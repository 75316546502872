import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { ConflictOfInterestViewPage, ConflictOfInterestListPage } from '@cotiss/conflict-of-interest'

export const ConflictOfInterestLayout = memo(() => (
  <Switch>
    <Route path="/conflict-of-interest/list" exact>
      <ConflictOfInterestListPage isAllConflicts />
    </Route>
    <Route path="/conflict-of-interest/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
    <Route path="/conflict-of-interest/me/list" exact>
      <ConflictOfInterestListPage />
    </Route>
    <Route path="/conflict-of-interest/me/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
  </Switch>
))
