import { useMutate } from '@cotiss/common'
import { SessionResponse } from '@cotiss/auth'

type UpgradeBillingBody = {
  freeSupplier?: boolean
}

type BillingPortalBody = {
  customerId: string
}

type BillingPortalResponse = {
  url: string
}

export const useMutateBilling = () => {
  const { mutate } = useMutate()

  return {
    upgradeBilling: async (body: UpgradeBillingBody) => {
      return await mutate<SessionResponse>({ route: '/v2/billing/upgrade', body })
    },
    billingPortal: async (body: BillingPortalBody) => {
      return await mutate<BillingPortalResponse>({ route: '/v2/billing/portal', body })
    },
  }
}
