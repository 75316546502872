import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

export const SignUpPromoBox = memo(({ className, children }: Props) => {
  const classes = classNames(className, 'absolute bg-secondary-700 rounded shadow-2xl w-[300px] p-8')

  return <div className={classes}>{children}</div>
})
