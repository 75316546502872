import { useHistory } from 'react-router-dom'
import React, { memo, useEffect } from 'react'
import { useAuth } from '@cotiss/auth'
import { COLOUR, Page, Spinner, Text, routerService } from '@cotiss/common'

export const LogoutPage = memo(() => {
  const { push } = useHistory()
  const { logout } = useAuth()

  useEffect(() => {
    logout()
    push(routerService.getHref('/login'))
  }, [])

  return (
    <Page className="flex items-center justify-center">
      <div>
        <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        <Text className="text-center">Logging you out...</Text>
      </div>
    </Page>
  )
})
