import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardDocumentListInput, GqlPerformanceScorecardDocumentListQuery } from '@gql'

export const queryPerformanceScorecardDocumentList = async (input: GqlPerformanceScorecardDocumentListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT}

      query PerformanceScorecardDocumentList($input: PerformanceScorecardDocumentListInput!) {
        performanceScorecardDocumentList(input: $input) {
          items {
            ...PerformanceScorecardDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardDocumentList
}
