import { useFetch, PaginatedModel } from '@cotiss/common'
import { CorrespondenceModel } from '@cotiss/correspondence'

type Props = {
  limit?: number
  offset?: number
  contractShellId?: string
}

export const useListCorrespondence = ({ offset = 0, limit = -1, ...params }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<CorrespondenceModel>>('/v2/correspondence', {
    route: '/v2/correspondence',
    params: { ...params, offset, limit },
  })

  return { correspondenceItems: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
