import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { documentService, useGetDocument } from '@cotiss/document'
import { EvaluationEventEnvelopeDocumentUploadModal, useEvaluationEnvelopeDocument, useEvaluationUser } from '@cotiss/evaluation-event'
import {
  Button,
  ConfirmModal,
  ErrorPanel,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  TableRowCta,
  Text,
  datetimeService,
  sentryService,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

export const EvaluationEventEvaluateEnvelopeUserResourcesTab = memo(() => {
  const { openModal } = useCallout()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationUserInSession } = useEvaluationUser()
  const [pagination, setPagination] = useState<GqlPagination>()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationEnvelopeDocuments, queryEvaluationEnvelopeDocumentList, mutateDeleteEvaluationEnvelopeDocument } = useEvaluationEnvelopeDocument()
  const { evaluationEventId, evaluationEnvelopeId, evaluationUserId } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationUserId: string
  }>()

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryEvaluationEnvelopeDocumentList({
        filter: { evaluationEventId, evaluationEnvelopeId, evaluationUserId },
        pagination: { page: currentPage, pageSize: 100 },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Document name',
        rows: map(evaluationEnvelopeDocuments, ({ id: evaluationEnvelopeDocumentId, fileName, documentId }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(documentId)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => mutateDeleteEvaluationEnvelopeDocument({ evaluationEnvelopeDocumentId })}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        rows: map(evaluationEnvelopeDocuments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(evaluationEnvelopeDocuments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [evaluationEnvelopeDocuments])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" font="jakarta" size="lg">
          Resources
        </Text>
        {evaluationUserInSession?.id === evaluationUserId && (
          <Button onClick={() => openModal(<EvaluationEventEnvelopeDocumentUploadModal />)} state="translucent" variant="secondary-dark" size="xs">
            + Add resource
          </Button>
        )}
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          evaluationUserInSession?.id === evaluationUserId && (
            <Button
              size="sm"
              state="text"
              variant="secondary"
              onClick={() => openModal(<EvaluationEventEnvelopeDocumentUploadModal />)}
              isDisabled={isLoading}
            >
              + Add resource
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </>
  )
})
