class HubSpotService {
  _isInitialised = false

  init = () => {
    if (!this._isInitialised) {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = '//js-na1.hs-scripts.com/20404630.js'
      head.appendChild(script)

      this._isInitialised = true
    }
  }
}

export const hubSpotService = new HubSpotService()
