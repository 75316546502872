import { useMutate } from '@cotiss/common'

export const useMutateNotification = () => {
  const { mutate } = useMutate()

  return {
    markAllNotificationsAsRead: async () => {
      return await mutate({ route: '/v2/notifications' })
    },
  }
}
