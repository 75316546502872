import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationEnvelopeOrdersInput,
  GqlUpdateEvaluationEnvelopeOrdersMutation,
  GqlUpdateEvaluationEnvelopeOrdersMutationVariables,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationEnvelopeOrders = async (input: GqlUpdateEvaluationEnvelopeOrdersInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationEnvelopeOrdersMutation, GqlUpdateEvaluationEnvelopeOrdersMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      mutation UpdateEvaluationEnvelopeOrders($input: UpdateEvaluationEnvelopeOrdersInput!) {
        updateEvaluationEnvelopeOrders(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationEnvelopeOrders as GqlEvaluationEnvelopeFieldsFragment[]
}
