import {
  Badge,
  Button,
  Card,
  CardFormHeader,
  Field,
  Form,
  Hr,
  Input,
  NoDataPlaceholder,
  OCDS_CURRENCY_MAP,
  OCDS_CURRENCY_OPTIONS,
  OcdsCurrencyCode,
  Pill,
  RegionsModal,
  RichText,
  RichTextEditor,
  Select_DEPRECATED,
  Skeleton,
  Text,
  UnspscCategoriesModal,
  regionService,
  richTextService,
  sentryService,
  useCallout,
  useToast,
  utilService,
} from '@cotiss/common'
import { useGetProcurement, useMutateProcurement } from '@cotiss/procurement'
import { supplierService } from '@cotiss/supplier'
import classNames from 'classnames'
import { filter, map } from 'lodash'
import React, { FormEvent, memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RemirrorJSON } from 'remirror'

type FormData = {
  title: string
  internalIdentifier: string
  manualIdentifier: string
  summary: RemirrorJSON
  regions: string[]
  topLevelClassification: string[]
  currency: OcdsCurrencyCode
  opexBudget: string
  capexBudget: string
}

export const ProcurementOverviewBackgroundGeneralTab = memo(() => {
  const { openToast } = useToast()
  const { openModal, closeModal } = useCallout()
  const { procurementId } = useParams<{ procurementId: string }>()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateProcurement } = useMutateProcurement()
  const { procurement, isLoading: isLoadingProcurement } = useGetProcurement(procurementId)
  const isLoading = isLoadingProcurement
  const isEditable = true // TODO: Figure out when this should be editable
  const [formData, setFormData] = useState<FormData>({
    title: procurement?.title || '',
    internalIdentifier: procurement?.internalIdentifier || '',
    manualIdentifier: procurement?.manualIdentifier || '',
    summary: procurement?.summary || richTextService.getEmptyRemirrorJson(),
    regions: procurement?.regions || [],
    topLevelClassification: map(procurement?.topLevelClassification, ({ _id }) => _id) || [],
    currency: procurement?.totalBudget?.currency || 'NZD',
    opexBudget: procurement?.opexBudget?.amount?.toString() || '',
    capexBudget: procurement?.capexBudget?.amount?.toString() || '',
  })
  const processedTopLevelClassification = supplierService.processCategoryCodes(formData.topLevelClassification)

  useEffect(() => {
    if (!procurement) {
      return
    }

    setFormData({
      title: procurement.title,
      internalIdentifier: procurement?.internalIdentifier || '',
      manualIdentifier: procurement?.manualIdentifier || '',
      summary: procurement?.summary || richTextService.getEmptyRemirrorJson(),
      regions: procurement?.regions || [],
      topLevelClassification: map(procurement?.topLevelClassification, ({ _id }) => _id) || [],
      currency: procurement?.totalBudget?.currency || 'NZD',
      opexBudget: procurement?.opexBudget?.amount?.toString() || '',
      capexBudget: procurement?.capexBudget?.amount?.toString() || '',
    })
  }, [procurement])

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({
      title: procurement?.title || '',
      summary: procurement?.summary || richTextService.getEmptyRemirrorJson(),
      internalIdentifier: procurement?.internalIdentifier || '',
      manualIdentifier: procurement?.manualIdentifier || '',
      regions: procurement?.regions || [],
      topLevelClassification: map(procurement?.topLevelClassification, ({ _id }) => _id) || [],
      currency: procurement?.totalBudget?.currency || 'NZD',
      opexBudget: procurement?.opexBudget?.amount?.toString() || '',
      capexBudget: procurement?.capexBudget?.amount?.toString() || '',
    })
    setIsEditing(isEditing)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const { title, summary, currency, opexBudget, capexBudget, ...restFormData } = formData
      await updateProcurement(procurementId, {
        title,
        summary,
        ...restFormData,
      })

      if (currency && (opexBudget !== '' || capexBudget !== '')) {
        const opexBudgetAmount = Number(opexBudget)
        const capexBudgetAmount = Number(capexBudget)

        await updateProcurement(procurementId, {
          opexBudget: opexBudget !== '' ? { currency, amount: opexBudgetAmount } : undefined,
          capexBudget: capexBudget !== '' ? { currency, amount: capexBudgetAmount } : undefined,
        })
      }

      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleUnspscCategoryChange = (topLevelClassification: string[]) => {
    setFormData({ ...formData, topLevelClassification })
    closeModal()
  }

  const handleOpenCategoriesModal = () => {
    openModal(<UnspscCategoriesModal selectedCategoryCodes={formData.topLevelClassification} onChange={handleUnspscCategoryChange} />)
  }

  const handleOpenRegionsModal = () =>
    openModal(
      <RegionsModal
        selectedRegions={formData.regions}
        onChange={(regions) => {
          setFormData({
            ...formData,
            regions,
          })
        }}
      />
    )

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderTotalBudget = () => {
    const opexBudget = formData.opexBudget && (Number(formData.opexBudget) || 0)
    const capexBudget = formData.capexBudget && (Number(formData.capexBudget) || 0)

    if (opexBudget === '' && capexBudget === '') {
      return '--'
    }

    const totalBudget = (opexBudget || 0) + (capexBudget || 0)

    return utilService.formatAsCurrency(totalBudget, formData.currency)
  }

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Field label="Title">
            <Input
              value={formData.title}
              isRequired
              onChange={({ target }) => setFormData({ ...formData, title: target.value })}
              isDisabled={isSaving}
            />
          </Field>
          <Field className="mt-8" label="Internal reference (optional)">
            <Input
              value={formData.manualIdentifier}
              onChange={({ target }) => setFormData({ ...formData, manualIdentifier: target.value })}
              isDisabled={isSaving}
            />
          </Field>
          <Field className="mt-8" label="Internal summary">
            <RichTextEditor value={formData.summary} onChange={(summary) => setFormData({ ...formData, summary })} isDisabled={isSaving} />
          </Field>
          <Field className="mt-8" label="Regions">
            {!formData.regions.length && <NoDataPlaceholder label="No regions selected" ctaLabel="+ Add" onCtaClick={handleOpenRegionsModal} />}
            {Boolean(formData.regions.length) && (
              <>
                <div className="bg-primary-50 p-2 pb-3">
                  {map(regionService.processRegions(formData.regions), ({ value, label }) => (
                    <Pill
                      className="mr-1 mt-1"
                      key={value}
                      onRemove={() => setFormData({ ...formData, regions: filter(formData.regions, (v) => v !== value) })}
                      isDisabled={isSaving}
                    >
                      {label}
                    </Pill>
                  ))}
                </div>
                <Button className="mt-2" onClick={handleOpenRegionsModal} size="xs" state="translucent" variant="secondary" isDisabled={isSaving}>
                  + Add
                </Button>
              </>
            )}
          </Field>
          <Field className="mt-8" label="Categories">
            {!formData.topLevelClassification.length && (
              <NoDataPlaceholder label="No categories selected" ctaLabel="+ Add" onCtaClick={handleOpenCategoriesModal} />
            )}
            {Boolean(formData.topLevelClassification.length) && (
              <>
                <div className="bg-primary-50 p-2 pb-3">
                  {map(processedTopLevelClassification, ({ code, name }) => (
                    <Pill
                      className="mr-1 mt-1"
                      key={code}
                      onRemove={() =>
                        setFormData({ ...formData, topLevelClassification: filter(formData.topLevelClassification, (c) => c !== code) })
                      }
                      isDisabled={isSaving}
                    >
                      {name}
                    </Pill>
                  ))}
                </div>
                <Button className="mt-2" onClick={handleOpenCategoriesModal} size="xs" state="translucent" variant="secondary" isDisabled={isSaving}>
                  + Add
                </Button>
              </>
            )}
          </Field>
          <Field className="mt-8" label="Currency">
            <Select_DEPRECATED<OcdsCurrencyCode>
              value={formData.currency}
              options={OCDS_CURRENCY_OPTIONS}
              onChange={(currency) => setFormData({ ...formData, currency: currency })}
              isDisabled={!isEditable || isSaving}
              placeholder
              isRequired={formData.opexBudget !== '' || formData.capexBudget !== ''}
            />
          </Field>
          <Field className="mt-8" label="Opex budget">
            <div className="relative">
              <Badge
                className="absolute top-3 left-3"
                state="translucent"
                shape="rect"
                variant="neutral"
                title={OCDS_CURRENCY_MAP[formData.currency]}
              >
                {formData.currency}
              </Badge>

              <Input
                className={classNames({ 'pl-16': formData.currency })}
                value={formData.opexBudget}
                onChange={({ target }) => setFormData({ ...formData, opexBudget: target.value })}
                isDisabled={isSaving}
              />
            </div>
          </Field>
          <Field className="mt-8" label="Capex budget">
            <div className="relative">
              <Badge
                className="absolute top-3 left-3"
                state="translucent"
                shape="rect"
                variant="neutral"
                title={OCDS_CURRENCY_MAP[formData.currency]}
              >
                {formData.currency}
              </Badge>

              <Input
                className={classNames({ 'pl-16': formData.currency })}
                value={formData.capexBudget}
                onChange={({ target }) => setFormData({ ...formData, capexBudget: target.value })}
                isDisabled={isSaving}
              />
            </div>
          </Field>
          <Field className="mt-8" label="Total budget">
            <Text>{renderTotalBudget()}</Text>
          </Field>
        </>
      )
    }

    return (
      <>
        <Field label="Title">
          {!isLoading && <Text className="font-medium">{procurement?.title}</Text>}
          {isLoading && <Skeleton className="h-3 w-2/3" />}
        </Field>
        <Field className="mt-8" label="Reference">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && <Text>{procurement?.internalIdentifier}</Text>}
        </Field>
        <Field className="mt-8" label="Internal reference">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!procurement?.manualIdentifier && isEditable && renderToggleLink('+ Add internal reference')}
              {!procurement?.manualIdentifier && !isEditable && <Text>--</Text>}
              {procurement?.manualIdentifier && <Text>{procurement.manualIdentifier}</Text>}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Internal summary">
          {!isLoading && (
            <>
              {!procurement?.summary && isEditable && renderToggleLink('+ Add internal summary')}
              {procurement?.summary && <RichText value={procurement.summary} />}
            </>
          )}
          {isLoading && (
            <>
              <Skeleton className="h-3 w-full mt-1" />
              <Skeleton className="h-3 w-full mt-1" />
              <Skeleton className="h-3 w-full mt-1" />
              <Skeleton className="h-3 w-full mt-1" />
              <Skeleton className="h-3 w-2/3 mt-1" />
            </>
          )}
        </Field>
        <Field className="mt-8" label="Regions">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!procurement?.regions?.length && isEditable && renderToggleLink('+ Add regions')}
              {!procurement?.regions?.length && !isEditable && <Text>--</Text>}
              {!!procurement?.regions.length &&
                map(regionService.processRegions(procurement.regions), (region) => (
                  <Badge key={region.value} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                    {region.label}
                  </Badge>
                ))}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Categories">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!procurement?.topLevelClassification.length && isEditable && renderToggleLink('+ Add categories')}
              {Boolean(procurement?.topLevelClassification.length) &&
                map(procurement?.topLevelClassification, (classification) => (
                  <Badge key={classification._id} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                    {classification.description}
                  </Badge>
                ))}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Currency">
          {!isLoading && (
            <>
              {!procurement?.totalBudget && isEditable && renderToggleLink('+ Add currency')}
              {!procurement?.totalBudget && !isEditable && <Text>--</Text>}
              {procurement?.totalBudget && <Text>{procurement?.totalBudget.currency}</Text>}
            </>
          )}
          {isLoading && <Skeleton className="h-3 w-2/3" />}
        </Field>
        <Field className="mt-8" label="Opex budget">
          {!isLoading && (
            <>
              {!procurement?.opexBudget && isEditable && renderToggleLink('+ Add opex budget')}
              {!procurement?.opexBudget && !isEditable && <Text>--</Text>}
              {procurement?.opexBudget && (
                <Text>{utilService.formatAsCurrency(procurement?.opexBudget.amount, procurement?.opexBudget.currency)}</Text>
              )}
            </>
          )}
          {isLoading && <Skeleton className="h-3 w-2/3" />}
        </Field>
        <Field className="mt-8" label="Capex budget">
          {!isLoading && (
            <>
              {!procurement?.capexBudget && isEditable && renderToggleLink('+ Add capex budget')}
              {!procurement?.capexBudget && !isEditable && <Text>--</Text>}
              {procurement?.capexBudget && (
                <Text>{utilService.formatAsCurrency(procurement?.capexBudget.amount, procurement?.capexBudget.currency)}</Text>
              )}
            </>
          )}
          {isLoading && <Skeleton className="h-3 w-2/3" />}
        </Field>
        <Field className="mt-8" label="Total budget">
          {!isLoading && (
            <>
              {!procurement?.totalBudget && <Text>--</Text>}
              {procurement?.totalBudget && (
                <Text>{utilService.formatAsCurrency(procurement?.totalBudget.amount, procurement?.totalBudget.currency)}</Text>
              )}
            </>
          )}
          {isLoading && <Skeleton className="h-3 w-2/3" />}
        </Field>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader heading="General" onToggleEdit={handleToggleEdit} isEditable={isEditable} isEditing={isEditing} isSaving={isSaving} />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
