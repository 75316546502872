import { parseISO } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { find, forEach, includes, map, reduce } from 'lodash'
import { SupplierViewDrawer } from '@cotiss/supplier'
import { useListTenderInvitation } from '@cotiss/tender-invitation'
import { TenderResponseInviteModal, TenderResponseStatus, useListTenderResponse } from '@cotiss/tender-response'
import {
  BaseVariant,
  datetimeService,
  Kanban,
  KanbanCard,
  KanbanCardSkeleton,
  KanbanColumn,
  KanbanHeaderCard,
  NoDataPlaceholder,
  Text,
  useCallout,
  useGetWindowDimensions,
} from '@cotiss/common'

const TENDER_RESPONSE_COLUMNS: TenderResponseStatus[] = ['invited', 'watching', 'drafting', 'submitted', 'withdrawn']

type TenderResponseItem = {
  organisationName: string
  organisationId?: string
  status: TenderResponseStatus
  verified?: boolean
  updatedAt: Date
  tenderResponseId?: string
  tenderInvitationId?: string
  procurementResponseId?: string
}

type GroupedTenderResponseItem = {
  variant: BaseVariant
  items: TenderResponseItem[]
}

type Props = {
  tenderId: string
  onPreviewTenderResponse: (tenderResponseId: string) => void
  isEditable?: boolean
}

export const TenderResponseResponsesTab = memo(({ tenderId, onPreviewTenderResponse, isEditable }: Props) => {
  const { windowHeight } = useGetWindowDimensions()
  const { openModal, openNarrowDrawer } = useCallout()
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId })
  const { tenderInvitations, isLoading: isTenderInvitationsLoading } = useListTenderInvitation({ tenderId })
  const isLoading = isTenderResponsesLoading || isTenderInvitationsLoading
  // Easiest way to get the correct overflow behaviour of the Kanban is to set the height of the Kanban to the height of the window minus the height
  // everything above it. We just have to be aware that if we change the height of anything above the Kanban, we need to update this calculation.
  const componentHeight = windowHeight - 400

  const groupedResponses = useMemo(() => {
    const groupedResponses: Record<TenderResponseStatus, GroupedTenderResponseItem> = {
      invited: { variant: 'primary', items: [] },
      watching: { variant: 'info', items: [] },
      drafting: { variant: 'warning', items: [] },
      submitted: { variant: 'success', items: [] },
      withdrawn: { variant: 'danger', items: [] },
    }

    forEach(tenderResponses, ({ _id, procurementResponse, status, updatedAt }) => {
      const tenderInvitation = find(tenderInvitations, ({ preferredSupplier }) => {
        return preferredSupplier.supplierOrganisation?._id === procurementResponse.supplier._id
      })

      groupedResponses[status].items = [
        ...groupedResponses[status].items,
        {
          tenderResponseId: _id,
          tenderInvitationId: tenderInvitation?._id,
          procurementResponseId: procurementResponse._id,
          organisationName: procurementResponse.supplier.name,
          organisationId: procurementResponse.supplier._id,
          updatedAt: parseISO(updatedAt),
          verified: true,
          status,
        },
      ]
    })

    const respondedOrganisations = map(tenderResponses, ({ procurementResponse }) => procurementResponse.supplier._id)

    forEach(tenderInvitations, ({ _id, preferredSupplier, updatedAt }) => {
      const { supplierOrganisation, organisationName } = preferredSupplier

      if (!includes(respondedOrganisations, supplierOrganisation?._id)) {
        groupedResponses.invited.items = [
          ...groupedResponses.invited.items,
          {
            tenderInvitationId: _id,
            organisationName: supplierOrganisation?.name || organisationName || '–',
            organisationId: supplierOrganisation?._id,
            updatedAt: parseISO(updatedAt),
            verified: Boolean(supplierOrganisation),
            status: 'invited',
          },
        ]
      }
    })

    return groupedResponses
  }, [tenderResponses, tenderInvitations])

  const totalResponses = useMemo(() => reduce(groupedResponses, (total, { items }) => total + items.length, 0), [groupedResponses])

  const handleClick = ({ organisationId, procurementResponseId, tenderResponseId, tenderInvitationId }: TenderResponseItem) => {
    if (organisationId) {
      openNarrowDrawer(
        <SupplierViewDrawer
          organisationId={organisationId}
          procurementResponseId={procurementResponseId}
          tenderResponseId={tenderResponseId}
          tenderInvitationId={tenderInvitationId}
          onPreviewTenderResponse={onPreviewTenderResponse}
        />
      )
    }
  }

  const renderColumnContent = (status: TenderResponseStatus, index: number) => {
    if (isLoading) {
      return (
        <>
          {map(Array((index + 1) % 3), (_, index) => (
            <KanbanCardSkeleton key={index} />
          ))}
        </>
      )
    }

    return (
      <>
        {map(groupedResponses[status].items, (tenderResponseItem, index) => (
          <KanbanCard key={index} onClick={() => handleClick(tenderResponseItem)}>
            <Text className="text-left">{tenderResponseItem.organisationName}</Text>
            <Text className="text-left mt-2" variant="light" size="sm">
              Last activity: {datetimeService.format(tenderResponseItem.updatedAt, 'do MMM yyyy')}
            </Text>
          </KanbanCard>
        ))}
      </>
    )
  }

  if (totalResponses === 0) {
    return (
      <div style={{ height: componentHeight }} className="flex items-center justify-center bg-gray-200 overflow-y-auto">
        <NoDataPlaceholder
          variant="gray"
          label="No suppliers have been invited or responded to this sourcing event."
          ctaLabel="+ Invite"
          isDisabled={!isEditable}
          onCtaClick={() => openModal(<TenderResponseInviteModal tenderId={tenderId} isEditable={isEditable} />)}
        />
      </div>
    )
  }

  return (
    <Kanban style={{ height: componentHeight }}>
      {TENDER_RESPONSE_COLUMNS.map((status, index) => (
        <KanbanColumn key={status}>
          <KanbanHeaderCard variant={groupedResponses[status].variant} count={groupedResponses[status].items.length}>
            <Text className="capitalize">{status}</Text>
          </KanbanHeaderCard>
          {renderColumnContent(status, index)}
        </KanbanColumn>
      ))}
    </Kanban>
  )
})
