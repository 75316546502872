import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { EvaluationStatus } from '@cotiss/evaluation'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'

const getVariant = (type: EvaluationStatus): BaseVariant => {
  switch (type) {
    case 'notStarted':
      return 'info'
    case 'inProgress':
      return 'warning'
    case 'completed':
      return 'success'
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: EvaluationStatus
  size?: BadgeSize
}

export const EvaluationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status))}
  </Badge>
))
