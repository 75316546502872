import { gql } from '@apollo/client'

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    accountId
    firstName
    lastName
    email
  }
`
