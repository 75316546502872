import React, { memo } from 'react'
import { Card, CardHeader, Text } from '@cotiss/common'
import { EvaluationEventSummaryEnvelopes } from '@cotiss/evaluation-event'

export const EvaluationEventViewDetailsEnvelopesTab = memo(() => (
  <Card>
    <CardHeader className="flex items-center justify-between">
      <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
        Envelopes
      </Text>
    </CardHeader>
    <EvaluationEventSummaryEnvelopes />
  </Card>
))
