import { useMutate } from '@cotiss/common'

type ResendTenderInvitationBody = {
  user?: string
  contact?: string
}

type SendTenderInvitationBulkBody = {
  tenderId: string
  items: {
    preferredSupplierId: string
    contacts?: string[]
    users?: string[]
  }[]
}

type ValidateTenderInvitationBody = {
  token: string
}

type ValidateTenderInvitationResponse = {
  redirectUrl: string
}

export const useMutateTenderInvitation = () => {
  const { mutate } = useMutate()

  return {
    sendTenderInvitationBulk: async (body: SendTenderInvitationBulkBody) => {
      return await mutate({ route: `/v2/tender-invitations/bulk`, body, invalidate: ['/v2/tender-invitations', '/v2/preferred-suppliers'] })
    },
    resendTenderInvitation: async (id: string, body: ResendTenderInvitationBody) => {
      return await mutate({ route: `/v2/tender-invitations/${id}/resend`, body })
    },
    validateTenderInvitation: async (body: ValidateTenderInvitationBody) => {
      return await mutate<ValidateTenderInvitationResponse>({ route: '/v2/tender-invitations/validate', body })
    },
  }
}
