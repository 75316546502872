import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common'
import { TenderUpdateViewModel } from '@cotiss/tender-update'

type CreateTenderUpdateBody = {
  tenderId: string
  title: string
  content: RemirrorJSON
  attachments: string[]
}

type UpdateTenderUpdateBody = {
  title?: string
  content?: RemirrorJSON
  attachments?: string[]
  pinned?: boolean
  hidden?: boolean
}

export type ActionTenderUpdateParam = 'like' | 'unlike'

type CreateTenderUpdateCommentBody = {
  tenderUpdateId: string
  content: string
}

type ReplyToTenderUpdateCommentBody = {
  content: string
}

export const useMutateTenderUpdate = () => {
  const { mutate } = useMutate()

  return {
    createTenderUpdate: async (body: CreateTenderUpdateBody) => {
      return await mutate({ route: `/v2/tender-updates`, body })
    },
    updateTenderUpdate: async (id: string, body: UpdateTenderUpdateBody) => {
      return await mutate({ route: `/v2/tender-updates/${id}`, body, invalidate: ['/v2/tender-updates', '/v2/tender-update-view'] })
    },
    actionTenderUpdate: async (id: string, action: ActionTenderUpdateParam) => {
      return await mutate({ route: `/v2/tender-updates/${id}/${action}`, invalidate: ['/v2/tender-updates'] })
    },
    createTenderUpdateComment: async (body: CreateTenderUpdateCommentBody) => {
      return await mutate({
        route: `/v2/tender-update-comments`,
        body,
        invalidate: ['/v2/tender-updates', '/v2/tender-update-comments'],
      })
    },
    replyToTenderUpdateComment: async (id: string, body: ReplyToTenderUpdateCommentBody) => {
      return await mutate({
        route: `/v2/tender-update-comments/${id}/replies`,
        body,
        invalidate: ['/v2/tender-updates', '/v2/tender-update-comments'],
      })
    },
    actionTenderUpdateComment: async (id: string, action: ActionTenderUpdateParam) => {
      return await mutate({ route: `/v2/tender-update-comments/${id}/${action}`, invalidate: ['/v2/tender-updates', '/v2/tender-update-comments'] })
    },
    createTenderUpdateView: async ({ tenderUpdateId, tenderId }: { tenderUpdateId: string; tenderId: string }) => {
      return await mutate<TenderUpdateViewModel>({
        route: `/v2/tender-update-view`,
        body: { tenderUpdateId, tenderId },
        invalidate: ['/v2/tender-updates', '/v2/tender-update-view'],
      })
    },
  }
}
