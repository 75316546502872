import { map, reverse } from 'lodash'
import { SelectOption_DEPRECATED } from '@cotiss/common'
import { GqlEvaluationCriteriaRatingScale, GqlEvaluationEventMethodology } from '@gql'

export const EVALUATION_EVENT_ROUTES = [
  '/evaluation-event',
  '/evaluation-event/list/:tab?',
  '/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?',
  '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
  '/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId',
  '/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId',
  '/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?',
] as const

export const EVALUATION_EVENT_METHODOLOGY_NAME_MAP: Record<GqlEvaluationEventMethodology, string> = {
  brooksLaw: 'Brooks law',
  lowestPrice: 'Lowest price',
  priceQuality: 'Price quality',
  simpleScore: 'Simple score',
  targetPrice: 'Target price',
  weightedAttribute: 'Weighted attribute',
}

export const EVALUATION_EVENT_METHODOLOGY_DESCRIPTION_MAP: Record<GqlEvaluationEventMethodology, string> = {
  brooksLaw: '(Coming soon)',
  lowestPrice: '(Coming soon)',
  priceQuality: '(Coming soon)',
  simpleScore: 'Define your criteria. Each criteria has equal weight.',
  targetPrice: '(Coming soon)',
  weightedAttribute: `Define your criteria and give each one it's own weight.`,
}

type EvaluationEventMethodologyOption = {
  value: GqlEvaluationEventMethodology
  name: string
  description: string
  isDisabled: boolean
}

export const EVALUATION_EVENT_METHODOLOGY_OPTIONS: EvaluationEventMethodologyOption[] = [
  {
    value: 'simpleScore',
    name: EVALUATION_EVENT_METHODOLOGY_NAME_MAP['simpleScore'],
    description: EVALUATION_EVENT_METHODOLOGY_DESCRIPTION_MAP['simpleScore'],
    isDisabled: false,
  },
  {
    value: 'weightedAttribute',
    name: EVALUATION_EVENT_METHODOLOGY_NAME_MAP['weightedAttribute'],
    description: EVALUATION_EVENT_METHODOLOGY_DESCRIPTION_MAP['weightedAttribute'],
    isDisabled: false,
  },
]

export const EVALUATION_EVENT_RATING_SCALE_NAME_MAP: Record<GqlEvaluationCriteriaRatingScale, string> = {
  oneToFour: '1-4',
  percentage: '0-100%',
  zeroToFive: '0-5',
  zeroToFiveWithDefinitions: '0-5 (with default definitions)',
  zeroToOneHundredWithDefinitions: '0-100 (with default definitions)',
  zeroToOneHundredWithStaggeredDefinitions: '0-100 (with staggered definitions)',
  zeroToSevenWithDefinitions: '0,1,3,5,6,7 (with default definitions)',
  zeroToTen: '0-10',
  zeroToTenWithDefinitions: '0-10 (with default definitions)',
}

export const EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP: Record<GqlEvaluationCriteriaRatingScale, string> = {
  oneToFour: '4',
  zeroToFive: '5',
  zeroToFiveWithDefinitions: '5',
  zeroToSevenWithDefinitions: '7',
  zeroToTen: '10',
  zeroToTenWithDefinitions: '10',
  zeroToOneHundredWithDefinitions: '100',
  zeroToOneHundredWithStaggeredDefinitions: '100',
  percentage: '%',
}

export const EVALUATION_EVENT_RATING_SCALE_OPTIONS: SelectOption_DEPRECATED<GqlEvaluationCriteriaRatingScale>[] = [
  { value: 'oneToFour', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['oneToFour'] },
  { value: 'zeroToFive', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToFive'] },
  { value: 'zeroToFiveWithDefinitions', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToFiveWithDefinitions'] },
  { value: 'zeroToTen', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToTen'] },
  { value: 'zeroToTenWithDefinitions', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToTenWithDefinitions'] },
  { value: 'zeroToSevenWithDefinitions', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToSevenWithDefinitions'] },
  { value: 'zeroToOneHundredWithDefinitions', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToOneHundredWithDefinitions'] },
  { value: 'zeroToOneHundredWithStaggeredDefinitions', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['zeroToOneHundredWithStaggeredDefinitions'] },
  { value: 'percentage', label: EVALUATION_EVENT_RATING_SCALE_NAME_MAP['percentage'] },
]

export const IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES: GqlEvaluationCriteriaRatingScale[] = [
  'zeroToFiveWithDefinitions',
  'zeroToSevenWithDefinitions',
  'zeroToTenWithDefinitions',
  'zeroToOneHundredWithDefinitions',
  'zeroToOneHundredWithStaggeredDefinitions',
]

export const EVALUATION_EVENT_RATING_SCALE_ZERO_TO_FIVE_DEFINITIONS = [
  {
    score: 5,
    heading: 'Excellent',
    definition:
      'Exceeds the requirement.  Exceptional demonstration by the supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential substantial added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 4,
    heading: 'Good',
    definition:
      'Satisfies the requirement with minor additional benefits. Above average demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential minor added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 3,
    heading: 'Acceptable',
    definition:
      'Satisfies the requirement. Demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with evidence to support the response.',
  },
  {
    score: 2,
    heading: 'Minor reservations',
    definition:
      'Satisfies the requirement with minor reservations and/or risk. Some minor reservations of the suppliers relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 1,
    heading: 'Serious reservations',
    definition:
      'Satisfies the requirement with major reservations and/or risk. Considerable reservations of the suppliers relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 0,
    heading: 'Unacceptable',
    definition:
      'Does not meet the requirement. Does not comply and/or unacceptable risk and/or insufficient information provided to demonstrate that the Supplier has the ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
]

export const EVALUATION_EVENT_RATING_SCALE_ZERO_TO_TEN_DEFINITIONS = [
  {
    score: 10,
    heading: 'Excellent',
    definition:
      'Exceptional demonstration by the supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential substantial added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 9,
    heading: 'Excellent',
    definition:
      'Exceptional demonstration by the supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential substantial added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 8,
    heading: 'Good',
    definition:
      'Satisfies the requirement with minor additional benefits. Above average demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential minor added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 7,
    heading: 'Good',
    definition:
      'Satisfies the requirement with minor additional benefits. Above average demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential minor added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 6,
    heading: 'Acceptable',
    definition:
      'Satisfies the requirement. Demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with evidence to support the response.',
  },
  {
    score: 5,
    heading: 'Acceptable',
    definition:
      'Satisfies the requirement. Demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with evidence to support the response.',
  },
  {
    score: 4,
    heading: 'Minor reservations',
    definition:
      'Satisfies the requirement with minor reservations and/or risk. Some minor reservations of the Supplier’s relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 3,
    heading: 'Minor reservations',
    definition:
      'Satisfies the requirement with minor reservations and/or risk. Some minor reservations of the Supplier’s relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 2,
    heading: 'Serious reservations',
    definition:
      'Satisfies the requirement with major reservations and/or risk. Considerable reservations of the suppliers relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 1,
    heading: 'Serious reservations',
    definition:
      'Satisfies the requirement with major reservations and/or risk. Considerable reservations of the suppliers relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 0,
    heading: 'Unacceptable',
    definition:
      'Does not meet the requirement. Does not comply and/or unacceptable risk and/or insufficient information provided to demonstrate that the Supplier has the ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
]

export const EVALUATION_EVENT_RATING_SCALE_ZERO_TO_SEVEN_DEFINITIONS = [
  {
    score: 7,
    heading: 'Excellent',
    definition:
      'Exceeds the requirement.  Exceptional demonstration by the supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential substantial added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 6,
    heading: 'Good',
    definition:
      'Exceeds the requirement with minor additional benefits. Above average demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services. Response identifies factors that will offer potential minor added value and/or reduction of risk, with evidence to support the response.',
  },
  {
    score: 5,
    heading: 'Acceptable',
    definition:
      'Satisfies the requirement. Demonstration by the Supplier of the relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with evidence to support the response.',
  },
  {
    score: 3,
    heading: 'Minor reservations',
    definition:
      'Satisfies the requirement with minor reservations and/or risk. Some minor reservations of the Supplier’s relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 1,
    heading: 'Serious reservations',
    definition:
      'Satisfies the requirement with major reservations and/or risk. Considerable reservations of the suppliers relevant ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
  {
    score: 0,
    heading: 'Unacceptable',
    definition:
      'Does not meet the requirement. Does not comply and/or unacceptable risk and/or insufficient information provided to demonstrate that the Supplier has the ability, understanding, experience, skills, resource & quality measures required to provide the supplies / services, with little or no evidence to support the response.',
  },
]

export const EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_DEFINITIONS = [
  {
    score: `100,\n95,\n90,\n85`,
    heading: 'Excellent',
    definition:
      'Exceeds the criterion. Exceptional demonstration by the participant of the relevant ability, understanding, experience, skills, resource and quality measures required to meet the criterion. Response identifies factors that will offer significant potential added value, with supporting evidence.',
  },
  {
    score: `80,\n75,\n70,\n65`,
    heading: 'Very good',
    definition:
      'Satisfies the criterion with minor additional benefits. Above average demonstration by the participant of the relevant ability, understanding, experience, skills, resource and quality measures required to meet the criterion. Response identifies factors that will offer potential added value, with supporting evidence.',
  },
  {
    score: `60,\n55,\n50,\n45`,
    heading: 'Good',
    definition:
      'Satisfies the criterion. Demonstration by the participant of the relevant ability, understanding, experience, skills, resource and quality measures required to meet the criterion, with supporting evidence.',
  },
  {
    score: `40,\n35,\n30,\n25`,
    heading: 'Minor reservations',
    definition:
      'Does not fully meet the criterion. Some minor reservations of the participant’s relevant ability, understanding, experience, skills, resource and quality measures required to meet the criterion, with little supporting evidence.',
  },
  {
    score: `20,\n15,\n10,\n5`,
    heading: 'Serious reservations',
    definition:
      'Does not meet the criterion. Considerable reservations of the participant’s relevant ability, understanding, experience, skills, resource and quality measures required to meet the criterion, with little or no supporting evidence.',
  },
  {
    score: 0,
    heading: 'Unacceptable',
    definition:
      'Does not meet the criterion. Does not comply and/or insufficient information provided to demonstrate that the participant has the ability, understanding, experience, skills, resource and quality measures required to meet the criterion, with no supporting evidence.',
  },
]

export const EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_STAGGERED_DEFINITIONS = [
  {
    score: '90-100',
    heading: 'Excellent',
    definition:
      'Respondent demonstrates exceptional ability, understanding, experience and skills. The Proposal identifies factors that will offer potential added value, with supporting evidence.',
  },
  {
    score: '70-89',
    heading: 'Good',
    definition:
      'Respondent demonstrates above average ability, understanding, experience and skills. The Proposal identifies minor additional benefits, with supporting evidence.',
  },
  {
    score: '50-69',
    heading: 'Acceptable',
    definition: 'Respondent demonstrates the ability to meet the criteria, with supporting evidence.',
  },
  {
    score: '30-49',
    heading: 'Reservations',
    definition:
      'Satisfies only a minimum of the criteria but not all. Reservations about the Respondent to adequately meet the criteria. Little supporting evidence.',
  },
  {
    score: '10-29',
    heading: 'Serious reservations',
    definition: 'Extremely limited or no supporting evidence to meet the criteria. Minimum effort made to meet the criteria.',
  },
  {
    score: '0-9',
    heading: 'Unacceptable',
    definition: 'Does not comply or meet the criteria at all. Insufficient information to demonstrate the criteria.',
  },
]

export const EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS: Record<
  Exclude<GqlEvaluationCriteriaRatingScale, 'percentage' | 'zeroToOneHundredWithStaggeredDefinitions'>,
  SelectOption_DEPRECATED[]
> = {
  oneToFour: map(Array(4), (_, index) => ({ label: (index + 1).toString(), value: (index + 1).toString() })),
  zeroToFive: map(Array(6), (_, index) => ({ label: index.toString(), value: index.toString() })),
  zeroToFiveWithDefinitions: map(Array(6), (_, index) => ({ label: index.toString(), value: index.toString() })),
  zeroToTen: map(Array(11), (_, index) => ({ label: index.toString(), value: index.toString() })),
  zeroToTenWithDefinitions: map(Array(11), (_, index) => ({ label: index.toString(), value: index.toString() })),
  zeroToSevenWithDefinitions: map(reverse(EVALUATION_EVENT_RATING_SCALE_ZERO_TO_SEVEN_DEFINITIONS), ({ score }) => ({
    label: score.toString(),
    value: score.toString(),
  })),
  zeroToOneHundredWithDefinitions: [
    { label: '0', value: '0' },
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '30', value: '30' },
    { label: '35', value: '35' },
    { label: '40', value: '40' },
    { label: '45', value: '45' },
    { label: '50', value: '50' },
    { label: '55', value: '55' },
    { label: '60', value: '60' },
    { label: '65', value: '65' },
    { label: '70', value: '70' },
    { label: '75', value: '75' },
    { label: '80', value: '80' },
    { label: '85', value: '85' },
    { label: '90', value: '90' },
    { label: '95', value: '95' },
    { label: '100', value: '100' },
  ],
}
