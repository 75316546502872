import { PaginatedModel, useFetch } from '@cotiss/common'
import { ShortListPopulatedModel } from '@cotiss/short-list'

type Props = {
  recommendationId?: string
  limit?: number
  offset?: number
}

export const useListShortList = ({ recommendationId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ShortListPopulatedModel>>('/v2/short-lists', {
    params: { recommendationId, limit, offset },
    isEnabled: Boolean(recommendationId),
  })

  return { shortLists: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
