import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useListCorrespondence } from '@cotiss/correspondence'
import { Button, Card, CardHeader, NoDataPlaceholder, Skeleton, Text, useCallout } from '@cotiss/common'
import {
  ContractAddEditCorrespondenceDrawer,
  ContractCorrespondenceListItem,
  ContractStatusBadge,
  contractService,
  useGetContractShell,
} from '@cotiss/contract'

export const ContractViewCorrespondenceTab = memo(() => {
  const { openNarrowDrawer } = useCallout()
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { correspondenceItems } = useListCorrespondence({ contractShellId })
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5" font="jakarta">
              Notes
            </Text>
          </div>
        </CardHeader>
        <div className="w-full p-6 border rounded-md">
          <Skeleton className="h-6 w-32" />
          <Skeleton className="h-6 w-60" />
        </div>
        <div className="w-full p-6 border rounded-md">
          <Skeleton className="h-6 w-32" />
          <Skeleton className="h-6 w-60" />
        </div>
      </Card>
    )
  }

  if (!contract || !(contract.status === 'PUBLISHED' || contract.status === 'CEASED')) {
    return (
      <Card>
        <NoDataPlaceholder illustration="dot-list" label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div>
            {isLoading && <Skeleton className="h-2 w-1/3 mb-1" />}
            {!isLoading && (
              <Text className="mb-1" size="sm" variant="light">
                {contractShell?.title || ''}
              </Text>
            )}
            <Text className="font-semibold" size="h5" font="jakarta">
              Notes
            </Text>
          </div>
        </div>
        <div>
          <ContractStatusBadge className="mr-4" status={contract.status} />
          <Button
            variant="secondary"
            size="sm"
            onClick={() => openNarrowDrawer(<ContractAddEditCorrespondenceDrawer contractShellId={contractShellId} />)}
          >
            Add note
          </Button>
        </div>
      </CardHeader>
      {!correspondenceItems.length && <NoDataPlaceholder illustration="dot-list" label="No notes" />}
      {correspondenceItems.map((correspondence) => {
        return <ContractCorrespondenceListItem key={correspondence._id} contractShellId={contractShellId} correspondence={correspondence} />
      })}
    </Card>
  )
})
