import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationSubmissionInput,
  GqlUpdateEvaluationSubmissionMutation,
  GqlUpdateEvaluationSubmissionMutationVariables,
  GqlEvaluationSubmissionFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationSubmission = async (input: GqlUpdateEvaluationSubmissionInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationSubmissionMutation, GqlUpdateEvaluationSubmissionMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_FRAGMENT}

      mutation UpdateEvaluationSubmission($input: UpdateEvaluationSubmissionInput!) {
        updateEvaluationSubmission(input: $input) {
          ...EvaluationSubmissionFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationSubmission as GqlEvaluationSubmissionFieldsFragment
}
