var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-collaboration/src/collaboration-extension.ts
import {
  command,
  debounce,
  extension,
  invariant,
  isArray,
  isNumber,
  PlainExtension,
  uniqueId
} from "@remirror/core";
import { collab, getVersion, receiveTransaction, sendableSteps } from "@remirror/pm/collab";
import { Step } from "@remirror/pm/transform";
var CollaborationExtension = class extends PlainExtension {
  get name() {
    return "collaboration";
  }
  get debounceGetSendableSteps() {
    if (!this._debounceGetSendableSteps) {
      this._debounceGetSendableSteps = debounce(
        this.options.debounceMs,
        this.getSendableSteps.bind(this)
      );
    }
    return this._debounceGetSendableSteps;
  }
  sendCollaborationUpdate(attributes) {
    return ({ state, dispatch }) => {
      invariant(isValidCollaborationAttributes(attributes), {
        message: "Invalid attributes passed to the collaboration command."
      });
      const { version, steps } = attributes;
      if (getVersion(state) > version) {
        return false;
      }
      if (dispatch) {
        dispatch(
          receiveTransaction(
            state,
            steps.map((item) => Step.fromJSON(this.store.schema, item)),
            steps.map((item) => item.clientID)
          )
        );
      }
      return true;
    };
  }
  cancelSendableSteps() {
    return () => {
      var _a;
      (_a = this.debounceGetSendableSteps) == null ? void 0 : _a.cancel();
      return true;
    };
  }
  flushSendableSteps() {
    return ({ state }) => {
      var _a;
      (_a = this.debounceGetSendableSteps) == null ? void 0 : _a.cancel();
      this.getSendableSteps(state);
      return true;
    };
  }
  createExternalPlugins() {
    const { version, clientID } = this.options;
    const plugin = collab({
      version,
      clientID
    });
    return [plugin];
  }
  onStateUpdate(props) {
    var _a;
    (_a = this.debounceGetSendableSteps) == null ? void 0 : _a.call(this, props.state);
  }
  onDestroy() {
    this.store.commands.flushSendableSteps();
  }
  /**
   * This passes the sendable steps into the `onSendableReceived` handler defined in the
   * options when there is something to send.
   */
  getSendableSteps(state) {
    const sendable = sendableSteps(state);
    if (sendable) {
      const jsonSendable = {
        version: sendable.version,
        steps: sendable.steps.map((step) => step.toJSON()),
        clientID: sendable.clientID
      };
      this.options.onSendableReceived({ sendable, jsonSendable });
    }
  }
};
__decorateClass([
  command()
], CollaborationExtension.prototype, "sendCollaborationUpdate", 1);
__decorateClass([
  command()
], CollaborationExtension.prototype, "cancelSendableSteps", 1);
__decorateClass([
  command()
], CollaborationExtension.prototype, "flushSendableSteps", 1);
CollaborationExtension = __decorateClass([
  extension({
    defaultOptions: {
      version: 0,
      clientID: uniqueId(),
      debounceMs: 250
    },
    staticKeys: ["version", "clientID", "debounceMs"],
    handlerKeys: ["onSendableReceived"]
  })
], CollaborationExtension);
var isValidCollaborationAttributes = (attributes) => {
  return !(!attributes || !isArray(attributes.steps) || !isNumber(attributes.version));
};
export {
  CollaborationExtension
};
