import { useMutate } from '@cotiss/common'

export type UpdateTenderCriteriaBulkItem = {
  tenderCriteriaId?: string
  body: {
    index: number
    name: string
    weight: number
  }
}

type UpdateTenderCriteriaBulkBody = {
  tenderId: string
  items: UpdateTenderCriteriaBulkItem[]
}

export const useMutateTenderCriteria = () => {
  const { mutate } = useMutate()

  return {
    updateTenderCriteriaBulk: async (body: UpdateTenderCriteriaBulkBody) => {
      return await mutate({ route: `/v2/tender-criteria/bulk`, body, invalidate: ['/v2/tender-criteria', '/v2/procurements'] })
    },
  }
}
