import React, { memo } from 'react'
import { Button, Icon, routerService } from '@cotiss/common'
import { ApprovalModel } from '@cotiss/approval'

type Props = {
  approval: ApprovalModel
}

export const TaskListApprovalCta = memo(({ approval }: Props) => {
  return (
    <Button href={routerService.getHref('/approval/view/:id', { id: approval._id })} state="outline" variant="secondary" size="xs" isLink>
      View <Icon className="ml-1" icon="arrow-right" />
    </Button>
  )
})
