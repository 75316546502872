import { filter, map, sortBy } from 'lodash'
import React, { memo, useMemo } from 'react'
import { Text, Hr, TableHeader, utilService } from '@cotiss/common'
import {
  EvaluationEventParentTerminalCriteria,
  EvaluationEventSubCriteriaList,
  evaluationEventService,
  useEvaluationCriteria,
  useEvaluationEnvelope,
} from '@cotiss/evaluation-event'

export const EvaluationEventSummaryCriteria = memo(() => {
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { weightedPercentageById, overallWeightedPercentageById } = useMemo(() => {
    return evaluationEventService.getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria })
  }, [evaluationEnvelopes, evaluationCriteria])

  return (
    <>
      {map(evaluationEnvelopes, (evaluationEnvelope, index) => {
        const { id, name, order } = evaluationEnvelope
        const parentCriteria = sortBy(filter(evaluationCriteria, { evaluationEnvelopeId: id, parentEvaluationCriteriaId: null }), 'index')

        return (
          <div className={index ? 'mt-8' : ''} key={id}>
            <div className="flex justify-between">
              <div>
                <Text size="sm" variant="light">
                  Envelope {order}.
                </Text>
                <Text className="font-medium" size="lg">
                  {name}
                </Text>
              </div>
              <div>
                <Text size="sm" variant="light">
                  Envelope weight
                </Text>
                <Text className="text-right">
                  {weightedPercentageById[id] ? utilService.formatAsPercentage(weightedPercentageById[id] * 100) : '--'}
                </Text>
              </div>
            </div>
            <Hr className="my-4" />

            {map(parentCriteria, (parentEvaluationCriteriaItem) => {
              const { id: parentEvaluationCriteriaId, content, index, isScored } = parentEvaluationCriteriaItem

              return (
                <React.Fragment key={parentEvaluationCriteriaId}>
                  <TableHeader className="mt-8">
                    <Text size="sm" variant="light">
                      Criteria {index}
                    </Text>
                    <Text className="font-semibold">{content}</Text>
                  </TableHeader>
                  {isScored ? (
                    <EvaluationEventParentTerminalCriteria
                      evaluationEnvelope={evaluationEnvelope}
                      evaluationCriteriaItem={parentEvaluationCriteriaItem}
                      weightedPercentageById={weightedPercentageById}
                      overallWeightedPercentageById={overallWeightedPercentageById}
                    />
                  ) : (
                    <EvaluationEventSubCriteriaList
                      evaluationEnvelope={evaluationEnvelope}
                      parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                      weightedPercentageById={weightedPercentageById}
                      overallWeightedPercentageById={overallWeightedPercentageById}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </div>
        )
      })}
    </>
  )
})
