import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import {
  GqlApprovalTemplateFieldsFragment,
  GqlUpdateApprovalTemplateInput,
  GqlUpdateApprovalTemplateMutation,
  GqlUpdateApprovalTemplateMutationVariables,
} from '@gql'

export const mutateUpdateApprovalTemplate = async (input: GqlUpdateApprovalTemplateInput) => {
  const { data } = await apolloService.mutate<GqlUpdateApprovalTemplateMutation, GqlUpdateApprovalTemplateMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      mutation UpdateApprovalTemplate($input: UpdateApprovalTemplateInput!) {
        updateApprovalTemplate(input: $input) {
          ...ApprovalTemplateFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateApprovalTemplate as GqlApprovalTemplateFieldsFragment
}
