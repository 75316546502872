import React, { useState, memo, useEffect } from 'react'
import { DOCUMENT_FILE_TYPE, DocumentUpload } from '@cotiss/document'
import { useGetSsoConfiguration, useMutateAuth, SsoConfigurationModel } from '@cotiss/auth'
import { Label, sentryService, useToast, TextArea, useAnalytics, Text, Button, Drawer, useCallout } from '@cotiss/common'

export const SsoCertificateEditDrawer = memo(() => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeNarrowDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateSsoConfiguration } = useMutateAuth()
  const { configurations } = useGetSsoConfiguration()
  const [certificateText, setCertificateText] = useState('')
  const [configuration, setConfiguration] = useState<SsoConfigurationModel | null>(null)

  useEffect(() => {
    const configuration = configurations?.length ? configurations[0] : null

    if (!configuration) {
      return
    }

    setConfiguration(configuration)
    setCertificateText(configuration.x509Certificate ? window.atob(configuration.x509Certificate) : '')
  }, [configurations])

  const handleSubmit = async () => {
    if (!certificateText || !configuration) {
      //  The ui should prevent this
      return
    }

    setIsSaving(true)

    try {
      track('sso_configuration_update_submit')
      const encodedCert = window.btoa(certificateText)
      await updateSsoConfiguration(configuration.id, { x509Certificate: encodedCert })
      setIsSaving(false)
      closeNarrowDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  const handleUpload = async (file: File) => {
    const reader = new FileReader()

    reader.onload = async () => {
      const text = reader.result

      if (typeof text !== 'string') {
        openToast('Something went wrong. Please try upload again.')
        return
      }

      setCertificateText(text)
    }

    reader.readAsText(file)
  }

  const renderHeader = () => (
    <div>
      <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
        {configuration ? 'Edit' : 'Add'} certificate
      </Text>
      <Text size="sm" variant="light">
        Paste certificate or upload .cert file below
      </Text>
    </div>
  )

  const renderFooter = () => (
    <Button type="submit" variant="secondary" className="mr-2" isLoading={isSaving} isDisabled={!certificateText}>
      Confirm
    </Button>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <Label>Certificate</Label>
      <TextArea className="h-64 text-xs" autoFocus isRequired value={certificateText} onChange={({ target }) => setCertificateText(target.value)} />
      <DocumentUpload showUploadedFiles={false} onUpload={handleUpload} className="mt-4" acceptedFileTypes={DOCUMENT_FILE_TYPE.document} />
    </Drawer>
  )
})
