class ApiService {
  async get<T>(route: string, allowError?: false): Promise<T>
  async get<T>(route: string, allowError: true): Promise<T | null>
  async get<T>(route: string, allowError = false) {
    try {
      const response = await fetch(`${process.env.API_DOMAIN}${route}`)
      const data: T = await response.json()

      return data
    } catch (error: any) {
      if (allowError) {
        return null
      }

      throw error
    }
  }

  post = async <T>(route: string, body: Record<string, any>) => {
    const response = await fetch(`${process.env.API_DOMAIN}${route}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message)
    }

    if (response.status !== 204) {
      return (await response.json()) as T
    }
  }
}

export const apiService = new ApiService()
