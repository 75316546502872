import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT = gql`
  fragment PerformanceScorecardMetricCycleScoreFields on PerformanceScorecardMetricCycleScore {
    id
    performanceScorecardMetricCycleId
    performanceScorecardUserId
    value
    comment
    status
    submittedAt
    createdAt
    updatedAt
    deletedAt
  }
`
