import { Form, ModalContent, ModalFooter, ModalHeader, useCallout } from '@cotiss/common'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { ForumQuestionModel } from '@cotiss/forum'
import React, { FormEvent, memo, useState } from 'react'

type Props = {
  forumQuestion: ForumQuestionModel
  onSubmit: (documents: DocumentModel[]) => void
}

export const ForumResponseDocumentUploadModal = memo(({ onSubmit }: Props) => {
  const { closeModal } = useCallout()
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(attachments)
    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload documents" />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
