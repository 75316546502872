import { find, map } from 'lodash'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { TableColumn, Text, datetimeService, Icon, useCallout, TableRowCta, ConfirmModal, NoDataPlaceholder, Tooltip, Table } from '@cotiss/common'
import { ContractOwnerModel, useGetContractShell, useMutateContractShell } from '@cotiss/contract'
import { useGetLoggedInUser, userService } from '@cotiss/user'

type Props = {
  contractShellId: string
  contractId: string
  isEditable?: boolean
  showTableHeader?: boolean
  className?: string
}

export const ContractOwnersTable = memo(({ contractShellId, contractId, isEditable, className = '' }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateContractMetadata } = useMutateContractShell()

  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const classes = classNames(className)

  const metadata = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract._id === contractId)
    return contract?.metadata
  }, [contractShell, contractId])

  const owners = useMemo(() => {
    return map(metadata?.owners, (owner) => {
      return {
        ...owner,
        email: owner.email,
        createdAt: owner.createdAt,
      }
    })
  }, [metadata])

  const handleDeleteContractOwner = async (owner: ContractOwnerModel) => {
    if (!metadata) {
      return
    }

    await updateContractMetadata(contractShellId, contractId, metadata?._id, {
      owners: map(
        owners.filter(({ _id }) => _id !== owner._id),
        ({ _id }) => _id
      ),
    })
  }

  if (!isLoading && !owners.length) {
    return <NoDataPlaceholder label="No contract owners assigned" />
  }

  const columns: TableColumn[] = [
    {
      heading: 'Name',
      rows: map(owners, (owner) => {
        const isOwner = owner._id !== user?._id

        return {
          content: () => <Text className="truncate">{userService.getFullName(owner)}</Text>,
          cta: isEditable ? (
            <>
              {!isOwner && (
                <Tooltip className="top-2 right-2" tooltipClassName="text-center" tooltip="You cannot remove yourself as an owner" isAbsolute>
                  <Icon icon="lock" variant="light" />
                </Tooltip>
              )}
              {isOwner && (
                <TableRowCta
                  actions={[
                    {
                      label: 'Delete',
                      onClick: () => {
                        openModal(
                          <ConfirmModal
                            heading="Delete contract owner"
                            description="Are you sure you want to delete this contract owner?"
                            onSubmit={() => handleDeleteContractOwner(owner)}
                          />
                        )
                      },
                    },
                  ]}
                />
              )}
            </>
          ) : null,
        }
      }),
    },
    {
      heading: 'Email',
      rows: map(owners, ({ email }) => ({
        content: () => (
          <div className="flex items-center truncate">
            <Text className="truncate">{email}</Text>
          </div>
        ),
      })),
    },
    {
      heading: 'Date',
      rows: map(owners, ({ createdAt }) => ({
        content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
      })),
    },
  ]

  return <Table className={classes} columns={columns} isLoading={isLoading} />
})
