import { Button, useCallout } from '@cotiss/common'
import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
  isForm?: boolean
  isSaving?: boolean
  isDisabled?: boolean
}

export const ModalFooter = memo(({ className, children, isForm, isSaving, isDisabled }: Props) => {
  const { closeModal } = useCallout()
  const classes = classNames(className, 'bg-white border-t border-gray-100 rounded-b-lg py-4 px-10 w-full', {
    'flex justify-end': isForm,
  })

  return (
    <div className={classes}>
      {isForm ? (
        <>
          <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={isDisabled}>
            Save
          </Button>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  )
})
