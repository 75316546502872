import { UserPermission } from '@cotiss/user'

export const USER_QUERY_KEYS = ['/v2/users/me', '/v2/users/roles'] as const

export const USER_PERMISSION_MAP: Record<UserPermission, string> = {
  ADMIN: 'Admin',
  APPROVER: 'Approver',
  BID_MANAGER: 'Bid Manager',
  CONTRACT_MANAGER: 'Contract Manager',
  EVALUATOR: 'Evaluator',
  LEGAL: 'Legal',
  PERFORMANCE_MANAGER: 'Performance Manager',
  PROCUREMENT_MANAGER: 'Procurement Manager',
}

export const USER_ROLE_DESCRIPTION: Record<UserPermission, string> = {
  ADMIN: 'Manage all account settings and has access to all features for your account.',
  APPROVER: 'Access the service desk to approve procurement stages and contract variations.',
  BID_MANAGER: 'Access responses and update your supplier profile.',
  CONTRACT_MANAGER: 'View and edit all contracts.',
  EVALUATOR: 'Access the service desk to chair or evaluate procurement stages.',
  LEGAL: 'View and resolve conflicts of interest.',
  PERFORMANCE_MANAGER: 'Access the entire performance module and create new scorecards.',
  PROCUREMENT_MANAGER: 'Access the entire procurement module and create new evaluation events.',
}
