import React, { useState, memo } from 'react'
import { ContractWizardScheduleRatesModal, ScheduledRateModel } from '@cotiss/contract'
import { DropdownContent, Dropdown, Button, Icon, useCallout, ConfirmModal } from '@cotiss/common'

type Props = {
  contractShellId: string
  scheduledRate: ScheduledRateModel
  onDelete: (id: string) => void
}

export const ContractScheduledRateCta = memo(({ contractShellId, scheduledRate, onDelete }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className="relative flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="fixed top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() => openModal(<ContractWizardScheduleRatesModal contractShellId={contractShellId} scheduledRate={scheduledRate} />)}
        >
          Edit
        </DropdownContent>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(
              <ConfirmModal heading="Delete" description="Are you sure you want to delete this rate?" onSubmit={() => onDelete(scheduledRate._id)} />
            )
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </div>
  )
})
