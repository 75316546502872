import { useFetch, PaginatedModel } from '@cotiss/common'
import { GroupEvaluationModel } from '@cotiss/group-evaluation'

type Props = {
  tenderResponseId?: string
  limit?: number
  offset?: number
}

export const useListGroupEvaluation = ({ tenderResponseId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<GroupEvaluationModel>>('/v2/group-evaluations', {
    route: `/v2/group-evaluations`,
    params: { tenderResponseId, offset, limit },
    isEnabled: Boolean(tenderResponseId),
  })

  return { groupEvaluations: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
