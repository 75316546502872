import { filter, findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useListMetafield } from '@cotiss/metafield'
import { Banner, Icon, TransitionContainer, VerticalTabModel, VerticalTabs, routerService, useTransition, Text } from '@cotiss/common'
import {
  ProcurementOverviewBackgroundAttachmentsTab,
  ProcurementOverviewBackgroundGeneralTab,
  ProcurementOverviewBackgroundMarketAnalysisTab,
  ProcurementOverviewBackgroundMetafieldsTab,
} from '@cotiss/procurement'

export type ProcurementOverviewBackgroundTab = 'general' | 'attachments' | 'market-analysis' | 'metafields'

export const ProcurementOverviewBackgroundTab = memo(() => {
  const { push, replace } = useHistory()
  const { metafields } = useListMetafield({ entityType: 'PROCUREMENT_PLAN' })
  const { procurementId, tab, nestedTab } = useParams<{ procurementId: string; tab: string; nestedTab?: ProcurementOverviewBackgroundTab }>()

  const tabs = useMemo(() => {
    const tabs: VerticalTabModel<ProcurementOverviewBackgroundTab>[] = [
      { id: 'general', label: 'General' },
      { id: 'attachments', label: 'Attachments' },
      { id: 'market-analysis', label: 'Market analysis' },
      { id: 'metafields', label: 'Custom fields', isHidden: !metafields.length },
    ]

    return filter(tabs, (tab) => !tab.isHidden)
  }, [metafields])

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  useEffect(() => {
    if (!nestedTab) {
      replace(
        routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', {
          procurementId,
          tab,
          nestedTab: tabs[0].id,
        })
      )
    }

    const newStep = findIndex(tabs, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [nestedTab])

  const activeTabId = tabs[step - 1].id

  return (
    <>
      <Banner className="relative flex items-center mb-8" variant="secondary">
        <div className="flex items-center justify-center bg-white rounded-md w-9 h-9 mr-4">
          <Icon icon="invisible" variant="secondary" size={20} />
        </div>
        <div>
          <Text className="font-semibold" variant="heading">
            The below information is for internal reference only.
          </Text>
          <Text size="sm">External vendors and those outside of your organisation will not have access to this information.</Text>
        </div>
      </Banner>
      <div className="flex items-start">
        <VerticalTabs<ProcurementOverviewBackgroundTab>
          className="sticky top-28 mr-8"
          tab={nestedTab}
          tabs={tabs}
          onChange={({ id: nestedTab }) =>
            push(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab, nestedTab }))
          }
        />

        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} className="w-full max-w-5xl" transition={transition}>
            {activeTabId === 'general' && <ProcurementOverviewBackgroundGeneralTab />}
            {activeTabId === 'attachments' && <ProcurementOverviewBackgroundAttachmentsTab />}
            {activeTabId === 'market-analysis' && <ProcurementOverviewBackgroundMarketAnalysisTab />}
            {activeTabId === 'metafields' && <ProcurementOverviewBackgroundMetafieldsTab />}
          </TransitionContainer>
        </AnimatePresence>
      </div>
    </>
  )
})
