import {
  Badge,
  Button,
  ButtonReadMore,
  Card,
  CardHeader,
  EditableField,
  Field,
  Form,
  Icon,
  NoDataPlaceholder,
  OCDS_CURRENCY_MAP,
  RegionsModal,
  Skeleton,
  TableHeader,
  Text,
  UnspscCategoriesModal,
  regionService,
  sentryService,
  useAnalytics,
  useCallout,
  useFeature,
  useToast,
} from '@cotiss/common'
import {
  ContractAssociations,
  ContractEditContactsDrawer,
  ContractOwnerAddModal,
  ContractOwnersTable,
  ContractStepCardSkeletonLoading,
  ContractSupportingDocumentsTable,
  ContractWizardStepMetadataEditModal,
  ContractWizardStepSupportDocumentsUploadModal,
  useGetContractShell,
  useMutateContractShell,
} from '@cotiss/contract'
import { MetafieldForm, useListMetafield } from '@cotiss/metafield'
import { useListMetafieldValue } from '@cotiss/metafield-value'
import classNames from 'classnames'
import { filter, find, map } from 'lodash'
import React, { FormEvent, memo, useEffect, useMemo, useRef } from 'react'

type Props = {
  contractShellId: string
  onNext: () => void
  onBack: (() => void) | null
}

export const ContractWizardMetadataStep = memo(({ contractShellId, onNext, onBack }: Props) => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const shortDescriptionRef = useRef<HTMLDivElement>(null)
  const { openModal, closeModal, openDrawer } = useCallout()
  const { updateContractMetadata } = useMutateContractShell()
  const { metafields } = useListMetafield({ entityType: 'CONTRACT' })
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const isEsCoreFieldDescriptorsEnabled = useFeature('es-core-field-descriptors')
  const { contract, supportingDocumentShells } = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract.status === 'DRAFTING')
    const supportingDocumentShells = filter(contract?.documentShells, { type: 'SUPPORTING' })
    return { contract, supportingDocumentShells }
  }, [contractShell])
  const { metafieldValues } = useListMetafieldValue({ resourceId: contract?._id })

  useEffect(() => {
    track('contract_wizard_metadata_view')
  }, [])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onNext()
  }

  const handleSaveContractMetadata = async ({ categories, regions }: { categories?: string[]; regions?: string[] }) => {
    if (!contractShell || !contract || !contract.metadata) {
      return
    }
    try {
      await updateContractMetadata(contractShell._id, contract._id, contract.metadata._id, {
        categories,
        regions,
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
    }
  }

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="px-8">
          <div className="py-6 border-grey border-b">
            {map(Array(3), (_, index) => (
              <div className="flex mb-6 last:mb-0" key={index}>
                <Skeleton className="bg-primary-200 h-6 w-1/4 mr-24" />
                <Skeleton className="bg-primary-200 h-6 w-1/2" />
              </div>
            ))}
          </div>
          <div className="py-6">
            {map(Array(5), (_, index) => (
              <div className="flex mb-6 last:mb-0" key={index}>
                <Skeleton className="bg-primary-200 h-6 w-1/4 mr-24" />
                <Skeleton className="bg-primary-200 h-6 w-1/3" />
              </div>
            ))}
          </div>
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell || !contract) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
              Terms & metadata
            </Text>
          </div>
          <div className="ml-4">
            {onBack && (
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading}>
                Back
              </Button>
            )}
            <Button type="submit" variant="secondary" size="sm" isDisabled={isLoading}>
              Continue
            </Button>
          </div>
        </CardHeader>
        <div className="flex">
          <div className={classNames('px-8', { 'w-2/3': !contract.variationTypes.length, 'w-full': contract.variationTypes.length })}>
            {/* Only allow these fields to be edited on the initial contract */}
            {!contract.variationTypes.length && (
              <div className="flex flex-col py-6 gap-2 border-grey border-b">
                <EditableField
                  label="Contract shell title"
                  textValue={contractShell.title}
                  supplementary={
                    isEsCoreFieldDescriptorsEnabled &&
                    'Contact Name, Execution date: YYYYMMDD, Agreement type (SoW, Contract, Variation), Activity Name'
                  }
                  onClick={() =>
                    openModal(
                      <ContractWizardStepMetadataEditModal
                        contractId={contract._id}
                        label="Contract shell title"
                        field="contractShellTitle"
                        supplementary={
                          isEsCoreFieldDescriptorsEnabled &&
                          'Contact Name, Execution date: YYYYMMDD, Agreement type (SoW, Contract, Variation), Activity Name'
                        }
                        contractShell={contractShell}
                      />
                    )
                  }
                  isTruncated
                />
                <EditableField
                  label="Short description"
                  className="mt-2"
                  onClick={() =>
                    openModal(
                      <ContractWizardStepMetadataEditModal
                        contractId={contract._id}
                        label="Short description"
                        field="shortDescription"
                        contractShell={contractShell}
                      />
                    )
                  }
                >
                  {contractShell.description ? (
                    <div ref={shortDescriptionRef} className="whitespace-pre-wrap line-clamp-2 overflow-hidden">
                      {contractShell.description}
                    </div>
                  ) : null}
                </EditableField>
                {/* If the short description is long, this button will appear to help the user read all the text */}
                <Field label="" className="ml-4 -mt-2">
                  <ButtonReadMore modalContent={contractShell.description} modalTitle="Short description" readMoreRef={shortDescriptionRef} />
                </Field>
                {contractShell.procurement && (
                  <EditableField isDisabled label="Procurement title" className="mt-2" textValue={contractShell.procurement.title || '--'} />
                )}
              </div>
            )}
            <div className="flex flex-col gap-2 py-6">
              {/* Only allow this field to be edited on the initial contract - disable on variation  */}
              <EditableField
                label="Counterparties"
                isDisabled={Boolean(contract.variationTypes.length)}
                textValue={map(contract?.metadata?.suppliers, ({ name }) => name).join(', ')}
                onClick={() => openDrawer(<ContractEditContactsDrawer contractShellId={contractShellId} contractId={contract._id} />)}
              />
              {/* Only allow this field to be edited on the initial contract - disable on variation  */}
              <EditableField
                label="Contracting entity"
                className="mt-2"
                isDisabled={Boolean(contract.variationTypes.length)}
                textValue={contract?.metadata?.contractingEntity?.name}
                onClick={() =>
                  openModal(
                    <ContractWizardStepMetadataEditModal
                      contractId={contract._id}
                      label="Contracting entity"
                      field="contractingEntity"
                      contractShell={contractShell}
                    />
                  )
                }
              />
              <EditableField
                label="Contract category"
                className="mt-2"
                onClick={() =>
                  openModal(
                    <UnspscCategoriesModal
                      selectedCategoryCodes={map(contract?.metadata?.categories, (category) => category._id)}
                      onChange={(categories) => handleSaveContractMetadata({ categories })}
                    />
                  )
                }
              >
                {contract?.metadata?.categories.length ? (
                  <div className="w-96">
                    {map(contract?.metadata?.categories, (category) => (
                      <Badge
                        title={category.description}
                        key={category._id}
                        className="truncate max-w-[140px] mr-2"
                        state="translucent"
                        variant="secondary"
                        size="sm"
                      >
                        {category.description}
                      </Badge>
                    ))}
                  </div>
                ) : null}
              </EditableField>
              <EditableField
                label="Region(s)"
                onClick={() =>
                  openModal(
                    <RegionsModal selectedRegions={contract.metadata.regions} onChange={(regions) => handleSaveContractMetadata({ regions })} />
                  )
                }
              >
                {contract?.metadata?.regions.length ? (
                  <div className="w-96">
                    {map(regionService.processRegions(contract.metadata.regions), (region) => (
                      <Badge
                        title={region.label}
                        key={region.value}
                        className="truncate max-w-[140px] mr-2"
                        state="translucent"
                        variant="secondary"
                        size="sm"
                      >
                        {region.label}
                      </Badge>
                    ))}
                  </div>
                ) : null}
              </EditableField>
              <EditableField
                label="Currency"
                textValue={contract?.metadata?.currency ? OCDS_CURRENCY_MAP[contract?.metadata?.currency] : '--'}
                className="mt-2"
                onClick={() =>
                  openModal(
                    <ContractWizardStepMetadataEditModal contractId={contract._id} label="Currency" field="currency" contractShell={contractShell} />
                  )
                }
              ></EditableField>
              <EditableField label="Contract ID" isDisabled textValue={contract?.metadata?.internalReference} />
              <EditableField
                label="Internal reference"
                textValue={contract?.metadata?.externalReference}
                supplementary={isEsCoreFieldDescriptorsEnabled && 'Objective URL'}
                onClick={() =>
                  openModal(
                    <ContractWizardStepMetadataEditModal
                      contractId={contract._id}
                      label="Internal reference"
                      field="internalReference"
                      supplementary={isEsCoreFieldDescriptorsEnabled && 'Objective URL'}
                      contractShell={contractShell}
                    />
                  )
                }
              />
              <div>
                <TableHeader className="mt-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <Text className="font-semibold" font="jakarta">
                        Contract owners
                      </Text>
                      <Text className="mt-1" variant="light" size="sm">
                        These users will have editing access to this contract
                      </Text>
                    </div>
                    <Button
                      size="xs"
                      variant="secondary"
                      onClick={() => openModal(<ContractOwnerAddModal contractShellId={contractShellId} contractId={contract._id} />)}
                    >
                      <Icon icon="plus" />
                      Add owner
                    </Button>
                  </div>
                </TableHeader>
                <ContractOwnersTable contractShellId={contractShellId} contractId={contract._id} isEditable />
              </div>
              {!supportingDocumentShells.length && (
                <NoDataPlaceholder
                  className="mt-4"
                  label="No supporting documents added"
                  illustration="dot-list"
                  ctaLabel="Upload document"
                  onCtaClick={() => openModal(<ContractWizardStepSupportDocumentsUploadModal contractShellId={contractShellId} />)}
                />
              )}
              {Boolean(supportingDocumentShells.length) && (
                <ContractSupportingDocumentsTable
                  className="mt-4"
                  documentShells={supportingDocumentShells}
                  contractShellId={contractShellId}
                  contractId={contract._id}
                  isEditable
                />
              )}
            </div>
            {Boolean(metafields.length) && (
              <div className="flex flex-col py-6 gap-2 border-grey border-t">
                {map(metafields, (metafield) => (
                  <MetafieldForm key={metafield._id} resourceId={contract._id} metafield={metafield} metafieldValues={metafieldValues} isEditable />
                ))}
              </div>
            )}
          </div>
          {!contract.variationTypes.length && (
            <div className="px-8 border-l w-1/3">
              <ContractAssociations contractShellId={contractShellId} isEditable />
            </div>
          )}
        </div>
      </Card>
    </Form>
  )
})
