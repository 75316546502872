import { useFetch, PaginatedModel } from '@cotiss/common'
import { TenderCriterionModel } from '@cotiss/tender-criteria'

type Props = {
  tenderId?: string
  limit?: number
  offset?: number
}

export const useListTenderCriteria = ({ tenderId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderCriterionModel>>('/v2/tender-criteria', {
    route: `/v2/tender-criteria`,
    params: { tenderId, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { tenderCriteria: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
