import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT = gql`
  fragment EvaluationEnvelopeDocumentFields on EvaluationEnvelopeDocument {
    id
    evaluationEnvelopeId
    evaluationUserId
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
