import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_USER_FRAGMENT } from '@cotiss/approval-template-user'
import {
  GqlApprovalTemplateUserFieldsFragment,
  GqlCreateApprovalTemplateUserInput,
  GqlCreateApprovalTemplateUserMutation,
  GqlCreateApprovalTemplateUserMutationVariables,
} from '@gql'

export const mutateCreateApprovalTemplateUser = async (input: GqlCreateApprovalTemplateUserInput) => {
  const { data } = await apolloService.mutate<GqlCreateApprovalTemplateUserMutation, GqlCreateApprovalTemplateUserMutationVariables>({
    mutation: gql`
      ${APPROVAL_TEMPLATE_USER_FRAGMENT}

      mutation CreateApprovalTemplateUser($input: CreateApprovalTemplateUserInput!) {
        createApprovalTemplateUser(input: $input) {
          ...ApprovalTemplateUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createApprovalTemplateUser as GqlApprovalTemplateUserFieldsFragment
}
