import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { DocumentList } from '@cotiss/document'
import { TenderResponsePriceTable, useGetTenderResponse, useMutateTenderResponse } from '@cotiss/tender-response'
import { Breadcrumb, BreadcrumbModel, Button, Card, CardHeader, Form, Icon, Input, Text, sentryService, useToast } from '@cotiss/common'
import { RATING_SCALE_VALUE_MAP } from '@cotiss/tender/tender.constants'

type Props = {
  tenderResponseId: string
  breadcrumbs?: BreadcrumbModel[]
  isEditable?: boolean
}

export const TenderResponsePriceScore = memo(({ tenderResponseId, breadcrumbs: rootBreadcrumbs, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { tenderResponse, isLoading } = useGetTenderResponse(tenderResponseId)
  const [priceScore, setPriceScore] = useState(tenderResponse?.priceScore?.toString() || '')
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return rootBreadcrumbs ? [...rootBreadcrumbs, { label: tenderResponse?.procurementResponse.supplier.name || '', isLoading: !tenderResponse }] : []
  }, [rootBreadcrumbs, tenderResponse])

  useEffect(() => {
    if (isLoading) {
      return
    }

    setPriceScore(tenderResponse?.priceScore?.toString() || '')
  }, [tenderResponse])

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponseId, { priceScoreConsensusStatus: 'inProgress' })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      if (tenderResponse?.tender.isPriceWeighted) {
        await updateTenderResponse(tenderResponseId, {
          priceScoreConsensusStatus: 'completed',
          priceScore: Number(Number(priceScore).toFixed(2)),
        })
      } else {
        await updateTenderResponse(tenderResponseId, { priceScoreConsensusStatus: 'completed' })
      }

      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
      <Card>
        <CardHeader>
          <Text className="font-semibold" size="h7" font="jakarta">
            {tenderResponse?.procurementResponse.supplier.name}
          </Text>
        </CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <Text className="font-semibold" size="h7" font="jakarta">
              Price documents
            </Text>
            <Text className="mt-1" variant="light" size="sm">
              Download and review documents to conduct evaluation process
            </Text>
          </div>
          <Form className="flex items-center" onSubmit={handleSubmit}>
            {tenderResponse?.tender.isPriceWeighted && (
              <>
                <Text className="font-medium text-sm whitespace-nowrap mr-2">Price score:</Text>
                <Input
                  className="w-[80px]"
                  value={priceScore}
                  onChange={({ target }) => setPriceScore(target.value)}
                  type="number"
                  min={tenderResponse.tender.ratingScale ? RATING_SCALE_VALUE_MAP[tenderResponse.tender.ratingScale].min : 0}
                  max={tenderResponse.tender.ratingScale ? RATING_SCALE_VALUE_MAP[tenderResponse.tender.ratingScale].max : 10}
                  step={0.01}
                  size="sm"
                  isFull={false}
                  isDisabled={!isEditable || tenderResponse?.priceScoreConsensusStatus === 'completed' || isSaving}
                  isRequired
                />
              </>
            )}
            {!isLoading && isEditable && tenderResponse?.priceScoreConsensusStatus !== 'completed' && (
              <Button className="ml-2" type="submit" size="sm" isDisabled={isSaving}>
                Mark as complete
              </Button>
            )}
            {!isLoading && isEditable && tenderResponse?.priceScoreConsensusStatus === 'completed' && (
              <div className="flex items-center justify-between bg-gray-100 rounded-lg w-full p-2 ml-2">
                <div className="flex items-center">
                  <Icon className="shrink-0 mr-2" icon="check-circle" variant="success" size={20} />
                  <Text>Marked as complete.</Text>
                </div>
                <Button className="ml-4" onClick={handleEdit} state="text" variant="link">
                  Edit
                </Button>
              </div>
            )}
          </Form>
        </div>
        <DocumentList className="mt-8" title="Price documents" documents={tenderResponse?.priceAttachments} isLoading={isLoading} />
        <DocumentList
          className="mt-8"
          title="Clarification documents"
          documents={tenderResponse?.priceAttachmentsClarification}
          isLoading={isLoading}
        />

        {tenderResponse?.tender?.priceTableEnabled && (
          <>
            <TenderResponsePriceTable
              className="mt-8"
              tenderResponseId={tenderResponseId}
              isNpvVisible={tenderResponse?.tender.priceTableRequirePaymentDate}
            />
          </>
        )}
      </Card>
    </>
  )
})
