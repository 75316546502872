import React, { memo, useEffect, useMemo, useState } from 'react'
import { compact, filter, find, includes, map, uniq } from 'lodash'
import { UserAvatarGroup } from '@cotiss/user'
import { organisationService } from '@cotiss/organisation'
import { useGetContractShell, useMutateContractShell } from '@cotiss/contract'
import { FilterPreferredSupplierSortKey, PreferredSupplierPopulatedModel, useMutatePreferredSupplier } from '@cotiss/preferred-supplier'
import {
  Badge,
  Button,
  Checkbox,
  Drawer,
  FilterDrawer_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  Filter_DEPRECATED,
  Icon,
  PaginationModel,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  Text,
  Tooltip,
  datetimeService,
  filterService_DEPRECATED,
  sentryService,
  sortService,
  useCallout,
  useSortTable,
  useToast,
} from '@cotiss/common'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractEditContactsDrawer = memo(({ contractShellId, contractId }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { openNarrowDrawer, closeDrawer } = useCallout()
  const { updateContractMetadata } = useMutateContractShell()
  const { contractShell } = useGetContractShell(contractShellId)
  const [pagination, setPagination] = useState<PaginationModel>()
  const { filterPreferredSupplier } = useMutatePreferredSupplier()
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([])
  const [filterFields, setFilterFields] = useState<FilterFieldOptions_DEPRECATED>({})
  const [preferredSuppliers, setPreferredSupplier] = useState<PreferredSupplierPopulatedModel[]>([])
  const [filters, setFilters] = useState<Array<Filter_DEPRECATED>>(filterService_DEPRECATED.getFiltersFromUrl())
  const { sortKey, sortDirection, onSort } = useSortTable<FilterPreferredSupplierSortKey>({ initialKey: 'organisationName' })
  const contract = useMemo(() => find(contractShell?.contracts, ({ _id }) => _id === contractId), [contractShell, contractId])

  const processedFilters = useMemo(() => {
    return [
      ...filters,
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
    ] as Filter_DEPRECATED[]
  }, [filters])

  useEffect(() => {
    if (!contract) {
      return
    }

    setSelectedContactIds(map(contract.metadata.suppliers, '_id'))
  }, [contract])

  const refreshPreferredSuppliers = async () => {
    try {
      setIsLoading(true)

      const { preferredSuppliers, meta, pagination } = await filterPreferredSupplier({
        filters: processedFilters,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setPreferredSupplier(preferredSuppliers)
      setPagination(pagination)

      // Remove archived filter from the UI; the user cannot override it so they needn't see it in the UI
      Object.hasOwn(meta, 'archived') && delete meta.archived

      setFilterFields(meta)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    refreshPreferredSuppliers()
  }, [processedFilters, currentPage, sortKey, sortDirection])

  const handleSubmit = async () => {
    if (!contractShell || !contract || !selectedContactIds.length) {
      return
    }

    setIsSaving(true)
    try {
      await updateContractMetadata(contractShell._id, contract._id, contract.metadata._id, {
        suppliers: selectedContactIds,
      })

      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  const handlePreferredSupplierChange = (preferredSupplier: PreferredSupplierPopulatedModel) => {
    if (!preferredSupplier.supplierOrganisation?._id) {
      return
    }

    if (includes(selectedContactIds, preferredSupplier.supplierOrganisation._id)) {
      setSelectedContactIds(filter(selectedContactIds, (selectedContactId) => selectedContactId !== preferredSupplier.supplierOrganisation?._id))
    } else {
      setSelectedContactIds(uniq(compact([...selectedContactIds, preferredSupplier.supplierOrganisation._id])))
    }
  }

  const handleSetFilters = (filters: Filter_DEPRECATED[]) => {
    setCurrentPage(1)
    setFilters(filters)
  }

  const { fixedColumns, columns } = useMemo(() => {
    const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
      if (!preferredSupplier.tags?.length) {
        return <Text size="sm">--</Text>
      }

      const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

      return (
        <>
          <Badge className="mr-1" state="outline" variant="secondary">
            {firstTag}
          </Badge>
          {Boolean(rest.length) && (
            <Tooltip tooltip={rest.join(', ')}>
              <Text className="font-medium" variant="link" size="sm">
                +{rest.length}
              </Text>
            </Tooltip>
          )}
        </>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Checkbox
              value={preferredSupplier._id}
              name="evaluation-event-submission-contact"
              onChange={() => handlePreferredSupplierChange(preferredSupplier)}
              isChecked={includes(selectedContactIds, preferredSupplier.supplierOrganisation?._id)}
              isDisabled={isSaving}
            />
          ),
        })),
      },
      {
        heading: 'Name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={preferredSupplier.supplierOrganisation?.name || '--'}>
              {preferredSupplier.supplierOrganisation?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [preferredSuppliers, selectedContactIds, isSaving])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      Associate counterparties
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button variant="secondary" type="submit" isLoading={isSaving} isDisabled={!selectedContactIds.length || isLoading}>
        Submit
      </Button>
      <Text className="ml-2">{selectedContactIds.length} selected</Text>
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <TableHeader className="flex items-center justify-between">
        <Text className="font-medium" size="lg">
          Select contact
        </Text>
        <Button
          size="xs"
          variant="secondary"
          state="outline"
          isDisabled={isLoading}
          onClick={() => openNarrowDrawer(<FilterDrawer_DEPRECATED filters={filters} filterFields={filterFields} setFilters={handleSetFilters} />)}
        >
          + Filters ({filters.length})
        </Button>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </Drawer>
  )
})
