import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreatePlanUser,
  mutateDeletePlanUser,
  mutateUpdatePlanUser,
  queryPlanUserInSessionView,
  queryPlanUserList,
  queryPlanUserView,
} from '@cotiss/plan-event'
import {
  GqlCreatePlanUserInput,
  GqlDeletePlanUserInput,
  GqlPlanUserFieldsFragment,
  GqlPlanUserInSessionViewInput,
  GqlPlanUserListInput,
  GqlPlanUserViewInput,
  GqlUpdatePlanUserInput,
} from '@gql'

const planUserVar = makeVar<GqlPlanUserFieldsFragment | null>(null)
const planUserInSessionVar = makeVar<GqlPlanUserFieldsFragment | null>(null)
const planUsersVar = makeVar<GqlPlanUserFieldsFragment[]>([])

export const usePlanUser = () => {
  const planUser = useReactiveVar(planUserVar)
  const planUserInSession = useReactiveVar(planUserInSessionVar)
  const planUsers = useReactiveVar(planUsersVar)

  return {
    planUser,
    planUserInSession,
    planUsers,
    setPlanUser: planUserVar,
    setPlanUserInSession: planUserInSessionVar,
    setPlanUsers: planUsersVar,
    queryPlanUserList: async (input: GqlPlanUserListInput) => {
      const { items, pagination } = await queryPlanUserList(input)

      planUsersVar(items)

      return { items, pagination }
    },
    queryPlanUserView: async (input: GqlPlanUserViewInput) => {
      const planUser = await queryPlanUserView(input)

      planUserVar(planUser)
      planUsersVar(uniqBy([planUser, ...planUsers], 'id'))

      return planUser
    },
    queryPlanUserInSessionView: async (input: GqlPlanUserInSessionViewInput) => {
      const planUserInSession = await queryPlanUserInSessionView(input)

      planUserInSessionVar(planUserInSession)
      planUsersVar(uniqBy([planUserInSession, ...planUsers], 'id'))

      return planUserInSession
    },
    mutateCreatePlanUser: async (input: GqlCreatePlanUserInput) => {
      const createdPlanUser = await mutateCreatePlanUser(input)

      planUserVar(createdPlanUser)
      planUsersVar(uniqBy([createdPlanUser, ...planUsers], 'id'))

      return createdPlanUser
    },
    mutateUpdatePlanUser: async (input: GqlUpdatePlanUserInput) => {
      const updatedPlanUser = await mutateUpdatePlanUser(input)

      planUsersVar(uniqBy([updatedPlanUser, ...planUsers], 'id'))

      return updatedPlanUser
    },
    mutateDeletePlanUser: async (input: GqlDeletePlanUserInput) => {
      await mutateDeletePlanUser(input)

      planUsersVar(filter(planUsers, ({ id }) => id !== input.planUserId))
    },
  }
}

export const clearReactivePlanUser = async () => {
  planUserVar(null)
  planUserInSessionVar(null)
  planUsersVar([])
}
