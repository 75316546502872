import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationEventDocumentListInput, GqlEvaluationEventDocumentListQuery } from '@gql'

export const queryEvaluationEventDocumentList = async (input: GqlEvaluationEventDocumentListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_EVENT_DOCUMENT_FRAGMENT}

      query EvaluationEventDocumentList($input: EvaluationEventDocumentListInput!) {
        evaluationEventDocumentList(input: $input) {
          items {
            ...EvaluationEventDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEventDocumentList
}
