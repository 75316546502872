import { trim } from 'lodash'

type GetFullNameParam = {
  firstname?: string
  surname?: string
  firstName?: string
  lastName?: string
}

type GetInitialsParam = GetFullNameParam

class UserService {
  getFullName = (user?: GetFullNameParam) => trim(`${trim(user?.firstname || user?.firstName)} ${trim(user?.surname || user?.lastName)}`)

  getInitials = (user?: GetInitialsParam) => {
    const fullName = this.getFullName(user)
    const names = fullName.split(' ')

    if (!names.length || !names[0]) {
      return '?'
    }

    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }

    return initials
  }
}

export const userService = new UserService()
