import { Button, Icon } from '@cotiss/common'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React, { memo } from 'react'

type Props = {
  className?: string
  id: string
  isActive?: boolean
  isInOverlay?: boolean
  isSortable?: boolean
  isDisabled?: boolean
  children: React.ReactNode
}

export const DraggableListItem = memo((props: Props) => {
  const { className, id, isActive, isInOverlay, isSortable, isDisabled, children } = props
  const { attributes, listeners, transform, transition, setNodeRef, setActivatorNodeRef } = useSortable({ id })
  const style = { transform: CSS.Transform.toString(transform), transition }
  const classes = classNames(className, 'relative flex items-center justify-between w-full', {
    'opacity-50': isDisabled || isInOverlay,
    'opacity-0': isActive,
  })

  return (
    <div className={classes} style={style}>
      <div
        className={classNames('group flex items-center justify-between bg-gray-50 border border-gray-200 rounded-lg cursor-default w-full', {
          'hover:bg-secondary-100': !isDisabled && !isInOverlay,
          'bg-secondary-100': isInOverlay,
        })}
        ref={setNodeRef}
        {...attributes}
      >
        {isSortable && (
          <Button className="ml-2" state="ghost" shape="square" ref={setActivatorNodeRef} {...listeners}>
            <Icon icon="menu-01" variant="secondary" />
          </Button>
        )}
        {children}
      </div>
    </div>
  )
})
