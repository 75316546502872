import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardUserListInput, GqlPerformanceScorecardUserListQuery } from '@gql'

export const queryPerformanceScorecardUserList = async (input: GqlPerformanceScorecardUserListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserList($input: PerformanceScorecardUserListInput!) {
        performanceScorecardUserList(input: $input) {
          items {
            ...PerformanceScorecardUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserList
}
