import React, { memo } from 'react'
import { Card, Hr } from '@cotiss/common'
import { useUserAccess } from '@cotiss/user'
import { SettingsPreferencesFormEmailNotificationPeriod, SettingsPreferencesFormNotificationPeriod } from '@cotiss/settings'

export const SettingsPreferencesTab = memo(() => {
  const { permissions } = useUserAccess()

  return (
    <Card>
      {permissions.hasSupplierAccess && (
        <>
          <SettingsPreferencesFormNotificationPeriod />
          <Hr className="my-4" />
        </>
      )}
      <SettingsPreferencesFormEmailNotificationPeriod />
    </Card>
  )
})
