import React, { memo } from 'react'
import { Button, Card, CardHeader, TableHeader, Text, useCallout } from '@cotiss/common'
import { EvaluationEventUserAddModal, EvaluationEventUserList, useEvaluationEvent, useEvaluationUser } from '@cotiss/evaluation-event'

export const EvaluationEventViewTeamUsersTab = memo(() => {
  const { openModal } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationUserInSession } = useEvaluationUser()

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
          Owners
        </Text>
        {evaluationUserInSession?.role === 'owner' && (
          <Button state="translucent" size="xs" variant="secondary-dark" onClick={() => openModal(<EvaluationEventUserAddModal />)}>
            + Add owner
          </Button>
        )}
      </CardHeader>
      <TableHeader>
        <Text className="font-medium" size="lg">
          Evaluation owners
        </Text>
      </TableHeader>
      <EvaluationEventUserList
        isEditable={evaluationUserInSession?.role === 'owner' && (evaluationEvent?.status === 'draft' || evaluationEvent?.status === 'active')}
      />
    </Card>
  )
})
