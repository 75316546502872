import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { GqlEvaluationEventStatus } from '@gql'
import { Badge, BadgeSize, BadgeState, BaseVariant } from '@cotiss/common'

const getVariant = (status: GqlEvaluationEventStatus): BaseVariant => {
  switch (status) {
    case 'active':
      return 'secondary'
    case 'complete':
      return 'success'
    case 'draft':
      return 'warning'
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: GqlEvaluationEventStatus
  size?: BadgeSize
  state?: BadgeState
}

export const EvaluationEventStatusBadge = memo(({ className, status, size = 'md', state = 'outline' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state={state}>
    {upperFirst(lowerCase(status))}
  </Badge>
))
