import React, { memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { Button, Text } from '@cotiss/common'
import { SettingsTwoFactorUnregisterForm, SettingsTwoFactorRegisterForm } from '@cotiss/settings'

export const SettingsGeneralTwoFactorForm = memo(() => {
  const [isEditing, setIsEditing] = useState(false)
  const { user } = useGetLoggedInUser()
  const isEnabled = Boolean(user?.otpEnabled)

  if (isEditing) {
    return isEnabled ? (
      <SettingsTwoFactorUnregisterForm onClose={() => setIsEditing(false)} />
    ) : (
      <SettingsTwoFactorRegisterForm onClose={() => setIsEditing(false)} />
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Two factor authentication</Text>
        <Text>{isEnabled ? 'On' : 'Off'}</Text>
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
        Update
      </Button>
    </div>
  )
})
