import { useFetch } from '@cotiss/common'
import { ConflictOfInterestModel } from '@cotiss/conflict-of-interest'

export const useGetConflictOfInterest = (id?: string) => {
  const { data: conflictOfInterest, ...rest } = useFetch<ConflictOfInterestModel>('/v2/conflict-of-interest', {
    route: `/v2/conflict-of-interest/${id}`,
    isEnabled: Boolean(id),
  })

  return { conflictOfInterest, ...rest }
}
