import { filter, find, includes, map } from 'lodash'
import { AccessControlModel, AccessControlRole, CreateAccessControlBulkBody, RemoveAccessControlBulkBody } from '@cotiss/access-control'

type GetBulkCreateUsersParam = {
  userIdsToAdd: string[]
  existingAccessControls: AccessControlModel[]
  roles: AccessControlRole[]
}

type GetBulkRemoveUsersParam = {
  userIdsToAdd: string[]
  existingAccessControls: AccessControlModel[]
  role: AccessControlRole
}

class AccessControlService {
  includesRole = (roles: AccessControlRole[], role: AccessControlRole): boolean => includes(roles, role)

  getCreateAccessControlBulkBody = ({ userIdsToAdd, existingAccessControls, roles }: GetBulkCreateUsersParam): CreateAccessControlBulkBody | null => {
    const newUsers = filter(userIdsToAdd, (id) => !find(existingAccessControls, ({ user }) => user._id === id))

    return newUsers.length ? { users: map(newUsers, (userId) => ({ userId, roles })) } : null
  }

  getRemoveAccessControlBulkBody = ({ userIdsToAdd, existingAccessControls, role }: GetBulkRemoveUsersParam): RemoveAccessControlBulkBody | null => {
    const removedPlanApprovers = filter(existingAccessControls, ({ user }) => {
      return !includes(userIdsToAdd, user._id)
    })

    return removedPlanApprovers.length ? { accessControls: map(removedPlanApprovers, ({ _id }) => ({ accessControlId: _id, role })) } : null
  }
}

export const accessControlService = new AccessControlService()
