import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlDeleteEvaluationEnvelopeInput, GqlDeleteEvaluationEnvelopeMutation, GqlDeleteEvaluationEnvelopeMutationVariables } from '@gql'

export const mutateDeleteEvaluationEnvelope = async (input: GqlDeleteEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationEnvelopeMutation, GqlDeleteEvaluationEnvelopeMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationEnvelope($input: DeleteEvaluationEnvelopeInput!) {
        deleteEvaluationEnvelope(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationEnvelope as boolean
}
