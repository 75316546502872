import { PaginatedModel, useFetch } from '@cotiss/common'
import { TenderModel } from '@cotiss/tender'

type Props = {
  procurementId?: string
  isOpenOnly?: boolean
  limit?: number
  offset?: number
}

export const useListTender = ({ procurementId, isOpenOnly, limit = -1, offset = 0 }: Props) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderModel>>('/v2/tenders', {
    params: { procurementId, openOnly: isOpenOnly, limit, offset },
    isEnabled: Boolean(procurementId),
  })

  return { tenders: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
