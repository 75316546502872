import { find, map } from 'lodash'
import React, { FormEvent, useState, useMemo, memo } from 'react'
import { useMutateContractShell, ContractShellPopulatedModel, useListContractingEntities } from '@cotiss/contract'
import {
  Form,
  ModalHeader,
  ModalContent,
  Label,
  ModalFooter,
  Input,
  useCallout,
  RegionMultiSelect,
  sentryService,
  useToast,
  TextArea,
  Text,
  Select_DEPRECATED,
  useAnalytics,
  OcdsCurrencyCode,
  OCDS_CURRENCY_OPTIONS,
} from '@cotiss/common'

type Props = {
  label: string
  field: FieldName
  supplementary?: string
  contractShell: ContractShellPopulatedModel
  contractId: string
}

type FormDataValues = {
  contractShellTitle: string
  shortDescription: string
  regions: string[]
  internalReference: string
  contractingEntity: string
  currency: OcdsCurrencyCode
}

type FieldName = keyof FormDataValues

export const ContractWizardStepMetadataEditModal = memo(({ label, field, supplementary, contractShell, contractId }: Props) => {
  const contract = useMemo(() => find(contractShell?.contracts, ({ _id }) => _id === contractId), [])
  const { contractingEntities, isLoading: isContractingEntitiesLoading } = useListContractingEntities()
  const [formData, setFormData] = useState<FormDataValues>({
    contractShellTitle: contractShell.title,
    shortDescription: contractShell.description,
    regions: contract?.metadata.regions || [],
    internalReference: contract?.metadata.externalReference || '',
    contractingEntity: contract?.metadata.contractingEntity?._id || '',
    currency: contract?.metadata.currency || 'NZD',
  })
  const [isSaving, setIsSaving] = useState(false)
  const { closeModal } = useCallout()
  const { updateContractShell, updateContractMetadata } = useMutateContractShell()
  const { openToast } = useToast()
  const { track } = useAnalytics()

  const contractingEntitiesOptions = useMemo(() => map(contractingEntities, ({ name, _id }) => ({ label: name, value: _id })), [contractingEntities])

  const canContinue = useMemo(() => {
    switch (field) {
      case 'contractShellTitle':
        return Boolean(formData.contractShellTitle)
      default:
        return true
    }
  }, [field, formData])

  if (!contract) {
    return (
      <div className="p-6 h-24 flex items-center justify-center">
        <Text>Couldn&apos;t load contract. Please try again.</Text>
      </div>
    )
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    track('contract_wizard_metadata_update_submit')
    setIsSaving(true)
    try {
      if (field === 'contractShellTitle' || field === 'shortDescription') {
        await updateContractShell(contractShell._id, {
          ...(field === 'contractShellTitle' && { title: formData.contractShellTitle }),
          ...(field === 'shortDescription' && { description: formData.shortDescription }),
        })
        closeModal()
        return
      }

      await updateContractMetadata(contractShell._id, contract._id, contract.metadata._id, {
        ...(field === 'regions' && { regions: formData.regions }),
        ...(field === 'internalReference' && { externalReference: formData.internalReference }),
        ...(field === 'contractingEntity' && { contractingEntity: formData.contractingEntity }),
        ...(field === 'currency' && { currency: formData.currency }),
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`Edit ${label.toLowerCase()}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>{label}</Label>
        {(field === 'contractShellTitle' || field === 'internalReference') && (
          <Input
            autoFocus
            placeholder="Type here"
            value={formData[field] as string}
            isRequired={field === 'contractShellTitle'}
            onChange={({ target }) => setFormData({ ...formData, [field]: target.value })}
          />
        )}
        {field === 'shortDescription' && (
          <TextArea
            className="h-36"
            autoFocus
            value={formData.shortDescription}
            onChange={({ target }) => setFormData({ ...formData, shortDescription: target.value })}
          />
        )}
        {field === 'regions' && <RegionMultiSelect value={formData.regions} onChange={(value) => setFormData({ ...formData, regions: value })} />}
        {field === 'currency' && (
          <Select_DEPRECATED<OcdsCurrencyCode>
            value={formData.currency}
            options={OCDS_CURRENCY_OPTIONS}
            placeholder
            onChange={(currency) => setFormData({ ...formData, currency })}
            isDisabled={isSaving}
          />
        )}
        {field === 'contractingEntity' && (
          <Select_DEPRECATED<string>
            value={formData.contractingEntity}
            options={contractingEntitiesOptions}
            placeholder
            onChange={(contractingEntity) => setFormData({ ...formData, contractingEntity })}
            isDisabled={isSaving || isContractingEntitiesLoading}
          />
        )}
        {supplementary && (
          <Text className="mt-1" variant="light" size="sm">
            {supplementary}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm isDisabled={!canContinue} />
    </Form>
  )
})
