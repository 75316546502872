[
  {
    "name": "Live Plant and Animal Material and Accessories and Supplies",
    "code": "10000000",
    "subcategories": [
      { "name": "Live animals", "code": "10100000" },
      { "name": "Livestock", "code": "10101500" },
      { "name": "Cats", "code": "10101501" },
      { "name": "Dogs", "code": "10101502" },
      { "name": "Mink", "code": "10101504" },
      { "name": "Rats", "code": "10101505" },
      { "name": "Horses", "code": "10101506" },
      { "name": "Sheep", "code": "10101507" },
      { "name": "Goats", "code": "10101508" },
      { "name": "Asses", "code": "10101509" },
      { "name": "Mice", "code": "10101510" },
      { "name": "Swine", "code": "10101511" },
      { "name": "Rabbits", "code": "10101512" },
      { "name": "Guinea pigs", "code": "10101513" },
      { "name": "Primates", "code": "10101514" },
      { "name": "Armadillos", "code": "10101515" },
      { "name": "Cattle", "code": "10101516" },
      { "name": "Camels", "code": "10101517" },
      { "name": "Alpaca", "code": "10101518" },
      { "name": "Buffalo or bison", "code": "10101519" },
      { "name": "Birds and fowl", "code": "10101600" },
      { "name": "Live chickens", "code": "10101601" },
      { "name": "Live ducks", "code": "10101602" },
      { "name": "Live turkeys", "code": "10101603" },
      { "name": "Live geese", "code": "10101604" },
      { "name": "Live pheasants", "code": "10101605" },
      { "name": "Live ostrich", "code": "10101606" },
      { "name": "Live guinea fowl", "code": "10101607" },
      { "name": "Live fish", "code": "10101700" },
      { "name": "Live salmon", "code": "10101701" },
      { "name": "Live trout", "code": "10101702" },
      { "name": "Live tilapia", "code": "10101703" },
      { "name": "Live carp", "code": "10101704" },
      { "name": "Live eels", "code": "10101705" },
      { "name": "Live sole", "code": "10101706" },
      { "name": "Live palometa fish or mylossoma aureum", "code": "10101707" },
      { "name": "Live sardine fish", "code": "10101708" },
      { "name": "Live red belly pacu fish", "code": "10101709" },
      { "name": "Live peruvian rock seabass fish", "code": "10101710" },
      { "name": "Live paiche fish", "code": "10101711" },
      { "name": "Live mojarra fish", "code": "10101712" },
      { "name": "Live mauri fish", "code": "10101713" },
      { "name": "Live maparate fish", "code": "10101714" },
      { "name": "Live lumptail sea robin fish", "code": "10101715" },
      { "name": "Live llambina fish", "code": "10101716" },
      { "name": "Live ispi fish", "code": "10101717" },
      { "name": "Live frigate tuna fish or melva fish", "code": "10101718" },
      { "name": "Live freshwater silverside fish", "code": "10101719" },
      { "name": "Live acarahuazu fish", "code": "10101720" },
      { "name": "Live arawana fish", "code": "10101721" },
      { "name": "Live armored catfish or carachama", "code": "10101722" },
      { "name": "Live black prochilodus", "code": "10101723" },
      { "name": "Live blochs catfish", "code": "10101724" },
      { "name": "Live bobo mullet", "code": "10101725" },
      { "name": "Live cabinza grunt", "code": "10101726" },
      { "name": "Live carachi fish", "code": "10101727" },
      { "name": "Live cascafe fish", "code": "10101728" },
      {
        "name": "Live freshwater palometa fish or mylossoma duriventre",
        "code": "10101729"
      },
      { "name": "Live goldfish", "code": "10101730" },
      { "name": "Live swordfish", "code": "10101731" },
      { "name": "Live tambaqui", "code": "10101732" },
      { "name": "Shellfish and aquatic invertebrates", "code": "10101800" },
      { "name": "Live shrimp", "code": "10101801" },
      { "name": "Live clams", "code": "10101802" },
      { "name": "Live mussels", "code": "10101803" },
      { "name": "Live oysters", "code": "10101804" },
      { "name": "Live crabs", "code": "10101805" },
      { "name": "Live abalone", "code": "10101806" },
      { "name": "Live octopi", "code": "10101807" },
      { "name": "Live squid", "code": "10101808" },
      { "name": "Leeches", "code": "10101809" },
      { "name": "Live sponge", "code": "10101810" },
      { "name": "Live lobster", "code": "10101811" },
      { "name": "Insects", "code": "10101900" },
      { "name": "Butterflies", "code": "10101901" },
      { "name": "Beetles", "code": "10101902" },
      { "name": "Bees", "code": "10101903" },
      { "name": "Silkworms", "code": "10101904" },
      { "name": "Live chilean recluse spider", "code": "10101905" },
      { "name": "Live aphid lion or chrysoperla externa", "code": "10101906" },
      { "name": "Live insidious flower bug", "code": "10101907" },
      { "name": "Live southern black widow", "code": "10101908" },
      { "name": "Live sugarcane borer or stem borer", "code": "10101909" },
      { "name": "Wild animals", "code": "10102000" },
      { "name": "Elephants", "code": "10102001" },
      { "name": "Live foxes", "code": "10102002" },
      { "name": "Live bothrops pit viper snake", "code": "10102003" },
      { "name": "Live chironius or vine snake", "code": "10102004" },
      { "name": "Live clelia or mussurana snake", "code": "10102005" },
      { "name": "Live drymarchon or indigo snake", "code": "10102006" },
      { "name": "Live epicrates or rainbow boa snake", "code": "10102007" },
      { "name": "Live giraffe", "code": "10102008" },
      { "name": "Live bushmaster or lachesis snake", "code": "10102009" },
      { "name": "Live coral or micrurus snake", "code": "10102010" },
      { "name": "Live paca", "code": "10102011" },
      { "name": "Live philodryas snake", "code": "10102012" },
      { "name": "Live xenodon snake", "code": "10102013" },
      { "name": "Domestic pet products", "code": "10110000" },
      {
        "name": "Domestic pet treatments and accessories and equipment",
        "code": "10111300"
      },
      { "name": "Pet toys", "code": "10111301" },
      { "name": "Pet grooming products", "code": "10111302" },
      {
        "name": "Pet litter or equipment for pet waste management",
        "code": "10111303"
      },
      { "name": "Pet food bowls or equipment", "code": "10111304" },
      { "name": "Medicated pet treatments", "code": "10111305" },
      { "name": "Domestic pet training kits", "code": "10111306" },
      { "name": "Pet blankets", "code": "10111307" },
      { "name": "Bird bath", "code": "10111308" },
      { "name": "Animal feed", "code": "10120000" },
      { "name": "Livestock feed", "code": "10121500" },
      { "name": "Pure wheat bran", "code": "10121501" },
      { "name": "Feed oats", "code": "10121502" },
      { "name": "Feed corn", "code": "10121503" },
      { "name": "Feed sorghum", "code": "10121504" },
      { "name": "Hay", "code": "10121505" },
      { "name": "Oil cake", "code": "10121506" },
      { "name": "Compound feed", "code": "10121507" },
      { "name": "Feed alfalfa or lucerne meal or pellets", "code": "10121508" },
      { "name": "Livestock salt", "code": "10121509" },
      { "name": "Bird and fowl food", "code": "10121600" },
      { "name": "Live food for birds", "code": "10121601" },
      { "name": "Bird seed", "code": "10121602" },
      { "name": "Bird treats or snacks", "code": "10121603" },
      { "name": "Poultry food", "code": "10121604" },
      { "name": "Fish food", "code": "10121700" },
      { "name": "Fresh or frozen brine", "code": "10121701" },
      { "name": "Fish food pellets", "code": "10121702" },
      { "name": "Fish food flakes", "code": "10121703" },
      { "name": "Dog and cat food", "code": "10121800" },
      { "name": "Dry food for dogs", "code": "10121801" },
      { "name": "Moist food for dogs", "code": "10121802" },
      { "name": "Milk for dogs or cats", "code": "10121803" },
      { "name": "Dry food for cats", "code": "10121804" },
      { "name": "Moist food for cats", "code": "10121805" },
      { "name": "Treats or snacks for cats or dogs", "code": "10121806" },
      { "name": "Rodent food", "code": "10121900" },
      { "name": "Pelletized food for rodents", "code": "10121901" },
      { "name": "Reptile food", "code": "10122000" },
      { "name": "Pelletized food for reptiles", "code": "10122001" },
      { "name": "Moist food for reptiles", "code": "10122002" },
      { "name": "Live food for reptiles", "code": "10122003" },
      { "name": "Miscellaneous animal food", "code": "10122100" },
      { "name": "Pig food", "code": "10122101" },
      { "name": "Mink food", "code": "10122102" },
      { "name": "Monkey food", "code": "10122103" },
      { "name": "Rabbit food", "code": "10122104" },
      { "name": "Animal containment and habitats", "code": "10130000" },
      { "name": "Animal shelters", "code": "10131500" },
      { "name": "Livestock stables", "code": "10131506" },
      { "name": "Domesticized pet houses", "code": "10131507" },
      { "name": "Pet beds", "code": "10131508" },
      { "name": "Animal containment", "code": "10131600" },
      { "name": "Cages or its accessories", "code": "10131601" },
      { "name": "Kennels", "code": "10131602" },
      { "name": "Animal carrying cases", "code": "10131603" },
      { "name": "Dog runs", "code": "10131604" },
      { "name": "Animal transport cage", "code": "10131605" },
      { "name": "Animal habitats", "code": "10131700" },
      { "name": "Terrariums", "code": "10131701" },
      { "name": "Aquariums", "code": "10131702" },
      { "name": "Saddlery and harness goods", "code": "10140000" },
      { "name": "Saddlery", "code": "10141500" },
      { "name": "Saddles", "code": "10141501" },
      { "name": "Whips", "code": "10141502" },
      { "name": "Horseshoes", "code": "10141503" },
      { "name": "Muleshoes", "code": "10141504" },
      { "name": "Saddle pad", "code": "10141505" },
      { "name": "Harness goods", "code": "10141600" },
      { "name": "Bridles", "code": "10141601" },
      { "name": "Yokes", "code": "10141602" },
      { "name": "Horse bits", "code": "10141603" },
      { "name": "Reins", "code": "10141604" },
      { "name": "Stirrups", "code": "10141605" },
      { "name": "Leashes or leads", "code": "10141606" },
      { "name": "Animal collars", "code": "10141607" },
      { "name": "Harnesses or its accessories", "code": "10141608" },
      { "name": "Restraints", "code": "10141609" },
      { "name": "Muzzles", "code": "10141610" },
      { "name": "Leash holders", "code": "10141611" },
      { "name": "Dog catching pole", "code": "10141612" },
      {
        "name": "Seeds and bulbs and seedlings and cuttings",
        "code": "10150000"
      },
      { "name": "Vegetable seeds and seedlings", "code": "10151500" },
      { "name": "Celery seeds or seedlings", "code": "10151503" },
      { "name": "Chili seeds or seedlings", "code": "10151504" },
      { "name": "Courgette seeds or seedlings", "code": "10151505" },
      { "name": "Pea seeds or seedlings", "code": "10151506" },
      { "name": "Cucumber seeds or seedlings", "code": "10151507" },
      { "name": "Eggplant seeds or seedlings", "code": "10151508" },
      { "name": "Endive seeds or seedlings", "code": "10151509" },
      { "name": "Garlic seeds or seedlings", "code": "10151510" },
      { "name": "Leek seeds or seedlings", "code": "10151511" },
      { "name": "Lettuce seeds or seedlings", "code": "10151512" },
      { "name": "Corn seeds or seedlings", "code": "10151513" },
      { "name": "Melon seeds or seedlings", "code": "10151514" },
      { "name": "Onion seeds or seedlings", "code": "10151515" },
      { "name": "Spinach seeds or seedlings", "code": "10151517" },
      { "name": "Tomato seeds or seedlings", "code": "10151518" },
      { "name": "Chard seeds or seedlings", "code": "10151520" },
      { "name": "Sweet pepper seeds or seedlings", "code": "10151521" },
      { "name": "Beet seeds or seedlings", "code": "10151522" },
      { "name": "Cauliflower seeds or seedlings", "code": "10151523" },
      { "name": "Parsley seeds or seedlings", "code": "10151524" },
      { "name": "Broccoli seeds or seedlings", "code": "10151525" },
      { "name": "Cabbage seeds or seedlings", "code": "10151526" },
      { "name": "Pumpkin seeds or seedlings", "code": "10151529" },
      { "name": "Brussel sprout seeds or seedlings", "code": "10151531" },
      { "name": "Squash seeds or seedlings", "code": "10151532" },
      { "name": "Okra seeds or seedlings", "code": "10151533" },
      { "name": "Cantaloupe seeds or seedlings", "code": "10151534" },
      { "name": "Peanut seeds or seedlings", "code": "10151535" },
      { "name": "Caigua seeds or seedlings", "code": "10151536" },
      { "name": "Asparagus seeds or seedlings", "code": "10151537" },
      { "name": "Chickpea seeds or seedlings", "code": "10151538" },
      { "name": "Fava or broad bean seeds or seedlings", "code": "10151539" },
      {
        "name": "Centrocema or butterfly pea seed or seedlings",
        "code": "10151540"
      },
      { "name": "Nettle seed or seedlings", "code": "10151541" },
      { "name": "Cereal seeds", "code": "10151600" },
      { "name": "Wheat seeds", "code": "10151601" },
      { "name": "Colza seeds", "code": "10151602" },
      { "name": "Barley seeds", "code": "10151603" },
      { "name": "Millet seeds", "code": "10151604" },
      { "name": "Oat seeds", "code": "10151605" },
      { "name": "Sesame seeds", "code": "10151606" },
      { "name": "Linseed seeds", "code": "10151607" },
      { "name": "Castor oil seeds", "code": "10151608" },
      { "name": "Maize seeds", "code": "10151609" },
      { "name": "Rye seeds", "code": "10151610" },
      { "name": "Sorghum seeds", "code": "10151611" },
      { "name": "Kiwicha seeds or seedlings", "code": "10151612" },
      { "name": "Quinoa seeds or seedlings", "code": "10151613" },
      { "name": "Rice seeds or seedlings", "code": "10151614" },
      { "name": "Safflower seed", "code": "10151615" },
      { "name": "Teff seed", "code": "10151616" },
      { "name": "Sugar cane seed or cutting", "code": "10151617" },
      { "name": "Grass and forage seeds and seedlings", "code": "10151700" },
      { "name": "Clover seeds or seedling", "code": "10151702" },
      { "name": "Alfalfa seeds or seedlings", "code": "10151703" },
      { "name": "Grass seeds or seedlings", "code": "10151704" },
      { "name": "Crown vetch seeds or seedlings", "code": "10151705" },
      { "name": "Guar seeds or seedlings", "code": "10151706" },
      { "name": "Reed seed or cutting", "code": "10151707" },
      { "name": "Spice crop seeds and seedlings", "code": "10151800" },
      { "name": "Pepper seeds or seedlings", "code": "10151801" },
      { "name": "Vanilla seeds or seedlings", "code": "10151802" },
      { "name": "Cinnamon seeds or seedlings", "code": "10151803" },
      { "name": "Clove seeds or seedlings", "code": "10151804" },
      { "name": "Coriander seeds or seedlings", "code": "10151805" },
      { "name": "Ginger seeds or seedlings", "code": "10151806" },
      { "name": "Saffron seeds or seedlings", "code": "10151807" },
      { "name": "Thyme seeds or seedlings", "code": "10151808" },
      { "name": "Curry seeds or seedlings", "code": "10151809" },
      { "name": "Mustard seeds or seedlings", "code": "10151810" },
      { "name": "Ginseng roots or seedlings", "code": "10151811" },
      { "name": "Mushroom seeds or seedlings", "code": "10151812" },
      { "name": "Sacha inchi seeds or seedlings", "code": "10151813" },
      { "name": "Achiote seeds or seedlings", "code": "10151814" },
      { "name": "Kudzu seeds or seedlings", "code": "10151815" },
      { "name": "Basil seeds or seedlings", "code": "10151816" },
      { "name": "Anise seeds or seedlings", "code": "10151817" },
      { "name": "Bay leaf seed or seedlings", "code": "10151818" },
      { "name": "Sage seed or seedlings", "code": "10151819" },
      {
        "name": "Flower seeds and bulbs and seedlings and cuttings",
        "code": "10151900"
      },
      {
        "name": "Tulip seeds or bulbs or seedlings or cuttings",
        "code": "10151901"
      },
      { "name": "Rose seeds or seedlings or cuttings", "code": "10151902" },
      {
        "name": "Daffodil seeds or bulbs or seedlings or cuttings",
        "code": "10151903"
      },
      { "name": "Sunflower seeds", "code": "10151904" },
      { "name": "Hyacinth bulbs or shoots", "code": "10151905" },
      { "name": "Lily Bulbs", "code": "10151906" },
      { "name": "Vetch seeds or seedlings", "code": "10151907" },
      { "name": "Zinnia seed or seedlings", "code": "10151908" },
      { "name": "Mutuy seed or seedling", "code": "10151909" },
      { "name": "Marigold seed or seedlings", "code": "10151910" },
      { "name": "Kild aliso seed", "code": "10151911" },
      { "name": "Ivy leaf geranium seeds or seedlings", "code": "10151912" },
      { "name": "Isabelita or dogbane seed or seedlings", "code": "10151913" },
      { "name": "Dogo or figwort seed or seedlings", "code": "10151914" },
      { "name": "Chachacoma seed or seedlings", "code": "10151915" },
      { "name": "Canola seed or seedlings", "code": "10151916" },
      {
        "name": "Balsam or impatiens balsamina seed or seedlings",
        "code": "10151917"
      },
      { "name": "Achira seed or seedlings", "code": "10151918" },
      { "name": "Acerato seed or seedlings", "code": "10151919" },
      { "name": "Senorita enredadera seed or seedling", "code": "10151920" },
      { "name": "Aheli amarillo seed or cutting", "code": "10151921" },
      { "name": "Gaillardia seed or seedling", "code": "10151922" },
      { "name": "Ciclamen seed, seedling or cutting", "code": "10151923" },
      { "name": "Carnation seed or seedling", "code": "10151924" },
      { "name": "Tree and shrub seeds and cuttings", "code": "10152000" },
      { "name": "Fruit tree seeds or cuttings", "code": "10152001" },
      { "name": "Conifer tree seeds or cuttings", "code": "10152002" },
      { "name": "Nut tree seeds or cuttings", "code": "10152003" },
      { "name": "Latifoliate tree seedling", "code": "10152004" },
      { "name": "Conifer tree seedling", "code": "10152005" },
      { "name": "Pine tree seeds or budwood", "code": "10152006" },
      { "name": "Carob tree seeds or budwood", "code": "10152007" },
      { "name": "Coffee seed or seedlings", "code": "10152008" },
      { "name": "Misa seed or seedlings", "code": "10152009" },
      { "name": "Royal poinciana seed or seedlings", "code": "10152010" },
      { "name": "Ceticio tree seed or seedlings", "code": "10152011" },
      { "name": "Ceibo seed or seedlings", "code": "10152012" },
      { "name": "Willow tree seed or cutting", "code": "10152013" },
      { "name": "Tornillo tree seed or cutting", "code": "10152014" },
      { "name": "Huaranhuay tree seed or cutting", "code": "10152015" },
      { "name": "Teca tree seed or cutting", "code": "10152016" },
      { "name": "Taya or tara tree seed or cutting", "code": "10152017" },
      { "name": "Tahuari negro tree seed or cutting", "code": "10152018" },
      { "name": "Small poinciana tree seed or cutting", "code": "10152019" },
      { "name": "Shihuahuaco tree seed or cutting", "code": "10152020" },
      { "name": "Sangre de grado tree seed or cutting", "code": "10152021" },
      { "name": "Retema tree seed or cutting", "code": "10152022" },
      { "name": "Quishuar tree seed or cutting", "code": "10152023" },
      { "name": "Quenua or quewina tree seed or cutting", "code": "10152024" },
      { "name": "Qolle tree seed or cutting", "code": "10152025" },
      { "name": "Podocarpus tree seed or cutting", "code": "10152026" },
      { "name": "Pashaco colorado tree seed or cutting", "code": "10152027" },
      { "name": "Palo de rosa tree seed or cutting", "code": "10152028" },
      { "name": "Ojo de paloma tree seed or cutting", "code": "10152029" },
      { "name": "Molle tree seed or cutting", "code": "10152030" },
      { "name": "Miaporo tree seed or cutting", "code": "10152031" },
      { "name": "Marupa tree seed or cutting", "code": "10152032" },
      { "name": "Mahogany tree seed or cutting", "code": "10152033" },
      { "name": "Jacaranda tree seed or cutting", "code": "10152034" },
      { "name": "Italia forage tree seed or seedlings", "code": "10152035" },
      { "name": "Huaruro tree seed or cutting", "code": "10152036" },
      {
        "name": "Huaranguillo or acacia horrida bush seed or cutting",
        "code": "10152037"
      },
      { "name": "Huarango tree seed or cutting", "code": "10152038" },
      { "name": "Huacapu tree seed or cutting", "code": "10152039" },
      { "name": "Ronceana regia tree seed or cutting", "code": "10152040" },
      {
        "name": "Glandular nakedwood tree seed or cutting",
        "code": "10152041"
      },
      { "name": "Eucalyptus tree seed or cutting", "code": "10152042" },
      { "name": "Cumala tree seed or cutting", "code": "10152043" },
      { "name": "Coral tree seed or cutting", "code": "10152044" },
      { "name": "Colle tree seed or cutting", "code": "10152045" },
      { "name": "Cinamo tree seed or cutting", "code": "10152046" },
      { "name": "Charan tree seed or cutting", "code": "10152047" },
      { "name": "Castor oil bush seed or cutting", "code": "10152048" },
      { "name": "Capirona tree seed or cutting", "code": "10152049" },
      { "name": "Bolaina blanca tree seed or cutting", "code": "10152050" },
      { "name": "Australian oak tree seed or cutting", "code": "10152051" },
      { "name": "Aromo bush seed or cutting", "code": "10152052" },
      {
        "name": "Aniba sp or nectandra sp or ocotea sp moena tree seed or cutting",
        "code": "10152053"
      },
      { "name": "Cacao or cocoa tree seed", "code": "10152054" },
      { "name": "Date palm cutting", "code": "10152055" },
      { "name": "Oil palm seed", "code": "10152056" },
      { "name": "Cassava seed or cutting", "code": "10152057" },
      { "name": "Rubber tree seed or cutting", "code": "10152058" },
      { "name": "Escalonia seed and cutting", "code": "10152059" },
      { "name": "Basul seed and cutting", "code": "10152060" },
      { "name": "Pisonay seed and cutting", "code": "10152061" },
      { "name": "Pucaquiro seed and cutting", "code": "10152062" },
      { "name": "Estoraque seed and cutting", "code": "10152063" },
      { "name": "Cedrillo tree seed, seedling or cutting", "code": "10152064" },
      { "name": "Residues other than animal feed", "code": "10152100" },
      { "name": "Residues babool seed extraction", "code": "10152101" },
      { "name": "Residues of rape seeds", "code": "10152102" },
      { "name": "Residue of linseed", "code": "10152103" },
      { "name": "Oil cake of neem", "code": "10152104" },
      { "name": "Fibrous crop seeds and seedlings", "code": "10152200" },
      { "name": "Cotton seeds or seedlings", "code": "10152201" },
      { "name": "Flax seeds or seedlings", "code": "10152202" },
      { "name": "Legume seeds and seedlings", "code": "10152300" },
      { "name": "Bean seeds or seedlings", "code": "10152301" },
      { "name": "Soya seeds or seedlings", "code": "10152302" },
      { "name": "Tuber seeds and seedlings", "code": "10152400" },
      { "name": "Potato seeds or seedlings", "code": "10152401" },
      { "name": "Turnip seeds or seedlings", "code": "10152402" },
      { "name": "Sweet potato seeds or seedlings", "code": "10152403" },
      { "name": "Carrot seeds or seedlings", "code": "10152404" },
      { "name": "Radish seeds or seedlings", "code": "10152405" },
      { "name": "Floriculture and silviculture products", "code": "10160000" },
      { "name": "Trees and shrubs", "code": "10161500" },
      { "name": "Olive trees", "code": "10161501" },
      { "name": "Coffee shrubs", "code": "10161502" },
      { "name": "Cocoa trees", "code": "10161503" },
      { "name": "Apple trees", "code": "10161504" },
      { "name": "Peach trees", "code": "10161505" },
      { "name": "Orange trees", "code": "10161506" },
      { "name": "Rhododendron shrubs", "code": "10161507" },
      { "name": "Tea shrubs", "code": "10161508" },
      { "name": "Conifer trees", "code": "10161509" },
      { "name": "Spruce trees", "code": "10161510" },
      { "name": "Pine trees", "code": "10161511" },
      { "name": "Fir trees", "code": "10161512" },
      { "name": "Palm trees", "code": "10161513" },
      { "name": "Casuarina tree", "code": "10161514" },
      { "name": "Cypress tree", "code": "10161515" },
      { "name": "Eucalyptus tree", "code": "10161516" },
      { "name": "Quinoa tree", "code": "10161517" },
      { "name": "Magnolia tree", "code": "10161518" },
      { "name": "Mioporo bush", "code": "10161519" },
      { "name": "Acalypha bush", "code": "10161520" },
      {
        "name": "Tecomaria capensis or cape honeysuckle bush",
        "code": "10161521"
      },
      { "name": "Croton bolaina bush", "code": "10161522" },
      { "name": "Abutilon bush", "code": "10161523" },
      { "name": "Ficus or fig bush", "code": "10161524" },
      { "name": "Eggfruit tree", "code": "10161525" },
      { "name": "Avocado tree", "code": "10161526" },
      { "name": "Guanabanillo tree", "code": "10161527" },
      { "name": "Star fruit tree", "code": "10161528" },
      { "name": "Plum tree", "code": "10161529" },
      { "name": "Quince tree", "code": "10161530" },
      { "name": "Wingleaf soapberry tree", "code": "10161531" },
      { "name": "Tornillo tree", "code": "10161532" },
      { "name": "Umari tree", "code": "10161533" },
      { "name": "Tara tree", "code": "10161534" },
      { "name": "Sauce lloron tree", "code": "10161535" },
      { "name": "Sauce criollo tree", "code": "10161536" },
      { "name": "Royal poinciana tree", "code": "10161537" },
      {
        "name": "Papelillo or copal blanco or bursera odorata tree",
        "code": "10161538"
      },
      { "name": "Palo rosa tree", "code": "10161539" },
      { "name": "Molle serrano tree", "code": "10161540" },
      { "name": "Molle costeno tree", "code": "10161541" },
      { "name": "Moena amarilla tree", "code": "10161542" },
      { "name": "Moena alcanfor or canela moena tree", "code": "10161543" },
      { "name": "Mimosa tree", "code": "10161544" },
      { "name": "Marupa tree", "code": "10161545" },
      { "name": "Macambo tree", "code": "10161546" },
      { "name": "Jacaranda tree", "code": "10161547" },
      { "name": "Huayruro tree", "code": "10161548" },
      { "name": "Grevilia tree", "code": "10161549" },
      { "name": "Floripondio bush", "code": "10161550" },
      { "name": "Erica bush", "code": "10161551" },
      { "name": "Cumala tree", "code": "10161552" },
      { "name": "Copoazu tree", "code": "10161553" },
      { "name": "Coral tree", "code": "10161554" },
      { "name": "Cocona bush", "code": "10161555" },
      { "name": "Chilean alamo tree", "code": "10161556" },
      { "name": "Chestnut tree", "code": "10161557" },
      { "name": "Ceder or cedro colorado tree", "code": "10161558" },
      { "name": "Cedrela tree", "code": "10161559" },
      { "name": "Capirona tree", "code": "10161560" },
      { "name": "Caoba tree", "code": "10161561" },
      { "name": "Calistemo bush", "code": "10161562" },
      { "name": "Aroma tree", "code": "10161563" },
      { "name": "Araucaria tree", "code": "10161564" },
      { "name": "Anallu caspi tree", "code": "10161565" },
      { "name": "Albizia tree", "code": "10161566" },
      { "name": "Alamo carolino tree", "code": "10161567" },
      { "name": "Cardenal tree", "code": "10161568" },
      { "name": "Chiflera tree", "code": "10161569" },
      { "name": "Cherry sapling", "code": "10161570" },
      { "name": "Cranberry sapling", "code": "10161571" },
      { "name": "Pomegranate sapling", "code": "10161572" },
      { "name": "Grenadilla sapling", "code": "10161573" },
      { "name": "Papaya sapling", "code": "10161574" },
      { "name": "Shihuahuaco tree", "code": "10161575" },
      { "name": "Murraya shrub", "code": "10161576" },
      { "name": "Hibiscus tiliaceus", "code": "10161577" },
      { "name": "Chirimoya tree", "code": "10161578" },
      { "name": "Lemon verbena plant", "code": "10161579" },
      { "name": "Floral plants", "code": "10161600" },
      { "name": "Poinsettias plants", "code": "10161602" },
      { "name": "Azaleas plants", "code": "10161604" },
      { "name": "Cactus plants", "code": "10161605" },
      { "name": "Purple ageratum", "code": "10161606" },
      { "name": "Acalypha picta plant", "code": "10161607" },
      { "name": "African daisy plant", "code": "10161608" },
      { "name": "African rose plant", "code": "10161609" },
      { "name": "Agapanto plant", "code": "10161610" },
      { "name": "Aglaonema plant", "code": "10161611" },
      { "name": "Alamanda plant", "code": "10161612" },
      { "name": "Allysum or aliso plant", "code": "10161613" },
      { "name": "Alubia plant", "code": "10161614" },
      { "name": "Balsam or impatiens balsamina plant", "code": "10161615" },
      { "name": "Begonia plant", "code": "10161616" },
      { "name": "Chlorophyte plant", "code": "10161617" },
      { "name": "Cineraria blanca plant", "code": "10161618" },
      { "name": "Copa de oro plant", "code": "10161619" },
      { "name": "Coprosona plant", "code": "10161620" },
      { "name": "Coreopsis plant", "code": "10161621" },
      { "name": "Crosanda plant", "code": "10161622" },
      { "name": "Cyclamen plant", "code": "10161623" },
      { "name": "Eucaris plant", "code": "10161624" },
      { "name": "Galan de noche plant", "code": "10161625" },
      { "name": "Gardenia plant", "code": "10161626" },
      { "name": "Gazania plant", "code": "10161627" },
      { "name": "Gerebra plant", "code": "10161628" },
      { "name": "Sacha garlic plant", "code": "10161629" },
      { "name": "Hemerocalis plant", "code": "10161630" },
      { "name": "Hortensia plant", "code": "10161631" },
      { "name": "Isabelita or dogbane plant", "code": "10161632" },
      { "name": "Jasmine plant", "code": "10161633" },
      { "name": "Lantana plant", "code": "10161634" },
      { "name": "Laurel rose plant", "code": "10161635" },
      { "name": "Machiques de pina plant", "code": "10161636" },
      { "name": "Madre selva plant", "code": "10161637" },
      { "name": "Mirto plant", "code": "10161638" },
      { "name": "Moco de pavo plant", "code": "10161639" },
      { "name": "Dogo or figwort plant", "code": "10161640" },
      { "name": "Oleander plant", "code": "10161641" },
      { "name": "Patiquina plant", "code": "10161642" },
      { "name": "Pensamiento plant", "code": "10161643" },
      { "name": "Petunia plant", "code": "10161644" },
      { "name": "Portulaca plant", "code": "10161645" },
      { "name": "Primula plant", "code": "10161646" },
      { "name": "Verbena plant", "code": "10161647" },
      { "name": "Sugar cane plant", "code": "10161648" },
      { "name": "Wedelia plant", "code": "10161649" },
      { "name": "Ixora coccinea plant", "code": "10161650" },
      { "name": "Pilea microphylla plant", "code": "10161651" },
      { "name": "Salvia splendens plant", "code": "10161652" },
      { "name": "Correhuela mayor plant", "code": "10161653" },
      { "name": "Espada amazonica plant", "code": "10161654" },
      { "name": "Loche pumpkin plant", "code": "10161656" },
      { "name": "Aubergine plant", "code": "10161657" },
      { "name": "Panca chili plant", "code": "10161658" },
      { "name": "Rocoto plant", "code": "10161659" },
      { "name": "Non flowering plants", "code": "10161800" },
      { "name": "Ferns", "code": "10161801" },
      { "name": "Ivies", "code": "10161802" },
      { "name": "Philodendrons", "code": "10161803" },
      { "name": "Lichens", "code": "10161804" },
      { "name": "Grape plant", "code": "10161805" },
      { "name": "Aloe vera plant", "code": "10161806" },
      { "name": "Calahuala plant", "code": "10161807" },
      { "name": "Climbing ivy plant", "code": "10161808" },
      { "name": "Cissus plant", "code": "10161809" },
      { "name": "Coleus limon plant", "code": "10161810" },
      { "name": "Costilla de adan plant", "code": "10161811" },
      { "name": "Dumbcane plant", "code": "10161812" },
      { "name": "Diverse agave plant", "code": "10161813" },
      { "name": "Croto plant", "code": "10161814" },
      { "name": "Dracena plant", "code": "10161815" },
      { "name": "Falsa hiedra plant", "code": "10161816" },
      { "name": "Ichu plant", "code": "10161817" },
      { "name": "Iresine herbstii plant", "code": "10161818" },
      { "name": "Elephant ear or oreja de elefante plant", "code": "10161819" },
      { "name": "Mijo plant", "code": "10161820" },
      { "name": "Papyrus plant", "code": "10161821" },
      { "name": "Rhapsis plant", "code": "10161822" },
      { "name": "Rhoeo plant", "code": "10161823" },
      { "name": "Sanguinaria roja plant", "code": "10161824" },
      { "name": "Stevia rebaudiana plant", "code": "10161825" },
      { "name": "Mandioc plant", "code": "10161826" },
      { "name": "Oregano plant", "code": "10161827" },
      { "name": "Mint plant", "code": "10161828" },
      { "name": "Rosemary plant", "code": "10161829" },
      { "name": "Celery plant", "code": "10161830" },
      { "name": "Parsley plant", "code": "10161831" },
      { "name": "Huacatay plant", "code": "10161832" },
      { "name": "Spearmint plant", "code": "10161833" },
      { "name": "Dried floral products", "code": "10161900" },
      { "name": "Dried pods", "code": "10161901" },
      { "name": "Dried leafy greenery", "code": "10161902" },
      { "name": "Dried fern greenery", "code": "10161903" },
      { "name": "Dried twigs or sticks", "code": "10161905" },
      { "name": "Dried grass plumes", "code": "10161906" },
      { "name": "Dried pressed flowers", "code": "10161907" },
      { "name": "Dried flower petals", "code": "10161908" },
      {
        "name": "Fertilizers and plant nutrients and herbicides",
        "code": "10170000"
      },
      { "name": "Organic fertilizers and plant nutrients", "code": "10171500" },
      { "name": "Manure or guano", "code": "10171501" },
      { "name": "Plant hormones", "code": "10171502" },
      { "name": "Fish meal", "code": "10171503" },
      { "name": "Compost", "code": "10171504" },
      { "name": "Foliar nutrient", "code": "10171505" },
      { "name": "Humus", "code": "10171506" },
      { "name": "Urea fertilizer", "code": "10171507" },
      {
        "name": "Chemical fertilizers and plant nutrients",
        "code": "10171600"
      },
      { "name": "Ammonium nitrate fertilizer", "code": "10171601" },
      { "name": "Potassic fertilizer", "code": "10171602" },
      { "name": "Phosphatic fertilizer", "code": "10171603" },
      { "name": "Sulphuric fertilizer", "code": "10171604" },
      {
        "name": "Nitrogen Phosphorous Potassium Mixtures NPK",
        "code": "10171605"
      },
      { "name": "Pure silica fertilizer", "code": "10171606" },
      { "name": "Magnesium fertilizer", "code": "10171607" },
      { "name": "Micro element fertilizer", "code": "10171608" },
      { "name": "Silica phosphate fertilizer", "code": "10171609" },
      { "name": "Silica potassium fertilizer", "code": "10171610" },
      { "name": "Calcium fertilizer", "code": "10171611" },
      { "name": "Superphosphate fertilizer", "code": "10171612" },
      {
        "name": "Ammonium nitrate mixed with calcium carbonate fertilizer",
        "code": "10171613"
      },
      { "name": "Potassium chloride for agricultural use", "code": "10171614" },
      { "name": "Potassium sulphate", "code": "10171615" },
      {
        "name": "Diammonium hydrogenorthophosphate fertilizer",
        "code": "10171616"
      },
      { "name": "Herbicides", "code": "10171700" },
      { "name": "Weed killer", "code": "10171701" },
      { "name": "Fungicides", "code": "10171702" },
      { "name": "Propanil", "code": "10171703" },
      { "name": "Soil conditioners", "code": "10171800" },
      { "name": "Organic soil conditioner", "code": "10171801" },
      { "name": "Inorganic soil conditioner", "code": "10171802" },
      { "name": "Pest control products", "code": "10190000" },
      { "name": "Pesticides or pest repellents", "code": "10191500" },
      { "name": "Rodenticides", "code": "10191506" },
      { "name": "Bird repellents", "code": "10191507" },
      { "name": "Termite shields", "code": "10191508" },
      { "name": "Insecticides", "code": "10191509" },
      { "name": "Abamectin", "code": "10191510" },
      { "name": "Fipronil", "code": "10191511" },
      { "name": "Cypermethrin", "code": "10191512" },
      { "name": "Deltamethrin", "code": "10191513" },
      { "name": "Fenitrothion", "code": "10191514" },
      { "name": "Chlorphyriphos", "code": "10191515" },
      { "name": "Malathion", "code": "10191516" },
      { "name": "Diazinon", "code": "10191517" },
      { "name": "Carbaryl", "code": "10191518" },
      { "name": "Telfubenzuron", "code": "10191519" },
      { "name": "Pest control devices", "code": "10191700" },
      { "name": "Animal control traps", "code": "10191701" },
      { "name": "Flying insect control traps", "code": "10191703" },
      { "name": "Fly swatters", "code": "10191704" },
      { "name": "Lariats", "code": "10191705" },
      { "name": "Leghold traps", "code": "10191706" },
      { "name": "Ultrasonic pest repeller", "code": "10191707" },
      { "name": "Live rose bushes", "code": "10200000" },
      {
        "name": "Live blue or lavender or purple rose bushes",
        "code": "10201500"
      },
      { "name": "Live allure or sterling 95 rose bush", "code": "10201501" },
      { "name": "Live amnesia rose bush", "code": "10201502" },
      { "name": "Live augusta louise rose bush", "code": "10201503" },
      { "name": "Live avant garde rose bush", "code": "10201504" },
      { "name": "Live blue bird rose bush", "code": "10201505" },
      { "name": "Live blue curiosa rose bush", "code": "10201506" },
      { "name": "Live cool water rose bush", "code": "10201507" },
      { "name": "Live delilah rose bush", "code": "10201508" },
      { "name": "Live double party rose bush", "code": "10201509" },
      { "name": "Live faith rose bush", "code": "10201510" },
      { "name": "Live mami blue or mammy blue rose bush", "code": "10201511" },
      { "name": "Live maritime rose bush", "code": "10201512" },
      { "name": "Live milano rose bush", "code": "10201513" },
      { "name": "Live mystery rose bush", "code": "10201514" },
      { "name": "Live ocean song or boyfriend rose bush", "code": "10201515" },
      { "name": "Live purple cezanne rose bush", "code": "10201516" },
      { "name": "Live purple fragrance rose bush", "code": "10201517" },
      { "name": "Live sanaa rose bush", "code": "10201518" },
      { "name": "Live silverstone rose bush", "code": "10201519" },
      { "name": "Live soulmate rose bush", "code": "10201520" },
      { "name": "Live stranger rose bush", "code": "10201521" },
      { "name": "Live tinted blue rose bush", "code": "10201522" },
      { "name": "Live two faces rose bush", "code": "10201523" },
      { "name": "Live chocolate or brown rose bushes", "code": "10201600" },
      { "name": "Live black lava rose bush", "code": "10201601" },
      { "name": "Live cimarron rose bush", "code": "10201602" },
      { "name": "Live coffee break rose bush", "code": "10201603" },
      { "name": "Live estelle rose bush", "code": "10201604" },
      { "name": "Live gypsy leonidas rose bush", "code": "10201605" },
      { "name": "Live leonidas rose bush", "code": "10201606" },
      { "name": "Live matilda rose bush", "code": "10201607" },
      { "name": "Live sunny leonidas rose bush", "code": "10201608" },
      { "name": "Live terra nostra rose bush", "code": "10201609" },
      { "name": "Live terracotta rose bush", "code": "10201610" },
      { "name": "Live cream rose bushes", "code": "10201700" },
      { "name": "Live advenire rose bush", "code": "10201701" },
      { "name": "Live alex rose bush", "code": "10201702" },
      { "name": "Live antique brass rose bush", "code": "10201703" },
      { "name": "Live aubade rose bush", "code": "10201704" },
      { "name": "Live beach rose bush", "code": "10201705" },
      { "name": "Live belle pearl rose bush", "code": "10201706" },
      {
        "name": "Live blush or blush de los andesrose bush",
        "code": "10201707"
      },
      { "name": "Live camel rose bush", "code": "10201708" },
      {
        "name": "Live caramel antike or caramel antique rose bush",
        "code": "10201709"
      },
      { "name": "Live champagne rose bush", "code": "10201710" },
      { "name": "Live clear ocean rose bush", "code": "10201711" },
      { "name": "Live combo rose bush", "code": "10201712" },
      { "name": "Live creme de la creme rose bush", "code": "10201713" },
      { "name": "Live emanuella rose bush", "code": "10201714" },
      { "name": "Live evolution rose bush", "code": "10201715" },
      { "name": "Live fedora rose bush", "code": "10201716" },
      { "name": "Live fenice rose bush", "code": "10201717" },
      { "name": "Live french vanilla rose bush", "code": "10201718" },
      { "name": "Live hollywood rose bush", "code": "10201719" },
      { "name": "Live ilios rose bush", "code": "10201720" },
      { "name": "Live jelena rose bush", "code": "10201721" },
      { "name": "Live kameleon rose bush", "code": "10201722" },
      { "name": "Live kentucky rose bush", "code": "10201723" },
      { "name": "Live kings pride rose bush", "code": "10201724" },
      { "name": "Live latin fusion rose bush", "code": "10201725" },
      { "name": "Live lemon dream rose bush", "code": "10201726" },
      { "name": "Live magic moka rose bush", "code": "10201727" },
      { "name": "Live mamamia rose bush", "code": "10201728" },
      { "name": "Live message rose bush", "code": "10201729" },
      { "name": "Live muneca or munieca rose bush", "code": "10201730" },
      { "name": "Live parfum de rosas rose bush", "code": "10201731" },
      { "name": "Live porcelina rose bush", "code": "10201732" },
      { "name": "Live privilege rose bush", "code": "10201733" },
      { "name": "Live quicksand rose bush", "code": "10201734" },
      { "name": "Live rollercoaster rose bush", "code": "10201735" },
      { "name": "Live romantic curiosa rose bush", "code": "10201736" },
      { "name": "Live safari rose bush", "code": "10201737" },
      { "name": "Live sahara rose bush", "code": "10201738" },
      { "name": "Live sandy femma rose bush", "code": "10201739" },
      { "name": "Live talea rose bush", "code": "10201740" },
      { "name": "Live timeless rose bush", "code": "10201741" },
      { "name": "Live transition rose bush", "code": "10201742" },
      { "name": "Live trump rose bush", "code": "10201743" },
      { "name": "Live twin rose bush", "code": "10201744" },
      { "name": "Live vendela rose bush", "code": "10201745" },
      { "name": "Live virginia rose bush", "code": "10201746" },
      { "name": "Live green or lime rose bushes", "code": "10201800" },
      { "name": "Live amandine rose bush", "code": "10201801" },
      { "name": "Live caipirinha rose bush", "code": "10201802" },
      { "name": "Live green fashion rose bush", "code": "10201803" },
      { "name": "Live green tea rose bush", "code": "10201804" },
      { "name": "Live jade rose bush", "code": "10201805" },
      { "name": "Live limbo rose bush", "code": "10201806" },
      { "name": "Live limena or limenia rose bush", "code": "10201807" },
      { "name": "Live limona rose bush", "code": "10201808" },
      { "name": "Live old dutch rose bush", "code": "10201809" },
      { "name": "Live super green rose bush", "code": "10201810" },
      { "name": "Live sweet green rose bush", "code": "10201811" },
      { "name": "Live viva rose bush", "code": "10201812" },
      { "name": "Live zazu rose bush", "code": "10201813" },
      { "name": "Live light pink rose bushes", "code": "10201900" },
      { "name": "Live anna rose bush", "code": "10201901" },
      { "name": "Live bella vita rose bush", "code": "10201902" },
      { "name": "Live bridal dream rose bush", "code": "10201903" },
      { "name": "Live candy bianca rose bush", "code": "10201904" },
      { "name": "Live caress rose bush", "code": "10201905" },
      { "name": "Live carolina rose bush", "code": "10201906" },
      { "name": "Live climax rose bush", "code": "10201907" },
      { "name": "Live danny rose bush", "code": "10201908" },
      { "name": "Live dolce vita rose bush", "code": "10201909" },
      { "name": "Live elite rose bush", "code": "10201910" },
      { "name": "Live emma rose bush", "code": "10201911" },
      { "name": "Live engagement rose bush", "code": "10201912" },
      { "name": "Live esther rose bush", "code": "10201913" },
      { "name": "Live excalibur rose bush", "code": "10201914" },
      { "name": "Live exciting rose bush", "code": "10201915" },
      { "name": "Live first lady rose bush", "code": "10201916" },
      { "name": "Live geraldine rose bush", "code": "10201917" },
      { "name": "Live gotcha rose bush", "code": "10201918" },
      { "name": "Live harmonie rose bush", "code": "10201919" },
      { "name": "Live heaven rose bush", "code": "10201920" },
      { "name": "Live high and elegant rose bush", "code": "10201921" },
      { "name": "Live katherine rose bush", "code": "10201922" },
      { "name": "Live king kong rose bush", "code": "10201923" },
      { "name": "Live livia rose bush", "code": "10201924" },
      { "name": "Live lorena rose bush", "code": "10201925" },
      { "name": "Live lovely amazon rose bush", "code": "10201926" },
      { "name": "Live maaike rose bush", "code": "10201927" },
      { "name": "Live marilyn rose bush", "code": "10201928" },
      { "name": "Live marlise rose bush", "code": "10201929" },
      { "name": "Live miranda or ausimmon rose bush", "code": "10201930" },
      { "name": "Live mona lisa rose bush", "code": "10201931" },
      { "name": "Live nirvana rose bush", "code": "10201932" },
      { "name": "Live o hara rose bush", "code": "10201933" },
      { "name": "Live ole rose bush", "code": "10201934" },
      { "name": "Live olga rose bush", "code": "10201935" },
      { "name": "Live pacifica rose bush", "code": "10201936" },
      { "name": "Live party mix rose bush", "code": "10201937" },
      { "name": "Live peckoubo or pekcoubo rose bush", "code": "10201938" },
      { "name": "Live phoebe or ausnotice rose bush", "code": "10201939" },
      { "name": "Live pink farfalla rose bush", "code": "10201940" },
      { "name": "Live pink finess rose bush", "code": "10201941" },
      { "name": "Live pink magic rose bush", "code": "10201942" },
      { "name": "Live pink osiana rose bush", "code": "10201943" },
      { "name": "Live pretty woman rose bush", "code": "10201944" },
      { "name": "Live romance rose bush", "code": "10201945" },
      {
        "name": "Live romantic antike or antique rose bush",
        "code": "10201946"
      },
      { "name": "Live rosalind or austew rose bush", "code": "10201947" },
      { "name": "Live rosita vendela rose bush", "code": "10201948" },
      { "name": "Live secret garden rose bush", "code": "10201949" },
      { "name": "Live solaire rose bush", "code": "10201950" },
      { "name": "Live sophie rose bush", "code": "10201951" },
      { "name": "Live sweet akito rose bush", "code": "10201952" },
      { "name": "Live sweet avalanche rose bush", "code": "10201953" },
      { "name": "Live sweet elegance rose bush", "code": "10201954" },
      { "name": "Live sweet pink rose bush", "code": "10201955" },
      { "name": "Live titanic rose bush", "code": "10201956" },
      { "name": "Live toscanini rose bush", "code": "10201957" },
      { "name": "Live vania rose bush", "code": "10201958" },
      { "name": "Live vanity rose bush", "code": "10201959" },
      { "name": "Live vision rose bush", "code": "10201960" },
      { "name": "Live vivaldi rose bush", "code": "10201961" },
      { "name": "Live whisper rose bush", "code": "10201962" },
      { "name": "Live multi-colored pink rose bushes", "code": "10202000" },
      { "name": "Live attracta rose bush", "code": "10202001" },
      { "name": "Live boheme rose bush", "code": "10202002" },
      { "name": "Live carousel rose bush", "code": "10202003" },
      { "name": "Live cezanne rose bush", "code": "10202004" },
      { "name": "Live crazy one rose bush", "code": "10202005" },
      { "name": "Live dance valley rose bush", "code": "10202006" },
      { "name": "Live duett rose bush", "code": "10202007" },
      { "name": "Live esperance rose bush", "code": "10202008" },
      { "name": "Live fiesta rose bush", "code": "10202009" },
      { "name": "Live halloween rose bush", "code": "10202010" },
      { "name": "Live highlander rose bush", "code": "10202011" },
      { "name": "Live hot ambiance rose bush", "code": "10202012" },
      { "name": "Live la belle rose bush", "code": "10202013" },
      { "name": "Live laguna rose bush", "code": "10202014" },
      { "name": "Live latin ambiance rose bush", "code": "10202015" },
      { "name": "Live latin breeze rose bush", "code": "10202016" },
      { "name": "Live long arifa rose bush", "code": "10202017" },
      { "name": "Live murano rose bush", "code": "10202018" },
      { "name": "Live n-joy rose bush", "code": "10202019" },
      { "name": "Live panama rose bush", "code": "10202020" },
      { "name": "Live peppermint rose bush", "code": "10202021" },
      { "name": "Live pijama party rose bush", "code": "10202022" },
      { "name": "Live portofino rose bush", "code": "10202023" },
      { "name": "Live priceless rose bush", "code": "10202024" },
      { "name": "Live queen amazon rose bush", "code": "10202025" },
      { "name": "Live ranuncula rose bush", "code": "10202026" },
      { "name": "Live rossini rose bush", "code": "10202027" },
      { "name": "Live sabina or sabrina rose bush", "code": "10202028" },
      { "name": "Live scandal rose bush", "code": "10202029" },
      { "name": "Live silvery pink rose bush", "code": "10202030" },
      { "name": "Live something else rose bush", "code": "10202031" },
      { "name": "Live soutine rose bush", "code": "10202032" },
      { "name": "Live sovereign rose bush", "code": "10202033" },
      { "name": "Live super disco rose bush", "code": "10202034" },
      { "name": "Live ts 1968 rose bush", "code": "10202035" },
      { "name": "Live variance rose bush", "code": "10202036" },
      { "name": "Live verdi rose bush", "code": "10202037" },
      { "name": "Live orange rose bushes", "code": "10202100" },
      { "name": "Live alhambra rose bush", "code": "10202101" },
      { "name": "Live aloha rose bush", "code": "10202102" },
      { "name": "Live amber rose bush", "code": "10202103" },
      { "name": "Live apache rose bush", "code": "10202104" },
      { "name": "Live arabia rose bush", "code": "10202105" },
      { "name": "Live bengala rose bush", "code": "10202106" },
      { "name": "Live bibi rose bush", "code": "10202107" },
      { "name": "Live caramba rose bush", "code": "10202108" },
      { "name": "Live caramella rose bush", "code": "10202109" },
      { "name": "Live carla rose bush", "code": "10202110" },
      { "name": "Live cartagena rose bush", "code": "10202111" },
      { "name": "Live chanson rose bush", "code": "10202112" },
      { "name": "Live charmer rose bush", "code": "10202113" },
      { "name": "Live cherry brandy rose bush", "code": "10202114" },
      { "name": "Live chilis rose bush", "code": "10202115" },
      { "name": "Live cinnamon rose bush", "code": "10202116" },
      { "name": "Live colandro rose bush", "code": "10202117" },
      { "name": "Live coral sea rose bush", "code": "10202118" },
      { "name": "Live corvette or red corvette rose bush", "code": "10202119" },
      { "name": "Live dark milva rose bush", "code": "10202120" },
      { "name": "Live donna rose bush", "code": "10202121" },
      { "name": "Live dreamer rose bush", "code": "10202122" },
      { "name": "Live el dorado rose bush", "code": "10202123" },
      { "name": "Live el toro rose bush", "code": "10202124" },
      { "name": "Live elena rose bush", "code": "10202125" },
      { "name": "Live ensueno rose bush", "code": "10202126" },
      { "name": "Live euforia rose bush", "code": "10202127" },
      { "name": "Live exotica rose bush", "code": "10202128" },
      { "name": "Live fancy amazon rose bush", "code": "10202129" },
      { "name": "Live fiction rose bush", "code": "10202130" },
      { "name": "Live finess rose bush", "code": "10202131" },
      { "name": "Live flamenco rose bush", "code": "10202132" },
      { "name": "Live free spirit rose bush", "code": "10202133" },
      { "name": "Live gelato rose bush", "code": "10202134" },
      { "name": "Live gypsy curiosa rose bush", "code": "10202135" },
      { "name": "Live high and magic rose bush", "code": "10202136" },
      { "name": "Live high and orange magic rose bush", "code": "10202137" },
      { "name": "Live iguana or alegra rose bush", "code": "10202138" },
      { "name": "Live impulse rose bush", "code": "10202139" },
      { "name": "Live indian femma rose bush", "code": "10202140" },
      { "name": "Live indian sunset rose bush", "code": "10202141" },
      { "name": "Live karusso rose bush", "code": "10202142" },
      { "name": "Live kerio rose bush", "code": "10202143" },
      { "name": "Live kiki rose bush", "code": "10202144" },
      { "name": "Live latin circus rose bush", "code": "10202145" },
      { "name": "Live leonisa rose bush", "code": "10202146" },
      { "name": "Live lipstick rose bush", "code": "10202147" },
      { "name": "Live lobita rose bush", "code": "10202148" },
      { "name": "Live luca rose bush", "code": "10202149" },
      { "name": "Live manitou rose bush", "code": "10202150" },
      { "name": "Live mariana rose bush", "code": "10202151" },
      { "name": "Live marjan or pk sensation rose bush", "code": "10202152" },
      { "name": "Live milonga rose bush", "code": "10202153" },
      { "name": "Live milva rose bush", "code": "10202154" },
      { "name": "Live miracle rose bush", "code": "10202155" },
      { "name": "Live mirage rose bush", "code": "10202156" },
      { "name": "Live monte carlo rose bush", "code": "10202157" },
      { "name": "Live movie star rose bush", "code": "10202158" },
      { "name": "Live nikita rose bush", "code": "10202159" },
      { "name": "Live orange flame rose bush", "code": "10202160" },
      { "name": "Live orange france rose bush", "code": "10202161" },
      { "name": "Live orange intuition rose bush", "code": "10202162" },
      { "name": "Live orange unique rose bush", "code": "10202163" },
      { "name": "Live orangine or orangina rose bush", "code": "10202164" },
      { "name": "Live papaya rose bush", "code": "10202165" },
      { "name": "Live pareo rose bush", "code": "10202166" },
      { "name": "Live peach sherbet rose bush", "code": "10202167" },
      { "name": "Live queensday rose bush", "code": "10202168" },
      { "name": "Live rosselle rose bush", "code": "10202169" },
      { "name": "Live royal circus rose bush", "code": "10202170" },
      { "name": "Live sari rose bush", "code": "10202171" },
      { "name": "Live sensual rose bush", "code": "10202172" },
      { "name": "Live soap rose bush", "code": "10202173" },
      { "name": "Live sombrero rose bush", "code": "10202174" },
      { "name": "Live spicy rose bush", "code": "10202175" },
      { "name": "Live star 2000 rose bush", "code": "10202176" },
      { "name": "Live summer versilia rose bush", "code": "10202177" },
      { "name": "Live trixx rose bush", "code": "10202178" },
      { "name": "Live tropical amazon rose bush", "code": "10202179" },
      { "name": "Live utopia rose bush", "code": "10202180" },
      { "name": "Live valentine rose bush", "code": "10202181" },
      { "name": "Live verano rose bush", "code": "10202182" },
      { "name": "Live versilia rose bush", "code": "10202183" },
      { "name": "Live voodoo rose bush", "code": "10202184" },
      { "name": "Live wow rose bush", "code": "10202185" },
      { "name": "Live yabadabadoo rose bush", "code": "10202186" },
      { "name": "Live peach rose bushes", "code": "10202200" },
      { "name": "Live alejandra rose bush", "code": "10202201" },
      { "name": "Live azafran rose bush", "code": "10202202" },
      { "name": "Live big fun rose bush", "code": "10202203" },
      { "name": "Live cabaret rose bush", "code": "10202204" },
      { "name": "Live capuccino rose bush", "code": "10202205" },
      { "name": "Live carpe diem rose bush", "code": "10202206" },
      { "name": "Live cosima rose bush", "code": "10202207" },
      { "name": "Live cumbia rose bush", "code": "10202208" },
      { "name": "Live dream rose bush", "code": "10202209" },
      { "name": "Live epoca rose bush", "code": "10202210" },
      { "name": "Live fado rose bush", "code": "10202211" },
      { "name": "Live femma rose bush", "code": "10202212" },
      { "name": "Live guajira rose bush", "code": "10202213" },
      { "name": "Live high and arena rose bush", "code": "10202214" },
      { "name": "Live high and dandy rose bush", "code": "10202215" },
      { "name": "Live high and lucky rose bush", "code": "10202216" },
      { "name": "Live high and peach rose bush", "code": "10202217" },
      { "name": "Live imagination rose bush", "code": "10202218" },
      { "name": "Live isis rose bush", "code": "10202219" },
      { "name": "Live joy or light versilia rose bush", "code": "10202220" },
      { "name": "Live juliet ausjameson rose bush", "code": "10202221" },
      { "name": "Live la parisienne rose bush", "code": "10202222" },
      { "name": "Live la perla rose bush", "code": "10202223" },
      { "name": "Live lovita sunblaze rose bush", "code": "10202224" },
      { "name": "Live malilena or marilena rose bush", "code": "10202225" },
      { "name": "Live monyna rose bush", "code": "10202226" },
      { "name": "Live nectarine rose bush", "code": "10202227" },
      { "name": "Live oriental curiosa rose bush", "code": "10202228" },
      { "name": "Live osiana rose bush", "code": "10202229" },
      { "name": "Live peach avalanche rose bush", "code": "10202230" },
      { "name": "Live peach deja vu rose bush", "code": "10202231" },
      { "name": "Live picanto rose bush", "code": "10202232" },
      { "name": "Live prima donna rose bush", "code": "10202233" },
      { "name": "Live sheril rose bush", "code": "10202234" },
      { "name": "Live sirocco rose bush", "code": "10202235" },
      { "name": "Live tamara rose bush", "code": "10202236" },
      { "name": "Live taxo rose bush", "code": "10202237" },
      { "name": "Live trust rose bush", "code": "10202238" },
      { "name": "Live valencia rose bush", "code": "10202239" },
      { "name": "Live vinci rose bush", "code": "10202240" },
      { "name": "Live wanda rose bush", "code": "10202241" },
      { "name": "Live pink rose bushes", "code": "10202300" },
      { "name": "Live aerobic rose bush", "code": "10202301" },
      { "name": "Live after party rose bush", "code": "10202302" },
      { "name": "Live amsterdam rose bush", "code": "10202303" },
      { "name": "Live aqua rose bush", "code": "10202304" },
      { "name": "Live attache rose bush", "code": "10202305" },
      { "name": "Live attitude rose bush", "code": "10202306" },
      { "name": "Live ballet rose bush", "code": "10202307" },
      { "name": "Live belami rose bush", "code": "10202308" },
      { "name": "Live bella voo or belle vue rose bush", "code": "10202309" },
      { "name": "Live bling bling rose bush", "code": "10202310" },
      { "name": "Live blushing akito rose bush", "code": "10202311" },
      { "name": "Live brooke rose bush", "code": "10202312" },
      { "name": "Live bugatti rose bush", "code": "10202313" },
      { "name": "Live cadillac rose bush", "code": "10202314" },
      { "name": "Live carnaval rose bush", "code": "10202315" },
      { "name": "Live cereza rose bush", "code": "10202316" },
      { "name": "Live charming unique rose bush", "code": "10202317" },
      { "name": "Live cherry o rose bush", "code": "10202318" },
      { "name": "Live ciciolina rose bush", "code": "10202319" },
      { "name": "Live classic cezanne rose bush", "code": "10202320" },
      { "name": "Live classic duett rose bush", "code": "10202321" },
      { "name": "Live cosmiq rose bush", "code": "10202322" },
      { "name": "Live dark engagement rose bush", "code": "10202323" },
      { "name": "Live daytona rose bush", "code": "10202324" },
      { "name": "Live dekora rose bush", "code": "10202325" },
      { "name": "Live dolores rose bush", "code": "10202326" },
      { "name": "Live eliza rose bush", "code": "10202327" },
      { "name": "Live flash baccara rose bush", "code": "10202328" },
      { "name": "Live full house rose bush", "code": "10202329" },
      { "name": "Live funky rose bush", "code": "10202330" },
      { "name": "Live giliane rose bush", "code": "10202331" },
      { "name": "Live gran europe rose bush", "code": "10202332" },
      { "name": "Live habari rose bush", "code": "10202333" },
      { "name": "Live hanseat rose bush", "code": "10202334" },
      { "name": "Live high and amazing rose bush", "code": "10202335" },
      { "name": "Live high and bonita rose bush", "code": "10202336" },
      { "name": "Live high and booming rose bush", "code": "10202337" },
      { "name": "Live high and fantasy rose bush", "code": "10202338" },
      { "name": "Live high and rich rose bush", "code": "10202339" },
      { "name": "Live hot lady rose bush", "code": "10202340" },
      { "name": "Live hot princess rose bush", "code": "10202341" },
      { "name": "Live inspiration rose bush", "code": "10202342" },
      { "name": "Live jeimy rose bush", "code": "10202343" },
      { "name": "Live kachita rose bush", "code": "10202344" },
      { "name": "Live karen rose bush", "code": "10202345" },
      { "name": "Live kenji rose bush", "code": "10202346" },
      { "name": "Live kiko rose bush", "code": "10202347" },
      { "name": "Live laser rose bush", "code": "10202348" },
      { "name": "Live latin duett rose bush", "code": "10202349" },
      { "name": "Live latin fever rose bush", "code": "10202350" },
      { "name": "Live lifestyle rose bush", "code": "10202351" },
      { "name": "Live light orlando rose bush", "code": "10202352" },
      { "name": "Live lovely dreams rose bush", "code": "10202353" },
      { "name": "Live loyalty rose bush", "code": "10202354" },
      { "name": "Live malibu rose bush", "code": "10202355" },
      { "name": "Live mata-hari rose bush", "code": "10202356" },
      { "name": "Live memphis rose bush", "code": "10202357" },
      { "name": "Live mi amor rose bush", "code": "10202358" },
      { "name": "Live miami rose bush", "code": "10202359" },
      { "name": "Live michelle rose bush", "code": "10202360" },
      { "name": "Live mikaela rose bush", "code": "10202361" },
      { "name": "Live orchestra rose bush", "code": "10202362" },
      { "name": "Live orlando rose bush", "code": "10202363" },
      { "name": "Live osadia rose bush", "code": "10202364" },
      { "name": "Live paeonia freelander rose bush", "code": "10202365" },
      { "name": "Live paula rose bush", "code": "10202366" },
      { "name": "Live pavarotti rose bush", "code": "10202367" },
      { "name": "Live pink intuition rose bush", "code": "10202368" },
      { "name": "Live poison rose bush", "code": "10202369" },
      { "name": "Live princess rose bush", "code": "10202370" },
      { "name": "Live queen mary rose bush", "code": "10202371" },
      { "name": "Live raphaela rose bush", "code": "10202372" },
      { "name": "Live raspberry ice rose bush", "code": "10202373" },
      { "name": "Live ravel rose bush", "code": "10202374" },
      { "name": "Live riviera rose bush", "code": "10202375" },
      { "name": "Live sade rose bush", "code": "10202376" },
      { "name": "Live sashimi rose bush", "code": "10202377" },
      { "name": "Live shanya rose bush", "code": "10202378" },
      { "name": "Live shocking versilia rose bush", "code": "10202379" },
      { "name": "Live solitaire rose bush", "code": "10202380" },
      { "name": "Live something different rose bush", "code": "10202381" },
      { "name": "Live splendid renate rose bush", "code": "10202382" },
      { "name": "Live star rose bush", "code": "10202383" },
      { "name": "Live sweet candia rose bush", "code": "10202384" },
      { "name": "Live sweet moments rose bush", "code": "10202385" },
      { "name": "Live sweet unique rose bush", "code": "10202386" },
      { "name": "Live taboo rose bush", "code": "10202387" },
      { "name": "Live timona rose bush", "code": "10202388" },
      { "name": "Live topaz rose bush", "code": "10202389" },
      { "name": "Live vogue rose bush", "code": "10202390" },
      { "name": "Live voila rose bush", "code": "10202391" },
      { "name": "Live wild one rose bush", "code": "10202392" },
      { "name": "Live yves piaget rose bush", "code": "10202393" },
      { "name": "Live red or burgundy rose bushes", "code": "10202400" },
      { "name": "Live african dawn rose bush", "code": "10202401" },
      { "name": "Live amada rose bush", "code": "10202402" },
      { "name": "Live black baccara rose bush", "code": "10202403" },
      { "name": "Live black beauty rose bush", "code": "10202404" },
      {
        "name": "Live black finess or black magic rose bush",
        "code": "10202405"
      },
      { "name": "Live black magic rose bush", "code": "10202406" },
      { "name": "Live bohemian or pasarela rose bush", "code": "10202407" },
      { "name": "Live breathless rose bush", "code": "10202408" },
      { "name": "Live caballero rose bush", "code": "10202409" },
      { "name": "Live carrera rose bush", "code": "10202410" },
      { "name": "Live charlene rose bush", "code": "10202411" },
      { "name": "Live charlotte rose bush", "code": "10202412" },
      { "name": "Live cherry lady rose bush", "code": "10202413" },
      { "name": "Live cherry love rose bush", "code": "10202414" },
      { "name": "Live classy rose bush", "code": "10202415" },
      { "name": "Live colorado velvet rose bush", "code": "10202416" },
      { "name": "Live corazon rose bush", "code": "10202417" },
      { "name": "Live corrida rose bush", "code": "10202418" },
      { "name": "Live dynamite rose bush", "code": "10202419" },
      { "name": "Live eurored rose bush", "code": "10202420" },
      { "name": "Live fashion rose bush", "code": "10202421" },
      { "name": "Live fire and ice rose bush", "code": "10202422" },
      { "name": "Live first red rose bush", "code": "10202423" },
      { "name": "Live forever young rose bush", "code": "10202424" },
      { "name": "Live freedom rose bush", "code": "10202425" },
      { "name": "Live freestyle rose bush", "code": "10202426" },
      { "name": "Live friendship rose bush", "code": "10202427" },
      { "name": "Live gospel rose bush", "code": "10202428" },
      { "name": "Live graffity rose bush", "code": "10202429" },
      { "name": "Live grand gala rose bush", "code": "10202430" },
      { "name": "Live grand prix rose bush", "code": "10202431" },
      { "name": "Live grande classe rose bush", "code": "10202432" },
      { "name": "Live hearts rose bush", "code": "10202433" },
      { "name": "Live heat rose bush", "code": "10202434" },
      { "name": "Live hocus pocus rose bush", "code": "10202435" },
      { "name": "Live lady in red rose bush", "code": "10202436" },
      { "name": "Live latin lady rose bush", "code": "10202437" },
      { "name": "Live legend rose bush", "code": "10202438" },
      { "name": "Live lulu rose bush", "code": "10202439" },
      { "name": "Live luna rossa rose bush", "code": "10202440" },
      { "name": "Live luxor rose bush", "code": "10202441" },
      { "name": "Live madame delbard or carola rose bush", "code": "10202442" },
      { "name": "Live miss paris rose bush", "code": "10202443" },
      { "name": "Live nicole rose bush", "code": "10202444" },
      { "name": "Live night fever rose bush", "code": "10202445" },
      { "name": "Live obsession rose bush", "code": "10202446" },
      { "name": "Live opium rose bush", "code": "10202447" },
      { "name": "Live paz rose bush", "code": "10202448" },
      { "name": "Live preference rose bush", "code": "10202449" },
      { "name": "Live red berlin rose bush", "code": "10202450" },
      { "name": "Live red bull rose bush", "code": "10202451" },
      { "name": "Live red calypso rose bush", "code": "10202452" },
      { "name": "Live red diamond rose bush", "code": "10202453" },
      { "name": "Live red fantasy rose bush", "code": "10202454" },
      { "name": "Live red france rose bush", "code": "10202455" },
      { "name": "Live red intuition rose bush", "code": "10202456" },
      { "name": "Live red jewel rose bush", "code": "10202457" },
      { "name": "Live red magic rose bush", "code": "10202458" },
      { "name": "Live red one rose bush", "code": "10202459" },
      { "name": "Live red paris rose bush", "code": "10202460" },
      { "name": "Live red princess rose bush", "code": "10202461" },
      { "name": "Live red sensation or colorad rose bush", "code": "10202462" },
      { "name": "Live red unique rose bush", "code": "10202463" },
      { "name": "Live rockefeller rose bush", "code": "10202464" },
      { "name": "Live romeo rose bush", "code": "10202465" },
      { "name": "Live rouge baiser rose bush", "code": "10202466" },
      { "name": "Live roulette rose bush", "code": "10202467" },
      { "name": "Live royal massai rose bush", "code": "10202468" },
      { "name": "Live royal red rose bush", "code": "10202469" },
      { "name": "Live samurai rose bush", "code": "10202470" },
      { "name": "Live sexy red rose bush", "code": "10202471" },
      { "name": "Live starfire rose bush", "code": "10202472" },
      { "name": "Live tango rose bush", "code": "10202473" },
      { "name": "Live tiger tail rose bush", "code": "10202474" },
      { "name": "Live tinto rose bush", "code": "10202475" },
      { "name": "Live top secret rose bush", "code": "10202476" },
      { "name": "Live vital rose bush", "code": "10202477" },
      { "name": "Live wisdom rose bush", "code": "10202478" },
      { "name": "Live xantia rose bush", "code": "10202479" },
      { "name": "Live xcite rose bush", "code": "10202480" },
      { "name": "Live sweetheart rose bushes", "code": "10202500" },
      { "name": "Live burgundy sweetheart rose bush", "code": "10202501" },
      { "name": "Live cream sweetheart rose bush", "code": "10202502" },
      { "name": "Live hot pink sweetheart rose bush", "code": "10202503" },
      { "name": "Live lavender sweetheart rose bush", "code": "10202504" },
      { "name": "Live light pink sweetheart rose bush", "code": "10202505" },
      { "name": "Live orange sweetheart rose bush", "code": "10202506" },
      { "name": "Live peach sweetheart rose bush", "code": "10202507" },
      { "name": "Live red sweetheart rose bush", "code": "10202508" },
      { "name": "Live white sweetheart rose bush", "code": "10202509" },
      { "name": "Live yellow sweetheart rose bush", "code": "10202510" },
      { "name": "Live white rose bushes", "code": "10202600" },
      { "name": "Live absolut rose bush", "code": "10202601" },
      { "name": "Live aida rose bush", "code": "10202602" },
      { "name": "Live akito rose bush", "code": "10202603" },
      { "name": "Live amelia rose bush", "code": "10202604" },
      { "name": "Live anastasia rose bush", "code": "10202605" },
      { "name": "Live andean crystal rose bush", "code": "10202606" },
      { "name": "Live angel rose bush", "code": "10202607" },
      { "name": "Live annemarie rose bush", "code": "10202608" },
      { "name": "Live avalanche rose bush", "code": "10202609" },
      { "name": "Live bianca rose bush", "code": "10202610" },
      { "name": "Live blizzard rose bush", "code": "10202611" },
      { "name": "Live bridal akito rose bush", "code": "10202612" },
      { "name": "Live domenica rose bush", "code": "10202613" },
      { "name": "Live escimo rose bush", "code": "10202614" },
      { "name": "Live farfalla rose bush", "code": "10202615" },
      { "name": "Live high and peace rose bush", "code": "10202616" },
      { "name": "Live high and pure rose bush", "code": "10202617" },
      { "name": "Live inocencia or innocenti rose bush", "code": "10202618" },
      { "name": "Live ivory rose bush", "code": "10202619" },
      { "name": "Live mondial rose bush", "code": "10202620" },
      { "name": "Live mount everest rose bush", "code": "10202621" },
      { "name": "Live nova zembla rose bush", "code": "10202622" },
      { "name": "Live patience or auspastor rose bush", "code": "10202623" },
      { "name": "Live polar star rose bush", "code": "10202624" },
      { "name": "Live polo rose bush", "code": "10202625" },
      { "name": "Live proud rose bush", "code": "10202626" },
      { "name": "Live snowy jewel rose bush", "code": "10202627" },
      { "name": "Live tibet rose bush", "code": "10202628" },
      { "name": "Live tineke rose bush", "code": "10202629" },
      { "name": "Live vitality rose bush", "code": "10202630" },
      { "name": "Live white cadillac rose bush", "code": "10202631" },
      { "name": "Live white dove rose bush", "code": "10202632" },
      { "name": "Live yellow rose bushes", "code": "10202700" },
      { "name": "Live aalsmeer gold rose bush", "code": "10202701" },
      { "name": "Live alina rose bush", "code": "10202702" },
      { "name": "Live ambiance rose bush", "code": "10202703" },
      { "name": "Live aquarel rose bush", "code": "10202704" },
      { "name": "Live autumn dream rose bush", "code": "10202705" },
      { "name": "Live brasil rose bush", "code": "10202706" },
      { "name": "Live candle light rose bush", "code": "10202707" },
      { "name": "Live cantata or cantate rose bush", "code": "10202708" },
      { "name": "Live capriccio rose bush", "code": "10202709" },
      { "name": "Live caribbean rose bush", "code": "10202710" },
      { "name": "Live circus rose bush", "code": "10202711" },
      { "name": "Live citran rose bush", "code": "10202712" },
      { "name": "Live concorde rose bush", "code": "10202713" },
      { "name": "Live conga rose bush", "code": "10202714" },
      { "name": "Live deja vu rose bush", "code": "10202715" },
      { "name": "Live desire rose bush", "code": "10202716" },
      { "name": "Live donia sol rose bush", "code": "10202717" },
      { "name": "Live dueto rose bush", "code": "10202718" },
      { "name": "Live erin rose bush", "code": "10202719" },
      { "name": "Live exotic curiosa rose bush", "code": "10202720" },
      { "name": "Live feria rose bush", "code": "10202721" },
      { "name": "Live fire bird rose bush", "code": "10202722" },
      { "name": "Live florida rose bush", "code": "10202723" },
      { "name": "Live friendly rose bush", "code": "10202724" },
      { "name": "Live gallinda rose bush", "code": "10202725" },
      { "name": "Live geisha rose bush", "code": "10202726" },
      { "name": "Live gelbe rose bush", "code": "10202727" },
      { "name": "Live gelosia or yellow flame rose bush", "code": "10202728" },
      { "name": "Live gold rush rose bush", "code": "10202729" },
      { "name": "Live gold star rose bush", "code": "10202730" },
      { "name": "Live gold strike rose bush", "code": "10202731" },
      { "name": "Live golda rose bush", "code": "10202732" },
      { "name": "Live golden fashion rose bush", "code": "10202733" },
      { "name": "Live golden gate rose bush", "code": "10202734" },
      { "name": "Live gran dorado rose bush", "code": "10202735" },
      { "name": "Live helio rose bush", "code": "10202736" },
      { "name": "Live high and exotic rose bush", "code": "10202737" },
      { "name": "Live high and yellow flame rose bush", "code": "10202738" },
      { "name": "Live high and yellow magic rose bush", "code": "10202739" },
      { "name": "Live high society rose bush", "code": "10202740" },
      { "name": "Live hummer rose bush", "code": "10202741" },
      { "name": "Live idole or elle rose bush", "code": "10202742" },
      { "name": "Live inti rose bush", "code": "10202743" },
      { "name": "Live jet set rose bush", "code": "10202744" },
      { "name": "Live judy rose bush", "code": "10202745" },
      { "name": "Live jupiter rose bush", "code": "10202746" },
      { "name": "Live konfetti rose bush", "code": "10202747" },
      { "name": "Live kyara or kira rose bush", "code": "10202748" },
      { "name": "Live latin beauty rose bush", "code": "10202749" },
      { "name": "Live latin spirit rose bush", "code": "10202750" },
      { "name": "Live latina rose bush", "code": "10202751" },
      { "name": "Live lina rose bush", "code": "10202752" },
      { "name": "Live lindsey rose bush", "code": "10202753" },
      { "name": "Live male rose bush", "code": "10202754" },
      { "name": "Live marie claire rose bush", "code": "10202755" },
      { "name": "Live marisa rose bush", "code": "10202756" },
      { "name": "Live matchball rose bush", "code": "10202757" },
      { "name": "Live melon rose bush", "code": "10202758" },
      { "name": "Live mohana rose bush", "code": "10202759" },
      { "name": "Live okie dokie rose bush", "code": "10202760" },
      { "name": "Live pailine rose bush", "code": "10202761" },
      { "name": "Live parrot rose bush", "code": "10202762" },
      { "name": "Live rio d oro rose bush", "code": "10202763" },
      { "name": "Live salami rose bush", "code": "10202764" },
      { "name": "Live santa fe rose bush", "code": "10202765" },
      { "name": "Live skyline rose bush", "code": "10202766" },
      { "name": "Live sonrisa rose bush", "code": "10202767" },
      { "name": "Live star ambiance rose bush", "code": "10202768" },
      { "name": "Live starburst rose bush", "code": "10202769" },
      { "name": "Live sun king rose bush", "code": "10202770" },
      { "name": "Live sunmaster rose bush", "code": "10202771" },
      { "name": "Live sunny milva rose bush", "code": "10202772" },
      { "name": "Live sushi rose bush", "code": "10202773" },
      { "name": "Live tabasco rose bush", "code": "10202774" },
      { "name": "Live tara rose bush", "code": "10202775" },
      { "name": "Live tresor 2000 rose bush", "code": "10202776" },
      { "name": "Live ooty rose bush", "code": "10202777" },
      { "name": "Live yellow coral rose bush", "code": "10202778" },
      { "name": "Live yellow finess rose bush", "code": "10202779" },
      { "name": "Live yellow submarine rose bush", "code": "10202780" },
      { "name": "Live yellow sunset rose bush", "code": "10202781" },
      { "name": "Live yellow timeless rose bush", "code": "10202782" },
      { "name": "Live spray rose bushes", "code": "10202800" },
      { "name": "Live alegria spray rose bush", "code": "10202801" },
      { "name": "Live andrea follies spray rose bush", "code": "10202802" },
      { "name": "Live antara follies spray rose bush", "code": "10202803" },
      { "name": "Live arrow follies spray rose bush", "code": "10202804" },
      { "name": "Live babe spray rose bush", "code": "10202805" },
      { "name": "Live bellina collection spray rose bush", "code": "10202806" },
      { "name": "Live blue moon spray rose bush", "code": "10202807" },
      { "name": "Live chablis spray rose bush", "code": "10202808" },
      { "name": "Live cherry follies spray rose bush", "code": "10202809" },
      { "name": "Live chess spray rose bush", "code": "10202810" },
      { "name": "Live classic lydia spray rose bush", "code": "10202811" },
      { "name": "Live cream gracia spray rose bush", "code": "10202812" },
      { "name": "Live cream lydia spray rose bush", "code": "10202813" },
      { "name": "Live cream sensation spray rose bush", "code": "10202814" },
      { "name": "Live cremita spray rose bush", "code": "10202815" },
      { "name": "Live diablo spray rose bush", "code": "10202816" },
      { "name": "Live electra spray rose bush", "code": "10202817" },
      { "name": "Live fire king spray rose bush", "code": "10202818" },
      { "name": "Live fleur spray rose bush", "code": "10202819" },
      { "name": "Live girlie follies spray rose bush", "code": "10202820" },
      { "name": "Live giselle follies spray rose bush", "code": "10202821" },
      { "name": "Live golden collection spray rose bush", "code": "10202822" },
      { "name": "Live golden mimi spray rose bush", "code": "10202823" },
      { "name": "Live gracia spray rose bush", "code": "10202824" },
      { "name": "Live hot majolica spray rose bush", "code": "10202825" },
      { "name": "Live hot pink follies spray rose bush", "code": "10202826" },
      { "name": "Live ilse spray rose bush", "code": "10202827" },
      { "name": "Live jelena spray rose bush", "code": "10202828" },
      { "name": "Live laminuette spray rose bush", "code": "10202829" },
      { "name": "Live lavender follies spray rose bush", "code": "10202830" },
      { "name": "Live limoncello spray rose bush", "code": "10202831" },
      { "name": "Live little silver spray rose bush", "code": "10202832" },
      { "name": "Live lovely lydia spray rose bush", "code": "10202833" },
      { "name": "Live lucy spray rose bush", "code": "10202834" },
      { "name": "Live lydia spray rose bush", "code": "10202835" },
      { "name": "Live macarena spray rose bush", "code": "10202836" },
      { "name": "Live magic sensation spray rose bush", "code": "10202837" },
      { "name": "Live majolica spray rose bush", "code": "10202838" },
      { "name": "Live mambo number 5 spray rose bush", "code": "10202839" },
      { "name": "Live mambo spray rose bush", "code": "10202840" },
      { "name": "Live marlene spray rose bush", "code": "10202841" },
      { "name": "Live mimi eden spray rose bush", "code": "10202842" },
      { "name": "Live minou spray rose bush", "code": "10202843" },
      { "name": "Live nikita spray rose bush", "code": "10202844" },
      { "name": "Live novel collection spray rose bush", "code": "10202845" },
      { "name": "Live orange success spray rose bush", "code": "10202846" },
      { "name": "Live pepita spray rose bush", "code": "10202847" },
      { "name": "Live pink flash spray rose bush", "code": "10202848" },
      { "name": "Live pink sensation spray rose bush", "code": "10202849" },
      { "name": "Live porcelina spray rose bush", "code": "10202850" },
      { "name": "Live princess spray rose bush", "code": "10202851" },
      { "name": "Live purple mikado spray rose bush", "code": "10202852" },
      { "name": "Live red angel spray rose bush", "code": "10202853" },
      { "name": "Live red collection spray rose bush", "code": "10202854" },
      { "name": "Live red hero spray rose bush", "code": "10202855" },
      { "name": "Live red mikado spray rose bush", "code": "10202856" },
      { "name": "Live red vision spray rose bush", "code": "10202857" },
      { "name": "Live ritmo spray rose bush", "code": "10202858" },
      {
        "name": "Live romance mikado or eva spray rose bush",
        "code": "10202859"
      },
      { "name": "Live romantica follies spray rose bush", "code": "10202860" },
      { "name": "Live rubicon spray rose bush", "code": "10202861" },
      { "name": "Live rumba spray rose bush", "code": "10202862" },
      { "name": "Live salsa spray rose bush", "code": "10202863" },
      { "name": "Live sangrita spray rose bush", "code": "10202864" },
      { "name": "Live santa barbara spray rose bush", "code": "10202865" },
      { "name": "Live sashaba spray rose bush", "code": "10202866" },
      { "name": "Live scarlett spray rose bush", "code": "10202867" },
      { "name": "Live seline spray rose bush", "code": "10202868" },
      { "name": "Live sensation spray rose bush", "code": "10202869" },
      { "name": "Live silver collection spray rose bush", "code": "10202870" },
      { "name": "Live silver sensation spray rose bush", "code": "10202871" },
      { "name": "Live snowdance spray rose bush", "code": "10202872" },
      { "name": "Live snowflake spray rose bush", "code": "10202873" },
      { "name": "Live suncity spray rose bush", "code": "10202874" },
      { "name": "Live super nova spray rose bush", "code": "10202875" },
      { "name": "Live sweet sensation spray rose bush", "code": "10202876" },
      { "name": "Live taifun or typhoon spray rose bush", "code": "10202877" },
      { "name": "Live tamango spray rose bush", "code": "10202878" },
      { "name": "Live tanger follies spray rose bush", "code": "10202879" },
      { "name": "Live tiara spray rose bush", "code": "10202880" },
      { "name": "Live tiramisu spray rose bush", "code": "10202881" },
      { "name": "Live twinkle bride spray rose bush", "code": "10202882" },
      { "name": "Live viviane spray rose bush", "code": "10202883" },
      { "name": "Live white majolica spray rose bush", "code": "10202884" },
      { "name": "Live white mikado spray rose bush", "code": "10202885" },
      { "name": "Live xentina spray rose bush", "code": "10202886" },
      { "name": "Live yellow babe spray rose bush", "code": "10202887" },
      { "name": "Live yellow follies spray rose bush", "code": "10202888" },
      {
        "name": "Live plants of high species or variety count flowers",
        "code": "10210000"
      },
      { "name": "Live anthuriums", "code": "10211500" },
      { "name": "Live chocolate anthurium", "code": "10211501" },
      { "name": "Live dark red anthurium", "code": "10211502" },
      { "name": "Live green anthurium", "code": "10211503" },
      { "name": "Live hot pink anthurium", "code": "10211504" },
      { "name": "Live mickey mouse anthurium", "code": "10211505" },
      { "name": "Live obake green and white anthurium", "code": "10211506" },
      { "name": "Live obake red and green anthurium", "code": "10211507" },
      { "name": "Live orange anthurium", "code": "10211508" },
      { "name": "Live peach anthurium", "code": "10211509" },
      { "name": "Live picasso or speckled anthurium", "code": "10211510" },
      { "name": "Live red anthurium", "code": "10211511" },
      { "name": "Live splash anthurium", "code": "10211512" },
      { "name": "Live tropic fire anthurium", "code": "10211513" },
      { "name": "Live tulip green anthurium", "code": "10211514" },
      { "name": "Live tulip pink anthurium", "code": "10211515" },
      { "name": "Live tulip purple anthurium", "code": "10211516" },
      { "name": "Live tulip red anthurium", "code": "10211517" },
      { "name": "Live white anthurium", "code": "10211518" },
      { "name": "Live wild thing anthurium", "code": "10211519" },
      { "name": "Live alliums", "code": "10211600" },
      { "name": "Live ambassador allium", "code": "10211601" },
      { "name": "Live ampeloprasum allium", "code": "10211602" },
      { "name": "Live bullit or drumstick allium", "code": "10211603" },
      { "name": "Live christophii allium", "code": "10211604" },
      { "name": "Live cowanii spray white allium", "code": "10211605" },
      { "name": "Live giant allium", "code": "10211606" },
      { "name": "Live gladiator allium", "code": "10211607" },
      { "name": "Live globemaster allium", "code": "10211608" },
      { "name": "Live golfball white allium", "code": "10211609" },
      { "name": "Live hair allium", "code": "10211610" },
      { "name": "Live pink giant allium", "code": "10211611" },
      { "name": "Live purple sensation allium", "code": "10211612" },
      { "name": "Live sicilum hanging allium", "code": "10211613" },
      { "name": "Live spider schubertii allium", "code": "10211614" },
      { "name": "Live spray moly allium", "code": "10211615" },
      { "name": "Live spray roseum allium", "code": "10211616" },
      { "name": "Live tuberosum allium", "code": "10211617" },
      { "name": "Live unifolium or spray allium", "code": "10211618" },
      { "name": "Live white mount everest allium", "code": "10211619" },
      { "name": "Live alstroemerias", "code": "10211700" },
      { "name": "Live agropoli alstroemeria", "code": "10211701" },
      { "name": "Live bourgogne alstroemeria", "code": "10211702" },
      { "name": "Live cairo alstroemeria", "code": "10211703" },
      { "name": "Live charmes alstroemeria", "code": "10211704" },
      { "name": "Live cherry bay alstroemeria", "code": "10211705" },
      { "name": "Live cherry white alstroemeria", "code": "10211706" },
      { "name": "Live dame blanche alstroemeria", "code": "10211707" },
      { "name": "Live diamond alstroemeria", "code": "10211708" },
      { "name": "Live gran canaria alstroemeria", "code": "10211709" },
      { "name": "Live harlekijn alstroemeria", "code": "10211710" },
      { "name": "Live indian summer alstroemeria", "code": "10211711" },
      { "name": "Live jamaica alstroemeria", "code": "10211712" },
      { "name": "Live macondo alstroemeria", "code": "10211713" },
      { "name": "Live mistique alstroemeria", "code": "10211714" },
      { "name": "Live my fair alstroemeria", "code": "10211715" },
      { "name": "Live new cairo alstroemeria", "code": "10211716" },
      { "name": "Live nice alstroemeria", "code": "10211717" },
      { "name": "Live orange bowl alstroemeria", "code": "10211718" },
      { "name": "Live orange queens alstroemeria", "code": "10211719" },
      { "name": "Live orange sun alstroemeria", "code": "10211720" },
      { "name": "Live paris alstroemeria", "code": "10211721" },
      { "name": "Live picasso alstroemeria", "code": "10211722" },
      { "name": "Live pink panther alstroemeria", "code": "10211723" },
      { "name": "Live prima donna alstroemeria", "code": "10211724" },
      { "name": "Live red silhouette alstroemeria", "code": "10211725" },
      { "name": "Live sacha alstroemeria", "code": "10211726" },
      { "name": "Live salmon alstroemeria", "code": "10211727" },
      { "name": "Live santiago alstroemeria", "code": "10211728" },
      { "name": "Live senna alstroemeria", "code": "10211729" },
      { "name": "Live snowball alstroemeria", "code": "10211730" },
      { "name": "Live sublime alstroemeria", "code": "10211731" },
      { "name": "Live tropicana alstroemeria", "code": "10211732" },
      { "name": "Live virginia alstroemeria", "code": "10211733" },
      { "name": "Live white alstroemeria", "code": "10211734" },
      { "name": "Live amaranthuses", "code": "10211800" },
      { "name": "Live hanging green amaranthus", "code": "10211801" },
      { "name": "Live hanging red amaranthus", "code": "10211802" },
      { "name": "Live upright bronze amaranthus", "code": "10211803" },
      { "name": "Live upright green amaranthus", "code": "10211804" },
      { "name": "Live upright red amaranthus", "code": "10211805" },
      { "name": "Live amaryllises", "code": "10211900" },
      { "name": "Live naranja amaryllis", "code": "10211901" },
      { "name": "Live orange nagano amaryllis", "code": "10211902" },
      { "name": "Live pygmee mini amaryllis", "code": "10211903" },
      { "name": "Live red lion amaryllis", "code": "10211904" },
      { "name": "Live rilona amaryllis", "code": "10211905" },
      { "name": "Live royal velvet amaryllis", "code": "10211906" },
      { "name": "Live sonatini orange amaryllis", "code": "10211907" },
      { "name": "Live sonatini red amaryllis", "code": "10211908" },
      { "name": "Live tango amaryllis", "code": "10211909" },
      { "name": "Live tinto night amaryllis", "code": "10211910" },
      { "name": "Live anemone", "code": "10212000" },
      { "name": "Live aubergine anemone", "code": "10212001" },
      { "name": "Live black anemone", "code": "10212002" },
      { "name": "Live blue anemone", "code": "10212003" },
      { "name": "Live cerise anemone", "code": "10212004" },
      { "name": "Live coronaria anemone", "code": "10212005" },
      { "name": "Live hot pink anemone", "code": "10212006" },
      { "name": "Live light pink anemone", "code": "10212007" },
      { "name": "Live pink anemone", "code": "10212008" },
      { "name": "Live purple anemone", "code": "10212009" },
      { "name": "Live red anemone", "code": "10212010" },
      { "name": "Live white anemone", "code": "10212011" },
      { "name": "Live asclepias", "code": "10212100" },
      { "name": "Live lavender asclepia", "code": "10212101" },
      { "name": "Live moby dick asclepia", "code": "10212102" },
      { "name": "Live tuberosa asclepia", "code": "10212103" },
      { "name": "Live white asclepia", "code": "10212104" },
      { "name": "Live asters", "code": "10212200" },
      { "name": "Live beauty aster", "code": "10212201" },
      { "name": "Live japanese blue aster", "code": "10212202" },
      { "name": "Live japanese green aster", "code": "10212203" },
      { "name": "Live japanese hot pink aster", "code": "10212204" },
      { "name": "Live japanese lavender aster", "code": "10212205" },
      { "name": "Live japanese light pink aster", "code": "10212206" },
      { "name": "Live japanese peach aster", "code": "10212207" },
      { "name": "Live japanese pink aster", "code": "10212208" },
      { "name": "Live japanese purple aster", "code": "10212209" },
      { "name": "Live japanese red aster", "code": "10212210" },
      { "name": "Live japanese spider aster", "code": "10212211" },
      { "name": "Live japanese white aster", "code": "10212212" },
      { "name": "Live novi belgii hot pink aster", "code": "10212213" },
      { "name": "Live novi belgii lavender aster", "code": "10212214" },
      { "name": "Live novi belgii pink aster", "code": "10212215" },
      { "name": "Live novi belgii purple aster", "code": "10212216" },
      { "name": "Live novi belgii white aster", "code": "10212217" },
      { "name": "Live solidago aster", "code": "10212218" },
      { "name": "Live spider aster", "code": "10212219" },
      { "name": "Live berzelia lanuginosas", "code": "10212300" },
      { "name": "Live abrotanoides berzelia lanuginosa", "code": "10212301" },
      { "name": "Live fireball berzelia lanuginosa", "code": "10212302" },
      { "name": "Live galpinii berzelia lanuginosa", "code": "10212303" },
      {
        "name": "Live galpinii or baubles berzelia lanuginosa",
        "code": "10212304"
      },
      { "name": "Live squarrosa berzelia lanuginosa", "code": "10212305" },
      { "name": "Live bouvardias", "code": "10212400" },
      { "name": "Live hot pink double bouvardia", "code": "10212401" },
      { "name": "Live light pink bouvardia", "code": "10212402" },
      { "name": "Live light pink double bouvardia", "code": "10212403" },
      { "name": "Live red bouvardia", "code": "10212404" },
      { "name": "Live white bouvardia", "code": "10212405" },
      { "name": "Live brodiaeas", "code": "10212500" },
      { "name": "Live congesta brodiaea", "code": "10212501" },
      { "name": "Live congesta lavender brodiaea", "code": "10212502" },
      { "name": "Live hyacintha brodiaea", "code": "10212503" },
      { "name": "Live ida maija brodiaea", "code": "10212504" },
      { "name": "Live starlight brodiaea", "code": "10212505" },
      { "name": "Live callas", "code": "10212600" },
      { "name": "Live green goddess calla", "code": "10212601" },
      { "name": "Live posey albertville calla", "code": "10212602" },
      { "name": "Live posey aranal calla", "code": "10212603" },
      { "name": "Live posey black eyed beauty calla", "code": "10212604" },
      { "name": "Live posey black star calla", "code": "10212605" },
      { "name": "Live posey brisbane calla", "code": "10212606" },
      { "name": "Live posey crystal blush calla", "code": "10212607" },
      { "name": "Live posey crystal pink calla", "code": "10212608" },
      { "name": "Live posey crystal white calla", "code": "10212609" },
      { "name": "Live posey dark captain romanc calla", "code": "10212610" },
      { "name": "Live posey dark mozart calla", "code": "10212611" },
      { "name": "Live posey dark naomi calla", "code": "10212612" },
      { "name": "Live posey deformed calla", "code": "10212613" },
      { "name": "Live posey dordogne calla", "code": "10212614" },
      { "name": "Live posey etude calla", "code": "10212615" },
      { "name": "Live posey farao calla", "code": "10212616" },
      { "name": "Live posey fire glow calla", "code": "10212617" },
      { "name": "Live posey florex gold calla", "code": "10212618" },
      { "name": "Live posey garnet glow calla", "code": "10212619" },
      { "name": "Live posey hot chocolate calla", "code": "10212620" },
      { "name": "Live posey lavender improved calla", "code": "10212621" },
      { "name": "Live posey light cromance calla", "code": "10212622" },
      { "name": "Live posey little suzy calla", "code": "10212623" },
      { "name": "Live posey majestic red calla", "code": "10212624" },
      { "name": "Live posey mango calla", "code": "10212625" },
      { "name": "Live posey merlot calla", "code": "10212626" },
      { "name": "Live posey mozart calla", "code": "10212627" },
      { "name": "Live posey naomi calla", "code": "10212628" },
      { "name": "Live posey night cap calla", "code": "10212629" },
      { "name": "Live posey odessa calla", "code": "10212630" },
      { "name": "Live posey pacific pink calla", "code": "10212631" },
      { "name": "Live posey passion fruit calla", "code": "10212632" },
      { "name": "Live posey picasso calla", "code": "10212633" },
      { "name": "Live posey pillow talk calla", "code": "10212634" },
      { "name": "Live posey pink persuation calla", "code": "10212635" },
      { "name": "Live posey pisa calla", "code": "10212636" },
      { "name": "Live posey pot of calla", "code": "10212637" },
      { "name": "Live posey red sox calla", "code": "10212638" },
      { "name": "Live posey rosa calla", "code": "10212639" },
      { "name": "Live posey ruby light rose calla", "code": "10212640" },
      { "name": "Live posey samur calla", "code": "10212641" },
      { "name": "Live posey sapporo calla", "code": "10212642" },
      { "name": "Live posey schwarzwalder calla", "code": "10212643" },
      { "name": "Live posey serrada calla", "code": "10212644" },
      { "name": "Live posey solemio calla", "code": "10212645" },
      { "name": "Live posey sunrise calla", "code": "10212646" },
      { "name": "Live posey super mac calla", "code": "10212647" },
      { "name": "Live posey swan lake calla", "code": "10212648" },
      { "name": "Live posey vermeer calla", "code": "10212649" },
      { "name": "Live posey white butterfly calla", "code": "10212650" },
      { "name": "Live posey yellow calla", "code": "10212651" },
      { "name": "Live posey yellow mozart calla", "code": "10212652" },
      { "name": "Live white large calla", "code": "10212653" },
      { "name": "Live celosias", "code": "10212800" },
      { "name": "Live cockscomb green celosia", "code": "10212801" },
      { "name": "Live cockscomb orange celosia", "code": "10212802" },
      { "name": "Live cockscomb pink celosia", "code": "10212803" },
      { "name": "Live cockscomb purple celosia", "code": "10212804" },
      { "name": "Live cockscomb red celosia", "code": "10212805" },
      { "name": "Live cockscomb yellow celosia", "code": "10212806" },
      { "name": "Live plume light pink celosia", "code": "10212807" },
      { "name": "Live plume orange celosia", "code": "10212808" },
      { "name": "Live plume purple celosia", "code": "10212809" },
      { "name": "Live plume red celosia", "code": "10212810" },
      { "name": "Live plume yellow celosia", "code": "10212811" },
      { "name": "Live wheat pink celosia", "code": "10212812" },
      { "name": "Live wheat yellow celosia", "code": "10212813" },
      { "name": "Live daffodils", "code": "10212900" },
      { "name": "Live dick wilden daffodil", "code": "10212901" },
      { "name": "Live dutch master daffodil", "code": "10212902" },
      { "name": "Live ice follies daffodil", "code": "10212903" },
      { "name": "Live ice king daffodil", "code": "10212904" },
      { "name": "Live johan strauss daffodil", "code": "10212905" },
      { "name": "Live yellow carlton daffodil", "code": "10212906" },
      { "name": "Live dahlias", "code": "10213000" },
      { "name": "Live bi color dahlia", "code": "10213001" },
      { "name": "Live hot pink dahlia", "code": "10213002" },
      { "name": "Live light pink dahlia", "code": "10213003" },
      { "name": "Live medium pink dahlia", "code": "10213004" },
      { "name": "Live orange dahlia", "code": "10213005" },
      { "name": "Live peach dahlia", "code": "10213006" },
      { "name": "Live purple dahlia", "code": "10213007" },
      { "name": "Live red dahlia", "code": "10213008" },
      { "name": "Live white dahlia", "code": "10213009" },
      { "name": "Live yellow dahlia", "code": "10213010" },
      { "name": "Live delphiniums", "code": "10213100" },
      { "name": "Live bella dark blue delphinium", "code": "10213101" },
      { "name": "Live bella light blue delphinium", "code": "10213102" },
      { "name": "Live bella white delphinium", "code": "10213103" },
      { "name": "Live blue shadow delphinium", "code": "10213104" },
      { "name": "Live hybrid dark blue delphinium", "code": "10213105" },
      { "name": "Live hybrid light blue delphinium", "code": "10213106" },
      { "name": "Live hybrid mauve delphinium", "code": "10213107" },
      { "name": "Live hybrid pink delphinium", "code": "10213108" },
      { "name": "Live hybrid purple delphinium", "code": "10213109" },
      { "name": "Live hybrid red delphinium", "code": "10213110" },
      { "name": "Live hybrid white delphinium", "code": "10213111" },
      { "name": "Live princess caroline delphinium", "code": "10213112" },
      { "name": "Live volkerfrieden delphinium", "code": "10213113" },
      { "name": "Live dianthuses", "code": "10213200" },
      { "name": "Live chocolate dianthus", "code": "10213201" },
      { "name": "Live fuchsia dianthus", "code": "10213202" },
      { "name": "Live green ball dianthus", "code": "10213203" },
      { "name": "Live hot pink dianthus", "code": "10213204" },
      { "name": "Live lavender dianthus", "code": "10213205" },
      { "name": "Live raspberry dianthus", "code": "10213206" },
      { "name": "Live red dianthus", "code": "10213207" },
      { "name": "Live rosie pink dianthus", "code": "10213208" },
      { "name": "Live eremuruses", "code": "10213300" },
      { "name": "Live deruyter hybrid eremurus", "code": "10213301" },
      { "name": "Live himalaicus white eremurus", "code": "10213302" },
      { "name": "Live orange eremurus", "code": "10213303" },
      { "name": "Live peach eremurus", "code": "10213304" },
      { "name": "Live yellow eremurus", "code": "10213305" },
      { "name": "Live ericas", "code": "10213400" },
      { "name": "Live campunalarus erica", "code": "10213401" },
      { "name": "Live conica erica", "code": "10213402" },
      { "name": "Live green ice erica", "code": "10213403" },
      { "name": "Live pink erica", "code": "10213404" },
      { "name": "Live prince of whales erica", "code": "10213405" },
      { "name": "Live euphorbias", "code": "10213500" },
      { "name": "Live characias euphorbia", "code": "10213501" },
      { "name": "Live griffithii fireglow euphorbia", "code": "10213502" },
      { "name": "Live martini euphorbia", "code": "10213503" },
      { "name": "Live orange euphorbia", "code": "10213504" },
      { "name": "Live peach euphorbia", "code": "10213505" },
      { "name": "Live pink euphorbia", "code": "10213506" },
      { "name": "Live red euphorbia", "code": "10213507" },
      { "name": "Live white euphorbia", "code": "10213508" },
      { "name": "Live yellow euphorbia", "code": "10213509" },
      { "name": "Live yellow spurge euphorbia", "code": "10213510" },
      { "name": "Live freesias", "code": "10213600" },
      { "name": "Live cream freesia", "code": "10213601" },
      { "name": "Live double white freesia", "code": "10213602" },
      { "name": "Live double yellow freesia", "code": "10213603" },
      { "name": "Live hot pink freesia", "code": "10213604" },
      { "name": "Live lady brunet freesia", "code": "10213605" },
      { "name": "Live lavender freesia", "code": "10213606" },
      { "name": "Live medium pink freesia", "code": "10213607" },
      { "name": "Live orange freesia", "code": "10213608" },
      { "name": "Live pimpernel freesia", "code": "10213609" },
      { "name": "Live pink freesia", "code": "10213610" },
      { "name": "Live purple freesia", "code": "10213611" },
      { "name": "Live red freesia", "code": "10213612" },
      { "name": "Live white freesia", "code": "10213613" },
      { "name": "Live yellow freesia", "code": "10213614" },
      { "name": "Live fritillarias", "code": "10213700" },
      { "name": "Live acmopelata fritillaria", "code": "10213701" },
      { "name": "Live assyriaca fritillaria", "code": "10213702" },
      { "name": "Live assyrica uva vulpis frittilarias", "code": "10213703" },
      { "name": "Live elysee fritillaria", "code": "10213704" },
      { "name": "Live imperialis orange fritillaria", "code": "10213705" },
      { "name": "Live imperialis yellow fritillaria", "code": "10213706" },
      { "name": "Live meleagris fritillaria", "code": "10213707" },
      { "name": "Live michailowski fritillaria", "code": "10213708" },
      { "name": "Live uva vulpis frittilaria", "code": "10213709" },
      { "name": "Live genistas", "code": "10213800" },
      { "name": "Live green genista", "code": "10213801" },
      { "name": "Live hot pink genista", "code": "10213802" },
      { "name": "Live lavender genista", "code": "10213803" },
      { "name": "Live light pink genista", "code": "10213804" },
      { "name": "Live peach genista", "code": "10213805" },
      { "name": "Live purple genista", "code": "10213806" },
      { "name": "Live white genista", "code": "10213807" },
      { "name": "Live yellow genista", "code": "10213808" },
      { "name": "Live gerberas", "code": "10213900" },
      { "name": "Live cream black center gerbera", "code": "10213901" },
      { "name": "Live cream gerbera", "code": "10213902" },
      { "name": "Live gold gerbera", "code": "10213903" },
      { "name": "Live hot pink gerbera", "code": "10213904" },
      { "name": "Live light pink gerbera", "code": "10213905" },
      { "name": "Live magenta gerbera", "code": "10213906" },
      { "name": "Live mini coral gerbera", "code": "10213907" },
      { "name": "Live mini fuchsia gerbera", "code": "10213908" },
      { "name": "Live mini hot pink gerbera", "code": "10213909" },
      {
        "name": "Live mini light orange black center gerbera",
        "code": "10213910"
      },
      { "name": "Live mini orange black center gerbera", "code": "10213911" },
      { "name": "Live mini orange gerbera", "code": "10213912" },
      { "name": "Live mini red black center gerbera", "code": "10213913" },
      { "name": "Live mini white gerbera", "code": "10213914" },
      { "name": "Live mini yellow black center gerbera", "code": "10213915" },
      { "name": "Live orange black center gerbera", "code": "10213916" },
      { "name": "Live orange gerbera", "code": "10213917" },
      { "name": "Live peach black center gerbera", "code": "10213918" },
      { "name": "Live peach gerbera", "code": "10213919" },
      { "name": "Live pink black center gerbera", "code": "10213920" },
      { "name": "Live pink gerbera", "code": "10213921" },
      { "name": "Live red black center gerbera", "code": "10213922" },
      { "name": "Live red gerbera", "code": "10213923" },
      { "name": "Live spider peach gerbera", "code": "10213924" },
      { "name": "Live spider red gerbera", "code": "10213925" },
      { "name": "Live terracotta gerbera", "code": "10213926" },
      { "name": "Live white black center gerbera", "code": "10213927" },
      { "name": "Live white gerbera", "code": "10213928" },
      { "name": "Live yellow gerbera", "code": "10213929" },
      { "name": "Live ginger plants", "code": "10214000" },
      { "name": "Live indonesian ginger", "code": "10214001" },
      { "name": "Live jungle king pink ginger", "code": "10214002" },
      { "name": "Live jungle king red ginger", "code": "10214003" },
      { "name": "Live pink ginger", "code": "10214004" },
      { "name": "Live red ginger", "code": "10214005" },
      { "name": "Live torch ginger", "code": "10214006" },
      { "name": "Live gladioluses", "code": "10214100" },
      { "name": "Live burgundy gladiolus", "code": "10214101" },
      { "name": "Live fuchsia gladiolus", "code": "10214102" },
      { "name": "Live green gladiolus", "code": "10214103" },
      { "name": "Live hot pink gladiolus", "code": "10214104" },
      { "name": "Live light pink gladiolus", "code": "10214105" },
      { "name": "Live orange gladiolus", "code": "10214106" },
      { "name": "Live peach gladiolus", "code": "10214107" },
      { "name": "Live pink medium gladiolus", "code": "10214108" },
      { "name": "Live purple gladiolus", "code": "10214109" },
      { "name": "Live red bi color gladiolus", "code": "10214110" },
      { "name": "Live red gladiolus", "code": "10214111" },
      { "name": "Live salmon gladiolus", "code": "10214112" },
      { "name": "Live white gladiolus", "code": "10214113" },
      { "name": "Live yellow gladiolus", "code": "10214114" },
      { "name": "Live godetias", "code": "10214200" },
      { "name": "Live bi color godetia", "code": "10214201" },
      { "name": "Live fuchsia godetia", "code": "10214202" },
      { "name": "Live hot pink godetia", "code": "10214203" },
      { "name": "Live orange godetia", "code": "10214204" },
      { "name": "Live red godetia", "code": "10214205" },
      { "name": "Live white godetia", "code": "10214206" },
      { "name": "Live guzmanias", "code": "10214300" },
      { "name": "Live lingulata orange guzmania", "code": "10214301" },
      { "name": "Live lingulata red guzmania", "code": "10214302" },
      { "name": "Live lingulata white guzmania", "code": "10214303" },
      { "name": "Live lingulata yellow guzmania", "code": "10214304" },
      { "name": "Live variegata guzmania", "code": "10214305" },
      { "name": "Live gypsophilias", "code": "10214400" },
      { "name": "Live bambino gypsophilia", "code": "10214401" },
      { "name": "Live million stars gypsophilia", "code": "10214402" },
      { "name": "Live mirabella gypsophilia", "code": "10214403" },
      { "name": "Live new love gypsophilia", "code": "10214404" },
      { "name": "Live orion gypsophilia", "code": "10214405" },
      { "name": "Live perfecta gypsophilia", "code": "10214406" },
      { "name": "Live heather", "code": "10214500" },
      { "name": "Live augustine heather", "code": "10214501" },
      { "name": "Live erica four sisters heather", "code": "10214502" },
      { "name": "Live french heather", "code": "10214503" },
      { "name": "Live green heather", "code": "10214504" },
      { "name": "Live sterling range white heather", "code": "10214505" },
      { "name": "Live sunset pink heather", "code": "10214506" },
      { "name": "Live white heather", "code": "10214507" },
      { "name": "Live heliconias", "code": "10214600" },
      { "name": "Live bihai claw heliconia", "code": "10214601" },
      { "name": "Live bihai flash heliconia", "code": "10214602" },
      { "name": "Live bihai lobster claw heliconia", "code": "10214603" },
      { "name": "Live caribea red heliconia", "code": "10214604" },
      { "name": "Live caribea yellow heliconia", "code": "10214605" },
      { "name": "Live christmas heliconia", "code": "10214606" },
      { "name": "Live edge of night heliconia", "code": "10214607" },
      { "name": "Live green bihai heliconia", "code": "10214608" },
      { "name": "Live marginata lutea heliconia", "code": "10214609" },
      { "name": "Live psitt fire opal heliconia", "code": "10214610" },
      { "name": "Live psittacorum heliconia", "code": "10214611" },
      { "name": "Live richmond red heliconia", "code": "10214612" },
      { "name": "Live rostrata heliconia", "code": "10214613" },
      { "name": "Live sexy pink heliconia", "code": "10214614" },
      { "name": "Live sexy scarlett heliconia", "code": "10214615" },
      { "name": "Live shogun heliconia", "code": "10214616" },
      { "name": "Live small red heliconia", "code": "10214617" },
      { "name": "Live southern cross heliconia", "code": "10214618" },
      { "name": "Live wagneriana heliconia", "code": "10214619" },
      { "name": "Live hyacinths", "code": "10214700" },
      { "name": "Live bean hyacinths", "code": "10214701" },
      { "name": "Live apricot hyacinth", "code": "10214702" },
      { "name": "Live blue hyacinth", "code": "10214703" },
      { "name": "Live fuchsia hyacinth", "code": "10214704" },
      { "name": "Live hot pink hyacinth", "code": "10214705" },
      { "name": "Live lavender hyacinth", "code": "10214706" },
      { "name": "Live light blue hyacinth", "code": "10214707" },
      { "name": "Live medium pink hyacinth", "code": "10214708" },
      { "name": "Live pink hyacinth", "code": "10214709" },
      { "name": "Live purple star hyacinth", "code": "10214710" },
      { "name": "Live white hyacinth", "code": "10214711" },
      { "name": "Live yellow hyacinth", "code": "10214712" },
      { "name": "Live hydrangeas", "code": "10214800" },
      { "name": "Live annabelle hydrangea", "code": "10214801" },
      { "name": "Live antique blue hydrangea", "code": "10214802" },
      {
        "name": "Live antique blue or green or new zealand hydrangea",
        "code": "10214803"
      },
      { "name": "Live antique green hydrangea", "code": "10214804" },
      { "name": "Live antique pink hydrangea", "code": "10214805" },
      {
        "name": "Live antique purple or new zealand hydrangea",
        "code": "10214806"
      },
      { "name": "Live aubergene or new zealand hydrangea", "code": "10214807" },
      { "name": "Live dark blue hydrangea", "code": "10214808" },
      { "name": "Live dark pink hydrangea", "code": "10214809" },
      { "name": "Live dark purple hydrangea", "code": "10214810" },
      { "name": "Live eggplant hydrangea", "code": "10214811" },
      { "name": "Live green dyed hydrangea", "code": "10214812" },
      { "name": "Live green lemon hydrangea", "code": "10214813" },
      { "name": "Live hot pink hydrangea", "code": "10214814" },
      { "name": "Live jumbo white hydrangea", "code": "10214815" },
      { "name": "Live lavender or new zealand hydrangea", "code": "10214816" },
      { "name": "Live light blue hydrangea", "code": "10214817" },
      { "name": "Live light pink large hydrangea", "code": "10214818" },
      { "name": "Live lime green large hydrangea", "code": "10214819" },
      { "name": "Live mini green hydrangea", "code": "10214820" },
      { "name": "Live oakleaf hydrangea", "code": "10214821" },
      { "name": "Live oakleaf snowflake hydrangea", "code": "10214822" },
      { "name": "Live pink dyed hydrangea", "code": "10214823" },
      { "name": "Live pink hydrangea", "code": "10214824" },
      { "name": "Live purple or new zealand hydrangea", "code": "10214825" },
      { "name": "Live red dyed hydrangea", "code": "10214826" },
      { "name": "Live shocking blue hydrangea", "code": "10214827" },
      { "name": "Live tardiva hydrangea", "code": "10214828" },
      { "name": "Live white hydrangea", "code": "10214829" },
      { "name": "Live irises", "code": "10214900" },
      { "name": "Live black bearded iris", "code": "10214901" },
      { "name": "Live bearded blue iris", "code": "10214902" },
      { "name": "Live bearded lavender iris", "code": "10214903" },
      { "name": "Live bearded light blue iris", "code": "10214904" },
      { "name": "Live bearded purple iris", "code": "10214905" },
      { "name": "Live bearded red iris", "code": "10214906" },
      { "name": "Live bearded white iris", "code": "10214907" },
      { "name": "Live bearded white and purple iris", "code": "10214908" },
      { "name": "Live bearded yellow iris", "code": "10214909" },
      { "name": "Live blue elegance iris", "code": "10214910" },
      { "name": "Live casablanca iris", "code": "10214911" },
      { "name": "Live golden beau iris", "code": "10214912" },
      { "name": "Live hildegard iris", "code": "10214913" },
      { "name": "Live hong kong iris", "code": "10214914" },
      { "name": "Live ideal iris", "code": "10214915" },
      { "name": "Live professor blue iris", "code": "10214916" },
      { "name": "Live purple iris", "code": "10214917" },
      { "name": "Live spuria iris", "code": "10214918" },
      { "name": "Live telstar iris", "code": "10214919" },
      { "name": "Live kangaroo paws", "code": "10215000" },
      { "name": "Live bi color kangaroo paw", "code": "10215001" },
      { "name": "Live black kangaroo paw", "code": "10215002" },
      { "name": "Live green kangaroo paw", "code": "10215003" },
      { "name": "Live orange kangaroo paw", "code": "10215004" },
      { "name": "Live pink kangaroo paw", "code": "10215005" },
      { "name": "Live red kangaroo paw", "code": "10215006" },
      { "name": "Live yellow kangaroo paw", "code": "10215007" },
      { "name": "Live larkspurs", "code": "10215100" },
      { "name": "Live blue cloud larkspur", "code": "10215101" },
      { "name": "Live dark pink larkspur", "code": "10215102" },
      { "name": "Live lavender larkspur", "code": "10215103" },
      { "name": "Live light pink larkspur", "code": "10215104" },
      { "name": "Live purple larkspur", "code": "10215105" },
      { "name": "Live white larkspur", "code": "10215106" },
      { "name": "Live leptos", "code": "10215200" },
      { "name": "Live blue or flowering lepto", "code": "10215201" },
      { "name": "Live hot pink lepto", "code": "10215202" },
      { "name": "Live light pink lepto", "code": "10215203" },
      { "name": "Live pink lepto", "code": "10215204" },
      { "name": "Live red lepto", "code": "10215205" },
      { "name": "Live white lepto", "code": "10215206" },
      { "name": "Live lilacs", "code": "10215300" },
      { "name": "Live french hybrid lavender lilac", "code": "10215301" },
      { "name": "Live french hybrid purple lilac", "code": "10215302" },
      { "name": "Live purple lilac", "code": "10215303" },
      { "name": "Live vine lilac", "code": "10215304" },
      { "name": "Live white lilac", "code": "10215305" },
      { "name": "Live wild white lilac", "code": "10215306" },
      { "name": "Live lilies", "code": "10215400" },
      {
        "name": "Live highness longiflorum and asiatic hybrid lily",
        "code": "10215401"
      },
      { "name": "Live asiatic black out lily", "code": "10215402" },
      { "name": "Live asiatic dark pink lily", "code": "10215403" },
      { "name": "Live asiatic electric lily", "code": "10215404" },
      { "name": "Live asiatic festival lily", "code": "10215405" },
      { "name": "Live asiatic geneva lily", "code": "10215406" },
      { "name": "Live asiatic light pink lily", "code": "10215407" },
      { "name": "Live asiatic lollipop lily", "code": "10215408" },
      { "name": "Live asiatic miss america purple lily", "code": "10215409" },
      { "name": "Live asiatic monte negro lily", "code": "10215410" },
      { "name": "Live asiatic orange lily", "code": "10215411" },
      { "name": "Live asiatic peach cannes lily", "code": "10215412" },
      { "name": "Live asiatic pink lily", "code": "10215413" },
      { "name": "Live asiatic sancerre lily", "code": "10215414" },
      { "name": "Live asiatic white dream lily", "code": "10215415" },
      { "name": "Live asiatic yellow lily", "code": "10215416" },
      {
        "name": "Live bright diamond longiflorum and asiatic hybrid lily",
        "code": "10215417"
      },
      {
        "name": "Live brindisi longiflorum and asiatic hybrid lily",
        "code": "10215418"
      },
      {
        "name": "Live carmine longiflorum and asiatic hybrid lily",
        "code": "10215419"
      },
      {
        "name": "Live cinnabar longiflorum and asiatic hybrid lily",
        "code": "10215420"
      },
      {
        "name": "Live club longiflorum and asiatic hybrid lily",
        "code": "10215421"
      },
      {
        "name": "Live discovery longiflorum and asiatic hybrid lily",
        "code": "10215422"
      },
      { "name": "Live easter lily", "code": "10215423" },
      {
        "name": "Live isis longiflorum and asiatic hybrid lily",
        "code": "10215424"
      },
      {
        "name": "Live la hybrid justice longiflorum and asiatic hybrid lily",
        "code": "10215425"
      },
      {
        "name": "Live lace longiflorum and asiatic hybrid lily",
        "code": "10215426"
      },
      { "name": "Live lily of the valley", "code": "10215427" },
      {
        "name": "Live love longiflorum and asiatic hybrid lily",
        "code": "10215428"
      },
      {
        "name": "Live menorca longiflorum and asiatic hybrid lily",
        "code": "10215429"
      },
      { "name": "Live oriental acapulco lily", "code": "10215430" },
      { "name": "Live oriental albion lily", "code": "10215431" },
      { "name": "Live oriental argentina lily", "code": "10215432" },
      { "name": "Live oriental auratum lily", "code": "10215433" },
      { "name": "Live oriental barbaresco lily", "code": "10215434" },
      { "name": "Live oriental bernini lily", "code": "10215435" },
      { "name": "Live oriental beseno lily", "code": "10215436" },
      { "name": "Live oriental broadway lily", "code": "10215437" },
      { "name": "Live oriental canada lily", "code": "10215438" },
      { "name": "Live oriental casablanca lily", "code": "10215439" },
      { "name": "Live oriental chili lily", "code": "10215440" },
      { "name": "Live oriental chrystal blanca lily", "code": "10215441" },
      { "name": "Live oriental cobra lily", "code": "10215442" },
      { "name": "Live oriental conca d or lily", "code": "10215443" },
      { "name": "Live oriental cote d ivor lily", "code": "10215444" },
      { "name": "Live oriental dizzy lily", "code": "10215445" },
      { "name": "Live oriental fireball lily", "code": "10215446" },
      { "name": "Live oriental gluhwein lily", "code": "10215447" },
      { "name": "Live oriental goldband lily", "code": "10215448" },
      { "name": "Live oriental halifax lily", "code": "10215449" },
      { "name": "Live oriental kathryn lily", "code": "10215450" },
      { "name": "Live oriental kyoto lily", "code": "10215451" },
      { "name": "Live oriental la mancha lily", "code": "10215452" },
      { "name": "Live oriental medusa lily", "code": "10215453" },
      { "name": "Live oriental montezuma lily", "code": "10215454" },
      { "name": "Live oriental muscadet lily", "code": "10215455" },
      { "name": "Live oriental nippon lily", "code": "10215456" },
      { "name": "Live oriental opus one lily", "code": "10215457" },
      { "name": "Live oriental pompeii lily", "code": "10215458" },
      { "name": "Live oriental rialto lily", "code": "10215459" },
      { "name": "Live oriental robina lily", "code": "10215460" },
      { "name": "Live oriental rousillon lily", "code": "10215461" },
      { "name": "Live oriental siberia lily", "code": "10215462" },
      { "name": "Live oriental sorbonne lily", "code": "10215463" },
      { "name": "Live oriental starfighter lily", "code": "10215464" },
      { "name": "Live oriental stargazer lily", "code": "10215465" },
      { "name": "Live oriental sumatra lily", "code": "10215466" },
      { "name": "Live oriental time out lily", "code": "10215467" },
      { "name": "Live oriental tom pouche lily", "code": "10215468" },
      { "name": "Live oriental tropical lily", "code": "10215469" },
      { "name": "Live oriental white cup lily", "code": "10215470" },
      { "name": "Live oriental white merostar lily", "code": "10215471" },
      { "name": "Live oriental white montana lily", "code": "10215472" },
      { "name": "Live oriental white stargazer lily", "code": "10215473" },
      { "name": "Live oriental yellow band lily", "code": "10215474" },
      { "name": "Live oriental yellow dream lily", "code": "10215475" },
      { "name": "Live oriental yellow queen lily", "code": "10215476" },
      { "name": "Live oriental yellow star lily", "code": "10215477" },
      { "name": "Live oriental yelloween lily", "code": "10215478" },
      { "name": "Live ot red dutch lily", "code": "10215479" },
      { "name": "Live sonata nimph lily", "code": "10215480" },
      { "name": "Live sonata shocking lily", "code": "10215481" },
      { "name": "Live sonata triumphater lily", "code": "10215482" },
      {
        "name": "Live sunset longiflorum and asiatic hybrid lily",
        "code": "10215483"
      },
      { "name": "Live water lily", "code": "10215484" },
      { "name": "Live limoniums", "code": "10215500" },
      { "name": "Live misty peach limonium", "code": "10215501" },
      { "name": "Live misty pink limonium", "code": "10215502" },
      { "name": "Live misty white limonium", "code": "10215503" },
      { "name": "Live misty yellow limonium", "code": "10215504" },
      { "name": "Live safora limonium", "code": "10215505" },
      { "name": "Live sinensis limonium", "code": "10215506" },
      { "name": "Live lisianthuses", "code": "10215600" },
      { "name": "Live creme lisianthus", "code": "10215601" },
      { "name": "Live dark pink lisianthus", "code": "10215602" },
      { "name": "Live green lisianthus", "code": "10215603" },
      { "name": "Live lavender lisianthus", "code": "10215604" },
      { "name": "Live light pink lisianthus", "code": "10215605" },
      { "name": "Live mini white lisianthus", "code": "10215606" },
      { "name": "Live peach lisianthus", "code": "10215607" },
      { "name": "Live pink with white edge lisianthus", "code": "10215608" },
      { "name": "Live purple lisianthus", "code": "10215609" },
      { "name": "Live purple with white edge lisianthus", "code": "10215610" },
      { "name": "Live white with pink edge lisianthus", "code": "10215611" },
      { "name": "Live white lisianthus", "code": "10215612" },
      { "name": "Live white with purple edge lisianthus", "code": "10215613" },
      { "name": "Live muscari plants or grape hyacinths", "code": "10215700" },
      { "name": "Live armeniacum muscari", "code": "10215701" },
      { "name": "Live bortyoides white muscari", "code": "10215702" },
      { "name": "Live green muscari", "code": "10215703" },
      { "name": "Live latifolia muscari", "code": "10215704" },
      { "name": "Live valerie finn muscari", "code": "10215705" },
      { "name": "Live narcissus", "code": "10215800" },
      { "name": "Live cheerfulness narcissus", "code": "10215801" },
      { "name": "Live golden dawn narcissus", "code": "10215802" },
      { "name": "Live paperwhite abba narcissus", "code": "10215803" },
      { "name": "Live paperwhite narcissus", "code": "10215804" },
      { "name": "Live pheasant eye narcissus", "code": "10215805" },
      { "name": "Live soleil d or narcissus", "code": "10215806" },
      { "name": "Live tete a tete narcissus", "code": "10215807" },
      { "name": "Live thalia narcissus", "code": "10215808" },
      { "name": "Live ornamental peppers", "code": "10216000" },
      { "name": "Live ornamental chili pepper", "code": "10216001" },
      { "name": "Live ornamental mixed pepper", "code": "10216002" },
      { "name": "Live ornamental orange pepper", "code": "10216003" },
      { "name": "Live ornamental red pepper", "code": "10216004" },
      { "name": "Live ornamental yellow pepper", "code": "10216005" },
      { "name": "Live ornithogalums", "code": "10216100" },
      { "name": "Live arabicum ornithogalum", "code": "10216101" },
      { "name": "Live orange dubium ornithogalum", "code": "10216102" },
      { "name": "Live umbellatum ornithogalum", "code": "10216103" },
      { "name": "Live white dubium ornithogalum", "code": "10216104" },
      { "name": "Live yellow dubium ornithogalum", "code": "10216105" },
      { "name": "Live peonies", "code": "10216200" },
      { "name": "Live alexander fleming peony", "code": "10216201" },
      { "name": "Live coral charm peony", "code": "10216202" },
      { "name": "Live coral sunset peony", "code": "10216203" },
      { "name": "Live coral supreme peony", "code": "10216204" },
      { "name": "Live double gardenia peony", "code": "10216205" },
      { "name": "Live double jules eli dark peony", "code": "10216206" },
      { "name": "Live double white dutchess peony", "code": "10216207" },
      { "name": "Live felix crousse peony", "code": "10216208" },
      { "name": "Live festiva maxima peony", "code": "10216209" },
      { "name": "Live garden treasure peony", "code": "10216210" },
      { "name": "Live kansas dark pink peony", "code": "10216211" },
      { "name": "Live karl rosenfelt peony", "code": "10216212" },
      { "name": "Live paula fay peony", "code": "10216213" },
      { "name": "Live red charm peony", "code": "10216214" },
      { "name": "Live red passion peony", "code": "10216215" },
      { "name": "Live sarah bernhardt pink peony", "code": "10216216" },
      { "name": "Live scarlet o hara peony", "code": "10216217" },
      { "name": "Live shirley temple peony", "code": "10216218" },
      { "name": "Live banksias", "code": "10216300" },
      { "name": "Live ashbyi banksia", "code": "10216301" },
      { "name": "Live baxteri banksia", "code": "10216302" },
      { "name": "Live coccinea banksia", "code": "10216306" },
      { "name": "Live ericifolia banksia", "code": "10216311" },
      { "name": "Live green banksia", "code": "10216315" },
      { "name": "Live menziesii banksia", "code": "10216322" },
      { "name": "Live natural white banksia", "code": "10216325" },
      { "name": "Live orange banksia", "code": "10216326" },
      { "name": "Live pink banksia", "code": "10216332" },
      { "name": "Live ranunculuses", "code": "10216400" },
      { "name": "Live chocolate ranunculus", "code": "10216401" },
      { "name": "Live elegance ranunculus", "code": "10216402" },
      { "name": "Live green ranunculus", "code": "10216403" },
      { "name": "Live grimaldi ranunculus", "code": "10216404" },
      { "name": "Live hot pink ranunculus", "code": "10216405" },
      { "name": "Live light pink ranunculus", "code": "10216406" },
      { "name": "Live orange ranunculus", "code": "10216407" },
      { "name": "Live pink green center ranunculus", "code": "10216408" },
      { "name": "Live pink ranunculus", "code": "10216409" },
      { "name": "Live red ranunculus", "code": "10216410" },
      { "name": "Live white ranunculus", "code": "10216411" },
      { "name": "Live yellow ranunculus", "code": "10216412" },
      { "name": "Live salmon ranunculus", "code": "10216413" },
      { "name": "Live scabiosas", "code": "10216500" },
      { "name": "Live annual scabiosa", "code": "10216501" },
      { "name": "Live black scabiosa", "code": "10216502" },
      { "name": "Live caucasica blue scabiosa", "code": "10216503" },
      { "name": "Live caucasica pink scabiosa", "code": "10216504" },
      { "name": "Live caucasica pods scabiosa", "code": "10216505" },
      { "name": "Live caucasica white scabiosa", "code": "10216506" },
      { "name": "Live strawberry scabiosa", "code": "10216507" },
      { "name": "Live scotch brooms", "code": "10216600" },
      { "name": "Live pink scotch broom", "code": "10216601" },
      { "name": "Live purple scotch broom", "code": "10216602" },
      { "name": "Live white scotch broom", "code": "10216603" },
      { "name": "Live yellow scotch broom", "code": "10216604" },
      { "name": "Live snapdragons", "code": "10216700" },
      { "name": "Live bi color snapdragon", "code": "10216701" },
      { "name": "Live burgundy snapdragon", "code": "10216702" },
      { "name": "Live hot pink snapdragon", "code": "10216703" },
      { "name": "Live lavender snapdragon", "code": "10216704" },
      { "name": "Live light orange snapdragon", "code": "10216705" },
      { "name": "Live light pink snapdragon", "code": "10216706" },
      { "name": "Live orange snapdragon", "code": "10216707" },
      { "name": "Live white snapdragon", "code": "10216708" },
      { "name": "Live yellow snapdragon", "code": "10216709" },
      { "name": "Live statices", "code": "10216800" },
      { "name": "Live blue statice", "code": "10216801" },
      { "name": "Live lavender statice", "code": "10216802" },
      { "name": "Live peach statice", "code": "10216803" },
      { "name": "Live pink statice", "code": "10216804" },
      { "name": "Live purple statice", "code": "10216805" },
      { "name": "Live seafoam statice", "code": "10216806" },
      { "name": "Live white statice", "code": "10216807" },
      { "name": "Live yellow statice", "code": "10216808" },
      { "name": "Live matthiola incana or stock flowers", "code": "10216900" },
      { "name": "Live apricot stock flower", "code": "10216901" },
      { "name": "Live cream stock flower", "code": "10216902" },
      { "name": "Live fuchsia stock flower", "code": "10216903" },
      { "name": "Live lavender stock flower", "code": "10216904" },
      { "name": "Live light lavender stock flower", "code": "10216905" },
      { "name": "Live pacific pink stock flower", "code": "10216906" },
      { "name": "Live purple stock flower", "code": "10216907" },
      { "name": "Live ruby red stock flower", "code": "10216908" },
      { "name": "Live sweetheart pink stock flower", "code": "10216909" },
      { "name": "Live white stock flower", "code": "10216910" },
      { "name": "Live sunflowers", "code": "10217000" },
      { "name": "Live holiday tint sunflower", "code": "10217001" },
      { "name": "Live mahogany sunflower", "code": "10217002" },
      { "name": "Live sunbeam sunflower", "code": "10217003" },
      { "name": "Live sunbright sunflower", "code": "10217004" },
      { "name": "Live sunsplash sunflower", "code": "10217005" },
      { "name": "Live teddybear sunflower", "code": "10217006" },
      { "name": "Live sweet peas", "code": "10217100" },
      { "name": "Live green dyed sweet pea", "code": "10217101" },
      { "name": "Live hot pink sweet pea", "code": "10217102" },
      { "name": "Live lavender sweet pea", "code": "10217103" },
      { "name": "Live light pink sweet pea", "code": "10217104" },
      { "name": "Live orange sweet pea", "code": "10217105" },
      { "name": "Live peach dyed sweet pea", "code": "10217106" },
      { "name": "Live purple sweet pea", "code": "10217107" },
      { "name": "Live white sweet pea", "code": "10217108" },
      { "name": "Live thistles", "code": "10217200" },
      { "name": "Live alpinum thistle", "code": "10217201" },
      { "name": "Live echinops thistle", "code": "10217202" },
      { "name": "Live eryngium arabian dream thistle", "code": "10217203" },
      { "name": "Live eryngium blue bell thistle", "code": "10217204" },
      { "name": "Live eryngium orion thistle", "code": "10217205" },
      { "name": "Live eryngium raspberry thistle", "code": "10217206" },
      { "name": "Live eryngium supernova thistle", "code": "10217207" },
      { "name": "Live eryngium tinkerbell thistle", "code": "10217208" },
      { "name": "Live tulips", "code": "10217300" },
      { "name": "Live adrem tulip", "code": "10217301" },
      { "name": "Live apricot tulip", "code": "10217302" },
      { "name": "Live bi color red and yellow tulip", "code": "10217303" },
      { "name": "Live double bicolor tulip", "code": "10217304" },
      { "name": "Live double pink tulip", "code": "10217305" },
      { "name": "Live double red tulip", "code": "10217306" },
      { "name": "Live double white tulip", "code": "10217307" },
      { "name": "Live double yellow tulip", "code": "10217308" },
      { "name": "Live french avignon tulip", "code": "10217309" },
      { "name": "Live french camarque tulip", "code": "10217310" },
      { "name": "Live french dordogne tulip", "code": "10217311" },
      { "name": "Live french fiat tulip", "code": "10217312" },
      { "name": "Live french flamboyant tulip", "code": "10217313" },
      { "name": "Live french flaming parrot tulip", "code": "10217314" },
      { "name": "Live french florissa tulip", "code": "10217315" },
      { "name": "Live french maureen double tulip", "code": "10217316" },
      { "name": "Live french maureen tulip", "code": "10217317" },
      { "name": "Live french menton tulip", "code": "10217318" },
      { "name": "Live french montpellier tulip", "code": "10217319" },
      { "name": "Live french orange unique tulip", "code": "10217320" },
      { "name": "Live french peony renown unique tulip", "code": "10217321" },
      { "name": "Live french pink parrot tulip", "code": "10217322" },
      { "name": "Live french princess unique tulip", "code": "10217323" },
      { "name": "Live french renown tulip", "code": "10217324" },
      { "name": "Live french scheppers tulip", "code": "10217325" },
      { "name": "Live french suede tulip", "code": "10217326" },
      { "name": "Live french toyota tulip", "code": "10217327" },
      { "name": "Live french weber parrot tulip", "code": "10217328" },
      { "name": "Live french white parrot tulip", "code": "10217329" },
      { "name": "Live frilly edge lavender tulip", "code": "10217330" },
      { "name": "Live hot pink tulip", "code": "10217331" },
      { "name": "Live hot pink variegated folia tulip", "code": "10217332" },
      { "name": "Live lavender tulip", "code": "10217333" },
      { "name": "Live light pink variegated folia tulip", "code": "10217334" },
      { "name": "Live merry widow tulip", "code": "10217335" },
      { "name": "Live orange tulip", "code": "10217336" },
      { "name": "Live parrot black tulip", "code": "10217337" },
      { "name": "Live parrot estella rijnveld tulip", "code": "10217338" },
      { "name": "Live parrot flaming tulip", "code": "10217339" },
      { "name": "Live parrot green tulip", "code": "10217340" },
      { "name": "Live parrot lavender tulip", "code": "10217341" },
      { "name": "Live parrot orange tulip", "code": "10217342" },
      { "name": "Live parrot peach tulip", "code": "10217343" },
      { "name": "Live parrot pink tulip", "code": "10217344" },
      { "name": "Live parrot red tulip", "code": "10217345" },
      { "name": "Live parrot rococo red tulip", "code": "10217346" },
      { "name": "Live parrot weber tulip", "code": "10217347" },
      { "name": "Live parrot white tulip", "code": "10217348" },
      { "name": "Live parrot yellow tulip", "code": "10217349" },
      { "name": "Live pink tulip", "code": "10217350" },
      { "name": "Live purple tulip", "code": "10217351" },
      { "name": "Live red tulip", "code": "10217352" },
      { "name": "Live species tulip", "code": "10217353" },
      { "name": "Live white tulip", "code": "10217354" },
      { "name": "Live yellow tulip", "code": "10217355" },
      { "name": "Live waxflowers", "code": "10217400" },
      { "name": "Live alba waxflower", "code": "10217401" },
      { "name": "Live bi color waxflower", "code": "10217402" },
      { "name": "Live chinchilla waxflower", "code": "10217403" },
      { "name": "Live dancing queen waxflower", "code": "10217404" },
      { "name": "Live danmark waxflower", "code": "10217405" },
      { "name": "Live denmar pearl waxflower", "code": "10217406" },
      { "name": "Live hybrid pastel gemflower waxflower", "code": "10217407" },
      { "name": "Live hybrid pink gemflower waxflower", "code": "10217408" },
      { "name": "Live hybrid blondie white waxflower", "code": "10217409" },
      { "name": "Live hybrid eric john waxflower", "code": "10217410" },
      { "name": "Live hybrid painted lady waxflower", "code": "10217411" },
      { "name": "Live hybrid revelation waxflower", "code": "10217412" },
      { "name": "Live hybrid snowball waxflower", "code": "10217413" },
      { "name": "Live juriens brook waxflower", "code": "10217414" },
      { "name": "Live lady stephany pink waxflower", "code": "10217415" },
      { "name": "Live madonna waxflower", "code": "10217416" },
      { "name": "Live mini white waxflower", "code": "10217417" },
      { "name": "Live orange waxflower", "code": "10217418" },
      { "name": "Live pearl waxflower", "code": "10217419" },
      { "name": "Live pixie moon waxflower", "code": "10217420" },
      { "name": "Live purple pride waxflower", "code": "10217421" },
      { "name": "Live red waxflower", "code": "10217422" },
      { "name": "Live wanaroo waxflower", "code": "10217423" },
      { "name": "Live yellow waxflower", "code": "10217424" },
      { "name": "Live yarrows", "code": "10217500" },
      { "name": "Live burgundy yarrow", "code": "10217501" },
      { "name": "Live cottage creme yarrow", "code": "10217502" },
      { "name": "Live cottage pink yarrow", "code": "10217503" },
      { "name": "Live moonshine yarrow", "code": "10217504" },
      { "name": "Live orange yarrow", "code": "10217505" },
      { "name": "Live peach yarrow", "code": "10217506" },
      { "name": "Live pink yarrow", "code": "10217507" },
      { "name": "Live red dyed yarrow", "code": "10217508" },
      { "name": "Live white yarrow", "code": "10217509" },
      { "name": "Live yellow yarrow", "code": "10217510" },
      { "name": "Live zinnias", "code": "10217600" },
      { "name": "Live hot pink zinnia", "code": "10217601" },
      { "name": "Live mini zinnia", "code": "10217602" },
      { "name": "Live pink zinnia", "code": "10217603" },
      { "name": "Live red zinnia", "code": "10217604" },
      { "name": "Live salmon zinnia", "code": "10217605" },
      { "name": "Live yellow zinnia", "code": "10217606" },
      { "name": "Live forsythias", "code": "10217700" },
      { "name": "Live forsythia viridissima", "code": "10217701" },
      { "name": "Live forsythia giraldiana", "code": "10217702" },
      { "name": "Live forsythia mira", "code": "10217703" },
      { "name": "Live forsythia suspensa", "code": "10217704" },
      { "name": "Live forsythia intermedia", "code": "10217705" },
      { "name": "Live forsythia variabilis", "code": "10217706" },
      { "name": "Live forsythia ovate", "code": "10217707" },
      { "name": "Live forsythia intermedia lynnwood", "code": "10217708" },
      { "name": "Live geraniums or cranesbills", "code": "10217800" },
      { "name": "Live argenteum geranium", "code": "10217801" },
      { "name": "Live cinereum geranium", "code": "10217802" },
      { "name": "Live clarkei geranium", "code": "10217803" },
      { "name": "Live dalmaticum geranium", "code": "10217804" },
      { "name": "Live endressii geranium", "code": "10217805" },
      { "name": "Live eriostemon geranium", "code": "10217806" },
      { "name": "Live farreri geranium", "code": "10217807" },
      {
        "name": "Live himalayense or grandiflorum geranium",
        "code": "10217808"
      },
      { "name": "Live ibericum geranium", "code": "10217809" },
      { "name": "Live macrorrhizum or bigroot geranium", "code": "10217810" },
      { "name": "Live maculatum geranium", "code": "10217811" },
      { "name": "Live nodosum geranium", "code": "10217812" },
      { "name": "Live phaeum geranium", "code": "10217813" },
      { "name": "Live platypetalum geranium", "code": "10217814" },
      { "name": "Live pratense geranium", "code": "10217815" },
      { "name": "Live procurrens geranium", "code": "10217816" },
      { "name": "Live psilostemon geranium", "code": "10217817" },
      { "name": "Live pylzowianum geranium", "code": "10217818" },
      { "name": "Live renardii geranium", "code": "10217819" },
      { "name": "Live sanguineum or bloody geranium", "code": "10217820" },
      { "name": "Live sylvaticum geranium", "code": "10217821" },
      { "name": "Live traversii geranium", "code": "10217822" },
      { "name": "Live tuberosum geranium", "code": "10217823" },
      { "name": "Live versicolor geranium", "code": "10217824" },
      { "name": "Live wallichianum geranium", "code": "10217825" },
      { "name": "Live wlassovianum geranium", "code": "10217826" },
      { "name": "Live x magnificum or showy geranium", "code": "10217827" },
      { "name": "Live geranium pelargonium hortorum", "code": "10217828" },
      { "name": "Live hippeastrums", "code": "10217900" },
      { "name": "Live aglaiae hippeastrum", "code": "10217901" },
      { "name": "Live amaru hippeastrum", "code": "10217902" },
      { "name": "Live angustifolium hippeastrum", "code": "10217903" },
      { "name": "Live anzaldoi hippeastrum", "code": "10217904" },
      { "name": "Live araripinum hippeastrum", "code": "10217905" },
      { "name": "Live arboricola hippeastrum", "code": "10217906" },
      { "name": "Live argentinum hippeastrum", "code": "10217907" },
      { "name": "Live aulicum hippeastrum", "code": "10217908" },
      { "name": "Live aviflorum hippeastrum", "code": "10217909" },
      { "name": "Live barreirasum hippeastrum", "code": "10217910" },
      { "name": "Live blossfeldiae hippeastrum", "code": "10217911" },
      { "name": "Live blumenavium hippeastrum", "code": "10217912" },
      { "name": "Live brasilianum hippeastrum", "code": "10217913" },
      { "name": "Live breviflorum hippeastrum", "code": "10217914" },
      { "name": "Live bukasovii hippeastrum", "code": "10217915" },
      { "name": "Live calyptratum hippeastrum", "code": "10217916" },
      { "name": "Live caupolicanense hippeastrum", "code": "10217917" },
      { "name": "Live chionedyanthum hippeastrum", "code": "10217918" },
      { "name": "Live condemaita hippeastrum", "code": "10217919" },
      { "name": "Live corriense hippeastrum", "code": "10217920" },
      { "name": "Live cuzcoense hippeastrum", "code": "10217921" },
      { "name": "Live curitibanum hippeastrum", "code": "10217922" },
      { "name": "Live cybister hippeastrum", "code": "10217923" },
      { "name": "Live divijuliani hippeastrum", "code": "10217924" },
      { "name": "Live evansiae hippeastrum", "code": "10217925" },
      { "name": "Live ferreyrae hippeastrum", "code": "10217926" },
      { "name": "Live forgetii hippeastrum", "code": "10217927" },
      { "name": "Live fosteri hippeastrum", "code": "10217928" },
      { "name": "Live fuscum hippeastrum", "code": "10217929" },
      { "name": "Live glaucescens hippeastrum", "code": "10217930" },
      { "name": "Live goianum hippeastrum", "code": "10217931" },
      { "name": "Live guarapuavicum hippeastrum", "code": "10217932" },
      { "name": "Live harrisonii hippeastrum", "code": "10217933" },
      { "name": "Live hugoi hippeastrum", "code": "10217934" },
      { "name": "Live iguazuanum hippeastrum", "code": "10217935" },
      { "name": "Live illustre hippeastrum", "code": "10217936" },
      { "name": "Live intiflorum hippeastrum", "code": "10217937" },
      { "name": "Live kromeri hippeastrum", "code": "10217938" },
      { "name": "Live lapacense hippeastrum", "code": "10217939" },
      { "name": "Live leonardii hippeastrum", "code": "10217940" },
      { "name": "Live leopoldii hippeastrum", "code": "10217941" },
      { "name": "Live macbridei hippeastrum", "code": "10217942" },
      { "name": "Live machupijchense hippeastrum", "code": "10217943" },
      { "name": "Live mandonii hippeastrum", "code": "10217944" },
      { "name": "Live minasgerais hippeastrum", "code": "10217945" },
      { "name": "Live miniatum hippeastrum", "code": "10217946" },
      { "name": "Live mollevillquense hippeastrum", "code": "10217947" },
      { "name": "Live morelianum hippeastrum", "code": "10217948" },
      { "name": "Live nelsonii hippeastrum", "code": "10217949" },
      { "name": "Live oconoquense hippeastrum", "code": "10217950" },
      { "name": "Live papilio hippeastrum", "code": "10217951" },
      { "name": "Live paquichanum hippeastrum", "code": "10217952" },
      { "name": "Live paradisiacum hippeastrum", "code": "10217953" },
      { "name": "Live pardinum hippeastrum", "code": "10217954" },
      { "name": "Live parodii hippeastrum", "code": "10217955" },
      { "name": "Live petiolatum hippeastrum", "code": "10217956" },
      { "name": "Live psittacinum hippeastrum", "code": "10217957" },
      { "name": "Live puniceum hippeastrum", "code": "10217958" },
      { "name": "Live reginae hippeastrum", "code": "10217959" },
      { "name": "Live reticulatum hippeastrum", "code": "10217960" },
      { "name": "Live rubropictum hippeastrum", "code": "10217961" },
      { "name": "Live santacatarina hippeastrum", "code": "10217962" },
      { "name": "Live solandraeflorum hippeastrum", "code": "10217963" },
      { "name": "Live starkiorum hippeastrum", "code": "10217964" },
      { "name": "Live striatum hippeastrum", "code": "10217965" },
      { "name": "Live stylosum hippeastrum", "code": "10217966" },
      { "name": "Live teyucuarense hippeastrum", "code": "10217967" },
      { "name": "Live traubii hippeastrum", "code": "10217968" },
      { "name": "Live vargasii hippeastrum", "code": "10217969" },
      { "name": "Live variegatum hippeastrum", "code": "10217970" },
      { "name": "Live vittatum hippeastrum", "code": "10217971" },
      { "name": "Live yungacense hippeastrum", "code": "10217972" },
      { "name": "Live rudbeckia or coneflower", "code": "10218000" },
      { "name": "Live alpicola rudbeckia", "code": "10218001" },
      { "name": "Live amplexicaulis rudbeckia", "code": "10218002" },
      { "name": "Live auriculata rudbeckia", "code": "10218003" },
      { "name": "Live bi color rudbeckia", "code": "10218004" },
      { "name": "Live californica rudbeckia", "code": "10218005" },
      { "name": "Live fulgida rudbeckia", "code": "10218006" },
      { "name": "Live glaucescens rudbeckia", "code": "10218007" },
      { "name": "Live graminifolia rudbeckia", "code": "10218008" },
      { "name": "Live grandiflora rudbeckia", "code": "10218009" },
      { "name": "Live heliopsidis rudbeckia", "code": "10218010" },
      { "name": "Live hirta rudbeckia", "code": "10218011" },
      { "name": "Live klamathensis rudbeckia", "code": "10218012" },
      { "name": "Live laciniata rudbeckia", "code": "10218013" },
      { "name": "Live maxima rudbeckia", "code": "10218014" },
      { "name": "Live missouriensis rudbeckia", "code": "10218015" },
      { "name": "Live mohrii rudbeckia", "code": "10218016" },
      { "name": "Live mollis rudbeckia", "code": "10218017" },
      { "name": "Live montana rudbeckia", "code": "10218018" },
      { "name": "Live nitida rudbeckia", "code": "10218019" },
      { "name": "Live occidentalis rudbeckia", "code": "10218020" },
      { "name": "Live pinnata rudbeckia", "code": "10218021" },
      { "name": "Live scabrifolia rudbeckia", "code": "10218022" },
      { "name": "Live serotina rudbeckia", "code": "10218023" },
      { "name": "Live speciosa rudbeckia", "code": "10218024" },
      { "name": "Live subtomentosa rudbeckia", "code": "10218025" },
      { "name": "Live texana rudbeckia", "code": "10218026" },
      { "name": "Live triloba rudbeckia", "code": "10218027" },
      { "name": "Live proteas", "code": "10218100" },
      { "name": "Live bouquet protea", "code": "10218101" },
      { "name": "Live bottle brush protea", "code": "10218102" },
      { "name": "Live carnival protea", "code": "10218103" },
      { "name": "Live cordata foliage protea", "code": "10218104" },
      { "name": "Live grandiceps protea", "code": "10218105" },
      { "name": "Live green mink protea", "code": "10218106" },
      { "name": "Live ivy protea", "code": "10218107" },
      { "name": "Live king protea", "code": "10218108" },
      { "name": "Live nana cones protea", "code": "10218109" },
      { "name": "Live pincushion orange protea", "code": "10218110" },
      { "name": "Live pincushion tango protea", "code": "10218111" },
      { "name": "Live pincushion yellow protea", "code": "10218112" },
      { "name": "Live pink ice protea", "code": "10218113" },
      { "name": "Live pink mink protea", "code": "10218114" },
      { "name": "Live queen protea", "code": "10218115" },
      { "name": "Live repens protea", "code": "10218116" },
      { "name": "Live rosespoon protea", "code": "10218117" },
      { "name": "Live silvia protea", "code": "10218118" },
      { "name": "Live sugarbush protea", "code": "10218119" },
      { "name": "Live susara protea", "code": "10218120" },
      { "name": "Live waratha long protea", "code": "10218121" },
      { "name": "Live white mink protea", "code": "10218122" },
      { "name": "Live leucadendrons", "code": "10218200" },
      { "name": "Live argenteum leucadendron", "code": "10218201" },
      { "name": "Live creme delight leucadendron", "code": "10218202" },
      { "name": "Live cumosum leucadendron", "code": "10218203" },
      { "name": "Live discolor leucadendron", "code": "10218204" },
      { "name": "Live galpini leucadendron", "code": "10218205" },
      { "name": "Live gold strike leucadendron", "code": "10218206" },
      { "name": "Live inca gold leucadendron", "code": "10218207" },
      { "name": "Live jester leucadendron", "code": "10218208" },
      { "name": "Live laxum leucadendron", "code": "10218209" },
      { "name": "Live mini leucadendron", "code": "10218210" },
      { "name": "Live patea gold leucadendron", "code": "10218211" },
      { "name": "Live petra leucadendron", "code": "10218212" },
      { "name": "Live plumosum leucadendron", "code": "10218213" },
      { "name": "Live rosette leucadendron", "code": "10218214" },
      { "name": "Live safari sunset leucadendron", "code": "10218215" },
      { "name": "Live safari sunset spr leucadendron", "code": "10218216" },
      { "name": "Live speciosa leucadendron", "code": "10218217" },
      { "name": "Live spray leucadendron", "code": "10218218" },
      { "name": "Live wilson wonder leucadendron", "code": "10218219" },
      { "name": "Live yarden leucadendron", "code": "10218220" },
      { "name": "Live leucospermums", "code": "10218300" },
      { "name": "Live leucospermum album", "code": "10218301" },
      { "name": "Live leucospermum attenuatum", "code": "10218302" },
      { "name": "Live leucospermum calligerum", "code": "10218303" },
      { "name": "Live leucospermum conocarpodendron", "code": "10218304" },
      { "name": "Live leucospermum cordatum", "code": "10218305" },
      { "name": "Live leucospermum cuneiforme", "code": "10218306" },
      { "name": "Live leucospermum formosum", "code": "10218307" },
      { "name": "Live leucospermum glabrum", "code": "10218308" },
      { "name": "Live leucospermum grandiflorum", "code": "10218309" },
      { "name": "Live leucospermum harmatum", "code": "10218310" },
      { "name": "Live leucospermum heterophyllum", "code": "10218311" },
      { "name": "Live leucospermum innovans", "code": "10218312" },
      { "name": "Live leucospermum muirii", "code": "10218313" },
      { "name": "Live leucospermum oleifolium", "code": "10218314" },
      { "name": "Live leucospermum patersonii", "code": "10218315" },
      { "name": "Live leucospermum pluridens", "code": "10218316" },
      { "name": "Live leucospermum praemorsum", "code": "10218317" },
      { "name": "Live leucospermum prostratum", "code": "10218318" },
      { "name": "Live leucospermum rodolentum", "code": "10218319" },
      { "name": "Live leucospermum saxatile", "code": "10218320" },
      { "name": "Live leucospermum secundifolium", "code": "10218321" },
      { "name": "Live leucospermum tomentosus", "code": "10218322" },
      { "name": "Live leucospermum truncatulum", "code": "10218323" },
      { "name": "Live leucospermum utriculosum", "code": "10218324" },
      { "name": "Live leucospermum winterii", "code": "10218325" },
      { "name": "Live leucospermum arenarium", "code": "10218326" },
      { "name": "Live leucospermum bolusii", "code": "10218327" },
      { "name": "Live leucospermum catherinae", "code": "10218328" },
      { "name": "Live leucospermum conocarpum", "code": "10218329" },
      { "name": "Live leucospermum cordifolium", "code": "10218330" },
      { "name": "Live leucospermum erubescens", "code": "10218331" },
      { "name": "Live leucospermum gerrardii", "code": "10218332" },
      { "name": "Live leucospermum gracile", "code": "10218333" },
      { "name": "Live leucospermum gueinzii", "code": "10218334" },
      { "name": "Live leucospermum harpagonatum", "code": "10218335" },
      {
        "name": "Live leucospermum hypophyllocarpodendron",
        "code": "10218336"
      },
      { "name": "Live leucospermum lineare", "code": "10218337" },
      { "name": "Live leucospermum mundii", "code": "10218338" },
      { "name": "Live leucospermum parile", "code": "10218339" },
      { "name": "Live leucospermum pendunculatum", "code": "10218340" },
      { "name": "Live leucospermum praecox", "code": "10218341" },
      { "name": "Live leucospermum profugum", "code": "10218342" },
      { "name": "Live leucospermum reflexum", "code": "10218343" },
      { "name": "Live leucospermum royenifolium", "code": "10218344" },
      { "name": "Live leucospermum saxosum", "code": "10218345" },
      { "name": "Live leucospermum spathulatum", "code": "10218346" },
      { "name": "Live leucospermum tottum", "code": "10218347" },
      { "name": "Live leucospermum truncatum", "code": "10218348" },
      { "name": "Live leucospermum vestitum", "code": "10218349" },
      { "name": "Live leucospermum wittebergense", "code": "10218350" },
      {
        "name": "Live plants of low species or variety count flowers",
        "code": "10220000"
      },
      { "name": "Live agapanthuses", "code": "10221500" },
      { "name": "Live blue agapanthus", "code": "10221501" },
      { "name": "Live white agapanthus", "code": "10221502" },
      { "name": "Live alchemillas", "code": "10221600" },
      { "name": "Live ladys mantle alchemilla", "code": "10221601" },
      { "name": "Live robustica alchemilla", "code": "10221602" },
      { "name": "Live alstilbes", "code": "10221700" },
      { "name": "Live hot pink astilbe", "code": "10221701" },
      { "name": "Live light pink astilbe", "code": "10221702" },
      { "name": "Live red astilbe", "code": "10221703" },
      { "name": "Live white astilbe", "code": "10221704" },
      { "name": "Live angelicas", "code": "10221800" },
      { "name": "Live gigas angelica", "code": "10221801" },
      { "name": "Live sylvestris angelica", "code": "10221802" },
      { "name": "Live artemesias", "code": "10221900" },
      { "name": "Live green artemesia", "code": "10221901" },
      { "name": "Live silver king artemesia", "code": "10221902" },
      { "name": "Live artichoke flowers", "code": "10222000" },
      { "name": "Live chocolate artichoke flower", "code": "10222001" },
      { "name": "Live green artichoke flower", "code": "10222002" },
      {
        "name": "Live purple or flowering artichoke flower",
        "code": "10222003"
      },
      { "name": "Live astrantias", "code": "10222100" },
      { "name": "Live pink astrantia", "code": "10222101" },
      { "name": "Live white astrantia", "code": "10222102" },
      { "name": "Live banana flowers", "code": "10222200" },
      { "name": "Live orange banana flower", "code": "10222201" },
      { "name": "Live orange torch banana flower", "code": "10222202" },
      { "name": "Live purple banana flower", "code": "10222203" },
      { "name": "Live baptisias", "code": "10222300" },
      { "name": "Live australis baptisia", "code": "10222301" },
      { "name": "Live sphaerocarpa baptisia", "code": "10222302" },
      { "name": "Live boronias", "code": "10222400" },
      { "name": "Live pink boronia", "code": "10222401" },
      { "name": "Live yellow boronia", "code": "10222402" },
      { "name": "Live bromelias", "code": "10222500" },
      { "name": "Live yellow reg bromelia", "code": "10222501" },
      { "name": "Live red reg bromelia", "code": "10222502" },
      { "name": "Live brunias", "code": "10222600" },
      { "name": "Live albiflora brunia", "code": "10222601" },
      { "name": "Live albiflora green brunia", "code": "10222602" },
      { "name": "Live silver spray brunia", "code": "10222603" },
      { "name": "Live calatheas", "code": "10222700" },
      { "name": "Live cigar calathea", "code": "10222701" },
      { "name": "Live green ice calathea", "code": "10222702" },
      { "name": "Live rattlesnake calathea", "code": "10222703" },
      { "name": "Live calcynias", "code": "10222800" },
      { "name": "Live pink calcynia", "code": "10222801" },
      { "name": "Live princess calcynia", "code": "10222802" },
      { "name": "Live white calcynia", "code": "10222803" },
      { "name": "Live calendulas", "code": "10222900" },
      { "name": "Live orange calendula", "code": "10222901" },
      { "name": "Live yellow calendula", "code": "10222902" },
      { "name": "Live campanulas or bellflowers", "code": "10223000" },
      { "name": "Live blue bells campanula", "code": "10223001" },
      { "name": "Live pink bells campanula", "code": "10223002" },
      { "name": "Live white bells campanula", "code": "10223003" },
      { "name": "Live cestrums", "code": "10223100" },
      { "name": "Live red cestrum", "code": "10223101" },
      { "name": "Live red zohar cestrum", "code": "10223102" },
      { "name": "Live yellow cestrum", "code": "10223103" },
      { "name": "Live chasmanthes", "code": "10223200" },
      { "name": "Live floribunda yellow chasmanthe", "code": "10223201" },
      { "name": "Live floribundi orange chasmanthe", "code": "10223202" },
      { "name": "Live costuses", "code": "10223300" },
      { "name": "Live barbatus costus", "code": "10223301" },
      { "name": "Live indian head costus", "code": "10223302" },
      { "name": "Live crocosmias", "code": "10223400" },
      { "name": "Live lucifer crocosmia", "code": "10223401" },
      { "name": "Live pods crocosmia", "code": "10223402" },
      { "name": "Live yellow crocosmia", "code": "10223403" },
      { "name": "Live cytanthuses", "code": "10223500" },
      { "name": "Live bright orange cyrtanthus", "code": "10223501" },
      { "name": "Live creme cyrtanthus", "code": "10223502" },
      { "name": "Live orange cyrtanthus", "code": "10223503" },
      { "name": "Live yellow cyrtanthus", "code": "10223504" },
      { "name": "Live rumex or dock flowers", "code": "10223600" },
      { "name": "Live green dock flower", "code": "10223601" },
      { "name": "Live red dock flower", "code": "10223602" },
      { "name": "Live eryngiums", "code": "10223700" },
      { "name": "Live tinted black eryngium", "code": "10223701" },
      { "name": "Live tinted orange eryngium", "code": "10223702" },
      { "name": "Live feverfews", "code": "10223800" },
      { "name": "Live single vegmo feverfew", "code": "10223801" },
      { "name": "Live double white feverfew", "code": "10223802" },
      { "name": "Live snowball feverfew", "code": "10223803" },
      { "name": "Live white feverfew", "code": "10223804" },
      { "name": "Live forget me nots", "code": "10223900" },
      { "name": "Live blue forget me not", "code": "10223901" },
      { "name": "Live white forget me not", "code": "10223902" },
      { "name": "Live gaillardias", "code": "10224000" },
      { "name": "Live orange gaillardia", "code": "10224001" },
      { "name": "Live yellow gaillardia", "code": "10224002" },
      { "name": "Live gentianas", "code": "10224100" },
      { "name": "Live blue gentiana", "code": "10224101" },
      { "name": "Live white gentiana", "code": "10224102" },
      { "name": "Live glamini gladioluses", "code": "10224200" },
      { "name": "Live pink glamini gladiolus", "code": "10224201" },
      { "name": "Live red glamini gladiolus", "code": "10224202" },
      { "name": "Live gloriosas", "code": "10224300" },
      { "name": "Live orange gloriosa", "code": "10224301" },
      { "name": "Live red gloriosa", "code": "10224302" },
      { "name": "Live yellow gloriosa", "code": "10224303" },
      {
        "name": "Live gomphrena globosa or globe amaranth",
        "code": "10224400"
      },
      { "name": "Live orange gomphrena globosa", "code": "10224401" },
      { "name": "Live pink gomphrena globosa", "code": "10224402" },
      { "name": "Live hellebores", "code": "10224500" },
      { "name": "Live green hellebore", "code": "10224501" },
      { "name": "Live moonshine hellebore", "code": "10224502" },
      { "name": "Live ixias", "code": "10224600" },
      { "name": "Live pink ixia", "code": "10224601" },
      { "name": "Live white ixia", "code": "10224602" },
      { "name": "Live liatrises", "code": "10224700" },
      { "name": "Live purple liatris", "code": "10224701" },
      { "name": "Live spray liatris", "code": "10224702" },
      { "name": "Live white liatris", "code": "10224703" },
      { "name": "Live lysimachias", "code": "10224800" },
      { "name": "Live punctata lysimachia", "code": "10224801" },
      { "name": "Live vulgaris lysimachia", "code": "10224802" },
      { "name": "Live white lysimachia", "code": "10224803" },
      { "name": "Live yellow lysimachia", "code": "10224804" },
      { "name": "Live maracas", "code": "10224900" },
      { "name": "Live brown maraca", "code": "10224901" },
      { "name": "Live shampoo ginger maraca", "code": "10224902" },
      { "name": "Live marigolds", "code": "10225000" },
      { "name": "Live french marigold", "code": "10225001" },
      { "name": "Live green marigold", "code": "10225002" },
      { "name": "Live orange marigold", "code": "10225003" },
      { "name": "Live yellow marigold", "code": "10225004" },
      { "name": "Live mimosas", "code": "10225100" },
      { "name": "Live blue or purple mimosa", "code": "10225101" },
      { "name": "Live finger mimosa", "code": "10225102" },
      { "name": "Live floribunda or italy mimosa", "code": "10225103" },
      { "name": "Live mirandole mimosa", "code": "10225104" },
      { "name": "Live nerines", "code": "10225200" },
      { "name": "Live pink nerine", "code": "10225201" },
      { "name": "Live white sarniensis nerine", "code": "10225202" },
      { "name": "Live pepperberry flowers", "code": "10225300" },
      { "name": "Live hanging pepperberry flower", "code": "10225301" },
      { "name": "Live leafless pepperberry flower", "code": "10225302" },
      {
        "name": "Live upright brazilian pepperberry flower",
        "code": "10225303"
      },
      { "name": "Live phlox", "code": "10225400" },
      { "name": "Live dark pink phlox", "code": "10225401" },
      { "name": "Live lavender phlox", "code": "10225402" },
      { "name": "Live light pink phlox", "code": "10225403" },
      { "name": "Live white phlox", "code": "10225404" },
      { "name": "Live physostegias or obedient plant", "code": "10225500" },
      { "name": "Live pink physostegia", "code": "10225501" },
      { "name": "Live pods physostegia", "code": "10225502" },
      { "name": "Live white physostegia", "code": "10225503" },
      { "name": "Live saponarias", "code": "10225600" },
      { "name": "Live pink saponaria", "code": "10225601" },
      { "name": "Live white saponaria", "code": "10225602" },
      { "name": "Live sarracenias", "code": "10225700" },
      { "name": "Live flava rugelii sarracenia", "code": "10225701" },
      { "name": "Live scillas", "code": "10225800" },
      { "name": "Live campanulata blue scilla", "code": "10225801" },
      { "name": "Live campanulata pink scilla", "code": "10225802" },
      { "name": "Live campanulata white scilla", "code": "10225803" },
      { "name": "Live peruviana scilla", "code": "10225804" },
      { "name": "Live sedums", "code": "10225900" },
      { "name": "Live brown sedum", "code": "10225901" },
      { "name": "Live green sedum", "code": "10225902" },
      { "name": "Live pink sedum", "code": "10225903" },
      { "name": "Live red sedum", "code": "10225904" },
      {
        "name": "Live single species or varieties of flowers",
        "code": "10226000"
      },
      { "name": "Live agrostemma", "code": "10226001" },
      { "name": "Live kniphofia or assegai poker", "code": "10226002" },
      { "name": "Live bellis perennis", "code": "10226003" },
      { "name": "Live bells of ireland or molucella", "code": "10226004" },
      { "name": "Live bird of paradise", "code": "10226005" },
      { "name": "Live blushing bride", "code": "10226006" },
      { "name": "Live buddleia or butterfly bush", "code": "10226007" },
      { "name": "Live bupleurum griffithii", "code": "10226008" },
      { "name": "Live california ginesta", "code": "10226009" },
      { "name": "Live callicarpa purple", "code": "10226010" },
      { "name": "Live white campanula bell", "code": "10226011" },
      { "name": "Live candy tuft", "code": "10226012" },
      { "name": "Live cariopteris", "code": "10226013" },
      { "name": "Live centaurea or marco polo", "code": "10226014" },
      { "name": "Live chinese lantern", "code": "10226015" },
      { "name": "Live clematis recta purpurea", "code": "10226016" },
      { "name": "Live cleome spinosa", "code": "10226017" },
      { "name": "Live coreopsis", "code": "10226018" },
      { "name": "Live blue cornflower", "code": "10226019" },
      { "name": "Live chocolate cosmos", "code": "10226020" },
      { "name": "Live cotinus coggygria", "code": "10226021" },
      { "name": "Live craspedia or billy balls", "code": "10226022" },
      { "name": "Live deutzia tall", "code": "10226023" },
      { "name": "Live diosma", "code": "10226024" },
      { "name": "Live echeveria succulent", "code": "10226025" },
      { "name": "Live echinacea purpurea", "code": "10226026" },
      { "name": "Live edelweiss", "code": "10226027" },
      { "name": "Live erythronium pagoda", "code": "10226028" },
      { "name": "Live eucalyptus flower", "code": "10226029" },
      { "name": "Live eucharis or amazon lily", "code": "10226030" },
      { "name": "Live eucomis or pineapple lily", "code": "10226031" },
      { "name": "Live eupatorium maculatum", "code": "10226032" },
      { "name": "Live filipendula", "code": "10226033" },
      { "name": "Live foxglove", "code": "10226034" },
      { "name": "Live globe gilia", "code": "10226035" },
      { "name": "Live globularia blue eye", "code": "10226036" },
      { "name": "Live washington hawthorne", "code": "10226037" },
      { "name": "Live helenium", "code": "10226038" },
      { "name": "Live helianthus", "code": "10226039" },
      { "name": "Live hesperis matronalis", "code": "10226040" },
      { "name": "Live houttuynia cordata chameleon", "code": "10226041" },
      { "name": "Live hyacinth with bulb", "code": "10226042" },
      { "name": "Live indian corn", "code": "10226043" },
      { "name": "Live jack in the pulpit", "code": "10226044" },
      { "name": "Live japanese tree of heaven", "code": "10226045" },
      { "name": "Live jasmine flowering vine", "code": "10226046" },
      { "name": "Live jatropha curcas or firecracker", "code": "10226047" },
      { "name": "Live knautia", "code": "10226048" },
      { "name": "Live kochia sedifolia", "code": "10226049" },
      { "name": "Live lachenalia romaud", "code": "10226050" },
      { "name": "Live lambs ears flower", "code": "10226051" },
      { "name": "Live lavender", "code": "10226052" },
      { "name": "Live leucocoryne speciosa", "code": "10226053" },
      { "name": "Live lythrum", "code": "10226054" },
      { "name": "Live malva zebrina", "code": "10226055" },
      { "name": "Live marguerite white daisy", "code": "10226056" },
      { "name": "Live montbretia yellow", "code": "10226057" },
      { "name": "Live nebelia", "code": "10226058" },
      { "name": "Live nicotiana green", "code": "10226059" },
      {
        "name": "Live nigella damascena or love in the mist",
        "code": "10226060"
      },
      { "name": "Live nigella pods", "code": "10226061" },
      { "name": "Live nuns orchid", "code": "10226062" },
      { "name": "Live paphiopedilum green orchid", "code": "10226063" },
      { "name": "Live paranomus", "code": "10226064" },
      { "name": "Live penstemon husker red", "code": "10226065" },
      { "name": "Live peruvian apple", "code": "10226066" },
      { "name": "Live phlomis sarnia", "code": "10226067" },
      { "name": "Live pink lace flower or didiscus", "code": "10226068" },
      { "name": "Live platycodon or balloon flower", "code": "10226069" },
      { "name": "Live retzia capensis", "code": "10226070" },
      { "name": "Live ricinus communis", "code": "10226071" },
      { "name": "Live snow on the mountain", "code": "10226072" },
      { "name": "Live solidago tinted", "code": "10226073" },
      { "name": "Live white squill", "code": "10226074" },
      { "name": "Live stachys byzantina", "code": "10226075" },
      { "name": "Live strawflower", "code": "10226076" },
      { "name": "Live succulent oscularia", "code": "10226077" },
      { "name": "Live tillandsia flower", "code": "10226078" },
      { "name": "Live triteleia", "code": "10226079" },
      { "name": "Live tritoma orange or red hot poker", "code": "10226080" },
      { "name": "Live veronicastrum virginiana", "code": "10226081" },
      { "name": "Live vriesea splendens", "code": "10226082" },
      { "name": "Live st johns wort or hypericim", "code": "10226084" },
      { "name": "Live spirea", "code": "10226085" },
      { "name": "Live ruscus", "code": "10226086" },
      { "name": "Live solomons seals", "code": "10226100" },
      { "name": "Live false solomon seal", "code": "10226101" },
      { "name": "Live variegated solomons seal", "code": "10226102" },
      { "name": "Live tanacetums", "code": "10226200" },
      { "name": "Live amazon tanacetum", "code": "10226201" },
      { "name": "Live victory double white tanacetum", "code": "10226202" },
      { "name": "Live victory single white tanacetum", "code": "10226203" },
      { "name": "Live yellow vegmo tanacetum", "code": "10226204" },
      { "name": "Live tracheliums", "code": "10226300" },
      { "name": "Live jade trachelium", "code": "10226301" },
      { "name": "Live purple trachelium", "code": "10226302" },
      { "name": "Live white trachelium", "code": "10226303" },
      { "name": "Live tuberosas", "code": "10226400" },
      { "name": "Live double tuberosa", "code": "10226401" },
      { "name": "Live single tuberosa", "code": "10226402" },
      { "name": "Live tweedias", "code": "10226500" },
      { "name": "Live blue tweedia", "code": "10226501" },
      { "name": "Live white tweedia", "code": "10226502" },
      { "name": "Live veronicas", "code": "10226600" },
      { "name": "Live pink veronica", "code": "10226601" },
      { "name": "Live purple veronica", "code": "10226602" },
      { "name": "Live white veronica", "code": "10226603" },
      { "name": "Live watsonias", "code": "10226700" },
      { "name": "Live orange watsonias", "code": "10226701" },
      { "name": "Live pink watsonia", "code": "10226702" },
      { "name": "Live red watsonia", "code": "10226703" },
      { "name": "Live white watsonia", "code": "10226704" },
      { "name": "Live chrysanthemums", "code": "10230000" },
      { "name": "Live button chrysanthemums", "code": "10231500" },
      { "name": "Live delirock pompon chrysanthemum", "code": "10231501" },
      { "name": "Live discovery pompon chrysanthemum", "code": "10231502" },
      { "name": "Live focus pompon chrysanthemum", "code": "10231503" },
      { "name": "Live jeanny pompon chrysanthemum", "code": "10231504" },
      { "name": "Live lady pompon chrysanthemum", "code": "10231505" },
      { "name": "Live leidy pompon chrysanthemum", "code": "10231506" },
      { "name": "Live lexy pompon chrysanthemum", "code": "10231507" },
      { "name": "Live ole pompon chrysanthemum", "code": "10231508" },
      { "name": "Live revise pompon chrysanthemum", "code": "10231509" },
      { "name": "Live statesman pompon chrysanthemum", "code": "10231510" },
      { "name": "Live sweet pompon chrysanthemum", "code": "10231511" },
      { "name": "Live yoko ono pompon chrysanthemum", "code": "10231512" },
      { "name": "Live zip pompon chrysanthemum", "code": "10231513" },
      { "name": "Live daisy pompon chrysanthemums", "code": "10231600" },
      { "name": "Live artist pink pompon chrysanthemum", "code": "10231601" },
      { "name": "Live artist yellow pompon chrysanthemum", "code": "10231602" },
      { "name": "Live atlantis pink pompon chrysanthemum", "code": "10231603" },
      {
        "name": "Live atlantis white pompon chrysanthemum",
        "code": "10231604"
      },
      {
        "name": "Live atlantis yellow pompon chrysanthemum",
        "code": "10231605"
      },
      { "name": "Live bennie jolink pompon chrysanthemum", "code": "10231606" },
      {
        "name": "Live bennie jolink yellow pompon chrysanthemum",
        "code": "10231607"
      },
      {
        "name": "Live bronze managua pompon chrysanthemum",
        "code": "10231608"
      },
      { "name": "Live clue pompon chrysanthemum", "code": "10231609" },
      { "name": "Live coral fiction pompon chrysanthemum", "code": "10231610" },
      { "name": "Live cumbia pompon chrysanthemum", "code": "10231611" },
      { "name": "Live dark cantata pompon chrysanthemum", "code": "10231612" },
      { "name": "Live dark lineker pompon chrysanthemum", "code": "10231613" },
      { "name": "Live dipper pompon chrysanthemum", "code": "10231614" },
      { "name": "Live elite pink pompon chrysanthemum", "code": "10231615" },
      { "name": "Live elite white pompon chrysanthemum", "code": "10231616" },
      { "name": "Live elite yellow pompon chrysanthemum", "code": "10231617" },
      { "name": "Live factor pompon chrysanthemum", "code": "10231618" },
      { "name": "Live fiction pompon chrysanthemum", "code": "10231619" },
      { "name": "Live force pompon chrysanthemum", "code": "10231620" },
      {
        "name": "Live improved reagan pompon chrysanthemum",
        "code": "10231621"
      },
      { "name": "Live life pompon chrysanthemum", "code": "10231622" },
      {
        "name": "Live managua orange pompon chrysanthemum",
        "code": "10231623"
      },
      {
        "name": "Live novedad bronze cocarde pompon chrysanthemum",
        "code": "10231624"
      },
      { "name": "Live orange reagan pompon chrysanthemum", "code": "10231625" },
      { "name": "Live orinoco pompon chrysanthemum", "code": "10231626" },
      { "name": "Live petra pompon chrysanthemum", "code": "10231627" },
      { "name": "Live pink balsas pompon chrysanthemum", "code": "10231628" },
      {
        "name": "Live pink mona lisa pompon chrysanthemum",
        "code": "10231629"
      },
      { "name": "Live pink reagan pompon chrysanthemum", "code": "10231630" },
      { "name": "Live reagan ivory pompon chrysanthemum", "code": "10231631" },
      { "name": "Live reagan rosy pompon chrysanthemum", "code": "10231632" },
      { "name": "Live rebasco pompon chrysanthemum", "code": "10231633" },
      { "name": "Live redock pompon chrysanthemum", "code": "10231634" },
      {
        "name": "Live salmon lineker pompon chrysanthemum",
        "code": "10231635"
      },
      { "name": "Live sheba pompon chrysanthemum", "code": "10231636" },
      { "name": "Live sirius pompon chrysanthemum", "code": "10231637" },
      {
        "name": "Live splendid reagan pompon chrysanthemum",
        "code": "10231638"
      },
      { "name": "Live sunny reagan pompon chrysanthemum", "code": "10231639" },
      { "name": "Live tina pompon chrysanthemum", "code": "10231640" },
      { "name": "Live vero pompon chrysanthemum", "code": "10231641" },
      { "name": "Live volare pompon chrysanthemum", "code": "10231642" },
      { "name": "Live white life pompon chrysanthemum", "code": "10231643" },
      { "name": "Live white reagan pompon chrysanthemum", "code": "10231644" },
      { "name": "Live white rhino pompon chrysanthemum", "code": "10231645" },
      { "name": "Live yellow vero pompon chrysanthemum", "code": "10231646" },
      { "name": "Live zenith pompon chrysanthemum", "code": "10231647" },
      { "name": "Live cremon disbud chrysanthemums", "code": "10231700" },
      {
        "name": "Live cremon annecy dark disbud chrysanthemum",
        "code": "10231701"
      },
      {
        "name": "Live cremon atlantis disbud chrysanthemum",
        "code": "10231702"
      },
      {
        "name": "Live cremon atlantis pink disbud chrysanthemum",
        "code": "10231703"
      },
      {
        "name": "Live cremon eleonora bronze disbud chrysanthemum",
        "code": "10231704"
      },
      {
        "name": "Live cremon eleonora lilac disbud chrysanthemum",
        "code": "10231705"
      },
      {
        "name": "Live cremon eleonora pink disbud chrysanthemum",
        "code": "10231706"
      },
      {
        "name": "Live cremon eleonora snow disbud chrysanthemum",
        "code": "10231707"
      },
      {
        "name": "Live cremon eleonora yellow disbud chrysanthemum",
        "code": "10231708"
      },
      { "name": "Live cremon idea disbud chrysanthemum", "code": "10231709" },
      {
        "name": "Live cremon ivanna purple disbud chrysanthemum",
        "code": "10231710"
      },
      {
        "name": "Live cremon minka pink disbud chrysanthemum",
        "code": "10231711"
      },
      { "name": "Live cremon ready disbud chrysanthemum", "code": "10231712" },
      {
        "name": "Live cremon sinatra disbud chrysanthemum",
        "code": "10231713"
      },
      { "name": "Live rover red chrysanthemum", "code": "10231714" },
      { "name": "Live football disbud chrysanthemums", "code": "10231800" },
      { "name": "Live blaze disbud chrysanthemums", "code": "10231801" },
      { "name": "Live football kiss disbud chrysanthemum", "code": "10231802" },
      {
        "name": "Live football lavender/pink disbud chrysanthemum",
        "code": "10231803"
      },
      {
        "name": "Live football resouci disbud chrysanthemum",
        "code": "10231804"
      },
      {
        "name": "Live football white disbud chrysanthemum",
        "code": "10231805"
      },
      {
        "name": "Live football yellow disbud chrysanthemum",
        "code": "10231806"
      },
      { "name": "Live promenade disbud chrysanthemum", "code": "10231807" },
      { "name": "Live rebonnet disbud chrysanthemum", "code": "10231808" },
      { "name": "Live reflex disbud chrysanthemum", "code": "10231809" },
      { "name": "Live residence disbud chrysanthemum", "code": "10231810" },
      { "name": "Live resomee pearl disbud chrysanthemum", "code": "10231811" },
      { "name": "Live resouci disbud chrysanthemum", "code": "10231812" },
      { "name": "Live spider chrysanthemums", "code": "10231900" },
      {
        "name": "Live anastasia bronze spider chrysanthemum",
        "code": "10231901"
      },
      {
        "name": "Live anastasia dark bronze spider chrysanthemum",
        "code": "10231902"
      },
      {
        "name": "Live anastasia green spider chrysanthemum",
        "code": "10231903"
      },
      {
        "name": "Live anastasia lilac spider chrysanthemum",
        "code": "10231904"
      },
      {
        "name": "Live anastasia pink spider chrysanthemum",
        "code": "10231905"
      },
      {
        "name": "Live anastasia purple spider chrysanthemum",
        "code": "10231906"
      },
      {
        "name": "Live anastasia sunny spider chrysanthemum",
        "code": "10231907"
      },
      {
        "name": "Live anastasia white spider chrysanthemum",
        "code": "10231908"
      },
      { "name": "Live bradford spider chrysanthemum", "code": "10231909" },
      {
        "name": "Live delistar white spider chrysanthemum",
        "code": "10231910"
      },
      {
        "name": "Live delistar yellow spider chrysanthemum",
        "code": "10231911"
      },
      { "name": "Live minka spider chrysanthemum", "code": "10231912" },
      { "name": "Live natasha sunny spider chrysanthemum", "code": "10231913" },
      { "name": "Live pirouette spider chrysanthemum", "code": "10231914" },
      { "name": "Live reflect spider chrysanthemum", "code": "10231915" },
      { "name": "Live regatta spider chrysanthemum", "code": "10231916" },
      { "name": "Live render spider chrysanthemum", "code": "10231917" },
      { "name": "Live repertoire spider chrysanthemum", "code": "10231918" },
      { "name": "Live resolute spider chrysanthemum", "code": "10231919" },
      { "name": "Live resomac spider chrysanthemum", "code": "10231920" },
      { "name": "Live shamrock spider chrysanthemum", "code": "10231921" },
      { "name": "Live bronze mood spider chrysanthemum", "code": "10231922" },
      { "name": "Live super white spider chrysanthemum", "code": "10231923" },
      { "name": "Live super yellow spider chrysanthemum", "code": "10231924" },
      { "name": "Live tender spider chrysanthemum", "code": "10231925" },
      { "name": "Live zembla spider chrysanthemum", "code": "10231926" },
      { "name": "Live novelty chrysanthemums", "code": "10232000" },
      { "name": "Live annecy pink pompon chrysanthemum", "code": "10232001" },
      { "name": "Live ardilo royal pompon chrysanthemum", "code": "10232002" },
      { "name": "Live athos pompon chrysanthemum", "code": "10232003" },
      { "name": "Live biarritz pompon chrysanthemum", "code": "10232004" },
      {
        "name": "Live bradford orange pompon chrysanthemum",
        "code": "10232005"
      },
      { "name": "Live bradford pompon chrysanthemum", "code": "10232006" },
      { "name": "Live candle pompon chrysanthemum", "code": "10232007" },
      { "name": "Live candor pompon chrysanthemum", "code": "10232008" },
      { "name": "Live dash pompon chrysanthemum", "code": "10232009" },
      { "name": "Live decima pompon chrysanthemum", "code": "10232010" },
      { "name": "Live delisun pompon chrysanthemum", "code": "10232012" },
      { "name": "Live dion pompon chrysanthemum", "code": "10232013" },
      { "name": "Live dorena pompon chrysanthemum", "code": "10232014" },
      { "name": "Live dublin pompon chrysanthemum", "code": "10232015" },
      { "name": "Live everglades pompon chrysanthemum", "code": "10232016" },
      { "name": "Live handsome pompon chrysanthemum", "code": "10232017" },
      { "name": "Live hasting pompon chrysanthemum", "code": "10232018" },
      { "name": "Live high five pompon chrysanthemum", "code": "10232019" },
      {
        "name": "Live improved mundial pompon chrysanthemum",
        "code": "10232020"
      },
      { "name": "Live juanes pompon chrysanthemum", "code": "10232021" },
      { "name": "Live kiato green pompon chrysanthemum", "code": "10232022" },
      { "name": "Live kiato pompon chrysanthemum", "code": "10232023" },
      { "name": "Live kiwi pompon chrysanthemum", "code": "10232024" },
      { "name": "Live madeira pompon chrysanthemum", "code": "10232025" },
      { "name": "Live magnet pompon chrysanthemum", "code": "10232026" },
      { "name": "Live marimo pompon chrysanthemum", "code": "10232027" },
      { "name": "Live matrix pompon chrysanthemum", "code": "10232028" },
      { "name": "Live miletta pompon chrysanthemum", "code": "10232029" },
      { "name": "Live monalisa pompon chrysanthemum", "code": "10232030" },
      { "name": "Live omaha pompon chrysanthemum", "code": "10232031" },
      {
        "name": "Live orinoco purple pompon chrysanthemum",
        "code": "10232032"
      },
      {
        "name": "Live orinoco yellow pompon chrysanthemum",
        "code": "10232033"
      },
      { "name": "Live pacific green pompon chrysanthemum", "code": "10232034" },
      { "name": "Live puma white pompon chrysanthemum", "code": "10232035" },
      { "name": "Live puma yellow pompon chrysanthemum", "code": "10232036" },
      {
        "name": "Live purple mundial pompon chrysanthemum",
        "code": "10232037"
      },
      { "name": "Live regatta pompon chrysanthemum", "code": "10232038" },
      { "name": "Live remco pompon chrysanthemum", "code": "10232039" },
      { "name": "Live royal mundial pompon chrysanthemum", "code": "10232040" },
      { "name": "Live sabrina pompon chrysanthemum", "code": "10232041" },
      { "name": "Live shakira white pompon chrysanthemum", "code": "10232042" },
      { "name": "Live sharp pompon chrysanthemum", "code": "10232043" },
      { "name": "Live shock pompon chrysanthemum", "code": "10232044" },
      { "name": "Live sizzle green pompon chrysanthemum", "code": "10232045" },
      { "name": "Live sizzle pink pompon chrysanthemum", "code": "10232046" },
      { "name": "Live sizzle pompon chrysanthemum", "code": "10232047" },
      { "name": "Live sizzle purple pompon chrysanthemum", "code": "10232048" },
      { "name": "Live sizzle salmon pompon chrysanthemum", "code": "10232049" },
      { "name": "Live sizzle yellow pompon chrysanthemum", "code": "10232050" },
      { "name": "Live spain flag pompon chrysanthemum", "code": "10232051" },
      {
        "name": "Live starburst or snowflake pompon chrysanthemum",
        "code": "10232052"
      },
      { "name": "Live swan pompon chrysanthemum", "code": "10232053" },
      { "name": "Live tedcha orange pompon chrysanthemum", "code": "10232054" },
      { "name": "Live tender pompon chrysanthemum", "code": "10232055" },
      { "name": "Live tinsel pompon chrysanthemum", "code": "10232056" },
      { "name": "Live touch pompon chrysanthemum", "code": "10232057" },
      { "name": "Live troyes pompon chrysanthemum", "code": "10232058" },
      { "name": "Live valesca pompon chrysanthemum", "code": "10232059" },
      { "name": "Live viking orange pompon chrysanthemum", "code": "10232060" },
      { "name": "Live viking pompon chrysanthemum", "code": "10232061" },
      { "name": "Live watch pompon chrysanthemum", "code": "10232062" },
      { "name": "Live white needle pompon chrysanthemum", "code": "10232063" },
      { "name": "Live white night pompon chrysanthemum", "code": "10232064" },
      { "name": "Live yellow artist pompon chrysanthemum", "code": "10232065" },
      {
        "name": "Live yellow fiction pompon chrysanthemum",
        "code": "10232066"
      },
      { "name": "Live yellow sharp pompon chrysanthemum", "code": "10232067" },
      { "name": "Live santini chrysanthemums", "code": "10232100" },
      { "name": "Live alma pompon chrysanthemum", "code": "10232101" },
      { "name": "Live baron pompon chrysanthemum", "code": "10232102" },
      { "name": "Live bernardo pompon chrysanthemum", "code": "10232103" },
      { "name": "Live bistro pompon chrysanthemum", "code": "10232104" },
      { "name": "Live bodega pompon chrysanthemum", "code": "10232105" },
      { "name": "Live breeze pompon chrysanthemum", "code": "10232106" },
      {
        "name": "Live bronze centella pompon chrysanthemum",
        "code": "10232107"
      },
      { "name": "Live costa white pompon chrysanthemum", "code": "10232108" },
      { "name": "Live creta pompon chrysanthemum", "code": "10232109" },
      { "name": "Live deliflame pompon chrysanthemum", "code": "10232110" },
      { "name": "Live delilah pompon chrysanthemum", "code": "10232111" },
      { "name": "Live digit pompon chrysanthemum", "code": "10232112" },
      { "name": "Live evilio pompon chrysanthemum", "code": "10232113" },
      { "name": "Live furense pompon chrysanthemum", "code": "10232114" },
      { "name": "Live guide pompon chrysanthemum", "code": "10232115" },
      { "name": "Live kerry pompon chrysanthemum", "code": "10232116" },
      { "name": "Live kess pompon chrysanthemum", "code": "10232117" },
      { "name": "Live lima pompon chrysanthemum", "code": "10232118" },
      { "name": "Live lupo pompon chrysanthemum", "code": "10232119" },
      {
        "name": "Live orange lineker pompon chrysanthemum",
        "code": "10232120"
      },
      { "name": "Live panuco red pompon chrysanthemum", "code": "10232121" },
      { "name": "Live pink costa pompon chrysanthemum", "code": "10232122" },
      { "name": "Live raphael pompon chrysanthemum", "code": "10232123" },
      { "name": "Live refine pompon chrysanthemum", "code": "10232124" },
      { "name": "Live regalis pompon chrysanthemum", "code": "10232125" },
      { "name": "Live renella pompon chrysanthemum", "code": "10232126" },
      { "name": "Live return pompon chrysanthemum", "code": "10232127" },
      { "name": "Live river pompon chrysanthemum", "code": "10232128" },
      { "name": "Live sabas pompon chrysanthemum", "code": "10232129" },
      { "name": "Live target pompon chrysanthemum", "code": "10232130" },
      { "name": "Live text pompon chrysanthemum", "code": "10232131" },
      {
        "name": "Live yellow stallion pompon chrysanthemum",
        "code": "10232132"
      },
      { "name": "Live carnations", "code": "10240000" },
      { "name": "Live single bloom carnations", "code": "10241500" },
      {
        "name": "Live single bloom burgundy bi color carnation",
        "code": "10241501"
      },
      { "name": "Live single bloom burgundy carnation", "code": "10241502" },
      { "name": "Live single bloom cinderella carnation", "code": "10241503" },
      {
        "name": "Live single bloom cream bi color carnation",
        "code": "10241504"
      },
      { "name": "Live single bloom cream carnation", "code": "10241505" },
      {
        "name": "Live single bloom green or prado carnation",
        "code": "10241506"
      },
      { "name": "Live single bloom hot pink carnation", "code": "10241507" },
      { "name": "Live single bloom light green carnation", "code": "10241508" },
      { "name": "Live single bloom light pink carnation", "code": "10241509" },
      {
        "name": "Live single bloom orange bi color carnation",
        "code": "10241510"
      },
      { "name": "Live single bloom orange carnation", "code": "10241511" },
      { "name": "Live single bloom peach carnation", "code": "10241512" },
      {
        "name": "Live single bloom peppermint bi color carnation",
        "code": "10241513"
      },
      {
        "name": "Live single bloom pink bi color carnation",
        "code": "10241514"
      },
      { "name": "Live single bloom pink carnation", "code": "10241515" },
      {
        "name": "Live single bloom purple bi color carnation",
        "code": "10241516"
      },
      {
        "name": "Live single bloom red bi color carnation",
        "code": "10241517"
      },
      { "name": "Live single bloom red carnation", "code": "10241518" },
      { "name": "Live single bloom white carnation", "code": "10241519" },
      { "name": "Live single bloom yellow carnation", "code": "10241520" },
      { "name": "Live mini or spray carnations", "code": "10241600" },
      { "name": "Live burgundy mini or spray carnation", "code": "10241601" },
      { "name": "Live cream mini or spray carnation", "code": "10241602" },
      { "name": "Live hot pink mini or spray carnation", "code": "10241603" },
      { "name": "Live lavender mini or spray carnation", "code": "10241604" },
      { "name": "Live light pink mini or spray carnation", "code": "10241605" },
      { "name": "Live orange mini or spray carnation", "code": "10241606" },
      { "name": "Live peach mini or spray carnation", "code": "10241607" },
      { "name": "Live peppermint mini or spray carnation", "code": "10241608" },
      { "name": "Live pink mini or spray carnation", "code": "10241609" },
      {
        "name": "Live purple bi color mini or spray carnation",
        "code": "10241610"
      },
      { "name": "Live purple mini or spray carnation", "code": "10241611" },
      { "name": "Live red mini or spray carnation", "code": "10241612" },
      { "name": "Live white mini or spray carnation", "code": "10241613" },
      { "name": "Live yellow mini or spray carnation", "code": "10241614" },
      { "name": "Live orchids", "code": "10250000" },
      {
        "name": "Live cypripedium or ladys slipper orchids",
        "code": "10251500"
      },
      { "name": "Live green cypripedium orchid", "code": "10251501" },
      { "name": "Live france cypripedium orchid", "code": "10251502" },
      {
        "name": "Live purple king arthur cypripedium orchid",
        "code": "10251503"
      },
      { "name": "Live green paphiopedilum orchid", "code": "10251504" },
      { "name": "Live aranthera maggie vie orchid", "code": "10251505" },
      { "name": "Live mokara or mocara orchids", "code": "10251600" },
      { "name": "Live mocara omyai orchid", "code": "10251601" },
      { "name": "Live mocara red orchid", "code": "10251602" },
      { "name": "Live mokara calypso orchid", "code": "10251603" },
      { "name": "Live mokara nora orchid", "code": "10251604" },
      { "name": "Live mokara panee orchid", "code": "10251605" },
      { "name": "Live cattleya orchids", "code": "10251700" },
      { "name": "Live white cattleya orchid", "code": "10251701" },
      { "name": "Live r b lavender cattleya orchid", "code": "10251702" },
      { "name": "Live disa orchids", "code": "10251800" },
      { "name": "Live red disa orchid", "code": "10251801" },
      { "name": "Live orange disa orchid", "code": "10251802" },
      { "name": "Live pink disa orchid", "code": "10251803" },
      {
        "name": "Live orange and yellow bi color disa orchid",
        "code": "10251804"
      },
      {
        "name": "Live peach and yellow bi color disa orchid",
        "code": "10251805"
      },
      {
        "name": "Live yellow and red bi color disa orchid",
        "code": "10251806"
      },
      { "name": "Live arachnis orchids", "code": "10251900" },
      { "name": "Live james storie red arachnis orchid", "code": "10251901" },
      {
        "name": "Live maggie oei red ribbon arachnis orchid",
        "code": "10251902"
      },
      {
        "name": "Live maggie oei yellow ribbon arachnis orchid",
        "code": "10251903"
      },
      { "name": "Live maroon maggie arachnis orchid", "code": "10251904" },
      { "name": "Live merry maggie arachnis orchid", "code": "10251905" },
      { "name": "Live phalaenopsis orchids", "code": "10252000" },
      { "name": "Live phalaenopsis amabilis orchid", "code": "10252001" },
      { "name": "Live phalaenopsis amboinensis orchid", "code": "10252002" },
      { "name": "Live phalaenopsis aphrodite orchid", "code": "10252003" },
      { "name": "Live phalaenopsis appendiculata orchid", "code": "10252004" },
      { "name": "Live phalaenopsis bastianii orchid", "code": "10252005" },
      { "name": "Live phalaenopsis bellina orchid", "code": "10252006" },
      { "name": "Live phalaenopsis borneensis orchid", "code": "10252007" },
      { "name": "Live phalaenopsis braceana orchid", "code": "10252008" },
      { "name": "Live phalaenopsis buyssoniana orchid", "code": "10252009" },
      { "name": "Live phalaenopsis celebensis orchid", "code": "10252010" },
      { "name": "Live phalaenopsis chibae orchid", "code": "10252011" },
      { "name": "Live phalaenopsis cochlearis orchid", "code": "10252012" },
      { "name": "Live phalaenopsis corningiana orchid", "code": "10252013" },
      { "name": "Live phalaenopsis cornu-cervi orchid", "code": "10252014" },
      { "name": "Live phalaenopsis deliciosa orchid", "code": "10252015" },
      {
        "name": "Live phalaenopsis dowery\u00ebnsis orchid",
        "code": "10252016"
      },
      { "name": "Live phalaenopsis equestris orchid", "code": "10252017" },
      { "name": "Live phalaenopsis fasciata orchid", "code": "10252018" },
      { "name": "Live phalaenopsis fimbriata orchid", "code": "10252019" },
      { "name": "Live phalaenopsis floresensis orchid", "code": "10252020" },
      { "name": "Live phalaenopsis fuscata orchid", "code": "10252021" },
      { "name": "Live phalaenopsis gibbosa orchid", "code": "10252022" },
      { "name": "Live phalaenopsis hainanensis orchid", "code": "10252023" },
      { "name": "Live phalaenopsis hieroglyphica orchid", "code": "10252024" },
      { "name": "Live phalaenopsis honghenensis orchid", "code": "10252025" },
      {
        "name": "Live phalaenopsis inscriptiosinensis orchid",
        "code": "10252026"
      },
      { "name": "Live phalaenopsis javanica orchid", "code": "10252027" },
      { "name": "Live phalaenopsis kunstleri orchid", "code": "10252028" },
      { "name": "Live phalaenopsis lamelligera orchid", "code": "10252029" },
      { "name": "Live phalaenopsis lindenii orchid", "code": "10252030" },
      { "name": "Live phalaenopsis lobbii orchid", "code": "10252031" },
      { "name": "Live phalaenopsis lowii orchid", "code": "10252032" },
      { "name": "Live phalaenopsis lueddemanniana orchid", "code": "10252033" },
      { "name": "Live phalaenopsis mambo orchid", "code": "10252034" },
      { "name": "Live phalaenopsis luteola orchid", "code": "10252035" },
      { "name": "Live phalaenopsis maculata orchid", "code": "10252036" },
      { "name": "Live phalaenopsis malipoensis orchid", "code": "10252037" },
      { "name": "Live phalaenopsis mannii orchid", "code": "10252038" },
      { "name": "Live phalaenopsis mariae orchid", "code": "10252039" },
      { "name": "Live phalaenopsis micholitzii orchid", "code": "10252040" },
      { "name": "Live phalaenopsis modesta orchid", "code": "10252041" },
      { "name": "Live phalaenopsis mysorensis orchid", "code": "10252042" },
      { "name": "Live phalaenopsis pallens orchid", "code": "10252043" },
      { "name": "Live phalaenopsis pantherina orchid", "code": "10252044" },
      { "name": "Live phalaenopsis parishii orchid", "code": "10252045" },
      { "name": "Live phalaenopsis petelotii orchid", "code": "10252046" },
      { "name": "Live phalaenopsis philippinensis orchid", "code": "10252047" },
      { "name": "Live phalaenopsis pulcherrima orchid", "code": "10252048" },
      { "name": "Live phalaenopsis pulchra orchid", "code": "10252049" },
      { "name": "Live phalaenopsis regnieriana orchid", "code": "10252050" },
      {
        "name": "Live phalaenopsis reichenbachiana orchid",
        "code": "10252051"
      },
      { "name": "Live phalaenopsis Nivacolor orchid", "code": "10252052" },
      { "name": "Live phalaenopsis sanderiana orchid", "code": "10252053" },
      { "name": "Live phalaenopsis schilleriana orchid", "code": "10252054" },
      { "name": "Live phalaenopsis speciosa orchid", "code": "10252055" },
      { "name": "Live phalaenopsis stobartiana orchid", "code": "10252056" },
      { "name": "Live phalaenopsis stuartiana orchid", "code": "10252057" },
      { "name": "Live phalaenopsis sumatrana orchid", "code": "10252058" },
      { "name": "Live phalaenopsis taenialis orchid", "code": "10252059" },
      { "name": "Live phalaenopsis tetraspis orchid", "code": "10252060" },
      { "name": "Live phalaenopsis venosa orchid", "code": "10252061" },
      { "name": "Live phalaenopsis violacea orchid", "code": "10252062" },
      { "name": "Live phalaenopsis viridis orchid", "code": "10252063" },
      { "name": "Live phalaenopsis wilsonii orchid", "code": "10252064" },
      { "name": "Live phalaenopsis zebrina orchid", "code": "10252065" },
      { "name": "Live lavender lip phalaenopsis orchid", "code": "10252067" },
      { "name": "Live dendrobium orchids", "code": "10252100" },
      { "name": "Live bom dendrobium orchid", "code": "10252101" },
      { "name": "Live burana jade dendrobium orchid", "code": "10252102" },
      { "name": "Live cheetah dendrobium orchid", "code": "10252103" },
      { "name": "Live fatima dendrobium orchid", "code": "10252104" },
      { "name": "Live intuwong dendrobium orchid", "code": "10252105" },
      { "name": "Live jumbo white dendrobium orchid", "code": "10252106" },
      { "name": "Live kating dang dendrobium orchid", "code": "10252107" },
      { "name": "Live liberty dendrobium orchid", "code": "10252108" },
      { "name": "Live orchid hawaii dendrobium orchid", "code": "10252109" },
      {
        "name": "Live sakura sweet pink dendrobium orchid",
        "code": "10252110"
      },
      {
        "name": "Live sensational purple dendrobium orchid",
        "code": "10252111"
      },
      { "name": "Live white dendrobium orchid", "code": "10252112" },
      { "name": "Live cymbidium orchids", "code": "10252200" },
      { "name": "Live cream cymbidium orchid", "code": "10252201" },
      { "name": "Live green cymbidium orchid", "code": "10252202" },
      { "name": "Live mini green cymbidium orchid", "code": "10252203" },
      { "name": "Live mini pink cymbidium orchid", "code": "10252204" },
      { "name": "Live mini red cymbidium orchid", "code": "10252205" },
      { "name": "Live mini white cymbidium orchid", "code": "10252206" },
      { "name": "Live mini yellow cymbidium orchid", "code": "10252207" },
      { "name": "Live chocolate cymbidium orchid", "code": "10252208" },
      { "name": "Live dark pink cymbidium orchid", "code": "10252209" },
      { "name": "Live orange cymbidium orchid", "code": "10252210" },
      { "name": "Live pink cymbidium orchid", "code": "10252211" },
      { "name": "Live white cymbidium orchid", "code": "10252212" },
      { "name": "Live yellow cymbidium orchid", "code": "10252213" },
      { "name": "Live oncidium orchids", "code": "10252300" },
      { "name": "Live golden shower oncidium orchid", "code": "10252301" },
      { "name": "Live rhamsey oncidium orchid", "code": "10252302" },
      { "name": "Live vanda orchids", "code": "10252400" },
      { "name": "Live alizarin vanda orchid", "code": "10252401" },
      { "name": "Live hot pink vanda orchid", "code": "10252402" },
      { "name": "Live lavender vanda orchid", "code": "10252403" },
      { "name": "Live purple vanda orchid", "code": "10252404" },
      { "name": "Live tickle me pink vanda orchid", "code": "10252405" },
      { "name": "Live yellow vanda orchid", "code": "10252406" },
      { "name": "Fresh cut rose", "code": "10300000" },
      {
        "name": "Fresh cut blue or lavender or purple rose",
        "code": "10301500"
      },
      { "name": "Fresh cut allure or sterling 95 rose", "code": "10301501" },
      { "name": "Fresh cut amnesia rose", "code": "10301502" },
      { "name": "Fresh cut augusta louise rose", "code": "10301503" },
      { "name": "Fresh cut avant garde rose", "code": "10301504" },
      { "name": "Fresh cut blue bird rose", "code": "10301505" },
      { "name": "Fresh cut blue curiosa rose", "code": "10301506" },
      { "name": "Fresh cut cool water rose", "code": "10301507" },
      { "name": "Fresh cut delilah rose", "code": "10301508" },
      { "name": "Fresh cut double party rose", "code": "10301509" },
      { "name": "Fresh cut faith rose", "code": "10301510" },
      { "name": "Fresh cut mami blue or mammy blue rose", "code": "10301511" },
      { "name": "Fresh cut maritime rose", "code": "10301512" },
      { "name": "Fresh cut milano rose", "code": "10301513" },
      { "name": "Fresh cut mystery rose", "code": "10301514" },
      { "name": "Fresh cut ocean song or boyfriend rose", "code": "10301515" },
      { "name": "Fresh cut purple cezanne rose", "code": "10301516" },
      { "name": "Fresh cut purple fragrance rose", "code": "10301517" },
      { "name": "Fresh cut sanaa rose", "code": "10301518" },
      { "name": "Fresh cut silverstone rose", "code": "10301519" },
      { "name": "Fresh cut soulmate rose", "code": "10301520" },
      { "name": "Fresh cut stranger rose", "code": "10301521" },
      { "name": "Fresh cut tinted blue rose", "code": "10301522" },
      { "name": "Fresh cut two faces rose", "code": "10301523" },
      { "name": "Fresh cut chocolate or brown rose", "code": "10301600" },
      { "name": "Fresh cut black lava rose", "code": "10301601" },
      { "name": "Fresh cut cimarron rose", "code": "10301602" },
      { "name": "Fresh cut coffee break rose", "code": "10301603" },
      { "name": "Fresh cut estelle rose", "code": "10301604" },
      { "name": "Fresh cut gypsy leonidas rose", "code": "10301605" },
      { "name": "Fresh cut leonidas rose", "code": "10301606" },
      { "name": "Fresh cut matilda rose", "code": "10301607" },
      { "name": "Fresh cut sunny leonidas rose", "code": "10301608" },
      { "name": "Fresh cut terra nostra rose", "code": "10301609" },
      { "name": "Fresh cut terracotta rose", "code": "10301610" },
      { "name": "Fresh cut cream rose", "code": "10301700" },
      { "name": "Fresh cut advenire rose", "code": "10301701" },
      { "name": "Fresh cut alex rose", "code": "10301702" },
      { "name": "Fresh cut antique brass rose", "code": "10301703" },
      { "name": "Fresh cut aubade rose", "code": "10301704" },
      { "name": "Fresh cut beach rose", "code": "10301705" },
      { "name": "Fresh cut belle pearl rose", "code": "10301706" },
      {
        "name": "Fresh cut blush or blush de los andesrose",
        "code": "10301707"
      },
      { "name": "Fresh cut camel rose", "code": "10301708" },
      {
        "name": "Fresh cut caramel antike or caramel antique rose",
        "code": "10301709"
      },
      { "name": "Fresh cut champagne rose", "code": "10301710" },
      { "name": "Fresh cut clear ocean rose", "code": "10301711" },
      { "name": "Fresh cut combo rose", "code": "10301712" },
      { "name": "Fresh cut creme de la creme rose", "code": "10301713" },
      { "name": "Fresh cut emanuella rose", "code": "10301714" },
      { "name": "Fresh cut evolution rose", "code": "10301715" },
      { "name": "Fresh cut fedora rose", "code": "10301716" },
      { "name": "Fresh cut fenice rose", "code": "10301717" },
      { "name": "Fresh cut french vanilla rose", "code": "10301718" },
      { "name": "Fresh cut hollywood rose", "code": "10301719" },
      { "name": "Fresh cut ilios rose", "code": "10301720" },
      { "name": "Fresh cut jelena rose", "code": "10301721" },
      { "name": "Fresh cut kameleon rose", "code": "10301722" },
      { "name": "Fresh cut kentucky rose", "code": "10301723" },
      { "name": "Fresh cut kings pride rose", "code": "10301724" },
      { "name": "Fresh cut latin fusion rose", "code": "10301725" },
      { "name": "Fresh cut lemon dream rose", "code": "10301726" },
      { "name": "Fresh cut magic moka rose", "code": "10301727" },
      { "name": "Fresh cut mamamia rose", "code": "10301728" },
      { "name": "Fresh cut message rose", "code": "10301729" },
      { "name": "Fresh cut muneca or munieca rose", "code": "10301730" },
      { "name": "Fresh cut parfum de rosas rose", "code": "10301731" },
      { "name": "Fresh cut porcelina rose", "code": "10301732" },
      { "name": "Fresh cut privilege rose", "code": "10301733" },
      { "name": "Fresh cut quicksand rose", "code": "10301734" },
      { "name": "Fresh cut rollercoaster rose", "code": "10301735" },
      { "name": "Fresh cut romantic curiosa rose", "code": "10301736" },
      { "name": "Fresh cut safari rose", "code": "10301737" },
      { "name": "Fresh cut sahara rose", "code": "10301738" },
      { "name": "Fresh cut sandy femma rose", "code": "10301739" },
      { "name": "Fresh cut talea rose", "code": "10301740" },
      { "name": "Fresh cut timeless rose", "code": "10301741" },
      { "name": "Fresh cut transition rose", "code": "10301742" },
      { "name": "Fresh cut trump rose", "code": "10301743" },
      { "name": "Fresh cut twin rose", "code": "10301744" },
      { "name": "Fresh cut vendela rose", "code": "10301745" },
      { "name": "Fresh cut virginia rose", "code": "10301746" },
      { "name": "Fresh cut green or lime rose", "code": "10301800" },
      { "name": "Fresh cut amandine rose", "code": "10301801" },
      { "name": "Fresh cut caipirinha rose", "code": "10301802" },
      { "name": "Fresh cut green fashion rose", "code": "10301803" },
      { "name": "Fresh cut green tea rose", "code": "10301804" },
      { "name": "Fresh cut jade rose", "code": "10301805" },
      { "name": "Fresh cut limbo rose", "code": "10301806" },
      { "name": "Fresh cut limena or limenia rose", "code": "10301807" },
      { "name": "Fresh cut limona rose", "code": "10301808" },
      { "name": "Fresh cut old dutch rose", "code": "10301809" },
      { "name": "Fresh cut super green rose", "code": "10301810" },
      { "name": "Fresh cut sweet green rose", "code": "10301811" },
      { "name": "Fresh cut viva rose", "code": "10301812" },
      { "name": "Fresh cut zazu rose", "code": "10301813" },
      { "name": "Fresh cut light pink rose", "code": "10301900" },
      { "name": "Fresh cut anna rose", "code": "10301901" },
      { "name": "Fresh cut bella vita rose", "code": "10301902" },
      { "name": "Fresh cut bridal dream rose", "code": "10301903" },
      { "name": "Fresh cut candy bianca rose", "code": "10301904" },
      { "name": "Fresh cut caress rose", "code": "10301905" },
      { "name": "Fresh cut carolina rose", "code": "10301906" },
      { "name": "Fresh cut climax rose", "code": "10301907" },
      { "name": "Fresh cut danny rose", "code": "10301908" },
      { "name": "Fresh cut dolce vita rose", "code": "10301909" },
      { "name": "Fresh cut elite rose", "code": "10301910" },
      { "name": "Fresh cut emma rose", "code": "10301911" },
      { "name": "Fresh cut engagement rose", "code": "10301912" },
      { "name": "Fresh cut esther rose", "code": "10301913" },
      { "name": "Fresh cut excalibur rose", "code": "10301914" },
      { "name": "Fresh cut exciting rose", "code": "10301915" },
      { "name": "Fresh cut first lady rose", "code": "10301916" },
      { "name": "Fresh cut geraldine rose", "code": "10301917" },
      { "name": "Fresh cut gotcha rose", "code": "10301918" },
      { "name": "Fresh cut harmonie rose", "code": "10301919" },
      { "name": "Fresh cut heaven rose", "code": "10301920" },
      { "name": "Fresh cut high and elegant rose", "code": "10301921" },
      { "name": "Fresh cut katherine rose", "code": "10301922" },
      { "name": "Fresh cut king kong rose", "code": "10301923" },
      { "name": "Fresh cut livia rose", "code": "10301924" },
      { "name": "Fresh cut lorena rose", "code": "10301925" },
      { "name": "Fresh cut lovely amazon rose", "code": "10301926" },
      { "name": "Fresh cut maaike rose", "code": "10301927" },
      { "name": "Fresh cut marilyn rose", "code": "10301928" },
      { "name": "Fresh cut marlise rose", "code": "10301929" },
      { "name": "Fresh cut miranda or ausimmon rose", "code": "10301930" },
      { "name": "Fresh cut mona lisa rose", "code": "10301931" },
      { "name": "Fresh cut nirvana rose", "code": "10301932" },
      { "name": "Fresh cut o hara rose", "code": "10301933" },
      { "name": "Fresh cut ole rose", "code": "10301934" },
      { "name": "Fresh cut olga rose", "code": "10301935" },
      { "name": "Fresh cut pacifica rose", "code": "10301936" },
      { "name": "Fresh cut party mix rose", "code": "10301937" },
      { "name": "Fresh cut peckoubo or pekcoubo rose", "code": "10301938" },
      { "name": "Fresh cut phoebe or ausnotice rose", "code": "10301939" },
      { "name": "Fresh cut pink farfalla rose", "code": "10301940" },
      { "name": "Fresh cut pink finess rose", "code": "10301941" },
      { "name": "Fresh cut pink magic rose", "code": "10301942" },
      { "name": "Fresh cut pink osiana rose", "code": "10301943" },
      { "name": "Fresh cut pretty woman rose", "code": "10301944" },
      { "name": "Fresh cut romance rose", "code": "10301945" },
      {
        "name": "Fresh cut romantic antike or antique rose",
        "code": "10301946"
      },
      { "name": "Fresh cut rosalind or austew rose", "code": "10301947" },
      { "name": "Fresh cut rosita vendela rose", "code": "10301948" },
      { "name": "Fresh cut secret garden rose", "code": "10301949" },
      { "name": "Fresh cut solaire rose", "code": "10301950" },
      { "name": "Fresh cut sophie rose", "code": "10301951" },
      { "name": "Fresh cut sweet akito rose", "code": "10301952" },
      { "name": "Fresh cut sweet avalanche rose", "code": "10301953" },
      { "name": "Fresh cut sweet elegance rose", "code": "10301954" },
      { "name": "Fresh cut sweet pink rose", "code": "10301955" },
      { "name": "Fresh cut titanic rose", "code": "10301956" },
      { "name": "Fresh cut toscanini rose", "code": "10301957" },
      { "name": "Fresh cut vania rose", "code": "10301958" },
      { "name": "Fresh cut vanity rose", "code": "10301959" },
      { "name": "Fresh cut vision rose", "code": "10301960" },
      { "name": "Fresh cut vivaldi rose", "code": "10301961" },
      { "name": "Fresh cut whisper rose", "code": "10301962" },
      { "name": "Fresh cut multi-colored pink rose", "code": "10302000" },
      { "name": "Fresh cut attracta rose", "code": "10302001" },
      { "name": "Fresh cut boheme rose", "code": "10302002" },
      { "name": "Fresh cut carousel rose", "code": "10302003" },
      { "name": "Fresh cut cezanne rose", "code": "10302004" },
      { "name": "Fresh cut crazy one rose", "code": "10302005" },
      { "name": "Fresh cut dance valley rose", "code": "10302006" },
      { "name": "Fresh cut duett rose", "code": "10302007" },
      { "name": "Fresh cut esperance rose", "code": "10302008" },
      { "name": "Fresh cut fiesta rose", "code": "10302009" },
      { "name": "Fresh cut halloween rose", "code": "10302010" },
      { "name": "Fresh cut highlander rose", "code": "10302011" },
      { "name": "Fresh cut hot ambiance rose", "code": "10302012" },
      { "name": "Fresh cut la belle rose", "code": "10302013" },
      { "name": "Fresh cut laguna rose", "code": "10302014" },
      { "name": "Fresh cut latin ambiance rose", "code": "10302015" },
      { "name": "Fresh cut latin breeze rose", "code": "10302016" },
      { "name": "Fresh cut long arifa rose", "code": "10302017" },
      { "name": "Fresh cut murano rose", "code": "10302018" },
      { "name": "Fresh cut n-joy rose", "code": "10302019" },
      { "name": "Fresh cut panama rose", "code": "10302020" },
      { "name": "Fresh cut peppermint rose", "code": "10302021" },
      { "name": "Fresh cut pijama party rose", "code": "10302022" },
      { "name": "Fresh cut portofino rose", "code": "10302023" },
      { "name": "Fresh cut priceless rose", "code": "10302024" },
      { "name": "Fresh cut queen amazon rose", "code": "10302025" },
      { "name": "Fresh cut ranuncula rose", "code": "10302026" },
      { "name": "Fresh cut rossini rose", "code": "10302027" },
      { "name": "Fresh cut sabina or sabrina rose", "code": "10302028" },
      { "name": "Fresh cut scandal rose", "code": "10302029" },
      { "name": "Fresh cut silvery pink rose", "code": "10302030" },
      { "name": "Fresh cut something else rose", "code": "10302031" },
      { "name": "Fresh cut soutine rose", "code": "10302032" },
      { "name": "Fresh cut sovereign rose", "code": "10302033" },
      { "name": "Fresh cut super disco rose", "code": "10302034" },
      { "name": "Fresh cut ts 1968 rose", "code": "10302035" },
      { "name": "Fresh cut variance rose", "code": "10302036" },
      { "name": "Fresh cut verdi rose", "code": "10302037" },
      { "name": "Fresh cut orange rose", "code": "10302100" },
      { "name": "Fresh cut alhambra rose", "code": "10302101" },
      { "name": "Fresh cut aloha rose", "code": "10302102" },
      { "name": "Fresh cut amber rose", "code": "10302103" },
      { "name": "Fresh cut apache rose", "code": "10302104" },
      { "name": "Fresh cut arabia rose", "code": "10302105" },
      { "name": "Fresh cut bengala rose", "code": "10302106" },
      { "name": "Fresh cut bibi rose", "code": "10302107" },
      { "name": "Fresh cut caramba rose", "code": "10302108" },
      { "name": "Fresh cut caramella rose", "code": "10302109" },
      { "name": "Fresh cut carla rose", "code": "10302110" },
      { "name": "Fresh cut cartagena rose", "code": "10302111" },
      { "name": "Fresh cut chanson rose", "code": "10302112" },
      { "name": "Fresh cut charmer rose", "code": "10302113" },
      { "name": "Fresh cut cherry brandy rose", "code": "10302114" },
      { "name": "Fresh cut chilis rose", "code": "10302115" },
      { "name": "Fresh cut cinnamon rose", "code": "10302116" },
      { "name": "Fresh cut colandro rose", "code": "10302117" },
      { "name": "Fresh cut coral sea rose", "code": "10302118" },
      { "name": "Fresh cut corvette or red corvette rose", "code": "10302119" },
      { "name": "Fresh cut dark milva rose", "code": "10302120" },
      { "name": "Fresh cut donna rose", "code": "10302121" },
      { "name": "Fresh cut dreamer rose", "code": "10302122" },
      { "name": "Fresh cut el dorado rose", "code": "10302123" },
      { "name": "Fresh cut el toro rose", "code": "10302124" },
      { "name": "Fresh cut elena rose", "code": "10302125" },
      { "name": "Fresh cut ensueno rose", "code": "10302126" },
      { "name": "Fresh cut euforia rose", "code": "10302127" },
      { "name": "Fresh cut exotica rose", "code": "10302128" },
      { "name": "Fresh cut fancy amazon rose", "code": "10302129" },
      { "name": "Fresh cut fiction rose", "code": "10302130" },
      { "name": "Fresh cut finess rose", "code": "10302131" },
      { "name": "Fresh cut flamenco rose", "code": "10302132" },
      { "name": "Fresh cut free spirit rose", "code": "10302133" },
      { "name": "Fresh cut gelato rose", "code": "10302134" },
      { "name": "Fresh cut gypsy curiosa rose", "code": "10302135" },
      { "name": "Fresh cut high and magic rose", "code": "10302136" },
      { "name": "Fresh cut high and orange magic rose", "code": "10302137" },
      { "name": "Fresh cut iguana or alegra rose", "code": "10302138" },
      { "name": "Fresh cut impulse rose", "code": "10302139" },
      { "name": "Fresh cut indian femma rose", "code": "10302140" },
      { "name": "Fresh cut indian sunset rose", "code": "10302141" },
      { "name": "Fresh cut karusso rose", "code": "10302142" },
      { "name": "Fresh cut kerio rose", "code": "10302143" },
      { "name": "Fresh cut kiki rose", "code": "10302144" },
      { "name": "Fresh cut latin circus rose", "code": "10302145" },
      { "name": "Fresh cut leonisa rose", "code": "10302146" },
      { "name": "Fresh cut lipstick rose", "code": "10302147" },
      { "name": "Fresh cut lobita rose", "code": "10302148" },
      { "name": "Fresh cut luca rose", "code": "10302149" },
      { "name": "Fresh cut manitou rose", "code": "10302150" },
      { "name": "Fresh cut mariana rose", "code": "10302151" },
      { "name": "Fresh cut marjan or pk sensation rose", "code": "10302152" },
      { "name": "Fresh cut milonga rose", "code": "10302153" },
      { "name": "Fresh cut milva rose", "code": "10302154" },
      { "name": "Fresh cut miracle rose", "code": "10302155" },
      { "name": "Fresh cut mirage rose", "code": "10302156" },
      { "name": "Fresh cut monte carlo rose", "code": "10302157" },
      { "name": "Fresh cut movie star rose", "code": "10302158" },
      { "name": "Fresh cut nikita rose", "code": "10302159" },
      { "name": "Fresh cut orange flame rose", "code": "10302160" },
      { "name": "Fresh cut orange france rose", "code": "10302161" },
      { "name": "Fresh cut orange intuition rose", "code": "10302162" },
      { "name": "Fresh cut orange unique rose", "code": "10302163" },
      { "name": "Fresh cut orangine or orangina rose", "code": "10302164" },
      { "name": "Fresh cut papaya rose", "code": "10302165" },
      { "name": "Fresh cut pareo rose", "code": "10302166" },
      { "name": "Fresh cut peach sherbet rose", "code": "10302167" },
      { "name": "Fresh cut queensday rose", "code": "10302168" },
      { "name": "Fresh cut rosselle rose", "code": "10302169" },
      { "name": "Fresh cut royal circus rose", "code": "10302170" },
      { "name": "Fresh cut sari rose", "code": "10302171" },
      { "name": "Fresh cut sensual rose", "code": "10302172" },
      { "name": "Fresh cut soap rose", "code": "10302173" },
      { "name": "Fresh cut sombrero rose", "code": "10302174" },
      { "name": "Fresh cut spicy rose", "code": "10302175" },
      { "name": "Fresh cut star 2000 rose", "code": "10302176" },
      { "name": "Fresh cut summer versilia rose", "code": "10302177" },
      { "name": "Fresh cut trixx rose", "code": "10302178" },
      { "name": "Fresh cut tropical amazon rose", "code": "10302179" },
      { "name": "Fresh cut utopia rose", "code": "10302180" },
      { "name": "Fresh cut valentine rose", "code": "10302181" },
      { "name": "Fresh cut verano rose", "code": "10302182" },
      { "name": "Fresh cut versilia rose", "code": "10302183" },
      { "name": "Fresh cut voodoo rose", "code": "10302184" },
      { "name": "Fresh cut wow rose", "code": "10302185" },
      { "name": "Fresh cut yabadabadoo rose", "code": "10302186" },
      { "name": "Fresh cut peach rose", "code": "10302200" },
      { "name": "Fresh cut alejandra rose", "code": "10302201" },
      { "name": "Fresh cut azafran rose", "code": "10302202" },
      { "name": "Fresh cut big fun rose", "code": "10302203" },
      { "name": "Fresh cut cabaret rose", "code": "10302204" },
      { "name": "Fresh cut capuccino rose", "code": "10302205" },
      { "name": "Fresh cut carpe diem rose", "code": "10302206" },
      { "name": "Fresh cut cosima rose", "code": "10302207" },
      { "name": "Fresh cut cumbia rose", "code": "10302208" },
      { "name": "Fresh cut dream rose", "code": "10302209" },
      { "name": "Fresh cut epoca rose", "code": "10302210" },
      { "name": "Fresh cut fado rose", "code": "10302211" },
      { "name": "Fresh cut femma rose", "code": "10302212" },
      { "name": "Fresh cut guajira rose", "code": "10302213" },
      { "name": "Fresh cut high and arena rose", "code": "10302214" },
      { "name": "Fresh cut high and dandy rose", "code": "10302215" },
      { "name": "Fresh cut high and lucky rose", "code": "10302216" },
      { "name": "Fresh cut high and peach rose", "code": "10302217" },
      { "name": "Fresh cut imagination rose", "code": "10302218" },
      { "name": "Fresh cut isis rose", "code": "10302219" },
      { "name": "Fresh cut joy or light versilia rose", "code": "10302220" },
      { "name": "Fresh cut juliet ausjameson rose", "code": "10302221" },
      { "name": "Fresh cut la parisienne rose", "code": "10302222" },
      { "name": "Fresh cut la perla rose", "code": "10302223" },
      { "name": "Fresh cut lovita sunblaze rose", "code": "10302224" },
      { "name": "Fresh cut malilena or marilena rose", "code": "10302225" },
      { "name": "Fresh cut monyna rose", "code": "10302226" },
      { "name": "Fresh cut nectarine rose", "code": "10302227" },
      { "name": "Fresh cut oriental curiosa rose", "code": "10302228" },
      { "name": "Fresh cut osiana rose", "code": "10302229" },
      { "name": "Fresh cut peach avalanche rose", "code": "10302230" },
      { "name": "Fresh cut peach deja vu rose", "code": "10302231" },
      { "name": "Fresh cut picanto rose", "code": "10302232" },
      { "name": "Fresh cut prima donna rose", "code": "10302233" },
      { "name": "Fresh cut sheril rose", "code": "10302234" },
      { "name": "Fresh cut sirocco rose", "code": "10302235" },
      { "name": "Fresh cut tamara rose", "code": "10302236" },
      { "name": "Fresh cut taxo rose", "code": "10302237" },
      { "name": "Fresh cut trust rose", "code": "10302238" },
      { "name": "Fresh cut valencia rose", "code": "10302239" },
      { "name": "Fresh cut vinci rose", "code": "10302240" },
      { "name": "Fresh cut wanda rose", "code": "10302241" },
      { "name": "Fresh cut pink rose", "code": "10302300" },
      { "name": "Fresh cut aerobic rose", "code": "10302301" },
      { "name": "Fresh cut after party rose", "code": "10302302" },
      { "name": "Fresh cut amsterdam rose", "code": "10302303" },
      { "name": "Fresh cut aqua rose", "code": "10302304" },
      { "name": "Fresh cut attache rose", "code": "10302305" },
      { "name": "Fresh cut attitude rose", "code": "10302306" },
      { "name": "Fresh cut ballet rose", "code": "10302307" },
      { "name": "Fresh cut belami rose", "code": "10302308" },
      { "name": "Fresh cut bella voo or belle vue rose", "code": "10302309" },
      { "name": "Fresh cut bling bling rose", "code": "10302310" },
      { "name": "Fresh cut blushing akito rose", "code": "10302311" },
      { "name": "Fresh cut brooke rose", "code": "10302312" },
      { "name": "Fresh cut bugatti rose", "code": "10302313" },
      { "name": "Fresh cut cadillac rose", "code": "10302314" },
      { "name": "Fresh cut carnaval rose", "code": "10302315" },
      { "name": "Fresh cut cereza rose", "code": "10302316" },
      { "name": "Fresh cut charming unique rose", "code": "10302317" },
      { "name": "Fresh cut cherry o rose", "code": "10302318" },
      { "name": "Fresh cut ciciolina rose", "code": "10302319" },
      { "name": "Fresh cut classic cezanne rose", "code": "10302320" },
      { "name": "Fresh cut classic duett rose", "code": "10302321" },
      { "name": "Fresh cut cosmiq rose", "code": "10302322" },
      { "name": "Fresh cut dark engagement rose", "code": "10302323" },
      { "name": "Fresh cut daytona rose", "code": "10302324" },
      { "name": "Fresh cut dekora rose", "code": "10302325" },
      { "name": "Fresh cut dolores rose", "code": "10302326" },
      { "name": "Fresh cut eliza rose", "code": "10302327" },
      { "name": "Fresh cut flash baccara rose", "code": "10302328" },
      { "name": "Fresh cut full house rose", "code": "10302329" },
      { "name": "Fresh cut funky rose", "code": "10302330" },
      { "name": "Fresh cut giliane rose", "code": "10302331" },
      { "name": "Fresh cut gran europe rose", "code": "10302332" },
      { "name": "Fresh cut habari rose", "code": "10302333" },
      { "name": "Fresh cut hanseat rose", "code": "10302334" },
      { "name": "Fresh cut high and amazing rose", "code": "10302335" },
      { "name": "Fresh cut high and bonita rose", "code": "10302336" },
      { "name": "Fresh cut high and booming rose", "code": "10302337" },
      { "name": "Fresh cut high and fantasy rose", "code": "10302338" },
      { "name": "Fresh cut high and rich rose", "code": "10302339" },
      { "name": "Fresh cut hot lady rose", "code": "10302340" },
      { "name": "Fresh cut hot princess rose", "code": "10302341" },
      { "name": "Fresh cut inspiration rose", "code": "10302342" },
      { "name": "Fresh cut jeimy rose", "code": "10302343" },
      { "name": "Fresh cut kachita rose", "code": "10302344" },
      { "name": "Fresh cut karen rose", "code": "10302345" },
      { "name": "Fresh cut kenji rose", "code": "10302346" },
      { "name": "Fresh cut kiko rose", "code": "10302347" },
      { "name": "Fresh cut laser rose", "code": "10302348" },
      { "name": "Fresh cut latin duett rose", "code": "10302349" },
      { "name": "Fresh cut latin fever rose", "code": "10302350" },
      { "name": "Fresh cut lifestyle rose", "code": "10302351" },
      { "name": "Fresh cut light orlando rose", "code": "10302352" },
      { "name": "Fresh cut lovely dreams rose", "code": "10302353" },
      { "name": "Fresh cut loyalty rose", "code": "10302354" },
      { "name": "Fresh cut malibu rose", "code": "10302355" },
      { "name": "Fresh cut mata-hari rose", "code": "10302356" },
      { "name": "Fresh cut memphis rose", "code": "10302357" },
      { "name": "Fresh cut mi amor rose", "code": "10302358" },
      { "name": "Fresh cut miami rose", "code": "10302359" },
      { "name": "Fresh cut michelle rose", "code": "10302360" },
      { "name": "Fresh cut mikaela rose", "code": "10302361" },
      { "name": "Fresh cut orchestra rose", "code": "10302362" },
      { "name": "Fresh cut orlando rose", "code": "10302363" },
      { "name": "Fresh cut osadia rose", "code": "10302364" },
      { "name": "Fresh cut paeonia freelander rose", "code": "10302365" },
      { "name": "Fresh cut paula rose", "code": "10302366" },
      { "name": "Fresh cut pavarotti rose", "code": "10302367" },
      { "name": "Fresh cut pink intuition rose", "code": "10302368" },
      { "name": "Fresh cut poison rose", "code": "10302369" },
      { "name": "Fresh cut princess rose", "code": "10302370" },
      { "name": "Fresh cut queen mary rose", "code": "10302371" },
      { "name": "Fresh cut raphaela rose", "code": "10302372" },
      { "name": "Fresh cut raspberry ice rose", "code": "10302373" },
      { "name": "Fresh cut ravel rose", "code": "10302374" },
      { "name": "Fresh cut riviera rose", "code": "10302375" },
      { "name": "Fresh cut sade rose", "code": "10302376" },
      { "name": "Fresh cut sashimi rose", "code": "10302377" },
      { "name": "Fresh cut shanya rose", "code": "10302378" },
      { "name": "Fresh cut shocking versilia rose", "code": "10302379" },
      { "name": "Fresh cut solitaire rose", "code": "10302380" },
      { "name": "Fresh cut something different rose", "code": "10302381" },
      { "name": "Fresh cut splendid renate rose", "code": "10302382" },
      { "name": "Fresh cut star rose", "code": "10302383" },
      { "name": "Fresh cut sweet candia rose", "code": "10302384" },
      { "name": "Fresh cut sweet moments rose", "code": "10302385" },
      { "name": "Fresh cut sweet unique rose", "code": "10302386" },
      { "name": "Fresh cut taboo rose", "code": "10302387" },
      { "name": "Fresh cut timona rose", "code": "10302388" },
      { "name": "Fresh cut topaz rose", "code": "10302389" },
      { "name": "Fresh cut vogue rose", "code": "10302390" },
      { "name": "Fresh cut voila rose", "code": "10302391" },
      { "name": "Fresh cut wild one rose", "code": "10302392" },
      { "name": "Fresh cut yves piaget rose", "code": "10302393" },
      { "name": "Fresh cut red or burgundy rose", "code": "10302400" },
      { "name": "Fresh cut african dawn rose", "code": "10302401" },
      { "name": "Fresh cut amada rose", "code": "10302402" },
      { "name": "Fresh cut black baccara rose", "code": "10302403" },
      { "name": "Fresh cut black beauty rose", "code": "10302404" },
      {
        "name": "Fresh cut black finess or black magic rose",
        "code": "10302405"
      },
      { "name": "Fresh cut black magic rose", "code": "10302406" },
      { "name": "Fresh cut bohemian or pasarela rose", "code": "10302407" },
      { "name": "Fresh cut breathless rose", "code": "10302408" },
      { "name": "Fresh cut caballero rose", "code": "10302409" },
      { "name": "Fresh cut carrera rose", "code": "10302410" },
      { "name": "Fresh cut charlene rose", "code": "10302411" },
      { "name": "Fresh cut charlotte rose", "code": "10302412" },
      { "name": "Fresh cut cherry lady rose", "code": "10302413" },
      { "name": "Fresh cut cherry love rose", "code": "10302414" },
      { "name": "Fresh cut classy rose", "code": "10302415" },
      { "name": "Fresh cut colorado velvet rose", "code": "10302416" },
      { "name": "Fresh cut corazon rose", "code": "10302417" },
      { "name": "Fresh cut corrida rose", "code": "10302418" },
      { "name": "Fresh cut dynamite rose", "code": "10302419" },
      { "name": "Fresh cut eurored rose", "code": "10302420" },
      { "name": "Fresh cut fashion rose", "code": "10302421" },
      { "name": "Fresh cut fire and ice rose", "code": "10302422" },
      { "name": "Fresh cut first red rose", "code": "10302423" },
      { "name": "Fresh cut forever young rose", "code": "10302424" },
      { "name": "Fresh cut freedom rose", "code": "10302425" },
      { "name": "Fresh cut freestyle rose", "code": "10302426" },
      { "name": "Fresh cut friendship rose", "code": "10302427" },
      { "name": "Fresh cut gospel rose", "code": "10302428" },
      { "name": "Fresh cut graffity rose", "code": "10302429" },
      { "name": "Fresh cut grand gala rose", "code": "10302430" },
      { "name": "Fresh cut grand prix rose", "code": "10302431" },
      { "name": "Fresh cut grande classe rose", "code": "10302432" },
      { "name": "Fresh cut hearts rose", "code": "10302433" },
      { "name": "Fresh cut heat rose", "code": "10302434" },
      { "name": "Fresh cut hocus pocus rose", "code": "10302435" },
      { "name": "Fresh cut lady in red rose", "code": "10302436" },
      { "name": "Fresh cut latin lady rose", "code": "10302437" },
      { "name": "Fresh cut legend rose", "code": "10302438" },
      { "name": "Fresh cut lulu rose", "code": "10302439" },
      { "name": "Fresh cut luna rossa rose", "code": "10302440" },
      { "name": "Fresh cut luxor rose", "code": "10302441" },
      { "name": "Fresh cut madame delbard or carola rose", "code": "10302442" },
      { "name": "Fresh cut miss paris rose", "code": "10302443" },
      { "name": "Fresh cut nicole rose", "code": "10302444" },
      { "name": "Fresh cut night fever rose", "code": "10302445" },
      { "name": "Fresh cut obsession rose", "code": "10302446" },
      { "name": "Fresh cut opium rose", "code": "10302447" },
      { "name": "Fresh cut paz rose", "code": "10302448" },
      { "name": "Fresh cut preference rose", "code": "10302449" },
      { "name": "Fresh cut red berlin rose", "code": "10302450" },
      { "name": "Fresh cut red bull rose", "code": "10302451" },
      { "name": "Fresh cut red calypso rose", "code": "10302452" },
      { "name": "Fresh cut red diamond rose", "code": "10302453" },
      { "name": "Fresh cut red fantasy rose", "code": "10302454" },
      { "name": "Fresh cut red france rose", "code": "10302455" },
      { "name": "Fresh cut red intuition rose", "code": "10302456" },
      { "name": "Fresh cut red jewel rose", "code": "10302457" },
      { "name": "Fresh cut red magic rose", "code": "10302458" },
      { "name": "Fresh cut red one rose", "code": "10302459" },
      { "name": "Fresh cut red paris rose", "code": "10302460" },
      { "name": "Fresh cut red princess rose", "code": "10302461" },
      { "name": "Fresh cut red sensation or colorad rose", "code": "10302462" },
      { "name": "Fresh cut red unique rose", "code": "10302463" },
      { "name": "Fresh cut rockefeller rose", "code": "10302464" },
      { "name": "Fresh cut romeo rose", "code": "10302465" },
      { "name": "Fresh cut rouge baiser rose", "code": "10302466" },
      { "name": "Fresh cut roulette rose", "code": "10302467" },
      { "name": "Fresh cut royal massai rose", "code": "10302468" },
      { "name": "Fresh cut royal red rose", "code": "10302469" },
      { "name": "Fresh cut samurai rose", "code": "10302470" },
      { "name": "Fresh cut sexy red rose", "code": "10302471" },
      { "name": "Fresh cut starfire rose", "code": "10302472" },
      { "name": "Fresh cut tango rose", "code": "10302473" },
      { "name": "Fresh cut tiger tail rose", "code": "10302474" },
      { "name": "Fresh cut tinto rose", "code": "10302475" },
      { "name": "Fresh cut top secret rose", "code": "10302476" },
      { "name": "Fresh cut vital rose", "code": "10302477" },
      { "name": "Fresh cut wisdom rose", "code": "10302478" },
      { "name": "Fresh cut xantia rose", "code": "10302479" },
      { "name": "Fresh cut xcite rose", "code": "10302480" },
      { "name": "Fresh cut sweetheart rose", "code": "10302500" },
      { "name": "Fresh cut burgundy sweetheart rose", "code": "10302501" },
      { "name": "Fresh cut cream sweetheart rose", "code": "10302502" },
      { "name": "Fresh cut hot pink sweetheart rose", "code": "10302503" },
      { "name": "Fresh cut lavender sweetheart rose", "code": "10302504" },
      { "name": "Fresh cut light pink sweetheart rose", "code": "10302505" },
      { "name": "Fresh cut orange sweetheart rose", "code": "10302506" },
      { "name": "Fresh cut peach sweetheart rose", "code": "10302507" },
      { "name": "Fresh cut red sweetheart rose", "code": "10302508" },
      { "name": "Fresh cut white sweetheart rose", "code": "10302509" },
      { "name": "Fresh cut yellow sweetheart rose", "code": "10302510" },
      { "name": "Fresh cut white rose", "code": "10302600" },
      { "name": "Fresh cut absolut rose", "code": "10302601" },
      { "name": "Fresh cut aida rose", "code": "10302602" },
      { "name": "Fresh cut akito rose", "code": "10302603" },
      { "name": "Fresh cut amelia rose", "code": "10302604" },
      { "name": "Fresh cut anastasia rose", "code": "10302605" },
      { "name": "Fresh cut andean crystal rose", "code": "10302606" },
      { "name": "Fresh cut angel rose", "code": "10302607" },
      { "name": "Fresh cut annemarie rose", "code": "10302608" },
      { "name": "Fresh cut avalanche rose", "code": "10302609" },
      { "name": "Fresh cut bianca rose", "code": "10302610" },
      { "name": "Fresh cut blizzard rose", "code": "10302611" },
      { "name": "Fresh cut bridal akito rose", "code": "10302612" },
      { "name": "Fresh cut domenica rose", "code": "10302613" },
      { "name": "Fresh cut escimo rose", "code": "10302614" },
      { "name": "Fresh cut farfalla rose", "code": "10302615" },
      { "name": "Fresh cut high and peace rose", "code": "10302616" },
      { "name": "Fresh cut high and pure rose", "code": "10302617" },
      { "name": "Fresh cut inocencia or innocenti rose", "code": "10302618" },
      { "name": "Fresh cut ivory rose", "code": "10302619" },
      { "name": "Fresh cut mondial rose", "code": "10302620" },
      { "name": "Fresh cut mount everest rose", "code": "10302621" },
      { "name": "Fresh cut nova zembla rose", "code": "10302622" },
      { "name": "Fresh cut patience or auspastor rose", "code": "10302623" },
      { "name": "Fresh cut polar star rose", "code": "10302624" },
      { "name": "Fresh cut polo rose", "code": "10302625" },
      { "name": "Fresh cut proud rose", "code": "10302626" },
      { "name": "Fresh cut snowy jewel rose", "code": "10302627" },
      { "name": "Fresh cut tibet rose", "code": "10302628" },
      { "name": "Fresh cut tineke rose", "code": "10302629" },
      { "name": "Fresh cut vitality rose", "code": "10302630" },
      { "name": "Fresh cut white cadillac rose", "code": "10302631" },
      { "name": "Fresh cut white dove rose", "code": "10302632" },
      { "name": "Fresh cut yellow rose", "code": "10302700" },
      { "name": "Fresh cut aalsmeer gold rose", "code": "10302701" },
      { "name": "Fresh cut alina rose", "code": "10302702" },
      { "name": "Fresh cut ambiance rose", "code": "10302703" },
      { "name": "Fresh cut aquarel rose", "code": "10302704" },
      { "name": "Fresh cut autumn dream rose", "code": "10302705" },
      { "name": "Fresh cut brasil rose", "code": "10302706" },
      { "name": "Fresh cut candle light rose", "code": "10302707" },
      { "name": "Fresh cut cantata or cantate rose", "code": "10302708" },
      { "name": "Fresh cut capriccio rose", "code": "10302709" },
      { "name": "Fresh cut caribbean rose", "code": "10302710" },
      { "name": "Fresh cut circus rose", "code": "10302711" },
      { "name": "Fresh cut citran rose", "code": "10302712" },
      { "name": "Fresh cut concorde rose", "code": "10302713" },
      { "name": "Fresh cut conga rose", "code": "10302714" },
      { "name": "Fresh cut deja vu rose", "code": "10302715" },
      { "name": "Fresh cut desire rose", "code": "10302716" },
      { "name": "Fresh cut donia sol rose", "code": "10302717" },
      { "name": "Fresh cut dueto rose", "code": "10302718" },
      { "name": "Fresh cut erin rose", "code": "10302719" },
      { "name": "Fresh cut exotic curiosa rose", "code": "10302720" },
      { "name": "Fresh cut feria rose", "code": "10302721" },
      { "name": "Fresh cut fire bird rose", "code": "10302722" },
      { "name": "Fresh cut florida rose", "code": "10302723" },
      { "name": "Fresh cut friendly rose", "code": "10302724" },
      { "name": "Fresh cut gallinda rose", "code": "10302725" },
      { "name": "Fresh cut geisha rose", "code": "10302726" },
      { "name": "Fresh cut gelbe rose", "code": "10302727" },
      { "name": "Fresh cut gelosia or yellow flame rose", "code": "10302728" },
      { "name": "Fresh cut gold rush rose", "code": "10302729" },
      { "name": "Fresh cut gold star rose", "code": "10302730" },
      { "name": "Fresh cut gold strike rose", "code": "10302731" },
      { "name": "Fresh cut golda rose", "code": "10302732" },
      { "name": "Fresh cut golden fashion rose", "code": "10302733" },
      { "name": "Fresh cut golden gate rose", "code": "10302734" },
      { "name": "Fresh cut gran dorado rose", "code": "10302735" },
      { "name": "Fresh cut helio rose", "code": "10302736" },
      { "name": "Fresh cut high and exotic rose", "code": "10302737" },
      { "name": "Fresh cut high and yellow flame rose", "code": "10302738" },
      { "name": "Fresh cut high and yellow magic rose", "code": "10302739" },
      { "name": "Fresh cut high society rose", "code": "10302740" },
      { "name": "Fresh cut hummer rose", "code": "10302741" },
      { "name": "Fresh cut idole or elle rose", "code": "10302742" },
      { "name": "Fresh cut inti rose", "code": "10302743" },
      { "name": "Fresh cut jet set rose", "code": "10302744" },
      { "name": "Fresh cut judy rose", "code": "10302745" },
      { "name": "Fresh cut jupiter rose", "code": "10302746" },
      { "name": "Fresh cut konfetti rose", "code": "10302747" },
      { "name": "Fresh cut kyara or kira rose", "code": "10302748" },
      { "name": "Fresh cut latin beauty rose", "code": "10302749" },
      { "name": "Fresh cut latin spirit rose", "code": "10302750" },
      { "name": "Fresh cut latina rose", "code": "10302751" },
      { "name": "Fresh cut lina rose", "code": "10302752" },
      { "name": "Fresh cut lindsey rose", "code": "10302753" },
      { "name": "Fresh cut male rose", "code": "10302754" },
      { "name": "Fresh cut marie claire rose", "code": "10302755" },
      { "name": "Fresh cut marisa rose", "code": "10302756" },
      { "name": "Fresh cut matchball rose", "code": "10302757" },
      { "name": "Fresh cut melon rose", "code": "10302758" },
      { "name": "Fresh cut mohana rose", "code": "10302759" },
      { "name": "Fresh cut okie dokie rose", "code": "10302760" },
      { "name": "Fresh cut pailine rose", "code": "10302761" },
      { "name": "Fresh cut parrot rose", "code": "10302762" },
      { "name": "Fresh cut rio d oro rose", "code": "10302763" },
      { "name": "Fresh cut salami rose", "code": "10302764" },
      { "name": "Fresh cut santa fe rose", "code": "10302765" },
      { "name": "Fresh cut skyline rose", "code": "10302766" },
      { "name": "Fresh cut sonrisa rose", "code": "10302767" },
      { "name": "Fresh cut star ambiance rose", "code": "10302768" },
      { "name": "Fresh cut starburst rose", "code": "10302769" },
      { "name": "Fresh cut sun king rose", "code": "10302770" },
      { "name": "Fresh cut sunmaster rose", "code": "10302771" },
      { "name": "Fresh cut sunny milva rose", "code": "10302772" },
      { "name": "Fresh cut sushi rose", "code": "10302773" },
      { "name": "Fresh cut tabasco rose", "code": "10302774" },
      { "name": "Fresh cut tara rose", "code": "10302775" },
      { "name": "Fresh cut tresor 2000 rose", "code": "10302776" },
      { "name": "Fresh cut ooty rose", "code": "10302777" },
      { "name": "Fresh cut yellow coral rose", "code": "10302778" },
      { "name": "Fresh cut yellow finess rose", "code": "10302779" },
      { "name": "Fresh cut yellow submarine rose", "code": "10302780" },
      { "name": "Fresh cut yellow sunset rose", "code": "10302781" },
      { "name": "Fresh cut yellow timeless rose", "code": "10302782" },
      { "name": "Fresh cut spray roses", "code": "10302800" },
      { "name": "Fresh cut alegria spray rose", "code": "10302801" },
      { "name": "Fresh cut andrea follies spray rose", "code": "10302802" },
      { "name": "Fresh cut antara follies spray rose", "code": "10302803" },
      { "name": "Fresh cut arrow follies spray rose", "code": "10302804" },
      { "name": "Fresh cut babe spray rose", "code": "10302805" },
      { "name": "Fresh cut bellina collection spray rose", "code": "10302806" },
      { "name": "Fresh cut blue moon spray rose", "code": "10302807" },
      { "name": "Fresh cut chablis spray rose", "code": "10302808" },
      { "name": "Fresh cut cherry follies spray rose", "code": "10302809" },
      { "name": "Fresh cut chess spray rose", "code": "10302810" },
      { "name": "Fresh cut classic lydia spray rose", "code": "10302811" },
      { "name": "Fresh cut cream gracia spray rose", "code": "10302812" },
      { "name": "Fresh cut cream lydia spray rose", "code": "10302813" },
      { "name": "Fresh cut cream sensation spray rose", "code": "10302814" },
      { "name": "Fresh cut cremita spray rose", "code": "10302815" },
      { "name": "Fresh cut diablo spray rose", "code": "10302816" },
      { "name": "Fresh cut electra spray rose", "code": "10302817" },
      { "name": "Fresh cut fire king spray rose", "code": "10302818" },
      { "name": "Fresh cut fleur spray rose", "code": "10302819" },
      { "name": "Fresh cut girlie follies spray rose", "code": "10302820" },
      { "name": "Fresh cut giselle follies spray rose", "code": "10302821" },
      { "name": "Fresh cut golden collection spray rose", "code": "10302822" },
      { "name": "Fresh cut golden mimi spray rose", "code": "10302823" },
      { "name": "Fresh cut gracia spray rose", "code": "10302824" },
      { "name": "Fresh cut hot majolica spray rose", "code": "10302825" },
      { "name": "Fresh cut hot pink follies spray rose", "code": "10302826" },
      { "name": "Fresh cut ilse spray rose", "code": "10302827" },
      { "name": "Fresh cut jelena spray rose", "code": "10302828" },
      { "name": "Fresh cut laminuette spray rose", "code": "10302829" },
      { "name": "Fresh cut lavender follies spray rose", "code": "10302830" },
      { "name": "Fresh cut limoncello spray rose", "code": "10302831" },
      { "name": "Fresh cut little silver spray rose", "code": "10302832" },
      { "name": "Fresh cut lovely lydia spray rose", "code": "10302833" },
      { "name": "Fresh cut lucy spray rose", "code": "10302834" },
      { "name": "Fresh cut lydia spray rose", "code": "10302835" },
      { "name": "Fresh cut macarena spray rose", "code": "10302836" },
      { "name": "Fresh cut magic sensation spray rose", "code": "10302837" },
      { "name": "Fresh cut majolica spray rose", "code": "10302838" },
      { "name": "Fresh cut mambo number 5 spray rose", "code": "10302839" },
      { "name": "Fresh cut mambo spray rose", "code": "10302840" },
      { "name": "Fresh cut marlene spray rose", "code": "10302841" },
      { "name": "Fresh cut mimi eden spray rose", "code": "10302842" },
      { "name": "Fresh cut minou spray rose", "code": "10302843" },
      { "name": "Fresh cut nikita spray rose", "code": "10302844" },
      { "name": "Fresh cut novel collection spray rose", "code": "10302845" },
      { "name": "Fresh cut orange success spray rose", "code": "10302846" },
      { "name": "Fresh cut pepita spray rose", "code": "10302847" },
      { "name": "Fresh cut pink flash spray rose", "code": "10302848" },
      { "name": "Fresh cut pink sensation spray rose", "code": "10302849" },
      { "name": "Fresh cut porcelina spray rose", "code": "10302850" },
      { "name": "Fresh cut princess spray rose", "code": "10302851" },
      { "name": "Fresh cut purple mikado spray rose", "code": "10302852" },
      { "name": "Fresh cut red angel spray rose", "code": "10302853" },
      { "name": "Fresh cut red collection spray rose", "code": "10302854" },
      { "name": "Fresh cut red hero spray rose", "code": "10302855" },
      { "name": "Fresh cut red mikado spray rose", "code": "10302856" },
      { "name": "Fresh cut red vision spray rose", "code": "10302857" },
      { "name": "Fresh cut ritmo spray rose", "code": "10302858" },
      {
        "name": "Fresh cut romance mikado or eva spray rose",
        "code": "10302859"
      },
      { "name": "Fresh cut romantica follies spray rose", "code": "10302860" },
      { "name": "Fresh cut rubicon spray rose", "code": "10302861" },
      { "name": "Fresh cut rumba spray rose", "code": "10302862" },
      { "name": "Fresh cut salsa spray rose", "code": "10302863" },
      { "name": "Fresh cut sangrita spray rose", "code": "10302864" },
      { "name": "Fresh cut santa barbara spray rose", "code": "10302865" },
      { "name": "Fresh cut sashaba spray rose", "code": "10302866" },
      { "name": "Fresh cut scarlett spray rose", "code": "10302867" },
      { "name": "Fresh cut seline spray rose", "code": "10302868" },
      { "name": "Fresh cut sensation spray rose", "code": "10302869" },
      { "name": "Fresh cut silver collection spray rose", "code": "10302870" },
      { "name": "Fresh cut silver sensation spray rose", "code": "10302871" },
      { "name": "Fresh cut snowdance spray rose", "code": "10302872" },
      { "name": "Fresh cut snowflake spray rose", "code": "10302873" },
      { "name": "Fresh cut suncity spray rose", "code": "10302874" },
      { "name": "Fresh cut super nova spray rose", "code": "10302875" },
      { "name": "Fresh cut sweet sensation spray rose", "code": "10302876" },
      { "name": "Fresh cut taifun or typhoon spray rose", "code": "10302877" },
      { "name": "Fresh cut tamango spray rose", "code": "10302878" },
      { "name": "Fresh cut tanger follies spray rose", "code": "10302879" },
      { "name": "Fresh cut tiara spray rose", "code": "10302880" },
      { "name": "Fresh cut tiramisu spray rose", "code": "10302881" },
      { "name": "Fresh cut twinkle bride spray rose", "code": "10302882" },
      { "name": "Fresh cut viviane spray rose", "code": "10302883" },
      { "name": "Fresh cut white majolica spray rose", "code": "10302884" },
      { "name": "Fresh cut white mikado spray rose", "code": "10302885" },
      { "name": "Fresh cut xentina spray rose", "code": "10302886" },
      { "name": "Fresh cut yellow babe spray rose", "code": "10302887" },
      { "name": "Fresh cut yellow follies spray rose", "code": "10302888" },
      {
        "name": "Fresh cut blooms of high species or variety count flowers",
        "code": "10310000"
      },
      { "name": "Fresh cut anthuriums", "code": "10311500" },
      { "name": "Fresh cut chocolate anthurium", "code": "10311501" },
      { "name": "Fresh cut dark red anthurium", "code": "10311502" },
      { "name": "Fresh cut green anthurium", "code": "10311503" },
      { "name": "Fresh cut hot pink anthurium", "code": "10311504" },
      { "name": "Fresh cut mickey mouse anthurium", "code": "10311505" },
      {
        "name": "Fresh cut obake green and white anthurium",
        "code": "10311506"
      },
      { "name": "Fresh cut obake red and green anthurium", "code": "10311507" },
      { "name": "Fresh cut orange anthurium", "code": "10311508" },
      { "name": "Fresh cut peach anthurium", "code": "10311509" },
      { "name": "Fresh cut picasso or speckled anthurium", "code": "10311510" },
      { "name": "Fresh cut red anthurium", "code": "10311511" },
      { "name": "Fresh cut splash anthurium", "code": "10311512" },
      { "name": "Fresh cut tropic fire anthurium", "code": "10311513" },
      { "name": "Fresh cut tulip green anthurium", "code": "10311514" },
      { "name": "Fresh cut tulip pink anthurium", "code": "10311515" },
      { "name": "Fresh cut tulip purple anthurium", "code": "10311516" },
      { "name": "Fresh cut tulip red anthurium", "code": "10311517" },
      { "name": "Fresh cut white anthurium", "code": "10311518" },
      { "name": "Fresh cut wild thing anthurium", "code": "10311519" },
      { "name": "Fresh cut alliums", "code": "10311600" },
      { "name": "Fresh cut ambassador allium", "code": "10311601" },
      { "name": "Fresh cut ampeloprasum allium", "code": "10311602" },
      { "name": "Fresh cut bullit or drumstick allium", "code": "10311603" },
      { "name": "Fresh cut christophii allium", "code": "10311604" },
      { "name": "Fresh cut cowanii spray white allium", "code": "10311605" },
      { "name": "Fresh cut giant allium", "code": "10311606" },
      { "name": "Fresh cut gladiator allium", "code": "10311607" },
      { "name": "Fresh cut globemaster allium", "code": "10311608" },
      { "name": "Fresh cut golfball white allium", "code": "10311609" },
      { "name": "Fresh cut hair allium", "code": "10311610" },
      { "name": "Fresh cut pink giant allium", "code": "10311611" },
      { "name": "Fresh cut purple sensation allium", "code": "10311612" },
      { "name": "Fresh cut sicilum hanging allium", "code": "10311613" },
      { "name": "Fresh cut spider schubertii allium", "code": "10311614" },
      { "name": "Fresh cut spray moly allium", "code": "10311615" },
      { "name": "Fresh cut spray roseum allium", "code": "10311616" },
      { "name": "Fresh cut tuberosum allium", "code": "10311617" },
      { "name": "Fresh cut unifolium or spray allium", "code": "10311618" },
      { "name": "Fresh cut white mount everest allium", "code": "10311619" },
      { "name": "Fresh cut alstroemerias", "code": "10311700" },
      { "name": "Fresh cut agropoli alstroemeria", "code": "10311701" },
      { "name": "Fresh cut bourgogne alstroemeria", "code": "10311702" },
      { "name": "Fresh cut cairo alstroemeria", "code": "10311703" },
      { "name": "Fresh cut charmes alstroemeria", "code": "10311704" },
      { "name": "Fresh cut cherry bay alstroemeria", "code": "10311705" },
      { "name": "Fresh cut cherry white alstroemeria", "code": "10311706" },
      { "name": "Fresh cut dame blanche alstroemeria", "code": "10311707" },
      { "name": "Fresh cut diamond alstroemeria", "code": "10311708" },
      { "name": "Fresh cut gran canaria alstroemeria", "code": "10311709" },
      { "name": "Fresh cut harlekijn alstroemeria", "code": "10311710" },
      { "name": "Fresh cut indian summer alstroemeria", "code": "10311711" },
      { "name": "Fresh cut jamaica alstroemeria", "code": "10311712" },
      { "name": "Fresh cut macondo alstroemeria", "code": "10311713" },
      { "name": "Fresh cut mistique alstroemeria", "code": "10311714" },
      { "name": "Fresh cut my fair alstroemeria", "code": "10311715" },
      { "name": "Fresh cut new cairo alstroemeria", "code": "10311716" },
      { "name": "Fresh cut nice alstroemeria", "code": "10311717" },
      { "name": "Fresh cut orange bowl alstroemeria", "code": "10311718" },
      { "name": "Fresh cut orange queens alstroemeria", "code": "10311719" },
      { "name": "Fresh cut orange sun alstroemeria", "code": "10311720" },
      { "name": "Fresh cut paris alstroemeria", "code": "10311721" },
      { "name": "Fresh cut picasso alstroemeria", "code": "10311722" },
      { "name": "Fresh cut pink panther alstroemeria", "code": "10311723" },
      { "name": "Fresh cut prima donna alstroemeria", "code": "10311724" },
      { "name": "Fresh cut red silhouette alstroemeria", "code": "10311725" },
      { "name": "Fresh cut sacha alstroemeria", "code": "10311726" },
      { "name": "Fresh cut salmon alstroemeria", "code": "10311727" },
      { "name": "Fresh cut santiago alstroemeria", "code": "10311728" },
      { "name": "Fresh cut senna alstroemeria", "code": "10311729" },
      { "name": "Fresh cut snowball alstroemeria", "code": "10311730" },
      { "name": "Fresh cut sublime alstroemeria", "code": "10311731" },
      { "name": "Fresh cut tropicana alstroemeria", "code": "10311732" },
      { "name": "Fresh cut virginia alstroemeria", "code": "10311733" },
      { "name": "Fresh cut white alstroemeria", "code": "10311734" },
      { "name": "Fresh cut amaranthuses", "code": "10311800" },
      { "name": "Fresh cut hanging green amaranthus", "code": "10311801" },
      { "name": "Fresh cut hanging red amaranthus", "code": "10311802" },
      { "name": "Fresh cut upright bronze amaranthus", "code": "10311803" },
      { "name": "Fresh cut upright green amaranthus", "code": "10311804" },
      { "name": "Fresh cut upright red amaranthus", "code": "10311805" },
      { "name": "Fresh cut amaryllises", "code": "10311900" },
      { "name": "Fresh cut naranja amaryllis", "code": "10311901" },
      { "name": "Fresh cut orange nagano amaryllis", "code": "10311902" },
      { "name": "Fresh cut pygmee mini amaryllis", "code": "10311903" },
      { "name": "Fresh cut red lion amaryllis", "code": "10311904" },
      { "name": "Fresh cut rilona amaryllis", "code": "10311905" },
      { "name": "Fresh cut royal velvet amaryllis", "code": "10311906" },
      { "name": "Fresh cut sonatini orange amaryllis", "code": "10311907" },
      { "name": "Fresh cut sonatini red amaryllis", "code": "10311908" },
      { "name": "Fresh cut tango amaryllis", "code": "10311909" },
      { "name": "Fresh cut tinto night amaryllis", "code": "10311910" },
      { "name": "Fresh cut anemone", "code": "10312000" },
      { "name": "Fresh cut aubergine anemone", "code": "10312001" },
      { "name": "Fresh cut black anemone", "code": "10312002" },
      { "name": "Fresh cut blue anemone", "code": "10312003" },
      { "name": "Fresh cut cerise anemone", "code": "10312004" },
      { "name": "Fresh cut coronaria anemone", "code": "10312005" },
      { "name": "Fresh cut hot pink anemone", "code": "10312006" },
      { "name": "Fresh cut light pink anemone", "code": "10312007" },
      { "name": "Fresh cut pink anemone", "code": "10312008" },
      { "name": "Fresh cut purple anemone", "code": "10312009" },
      { "name": "Fresh cut red anemone", "code": "10312010" },
      { "name": "Fresh cut white anemone", "code": "10312011" },
      { "name": "Fresh cut asclepias", "code": "10312100" },
      { "name": "Fresh cut lavender asclepia", "code": "10312101" },
      { "name": "Fresh cut moby dick asclepia", "code": "10312102" },
      { "name": "Fresh cut tuberosa asclepia", "code": "10312103" },
      { "name": "Fresh cut white asclepia", "code": "10312104" },
      { "name": "Fresh cut asters", "code": "10312200" },
      { "name": "Fresh cut beauty aster", "code": "10312201" },
      { "name": "Fresh cut japanese blue aster", "code": "10312202" },
      { "name": "Fresh cut japanese green aster", "code": "10312203" },
      { "name": "Fresh cut japanese hot pink aster", "code": "10312204" },
      { "name": "Fresh cut japanese lavender aster", "code": "10312205" },
      { "name": "Fresh cut japanese light pink aster", "code": "10312206" },
      { "name": "Fresh cut japanese peach aster", "code": "10312207" },
      { "name": "Fresh cut japanese pink aster", "code": "10312208" },
      { "name": "Fresh cut japanese purple aster", "code": "10312209" },
      { "name": "Fresh cut japanese red aster", "code": "10312210" },
      { "name": "Fresh cut japanese spider aster", "code": "10312211" },
      { "name": "Fresh cut japanese white aster", "code": "10312212" },
      { "name": "Fresh cut novi belgii hot pink aster", "code": "10312213" },
      { "name": "Fresh cut novi belgii lavender aster", "code": "10312214" },
      { "name": "Fresh cut novi belgii pink aster", "code": "10312215" },
      { "name": "Fresh cut novi belgii purple aster", "code": "10312216" },
      { "name": "Fresh cut novi belgii white aster", "code": "10312217" },
      { "name": "Fresh cut solidago aster", "code": "10312218" },
      { "name": "Fresh cut spider aster", "code": "10312219" },
      { "name": "Fresh cut berzelia lanuginosas", "code": "10312300" },
      {
        "name": "Fresh cut abrotanoides berzelia lanuginosa",
        "code": "10312301"
      },
      { "name": "Fresh cut fireball berzelia lanuginosa", "code": "10312302" },
      { "name": "Fresh cut galpinii berzelia lanuginosa", "code": "10312303" },
      {
        "name": "Fresh cut galpinii or baubles berzelia lanuginosa",
        "code": "10312304"
      },
      { "name": "Fresh cut squarrosa berzelia lanuginosa", "code": "10312305" },
      { "name": "Fresh cut bouvardias", "code": "10312400" },
      { "name": "Fresh cut hot pink double bouvardia", "code": "10312401" },
      { "name": "Fresh cut light pink bouvardia", "code": "10312402" },
      { "name": "Fresh cut light pink double bouvardia", "code": "10312403" },
      { "name": "Fresh cut red bouvardia", "code": "10312404" },
      { "name": "Fresh cut white bouvardia", "code": "10312405" },
      { "name": "Fresh cut brodiaeas", "code": "10312500" },
      { "name": "Fresh cut congesta brodiaea", "code": "10312501" },
      { "name": "Fresh cut congesta lavender brodiaea", "code": "10312502" },
      { "name": "Fresh cut hyacintha brodiaea", "code": "10312503" },
      { "name": "Fresh cut ida maija brodiaea", "code": "10312504" },
      { "name": "Fresh cut starlight brodiaea", "code": "10312505" },
      { "name": "Fresh cut callas", "code": "10312600" },
      { "name": "Fresh cut green goddess calla", "code": "10312601" },
      { "name": "Fresh cut posey albertville calla", "code": "10312602" },
      { "name": "Fresh cut posey aranal calla", "code": "10312603" },
      { "name": "Fresh cut posey black eyed beauty calla", "code": "10312604" },
      { "name": "Fresh cut posey black star calla", "code": "10312605" },
      { "name": "Fresh cut posey brisbane calla", "code": "10312606" },
      { "name": "Fresh cut posey crystal blush calla", "code": "10312607" },
      { "name": "Fresh cut posey crystal pink calla", "code": "10312608" },
      { "name": "Fresh cut posey crystal white calla", "code": "10312609" },
      {
        "name": "Fresh cut posey dark captain romanc calla",
        "code": "10312610"
      },
      { "name": "Fresh cut posey dark mozart calla", "code": "10312611" },
      { "name": "Fresh cut posey dark naomi calla", "code": "10312612" },
      { "name": "Fresh cut posey deformed calla", "code": "10312613" },
      { "name": "Fresh cut posey dordogne calla", "code": "10312614" },
      { "name": "Fresh cut posey etude calla", "code": "10312615" },
      { "name": "Fresh cut posey farao calla", "code": "10312616" },
      { "name": "Fresh cut posey fire glow calla", "code": "10312617" },
      { "name": "Fresh cut posey florex gold calla", "code": "10312618" },
      { "name": "Fresh cut posey garnet glow calla", "code": "10312619" },
      { "name": "Fresh cut posey hot chocolate calla", "code": "10312620" },
      { "name": "Fresh cut posey lavender improved calla", "code": "10312621" },
      { "name": "Fresh cut posey light cromance calla", "code": "10312622" },
      { "name": "Fresh cut posey little suzy calla", "code": "10312623" },
      { "name": "Fresh cut posey majestic red calla", "code": "10312624" },
      { "name": "Fresh cut posey mango calla", "code": "10312625" },
      { "name": "Fresh cut posey merlot calla", "code": "10312626" },
      { "name": "Fresh cut posey mozart calla", "code": "10312627" },
      { "name": "Fresh cut posey naomi calla", "code": "10312628" },
      { "name": "Fresh cut posey night cap calla", "code": "10312629" },
      { "name": "Fresh cut posey odessa calla", "code": "10312630" },
      { "name": "Fresh cut posey pacific pink calla", "code": "10312631" },
      { "name": "Fresh cut posey passion fruit calla", "code": "10312632" },
      { "name": "Fresh cut posey picasso calla", "code": "10312633" },
      { "name": "Fresh cut posey pillow talk calla", "code": "10312634" },
      { "name": "Fresh cut posey pink persuation calla", "code": "10312635" },
      { "name": "Fresh cut posey pisa calla", "code": "10312636" },
      { "name": "Fresh cut posey pot of calla", "code": "10312637" },
      { "name": "Fresh cut posey red sox calla", "code": "10312638" },
      { "name": "Fresh cut posey rosa calla", "code": "10312639" },
      { "name": "Fresh cut posey ruby light rose calla", "code": "10312640" },
      { "name": "Fresh cut posey samur calla", "code": "10312641" },
      { "name": "Fresh cut posey sapporo calla", "code": "10312642" },
      { "name": "Fresh cut posey schwarzwalder calla", "code": "10312643" },
      { "name": "Fresh cut posey serrada calla", "code": "10312644" },
      { "name": "Fresh cut posey solemio calla", "code": "10312645" },
      { "name": "Fresh cut posey sunrise calla", "code": "10312646" },
      { "name": "Fresh cut posey super mac calla", "code": "10312647" },
      { "name": "Fresh cut posey swan lake calla", "code": "10312648" },
      { "name": "Fresh cut posey vermeer calla", "code": "10312649" },
      { "name": "Fresh cut posey white butterfly calla", "code": "10312650" },
      { "name": "Fresh cut posey yellow calla", "code": "10312651" },
      { "name": "Fresh cut posey yellow mozart calla", "code": "10312652" },
      { "name": "Fresh cut white large calla", "code": "10312653" },
      { "name": "Fresh cut celosias", "code": "10312800" },
      { "name": "Fresh cut cockscomb green celosia", "code": "10312801" },
      { "name": "Fresh cut cockscomb orange celosia", "code": "10312802" },
      { "name": "Fresh cut cockscomb pink celosia", "code": "10312803" },
      { "name": "Fresh cut cockscomb purple celosia", "code": "10312804" },
      { "name": "Fresh cut cockscomb red celosia", "code": "10312805" },
      { "name": "Fresh cut cockscomb yellow celosia", "code": "10312806" },
      { "name": "Fresh cut plume light pink celosia", "code": "10312807" },
      { "name": "Fresh cut plume orange celosia", "code": "10312808" },
      { "name": "Fresh cut plume purple celosia", "code": "10312809" },
      { "name": "Fresh cut plume red celosia", "code": "10312810" },
      { "name": "Fresh cut plume yellow celosia", "code": "10312811" },
      { "name": "Fresh cut wheat pink celosia", "code": "10312812" },
      { "name": "Fresh cut wheat yellow celosia", "code": "10312813" },
      { "name": "Fresh cut daffodils", "code": "10312900" },
      { "name": "Fresh cut dick wilden daffodil", "code": "10312901" },
      { "name": "Fresh cut dutch master daffodil", "code": "10312902" },
      { "name": "Fresh cut ice follies daffodil", "code": "10312903" },
      { "name": "Fresh cut ice king daffodil", "code": "10312904" },
      { "name": "Fresh cut johan strauss daffodil", "code": "10312905" },
      { "name": "Fresh cut yellow carlton daffodil", "code": "10312906" },
      { "name": "Fresh cut dahlias", "code": "10313000" },
      { "name": "Fresh cut bi color dahlia", "code": "10313001" },
      { "name": "Fresh cut hot pink dahlia", "code": "10313002" },
      { "name": "Fresh cut light pink dahlia", "code": "10313003" },
      { "name": "Fresh cut medium pink dahlia", "code": "10313004" },
      { "name": "Fresh cut orange dahlia", "code": "10313005" },
      { "name": "Fresh cut peach dahlia", "code": "10313006" },
      { "name": "Fresh cut purple dahlia", "code": "10313007" },
      { "name": "Fresh cut red dahlia", "code": "10313008" },
      { "name": "Fresh cut white dahlia", "code": "10313009" },
      { "name": "Fresh cut yellow dahlia", "code": "10313010" },
      { "name": "Fresh cut delphiniums", "code": "10313100" },
      { "name": "Fresh cut bella dark blue delphinium", "code": "10313101" },
      { "name": "Fresh cut bella light blue delphinium", "code": "10313102" },
      { "name": "Fresh cut bella white delphinium", "code": "10313103" },
      { "name": "Fresh cut blue shadow delphinium", "code": "10313104" },
      { "name": "Fresh cut hybrid dark blue delphinium", "code": "10313105" },
      { "name": "Fresh cut hybrid light blue delphinium", "code": "10313106" },
      { "name": "Fresh cut hybrid mauve delphinium", "code": "10313107" },
      { "name": "Fresh cut hybrid pink delphinium", "code": "10313108" },
      { "name": "Fresh cut hybrid purple delphinium", "code": "10313109" },
      { "name": "Fresh cut hybrid red delphinium", "code": "10313110" },
      { "name": "Fresh cut hybrid white delphinium", "code": "10313111" },
      { "name": "Fresh cut princess caroline delphinium", "code": "10313112" },
      { "name": "Fresh cut volkerfrieden delphinium", "code": "10313113" },
      { "name": "Fresh cut dianthuses", "code": "10313200" },
      { "name": "Fresh cut chocolate dianthus", "code": "10313201" },
      { "name": "Fresh cut fuchsia dianthus", "code": "10313202" },
      { "name": "Fresh cut green ball dianthus", "code": "10313203" },
      { "name": "Fresh cut hot pink dianthus", "code": "10313204" },
      { "name": "Fresh cut lavender dianthus", "code": "10313205" },
      { "name": "Fresh cut raspberry dianthus", "code": "10313206" },
      { "name": "Fresh cut red dianthus", "code": "10313207" },
      { "name": "Fresh cut rosie pink dianthus", "code": "10313208" },
      { "name": "Fresh cut eremuruses", "code": "10313300" },
      { "name": "Fresh cut deruyter hybrid eremurus", "code": "10313301" },
      { "name": "Fresh cut himalaicus white eremurus", "code": "10313302" },
      { "name": "Fresh cut orange eremurus", "code": "10313303" },
      { "name": "Fresh cut peach eremurus", "code": "10313304" },
      { "name": "Fresh cut yellow eremurus", "code": "10313305" },
      { "name": "Fresh cut ericas", "code": "10313400" },
      { "name": "Fresh cut campunalarus erica", "code": "10313401" },
      { "name": "Fresh cut conica erica", "code": "10313402" },
      { "name": "Fresh cut green ice erica", "code": "10313403" },
      { "name": "Fresh cut pink erica", "code": "10313404" },
      { "name": "Fresh cut prince of whales erica", "code": "10313405" },
      { "name": "Fresh cut euphorbias", "code": "10313500" },
      { "name": "Fresh cut characias euphorbia", "code": "10313501" },
      { "name": "Fresh cut griffithii fireglow euphorbia", "code": "10313502" },
      { "name": "Fresh cut martini euphorbia", "code": "10313503" },
      { "name": "Fresh cut orange euphorbia", "code": "10313504" },
      { "name": "Fresh cut peach euphorbia", "code": "10313505" },
      { "name": "Fresh cut pink euphorbia", "code": "10313506" },
      { "name": "Fresh cut red euphorbia", "code": "10313507" },
      { "name": "Fresh cut white euphorbia", "code": "10313508" },
      { "name": "Fresh cut yellow euphorbia", "code": "10313509" },
      { "name": "Fresh cut yellow spurge euphorbia", "code": "10313510" },
      { "name": "Fresh cut freesias", "code": "10313600" },
      { "name": "Fresh cut cream freesia", "code": "10313601" },
      { "name": "Fresh cut double white freesia", "code": "10313602" },
      { "name": "Fresh cut double yellow freesia", "code": "10313603" },
      { "name": "Fresh cut hot pink freesia", "code": "10313604" },
      { "name": "Fresh cut lady brunet freesia", "code": "10313605" },
      { "name": "Fresh cut lavender freesia", "code": "10313606" },
      { "name": "Fresh cut medium pink freesia", "code": "10313607" },
      { "name": "Fresh cut orange freesia", "code": "10313608" },
      { "name": "Fresh cut pimpernel freesia", "code": "10313609" },
      { "name": "Fresh cut pink freesia", "code": "10313610" },
      { "name": "Fresh cut purple freesia", "code": "10313611" },
      { "name": "Fresh cut red freesia", "code": "10313612" },
      { "name": "Fresh cut white freesia", "code": "10313613" },
      { "name": "Fresh cut yellow freesia", "code": "10313614" },
      { "name": "Fresh cut fritillarias", "code": "10313700" },
      { "name": "Fresh cut acmopelata fritillaria", "code": "10313701" },
      { "name": "Fresh cut assyriaca fritillaria", "code": "10313702" },
      {
        "name": "Fresh cut assyrica uva vulpis frittilarias",
        "code": "10313703"
      },
      { "name": "Fresh cut elysee fritillaria", "code": "10313704" },
      { "name": "Fresh cut imperialis orange fritillaria", "code": "10313705" },
      { "name": "Fresh cut imperialis yellow fritillaria", "code": "10313706" },
      { "name": "Fresh cut meleagris fritillaria", "code": "10313707" },
      { "name": "Fresh cut michailowski fritillaria", "code": "10313708" },
      { "name": "Fresh cut uva vulpis frittilaria", "code": "10313709" },
      { "name": "Fresh cut genistas", "code": "10313800" },
      { "name": "Fresh cut green genista", "code": "10313801" },
      { "name": "Fresh cut hot pink genista", "code": "10313802" },
      { "name": "Fresh cut lavender genista", "code": "10313803" },
      { "name": "Fresh cut light pink genista", "code": "10313804" },
      { "name": "Fresh cut peach genista", "code": "10313805" },
      { "name": "Fresh cut purple genista", "code": "10313806" },
      { "name": "Fresh cut white genista", "code": "10313807" },
      { "name": "Fresh cut yellow genista", "code": "10313808" },
      { "name": "Fresh cut gerberas", "code": "10313900" },
      { "name": "Fresh cut cream black center gerbera", "code": "10313901" },
      { "name": "Fresh cut cream gerbera", "code": "10313902" },
      { "name": "Fresh cut gold gerbera", "code": "10313903" },
      { "name": "Fresh cut hot pink gerbera", "code": "10313904" },
      { "name": "Fresh cut light pink gerbera", "code": "10313905" },
      { "name": "Fresh cut magenta gerbera", "code": "10313906" },
      { "name": "Fresh cut mini coral gerbera", "code": "10313907" },
      { "name": "Fresh cut mini fuchsia gerbera", "code": "10313908" },
      { "name": "Fresh cut mini hot pink gerbera", "code": "10313909" },
      {
        "name": "Fresh cut mini light orange black center gerbera",
        "code": "10313910"
      },
      {
        "name": "Fresh cut mini orange black center gerbera",
        "code": "10313911"
      },
      { "name": "Fresh cut mini orange gerbera", "code": "10313912" },
      { "name": "Fresh cut mini red black center gerbera", "code": "10313913" },
      { "name": "Fresh cut mini white gerbera", "code": "10313914" },
      {
        "name": "Fresh cut mini yellow black center gerbera",
        "code": "10313915"
      },
      { "name": "Fresh cut orange black center gerbera", "code": "10313916" },
      { "name": "Fresh cut orange gerbera", "code": "10313917" },
      { "name": "Fresh cut peach black center gerbera", "code": "10313918" },
      { "name": "Fresh cut peach gerbera", "code": "10313919" },
      { "name": "Fresh cut pink black center gerbera", "code": "10313920" },
      { "name": "Fresh cut pink gerbera", "code": "10313921" },
      { "name": "Fresh cut red black center gerbera", "code": "10313922" },
      { "name": "Fresh cut red gerbera", "code": "10313923" },
      { "name": "Fresh cut spider peach gerbera", "code": "10313924" },
      { "name": "Fresh cut spider red gerbera", "code": "10313925" },
      { "name": "Fresh cut terracotta gerbera", "code": "10313926" },
      { "name": "Fresh cut white black center gerbera", "code": "10313927" },
      { "name": "Fresh cut white gerbera", "code": "10313928" },
      { "name": "Fresh cut yellow gerbera", "code": "10313929" },
      { "name": "Fresh cut ginger plants", "code": "10314000" },
      { "name": "Fresh cut indonesian ginger", "code": "10314001" },
      { "name": "Fresh cut jungle king pink ginger", "code": "10314002" },
      { "name": "Fresh cut jungle king red ginger", "code": "10314003" },
      { "name": "Fresh cut pink ginger", "code": "10314004" },
      { "name": "Fresh cut red ginger", "code": "10314005" },
      { "name": "Fresh cut torch ginger", "code": "10314006" },
      { "name": "Fresh cut gladioluses", "code": "10314100" },
      { "name": "Fresh cut burgundy gladiolus", "code": "10314101" },
      { "name": "Fresh cut fuchsia gladiolus", "code": "10314102" },
      { "name": "Fresh cut green gladiolus", "code": "10314103" },
      { "name": "Fresh cut hot pink gladiolus", "code": "10314104" },
      { "name": "Fresh cut light pink gladiolus", "code": "10314105" },
      { "name": "Fresh cut orange gladiolus", "code": "10314106" },
      { "name": "Fresh cut peach gladiolus", "code": "10314107" },
      { "name": "Fresh cut pink medium gladiolus", "code": "10314108" },
      { "name": "Fresh cut purple gladiolus", "code": "10314109" },
      { "name": "Fresh cut red bi color gladiolus", "code": "10314110" },
      { "name": "Fresh cut red gladiolus", "code": "10314111" },
      { "name": "Fresh cut salmon gladiolus", "code": "10314112" },
      { "name": "Fresh cut white gladiolus", "code": "10314113" },
      { "name": "Fresh cut yellow gladiolus", "code": "10314114" },
      { "name": "Fresh cut godetias", "code": "10314200" },
      { "name": "Fresh cut bi color godetia", "code": "10314201" },
      { "name": "Fresh cut fuchsia godetia", "code": "10314202" },
      { "name": "Fresh cut hot pink godetia", "code": "10314203" },
      { "name": "Fresh cut orange godetia", "code": "10314204" },
      { "name": "Fresh cut red godetia", "code": "10314205" },
      { "name": "Fresh cut white godetia", "code": "10314206" },
      { "name": "Fresh cut guzmanias", "code": "10314300" },
      { "name": "Fresh cut lingulata orange guzmania", "code": "10314301" },
      { "name": "Fresh cut lingulata red guzmania", "code": "10314302" },
      { "name": "Fresh cut lingulata white guzmania", "code": "10314303" },
      { "name": "Fresh cut lingulata yellow guzmania", "code": "10314304" },
      { "name": "Fresh cut variegata guzmania", "code": "10314305" },
      { "name": "Fresh cut gypsophilias", "code": "10314400" },
      { "name": "Fresh cut bambino gypsophilia", "code": "10314401" },
      { "name": "Fresh cut million stars gypsophilia", "code": "10314402" },
      { "name": "Fresh cut mirabella gypsophilia", "code": "10314403" },
      { "name": "Fresh cut new love gypsophilia", "code": "10314404" },
      { "name": "Fresh cut orion gypsophilia", "code": "10314405" },
      { "name": "Fresh cut perfecta gypsophilia", "code": "10314406" },
      { "name": "Fresh cut heather", "code": "10314500" },
      { "name": "Fresh cut augustine heather", "code": "10314501" },
      { "name": "Fresh cut erica four sisters heather", "code": "10314502" },
      { "name": "Fresh cut french heather", "code": "10314503" },
      { "name": "Fresh cut green heather", "code": "10314504" },
      { "name": "Fresh cut sterling range white heather", "code": "10314505" },
      { "name": "Fresh cut sunset pink heather", "code": "10314506" },
      { "name": "Fresh cut white heather", "code": "10314507" },
      { "name": "Fresh cut heliconias", "code": "10314600" },
      { "name": "Fresh cut bihai claw heliconia", "code": "10314601" },
      { "name": "Fresh cut bihai flash heliconia", "code": "10314602" },
      { "name": "Fresh cut bihai lobster claw heliconia", "code": "10314603" },
      { "name": "Fresh cut caribea red heliconia", "code": "10314604" },
      { "name": "Fresh cut caribea yellow heliconia", "code": "10314605" },
      { "name": "Fresh cut christmas heliconia", "code": "10314606" },
      { "name": "Fresh cut edge of night heliconia", "code": "10314607" },
      { "name": "Fresh cut green bihai heliconia", "code": "10314608" },
      { "name": "Fresh cut marginata lutea heliconia", "code": "10314609" },
      { "name": "Fresh cut psitt fire opal heliconia", "code": "10314610" },
      { "name": "Fresh cut psittacorum heliconia", "code": "10314611" },
      { "name": "Fresh cut richmond red heliconia", "code": "10314612" },
      { "name": "Fresh cut rostrata heliconia", "code": "10314613" },
      { "name": "Fresh cut sexy pink heliconia", "code": "10314614" },
      { "name": "Fresh cut sexy scarlett heliconia", "code": "10314615" },
      { "name": "Fresh cut shogun heliconia", "code": "10314616" },
      { "name": "Fresh cut small red heliconia", "code": "10314617" },
      { "name": "Fresh cut southern cross heliconia", "code": "10314618" },
      { "name": "Fresh cut wagneriana heliconia", "code": "10314619" },
      { "name": "Fresh cut hyacinths", "code": "10314700" },
      { "name": "Fresh cut bean hyacinths", "code": "10314701" },
      { "name": "Fresh cut apricot hyacinth", "code": "10314702" },
      { "name": "Fresh cut blue hyacinth", "code": "10314703" },
      { "name": "Fresh cut fuchsia hyacinth", "code": "10314704" },
      { "name": "Fresh cut hot pink hyacinth", "code": "10314705" },
      { "name": "Fresh cut lavender hyacinth", "code": "10314706" },
      { "name": "Fresh cut light blue hyacinth", "code": "10314707" },
      { "name": "Fresh cut medium pink hyacinth", "code": "10314708" },
      { "name": "Fresh cut pink hyacinth", "code": "10314709" },
      { "name": "Fresh cut purple star hyacinth", "code": "10314710" },
      { "name": "Fresh cut white hyacinth", "code": "10314711" },
      { "name": "Fresh cut yellow hyacinth", "code": "10314712" },
      { "name": "Fresh cut hydrangeas", "code": "10314800" },
      { "name": "Fresh cut annabelle hydrangea", "code": "10314801" },
      { "name": "Fresh cut antique blue hydrangea", "code": "10314802" },
      {
        "name": "Fresh cut antique blue or green or new zealand hydrangea",
        "code": "10314803"
      },
      { "name": "Fresh cut antique green hydrangea", "code": "10314804" },
      { "name": "Fresh cut antique pink hydrangea", "code": "10314805" },
      {
        "name": "Fresh cut antique purple or new zealand hydrangea",
        "code": "10314806"
      },
      {
        "name": "Fresh cut aubergene or new zealand hydrangea",
        "code": "10314807"
      },
      { "name": "Fresh cut dark blue hydrangea", "code": "10314808" },
      { "name": "Fresh cut dark pink hydrangea", "code": "10314809" },
      { "name": "Fresh cut dark purple hydrangea", "code": "10314810" },
      { "name": "Fresh cut eggbloom hydrangea", "code": "10314811" },
      { "name": "Fresh cut green dyed hydrangea", "code": "10314812" },
      { "name": "Fresh cut green lemon hydrangea", "code": "10314813" },
      { "name": "Fresh cut hot pink hydrangea", "code": "10314814" },
      { "name": "Fresh cut jumbo white hydrangea", "code": "10314815" },
      {
        "name": "Fresh cut lavender or new zealand hydrangea",
        "code": "10314816"
      },
      { "name": "Fresh cut light blue hydrangea", "code": "10314817" },
      { "name": "Fresh cut light pink large hydrangea", "code": "10314818" },
      { "name": "Fresh cut lime green large hydrangea", "code": "10314819" },
      { "name": "Fresh cut mini green hydrangea", "code": "10314820" },
      { "name": "Fresh cut oakleaf hydrangea", "code": "10314821" },
      { "name": "Fresh cut oakleaf snowflake hydrangea", "code": "10314822" },
      { "name": "Fresh cut pink dyed hydrangea", "code": "10314823" },
      { "name": "Fresh cut pink hydrangea", "code": "10314824" },
      {
        "name": "Fresh cut purple or new zealand hydrangea",
        "code": "10314825"
      },
      { "name": "Fresh cut red dyed hydrangea", "code": "10314826" },
      { "name": "Fresh cut shocking blue hydrangea", "code": "10314827" },
      { "name": "Fresh cut tardiva hydrangea", "code": "10314828" },
      { "name": "Fresh cut white hydrangea", "code": "10314829" },
      { "name": "Fresh cut irises", "code": "10314900" },
      { "name": "Fresh cut black bearded iris", "code": "10314901" },
      { "name": "Fresh cut bearded blue iris", "code": "10314902" },
      { "name": "Fresh cut bearded lavender iris", "code": "10314903" },
      { "name": "Fresh cut bearded light blue iris", "code": "10314904" },
      { "name": "Fresh cut bearded purple iris", "code": "10314905" },
      { "name": "Fresh cut bearded red iris", "code": "10314906" },
      { "name": "Fresh cut bearded white iris", "code": "10314907" },
      { "name": "Fresh cut bearded white and purple iris", "code": "10314908" },
      { "name": "Fresh cut bearded yellow iris", "code": "10314909" },
      { "name": "Fresh cut blue elegance iris", "code": "10314910" },
      { "name": "Fresh cut casablanca iris", "code": "10314911" },
      { "name": "Fresh cut golden beau iris", "code": "10314912" },
      { "name": "Fresh cut hildegard iris", "code": "10314913" },
      { "name": "Fresh cut hong kong iris", "code": "10314914" },
      { "name": "Fresh cut ideal iris", "code": "10314915" },
      { "name": "Fresh cut professor blue iris", "code": "10314916" },
      { "name": "Fresh cut purple iris", "code": "10314917" },
      { "name": "Fresh cut spuria iris", "code": "10314918" },
      { "name": "Fresh cut telstar iris", "code": "10314919" },
      { "name": "Fresh cut kangaroo paws", "code": "10315000" },
      { "name": "Fresh cut bi color kangaroo paw", "code": "10315001" },
      { "name": "Fresh cut black kangaroo paw", "code": "10315002" },
      { "name": "Fresh cut green kangaroo paw", "code": "10315003" },
      { "name": "Fresh cut orange kangaroo paw", "code": "10315004" },
      { "name": "Fresh cut pink kangaroo paw", "code": "10315005" },
      { "name": "Fresh cut red kangaroo paw", "code": "10315006" },
      { "name": "Fresh cut yellow kangaroo paw", "code": "10315007" },
      { "name": "Fresh cut larkspurs", "code": "10315100" },
      { "name": "Fresh cut blue cloud larkspur", "code": "10315101" },
      { "name": "Fresh cut dark pink larkspur", "code": "10315102" },
      { "name": "Fresh cut lavender larkspur", "code": "10315103" },
      { "name": "Fresh cut light pink larkspur", "code": "10315104" },
      { "name": "Fresh cut purple larkspur", "code": "10315105" },
      { "name": "Fresh cut white larkspur", "code": "10315106" },
      { "name": "Fresh cut leptos", "code": "10315200" },
      { "name": "Fresh cut blue or flowering lepto", "code": "10315201" },
      { "name": "Fresh cut hot pink lepto", "code": "10315202" },
      { "name": "Fresh cut light pink lepto", "code": "10315203" },
      { "name": "Fresh cut pink lepto", "code": "10315204" },
      { "name": "Fresh cut red lepto", "code": "10315205" },
      { "name": "Fresh cut white lepto", "code": "10315206" },
      { "name": "Fresh cut lilacs", "code": "10315300" },
      { "name": "Fresh cut french hybrid lavender lilac", "code": "10315301" },
      { "name": "Fresh cut french hybrid purple lilac", "code": "10315302" },
      { "name": "Fresh cut purple lilac", "code": "10315303" },
      { "name": "Fresh cut vine lilac", "code": "10315304" },
      { "name": "Fresh cut white lilac", "code": "10315305" },
      { "name": "Fresh cut wild white lilac", "code": "10315306" },
      { "name": "Fresh cut lilies", "code": "10315400" },
      {
        "name": "Fresh cut highness longiflorum and asiatic hybrid lily",
        "code": "10315401"
      },
      { "name": "Fresh cut asiatic black out lily", "code": "10315402" },
      { "name": "Fresh cut asiatic dark pink lily", "code": "10315403" },
      { "name": "Fresh cut asiatic electric lily", "code": "10315404" },
      { "name": "Fresh cut asiatic festival lily", "code": "10315405" },
      { "name": "Fresh cut asiatic geneva lily", "code": "10315406" },
      { "name": "Fresh cut asiatic light pink lily", "code": "10315407" },
      { "name": "Fresh cut asiatic lollipop lily", "code": "10315408" },
      {
        "name": "Fresh cut asiatic miss america purple lily",
        "code": "10315409"
      },
      { "name": "Fresh cut asiatic monte negro lily", "code": "10315410" },
      { "name": "Fresh cut asiatic orange lily", "code": "10315411" },
      { "name": "Fresh cut asiatic peach cannes lily", "code": "10315412" },
      { "name": "Fresh cut asiatic pink lily", "code": "10315413" },
      { "name": "Fresh cut asiatic sancerre lily", "code": "10315414" },
      { "name": "Fresh cut asiatic white dream lily", "code": "10315415" },
      { "name": "Fresh cut asiatic yellow lily", "code": "10315416" },
      {
        "name": "Fresh cut bright diamond longiflorum and asiatic hybrid lily",
        "code": "10315417"
      },
      {
        "name": "Fresh cut brindisi longiflorum and asiatic hybrid lily",
        "code": "10315418"
      },
      {
        "name": "Fresh cut carmine longiflorum and asiatic hybrid lily",
        "code": "10315419"
      },
      {
        "name": "Fresh cut cinnabar longiflorum and asiatic hybrid lily",
        "code": "10315420"
      },
      {
        "name": "Fresh cut club longiflorum and asiatic hybrid lily",
        "code": "10315421"
      },
      {
        "name": "Fresh cut discovery longiflorum and asiatic hybrid lily",
        "code": "10315422"
      },
      { "name": "Fresh cut easter lily", "code": "10315423" },
      {
        "name": "Fresh cut isis longiflorum and asiatic hybrid lily",
        "code": "10315424"
      },
      {
        "name": "Fresh cut la hybrid justice longiflorum and asiatic hybrid lily",
        "code": "10315425"
      },
      {
        "name": "Fresh cut lace longiflorum and asiatic hybrid lily",
        "code": "10315426"
      },
      { "name": "Fresh cut lily of the valley", "code": "10315427" },
      {
        "name": "Fresh cut love longiflorum and asiatic hybrid lily",
        "code": "10315428"
      },
      {
        "name": "Fresh cut menorca longiflorum and asiatic hybrid lily",
        "code": "10315429"
      },
      { "name": "Fresh cut oriental acapulco lily", "code": "10315430" },
      { "name": "Fresh cut oriental albion lily", "code": "10315431" },
      { "name": "Fresh cut oriental argentina lily", "code": "10315432" },
      { "name": "Fresh cut oriental auratum lily", "code": "10315433" },
      { "name": "Fresh cut oriental barbaresco lily", "code": "10315434" },
      { "name": "Fresh cut oriental bernini lily", "code": "10315435" },
      { "name": "Fresh cut oriental beseno lily", "code": "10315436" },
      { "name": "Fresh cut oriental broadway lily", "code": "10315437" },
      { "name": "Fresh cut oriental canada lily", "code": "10315438" },
      { "name": "Fresh cut oriental casablanca lily", "code": "10315439" },
      { "name": "Fresh cut oriental chili lily", "code": "10315440" },
      { "name": "Fresh cut oriental chrystal blanca lily", "code": "10315441" },
      { "name": "Fresh cut oriental cobra lily", "code": "10315442" },
      { "name": "Fresh cut oriental conca d or lily", "code": "10315443" },
      { "name": "Fresh cut oriental cote d ivor lily", "code": "10315444" },
      { "name": "Fresh cut oriental dizzy lily", "code": "10315445" },
      { "name": "Fresh cut oriental fireball lily", "code": "10315446" },
      { "name": "Fresh cut oriental gluhwein lily", "code": "10315447" },
      { "name": "Fresh cut oriental goldband lily", "code": "10315448" },
      { "name": "Fresh cut oriental halifax lily", "code": "10315449" },
      { "name": "Fresh cut oriental kathryn lily", "code": "10315450" },
      { "name": "Fresh cut oriental kyoto lily", "code": "10315451" },
      { "name": "Fresh cut oriental la mancha lily", "code": "10315452" },
      { "name": "Fresh cut oriental medusa lily", "code": "10315453" },
      { "name": "Fresh cut oriental montezuma lily", "code": "10315454" },
      { "name": "Fresh cut oriental muscadet lily", "code": "10315455" },
      { "name": "Fresh cut oriental nippon lily", "code": "10315456" },
      { "name": "Fresh cut oriental opus one lily", "code": "10315457" },
      { "name": "Fresh cut oriental pompeii lily", "code": "10315458" },
      { "name": "Fresh cut oriental rialto lily", "code": "10315459" },
      { "name": "Fresh cut oriental robina lily", "code": "10315460" },
      { "name": "Fresh cut oriental rousillon lily", "code": "10315461" },
      { "name": "Fresh cut oriental siberia lily", "code": "10315462" },
      { "name": "Fresh cut oriental sorbonne lily", "code": "10315463" },
      { "name": "Fresh cut oriental starfighter lily", "code": "10315464" },
      { "name": "Fresh cut oriental stargazer lily", "code": "10315465" },
      { "name": "Fresh cut oriental sumatra lily", "code": "10315466" },
      { "name": "Fresh cut oriental time out lily", "code": "10315467" },
      { "name": "Fresh cut oriental tom pouche lily", "code": "10315468" },
      { "name": "Fresh cut oriental tropical lily", "code": "10315469" },
      { "name": "Fresh cut oriental white cup lily", "code": "10315470" },
      { "name": "Fresh cut oriental white merostar lily", "code": "10315471" },
      { "name": "Fresh cut oriental white montana lily", "code": "10315472" },
      { "name": "Fresh cut oriental white stargazer lily", "code": "10315473" },
      { "name": "Fresh cut oriental yellow band lily", "code": "10315474" },
      { "name": "Fresh cut oriental yellow dream lily", "code": "10315475" },
      { "name": "Fresh cut oriental yellow queen lily", "code": "10315476" },
      { "name": "Fresh cut oriental yellow star lily", "code": "10315477" },
      { "name": "Fresh cut oriental yelloween lily", "code": "10315478" },
      { "name": "Fresh cut ot red dutch lily", "code": "10315479" },
      { "name": "Fresh cut sonata nimph lily", "code": "10315480" },
      { "name": "Fresh cut sonata shocking lily", "code": "10315481" },
      { "name": "Fresh cut sonata triumphater lily", "code": "10315482" },
      {
        "name": "Fresh cut sunset longiflorum and asiatic hybrid lily",
        "code": "10315483"
      },
      { "name": "Fresh cut water lily", "code": "10315484" },
      { "name": "Fresh cut limoniums", "code": "10315500" },
      { "name": "Fresh cut misty peach limonium", "code": "10315501" },
      { "name": "Fresh cut misty pink limonium", "code": "10315502" },
      { "name": "Fresh cut misty white limonium", "code": "10315503" },
      { "name": "Fresh cut misty yellow limonium", "code": "10315504" },
      { "name": "Fresh cut safora limonium", "code": "10315505" },
      { "name": "Fresh cut sinensis limonium", "code": "10315506" },
      { "name": "Fresh cut lisianthuses", "code": "10315600" },
      { "name": "Fresh cut creme lisianthus", "code": "10315601" },
      { "name": "Fresh cut dark pink lisianthus", "code": "10315602" },
      { "name": "Fresh cut green lisianthus", "code": "10315603" },
      { "name": "Fresh cut lavender lisianthus", "code": "10315604" },
      { "name": "Fresh cut light pink lisianthus", "code": "10315605" },
      { "name": "Fresh cut mini white lisianthus", "code": "10315606" },
      { "name": "Fresh cut peach lisianthus", "code": "10315607" },
      {
        "name": "Fresh cut pink with white edge lisianthus",
        "code": "10315608"
      },
      { "name": "Fresh cut purple lisianthus", "code": "10315609" },
      {
        "name": "Fresh cut purple with white edge lisianthus",
        "code": "10315610"
      },
      {
        "name": "Fresh cut white with pink edge lisianthus",
        "code": "10315611"
      },
      { "name": "Fresh cut white lisianthus", "code": "10315612" },
      {
        "name": "Fresh cut white with purple edge lisianthus",
        "code": "10315613"
      },
      {
        "name": "Fresh cut muscari blooms or grape hyacinths",
        "code": "10315700"
      },
      { "name": "Fresh cut armeniacum muscari", "code": "10315701" },
      { "name": "Fresh cut bortyoides white muscari", "code": "10315702" },
      { "name": "Fresh cut green muscari", "code": "10315703" },
      { "name": "Fresh cut latifolia muscari", "code": "10315704" },
      { "name": "Fresh cut valerie finn muscari", "code": "10315705" },
      { "name": "Fresh cut narcissus", "code": "10315800" },
      { "name": "Fresh cut cheerfulness narcissus", "code": "10315801" },
      { "name": "Fresh cut golden dawn narcissus", "code": "10315802" },
      { "name": "Fresh cut paperwhite abba narcissus", "code": "10315803" },
      { "name": "Fresh cut paperwhite narcissus", "code": "10315804" },
      { "name": "Fresh cut pheasant eye narcissus", "code": "10315805" },
      { "name": "Fresh cut soleil d or narcissus", "code": "10315806" },
      { "name": "Fresh cut tete a tete narcissus", "code": "10315807" },
      { "name": "Fresh cut thalia narcissus", "code": "10315808" },
      { "name": "Fresh cut ornamental peppers", "code": "10316000" },
      { "name": "Fresh cut ornamental chili pepper", "code": "10316001" },
      { "name": "Fresh cut ornamental mixed pepper", "code": "10316002" },
      { "name": "Fresh cut ornamental orange pepper", "code": "10316003" },
      { "name": "Fresh cut ornamental red pepper", "code": "10316004" },
      { "name": "Fresh cut ornamental yellow pepper", "code": "10316005" },
      { "name": "Fresh cut ornithogalums", "code": "10316100" },
      { "name": "Fresh cut arabicum ornithogalum", "code": "10316101" },
      { "name": "Fresh cut orange dubium ornithogalum", "code": "10316102" },
      { "name": "Fresh cut umbellatum ornithogalum", "code": "10316103" },
      { "name": "Fresh cut white dubium ornithogalum", "code": "10316104" },
      { "name": "Fresh cut yellow dubium ornithogalum", "code": "10316105" },
      { "name": "Fresh cut peonies", "code": "10316200" },
      { "name": "Fresh cut alexander fleming peony", "code": "10316201" },
      { "name": "Fresh cut coral charm peony", "code": "10316202" },
      { "name": "Fresh cut coral sunset peony", "code": "10316203" },
      { "name": "Fresh cut coral supreme peony", "code": "10316204" },
      { "name": "Fresh cut double gardenia peony", "code": "10316205" },
      { "name": "Fresh cut double jules eli dark peony", "code": "10316206" },
      { "name": "Fresh cut double white dutchess peony", "code": "10316207" },
      { "name": "Fresh cut felix crousse peony", "code": "10316208" },
      { "name": "Fresh cut festiva maxima peony", "code": "10316209" },
      { "name": "Fresh cut garden treasure peony", "code": "10316210" },
      { "name": "Fresh cut kansas dark pink peony", "code": "10316211" },
      { "name": "Fresh cut karl rosenfelt peony", "code": "10316212" },
      { "name": "Fresh cut paula fay peony", "code": "10316213" },
      { "name": "Fresh cut red charm peony", "code": "10316214" },
      { "name": "Fresh cut red passion peony", "code": "10316215" },
      { "name": "Fresh cut sarah bernhardt pink peony", "code": "10316216" },
      { "name": "Fresh cut scarlet o hara peony", "code": "10316217" },
      { "name": "Fresh cut shirley temple peony", "code": "10316218" },
      { "name": "Fresh cut banksias", "code": "10316300" },
      { "name": "Fresh cut ashbyi banksia", "code": "10316301" },
      { "name": "Fresh cut baxteri banksia", "code": "10316302" },
      { "name": "Fresh cut coccinea banksia", "code": "10316306" },
      { "name": "Fresh cut ericifolia banksia", "code": "10316311" },
      { "name": "Fresh cut green banksia", "code": "10316315" },
      { "name": "Fresh cut menziesii banksia", "code": "10316322" },
      { "name": "Fresh cut natural white banksia", "code": "10316325" },
      { "name": "Fresh cut orange banksia", "code": "10316326" },
      { "name": "Fresh cut pink banksia", "code": "10316332" },
      { "name": "Fresh cut ranunculuses", "code": "10316400" },
      { "name": "Fresh cut chocolate ranunculus", "code": "10316401" },
      { "name": "Fresh cut elegance ranunculus", "code": "10316402" },
      { "name": "Fresh cut green ranunculus", "code": "10316403" },
      { "name": "Fresh cut grimaldi ranunculus", "code": "10316404" },
      { "name": "Fresh cut hot pink ranunculus", "code": "10316405" },
      { "name": "Fresh cut light pink ranunculus", "code": "10316406" },
      { "name": "Fresh cut orange ranunculus", "code": "10316407" },
      { "name": "Fresh cut pink green center ranunculus", "code": "10316408" },
      { "name": "Fresh cut pink ranunculus", "code": "10316409" },
      { "name": "Fresh cut red ranunculus", "code": "10316410" },
      { "name": "Fresh cut white ranunculus", "code": "10316411" },
      { "name": "Fresh cut yellow ranunculus", "code": "10316412" },
      { "name": "Fresh cut salmon ranunculus", "code": "10316413" },
      { "name": "Fresh cut scabiosas", "code": "10316500" },
      { "name": "Fresh cut annual scabiosa", "code": "10316501" },
      { "name": "Fresh cut black scabiosa", "code": "10316502" },
      { "name": "Fresh cut caucasica blue scabiosa", "code": "10316503" },
      { "name": "Fresh cut caucasica pink scabiosa", "code": "10316504" },
      { "name": "Fresh cut caucasica pods scabiosa", "code": "10316505" },
      { "name": "Fresh cut caucasica white scabiosa", "code": "10316506" },
      { "name": "Fresh cut strawberry scabiosa", "code": "10316507" },
      { "name": "Fresh cut scotch brooms", "code": "10316600" },
      { "name": "Fresh cut pink scotch broom", "code": "10316601" },
      { "name": "Fresh cut purple scotch broom", "code": "10316602" },
      { "name": "Fresh cut white scotch broom", "code": "10316603" },
      { "name": "Fresh cut yellow scotch broom", "code": "10316604" },
      { "name": "Fresh cut snapdragons", "code": "10316700" },
      { "name": "Fresh cut bi color snapdragon", "code": "10316701" },
      { "name": "Fresh cut burgundy snapdragon", "code": "10316702" },
      { "name": "Fresh cut hot pink snapdragon", "code": "10316703" },
      { "name": "Fresh cut lavender snapdragon", "code": "10316704" },
      { "name": "Fresh cut light orange snapdragon", "code": "10316705" },
      { "name": "Fresh cut light pink snapdragon", "code": "10316706" },
      { "name": "Fresh cut orange snapdragon", "code": "10316707" },
      { "name": "Fresh cut white snapdragon", "code": "10316708" },
      { "name": "Fresh cut yellow snapdragon", "code": "10316709" },
      { "name": "Fresh cut statices", "code": "10316800" },
      { "name": "Fresh cut blue statice", "code": "10316801" },
      { "name": "Fresh cut lavender statice", "code": "10316802" },
      { "name": "Fresh cut peach statice", "code": "10316803" },
      { "name": "Fresh cut pink statice", "code": "10316804" },
      { "name": "Fresh cut purple statice", "code": "10316805" },
      { "name": "Fresh cut seafoam statice", "code": "10316806" },
      { "name": "Fresh cut white statice", "code": "10316807" },
      { "name": "Fresh cut yellow statice", "code": "10316808" },
      {
        "name": "Fresh cut matthiola incana or stock flowers",
        "code": "10316900"
      },
      { "name": "Fresh cut apricot stock flower", "code": "10316901" },
      { "name": "Fresh cut cream stock flower", "code": "10316902" },
      { "name": "Fresh cut fuchsia stock flower", "code": "10316903" },
      { "name": "Fresh cut lavender stock flower", "code": "10316904" },
      { "name": "Fresh cut light lavender stock flower", "code": "10316905" },
      { "name": "Fresh cut pacific pink stock flower", "code": "10316906" },
      { "name": "Fresh cut purple stock flower", "code": "10316907" },
      { "name": "Fresh cut ruby red stock flower", "code": "10316908" },
      { "name": "Fresh cut sweetheart pink stock flower", "code": "10316909" },
      { "name": "Fresh cut white stock flower", "code": "10316910" },
      { "name": "Fresh cut sunflowers", "code": "10317000" },
      { "name": "Fresh cut holiday tint sunflower", "code": "10317001" },
      { "name": "Fresh cut mahogany sunflower", "code": "10317002" },
      { "name": "Fresh cut sunbeam sunflower", "code": "10317003" },
      { "name": "Fresh cut sunbright sunflower", "code": "10317004" },
      { "name": "Fresh cut sunsplash sunflower", "code": "10317005" },
      { "name": "Fresh cut teddybear sunflower", "code": "10317006" },
      { "name": "Fresh cut sweet peas", "code": "10317100" },
      { "name": "Fresh cut green dyed sweet pea", "code": "10317101" },
      { "name": "Fresh cut hot pink sweet pea", "code": "10317102" },
      { "name": "Fresh cut lavender sweet pea", "code": "10317103" },
      { "name": "Fresh cut light pink sweet pea", "code": "10317104" },
      { "name": "Fresh cut orange sweet pea", "code": "10317105" },
      { "name": "Fresh cut peach dyed sweet pea", "code": "10317106" },
      { "name": "Fresh cut purple sweet pea", "code": "10317107" },
      { "name": "Fresh cut white sweet pea", "code": "10317108" },
      { "name": "Fresh cut thistles", "code": "10317200" },
      { "name": "Fresh cut alpinum thistle", "code": "10317201" },
      { "name": "Fresh cut echinops thistle", "code": "10317202" },
      {
        "name": "Fresh cut eryngium arabian dream thistle",
        "code": "10317203"
      },
      { "name": "Fresh cut eryngium blue bell thistle", "code": "10317204" },
      { "name": "Fresh cut eryngium orion thistle", "code": "10317205" },
      { "name": "Fresh cut eryngium raspberry thistle", "code": "10317206" },
      { "name": "Fresh cut eryngium supernova thistle", "code": "10317207" },
      { "name": "Fresh cut eryngium tinkerbell thistle", "code": "10317208" },
      { "name": "Fresh cut tulips", "code": "10317300" },
      { "name": "Fresh cut adrem tulip", "code": "10317301" },
      { "name": "Fresh cut apricot tulip", "code": "10317302" },
      { "name": "Fresh cut bi color red and yellow tulip", "code": "10317303" },
      { "name": "Fresh cut double bicolor tulip", "code": "10317304" },
      { "name": "Fresh cut double pink tulip", "code": "10317305" },
      { "name": "Fresh cut double red tulip", "code": "10317306" },
      { "name": "Fresh cut double white tulip", "code": "10317307" },
      { "name": "Fresh cut double yellow tulip", "code": "10317308" },
      { "name": "Fresh cut french avignon tulip", "code": "10317309" },
      { "name": "Fresh cut french camarque tulip", "code": "10317310" },
      { "name": "Fresh cut french dordogne tulip", "code": "10317311" },
      { "name": "Fresh cut french fiat tulip", "code": "10317312" },
      { "name": "Fresh cut french flamboyant tulip", "code": "10317313" },
      { "name": "Fresh cut french flaming parrot tulip", "code": "10317314" },
      { "name": "Fresh cut french florissa tulip", "code": "10317315" },
      { "name": "Fresh cut french maureen double tulip", "code": "10317316" },
      { "name": "Fresh cut french maureen tulip", "code": "10317317" },
      { "name": "Fresh cut french menton tulip", "code": "10317318" },
      { "name": "Fresh cut french montpellier tulip", "code": "10317319" },
      { "name": "Fresh cut french orange unique tulip", "code": "10317320" },
      {
        "name": "Fresh cut french peony renown unique tulip",
        "code": "10317321"
      },
      { "name": "Fresh cut french pink parrot tulip", "code": "10317322" },
      { "name": "Fresh cut french princess unique tulip", "code": "10317323" },
      { "name": "Fresh cut french renown tulip", "code": "10317324" },
      { "name": "Fresh cut french scheppers tulip", "code": "10317325" },
      { "name": "Fresh cut french suede tulip", "code": "10317326" },
      { "name": "Fresh cut french toyota tulip", "code": "10317327" },
      { "name": "Fresh cut french weber parrot tulip", "code": "10317328" },
      { "name": "Fresh cut french white parrot tulip", "code": "10317329" },
      { "name": "Fresh cut frilly edge lavender tulip", "code": "10317330" },
      { "name": "Fresh cut hot pink tulip", "code": "10317331" },
      {
        "name": "Fresh cut hot pink variegated folia tulip",
        "code": "10317332"
      },
      { "name": "Fresh cut lavender tulip", "code": "10317333" },
      {
        "name": "Fresh cut light pink variegated folia tulip",
        "code": "10317334"
      },
      { "name": "Fresh cut merry widow tulip", "code": "10317335" },
      { "name": "Fresh cut orange tulip", "code": "10317336" },
      { "name": "Fresh cut parrot black tulip", "code": "10317337" },
      { "name": "Fresh cut parrot estella rijnveld tulip", "code": "10317338" },
      { "name": "Fresh cut parrot flaming tulip", "code": "10317339" },
      { "name": "Fresh cut parrot green tulip", "code": "10317340" },
      { "name": "Fresh cut parrot lavender tulip", "code": "10317341" },
      { "name": "Fresh cut parrot orange tulip", "code": "10317342" },
      { "name": "Fresh cut parrot peach tulip", "code": "10317343" },
      { "name": "Fresh cut parrot pink tulip", "code": "10317344" },
      { "name": "Fresh cut parrot red tulip", "code": "10317345" },
      { "name": "Fresh cut parrot rococo red tulip", "code": "10317346" },
      { "name": "Fresh cut parrot weber tulip", "code": "10317347" },
      { "name": "Fresh cut parrot white tulip", "code": "10317348" },
      { "name": "Fresh cut parrot yellow tulip", "code": "10317349" },
      { "name": "Fresh cut pink tulip", "code": "10317350" },
      { "name": "Fresh cut purple tulip", "code": "10317351" },
      { "name": "Fresh cut red tulip", "code": "10317352" },
      { "name": "Fresh cut species tulip", "code": "10317353" },
      { "name": "Fresh cut white tulip", "code": "10317354" },
      { "name": "Fresh cut yellow tulip", "code": "10317355" },
      { "name": "Fresh cut waxflowers", "code": "10317400" },
      { "name": "Fresh cut alba waxflower", "code": "10317401" },
      { "name": "Fresh cut bi color waxflower", "code": "10317402" },
      { "name": "Fresh cut chinchilla waxflower", "code": "10317403" },
      { "name": "Fresh cut dancing queen waxflower", "code": "10317404" },
      { "name": "Fresh cut danmark waxflower", "code": "10317405" },
      { "name": "Fresh cut denmar pearl waxflower", "code": "10317406" },
      {
        "name": "Fresh cut hybrid pastel gemflower waxflower",
        "code": "10317407"
      },
      {
        "name": "Fresh cut hybrid pink gemflower waxflower",
        "code": "10317408"
      },
      {
        "name": "Fresh cut hybrid blondie white waxflower",
        "code": "10317409"
      },
      { "name": "Fresh cut hybrid eric john waxflower", "code": "10317410" },
      { "name": "Fresh cut hybrid painted lady waxflower", "code": "10317411" },
      { "name": "Fresh cut hybrid revelation waxflower", "code": "10317412" },
      { "name": "Fresh cut hybrid snowball waxflower", "code": "10317413" },
      { "name": "Fresh cut juriens brook waxflower", "code": "10317414" },
      { "name": "Fresh cut lady stephany pink waxflower", "code": "10317415" },
      { "name": "Fresh cut madonna waxflower", "code": "10317416" },
      { "name": "Fresh cut mini white waxflower", "code": "10317417" },
      { "name": "Fresh cut orange waxflower", "code": "10317418" },
      { "name": "Fresh cut pearl waxflower", "code": "10317419" },
      { "name": "Fresh cut pixie moon waxflower", "code": "10317420" },
      { "name": "Fresh cut purple pride waxflower", "code": "10317421" },
      { "name": "Fresh cut red waxflower", "code": "10317422" },
      { "name": "Fresh cut wanaroo waxflower", "code": "10317423" },
      { "name": "Fresh cut yellow waxflower", "code": "10317424" },
      { "name": "Fresh cut yarrows", "code": "10317500" },
      { "name": "Fresh cut burgundy yarrow", "code": "10317501" },
      { "name": "Fresh cut cottage creme yarrow", "code": "10317502" },
      { "name": "Fresh cut cottage pink yarrow", "code": "10317503" },
      { "name": "Fresh cut moonshine yarrow", "code": "10317504" },
      { "name": "Fresh cut orange yarrow", "code": "10317505" },
      { "name": "Fresh cut peach yarrow", "code": "10317506" },
      { "name": "Fresh cut pink yarrow", "code": "10317507" },
      { "name": "Fresh cut red dyed yarrow", "code": "10317508" },
      { "name": "Fresh cut white yarrow", "code": "10317509" },
      { "name": "Fresh cut yellow yarrow", "code": "10317510" },
      { "name": "Fresh cut zinnias", "code": "10317600" },
      { "name": "Fresh cut hot pink zinnia", "code": "10317601" },
      { "name": "Fresh cut mini zinnia", "code": "10317602" },
      { "name": "Fresh cut pink zinnia", "code": "10317603" },
      { "name": "Fresh cut red zinnia", "code": "10317604" },
      { "name": "Fresh cut salmon zinnia", "code": "10317605" },
      { "name": "Fresh cut yellow zinnia", "code": "10317606" },
      { "name": "Fresh cut forsythias", "code": "10317700" },
      { "name": "Fresh cut forsythia viridissima", "code": "10317701" },
      { "name": "Fresh cut forsythia giraldiana", "code": "10317702" },
      { "name": "Fresh cut forsythia mira", "code": "10317703" },
      { "name": "Fresh cut forsythia suspensa", "code": "10317704" },
      { "name": "Fresh cut forsythia intermedia", "code": "10317705" },
      { "name": "Fresh cut forsythia variabilis", "code": "10317706" },
      { "name": "Fresh cut forsythia ovate", "code": "10317707" },
      { "name": "Fresh cut forsythia intermedia lynnwood", "code": "10317708" },
      { "name": "Fresh cut geraniums or cranesbills", "code": "10317800" },
      { "name": "Fresh cut argenteum geranium", "code": "10317801" },
      { "name": "Fresh cut cinereum geranium", "code": "10317802" },
      { "name": "Fresh cut clarkei geranium", "code": "10317803" },
      { "name": "Fresh cut dalmaticum geranium", "code": "10317804" },
      { "name": "Fresh cut endressii geranium", "code": "10317805" },
      { "name": "Fresh cut eriostemon geranium", "code": "10317806" },
      { "name": "Fresh cut farreri geranium", "code": "10317807" },
      {
        "name": "Fresh cut himalayense or grandiflorum geranium",
        "code": "10317808"
      },
      { "name": "Fresh cut ibericum geranium", "code": "10317809" },
      {
        "name": "Fresh cut macrorrhizum or bigroot geranium",
        "code": "10317810"
      },
      { "name": "Fresh cut maculatum geranium", "code": "10317811" },
      { "name": "Fresh cut nodosum geranium", "code": "10317812" },
      { "name": "Fresh cut phaeum geranium", "code": "10317813" },
      { "name": "Fresh cut platypetalum geranium", "code": "10317814" },
      { "name": "Fresh cut pratense geranium", "code": "10317815" },
      { "name": "Fresh cut procurrens geranium", "code": "10317816" },
      { "name": "Fresh cut psilostemon geranium", "code": "10317817" },
      { "name": "Fresh cut pylzowianum geranium", "code": "10317818" },
      { "name": "Fresh cut renardii geranium", "code": "10317819" },
      { "name": "Fresh cut sanguineum or bloody geranium", "code": "10317820" },
      { "name": "Fresh cut sylvaticum geranium", "code": "10317821" },
      { "name": "Fresh cut traversii geranium", "code": "10317822" },
      { "name": "Fresh cut tuberosum geranium", "code": "10317823" },
      { "name": "Fresh cut versicolor geranium", "code": "10317824" },
      { "name": "Fresh cut wallichianum geranium", "code": "10317825" },
      { "name": "Fresh cut wlassovianum geranium", "code": "10317826" },
      {
        "name": "Fresh cut x magnificum or showy geranium",
        "code": "10317827"
      },
      { "name": "Fresh cut hippeastrums", "code": "10317900" },
      { "name": "Fresh cut aglaiae hippeastrum", "code": "10317901" },
      { "name": "Fresh cut amaru hippeastrum", "code": "10317902" },
      { "name": "Fresh cut angustifolium hippeastrum", "code": "10317903" },
      { "name": "Fresh cut anzaldoi hippeastrum", "code": "10317904" },
      { "name": "Fresh cut araripinum hippeastrum", "code": "10317905" },
      { "name": "Fresh cut arboricola hippeastrum", "code": "10317906" },
      { "name": "Fresh cut argentinum hippeastrum", "code": "10317907" },
      { "name": "Fresh cut aulicum hippeastrum", "code": "10317908" },
      { "name": "Fresh cut aviflorum hippeastrum", "code": "10317909" },
      { "name": "Fresh cut barreirasum hippeastrum", "code": "10317910" },
      { "name": "Fresh cut blossfeldiae hippeastrum", "code": "10317911" },
      { "name": "Fresh cut blumenavium hippeastrum", "code": "10317912" },
      { "name": "Fresh cut brasilianum hippeastrum", "code": "10317913" },
      { "name": "Fresh cut breviflorum hippeastrum", "code": "10317914" },
      { "name": "Fresh cut bukasovii hippeastrum", "code": "10317915" },
      { "name": "Fresh cut calyptratum hippeastrum", "code": "10317916" },
      { "name": "Fresh cut caupolicanense hippeastrum", "code": "10317917" },
      { "name": "Fresh cut chionedyanthum hippeastrum", "code": "10317918" },
      { "name": "Fresh cut condemaita hippeastrum", "code": "10317919" },
      { "name": "Fresh cut corriense hippeastrum", "code": "10317920" },
      { "name": "Fresh cut cuzcoense hippeastrum", "code": "10317921" },
      { "name": "Fresh cut curitibanum hippeastrum", "code": "10317922" },
      { "name": "Fresh cut cybister hippeastrum", "code": "10317923" },
      { "name": "Fresh cut divijuliani hippeastrum", "code": "10317924" },
      { "name": "Fresh cut evansiae hippeastrum", "code": "10317925" },
      { "name": "Fresh cut ferreyrae hippeastrum", "code": "10317926" },
      { "name": "Fresh cut forgetii hippeastrum", "code": "10317927" },
      { "name": "Fresh cut fosteri hippeastrum", "code": "10317928" },
      { "name": "Fresh cut fuscum hippeastrum", "code": "10317929" },
      { "name": "Fresh cut glaucescens hippeastrum", "code": "10317930" },
      { "name": "Fresh cut goianum hippeastrum", "code": "10317931" },
      { "name": "Fresh cut guarapuavicum hippeastrum", "code": "10317932" },
      { "name": "Fresh cut harrisonii hippeastrum", "code": "10317933" },
      { "name": "Fresh cut hugoi hippeastrum", "code": "10317934" },
      { "name": "Fresh cut iguazuanum hippeastrum", "code": "10317935" },
      { "name": "Fresh cut illustre hippeastrum", "code": "10317936" },
      { "name": "Fresh cut intiflorum hippeastrum", "code": "10317937" },
      { "name": "Fresh cut kromeri hippeastrum", "code": "10317938" },
      { "name": "Fresh cut lapacense hippeastrum", "code": "10317939" },
      { "name": "Fresh cut leonardii hippeastrum", "code": "10317940" },
      { "name": "Fresh cut leopoldii hippeastrum", "code": "10317941" },
      { "name": "Fresh cut macbridei hippeastrum", "code": "10317942" },
      { "name": "Fresh cut machupijchense hippeastrum", "code": "10317943" },
      { "name": "Fresh cut mandonii hippeastrum", "code": "10317944" },
      { "name": "Fresh cut minasgerais hippeastrum", "code": "10317945" },
      { "name": "Fresh cut miniatum hippeastrum", "code": "10317946" },
      { "name": "Fresh cut mollevillquense hippeastrum", "code": "10317947" },
      { "name": "Fresh cut morelianum hippeastrum", "code": "10317948" },
      { "name": "Fresh cut nelsonii hippeastrum", "code": "10317949" },
      { "name": "Fresh cut oconoquense hippeastrum", "code": "10317950" },
      { "name": "Fresh cut papilio hippeastrum", "code": "10317951" },
      { "name": "Fresh cut paquichanum hippeastrum", "code": "10317952" },
      { "name": "Fresh cut paradisiacum hippeastrum", "code": "10317953" },
      { "name": "Fresh cut pardinum hippeastrum", "code": "10317954" },
      { "name": "Fresh cut parodii hippeastrum", "code": "10317955" },
      { "name": "Fresh cut petiolatum hippeastrum", "code": "10317956" },
      { "name": "Fresh cut psittacinum hippeastrum", "code": "10317957" },
      { "name": "Fresh cut puniceum hippeastrum", "code": "10317958" },
      { "name": "Fresh cut reginae hippeastrum", "code": "10317959" },
      { "name": "Fresh cut reticulatum hippeastrum", "code": "10317960" },
      { "name": "Fresh cut rubropictum hippeastrum", "code": "10317961" },
      { "name": "Fresh cut santacatarina hippeastrum", "code": "10317962" },
      { "name": "Fresh cut solandraeflorum hippeastrum", "code": "10317963" },
      { "name": "Fresh cut starkiorum hippeastrum", "code": "10317964" },
      { "name": "Fresh cut striatum hippeastrum", "code": "10317965" },
      { "name": "Fresh cut stylosum hippeastrum", "code": "10317966" },
      { "name": "Fresh cut teyucuarense hippeastrum", "code": "10317967" },
      { "name": "Fresh cut traubii hippeastrum", "code": "10317968" },
      { "name": "Fresh cut vargasii hippeastrum", "code": "10317969" },
      { "name": "Fresh cut variegatum hippeastrum", "code": "10317970" },
      { "name": "Fresh cut vittatum hippeastrum", "code": "10317971" },
      { "name": "Fresh cut yungacense hippeastrum", "code": "10317972" },
      { "name": "Fresh cut rudbeckia or coneflower", "code": "10318000" },
      { "name": "Fresh cut alpicola rudbeckia", "code": "10318001" },
      { "name": "Fresh cut amplexicaulis rudbeckia", "code": "10318002" },
      { "name": "Fresh cut auriculata rudbeckia", "code": "10318003" },
      { "name": "Fresh cut bi color rudbeckia", "code": "10318004" },
      { "name": "Fresh cut californica rudbeckia", "code": "10318005" },
      { "name": "Fresh cut fulgida rudbeckia", "code": "10318006" },
      { "name": "Fresh cut glaucescens rudbeckia", "code": "10318007" },
      { "name": "Fresh cut graminifolia rudbeckia", "code": "10318008" },
      { "name": "Fresh cut grandiflora rudbeckia", "code": "10318009" },
      { "name": "Fresh cut heliopsidis rudbeckia", "code": "10318010" },
      { "name": "Fresh cut hirta rudbeckia", "code": "10318011" },
      { "name": "Fresh cut klamathensis rudbeckia", "code": "10318012" },
      { "name": "Fresh cut laciniata rudbeckia", "code": "10318013" },
      { "name": "Fresh cut maxima rudbeckia", "code": "10318014" },
      { "name": "Fresh cut missouriensis rudbeckia", "code": "10318015" },
      { "name": "Fresh cut mohrii rudbeckia", "code": "10318016" },
      { "name": "Fresh cut mollis rudbeckia", "code": "10318017" },
      { "name": "Fresh cut montana rudbeckia", "code": "10318018" },
      { "name": "Fresh cut nitida rudbeckia", "code": "10318019" },
      { "name": "Fresh cut occidentalis rudbeckia", "code": "10318020" },
      { "name": "Fresh cut pinnata rudbeckia", "code": "10318021" },
      { "name": "Fresh cut scabrifolia rudbeckia", "code": "10318022" },
      { "name": "Fresh cut serotina rudbeckia", "code": "10318023" },
      { "name": "Fresh cut speciosa rudbeckia", "code": "10318024" },
      { "name": "Fresh cut subtomentosa rudbeckia", "code": "10318025" },
      { "name": "Fresh cut texana rudbeckia", "code": "10318026" },
      { "name": "Fresh cut triloba rudbeckia", "code": "10318027" },
      { "name": "Fresh cut proteas", "code": "10318100" },
      { "name": "Fresh cut bouquet protea", "code": "10318101" },
      { "name": "Fresh cut bottle brush protea", "code": "10318102" },
      { "name": "Fresh cut carnival protea", "code": "10318103" },
      { "name": "Fresh cut cordata foliage protea", "code": "10318104" },
      { "name": "Fresh cut grandiceps protea", "code": "10318105" },
      { "name": "Fresh cut green mink protea", "code": "10318106" },
      { "name": "Fresh cut ivy protea", "code": "10318107" },
      { "name": "Fresh cut king protea", "code": "10318108" },
      { "name": "Fresh cut nana cones protea", "code": "10318109" },
      { "name": "Fresh cut pincushion orange protea", "code": "10318110" },
      { "name": "Fresh cut pincushion tango protea", "code": "10318111" },
      { "name": "Fresh cut pincushion yellow protea", "code": "10318112" },
      { "name": "Fresh cut pink ice protea", "code": "10318113" },
      { "name": "Fresh cut pink mink protea", "code": "10318114" },
      { "name": "Fresh cut queen protea", "code": "10318115" },
      { "name": "Fresh cut repens protea", "code": "10318116" },
      { "name": "Fresh cut rosespoon protea", "code": "10318117" },
      { "name": "Fresh cut silvia protea", "code": "10318118" },
      { "name": "Fresh cut sugar protea", "code": "10318119" },
      { "name": "Fresh cut susara protea", "code": "10318120" },
      { "name": "Fresh cut waratha long protea", "code": "10318121" },
      { "name": "Fresh cut white mink protea", "code": "10318122" },
      { "name": "Fresh cut leucadendrons", "code": "10318200" },
      { "name": "Fresh cut argenteum leucadendron", "code": "10318201" },
      { "name": "Fresh cut creme delight leucadendron", "code": "10318202" },
      { "name": "Fresh cut cumosum leucadendron", "code": "10318203" },
      { "name": "Fresh cut discolor leucadendron", "code": "10318204" },
      { "name": "Fresh cut galpini leucadendron", "code": "10318205" },
      { "name": "Fresh cut gold strike leucadendron", "code": "10318206" },
      { "name": "Fresh cut inca gold leucadendron", "code": "10318207" },
      { "name": "Fresh cut jester leucadendron", "code": "10318208" },
      { "name": "Fresh cut laxum leucadendron", "code": "10318209" },
      { "name": "Fresh cut mini leucadendron", "code": "10318210" },
      { "name": "Fresh cut patea gold leucadendron", "code": "10318211" },
      { "name": "Fresh cut petra leucadendron", "code": "10318212" },
      { "name": "Fresh cut plumosum leucadendron", "code": "10318213" },
      { "name": "Fresh cut rosette leucadendron", "code": "10318214" },
      { "name": "Fresh cut safari sunset leucadendron", "code": "10318215" },
      {
        "name": "Fresh cut safari sunset spr leucadendron",
        "code": "10318216"
      },
      { "name": "Fresh cut speciosa leucadendron", "code": "10318217" },
      { "name": "Fresh cut spray leucadendron", "code": "10318218" },
      { "name": "Fresh cut wilson wonder leucadendron", "code": "10318219" },
      { "name": "Fresh cut yarden leucadendron", "code": "10318220" },
      { "name": "Fresh cut leucospermums", "code": "10318300" },
      { "name": "Fresh cut leucospermum album", "code": "10318301" },
      { "name": "Fresh cut leucospermum attenuatum", "code": "10318302" },
      { "name": "Fresh cut leucospermum calligerum", "code": "10318303" },
      { "name": "Fresh cut leucospermum conocarpodendron", "code": "10318304" },
      { "name": "Fresh cut leucospermum cordatum", "code": "10318305" },
      { "name": "Fresh cut leucospermum cuneiforme", "code": "10318306" },
      { "name": "Fresh cut leucospermum formosum", "code": "10318307" },
      { "name": "Fresh cut leucospermum glabrum", "code": "10318308" },
      { "name": "Fresh cut leucospermum grandiflorum", "code": "10318309" },
      { "name": "Fresh cut leucospermum harmatum", "code": "10318310" },
      { "name": "Fresh cut leucospermum heterophyllum", "code": "10318311" },
      { "name": "Fresh cut leucospermum innovans", "code": "10318312" },
      { "name": "Fresh cut leucospermum muirii", "code": "10318313" },
      { "name": "Fresh cut leucospermum oleifolium", "code": "10318314" },
      { "name": "Fresh cut leucospermum patersonii", "code": "10318315" },
      { "name": "Fresh cut leucospermum pluridens", "code": "10318316" },
      { "name": "Fresh cut leucospermum praemorsum", "code": "10318317" },
      { "name": "Fresh cut leucospermum prostratum", "code": "10318318" },
      { "name": "Fresh cut leucospermum rodolentum", "code": "10318319" },
      { "name": "Fresh cut leucospermum saxatile", "code": "10318320" },
      { "name": "Fresh cut leucospermum secundifolium", "code": "10318321" },
      { "name": "Fresh cut leucospermum tomentosus", "code": "10318322" },
      { "name": "Fresh cut leucospermum truncatulum", "code": "10318323" },
      { "name": "Fresh cut leucospermum utriculosum", "code": "10318324" },
      { "name": "Fresh cut leucospermum winterii", "code": "10318325" },
      { "name": "Fresh cut leucospermum arenarium", "code": "10318326" },
      { "name": "Fresh cut leucospermum bolusii", "code": "10318327" },
      { "name": "Fresh cut leucospermum catherinae", "code": "10318328" },
      { "name": "Fresh cut leucospermum conocarpum", "code": "10318329" },
      { "name": "Fresh cut leucospermum cordifolium", "code": "10318330" },
      { "name": "Fresh cut leucospermum erubescens", "code": "10318331" },
      { "name": "Fresh cut leucospermum gerrardii", "code": "10318332" },
      { "name": "Fresh cut leucospermum gracile", "code": "10318333" },
      { "name": "Fresh cut leucospermum gueinzii", "code": "10318334" },
      { "name": "Fresh cut leucospermum harpagonatum", "code": "10318335" },
      {
        "name": "Fresh cut leucospermum hypophyllocarpodendron",
        "code": "10318336"
      },
      { "name": "Fresh cut leucospermum lineare", "code": "10318337" },
      { "name": "Fresh cut leucospermum mundii", "code": "10318338" },
      { "name": "Fresh cut leucospermum parile", "code": "10318339" },
      { "name": "Fresh cut leucospermum pendunculatum", "code": "10318340" },
      { "name": "Fresh cut leucospermum praecox", "code": "10318341" },
      { "name": "Fresh cut leucospermum profugum", "code": "10318342" },
      { "name": "Fresh cut leucospermum reflexum", "code": "10318343" },
      { "name": "Fresh cut leucospermum royenifolium", "code": "10318344" },
      { "name": "Fresh cut leucospermum saxosum", "code": "10318345" },
      { "name": "Fresh cut leucospermum spathulatum", "code": "10318346" },
      { "name": "Fresh cut leucospermum tottum", "code": "10318347" },
      { "name": "Fresh cut leucospermum truncatum", "code": "10318348" },
      { "name": "Fresh cut leucospermum vestitum", "code": "10318349" },
      { "name": "Fresh cut leucospermum wittebergense", "code": "10318350" },
      {
        "name": "Fresh cut blooms of low species or variety count flowers",
        "code": "10320000"
      },
      { "name": "Fresh cut agapanthuses", "code": "10321500" },
      { "name": "Fresh cut blue agapanthus", "code": "10321501" },
      { "name": "Fresh cut white agapanthus", "code": "10321502" },
      { "name": "Fresh cut alchemillas", "code": "10321600" },
      { "name": "Fresh cut ladys mantle alchemilla", "code": "10321601" },
      { "name": "Fresh cut robustica alchemilla", "code": "10321602" },
      { "name": "Fresh cut alstilbes", "code": "10321700" },
      { "name": "Fresh cut hot pink astilbe", "code": "10321701" },
      { "name": "Fresh cut light pink astilbe", "code": "10321702" },
      { "name": "Fresh cut red astilbe", "code": "10321703" },
      { "name": "Fresh cut white astilbe", "code": "10321704" },
      { "name": "Fresh cut angelicas", "code": "10321800" },
      { "name": "Fresh cut gigas angelica", "code": "10321801" },
      { "name": "Fresh cut sylvestris angelica", "code": "10321802" },
      { "name": "Fresh cut artemesias", "code": "10321900" },
      { "name": "Fresh cut green artemesia", "code": "10321901" },
      { "name": "Fresh cut silver king artemesia", "code": "10321902" },
      { "name": "Fresh cut artichoke flowers", "code": "10322000" },
      { "name": "Fresh cut chocolate artichoke flower", "code": "10322001" },
      { "name": "Fresh cut green artichoke flower", "code": "10322002" },
      {
        "name": "Fresh cut purple or flowering artichoke flower",
        "code": "10322003"
      },
      { "name": "Fresh cut astrantias", "code": "10322100" },
      { "name": "Fresh cut pink astrantia", "code": "10322101" },
      { "name": "Fresh cut white astrantia", "code": "10322102" },
      { "name": "Fresh cut banana flowers", "code": "10322200" },
      { "name": "Fresh cut orange banana flower", "code": "10322201" },
      { "name": "Fresh cut orange torch banana flower", "code": "10322202" },
      { "name": "Fresh cut purple banana flower", "code": "10322203" },
      { "name": "Fresh cut baptisias", "code": "10322300" },
      { "name": "Fresh cut australis baptisia", "code": "10322301" },
      { "name": "Fresh cut sphaerocarpa baptisia", "code": "10322302" },
      { "name": "Fresh cut boronias", "code": "10322400" },
      { "name": "Fresh cut pink boronia", "code": "10322401" },
      { "name": "Fresh cut yellow boronia", "code": "10322402" },
      { "name": "Fresh cut bromelias", "code": "10322500" },
      { "name": "Fresh cut yellow reg bromelia", "code": "10322501" },
      { "name": "Fresh cut red reg bromelia", "code": "10322502" },
      { "name": "Fresh cut brunias", "code": "10322600" },
      { "name": "Fresh cut albiflora brunia", "code": "10322601" },
      { "name": "Fresh cut albiflora green brunia", "code": "10322602" },
      { "name": "Fresh cut silver spray brunia", "code": "10322603" },
      { "name": "Fresh cut calatheas", "code": "10322700" },
      { "name": "Fresh cut cigar calathea", "code": "10322701" },
      { "name": "Fresh cut green ice calathea", "code": "10322702" },
      { "name": "Fresh cut rattlesnake calathea", "code": "10322703" },
      { "name": "Fresh cut calcynias", "code": "10322800" },
      { "name": "Fresh cut pink calcynia", "code": "10322801" },
      { "name": "Fresh cut princess calcynia", "code": "10322802" },
      { "name": "Fresh cut white calcynia", "code": "10322803" },
      { "name": "Fresh cut calendulas", "code": "10322900" },
      { "name": "Fresh cut orange calendula", "code": "10322901" },
      { "name": "Fresh cut yellow calendula", "code": "10322902" },
      { "name": "Fresh cut campanulas or bellflowers", "code": "10323000" },
      { "name": "Fresh cut blue bells campanula", "code": "10323001" },
      { "name": "Fresh cut pink bells campanula", "code": "10323002" },
      { "name": "Fresh cut white bells campanula", "code": "10323003" },
      { "name": "Fresh cut cestrums", "code": "10323100" },
      { "name": "Fresh cut red cestrum", "code": "10323101" },
      { "name": "Fresh cut red zohar cestrum", "code": "10323102" },
      { "name": "Fresh cut yellow cestrum", "code": "10323103" },
      { "name": "Fresh cut chasmanthes", "code": "10323200" },
      { "name": "Fresh cut floribunda yellow chasmanthe", "code": "10323201" },
      { "name": "Fresh cut floribundi orange chasmanthe", "code": "10323202" },
      { "name": "Fresh cut costuses", "code": "10323300" },
      { "name": "Fresh cut barbatus costus", "code": "10323301" },
      { "name": "Fresh cut indian head costus", "code": "10323302" },
      { "name": "Fresh cut crocosmias", "code": "10323400" },
      { "name": "Fresh cut lucifer crocosmia", "code": "10323401" },
      { "name": "Fresh cut pods crocosmia", "code": "10323402" },
      { "name": "Fresh cut yellow crocosmia", "code": "10323403" },
      { "name": "Fresh cut cytanthuses", "code": "10323500" },
      { "name": "Fresh cut bright orange cyrtanthus", "code": "10323501" },
      { "name": "Fresh cut creme cyrtanthus", "code": "10323502" },
      { "name": "Fresh cut orange cyrtanthus", "code": "10323503" },
      { "name": "Fresh cut yellow cyrtanthus", "code": "10323504" },
      { "name": "Fresh cut rumex or dock flowers", "code": "10323600" },
      { "name": "Fresh cut green dock flower", "code": "10323601" },
      { "name": "Fresh cut red dock flower", "code": "10323602" },
      { "name": "Fresh cut eryngiums", "code": "10323700" },
      { "name": "Fresh cut tinted black eryngium", "code": "10323701" },
      { "name": "Fresh cut tinted orange eryngium", "code": "10323702" },
      { "name": "Fresh cut feverfews", "code": "10323800" },
      { "name": "Fresh cut single vegmo feverfew", "code": "10323801" },
      { "name": "Fresh cut double white feverfew", "code": "10323802" },
      { "name": "Fresh cut snowball feverfew", "code": "10323803" },
      { "name": "Fresh cut white feverfew", "code": "10323804" },
      { "name": "Fresh cut forget me nots", "code": "10323900" },
      { "name": "Fresh cut blue forget me not", "code": "10323901" },
      { "name": "Fresh cut white forget me not", "code": "10323902" },
      { "name": "Fresh cut gaillardias", "code": "10324000" },
      { "name": "Fresh cut orange gaillardia", "code": "10324001" },
      { "name": "Fresh cut yellow gaillardia", "code": "10324002" },
      { "name": "Fresh cut gentianas", "code": "10324100" },
      { "name": "Fresh cut blue gentiana", "code": "10324101" },
      { "name": "Fresh cut white gentiana", "code": "10324102" },
      { "name": "Fresh cut glamini gladioluses", "code": "10324200" },
      { "name": "Fresh cut pink glamini gladiolus", "code": "10324201" },
      { "name": "Fresh cut red glamini gladiolus", "code": "10324202" },
      { "name": "Fresh cut gloriosas", "code": "10324300" },
      { "name": "Fresh cut orange gloriosa", "code": "10324301" },
      { "name": "Fresh cut red gloriosa", "code": "10324302" },
      { "name": "Fresh cut yellow gloriosa", "code": "10324303" },
      {
        "name": "Fresh cut gomphrena globosa or globe amaranth",
        "code": "10324400"
      },
      { "name": "Fresh cut orange gomphrena globosa", "code": "10324401" },
      { "name": "Fresh cut pink gomphrena globosa", "code": "10324402" },
      { "name": "Fresh cut hellebores", "code": "10324500" },
      { "name": "Fresh cut green hellebore", "code": "10324501" },
      { "name": "Fresh cut moonshine hellebore", "code": "10324502" },
      { "name": "Fresh cut ixias", "code": "10324600" },
      { "name": "Fresh cut pink ixia", "code": "10324601" },
      { "name": "Fresh cut white ixia", "code": "10324602" },
      { "name": "Fresh cut liatrises", "code": "10324700" },
      { "name": "Fresh cut purple liatris", "code": "10324701" },
      { "name": "Fresh cut spray liatris", "code": "10324702" },
      { "name": "Fresh cut white liatris", "code": "10324703" },
      { "name": "Fresh cut lysimachias", "code": "10324800" },
      { "name": "Fresh cut punctata lysimachia", "code": "10324801" },
      { "name": "Fresh cut vulgaris lysimachia", "code": "10324802" },
      { "name": "Fresh cut white lysimachia", "code": "10324803" },
      { "name": "Fresh cut yellow lysimachia", "code": "10324804" },
      { "name": "Fresh cut maracas", "code": "10324900" },
      { "name": "Fresh cut brown maraca", "code": "10324901" },
      { "name": "Fresh cut shampoo ginger maraca", "code": "10324902" },
      { "name": "Fresh cut marigolds", "code": "10325000" },
      { "name": "Fresh cut french marigold", "code": "10325001" },
      { "name": "Fresh cut green marigold", "code": "10325002" },
      { "name": "Fresh cut orange marigold", "code": "10325003" },
      { "name": "Fresh cut yellow marigold", "code": "10325004" },
      { "name": "Fresh cut mimosas", "code": "10325100" },
      { "name": "Fresh cut blue or purple mimosa", "code": "10325101" },
      { "name": "Fresh cut finger mimosa", "code": "10325102" },
      { "name": "Fresh cut floribunda or italy mimosa", "code": "10325103" },
      { "name": "Fresh cut mirandole mimosa", "code": "10325104" },
      { "name": "Fresh cut nerines", "code": "10325200" },
      { "name": "Fresh cut pink nerine", "code": "10325201" },
      { "name": "Fresh cut white sarniensis nerine", "code": "10325202" },
      { "name": "Fresh cut pepperberry flowers", "code": "10325300" },
      { "name": "Fresh cut hanging pepperberry flower", "code": "10325301" },
      { "name": "Fresh cut leafless pepperberry flower", "code": "10325302" },
      {
        "name": "Fresh cut upright brazilian pepperberry flower",
        "code": "10325303"
      },
      { "name": "Fresh cut phlox", "code": "10325400" },
      { "name": "Fresh cut dark pink phlox", "code": "10325401" },
      { "name": "Fresh cut lavender phlox", "code": "10325402" },
      { "name": "Fresh cut light pink phlox", "code": "10325403" },
      { "name": "Fresh cut white phlox", "code": "10325404" },
      {
        "name": "Fresh cut physostegias or obedient plant",
        "code": "10325500"
      },
      { "name": "Fresh cut pink physostegia", "code": "10325501" },
      { "name": "Fresh cut pods physostegia", "code": "10325502" },
      { "name": "Fresh cut white physostegia", "code": "10325503" },
      { "name": "Fresh cut saponarias", "code": "10325600" },
      { "name": "Fresh cut pink saponaria", "code": "10325601" },
      { "name": "Fresh cut white saponaria", "code": "10325602" },
      { "name": "Fresh cut sarracenias", "code": "10325700" },
      { "name": "Fresh cut flava rugelii sarracenia", "code": "10325701" },
      { "name": "Fresh cut scillas", "code": "10325800" },
      { "name": "Fresh cut campanulata blue scilla", "code": "10325801" },
      { "name": "Fresh cut campanulata pink scilla", "code": "10325802" },
      { "name": "Fresh cut campanulata white scilla", "code": "10325803" },
      { "name": "Fresh cut peruviana scilla", "code": "10325804" },
      { "name": "Fresh cut sedums", "code": "10325900" },
      { "name": "Fresh cut brown sedum", "code": "10325901" },
      { "name": "Fresh cut green sedum", "code": "10325902" },
      { "name": "Fresh cut pink sedum", "code": "10325903" },
      { "name": "Fresh cut red sedum", "code": "10325904" },
      {
        "name": "Fresh cut single species or varieties of flowers",
        "code": "10326000"
      },
      { "name": "Fresh cut agrostemma", "code": "10326001" },
      { "name": "Fresh cut kniphofia or assegai poker", "code": "10326002" },
      { "name": "Fresh cut bellis perennis", "code": "10326003" },
      { "name": "Fresh cut bells of ireland or molucella", "code": "10326004" },
      { "name": "Fresh cut bird of paradise", "code": "10326005" },
      { "name": "Fresh cut blushing bride", "code": "10326006" },
      { "name": "Fresh cut buddleia or butterfly", "code": "10326007" },
      { "name": "Fresh cut bupleurum griffithii", "code": "10326008" },
      { "name": "Fresh cut california ginesta", "code": "10326009" },
      { "name": "Fresh cut callicarpa purple", "code": "10326010" },
      { "name": "Fresh cut white campanula bell", "code": "10326011" },
      { "name": "Fresh cut candy tuft", "code": "10326012" },
      { "name": "Fresh cut cariopteris", "code": "10326013" },
      { "name": "Fresh cut centaurea or marco polo", "code": "10326014" },
      { "name": "Fresh cut chinese lantern", "code": "10326015" },
      { "name": "Fresh cut clematis recta purpurea", "code": "10326016" },
      { "name": "Fresh cut cleome spinosa", "code": "10326017" },
      { "name": "Fresh cut coreopsis", "code": "10326018" },
      { "name": "Fresh cut blue cornflower", "code": "10326019" },
      { "name": "Fresh cut chocolate cosmos", "code": "10326020" },
      { "name": "Fresh cut cotinus coggygria", "code": "10326021" },
      { "name": "Fresh cut craspedia or billy balls", "code": "10326022" },
      { "name": "Fresh cut deutzia tall", "code": "10326023" },
      { "name": "Fresh cut diosma", "code": "10326024" },
      { "name": "Fresh cut echeveria succulent", "code": "10326025" },
      { "name": "Fresh cut echinacea purpurea", "code": "10326026" },
      { "name": "Fresh cut edelweiss", "code": "10326027" },
      { "name": "Fresh cut erythronium pagoda", "code": "10326028" },
      { "name": "Fresh cut eucalyptus flower", "code": "10326029" },
      { "name": "Fresh cut eucharis or amazon lily", "code": "10326030" },
      { "name": "Fresh cut eucomis or pineapple lily", "code": "10326031" },
      { "name": "Fresh cut eupatorium maculatum", "code": "10326032" },
      { "name": "Fresh cut filipendula", "code": "10326033" },
      { "name": "Fresh cut foxglove", "code": "10326034" },
      { "name": "Fresh cut globe gilia", "code": "10326035" },
      { "name": "Fresh cut globularia blue eye", "code": "10326036" },
      { "name": "Fresh cut washington hawthorne", "code": "10326037" },
      { "name": "Fresh cut helenium", "code": "10326038" },
      { "name": "Fresh cut helianthus", "code": "10326039" },
      { "name": "Fresh cut hesperis matronalis", "code": "10326040" },
      { "name": "Fresh cut houttuynia cordata chameleon", "code": "10326041" },
      { "name": "Fresh cut hyacinth with bulb", "code": "10326042" },
      { "name": "Fresh cut indian corn", "code": "10326043" },
      { "name": "Fresh cut jack in the pulpit", "code": "10326044" },
      { "name": "Fresh cut japanese tree of heaven", "code": "10326045" },
      { "name": "Fresh cut jasmine flowering vine", "code": "10326046" },
      {
        "name": "Fresh cut jatropha curcas or firecracker",
        "code": "10326047"
      },
      { "name": "Fresh cut knautia", "code": "10326048" },
      { "name": "Fresh cut kochia sedifolia", "code": "10326049" },
      { "name": "Fresh cut lachenalia romaud", "code": "10326050" },
      { "name": "Fresh cut lambs ears flower", "code": "10326051" },
      { "name": "Fresh cut lavender", "code": "10326052" },
      { "name": "Fresh cut leucocoryne speciosa", "code": "10326053" },
      { "name": "Fresh cut lythrum", "code": "10326054" },
      { "name": "Fresh cut malva zebrina", "code": "10326055" },
      { "name": "Fresh cut marguerite white daisy", "code": "10326056" },
      { "name": "Fresh cut montbretia yellow", "code": "10326057" },
      { "name": "Fresh cut nebelia", "code": "10326058" },
      { "name": "Fresh cut nicotiana green", "code": "10326059" },
      {
        "name": "Fresh cut nigella damascena or love in the mist",
        "code": "10326060"
      },
      { "name": "Fresh cut nigella pods", "code": "10326061" },
      { "name": "Fresh cut nuns orchid", "code": "10326062" },
      { "name": "Fresh cut paphiopedilum green orchid", "code": "10326063" },
      { "name": "Fresh cut paranomus", "code": "10326064" },
      { "name": "Fresh cut penstemon husker red", "code": "10326065" },
      { "name": "Fresh cut peruvian apple", "code": "10326066" },
      { "name": "Fresh cut phlomis sarnia", "code": "10326067" },
      { "name": "Fresh cut pink lace flower or didiscus", "code": "10326068" },
      { "name": "Fresh cut platycodon or balloon flower", "code": "10326069" },
      { "name": "Fresh cut retzia capensis", "code": "10326070" },
      { "name": "Fresh cut ricinus communis", "code": "10326071" },
      { "name": "Fresh cut snow on the mountain", "code": "10326072" },
      { "name": "Fresh cut solidago tinted", "code": "10326073" },
      { "name": "Fresh cut white squill", "code": "10326074" },
      { "name": "Fresh cut stachys byzantina", "code": "10326075" },
      { "name": "Fresh cut strawflower", "code": "10326076" },
      { "name": "Fresh cut succulent oscularia", "code": "10326077" },
      { "name": "Fresh cut tillandsia flower", "code": "10326078" },
      { "name": "Fresh cut triteleia", "code": "10326079" },
      {
        "name": "Fresh cut tritoma orange or red hot poker",
        "code": "10326080"
      },
      { "name": "Fresh cut veronicastrum virginiana", "code": "10326081" },
      { "name": "Fresh cut vriesea splendens", "code": "10326082" },
      { "name": "Fresh cut st johns wort or hypericim", "code": "10326084" },
      { "name": "Fresh cut spirea", "code": "10326085" },
      { "name": "Fresh cut ruscus", "code": "10326086" },
      { "name": "Fresh cut solomons seals", "code": "10326100" },
      { "name": "Fresh cut false solomon seal", "code": "10326101" },
      { "name": "Fresh cut variegated solomons seal", "code": "10326102" },
      { "name": "Fresh cut tanacetums", "code": "10326200" },
      { "name": "Fresh cut amazon tanacetum", "code": "10326201" },
      {
        "name": "Fresh cut victory double white tanacetum",
        "code": "10326202"
      },
      {
        "name": "Fresh cut victory single white tanacetum",
        "code": "10326203"
      },
      { "name": "Fresh cut yellow vegmo tanacetum", "code": "10326204" },
      { "name": "Fresh cut tracheliums", "code": "10326300" },
      { "name": "Fresh cut jade trachelium", "code": "10326301" },
      { "name": "Fresh cut purple trachelium", "code": "10326302" },
      { "name": "Fresh cut white trachelium", "code": "10326303" },
      { "name": "Fresh cut tuberosas", "code": "10326400" },
      { "name": "Fresh cut double tuberosa", "code": "10326401" },
      { "name": "Fresh cut single tuberosa", "code": "10326402" },
      { "name": "Fresh cut tweedias", "code": "10326500" },
      { "name": "Fresh cut blue tweedia", "code": "10326501" },
      { "name": "Fresh cut white tweedia", "code": "10326502" },
      { "name": "Fresh cut veronicas", "code": "10326600" },
      { "name": "Fresh cut pink veronica", "code": "10326601" },
      { "name": "Fresh cut purple veronica", "code": "10326602" },
      { "name": "Fresh cut white veronica", "code": "10326603" },
      { "name": "Fresh cut watsonias", "code": "10326700" },
      { "name": "Fresh cut orange watsonias", "code": "10326701" },
      { "name": "Fresh cut pink watsonia", "code": "10326702" },
      { "name": "Fresh cut red watsonia", "code": "10326703" },
      { "name": "Fresh cut white watsonia", "code": "10326704" },
      { "name": "Fresh cut chrysanthemums", "code": "10330000" },
      { "name": "Fresh cut button chrysanthemums", "code": "10331500" },
      { "name": "Fresh cut delirock pompon chrysanthemum", "code": "10331501" },
      {
        "name": "Fresh cut discovery pompon chrysanthemum",
        "code": "10331502"
      },
      { "name": "Fresh cut focus pompon chrysanthemum", "code": "10331503" },
      { "name": "Fresh cut jeanny pompon chrysanthemum", "code": "10331504" },
      { "name": "Fresh cut lady pompon chrysanthemum", "code": "10331505" },
      { "name": "Fresh cut leidy pompon chrysanthemum", "code": "10331506" },
      { "name": "Fresh cut lexy pompon chrysanthemum", "code": "10331507" },
      { "name": "Fresh cut ole pompon chrysanthemum", "code": "10331508" },
      { "name": "Fresh cut revise pompon chrysanthemum", "code": "10331509" },
      {
        "name": "Fresh cut statesman pompon chrysanthemum",
        "code": "10331510"
      },
      { "name": "Fresh cut sweet pompon chrysanthemum", "code": "10331511" },
      { "name": "Fresh cut yoko ono pompon chrysanthemum", "code": "10331512" },
      { "name": "Fresh cut zip pompon chrysanthemum", "code": "10331513" },
      { "name": "Fresh cut daisy pompon chrysanthemums", "code": "10331600" },
      {
        "name": "Fresh cut artist pink pompon chrysanthemum",
        "code": "10331601"
      },
      {
        "name": "Fresh cut artist yellow pompon chrysanthemum",
        "code": "10331602"
      },
      {
        "name": "Fresh cut atlantis pink pompon chrysanthemum",
        "code": "10331603"
      },
      {
        "name": "Fresh cut atlantis white pompon chrysanthemum",
        "code": "10331604"
      },
      {
        "name": "Fresh cut atlantis yellow pompon chrysanthemum",
        "code": "10331605"
      },
      {
        "name": "Fresh cut bennie jolink pompon chrysanthemum",
        "code": "10331606"
      },
      {
        "name": "Fresh cut bennie jolink yellow pompon chrysanthemum",
        "code": "10331607"
      },
      {
        "name": "Fresh cut bronze managua pompon chrysanthemum",
        "code": "10331608"
      },
      { "name": "Fresh cut clue pompon chrysanthemum", "code": "10331609" },
      {
        "name": "Fresh cut coral fiction pompon chrysanthemum",
        "code": "10331610"
      },
      { "name": "Fresh cut cumbia pompon chrysanthemum", "code": "10331611" },
      {
        "name": "Fresh cut dark cantata pompon chrysanthemum",
        "code": "10331612"
      },
      {
        "name": "Fresh cut dark lineker pompon chrysanthemum",
        "code": "10331613"
      },
      { "name": "Fresh cut dipper pompon chrysanthemum", "code": "10331614" },
      {
        "name": "Fresh cut elite pink pompon chrysanthemum",
        "code": "10331615"
      },
      {
        "name": "Fresh cut elite white pompon chrysanthemum",
        "code": "10331616"
      },
      {
        "name": "Fresh cut elite yellow pompon chrysanthemum",
        "code": "10331617"
      },
      { "name": "Fresh cut factor pompon chrysanthemum", "code": "10331618" },
      { "name": "Fresh cut fiction pompon chrysanthemum", "code": "10331619" },
      { "name": "Fresh cut force pompon chrysanthemum", "code": "10331620" },
      {
        "name": "Fresh cut improved reagan pompon chrysanthemum",
        "code": "10331621"
      },
      { "name": "Fresh cut life pompon chrysanthemum", "code": "10331622" },
      {
        "name": "Fresh cut managua orange pompon chrysanthemum",
        "code": "10331623"
      },
      {
        "name": "Fresh cut novedad bronze cocarde pompon chrysanthemum",
        "code": "10331624"
      },
      {
        "name": "Fresh cut orange reagan pompon chrysanthemum",
        "code": "10331625"
      },
      { "name": "Fresh cut orinoco pompon chrysanthemum", "code": "10331626" },
      { "name": "Fresh cut petra pompon chrysanthemum", "code": "10331627" },
      {
        "name": "Fresh cut pink balsas pompon chrysanthemum",
        "code": "10331628"
      },
      {
        "name": "Fresh cut pink mona lisa pompon chrysanthemum",
        "code": "10331629"
      },
      {
        "name": "Fresh cut pink reagan pompon chrysanthemum",
        "code": "10331630"
      },
      {
        "name": "Fresh cut reagan ivory pompon chrysanthemum",
        "code": "10331631"
      },
      {
        "name": "Fresh cut reagan rosy pompon chrysanthemum",
        "code": "10331632"
      },
      { "name": "Fresh cut rebasco pompon chrysanthemum", "code": "10331633" },
      { "name": "Fresh cut redock pompon chrysanthemum", "code": "10331634" },
      {
        "name": "Fresh cut salmon lineker pompon chrysanthemum",
        "code": "10331635"
      },
      { "name": "Fresh cut sheba pompon chrysanthemum", "code": "10331636" },
      { "name": "Fresh cut sirius pompon chrysanthemum", "code": "10331637" },
      {
        "name": "Fresh cut splendid reagan pompon chrysanthemum",
        "code": "10331638"
      },
      {
        "name": "Fresh cut sunny reagan pompon chrysanthemum",
        "code": "10331639"
      },
      { "name": "Fresh cut tina pompon chrysanthemum", "code": "10331640" },
      { "name": "Fresh cut vero pompon chrysanthemum", "code": "10331641" },
      { "name": "Fresh cut volare pompon chrysanthemum", "code": "10331642" },
      {
        "name": "Fresh cut white life pompon chrysanthemum",
        "code": "10331643"
      },
      {
        "name": "Fresh cut white reagan pompon chrysanthemum",
        "code": "10331644"
      },
      {
        "name": "Fresh cut white rhino pompon chrysanthemum",
        "code": "10331645"
      },
      {
        "name": "Fresh cut yellow vero pompon chrysanthemum",
        "code": "10331646"
      },
      { "name": "Fresh cut zenith pompon chrysanthemum", "code": "10331647" },
      { "name": "Fresh cut cremon disbud chrysanthemums", "code": "10331700" },
      {
        "name": "Fresh cut cremon annecy dark disbud chrysanthemum",
        "code": "10331701"
      },
      {
        "name": "Fresh cut cremon atlantis disbud chrysanthemum",
        "code": "10331702"
      },
      {
        "name": "Fresh cut cremon atlantis pink disbud chrysanthemum",
        "code": "10331703"
      },
      {
        "name": "Fresh cut cremon eleonora bronze disbud chrysanthemum",
        "code": "10331704"
      },
      {
        "name": "Fresh cut cremon eleonora lilac disbud chrysanthemum",
        "code": "10331705"
      },
      {
        "name": "Fresh cut cremon eleonora pink disbud chrysanthemum",
        "code": "10331706"
      },
      {
        "name": "Fresh cut cremon eleonora snow disbud chrysanthemum",
        "code": "10331707"
      },
      {
        "name": "Fresh cut cremon eleonora yellow disbud chrysanthemum",
        "code": "10331708"
      },
      {
        "name": "Fresh cut cremon idea disbud chrysanthemum",
        "code": "10331709"
      },
      {
        "name": "Fresh cut cremon ivanna purple disbud chrysanthemum",
        "code": "10331710"
      },
      {
        "name": "Fresh cut cremon minka pink disbud chrysanthemum",
        "code": "10331711"
      },
      {
        "name": "Fresh cut cremon ready disbud chrysanthemum",
        "code": "10331712"
      },
      {
        "name": "Fresh cut cremon sinatra disbud chrysanthemum",
        "code": "10331713"
      },
      { "name": "Fresh cut rover red chrysanthemum", "code": "10331714" },
      {
        "name": "Fresh cut football disbud chrysanthemums",
        "code": "10331800"
      },
      { "name": "Fresh cut blaze disbud chrysanthemum", "code": "10331801" },
      {
        "name": "Fresh cut football kiss disbud chrysanthemum",
        "code": "10331802"
      },
      {
        "name": "Fresh cut football lavender/pink disbud chrysanthemum",
        "code": "10331803"
      },
      {
        "name": "Fresh cut football resouci disbud chrysanthemum",
        "code": "10331804"
      },
      {
        "name": "Fresh cut football white disbud chrysanthemum",
        "code": "10331805"
      },
      {
        "name": "Fresh cut football yellow disbud chrysanthemum",
        "code": "10331806"
      },
      {
        "name": "Fresh cut promenade disbud chrysanthemum",
        "code": "10331807"
      },
      { "name": "Fresh cut rebonnet disbud chrysanthemum", "code": "10331808" },
      { "name": "Fresh cut reflex disbud chrysanthemum", "code": "10331809" },
      {
        "name": "Fresh cut residence disbud chrysanthemum",
        "code": "10331810"
      },
      {
        "name": "Fresh cut resomee pearl disbud chrysanthemum",
        "code": "10331811"
      },
      { "name": "Fresh cut resouci disbud chrysanthemum", "code": "10331812" },
      { "name": "Fresh cut spider chrysanthemums", "code": "10331900" },
      {
        "name": "Fresh cut anastasia bronze spider chrysanthemum",
        "code": "10331901"
      },
      {
        "name": "Fresh cut anastasia dark bronze spider chrysanthemum",
        "code": "10331902"
      },
      {
        "name": "Fresh cut anastasia green spider chrysanthemum",
        "code": "10331903"
      },
      {
        "name": "Fresh cut anastasia lilac spider chrysanthemum",
        "code": "10331904"
      },
      {
        "name": "Fresh cut anastasia pink spider chrysanthemum",
        "code": "10331905"
      },
      {
        "name": "Fresh cut anastasia purple spider chrysanthemum",
        "code": "10331906"
      },
      {
        "name": "Fresh cut anastasia sunny spider chrysanthemum",
        "code": "10331907"
      },
      {
        "name": "Fresh cut anastasia white spider chrysanthemum",
        "code": "10331908"
      },
      { "name": "Fresh cut bradford spider chrysanthemum", "code": "10331909" },
      {
        "name": "Fresh cut delistar white spider chrysanthemum",
        "code": "10331910"
      },
      {
        "name": "Fresh cut delistar yellow spider chrysanthemum",
        "code": "10331911"
      },
      { "name": "Fresh cut minka spider chrysanthemum", "code": "10331912" },
      {
        "name": "Fresh cut natasha sunny spider chrysanthemum",
        "code": "10331913"
      },
      {
        "name": "Fresh cut pirouette spider chrysanthemum",
        "code": "10331914"
      },
      { "name": "Fresh cut reflect spider chrysanthemum", "code": "10331915" },
      { "name": "Fresh cut regatta spider chrysanthemum", "code": "10331916" },
      { "name": "Fresh cut render spider chrysanthemum", "code": "10331917" },
      {
        "name": "Fresh cut repertoire spider chrysanthemum",
        "code": "10331918"
      },
      { "name": "Fresh cut resolute spider chrysanthemum", "code": "10331919" },
      { "name": "Fresh cut resomac spider chrysanthemum", "code": "10331920" },
      { "name": "Fresh cut shamrock spider chrysanthemum", "code": "10331921" },
      {
        "name": "Fresh cut bronze mood spider chrysanthemum",
        "code": "10331922"
      },
      {
        "name": "Fresh cut super white spider chrysanthemum",
        "code": "10331923"
      },
      {
        "name": "Fresh cut super yellow spider chrysanthemum",
        "code": "10331924"
      },
      { "name": "Fresh cut tender spider chrysanthemum", "code": "10331925" },
      { "name": "Fresh cut zembla spider chrysanthemum", "code": "10331926" },
      { "name": "Fresh cut novelty chrysanthemums", "code": "10332000" },
      {
        "name": "Fresh cut annecy pink pompon chrysanthemum",
        "code": "10332001"
      },
      {
        "name": "Fresh cut ardilo royal pompon chrysanthemum",
        "code": "10332002"
      },
      { "name": "Fresh cut athos pompon chrysanthemum", "code": "10332003" },
      { "name": "Fresh cut biarritz pompon chrysanthemum", "code": "10332004" },
      {
        "name": "Fresh cut bradford orange pompon chrysanthemum",
        "code": "10332005"
      },
      { "name": "Fresh cut bradford pompon chrysanthemum", "code": "10332006" },
      { "name": "Fresh cut candle pompon chrysanthemum", "code": "10332007" },
      { "name": "Fresh cut candor pompon chrysanthemum", "code": "10332008" },
      { "name": "Fresh cut dash pompon chrysanthemum", "code": "10332009" },
      { "name": "Fresh cut decima pompon chrysanthemum", "code": "10332010" },
      { "name": "Fresh cut delisun pompon chrysanthemum", "code": "10332012" },
      { "name": "Fresh cut dion pompon chrysanthemum", "code": "10332013" },
      { "name": "Fresh cut dorena pompon chrysanthemum", "code": "10332014" },
      { "name": "Fresh cut dublin pompon chrysanthemum", "code": "10332015" },
      {
        "name": "Fresh cut everglades pompon chrysanthemum",
        "code": "10332016"
      },
      { "name": "Fresh cut handsome pompon chrysanthemum", "code": "10332017" },
      { "name": "Fresh cut hasting pompon chrysanthemum", "code": "10332018" },
      {
        "name": "Fresh cut high five pompon chrysanthemum",
        "code": "10332019"
      },
      {
        "name": "Fresh cut improved mundial pompon chrysanthemum",
        "code": "10332020"
      },
      { "name": "Fresh cut juanes pompon chrysanthemum", "code": "10332021" },
      {
        "name": "Fresh cut kiato green pompon chrysanthemum",
        "code": "10332022"
      },
      { "name": "Fresh cut kiato pompon chrysanthemum", "code": "10332023" },
      { "name": "Fresh cut kiwi pompon chrysanthemum", "code": "10332024" },
      { "name": "Fresh cut madeira pompon chrysanthemum", "code": "10332025" },
      { "name": "Fresh cut magnet pompon chrysanthemum", "code": "10332026" },
      { "name": "Fresh cut marimo pompon chrysanthemum", "code": "10332027" },
      { "name": "Fresh cut matrix pompon chrysanthemum", "code": "10332028" },
      { "name": "Fresh cut miletta pompon chrysanthemum", "code": "10332029" },
      { "name": "Fresh cut monalisa pompon chrysanthemum", "code": "10332030" },
      { "name": "Fresh cut omaha pompon chrysanthemum", "code": "10332031" },
      {
        "name": "Fresh cut orinoco purple pompon chrysanthemum",
        "code": "10332032"
      },
      {
        "name": "Fresh cut orinoco yellow pompon chrysanthemum",
        "code": "10332033"
      },
      {
        "name": "Fresh cut pacific green pompon chrysanthemum",
        "code": "10332034"
      },
      {
        "name": "Fresh cut puma white pompon chrysanthemum",
        "code": "10332035"
      },
      {
        "name": "Fresh cut puma yellow pompon chrysanthemum",
        "code": "10332036"
      },
      {
        "name": "Fresh cut purple mundial pompon chrysanthemum",
        "code": "10332037"
      },
      { "name": "Fresh cut regatta pompon chrysanthemum", "code": "10332038" },
      { "name": "Fresh cut remco pompon chrysanthemum", "code": "10332039" },
      {
        "name": "Fresh cut royal mundial pompon chrysanthemum",
        "code": "10332040"
      },
      { "name": "Fresh cut sabrina pompon chrysanthemum", "code": "10332041" },
      {
        "name": "Fresh cut shakira white pompon chrysanthemum",
        "code": "10332042"
      },
      { "name": "Fresh cut sharp pompon chrysanthemum", "code": "10332043" },
      { "name": "Fresh cut shock pompon chrysanthemum", "code": "10332044" },
      {
        "name": "Fresh cut sizzle green pompon chrysanthemum",
        "code": "10332045"
      },
      {
        "name": "Fresh cut sizzle pink pompon chrysanthemum",
        "code": "10332046"
      },
      { "name": "Fresh cut sizzle pompon chrysanthemum", "code": "10332047" },
      {
        "name": "Fresh cut sizzle purple pompon chrysanthemum",
        "code": "10332048"
      },
      {
        "name": "Fresh cut sizzle salmon pompon chrysanthemum",
        "code": "10332049"
      },
      {
        "name": "Fresh cut sizzle yellow pompon chrysanthemum",
        "code": "10332050"
      },
      {
        "name": "Fresh cut spain flag pompon chrysanthemum",
        "code": "10332051"
      },
      {
        "name": "Fresh cut starburst or snowflake pompon chrysanthemum",
        "code": "10332052"
      },
      { "name": "Fresh cut swan pompon chrysanthemum", "code": "10332053" },
      {
        "name": "Fresh cut tedcha orange pompon chrysanthemum",
        "code": "10332054"
      },
      { "name": "Fresh cut tender pompon chrysanthemum", "code": "10332055" },
      { "name": "Fresh cut tinsel pompon chrysanthemum", "code": "10332056" },
      { "name": "Fresh cut touch pompon chrysanthemum", "code": "10332057" },
      { "name": "Fresh cut troyes pompon chrysanthemum", "code": "10332058" },
      { "name": "Fresh cut valesca pompon chrysanthemum", "code": "10332059" },
      {
        "name": "Fresh cut viking orange pompon chrysanthemum",
        "code": "10332060"
      },
      { "name": "Fresh cut viking pompon chrysanthemum", "code": "10332061" },
      { "name": "Fresh cut watch pompon chrysanthemum", "code": "10332062" },
      {
        "name": "Fresh cut white needle pompon chrysanthemum",
        "code": "10332063"
      },
      {
        "name": "Fresh cut white night pompon chrysanthemum",
        "code": "10332064"
      },
      {
        "name": "Fresh cut yellow artist pompon chrysanthemum",
        "code": "10332065"
      },
      {
        "name": "Fresh cut yellow fiction pompon chrysanthemum",
        "code": "10332066"
      },
      {
        "name": "Fresh cut yellow sharp pompon chrysanthemum",
        "code": "10332067"
      },
      { "name": "Fresh cut santini chrysanthemums", "code": "10332100" },
      { "name": "Fresh cut alma pompon chrysanthemum", "code": "10332101" },
      { "name": "Fresh cut baron pompon chrysanthemum", "code": "10332102" },
      { "name": "Fresh cut bernardo pompon chrysanthemum", "code": "10332103" },
      { "name": "Fresh cut bistro pompon chrysanthemum", "code": "10332104" },
      { "name": "Fresh cut bodega pompon chrysanthemum", "code": "10332105" },
      { "name": "Fresh cut breeze pompon chrysanthemum", "code": "10332106" },
      {
        "name": "Fresh cut bronze centella pompon chrysanthemum",
        "code": "10332107"
      },
      {
        "name": "Fresh cut costa white pompon chrysanthemum",
        "code": "10332108"
      },
      { "name": "Fresh cut creta pompon chrysanthemum", "code": "10332109" },
      {
        "name": "Fresh cut deliflame pompon chrysanthemum",
        "code": "10332110"
      },
      { "name": "Fresh cut delilah pompon chrysanthemum", "code": "10332111" },
      { "name": "Fresh cut digit pompon chrysanthemum", "code": "10332112" },
      { "name": "Fresh cut evilio pompon chrysanthemum", "code": "10332113" },
      { "name": "Fresh cut furense pompon chrysanthemum", "code": "10332114" },
      { "name": "Fresh cut guide pompon chrysanthemum", "code": "10332115" },
      { "name": "Fresh cut kerry pompon chrysanthemum", "code": "10332116" },
      { "name": "Fresh cut kess pompon chrysanthemum", "code": "10332117" },
      { "name": "Fresh cut lima pompon chrysanthemum", "code": "10332118" },
      { "name": "Fresh cut lupo pompon chrysanthemum", "code": "10332119" },
      {
        "name": "Fresh cut orange lineker pompon chrysanthemum",
        "code": "10332120"
      },
      {
        "name": "Fresh cut panuco red pompon chrysanthemum",
        "code": "10332121"
      },
      {
        "name": "Fresh cut pink costa pompon chrysanthemum",
        "code": "10332122"
      },
      { "name": "Fresh cut raphael pompon chrysanthemum", "code": "10332123" },
      { "name": "Fresh cut refine pompon chrysanthemum", "code": "10332124" },
      { "name": "Fresh cut regalis pompon chrysanthemum", "code": "10332125" },
      { "name": "Fresh cut renella pompon chrysanthemum", "code": "10332126" },
      { "name": "Fresh cut return pompon chrysanthemum", "code": "10332127" },
      { "name": "Fresh cut river pompon chrysanthemum", "code": "10332128" },
      { "name": "Fresh cut sabas pompon chrysanthemum", "code": "10332129" },
      { "name": "Fresh cut target pompon chrysanthemum", "code": "10332130" },
      { "name": "Fresh cut text pompon chrysanthemum", "code": "10332131" },
      {
        "name": "Fresh cut yellow stallion pompon chrysanthemum",
        "code": "10332132"
      },
      { "name": "Fresh cut floral bouquets", "code": "10340000" },
      { "name": "Fresh cut rose bouquets", "code": "10341500" },
      {
        "name": "Fresh cut blue or lavender or purple rose bouquet",
        "code": "10341501"
      },
      { "name": "Fresh cut carnation bouquets", "code": "10341600" },
      {
        "name": "Fresh cut burgundy bi color carnation bouquet",
        "code": "10341601"
      },
      { "name": "Fresh cut mini carnation bouquets", "code": "10341700" },
      {
        "name": "Fresh cut burgundy mini carnation bouquet",
        "code": "10341701"
      },
      { "name": "Fresh cut lily bouquets", "code": "10341800" },
      {
        "name": "Fresh cut asiatic black out lily bouquet",
        "code": "10341801"
      },
      { "name": "Fresh cut pompon chrysanthemum bouquets", "code": "10341900" },
      {
        "name": "Fresh cut daisy pompon chrysanthemum bouquet",
        "code": "10341901"
      },
      { "name": "Fresh cut alstroemeria bouquets", "code": "10342000" },
      { "name": "Fresh cut agropoli alstroemeria bouquet", "code": "10342001" },
      { "name": "Fresh cut tropical flower bouquets", "code": "10342100" },
      { "name": "Fresh cut dendrobium orchid bouquet", "code": "10342101" },
      { "name": "Fresh cut mixed floral bouquets", "code": "10342200" },
      { "name": "Fresh cut rose carnation peony bouquet", "code": "10342201" },
      { "name": "Fresh cut carnations", "code": "10350000" },
      { "name": "Fresh cut single bloom carnations", "code": "10351500" },
      {
        "name": "Fresh cut single bloom burgundy bi color carnation",
        "code": "10351501"
      },
      {
        "name": "Fresh cut single bloom burgundy carnation",
        "code": "10351502"
      },
      {
        "name": "Fresh cut single bloom cinderella carnation",
        "code": "10351503"
      },
      {
        "name": "Fresh cut single bloom cream bi color carnation",
        "code": "10351504"
      },
      { "name": "Fresh cut single bloom cream carnation", "code": "10351505" },
      {
        "name": "Fresh cut single bloom green or prado carnation",
        "code": "10351506"
      },
      {
        "name": "Fresh cut single bloom hot pink carnation",
        "code": "10351507"
      },
      {
        "name": "Fresh cut single bloom light green carnation",
        "code": "10351508"
      },
      {
        "name": "Fresh cut single bloom light pink carnation",
        "code": "10351509"
      },
      {
        "name": "Fresh cut single bloom orange bi color carnation",
        "code": "10351510"
      },
      { "name": "Fresh cut single bloom orange carnation", "code": "10351511" },
      { "name": "Fresh cut single bloom peach carnation", "code": "10351512" },
      {
        "name": "Fresh cut single bloom peppermint bi color carnation",
        "code": "10351513"
      },
      {
        "name": "Fresh cut single bloom pink bi color carnation",
        "code": "10351514"
      },
      { "name": "Fresh cut single bloom pink carnation", "code": "10351515" },
      {
        "name": "Fresh cut single bloom purple bi color carnation",
        "code": "10351516"
      },
      {
        "name": "Fresh cut single bloom red bi color carnation",
        "code": "10351517"
      },
      { "name": "Fresh cut single bloom red carnation", "code": "10351518" },
      { "name": "Fresh cut single bloom white carnation", "code": "10351519" },
      { "name": "Fresh cut single bloom yellow carnation", "code": "10351520" },
      { "name": "Fresh cut mini or spray carnations", "code": "10351600" },
      {
        "name": "Fresh cut burgundy mini or spray carnation",
        "code": "10351601"
      },
      { "name": "Fresh cut cream mini or spray carnation", "code": "10351602" },
      {
        "name": "Fresh cut hot pink mini or spray carnation",
        "code": "10351603"
      },
      {
        "name": "Fresh cut lavender mini or spray carnation",
        "code": "10351604"
      },
      {
        "name": "Fresh cut light pink mini or spray carnation",
        "code": "10351605"
      },
      {
        "name": "Fresh cut orange mini or spray carnation",
        "code": "10351606"
      },
      { "name": "Fresh cut peach mini or spray carnation", "code": "10351607" },
      {
        "name": "Fresh cut peppermint mini or spray carnation",
        "code": "10351608"
      },
      { "name": "Fresh cut pink mini or spray carnation", "code": "10351609" },
      {
        "name": "Fresh cut purple bi color mini or spray carnation",
        "code": "10351610"
      },
      {
        "name": "Fresh cut purple mini or spray carnation",
        "code": "10351611"
      },
      { "name": "Fresh cut red mini or spray carnation", "code": "10351612" },
      { "name": "Fresh cut white mini or spray carnation", "code": "10351613" },
      {
        "name": "Fresh cut yellow mini or spray carnation",
        "code": "10351614"
      },
      { "name": "Fresh cut orchids", "code": "10360000" },
      {
        "name": "Fresh cut cypripedium or ladys slipper orchids",
        "code": "10361500"
      },
      { "name": "Fresh cut green cypripedium orchid", "code": "10361501" },
      { "name": "Fresh cut france cypripedium orchid", "code": "10361502" },
      {
        "name": "Fresh cut purple king arthur cypripedium orchid",
        "code": "10361503"
      },
      { "name": "Fresh cut green paphiopedilum orchid", "code": "10361504" },
      { "name": "Fresh cut aranthera maggie vie orchid", "code": "10361505" },
      { "name": "Fresh cut mokara or mocara orchids", "code": "10361600" },
      { "name": "Fresh cut mocara omyai orchid", "code": "10361601" },
      { "name": "Fresh cut mocara red orchid", "code": "10361602" },
      { "name": "Fresh cut mokara calypso orchid", "code": "10361603" },
      { "name": "Fresh cut mokara nora orchid", "code": "10361604" },
      { "name": "Fresh cut mokara panee orchid", "code": "10361605" },
      { "name": "Fresh cut cattleya orchids", "code": "10361700" },
      { "name": "Fresh cut white cattleya orchid", "code": "10361701" },
      { "name": "Fresh cut r b lavender cattleya orchid", "code": "10361702" },
      { "name": "Fresh cut disa orchids", "code": "10361800" },
      { "name": "Fresh cut red disa orchid", "code": "10361801" },
      { "name": "Fresh cut orange disa orchid", "code": "10361802" },
      { "name": "Fresh cut pink disa orchid", "code": "10361803" },
      {
        "name": "Fresh cut orange and yellow bi color disa orchid",
        "code": "10361804"
      },
      {
        "name": "Fresh cut peach and yellow bi color disa orchid",
        "code": "10361805"
      },
      {
        "name": "Fresh cut yellow and red bi color disa orchid",
        "code": "10361806"
      },
      { "name": "Fresh cut arachnis orchids", "code": "10361900" },
      {
        "name": "Fresh cut james storie red arachnis orchid",
        "code": "10361901"
      },
      {
        "name": "Fresh cut maggie oei red ribbon arachnis orchid",
        "code": "10361902"
      },
      {
        "name": "Fresh cut maggie oei yellow ribbon arachnis orchid",
        "code": "10361903"
      },
      { "name": "Fresh cut maroon maggie arachnis orchid", "code": "10361904" },
      { "name": "Fresh cut merry maggie arachnis orchid", "code": "10361905" },
      { "name": "Fresh cut phalaenopsis orchids", "code": "10362000" },
      { "name": "Fresh cut phalaenopsis amabilis orchid", "code": "10362001" },
      {
        "name": "Fresh cut phalaenopsis amboinensis orchid",
        "code": "10362002"
      },
      { "name": "Fresh cut phalaenopsis aphrodite orchid", "code": "10362003" },
      {
        "name": "Fresh cut phalaenopsis appendiculata orchid",
        "code": "10362004"
      },
      { "name": "Fresh cut phalaenopsis bastianii orchid", "code": "10362005" },
      { "name": "Fresh cut phalaenopsis bellina orchid", "code": "10362006" },
      {
        "name": "Fresh cut phalaenopsis borneensis orchid",
        "code": "10362007"
      },
      { "name": "Fresh cut phalaenopsis braceana orchid", "code": "10362008" },
      {
        "name": "Fresh cut phalaenopsis buyssoniana orchid",
        "code": "10362009"
      },
      {
        "name": "Fresh cut phalaenopsis celebensis orchid",
        "code": "10362010"
      },
      { "name": "Fresh cut phalaenopsis chibae orchid", "code": "10362011" },
      {
        "name": "Fresh cut phalaenopsis cochlearis orchid",
        "code": "10362012"
      },
      {
        "name": "Fresh cut phalaenopsis corningiana orchid",
        "code": "10362013"
      },
      {
        "name": "Fresh cut phalaenopsis cornu-cervi orchid",
        "code": "10362014"
      },
      { "name": "Fresh cut phalaenopsis deliciosa orchid", "code": "10362015" },
      {
        "name": "Fresh cut phalaenopsis dowery\u00ebnsis orchid",
        "code": "10362016"
      },
      { "name": "Fresh cut phalaenopsis equestris orchid", "code": "10362017" },
      { "name": "Fresh cut phalaenopsis fasciata orchid", "code": "10362018" },
      { "name": "Fresh cut phalaenopsis fimbriata orchid", "code": "10362019" },
      {
        "name": "Fresh cut phalaenopsis floresensis orchid",
        "code": "10362020"
      },
      { "name": "Fresh cut phalaenopsis fuscata orchid", "code": "10362021" },
      { "name": "Fresh cut phalaenopsis gibbosa orchid", "code": "10362022" },
      {
        "name": "Fresh cut phalaenopsis hainanensis orchid",
        "code": "10362023"
      },
      {
        "name": "Fresh cut phalaenopsis hieroglyphica orchid",
        "code": "10362024"
      },
      {
        "name": "Fresh cut phalaenopsis honghenensis orchid",
        "code": "10362025"
      },
      {
        "name": "Fresh cut phalaenopsis inscriptiosinensis orchid",
        "code": "10362026"
      },
      { "name": "Fresh cut phalaenopsis javanica orchid", "code": "10362027" },
      { "name": "Fresh cut phalaenopsis kunstleri orchid", "code": "10362028" },
      {
        "name": "Fresh cut phalaenopsis lamelligera orchid",
        "code": "10362029"
      },
      { "name": "Fresh cut phalaenopsis lindenii orchid", "code": "10362030" },
      { "name": "Fresh cut phalaenopsis lobbii orchid", "code": "10362031" },
      { "name": "Fresh cut phalaenopsis lowii orchid", "code": "10362032" },
      {
        "name": "Fresh cut phalaenopsis lueddemanniana orchid",
        "code": "10362033"
      },
      { "name": "Fresh cut phalaenopsis mambo orchid", "code": "10362034" },
      { "name": "Fresh cut phalaenopsis luteola orchid", "code": "10362035" },
      { "name": "Fresh cut phalaenopsis maculata orchid", "code": "10362036" },
      {
        "name": "Fresh cut phalaenopsis malipoensis orchid",
        "code": "10362037"
      },
      { "name": "Fresh cut phalaenopsis mannii orchid", "code": "10362038" },
      { "name": "Fresh cut phalaenopsis mariae orchid", "code": "10362039" },
      {
        "name": "Fresh cut phalaenopsis micholitzii orchid",
        "code": "10362040"
      },
      { "name": "Fresh cut phalaenopsis modesta orchid", "code": "10362041" },
      {
        "name": "Fresh cut phalaenopsis mysorensis orchid",
        "code": "10362042"
      },
      { "name": "Fresh cut phalaenopsis pallens orchid", "code": "10362043" },
      {
        "name": "Fresh cut phalaenopsis pantherina orchid",
        "code": "10362044"
      },
      { "name": "Fresh cut phalaenopsis parishii orchid", "code": "10362045" },
      { "name": "Fresh cut phalaenopsis petelotii orchid", "code": "10362046" },
      {
        "name": "Fresh cut phalaenopsis philippinensis orchid",
        "code": "10362047"
      },
      {
        "name": "Fresh cut phalaenopsis pulcherrima orchid",
        "code": "10362048"
      },
      { "name": "Fresh cut phalaenopsis pulchra orchid", "code": "10362049" },
      {
        "name": "Fresh cut phalaenopsis regnieriana orchid",
        "code": "10362050"
      },
      {
        "name": "Fresh cut phalaenopsis reichenbachiana orchid",
        "code": "10362051"
      },
      { "name": "Fresh cut phalaenopsis Nivacolor orchid", "code": "10362052" },
      {
        "name": "Fresh cut phalaenopsis sanderiana orchid",
        "code": "10362053"
      },
      {
        "name": "Fresh cut phalaenopsis schilleriana orchid",
        "code": "10362054"
      },
      { "name": "Fresh cut phalaenopsis speciosa orchid", "code": "10362055" },
      {
        "name": "Fresh cut phalaenopsis stobartiana orchid",
        "code": "10362056"
      },
      {
        "name": "Fresh cut phalaenopsis stuartiana orchid",
        "code": "10362057"
      },
      { "name": "Fresh cut phalaenopsis sumatrana orchid", "code": "10362058" },
      { "name": "Fresh cut phalaenopsis taenialis orchid", "code": "10362059" },
      { "name": "Fresh cut phalaenopsis tetraspis orchid", "code": "10362060" },
      { "name": "Fresh cut phalaenopsis venosa orchid", "code": "10362061" },
      { "name": "Fresh cut phalaenopsis violacea orchid", "code": "10362062" },
      { "name": "Fresh cut phalaenopsis viridis orchid", "code": "10362063" },
      { "name": "Fresh cut phalaenopsis wilsonii orchid", "code": "10362064" },
      { "name": "Fresh cut phalaenopsis zebrina orchid", "code": "10362065" },
      {
        "name": "Fresh cut lavender lip phalaenopsis orchid",
        "code": "10362067"
      },
      { "name": "Fresh cut dendrobium orchids", "code": "10362100" },
      { "name": "Fresh cut bom dendrobium orchid", "code": "10362101" },
      { "name": "Fresh cut burana jade dendrobium orchid", "code": "10362102" },
      { "name": "Fresh cut cheetah dendrobium orchid", "code": "10362103" },
      { "name": "Fresh cut fatima dendrobium orchid", "code": "10362104" },
      { "name": "Fresh cut intuwong dendrobium orchid", "code": "10362105" },
      { "name": "Fresh cut jumbo white dendrobium orchid", "code": "10362106" },
      { "name": "Fresh cut kating dang dendrobium orchid", "code": "10362107" },
      { "name": "Fresh cut liberty dendrobium orchid", "code": "10362108" },
      {
        "name": "Fresh cut orchid hawaii dendrobium orchid",
        "code": "10362109"
      },
      {
        "name": "Fresh cut sakura sweet pink dendrobium orchid",
        "code": "10362110"
      },
      {
        "name": "Fresh cut sensational purple dendrobium orchid",
        "code": "10362111"
      },
      { "name": "Fresh cut white dendrobium orchid", "code": "10362112" },
      { "name": "Fresh cut cymbidium orchids", "code": "10362200" },
      { "name": "Fresh cut cream cymbidium orchid", "code": "10362201" },
      { "name": "Fresh cut green cymbidium orchid", "code": "10362202" },
      { "name": "Fresh cut mini green cymbidium orchid", "code": "10362203" },
      { "name": "Fresh cut mini pink cymbidium orchid", "code": "10362204" },
      { "name": "Fresh cut mini red cymbidium orchid", "code": "10362205" },
      { "name": "Fresh cut mini white cymbidium orchid", "code": "10362206" },
      { "name": "Fresh cut mini yellow cymbidium orchid", "code": "10362207" },
      { "name": "Fresh cut chocolate cymbidium orchid", "code": "10362208" },
      { "name": "Fresh cut dark pink cymbidium orchid", "code": "10362209" },
      { "name": "Fresh cut orange cymbidium orchid", "code": "10362210" },
      { "name": "Fresh cut pink cymbidium orchid", "code": "10362211" },
      { "name": "Fresh cut white cymbidium orchid", "code": "10362212" },
      { "name": "Fresh cut yellow cymbidium orchid", "code": "10362213" },
      { "name": "Fresh cut oncidium orchids", "code": "10362300" },
      { "name": "Fresh cut golden shower oncidium orchid", "code": "10362301" },
      { "name": "Fresh cut rhamsey oncidium orchid", "code": "10362302" },
      { "name": "Fresh cut vanda orchids", "code": "10362400" },
      { "name": "Fresh cut alizarin vanda orchid", "code": "10362401" },
      { "name": "Fresh cut hot pink vanda orchid", "code": "10362402" },
      { "name": "Fresh cut lavender vanda orchid", "code": "10362403" },
      { "name": "Fresh cut purple vanda orchid", "code": "10362404" },
      { "name": "Fresh cut tickle me pink vanda orchid", "code": "10362405" },
      { "name": "Fresh cut yellow vanda orchid", "code": "10362406" },
      { "name": "Dried cut roses", "code": "10400000" },
      {
        "name": "Dried cut blue or lavender or purple rose",
        "code": "10401500"
      },
      { "name": "Dried cut allure or sterling 95 rose", "code": "10401501" },
      { "name": "Dried cut amnesia rose", "code": "10401502" },
      { "name": "Dried cut augusta louise rose", "code": "10401503" },
      { "name": "Dried cut avant garde rose", "code": "10401504" },
      { "name": "Dried cut blue bird rose", "code": "10401505" },
      { "name": "Dried cut blue curiosa rose", "code": "10401506" },
      { "name": "Dried cut cool water rose", "code": "10401507" },
      { "name": "Dried cut delilah rose", "code": "10401508" },
      { "name": "Dried cut double party rose", "code": "10401509" },
      { "name": "Dried cut faith rose", "code": "10401510" },
      { "name": "Dried cut mami blue or mammy blue rose", "code": "10401511" },
      { "name": "Dried cut maritime rose", "code": "10401512" },
      { "name": "Dried cut milano rose", "code": "10401513" },
      { "name": "Dried cut mystery rose", "code": "10401514" },
      { "name": "Dried cut ocean song or boyfriend rose", "code": "10401515" },
      { "name": "Dried cut purple cezanne rose", "code": "10401516" },
      { "name": "Dried cut purple fragrance rose", "code": "10401517" },
      { "name": "Dried cut sanaa rose", "code": "10401518" },
      { "name": "Dried cut silverstone rose", "code": "10401519" },
      { "name": "Dried cut soulmate rose", "code": "10401520" },
      { "name": "Dried cut stranger rose", "code": "10401521" },
      { "name": "Dried cut tinted blue rose", "code": "10401522" },
      { "name": "Dried cut two faces rose", "code": "10401523" },
      { "name": "Dried cut chocolate or brown rose", "code": "10401600" },
      { "name": "Dried cut black lava rose", "code": "10401601" },
      { "name": "Dried cut cimarron rose", "code": "10401602" },
      { "name": "Dried cut coffee break rose", "code": "10401603" },
      { "name": "Dried cut estelle rose", "code": "10401604" },
      { "name": "Dried cut gypsy leonidas rose", "code": "10401605" },
      { "name": "Dried cut leonidas rose", "code": "10401606" },
      { "name": "Dried cut matilda rose", "code": "10401607" },
      { "name": "Dried cut sunny leonidas rose", "code": "10401608" },
      { "name": "Dried cut terra nostra rose", "code": "10401609" },
      { "name": "Dried cut terracotta rose", "code": "10401610" },
      { "name": "Dried cut cream rose", "code": "10401700" },
      { "name": "Dried cut advenire rose", "code": "10401701" },
      { "name": "Dried cut alex rose", "code": "10401702" },
      { "name": "Dried cut antique brass rose", "code": "10401703" },
      { "name": "Dried cut aubade rose", "code": "10401704" },
      { "name": "Dried cut beach rose", "code": "10401705" },
      { "name": "Dried cut belle pearl rose", "code": "10401706" },
      {
        "name": "Dried cut blush or blush de los andesrose",
        "code": "10401707"
      },
      { "name": "Dried cut camel rose", "code": "10401708" },
      {
        "name": "Dried cut caramel antike or caramel antique rose",
        "code": "10401709"
      },
      { "name": "Dried cut champagne rose", "code": "10401710" },
      { "name": "Dried cut clear ocean rose", "code": "10401711" },
      { "name": "Dried cut combo rose", "code": "10401712" },
      { "name": "Dried cut creme de la creme rose", "code": "10401713" },
      { "name": "Dried cut emanuella rose", "code": "10401714" },
      { "name": "Dried cut evolution rose", "code": "10401715" },
      { "name": "Dried cut fedora rose", "code": "10401716" },
      { "name": "Dried cut fenice rose", "code": "10401717" },
      { "name": "Dried cut french vanilla rose", "code": "10401718" },
      { "name": "Dried cut hollywood rose", "code": "10401719" },
      { "name": "Dried cut ilios rose", "code": "10401720" },
      { "name": "Dried cut jelena rose", "code": "10401721" },
      { "name": "Dried cut kameleon rose", "code": "10401722" },
      { "name": "Dried cut kentucky rose", "code": "10401723" },
      { "name": "Dried cut kings pride rose", "code": "10401724" },
      { "name": "Dried cut latin fusion rose", "code": "10401725" },
      { "name": "Dried cut lemon dream rose", "code": "10401726" },
      { "name": "Dried cut magic moka rose", "code": "10401727" },
      { "name": "Dried cut mamamia rose", "code": "10401728" },
      { "name": "Dried cut message rose", "code": "10401729" },
      { "name": "Dried cut muneca or munieca rose", "code": "10401730" },
      { "name": "Dried cut parfum de rosas rose", "code": "10401731" },
      { "name": "Dried cut porcelina rose", "code": "10401732" },
      { "name": "Dried cut privilege rose", "code": "10401733" },
      { "name": "Dried cut quicksand rose", "code": "10401734" },
      { "name": "Dried cut rollercoaster rose", "code": "10401735" },
      { "name": "Dried cut romantic curiosa rose", "code": "10401736" },
      { "name": "Dried cut safari rose", "code": "10401737" },
      { "name": "Dried cut sahara rose", "code": "10401738" },
      { "name": "Dried cut sandy femma rose", "code": "10401739" },
      { "name": "Dried cut talea rose", "code": "10401740" },
      { "name": "Dried cut timeless rose", "code": "10401741" },
      { "name": "Dried cut transition rose", "code": "10401742" },
      { "name": "Dried cut trump rose", "code": "10401743" },
      { "name": "Dried cut twin rose", "code": "10401744" },
      { "name": "Dried cut vendela rose", "code": "10401745" },
      { "name": "Dried cut virginia rose", "code": "10401746" },
      { "name": "Dried cut green or lime rose", "code": "10401800" },
      { "name": "Dried cut amandine rose", "code": "10401801" },
      { "name": "Dried cut caipirinha rose", "code": "10401802" },
      { "name": "Dried cut green fashion rose", "code": "10401803" },
      { "name": "Dried cut green tea rose", "code": "10401804" },
      { "name": "Dried cut jade rose", "code": "10401805" },
      { "name": "Dried cut limbo rose", "code": "10401806" },
      { "name": "Dried cut limena or limenia rose", "code": "10401807" },
      { "name": "Dried cut limona rose", "code": "10401808" },
      { "name": "Dried cut old dutch rose", "code": "10401809" },
      { "name": "Dried cut super green rose", "code": "10401810" },
      { "name": "Dried cut sweet green rose", "code": "10401811" },
      { "name": "Dried cut viva rose", "code": "10401812" },
      { "name": "Dried cut zazu rose", "code": "10401813" },
      { "name": "Dried cut light pink rose", "code": "10401900" },
      { "name": "Dried cut anna rose", "code": "10401901" },
      { "name": "Dried cut bella vita rose", "code": "10401902" },
      { "name": "Dried cut bridal dream rose", "code": "10401903" },
      { "name": "Dried cut candy bianca rose", "code": "10401904" },
      { "name": "Dried cut caress rose", "code": "10401905" },
      { "name": "Dried cut carolina rose", "code": "10401906" },
      { "name": "Dried cut climax rose", "code": "10401907" },
      { "name": "Dried cut danny rose", "code": "10401908" },
      { "name": "Dried cut dolce vita rose", "code": "10401909" },
      { "name": "Dried cut elite rose", "code": "10401910" },
      { "name": "Dried cut emma rose", "code": "10401911" },
      { "name": "Dried cut engagement rose", "code": "10401912" },
      { "name": "Dried cut esther rose", "code": "10401913" },
      { "name": "Dried cut excalibur rose", "code": "10401914" },
      { "name": "Dried cut exciting rose", "code": "10401915" },
      { "name": "Dried cut first lady rose", "code": "10401916" },
      { "name": "Dried cut geraldine rose", "code": "10401917" },
      { "name": "Dried cut gotcha rose", "code": "10401918" },
      { "name": "Dried cut harmonie rose", "code": "10401919" },
      { "name": "Dried cut heaven rose", "code": "10401920" },
      { "name": "Dried cut high and elegant rose", "code": "10401921" },
      { "name": "Dried cut katherine rose", "code": "10401922" },
      { "name": "Dried cut king kong rose", "code": "10401923" },
      { "name": "Dried cut livia rose", "code": "10401924" },
      { "name": "Dried cut lorena rose", "code": "10401925" },
      { "name": "Dried cut lovely amazon rose", "code": "10401926" },
      { "name": "Dried cut maaike rose", "code": "10401927" },
      { "name": "Dried cut marilyn rose", "code": "10401928" },
      { "name": "Dried cut marlise rose", "code": "10401929" },
      { "name": "Dried cut miranda or ausimmon rose", "code": "10401930" },
      { "name": "Dried cut mona lisa rose", "code": "10401931" },
      { "name": "Dried cut nirvana rose", "code": "10401932" },
      { "name": "Dried cut o hara rose", "code": "10401933" },
      { "name": "Dried cut ole rose", "code": "10401934" },
      { "name": "Dried cut olga rose", "code": "10401935" },
      { "name": "Dried cut pacifica rose", "code": "10401936" },
      { "name": "Dried cut party mix rose", "code": "10401937" },
      { "name": "Dried cut peckoubo or pekcoubo rose", "code": "10401938" },
      { "name": "Dried cut phoebe or ausnotice rose", "code": "10401939" },
      { "name": "Dried cut pink farfalla rose", "code": "10401940" },
      { "name": "Dried cut pink finess rose", "code": "10401941" },
      { "name": "Dried cut pink magic rose", "code": "10401942" },
      { "name": "Dried cut pink osiana rose", "code": "10401943" },
      { "name": "Dried cut pretty woman rose", "code": "10401944" },
      { "name": "Dried cut romance rose", "code": "10401945" },
      {
        "name": "Dried cut romantic antike or antique rose",
        "code": "10401946"
      },
      { "name": "Dried cut rosalind or austew rose", "code": "10401947" },
      { "name": "Dried cut rosita vendela rose", "code": "10401948" },
      { "name": "Dried cut secret garden rose", "code": "10401949" },
      { "name": "Dried cut solaire rose", "code": "10401950" },
      { "name": "Dried cut sophie rose", "code": "10401951" },
      { "name": "Dried cut sweet akito rose", "code": "10401952" },
      { "name": "Dried cut sweet avalanche rose", "code": "10401953" },
      { "name": "Dried cut sweet elegance rose", "code": "10401954" },
      { "name": "Dried cut sweet pink rose", "code": "10401955" },
      { "name": "Dried cut titanic rose", "code": "10401956" },
      { "name": "Dried cut toscanini rose", "code": "10401957" },
      { "name": "Dried cut vania rose", "code": "10401958" },
      { "name": "Dried cut vanity rose", "code": "10401959" },
      { "name": "Dried cut vision rose", "code": "10401960" },
      { "name": "Dried cut vivaldi rose", "code": "10401961" },
      { "name": "Dried cut whisper rose", "code": "10401962" },
      { "name": "Dried cut multi-colored pink rose", "code": "10402000" },
      { "name": "Dried cut attracta rose", "code": "10402001" },
      { "name": "Dried cut boheme rose", "code": "10402002" },
      { "name": "Dried cut carousel rose", "code": "10402003" },
      { "name": "Dried cut cezanne rose", "code": "10402004" },
      { "name": "Dried cut crazy one rose", "code": "10402005" },
      { "name": "Dried cut dance valley rose", "code": "10402006" },
      { "name": "Dried cut duett rose", "code": "10402007" },
      { "name": "Dried cut esperance rose", "code": "10402008" },
      { "name": "Dried cut fiesta rose", "code": "10402009" },
      { "name": "Dried cut halloween rose", "code": "10402010" },
      { "name": "Dried cut highlander rose", "code": "10402011" },
      { "name": "Dried cut hot ambiance rose", "code": "10402012" },
      { "name": "Dried cut la belle rose", "code": "10402013" },
      { "name": "Dried cut laguna rose", "code": "10402014" },
      { "name": "Dried cut latin ambiance rose", "code": "10402015" },
      { "name": "Dried cut latin breeze rose", "code": "10402016" },
      { "name": "Dried cut long arifa rose", "code": "10402017" },
      { "name": "Dried cut murano rose", "code": "10402018" },
      { "name": "Dried cut n-joy rose", "code": "10402019" },
      { "name": "Dried cut panama rose", "code": "10402020" },
      { "name": "Dried cut peppermint rose", "code": "10402021" },
      { "name": "Dried cut pijama party rose", "code": "10402022" },
      { "name": "Dried cut portofino rose", "code": "10402023" },
      { "name": "Dried cut priceless rose", "code": "10402024" },
      { "name": "Dried cut queen amazon rose", "code": "10402025" },
      { "name": "Dried cut ranuncula rose", "code": "10402026" },
      { "name": "Dried cut rossini rose", "code": "10402027" },
      { "name": "Dried cut sabina or sabrina rose", "code": "10402028" },
      { "name": "Dried cut scandal rose", "code": "10402029" },
      { "name": "Dried cut silvery pink rose", "code": "10402030" },
      { "name": "Dried cut something else rose", "code": "10402031" },
      { "name": "Dried cut soutine rose", "code": "10402032" },
      { "name": "Dried cut sovereign rose", "code": "10402033" },
      { "name": "Dried cut super disco rose", "code": "10402034" },
      { "name": "Dried cut ts 1968 rose", "code": "10402035" },
      { "name": "Dried cut variance rose", "code": "10402036" },
      { "name": "Dried cut verdi rose", "code": "10402037" },
      { "name": "Dried cut orange rose", "code": "10402100" },
      { "name": "Dried cut alhambra rose", "code": "10402101" },
      { "name": "Dried cut aloha rose", "code": "10402102" },
      { "name": "Dried cut amber rose", "code": "10402103" },
      { "name": "Dried cut apache rose", "code": "10402104" },
      { "name": "Dried cut arabia rose", "code": "10402105" },
      { "name": "Dried cut bengala rose", "code": "10402106" },
      { "name": "Dried cut bibi rose", "code": "10402107" },
      { "name": "Dried cut caramba rose", "code": "10402108" },
      { "name": "Dried cut caramella rose", "code": "10402109" },
      { "name": "Dried cut carla rose", "code": "10402110" },
      { "name": "Dried cut cartagena rose", "code": "10402111" },
      { "name": "Dried cut chanson rose", "code": "10402112" },
      { "name": "Dried cut charmer rose", "code": "10402113" },
      { "name": "Dried cut cherry brandy rose", "code": "10402114" },
      { "name": "Dried cut chilis rose", "code": "10402115" },
      { "name": "Dried cut cinnamon rose", "code": "10402116" },
      { "name": "Dried cut colandro rose", "code": "10402117" },
      { "name": "Dried cut coral sea rose", "code": "10402118" },
      { "name": "Dried cut corvette or red corvette rose", "code": "10402119" },
      { "name": "Dried cut dark milva rose", "code": "10402120" },
      { "name": "Dried cut donna rose", "code": "10402121" },
      { "name": "Dried cut dreamer rose", "code": "10402122" },
      { "name": "Dried cut el dorado rose", "code": "10402123" },
      { "name": "Dried cut el toro rose", "code": "10402124" },
      { "name": "Dried cut elena rose", "code": "10402125" },
      { "name": "Dried cut ensueno rose", "code": "10402126" },
      { "name": "Dried cut euforia rose", "code": "10402127" },
      { "name": "Dried cut exotica rose", "code": "10402128" },
      { "name": "Dried cut fancy amazon rose", "code": "10402129" },
      { "name": "Dried cut fiction rose", "code": "10402130" },
      { "name": "Dried cut finess rose", "code": "10402131" },
      { "name": "Dried cut flamenco rose", "code": "10402132" },
      { "name": "Dried cut free spirit rose", "code": "10402133" },
      { "name": "Dried cut gelato rose", "code": "10402134" },
      { "name": "Dried cut gypsy curiosa rose", "code": "10402135" },
      { "name": "Dried cut high and magic rose", "code": "10402136" },
      { "name": "Dried cut high and orange magic rose", "code": "10402137" },
      { "name": "Dried cut iguana or alegra rose", "code": "10402138" },
      { "name": "Dried cut impulse rose", "code": "10402139" },
      { "name": "Dried cut indian femma rose", "code": "10402140" },
      { "name": "Dried cut indian sunset rose", "code": "10402141" },
      { "name": "Dried cut karusso rose", "code": "10402142" },
      { "name": "Dried cut kerio rose", "code": "10402143" },
      { "name": "Dried cut kiki rose", "code": "10402144" },
      { "name": "Dried cut latin circus rose", "code": "10402145" },
      { "name": "Dried cut leonisa rose", "code": "10402146" },
      { "name": "Dried cut lipstick rose", "code": "10402147" },
      { "name": "Dried cut lobita rose", "code": "10402148" },
      { "name": "Dried cut luca rose", "code": "10402149" },
      { "name": "Dried cut manitou rose", "code": "10402150" },
      { "name": "Dried cut mariana rose", "code": "10402151" },
      { "name": "Dried cut marjan or pk sensation rose", "code": "10402152" },
      { "name": "Dried cut milonga rose", "code": "10402153" },
      { "name": "Dried cut milva rose", "code": "10402154" },
      { "name": "Dried cut miracle rose", "code": "10402155" },
      { "name": "Dried cut mirage rose", "code": "10402156" },
      { "name": "Dried cut monte carlo rose", "code": "10402157" },
      { "name": "Dried cut movie star rose", "code": "10402158" },
      { "name": "Dried cut nikita rose", "code": "10402159" },
      { "name": "Dried cut orange flame rose", "code": "10402160" },
      { "name": "Dried cut orange france rose", "code": "10402161" },
      { "name": "Dried cut orange intuition rose", "code": "10402162" },
      { "name": "Dried cut orange unique rose", "code": "10402163" },
      { "name": "Dried cut orangine or orangina rose", "code": "10402164" },
      { "name": "Dried cut papaya rose", "code": "10402165" },
      { "name": "Dried cut pareo rose", "code": "10402166" },
      { "name": "Dried cut peach sherbet rose", "code": "10402167" },
      { "name": "Dried cut queensday rose", "code": "10402168" },
      { "name": "Dried cut rosselle rose", "code": "10402169" },
      { "name": "Dried cut royal circus rose", "code": "10402170" },
      { "name": "Dried cut sari rose", "code": "10402171" },
      { "name": "Dried cut sensual rose", "code": "10402172" },
      { "name": "Dried cut soap rose", "code": "10402173" },
      { "name": "Dried cut sombrero rose", "code": "10402174" },
      { "name": "Dried cut spicy rose", "code": "10402175" },
      { "name": "Dried cut star 2000 rose", "code": "10402176" },
      { "name": "Dried cut summer versilia rose", "code": "10402177" },
      { "name": "Dried cut trixx rose", "code": "10402178" },
      { "name": "Dried cut tropical amazon rose", "code": "10402179" },
      { "name": "Dried cut utopia rose", "code": "10402180" },
      { "name": "Dried cut valentine rose", "code": "10402181" },
      { "name": "Dried cut verano rose", "code": "10402182" },
      { "name": "Dried cut versilia rose", "code": "10402183" },
      { "name": "Dried cut voodoo rose", "code": "10402184" },
      { "name": "Dried cut wow rose", "code": "10402185" },
      { "name": "Dried cut yabadabadoo rose", "code": "10402186" },
      { "name": "Dried cut peach rose", "code": "10402200" },
      { "name": "Dried cut alejandra rose", "code": "10402201" },
      { "name": "Dried cut azafran rose", "code": "10402202" },
      { "name": "Dried cut big fun rose", "code": "10402203" },
      { "name": "Dried cut cabaret rose", "code": "10402204" },
      { "name": "Dried cut capuccino rose", "code": "10402205" },
      { "name": "Dried cut carpe diem rose", "code": "10402206" },
      { "name": "Dried cut cosima rose", "code": "10402207" },
      { "name": "Dried cut cumbia rose", "code": "10402208" },
      { "name": "Dried cut dream rose", "code": "10402209" },
      { "name": "Dried cut epoca rose", "code": "10402210" },
      { "name": "Dried cut fado rose", "code": "10402211" },
      { "name": "Dried cut femma rose", "code": "10402212" },
      { "name": "Dried cut guajira rose", "code": "10402213" },
      { "name": "Dried cut high and arena rose", "code": "10402214" },
      { "name": "Dried cut high and dandy rose", "code": "10402215" },
      { "name": "Dried cut high and lucky rose", "code": "10402216" },
      { "name": "Dried cut high and peach rose", "code": "10402217" },
      { "name": "Dried cut imagination rose", "code": "10402218" },
      { "name": "Dried cut isis rose", "code": "10402219" },
      { "name": "Dried cut joy or light versilia rose", "code": "10402220" },
      { "name": "Dried cut juliet ausjameson rose", "code": "10402221" },
      { "name": "Dried cut la parisienne rose", "code": "10402222" },
      { "name": "Dried cut la perla rose", "code": "10402223" },
      { "name": "Dried cut lovita sunblaze rose", "code": "10402224" },
      { "name": "Dried cut malilena or marilena rose", "code": "10402225" },
      { "name": "Dried cut monyna rose", "code": "10402226" },
      { "name": "Dried cut nectarine rose", "code": "10402227" },
      { "name": "Dried cut oriental curiosa rose", "code": "10402228" },
      { "name": "Dried cut osiana rose", "code": "10402229" },
      { "name": "Dried cut peach avalanche rose", "code": "10402230" },
      { "name": "Dried cut peach deja vu rose", "code": "10402231" },
      { "name": "Dried cut picanto rose", "code": "10402232" },
      { "name": "Dried cut prima donna rose", "code": "10402233" },
      { "name": "Dried cut sheril rose", "code": "10402234" },
      { "name": "Dried cut sirocco rose", "code": "10402235" },
      { "name": "Dried cut tamara rose", "code": "10402236" },
      { "name": "Dried cut taxo rose", "code": "10402237" },
      { "name": "Dried cut trust rose", "code": "10402238" },
      { "name": "Dried cut valencia rose", "code": "10402239" },
      { "name": "Dried cut vinci rose", "code": "10402240" },
      { "name": "Dried cut wanda rose", "code": "10402241" },
      { "name": "Dried cut pink rose", "code": "10402300" },
      { "name": "Dried cut aerobic rose", "code": "10402301" },
      { "name": "Dried cut after party rose", "code": "10402302" },
      { "name": "Dried cut amsterdam rose", "code": "10402303" },
      { "name": "Dried cut aqua rose", "code": "10402304" },
      { "name": "Dried cut attache rose", "code": "10402305" },
      { "name": "Dried cut attitude rose", "code": "10402306" },
      { "name": "Dried cut ballet rose", "code": "10402307" },
      { "name": "Dried cut belami rose", "code": "10402308" },
      { "name": "Dried cut bella voo or belle vue rose", "code": "10402309" },
      { "name": "Dried cut bling bling rose", "code": "10402310" },
      { "name": "Dried cut blushing akito rose", "code": "10402311" },
      { "name": "Dried cut brooke rose", "code": "10402312" },
      { "name": "Dried cut bugatti rose", "code": "10402313" },
      { "name": "Dried cut cadillac rose", "code": "10402314" },
      { "name": "Dried cut carnaval rose", "code": "10402315" },
      { "name": "Dried cut cereza rose", "code": "10402316" },
      { "name": "Dried cut charming unique rose", "code": "10402317" },
      { "name": "Dried cut cherry o rose", "code": "10402318" },
      { "name": "Dried cut ciciolina rose", "code": "10402319" },
      { "name": "Dried cut classic cezanne rose", "code": "10402320" },
      { "name": "Dried cut classic duett rose", "code": "10402321" },
      { "name": "Dried cut cosmiq rose", "code": "10402322" },
      { "name": "Dried cut dark engagement rose", "code": "10402323" },
      { "name": "Dried cut daytona rose", "code": "10402324" },
      { "name": "Dried cut dekora rose", "code": "10402325" },
      { "name": "Dried cut dolores rose", "code": "10402326" },
      { "name": "Dried cut eliza rose", "code": "10402327" },
      { "name": "Dried cut flash baccara rose", "code": "10402328" },
      { "name": "Dried cut full house rose", "code": "10402329" },
      { "name": "Dried cut funky rose", "code": "10402330" },
      { "name": "Dried cut giliane rose", "code": "10402331" },
      { "name": "Dried cut gran europe rose", "code": "10402332" },
      { "name": "Dried cut habari rose", "code": "10402333" },
      { "name": "Dried cut hanseat rose", "code": "10402334" },
      { "name": "Dried cut high and amazing rose", "code": "10402335" },
      { "name": "Dried cut high and bonita rose", "code": "10402336" },
      { "name": "Dried cut high and booming rose", "code": "10402337" },
      { "name": "Dried cut high and fantasy rose", "code": "10402338" },
      { "name": "Dried cut high and rich rose", "code": "10402339" },
      { "name": "Dried cut hot lady rose", "code": "10402340" },
      { "name": "Dried cut hot princess rose", "code": "10402341" },
      { "name": "Dried cut inspiration rose", "code": "10402342" },
      { "name": "Dried cut jeimy rose", "code": "10402343" },
      { "name": "Dried cut kachita rose", "code": "10402344" },
      { "name": "Dried cut karen rose", "code": "10402345" },
      { "name": "Dried cut kenji rose", "code": "10402346" },
      { "name": "Dried cut kiko rose", "code": "10402347" },
      { "name": "Dried cut laser rose", "code": "10402348" },
      { "name": "Dried cut latin duett rose", "code": "10402349" },
      { "name": "Dried cut latin fever rose", "code": "10402350" },
      { "name": "Dried cut lifestyle rose", "code": "10402351" },
      { "name": "Dried cut light orlando rose", "code": "10402352" },
      { "name": "Dried cut lovely dreams rose", "code": "10402353" },
      { "name": "Dried cut loyalty rose", "code": "10402354" },
      { "name": "Dried cut malibu rose", "code": "10402355" },
      { "name": "Dried cut mata-hari rose", "code": "10402356" },
      { "name": "Dried cut memphis rose", "code": "10402357" },
      { "name": "Dried cut mi amor rose", "code": "10402358" },
      { "name": "Dried cut miami rose", "code": "10402359" },
      { "name": "Dried cut michelle rose", "code": "10402360" },
      { "name": "Dried cut mikaela rose", "code": "10402361" },
      { "name": "Dried cut orchestra rose", "code": "10402362" },
      { "name": "Dried cut orlando rose", "code": "10402363" },
      { "name": "Dried cut osadia rose", "code": "10402364" },
      { "name": "Dried cut paeonia freelander rose", "code": "10402365" },
      { "name": "Dried cut paula rose", "code": "10402366" },
      { "name": "Dried cut pavarotti rose", "code": "10402367" },
      { "name": "Dried cut pink intuition rose", "code": "10402368" },
      { "name": "Dried cut poison rose", "code": "10402369" },
      { "name": "Dried cut princess rose", "code": "10402370" },
      { "name": "Dried cut queen mary rose", "code": "10402371" },
      { "name": "Dried cut raphaela rose", "code": "10402372" },
      { "name": "Dried cut raspberry ice rose", "code": "10402373" },
      { "name": "Dried cut ravel rose", "code": "10402374" },
      { "name": "Dried cut riviera rose", "code": "10402375" },
      { "name": "Dried cut sade rose", "code": "10402376" },
      { "name": "Dried cut sashimi rose", "code": "10402377" },
      { "name": "Dried cut shanya rose", "code": "10402378" },
      { "name": "Dried cut shocking versilia rose", "code": "10402379" },
      { "name": "Dried cut solitaire rose", "code": "10402380" },
      { "name": "Dried cut something different rose", "code": "10402381" },
      { "name": "Dried cut splendid renate rose", "code": "10402382" },
      { "name": "Dried cut star rose", "code": "10402383" },
      { "name": "Dried cut sweet candia rose", "code": "10402384" },
      { "name": "Dried cut sweet moments rose", "code": "10402385" },
      { "name": "Dried cut sweet unique rose", "code": "10402386" },
      { "name": "Dried cut taboo rose", "code": "10402387" },
      { "name": "Dried cut timona rose", "code": "10402388" },
      { "name": "Dried cut topaz rose", "code": "10402389" },
      { "name": "Dried cut vogue rose", "code": "10402390" },
      { "name": "Dried cut voila rose", "code": "10402391" },
      { "name": "Dried cut wild one rose", "code": "10402392" },
      { "name": "Dried cut yves piaget rose", "code": "10402393" },
      { "name": "Dried cut red or burgundy rose", "code": "10402400" },
      { "name": "Dried cut african dawn rose", "code": "10402401" },
      { "name": "Dried cut amada rose", "code": "10402402" },
      { "name": "Dried cut black baccara rose", "code": "10402403" },
      { "name": "Dried cut black beauty rose", "code": "10402404" },
      {
        "name": "Dried cut black finess or black magic rose",
        "code": "10402405"
      },
      { "name": "Dried cut black magic rose", "code": "10402406" },
      { "name": "Dried cut bohemian or pasarela rose", "code": "10402407" },
      { "name": "Dried cut breathless rose", "code": "10402408" },
      { "name": "Dried cut caballero rose", "code": "10402409" },
      { "name": "Dried cut carrera rose", "code": "10402410" },
      { "name": "Dried cut charlene rose", "code": "10402411" },
      { "name": "Dried cut charlotte rose", "code": "10402412" },
      { "name": "Dried cut cherry lady rose", "code": "10402413" },
      { "name": "Dried cut cherry love rose", "code": "10402414" },
      { "name": "Dried cut classy rose", "code": "10402415" },
      { "name": "Dried cut colorado velvet rose", "code": "10402416" },
      { "name": "Dried cut corazon rose", "code": "10402417" },
      { "name": "Dried cut corrida rose", "code": "10402418" },
      { "name": "Dried cut dynamite rose", "code": "10402419" },
      { "name": "Dried cut eurored rose", "code": "10402420" },
      { "name": "Dried cut fashion rose", "code": "10402421" },
      { "name": "Dried cut fire and ice rose", "code": "10402422" },
      { "name": "Dried cut first red rose", "code": "10402423" },
      { "name": "Dried cut forever young rose", "code": "10402424" },
      { "name": "Dried cut freedom rose", "code": "10402425" },
      { "name": "Dried cut freestyle rose", "code": "10402426" },
      { "name": "Dried cut friendship rose", "code": "10402427" },
      { "name": "Dried cut gospel rose", "code": "10402428" },
      { "name": "Dried cut graffity rose", "code": "10402429" },
      { "name": "Dried cut grand gala rose", "code": "10402430" },
      { "name": "Dried cut grand prix rose", "code": "10402431" },
      { "name": "Dried cut grande classe rose", "code": "10402432" },
      { "name": "Dried cut hearts rose", "code": "10402433" },
      { "name": "Dried cut heat rose", "code": "10402434" },
      { "name": "Dried cut hocus pocus rose", "code": "10402435" },
      { "name": "Dried cut lady in red rose", "code": "10402436" },
      { "name": "Dried cut latin lady rose", "code": "10402437" },
      { "name": "Dried cut legend rose", "code": "10402438" },
      { "name": "Dried cut lulu rose", "code": "10402439" },
      { "name": "Dried cut luna rossa rose", "code": "10402440" },
      { "name": "Dried cut luxor rose", "code": "10402441" },
      { "name": "Dried cut madame delbard or carola rose", "code": "10402442" },
      { "name": "Dried cut miss paris rose", "code": "10402443" },
      { "name": "Dried cut nicole rose", "code": "10402444" },
      { "name": "Dried cut night fever rose", "code": "10402445" },
      { "name": "Dried cut obsession rose", "code": "10402446" },
      { "name": "Dried cut opium rose", "code": "10402447" },
      { "name": "Dried cut paz rose", "code": "10402448" },
      { "name": "Dried cut preference rose", "code": "10402449" },
      { "name": "Dried cut red berlin rose", "code": "10402450" },
      { "name": "Dried cut red bull rose", "code": "10402451" },
      { "name": "Dried cut red calypso rose", "code": "10402452" },
      { "name": "Dried cut red diamond rose", "code": "10402453" },
      { "name": "Dried cut red fantasy rose", "code": "10402454" },
      { "name": "Dried cut red france rose", "code": "10402455" },
      { "name": "Dried cut red intuition rose", "code": "10402456" },
      { "name": "Dried cut red jewel rose", "code": "10402457" },
      { "name": "Dried cut red magic rose", "code": "10402458" },
      { "name": "Dried cut red one rose", "code": "10402459" },
      { "name": "Dried cut red paris rose", "code": "10402460" },
      { "name": "Dried cut red princess rose", "code": "10402461" },
      { "name": "Dried cut red sensation or colorad rose", "code": "10402462" },
      { "name": "Dried cut red unique rose", "code": "10402463" },
      { "name": "Dried cut rockefeller rose", "code": "10402464" },
      { "name": "Dried cut romeo rose", "code": "10402465" },
      { "name": "Dried cut rouge baiser rose", "code": "10402466" },
      { "name": "Dried cut roulette rose", "code": "10402467" },
      { "name": "Dried cut royal massai rose", "code": "10402468" },
      { "name": "Dried cut royal red rose", "code": "10402469" },
      { "name": "Dried cut samurai rose", "code": "10402470" },
      { "name": "Dried cut sexy red rose", "code": "10402471" },
      { "name": "Dried cut starfire rose", "code": "10402472" },
      { "name": "Dried cut tango rose", "code": "10402473" },
      { "name": "Dried cut tiger tail rose", "code": "10402474" },
      { "name": "Dried cut tinto rose", "code": "10402475" },
      { "name": "Dried cut top secret rose", "code": "10402476" },
      { "name": "Dried cut vital rose", "code": "10402477" },
      { "name": "Dried cut wisdom rose", "code": "10402478" },
      { "name": "Dried cut xantia rose", "code": "10402479" },
      { "name": "Dried cut xcite rose", "code": "10402480" },
      { "name": "Dried cut sweetheart rose", "code": "10402500" },
      { "name": "Dried cut burgundy sweetheart rose", "code": "10402501" },
      { "name": "Dried cut cream sweetheart rose", "code": "10402502" },
      { "name": "Dried cut hot pink sweetheart rose", "code": "10402503" },
      { "name": "Dried cut lavender sweetheart rose", "code": "10402504" },
      { "name": "Dried cut light pink sweetheart rose", "code": "10402505" },
      { "name": "Dried cut orange sweetheart rose", "code": "10402506" },
      { "name": "Dried cut peach sweetheart rose", "code": "10402507" },
      { "name": "Dried cut red sweetheart rose", "code": "10402508" },
      { "name": "Dried cut white sweetheart rose", "code": "10402509" },
      { "name": "Dried cut yellow sweetheart rose", "code": "10402510" },
      { "name": "Dried cut white rose", "code": "10402600" },
      { "name": "Dried cut absolut rose", "code": "10402601" },
      { "name": "Dried cut aida rose", "code": "10402602" },
      { "name": "Dried cut akito rose", "code": "10402603" },
      { "name": "Dried cut amelia rose", "code": "10402604" },
      { "name": "Dried cut anastasia rose", "code": "10402605" },
      { "name": "Dried cut andean crystal rose", "code": "10402606" },
      { "name": "Dried cut angel rose", "code": "10402607" },
      { "name": "Dried cut annemarie rose", "code": "10402608" },
      { "name": "Dried cut avalanche rose", "code": "10402609" },
      { "name": "Dried cut bianca rose", "code": "10402610" },
      { "name": "Dried cut blizzard rose", "code": "10402611" },
      { "name": "Dried cut bridal akito rose", "code": "10402612" },
      { "name": "Dried cut domenica rose", "code": "10402613" },
      { "name": "Dried cut escimo rose", "code": "10402614" },
      { "name": "Dried cut farfalla rose", "code": "10402615" },
      { "name": "Dried cut high and peace rose", "code": "10402616" },
      { "name": "Dried cut high and pure rose", "code": "10402617" },
      { "name": "Dried cut inocencia or innocenti rose", "code": "10402618" },
      { "name": "Dried cut ivory rose", "code": "10402619" },
      { "name": "Dried cut mondial rose", "code": "10402620" },
      { "name": "Dried cut mount everest rose", "code": "10402621" },
      { "name": "Dried cut nova zembla rose", "code": "10402622" },
      { "name": "Dried cut patience or auspastor rose", "code": "10402623" },
      { "name": "Dried cut polar star rose", "code": "10402624" },
      { "name": "Dried cut polo rose", "code": "10402625" },
      { "name": "Dried cut proud rose", "code": "10402626" },
      { "name": "Dried cut snowy jewel rose", "code": "10402627" },
      { "name": "Dried cut tibet rose", "code": "10402628" },
      { "name": "Dried cut tineke rose", "code": "10402629" },
      { "name": "Dried cut vitality rose", "code": "10402630" },
      { "name": "Dried cut white cadillac rose", "code": "10402631" },
      { "name": "Dried cut white dove rose", "code": "10402632" },
      { "name": "Dried cut yellow rose", "code": "10402700" },
      { "name": "Dried cut aalsmeer gold rose", "code": "10402701" },
      { "name": "Dried cut alina rose", "code": "10402702" },
      { "name": "Dried cut ambiance rose", "code": "10402703" },
      { "name": "Dried cut aquarel rose", "code": "10402704" },
      { "name": "Dried cut autumn dream rose", "code": "10402705" },
      { "name": "Dried cut brasil rose", "code": "10402706" },
      { "name": "Dried cut candle light rose", "code": "10402707" },
      { "name": "Dried cut cantata or cantate rose", "code": "10402708" },
      { "name": "Dried cut capriccio rose", "code": "10402709" },
      { "name": "Dried cut caribbean rose", "code": "10402710" },
      { "name": "Dried cut circus rose", "code": "10402711" },
      { "name": "Dried cut citran rose", "code": "10402712" },
      { "name": "Dried cut concorde rose", "code": "10402713" },
      { "name": "Dried cut conga rose", "code": "10402714" },
      { "name": "Dried cut deja vu rose", "code": "10402715" },
      { "name": "Dried cut desire rose", "code": "10402716" },
      { "name": "Dried cut donia sol rose", "code": "10402717" },
      { "name": "Dried cut dueto rose", "code": "10402718" },
      { "name": "Dried cut erin rose", "code": "10402719" },
      { "name": "Dried cut exotic curiosa rose", "code": "10402720" },
      { "name": "Dried cut feria rose", "code": "10402721" },
      { "name": "Dried cut fire bird rose", "code": "10402722" },
      { "name": "Dried cut florida rose", "code": "10402723" },
      { "name": "Dried cut friendly rose", "code": "10402724" },
      { "name": "Dried cut gallinda rose", "code": "10402725" },
      { "name": "Dried cut geisha rose", "code": "10402726" },
      { "name": "Dried cut gelbe rose", "code": "10402727" },
      { "name": "Dried cut gelosia or yellow flame rose", "code": "10402728" },
      { "name": "Dried cut gold rush rose", "code": "10402729" },
      { "name": "Dried cut gold star rose", "code": "10402730" },
      { "name": "Dried cut gold strike rose", "code": "10402731" },
      { "name": "Dried cut golda rose", "code": "10402732" },
      { "name": "Dried cut golden fashion rose", "code": "10402733" },
      { "name": "Dried cut golden gate rose", "code": "10402734" },
      { "name": "Dried cut gran dorado rose", "code": "10402735" },
      { "name": "Dried cut helio rose", "code": "10402736" },
      { "name": "Dried cut high and exotic rose", "code": "10402737" },
      { "name": "Dried cut high and yellow flame rose", "code": "10402738" },
      { "name": "Dried cut high and yellow magic rose", "code": "10402739" },
      { "name": "Dried cut high society rose", "code": "10402740" },
      { "name": "Dried cut hummer rose", "code": "10402741" },
      { "name": "Dried cut idole or elle rose", "code": "10402742" },
      { "name": "Dried cut inti rose", "code": "10402743" },
      { "name": "Dried cut jet set rose", "code": "10402744" },
      { "name": "Dried cut judy rose", "code": "10402745" },
      { "name": "Dried cut jupiter rose", "code": "10402746" },
      { "name": "Dried cut konfetti rose", "code": "10402747" },
      { "name": "Dried cut kyara or kira rose", "code": "10402748" },
      { "name": "Dried cut latin beauty rose", "code": "10402749" },
      { "name": "Dried cut latin spirit rose", "code": "10402750" },
      { "name": "Dried cut latina rose", "code": "10402751" },
      { "name": "Dried cut lina rose", "code": "10402752" },
      { "name": "Dried cut lindsey rose", "code": "10402753" },
      { "name": "Dried cut male rose", "code": "10402754" },
      { "name": "Dried cut marie claire rose", "code": "10402755" },
      { "name": "Dried cut marisa rose", "code": "10402756" },
      { "name": "Dried cut matchball rose", "code": "10402757" },
      { "name": "Dried cut melon rose", "code": "10402758" },
      { "name": "Dried cut mohana rose", "code": "10402759" },
      { "name": "Dried cut okie dokie rose", "code": "10402760" },
      { "name": "Dried cut pailine rose", "code": "10402761" },
      { "name": "Dried cut parrot rose", "code": "10402762" },
      { "name": "Dried cut rio d oro rose", "code": "10402763" },
      { "name": "Dried cut salami rose", "code": "10402764" },
      { "name": "Dried cut santa fe rose", "code": "10402765" },
      { "name": "Dried cut skyline rose", "code": "10402766" },
      { "name": "Dried cut sonrisa rose", "code": "10402767" },
      { "name": "Dried cut star ambiance rose", "code": "10402768" },
      { "name": "Dried cut starburst rose", "code": "10402769" },
      { "name": "Dried cut sun king rose", "code": "10402770" },
      { "name": "Dried cut sunmaster rose", "code": "10402771" },
      { "name": "Dried cut sunny milva rose", "code": "10402772" },
      { "name": "Dried cut sushi rose", "code": "10402773" },
      { "name": "Dried cut tabasco rose", "code": "10402774" },
      { "name": "Dried cut tara rose", "code": "10402775" },
      { "name": "Dried cut tresor 2000 rose", "code": "10402776" },
      { "name": "Dried cut ooty rose", "code": "10402777" },
      { "name": "Dried cut yellow coral rose", "code": "10402778" },
      { "name": "Dried cut yellow finess rose", "code": "10402779" },
      { "name": "Dried cut yellow submarine rose", "code": "10402780" },
      { "name": "Dried cut yellow sunset rose", "code": "10402781" },
      { "name": "Dried cut yellow timeless rose", "code": "10402782" },
      { "name": "Dried cut spray roses", "code": "10402800" },
      { "name": "Dried cut alegria spray rose", "code": "10402801" },
      { "name": "Dried cut andrea follies spray rose", "code": "10402802" },
      { "name": "Dried cut antara follies spray rose", "code": "10402803" },
      { "name": "Dried cut arrow follies spray rose", "code": "10402804" },
      { "name": "Dried cut babe spray rose", "code": "10402805" },
      { "name": "Dried cut bellina collection spray rose", "code": "10402806" },
      { "name": "Dried cut blue moon spray rose", "code": "10402807" },
      { "name": "Dried cut chablis spray rose", "code": "10402808" },
      { "name": "Dried cut cherry follies spray rose", "code": "10402809" },
      { "name": "Dried cut chess spray rose", "code": "10402810" },
      { "name": "Dried cut classic lydia spray rose", "code": "10402811" },
      { "name": "Dried cut cream gracia spray rose", "code": "10402812" },
      { "name": "Dried cut cream lydia spray rose", "code": "10402813" },
      { "name": "Dried cut cream sensation spray rose", "code": "10402814" },
      { "name": "Dried cut cremita spray rose", "code": "10402815" },
      { "name": "Dried cut diablo spray rose", "code": "10402816" },
      { "name": "Dried cut electra spray rose", "code": "10402817" },
      { "name": "Dried cut fire king spray rose", "code": "10402818" },
      { "name": "Dried cut fleur spray rose", "code": "10402819" },
      { "name": "Dried cut girlie follies spray rose", "code": "10402820" },
      { "name": "Dried cut giselle follies spray rose", "code": "10402821" },
      { "name": "Dried cut golden collection spray rose", "code": "10402822" },
      { "name": "Dried cut golden mimi spray rose", "code": "10402823" },
      { "name": "Dried cut gracia spray rose", "code": "10402824" },
      { "name": "Dried cut hot majolica spray rose", "code": "10402825" },
      { "name": "Dried cut hot pink follies spray rose", "code": "10402826" },
      { "name": "Dried cut ilse spray rose", "code": "10402827" },
      { "name": "Dried cut jelena spray rose", "code": "10402828" },
      { "name": "Dried cut laminuette spray rose", "code": "10402829" },
      { "name": "Dried cut lavender follies spray rose", "code": "10402830" },
      { "name": "Dried cut limoncello spray rose", "code": "10402831" },
      { "name": "Dried cut little silver spray rose", "code": "10402832" },
      { "name": "Dried cut lovely lydia spray rose", "code": "10402833" },
      { "name": "Dried cut lucy spray rose", "code": "10402834" },
      { "name": "Dried cut lydia spray rose", "code": "10402835" },
      { "name": "Dried cut macarena spray rose", "code": "10402836" },
      { "name": "Dried cut magic sensation spray rose", "code": "10402837" },
      { "name": "Dried cut majolica spray rose", "code": "10402838" },
      { "name": "Dried cut mambo number 5 spray rose", "code": "10402839" },
      { "name": "Dried cut mambo spray rose", "code": "10402840" },
      { "name": "Dried cut marlene spray rose", "code": "10402841" },
      { "name": "Dried cut mimi eden spray rose", "code": "10402842" },
      { "name": "Dried cut minou spray rose", "code": "10402843" },
      { "name": "Dried cut nikita spray rose", "code": "10402844" },
      { "name": "Dried cut novel collection spray rose", "code": "10402845" },
      { "name": "Dried cut orange success spray rose", "code": "10402846" },
      { "name": "Dried cut pepita spray rose", "code": "10402847" },
      { "name": "Dried cut pink flash spray rose", "code": "10402848" },
      { "name": "Dried cut pink sensation spray rose", "code": "10402849" },
      { "name": "Dried cut porcelina spray rose", "code": "10402850" },
      { "name": "Dried cut princess spray rose", "code": "10402851" },
      { "name": "Dried cut purple mikado spray rose", "code": "10402852" },
      { "name": "Dried cut red angel spray rose", "code": "10402853" },
      { "name": "Dried cut red collection spray rose", "code": "10402854" },
      { "name": "Dried cut red hero spray rose", "code": "10402855" },
      { "name": "Dried cut red mikado spray rose", "code": "10402856" },
      { "name": "Dried cut red vision spray rose", "code": "10402857" },
      { "name": "Dried cut ritmo spray rose", "code": "10402858" },
      {
        "name": "Dried cut romance mikado or eva spray rose",
        "code": "10402859"
      },
      { "name": "Dried cut romantica follies spray rose", "code": "10402860" },
      { "name": "Dried cut rubicon spray rose", "code": "10402861" },
      { "name": "Dried cut rumba spray rose", "code": "10402862" },
      { "name": "Dried cut salsa spray rose", "code": "10402863" },
      { "name": "Dried cut sangrita spray rose", "code": "10402864" },
      { "name": "Dried cut santa barbara spray rose", "code": "10402865" },
      { "name": "Dried cut sashaba spray rose", "code": "10402866" },
      { "name": "Dried cut scarlett spray rose", "code": "10402867" },
      { "name": "Dried cut seline spray rose", "code": "10402868" },
      { "name": "Dried cut sensation spray rose", "code": "10402869" },
      { "name": "Dried cut silver collection spray rose", "code": "10402870" },
      { "name": "Dried cut silver sensation spray rose", "code": "10402871" },
      { "name": "Dried cut snowdance spray rose", "code": "10402872" },
      { "name": "Dried cut snowflake spray rose", "code": "10402873" },
      { "name": "Dried cut suncity spray rose", "code": "10402874" },
      { "name": "Dried cut super nova spray rose", "code": "10402875" },
      { "name": "Dried cut sweet sensation spray rose", "code": "10402876" },
      { "name": "Dried cut taifun or typhoon spray rose", "code": "10402877" },
      { "name": "Dried cut tamango spray rose", "code": "10402878" },
      { "name": "Dried cut tanger follies spray rose", "code": "10402879" },
      { "name": "Dried cut tiara spray rose", "code": "10402880" },
      { "name": "Dried cut tiramisu spray rose", "code": "10402881" },
      { "name": "Dried cut twinkle bride spray rose", "code": "10402882" },
      { "name": "Dried cut viviane spray rose", "code": "10402883" },
      { "name": "Dried cut white majolica spray rose", "code": "10402884" },
      { "name": "Dried cut white mikado spray rose", "code": "10402885" },
      { "name": "Dried cut xentina spray rose", "code": "10402886" },
      { "name": "Dried cut yellow babe spray rose", "code": "10402887" },
      { "name": "Dried cut yellow follies spray rose", "code": "10402888" },
      {
        "name": "Dried cut blooms of high species or variety count flowers",
        "code": "10410000"
      },
      { "name": "Dried cut anthuriums", "code": "10411500" },
      { "name": "Dried cut chocolate anthurium", "code": "10411501" },
      { "name": "Dried cut dark red anthurium", "code": "10411502" },
      { "name": "Dried cut green anthurium", "code": "10411503" },
      { "name": "Dried cut hot pink anthurium", "code": "10411504" },
      { "name": "Dried cut mickey mouse anthurium", "code": "10411505" },
      {
        "name": "Dried cut obake green and white anthurium",
        "code": "10411506"
      },
      { "name": "Dried cut obake red and green anthurium", "code": "10411507" },
      { "name": "Dried cut orange anthurium", "code": "10411508" },
      { "name": "Dried cut peach anthurium", "code": "10411509" },
      { "name": "Dried cut picasso or speckled anthurium", "code": "10411510" },
      { "name": "Dried cut red anthurium", "code": "10411511" },
      { "name": "Dried cut splash anthurium", "code": "10411512" },
      { "name": "Dried cut tropic fire anthurium", "code": "10411513" },
      { "name": "Dried cut tulip green anthurium", "code": "10411514" },
      { "name": "Dried cut tulip pink anthurium", "code": "10411515" },
      { "name": "Dried cut tulip purple anthurium", "code": "10411516" },
      { "name": "Dried cut tulip red anthurium", "code": "10411517" },
      { "name": "Dried cut white anthurium", "code": "10411518" },
      { "name": "Dried cut wild thing anthurium", "code": "10411519" },
      { "name": "Dried cut alliums", "code": "10411600" },
      { "name": "Dried cut ambassador allium", "code": "10411601" },
      { "name": "Dried cut ampeloprasum allium", "code": "10411602" },
      { "name": "Dried cut bullit or drumstick allium", "code": "10411603" },
      { "name": "Dried cut christophii allium", "code": "10411604" },
      { "name": "Dried cut cowanii spray white allium", "code": "10411605" },
      { "name": "Dried cut giant allium", "code": "10411606" },
      { "name": "Dried cut gladiator allium", "code": "10411607" },
      { "name": "Dried cut globemaster allium", "code": "10411608" },
      { "name": "Dried cut golfball white allium", "code": "10411609" },
      { "name": "Dried cut hair allium", "code": "10411610" },
      { "name": "Dried cut pink giant allium", "code": "10411611" },
      { "name": "Dried cut purple sensation allium", "code": "10411612" },
      { "name": "Dried cut sicilum hanging allium", "code": "10411613" },
      { "name": "Dried cut spider schubertii allium", "code": "10411614" },
      { "name": "Dried cut spray moly allium", "code": "10411615" },
      { "name": "Dried cut spray roseum allium", "code": "10411616" },
      { "name": "Dried cut tuberosum allium", "code": "10411617" },
      { "name": "Dried cut unifolium or spray allium", "code": "10411618" },
      { "name": "Dried cut white mount everest allium", "code": "10411619" },
      { "name": "Dried cut alstroemerias", "code": "10411700" },
      { "name": "Dried cut agropoli alstroemeria", "code": "10411701" },
      { "name": "Dried cut bourgogne alstroemeria", "code": "10411702" },
      { "name": "Dried cut cairo alstroemeria", "code": "10411703" },
      { "name": "Dried cut charmes alstroemeria", "code": "10411704" },
      { "name": "Dried cut cherry bay alstroemeria", "code": "10411705" },
      { "name": "Dried cut cherry white alstroemeria", "code": "10411706" },
      { "name": "Dried cut dame blanche alstroemeria", "code": "10411707" },
      { "name": "Dried cut diamond alstroemeria", "code": "10411708" },
      { "name": "Dried cut gran canaria alstroemeria", "code": "10411709" },
      { "name": "Dried cut harlekijn alstroemeria", "code": "10411710" },
      { "name": "Dried cut indian summer alstroemeria", "code": "10411711" },
      { "name": "Dried cut jamaica alstroemeria", "code": "10411712" },
      { "name": "Dried cut macondo alstroemeria", "code": "10411713" },
      { "name": "Dried cut mistique alstroemeria", "code": "10411714" },
      { "name": "Dried cut my fair alstroemeria", "code": "10411715" },
      { "name": "Dried cut new cairo alstroemeria", "code": "10411716" },
      { "name": "Dried cut nice alstroemeria", "code": "10411717" },
      { "name": "Dried cut orange bowl alstroemeria", "code": "10411718" },
      { "name": "Dried cut orange queens alstroemeria", "code": "10411719" },
      { "name": "Dried cut orange sun alstroemeria", "code": "10411720" },
      { "name": "Dried cut paris alstroemeria", "code": "10411721" },
      { "name": "Dried cut picasso alstroemeria", "code": "10411722" },
      { "name": "Dried cut pink panther alstroemeria", "code": "10411723" },
      { "name": "Dried cut prima donna alstroemeria", "code": "10411724" },
      { "name": "Dried cut red silhouette alstroemeria", "code": "10411725" },
      { "name": "Dried cut sacha alstroemeria", "code": "10411726" },
      { "name": "Dried cut salmon alstroemeria", "code": "10411727" },
      { "name": "Dried cut santiago alstroemeria", "code": "10411728" },
      { "name": "Dried cut senna alstroemeria", "code": "10411729" },
      { "name": "Dried cut snowball alstroemeria", "code": "10411730" },
      { "name": "Dried cut sublime alstroemeria", "code": "10411731" },
      { "name": "Dried cut tropicana alstroemeria", "code": "10411732" },
      { "name": "Dried cut virginia alstroemeria", "code": "10411733" },
      { "name": "Dried cut white alstroemeria", "code": "10411734" },
      { "name": "Dried cut amaranthuses", "code": "10411800" },
      { "name": "Dried cut hanging green amaranthus", "code": "10411801" },
      { "name": "Dried cut hanging red amaranthus", "code": "10411802" },
      { "name": "Dried cut upright bronze amaranthus", "code": "10411803" },
      { "name": "Dried cut upright green amaranthus", "code": "10411804" },
      { "name": "Dried cut upright red amaranthus", "code": "10411805" },
      { "name": "Dried cut amaryllises", "code": "10411900" },
      { "name": "Dried cut naranja amaryllis", "code": "10411901" },
      { "name": "Dried cut orange nagano amaryllis", "code": "10411902" },
      { "name": "Dried cut pygmee mini amaryllis", "code": "10411903" },
      { "name": "Dried cut red lion amaryllis", "code": "10411904" },
      { "name": "Dried cut rilona amaryllis", "code": "10411905" },
      { "name": "Dried cut royal velvet amaryllis", "code": "10411906" },
      { "name": "Dried cut sonatini orange amaryllis", "code": "10411907" },
      { "name": "Dried cut sonatini red amaryllis", "code": "10411908" },
      { "name": "Dried cut tango amaryllis", "code": "10411909" },
      { "name": "Dried cut tinto night amaryllis", "code": "10411910" },
      { "name": "Dried cut anemone", "code": "10412000" },
      { "name": "Dried cut aubergine anemone", "code": "10412001" },
      { "name": "Dried cut black anemone", "code": "10412002" },
      { "name": "Dried cut blue anemone", "code": "10412003" },
      { "name": "Dried cut cerise anemone", "code": "10412004" },
      { "name": "Dried cut coronaria anemone", "code": "10412005" },
      { "name": "Dried cut hot pink anemone", "code": "10412006" },
      { "name": "Dried cut light pink anemone", "code": "10412007" },
      { "name": "Dried cut pink anemone", "code": "10412008" },
      { "name": "Dried cut purple anemone", "code": "10412009" },
      { "name": "Dried cut red anemone", "code": "10412010" },
      { "name": "Dried cut white anemone", "code": "10412011" },
      { "name": "Dried cut asclepias", "code": "10412100" },
      { "name": "Dried cut lavender asclepia", "code": "10412101" },
      { "name": "Dried cut moby dick asclepia", "code": "10412102" },
      { "name": "Dried cut tuberosa asclepia", "code": "10412103" },
      { "name": "Dried cut white asclepia", "code": "10412104" },
      { "name": "Dried cut asters", "code": "10412200" },
      { "name": "Dried cut beauty aster", "code": "10412201" },
      { "name": "Dried cut japanese blue aster", "code": "10412202" },
      { "name": "Dried cut japanese green aster", "code": "10412203" },
      { "name": "Dried cut japanese hot pink aster", "code": "10412204" },
      { "name": "Dried cut japanese lavender aster", "code": "10412205" },
      { "name": "Dried cut japanese light pink aster", "code": "10412206" },
      { "name": "Dried cut japanese peach aster", "code": "10412207" },
      { "name": "Dried cut japanese pink aster", "code": "10412208" },
      { "name": "Dried cut japanese purple aster", "code": "10412209" },
      { "name": "Dried cut japanese red aster", "code": "10412210" },
      { "name": "Dried cut japanese spider aster", "code": "10412211" },
      { "name": "Dried cut japanese white aster", "code": "10412212" },
      { "name": "Dried cut novi belgii hot pink aster", "code": "10412213" },
      { "name": "Dried cut novi belgii lavender aster", "code": "10412214" },
      { "name": "Dried cut novi belgii pink aster", "code": "10412215" },
      { "name": "Dried cut novi belgii purple aster", "code": "10412216" },
      { "name": "Dried cut novi belgii white aster", "code": "10412217" },
      { "name": "Dried cut solidago aster", "code": "10412218" },
      { "name": "Dried cut spider aster", "code": "10412219" },
      { "name": "Dried cut berzelia lanuginosas", "code": "10412300" },
      {
        "name": "Dried cut abrotanoides berzelia lanuginosa",
        "code": "10412301"
      },
      { "name": "Dried cut fireball berzelia lanuginosa", "code": "10412302" },
      { "name": "Dried cut galpinii berzelia lanuginosa", "code": "10412303" },
      {
        "name": "Dried cut galpinii or baubles berzelia lanuginosa",
        "code": "10412304"
      },
      { "name": "Dried cut squarrosa berzelia lanuginosa", "code": "10412305" },
      { "name": "Dried cut bouvardias", "code": "10412400" },
      { "name": "Dried cut hot pink double bouvardia", "code": "10412401" },
      { "name": "Dried cut light pink bouvardia", "code": "10412402" },
      { "name": "Dried cut light pink double bouvardia", "code": "10412403" },
      { "name": "Dried cut red bouvardia", "code": "10412404" },
      { "name": "Dried cut white bouvardia", "code": "10412405" },
      { "name": "Dried cut brodiaeas", "code": "10412500" },
      { "name": "Dried cut congesta brodiaea", "code": "10412501" },
      { "name": "Dried cut congesta lavender brodiaea", "code": "10412502" },
      { "name": "Dried cut hyacintha brodiaea", "code": "10412503" },
      { "name": "Dried cut ida maija brodiaea", "code": "10412504" },
      { "name": "Dried cut starlight brodiaea", "code": "10412505" },
      { "name": "Dried cut callas", "code": "10412600" },
      { "name": "Dried cut green goddess calla", "code": "10412601" },
      { "name": "Dried cut posey albertville calla", "code": "10412602" },
      { "name": "Dried cut posey aranal calla", "code": "10412603" },
      { "name": "Dried cut posey black eyed beauty calla", "code": "10412604" },
      { "name": "Dried cut posey black star calla", "code": "10412605" },
      { "name": "Dried cut posey brisbane calla", "code": "10412606" },
      { "name": "Dried cut posey crystal blush calla", "code": "10412607" },
      { "name": "Dried cut posey crystal pink calla", "code": "10412608" },
      { "name": "Dried cut posey crystal white calla", "code": "10412609" },
      {
        "name": "Dried cut posey dark captain romanc calla",
        "code": "10412610"
      },
      { "name": "Dried cut posey dark mozart calla", "code": "10412611" },
      { "name": "Dried cut posey dark naomi calla", "code": "10412612" },
      { "name": "Dried cut posey deformed calla", "code": "10412613" },
      { "name": "Dried cut posey dordogne calla", "code": "10412614" },
      { "name": "Dried cut posey etude calla", "code": "10412615" },
      { "name": "Dried cut posey farao calla", "code": "10412616" },
      { "name": "Dried cut posey fire glow calla", "code": "10412617" },
      { "name": "Dried cut posey florex gold calla", "code": "10412618" },
      { "name": "Dried cut posey garnet glow calla", "code": "10412619" },
      { "name": "Dried cut posey hot chocolate calla", "code": "10412620" },
      { "name": "Dried cut posey lavender improved calla", "code": "10412621" },
      { "name": "Dried cut posey light cromance calla", "code": "10412622" },
      { "name": "Dried cut posey little suzy calla", "code": "10412623" },
      { "name": "Dried cut posey majestic red calla", "code": "10412624" },
      { "name": "Dried cut posey mango calla", "code": "10412625" },
      { "name": "Dried cut posey merlot calla", "code": "10412626" },
      { "name": "Dried cut posey mozart calla", "code": "10412627" },
      { "name": "Dried cut posey naomi calla", "code": "10412628" },
      { "name": "Dried cut posey night cap calla", "code": "10412629" },
      { "name": "Dried cut posey odessa calla", "code": "10412630" },
      { "name": "Dried cut posey pacific pink calla", "code": "10412631" },
      { "name": "Dried cut posey passion fruit calla", "code": "10412632" },
      { "name": "Dried cut posey picasso calla", "code": "10412633" },
      { "name": "Dried cut posey pillow talk calla", "code": "10412634" },
      { "name": "Dried cut posey pink persuation calla", "code": "10412635" },
      { "name": "Dried cut posey pisa calla", "code": "10412636" },
      { "name": "Dried cut posey pot of calla", "code": "10412637" },
      { "name": "Dried cut posey red sox calla", "code": "10412638" },
      { "name": "Dried cut posey rosa calla", "code": "10412639" },
      { "name": "Dried cut posey ruby light rose calla", "code": "10412640" },
      { "name": "Dried cut posey samur calla", "code": "10412641" },
      { "name": "Dried cut posey sapporo calla", "code": "10412642" },
      { "name": "Dried cut posey schwarzwalder calla", "code": "10412643" },
      { "name": "Dried cut posey serrada calla", "code": "10412644" },
      { "name": "Dried cut posey solemio calla", "code": "10412645" },
      { "name": "Dried cut posey sunrise calla", "code": "10412646" },
      { "name": "Dried cut posey super mac calla", "code": "10412647" },
      { "name": "Dried cut posey swan lake calla", "code": "10412648" },
      { "name": "Dried cut posey vermeer calla", "code": "10412649" },
      { "name": "Dried cut posey white butterfly calla", "code": "10412650" },
      { "name": "Dried cut posey yellow calla", "code": "10412651" },
      { "name": "Dried cut posey yellow mozart calla", "code": "10412652" },
      { "name": "Dried cut white large calla", "code": "10412653" },
      { "name": "Dried cut celosias", "code": "10412800" },
      { "name": "Dried cut cockscomb green celosia", "code": "10412801" },
      { "name": "Dried cut cockscomb orange celosia", "code": "10412802" },
      { "name": "Dried cut cockscomb pink celosia", "code": "10412803" },
      { "name": "Dried cut cockscomb purple celosia", "code": "10412804" },
      { "name": "Dried cut cockscomb red celosia", "code": "10412805" },
      { "name": "Dried cut cockscomb yellow celosia", "code": "10412806" },
      { "name": "Dried cut plume light pink celosia", "code": "10412807" },
      { "name": "Dried cut plume orange celosia", "code": "10412808" },
      { "name": "Dried cut plume purple celosia", "code": "10412809" },
      { "name": "Dried cut plume red celosia", "code": "10412810" },
      { "name": "Dried cut plume yellow celosia", "code": "10412811" },
      { "name": "Dried cut wheat pink celosia", "code": "10412812" },
      { "name": "Dried cut wheat yellow celosia", "code": "10412813" },
      { "name": "Dried cut daffodils", "code": "10412900" },
      { "name": "Dried cut dick wilden daffodil", "code": "10412901" },
      { "name": "Dried cut dutch master daffodil", "code": "10412902" },
      { "name": "Dried cut ice follies daffodil", "code": "10412903" },
      { "name": "Dried cut ice king daffodil", "code": "10412904" },
      { "name": "Dried cut johan strauss daffodil", "code": "10412905" },
      { "name": "Dried cut yellow carlton daffodil", "code": "10412906" },
      { "name": "Dried cut dahlias", "code": "10413000" },
      { "name": "Dried cut bi color dahlia", "code": "10413001" },
      { "name": "Dried cut hot pink dahlia", "code": "10413002" },
      { "name": "Dried cut light pink dahlia", "code": "10413003" },
      { "name": "Dried cut medium pink dahlia", "code": "10413004" },
      { "name": "Dried cut orange dahlia", "code": "10413005" },
      { "name": "Dried cut peach dahlia", "code": "10413006" },
      { "name": "Dried cut purple dahlia", "code": "10413007" },
      { "name": "Dried cut red dahlia", "code": "10413008" },
      { "name": "Dried cut white dahlia", "code": "10413009" },
      { "name": "Dried cut yellow dahlia", "code": "10413010" },
      { "name": "Dried cut delphiniums", "code": "10413100" },
      { "name": "Dried cut bella dark blue delphinium", "code": "10413101" },
      { "name": "Dried cut bella light blue delphinium", "code": "10413102" },
      { "name": "Dried cut bella white delphinium", "code": "10413103" },
      { "name": "Dried cut blue shadow delphinium", "code": "10413104" },
      { "name": "Dried cut hybrid dark blue delphinium", "code": "10413105" },
      { "name": "Dried cut hybrid light blue delphinium", "code": "10413106" },
      { "name": "Dried cut hybrid mauve delphinium", "code": "10413107" },
      { "name": "Dried cut hybrid pink delphinium", "code": "10413108" },
      { "name": "Dried cut hybrid purple delphinium", "code": "10413109" },
      { "name": "Dried cut hybrid red delphinium", "code": "10413110" },
      { "name": "Dried cut hybrid white delphinium", "code": "10413111" },
      { "name": "Dried cut princess caroline delphinium", "code": "10413112" },
      { "name": "Dried cut volkerfrieden delphinium", "code": "10413113" },
      { "name": "Dried cut dianthuses", "code": "10413200" },
      { "name": "Dried cut chocolate dianthus", "code": "10413201" },
      { "name": "Dried cut fuchsia dianthus", "code": "10413202" },
      { "name": "Dried cut green ball dianthus", "code": "10413203" },
      { "name": "Dried cut hot pink dianthus", "code": "10413204" },
      { "name": "Dried cut lavender dianthus", "code": "10413205" },
      { "name": "Dried cut raspberry dianthus", "code": "10413206" },
      { "name": "Dried cut red dianthus", "code": "10413207" },
      { "name": "Dried cut rosie pink dianthus", "code": "10413208" },
      { "name": "Dried cut eremuruses", "code": "10413300" },
      { "name": "Dried cut deruyter hybrid eremurus", "code": "10413301" },
      { "name": "Dried cut himalaicus white eremurus", "code": "10413302" },
      { "name": "Dried cut orange eremurus", "code": "10413303" },
      { "name": "Dried cut peach eremurus", "code": "10413304" },
      { "name": "Dried cut yellow eremurus", "code": "10413305" },
      { "name": "Dried cut ericas", "code": "10413400" },
      { "name": "Dried cut campunalarus erica", "code": "10413401" },
      { "name": "Dried cut conica erica", "code": "10413402" },
      { "name": "Dried cut green ice erica", "code": "10413403" },
      { "name": "Dried cut pink erica", "code": "10413404" },
      { "name": "Dried cut prince of whales erica", "code": "10413405" },
      { "name": "Dried cut euphorbias", "code": "10413500" },
      { "name": "Dried cut characias euphorbia", "code": "10413501" },
      { "name": "Dried cut griffithii fireglow euphorbia", "code": "10413502" },
      { "name": "Dried cut martini euphorbia", "code": "10413503" },
      { "name": "Dried cut orange euphorbia", "code": "10413504" },
      { "name": "Dried cut peach euphorbia", "code": "10413505" },
      { "name": "Dried cut pink euphorbia", "code": "10413506" },
      { "name": "Dried cut red euphorbia", "code": "10413507" },
      { "name": "Dried cut white euphorbia", "code": "10413508" },
      { "name": "Dried cut yellow euphorbia", "code": "10413509" },
      { "name": "Dried cut yellow spurge euphorbia", "code": "10413510" },
      { "name": "Dried cut freesias", "code": "10413600" },
      { "name": "Dried cut cream freesia", "code": "10413601" },
      { "name": "Dried cut double white freesia", "code": "10413602" },
      { "name": "Dried cut double yellow freesia", "code": "10413603" },
      { "name": "Dried cut hot pink freesia", "code": "10413604" },
      { "name": "Dried cut lady brunet freesia", "code": "10413605" },
      { "name": "Dried cut lavender freesia", "code": "10413606" },
      { "name": "Dried cut medium pink freesia", "code": "10413607" },
      { "name": "Dried cut orange freesia", "code": "10413608" },
      { "name": "Dried cut pimpernel freesia", "code": "10413609" },
      { "name": "Dried cut pink freesia", "code": "10413610" },
      { "name": "Dried cut purple freesia", "code": "10413611" },
      { "name": "Dried cut red freesia", "code": "10413612" },
      { "name": "Dried cut white freesia", "code": "10413613" },
      { "name": "Dried cut yellow freesia", "code": "10413614" },
      { "name": "Dried cut fritillarias", "code": "10413700" },
      { "name": "Dried cut acmopelata fritillaria", "code": "10413701" },
      { "name": "Dried cut assyriaca fritillaria", "code": "10413702" },
      {
        "name": "Dried cut assyrica uva vulpis frittilarias",
        "code": "10413703"
      },
      { "name": "Dried cut elysee fritillaria", "code": "10413704" },
      { "name": "Dried cut imperialis orange fritillaria", "code": "10413705" },
      { "name": "Dried cut imperialis yellow fritillaria", "code": "10413706" },
      { "name": "Dried cut meleagris fritillaria", "code": "10413707" },
      { "name": "Dried cut michailowski fritillaria", "code": "10413708" },
      { "name": "Dried cut uva vulpis frittilaria", "code": "10413709" },
      { "name": "Dried cut genistas", "code": "10413800" },
      { "name": "Dried cut green genista", "code": "10413801" },
      { "name": "Dried cut hot pink genista", "code": "10413802" },
      { "name": "Dried cut lavender genista", "code": "10413803" },
      { "name": "Dried cut light pink genista", "code": "10413804" },
      { "name": "Dried cut peach genista", "code": "10413805" },
      { "name": "Dried cut purple genista", "code": "10413806" },
      { "name": "Dried cut white genista", "code": "10413807" },
      { "name": "Dried cut yellow genista", "code": "10413808" },
      { "name": "Dried cut gerberas", "code": "10413900" },
      { "name": "Dried cut cream black center gerbera", "code": "10413901" },
      { "name": "Dried cut cream gerbera", "code": "10413902" },
      { "name": "Dried cut gold gerbera", "code": "10413903" },
      { "name": "Dried cut hot pink gerbera", "code": "10413904" },
      { "name": "Dried cut light pink gerbera", "code": "10413905" },
      { "name": "Dried cut magenta gerbera", "code": "10413906" },
      { "name": "Dried cut mini coral gerbera", "code": "10413907" },
      { "name": "Dried cut mini fuchsia gerbera", "code": "10413908" },
      { "name": "Dried cut mini hot pink gerbera", "code": "10413909" },
      {
        "name": "Dried cut mini light orange black center gerbera",
        "code": "10413910"
      },
      {
        "name": "Dried cut mini orange black center gerbera",
        "code": "10413911"
      },
      { "name": "Dried cut mini orange gerbera", "code": "10413912" },
      { "name": "Dried cut mini red black center gerbera", "code": "10413913" },
      { "name": "Dried cut mini white gerbera", "code": "10413914" },
      {
        "name": "Dried cut mini yellow black center gerbera",
        "code": "10413915"
      },
      { "name": "Dried cut orange black center gerbera", "code": "10413916" },
      { "name": "Dried cut orange gerbera", "code": "10413917" },
      { "name": "Dried cut peach black center gerbera", "code": "10413918" },
      { "name": "Dried cut peach gerbera", "code": "10413919" },
      { "name": "Dried cut pink black center gerbera", "code": "10413920" },
      { "name": "Dried cut pink gerbera", "code": "10413921" },
      { "name": "Dried cut red black center gerbera", "code": "10413922" },
      { "name": "Dried cut red gerbera", "code": "10413923" },
      { "name": "Dried cut spider peach gerbera", "code": "10413924" },
      { "name": "Dried cut spider red gerbera", "code": "10413925" },
      { "name": "Dried cut terracotta gerbera", "code": "10413926" },
      { "name": "Dried cut white black center gerbera", "code": "10413927" },
      { "name": "Dried cut white gerbera", "code": "10413928" },
      { "name": "Dried cut yellow gerbera", "code": "10413929" },
      { "name": "Dried cut ginger plants", "code": "10414000" },
      { "name": "Dried cut indonesian ginger", "code": "10414001" },
      { "name": "Dried cut jungle king pink ginger", "code": "10414002" },
      { "name": "Dried cut jungle king red ginger", "code": "10414003" },
      { "name": "Dried cut pink ginger", "code": "10414004" },
      { "name": "Dried cut red ginger", "code": "10414005" },
      { "name": "Dried cut torch ginger", "code": "10414006" },
      { "name": "Dried cut gladioluses", "code": "10414100" },
      { "name": "Dried cut burgundy gladiolus", "code": "10414101" },
      { "name": "Dried cut fuchsia gladiolus", "code": "10414102" },
      { "name": "Dried cut green gladiolus", "code": "10414103" },
      { "name": "Dried cut hot pink gladiolus", "code": "10414104" },
      { "name": "Dried cut light pink gladiolus", "code": "10414105" },
      { "name": "Dried cut orange gladiolus", "code": "10414106" },
      { "name": "Dried cut peach gladiolus", "code": "10414107" },
      { "name": "Dried cut pink medium gladiolus", "code": "10414108" },
      { "name": "Dried cut purple gladiolus", "code": "10414109" },
      { "name": "Dried cut red bi color gladiolus", "code": "10414110" },
      { "name": "Dried cut red gladiolus", "code": "10414111" },
      { "name": "Dried cut salmon gladiolus", "code": "10414112" },
      { "name": "Dried cut white gladiolus", "code": "10414113" },
      { "name": "Dried cut yellow gladiolus", "code": "10414114" },
      { "name": "Dried cut godetias", "code": "10414200" },
      { "name": "Dried cut bi color godetia", "code": "10414201" },
      { "name": "Dried cut fuchsia godetia", "code": "10414202" },
      { "name": "Dried cut hot pink godetia", "code": "10414203" },
      { "name": "Dried cut orange godetia", "code": "10414204" },
      { "name": "Dried cut red godetia", "code": "10414205" },
      { "name": "Dried cut white godetia", "code": "10414206" },
      { "name": "Dried cut guzmanias", "code": "10414300" },
      { "name": "Dried cut lingulata orange guzmania", "code": "10414301" },
      { "name": "Dried cut lingulata red guzmania", "code": "10414302" },
      { "name": "Dried cut lingulata white guzmania", "code": "10414303" },
      { "name": "Dried cut lingulata yellow guzmania", "code": "10414304" },
      { "name": "Dried cut variegata guzmania", "code": "10414305" },
      { "name": "Dried cut gypsophilias", "code": "10414400" },
      { "name": "Dried cut bambino gypsophilia", "code": "10414401" },
      { "name": "Dried cut million stars gypsophilia", "code": "10414402" },
      { "name": "Dried cut mirabella gypsophilia", "code": "10414403" },
      { "name": "Dried cut new love gypsophilia", "code": "10414404" },
      { "name": "Dried cut orion gypsophilia", "code": "10414405" },
      { "name": "Dried cut perfecta gypsophilia", "code": "10414406" },
      { "name": "Dried cut heather", "code": "10414500" },
      { "name": "Dried cut augustine heather", "code": "10414501" },
      { "name": "Dried cut erica four sisters heather", "code": "10414502" },
      { "name": "Dried cut french heather", "code": "10414503" },
      { "name": "Dried cut green heather", "code": "10414504" },
      { "name": "Dried cut sterling range white heather", "code": "10414505" },
      { "name": "Dried cut sunset pink heather", "code": "10414506" },
      { "name": "Dried cut white heather", "code": "10414507" },
      { "name": "Dried cut heliconias", "code": "10414600" },
      { "name": "Dried cut bihai claw heliconia", "code": "10414601" },
      { "name": "Dried cut bihai flash heliconia", "code": "10414602" },
      { "name": "Dried cut bihai lobster claw heliconia", "code": "10414603" },
      { "name": "Dried cut caribea red heliconia", "code": "10414604" },
      { "name": "Dried cut caribea yellow heliconia", "code": "10414605" },
      { "name": "Dried cut christmas heliconia", "code": "10414606" },
      { "name": "Dried cut edge of night heliconia", "code": "10414607" },
      { "name": "Dried cut green bihai heliconia", "code": "10414608" },
      { "name": "Dried cut marginata lutea heliconia", "code": "10414609" },
      { "name": "Dried cut psitt fire opal heliconia", "code": "10414610" },
      { "name": "Dried cut psittacorum heliconia", "code": "10414611" },
      { "name": "Dried cut richmond red heliconia", "code": "10414612" },
      { "name": "Dried cut rostrata heliconia", "code": "10414613" },
      { "name": "Dried cut sexy pink heliconia", "code": "10414614" },
      { "name": "Dried cut sexy scarlett heliconia", "code": "10414615" },
      { "name": "Dried cut shogun heliconia", "code": "10414616" },
      { "name": "Dried cut small red heliconia", "code": "10414617" },
      { "name": "Dried cut southern cross heliconia", "code": "10414618" },
      { "name": "Dried cut wagneriana heliconia", "code": "10414619" },
      { "name": "Dried cut hyacinths", "code": "10414700" },
      { "name": "Dried cut bean hyacinths", "code": "10414701" },
      { "name": "Dried cut apricot hyacinth", "code": "10414702" },
      { "name": "Dried cut blue hyacinth", "code": "10414703" },
      { "name": "Dried cut fuchsia hyacinth", "code": "10414704" },
      { "name": "Dried cut hot pink hyacinth", "code": "10414705" },
      { "name": "Dried cut lavender hyacinth", "code": "10414706" },
      { "name": "Dried cut light blue hyacinth", "code": "10414707" },
      { "name": "Dried cut medium pink hyacinth", "code": "10414708" },
      { "name": "Dried cut pink hyacinth", "code": "10414709" },
      { "name": "Dried cut purple star hyacinth", "code": "10414710" },
      { "name": "Dried cut white hyacinth", "code": "10414711" },
      { "name": "Dried cut yellow hyacinth", "code": "10414712" },
      { "name": "Dried cut hydrangeas", "code": "10414800" },
      { "name": "Dried cut annabelle hydrangea", "code": "10414801" },
      { "name": "Dried cut antique blue hydrangea", "code": "10414802" },
      {
        "name": "Dried cut antique blue or green or new zealand hydrangea",
        "code": "10414803"
      },
      { "name": "Dried cut antique green hydrangea", "code": "10414804" },
      { "name": "Dried cut antique pink hydrangea", "code": "10414805" },
      {
        "name": "Dried cut antique purple or new zealand hydrangea",
        "code": "10414806"
      },
      {
        "name": "Dried cut aubergene or new zealand hydrangea",
        "code": "10414807"
      },
      { "name": "Dried cut dark blue hydrangea", "code": "10414808" },
      { "name": "Dried cut dark pink hydrangea", "code": "10414809" },
      { "name": "Dried cut dark purple hydrangea", "code": "10414810" },
      { "name": "Dried cut eggbloom hydrangea", "code": "10414811" },
      { "name": "Dried cut green dyed hydrangea", "code": "10414812" },
      { "name": "Dried cut green lemon hydrangea", "code": "10414813" },
      { "name": "Dried cut hot pink hydrangea", "code": "10414814" },
      { "name": "Dried cut jumbo white hydrangea", "code": "10414815" },
      {
        "name": "Dried cut lavender or new zealand hydrangea",
        "code": "10414816"
      },
      { "name": "Dried cut light blue hydrangea", "code": "10414817" },
      { "name": "Dried cut light pink large hydrangea", "code": "10414818" },
      { "name": "Dried cut lime green large hydrangea", "code": "10414819" },
      { "name": "Dried cut mini green hydrangea", "code": "10414820" },
      { "name": "Dried cut oakleaf hydrangea", "code": "10414821" },
      { "name": "Dried cut oakleaf snowflake hydrangea", "code": "10414822" },
      { "name": "Dried cut pink dyed hydrangea", "code": "10414823" },
      { "name": "Dried cut pink hydrangea", "code": "10414824" },
      {
        "name": "Dried cut purple or new zealand hydrangea",
        "code": "10414825"
      },
      { "name": "Dried cut red dyed hydrangea", "code": "10414826" },
      { "name": "Dried cut shocking blue hydrangea", "code": "10414827" },
      { "name": "Dried cut tardiva hydrangea", "code": "10414828" },
      { "name": "Dried cut white hydrangea", "code": "10414829" },
      { "name": "Dried cut irises", "code": "10414900" },
      { "name": "Dried cut black bearded iris", "code": "10414901" },
      { "name": "Dried cut bearded blue iris", "code": "10414902" },
      { "name": "Dried cut bearded lavender iris", "code": "10414903" },
      { "name": "Dried cut bearded light blue iris", "code": "10414904" },
      { "name": "Dried cut bearded purple iris", "code": "10414905" },
      { "name": "Dried cut bearded red iris", "code": "10414906" },
      { "name": "Dried cut bearded white iris", "code": "10414907" },
      { "name": "Dried cut bearded white and purple iris", "code": "10414908" },
      { "name": "Dried cut bearded yellow iris", "code": "10414909" },
      { "name": "Dried cut blue elegance iris", "code": "10414910" },
      { "name": "Dried cut casablanca iris", "code": "10414911" },
      { "name": "Dried cut golden beau iris", "code": "10414912" },
      { "name": "Dried cut hildegard iris", "code": "10414913" },
      { "name": "Dried cut hong kong iris", "code": "10414914" },
      { "name": "Dried cut ideal iris", "code": "10414915" },
      { "name": "Dried cut professor blue iris", "code": "10414916" },
      { "name": "Dried cut purple iris", "code": "10414917" },
      { "name": "Dried cut spuria iris", "code": "10414918" },
      { "name": "Dried cut telstar iris", "code": "10414919" },
      { "name": "Dried cut kangaroo paws", "code": "10415000" },
      { "name": "Dried cut bi color kangaroo paw", "code": "10415001" },
      { "name": "Dried cut black kangaroo paw", "code": "10415002" },
      { "name": "Dried cut green kangaroo paw", "code": "10415003" },
      { "name": "Dried cut orange kangaroo paw", "code": "10415004" },
      { "name": "Dried cut pink kangaroo paw", "code": "10415005" },
      { "name": "Dried cut red kangaroo paw", "code": "10415006" },
      { "name": "Dried cut yellow kangaroo paw", "code": "10415007" },
      { "name": "Dried cut larkspurs", "code": "10415100" },
      { "name": "Dried cut blue cloud larkspur", "code": "10415101" },
      { "name": "Dried cut dark pink larkspur", "code": "10415102" },
      { "name": "Dried cut lavender larkspur", "code": "10415103" },
      { "name": "Dried cut light pink larkspur", "code": "10415104" },
      { "name": "Dried cut purple larkspur", "code": "10415105" },
      { "name": "Dried cut white larkspur", "code": "10415106" },
      { "name": "Dried cut leptos", "code": "10415200" },
      { "name": "Dried cut blue or flowering lepto", "code": "10415201" },
      { "name": "Dried cut hot pink lepto", "code": "10415202" },
      { "name": "Dried cut light pink lepto", "code": "10415203" },
      { "name": "Dried cut pink lepto", "code": "10415204" },
      { "name": "Dried cut red lepto", "code": "10415205" },
      { "name": "Dried cut white lepto", "code": "10415206" },
      { "name": "Dried cut lilacs", "code": "10415300" },
      { "name": "Dried cut french hybrid lavender lilac", "code": "10415301" },
      { "name": "Dried cut french hybrid purple lilac", "code": "10415302" },
      { "name": "Dried cut purple lilac", "code": "10415303" },
      { "name": "Dried cut vine lilac", "code": "10415304" },
      { "name": "Dried cut white lilac", "code": "10415305" },
      { "name": "Dried cut wild white lilac", "code": "10415306" },
      { "name": "Dried cut lilies", "code": "10415400" },
      {
        "name": "Dried cut highness longiflorum and asiatic hybrid lily",
        "code": "10415401"
      },
      { "name": "Dried cut asiatic black out lily", "code": "10415402" },
      { "name": "Dried cut asiatic dark pink lily", "code": "10415403" },
      { "name": "Dried cut asiatic electric lily", "code": "10415404" },
      { "name": "Dried cut asiatic festival lily", "code": "10415405" },
      { "name": "Dried cut asiatic geneva lily", "code": "10415406" },
      { "name": "Dried cut asiatic light pink lily", "code": "10415407" },
      { "name": "Dried cut asiatic lollipop lily", "code": "10415408" },
      {
        "name": "Dried cut asiatic miss america purple lily",
        "code": "10415409"
      },
      { "name": "Dried cut asiatic monte negro lily", "code": "10415410" },
      { "name": "Dried cut asiatic orange lily", "code": "10415411" },
      { "name": "Dried cut asiatic peach cannes lily", "code": "10415412" },
      { "name": "Dried cut asiatic pink lily", "code": "10415413" },
      { "name": "Dried cut asiatic sancerre lily", "code": "10415414" },
      { "name": "Dried cut asiatic white dream lily", "code": "10415415" },
      { "name": "Dried cut asiatic yellow lily", "code": "10415416" },
      {
        "name": "Dried cut bright diamond longiflorum and asiatic hybrid lily",
        "code": "10415417"
      },
      {
        "name": "Dried cut brindisi longiflorum and asiatic hybrid lily",
        "code": "10415418"
      },
      {
        "name": "Dried cut carmine longiflorum and asiatic hybrid lily",
        "code": "10415419"
      },
      {
        "name": "Dried cut cinnabar longiflorum and asiatic hybrid lily",
        "code": "10415420"
      },
      {
        "name": "Dried cut club longiflorum and asiatic hybrid lily",
        "code": "10415421"
      },
      {
        "name": "Dried cut discovery longiflorum and asiatic hybrid lily",
        "code": "10415422"
      },
      { "name": "Dried cut easter lily", "code": "10415423" },
      {
        "name": "Dried cut isis longiflorum and asiatic hybrid lily",
        "code": "10415424"
      },
      {
        "name": "Dried cut la hybrid justice longiflorum and asiatic hybrid lily",
        "code": "10415425"
      },
      {
        "name": "Dried cut lace longiflorum and asiatic hybrid lily",
        "code": "10415426"
      },
      { "name": "Dried cut lily of the valley", "code": "10415427" },
      {
        "name": "Dried cut love longiflorum and asiatic hybrid lily",
        "code": "10415428"
      },
      {
        "name": "Dried cut menorca longiflorum and asiatic hybrid lily",
        "code": "10415429"
      },
      { "name": "Dried cut oriental acapulco lily", "code": "10415430" },
      { "name": "Dried cut oriental albion lily", "code": "10415431" },
      { "name": "Dried cut oriental argentina lily", "code": "10415432" },
      { "name": "Dried cut oriental auratum lily", "code": "10415433" },
      { "name": "Dried cut oriental barbaresco lily", "code": "10415434" },
      { "name": "Dried cut oriental bernini lily", "code": "10415435" },
      { "name": "Dried cut oriental beseno lily", "code": "10415436" },
      { "name": "Dried cut oriental broadway lily", "code": "10415437" },
      { "name": "Dried cut oriental canada lily", "code": "10415438" },
      { "name": "Dried cut oriental casablanca lily", "code": "10415439" },
      { "name": "Dried cut oriental chili lily", "code": "10415440" },
      { "name": "Dried cut oriental chrystal blanca lily", "code": "10415441" },
      { "name": "Dried cut oriental cobra lily", "code": "10415442" },
      { "name": "Dried cut oriental conca d or lily", "code": "10415443" },
      { "name": "Dried cut oriental cote d ivor lily", "code": "10415444" },
      { "name": "Dried cut oriental dizzy lily", "code": "10415445" },
      { "name": "Dried cut oriental fireball lily", "code": "10415446" },
      { "name": "Dried cut oriental gluhwein lily", "code": "10415447" },
      { "name": "Dried cut oriental goldband lily", "code": "10415448" },
      { "name": "Dried cut oriental halifax lily", "code": "10415449" },
      { "name": "Dried cut oriental kathryn lily", "code": "10415450" },
      { "name": "Dried cut oriental kyoto lily", "code": "10415451" },
      { "name": "Dried cut oriental la mancha lily", "code": "10415452" },
      { "name": "Dried cut oriental medusa lily", "code": "10415453" },
      { "name": "Dried cut oriental montezuma lily", "code": "10415454" },
      { "name": "Dried cut oriental muscadet lily", "code": "10415455" },
      { "name": "Dried cut oriental nippon lily", "code": "10415456" },
      { "name": "Dried cut oriental opus one lily", "code": "10415457" },
      { "name": "Dried cut oriental pompeii lily", "code": "10415458" },
      { "name": "Dried cut oriental rialto lily", "code": "10415459" },
      { "name": "Dried cut oriental robina lily", "code": "10415460" },
      { "name": "Dried cut oriental rousillon lily", "code": "10415461" },
      { "name": "Dried cut oriental siberia lily", "code": "10415462" },
      { "name": "Dried cut oriental sorbonne lily", "code": "10415463" },
      { "name": "Dried cut oriental starfighter lily", "code": "10415464" },
      { "name": "Dried cut oriental stargazer lily", "code": "10415465" },
      { "name": "Dried cut oriental sumatra lily", "code": "10415466" },
      { "name": "Dried cut oriental time out lily", "code": "10415467" },
      { "name": "Dried cut oriental tom pouche lily", "code": "10415468" },
      { "name": "Dried cut oriental tropical lily", "code": "10415469" },
      { "name": "Dried cut oriental white cup lily", "code": "10415470" },
      { "name": "Dried cut oriental white merostar lily", "code": "10415471" },
      { "name": "Dried cut oriental white montana lily", "code": "10415472" },
      { "name": "Dried cut oriental white stargazer lily", "code": "10415473" },
      { "name": "Dried cut oriental yellow band lily", "code": "10415474" },
      { "name": "Dried cut oriental yellow dream lily", "code": "10415475" },
      { "name": "Dried cut oriental yellow queen lily", "code": "10415476" },
      { "name": "Dried cut oriental yellow star lily", "code": "10415477" },
      { "name": "Dried cut oriental yelloween lily", "code": "10415478" },
      { "name": "Dried cut ot red dutch lily", "code": "10415479" },
      { "name": "Dried cut sonata nimph lily", "code": "10415480" },
      { "name": "Dried cut sonata shocking lily", "code": "10415481" },
      { "name": "Dried cut sonata triumphater lily", "code": "10415482" },
      {
        "name": "Dried cut sunset longiflorum and asiatic hybrid lily",
        "code": "10415483"
      },
      { "name": "Dried cut water lily", "code": "10415484" },
      { "name": "Dried cut limoniums", "code": "10415500" },
      { "name": "Dried cut misty peach limonium", "code": "10415501" },
      { "name": "Dried cut misty pink limonium", "code": "10415502" },
      { "name": "Dried cut misty white limonium", "code": "10415503" },
      { "name": "Dried cut misty yellow limonium", "code": "10415504" },
      { "name": "Dried cut safora limonium", "code": "10415505" },
      { "name": "Dried cut sinensis limonium", "code": "10415506" },
      { "name": "Dried cut lisianthuses", "code": "10415600" },
      { "name": "Dried cut creme lisianthus", "code": "10415601" },
      { "name": "Dried cut dark pink lisianthus", "code": "10415602" },
      { "name": "Dried cut green lisianthus", "code": "10415603" },
      { "name": "Dried cut lavender lisianthus", "code": "10415604" },
      { "name": "Dried cut light pink lisianthus", "code": "10415605" },
      { "name": "Dried cut mini white lisianthus", "code": "10415606" },
      { "name": "Dried cut peach lisianthus", "code": "10415607" },
      {
        "name": "Dried cut pink with white edge lisianthus",
        "code": "10415608"
      },
      { "name": "Dried cut purple lisianthus", "code": "10415609" },
      {
        "name": "Dried cut purple with white edge lisianthus",
        "code": "10415610"
      },
      {
        "name": "Dried cut white with pink edge lisianthus",
        "code": "10415611"
      },
      { "name": "Dried cut white lisianthus", "code": "10415612" },
      {
        "name": "Dried cut white with purple edge lisianthus",
        "code": "10415613"
      },
      {
        "name": "Dried cut muscari blooms or grape hyacinths",
        "code": "10415700"
      },
      { "name": "Dried cut armeniacum muscari", "code": "10415701" },
      { "name": "Dried cut bortyoides white muscari", "code": "10415702" },
      { "name": "Dried cut green muscari", "code": "10415703" },
      { "name": "Dried cut latifolia muscari", "code": "10415704" },
      { "name": "Dried cut valerie finn muscari", "code": "10415705" },
      { "name": "Dried cut narcissus", "code": "10415800" },
      { "name": "Dried cut cheerfulness narcissus", "code": "10415801" },
      { "name": "Dried cut golden dawn narcissus", "code": "10415802" },
      { "name": "Dried cut paperwhite abba narcissus", "code": "10415803" },
      { "name": "Dried cut paperwhite narcissus", "code": "10415804" },
      { "name": "Dried cut pheasant eye narcissus", "code": "10415805" },
      { "name": "Dried cut soleil d or narcissus", "code": "10415806" },
      { "name": "Dried cut tete a tete narcissus", "code": "10415807" },
      { "name": "Dried cut thalia narcissus", "code": "10415808" },
      { "name": "Dried cut ornamental peppers", "code": "10416000" },
      { "name": "Dried cut ornamental chili pepper", "code": "10416001" },
      { "name": "Dried cut ornamental mixed pepper", "code": "10416002" },
      { "name": "Dried cut ornamental orange pepper", "code": "10416003" },
      { "name": "Dried cut ornamental red pepper", "code": "10416004" },
      { "name": "Dried cut ornamental yellow pepper", "code": "10416005" },
      { "name": "Dried cut ornithogalums", "code": "10416100" },
      { "name": "Dried cut arabicum ornithogalum", "code": "10416101" },
      { "name": "Dried cut orange dubium ornithogalum", "code": "10416102" },
      { "name": "Dried cut umbellatum ornithogalum", "code": "10416103" },
      { "name": "Dried cut white dubium ornithogalum", "code": "10416104" },
      { "name": "Dried cut yellow dubium ornithogalum", "code": "10416105" },
      { "name": "Dried cut peonies", "code": "10416200" },
      { "name": "Dried cut alexander fleming peony", "code": "10416201" },
      { "name": "Dried cut coral charm peony", "code": "10416202" },
      { "name": "Dried cut coral sunset peony", "code": "10416203" },
      { "name": "Dried cut coral supreme peony", "code": "10416204" },
      { "name": "Dried cut double gardenia peony", "code": "10416205" },
      { "name": "Dried cut double jules eli dark peony", "code": "10416206" },
      { "name": "Dried cut double white dutchess peony", "code": "10416207" },
      { "name": "Dried cut felix crousse peony", "code": "10416208" },
      { "name": "Dried cut festiva maxima peony", "code": "10416209" },
      { "name": "Dried cut garden treasure peony", "code": "10416210" },
      { "name": "Dried cut kansas dark pink peony", "code": "10416211" },
      { "name": "Dried cut karl rosenfelt peony", "code": "10416212" },
      { "name": "Dried cut paula fay peony", "code": "10416213" },
      { "name": "Dried cut red charm peony", "code": "10416214" },
      { "name": "Dried cut red passion peony", "code": "10416215" },
      { "name": "Dried cut sarah bernhardt pink peony", "code": "10416216" },
      { "name": "Dried cut scarlet o hara peony", "code": "10416217" },
      { "name": "Dried cut shirley temple peony", "code": "10416218" },
      { "name": "Dried cut banksias", "code": "10416300" },
      { "name": "Dried cut ashbyi banksia", "code": "10416301" },
      { "name": "Dried cut baxteri banksia", "code": "10416302" },
      { "name": "Dried cut coccinea banksia", "code": "10416306" },
      { "name": "Dried cut ericifolia banksia", "code": "10416311" },
      { "name": "Dried cut green banksia", "code": "10416315" },
      { "name": "Dried cut menziesii banksia", "code": "10416322" },
      { "name": "Dried cut natural white banksia", "code": "10416325" },
      { "name": "Dried cut orange banksia", "code": "10416326" },
      { "name": "Dried cut pink banksia", "code": "10416332" },
      { "name": "Dried cut ranunculuses", "code": "10416400" },
      { "name": "Dried cut chocolate ranunculus", "code": "10416401" },
      { "name": "Dried cut elegance ranunculus", "code": "10416402" },
      { "name": "Dried cut green ranunculus", "code": "10416403" },
      { "name": "Dried cut grimaldi ranunculus", "code": "10416404" },
      { "name": "Dried cut hot pink ranunculus", "code": "10416405" },
      { "name": "Dried cut light pink ranunculus", "code": "10416406" },
      { "name": "Dried cut orange ranunculus", "code": "10416407" },
      { "name": "Dried cut pink green center ranunculus", "code": "10416408" },
      { "name": "Dried cut pink ranunculus", "code": "10416409" },
      { "name": "Dried cut red ranunculus", "code": "10416410" },
      { "name": "Dried cut white ranunculus", "code": "10416411" },
      { "name": "Dried cut yellow ranunculus", "code": "10416412" },
      { "name": "Dried cut salmon ranunculus", "code": "10416413" },
      { "name": "Dried cut scabiosas", "code": "10416500" },
      { "name": "Dried cut annual scabiosa", "code": "10416501" },
      { "name": "Dried cut black scabiosa", "code": "10416502" },
      { "name": "Dried cut caucasica blue scabiosa", "code": "10416503" },
      { "name": "Dried cut caucasica pink scabiosa", "code": "10416504" },
      { "name": "Dried cut caucasica pods scabiosa", "code": "10416505" },
      { "name": "Dried cut caucasica white scabiosa", "code": "10416506" },
      { "name": "Dried cut strawberry scabiosa", "code": "10416507" },
      { "name": "Dried cut scotch brooms", "code": "10416600" },
      { "name": "Dried cut pink scotch broom", "code": "10416601" },
      { "name": "Dried cut purple scotch broom", "code": "10416602" },
      { "name": "Dried cut white scotch broom", "code": "10416603" },
      { "name": "Dried cut yellow scotch broom", "code": "10416604" },
      { "name": "Dried cut snapdragons", "code": "10416700" },
      { "name": "Dried cut bi color snapdragon", "code": "10416701" },
      { "name": "Dried cut burgundy snapdragon", "code": "10416702" },
      { "name": "Dried cut hot pink snapdragon", "code": "10416703" },
      { "name": "Dried cut lavender snapdragon", "code": "10416704" },
      { "name": "Dried cut light orange snapdragon", "code": "10416705" },
      { "name": "Dried cut light pink snapdragon", "code": "10416706" },
      { "name": "Dried cut orange snapdragon", "code": "10416707" },
      { "name": "Dried cut white snapdragon", "code": "10416708" },
      { "name": "Dried cut yellow snapdragon", "code": "10416709" },
      { "name": "Dried cut statices", "code": "10416800" },
      { "name": "Dried cut blue statice", "code": "10416801" },
      { "name": "Dried cut lavender statice", "code": "10416802" },
      { "name": "Dried cut peach statice", "code": "10416803" },
      { "name": "Dried cut pink statice", "code": "10416804" },
      { "name": "Dried cut purple statice", "code": "10416805" },
      { "name": "Dried cut seafoam statice", "code": "10416806" },
      { "name": "Dried cut white statice", "code": "10416807" },
      { "name": "Dried cut yellow statice", "code": "10416808" },
      {
        "name": "Dried cut matthiola incana or stock flowers",
        "code": "10416900"
      },
      { "name": "Dried cut apricot stock flower", "code": "10416901" },
      { "name": "Dried cut cream stock flower", "code": "10416902" },
      { "name": "Dried cut fuchsia stock flower", "code": "10416903" },
      { "name": "Dried cut lavender stock flower", "code": "10416904" },
      { "name": "Dried cut light lavender stock flower", "code": "10416905" },
      { "name": "Dried cut pacific pink stock flower", "code": "10416906" },
      { "name": "Dried cut purple stock flower", "code": "10416907" },
      { "name": "Dried cut ruby red stock flower", "code": "10416908" },
      { "name": "Dried cut sweetheart pink stock flower", "code": "10416909" },
      { "name": "Dried cut white stock flower", "code": "10416910" },
      { "name": "Dried cut sunflowers", "code": "10417000" },
      { "name": "Dried cut holiday tint sunflower", "code": "10417001" },
      { "name": "Dried cut mahogany sunflower", "code": "10417002" },
      { "name": "Dried cut sunbeam sunflower", "code": "10417003" },
      { "name": "Dried cut sunbright sunflower", "code": "10417004" },
      { "name": "Dried cut sunsplash sunflower", "code": "10417005" },
      { "name": "Dried cut teddybear sunflower", "code": "10417006" },
      { "name": "Dried cut sweet peas", "code": "10417100" },
      { "name": "Dried cut green dyed sweet pea", "code": "10417101" },
      { "name": "Dried cut hot pink sweet pea", "code": "10417102" },
      { "name": "Dried cut lavender sweet pea", "code": "10417103" },
      { "name": "Dried cut light pink sweet pea", "code": "10417104" },
      { "name": "Dried cut orange sweet pea", "code": "10417105" },
      { "name": "Dried cut peach dyed sweet pea", "code": "10417106" },
      { "name": "Dried cut purple sweet pea", "code": "10417107" },
      { "name": "Dried cut white sweet pea", "code": "10417108" },
      { "name": "Dried cut thistles", "code": "10417200" },
      { "name": "Dried cut alpinum thistle", "code": "10417201" },
      { "name": "Dried cut echinops thistle", "code": "10417202" },
      {
        "name": "Dried cut eryngium arabian dream thistle",
        "code": "10417203"
      },
      { "name": "Dried cut eryngium blue bell thistle", "code": "10417204" },
      { "name": "Dried cut eryngium orion thistle", "code": "10417205" },
      { "name": "Dried cut eryngium raspberry thistle", "code": "10417206" },
      { "name": "Dried cut eryngium supernova thistle", "code": "10417207" },
      { "name": "Dried cut eryngium tinkerbell thistle", "code": "10417208" },
      { "name": "Dried cut tulips", "code": "10417300" },
      { "name": "Dried cut adrem tulip", "code": "10417301" },
      { "name": "Dried cut apricot tulip", "code": "10417302" },
      { "name": "Dried cut bi color red and yellow tulip", "code": "10417303" },
      { "name": "Dried cut double bicolor tulip", "code": "10417304" },
      { "name": "Dried cut double pink tulip", "code": "10417305" },
      { "name": "Dried cut double red tulip", "code": "10417306" },
      { "name": "Dried cut double white tulip", "code": "10417307" },
      { "name": "Dried cut double yellow tulip", "code": "10417308" },
      { "name": "Dried cut french avignon tulip", "code": "10417309" },
      { "name": "Dried cut french camarque tulip", "code": "10417310" },
      { "name": "Dried cut french dordogne tulip", "code": "10417311" },
      { "name": "Dried cut french fiat tulip", "code": "10417312" },
      { "name": "Dried cut french flamboyant tulip", "code": "10417313" },
      { "name": "Dried cut french flaming parrot tulip", "code": "10417314" },
      { "name": "Dried cut french florissa tulip", "code": "10417315" },
      { "name": "Dried cut french maureen double tulip", "code": "10417316" },
      { "name": "Dried cut french maureen tulip", "code": "10417317" },
      { "name": "Dried cut french menton tulip", "code": "10417318" },
      { "name": "Dried cut french montpellier tulip", "code": "10417319" },
      { "name": "Dried cut french orange unique tulip", "code": "10417320" },
      {
        "name": "Dried cut french peony renown unique tulip",
        "code": "10417321"
      },
      { "name": "Dried cut french pink parrot tulip", "code": "10417322" },
      { "name": "Dried cut french princess unique tulip", "code": "10417323" },
      { "name": "Dried cut french renown tulip", "code": "10417324" },
      { "name": "Dried cut french scheppers tulip", "code": "10417325" },
      { "name": "Dried cut french suede tulip", "code": "10417326" },
      { "name": "Dried cut french toyota tulip", "code": "10417327" },
      { "name": "Dried cut french weber parrot tulip", "code": "10417328" },
      { "name": "Dried cut french white parrot tulip", "code": "10417329" },
      { "name": "Dried cut frilly edge lavender tulip", "code": "10417330" },
      { "name": "Dried cut hot pink tulip", "code": "10417331" },
      {
        "name": "Dried cut hot pink variegated folia tulip",
        "code": "10417332"
      },
      { "name": "Dried cut lavender tulip", "code": "10417333" },
      {
        "name": "Dried cut light pink variegated folia tulip",
        "code": "10417334"
      },
      { "name": "Dried cut merry widow tulip", "code": "10417335" },
      { "name": "Dried cut orange tulip", "code": "10417336" },
      { "name": "Dried cut parrot black tulip", "code": "10417337" },
      { "name": "Dried cut parrot estella rijnveld tulip", "code": "10417338" },
      { "name": "Dried cut parrot flaming tulip", "code": "10417339" },
      { "name": "Dried cut parrot green tulip", "code": "10417340" },
      { "name": "Dried cut parrot lavender tulip", "code": "10417341" },
      { "name": "Dried cut parrot orange tulip", "code": "10417342" },
      { "name": "Dried cut parrot peach tulip", "code": "10417343" },
      { "name": "Dried cut parrot pink tulip", "code": "10417344" },
      { "name": "Dried cut parrot red tulip", "code": "10417345" },
      { "name": "Dried cut parrot rococo red tulip", "code": "10417346" },
      { "name": "Dried cut parrot weber tulip", "code": "10417347" },
      { "name": "Dried cut parrot white tulip", "code": "10417348" },
      { "name": "Dried cut parrot yellow tulip", "code": "10417349" },
      { "name": "Dried cut pink tulip", "code": "10417350" },
      { "name": "Dried cut purple tulip", "code": "10417351" },
      { "name": "Dried cut red tulip", "code": "10417352" },
      { "name": "Dried cut species tulip", "code": "10417353" },
      { "name": "Dried cut white tulip", "code": "10417354" },
      { "name": "Dried cut yellow tulip", "code": "10417355" },
      { "name": "Dried cut waxflowers", "code": "10417400" },
      { "name": "Dried cut alba waxflower", "code": "10417401" },
      { "name": "Dried cut bi color waxflower", "code": "10417402" },
      { "name": "Dried cut chinchilla waxflower", "code": "10417403" },
      { "name": "Dried cut dancing queen waxflower", "code": "10417404" },
      { "name": "Dried cut danmark waxflower", "code": "10417405" },
      { "name": "Dried cut denmar pearl waxflower", "code": "10417406" },
      {
        "name": "Dried cut hybrid pastel gemflower waxflower",
        "code": "10417407"
      },
      {
        "name": "Dried cut hybrid pink gemflower waxflower",
        "code": "10417408"
      },
      {
        "name": "Dried cut hybrid blondie white waxflower",
        "code": "10417409"
      },
      { "name": "Dried cut hybrid eric john waxflower", "code": "10417410" },
      { "name": "Dried cut hybrid painted lady waxflower", "code": "10417411" },
      { "name": "Dried cut hybrid revelation waxflower", "code": "10417412" },
      { "name": "Dried cut hybrid snowball waxflower", "code": "10417413" },
      { "name": "Dried cut juriens brook waxflower", "code": "10417414" },
      { "name": "Dried cut lady stephany pink waxflower", "code": "10417415" },
      { "name": "Dried cut madonna waxflower", "code": "10417416" },
      { "name": "Dried cut mini white waxflower", "code": "10417417" },
      { "name": "Dried cut orange waxflower", "code": "10417418" },
      { "name": "Dried cut pearl waxflower", "code": "10417419" },
      { "name": "Dried cut pixie moon waxflower", "code": "10417420" },
      { "name": "Dried cut purple pride waxflower", "code": "10417421" },
      { "name": "Dried cut red waxflower", "code": "10417422" },
      { "name": "Dried cut wanaroo waxflower", "code": "10417423" },
      { "name": "Dried cut yellow waxflower", "code": "10417424" },
      { "name": "Dried cut yarrows", "code": "10417500" },
      { "name": "Dried cut burgundy yarrow", "code": "10417501" },
      { "name": "Dried cut cottage creme yarrow", "code": "10417502" },
      { "name": "Dried cut cottage pink yarrow", "code": "10417503" },
      { "name": "Dried cut moonshine yarrow", "code": "10417504" },
      { "name": "Dried cut orange yarrow", "code": "10417505" },
      { "name": "Dried cut peach yarrow", "code": "10417506" },
      { "name": "Dried cut pink yarrow", "code": "10417507" },
      { "name": "Dried cut red dyed yarrow", "code": "10417508" },
      { "name": "Dried cut white yarrow", "code": "10417509" },
      { "name": "Dried cut yellow yarrow", "code": "10417510" },
      { "name": "Dried cut zinnias", "code": "10417600" },
      { "name": "Dried cut hot pink zinnia", "code": "10417601" },
      { "name": "Dried cut mini zinnia", "code": "10417602" },
      { "name": "Dried cut pink zinnia", "code": "10417603" },
      { "name": "Dried cut red zinnia", "code": "10417604" },
      { "name": "Dried cut salmon zinnia", "code": "10417605" },
      { "name": "Dried cut yellow zinnia", "code": "10417606" },
      { "name": "Dried cut forsythias", "code": "10417700" },
      { "name": "Dried cut forsythia viridissima", "code": "10417701" },
      { "name": "Dried cut forsythia giraldiana", "code": "10417702" },
      { "name": "Dried cut forsythia mira", "code": "10417703" },
      { "name": "Dried cut forsythia suspensa", "code": "10417704" },
      { "name": "Dried cut forsythia intermedia", "code": "10417705" },
      { "name": "Dried cut forsythia variabilis", "code": "10417706" },
      { "name": "Dried cut forsythia ovate", "code": "10417707" },
      { "name": "Dried cut forsythia intermedia lynnwood", "code": "10417708" },
      { "name": "Dried cut geraniums or cranesbills", "code": "10417800" },
      { "name": "Dried cut argenteum geranium", "code": "10417801" },
      { "name": "Dried cut cinereum geranium", "code": "10417802" },
      { "name": "Dried cut clarkei geranium", "code": "10417803" },
      { "name": "Dried cut dalmaticum geranium", "code": "10417804" },
      { "name": "Dried cut endressii geranium", "code": "10417805" },
      { "name": "Dried cut eriostemon geranium", "code": "10417806" },
      { "name": "Dried cut farreri geranium", "code": "10417807" },
      {
        "name": "Dried cut himalayense or grandiflorum geranium",
        "code": "10417808"
      },
      { "name": "Dried cut ibericum geranium", "code": "10417809" },
      {
        "name": "Dried cut macrorrhizum or bigroot geranium",
        "code": "10417810"
      },
      { "name": "Dried cut maculatum geranium", "code": "10417811" },
      { "name": "Dried cut nodosum geranium", "code": "10417812" },
      { "name": "Dried cut phaeum geranium", "code": "10417813" },
      { "name": "Dried cut platypetalum geranium", "code": "10417814" },
      { "name": "Dried cut pratense geranium", "code": "10417815" },
      { "name": "Dried cut procurrens geranium", "code": "10417816" },
      { "name": "Dried cut psilostemon geranium", "code": "10417817" },
      { "name": "Dried cut pylzowianum geranium", "code": "10417818" },
      { "name": "Dried cut renardii geranium", "code": "10417819" },
      { "name": "Dried cut sanguineum or bloody geranium", "code": "10417820" },
      { "name": "Dried cut sylvaticum geranium", "code": "10417821" },
      { "name": "Dried cut traversii geranium", "code": "10417822" },
      { "name": "Dried cut tuberosum geranium", "code": "10417823" },
      { "name": "Dried cut versicolor geranium", "code": "10417824" },
      { "name": "Dried cut wallichianum geranium", "code": "10417825" },
      { "name": "Dried cut wlassovianum geranium", "code": "10417826" },
      {
        "name": "Dried cut x magnificum or showy geranium",
        "code": "10417827"
      },
      { "name": "Dried cut hippeastrums", "code": "10417900" },
      { "name": "Dried cut aglaiae hippeastrum", "code": "10417901" },
      { "name": "Dried cut amaru hippeastrum", "code": "10417902" },
      { "name": "Dried cut angustifolium hippeastrum", "code": "10417903" },
      { "name": "Dried cut anzaldoi hippeastrum", "code": "10417904" },
      { "name": "Dried cut araripinum hippeastrum", "code": "10417905" },
      { "name": "Dried cut arboricola hippeastrum", "code": "10417906" },
      { "name": "Dried cut argentinum hippeastrum", "code": "10417907" },
      { "name": "Dried cut aulicum hippeastrum", "code": "10417908" },
      { "name": "Dried cut aviflorum hippeastrum", "code": "10417909" },
      { "name": "Dried cut barreirasum hippeastrum", "code": "10417910" },
      { "name": "Dried cut blossfeldiae hippeastrum", "code": "10417911" },
      { "name": "Dried cut blumenavium hippeastrum", "code": "10417912" },
      { "name": "Dried cut brasilianum hippeastrum", "code": "10417913" },
      { "name": "Dried cut breviflorum hippeastrum", "code": "10417914" },
      { "name": "Dried cut bukasovii hippeastrum", "code": "10417915" },
      { "name": "Dried cut calyptratum hippeastrum", "code": "10417916" },
      { "name": "Dried cut caupolicanense hippeastrum", "code": "10417917" },
      { "name": "Dried cut chionedyanthum hippeastrum", "code": "10417918" },
      { "name": "Dried cut condemaita hippeastrum", "code": "10417919" },
      { "name": "Dried cut corriense hippeastrum", "code": "10417920" },
      { "name": "Dried cut cuzcoense hippeastrum", "code": "10417921" },
      { "name": "Dried cut curitibanum hippeastrum", "code": "10417922" },
      { "name": "Dried cut cybister hippeastrum", "code": "10417923" },
      { "name": "Dried cut divijuliani hippeastrum", "code": "10417924" },
      { "name": "Dried cut evansiae hippeastrum", "code": "10417925" },
      { "name": "Dried cut ferreyrae hippeastrum", "code": "10417926" },
      { "name": "Dried cut forgetii hippeastrum", "code": "10417927" },
      { "name": "Dried cut fosteri hippeastrum", "code": "10417928" },
      { "name": "Dried cut fuscum hippeastrum", "code": "10417929" },
      { "name": "Dried cut glaucescens hippeastrum", "code": "10417930" },
      { "name": "Dried cut goianum hippeastrum", "code": "10417931" },
      { "name": "Dried cut guarapuavicum hippeastrum", "code": "10417932" },
      { "name": "Dried cut harrisonii hippeastrum", "code": "10417933" },
      { "name": "Dried cut hugoi hippeastrum", "code": "10417934" },
      { "name": "Dried cut iguazuanum hippeastrum", "code": "10417935" },
      { "name": "Dried cut illustre hippeastrum", "code": "10417936" },
      { "name": "Dried cut intiflorum hippeastrum", "code": "10417937" },
      { "name": "Dried cut kromeri hippeastrum", "code": "10417938" },
      { "name": "Dried cut lapacense hippeastrum", "code": "10417939" },
      { "name": "Dried cut leonardii hippeastrum", "code": "10417940" },
      { "name": "Dried cut leopoldii hippeastrum", "code": "10417941" },
      { "name": "Dried cut macbridei hippeastrum", "code": "10417942" },
      { "name": "Dried cut machupijchense hippeastrum", "code": "10417943" },
      { "name": "Dried cut mandonii hippeastrum", "code": "10417944" },
      { "name": "Dried cut minasgerais hippeastrum", "code": "10417945" },
      { "name": "Dried cut miniatum hippeastrum", "code": "10417946" },
      { "name": "Dried cut mollevillquense hippeastrum", "code": "10417947" },
      { "name": "Dried cut morelianum hippeastrum", "code": "10417948" },
      { "name": "Dried cut nelsonii hippeastrum", "code": "10417949" },
      { "name": "Dried cut oconoquense hippeastrum", "code": "10417950" },
      { "name": "Dried cut papilio hippeastrum", "code": "10417951" },
      { "name": "Dried cut paquichanum hippeastrum", "code": "10417952" },
      { "name": "Dried cut paradisiacum hippeastrum", "code": "10417953" },
      { "name": "Dried cut pardinum hippeastrum", "code": "10417954" },
      { "name": "Dried cut parodii hippeastrum", "code": "10417955" },
      { "name": "Dried cut petiolatum hippeastrum", "code": "10417956" },
      { "name": "Dried cut psittacinum hippeastrum", "code": "10417957" },
      { "name": "Dried cut puniceum hippeastrum", "code": "10417958" },
      { "name": "Dried cut reginae hippeastrum", "code": "10417959" },
      { "name": "Dried cut reticulatum hippeastrum", "code": "10417960" },
      { "name": "Dried cut rubropictum hippeastrum", "code": "10417961" },
      { "name": "Dried cut santacatarina hippeastrum", "code": "10417962" },
      { "name": "Dried cut solandraeflorum hippeastrum", "code": "10417963" },
      { "name": "Dried cut starkiorum hippeastrum", "code": "10417964" },
      { "name": "Dried cut striatum hippeastrum", "code": "10417965" },
      { "name": "Dried cut stylosum hippeastrum", "code": "10417966" },
      { "name": "Dried cut teyucuarense hippeastrum", "code": "10417967" },
      { "name": "Dried cut traubii hippeastrum", "code": "10417968" },
      { "name": "Dried cut vargasii hippeastrum", "code": "10417969" },
      { "name": "Dried cut variegatum hippeastrum", "code": "10417970" },
      { "name": "Dried cut vittatum hippeastrum", "code": "10417971" },
      { "name": "Dried cut yungacense hippeastrum", "code": "10417972" },
      { "name": "Dried cut rudbeckia or coneflower", "code": "10418000" },
      { "name": "Dried cut alpicola rudbeckia", "code": "10418001" },
      { "name": "Dried cut amplexicaulis rudbeckia", "code": "10418002" },
      { "name": "Dried cut auriculata rudbeckia", "code": "10418003" },
      { "name": "Dried cut bi color rudbeckia", "code": "10418004" },
      { "name": "Dried cut californica rudbeckia", "code": "10418005" },
      { "name": "Dried cut fulgida rudbeckia", "code": "10418006" },
      { "name": "Dried cut glaucescens rudbeckia", "code": "10418007" },
      { "name": "Dried cut graminifolia rudbeckia", "code": "10418008" },
      { "name": "Dried cut grandiflora rudbeckia", "code": "10418009" },
      { "name": "Dried cut heliopsidis rudbeckia", "code": "10418010" },
      { "name": "Dried cut hirta rudbeckia", "code": "10418011" },
      { "name": "Dried cut klamathensis rudbeckia", "code": "10418012" },
      { "name": "Dried cut laciniata rudbeckia", "code": "10418013" },
      { "name": "Dried cut maxima rudbeckia", "code": "10418014" },
      { "name": "Dried cut missouriensis rudbeckia", "code": "10418015" },
      { "name": "Dried cut mohrii rudbeckia", "code": "10418016" },
      { "name": "Dried cut mollis rudbeckia", "code": "10418017" },
      { "name": "Dried cut montana rudbeckia", "code": "10418018" },
      { "name": "Dried cut nitida rudbeckia", "code": "10418019" },
      { "name": "Dried cut occidentalis rudbeckia", "code": "10418020" },
      { "name": "Dried cut pinnata rudbeckia", "code": "10418021" },
      { "name": "Dried cut scabrifolia rudbeckia", "code": "10418022" },
      { "name": "Dried cut serotina rudbeckia", "code": "10418023" },
      { "name": "Dried cut speciosa rudbeckia", "code": "10418024" },
      { "name": "Dried cut subtomentosa rudbeckia", "code": "10418025" },
      { "name": "Dried cut texana rudbeckia", "code": "10418026" },
      { "name": "Dried cut triloba rudbeckia", "code": "10418027" },
      { "name": "Dried cut proteas", "code": "10418100" },
      { "name": "Dried cut bouquet protea", "code": "10418101" },
      { "name": "Dried cut bottle brush protea", "code": "10418102" },
      { "name": "Dried cut carnival protea", "code": "10418103" },
      { "name": "Dried cut cordata foliage protea", "code": "10418104" },
      { "name": "Dried cut grandiceps protea", "code": "10418105" },
      { "name": "Dried cut green mink protea", "code": "10418106" },
      { "name": "Dried cut ivy protea", "code": "10418107" },
      { "name": "Dried cut king protea", "code": "10418108" },
      { "name": "Dried cut nana cones protea", "code": "10418109" },
      { "name": "Dried cut pincushion orange protea", "code": "10418110" },
      { "name": "Dried cut pincushion tango protea", "code": "10418111" },
      { "name": "Dried cut pincushion yellow protea", "code": "10418112" },
      { "name": "Dried cut pink ice protea", "code": "10418113" },
      { "name": "Dried cut pink mink protea", "code": "10418114" },
      { "name": "Dried cut queen protea", "code": "10418115" },
      { "name": "Dried cut repens protea", "code": "10418116" },
      { "name": "Dried cut rosespoon protea", "code": "10418117" },
      { "name": "Dried cut silvia protea", "code": "10418118" },
      { "name": "Dried cut sugar protea", "code": "10418119" },
      { "name": "Dried cut susara protea", "code": "10418120" },
      { "name": "Dried cut waratha long protea", "code": "10418121" },
      { "name": "Dried cut white mink protea", "code": "10418122" },
      { "name": "Dried cut leucadendrons", "code": "10418200" },
      { "name": "Dried cut argenteum leucadendron", "code": "10418201" },
      { "name": "Dried cut creme delight leucadendron", "code": "10418202" },
      { "name": "Dried cut cumosum leucadendron", "code": "10418203" },
      { "name": "Dried cut discolor leucadendron", "code": "10418204" },
      { "name": "Dried cut galpini leucadendron", "code": "10418205" },
      { "name": "Dried cut gold strike leucadendron", "code": "10418206" },
      { "name": "Dried cut inca gold leucadendron", "code": "10418207" },
      { "name": "Dried cut jester leucadendron", "code": "10418208" },
      { "name": "Dried cut laxum leucadendron", "code": "10418209" },
      { "name": "Dried cut mini leucadendron", "code": "10418210" },
      { "name": "Dried cut patea gold leucadendron", "code": "10418211" },
      { "name": "Dried cut petra leucadendron", "code": "10418212" },
      { "name": "Dried cut plumosum leucadendron", "code": "10418213" },
      { "name": "Dried cut rosette leucadendron", "code": "10418214" },
      { "name": "Dried cut safari sunset leucadendron", "code": "10418215" },
      {
        "name": "Dried cut safari sunset spr leucadendron",
        "code": "10418216"
      },
      { "name": "Dried cut speciosa leucadendron", "code": "10418217" },
      { "name": "Dried cut spray leucadendron", "code": "10418218" },
      { "name": "Dried cut wilson wonder leucadendron", "code": "10418219" },
      { "name": "Dried cut yarden leucadendron", "code": "10418220" },
      { "name": "Dried cut leucospermums", "code": "10418300" },
      { "name": "Dried cut leucospermum album", "code": "10418301" },
      { "name": "Dried cut leucospermum attenuatum", "code": "10418302" },
      { "name": "Dried cut leucospermum calligerum", "code": "10418303" },
      { "name": "Dried cut leucospermum conocarpodendron", "code": "10418304" },
      { "name": "Dried cut leucospermum cordatum", "code": "10418305" },
      { "name": "Dried cut leucospermum cuneiforme", "code": "10418306" },
      { "name": "Dried cut leucospermum formosum", "code": "10418307" },
      { "name": "Dried cut leucospermum glabrum", "code": "10418308" },
      { "name": "Dried cut leucospermum grandiflorum", "code": "10418309" },
      { "name": "Dried cut leucospermum harmatum", "code": "10418310" },
      { "name": "Dried cut leucospermum heterophyllum", "code": "10418311" },
      { "name": "Dried cut leucospermum innovans", "code": "10418312" },
      { "name": "Dried cut leucospermum muirii", "code": "10418313" },
      { "name": "Dried cut leucospermum oleifolium", "code": "10418314" },
      { "name": "Dried cut leucospermum patersonii", "code": "10418315" },
      { "name": "Dried cut leucospermum pluridens", "code": "10418316" },
      { "name": "Dried cut leucospermum praemorsum", "code": "10418317" },
      { "name": "Dried cut leucospermum prostratum", "code": "10418318" },
      { "name": "Dried cut leucospermum rodolentum", "code": "10418319" },
      { "name": "Dried cut leucospermum saxatile", "code": "10418320" },
      { "name": "Dried cut leucospermum secundifolium", "code": "10418321" },
      { "name": "Dried cut leucospermum tomentosus", "code": "10418322" },
      { "name": "Dried cut leucospermum truncatulum", "code": "10418323" },
      { "name": "Dried cut leucospermum utriculosum", "code": "10418324" },
      { "name": "Dried cut leucospermum winterii", "code": "10418325" },
      { "name": "Dried cut leucospermum arenarium", "code": "10418326" },
      { "name": "Dried cut leucospermum bolusii", "code": "10418327" },
      { "name": "Dried cut leucospermum catherinae", "code": "10418328" },
      { "name": "Dried cut leucospermum conocarpum", "code": "10418329" },
      { "name": "Dried cut leucospermum cordifolium", "code": "10418330" },
      { "name": "Dried cut leucospermum erubescens", "code": "10418331" },
      { "name": "Dried cut leucospermum gerrardii", "code": "10418332" },
      { "name": "Dried cut leucospermum gracile", "code": "10418333" },
      { "name": "Dried cut leucospermum gueinzii", "code": "10418334" },
      { "name": "Dried cut leucospermum harpagonatum", "code": "10418335" },
      {
        "name": "Dried cut leucospermum hypophyllocarpodendron",
        "code": "10418336"
      },
      { "name": "Dried cut leucospermum lineare", "code": "10418337" },
      { "name": "Dried cut leucospermum mundii", "code": "10418338" },
      { "name": "Dried cut leucospermum parile", "code": "10418339" },
      { "name": "Dried cut leucospermum pendunculatum", "code": "10418340" },
      { "name": "Dried cut leucospermum praecox", "code": "10418341" },
      { "name": "Dried cut leucospermum profugum", "code": "10418342" },
      { "name": "Dried cut leucospermum reflexum", "code": "10418343" },
      { "name": "Dried cut leucospermum royenifolium", "code": "10418344" },
      { "name": "Dried cut leucospermum saxosum", "code": "10418345" },
      { "name": "Dried cut leucospermum spathulatum", "code": "10418346" },
      { "name": "Dried cut leucospermum tottum", "code": "10418347" },
      { "name": "Dried cut leucospermum truncatum", "code": "10418348" },
      { "name": "Dried cut leucospermum vestitum", "code": "10418349" },
      { "name": "Dried cut leucospermum wittebergense", "code": "10418350" },
      {
        "name": "Dried cut blooms of low species or variety count flowers",
        "code": "10420000"
      },
      { "name": "Dried cut agapanthuses", "code": "10421500" },
      { "name": "Dried cut blue agapanthus", "code": "10421501" },
      { "name": "Dried cut white agapanthus", "code": "10421502" },
      { "name": "Dried cut alchemillas", "code": "10421600" },
      { "name": "Dried cut ladys mantle alchemilla", "code": "10421601" },
      { "name": "Dried cut robustica alchemilla", "code": "10421602" },
      { "name": "Dried cut alstilbes", "code": "10421700" },
      { "name": "Dried cut hot pink astilbe", "code": "10421701" },
      { "name": "Dried cut light pink astilbe", "code": "10421702" },
      { "name": "Dried cut red astilbe", "code": "10421703" },
      { "name": "Dried cut white astilbe", "code": "10421704" },
      { "name": "Dried cut angelicas", "code": "10421800" },
      { "name": "Dried cut gigas angelica", "code": "10421801" },
      { "name": "Dried cut sylvestris angelica", "code": "10421802" },
      { "name": "Dried cut artemesias", "code": "10421900" },
      { "name": "Dried cut green artemesia", "code": "10421901" },
      { "name": "Dried cut silver king artemesia", "code": "10421902" },
      { "name": "Dried cut artichoke flowers", "code": "10422000" },
      { "name": "Dried cut chocolate artichoke flower", "code": "10422001" },
      { "name": "Dried cut green artichoke flower", "code": "10422002" },
      {
        "name": "Dried cut purple or flowering artichoke flower",
        "code": "10422003"
      },
      { "name": "Dried cut astrantias", "code": "10422100" },
      { "name": "Dried cut pink astrantia", "code": "10422101" },
      { "name": "Dried cut white astrantia", "code": "10422102" },
      { "name": "Dried cut banana flowers", "code": "10422200" },
      { "name": "Dried cut orange banana flower", "code": "10422201" },
      { "name": "Dried cut orange torch banana flower", "code": "10422202" },
      { "name": "Dried cut purple banana flower", "code": "10422203" },
      { "name": "Dried cut baptisias", "code": "10422300" },
      { "name": "Dried cut australis baptisia", "code": "10422301" },
      { "name": "Dried cut sphaerocarpa baptisia", "code": "10422302" },
      { "name": "Dried cut boronias", "code": "10422400" },
      { "name": "Dried cut pink boronia", "code": "10422401" },
      { "name": "Dried cut yellow boronia", "code": "10422402" },
      { "name": "Dried cut bromelias", "code": "10422500" },
      { "name": "Dried cut yellow reg bromelia", "code": "10422501" },
      { "name": "Dried cut red reg bromelia", "code": "10422502" },
      { "name": "Dried cut brunias", "code": "10422600" },
      { "name": "Dried cut albiflora brunia", "code": "10422601" },
      { "name": "Dried cut albiflora green brunia", "code": "10422602" },
      { "name": "Dried cut silver spray brunia", "code": "10422603" },
      { "name": "Dried cut calatheas", "code": "10422700" },
      { "name": "Dried cut cigar calathea", "code": "10422701" },
      { "name": "Dried cut green ice calathea", "code": "10422702" },
      { "name": "Dried cut rattlesnake calathea", "code": "10422703" },
      { "name": "Dried cut calcynias", "code": "10422800" },
      { "name": "Dried cut pink calcynia", "code": "10422801" },
      { "name": "Dried cut princess calcynia", "code": "10422802" },
      { "name": "Dried cut white calcynia", "code": "10422803" },
      { "name": "Dried cut calendulas", "code": "10422900" },
      { "name": "Dried cut orange calendula", "code": "10422901" },
      { "name": "Dried cut yellow calendula", "code": "10422902" },
      { "name": "Dried cut campanulas or bellflowers", "code": "10423000" },
      { "name": "Dried cut blue bells campanula", "code": "10423001" },
      { "name": "Dried cut pink bells campanula", "code": "10423002" },
      { "name": "Dried cut white bells campanula", "code": "10423003" },
      { "name": "Dried cut cestrums", "code": "10423100" },
      { "name": "Dried cut red cestrum", "code": "10423101" },
      { "name": "Dried cut red zohar cestrum", "code": "10423102" },
      { "name": "Dried cut yellow cestrum", "code": "10423103" },
      { "name": "Dried cut chasmanthes", "code": "10423200" },
      { "name": "Dried cut floribunda yellow chasmanthe", "code": "10423201" },
      { "name": "Dried cut floribundi orange chasmanthe", "code": "10423202" },
      { "name": "Dried cut costuses", "code": "10423300" },
      { "name": "Dried cut barbatus costus", "code": "10423301" },
      { "name": "Dried cut indian head costus", "code": "10423302" },
      { "name": "Dried cut crocosmias", "code": "10423400" },
      { "name": "Dried cut lucifer crocosmia", "code": "10423401" },
      { "name": "Dried cut pods crocosmia", "code": "10423402" },
      { "name": "Dried cut yellow crocosmia", "code": "10423403" },
      { "name": "Dried cut cytanthuses", "code": "10423500" },
      { "name": "Dried cut bright orange cyrtanthus", "code": "10423501" },
      { "name": "Dried cut creme cyrtanthus", "code": "10423502" },
      { "name": "Dried cut orange cyrtanthus", "code": "10423503" },
      { "name": "Dried cut yellow cyrtanthus", "code": "10423504" },
      { "name": "Dried cut rumex or dock flowers", "code": "10423600" },
      { "name": "Dried cut green dock flower", "code": "10423601" },
      { "name": "Dried cut red dock flower", "code": "10423602" },
      { "name": "Dried cut eryngiums", "code": "10423700" },
      { "name": "Dried cut tinted black eryngium", "code": "10423701" },
      { "name": "Dried cut tinted orange eryngium", "code": "10423702" },
      { "name": "Dried cut feverfews", "code": "10423800" },
      { "name": "Dried cut single vegmo feverfew", "code": "10423801" },
      { "name": "Dried cut double white feverfew", "code": "10423802" },
      { "name": "Dried cut snowball feverfew", "code": "10423803" },
      { "name": "Dried cut white feverfew", "code": "10423804" },
      { "name": "Dried cut forget me nots", "code": "10423900" },
      { "name": "Dried cut blue forget me not", "code": "10423901" },
      { "name": "Dried cut white forget me not", "code": "10423902" },
      { "name": "Dried cut gaillardias", "code": "10424000" },
      { "name": "Dried cut orange gaillardia", "code": "10424001" },
      { "name": "Dried cut yellow gaillardia", "code": "10424002" },
      { "name": "Dried cut gentianas", "code": "10424100" },
      { "name": "Dried cut blue gentiana", "code": "10424101" },
      { "name": "Dried cut white gentiana", "code": "10424102" },
      { "name": "Dried cut glamini gladioluses", "code": "10424200" },
      { "name": "Dried cut pink glamini gladiolus", "code": "10424201" },
      { "name": "Dried cut red glamini gladiolus", "code": "10424202" },
      { "name": "Dried cut gloriosas", "code": "10424300" },
      { "name": "Dried cut orange gloriosa", "code": "10424301" },
      { "name": "Dried cut red gloriosa", "code": "10424302" },
      { "name": "Dried cut yellow gloriosa", "code": "10424303" },
      {
        "name": "Dried cut gomphrena globosa or globe amaranth",
        "code": "10424400"
      },
      { "name": "Dried cut orange gomphrena globosa", "code": "10424401" },
      { "name": "Dried cut pink gomphrena globosa", "code": "10424402" },
      { "name": "Dried cut hellebores", "code": "10424500" },
      { "name": "Dried cut green hellebore", "code": "10424501" },
      { "name": "Dried cut moonshine hellebore", "code": "10424502" },
      { "name": "Dried cut ixias", "code": "10424600" },
      { "name": "Dried cut pink ixia", "code": "10424601" },
      { "name": "Dried cut white ixia", "code": "10424602" },
      { "name": "Dried cut liatrises", "code": "10424700" },
      { "name": "Dried cut purple liatris", "code": "10424701" },
      { "name": "Dried cut spray liatris", "code": "10424702" },
      { "name": "Dried cut white liatris", "code": "10424703" },
      { "name": "Dried cut lysimachias", "code": "10424800" },
      { "name": "Dried cut punctata lysimachia", "code": "10424801" },
      { "name": "Dried cut vulgaris lysimachia", "code": "10424802" },
      { "name": "Dried cut white lysimachia", "code": "10424803" },
      { "name": "Dried cut yellow lysimachia", "code": "10424804" },
      { "name": "Dried cut maracas", "code": "10424900" },
      { "name": "Dried cut brown maraca", "code": "10424901" },
      { "name": "Dried cut shampoo ginger maraca", "code": "10424902" },
      { "name": "Dried cut marigolds", "code": "10425000" },
      { "name": "Dried cut french marigold", "code": "10425001" },
      { "name": "Dried cut green marigold", "code": "10425002" },
      { "name": "Dried cut orange marigold", "code": "10425003" },
      { "name": "Dried cut yellow marigold", "code": "10425004" },
      { "name": "Dried cut mimosas", "code": "10425100" },
      { "name": "Dried cut blue or purple mimosa", "code": "10425101" },
      { "name": "Dried cut finger mimosa", "code": "10425102" },
      { "name": "Dried cut floribunda or italy mimosa", "code": "10425103" },
      { "name": "Dried cut mirandole mimosa", "code": "10425104" },
      { "name": "Dried cut nerines", "code": "10425200" },
      { "name": "Dried cut pink nerine", "code": "10425201" },
      { "name": "Dried cut white sarniensis nerine", "code": "10425202" },
      { "name": "Dried cut pepperberry flowers", "code": "10425300" },
      { "name": "Dried cut hanging pepperberry flower", "code": "10425301" },
      { "name": "Dried cut leafless pepperberry flower", "code": "10425302" },
      {
        "name": "Dried cut upright brazilian pepperberry flower",
        "code": "10425303"
      },
      { "name": "Dried cut phlox", "code": "10425400" },
      { "name": "Dried cut dark pink phlox", "code": "10425401" },
      { "name": "Dried cut lavender phlox", "code": "10425402" },
      { "name": "Dried cut light pink phlox", "code": "10425403" },
      { "name": "Dried cut white phlox", "code": "10425404" },
      {
        "name": "Dried cut physostegias or obedient plant",
        "code": "10425500"
      },
      { "name": "Dried cut pink physostegia", "code": "10425501" },
      { "name": "Dried cut pods physostegia", "code": "10425502" },
      { "name": "Dried cut white physostegia", "code": "10425503" },
      { "name": "Dried cut saponarias", "code": "10425600" },
      { "name": "Dried cut pink saponaria", "code": "10425601" },
      { "name": "Dried cut white saponaria", "code": "10425602" },
      { "name": "Dried cut sarracenias", "code": "10425700" },
      { "name": "Dried cut flava rugelii sarracenia", "code": "10425701" },
      { "name": "Dried cut scillas", "code": "10425800" },
      { "name": "Dried cut campanulata blue scilla", "code": "10425801" },
      { "name": "Dried cut campanulata pink scilla", "code": "10425802" },
      { "name": "Dried cut campanulata white scilla", "code": "10425803" },
      { "name": "Dried cut peruviana scilla", "code": "10425804" },
      { "name": "Dried cut sedums", "code": "10425900" },
      { "name": "Dried cut brown sedum", "code": "10425901" },
      { "name": "Dried cut green sedum", "code": "10425902" },
      { "name": "Dried cut pink sedum", "code": "10425903" },
      { "name": "Dried cut red sedum", "code": "10425904" },
      {
        "name": "Dried cut single species or varieties of flowers",
        "code": "10426000"
      },
      { "name": "Dried cut agrostemma", "code": "10426001" },
      { "name": "Dried cut kniphofia or assegai poker", "code": "10426002" },
      { "name": "Dried cut bellis perennis", "code": "10426003" },
      { "name": "Dried cut bells of ireland or molucella", "code": "10426004" },
      { "name": "Dried cut bird of paradise", "code": "10426005" },
      { "name": "Dried cut blushing bride", "code": "10426006" },
      { "name": "Dried cut buddleia or butterfly", "code": "10426007" },
      { "name": "Dried cut bupleurum griffithii", "code": "10426008" },
      { "name": "Dried cut california ginesta", "code": "10426009" },
      { "name": "Dried cut callicarpa purple", "code": "10426010" },
      { "name": "Dried cut white campanula bell", "code": "10426011" },
      { "name": "Dried cut candy tuft", "code": "10426012" },
      { "name": "Dried cut cariopteris", "code": "10426013" },
      { "name": "Dried cut centaurea or marco polo", "code": "10426014" },
      { "name": "Dried cut chinese lantern", "code": "10426015" },
      { "name": "Dried cut clematis recta purpurea", "code": "10426016" },
      { "name": "Dried cut cleome spinosa", "code": "10426017" },
      { "name": "Dried cut coreopsis", "code": "10426018" },
      { "name": "Dried cut blue cornflower", "code": "10426019" },
      { "name": "Dried cut chocolate cosmos", "code": "10426020" },
      { "name": "Dried cut cotinus coggygria", "code": "10426021" },
      { "name": "Dried cut craspedia or billy balls", "code": "10426022" },
      { "name": "Dried cut deutzia tall", "code": "10426023" },
      { "name": "Dried cut diosma", "code": "10426024" },
      { "name": "Dried cut echeveria succulent", "code": "10426025" },
      { "name": "Dried cut echinacea purpurea", "code": "10426026" },
      { "name": "Dried cut edelweiss", "code": "10426027" },
      { "name": "Dried cut erythronium pagoda", "code": "10426028" },
      { "name": "Dried cut eucalyptus flower", "code": "10426029" },
      { "name": "Dried cut eucharis or amazon lily", "code": "10426030" },
      { "name": "Dried cut eucomis or pineapple lily", "code": "10426031" },
      { "name": "Dried cut eupatorium maculatum", "code": "10426032" },
      { "name": "Dried cut filipendula", "code": "10426033" },
      { "name": "Dried cut foxglove", "code": "10426034" },
      { "name": "Dried cut globe gilia", "code": "10426035" },
      { "name": "Dried cut globularia blue eye", "code": "10426036" },
      { "name": "Dried cut washington hawthorne", "code": "10426037" },
      { "name": "Dried cut helenium", "code": "10426038" },
      { "name": "Dried cut helianthus", "code": "10426039" },
      { "name": "Dried cut hesperis matronalis", "code": "10426040" },
      { "name": "Dried cut houttuynia cordata chameleon", "code": "10426041" },
      { "name": "Dried cut hyacinth with bulb", "code": "10426042" },
      { "name": "Dried cut indian corn", "code": "10426043" },
      { "name": "Dried cut jack in the pulpit", "code": "10426044" },
      { "name": "Dried cut japanese tree of heaven", "code": "10426045" },
      { "name": "Dried cut jasmine flowering vine", "code": "10426046" },
      {
        "name": "Dried cut jatropha curcas or firecracker",
        "code": "10426047"
      },
      { "name": "Dried cut knautia", "code": "10426048" },
      { "name": "Dried cut kochia sedifolia", "code": "10426049" },
      { "name": "Dried cut lachenalia romaud", "code": "10426050" },
      { "name": "Dried cut lambs ears flower", "code": "10426051" },
      { "name": "Dried cut lavender", "code": "10426052" },
      { "name": "Dried cut leucocoryne speciosa", "code": "10426053" },
      { "name": "Dried cut lythrum", "code": "10426054" },
      { "name": "Dried cut malva zebrina", "code": "10426055" },
      { "name": "Dried cut marguerite white daisy", "code": "10426056" },
      { "name": "Dried cut montbretia yellow", "code": "10426057" },
      { "name": "Dried cut nebelia", "code": "10426058" },
      { "name": "Dried cut nicotiana green", "code": "10426059" },
      {
        "name": "Dried cut nigella damascena or love in the mist",
        "code": "10426060"
      },
      { "name": "Dried cut nigella pods", "code": "10426061" },
      { "name": "Dried cut nuns orchid", "code": "10426062" },
      { "name": "Dried cut paphiopedilum green orchid", "code": "10426063" },
      { "name": "Dried cut paranomus", "code": "10426064" },
      { "name": "Dried cut penstemon husker red", "code": "10426065" },
      { "name": "Dried cut peruvian apple", "code": "10426066" },
      { "name": "Dried cut phlomis sarnia", "code": "10426067" },
      { "name": "Dried cut pink lace flower or didiscus", "code": "10426068" },
      { "name": "Dried cut platycodon or balloon flower", "code": "10426069" },
      { "name": "Dried cut retzia capensis", "code": "10426070" },
      { "name": "Dried cut ricinus communis", "code": "10426071" },
      { "name": "Dried cut snow on the mountain", "code": "10426072" },
      { "name": "Dried cut solidago tinted", "code": "10426073" },
      { "name": "Dried cut white squill", "code": "10426074" },
      { "name": "Dried cut stachys byzantina", "code": "10426075" },
      { "name": "Dried cut strawflower", "code": "10426076" },
      { "name": "Dried cut succulent oscularia", "code": "10426077" },
      { "name": "Dried cut tillandsia flower", "code": "10426078" },
      { "name": "Dried cut triteleia", "code": "10426079" },
      {
        "name": "Dried cut tritoma orange or red hot poker",
        "code": "10426080"
      },
      { "name": "Dried cut veronicastrum virginiana", "code": "10426081" },
      { "name": "Dried cut vriesea splendens", "code": "10426082" },
      { "name": "Dried cut st johns wort or hypericim", "code": "10426084" },
      { "name": "Dried cut spirea", "code": "10426085" },
      { "name": "Dried cut ruscus", "code": "10426086" },
      { "name": "Dried cut solomons seals", "code": "10426100" },
      { "name": "Dried cut false solomon seal", "code": "10426101" },
      { "name": "Dried cut variegated solomons seal", "code": "10426102" },
      { "name": "Dried cut tanacetums", "code": "10426200" },
      { "name": "Dried cut amazon tanacetum", "code": "10426201" },
      {
        "name": "Dried cut victory double white tanacetum",
        "code": "10426202"
      },
      {
        "name": "Dried cut victory single white tanacetum",
        "code": "10426203"
      },
      { "name": "Dried cut yellow vegmo tanacetum", "code": "10426204" },
      { "name": "Dried cut tracheliums", "code": "10426300" },
      { "name": "Dried cut jade trachelium", "code": "10426301" },
      { "name": "Dried cut purple trachelium", "code": "10426302" },
      { "name": "Dried cut white trachelium", "code": "10426303" },
      { "name": "Dried cut tuberosas", "code": "10426400" },
      { "name": "Dried cut double tuberosa", "code": "10426401" },
      { "name": "Dried cut single tuberosa", "code": "10426402" },
      { "name": "Dried cut tweedias", "code": "10426500" },
      { "name": "Dried cut blue tweedia", "code": "10426501" },
      { "name": "Dried cut white tweedia", "code": "10426502" },
      { "name": "Dried cut veronicas", "code": "10426600" },
      { "name": "Dried cut pink veronica", "code": "10426601" },
      { "name": "Dried cut purple veronica", "code": "10426602" },
      { "name": "Dried cut white veronica", "code": "10426603" },
      { "name": "Dried cut watsonias", "code": "10426700" },
      { "name": "Dried cut orange watsonias", "code": "10426701" },
      { "name": "Dried cut pink watsonia", "code": "10426702" },
      { "name": "Dried cut red watsonia", "code": "10426703" },
      { "name": "Dried cut white watsonia", "code": "10426704" },
      { "name": "Dried cut chrysanthemums", "code": "10430000" },
      { "name": "Dried cut button chrysanthemums", "code": "10431500" },
      { "name": "Dried cut delirock pompon chrysanthemum", "code": "10431501" },
      {
        "name": "Dried cut discovery pompon chrysanthemum",
        "code": "10431502"
      },
      { "name": "Dried cut focus pompon chrysanthemum", "code": "10431503" },
      { "name": "Dried cut jeanny pompon chrysanthemum", "code": "10431504" },
      { "name": "Dried cut lady pompon chrysanthemum", "code": "10431505" },
      { "name": "Dried cut leidy pompon chrysanthemum", "code": "10431506" },
      { "name": "Dried cut lexy pompon chrysanthemum", "code": "10431507" },
      { "name": "Dried cut ole pompon chrysanthemum", "code": "10431508" },
      { "name": "Dried cut revise pompon chrysanthemum", "code": "10431509" },
      {
        "name": "Dried cut statesman pompon chrysanthemum",
        "code": "10431510"
      },
      { "name": "Dried cut sweet pompon chrysanthemum", "code": "10431511" },
      { "name": "Dried cut yoko ono pompon chrysanthemum", "code": "10431512" },
      { "name": "Dried cut zip pompon chrysanthemum", "code": "10431513" },
      { "name": "Dried cut daisy pompon chrysanthemums", "code": "10431600" },
      {
        "name": "Dried cut artist pink pompon chrysanthemum",
        "code": "10431601"
      },
      {
        "name": "Dried cut artist yellow pompon chrysanthemum",
        "code": "10431602"
      },
      {
        "name": "Dried cut atlantis pink pompon chrysanthemum",
        "code": "10431603"
      },
      {
        "name": "Dried cut atlantis white pompon chrysanthemum",
        "code": "10431604"
      },
      {
        "name": "Dried cut atlantis yellow pompon chrysanthemum",
        "code": "10431605"
      },
      {
        "name": "Dried cut bennie jolink pompon chrysanthemum",
        "code": "10431606"
      },
      {
        "name": "Dried cut bennie jolink yellow pompon chrysanthemum",
        "code": "10431607"
      },
      {
        "name": "Dried cut bronze managua pompon chrysanthemum",
        "code": "10431608"
      },
      { "name": "Dried cut clue pompon chrysanthemum", "code": "10431609" },
      {
        "name": "Dried cut coral fiction pompon chrysanthemum",
        "code": "10431610"
      },
      { "name": "Dried cut cumbia pompon chrysanthemum", "code": "10431611" },
      {
        "name": "Dried cut dark cantata pompon chrysanthemum",
        "code": "10431612"
      },
      {
        "name": "Dried cut dark lineker pompon chrysanthemum",
        "code": "10431613"
      },
      { "name": "Dried cut dipper pompon chrysanthemum", "code": "10431614" },
      {
        "name": "Dried cut elite pink pompon chrysanthemum",
        "code": "10431615"
      },
      {
        "name": "Dried cut elite white pompon chrysanthemum",
        "code": "10431616"
      },
      {
        "name": "Dried cut elite yellow pompon chrysanthemum",
        "code": "10431617"
      },
      { "name": "Dried cut factor pompon chrysanthemum", "code": "10431618" },
      { "name": "Dried cut fiction pompon chrysanthemum", "code": "10431619" },
      { "name": "Dried cut force pompon chrysanthemum", "code": "10431620" },
      {
        "name": "Dried cut improved reagan pompon chrysanthemum",
        "code": "10431621"
      },
      { "name": "Dried cut life pompon chrysanthemum", "code": "10431622" },
      {
        "name": "Dried cut managua orange pompon chrysanthemum",
        "code": "10431623"
      },
      {
        "name": "Dried cut novedad bronze cocarde pompon chrysanthemum",
        "code": "10431624"
      },
      {
        "name": "Dried cut orange reagan pompon chrysanthemum",
        "code": "10431625"
      },
      { "name": "Dried cut orinoco pompon chrysanthemum", "code": "10431626" },
      { "name": "Dried cut petra pompon chrysanthemum", "code": "10431627" },
      {
        "name": "Dried cut pink balsas pompon chrysanthemum",
        "code": "10431628"
      },
      {
        "name": "Dried cut pink mona lisa pompon chrysanthemum",
        "code": "10431629"
      },
      {
        "name": "Dried cut pink reagan pompon chrysanthemum",
        "code": "10431630"
      },
      {
        "name": "Dried cut reagan ivory pompon chrysanthemum",
        "code": "10431631"
      },
      {
        "name": "Dried cut reagan rosy pompon chrysanthemum",
        "code": "10431632"
      },
      { "name": "Dried cut rebasco pompon chrysanthemum", "code": "10431633" },
      { "name": "Dried cut redock pompon chrysanthemum", "code": "10431634" },
      {
        "name": "Dried cut salmon lineker pompon chrysanthemum",
        "code": "10431635"
      },
      { "name": "Dried cut sheba pompon chrysanthemum", "code": "10431636" },
      { "name": "Dried cut sirius pompon chrysanthemum", "code": "10431637" },
      {
        "name": "Dried cut splendid reagan pompon chrysanthemum",
        "code": "10431638"
      },
      {
        "name": "Dried cut sunny reagan pompon chrysanthemum",
        "code": "10431639"
      },
      { "name": "Dried cut tina pompon chrysanthemum", "code": "10431640" },
      { "name": "Dried cut vero pompon chrysanthemum", "code": "10431641" },
      { "name": "Dried cut volare pompon chrysanthemum", "code": "10431642" },
      {
        "name": "Dried cut white life pompon chrysanthemum",
        "code": "10431643"
      },
      {
        "name": "Dried cut white reagan pompon chrysanthemum",
        "code": "10431644"
      },
      {
        "name": "Dried cut white rhino pompon chrysanthemum",
        "code": "10431645"
      },
      {
        "name": "Dried cut yellow vero pompon chrysanthemum",
        "code": "10431646"
      },
      { "name": "Dried cut zenith pompon chrysanthemum", "code": "10431647" },
      { "name": "Dried cut cremon disbud chrysanthemums", "code": "10431700" },
      {
        "name": "Dried cut cremon annecy dark disbud chrysanthemum",
        "code": "10431701"
      },
      {
        "name": "Dried cut cremon atlantis disbud chrysanthemum",
        "code": "10431702"
      },
      {
        "name": "Dried cut cremon atlantis pink disbud chrysanthemum",
        "code": "10431703"
      },
      {
        "name": "Dried cut cremon eleonora bronze disbud chrysanthemum",
        "code": "10431704"
      },
      {
        "name": "Dried cut cremon eleonora lilac disbud chrysanthemum",
        "code": "10431705"
      },
      {
        "name": "Dried cut cremon eleonora pink disbud chrysanthemum",
        "code": "10431706"
      },
      {
        "name": "Dried cut cremon eleonora snow disbud chrysanthemum",
        "code": "10431707"
      },
      {
        "name": "Dried cut cremon eleonora yellow disbud chrysanthemum",
        "code": "10431708"
      },
      {
        "name": "Dried cut cremon idea disbud chrysanthemum",
        "code": "10431709"
      },
      {
        "name": "Dried cut cremon ivanna purple disbud chrysanthemum",
        "code": "10431710"
      },
      {
        "name": "Dried cut cremon minka pink disbud chrysanthemum",
        "code": "10431711"
      },
      {
        "name": "Dried cut cremon ready disbud chrysanthemum",
        "code": "10431712"
      },
      {
        "name": "Dried cut cremon sinatra disbud chrysanthemum",
        "code": "10431713"
      },
      { "name": "Dried cut rover red chrysanthemum", "code": "10431714" },
      {
        "name": "Dried cut football disbud chrysanthemums",
        "code": "10431800"
      },
      { "name": "Dried cut blaze disbud chrysanthemum", "code": "10431801" },
      {
        "name": "Dried cut football kiss disbud chrysanthemum",
        "code": "10431802"
      },
      {
        "name": "Dried cut football lavender/pink disbud chrysanthemum",
        "code": "10431803"
      },
      {
        "name": "Dried cut football resouci disbud chrysanthemum",
        "code": "10431804"
      },
      {
        "name": "Dried cut football white disbud chrysanthemum",
        "code": "10431805"
      },
      {
        "name": "Dried cut football yellow disbud chrysanthemum",
        "code": "10431806"
      },
      {
        "name": "Dried cut promenade disbud chrysanthemum",
        "code": "10431807"
      },
      { "name": "Dried cut rebonnet disbud chrysanthemum", "code": "10431808" },
      { "name": "Dried cut reflex disbud chrysanthemum", "code": "10431809" },
      {
        "name": "Dried cut residence disbud chrysanthemum",
        "code": "10431810"
      },
      {
        "name": "Dried cut resomee pearl disbud chrysanthemum",
        "code": "10431811"
      },
      { "name": "Dried cut resouci disbud chrysanthemum", "code": "10431812" },
      { "name": "Dried cut spider chrysanthemums", "code": "10431900" },
      {
        "name": "Dried cut anastasia bronze spider chrysanthemum",
        "code": "10431901"
      },
      {
        "name": "Dried cut anastasia dark bronze spider chrysanthemum",
        "code": "10431902"
      },
      {
        "name": "Dried cut anastasia green spider chrysanthemum",
        "code": "10431903"
      },
      {
        "name": "Dried cut anastasia lilac spider chrysanthemum",
        "code": "10431904"
      },
      {
        "name": "Dried cut anastasia pink spider chrysanthemum",
        "code": "10431905"
      },
      {
        "name": "Dried cut anastasia purple spider chrysanthemum",
        "code": "10431906"
      },
      {
        "name": "Dried cut anastasia sunny spider chrysanthemum",
        "code": "10431907"
      },
      {
        "name": "Dried cut anastasia white spider chrysanthemum",
        "code": "10431908"
      },
      { "name": "Dried cut bradford spider chrysanthemum", "code": "10431909" },
      {
        "name": "Dried cut delistar white spider chrysanthemum",
        "code": "10431910"
      },
      {
        "name": "Dried cut delistar yellow spider chrysanthemum",
        "code": "10431911"
      },
      { "name": "Dried cut minka spider chrysanthemum", "code": "10431912" },
      {
        "name": "Dried cut natasha sunny spider chrysanthemum",
        "code": "10431913"
      },
      {
        "name": "Dried cut pirouette spider chrysanthemum",
        "code": "10431914"
      },
      { "name": "Dried cut reflect spider chrysanthemum", "code": "10431915" },
      { "name": "Dried cut regatta spider chrysanthemum", "code": "10431916" },
      { "name": "Dried cut render spider chrysanthemum", "code": "10431917" },
      {
        "name": "Dried cut repertoire spider chrysanthemum",
        "code": "10431918"
      },
      { "name": "Dried cut resolute spider chrysanthemum", "code": "10431919" },
      { "name": "Dried cut resomac spider chrysanthemum", "code": "10431920" },
      { "name": "Dried cut shamrock spider chrysanthemum", "code": "10431921" },
      {
        "name": "Dried cut bronze mood spider chrysanthemum",
        "code": "10431922"
      },
      {
        "name": "Dried cut super white spider chrysanthemum",
        "code": "10431923"
      },
      {
        "name": "Dried cut super yellow spider chrysanthemum",
        "code": "10431924"
      },
      { "name": "Dried cut tender spider chrysanthemum", "code": "10431925" },
      { "name": "Dried cut zembla spider chrysanthemum", "code": "10431926" },
      { "name": "Dried cut novelty chrysanthemums", "code": "10432000" },
      {
        "name": "Dried cut annecy pink pompon chrysanthemum",
        "code": "10432001"
      },
      {
        "name": "Dried cut ardilo royal pompon chrysanthemum",
        "code": "10432002"
      },
      { "name": "Dried cut athos pompon chrysanthemum", "code": "10432003" },
      { "name": "Dried cut biarritz pompon chrysanthemum", "code": "10432004" },
      {
        "name": "Dried cut bradford orange pompon chrysanthemum",
        "code": "10432005"
      },
      { "name": "Dried cut bradford pompon chrysanthemum", "code": "10432006" },
      { "name": "Dried cut candle pompon chrysanthemum", "code": "10432007" },
      { "name": "Dried cut candor pompon chrysanthemum", "code": "10432008" },
      { "name": "Dried cut dash pompon chrysanthemum", "code": "10432009" },
      { "name": "Dried cut decima pompon chrysanthemum", "code": "10432010" },
      { "name": "Dried cut delisun pompon chrysanthemum", "code": "10432012" },
      { "name": "Dried cut dion pompon chrysanthemum", "code": "10432013" },
      { "name": "Dried cut dorena pompon chrysanthemum", "code": "10432014" },
      { "name": "Dried cut dublin pompon chrysanthemum", "code": "10432015" },
      {
        "name": "Dried cut everglades pompon chrysanthemum",
        "code": "10432016"
      },
      { "name": "Dried cut handsome pompon chrysanthemum", "code": "10432017" },
      { "name": "Dried cut hasting pompon chrysanthemum", "code": "10432018" },
      {
        "name": "Dried cut high five pompon chrysanthemum",
        "code": "10432019"
      },
      {
        "name": "Dried cut improved mundial pompon chrysanthemum",
        "code": "10432020"
      },
      { "name": "Dried cut juanes pompon chrysanthemum", "code": "10432021" },
      {
        "name": "Dried cut kiato green pompon chrysanthemum",
        "code": "10432022"
      },
      { "name": "Dried cut kiato pompon chrysanthemum", "code": "10432023" },
      { "name": "Dried cut kiwi pompon chrysanthemum", "code": "10432024" },
      { "name": "Dried cut madeira pompon chrysanthemum", "code": "10432025" },
      { "name": "Dried cut magnet pompon chrysanthemum", "code": "10432026" },
      { "name": "Dried cut marimo pompon chrysanthemum", "code": "10432027" },
      { "name": "Dried cut matrix pompon chrysanthemum", "code": "10432028" },
      { "name": "Dried cut miletta pompon chrysanthemum", "code": "10432029" },
      { "name": "Dried cut monalisa pompon chrysanthemum", "code": "10432030" },
      { "name": "Dried cut omaha pompon chrysanthemum", "code": "10432031" },
      {
        "name": "Dried cut orinoco purple pompon chrysanthemum",
        "code": "10432032"
      },
      {
        "name": "Dried cut orinoco yellow pompon chrysanthemum",
        "code": "10432033"
      },
      {
        "name": "Dried cut pacific green pompon chrysanthemum",
        "code": "10432034"
      },
      {
        "name": "Dried cut puma white pompon chrysanthemum",
        "code": "10432035"
      },
      {
        "name": "Dried cut puma yellow pompon chrysanthemum",
        "code": "10432036"
      },
      {
        "name": "Dried cut purple mundial pompon chrysanthemum",
        "code": "10432037"
      },
      { "name": "Dried cut regatta pompon chrysanthemum", "code": "10432038" },
      { "name": "Dried cut remco pompon chrysanthemum", "code": "10432039" },
      {
        "name": "Dried cut royal mundial pompon chrysanthemum",
        "code": "10432040"
      },
      { "name": "Dried cut sabrina pompon chrysanthemum", "code": "10432041" },
      {
        "name": "Dried cut shakira white pompon chrysanthemum",
        "code": "10432042"
      },
      { "name": "Dried cut sharp pompon chrysanthemum", "code": "10432043" },
      { "name": "Dried cut shock pompon chrysanthemum", "code": "10432044" },
      {
        "name": "Dried cut sizzle green pompon chrysanthemum",
        "code": "10432045"
      },
      {
        "name": "Dried cut sizzle pink pompon chrysanthemum",
        "code": "10432046"
      },
      { "name": "Dried cut sizzle pompon chrysanthemum", "code": "10432047" },
      {
        "name": "Dried cut sizzle purple pompon chrysanthemum",
        "code": "10432048"
      },
      {
        "name": "Dried cut sizzle salmon pompon chrysanthemum",
        "code": "10432049"
      },
      {
        "name": "Dried cut sizzle yellow pompon chrysanthemum",
        "code": "10432050"
      },
      {
        "name": "Dried cut spain flag pompon chrysanthemum",
        "code": "10432051"
      },
      {
        "name": "Dried cut starburst or snowflake pompon chrysanthemum",
        "code": "10432052"
      },
      { "name": "Dried cut swan pompon chrysanthemum", "code": "10432053" },
      {
        "name": "Dried cut tedcha orange pompon chrysanthemum",
        "code": "10432054"
      },
      { "name": "Dried cut tender pompon chrysanthemum", "code": "10432055" },
      { "name": "Dried cut tinsel pompon chrysanthemum", "code": "10432056" },
      { "name": "Dried cut touch pompon chrysanthemum", "code": "10432057" },
      { "name": "Dried cut troyes pompon chrysanthemum", "code": "10432058" },
      { "name": "Dried cut valesca pompon chrysanthemum", "code": "10432059" },
      {
        "name": "Dried cut viking orange pompon chrysanthemum",
        "code": "10432060"
      },
      { "name": "Dried cut viking pompon chrysanthemum", "code": "10432061" },
      { "name": "Dried cut watch pompon chrysanthemum", "code": "10432062" },
      {
        "name": "Dried cut white needle pompon chrysanthemum",
        "code": "10432063"
      },
      {
        "name": "Dried cut white night pompon chrysanthemum",
        "code": "10432064"
      },
      {
        "name": "Dried cut yellow artist pompon chrysanthemum",
        "code": "10432065"
      },
      {
        "name": "Dried cut yellow fiction pompon chrysanthemum",
        "code": "10432066"
      },
      {
        "name": "Dried cut yellow sharp pompon chrysanthemum",
        "code": "10432067"
      },
      { "name": "Dried cut santini chrysanthemums", "code": "10432100" },
      { "name": "Dried cut alma pompon chrysanthemum", "code": "10432101" },
      { "name": "Dried cut baron pompon chrysanthemum", "code": "10432102" },
      { "name": "Dried cut bernardo pompon chrysanthemum", "code": "10432103" },
      { "name": "Dried cut bistro pompon chrysanthemum", "code": "10432104" },
      { "name": "Dried cut bodega pompon chrysanthemum", "code": "10432105" },
      { "name": "Dried cut breeze pompon chrysanthemum", "code": "10432106" },
      {
        "name": "Dried cut bronze centella pompon chrysanthemum",
        "code": "10432107"
      },
      {
        "name": "Dried cut costa white pompon chrysanthemum",
        "code": "10432108"
      },
      { "name": "Dried cut creta pompon chrysanthemum", "code": "10432109" },
      {
        "name": "Dried cut deliflame pompon chrysanthemum",
        "code": "10432110"
      },
      { "name": "Dried cut delilah pompon chrysanthemum", "code": "10432111" },
      { "name": "Dried cut digit pompon chrysanthemum", "code": "10432112" },
      { "name": "Dried cut evilio pompon chrysanthemum", "code": "10432113" },
      { "name": "Dried cut furense pompon chrysanthemum", "code": "10432114" },
      { "name": "Dried cut guide pompon chrysanthemum", "code": "10432115" },
      { "name": "Dried cut kerry pompon chrysanthemum", "code": "10432116" },
      { "name": "Dried cut kess pompon chrysanthemum", "code": "10432117" },
      { "name": "Dried cut lima pompon chrysanthemum", "code": "10432118" },
      { "name": "Dried cut lupo pompon chrysanthemum", "code": "10432119" },
      {
        "name": "Dried cut orange lineker pompon chrysanthemum",
        "code": "10432120"
      },
      {
        "name": "Dried cut panuco red pompon chrysanthemum",
        "code": "10432121"
      },
      {
        "name": "Dried cut pink costa pompon chrysanthemum",
        "code": "10432122"
      },
      { "name": "Dried cut raphael pompon chrysanthemum", "code": "10432123" },
      { "name": "Dried cut refine pompon chrysanthemum", "code": "10432124" },
      { "name": "Dried cut regalis pompon chrysanthemum", "code": "10432125" },
      { "name": "Dried cut renella pompon chrysanthemum", "code": "10432126" },
      { "name": "Dried cut return pompon chrysanthemum", "code": "10432127" },
      { "name": "Dried cut river pompon chrysanthemum", "code": "10432128" },
      { "name": "Dried cut sabas pompon chrysanthemum", "code": "10432129" },
      { "name": "Dried cut target pompon chrysanthemum", "code": "10432130" },
      { "name": "Dried cut text pompon chrysanthemum", "code": "10432131" },
      {
        "name": "Dried cut yellow stallion pompon chrysanthemum",
        "code": "10432132"
      },
      { "name": "Dried cut carnations", "code": "10440000" },
      { "name": "Dried cut single bloom carnations", "code": "10441500" },
      {
        "name": "Dried cut single bloom burgundy bi color carnation",
        "code": "10441501"
      },
      {
        "name": "Dried cut single bloom burgundy carnation",
        "code": "10441502"
      },
      {
        "name": "Dried cut single bloom cinderella carnation",
        "code": "10441503"
      },
      {
        "name": "Dried cut single bloom cream bi color carnation",
        "code": "10441504"
      },
      { "name": "Dried cut single bloom cream carnation", "code": "10441505" },
      {
        "name": "Dried cut single bloom green or prado carnation",
        "code": "10441506"
      },
      {
        "name": "Dried cut single bloom hot pink carnation",
        "code": "10441507"
      },
      {
        "name": "Dried cut single bloom light green carnation",
        "code": "10441508"
      },
      {
        "name": "Dried cut single bloom light pink carnation",
        "code": "10441509"
      },
      {
        "name": "Dried cut single bloom orange bi color carnation",
        "code": "10441510"
      },
      { "name": "Dried cut single bloom orange carnation", "code": "10441511" },
      { "name": "Dried cut single bloom peach carnation", "code": "10441512" },
      {
        "name": "Dried cut single bloom peppermint bi color carnation",
        "code": "10441513"
      },
      {
        "name": "Dried cut single bloom pink bi color carnation",
        "code": "10441514"
      },
      { "name": "Dried cut single bloom pink carnation", "code": "10441515" },
      {
        "name": "Dried cut single bloom purple bi color carnation",
        "code": "10441516"
      },
      {
        "name": "Dried cut single bloom red bi color carnation",
        "code": "10441517"
      },
      { "name": "Dried cut single bloom red carnation", "code": "10441518" },
      { "name": "Dried cut single bloom white carnation", "code": "10441519" },
      { "name": "Dried cut single bloom yellow carnation", "code": "10441520" },
      { "name": "Dried cut mini or spray carnations", "code": "10441600" },
      {
        "name": "Dried cut burgundy mini or spray carnation",
        "code": "10441601"
      },
      { "name": "Dried cut cream mini or spray carnation", "code": "10441602" },
      {
        "name": "Dried cut hot pink mini or spray carnation",
        "code": "10441603"
      },
      {
        "name": "Dried cut lavender mini or spray carnation",
        "code": "10441604"
      },
      {
        "name": "Dried cut light pink mini or spray carnation",
        "code": "10441605"
      },
      {
        "name": "Dried cut orange mini or spray carnation",
        "code": "10441606"
      },
      { "name": "Dried cut peach mini or spray carnation", "code": "10441607" },
      {
        "name": "Dried cut peppermint mini or spray carnation",
        "code": "10441608"
      },
      { "name": "Dried cut pink mini or spray carnation", "code": "10441609" },
      {
        "name": "Dried cut purple bi color mini or spray carnation",
        "code": "10441610"
      },
      {
        "name": "Dried cut purple mini or spray carnation",
        "code": "10441611"
      },
      { "name": "Dried cut red mini or spray carnation", "code": "10441612" },
      { "name": "Dried cut white mini or spray carnation", "code": "10441613" },
      {
        "name": "Dried cut yellow mini or spray carnation",
        "code": "10441614"
      },
      { "name": "Dried cut orchids", "code": "10450000" },
      {
        "name": "Dried cut cypripedium or ladys slipper orchids",
        "code": "10451500"
      },
      { "name": "Dried cut green cypripedium orchid", "code": "10451501" },
      { "name": "Dried cut france cypripedium orchid", "code": "10451502" },
      {
        "name": "Dried cut purple king arthur cypripedium orchid",
        "code": "10451503"
      },
      { "name": "Dried cut green paphiopedilum orchid", "code": "10451504" },
      { "name": "Dried cut aranthera maggie vie orchid", "code": "10451505" },
      { "name": "Dried cut mokara or mocara orchids", "code": "10451600" },
      { "name": "Dried cut mocara omyai orchid", "code": "10451601" },
      { "name": "Dried cut mocara red orchid", "code": "10451602" },
      { "name": "Dried cut mokara calypso orchid", "code": "10451603" },
      { "name": "Dried cut mokara nora orchid", "code": "10451604" },
      { "name": "Dried cut mokara panee orchid", "code": "10451605" },
      { "name": "Dried cut cattleya orchids", "code": "10451700" },
      { "name": "Dried cut white cattleya orchid", "code": "10451701" },
      { "name": "Dried cut r b lavender cattleya orchid", "code": "10451702" },
      { "name": "Dried cut disa orchids", "code": "10451800" },
      { "name": "Dried cut red disa orchid", "code": "10451801" },
      { "name": "Dried cut orange disa orchid", "code": "10451802" },
      { "name": "Dried cut pink disa orchid", "code": "10451803" },
      {
        "name": "Dried cut orange and yellow bi color disa orchid",
        "code": "10451804"
      },
      {
        "name": "Dried cut peach and yellow bi color disa orchid",
        "code": "10451805"
      },
      {
        "name": "Dried cut yellow and red bi color disa orchid",
        "code": "10451806"
      },
      { "name": "Dried cut arachnis orchids", "code": "10451900" },
      {
        "name": "Dried cut james storie red arachnis orchid",
        "code": "10451901"
      },
      {
        "name": "Dried cut maggie oei red ribbon arachnis orchid",
        "code": "10451902"
      },
      {
        "name": "Dried cut maggie oei yellow ribbon arachnis orchid",
        "code": "10451903"
      },
      { "name": "Dried cut maroon maggie arachnis orchid", "code": "10451904" },
      { "name": "Dried cut merry maggie arachnis orchid", "code": "10451905" },
      { "name": "Dried cut phalaenopsis orchids", "code": "10452000" },
      { "name": "Dried cut phalaenopsis amabilis orchid", "code": "10452001" },
      {
        "name": "Dried cut phalaenopsis amboinensis orchid",
        "code": "10452002"
      },
      { "name": "Dried cut phalaenopsis aphrodite orchid", "code": "10452003" },
      {
        "name": "Dried cut phalaenopsis appendiculata orchid",
        "code": "10452004"
      },
      { "name": "Dried cut phalaenopsis bastianii orchid", "code": "10452005" },
      { "name": "Dried cut phalaenopsis bellina orchid", "code": "10452006" },
      {
        "name": "Dried cut phalaenopsis borneensis orchid",
        "code": "10452007"
      },
      { "name": "Dried cut phalaenopsis braceana orchid", "code": "10452008" },
      {
        "name": "Dried cut phalaenopsis buyssoniana orchid",
        "code": "10452009"
      },
      {
        "name": "Dried cut phalaenopsis celebensis orchid",
        "code": "10452010"
      },
      { "name": "Dried cut phalaenopsis chibae orchid", "code": "10452011" },
      {
        "name": "Dried cut phalaenopsis cochlearis orchid",
        "code": "10452012"
      },
      {
        "name": "Dried cut phalaenopsis corningiana orchid",
        "code": "10452013"
      },
      {
        "name": "Dried cut phalaenopsis cornu-cervi orchid",
        "code": "10452014"
      },
      { "name": "Dried cut phalaenopsis deliciosa orchid", "code": "10452015" },
      {
        "name": "Dried cut phalaenopsis dowery\u00ebnsis orchid",
        "code": "10452016"
      },
      { "name": "Dried cut phalaenopsis equestris orchid", "code": "10452017" },
      { "name": "Dried cut phalaenopsis fasciata orchid", "code": "10452018" },
      { "name": "Dried cut phalaenopsis fimbriata orchid", "code": "10452019" },
      {
        "name": "Dried cut phalaenopsis floresensis orchid",
        "code": "10452020"
      },
      { "name": "Dried cut phalaenopsis fuscata orchid", "code": "10452021" },
      { "name": "Dried cut phalaenopsis gibbosa orchid", "code": "10452022" },
      {
        "name": "Dried cut phalaenopsis hainanensis orchid",
        "code": "10452023"
      },
      {
        "name": "Dried cut phalaenopsis hieroglyphica orchid",
        "code": "10452024"
      },
      {
        "name": "Dried cut phalaenopsis honghenensis orchid",
        "code": "10452025"
      },
      {
        "name": "Dried cut phalaenopsis inscriptiosinensis orchid",
        "code": "10452026"
      },
      { "name": "Dried cut phalaenopsis javanica orchid", "code": "10452027" },
      { "name": "Dried cut phalaenopsis kunstleri orchid", "code": "10452028" },
      {
        "name": "Dried cut phalaenopsis lamelligera orchid",
        "code": "10452029"
      },
      { "name": "Dried cut phalaenopsis lindenii orchid", "code": "10452030" },
      { "name": "Dried cut phalaenopsis lobbii orchid", "code": "10452031" },
      { "name": "Dried cut phalaenopsis lowii orchid", "code": "10452032" },
      {
        "name": "Dried cut phalaenopsis lueddemanniana orchid",
        "code": "10452033"
      },
      { "name": "Dried cut phalaenopsis mambo orchid", "code": "10452034" },
      { "name": "Dried cut phalaenopsis luteola orchid", "code": "10452035" },
      { "name": "Dried cut phalaenopsis maculata orchid", "code": "10452036" },
      {
        "name": "Dried cut phalaenopsis malipoensis orchid",
        "code": "10452037"
      },
      { "name": "Dried cut phalaenopsis mannii orchid", "code": "10452038" },
      { "name": "Dried cut phalaenopsis mariae orchid", "code": "10452039" },
      {
        "name": "Dried cut phalaenopsis micholitzii orchid",
        "code": "10452040"
      },
      { "name": "Dried cut phalaenopsis modesta orchid", "code": "10452041" },
      {
        "name": "Dried cut phalaenopsis mysorensis orchid",
        "code": "10452042"
      },
      { "name": "Dried cut phalaenopsis pallens orchid", "code": "10452043" },
      {
        "name": "Dried cut phalaenopsis pantherina orchid",
        "code": "10452044"
      },
      { "name": "Dried cut phalaenopsis parishii orchid", "code": "10452045" },
      { "name": "Dried cut phalaenopsis petelotii orchid", "code": "10452046" },
      {
        "name": "Dried cut phalaenopsis philippinensis orchid",
        "code": "10452047"
      },
      {
        "name": "Dried cut phalaenopsis pulcherrima orchid",
        "code": "10452048"
      },
      { "name": "Dried cut phalaenopsis pulchra orchid", "code": "10452049" },
      {
        "name": "Dried cut phalaenopsis regnieriana orchid",
        "code": "10452050"
      },
      {
        "name": "Dried cut phalaenopsis reichenbachiana orchid",
        "code": "10452051"
      },
      { "name": "Dried cut phalaenopsis Nivacolor orchid", "code": "10452052" },
      {
        "name": "Dried cut phalaenopsis sanderiana orchid",
        "code": "10452053"
      },
      {
        "name": "Dried cut phalaenopsis schilleriana orchid",
        "code": "10452054"
      },
      { "name": "Dried cut phalaenopsis speciosa orchid", "code": "10452055" },
      {
        "name": "Dried cut phalaenopsis stobartiana orchid",
        "code": "10452056"
      },
      {
        "name": "Dried cut phalaenopsis stuartiana orchid",
        "code": "10452057"
      },
      { "name": "Dried cut phalaenopsis sumatrana orchid", "code": "10452058" },
      { "name": "Dried cut phalaenopsis taenialis orchid", "code": "10452059" },
      { "name": "Dried cut phalaenopsis tetraspis orchid", "code": "10452060" },
      { "name": "Dried cut phalaenopsis venosa orchid", "code": "10452061" },
      { "name": "Dried cut phalaenopsis violacea orchid", "code": "10452062" },
      { "name": "Dried cut phalaenopsis viridis orchid", "code": "10452063" },
      { "name": "Dried cut phalaenopsis wilsonii orchid", "code": "10452064" },
      { "name": "Dried cut phalaenopsis zebrina orchid", "code": "10452065" },
      {
        "name": "Dried cut lavender lip phalaenopsis orchid",
        "code": "10452067"
      },
      { "name": "Dried cut dendrobium orchids", "code": "10452100" },
      { "name": "Dried cut bom dendrobium orchid", "code": "10452101" },
      { "name": "Dried cut burana jade dendrobium orchid", "code": "10452102" },
      { "name": "Dried cut cheetah dendrobium orchid", "code": "10452103" },
      { "name": "Dried cut fatima dendrobium orchid", "code": "10452104" },
      { "name": "Dried cut intuwong dendrobium orchid", "code": "10452105" },
      { "name": "Dried cut jumbo white dendrobium orchid", "code": "10452106" },
      { "name": "Dried cut kating dang dendrobium orchid", "code": "10452107" },
      { "name": "Dried cut liberty dendrobium orchid", "code": "10452108" },
      {
        "name": "Dried cut orchid hawaii dendrobium orchid",
        "code": "10452109"
      },
      {
        "name": "Dried cut sakura sweet pink dendrobium orchid",
        "code": "10452110"
      },
      {
        "name": "Dried cut sensational purple dendrobium orchid",
        "code": "10452111"
      },
      { "name": "Dried cut white dendrobium orchid", "code": "10452112" },
      { "name": "Dried cut cymbidium orchids", "code": "10452200" },
      { "name": "Dried cut cream cymbidium orchid", "code": "10452201" },
      { "name": "Dried cut green cymbidium orchid", "code": "10452202" },
      { "name": "Dried cut mini green cymbidium orchid", "code": "10452203" },
      { "name": "Dried cut mini pink cymbidium orchid", "code": "10452204" },
      { "name": "Dried cut mini red cymbidium orchid", "code": "10452205" },
      { "name": "Dried cut mini white cymbidium orchid", "code": "10452206" },
      { "name": "Dried cut mini yellow cymbidium orchid", "code": "10452207" },
      { "name": "Dried cut chocolate cymbidium orchid", "code": "10452208" },
      { "name": "Dried cut dark pink cymbidium orchid", "code": "10452209" },
      { "name": "Dried cut orange cymbidium orchid", "code": "10452210" },
      { "name": "Dried cut pink cymbidium orchid", "code": "10452211" },
      { "name": "Dried cut white cymbidium orchid", "code": "10452212" },
      { "name": "Dried cut yellow cymbidium orchid", "code": "10452213" },
      { "name": "Dried cut oncidium orchids", "code": "10452300" },
      { "name": "Dried cut golden shower oncidium orchid", "code": "10452301" },
      { "name": "Dried cut rhamsey oncidium orchid", "code": "10452302" },
      { "name": "Dried cut vanda orchids", "code": "10452400" },
      { "name": "Dried cut alizarin vanda orchid", "code": "10452401" },
      { "name": "Dried cut hot pink vanda orchid", "code": "10452402" },
      { "name": "Dried cut lavender vanda orchid", "code": "10452403" },
      { "name": "Dried cut purple vanda orchid", "code": "10452404" },
      { "name": "Dried cut tickle me pink vanda orchid", "code": "10452405" },
      { "name": "Dried cut yellow vanda orchid", "code": "10452406" },
      { "name": "Fresh cut greenery", "code": "10500000" },
      { "name": "Fresh cut aspidistras", "code": "10501500" },
      { "name": "Fresh cut green aspidistra", "code": "10501501" },
      { "name": "Fresh cut milky way aspidistra", "code": "10501502" },
      { "name": "Fresh cut variegated aspidistra", "code": "10501503" },
      { "name": "Fresh cut chile greens", "code": "10501600" },
      { "name": "Fresh cut avellana chile green", "code": "10501601" },
      { "name": "Fresh cut romerillo chile green", "code": "10501602" },
      { "name": "Fresh cut pacarilla chile green", "code": "10501603" },
      { "name": "Fresh cut muzgo chile green", "code": "10501604" },
      { "name": "Fresh cut copperbeech greens", "code": "10501700" },
      { "name": "Fresh cut bronze copperbeech", "code": "10501701" },
      { "name": "Fresh cut green copperbeech", "code": "10501702" },
      { "name": "Fresh cut red copperbeech green", "code": "10501703" },
      { "name": "Fresh cut eucalyptus greens", "code": "10501800" },
      { "name": "Fresh cut baby blue eucalyptus", "code": "10501801" },
      { "name": "Fresh cut bonsai tall eucalyptus", "code": "10501802" },
      { "name": "Fresh cut feather eucalyptus", "code": "10501803" },
      { "name": "Fresh cut gunnii eucalyptus", "code": "10501804" },
      { "name": "Fresh cut parvifolia eucalyptus", "code": "10501805" },
      { "name": "Fresh cut preserved eucalyptus", "code": "10501806" },
      { "name": "Fresh cut seeded eucalyptus", "code": "10501807" },
      { "name": "Fresh cut seeded leafless eucalyptus", "code": "10501808" },
      { "name": "Fresh cut seeded weeping eucalyptus", "code": "10501809" },
      { "name": "Fresh cut silver dollar eucalyptus", "code": "10501810" },
      { "name": "Fresh cut spiral tall eucalyptus", "code": "10501811" },
      { "name": "Fresh cut true blue eucalyptus", "code": "10501812" },
      { "name": "Fresh cut willow seeded eucalyptus", "code": "10501813" },
      { "name": "Fresh cut ferns", "code": "10501900" },
      { "name": "Fresh cut coontie fern", "code": "10501901" },
      { "name": "Fresh cut feather fern", "code": "10501902" },
      { "name": "Fresh cut flat fern", "code": "10501903" },
      { "name": "Fresh cut maidenhair fern", "code": "10501904" },
      { "name": "Fresh cut ming fern", "code": "10501905" },
      { "name": "Fresh cut sword fern florida tall", "code": "10501906" },
      { "name": "Fresh cut tree fern", "code": "10501907" },
      { "name": "Fresh cut umbrella fern", "code": "10501908" },
      { "name": "Fresh cut leaves or foliage", "code": "10502000" },
      { "name": "Fresh cut australian melaluca foliage", "code": "10502001" },
      { "name": "Fresh cut blue lepto foliage", "code": "10502002" },
      { "name": "Fresh cut corylus avellana foliage", "code": "10502003" },
      { "name": "Fresh cut green hosta foliage", "code": "10502004" },
      { "name": "Fresh cut variegated hosta foliage", "code": "10502005" },
      { "name": "Fresh cut kunzia foliage", "code": "10502006" },
      { "name": "Fresh cut jade foliage", "code": "10502007" },
      { "name": "Fresh cut magnolia foliage", "code": "10502008" },
      {
        "name": "Fresh cut meyerii foliage or foxtail foliage",
        "code": "10502009"
      },
      { "name": "Fresh cut olive foliage", "code": "10502010" },
      { "name": "Fresh cut pistaccio foliage", "code": "10502011" },
      { "name": "Fresh cut plum foliage", "code": "10502012" },
      { "name": "Fresh cut robellini foliage", "code": "10502013" },
      { "name": "Fresh cut viburnum foliage", "code": "10502014" },
      { "name": "Fresh cut xanadu foliage", "code": "10502015" },
      { "name": "Fresh cut sanderiana foliage", "code": "10502016" },
      { "name": "Fresh cut gardenia foliage", "code": "10502017" },
      { "name": "Fresh cut grasses", "code": "10502100" },
      { "name": "Fresh cut green florida lily grass", "code": "10502101" },
      { "name": "Fresh cut variegated florida lily grass", "code": "10502102" },
      { "name": "Fresh cut bear grass", "code": "10502103" },
      { "name": "Fresh cut bamboo grass", "code": "10502104" },
      { "name": "Fresh cut cane grass", "code": "10502105" },
      { "name": "Fresh cut corkscrew grass", "code": "10502106" },
      { "name": "Fresh cut feather grass", "code": "10502107" },
      { "name": "Fresh cut flexi grass", "code": "10502108" },
      { "name": "Fresh cut fountain grass", "code": "10502109" },
      { "name": "Fresh cut giant striped reed grass", "code": "10502110" },
      { "name": "Fresh cut millet grass", "code": "10502111" },
      { "name": "Fresh cut black millet grass", "code": "10502112" },
      { "name": "Fresh cut milo grass", "code": "10502113" },
      { "name": "Fresh cut variegated oat grass", "code": "10502114" },
      { "name": "Fresh cut phalaris canariensis grass", "code": "10502115" },
      { "name": "Fresh cut purple plum grass", "code": "10502116" },
      { "name": "Fresh cut rattlesnake grass", "code": "10502117" },
      { "name": "Fresh cut ribbon grass", "code": "10502118" },
      { "name": "Fresh cut sea oats grass", "code": "10502119" },
      { "name": "Fresh cut steelgrass", "code": "10502120" },
      { "name": "Fresh cut variegated zebra grass", "code": "10502121" },
      { "name": "Fresh cut panicum or jungle grass", "code": "10502122" },
      { "name": "Fresh cut pampas grass", "code": "10502123" },
      { "name": "Fresh cut pepper grass", "code": "10502124" },
      { "name": "Fresh cut zebra grass", "code": "10502125" },
      { "name": "Fresh cut huckleberry", "code": "10502200" },
      { "name": "Fresh cut green huckleberry", "code": "10502201" },
      { "name": "Fresh cut red huckleberry", "code": "10502202" },
      { "name": "Fresh cut ivy", "code": "10502300" },
      { "name": "Fresh cut green ivy", "code": "10502301" },
      { "name": "Fresh cut variegated ivy", "code": "10502302" },
      { "name": "Fresh cut upright ivy", "code": "10502303" },
      { "name": "Fresh cut green tree ivy", "code": "10502304" },
      { "name": "Fresh cut variegated tree ivy", "code": "10502305" },
      { "name": "Fresh cut integrifolia", "code": "10502400" },
      { "name": "Fresh cut red integrifolia", "code": "10502401" },
      { "name": "Fresh cut natural integrifolia", "code": "10502402" },
      { "name": "Fresh cut myrtle", "code": "10502500" },
      { "name": "Fresh cut dwarf myrtle", "code": "10502501" },
      { "name": "Fresh cut variegated myrtle", "code": "10502502" },
      { "name": "Fresh cut oak leaves", "code": "10502600" },
      { "name": "Fresh cut holland red oak leaves", "code": "10502601" },
      { "name": "Fresh cut oregonia", "code": "10502700" },
      { "name": "Fresh cut green oregonia", "code": "10502701" },
      { "name": "Fresh cut silver oregonia", "code": "10502702" },
      { "name": "Fresh cut variegated oregonia", "code": "10502703" },
      { "name": "Fresh cut pittosporum", "code": "10502800" },
      { "name": "Fresh cut green pittosporum", "code": "10502801" },
      { "name": "Fresh cut nevatus pittosporum", "code": "10502802" },
      { "name": "Fresh cut new zealand pittosporum", "code": "10502803" },
      { "name": "Fresh cut nigra pittosporum", "code": "10502804" },
      { "name": "Fresh cut variegated pittosporum", "code": "10502805" },
      { "name": "Fresh cut single species greens", "code": "10502900" },
      { "name": "Fresh cut acanthus or cordone", "code": "10502901" },
      { "name": "Fresh cut acacia purple feather", "code": "10502902" },
      { "name": "Fresh cut adina rubella", "code": "10502903" },
      { "name": "Fresh cut agonis flexuosa", "code": "10502904" },
      { "name": "Fresh cut azara dentata", "code": "10502905" },
      { "name": "Fresh cut barker bush", "code": "10502906" },
      { "name": "Fresh cut bay wreath", "code": "10502907" },
      { "name": "Fresh cut blue flame plumosus", "code": "10502908" },
      { "name": "Fresh cut african boxwood", "code": "10502909" },
      { "name": "Fresh cut bay leaf", "code": "10502910" },
      { "name": "Fresh cut camellia leaf", "code": "10502911" },
      { "name": "Fresh cut gold new zealand cedar", "code": "10502912" },
      { "name": "Fresh cut new zealand ceratopetalum", "code": "10502913" },
      { "name": "Fresh cut choisya ternata", "code": "10502914" },
      { "name": "Fresh cut cocculus", "code": "10502915" },
      { "name": "Fresh cut croton", "code": "10502916" },
      { "name": "Fresh cut datura pods", "code": "10502917" },
      { "name": "Fresh cut dusty miller", "code": "10502918" },
      { "name": "Fresh cut elegia capensis", "code": "10502919" },
      { "name": "Fresh cut euonymous leaf", "code": "10502920" },
      { "name": "Fresh cut flax leaf", "code": "10502921" },
      { "name": "Fresh cut galax leaf", "code": "10502922" },
      { "name": "Fresh cut goldleaf", "code": "10502923" },
      { "name": "Fresh cut grevillea leaf", "code": "10502924" },
      { "name": "Fresh cut horsetail or snake grass", "code": "10502925" },
      { "name": "Fresh cut knifeblade acacia", "code": "10502926" },
      { "name": "Fresh cut laurel leaf", "code": "10502927" },
      { "name": "Fresh cut leather leaf", "code": "10502928" },
      { "name": "Fresh cut ligularia leaf", "code": "10502929" },
      { "name": "Fresh cut ligularia argentea leaf", "code": "10502930" },
      { "name": "Fresh cut lophomyrtus", "code": "10502931" },
      { "name": "Fresh cut lycopodium or princess pine", "code": "10502932" },
      { "name": "Fresh cut mahonia aquifolium", "code": "10502933" },
      { "name": "Fresh cut nagi leaf", "code": "10502934" },
      { "name": "Fresh cut palmetto fan palm", "code": "10502935" },
      { "name": "Fresh cut papyrus umbrella florida", "code": "10502936" },
      { "name": "Fresh cut pennycress", "code": "10502937" },
      { "name": "Fresh cut red robin photinia", "code": "10502938" },
      {
        "name": "Fresh cut diablo ninebark or physocarpus",
        "code": "10502939"
      },
      { "name": "Fresh cut pimelea nivea", "code": "10502940" },
      { "name": "Fresh cut pistache leaf", "code": "10502941" },
      { "name": "Fresh cut plumosa", "code": "10502942" },
      { "name": "Fresh cut podocarpus", "code": "10502943" },
      { "name": "Fresh cut pokeweed", "code": "10502944" },
      { "name": "Fresh cut red tip photinia", "code": "10502945" },
      {
        "name": "Fresh cut variegated rhamnus or buckthorn",
        "code": "10502946"
      },
      { "name": "Fresh cut blooming rhododendron", "code": "10502947" },
      { "name": "Fresh cut rosio leaf", "code": "10502948" },
      { "name": "Fresh cut florida long ruscus", "code": "10502949" },
      { "name": "Fresh cut italian ruscus", "code": "10502950" },
      { "name": "Fresh cut salal or lemon leaf", "code": "10502951" },
      { "name": "Fresh cut ruscifolia sarcococca", "code": "10502952" },
      { "name": "Fresh cut schefflera", "code": "10502953" },
      { "name": "Fresh cut uniola latifolia sea oats", "code": "10502954" },
      { "name": "Fresh cut silver sage", "code": "10502955" },
      { "name": "Fresh cut skimmia", "code": "10502956" },
      { "name": "Fresh cut springeri", "code": "10502957" },
      { "name": "Fresh cut tulepods", "code": "10502958" },
      { "name": "Fresh cut weigelia", "code": "10502959" }
    ]
  },
  {
    "name": "Mineral and Textile and Inedible Plant and Animal Materials",
    "code": "11000000",
    "subcategories": [
      { "name": "Minerals and ores and metals", "code": "11100000" },
      { "name": "Minerals", "code": "11101500" },
      { "name": "Mica", "code": "11101501" },
      { "name": "Emery", "code": "11101502" },
      { "name": "Quartz", "code": "11101503" },
      { "name": "Pyrite", "code": "11101504" },
      { "name": "Sulphur", "code": "11101505" },
      { "name": "Chalk", "code": "11101506" },
      { "name": "Graphite", "code": "11101507" },
      { "name": "Dolomite", "code": "11101508" },
      { "name": "Magnesite", "code": "11101509" },
      { "name": "Asbestos", "code": "11101510" },
      { "name": "Calcium", "code": "11101511" },
      { "name": "Borate", "code": "11101512" },
      { "name": "Cryolite", "code": "11101513" },
      { "name": "Feldspar", "code": "11101514" },
      { "name": "Leucite", "code": "11101515" },
      { "name": "Nephelite", "code": "11101516" },
      { "name": "Steatite", "code": "11101517" },
      { "name": "Talc", "code": "11101518" },
      { "name": "Rough diamond", "code": "11101519" },
      { "name": "Garnets", "code": "11101520" },
      { "name": "Silicon carbide", "code": "11101521" },
      { "name": "Activated carbon", "code": "11101522" },
      { "name": "Mulite", "code": "11101523" },
      { "name": "Fluorspar", "code": "11101524" },
      { "name": "Kieserite", "code": "11101525" },
      { "name": "Carnallite", "code": "11101526" },
      { "name": "Diatomaceous silica", "code": "11101527" },
      { "name": "Activated alumina", "code": "11101528" },
      { "name": "Zeolite", "code": "11101529" },
      { "name": "Calcite", "code": "11101530" },
      { "name": "Serpentine", "code": "11101531" },
      { "name": "Peridot", "code": "11101532" },
      { "name": "Pyrophyllite", "code": "11101533" },
      { "name": "Polished diamond", "code": "11101534" },
      { "name": "Ores", "code": "11101600" },
      { "name": "Iron ore", "code": "11101601" },
      { "name": "Titanium ore", "code": "11101602" },
      { "name": "Uranium ore", "code": "11101603" },
      { "name": "Copper ore", "code": "11101604" },
      { "name": "Aluminum ore", "code": "11101605" },
      { "name": "Nickel ore", "code": "11101606" },
      { "name": "Silver ore", "code": "11101607" },
      { "name": "Lead ore", "code": "11101608" },
      { "name": "Zinc ore", "code": "11101609" },
      { "name": "Tin ore", "code": "11101610" },
      { "name": "Manganese ore", "code": "11101611" },
      { "name": "Chromium ore", "code": "11101612" },
      { "name": "Tungsten or wolfram ore", "code": "11101613" },
      { "name": "Molybdenum ore", "code": "11101614" },
      { "name": "Cobalt ore", "code": "11101615" },
      { "name": "Gold ore", "code": "11101616" },
      { "name": "Tantalum ore", "code": "11101617" },
      { "name": "Platinum ore", "code": "11101618" },
      { "name": "Vermiculite ore", "code": "11101619" },
      { "name": "Thorium ore", "code": "11101620" },
      { "name": "Kyanite ore", "code": "11101621" },
      { "name": "Antimony ore", "code": "11101622" },
      { "name": "Zirconium ore", "code": "11101623" },
      { "name": "Magnetite ore", "code": "11101624" },
      { "name": "Bauxite ore", "code": "11101625" },
      { "name": "Base Metals", "code": "11101700" },
      { "name": "Slag or ash", "code": "11101701" },
      { "name": "Natural graphite", "code": "11101702" },
      { "name": "Steel", "code": "11101704" },
      { "name": "Aluminum", "code": "11101705" },
      { "name": "Nickel", "code": "11101706" },
      { "name": "Bismuth", "code": "11101708" },
      { "name": "Antimony", "code": "11101709" },
      { "name": "Cadmium", "code": "11101710" },
      { "name": "Non ferrous alloy", "code": "11101711" },
      { "name": "Ferrous alloy", "code": "11101712" },
      { "name": "Iron", "code": "11101713" },
      { "name": "Lead", "code": "11101714" },
      { "name": "Tin", "code": "11101716" },
      { "name": "Indium", "code": "11101717" },
      { "name": "Palladium", "code": "11101718" },
      { "name": "Zinc", "code": "11101719" },
      { "name": "Precious metals", "code": "11101800" },
      { "name": "Gold", "code": "11101801" },
      { "name": "Silver", "code": "11101802" },
      { "name": "Platinum", "code": "11101803" },
      {
        "name": "Intermediate processed and refined ore products",
        "code": "11101900"
      },
      { "name": "Iron ore concentrate", "code": "11101901" },
      { "name": "Nickel concentrate", "code": "11101902" },
      { "name": "Nickel briquette or compact", "code": "11101903" },
      { "name": "Nickel matte", "code": "11101904" },
      { "name": "Aluminum liquid", "code": "11101905" },
      { "name": "Copper concentrate", "code": "11101906" },
      { "name": "Copper cathode", "code": "11101907" },
      { "name": "Manganese sinter or pellet", "code": "11101908" },
      { "name": "Manganese fines", "code": "11101909" },
      { "name": "Manganese lumps", "code": "11101910" },
      { "name": "Iron ore pellets", "code": "11101911" },
      { "name": "Iron ore lumps", "code": "11101912" },
      { "name": "Iron ore fines", "code": "11101913" },
      { "name": "Cobalt ore matte", "code": "11101914" },
      { "name": "Zinc dust or powder or flakes", "code": "11101915" },
      { "name": "Nickel powder or flakes", "code": "11101916" },
      { "name": "Copper powder or flakes", "code": "11101917" },
      { "name": "Copper matte", "code": "11101919" },
      { "name": "Pig iron", "code": "11101920" },
      { "name": "Cement clinker", "code": "11101921" },
      { "name": "Aluminum powder or flakes", "code": "11101922" },
      { "name": "Zinc concentrate", "code": "11101923" },
      { "name": "Lead concentrate", "code": "11101924" },
      { "name": "Earth and stone", "code": "11110000" },
      { "name": "Dirt and soil", "code": "11111500" },
      { "name": "Soil", "code": "11111501" },
      { "name": "Fill dirt", "code": "11111502" },
      { "name": "Topsoil", "code": "11111503" },
      { "name": "Crystal soil", "code": "11111504" },
      { "name": "Artificial soil", "code": "11111505" },
      { "name": "Stone", "code": "11111600" },
      { "name": "Gypsum", "code": "11111601" },
      { "name": "Travertine", "code": "11111602" },
      { "name": "Ecaussine or alabaster", "code": "11111603" },
      { "name": "Granite", "code": "11111604" },
      { "name": "Marble", "code": "11111605" },
      { "name": "Slate", "code": "11111606" },
      { "name": "Sandstone", "code": "11111607" },
      { "name": "Limestone", "code": "11111608" },
      { "name": "Basalt", "code": "11111609" },
      { "name": "Pumice stone", "code": "11111610" },
      { "name": "Gravel", "code": "11111611" },
      { "name": "Limestone dust or mine rock dust", "code": "11111612" },
      { "name": "Wollastonite", "code": "11111613" },
      { "name": "Sand", "code": "11111700" },
      { "name": "Silica sand", "code": "11111701" },
      { "name": "Clays", "code": "11111800" },
      { "name": "Terra cotta", "code": "11111801" },
      { "name": "Fireclay", "code": "11111802" },
      { "name": "Kaolin or other kaolinic clays", "code": "11111803" },
      { "name": "Bentonite", "code": "11111804" },
      { "name": "Andalusite", "code": "11111805" },
      { "name": "Mullite", "code": "11111806" },
      { "name": "Chamotte", "code": "11111807" },
      { "name": "Common clay", "code": "11111808" },
      { "name": "Ball clay", "code": "11111809" },
      { "name": "Fullers earth", "code": "11111810" },
      { "name": "Haydite", "code": "11111811" },
      { "name": "Vermiculite", "code": "11111812" },
      { "name": "Non edible plant and forestry products", "code": "11120000" },
      { "name": "Sap", "code": "11121500" },
      { "name": "Rosin", "code": "11121502" },
      { "name": "Lac", "code": "11121503" },
      { "name": "Wood", "code": "11121600" },
      { "name": "Logs", "code": "11121603" },
      { "name": "Soft timber", "code": "11121604" },
      { "name": "Rattan", "code": "11121605" },
      { "name": "Cork", "code": "11121606" },
      { "name": "Wood pulp", "code": "11121607" },
      { "name": "Bamboo", "code": "11121608" },
      { "name": "Hardwoods", "code": "11121610" },
      { "name": "Wood pith", "code": "11121612" },
      { "name": "Teak wood", "code": "11121614" },
      { "name": "Poplar wood", "code": "11121615" },
      { "name": "Pine wood", "code": "11121616" },
      { "name": "Oak wood", "code": "11121617" },
      { "name": "Maple wood", "code": "11121618" },
      { "name": "Cherry wood", "code": "11121619" },
      { "name": "Tornillo wood", "code": "11121620" },
      { "name": "Pashaco wood", "code": "11121621" },
      { "name": "Catahua wood", "code": "11121622" },
      { "name": "Capirona wood", "code": "11121623" },
      { "name": "Copaiba wood", "code": "11121624" },
      { "name": "Eucalyptus wood", "code": "11121625" },
      { "name": "Utucuro wood", "code": "11121626" },
      { "name": "Ceiba wood", "code": "11121627" },
      { "name": "Forestry byproducts", "code": "11121700" },
      { "name": "Saw dust", "code": "11121701" },
      { "name": "Wood chips", "code": "11121702" },
      { "name": "Peat moss", "code": "11121703" },
      { "name": "Bark", "code": "11121705" },
      { "name": "Mulch", "code": "11121706" },
      { "name": "Wood wool", "code": "11121707" },
      { "name": "Wood flour", "code": "11121708" },
      { "name": "Balsams", "code": "11121709" },
      { "name": "Wood tar", "code": "11121710" },
      { "name": "Plant fibers", "code": "11121800" },
      { "name": "Hemp", "code": "11121801" },
      { "name": "Cotton", "code": "11121802" },
      { "name": "Flax", "code": "11121803" },
      { "name": "Jute", "code": "11121804" },
      { "name": "Sisal", "code": "11121805" },
      { "name": "Coconut fibres or coir", "code": "11121806" },
      { "name": "Abaca fibers", "code": "11121807" },
      { "name": "Ramie", "code": "11121808" },
      { "name": "Straw", "code": "11121809" },
      { "name": "Broom corn or istle or piassava", "code": "11121810" },
      { "name": "Cotton linter", "code": "11121811" },
      { "name": "Perfumery products", "code": "11121900" },
      { "name": "Licorice roots", "code": "11121901" },
      { "name": "Engineered wood products", "code": "11122000" },
      { "name": "Plywood", "code": "11122001" },
      { "name": "Particleboard", "code": "11122002" },
      { "name": "Medium density fiberboard", "code": "11122003" },
      { "name": "Wood veneers", "code": "11122004" },
      { "name": "Glued laminated timber", "code": "11122005" },
      { "name": "Treated timber", "code": "11122006" },
      { "name": "Densified wood", "code": "11122007" },
      { "name": "Non edible animal products", "code": "11130000" },
      {
        "name": "Animal hides and skins and animal textile materials",
        "code": "11131500"
      },
      { "name": "Feathers", "code": "11131501" },
      { "name": "Furs", "code": "11131502" },
      { "name": "Animal hair", "code": "11131503" },
      { "name": "Unprocessed mohair", "code": "11131505" },
      { "name": "Unprocessed wool", "code": "11131506" },
      { "name": "Suede", "code": "11131507" },
      { "name": "Full grain leather", "code": "11131508" },
      { "name": "Other animal products", "code": "11131600" },
      { "name": "Ivory", "code": "11131601" },
      { "name": "Semen", "code": "11131602" },
      { "name": "Excretions", "code": "11131603" },
      { "name": "Fish eggs", "code": "11131604" },
      { "name": "Bones", "code": "11131605" },
      { "name": "Animal horns", "code": "11131606" },
      { "name": "Embryos", "code": "11131607" },
      { "name": "Shell articles", "code": "11131608" },
      { "name": "Bovine semen", "code": "11131609" },
      { "name": "Non-edible bird and poultry eggs", "code": "11131610" },
      { "name": "Scrap and waste materials", "code": "11140000" },
      { "name": "Non metallic waste and scrap", "code": "11141600" },
      { "name": "Textile waste or scrap", "code": "11141601" },
      { "name": "Plastic waste or scrap", "code": "11141602" },
      { "name": "Oil wastes", "code": "11141603" },
      { "name": "Paper wastes", "code": "11141604" },
      { "name": "Glass waste or scrap", "code": "11141605" },
      { "name": "Wood waste or scrap", "code": "11141606" },
      { "name": "Rubber waste or scrap", "code": "11141607" },
      { "name": "Hazardous waste or scrap", "code": "11141608" },
      { "name": "Composition leather", "code": "11141609" },
      { "name": "Leather waste or scrap", "code": "11141610" },
      { "name": "Food and tobacco waste and scrap", "code": "11141700" },
      { "name": "Food waste or scrap", "code": "11141701" },
      { "name": "Tobacco waste or scrap", "code": "11141702" },
      { "name": "Fibers and threads and yarns", "code": "11150000" },
      { "name": "Fibers", "code": "11151500" },
      { "name": "Acetate fibers", "code": "11151501" },
      { "name": "Nylon fibers", "code": "11151502" },
      { "name": "Polyester fibers", "code": "11151503" },
      { "name": "Acrylic fibers", "code": "11151504" },
      { "name": "Viscose fibers", "code": "11151505" },
      { "name": "Rayon fibers", "code": "11151506" },
      { "name": "Cotton fibers", "code": "11151507" },
      { "name": "Wool fibers", "code": "11151508" },
      { "name": "Silk fibers", "code": "11151509" },
      { "name": "Vegetable fibers", "code": "11151510" },
      { "name": "Polypropylene fibers", "code": "11151511" },
      { "name": "Glass fibers", "code": "11151512" },
      { "name": "Ceramic fibers", "code": "11151513" },
      { "name": "Polymer aramid fibers", "code": "11151514" },
      { "name": "Asbestos fibers", "code": "11151515" },
      { "name": "Polyurethane fiber or spandex", "code": "11151516" },
      { "name": "Polyvinyl alcohol fiber", "code": "11151517" },
      { "name": "Polyethylene fiber", "code": "11151518" },
      { "name": "Rock wool or mineral wool", "code": "11151519" },
      { "name": "Human hair worked", "code": "11151520" },
      { "name": "Human hair unworked", "code": "11151521" },
      { "name": "Threads", "code": "11151600" },
      { "name": "Cotton thread", "code": "11151601" },
      { "name": "Silk thread", "code": "11151602" },
      { "name": "Polyester thread", "code": "11151603" },
      { "name": "Polyamide thread", "code": "11151604" },
      { "name": "Bismalemide thread", "code": "11151605" },
      { "name": "Fiberglass thread", "code": "11151606" },
      { "name": "Graphite thread", "code": "11151607" },
      { "name": "Nylon thread", "code": "11151608" },
      { "name": "Resin impregnated thread", "code": "11151609" },
      { "name": "Rubber or latex thread", "code": "11151610" },
      { "name": "Spandex thread", "code": "11151611" },
      { "name": "Asbestos thread", "code": "11151612" },
      { "name": "Metal thread", "code": "11151613" },
      { "name": "Yarns", "code": "11151700" },
      { "name": "Wool yarn", "code": "11151701" },
      { "name": "Cotton yarn", "code": "11151702" },
      { "name": "Polyester yarn", "code": "11151703" },
      { "name": "Acrylic yarn", "code": "11151704" },
      { "name": "Silk yarn", "code": "11151705" },
      { "name": "Ramie yarn", "code": "11151706" },
      { "name": "Animal hair yarn", "code": "11151708" },
      { "name": "Synthetic yarn", "code": "11151709" },
      { "name": "Jute yarn", "code": "11151710" },
      { "name": "Coir yarn", "code": "11151711" },
      { "name": "Paper yarn", "code": "11151712" },
      { "name": "Hemp yarn", "code": "11151713" },
      { "name": "Glass yarn", "code": "11151714" },
      { "name": "Flax yarn", "code": "11151715" },
      { "name": "Blended yarn", "code": "11151716" },
      { "name": "Gimped yarn", "code": "11151717" },
      { "name": "Metalicized yarn", "code": "11151718" },
      { "name": "Fabrics and leather materials", "code": "11160000" },
      { "name": "Silk fabrics", "code": "11161500" },
      { "name": "Plain weave silk fabrics", "code": "11161501" },
      { "name": "Jacquard weave silk fabrics", "code": "11161502" },
      { "name": "Knit silk fabrics", "code": "11161503" },
      { "name": "Silk velvets fabrics", "code": "11161504" },
      { "name": "Wool fabrics", "code": "11161600" },
      { "name": "Plain weave wool fabrics", "code": "11161601" },
      { "name": "Jacquard weave wool fabrics", "code": "11161602" },
      { "name": "Knit wool fabrics", "code": "11161603" },
      { "name": "Twill weave wool fabrics", "code": "11161604" },
      { "name": "Carded wool", "code": "11161605" },
      { "name": "Noil of wool", "code": "11161606" },
      {
        "name": "Wool degreased or carbonized and not carded or combed",
        "code": "11161607"
      },
      { "name": "Cotton terry towelling", "code": "11161608" },
      { "name": "Cotton fabrics", "code": "11161700" },
      { "name": "Plain weave cotton fabrics", "code": "11161701" },
      { "name": "Twill weave cotton fabrics", "code": "11161702" },
      { "name": "Cotton oxford cloths", "code": "11161703" },
      { "name": "Knit cotton fabrics", "code": "11161704" },
      { "name": "Cotton velvet fabrics", "code": "11161705" },
      { "name": "Cotton chenille", "code": "11161706" },
      { "name": "Cotton carded or combed", "code": "11161707" },
      { "name": "Synthetic fabrics", "code": "11161800" },
      { "name": "Plain weave synthetic fabrics", "code": "11161801" },
      { "name": "Jacquard weave synthetic fabrics", "code": "11161802" },
      { "name": "Dobby weave synthetic fabrics", "code": "11161803" },
      { "name": "Knit synthetic fabrics", "code": "11161804" },
      { "name": "Synthetic velvet fabrics", "code": "11161805" },
      { "name": "Twill weave synthetic fabric", "code": "11161806" },
      { "name": "Pile weave synthetic fabric", "code": "11161807" },
      { "name": "Chenille weave synthetic fabric", "code": "11161808" },
      {
        "name": "Fabrics of vegetable material other than cotton",
        "code": "11162000"
      },
      {
        "name": "Plain weave non cotton vegetable fiber fabrics",
        "code": "11162001"
      },
      { "name": "Knit non cotton vegetable fiber fabrics", "code": "11162002" },
      { "name": "Hessian or hemp or jute cloth", "code": "11162003" },
      { "name": "Woven jute fabric", "code": "11162004" },
      { "name": "Woven flax fabric", "code": "11162005" },
      { "name": "Specialty fabrics or cloth", "code": "11162100" },
      { "name": "Cheese cloth or fabric", "code": "11162101" },
      { "name": "Bismalemide fabric or cloth", "code": "11162102" },
      { "name": "Graphite fabric or cloth", "code": "11162104" },
      { "name": "Glass fabric or cloth", "code": "11162105" },
      { "name": "Resin impregnated fabric or cloth", "code": "11162107" },
      { "name": "Wire mesh fabric or cloth", "code": "11162108" },
      { "name": "Lace", "code": "11162109" },
      { "name": "Netting", "code": "11162110" },
      { "name": "Mesh", "code": "11162111" },
      { "name": "Coated fabrics", "code": "11162112" },
      { "name": "Upholstery fabrics", "code": "11162113" },
      { "name": "Hook and loop fabrics or tapes", "code": "11162114" },
      { "name": "Elastic braid", "code": "11162115" },
      { "name": "Burlap cloth", "code": "11162116" },
      { "name": "Rubber fabrics", "code": "11162117" },
      { "name": "Paper yarn fabric", "code": "11162118" },
      { "name": "Tracing cloth", "code": "11162119" },
      { "name": "Bolting cloth", "code": "11162120" },
      { "name": "Ornamental trimmings", "code": "11162121" },
      { "name": "Binding fabrics", "code": "11162122" },
      { "name": "Tape fabrics", "code": "11162123" },
      { "name": "Felt fabrics", "code": "11162124" },
      { "name": "Webbing fabrics", "code": "11162125" },
      { "name": "Quilted cloth", "code": "11162126" },
      { "name": "Camouflage cloth", "code": "11162127" },
      { "name": "Parachute cloth", "code": "11162128" },
      { "name": "Marquisette cloth", "code": "11162129" },
      { "name": "Dossal", "code": "11162130" },
      { "name": "Welting fabrics", "code": "11162131" },
      { "name": "Damask fabric", "code": "11162132" },
      { "name": "Satin fabric", "code": "11162133" },
      { "name": "Waterproof fabric", "code": "11162134" },
      { "name": "Embroidered fabric", "code": "11162135" },
      { "name": "Narrow weave fabric", "code": "11162136" },
      { "name": "Tufted fabric other than carpet", "code": "11162137" },
      { "name": "Gauze fabric", "code": "11162138" },
      { "name": "Animal hair or horsehair fabric", "code": "11162139" },
      { "name": "Nonwoven fabrics", "code": "11162200" },
      { "name": "Spunbonded nonwovens", "code": "11162201" },
      { "name": "Spunlaced nonwovens", "code": "11162202" },
      { "name": "Leathers", "code": "11162300" },
      { "name": "Chamois leather", "code": "11162301" },
      { "name": "Goat leather", "code": "11162302" },
      { "name": "Sheep leather", "code": "11162303" },
      { "name": "Patent leather", "code": "11162304" },
      { "name": "Cow leather", "code": "11162305" },
      { "name": "Pig leather", "code": "11162306" },
      { "name": "Synthetic or imitation leather", "code": "11162307" },
      { "name": "Buffalo leather", "code": "11162308" },
      { "name": "Reptile leather", "code": "11162309" },
      { "name": "Horsehide leather", "code": "11162310" },
      { "name": "Calfskin leather", "code": "11162311" },
      { "name": "Batting", "code": "11162400" },
      { "name": "Cotton batting", "code": "11162401" },
      { "name": "Synthetic batting", "code": "11162402" },
      { "name": "Alloys", "code": "11170000" },
      { "name": "Basic steels", "code": "11171500" },
      { "name": "E24-2 or A37-2 steel", "code": "11171501" },
      { "name": "Stainless steel alloys", "code": "11171600" },
      { "name": "Stainless steel alloy 304", "code": "11171601" },
      { "name": "Stainless steel alloy 304l", "code": "11171602" },
      { "name": "Stainless steel alloy 316", "code": "11171603" },
      { "name": "Ferro nickel alloy", "code": "11171604" },
      { "name": "High speed steels", "code": "11171700" },
      { "name": "Z90WDCV6542 or M2 high speed steel", "code": "11171701" },
      { "name": "Z90WDKCV65542 or M35 high speed steel", "code": "11171702" },
      { "name": "Nickel based super alloys", "code": "11171800" },
      { "name": "Inconel 600 super alloy", "code": "11171801" },
      { "name": "Titanium based super alloys", "code": "11171900" },
      { "name": "TA6V super alloy", "code": "11171901" },
      { "name": "Aluminum based alloys", "code": "11172000" },
      { "name": "Aluminum alloy 7178", "code": "11172001" },
      { "name": "Aluminum remelt", "code": "11172002" },
      { "name": "Aluminum iron alloy", "code": "11172003" },
      { "name": "Cobalt based super alloys", "code": "11172100" },
      { "name": "Pygmalion or 846 alloy", "code": "11172101" },
      { "name": "Magnesium based alloys", "code": "11172200" },
      { "name": "Magnesium aluminum alloy", "code": "11172201" },
      { "name": "Manganese based alloys", "code": "11172300" },
      { "name": "Ferro manganese alloy", "code": "11172301" },
      { "name": "Med carbon ferro manganese alloy", "code": "11172302" },
      { "name": "Silicon manganese alloy", "code": "11172303" },
      { "name": "Low carbon ferro manganese alloy", "code": "11172304" },
      { "name": "Metal oxide", "code": "11180000" },
      { "name": "Non ferrous metal oxides", "code": "11181500" },
      { "name": "Molybdenum oxide", "code": "11181501" },
      { "name": "Titanium oxide", "code": "11181502" },
      { "name": "Indium oxide", "code": "11181503" },
      { "name": "Tin oxide", "code": "11181504" },
      { "name": "Sealing clay", "code": "11181505" },
      { "name": "Vanadium oxide", "code": "11181506" },
      { "name": "Nickel oxide", "code": "11181507" },
      { "name": "Manganese oxide", "code": "11181508" },
      { "name": "Artificial corundum", "code": "11181509" },
      { "name": "Zinc oxide", "code": "11181510" },
      { "name": "Aluminum oxide", "code": "11181511" },
      { "name": "Copper oxide", "code": "11181512" },
      { "name": "Metal waste scrap and by products", "code": "11190000" },
      { "name": "Metal solids", "code": "11191500" },
      { "name": "Nickel solids", "code": "11191501" },
      { "name": "Basic steel solids", "code": "11191502" },
      { "name": "Ferrous alloy solids", "code": "11191503" },
      { "name": "Non ferrous alloy solids", "code": "11191504" },
      { "name": "Super alloy solids", "code": "11191505" },
      { "name": "Metal scrap", "code": "11191600" },
      { "name": "Nickel scrap", "code": "11191601" },
      { "name": "Basic steel scrap", "code": "11191602" },
      { "name": "Ferrous alloy scrap", "code": "11191603" },
      { "name": "Non ferrous alloy scrap", "code": "11191604" },
      { "name": "Super alloy scrap", "code": "11191605" },
      { "name": "Automotive wrecking for waste or scrap", "code": "11191606" },
      { "name": "Copper scrap", "code": "11191607" },
      { "name": "Lead scrap", "code": "11191608" },
      { "name": "Zinc scrap", "code": "11191609" },
      { "name": "Aluminum scrap", "code": "11191610" },
      { "name": "Tin scrap", "code": "11191611" },
      { "name": "Iron scrap", "code": "11191612" },
      { "name": "Precious metal scrap excluding gold", "code": "11191613" },
      { "name": "Gold scrap", "code": "11191614" },
      { "name": "Turnings", "code": "11191700" },
      { "name": "Nickel turnings", "code": "11191701" },
      { "name": "Bronze turnings", "code": "11191702" },
      { "name": "Metal byproducts", "code": "11191800" },
      { "name": "Manganese slag", "code": "11191801" },
      { "name": "Copper sulphide", "code": "11191802" },
      { "name": "Nickel sulphide", "code": "11191803" },
      {
        "name": "Ash containing precious metal or precious metal compounds",
        "code": "11191804"
      },
      {
        "name": "Ash containing metals or metallic compounds except precious metals",
        "code": "11191805"
      }
    ]
  },
  {
    "name": "Chemicals including Bio Chemicals and Gas Materials",
    "code": "12000000",
    "subcategories": [
      { "name": "Explosive materials", "code": "12130000" },
      { "name": "Explosives", "code": "12131500" },
      { "name": "Dynamite", "code": "12131501" },
      { "name": "Explosive cartridges", "code": "12131502" },
      { "name": "Propellant explosives", "code": "12131503" },
      { "name": "Explosive charges", "code": "12131504" },
      { "name": "Plastic explosives", "code": "12131505" },
      { "name": "Aluminized explosives", "code": "12131506" },
      { "name": "Ammonium nitrate explosives", "code": "12131507" },
      { "name": "Nitroglycerin powder explosives", "code": "12131508" },
      { "name": "Ammonium nitrate and fuel oil ANFO", "code": "12131509" },
      { "name": "White phosphorus", "code": "12131510" },
      { "name": "Pyrotechnics", "code": "12131600" },
      { "name": "Fireworks", "code": "12131601" },
      { "name": "Fog signals", "code": "12131602" },
      { "name": "Rain rockets", "code": "12131603" },
      { "name": "Flares", "code": "12131604" },
      {
        "name": "Pyrotechnic materials for theater or television",
        "code": "12131605"
      },
      { "name": "Igniters", "code": "12131700" },
      { "name": "Blasting caps", "code": "12131701" },
      { "name": "Detonators", "code": "12131702" },
      { "name": "Explosives fuses", "code": "12131703" },
      { "name": "Explosive initiators", "code": "12131704" },
      { "name": "Explosive primers", "code": "12131705" },
      { "name": "Matches", "code": "12131706" },
      { "name": "Lighters", "code": "12131707" },
      { "name": "Detonator box", "code": "12131708" },
      { "name": "Explosive tamper", "code": "12131709" },
      { "name": "Propellants", "code": "12131800" },
      { "name": "Powder propellants", "code": "12131801" },
      { "name": "Solid propellants", "code": "12131802" },
      { "name": "Gun propellants", "code": "12131803" },
      { "name": "High energy propellants", "code": "12131804" },
      { "name": "Gelled propellants", "code": "12131805" },
      { "name": "Hybrid propellants", "code": "12131806" },
      { "name": "Elements and gases", "code": "12140000" },
      { "name": "Earth metals", "code": "12141500" },
      { "name": "Beryllium Be", "code": "12141501" },
      { "name": "Magnesium Mg", "code": "12141502" },
      { "name": "Calcium Ca", "code": "12141503" },
      { "name": "Strontium Sr", "code": "12141504" },
      { "name": "Barium Ba", "code": "12141505" },
      { "name": "Radium Ra", "code": "12141506" },
      { "name": "Rare earth metals", "code": "12141600" },
      { "name": "Cerium Ce", "code": "12141601" },
      { "name": "Dysprosium Dy", "code": "12141602" },
      { "name": "Erbium Er", "code": "12141603" },
      { "name": "Europium Eu", "code": "12141604" },
      { "name": "Gadolinium Gd", "code": "12141605" },
      { "name": "Holmium Ho", "code": "12141606" },
      { "name": "Lanthanum La", "code": "12141607" },
      { "name": "Lutetium Lu", "code": "12141608" },
      { "name": "Neodymium Nd", "code": "12141609" },
      { "name": "Praseodymium Pr", "code": "12141610" },
      { "name": "Promethium Pm", "code": "12141611" },
      { "name": "Samarium Sm", "code": "12141612" },
      { "name": "Scandium Sc", "code": "12141613" },
      { "name": "Terbium Tb", "code": "12141614" },
      { "name": "Thulium Tm", "code": "12141615" },
      { "name": "Ytterbium Yb", "code": "12141616" },
      { "name": "Yttrium Y", "code": "12141617" },
      { "name": "Transition metals", "code": "12141700" },
      { "name": "Actinium Ac", "code": "12141701" },
      { "name": "Aluminum Al", "code": "12141702" },
      { "name": "Americium Am", "code": "12141703" },
      { "name": "Antimony Sb", "code": "12141704" },
      { "name": "Berkelium Bk", "code": "12141705" },
      { "name": "Bismuth Bi", "code": "12141706" },
      { "name": "Cadmium Ca", "code": "12141707" },
      { "name": "Californium Cf", "code": "12141708" },
      { "name": "Chromium Cr", "code": "12141709" },
      { "name": "Cobalt Co", "code": "12141710" },
      { "name": "Copper Cu", "code": "12141711" },
      { "name": "Curium Cm", "code": "12141712" },
      { "name": "Einsteinium Es", "code": "12141713" },
      { "name": "Fermium Fm", "code": "12141714" },
      { "name": "Gallium Ga", "code": "12141715" },
      { "name": "Germanium Ge", "code": "12141716" },
      { "name": "Gold Au", "code": "12141717" },
      { "name": "Hafnium Hf", "code": "12141718" },
      { "name": "Indium In", "code": "12141719" },
      { "name": "Iridium Ir", "code": "12141720" },
      { "name": "Iron Fe", "code": "12141721" },
      { "name": "Lawrencium Lr", "code": "12141722" },
      { "name": "Lead Pb", "code": "12141723" },
      { "name": "Manganese Mn", "code": "12141724" },
      { "name": "Mendelevium Md", "code": "12141725" },
      { "name": "Mercury Hg", "code": "12141726" },
      { "name": "Molybdenum Mo", "code": "12141727" },
      { "name": "Neptunium Np", "code": "12141728" },
      { "name": "Nickel Ni", "code": "12141729" },
      { "name": "Niobium Nb", "code": "12141730" },
      { "name": "Nobelium No", "code": "12141731" },
      { "name": "Osmium Os", "code": "12141732" },
      { "name": "Palladium Pd", "code": "12141733" },
      { "name": "Platinum Pt", "code": "12141734" },
      { "name": "Plutonium Pu", "code": "12141735" },
      { "name": "Protactinium Pa", "code": "12141736" },
      { "name": "Rhenium Re", "code": "12141737" },
      { "name": "Rhodium Rh", "code": "12141738" },
      { "name": "Ruthenium Ru", "code": "12141739" },
      { "name": "Silver Ag", "code": "12141740" },
      { "name": "Tantalum Ta", "code": "12141741" },
      { "name": "Technetium Te", "code": "12141742" },
      { "name": "Thallium Tl", "code": "12141743" },
      { "name": "Thorium Th", "code": "12141744" },
      { "name": "Tin Sn", "code": "12141745" },
      { "name": "Titanium Ti", "code": "12141746" },
      { "name": "Tungsten W", "code": "12141747" },
      { "name": "Uranium U", "code": "12141748" },
      { "name": "Vanadium V", "code": "12141749" },
      { "name": "Zinc Zn", "code": "12141750" },
      { "name": "Zirconium Zr", "code": "12141751" },
      { "name": "Bohrium Bh", "code": "12141752" },
      { "name": "Dubnium Db", "code": "12141753" },
      { "name": "Hassium Hs", "code": "12141754" },
      { "name": "Rutherfordium Rf", "code": "12141755" },
      { "name": "Seaborgium Sg", "code": "12141756" },
      { "name": "Ununnilium Uum", "code": "12141757" },
      { "name": "Unununium Uuu", "code": "12141758" },
      { "name": "Ununbium Uub", "code": "12141759" },
      { "name": "Polonium Po", "code": "12141760" },
      { "name": "Alkali metals", "code": "12141800" },
      { "name": "Cesium Cs", "code": "12141801" },
      { "name": "Francium Fm", "code": "12141802" },
      { "name": "Lithium Li", "code": "12141803" },
      { "name": "Potassium K", "code": "12141804" },
      { "name": "Rubidium Rb", "code": "12141805" },
      { "name": "Sodium Na", "code": "12141806" },
      { "name": "Non metals and pure and elemental gases", "code": "12141900" },
      { "name": "Chlorine Cl", "code": "12141901" },
      { "name": "Hydrogen H", "code": "12141902" },
      { "name": "Nitrogen N", "code": "12141903" },
      { "name": "Oxygen O", "code": "12141904" },
      { "name": "Fluorine F", "code": "12141905" },
      { "name": "Arsenic As", "code": "12141906" },
      { "name": "Boron B", "code": "12141907" },
      { "name": "Carbon C", "code": "12141908" },
      { "name": "Phosphorus P", "code": "12141909" },
      { "name": "Selenium Se", "code": "12141910" },
      { "name": "Silicon Si", "code": "12141911" },
      { "name": "Sulfur S", "code": "12141912" },
      { "name": "Tellurium Te", "code": "12141913" },
      { "name": "Astatine At", "code": "12141914" },
      { "name": "Bromine Br", "code": "12141915" },
      { "name": "Iodine I", "code": "12141916" },
      { "name": "Noble gases", "code": "12142000" },
      { "name": "Xenon gas Xe", "code": "12142001" },
      { "name": "Radon gas Rn", "code": "12142002" },
      { "name": "Krypton gas Kr", "code": "12142003" },
      { "name": "Argon gas Ar", "code": "12142004" },
      { "name": "Helium gas He", "code": "12142005" },
      { "name": "Neon gas Ne", "code": "12142006" },
      { "name": "Industrial use gases", "code": "12142100" },
      { "name": "Hydrogen compound gases", "code": "12142101" },
      { "name": "Chlorinated mixed gases", "code": "12142102" },
      { "name": "Ammonia", "code": "12142103" },
      { "name": "Carbon dioxide gas CO2", "code": "12142104" },
      { "name": "Industrial air", "code": "12142105" },
      { "name": "Inert gas mixtures", "code": "12142106" },
      { "name": "Hydrogen sulfide", "code": "12142107" },
      { "name": "Carbon monoxide", "code": "12142108" },
      { "name": "Dry ice", "code": "12142109" },
      { "name": "Liquid ammonia", "code": "12142110" },
      { "name": "Isotopes", "code": "12142200" },
      { "name": "Deuterated solvents", "code": "12142201" },
      { "name": "Heavy water", "code": "12142202" },
      { "name": "Alpha sources", "code": "12142203" },
      { "name": "Beta sources", "code": "12142204" },
      { "name": "Cobalt sources", "code": "12142205" },
      { "name": "Gamma sources", "code": "12142206" },
      { "name": "Radioisotope sources", "code": "12142207" },
      { "name": "Calibration sources", "code": "12142208" },
      { "name": "Additives", "code": "12160000" },
      { "name": "Indicators and Reagents", "code": "12161500" },
      { "name": "Affinity labels", "code": "12161501" },
      { "name": "Cross linking agents", "code": "12161502" },
      { "name": "Reagent kits", "code": "12161503" },
      { "name": "Sulfhydryl reagents", "code": "12161504" },
      { "name": "Intercalating agents", "code": "12161505" },
      { "name": "Diverting agents", "code": "12161506" },
      { "name": "Cupferron reagent", "code": "12161507" },
      { "name": "Catalysts", "code": "12161600" },
      { "name": "Acid catalysts", "code": "12161601" },
      { "name": "Combustion catalysts", "code": "12161602" },
      { "name": "Custom catalysts", "code": "12161603" },
      { "name": "Cracking catalysts", "code": "12161604" },
      { "name": "Treating catalyst", "code": "12161605" },
      { "name": "Reforming catalyst", "code": "12161606" },
      { "name": "Buffers", "code": "12161700" },
      { "name": "Ampholyte mixtures", "code": "12161701" },
      { "name": "Bicarbonate buffers", "code": "12161702" },
      { "name": "Other buffers", "code": "12161703" },
      { "name": "Acid buffers", "code": "12161704" },
      { "name": "Basic buffers", "code": "12161705" },
      { "name": "Neutral buffers", "code": "12161706" },
      { "name": "Colloids", "code": "12161800" },
      { "name": "Gels", "code": "12161801" },
      { "name": "Suspensions", "code": "12161802" },
      { "name": "Aerosols", "code": "12161803" },
      { "name": "Emulsions", "code": "12161804" },
      { "name": "Natural gelling agents", "code": "12161805" },
      { "name": "Synthetic gelling agents", "code": "12161806" },
      { "name": "Gel stabilizers", "code": "12161807" },
      { "name": "Suspending agents", "code": "12161808" },
      { "name": "Silicone gel", "code": "12161809" },
      { "name": "Surfactants", "code": "12161900" },
      { "name": "Anti foaming agents", "code": "12161901" },
      { "name": "Detergent surfactants", "code": "12161902" },
      { "name": "Foaming agents", "code": "12161903" },
      { "name": "Dispersing agents", "code": "12161904" },
      { "name": "Flushes", "code": "12161905" },
      { "name": "Wetting agents", "code": "12161906" },
      { "name": "Water flood additives", "code": "12161907" },
      { "name": "Alkyl sulfates", "code": "12161908" },
      { "name": "Betaines", "code": "12161909" },
      { "name": "Ether sulfates", "code": "12161910" },
      { "name": "Quaternaries", "code": "12161911" },
      { "name": "Sultaines", "code": "12161912" },
      { "name": "Spray adjuvant", "code": "12161913" },
      { "name": "Plasticizers", "code": "12162000" },
      { "name": "Polymerics", "code": "12162002" },
      { "name": "Agricultural oils", "code": "12162003" },
      { "name": "Sulfonamides", "code": "12162004" },
      { "name": "Glutarates", "code": "12162005" },
      { "name": "Aromatic ester plasticizer", "code": "12162006" },
      { "name": "Polyols", "code": "12162007" },
      { "name": "Prepolymers", "code": "12162008" },
      { "name": "Flame retardants", "code": "12162100" },
      { "name": "Brominated retardants", "code": "12162101" },
      { "name": "Anti oxidants", "code": "12162200" },
      { "name": "Ascorbic acid", "code": "12162201" },
      { "name": "Beta carotene", "code": "12162202" },
      { "name": "Butylated hydroxyanisole", "code": "12162203" },
      { "name": "Butylated hydroxytoluene", "code": "12162204" },
      { "name": "Calcium citrate", "code": "12162205" },
      { "name": "Canthaxanthin", "code": "12162206" },
      { "name": "Melatonin", "code": "12162207" },
      { "name": "Nordihydroguaiaretic acid", "code": "12162208" },
      { "name": "Propyl gallate", "code": "12162209" },
      { "name": "Silymarin", "code": "12162210" },
      { "name": "Sulfur dioxide", "code": "12162211" },
      { "name": "Ubiquinone or coenzyme Q10", "code": "12162212" },
      { "name": "Curing agents", "code": "12162300" },
      { "name": "Waterborne curing agents", "code": "12162301" },
      { "name": "Cement accelerators", "code": "12162302" },
      { "name": "Cement retarders", "code": "12162303" },
      { "name": "Adhesive accelerator", "code": "12162304" },
      { "name": "Concrete additive", "code": "12162305" },
      { "name": "Polymer breakers", "code": "12162400" },
      { "name": "Acidic polymer breakers", "code": "12162401" },
      { "name": "Organic polymer breakers", "code": "12162402" },
      { "name": "Emulsion breakers", "code": "12162500" },
      { "name": "Water in oil emulsion breakers", "code": "12162501" },
      { "name": "Oil in water emulsion breakers", "code": "12162502" },
      { "name": "Flotation aids", "code": "12162503" },
      { "name": "Clay stabilizers", "code": "12162600" },
      { "name": "Inorganic clay stabilizers", "code": "12162601" },
      { "name": "Organic clay stabilizers", "code": "12162602" },
      { "name": "Fluid loss additives", "code": "12162700" },
      { "name": "Natural polymer fluid loss additives", "code": "12162701" },
      { "name": "Modified polymer fluid loss additives", "code": "12162702" },
      { "name": "Friction reducers", "code": "12162800" },
      { "name": "Anionic friction reducers", "code": "12162801" },
      { "name": "Cationic friction reducers", "code": "12162802" },
      { "name": "Paraffin asphaltene control agents", "code": "12162900" },
      {
        "name": "Solvent type paraffin asphaltene control agents",
        "code": "12162901"
      },
      {
        "name": "Crystal modified paraffin asphaltene control agents",
        "code": "12162902"
      },
      {
        "name": "Dispersant type paraffin asphaltene control agents",
        "code": "12162903"
      },
      { "name": "Mud removal mixtures", "code": "12163000" },
      { "name": "Mud cleanout agents", "code": "12163001" },
      { "name": "Anti sludgers", "code": "12163100" },
      { "name": "Anti sludge additives", "code": "12163101" },
      { "name": "Deflocculant", "code": "12163102" },
      { "name": "Anti gas migration agents", "code": "12163200" },
      { "name": "Anti gas migration additives", "code": "12163201" },
      { "name": "Expanding agents", "code": "12163300" },
      { "name": "Cement expanding agents", "code": "12163301" },
      { "name": "Extenders", "code": "12163400" },
      { "name": "Cement extenders", "code": "12163401" },
      { "name": "Oil well sealants", "code": "12163500" },
      { "name": "Cementing sealants", "code": "12163501" },
      { "name": "Corrosion inhibitors", "code": "12163600" },
      { "name": "Oil production corrosion inhibitors", "code": "12163601" },
      { "name": "Gas production corrosion inhibitors", "code": "12163602" },
      { "name": "Gas hydrate controllers", "code": "12163700" },
      { "name": "Kinetic hydrate controllers", "code": "12163701" },
      { "name": "Chemical scavengers", "code": "12163800" },
      { "name": "Hydrogen sulfide scavengers", "code": "12163801" },
      { "name": "Oxygen scavengers", "code": "12163802" },
      { "name": "Scale controllers", "code": "12163900" },
      { "name": "Scale inhibitor", "code": "12163901" },
      { "name": "Scale removers or converters", "code": "12163902" },
      { "name": "Bactericides", "code": "12164000" },
      { "name": "Registered microbiocides", "code": "12164001" },
      { "name": "In situ", "code": "12164100" },
      { "name": "In situ additives", "code": "12164101" },
      { "name": "Acid additives", "code": "12164102" },
      { "name": "Retarders", "code": "12164200" },
      { "name": "Acid corrosion inhibitors", "code": "12164201" },
      { "name": "Iron controllers", "code": "12164300" },
      { "name": "Iron control additives", "code": "12164301" },
      { "name": "Iron sequestering agent", "code": "12164302" },
      { "name": "Iron corrosion inhibitor", "code": "12164303" },
      { "name": "Non emulsifiers", "code": "12164400" },
      { "name": "Non emulsifying additives", "code": "12164401" },
      { "name": "Food or drug additives", "code": "12164500" },
      { "name": "Preservatives", "code": "12164501" },
      { "name": "Flavours or extracts", "code": "12164502" },
      { "name": "Fragrance additives", "code": "12164503" },
      { "name": "Sweeteners", "code": "12164504" },
      { "name": "Excipient", "code": "12164505" },
      { "name": "Tablet binder", "code": "12164506" },
      { "name": "Tablet coating", "code": "12164507" },
      { "name": "Disintegrant", "code": "12164508" },
      { "name": "Anticaking agent", "code": "12164509" },
      { "name": "Paper plastic and fabric modifiers", "code": "12164600" },
      { "name": "Sizing agent", "code": "12164601" },
      { "name": "Thickening agent", "code": "12164602" },
      { "name": "Optical brightener", "code": "12164603" },
      { "name": "Antistatic agent", "code": "12164604" },
      { "name": "Micro flocculant", "code": "12164605" },
      { "name": "Light and ultraviolet UV stabilizer", "code": "12164606" },
      { "name": "Industrial antiseptics", "code": "12164700" },
      { "name": "Antiseptic additive", "code": "12164701" },
      { "name": "Water reducing agents", "code": "12164800" },
      { "name": "Cement water reducing agent", "code": "12164801" },
      { "name": "Water swelling or water stop agent", "code": "12164802" },
      { "name": "Structural water repellent", "code": "12164803" },
      { "name": "Waterproofing agents", "code": "12164900" },
      { "name": "Urethane waterproof coating", "code": "12164901" },
      { "name": "Asphalt waterproof coating", "code": "12164902" },
      { "name": "Epoxy waterproof coating", "code": "12164903" },
      { "name": "Inorganic waterproof coating", "code": "12164904" },
      { "name": "Waterproof admixture", "code": "12164905" },
      { "name": "Non shrinkage agents", "code": "12165000" },
      { "name": "Concrete non shrinkage agent", "code": "12165001" },
      { "name": "Chemicals for enhanced oil recovery", "code": "12165100" },
      { "name": "Polymer gel", "code": "12165101" },
      { "name": "Dispersant surfactant", "code": "12165102" },
      { "name": "Alkali polymer surfactant ASP", "code": "12165103" },
      { "name": "Surfactant polymer SP", "code": "12165104" },
      { "name": "Colorants", "code": "12170000" },
      { "name": "Dyes", "code": "12171500" },
      { "name": "Fluorescent dyes", "code": "12171501" },
      { "name": "Phthalein dyes", "code": "12171502" },
      { "name": "Rosaniline dyes", "code": "12171503" },
      { "name": "Food or drug or cosmetic safe FDC dyes", "code": "12171504" },
      { "name": "Laked", "code": "12171505" },
      { "name": "Natural dyes", "code": "12171506" },
      { "name": "Sulfur dye", "code": "12171507" },
      { "name": "Vat dye", "code": "12171508" },
      { "name": "Reactive dye", "code": "12171509" },
      { "name": "Solvent dye", "code": "12171510" },
      { "name": "Acid dye", "code": "12171511" },
      { "name": "Pigments", "code": "12171600" },
      { "name": "Inorganic metal oxides", "code": "12171602" },
      { "name": "Carbon black", "code": "12171603" },
      { "name": "Titanium dioxide", "code": "12171604" },
      { "name": "Organic pigments", "code": "12171605" },
      { "name": "Zeaxanthin", "code": "12171606" },
      { "name": "Paris green", "code": "12171607" },
      { "name": "Caput mortuum", "code": "12171608" },
      { "name": "Cadmium green", "code": "12171609" },
      { "name": "Cadmium orange", "code": "12171610" },
      { "name": "Cadmium yellow", "code": "12171611" },
      { "name": "Cadmium red", "code": "12171612" },
      { "name": "Red ochre", "code": "12171613" },
      { "name": "Yellow ochre", "code": "12171614" },
      { "name": "Sanguine", "code": "12171615" },
      { "name": "Prussian blue", "code": "12171616" },
      { "name": "Venetian red", "code": "12171617" },
      { "name": "Chrome green", "code": "12171618" },
      { "name": "Aureolin", "code": "12171619" },
      { "name": "Chrome yellow", "code": "12171620" },
      { "name": "Fluorescent pigment", "code": "12171621" },
      { "name": "Color compounds and dispersions", "code": "12171700" },
      { "name": "Polymer masterbatches", "code": "12171701" },
      { "name": "Pigment dispersions", "code": "12171702" },
      { "name": "Inks", "code": "12171703" },
      { "name": "Waxes and oils", "code": "12180000" },
      { "name": "Waxes", "code": "12181500" },
      { "name": "Synthetic waxes", "code": "12181501" },
      { "name": "Natural waxes", "code": "12181502" },
      { "name": "Paraffins", "code": "12181503" },
      { "name": "Petrolatums", "code": "12181504" },
      { "name": "Oils", "code": "12181600" },
      { "name": "Synthetic oils", "code": "12181601" },
      { "name": "Natural oils", "code": "12181602" },
      { "name": "Solvents", "code": "12190000" },
      { "name": "Hydrocarbonated solvents", "code": "12191500" },
      { "name": "Aromatic solvents", "code": "12191501" },
      { "name": "Aliphatic solvents", "code": "12191502" },
      {
        "name": "Phenols or its substitutes or derivatives",
        "code": "12191503"
      },
      { "name": "Cyclic alkanes", "code": "12191504" },
      { "name": "Oxygenated solvents", "code": "12191600" },
      { "name": "Alcohol solvents", "code": "12191601" },
      { "name": "Active solvents", "code": "12191602" },
      { "name": "Compounds and mixtures", "code": "12350000" },
      { "name": "Aliphatic and aromatic compounds", "code": "12352000" },
      { "name": "Alkanes", "code": "12352001" },
      { "name": "Alkenes", "code": "12352002" },
      { "name": "Alkynes", "code": "12352003" },
      { "name": "Aromatic or heterocyclic compounds", "code": "12352005" },
      { "name": "Toluene", "code": "12352006" },
      {
        "name": "Organic derivatives and substituted compounds",
        "code": "12352100"
      },
      { "name": "Organic halogenated compounds", "code": "12352101" },
      { "name": "Organic nitro or nitroso compounds", "code": "12352102" },
      { "name": "Organo metallic compounds", "code": "12352103" },
      { "name": "Alcohols or its substitutes", "code": "12352104" },
      { "name": "Thio alcohols", "code": "12352105" },
      { "name": "Organic acids or its substitutes", "code": "12352106" },
      { "name": "Organic salts or its substitutes", "code": "12352107" },
      { "name": "Esters or its substitutes", "code": "12352108" },
      { "name": "Amides or imides", "code": "12352111" },
      { "name": "Ethers or its substitutes", "code": "12352112" },
      { "name": "Thioethers", "code": "12352113" },
      { "name": "Aldehydes or its substitutes", "code": "12352114" },
      { "name": "Ketones or quinones or its substitutes", "code": "12352115" },
      { "name": "Amines or imines or its substitutes", "code": "12352116" },
      { "name": "Cyanides or isocyanides", "code": "12352117" },
      {
        "name": "Cyanates or isocyanates or thiocyantes or isothiocyanates",
        "code": "12352118"
      },
      { "name": "Organic oxides", "code": "12352119" },
      { "name": "Organic peroxides", "code": "12352120" },
      { "name": "Organic hydroxides", "code": "12352121" },
      { "name": "Ureides or purines or their derivatives", "code": "12352123" },
      { "name": "Azo compounds or its substitutes", "code": "12352124" },
      { "name": "Azides or azines", "code": "12352125" },
      { "name": "Oximes", "code": "12352126" },
      {
        "name": "Hydrazines or hydrazides or its substitiutes",
        "code": "12352127"
      },
      { "name": "Phosphines", "code": "12352128" },
      { "name": "Amidines or imidines", "code": "12352129" },
      { "name": "Acrylate or methacrylate intermediates", "code": "12352130" },
      { "name": "Pyrrolidone", "code": "12352131" },
      { "name": "Triclosan", "code": "12352132" },
      { "name": "Freeze conditioner", "code": "12352133" },
      { "name": "Propylene glycol", "code": "12352135" },
      { "name": "Triethylene glycol", "code": "12352136" },
      { "name": "Ethylene glycol", "code": "12352137" },
      { "name": "Methyl ethyl ketone", "code": "12352138" },
      { "name": "Biochemicals", "code": "12352200" },
      { "name": "Carbohydrates or its derivatives", "code": "12352201" },
      { "name": "Proteins", "code": "12352202" },
      { "name": "Antibodies", "code": "12352203" },
      { "name": "Enzymes", "code": "12352204" },
      { "name": "Nutrients", "code": "12352205" },
      { "name": "Tissues", "code": "12352206" },
      { "name": "Cultures and fluids", "code": "12352207" },
      { "name": "Nucleic acids", "code": "12352208" },
      { "name": "Amino acids or its derivatives", "code": "12352209" },
      { "name": "Alkaloids", "code": "12352210" },
      { "name": "Fats or lipids", "code": "12352211" },
      { "name": "Terpenoids", "code": "12352212" },
      { "name": "Inorganic compounds", "code": "12352300" },
      { "name": "Inorganic acids", "code": "12352301" },
      { "name": "Inorganic metal salts", "code": "12352302" },
      { "name": "Inorganic oxides", "code": "12352303" },
      { "name": "Inorganic peroxides", "code": "12352304" },
      { "name": "Inorganic hydroxides", "code": "12352305" },
      { "name": "Inorganic hydrides", "code": "12352306" },
      { "name": "Acid halides or its substitutes", "code": "12352307" },
      { "name": "Silicates", "code": "12352308" },
      { "name": "Silica", "code": "12352309" },
      { "name": "Silicones", "code": "12352310" },
      { "name": "Alumina and other aluminum compounds", "code": "12352311" },
      { "name": "Potassium permanganate", "code": "12352312" },
      { "name": "Ammonium sulphate", "code": "12352314" },
      { "name": "Liquid silicone rubber LSR", "code": "12352315" },
      { "name": "Sodium hydroxide", "code": "12352316" },
      { "name": "Aluminum fluoride", "code": "12352317" },
      { "name": "Calcium fluoride", "code": "12352318" },
      { "name": "Calcium hydroxide", "code": "12352319" },
      { "name": "Potassium hydroxide", "code": "12352320" },
      { "name": "Acetylene glycol", "code": "12352321" },
      { "name": "Sulphuric acid", "code": "12352322" },
      { "name": "Hydrochloric acid", "code": "12352323" },
      { "name": "Mixtures", "code": "12352400" },
      { "name": "Organic chemical mixtures", "code": "12352401" },
      { "name": "Inorganic chemical mixtures", "code": "12352402" },
      { "name": "Fixatives", "code": "12352500" },
      { "name": "Formaldehydes", "code": "12352501" },
      { "name": "Glutarals", "code": "12352502" },
      { "name": "Tannins", "code": "12352503" },
      { "name": "Fluorochemicals", "code": "12352600" },
      { "name": "Trifluoroacetic acid TFA", "code": "12352601" },
      { "name": "Hydrofluoric acid, anhydrous HF", "code": "12352602" },
      { "name": "Hexafluoropropylene HFP", "code": "12352603" },
      { "name": "Fluorinated Refrigerants", "code": "12352604" },
      { "name": "Pharmaceutical drug precursors", "code": "12360000" },
      { "name": "Narcotic drug precursors", "code": "12361500" },
      { "name": "1-phenyl-2-propanone", "code": "12361501" },
      { "name": "3,4-methylenedioxyphenyl-2-propanone", "code": "12361502" },
      { "name": "Gamma-butyrolactone", "code": "12361503" },
      { "name": "Hydroiodic acid", "code": "12361504" },
      { "name": "Hypophosphorous acid", "code": "12361505" },
      { "name": "N-acetylanthranilic acid", "code": "12361506" },
      { "name": "Phenylacetic acid", "code": "12361507" },
      { "name": "Piperonal", "code": "12361508" },
      { "name": "Red phosphorus", "code": "12361509" },
      { "name": "Acetic anhydride", "code": "12361510" },
      { "name": "Pseudoephedrine hydrochloride", "code": "12361512" },
      { "name": "Ephedrine hydrochloride", "code": "12361513" }
    ]
  },
  {
    "name": "Resin and Rosin and Rubber and Foam and Film and Elastomeric Materials",
    "code": "13000000",
    "subcategories": [
      { "name": "Rubber and elastomers", "code": "13100000" },
      { "name": "Natural rubber", "code": "13101500" },
      { "name": "Latex rubber", "code": "13101501" },
      { "name": "Crepe rubber", "code": "13101502" },
      { "name": "Smoked sheet rubber", "code": "13101503" },
      { "name": "Natural foam rubber", "code": "13101504" },
      { "name": "Block or crumb rubber", "code": "13101505" },
      { "name": "Processed and synthetic rubber", "code": "13101600" },
      { "name": "Vulcanized rubber", "code": "13101601" },
      { "name": "Chlorinated rubber", "code": "13101602" },
      { "name": "Hydrochloride rubber", "code": "13101603" },
      { "name": "Cyclized rubber", "code": "13101604" },
      { "name": "Isomerized rubber", "code": "13101605" },
      { "name": "Thermplastic rubber", "code": "13101606" },
      { "name": "Rubber compound", "code": "13101607" },
      { "name": "Reclaimed rubber", "code": "13101608" },
      { "name": "Elastomers", "code": "13101700" },
      { "name": "Acrylonitrile butadiene NBR", "code": "13101701" },
      { "name": "Highly saturated nitrile HNBR", "code": "13101702" },
      { "name": "Fluorocarbon FKM", "code": "13101703" },
      { "name": "Ethylene propylene EP", "code": "13101704" },
      { "name": "Styrene butadiene SBR", "code": "13101705" },
      { "name": "Chloroprene CR", "code": "13101706" },
      { "name": "Isobutylene isoprene IIR/XIIR", "code": "13101707" },
      { "name": "Silicone VMQ and PMQ and PVMQ", "code": "13101708" },
      { "name": "Fluorosilicone FVMQ", "code": "13101709" },
      { "name": "Polyacrylate ACM", "code": "13101710" },
      { "name": "Ethylene acrylic AEM", "code": "13101711" },
      { "name": "Chlorosulfonated polyethylene CSM", "code": "13101712" },
      { "name": "Chloropolyethylene CM", "code": "13101713" },
      { "name": "Epichlorohydrin ECO", "code": "13101714" },
      { "name": "Natural polyisoprene NR", "code": "13101715" },
      { "name": "Synthetic polyisoprene IR", "code": "13101716" },
      { "name": "Polyester urethane AU", "code": "13101717" },
      { "name": "Polyether urethane EU", "code": "13101718" },
      { "name": "Polybutadiene BR", "code": "13101719" },
      { "name": "Polyether block amide PEBA", "code": "13101720" },
      { "name": "Styrene block coploymer TES", "code": "13101721" },
      { "name": "Copolyester", "code": "13101722" },
      { "name": "Thermoplastic", "code": "13101723" },
      { "name": "Polyolenfinic", "code": "13101724" },
      { "name": "Ethylene propylene diene EPDM", "code": "13101725" },
      { "name": "Thermoset plastics", "code": "13101900" },
      { "name": "Phenolic PF", "code": "13101902" },
      { "name": "Unsaturate Polyester UP", "code": "13101903" },
      { "name": "Urea UF", "code": "13101904" },
      { "name": "Melamine MF", "code": "13101905" },
      { "name": "Thermoset Polyurethane PUR", "code": "13101906" },
      { "name": "Thermoplastic plastics", "code": "13102000" },
      { "name": "Acrylonitrile butadiene styrene ABS", "code": "13102001" },
      {
        "name": "Acrylonitrile butadiene styrene ABS alloys",
        "code": "13102002"
      },
      { "name": "Acetal polymer", "code": "13102003" },
      { "name": "Acrylonitrile Styrene Acrylic ASA", "code": "13102005" },
      {
        "name": "Acrylonitrile Styrene Acrylic ASA alloys",
        "code": "13102006"
      },
      { "name": "Fluoropolymers PTFE", "code": "13102008" },
      { "name": "Liquid Crystal Polymer LCP", "code": "13102010" },
      { "name": "Polyamide Nylons PA", "code": "13102011" },
      { "name": "Polybutylene Terepthalate PBT", "code": "13102012" },
      { "name": "Polycarbonate PC", "code": "13102013" },
      { "name": "Polyetheretherketone PEEK", "code": "13102014" },
      { "name": "Polyethersulfone PES", "code": "13102016" },
      { "name": "High Density Polyethylene HDPE", "code": "13102017" },
      { "name": "Low Density Polyethylene LDPE", "code": "13102018" },
      { "name": "Medium Density Polyethylene MDPE", "code": "13102019" },
      { "name": "Polyethylene Terepthalate PET", "code": "13102020" },
      { "name": "Polyimide PI", "code": "13102021" },
      { "name": "Polypropylene PP", "code": "13102022" },
      { "name": "Polyphenylene oxide PPO", "code": "13102024" },
      { "name": "Polyphenylene Sulfide PPS", "code": "13102025" },
      { "name": "Polystyrene PS", "code": "13102026" },
      { "name": "High Impact Polystyrene HIPS", "code": "13102027" },
      { "name": "Polysulfone PSU", "code": "13102028" },
      { "name": "Rigid Thermoplastic Polyurethane RPTU", "code": "13102029" },
      { "name": "Polyvinyl Chloride PVC", "code": "13102030" },
      { "name": "Polyphenylene ether PPE", "code": "13102031" },
      { "name": "Thermoplastic polyolefin TPO", "code": "13102032" },
      {
        "name": "Resins and rosins and other resin derived materials",
        "code": "13110000"
      },
      { "name": "Resins", "code": "13111000" },
      { "name": "Epoxy", "code": "13111001" },
      { "name": "Phenolic resin", "code": "13111002" },
      { "name": "Unsaturated polyester resin", "code": "13111003" },
      { "name": "Acrylonitrile butadiene styrene resin", "code": "13111004" },
      { "name": "Acrylonitrile styrene acrylic resin", "code": "13111005" },
      {
        "name": "Acrylonitrile styrene acrylic alloy resin",
        "code": "13111006"
      },
      { "name": "Fluoropolymer resin", "code": "13111007" },
      { "name": "Ethylene vinyl acetate resin", "code": "13111008" },
      { "name": "Liquid crystal polymer resin", "code": "13111009" },
      { "name": "Nylon", "code": "13111010" },
      { "name": "Polybutylene terepthatlate", "code": "13111011" },
      { "name": "Polycarbonate resin", "code": "13111012" },
      { "name": "Polyetheretherketone resin", "code": "13111013" },
      { "name": "Polyetherimide resin", "code": "13111014" },
      { "name": "Polyethersulfone resin", "code": "13111015" },
      { "name": "Polyethylene", "code": "13111016" },
      { "name": "Polyethylene terpthalate resin", "code": "13111017" },
      { "name": "Polyimide resin", "code": "13111018" },
      { "name": "Polypropylene resin", "code": "13111019" },
      { "name": "Polyphthalamide resin", "code": "13111020" },
      { "name": "Polyethylene oxide", "code": "13111021" },
      { "name": "Polyphenylene sulfide resin", "code": "13111022" },
      { "name": "Polystyrene resin", "code": "13111023" },
      { "name": "Polysulfone resin", "code": "13111024" },
      { "name": "Polyvinyl chloride resin", "code": "13111025" },
      { "name": "Styrene acrylonitrile resin", "code": "13111026" },
      { "name": "Urea formaldehyde", "code": "13111027" },
      { "name": "Alkyd", "code": "13111028" },
      { "name": "Melamine formaldehyde", "code": "13111029" },
      { "name": "Polyacetal", "code": "13111030" },
      { "name": "Polyamide", "code": "13111031" },
      { "name": "Allyl", "code": "13111032" },
      { "name": "Ethylene acrylic acid", "code": "13111033" },
      { "name": "Polyvinyl chloride compound", "code": "13111034" },
      { "name": "Solution vinyl", "code": "13111035" },
      { "name": "Phenoxy", "code": "13111036" },
      { "name": "Compounded resin", "code": "13111037" },
      { "name": "Polyvinyl pyrolidine", "code": "13111038" },
      {
        "name": "Polyethylene terephthalate or glycol modified",
        "code": "13111039"
      },
      { "name": "Hydrocarbon tackifier", "code": "13111040" },
      { "name": "Polycarbonate blends", "code": "13111041" },
      { "name": "Polyvinyl alcohol", "code": "13111042" },
      { "name": "Polyvinyl butyral", "code": "13111043" },
      { "name": "Polyester molding compound", "code": "13111044" },
      { "name": "Polyvinyl acetate", "code": "13111045" },
      { "name": "Polyvinyl ether", "code": "13111046" },
      { "name": "Polyvinyl formal", "code": "13111047" },
      { "name": "Styrene acrylic", "code": "13111048" },
      { "name": "Ethylene propylene polymers", "code": "13111049" },
      { "name": "Polypropylene oxide", "code": "13111050" },
      { "name": "Polypropylene ether", "code": "13111051" },
      { "name": "Polypropylene sulfone", "code": "13111052" },
      { "name": "Polymethylacrylate", "code": "13111053" },
      { "name": "Styrene maleic anhydride", "code": "13111054" },
      { "name": "Syndiotatic polystyrene", "code": "13111055" },
      { "name": "Chlorinated polyvinyl chloride", "code": "13111056" },
      { "name": "Thermoplastic polyester", "code": "13111057" },
      { "name": "Indene resins", "code": "13111058" },
      { "name": "Plastic resins", "code": "13111059" },
      { "name": "Petroleum resins", "code": "13111060" },
      { "name": "Polyurethane resins", "code": "13111061" },
      { "name": "Polyether resins", "code": "13111062" },
      { "name": "Recycled resins", "code": "13111063" },
      { "name": "Acrylic resins", "code": "13111064" },
      { "name": "Cellulosic resins", "code": "13111065" },
      { "name": "Polyterpene resins", "code": "13111066" },
      { "name": "Ethylene Vinyl Alcohol", "code": "13111067" },
      { "name": "Linear Low Density Polyethylene", "code": "13111068" },
      { "name": "Polyacrylonitrile resin", "code": "13111069" },
      { "name": "Polyamideimide resin", "code": "13111070" },
      { "name": "Polyaryletherketone resin", "code": "13111071" },
      { "name": "Polybenzimidazole resin", "code": "13111072" },
      { "name": "Polymethylpentene resin", "code": "13111073" },
      { "name": "Polyvinylidene Fluoride", "code": "13111074" },
      {
        "name": "Polycarbonate acrylonitrile butadiene styrene alloy PC ABS",
        "code": "13111075"
      },
      { "name": "Cross linked polyethylene PEX", "code": "13111076" },
      { "name": "Polyamide 6-12", "code": "13111077" },
      { "name": "Polyamide 6-6", "code": "13111078" },
      { "name": "Polyamide 4-6", "code": "13111079" },
      { "name": "Polyamide high temperature nylon HTN", "code": "13111080" },
      { "name": "Polyamide 12", "code": "13111081" },
      { "name": "Polyamide 6", "code": "13111082" },
      { "name": "Rosins", "code": "13111100" },
      { "name": "Wood rosin", "code": "13111101" },
      { "name": "Gum rosin", "code": "13111102" },
      { "name": "Tall oil rosin", "code": "13111103" },
      { "name": "Plastic films", "code": "13111200" },
      { "name": "Polyethylene films", "code": "13111201" },
      { "name": "Polyurethane films", "code": "13111202" },
      { "name": "Acetate films", "code": "13111203" },
      { "name": "Acrylic films", "code": "13111204" },
      { "name": "Coextruded films", "code": "13111205" },
      { "name": "Flouropolymer films", "code": "13111206" },
      { "name": "Metalized films", "code": "13111207" },
      { "name": "Nylon films", "code": "13111208" },
      { "name": "Polycarbonate films", "code": "13111209" },
      { "name": "Polyester films", "code": "13111210" },
      { "name": "Polypropylene films", "code": "13111211" },
      { "name": "Biaxially orientated polypropylene", "code": "13111212" },
      { "name": "Polymide films", "code": "13111213" },
      { "name": "Polystyrene films", "code": "13111214" },
      { "name": "Flexible polyvinyl chloride film", "code": "13111215" },
      { "name": "Rigid polyvinyl chloride film", "code": "13111216" },
      { "name": "Ethylene vinyl alcohol film", "code": "13111217" },
      { "name": "Polyvinylidene chloride", "code": "13111218" },
      { "name": "Polyvinyl alcohol films", "code": "13111219" },
      { "name": "Silicone coated films", "code": "13111220" },
      { "name": "Foams", "code": "13111300" },
      { "name": "Polyolefin foam", "code": "13111301" },
      { "name": "Polyether foam", "code": "13111302" },
      { "name": "Silicone foam", "code": "13111303" },
      { "name": "Ethylene propylene terpolymer foam", "code": "13111304" },
      { "name": "Neoprene foam", "code": "13111305" },
      { "name": "Polyvinyl chloride foam", "code": "13111306" },
      { "name": "Rubber foam", "code": "13111307" },
      { "name": "Polystyrene foam", "code": "13111308" },
      { "name": "Polyurethane foam", "code": "13111309" },
      { "name": "Ethylene vinyl acetate foam", "code": "13111310" }
    ]
  },
  {
    "name": "Paper Materials and Products",
    "code": "14000000",
    "subcategories": [
      { "name": "Paper materials", "code": "14100000" },
      { "name": "Raw materials", "code": "14101500" },
      { "name": "Paper pulp", "code": "14101501" },
      { "name": "Paper products", "code": "14110000" },
      { "name": "Printing and writing paper", "code": "14111500" },
      { "name": "Onion skin paper", "code": "14111501" },
      { "name": "Vellum paper", "code": "14111502" },
      { "name": "Parchment paper", "code": "14111503" },
      { "name": "Tractor feed paper", "code": "14111504" },
      { "name": "Mimeograph paper", "code": "14111505" },
      { "name": "Computer printout paper", "code": "14111506" },
      { "name": "Printer or copier paper", "code": "14111507" },
      { "name": "Facsimile paper", "code": "14111508" },
      { "name": "Stationery", "code": "14111509" },
      { "name": "Plotter paper", "code": "14111510" },
      { "name": "Writing paper", "code": "14111511" },
      { "name": "Graph paper", "code": "14111512" },
      { "name": "Ledger paper", "code": "14111513" },
      { "name": "Paper pads or notebooks", "code": "14111514" },
      { "name": "Calculator or cash register paper", "code": "14111515" },
      { "name": "Notebook filler paper", "code": "14111516" },
      { "name": "Index cards", "code": "14111518" },
      { "name": "Cardstock papers", "code": "14111519" },
      { "name": "Blotter paper", "code": "14111520" },
      { "name": "Tracing paper", "code": "14111523" },
      { "name": "Foolscap sheets", "code": "14111524" },
      { "name": "Multipurpose paper", "code": "14111525" },
      { "name": "Telephone message pads or books", "code": "14111526" },
      { "name": "Carbonless paper", "code": "14111527" },
      { "name": "Magnet paper", "code": "14111528" },
      { "name": "Telex rolls", "code": "14111529" },
      { "name": "Self adhesive note paper", "code": "14111530" },
      { "name": "Log books or pads", "code": "14111531" },
      { "name": "Assorted paper kits", "code": "14111532" },
      { "name": "Examination booklets or forms", "code": "14111533" },
      { "name": "Music score or manuscript papers", "code": "14111534" },
      { "name": "Telegraph papers", "code": "14111535" },
      { "name": "Library book or borrowers cards", "code": "14111536" },
      { "name": "Label papers", "code": "14111537" },
      { "name": "Digital paper", "code": "14111538" },
      {
        "name": "Medical monitoring or tracing or recording paper",
        "code": "14111539"
      },
      { "name": "Stamp paper", "code": "14111540" },
      { "name": "Optical mark reader paper", "code": "14111541" },
      { "name": "Korean paper for stationery", "code": "14111542" },
      { "name": "Inkstone", "code": "14111543" },
      { "name": "Security paper", "code": "14111544" },
      { "name": "Wide format printer paper", "code": "14111545" },
      { "name": "Novelty paper", "code": "14111600" },
      { "name": "Gift wrapping paper or bags or boxes", "code": "14111601" },
      { "name": "Business cards", "code": "14111604" },
      { "name": "Greeting or note or post cards", "code": "14111605" },
      { "name": "Art or craft paper", "code": "14111606" },
      { "name": "Poster boards", "code": "14111607" },
      { "name": "Gift certificate", "code": "14111608" },
      { "name": "Cover paper", "code": "14111609" },
      { "name": "Construction paper", "code": "14111610" },
      { "name": "Invitation or announcement cards", "code": "14111611" },
      { "name": "Banner paper", "code": "14111613" },
      { "name": "Album papers or tissues", "code": "14111614" },
      { "name": "Poster papers", "code": "14111615" },
      { "name": "Lining papers", "code": "14111616" },
      { "name": "Leathack paper", "code": "14111617" },
      { "name": "Kent paper", "code": "14111618" },
      { "name": "Personal paper products", "code": "14111700" },
      { "name": "Facial tissues", "code": "14111701" },
      { "name": "Toilet seat covers", "code": "14111702" },
      { "name": "Paper towels", "code": "14111703" },
      { "name": "Toilet tissue", "code": "14111704" },
      { "name": "Paper napkins or serviettes", "code": "14111705" },
      { "name": "Paper table cloth", "code": "14111706" },
      { "name": "Business use papers", "code": "14111800" },
      { "name": "Tickets or ticket rolls", "code": "14111801" },
      { "name": "Receipts or receipt books", "code": "14111802" },
      { "name": "Vouchers", "code": "14111803" },
      { "name": "Bills or bill books", "code": "14111804" },
      { "name": "Checks or check books", "code": "14111805" },
      { "name": "Business forms or questionnaires", "code": "14111806" },
      { "name": "Multipurpose business book", "code": "14111807" },
      { "name": "Accounting forms or accounting books", "code": "14111808" },
      {
        "name": "Bill of lading forms or bill of lading books",
        "code": "14111809"
      },
      { "name": "Personnel forms or personnel books", "code": "14111810" },
      { "name": "Sales forms or sales books", "code": "14111811" },
      { "name": "Inventory forms or inventory books", "code": "14111812" },
      {
        "name": "Correspondence forms or correspondence books",
        "code": "14111813"
      },
      { "name": "Tax forms or tax books", "code": "14111814" },
      { "name": "Tent cards", "code": "14111815" },
      { "name": "Applicant fingerprint cards", "code": "14111816" },
      { "name": "Deposit verification form", "code": "14111817" },
      { "name": "Thermal paper", "code": "14111818" },
      { "name": "Booking forms or reservation books", "code": "14111819" },
      { "name": "Game of chance forms or coupons", "code": "14111820" },
      { "name": "Order forms or order books", "code": "14111821" },
      { "name": "Delivery forms or delivery books", "code": "14111822" },
      { "name": "Control forms or control books", "code": "14111823" },
      { "name": "Pharmacy prescription pad", "code": "14111824" },
      { "name": "Menu", "code": "14111825" },
      { "name": "Birth certificate", "code": "14111826" },
      { "name": "Death certificate", "code": "14111827" },
      { "name": "Business letterhead paper", "code": "14111828" },
      { "name": "Pre-printed notepad", "code": "14111829" },
      { "name": "Engrossing paper", "code": "14111830" },
      { "name": "Visitor or guest book", "code": "14111831" },
      { "name": "Industrial use papers", "code": "14120000" },
      { "name": "Paperboard and packaging papers", "code": "14121500" },
      { "name": "Bleached paperboard", "code": "14121501" },
      { "name": "Unbleached paperboard", "code": "14121502" },
      { "name": "Cardboard", "code": "14121503" },
      { "name": "Packaging paper", "code": "14121504" },
      { "name": "Fiberboards", "code": "14121505" },
      {
        "name": "Corrugated fiberboard or container board CCM",
        "code": "14121506"
      },
      {
        "name": "Volatile corrosion inhibitor or VCI paper",
        "code": "14121507"
      },
      { "name": "Kaolin treated paperboard", "code": "14121508" },
      {
        "name": "Composite paper or paperboard without surface coating",
        "code": "14121509"
      },
      { "name": "Test liner paperboard", "code": "14121510" },
      { "name": "Tissue papers", "code": "14121600" },
      { "name": "Unbleached crepe papers", "code": "14121601" },
      { "name": "Semi bleached crepe papers", "code": "14121602" },
      { "name": "Wet strength tissue papers", "code": "14121603" },
      { "name": "Acid free tissue papers", "code": "14121604" },
      { "name": "Kraft tissue paper", "code": "14121605" },
      { "name": "Laminated papers", "code": "14121700" },
      { "name": "Papers bonded with film", "code": "14121701" },
      {
        "name": "Cylinder papers or multi layer heavyweight paper",
        "code": "14121702"
      },
      { "name": "Laminated aluminum foil paper", "code": "14121703" },
      { "name": "Coated papers", "code": "14121800" },
      { "name": "Clay coated papers", "code": "14121801" },
      { "name": "Polyethylene coated papers", "code": "14121802" },
      { "name": "Polyester coated papers", "code": "14121803" },
      { "name": "Silicone coated papers", "code": "14121804" },
      { "name": "Latex treated coated paper", "code": "14121805" },
      { "name": "Waxed paper", "code": "14121806" },
      { "name": "Butcher papers", "code": "14121807" },
      { "name": "Freezer paper", "code": "14121808" },
      { "name": "Masking paper", "code": "14121809" },
      { "name": "Carbon papers", "code": "14121810" },
      { "name": "Sensitized copying papers", "code": "14121811" },
      { "name": "Photography paper", "code": "14121812" },
      { "name": "Satin paper", "code": "14121813" },
      { "name": "Newsprint and offset papers", "code": "14121900" },
      { "name": "Standard newsprint", "code": "14121901" },
      { "name": "Colored newsprint", "code": "14121902" },
      { "name": "High brightness newsprint", "code": "14121903" },
      { "name": "Offset paper", "code": "14121904" },
      { "name": "Tympan papers", "code": "14121905" },
      { "name": "Uncoated base papers", "code": "14122100" },
      { "name": "Super calendared kraft paper", "code": "14122101" },
      { "name": "Machine finished or glazed kraft paper", "code": "14122102" },
      { "name": "Non treated uncoated paper", "code": "14122103" },
      { "name": "Non treated crepe paper", "code": "14122104" },
      { "name": "Latex treated crepe paper", "code": "14122105" },
      { "name": "Latex treated uncoated paper", "code": "14122106" },
      { "name": "Corrugated base paper", "code": "14122107" },
      { "name": "Specialty industrial use papers", "code": "14122200" },
      { "name": "Seed germinating papers", "code": "14122201" },
      { "name": "Tea bag paper", "code": "14122202" },
      { "name": "Transfer paper", "code": "14122203" },
      { "name": "Impressed stamp paper", "code": "14122204" }
    ]
  },
  {
    "name": "Fuels and Fuel Additives and Lubricants and Anti corrosive Materials",
    "code": "15000000",
    "subcategories": [
      { "name": "Fuels", "code": "15100000" },
      { "name": "Petroleum and distillates", "code": "15101500" },
      { "name": "Kerosene", "code": "15101502" },
      { "name": "Naphtha", "code": "15101503" },
      { "name": "Aviation fuel", "code": "15101504" },
      { "name": "Diesel fuel", "code": "15101505" },
      { "name": "Gasoline or Petrol", "code": "15101506" },
      { "name": "Benzene", "code": "15101507" },
      { "name": "Crude oil", "code": "15101508" },
      { "name": "Marine fuel", "code": "15101509" },
      { "name": "Condensate", "code": "15101510" },
      { "name": "Petroleum coke or pet coke", "code": "15101512" },
      { "name": "Diesel fuel off road", "code": "15101513" },
      { "name": "Solid and gel fuels", "code": "15101600" },
      { "name": "Sub bituminous or weak coal", "code": "15101601" },
      { "name": "Lignite", "code": "15101602" },
      { "name": "Peat", "code": "15101603" },
      { "name": "Coke", "code": "15101604" },
      { "name": "Charcoal", "code": "15101605" },
      { "name": "Jellied alcohol fuels", "code": "15101606" },
      { "name": "Hexamines", "code": "15101607" },
      { "name": "Trioxanes", "code": "15101608" },
      { "name": "Briquette", "code": "15101609" },
      { "name": "Coconut shell charcoal", "code": "15101610" },
      { "name": "Anthracite or hard coal", "code": "15101611" },
      { "name": "Metallurgical coal", "code": "15101612" },
      { "name": "Raw coal", "code": "15101613" },
      { "name": "Energy coal", "code": "15101614" },
      { "name": "Wood chip fuel", "code": "15101615" },
      { "name": "Fuel Oils", "code": "15101700" },
      { "name": "#2 Heating fuel oil", "code": "15101701" },
      { "name": "#4 or #6 Residual heavy fuel oils", "code": "15101702" },
      { "name": "No.5 Residual heavy fuel oil", "code": "15101703" },
      { "name": "Plant based liquid fuels or biofuels", "code": "15101800" },
      { "name": "Biodiesel", "code": "15101801" },
      { "name": "Ethanol", "code": "15101802" },
      { "name": "Gaseous fuels and additives", "code": "15110000" },
      { "name": "Gaseous fuels", "code": "15111500" },
      { "name": "Propane", "code": "15111501" },
      { "name": "Methane", "code": "15111502" },
      { "name": "Propylene", "code": "15111503" },
      { "name": "Ethylene", "code": "15111504" },
      { "name": "Butane", "code": "15111505" },
      { "name": "Acetylene", "code": "15111506" },
      { "name": "Water gas or producer gas", "code": "15111507" },
      { "name": "Coal gas", "code": "15111508" },
      { "name": "Methylacetylene propadiene MAPP gas", "code": "15111509" },
      { "name": "Liquified petroleum gas", "code": "15111510" },
      { "name": "Liquefied natural gas LNG", "code": "15111511" },
      { "name": "Compressed natural gas CNG", "code": "15111512" },
      { "name": "Fuel additives", "code": "15111700" },
      { "name": "Fuel thickeners", "code": "15111701" },
      { "name": "Icing inhibitors for fuel systems", "code": "15111702" },
      {
        "name": "Lubricants and oils and greases and anti corrosives",
        "code": "15120000"
      },
      { "name": "Lubricating preparations", "code": "15121500" },
      { "name": "Engine oil", "code": "15121501" },
      { "name": "Cutting oil", "code": "15121502" },
      { "name": "Gear oil", "code": "15121503" },
      { "name": "Hydraulic oil", "code": "15121504" },
      { "name": "Transformer oil or insulating oil", "code": "15121505" },
      { "name": "Transmission oil", "code": "15121508" },
      { "name": "Brake oil", "code": "15121509" },
      { "name": "Antigalling", "code": "15121510" },
      { "name": "Assembly pastes", "code": "15121511" },
      { "name": "Anti adhesives", "code": "15121512" },
      { "name": "Graphite lubricants", "code": "15121513" },
      { "name": "Spray lubricants", "code": "15121514" },
      { "name": "Anti seize or anti stain compounds", "code": "15121515" },
      { "name": "Leak stop", "code": "15121516" },
      { "name": "Lubricating soaps", "code": "15121517" },
      { "name": "Damping fluids", "code": "15121518" },
      { "name": "Watch lubricating oils", "code": "15121519" },
      { "name": "General purpose lubricants", "code": "15121520" },
      { "name": "Pump lubricating oils", "code": "15121521" },
      { "name": "Weapon lubricating oils", "code": "15121522" },
      { "name": "Lens preparation fluids", "code": "15121523" },
      { "name": "Tempering oils", "code": "15121524" },
      { "name": "Quenching oils", "code": "15121525" },
      {
        "name": "Lubricants for food processing equipment",
        "code": "15121526"
      },
      { "name": "Turbine oil", "code": "15121527" },
      { "name": "Fire resistant hydraulic fluid", "code": "15121528" },
      { "name": "Refrigerating machine oil", "code": "15121529" },
      { "name": "Heat transfer oil or fluid", "code": "15121530" },
      { "name": "Anti corrosives", "code": "15121800" },
      { "name": "Moisture repellent", "code": "15121801" },
      { "name": "Anti corrosion lubricant", "code": "15121802" },
      { "name": "Rust remover", "code": "15121803" },
      { "name": "Rust proofing preparation", "code": "15121804" },
      { "name": "Anti weld pastes", "code": "15121805" },
      { "name": "Penetrating oils", "code": "15121806" },
      { "name": "Antifreeze", "code": "15121807" },
      { "name": "Greases", "code": "15121900" },
      { "name": "Silicone grease", "code": "15121901" },
      { "name": "Grease", "code": "15121902" },
      { "name": "Fluoropolymer grease", "code": "15121903" },
      { "name": "Wool grease", "code": "15121904" },
      { "name": "Thermal grease", "code": "15121905" },
      { "name": "Fuel for nuclear reactors", "code": "15130000" },
      { "name": "Nuclear fuel", "code": "15131500" },
      { "name": "Depleted uranium", "code": "15131502" },
      { "name": "Enriched uranium", "code": "15131503" },
      { "name": "Iridium", "code": "15131504" },
      { "name": "Enriched plutonium", "code": "15131505" },
      { "name": "Depleted plutonium", "code": "15131506" },
      { "name": "Fission fuel assemblies", "code": "15131600" },
      { "name": "Nuclear fuel rod", "code": "15131601" },
      { "name": "Spent or irradiated nuclear fuel rod", "code": "15131602" }
    ]
  },
  {
    "name": "Mining and Well Drilling Machinery and Accessories",
    "code": "20000000",
    "subcategories": [
      {
        "name": "Mining and quarrying machinery and equipment",
        "code": "20100000"
      },
      { "name": "Cutting equipment", "code": "20101500" },
      { "name": "Continuous mining equipment", "code": "20101501" },
      { "name": "Longwall shears", "code": "20101502" },
      { "name": "Coal cutters", "code": "20101503" },
      { "name": "Rock cutters", "code": "20101504" },
      { "name": "Cutter chain for mining", "code": "20101505" },
      { "name": "Cutter bar", "code": "20101506" },
      { "name": "Screens and feeding equipment", "code": "20101600" },
      { "name": "Screens", "code": "20101601" },
      { "name": "Feeders", "code": "20101602" },
      { "name": "Drain hole screen", "code": "20101603" },
      { "name": "Gearmotors", "code": "20101617" },
      { "name": "Apron feeder", "code": "20101618" },
      { "name": "Weigh belt feeder", "code": "20101619" },
      { "name": "Electromagnetic vibro feeder", "code": "20101620" },
      { "name": "Electromechanical vibro feeder", "code": "20101621" },
      { "name": "Crushers and breakers and grinders", "code": "20101700" },
      { "name": "Rock crushers", "code": "20101701" },
      { "name": "Roll crushers", "code": "20101702" },
      { "name": "Cone crushers", "code": "20101703" },
      { "name": "Gyratory crushers", "code": "20101704" },
      { "name": "Impact crushers", "code": "20101705" },
      { "name": "Jaw crushers", "code": "20101706" },
      { "name": "Crushing plants", "code": "20101707" },
      { "name": "Rod mills", "code": "20101708" },
      { "name": "Ball mills", "code": "20101709" },
      { "name": "Pulverizing machinery", "code": "20101710" },
      { "name": "Rock breakers", "code": "20101711" },
      { "name": "Earth grinders", "code": "20101712" },
      { "name": "Cyclone or vortex grinders", "code": "20101713" },
      { "name": "Jaw plates", "code": "20101714" },
      { "name": "Crusher bit", "code": "20101715" },
      { "name": "Crusher hammer", "code": "20101716" },
      { "name": "Mechanized ground support systems", "code": "20101800" },
      { "name": "Cable bolters", "code": "20101801" },
      { "name": "Scissor bolters", "code": "20101802" },
      { "name": "Boom bolters", "code": "20101803" },
      { "name": "Shotcrete spraying equipment", "code": "20101804" },
      {
        "name": "Mechanized ground support system spare parts or accessories",
        "code": "20101805"
      },
      { "name": "Commutators", "code": "20101810" },
      { "name": "Secondary rock breaking systems", "code": "20101900" },
      { "name": "Blockholer or drill and load systems", "code": "20101901" },
      { "name": "Repetitive impact systems", "code": "20101902" },
      {
        "name": "Secondary rock breaking system spare parts or accessories",
        "code": "20101903"
      },
      { "name": "Exploration and development systems", "code": "20102000" },
      {
        "name": "In the hole drills ITH or down the hole DTH long hole drills",
        "code": "20102001"
      },
      { "name": "Top hammer long hole drills", "code": "20102002" },
      { "name": "Pneumatic shaft sinking jumbos", "code": "20102003" },
      { "name": "Hydraulic shaft sinking jumbos", "code": "20102004" },
      { "name": "Pneumatic horizontal development jumbos", "code": "20102005" },
      { "name": "Hydraulic horizontal development jumbos", "code": "20102006" },
      { "name": "Core drills", "code": "20102007" },
      {
        "name": "Exploration or development system spare parts or accessories",
        "code": "20102008"
      },
      { "name": "Rock drills", "code": "20102100" },
      { "name": "Pneumatic rock drills", "code": "20102101" },
      { "name": "Hydraulic rock drills", "code": "20102102" },
      { "name": "Hand held rock drills", "code": "20102103" },
      { "name": "Rock drill spare parts or accessories", "code": "20102104" },
      { "name": "Steel drill rod", "code": "20102105" },
      { "name": "Vacuum drill rod", "code": "20102106" },
      { "name": "Explosive loading machinery", "code": "20102200" },
      {
        "name": "Ammonium nitrate and fuel oil ANFO loading machinery",
        "code": "20102201"
      },
      { "name": "Emulsion loading machinery", "code": "20102202" },
      {
        "name": "Explosive loading machinery spare parts or accessories",
        "code": "20102203"
      },
      { "name": "Underground mining service vehicles", "code": "20102300" },
      { "name": "Personnel carriers", "code": "20102301" },
      { "name": "Crane vehicles", "code": "20102302" },
      { "name": "Flat deck material carriers", "code": "20102303" },
      { "name": "Bulk material carriers", "code": "20102304" },
      { "name": "Utility service vehicles", "code": "20102305" },
      {
        "name": "Elevating platform vehicles or scissor lifts",
        "code": "20102306"
      },
      {
        "name": "Underground mining service vehicle spare parts or accessories",
        "code": "20102307"
      },
      { "name": "Well drilling and operation equipment", "code": "20110000" },
      { "name": "Drilling and exploration equipment", "code": "20111500" },
      { "name": "Water well drilling equipment", "code": "20111504" },
      { "name": "Uranium exploration equipment", "code": "20111505" },
      { "name": "Drilling and operation machinery", "code": "20111600" },
      { "name": "Boring or sinking machinery", "code": "20111601" },
      { "name": "Downhole assembly machinery", "code": "20111602" },
      { "name": "Hammer drills", "code": "20111603" },
      { "name": "Crawler drills", "code": "20111604" },
      { "name": "Pneumatic vibrators", "code": "20111606" },
      { "name": "Tunneling machinery", "code": "20111607" },
      { "name": "Striking hammers", "code": "20111608" },
      { "name": "Sinker drills", "code": "20111609" },
      { "name": "Sewer inspection machinery", "code": "20111610" },
      { "name": "Rotary drills", "code": "20111611" },
      { "name": "Drilling rigs", "code": "20111612" },
      { "name": "Long hole drills", "code": "20111613" },
      { "name": "Industrial drill bits", "code": "20111614" },
      { "name": "Drifters", "code": "20111615" },
      { "name": "Derricks", "code": "20111616" },
      { "name": "Drilling carriages", "code": "20111617" },
      { "name": "Downhole fishing poles", "code": "20111618" },
      { "name": "Well drilling bit cones", "code": "20111619" },
      { "name": "Hammer grab", "code": "20111620" },
      { "name": "Casing oscillator", "code": "20111621" },
      { "name": "Reverse circulation drill", "code": "20111622" },
      { "name": "Diaphragm wall clamshell", "code": "20111623" },
      { "name": "Drilling and operation accessories", "code": "20111700" },
      {
        "name": "Audio visual instruments for well inspection",
        "code": "20111701"
      },
      { "name": "Packers or tubing anchors", "code": "20111702" },
      { "name": "Drilling casings", "code": "20111703" },
      { "name": "Drilling screens", "code": "20111704" },
      { "name": "Well points", "code": "20111705" },
      { "name": "Drilling wedges", "code": "20111706" },
      { "name": "Drilling tool adapters", "code": "20111707" },
      { "name": "Drill stems", "code": "20111708" },
      { "name": "Well drilling tool or accessory kits", "code": "20111709" },
      { "name": "Thru tubing packer repair kit", "code": "20111710" },
      { "name": "Thru tubing parts and accessories", "code": "20111711" },
      { "name": "Wash pipe", "code": "20111712" },
      { "name": "Drilling fingerboard", "code": "20111713" },
      { "name": "Coiled tubing connector", "code": "20111714" },
      { "name": "Well site pit liner", "code": "20111715" },
      {
        "name": "Oil and gas drilling and exploration equipment",
        "code": "20120000"
      },
      { "name": "Acidizing equipment", "code": "20121000" },
      { "name": "Acidizing blending units", "code": "20121001" },
      { "name": "Acidizing density sensors", "code": "20121002" },
      { "name": "Acidizing pumping units", "code": "20121003" },
      { "name": "Acidizing units", "code": "20121004" },
      { "name": "Acidizing air piping", "code": "20121005" },
      { "name": "Acidizing ball injectors", "code": "20121006" },
      { "name": "Bulk liquid acid equipment", "code": "20121007" },
      { "name": "Acidizing drop boxes", "code": "20121008" },
      { "name": "Acidizing flow meters", "code": "20121009" },
      { "name": "Acidizing junction boxes", "code": "20121010" },
      { "name": "Acidizing pressure sensors", "code": "20121011" },
      { "name": "Acidizing process piping", "code": "20121012" },
      { "name": "Acidizing straight joints", "code": "20121013" },
      { "name": "Acidizing swivels", "code": "20121014" },
      { "name": "Acidizing treating irons", "code": "20121015" },
      { "name": "Acidizing tree savers", "code": "20121016" },
      { "name": "Cementing equipment", "code": "20121100" },
      { "name": "Blending units", "code": "20121101" },
      { "name": "Bridge plugs", "code": "20121102" },
      { "name": "Bulk liquid cement equipment", "code": "20121103" },
      { "name": "Cement bulk material equipment", "code": "20121104" },
      { "name": "Cement density sensors", "code": "20121105" },
      { "name": "Cement floating bulk units", "code": "20121106" },
      { "name": "Cement floating equipment stage tools", "code": "20121107" },
      { "name": "Cement floating equipment wiper plugs", "code": "20121108" },
      { "name": "Cement pumping units", "code": "20121109" },
      { "name": "Cement retainers", "code": "20121110" },
      { "name": "Centralizers", "code": "20121111" },
      { "name": "Express latch couplers", "code": "20121112" },
      { "name": "Float collars", "code": "20121113" },
      { "name": "Float shoes", "code": "20121114" },
      { "name": "Oilfield cementing tools", "code": "20121115" },
      { "name": "Retrievable cementing packers", "code": "20121116" },
      { "name": "Subsea cement heads", "code": "20121118" },
      { "name": "Surface cement heads", "code": "20121119" },
      { "name": "Bow spring centralizer", "code": "20121120" },
      { "name": "Blade centralizer", "code": "20121121" },
      { "name": "Bow spring centralizer sub", "code": "20121122" },
      { "name": "Cementing stage tool kit", "code": "20121123" },
      { "name": "Cementing float equipment kit", "code": "20121124" },
      { "name": "Cement retainer kit", "code": "20121125" },
      { "name": "Centralizer parts and accessories", "code": "20121126" },
      { "name": "Landing collar", "code": "20121127" },
      { "name": "Torque and drag reduction tool", "code": "20121128" },
      {
        "name": "Torque and drag reduction tool parts and accessories",
        "code": "20121129"
      },
      { "name": "Bridge plug parts and accessories", "code": "20121130" },
      { "name": "Fracturing equipment", "code": "20121200" },
      { "name": "Bulk liquid fracturing equipment", "code": "20121201" },
      { "name": "Fracturing bulk proppant equipment", "code": "20121202" },
      { "name": "Fracturing control units", "code": "20121203" },
      { "name": "Fracturing density sensors", "code": "20121204" },
      { "name": "Fracturing manifold units", "code": "20121205" },
      { "name": "Fracturing proppant conveying equipment", "code": "20121206" },
      { "name": "Fracturing pumping units", "code": "20121207" },
      { "name": "Fracturing slurry blending units", "code": "20121208" },
      { "name": "Gel blending units", "code": "20121209" },
      { "name": "Fracturing missiles", "code": "20121210" },
      { "name": "Pump integrity monitors", "code": "20121211" },
      { "name": "Fracturing service packers", "code": "20121212" },
      { "name": "Stimulation pumping units", "code": "20121213" },
      { "name": "Sand control equipment", "code": "20121300" },
      { "name": "Blanking plugs", "code": "20121301" },
      { "name": "Floaters", "code": "20121302" },
      { "name": "Frac pack systems", "code": "20121303" },
      { "name": "Gravel pack systems", "code": "20121304" },
      { "name": "Guide shoes", "code": "20121305" },
      { "name": "Hook up nipples", "code": "20121306" },
      { "name": "Make up subs", "code": "20121307" },
      { "name": "Production tubing overshots", "code": "20121308" },
      { "name": "Sand control blanks", "code": "20121309" },
      { "name": "Sand control bulk liquid equipment", "code": "20121310" },
      { "name": "Sand control bulk proppant equipment", "code": "20121311" },
      { "name": "Sand control density sensors", "code": "20121312" },
      { "name": "Sand control manifold units", "code": "20121313" },
      {
        "name": "Sand control proppant conveying equipment",
        "code": "20121314"
      },
      { "name": "Sand control pumping units", "code": "20121315" },
      { "name": "Sand control screens", "code": "20121316" },
      { "name": "Sand control slurry blending units", "code": "20121317" },
      { "name": "Sand detectors", "code": "20121318" },
      { "name": "Seal assembly locators", "code": "20121319" },
      { "name": "Shear joints", "code": "20121320" },
      { "name": "Sleeve shifting tools", "code": "20121321" },
      { "name": "Sliding sleeves", "code": "20121322" },
      { "name": "Velocity strings", "code": "20121323" },
      { "name": "Sand control ring", "code": "20121324" },
      { "name": "Slotted pipe pattern", "code": "20121325" },
      {
        "name": "Sand control screen parts and accessories",
        "code": "20121326"
      },
      { "name": "Completion tools and equipment", "code": "20121400" },
      { "name": "Ball catcher subs", "code": "20121401" },
      { "name": "Blast joints", "code": "20121402" },
      { "name": "Blast nipples", "code": "20121403" },
      { "name": "Completion bull plugs", "code": "20121404" },
      { "name": "Circulation production devices", "code": "20121405" },
      { "name": "Completion test equipment", "code": "20121406" },
      { "name": "Control line protectors", "code": "20121407" },
      { "name": "Deflection tools", "code": "20121408" },
      { "name": "Completion expansion joints", "code": "20121409" },
      { "name": "Flow couplings", "code": "20121410" },
      { "name": "Gas lift equipment", "code": "20121411" },
      { "name": "Hanger landing tools", "code": "20121412" },
      { "name": "Completion hydraulic pumps", "code": "20121413" },
      { "name": "Hydraulic setting tools", "code": "20121414" },
      { "name": "Injection systems", "code": "20121415" },
      { "name": "Landing nipples", "code": "20121416" },
      { "name": "Liner hangers", "code": "20121417" },
      { "name": "Packer pulling tools", "code": "20121418" },
      { "name": "Packer running tools", "code": "20121419" },
      { "name": "Production packers", "code": "20121420" },
      { "name": "Pump down through flow line equipment", "code": "20121421" },
      { "name": "Completion safety joints", "code": "20121422" },
      { "name": "Completion seal assemblies", "code": "20121423" },
      { "name": "Seal bores or polished bores", "code": "20121424" },
      { "name": "Side pocket mandrels", "code": "20121425" },
      { "name": "Subsurface safety valves", "code": "20121427" },
      { "name": "Travel joints", "code": "20121428" },
      { "name": "Tubing anchors", "code": "20121429" },
      { "name": "Twin flow assemblies", "code": "20121430" },
      { "name": "Inflatable packer", "code": "20121431" },
      {
        "name": "Downhole control valve parts and accessories",
        "code": "20121432"
      },
      { "name": "Cased hole completion repair kit", "code": "20121433" },
      { "name": "Sliding sleeve repair kit", "code": "20121434" },
      { "name": "Setting adapter kit", "code": "20121435" },
      {
        "name": "Plunger lift system parts and accessories",
        "code": "20121436"
      },
      {
        "name": "Subsurface safety valve parts and accessories",
        "code": "20121437"
      },
      { "name": "Gas lift valve parts and accessories", "code": "20121438" },
      { "name": "Production packer mandrel", "code": "20121439" },
      { "name": "Annulus casing packer sub", "code": "20121440" },
      { "name": "Liner setting tool", "code": "20121441" },
      { "name": "Drag block", "code": "20121442" },
      { "name": "Liner packer setting tool kit", "code": "20121443" },
      { "name": "Liner system repair kit", "code": "20121444" },
      { "name": "Production packer parts and accessories", "code": "20121445" },
      { "name": "Liner top packer", "code": "20121446" },
      { "name": "Liner setting collar", "code": "20121447" },
      { "name": "Junk bonnet", "code": "20121448" },
      { "name": "Plunger lift equipment", "code": "20121449" },
      { "name": "Gas lift mandrel", "code": "20121450" },
      { "name": "Gas lift valve", "code": "20121451" },
      { "name": "Conventional drilling tools", "code": "20121500" },
      { "name": "Blowout preventers", "code": "20121501" },
      { "name": "Blowout preventer controls", "code": "20121502" },
      { "name": "Casing scrapers", "code": "20121503" },
      { "name": "Drill collars", "code": "20121504" },
      { "name": "Coring equipment", "code": "20121505" },
      { "name": "Drill pipe thread protectors", "code": "20121506" },
      { "name": "Drill pipe tool joints", "code": "20121507" },
      { "name": "Drill pipe", "code": "20121508" },
      { "name": "Gauge rings", "code": "20121509" },
      { "name": "Hole openers", "code": "20121510" },
      { "name": "Hole reamers", "code": "20121511" },
      { "name": "Downhole shock absorbers", "code": "20121513" },
      { "name": "Downhole stabilizers", "code": "20121514" },
      { "name": "Drilling subs", "code": "20121515" },
      { "name": "Thrusters", "code": "20121516" },
      {
        "name": "Wellbore hole reamer parts and accessories",
        "code": "20121517"
      },
      { "name": "Rotating control head", "code": "20121518" },
      {
        "name": "Rotating control head parts and accessories",
        "code": "20121519"
      },
      { "name": "Blowout preventer parts and accessories", "code": "20121520" },
      {
        "name": "Downhole shock absorber parts and accessories",
        "code": "20121521"
      },
      { "name": "Casing scraper parts and accessories", "code": "20121522" },
      { "name": "Drilling jar", "code": "20121523" },
      { "name": "Drilling jar parts and accessories", "code": "20121524" },
      { "name": "Drilling bits", "code": "20121600" },
      { "name": "Fixed cutter drill bits", "code": "20121601" },
      { "name": "Natural diamond drill bits", "code": "20121602" },
      { "name": "Nozzle drill bits", "code": "20121603" },
      { "name": "PDC bits", "code": "20121604" },
      { "name": "Roller cone button insert drill bits", "code": "20121605" },
      { "name": "Roller steel tooth drill bits", "code": "20121606" },
      { "name": "Core bits", "code": "20121607" },
      { "name": "Bit block", "code": "20121608" },
      { "name": "Continuous mining bit", "code": "20121609" },
      { "name": "Feeder bit", "code": "20121610" },
      { "name": "Longwall bit", "code": "20121611" },
      { "name": "Roof drill bit", "code": "20121612" },
      { "name": "Drill bit accessories", "code": "20121613" },
      { "name": "Fishing tools", "code": "20121700" },
      { "name": "Bumper subs", "code": "20121701" },
      { "name": "Casing patches", "code": "20121702" },
      { "name": "Jar boosters", "code": "20121703" },
      { "name": "Junk subs", "code": "20121704" },
      { "name": "Mills or burning shoes", "code": "20121705" },
      { "name": "Overshots", "code": "20121706" },
      { "name": "Oilfield fishing spears", "code": "20121707" },
      { "name": "Unspecified fishing tools", "code": "20121708" },
      { "name": "Fishing wash pipe and extension", "code": "20121709" },
      { "name": "Overshot extension", "code": "20121710" },
      { "name": "Fishing sub", "code": "20121711" },
      { "name": "Casing patch parts and accessories", "code": "20121712" },
      { "name": "Wash pipe drive bushing", "code": "20121713" },
      { "name": "Fishing impression block", "code": "20121714" },
      { "name": "Wireline system fishing kit", "code": "20121715" },
      { "name": "Junk basket parts and accessories", "code": "20121716" },
      { "name": "Overshot grapple", "code": "20121717" },
      { "name": "Overshot control", "code": "20121718" },
      { "name": "Overshot packer", "code": "20121719" },
      { "name": "Overshot parts and accessories", "code": "20121720" },
      { "name": "Fishing magnet", "code": "20121721" },
      { "name": "Taper tap", "code": "20121722" },
      { "name": "Fishing spear grapple", "code": "20121723" },
      { "name": "Fishing spear parts and accessories", "code": "20121724" },
      { "name": "Casing cutter", "code": "20121725" },
      { "name": "Box tap", "code": "20121726" },
      { "name": "Junk basket", "code": "20121727" },
      { "name": "Fishing jar", "code": "20121728" },
      { "name": "Directional drilling equipment", "code": "20121800" },
      { "name": "Geosteering tools", "code": "20121801" },
      { "name": "Mud motors", "code": "20121802" },
      { "name": "Rotary steerable tools", "code": "20121803" },
      {
        "name": "Directional drilling surface control systems",
        "code": "20121804"
      },
      {
        "name": "Straight hole directional drilling tools",
        "code": "20121805"
      },
      { "name": "Logging while drilling tools LWD", "code": "20121806" },
      {
        "name": "Logging while drilling tools LWD parts and accessories",
        "code": "20121807"
      },
      { "name": "Directional drilling stabilizer", "code": "20121808" },
      {
        "name": "Rotary steerable tools parts and accessories",
        "code": "20121809"
      },
      { "name": "Directional drilling sub", "code": "20121810" },
      { "name": "Directional drilling thruster", "code": "20121811" },
      { "name": "Directional drilling drill collar", "code": "20121812" },
      { "name": "Mud motor parts and accessories", "code": "20121813" },
      { "name": "Well measurement and logging equipment", "code": "20121900" },
      { "name": "Acoustic tools", "code": "20121901" },
      { "name": "Drilling or mud control instruments", "code": "20121902" },
      { "name": "Drilling performance measurement tools", "code": "20121903" },
      { "name": "Flow measurement equipment", "code": "20121904" },
      { "name": "Nuclear magnetic resonance tools", "code": "20121905" },
      { "name": "Nuclear tools", "code": "20121906" },
      { "name": "Production logging equipment", "code": "20121907" },
      { "name": "Resistivity tools", "code": "20121908" },
      { "name": "Surveying systems", "code": "20121909" },
      { "name": "Telemetry systems", "code": "20121910" },
      { "name": "Ultrasonic tools", "code": "20121911" },
      {
        "name": "Well logging bottom hole pressure equipment",
        "code": "20121912"
      },
      { "name": "Well logging downhole test equipment", "code": "20121913" },
      { "name": "Well logging units", "code": "20121914" },
      { "name": "Bulk density log", "code": "20121915" },
      { "name": "Optical sensing downhole cable", "code": "20121916" },
      { "name": "Optical sensing mandrel and accessories", "code": "20121917" },
      { "name": "Optical sensing surface cable", "code": "20121918" },
      { "name": "Casing inspection tool and accessories", "code": "20121919" },
      {
        "name": "Freepoint indicator tool and accessories",
        "code": "20121920"
      },
      { "name": "Radiation survey meter", "code": "20121921" },
      { "name": "Gamma ray tool parts and accessories", "code": "20121922" },
      { "name": "Well imaging tool and parts", "code": "20121923" },
      { "name": "Fixturing and test equipment", "code": "20122000" },
      { "name": "Drift bars", "code": "20122001" },
      { "name": "Drift sleeves", "code": "20122002" },
      { "name": "Drift rabbits", "code": "20122003" },
      { "name": "Test fixtures", "code": "20122004" },
      { "name": "Test nipples", "code": "20122005" },
      { "name": "Test plugs", "code": "20122006" },
      { "name": "Perforating equipment", "code": "20122100" },
      { "name": "Capsule guns", "code": "20122101" },
      { "name": "Casing guns", "code": "20122102" },
      { "name": "Deployment heads", "code": "20122103" },
      { "name": "Perforating explosives", "code": "20122104" },
      { "name": "Firing heads", "code": "20122105" },
      { "name": "Gun adapters", "code": "20122106" },
      { "name": "High shot density guns", "code": "20122107" },
      { "name": "Perforating bull plugs", "code": "20122108" },
      { "name": "Plug setting tools", "code": "20122109" },
      { "name": "Perforating positioning equipment", "code": "20122110" },
      { "name": "Scalloped guns", "code": "20122111" },
      { "name": "Tandem subs", "code": "20122112" },
      {
        "name": "Through tubing perforation gun accessories",
        "code": "20122113"
      },
      { "name": "Through tubing perforation guns", "code": "20122114" },
      { "name": "Under balance vent subs", "code": "20122115" },
      { "name": "Well testing equipment", "code": "20122200" },
      { "name": "Flare booms", "code": "20122201" },
      { "name": "Flare burners", "code": "20122202" },
      { "name": "Cased hole test tools", "code": "20122203" },
      { "name": "Choke manifolds", "code": "20122204" },
      { "name": "Diverting manifolds", "code": "20122205" },
      { "name": "Flowhead baskets", "code": "20122206" },
      { "name": "Flowhead swivels", "code": "20122207" },
      { "name": "Flowheads", "code": "20122208" },
      { "name": "Formation shut in tools", "code": "20122209" },
      { "name": "Gas flares", "code": "20122210" },
      { "name": "Mud gas analyzers", "code": "20122211" },
      { "name": "Oil samplers", "code": "20122212" },
      { "name": "Well testing separators", "code": "20122213" },
      { "name": "Well testing surface piping", "code": "20122214" },
      { "name": "Surge tanks", "code": "20122215" },
      { "name": "Well testing downhole tools", "code": "20122216" },
      { "name": "Slickline equipment", "code": "20122300" },
      { "name": "Slickline adapter heads", "code": "20122301" },
      { "name": "Slickline backoffs", "code": "20122302" },
      { "name": "Slickline bell guides", "code": "20122303" },
      { "name": "Slickline blind boxes", "code": "20122304" },
      {
        "name": "Slickline bottom hole pressure equipment",
        "code": "20122305"
      },
      { "name": "Slickline caliper tools", "code": "20122306" },
      { "name": "Slickline cement dump bailing equipment", "code": "20122307" },
      { "name": "Slickline chemical cutters", "code": "20122308" },
      {
        "name": "Slickline clamp on tool string centralizers",
        "code": "20122309"
      },
      {
        "name": "Slickline clamp on wireline centralizers",
        "code": "20122310"
      },
      { "name": "Slickline collar locators", "code": "20122311" },
      { "name": "Slickline collectors", "code": "20122312" },
      { "name": "Slickline colliding tools", "code": "20122313" },
      { "name": "Slickline crossovers", "code": "20122314" },
      { "name": "Slickline depth measurement equipment", "code": "20122315" },
      { "name": "Slickline dewar flasks", "code": "20122316" },
      { "name": "Slickline dipmeter tools", "code": "20122317" },
      { "name": "Slickline directional tools", "code": "20122318" },
      { "name": "Slickline go devil tools", "code": "20122319" },
      { "name": "Slickline hole punchers", "code": "20122320" },
      { "name": "Slickline jet cutters", "code": "20122321" },
      { "name": "Slickline junk shots", "code": "20122322" },
      { "name": "Slickline kickover tools", "code": "20122323" },
      { "name": "Slickline knuckle joints", "code": "20122324" },
      { "name": "Slickline lead impression blocks", "code": "20122325" },
      { "name": "Slickline locator mandrels", "code": "20122326" },
      { "name": "Slickline lock mandrels", "code": "20122327" },
      { "name": "Slickline lubricators", "code": "20122328" },
      { "name": "Slickline mechanical bailers", "code": "20122329" },
      { "name": "Slickline mechanical plugbacks", "code": "20122330" },
      { "name": "Other Slickline Tools", "code": "20122331" },
      { "name": "Slickline paraffin scrappers", "code": "20122332" },
      { "name": "Slickline rope sockets", "code": "20122333" },
      { "name": "Slickline running or pulling prongs", "code": "20122334" },
      { "name": "Slickline severing tools", "code": "20122335" },
      { "name": "Slickline sheaves or floor blocks", "code": "20122336" },
      { "name": "Slickline pulling tool accessories", "code": "20122338" },
      { "name": "Slickline pulling tools", "code": "20122339" },
      { "name": "Slickline running tools", "code": "20122340" },
      { "name": "Slickline units", "code": "20122341" },
      { "name": "Slickline wire", "code": "20122342" },
      { "name": "Slickline sonic tools", "code": "20122343" },
      { "name": "Slickline spacer bars", "code": "20122344" },
      { "name": "Slickline swages", "code": "20122345" },
      { "name": "Slickline tension devices", "code": "20122346" },
      { "name": "Slickline tubing plugs", "code": "20122347" },
      { "name": "Slickline ultrasonic tools", "code": "20122348" },
      { "name": "Wireline grabs", "code": "20122349" },
      { "name": "Wireline jars", "code": "20122350" },
      { "name": "Wireline scrapers", "code": "20122351" },
      { "name": "Wireline spear", "code": "20122352" },
      { "name": "Wireline stems", "code": "20122353" },
      { "name": "Wireline valves", "code": "20122354" },
      { "name": "Wireline preventers", "code": "20122356" },
      { "name": "Wireline jar accelerators", "code": "20122357" },
      { "name": "Test dart", "code": "20122358" },
      {
        "name": "Slickline running tool parts and accessories",
        "code": "20122359"
      },
      { "name": "Wireline broach", "code": "20122360" },
      { "name": "Standing valve", "code": "20122361" },
      { "name": "Wireline bell guide", "code": "20122362" },
      { "name": "Wire finder", "code": "20122363" },
      { "name": "Wireline tool string", "code": "20122364" },
      {
        "name": "Slickline centralizer parts and accessories",
        "code": "20122365"
      },
      { "name": "Magnetic decentralizer", "code": "20122366" },
      { "name": "Wireline pulling tool", "code": "20122367" },
      { "name": "Wireline mandrel", "code": "20122368" },
      { "name": "Wireline setting tool", "code": "20122369" },
      { "name": "Wireline crossover", "code": "20122370" },
      { "name": "Slickline centralizer", "code": "20122371" },
      { "name": "Wireline swivel joint", "code": "20122372" },
      {
        "name": "Slickline kickover tool parts and accessories",
        "code": "20122373"
      },
      { "name": "Production systems equipment", "code": "20122400" },
      { "name": "Cable thumpers", "code": "20122401" },
      { "name": "Oilfield production evaporators", "code": "20122402" },
      { "name": "Hipot testers", "code": "20122403" },
      { "name": "Oilfield lapping machines", "code": "20122404" },
      { "name": "Motor end lifts", "code": "20122405" },
      { "name": "Oil dielectric testers", "code": "20122406" },
      { "name": "Oil vacuum filling units", "code": "20122407" },
      { "name": "Oilfield production shaft straighteners", "code": "20122408" },
      { "name": "Oilfield production spoolers", "code": "20122409" },
      { "name": "Vibration analyzers", "code": "20122410" },
      { "name": "Coiled tubing equipment", "code": "20122500" },
      { "name": "Blaster tools", "code": "20122501" },
      { "name": "Coiled tubing truck crane units", "code": "20122502" },
      { "name": "Coiled tubing units", "code": "20122503" },
      { "name": "Coiled tubing hose packages", "code": "20122504" },
      { "name": "Coiled tubing inflatable systems", "code": "20122505" },
      { "name": "Coiled tubing injector heads", "code": "20122506" },
      { "name": "Coiled tubing lifting equipment", "code": "20122507" },
      { "name": "Operator houses", "code": "20122508" },
      { "name": "Coiled tubing power packs", "code": "20122509" },
      { "name": "Coiled tubing reels", "code": "20122510" },
      { "name": "Coiled tubing spooling reels", "code": "20122511" },
      { "name": "Tubing guides", "code": "20122512" },
      { "name": "Wellhead hookups", "code": "20122513" },
      { "name": "Wellhead support structures", "code": "20122514" },
      { "name": "Oilfield coiled tubing", "code": "20122515" },
      { "name": "Coiled tubing tool string", "code": "20122516" },
      { "name": "Coiled tubing centralizer", "code": "20122518" },
      { "name": "Seismic equipment", "code": "20122600" },
      { "name": "Seismic analog sensors", "code": "20122601" },
      { "name": "Seismic arrays", "code": "20122602" },
      { "name": "Seismic streamer cable birds", "code": "20122603" },
      { "name": "Seismic drill tankers", "code": "20122604" },
      { "name": "Seismic geophones", "code": "20122605" },
      { "name": "Seismic gravity systems", "code": "20122606" },
      { "name": "Seismic gun winch systems", "code": "20122607" },
      { "name": "Seismic hydrophones", "code": "20122608" },
      { "name": "Seismic impulse sources", "code": "20122609" },
      { "name": "Seismic marine streamer cables", "code": "20122610" },
      { "name": "Seismic ocean bottom cables", "code": "20122611" },
      { "name": "Seismic magnetic systems", "code": "20122612" },
      { "name": "Seismic positioning equipment", "code": "20122613" },
      { "name": "Seismic rams", "code": "20122614" },
      { "name": "Seismic receivers", "code": "20122615" },
      { "name": "Seismic refraction systems", "code": "20122616" },
      { "name": "Seismic source controllers", "code": "20122617" },
      { "name": "Seismic spooling devices", "code": "20122618" },
      { "name": "Seismic tow blocks", "code": "20122619" },
      { "name": "Seismic tow points", "code": "20122620" },
      { "name": "Seismic vibrators", "code": "20122621" },
      { "name": "Seismic recording systems", "code": "20122622" },
      { "name": "Seismic data processing systems", "code": "20122623" },
      { "name": "Oil country tubular goods", "code": "20122700" },
      { "name": "Oil country casing", "code": "20122701" },
      { "name": "Oil country couplings", "code": "20122702" },
      { "name": "Oil country pup joints", "code": "20122703" },
      { "name": "Oil country tubing", "code": "20122704" },
      { "name": "Oil country pipe coatings", "code": "20122705" },
      { "name": "Conductor casing", "code": "20122706" },
      { "name": "Conductor casing running equipment", "code": "20122707" },
      { "name": "Drill pipe crossovers", "code": "20122708" },
      { "name": "Oil country thread protectors", "code": "20122709" },
      { "name": "Casing stop device", "code": "20122710" },
      {
        "name": "Drilling and workover rigs and equipment",
        "code": "20122800"
      },
      { "name": "Mud agitators", "code": "20122801" },
      { "name": "Mud tanks", "code": "20122802" },
      { "name": "Air drilling equipment", "code": "20122803" },
      { "name": "Barge rigs", "code": "20122804" },
      { "name": "Fluid diverters", "code": "20122806" },
      { "name": "Drawworks", "code": "20122807" },
      { "name": "Drill floor equipment", "code": "20122808" },
      { "name": "Drill swivels", "code": "20122809" },
      { "name": "Drilling rig ships", "code": "20122810" },
      { "name": "Drill rig elevators", "code": "20122811" },
      { "name": "Drill rig bails", "code": "20122812" },
      { "name": "Hydraulic workover units", "code": "20122813" },
      { "name": "Drill rig jacking systems", "code": "20122814" },
      { "name": "Jackup marine drilling rigs", "code": "20122815" },
      { "name": "Kelly bushings", "code": "20122816" },
      { "name": "Kelly valves", "code": "20122817" },
      { "name": "Kelly wipers", "code": "20122818" },
      { "name": "Kellys", "code": "20122819" },
      { "name": "Land drilling rigs", "code": "20122820" },
      { "name": "Mud cleaning equipment", "code": "20122821" },
      { "name": "Mud manifolds", "code": "20122822" },
      { "name": "Mud mixers", "code": "20122823" },
      { "name": "Pipe handling equipment", "code": "20122824" },
      { "name": "Platform drilling rigs", "code": "20122825" },
      { "name": "Power swivel or top drives", "code": "20122826" },
      { "name": "Rig skids", "code": "20122827" },
      { "name": "Drill rig risers", "code": "20122828" },
      { "name": "Drill rig rotary tables", "code": "20122829" },
      { "name": "Self elevating workover platforms", "code": "20122830" },
      { "name": "Semi submersible drilling rigs", "code": "20122831" },
      { "name": "Drill floor slips", "code": "20122832" },
      { "name": "Makeup tongs", "code": "20122833" },
      { "name": "Iron roughnecks", "code": "20122834" },
      { "name": "Traveling equipment", "code": "20122835" },
      { "name": "Workover boats", "code": "20122836" },
      { "name": "Workover rigs", "code": "20122837" },
      { "name": "Shale shakers", "code": "20122838" },
      { "name": "Mud degassers", "code": "20122839" },
      { "name": "Crown blocks", "code": "20122840" },
      { "name": "Traveling blocks", "code": "20122841" },
      { "name": "Mud desanders", "code": "20122842" },
      { "name": "Mud dessilters", "code": "20122843" },
      { "name": "Power tongs", "code": "20122844" },
      {
        "name": "Pipe handling equipment parts and accessories",
        "code": "20122845"
      },
      { "name": "Stabbing board", "code": "20122846" },
      { "name": "Lift sub and plug", "code": "20122847" },
      {
        "name": "Horizontal makeup device or bucking unit parts and accessories",
        "code": "20122848"
      },
      { "name": "Well casing spider", "code": "20122849" },
      { "name": "Hydraulic power unit", "code": "20122851" },
      { "name": "Surface data logging equipment", "code": "20122900" },
      { "name": "Surface data logging conduits", "code": "20122901" },
      { "name": "Surface data logging sensors", "code": "20122902" },
      { "name": "Surface data logging units", "code": "20122903" },
      { "name": "Multilateral equipment", "code": "20123000" },
      { "name": "Multilateral casing", "code": "20123001" },
      { "name": "Multilateral junctions", "code": "20123002" },
      { "name": "Multilateral packers", "code": "20123003" },
      {
        "name": "Multilateral packer parts and accessories",
        "code": "20123004"
      },
      { "name": "Casing exit tools", "code": "20123100" },
      { "name": "Casing exit setting tool", "code": "20123101" },
      { "name": "Casing exit whipstock", "code": "20123102" },
      { "name": "Expandable downhole equipment", "code": "20123200" },
      { "name": "Slotted expandable setting tool", "code": "20123201" },
      {
        "name": "Expandable sand screen hanger repair kit",
        "code": "20123202"
      },
      { "name": "Expandable liner", "code": "20123203" },
      { "name": "Weldable liner", "code": "20123204" },
      { "name": "Casing while drilling tools", "code": "20123300" },
      { "name": "Drill shoe", "code": "20123301" },
      { "name": "Drill shoe parts and accessories", "code": "20123302" },
      { "name": "Drilling spear", "code": "20123303" },
      { "name": "Drilling spear parts and accessories", "code": "20123304" },
      {
        "name": "Oil and gas drilling and operation materials",
        "code": "20130000"
      },
      { "name": "Drilling mud and materials", "code": "20131000" },
      { "name": "Filtration control agents", "code": "20131001" },
      { "name": "Fluid spacers", "code": "20131002" },
      { "name": "Lost circulation agents", "code": "20131003" },
      { "name": "Oil based muds", "code": "20131004" },
      { "name": "Rate of penetration enhancers", "code": "20131005" },
      { "name": "Spotting fluids", "code": "20131006" },
      { "name": "Synthetic based muds", "code": "20131007" },
      { "name": "Mud thinning agents", "code": "20131008" },
      { "name": "Water based muds", "code": "20131009" },
      { "name": "Mud weighting agents", "code": "20131010" },
      { "name": "Well fracturing proppants", "code": "20131100" },
      { "name": "Ceramic proppants", "code": "20131101" },
      { "name": "Fracturing sands", "code": "20131102" },
      { "name": "Resin coated ceramic proppants", "code": "20131103" },
      { "name": "Resin coated fracturing sands", "code": "20131104" },
      { "name": "Resin coated sintered bauxites", "code": "20131105" },
      { "name": "Sintered bauxites", "code": "20131106" },
      { "name": "Completion fluids", "code": "20131200" },
      { "name": "Divalent brines", "code": "20131201" },
      { "name": "Monovalent brines", "code": "20131202" },
      { "name": "Oil well cement", "code": "20131300" },
      { "name": "Oil well bulk cement", "code": "20131301" },
      { "name": "Oil well class a type I cement", "code": "20131302" },
      { "name": "Oil well class b type II cement", "code": "20131303" },
      { "name": "Oil well class c cement", "code": "20131304" },
      { "name": "Oil well class g cement", "code": "20131305" },
      { "name": "Oil well class h cement", "code": "20131306" },
      { "name": "Oil well lightweight cement", "code": "20131307" },
      { "name": "Oil well standard fine type III cement", "code": "20131308" },
      {
        "name": "Oil and gas operating and production equipment",
        "code": "20140000"
      },
      { "name": "Wellhead equipment", "code": "20141000" },
      { "name": "Wellhead actuators", "code": "20141001" },
      { "name": "Wellhead beam pumps", "code": "20141002" },
      { "name": "Wellhead flow lines", "code": "20141003" },
      { "name": "Wellhead gate valves", "code": "20141004" },
      { "name": "Wellhead production chokes", "code": "20141005" },
      {
        "name": "Wellhead sub surface flow or christmas trees",
        "code": "20141006"
      },
      {
        "name": "Wellhead surface flow or christmas trees",
        "code": "20141007"
      },
      { "name": "Wellhead surface safety valves", "code": "20141008" },
      { "name": "Tubing head adapter", "code": "20141011" },
      { "name": "Casing head housing", "code": "20141012" },
      { "name": "Tubing head spool", "code": "20141013" },
      { "name": "Casing head spool", "code": "20141014" },
      { "name": "Wellhead tees or crosses", "code": "20141015" },
      { "name": "Wellhead landing base", "code": "20141016" },
      { "name": "Wellhead carrier body", "code": "20141017" },
      { "name": "Wellhead hanger", "code": "20141018" },
      { "name": "Chemical injection systems", "code": "20141100" },
      { "name": "Paraffin injection systems", "code": "20141101" },
      { "name": "Desanding equipment", "code": "20141200" },
      { "name": "Production desanding equipment", "code": "20141201" },
      { "name": "Downhole jet pumps and anchors", "code": "20141300" },
      { "name": "Downhole jet pumps", "code": "20141301" },
      { "name": "Downhole jet pump parts and accessories", "code": "20141302" },
      { "name": "Drill tubing", "code": "20141303" },
      { "name": "Downhole production accessories", "code": "20141400" },
      { "name": "Tubing stops", "code": "20141401" },
      { "name": "Downhole pumps", "code": "20141500" },
      { "name": "Electric downhole pumps", "code": "20141501" },
      { "name": "Downhole progressive cavity pump", "code": "20141502" },
      { "name": "Export pumps", "code": "20141600" },
      { "name": "Pneumatic export pumps", "code": "20141601" },
      {
        "name": "Offshore production and storage platforms",
        "code": "20141700"
      },
      { "name": "Fixed offshore production platforms", "code": "20141701" },
      { "name": "Floating offshore production platforms", "code": "20141702" },
      { "name": "Floating offshore storage platforms", "code": "20141703" },
      {
        "name": "Floating offshore tension leg production platforms",
        "code": "20141704"
      },
      {
        "name": "Floating offshore tension leg storage platforms",
        "code": "20141705"
      },
      { "name": "Well production flow measurement meters", "code": "20141800" },
      { "name": "Well production gas turbine meters", "code": "20141801" },
      { "name": "Gas treating equipment", "code": "20141900" },
      {
        "name": "Oil well production gas treating equipment",
        "code": "20141901"
      },
      { "name": "Glycol regenerators", "code": "20142000" },
      { "name": "Oil well glycol regenerators", "code": "20142001" },
      { "name": "Heater treaters", "code": "20142100" },
      { "name": "Oil well heater treaters", "code": "20142101" },
      { "name": "Line heaters", "code": "20142200" },
      { "name": "Electrical line heaters", "code": "20142201" },
      { "name": "Production injection skids", "code": "20142300" },
      { "name": "Pneumatic methanol injections skids", "code": "20142301" },
      { "name": "Ocean floor equipment", "code": "20142400" },
      { "name": "Subsea production wellhead equipment", "code": "20142401" },
      { "name": "Subsea production manifold system", "code": "20142403" },
      {
        "name": "Vertical annular separation and pumping system",
        "code": "20142404"
      },
      { "name": "Subsea christmas tree and component", "code": "20142405" },
      { "name": "Subsea control system", "code": "20142406" },
      { "name": "Produced water treating equipment", "code": "20142500" },
      { "name": "Oil field water oil centrifuges", "code": "20142501" },
      {
        "name": "Production control system instrumentation",
        "code": "20142600"
      },
      { "name": "Wireless production control systems", "code": "20142601" },
      { "name": "Pumping units", "code": "20142700" },
      { "name": "Sucker rod pump jacks", "code": "20142701" },
      { "name": "Rod pumps", "code": "20142702" },
      { "name": "Mechanical rod pumps", "code": "20142703" },
      { "name": "Pumping frame and extension assembly", "code": "20142704" },
      { "name": "Crank arm assembly", "code": "20142705" },
      { "name": "Equalizer pitman assembly", "code": "20142706" },
      { "name": "Horsehead assembly", "code": "20142707" },
      { "name": "Samson post assembly", "code": "20142708" },
      { "name": "Walking beam assembly", "code": "20142709" },
      { "name": "Hanger bar assembly", "code": "20142710" },
      { "name": "Production separators", "code": "20142800" },
      { "name": "Oil water separators", "code": "20142801" },
      { "name": "Storage vessels and tanks", "code": "20142900" },
      { "name": "Oil storage tanks", "code": "20142901" },
      { "name": "Fiberglass holding tank", "code": "20142902" },
      { "name": "Steel holding tank", "code": "20142903" },
      { "name": "Plastic holding tank", "code": "20142904" },
      { "name": "Air receiver tank", "code": "20142905" },
      { "name": "Sucker rods", "code": "20143000" },
      { "name": "Alloy steel sucker rods", "code": "20143001" },
      { "name": "Pony rods", "code": "20143002" },
      { "name": "Continuous sucker rod", "code": "20143003" },
      { "name": "Continuous sucker rod pin end", "code": "20143004" },
      { "name": "Sucker rod shear coupling", "code": "20143005" },
      { "name": "Progressive cavity pump sucker rod", "code": "20143006" },
      { "name": "Pipeline service equipment", "code": "20143300" },
      { "name": "Pipeline pig", "code": "20143301" },
      { "name": "Interior pipeline inspection equipment", "code": "20143302" },
      { "name": "Interior pipeline robotic cutter", "code": "20143303" },
      { "name": "Subsea umbilicals and related equipment", "code": "20143400" },
      { "name": "Umbilical termination assembly", "code": "20143401" },
      { "name": "Umbilical riser and flowline", "code": "20143402" },
      { "name": "Umbilical unit", "code": "20143403" },
      { "name": "Subsea jumper assembly", "code": "20143404" },
      { "name": "Subsea project installation tools", "code": "20143500" },
      { "name": "Subsea running tool", "code": "20143501" },
      { "name": "Subsea manifold", "code": "20143502" },
      { "name": "Subsea connection system", "code": "20143503" },
      { "name": "Subsea jumper installation tool", "code": "20143504" },
      { "name": "Subsea flexibles", "code": "20143600" },
      { "name": "Subsea flexible riser", "code": "20143601" },
      { "name": "Subsea flexible pipe or pipeline", "code": "20143602" },
      { "name": "Subsea pipeline materials", "code": "20143700" },
      { "name": "Subsea line pipe", "code": "20143701" },
      {
        "name": "Subsea pipe coating and insulation and cathodic protection",
        "code": "20143702"
      },
      { "name": "Subsea buoyancy equipment or module", "code": "20143703" },
      { "name": "Subsea flange", "code": "20143704" },
      { "name": "Subsea fitting", "code": "20143705" },
      {
        "name": "Vortex induced vibration VIV suppression equipment",
        "code": "20143706"
      },
      { "name": "Subsea meters", "code": "20143800" },
      { "name": "Subsea flow meter, single phase", "code": "20143801" },
      { "name": "Subsea flow meter, multi phase", "code": "20143802" },
      { "name": "Subsea wet gas meter", "code": "20143803" }
    ]
  },
  {
    "name": "Farming and Fishing and Forestry and Wildlife Machinery and Accessories",
    "code": "21000000",
    "subcategories": [
      {
        "name": "Agricultural and forestry and landscape machinery and equipment",
        "code": "21100000"
      },
      {
        "name": "Agricultural machinery for soil preparation",
        "code": "21101500"
      },
      { "name": "Ploughs", "code": "21101501" },
      { "name": "Harrows", "code": "21101502" },
      { "name": "Cultivators", "code": "21101503" },
      { "name": "Weeders", "code": "21101504" },
      { "name": "Hoeing machines", "code": "21101505" },
      { "name": "Graders or land levelers", "code": "21101506" },
      { "name": "Agricultural rollers", "code": "21101507" },
      { "name": "Rollers for lawn or sports grounds", "code": "21101508" },
      { "name": "Trencher drainage machine", "code": "21101509" },
      { "name": "Disks", "code": "21101513" },
      { "name": "Subsoilers", "code": "21101514" },
      { "name": "Dibblers", "code": "21101516" },
      { "name": "Rotary tiller or power tiller", "code": "21101517" },
      { "name": "Soil sterilizer", "code": "21101518" },
      { "name": "Soil injector", "code": "21101519" },
      { "name": "Flame thrower", "code": "21101520" },
      { "name": "Fertilizer mixer", "code": "21101521" },
      { "name": "Ridge plough or plow", "code": "21101522" },
      {
        "name": "Automated management system for agricultural machinery",
        "code": "21101523"
      },
      { "name": "Levee banking machine", "code": "21101524" },
      { "name": "Stone collection machine", "code": "21101525" },
      { "name": "Farming excavator", "code": "21101526" },
      { "name": "Stump cutting machine", "code": "21101527" },
      {
        "name": "Agricultural machinery for planting and seeding",
        "code": "21101600"
      },
      { "name": "Planters", "code": "21101601" },
      { "name": "Transplanters", "code": "21101602" },
      { "name": "Grain drills", "code": "21101603" },
      { "name": "Seed drills", "code": "21101604" },
      { "name": "Seed treating equipment", "code": "21101605" },
      { "name": "Hole diggers", "code": "21101606" },
      { "name": "Seeder attachment", "code": "21101607" },
      { "name": "Plant growth cabinet or chamber", "code": "21101608" },
      { "name": "Seed bed", "code": "21101609" },
      { "name": "Soil covering machine", "code": "21101610" },
      { "name": "Fertilizer flow rate controller", "code": "21101611" },
      { "name": "Seed germinator", "code": "21101612" },
      { "name": "Air seeder", "code": "21101613" },
      { "name": "Agricultural machinery for harvesting", "code": "21101700" },
      { "name": "Mowers", "code": "21101701" },
      { "name": "Haymaking machinery", "code": "21101702" },
      { "name": "Harvesters", "code": "21101703" },
      { "name": "Combine harvesters", "code": "21101704" },
      { "name": "Threshing machines", "code": "21101705" },
      { "name": "Crop dividers", "code": "21101706" },
      { "name": "Harvester parts or accessories", "code": "21101707" },
      { "name": "Mower parts or accessories", "code": "21101708" },
      { "name": "Baler", "code": "21101709" },
      { "name": "Harvest dryer", "code": "21101710" },
      { "name": "Corn sheller", "code": "21101711" },
      { "name": "Cut flower automatic binding machine", "code": "21101712" },
      { "name": "Cotton ginning machine", "code": "21101713" },
      { "name": "Garlic separating machine", "code": "21101714" },
      {
        "name": "Dispersing and spraying appliances for agriculture",
        "code": "21101800"
      },
      { "name": "Sprayers", "code": "21101801" },
      { "name": "Dusters", "code": "21101802" },
      { "name": "Water sprinklers", "code": "21101803" },
      { "name": "Fertilizer spreaders or distributors", "code": "21101804" },
      { "name": "Fog or mist generators", "code": "21101805" },
      { "name": "Composter", "code": "21101806" },
      { "name": "Pollination equipment or supplies", "code": "21101807" },
      { "name": "Frost protection equipment", "code": "21101808" },
      { "name": "Grain fumigation system", "code": "21101809" },
      { "name": "Poultry and livestock equipment", "code": "21101900" },
      { "name": "Milking machines", "code": "21101901" },
      { "name": "Animal husbandry equipment", "code": "21101902" },
      { "name": "Incubators or brooders for poultry", "code": "21101903" },
      { "name": "Feed mixers", "code": "21101904" },
      { "name": "Livestock identification equipment", "code": "21101905" },
      { "name": "Egg inspection or collecting equipment", "code": "21101906" },
      { "name": "Animal watering machines", "code": "21101907" },
      { "name": "Milk cooling tanks", "code": "21101908" },
      { "name": "Animal shearing or clipping equipment", "code": "21101909" },
      { "name": "Wool comb", "code": "21101910" },
      { "name": "Poultry manure composter", "code": "21101911" },
      { "name": "Livestock restraint chute", "code": "21101912" },
      { "name": "Debeaker", "code": "21101913" },
      { "name": "Livestock electric fence", "code": "21101914" },
      { "name": "Pig backfat tester", "code": "21101915" },
      { "name": "Milking machine parts and accessories", "code": "21101916" },
      {
        "name": "Agricultural machinery for cleaning and sorting and grading",
        "code": "21102000"
      },
      {
        "name": "Cleaning machines for seed or grain or dried leguminous vegetables",
        "code": "21102001"
      },
      {
        "name": "Sorting machines for seed or grain or dried leguminous vegetables",
        "code": "21102002"
      },
      {
        "name": "Grading machines for seed or grain or dried leguminous vegetables",
        "code": "21102003"
      },
      { "name": "Rice cleaning or hulling equipment", "code": "21102004" },
      { "name": "Grinding mills", "code": "21102005" },
      { "name": "Hammer mills", "code": "21102006" },
      { "name": "Fruit sorter", "code": "21102007" },
      { "name": "Barley cleaning or hulling equipment", "code": "21102008" },
      {
        "name": "Cleaning, sorting, and grading machine parts and accessories",
        "code": "21102009"
      },
      {
        "name": "Agricultural processing machinery and equipment",
        "code": "21102100"
      },
      {
        "name": "Agricultural briquetting or pelting machines",
        "code": "21102101"
      },
      { "name": "Oil seed expeller", "code": "21102102" },
      { "name": "Forestry machinery and equipment", "code": "21102200" },
      { "name": "Decorticators", "code": "21102201" },
      { "name": "Lumbering equipment", "code": "21102202" },
      { "name": "Reforestation equipment", "code": "21102203" },
      { "name": "Forestry saws", "code": "21102204" },
      { "name": "Forestry skidders", "code": "21102205" },
      { "name": "Forestry increment borers", "code": "21102206" },
      { "name": "Forestry ipsometer", "code": "21102207" },
      { "name": "Log splitter", "code": "21102208" },
      { "name": "Forestry winch", "code": "21102209" },
      { "name": "Greenhouse equipment", "code": "21102300" },
      { "name": "Greenhouse irrigation equipment", "code": "21102301" },
      { "name": "Greenhouse pots", "code": "21102302" },
      { "name": "Greenhouse ventilation equipment", "code": "21102303" },
      { "name": "Greenhouse isolation equipment", "code": "21102304" },
      { "name": "Agricultural sun shade material", "code": "21102305" },
      { "name": "Hydroponic fertilizer injector", "code": "21102306" },
      { "name": "Insect equipment", "code": "21102400" },
      { "name": "Beekeeping equipment", "code": "21102401" },
      { "name": "Silkworm equipment", "code": "21102402" },
      { "name": "Butterfly breeding equipment", "code": "21102403" },
      { "name": "Beetle breeding equipment", "code": "21102404" },
      { "name": "Irrigation systems and equipment", "code": "21102500" },
      { "name": "Irrigation trickles", "code": "21102501" },
      { "name": "Irrigation overheads", "code": "21102502" },
      { "name": "Irrigation parts and accessories", "code": "21102503" },
      { "name": "Agricultural protection materials", "code": "21102600" },
      { "name": "Plastic film for agriculture", "code": "21102601" },
      { "name": "Fishing and aquaculture equipment", "code": "21110000" },
      { "name": "Commercial fishing equipment", "code": "21111500" },
      { "name": "Commercial fish hooks", "code": "21111501" },
      { "name": "Commercial fishing reels", "code": "21111502" },
      { "name": "Commercial fishing line tackle", "code": "21111503" },
      { "name": "Commercial fishing nets", "code": "21111504" },
      { "name": "Commercial fishing floats", "code": "21111506" },
      { "name": "Commercial sinkers or weights", "code": "21111507" },
      { "name": "Fishing net haulers", "code": "21111508" },
      { "name": "Aquaculture equipment", "code": "21111600" },
      { "name": "Marine hatchery equipment", "code": "21111601" },
      { "name": "Pisciculture supplies", "code": "21111602" }
    ]
  },
  {
    "name": "Building and Construction Machinery and Accessories",
    "code": "22000000",
    "subcategories": [
      {
        "name": "Heavy construction machinery and equipment",
        "code": "22100000"
      },
      { "name": "Earth moving machinery", "code": "22101500" },
      { "name": "Front end loaders", "code": "22101501" },
      { "name": "Graders", "code": "22101502" },
      { "name": "Pile drivers", "code": "22101504" },
      { "name": "Rollers", "code": "22101505" },
      { "name": "Tampers", "code": "22101507" },
      { "name": "Trenching machines", "code": "22101508" },
      { "name": "Backhoes", "code": "22101509" },
      { "name": "Compactors", "code": "22101511" },
      { "name": "Draglines", "code": "22101513" },
      { "name": "Dredgers", "code": "22101514" },
      { "name": "Ditchers", "code": "22101516" },
      { "name": "Elevating scrapers", "code": "22101518" },
      { "name": "Twin engine open bowl scrapers", "code": "22101519" },
      { "name": "Twin engine elevating scrapers", "code": "22101520" },
      { "name": "Pulled scrapers", "code": "22101521" },
      { "name": "Track bulldozers", "code": "22101522" },
      { "name": "Wheel bulldozers", "code": "22101523" },
      { "name": "Mobile excavators", "code": "22101524" },
      { "name": "Wheel excavators", "code": "22101525" },
      { "name": "Track excavators", "code": "22101526" },
      { "name": "Integrated tool carriers", "code": "22101527" },
      { "name": "Wheel loaders", "code": "22101528" },
      { "name": "Skid steer loaders", "code": "22101529" },
      { "name": "Open bowl scrapers", "code": "22101530" },
      { "name": "Snow blowers", "code": "22101531" },
      { "name": "Track loaders", "code": "22101532" },
      { "name": "Treedozers", "code": "22101533" },
      { "name": "Combat earthmovers", "code": "22101534" },
      { "name": "Pile extractor", "code": "22101535" },
      { "name": "Tire washer", "code": "22101536" },
      { "name": "Concrete pile cutter", "code": "22101537" },
      { "name": "Snow plow", "code": "22101538" },
      {
        "name": "Earthmoving machinery parts and accessories",
        "code": "22101539"
      },
      { "name": "Dumper designed for off-highway use", "code": "22101540" },
      { "name": "Paving equipment", "code": "22101600" },
      { "name": "Ramming equipment", "code": "22101602" },
      { "name": "Road wideners", "code": "22101603" },
      { "name": "Vibratory plates", "code": "22101604" },
      { "name": "Asphalt finishers", "code": "22101605" },
      { "name": "Chip Spreaders", "code": "22101606" },
      { "name": "Road pavers", "code": "22101607" },
      { "name": "Cold planers", "code": "22101608" },
      { "name": "Paving material mixers", "code": "22101609" },
      { "name": "Aggregate spreaders", "code": "22101610" },
      { "name": "Bituminous material distributors", "code": "22101611" },
      { "name": "Road rooters", "code": "22101612" },
      { "name": "Road surface heater planers", "code": "22101613" },
      { "name": "Concrete paving strike offs", "code": "22101614" },
      { "name": "Paving breakers", "code": "22101615" },
      { "name": "Curbing machines", "code": "22101616" },
      { "name": "Grouting machines", "code": "22101617" },
      {
        "name": "Trackway surfacing outfits or its laying mechanisms",
        "code": "22101618"
      },
      { "name": "Scrubbing machines", "code": "22101619" },
      { "name": "Joint cleaning or refacing machines", "code": "22101620" },
      { "name": "Asphalt distributor", "code": "22101621" },
      { "name": "Asphalt recycler", "code": "22101622" },
      { "name": "Concrete paving and finishing machine", "code": "22101623" },
      { "name": "Road marking machine", "code": "22101624" },
      { "name": "Concrete surfacing machine", "code": "22101625" },
      { "name": "Asphalt mixing plant", "code": "22101626" },
      { "name": "Asphalt melter", "code": "22101627" },
      { "name": "Aggregate washer", "code": "22101628" },
      { "name": "Aggregate dryer", "code": "22101629" },
      { "name": "Roadmarking remover", "code": "22101630" },
      { "name": "Pile driver guide", "code": "22101631" },
      { "name": "Heavy equipment components", "code": "22101700" },
      { "name": "Earthmoving shovels", "code": "22101701" },
      {
        "name": "Earthmoving buckets or its parts or accessories",
        "code": "22101702"
      },
      { "name": "Blades or tooth or other cutting edges", "code": "22101703" },
      { "name": "Scarifiers", "code": "22101704" },
      { "name": "Track links or track shoes or its parts", "code": "22101705" },
      { "name": "Dippers", "code": "22101706" },
      { "name": "Taglines", "code": "22101707" },
      { "name": "Rippers", "code": "22101708" },
      { "name": "Grapples", "code": "22101709" },
      { "name": "Snowplow attachments", "code": "22101710" },
      { "name": "Paving breaker tools or accessories", "code": "22101711" },
      {
        "name": "Pile driver tools or its parts or accessories",
        "code": "22101712"
      },
      { "name": "Backhoe boom or boom sections", "code": "22101713" },
      { "name": "Tamper parts or repair kits", "code": "22101714" },
      { "name": "Batching plants or feeders", "code": "22101715" },
      { "name": "Construction machinery conversion kits", "code": "22101716" },
      { "name": "Earth moving moldboards", "code": "22101717" },
      { "name": "Grader control systems", "code": "22101718" },
      { "name": "Grader frame saddles", "code": "22101719" },
      { "name": "Trencher crumber shoes", "code": "22101720" },
      { "name": "Stake driver bits and accessories", "code": "22101721" },
      { "name": "Bucket tooth", "code": "22101722" },
      { "name": "Bucket side cutter", "code": "22101723" },
      { "name": "Hydraulic breaker chisel", "code": "22101724" },
      { "name": "Aerial lifts", "code": "22101800" },
      { "name": "Manlift or personnel lift", "code": "22101801" },
      { "name": "Platform lift", "code": "22101802" },
      { "name": "Articulating boom lift", "code": "22101803" },
      { "name": "Telescoping boom lift", "code": "22101804" },
      {
        "name": "Building construction machinery and accessories",
        "code": "22101900"
      },
      { "name": "Concrete mixers or plants", "code": "22101901" },
      { "name": "Plaster or mortar mixers", "code": "22101902" },
      { "name": "Rotary tiller mixers", "code": "22101903" },
      { "name": "Curing machines", "code": "22101904" },
      { "name": "Concrete spreaders", "code": "22101905" },
      { "name": "Shoring equipment", "code": "22101906" },
      { "name": "Trench braces", "code": "22101907" },
      { "name": "Cardboard band drain or wick drain", "code": "22101908" },
      {
        "name": "Building demolition machinery and equipment",
        "code": "22102000"
      },
      { "name": "Demolition equipment kits", "code": "22102001" },
      { "name": "Wrecking ball", "code": "22102002" }
    ]
  },
  {
    "name": "Industrial Manufacturing and Processing Machinery and Accessories",
    "code": "23000000",
    "subcategories": [
      { "name": "Raw materials processing machinery", "code": "23100000" },
      {
        "name": "Machinery for working wood and stone and ceramic and the like",
        "code": "23101500"
      },
      { "name": "Coping machines", "code": "23101501" },
      { "name": "Drilling machines", "code": "23101502" },
      { "name": "Broaching machines", "code": "23101503" },
      { "name": "Bending machines", "code": "23101504" },
      { "name": "Boring machines", "code": "23101505" },
      { "name": "Grinding machines", "code": "23101506" },
      { "name": "Molding machines", "code": "23101507" },
      { "name": "Cutting machines", "code": "23101508" },
      { "name": "Sanding machines", "code": "23101509" },
      { "name": "Polishing machines", "code": "23101510" },
      { "name": "Turning machines", "code": "23101511" },
      { "name": "Sawing machines", "code": "23101512" },
      { "name": "Milling machines", "code": "23101513" },
      { "name": "Planing machines", "code": "23101514" },
      { "name": "Engraving machines", "code": "23101515" },
      { "name": "Glass bead peener machines", "code": "23101516" },
      { "name": "Grit blast machines", "code": "23101517" },
      { "name": "Shot peen machine", "code": "23101518" },
      { "name": "Robot machines", "code": "23101519" },
      { "name": "Ram electro discharge machines", "code": "23101520" },
      {
        "name": "Wire cathode electrode discharge machine",
        "code": "23101521"
      },
      { "name": "Chip breaker", "code": "23101522" },
      { "name": "Oscillating spindle sander", "code": "23101525" },
      { "name": "Drum sander", "code": "23101528" },
      { "name": "Glue roller spreader", "code": "23101529" },
      { "name": "Dovetail machine", "code": "23101530" },
      { "name": "Combination woodworking machine", "code": "23101531" },
      { "name": "Tenoner or tenoning machine", "code": "23101532" },
      { "name": "Cold press", "code": "23101533" },
      { "name": "Edge bander", "code": "23101534" },
      { "name": "Finger jointing machine", "code": "23101535" },
      { "name": "Dowel machine", "code": "23101536" },
      { "name": "Belt and disc sander", "code": "23101537" },
      { "name": "Woodworking jointer", "code": "23101538" },
      { "name": "Gaining machine", "code": "23101539" },
      { "name": "Machine to make wood music instruments", "code": "23101540" },
      { "name": "Petroleum processing machinery", "code": "23110000" },
      {
        "name": "Petroleum distilling and processing machinery and equipment",
        "code": "23111500"
      },
      { "name": "Distillate hydroprocessing equipment", "code": "23111501" },
      { "name": "Crude distilling machinery", "code": "23111502" },
      { "name": "Catalytic cracking equipment", "code": "23111503" },
      { "name": "Hydrocracking equipment", "code": "23111504" },
      { "name": "Isomerization machinery", "code": "23111505" },
      { "name": "Coking machinery", "code": "23111506" },
      { "name": "Gas recovery machinery", "code": "23111507" },
      { "name": "Hydrotreating machinery", "code": "23111600" },
      { "name": "Naptha hydrotreater", "code": "23111601" },
      { "name": "Distillate hydrotreater", "code": "23111602" },
      { "name": "Catalytic feed hydrotreater", "code": "23111603" },
      { "name": "Lube hydrotreater", "code": "23111604" },
      { "name": "Gasoline hydrotreater", "code": "23111605" },
      { "name": "Resid hydrotreater", "code": "23111606" },
      {
        "name": "Textile and fabric machinery and accessories",
        "code": "23120000"
      },
      {
        "name": "Textile processing machinery and accessories",
        "code": "23121500"
      },
      { "name": "Embroidery making machines", "code": "23121501" },
      { "name": "Felting machines", "code": "23121502" },
      { "name": "Winding or reeling or spooling machines", "code": "23121503" },
      { "name": "Twisting machines", "code": "23121504" },
      { "name": "Stitch bonding machines", "code": "23121505" },
      { "name": "Knitting machines", "code": "23121506" },
      { "name": "Weaving machines", "code": "23121507" },
      { "name": "Finishing machines", "code": "23121508" },
      { "name": "Spinning machines", "code": "23121509" },
      { "name": "Lace making machines", "code": "23121510" },
      { "name": "Textile washing machine", "code": "23121511" },
      { "name": "Carding machine", "code": "23121512" },
      { "name": "Yarn assembly winder", "code": "23121513" },
      { "name": "Sizing machine", "code": "23121514" },
      { "name": "Raising machine", "code": "23121515" },
      { "name": "Singeing machine", "code": "23121516" },
      { "name": "Fine spinning machine", "code": "23121517" },
      { "name": "Thread unravelling machine", "code": "23121518" },
      { "name": "Laboratory spinning system", "code": "23121519" },
      { "name": "Warping machine", "code": "23121520" },
      {
        "name": "Textile working machinery and equipment and accessories",
        "code": "23121600"
      },
      { "name": "Button covering machines", "code": "23121601" },
      { "name": "Button sewing machines", "code": "23121602" },
      { "name": "Buttonhole machines", "code": "23121603" },
      { "name": "Cloth cutting machines", "code": "23121604" },
      { "name": "Cushion filling machines", "code": "23121605" },
      { "name": "Folding or rewinding machines", "code": "23121606" },
      { "name": "Bleaching machines", "code": "23121607" },
      { "name": "Fabric or cloth folding machines", "code": "23121608" },
      { "name": "Reeling or unreeling machines", "code": "23121609" },
      { "name": "Dyeing machines", "code": "23121610" },
      { "name": "Cutting or pinking machines", "code": "23121611" },
      { "name": "Sewing machine needles", "code": "23121612" },
      { "name": "Silk processing machines", "code": "23121613" },
      { "name": "Sewing machines", "code": "23121614" },
      { "name": "Fabric cutting tables", "code": "23121615" },
      { "name": "Dyeing tester", "code": "23121616" },
      { "name": "Fabric frame", "code": "23121617" },
      { "name": "Grip of overlock sewing machine", "code": "23121618" },
      { "name": "Hemmer for sewing machine", "code": "23121619" },
      { "name": "Textile turning machine", "code": "23121620" },
      { "name": "Fabric splicing machine", "code": "23121621" },
      { "name": "Lapidary machinery and equipment", "code": "23130000" },
      {
        "name": "Grinding and sanding and polishing equipment and supplies",
        "code": "23131500"
      },
      { "name": "Abrasive compounds", "code": "23131501" },
      { "name": "Felt wheels", "code": "23131502" },
      { "name": "Grinding wheels", "code": "23131503" },
      { "name": "Polishing compounds", "code": "23131504" },
      { "name": "Polishing heads", "code": "23131505" },
      { "name": "Polishing wheels", "code": "23131506" },
      { "name": "Sanding cloths", "code": "23131507" },
      { "name": "Sanding drums", "code": "23131508" },
      { "name": "Tumblers or polishers", "code": "23131509" },
      { "name": "Tumbling supplies or media", "code": "23131510" },
      { "name": "Water swivels", "code": "23131511" },
      { "name": "Water trays", "code": "23131512" },
      { "name": "Sanding blocks", "code": "23131513" },
      { "name": "Mounted stones", "code": "23131514" },
      { "name": "Grinding wheel dressers", "code": "23131515" },
      { "name": "Faceting equipment and accessories", "code": "23131600" },
      { "name": "Faceting accessories", "code": "23131601" },
      { "name": "Faceting machines", "code": "23131602" },
      { "name": "Faceting laps", "code": "23131603" },
      { "name": "Faceting saws", "code": "23131604" },
      { "name": "Cabbing equipment", "code": "23131700" },
      { "name": "Cabbing accessories", "code": "23131701" },
      { "name": "Cabbing belts", "code": "23131702" },
      { "name": "Cabbing discs", "code": "23131703" },
      { "name": "Cabochon machines", "code": "23131704" },
      {
        "name": "Leatherworking repairing machinery and equipment",
        "code": "23140000"
      },
      {
        "name": "Leather preparing machinery and accessories",
        "code": "23141600"
      },
      { "name": "Leather fleshing machines", "code": "23141601" },
      { "name": "Leather tanning machines", "code": "23141602" },
      { "name": "Leather dyeing machines", "code": "23141603" },
      { "name": "Leather degreasing machines", "code": "23141604" },
      { "name": "Leather presses", "code": "23141605" },
      { "name": "Sammying machine", "code": "23141606" },
      { "name": "Slicker", "code": "23141607" },
      { "name": "Dehairing machine", "code": "23141608" },
      {
        "name": "Leather working and repairing machinery and accessories",
        "code": "23141700"
      },
      { "name": "Leather cutting machines", "code": "23141701" },
      { "name": "Leather riveting machines", "code": "23141702" },
      { "name": "Leather nailing machines", "code": "23141703" },
      { "name": "Leatherworking workshop equipment", "code": "23141704" },
      {
        "name": "Industrial process machinery and equipment and supplies",
        "code": "23150000"
      },
      {
        "name": "Rubber and plastic processing machinery and equipment and supplies",
        "code": "23151500"
      },
      { "name": "Blow molding machines", "code": "23151501" },
      { "name": "Coating machines", "code": "23151502" },
      { "name": "Extruders", "code": "23151503" },
      { "name": "Injection molding machines", "code": "23151504" },
      { "name": "Rubber or plastic presses", "code": "23151506" },
      { "name": "Thermo forming machines", "code": "23151507" },
      { "name": "Vacuum molding machines", "code": "23151508" },
      { "name": "Vulcanizing machines", "code": "23151509" },
      { "name": "Plastic cutting machinery", "code": "23151510" },
      { "name": "Plastic grinding machinery", "code": "23151511" },
      { "name": "Rubber or plastic mills", "code": "23151512" },
      { "name": "Rubber or plastic extrusion dies", "code": "23151513" },
      { "name": "Plastic injection molds", "code": "23151514" },
      { "name": "Thermoforming molds", "code": "23151515" },
      { "name": "Ejector pins", "code": "23151516" },
      { "name": "In mold decoration IMD cylinder", "code": "23151517" },
      { "name": "Dip molding equipment", "code": "23151518" },
      {
        "name": "Urethane foam molding and processing equipment",
        "code": "23151519"
      },
      { "name": "Rotational molding equipment", "code": "23151520" },
      {
        "name": "Cement and ceramics and glass industry machinery and equipment and supplies",
        "code": "23151600"
      },
      { "name": "Blowers or dryers", "code": "23151601" },
      { "name": "Crushers", "code": "23151602" },
      {
        "name": "Fusion welding or glass drawing machines",
        "code": "23151603"
      },
      { "name": "Grinding or polishing machines", "code": "23151604" },
      {
        "name": "Cement or ceramic or glass or similar material molding machines",
        "code": "23151606"
      },
      { "name": "Presses", "code": "23151607" },
      { "name": "Sifters", "code": "23151608" },
      { "name": "Glass blowing instrument", "code": "23151609" },
      { "name": "Micropipette puller", "code": "23151610" },
      {
        "name": "Optical industry machinery and equipment and supplies",
        "code": "23151700"
      },
      { "name": "Lens grinding machines", "code": "23151701" },
      { "name": "Lens measuring equipment", "code": "23151702" },
      { "name": "Lens polishing equipment", "code": "23151703" },
      { "name": "Lens testing equipment", "code": "23151704" },
      { "name": "Optical vacuum coating equipment", "code": "23151705" },
      {
        "name": "Pharmaceutical industry machinery and equipment and supplies",
        "code": "23151800"
      },
      { "name": "Ampoule filling equipment", "code": "23151801" },
      {
        "name": "Bottle cappers or cotton inserters or safety seal applicators",
        "code": "23151802"
      },
      { "name": "Capsulating machines", "code": "23151803" },
      { "name": "Reactors or fermenters or digesters", "code": "23151804" },
      { "name": "Filling or sealing auger dose machines", "code": "23151805" },
      { "name": "Pharmaceutical filters or ultra filters", "code": "23151806" },
      { "name": "Freezedryers or lyophilzers", "code": "23151807" },
      { "name": "Granulators", "code": "23151808" },
      {
        "name": "Pharmaceutical sieve or screening machines",
        "code": "23151809"
      },
      {
        "name": "Sterile or aseptic processing or filling machines",
        "code": "23151810"
      },
      { "name": "Tablet or capsule testing machines", "code": "23151811" },
      { "name": "Tablet counters", "code": "23151812" },
      { "name": "Tabletting machines", "code": "23151813" },
      { "name": "Vaccine production equipment", "code": "23151814" },
      { "name": "Chromatography columns", "code": "23151816" },
      { "name": "Chromatography media", "code": "23151817" },
      { "name": "Sterility test devices", "code": "23151818" },
      { "name": "Filter integrity testers", "code": "23151819" },
      { "name": "Filter cartridge adapter", "code": "23151821" },
      {
        "name": "Adapters or connectors or fittings for pharmaceutical filter housings",
        "code": "23151822"
      },
      { "name": "Diagnostic radiopharmaceutical", "code": "23151823" },
      { "name": "Pharmacy compounder or accessories", "code": "23151824" },
      { "name": "Filter and mixer tank unit", "code": "23151825" },
      {
        "name": "Dissolution or disintegration verification equipment",
        "code": "23151826"
      },
      {
        "name": "Paper making and paper processing machinery and equipment and supplies",
        "code": "23151900"
      },
      { "name": "Cutters", "code": "23151901" },
      { "name": "Slitters", "code": "23151902" },
      { "name": "Washing or dewatering machines", "code": "23151903" },
      { "name": "Winders", "code": "23151904" },
      { "name": "Wood pulp or dissolver machines", "code": "23151905" },
      { "name": "Calenders for paper or cardboard making", "code": "23151906" },
      { "name": "Screen drum or sieve", "code": "23151907" },
      { "name": "Mechanical pulp refiner", "code": "23151908" },
      { "name": "Wood pulper", "code": "23151909" },
      { "name": "Flotator", "code": "23151910" },
      { "name": "Paper machine", "code": "23151911" },
      {
        "name": "Web handling and control machinery and equipment and supplies",
        "code": "23152000"
      },
      { "name": "Corona treaters", "code": "23152001" },
      { "name": "Flame treaters", "code": "23152002" },
      { "name": "Separation machinery and equipment", "code": "23152100" },
      {
        "name": "Vibratory separation equipment or parts or screens",
        "code": "23152101"
      },
      {
        "name": "Stationary separation equipment or parts or screens",
        "code": "23152102"
      },
      {
        "name": "Air classification equipment or parts or screens",
        "code": "23152103"
      },
      {
        "name": "Centrifugal separation equipment or parts or screens",
        "code": "23152104"
      },
      { "name": "Electromechanical vibrator", "code": "23152105" },
      {
        "name": "Urethane profile vibratory separation screen",
        "code": "23152106"
      },
      {
        "name": "Profile wire vibratory separation screen",
        "code": "23152107"
      },
      { "name": "Punch plate vibratory separation screen", "code": "23152108" },
      {
        "name": "Urethane coated punch plate vibratory separation screen",
        "code": "23152109"
      },
      {
        "name": "Urethane or rubber wire vibratory separation screen",
        "code": "23152110"
      },
      { "name": "Woven wire vibratory separation screen", "code": "23152111" },
      {
        "name": "Urethane coated woven wire vibratory separation screen",
        "code": "23152112"
      },
      { "name": "Vibro shifter", "code": "23152113" },
      { "name": "Manufacturing tables and stands", "code": "23152200" },
      { "name": "Rotary tables", "code": "23152201" },
      { "name": "Stack stands", "code": "23152202" },
      { "name": "Engine or component test stands", "code": "23152203" },
      { "name": "Machine guarding", "code": "23152204" },
      { "name": "Band saw tables", "code": "23152205" },
      { "name": "Barrier guarding", "code": "23152206" },
      { "name": "Packaging machinery", "code": "23152900" },
      { "name": "Wrapping machinery", "code": "23152901" },
      { "name": "Form or fill or seal machinery", "code": "23152902" },
      { "name": "Packaging vacuum", "code": "23152903" },
      { "name": "Packaging hoppers", "code": "23152904" },
      { "name": "Carton forming machines", "code": "23152905" },
      { "name": "Taping machines", "code": "23152906" },
      { "name": "Packaging machinery supplies", "code": "23152907" },
      { "name": "Bottle washing machine", "code": "23152908" },
      { "name": "Commodity sorting machine", "code": "23152909" },
      { "name": "Automatic bag making machine", "code": "23152910" },
      { "name": "Shoe closing machine", "code": "23152911" },
      { "name": "Bottle capping machine", "code": "23152912" },
      { "name": "Canning seamer", "code": "23152913" },
      {
        "name": "Holding and positioning and guiding systems and devices",
        "code": "23153000"
      },
      { "name": "Calibration jig", "code": "23153001" },
      { "name": "Guide jig", "code": "23153002" },
      { "name": "Master jig", "code": "23153003" },
      { "name": "Needle jig", "code": "23153004" },
      { "name": "Shaft jig", "code": "23153005" },
      { "name": "Checking jig", "code": "23153006" },
      { "name": "Setting jig", "code": "23153007" },
      { "name": "Camera jig", "code": "23153008" },
      { "name": "Pickup jig", "code": "23153009" },
      { "name": "Removal jig", "code": "23153010" },
      { "name": "Nozzle jig", "code": "23153011" },
      { "name": "Sliding jig", "code": "23153012" },
      { "name": "Centering jig", "code": "23153013" },
      { "name": "Inspecting jig", "code": "23153014" },
      { "name": "Feeder jig", "code": "23153015" },
      { "name": "Clutch jig", "code": "23153016" },
      { "name": "Alignment jig", "code": "23153017" },
      { "name": "Positioning jig", "code": "23153018" },
      { "name": "Lifter plate", "code": "23153019" },
      { "name": "Tape guide", "code": "23153020" },
      { "name": "Tape feed jaw", "code": "23153021" },
      { "name": "Power feeder", "code": "23153022" },
      { "name": "Feeder harness", "code": "23153023" },
      { "name": "Feed jaw", "code": "23153024" },
      { "name": "Mechanical gripper", "code": "23153025" },
      { "name": "Holder jaw", "code": "23153026" },
      { "name": "Jaw assembly", "code": "23153027" },
      { "name": "Stationary jaw", "code": "23153028" },
      { "name": "Jig block", "code": "23153029" },
      { "name": "Linear motion guides", "code": "23153030" },
      { "name": "Measuring jigs", "code": "23153031" },
      { "name": "Ring jigs", "code": "23153032" },
      { "name": "Throat plate", "code": "23153033" },
      { "name": "Machine rails", "code": "23153034" },
      { "name": "Finger plates", "code": "23153035" },
      { "name": "Keeper plates", "code": "23153036" },
      { "name": "Feed roll assembly", "code": "23153037" },
      { "name": "Trestle", "code": "23153038" },
      { "name": "Machine rail carriage", "code": "23153039" },
      {
        "name": "Industrial machinery components and accessories",
        "code": "23153100"
      },
      { "name": "Pitch stopper", "code": "23153101" },
      { "name": "Paper stopper", "code": "23153102" },
      { "name": "Stopper pad", "code": "23153103" },
      { "name": "Machine way wipers", "code": "23153129" },
      { "name": "Machine mounts or vibration isolators", "code": "23153130" },
      { "name": "Wear plates or bars or strips", "code": "23153131" },
      { "name": "Dust deflectors", "code": "23153132" },
      { "name": "Sprocket hubs", "code": "23153133" },
      { "name": "Feed or drive rollers", "code": "23153134" },
      { "name": "Feed roller covers", "code": "23153135" },
      { "name": "Saw dust chutes", "code": "23153136" },
      { "name": "Machinery dust covers", "code": "23153137" },
      { "name": "Cutting or chipping heads", "code": "23153138" },
      { "name": "Guide beds", "code": "23153139" },
      { "name": "Link arms", "code": "23153140" },
      { "name": "Electro discharge machine EDM wire", "code": "23153141" },
      { "name": "Accordion bellows", "code": "23153142" },
      { "name": "Spray nozzle", "code": "23153143" },
      { "name": "Spray block", "code": "23153144" },
      { "name": "Spray gun", "code": "23153145" },
      { "name": "Robotics", "code": "23153200" },
      { "name": "Paint robots", "code": "23153201" },
      { "name": "Pick or place robots", "code": "23153202" },
      { "name": "Sealant adhesive robots", "code": "23153203" },
      { "name": "Welding robots", "code": "23153204" },
      { "name": "Machine tending robot", "code": "23153205" },
      { "name": "Material removal robot", "code": "23153206" },
      { "name": "Cleaning robot", "code": "23153207" },
      { "name": "Assembly machines", "code": "23153400" },
      { "name": "Adhesive or glue application systems", "code": "23153401" },
      { "name": "Assembly fixtures", "code": "23153402" },
      { "name": "Specialty assembly", "code": "23153403" },
      {
        "name": "Assembly systems for chassis vehicle operation VO",
        "code": "23153404"
      },
      { "name": "Unlimited component assembly", "code": "23153405" },
      { "name": "Powertrain complete lines", "code": "23153406" },
      { "name": "Surface mount device", "code": "23153407" },
      { "name": "Fill test", "code": "23153408" },
      { "name": "Non portable nutrunner multispindle", "code": "23153409" },
      { "name": "Body skids", "code": "23153410" },
      { "name": "Tire mounting inflating", "code": "23153411" },
      { "name": "Gullotine Shears", "code": "23153412" },
      { "name": "Glass insertion pick up PU application", "code": "23153413" },
      { "name": "Articulating lazy arms", "code": "23153414" },
      { "name": "Automatic chassis decking", "code": "23153415" },
      { "name": "Flexible components", "code": "23153416" },
      { "name": "Miscellaneous assembly machines", "code": "23153417" },
      { "name": "Custom assembly fixtures or tooling", "code": "23153418" },
      { "name": "Custom single station machine", "code": "23153419" },
      { "name": "Wire and cable assembly machine", "code": "23153420" },
      { "name": "Twister or stranding machine", "code": "23153421" },
      { "name": "Paint systems", "code": "23153500" },
      { "name": "Paint application system", "code": "23153501" },
      { "name": "Paint booth repair", "code": "23153502" },
      { "name": "Paint systems ovens", "code": "23153503" },
      { "name": "Paint plant layout or engineering", "code": "23153504" },
      { "name": "Turn key paint system", "code": "23153505" },
      { "name": "Phosphate or e coat paint system", "code": "23153506" },
      {
        "name": "Miscellaneous or miscellaneous paint shop",
        "code": "23153507"
      },
      { "name": "Miscellaneous paint systems", "code": "23153508" },
      { "name": "Part marking machines", "code": "23153600" },
      { "name": "Acid etch marking machines", "code": "23153601" },
      { "name": "Laser marking machine", "code": "23153602" },
      { "name": "Pinstamp marking machine", "code": "23153603" },
      { "name": "Laser Etching Tool", "code": "23153604" },
      { "name": "Dot marking machine", "code": "23153605" },
      { "name": "Electro etcher marking machine", "code": "23153606" },
      { "name": "Inkjet marking machine", "code": "23153607" },
      { "name": "Electronic tube marking machine", "code": "23153608" },
      { "name": "Precision fastening or torque equipment", "code": "23153700" },
      { "name": "Pulse tool", "code": "23153701" },
      { "name": "Nutrunner", "code": "23153702" },
      { "name": "Coating systems", "code": "23153800" },
      { "name": "Electrostatic fluxer", "code": "23153801" },
      { "name": "Electrical coil winding machine", "code": "23153802" },
      {
        "name": "Foundry machines and equipment and supplies",
        "code": "23160000"
      },
      { "name": "Foundry machines and equipment", "code": "23161500" },
      { "name": "Foundry blowers", "code": "23161501" },
      { "name": "Foundry burners", "code": "23161502" },
      { "name": "Core drying ovens", "code": "23161503" },
      { "name": "Foundry crucibles", "code": "23161506" },
      { "name": "Foundry converters", "code": "23161507" },
      { "name": "Casting machines", "code": "23161510" },
      { "name": "Sizing or embossing presses", "code": "23161514" },
      { "name": "Foundry mold machine", "code": "23161516" },
      { "name": "Die casting machine", "code": "23161517" },
      { "name": "Foundry supplies", "code": "23161600" },
      { "name": "Foundry bellows", "code": "23161601" },
      { "name": "Foundry clays", "code": "23161602" },
      { "name": "Foundry flasks", "code": "23161603" },
      { "name": "Foundry ladles", "code": "23161605" },
      { "name": "Foundry molds", "code": "23161606" },
      { "name": "Foundry sand", "code": "23161607" },
      { "name": "Foundry shovels", "code": "23161608" },
      { "name": "Foundry dies and tooling", "code": "23161700" },
      { "name": "Sand casting core box", "code": "23161701" },
      { "name": "Sand casting pattern", "code": "23161702" },
      { "name": "Industrial food and beverage equipment", "code": "23180000" },
      { "name": "Food preparation machinery", "code": "23181500" },
      { "name": "Filling machinery", "code": "23181501" },
      { "name": "Milling machinery", "code": "23181502" },
      { "name": "Sifting machinery", "code": "23181504" },
      { "name": "Dehydrating machinery", "code": "23181505" },
      { "name": "Washing machinery", "code": "23181506" },
      { "name": "Crushing machinery", "code": "23181507" },
      { "name": "Blanching machinery", "code": "23181508" },
      { "name": "Sorting machinery", "code": "23181509" },
      { "name": "Meat tyer or bagger", "code": "23181510" },
      { "name": "Forming machine", "code": "23181511" },
      { "name": "Cooling machine", "code": "23181512" },
      { "name": "Preduster", "code": "23181513" },
      { "name": "Cheese making machine", "code": "23181514" },
      { "name": "Bean curd making machine", "code": "23181515" },
      { "name": "Noodle making machine", "code": "23181516" },
      { "name": "Fermented soybean machine", "code": "23181517" },
      { "name": "Food fermentation device", "code": "23181518" },
      { "name": "Pasteurizing machine", "code": "23181519" },
      { "name": "Cream separator", "code": "23181520" },
      { "name": "Laver or seaweed refining machine", "code": "23181521" },
      { "name": "Seed or nut sheller", "code": "23181522" },
      { "name": "Slaughterhouse or abattoir equipment", "code": "23181523" },
      { "name": "Butter churn", "code": "23181524" },
      { "name": "Food cutting machinery", "code": "23181600" },
      { "name": "Dicing machinery", "code": "23181601" },
      { "name": "Slicing machinery", "code": "23181602" },
      { "name": "Chopping machinery", "code": "23181603" },
      { "name": "Cutting machinery", "code": "23181604" },
      { "name": "Grating machinery", "code": "23181605" },
      { "name": "Peeling machinery", "code": "23181606" },
      { "name": "Food cooking and smoking machinery", "code": "23181700" },
      { "name": "Smoking machinery", "code": "23181701" },
      { "name": "Roasting machinery", "code": "23181702" },
      { "name": "Cooking machinery", "code": "23181703" },
      { "name": "Steaming machinery", "code": "23181704" },
      { "name": "Food sterilizing machine", "code": "23181705" },
      {
        "name": "Industrial beverage processing machinery",
        "code": "23181800"
      },
      { "name": "Coffee roasting equipment", "code": "23181801" },
      { "name": "Juicing machinery", "code": "23181802" },
      { "name": "Ice making machines", "code": "23181803" },
      { "name": "Ice cream machines", "code": "23181804" },
      {
        "name": "Ice making machine parts and accessories",
        "code": "23181805"
      },
      { "name": "Beverage sterilizer", "code": "23181806" },
      { "name": "Dairy mixer", "code": "23181807" },
      { "name": "Ripple machine", "code": "23181808" },
      { "name": "Mixers and their parts and accessories", "code": "23190000" },
      { "name": "Batch mixers", "code": "23191000" },
      { "name": "Change can mixers", "code": "23191001" },
      { "name": "Helical blade mixer", "code": "23191002" },
      { "name": "Double arm kneading mixers", "code": "23191003" },
      { "name": "Intensive mixers", "code": "23191004" },
      { "name": "Roll mixers", "code": "23191005" },
      { "name": "Sand mixer", "code": "23191006" },
      { "name": "Continuous mixers", "code": "23191100" },
      { "name": "Single screw mixers", "code": "23191101" },
      { "name": "Twin screw extruder", "code": "23191102" },
      { "name": "Mixer parts and accessories", "code": "23191200" },
      { "name": "Mixer plows", "code": "23191201" },
      { "name": "Mixer blades", "code": "23191202" },
      { "name": "Mass transfer equipment", "code": "23200000" },
      { "name": "Gas liquid contacting systems", "code": "23201000" },
      { "name": "Plate columns", "code": "23201001" },
      { "name": "Packed columns", "code": "23201002" },
      { "name": "Liquid dispersed contactor", "code": "23201003" },
      { "name": "Wetted wall column", "code": "23201004" },
      { "name": "Bubble columns", "code": "23201005" },
      { "name": "Distillation column", "code": "23201006" },
      { "name": "Distillation column packing", "code": "23201007" },
      { "name": "Distillation tray", "code": "23201008" },
      { "name": "Adsorption and ion exchange", "code": "23201100" },
      { "name": "Adsorber vessel", "code": "23201101" },
      { "name": "Ambient pressure adsorber vessel", "code": "23201102" },
      { "name": "Industrial drying equipment", "code": "23201200" },
      { "name": "Spray dryers", "code": "23201201" },
      { "name": "Air dryers", "code": "23201202" },
      { "name": "Fluidbed dryers", "code": "23201203" },
      { "name": "Food drying equipment", "code": "23201204" },
      {
        "name": "Electronic manufacturing machinery and equipment and accessories",
        "code": "23210000"
      },
      {
        "name": "Electronic assembly machinery and support equipment",
        "code": "23211000"
      },
      { "name": "Chip placers", "code": "23211001" },
      { "name": "Glue dispensing machinery", "code": "23211002" },
      { "name": "Terminal insertion machine", "code": "23211003" },
      {
        "name": "Electronic manufacturing and processing machinery",
        "code": "23211100"
      },
      { "name": "Semiconductor process systems", "code": "23211101" },
      { "name": "Printed circuit board making system", "code": "23211102" },
      { "name": "Wafer wire bonder", "code": "23211103" },
      { "name": "Semiconductor chip inspection monitor", "code": "23211104" },
      {
        "name": "Vacuum impregnation or porosity sealing device",
        "code": "23211105"
      },
      { "name": "Ion implanter", "code": "23211106" },
      {
        "name": "Chicken processing machinery and equipment",
        "code": "23220000"
      },
      {
        "name": "Arrival department machinery and equipment",
        "code": "23221000"
      },
      { "name": "Crate unloading system", "code": "23221001" },
      { "name": "Crate washer", "code": "23221002" },
      {
        "name": "Killing and defeathering department machinery and equipment",
        "code": "23221100"
      },
      { "name": "Stunner", "code": "23221101" },
      { "name": "Chicken defeathering machine", "code": "23221102" },
      { "name": "Plucker or hide puller", "code": "23221103" },
      {
        "name": "Evisceration department machinery and equipment",
        "code": "23221200"
      },
      { "name": "Vent cutter", "code": "23221201" },
      {
        "name": "Sawmilling and lumber processing machinery and equipment",
        "code": "23230000"
      },
      { "name": "Log debarkers and accessories", "code": "23231000" },
      { "name": "Tool holder", "code": "23231001" },
      { "name": "Carbide tool tip", "code": "23231002" },
      { "name": "Bandsaws and accessories", "code": "23231100" },
      { "name": "Bandsaw wheel", "code": "23231101" },
      { "name": "Saw guide", "code": "23231102" },
      { "name": "Circular saws and accessories", "code": "23231200" },
      { "name": "Saw spacer", "code": "23231201" },
      { "name": "Saw arbor", "code": "23231202" },
      { "name": "Lumber edgers", "code": "23231300" },
      { "name": "Infeed roller", "code": "23231301" },
      { "name": "Hold down roll", "code": "23231302" },
      { "name": "Trimsaws and accessories", "code": "23231400" },
      { "name": "Lumber alignment guide", "code": "23231401" },
      { "name": "Zero saw", "code": "23231402" },
      { "name": "Lumber sorters and accessories", "code": "23231500" },
      { "name": "J bars", "code": "23231501" },
      { "name": "Bin sling", "code": "23231502" },
      { "name": "Lumber stackers and accessories", "code": "23231600" },
      { "name": "Fillet holder", "code": "23231601" },
      { "name": "Ending roll", "code": "23231602" },
      {
        "name": "Lumber kilns and equipment and accessories",
        "code": "23231700"
      },
      { "name": "Baffle", "code": "23231701" },
      { "name": "Lumber chippers and accessories", "code": "23231800" },
      { "name": "Knife clamp", "code": "23231801" },
      { "name": "Planers and accessories", "code": "23231900" },
      { "name": "Base bed", "code": "23231901" },
      { "name": "Rear shoe", "code": "23231902" },
      { "name": "Water cooled guide", "code": "23231903" },
      { "name": "Planer, woodworking", "code": "23231904" },
      { "name": "Lumber surfacers and accessories", "code": "23232000" },
      { "name": "Knife holder", "code": "23232001" },
      { "name": "Docking saws and accessories", "code": "23232100" },
      { "name": "Adjustable fence", "code": "23232101" },
      { "name": "Finger jointers and accessories", "code": "23232200" },
      { "name": "Glue nozzle", "code": "23232201" },
      { "name": "Metal cutting machinery and accessories", "code": "23240000" },
      { "name": "Metal grinding machines", "code": "23241400" },
      { "name": "Bench grinder", "code": "23241401" },
      { "name": "Surface grinding machine", "code": "23241402" },
      { "name": "Internal grinding machine", "code": "23241403" },
      { "name": "External grinding machine", "code": "23241404" },
      { "name": "Centerless grinding machine", "code": "23241405" },
      { "name": "Ultrasonic machine", "code": "23241406" },
      { "name": "Cylindrical grinding machine", "code": "23241407" },
      { "name": "Knife grinding machine", "code": "23241408" },
      { "name": "Optical profile grinding machine", "code": "23241409" },
      { "name": "Worm grinding machine", "code": "23241410" },
      { "name": "Buffing machine", "code": "23241411" },
      { "name": "Metal cutting machines", "code": "23241500" },
      { "name": "Abrasive jet machining equipment", "code": "23241501" },
      { "name": "Electrochemical machine ECM", "code": "23241502" },
      { "name": "Flame cutting machine", "code": "23241503" },
      { "name": "Gear cutting tool", "code": "23241504" },
      { "name": "Laser cutting machine", "code": "23241505" },
      { "name": "Plasma cutting machine", "code": "23241506" },
      { "name": "Sawing and cut-off machine", "code": "23241507" },
      { "name": "Water jet cutting machine", "code": "23241508" },
      { "name": "Metal band sawing machine", "code": "23241509" },
      { "name": "Metal shearing machine", "code": "23241510" },
      { "name": "Threading machine", "code": "23241511" },
      { "name": "Metal cutting tools", "code": "23241600" },
      { "name": "Boring tool", "code": "23241601" },
      { "name": "Broach", "code": "23241602" },
      { "name": "Countersink tool or counterbore tool", "code": "23241603" },
      { "name": "Drill carbide", "code": "23241605" },
      { "name": "Drills high speed steel", "code": "23241606" },
      { "name": "Hob cutter", "code": "23241608" },
      { "name": "Knives and skives", "code": "23241609" },
      { "name": "Pipe or tube cutter", "code": "23241610" },
      { "name": "Reamer", "code": "23241611" },
      { "name": "Shaper cutter", "code": "23241612" },
      { "name": "Shaving cutter", "code": "23241613" },
      { "name": "Solid milling cutter", "code": "23241614" },
      { "name": "Taps", "code": "23241615" },
      { "name": "Wire or cable cutter", "code": "23241616" },
      { "name": "Taper pin reamer", "code": "23241617" },
      { "name": "Tower bolt reamer", "code": "23241618" },
      { "name": "Pipe reamer", "code": "23241619" },
      { "name": "Shell reamer", "code": "23241620" },
      { "name": "Rod saw blade", "code": "23241621" },
      { "name": "Single angle cutter", "code": "23241622" },
      { "name": "Side milling cutter", "code": "23241623" },
      { "name": "Plain milling cylindrical cutter", "code": "23241624" },
      { "name": "Shell end milling cutter", "code": "23241625" },
      { "name": "Convex cutter", "code": "23241626" },
      { "name": "Concave cutter", "code": "23241627" },
      { "name": "Corner rounding cutter", "code": "23241628" },
      { "name": "Metal slitting saw", "code": "23241629" },
      { "name": "T slot cutter", "code": "23241630" },
      { "name": "Woodruff seat cutter", "code": "23241631" },
      { "name": "Dovetail cutter", "code": "23241632" },
      { "name": "Gear shaper cutter", "code": "23241633" },
      { "name": "Jobber length drill", "code": "23241634" },
      { "name": "Screw machine drill", "code": "23241635" },
      { "name": "Silver and deming drill", "code": "23241636" },
      { "name": "Extra length or longboy drill", "code": "23241637" },
      { "name": "Carbide tipped hole cutter", "code": "23241638" },
      { "name": "Taper shank drill", "code": "23241639" },
      { "name": "Multi step drill", "code": "23241640" },
      { "name": "Mult step hex step drill", "code": "23241641" },
      { "name": "Speed threader", "code": "23241642" },
      { "name": "Hex shank reamer", "code": "23241643" },
      { "name": "Carbon dioxide laser", "code": "23241644" },
      { "name": "Straight shank chucking reamer", "code": "23241645" },
      { "name": "Adjustable reamer", "code": "23241646" },
      { "name": "Taper shank chucking reamer", "code": "23241647" },
      { "name": "Metal deburring machines", "code": "23241700" },
      { "name": "Shot blasting machine", "code": "23241701" },
      { "name": "Thermal energy deburring machine", "code": "23241702" },
      { "name": "Vibratory or barrel finishing machine", "code": "23241703" },
      { "name": "Metal drilling machines", "code": "23241800" },
      { "name": "Drill press or radial drill", "code": "23241801" },
      { "name": "Gang drilling machine", "code": "23241802" },
      { "name": "Gun drilling machine", "code": "23241803" },
      { "name": "Numerically controlled drilling machine", "code": "23241804" },
      { "name": "Rotary table drilling machine", "code": "23241805" },
      { "name": "Multiple spindle head drilling machine", "code": "23241806" },
      { "name": "Radial arm drilling machine", "code": "23241807" },
      { "name": "Deep hole drilling machine", "code": "23241808" },
      { "name": "Bench drilling machine", "code": "23241809" },
      { "name": "Metal boring machines", "code": "23241900" },
      { "name": "Horizontal boring machine", "code": "23241901" },
      { "name": "Jig boring machine", "code": "23241902" },
      { "name": "Metal broaching machines", "code": "23241903" },
      { "name": "Internal broaching machine", "code": "23241904" },
      { "name": "Surface broaching machine", "code": "23241905" },
      { "name": "Vertical boring machine", "code": "23241906" },
      { "name": "Metal cutting machine attachments", "code": "23242100" },
      { "name": "Bar or rod cutter", "code": "23242101" },
      { "name": "Electrode holder", "code": "23242102" },
      { "name": "Form relief", "code": "23242103" },
      { "name": "Form tools or toolbits", "code": "23242104" },
      { "name": "Indexable insert", "code": "23242105" },
      { "name": "Indexable tool bodies or holders", "code": "23242106" },
      { "name": "Insert carbide", "code": "23242107" },
      { "name": "Insert ceramic", "code": "23242108" },
      { "name": "Insert diamond", "code": "23242109" },
      { "name": "Insert steel", "code": "23242110" },
      { "name": "Lathe turret", "code": "23242111" },
      { "name": "Metal cutting band saw blade", "code": "23242112" },
      { "name": "Metal cutting circular saw blade", "code": "23242113" },
      { "name": "Steady rest", "code": "23242114" },
      { "name": "Tailstock", "code": "23242115" },
      { "name": "Tapping machine attachment", "code": "23242116" },
      { "name": "Threading machine attachment", "code": "23242117" },
      { "name": "Knurling tool", "code": "23242118" },
      { "name": "Lathe bit", "code": "23242119" },
      { "name": "Machine table base", "code": "23242120" },
      { "name": "Gear manufacturing machines", "code": "23242200" },
      { "name": "Bevel gear generator", "code": "23242201" },
      { "name": "Gear hobbing machine", "code": "23242202" },
      { "name": "Gear honing machine", "code": "23242203" },
      { "name": "Gear lapping machine", "code": "23242204" },
      { "name": "Gear shaping machine", "code": "23242205" },
      { "name": "Gear shaving machine", "code": "23242206" },
      { "name": "Gear grinding machine", "code": "23242207" },
      { "name": "Gear tooth chamfering machine", "code": "23242208" },
      { "name": "Lathes and turning centers", "code": "23242300" },
      { "name": "Automatic bar machine", "code": "23242301" },
      { "name": "Automatic lathe or chucking machine", "code": "23242302" },
      { "name": "Horizontal turning center", "code": "23242303" },
      {
        "name": "Tracer or duplicating or contouring lathe",
        "code": "23242304"
      },
      { "name": "Turret lathe", "code": "23242305" },
      { "name": "Vertical turning center", "code": "23242306" },
      { "name": "Leadscrew", "code": "23242307" },
      { "name": "Centering machine", "code": "23242308" },
      { "name": "Machining centers", "code": "23242400" },
      { "name": "Horizontal machining center", "code": "23242401" },
      {
        "name": "Multi-tasking or universal machining center",
        "code": "23242402"
      },
      { "name": "Vertical machining center", "code": "23242403" },
      { "name": "Metal milling machines", "code": "23242500" },
      { "name": "Bed milling machine", "code": "23242501" },
      { "name": "Bridge milling machine", "code": "23242502" },
      { "name": "Column and knee milling machine", "code": "23242503" },
      { "name": "Gantry milling machine", "code": "23242504" },
      {
        "name": "Profiling and duplicating milling machine",
        "code": "23242505"
      },
      { "name": "Traveling column milling machine", "code": "23242506" },
      { "name": "Universal milling machine", "code": "23242507" },
      { "name": "Copy milling machine", "code": "23242508" },
      { "name": "Turret milling machine", "code": "23242509" },
      { "name": "Planer style milling machine", "code": "23242510" },
      { "name": "Machine end mill", "code": "23242511" },
      {
        "name": "Specialized or miscellaneous metal cutting machines",
        "code": "23242600"
      },
      { "name": "Beveling machine", "code": "23242601" },
      { "name": "Burnishing machine", "code": "23242602" },
      { "name": "Chamfering machine", "code": "23242603" },
      { "name": "Lapping machine", "code": "23242604" },
      { "name": "Metal polishing machine", "code": "23242605" },
      { "name": "Skiving machine", "code": "23242606" },
      { "name": "Super finishing machine", "code": "23242607" },
      { "name": "Tool presetter", "code": "23242608" },
      { "name": "Honing machine", "code": "23242609" },
      { "name": "Diamond bore sizing machine", "code": "23242610" },
      { "name": "Notching machine", "code": "23242611" },
      { "name": "Metal engraving machine", "code": "23242612" },
      { "name": "Tapping machine", "code": "23242613" },
      { "name": "Key seating machine", "code": "23242614" },
      { "name": "Sheet metal grooving machine", "code": "23242615" },
      { "name": "Tube mill machinery", "code": "23242700" },
      { "name": "Folded tube mill machinery", "code": "23242701" },
      { "name": "Welded tube mill machinery", "code": "23242702" },
      { "name": "Metal forming machinery and accessories", "code": "23250000" },
      { "name": "Metal bending machines", "code": "23251500" },
      { "name": "Hydraulic press brake", "code": "23251501" },
      { "name": "Manual press brake", "code": "23251502" },
      { "name": "Metal folding machine", "code": "23251503" },
      { "name": "Tangent bender", "code": "23251504" },
      { "name": "Tube bending machine", "code": "23251505" },
      { "name": "Tube end finisher", "code": "23251506" },
      { "name": "Wing bender", "code": "23251507" },
      { "name": "Steel bar bending machine", "code": "23251508" },
      { "name": "Wire drawing machine", "code": "23251509" },
      { "name": "Metal rolling machines", "code": "23251600" },
      { "name": "Thread rolling machine", "code": "23251601" },
      { "name": "Sheet metal forming machine", "code": "23251602" },
      { "name": "Rolling press", "code": "23251603" },
      { "name": "Forging machines", "code": "23251700" },
      { "name": "Forge shear press", "code": "23251701" },
      { "name": "Impression and closed die forging press", "code": "23251702" },
      { "name": "Open die forging press", "code": "23251703" },
      { "name": "Radial forging machines", "code": "23251704" },
      { "name": "Roll forging machines", "code": "23251705" },
      { "name": "Rotary burrs", "code": "23251706" },
      { "name": "Trim press", "code": "23251707" },
      { "name": "High speed forging machine", "code": "23251708" },
      { "name": "Air hammer forging machine", "code": "23251709" },
      { "name": "Spring hammer forging machine", "code": "23251710" },
      { "name": "Steam hammer forging machine", "code": "23251711" },
      { "name": "Drop hammer forging machine", "code": "23251712" },
      { "name": "Swaging forging machine", "code": "23251713" },
      { "name": "Upset forger or crank press", "code": "23251714" },
      { "name": "Metal forming dies and tooling", "code": "23251800" },
      { "name": "Bend die", "code": "23251801" },
      { "name": "Cutting die", "code": "23251802" },
      { "name": "Die casting die", "code": "23251803" },
      { "name": "Fine blanking die", "code": "23251804" },
      { "name": "Forge die", "code": "23251805" },
      { "name": "Metal extrusion die", "code": "23251806" },
      { "name": "Metal stamp", "code": "23251807" },
      { "name": "Pipe bending mandrel", "code": "23251808" },
      { "name": "Rotary die", "code": "23251809" },
      { "name": "Stamping die", "code": "23251810" },
      { "name": "Steel rule die", "code": "23251811" },
      { "name": "Threading die", "code": "23251812" },
      { "name": "Cabling die", "code": "23251813" },
      { "name": "Drawing die", "code": "23251814" },
      {
        "name": "Metal container manufacturing machinery and equipment",
        "code": "23251900"
      },
      { "name": "Metal sheet feeder", "code": "23251901" },
      {
        "name": "Rapid prototyping machinery and accessories",
        "code": "23260000"
      },
      { "name": "Rapid prototyping machines", "code": "23261500" },
      { "name": "Fused deposition modeling machine", "code": "23261501" },
      { "name": "Inkjet method machine", "code": "23261502" },
      { "name": "Laminated object manufacturing machine", "code": "23261503" },
      { "name": "Laser powder forming machine", "code": "23261504" },
      { "name": "Selective laser sintering machine", "code": "23261505" },
      { "name": "Stereolithography machine", "code": "23261506" },
      { "name": "Three dimensional printing machine", "code": "23261507" },
      {
        "name": "Welding and soldering and brazing machinery and accessories and supplies",
        "code": "23270000"
      },
      { "name": "Welding machinery", "code": "23271400" },
      { "name": "Submerged arc welding machine", "code": "23271401" },
      { "name": "Ultrasonic welding machine", "code": "23271402" },
      { "name": "Projection welding machine", "code": "23271403" },
      { "name": "Plasma arc welding machine", "code": "23271404" },
      { "name": "Laser welding machine", "code": "23271405" },
      { "name": "Spot welding machine", "code": "23271406" },
      { "name": "Tungsten inert gas welding machine", "code": "23271407" },
      { "name": "Metal inert gas welding machine", "code": "23271408" },
      {
        "name": "Shielded metal arc welding or stick welding machine",
        "code": "23271409"
      },
      { "name": "Welding rectifier", "code": "23271410" },
      { "name": "Plastic welder", "code": "23271411" },
      { "name": "Thermite welder", "code": "23271412" },
      { "name": "Electron beam welding EBW machine", "code": "23271413" },
      { "name": "Electro slag welding ESW machine", "code": "23271414" },
      {
        "name": "Cold pressure or contact welding machine",
        "code": "23271415"
      },
      { "name": "Friction welding FW machine", "code": "23271416" },
      { "name": "Braze welding machine", "code": "23271417" },
      { "name": "Flash butt welding machine", "code": "23271418" },
      { "name": "Upset welder", "code": "23271419" },
      { "name": "Seam welder", "code": "23271420" },
      { "name": "Band saw blade welder", "code": "23271421" },
      { "name": "Stud welder or stud arc welder", "code": "23271422" },
      { "name": "Alternating current AC arc welder", "code": "23271423" },
      { "name": "Direct current DC arc welder", "code": "23271424" },
      { "name": "Brazing machinery", "code": "23271500" },
      { "name": "Furnace brazing machine", "code": "23271501" },
      { "name": "Induction brazing machine", "code": "23271502" },
      { "name": "Soldering machines", "code": "23271600" },
      { "name": "Furnace soldering machine", "code": "23271601" },
      { "name": "Induction soldering machine", "code": "23271602" },
      { "name": "Soldering iron", "code": "23271603" },
      { "name": "Wave soldering machine", "code": "23271604" },
      { "name": "Reflow oven", "code": "23271605" },
      { "name": "Selective soldering machine", "code": "23271606" },
      { "name": "Automatic soldering machine", "code": "23271607" },
      {
        "name": "Welding and soldering and brazing accessories",
        "code": "23271700"
      },
      { "name": "Blow pipe", "code": "23271701" },
      { "name": "Blow torch", "code": "23271702" },
      { "name": "Braze ring", "code": "23271703" },
      { "name": "Desoldering Gun", "code": "23271704" },
      { "name": "Desoldering pump", "code": "23271705" },
      { "name": "Desoldering tip", "code": "23271706" },
      {
        "name": "Gas welding or brazing or cutting apparatus",
        "code": "23271707"
      },
      { "name": "Temperature indicating stick", "code": "23271708" },
      { "name": "Welding generator", "code": "23271709" },
      { "name": "Welding or brazing tip cleaner file", "code": "23271710" },
      { "name": "Welding or cutting tip", "code": "23271711" },
      { "name": "Welding or soldering kit", "code": "23271712" },
      { "name": "Welding positioner and manipulator", "code": "23271713" },
      { "name": "Welding screen", "code": "23271714" },
      { "name": "Welding tip dresser blade", "code": "23271715" },
      { "name": "Welding tip dresser or accessories", "code": "23271716" },
      { "name": "Flashback arrestor", "code": "23271717" },
      { "name": "Welding blanket", "code": "23271718" },
      {
        "name": "Welding and soldering and brazing supplies",
        "code": "23271800"
      },
      { "name": "Anti spatter spray", "code": "23271801" },
      { "name": "Brazing flux", "code": "23271802" },
      { "name": "Desoldering braid", "code": "23271803" },
      { "name": "Gas welding or brazing rod", "code": "23271804" },
      { "name": "Iron powder", "code": "23271805" },
      { "name": "Solder", "code": "23271806" },
      { "name": "Soldering fluid", "code": "23271807" },
      { "name": "Soldering flux", "code": "23271808" },
      { "name": "Soldering tip", "code": "23271809" },
      { "name": "Welding electrode", "code": "23271810" },
      { "name": "Welding flux", "code": "23271811" },
      { "name": "Welding rod", "code": "23271812" },
      { "name": "Welding wire", "code": "23271813" },
      { "name": "Cutting electrode", "code": "23271814" },
      { "name": "Solder bar", "code": "23271815" },
      { "name": "Solder paste", "code": "23271816" },
      { "name": "Solder powder", "code": "23271817" },
      { "name": "Solder wire", "code": "23271818" },
      { "name": "Metal joining flux", "code": "23271819" },
      { "name": "Welding electrode moisture stabilizer", "code": "23271820" },
      { "name": "Welder torch", "code": "23271821" },
      { "name": "Metal treatment machinery", "code": "23280000" },
      { "name": "Coating or plating machines", "code": "23281500" },
      { "name": "Anodizing machine", "code": "23281501" },
      { "name": "Electrolytic bath machine", "code": "23281502" },
      { "name": "Thermal spray machine", "code": "23281503" },
      { "name": "Barrel plating machine", "code": "23281504" },
      { "name": "Heat treating machines", "code": "23281600" },
      { "name": "Induction heating machine", "code": "23281601" },
      { "name": "Quench machine", "code": "23281602" },
      { "name": "Heat treating age hardening furnace", "code": "23281603" },
      { "name": "Mechanical surface treatment machines", "code": "23281700" },
      { "name": "Cladding machine", "code": "23281701" },
      { "name": "Shot peening machine", "code": "23281702" },
      { "name": "Sand blasting machine", "code": "23281703" },
      {
        "name": "Surface hardening electro discharge machine",
        "code": "23281704"
      },
      { "name": "Metal treatment fixtures and tooling", "code": "23281800" },
      { "name": "Electroplating rack", "code": "23281801" },
      { "name": "Physical vapor deposition rack", "code": "23281802" },
      { "name": "Metal cleaning machinery and supplies", "code": "23281900" },
      { "name": "Electrochemical metal cleaner", "code": "23281901" },
      { "name": "Chemical metal cleaner", "code": "23281902" },
      { "name": "Mechanical or ultrasonic metal cleaner", "code": "23281903" },
      { "name": "Aqueous cleaning and washing equipment", "code": "23281904" },
      { "name": "Thermal degreasing equipment", "code": "23281905" },
      { "name": "Industrial machine tools", "code": "23290000" },
      { "name": "Industrial drilling tools", "code": "23291500" },
      { "name": "Brazed drill", "code": "23291501" },
      { "name": "Exchangeable top or nosepiece drill", "code": "23291502" },
      { "name": "Indexable insert drill", "code": "23291503" },
      { "name": "Exchangeable top or nosepiece mill", "code": "23291504" },
      { "name": "Industrial milling tools", "code": "23291600" },
      { "name": "Milling insert", "code": "23291601" },
      { "name": "Industrial reaming tools", "code": "23291700" },
      { "name": "Reamer blade", "code": "23291701" },
      { "name": "Brazed reamer", "code": "23291702" },
      { "name": "Solid reamer", "code": "23291703" },
      { "name": "Indexable reamer", "code": "23291704" },
      { "name": "Industrial threading tools", "code": "23291800" },
      { "name": "Axial threading holder", "code": "23291801" },
      { "name": "External threading holder", "code": "23291802" },
      { "name": "Internal threading holder", "code": "23291803" },
      { "name": "Threading insert", "code": "23291804" },
      { "name": "Industrial boring tools", "code": "23291900" },
      { "name": "Boring head", "code": "23291901" },
      { "name": "Rough boring tool", "code": "23291902" },
      { "name": "Wire machinery and equipment", "code": "23300000" },
      {
        "name": "Wire working machinery and equipment and accessories",
        "code": "23301500"
      },
      {
        "name": "Wire and cable cutting and terminal assembly equipment",
        "code": "23301501"
      }
    ]
  },
  {
    "name": "Material Handling and Conditioning and Storage Machinery and their Accessories and Supplies",
    "code": "24000000",
    "subcategories": [
      {
        "name": "Material handling machinery and equipment",
        "code": "24100000"
      },
      { "name": "Industrial trucks", "code": "24101500" },
      { "name": "Carts", "code": "24101501" },
      { "name": "Bulk transporters", "code": "24101502" },
      { "name": "Dollies", "code": "24101503" },
      { "name": "Hand trucks or accessories", "code": "24101504" },
      { "name": "Pallet trucks", "code": "24101505" },
      { "name": "Pushcarts", "code": "24101506" },
      { "name": "Wheelbarrows", "code": "24101507" },
      { "name": "Creepers", "code": "24101508" },
      { "name": "Wagons", "code": "24101509" },
      { "name": "Tilt trucks", "code": "24101510" },
      { "name": "Shelf trolleys", "code": "24101511" },
      { "name": "Power buggies", "code": "24101512" },
      { "name": "Tugger", "code": "24101513" },
      { "name": "Platform truck", "code": "24101514" },
      { "name": "Straddle carrier truck", "code": "24101515" },
      { "name": "Powered platform truck", "code": "24101516" },
      { "name": "Electric tow tractor", "code": "24101517" },
      { "name": "Lifting equipment and accessories", "code": "24101600" },
      { "name": "Elevators", "code": "24101601" },
      { "name": "Hoists", "code": "24101602" },
      { "name": "Forklifts", "code": "24101603" },
      { "name": "Lifts", "code": "24101604" },
      { "name": "Loading equipment", "code": "24101605" },
      { "name": "Stackers", "code": "24101606" },
      { "name": "Winches", "code": "24101608" },
      { "name": "Tilts", "code": "24101609" },
      { "name": "Manipulators", "code": "24101610" },
      { "name": "Slings", "code": "24101611" },
      { "name": "Jacks", "code": "24101612" },
      { "name": "Blocks or pulleys", "code": "24101613" },
      { "name": "Air bags for loading", "code": "24101614" },
      { "name": "Loading ramps", "code": "24101615" },
      { "name": "Below the hook device", "code": "24101616" },
      { "name": "Scissor lift or lift table", "code": "24101617" },
      { "name": "Pipe layer", "code": "24101618" },
      { "name": "Bridge cranes", "code": "24101619" },
      { "name": "Track cranes", "code": "24101620" },
      { "name": "All terrain cranes", "code": "24101621" },
      { "name": "Rough terrain cranes", "code": "24101622" },
      { "name": "Tower cranes", "code": "24101623" },
      { "name": "Hydraulic truck cranes", "code": "24101624" },
      { "name": "Conventional truck cranes", "code": "24101625" },
      { "name": "Escalator or walkways", "code": "24101626" },
      { "name": "Girder trolleys", "code": "24101627" },
      { "name": "Adjustable forks", "code": "24101628" },
      {
        "name": "Forklift or elevator accessories or supplies",
        "code": "24101629"
      },
      { "name": "Workshop cranes", "code": "24101630" },
      { "name": "Suction cups", "code": "24101631" },
      { "name": "Side shifts", "code": "24101632" },
      { "name": "Hoist drums", "code": "24101633" },
      { "name": "Chain bags", "code": "24101634" },
      { "name": "Screw jacks", "code": "24101635" },
      { "name": "Counter weight bag and counterweight", "code": "24101636" },
      { "name": "Nylon fabric sling", "code": "24101637" },
      { "name": "Wire rope sling", "code": "24101638" },
      { "name": "Windlass", "code": "24101639" },
      { "name": "Traversing gear unit", "code": "24101640" },
      { "name": "Floating crane", "code": "24101642" },
      { "name": "Laundry and dressing trolley", "code": "24101643" },
      { "name": "Dumb waiter", "code": "24101644" },
      { "name": "Crawler crane", "code": "24101645" },
      { "name": "Railway crane", "code": "24101646" },
      { "name": "Material lifting clamp", "code": "24101647" },
      { "name": "Plate lifting clamp", "code": "24101648" },
      { "name": "Winch crane", "code": "24101649" },
      { "name": "Guy derrick", "code": "24101650" },
      { "name": "Capstan", "code": "24101651" },
      { "name": "Jib crane", "code": "24101652" },
      { "name": "Overhead crane", "code": "24101653" },
      { "name": "Gantry crane", "code": "24101654" },
      { "name": "Vehicle parking lift system", "code": "24101655" },
      { "name": "Tractor towed crane", "code": "24101656" },
      { "name": "Tractor mounted crane", "code": "24101657" },
      { "name": "Lifting magnet", "code": "24101658" },
      { "name": "Vehicle movable jack or dolly", "code": "24101659" },
      { "name": "Battery lifter", "code": "24101660" },
      { "name": "Vehicle lift", "code": "24101661" },
      { "name": "Winch cover", "code": "24101662" },
      { "name": "Conveyors and accessories", "code": "24101700" },
      { "name": "Conveyor roller", "code": "24101701" },
      { "name": "Ball transfer tables", "code": "24101702" },
      { "name": "Rock bins", "code": "24101703" },
      { "name": "Conveyor feeders", "code": "24101704" },
      { "name": "Conveyor screw", "code": "24101705" },
      { "name": "Trolleys or accessories", "code": "24101706" },
      { "name": "Conveyor rails", "code": "24101707" },
      { "name": "Extendable conveyors", "code": "24101708" },
      { "name": "Roller conveyors", "code": "24101709" },
      { "name": "Package stops", "code": "24101710" },
      { "name": "Turntables", "code": "24101711" },
      { "name": "Belt conveyors", "code": "24101712" },
      { "name": "Bucket conveyors", "code": "24101713" },
      { "name": "Air conveyors", "code": "24101714" },
      { "name": "Conveyor belting", "code": "24101715" },
      { "name": "Conveyor pulleys", "code": "24101716" },
      { "name": "Conveyor idlers", "code": "24101717" },
      { "name": "Conveyor belt lacing or fasteners", "code": "24101718" },
      { "name": "Conveyor belt brushes", "code": "24101719" },
      { "name": "Trolley lid", "code": "24101721" },
      { "name": "Chain conveyors", "code": "24101722" },
      { "name": "Motorized rollers or drums", "code": "24101723" },
      { "name": "Conveyor frames", "code": "24101724" },
      { "name": "Conveyor flights or links", "code": "24101725" },
      { "name": "Conveyor liner", "code": "24101726" },
      { "name": "Vibrating conveyors", "code": "24101727" },
      { "name": "Conveyor mounts", "code": "24101728" },
      { "name": "Elevator bucket", "code": "24101729" },
      { "name": "Screw conveyor", "code": "24101730" },
      { "name": "Conveyor skirt board", "code": "24101731" },
      { "name": "Conveyor carrying idler bracket", "code": "24101732" },
      { "name": "Conveyor return idler bracket", "code": "24101733" },
      { "name": "V plough scraper", "code": "24101734" },
      { "name": "Air slide", "code": "24101735" },
      { "name": "Dense phase conveying system", "code": "24101736" },
      { "name": "Lean phase conveying system", "code": "24101737" },
      { "name": "Rod gate", "code": "24101738" },
      { "name": "Bulk receiving unit", "code": "24101739" },
      { "name": "High angle conveyor", "code": "24101740" },
      { "name": "Internal belt scraper", "code": "24101741" },
      { "name": "External belt scraper", "code": "24101742" },
      { "name": "Conveyor tail shaft", "code": "24101743" },
      { "name": "Conveyor head shaft", "code": "24101744" },
      { "name": "Wheel conveyor", "code": "24101745" },
      { "name": "Portable conveyor", "code": "24101746" },
      { "name": "Conveyor support", "code": "24101747" },
      { "name": "Tripper conveyor", "code": "24101748" },
      { "name": "Conveyor system", "code": "24101749" },
      { "name": "Overhead track conveyor", "code": "24101750" },
      { "name": "Dock equipment", "code": "24101800" },
      { "name": "Dock levelers", "code": "24101801" },
      { "name": "Dock seals", "code": "24101802" },
      { "name": "Dock ramps", "code": "24101803" },
      { "name": "Strip doors", "code": "24101804" },
      { "name": "Dock bumpers", "code": "24101805" },
      { "name": "Dock ladders", "code": "24101806" },
      { "name": "Dock plates", "code": "24101807" },
      { "name": "Wheel chocks", "code": "24101808" },
      { "name": "Dock rails or accessories", "code": "24101809" },
      { "name": "Drum handling equipment", "code": "24101900" },
      { "name": "Drum openers", "code": "24101901" },
      { "name": "Drum grabs", "code": "24101902" },
      { "name": "Drum lifters", "code": "24101903" },
      { "name": "Drum cradles", "code": "24101904" },
      { "name": "Spill deck", "code": "24101905" },
      { "name": "Drum recuperators", "code": "24101906" },
      { "name": "Spill containment supports", "code": "24101907" },
      { "name": "Drum stillage", "code": "24101908" },
      { "name": "Shelving and storage", "code": "24102000" },
      {
        "name": "Rack systems for rack mount electronic equipment",
        "code": "24102001"
      },
      { "name": "Bin handlers", "code": "24102002" },
      { "name": "Storage racks or shelves", "code": "24102004" },
      { "name": "Automated storage or retrieval systems", "code": "24102005" },
      { "name": "Work benches", "code": "24102006" },
      { "name": "Reel storage shelves", "code": "24102007" },
      { "name": "Portable reel holders", "code": "24102008" },
      { "name": "Wall mounted rails", "code": "24102009" },
      { "name": "Glass shelving", "code": "24102010" },
      { "name": "Bookshelf accessory", "code": "24102011" },
      { "name": "Warehousing equipment and supplies", "code": "24102100" },
      { "name": "Cargo handling equipment", "code": "24102101" },
      { "name": "Warehouse carousels", "code": "24102102" },
      { "name": "Warehouse casers", "code": "24102103" },
      { "name": "Depalletizers", "code": "24102104" },
      { "name": "Palletizers", "code": "24102105" },
      { "name": "Industrial shrink wrap equipment", "code": "24102106" },
      { "name": "Cartoning machinery", "code": "24102107" },
      { "name": "Packaging compactors", "code": "24102108" },
      { "name": "Sack holders", "code": "24102109" },
      { "name": "Packing tools", "code": "24102200" },
      { "name": "Stretch film dispensers", "code": "24102201" },
      { "name": "Box sealing tape dispensers", "code": "24102202" },
      { "name": "Bag sealing tools or equipment", "code": "24102203" },
      { "name": "Strapping dispenser", "code": "24102204" },
      { "name": "Air inflators", "code": "24102208" },
      { "name": "Die cutting machine", "code": "24102209" },
      { "name": "Baling press", "code": "24102210" },
      { "name": "Automatic guided vehicles AGV", "code": "24102300" },
      {
        "name": "Bar code guided automatic guided vehicle",
        "code": "24102301"
      },
      { "name": "Wire guided automatic guided vehicle", "code": "24102302" },
      {
        "name": "Waste material handling and recycling systems",
        "code": "24102400"
      },
      { "name": "Vertical waste compactor", "code": "24102401" },
      { "name": "Stationary waste compactor", "code": "24102402" },
      { "name": "Containers and storage", "code": "24110000" },
      { "name": "Bags", "code": "24111500" },
      { "name": "Canvas bags", "code": "24111501" },
      { "name": "Paper bags", "code": "24111502" },
      { "name": "Plastic bags", "code": "24111503" },
      { "name": "Flexible intermediate bulk containers", "code": "24111505" },
      { "name": "Laundry nets or bags", "code": "24111506" },
      { "name": "Tool bags", "code": "24111507" },
      { "name": "Tent bag", "code": "24111508" },
      { "name": "Water bags", "code": "24111509" },
      { "name": "Rope bags and rope packs", "code": "24111510" },
      { "name": "Fiber mesh bag", "code": "24111511" },
      { "name": "Straw bag", "code": "24111512" },
      { "name": "Cotton bag", "code": "24111513" },
      { "name": "Zipper bag", "code": "24111514" },
      { "name": "Unwoven fabric bag", "code": "24111515" },
      {
        "name": "Tanks and cylinders and their accessories",
        "code": "24111800"
      },
      { "name": "Reservoirs", "code": "24111801" },
      { "name": "Air or gas tanks or cylinders", "code": "24111802" },
      { "name": "Storage tanks", "code": "24111803" },
      { "name": "Calibrating tanks", "code": "24111804" },
      { "name": "Chemical tanks", "code": "24111805" },
      { "name": "Dip tanks", "code": "24111806" },
      { "name": "Expansion tanks", "code": "24111807" },
      { "name": "Fuel storage tanks", "code": "24111808" },
      { "name": "Processing tanks", "code": "24111809" },
      { "name": "Water storage tanks", "code": "24111810" },
      { "name": "Carboys", "code": "24111811" },
      { "name": "Containment basin", "code": "24111812" },
      { "name": "Rinse tanks", "code": "24111813" },
      { "name": "Hot water tank", "code": "24111814" },
      { "name": "Steam condensate tank", "code": "24111815" },
      { "name": "Liquified gas tank or cylinder", "code": "24111816" },
      { "name": "Cement silo", "code": "24111817" },
      { "name": "Tank seal", "code": "24111818" },
      { "name": "Hydropneumatic tank", "code": "24111819" },
      { "name": "Bins and baskets", "code": "24112000" },
      { "name": "Non metallic bins", "code": "24112003" },
      { "name": "Metallic bins", "code": "24112004" },
      { "name": "Metallic baskets", "code": "24112005" },
      { "name": "Non metallic baskets", "code": "24112006" },
      { "name": "Material handling racks", "code": "24112007" },
      { "name": "Casks and barrels and drums", "code": "24112100" },
      { "name": "Casks", "code": "24112101" },
      { "name": "Barrels", "code": "24112102" },
      { "name": "Metallic drums", "code": "24112108" },
      { "name": "Non metallic drums", "code": "24112109" },
      { "name": "Intermediate bulk containers", "code": "24112110" },
      { "name": "Stabilizer", "code": "24112111" },
      { "name": "Drum lid", "code": "24112112" },
      { "name": "Cans and pails", "code": "24112200" },
      { "name": "Metallic pails", "code": "24112204" },
      { "name": "Non metallic pails", "code": "24112205" },
      { "name": "Metallic flammable liquid cans", "code": "24112206" },
      { "name": "Non metallic flammable liquid cans", "code": "24112207" },
      { "name": "Spray kit", "code": "24112208" },
      { "name": "Jerrycans", "code": "24112209" },
      { "name": "Milk can", "code": "24112210" },
      { "name": "Storage chests and cabinets and trunks", "code": "24112400" },
      { "name": "Tool chest or cabinet", "code": "24112401" },
      { "name": "Hazardous materials cabinets", "code": "24112402" },
      { "name": "Tool belts", "code": "24112403" },
      { "name": "Shelf partition", "code": "24112406" },
      { "name": "Road and flight case", "code": "24112411" },
      { "name": "Equipment transportation case", "code": "24112412" },
      { "name": "Flag storage box or case", "code": "24112413" },
      { "name": "Underground equipment cabinet", "code": "24112416" },
      {
        "name": "Corrugated and other supplies for distribution",
        "code": "24112500"
      },
      { "name": "Slotted corrugated cartons", "code": "24112501" },
      { "name": "One piece die cut shipping cartons", "code": "24112502" },
      {
        "name": "Die cut corrugated shipping cartons with separate lids",
        "code": "24112503"
      },
      { "name": "Corrugated cardboard shapes", "code": "24112505" },
      { "name": "Liquid containers", "code": "24112600" },
      { "name": "Jugs", "code": "24112601" },
      { "name": "Jars", "code": "24112602" },
      { "name": "Pallets", "code": "24112700" },
      { "name": "Wood pallet", "code": "24112701" },
      { "name": "Plastic pallet", "code": "24112702" },
      { "name": "Metal pallet", "code": "24112703" },
      { "name": "Freight containers", "code": "24112800" },
      { "name": "Dry freight container", "code": "24112801" },
      { "name": "Open top freight container", "code": "24112802" },
      { "name": "Platform freight container", "code": "24112803" },
      { "name": "Flatrack freight container", "code": "24112804" },
      {
        "name": "Temperature controlled freight container",
        "code": "24112805"
      },
      { "name": "Crates", "code": "24112900" },
      { "name": "Wooden crate", "code": "24112901" },
      { "name": "Plastic crate", "code": "24112902" },
      { "name": "Slip sheets", "code": "24113000" },
      { "name": "Corrugated slip sheet", "code": "24113001" },
      {
        "name": "Fiberboard or solid kraftboard slip sheet",
        "code": "24113002"
      },
      { "name": "Plastic slip sheet", "code": "24113003" },
      { "name": "Boxes", "code": "24113100" },
      { "name": "Mail box", "code": "24113101" },
      { "name": "Antistatic box", "code": "24113102" },
      { "name": "Box lid", "code": "24113103" },
      { "name": "Cold storage box", "code": "24113104" },
      { "name": "Ballot box", "code": "24113105" },
      { "name": "Tool box", "code": "24113106" },
      { "name": "Box partition", "code": "24113107" },
      { "name": "Insulated box for vaccines", "code": "24113108" },
      { "name": "Molded box", "code": "24113109" },
      { "name": "Packaging materials", "code": "24120000" },
      { "name": "Packaging boxes and bags and pouches", "code": "24121500" },
      { "name": "Packaging pouches or bags", "code": "24121502" },
      { "name": "Packaging boxes", "code": "24121503" },
      { "name": "Carded packaging", "code": "24121504" },
      { "name": "Conductive boxes", "code": "24121506" },
      { "name": "Rigid set up boxes", "code": "24121507" },
      { "name": "Egg trays", "code": "24121508" },
      { "name": "Packaging trays", "code": "24121509" },
      { "name": "Tea bag envelope", "code": "24121510" },
      { "name": "Packaging carton", "code": "24121511" },
      { "name": "Cold pack or ice brick", "code": "24121512" },
      { "name": "Packaging case", "code": "24121513" },
      { "name": "Insulation pack", "code": "24121514" },
      { "name": "Packaging cans", "code": "24121800" },
      { "name": "Aerosol cans", "code": "24121801" },
      { "name": "Paint or varnish cans", "code": "24121802" },
      { "name": "Beverage cans", "code": "24121803" },
      { "name": "Food cans", "code": "24121804" },
      { "name": "Steel cans", "code": "24121805" },
      { "name": "Aluminum cans", "code": "24121806" },
      { "name": "Plastic cans", "code": "24121807" },
      { "name": "Fiberboard can", "code": "24121808" },
      { "name": "Bottles", "code": "24122000" },
      { "name": "Squeeze bottles", "code": "24122001" },
      { "name": "Plastic bottles", "code": "24122002" },
      { "name": "Glass bottles", "code": "24122003" },
      { "name": "Caps or tops", "code": "24122004" },
      { "name": "Applicator bottles", "code": "24122005" },
      { "name": "Applicators", "code": "24122006" },
      { "name": "Industrial refrigeration", "code": "24130000" },
      { "name": "Industrial refrigerators", "code": "24131500" },
      { "name": "Combined refrigerator freezers", "code": "24131501" },
      { "name": "Liquid nitrogen refrigerators", "code": "24131502" },
      { "name": "Walk in refrigerators", "code": "24131503" },
      { "name": "Refrigerated containers", "code": "24131504" },
      { "name": "Refrigerated vessels", "code": "24131505" },
      { "name": "Refrigerated tanks", "code": "24131506" },
      { "name": "Blast chiller", "code": "24131507" },
      { "name": "Cabinet refrigerator", "code": "24131508" },
      { "name": "Under counter refrigerator", "code": "24131509" },
      { "name": "Counter refrigerator", "code": "24131510" },
      { "name": "Bakery cabinet refrigerator", "code": "24131511" },
      {
        "name": "Refrigerated counter preparation station",
        "code": "24131512"
      },
      { "name": "Refrigerant", "code": "24131513" },
      { "name": "Precooling and cold storage unit", "code": "24131514" },
      { "name": "Pharmaceutical refrigerator and freezer", "code": "24131515" },
      { "name": "Cold storage room", "code": "24131516" },
      { "name": "Industrial freezers", "code": "24131600" },
      { "name": "Chest freezers", "code": "24131601" },
      { "name": "Upright cabinet freezer", "code": "24131602" },
      { "name": "Low temperature freezers", "code": "24131603" },
      { "name": "Freeze drying equipment", "code": "24131604" },
      { "name": "Walk in freezers", "code": "24131605" },
      { "name": "Plate freezers", "code": "24131606" },
      { "name": "Blast freezers", "code": "24131607" },
      { "name": "Bakery cabinet freezer", "code": "24131608" },
      { "name": "Under counter cabinet freezer", "code": "24131609" },
      { "name": "Counter cabinet freezer", "code": "24131610" },
      { "name": "Ice makers", "code": "24131900" },
      { "name": "Ice cube makers", "code": "24131901" },
      { "name": "Ice block makers", "code": "24131902" },
      { "name": "Ice flake maker", "code": "24131903" },
      { "name": "Packing supplies", "code": "24140000" },
      { "name": "Securing and protecting supplies", "code": "24141500" },
      { "name": "Stretch wrap films", "code": "24141501" },
      { "name": "Shrink wrap films", "code": "24141502" },
      { "name": "Tamper proof or security seals", "code": "24141504" },
      { "name": "Tarpaulins", "code": "24141506" },
      { "name": "Anti static packaging films", "code": "24141507" },
      { "name": "Carton corner support", "code": "24141508" },
      { "name": "Cable protectors", "code": "24141510" },
      { "name": "Load binders", "code": "24141511" },
      { "name": "Packing absorbents", "code": "24141512" },
      { "name": "Blister packs", "code": "24141513" },
      { "name": "Packaging films", "code": "24141514" },
      { "name": "Protective netting", "code": "24141515" },
      { "name": "Dessicant", "code": "24141516" },
      { "name": "Polyfilm", "code": "24141517" },
      { "name": "Separator sheet", "code": "24141518" },
      { "name": "Steel packing band or strapping", "code": "24141519" },
      { "name": "Non metal packing band or strapping", "code": "24141520" },
      { "name": "Cushioning supplies", "code": "24141600" },
      { "name": "Bubble wrap", "code": "24141601" },
      { "name": "Thermoforming materials", "code": "24141602" },
      { "name": "Cushioning", "code": "24141603" },
      { "name": "Wadding materials", "code": "24141604" },
      { "name": "Air filled packing materials", "code": "24141605" },
      { "name": "Packing peanuts", "code": "24141606" },
      { "name": "Cardboard inserts", "code": "24141607" },
      { "name": "Protective ends", "code": "24141608" },
      {
        "name": "Packaging tubes and cores and labels and accessories",
        "code": "24141700"
      },
      { "name": "Paper tubes or cores", "code": "24141701" },
      { "name": "Tube or core end plugs", "code": "24141702" },
      { "name": "Paper pieces", "code": "24141703" },
      { "name": "Printed inserts or instructions", "code": "24141704" },
      { "name": "Collapsible tubes", "code": "24141705" },
      { "name": "Spool", "code": "24141706" },
      { "name": "Reel", "code": "24141707" },
      { "name": "Parcel handles", "code": "24141708" },
      { "name": "Capsule tubes or caps", "code": "24141709" },
      { "name": "Tag wire", "code": "24141710" }
    ]
  },
  {
    "name": "Commercial and Military and Private Vehicles and their Accessories and Components",
    "code": "25000000",
    "subcategories": [
      { "name": "Motor vehicles", "code": "25100000" },
      { "name": "Passenger motor vehicles", "code": "25101500" },
      { "name": "Minibuses", "code": "25101501" },
      { "name": "Busses", "code": "25101502" },
      { "name": "Automobiles or cars", "code": "25101503" },
      { "name": "Station wagons", "code": "25101504" },
      { "name": "Minivans or vans", "code": "25101505" },
      { "name": "Limousines", "code": "25101506" },
      { "name": "Light trucks or sport utility vehicles", "code": "25101507" },
      { "name": "Sports car", "code": "25101508" },
      { "name": "Electrically powered vehicle", "code": "25101509" },
      { "name": "Armored motor vehicle", "code": "25101510" },
      { "name": "Product and material transport vehicles", "code": "25101600" },
      { "name": "Dump trucks", "code": "25101601" },
      { "name": "Tow trucks", "code": "25101602" },
      { "name": "Delivery trucks", "code": "25101604" },
      { "name": "Sludge or sewage handling trucks", "code": "25101609" },
      { "name": "Water trucks", "code": "25101610" },
      { "name": "Cargo trucks", "code": "25101611" },
      { "name": "Concrete transport truck", "code": "25101612" },
      { "name": "Concrete pump truck", "code": "25101613" },
      { "name": "Load motovan", "code": "25101614" },
      { "name": "Safety and rescue vehicles", "code": "25101700" },
      { "name": "Police vehicles", "code": "25101702" },
      { "name": "Ambulances", "code": "25101703" },
      { "name": "Fire fighting pump truck", "code": "25101704" },
      { "name": "Fire fighting water tank truck", "code": "25101705" },
      { "name": "Fire fighting chemical truck", "code": "25101706" },
      { "name": "Fire fighting ladder truck", "code": "25101707" },
      { "name": "Rescue truck", "code": "25101708" },
      { "name": "Smoke exhaust truck", "code": "25101709" },
      { "name": "Smoke exhaust truck with lighting", "code": "25101710" },
      {
        "name": "Fire fighting truck with remote control nozzle",
        "code": "25101711"
      },
      { "name": "Fire investigation car", "code": "25101712" },
      { "name": "Motorized cycles", "code": "25101800" },
      { "name": "Motorcycles", "code": "25101801" },
      { "name": "Scooters", "code": "25101802" },
      { "name": "Mopeds", "code": "25101803" },
      { "name": "Motorcycle sidecar", "code": "25101804" },
      { "name": "Specialized and recreational vehicles", "code": "25101900" },
      { "name": "Agricultural tractors", "code": "25101901" },
      { "name": "Motor homes", "code": "25101902" },
      { "name": "Snowmobiles or snow scooter", "code": "25101903" },
      { "name": "Golf carts", "code": "25101904" },
      { "name": "All terrain vehicles tracked or wheeled", "code": "25101905" },
      { "name": "Go cart", "code": "25101906" },
      { "name": "Trailer, travel or caravan", "code": "25101907" },
      { "name": "Quads", "code": "25101908" },
      { "name": "Amphibious vehicle", "code": "25101909" },
      { "name": "Water sprinkling truck", "code": "25101910" },
      { "name": "Aerial working platform truck", "code": "25101911" },
      { "name": "Ladder lift truck", "code": "25101912" },
      { "name": "Refrigerator truck", "code": "25101913" },
      {
        "name": "Waste collection vehicle or garbage truck",
        "code": "25101914"
      },
      { "name": "Oil tank truck", "code": "25101915" },
      { "name": "Hopper truck", "code": "25101916" },
      { "name": "Flatbed truck", "code": "25101917" },
      { "name": "Neighborhood electric vehicle NEV", "code": "25101918" },
      { "name": "Road sweeper", "code": "25101919" },
      { "name": "Tank trucks", "code": "25101920" },
      { "name": "Van trucks", "code": "25101921" },
      { "name": "Road repair truck", "code": "25101922" },
      { "name": "Crawler type tractor", "code": "25101923" },
      { "name": "Guard rail cleaning vehicle", "code": "25101924" },
      { "name": "Sign truck", "code": "25101925" },
      { "name": "Snow plow truck", "code": "25101926" },
      { "name": "Salt spreader truck", "code": "25101927" },
      { "name": "Mobile media truck", "code": "25101928" },
      { "name": "Mobile office van", "code": "25101929" },
      { "name": "Lighting truck", "code": "25101930" },
      { "name": "Outside broadcasting van", "code": "25101931" },
      { "name": "Crowd control truck", "code": "25101932" },
      { "name": "Crane truck", "code": "25101933" },
      { "name": "Funeral vehicle or hearse", "code": "25101934" },
      { "name": "Spray truck", "code": "25101935" },
      { "name": "Vacuum truck", "code": "25101936" },
      { "name": "Medical examination and treatment bus", "code": "25101937" },
      { "name": "Bloodmobile", "code": "25101938" },
      { "name": "Trailer, mobile classroom", "code": "25101939" },
      {
        "name": "Utility task vehicle UTV or recreational off highway vehicle ROV",
        "code": "25101940"
      },
      { "name": "Mobile laboratory", "code": "25101941" },
      { "name": "War vehicles", "code": "25102000" },
      { "name": "Tanks", "code": "25102001" },
      { "name": "Armored fighting vehicles", "code": "25102002" },
      { "name": "Self propelled artillery", "code": "25102003" },
      { "name": "Truck tractors", "code": "25102100" },
      { "name": "Low cab forward tractors", "code": "25102101" },
      { "name": "Long nose tractors with sleeper", "code": "25102102" },
      { "name": "Long nose tractors without sleeper", "code": "25102103" },
      { "name": "Cab over engine tractors with sleeper", "code": "25102104" },
      {
        "name": "Cab over engine tractors without sleeper",
        "code": "25102105"
      },
      { "name": "Tractor head", "code": "25102106" },
      { "name": "Marine transport", "code": "25110000" },
      { "name": "Commercial marine craft", "code": "25111500" },
      { "name": "Trawlers", "code": "25111501" },
      { "name": "Fishing boats", "code": "25111502" },
      { "name": "Cargo or container ships", "code": "25111503" },
      { "name": "Dredging craft", "code": "25111504" },
      { "name": "Tankers", "code": "25111505" },
      { "name": "Tug boats", "code": "25111506" },
      { "name": "Barges", "code": "25111507" },
      { "name": "Passenger or automobile ferries", "code": "25111508" },
      { "name": "Cruise ships", "code": "25111509" },
      { "name": "Salvage ships", "code": "25111510" },
      { "name": "Oil or gas crew boat", "code": "25111511" },
      { "name": "Oil or gas workboat", "code": "25111512" },
      { "name": "Seismic vessel", "code": "25111513" },
      { "name": "Icebreaker", "code": "25111514" },
      { "name": "Liquified gas carrier", "code": "25111515" },
      { "name": "Inflatable rubber boat", "code": "25111516" },
      { "name": "Hovercraft", "code": "25111517" },
      { "name": "Hospital ship", "code": "25111518" },
      { "name": "Racing boat or shell", "code": "25111519" },
      { "name": "Buoy", "code": "25111520" },
      { "name": "Marine anchor", "code": "25111521" },
      { "name": "Chemical tanker carrier", "code": "25111522" },
      { "name": "Factory ship", "code": "25111523" },
      { "name": "Bulk carrier", "code": "25111524" },
      {
        "name": "Pure car carrier or roll on roll off ship",
        "code": "25111525"
      },
      { "name": "Drill ship", "code": "25111526" },
      { "name": "Row boat", "code": "25111527" },
      { "name": "Quarantine boat", "code": "25111528" },
      { "name": "Weather ship", "code": "25111529" },
      { "name": "Pilot boat", "code": "25111530" },
      { "name": "Hydrographical survey ship", "code": "25111531" },
      { "name": "Debris collection vessel", "code": "25111532" },
      { "name": "Training ship", "code": "25111533" },
      { "name": "Research vessel", "code": "25111534" },
      { "name": "Cable laying ships", "code": "25111535" },
      { "name": "Safety and rescue water craft", "code": "25111600" },
      { "name": "Lifeboats or liferafts", "code": "25111601" },
      { "name": "Fire fighting watercraft", "code": "25111602" },
      { "name": "Rescue ships or boats", "code": "25111603" },
      { "name": "Military watercraft", "code": "25111700" },
      { "name": "Submarines", "code": "25111701" },
      { "name": "Aircraft carriers", "code": "25111702" },
      { "name": "Ammunition ships", "code": "25111703" },
      { "name": "Amphibious assault ships", "code": "25111704" },
      { "name": "Amphibious transport docks", "code": "25111705" },
      { "name": "Amphibious command ships", "code": "25111706" },
      { "name": "Command ships", "code": "25111707" },
      { "name": "Cruisers", "code": "25111708" },
      { "name": "Destroyers", "code": "25111709" },
      { "name": "Dock landing ships", "code": "25111710" },
      { "name": "Fast combat support ships", "code": "25111711" },
      { "name": "Frigates", "code": "25111712" },
      { "name": "Fleet oilers", "code": "25111713" },
      { "name": "Utility landing watercraft", "code": "25111714" },
      { "name": "Mechanized or utility watercraft", "code": "25111715" },
      { "name": "Mine hunting ships", "code": "25111716" },
      { "name": "Mine countermeasures ships", "code": "25111717" },
      { "name": "Coastal patrol watercraft", "code": "25111718" },
      { "name": "Submarine tenders", "code": "25111719" },
      { "name": "Tank landing ships", "code": "25111720" },
      { "name": "Air cushioned landing watercraft", "code": "25111721" },
      { "name": "Coast guard boat or cutter", "code": "25111722" },
      { "name": "Fishery patrol boat", "code": "25111723" },
      { "name": "Fishery training ship", "code": "25111724" },
      { "name": "Fishery research ship", "code": "25111725" },
      { "name": "Marine pollution control ship", "code": "25111726" },
      { "name": "Recreational watercraft", "code": "25111800" },
      { "name": "Recreational sailboats", "code": "25111801" },
      { "name": "Recreational motorboats", "code": "25111802" },
      { "name": "Recreational rowboats", "code": "25111803" },
      { "name": "Canoes or kayaks", "code": "25111804" },
      { "name": "Personal motorized watercraft", "code": "25111805" },
      { "name": "Rafts", "code": "25111806" },
      { "name": "Dinghies", "code": "25111807" },
      { "name": "Yachts", "code": "25111808" },
      { "name": "Skiff", "code": "25111809" },
      { "name": "Marine craft systems and subassemblies", "code": "25111900" },
      { "name": "Marine craft communications systems", "code": "25111901" },
      { "name": "Marine propellers", "code": "25111902" },
      { "name": "Sails", "code": "25111903" },
      { "name": "Paddles", "code": "25111904" },
      { "name": "Marine ballast systems", "code": "25111905" },
      { "name": "Anchor chocks", "code": "25111906" },
      { "name": "Anchor lines", "code": "25111907" },
      { "name": "Anchor retrievers", "code": "25111908" },
      { "name": "Anchor rollers", "code": "25111909" },
      { "name": "Boat ramps", "code": "25111910" },
      { "name": "Boathooks", "code": "25111911" },
      { "name": "Boom vangs", "code": "25111912" },
      { "name": "Deck hatches", "code": "25111913" },
      { "name": "Dock rings", "code": "25111914" },
      { "name": "Dock steps", "code": "25111915" },
      { "name": "Emergency dye signals", "code": "25111916" },
      { "name": "Fairleaders", "code": "25111917" },
      { "name": "Furler systems", "code": "25111918" },
      { "name": "Keel protectors", "code": "25111919" },
      { "name": "Marine fenders", "code": "25111920" },
      { "name": "Marine goosenecks", "code": "25111921" },
      { "name": "Mooring whips", "code": "25111922" },
      { "name": "Oarlocks", "code": "25111923" },
      { "name": "Radar reflectors", "code": "25111924" },
      { "name": "Marine rudders", "code": "25111925" },
      { "name": "Sail battens", "code": "25111926" },
      { "name": "Sail booms", "code": "25111927" },
      { "name": "Sail covers", "code": "25111928" },
      { "name": "Sheet bags", "code": "25111929" },
      { "name": "Spinnaker poles", "code": "25111930" },
      { "name": "Stern platforms", "code": "25111931" },
      { "name": "Tell tales", "code": "25111932" },
      { "name": "Tide clocks", "code": "25111933" },
      { "name": "Tillers", "code": "25111934" },
      { "name": "Whisker poles", "code": "25111935" },
      { "name": "Marine thruster", "code": "25111936" },
      { "name": "Mast boot", "code": "25111937" },
      { "name": "Marine steering gear", "code": "25111938" },
      { "name": "Marine fresh water generator", "code": "25111939" },
      { "name": "Ship navigation simulator", "code": "25111940" },
      {
        "name": "Railway and tramway machinery and equipment",
        "code": "25120000"
      },
      { "name": "Locomotives and electric trolleys", "code": "25121500" },
      { "name": "Diesel freight locomotives", "code": "25121501" },
      { "name": "Electric freight locomotives", "code": "25121502" },
      { "name": "Diesel passenger locomotives", "code": "25121503" },
      { "name": "Electric passenger locomotives", "code": "25121504" },
      {
        "name": "Parts of railway or tramway locomotives or rolling stock",
        "code": "25121505"
      },
      { "name": "Railway and tramway cars", "code": "25121600" },
      { "name": "Freight rail cars", "code": "25121601" },
      { "name": "Tanker rail cars", "code": "25121602" },
      { "name": "Passenger rail cars", "code": "25121603" },
      { "name": "Hopper rail cars", "code": "25121604" },
      { "name": "Streetcars or tramway cars", "code": "25121605" },
      {
        "name": "Self-propelled railway or tramway coach, van, or truck",
        "code": "25121606"
      },
      {
        "name": "Railway or tramway maintenance or service vehicle",
        "code": "25121607"
      },
      { "name": "Railroad support equipment and systems", "code": "25121700" },
      { "name": "Rail switching systems", "code": "25121701" },
      { "name": "Railway sleepers", "code": "25121702" },
      { "name": "Railway tracks", "code": "25121703" },
      { "name": "Railway frogs or fish plates", "code": "25121704" },
      { "name": "Rail couplers", "code": "25121705" },
      { "name": "Draft gears", "code": "25121706" },
      { "name": "Bogie assemblies", "code": "25121707" },
      { "name": "Heavy railway rail", "code": "25121708" },
      { "name": "Heavy railway relay rail", "code": "25121709" },
      { "name": "Light railway rail", "code": "25121710" },
      { "name": "Light railway relay rail", "code": "25121711" },
      { "name": "Steel railway tie", "code": "25121712" },
      { "name": "Electrical rail contact bond", "code": "25121713" },
      { "name": "Heavy rail turnout switch", "code": "25121714" },
      { "name": "Light rail turnout switch", "code": "25121715" },
      { "name": "Wooden railroad tie", "code": "25121716" },
      { "name": "Rail bond", "code": "25121717" },
      { "name": "Aircraft", "code": "25130000" },
      { "name": "Powered fixed wing aircraft", "code": "25131500" },
      { "name": "Fixed wing agricultural aircraft", "code": "25131501" },
      { "name": "Cargo propeller aircraft", "code": "25131502" },
      { "name": "Seaplanes", "code": "25131503" },
      { "name": "Commercial passenger propeller aircraft", "code": "25131504" },
      { "name": "Cargo jet aircraft", "code": "25131505" },
      { "name": "Commercial passenger jet aircraft", "code": "25131506" },
      { "name": "Private or business propeller aircraft", "code": "25131507" },
      { "name": "Private or business jet aircraft", "code": "25131508" },
      { "name": "Fixed wing training aircraft or trainer", "code": "25131509" },
      {
        "name": "Civilian and commercial rotary wing aircraft",
        "code": "25131600"
      },
      { "name": "Passenger transport helicopters", "code": "25131601" },
      { "name": "Cargo transport helicopters", "code": "25131602" },
      { "name": "Agricultural helicopters", "code": "25131603" },
      { "name": "Medical or rescue helicopters", "code": "25131604" },
      {
        "name": "Rotary wing training aircraft or helicopter trainer",
        "code": "25131605"
      },
      { "name": "Military fixed wing aircraft", "code": "25131700" },
      { "name": "Bomber aircraft", "code": "25131701" },
      { "name": "Fighter bomber aircraft", "code": "25131702" },
      { "name": "Fighter aircraft", "code": "25131703" },
      { "name": "Attack aircraft", "code": "25131704" },
      { "name": "Target or reconnaissance drones", "code": "25131705" },
      { "name": "Military seaplanes", "code": "25131706" },
      { "name": "Reconnaissance or surveillance aircraft", "code": "25131707" },
      { "name": "Anti submarine aircraft", "code": "25131708" },
      { "name": "Military transport aircraft", "code": "25131709" },
      { "name": "Specialty aircraft", "code": "25131800" },
      { "name": "Airships", "code": "25131801" },
      { "name": "Model aircraft", "code": "25131802" },
      { "name": "Military rotary wing aircraft", "code": "25131900" },
      { "name": "Military transport helicopters", "code": "25131902" },
      { "name": "Attack helicopters", "code": "25131903" },
      { "name": "Reconnaissance helicopters", "code": "25131904" },
      { "name": "Anti submarine helicopters", "code": "25131905" },
      { "name": "Tilt rotor wing aircraft", "code": "25131906" },
      { "name": "Recreational aircraft", "code": "25132000" },
      { "name": "Hang gliders", "code": "25132001" },
      { "name": "Hot air balloons", "code": "25132002" },
      { "name": "Gliders", "code": "25132003" },
      { "name": "Paragliders", "code": "25132004" },
      { "name": "Ultra light aircraft", "code": "25132005" },
      { "name": "Spacecraft", "code": "25150000" },
      { "name": "Spaceships", "code": "25151500" },
      { "name": "Manned spacecraft", "code": "25151501" },
      { "name": "Spacecraft structures", "code": "25151502" },
      { "name": "Satellites", "code": "25151700" },
      { "name": "Communication satellites", "code": "25151701" },
      { "name": "Weather satellites", "code": "25151702" },
      { "name": "Military satellites", "code": "25151703" },
      { "name": "Scientific or research satellites", "code": "25151704" },
      { "name": "Navigation satellites", "code": "25151705" },
      { "name": "Geostationary satellites", "code": "25151706" },
      { "name": "Low earth orbit satellites", "code": "25151707" },
      { "name": "Sun synchronous orbit satellites", "code": "25151708" },
      { "name": "Geosynchronous satellites", "code": "25151709" },
      { "name": "Non motorized cycles", "code": "25160000" },
      { "name": "Pedal powered vehicles", "code": "25161500" },
      { "name": "Touring bicycles", "code": "25161501" },
      { "name": "Unicycles", "code": "25161502" },
      { "name": "Tricycles", "code": "25161503" },
      { "name": "Tandem bicycles", "code": "25161504" },
      { "name": "Mountain bicycles", "code": "25161505" },
      { "name": "Racing bicycles", "code": "25161506" },
      { "name": "Bicycles", "code": "25161507" },
      { "name": "Recumbent bicycles", "code": "25161508" },
      { "name": "Childrens bicycles", "code": "25161509" },
      { "name": "Four wheel bicycle or quadricycle", "code": "25161510" },
      { "name": "Transportation components and systems", "code": "25170000" },
      { "name": "Windshield wipers", "code": "25171500" },
      { "name": "Automotive windshield wipers", "code": "25171502" },
      { "name": "Locomotive windshield wipers", "code": "25171503" },
      { "name": "Marine windshield wipers", "code": "25171504" },
      { "name": "Truck windshield wipers", "code": "25171505" },
      { "name": "Windshield wiper pump", "code": "25171506" },
      { "name": "Wiper blades", "code": "25171507" },
      { "name": "Windshield wiper motor", "code": "25171508" },
      { "name": "Motorcycle windshield wiper", "code": "25171509" },
      { "name": "Defrosting and defogging systems", "code": "25171600" },
      {
        "name": "Automotive defrosting or defogging systems",
        "code": "25171602"
      },
      { "name": "Train defrosting or defogging systems", "code": "25171603" },
      { "name": "Motorcycle defroster", "code": "25171604" },
      { "name": "Braking systems and components", "code": "25171700" },
      { "name": "Automotive braking systems", "code": "25171702" },
      { "name": "Train braking systems", "code": "25171703" },
      { "name": "Drag chutes", "code": "25171704" },
      { "name": "Rotors", "code": "25171705" },
      { "name": "Brake calipers", "code": "25171706" },
      { "name": "Drum brake", "code": "25171707" },
      { "name": "Disc brake", "code": "25171708" },
      { "name": "Liquid cooled brake", "code": "25171709" },
      { "name": "Master cylinders", "code": "25171710" },
      { "name": "Slave cylinders", "code": "25171711" },
      { "name": "Drum brake shoes", "code": "25171712" },
      { "name": "Disc brake pads", "code": "25171713" },
      { "name": "Brake drum", "code": "25171714" },
      { "name": "Disc brake rotors", "code": "25171715" },
      { "name": "Brake lines", "code": "25171716" },
      { "name": "Brake pistons", "code": "25171717" },
      { "name": "Brake repair kits", "code": "25171718" },
      { "name": "Brake booster", "code": "25171719" },
      { "name": "Dual circuit brake", "code": "25171720" },
      { "name": "Mechanical or parking brake", "code": "25171721" },
      { "name": "Brake bleed screw cap", "code": "25171722" },
      { "name": "Antilock braking system ABS", "code": "25171723" },
      { "name": "Antilock braking system ABS coil", "code": "25171724" },
      { "name": "Band brake", "code": "25171725" },
      { "name": "Motorcycle braking system", "code": "25171726" },
      { "name": "Wheels and wheel trims", "code": "25171900" },
      { "name": "Automobile rims or wheels", "code": "25171901" },
      { "name": "Train wheels", "code": "25171902" },
      { "name": "Truck rims or wheels", "code": "25171903" },
      { "name": "Tire valves", "code": "25171905" },
      { "name": "Automotive wheel cover", "code": "25171906" },
      { "name": "Railway sander support or base", "code": "25171907" },
      { "name": "Railway sander", "code": "25171908" },
      { "name": "Suspension system components", "code": "25172000" },
      { "name": "Automobile suspension systems", "code": "25172001" },
      { "name": "Truck suspension systems", "code": "25172002" },
      { "name": "Truck shock absorbers", "code": "25172003" },
      { "name": "Automobile shock absorbers", "code": "25172004" },
      { "name": "Train suspension systems", "code": "25172005" },
      { "name": "Struts", "code": "25172007" },
      { "name": "Automotive bushings", "code": "25172009" },
      { "name": "Sway bars", "code": "25172010" },
      { "name": "Shock absorber", "code": "25172011" },
      { "name": "Slip plate", "code": "25172012" },
      { "name": "Automotive control arm", "code": "25172013" },
      { "name": "Active suspension system", "code": "25172014" },
      { "name": "Motorcycle suspension system", "code": "25172015" },
      {
        "name": "Vehicle safety and security systems and components",
        "code": "25172100"
      },
      { "name": "Airbags", "code": "25172101" },
      { "name": "Seatbelts", "code": "25172104" },
      { "name": "Collision avoidance systems", "code": "25172105" },
      { "name": "Impact sensing systems", "code": "25172106" },
      { "name": "Head restraints", "code": "25172108" },
      { "name": "Air bag propellant canisters", "code": "25172109" },
      { "name": "Vehicle horns", "code": "25172110" },
      { "name": "Remote locking system", "code": "25172111" },
      { "name": "Vehicle stability control systems", "code": "25172112" },
      { "name": "Vehicle traction control systems", "code": "25172113" },
      { "name": "Wheel clamps", "code": "25172114" },
      { "name": "Central locking system", "code": "25172115" },
      { "name": "Tire chains", "code": "25172116" },
      { "name": "Airbag cover assembly", "code": "25172117" },
      { "name": "Airbag fabric assembly", "code": "25172118" },
      { "name": "Airbag fabric", "code": "25172119" },
      { "name": "Airbag inflator", "code": "25172120" },
      { "name": "Driver airbag inflator", "code": "25172121" },
      { "name": "Passenger airbag inflator", "code": "25172122" },
      { "name": "Knee airbag inflator", "code": "25172123" },
      { "name": "Side airbag inflator", "code": "25172124" },
      { "name": "Curtain airbag inflator", "code": "25172125" },
      { "name": "Seat belt webbing", "code": "25172126" },
      { "name": "Seat bladder", "code": "25172127" },
      { "name": "Seat belt tension sensor BTS", "code": "25172128" },
      { "name": "Driver information system", "code": "25172129" },
      { "name": "Vehicle keyless entry control or fob", "code": "25172130" },
      { "name": "Immobilizer", "code": "25172131" },
      { "name": "Vehicle doors", "code": "25172200" },
      { "name": "Removable automotive doors", "code": "25172201" },
      { "name": "Automotive doors", "code": "25172203" },
      { "name": "Roll up truck doors", "code": "25172204" },
      { "name": "Tailgates or liftgates", "code": "25172205" },
      { "name": "Train door and components", "code": "25172206" },
      { "name": "Vehicle windows and windshields", "code": "25172300" },
      { "name": "Automotive windshields", "code": "25172301" },
      { "name": "Automotive windows", "code": "25172303" },
      { "name": "Window lift or regulator assembly", "code": "25172304" },
      { "name": "Windshield for two wheeled vehicle", "code": "25172305" },
      { "name": "Fuel tanks and systems", "code": "25172400" },
      { "name": "Hybrid fuel storage systems", "code": "25172404" },
      { "name": "Fuel injection systems", "code": "25172405" },
      { "name": "Fuel tanks", "code": "25172406" },
      { "name": "Breather elements", "code": "25172407" },
      { "name": "Fuel or oil caps", "code": "25172408" },
      { "name": "Fuel module", "code": "25172409" },
      { "name": "Fuel injector o ring", "code": "25172410" },
      { "name": "Fuel tank float", "code": "25172411" },
      { "name": "Tires and tire tubes", "code": "25172500" },
      { "name": "Automobile tire tubes", "code": "25172502" },
      { "name": "Heavy truck tires", "code": "25172503" },
      { "name": "Automobile or light truck tires", "code": "25172504" },
      { "name": "Bicycle tubes", "code": "25172505" },
      { "name": "Bicycle tires", "code": "25172506" },
      { "name": "Tire cord", "code": "25172507" },
      { "name": "Tire treads", "code": "25172508" },
      { "name": "Heavy truck tire tube", "code": "25172509" },
      { "name": "Foam tire", "code": "25172510" },
      { "name": "Tire repair kit", "code": "25172511" },
      { "name": "Motorcycle tire", "code": "25172512" },
      { "name": "Retreaded pneumatic rubber tire", "code": "25172513" },
      { "name": "Motorcycle tire inner tube", "code": "25172514" },
      { "name": "Vehicle trim and exterior covering", "code": "25172600" },
      { "name": "Automotive trim", "code": "25172601" },
      { "name": "Automotive fenders", "code": "25172602" },
      { "name": "Vehicle bumpers", "code": "25172603" },
      { "name": "Vehicle mirrors", "code": "25172604" },
      { "name": "Vehicle grilles", "code": "25172605" },
      { "name": "Vehicle hoods", "code": "25172606" },
      { "name": "Vehicle side panels", "code": "25172607" },
      { "name": "Fascias", "code": "25172608" },
      { "name": "Mud flap", "code": "25172609" },
      { "name": "License plate", "code": "25172610" },
      { "name": "Vehicle bumper guard", "code": "25172611" },
      { "name": "Motorcycle side sill and stirrup", "code": "25172612" },
      { "name": "Vehicle tire carrier", "code": "25172613" },
      { "name": "Environmental control systems", "code": "25172700" },
      { "name": "Space environmental control systems", "code": "25172702" },
      { "name": "Marine environmental control systems", "code": "25172703" },
      { "name": "Vehicle climate control systems", "code": "25172704" },
      { "name": "Oil fence", "code": "25172705" },
      { "name": "Silt protector", "code": "25172706" },
      { "name": "Oil skimmer", "code": "25172707" },
      { "name": "Algae control machine", "code": "25172708" },
      { "name": "Vehicle air conditioner", "code": "25172709" },
      { "name": "Vehicle heater", "code": "25172710" },
      { "name": "Vehicle air purifier", "code": "25172711" },
      { "name": "Hydraulic systems and components", "code": "25172800" },
      { "name": "Automotive hydraulic systems", "code": "25172802" },
      { "name": "Marine hydraulic systems", "code": "25172803" },
      { "name": "Exterior vehicle lighting", "code": "25172900" },
      { "name": "Exterior automobile lighting", "code": "25172901" },
      { "name": "Exterior railcar lighting", "code": "25172903" },
      { "name": "Exterior ship or boat lighting", "code": "25172904" },
      { "name": "Headlamp wiper or washer systems", "code": "25172905" },
      { "name": "Reflectors", "code": "25172906" },
      { "name": "Vehicle headlight", "code": "25172907" },
      { "name": "Vehicle rear light", "code": "25172908" },
      { "name": "Motorcycle exterior lighting", "code": "25172909" },
      { "name": "Interior vehicle lighting", "code": "25173000" },
      { "name": "Interior automobile lighting", "code": "25173001" },
      { "name": "Interior railcar lighting", "code": "25173003" },
      { "name": "Interior ship or boat lighting", "code": "25173004" },
      { "name": "Light plate assemblies", "code": "25173005" },
      {
        "name": "Uncoated vehicle interior lighting lense",
        "code": "25173006"
      },
      { "name": "Coated vehicle interior lighting lense", "code": "25173007" },
      { "name": "Vehicle light pipe", "code": "25173008" },
      {
        "name": "Location and navigation systems and components",
        "code": "25173100"
      },
      { "name": "Vehicular global positioning system GPS", "code": "25173107" },
      { "name": "Vehicle navigation systems", "code": "25173108" },
      { "name": "Master control systems", "code": "25173300" },
      { "name": "Automotive computer systems", "code": "25173303" },
      { "name": "Electronic ignition systems", "code": "25173304" },
      { "name": "Exhaust and emission controls", "code": "25173700" },
      { "name": "Catalytic converters", "code": "25173701" },
      { "name": "Mufflers or resonators", "code": "25173702" },
      { "name": "Exhaust manifolds", "code": "25173703" },
      { "name": "Muffler adapters", "code": "25173704" },
      { "name": "Spark arresters", "code": "25173705" },
      { "name": "Catalytic mat", "code": "25173706" },
      { "name": "Catalytic substrate", "code": "25173707" },
      { "name": "Catalytic waste coat and solution", "code": "25173708" },
      { "name": "Drivetrain systems", "code": "25173800" },
      { "name": "Driving axles", "code": "25173801" },
      { "name": "Non driving axles", "code": "25173802" },
      { "name": "Axle housings", "code": "25173803" },
      { "name": "Axle spindles", "code": "25173804" },
      { "name": "Differentials", "code": "25173805" },
      { "name": "Constant velocity joints", "code": "25173806" },
      { "name": "Axle shafts", "code": "25173807" },
      { "name": "Axle repair kits", "code": "25173808" },
      { "name": "Axle hubs", "code": "25173809" },
      { "name": "Universal joints", "code": "25173810" },
      { "name": "Drive shafts", "code": "25173811" },
      { "name": "Manual transmissions", "code": "25173812" },
      { "name": "Automatic transmissions", "code": "25173813" },
      { "name": "Clutch cables", "code": "25173815" },
      { "name": "Hydraulic clutch parts", "code": "25173816" },
      { "name": "Drivetrain chains", "code": "25173817" },
      { "name": "Propeller or prop shaft", "code": "25173818" },
      { "name": "Marine propeller drive shaft", "code": "25173819" },
      { "name": "Continuously variable transmission", "code": "25173820" },
      { "name": "Transfer case assembly", "code": "25173821" },
      { "name": "Gear shift pedal for motorcycle", "code": "25173822" },
      { "name": "Electrical components", "code": "25173900" },
      { "name": "Ignition", "code": "25173901" },
      { "name": "Engine starter motor", "code": "25173902" },
      {
        "name": "Vehicle interior die cut electrical and shielding component",
        "code": "25173903"
      },
      { "name": "Ignition system for motorcycle", "code": "25173904" },
      { "name": "Engine coolant system", "code": "25174000" },
      { "name": "Fan", "code": "25174001" },
      { "name": "Engine radiators", "code": "25174002" },
      { "name": "Radiator caps", "code": "25174003" },
      { "name": "Engine coolant", "code": "25174004" },
      { "name": "Vehicle gauge indicator pointer", "code": "25174005" },
      { "name": "Cooling water hose assembly", "code": "25174006" },
      { "name": "Thermo switch ventilator", "code": "25174007" },
      {
        "name": "Water pump for internal combustion engines",
        "code": "25174008"
      },
      { "name": "Roof systems", "code": "25174100" },
      { "name": "Emergency vehicle exits", "code": "25174101" },
      { "name": "Permanent convertible roof tops", "code": "25174102" },
      { "name": "Removable hard roof tops", "code": "25174103" },
      { "name": "Removable soft roof tops", "code": "25174104" },
      { "name": "Roof rack systems", "code": "25174105" },
      { "name": "Sunroofs or moonroofs", "code": "25174106" },
      { "name": "Wind deflectors", "code": "25174107" },
      { "name": "Steering system", "code": "25174200" },
      { "name": "Steering sacks", "code": "25174201" },
      { "name": "Steering suspension", "code": "25174202" },
      { "name": "Ball joint", "code": "25174203" },
      { "name": "Power steering system", "code": "25174204" },
      { "name": "Tie Rods", "code": "25174205" },
      { "name": "Drag link", "code": "25174206" },
      { "name": "Steering linkage", "code": "25174207" },
      { "name": "Hublock", "code": "25174208" },
      { "name": "Pinions", "code": "25174209" },
      { "name": "Power steering tuning cable", "code": "25174210" },
      { "name": "Steering wheels", "code": "25174211" },
      { "name": "Steering column assemblies", "code": "25174212" },
      { "name": "Steering cylinder assemblies", "code": "25174213" },
      { "name": "Wood steering wheel", "code": "25174214" },
      { "name": "Leather steering wheel", "code": "25174215" },
      { "name": "Steering knuckle arm", "code": "25174216" },
      { "name": "Vehicle steering gear", "code": "25174217" },
      { "name": "Motorcycle handle and grips", "code": "25174218" },
      { "name": "Vehicle interior systems", "code": "25174400" },
      { "name": "Bezels", "code": "25174401" },
      { "name": "Consoles", "code": "25174402" },
      { "name": "Door panels", "code": "25174403" },
      { "name": "Headliners", "code": "25174404" },
      { "name": "Instrument clusters", "code": "25174405" },
      { "name": "Instrument panels", "code": "25174406" },
      { "name": "Pedals", "code": "25174407" },
      { "name": "Power ports or lighters", "code": "25174408" },
      { "name": "Sun visors", "code": "25174409" },
      { "name": "Vehicle sound systems and components", "code": "25174410" },
      { "name": "Rubber key pad", "code": "25174411" },
      { "name": "Vehicle decorative applique", "code": "25174412" },
      { "name": "Vehicle interior badge or emblem", "code": "25174413" },
      { "name": "Tachograph", "code": "25174414" },
      { "name": "Vehicle oil pressure gauge", "code": "25174415" },
      { "name": "Vehicle cigarette lighter", "code": "25174416" },
      { "name": "Vehicle combination switch", "code": "25174417" },
      { "name": "Vehicle floor mat", "code": "25174418" },
      { "name": "Vehicle audio video system", "code": "25174419" },
      { "name": "Vehicle ash tray", "code": "25174420" },
      { "name": "Vehicle curtain", "code": "25174421" },
      { "name": "Vehicle seating systems", "code": "25174600" },
      { "name": "Seat covers", "code": "25174601" },
      { "name": "Seat cushions or bolsters", "code": "25174602" },
      { "name": "Seat frames", "code": "25174603" },
      { "name": "Helm or boat seating", "code": "25174604" },
      { "name": "Motorcycle seat", "code": "25174605" },
      {
        "name": "Non motorized cycle components and accessories",
        "code": "25174700"
      },
      { "name": "Cycle pedals", "code": "25174701" },
      { "name": "Bicycle spoke", "code": "25174702" },
      { "name": "Motorcycle tail box", "code": "25174703" },
      { "name": "Basket for two wheeled vehicle", "code": "25174704" },
      {
        "name": "Mail carrying box for two wheeled vehicle",
        "code": "25174705"
      },
      {
        "name": "Specialized vehicle systems and components",
        "code": "25174800"
      },
      { "name": "Truck suction stabilizer or ground jack", "code": "25174801" },
      { "name": "Boom for wrecker truck or tow truck", "code": "25174802" },
      { "name": "Towing hook", "code": "25174803" },
      { "name": "Vehicle outrigger", "code": "25174804" },
      { "name": "Vehicle sand spreader", "code": "25174805" },
      { "name": "Fitted vehicle body cover", "code": "25174806" },
      { "name": "Vehicle hinge", "code": "25174807" },
      { "name": "Vehicle accessories storage box", "code": "25174808" },
      { "name": "Vehicle ladder", "code": "25174809" },
      { "name": "Vehicle jack", "code": "25174810" },
      { "name": "Trunk claw", "code": "25174811" },
      {
        "name": "Vehicle vibration dampeners and isolators",
        "code": "25174900"
      },
      {
        "name": "Molded vehicle vibration dampener and isolator",
        "code": "25174901"
      },
      {
        "name": "Extruded vehicle vibration dampener and isolator",
        "code": "25174902"
      },
      {
        "name": "Die cut vehicle vibration dampener and isolator",
        "code": "25174903"
      },
      { "name": "Electric vehicle charging systems", "code": "25175000" },
      { "name": "Electric vehicle portable charger", "code": "25175001" },
      { "name": "Electric vehicle charging station", "code": "25175002" },
      { "name": "Electric vehicle charging kit", "code": "25175003" },
      { "name": "Transportation repair parts kits", "code": "25175100" },
      { "name": "Motor vehicle repair part kit", "code": "25175101" },
      { "name": "Motorcycle repair part kit", "code": "25175102" },
      { "name": "Marine transport repair part kits", "code": "25175103" },
      { "name": "Railway repair part kits", "code": "25175104" },
      { "name": "Aircraft repair part kits", "code": "25175105" },
      { "name": "Non motorized cycle repair part kit", "code": "25175106" },
      { "name": "Spacecraft repair part kits", "code": "25175107" },
      { "name": "Vehicle bodies and trailers", "code": "25180000" },
      { "name": "Automotive chassis", "code": "25181600" },
      { "name": "Automobile chassis", "code": "25181601" },
      { "name": "Truck chassis", "code": "25181602" },
      { "name": "Motorcycle frames", "code": "25181603" },
      { "name": "Cargo truck body", "code": "25181604" },
      { "name": "Dump truck body", "code": "25181605" },
      { "name": "Trailer body", "code": "25181606" },
      { "name": "Tractor cab", "code": "25181607" },
      { "name": "Vehicle frame", "code": "25181608" },
      { "name": "Bulk trailer", "code": "25181609" },
      { "name": "Air cargo trailer", "code": "25181610" },
      { "name": "Dolly trailer", "code": "25181611" },
      { "name": "Agricultural trailer", "code": "25181612" },
      { "name": "Automotive chassis fitted with engine", "code": "25181613" },
      { "name": "Product and material trailers", "code": "25181700" },
      { "name": "Container trailers", "code": "25181701" },
      { "name": "Flatbed trailers", "code": "25181702" },
      { "name": "Livestock trailers", "code": "25181703" },
      {
        "name": "Non temperature controlled tanker trailers",
        "code": "25181704"
      },
      { "name": "Temperature controlled tanker trailers", "code": "25181705" },
      {
        "name": "Temperature controlled container trailers",
        "code": "25181706"
      },
      { "name": "Automobile carrier trailers", "code": "25181707" },
      { "name": "Trailer hitches", "code": "25181708" },
      { "name": "Skip loader", "code": "25181709" },
      { "name": "Trailer end plates", "code": "25181710" },
      { "name": "Snowmobile trailer", "code": "25181711" },
      { "name": "Motorcycle trailer", "code": "25181712" },
      { "name": "Boat Trailer", "code": "25181713" },
      { "name": "Semi trailer", "code": "25181714" },
      { "name": "Dump trailer", "code": "25181715" },
      { "name": "Chassis trailer", "code": "25181716" },
      { "name": "Low bed trailer", "code": "25181717" },
      { "name": "Cable reel trailer", "code": "25181718" },
      { "name": "Refrigerated trailer", "code": "25181719" },
      { "name": "Trailer and semi-trailer parts", "code": "25181720" },
      { "name": "Transportation services equipment", "code": "25190000" },
      {
        "name": "Air transportation support systems and equipment",
        "code": "25191500"
      },
      { "name": "Ground support training systems", "code": "25191501" },
      {
        "name": "Ground support test or maintenance systems",
        "code": "25191502"
      },
      {
        "name": "Integrated maintenance information systems",
        "code": "25191503"
      },
      { "name": "Aircraft flight simulators or trainers", "code": "25191504" },
      { "name": "Aircraft cargo handling equipment", "code": "25191505" },
      { "name": "Aircraft refueling equipment", "code": "25191506" },
      { "name": "Aircraft deicing equipment", "code": "25191507" },
      { "name": "Jetways", "code": "25191508" },
      { "name": "Aircraft pushback or tow tractors", "code": "25191509" },
      { "name": "Ground power units for aircraft", "code": "25191510" },
      { "name": "Lavatory service equipment for aircraft", "code": "25191511" },
      { "name": "Airstairs or stair trucks", "code": "25191512" },
      { "name": "Ground support vehicle maintenance kit", "code": "25191513" },
      { "name": "Aircraft towbar", "code": "25191514" },
      { "name": "Ground controlled approach system", "code": "25191515" },
      { "name": "Aircraft air start unit", "code": "25191516" },
      {
        "name": "Aircraft air conditioning and heating truck",
        "code": "25191517"
      },
      { "name": "Aeronautical sensor test equipment", "code": "25191518" },
      { "name": "Aircraft towing adapter", "code": "25191519" },
      { "name": "Environmental test chamber", "code": "25191520" },
      { "name": "Retractable hook cable support system", "code": "25191521" },
      { "name": "Aircraft engine maintenance set", "code": "25191522" },
      { "name": "Airplane baggage tug or tractor", "code": "25191523" },
      { "name": "Airfreight conveyor truck", "code": "25191524" },
      { "name": "Aircraft refueling truck", "code": "25191525" },
      {
        "name": "Space transportation support systems and equipment",
        "code": "25191600"
      },
      { "name": "Spacecraft flight simulators", "code": "25191601" },
      { "name": "Payload delivery systems for spacecraft", "code": "25191602" },
      { "name": "Liquid launch vehicles", "code": "25191603" },
      { "name": "Solid launch vehicles", "code": "25191604" },
      { "name": "Spacecraft service modules", "code": "25191605" },
      { "name": "Vehicle servicing equipment", "code": "25191700" },
      { "name": "Wheel balancing equipment", "code": "25191701" },
      { "name": "Wheel alignment equipment", "code": "25191702" },
      { "name": "Tire changing machines", "code": "25191703" },
      { "name": "Engine or vehicle stands", "code": "25191704" },
      { "name": "Engine piston and rod scale", "code": "25191705" },
      { "name": "Railway rolling stock lifting jack", "code": "25191706" },
      {
        "name": "Railway lift table for engine and component",
        "code": "25191707"
      },
      { "name": "Carburetor balancer", "code": "25191708" },
      { "name": "Power adjuster", "code": "25191709" },
      { "name": "Valve lapper", "code": "25191710" },
      { "name": "Valve refacer", "code": "25191711" },
      { "name": "Valve seat cutter", "code": "25191712" },
      { "name": "Antifreeze recycling device", "code": "25191713" },
      { "name": "Brake drum lathe", "code": "25191714" },
      { "name": "Brake shoe grinder", "code": "25191715" },
      { "name": "Brake spring pliers", "code": "25191716" },
      { "name": "Vehicle body straightening machine", "code": "25191717" },
      { "name": "Vehicle service creeper", "code": "25191718" },
      { "name": "Wheel nut or lug wrench", "code": "25191719" },
      { "name": "Spark plug wrench", "code": "25191720" },
      { "name": "Spark plug cleaner", "code": "25191721" },
      { "name": "Engine tool kit", "code": "25191722" },
      { "name": "Piston heater", "code": "25191723" },
      { "name": "Automatic transmission rebuild kit", "code": "25191724" },
      { "name": "Automotive boring machine", "code": "25191725" },
      { "name": "Automotive honing machine", "code": "25191726" },
      { "name": "Connecting rod aligner", "code": "25191727" },
      { "name": "Crankshaft balancer", "code": "25191728" },
      { "name": "Crankshaft grinder", "code": "25191729" },
      { "name": "Timing light", "code": "25191730" },
      { "name": "Torque converter cleaner", "code": "25191731" },
      { "name": "Piston vise", "code": "25191732" },
      { "name": "Piston grinder", "code": "25191733" },
      { "name": "Valve spring compressor", "code": "25191734" },
      { "name": "Piston ring compressor", "code": "25191735" },
      { "name": "Vehicle driving simulator", "code": "25191736" },
      { "name": "Automatic tire inflator", "code": "25191737" },
      { "name": "Tire spreader", "code": "25191738" },
      { "name": "Steering gear simulation tester", "code": "25191739" },
      { "name": "Vehicle washing machine", "code": "25191740" },
      { "name": "Railway washing machine", "code": "25191741" },
      { "name": "Vehicle parts washing machine", "code": "25191742" },
      { "name": "Railway component washing machine", "code": "25191743" },
      { "name": "Tire retreading machine", "code": "25191744" },
      { "name": "Vehicle testing and measuring equipment", "code": "25191800" },
      { "name": "Growler tester", "code": "25191801" },
      { "name": "Dwell tachometer", "code": "25191802" },
      { "name": "Diesel smoke tester", "code": "25191803" },
      { "name": "Radiator tester", "code": "25191804" },
      { "name": "Engine distributor tester", "code": "25191805" },
      { "name": "Battery hydrometer", "code": "25191806" },
      { "name": "Bearing oil leakage detector", "code": "25191807" },
      { "name": "Brake tester", "code": "25191808" },
      { "name": "Side slip tester", "code": "25191809" },
      { "name": "Chassis dynamometer", "code": "25191810" },
      { "name": "Speedometer tester", "code": "25191811" },
      { "name": "Stall tester", "code": "25191812" },
      { "name": "Spark plug gap gauge", "code": "25191813" },
      { "name": "Spark plug tester", "code": "25191814" },
      { "name": "Armature tester", "code": "25191815" },
      { "name": "Compression type spring tester", "code": "25191816" },
      { "name": "Compression pressure gauge", "code": "25191817" },
      { "name": "Air cleaner element tester", "code": "25191818" },
      {
        "name": "Anti lock braking system ABS simulation tester",
        "code": "25191819"
      },
      { "name": "Engine multimeter", "code": "25191820" },
      { "name": "Engine simulation tester", "code": "25191821" },
      { "name": "Engine injector tester", "code": "25191822" },
      { "name": "Engine test bed", "code": "25191823" },
      { "name": "Engine tune up tester", "code": "25191824" },
      { "name": "Fuel consumption meter", "code": "25191825" },
      { "name": "Oil leakage tester", "code": "25191826" },
      {
        "name": "Electronic control suspension ECS simulation tester",
        "code": "25191827"
      },
      { "name": "Automatic transmission tester", "code": "25191828" },
      { "name": "Electronic control inspection system", "code": "25191829" },
      { "name": "Headlight tester", "code": "25191830" },
      { "name": "Vehicle window regulator tester", "code": "25191831" },
      { "name": "Vacuum gauge for vehicle servicing", "code": "25191832" },
      { "name": "Camber caster gauge", "code": "25191833" },
      { "name": "Coil condenser resistance or ohm tester", "code": "25191834" },
      { "name": "Tire pressure gauge", "code": "25191835" },
      { "name": "Toe in gauge", "code": "25191836" },
      { "name": "Turning radius gauge", "code": "25191837" },
      { "name": "Automotive vehicle inspection system", "code": "25191838" },
      { "name": "Odometer", "code": "25191839" },
      {
        "name": "Aerospace systems and components and equipment",
        "code": "25200000"
      },
      { "name": "Aircraft fuselage and components", "code": "25201500" },
      { "name": "Aircraft spoilers", "code": "25201501" },
      { "name": "Aircraft fins", "code": "25201502" },
      { "name": "Aircraft horizontal stabilizers", "code": "25201503" },
      { "name": "Aircraft canards", "code": "25201504" },
      { "name": "Aircraft slats", "code": "25201505" },
      { "name": "Aircraft flaps or flap drives", "code": "25201506" },
      { "name": "Aircraft rudders", "code": "25201507" },
      { "name": "Aircraft elevators", "code": "25201508" },
      { "name": "Aircraft ailerons", "code": "25201509" },
      { "name": "Aircraft propellers", "code": "25201510" },
      { "name": "Aircraft wings", "code": "25201511" },
      { "name": "Aircraft fuselages", "code": "25201512" },
      { "name": "Aircraft radomes", "code": "25201513" },
      { "name": "Aircraft rotors", "code": "25201514" },
      { "name": "Aircraft lift fans", "code": "25201515" },
      { "name": "Aircraft canopies", "code": "25201516" },
      { "name": "Aircraft furnishings", "code": "25201517" },
      { "name": "Aircraft doublers", "code": "25201518" },
      { "name": "Aircraft ribs", "code": "25201519" },
      { "name": "Aircraft spars", "code": "25201520" },
      { "name": "Trim tab for rotor blade", "code": "25201521" },
      { "name": "Helicopter universal pod", "code": "25201522" },
      {
        "name": "Aerospace location and navigation systems and components",
        "code": "25201600"
      },
      {
        "name": "Aircraft digital altitude control systems",
        "code": "25201601"
      },
      { "name": "Aircraft navigation beacons", "code": "25201602" },
      { "name": "Air to ground terrain following systems", "code": "25201603" },
      { "name": "Aircraft guidance systems", "code": "25201604" },
      { "name": "Aircraft steering controls", "code": "25201605" },
      { "name": "Spacecraft altitude control systems", "code": "25201606" },
      { "name": "Flight communications related systems", "code": "25201700" },
      { "name": "Aircraft communication systems", "code": "25201701" },
      { "name": "Flight data recorders", "code": "25201702" },
      { "name": "Aircraft countermeasures", "code": "25201703" },
      { "name": "Encryption or decryption systems", "code": "25201704" },
      { "name": "Aircraft telemetry systems", "code": "25201705" },
      { "name": "Aircraft interface electronics", "code": "25201706" },
      { "name": "Aircraft gyros", "code": "25201707" },
      { "name": "Aircraft cameras", "code": "25201708" },
      { "name": "Aircraft probes or sensors", "code": "25201709" },
      { "name": "Aircraft waveguides", "code": "25201710" },
      { "name": "Aircraft master control systems", "code": "25201800" },
      { "name": "Flight computer systems", "code": "25201801" },
      { "name": "Spacecraft command modules", "code": "25201802" },
      { "name": "Aircraft emergency systems", "code": "25201900" },
      {
        "name": "Aircraft fire control or extinguishing systems",
        "code": "25201901"
      },
      { "name": "Aircraft escape or ejection systems", "code": "25201902" },
      { "name": "Aircraft warning systems", "code": "25201903" },
      { "name": "Parachutes", "code": "25201904" },
      { "name": "Aircraft power systems", "code": "25202000" },
      { "name": "Spacecraft solar cells", "code": "25202001" },
      { "name": "Spacecraft solar arrays", "code": "25202002" },
      { "name": "Aircraft power supply units", "code": "25202003" },
      { "name": "Auxiliary power unit systems APUs", "code": "25202004" },
      { "name": "Flight instrumentation", "code": "25202100" },
      { "name": "Aerospace cockpit indicators", "code": "25202101" },
      { "name": "Aerospace cockpit gauges", "code": "25202102" },
      { "name": "Aerospace cockpit display panels", "code": "25202103" },
      { "name": "Aerospace cockpit switch panels", "code": "25202104" },
      { "name": "Aerospace head up display HUDs", "code": "25202105" },
      { "name": "Aircraft landing and braking systems", "code": "25202200" },
      { "name": "Aircraft braking systems", "code": "25202201" },
      { "name": "Aircraft drag chutes", "code": "25202202" },
      { "name": "Aircraft wheels", "code": "25202203" },
      { "name": "Landing gear assemblies", "code": "25202204" },
      { "name": "Aircraft tires", "code": "25202205" },
      { "name": "Aircraft anti skid controls", "code": "25202206" },
      { "name": "Landing gear drag brace", "code": "25202207" },
      { "name": "Aircraft passenger restraints", "code": "25202300" },
      { "name": "Aircraft lapbelts", "code": "25202301" },
      { "name": "Aircraft harness restraints", "code": "25202302" },
      { "name": "Aircraft fuel tanks and systems", "code": "25202400" },
      { "name": "Aircraft internal fuel tanks", "code": "25202401" },
      { "name": "Aircraft fuel drop tanks", "code": "25202402" },
      { "name": "Aircraft propellant tanks", "code": "25202403" },
      { "name": "Aircraft hybrid fuel storage systems", "code": "25202404" },
      { "name": "Aircraft fuel management systems", "code": "25202405" },
      { "name": "Postboosters", "code": "25202406" },
      { "name": "Aircraft equipment", "code": "25202500" },
      { "name": "Aircraft hydraulic systems", "code": "25202501" },
      { "name": "Exterior aircraft lighting", "code": "25202502" },
      { "name": "Interior aircraft lighting", "code": "25202503" },
      { "name": "Aircraft windshield wipers", "code": "25202504" },
      {
        "name": "Aircraft onboard defrosting or defogging systems",
        "code": "25202505"
      },
      { "name": "Aircraft doors", "code": "25202506" },
      { "name": "Aircraft windows", "code": "25202507" },
      { "name": "Aircraft windshields", "code": "25202508" },
      { "name": "Aircraft shock mounts", "code": "25202509" },
      { "name": "Aircraft slip ring assemblies", "code": "25202510" },
      {
        "name": "Aircraft environmental control systems and components",
        "code": "25202600"
      },
      { "name": "Aircraft environment controllers", "code": "25202601" },
      { "name": "Aircraft environment regulators", "code": "25202602" },
      { "name": "Aircraft cooling turbines", "code": "25202603" },
      { "name": "Aircraft cooling fans", "code": "25202604" },
      { "name": "Aircraft heat exchangers", "code": "25202605" },
      { "name": "Aircraft water separators", "code": "25202606" },
      { "name": "Aircraft oxygen equipment", "code": "25202607" },
      { "name": "Aircraft accumulators", "code": "25202700" },
      { "name": "Hydraulic aircraft accumulators", "code": "25202701" },
      { "name": "Pneumatic aircraft accumulators", "code": "25202702" }
    ]
  },
  {
    "name": "Power Generation and Distribution Machinery and Accessories",
    "code": "26000000",
    "subcategories": [
      { "name": "Power sources", "code": "26100000" },
      { "name": "Electric alternating current AC motors", "code": "26101100" },
      { "name": "Automotive motor AC", "code": "26101101" },
      { "name": "Brake motor AC", "code": "26101102" },
      { "name": "Farm duty motor AC", "code": "26101103" },
      { "name": "Heating and cooling system motor AC", "code": "26101105" },
      { "name": "Inverter motor AC", "code": "26101106" },
      { "name": "Pump motor AC", "code": "26101107" },
      { "name": "Compressor motor AC", "code": "26101108" },
      { "name": "Synchronous motor AC", "code": "26101109" },
      { "name": "Multispeed motor AC", "code": "26101110" },
      { "name": "Pressure washer motor AC", "code": "26101111" },
      { "name": "General purpose motor AC", "code": "26101112" },
      { "name": "Submersible motor AC", "code": "26101113" },
      { "name": "Induction motor AC", "code": "26101114" },
      { "name": "Multi phase motor AC", "code": "26101115" },
      { "name": "Single phase motor AC", "code": "26101116" },
      { "name": "Vertical hollowshaft motor AC", "code": "26101117" },
      { "name": "Electric direct current DC motors", "code": "26101200" },
      { "name": "Shunt wound motor DC", "code": "26101201" },
      { "name": "Step motor DC", "code": "26101202" },
      { "name": "Coreless motor DC", "code": "26101203" },
      { "name": "Series wound motor DC", "code": "26101204" },
      { "name": "Servo motor DC", "code": "26101205" },
      { "name": "Limited angle torque motor DC", "code": "26101206" },
      { "name": "Linear motor DC", "code": "26101207" },
      { "name": "Permanent magnet motor DC", "code": "26101208" },
      { "name": "Brushless motor DC", "code": "26101209" },
      { "name": "Compound wound motor DC", "code": "26101210" },
      { "name": "Ultrasonic or vibration motor", "code": "26101211" },
      { "name": "Spindle motor", "code": "26101212" },
      { "name": "Non electric motors", "code": "26101300" },
      { "name": "Air motor", "code": "26101301" },
      { "name": "Alternator", "code": "26101302" },
      { "name": "Dynamotor", "code": "26101303" },
      { "name": "Hydraulic motor", "code": "26101304" },
      { "name": "Liquid rocket motor", "code": "26101306" },
      { "name": "Solid rocket motor", "code": "26101309" },
      { "name": "Torque motor", "code": "26101310" },
      { "name": "Universal motor", "code": "26101311" },
      { "name": "Axial piston motor", "code": "26101312" },
      { "name": "Radial piston motor", "code": "26101313" },
      { "name": "Motor or generator components", "code": "26101400" },
      { "name": "Armature", "code": "26101401" },
      { "name": "Motor base adapter", "code": "26101402" },
      { "name": "Motor brake", "code": "26101403" },
      { "name": "Motor brush", "code": "26101404" },
      { "name": "Motor coil", "code": "26101405" },
      { "name": "Motor mount or base", "code": "26101406" },
      { "name": "Motor pole", "code": "26101408" },
      { "name": "Motor rotor", "code": "26101409" },
      { "name": "Motor stator", "code": "26101410" },
      { "name": "Motor lamination", "code": "26101411" },
      { "name": "Motor repair kit", "code": "26101412" },
      { "name": "Motor casing or cover", "code": "26101413" },
      { "name": "Motor brush carrier", "code": "26101414" },
      { "name": "Motor commutator", "code": "26101415" },
      { "name": "Engines", "code": "26101500" },
      { "name": "Hydraulic engines", "code": "26101501" },
      { "name": "Pneumatic engines", "code": "26101502" },
      { "name": "Gas engines", "code": "26101503" },
      { "name": "Diesel engines", "code": "26101504" },
      { "name": "Steam engines", "code": "26101505" },
      { "name": "Turbine engines", "code": "26101506" },
      { "name": "Turbofan engines", "code": "26101507" },
      { "name": "Thermal engines", "code": "26101508" },
      { "name": "Hydroelectric engines", "code": "26101509" },
      { "name": "Rotary engines", "code": "26101510" },
      { "name": "Hydraulic turbine engines", "code": "26101511" },
      { "name": "Turboprop engines", "code": "26101512" },
      { "name": "Engine Repair Kit", "code": "26101513" },
      { "name": "Jet engine", "code": "26101514" },
      { "name": "Gasoline outboard motor", "code": "26101515" },
      { "name": "Engine components and accessories", "code": "26101700" },
      { "name": "Aircraft burners", "code": "26101701" },
      { "name": "Aircraft engine compressors", "code": "26101702" },
      { "name": "Aircraft engine diffusers", "code": "26101703" },
      { "name": "Engine mounts", "code": "26101704" },
      {
        "name": "Aircraft drive shafts power take offs or screw jacks",
        "code": "26101705"
      },
      { "name": "Aircraft transmission units", "code": "26101706" },
      { "name": "Balance shafts", "code": "26101707" },
      { "name": "Cam followers", "code": "26101708" },
      { "name": "Camshaft lifters", "code": "26101709" },
      { "name": "Carburetors", "code": "26101710" },
      { "name": "Connecting rods", "code": "26101711" },
      { "name": "Crankcase ventilation valves", "code": "26101712" },
      { "name": "Cylinder heads", "code": "26101713" },
      { "name": "Engine covers or pans", "code": "26101715" },
      { "name": "Engine forgings", "code": "26101716" },
      { "name": "Engine heaters", "code": "26101717" },
      { "name": "Engine ignition systems", "code": "26101718" },
      { "name": "Supercharger", "code": "26101719" },
      { "name": "Turbocharger", "code": "26101720" },
      { "name": "Engine pulleys", "code": "26101721" },
      { "name": "Fuel vapor canister", "code": "26101723" },
      { "name": "Glow plugs", "code": "26101724" },
      { "name": "Oil dip sticks or tubes", "code": "26101725" },
      { "name": "Oil strainers", "code": "26101726" },
      { "name": "Piston rings", "code": "26101727" },
      { "name": "Push rod tubes", "code": "26101728" },
      { "name": "Rocker arm balls", "code": "26101729" },
      { "name": "Rocker arm shafts", "code": "26101730" },
      { "name": "Rocker arms", "code": "26101731" },
      { "name": "Spark plugs", "code": "26101732" },
      { "name": "Carburetor jet", "code": "26101733" },
      { "name": "Carburetor diaphragms", "code": "26101734" },
      { "name": "Oil pan", "code": "26101735" },
      { "name": "Pistons", "code": "26101736" },
      { "name": "Timing chain", "code": "26101737" },
      { "name": "Intake manifolds", "code": "26101738" },
      { "name": "Fuel injectors", "code": "26101740" },
      { "name": "Engine sleeves", "code": "26101741" },
      { "name": "Fuel injection manifolds", "code": "26101742" },
      { "name": "Engine valves", "code": "26101743" },
      { "name": "Push rods", "code": "26101747" },
      { "name": "Engine flywheel", "code": "26101748" },
      { "name": "Crankshaft", "code": "26101749" },
      { "name": "Throttle", "code": "26101750" },
      { "name": "Electronic engine controls", "code": "26101751" },
      { "name": "Engine valve seat", "code": "26101754" },
      { "name": "Valve guide", "code": "26101755" },
      { "name": "Carburetor adapters", "code": "26101756" },
      { "name": "Spark plug fittings", "code": "26101757" },
      { "name": "Rocker arm adjusters", "code": "26101758" },
      { "name": "Starter adapters", "code": "26101759" },
      { "name": "Choke rods", "code": "26101760" },
      { "name": "Camshaft plugs", "code": "26101761" },
      { "name": "Engine component linkages", "code": "26101762" },
      { "name": "Freeze plugs", "code": "26101763" },
      { "name": "Cylinder liners", "code": "26101764" },
      { "name": "Vibration dampers", "code": "26101765" },
      { "name": "Governors", "code": "26101766" },
      { "name": "Turbine stator", "code": "26101767" },
      { "name": "Turbine shaft", "code": "26101768" },
      { "name": "Ignition coil", "code": "26101769" },
      {
        "name": "Electronic engine control for gasoline engines",
        "code": "26101770"
      },
      {
        "name": "Electronic engine control for diesel engine",
        "code": "26101771"
      },
      { "name": "Injector cleaner", "code": "26101772" },
      { "name": "Diesel proportional metering valve", "code": "26101773" },
      { "name": "Canister purge solenoid valve", "code": "26101774" },
      { "name": "Diesel high pressure valve", "code": "26101775" },
      { "name": "Immobilizer coil", "code": "26101776" },
      { "name": "Fuel injector coil gasoline", "code": "26101777" },
      { "name": "Fuel injector coil diesel", "code": "26101778" },
      { "name": "Wiring harness grommet", "code": "26101779" },
      { "name": "Oil injector or metering valve", "code": "26101780" },
      { "name": "Piston pin", "code": "26101781" },
      { "name": "Ignition distributor", "code": "26101782" },
      { "name": "Hydraulic swivel joint", "code": "26101783" },
      { "name": "Differential spider", "code": "26101784" },
      { "name": "Oil strainer element", "code": "26101785" },
      { "name": "Valve tappet", "code": "26101786" },
      { "name": "Admission valve and rack", "code": "26101787" },
      { "name": "Piston rod", "code": "26101788" },
      { "name": "Internal combustion engine components", "code": "26101900" },
      { "name": "Camshaft", "code": "26101903" },
      { "name": "Fuel injection nozzle", "code": "26101904" },
      { "name": "Cylinder block", "code": "26101905" },
      {
        "name": "Batteries and generators and kinetic power transmission",
        "code": "26110000"
      },
      { "name": "Kinetic power transmission", "code": "26111500" },
      { "name": "Mechanical adjustable speed drive", "code": "26111503" },
      { "name": "Belt drives", "code": "26111504" },
      { "name": "Chain drives", "code": "26111505" },
      { "name": "Linear motion devices", "code": "26111506" },
      { "name": "Power take offs", "code": "26111508" },
      { "name": "Transmission yokes", "code": "26111509" },
      { "name": "Transmission shafts", "code": "26111510" },
      { "name": "Axles", "code": "26111512" },
      { "name": "Power transmission chains", "code": "26111513" },
      { "name": "Knuckle joints", "code": "26111514" },
      { "name": "Servo controller", "code": "26111515" },
      {
        "name": "Step drive or stepper drive or step indexer",
        "code": "26111516"
      },
      { "name": "Planet carrier", "code": "26111517" },
      { "name": "Tension strut", "code": "26111518" },
      { "name": "Torque converters", "code": "26111519" },
      { "name": "Trunnions", "code": "26111520" },
      { "name": "Pusher head", "code": "26111521" },
      { "name": "Pusher assembly", "code": "26111522" },
      { "name": "Backstops", "code": "26111523" },
      { "name": "Gear units", "code": "26111524" },
      { "name": "Drum motor drives", "code": "26111525" },
      { "name": "Integrated motion control systems", "code": "26111527" },
      { "name": "Hydrostatic drives", "code": "26111528" },
      { "name": "Hydrokinetic drives", "code": "26111529" },
      { "name": "Transmission cam", "code": "26111530" },
      { "name": "Transmission sleeves", "code": "26111531" },
      { "name": "Shaft supports or assemblies", "code": "26111532" },
      { "name": "Chain tensioners", "code": "26111533" },
      { "name": "Transmission hubs", "code": "26111534" },
      { "name": "Ballscrews or ballscrew assemblies", "code": "26111535" },
      { "name": "Shaft mounted speed reducer", "code": "26111536" },
      { "name": "Worm speed reducer", "code": "26111537" },
      { "name": "Helical speed reducer", "code": "26111538" },
      { "name": "Helical and worm speed reducer", "code": "26111539" },
      { "name": "Planetary speed reducer", "code": "26111540" },
      { "name": "Cycloidal speed reducer", "code": "26111541" },
      { "name": "Bevel speed reducer increaser", "code": "26111542" },
      { "name": "Traction drive speed reducer", "code": "26111543" },
      { "name": "Speed controller", "code": "26111544" },
      { "name": "Brake drum adapter", "code": "26111545" },
      { "name": "Turbine disk", "code": "26111546" },
      { "name": "Clamping hub", "code": "26111547" },
      { "name": "Gear shift", "code": "26111548" },
      { "name": "Shaft shoulder", "code": "26111549" },
      { "name": "Motion control parts and accessories", "code": "26111550" },
      { "name": "Power generators", "code": "26111600" },
      { "name": "Diesel generators", "code": "26111601" },
      { "name": "Hydro electric generators", "code": "26111602" },
      { "name": "Wind generators", "code": "26111603" },
      { "name": "Gas generators", "code": "26111604" },
      { "name": "Thermal generators", "code": "26111605" },
      { "name": "Hydraulic generators", "code": "26111606" },
      { "name": "Solar generators", "code": "26111607" },
      { "name": "Steam generators", "code": "26111608" },
      { "name": "Gas turbine generator", "code": "26111609" },
      { "name": "Selsyn generator", "code": "26111610" },
      { "name": "Auxiliary generator", "code": "26111611" },
      { "name": "Impulse generator", "code": "26111612" },
      { "name": "Tidal wave generator", "code": "26111613" },
      { "name": "Batteries and cells and accessories", "code": "26111700" },
      { "name": "Rechargeable batteries", "code": "26111701" },
      { "name": "Alkaline batteries", "code": "26111702" },
      { "name": "Vehicle batteries", "code": "26111703" },
      { "name": "Battery chargers", "code": "26111704" },
      { "name": "Dry cell batteries", "code": "26111705" },
      { "name": "Electronic batteries", "code": "26111706" },
      { "name": "Lead acid batteries", "code": "26111707" },
      { "name": "Nickel iron batteries", "code": "26111708" },
      { "name": "Nickel cadmium batteries", "code": "26111709" },
      { "name": "Product specific battery packs", "code": "26111710" },
      { "name": "Lithium batteries", "code": "26111711" },
      { "name": "Nickel hydrogen batteries", "code": "26111712" },
      { "name": "Thermal batteries", "code": "26111713" },
      { "name": "Zinc air", "code": "26111714" },
      { "name": "Zinc coal battery", "code": "26111715" },
      { "name": "Mercury oxide battery", "code": "26111716" },
      { "name": "Manganese batteries", "code": "26111717" },
      { "name": "Silver oxide batteries", "code": "26111718" },
      { "name": "Battery testers", "code": "26111719" },
      { "name": "Battery holders", "code": "26111720" },
      { "name": "Nickel metal hydride batteries", "code": "26111721" },
      { "name": "Battery adapter or accessories", "code": "26111722" },
      { "name": "Battery cabinets or covers or doors", "code": "26111723" },
      { "name": "Tool kits for batteries", "code": "26111724" },
      { "name": "Nickel sodium chloride batteries", "code": "26111725" },
      { "name": "Fluorescent ballast battery unit", "code": "26111726" },
      { "name": "Battery discharger", "code": "26111727" },
      { "name": "Standard cell", "code": "26111728" },
      { "name": "Auto battery charger unit", "code": "26111729" },
      { "name": "Drive components", "code": "26111800" },
      { "name": "V belts", "code": "26111801" },
      { "name": "Geared timing belts", "code": "26111802" },
      { "name": "Round belts", "code": "26111803" },
      { "name": "Flat belts", "code": "26111804" },
      { "name": "Belt tensioners", "code": "26111805" },
      { "name": "Transmission pulleys", "code": "26111806" },
      { "name": "Timing pulley", "code": "26111807" },
      { "name": "Trantorque", "code": "26111808" },
      { "name": "Belt guards", "code": "26111809" },
      { "name": "Timing pulley flanges", "code": "26111810" },
      { "name": "Synchronous belt", "code": "26111811" },
      { "name": "Synchronous pulley", "code": "26111812" },
      { "name": "Variable speed pulley", "code": "26111813" },
      { "name": "Hexagonal belt", "code": "26111814" },
      { "name": "Cone pulley", "code": "26111815" },
      { "name": "Flat pulley", "code": "26111816" },
      { "name": "Clutches", "code": "26111900" },
      { "name": "Plate clutches", "code": "26111901" },
      { "name": "Diaphragm clutch", "code": "26111902" },
      { "name": "Centrifugal clutch", "code": "26111903" },
      { "name": "Semi centrifugal clutch", "code": "26111904" },
      { "name": "Free wheel clutch", "code": "26111905" },
      { "name": "Fluid coupling", "code": "26111907" },
      { "name": "Cam clutches", "code": "26111908" },
      { "name": "Electrical clutches", "code": "26111909" },
      { "name": "Hydraulic clutches", "code": "26111910" },
      { "name": "Pneumatic clutch", "code": "26111911" },
      { "name": "Mechanical clutch", "code": "26111912" },
      { "name": "Clutch hold back unit", "code": "26111913" },
      { "name": "Jaw clutch", "code": "26111914" },
      { "name": "Automatic clutch", "code": "26111915" },
      { "name": "Semi automatic clutch", "code": "26111916" },
      { "name": "Electromagnetic clutch", "code": "26111917" },
      { "name": "Clutch parts and accessories", "code": "26112000" },
      { "name": "Pressure plate", "code": "26112001" },
      { "name": "Driven plate", "code": "26112002" },
      { "name": "Clutch plates", "code": "26112003" },
      { "name": "Clutch repair kits", "code": "26112004" },
      { "name": "Clutch handle base for motorcycle", "code": "26112005" },
      { "name": "Clutch pump and components", "code": "26112006" },
      { "name": "Industrial braking systems", "code": "26112100" },
      { "name": "Air or pneumatic braking systems", "code": "26112101" },
      { "name": "Hydraulic braking systems", "code": "26112102" },
      { "name": "Mechanical braking systems", "code": "26112103" },
      { "name": "Braking clutch assemblies", "code": "26112104" },
      { "name": "Electrical braking systems", "code": "26112105" },
      { "name": "Electrical wire and cable and harness", "code": "26120000" },
      { "name": "Electrical wire", "code": "26121500" },
      { "name": "Heating wire", "code": "26121501" },
      { "name": "Fixture wire", "code": "26121505" },
      { "name": "Radio or television wire", "code": "26121507" },
      { "name": "Automotive or aircraft wire", "code": "26121508" },
      { "name": "Magnet wire", "code": "26121509" },
      { "name": "Trolley wire", "code": "26121510" },
      { "name": "Underground wire", "code": "26121514" },
      { "name": "Silicon asbestos SA wire", "code": "26121515" },
      { "name": "Copper wire", "code": "26121517" },
      { "name": "Copper clad aluminum wire", "code": "26121519" },
      { "name": "Copper steel wire", "code": "26121520" },
      { "name": "Bronze wire", "code": "26121521" },
      { "name": "Bare wire", "code": "26121522" },
      { "name": "Covered but not insulated wire", "code": "26121523" },
      { "name": "Insulated or covered wire", "code": "26121524" },
      { "name": "Inter connect wire", "code": "26121532" },
      { "name": "Kaptan wire", "code": "26121533" },
      { "name": "Polymide wire", "code": "26121534" },
      { "name": "Extension cord", "code": "26121536" },
      { "name": "Wire assembly", "code": "26121538" },
      { "name": "Hook up wires", "code": "26121539" },
      { "name": "Galvanized wire", "code": "26121540" },
      { "name": "Bus conductors", "code": "26121541" },
      { "name": "Installation wire", "code": "26121542" },
      { "name": "Heat resistant wire", "code": "26121543" },
      { "name": "Drop wire", "code": "26121544" },
      { "name": "Portable electrical cord", "code": "26121545" },
      { "name": "Tinned copper wire", "code": "26121546" },
      { "name": "Brass wire", "code": "26121547" },
      { "name": "Rectangular wire", "code": "26121548" },
      { "name": "Insulated winding wire", "code": "26121549" },
      { "name": "Electrical cable and accessories", "code": "26121600" },
      { "name": "Heating cable", "code": "26121601" },
      { "name": "Submarine cable", "code": "26121602" },
      { "name": "Control cable", "code": "26121603" },
      { "name": "Signal cable", "code": "26121604" },
      { "name": "Coaxial cable", "code": "26121606" },
      { "name": "Fiber optic cable", "code": "26121607" },
      { "name": "Aerial cable", "code": "26121608" },
      { "name": "Network cable", "code": "26121609" },
      { "name": "Bronze cable", "code": "26121610" },
      { "name": "Bare cable", "code": "26121611" },
      { "name": "Covered but not insulated cable", "code": "26121612" },
      { "name": "Insulated or covered cable", "code": "26121613" },
      { "name": "Building cable", "code": "26121614" },
      { "name": "Power cable for direct burial", "code": "26121615" },
      { "name": "Telecommunications cable", "code": "26121616" },
      { "name": "Triaxial cable", "code": "26121617" },
      { "name": "Crosslinked polykaene cable", "code": "26121618" },
      { "name": "Floropolymer cable", "code": "26121619" },
      { "name": "Inter connect cable", "code": "26121620" },
      { "name": "Kaptan cable", "code": "26121621" },
      { "name": "Polymide cable", "code": "26121622" },
      { "name": "Radio frequency RF cable", "code": "26121623" },
      { "name": "Ribbon or flat cable", "code": "26121624" },
      { "name": "Screened cables", "code": "26121628" },
      { "name": "Power cable", "code": "26121629" },
      { "name": "Cable accessories", "code": "26121630" },
      { "name": "Outside plant coaxial cable", "code": "26121631" },
      { "name": "Outside plant communications cable", "code": "26121632" },
      { "name": "Outside plant telecommunications cable", "code": "26121633" },
      { "name": "Copper cable", "code": "26121634" },
      { "name": "Cable reels", "code": "26121635" },
      { "name": "Power cord", "code": "26121636" },
      { "name": "Outdoor fiber optics cable", "code": "26121637" },
      { "name": "Crimping materials", "code": "26121638" },
      { "name": "Combined or customized multi cables", "code": "26121639" },
      { "name": "Heat resistant cables", "code": "26121640" },
      { "name": "Installation cables", "code": "26121641" },
      { "name": "Instrumentation Cable", "code": "26121642" },
      { "name": "Bare steel reinforced electrical cable", "code": "26121643" },
      { "name": "Bare aluminum electrical cable", "code": "26121644" },
      { "name": "Bare copper electrical cable", "code": "26121645" },
      { "name": "Trailing and mining machine cable", "code": "26121646" },
      {
        "name": "Trailing and mining machine cable assembly",
        "code": "26121647"
      },
      { "name": "High voltage cable termination kit", "code": "26121648" },
      { "name": "High voltage cable joining kit", "code": "26121649" },
      { "name": "Fiber optic cable assembly", "code": "26121650" },
      { "name": "Oil resistant SO cable", "code": "26121651" },
      { "name": "Electrical cord assembly", "code": "26121652" },
      { "name": "Toll cable", "code": "26121653" },
      { "name": "Carrier frequency cable", "code": "26121654" },
      { "name": "Simplex and duplex fiber optic cable", "code": "26121655" },
      { "name": "Submarine fiber optic cable", "code": "26121656" },
      { "name": "Flame retardant fiber optic cable", "code": "26121657" },
      {
        "name": "Aluminum conductor motion resistant transmission cable",
        "code": "26121658"
      },
      {
        "name": "Aluminum conductor quadruplex service drop cable",
        "code": "26121659"
      },
      {
        "name": "Aluminum conductor duplex service drop cable",
        "code": "26121660"
      },
      {
        "name": "Aluminum conductor underground service entrance cable",
        "code": "26121661"
      },
      {
        "name": "Aluminum conductor service entrance cable",
        "code": "26121662"
      },
      { "name": "Single aluminum conductor service cable", "code": "26121663" },
      {
        "name": "Aluminum conductor polyethylene covered line wire",
        "code": "26121664"
      },
      { "name": "Aluminum triplex service drop cable", "code": "26121665" },
      { "name": "Communication cable assembly", "code": "26121666" },
      { "name": "Backplane input output cable", "code": "26121667" },
      { "name": "Wiring harness", "code": "26121700" },
      { "name": "Panel wiring harness", "code": "26121701" },
      { "name": "Trunk wiring harness", "code": "26121702" },
      { "name": "Communication wiring harness", "code": "26121703" },
      { "name": "Specialty wiring harness", "code": "26121704" },
      { "name": "Coaxial assembly", "code": "26121706" },
      { "name": "Flat flexible cable assembly", "code": "26121707" },
      { "name": "Battery cable assembly", "code": "26121708" },
      { "name": "Coiled cord assembly", "code": "26121709" },
      { "name": "Oxygen sensor wiring harness", "code": "26121710" },
      { "name": "Automotive cable", "code": "26121800" },
      {
        "name": "Single core 60 volt class a automotive cable",
        "code": "26121802"
      },
      {
        "name": "Single core 60 volt class b automotive cable",
        "code": "26121803"
      },
      {
        "name": "Single core 60 volt class c automotive cable",
        "code": "26121804"
      },
      {
        "name": "Single core 60 volt class d automotive cable",
        "code": "26121805"
      },
      {
        "name": "Single core 60 volt class e automotive cable",
        "code": "26121806"
      },
      {
        "name": "Single core 60 volt class f automotive cable",
        "code": "26121807"
      },
      {
        "name": "Single core 60 volt class g automotive cable",
        "code": "26121808"
      },
      {
        "name": "Single core 60 volt class h automotive cable",
        "code": "26121809"
      },
      {
        "name": "Single core 600 volt class a automotive cable",
        "code": "26121810"
      },
      {
        "name": "Single core 600 volt class b automotive cable",
        "code": "26121811"
      },
      {
        "name": "Single core 600 volt class c automotive cable",
        "code": "26121812"
      },
      {
        "name": "Single core 600 volt class d automotive cable",
        "code": "26121813"
      },
      {
        "name": "Single core 600 volt class e automotive cable",
        "code": "26121814"
      },
      {
        "name": "Single core 600 volt class f automotive cable",
        "code": "26121815"
      },
      {
        "name": "Single core 600 volt class g automotive cable",
        "code": "26121816"
      },
      {
        "name": "Single core 600 volt class h automotive cable",
        "code": "26121817"
      },
      {
        "name": "Multi core 60 volt class a automotive cable",
        "code": "26121818"
      },
      {
        "name": "Multi core 60 volt class b automotive cable",
        "code": "26121819"
      },
      {
        "name": "Multi core 60 volt class c automotive cable",
        "code": "26121820"
      },
      {
        "name": "Multi core 60 volt class d automotive cable",
        "code": "26121821"
      },
      {
        "name": "Multi core 60 volt class e automotive cable",
        "code": "26121822"
      },
      {
        "name": "Multi core 60 volt class f automotive cable",
        "code": "26121823"
      },
      {
        "name": "Multi core 60 volt class g automotive cable",
        "code": "26121824"
      },
      {
        "name": "Multi core 60 volt class h automotive cable",
        "code": "26121825"
      },
      {
        "name": "Multi core 600 volt class a automotive cable",
        "code": "26121826"
      },
      {
        "name": "Multi core 600 volt class b automotive cable",
        "code": "26121827"
      },
      {
        "name": "Multi core 600 volt class c automotive cable",
        "code": "26121828"
      },
      {
        "name": "Multi core 600 volt class d automotive cable",
        "code": "26121829"
      },
      {
        "name": "Multi core 600 volt class e automotive cable",
        "code": "26121830"
      },
      {
        "name": "Multi core 600 volt class f automotive cable",
        "code": "26121831"
      },
      {
        "name": "Multi core 600 volt class g automotive cable",
        "code": "26121832"
      },
      {
        "name": "Multi core 600 volt class h automotive cable",
        "code": "26121833"
      },
      {
        "name": "Braided 60 volt class a automotive cable",
        "code": "26121834"
      },
      {
        "name": "Braided 60 volt class b automotive cable",
        "code": "26121835"
      },
      {
        "name": "Braided 60 volt class c automotive cable",
        "code": "26121836"
      },
      {
        "name": "Braided 60 volt class d automotive cable",
        "code": "26121837"
      },
      {
        "name": "Braided 60 volt class e automotive cable",
        "code": "26121838"
      },
      {
        "name": "Braided 60 volt class f automotive cable",
        "code": "26121839"
      },
      {
        "name": "Braided 60 volt class g automotive cable",
        "code": "26121840"
      },
      {
        "name": "Braided 60 volt class h automotive cable",
        "code": "26121841"
      },
      {
        "name": "Braided 600 volt class a automotive cable",
        "code": "26121842"
      },
      {
        "name": "Braided 600 volt class b automotive cable",
        "code": "26121843"
      },
      {
        "name": "Braided 600 volt class c automotive cable",
        "code": "26121844"
      },
      {
        "name": "Braided 600 volt class d automotive cable",
        "code": "26121845"
      },
      {
        "name": "Braided 600 volt class e automotive cable",
        "code": "26121846"
      },
      {
        "name": "Braided 600 volt class f automotive cable",
        "code": "26121847"
      },
      {
        "name": "Braided 600 volt class g automotive cable",
        "code": "26121848"
      },
      {
        "name": "Braided 600 volt class h automotive cable",
        "code": "26121849"
      },
      {
        "name": "Multi core screened 60 volt automotive cable",
        "code": "26121850"
      },
      {
        "name": "Multi core screened 600 volt automotive cable",
        "code": "26121851"
      },
      { "name": "Ignition core automotive cable", "code": "26121852" },
      { "name": "Power generation", "code": "26130000" },
      { "name": "Power plants", "code": "26131500" },
      { "name": "Diesel power plants", "code": "26131501" },
      { "name": "Geothermal power plants", "code": "26131502" },
      { "name": "Hydro power plants", "code": "26131503" },
      { "name": "Gas power plants", "code": "26131504" },
      { "name": "Marine power plants", "code": "26131505" },
      { "name": "Petrol power plants", "code": "26131506" },
      { "name": "Solar power plants", "code": "26131507" },
      { "name": "Steam power plants", "code": "26131508" },
      { "name": "Wind power plants", "code": "26131509" },
      { "name": "Thermal power plants", "code": "26131510" },
      {
        "name": "Exhaust structures or screening equipment",
        "code": "26131600"
      },
      { "name": "Traveling water screens", "code": "26131601" },
      { "name": "Stop logs", "code": "26131602" },
      { "name": "Water trash racks", "code": "26131603" },
      { "name": "Fixed screens", "code": "26131604" },
      { "name": "Intake structures", "code": "26131605" },
      { "name": "Steel exhaust stack", "code": "26131606" },
      { "name": "Concrete exhaust stacks", "code": "26131607" },
      { "name": "Flare or vent stacks", "code": "26131608" },
      { "name": "Exhaust inlet stacks", "code": "26131609" },
      { "name": "Bypass stacks", "code": "26131610" },
      { "name": "Silencer sections", "code": "26131611" },
      { "name": "Exhaust outlet ducts", "code": "26131612" },
      { "name": "Exhaust duct expansion joints", "code": "26131613" },
      { "name": "Stack closure dampers", "code": "26131614" },
      { "name": "Exhaust diversion dampers", "code": "26131615" },
      { "name": "Exhaust isolation dampers", "code": "26131616" },
      { "name": "Vane damper", "code": "26131617" },
      { "name": "Poppet damper", "code": "26131618" },
      {
        "name": "Power generation monitoring or detecting equipment",
        "code": "26131700"
      },
      {
        "name": "Combustible or hazardous gas detectors for power generators",
        "code": "26131701"
      },
      {
        "name": "Gas turbine combustion system flame detectors",
        "code": "26131702"
      },
      { "name": "Power generation control equipment", "code": "26131800" },
      {
        "name": "Electrical control panels for generators",
        "code": "26131801"
      },
      { "name": "Compressor control panels", "code": "26131802" },
      { "name": "Generator control or protection panels", "code": "26131803" },
      { "name": "Gas turbine control panels", "code": "26131804" },
      { "name": "Steam turbines control panels", "code": "26131807" },
      { "name": "Substation load control switchgears", "code": "26131808" },
      { "name": "Current limiting reactors", "code": "26131811" },
      { "name": "Gas insulated switchgears", "code": "26131812" },
      { "name": "Switchyard disconnect switches", "code": "26131813" },
      { "name": "Switchyard surge arrestors", "code": "26131814" },
      { "name": "Electric accumulator", "code": "26131815" },
      {
        "name": "Atomic and nuclear energy machinery and equipment",
        "code": "26140000"
      },
      { "name": "Subcritical assembly equipment", "code": "26141600" },
      { "name": "Subcritical assembly fuel", "code": "26141601" },
      { "name": "Subcritical assembly components", "code": "26141602" },
      { "name": "Subcritical assembly moderator", "code": "26141603" },
      { "name": "Dosimetry equipment", "code": "26141700" },
      { "name": "Ionization chamber dosimeters", "code": "26141701" },
      { "name": "Dosimeters", "code": "26141702" },
      { "name": "Secondary standard dosimetry systems", "code": "26141703" },
      { "name": "Phantom dosimeters", "code": "26141704" },
      { "name": "Hot cell devices", "code": "26141800" },
      { "name": "Hot cell remote handling equipment", "code": "26141801" },
      { "name": "Hot cell remote viewing device", "code": "26141802" },
      { "name": "Hot cell shielding doors", "code": "26141803" },
      { "name": "Hot cell samplers", "code": "26141804" },
      { "name": "Hot cell sample processing equipment", "code": "26141805" },
      { "name": "Hot cell special tools", "code": "26141806" },
      { "name": "Hot cell lead glass windows", "code": "26141807" },
      { "name": "Hot cell decontamination systems", "code": "26141808" },
      { "name": "Hot cell penetration devices", "code": "26141809" },
      { "name": "Industrial nucleonic instruments", "code": "26141900" },
      {
        "name": "Industrial nucleonic airborne dust measuring systems",
        "code": "26141901"
      },
      { "name": "Beta gauge measuring systems", "code": "26141902" },
      {
        "name": "Industrial nucleonic liquid level gauges",
        "code": "26141904"
      },
      {
        "name": "Industrial nucleonic mass per unit ore measuring systems",
        "code": "26141905"
      },
      {
        "name": "Industrial nucleonic moisture measuring systems",
        "code": "26141906"
      },
      {
        "name": "Industrial nucleonic thickness measuring systems",
        "code": "26141907"
      },
      {
        "name": "Industrial nucleonic flow measuring systems",
        "code": "26141908"
      },
      { "name": "Isotope separators", "code": "26141909" },
      { "name": "Isotope production facilities", "code": "26141910" },
      { "name": "Isotope calibrator activity meters", "code": "26141911" },
      { "name": "Irradiation equipment", "code": "26142000" },
      { "name": "Irradiation gamma sources", "code": "26142001" },
      { "name": "Magnet systems", "code": "26142002" },
      { "name": "NIM nuclear electronic units", "code": "26142003" },
      { "name": "Neutron irradiators", "code": "26142004" },
      { "name": "Irradiation testing capsules", "code": "26142005" },
      { "name": "Irradiation sample transfer system", "code": "26142006" },
      { "name": "Neutron generators", "code": "26142007" },
      { "name": "Nuclear reactor equipment", "code": "26142100" },
      {
        "name": "Nuclear reactor specimen irradiation containers",
        "code": "26142101"
      },
      { "name": "Nuclear reactor control rod systems", "code": "26142106" },
      {
        "name": "Nuclear reactor in core neutron flux instrumentation",
        "code": "26142108"
      },
      {
        "name": "Nuclear reactor earthquake instrumentation",
        "code": "26142117"
      },
      { "name": "Nuclear fuel equipment", "code": "26142200" },
      { "name": "Nuclear fuel cladding tubes", "code": "26142201" },
      {
        "name": "Nuclear fuel element failure detection systems",
        "code": "26142202"
      },
      { "name": "Radiation shielding equipment", "code": "26142300" },
      { "name": "Lead screens", "code": "26142302" },
      { "name": "Film badges", "code": "26142303" },
      { "name": "Radiographic equipment", "code": "26142304" },
      {
        "name": "Shielded containers for radiation protection",
        "code": "26142306"
      },
      {
        "name": "Lead chambers or safes for radiation protection",
        "code": "26142307"
      },
      { "name": "Lead bricks for radiation protection", "code": "26142308" },
      { "name": "Glove boxes for radiation protection", "code": "26142310" },
      { "name": "Radiation shielding windows", "code": "26142311" },
      { "name": "Lead for radiation shielding", "code": "26142312" },
      { "name": "Radioactive waste equipment", "code": "26142400" },
      {
        "name": "Radioactive waste treatment compactors or incinerators",
        "code": "26142401"
      },
      { "name": "Nuclear radiation absorbers", "code": "26142402" },
      {
        "name": "Atomic energy evaporators or concentrators or dryers",
        "code": "26142403"
      },
      { "name": "Door interlocking systems", "code": "26142404" },
      { "name": "Radioactive waste dosage systems", "code": "26142405" },
      {
        "name": "Radioactive waste solidification systems",
        "code": "26142406"
      },
      { "name": "Radioactive waste disposal systems", "code": "26142407" },
      { "name": "Radioactive waste treatment facilities", "code": "26142408" }
    ]
  },
  {
    "name": "Tools and General Machinery",
    "code": "27000000",
    "subcategories": [
      { "name": "Hand tools", "code": "27110000" },
      { "name": "Cutting and crimping and punching tools", "code": "27111500" },
      { "name": "Knife blades", "code": "27111501" },
      { "name": "Razor knives", "code": "27111502" },
      { "name": "Utility knives", "code": "27111503" },
      { "name": "Pocket knives", "code": "27111504" },
      { "name": "Punches or nail sets or drifts", "code": "27111505" },
      { "name": "Shears", "code": "27111506" },
      { "name": "Metal cutters", "code": "27111507" },
      { "name": "Saws", "code": "27111508" },
      { "name": "Augers", "code": "27111509" },
      { "name": "Stripping tools", "code": "27111510" },
      { "name": "Wire cutters", "code": "27111511" },
      { "name": "Bolt cutters", "code": "27111512" },
      { "name": "Hose cutter", "code": "27111513" },
      { "name": "Glass cutters", "code": "27111514" },
      { "name": "Hand or push drill", "code": "27111515" },
      { "name": "Punching pliers", "code": "27111516" },
      { "name": "Knife blade sets or dispensers", "code": "27111517" },
      { "name": "Wire lug crimping tool", "code": "27111518" },
      { "name": "Tinners snips", "code": "27111519" },
      { "name": "Nut splitters", "code": "27111520" },
      { "name": "Nibblers", "code": "27111521" },
      { "name": "Flaring tool", "code": "27111522" },
      { "name": "Glass scraper", "code": "27111523" },
      { "name": "Rivet cutter", "code": "27111524" },
      { "name": "Pincers", "code": "27111525" },
      { "name": "Deburring tool", "code": "27111526" },
      { "name": "Wad punch", "code": "27111527" },
      { "name": "Insulated scissors", "code": "27111529" },
      { "name": "Fishmonger scissors", "code": "27111530" },
      { "name": "Hair scissors", "code": "27111531" },
      { "name": "Jeweler scissors", "code": "27111532" },
      { "name": "Butcher scissors", "code": "27111533" },
      { "name": "Compass cutter", "code": "27111534" },
      { "name": "Wire scissors", "code": "27111535" },
      { "name": "Circle cutter", "code": "27111536" },
      { "name": "Wood auger bit", "code": "27111537" },
      { "name": "Forstner bit", "code": "27111538" },
      { "name": "Flat wood bit", "code": "27111539" },
      { "name": "Tile bit", "code": "27111540" },
      { "name": "Bullet pilot point", "code": "27111541" },
      { "name": "Spur point bit", "code": "27111542" },
      { "name": "Masonry bit", "code": "27111543" },
      { "name": "Rip saw", "code": "27111544" },
      { "name": "Crosscut saw", "code": "27111545" },
      { "name": "Panel saw", "code": "27111546" },
      { "name": "Flooring saw", "code": "27111547" },
      { "name": "Tenon or back saw", "code": "27111548" },
      { "name": "Pad or keyhole saw", "code": "27111549" },
      { "name": "Bow saw", "code": "27111550" },
      { "name": "Log saw", "code": "27111551" },
      { "name": "Coping saw", "code": "27111552" },
      { "name": "Two man crosscut saw", "code": "27111553" },
      { "name": "Blade sharpener", "code": "27111554" },
      { "name": "Center punch", "code": "27111555" },
      { "name": "Hollow punch", "code": "27111556" },
      { "name": "Taper punch", "code": "27111557" },
      { "name": "Parallel pin punch", "code": "27111558" },
      { "name": "Hacksaw", "code": "27111559" },
      { "name": "Mini pliers", "code": "27111560" },
      { "name": "Miter box", "code": "27111561" },
      { "name": "Optical fiber cleaver", "code": "27111562" },
      { "name": "Forming tools", "code": "27111600" },
      { "name": "Mallets", "code": "27111601" },
      { "name": "Hammers", "code": "27111602" },
      { "name": "Anvils", "code": "27111603" },
      { "name": "Hatchets", "code": "27111604" },
      { "name": "Picks", "code": "27111605" },
      { "name": "Swaging tools", "code": "27111607" },
      { "name": "Manual wire straighteners", "code": "27111608" },
      { "name": "Electric wire straighteners", "code": "27111609" },
      { "name": "Hammer and mallet components", "code": "27111610" },
      { "name": "Stonemason hammer", "code": "27111611" },
      { "name": "Non sparking hammer or mallet", "code": "27111612" },
      { "name": "Non sparking hatchet", "code": "27111613" },
      { "name": "Non sparking pick", "code": "27111614" },
      { "name": "Sledge hammer", "code": "27111615" },
      { "name": "Ball peen hammer", "code": "27111616" },
      { "name": "Rubber mallet", "code": "27111617" },
      { "name": "Claw hammer", "code": "27111618" },
      { "name": "Cross and straight pein hammer", "code": "27111619" },
      { "name": "Cross pein pin hammer", "code": "27111620" },
      { "name": "Club hammer or lump hammer", "code": "27111621" },
      { "name": "Joiners mallet", "code": "27111622" },
      { "name": "Hand bottle crimper", "code": "27111623" },
      { "name": "Wrenches and drivers", "code": "27111700" },
      { "name": "Screwdrivers", "code": "27111701" },
      { "name": "Nut drivers", "code": "27111702" },
      { "name": "Socket sets", "code": "27111703" },
      { "name": "Sockets", "code": "27111704" },
      { "name": "Box end wrenches", "code": "27111705" },
      { "name": "Open end wrenches", "code": "27111706" },
      { "name": "Adjustable wrenches", "code": "27111707" },
      { "name": "Pipe wrenches", "code": "27111708" },
      { "name": "Screw extractors", "code": "27111709" },
      { "name": "Hex keys", "code": "27111710" },
      { "name": "Ratchets", "code": "27111711" },
      { "name": "Pullers", "code": "27111712" },
      { "name": "Combination wrenches", "code": "27111713" },
      { "name": "Specialty wrenches", "code": "27111714" },
      { "name": "Torque wrenches", "code": "27111715" },
      { "name": "Torx keys", "code": "27111716" },
      { "name": "Pipe extractors", "code": "27111717" },
      { "name": "Tap extractors", "code": "27111718" },
      { "name": "T handle tap wrenches", "code": "27111720" },
      { "name": "Cranks", "code": "27111721" },
      { "name": "Die stocks", "code": "27111722" },
      { "name": "Tube wrenches", "code": "27111723" },
      { "name": "Hook wrenches", "code": "27111724" },
      { "name": "Offset socket wrenches", "code": "27111725" },
      { "name": "Spanner wrenches", "code": "27111726" },
      { "name": "Chuck keys", "code": "27111727" },
      { "name": "Screwdriver set", "code": "27111728" },
      { "name": "Wrench set", "code": "27111729" },
      { "name": "Double ended swivel wrench", "code": "27111730" },
      { "name": "Precision screwdriver", "code": "27111731" },
      { "name": "Non sparking screwdriver", "code": "27111732" },
      { "name": "Flexible rod screwdriver", "code": "27111733" },
      { "name": "Multiple tip screwdriver", "code": "27111734" },
      { "name": "Ratchet screwdriver", "code": "27111735" },
      { "name": "Insulated screwdriver", "code": "27111736" },
      { "name": "Hand push automatic screwdriver", "code": "27111737" },
      { "name": "Voltage tester screwdriver", "code": "27111738" },
      { "name": "Torque screwdriver", "code": "27111739" },
      { "name": "Telescoping screwdriver", "code": "27111740" },
      { "name": "Interchangeable rod screwdriver", "code": "27111741" },
      { "name": "Impact screwdriver", "code": "27111742" },
      { "name": "Ring wrench or spanner", "code": "27111743" },
      { "name": "Offset ring wrench or spanner", "code": "27111744" },
      { "name": "Split ring wrench or spanner", "code": "27111745" },
      { "name": "Bulldog wrench or spanner", "code": "27111746" },
      { "name": "Box wrench or spanner", "code": "27111747" },
      { "name": "Footprint wrench", "code": "27111748" },
      { "name": "Pipe wrench or stillson wrench", "code": "27111749" },
      { "name": "Mole wrench", "code": "27111750" },
      { "name": "Tap spanner or basin wrench", "code": "27111751" },
      { "name": "Obstruction wrench", "code": "27111752" },
      { "name": "Ratchet wrench", "code": "27111753" },
      { "name": "S wrench", "code": "27111754" },
      { "name": "Y wrench", "code": "27111755" },
      { "name": "Crows foot wrench", "code": "27111756" },
      { "name": "Slugging wrench", "code": "27111757" },
      { "name": "Cam over wrench", "code": "27111758" },
      { "name": "Blade wrench", "code": "27111759" },
      { "name": "Wheel nut wrench or lug nut wrench", "code": "27111760" },
      { "name": "Water pump wrench", "code": "27111761" },
      { "name": "Flexible head gear wrench", "code": "27111762" },
      { "name": "Allen wrench", "code": "27111763" },
      { "name": "Drill drift", "code": "27111764" },
      { "name": "Drum plug wrench", "code": "27111765" },
      { "name": "Cross rim wrench", "code": "27111766" },
      { "name": "Offset screw driver", "code": "27111767" },
      { "name": "Point socket", "code": "27111768" },
      { "name": "Measuring and layout tools", "code": "27111800" },
      { "name": "Tape measures", "code": "27111801" },
      { "name": "Levels", "code": "27111802" },
      { "name": "Squares", "code": "27111803" },
      { "name": "Plumb bobs", "code": "27111804" },
      { "name": "Nail or router gauges", "code": "27111806" },
      { "name": "Straight edges", "code": "27111807" },
      { "name": "Bevels", "code": "27111809" },
      { "name": "Stud finders", "code": "27111810" },
      { "name": "Folding ruler", "code": "27111811" },
      { "name": "Long tape ruler", "code": "27111812" },
      { "name": "Plumb bob line", "code": "27111813" },
      { "name": "Inspection mirror", "code": "27111814" },
      { "name": "Combination square", "code": "27111815" },
      { "name": "Gage ball set", "code": "27111816" },
      { "name": "Shrink or shrinkage rule", "code": "27111817" },
      { "name": "Dial gauge stand", "code": "27111818" },
      { "name": "Roughness comparison specimen", "code": "27111819" },
      { "name": "Square master", "code": "27111820" },
      { "name": "Cylindrical square", "code": "27111821" },
      { "name": "Angle measuring instrument", "code": "27111822" },
      { "name": "Center gauge", "code": "27111823" },
      { "name": "Angle gauge", "code": "27111824" },
      { "name": "Sine plate", "code": "27111825" },
      { "name": "Precision surface plate", "code": "27111826" },
      { "name": "Adjustable angle plate", "code": "27111827" },
      { "name": "Fixed angle plate", "code": "27111828" },
      { "name": "Optical parallel", "code": "27111829" },
      { "name": "Rough and finishing tools", "code": "27111900" },
      { "name": "Cold chisels", "code": "27111901" },
      { "name": "Planes", "code": "27111903" },
      { "name": "Rasps", "code": "27111904" },
      { "name": "Grinders", "code": "27111905" },
      { "name": "Wood chisels", "code": "27111906" },
      { "name": "Wire brushes", "code": "27111907" },
      { "name": "Sharpening stones or tools or kits", "code": "27111908" },
      { "name": "Spatulas", "code": "27111909" },
      { "name": "Burins", "code": "27111910" },
      { "name": "Cross cut chisels", "code": "27111911" },
      { "name": "Wood gouge", "code": "27111912" },
      { "name": "Edge trimmer", "code": "27111913" },
      { "name": "Bull point chisel", "code": "27111914" },
      { "name": "Stonemason chisel", "code": "27111915" },
      { "name": "Roof ripper", "code": "27111916" },
      { "name": "Glue roller", "code": "27111917" },
      { "name": "Hand sander", "code": "27111918" },
      { "name": "Bastard cut file", "code": "27111919" },
      { "name": "Second cut file", "code": "27111920" },
      { "name": "Smooth cut file", "code": "27111921" },
      { "name": "Cross cut file", "code": "27111922" },
      { "name": "Chain saw file", "code": "27111923" },
      { "name": "Rasp cut file", "code": "27111924" },
      { "name": "Needle file", "code": "27111925" },
      { "name": "Aluminum flat file", "code": "27111926" },
      { "name": "Aluminum half round file", "code": "27111927" },
      { "name": "Cheesegrater file", "code": "27111928" },
      { "name": "Flat hand file", "code": "27111929" },
      { "name": "Round file", "code": "27111930" },
      { "name": "Half round file", "code": "27111931" },
      { "name": "Square file", "code": "27111932" },
      { "name": "Three square triangular file", "code": "27111933" },
      { "name": "Flat taper file", "code": "27111934" },
      { "name": "Knife file", "code": "27111935" },
      { "name": "Warding file", "code": "27111936" },
      { "name": "Laminate file", "code": "27111937" },
      { "name": "Long angle lathe file", "code": "27111938" },
      { "name": "Half round pipe line file", "code": "27111939" },
      { "name": "Mill saw file", "code": "27111940" },
      { "name": "Taper saw file", "code": "27111941" },
      { "name": "Double end saw file", "code": "27111942" },
      { "name": "Farmers own file", "code": "27111943" },
      { "name": "Precision file", "code": "27111944" },
      { "name": "Die sinker riffler", "code": "27111945" },
      { "name": "Silversmiths riffler", "code": "27111946" },
      { "name": "Half round wood rasp", "code": "27111948" },
      { "name": "Half round cabinet rasp", "code": "27111949" },
      { "name": "Round wood rasp", "code": "27111950" },
      { "name": "Four in hand shoe rasp", "code": "27111951" },
      { "name": "Horse rasp", "code": "27111952" },
      { "name": "Farrier rasp", "code": "27111953" },
      { "name": "Taper shank bridge reamer", "code": "27111954" },
      { "name": "Round shank bridge reamer", "code": "27111955" },
      { "name": "Burnisher", "code": "27111956" },
      { "name": "Nozzle cleaner", "code": "27111957" },
      { "name": "Hand lapper", "code": "27111958" },
      {
        "name": "Agriculture, forestry and garden handtools",
        "code": "27112000"
      },
      { "name": "Machetes", "code": "27112001" },
      { "name": "Spades", "code": "27112002" },
      { "name": "Rakes", "code": "27112003" },
      { "name": "Shovels", "code": "27112004" },
      { "name": "Axes", "code": "27112005" },
      { "name": "Scythes", "code": "27112006" },
      { "name": "Secateurs or pruning shears", "code": "27112007" },
      { "name": "Hoes", "code": "27112008" },
      { "name": "Scrapers", "code": "27112009" },
      { "name": "Garden forks", "code": "27112010" },
      { "name": "Tool handles", "code": "27112011" },
      { "name": "Garden riddles", "code": "27112012" },
      { "name": "Post hole digger", "code": "27112013" },
      { "name": "Lawnmowers", "code": "27112014" },
      { "name": "Lawn scarifiers", "code": "27112015" },
      { "name": "Hedge clippers", "code": "27112016" },
      { "name": "Digging bars", "code": "27112017" },
      { "name": "Bulb planter", "code": "27112018" },
      { "name": "Pruning saw", "code": "27112019" },
      { "name": "Sickle", "code": "27112020" },
      { "name": "Lawn aerator", "code": "27112021" },
      { "name": "Border shear", "code": "27112022" },
      { "name": "Hand cultivator", "code": "27112023" },
      { "name": "Miniature garden tool set", "code": "27112024" },
      { "name": "Short handled hoe", "code": "27112025" },
      { "name": "Handle wedge", "code": "27112026" },
      { "name": "Garden broom", "code": "27112027" },
      { "name": "Tree debarker", "code": "27112028" },
      { "name": "Garden watering can", "code": "27112029" },
      { "name": "Billhook", "code": "27112030" },
      { "name": "Garden sieve", "code": "27112031" },
      { "name": "Adze", "code": "27112032" },
      { "name": "Garden tool set", "code": "27112033" },
      { "name": "Root cutter", "code": "27112034" },
      { "name": "Hedge trimmer", "code": "27112035" },
      { "name": "Garden shredder", "code": "27112036" },
      { "name": "Brush cutter", "code": "27112037" },
      { "name": "Garden chainsaw", "code": "27112038" },
      { "name": "Lopper", "code": "27112039" },
      { "name": "Soil probe", "code": "27112040" },
      { "name": "Grafting knife", "code": "27112041" },
      { "name": "Tree caliper", "code": "27112042" },
      { "name": "Tree diagnostic tool or resistograph", "code": "27112043" },
      { "name": "Tree trunk Injection set", "code": "27112044" },
      { "name": "Sod cutter", "code": "27112045" },
      { "name": "Turf sweeper", "code": "27112046" },
      { "name": "High branch shears", "code": "27112047" },
      { "name": "Holding and clamping tools", "code": "27112100" },
      { "name": "Pipe vises", "code": "27112101" },
      { "name": "Bench vises", "code": "27112102" },
      { "name": "Hand clamps", "code": "27112103" },
      { "name": "Tongs", "code": "27112104" },
      { "name": "Tweezers", "code": "27112105" },
      { "name": "Linemans pliers", "code": "27112106" },
      { "name": "Adjustable widemouth pliers", "code": "27112107" },
      { "name": "Needlenose pliers", "code": "27112108" },
      { "name": "Magnetic tools", "code": "27112109" },
      { "name": "Retaining ring pliers", "code": "27112110" },
      { "name": "Alligator pliers", "code": "27112111" },
      { "name": "Tongue and groove pliers", "code": "27112112" },
      { "name": "Slip or groove joint pliers", "code": "27112113" },
      { "name": "Diagonal cut pliers", "code": "27112114" },
      { "name": "Locking pliers", "code": "27112115" },
      { "name": "Fence pliers", "code": "27112116" },
      { "name": "End cut pliers", "code": "27112117" },
      { "name": "Light bulb changer", "code": "27112119" },
      { "name": "C clamps", "code": "27112120" },
      { "name": "Corner clamps", "code": "27112121" },
      { "name": "Sheet metal pliers", "code": "27112122" },
      { "name": "Bench dog", "code": "27112123" },
      { "name": "Tensioners", "code": "27112124" },
      { "name": "Round nose pliers", "code": "27112125" },
      { "name": "Flat nose pliers", "code": "27112126" },
      { "name": "Strap wrenches", "code": "27112127" },
      { "name": "Curved nose pliers", "code": "27112128" },
      { "name": "Round handle clamps", "code": "27112129" },
      { "name": "Three jaw clamps", "code": "27112130" },
      { "name": "Deep throat jaw opening clamps", "code": "27112131" },
      { "name": "Hold down clamps", "code": "27112132" },
      { "name": "T handle clamps", "code": "27112133" },
      { "name": "Longnose pliers", "code": "27112134" },
      { "name": "Insulated pliers", "code": "27112135" },
      { "name": "Non sparking pliers", "code": "27112136" },
      { "name": "Pliers set", "code": "27112137" },
      { "name": "Manual pipe straightener", "code": "27112138" },
      { "name": "Combination pliers", "code": "27112139" },
      { "name": "Circlip pliers", "code": "27112140" },
      { "name": "Glass pliers", "code": "27112141" },
      { "name": "Multi tool pliers", "code": "27112142" },
      { "name": "Tile cutting pliers", "code": "27112143" },
      { "name": "Snap pliers", "code": "27112144" },
      { "name": "Slip joint pliers", "code": "27112145" },
      { "name": "Swivel base vise", "code": "27112146" },
      { "name": "Crimping pliers", "code": "27112147" },
      { "name": "Electronics pliers", "code": "27112148" },
      { "name": "Electrostatic pliers", "code": "27112149" },
      { "name": "Jewellers pliers", "code": "27112150" },
      { "name": "Wire-stripping pliers", "code": "27112151" },
      { "name": "Triclover clamp", "code": "27112152" },
      { "name": "Belt lacer", "code": "27112153" },
      { "name": "Milling vise", "code": "27112154" },
      { "name": "Piston ring expander", "code": "27112155" },
      { "name": "Hand vise", "code": "27112156" },
      { "name": "Drill vise", "code": "27112157" },
      { "name": "Leg vise", "code": "27112158" },
      { "name": "Chain vise", "code": "27112159" },
      { "name": "Wire twister", "code": "27112160" },
      { "name": "Wire wrapping tool", "code": "27112161" },
      { "name": "Rule clamp", "code": "27112162" },
      { "name": "Masonry and concrete tools", "code": "27112200" },
      { "name": "Trowels", "code": "27112201" },
      { "name": "Floats", "code": "27112202" },
      { "name": "Edgers", "code": "27112203" },
      { "name": "Concrete vibrators", "code": "27112205" },
      { "name": "Screed board", "code": "27112206" },
      { "name": "Hand rammer", "code": "27112207" },
      { "name": "Pickaxe", "code": "27112208" },
      { "name": "Masonry shovel", "code": "27112209" },
      { "name": "Brick jointer", "code": "27112210" },
      { "name": "Tuck pointer", "code": "27112211" },
      { "name": "Curb face tool", "code": "27112212" },
      { "name": "Concrete chute", "code": "27112213" },
      { "name": "Grooving edger", "code": "27112215" },
      { "name": "Tile scribe", "code": "27112216" },
      { "name": "Tile cutter", "code": "27112217" },
      { "name": "Tile power saw", "code": "27112218" },
      { "name": "Tile nipper", "code": "27112219" },
      { "name": "Tile hand saw", "code": "27112220" },
      { "name": "Tile drill bit", "code": "27112221" },
      { "name": "Tile hole cutter", "code": "27112222" },
      { "name": "Tile file", "code": "27112223" },
      { "name": "Profile gauge", "code": "27112224" },
      { "name": "Notched spreader", "code": "27112225" },
      { "name": "Tile trowel", "code": "27112226" },
      { "name": "Grout spreader", "code": "27112227" },
      { "name": "Grout rake", "code": "27112228" },
      { "name": "Tile removing chisel", "code": "27112229" },
      { "name": "Tuck pointer or joint filler", "code": "27112230" },
      { "name": "Step tool", "code": "27112231" },
      { "name": "Groover", "code": "27112232" },
      { "name": "Marking tools", "code": "27112300" },
      { "name": "Branders", "code": "27112301" },
      { "name": "Awls", "code": "27112302" },
      { "name": "Scribers", "code": "27112303" },
      { "name": "Chalk lines", "code": "27112304" },
      { "name": "Metal markers or holders", "code": "27112305" },
      { "name": "Metal stamps", "code": "27112306" },
      { "name": "Chalk for chalk line", "code": "27112307" },
      { "name": "Marking cart", "code": "27112308" },
      { "name": "Marking pen", "code": "27112309" },
      { "name": "Chalk holder", "code": "27112310" },
      { "name": "Marking chalk", "code": "27112311" },
      { "name": "Surface gauge", "code": "27112312" },
      { "name": "Marking gauge", "code": "27112313" },
      { "name": "Fastener setting tools", "code": "27112400" },
      { "name": "Staple guns", "code": "27112401" },
      { "name": "Rivet tools", "code": "27112402" },
      { "name": "Banders", "code": "27112403" },
      { "name": "Anchor setting tools", "code": "27112404" },
      { "name": "Bolt heaters", "code": "27112405" },
      { "name": "Tag guns", "code": "27112406" },
      { "name": "Security tag detacher", "code": "27112407" },
      { "name": "Industrial staple remover", "code": "27112408" },
      { "name": "Explosive stud gun", "code": "27112409" },
      { "name": "Cable tie gun", "code": "27112410" },
      { "name": "Prying and bending tools", "code": "27112500" },
      { "name": "Pipe bending tools", "code": "27112501" },
      { "name": "Pry bars", "code": "27112502" },
      { "name": "Conduit benders", "code": "27112503" },
      { "name": "Wedges", "code": "27112504" },
      { "name": "Packing hooks", "code": "27112505" },
      { "name": "Hickeys", "code": "27112506" },
      { "name": "Wrecking or crow bar", "code": "27112507" },
      { "name": "Cable bender", "code": "27112508" },
      { "name": "Sealing tools", "code": "27112600" },
      { "name": "Putty knives", "code": "27112601" },
      { "name": "Caulking tools", "code": "27112602" },
      { "name": "Insertion tool", "code": "27112603" },
      { "name": "Putty bowl", "code": "27112604" },
      { "name": "Power tools", "code": "27112700" },
      { "name": "Power blowers", "code": "27112701" },
      { "name": "Power buffers", "code": "27112702" },
      { "name": "Power drills", "code": "27112703" },
      { "name": "Power grinders", "code": "27112704" },
      { "name": "Demolition hammers", "code": "27112705" },
      { "name": "Power planes", "code": "27112706" },
      { "name": "Power routers", "code": "27112707" },
      { "name": "Power sanders", "code": "27112708" },
      { "name": "Power saws", "code": "27112709" },
      { "name": "Power screwguns", "code": "27112710" },
      { "name": "Power staple guns", "code": "27112711" },
      { "name": "Power trimmers", "code": "27112712" },
      { "name": "Impact wrenches", "code": "27112713" },
      { "name": "Power caulking guns", "code": "27112714" },
      { "name": "Power chippers", "code": "27112715" },
      { "name": "Power nail guns", "code": "27112716" },
      { "name": "Heat guns", "code": "27112717" },
      { "name": "Engravers", "code": "27112718" },
      { "name": "Glue guns", "code": "27112719" },
      { "name": "Torque tools", "code": "27112720" },
      { "name": "Biscuit jointers", "code": "27112721" },
      { "name": "Beveling tool", "code": "27112722" },
      { "name": "Power shears", "code": "27112723" },
      { "name": "Power nibbler", "code": "27112724" },
      { "name": "Power riveter", "code": "27112725" },
      { "name": "Power rod cutter", "code": "27112726" },
      { "name": "Mortar gun", "code": "27112727" },
      { "name": "Power flaring tool", "code": "27112728" },
      { "name": "Tube crimper", "code": "27112729" },
      { "name": "Power grease gun", "code": "27112730" },
      { "name": "Power scissors", "code": "27112731" },
      { "name": "Power pipe bender", "code": "27112732" },
      { "name": "Angle cutter", "code": "27112733" },
      { "name": "Jigsaw", "code": "27112734" },
      { "name": "Scroll saw", "code": "27112735" },
      { "name": "Wall chaser", "code": "27112736" },
      { "name": "Orbital sander", "code": "27112737" },
      { "name": "Detail sander", "code": "27112738" },
      { "name": "Belt sander", "code": "27112739" },
      { "name": "Bench sander", "code": "27112740" },
      { "name": "File sander or finger sander", "code": "27112741" },
      { "name": "Disc sander", "code": "27112742" },
      { "name": "Radial arm saw", "code": "27112743" },
      { "name": "Pneumatic saw", "code": "27112744" },
      { "name": "Concrete saw", "code": "27112745" },
      { "name": "Chain saw", "code": "27112746" },
      { "name": "Reciprocating saw", "code": "27112747" },
      { "name": "Miter saw", "code": "27112748" },
      { "name": "Angle grinder", "code": "27112749" },
      { "name": "Battery powered crimping tool", "code": "27112750" },
      { "name": "Power cable cutter accessories", "code": "27112751" },
      { "name": "Tool attachments and accessories", "code": "27112800" },
      { "name": "Saw blades", "code": "27112802" },
      { "name": "End mills", "code": "27112803" },
      { "name": "Stamping dies or punches", "code": "27112804" },
      { "name": "Threading dies", "code": "27112805" },
      { "name": "Threading taps", "code": "27112806" },
      { "name": "Chucks", "code": "27112807" },
      { "name": "Collets", "code": "27112808" },
      { "name": "Tool holders", "code": "27112809" },
      { "name": "Thread repair kits", "code": "27112810" },
      { "name": "Arbors", "code": "27112811" },
      { "name": "Countersinks", "code": "27112812" },
      { "name": "Extension pole", "code": "27112813" },
      { "name": "Screwdriver bits", "code": "27112814" },
      { "name": "Nut driver bits", "code": "27112815" },
      { "name": "Vise jaw liners or caps", "code": "27112818" },
      { "name": "Skiving tool cutter blades", "code": "27112819" },
      { "name": "Lug crimping tool dies", "code": "27112820" },
      { "name": "Router bits", "code": "27112821" },
      { "name": "Hub adapters", "code": "27112822" },
      { "name": "Cutting chains", "code": "27112823" },
      { "name": "Chuck sleeves", "code": "27112824" },
      { "name": "Tool template sets", "code": "27112825" },
      { "name": "Hole saws", "code": "27112826" },
      { "name": "Magnetizer demagnetizer devices", "code": "27112827" },
      { "name": "Impact socket", "code": "27112828" },
      {
        "name": "Impact wrench attachments and accessories",
        "code": "27112829"
      },
      { "name": "Screwdriver accessories and supplies", "code": "27112830" },
      { "name": "Wrench accessories and supplies", "code": "27112831" },
      { "name": "Socket attachments and accessories", "code": "27112832" },
      { "name": "Drill bit case", "code": "27112833" },
      { "name": "Hand pipe threader case", "code": "27112834" },
      { "name": "Machine reamer", "code": "27112835" },
      { "name": "Screwdriver bit set", "code": "27112836" },
      { "name": "Hand reamer", "code": "27112837" },
      { "name": "Cutting disc", "code": "27112838" },
      { "name": "Chisel bit", "code": "27112839" },
      { "name": "Guide drill bit", "code": "27112840" },
      { "name": "Hand drill bit for metal", "code": "27112841" },
      { "name": "Hand drill bit for wood", "code": "27112842" },
      { "name": "Hand drill bit for stone", "code": "27112843" },
      { "name": "Lapping plate", "code": "27112844" },
      { "name": "Drill bit set", "code": "27112845" },
      { "name": "Heat gun adapter", "code": "27112846" },
      { "name": "Die case", "code": "27112847" },
      { "name": "Dispensing tools", "code": "27112900" },
      { "name": "Grease guns", "code": "27112901" },
      { "name": "Industrial funnels", "code": "27112902" },
      { "name": "Hand sprayers", "code": "27112903" },
      { "name": "Resin guns", "code": "27112904" },
      { "name": "Oil can", "code": "27112905" },
      { "name": "Caulking guns", "code": "27112906" },
      { "name": "Broadcast spreaders", "code": "27112907" },
      { "name": "Oil gun", "code": "27112908" },
      { "name": "Roofing mop", "code": "27112909" },
      { "name": "Portable waste oil storage", "code": "27112910" },
      { "name": "Oil cup", "code": "27112911" },
      { "name": "Oil changer", "code": "27112912" },
      { "name": "Oil lubricator", "code": "27112913" },
      { "name": "Grease lubricator", "code": "27112914" },
      { "name": "Natural gas dispenser", "code": "27112915" },
      { "name": "Liquid fuel dispenser or gasoline pump", "code": "27112916" },
      { "name": "Brushes", "code": "27113000" },
      { "name": "Scratch brushes", "code": "27113001" },
      { "name": "Tube brushes", "code": "27113002" },
      { "name": "Applicator brushes", "code": "27113003" },
      { "name": "Stretcher brushes", "code": "27113004" },
      { "name": "Roofing brush", "code": "27113005" },
      { "name": "Pulling tools", "code": "27113100" },
      { "name": "Fish tape", "code": "27113101" },
      { "name": "Fuse pullers", "code": "27113102" },
      { "name": "Fids", "code": "27113103" },
      { "name": "Stake pullers", "code": "27113104" },
      { "name": "Spring expander", "code": "27113105" },
      { "name": "Tool kits", "code": "27113200" },
      { "name": "General tool kits", "code": "27113201" },
      { "name": "Bearing fitting tool kits", "code": "27113202" },
      { "name": "Computer tool kits", "code": "27113203" },
      { "name": "Electrician kits", "code": "27113204" },
      { "name": "Precision hand tools", "code": "27113300" },
      { "name": "Magnifying glass", "code": "27113301" },
      { "name": "Hydraulic machinery and equipment", "code": "27120000" },
      { "name": "Hydraulic presses", "code": "27121500" },
      { "name": "Press return springs", "code": "27121501" },
      { "name": "Hydraulic press frames", "code": "27121502" },
      { "name": "Hydraulic press columns", "code": "27121503" },
      { "name": "Industrial hydraulic press", "code": "27121504" },
      { "name": "Hydraulic cylinders and pistons", "code": "27121600" },
      { "name": "Cylinder pistons", "code": "27121601" },
      { "name": "Hydraulic cylinders", "code": "27121602" },
      { "name": "Hydraulic cylinder piston rods", "code": "27121603" },
      {
        "name": "Hydraulic cylinder or component repair kits",
        "code": "27121604"
      },
      { "name": "Hydraulic cylinder barrels", "code": "27121605" },
      { "name": "Mounting bases for hydraulic cylinders", "code": "27121606" },
      { "name": "Hydraulic hose and tube fittings", "code": "27121700" },
      { "name": "Hydraulic quick connectors", "code": "27121701" },
      { "name": "Hydraulic tees or crosses", "code": "27121702" },
      { "name": "Ferrules", "code": "27121703" },
      { "name": "Hydraulic unions", "code": "27121704" },
      { "name": "Hydraulic or compression elbows", "code": "27121705" },
      { "name": "Ferrule nuts", "code": "27121706" },
      { "name": "Crimp connectors", "code": "27121707" },
      { "name": "Hydraulic tools", "code": "27121800" },
      { "name": "Manhole cover lifters", "code": "27121801" },
      { "name": "Hydraulic accumulators", "code": "27121802" },
      { "name": "Hydraulic clamp", "code": "27121803" },
      { "name": "Hydraulic shears", "code": "27121804" },
      { "name": "Hydraulic extractor", "code": "27121805" },
      { "name": "Hydraulic pipe bender", "code": "27121806" },
      { "name": "Hydraulic puller", "code": "27121807" },
      { "name": "Hydraulic chain cutter", "code": "27121808" },
      { "name": "Hydraulic nut breaking tool", "code": "27121809" },
      { "name": "Hydraulic hand crimp tool", "code": "27121810" },
      { "name": "Hydraulic crimp tool accessory", "code": "27121811" },
      { "name": "Hydraulic remote", "code": "27121812" },
      { "name": "Hydraulic classifier", "code": "27121813" },
      { "name": "Compression die adaptor", "code": "27121814" },
      { "name": "Hydraulic wrench", "code": "27121815" },
      { "name": "Pneumatic machinery and equipment", "code": "27130000" },
      { "name": "Pneumatic tools", "code": "27131500" },
      { "name": "Pneumatic impact wrenches", "code": "27131501" },
      { "name": "Compressed air gun", "code": "27131502" },
      { "name": "Pneumatic hammer", "code": "27131504" },
      { "name": "Pneumatic drill", "code": "27131505" },
      { "name": "Pneumatic nail drivers", "code": "27131506" },
      { "name": "Pneumatic sanding machines", "code": "27131507" },
      { "name": "Air motors", "code": "27131508" },
      { "name": "Pneumatic accumulators", "code": "27131509" },
      { "name": "Accumulator bladders or bags", "code": "27131510" },
      { "name": "Pneumatic grinders", "code": "27131511" },
      { "name": "Pneumatic screwdriver", "code": "27131512" },
      { "name": "Pneumatic file", "code": "27131513" },
      { "name": "Pneumatic pliers", "code": "27131514" },
      { "name": "Pneumatic wrench non impact", "code": "27131515" },
      { "name": "Pneumatic pen", "code": "27131516" },
      { "name": "Industrial pneumatic stapler", "code": "27131517" },
      { "name": "Pneumatic auto glass removal tool", "code": "27131518" },
      { "name": "Pneumatic or air punch", "code": "27131519" },
      { "name": "Pneumatic rod cutter", "code": "27131520" },
      { "name": "Pneumatic riveter", "code": "27131521" },
      { "name": "Miniature pneumatic sander", "code": "27131522" },
      { "name": "Pneumatic rammer", "code": "27131523" },
      { "name": "Pneumatic countersink", "code": "27131524" },
      { "name": "Pneumatic shears", "code": "27131525" },
      { "name": "Pneumatic press", "code": "27131526" },
      { "name": "Pneumatic tool set", "code": "27131527" },
      { "name": "Pneumatic nail puller", "code": "27131528" },
      { "name": "Air fittings and connectors", "code": "27131600" },
      { "name": "Air manifolds", "code": "27131601" },
      { "name": "Air regulators", "code": "27131603" },
      { "name": "Pneumatic lubricators", "code": "27131604" },
      { "name": "Air curtain", "code": "27131605" },
      { "name": "Vacuum cups or pads", "code": "27131608" },
      { "name": "Pneumatic silencers", "code": "27131609" },
      { "name": "Lubricator or regulator repair kits", "code": "27131610" },
      { "name": "Air coupling", "code": "27131613" },
      { "name": "Pneumatic adapters", "code": "27131614" },
      { "name": "Pneumatic vacuum generator", "code": "27131615" },
      { "name": "Pneumatic cylinders and components", "code": "27131700" },
      { "name": "Pneumatic cylinders", "code": "27131701" },
      { "name": "Pneumatic cylinder rod accessories", "code": "27131702" },
      { "name": "Pneumatic cylinder pistons", "code": "27131703" },
      { "name": "Pneumatic cylinder piston rods", "code": "27131704" },
      { "name": "Pneumatic cylinder barrel", "code": "27131705" },
      { "name": "Pneumatic cylinder end caps", "code": "27131706" },
      {
        "name": "Pneumatic cylinder or component repair kits",
        "code": "27131707"
      },
      { "name": "Mounting bases for pneumatic cylinder", "code": "27131708" },
      { "name": "Pneumatic cylinder cushion rings", "code": "27131709" },
      { "name": "Automotive specialty tools", "code": "27140000" },
      { "name": "Body tools", "code": "27141000" },
      { "name": "Trim or molding tools", "code": "27141001" },
      { "name": "Suspension tools", "code": "27141100" },
      { "name": "Steering wheel puller", "code": "27141101" }
    ]
  },
  {
    "name": "Structures and Building and Construction and Manufacturing Components and Supplies",
    "code": "30000000",
    "subcategories": [
      { "name": "Structural components and basic shapes", "code": "30100000" },
      { "name": "Angles", "code": "30101500" },
      { "name": "Ferrous alloy angles", "code": "30101501" },
      { "name": "Non ferrous alloy angles", "code": "30101502" },
      { "name": "Iron angles", "code": "30101503" },
      { "name": "Steel angles", "code": "30101504" },
      { "name": "Stainless steel angles", "code": "30101505" },
      { "name": "Aluminum angles", "code": "30101506" },
      { "name": "Magnesium angles", "code": "30101507" },
      { "name": "Titanium angles", "code": "30101508" },
      { "name": "Copper angles", "code": "30101509" },
      { "name": "Brass angles", "code": "30101510" },
      { "name": "Bronze angles", "code": "30101511" },
      { "name": "Zinc angles", "code": "30101512" },
      { "name": "Tin angles", "code": "30101513" },
      { "name": "Lead angles", "code": "30101514" },
      { "name": "Plastic angles", "code": "30101515" },
      { "name": "Rubber angles", "code": "30101516" },
      { "name": "Precious metal angles", "code": "30101517" },
      { "name": "Beams", "code": "30101700" },
      { "name": "Ferrous alloy beams", "code": "30101701" },
      { "name": "Non ferrous alloy beams", "code": "30101702" },
      { "name": "Iron beams", "code": "30101703" },
      { "name": "Steel beams", "code": "30101704" },
      { "name": "Stainless steel beams", "code": "30101705" },
      { "name": "Aluminum beams", "code": "30101706" },
      { "name": "Magnesium beams", "code": "30101707" },
      { "name": "Titanium beams", "code": "30101708" },
      { "name": "Copper beams", "code": "30101709" },
      { "name": "Brass beams", "code": "30101710" },
      { "name": "Bronze beams", "code": "30101711" },
      { "name": "Zinc beams", "code": "30101712" },
      { "name": "Tin beams", "code": "30101713" },
      { "name": "Lead beams", "code": "30101714" },
      { "name": "Plastic beams", "code": "30101715" },
      { "name": "Rubber beams", "code": "30101716" },
      { "name": "Concrete beams", "code": "30101717" },
      { "name": "Precious metal beams", "code": "30101718" },
      { "name": "Haydite beam", "code": "30101719" },
      { "name": "Plywood beam", "code": "30101720" },
      { "name": "Channels", "code": "30101800" },
      { "name": "Ferrous alloy channels", "code": "30101801" },
      { "name": "Non ferrous alloy channels", "code": "30101802" },
      { "name": "Iron channels", "code": "30101803" },
      { "name": "Steel channels", "code": "30101804" },
      { "name": "Stainless steel channels", "code": "30101805" },
      { "name": "Aluminum channels", "code": "30101806" },
      { "name": "Magnesium channels", "code": "30101807" },
      { "name": "Titanium channels", "code": "30101808" },
      { "name": "Copper channels", "code": "30101809" },
      { "name": "Brass channels", "code": "30101810" },
      { "name": "Bronze channels", "code": "30101811" },
      { "name": "Zinc channels", "code": "30101812" },
      { "name": "Tin channels", "code": "30101813" },
      { "name": "Lead channels", "code": "30101814" },
      { "name": "Plastic channels", "code": "30101815" },
      { "name": "Rubber channels", "code": "30101816" },
      { "name": "Precious metal channels", "code": "30101817" },
      { "name": "Fiberglass channel", "code": "30101818" },
      { "name": "Foil", "code": "30102000" },
      { "name": "Ferrous alloy foil", "code": "30102001" },
      { "name": "Non ferrous alloy foil", "code": "30102002" },
      { "name": "Iron foil", "code": "30102003" },
      { "name": "Steel foil", "code": "30102004" },
      { "name": "Stainless steel foil", "code": "30102005" },
      { "name": "Aluminum foil", "code": "30102006" },
      { "name": "Magnesium foil", "code": "30102007" },
      { "name": "Titanium foil", "code": "30102008" },
      { "name": "Copper foil", "code": "30102009" },
      { "name": "Brass foil", "code": "30102010" },
      { "name": "Bronze foil", "code": "30102011" },
      { "name": "Zinc foil", "code": "30102012" },
      { "name": "Tin foil", "code": "30102013" },
      { "name": "Lead foil", "code": "30102014" },
      { "name": "Plastic foil", "code": "30102015" },
      { "name": "Tantalum foil", "code": "30102016" },
      { "name": "Plate", "code": "30102200" },
      { "name": "Ferrous alloy plate", "code": "30102201" },
      { "name": "Non ferrous plate", "code": "30102202" },
      { "name": "Iron plate", "code": "30102203" },
      { "name": "Steel plate", "code": "30102204" },
      { "name": "Stainless steel plate", "code": "30102205" },
      { "name": "Aluminum plate", "code": "30102206" },
      { "name": "Magnesium plate", "code": "30102207" },
      { "name": "Titanium plate", "code": "30102208" },
      { "name": "Copper plate", "code": "30102209" },
      { "name": "Brass plate", "code": "30102210" },
      { "name": "Bronze plate", "code": "30102211" },
      { "name": "Zinc plate", "code": "30102212" },
      { "name": "Tin plate", "code": "30102213" },
      { "name": "Lead plate", "code": "30102214" },
      { "name": "Plastic plate", "code": "30102215" },
      { "name": "Rubber plate", "code": "30102216" },
      { "name": "Concrete plate", "code": "30102217" },
      { "name": "Precious metal plate", "code": "30102218" },
      { "name": "Nickel plate", "code": "30102220" },
      { "name": "Fiber plate", "code": "30102221" },
      { "name": "Woodwool plate", "code": "30102222" },
      { "name": "Wood veneer plate", "code": "30102223" },
      { "name": "Coreboard plate", "code": "30102224" },
      { "name": "Cork plate", "code": "30102225" },
      { "name": "Butyl plate", "code": "30102226" },
      { "name": "Tantalum plate", "code": "30102227" },
      { "name": "Profiles", "code": "30102300" },
      { "name": "Ferrous alloy profiles", "code": "30102301" },
      { "name": "Non ferrous alloy profiles", "code": "30102302" },
      { "name": "Iron profiles", "code": "30102303" },
      { "name": "Steel profiles", "code": "30102304" },
      { "name": "Stainless steel profiles", "code": "30102305" },
      { "name": "Aluminum profiles", "code": "30102306" },
      { "name": "Magnesium profiles", "code": "30102307" },
      { "name": "Titanium profiles", "code": "30102308" },
      { "name": "Copper profiles", "code": "30102309" },
      { "name": "Brass profiles", "code": "30102310" },
      { "name": "Bronze profiles", "code": "30102311" },
      { "name": "Zinc profiles", "code": "30102312" },
      { "name": "Tin profiles", "code": "30102313" },
      { "name": "Lead profiles", "code": "30102314" },
      { "name": "Plastic profiles", "code": "30102315" },
      { "name": "Rubber profiles", "code": "30102316" },
      { "name": "Composite profiles", "code": "30102317" },
      { "name": "Rod", "code": "30102400" },
      { "name": "Ferrous alloy rods", "code": "30102401" },
      { "name": "Non ferrous alloy rods", "code": "30102402" },
      { "name": "Iron rods", "code": "30102403" },
      { "name": "Steel rods", "code": "30102404" },
      { "name": "Stainless steel rods", "code": "30102405" },
      { "name": "Aluminum rods", "code": "30102406" },
      { "name": "Magnesium rods", "code": "30102407" },
      { "name": "Titanium rods", "code": "30102408" },
      { "name": "Copper rods", "code": "30102409" },
      { "name": "Brass rods", "code": "30102410" },
      { "name": "Bronze rods", "code": "30102411" },
      { "name": "Zinc rods", "code": "30102412" },
      { "name": "Tin rods", "code": "30102413" },
      { "name": "Lead rods", "code": "30102414" },
      { "name": "Plastic rods", "code": "30102415" },
      { "name": "Rubber rods", "code": "30102416" },
      { "name": "Nickel rod", "code": "30102417" },
      { "name": "Piling", "code": "30102800" },
      { "name": "Aluminum pilings", "code": "30102801" },
      { "name": "Concrete pilings", "code": "30102802" },
      { "name": "Steel pilings", "code": "30102803" },
      { "name": "Wooden piling", "code": "30102804" },
      { "name": "Post", "code": "30102900" },
      { "name": "Cement or concrete posts", "code": "30102901" },
      { "name": "Metal posts", "code": "30102903" },
      { "name": "Wooden posts", "code": "30102904" },
      { "name": "Plastic posts", "code": "30102905" },
      { "name": "Fiberglass posts", "code": "30102906" },
      { "name": "Treated wooden post", "code": "30102907" },
      { "name": "Rails", "code": "30103100" },
      { "name": "Steel rail", "code": "30103101" },
      { "name": "Aluminum rail", "code": "30103102" },
      { "name": "Metal rail", "code": "30103103" },
      { "name": "Wooden rail", "code": "30103104" },
      { "name": "Grating", "code": "30103200" },
      { "name": "Steel grating", "code": "30103201" },
      { "name": "Stainless steel grating", "code": "30103202" },
      { "name": "Aluminum grating", "code": "30103203" },
      { "name": "Fiberglass grating", "code": "30103204" },
      { "name": "Iron grating", "code": "30103205" },
      { "name": "Plastic grating", "code": "30103206" },
      { "name": "Laminated grating", "code": "30103207" },
      { "name": "Wood grating", "code": "30103208" },
      { "name": "Grating cover", "code": "30103209" },
      { "name": "Honeycomb core", "code": "30103500" },
      { "name": "Aluminum honeycomb core", "code": "30103501" },
      { "name": "Magnesium honeycomb core", "code": "30103502" },
      { "name": "Foam honeycomb core", "code": "30103503" },
      { "name": "Plastic honeycomb core", "code": "30103504" },
      { "name": "Wooden honeycomb core", "code": "30103505" },
      { "name": "Ferrous metal honeycomb core", "code": "30103506" },
      { "name": "Bronze honeycomb core", "code": "30103507" },
      { "name": "Copper honeycomb core", "code": "30103508" },
      { "name": "Steel honeycomb core", "code": "30103509" },
      { "name": "Lead honeycomb core", "code": "30103510" },
      { "name": "Zinc honeycomb core", "code": "30103511" },
      { "name": "Titanium honeycomb core", "code": "30103512" },
      { "name": "Brass honeycomb core", "code": "30103513" },
      { "name": "Non ferrous metal honeycomb core", "code": "30103514" },
      { "name": "Precious metal honeycomb core", "code": "30103515" },
      { "name": "Structural products", "code": "30103600" },
      { "name": "Wood beams", "code": "30103601" },
      { "name": "Wood composite beams", "code": "30103602" },
      { "name": "Framing lumber", "code": "30103603" },
      { "name": "Wood sheathing or sheets", "code": "30103604" },
      { "name": "Wood planks", "code": "30103605" },
      { "name": "Wood trusses", "code": "30103606" },
      { "name": "Wood joists", "code": "30103607" },
      { "name": "Wooden poles or telephone poles", "code": "30103608" },
      { "name": "Brattice board", "code": "30103609" },
      { "name": "Crib block", "code": "30103610" },
      { "name": "Shovel mat", "code": "30103611" },
      { "name": "Railway tie", "code": "30103612" },
      { "name": "Precast haydite element", "code": "30103613" },
      { "name": "Steel plate joist", "code": "30103614" },
      { "name": "Plate girder", "code": "30103615" },
      { "name": "Composite framework", "code": "30103616" },
      { "name": "Wooden framework", "code": "30103617" },
      { "name": "Steel framework", "code": "30103618" },
      { "name": "Precast concrete element", "code": "30103619" },
      { "name": "Crib block treated", "code": "30103620" },
      { "name": "Railway tie untreated", "code": "30103621" },
      { "name": "Square timber", "code": "30103622" },
      { "name": "Reinforcing bar or rebar or mesh", "code": "30103623" },
      { "name": "Braid", "code": "30103700" },
      { "name": "Stainless steel braid", "code": "30103701" },
      { "name": "Metallic fibers and filaments", "code": "30103800" },
      { "name": "Steel fiber", "code": "30103801" },
      { "name": "Tin fiber", "code": "30103802" },
      { "name": "Aluminum fiber", "code": "30103803" },
      { "name": "Alloy fiber", "code": "30103804" },
      { "name": "Shafts", "code": "30103900" },
      { "name": "Headed shafts", "code": "30103901" },
      { "name": "Hollow structural sections HSS", "code": "30104000" },
      { "name": "Round hollow structural section HSS", "code": "30104001" },
      { "name": "Square hollow structural section HSS", "code": "30104002" },
      {
        "name": "Rectangular hollow structural section HSS",
        "code": "30104003"
      },
      { "name": "Concrete and cement and plaster", "code": "30110000" },
      { "name": "Concrete and mortars", "code": "30111500" },
      { "name": "Foamed concrete", "code": "30111501" },
      { "name": "Conductive concrete", "code": "30111502" },
      { "name": "Insulating concrete", "code": "30111503" },
      { "name": "Mortars", "code": "30111504" },
      { "name": "Ready mix concrete", "code": "30111505" },
      { "name": "Grout", "code": "30111506" },
      { "name": "Soil cured concrete", "code": "30111507" },
      { "name": "Water permeable concrete", "code": "30111508" },
      { "name": "Asphalt based concrete", "code": "30111509" },
      { "name": "Cement and lime", "code": "30111600" },
      { "name": "Cement", "code": "30111601" },
      { "name": "Chlorinated lime", "code": "30111602" },
      { "name": "Hydraulic lime", "code": "30111603" },
      { "name": "Hydrated lime", "code": "30111604" },
      { "name": "Lean lime", "code": "30111605" },
      { "name": "Unslaked lime", "code": "30111607" },
      { "name": "Plasters", "code": "30111700" },
      { "name": "Gypsum plaster", "code": "30111701" },
      { "name": "Aggregates", "code": "30111800" },
      { "name": "Natural aggregate", "code": "30111801" },
      { "name": "Artificial aggregate", "code": "30111802" },
      { "name": "Recycled aggregate", "code": "30111803" },
      { "name": "Concrete reinforcement hardware", "code": "30111900" },
      { "name": "Concrete reinforcing fiber", "code": "30111901" },
      { "name": "Construction expansion joint", "code": "30111902" },
      { "name": "Wire mesh sheet", "code": "30111903" },
      { "name": "Roads and landscape", "code": "30120000" },
      { "name": "Bituminous derivatives", "code": "30121500" },
      { "name": "Coal tar", "code": "30121501" },
      { "name": "Creosote", "code": "30121503" },
      { "name": "Bitumen", "code": "30121504" },
      { "name": "Asphalts", "code": "30121600" },
      { "name": "Asphalt", "code": "30121601" },
      { "name": "Pitch", "code": "30121602" },
      { "name": "Gilsonite", "code": "30121603" },
      { "name": "Cutback products", "code": "30121604" },
      { "name": "Manhole frames with covers", "code": "30121605" },
      {
        "name": "Road and railroad construction materials",
        "code": "30121700"
      },
      { "name": "Geomesh", "code": "30121701" },
      { "name": "Geotextile", "code": "30121702" },
      { "name": "Bridge rail", "code": "30121703" },
      { "name": "Concrete slab", "code": "30121704" },
      { "name": "Paving slab", "code": "30121705" },
      { "name": "Concrete curb", "code": "30121706" },
      { "name": "Noise protection board", "code": "30121707" },
      { "name": "Brick tile", "code": "30121708" },
      { "name": "Ungraded crushed rock", "code": "30121709" },
      { "name": "Paving stone", "code": "30121710" },
      { "name": "Natural curbstone", "code": "30121711" },
      { "name": "Post cover", "code": "30121712" },
      { "name": "Manhole cover", "code": "30121713" },
      { "name": "Manhole box", "code": "30121714" },
      { "name": "Bridge expansion joint", "code": "30121715" },
      { "name": "Bridge seat mounting", "code": "30121716" },
      { "name": "Guardrail", "code": "30121717" },
      { "name": "Safety fence and net for rock drop", "code": "30121718" },
      { "name": "Safety separator for road", "code": "30121719" },
      { "name": "Drain pipe odor suppression device", "code": "30121720" },
      { "name": "Drainage earth and sand barrel", "code": "30121721" },
      { "name": "Landscape architecture materials", "code": "30121800" },
      { "name": "Tree grating", "code": "30121801" },
      { "name": "Tree protection rod", "code": "30121802" },
      { "name": "Artificial turf", "code": "30121803" },
      {
        "name": "Soil stabilizers and reinforcing materials",
        "code": "30121900"
      },
      { "name": "Soil stabilizer", "code": "30121901" },
      {
        "name": "Specialized external flooring and paving materials",
        "code": "30122000"
      },
      { "name": "Elastic sheet exterior paving material", "code": "30122001" },
      {
        "name": "Exterior permeable aggregate flooring material",
        "code": "30122002"
      },
      { "name": "Painted or coated exterior flooring", "code": "30122003" },
      { "name": "Non skid exterior paving material", "code": "30122004" },
      { "name": "Structural building products", "code": "30130000" },
      { "name": "Blocks", "code": "30131500" },
      { "name": "Concrete blocks", "code": "30131502" },
      { "name": "Stone blocks", "code": "30131503" },
      { "name": "Ceramic blocks", "code": "30131504" },
      { "name": "Haydite block", "code": "30131505" },
      { "name": "Natural rock slab", "code": "30131506" },
      { "name": "Light concrete block", "code": "30131507" },
      { "name": "Glass block", "code": "30131508" },
      { "name": "Sound proof block", "code": "30131509" },
      { "name": "Concrete block for revetment", "code": "30131510" },
      { "name": "Concrete armor unit", "code": "30131511" },
      {
        "name": "Autoclaved lightweight aerated concrete block",
        "code": "30131512"
      },
      { "name": "Rubber block", "code": "30131513" },
      {
        "name": "Reinforced concrete built up culvert block",
        "code": "30131514"
      },
      { "name": "Wood block", "code": "30131515" },
      { "name": "Braille block", "code": "30131516" },
      { "name": "Adobe block", "code": "30131517" },
      { "name": "Concrete block for bridges", "code": "30131518" },
      { "name": "Bricks", "code": "30131600" },
      { "name": "Ceramic bricks", "code": "30131602" },
      { "name": "Concrete bricks", "code": "30131603" },
      { "name": "Stone bricks", "code": "30131604" },
      { "name": "Sandlime brick", "code": "30131605" },
      { "name": "Loess brick", "code": "30131606" },
      { "name": "Clay brick", "code": "30131607" },
      { "name": "Salt glazed brick", "code": "30131608" },
      { "name": "Fabricated brick", "code": "30131609" },
      { "name": "Insulating fire brick", "code": "30131610" },
      { "name": "Wood brick", "code": "30131611" },
      { "name": "Tiles and flagstones", "code": "30131700" },
      { "name": "Stone tiles or flagstones", "code": "30131702" },
      { "name": "Concrete tiles or flagstones", "code": "30131703" },
      { "name": "Ceramic tiles or flagstones", "code": "30131704" },
      { "name": "Head stones", "code": "30131705" },
      { "name": "Metal tile or flagstone", "code": "30131706" },
      { "name": "Insulation", "code": "30140000" },
      { "name": "Thermal insulation", "code": "30141500" },
      { "name": "Weather stripping", "code": "30141501" },
      { "name": "Foam insulation", "code": "30141503" },
      { "name": "Thermal insulation sleeving", "code": "30141505" },
      { "name": "Fiber insulation", "code": "30141508" },
      { "name": "Door sweep", "code": "30141510" },
      { "name": "Window film", "code": "30141511" },
      { "name": "Thermal insulation kits", "code": "30141512" },
      { "name": "Thermal Insulating bricks", "code": "30141513" },
      { "name": "Expanded polystyrene EPS insulation", "code": "30141514" },
      { "name": "Extruded polystyrene XPS insulation", "code": "30141515" },
      { "name": "Mineral wool insulation", "code": "30141516" },
      { "name": "Specialty insulation", "code": "30141600" },
      { "name": "Acoustical insulation", "code": "30141601" },
      { "name": "Heat Shields", "code": "30141603" },
      { "name": "Spray coating insulation", "code": "30141604" },
      { "name": "Fireproofing material", "code": "30141605" },
      { "name": "Insulation sealing layers", "code": "30141700" },
      { "name": "Asphalt board sealing layer", "code": "30141701" },
      { "name": "Plastic sealing layer", "code": "30141702" },
      { "name": "Rubber sealing layer", "code": "30141703" },
      { "name": "Exterior finishing materials", "code": "30150000" },
      { "name": "Roofing materials", "code": "30151500" },
      { "name": "Roll roofing", "code": "30151501" },
      { "name": "Roof valleys", "code": "30151502" },
      { "name": "Roofing fabrics", "code": "30151503" },
      { "name": "Roofing membranes", "code": "30151505" },
      { "name": "Shakes", "code": "30151507" },
      { "name": "Shingles", "code": "30151508" },
      { "name": "Rubber support block", "code": "30151509" },
      { "name": "Slate roofing", "code": "30151510" },
      { "name": "Concrete roofing tile", "code": "30151511" },
      { "name": "Brick roofing tile", "code": "30151512" },
      { "name": "Ceramic roof tile", "code": "30151513" },
      { "name": "Metal roof tile", "code": "30151514" },
      { "name": "Wooden roof tile", "code": "30151515" },
      { "name": "Roofing accessories", "code": "30151600" },
      { "name": "Roof fascias", "code": "30151601" },
      { "name": "Flashings", "code": "30151602" },
      { "name": "Gravel stops", "code": "30151603" },
      { "name": "Roofing drains", "code": "30151605" },
      { "name": "Roofing vents", "code": "30151607" },
      { "name": "Soffits", "code": "30151608" },
      { "name": "Roof curbs", "code": "30151609" },
      { "name": "Hip and ridge", "code": "30151610" },
      { "name": "Rain gutters and accessories", "code": "30151700" },
      { "name": "Downspouts", "code": "30151701" },
      { "name": "Drip caps", "code": "30151702" },
      { "name": "Gutters", "code": "30151703" },
      { "name": "Splashblocks", "code": "30151704" },
      { "name": "Siding and exterior wall materials", "code": "30151800" },
      { "name": "Shutters", "code": "30151801" },
      { "name": "Siding", "code": "30151802" },
      { "name": "Siding butt joints", "code": "30151803" },
      { "name": "Stucco", "code": "30151805" },
      { "name": "Glass curtainwalling", "code": "30151806" },
      { "name": "Exterior trim material", "code": "30151807" },
      { "name": "Brick mould", "code": "30151808" },
      { "name": "Finishing materials and products", "code": "30151900" },
      { "name": "Awnings", "code": "30151901" },
      { "name": "Canopy", "code": "30151902" },
      { "name": "Fencing", "code": "30152000" },
      { "name": "Metal fencing", "code": "30152001" },
      { "name": "Wood fencing", "code": "30152002" },
      { "name": "Fibrocement fencing", "code": "30152003" },
      { "name": "Surface", "code": "30152100" },
      { "name": "Shot steel", "code": "30152101" },
      { "name": "Interior finishing materials", "code": "30160000" },
      { "name": "Wall finishing materials", "code": "30161500" },
      { "name": "Wallboard", "code": "30161501" },
      { "name": "Wallpapers", "code": "30161502" },
      { "name": "Drywall", "code": "30161503" },
      { "name": "Corner guards", "code": "30161504" },
      { "name": "Panels or paneling", "code": "30161505" },
      { "name": "Wallpaper roller", "code": "30161508" },
      { "name": "Gypsum board", "code": "30161509" },
      { "name": "Plastic wall covering", "code": "30161510" },
      { "name": "Wall fabric", "code": "30161511" },
      { "name": "Ceiling materials", "code": "30161600" },
      { "name": "Acoustic ceiling tiles", "code": "30161601" },
      { "name": "Ceiling panels", "code": "30161602" },
      { "name": "Coffers", "code": "30161603" },
      { "name": "Suspended ceiling systems", "code": "30161604" },
      { "name": "Flooring", "code": "30161700" },
      { "name": "Carpeting", "code": "30161701" },
      { "name": "Wood flooring", "code": "30161702" },
      { "name": "Linoleum", "code": "30161703" },
      { "name": "Rubber flooring", "code": "30161705" },
      { "name": "Stone or tile flooring", "code": "30161706" },
      { "name": "Vinyl flooring", "code": "30161707" },
      { "name": "Knotted carpeting", "code": "30161708" },
      { "name": "Tufted carpeting", "code": "30161709" },
      { "name": "Laminate flooring", "code": "30161710" },
      { "name": "Outdoor carpeting", "code": "30161711" },
      { "name": "Flooring joists", "code": "30161712" },
      { "name": "Carpet pads", "code": "30161713" },
      { "name": "Cork flooring", "code": "30161714" },
      { "name": "Duckboards", "code": "30161715" },
      { "name": "Access flooring", "code": "30161717" },
      { "name": "Non skid steel flooring", "code": "30161718" },
      { "name": "Carpet or rug underlays", "code": "30161719" },
      { "name": "Floor panel", "code": "30161720" },
      { "name": "Flooring threshold", "code": "30161721" },
      { "name": "Cabinetry", "code": "30161800" },
      { "name": "Cabinets", "code": "30161801" },
      { "name": "Domestic cupboard", "code": "30161803" },
      { "name": "School cupboard", "code": "30161804" },
      { "name": "Laboratory cupboard", "code": "30161805" },
      { "name": "Wardrobe", "code": "30161806" },
      { "name": "Drawer", "code": "30161807" },
      { "name": "Drawer slide", "code": "30161808" },
      { "name": "Bumper pad", "code": "30161809" },
      { "name": "Tri view cabinet", "code": "30161810" },
      { "name": "Molding and millwork", "code": "30161900" },
      { "name": "Louvers", "code": "30161901" },
      { "name": "Columns", "code": "30161902" },
      { "name": "Wainscoting", "code": "30161903" },
      { "name": "Cornices", "code": "30161904" },
      { "name": "Door surrounds", "code": "30161905" },
      { "name": "Molding", "code": "30161906" },
      { "name": "Stair parts", "code": "30161908" },
      { "name": "Interior laminates", "code": "30162000" },
      { "name": "High pressure laminate", "code": "30162001" },
      { "name": "Specialty laminate", "code": "30162002" },
      { "name": "Metal laminate", "code": "30162003" },
      { "name": "Edgebanding laminate", "code": "30162004" },
      { "name": "Rigid thermal foil RTF", "code": "30162005" },
      { "name": "Stairs and stairways", "code": "30162100" },
      { "name": "Steel stairs", "code": "30162101" },
      { "name": "Wooden stairs", "code": "30162102" },
      { "name": "Concrete stairs", "code": "30162103" },
      { "name": "Stair nosing", "code": "30162104" },
      { "name": "Countertops", "code": "30162200" },
      { "name": "Laminate countertop", "code": "30162201" },
      { "name": "Cultured marble countertop", "code": "30162202" },
      { "name": "Solid surface countertop", "code": "30162203" },
      { "name": "Granite countertop", "code": "30162204" },
      { "name": "Cabinet accessories", "code": "30162300" },
      { "name": "Lazy susan hardware or assembly", "code": "30162301" },
      { "name": "Spice rack or spice drawer insert", "code": "30162302" },
      {
        "name": "Pull out waste basket hardware or assembly",
        "code": "30162303"
      },
      { "name": "Pantry hardware or assembly", "code": "30162304" },
      { "name": "Tilt out tray hardware or assembly", "code": "30162305" },
      {
        "name": "Door mount or sliding towel hardware or assembly",
        "code": "30162306"
      },
      {
        "name": "Pull out or tip out hamper hardware or assembly",
        "code": "30162307"
      },
      { "name": "Bread box lid or assembly", "code": "30162308" },
      { "name": "Stemware rack", "code": "30162309" },
      { "name": "Roll out tray", "code": "30162310" },
      { "name": "Slide out storage bin or assembly", "code": "30162311" },
      { "name": "Partition walls", "code": "30162400" },
      { "name": "Folding wall", "code": "30162401" },
      { "name": "Screen wall or cubicle", "code": "30162402" },
      { "name": "Interior wall system", "code": "30162403" },
      { "name": "Cold storage wall", "code": "30162404" },
      { "name": "Doors and windows and glass", "code": "30170000" },
      { "name": "Doors", "code": "30171500" },
      { "name": "Glass doors", "code": "30171501" },
      { "name": "Screen doors", "code": "30171502" },
      { "name": "Rolling doors", "code": "30171503" },
      { "name": "Wooden doors", "code": "30171504" },
      { "name": "Metal doors", "code": "30171505" },
      { "name": "Storm doors", "code": "30171506" },
      { "name": "Door frames", "code": "30171507" },
      { "name": "Pocket doors", "code": "30171508" },
      { "name": "Revolving doors", "code": "30171509" },
      { "name": "Automatic doors", "code": "30171510" },
      { "name": "Swing door", "code": "30171511" },
      { "name": "Door openers", "code": "30171512" },
      { "name": "Kick plates", "code": "30171513" },
      { "name": "Door closers", "code": "30171514" },
      { "name": "Inspection door", "code": "30171515" },
      { "name": "Door screen", "code": "30171516" },
      { "name": "Sound proof door", "code": "30171517" },
      { "name": "Blem door", "code": "30171518" },
      { "name": "Blast proof door", "code": "30171519" },
      { "name": "Door and window loop", "code": "30171520" },
      { "name": "Water tight door", "code": "30171521" },
      { "name": "Air tight door", "code": "30171522" },
      { "name": "Fire door", "code": "30171523" },
      { "name": "Shielding door", "code": "30171524" },
      { "name": "Pressure door", "code": "30171525" },
      { "name": "Folding door", "code": "30171526" },
      { "name": "Melamine door", "code": "30171527" },
      { "name": "Windows", "code": "30171600" },
      { "name": "Double hung windows", "code": "30171604" },
      { "name": "Single hung windows", "code": "30171605" },
      { "name": "Casement windows", "code": "30171606" },
      { "name": "Horizontal slider windows", "code": "30171607" },
      { "name": "Tilt or transom windows", "code": "30171608" },
      { "name": "Fixed windows", "code": "30171609" },
      { "name": "Bay windows", "code": "30171610" },
      { "name": "Bow windows", "code": "30171611" },
      { "name": "Projected windows", "code": "30171612" },
      { "name": "Window walls", "code": "30171613" },
      { "name": "Window screens", "code": "30171614" },
      { "name": "French windows", "code": "30171615" },
      { "name": "Glass products", "code": "30171700" },
      { "name": "Paving blocks", "code": "30171701" },
      { "name": "Beveled glass", "code": "30171703" },
      { "name": "Leaded glass", "code": "30171704" },
      { "name": "Laminated glass", "code": "30171705" },
      { "name": "Tempered glass", "code": "30171706" },
      { "name": "Safety glass", "code": "30171707" },
      { "name": "Float glass", "code": "30171708" },
      { "name": "Wired glass", "code": "30171709" },
      { "name": "Insulating glass", "code": "30171710" },
      { "name": "Corrugated glass", "code": "30171711" },
      { "name": "Cathedral glass", "code": "30171712" },
      { "name": "Skylights", "code": "30171800" },
      { "name": "Fixed skylights", "code": "30171801" },
      { "name": "Vented skylights", "code": "30171802" },
      { "name": "Tube skylights", "code": "30171803" },
      { "name": "Smoke lid", "code": "30171804" },
      { "name": "Window frames", "code": "30171900" },
      { "name": "Double hung window frames", "code": "30171901" },
      { "name": "Single hung window frames", "code": "30171902" },
      { "name": "Casement window frames", "code": "30171903" },
      { "name": "Horizontal slider window frames", "code": "30171904" },
      { "name": "Tilt or transom window frames", "code": "30171905" },
      { "name": "Fixed window frames", "code": "30171906" },
      { "name": "Window sill", "code": "30171907" },
      { "name": "Window arch", "code": "30171908" },
      { "name": "Gates", "code": "30172000" },
      { "name": "Single bar gate", "code": "30172001" },
      { "name": "Double bar gate", "code": "30172002" },
      { "name": "Garage doors and operators", "code": "30172100" },
      { "name": "Pan garage door", "code": "30172101" },
      { "name": "Insulated sandwich garage door", "code": "30172102" },
      { "name": "Wood garage door", "code": "30172103" },
      { "name": "Carriage garage door", "code": "30172104" },
      { "name": "Screen garage door", "code": "30172105" },
      { "name": "PVC garage door", "code": "30172106" },
      { "name": "Chain link garage door", "code": "30172107" },
      { "name": "Rolling garage door", "code": "30172108" },
      { "name": "Garage door component", "code": "30172109" },
      { "name": "Garage door accessory", "code": "30172110" },
      { "name": "Garage door complete operator", "code": "30172111" },
      { "name": "Garage door operator component", "code": "30172112" },
      { "name": "Garage door operator accessory", "code": "30172113" },
      { "name": "Plumbing fixtures", "code": "30180000" },
      { "name": "Sanitary ware", "code": "30181500" },
      { "name": "Bathtubs", "code": "30181501" },
      { "name": "Bidets", "code": "30181502" },
      { "name": "Showers", "code": "30181503" },
      { "name": "Sinks", "code": "30181504" },
      { "name": "Toilets", "code": "30181505" },
      { "name": "Urinals", "code": "30181506" },
      { "name": "Bathtub or shower enclosures", "code": "30181507" },
      { "name": "Restroom partitions", "code": "30181508" },
      { "name": "Toilet bowls", "code": "30181511" },
      { "name": "Toilet tank covers", "code": "30181514" },
      { "name": "Toilet tanks", "code": "30181515" },
      { "name": "Sauna", "code": "30181516" },
      { "name": "Whirlpool tub", "code": "30181517" },
      { "name": "Non sanitary residential fixtures", "code": "30181600" },
      { "name": "Soap dish", "code": "30181601" },
      { "name": "Towel bar or ring or stand or hook", "code": "30181602" },
      { "name": "Toilet seat", "code": "30181603" },
      { "name": "Toilet seat lid", "code": "30181604" },
      { "name": "Drain", "code": "30181605" },
      { "name": "Robe hook", "code": "30181606" },
      { "name": "Shower curtain or assembly", "code": "30181607" },
      { "name": "Shower rod", "code": "30181608" },
      { "name": "Shower caddy", "code": "30181609" },
      { "name": "Toilet tissue holder", "code": "30181610" },
      { "name": "Toothbrush or tumbler holder", "code": "30181611" },
      { "name": "Shaving razor hook", "code": "30181612" },
      { "name": "Bathtub or whirlpool apron or skirt", "code": "30181613" },
      { "name": "Soap dispenser", "code": "30181614" },
      { "name": "Faucets or taps", "code": "30181700" },
      { "name": "Spigot", "code": "30181701" },
      { "name": "Faucet unit", "code": "30181702" },
      {
        "name": "Faucet and shower heads, jets and parts and accessories",
        "code": "30181800"
      },
      { "name": "Shower head", "code": "30181801" },
      { "name": "Faucet aerator", "code": "30181802" },
      { "name": "Hand held shower unit", "code": "30181803" },
      { "name": "Faucet handle", "code": "30181804" },
      {
        "name": "Combination fixed and hand held shower head",
        "code": "30181805"
      },
      { "name": "Whirlpool jet", "code": "30181806" },
      { "name": "Spout", "code": "30181807" },
      { "name": "Rough in valve", "code": "30181808" },
      { "name": "Hand shower repair kit", "code": "30181809" },
      { "name": "Faucet trim", "code": "30181810" },
      { "name": "Faucet repair kit", "code": "30181811" },
      { "name": "Body spray head", "code": "30181812" },
      {
        "name": "Construction and maintenance support equipment",
        "code": "30190000"
      },
      { "name": "Ladders and scaffolding", "code": "30191500" },
      { "name": "Ladders", "code": "30191501" },
      { "name": "Scaffolding", "code": "30191502" },
      { "name": "Step stool", "code": "30191505" },
      { "name": "Platform step ladder", "code": "30191506" },
      { "name": "Combination ladder", "code": "30191507" },
      { "name": "Roof ladder", "code": "30191508" },
      { "name": "Fire escape ladder", "code": "30191509" },
      { "name": "Fire extinguishing ladder", "code": "30191510" },
      { "name": "Ladders and scaffolding accessories", "code": "30191600" },
      { "name": "Scaffolding handrail", "code": "30191601" },
      { "name": "Scaffolding stabilizers", "code": "30191602" },
      { "name": "Scaffolding flooring", "code": "30191603" },
      { "name": "Scaffolding cross brace", "code": "30191604" },
      { "name": "Scaffolding frame", "code": "30191605" },
      { "name": "Scaffolding staircase", "code": "30191606" },
      { "name": "Scaffolding counterweight", "code": "30191607" },
      { "name": "Scaffolding corbel", "code": "30191608" },
      { "name": "Scaffolding trestle", "code": "30191609" },
      { "name": "Scaffolding davit", "code": "30191610" },
      { "name": "Scaffolding fixation device", "code": "30191611" },
      { "name": "Scaffolding wire rope", "code": "30191612" },
      { "name": "Ladder platform", "code": "30191613" },
      { "name": "Ladder tray", "code": "30191614" },
      { "name": "Ladder stay", "code": "30191615" },
      { "name": "Construction safety net", "code": "30191616" },
      { "name": "Construction safety shelf", "code": "30191617" },
      { "name": "Construction sheds and trailers", "code": "30191700" },
      { "name": "Construction shed", "code": "30191701" },
      { "name": "Construction trailer", "code": "30191702" },
      {
        "name": "Temporary construction and maintenance support equipment and materials",
        "code": "30191800"
      },
      { "name": "Structural formwork", "code": "30191801" },
      { "name": "Structural formwork accessory", "code": "30191802" },
      { "name": "Structural spacer", "code": "30191803" },
      { "name": "Temporary roadway lining plate", "code": "30191804" },
      { "name": "Structural deck plate", "code": "30191805" },
      { "name": "Portable Structure Building Components", "code": "30240000" },
      {
        "name": "Portable Structure Consolidating Components",
        "code": "30241500"
      },
      { "name": "Tube frame connector", "code": "30241501" },
      { "name": "Anchor plate", "code": "30241502" },
      { "name": "Bail ring", "code": "30241503" },
      { "name": "Base plate", "code": "30241504" },
      { "name": "Cupola", "code": "30241505" },
      { "name": "Funicular bell", "code": "30241506" },
      { "name": "Gable", "code": "30241507" },
      { "name": "Gin pole and accessories", "code": "30241508" },
      { "name": "Mast section and king pole", "code": "30241509" },
      { "name": "Side pole", "code": "30241510" },
      { "name": "Stake or peg", "code": "30241511" },
      { "name": "Transom", "code": "30241512" },
      { "name": "Steel A-frame", "code": "30241513" },
      { "name": "Structural brace", "code": "30241514" },
      { "name": "Structural alignment tool", "code": "30241515" },
      {
        "name": "Grandstand bleacher and stair structural components",
        "code": "30241600"
      },
      { "name": "Grandstand footboard and accessories", "code": "30241601" },
      { "name": "Stair step", "code": "30241602" },
      { "name": "Stringer", "code": "30241603" },
      { "name": "Stair riser", "code": "30241604" },
      {
        "name": "Tent and membrane structure framing and covering components",
        "code": "30241700"
      },
      { "name": "Framing beam or rail or tubing", "code": "30241701" },
      { "name": "Portable structure canvas section", "code": "30241702" },
      {
        "name": "Underground mining structures and materials",
        "code": "30250000"
      },
      { "name": "Underground roof support structures", "code": "30251500" },
      { "name": "Combination roof bolt and truss", "code": "30251501" },
      { "name": "Roof plate", "code": "30251502" },
      { "name": "Resin glue capsule or cartridge", "code": "30251503" },
      { "name": "Expansion shell", "code": "30251504" },
      { "name": "Rib mat", "code": "30251505" },
      { "name": "Structural materials", "code": "30260000" },
      { "name": "Brass bars", "code": "30261500" },
      { "name": "Hot rolled c360 brass bar", "code": "30261501" },
      { "name": "Hot forged c360 brass bar", "code": "30261502" },
      { "name": "Hot rolled c464 brass bar", "code": "30261503" },
      { "name": "Hot forged c464 brass bar", "code": "30261504" },
      { "name": "Cold drawn c464 brass bar", "code": "30261505" },
      { "name": "Brass sheets", "code": "30261600" },
      { "name": "Hot rolled c360 brass sheet", "code": "30261601" },
      { "name": "Hot rolled c464 brass sheet", "code": "30261602" },
      { "name": "Cold rolled c464 brass sheet", "code": "30261603" },
      { "name": "Brass ingots strips billets and coil", "code": "30261700" },
      { "name": "Brass coil", "code": "30261701" },
      { "name": "Brass strip", "code": "30261702" },
      { "name": "Brass billet", "code": "30261703" },
      { "name": "Brass ingot", "code": "30261704" },
      { "name": "Magnesium bars and sheets", "code": "30261800" },
      { "name": "Extruded magnesium bar", "code": "30261801" },
      { "name": "Cold rolled magnesium sheet", "code": "30261802" },
      {
        "name": "Magnesium ingots strips billets and coil",
        "code": "30261900"
      },
      { "name": "Magnesium coil", "code": "30261901" },
      { "name": "Magnesium strip", "code": "30261902" },
      { "name": "Magnesium billet", "code": "30261903" },
      { "name": "Magnesium ingot", "code": "30261904" },
      { "name": "Titanium bars and sheets", "code": "30262000" },
      { "name": "Cast titanium bar", "code": "30262001" },
      { "name": "Hot rolled titanium sheet", "code": "30262002" },
      { "name": "Titanium ingots strips billets and coil", "code": "30262100" },
      { "name": "Titanium coil", "code": "30262101" },
      { "name": "Titanium strip", "code": "30262102" },
      { "name": "Titanium billet", "code": "30262103" },
      { "name": "Titanium ingot", "code": "30262104" },
      { "name": "Copper bars", "code": "30262200" },
      { "name": "Cold drawn copper bar", "code": "30262201" },
      { "name": "Hot rolled copper bar", "code": "30262202" },
      { "name": "Copper sheets", "code": "30262300" },
      { "name": "Hot rolled copper sheet", "code": "30262301" },
      { "name": "Cold rolled copper sheet", "code": "30262302" },
      { "name": "Copper ingots strip billets and coil", "code": "30262400" },
      { "name": "Copper coil", "code": "30262401" },
      { "name": "Hot rolled copper strip", "code": "30262402" },
      { "name": "Cold rolled copper strip", "code": "30262403" },
      { "name": "Copper billet", "code": "30262404" },
      { "name": "Copper ingot", "code": "30262405" },
      { "name": "Bronze bars", "code": "30262500" },
      { "name": "Hot rolled phosphor bronze bar", "code": "30262501" },
      { "name": "Cold drawn phosphor bronze bar", "code": "30262502" },
      { "name": "Hot rolled aluminum bronze bar", "code": "30262503" },
      { "name": "Cold drawn aluminum bronze bar", "code": "30262504" },
      { "name": "Hot rolled silicone bronze bar", "code": "30262505" },
      { "name": "Cold drawn silicone bronze bar", "code": "30262506" },
      { "name": "Bronze sheets", "code": "30262600" },
      { "name": "Hot rolled phosphor bronze sheet", "code": "30262601" },
      { "name": "Cold rolled phosphor bronze sheet", "code": "30262602" },
      { "name": "Hot rolled aluminum bronze sheet", "code": "30262603" },
      { "name": "Cold rolled aluminum bronze sheet", "code": "30262604" },
      { "name": "Hot rolled aluminum silicone sheet", "code": "30262605" },
      { "name": "Cold rolled silicone bronze sheet", "code": "30262606" },
      { "name": "Bronze ingots strips billets and coil", "code": "30262700" },
      { "name": "Bronze coil", "code": "30262701" },
      { "name": "Bronze strip", "code": "30262702" },
      { "name": "Bronze billet", "code": "30262703" },
      { "name": "Bronze ingot", "code": "30262704" },
      { "name": "Zinc bars and sheets", "code": "30262800" },
      { "name": "Hot rolled zinc bar", "code": "30262801" },
      { "name": "Hot rolled zinc sheet", "code": "30262802" },
      { "name": "Zinc ingots strips billets and coil", "code": "30262900" },
      { "name": "Zinc coil", "code": "30262901" },
      { "name": "Zinc strip", "code": "30262902" },
      { "name": "Zinc billet", "code": "30262903" },
      { "name": "Zinc ingot", "code": "30262904" },
      { "name": "Tin bars", "code": "30263000" },
      { "name": "Tin cold drawn bar", "code": "30263001" },
      { "name": "Tin hot rolled bar", "code": "30263002" },
      { "name": "Tin sheet", "code": "30263100" },
      { "name": "Tin hot rolled sheet", "code": "30263101" },
      { "name": "Tin cold rolled sheet", "code": "30263102" },
      { "name": "Tin ingots strip and coil", "code": "30263200" },
      { "name": "Tin coil", "code": "30263201" },
      { "name": "Tin strip", "code": "30263202" },
      { "name": "Tin ingot", "code": "30263203" },
      { "name": "Lead bars", "code": "30263300" },
      { "name": "Lead extruded bar", "code": "30263301" },
      { "name": "Lead hot rolled bar", "code": "30263302" },
      { "name": "Lead cold drawn bar", "code": "30263303" },
      { "name": "Lead sheets", "code": "30263400" },
      { "name": "Lead hot rolled sheet", "code": "30263401" },
      { "name": "Lead cold rolled sheet", "code": "30263402" },
      { "name": "Lead ingots strips billets and coil", "code": "30263500" },
      { "name": "Lead coil", "code": "30263501" },
      { "name": "Lead strip", "code": "30263502" },
      { "name": "Lead billet", "code": "30263503" },
      { "name": "Lead ingot", "code": "30263504" },
      { "name": "Carbon steel bars", "code": "30263600" },
      {
        "name": "Carbon steel SAE 1000 series cold drawn bar",
        "code": "30263601"
      },
      {
        "name": "Carbon steel SAE 1100 series cold drawn bar",
        "code": "30263602"
      },
      {
        "name": "Carbon steel SAE 1200 series cold drawn bar",
        "code": "30263603"
      },
      {
        "name": "Carbon steel SAE 1500 series cold drawn bar",
        "code": "30263604"
      },
      {
        "name": "Carbon steel SAE 1000 series hot rolled bar",
        "code": "30263605"
      },
      {
        "name": "Carbon steel SAE 1100 series hot rolled bar",
        "code": "30263606"
      },
      {
        "name": "Carbon steel SAE 1200 series hot rolled bar",
        "code": "30263607"
      },
      {
        "name": "Carbon steel SAE 1500 series hot rolled bar",
        "code": "30263608"
      },
      { "name": "Steel alloy bars", "code": "30263700" },
      {
        "name": "Steel alloy SAE 4000 series cold drawn bar",
        "code": "30263701"
      },
      {
        "name": "Steel alloy SAE 5000 series cold drawn bar",
        "code": "30263702"
      },
      {
        "name": "Steel alloy SAE 6000 series cold drawn bar",
        "code": "30263703"
      },
      {
        "name": "Steel alloy SAE 8000 series cold drawn bar",
        "code": "30263704"
      },
      {
        "name": "Steel alloy SAE 9000 series cold drawn bar",
        "code": "30263705"
      },
      {
        "name": "Steel alloy SAE 4000 series hot rolled bar",
        "code": "30263706"
      },
      {
        "name": "Steel alloy SAE 5000 series hot rolled bar",
        "code": "30263707"
      },
      {
        "name": "Steel alloy SAE 6000 series hot rolled bar",
        "code": "30263708"
      },
      {
        "name": "Steel alloy SAE 8000 series hot rolled bar",
        "code": "30263709"
      },
      {
        "name": "Steel alloy SAE 9000 series hot rolled bar",
        "code": "30263710"
      },
      { "name": "Tool steel bars", "code": "30263800" },
      { "name": "Tool steel cold drawn bar", "code": "30263801" },
      { "name": "Tool steel hot rolled bar", "code": "30263802" },
      { "name": "Specialty steel bars", "code": "30263900" },
      {
        "name": "High strength low alloy HSLA steel hot rolled bar",
        "code": "30263901"
      },
      { "name": "Carbon steel sheets", "code": "30264000" },
      {
        "name": "Carbon steel SAE 1000 series hot rolled sheet",
        "code": "30264001"
      },
      {
        "name": "Carbon steel SAE 1100 series hot rolled sheet",
        "code": "30264002"
      },
      {
        "name": "Carbon steel SAE 1200 series hot rolled sheet",
        "code": "30264003"
      },
      {
        "name": "Carbon steel SAE 1500 series hot rolled sheet",
        "code": "30264004"
      },
      {
        "name": "Carbon steel SAE 1000 series electro galvanized hot rolled sheet",
        "code": "30264005"
      },
      {
        "name": "Carbon steel SAE 1100 series electro galvanized hot rolled sheet",
        "code": "30264006"
      },
      {
        "name": "Carbon steel SAE 1200 series electro galvanized hot rolled sheet",
        "code": "30264007"
      },
      {
        "name": "Carbon steel SAE 1500 series electro galvanized hot rolled sheet",
        "code": "30264008"
      },
      {
        "name": "Carbon steel SAE 1000 series hot dip galvanized hot rolled sheet",
        "code": "30264009"
      },
      {
        "name": "Carbon steel SAE 1100 series hot dip galvanized hot rolled sheet",
        "code": "30264010"
      },
      {
        "name": "Carbon steel SAE 1200 series hot dip galvanized hot rolled sheet",
        "code": "30264011"
      },
      {
        "name": "Carbon steel SAE 1500 series hot dip galvanized hot rolled sheet",
        "code": "30264012"
      },
      {
        "name": "Carbon steel SAE 1000 series cold rolled sheet",
        "code": "30264013"
      },
      {
        "name": "Carbon steel SAE 1100 series cold rolled sheet",
        "code": "30264014"
      },
      {
        "name": "Carbon steel SAE 1200 series cold rolled sheet",
        "code": "30264015"
      },
      {
        "name": "Carbon steel SAE 1500 series cold rolled sheet",
        "code": "30264016"
      },
      {
        "name": "Carbon steel SAE 1000 series electro galvanized cold rolled sheet",
        "code": "30264017"
      },
      {
        "name": "Carbon steel SAE 1100 series electro galvanized cold rolled sheet",
        "code": "30264018"
      },
      {
        "name": "Carbon steel SAE 1200 series electro galvanized cold rolled sheet",
        "code": "30264019"
      },
      {
        "name": "Carbon steel SAE 1500 series electro galvanized cold rolled sheet",
        "code": "30264020"
      },
      {
        "name": "Carbon steel SAE 1000 series hot dip galvanized cold rolled sheet",
        "code": "30264021"
      },
      {
        "name": "Carbon steel SAE 1100 series hot dip galvanized cold rolled sheet",
        "code": "30264022"
      },
      {
        "name": "Carbon steel SAE 1200 series hot dip galvanized cold rolled sheet",
        "code": "30264023"
      },
      {
        "name": "Carbon steel SAE 1500 series hot dip galvanized cold rolled sheet",
        "code": "30264024"
      },
      { "name": "Steel alloy sheets", "code": "30264100" },
      {
        "name": "Steel alloy SAE 4000 series hot rolled sheet",
        "code": "30264101"
      },
      {
        "name": "Steel alloy SAE 5000 series hot rolled sheet",
        "code": "30264102"
      },
      {
        "name": "Steel alloy SAE 6000 series hot rolled sheet",
        "code": "30264103"
      },
      {
        "name": "Steel alloy SAE 8000 series hot rolled sheet",
        "code": "30264104"
      },
      {
        "name": "Steel alloy SAE 9000 series hot rolled sheet",
        "code": "30264105"
      },
      {
        "name": "Steel alloy SAE 4000 series cold rolled sheet",
        "code": "30264106"
      },
      {
        "name": "Steel alloy SAE 5000 series cold rolled sheet",
        "code": "30264107"
      },
      {
        "name": "Steel alloy SAE 6000 series cold rolled sheet",
        "code": "30264108"
      },
      {
        "name": "Steel alloy SAE 8000 series cold rolled sheet",
        "code": "30264109"
      },
      {
        "name": "Steel alloy SAE 9000 series cold rolled sheet",
        "code": "30264110"
      },
      { "name": "Specialty steel sheets", "code": "30264200" },
      {
        "name": "High strength low alloy HSLA steel hot rolled sheet",
        "code": "30264201"
      },
      {
        "name": "High strength low alloy HSLA cold rolled sheet",
        "code": "30264202"
      },
      { "name": "Aluminized steel sheet", "code": "30264203" },
      { "name": "Corrugated steel sheet", "code": "30264204" },
      { "name": "Specialty steel coils", "code": "30264300" },
      { "name": "Perforated steel coil", "code": "30264301" },
      { "name": "Embossed steel coil", "code": "30264302" },
      { "name": "Aluminized steel coil", "code": "30264303" },
      { "name": "Hot dip galvanized steel coil", "code": "30264304" },
      { "name": "Electro galvanized steel coil", "code": "30264305" },
      {
        "name": "Carbon steel ingots strips billets and coil",
        "code": "30264400"
      },
      {
        "name": "Carbon steel SAE 1100 series hot rolled coil",
        "code": "30264401"
      },
      {
        "name": "Carbon steel SAE 1200 series hot rolled coil",
        "code": "30264402"
      },
      {
        "name": "Carbon steel SAE 1500 series hot rolled coil",
        "code": "30264403"
      },
      {
        "name": "Carbon steel SAE 1100 series cold rolled strip",
        "code": "30264404"
      },
      {
        "name": "Carbon steel SAE 1200 series cold rolled strip",
        "code": "30264405"
      },
      {
        "name": "Carbon steel SAE 1500 series cold rolled strip",
        "code": "30264406"
      },
      {
        "name": "Carbon steel SAE 1100 series hot rolled strip",
        "code": "30264407"
      },
      {
        "name": "Carbon steel SAE 1200 series hot rolled strip",
        "code": "30264408"
      },
      {
        "name": "Carbon steel SAE 1500 series hot rolled strip",
        "code": "30264409"
      },
      { "name": "Carbon steel hot rolled billet", "code": "30264410" },
      { "name": "Carbon steel cold rolled billet", "code": "30264411" },
      { "name": "Carbon steel hot rolled ingot", "code": "30264412" },
      { "name": "Carbon steel cold rolled ingot", "code": "30264413" },
      { "name": "Stainless steel bars", "code": "30264500" },
      {
        "name": "Stainless steel SAE 200 series cold drawn bar",
        "code": "30264501"
      },
      {
        "name": "Stainless steel SAE 300 series cold drawn bar",
        "code": "30264502"
      },
      {
        "name": "Stainless steel SAE 400 series cold drawn bar",
        "code": "30264503"
      },
      {
        "name": "Stainless steel SAE 200 series hot rolled bar",
        "code": "30264504"
      },
      {
        "name": "Stainless steel SAE 300 series hot rolled bar",
        "code": "30264505"
      },
      {
        "name": "Stainless steel SAE 400 series hot rolled bar",
        "code": "30264506"
      },
      { "name": "Stainless steel sheets", "code": "30264600" },
      {
        "name": "Stainless steel SAE 200 series hot rolled sheet",
        "code": "30264601"
      },
      {
        "name": "Stainless steel SAE 300 series hot rolled sheet",
        "code": "30264602"
      },
      {
        "name": "Stainless steel SAE 400 series hot rolled sheet",
        "code": "30264603"
      },
      {
        "name": "Stainless steel SAE 200 series cold rolled sheet",
        "code": "30264604"
      },
      {
        "name": "Stainless steel SAE 300 series cold rolled sheet",
        "code": "30264605"
      },
      {
        "name": "Stainless steel SAE 400 series cold rolled sheet",
        "code": "30264606"
      },
      { "name": "Stainless steel coil", "code": "30264700" },
      {
        "name": "Stainless steel SAE 300 series hot rolled coil",
        "code": "30264701"
      },
      {
        "name": "Stainless steel SAE 400 series hot rolled coil",
        "code": "30264702"
      },
      {
        "name": "Stainless steel SAE 200 series cold rolled coil",
        "code": "30264703"
      },
      {
        "name": "Stainless steel SAE 300 series cold rolled coil",
        "code": "30264704"
      },
      {
        "name": "Stainless steel SAE 400 series cold rolled coil",
        "code": "30264705"
      },
      { "name": "Stainless steel strips", "code": "30264800" },
      {
        "name": "Stainless steel SAE 200 series hot rolled strip",
        "code": "30264801"
      },
      {
        "name": "Stainless steel SAE 300 series hot rolled strip",
        "code": "30264802"
      },
      {
        "name": "Stainless steel SAE 400 series hot rolled strip",
        "code": "30264803"
      },
      {
        "name": "Stainless steel SAE 200 series cold rolled strip",
        "code": "30264804"
      },
      {
        "name": "Stainless steel SAE 300 series cold rolled strip",
        "code": "30264805"
      },
      {
        "name": "Stainless steel SAE 400 series cold rolled strip",
        "code": "30264806"
      },
      { "name": "Aluminum bars", "code": "30264900" },
      { "name": "Aluminum SAE 1000 series cold drawn bar", "code": "30264901" },
      { "name": "Aluminum SAE 1000 series hot rolled bar", "code": "30264902" },
      { "name": "Aluminum SAE 2000 series cold drawn bar", "code": "30264903" },
      { "name": "Aluminum SAE 2000 series hot rolled bar", "code": "30264904" },
      { "name": "Aluminum SAE 3000 series cold drawn bar", "code": "30264905" },
      { "name": "Aluminum SAE 3000 series hot rolled bar", "code": "30264906" },
      { "name": "Aluminum SAE 4000 series cold drawn bar", "code": "30264907" },
      { "name": "Aluminum SAE 4000 series hot rolled bar", "code": "30264908" },
      { "name": "Aluminum SAE 5000 series cold drawn bar", "code": "30264909" },
      { "name": "Aluminum SAE 5000 series hot rolled bar", "code": "30264910" },
      { "name": "Aluminum SAE 6000 series cold drawn bar", "code": "30264911" },
      { "name": "Aluminum SAE 6000 series hot rolled bar", "code": "30264912" },
      { "name": "Aluminum sheets", "code": "30265000" },
      {
        "name": "Aluminum SAE 1000 series cold rolled sheet",
        "code": "30265001"
      },
      {
        "name": "Aluminum SAE 1000 series hot rolled sheet",
        "code": "30265002"
      },
      {
        "name": "Aluminum SAE 2000 series cold rolled sheet",
        "code": "30265003"
      },
      {
        "name": "Aluminum SAE 2000 series hot rolled sheet",
        "code": "30265004"
      },
      {
        "name": "Aluminum SAE 3000 series cold rolled sheet",
        "code": "30265005"
      },
      {
        "name": "Aluminum SAE 3000 series hot rolled sheet",
        "code": "30265006"
      },
      {
        "name": "Aluminum SAE 4000 series cold rolled sheet",
        "code": "30265007"
      },
      {
        "name": "Aluminum SAE 4000 series hot rolled sheet",
        "code": "30265008"
      },
      {
        "name": "Aluminum SAE 5000 series cold rolled sheet",
        "code": "30265009"
      },
      {
        "name": "Aluminum SAE 5000 series hot rolled sheet",
        "code": "30265010"
      },
      {
        "name": "Aluminum SAE 6000 series cold rolled sheet",
        "code": "30265011"
      },
      { "name": "Corrugated aluminum sheet", "code": "30265012" },
      { "name": "Aluminum coil", "code": "30265100" },
      {
        "name": "Aluminum SAE 1000 series hot rolled coil",
        "code": "30265101"
      },
      {
        "name": "Aluminum SAE 2000 series hot rolled coil",
        "code": "30265102"
      },
      {
        "name": "Aluminum SAE 3000 series hot rolled coil",
        "code": "30265103"
      },
      {
        "name": "Aluminum SAE 4000 series hot rolled coil",
        "code": "30265104"
      },
      {
        "name": "Aluminum SAE 5000 series hot rolled coil",
        "code": "30265105"
      },
      {
        "name": "Aluminum SAE 6000 series hot rolled coil",
        "code": "30265106"
      },
      { "name": "Aluminum strips and ingots", "code": "30265200" },
      { "name": "Aluminum strip", "code": "30265201" },
      { "name": "Aluminum ingot", "code": "30265202" },
      { "name": "Iron bars", "code": "30265300" },
      { "name": "Ductile iron cast bar", "code": "30265301" },
      { "name": "Gray iron cast bar", "code": "30265302" },
      { "name": "White iron cast bar", "code": "30265303" },
      { "name": "Malleable iron cast bar", "code": "30265304" },
      { "name": "Iron sheets", "code": "30265400" },
      { "name": "Ductile iron hot rolled sheet", "code": "30265401" },
      { "name": "Gray iron hot rolled sheet", "code": "30265402" },
      { "name": "White iron hot rolled sheet", "code": "30265403" },
      { "name": "Malleable iron hot rolled sheet", "code": "30265404" },
      { "name": "Iron ingots strips billets and coil", "code": "30265500" },
      { "name": "Ductile iron coil", "code": "30265501" },
      { "name": "Ductile iron strip", "code": "30265502" },
      { "name": "Ductile iron billet", "code": "30265503" },
      { "name": "Gray iron billet", "code": "30265504" },
      { "name": "White iron billet", "code": "30265505" },
      { "name": "Malleable iron billet", "code": "30265506" },
      { "name": "Ferrous alloy bars and sheets", "code": "30265600" },
      { "name": "Ferrous alloy bar", "code": "30265601" },
      { "name": "Ferrous alloy sheet", "code": "30265602" },
      {
        "name": "Ferrous alloy ingots strips billets and coil",
        "code": "30265700"
      },
      { "name": "Ferrous alloy coil", "code": "30265701" },
      { "name": "Ferrous alloy strip", "code": "30265702" },
      { "name": "Ferrous alloy billet", "code": "30265703" },
      { "name": "Ferrous alloy extrusion ingot", "code": "30265704" },
      { "name": "Plastic bars and sheets", "code": "30265800" },
      { "name": "Plastic bar", "code": "30265801" },
      { "name": "Plastic sheet", "code": "30265802" },
      { "name": "Corrugated plastic sheet", "code": "30265803" },
      { "name": "Plastic coil and strips", "code": "30265900" },
      { "name": "Plastic coil", "code": "30265901" },
      { "name": "Plastic strip", "code": "30265902" },
      { "name": "Precious metal and specialty metal bars", "code": "30266000" },
      { "name": "Precious metal bar", "code": "30266001" },
      { "name": "Non ferrous alloy bar", "code": "30266002" },
      {
        "name": "Precious metal and specialty metal sheets",
        "code": "30266100"
      },
      { "name": "Beryllium alloy sheet", "code": "30266101" },
      { "name": "Composite metal sheet", "code": "30266102" },
      { "name": "Non ferrous alloy sheet", "code": "30266103" },
      {
        "name": "Precious metal and specialty metal coil strips billets and ingots",
        "code": "30266200"
      },
      { "name": "Graphite coil", "code": "30266201" },
      { "name": "Non ferrous alloy coil", "code": "30266202" },
      { "name": "Precious metal coil", "code": "30266203" },
      { "name": "Non ferrous alloy strip", "code": "30266204" },
      { "name": "Non ferrous alloy billet", "code": "30266205" },
      { "name": "Precious metal billet", "code": "30266206" },
      { "name": "Precious metal ingot", "code": "30266207" },
      { "name": "Antimony ingot", "code": "30266208" },
      { "name": "Cadmium ingot", "code": "30266209" },
      { "name": "Zirconium ingot", "code": "30266210" },
      { "name": "Cobalt ingot", "code": "30266211" },
      { "name": "Molybdenum ingot", "code": "30266212" },
      { "name": "Arsenic ingot", "code": "30266213" },
      { "name": "Bismuth ingot", "code": "30266214" },
      { "name": "Indium ingot", "code": "30266215" },
      { "name": "Non ferrous alloy extrusion ingot", "code": "30266216" },
      { "name": "Wood structural materials", "code": "30266300" },
      { "name": "Wooden bar", "code": "30266301" },
      { "name": "Specialty material bars and sheets", "code": "30266400" },
      { "name": "Rubber bar", "code": "30266401" },
      { "name": "Rubber sheet", "code": "30266402" },
      { "name": "Foam rubber sheet", "code": "30266403" },
      { "name": "Cork and rubber sheet", "code": "30266404" },
      { "name": "Compressed fiber sheet", "code": "30266405" },
      { "name": "Metal inserted compressed fiber sheet", "code": "30266406" },
      { "name": "Fiber and rubber sheet", "code": "30266407" },
      { "name": "Asbestos sheet", "code": "30266408" },
      { "name": "Tantalum bar", "code": "30266410" },
      {
        "name": "Specialty material coil strips billets and ingots",
        "code": "30266500"
      },
      { "name": "Rubber strip", "code": "30266501" },
      { "name": "Compressed fiber coil", "code": "30266502" },
      { "name": "Fiber and rubber coil", "code": "30266503" },
      { "name": "Tantalum strip", "code": "30266504" },
      { "name": "Nickel sheets and ingots", "code": "30266600" },
      { "name": "Nickel sheet", "code": "30266601" },
      { "name": "Nickel ingot", "code": "30266602" },
      { "name": "Shafting", "code": "30266700" },
      { "name": "Steel shafting", "code": "30266701" },
      { "name": "Stainless steel shafting", "code": "30266702" }
    ]
  },
  {
    "name": "Manufacturing Components and Supplies",
    "code": "31000000",
    "subcategories": [
      { "name": "Castings and casting assemblies", "code": "31100000" },
      { "name": "Sand castings and casting assemblies", "code": "31101600" },
      { "name": "Non ferrous alloy sand casting", "code": "31101601" },
      { "name": "Ferrous alloy sand casting", "code": "31101602" },
      { "name": "Steel sand casting", "code": "31101603" },
      { "name": "Stainless steel sand casting", "code": "31101604" },
      { "name": "Aluminum sand casting", "code": "31101606" },
      { "name": "Magnesium sand casting", "code": "31101607" },
      { "name": "Titanium sand casting", "code": "31101608" },
      { "name": "Beryllium sand casting", "code": "31101609" },
      { "name": "Copper sand casting", "code": "31101610" },
      { "name": "Brass sand casting", "code": "31101611" },
      { "name": "Bronze sand casting", "code": "31101612" },
      { "name": "Zinc sand casting", "code": "31101613" },
      { "name": "Tin sand casting", "code": "31101614" },
      { "name": "Lead sand casting", "code": "31101615" },
      { "name": "Precious metal sand casting", "code": "31101616" },
      { "name": "Grey iron sand casting", "code": "31101617" },
      { "name": "Ductile iron sand casting", "code": "31101618" },
      { "name": "Malleable iron sand casting", "code": "31101619" },
      {
        "name": "Permanent mold castings and casting assemblies",
        "code": "31101700"
      },
      {
        "name": "Non ferrous alloy permanent mold casting",
        "code": "31101701"
      },
      { "name": "Ferrous alloy permanent mold casting", "code": "31101702" },
      { "name": "Steel permanent mold casting", "code": "31101703" },
      { "name": "Stainless steel permanent mold casting", "code": "31101704" },
      { "name": "Iron permanent mold casting", "code": "31101705" },
      { "name": "Aluminum permanent mold casting", "code": "31101706" },
      { "name": "Magnesium permanent mold casting", "code": "31101707" },
      { "name": "Titanium permanent mold casting", "code": "31101708" },
      { "name": "Beryllium permanent mold casting", "code": "31101709" },
      { "name": "Copper permanent mold casting", "code": "31101710" },
      { "name": "Brass permanent mold casting", "code": "31101711" },
      { "name": "Bronze permanent mold casting", "code": "31101712" },
      { "name": "Zinc permanent mold casting", "code": "31101713" },
      { "name": "Tin permanent mold casting", "code": "31101714" },
      { "name": "Lead permanent mold casting", "code": "31101715" },
      { "name": "Precious metal permanent mold casting", "code": "31101716" },
      {
        "name": "Shell mold castings and casting assemblies",
        "code": "31101800"
      },
      { "name": "Non ferrous alloy shell mold casting", "code": "31101801" },
      { "name": "Ferrous alloy shell mold casting", "code": "31101802" },
      { "name": "Steel shell mold casting", "code": "31101803" },
      { "name": "Stainless steel shell mold casting", "code": "31101804" },
      { "name": "Aluminum shell mold casting", "code": "31101806" },
      { "name": "Magnesium shell mold casting", "code": "31101807" },
      { "name": "Titanium shell mold casting", "code": "31101808" },
      { "name": "Beryllium shell mold casting", "code": "31101809" },
      { "name": "Copper shell mold casting", "code": "31101810" },
      { "name": "Brass shell mold casting", "code": "31101811" },
      { "name": "Bronze shell mold casting", "code": "31101812" },
      { "name": "Zinc shell mold casting", "code": "31101813" },
      { "name": "Tin shell mold casting", "code": "31101814" },
      { "name": "Lead shell mold casting", "code": "31101815" },
      { "name": "Precious metal shell mold casting", "code": "31101816" },
      { "name": "Ductile iron shell mold casting", "code": "31101817" },
      {
        "name": "Investment castings and casting assemblies",
        "code": "31101900"
      },
      { "name": "Non ferrous alloy investment casting", "code": "31101901" },
      { "name": "Ferrous alloy investment casting", "code": "31101902" },
      { "name": "Steel investment casting", "code": "31101903" },
      { "name": "Stainless steel investment casting", "code": "31101904" },
      { "name": "Iron investment casting", "code": "31101905" },
      { "name": "Aluminum investment casting", "code": "31101906" },
      { "name": "Magnesium investment casting", "code": "31101907" },
      { "name": "Zinc investment casting", "code": "31101908" },
      { "name": "Tin investment casting", "code": "31101909" },
      { "name": "Lead investment casting", "code": "31101910" },
      { "name": "Precious metal investment casting", "code": "31101911" },
      { "name": "Titanium investment casting", "code": "31101912" },
      { "name": "Steel alloy investment casting", "code": "31101914" },
      { "name": "Centrifugal castings", "code": "31102000" },
      { "name": "Non ferrous alloy centrifugal casting", "code": "31102001" },
      { "name": "Ferrous alloy centrifugal casting", "code": "31102002" },
      { "name": "Steel centrifugal casting", "code": "31102003" },
      { "name": "Stainless steel centrifugal casting", "code": "31102004" },
      { "name": "Iron centrifugal casting", "code": "31102005" },
      { "name": "Aluminum centrifugal casting", "code": "31102006" },
      { "name": "Magnesium centrifugal casting", "code": "31102007" },
      { "name": "Titanium centrifugal casting", "code": "31102008" },
      { "name": "Beryllium centrifugal casting", "code": "31102009" },
      { "name": "Copper centrifugal casting", "code": "31102010" },
      { "name": "Brass centrifugal casting", "code": "31102011" },
      { "name": "Bronze centrifugal casting", "code": "31102012" },
      { "name": "Zinc centrifugal casting", "code": "31102013" },
      { "name": "Tin centrifugal casting", "code": "31102014" },
      { "name": "Lead centrifugal casting", "code": "31102015" },
      { "name": "Precious metal centrifugal casting", "code": "31102016" },
      { "name": "Ceramic mold castings", "code": "31102100" },
      { "name": "Non ferrous alloy ceramic mold casting", "code": "31102101" },
      { "name": "Ferrous alloy ceramic mold casting", "code": "31102102" },
      { "name": "Steel ceramic mold casting", "code": "31102103" },
      { "name": "Stainless steel ceramic mold casting", "code": "31102104" },
      { "name": "Iron ceramic mold casting", "code": "31102105" },
      { "name": "Aluminum ceramic mold casting", "code": "31102106" },
      { "name": "Magnesium ceramic mold casting", "code": "31102107" },
      { "name": "Titanium ceramic mold casting", "code": "31102108" },
      { "name": "Beryllium ceramic mold casting", "code": "31102109" },
      { "name": "Copper ceramic mold casting", "code": "31102110" },
      { "name": "Brass ceramic mold casting", "code": "31102111" },
      { "name": "Bronze ceramic mold casting", "code": "31102112" },
      { "name": "Zinc ceramic mold casting", "code": "31102113" },
      { "name": "Tin ceramic mold casting", "code": "31102114" },
      { "name": "Lead ceramic mold casting", "code": "31102115" },
      { "name": "Precious metal ceramic mold casting", "code": "31102116" },
      { "name": "Graphite mold castings", "code": "31102200" },
      { "name": "Non ferrous alloy graphite mold casting", "code": "31102201" },
      { "name": "Ferrous alloy graphite mold casting", "code": "31102202" },
      { "name": "Steel graphite mold casting", "code": "31102203" },
      { "name": "Stainless steel graphite mold casting", "code": "31102204" },
      { "name": "Iron graphite mold casting", "code": "31102205" },
      { "name": "Aluminum graphite mold casting", "code": "31102206" },
      { "name": "Magnesium graphite mold casting", "code": "31102207" },
      { "name": "Titanium graphite mold casting", "code": "31102208" },
      { "name": "Beryllium graphite mold casting", "code": "31102209" },
      { "name": "Copper graphite mold casting", "code": "31102210" },
      { "name": "Brass graphite mold casting", "code": "31102211" },
      { "name": "Bronze graphite mold casting", "code": "31102212" },
      { "name": "Zinc graphite mold casting", "code": "31102213" },
      { "name": "Tin graphite mold casting", "code": "31102214" },
      { "name": "Lead graphite mold casting", "code": "31102215" },
      { "name": "Precious metal graphite mold casting", "code": "31102216" },
      { "name": "Plaster mold castings", "code": "31102300" },
      { "name": "Non ferrous alloy plaster mold casting", "code": "31102301" },
      { "name": "Ferrous alloy plaster mold casting", "code": "31102302" },
      { "name": "Steel plaster mold casting", "code": "31102303" },
      { "name": "Stainless steel plaster mold casting", "code": "31102304" },
      { "name": "Iron plaster mold casting", "code": "31102305" },
      { "name": "Aluminum plaster mold casting", "code": "31102306" },
      { "name": "Magnesium plaster mold casting", "code": "31102307" },
      { "name": "Titanium plaster mold casting", "code": "31102308" },
      { "name": "Beryllium plaster mold casting", "code": "31102309" },
      { "name": "Copper plaster mold casting", "code": "31102310" },
      { "name": "Brass plaster mold casting", "code": "31102311" },
      { "name": "Bronze plaster mold casting", "code": "31102312" },
      { "name": "Zinc plaster mold casting", "code": "31102313" },
      { "name": "Tin plaster mold casting", "code": "31102314" },
      { "name": "Lead plaster mold casting", "code": "31102315" },
      { "name": "Precious metal plaster mold casting", "code": "31102316" },
      { "name": "V process castings", "code": "31102400" },
      { "name": "Non ferrous alloy v process casting", "code": "31102401" },
      { "name": "Ferrous alloy v process casting", "code": "31102402" },
      { "name": "Steel v process casting", "code": "31102403" },
      { "name": "Stainless steel v process casting", "code": "31102404" },
      { "name": "Iron v process casting", "code": "31102405" },
      { "name": "Aluminum v process casting", "code": "31102406" },
      { "name": "Magnesium v process casting", "code": "31102407" },
      { "name": "Titanium v process casting", "code": "31102408" },
      { "name": "Beryllium v process casting", "code": "31102409" },
      { "name": "Copper v process casting", "code": "31102410" },
      { "name": "Brass v process casting", "code": "31102411" },
      { "name": "Bronze v process casting", "code": "31102412" },
      { "name": "Zinc v process casting", "code": "31102413" },
      { "name": "Tin v process casting", "code": "31102414" },
      { "name": "Lead v process casting", "code": "31102415" },
      { "name": "Precious metal v process casting", "code": "31102416" },
      {
        "name": "Low pressure permanent die castings and casting assemblies",
        "code": "31102500"
      },
      {
        "name": "Aluminum low pressure permanent mold casting",
        "code": "31102501"
      },
      {
        "name": "High pressure die castings and casting assemblies",
        "code": "31102600"
      },
      { "name": "Aluminum high pressure die casting", "code": "31102601" },
      { "name": "Zinc high pressure die casting", "code": "31102602" },
      { "name": "Magnesium high pressure die casting", "code": "31102603" },
      { "name": "Squeeze castings and casting assemblies", "code": "31102700" },
      { "name": "Aluminum squeeze casting", "code": "31102701" },
      {
        "name": "Semi solid castings and casting assemblies",
        "code": "31102800"
      },
      { "name": "Aluminum semi solid casting", "code": "31102801" },
      {
        "name": "Gravity permanent mold castings and casting assemblies",
        "code": "31102900"
      },
      { "name": "Aluminum gravity permanent mold casting", "code": "31102901" },
      { "name": "Extrusions", "code": "31110000" },
      { "name": "Profile extrusions", "code": "31111500" },
      { "name": "Aluminum profile extrusions", "code": "31111501" },
      { "name": "Beryllium profile extrusions", "code": "31111502" },
      { "name": "Brass profile extrusions", "code": "31111503" },
      { "name": "Bronze profile extrusions", "code": "31111504" },
      { "name": "Copper profile extrusions", "code": "31111505" },
      { "name": "Ferrous alloy profile extrusions", "code": "31111506" },
      { "name": "Lead profile extrusions", "code": "31111507" },
      { "name": "Magnesium profile extrusions", "code": "31111508" },
      { "name": "Non ferrous alloy profile extrusions", "code": "31111509" },
      { "name": "Plastic profile extrusions", "code": "31111510" },
      { "name": "Precious metal profile extrusions", "code": "31111511" },
      { "name": "Rubber profile extrusions", "code": "31111512" },
      { "name": "Stainless steel profile extrusions", "code": "31111513" },
      { "name": "Steel profile extrusions", "code": "31111514" },
      { "name": "Tin profile extrusions", "code": "31111515" },
      { "name": "Titanium profile extrusions", "code": "31111516" },
      { "name": "Zinc profile extrusions", "code": "31111517" },
      { "name": "Impact extrusions", "code": "31111600" },
      { "name": "Aluminum impact extrusions", "code": "31111601" },
      { "name": "Beryllium impact extrusions", "code": "31111602" },
      { "name": "Brass impact extrusions", "code": "31111603" },
      { "name": "Bronze impact extrusions", "code": "31111604" },
      { "name": "Copper impact extrusions", "code": "31111605" },
      { "name": "Ferrous alloy impact extrusions", "code": "31111606" },
      { "name": "Lead impact extrusions", "code": "31111607" },
      { "name": "Magnesium impact extrusions", "code": "31111608" },
      { "name": "Non ferrous alloy impact extrusions", "code": "31111609" },
      { "name": "Plastic impact extrusions", "code": "31111610" },
      { "name": "Precious metal impact extrusions", "code": "31111611" },
      { "name": "Rubber impact extrusions", "code": "31111612" },
      { "name": "Stainless steel impact extrusions", "code": "31111613" },
      { "name": "Steel impact extrusions", "code": "31111614" },
      { "name": "Tin impact extrusions", "code": "31111615" },
      { "name": "Titanium impact extrusions", "code": "31111616" },
      { "name": "Zinc impact extrusions", "code": "31111617" },
      { "name": "Cold extrusions", "code": "31111700" },
      { "name": "Aluminum cold extrusions", "code": "31111701" },
      { "name": "Beryllium cold extrusions", "code": "31111702" },
      { "name": "Brass cold extrusions", "code": "31111703" },
      { "name": "Bronze cold extrusions", "code": "31111704" },
      { "name": "Copper cold extrusions", "code": "31111705" },
      { "name": "Ferrous alloy cold extrusions", "code": "31111706" },
      { "name": "Lead cold extrusions", "code": "31111707" },
      { "name": "Magnesium cold extrusions", "code": "31111708" },
      { "name": "Non ferrous alloy cold extrusions", "code": "31111709" },
      { "name": "Plastic cold extrusions", "code": "31111710" },
      { "name": "Precious metal cold extrusions", "code": "31111711" },
      { "name": "Rubber cold extrusions", "code": "31111712" },
      { "name": "Stainless steel cold extrusions", "code": "31111713" },
      { "name": "Steel cold extrusions", "code": "31111714" },
      { "name": "Tin cold extrusions", "code": "31111715" },
      { "name": "Titanium cold extrusions", "code": "31111716" },
      { "name": "Zinc cold extrusions", "code": "31111717" },
      { "name": "Machined castings", "code": "31120000" },
      { "name": "Machined v process castings", "code": "31121000" },
      {
        "name": "Non ferrous alloy v process machined castings",
        "code": "31121001"
      },
      {
        "name": "Ferrous alloy v process machined castings",
        "code": "31121002"
      },
      { "name": "Steel v process machined castings", "code": "31121003" },
      {
        "name": "Stainless steel v process machined castings",
        "code": "31121004"
      },
      { "name": "Iron v process machined castings", "code": "31121005" },
      { "name": "Aluminum v process machined castings", "code": "31121006" },
      { "name": "Magnesium v process machined castings", "code": "31121007" },
      { "name": "Titanium v process machined castings", "code": "31121008" },
      { "name": "Beryllium v process machined castings", "code": "31121009" },
      { "name": "Copper v process machined castings", "code": "31121010" },
      { "name": "Brass v process machined castings", "code": "31121011" },
      { "name": "Bronze v process machined castings", "code": "31121012" },
      { "name": "Zinc v process machined castings", "code": "31121013" },
      { "name": "Tin v process machined castings", "code": "31121014" },
      { "name": "Lead v process machined castings", "code": "31121015" },
      {
        "name": "Precious metal v process machined castings",
        "code": "31121016"
      },
      { "name": "Composite v process machined castings", "code": "31121017" },
      {
        "name": "Nickel alloy v process machined castings",
        "code": "31121018"
      },
      {
        "name": "Non metallic v process machined castings",
        "code": "31121019"
      },
      { "name": "Machined die castings", "code": "31121100" },
      { "name": "Aluminum die machined castings", "code": "31121101" },
      { "name": "Ferrous alloy die machined castings", "code": "31121102" },
      { "name": "Iron die machined castings", "code": "31121103" },
      { "name": "Non ferrous alloy die machined castings", "code": "31121104" },
      { "name": "Stainless steel die machined castings", "code": "31121105" },
      { "name": "Steel die machined castings", "code": "31121106" },
      { "name": "Magnesium die machined castings", "code": "31121107" },
      { "name": "Zinc die machined castings", "code": "31121108" },
      { "name": "Tin die machined castings", "code": "31121109" },
      { "name": "Titanium die machined castings", "code": "31121110" },
      { "name": "Beryllium die machined castings", "code": "31121111" },
      { "name": "Precious metal die machined castings", "code": "31121112" },
      { "name": "Copper die machined castings", "code": "31121113" },
      { "name": "Lead die machined castings", "code": "31121114" },
      { "name": "Brass die machined castings", "code": "31121115" },
      { "name": "Bronze die machined castings", "code": "31121116" },
      { "name": "Composite die machined castings", "code": "31121117" },
      { "name": "Nickel alloy die machined castings", "code": "31121118" },
      { "name": "Non metallic die machined castings", "code": "31121119" },
      {
        "name": "Aluminum high pressure die machined casting",
        "code": "31121120"
      },
      {
        "name": "Aluminum high pressure die machined casting assembly",
        "code": "31121121"
      },
      {
        "name": "Magnesium high pressure die machined casting",
        "code": "31121122"
      },
      {
        "name": "Magnesium high pressure die machined casting assembly",
        "code": "31121123"
      },
      { "name": "Zinc high pressure die machined casting", "code": "31121124" },
      {
        "name": "Zinc high pressure die machined casting assembly",
        "code": "31121125"
      },
      { "name": "Machined sand castings", "code": "31121200" },
      {
        "name": "Non ferrous alloy sand machined castings",
        "code": "31121201"
      },
      { "name": "Ferrous alloy sand machined castings", "code": "31121202" },
      { "name": "Steel sand machined castings", "code": "31121203" },
      { "name": "Stainless steel sand machined castings", "code": "31121204" },
      { "name": "Iron sand machined castings", "code": "31121205" },
      { "name": "Aluminum sand machined castings", "code": "31121206" },
      { "name": "Magnesium sand machined castings", "code": "31121207" },
      { "name": "Titanium sand machined castings", "code": "31121208" },
      { "name": "Beryllium sand machined castings", "code": "31121209" },
      { "name": "Copper sand machined castings", "code": "31121210" },
      { "name": "Brass sand machined castings", "code": "31121211" },
      { "name": "Bronze sand machined castings", "code": "31121212" },
      { "name": "Zinc sand machined castings", "code": "31121213" },
      { "name": "Tin sand machined castings", "code": "31121214" },
      { "name": "Lead sand machined castings", "code": "31121215" },
      { "name": "Precious metal sand machined castings", "code": "31121216" },
      { "name": "Composite sand machined castings", "code": "31121217" },
      { "name": "Nickel Alloy sand machined castings", "code": "31121218" },
      { "name": "Non metallic sand machined castings", "code": "31121219" },
      { "name": "Aluminum sand machined casting", "code": "31121220" },
      { "name": "Aluminum sand machined casting assembly", "code": "31121221" },
      { "name": "Ductile iron sand machined casting", "code": "31121222" },
      {
        "name": "Ductile iron sand machined casting assembly",
        "code": "31121223"
      },
      { "name": "Grey iron sand machined casting", "code": "31121224" },
      {
        "name": "Grey iron sand machined casting assembly",
        "code": "31121225"
      },
      { "name": "Malleable iron sand machined casting", "code": "31121226" },
      {
        "name": "Malleable iron sand machined casting assembly",
        "code": "31121227"
      },
      { "name": "Machined permanent mold castings", "code": "31121300" },
      {
        "name": "Non ferrous alloy permanent mold machined castings",
        "code": "31121301"
      },
      {
        "name": "Ferrous alloy permanent mold machined castings",
        "code": "31121302"
      },
      { "name": "Steel permanent mold machined castings", "code": "31121303" },
      {
        "name": "Stainless steel permanent mold machined castings",
        "code": "31121304"
      },
      { "name": "Iron permanent mold machined castings", "code": "31121305" },
      {
        "name": "Aluminum permanent mold machined castings",
        "code": "31121306"
      },
      {
        "name": "Magnesium permanent mold machined castings",
        "code": "31121307"
      },
      {
        "name": "Titanium permanent mold machined castings",
        "code": "31121308"
      },
      {
        "name": "Beryllium permanent mold machined castings",
        "code": "31121309"
      },
      { "name": "Copper permanent mold machined castings", "code": "31121310" },
      { "name": "Brass permanent mold machined castings", "code": "31121311" },
      { "name": "Bronze permanent mold machined castings", "code": "31121312" },
      { "name": "Zinc permanent mold machined castings", "code": "31121313" },
      { "name": "Tin permanent mold machined castings", "code": "31121314" },
      { "name": "Lead permanent mold machined castings", "code": "31121315" },
      {
        "name": "Precious metal permanent mold machined castings",
        "code": "31121316"
      },
      {
        "name": "Composite permanent mold machined castings",
        "code": "31121317"
      },
      {
        "name": "Nickel alloy permanent mold machined castings",
        "code": "31121318"
      },
      {
        "name": "Non metallic permanent mold machined castings",
        "code": "31121319"
      },
      {
        "name": "Aluminum low pressure permanent mold machined casting",
        "code": "31121320"
      },
      {
        "name": "Aluminum low pressure permanent mold machined casting assembly",
        "code": "31121321"
      },
      { "name": "Machined plaster mold castings", "code": "31121400" },
      {
        "name": "Non ferrous alloy plaster mold machined castings",
        "code": "31121401"
      },
      {
        "name": "Ferrous alloy plaster mold machined castings",
        "code": "31121402"
      },
      { "name": "Steel plaster mold machined castings", "code": "31121403" },
      {
        "name": "Stainless steel plaster mold machined castings",
        "code": "31121404"
      },
      { "name": "Iron plaster mold machined castings", "code": "31121405" },
      { "name": "Aluminum plaster mold machined castings", "code": "31121406" },
      {
        "name": "Magnesium plaster mold machined castings",
        "code": "31121407"
      },
      { "name": "Titanium plaster mold machined castings", "code": "31121408" },
      {
        "name": "Beryllium plaster mold machined castings",
        "code": "31121409"
      },
      { "name": "Copper plaster mold machined castings", "code": "31121410" },
      { "name": "Brass plaster mold machined castings", "code": "31121411" },
      { "name": "Bronze plaster mold machined castings", "code": "31121412" },
      { "name": "Zinc plaster mold machined castings", "code": "31121413" },
      { "name": "Tin plaster mold machined castings", "code": "31121414" },
      { "name": "Lead plaster mold machined castings", "code": "31121415" },
      {
        "name": "Precious metal plaster mold machined castings",
        "code": "31121416"
      },
      {
        "name": "Composite plaster mold machined castings",
        "code": "31121417"
      },
      {
        "name": "Nickel alloy plaster mold machined castings",
        "code": "31121418"
      },
      {
        "name": "Non metallic plaster mold machined castings",
        "code": "31121419"
      },
      { "name": "Machined shell mold castings", "code": "31121500" },
      {
        "name": "Non ferrous alloy shell mold machined castings",
        "code": "31121501"
      },
      {
        "name": "Ferrous alloy shell mold machined castings",
        "code": "31121502"
      },
      { "name": "Steel shell mold machined castings", "code": "31121503" },
      {
        "name": "Stainless steel shell mold machined castings",
        "code": "31121504"
      },
      { "name": "Iron shell mold machined castings", "code": "31121505" },
      { "name": "Aluminum shell mold machined castings", "code": "31121506" },
      { "name": "Magnesium shell mold machined castings", "code": "31121507" },
      { "name": "Titanium shell mold machined castings", "code": "31121508" },
      { "name": "Beryllium shell mold machined castings", "code": "31121509" },
      { "name": "Copper shell mold machined castings", "code": "31121510" },
      { "name": "Brass shell mold machined castings", "code": "31121511" },
      { "name": "Bronze shell mold machined castings", "code": "31121512" },
      { "name": "Zinc shell mold machined castings", "code": "31121513" },
      { "name": "Tin shell mold machined castings", "code": "31121514" },
      { "name": "Lead shell mold machined castings", "code": "31121515" },
      { "name": "Precious shell mold machined castings", "code": "31121516" },
      { "name": "Composite shell mold machined castings", "code": "31121517" },
      {
        "name": "Nickel alloy shell mold machined castings",
        "code": "31121518"
      },
      {
        "name": "Non metallic shell mold machined castings",
        "code": "31121519"
      },
      {
        "name": "Ductile iron shell mold machined casting",
        "code": "31121520"
      },
      {
        "name": "Ductile iron shell mold machined casting assembly",
        "code": "31121521"
      },
      { "name": "Machined investment castings", "code": "31121600" },
      {
        "name": "Non ferrous alloy investment machined castings",
        "code": "31121601"
      },
      {
        "name": "Ferrous alloy investment machined castings",
        "code": "31121602"
      },
      { "name": "Steel investment machined castings", "code": "31121603" },
      {
        "name": "Stainless steel investment machined castings",
        "code": "31121604"
      },
      { "name": "Iron investment machined castings", "code": "31121605" },
      { "name": "Aluminum investment machined castings", "code": "31121606" },
      { "name": "Magnesium investment machined castings", "code": "31121607" },
      { "name": "Zinc investment machined castings", "code": "31121608" },
      { "name": "Tin investment machined castings", "code": "31121609" },
      { "name": "Lead investment machined castings", "code": "31121610" },
      {
        "name": "Precious metal investment machined castings",
        "code": "31121611"
      },
      { "name": "Titanium investment machined castings", "code": "31121612" },
      { "name": "Composite investment machined castings", "code": "31121613" },
      {
        "name": "Nickel alloy investment machined castings",
        "code": "31121614"
      },
      {
        "name": "Non metallic investment machined castings",
        "code": "31121615"
      },
      {
        "name": "Stainless steel investment machined casting assembly",
        "code": "31121616"
      },
      { "name": "Steel alloy investment machined casting", "code": "31121617" },
      {
        "name": "Steel alloy investment machined casting assembly",
        "code": "31121618"
      },
      { "name": "Machined centrifugal castings", "code": "31121700" },
      {
        "name": "Non ferrous alloy centrifugal machined castings",
        "code": "31121701"
      },
      {
        "name": "Ferrous alloy centrifugal machined castings",
        "code": "31121702"
      },
      { "name": "Steel centrifugal machined castings", "code": "31121703" },
      {
        "name": "Stainless steel centrifugal machined castings",
        "code": "31121704"
      },
      { "name": "Iron centrifugal machined castings", "code": "31121705" },
      { "name": "Aluminum centrifugal machined castings", "code": "31121706" },
      { "name": "Magnesium centrifugal machined castings", "code": "31121707" },
      { "name": "Titanium centrifugal machined castings", "code": "31121708" },
      { "name": "Beryllium centrifugal machined castings", "code": "31121709" },
      { "name": "Copper centrifugal machined castings", "code": "31121710" },
      { "name": "Brass centrifugal machined castings", "code": "31121711" },
      { "name": "Bronze centrifugal machined castings", "code": "31121712" },
      { "name": "Zinc centrifugal machined castings", "code": "31121713" },
      { "name": "Tin centrifugal machined castings", "code": "31121714" },
      { "name": "Lead centrifugal machined castings", "code": "31121715" },
      {
        "name": "Precious metal centrifugal machined castings",
        "code": "31121716"
      },
      { "name": "Composite centrifugal machined castings", "code": "31121717" },
      {
        "name": "Nickel alloy centrifugal machined castings",
        "code": "31121718"
      },
      {
        "name": "Non metallic centrifugal machined castings",
        "code": "31121719"
      },
      { "name": "Machined ceramic mold castings", "code": "31121800" },
      {
        "name": "Non ferrous alloy ceramic mold machined castings",
        "code": "31121801"
      },
      {
        "name": "Ferrous alloy ceramic mold machined castings",
        "code": "31121802"
      },
      { "name": "Steel ceramic mold machined castings", "code": "31121803" },
      {
        "name": "Stainless steel ceramic mold machined castings",
        "code": "31121804"
      },
      { "name": "Iron ceramic mold machined castings", "code": "31121805" },
      { "name": "Aluminum ceramic mold machined castings", "code": "31121806" },
      {
        "name": "Magnesium ceramic mold machined castings",
        "code": "31121807"
      },
      { "name": "Titanium ceramic mold machined castings", "code": "31121808" },
      {
        "name": "Beryllium ceramic mold machined castings",
        "code": "31121809"
      },
      { "name": "Copper ceramic mold machined castings", "code": "31121810" },
      { "name": "Brass ceramic mold machined castings", "code": "31121811" },
      { "name": "Bronze ceramic mold machined castings", "code": "31121812" },
      { "name": "Zinc ceramic mold machined castings", "code": "31121813" },
      { "name": "Tin ceramic mold machined castings", "code": "31121814" },
      { "name": "Lead ceramic mold machined castings", "code": "31121815" },
      {
        "name": "Precious metal ceramic mold machined castings",
        "code": "31121816"
      },
      {
        "name": "Composite ceramic mold machined castings",
        "code": "31121817"
      },
      {
        "name": "Nickel alloy ceramic mold machined castings",
        "code": "31121818"
      },
      {
        "name": "Non metallic ceramic mold machined castings",
        "code": "31121819"
      },
      { "name": "Machined graphite mold castings", "code": "31121900" },
      {
        "name": "Non ferrous alloy graphite mold machined castings",
        "code": "31121901"
      },
      {
        "name": "Ferrous alloy graphite mold machined castings",
        "code": "31121902"
      },
      { "name": "Steel graphite mold machined castings", "code": "31121903" },
      {
        "name": "Stainless steel graphite mold machined castings",
        "code": "31121904"
      },
      { "name": "Iron graphite mold machined castings", "code": "31121905" },
      {
        "name": "Aluminum graphite mold machined castings",
        "code": "31121906"
      },
      {
        "name": "Magnesium graphite mold machined castings",
        "code": "31121907"
      },
      {
        "name": "Titanium graphite mold machined castings",
        "code": "31121908"
      },
      {
        "name": "Beryllium graphite mold machined castings",
        "code": "31121909"
      },
      { "name": "Copper graphite mold machined castings", "code": "31121910" },
      { "name": "Brass graphite mold machined castings", "code": "31121911" },
      { "name": "Bronze graphite mold machined castings", "code": "31121912" },
      { "name": "Zinc graphite mold machined castings", "code": "31121913" },
      { "name": "Tin graphite mold machined castings", "code": "31121914" },
      { "name": "Lead graphite mold machined castings", "code": "31121915" },
      {
        "name": "Precious metal graphite mold machined castings",
        "code": "31121916"
      },
      {
        "name": "Composite graphite mold machined castings",
        "code": "31121917"
      },
      {
        "name": "Nickel alloy metal graphite mold machined castings",
        "code": "31121918"
      },
      {
        "name": "Non metallic graphite mold machined castings",
        "code": "31121919"
      },
      {
        "name": "Squeeze machined castings and casting assemblies",
        "code": "31122000"
      },
      { "name": "Aluminum squeeze machined casting", "code": "31122001" },
      {
        "name": "Aluminum squeeze machined casting assembly",
        "code": "31122002"
      },
      {
        "name": "Semi solid machined castings and casting assemblies",
        "code": "31122100"
      },
      { "name": "Aluminum semi solid machined casting", "code": "31122101" },
      {
        "name": "Aluminum semi solid machined casting assembly",
        "code": "31122102"
      },
      {
        "name": "Gravity permanent mold machined castings and casting assemblies",
        "code": "31122200"
      },
      {
        "name": "Aluminum gravity permanent mold machined casting",
        "code": "31122201"
      },
      {
        "name": "Aluminum gravity permanent mold machined casting assembly",
        "code": "31122202"
      },
      { "name": "Forgings", "code": "31130000" },
      { "name": "Powdered metal components", "code": "31132000" },
      { "name": "Ferrous powdered metal components", "code": "31132001" },
      { "name": "Non ferrous powdered metal parts", "code": "31132002" },
      { "name": "Steel forgings", "code": "31132100" },
      { "name": "Cold forged machined steel forging", "code": "31132101" },
      { "name": "Cold forged heat treated steel forging", "code": "31132102" },
      {
        "name": "Cold forged machined and heat treated steel forging",
        "code": "31132103"
      },
      {
        "name": "Cold forged heat treated and cold sized steel forging",
        "code": "31132104"
      },
      { "name": "Warm forged machined steel forging", "code": "31132105" },
      { "name": "Warm forged heat treated steel forging", "code": "31132106" },
      {
        "name": "Warm forged machined and heat treated steel forging",
        "code": "31132107"
      },
      {
        "name": "Warm forged heat treated and cold sized steel forging",
        "code": "31132108"
      },
      { "name": "Hot forged machined steel forging", "code": "31132109" },
      { "name": "Hot forged heat treated steel forging", "code": "31132110" },
      {
        "name": "Hot forged machined and heat treated steel forging",
        "code": "31132111"
      },
      {
        "name": "Hot forged heat treated and cold sized steel forging",
        "code": "31132112"
      },
      { "name": "Brass forgings", "code": "31132200" },
      { "name": "Cold forged machined brass forging", "code": "31132201" },
      { "name": "Cold forged heat treated brass forging", "code": "31132202" },
      {
        "name": "Cold forged machined and heat treated brass forging",
        "code": "31132203"
      },
      {
        "name": "Cold forged heat treated and cold sized brass forging",
        "code": "31132204"
      },
      { "name": "Warm forged machined brass forging", "code": "31132205" },
      { "name": "Warm forged heat treated brass forging", "code": "31132206" },
      {
        "name": "Warm forged machined and heat treated brass forging",
        "code": "31132207"
      },
      {
        "name": "Warm forged heat treated and cold sized brass forging",
        "code": "31132208"
      },
      { "name": "Aluminum forgings", "code": "31132300" },
      { "name": "Cold forged machined aluminum forging", "code": "31132301" },
      {
        "name": "Cold forged heat treated aluminum forging",
        "code": "31132302"
      },
      {
        "name": "Cold forged machined and heat treated aluminum forging",
        "code": "31132303"
      },
      {
        "name": "Cold forged heat treated and cold sized aluminum forging",
        "code": "31132304"
      },
      { "name": "Warm forged machined aluminum forging", "code": "31132305" },
      {
        "name": "Warm forged heat treated aluminum forging",
        "code": "31132306"
      },
      {
        "name": "Warm forged machined and heat treated aluminum forging",
        "code": "31132307"
      },
      {
        "name": "Warm forged heat treated and cold sized aluminum forging",
        "code": "31132308"
      },
      { "name": "Non ferrous alloy forgings", "code": "31132400" },
      {
        "name": "Cold forged machined non ferrous alloy forging",
        "code": "31132401"
      },
      {
        "name": "Cold forged heat treated non ferrous alloy forging",
        "code": "31132402"
      },
      {
        "name": "Cold forged machined and heat treated non ferrous alloy forging",
        "code": "31132403"
      },
      {
        "name": "Cold forged heat treated and cold sized non ferrous alloy forging",
        "code": "31132404"
      },
      {
        "name": "Warm forged machined non ferrous alloy forging",
        "code": "31132405"
      },
      {
        "name": "Warm forged heat treated non ferrous alloy forging",
        "code": "31132406"
      },
      {
        "name": "Warm forged machined and heat treated non ferrous alloy forging",
        "code": "31132407"
      },
      {
        "name": "Warm forged heat treated and cold sized non ferrous alloy forging",
        "code": "31132408"
      },
      {
        "name": "Hot forged machined non ferrous alloy forging",
        "code": "31132409"
      },
      {
        "name": "Hot forged heat treated non ferrous alloy forging",
        "code": "31132410"
      },
      {
        "name": "Hot forged machined and heat treated non ferrous alloy forging",
        "code": "31132411"
      },
      {
        "name": "Hot forged heat treated and cold sized non ferrous alloy forging",
        "code": "31132412"
      },
      { "name": "Ferrous alloy forgings", "code": "31132500" },
      {
        "name": "Cold forged machined ferrous alloy forging",
        "code": "31132501"
      },
      {
        "name": "Cold forged heat treated ferrous alloy forging",
        "code": "31132502"
      },
      {
        "name": "Cold forged machined and heat treated ferrous alloy forging",
        "code": "31132503"
      },
      {
        "name": "Cold forged heat treated and cold sized ferrous alloy forging",
        "code": "31132504"
      },
      {
        "name": "Warm forged machined ferrous alloy forging",
        "code": "31132505"
      },
      {
        "name": "Warm forged heat treated ferrous alloy forging",
        "code": "31132506"
      },
      {
        "name": "Warm forged machined and heat treated ferrous alloy forging",
        "code": "31132507"
      },
      {
        "name": "Warm forged heat treated and cold sized ferrous alloy forging",
        "code": "31132508"
      },
      {
        "name": "Hot forged machined ferrous alloy forging",
        "code": "31132509"
      },
      {
        "name": "Hot forged heat treated ferrous alloy forging",
        "code": "31132510"
      },
      {
        "name": "Hot forged machined and heat treated ferrous alloy forging",
        "code": "31132511"
      },
      {
        "name": "Hot forged heat treated and cold sized ferrous alloy forging",
        "code": "31132512"
      },
      { "name": "Stainless steel forgings", "code": "31132600" },
      {
        "name": "Cold forged machined stainless steel forging",
        "code": "31132601"
      },
      {
        "name": "Cold forged heat treated stainless steel forging",
        "code": "31132602"
      },
      {
        "name": "Cold forged machined and heat treated stainless steel forging",
        "code": "31132603"
      },
      {
        "name": "Cold forged heat treated and cold sized stainless steel forging",
        "code": "31132604"
      },
      {
        "name": "Warm forged machined stainless steel forging",
        "code": "31132605"
      },
      {
        "name": "Warm forged heat treated stainless steel forging",
        "code": "31132606"
      },
      {
        "name": "Warm forged machined and heat treated stainless steel forging",
        "code": "31132607"
      },
      {
        "name": "Warm forged heat treated and cold sized stainless steel forging",
        "code": "31132608"
      },
      {
        "name": "Hot forged machined stainless steel forging",
        "code": "31132609"
      },
      {
        "name": "Hot forged heat treated stainless steel forging",
        "code": "31132610"
      },
      {
        "name": "Hot forged machined and heat treated stainless steel forging",
        "code": "31132611"
      },
      {
        "name": "Hot forged heat treated and cold sized stainless steel forging",
        "code": "31132612"
      },
      { "name": "Iron forgings", "code": "31132700" },
      { "name": "Cold forged machined iron forging", "code": "31132701" },
      { "name": "Cold forged heat treated iron forging", "code": "31132702" },
      {
        "name": "Cold forged machined and heat treated iron forging",
        "code": "31132703"
      },
      {
        "name": "Cold forged heat treated and cold sized iron forging",
        "code": "31132704"
      },
      { "name": "Warm forged machined iron forging", "code": "31132705" },
      { "name": "Warm forged heat treated iron forging", "code": "31132706" },
      {
        "name": "Warm forged machined and heat treated iron forging",
        "code": "31132707"
      },
      {
        "name": "Warm forged heat treated and cold sized iron forging",
        "code": "31132708"
      },
      { "name": "Hot forged machined iron forging", "code": "31132709" },
      { "name": "Hot forged heat treated iron forging", "code": "31132710" },
      {
        "name": "Hot forged machined and heat treated iron forging",
        "code": "31132711"
      },
      {
        "name": "Hot forged heat treated and cold sized iron forging",
        "code": "31132712"
      },
      { "name": "Magnesium forgings", "code": "31132800" },
      { "name": "Cold forged machined magnesium forging", "code": "31132801" },
      {
        "name": "Cold forged heat treated magnesium forging",
        "code": "31132802"
      },
      {
        "name": "Cold forged machined and heat treated magnesium forging",
        "code": "31132803"
      },
      {
        "name": "Cold forged heat treated and cold sized magnesium forging",
        "code": "31132804"
      },
      { "name": "Warm forged machined magnesium forging", "code": "31132805" },
      {
        "name": "Warm forged heat treated magnesium forging",
        "code": "31132806"
      },
      {
        "name": "Warm forged machined and heat treated magnesium forging",
        "code": "31132807"
      },
      {
        "name": "Warm forged heat treated and cold sized magnesium forging",
        "code": "31132808"
      },
      { "name": "Hot forged machined magnesium forging", "code": "31132809" },
      {
        "name": "Hot forged heat treated magnesium forging",
        "code": "31132810"
      },
      {
        "name": "Hot forged machined and heat treated magnesium forging",
        "code": "31132811"
      },
      {
        "name": "Hot forged heat treated and cold sized magnesium forging",
        "code": "31132812"
      },
      { "name": "Titanium forgings", "code": "31132900" },
      { "name": "Cold forged machined titanium forging", "code": "31132901" },
      {
        "name": "Cold forged heat treated titanium forging",
        "code": "31132902"
      },
      {
        "name": "Cold forged machined and heat treated titanium forging",
        "code": "31132903"
      },
      {
        "name": "Cold forged heat treated and cold sized titanium forging",
        "code": "31132904"
      },
      { "name": "Warm forged machined titanium forging", "code": "31132905" },
      {
        "name": "Warm forged heat treated titanium forging",
        "code": "31132906"
      },
      {
        "name": "Warm forged machined and heat treated titanium forging",
        "code": "31132907"
      },
      {
        "name": "Warm forged heat treated and cold sized titanium forging",
        "code": "31132908"
      },
      { "name": "Hot forged machined titanium forging", "code": "31132909" },
      {
        "name": "Hot forged heat treated titanium forging",
        "code": "31132910"
      },
      {
        "name": "Hot forged machined and heat treated titanium forging",
        "code": "31132911"
      },
      {
        "name": "Hot forged heat treated and cold sized titanium forging",
        "code": "31132912"
      },
      { "name": "Beryllium forgings", "code": "31133000" },
      { "name": "Cold forged machined beryllium forging", "code": "31133001" },
      {
        "name": "Cold forged heat treated beryllium forging",
        "code": "31133002"
      },
      {
        "name": "Cold forged machined and heat treated beryllium forging",
        "code": "31133003"
      },
      {
        "name": "Cold forged heat treated and cold sized beryllium forging",
        "code": "31133004"
      },
      { "name": "Warm forged machined beryllium forging", "code": "31133005" },
      {
        "name": "Warm forged heat treated beryllium forging",
        "code": "31133006"
      },
      {
        "name": "Warm forged machined and heat treated beryllium forging",
        "code": "31133007"
      },
      {
        "name": "Warm forged heat treated and cold sized beryllium forging",
        "code": "31133008"
      },
      { "name": "Hot forged machined beryllium forging", "code": "31133009" },
      {
        "name": "Hot forged heat treated beryllium forging",
        "code": "31133010"
      },
      {
        "name": "Hot forged machined and heat treated beryllium forging",
        "code": "31133011"
      },
      {
        "name": "Hot forged heat treated and cold sized beryllium forging",
        "code": "31133012"
      },
      { "name": "Copper forgings", "code": "31133100" },
      { "name": "Cold forged machined copper forging", "code": "31133101" },
      { "name": "Cold forged heat treated copper forging", "code": "31133102" },
      {
        "name": "Cold forged machined and heat treated copper forging",
        "code": "31133103"
      },
      {
        "name": "Cold forged heat treated and cold sized copper forging",
        "code": "31133104"
      },
      { "name": "Warm forged machined copper forging", "code": "31133105" },
      { "name": "Warm forged heat treated copper forging", "code": "31133106" },
      {
        "name": "Warm forged machined and heat treated copper forging",
        "code": "31133107"
      },
      {
        "name": "Warm forged heat treated and cold sized copper forging",
        "code": "31133108"
      },
      { "name": "Hot forged machined copper forging", "code": "31133109" },
      { "name": "Hot forged heat treated copper forging", "code": "31133110" },
      {
        "name": "Hot forged machined and heat treated copper forging",
        "code": "31133111"
      },
      {
        "name": "Hot forged heat treated and cold sized copper forging",
        "code": "31133112"
      },
      { "name": "Zinc forgings", "code": "31133200" },
      { "name": "Cold forged machined zinc forging", "code": "31133201" },
      { "name": "Cold forged heat treated zinc forging", "code": "31133202" },
      {
        "name": "Cold forged machined and heat treated zinc forging",
        "code": "31133203"
      },
      {
        "name": "Cold forged heat treated and cold sized zinc forging",
        "code": "31133204"
      },
      { "name": "Warm forged machined zinc forging", "code": "31133205" },
      { "name": "Warm forged heat treated zinc forging", "code": "31133206" },
      {
        "name": "Warm forged machined and heat treated zinc forging",
        "code": "31133207"
      },
      {
        "name": "Warm forged heat treated and cold sized zinc forging",
        "code": "31133208"
      },
      { "name": "Hot forged machined zinc forging", "code": "31133209" },
      { "name": "Hot forged heat treated zinc forging", "code": "31133210" },
      {
        "name": "Hot forged machined and heat treated zinc forging",
        "code": "31133211"
      },
      {
        "name": "Hot forged heat treated and cold sized zinc forging",
        "code": "31133212"
      },
      { "name": "Bronze forgings", "code": "31133300" },
      { "name": "Cold forged machined bronze forging", "code": "31133301" },
      { "name": "Cold forged heat treated bronze forging", "code": "31133302" },
      {
        "name": "Cold forged machined and heat treated bronze forging",
        "code": "31133303"
      },
      {
        "name": "Cold forged heat treated and cold sized bronze forging",
        "code": "31133304"
      },
      { "name": "Warm forged machined bronze forging", "code": "31133305" },
      { "name": "Warm forged heat treated bronze forging", "code": "31133306" },
      {
        "name": "Warm forged machined and heat treated bronze forging",
        "code": "31133307"
      },
      {
        "name": "Warm forged heat treated and cold sized bronze forging",
        "code": "31133308"
      },
      { "name": "Hot forged machined bronze forging", "code": "31133309" },
      { "name": "Hot forged heat treated bronze forging", "code": "31133310" },
      {
        "name": "Hot forged machined and heat treated bronze forging",
        "code": "31133311"
      },
      {
        "name": "Hot forged heat treated and cold sized bronze forging",
        "code": "31133312"
      },
      { "name": "Tin forgings", "code": "31133400" },
      { "name": "Cold forged machined tin forging", "code": "31133401" },
      { "name": "Cold forged heat treated tin forging", "code": "31133402" },
      {
        "name": "Cold forged machined and heat treated tin forging",
        "code": "31133403"
      },
      {
        "name": "Cold forged heat treated and cold sized tin forging",
        "code": "31133404"
      },
      { "name": "Warm forged machined tin forging", "code": "31133405" },
      { "name": "Warm forged heat treated tin forging", "code": "31133406" },
      {
        "name": "Warm forged machined and heat treated tin forging",
        "code": "31133407"
      },
      {
        "name": "Warm forged heat treated and cold sized tin forging",
        "code": "31133408"
      },
      { "name": "Hot forged machined tin forging", "code": "31133409" },
      { "name": "Hot forged heat treated tin forging", "code": "31133410" },
      {
        "name": "Hot forged machined and heat treated tin forging",
        "code": "31133411"
      },
      {
        "name": "Hot forged heat treated and cold sized tin forging",
        "code": "31133412"
      },
      { "name": "Lead forgings", "code": "31133500" },
      { "name": "Cold forged machined lead forging", "code": "31133501" },
      { "name": "Cold forged heat treated lead forging", "code": "31133502" },
      {
        "name": "Cold forged machined and heat treated lead forging",
        "code": "31133503"
      },
      {
        "name": "Cold forged heat treated and cold sized lead forging",
        "code": "31133504"
      },
      { "name": "Warm forged machined lead forging", "code": "31133505" },
      { "name": "Warm forged heat treated lead forging", "code": "31133506" },
      {
        "name": "Warm forged machined and heat treated lead forging",
        "code": "31133507"
      },
      {
        "name": "Warm forged heat treated and cold sized lead forging",
        "code": "31133508"
      },
      { "name": "Hot forged machined lead forging", "code": "31133509" },
      { "name": "Hot forged heat treated lead forging", "code": "31133510" },
      {
        "name": "Hot forged machined and heat treated lead forging",
        "code": "31133511"
      },
      {
        "name": "Hot forged heat treated and cold sized lead forging",
        "code": "31133512"
      },
      { "name": "Precious metal forgings", "code": "31133600" },
      {
        "name": "Cold forged machined precious metal forging",
        "code": "31133601"
      },
      {
        "name": "Cold forged heat treated precious metal forging",
        "code": "31133602"
      },
      {
        "name": "Cold forged machined and heat treated precious metal forging",
        "code": "31133603"
      },
      {
        "name": "Cold forged heat treated and cold sized precious metal forging",
        "code": "31133604"
      },
      {
        "name": "Warm forged machined precious metal forging",
        "code": "31133605"
      },
      {
        "name": "Warm forged heat treated precious metal forging",
        "code": "31133606"
      },
      {
        "name": "Warm forged machined and heat treated precious metal forging",
        "code": "31133607"
      },
      {
        "name": "Warm forged heat treated and cold sized precious metal forging",
        "code": "31133608"
      },
      {
        "name": "Hot forged machined precious metal forging",
        "code": "31133609"
      },
      {
        "name": "Hot forged heat treated precious metal forging",
        "code": "31133610"
      },
      {
        "name": "Hot forged machined and heat treated precious metal forging",
        "code": "31133611"
      },
      {
        "name": "Hot forged heat treated and cold sized precious metal forging",
        "code": "31133612"
      },
      { "name": "Powdered metals and metal alloys", "code": "31133700" },
      {
        "name": "Friction material powdered metal and metal alloy",
        "code": "31133701"
      },
      {
        "name": "Electrical contact powdered metal and metal alloy",
        "code": "31133702"
      },
      {
        "name": "Cemented carbide powdered metal and metal alloy",
        "code": "31133703"
      },
      {
        "name": "Diamond metal matrix powdered metal and metal alloy",
        "code": "31133704"
      },
      { "name": "Moldings", "code": "31140000" },
      { "name": "Injection moldings", "code": "31141500" },
      { "name": "Plastic injection moldings", "code": "31141501" },
      { "name": "Rubber injection moldings", "code": "31141502" },
      { "name": "Glass injection moldings", "code": "31141503" },
      { "name": "Vacuum moldings", "code": "31141600" },
      { "name": "Plastic vacuum moldings", "code": "31141601" },
      { "name": "Rubber vacuum moldings", "code": "31141602" },
      { "name": "Glass vacuum moldings", "code": "31141603" },
      { "name": "Blow moldings", "code": "31141700" },
      { "name": "Plastic blow moldings", "code": "31141701" },
      { "name": "Rubber blow moldings", "code": "31141702" },
      { "name": "Reaction injection moldings RIM", "code": "31141800" },
      { "name": "Plastic reaction injection moldings", "code": "31141801" },
      { "name": "Rubber reaction injection moldings", "code": "31141802" },
      { "name": "Inserted injection moldings", "code": "31141900" },
      { "name": "Plastic inserted injection molding", "code": "31141901" },
      { "name": "Thermoplastic molding assemblies", "code": "31142000" },
      {
        "name": "Thermoplastic compression molding assembly",
        "code": "31142001"
      },
      { "name": "Thermoplastic dip molding assembly", "code": "31142002" },
      { "name": "Thermoplastic blown molding assembly", "code": "31142003" },
      {
        "name": "Thermoplastic injection molding assembly",
        "code": "31142004"
      },
      {
        "name": "Thermoplastic gas assisted injection molding assembly",
        "code": "31142005"
      },
      {
        "name": "Thermoplastic high precision injection molding assembly",
        "code": "31142006"
      },
      { "name": "Thermoplastic transfer molding assembly", "code": "31142007" },
      { "name": "Thermoplastic molding inserts", "code": "31142100" },
      {
        "name": "Thermoplastic compression molding insert",
        "code": "31142101"
      },
      { "name": "Thermoplastic dip molding insert", "code": "31142102" },
      { "name": "Thermoplastic blown molding insert", "code": "31142103" },
      { "name": "Thermoplastic injection molding insert", "code": "31142104" },
      {
        "name": "Thermoplastic gas assisted injection molding insert",
        "code": "31142105"
      },
      {
        "name": "Thermoplastic high precision injection molding insert",
        "code": "31142106"
      },
      { "name": "Thermoplastic transfer molding insert", "code": "31142107" },
      {
        "name": "Thermoplastic multiple shot molding assemblies",
        "code": "31142200"
      },
      {
        "name": "Thermoplastic compression multiple shot inserted molding assembly",
        "code": "31142201"
      },
      {
        "name": "Thermoplastic dip multiple shot inserted molding assembly",
        "code": "31142202"
      },
      {
        "name": "Thermoplastic blown multiple shot inserted molding assembly",
        "code": "31142203"
      },
      {
        "name": "Thermoplastic injection multiple shot inserted molding assembly",
        "code": "31142204"
      },
      {
        "name": "Thermoplastic gas assisted injection multiple shot inserted molding assembly",
        "code": "31142205"
      },
      {
        "name": "Thermoplastic high precision injection multiple shot inserted molding assembly",
        "code": "31142206"
      },
      {
        "name": "Thermoplastic transfer multiple shot inserted molding assembly",
        "code": "31142207"
      },
      {
        "name": "Thermoplastic inserted molding assemblies",
        "code": "31142300"
      },
      {
        "name": "Thermoplastic compression inserted molding assembly",
        "code": "31142301"
      },
      {
        "name": "Thermoplastic dip inserted molding assembly",
        "code": "31142302"
      },
      {
        "name": "Thermoplastic blown inserted molding assembly",
        "code": "31142303"
      },
      {
        "name": "Thermoplastic injection inserted molding assembly",
        "code": "31142304"
      },
      {
        "name": "Thermoplastic gas assisted injection inserted molding assembly",
        "code": "31142305"
      },
      {
        "name": "Thermoplastic high precision injection inserted molding assembly",
        "code": "31142306"
      },
      {
        "name": "Thermoplastic transfer inserted molding assembly",
        "code": "31142307"
      },
      { "name": "Thermoset molding assemblies", "code": "31142400" },
      { "name": "Thermoset compression molding assembly", "code": "31142401" },
      { "name": "Thermoset dip molding assembly", "code": "31142402" },
      { "name": "Thermoset blown molding assembly", "code": "31142403" },
      { "name": "Thermoset injection molding assembly", "code": "31142404" },
      {
        "name": "Thermoset gas assisted injection molding assembly",
        "code": "31142405"
      },
      {
        "name": "Thermoset high precision injection molding assembly",
        "code": "31142406"
      },
      { "name": "Thermoset transfer molding assembly", "code": "31142407" },
      { "name": "Thermoset molding inserts", "code": "31142500" },
      { "name": "Thermoset compression molding insert", "code": "31142501" },
      { "name": "Thermoset dip molding insert", "code": "31142502" },
      { "name": "Thermoset blown molding insert", "code": "31142503" },
      { "name": "Thermoset injection molding insert", "code": "31142504" },
      {
        "name": "Thermoset gas assisted injection molding insert",
        "code": "31142505"
      },
      {
        "name": "Thermoset high precision injection molding insert",
        "code": "31142506"
      },
      { "name": "Thermoset transfer molding insert", "code": "31142507" },
      {
        "name": "Thermoset multiple shot molding assemblies",
        "code": "31142600"
      },
      {
        "name": "Thermoset compression multiple shot inserted molding assembly",
        "code": "31142601"
      },
      {
        "name": "Thermoset dip multiple shot inserted molding assembly",
        "code": "31142602"
      },
      {
        "name": "Thermoset blown multiple shot inserted molding assembly",
        "code": "31142603"
      },
      {
        "name": "Thermoset injection multiple shot inserted molding assembly",
        "code": "31142604"
      },
      {
        "name": "Thermoset gas assisted injection multiple shot inserted molding assembly",
        "code": "31142605"
      },
      {
        "name": "Thermoset high precision injection multiple shot inserted molding assembly",
        "code": "31142606"
      },
      {
        "name": "Thermoset transfer multiple shot inserted molding assembly",
        "code": "31142607"
      },
      { "name": "Thermoset inserted modling assembly", "code": "31142700" },
      {
        "name": "Thermoset compression inserted molding assembly",
        "code": "31142701"
      },
      { "name": "Thermoset dip inserted molding assembly", "code": "31142702" },
      {
        "name": "Thermoset blown inserted molding assembly",
        "code": "31142703"
      },
      {
        "name": "Thermoset injection inserted molding assembly",
        "code": "31142704"
      },
      {
        "name": "Thermoset gas assisted injection inserted molding assembly",
        "code": "31142705"
      },
      {
        "name": "Thermoset high precision injection inserted molding assembly",
        "code": "31142706"
      },
      {
        "name": "Thermoset transfer inserted molding assembly",
        "code": "31142707"
      },
      { "name": "Dip moldings", "code": "31142800" },
      { "name": "Plastic or plastisol dip molding", "code": "31142801" },
      { "name": "Latex dip molding", "code": "31142802" },
      { "name": "Neoprene dip molding", "code": "31142803" },
      { "name": "Urethane dip molding", "code": "31142804" },
      { "name": "In mold decorated injection moldings", "code": "31142900" },
      { "name": "In mold applique injection molding", "code": "31142901" },
      { "name": "In mold ink transfer injection molding", "code": "31142902" },
      { "name": "Mold to color injection molding", "code": "31142903" },
      { "name": "Multiple shot injection molding", "code": "31142904" },
      { "name": "Gas assist moldings", "code": "31143000" },
      { "name": "Plastic gas assist molding", "code": "31143001" },
      { "name": "Structural foam gas assist molding", "code": "31143002" },
      {
        "name": "Thermoplastic finished molding assemblies",
        "code": "31143100"
      },
      {
        "name": "Thermoplastic injection finished molding assembly",
        "code": "31143101"
      },
      {
        "name": "Thermoplastic compression finished molding assembly",
        "code": "31143102"
      },
      {
        "name": "Thermoplastic gas assisted injection finished molding assembly",
        "code": "31143103"
      },
      { "name": "Post mold decorated injection moldings", "code": "31143200" },
      { "name": "Painted injection molding", "code": "31143201" },
      { "name": "Plated injection molding", "code": "31143202" },
      { "name": "Metallized injection molding", "code": "31143203" },
      { "name": "Lased injection molding", "code": "31143204" },
      { "name": "Lased and painted injection molding", "code": "31143205" },
      {
        "name": "Rope and chain and cable and wire and strap",
        "code": "31150000"
      },
      { "name": "Ropes", "code": "31151500" },
      { "name": "Cotton rope", "code": "31151501" },
      { "name": "Polyester rope", "code": "31151502" },
      { "name": "Polypropylene rope", "code": "31151503" },
      { "name": "Nylon rope", "code": "31151504" },
      { "name": "Wire rope", "code": "31151505" },
      { "name": "Hemp rope", "code": "31151506" },
      { "name": "String or twine", "code": "31151507" },
      { "name": "Sisal rope", "code": "31151508" },
      { "name": "Rubber rope", "code": "31151509" },
      { "name": "Rayon rope", "code": "31151510" },
      { "name": "Linen rope", "code": "31151511" },
      { "name": "Polyethylene rope", "code": "31151512" },
      { "name": "Coconut fiber rope", "code": "31151513" },
      { "name": "Jute rope", "code": "31151514" },
      { "name": "Esparto grass rope", "code": "31151515" },
      { "name": "Raffia rope", "code": "31151516" },
      { "name": "Aloe rope", "code": "31151517" },
      { "name": "Impregnated rope", "code": "31151518" },
      { "name": "Latex rope", "code": "31151519" },
      { "name": "Vinylon rope", "code": "31151520" },
      { "name": "Asbestos rope", "code": "31151521" },
      { "name": "Chains", "code": "31151600" },
      { "name": "Safety chains", "code": "31151601" },
      { "name": "Roller chains", "code": "31151603" },
      { "name": "Proof coil chains", "code": "31151604" },
      { "name": "Sash chain", "code": "31151605" },
      { "name": "Jack chains", "code": "31151606" },
      { "name": "Coil chains", "code": "31151607" },
      { "name": "Ball chains", "code": "31151608" },
      { "name": "Chain links", "code": "31151609" },
      { "name": "Hollow pin chain", "code": "31151610" },
      { "name": "Silent chain", "code": "31151611" },
      { "name": "Block chain", "code": "31151612" },
      { "name": "Engineering chain", "code": "31151613" },
      { "name": "Conveying chain", "code": "31151614" },
      { "name": "Roller or plate top chain", "code": "31151615" },
      { "name": "Pintle chain", "code": "31151616" },
      { "name": "Dragline chain", "code": "31151617" },
      { "name": "Longwall chain", "code": "31151618" },
      { "name": "Escalator step chain", "code": "31151619" },
      { "name": "Mechanical cable", "code": "31151700" },
      { "name": "Non electric control cables", "code": "31151702" },
      { "name": "Lifting cables", "code": "31151703" },
      { "name": "Track cables", "code": "31151704" },
      { "name": "Non electrical steel cables", "code": "31151705" },
      { "name": "Non electrical copper cables", "code": "31151706" },
      { "name": "Non electrical aluminum cables", "code": "31151707" },
      { "name": "Guy cables", "code": "31151708" },
      { "name": "Non electrical iron cable", "code": "31151709" },
      { "name": "Synthetic cable", "code": "31151710" },
      { "name": "Mechanical wire", "code": "31151800" },
      { "name": "Piano steel wire", "code": "31151803" },
      { "name": "Staple wire", "code": "31151804" },
      { "name": "Strand or multi filament wire", "code": "31151805" },
      { "name": "Laminated wire", "code": "31151806" },
      { "name": "Straps", "code": "31151900" },
      { "name": "Metal straps", "code": "31151901" },
      { "name": "Leather straps", "code": "31151902" },
      { "name": "Fiber straps", "code": "31151903" },
      { "name": "Plastic straps", "code": "31151904" },
      { "name": "Rubber straps", "code": "31151905" },
      { "name": "Ratchet tie down strap", "code": "31151906" },
      { "name": "Security wire", "code": "31152000" },
      { "name": "Razor wire", "code": "31152001" },
      { "name": "Barbed wire", "code": "31152002" },
      { "name": "Cords", "code": "31152100" },
      { "name": "Cotton cord", "code": "31152101" },
      { "name": "Nylon cord", "code": "31152102" },
      { "name": "Metallic cord", "code": "31152103" },
      { "name": "Silicone cord", "code": "31152104" },
      { "name": "Latex cord", "code": "31152105" },
      { "name": "Polyester cord", "code": "31152106" },
      { "name": "Neoprene cord", "code": "31152107" },
      { "name": "Polypropylene cord", "code": "31152108" },
      { "name": "Rubber cord", "code": "31152109" },
      { "name": "Asbestos cord", "code": "31152110" },
      { "name": "Glass fiber cord", "code": "31152111" },
      { "name": "Ceramic fiber cord", "code": "31152112" },
      { "name": "Non electric iron and steel wire", "code": "31152200" },
      { "name": "Low carbon steel wire", "code": "31152201" },
      { "name": "Hard drawn steel wire", "code": "31152202" },
      { "name": "Stainless steel wire", "code": "31152203" },
      { "name": "Stranded steel wire", "code": "31152204" },
      { "name": "Oil tempered wire", "code": "31152205" },
      { "name": "Coated steel wire", "code": "31152206" },
      { "name": "Prestressed concrete steel wire", "code": "31152207" },
      {
        "name": "Prestressed concrete stranded steel wire",
        "code": "31152208"
      },
      { "name": "Iron wire", "code": "31152209" },
      { "name": "Non electric nonferrous metal wire", "code": "31152300" },
      { "name": "Lead or lead alloy wire", "code": "31152301" },
      { "name": "Nickel or nickel alloy wire", "code": "31152302" },
      { "name": "Nichrome alloy wire", "code": "31152303" },
      { "name": "Copper or copper alloy wire", "code": "31152304" },
      { "name": "Aluminium or aluminium alloy wire", "code": "31152305" },
      { "name": "Tin or tin alloy wire", "code": "31152306" },
      { "name": "Titanium or titanium alloy wire", "code": "31152307" },
      { "name": "Hardware", "code": "31160000" },
      { "name": "Screws", "code": "31161500" },
      { "name": "Cap screws", "code": "31161501" },
      { "name": "Anchor screws", "code": "31161502" },
      { "name": "Drive screws", "code": "31161503" },
      { "name": "Machine screws", "code": "31161504" },
      { "name": "Set screws", "code": "31161505" },
      { "name": "Sheet metal screws", "code": "31161506" },
      { "name": "Tapping screws", "code": "31161507" },
      { "name": "Wood screws", "code": "31161508" },
      { "name": "Drywall screws", "code": "31161509" },
      { "name": "Captive screw", "code": "31161510" },
      { "name": "Locking screws", "code": "31161511" },
      { "name": "Thread rolling screws", "code": "31161512" },
      { "name": "Flathead screws", "code": "31161513" },
      { "name": "Weld screws", "code": "31161514" },
      { "name": "Thumb screws", "code": "31161516" },
      { "name": "Shoulder screws", "code": "31161517" },
      { "name": "Socket screw", "code": "31161518" },
      { "name": "Elevator bucket screw", "code": "31161519" },
      { "name": "Hexagonal head screw", "code": "31161520" },
      { "name": "Carriage screw", "code": "31161521" },
      { "name": "Eye screw", "code": "31161522" },
      { "name": "Wing screw", "code": "31161523" },
      { "name": "Decorative screw", "code": "31161524" },
      { "name": "Screw for plastic", "code": "31161525" },
      { "name": "Concrete screw", "code": "31161526" },
      { "name": "Hammerhead or T screw", "code": "31161527" },
      { "name": "Self drilling screw", "code": "31161528" },
      { "name": "Jack screw", "code": "31161529" },
      { "name": "Grub screw", "code": "31161530" },
      { "name": "Lead screw or power screw", "code": "31161531" },
      { "name": "Allen screw", "code": "31161532" },
      { "name": "Washer assembled screw", "code": "31161533" },
      { "name": "Trapezoidal thread screw", "code": "31161534" },
      { "name": "Acoustical lag screw", "code": "31161535" },
      { "name": "Bolts", "code": "31161600" },
      { "name": "Anchor bolts", "code": "31161601" },
      { "name": "Blind bolts", "code": "31161602" },
      { "name": "Carriage bolts", "code": "31161603" },
      { "name": "Clevis bolts", "code": "31161604" },
      { "name": "Cylinder bolts", "code": "31161605" },
      { "name": "Door bolts", "code": "31161606" },
      { "name": "Expansion bolts", "code": "31161607" },
      { "name": "Lag bolts", "code": "31161608" },
      { "name": "Toggle bolts", "code": "31161609" },
      { "name": "Eye bolts", "code": "31161610" },
      { "name": "Locking bolts", "code": "31161611" },
      { "name": "Pin or collar bolts", "code": "31161612" },
      { "name": "Tension bolts", "code": "31161613" },
      { "name": "Structural bolts", "code": "31161614" },
      { "name": "U bolts", "code": "31161616" },
      { "name": "Wing bolts", "code": "31161617" },
      { "name": "Threaded rod", "code": "31161618" },
      { "name": "Stud bolts", "code": "31161619" },
      { "name": "Hexagonal bolts", "code": "31161620" },
      { "name": "Elevator bolts", "code": "31161621" },
      { "name": "Shear bolt", "code": "31161622" },
      { "name": "Cable bolt", "code": "31161623" },
      { "name": "Resin bolt", "code": "31161624" },
      { "name": "Railway track bolt", "code": "31161625" },
      { "name": "Sems bolt", "code": "31161626" },
      { "name": "Bolt assembly", "code": "31161627" },
      { "name": "Square head bolt", "code": "31161628" },
      { "name": "Round head bolt", "code": "31161629" },
      { "name": "Blank bolt", "code": "31161630" },
      { "name": "Shoulder bolt", "code": "31161631" },
      { "name": "Rock bolt", "code": "31161632" },
      { "name": "Stove bolt", "code": "31161633" },
      { "name": "Over neck bolt", "code": "31161634" },
      { "name": "Washer assembled bolt", "code": "31161635" },
      { "name": "Welding bolt", "code": "31161636" },
      { "name": "Socket head bolt", "code": "31161637" },
      { "name": "T bolt", "code": "31161638" },
      { "name": "Hanger bolt", "code": "31161639" },
      { "name": "Hook bolt", "code": "31161640" },
      { "name": "Nuts", "code": "31161700" },
      { "name": "Anchor nuts", "code": "31161701" },
      { "name": "Bearing nuts", "code": "31161702" },
      { "name": "Blind nuts", "code": "31161703" },
      { "name": "Barrel nuts", "code": "31161704" },
      { "name": "Cap nuts", "code": "31161705" },
      { "name": "Captive nuts", "code": "31161706" },
      { "name": "Castle nuts", "code": "31161707" },
      { "name": "Channel nuts", "code": "31161708" },
      { "name": "Clamping nuts", "code": "31161709" },
      { "name": "Expansion nuts", "code": "31161710" },
      { "name": "Eye nuts", "code": "31161711" },
      { "name": "Flange nuts", "code": "31161712" },
      { "name": "Hose nuts", "code": "31161713" },
      { "name": "Insert nuts", "code": "31161714" },
      { "name": "Locknuts", "code": "31161716" },
      { "name": "Wing nuts", "code": "31161717" },
      { "name": "Toggle nuts", "code": "31161718" },
      { "name": "Swivel nuts", "code": "31161719" },
      { "name": "Stop nuts", "code": "31161720" },
      { "name": "Spring nuts", "code": "31161721" },
      { "name": "Union nuts", "code": "31161722" },
      { "name": "Nutplate nuts", "code": "31161723" },
      { "name": "Press nuts", "code": "31161724" },
      { "name": "Clip nuts", "code": "31161725" },
      { "name": "Dome nuts", "code": "31161726" },
      { "name": "Hexagonal nuts", "code": "31161727" },
      { "name": "Coupling nuts", "code": "31161728" },
      { "name": "Knurled nuts", "code": "31161729" },
      { "name": "Square nuts", "code": "31161730" },
      { "name": "Weld nuts", "code": "31161731" },
      { "name": "Half moon nut", "code": "31161732" },
      { "name": "T nut or T slot nut", "code": "31161733" },
      { "name": "Slotted round nut", "code": "31161734" },
      { "name": "Cage nut", "code": "31161735" },
      { "name": "Decorative nut cap", "code": "31161736" },
      { "name": "Shear nut", "code": "31161737" },
      { "name": "Keps nut or K nut", "code": "31161738" },
      { "name": "Weld or clinch nut", "code": "31161739" },
      { "name": "Bolt nut", "code": "31161740" },
      { "name": "Internal wrenching nut", "code": "31161741" },
      { "name": "Washers", "code": "31161800" },
      { "name": "Locking washers", "code": "31161801" },
      { "name": "Bevel washers", "code": "31161802" },
      { "name": "Binding washers", "code": "31161803" },
      { "name": "Curved washers", "code": "31161804" },
      { "name": "Electrical insulating washers", "code": "31161805" },
      { "name": "Finishing washers", "code": "31161806" },
      { "name": "Flat washers", "code": "31161807" },
      { "name": "Open washers", "code": "31161808" },
      { "name": "Reducing washers", "code": "31161809" },
      { "name": "Shim washers", "code": "31161810" },
      { "name": "Spring washers", "code": "31161811" },
      { "name": "Square washers", "code": "31161812" },
      { "name": "Swivel washers", "code": "31161813" },
      { "name": "Thrust washers", "code": "31161814" },
      { "name": "Shoulder washers", "code": "31161815" },
      { "name": "Spacers or standoffs", "code": "31161816" },
      { "name": "Conical washers", "code": "31161817" },
      { "name": "Seal washers", "code": "31161818" },
      { "name": "Washer kits", "code": "31161819" },
      { "name": "Spherical or domed washers", "code": "31161820" },
      { "name": "Tapered washer", "code": "31161821" },
      { "name": "Tooth lock washer", "code": "31161822" },
      { "name": "Fender washer", "code": "31161823" },
      { "name": "Roof bolt washer", "code": "31161824" },
      { "name": "Belleville washer", "code": "31161825" },
      { "name": "Notched washer", "code": "31161826" },
      { "name": "Clipped washer", "code": "31161827" },
      { "name": "Slotted washer", "code": "31161828" },
      { "name": "Retaining washer", "code": "31161829" },
      { "name": "Wave washer", "code": "31161830" },
      { "name": "Finger spring washer", "code": "31161831" },
      { "name": "Countersunk washer", "code": "31161832" },
      { "name": "Bonded washer", "code": "31161833" },
      { "name": "Cup washer", "code": "31161834" },
      { "name": "Tab washer", "code": "31161835" },
      { "name": "Anchor plate washer", "code": "31161836" },
      { "name": "Split washer", "code": "31161837" },
      { "name": "Tongued washer", "code": "31161838" },
      { "name": "Springs", "code": "31161900" },
      { "name": "Helical springs", "code": "31161901" },
      { "name": "Leaf springs", "code": "31161902" },
      { "name": "Spiral springs", "code": "31161903" },
      { "name": "Compression springs", "code": "31161904" },
      { "name": "Die springs", "code": "31161905" },
      { "name": "Disk springs", "code": "31161906" },
      { "name": "Extension springs", "code": "31161907" },
      { "name": "Torsion springs", "code": "31161908" },
      { "name": "Waveform spring", "code": "31161909" },
      { "name": "Wireform spring", "code": "31161910" },
      { "name": "Spring assembly", "code": "31161911" },
      { "name": "Injector valve spring", "code": "31161912" },
      { "name": "Nails", "code": "31162000" },
      { "name": "Brads", "code": "31162001" },
      { "name": "Cap nails", "code": "31162002" },
      { "name": "Finishing nails", "code": "31162003" },
      { "name": "Masonry nails", "code": "31162004" },
      { "name": "Roofing nails", "code": "31162005" },
      { "name": "Wire nails", "code": "31162006" },
      { "name": "Upholstery nails", "code": "31162007" },
      { "name": "Drive pins", "code": "31162008" },
      { "name": "Fiber cement nail", "code": "31162009" },
      { "name": "Horseshoe nail", "code": "31162010" },
      { "name": "Decorative nail", "code": "31162011" },
      { "name": "Survey nail", "code": "31162012" },
      { "name": "Boat nail", "code": "31162013" },
      { "name": "Railroad spike or nail", "code": "31162014" },
      { "name": "Anchors", "code": "31162100" },
      { "name": "Concrete anchors", "code": "31162101" },
      { "name": "Wedge anchors", "code": "31162102" },
      { "name": "Wall anchors", "code": "31162103" },
      { "name": "Screw anchors", "code": "31162104" },
      { "name": "Resin anchors", "code": "31162105" },
      { "name": "Pipe anchors", "code": "31162106" },
      { "name": "Nail expansion anchors", "code": "31162107" },
      { "name": "Tie down anchors", "code": "31162108" },
      { "name": "Ground anchor", "code": "31162109" },
      { "name": "Self-drilling plastic anchor", "code": "31162110" },
      { "name": "Rivets", "code": "31162200" },
      { "name": "Blind rivets", "code": "31162201" },
      { "name": "Crownhead rivets", "code": "31162202" },
      { "name": "Flat head rivets", "code": "31162203" },
      { "name": "Full rivets", "code": "31162204" },
      { "name": "Ratchet rivets", "code": "31162205" },
      { "name": "Tinners rivets", "code": "31162206" },
      { "name": "Compression rivets", "code": "31162207" },
      { "name": "Coopers rivets", "code": "31162208" },
      { "name": "Button head rivets", "code": "31162209" },
      { "name": "Hinged or self setting rivets", "code": "31162210" },
      { "name": "Solid rivet", "code": "31162211" },
      { "name": "Drive rivet", "code": "31162212" },
      { "name": "Flush rivet", "code": "31162213" },
      { "name": "Semi tubular rivet", "code": "31162214" },
      { "name": "Tubular rivet", "code": "31162215" },
      { "name": "Shoulder rivet", "code": "31162216" },
      { "name": "Mounting hardware", "code": "31162300" },
      { "name": "Mounting profiles", "code": "31162301" },
      { "name": "Mounting bars", "code": "31162303" },
      { "name": "Mounting strips", "code": "31162304" },
      { "name": "Mounting clips", "code": "31162305" },
      { "name": "Mounting hangers", "code": "31162306" },
      { "name": "Mounting plates", "code": "31162307" },
      { "name": "Mounting panels", "code": "31162308" },
      { "name": "Mounting racks", "code": "31162309" },
      { "name": "Mounting straps", "code": "31162310" },
      { "name": "Wall bushings", "code": "31162311" },
      { "name": "Mounting pins", "code": "31162312" },
      { "name": "Mounting kits", "code": "31162313" },
      { "name": "Tile spacer", "code": "31162314" },
      { "name": "Miscellaneous fasteners", "code": "31162400" },
      { "name": "Grommets", "code": "31162401" },
      { "name": "Hasps", "code": "31162402" },
      { "name": "Hinges", "code": "31162403" },
      { "name": "Hardware staples", "code": "31162404" },
      { "name": "Turnbuckles", "code": "31162405" },
      { "name": "Strapping or banding buckles", "code": "31162406" },
      { "name": "Latch", "code": "31162407" },
      { "name": "Clevis pin", "code": "31162409" },
      { "name": "Knurled pins", "code": "31162410" },
      { "name": "Snap rings", "code": "31162411" },
      { "name": "Clevis", "code": "31162412" },
      { "name": "Snap fastener", "code": "31162413" },
      { "name": "Cleat", "code": "31162414" },
      { "name": "Pawls", "code": "31162415" },
      { "name": "Connecting or coupling pins", "code": "31162416" },
      { "name": "Aligning pins", "code": "31162417" },
      { "name": "Twist ties", "code": "31162418" },
      { "name": "Rings and links", "code": "31162419" },
      { "name": "Engineered hinge", "code": "31162420" },
      { "name": "Catch", "code": "31162421" },
      { "name": "Brackets and braces", "code": "31162500" },
      { "name": "Shelf brackets", "code": "31162501" },
      { "name": "Angle brackets", "code": "31162502" },
      { "name": "Braces", "code": "31162503" },
      { "name": "Electrical fixture brackets", "code": "31162504" },
      { "name": "Magnetic mounting brackets", "code": "31162505" },
      { "name": "Wall mount bracket", "code": "31162506" },
      { "name": "Pinion brackets", "code": "31162507" },
      { "name": "Shaft eye bracket", "code": "31162508" },
      { "name": "Hooks", "code": "31162600" },
      { "name": "Swivel hooks", "code": "31162601" },
      { "name": "Spring hooks", "code": "31162602" },
      { "name": "S hooks", "code": "31162603" },
      { "name": "Safety hooks", "code": "31162604" },
      { "name": "Lifting hooks", "code": "31162605" },
      { "name": "J hooks", "code": "31162606" },
      { "name": "Guy wire hooks", "code": "31162607" },
      { "name": "Grab hooks", "code": "31162608" },
      { "name": "Screw hooks", "code": "31162609" },
      { "name": "Peg board hooks", "code": "31162610" },
      { "name": "Slip hooks", "code": "31162611" },
      { "name": "Square hook", "code": "31162612" },
      { "name": "Figure eight hook", "code": "31162613" },
      { "name": "Fixed hook", "code": "31162614" },
      { "name": "Rolling hardware", "code": "31162700" },
      { "name": "Casters", "code": "31162701" },
      { "name": "Wheels", "code": "31162702" },
      { "name": "Sliders", "code": "31162703" },
      { "name": "Roller spikes", "code": "31162704" },
      { "name": "Miscellaneous hardware", "code": "31162800" },
      { "name": "Handles or knobs", "code": "31162801" },
      { "name": "Inserts", "code": "31162802" },
      { "name": "Door stops", "code": "31162804" },
      { "name": "Cable thimble", "code": "31162805" },
      { "name": "Screw covers", "code": "31162806" },
      { "name": "Levers", "code": "31162807" },
      { "name": "Panic bars", "code": "31162808" },
      { "name": "Locating pins", "code": "31162809" },
      { "name": "Splices or splice plates", "code": "31162810" },
      { "name": "Shaft collar", "code": "31162811" },
      { "name": "Swaging sleeves", "code": "31162812" },
      { "name": "Wire rope clip", "code": "31162813" },
      { "name": "Fastener assortment kit", "code": "31162814" },
      { "name": "Shim", "code": "31162815" },
      { "name": "Spinneret", "code": "31162816" },
      { "name": "Wire rope socket", "code": "31162817" },
      { "name": "Anti corrosion anode", "code": "31162818" },
      { "name": "Control lever", "code": "31162819" },
      { "name": "Clamps", "code": "31162900" },
      { "name": "Ear clamps", "code": "31162901" },
      { "name": "Spring clamps", "code": "31162902" },
      { "name": "Screw clamps", "code": "31162903" },
      { "name": "Wire rope clamps", "code": "31162904" },
      { "name": "Beam clamps", "code": "31162905" },
      { "name": "Hose or pipe clamps", "code": "31162906" },
      { "name": "Extending clamp", "code": "31162907" },
      { "name": "Miniature clamp", "code": "31162908" },
      { "name": "Isophonic clamp", "code": "31162909" },
      { "name": "Drill clamp", "code": "31162910" },
      { "name": "Hand screw clamp", "code": "31162911" },
      { "name": "Sash clamp", "code": "31162912" },
      { "name": "Bench clamp", "code": "31162913" },
      { "name": "Web clamp", "code": "31162914" },
      { "name": "Miter clamp", "code": "31162915" },
      { "name": "Quick action clamp", "code": "31162916" },
      { "name": "Quick grip clamp", "code": "31162917" },
      { "name": "Power clamp", "code": "31162918" },
      { "name": "Speed clamp", "code": "31162919" },
      { "name": "Picture frame clamp", "code": "31162920" },
      { "name": "Couplings", "code": "31163000" },
      { "name": "Elastomeric couplings", "code": "31163001" },
      { "name": "Gear couplings", "code": "31163002" },
      { "name": "Metallic couplings", "code": "31163003" },
      { "name": "Miniature couplings", "code": "31163004" },
      { "name": "Coupling sleeves", "code": "31163005" },
      { "name": "Grid coupling", "code": "31163006" },
      { "name": "Chain coupling", "code": "31163007" },
      { "name": "Disc coupling", "code": "31163008" },
      { "name": "Rigid coupling", "code": "31163009" },
      { "name": "Spider coupling", "code": "31163010" },
      { "name": "Jaw coupling", "code": "31163011" },
      { "name": "Coupling half", "code": "31163012" },
      { "name": "Shaft coupling", "code": "31163013" },
      { "name": "Rotary coupling", "code": "31163014" },
      { "name": "Oldham coupling", "code": "31163015" },
      { "name": "Rod coupling", "code": "31163016" },
      { "name": "Coupling rod", "code": "31163017" },
      { "name": "Bellows coupling", "code": "31163018" },
      { "name": "Magnetic coupling", "code": "31163019" },
      { "name": "Flange coupling", "code": "31163020" },
      { "name": "Universal flexible coupling", "code": "31163021" },
      { "name": "Clamp coupling", "code": "31163022" },
      { "name": "Muff coupling", "code": "31163023" },
      { "name": "Flange type flexible coupling", "code": "31163024" },
      { "name": "Split muff coupling", "code": "31163025" },
      { "name": "Half lap coupling", "code": "31163026" },
      { "name": "Roller chain coupling", "code": "31163027" },
      { "name": "Grid type flexible coupling", "code": "31163028" },
      { "name": "Friction clip coupling", "code": "31163029" },
      { "name": "Dura flex coupling", "code": "31163030" },
      { "name": "Sellers coupling", "code": "31163031" },
      { "name": "Electrical coupling adapter", "code": "31163032" },
      { "name": "Connectors", "code": "31163100" },
      { "name": "Quick disconnects", "code": "31163101" },
      { "name": "Ferrule", "code": "31163102" },
      { "name": "Trailer Connector", "code": "31163103" },
      { "name": "Retaining hardware", "code": "31163200" },
      { "name": "Spring pins", "code": "31163201" },
      { "name": "Retaining rings", "code": "31163202" },
      { "name": "Dowel pin", "code": "31163203" },
      { "name": "Cotter pin", "code": "31163204" },
      { "name": "Taper pins", "code": "31163205" },
      { "name": "Shaft or woodruff keys", "code": "31163207" },
      { "name": "Keystock", "code": "31163208" },
      { "name": "Bearing holders or retainers", "code": "31163209" },
      { "name": "Retaining collars", "code": "31163210" },
      { "name": "Retaining clips", "code": "31163211" },
      { "name": "Threaded pins", "code": "31163212" },
      { "name": "Pivot pins", "code": "31163213" },
      { "name": "Shear pins", "code": "31163214" },
      { "name": "Grooved pins", "code": "31163215" },
      { "name": "Cable hanger", "code": "31163216" },
      {
        "name": "Quick disconnect shaft hub locking bushing",
        "code": "31163217"
      },
      { "name": "Bearing adapter sleeve", "code": "31163218" },
      { "name": "Cylindrical pin", "code": "31163219" },
      { "name": "Pin assortment set", "code": "31163220" },
      { "name": "Panel pin", "code": "31163221" },
      { "name": "Square key", "code": "31163222" },
      { "name": "Rectangular key", "code": "31163223" },
      { "name": "Taper key", "code": "31163224" },
      { "name": "Gib head key", "code": "31163225" },
      { "name": "Circlip", "code": "31163226" },
      { "name": "Flinger", "code": "31163227" },
      { "name": "Split ring", "code": "31163228" },
      { "name": "Metal retaining clip", "code": "31163229" },
      { "name": "Plastic retaining clip", "code": "31163230" },
      { "name": "Pipe retaining collar", "code": "31163231" },
      { "name": "Studs", "code": "31163300" },
      { "name": "Double ended stud", "code": "31163301" },
      { "name": "Weld or clinch stud", "code": "31163302" },
      { "name": "Ball stud", "code": "31163303" },
      { "name": "Complex stud", "code": "31163304" },
      { "name": "Metal nets and screening structures", "code": "31163400" },
      { "name": "Gabion", "code": "31163401" },
      {
        "name": "Bearings and bushings and wheels and gears",
        "code": "31170000"
      },
      { "name": "Bearings", "code": "31171500" },
      { "name": "Flanged bearings", "code": "31171501" },
      { "name": "Radial bearings", "code": "31171502" },
      { "name": "Wheel bearings", "code": "31171503" },
      { "name": "Ball bearings", "code": "31171504" },
      { "name": "Roller bearings", "code": "31171505" },
      { "name": "Linear bearings", "code": "31171506" },
      { "name": "Thrust bearings", "code": "31171507" },
      { "name": "Rod end bearings", "code": "31171508" },
      { "name": "Sleeve bearings", "code": "31171509" },
      { "name": "Spherical bearings", "code": "31171510" },
      { "name": "Pillow block bearings", "code": "31171511" },
      { "name": "Needle bearings", "code": "31171512" },
      { "name": "Hanger bearings", "code": "31171513" },
      { "name": "Plain bearings", "code": "31171515" },
      { "name": "Tapered bearings", "code": "31171516" },
      { "name": "Bearing cage", "code": "31171518" },
      { "name": "Bearing blocks or housings", "code": "31171519" },
      { "name": "Bearing journals", "code": "31171520" },
      { "name": "Magnetic bearings", "code": "31171522" },
      { "name": "Air bearings", "code": "31171523" },
      { "name": "Bearing caps", "code": "31171524" },
      { "name": "Bearing liners", "code": "31171525" },
      { "name": "Bearing pads", "code": "31171526" },
      { "name": "Bearing cones", "code": "31171527" },
      { "name": "Split bearing", "code": "31171528" },
      { "name": "Bearing cups", "code": "31171529" },
      { "name": "Cam follower bearing", "code": "31171530" },
      { "name": "Angular contact bearing", "code": "31171531" },
      { "name": "Self aligning bearing", "code": "31171532" },
      { "name": "Take up bearing and frame", "code": "31171533" },
      { "name": "Tapped base bearing", "code": "31171534" },
      { "name": "Cartridge bearing", "code": "31171535" },
      { "name": "Insert bearing", "code": "31171536" },
      { "name": "Combination bearing", "code": "31171537" },
      { "name": "Four point bearing", "code": "31171538" },
      { "name": "Bearing ball", "code": "31171539" },
      { "name": "Bearing needle", "code": "31171540" },
      { "name": "Bearing roller", "code": "31171541" },
      { "name": "Tensioner bearing", "code": "31171542" },
      { "name": "Flexure bearing", "code": "31171543" },
      { "name": "Plummer block bearing", "code": "31171544" },
      { "name": "Bearing cover", "code": "31171545" },
      { "name": "Reversible bearing", "code": "31171546" },
      { "name": "Cylindrical bearing", "code": "31171547" },
      { "name": "Bearing withdrawal sleeve", "code": "31171548" },
      { "name": "Jewel bearing", "code": "31171549" },
      { "name": "Combined ball roller bearing", "code": "31171550" },
      { "name": "Oilless bearing", "code": "31171551" },
      { "name": "Thrust ball bearing", "code": "31171552" },
      { "name": "Thrust roller bearing", "code": "31171553" },
      { "name": "Guide bearing", "code": "31171554" },
      { "name": "Roller bearing adapter assembly", "code": "31171555" },
      { "name": "High performance insert bearing", "code": "31171556" },
      { "name": "Journal bearing bushing", "code": "31171557" },
      { "name": "Linear and rotary motion roller", "code": "31171558" },
      { "name": "Lock plate for roller bearing", "code": "31171559" },
      { "name": "Locking snap ring for roller bearing", "code": "31171560" },
      { "name": "Adapter sleeve for roller bearing", "code": "31171561" },
      { "name": "Insert bearing with set screw locking", "code": "31171562" },
      {
        "name": "Insert bearing with eccentric locking collar",
        "code": "31171563"
      },
      { "name": "Insert bearing with standard inner ring", "code": "31171564" },
      { "name": "Bushings", "code": "31171600" },
      { "name": "Drill bushings", "code": "31171603" },
      { "name": "Pilot bushings", "code": "31171604" },
      { "name": "Shaft bushings", "code": "31171605" },
      { "name": "Flange bushings", "code": "31171606" },
      { "name": "Wear bushing", "code": "31171607" },
      { "name": "Bushing blank", "code": "31171608" },
      { "name": "Bushing sleeve", "code": "31171609" },
      { "name": "Cold headed bushing", "code": "31171610" },
      { "name": "Gears", "code": "31171700" },
      { "name": "Friction gears", "code": "31171704" },
      { "name": "Conical gears", "code": "31171706" },
      { "name": "Spur gears", "code": "31171707" },
      { "name": "Bevel gears", "code": "31171708" },
      { "name": "Rack gears", "code": "31171709" },
      { "name": "Pinion gears", "code": "31171710" },
      { "name": "Ring gears", "code": "31171711" },
      { "name": "Worm gears", "code": "31171712" },
      { "name": "Side Gears", "code": "31171713" },
      { "name": "Helical gears", "code": "31171714" },
      { "name": "Cylindrical gear", "code": "31171715" },
      { "name": "Herringbone gear", "code": "31171716" },
      { "name": "Industrial wheels", "code": "31171800" },
      { "name": "Cog wheels", "code": "31171801" },
      { "name": "Impeller wheels", "code": "31171802" },
      { "name": "Flywheels", "code": "31171803" },
      { "name": "Sheaves or pulleys", "code": "31171804" },
      { "name": "Wheel brushes", "code": "31171805" },
      { "name": "Idler wheels", "code": "31171806" },
      { "name": "Sprockets", "code": "31171900" },
      { "name": "Roller chain sprockets", "code": "31171901" },
      { "name": "Engineering chain sprocket", "code": "31171902" },
      { "name": "Conveying chain sprocket", "code": "31171903" },
      { "name": "Silent chain sprocket", "code": "31171904" },
      { "name": "Chain wheel", "code": "31171905" },
      { "name": "Packings glands boots and covers", "code": "31180000" },
      { "name": "Packings and glands", "code": "31181700" },
      { "name": "Packings", "code": "31181701" },
      { "name": "Glands", "code": "31181702" },
      { "name": "Oil slingers", "code": "31181703" },
      { "name": "Stuffing box", "code": "31181704" },
      { "name": "Seal backup ring", "code": "31181705" },
      { "name": "Automotive boots and covers", "code": "31181800" },
      {
        "name": "Automotive transmission shift lever boot",
        "code": "31181801"
      },
      { "name": "Ignition boot", "code": "31181802" },
      {
        "name": "Grinding and polishing and smoothing materials",
        "code": "31190000"
      },
      { "name": "Abrasives and abrasive media", "code": "31191500" },
      { "name": "Abrasive papers", "code": "31191501" },
      { "name": "Buffs", "code": "31191502" },
      { "name": "Abrasive cloth", "code": "31191504" },
      { "name": "Abrasive pads", "code": "31191505" },
      { "name": "Abrasive discs", "code": "31191506" },
      { "name": "Abrasive belts", "code": "31191507" },
      { "name": "Bort", "code": "31191508" },
      { "name": "Abrasive polishers", "code": "31191509" },
      { "name": "Abrasive stones", "code": "31191510" },
      { "name": "Steel wool", "code": "31191511" },
      { "name": "Shot blast", "code": "31191512" },
      { "name": "Glass bead", "code": "31191513" },
      { "name": "Tumble media", "code": "31191514" },
      { "name": "Abrasive mesh", "code": "31191515" },
      { "name": "Abrasive cartridge rolls", "code": "31191516" },
      { "name": "Emery boards", "code": "31191517" },
      { "name": "Tungsten carbide", "code": "31191518" },
      { "name": "Abrasive drums", "code": "31191519" },
      { "name": "Abrasive star", "code": "31191520" },
      { "name": "Abrasive brush", "code": "31191521" },
      { "name": "Abrasive wheels", "code": "31191600" },
      { "name": "Abrasive cubic borozon nitrate wheels", "code": "31191601" },
      { "name": "Abrasive diamond wheels", "code": "31191602" },
      { "name": "Tungsten carbide abrasive wheels", "code": "31191603" },
      { "name": "Adhesives and sealants", "code": "31200000" },
      { "name": "Tape", "code": "31201500" },
      { "name": "Duct tape", "code": "31201501" },
      { "name": "Electrical insulating tape", "code": "31201502" },
      { "name": "Masking tape", "code": "31201503" },
      { "name": "Carpet tape", "code": "31201504" },
      { "name": "Double sided tape", "code": "31201505" },
      { "name": "Bismalemide tape", "code": "31201506" },
      { "name": "Fiberglass tape", "code": "31201507" },
      { "name": "Graphite tape", "code": "31201508" },
      { "name": "Nylon tape", "code": "31201509" },
      { "name": "Resin impregnated tape", "code": "31201510" },
      { "name": "Wire mesh tape", "code": "31201511" },
      { "name": "Transparent tape", "code": "31201512" },
      { "name": "Non skid safety tapes", "code": "31201513" },
      {
        "name": "Polytetrafluoroethylene PTFE thread sealing tape",
        "code": "31201514"
      },
      { "name": "Paper tapes", "code": "31201515" },
      { "name": "Reflective tape", "code": "31201516" },
      { "name": "Packaging tape", "code": "31201517" },
      { "name": "Electrically conductive tape", "code": "31201518" },
      { "name": "Pipe or hose repair tape", "code": "31201519" },
      { "name": "Aisle marking tape", "code": "31201520" },
      { "name": "Foil tape", "code": "31201521" },
      { "name": "Adhesive transfer tape", "code": "31201522" },
      { "name": "Cloth tape", "code": "31201523" },
      { "name": "Color coding tape", "code": "31201524" },
      { "name": "Vinyl tape", "code": "31201525" },
      { "name": "Magnetic tape", "code": "31201526" },
      { "name": "Foam tapes", "code": "31201527" },
      { "name": "Rigging tape", "code": "31201528" },
      { "name": "Line Tape", "code": "31201529" },
      { "name": "Aluminum foil tape", "code": "31201530" },
      { "name": "Polyethylene tape", "code": "31201531" },
      { "name": "Polyvinyl chloride PVC tape", "code": "31201532" },
      { "name": "Lead foil tape", "code": "31201533" },
      { "name": "Rubber tape", "code": "31201534" },
      { "name": "Cable splicing tape", "code": "31201535" },
      { "name": "Polyethylene fleece tape", "code": "31201536" },
      { "name": "Tear tape", "code": "31201537" },
      { "name": "Photoluminescent tape", "code": "31201538" },
      { "name": "Adhesives", "code": "31201600" },
      { "name": "Chemical adhesives", "code": "31201601" },
      { "name": "Pastes", "code": "31201602" },
      { "name": "Gums", "code": "31201603" },
      { "name": "Rubber cements", "code": "31201604" },
      { "name": "Putties", "code": "31201605" },
      { "name": "Caulks", "code": "31201606" },
      { "name": "Epoxy bond", "code": "31201607" },
      { "name": "Foam adhesives", "code": "31201608" },
      { "name": "Hot melt adhesives", "code": "31201609" },
      { "name": "Glues", "code": "31201610" },
      { "name": "Film adhesives", "code": "31201611" },
      { "name": "Re usable adhesive", "code": "31201613" },
      { "name": "Adhesive activators", "code": "31201615" },
      { "name": "Liquid adhesives", "code": "31201616" },
      { "name": "Solvent cements", "code": "31201617" },
      { "name": "Drywall joint compound", "code": "31201618" },
      { "name": "Instant adhesive", "code": "31201619" },
      { "name": "Reactive adhesive", "code": "31201620" },
      { "name": "Aerosol adhesive", "code": "31201621" },
      { "name": "Multi purpose adhesive", "code": "31201622" },
      { "name": "Contact adhesive", "code": "31201623" },
      { "name": "Mounting adhesive", "code": "31201624" },
      { "name": "Putty primer", "code": "31201625" },
      { "name": "Pressure sensitive adhesive PSA", "code": "31201626" },
      { "name": "Anaerobic adhesive", "code": "31201627" },
      { "name": "Silicone adhesive", "code": "31201632" },
      { "name": "Fluorocarbon adhesive", "code": "31201633" },
      { "name": "Acrylic anerobic adhesive", "code": "31201634" },
      { "name": "Polyurethane adhesive", "code": "31201635" },
      { "name": "Cyanoacrylate adhesive", "code": "31201636" },
      {
        "name": "Foam type water stop material for communication",
        "code": "31201637"
      },
      { "name": "Sealants", "code": "31201700" },
      { "name": "Thread sealant", "code": "31201701" },
      { "name": "Impregnation sealant", "code": "31201702" },
      { "name": "Silicone encapsulation", "code": "31201703" },
      { "name": "Foam sealant", "code": "31201704" },
      { "name": "Sealing compound", "code": "31201705" },
      { "name": "Sealing wax", "code": "31201706" },
      { "name": "Silicone conformal coating", "code": "31201707" },
      { "name": "Acrylic conformal coating", "code": "31201708" },
      { "name": "Polyurethane conformal coating", "code": "31201709" },
      { "name": "Epoxy conformal coating", "code": "31201710" },
      { "name": "Parylene conformal coating", "code": "31201711" },
      { "name": "Silicone sealant", "code": "31201712" },
      { "name": "Polyurethane sealant", "code": "31201713" },
      { "name": "Paints and primers and finishes", "code": "31210000" },
      { "name": "Paints and primers", "code": "31211500" },
      { "name": "Enamel paints", "code": "31211501" },
      { "name": "Water based paints", "code": "31211502" },
      { "name": "Pigment paints", "code": "31211503" },
      { "name": "Coating paints", "code": "31211504" },
      { "name": "Oil based paints", "code": "31211505" },
      { "name": "Latex paints", "code": "31211506" },
      { "name": "Spray paints", "code": "31211507" },
      { "name": "Acrylic paints", "code": "31211508" },
      { "name": "Enamel primers", "code": "31211509" },
      { "name": "Polyurethane primers", "code": "31211510" },
      { "name": "Urethane primers", "code": "31211511" },
      { "name": "Latex primers", "code": "31211512" },
      { "name": "Marking paint", "code": "31211513" },
      { "name": "Cold galvanizing compound", "code": "31211514" },
      { "name": "Solvent based paint", "code": "31211515" },
      { "name": "Baked finish paint", "code": "31211516" },
      { "name": "Pattern finish paint", "code": "31211517" },
      { "name": "Anti rust paint", "code": "31211518" },
      { "name": "Aluminum paint", "code": "31211519" },
      { "name": "Antifouling paint", "code": "31211520" },
      { "name": "Ready mixed paint", "code": "31211521" },
      { "name": "Heavy duty coating", "code": "31211522" },
      { "name": "High temperature paint", "code": "31211523" },
      { "name": "Paint additives", "code": "31211600" },
      { "name": "Calcimines", "code": "31211601" },
      { "name": "Texturing materials", "code": "31211602" },
      { "name": "Paint driers", "code": "31211603" },
      { "name": "Paint extenders", "code": "31211604" },
      { "name": "Anti slip agents", "code": "31211605" },
      { "name": "Leveling agents", "code": "31211606" },
      { "name": "Ultraviolet inhibitor", "code": "31211607" },
      { "name": "Miscellaneous finishes", "code": "31211700" },
      { "name": "Glazes", "code": "31211701" },
      { "name": "Lusters", "code": "31211702" },
      { "name": "Lacquers", "code": "31211703" },
      { "name": "Sealers", "code": "31211704" },
      { "name": "Shellacs", "code": "31211705" },
      { "name": "Stains", "code": "31211706" },
      { "name": "Varnishes", "code": "31211707" },
      { "name": "Powder coat", "code": "31211708" },
      { "name": "Finishing stain toner", "code": "31211709" },
      { "name": "Paint solvents and thinners", "code": "31211800" },
      { "name": "Paint or varnish removers", "code": "31211801" },
      { "name": "Paint or varnish strippers", "code": "31211802" },
      { "name": "Paint or varnish thinners", "code": "31211803" },
      {
        "name": "Paint applicators and painting accessories",
        "code": "31211900"
      },
      { "name": "Drop cloths", "code": "31211901" },
      { "name": "Edging tools", "code": "31211902" },
      { "name": "Masking equipment", "code": "31211903" },
      { "name": "Paint brushes", "code": "31211904" },
      { "name": "Paint mixers", "code": "31211905" },
      { "name": "Paint rollers", "code": "31211906" },
      { "name": "Paint sprayers", "code": "31211908" },
      { "name": "Paint trays", "code": "31211909" },
      { "name": "Paint mitts", "code": "31211910" },
      { "name": "Telescoping poles", "code": "31211912" },
      { "name": "Paint nozzles", "code": "31211913" },
      { "name": "Air brushes", "code": "31211914" },
      { "name": "Paint strainers", "code": "31211915" },
      { "name": "Paint tray liners", "code": "31211916" },
      { "name": "Paint roller covers", "code": "31211917" },
      { "name": "Paint fixture or mask", "code": "31211918" },
      { "name": "Dyeing and tanning extracts", "code": "31220000" },
      { "name": "Tanning products", "code": "31221600" },
      { "name": "Inorganic tanning extracts", "code": "31221601" },
      {
        "name": "Organic tanning extracts of animal origin",
        "code": "31221602"
      },
      {
        "name": "Organic tanning extracts of vegetable origin",
        "code": "31221603"
      },
      { "name": "Machined raw stock", "code": "31230000" },
      { "name": "Machined bar stock", "code": "31231100" },
      { "name": "Aluminum machined bar stock", "code": "31231101" },
      { "name": "Beryllium machined bar stock", "code": "31231102" },
      { "name": "Brass machined bar stock", "code": "31231103" },
      { "name": "Bronze machined bar stock", "code": "31231104" },
      { "name": "Copper machined bar stock", "code": "31231105" },
      { "name": "Iron machined bar stock", "code": "31231106" },
      { "name": "Lead machined bar stock", "code": "31231107" },
      { "name": "Magnesium machined bar stock", "code": "31231108" },
      { "name": "Precious metal machined bar stock", "code": "31231109" },
      { "name": "Stainless steel machined bar stock", "code": "31231110" },
      { "name": "Tin machined bar stock", "code": "31231111" },
      { "name": "Titanium machined bar stock", "code": "31231112" },
      { "name": "Zinc machined bar stock", "code": "31231113" },
      { "name": "Non ferrous alloy machined bar stock", "code": "31231114" },
      { "name": "Ferrous alloy machined bar stock", "code": "31231115" },
      { "name": "Steel machined bar stock", "code": "31231116" },
      { "name": "Composite machined bar stock", "code": "31231117" },
      { "name": "Nickel alloy machined bar stock", "code": "31231118" },
      { "name": "Non metallic machined bar stock", "code": "31231119" },
      { "name": "Silver steel machined bar stock", "code": "31231120" },
      { "name": "Machined plate stock", "code": "31231200" },
      { "name": "Aluminum machined plate stock", "code": "31231201" },
      { "name": "Beryllium machined plate stock", "code": "31231202" },
      { "name": "Brass machined plate stock", "code": "31231203" },
      { "name": "Bronze machined plate stock", "code": "31231204" },
      { "name": "Copper machined plate stock", "code": "31231205" },
      { "name": "Iron machined plate stock", "code": "31231206" },
      { "name": "Lead machined plate stock", "code": "31231207" },
      { "name": "Magnesium machined plate stock", "code": "31231208" },
      { "name": "Precious metal machined plate stock", "code": "31231209" },
      { "name": "Stainless steel machined plate stock", "code": "31231210" },
      { "name": "Tin machined plate stock", "code": "31231211" },
      { "name": "Titanium machined plate stock", "code": "31231212" },
      { "name": "Zinc machined plate stock", "code": "31231213" },
      { "name": "Non ferrous alloy machined plate stock", "code": "31231214" },
      { "name": "Ferrous alloy machined plate stock", "code": "31231215" },
      { "name": "Steel machined plate stock", "code": "31231216" },
      { "name": "Composite machined plate stock", "code": "31231217" },
      { "name": "Nickel alloy machined plate stock", "code": "31231218" },
      { "name": "Non metallic machined plate stock", "code": "31231219" },
      { "name": "Shim stock", "code": "31231400" },
      { "name": "Brass shim stock", "code": "31231401" },
      { "name": "Steel shim stock", "code": "31231402" },
      { "name": "Stainless steel shim stock", "code": "31231403" },
      { "name": "Aluminum shim stock", "code": "31231404" },
      { "name": "Copper shim stock", "code": "31231405" },
      { "name": "Industrial optics", "code": "31240000" },
      { "name": "Lenses and prisms", "code": "31241500" },
      { "name": "Lenses", "code": "31241501" },
      { "name": "Prisms", "code": "31241502" },
      { "name": "Optical blanks", "code": "31241600" },
      { "name": "Filter blanks", "code": "31241601" },
      { "name": "Glass disks", "code": "31241602" },
      { "name": "Molded glass", "code": "31241603" },
      { "name": "Prism blanks", "code": "31241604" },
      { "name": "Silicon blanks", "code": "31241605" },
      { "name": "Germanium blanks", "code": "31241606" },
      { "name": "Round bar stock", "code": "31241607" },
      { "name": "Square bar stock", "code": "31241608" },
      { "name": "Witness sample blanks", "code": "31241609" },
      { "name": "Infrared optical material blanks", "code": "31241610" },
      { "name": "Mirrors", "code": "31241700" },
      { "name": "Diamond turned mirrors", "code": "31241701" },
      { "name": "Metallic mirrors", "code": "31241702" },
      { "name": "Parabolic mirrors", "code": "31241703" },
      { "name": "Uncoated mirrors", "code": "31241704" },
      { "name": "Laser mirrors", "code": "31241705" },
      { "name": "Flat mirror", "code": "31241706" },
      { "name": "Optical filters", "code": "31241800" },
      { "name": "Broad band filters", "code": "31241801" },
      { "name": "Gradient filters", "code": "31241802" },
      { "name": "Infrared filters", "code": "31241803" },
      { "name": "Laser filters", "code": "31241804" },
      { "name": "Narrow band filters", "code": "31241805" },
      { "name": "Pelicle filters", "code": "31241806" },
      { "name": "Visual filters", "code": "31241807" },
      { "name": "Dichroic filter", "code": "31241808" },
      { "name": "Lyot filter", "code": "31241809" },
      { "name": "Gelatin filter", "code": "31241810" },
      { "name": "Color filter", "code": "31241811" },
      { "name": "Polarizing filter", "code": "31241812" },
      { "name": "Reflection filter", "code": "31241813" },
      { "name": "Suction filter", "code": "31241814" },
      { "name": "Interference filter", "code": "31241815" },
      { "name": "Optical domes", "code": "31241900" },
      { "name": "Specialty domes", "code": "31241901" },
      { "name": "Diamond turned domes", "code": "31241902" },
      { "name": "Metallic domes", "code": "31241903" },
      { "name": "Molded glass domes", "code": "31241904" },
      { "name": "Molded polycarbonate domes", "code": "31241905" },
      { "name": "Replicated domes", "code": "31241906" },
      { "name": "Shaped domes", "code": "31241907" },
      { "name": "Frangible domes", "code": "31241908" },
      { "name": "Lens and laser windows", "code": "31242000" },
      { "name": "External lens or laser windows", "code": "31242001" },
      { "name": "Infrared lens or laser windows", "code": "31242002" },
      { "name": "Visual lens or laser windows", "code": "31242003" },
      { "name": "Optical device accessories", "code": "31242100" },
      { "name": "Optical mounts", "code": "31242101" },
      { "name": "Optical slits or apertures", "code": "31242103" },
      { "name": "Optical rails or bases", "code": "31242104" },
      { "name": "Optical fiber identifiers", "code": "31242105" },
      { "name": "Optical coatings", "code": "31242106" },
      { "name": "Optical test board", "code": "31242107" },
      { "name": "Miscellaneous optical components", "code": "31242200" },
      { "name": "Optical beamsplitters", "code": "31242201" },
      { "name": "Polarizers", "code": "31242202" },
      { "name": "Depolarizers", "code": "31242203" },
      { "name": "Optical diffusers", "code": "31242204" },
      { "name": "Optical retarders", "code": "31242205" },
      { "name": "Optical flats", "code": "31242206" },
      { "name": "Optical breadboards", "code": "31242207" },
      { "name": "Optical choppers", "code": "31242208" },
      {
        "name": "Pneumatic and hydraulic and electric control systems",
        "code": "31250000"
      },
      { "name": "Actuators", "code": "31251500" },
      { "name": "Electric actuators", "code": "31251501" },
      { "name": "Electronic actuators", "code": "31251502" },
      { "name": "Hydraulic actuators", "code": "31251503" },
      { "name": "Pneumatic actuators", "code": "31251504" },
      { "name": "Valve actuators", "code": "31251505" },
      { "name": "Gear actuators", "code": "31251506" },
      { "name": "Rotary actuators", "code": "31251507" },
      { "name": "Photoelectric actuators", "code": "31251508" },
      { "name": "Electromagnetic actuators", "code": "31251509" },
      { "name": "Solenoids", "code": "31251510" },
      { "name": "Linear actuators", "code": "31251511" },
      { "name": "Automotive HVAC actuator", "code": "31251512" },
      { "name": "Sluice gate actuator", "code": "31251513" },
      { "name": "Aircraft electromechanical actuator", "code": "31251514" },
      { "name": "Actuator kit", "code": "31251515" },
      { "name": "Switch actuator", "code": "31251516" },
      { "name": "Robot components", "code": "31251600" },
      { "name": "Robotic end effectors", "code": "31251601" },
      { "name": "Housings and cabinets and casings", "code": "31260000" },
      { "name": "Housings and cabinets", "code": "31261500" },
      { "name": "Plastic housings or cabinets", "code": "31261501" },
      { "name": "Metal housings or cabinets", "code": "31261502" },
      { "name": "Steel housings or cabinets", "code": "31261503" },
      { "name": "Gear box housings", "code": "31261504" },
      { "name": "Clutch housing", "code": "31261505" },
      { "name": "Shells and casings", "code": "31261600" },
      { "name": "Plastic shells or casings", "code": "31261601" },
      { "name": "Metal shells or casings", "code": "31261602" },
      { "name": "Steel shells or casings", "code": "31261603" },
      {
        "name": "Acoustical or noise control housings or enclosures",
        "code": "31261700"
      },
      { "name": "Machine noise control housing", "code": "31261701" },
      {
        "name": "Mounted generator set noise control enclosure",
        "code": "31261702"
      },
      { "name": "Pump noise control housing", "code": "31261703" },
      { "name": "Air intake noise control enclosure", "code": "31261704" },
      { "name": "Machine made parts", "code": "31270000" },
      { "name": "Screw machine made parts", "code": "31271600" },
      { "name": "Metal screw machine made parts", "code": "31271601" },
      { "name": "Non metal screw machine made parts", "code": "31271602" },
      { "name": "Stampings and sheet components", "code": "31280000" },
      { "name": "Stamped components", "code": "31281500" },
      { "name": "Aluminum stamped components", "code": "31281502" },
      { "name": "Ferrous alloy stamped components", "code": "31281503" },
      { "name": "Iron stamped components", "code": "31281504" },
      { "name": "Non ferrous alloy stamped components", "code": "31281505" },
      { "name": "Stainless steel stamped components", "code": "31281506" },
      { "name": "Carbon steel stamped components", "code": "31281507" },
      { "name": "Magnesium stamped components", "code": "31281508" },
      { "name": "Zinc stamped components", "code": "31281509" },
      { "name": "Tin stamped components", "code": "31281510" },
      { "name": "Titanium stamped components", "code": "31281511" },
      { "name": "Beryllium stamped components", "code": "31281512" },
      { "name": "Precious metal stamped components", "code": "31281513" },
      { "name": "Copper stamped components", "code": "31281514" },
      { "name": "Lead stamped components", "code": "31281515" },
      { "name": "Brass stamped components", "code": "31281516" },
      { "name": "Bronze stamped components", "code": "31281517" },
      { "name": "Composite stamped components", "code": "31281518" },
      { "name": "Nickel alloy stamped components", "code": "31281519" },
      { "name": "Non metallic stamped components", "code": "31281520" },
      { "name": "Coated stampings", "code": "31281521" },
      { "name": "Beryllium copper stamped component", "code": "31281522" },
      { "name": "Tin plated copper stamped component", "code": "31281523" },
      {
        "name": "Aluminum SAE series 1000 stamped component",
        "code": "31281524"
      },
      {
        "name": "Aluminum SAE series 3000 stamped component",
        "code": "31281525"
      },
      {
        "name": "Aluminum SAE series 5000 stamped component",
        "code": "31281526"
      },
      {
        "name": "Aluminum SAE series 6000 stamped component",
        "code": "31281527"
      },
      { "name": "Inconel stamped component", "code": "31281528" },
      {
        "name": "Carbon steel SAE series 1000 cold rolled tin coated stamped component",
        "code": "31281529"
      },
      {
        "name": "Carbon steel SAE series 1000 hot rolled stamped component",
        "code": "31281530"
      },
      {
        "name": "Steel alloy hot rolled stamped component",
        "code": "31281531"
      },
      {
        "name": "Carbon steel SAE series 1000 cold rolled stamped component",
        "code": "31281532"
      },
      {
        "name": "Stainless steel SAE series 200 cold rolled stamped component",
        "code": "31281533"
      },
      {
        "name": "Stainless steel SAE series 300 cold rolled stamped component",
        "code": "31281534"
      },
      {
        "name": "Stainless steel SAE series 400 cold rolled stamped component",
        "code": "31281535"
      },
      {
        "name": "Steel alloy cold rolled stamped component",
        "code": "31281536"
      },
      {
        "name": "Carbon steel SAE series 1000 cold rolled hot dip galvanized stamped component",
        "code": "31281537"
      },
      {
        "name": "Carbon steel SAE series 1000 electro galvanized cold rolled stamped component",
        "code": "31281538"
      },
      { "name": "Welded components", "code": "31281700" },
      { "name": "Welded metal components", "code": "31281701" },
      { "name": "Punched components", "code": "31281800" },
      { "name": "Aluminum punched components", "code": "31281801" },
      { "name": "Ferrous alloy punched components", "code": "31281802" },
      { "name": "Iron punched components", "code": "31281803" },
      { "name": "Non ferrous alloy punched components", "code": "31281804" },
      { "name": "Stainless steel punched components", "code": "31281805" },
      { "name": "Carbon steel punched components", "code": "31281806" },
      { "name": "Composite punched components", "code": "31281807" },
      { "name": "Nickel alloy punched components", "code": "31281808" },
      { "name": "Non metallic punched components", "code": "31281809" },
      { "name": "Titanium punched components", "code": "31281810" },
      { "name": "Beryllium punched components", "code": "31281811" },
      { "name": "Precious metal punched components", "code": "31281812" },
      { "name": "Copper punched components", "code": "31281813" },
      { "name": "Lead punched components", "code": "31281814" },
      { "name": "Brass punched components", "code": "31281815" },
      { "name": "Bronze punched components", "code": "31281816" },
      { "name": "Magnesium punched components", "code": "31281817" },
      { "name": "Zinc punched components", "code": "31281818" },
      { "name": "Tin punched components", "code": "31281819" },
      { "name": "Draw formed components", "code": "31281900" },
      { "name": "Aluminum draw formed components", "code": "31281901" },
      { "name": "Beryllium draw formed components", "code": "31281902" },
      { "name": "Brass draw formed components", "code": "31281903" },
      { "name": "Bronze draw formed components", "code": "31281904" },
      { "name": "Composite draw formed components", "code": "31281905" },
      { "name": "Copper draw formed components", "code": "31281906" },
      { "name": "Ferrous alloy draw formed components", "code": "31281907" },
      { "name": "Iron draw formed components", "code": "31281908" },
      { "name": "Lead draw formed components", "code": "31281909" },
      { "name": "Magnesium draw formed components", "code": "31281910" },
      { "name": "Nickel alloy draw formed components", "code": "31281911" },
      {
        "name": "Non ferrous alloy draw formed components",
        "code": "31281912"
      },
      { "name": "Non metallic draw formed components", "code": "31281913" },
      { "name": "Precious metal draw formed components", "code": "31281914" },
      { "name": "Stainless steel draw formed components", "code": "31281915" },
      { "name": "Steel draw formed components", "code": "31281916" },
      { "name": "Tin draw formed components", "code": "31281917" },
      { "name": "Titanium draw formed components", "code": "31281918" },
      { "name": "Zinc draw formed components", "code": "31281919" },
      { "name": "Hydro formed components", "code": "31282000" },
      { "name": "Aluminum hydro formed components", "code": "31282001" },
      { "name": "Beryllium hydro formed components", "code": "31282002" },
      { "name": "Brass hydro formed components", "code": "31282003" },
      { "name": "Bronze hydro formed components", "code": "31282004" },
      { "name": "Composite hydro formed components", "code": "31282005" },
      { "name": "Copper hydro formed components", "code": "31282006" },
      { "name": "Ferrous alloy hydro formed components", "code": "31282007" },
      { "name": "Iron hydro formed components", "code": "31282008" },
      { "name": "Lead hydro formed components", "code": "31282009" },
      { "name": "Magnesium hydro formed components", "code": "31282010" },
      { "name": "Nickel alloy hydro formed components", "code": "31282011" },
      {
        "name": "Non ferrous alloy hydro formed components",
        "code": "31282012"
      },
      { "name": "Non metallic hydro formed components", "code": "31282013" },
      { "name": "Precious metal hydro formed components", "code": "31282014" },
      { "name": "Stainless steel hydro formed components", "code": "31282015" },
      { "name": "Steel hydro formed components", "code": "31282016" },
      { "name": "Tin hydro formed components", "code": "31282017" },
      { "name": "Titanium hydro formed components", "code": "31282018" },
      { "name": "Zinc hydro formed components", "code": "31282019" },
      { "name": "Spin formed components", "code": "31282100" },
      { "name": "Aluminum spin formed components", "code": "31282101" },
      { "name": "Beryllium spin formed components", "code": "31282102" },
      { "name": "Brass spin formed components", "code": "31282103" },
      { "name": "Bronze spin formed components", "code": "31282104" },
      { "name": "Composite spin formed components", "code": "31282105" },
      { "name": "Copper spin formed components", "code": "31282106" },
      { "name": "Ferrous alloy spin formed components", "code": "31282107" },
      { "name": "Iron spin formed components", "code": "31282108" },
      { "name": "Lead spin formed components", "code": "31282109" },
      { "name": "Magnesium spin formed components", "code": "31282110" },
      { "name": "Nickel alloy spin formed components", "code": "31282111" },
      {
        "name": "Non ferrous alloy spin formed components",
        "code": "31282112"
      },
      { "name": "Non metallic spin formed components", "code": "31282113" },
      { "name": "Precious metal spin formed components", "code": "31282114" },
      { "name": "Stainless steel spin formed components", "code": "31282115" },
      { "name": "Steel spin formed components", "code": "31282116" },
      { "name": "Tin spin formed components", "code": "31282117" },
      { "name": "Titanium spin formed components", "code": "31282118" },
      { "name": "Zinc spin formed components", "code": "31282119" },
      { "name": "Roll formed components", "code": "31282200" },
      { "name": "Aluminum roll formed components", "code": "31282201" },
      { "name": "Beryllium roll formed components", "code": "31282202" },
      { "name": "Brass roll formed components", "code": "31282203" },
      { "name": "Bronze roll formed components", "code": "31282204" },
      { "name": "Composite roll formed components", "code": "31282205" },
      { "name": "Copper roll formed components", "code": "31282206" },
      { "name": "Ferrous alloy roll formed components", "code": "31282207" },
      { "name": "Iron roll formed components", "code": "31282208" },
      { "name": "Lead roll formed components", "code": "31282209" },
      { "name": "Magnesium roll formed components", "code": "31282210" },
      { "name": "Nickel alloy roll formed components", "code": "31282211" },
      {
        "name": "Non ferrous alloy roll formed components",
        "code": "31282212"
      },
      { "name": "Non metallic roll formed components", "code": "31282213" },
      { "name": "Precious metal roll formed components", "code": "31282214" },
      { "name": "Stainless steel roll formed components", "code": "31282215" },
      { "name": "Steel roll formed components", "code": "31282216" },
      { "name": "Tin roll formed components", "code": "31282217" },
      { "name": "Titanium roll formed components", "code": "31282218" },
      { "name": "Zinc roll formed components", "code": "31282219" },
      { "name": "Stretch formed components", "code": "31282300" },
      { "name": "Aluminum stretch formed components", "code": "31282301" },
      { "name": "Beryllium stretch formed components", "code": "31282302" },
      { "name": "Brass stretch formed components", "code": "31282303" },
      { "name": "Bronze stretch formed components", "code": "31282304" },
      { "name": "Composite stretch formed components", "code": "31282305" },
      { "name": "Copper stretch formed components", "code": "31282306" },
      { "name": "Ferrous alloy stretch formed components", "code": "31282307" },
      { "name": "Iron stretch formed components", "code": "31282308" },
      { "name": "Lead stretch formed components", "code": "31282309" },
      { "name": "Magnesium stretch formed components", "code": "31282310" },
      { "name": "Nickel alloy stretch formed components", "code": "31282311" },
      {
        "name": "Non ferrous alloy stretch formed components",
        "code": "31282312"
      },
      { "name": "Non metallic stretch formed components", "code": "31282313" },
      {
        "name": "Precious metal stretch formed components",
        "code": "31282314"
      },
      {
        "name": "Stainless steel stretch formed components",
        "code": "31282315"
      },
      { "name": "Steel stretch formed components", "code": "31282316" },
      { "name": "Tin stretch formed components", "code": "31282317" },
      { "name": "Titanium stretch formed components", "code": "31282318" },
      { "name": "Zinc stretch formed components", "code": "31282319" },
      { "name": "Explosive formed components", "code": "31282400" },
      { "name": "Aluminum explosive formed components", "code": "31282401" },
      { "name": "Beryllium explosive formed components", "code": "31282402" },
      { "name": "Brass explosive formed components", "code": "31282403" },
      { "name": "Bronze explosive formed components", "code": "31282404" },
      { "name": "Composite explosive formed components", "code": "31282405" },
      { "name": "Copper explosive formed components", "code": "31282406" },
      {
        "name": "Ferrous alloy explosive formed components",
        "code": "31282407"
      },
      { "name": "Iron explosive formed components", "code": "31282408" },
      { "name": "Lead explosive formed components", "code": "31282409" },
      { "name": "Magnesium explosive formed components", "code": "31282410" },
      {
        "name": "Nickel alloy explosive formed components",
        "code": "31282411"
      },
      {
        "name": "Non ferrous alloy explosive formed components",
        "code": "31282412"
      },
      {
        "name": "Non metallic explosive formed components",
        "code": "31282413"
      },
      {
        "name": "Precious metal explosive formed components",
        "code": "31282414"
      },
      {
        "name": "Stainless steel explosive formed components",
        "code": "31282415"
      },
      { "name": "Steel explosive formed components", "code": "31282416" },
      { "name": "Tin explosive formed components", "code": "31282417" },
      { "name": "Titanium explosive formed components", "code": "31282418" },
      { "name": "Zinc explosive formed components", "code": "31282419" },
      { "name": "Machined extrusions", "code": "31290000" },
      { "name": "Machined hydro static extrusions", "code": "31291100" },
      {
        "name": "Aluminum machined hydro static extrusions",
        "code": "31291101"
      },
      {
        "name": "Beryllium machined hydro static extrusions",
        "code": "31291102"
      },
      { "name": "Brass machined hydro static extrusions", "code": "31291103" },
      { "name": "Bronze machined hydro static extrusions", "code": "31291104" },
      { "name": "Copper machined hydro static extrusions", "code": "31291105" },
      {
        "name": "Ferrous alloy machined hydro static extrusions",
        "code": "31291106"
      },
      { "name": "Lead machined hydro static extrusions", "code": "31291107" },
      {
        "name": "Magnesium machined hydro static extrusions",
        "code": "31291108"
      },
      {
        "name": "Non ferrous alloy machined hydro static extrusions",
        "code": "31291109"
      },
      {
        "name": "Plastic machined hydro static extrusions",
        "code": "31291110"
      },
      {
        "name": "Precious metal machined hydro static extrusions",
        "code": "31291111"
      },
      { "name": "Rubber machined hydro static extrusions", "code": "31291112" },
      {
        "name": "Stainless steel machined hydro static extrusions",
        "code": "31291113"
      },
      { "name": "Steel machined hydro static extrusions", "code": "31291114" },
      { "name": "Tin machined hydro static extrusions", "code": "31291115" },
      {
        "name": "Titanium machined hydro static extrusions",
        "code": "31291116"
      },
      { "name": "Zinc machined hydro static extrusions", "code": "31291117" },
      {
        "name": "Composite machined hydro static extrusions",
        "code": "31291118"
      },
      {
        "name": "Nickel alloy machined hydro static extrusions",
        "code": "31291119"
      },
      {
        "name": "Non metallic machined hydro static extrusions",
        "code": "31291120"
      },
      { "name": "Machined impact extrusions", "code": "31291200" },
      { "name": "Aluminum machined impact extrusions", "code": "31291201" },
      { "name": "Beryllium machined impact extrusions", "code": "31291202" },
      { "name": "Brass machined impact extrusions", "code": "31291203" },
      { "name": "Bronze machined impact extrusions", "code": "31291204" },
      { "name": "Copper machined impact extrusions", "code": "31291205" },
      {
        "name": "Ferrous alloy machined impact extrusions",
        "code": "31291206"
      },
      { "name": "Lead machined impact extrusions", "code": "31291207" },
      { "name": "Magnesium machined impact extrusions", "code": "31291208" },
      {
        "name": "Non ferrous alloy machined impact extrusions",
        "code": "31291209"
      },
      { "name": "Plastic machined impact extrusions", "code": "31291210" },
      {
        "name": "Precious metal machined impact extrusions",
        "code": "31291211"
      },
      { "name": "Rubber machined impact extrusions", "code": "31291212" },
      {
        "name": "Stainless steel machined impact extrusions",
        "code": "31291213"
      },
      { "name": "Steel machined impact extrusions", "code": "31291214" },
      { "name": "Tin machined impact extrusions", "code": "31291215" },
      { "name": "Titanium machined impact extrusions", "code": "31291216" },
      { "name": "Zinc machined impact extrusions", "code": "31291217" },
      { "name": "Composite machined impact extrusions", "code": "31291218" },
      { "name": "Nickel alloy machined impact extrusions", "code": "31291219" },
      { "name": "Non metallic machined impact extrusions", "code": "31291220" },
      { "name": "Machined cold extrusions", "code": "31291300" },
      { "name": "Aluminum machined cold extrusions", "code": "31291301" },
      { "name": "Beryllium machined cold extrusions", "code": "31291302" },
      { "name": "Brass machined cold extrusions", "code": "31291303" },
      { "name": "Bronze machined cold extrusions", "code": "31291304" },
      { "name": "Copper machined cold extrusions", "code": "31291305" },
      { "name": "Ferrous alloy machined cold extrusions", "code": "31291306" },
      { "name": "Lead machined cold extrusions", "code": "31291307" },
      { "name": "Magnesium machined cold extrusions", "code": "31291308" },
      {
        "name": "Non ferrous alloy machined cold extrusions",
        "code": "31291309"
      },
      { "name": "Plastic machined cold extrusions", "code": "31291310" },
      { "name": "Precious metal machined cold extrusions", "code": "31291311" },
      { "name": "Rubber machined cold extrusions", "code": "31291312" },
      {
        "name": "Stainless steel machined cold extrusions",
        "code": "31291313"
      },
      { "name": "Steel machined cold extrusions", "code": "31291314" },
      { "name": "Tin machined cold extrusions", "code": "31291315" },
      { "name": "Titanium machined cold extrusions", "code": "31291316" },
      { "name": "Zinc machined cold extrusions", "code": "31291317" },
      { "name": "Composite machined cold extrusions", "code": "31291318" },
      { "name": "Nickel alloy machined cold extrusions", "code": "31291319" },
      { "name": "Non metallic machined cold extrusions", "code": "31291320" },
      { "name": "Machined hot extrusions", "code": "31291400" },
      { "name": "Aluminum machined hot extrusions", "code": "31291401" },
      { "name": "Beryllium machined hot extrusions", "code": "31291402" },
      { "name": "Brass machined hot extrusions", "code": "31291403" },
      { "name": "Bronze machined hot extrusions", "code": "31291404" },
      { "name": "Copper machined hot extrusions", "code": "31291405" },
      { "name": "Ferrous alloy machined hot extrusions", "code": "31291406" },
      { "name": "Lead machined hot extrusions", "code": "31291407" },
      { "name": "Magnesium machined hot extrusions", "code": "31291408" },
      {
        "name": "Non ferrous alloy machined hot extrusions",
        "code": "31291409"
      },
      { "name": "Plastic machined hot extrusions", "code": "31291410" },
      { "name": "Precious metal machined hot extrusions", "code": "31291411" },
      { "name": "Rubber machined hot extrusions", "code": "31291412" },
      { "name": "Stainless steel machined hot extrusions", "code": "31291413" },
      { "name": "Steel machined hot extrusions", "code": "31291414" },
      { "name": "Tin machined hot extrusions", "code": "31291415" },
      { "name": "Titanium machined hot extrusions", "code": "31291416" },
      { "name": "Zinc machined hot extrusions", "code": "31291417" },
      { "name": "Composite machined hot extrusions", "code": "31291418" },
      { "name": "Nickel alloy machined hot extrusions", "code": "31291419" },
      { "name": "Non metallic machined hot extrusions", "code": "31291420" },
      { "name": "Machined forgings", "code": "31300000" },
      { "name": "Machined open die forgings", "code": "31301100" },
      {
        "name": "Non ferrous alloy open die machined forgings",
        "code": "31301101"
      },
      {
        "name": "Ferrous alloy open die machined forgings",
        "code": "31301102"
      },
      { "name": "Steel open die machined forgings", "code": "31301103" },
      {
        "name": "Stainless steel open die machined forgings",
        "code": "31301104"
      },
      { "name": "Iron open die machined forgings", "code": "31301105" },
      { "name": "Aluminum open die machined forgings", "code": "31301106" },
      { "name": "Magnesium open die machined forgings", "code": "31301107" },
      { "name": "Titanium open die machined forgings", "code": "31301108" },
      { "name": "Beryllium open die machined forgings", "code": "31301109" },
      { "name": "Copper open die machined forgings", "code": "31301110" },
      { "name": "Brass open die machined forgings", "code": "31301111" },
      { "name": "Bronze open die machined forgings", "code": "31301112" },
      { "name": "Zinc open die machined forgings", "code": "31301113" },
      { "name": "Tin open die machined forgings", "code": "31301114" },
      { "name": "Lead open die machined forgings", "code": "31301115" },
      {
        "name": "Precious metal open die machined forgings",
        "code": "31301116"
      },
      { "name": "Composite open die machined forgings", "code": "31301117" },
      { "name": "Nickel alloy open die machined forgings", "code": "31301118" },
      { "name": "Non metallic open die machined forgings", "code": "31301119" },
      { "name": "Machined closed die forgings", "code": "31301200" },
      {
        "name": "Non ferrous alloy closed die machined forgings",
        "code": "31301201"
      },
      {
        "name": "Ferrous alloy closed die machined forgings",
        "code": "31301202"
      },
      { "name": "Steel closed die machined forgings", "code": "31301203" },
      {
        "name": "Stainless steel closed die machined forgings",
        "code": "31301204"
      },
      { "name": "Iron closed die machined forgings", "code": "31301205" },
      { "name": "Aluminum closed die machined forgings", "code": "31301206" },
      { "name": "Magnesium closed die machined forgings", "code": "31301207" },
      { "name": "Titanium closed die machined forgings", "code": "31301208" },
      { "name": "Beryllium closed die machined forgings", "code": "31301209" },
      { "name": "Copper closed die machined forgings", "code": "31301210" },
      { "name": "Brass closed die machined forgings", "code": "31301211" },
      { "name": "Bronze closed die machined forgings", "code": "31301212" },
      { "name": "Zinc closed die machined forgings", "code": "31301213" },
      { "name": "Tin closed die machined forgings", "code": "31301214" },
      { "name": "Lead closed die machined forgings", "code": "31301215" },
      {
        "name": "Precious metal closed die machined forgings",
        "code": "31301216"
      },
      { "name": "Composite closed die machined forgings", "code": "31301217" },
      {
        "name": "Nickel alloy closed die machined forgings",
        "code": "31301218"
      },
      {
        "name": "Non metallic closed die machined forgings",
        "code": "31301219"
      },
      { "name": "Machined impression die forgings", "code": "31301300" },
      {
        "name": "Non ferrous alloy impression die machined forgings",
        "code": "31301301"
      },
      {
        "name": "Ferrous alloy impression die machined forgings",
        "code": "31301302"
      },
      { "name": "Steel impression die machined forgings", "code": "31301303" },
      {
        "name": "Stainless steel impression die machined forgings",
        "code": "31301304"
      },
      { "name": "Iron impression die machined forgings", "code": "31301305" },
      {
        "name": "Aluminum impression die machined forgings",
        "code": "31301306"
      },
      {
        "name": "Magnesium impression die machined forgings",
        "code": "31301307"
      },
      {
        "name": "Titanium impression die machined forgings",
        "code": "31301308"
      },
      {
        "name": "Beryllium impression die machined forgings",
        "code": "31301309"
      },
      { "name": "Copper impression die machined forgings", "code": "31301310" },
      { "name": "Brass impression die machined forgings", "code": "31301311" },
      { "name": "Bronze impression die machined forgings", "code": "31301312" },
      { "name": "Zinc impression die machined forgings", "code": "31301313" },
      { "name": "Tin impression die machined forgings", "code": "31301314" },
      { "name": "Lead impression die machined forgings", "code": "31301315" },
      {
        "name": "Precious metal impression die machined forgings",
        "code": "31301316"
      },
      {
        "name": "Composite impression die machined forgings",
        "code": "31301317"
      },
      {
        "name": "Nickel alloy impression die machined forgings",
        "code": "31301318"
      },
      {
        "name": "Non metallic impression die machined forgings",
        "code": "31301319"
      },
      { "name": "Machined drop forgings", "code": "31301400" },
      {
        "name": "Non ferrous alloy drop machined forgings",
        "code": "31301401"
      },
      { "name": "Zinc drop machined forgings", "code": "31301402" },
      { "name": "Ferrous alloy drop machined forgings", "code": "31301403" },
      { "name": "Tin drop machined forgings", "code": "31301404" },
      { "name": "Lead drop machined forgings", "code": "31301405" },
      { "name": "Steel drop machined forgings", "code": "31301406" },
      { "name": "Precious metal drop machined forgings", "code": "31301407" },
      { "name": "Stainless steel drop machined forgings", "code": "31301408" },
      { "name": "Iron drop machined forgings", "code": "31301409" },
      { "name": "Aluminum drop machined forgings", "code": "31301410" },
      { "name": "Magnesium drop machined forgings", "code": "31301411" },
      { "name": "Titanium drop machined forgings", "code": "31301412" },
      { "name": "Beryllium drop machined forgings", "code": "31301413" },
      { "name": "Copper drop machined forgings", "code": "31301414" },
      { "name": "Brass drop machined forgings", "code": "31301415" },
      { "name": "Bronze drop machined forgings", "code": "31301416" },
      { "name": "Composite drop machined forgings", "code": "31301417" },
      { "name": "Nickel alloy drop machined forgings", "code": "31301418" },
      { "name": "Non metallic drop machined forgings", "code": "31301419" },
      { "name": "Machined rolled ring forgings", "code": "31301500" },
      { "name": "Aluminum rolled ring machined forgings", "code": "31301501" },
      { "name": "Beryllium rolled ring machined forgings", "code": "31301502" },
      { "name": "Brass rolled ring machined forgings", "code": "31301503" },
      { "name": "Bronze rolled ring machined forgings", "code": "31301504" },
      { "name": "Copper rolled ring machined forgings", "code": "31301505" },
      { "name": "Iron rolled ring machined forgings", "code": "31301506" },
      { "name": "Lead rolled ring machined forgings", "code": "31301507" },
      { "name": "Magnesium rolled ring machined forgings", "code": "31301508" },
      {
        "name": "Precious metal rolled ring machined forgings",
        "code": "31301509"
      },
      {
        "name": "Stainless steel rolled ring machined forgings",
        "code": "31301510"
      },
      { "name": "Tin rolled ring machined forgings", "code": "31301511" },
      { "name": "Titanium rolled ring machined forgings", "code": "31301512" },
      { "name": "Zinc rolled ring machined forgings", "code": "31301513" },
      {
        "name": "Non ferrous alloy rolled ring machined forgings",
        "code": "31301514"
      },
      {
        "name": "Ferrous alloy rolled ring machined forgings",
        "code": "31301515"
      },
      { "name": "Steel rolled ring machined forgings", "code": "31301516" },
      { "name": "Composite rolled ring machined forgings", "code": "31301517" },
      {
        "name": "Nickel alloy rolled ring machined forgings",
        "code": "31301518"
      },
      {
        "name": "Non metallic rolled ring machined forgings",
        "code": "31301519"
      },
      { "name": "Fabricated pipe assemblies", "code": "31310000" },
      { "name": "Solvent welded pipe assemblies", "code": "31311100" },
      { "name": "Aluminum solvent welded pipe assemblies", "code": "31311101" },
      {
        "name": "Carbon steel solvent welded pipe assemblies",
        "code": "31311102"
      },
      {
        "name": "Hastalloy X solvent welded pipe assemblies",
        "code": "31311103"
      },
      { "name": "Inconel solvent welded pipe assemblies", "code": "31311104" },
      {
        "name": "Low alloy steel solvent welded pipe assemblies",
        "code": "31311105"
      },
      {
        "name": "Non metallic solvent welded pipe assemblies",
        "code": "31311106"
      },
      {
        "name": "Stainless steel solvent welded pipe assemblies",
        "code": "31311109"
      },
      { "name": "Titanium solvent welded pipe assemblies", "code": "31311110" },
      {
        "name": "Waspalloy solvent welded pipe assemblies",
        "code": "31311111"
      },
      { "name": "Copper solvent welded pipe assemblies", "code": "31311112" },
      { "name": "Brass solvent welded pipe assemblies", "code": "31311113" },
      { "name": "Riveted pipe assemblies", "code": "31311200" },
      { "name": "Aluminum riveted pipe assemblies", "code": "31311201" },
      { "name": "Carbon steel riveted pipe assemblies", "code": "31311202" },
      { "name": "Hastalloy X riveted pipe assemblies", "code": "31311203" },
      { "name": "Inconel riveted pipe assemblies", "code": "31311204" },
      { "name": "Low alloy steel riveted pipe assemblies", "code": "31311205" },
      { "name": "Non metallic riveted pipe assemblies", "code": "31311206" },
      { "name": "Stainless steel riveted pipe assemblies", "code": "31311209" },
      { "name": "Titanium riveted pipe assemblies", "code": "31311210" },
      { "name": "Waspalloy riveted pipe assemblies", "code": "31311211" },
      { "name": "Copper riveted pipe assemblies", "code": "31311212" },
      { "name": "Brass riveted pipe assemblies", "code": "31311213" },
      { "name": "Bolted pipe assemblies", "code": "31311300" },
      { "name": "Aluminum bolted pipe assemblies", "code": "31311301" },
      { "name": "Carbon steel bolted pipe assemblies", "code": "31311302" },
      { "name": "Hastalloy X bolted pipe assemblies", "code": "31311303" },
      { "name": "Inconel bolted pipe assemblies", "code": "31311304" },
      { "name": "Low alloy steel bolted pipe assemblies", "code": "31311305" },
      { "name": "Non metallic bolted pipe assemblies", "code": "31311306" },
      { "name": "Stainless steel bolted pipe assemblies", "code": "31311309" },
      { "name": "Titanium bolted pipe assemblies", "code": "31311310" },
      { "name": "Waspalloy bolted pipe assemblies", "code": "31311311" },
      { "name": "Copper bolted pipe assemblies", "code": "31311312" },
      { "name": "Brass bolted pipe assemblies", "code": "31311313" },
      { "name": "Ultra violet welded UV pipe assemblies", "code": "31311400" },
      {
        "name": "Aluminum ultra violet welded pipe assemblies",
        "code": "31311401"
      },
      {
        "name": "Carbon steel ultra violet welded pipe assemblies",
        "code": "31311402"
      },
      {
        "name": "Hastalloy X ultra violet welded pipe assemblies",
        "code": "31311403"
      },
      {
        "name": "Inconel ultra violet welded pipe assemblies",
        "code": "31311404"
      },
      {
        "name": "Low alloy steel ultra violet welded pipe assemblies",
        "code": "31311405"
      },
      {
        "name": "Non metallic ultra violet welded pipe assemblies",
        "code": "31311406"
      },
      {
        "name": "Stainless steel ultra violet welded pipe assemblies",
        "code": "31311409"
      },
      {
        "name": "Titanium ultra violet welded pipe assemblies",
        "code": "31311410"
      },
      {
        "name": "Waspalloy ultra violet welded pipe assemblies",
        "code": "31311411"
      },
      {
        "name": "Copper ultra violet welded pipe assemblies",
        "code": "31311412"
      },
      {
        "name": "Brass ultra violet welded pipe assemblies",
        "code": "31311413"
      },
      { "name": "Welded or brazed pipe assemblies", "code": "31311500" },
      {
        "name": "Aluminum welded or brazed pipe assemblies",
        "code": "31311501"
      },
      {
        "name": "Carbon steel welded or brazed pipe assemblies",
        "code": "31311502"
      },
      {
        "name": "Hastalloy X welded or brazed pipe assemblies",
        "code": "31311503"
      },
      {
        "name": "Inconel welded or brazed pipe assemblies",
        "code": "31311504"
      },
      {
        "name": "Low alloy steel welded or brazed pipe assemblies",
        "code": "31311505"
      },
      {
        "name": "Non metallic welded or brazed pipe assemblies",
        "code": "31311506"
      },
      {
        "name": "Stainless steel welded or brazed pipe assemblies",
        "code": "31311509"
      },
      {
        "name": "Titanium welded or brazed pipe assemblies",
        "code": "31311510"
      },
      {
        "name": "Waspalloy welded or brazed pipe assemblies",
        "code": "31311511"
      },
      { "name": "Copper welded or brazed pipe assemblies", "code": "31311512" },
      { "name": "Brass welded or brazed pipe assemblies", "code": "31311513" },
      { "name": "Sonic welded pipe assemblies", "code": "31311600" },
      { "name": "Aluminum sonic welded pipe assemblies", "code": "31311601" },
      {
        "name": "Carbon steel sonic welded pipe assemblies",
        "code": "31311602"
      },
      {
        "name": "Hastalloy X sonic welded pipe assemblies",
        "code": "31311603"
      },
      { "name": "Inconel sonic welded pipe assemblies", "code": "31311604" },
      {
        "name": "Low alloy steel sonic welded pipe assemblies",
        "code": "31311605"
      },
      {
        "name": "Non metallic sonic welded pipe assemblies",
        "code": "31311606"
      },
      {
        "name": "Stainless steel sonic welded pipe assemblies",
        "code": "31311609"
      },
      { "name": "Titanium sonic welded pipe assemblies", "code": "31311610" },
      { "name": "Waspalloy sonic welded pipe assemblies", "code": "31311611" },
      { "name": "Copper sonic welded pipe assemblies", "code": "31311612" },
      { "name": "Brass sonic welded pipe assemblies", "code": "31311613" },
      { "name": "Bonded pipe assemblies", "code": "31311700" },
      { "name": "Aluminum bonded pipe assemblies", "code": "31311701" },
      { "name": "Carbon steel bonded pipe assemblies", "code": "31311702" },
      { "name": "Hastalloy X bonded pipe assemblies", "code": "31311703" },
      { "name": "Inconel bonded pipe assemblies", "code": "31311704" },
      { "name": "Low alloy steel bonded pipe assemblies", "code": "31311705" },
      { "name": "Non metallic bonded pipe assemblies", "code": "31311706" },
      { "name": "Stainless steel bonded pipe assemblies", "code": "31311709" },
      { "name": "Titanium bonded pipe assemblies", "code": "31311710" },
      { "name": "Waspalloy bonded pipe assemblies", "code": "31311711" },
      { "name": "Copper bonded pipe assemblies", "code": "31311712" },
      { "name": "Brass bonded pipe assemblies", "code": "31311713" },
      { "name": "Fabricated bar stock assemblies", "code": "31320000" },
      { "name": "Bonded bar stock assemblies", "code": "31321100" },
      { "name": "Aluminum bonded bar stock assemblies", "code": "31321101" },
      {
        "name": "Carbon steel bonded bar stock assemblies",
        "code": "31321102"
      },
      { "name": "Hastalloy X bonded bar stock assemblies", "code": "31321103" },
      { "name": "Inconel bonded bar stock assemblies", "code": "31321104" },
      {
        "name": "Low alloy steel bonded bar stock assemblies",
        "code": "31321105"
      },
      {
        "name": "Non metallic bonded bar stock assemblies",
        "code": "31321106"
      },
      {
        "name": "Stainless steel bonded bar stock assemblies",
        "code": "31321109"
      },
      { "name": "Titanium bonded bar stock assemblies", "code": "31321110" },
      { "name": "Waspalloy bonded bar stock assemblies", "code": "31321111" },
      { "name": "Copper bonded bar stock assemblies", "code": "31321112" },
      { "name": "Brass bonded bar stock assemblies", "code": "31321113" },
      { "name": "Solvent welded bar stock assemblies", "code": "31321200" },
      {
        "name": "Aluminum solvent welded bar stock assemblies",
        "code": "31321201"
      },
      {
        "name": "Carbon steel solvent welded bar stock assemblies",
        "code": "31321202"
      },
      {
        "name": "Hastalloy X solvent welded bar stock assemblies",
        "code": "31321203"
      },
      {
        "name": "Inconel solvent welded bar stock assemblies",
        "code": "31321204"
      },
      {
        "name": "Low alloy steel solvent welded bar stock assemblies",
        "code": "31321205"
      },
      {
        "name": "Non metallic solvent welded bar stock assemblies",
        "code": "31321206"
      },
      {
        "name": "Stainless steel solvent welded bar stock assemblies",
        "code": "31321209"
      },
      {
        "name": "Titanium solvent welded bar stock assemblies",
        "code": "31321210"
      },
      {
        "name": "Waspalloy solvent welded bar stock assemblies",
        "code": "31321211"
      },
      {
        "name": "Copper solvent welded bar stock assemblies",
        "code": "31321212"
      },
      {
        "name": "Brass solvent welded bar stock assemblies",
        "code": "31321213"
      },
      { "name": "Riveted bar stock assemblies", "code": "31321300" },
      { "name": "Aluminum riveted bar stock assemblies", "code": "31321301" },
      {
        "name": "Carbon steel riveted bar stock assemblies",
        "code": "31321302"
      },
      {
        "name": "Hastalloy X riveted bar stock assemblies",
        "code": "31321303"
      },
      { "name": "Inconel riveted bar stock assemblies", "code": "31321304" },
      {
        "name": "Low alloy steel riveted bar stock assemblies",
        "code": "31321305"
      },
      {
        "name": "Non metallic riveted bar stock assemblies",
        "code": "31321306"
      },
      {
        "name": "Stainless steel riveted bar stock assemblies",
        "code": "31321309"
      },
      { "name": "Titanium riveted bar stock assemblies", "code": "31321310" },
      { "name": "Waspalloy riveted bar stock assemblies", "code": "31321311" },
      { "name": "Copper riveted bar stock assemblies", "code": "31321312" },
      { "name": "Brass riveted bar stock assemblies", "code": "31321313" },
      { "name": "Welded or brazed bar stock assemblies", "code": "31321400" },
      {
        "name": "Aluminum welded or brazed bar stock assemblies",
        "code": "31321401"
      },
      {
        "name": "Carbon steel welded or brazed bar stock assemblies",
        "code": "31321402"
      },
      {
        "name": "Hastalloy X welded or brazed bar stock assemblies",
        "code": "31321403"
      },
      {
        "name": "Inconel welded or brazed bar stock assemblies",
        "code": "31321404"
      },
      {
        "name": "Low alloy steel welded or brazed bar stock assemblies",
        "code": "31321405"
      },
      {
        "name": "Non metallic welded or brazed bar stock assemblies",
        "code": "31321406"
      },
      {
        "name": "Stainless steel welded or brazed bar stock assemblies",
        "code": "31321409"
      },
      {
        "name": "Titanium welded or brazed bar stock assemblies",
        "code": "31321410"
      },
      {
        "name": "Waspalloy welded or brazed bar stock assemblies",
        "code": "31321411"
      },
      {
        "name": "Copper welded or brazed bar stock assemblies",
        "code": "31321412"
      },
      {
        "name": "Brass welded or brazed bar stock assemblies",
        "code": "31321413"
      },
      {
        "name": "Ultra violet welded UV bar stock assemblies",
        "code": "31321500"
      },
      {
        "name": "Aluminum ultra violet welded bar stock assemblies",
        "code": "31321501"
      },
      {
        "name": "Carbon steel ultra violet welded bar stock assemblies",
        "code": "31321502"
      },
      {
        "name": "Hastalloy X ultra violet welded bar stock assemblies",
        "code": "31321503"
      },
      {
        "name": "Inconel ultra violet welded bar stock assemblies",
        "code": "31321504"
      },
      {
        "name": "Low alloy steel ultra violet welded bar stock assemblies",
        "code": "31321505"
      },
      {
        "name": "Non metallic ultra violet welded bar stock assemblies",
        "code": "31321506"
      },
      {
        "name": "Stainless steel ultra violet welded bar stock assemblies",
        "code": "31321509"
      },
      {
        "name": "Titanium ultra violet welded bar stock assemblies",
        "code": "31321510"
      },
      {
        "name": "Waspalloy ultra violet welded bar stock assemblies",
        "code": "31321511"
      },
      {
        "name": "Copper ultra violet welded bar stock assemblies",
        "code": "31321512"
      },
      {
        "name": "Brass ultra violet welded bar stock assemblies",
        "code": "31321513"
      },
      { "name": "Sonic welded bar stock assemblies", "code": "31321600" },
      {
        "name": "Aluminum sonic welded bar stock assemblies",
        "code": "31321601"
      },
      {
        "name": "Carbon steel sonic welded bar stock assemblies",
        "code": "31321602"
      },
      {
        "name": "Hastalloy X sonic welded bar stock assemblies",
        "code": "31321603"
      },
      {
        "name": "Inconel sonic welded bar stock assemblies",
        "code": "31321604"
      },
      {
        "name": "Low alloy steel sonic welded bar stock assemblies",
        "code": "31321605"
      },
      {
        "name": "Non metallic sonic welded bar stock assemblies",
        "code": "31321606"
      },
      {
        "name": "Stainless steel sonic welded bar stock assemblies",
        "code": "31321609"
      },
      {
        "name": "Titanium sonic welded bar stock assemblies",
        "code": "31321610"
      },
      {
        "name": "Waspalloy sonic welded bar stock assemblies",
        "code": "31321611"
      },
      {
        "name": "Copper sonic welded bar stock assemblies",
        "code": "31321612"
      },
      { "name": "Brass sonic welded bar stock assemblies", "code": "31321613" },
      { "name": "Bolted bar stock assemblies", "code": "31321700" },
      { "name": "Aluminum bolted bar stock assemblies", "code": "31321701" },
      {
        "name": "Carbon steel bolted bar stock assemblies",
        "code": "31321702"
      },
      { "name": "Hastalloy X bolted bar stock assemblies", "code": "31321703" },
      { "name": "Inconel bolted bar stock assemblies", "code": "31321704" },
      {
        "name": "Low alloy steel bolted bar stock assemblies",
        "code": "31321705"
      },
      {
        "name": "Non metallic bolted bar stock assemblies",
        "code": "31321706"
      },
      {
        "name": "Stainless steel bolted bar stock assemblies",
        "code": "31321709"
      },
      { "name": "Titanium bolted bar stock assemblies", "code": "31321710" },
      { "name": "Waspalloy bolted bar stock assemblies", "code": "31321711" },
      { "name": "Copper bolted bar stock assemblies", "code": "31321712" },
      { "name": "Brass bolted bar stock assemblies", "code": "31321713" },
      { "name": "Fabricated structural assemblies", "code": "31330000" },
      { "name": "Bonded structural assemblies", "code": "31331100" },
      { "name": "Aluminum bonded structural assemblies", "code": "31331101" },
      {
        "name": "Carbon steel bonded structural assemblies",
        "code": "31331102"
      },
      {
        "name": "Hastalloy X bonded structural assemblies",
        "code": "31331103"
      },
      { "name": "Inconel bonded structural assemblies", "code": "31331104" },
      {
        "name": "Low alloy steel bonded structural assemblies",
        "code": "31331105"
      },
      {
        "name": "Non metallic bonded structural assemblies",
        "code": "31331106"
      },
      {
        "name": "Stainless steel bonded structural assemblies",
        "code": "31331109"
      },
      { "name": "Titanium bonded structural assemblies", "code": "31331110" },
      { "name": "Waspalloy bonded structural assemblies", "code": "31331111" },
      { "name": "Copper bonded structural assemblies", "code": "31331112" },
      { "name": "Brass bonded structural assemblies", "code": "31331113" },
      { "name": "Bolted structural assemblies", "code": "31331200" },
      { "name": "Aluminum bolted structural assemblies", "code": "31331201" },
      {
        "name": "Carbon steel bolted structural assemblies",
        "code": "31331202"
      },
      {
        "name": "Hastalloy X bolted structural assemblies",
        "code": "31331203"
      },
      { "name": "Inconel bolted structural assemblies", "code": "31331204" },
      {
        "name": "Low alloy steel bolted structural assemblies",
        "code": "31331205"
      },
      {
        "name": "Non metallic bolted structural assemblies",
        "code": "31331206"
      },
      {
        "name": "Stainless steel bolted structural assemblies",
        "code": "31331209"
      },
      { "name": "Titanium bolted structural assemblies", "code": "31331210" },
      { "name": "Waspalloy bolted structural assemblies", "code": "31331211" },
      { "name": "Copper bolted structural assemblies", "code": "31331212" },
      { "name": "Brass bolted structural assemblies", "code": "31331213" },
      { "name": "Sonic welded structural assemblies", "code": "31331300" },
      {
        "name": "Aluminum sonic welded structural assemblies",
        "code": "31331301"
      },
      {
        "name": "Carbon steel sonic welded structural assemblies",
        "code": "31331302"
      },
      {
        "name": "Hastalloy X sonic welded structural assemblies",
        "code": "31331303"
      },
      {
        "name": "Inconel sonic welded structural assemblies",
        "code": "31331304"
      },
      {
        "name": "Low alloy steel sonic welded structural assemblies",
        "code": "31331305"
      },
      {
        "name": "Non metallic sonic welded structural assemblies",
        "code": "31331306"
      },
      {
        "name": "Stainless steel sonic welded structural assemblies",
        "code": "31331309"
      },
      {
        "name": "Titanium sonic welded structural assemblies",
        "code": "31331310"
      },
      {
        "name": "Waspalloy sonic welded structural assemblies",
        "code": "31331311"
      },
      {
        "name": "Copper sonic welded structural assemblies",
        "code": "31331312"
      },
      {
        "name": "Brass sonic welded structural assemblies",
        "code": "31331313"
      },
      {
        "name": "Ultra violet welded UV structural assemblies",
        "code": "31331400"
      },
      {
        "name": "Aluminum ultra violet welded structural assemblies",
        "code": "31331401"
      },
      {
        "name": "Carbon steel ultra violet welded structural assemblies",
        "code": "31331402"
      },
      {
        "name": "Hastalloy X ultra violet welded structural assemblies",
        "code": "31331403"
      },
      {
        "name": "Inconel ultra violet welded structural assemblies",
        "code": "31331404"
      },
      {
        "name": "Low alloy steel ultra violet welded structural assemblies",
        "code": "31331405"
      },
      {
        "name": "Non metallic ultra violet welded structural assemblies",
        "code": "31331406"
      },
      {
        "name": "Stainless steel ultra violet welded structural assemblies",
        "code": "31331409"
      },
      {
        "name": "Titanium ultra violet welded structural assemblies",
        "code": "31331410"
      },
      {
        "name": "Waspalloy ultra violet welded structural assemblies",
        "code": "31331411"
      },
      {
        "name": "Copper ultra violet welded structural assemblies",
        "code": "31331412"
      },
      {
        "name": "Brass ultra violet welded structural assemblies",
        "code": "31331413"
      },
      { "name": "Solvent welded structural assemblies", "code": "31331500" },
      {
        "name": "Aluminum solvent welded structural assemblies",
        "code": "31331501"
      },
      {
        "name": "Carbon steel solvent welded structural assemblies",
        "code": "31331502"
      },
      {
        "name": "Hastalloy X solvent welded structural assemblies",
        "code": "31331503"
      },
      {
        "name": "Inconel solvent welded structural assemblies",
        "code": "31331504"
      },
      {
        "name": "Low alloy steel solvent welded structural assemblies",
        "code": "31331505"
      },
      {
        "name": "Non metallic solvent welded structural assemblies",
        "code": "31331506"
      },
      {
        "name": "Stainless steel solvent welded structural assemblies",
        "code": "31331509"
      },
      {
        "name": "Titanium solvent welded structural assemblies",
        "code": "31331510"
      },
      {
        "name": "Waspalloy solvent welded structural assemblies",
        "code": "31331511"
      },
      {
        "name": "Copper solvent welded structural assemblies",
        "code": "31331512"
      },
      {
        "name": "Brass solvent welded structural assemblies",
        "code": "31331513"
      },
      { "name": "Welded or brazed structural assemblies", "code": "31331600" },
      {
        "name": "Aluminum welded or brazed structural assemblies",
        "code": "31331601"
      },
      {
        "name": "Carbon steel welded or brazed structural assemblies",
        "code": "31331602"
      },
      {
        "name": "Hastalloy X welded or brazed structural assemblies",
        "code": "31331603"
      },
      {
        "name": "Inconel welded or brazed structural assemblies",
        "code": "31331604"
      },
      {
        "name": "Low alloy steel welded or brazed structural assemblies",
        "code": "31331605"
      },
      {
        "name": "Non metallic welded or brazed structural assemblies",
        "code": "31331606"
      },
      {
        "name": "Stainless steel welded or brazed structural assemblies",
        "code": "31331609"
      },
      {
        "name": "Titanium welded or brazed structural assemblies",
        "code": "31331610"
      },
      {
        "name": "Waspalloy welded or brazed structural assemblies",
        "code": "31331611"
      },
      {
        "name": "Copper welded or brazed structural assemblies",
        "code": "31331612"
      },
      {
        "name": "Brass welded or brazed structural assemblies",
        "code": "31331613"
      },
      { "name": "Riveted structural assemblies", "code": "31331700" },
      { "name": "Aluminum riveted structural assemblies", "code": "31331701" },
      {
        "name": "Carbon steel riveted structural assemblies",
        "code": "31331702"
      },
      {
        "name": "Hastalloy X riveted structural assemblies",
        "code": "31331703"
      },
      { "name": "Inconel riveted structural assemblies", "code": "31331704" },
      {
        "name": "Low alloy steel riveted structural assemblies",
        "code": "31331705"
      },
      {
        "name": "Non metallic riveted structural assemblies",
        "code": "31331706"
      },
      {
        "name": "Stainless steel riveted structural assemblies",
        "code": "31331709"
      },
      { "name": "Titanium riveted structural assemblies", "code": "31331710" },
      { "name": "Waspalloy riveted structural assemblies", "code": "31331711" },
      { "name": "Copper riveted structural assemblies", "code": "31331712" },
      { "name": "Brass riveted structural assemblies", "code": "31331713" },
      { "name": "Fabricated sheet assemblies", "code": "31340000" },
      { "name": "Welded or brazed sheet assemblies", "code": "31341100" },
      {
        "name": "Aluminum welded or brazed sheet assemblies",
        "code": "31341101"
      },
      {
        "name": "Carbon steel welded or brazed sheet assemblies",
        "code": "31341102"
      },
      {
        "name": "Hastalloy X welded or brazed sheet assemblies",
        "code": "31341103"
      },
      {
        "name": "Inconel welded or brazed sheet assemblies",
        "code": "31341104"
      },
      {
        "name": "Low alloy steel welded or brazed sheet assemblies",
        "code": "31341105"
      },
      {
        "name": "Non metallic welded or brazed sheet assemblies",
        "code": "31341106"
      },
      {
        "name": "Stainless steel welded or brazed sheet assemblies",
        "code": "31341109"
      },
      {
        "name": "Titanium welded or brazed sheet assemblies",
        "code": "31341110"
      },
      {
        "name": "Waspalloy welded or brazed sheet assemblies",
        "code": "31341111"
      },
      {
        "name": "Copper welded or brazed sheet assemblies",
        "code": "31341112"
      },
      { "name": "Brass welded or brazed sheet assemblies", "code": "31341113" },
      { "name": "Riveted sheet assemblies", "code": "31341200" },
      { "name": "Aluminum riveted sheet assemblies", "code": "31341201" },
      { "name": "Carbon steel riveted sheet assemblies", "code": "31341202" },
      { "name": "Hastalloy X riveted sheet assemblies", "code": "31341203" },
      { "name": "Inconel riveted sheet assemblies", "code": "31341204" },
      {
        "name": "Low alloy steel riveted sheet assemblies",
        "code": "31341205"
      },
      { "name": "Non metallic riveted sheet assemblies", "code": "31341206" },
      {
        "name": "Stainless steel riveted sheet assemblies",
        "code": "31341209"
      },
      { "name": "Titanium riveted sheet assemblies", "code": "31341210" },
      { "name": "Waspalloy riveted sheet assemblies", "code": "31341211" },
      { "name": "Copper riveted sheet assemblies", "code": "31341212" },
      { "name": "Brass riveted sheet assemblies", "code": "31341213" },
      { "name": "Ultra violet welded UV sheet assemblies", "code": "31341300" },
      {
        "name": "Aluminum ultra violet welded sheet assemblies",
        "code": "31341301"
      },
      {
        "name": "Carbon steel ultra violet welded sheet assemblies",
        "code": "31341302"
      },
      {
        "name": "Hastalloy X ultra violet welded sheet assemblies",
        "code": "31341303"
      },
      {
        "name": "Inconel ultra violet welded sheet assemblies",
        "code": "31341304"
      },
      {
        "name": "Low alloy steel ultra violet welded sheet assemblies",
        "code": "31341305"
      },
      {
        "name": "Non metallic ultra violet welded sheet assemblies",
        "code": "31341306"
      },
      {
        "name": "Stainless steel ultra violet welded sheet assemblies",
        "code": "31341309"
      },
      {
        "name": "Titanium ultra violet welded sheet assemblies",
        "code": "31341310"
      },
      {
        "name": "Waspalloy ultra violet welded sheet assemblies",
        "code": "31341311"
      },
      {
        "name": "Copper ultra violet welded sheet assemblies",
        "code": "31341312"
      },
      {
        "name": "Brass ultra violet welded sheet assemblies",
        "code": "31341313"
      },
      { "name": "Sonic welded sheet assemblies", "code": "31341400" },
      { "name": "Aluminum sonic welded sheet assemblies", "code": "31341401" },
      {
        "name": "Carbon steel sonic welded sheet assemblies",
        "code": "31341402"
      },
      {
        "name": "Hastalloy X sonic welded sheet assemblies",
        "code": "31341403"
      },
      { "name": "Inconel sonic welded sheet assemblies", "code": "31341404" },
      {
        "name": "Low alloy steel sonic welded sheet assemblies",
        "code": "31341405"
      },
      {
        "name": "Non metallic sonic welded sheet assemblies",
        "code": "31341406"
      },
      {
        "name": "Stainless steel sonic welded sheet assemblies",
        "code": "31341409"
      },
      { "name": "Titanium sonic welded sheet assemblies", "code": "31341410" },
      { "name": "Waspalloy sonic welded sheet assemblies", "code": "31341411" },
      { "name": "Copper sonic welded sheet assemblies", "code": "31341412" },
      { "name": "Brass sonic welded sheet assemblies", "code": "31341413" },
      { "name": "Solvent welded sheet assemblies", "code": "31341500" },
      {
        "name": "Aluminum solvent welded sheet assemblies",
        "code": "31341501"
      },
      {
        "name": "Carbon steel solvent welded sheet assemblies",
        "code": "31341502"
      },
      {
        "name": "Hastalloy X solvent welded sheet assemblies",
        "code": "31341503"
      },
      { "name": "Inconel solvent welded sheet assemblies", "code": "31341504" },
      {
        "name": "Low alloy steel solvent welded sheet assemblies",
        "code": "31341505"
      },
      {
        "name": "Non metallic solvent welded sheet assemblies",
        "code": "31341506"
      },
      {
        "name": "Stainless steel solvent welded sheet assemblies",
        "code": "31341509"
      },
      {
        "name": "Titanium solvent welded sheet assemblies",
        "code": "31341510"
      },
      {
        "name": "Waspalloy solvent welded sheet assemblies",
        "code": "31341511"
      },
      { "name": "Copper solvent welded sheet assemblies", "code": "31341512" },
      { "name": "Brass solvent welded sheet assemblies", "code": "31341513" },
      { "name": "Bonded sheet assemblies", "code": "31341600" },
      { "name": "Aluminum bonded sheet assemblies", "code": "31341601" },
      { "name": "Carbon steel bonded sheet assemblies", "code": "31341602" },
      { "name": "Hastalloy X bonded sheet assemblies", "code": "31341603" },
      { "name": "Inconel bonded sheet assemblies", "code": "31341604" },
      { "name": "Low alloy steel bonded sheet assemblies", "code": "31341605" },
      { "name": "Non metallic bonded sheet assemblies", "code": "31341606" },
      { "name": "Stainless steel bonded sheet assemblies", "code": "31341609" },
      { "name": "Titanium bonded sheet assemblies", "code": "31341610" },
      { "name": "Waspalloy bonded sheet assemblies", "code": "31341611" },
      { "name": "Copper bonded sheet assemblies", "code": "31341612" },
      { "name": "Brass bonded sheet assemblies", "code": "31341613" },
      { "name": "Bolted sheet assemblies", "code": "31341700" },
      { "name": "Aluminum bolted sheet assemblies", "code": "31341701" },
      { "name": "Carbon steel bolted sheet assemblies", "code": "31341702" },
      { "name": "Hastalloy X bolted sheet assemblies", "code": "31341703" },
      { "name": "Inconel bolted sheet assemblies", "code": "31341704" },
      { "name": "Low alloy steel bolted sheet assemblies", "code": "31341705" },
      { "name": "Non metallic bolted sheet assemblies", "code": "31341706" },
      { "name": "Stainless steel bolted sheet assemblies", "code": "31341709" },
      { "name": "Titanium bolted sheet assemblies", "code": "31341710" },
      { "name": "Waspalloy bolted sheet assemblies", "code": "31341711" },
      { "name": "Copper bolted sheet assemblies", "code": "31341712" },
      { "name": "Brass bolted sheet assemblies", "code": "31341713" },
      { "name": "Fabricated tube assemblies", "code": "31350000" },
      { "name": "Ultra violet welded UV tube assemblies", "code": "31351100" },
      {
        "name": "Aluminum ultra violet welded tube assemblies",
        "code": "31351101"
      },
      {
        "name": "Carbon steel ultra violet welded tube assemblies",
        "code": "31351102"
      },
      {
        "name": "Hastalloy X ultra violet welded tube assemblies",
        "code": "31351103"
      },
      {
        "name": "Inconel ultra violet welded tube assemblies",
        "code": "31351104"
      },
      {
        "name": "Low alloy steel ultra violet welded tube assemblies",
        "code": "31351105"
      },
      {
        "name": "Non metallic ultra violet welded tube assemblies",
        "code": "31351106"
      },
      {
        "name": "Stainless steel ultra violet welded tube assemblies",
        "code": "31351109"
      },
      {
        "name": "Titanium ultra violet welded tube assemblies",
        "code": "31351110"
      },
      {
        "name": "Waspalloy ultra violet welded tube assemblies",
        "code": "31351111"
      },
      {
        "name": "Copper ultra violet welded tube assemblies",
        "code": "31351112"
      },
      {
        "name": "Brass ultra violet welded tube assemblies",
        "code": "31351113"
      },
      { "name": "Welded or brazed tube assemblies", "code": "31351200" },
      {
        "name": "Aluminum welded or brazed tube assemblies",
        "code": "31351201"
      },
      {
        "name": "Carbon steel welded or brazed tube assemblies",
        "code": "31351202"
      },
      {
        "name": "Hastalloy X welded or brazed tube assemblies",
        "code": "31351203"
      },
      {
        "name": "Inconel welded or brazed tube assemblies",
        "code": "31351204"
      },
      {
        "name": "Low alloy steel welded or brazed tube assemblies",
        "code": "31351205"
      },
      {
        "name": "Non metallic welded or brazed tube assemblies",
        "code": "31351206"
      },
      {
        "name": "Stainless steel welded or brazed tube assemblies",
        "code": "31351209"
      },
      {
        "name": "Titanium welded or brazed tube assemblies",
        "code": "31351210"
      },
      {
        "name": "Waspalloy welded or brazed tube assemblies",
        "code": "31351211"
      },
      { "name": "Copper welded or brazed tube assemblies", "code": "31351212" },
      { "name": "Brass welded or brazed tube assemblies", "code": "31351213" },
      { "name": "Riveted tube assemblies", "code": "31351300" },
      { "name": "Aluminum riveted tube assemblies", "code": "31351301" },
      { "name": "Carbon steel riveted tube assemblies", "code": "31351302" },
      { "name": "Hastalloy X riveted tube assemblies", "code": "31351303" },
      { "name": "Inconel riveted tube assemblies", "code": "31351304" },
      { "name": "Low alloy steel riveted tube assemblies", "code": "31351305" },
      { "name": "Non metallic riveted tube assemblies", "code": "31351306" },
      { "name": "Stainless steel riveted tube assemblies", "code": "31351309" },
      { "name": "Titanium riveted tube assemblies", "code": "31351310" },
      { "name": "Waspalloy riveted tube assemblies", "code": "31351311" },
      { "name": "Copper riveted tube assemblies", "code": "31351312" },
      { "name": "Brass riveted tube assemblies", "code": "31351313" },
      { "name": "Bonded tube assemblies", "code": "31351400" },
      { "name": "Aluminum bonded tube assemblies", "code": "31351401" },
      { "name": "Carbon steel bonded tube assemblies", "code": "31351402" },
      { "name": "Hastalloy X bonded tube assemblies", "code": "31351403" },
      { "name": "Inconel bonded tube assemblies", "code": "31351404" },
      { "name": "Low alloy steel bonded tube assemblies", "code": "31351405" },
      { "name": "Non metallic bonded tube assemblies", "code": "31351406" },
      { "name": "Stainless steel bonded tube assemblies", "code": "31351409" },
      { "name": "Titanium bonded tube assemblies", "code": "31351410" },
      { "name": "Waspalloy bonded tube assemblies", "code": "31351411" },
      { "name": "Copper bonded tube assemblies", "code": "31351412" },
      { "name": "Brass bonded tube assemblies", "code": "31351413" },
      { "name": "Bolted tube assemblies", "code": "31351500" },
      { "name": "Aluminum bolted tube assemblies", "code": "31351501" },
      { "name": "Carbon steel bolted tube assemblies", "code": "31351502" },
      { "name": "Hastalloy X bolted tube assemblies", "code": "31351503" },
      { "name": "Inconel bolted tube assemblies", "code": "31351504" },
      { "name": "Low alloy steel bolted tube assemblies", "code": "31351505" },
      { "name": "Non metallic bolted tube assemblies", "code": "31351506" },
      { "name": "Stainless steel bolted tube assemblies", "code": "31351509" },
      { "name": "Titanium bolted tube assemblies", "code": "31351510" },
      { "name": "Waspalloy bolted tube assemblies", "code": "31351511" },
      { "name": "Copper bolted tube assemblies", "code": "31351512" },
      { "name": "Brass bolted tube assemblies", "code": "31351513" },
      { "name": "Solvent welded tube assemblies", "code": "31351600" },
      { "name": "Aluminum solvent welded tube assemblies", "code": "31351601" },
      {
        "name": "Carbon steel solvent welded tube assemblies",
        "code": "31351602"
      },
      {
        "name": "Hastalloy X solvent welded tube assemblies",
        "code": "31351603"
      },
      { "name": "Inconel solvent welded tube assemblies", "code": "31351604" },
      {
        "name": "Low alloy steel solvent welded tube assemblies",
        "code": "31351605"
      },
      {
        "name": "Non metallic solvent welded tube assemblies",
        "code": "31351606"
      },
      {
        "name": "Stainless steel solvent welded tube assemblies",
        "code": "31351609"
      },
      { "name": "Titanium solvent welded tube assemblies", "code": "31351610" },
      {
        "name": "Waspalloy solvent welded tube assemblies",
        "code": "31351611"
      },
      { "name": "Copper solvent welded tube assemblies", "code": "31351612" },
      { "name": "Brass solvent welded tube assemblies", "code": "31351613" },
      { "name": "Sonic welded tube assemblies", "code": "31351700" },
      { "name": "Aluminum sonic welded tube assemblies", "code": "31351701" },
      {
        "name": "Carbon steel sonic welded tube assemblies",
        "code": "31351702"
      },
      {
        "name": "Hastalloy X sonic welded tube assemblies",
        "code": "31351703"
      },
      { "name": "Inconel sonic welded tube assemblies", "code": "31351704" },
      {
        "name": "Low alloy steel sonic welded tube assemblies",
        "code": "31351705"
      },
      {
        "name": "Non metallic sonic welded tube assemblies",
        "code": "31351706"
      },
      {
        "name": "Stainless steel sonic welded tube assemblies",
        "code": "31351709"
      },
      { "name": "Titanium sonic welded tube assemblies", "code": "31351710" },
      { "name": "Waspalloy sonic welded tube assemblies", "code": "31351711" },
      { "name": "Copper sonic welded tube assemblies", "code": "31351712" },
      { "name": "Brass sonic welded tube assemblies", "code": "31351713" },
      { "name": "Fabricated plate assemblies", "code": "31360000" },
      { "name": "Bonded plate assemblies", "code": "31361100" },
      { "name": "Aluminum bonded plate assemblies", "code": "31361101" },
      { "name": "Carbon steel bonded plate assemblies", "code": "31361102" },
      { "name": "Hastalloy X bonded plate assemblies", "code": "31361103" },
      { "name": "Inconel bonded plate assemblies", "code": "31361104" },
      { "name": "Low alloy steel bonded plate assemblies", "code": "31361105" },
      { "name": "Non metallic bonded plate assemblies", "code": "31361106" },
      { "name": "Stainless steel bonded plate assemblies", "code": "31361109" },
      { "name": "Titanium bonded plate assemblies", "code": "31361110" },
      { "name": "Waspalloy bonded plate assemblies", "code": "31361111" },
      { "name": "Copper bonded plate assemblies", "code": "31361112" },
      { "name": "Brass bonded plate assemblies", "code": "31361113" },
      { "name": "Bolted plate assemblies", "code": "31361200" },
      { "name": "Aluminum bolted plate assemblies", "code": "31361201" },
      { "name": "Carbon steel bolted plate assemblies", "code": "31361202" },
      { "name": "Hastalloy X bolted plate assemblies", "code": "31361203" },
      { "name": "Inconel bolted plate assemblies", "code": "31361204" },
      { "name": "Low alloy steel bolted plate assemblies", "code": "31361205" },
      { "name": "Non metallic bolted plate assemblies", "code": "31361206" },
      { "name": "Stainless steel bolted plate assemblies", "code": "31361209" },
      { "name": "Titanium bolted plate assemblies", "code": "31361210" },
      { "name": "Waspalloy bolted plate assemblies", "code": "31361211" },
      { "name": "Copper bolted plate assemblies", "code": "31361212" },
      { "name": "Brass bolted plate assemblies", "code": "31361213" },
      { "name": "Solvent welded plate assemblies", "code": "31361300" },
      {
        "name": "Aluminum solvent welded plate assemblies",
        "code": "31361301"
      },
      {
        "name": "Carbon steel solvent welded plate assemblies",
        "code": "31361302"
      },
      {
        "name": "Hastalloy X solvent welded plate assemblies",
        "code": "31361303"
      },
      { "name": "Inconel solvent welded plate assemblies", "code": "31361304" },
      {
        "name": "Low alloy steel solvent welded plate assemblies",
        "code": "31361305"
      },
      {
        "name": "Non metallic solvent welded plate assemblies",
        "code": "31361306"
      },
      {
        "name": "Stainless steel solvent welded plate assemblies",
        "code": "31361309"
      },
      {
        "name": "Titanium solvent welded plate assemblies",
        "code": "31361310"
      },
      {
        "name": "Waspalloy solvent welded plate assemblies",
        "code": "31361311"
      },
      { "name": "Copper solvent welded plate assemblies", "code": "31361312" },
      { "name": "Brass solvent welded plate assemblies", "code": "31361313" },
      { "name": "Welded or brazed plate assemblies", "code": "31361400" },
      {
        "name": "Aluminum welded or brazed plate assemblies",
        "code": "31361401"
      },
      {
        "name": "Carbon steel welded or brazed plate assemblies",
        "code": "31361402"
      },
      {
        "name": "Hastalloy X welded or brazed plate assemblies",
        "code": "31361403"
      },
      {
        "name": "Inconel welded or brazed plate assemblies",
        "code": "31361404"
      },
      {
        "name": "Low alloy steel welded or brazed plate assemblies",
        "code": "31361405"
      },
      {
        "name": "Non metallic welded or brazed plate assemblies",
        "code": "31361406"
      },
      {
        "name": "Stainless steel welded or brazed plate assemblies",
        "code": "31361409"
      },
      {
        "name": "Titanium welded or brazed plate assemblies",
        "code": "31361410"
      },
      {
        "name": "Waspalloy welded or brazed plate assemblies",
        "code": "31361411"
      },
      {
        "name": "Copper welded or brazed plate assemblies",
        "code": "31361412"
      },
      { "name": "Brass welded or brazed plate assemblies", "code": "31361413" },
      { "name": "Ultra violet welded UV plate assemblies", "code": "31361500" },
      {
        "name": "Aluminum ultra violet welded plate assemblies",
        "code": "31361501"
      },
      {
        "name": "Carbon steel ultra violet welded plate assemblies",
        "code": "31361502"
      },
      {
        "name": "Hastalloy X ultra violet welded plate assemblies",
        "code": "31361503"
      },
      {
        "name": "Inconel ultra violet welded plate assemblies",
        "code": "31361504"
      },
      {
        "name": "Low alloy steel ultra violet welded plate assemblies",
        "code": "31361505"
      },
      {
        "name": "Non metallic ultra violet welded plate assemblies",
        "code": "31361506"
      },
      {
        "name": "Stainless steel ultra violet welded plate assemblies",
        "code": "31361509"
      },
      {
        "name": "Titanium ultra violet welded plate assemblies",
        "code": "31361510"
      },
      {
        "name": "Waspalloy ultra violet welded plate assemblies",
        "code": "31361511"
      },
      {
        "name": "Copper ultra violet welded plate assemblies",
        "code": "31361512"
      },
      {
        "name": "Brass ultra violet welded plate assemblies",
        "code": "31361513"
      },
      { "name": "Sonic welded plate assemblies", "code": "31361600" },
      { "name": "Aluminum sonic welded plate assemblies", "code": "31361601" },
      {
        "name": "Carbon steel sonic welded plate assemblies",
        "code": "31361602"
      },
      {
        "name": "Hastalloy X sonic welded plate assemblies",
        "code": "31361603"
      },
      { "name": "Inconel sonic welded plate assemblies", "code": "31361604" },
      {
        "name": "Low alloy steel sonic welded plate assemblies",
        "code": "31361605"
      },
      {
        "name": "Non metallic sonic welded plate assemblies",
        "code": "31361606"
      },
      {
        "name": "Stainless steel sonic welded plate assemblies",
        "code": "31361609"
      },
      { "name": "Titanium sonic welded plate assemblies", "code": "31361610" },
      { "name": "Waspalloy sonic welded plate assemblies", "code": "31361611" },
      { "name": "Copper sonic welded plate assemblies", "code": "31361612" },
      { "name": "Brass sonic welded plate assemblies", "code": "31361613" },
      { "name": "Riveted plate assemblies", "code": "31361700" },
      { "name": "Aluminum riveted plate assemblies", "code": "31361701" },
      { "name": "Carbon steel riveted plate assemblies", "code": "31361702" },
      { "name": "Hastalloy X riveted plate assemblies", "code": "31361703" },
      { "name": "Inconel riveted plate assemblies", "code": "31361704" },
      {
        "name": "Low alloy steel riveted plate assemblies",
        "code": "31361705"
      },
      { "name": "Non metallic riveted plate assemblies", "code": "31361706" },
      {
        "name": "Stainless steel riveted plate assemblies",
        "code": "31361709"
      },
      { "name": "Titanium riveted plate assemblies", "code": "31361710" },
      { "name": "Waspalloy riveted plate assemblies", "code": "31361711" },
      { "name": "Copper riveted plate assemblies", "code": "31361712" },
      { "name": "Brass riveted plate assemblies", "code": "31361713" },
      { "name": "Refractories", "code": "31370000" },
      { "name": "Ceramic fibre products", "code": "31371000" },
      { "name": "Insulation boards", "code": "31371001" },
      { "name": "Insulating wool", "code": "31371002" },
      { "name": "Refractory blankets", "code": "31371003" },
      { "name": "Refractory bricks", "code": "31371100" },
      { "name": "Mullite bricks", "code": "31371101" },
      { "name": "Sillimanite bricks", "code": "31371102" },
      { "name": "Acid resistant bricks", "code": "31371103" },
      { "name": "Silica bricks", "code": "31371104" },
      { "name": "High alumina bricks", "code": "31371105" },
      { "name": "Calcium silicate blocks", "code": "31371106" },
      { "name": "Shaped bricks", "code": "31371107" },
      { "name": "Fire clay brick", "code": "31371108" },
      { "name": "Castables", "code": "31371200" },
      { "name": "Dense castables", "code": "31371201" },
      { "name": "Insulating castables", "code": "31371202" },
      { "name": "Low cement castables", "code": "31371203" },
      { "name": "Acid or alkali resistant castable", "code": "31371204" },
      { "name": "Abrasion resistant castable", "code": "31371205" },
      { "name": "Sic castable", "code": "31371206" },
      { "name": "Self flow castable", "code": "31371207" },
      { "name": "Tabular alumina castable", "code": "31371208" },
      { "name": "Erosion resistant castable", "code": "31371209" },
      { "name": "High alumina castable", "code": "31371210" },
      { "name": "Fire clay castable", "code": "31371211" },
      { "name": "Special service castable", "code": "31371212" },
      { "name": "Shaped refractories", "code": "31371300" },
      { "name": "Porous blocks", "code": "31371301" },
      { "name": "Zircon nozzles", "code": "31371302" },
      { "name": "Refractory tiles", "code": "31371400" },
      { "name": "Silica tiles", "code": "31371401" },
      { "name": "Magnets and magnetic materials", "code": "31380000" },
      { "name": "Castmagnets and magnet assemblies", "code": "31381100" },
      { "name": "Cast machined isotropic ferrite magnet", "code": "31381101" },
      {
        "name": "Cast machined isotropic barium ferrite magnet",
        "code": "31381102"
      },
      {
        "name": "Cast machined isotropic strontium ferrite magnet",
        "code": "31381103"
      },
      {
        "name": "Cast machined isotropic neodymium magnet",
        "code": "31381104"
      },
      {
        "name": "Cast machined isotropic samarium cobalt magnet",
        "code": "31381105"
      },
      {
        "name": "Cast machined isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381106"
      },
      {
        "name": "Cast machined anisotropic ferrite magnet",
        "code": "31381107"
      },
      {
        "name": "Cast machined anisotropic barium ferrite magnet",
        "code": "31381108"
      },
      {
        "name": "Cast machined anisotropic strontium ferrite magnet",
        "code": "31381109"
      },
      {
        "name": "Cast machined anisotropic samarium cobalt magnet",
        "code": "31381110"
      },
      {
        "name": "Cast machined anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381111"
      },
      {
        "name": "Cast machined and coated isotropic ferrite magnet",
        "code": "31381112"
      },
      {
        "name": "Cast machined and coated isotropic barium ferrite magnet",
        "code": "31381113"
      },
      {
        "name": "Cast machined and coated isotropic strontium ferrite magnet",
        "code": "31381114"
      },
      {
        "name": "Cast machined and coated isotropic neodymium magnet",
        "code": "31381115"
      },
      {
        "name": "Cast machined and coated isotropic samarium cobalt magnet",
        "code": "31381116"
      },
      {
        "name": "Cast machined and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381117"
      },
      {
        "name": "Cast machined and coated anisotropic ferrite magnet",
        "code": "31381118"
      },
      {
        "name": "Cast machined and coated anisotropic barium ferrite magnet",
        "code": "31381119"
      },
      {
        "name": "Cast machined and coated anisotropic strontium ferrite magnet",
        "code": "31381120"
      },
      {
        "name": "Cast machined and coated anisotropic samarium cobalt magnet",
        "code": "31381121"
      },
      {
        "name": "Cast machined and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381122"
      },
      { "name": "Cast coated isotropic ferrite magnet", "code": "31381123" },
      {
        "name": "Cast coated isotropic barium ferrite magnet",
        "code": "31381124"
      },
      {
        "name": "Cast coated isotropic strontium ferrite magnet",
        "code": "31381125"
      },
      { "name": "Cast coated isotropic neodymium magnet", "code": "31381126" },
      {
        "name": "Cast coated isotropic samarium cobalt magnet",
        "code": "31381127"
      },
      {
        "name": "Cast coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381128"
      },
      { "name": "Cast coated anisotropic ferrite magnet", "code": "31381129" },
      {
        "name": "Cast coated anisotropic barium ferrite magnet",
        "code": "31381130"
      },
      {
        "name": "Cast coated anisotropic strontium ferrite magnet",
        "code": "31381131"
      },
      {
        "name": "Cast coated anisotropic samarium cobalt magnet",
        "code": "31381132"
      },
      {
        "name": "Cast coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381133"
      },
      { "name": "Cast off tool isotropic ferrite magnet", "code": "31381134" },
      {
        "name": "Cast off tool isotropic barium ferrite magnet",
        "code": "31381135"
      },
      {
        "name": "Cast off tool isotropic strontium ferrite magnet",
        "code": "31381136"
      },
      {
        "name": "Cast off tool isotropic neodymium magnet",
        "code": "31381137"
      },
      {
        "name": "Cast off tool isotropic samarium cobalt magnet",
        "code": "31381138"
      },
      {
        "name": "Cast off tool isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381139"
      },
      {
        "name": "Cast off tool anisotropic ferrite magnet",
        "code": "31381140"
      },
      {
        "name": "Cast off tool anisotropic barium ferrite magnet",
        "code": "31381141"
      },
      {
        "name": "Cast off tool anisotropic strontium ferrite magnet",
        "code": "31381142"
      },
      {
        "name": "Cast off tool anisotropic samarium cobalt magnet",
        "code": "31381143"
      },
      {
        "name": "Cast off tool anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381144"
      },
      { "name": "Castisotropic ferrite magnet assembly", "code": "31381145" },
      {
        "name": "Castisotropic barium ferrite magnet assembly",
        "code": "31381146"
      },
      {
        "name": "Castisotropic strontium ferrite magnet assembly",
        "code": "31381147"
      },
      { "name": "Castisotropic neodymium magnet assembly", "code": "31381148" },
      {
        "name": "Castisotropic samarium cobalt magnet assembly",
        "code": "31381149"
      },
      {
        "name": "Castisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381150"
      },
      { "name": "Castanisotropic ferrite magnet assembly", "code": "31381151" },
      {
        "name": "Castanisotropic barium ferrite magnet assembly",
        "code": "31381152"
      },
      {
        "name": "Castanisotropic strontium ferrite magnet assembly",
        "code": "31381153"
      },
      {
        "name": "Castanisotropic samarium cobalt magnet assembly",
        "code": "31381154"
      },
      {
        "name": "Castanisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381155"
      },
      { "name": "Sintered magnets and magnet assemblies", "code": "31381200" },
      {
        "name": "Sintered machined isotropic ferrite magnet",
        "code": "31381201"
      },
      {
        "name": "Sintered machined isotropic barium ferrite magnet",
        "code": "31381202"
      },
      {
        "name": "Sintered machined isotropic strontium ferrite magnet",
        "code": "31381203"
      },
      {
        "name": "Sintered machined isotropic neodymium magnet",
        "code": "31381204"
      },
      {
        "name": "Sintered machined isotropic samarium cobalt magnet",
        "code": "31381205"
      },
      {
        "name": "Sintered machined isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381206"
      },
      {
        "name": "Sintered machined anisotropic ferrite magnet",
        "code": "31381207"
      },
      {
        "name": "Sintered machined anisotropic barium ferrite magnet",
        "code": "31381208"
      },
      {
        "name": "Sintered machined anisotropic strontium ferrite magnet",
        "code": "31381209"
      },
      {
        "name": "Sintered machined anisotropic samarium cobalt magnet",
        "code": "31381210"
      },
      {
        "name": "Sintered machined anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381211"
      },
      {
        "name": "Sintered machined and coated isotropic ferrite magnet",
        "code": "31381212"
      },
      {
        "name": "Sintered machined and coated isotropic barium ferrite magnet",
        "code": "31381213"
      },
      {
        "name": "Sintered machined and coated isotropic strontium ferrite magnet",
        "code": "31381214"
      },
      {
        "name": "Sintered machined and coated isotropic neodymium magnet",
        "code": "31381215"
      },
      {
        "name": "Sintered machined and coated isotropic samarium cobalt magnet",
        "code": "31381216"
      },
      {
        "name": "Sintered machined and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381217"
      },
      {
        "name": "Sintered machined and coated anisotropic ferrite magnet",
        "code": "31381218"
      },
      {
        "name": "Sintered machined and coated anisotropic barium ferrite magnet",
        "code": "31381219"
      },
      {
        "name": "Sintered machined and coated anisotropic strontium ferrite magnet",
        "code": "31381220"
      },
      {
        "name": "Sintered machined and coated anisotropic samarium cobalt magnet",
        "code": "31381221"
      },
      {
        "name": "Sintered machined and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381222"
      },
      {
        "name": "Sintered coated isotropic ferrite magnet",
        "code": "31381223"
      },
      {
        "name": "Sintered coated isotropic barium ferrite magnet",
        "code": "31381224"
      },
      {
        "name": "Sintered coated isotropic strontium ferrite magnet",
        "code": "31381225"
      },
      {
        "name": "Sintered coated isotropic neodymium magnet",
        "code": "31381226"
      },
      {
        "name": "Sintered coated isotropic samarium cobalt magnet",
        "code": "31381227"
      },
      {
        "name": "Sintered coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381228"
      },
      {
        "name": "Sintered coated anisotropic ferrite magnet",
        "code": "31381229"
      },
      {
        "name": "Sintered coated anisotropic barium ferrite magnet",
        "code": "31381230"
      },
      {
        "name": "Sintered coated anisotropic strontium ferrite magnet",
        "code": "31381231"
      },
      {
        "name": "Sintered coated anisotropic samarium cobalt magnet",
        "code": "31381232"
      },
      {
        "name": "Sintered coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381233"
      },
      {
        "name": "Sintered off tool isotropic ferrite magnet",
        "code": "31381234"
      },
      {
        "name": "Sintered off tool isotropic barium ferrite magnet",
        "code": "31381235"
      },
      {
        "name": "Sintered off tool isotropic strontium ferrite magnet",
        "code": "31381236"
      },
      {
        "name": "Sintered off tool isotropic neodymium magnet",
        "code": "31381237"
      },
      {
        "name": "Sintered off tool isotropic samarium cobalt magnet",
        "code": "31381238"
      },
      {
        "name": "Sintered off tool isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381239"
      },
      {
        "name": "Sintered off tool anisotropic ferrite magnet",
        "code": "31381240"
      },
      {
        "name": "Sintered off tool anisotropic barium ferrite magnet",
        "code": "31381241"
      },
      {
        "name": "Sintered off tool anisotropic strontium ferrite magnet",
        "code": "31381242"
      },
      {
        "name": "Sintered off tool anisotropic samarium cobalt magnet",
        "code": "31381243"
      },
      {
        "name": "Sintered off tool anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381244"
      },
      {
        "name": "Sinteredisotropic ferrite magnet assembly",
        "code": "31381245"
      },
      {
        "name": "Sinteredisotropic barium ferrite magnet assembly",
        "code": "31381246"
      },
      {
        "name": "Sinteredisotropic strontium ferrite magnet assembly",
        "code": "31381247"
      },
      {
        "name": "Sinteredisotropic neodymium magnet assembly",
        "code": "31381248"
      },
      {
        "name": "Sinteredisotropic samarium cobalt magnet assembly",
        "code": "31381249"
      },
      {
        "name": "Sinteredisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381250"
      },
      {
        "name": "Sinteredanisotropic ferrite magnet assembly",
        "code": "31381251"
      },
      {
        "name": "Sinteredanisotropic barium ferrite magnet assembly",
        "code": "31381252"
      },
      {
        "name": "Sinteredanisotropic strontium ferrite magnet assembly",
        "code": "31381253"
      },
      {
        "name": "Sinteredanisotropic samarium cobalt magnet assembly",
        "code": "31381254"
      },
      {
        "name": "Sinteredanisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381255"
      },
      { "name": "Pressed magnet and magnet assemblies", "code": "31381300" },
      {
        "name": "Pressed sintered and machined isotropic ferrite magnet",
        "code": "31381301"
      },
      {
        "name": "Pressed sintered and machined isotropic barium ferrite magnet",
        "code": "31381302"
      },
      {
        "name": "Pressed sintered and machined isotropic strontium ferrite magnet",
        "code": "31381303"
      },
      {
        "name": "Pressed sintered and machined isotropic neodymium magnet",
        "code": "31381304"
      },
      {
        "name": "Pressed sintered and machined isotropic samarium cobalt magnet",
        "code": "31381305"
      },
      {
        "name": "Pressed sintered and machined isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381306"
      },
      {
        "name": "Pressed sintered and machined anisotropic ferrite magnet",
        "code": "31381307"
      },
      {
        "name": "Pressed sintered and machined anisotropic barium ferrite magnet",
        "code": "31381308"
      },
      {
        "name": "Pressed sintered and machined anisotropic strontium ferrite magnet",
        "code": "31381309"
      },
      {
        "name": "Pressed sintered and machined anisotropic samarium cobalt magnet",
        "code": "31381310"
      },
      {
        "name": "Pressed sintered and machined anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381311"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic ferrite magnet",
        "code": "31381312"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic barium ferrite magnet",
        "code": "31381313"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic strontium ferrite magnet",
        "code": "31381314"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic neodymium magnet",
        "code": "31381315"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic samarium cobalt magnet",
        "code": "31381316"
      },
      {
        "name": "Pressed sintered and machined and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381317"
      },
      {
        "name": "Pressed sintered and machined and coated anisotropic ferrite magnet",
        "code": "31381318"
      },
      {
        "name": "Pressed sintered and machined and coated anisotropic barium ferrite magnet",
        "code": "31381319"
      },
      {
        "name": "Pressed sintered and machined and coated anisotropic strontium ferrite magnet",
        "code": "31381320"
      },
      {
        "name": "Pressed sintered and machined and coated anisotropic samarium cobalt magnet",
        "code": "31381321"
      },
      {
        "name": "Pressed sintered and machined and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381322"
      },
      {
        "name": "Pressed sintered and coated isotropic ferrite magnet",
        "code": "31381323"
      },
      {
        "name": "Pressed sintered and coated isotropic barium ferrite magnet",
        "code": "31381324"
      },
      {
        "name": "Pressed sintered and coated isotropic strontium ferrite magnet",
        "code": "31381325"
      },
      {
        "name": "Pressed sintered and coated isotropic neodymium magnet",
        "code": "31381326"
      },
      {
        "name": "Pressed sintered and coated isotropic samarium cobalt magnet",
        "code": "31381327"
      },
      {
        "name": "Pressed sintered and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381328"
      },
      {
        "name": "Pressed sintered and coated anisotropic ferrite magnet",
        "code": "31381329"
      },
      {
        "name": "Pressed sintered and coated anisotropic barium ferrite magnet",
        "code": "31381330"
      },
      {
        "name": "Pressed sintered and coated anisotropic strontium ferrite magnet",
        "code": "31381331"
      },
      {
        "name": "Pressed sintered and coated anisotropic samarium cobalt magnet",
        "code": "31381332"
      },
      {
        "name": "Pressed sintered and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381333"
      },
      {
        "name": "Pressed and sintered off tool isotropic ferrite magnet",
        "code": "31381334"
      },
      {
        "name": "Pressed and sintered off tool isotropic barium ferrite magnet",
        "code": "31381335"
      },
      {
        "name": "Pressed and sintered off tool isotropic strontium ferrite magnet",
        "code": "31381336"
      },
      {
        "name": "Pressed and sintered off tool isotropic neodymium magnet",
        "code": "31381337"
      },
      {
        "name": "Pressed and sintered off tool isotropic samarium cobalt magnet",
        "code": "31381338"
      },
      {
        "name": "Pressed and sintered off tool isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381339"
      },
      {
        "name": "Pressed and sintered off tool anisotropic ferrite magnet",
        "code": "31381340"
      },
      {
        "name": "Pressed and sintered off tool anisotropic barium ferrite magnet",
        "code": "31381341"
      },
      {
        "name": "Pressed and sintered off tool anisotropic strontium ferrite magnet",
        "code": "31381342"
      },
      {
        "name": "Pressed and sintered off tool anisotropic samarium cobalt magnet",
        "code": "31381343"
      },
      {
        "name": "Pressed and sintered off tool anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381344"
      },
      {
        "name": "Pressed and sintered isotropic ferrite magnet assembly",
        "code": "31381345"
      },
      {
        "name": "Pressed and sintered isotropic barium ferrite magnet assembly",
        "code": "31381346"
      },
      {
        "name": "Pressed and sintered isotropic strontium ferrite magnet assembly",
        "code": "31381347"
      },
      {
        "name": "Pressed and sintered isotropic neodymium magnet assembly",
        "code": "31381348"
      },
      {
        "name": "Pressed and sintered isotropic samarium cobalt magnet assembly",
        "code": "31381349"
      },
      {
        "name": "Pressed and sintered isotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381350"
      },
      {
        "name": "Pressed and sintered anisotropic ferrite magnet assembly",
        "code": "31381351"
      },
      {
        "name": "Pressed and sintered anisotropic barium ferrite magnet assembly",
        "code": "31381352"
      },
      {
        "name": "Pressed and sintered anisotropic strontium ferrite magnet assembly",
        "code": "31381353"
      },
      {
        "name": "Pressed and sintered anisotropic samarium cobalt magnet assembly",
        "code": "31381354"
      },
      {
        "name": "Pressed and sintered anisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381355"
      },
      {
        "name": "Plastic bonded magnets and magnet assemblies",
        "code": "31381400"
      },
      {
        "name": "Plastic bonded machined isotropic ferrite magnet",
        "code": "31381401"
      },
      {
        "name": "Plastic bonded machined isotropic barium ferrite magnet",
        "code": "31381402"
      },
      {
        "name": "Plastic bonded machined isotropic strontium ferrite magnet",
        "code": "31381403"
      },
      {
        "name": "Plastic bonded machined isotropic neodymium magnet",
        "code": "31381404"
      },
      {
        "name": "Plastic bonded machined isotropic samarium cobalt magnet",
        "code": "31381405"
      },
      {
        "name": "Plastic bonded machined isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381406"
      },
      {
        "name": "Plastic bonded machined anisotropic ferrite magnet",
        "code": "31381407"
      },
      {
        "name": "Plastic bonded machined anisotropic barium ferrite magnet",
        "code": "31381408"
      },
      {
        "name": "Plastic bonded machined anisotropic strontium ferrite magnet",
        "code": "31381409"
      },
      {
        "name": "Plastic bonded machined anisotropic samarium cobalt magnet",
        "code": "31381410"
      },
      {
        "name": "Plastic bonded machined anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381411"
      },
      {
        "name": "Plastic bonded machined and coated isotropic ferrite magnet",
        "code": "31381412"
      },
      {
        "name": "Plastic bonded machined and coated isotropic barium ferrite magnet",
        "code": "31381413"
      },
      {
        "name": "Plastic bonded machined and coated isotropic strontium ferrite magnet",
        "code": "31381414"
      },
      {
        "name": "Plastic bonded machined and coated isotropic neodymium magnet",
        "code": "31381415"
      },
      {
        "name": "Plastic bonded machined and coated isotropic samarium cobalt magnet",
        "code": "31381416"
      },
      {
        "name": "Plastic bonded machined and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381417"
      },
      {
        "name": "Plastic bonded machined and coated anisotropic ferrite magnet",
        "code": "31381418"
      },
      {
        "name": "Plastic bonded machined and coated anisotropic barium ferrite magnet",
        "code": "31381419"
      },
      {
        "name": "Plastic bonded machined and coated anisotropic strontium ferrite magnet",
        "code": "31381420"
      },
      {
        "name": "Plastic bonded machined and coated anisotropic samarium cobalt magnet",
        "code": "31381421"
      },
      {
        "name": "Plastic bonded machined and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381422"
      },
      {
        "name": "Plastic bonded coated isotropic ferrite magnet",
        "code": "31381423"
      },
      {
        "name": "Plastic bonded coated isotropic barium ferrite magnet",
        "code": "31381424"
      },
      {
        "name": "Plastic bonded coated isotropic strontium ferrite magnet",
        "code": "31381425"
      },
      {
        "name": "Plastic bonded coated isotropic neodymium magnet",
        "code": "31381426"
      },
      {
        "name": "Plastic bonded coated isotropic samarium cobalt magnet",
        "code": "31381427"
      },
      {
        "name": "Plastic bonded coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381428"
      },
      {
        "name": "Plastic bonded coated anisotropic ferrite magnet",
        "code": "31381429"
      },
      {
        "name": "Plastic bonded coated anisotropic barium ferrite magnet",
        "code": "31381430"
      },
      {
        "name": "Plastic bonded coated anisotropic strontium ferrite magnet",
        "code": "31381431"
      },
      {
        "name": "Plastic bonded coated anisotropic samarium cobalt magnet",
        "code": "31381432"
      },
      {
        "name": "Plastic bonded coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381433"
      },
      {
        "name": "Plastic bonded off tool isotropic ferrite magnet",
        "code": "31381434"
      },
      {
        "name": "Plastic bonded off tool isotropic barium ferrite magnet",
        "code": "31381435"
      },
      {
        "name": "Plastic bonded off tool isotropic strontium ferrite magnet",
        "code": "31381436"
      },
      {
        "name": "Plastic bonded off tool isotropic neodymium magnet",
        "code": "31381437"
      },
      {
        "name": "Plastic bonded off tool isotropic samarium cobalt magnet",
        "code": "31381438"
      },
      {
        "name": "Plastic bonded off tool isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381439"
      },
      {
        "name": "Plastic bonded off tool anisotropic ferrite magnet",
        "code": "31381440"
      },
      {
        "name": "Plastic bonded off tool anisotropic barium ferrite magnet",
        "code": "31381441"
      },
      {
        "name": "Plastic bonded off tool anisotropic strontium ferrite magnet",
        "code": "31381442"
      },
      {
        "name": "Plastic bonded off tool anisotropic samarium cobalt magnet",
        "code": "31381443"
      },
      {
        "name": "Plastic bonded off tool anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381444"
      },
      {
        "name": "Plastic bonded isotropic ferrite magnet assembly",
        "code": "31381445"
      },
      {
        "name": "Plastic bonded isotropic barium ferrite magnet assembly",
        "code": "31381446"
      },
      {
        "name": "Plastic bonded isotropic strontium ferrite magnet assembly",
        "code": "31381447"
      },
      {
        "name": "Plastic bonded isotropic neodymium magnet assembly",
        "code": "31381448"
      },
      {
        "name": "Plastic bonded isotropic samarium cobalt magnet assembly",
        "code": "31381449"
      },
      {
        "name": "Plastic bonded isotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381450"
      },
      {
        "name": "Plastic bonded anisotropic ferrite magnet assembly",
        "code": "31381451"
      },
      {
        "name": "Plastic bonded anisotropic barium ferrite magnet assembly",
        "code": "31381452"
      },
      {
        "name": "Plastic bonded anisotropic strontium ferrite magnet assembly",
        "code": "31381453"
      },
      {
        "name": "Plastic bonded anisotropic samarium cobalt magnet assembly",
        "code": "31381454"
      },
      {
        "name": "Plastic bonded anisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381455"
      },
      {
        "name": "Injection molded magnets and magnet assemblies",
        "code": "31381500"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic ferrite magnet",
        "code": "31381501"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic barium ferrite magnet",
        "code": "31381502"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic strontium ferrite magnet",
        "code": "31381503"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic neodymium magnet",
        "code": "31381504"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic samarium cobalt magnet",
        "code": "31381505"
      },
      {
        "name": "Plastic bonded injection molded machined isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381506"
      },
      {
        "name": "Plastic bonded injection molded machined anisotropic ferrite magnet",
        "code": "31381507"
      },
      {
        "name": "Plastic bonded injection molded machined anisotropic barium ferrite magnet",
        "code": "31381508"
      },
      {
        "name": "Plastic bonded injection molded machined anisotropic strontium ferrite magnet",
        "code": "31381509"
      },
      {
        "name": "Plastic bonded injection molded machined anisotropic samarium cobalt magnet",
        "code": "31381510"
      },
      {
        "name": "Plastic bonded injection molded machined anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381511"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic ferrite magnet",
        "code": "31381512"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic barium ferrite magnet",
        "code": "31381513"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic strontium ferrite magnet",
        "code": "31381514"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic neodymium magnet",
        "code": "31381515"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic samarium cobalt magnet",
        "code": "31381516"
      },
      {
        "name": "Plastic bonded injection molded machined and coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381517"
      },
      {
        "name": "Plastic bonded injection molded machined and coated anisotropic ferrite magnet",
        "code": "31381518"
      },
      {
        "name": "Plastic bonded injection molded machined and coated anisotropic barium ferrite magnet",
        "code": "31381519"
      },
      {
        "name": "Plastic bonded injection molded machined and coated anisotropic strontium ferrite magnet",
        "code": "31381520"
      },
      {
        "name": "Plastic bonded injection molded machined and coated anisotropic samarium cobalt magnet",
        "code": "31381521"
      },
      {
        "name": "Plastic bonded injection molded machined and coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381522"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic ferrite magnet",
        "code": "31381523"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic barium ferrite magnet",
        "code": "31381524"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic strontium ferrite magnet",
        "code": "31381525"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic neodymium magnet",
        "code": "31381526"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic samarium cobalt magnet",
        "code": "31381527"
      },
      {
        "name": "Plastic bonded injection molded coated isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381528"
      },
      {
        "name": "Plastic bonded injection molded coated anisotropic ferrite magnet",
        "code": "31381529"
      },
      {
        "name": "Plastic bonded injection molded coated anisotropic barium ferrite magnet",
        "code": "31381530"
      },
      {
        "name": "Plastic bonded injection molded coated anisotropic strontium ferrite magnet",
        "code": "31381531"
      },
      {
        "name": "Plastic bonded injection molded coated anisotropic samarium cobalt magnet",
        "code": "31381532"
      },
      {
        "name": "Plastic bonded injection molded coated anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381533"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic ferrite magnet",
        "code": "31381534"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic barium ferrite magnet",
        "code": "31381535"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic strontium ferrite magnet",
        "code": "31381536"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic neodymium magnet",
        "code": "31381537"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic samarium cobalt magnet",
        "code": "31381538"
      },
      {
        "name": "Plastic bonded injection molded off tool isotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381539"
      },
      {
        "name": "Plastic bonded injection molded off tool anisotropic ferrite magnet",
        "code": "31381540"
      },
      {
        "name": "Plastic bonded injection molded off tool anisotropic barium ferrite magnet",
        "code": "31381541"
      },
      {
        "name": "Plastic bonded injection molded off tool anisotropic strontium ferrite magnet",
        "code": "31381542"
      },
      {
        "name": "Plastic bonded injection molded off tool anisotropic samarium cobalt magnet",
        "code": "31381543"
      },
      {
        "name": "Plastic bonded injection molded off tool anisotropic ferrous aluminum nickel cobalt magnet",
        "code": "31381544"
      },
      {
        "name": "Plastic bonded injection molded isotropic ferrite magnet assembly",
        "code": "31381545"
      },
      {
        "name": "Plastic bonded injection molded isotropic barium ferrite magnet assembly",
        "code": "31381546"
      },
      {
        "name": "Plastic bonded injection molded isotropic strontium ferrite magnet assembly",
        "code": "31381547"
      },
      {
        "name": "Plastic bonded injection molded isotropic neodymium magnet assembly",
        "code": "31381548"
      },
      {
        "name": "Plastic bonded injection molded isotropic samarium cobalt magnet assembly",
        "code": "31381549"
      },
      {
        "name": "Plastic bonded injection molded isotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381550"
      },
      {
        "name": "Plastic bonded injection molded anisotropic ferrite magnet assembly",
        "code": "31381551"
      },
      {
        "name": "Plastic bonded injection molded anisotropic barium ferrite magnet assembly",
        "code": "31381552"
      },
      {
        "name": "Plastic bonded injection molded anisotropic strontium ferrite magnet assembly",
        "code": "31381553"
      },
      {
        "name": "Plastic bonded injection molded anisotropic samarium cobalt magnet assembly",
        "code": "31381554"
      },
      {
        "name": "Plastic bonded injection molded anisotropic ferrous aluminum nickel cobalt magnet assembly",
        "code": "31381555"
      },
      { "name": "Machinings", "code": "31390000" },
      { "name": "Standard precision machinings", "code": "31391500" },
      { "name": "Plastic standard precision machining", "code": "31391501" },
      { "name": "Ceramic standard precision machining", "code": "31391502" },
      { "name": "Steel standard precision machining", "code": "31391503" },
      {
        "name": "Stainless steel standard precision machining",
        "code": "31391504"
      },
      { "name": "Aluminum standard precision machining", "code": "31391505" },
      { "name": "Brass standard precision machining", "code": "31391506" },
      { "name": "Medium precision machinings", "code": "31391600" },
      { "name": "Plastic medium precision machining", "code": "31391601" },
      { "name": "Ceramic medium precision machining", "code": "31391602" },
      { "name": "Steel medium precision machining", "code": "31391603" },
      {
        "name": "Stainless steel medium precision machining",
        "code": "31391604"
      },
      { "name": "Aluminum medium precision machining", "code": "31391605" },
      { "name": "Brass medium precision machining", "code": "31391606" },
      { "name": "High precision machinings", "code": "31391700" },
      { "name": "Plastic high precision machining", "code": "31391701" },
      { "name": "Ceramic high precision machining", "code": "31391702" },
      { "name": "Steel high precision machining", "code": "31391703" },
      {
        "name": "Stainless steel high precision machining",
        "code": "31391704"
      },
      { "name": "Aluminum high precision machining", "code": "31391705" },
      { "name": "Brass high precision machining", "code": "31391706" },
      { "name": "Gaskets", "code": "31400000" },
      { "name": "Molded gaskets", "code": "31401500" },
      { "name": "Rubber molded gasket", "code": "31401501" },
      { "name": "Plastic molded gasket", "code": "31401502" },
      { "name": "O ring molded gasket", "code": "31401503" },
      {
        "name": "Electromagnetic interference molded gasket",
        "code": "31401504"
      },
      { "name": "Silicone molded gasket", "code": "31401505" },
      { "name": "Inflatable molded gasket", "code": "31401506" },
      { "name": "Molded gasket kit", "code": "31401507" },
      { "name": "Die cut gaskets", "code": "31401600" },
      { "name": "Rubber die cut gasket", "code": "31401601" },
      { "name": "Plastic die cut gasket", "code": "31401602" },
      { "name": "Textile die cut gasket", "code": "31401603" },
      { "name": "Cork die cut gasket", "code": "31401604" },
      {
        "name": "Electromagnetic interference or EMI die cut gasket",
        "code": "31401605"
      },
      { "name": "Silicone die cut gasket", "code": "31401606" },
      { "name": "Compressed fiber die cut gasket", "code": "31401607" },
      { "name": "Bimaterial die cut gasket", "code": "31401608" },
      { "name": "Polyfluoroethylene die cut gasket", "code": "31401609" },
      { "name": "Die cut gasket kit", "code": "31401610" },
      { "name": "Stamped gaskets", "code": "31401700" },
      { "name": "Rubber coated steel stamped gasket", "code": "31401701" },
      { "name": "Plastic stamped gasket", "code": "31401702" },
      { "name": "Rubber stamped gasket", "code": "31401703" },
      { "name": "Metal stamped gasket", "code": "31401704" },
      { "name": "Textile stamped gasket", "code": "31401705" },
      { "name": "Cork stamped gasket", "code": "31401706" },
      { "name": "Bolted stamped gasket", "code": "31401707" },
      { "name": "Stamped gasket kit", "code": "31401708" },
      { "name": "Water jet cut gaskets", "code": "31401800" },
      {
        "name": "Electromagnetic interference water jet cut gasket",
        "code": "31401801"
      },
      { "name": "Rubber water jet cut gasket", "code": "31401802" },
      { "name": "Plastic water jet cut gasket", "code": "31401803" },
      { "name": "Textile water jet cut gasket", "code": "31401804" },
      { "name": "Cork water jet cut gasket", "code": "31401805" },
      { "name": "Silicone water jet cut gasket", "code": "31401806" },
      { "name": "Water jet cut gasket kit", "code": "31401807" },
      { "name": "Liquid gaskets", "code": "31401900" },
      { "name": "Polyacrylate liquid gasket", "code": "31401901" },
      { "name": "Silicone liquid gasket", "code": "31401902" },
      { "name": "Solvent liquid gasket", "code": "31401903" },
      { "name": "Anaerobic liquid gasket", "code": "31401904" },
      { "name": "Seals", "code": "31410000" },
      { "name": "Lathe cut seals", "code": "31411500" },
      { "name": "Rubber lathe cut seal", "code": "31411501" },
      { "name": "Polytetrafluoroethylene lathe cut seal", "code": "31411502" },
      { "name": "Neoprene lathe cut seal", "code": "31411503" },
      { "name": "Polyacrylate lathe cut seal", "code": "31411504" },
      { "name": "Metallic lathe cut seal", "code": "31411505" },
      { "name": "Lathe cut seal kit", "code": "31411506" },
      { "name": "Die cut seals", "code": "31411600" },
      { "name": "Rubber die cut seal", "code": "31411601" },
      { "name": "Foam die cut seal", "code": "31411602" },
      { "name": "Plastic die cut seal", "code": "31411603" },
      { "name": "Die cut seal kit", "code": "31411604" },
      { "name": "Silicone die cut seal", "code": "31411605" },
      { "name": "Mechanical seals", "code": "31411700" },
      { "name": "Pusher mechanical seal", "code": "31411701" },
      { "name": "Bellows mechanical seal", "code": "31411702" },
      { "name": "Mechanical seal kit", "code": "31411703" },
      { "name": "V ring seal", "code": "31411704" },
      { "name": "Lip seal", "code": "31411705" },
      { "name": "Diaphragm seals", "code": "31411800" },
      { "name": "Welded diaphragm seal", "code": "31411801" },
      { "name": "Clamped diaphragm seal", "code": "31411802" },
      { "name": "Capsule diaphragm seal", "code": "31411803" },
      { "name": "Rubber diaphragm seal", "code": "31411804" },
      { "name": "Fiber reinforced diaphragm seal", "code": "31411805" },
      { "name": "Molded seals", "code": "31411900" },
      { "name": "Rubber molded seal", "code": "31411901" },
      { "name": "Plastic molded seal", "code": "31411902" },
      { "name": "Precision molded seal", "code": "31411903" },
      { "name": "Rubber on metal or plastic molded seal", "code": "31411904" },
      { "name": "Silicone molded seal", "code": "31411905" },
      { "name": "Sintered parts", "code": "31420000" },
      { "name": "Sintered filters", "code": "31421500" },
      { "name": "Iron copper standard sintered filter", "code": "31421501" },
      {
        "name": "Soft magnetic iron composite standard sintered filter",
        "code": "31421502"
      },
      {
        "name": "Soft magnetic iron composite high temperature sintered filter",
        "code": "31421503"
      },
      {
        "name": "Stainless steel standard sintered filter",
        "code": "31421504"
      },
      {
        "name": "Stainless steel high temperature sintered filter",
        "code": "31421505"
      },
      {
        "name": "Stainless steel compacted standard sintered filter",
        "code": "31421506"
      },
      { "name": "Nickel cobalt standard sintered filter", "code": "31421507" },
      {
        "name": "Nickel cobalt high temperature sintered filter",
        "code": "31421508"
      },
      {
        "name": "Nickel cobalt compacted standard sintered filter",
        "code": "31421509"
      },
      { "name": "Aluminum standard sintered filter", "code": "31421510" },
      {
        "name": "Aluminum high temperature sintered filter",
        "code": "31421511"
      },
      { "name": "Copper based standard sintered filter", "code": "31421512" },
      { "name": "Titanium alloy standard sintered filter", "code": "31421513" },
      {
        "name": "Titanium alloy high temperature sintered filter",
        "code": "31421514"
      },
      { "name": "Beryllium standard sintered filter", "code": "31421515" },
      {
        "name": "Beryllium high temperature sintered filter",
        "code": "31421516"
      },
      {
        "name": "Refractory metal standard sintered filter",
        "code": "31421517"
      },
      {
        "name": "Refractory metal high temperature sintered filter",
        "code": "31421518"
      },
      { "name": "Ceramic standard sintered filter", "code": "31421519" },
      {
        "name": "Ceramic high temperature sintered filter",
        "code": "31421520"
      },
      {
        "name": "Pure iron powder standard sintered filter",
        "code": "31421521"
      },
      {
        "name": "Pure iron powder high temperature sintered filter",
        "code": "31421522"
      }
    ]
  },
  {
    "name": "Electronic Components and Supplies",
    "code": "32000000",
    "subcategories": [
      {
        "name": "Printed circuits and integrated circuits and microassemblies",
        "code": "32100000"
      },
      {
        "name": "Circuit assemblies and radio frequency RF components",
        "code": "32101500"
      },
      { "name": "Printed circuit assemblies PCAs", "code": "32101502" },
      { "name": "Mixed circuit assemblies", "code": "32101503" },
      { "name": "Surface mount circuit assemblies", "code": "32101504" },
      { "name": "Plated through circuit assemblies", "code": "32101505" },
      { "name": "Double sided circuit cards", "code": "32101506" },
      { "name": "Backplane circuit cards", "code": "32101507" },
      { "name": "Multilayer circuit cards", "code": "32101508" },
      { "name": "Single sided circuit cards", "code": "32101509" },
      { "name": "Printed wire boards", "code": "32101510" },
      { "name": "Demodulators", "code": "32101512" },
      { "name": "Application specific circuit assemblies", "code": "32101513" },
      { "name": "Amplifiers", "code": "32101514" },
      { "name": "Attenuators", "code": "32101515" },
      { "name": "Circulators", "code": "32101516" },
      { "name": "Couplers", "code": "32101517" },
      { "name": "Delay lines", "code": "32101518" },
      { "name": "Detectors", "code": "32101519" },
      { "name": "Dummy loads", "code": "32101520" },
      { "name": "Radio frequency RF filters", "code": "32101521" },
      { "name": "Isolators", "code": "32101522" },
      { "name": "Mixers", "code": "32101523" },
      { "name": "Phase shifters", "code": "32101524" },
      { "name": "Power dividers", "code": "32101525" },
      { "name": "Wave tube amplifiers", "code": "32101526" },
      { "name": "Terminations", "code": "32101527" },
      { "name": "Modulators", "code": "32101528" },
      { "name": "Splitter", "code": "32101529" },
      { "name": "Radio frequency RF combiner", "code": "32101530" },
      { "name": "Attenuator network pad", "code": "32101531" },
      { "name": "Ceramic substrate", "code": "32101532" },
      { "name": "Output transformer", "code": "32101533" },
      { "name": "Wave trap", "code": "32101534" },
      { "name": "Phase modulation circuit", "code": "32101535" },
      {
        "name": "Phase unbalance and power factor compensator",
        "code": "32101536"
      },
      { "name": "Synchro repeater", "code": "32101537" },
      { "name": "Impedance stabilizer", "code": "32101538" },
      { "name": "Frequency selector", "code": "32101539" },
      { "name": "Resolver", "code": "32101540" },
      { "name": "Synchro transmitter", "code": "32101541" },
      { "name": "Synchro receiver", "code": "32101542" },
      { "name": "Gauge controller and indicator", "code": "32101543" },
      { "name": "Frequency oscillator", "code": "32101544" },
      { "name": "Antenna coil", "code": "32101545" },
      { "name": "Frequency multiplier", "code": "32101546" },
      { "name": "Limiter", "code": "32101547" },
      { "name": "Discriminator", "code": "32101548" },
      { "name": "Integrated circuits", "code": "32101600" },
      { "name": "Random access memory RAM", "code": "32101601" },
      { "name": "Dynamic random access memory DRAM", "code": "32101602" },
      { "name": "Static random access memory SRAM", "code": "32101603" },
      { "name": "Programmable read only memory PROM", "code": "32101604" },
      {
        "name": "Eraseable programmable read only memory EPROM",
        "code": "32101605"
      },
      {
        "name": "Electronically erasable programmable read only memory EEPROM",
        "code": "32101606"
      },
      {
        "name": "Monolithic memory integrated circuits MMIC",
        "code": "32101607"
      },
      { "name": "Read only memory ROM", "code": "32101608" },
      {
        "name": "Application specific integrated circuits ASIC",
        "code": "32101609"
      },
      { "name": "Programmable array logic PAL", "code": "32101611" },
      { "name": "Gate array logic GAL", "code": "32101612" },
      { "name": "Transistor transistor logic TTL", "code": "32101613" },
      { "name": "Emitter coupled logic ECL", "code": "32101614" },
      {
        "name": "Bipolar or metal oxide semiconductor technology BIMOS",
        "code": "32101615"
      },
      {
        "name": "Bipolar complementary metal oxide semiconductor technology BICMOS",
        "code": "32101616"
      },
      { "name": "Smart cards", "code": "32101617" },
      { "name": "Unscreened integrated circuits", "code": "32101618" },
      { "name": "Linear integrated circuits", "code": "32101619" },
      { "name": "Digital integrated circuits", "code": "32101620" },
      {
        "name": "Synchronous dynamic random access memory SDRAM",
        "code": "32101621"
      },
      { "name": "Flash memory", "code": "32101622" },
      {
        "name": "Rambus dynamic random access memory RDRAM",
        "code": "32101623"
      },
      {
        "name": "Synchronous graphic random access memory SGRAM",
        "code": "32101624"
      },
      {
        "name": "Motor drive or control integrated circuits",
        "code": "32101625"
      },
      { "name": "Microprocessors", "code": "32101626" },
      { "name": "Clock oscillators", "code": "32101627" },
      { "name": "Microcontrollers", "code": "32101628" },
      { "name": "Operational amplifiers", "code": "32101629" },
      { "name": "Voltage regulator integrated circuits", "code": "32101630" },
      { "name": "Voltage comparator integrated circuits", "code": "32101631" },
      { "name": "Timer integrated circuits", "code": "32101632" },
      { "name": "Logic gates", "code": "32101633" },
      { "name": "Flip flops", "code": "32101634" },
      { "name": "Shift registers", "code": "32101635" },
      { "name": "Digital Signal Processor DSP", "code": "32101636" },
      { "name": "Network Processors", "code": "32101637" },
      { "name": "Application specific integrated circuit", "code": "32101638" },
      { "name": "8 bit microcontroller", "code": "32101639" },
      { "name": "16 bit microcontroller", "code": "32101640" },
      { "name": "Low end digital signal processor DSP", "code": "32101641" },
      { "name": "32 bit midrange microcontroller", "code": "32101642" },
      { "name": "Programmable logic integrated circuit", "code": "32101643" },
      { "name": "High end digital signal processor DSP", "code": "32101644" },
      { "name": "32 bit high end microcontroller", "code": "32101645" },
      { "name": "NOR parallel flash memory", "code": "32101646" },
      { "name": "NOR serial flash memory", "code": "32101647" },
      { "name": "NAND flash memory", "code": "32101648" },
      {
        "name": "Standard analog or linear integrated circuit",
        "code": "32101649"
      },
      {
        "name": "Analog audio amplifier integrated circuit",
        "code": "32101650"
      },
      {
        "name": "Digital audio amplifier integrated circuit",
        "code": "32101651"
      },
      { "name": "Standard logic integrated circuit", "code": "32101652" },
      { "name": "Microwave integrated circuit", "code": "32101653" },
      { "name": "Bus transceiver integrated circuit", "code": "32101654" },
      {
        "name": "Satellite digital audio radio service integrated circuit",
        "code": "32101655"
      },
      {
        "name": "Global positioning system GPS integrated circuit",
        "code": "32101656"
      },
      { "name": "Tuner integrated circuit", "code": "32101657" },
      { "name": "Graphics accelerator integrated circuit", "code": "32101658" },
      { "name": "Drivers displayintegrated circuit", "code": "32101659" },
      { "name": "Angular rate sensor integrated circuit", "code": "32101660" },
      { "name": "Accelerometer integrated circuit", "code": "32101661" },
      { "name": "Hall effect integrated circuit", "code": "32101662" },
      {
        "name": "Infrared IR temperature sensor integrated circuit",
        "code": "32101663"
      },
      { "name": "Ultrasonic integrated circuit", "code": "32101664" },
      { "name": "Image sensor integrated circuit", "code": "32101665" },
      {
        "name": "Video or media processor integrated circuit",
        "code": "32101666"
      },
      { "name": "Vision processor integrated circuit", "code": "32101667" },
      { "name": "Codecs integrated circuit", "code": "32101668" },
      {
        "name": "Communication protocol integrated circuit",
        "code": "32101669"
      },
      { "name": "Transponder integrated circuit", "code": "32101670" },
      { "name": "Pressure sensor integrated circuit", "code": "32101671" },
      { "name": "Lead frame", "code": "32101672" },
      { "name": "Discrete semiconductor devices", "code": "32110000" },
      { "name": "Diodes", "code": "32111500" },
      { "name": "Microwave diodes", "code": "32111501" },
      { "name": "Zener diodes", "code": "32111502" },
      { "name": "Light emitting diodes LEDs", "code": "32111503" },
      { "name": "Schottky diodes", "code": "32111504" },
      { "name": "Tunnel diodes", "code": "32111505" },
      { "name": "Photosensitive diodes", "code": "32111506" },
      { "name": "Variable capacitance diodes", "code": "32111507" },
      { "name": "Solar diodes", "code": "32111508" },
      { "name": "Power diodes", "code": "32111509" },
      { "name": "Radio frequency RF diodes", "code": "32111510" },
      { "name": "Small signal diodes", "code": "32111511" },
      { "name": "Laser diodes", "code": "32111512" },
      { "name": "Varactor diode", "code": "32111513" },
      { "name": "PIN diode", "code": "32111514" },
      { "name": "Optical diode", "code": "32111515" },
      { "name": "Transistors", "code": "32111600" },
      { "name": "Photo sensitive transistors", "code": "32111601" },
      { "name": "Field effect transistors FET", "code": "32111602" },
      {
        "name": "Metal oxide silicone field effect transistors MOSFET",
        "code": "32111603"
      },
      { "name": "Transistor chips", "code": "32111604" },
      {
        "name": "Bipolar darlington or radio frequency RF transistors",
        "code": "32111607"
      },
      { "name": "Unijunction transistors", "code": "32111608" },
      { "name": "Insulated gate bipolar transistors IGBT", "code": "32111609" },
      { "name": "Junction field effect transistors JFET", "code": "32111610" },
      { "name": "Bipolar junction transistors BJT", "code": "32111611" },
      { "name": "Power field effect transistor", "code": "32111612" },
      {
        "name": "Bipolar or radio frequency bipolar transistor",
        "code": "32111613"
      },
      { "name": "Smart field effect transistor", "code": "32111614" },
      { "name": "Small signal field effect transistor", "code": "32111615" },
      { "name": "Radio frequency field effect transistor", "code": "32111616" },
      { "name": "Semiconductor devices", "code": "32111700" },
      { "name": "Photovoltaic cells", "code": "32111701" },
      { "name": "Thyristors", "code": "32111702" },
      { "name": "Diacs", "code": "32111703" },
      { "name": "Triacs", "code": "32111704" },
      { "name": "Optical coupled isolators", "code": "32111705" },
      { "name": "Crystal oscillators", "code": "32111706" },
      { "name": "Semiconductor suspector", "code": "32111707" },
      { "name": "Impedance matching network", "code": "32111708" },
      { "name": "Temperature compensating network", "code": "32111709" },
      { "name": "Passive discrete components", "code": "32120000" },
      { "name": "Capacitors", "code": "32121500" },
      { "name": "Fixed capacitors", "code": "32121501" },
      { "name": "Variable capacitors or varactors", "code": "32121502" },
      { "name": "Adjustable pre set capacitors", "code": "32121503" },
      { "name": "Capacitor networks", "code": "32121504" },
      { "name": "Aluminum electrolytic fixed capacitor", "code": "32121505" },
      { "name": "Ceramic fixed capacitor", "code": "32121506" },
      { "name": "Film fixed capacitor", "code": "32121507" },
      { "name": "Tantalum fixed capacitor", "code": "32121508" },
      { "name": "Air capacitor", "code": "32121509" },
      { "name": "Gas capacitor", "code": "32121510" },
      { "name": "Oil capacitor", "code": "32121511" },
      { "name": "Mica capacitor", "code": "32121512" },
      { "name": "Vacuum capacitor", "code": "32121513" },
      { "name": "Paper capacitor", "code": "32121514" },
      { "name": "Metallized paper capacitor", "code": "32121515" },
      { "name": "Resistors", "code": "32121600" },
      { "name": "Fusistors", "code": "32121602" },
      { "name": "Variable resistors or varistors", "code": "32121603" },
      { "name": "Resistor networks", "code": "32121607" },
      { "name": "Fixed resistors", "code": "32121609" },
      { "name": "Thermistor", "code": "32121610" },
      { "name": "Restrictor plate or air restrictor", "code": "32121611" },
      { "name": "Surface mount resistor", "code": "32121612" },
      { "name": "Wirewound resistor", "code": "32121613" },
      { "name": "Metal film oxide resistor", "code": "32121614" },
      { "name": "Carbon film resistor", "code": "32121615" },
      { "name": "Trimmer resistor", "code": "32121616" },
      { "name": "Power resistor", "code": "32121617" },
      { "name": "Metal film resistor", "code": "32121618" },
      { "name": "Discrete components", "code": "32121700" },
      { "name": "Rectifiers", "code": "32121701" },
      { "name": "Inductors", "code": "32121702" },
      { "name": "Ferrites", "code": "32121703" },
      { "name": "Static converters", "code": "32121704" },
      { "name": "Inverters", "code": "32121705" },
      { "name": "Resistor or capacitor R/C networks", "code": "32121706" },
      { "name": "Silicon controlled rectifier", "code": "32121707" },
      { "name": "Bridge rectifier", "code": "32121708" },
      { "name": "Wirewound inductor", "code": "32121709" },
      { "name": "Multilayerinductor", "code": "32121710" },
      { "name": "Choke inductor", "code": "32121711" },
      { "name": "High frequency coil", "code": "32121712" },
      { "name": "Signal filters", "code": "32121800" },
      { "name": "Surface acoustic wave SAW filter", "code": "32121801" },
      { "name": "Ceramic filter", "code": "32121802" },
      { "name": "Microwave filter", "code": "32121803" },
      { "name": "Crystal filter", "code": "32121804" },
      { "name": "Resonators", "code": "32121900" },
      { "name": "Surface acoustic wave SAW resonator", "code": "32121901" },
      { "name": "Ceramic resonator", "code": "32121902" },
      {
        "name": "Electronic hardware and component parts and accessories",
        "code": "32130000"
      },
      {
        "name": "Electronic component parts and raw materials and accessories",
        "code": "32131000"
      },
      { "name": "Heat sinks", "code": "32131001" },
      { "name": "Semiconductor dies", "code": "32131002" },
      { "name": "Semiconductor wafers", "code": "32131003" },
      { "name": "Integrated circuit packages", "code": "32131005" },
      { "name": "Integrated circuit sockets or mounts", "code": "32131006" },
      { "name": "Discrete component mounts", "code": "32131007" },
      { "name": "Heat sink compounds", "code": "32131008" },
      { "name": "Insulators for heat sinks", "code": "32131009" },
      { "name": "Bare printed circuit boards", "code": "32131010" },
      { "name": "Integrated circuit lids", "code": "32131011" },
      { "name": "Sputtering targets", "code": "32131012" },
      { "name": "Photo mask", "code": "32131013" },
      { "name": "Bare flexible printed circuit board", "code": "32131014" },
      { "name": "Single sided bare printed circuit board", "code": "32131015" },
      { "name": "Double sided bare printed circuit board", "code": "32131016" },
      { "name": "Multilayer bare printed circuit board", "code": "32131017" },
      {
        "name": "Heavy layer copper bare printed circuit board",
        "code": "32131018"
      },
      {
        "name": "Electroplated gold layer bare printed circuit board",
        "code": "32131019"
      },
      {
        "name": "Assembled flexible printed circuit board",
        "code": "32131020"
      },
      {
        "name": "Low temperature co fired ceramic substrate",
        "code": "32131021"
      },
      {
        "name": "Ceramic for printed circuit board PCB substrates",
        "code": "32131022"
      },
      { "name": "Electrical or electronic device holder", "code": "32131023" },
      { "name": "Electron tube devices and accessories", "code": "32140000" },
      { "name": "Electron Tubes", "code": "32141000" },
      { "name": "Cathode ray tubes", "code": "32141001" },
      { "name": "Klystrons", "code": "32141002" },
      { "name": "Magnetrons", "code": "32141003" },
      { "name": "Traveling wave tubes", "code": "32141004" },
      { "name": "Disk seal tubes", "code": "32141005" },
      { "name": "Resnatrons", "code": "32141006" },
      { "name": "Thyratrons", "code": "32141007" },
      { "name": "Ignitrons", "code": "32141008" },
      { "name": "Photo tubes", "code": "32141009" },
      { "name": "Photo multiplier tubes", "code": "32141010" },
      { "name": "Camera or television pickup tubes", "code": "32141011" },
      { "name": "Diode tubes", "code": "32141012" },
      { "name": "Triode tubes", "code": "32141013" },
      { "name": "Tetrode tubes", "code": "32141014" },
      { "name": "Pentode tubes", "code": "32141015" },
      { "name": "Multiple valve tubes", "code": "32141016" },
      { "name": "Counter tube", "code": "32141017" },
      { "name": "Beam output tube", "code": "32141018" },
      { "name": "Microwave electron tube", "code": "32141019" },
      { "name": "Frequency converting tube", "code": "32141020" },
      { "name": "Rectifier tube", "code": "32141021" },
      { "name": "Voltage regulator electron tube", "code": "32141022" },
      { "name": "Electron tube parts and accessories", "code": "32141100" },
      { "name": "Cathodes or emitters", "code": "32141101" },
      { "name": "Anode devices", "code": "32141102" },
      { "name": "Grid devices", "code": "32141103" },
      { "name": "Deflecting devices", "code": "32141104" },
      { "name": "Tube envelopes or blanks", "code": "32141105" },
      { "name": "Tube bases", "code": "32141106" },
      { "name": "Tube sockets", "code": "32141107" },
      { "name": "Electrode pins", "code": "32141108" },
      { "name": "Electrode carriers", "code": "32141109" },
      { "name": "Magnet pole piece", "code": "32141110" },
      {
        "name": "Automation control devices and components and accessories",
        "code": "32150000"
      },
      {
        "name": "Control indicating and signaling devices",
        "code": "32151500"
      },
      { "name": "Control sound module", "code": "32151501" },
      { "name": "Light module", "code": "32151502" },
      { "name": "Stack Light", "code": "32151503" },
      { "name": "Electronic circuit equalizer", "code": "32151504" },
      {
        "name": "Programmable logic controller subsystems",
        "code": "32151600"
      },
      {
        "name": "Programmable logic controller chassis I/O subsystem",
        "code": "32151601"
      },
      {
        "name": "Programmable logic controller distributed in cabinet I/O subsystem",
        "code": "32151602"
      },
      {
        "name": "Programmable logic controller distributed on machine I/O subsystem",
        "code": "32151603"
      },
      { "name": "Programmable Logic Controllers", "code": "32151700" },
      { "name": "Control network linking device", "code": "32151701" },
      { "name": "Control network PC interface", "code": "32151702" },
      {
        "name": "Programmable Logic Controller Accessories",
        "code": "32151703"
      },
      { "name": "Programmable Logic Controller Chassis", "code": "32151704" },
      { "name": "Programmable Logic Controller Module", "code": "32151705" },
      {
        "name": "Programmable Logic Controller Power Supply",
        "code": "32151706"
      },
      {
        "name": "Programmable Logic Controller Programming Device",
        "code": "32151707"
      },
      {
        "name": "Programmable logic controller input output housing",
        "code": "32151708"
      },
      {
        "name": "Programmable logic controller Input output doors",
        "code": "32151709"
      },
      { "name": "Safety control devices", "code": "32151800" },
      { "name": "Load switch IEC", "code": "32151801" },
      { "name": "Safety Control Module", "code": "32151802" },
      { "name": "Safety isolation system", "code": "32151803" },
      { "name": "Safety light curtain and scanner", "code": "32151804" },
      { "name": "Safety mat and edge", "code": "32151805" },
      { "name": "Automation control connectivity devices", "code": "32151900" },
      { "name": "Bulkhead pass through connector", "code": "32151901" },
      { "name": "Control system cordset", "code": "32151902" },
      { "name": "Control system distribution box", "code": "32151903" },
      { "name": "Control system patchcord", "code": "32151904" },
      { "name": "Control system receptacle", "code": "32151905" },
      { "name": "Control system splitter", "code": "32151906" },
      { "name": "Control system Y cable", "code": "32151907" },
      { "name": "Control system wiring", "code": "32151908" },
      { "name": "Field attachable connector", "code": "32151909" },
      { "name": "Through panel control connection system", "code": "32151910" },
      {
        "name": "Process control or packaged automation systems",
        "code": "32152000"
      },
      { "name": "Distributed control packaged system DCS", "code": "32152001" },
      {
        "name": "Supervisory control and data acquisition packaged system SCADA",
        "code": "32152002"
      },
      { "name": "Safety instrumented packaged system SIS", "code": "32152003" }
    ]
  },
  {
    "name": "Electrical Systems and Lighting and Components and Accessories and Supplies",
    "code": "39000000",
    "subcategories": [
      {
        "name": "Lamps and lightbulbs and lamp components",
        "code": "39100000"
      },
      { "name": "Lamps and lightbulbs", "code": "39101600" },
      { "name": "Halogen lamps", "code": "39101601" },
      { "name": "Medical lamps", "code": "39101602" },
      { "name": "Solar lamps", "code": "39101603" },
      { "name": "Fluorescent lamps", "code": "39101605" },
      { "name": "Shadowless or scialytic operation light", "code": "39101608" },
      { "name": "Stage or studio lamps", "code": "39101609" },
      { "name": "Incandescent lamps", "code": "39101612" },
      { "name": "Infrared lamps", "code": "39101613" },
      { "name": "Metal halide lamp HID", "code": "39101614" },
      { "name": "Mercury vapor lamp HID", "code": "39101615" },
      { "name": "Ultraviolet UV lamps", "code": "39101616" },
      { "name": "High pressure sodium lamp HID", "code": "39101617" },
      { "name": "Neon lamps", "code": "39101618" },
      { "name": "Compact fluorescent CFL lamps", "code": "39101619" },
      { "name": "Induction lamp", "code": "39101620" },
      { "name": "Low pressure sodium lamp HID", "code": "39101621" },
      { "name": "Miniature lamp", "code": "39101622" },
      { "name": "Sealed beam lamp", "code": "39101623" },
      { "name": "Black light", "code": "39101624" },
      { "name": "Xenon lamp", "code": "39101625" },
      { "name": "Krypton lamp", "code": "39101626" },
      { "name": "Arc lamp", "code": "39101627" },
      { "name": "Light emitting diode LED lamp", "code": "39101628" },
      { "name": "Lamp components and accessories", "code": "39101800" },
      { "name": "Lamp filament", "code": "39101801" },
      { "name": "Lamp glass", "code": "39101802" },
      { "name": "Lamp base", "code": "39101803" },
      { "name": "High pressure sodium lamp ignitor", "code": "39101804" },
      { "name": "Metal halide dry capacitor", "code": "39101805" },
      { "name": "High pressure sodium lamp dry capacitor", "code": "39101806" },
      { "name": "Lamp tee", "code": "39101807" },
      { "name": "Lamp Ballasts and Lamp Transformers", "code": "39101900" },
      { "name": "Fluorescent ballast", "code": "39101901" },
      { "name": "High intensity discharge HID ballast", "code": "39101902" },
      { "name": "Induction Lighting System", "code": "39101903" },
      { "name": "Low voltage lighting transformer", "code": "39101904" },
      { "name": "Neon ballast", "code": "39101905" },
      { "name": "Step down lamp transformer", "code": "39101906" },
      {
        "name": "Electronic high intensity discharge EHID ballast",
        "code": "39101907"
      },
      { "name": "Lighting Fixtures and Accessories", "code": "39110000" },
      {
        "name": "Interior lighting fixtures and accessories",
        "code": "39111500"
      },
      { "name": "Fluorescent fixtures", "code": "39111501" },
      { "name": "Wall fixture", "code": "39111503" },
      {
        "name": "Stage or projection or studio lighting system",
        "code": "39111504"
      },
      { "name": "Recessed lighting", "code": "39111505" },
      { "name": "Chandeliers", "code": "39111506" },
      { "name": "Desk fixtures", "code": "39111507" },
      { "name": "Track lighting", "code": "39111508" },
      { "name": "Floor lamp fixture", "code": "39111509" },
      { "name": "Table lamp fixture", "code": "39111510" },
      { "name": "Laboratory task light", "code": "39111512" },
      { "name": "Downlighting fixtures", "code": "39111515" },
      { "name": "Halogen lighting fixtures", "code": "39111520" },
      {
        "name": "Decorative ceiling or flush mount fixture",
        "code": "39111521"
      },
      { "name": "Pendant lighting", "code": "39111522" },
      { "name": "High intensity discharge HID fixture", "code": "39111524" },
      { "name": "Incandescent fixture", "code": "39111525" },
      { "name": "Solar lighting fixture", "code": "39111527" },
      { "name": "Undercabinet fixture", "code": "39111528" },
      { "name": "Lighting gobo", "code": "39111529" },
      { "name": "Commercial downlighting fixture", "code": "39111530" },
      { "name": "Commercial downlighting trim", "code": "39111531" },
      { "name": "Fluorescent high bay fixture", "code": "39111532" },
      { "name": "Fluorescent strip", "code": "39111533" },
      { "name": "Lensed troffer", "code": "39111534" },
      { "name": "Parabolic fixture", "code": "39111535" },
      { "name": "Vanity lighting", "code": "39111536" },
      { "name": "Sports lighting fixture", "code": "39111537" },
      { "name": "Garage or canopy lighting fixture", "code": "39111538" },
      { "name": "Lighting box", "code": "39111539" },
      { "name": "Auto lift lighting system", "code": "39111540" },
      { "name": "Interior lighting fixture accessory", "code": "39111541" },
      { "name": "Magnifying lamp", "code": "39111542" },
      { "name": "Bath fan with light", "code": "39111543" },
      { "name": "Light emitting diode LED fixture", "code": "39111544" },
      { "name": "Domestic night light", "code": "39111545" },
      {
        "name": "Exterior lighting fixtures and accessories",
        "code": "39111600"
      },
      { "name": "Roadway or highway lighting", "code": "39111603" },
      { "name": "Landscape lighting", "code": "39111605" },
      { "name": "Underwater lighting", "code": "39111606" },
      { "name": "Residential street lights", "code": "39111608" },
      { "name": "Lighting pole or post and hardware", "code": "39111609" },
      { "name": "Flashlight", "code": "39111610" },
      { "name": "Flood light", "code": "39111611" },
      { "name": "Area lighting", "code": "39111612" },
      { "name": "Security lighting", "code": "39111613" },
      { "name": "Mailbox lighting fixture", "code": "39111614" },
      { "name": "Ingrade lighting", "code": "39111615" },
      { "name": "Outdoor lighting accessories", "code": "39111616" },
      { "name": "Renewable energy street lighting", "code": "39111617" },
      { "name": "Sports lighting system", "code": "39111618" },
      { "name": "Large area lighting system", "code": "39111619" },
      { "name": "Emergency lighting and accessories", "code": "39111700" },
      { "name": "Storm lights", "code": "39111703" },
      { "name": "Glow stick or light stick", "code": "39111705" },
      { "name": "Strobe or warning lights", "code": "39111706" },
      { "name": "Combo light unit exit", "code": "39111707" },
      { "name": "Emergency exit illuminated sign", "code": "39111708" },
      { "name": "Emergency light unit", "code": "39111709" },
      { "name": "Emergency Lighting Accessories", "code": "39111710" },
      { "name": "Exit light conversion kit", "code": "39111711" },
      { "name": "Exit light mounting canopy", "code": "39111712" },
      { "name": "Remote lighting fixture", "code": "39111713" },
      { "name": "Signal Flare", "code": "39111714" },
      { "name": "Lighting accessories", "code": "39111800" },
      { "name": "Lamp ballasts", "code": "39111801" },
      { "name": "Lamp housings", "code": "39111802" },
      { "name": "Lamp sockets", "code": "39111803" },
      { "name": "Light boxes", "code": "39111806" },
      { "name": "Grilles", "code": "39111808" },
      { "name": "Light conditioner filters", "code": "39111809" },
      { "name": "Lamp starter", "code": "39111810" },
      { "name": "Electrified tracks", "code": "39111811" },
      { "name": "Lamp covers", "code": "39111812" },
      { "name": "Lamp arms", "code": "39111813" },
      { "name": "Ceiling frame lighting flange kit", "code": "39111814" },
      { "name": "Lamp and lamp fixture guard", "code": "39111815" },
      { "name": "Lamp Lens", "code": "39111816" },
      { "name": "Lamp mounting accessory", "code": "39111817" },
      { "name": "Lamp reflector", "code": "39111818" },
      { "name": "Lamp safety fitting", "code": "39111819" },
      { "name": "Lamp wiring assembly kit", "code": "39111820" },
      { "name": "Lighting retrofit kit", "code": "39111821" },
      { "name": "Lighting wall bracket", "code": "39111822" },
      { "name": "Lighting post cap", "code": "39111823" },
      { "name": "Lighting lens diffuser", "code": "39111824" },
      { "name": "Lamp swing gate", "code": "39111825" },
      { "name": "Lighting cord set", "code": "39111826" },
      { "name": "Lighting reflector or reflecting baffle", "code": "39111827" },
      { "name": "Lighting fixture door", "code": "39111828" },
      { "name": "Lighting channel cover", "code": "39111829" },
      { "name": "Lighting cross arm", "code": "39111830" },
      {
        "name": "Suspended lighting fixture mounting accessories",
        "code": "39111831"
      },
      {
        "name": "Special environment fixtures and accessories",
        "code": "39111900"
      },
      { "name": "Longwall lighting", "code": "39111903" },
      { "name": "Marine location fixture", "code": "39111904" },
      { "name": "Clean room light fixture", "code": "39111905" },
      { "name": "Rough service light fixture", "code": "39111906" },
      { "name": "Vapor tight light fixture", "code": "39111907" },
      { "name": "Vandal resistant light fixture", "code": "39111908" },
      {
        "name": "Special environment lighting fixture accessory",
        "code": "39111909"
      },
      { "name": "Festive or christmas tree lighting", "code": "39111910" },
      { "name": "Ceiling fan light kit", "code": "39111911" },
      {
        "name": "Portable and Temporary Lighting and accessories",
        "code": "39112000"
      },
      { "name": "Light tower", "code": "39112001" },
      { "name": "Light cart", "code": "39112002" },
      { "name": "Light stand", "code": "39112003" },
      { "name": "Loading dock light", "code": "39112004" },
      { "name": "Portable fluorescent light", "code": "39112005" },
      { "name": "Portable hand lamp", "code": "39112006" },
      {
        "name": "Portable high intensity discharge HID light",
        "code": "39112007"
      },
      { "name": "Portable incandescent light", "code": "39112008" },
      { "name": "Portable shop light", "code": "39112009" },
      { "name": "Rope light", "code": "39112010" },
      { "name": "String or tree light", "code": "39112011" },
      { "name": "Hand or extension light", "code": "39112012" },
      {
        "name": "Portable and temporary lighting accessory",
        "code": "39112013"
      },
      { "name": "Optical lighting", "code": "39112100" },
      { "name": "Fiber optic lighting", "code": "39112101" },
      { "name": "Light emitting diode LED optic lighting", "code": "39112102" },
      { "name": "Light emitting diode LED driver", "code": "39112103" },
      { "name": "Special effects devices", "code": "39112200" },
      { "name": "Atmospheric effect apparatus", "code": "39112201" },
      { "name": "Bubble machine", "code": "39112202" },
      { "name": "Confetti machine", "code": "39112203" },
      { "name": "Foaming machine", "code": "39112204" },
      { "name": "Smoke machine", "code": "39112205" },
      { "name": "Snow machine", "code": "39112206" },
      {
        "name": "Stage and studio lighting and accessories",
        "code": "39112300"
      },
      { "name": "Color changer", "code": "39112301" },
      { "name": "Color filters", "code": "39112302" },
      { "name": "Filter holders and frames", "code": "39112303" },
      { "name": "Gobo holders", "code": "39112304" },
      {
        "name": "Gobo rotators and light moving effect accessories",
        "code": "39112305"
      },
      { "name": "Iris diaphragm", "code": "39112306" },
      { "name": "Lighting bars", "code": "39112307" },
      { "name": "Spot chairs", "code": "39112308" },
      { "name": "Mirror ball or disco ball", "code": "39112309" },
      {
        "name": "Stage and studio lighting dimming and control devices",
        "code": "39112400"
      },
      { "name": "Dimmers and accessories", "code": "39112401" },
      {
        "name": "Lighting control consoles and accessories",
        "code": "39112402"
      },
      { "name": "Lighting power supply and control units", "code": "39112403" },
      { "name": "Stage and studio luminaires", "code": "39112500" },
      { "name": "Focus spots", "code": "39112501" },
      { "name": "Follow spots", "code": "39112502" },
      { "name": "Moving lights", "code": "39112503" },
      { "name": "Par cans and pin spots", "code": "39112504" },
      { "name": "Profile type luminaires", "code": "39112505" },
      { "name": "Spot banks", "code": "39112506" },
      { "name": "Strip lights", "code": "39112507" },
      { "name": "Ultraviolet and black light luminaires", "code": "39112508" },
      { "name": "Non electrical lighting devices", "code": "39112600" },
      { "name": "Alcohol lamp", "code": "39112601" },
      { "name": "Candle holder", "code": "39112602" },
      {
        "name": "Kerosene or propane or natural gas or butane lantern",
        "code": "39112603"
      },
      { "name": "Wax candle", "code": "39112604" },
      {
        "name": "Electrical equipment and components and supplies",
        "code": "39120000"
      },
      { "name": "Power conditioning equipment", "code": "39121000" },
      { "name": "Distribution power transformers", "code": "39121001" },
      { "name": "Power supply transformers", "code": "39121002" },
      { "name": "Instrument transformers", "code": "39121003" },
      { "name": "Power supply units", "code": "39121004" },
      { "name": "Power adapters or inverters", "code": "39121006" },
      { "name": "Frequency converters", "code": "39121007" },
      { "name": "Signal converters", "code": "39121008" },
      { "name": "Electrical or power regulators", "code": "39121009" },
      { "name": "Magnetic coils", "code": "39121010" },
      { "name": "Uninterruptible power supply UPS", "code": "39121011" },
      { "name": "Chokes", "code": "39121012" },
      { "name": "Electric rotary converters", "code": "39121013" },
      { "name": "Capacitor banks", "code": "39121014" },
      { "name": "Reactors", "code": "39121015" },
      { "name": "Slip rings", "code": "39121016" },
      { "name": "Power distribution units PDUs", "code": "39121017" },
      { "name": "Intrinsic safety barriers", "code": "39121018" },
      { "name": "Inductive coupling devices", "code": "39121019" },
      { "name": "Signal conditioners", "code": "39121020" },
      { "name": "Servo drives", "code": "39121021" },
      { "name": "Electronic transformers", "code": "39121022" },
      { "name": "Static var compensators", "code": "39121023" },
      { "name": "Synchronous condensers", "code": "39121024" },
      { "name": "Buck boost transformer", "code": "39121025" },
      { "name": "Control power transformer", "code": "39121026" },
      { "name": "Encapsulated transformer", "code": "39121027" },
      { "name": "Harmonic mitigation transformer", "code": "39121028" },
      { "name": "Isolation transformer", "code": "39121029" },
      { "name": "Pad mount transformer", "code": "39121030" },
      { "name": "Power supply outlet strip", "code": "39121031" },
      { "name": "Current transformer", "code": "39121032" },
      { "name": "Potential transformer", "code": "39121033" },
      { "name": "Zero phase current transformer", "code": "39121034" },
      { "name": "Motor starting compensator", "code": "39121035" },
      { "name": "Radio frequency RF transformer", "code": "39121036" },
      { "name": "Limiting transformer", "code": "39121037" },
      { "name": "Deflecting yoke", "code": "39121038" },
      { "name": "Fuel cell power supply", "code": "39121039" },
      { "name": "Electric power saver", "code": "39121040" },
      { "name": "Constant voltage transformer", "code": "39121041" },
      { "name": "Filament transformer", "code": "39121042" },
      { "name": "Rotary transformer", "code": "39121043" },
      { "name": "Audio frequency transformer", "code": "39121044" },
      { "name": "Modulation transformer", "code": "39121045" },
      { "name": "Matching transformer", "code": "39121046" },
      { "name": "Intermediate frequency transformer", "code": "39121047" },
      {
        "name": "Distribution and control centers and accessories",
        "code": "39121100"
      },
      { "name": "Load centers", "code": "39121101" },
      { "name": "Meter centers or sockets", "code": "39121102" },
      { "name": "Panelboards", "code": "39121103" },
      { "name": "Motor control centers", "code": "39121104" },
      { "name": "Switchgear systems", "code": "39121105" },
      { "name": "Power monitoring or control systems", "code": "39121106" },
      { "name": "Lighting control systems", "code": "39121107" },
      { "name": "Distribution or control board fixtures", "code": "39121108" },
      { "name": "Transmission transformers", "code": "39121109" },
      { "name": "Circuit breaker switchboard", "code": "39121110" },
      { "name": "Fusible switchboard", "code": "39121111" },
      {
        "name": "Low voltage alternating and direct current AC DC panelboard",
        "code": "39121112"
      },
      { "name": "Low voltage motor control center", "code": "39121113" },
      { "name": "Medium voltage motor control center", "code": "39121114" },
      { "name": "Medium voltage switchgear", "code": "39121115" },
      { "name": "Low voltage switchgear", "code": "39121116" },
      { "name": "Buss bar", "code": "39121117" },
      {
        "name": "Electrical boxes and enclosures and fittings and accessories",
        "code": "39121300"
      },
      { "name": "Control board enclosure", "code": "39121301" },
      { "name": "Enclosure plates or covers", "code": "39121302" },
      { "name": "Electrical boxes", "code": "39121303" },
      { "name": "Electrical box covers", "code": "39121304" },
      { "name": "Weatherproof boxes", "code": "39121305" },
      { "name": "Switch box", "code": "39121306" },
      { "name": "Floor boxes", "code": "39121307" },
      { "name": "Outlet box", "code": "39121308" },
      { "name": "Specialty electrical boxes", "code": "39121309" },
      { "name": "Utility boxes", "code": "39121310" },
      { "name": "Electrical fittings", "code": "39121311" },
      { "name": "Electrical bushing", "code": "39121312" },
      { "name": "Ceiling flanges", "code": "39121313" },
      { "name": "Explosion proof enclosure", "code": "39121314" },
      { "name": "Cast device box", "code": "39121315" },
      { "name": "Ceiling box", "code": "39121316" },
      { "name": "Ceiling pan", "code": "39121317" },
      { "name": "Circuit breaker enclosure", "code": "39121318" },
      { "name": "Current transformer enclosure", "code": "39121319" },
      { "name": "Electrical box extension", "code": "39121320" },
      { "name": "Electrical Box Hardware and Accessories", "code": "39121321" },
      { "name": "Electrical box partition", "code": "39121322" },
      {
        "name": "Electrical console and consolet enclosure",
        "code": "39121323"
      },
      { "name": "Electrical general purpose enclosure", "code": "39121324" },
      {
        "name": "Electrical operator interface unit enclosure",
        "code": "39121325"
      },
      { "name": "Electrical pushbutton enclosure", "code": "39121326" },
      { "name": "Electrical terminal enclosure", "code": "39121327" },
      { "name": "Fire rated poke through box", "code": "39121328" },
      { "name": "Fuse cabinet", "code": "39121329" },
      { "name": "Gang box", "code": "39121330" },
      { "name": "Hazardous location box", "code": "39121331" },
      { "name": "Electrical junction box", "code": "39121332" },
      { "name": "Masonry box", "code": "39121333" },
      { "name": "Modular electrical enclosure", "code": "39121334" },
      { "name": "Telephone termination cabinet", "code": "39121335" },
      { "name": "Underground electrical enclosure", "code": "39121336" },
      { "name": "Electrical sealing compound", "code": "39121337" },
      { "name": "Electrical lugs plugs and connectors", "code": "39121400" },
      { "name": "Electrical plugs", "code": "39121402" },
      { "name": "Locking plug", "code": "39121403" },
      { "name": "Electrical sleeves", "code": "39121404" },
      { "name": "Cable or wire lug", "code": "39121405" },
      { "name": "Electrical receptacles", "code": "39121406" },
      { "name": "Terminal strips", "code": "39121407" },
      { "name": "Mechanical connectors", "code": "39121408" },
      { "name": "Wire connectors", "code": "39121409" },
      { "name": "Terminal blocks", "code": "39121410" },
      { "name": "Backshell connectors", "code": "39121412" },
      { "name": "Circular connectors", "code": "39121413" },
      { "name": "Coaxial connectors", "code": "39121414" },
      { "name": "Flat connectors", "code": "39121415" },
      { "name": "Electronic connector caps", "code": "39121416" },
      { "name": "Flex connectors", "code": "39121419" },
      { "name": "Liquid tight connectors", "code": "39121420" },
      { "name": "Electrical connector assembly", "code": "39121421" },
      { "name": "Spring jaw connectors", "code": "39121423" },
      { "name": "Terminal block covers", "code": "39121424" },
      { "name": "Terminal block separator", "code": "39121425" },
      { "name": "Jumper bar", "code": "39121426" },
      { "name": "Wiring taps", "code": "39121427" },
      { "name": "Electrical taps", "code": "39121428" },
      { "name": "Cable gland connector", "code": "39121431" },
      { "name": "Electrical terminals", "code": "39121432" },
      { "name": "Radio frequency RF connectors", "code": "39121433" },
      {
        "name": "Electrical metallic tubing EMT connectors",
        "code": "39121434"
      },
      { "name": "Connecting leads or wires", "code": "39121435" },
      { "name": "Electrodes", "code": "39121436" },
      { "name": "Current collector shoes", "code": "39121437" },
      { "name": "Automatic wire or cable connector", "code": "39121438" },
      { "name": "Electrical extension cable", "code": "39121440" },
      { "name": "Electrical jumper cable", "code": "39121441" },
      { "name": "Electrical port assembly", "code": "39121442" },
      { "name": "Electrical underground bus connector", "code": "39121443" },
      { "name": "RJ jack and module", "code": "39121444" },
      { "name": "Twist on wire connector", "code": "39121445" },
      { "name": "Wire terminal connector kit", "code": "39121446" },
      { "name": "Wire terminal disconnect", "code": "39121447" },
      { "name": "Wire or cable compression connector", "code": "39121448" },
      { "name": "Wire or cable compression splice", "code": "39121449" },
      { "name": "Hammer head", "code": "39121450" },
      { "name": "Tap off", "code": "39121451" },
      { "name": "Cable joint", "code": "39121452" },
      { "name": "Crab joint", "code": "39121453" },
      { "name": "Battery terminal", "code": "39121454" },
      { "name": "Electrical insertion mold connector", "code": "39121455" },
      { "name": "Substation connector", "code": "39121456" },
      { "name": "Exothermic connector", "code": "39121457" },
      { "name": "Wire or cable compression reducer", "code": "39121458" },
      {
        "name": "Printed circuit board PCB press fit header connector",
        "code": "39121459"
      },
      { "name": "Filtered header connector", "code": "39121460" },
      { "name": "Connector and cable seal", "code": "39121461" },
      { "name": "Telecommunication connector", "code": "39121462" },
      { "name": "Jack for plug", "code": "39121463" },
      { "name": "Electrical metallic tubing EMT elbow", "code": "39121464" },
      { "name": "Flexible braid", "code": "39121465" },
      { "name": "Mechanical connector lug and cap", "code": "39121466" },
      { "name": "Mechanical connector plug and cap", "code": "39121467" },
      { "name": "Input output connector cover", "code": "39121468" },
      { "name": "Electrical controls and accessories", "code": "39121500" },
      { "name": "Motor starter controls", "code": "39121521" },
      { "name": "Electrical contacts", "code": "39121522" },
      { "name": "Timer controls", "code": "39121523" },
      { "name": "Photocontrols", "code": "39121524" },
      { "name": "Encoders", "code": "39121527" },
      { "name": "Photoelectric sensors", "code": "39121528" },
      { "name": "Contactors", "code": "39121529" },
      { "name": "Indicator or pilot lights", "code": "39121534" },
      { "name": "Indicator light parts or accessories", "code": "39121544" },
      { "name": "Emergency stop device", "code": "39121545" },
      { "name": "Reflector parts", "code": "39121551" },
      { "name": "Electrical control modules", "code": "39121552" },
      { "name": "Counter control", "code": "39121555" },
      { "name": "Pendant control station", "code": "39121561" },
      { "name": "Surface mount control station", "code": "39121565" },
      { "name": "Shunt reactor", "code": "39121568" },
      { "name": "Series reactor", "code": "39121569" },
      { "name": "Recloser", "code": "39121570" },
      {
        "name": "Circuit protection devices and accessories",
        "code": "39121600"
      },
      { "name": "Circuit breakers", "code": "39121601" },
      { "name": "Magnetic circuit breakers", "code": "39121602" },
      { "name": "Miniature circuit breakers", "code": "39121603" },
      { "name": "Time delay fuses", "code": "39121604" },
      { "name": "Plug fuses", "code": "39121605" },
      { "name": "Cartridge fuses", "code": "39121606" },
      { "name": "Glass body fuses", "code": "39121607" },
      { "name": "Midget fuses", "code": "39121609" },
      { "name": "Surge suppressers", "code": "39121610" },
      { "name": "Ceramic fuses", "code": "39121611" },
      { "name": "Blade fuses", "code": "39121612" },
      { "name": "Grounding devices or assemblies", "code": "39121613" },
      { "name": "Earth leakage circuit breakers", "code": "39121614" },
      { "name": "Air circuit breakers", "code": "39121615" },
      { "name": "Molded case circuit breakers", "code": "39121616" },
      { "name": "Fuse parts or accessories", "code": "39121617" },
      { "name": "Fuse wire", "code": "39121618" },
      { "name": "Diazed or bottle fuses", "code": "39121619" },
      { "name": "Transient protection materials", "code": "39121620" },
      {
        "name": "Lightning protection apparatus and accessories",
        "code": "39121621"
      },
      { "name": "Automotive fuse", "code": "39121622" },
      { "name": "Electronic fuse", "code": "39121623" },
      { "name": "Fuse holder", "code": "39121624" },
      { "name": "High speed fuse", "code": "39121625" },
      { "name": "Medium voltage fuse", "code": "39121626" },
      { "name": "Power fuse", "code": "39121627" },
      { "name": "Semiconductor fuse", "code": "39121628" },
      { "name": "Fuse block", "code": "39121629" },
      { "name": "Fuse clip", "code": "39121630" },
      { "name": "High voltage oil filled circuit breaker", "code": "39121631" },
      { "name": "Network protector", "code": "39121632" },
      { "name": "Circuit interruptor", "code": "39121633" },
      { "name": "Surge arrestor", "code": "39121634" },
      { "name": "Voltage regulator", "code": "39121635" },
      { "name": "Current limiter", "code": "39121636" },
      { "name": "Arc suppressor", "code": "39121637" },
      { "name": "Fuse cutout", "code": "39121638" },
      { "name": "Vacuum interruptor", "code": "39121639" },
      { "name": "Hydraulic circuit breaker", "code": "39121640" },
      { "name": "Hook fuse", "code": "39121641" },
      { "name": "Screw fuse", "code": "39121642" },
      { "name": "Surge protector panel", "code": "39121643" },
      { "name": "High speed circuit breaker", "code": "39121644" },
      { "name": "Oil circuit breaker", "code": "39121645" },
      { "name": "Gas circuit breaker", "code": "39121646" },
      { "name": "Electrostatic discharger", "code": "39121647" },
      { "name": "Earth additive", "code": "39121648" },
      { "name": "Electrical hardware and supplies", "code": "39121700" },
      { "name": "Electrical hangers", "code": "39121701" },
      { "name": "Cable clips", "code": "39121702" },
      { "name": "Cable ties", "code": "39121703" },
      { "name": "Wallplates", "code": "39121704" },
      { "name": "Cable clamp and staple", "code": "39121705" },
      { "name": "Transformer bushings", "code": "39121706" },
      { "name": "Harness board nails", "code": "39121707" },
      { "name": "Din rail", "code": "39121708" },
      { "name": "Handle tie", "code": "39121709" },
      { "name": "Electrical receptacle multipliers", "code": "39121710" },
      { "name": "Cable tie mounts", "code": "39121717" },
      { "name": "Cable splicing kits", "code": "39121718" },
      { "name": "Strain reliefs", "code": "39121719" },
      { "name": "Transformer handles", "code": "39121720" },
      { "name": "Electrical insulators", "code": "39121721" },
      { "name": "Wire and cable pulling device", "code": "39121722" },
      { "name": "Heat shrinkable tube", "code": "39121723" },
      { "name": "Connector mounting hardware", "code": "39121724" },
      { "name": "Electrical cable grip", "code": "39121725" },
      { "name": "Electrical cable lasher", "code": "39121726" },
      { "name": "Electrical insulator rod", "code": "39121727" },
      { "name": "Insulating tube", "code": "39121728" },
      { "name": "Electrical lead set", "code": "39121729" },
      { "name": "Electrical rosette", "code": "39121730" },
      { "name": "Cable tie kit", "code": "39121731" },
      { "name": "Intelligent Building Installations IBI", "code": "39121800" },
      { "name": "Controlling units or devices", "code": "39121801" },
      { "name": "Inactive units or devices", "code": "39121802" },
      { "name": "Building environmental control system", "code": "39121803" },
      {
        "name": "Electrical safety devices and accessories",
        "code": "39121900"
      },
      { "name": "Circuit breaker lockout", "code": "39121901" },
      { "name": "Lockout enclosure", "code": "39121902" },
      { "name": "Lockout hasp and padlock", "code": "39121903" },
      { "name": "Lockout kit and station", "code": "39121904" },
      { "name": "Multiple Lockout Device", "code": "39121905" },
      { "name": "Plug and cord lockout", "code": "39121906" },
      { "name": "Receptacle Blockout", "code": "39121907" },
      { "name": "Switch lockout", "code": "39121908" },
      { "name": "Valve lockout", "code": "39121909" },
      { "name": "Voltage marker", "code": "39121910" },
      { "name": "Watertight locking outlet and cover", "code": "39121911" },
      { "name": "Fuse lockout", "code": "39121912" },
      { "name": "Electrical Variable Speed Drives", "code": "39122000" },
      { "name": "Inverter drive AC", "code": "39122001" },
      { "name": "Motor control drive DC", "code": "39122002" },
      { "name": "Servo control drive", "code": "39122003" },
      { "name": "Inverter drive parts and accessories", "code": "39122004" },
      { "name": "Inverter drive output filter", "code": "39122005" },
      {
        "name": "Electrical transmission and distribution equipment",
        "code": "39122100"
      },
      { "name": "Cable pothead", "code": "39122101" },
      { "name": "Tap changer", "code": "39122102" },
      { "name": "Utility pole", "code": "39122103" },
      { "name": "Guy wire", "code": "39122104" },
      { "name": "Electrical spacer", "code": "39122105" },
      { "name": "Brace for crossarm", "code": "39122106" },
      { "name": "Wood wiring block", "code": "39122107" },
      { "name": "Guy wire anchor rod", "code": "39122108" },
      { "name": "Square type steel crossarm", "code": "39122109" },
      {
        "name": "Connecting washer for electric overhead line",
        "code": "39122110"
      },
      {
        "name": "Sectioning device on the electric car line",
        "code": "39122111"
      },
      { "name": "Insulator set eye", "code": "39122112" },
      { "name": "Utility pole crossarm", "code": "39122113" },
      { "name": "Electrical bus way tap or bus tap", "code": "39122114" },
      { "name": "Electrical armor rod", "code": "39122115" },
      { "name": "Overhead line link fitting", "code": "39122116" },
      { "name": "Strain pole suspension yoke", "code": "39122117" },
      { "name": "Electrical wire block connector", "code": "39122118" },
      { "name": "Power transmission steel tower", "code": "39122119" },
      { "name": "Utility pole band", "code": "39122120" },
      { "name": "Electrical switches and accessories", "code": "39122200" },
      { "name": "Code switch", "code": "39122201" },
      { "name": "Knife switch", "code": "39122202" },
      { "name": "Sensitive switch", "code": "39122203" },
      { "name": "Tumble switch", "code": "39122204" },
      { "name": "Safety switch", "code": "39122205" },
      { "name": "Dimmer switch", "code": "39122206" },
      { "name": "Drum switch", "code": "39122207" },
      { "name": "Time switch", "code": "39122208" },
      { "name": "Snap switch", "code": "39122209" },
      { "name": "Pressure switch", "code": "39122210" },
      { "name": "Toggle switch", "code": "39122211" },
      { "name": "Slide switch", "code": "39122212" },
      { "name": "Limit switch", "code": "39122213" },
      { "name": "Controller switch", "code": "39122214" },
      { "name": "Variable switch", "code": "39122215" },
      { "name": "Push button switch", "code": "39122216" },
      { "name": "Rotary switch", "code": "39122217" },
      { "name": "Non fusible switch", "code": "39122218" },
      { "name": "Level or float switch", "code": "39122219" },
      { "name": "Radio frequency RF switch", "code": "39122220" },
      { "name": "Switch part or accessory", "code": "39122221" },
      { "name": "Foot switch", "code": "39122222" },
      { "name": "Flow switch", "code": "39122223" },
      { "name": "Keylock switch", "code": "39122224" },
      { "name": "Mercury switch", "code": "39122225" },
      { "name": "Rocker switch", "code": "39122226" },
      { "name": "Joystick switch or control", "code": "39122227" },
      { "name": "Vacuum switch", "code": "39122228" },
      { "name": "Temperature switch", "code": "39122229" },
      { "name": "Proximity switch", "code": "39122230" },
      { "name": "Combination device switch", "code": "39122231" },
      { "name": "Combination switch", "code": "39122232" },
      { "name": "Disconnect switch", "code": "39122233" },
      { "name": "Locking switch", "code": "39122234" },
      { "name": "Modular wiring system switch", "code": "39122235" },
      { "name": "Occupancy or motion sensing switch", "code": "39122236" },
      { "name": "Panel mount switch", "code": "39122237" },
      { "name": "Photocell switch", "code": "39122238" },
      { "name": "Pull chain switch", "code": "39122239" },
      { "name": "Speed switch", "code": "39122240" },
      { "name": "Reed switch", "code": "39122241" },
      { "name": "Ground switch", "code": "39122242" },
      { "name": "Micro switch", "code": "39122243" },
      { "name": "Cutout switch", "code": "39122244" },
      { "name": "Magnetic switch", "code": "39122245" },
      { "name": "Electrical potentiometer switch", "code": "39122246" },
      { "name": "Automotive HVAC potentiometer switch", "code": "39122247" },
      { "name": "Power signal distribution switch", "code": "39122248" },
      { "name": "Steering wheel control switch", "code": "39122249" },
      {
        "name": "Automatic transmission gear selector switch",
        "code": "39122250"
      },
      { "name": "Detent switch", "code": "39122251" },
      { "name": "Electrical relays and accessories", "code": "39122300" },
      { "name": "Buchholtz relay", "code": "39122301" },
      { "name": "Distance relay", "code": "39122302" },
      { "name": "Multicontact relay", "code": "39122303" },
      { "name": "Reed relay", "code": "39122304" },
      { "name": "Meter relay", "code": "39122305" },
      { "name": "Directional ground relay", "code": "39122306" },
      { "name": "Auxiliary relay", "code": "39122307" },
      { "name": "Ratio differential relay", "code": "39122308" },
      { "name": "Unground relay", "code": "39122309" },
      { "name": "Selecting ground relay", "code": "39122310" },
      { "name": "Pressure relay", "code": "39122311" },
      { "name": "Polarity relay", "code": "39122312" },
      { "name": "Trip free relay", "code": "39122313" },
      { "name": "Blocking relay", "code": "39122314" },
      { "name": "Electromagnetic relay", "code": "39122315" },
      { "name": "Reclosing relay", "code": "39122316" },
      { "name": "Latching relay", "code": "39122317" },
      { "name": "Frequency relay", "code": "39122318" },
      { "name": "Direct current relay", "code": "39122319" },
      { "name": "Horizontal relay", "code": "39122320" },
      { "name": "Non polarized relay", "code": "39122321" },
      { "name": "Direct current voltage relay", "code": "39122322" },
      { "name": "Multi function digital relay", "code": "39122323" },
      { "name": "Power relay", "code": "39122324" },
      { "name": "General purpose relay", "code": "39122325" },
      { "name": "Socket relay", "code": "39122326" },
      { "name": "Alternating voltage relay", "code": "39122327" },
      { "name": "Mercury relay", "code": "39122328" },
      { "name": "Time relay", "code": "39122329" },
      { "name": "Overload relay", "code": "39122330" },
      { "name": "Control relay", "code": "39122331" },
      { "name": "Phase failure relay", "code": "39122332" },
      { "name": "Solid state relay", "code": "39122333" },
      { "name": "Relay board or multiple relay module", "code": "39122334" },
      { "name": "Relay socket or base", "code": "39122335" },
      { "name": "Turn signal flasher relay", "code": "39122336" },
      { "name": "Printed circuit board relay", "code": "39122337" },
      {
        "name": "Electrical wire management devices and accessories and supplies",
        "code": "39130000"
      },
      { "name": "Wire Markers and Wire Marking Devices", "code": "39131500" },
      { "name": "Clip on wire marker", "code": "39131501" },
      { "name": "Heat shrink wire marker", "code": "39131502" },
      { "name": "Slip on wire marker", "code": "39131503" },
      { "name": "Wire identification marker", "code": "39131504" },
      { "name": "Wire labeling tool and printer", "code": "39131505" },
      { "name": "Wire marker book", "code": "39131506" },
      { "name": "Wire marker card", "code": "39131507" },
      { "name": "Wire marker roll and dispenser", "code": "39131508" },
      { "name": "Write on wire marker", "code": "39131509" },
      { "name": "Wire protection devices", "code": "39131600" },
      { "name": "Corrugated loom tubing", "code": "39131601" },
      { "name": "Expandable braided sleeving", "code": "39131602" },
      { "name": "Grommet edging", "code": "39131603" },
      { "name": "Spiral wrapping", "code": "39131604" },
      { "name": "Wire Floor Track or Guard", "code": "39131605" },
      { "name": "Wire guard nail plate", "code": "39131606" },
      { "name": "Wire Lacing Cord", "code": "39131607" },
      { "name": "Duct sealing system", "code": "39131608" },
      { "name": "Cable protection plate", "code": "39131609" },
      { "name": "Wire Raceways Conduit and Busways", "code": "39131700" },
      { "name": "Busway", "code": "39131701" },
      { "name": "Busway fitting and accessories", "code": "39131702" },
      { "name": "Cable ladder", "code": "39131703" },
      { "name": "Cable tray", "code": "39131704" },
      { "name": "Cable tray fitting and accessories", "code": "39131705" },
      { "name": "Electrical conduit", "code": "39131706" },
      { "name": "Electrical conduit coupling", "code": "39131707" },
      { "name": "Electrical conduit fitting body", "code": "39131708" },
      { "name": "Electrical wire or cable raceway", "code": "39131709" },
      { "name": "Wireway", "code": "39131710" },
      { "name": "Wireway fitting and accessories", "code": "39131711" },
      { "name": "Wiring duct", "code": "39131712" },
      { "name": "Wiring duct fitting and accessories", "code": "39131713" },
      { "name": "Wiring trough", "code": "39131714" },
      { "name": "Underground hose conduit", "code": "39131715" },
      { "name": "Cable handler", "code": "39131716" },
      { "name": "Electrical conduit elbow", "code": "39131717" },
      { "name": "Electrical conduit nipple", "code": "39131718" },
      { "name": "Electrical metallic tubing EMT", "code": "39131719" },
      { "name": "Running thread conduit", "code": "39131720" }
    ]
  },
  {
    "name": "Distribution and Conditioning Systems and Equipment and Components",
    "code": "40000000",
    "subcategories": [
      {
        "name": "Heating and ventilation and air circulation",
        "code": "40100000"
      },
      { "name": "Ventilation", "code": "40101500" },
      { "name": "Air collectors", "code": "40101501" },
      { "name": "Air exhausters", "code": "40101502" },
      { "name": "Vents", "code": "40101503" },
      { "name": "Ventilation dampers", "code": "40101504" },
      { "name": "Air diffusers", "code": "40101505" },
      { "name": "Ventilation pipes", "code": "40101506" },
      { "name": "Spiral ventilation tubing", "code": "40101507" },
      { "name": "Ventilation curtain", "code": "40101508" },
      { "name": "Ventilation spad", "code": "40101509" },
      { "name": "Ventilation tubing", "code": "40101510" },
      { "name": "Ventilation tube hanger", "code": "40101511" },
      { "name": "Ventilation tube fittings", "code": "40101512" },
      {
        "name": "Air circulation and parts and accessories",
        "code": "40101600"
      },
      { "name": "Blowers", "code": "40101601" },
      { "name": "Air circulators", "code": "40101602" },
      { "name": "Impellers", "code": "40101603" },
      { "name": "Fans", "code": "40101604" },
      { "name": "Fan guards or accessories", "code": "40101605" },
      { "name": "Ventilation check", "code": "40101606" },
      { "name": "Air volume control valve", "code": "40101607" },
      { "name": "Fan filter unit", "code": "40101608" },
      { "name": "Ceiling fan", "code": "40101609" },
      { "name": "Ceiling fan kit", "code": "40101610" },
      {
        "name": "Cooling equipment and parts and accessories",
        "code": "40101700"
      },
      { "name": "Air conditioners", "code": "40101701" },
      { "name": "Cooling exchangers", "code": "40101702" },
      { "name": "Evaporative coolers", "code": "40101703" },
      { "name": "Condensing units", "code": "40101704" },
      { "name": "Capillary tube assemblies", "code": "40101705" },
      { "name": "Air conditioner shutters", "code": "40101706" },
      { "name": "Cooling tower accessories", "code": "40101707" },
      { "name": "Fan coil unit", "code": "40101708" },
      { "name": "Air handling unit", "code": "40101709" },
      { "name": "Reciprocating chiller", "code": "40101710" },
      { "name": "Centrifugal liquid chiller", "code": "40101711" },
      { "name": "Screw chiller", "code": "40101712" },
      { "name": "Absorption chiller", "code": "40101713" },
      { "name": "Absorption chiller heater", "code": "40101714" },
      {
        "name": "Constant temperature and humidity chamber",
        "code": "40101715"
      },
      { "name": "Cooling tower", "code": "40101716" },
      { "name": "Air conditioner power saver", "code": "40101717" },
      { "name": "Cooling coil", "code": "40101718" },
      { "name": "Liquid refrigerant receiver", "code": "40101719" },
      { "name": "Unit cooler", "code": "40101720" },
      { "name": "Water cooled condensor", "code": "40101721" },
      { "name": "Steam condenser", "code": "40101722" },
      { "name": "Thermoelectric cooler", "code": "40101723" },
      {
        "name": "Heating equipment and parts and accessories",
        "code": "40101800"
      },
      { "name": "Radiators", "code": "40101801" },
      { "name": "Heat exchangers", "code": "40101802" },
      { "name": "Furnaces", "code": "40101805" },
      { "name": "Heat pumps", "code": "40101806" },
      { "name": "Solar heating units", "code": "40101807" },
      { "name": "Heating stoves", "code": "40101808" },
      { "name": "Circulation heaters", "code": "40101809" },
      { "name": "Coil duct heaters", "code": "40101810" },
      { "name": "Convection heaters", "code": "40101811" },
      { "name": "Divided exchangers", "code": "40101812" },
      { "name": "Double split exchangers", "code": "40101813" },
      { "name": "Finned tubular heaters", "code": "40101814" },
      { "name": "Immersion heaters", "code": "40101815" },
      { "name": "Kettle exchangers", "code": "40101816" },
      { "name": "One pass exchangers", "code": "40101817" },
      { "name": "Process air heaters", "code": "40101818" },
      { "name": "Space heaters", "code": "40101819" },
      { "name": "Split exchangers", "code": "40101820" },
      { "name": "Strip heaters", "code": "40101821" },
      { "name": "Tubular heaters", "code": "40101822" },
      { "name": "Two pass exchangers", "code": "40101823" },
      { "name": "Quartz heater", "code": "40101824" },
      { "name": "Domestic water heaters", "code": "40101825" },
      { "name": "Commercial water heaters", "code": "40101826" },
      { "name": "Ceramic fiber heaters", "code": "40101827" },
      { "name": "Cartridge heaters", "code": "40101828" },
      { "name": "Band heaters", "code": "40101829" },
      { "name": "Heater elements", "code": "40101830" },
      { "name": "Induction heaters", "code": "40101831" },
      { "name": "Doors for heating equipment", "code": "40101832" },
      { "name": "Boiler or heater igniter", "code": "40101833" },
      { "name": "Burners", "code": "40101834" },
      { "name": "Plate heat exchanger", "code": "40101835" },
      { "name": "Shell and tube heat exchanger", "code": "40101836" },
      { "name": "Chimney", "code": "40101837" },
      { "name": "Economizer", "code": "40101838" },
      { "name": "Baffle plate", "code": "40101839" },
      { "name": "Film heater", "code": "40101840" },
      { "name": "Air preheater", "code": "40101841" },
      { "name": "Ice melting device", "code": "40101842" },
      { "name": "Electric furnace", "code": "40101843" },
      {
        "name": "High frequency induction vacuum melting furnace",
        "code": "40101844"
      },
      { "name": "Reverberatory furnace", "code": "40101845" },
      { "name": "Salt bath furnace", "code": "40101846" },
      {
        "name": "Carburization and nitrification electric furnace",
        "code": "40101847"
      },
      { "name": "Forced air heating system", "code": "40101848" },
      { "name": "Hot water distribution header", "code": "40101849" },
      { "name": "Crematory", "code": "40101850" },
      { "name": "Boiler parts and accessories", "code": "40101851" },
      {
        "name": "Plate heat exchanger parts and accessories",
        "code": "40101852"
      },
      { "name": "Humidity control", "code": "40101900" },
      { "name": "Vaporizers", "code": "40101901" },
      { "name": "Dehumidifiers", "code": "40101902" },
      { "name": "Humidifiers", "code": "40101903" },
      { "name": "Boilers", "code": "40102000" },
      { "name": "Fire tube boilers", "code": "40102001" },
      { "name": "Water tube boiler", "code": "40102002" },
      { "name": "Electric boilers", "code": "40102003" },
      { "name": "Natural gas powered boilers", "code": "40102004" },
      { "name": "Propane gas powered boilers", "code": "40102005" },
      { "name": "Waste heat boiler", "code": "40102006" },
      { "name": "Mini oil boiler", "code": "40102007" },
      { "name": "Fireplaces and accessories", "code": "40102100" },
      { "name": "Wood fueled fireplace", "code": "40102101" },
      { "name": "Gas fueled fireplace B vent", "code": "40102102" },
      { "name": "Gas fueled fireplace vent direct", "code": "40102103" },
      { "name": "Gas fueled fireplace vent free", "code": "40102104" },
      { "name": "Electric fueled fireplace", "code": "40102105" },
      { "name": "Fireplace facing", "code": "40102106" },
      { "name": "Fireplace mantle surrounds", "code": "40102107" },
      { "name": "Fluid and gas distribution", "code": "40140000" },
      { "name": "Valves", "code": "40141600" },
      { "name": "Needle valves", "code": "40141602" },
      { "name": "Pneumatic valves", "code": "40141603" },
      { "name": "Safety valves", "code": "40141604" },
      { "name": "Solenoid valves", "code": "40141605" },
      { "name": "Relief valves", "code": "40141606" },
      { "name": "Ball valves", "code": "40141607" },
      { "name": "Hydraulic valves", "code": "40141608" },
      { "name": "Control valves", "code": "40141609" },
      { "name": "Float valves", "code": "40141610" },
      { "name": "Globe valves", "code": "40141611" },
      { "name": "Expansion valves", "code": "40141612" },
      { "name": "Gate valves", "code": "40141613" },
      { "name": "Flap valves", "code": "40141615" },
      { "name": "Valve parts or accessories", "code": "40141616" },
      { "name": "Angle globe valves", "code": "40141617" },
      { "name": "Ball check valves", "code": "40141618" },
      { "name": "Butterfly lug pattern valves", "code": "40141619" },
      { "name": "Butterfly wafer pattern valves", "code": "40141620" },
      { "name": "Diaphragm valves", "code": "40141621" },
      { "name": "Inline check valves", "code": "40141622" },
      { "name": "Knife gate valves", "code": "40141623" },
      { "name": "Lubricated plug valves", "code": "40141624" },
      { "name": "Mud or slush valves", "code": "40141625" },
      { "name": "Nonlubricated plug valves", "code": "40141626" },
      { "name": "Orifice valves", "code": "40141627" },
      { "name": "Pilot valves", "code": "40141628" },
      { "name": "Pinch valves", "code": "40141629" },
      { "name": "Piston check valves", "code": "40141630" },
      { "name": "Pump valves", "code": "40141631" },
      { "name": "Sentinel valves", "code": "40141632" },
      { "name": "Slider valves", "code": "40141633" },
      { "name": "Swing check valves", "code": "40141634" },
      { "name": "Turbine valves", "code": "40141635" },
      { "name": "Valve kits", "code": "40141636" },
      { "name": "Wafer check valves", "code": "40141637" },
      { "name": "Toggle valves", "code": "40141638" },
      { "name": "Toilet fill valve", "code": "40141639" },
      { "name": "Toilet flush valve", "code": "40141640" },
      { "name": "Poppet valve", "code": "40141641" },
      { "name": "Saddle valve", "code": "40141642" },
      { "name": "Foot valve", "code": "40141643" },
      { "name": "Rotary airlock valve", "code": "40141644" },
      { "name": "Valve stem", "code": "40141645" },
      { "name": "Valve seat", "code": "40141646" },
      { "name": "Segment valve", "code": "40141647" },
      { "name": "Magnetic valve", "code": "40141648" },
      { "name": "Directional control valve coil assembly", "code": "40141649" },
      { "name": "Compressed gas valve", "code": "40141650" },
      { "name": "Air valve", "code": "40141651" },
      { "name": "Pressure reducing valve", "code": "40141652" },
      { "name": "Sluice valve", "code": "40141653" },
      { "name": "Cock valve", "code": "40141654" },
      { "name": "Vacuum valve", "code": "40141655" },
      { "name": "Flange type butterfly valve", "code": "40141656" },
      { "name": "Fire sprinkler control valve", "code": "40141657" },
      { "name": "Block and bleed valve", "code": "40141658" },
      { "name": "Lift check valve", "code": "40141659" },
      { "name": "Hardware and fittings", "code": "40141700" },
      { "name": "Plumbing adapters", "code": "40141719" },
      { "name": "Plumbing connectors", "code": "40141720" },
      { "name": "Plumbing hangers", "code": "40141725" },
      { "name": "Hydrants", "code": "40141726" },
      { "name": "Plumbing vents", "code": "40141727" },
      { "name": "Nozzles", "code": "40141731" },
      { "name": "Plumbing spiders", "code": "40141732" },
      { "name": "Hose fitting", "code": "40141734" },
      { "name": "Funnels", "code": "40141735" },
      { "name": "Grease fitting", "code": "40141736" },
      { "name": "Diaphragms", "code": "40141737" },
      { "name": "Drain plugs", "code": "40141738" },
      { "name": "Drain covers", "code": "40141739" },
      { "name": "Fuel cocks", "code": "40141740" },
      { "name": "Orifice fittings", "code": "40141741" },
      { "name": "Atomizers", "code": "40141742" },
      { "name": "Nozzle tips or caps", "code": "40141743" },
      { "name": "Drain bowls", "code": "40141744" },
      { "name": "Fusible plugs", "code": "40141745" },
      { "name": "Sight glass", "code": "40141746" },
      { "name": "Grease trap", "code": "40141747" },
      { "name": "Vacuum breaker", "code": "40141749" },
      { "name": "Waste arm", "code": "40141750" },
      { "name": "Wall bend", "code": "40141751" },
      { "name": "Pipe sway brace", "code": "40141755" },
      { "name": "Pipe connection box accessory", "code": "40141756" },
      { "name": "Escutcheon", "code": "40141757" },
      { "name": "Drainage channel", "code": "40141758" },
      { "name": "Floor drain", "code": "40141759" },
      { "name": "Non freeze hydrant", "code": "40141760" },
      { "name": "Piping manifold", "code": "40141761" },
      { "name": "Water meter protection tub", "code": "40141762" },
      { "name": "Water hammer arrester", "code": "40141763" },
      { "name": "Hose assembly", "code": "40141764" },
      { "name": "Drain separator", "code": "40141765" },
      { "name": "Snap tap with saddle for water works", "code": "40141766" },
      { "name": "Pipe shoe", "code": "40141767" },
      { "name": "Pitless adapter", "code": "40141768" },
      { "name": "Valve box or valve room", "code": "40141769" },
      { "name": "Pipe fusion machine", "code": "40141770" },
      { "name": "Ducts", "code": "40141900" },
      { "name": "Flexible ducts", "code": "40141901" },
      { "name": "Rigid ducts", "code": "40141902" },
      { "name": "Magnesium ducts or ductwork", "code": "40141903" },
      { "name": "Ferrous alloy ducts or ductwork", "code": "40141904" },
      { "name": "Titanium ducts or ductwork", "code": "40141905" },
      { "name": "Tin ducts or ductwork", "code": "40141906" },
      { "name": "Brass ducts or ductwork", "code": "40141907" },
      { "name": "Lead ducts or ductwork", "code": "40141908" },
      { "name": "Bronze ducts or ductwork", "code": "40141909" },
      { "name": "Zinc ducts or ductwork", "code": "40141910" },
      { "name": "Steel ducts or ductwork", "code": "40141911" },
      { "name": "Iron ducts or ductwork", "code": "40141912" },
      { "name": "Cement ducts or ductwork", "code": "40141913" },
      { "name": "Plastics duct or ductwork", "code": "40141914" },
      { "name": "Rubber duct or ductwork", "code": "40141915" },
      { "name": "Glass ducts or ductwork", "code": "40141916" },
      { "name": "Stone ducts or ductwork", "code": "40141917" },
      { "name": "Non ferrous alloy ducts or ductwork", "code": "40141918" },
      { "name": "Aluminum ducts or ductwork", "code": "40141919" },
      { "name": "Stainless steel ducts or ductwork", "code": "40141920" },
      { "name": "Precious metal ducts or ductwork", "code": "40141921" },
      { "name": "Copper ducts or ductwork", "code": "40141922" },
      { "name": "Latex duct or ductwork", "code": "40141923" },
      { "name": "Nylon duct or ductwork", "code": "40141924" },
      { "name": "Hoses", "code": "40142000" },
      { "name": "Acid hoses", "code": "40142001" },
      { "name": "Air hoses", "code": "40142002" },
      { "name": "Drill hoses", "code": "40142003" },
      { "name": "Marine hoses", "code": "40142004" },
      { "name": "Material handling hoses", "code": "40142005" },
      { "name": "Oil hoses", "code": "40142006" },
      { "name": "Special hoses", "code": "40142007" },
      { "name": "Water hoses", "code": "40142008" },
      { "name": "Air water gas multipurpose hoses", "code": "40142009" },
      { "name": "Fluoropolymer lined hoses", "code": "40142010" },
      { "name": "Chemical hose", "code": "40142011" },
      { "name": "Food and beverage hose", "code": "40142012" },
      { "name": "Mining hose", "code": "40142013" },
      { "name": "Petroleum hose", "code": "40142014" },
      { "name": "Steam cleaning hose", "code": "40142015" },
      { "name": "Vacuum hose", "code": "40142016" },
      { "name": "Welding hose", "code": "40142017" },
      { "name": "Spray hose", "code": "40142018" },
      { "name": "Ducting hose", "code": "40142019" },
      { "name": "Hydraulic hose", "code": "40142020" },
      { "name": "Rock dust hose", "code": "40142021" },
      { "name": "Hose reel", "code": "40142022" },
      { "name": "Fluid and gas regulators", "code": "40142200" },
      { "name": "Gas regulators", "code": "40142201" },
      { "name": "Fluid regulators", "code": "40142202" },
      { "name": "Fluid regulator repair kits", "code": "40142203" },
      { "name": "Welding regulator oxygen", "code": "40142204" },
      { "name": "Welding regulator acetylene", "code": "40142205" },
      { "name": "Sluice gate", "code": "40142206" },
      { "name": "Water level regulator", "code": "40142207" },
      { "name": "Gas mixer", "code": "40142208" },
      { "name": "Traps and strainers", "code": "40142500" },
      { "name": "Liquid strainers", "code": "40142501" },
      { "name": "Liquid traps", "code": "40142502" },
      { "name": "Steam traps", "code": "40142503" },
      { "name": "Steam strainers", "code": "40142504" },
      { "name": "Y strainer", "code": "40142505" },
      { "name": "Tee strainer", "code": "40142506" },
      { "name": "Conical strainer", "code": "40142507" },
      { "name": "Basket strainer", "code": "40142508" },
      { "name": "Oil trap", "code": "40142509" },
      { "name": "Thermostatic steam trap", "code": "40142510" },
      { "name": "Thermodynamic steam trap", "code": "40142511" },
      { "name": "Breather", "code": "40142512" },
      { "name": "P trap", "code": "40142513" },
      { "name": "J trap", "code": "40142514" },
      { "name": "S trap", "code": "40142515" },
      { "name": "Industrial pumps and compressors", "code": "40150000" },
      { "name": "Pumps", "code": "40151500" },
      { "name": "Air pumps", "code": "40151501" },
      { "name": "Vacuum pumps", "code": "40151502" },
      { "name": "Centrifugal pumps", "code": "40151503" },
      { "name": "Circulating pumps", "code": "40151504" },
      { "name": "Dosing pumps", "code": "40151505" },
      { "name": "Hand pumps", "code": "40151506" },
      { "name": "Irrigation pumps", "code": "40151507" },
      { "name": "Mud pumps", "code": "40151508" },
      { "name": "Reciprocating pumps", "code": "40151509" },
      { "name": "Water pumps", "code": "40151510" },
      { "name": "Well pumps", "code": "40151511" },
      { "name": "Sump pumps", "code": "40151512" },
      { "name": "Submersible pumps", "code": "40151513" },
      { "name": "Steam pumps", "code": "40151514" },
      { "name": "Solenoid pumps", "code": "40151515" },
      { "name": "Shear pumps", "code": "40151516" },
      { "name": "Sewage pumps", "code": "40151517" },
      { "name": "Sealless pumps", "code": "40151518" },
      { "name": "Sanitary pumps", "code": "40151519" },
      { "name": "Sampling pumps", "code": "40151520" },
      { "name": "Rotary pumps", "code": "40151521" },
      { "name": "Reverse osmosis pumps", "code": "40151522" },
      { "name": "Positive displacement pumps", "code": "40151523" },
      { "name": "Oil pumps", "code": "40151524" },
      { "name": "Sludge pumps", "code": "40151525" },
      { "name": "Turbine pumps", "code": "40151526" },
      { "name": "Plunger pumps", "code": "40151527" },
      { "name": "Oscillating pumps", "code": "40151528" },
      { "name": "Drum pumps", "code": "40151529" },
      { "name": "Dredge pumps", "code": "40151530" },
      { "name": "Dewatering pumps", "code": "40151531" },
      { "name": "Fuel pumps", "code": "40151532" },
      { "name": "Hydraulic pumps", "code": "40151533" },
      { "name": "Cryogenic pumps", "code": "40151534" },
      { "name": "Axial split pumps", "code": "40151546" },
      { "name": "Deepwell pumps", "code": "40151547" },
      { "name": "Diaphragm pumps", "code": "40151548" },
      { "name": "Double diaphragm pumps", "code": "40151549" },
      { "name": "Duplex pumps", "code": "40151550" },
      { "name": "Gear pumps", "code": "40151551" },
      {
        "name": "Metering or injection or proportioning pumps",
        "code": "40151552"
      },
      { "name": "Progressive cavity pumps", "code": "40151553" },
      { "name": "Ram pumps", "code": "40151554" },
      { "name": "Rotary cam pumps", "code": "40151555" },
      { "name": "Rotary lobe pumps", "code": "40151556" },
      { "name": "Rotating piston pumps", "code": "40151557" },
      { "name": "Screw Pumps", "code": "40151558" },
      { "name": "Simplex pumps", "code": "40151559" },
      { "name": "Sliding vane pumps", "code": "40151560" },
      { "name": "Triplex pumps", "code": "40151561" },
      { "name": "Worm pumps", "code": "40151562" },
      { "name": "Fire pump sets", "code": "40151563" },
      { "name": "Chemical pumps", "code": "40151564" },
      { "name": "Macerator pump", "code": "40151565" },
      { "name": "Booster pump", "code": "40151566" },
      { "name": "High temperature pump", "code": "40151567" },
      { "name": "Pulp pump", "code": "40151568" },
      { "name": "Monoflex pump", "code": "40151569" },
      { "name": "Mixed flow pump", "code": "40151570" },
      { "name": "Inline pump", "code": "40151571" },
      { "name": "Air lift pump", "code": "40151572" },
      { "name": "Magnet pump", "code": "40151573" },
      { "name": "Ejector pump", "code": "40151574" },
      { "name": "Brushless coolant pump", "code": "40151575" },
      { "name": "Mortar pump", "code": "40151576" },
      { "name": "Grouting pump", "code": "40151577" },
      { "name": "Diving air pump", "code": "40151578" },
      { "name": "Gravity pump", "code": "40151579" },
      { "name": "Lubricator pump", "code": "40151580" },
      { "name": "Multi stage pump", "code": "40151581" },
      { "name": "Compressors", "code": "40151600" },
      { "name": "Air compressors", "code": "40151601" },
      { "name": "Axial flow compressors", "code": "40151602" },
      { "name": "Diaphragm compressors", "code": "40151603" },
      { "name": "Gas compressors", "code": "40151604" },
      { "name": "Motor compressors", "code": "40151605" },
      { "name": "Reciprocating compressors", "code": "40151606" },
      { "name": "Refrigerant compressors", "code": "40151607" },
      { "name": "Rotary compressors", "code": "40151608" },
      { "name": "Screw compressors", "code": "40151609" },
      { "name": "Barrel compressors", "code": "40151611" },
      { "name": "Centrifugal compressors", "code": "40151612" },
      { "name": "Combination compressors", "code": "40151613" },
      { "name": "Semi radial compressors", "code": "40151614" },
      { "name": "Turbo compressors", "code": "40151615" },
      { "name": "Compressor kits", "code": "40151616" },
      { "name": "Pump parts and accessories", "code": "40151700" },
      { "name": "Pump casings", "code": "40151701" },
      { "name": "Pump packings", "code": "40151712" },
      { "name": "Pump liners", "code": "40151713" },
      { "name": "Pump barrels", "code": "40151714" },
      { "name": "Pump idlers", "code": "40151715" },
      { "name": "Pump head", "code": "40151716" },
      { "name": "Pump discs", "code": "40151717" },
      { "name": "Sludge pump spare parts", "code": "40151718" },
      { "name": "Sewage pump spare parts", "code": "40151719" },
      { "name": "Submersible pump spare parts", "code": "40151720" },
      { "name": "Water pump spare parts", "code": "40151721" },
      { "name": "Well pump spare parts", "code": "40151722" },
      { "name": "Sump pump spare parts", "code": "40151723" },
      { "name": "Dosing pump spare parts", "code": "40151724" },
      { "name": "Centrifugal pump spare parts", "code": "40151725" },
      { "name": "Circulation pump spare parts", "code": "40151726" },
      { "name": "Rotary pump spare parts", "code": "40151727" },
      { "name": "Pump repair kits", "code": "40151728" },
      { "name": "Pump stator", "code": "40151729" },
      { "name": "Pump impeller", "code": "40151730" },
      { "name": "Pump rotor", "code": "40151731" },
      { "name": "Pump shaft", "code": "40151732" },
      { "name": "Pump soleplate", "code": "40151733" },
      { "name": "Pump baseplate", "code": "40151734" },
      { "name": "Pump column assembly", "code": "40151735" },
      { "name": "Pump column pipe", "code": "40151736" },
      { "name": "Pump bowl assembly", "code": "40151737" },
      { "name": "Pump suction bell", "code": "40151738" },
      { "name": "Reciprocating pump parts", "code": "40151739" },
      { "name": "Compressor parts or accessories", "code": "40151800" },
      { "name": "Rotary compressor parts", "code": "40151801" },
      { "name": "Air compressor parts", "code": "40151802" },
      { "name": "Reciprocating compressor parts", "code": "40151803" },
      { "name": "Centrifugal compressor parts", "code": "40151804" },
      { "name": "Industrial filtering and purification", "code": "40160000" },
      { "name": "Filters", "code": "40161500" },
      { "name": "Vacuum filters", "code": "40161501" },
      { "name": "Water filters", "code": "40161502" },
      { "name": "Dust collectors", "code": "40161503" },
      { "name": "Oil filters", "code": "40161504" },
      { "name": "Air filters", "code": "40161505" },
      { "name": "Filtering machinery", "code": "40161506" },
      { "name": "Filter membranes", "code": "40161507" },
      { "name": "Bag filters", "code": "40161508" },
      { "name": "Absorption filters", "code": "40161509" },
      { "name": "Coalescing filters", "code": "40161511" },
      { "name": "Electronic filters", "code": "40161512" },
      { "name": "Fuel filters", "code": "40161513" },
      { "name": "Gas pipeline filters", "code": "40161514" },
      { "name": "Hydraulic filters", "code": "40161515" },
      { "name": "In line filters", "code": "40161516" },
      { "name": "Light filters", "code": "40161517" },
      { "name": "Microfiber filters", "code": "40161518" },
      { "name": "Panel filters", "code": "40161519" },
      { "name": "Radial fin filters", "code": "40161520" },
      { "name": "Filter Base", "code": "40161521" },
      { "name": "Filter Fins", "code": "40161522" },
      { "name": "Paint filters", "code": "40161524" },
      { "name": "Housings for filters", "code": "40161525" },
      { "name": "Filter retainers or accessories", "code": "40161526" },
      { "name": "Filter repair kits", "code": "40161527" },
      { "name": "Sand filter", "code": "40161528" },
      { "name": "Tube filter", "code": "40161529" },
      { "name": "Cabin air filter", "code": "40161530" },
      { "name": "Filter assembly", "code": "40161531" },
      { "name": "Filter strainer", "code": "40161532" },
      { "name": "Solid waste filter", "code": "40161533" },
      { "name": "Purification", "code": "40161600" },
      { "name": "Air scrubbers", "code": "40161601" },
      { "name": "Air cleaners", "code": "40161602" },
      { "name": "Pipe and tube cleaning machine", "code": "40161603" },
      { "name": "Oil regenerator", "code": "40161604" },
      { "name": "Deodorizing tower", "code": "40161605" },
      { "name": "Ozone generator for air cleaning", "code": "40161606" },
      { "name": "Flue gas desulphurization system", "code": "40161607" },
      { "name": "Air sterilizer", "code": "40161608" },
      { "name": "Water purification system", "code": "40161609" },
      { "name": "Separators", "code": "40161700" },
      { "name": "Centrifuges", "code": "40161701" },
      { "name": "Wet scrubbers", "code": "40161702" },
      { "name": "Mist eliminators", "code": "40161703" },
      { "name": "Hydro cyclones", "code": "40161704" },
      { "name": "Demister pad", "code": "40161705" },
      { "name": "Filter media", "code": "40161800" },
      { "name": "Metal fabric media", "code": "40161801" },
      { "name": "Pressed felts", "code": "40161802" },
      { "name": "Filter papers", "code": "40161803" },
      { "name": "Filter aids", "code": "40161804" },
      { "name": "Filter cloth", "code": "40161805" },
      { "name": "Filter mesh", "code": "40161806" },
      { "name": "Filter element", "code": "40161807" },
      { "name": "Biological filter media", "code": "40161808" },
      { "name": "Filter cartridge", "code": "40161809" },
      { "name": "Pipe piping and pipe fittings", "code": "40170000" },
      { "name": "Commercial pipe and piping", "code": "40171500" },
      { "name": "Commercial welded carbon steel pipe", "code": "40171501" },
      { "name": "Commercial seamless carbon steel pipe", "code": "40171502" },
      { "name": "Commercial ductile iron pipe", "code": "40171503" },
      { "name": "Commercial high nickel alloy pipe", "code": "40171504" },
      { "name": "Commercial high yield steel pipe", "code": "40171505" },
      { "name": "Commercial ferrous alloy pipe", "code": "40171506" },
      { "name": "Commercial aluminum pipe", "code": "40171507" },
      { "name": "Commercial brass pipe", "code": "40171508" },
      { "name": "Commercial bronze pipe", "code": "40171509" },
      { "name": "Commercial concrete pipe", "code": "40171510" },
      { "name": "Commercial copper pipe", "code": "40171511" },
      { "name": "Commercial cast iron pipe", "code": "40171512" },
      { "name": "Commercial assembled cast iron pipe hub", "code": "40171513" },
      { "name": "Commercial lead pipe", "code": "40171514" },
      { "name": "Commercial magnesium pipe", "code": "40171515" },
      { "name": "Commercial non ferrous pipe", "code": "40171516" },
      { "name": "Commercial PVC pipe", "code": "40171517" },
      { "name": "Commercial CPVC pipe", "code": "40171518" },
      { "name": "Commercial ABS pipe", "code": "40171519" },
      { "name": "Commercial HDPE pipe", "code": "40171520" },
      { "name": "Commercial welded stainless steel pipe", "code": "40171521" },
      {
        "name": "Commercial seamless stainless steel pipe",
        "code": "40171522"
      },
      { "name": "Commercial tin pipe", "code": "40171523" },
      { "name": "Commercial titanium pipe", "code": "40171524" },
      { "name": "Commercial zinc pipe", "code": "40171525" },
      { "name": "Commercial glass pipe", "code": "40171526" },
      {
        "name": "Commercial welded corrugated galvanized pipe",
        "code": "40171527"
      },
      { "name": "Industrial pipe and piping", "code": "40171600" },
      { "name": "Industrial welded carbon steel pipe", "code": "40171601" },
      { "name": "Industrial seamless carbon steel pipe", "code": "40171602" },
      { "name": "Industrial ductile iron pipe", "code": "40171603" },
      { "name": "Industrial high nickel alloy pipe", "code": "40171604" },
      { "name": "Industrial high yield steel pipe", "code": "40171605" },
      { "name": "Industrial ferrous alloy pipe", "code": "40171606" },
      { "name": "Industrial aluminum pipe", "code": "40171607" },
      { "name": "Industrial brass pipe", "code": "40171608" },
      { "name": "Industrial bronze pipe", "code": "40171609" },
      { "name": "Industrial concrete pipe", "code": "40171610" },
      { "name": "Industrial copper pipe", "code": "40171611" },
      { "name": "Industrial cast iron pipe", "code": "40171612" },
      { "name": "Industrial assembled cast iron pipe hub", "code": "40171613" },
      { "name": "Industrial lead pipe", "code": "40171614" },
      { "name": "Industrial magnesium pipe", "code": "40171615" },
      { "name": "Industrial non ferrous pipe", "code": "40171616" },
      { "name": "Industrial PVC pipe", "code": "40171617" },
      { "name": "Industrial CPVC pipe", "code": "40171618" },
      { "name": "Industrial ABS pipe", "code": "40171619" },
      { "name": "Industrial HDPE pipe", "code": "40171620" },
      { "name": "Industrial welded stainless steel pipe", "code": "40171621" },
      {
        "name": "Industrial seamless stainless steel pipe",
        "code": "40171622"
      },
      { "name": "Industrial tin pipe", "code": "40171623" },
      { "name": "Industrial titanium pipe", "code": "40171624" },
      { "name": "Industrial zinc pipe", "code": "40171625" },
      { "name": "Industrial glass pipe", "code": "40171626" },
      { "name": "Pipe adapters", "code": "40171700" },
      { "name": "Brass pipe adapter", "code": "40171701" },
      { "name": "Carbon steel pipe adapter", "code": "40171702" },
      { "name": "Cast iron pipe adapter", "code": "40171703" },
      { "name": "Ductile iron pipe adapter", "code": "40171704" },
      { "name": "Forged steel pipe adapter", "code": "40171705" },
      { "name": "Malleable iron pipe adapter", "code": "40171706" },
      { "name": "Stainless steel pipe adapter", "code": "40171707" },
      { "name": "PVC plastic pipe adapter", "code": "40171708" },
      { "name": "CPVC plastic pipe adapter", "code": "40171709" },
      { "name": "ABS plastic pipe adapter", "code": "40171710" },
      { "name": "HDPE plastic pipe adapter", "code": "40171711" },
      { "name": "Pipe angle face rings", "code": "40171800" },
      { "name": "Carbon steel pipe angle face ring", "code": "40171801" },
      { "name": "Forged steel pipe angle face ring", "code": "40171802" },
      { "name": "Stainless steel pipe angle face ring", "code": "40171803" },
      { "name": "Pipe backup flanges", "code": "40171900" },
      { "name": "Carbon steel pipe backup flange", "code": "40171901" },
      { "name": "Forged steel pipe backup flange", "code": "40171902" },
      { "name": "Stainless steel pipe backup flange", "code": "40171903" },
      { "name": "Ductile iron pipe backup flange", "code": "40171904" },
      { "name": "Copper pipe backup flange", "code": "40171905" },
      { "name": "Aluminum pipe reserve flange", "code": "40171906" },
      { "name": "Galvanized iron pipe reserve flange", "code": "40171907" },
      { "name": "Pipe baffles", "code": "40172000" },
      { "name": "Brass pipe baffle", "code": "40172001" },
      { "name": "Ductile iron pipe baffle", "code": "40172002" },
      { "name": "Forged steel pipe baffle", "code": "40172003" },
      { "name": "Stainless steel pipe baffle", "code": "40172004" },
      { "name": "PVC plastic pipe baffle", "code": "40172005" },
      { "name": "CPVC plastic pipe baffle", "code": "40172006" },
      { "name": "ABS plastic pipe baffle", "code": "40172007" },
      { "name": "HDPE plastic pipe baffle", "code": "40172008" },
      { "name": "Pipe bends", "code": "40172100" },
      { "name": "Cast iron pipe bend", "code": "40172101" },
      { "name": "Malleable iron pipe bend", "code": "40172102" },
      { "name": "Copper pipe bend", "code": "40172103" },
      { "name": "Galvanized iron pipe bend", "code": "40172104" },
      { "name": "Pipe blind flanges", "code": "40172200" },
      { "name": "Carbon steel pipe blind flange", "code": "40172201" },
      { "name": "Cast iron pipe blind flange", "code": "40172202" },
      { "name": "Ductile iron pipe blind flange", "code": "40172203" },
      { "name": "Forged steel pipe blind flange", "code": "40172204" },
      { "name": "Stainless steel pipe blind flange", "code": "40172205" },
      { "name": "Copper pipe blind flange", "code": "40172206" },
      { "name": "Galvanized iron pipe blind flange", "code": "40172207" },
      { "name": "Pipe bushings", "code": "40172300" },
      { "name": "Brass pipe bushing", "code": "40172301" },
      { "name": "Carbon steel pipe bushing", "code": "40172302" },
      { "name": "Cast iron pipe bushing", "code": "40172303" },
      { "name": "Ductile iron pipe bushing", "code": "40172304" },
      { "name": "Forged steel pipe bushing", "code": "40172305" },
      { "name": "Malleable iron pipe bushing", "code": "40172306" },
      { "name": "Stainless steel pipe bushing", "code": "40172307" },
      { "name": "PVC plastic pipe bushing", "code": "40172308" },
      { "name": "CPVC plastic pipe bushing", "code": "40172309" },
      { "name": "ABS plastic pipe bushing", "code": "40172310" },
      { "name": "HDPE plastic pipe bushing", "code": "40172311" },
      { "name": "Copper pipe bushing", "code": "40172312" },
      { "name": "Rubber pipe bushing", "code": "40172313" },
      { "name": "Galvanized steel pipe bushing", "code": "40172314" },
      { "name": "Bronze pipe bushing", "code": "40172315" },
      { "name": "Pipe caps", "code": "40172400" },
      { "name": "Brass pipe cap", "code": "40172401" },
      { "name": "Carbon steel pipe cap", "code": "40172402" },
      { "name": "Cast iron pipe cap", "code": "40172403" },
      { "name": "Ductile iron pipe cap", "code": "40172404" },
      { "name": "Forged steel pipe cap", "code": "40172405" },
      { "name": "Malleable iron pipe cap", "code": "40172406" },
      { "name": "Stainless steel pipe cap", "code": "40172407" },
      { "name": "PVC plastic pipe cap", "code": "40172408" },
      { "name": "CPVC plastic pipe cap", "code": "40172409" },
      { "name": "ABS plastic pipe cap", "code": "40172410" },
      { "name": "HDPE plastic pipe cap", "code": "40172411" },
      { "name": "Copper pipe cap", "code": "40172412" },
      { "name": "Rubber pipe cap", "code": "40172413" },
      { "name": "Galvanized iron pipe cap", "code": "40172414" },
      { "name": "Pipe connectors", "code": "40172500" },
      { "name": "Brass pipe connector", "code": "40172501" },
      { "name": "Carbon steel pipe connector", "code": "40172502" },
      { "name": "Cast iron pipe connector", "code": "40172503" },
      { "name": "Ductile iron pipe connector", "code": "40172504" },
      { "name": "Forged steel pipe connector", "code": "40172505" },
      { "name": "Malleable iron pipe connector", "code": "40172506" },
      { "name": "Stainless steel pipe connector", "code": "40172507" },
      { "name": "PVC plastic pipe connector", "code": "40172508" },
      { "name": "CPVC plastic pipe connector", "code": "40172509" },
      { "name": "ABS plastic pipe connector", "code": "40172510" },
      { "name": "HDPE plastic pipe connector", "code": "40172511" },
      {
        "name": "Glass reinforced thermosetting plastic pipe connector",
        "code": "40172512"
      },
      { "name": "Flexible pipe connector", "code": "40172513" },
      { "name": "Preinsulated pipe connector", "code": "40172514" },
      { "name": "Polybutylene pipe connector", "code": "40172515" },
      { "name": "Polyethylene pipe connector", "code": "40172516" },
      { "name": "Polypropylene pipe connector", "code": "40172517" },
      { "name": "Coated steel pipe connector", "code": "40172518" },
      {
        "name": "Aluminium and aluminium alloy pipe connector",
        "code": "40172519"
      },
      { "name": "Rigid polyvinyl chloride pipe connector", "code": "40172520" },
      { "name": "Copper and copper alloy pipe connector", "code": "40172521" },
      { "name": "Flexible pipe joint", "code": "40172522" },
      { "name": "Corrugated pipe connector", "code": "40172523" },
      { "name": "Pipe couplings", "code": "40172600" },
      { "name": "Brass pipe coupling", "code": "40172601" },
      { "name": "Carbon steel pipe coupling", "code": "40172602" },
      { "name": "Cast iron pipe coupling", "code": "40172603" },
      { "name": "Ductile iron pipe coupling", "code": "40172604" },
      { "name": "Forged steel pipe coupling", "code": "40172605" },
      { "name": "Malleable iron pipe coupling", "code": "40172606" },
      { "name": "Stainless steel pipe coupling", "code": "40172607" },
      { "name": "PVC plastic pipe coupling", "code": "40172608" },
      { "name": "CPVC plastic pipe coupling", "code": "40172609" },
      { "name": "ABS plastic pipe coupling", "code": "40172610" },
      { "name": "HDPE plastic pipe coupling", "code": "40172611" },
      { "name": "Copper pipe coupling", "code": "40172612" },
      { "name": "Galvanized iron pipe coupling", "code": "40172613" },
      { "name": "Pipe crosses", "code": "40172700" },
      { "name": "Brass pipe cross", "code": "40172701" },
      { "name": "Carbon steel pipe cross", "code": "40172702" },
      { "name": "Cast iron pipe cross", "code": "40172703" },
      { "name": "Ductile iron pipe cross", "code": "40172704" },
      { "name": "Forged steel pipe cross", "code": "40172705" },
      { "name": "Malleable iron pipe cross", "code": "40172706" },
      { "name": "Stainless steel pipe cross", "code": "40172707" },
      { "name": "PVC plastic pipe cross", "code": "40172708" },
      { "name": "CPVC plastic pipe cross", "code": "40172709" },
      { "name": "ABS plastic pipe cross", "code": "40172710" },
      { "name": "HDPE plastic pipe cross", "code": "40172711" },
      { "name": "Copper pipe cross", "code": "40172712" },
      { "name": "Galvanized iron pipe cross or junction", "code": "40172713" },
      { "name": "Pipe elbows", "code": "40172800" },
      { "name": "Brass pipe elbow", "code": "40172801" },
      { "name": "Carbon steel pipe elbow", "code": "40172802" },
      { "name": "Cast iron pipe elbow", "code": "40172803" },
      { "name": "Ductile iron pipe elbow", "code": "40172804" },
      { "name": "Forged steel pipe elbow", "code": "40172805" },
      { "name": "Malleable iron pipe elbow", "code": "40172806" },
      { "name": "Stainless steel pipe elbow", "code": "40172807" },
      { "name": "PVC plastic pipe elbow", "code": "40172808" },
      { "name": "CPVC plastic pipe elbow", "code": "40172809" },
      { "name": "ABS plastic pipe elbow", "code": "40172810" },
      { "name": "HDPE plastic pipe elbow", "code": "40172811" },
      { "name": "Copper pipe elbow", "code": "40172812" },
      { "name": "Aluminum pipe elbow", "code": "40172813" },
      { "name": "Galvanized iron pipe elbow", "code": "40172814" },
      { "name": "Galvanized steel pipe elbow", "code": "40172815" },
      { "name": "Pipe expansion joints", "code": "40172900" },
      { "name": "Carbon steel pipe expansion joint", "code": "40172901" },
      { "name": "Cast iron pipe expansion joint", "code": "40172902" },
      { "name": "Ductile iron pipe expansion joint", "code": "40172903" },
      { "name": "Forged steel pipe expansion joint", "code": "40172904" },
      { "name": "Stainless steel pipe expansion joint", "code": "40172905" },
      { "name": "PVC plastic pipe expansion joint", "code": "40172906" },
      { "name": "CPVC plastic pipe expansion joint", "code": "40172907" },
      { "name": "ABS plastic pipe expansion joint", "code": "40172908" },
      { "name": "HDPE plastic pipe expansion joint", "code": "40172909" },
      { "name": "Rubber pipe expansion joint", "code": "40172910" },
      { "name": "Copper pipe expansion joint", "code": "40172911" },
      { "name": "Pipe half couplings", "code": "40173000" },
      { "name": "Brass pipe half coupling", "code": "40173001" },
      { "name": "Carbon steel pipe half coupling", "code": "40173002" },
      { "name": "Forged steel pipe half coupling", "code": "40173003" },
      { "name": "Malleable iron pipe half coupling", "code": "40173004" },
      { "name": "Stainless steel pipe half coupling", "code": "40173005" },
      { "name": "PVC plastic pipe half coupling", "code": "40173006" },
      { "name": "CPVC plastic pipe half coupling", "code": "40173007" },
      { "name": "ABS plastic pipe half coupling", "code": "40173008" },
      { "name": "HDPE plastic pipe half coupling", "code": "40173009" },
      { "name": "Pipe lapjoint flanges", "code": "40173100" },
      { "name": "Carbon steel pipe lapjoint flange", "code": "40173101" },
      { "name": "Forged steel pipe lapjoint flange", "code": "40173102" },
      { "name": "Stainless steel pipe lapjoint flange", "code": "40173103" },
      { "name": "Copper pipe lapjoint flange", "code": "40173104" },
      { "name": "Pipe long weldneck flanges", "code": "40173200" },
      { "name": "Carbon steel pipe long weldneck flange", "code": "40173201" },
      { "name": "Forged steel pipe long weldneck flange", "code": "40173202" },
      {
        "name": "Stainless steel pipe long weldneck flange",
        "code": "40173203"
      },
      { "name": "Pipe nipples", "code": "40173300" },
      { "name": "Brass pipe nipple", "code": "40173301" },
      { "name": "Carbon steel pipe nipple", "code": "40173302" },
      { "name": "Stainless steel pipe nipple", "code": "40173303" },
      { "name": "PVC plastic pipe nipple", "code": "40173304" },
      { "name": "CPVC plastic pipe nipple", "code": "40173305" },
      { "name": "ABS plastic pipe nipple", "code": "40173306" },
      { "name": "HDPE plastic pipe nipple", "code": "40173307" },
      { "name": "Copper pipe nipple", "code": "40173308" },
      { "name": "Galvanized iron pipe nipple", "code": "40173309" },
      { "name": "Ductile iron pipe nipple", "code": "40173310" },
      { "name": "Black iron pipe nipple", "code": "40173311" },
      { "name": "Pipe plate flanges", "code": "40173400" },
      { "name": "Brass pipe plate flange", "code": "40173401" },
      { "name": "Carbon steel pipe plate flange", "code": "40173402" },
      { "name": "Cast iron pipe plate flange", "code": "40173403" },
      { "name": "Ductile iron pipe plate flange", "code": "40173404" },
      { "name": "Malleable iron pipe plate flange", "code": "40173405" },
      { "name": "Stainless steel pipe plate flange", "code": "40173406" },
      { "name": "ABS plastic pipe plate flange", "code": "40173407" },
      { "name": "PVC plastic pipe plate flange", "code": "40173408" },
      { "name": "Copper pipe plate flange", "code": "40173409" },
      { "name": "Pipe plugs", "code": "40173500" },
      { "name": "Brass pipe plug", "code": "40173501" },
      { "name": "Carbon steel pipe plug", "code": "40173502" },
      { "name": "Cast iron pipe plug", "code": "40173503" },
      { "name": "Ductile iron pipe plug", "code": "40173504" },
      { "name": "Forged steel pipe plug", "code": "40173505" },
      { "name": "Malleable iron pipe plug", "code": "40173506" },
      { "name": "Stainless steel pipe plug", "code": "40173507" },
      { "name": "PVC plastic pipe plug", "code": "40173508" },
      { "name": "CPVC plastic pipe plug", "code": "40173509" },
      { "name": "ABS plastic pipe plug", "code": "40173510" },
      { "name": "HDPE plastic pipe plug", "code": "40173511" },
      { "name": "Copper pipe plug", "code": "40173512" },
      { "name": "Rubber pipe plug", "code": "40173513" },
      { "name": "Aluminum pipe plug", "code": "40173514" },
      { "name": "Galvanized iron pipe plug", "code": "40173515" },
      { "name": "Pipe reducing couplings", "code": "40173600" },
      { "name": "Brass pipe reducing coupling", "code": "40173601" },
      { "name": "Carbon steel pipe reducing coupling", "code": "40173602" },
      { "name": "Cast iron pipe reducing coupling", "code": "40173603" },
      { "name": "Ductile iron pipe reducing coupling", "code": "40173604" },
      { "name": "Forged steel pipe reducing coupling", "code": "40173605" },
      { "name": "Malleable iron pipe reducing coupling", "code": "40173606" },
      { "name": "Stainless steel pipe reducing coupling", "code": "40173607" },
      { "name": "PVC plastic pipe reducing coupling", "code": "40173608" },
      { "name": "CPVC plastic pipe reducing coupling", "code": "40173609" },
      { "name": "ABS plastic pipe reducing coupling", "code": "40173610" },
      { "name": "HDPE plastic pipe reducing coupling", "code": "40173611" },
      { "name": "Rubber pipe reducing coupling", "code": "40173612" },
      { "name": "Copper pipe reducing coupling", "code": "40173613" },
      { "name": "Aluminum pipe reducing coupling", "code": "40173614" },
      { "name": "Galvanized iron pipe reducing coupling", "code": "40173615" },
      { "name": "Pipe reducing flanges", "code": "40173700" },
      { "name": "Brass pipe reducing flange", "code": "40173701" },
      { "name": "Cast iron pipe reducing flange", "code": "40173702" },
      { "name": "Ductile iron pipe reducing flange", "code": "40173703" },
      { "name": "Forged steel pipe reducing flange", "code": "40173704" },
      { "name": "Malleable iron pipe reducing flange", "code": "40173705" },
      { "name": "Stainless steel pipe reducing flange", "code": "40173706" },
      { "name": "PVC plastic pipe reducing flange", "code": "40173707" },
      { "name": "CPVC plastic pipe reducing flange", "code": "40173708" },
      { "name": "ABS plastic pipe reducing flange", "code": "40173709" },
      { "name": "HDPE plastic pipe reducing flange", "code": "40173710" },
      { "name": "Copper pipe reducing flange", "code": "40173711" },
      { "name": "Pipe repair clamps", "code": "40173800" },
      { "name": "Cast iron pipe repair clamp", "code": "40173801" },
      { "name": "Ductile iron pipe repair clamp", "code": "40173802" },
      { "name": "Forged steel pipe repair clamp", "code": "40173803" },
      { "name": "Stainless steel pipe repair clamp", "code": "40173804" },
      { "name": "PVC plastic pipe repair clamp", "code": "40173805" },
      { "name": "CPVC plastic pipe repair clamp", "code": "40173806" },
      { "name": "ABS plastic pipe repair clamp", "code": "40173807" },
      { "name": "HDPE plastic pipe repair clamp", "code": "40173808" },
      { "name": "Pipe rupture disks", "code": "40173900" },
      { "name": "Carbon steel pipe rupture disk", "code": "40173901" },
      { "name": "Cast iron pipe rupture disk", "code": "40173902" },
      { "name": "Ductile iron pipe rupture disk", "code": "40173903" },
      { "name": "Forged steel pipe rupture disk", "code": "40173904" },
      { "name": "Malleable iron pipe rupture disk", "code": "40173905" },
      { "name": "Stainless steel pipe rupture disk", "code": "40173906" },
      { "name": "PVC plastic pipe rupture disk", "code": "40173907" },
      { "name": "CPVC plastic pipe rupture disk", "code": "40173908" },
      { "name": "ABS plastic pipe rupture disk", "code": "40173909" },
      { "name": "HDPE plastic pipe rupture disk", "code": "40173910" },
      { "name": "Pipe slipon flanges", "code": "40174000" },
      { "name": "Brass pipe slipon flange", "code": "40174001" },
      { "name": "Carbon steel pipe slipon flange", "code": "40174002" },
      { "name": "Cast iron pipe slipon flange", "code": "40174003" },
      { "name": "Ductile iron pipe slipon flange", "code": "40174004" },
      { "name": "Forged steel pipe slipon flange", "code": "40174005" },
      { "name": "Stainless steel pipe slipon flange", "code": "40174006" },
      { "name": "Copper pipe slipon flange", "code": "40174007" },
      { "name": "PVC pipe slipon flange", "code": "40174008" },
      { "name": "Pipe socketweld flanges", "code": "40174100" },
      { "name": "Carbon steel pipe socketweld flange", "code": "40174101" },
      { "name": "Forged steel pipe socketweld flange", "code": "40174102" },
      { "name": "Stainless steel pipe socketweld flange", "code": "40174103" },
      { "name": "PVC plastic pipe socketweld flange", "code": "40174104" },
      { "name": "CPVC plastic pipe socketweld flange", "code": "40174105" },
      { "name": "ABS plastic pipe socketweld flange", "code": "40174106" },
      { "name": "HDPE plastic pipe socketweld flange", "code": "40174107" },
      { "name": "Copper pipe socketweld flange", "code": "40174108" },
      { "name": "Pipe sockolets", "code": "40174200" },
      { "name": "Carbon steel pipe sockolet", "code": "40174201" },
      { "name": "Forged steel pipe sockolet", "code": "40174202" },
      { "name": "Stainless steel pipe sockolet", "code": "40174203" },
      { "name": "Pipe spacers", "code": "40174300" },
      { "name": "Brass pipe spacer", "code": "40174301" },
      { "name": "Carbon steel pipe spacer", "code": "40174302" },
      { "name": "Cast iron pipe spacer", "code": "40174303" },
      { "name": "Ductile iron pipe spacer", "code": "40174304" },
      { "name": "Forged steel pipe spacer", "code": "40174305" },
      { "name": "Stainless steel pipe spacer", "code": "40174306" },
      { "name": "PVC plastic pipe spacer", "code": "40174307" },
      { "name": "CPVC plastic pipe spacer", "code": "40174308" },
      { "name": "ABS plastic pipe spacer", "code": "40174309" },
      { "name": "HDPE plastic pipe spacer", "code": "40174310" },
      { "name": "Pipe spectacle blind flanges", "code": "40174400" },
      {
        "name": "Carbon steel pipe spectacle blind flange",
        "code": "40174401"
      },
      {
        "name": "Forged steel pipe spectacle blind flange",
        "code": "40174402"
      },
      {
        "name": "Stainless steel pipe spectacle blind flange",
        "code": "40174403"
      },
      { "name": "Pipe swivel or rotating joints", "code": "40174500" },
      { "name": "Brass pipe swivel or rotating joint", "code": "40174501" },
      {
        "name": "PVC plastic pipe swivel or rotating joint",
        "code": "40174502"
      },
      {
        "name": "CPVC plastic pipe swivel or rotating joint",
        "code": "40174503"
      },
      {
        "name": "ABS plastic pipe swivel or rotating joint",
        "code": "40174504"
      },
      {
        "name": "HDPE plastic pipe swivel or rotating joint",
        "code": "40174505"
      },
      { "name": "Copper pipe swivel or rotating joint", "code": "40174506" },
      { "name": "Pipe tees", "code": "40174600" },
      { "name": "Brass pipe tee", "code": "40174601" },
      { "name": "Carbon steel pipe tee", "code": "40174602" },
      { "name": "Cast iron pipe tee", "code": "40174603" },
      { "name": "Ductile iron pipe tee", "code": "40174604" },
      { "name": "Forged steel pipe tee", "code": "40174605" },
      { "name": "Malleable iron pipe tee", "code": "40174606" },
      { "name": "Stainless steel pipe tee", "code": "40174607" },
      { "name": "PVC plastic pipe tee", "code": "40174608" },
      { "name": "CPVC plastic pipe tee", "code": "40174609" },
      { "name": "ABS plastic pipe tee", "code": "40174610" },
      { "name": "HDPE plastic pipe tee", "code": "40174611" },
      { "name": "Copper pipe tee", "code": "40174612" },
      { "name": "Galvanized iron pipe tee", "code": "40174613" },
      { "name": "Pipe threaded flanges", "code": "40174700" },
      { "name": "Brass pipe threaded flange", "code": "40174701" },
      { "name": "Carbon steel pipe threaded flange", "code": "40174702" },
      { "name": "Cast iron pipe threaded flange", "code": "40174703" },
      { "name": "Ductile iron pipe threaded flange", "code": "40174704" },
      { "name": "Forged steel pipe threaded flange", "code": "40174705" },
      { "name": "Malleable iron pipe threaded flange", "code": "40174706" },
      { "name": "Stainless steel pipe threaded flange", "code": "40174707" },
      { "name": "PVC plastic pipe threaded flange", "code": "40174708" },
      { "name": "CPVC plastic pipe threaded flange", "code": "40174709" },
      { "name": "ABS plastic pipe threaded flange", "code": "40174710" },
      { "name": "HDPE plastic pipe threaded flange", "code": "40174711" },
      { "name": "Pipe toilet flanges", "code": "40174800" },
      { "name": "Carbon steel pipe toilet flange", "code": "40174801" },
      { "name": "Cast iron pipe toilet flange", "code": "40174802" },
      { "name": "PVC plastic pipe toilet flange", "code": "40174803" },
      { "name": "CPVC plastic pipe toilet flange", "code": "40174804" },
      { "name": "ABS plastic pipe toilet flange", "code": "40174805" },
      { "name": "HDPE plastic pipe toilet flange", "code": "40174806" },
      { "name": "Brass pipe toilet flange", "code": "40174807" },
      { "name": "Copper pipe toilet flange", "code": "40174808" },
      { "name": "Pipe unions", "code": "40174900" },
      { "name": "Brass pipe union", "code": "40174901" },
      { "name": "Carbon steel pipe union", "code": "40174902" },
      { "name": "Cast iron pipe union", "code": "40174903" },
      { "name": "Ductile iron pipe union", "code": "40174904" },
      { "name": "Forged steel pipe union", "code": "40174905" },
      { "name": "Malleable iron pipe union", "code": "40174906" },
      { "name": "Stainless steel pipe union", "code": "40174907" },
      { "name": "PVC plastic pipe union", "code": "40174908" },
      { "name": "CPVC plastic pipe union", "code": "40174909" },
      { "name": "ABS plastic pipe union", "code": "40174910" },
      { "name": "HDPE plastic pipe union", "code": "40174911" },
      { "name": "Copper pipe union", "code": "40174912" },
      { "name": "Pipe weldneck flanges", "code": "40175000" },
      { "name": "Carbon steel pipe weldneck flange", "code": "40175001" },
      { "name": "Forged steel pipe weldneck flange", "code": "40175002" },
      { "name": "Stainless steel pipe weldneck flange", "code": "40175003" },
      { "name": "Pipe weldolets", "code": "40175100" },
      { "name": "Carbon steel pipe weldolet", "code": "40175101" },
      { "name": "Forged steel pipe weldolet", "code": "40175102" },
      { "name": "Stainless steel pipe weldolet", "code": "40175103" },
      { "name": "Pipe wyes", "code": "40175200" },
      { "name": "Brass pipe wye", "code": "40175201" },
      { "name": "Carbon steel pipe wye", "code": "40175202" },
      { "name": "Cast iron pipe wye", "code": "40175203" },
      { "name": "Ductile iron pipe wye", "code": "40175204" },
      { "name": "Forged steel pipe wye", "code": "40175205" },
      { "name": "Malleable iron pipe wye", "code": "40175206" },
      { "name": "Stainless steel pipe wye", "code": "40175207" },
      { "name": "PVC plastic pipe wye", "code": "40175208" },
      { "name": "CPVC plastic pipe wye", "code": "40175209" },
      { "name": "ABS plastic pipe wye", "code": "40175210" },
      { "name": "HDPE plastic pipe wye", "code": "40175211" },
      { "name": "Copper pipe wye", "code": "40175212" },
      { "name": "Specialized pipe fittings and flanges", "code": "40175300" },
      { "name": "Ammonia flanges", "code": "40175301" },
      { "name": "Orifice flanges", "code": "40175302" },
      { "name": "Pipe branch outlets", "code": "40175303" },
      { "name": "Pipe connection boxes", "code": "40175304" },
      { "name": "Pipe inserts", "code": "40175305" },
      { "name": "Pipe laterals", "code": "40175306" },
      { "name": "Pipe saddles", "code": "40175307" },
      { "name": "Pipe stubends", "code": "40175308" },
      { "name": "Pipe puddle flanges", "code": "40175309" },
      { "name": "Tubes tubing and tube fittings", "code": "40180000" },
      { "name": "Welded copper tubes", "code": "40181500" },
      { "name": "Welded copper bent tube", "code": "40181501" },
      { "name": "Welded copper pierced tube", "code": "40181502" },
      { "name": "Welded copper end formed tube", "code": "40181503" },
      { "name": "Welded copper multiport tube", "code": "40181504" },
      { "name": "Welded copper chamfered tube", "code": "40181505" },
      { "name": "Welded copper tube assembly", "code": "40181506" },
      { "name": "Welded brass tubes", "code": "40181600" },
      { "name": "Welded brass bent tube", "code": "40181601" },
      { "name": "Welded brass pierced tube", "code": "40181602" },
      { "name": "Welded brass end formed tube", "code": "40181603" },
      { "name": "Welded brass multiport tube", "code": "40181604" },
      { "name": "Welded brass chamfered tube", "code": "40181605" },
      { "name": "Welded brass tube assembly", "code": "40181606" },
      { "name": "Welded aluminum tubes", "code": "40181700" },
      { "name": "Welded aluminum bent tube", "code": "40181701" },
      { "name": "Welded aluminum pierced tube", "code": "40181702" },
      { "name": "Welded aluminum end formed tube", "code": "40181703" },
      { "name": "Welded aluminum multiport tube", "code": "40181704" },
      { "name": "Welded aluminum chamfered tube", "code": "40181705" },
      { "name": "Welded aluminum tube assembly", "code": "40181706" },
      { "name": "Welded steel tubes", "code": "40181800" },
      { "name": "Welded steel bent tube", "code": "40181801" },
      { "name": "Welded steel pierced tube", "code": "40181802" },
      { "name": "Welded steel end formed tube", "code": "40181803" },
      { "name": "Welded steel multiport tube", "code": "40181804" },
      { "name": "Welded steel chamfered tube", "code": "40181805" },
      { "name": "Welded steel tube assembly", "code": "40181806" },
      { "name": "Welded stainless steel tubes", "code": "40181900" },
      { "name": "Welded stainless steel bent tube", "code": "40181901" },
      { "name": "Welded stainless steel pierced tube", "code": "40181902" },
      { "name": "Welded stainless steel end formed tube", "code": "40181903" },
      { "name": "Welded stainless steel multiport tube", "code": "40181904" },
      { "name": "Welded stainless steel chamfered tube", "code": "40181905" },
      { "name": "Welded stainless steel tube assembly", "code": "40181906" },
      { "name": "Seamless copper tubes", "code": "40182000" },
      { "name": "Seamless copper bent tube", "code": "40182001" },
      { "name": "Seamless copper pierced tube", "code": "40182002" },
      { "name": "Seamless copper end formed tube", "code": "40182003" },
      { "name": "Seamless copper multiport tube", "code": "40182004" },
      { "name": "Seamless copper chamfered tube", "code": "40182005" },
      { "name": "Seamless copper tube assembly", "code": "40182006" },
      { "name": "Seamless brass tubes", "code": "40182100" },
      { "name": "Seamless brass bent tube", "code": "40182101" },
      { "name": "Seamless brass pierced tube", "code": "40182102" },
      { "name": "Seamless brass end formed tube", "code": "40182103" },
      { "name": "Seamless brass multiport tube", "code": "40182104" },
      { "name": "Seamless brass chamfered tube", "code": "40182105" },
      { "name": "Seamless brass tube assembly", "code": "40182106" },
      { "name": "Seamless aluminum tubes", "code": "40182200" },
      { "name": "Seamless aluminum bent tube", "code": "40182201" },
      { "name": "Seamless aluminum pierced tube", "code": "40182202" },
      { "name": "Seamless aluminum end formed tube", "code": "40182203" },
      { "name": "Seamless aluminum multiport tube", "code": "40182204" },
      { "name": "Seamless aluminum chamfered tube", "code": "40182205" },
      { "name": "Seamless aluminum tube assembly", "code": "40182206" },
      { "name": "Seamless steel tubes", "code": "40182300" },
      { "name": "Seamless steel bent tube", "code": "40182301" },
      { "name": "Seamless steel pierced tube", "code": "40182302" },
      { "name": "Seamless steel end formed tube", "code": "40182303" },
      { "name": "Seamless steel multiport tube", "code": "40182304" },
      { "name": "Seamless steel chamfered tube", "code": "40182305" },
      { "name": "Seamless steel tube assembly", "code": "40182306" },
      { "name": "Seamless stainless steel tubes", "code": "40182400" },
      { "name": "Seamless stainless steel bent tube", "code": "40182401" },
      { "name": "Seamless stainless steel pierced tube", "code": "40182402" },
      {
        "name": "Seamless stainless steel end formed tube",
        "code": "40182403"
      },
      { "name": "Seamless stainless steel multiport tube", "code": "40182404" },
      { "name": "Seamless stainless steel chamfered tube", "code": "40182405" },
      { "name": "Seamless stainless steel tube assembly", "code": "40182406" },
      { "name": "Extruded copper tubes", "code": "40182500" },
      { "name": "Extruded copper bent tube", "code": "40182501" },
      { "name": "Extruded copper pierced tube", "code": "40182502" },
      { "name": "Extruded copper end formed tube", "code": "40182503" },
      { "name": "Extruded copper multiport tube", "code": "40182504" },
      { "name": "Extruded copper chamfered tube", "code": "40182505" },
      { "name": "Extruded copper tube assembly", "code": "40182506" },
      { "name": "Extruded brass tubes", "code": "40182600" },
      { "name": "Extruded brass bent tube", "code": "40182601" },
      { "name": "Extruded brass pierced tube", "code": "40182602" },
      { "name": "Extruded brass end formed tube", "code": "40182603" },
      { "name": "Extruded brass multiport tube", "code": "40182604" },
      { "name": "Extruded brass chamfered tube", "code": "40182605" },
      { "name": "Extruded brass tube assembly", "code": "40182606" },
      { "name": "Extruded aluminum tubes", "code": "40182700" },
      { "name": "Extruded aluminum bent tube", "code": "40182701" },
      { "name": "Extruded aluminum pierced tube", "code": "40182702" },
      { "name": "Extruded aluminum end formed tube", "code": "40182703" },
      { "name": "Extruded aluminum multiport tube", "code": "40182704" },
      { "name": "Extruded aluminum chamfered tube", "code": "40182705" },
      { "name": "Extruded aluminum tube assembly", "code": "40182706" },
      { "name": "Extruded aluminum drawn tubing", "code": "40182707" },
      { "name": "Extruded steel tubes", "code": "40182800" },
      { "name": "Extruded steel bent tube", "code": "40182801" },
      { "name": "Extruded steel pierced tube", "code": "40182802" },
      { "name": "Extruded steel end formed tube", "code": "40182803" },
      { "name": "Extruded steel multiport tube", "code": "40182804" },
      { "name": "Extruded steel chamfered tube", "code": "40182805" },
      { "name": "Extruded steel tube assembly", "code": "40182806" },
      { "name": "Extruded stainless steel tubes", "code": "40182900" },
      { "name": "Extruded stainless steel bent tube", "code": "40182901" },
      { "name": "Extruded stainless steel pierced tube", "code": "40182902" },
      {
        "name": "Extruded stainless steel end formed tube",
        "code": "40182903"
      },
      { "name": "Extruded stainless steel multiport tube", "code": "40182904" },
      { "name": "Extruded stainless steel chamfered tube", "code": "40182905" },
      { "name": "Extruded stainless steel tube assembly", "code": "40182906" },
      { "name": "Rubber and plastic tubing", "code": "40183000" },
      { "name": "Rubber tubing", "code": "40183001" },
      { "name": "PVC plastic tubing", "code": "40183002" },
      { "name": "CPVC plastic tubing", "code": "40183003" },
      { "name": "HDPE plastic tubing", "code": "40183004" },
      { "name": "Low pressure rubber tubing", "code": "40183005" },
      { "name": "Heat shrink tubing", "code": "40183006" },
      { "name": "Rubber foam tubing", "code": "40183007" },
      { "name": "Polyurethane PUR tubing", "code": "40183008" },
      { "name": "Braided sleeve tubing", "code": "40183009" },
      {
        "name": "High pressure rubber tubing with assembly",
        "code": "40183010"
      },
      { "name": "Tube fittings", "code": "40183100" },
      { "name": "Tube elbow", "code": "40183101" },
      { "name": "Tube tee", "code": "40183102" },
      { "name": "Tube union", "code": "40183103" },
      { "name": "Tube cap", "code": "40183104" },
      { "name": "Tube nipple", "code": "40183105" },
      { "name": "Tube plug", "code": "40183106" },
      { "name": "Tube coupling", "code": "40183107" },
      { "name": "Tube bushing", "code": "40183108" },
      { "name": "Tube adapter", "code": "40183109" },
      { "name": "Tube connector", "code": "40183110" },
      { "name": "Tube cross", "code": "40183111" },
      { "name": "Tube reducer", "code": "40183112" },
      { "name": "Iron tubes", "code": "40183200" },
      { "name": "Iron bent tube", "code": "40183201" },
      { "name": "Iron pierced tube", "code": "40183202" },
      { "name": "Iron end formed tube", "code": "40183203" },
      { "name": "Iron multiport tube", "code": "40183204" },
      { "name": "Iron chamfered tube", "code": "40183205" },
      { "name": "Iron tube assembly", "code": "40183206" }
    ]
  },
  {
    "name": "Laboratory and Measuring and Observing and Testing Equipment",
    "code": "41000000",
    "subcategories": [
      { "name": "Laboratory and scientific equipment", "code": "41100000" },
      {
        "name": "Laboratory blending and dispersing and homogenizing equipment and supplies",
        "code": "41101500"
      },
      { "name": "Stomachers", "code": "41101502" },
      { "name": "Laboratory sprayers", "code": "41101503" },
      { "name": "Homogenizers", "code": "41101504" },
      { "name": "French pressure cells", "code": "41101505" },
      { "name": "Liquid measuring cans", "code": "41101515" },
      { "name": "Dounce homogenizers", "code": "41101516" },
      { "name": "Laboratory blenders or emulsifiers", "code": "41101518" },
      { "name": "Laboratory cell disruptor", "code": "41101519" },
      { "name": "Homogenizer parts and accessories", "code": "41101520" },
      {
        "name": "Laboratory boring and grinding and cutting and crushing and pressing equipment",
        "code": "41101700"
      },
      { "name": "Laboratory mills", "code": "41101701" },
      { "name": "Pestle or mortars", "code": "41101702" },
      { "name": "Tissue grinders", "code": "41101703" },
      { "name": "Laboratory crushers or pulverizers", "code": "41101705" },
      { "name": "Laboratory disintegrators", "code": "41101706" },
      { "name": "Laboratory presses", "code": "41101707" },
      { "name": "Laboratory grinder or polisher", "code": "41101708" },
      { "name": "Laboratory asphalt and concrete mixer", "code": "41101709" },
      { "name": "Laboratory grinder or pulverizer pot", "code": "41101710" },
      {
        "name": "Laboratory electron and solid state physics equipment",
        "code": "41101800"
      },
      { "name": "Electron guns", "code": "41101801" },
      { "name": "X ray generators", "code": "41101802" },
      { "name": "Coulometers", "code": "41101803" },
      { "name": "Electroscopes", "code": "41101804" },
      { "name": "Fluxmeters", "code": "41101805" },
      { "name": "Magnetometers", "code": "41101806" },
      { "name": "Electron diffraction apparatus", "code": "41101807" },
      { "name": "Neutron diffraction apparatus", "code": "41101808" },
      { "name": "Optical diffraction apparatus", "code": "41101809" },
      { "name": "Diffractometers", "code": "41101810" },
      { "name": "Electron probe x ray micro analyzer", "code": "41101811" },
      { "name": "Particle accelerator", "code": "41101812" },
      { "name": "Laboratory X ray equipment controller", "code": "41101813" },
      { "name": "Laboratory ionic equipment", "code": "41101900" },
      { "name": "Ion sources", "code": "41101901" },
      { "name": "Ion exchange apparatus", "code": "41101902" },
      { "name": "Ion implantation equipment", "code": "41101903" },
      { "name": "Laboratory heating and drying equipment", "code": "41102400" },
      { "name": "Gas burners", "code": "41102401" },
      { "name": "Spirit burners", "code": "41102402" },
      { "name": "Laboratory incinerators", "code": "41102403" },
      { "name": "Laboratory heaters", "code": "41102404" },
      { "name": "Heating mantles or tapes", "code": "41102405" },
      { "name": "Laboratory hotplates", "code": "41102406" },
      { "name": "Warming cabinets", "code": "41102407" },
      { "name": "Infrared dryers", "code": "41102410" },
      { "name": "Hot air blowers", "code": "41102412" },
      {
        "name": "Temperature cycling chambers or thermal cyclers",
        "code": "41102421"
      },
      { "name": "Dry baths or heating blocks", "code": "41102422" },
      { "name": "Stirring hotplates", "code": "41102423" },
      { "name": "Slide warmers", "code": "41102424" },
      { "name": "Slide dryers", "code": "41102425" },
      {
        "name": "Heating or drying equipment or accessories",
        "code": "41102426"
      },
      { "name": "Drying tower", "code": "41102427" },
      { "name": "Bibulous paper", "code": "41102428" },
      { "name": "Blood unit tubing heat sealer", "code": "41102429" },
      { "name": "Laboratory general purpose heat sealer", "code": "41102430" },
      {
        "name": "Laboratory entomological equipment and accessories",
        "code": "41102500"
      },
      { "name": "Laboratory insect containers", "code": "41102501" },
      { "name": "Rearing facilities for entomology", "code": "41102502" },
      { "name": "Fabric or netting for entomology", "code": "41102503" },
      { "name": "Entomological pinning equipment", "code": "41102504" },
      { "name": "Entomological mounting materials", "code": "41102505" },
      { "name": "Entomological trays", "code": "41102506" },
      { "name": "Entomological catching equipment", "code": "41102507" },
      { "name": "Entomological aspirators", "code": "41102508" },
      { "name": "Entomological dippers", "code": "41102509" },
      { "name": "Entomological monocups", "code": "41102510" },
      { "name": "Entomological sticky traps", "code": "41102511" },
      { "name": "Insect test kits", "code": "41102512" },
      { "name": "Entomological display units", "code": "41102513" },
      {
        "name": "Animal laboratory equipment and accessories",
        "code": "41102600"
      },
      { "name": "Laboratory cages for small animals", "code": "41102601" },
      { "name": "Aquaria equipment", "code": "41102602" },
      { "name": "Animal identification supplies", "code": "41102603" },
      { "name": "Animal catching devices", "code": "41102604" },
      { "name": "Fish aeration systems", "code": "41102605" },
      {
        "name": "Laboratory animal restraints or harnesses",
        "code": "41102606"
      },
      { "name": "Animal feeding needles", "code": "41102607" },
      { "name": "Animal testing equipment", "code": "41102608" },
      { "name": "Anaesthetic gun", "code": "41102609" },
      { "name": "Animal for research testing", "code": "41102610" },
      { "name": "Research animal food and diet", "code": "41102611" },
      { "name": "Research animal induction chamber", "code": "41102612" },
      { "name": "Research animal physiological test kit", "code": "41102613" },
      { "name": "Research animal bedding material", "code": "41102614" },
      { "name": "Crystallography equipment", "code": "41102700" },
      { "name": "Crystal lattice models", "code": "41102701" },
      { "name": "Scintillation crystal assemblies", "code": "41102702" },
      { "name": "Light scattering equipment", "code": "41102703" },
      { "name": "X ray diffraction equipment", "code": "41102704" },
      { "name": "Crystallizers", "code": "41102705" },
      { "name": "Crystal growing equipment", "code": "41102706" },
      { "name": "Histology equipment", "code": "41102900" },
      { "name": "Tissue embedding stations", "code": "41102901" },
      { "name": "Embedding molds", "code": "41102902" },
      { "name": "Embedding capsules", "code": "41102903" },
      { "name": "Embedding compounds", "code": "41102904" },
      { "name": "Histological staining apparatus", "code": "41102905" },
      { "name": "Tissue processors", "code": "41102909" },
      { "name": "Tissue culture apparatus", "code": "41102910" },
      {
        "name": "Histological knives or knife holders or blades",
        "code": "41102911"
      },
      { "name": "Histological glass knife makers", "code": "41102912" },
      {
        "name": "Histological hones or straps or compounds",
        "code": "41102913"
      },
      { "name": "Ultrasonic disintegrators", "code": "41102914" },
      {
        "name": "Histology sampling and dissecting stations",
        "code": "41102915"
      },
      { "name": "Microtomes", "code": "41102916" },
      { "name": "Microtome blades", "code": "41102917" },
      { "name": "Laboratory cover slippers", "code": "41102918" },
      { "name": "Solvent recyclers", "code": "41102919" },
      { "name": "Histology tissue cassettes", "code": "41102920" },
      { "name": "Histology paraffin", "code": "41102921" },
      { "name": "Automated cover slipping equipment", "code": "41102922" },
      { "name": "Somatic cell counter", "code": "41102923" },
      { "name": "Automated tissue cassette labeler", "code": "41102924" },
      {
        "name": "Histology formalin and solvent resistant permanent marker",
        "code": "41102925"
      },
      { "name": "Histology tissue cassette hopper", "code": "41102926" },
      { "name": "Histology tissue processing implement", "code": "41102927" },
      { "name": "Automated microscope slide labeler", "code": "41102928" },
      { "name": "Histology ultrasonic cleaner", "code": "41102929" },
      { "name": "Cytology slide processor", "code": "41102930" },
      { "name": "Paraffin dispenser", "code": "41102931" },
      { "name": "Laboratory cooling equipment", "code": "41103000" },
      { "name": "Refrigerated cooling plate probes", "code": "41103001" },
      { "name": "Cryostats", "code": "41103003" },
      { "name": "Fan circulated ovens", "code": "41103004" },
      {
        "name": "Ultra cold or ultralow upright cabinets or freezers",
        "code": "41103005"
      },
      { "name": "Cryogenic or liquid nitrogen freezers", "code": "41103006" },
      {
        "name": "Chilling units or cold water circulators",
        "code": "41103007"
      },
      { "name": "Refrigerated cooling modules", "code": "41103008" },
      { "name": "Blood bank refrigerators", "code": "41103010" },
      {
        "name": "General purpose refrigerators or refrigerator freezers",
        "code": "41103011"
      },
      {
        "name": "Flammable material storage refrigerators or refrigerator freezers",
        "code": "41103012"
      },
      {
        "name": "Explosion proof refrigerators or refrigerator freezers",
        "code": "41103013"
      },
      { "name": "Chromatography refrigerators", "code": "41103014" },
      { "name": "Blood bank freezers", "code": "41103015" },
      { "name": "Flammable material storage freezers", "code": "41103017" },
      { "name": "Plasma storage freezers", "code": "41103019" },
      { "name": "Ultra cold or ultralow chest freezers", "code": "41103020" },
      { "name": "Laboratory plate freezers", "code": "41103021" },
      { "name": "Cool transport or storage", "code": "41103022" },
      { "name": "Laboratory chillers", "code": "41103023" },
      { "name": "Cold traps", "code": "41103024" },
      {
        "name": "Laboratory cooling equipment accessories",
        "code": "41103025"
      },
      {
        "name": "Benchtop ice bucket or chilling container",
        "code": "41103026"
      },
      { "name": "Blood unit storage boot", "code": "41103027" },
      { "name": "Liquid nitrogen measuring stick", "code": "41103028" },
      { "name": "Cryogenic storage cane", "code": "41103029" },
      { "name": "Cryogenic storage bag or overwrap", "code": "41103030" },
      { "name": "Insulated transport cooler or tote", "code": "41103031" },
      {
        "name": "Cryogenic tube or vial permanent marker or label",
        "code": "41103032"
      },
      { "name": "Laboratory dewar flask", "code": "41103033" },
      {
        "name": "Cryobead system for microbial organism cryogenic storage",
        "code": "41103034"
      },
      {
        "name": "Fresh frozen plasma storage carton or frame",
        "code": "41103035"
      },
      {
        "name": "Refrigerated specimen storage rack or tray",
        "code": "41103036"
      },
      {
        "name": "Polymerase chain reaction PCR tube strip and plate cooler",
        "code": "41103037"
      },
      {
        "name": "Laboratory washing and cleaning equipment",
        "code": "41103200"
      },
      { "name": "Chemical engineering washers", "code": "41103201" },
      { "name": "Laboratory washing machines", "code": "41103202" },
      { "name": "Pipette washers", "code": "41103203" },
      { "name": "Washing machine racks or accessories", "code": "41103205" },
      { "name": "Laboratory washing detergents", "code": "41103206" },
      { "name": "Microplate washers", "code": "41103207" },
      { "name": "Blood bank cell washers", "code": "41103208" },
      { "name": "Laboratory wash bottles", "code": "41103209" },
      { "name": "Laboratory ultraviolet UV sterilizers", "code": "41103210" },
      {
        "name": "Laboratory wastewater treatment equipment",
        "code": "41103211"
      },
      { "name": "Lab glassware cleaning brush", "code": "41103212" },
      {
        "name": "Laboratory surface and glassware decontaminant",
        "code": "41103213"
      },
      { "name": "Fluid mechanics equipment", "code": "41103300" },
      { "name": "Liquid scintillation counters", "code": "41103301" },
      { "name": "Battery acid hydrometers", "code": "41103302" },
      { "name": "Densitometers", "code": "41103303" },
      { "name": "High vacuum equipment", "code": "41103305" },
      { "name": "Pneumatic vacuum equipment", "code": "41103306" },
      { "name": "Vacuum or mercury vapour equipment", "code": "41103307" },
      { "name": "High vacuum combustion apparatus", "code": "41103308" },
      { "name": "Flow injection analysis equipment", "code": "41103309" },
      {
        "name": "Gas or vapour concentration measuring instruments",
        "code": "41103310"
      },
      { "name": "Manometers", "code": "41103311" },
      { "name": "Viscosimeters", "code": "41103312" },
      { "name": "Depth indicators", "code": "41103313" },
      {
        "name": "Microscopic structure estimation apparatus",
        "code": "41103314"
      },
      { "name": "Solution strength estimation apparatus", "code": "41103315" },
      { "name": "Pycnometers", "code": "41103316" },
      { "name": "Surface tension measuring instruments", "code": "41103317" },
      { "name": "Nuclear densitometer", "code": "41103318" },
      { "name": "Concentration measurement instrument", "code": "41103319" },
      { "name": "Density measurement instrument", "code": "41103320" },
      {
        "name": "Floating body stability measurement apparatus",
        "code": "41103321"
      },
      { "name": "Draft gauge", "code": "41103322" },
      { "name": "Open channel acoustic flowmeter", "code": "41103323" },
      { "name": "Laboratory wave generator", "code": "41103324" },
      { "name": "Visual fluid flow apparatus", "code": "41103325" },
      { "name": "Wind tunnel", "code": "41103326" },
      { "name": "Current meter", "code": "41103327" },
      { "name": "Vacuum based pipette aspirator system", "code": "41103328" },
      {
        "name": "Laboratory environmental conditioning equipment",
        "code": "41103400"
      },
      { "name": "Contamination control screens", "code": "41103401" },
      { "name": "Microbiological aircontrol equipment", "code": "41103403" },
      { "name": "Isolation glove boxes", "code": "41103406" },
      { "name": "Anaerobic chamber", "code": "41103407" },
      {
        "name": "Refrigerated reach in environmental or growth chambers",
        "code": "41103408"
      },
      {
        "name": "Heated reach in environmental or growth chambers",
        "code": "41103409"
      },
      {
        "name": "Refrigerated and heated reach in environmental or growth chambers",
        "code": "41103410"
      },
      {
        "name": "Refrigerated walk in environmental or growth chambers",
        "code": "41103411"
      },
      {
        "name": "Heated walk in environmental or growth chambers",
        "code": "41103412"
      },
      {
        "name": "Refrigerated and heated walk in environmental or growth chambers",
        "code": "41103413"
      },
      {
        "name": "Laboratory environmental conditioning equipment accessories",
        "code": "41103414"
      },
      { "name": "Clean benches", "code": "41103415" },
      { "name": "Temperature cycle chamber", "code": "41103416" },
      { "name": "Indoor air quality monitor", "code": "41103417" },
      {
        "name": "Temperature and humidity walk in environmental chamber",
        "code": "41103418"
      },
      { "name": "Pollution environmental chamber", "code": "41103419" },
      { "name": "Ozone environmental chamber", "code": "41103420" },
      { "name": "Explosion environmental chamber", "code": "41103421" },
      { "name": "Laboratory enclosures and accessories", "code": "41103500" },
      { "name": "Ebuliometer", "code": "41103501" },
      { "name": "Fume hoods or cupboards", "code": "41103502" },
      { "name": "Laminar flow cabinets or stations", "code": "41103504" },
      { "name": "PCR enclosures", "code": "41103506" },
      { "name": "HEPA filtered enclosures", "code": "41103507" },
      { "name": "Carbon filtered enclosures", "code": "41103508" },
      { "name": "Laboratory scrubbers", "code": "41103509" },
      { "name": "Laboratory blowers", "code": "41103510" },
      { "name": "Laboratory enclosure accessories", "code": "41103511" },
      { "name": "Static eliminators", "code": "41103512" },
      { "name": "Tissue culture enclosures", "code": "41103513" },
      { "name": "Laboratory steam generator", "code": "41103514" },
      { "name": "Laboratory gas generator", "code": "41103515" },
      { "name": "Arm hood", "code": "41103516" },
      { "name": "Biological safety cabinet", "code": "41103517" },
      { "name": "Laboratory baths", "code": "41103700" },
      { "name": "Circulating baths", "code": "41103701" },
      { "name": "Thermostatic baths", "code": "41103702" },
      { "name": "Multiple baths", "code": "41103703" },
      { "name": "Biological baths", "code": "41103704" },
      { "name": "Organ baths", "code": "41103705" },
      { "name": "Water baths", "code": "41103706" },
      { "name": "Oil baths", "code": "41103707" },
      { "name": "Sand baths", "code": "41103708" },
      { "name": "Refrigerated baths", "code": "41103709" },
      { "name": "Orbital shaking water baths", "code": "41103710" },
      { "name": "Reciprocating shaking water baths", "code": "41103711" },
      { "name": "Immersion circulators", "code": "41103712" },
      { "name": "Viscosity baths", "code": "41103713" },
      { "name": "Tissue flotation baths", "code": "41103714" },
      { "name": "Laboratory bath accessories or supplies", "code": "41103715" },
      { "name": "Blood bank plasma thawing bath", "code": "41103716" },
      { "name": "Tissue culture bath", "code": "41103717" },
      { "name": "Histology tissue freezing bath", "code": "41103718" },
      {
        "name": "Laboratory mixing and stirring and shaking equipment and supplies",
        "code": "41103800"
      },
      { "name": "Laboratory mixers", "code": "41103801" },
      { "name": "Roller mixers", "code": "41103802" },
      { "name": "Stirring tables", "code": "41103803" },
      { "name": "Multi bank or flocculation equipment", "code": "41103804" },
      { "name": "Laboratory vibrators", "code": "41103805" },
      { "name": "Magnetic stirrers", "code": "41103806" },
      { "name": "Laboratory touch mixers", "code": "41103807" },
      { "name": "Platelet mixers", "code": "41103808" },
      { "name": "Hematology or chemistry mixers", "code": "41103809" },
      { "name": "Overhead stirrers", "code": "41103810" },
      { "name": "Orbital shakers", "code": "41103811" },
      { "name": "Reciprocal shakers", "code": "41103812" },
      { "name": "Rotating shakers", "code": "41103813" },
      { "name": "Vortex mixers", "code": "41103814" },
      { "name": "Tube rotators", "code": "41103815" },
      {
        "name": "Mixer or shaker accessories or attachments",
        "code": "41103816"
      },
      { "name": "Laboratory reactor", "code": "41103817" },
      { "name": "Microplate shaker", "code": "41103818" },
      { "name": "Tissue culture roller drum", "code": "41103819" },
      { "name": "Laboratory centrifuges and accessories", "code": "41103900" },
      { "name": "Microcentrifuges", "code": "41103901" },
      { "name": "Refrigerated microcentrifuges", "code": "41103902" },
      { "name": "Benchtop centrifuges", "code": "41103903" },
      { "name": "Refrigerated benchtop centrifuges", "code": "41103904" },
      { "name": "Floor centrifuges", "code": "41103905" },
      { "name": "Refrigerated floor centrifuges", "code": "41103906" },
      { "name": "Ultracentrifuges", "code": "41103907" },
      { "name": "Vacuum centrifuges", "code": "41103908" },
      { "name": "Centrifuge rotors", "code": "41103909" },
      { "name": "Centrifuge buckets", "code": "41103910" },
      { "name": "Centrifuge adapters", "code": "41103911" },
      { "name": "Centrifuge brushes", "code": "41103912" },
      { "name": "Laboratory centrifuge accessories", "code": "41103913" },
      { "name": "Cytocentrifuge", "code": "41103914" },
      {
        "name": "Cytocentrifuge cytofunnel or filter card or clamp",
        "code": "41103915"
      },
      {
        "name": "Centrifuge control board or printed circuit board",
        "code": "41103916"
      },
      {
        "name": "Centrifuge microplate carrier or sealing lid",
        "code": "41103917"
      },
      { "name": "Sampling equipment", "code": "41104000" },
      { "name": "Sample changers", "code": "41104001" },
      { "name": "Sample oxidizer", "code": "41104002" },
      { "name": "Sample preparation line", "code": "41104003" },
      { "name": "Sample preparation bombs", "code": "41104004" },
      { "name": "Laboratory bailers", "code": "41104005" },
      { "name": "Coliwasas", "code": "41104006" },
      { "name": "Water samplers", "code": "41104007" },
      { "name": "Air samplers or collectors", "code": "41104008" },
      { "name": "Air sampling pumps", "code": "41104009" },
      { "name": "Reagent kits for use with air samplers", "code": "41104010" },
      {
        "name": "Filters or other spare parts for samplers",
        "code": "41104011"
      },
      { "name": "Dust fall holders or jars", "code": "41104012" },
      { "name": "Sulphur dioxide or smoke samplers", "code": "41104013" },
      { "name": "Sample applicators", "code": "41104014" },
      { "name": "Plant samples analysis equipment", "code": "41104015" },
      { "name": "Air pollutant samplers", "code": "41104016" },
      { "name": "Sample holders", "code": "41104017" },
      { "name": "Solid phase extraction preparations", "code": "41104018" },
      { "name": "Sampling manifolds", "code": "41104019" },
      { "name": "Calcine element flow tray", "code": "41104020" },
      { "name": "Fraction collector", "code": "41104021" },
      { "name": "Sample shaper", "code": "41104022" },
      { "name": "Water sampler accessories", "code": "41104023" },
      {
        "name": "Specimen collection and transport containers and supplies",
        "code": "41104100"
      },
      { "name": "Slide or specimen mailers or shippers", "code": "41104101" },
      { "name": "Lancets", "code": "41104102" },
      { "name": "Heel warmers", "code": "41104103" },
      { "name": "Tourniquets", "code": "41104104" },
      { "name": "Specimen collection or transport bags", "code": "41104105" },
      { "name": "Phlebotomy trays or accessories", "code": "41104106" },
      {
        "name": "Vacuum blood collection tubes or containers",
        "code": "41104107"
      },
      {
        "name": "Non vacuum blood collection tubes or containers",
        "code": "41104108"
      },
      { "name": "Blood unit collection bags", "code": "41104109" },
      { "name": "Blood culture bottles", "code": "41104110" },
      { "name": "Cytology collection kits or containers", "code": "41104111" },
      { "name": "Urine collection containers", "code": "41104112" },
      { "name": "Frepp Sepp collection containers", "code": "41104114" },
      { "name": "Serum Filter collection containers", "code": "41104115" },
      { "name": "Swab collection or transport containers", "code": "41104116" },
      { "name": "Specimen holders", "code": "41104117" },
      { "name": "Specimen collection container", "code": "41104118" },
      { "name": "Bone tissue collection containers", "code": "41104119" },
      { "name": "Sedimentation rate tubes", "code": "41104120" },
      { "name": "Stool collection containers with media", "code": "41104121" },
      {
        "name": "Stool collection containers without media",
        "code": "41104122"
      },
      {
        "name": "Sputum collection apparatus or containers",
        "code": "41104123"
      },
      { "name": "Laboratory bone marrow biopsy trays", "code": "41104124" },
      {
        "name": "Histology or pathology preservative specimen container",
        "code": "41104125"
      },
      {
        "name": "Histology or pathology specimen container",
        "code": "41104126"
      },
      { "name": "Laboratory feeder", "code": "41104127" },
      {
        "name": "Culture specimen collector without swabs",
        "code": "41104128"
      },
      {
        "name": "Neonatal metabolic disorder screen collection card",
        "code": "41104129"
      },
      { "name": "Blood culture collection kit", "code": "41104130" },
      { "name": "Pinworm collection paddle or device", "code": "41104131" },
      { "name": "Urine strainer for renal calculi", "code": "41104132" },
      { "name": "Umbilical cord blood collector", "code": "41104133" },
      {
        "name": "Refrigerant pack for diagnostic specimen shippers",
        "code": "41104134"
      },
      {
        "name": "Template bleeding time incision device and blotting paper",
        "code": "41104135"
      },
      { "name": "Specimen drop box", "code": "41104136" },
      {
        "name": "Arterial and capillary blood gas collection kit",
        "code": "41104137"
      },
      {
        "name": "Laboratory water purification equipment and supplies",
        "code": "41104200"
      },
      { "name": "Water purification reagents", "code": "41104201" },
      {
        "name": "Deionization or demineralization equipment",
        "code": "41104202"
      },
      { "name": "Base exchange equipment", "code": "41104203" },
      { "name": "Reverse osmosis equipment", "code": "41104204" },
      { "name": "Ultra violet water purification units", "code": "41104205" },
      { "name": "Ultra pure water systems", "code": "41104206" },
      { "name": "Water analysis systems", "code": "41104207" },
      { "name": "Dehydrators", "code": "41104208" },
      { "name": "Deoxiders", "code": "41104209" },
      { "name": "Dissolvers", "code": "41104210" },
      { "name": "Softeners", "code": "41104211" },
      { "name": "Water filtration cartridges", "code": "41104212" },
      { "name": "Distilled or deionized water", "code": "41104213" },
      {
        "name": "Reverse osmosis equipment parts and accessories",
        "code": "41104214"
      },
      { "name": "Fermentation equipment", "code": "41104300" },
      { "name": "Standard fermentation units", "code": "41104301" },
      { "name": "Continuous culture apparatus", "code": "41104302" },
      { "name": "Anaerobic jars or accessories", "code": "41104303" },
      { "name": "Digestion systems", "code": "41104304" },
      { "name": "Inspissators", "code": "41104305" },
      { "name": "In vitro culture equipment", "code": "41104306" },
      { "name": "Microbiology fermentation equipment", "code": "41104307" },
      {
        "name": "Anaerobic environmental culture systems or supplies",
        "code": "41104308"
      },
      {
        "name": "Microaerophilic environmental cutlure system",
        "code": "41104309"
      },
      { "name": "Laboratory incubating equipment", "code": "41104400" },
      {
        "name": "Gravity convection general purpose incubators",
        "code": "41104401"
      },
      {
        "name": "Forced air or mechanical convection general purpose incubators",
        "code": "41104402"
      },
      { "name": "Tissue culture incubators", "code": "41104403" },
      {
        "name": "Cooled biological oxygen demand BOD incubators",
        "code": "41104404"
      },
      { "name": "Shaking incubators", "code": "41104405" },
      { "name": "Plate incubators", "code": "41104406" },
      {
        "name": "Water jacketed single chamber carbon dioxide incubators",
        "code": "41104407"
      },
      {
        "name": "Water jacketed dual chamber carbon dioxide incubators",
        "code": "41104408"
      },
      {
        "name": "Water jacketed single chamber carbon dioxide incubators with humidity control",
        "code": "41104409"
      },
      {
        "name": "Water jacketed dual chamber carbon dioxide incubators with humidity control",
        "code": "41104410"
      },
      {
        "name": "Dry wall single chamber carbon dioxide incubators",
        "code": "41104411"
      },
      {
        "name": "Dry wall dual chamber carbon dioxide incubators",
        "code": "41104412"
      },
      {
        "name": "Dry wall single chamber carbon dioxide incubators with humidity control",
        "code": "41104413"
      },
      {
        "name": "Dry wall dual chamber carbon dioxide incubators with humidity control",
        "code": "41104414"
      },
      {
        "name": "Water jacketed single chamber three gas incubators",
        "code": "41104415"
      },
      {
        "name": "Water jacketed dual chamber three gas incubators",
        "code": "41104416"
      },
      {
        "name": "Water jacketed single chamber three gas incubators with humidity control",
        "code": "41104417"
      },
      {
        "name": "Water jacketed dual chamber three gas incubators with humidity control",
        "code": "41104418"
      },
      {
        "name": "Dry wall single chamber three gas incubators",
        "code": "41104419"
      },
      {
        "name": "Dry wall dual chamber three gas incubators",
        "code": "41104420"
      },
      {
        "name": "Dry wall single chamber three gas incubators with humidity control",
        "code": "41104421"
      },
      {
        "name": "Dry wall dual chamber three gas incubators with humidity control",
        "code": "41104422"
      },
      { "name": "Refrigerated incubators", "code": "41104423" },
      { "name": "Incubator accessories", "code": "41104424" },
      { "name": "Carbon dioxide incubator", "code": "41104425" },
      { "name": "Media preparation apparatus", "code": "41104426" },
      { "name": "Platelet incubator", "code": "41104427" },
      { "name": "Laboratory ovens and accessories", "code": "41104500" },
      { "name": "Laboratory mechanical convection ovens", "code": "41104501" },
      { "name": "Gravity convection ovens", "code": "41104502" },
      { "name": "Ageing ovens", "code": "41104503" },
      { "name": "Cleanroom ovens", "code": "41104504" },
      { "name": "Laboratory quartz oven pots", "code": "41104505" },
      { "name": "Laboratory safety ovens", "code": "41104506" },
      { "name": "Laboratory microwave ovens", "code": "41104507" },
      { "name": "Induction dryers", "code": "41104508" },
      { "name": "Vacuum ovens", "code": "41104509" },
      { "name": "Drying cabinets or ovens", "code": "41104510" },
      { "name": "Hybridization ovens or incubators", "code": "41104511" },
      { "name": "Laboratory oven accessories", "code": "41104512" },
      { "name": "Magnesium melt oven", "code": "41104513" },
      { "name": "Laboratory furnaces and accessories", "code": "41104600" },
      { "name": "Laboratory box furnaces", "code": "41104601" },
      { "name": "Programmable box furnaces", "code": "41104602" },
      { "name": "Tube furnaces", "code": "41104603" },
      { "name": "Programmable tube furnaces", "code": "41104604" },
      { "name": "Crucible furnaces", "code": "41104605" },
      { "name": "Programmable crucible furnaces", "code": "41104606" },
      { "name": "Furnace control console", "code": "41104607" },
      { "name": "Programmable furnace control console", "code": "41104608" },
      { "name": "Laboratory safety furnaces", "code": "41104609" },
      { "name": "Laboratory furnace hearthplates", "code": "41104610" },
      {
        "name": "Laboratory furnace replacement insulation",
        "code": "41104611"
      },
      { "name": "Laboratory furnace accessories", "code": "41104612" },
      { "name": "Laboratory hot press", "code": "41104613" },
      {
        "name": "Laboratory freeze dryers and lyopholizers and accessories",
        "code": "41104700"
      },
      { "name": "Freeze dryers or lyopholizers", "code": "41104701" },
      { "name": "Freeze dryer glassware", "code": "41104702" },
      { "name": "Tray dryers", "code": "41104703" },
      { "name": "Freeze dryer or lyopholizer accessories", "code": "41104704" },
      {
        "name": "Laboratory decanting and distilling and evaporating and extracting equipment and supplies",
        "code": "41104800"
      },
      { "name": "Flask or retort units", "code": "41104801" },
      { "name": "Bi distillation units", "code": "41104802" },
      { "name": "Laboratory evaporators", "code": "41104803" },
      { "name": "Vacuum or rotary evaporators", "code": "41104804" },
      { "name": "Nitrogen blowdown evaporators", "code": "41104805" },
      { "name": "Extracting equipment for laboratories", "code": "41104806" },
      { "name": "Fat extractors", "code": "41104807" },
      { "name": "Crude fiber extractors", "code": "41104808" },
      { "name": "Sedimentological analyzing unit", "code": "41104809" },
      { "name": "Fractionation apparatus", "code": "41104810" },
      { "name": "Density gradient fractionators", "code": "41104811" },
      {
        "name": "Distillation pipings or columns or fittings",
        "code": "41104812"
      },
      { "name": "Reflux components", "code": "41104813" },
      { "name": "Laboratory heat exchange condensers", "code": "41104814" },
      {
        "name": "Kjeldahl nitrogen determination apparatus",
        "code": "41104815"
      },
      { "name": "Vacuum or centrifugal concentrators", "code": "41104816" },
      { "name": "Extraction thimbles", "code": "41104817" },
      { "name": "Structured packing", "code": "41104818" },
      { "name": "Cell harvester", "code": "41104819" },
      {
        "name": "Laboratory fractional distillation apparatus",
        "code": "41104820"
      },
      { "name": "Static sample concentrator", "code": "41104821" },
      {
        "name": "Laboratory filtering equipment and supplies",
        "code": "41104900"
      },
      { "name": "Laboratory line filters", "code": "41104901" },
      { "name": "Gel filtration equipment", "code": "41104902" },
      { "name": "Ultra filtration equipment", "code": "41104903" },
      { "name": "Sintered cell filters", "code": "41104904" },
      { "name": "Thin channel filtration equipment", "code": "41104905" },
      { "name": "Reverse osmosis filtration equipment", "code": "41104906" },
      { "name": "Molecular filtration equipment", "code": "41104907" },
      { "name": "Laboratory cartridge element filters", "code": "41104908" },
      { "name": "Laboratory filter holders or cyclones", "code": "41104909" },
      { "name": "Laboratory multi sheet or press filters", "code": "41104910" },
      { "name": "Laboratory air filtration systems", "code": "41104911" },
      { "name": "Fluid presses filter", "code": "41104912" },
      { "name": "Bioseparation filters", "code": "41104913" },
      { "name": "Bottletops or filtration cups", "code": "41104914" },
      { "name": "Capsules filters", "code": "41104915" },
      { "name": "Centrifugal filters", "code": "41104916" },
      { "name": "Laboratory environmental filters", "code": "41104917" },
      { "name": "Laboratory glass filters", "code": "41104918" },
      { "name": "Laboratory HEPA filters", "code": "41104919" },
      { "name": "Hybridization filters", "code": "41104920" },
      { "name": "Laboratory membrane filters", "code": "41104921" },
      { "name": "Syringe filters", "code": "41104922" },
      { "name": "Multiwell plate filters", "code": "41104923" },
      { "name": "Microbiology filters", "code": "41104924" },
      {
        "name": "Laboratory filtration hardware or accessories",
        "code": "41104925"
      },
      { "name": "Silica bed filter", "code": "41104926" },
      { "name": "Filter support screens", "code": "41104927" },
      { "name": "Laboratory bottle receiver", "code": "41104928" },
      { "name": "Laboratory filter papers", "code": "41104929" },
      { "name": "Molecular sieve", "code": "41104930" },
      { "name": "Serum separator tube filter", "code": "41104931" },
      { "name": "Cell strainer", "code": "41104932" },
      {
        "name": "Laboratory sieves and sifting equipment and supplies",
        "code": "41105000"
      },
      { "name": "Laboratory separators", "code": "41105001" },
      { "name": "Laboratory sifting equipment", "code": "41105002" },
      { "name": "Test sieves", "code": "41105003" },
      { "name": "Laboratory pumps and tubing", "code": "41105100" },
      { "name": "Laboratory vacuum pumps", "code": "41105101" },
      { "name": "Peristaltic pumps", "code": "41105102" },
      { "name": "Laboratory centrifugal pumps", "code": "41105103" },
      { "name": "Syringe pumps", "code": "41105104" },
      { "name": "Metering pumps", "code": "41105105" },
      { "name": "Chromatography pumps", "code": "41105106" },
      { "name": "Laboratory drum pumps", "code": "41105107" },
      { "name": "Laboratory general purpose tubing", "code": "41105108" },
      { "name": "Rotary vane pumps", "code": "41105109" },
      {
        "name": "Laboratory slide stainer equipment and accessories",
        "code": "41105200"
      },
      { "name": "Histology or cytology slide stainers", "code": "41105201" },
      { "name": "Hematology slide stainers", "code": "41105202" },
      { "name": "Microbiology slide stainers", "code": "41105203" },
      { "name": "Laboratory slide stainer accessories", "code": "41105204" },
      { "name": "Microslide making equipment", "code": "41105205" },
      { "name": "Immunohistochemistry autostainer", "code": "41105206" },
      {
        "name": "Immunohistochemistry autostainer accessory",
        "code": "41105207"
      },
      { "name": "Laboratory staining rack and tray", "code": "41105208" },
      {
        "name": "Multidepartment manual slide stainer set",
        "code": "41105209"
      },
      {
        "name": "Laboratory electrophoresis and blotting system and supplies",
        "code": "41105300"
      },
      { "name": "Gel boxes", "code": "41105301" },
      { "name": "Gel dryers", "code": "41105302" },
      { "name": "Electrophoresis system power supplies", "code": "41105303" },
      { "name": "Transilluminators", "code": "41105304" },
      { "name": "Electrophoresis system accessories", "code": "41105305" },
      {
        "name": "Instrumentation for capillary electrophoresis",
        "code": "41105307"
      },
      { "name": "Capillaries or cartridges", "code": "41105308" },
      {
        "name": "Kits or reagents for capillary electrophoresis",
        "code": "41105309"
      },
      { "name": "Blotting or transfer accessories", "code": "41105310" },
      { "name": "Blotting or transfer apparatus", "code": "41105311" },
      { "name": "Combs or plates or spacers or trays", "code": "41105312" },
      {
        "name": "Cassettes or related detection accessories",
        "code": "41105313"
      },
      { "name": "Gel documentation systems", "code": "41105314" },
      { "name": "Gel documentation accessories", "code": "41105315" },
      { "name": "Ultraviolet crosslinkers", "code": "41105316" },
      { "name": "Agarose gel making reagents", "code": "41105317" },
      { "name": "Agarose premade gels", "code": "41105318" },
      { "name": "Polyacrylamide gel making reagents", "code": "41105319" },
      { "name": "Polyacrylamide premade gels", "code": "41105320" },
      { "name": "Nucleic acid gels stain", "code": "41105321" },
      { "name": "Polyacrylamide gels stain", "code": "41105322" },
      {
        "name": "Electrophoresis premade buffers or solutions",
        "code": "41105323"
      },
      {
        "name": "Deoxyribonucleic acid DNA or ribonucleic acid RNA probes",
        "code": "41105324"
      },
      {
        "name": "Microwells plates for deoxyribonucleic acid DNA or deoxyribonucleic acid DNA hybridization",
        "code": "41105325"
      },
      { "name": "Hybridization reagents or buffers", "code": "41105326" },
      { "name": "Conjugated nucleotides or oligomers", "code": "41105327" },
      {
        "name": "Premade northern or southern or western blots",
        "code": "41105328"
      },
      { "name": "Blocking agents", "code": "41105329" },
      {
        "name": "Control proteins or cell lysates or tissue lysates",
        "code": "41105330"
      },
      {
        "name": "Protein chemifluorescent detection reagents or kits or substrates",
        "code": "41105331"
      },
      {
        "name": "Protein chemiluminescent detection reagents or kits or substrates",
        "code": "41105332"
      },
      {
        "name": "Protein chromogenic detection reagents or kits or substrates",
        "code": "41105333"
      },
      {
        "name": "Deoxyribonucleic acid DNA quantitation markers",
        "code": "41105334"
      },
      {
        "name": "Deoxyribonucleic acid DNA size markers or standards",
        "code": "41105335"
      },
      { "name": "Isoelectric focusing IEF markers", "code": "41105336" },
      { "name": "Protein electrophoresis markers", "code": "41105337" },
      {
        "name": "Ribonucleic acid RNA markers or standards",
        "code": "41105338"
      },
      { "name": "Blotting membranes", "code": "41105339" },
      { "name": "Electrophoresis system", "code": "41105340" },
      { "name": "Autoradiography film", "code": "41105341" },
      {
        "name": "Kit and reagent for agarose gel electrophoresis",
        "code": "41105342"
      },
      {
        "name": "Kit and reagent for tape based electrophoresis",
        "code": "41105343"
      },
      {
        "name": "Electrophoresis sample applicator or blade",
        "code": "41105344"
      },
      { "name": "Protein gel stain", "code": "41105345" },
      {
        "name": "Nucleic acid DNA and RNA extraction and purification and quantitation kits and components",
        "code": "41105500"
      },
      {
        "name": "Deoxyribonucleic acid DNA cleanup or gel extraction kits",
        "code": "41105501"
      },
      {
        "name": "Kits for deoxyribonucleic acid DNA extraction from food",
        "code": "41105502"
      },
      { "name": "Electroelution systems", "code": "41105503" },
      {
        "name": "Genomic deoxyribonucleic acid DNA purification kits",
        "code": "41105504"
      },
      {
        "name": "High throughput screening HTS systems in nucleic acid purification",
        "code": "41105505"
      },
      {
        "name": "Kits for purification of messenger ribonucleic acid mRNA",
        "code": "41105506"
      },
      { "name": "Nucleic acid isolation magnetic beads", "code": "41105507" },
      { "name": "Nucleic acids coprecipitants", "code": "41105508" },
      { "name": "Nucleic acids quantitation kits", "code": "41105509" },
      {
        "name": "Phage deoxyribonucleic acid DNA purification kits",
        "code": "41105510"
      },
      {
        "name": "Kits for plasmids deoxyribonucleic acid DNA extraction from yeast",
        "code": "41105511"
      },
      {
        "name": "Plasmids or cosmids or bacterial artificial chromosomes BAC purification kit",
        "code": "41105512"
      },
      { "name": "Labeled nucleic acid purification kits", "code": "41105513" },
      {
        "name": "Reagents for nucleic acid extraction or precipitation or resuspension",
        "code": "41105514"
      },
      {
        "name": "Ribonucleic acid RNA cleanup or stabilization materials",
        "code": "41105515"
      },
      {
        "name": "Ribonucleic acid RNA gel extraction kits",
        "code": "41105516"
      },
      {
        "name": "Kits for nucleic acid extraction from plant cells or tissue",
        "code": "41105517"
      },
      {
        "name": "Total ribonucleic acid RNA purification kits",
        "code": "41105518"
      },
      {
        "name": "Viral deoxyribonucleic acid DNA purification kits",
        "code": "41105519"
      },
      {
        "name": "Viral ribonucleic acid RNA purification kits",
        "code": "41105520"
      },
      {
        "name": "Deoxyribonucleic acid DNA detection system",
        "code": "41105521"
      },
      {
        "name": "Deoxyribonucleic acid DNA sequencing products",
        "code": "41105600"
      },
      { "name": "Kits or enzymes for sequencing", "code": "41105601" },
      { "name": "Gene arrays", "code": "41105700" },
      {
        "name": "Acid nucleic immobilized on glass or nylon membranes",
        "code": "41105701"
      },
      {
        "name": "In vitro transcription and translation products",
        "code": "41105800"
      },
      { "name": "Oligomer conjugates or derivatives", "code": "41105801" },
      { "name": "Ribonucleotides", "code": "41105802" },
      {
        "name": "Transcription or translation systems or kits",
        "code": "41105803"
      },
      { "name": "Translation labeling accessories", "code": "41105804" },
      { "name": "Libraries and related materials", "code": "41105900" },
      { "name": "Animal tissues or bodily fluids", "code": "41105901" },
      {
        "name": "Complementary deoxyribonucleic acid cDNA libraries",
        "code": "41105902"
      },
      {
        "name": "Complementary deoxyribonucleic acid cDNA synthesis kits",
        "code": "41105903"
      },
      { "name": "Genomic libraries", "code": "41105904" },
      { "name": "Library construction kits", "code": "41105905" },
      { "name": "Protein or peptide display libraries", "code": "41105906" },
      { "name": "Two hybrid libraries or systems", "code": "41105907" },
      { "name": "Viral packaging kits", "code": "41105908" },
      { "name": "Peptide synthesizer", "code": "41105909" },
      {
        "name": "Nucleic acid labeling and detection systems",
        "code": "41106000"
      },
      {
        "name": "Nucleic acid chemifluorescent detection materials",
        "code": "41106001"
      },
      {
        "name": "Nucleic acid chemiluminescent detection materials",
        "code": "41106002"
      },
      {
        "name": "Nucleic acid chromogenic detection materials",
        "code": "41106003"
      },
      {
        "name": "Nucleic acid non radioactive labeling kits",
        "code": "41106004"
      },
      { "name": "Nucleic acid radioactive labeling kits", "code": "41106005" },
      { "name": "Radio nucleotides or nucleosides", "code": "41106006" },
      { "name": "Deoxyribonucleic acid DNA analysis kits", "code": "41106100" },
      { "name": "Cytogenetics kits", "code": "41106101" },
      {
        "name": "Differential display or subtraction kits",
        "code": "41106102"
      },
      { "name": "Deoxyribonucleic acid DNA typing kits", "code": "41106103" },
      { "name": "Nuclease protection assays", "code": "41106104" },
      {
        "name": "Microorganism propagation and transformation media and kits and equipment",
        "code": "41106200"
      },
      { "name": "Antimycotics", "code": "41106201" },
      { "name": "Bacteria competent cells", "code": "41106202" },
      { "name": "Bacteria transformation kits", "code": "41106203" },
      {
        "name": "Bottled agar media or stabs for bacteria",
        "code": "41106204"
      },
      { "name": "Brent supplement mixtures for yeast", "code": "41106205" },
      { "name": "Complete supplement mixtures for yeast", "code": "41106206" },
      { "name": "Dictyostelium discoideum media", "code": "41106207" },
      { "name": "Electroporation cuvettes", "code": "41106208" },
      {
        "name": "Hollenberg supplement mixtures for yeast",
        "code": "41106209"
      },
      {
        "name": "Media or supplements for schizosaccharomyces pombe",
        "code": "41106210"
      },
      {
        "name": "Media ingredients or additives schizosaccharomyces pombe",
        "code": "41106211"
      },
      {
        "name": "Media ingredients or additives for bacteria",
        "code": "41106212"
      },
      { "name": "Premixed media dry", "code": "41106213" },
      {
        "name": "Reagents for preparing competent bacteria",
        "code": "41106214"
      },
      { "name": "Reagents for preparing competent yeast", "code": "41106215" },
      { "name": "Rich media for yeast", "code": "41106216" },
      { "name": "Specialty plates for bacteria", "code": "41106217" },
      { "name": "Specialty premixed media dry", "code": "41106218" },
      {
        "name": "Synthetic complete supplement mixtures for yeast",
        "code": "41106219"
      },
      { "name": "Synthetic media for yeast", "code": "41106220" },
      { "name": "Yeast competent cells", "code": "41106221" },
      { "name": "Yeast transformation kits", "code": "41106222" },
      {
        "name": "Yeast nitrogen bases YNB or yeast nitrogen base YNB variants",
        "code": "41106223"
      },
      { "name": "Electroporation system", "code": "41106224" },
      { "name": "Bottled broth media for bacteria", "code": "41106225" },
      { "name": "Bottled broth media for yeast", "code": "41106226" },
      { "name": "Specialty plate for yeast", "code": "41106227" },
      { "name": "Bottled agar media or stabs for yeast", "code": "41106228" },
      {
        "name": "Bottled saline or water for microbiology",
        "code": "41106229"
      },
      {
        "name": "Inoculum fluid for identification and sensitivity panels",
        "code": "41106230"
      },
      {
        "name": "Contact agar plate for environmental microbial presence",
        "code": "41106231"
      },
      {
        "name": "Automated microbial culture plate streaker",
        "code": "41106232"
      },
      { "name": "Biolistic particle delivery system", "code": "41106233" },
      {
        "name": "Polymerase chain reaction PCR and reverse transcriptase polymerase chain reaction RT PCR products",
        "code": "41106300"
      },
      { "name": "Deoxynucleotide triphosphates dNTPs", "code": "41106301" },
      {
        "name": "Gene specific polymerase chain reaction PCR kits",
        "code": "41106302"
      },
      {
        "name": "Kits for polymerase chain reaction PCR purification",
        "code": "41106303"
      },
      {
        "name": "Kits for messenger ribonucleic acid mRNA quantitation by polymerase chain reaction PCR",
        "code": "41106304"
      },
      { "name": "Nucleotides", "code": "41106305" },
      { "name": "Polymerase chain reaction PCR buffers", "code": "41106306" },
      {
        "name": "Polymerase chain reaction PCR optimizing products",
        "code": "41106307"
      },
      {
        "name": "Polymerase chain reaction PCR or reverse transcriptase polymerase chain reaction RT PCR primers",
        "code": "41106308"
      },
      {
        "name": "Premade complementary deoxyribonucleic acid cDNA",
        "code": "41106309"
      },
      {
        "name": "Purified genomic deoxyribonucleic acids DNA",
        "code": "41106310"
      },
      { "name": "Purified ribonucleic acids RNA", "code": "41106311" },
      {
        "name": "Rapid amplification or complementary deoxyribonucleic acid ends RACE technology products",
        "code": "41106312"
      },
      {
        "name": "Reverse transcriptase polymerase chain reaction RT PCR kits",
        "code": "41106313"
      },
      {
        "name": "Thermostable deoxyribonucleic acid DNA polymerases or kits",
        "code": "41106314"
      },
      { "name": "Primers and linkers and adaptors", "code": "41106400" },
      { "name": "Adaptors or linkers", "code": "41106401" },
      { "name": "Miscellaneous primers", "code": "41106402" },
      { "name": "Sequencing primers", "code": "41106403" },
      { "name": "Protein expression products", "code": "41106500" },
      { "name": "Bacterial expression kits", "code": "41106501" },
      { "name": "Eucariotic transfection reagents", "code": "41106502" },
      { "name": "Inducers or regulators", "code": "41106503" },
      { "name": "Insect cells", "code": "41106504" },
      { "name": "Insect expression kits", "code": "41106505" },
      { "name": "Insect media", "code": "41106506" },
      { "name": "Insect medium supplements or reagents", "code": "41106507" },
      { "name": "Mammalian cell expression kits", "code": "41106508" },
      { "name": "Mammalian cells", "code": "41106509" },
      {
        "name": "Kits for protein extraction from mammalian cells or tissues",
        "code": "41106510"
      },
      {
        "name": "Kits for protein extraction from bacteria",
        "code": "41106511"
      },
      { "name": "Kits for protein extraction from yeast", "code": "41106512" },
      { "name": "Reporter gene assay", "code": "41106513" },
      { "name": "Stable mammalian cell lines", "code": "41106514" },
      { "name": "Yeast expression kits", "code": "41106515" },
      { "name": "Enzyme expression consumables", "code": "41106516" },
      { "name": "Vectors", "code": "41106600" },
      { "name": "Chromosome targeting vectors", "code": "41106601" },
      { "name": "Bacterial expression vectors", "code": "41106602" },
      { "name": "Cassette vectors", "code": "41106603" },
      { "name": "Display vector maps or sequences", "code": "41106604" },
      {
        "name": "Enzyme reporter vector maps or sequences",
        "code": "41106605"
      },
      {
        "name": "Expression complementary deoxyribonucleic acid cDNA vectors",
        "code": "41106606"
      },
      {
        "name": "Fluorescent protein vector maps or sequences",
        "code": "41106607"
      },
      { "name": "Fusion vectors", "code": "41106608" },
      { "name": "Gene targeting vectors", "code": "41106609" },
      { "name": "General cloning vectors", "code": "41106610" },
      { "name": "Hybrid system vectors or kits", "code": "41106611" },
      { "name": "Insect expression vectors", "code": "41106612" },
      { "name": "Library construction vectors", "code": "41106613" },
      { "name": "Mammalian cell expression vectors", "code": "41106614" },
      {
        "name": "Polymerase chain reaction PCR cloning vectors or kits",
        "code": "41106615"
      },
      {
        "name": "Phage or viral deoxyribonucleic acids DNA",
        "code": "41106616"
      },
      { "name": "Plasmid mutagenesis vectors or kits", "code": "41106617" },
      {
        "name": "Recombination mediated cloning or expression products",
        "code": "41106618"
      },
      { "name": "Sequencing vectors", "code": "41106619" },
      {
        "name": "Signal transduction reporter vector maps or sequences",
        "code": "41106620"
      },
      {
        "name": "Virus mediated expression vectors or kits",
        "code": "41106621"
      },
      { "name": "Yeast expression vectors", "code": "41106622" },
      {
        "name": "Laboratory botanical equipment and accessories",
        "code": "41106700"
      },
      { "name": "Leaf area meter", "code": "41106701" },
      { "name": "Photosynthesis measurement apparatus", "code": "41106702" },
      {
        "name": "Plant growth measuring instrument or auxanometer",
        "code": "41106703"
      },
      { "name": "Chlorophyl measuring instrument", "code": "41106704" },
      { "name": "Phytotron", "code": "41106705" },
      { "name": "Minirhizotron", "code": "41106706" },
      { "name": "Vegetation nutrition inspection device", "code": "41106707" },
      { "name": "Plant collection device set", "code": "41106708" },
      {
        "name": "Measuring and observing and testing instruments",
        "code": "41110000"
      },
      { "name": "Weight measuring instruments", "code": "41111500" },
      { "name": "Electronic toploading balances", "code": "41111501" },
      { "name": "Laboratory balances", "code": "41111502" },
      { "name": "Mechanical balances", "code": "41111503" },
      { "name": "Pull spring balances", "code": "41111504" },
      { "name": "Calibration weights or weight sets", "code": "41111505" },
      { "name": "Animal weighing scales", "code": "41111506" },
      { "name": "Bench scales", "code": "41111507" },
      { "name": "Bodyweight measuring scales", "code": "41111508" },
      { "name": "Floor or platform scales", "code": "41111509" },
      { "name": "Postal scales", "code": "41111510" },
      { "name": "Truck or rail scales", "code": "41111511" },
      { "name": "Triple beam balances", "code": "41111512" },
      { "name": "Moisture balances", "code": "41111513" },
      {
        "name": "Balance weighing containers or bowls or boats or papers",
        "code": "41111515"
      },
      { "name": "Weight measuring instrument accessories", "code": "41111516" },
      { "name": "Analytical balances", "code": "41111517" },
      { "name": "Axle load scales", "code": "41111518" },
      { "name": "Crane scale", "code": "41111519" },
      { "name": "Conveyor weighting scale", "code": "41111520" },
      { "name": "Thermogravimeter", "code": "41111521" },
      { "name": "Hopper scale", "code": "41111522" },
      { "name": "Weight prototype", "code": "41111523" },
      { "name": "Price indicating scale", "code": "41111524" },
      { "name": "Automatic packer scale", "code": "41111525" },
      { "name": "Automatic selective scale", "code": "41111526" },
      { "name": "Domestic luggage scale", "code": "41111527" },
      {
        "name": "Length and thickness and distance measuring instruments",
        "code": "41111600"
      },
      { "name": "Micrometers", "code": "41111601" },
      { "name": "Pedometers", "code": "41111602" },
      { "name": "Rangefinders", "code": "41111603" },
      { "name": "Rulers", "code": "41111604" },
      { "name": "Strain gauges", "code": "41111605" },
      { "name": "Tellurometers", "code": "41111606" },
      { "name": "Thread counters or gauges", "code": "41111607" },
      { "name": "Distance meters", "code": "41111613" },
      { "name": "Height gauges", "code": "41111614" },
      { "name": "Laser measuring systems", "code": "41111615" },
      { "name": "Measuring wheels for distance", "code": "41111616" },
      { "name": "Feeler gauges", "code": "41111617" },
      { "name": "Gage block set", "code": "41111618" },
      { "name": "Go or no go gauge", "code": "41111619" },
      { "name": "Etalon wedge", "code": "41111620" },
      { "name": "Calipers", "code": "41111621" },
      { "name": "Micrometer calipers", "code": "41111622" },
      { "name": "Thickness measuring devices", "code": "41111623" },
      { "name": "Alexometer", "code": "41111624" },
      { "name": "Curvimeter", "code": "41111625" },
      { "name": "Gauge block", "code": "41111626" },
      { "name": "Vee block", "code": "41111627" },
      { "name": "Wire meter", "code": "41111628" },
      {
        "name": "Cable or wire extension linear position sensor",
        "code": "41111629"
      },
      { "name": "Dial indicator or dial gauge", "code": "41111630" },
      { "name": "Radius gauge", "code": "41111631" },
      { "name": "Cylinder gauge", "code": "41111632" },
      { "name": "Air micrometer", "code": "41111633" },
      { "name": "Electrical micrometer", "code": "41111634" },
      { "name": "Gage block comparator", "code": "41111635" },
      { "name": "Cylinder diameter measuring equipment", "code": "41111636" },
      { "name": "Conical cup tester", "code": "41111637" },
      { "name": "Cigarette circumference tester", "code": "41111638" },
      { "name": "Screw plug gauge and cross recess", "code": "41111639" },
      { "name": "Welding gauge", "code": "41111640" },
      { "name": "Thread pitch gauge", "code": "41111641" },
      { "name": "Rail joint gap gauge", "code": "41111642" },
      { "name": "Wire gauge", "code": "41111643" },
      { "name": "Pin gauge", "code": "41111644" },
      { "name": "Sine bar", "code": "41111645" },
      { "name": "Telescoping gauge", "code": "41111646" },
      { "name": "Hole gauge", "code": "41111647" },
      { "name": "Taper gauge", "code": "41111648" },
      { "name": "Tire depth gauge", "code": "41111649" },
      { "name": "Drill gauge", "code": "41111650" },
      { "name": "Cathetometer", "code": "41111651" },
      { "name": "Compressometer", "code": "41111652" },
      {
        "name": "Viewing and observing instruments and accessories",
        "code": "41111700"
      },
      { "name": "Ion microscopes", "code": "41111701" },
      { "name": "Monocular microscopes", "code": "41111702" },
      { "name": "Stereo or dissecting light microscopes", "code": "41111703" },
      { "name": "Illuminators for microscopes", "code": "41111704" },
      { "name": "Microscope objectives", "code": "41111705" },
      { "name": "Photo attachments for microscopes", "code": "41111706" },
      { "name": "Profile projectors", "code": "41111707" },
      { "name": "Video attachments for microscopes", "code": "41111708" },
      { "name": "Binocular light compound microscopes", "code": "41111709" },
      {
        "name": "Combination electron and light microscopes",
        "code": "41111710"
      },
      { "name": "Electron microscopes", "code": "41111711" },
      { "name": "Inverted microscopes", "code": "41111712" },
      { "name": "Magnifiers", "code": "41111713" },
      { "name": "Loupes", "code": "41111714" },
      { "name": "Telescopes", "code": "41111715" },
      { "name": "Borescope inspection equipment", "code": "41111716" },
      { "name": "Binoculars", "code": "41111717" },
      { "name": "Metallurgical microscopes", "code": "41111718" },
      { "name": "Darkfield microscopes", "code": "41111719" },
      { "name": "Scanning electron microscopes", "code": "41111720" },
      { "name": "Transmission electron microscopes", "code": "41111721" },
      { "name": "Fluorescent microscopes", "code": "41111722" },
      {
        "name": "Scanning light or spinning disk or laser scanning microscopes",
        "code": "41111723"
      },
      { "name": "Scanning probe microscopes", "code": "41111724" },
      { "name": "Polarizing microscopes", "code": "41111725" },
      { "name": "Acoustic microscopes", "code": "41111726" },
      { "name": "Projection microscopes", "code": "41111727" },
      { "name": "Wide field microscopes", "code": "41111728" },
      { "name": "Microscope eyepieces", "code": "41111729" },
      { "name": "Microscope condensers", "code": "41111730" },
      { "name": "Microscope collectors", "code": "41111731" },
      { "name": "Microscope tubes", "code": "41111733" },
      { "name": "Microscope stages", "code": "41111734" },
      { "name": "Automated microscope stages", "code": "41111735" },
      { "name": "Microscope covers", "code": "41111736" },
      { "name": "Videoscopes", "code": "41111737" },
      { "name": "Fiberscopes", "code": "41111738" },
      { "name": "Laboratory microscope replacement bulbs", "code": "41111739" },
      { "name": "Automated optical inspection system", "code": "41111740" },
      {
        "name": "Microscope differential interference contrast equipment",
        "code": "41111741"
      },
      { "name": "Periscope or protectorscope", "code": "41111742" },
      { "name": "Autocollimator", "code": "41111743" },
      { "name": "Microscopic micrometer", "code": "41111744" },
      { "name": "Micromanipulator", "code": "41111745" },
      { "name": "Optical lever", "code": "41111746" },
      { "name": "Industrial fiberscope", "code": "41111747" },
      { "name": "Multimedia image microscope", "code": "41111748" },
      { "name": "Phase contrast microscope", "code": "41111749" },
      { "name": "Microscope anti vibration mat or table", "code": "41111750" },
      { "name": "Microscope fluorescence filter", "code": "41111751" },
      { "name": "Microscope filter cube or box", "code": "41111752" },
      { "name": "Manual microscope nosepiece", "code": "41111753" },
      { "name": "Motorized microscope nosepiece", "code": "41111754" },
      { "name": "Microscope knob", "code": "41111755" },
      { "name": "Microscope base unit", "code": "41111756" },
      { "name": "Microscope head", "code": "41111757" },
      { "name": "Microscope body", "code": "41111758" },
      { "name": "Trinocular light compound microscope", "code": "41111759" },
      {
        "name": "Microscope sample manipulation system and accessories",
        "code": "41111760"
      },
      {
        "name": "Transmission electron microscopy TEM grid or support film",
        "code": "41111761"
      },
      {
        "name": "Transmission electron microscopy TEM grid storage box",
        "code": "41111762"
      },
      { "name": "Microscope cleaning kit", "code": "41111763" },
      { "name": "Microscope fitting", "code": "41111764" },
      { "name": "Microscope stage slide clip", "code": "41111765" },
      { "name": "Handheld digital microscope", "code": "41111766" },
      {
        "name": "Microscope stage warming system or incubator and accessories",
        "code": "41111767"
      },
      { "name": "Lighted box agglutination viewer", "code": "41111768" },
      { "name": "Lighted mirror agglutination viewer", "code": "41111769" },
      { "name": "Microscope pointer", "code": "41111770" },
      { "name": "Non destructive examination equipment", "code": "41111800" },
      { "name": "Eddy current examination equipment", "code": "41111801" },
      { "name": "Liquid penetrant examination equipment", "code": "41111802" },
      { "name": "Magnetic particle examination equipment", "code": "41111803" },
      { "name": "Ultrasonic examination equipment", "code": "41111804" },
      { "name": "CO 60 radiography examination equipment", "code": "41111805" },
      {
        "name": "CS 137 radiography examination equipment",
        "code": "41111806"
      },
      {
        "name": "IR 192 radiography examination equipment",
        "code": "41111807"
      },
      { "name": "X ray radiography examination equipment", "code": "41111808" },
      { "name": "Leak testing equipment", "code": "41111809" },
      { "name": "Hot testing equipment station", "code": "41111810" },
      { "name": "Cold testing equipment station", "code": "41111811" },
      {
        "name": "Gamma ray radiography examination equipment",
        "code": "41111812"
      },
      {
        "name": "Industrial radiograph viewing illuminator",
        "code": "41111813"
      },
      {
        "name": "Neutron radiography examination equipment",
        "code": "41111814"
      },
      { "name": "Reinforcement metal detector", "code": "41111815" },
      { "name": "Displacement measuring instrument", "code": "41111816" },
      { "name": "Rice taste measuring system", "code": "41111817" },
      { "name": "Non destructive examination reagent", "code": "41111818" },
      { "name": "Wire rope tester", "code": "41111819" },
      { "name": "Fluorescence detector", "code": "41111820" },
      { "name": "Indicating and recording instruments", "code": "41111900" },
      { "name": "Counters", "code": "41111901" },
      { "name": "Electronic counters", "code": "41111902" },
      { "name": "Metal detectors", "code": "41111903" },
      { "name": "Electronic columns", "code": "41111904" },
      { "name": "Electronic measuring probes", "code": "41111905" },
      { "name": "Chart recorders", "code": "41111906" },
      { "name": "Digital readout recorders", "code": "41111907" },
      { "name": "Graphic recorders", "code": "41111908" },
      { "name": "Magnetic tape recorders", "code": "41111909" },
      { "name": "Multipen recorders", "code": "41111910" },
      { "name": "Oscillographic recorders", "code": "41111911" },
      { "name": "Physiological recorders", "code": "41111912" },
      { "name": "Point plotting recorders", "code": "41111913" },
      { "name": "Servo recorders", "code": "41111914" },
      { "name": "Bi metallic sensors", "code": "41111915" },
      { "name": "Non contact sensors", "code": "41111916" },
      { "name": "Digital testers", "code": "41111917" },
      { "name": "Gyroscopic instruments", "code": "41111918" },
      {
        "name": "Detection apparatus for non metallic objects",
        "code": "41111919"
      },
      { "name": "Coordinate measuring machines CMM", "code": "41111920" },
      { "name": "Speed sensors", "code": "41111921" },
      { "name": "Lamp failure sensor", "code": "41111922" },
      { "name": "Pre ignition knock sensor", "code": "41111923" },
      { "name": "Oxygen sensors", "code": "41111924" },
      { "name": "Proximity sensors", "code": "41111926" },
      { "name": "Pressure sensors", "code": "41111927" },
      { "name": "Current sensors", "code": "41111928" },
      { "name": "Radiation detectors", "code": "41111929" },
      { "name": "Electrical power sensors", "code": "41111930" },
      { "name": "Flow sensors", "code": "41111931" },
      { "name": "Liquid leak detectors", "code": "41111932" },
      { "name": "Electrical charge sensors", "code": "41111933" },
      { "name": "Force or torque sensors", "code": "41111934" },
      { "name": "Tilt sensors", "code": "41111935" },
      {
        "name": "Complementary metal oxide semiconductor CMOS image sensors",
        "code": "41111936"
      },
      { "name": "Rotary position sensors", "code": "41111937" },
      { "name": "Level sensors or transmitters", "code": "41111938" },
      { "name": "Acoustic sensors", "code": "41111939" },
      { "name": "Color sensors", "code": "41111940" },
      { "name": "Olfactory sensors", "code": "41111941" },
      { "name": "Opacity or dust or visibility sensors", "code": "41111942" },
      {
        "name": "Electrical resistance or conductance sensors",
        "code": "41111943"
      },
      { "name": "Electrical admittance sensors", "code": "41111944" },
      { "name": "Linear position sensors", "code": "41111945" },
      { "name": "Electrical inductance sensors", "code": "41111946" },
      { "name": "Chart recorder pens", "code": "41111947" },
      {
        "name": "Manual or electronic hematology differential cell counters",
        "code": "41111948"
      },
      { "name": "Hour meter", "code": "41111949" },
      { "name": "Level indicator", "code": "41111950" },
      { "name": "Humidistat", "code": "41111951" },
      { "name": "Hydrogen sulfide sensor", "code": "41111952" },
      {
        "name": "Supplemental inflator restraint arming sensor",
        "code": "41111953"
      },
      { "name": "Liquid sensor", "code": "41111954" },
      { "name": "Solar sensor", "code": "41111955" },
      { "name": "Infrared temperature sensor", "code": "41111956" },
      { "name": "Oil pressure sensor", "code": "41111957" },
      { "name": "Manifold ambient pressure sensor", "code": "41111958" },
      { "name": "Fuel pressure sensor", "code": "41111959" },
      { "name": "Ultrasonic sensor", "code": "41111960" },
      { "name": "Occupant sensor", "code": "41111961" },
      { "name": "Brake wear sensor", "code": "41111962" },
      { "name": "Fuel level sensor", "code": "41111963" },
      { "name": "Seat belt tension sensor", "code": "41111964" },
      { "name": "Water sensor", "code": "41111965" },
      { "name": "Humidity sensor", "code": "41111966" },
      { "name": "Lateral long yaw sensor", "code": "41111967" },
      { "name": "Angular rate sensor", "code": "41111968" },
      { "name": "Voltage control sensor", "code": "41111969" },
      { "name": "Temperature sensor", "code": "41111970" },
      { "name": "Binary counter", "code": "41111971" },
      {
        "name": "Supplemental inflator restraint SIR or airbag coil assembly sensor",
        "code": "41111972"
      },
      { "name": "Particle counter", "code": "41111973" },
      { "name": "Heating sensor tester", "code": "41111974" },
      { "name": "Magnetic field monitor", "code": "41111975" },
      { "name": "Water meter check system", "code": "41111976" },
      { "name": "Ion counter", "code": "41111977" },
      { "name": "Manual microhematocrit tube reader", "code": "41111978" },
      {
        "name": "Data logger for clinical temperature controlled equipment",
        "code": "41111979"
      },
      {
        "name": "Remote monitoring system for clinical temperature controlled equipment",
        "code": "41111980"
      },
      { "name": "Blood utilization management system", "code": "41111981" },
      { "name": "Transducers", "code": "41112100" },
      { "name": "Piezo electric crystals", "code": "41112101" },
      { "name": "Fiber sensors", "code": "41112103" },
      { "name": "Audio transducers", "code": "41112104" },
      { "name": "Temperature transmitters", "code": "41112105" },
      { "name": "Humidity transmitters", "code": "41112106" },
      { "name": "Electro pneumatic transducers", "code": "41112107" },
      { "name": "Loadcells", "code": "41112108" },
      { "name": "Current transducer", "code": "41112109" },
      { "name": "Pressure transducer", "code": "41112110" },
      { "name": "Displacement transducer", "code": "41112111" },
      { "name": "Electric power transducer", "code": "41112112" },
      { "name": "Mechanical energy transducer", "code": "41112113" },
      { "name": "Thermo hygro transmitter", "code": "41112114" },
      {
        "name": "Temperature and heat measuring instruments",
        "code": "41112200"
      },
      { "name": "Calorimeters", "code": "41112201" },
      { "name": "Heat tracing equipment", "code": "41112202" },
      { "name": "Melting point recorders", "code": "41112203" },
      { "name": "Pyrometers", "code": "41112204" },
      { "name": "Temperature regulators", "code": "41112205" },
      { "name": "Thermocouples", "code": "41112206" },
      { "name": "Thermographs", "code": "41112207" },
      { "name": "Thermostats", "code": "41112209" },
      { "name": "Remote reading thermometers", "code": "41112210" },
      { "name": "Resistance thermometers", "code": "41112211" },
      { "name": "Surface thermometers", "code": "41112212" },
      { "name": "Handheld thermometer", "code": "41112213" },
      { "name": "Cryogenic temperature controllers", "code": "41112214" },
      { "name": "Humidifier temperature controllers", "code": "41112215" },
      { "name": "Thermowells", "code": "41112216" },
      { "name": "Thermoheads", "code": "41112217" },
      { "name": "Thermocouple probes", "code": "41112219" },
      {
        "name": "Laboratory freezer or refrigerator thermometers",
        "code": "41112220"
      },
      { "name": "Laboratory incubator thermometers", "code": "41112221" },
      { "name": "Temperature gauge", "code": "41112222" },
      { "name": "Compound gauge", "code": "41112223" },
      { "name": "Infrared thermometer", "code": "41112224" },
      { "name": "Resistance temperature detector RTD", "code": "41112225" },
      { "name": "Low temperature thermocouple sensor", "code": "41112226" },
      { "name": "High temperature thermocouple sensor", "code": "41112227" },
      { "name": "Combustion efficiency analyzer", "code": "41112228" },
      { "name": "Heat meter", "code": "41112229" },
      { "name": "Pyrometer calibrator", "code": "41112230" },
      { "name": "Oil cloud and pour point tester", "code": "41112231" },
      { "name": "Freezing point measuring instrument", "code": "41112232" },
      { "name": "Melting point measuring instrument", "code": "41112233" },
      { "name": "Heat flowmeter", "code": "41112234" },
      { "name": "Specific heat measuring instrument", "code": "41112235" },
      { "name": "Asphalt softening point tester", "code": "41112236" },
      { "name": "Dewpoint thermometer", "code": "41112237" },
      { "name": "Dropping point tester", "code": "41112238" },
      { "name": "Glass thermometer", "code": "41112239" },
      {
        "name": "Blood unit temperature verification strip",
        "code": "41112240"
      },
      { "name": "Laboratory oven thermometer", "code": "41112241" },
      { "name": "Laboratory waterbath thermometer", "code": "41112242" },
      { "name": "Laboratory heat block thermometer", "code": "41112243" },
      { "name": "Calibration reference thermometer", "code": "41112244" },
      {
        "name": "Humidity and moisture measuring instruments",
        "code": "41112300"
      },
      { "name": "Hygrometers", "code": "41112301" },
      { "name": "Psychrometers", "code": "41112302" },
      { "name": "Temperature humidity testers", "code": "41112303" },
      { "name": "Moisture meters", "code": "41112304" },
      { "name": "Humidity controller", "code": "41112305" },
      { "name": "Humidity calibrator", "code": "41112306" },
      { "name": "Cement water retentivity tester", "code": "41112307" },
      {
        "name": "Pressure measuring and control instruments",
        "code": "41112400"
      },
      { "name": "Depth gauges", "code": "41112401" },
      { "name": "Manostats", "code": "41112402" },
      { "name": "Pressure indicators", "code": "41112403" },
      { "name": "Pressure regulator", "code": "41112404" },
      { "name": "Pressure or vacuum recorders", "code": "41112405" },
      { "name": "Vacuum gauges", "code": "41112406" },
      { "name": "Liquid level controls or instruments", "code": "41112407" },
      { "name": "Pressure intensifiers", "code": "41112408" },
      { "name": "Pressure scanners", "code": "41112409" },
      { "name": "Pressure transmitters", "code": "41112410" },
      { "name": "Pressure controllers", "code": "41112411" },
      { "name": "Pressure gauge", "code": "41112412" },
      { "name": "Differentialpressure gauge", "code": "41112413" },
      { "name": "Pressure calibrator", "code": "41112414" },
      { "name": "Warburg apparatus", "code": "41112415" },
      { "name": "Bursting pressure tester", "code": "41112416" },
      { "name": "Vapor pressure measuring instrument", "code": "41112417" },
      { "name": "Capillary pressure tester", "code": "41112418" },
      { "name": "Cigarette filter draw resistance meter", "code": "41112419" },
      { "name": "Pressure drop gauge", "code": "41112420" },
      { "name": "Fruit hardness tester", "code": "41112421" },
      { "name": "Hydraulic pressure tester", "code": "41112422" },
      { "name": "Pressure altimeter", "code": "41112423" },
      {
        "name": "Liquid and gas flow measuring and observing instruments",
        "code": "41112500"
      },
      { "name": "Flowmeters", "code": "41112501" },
      { "name": "Rheometers", "code": "41112502" },
      { "name": "Rotameters", "code": "41112503" },
      { "name": "Water meters", "code": "41112504" },
      { "name": "Water meter spares", "code": "41112505" },
      { "name": "Venturis", "code": "41112506" },
      { "name": "Gas gauges", "code": "41112508" },
      { "name": "Air velocity and temperature monitors", "code": "41112509" },
      { "name": "Sight flow indicators", "code": "41112510" },
      { "name": "Sight flow windows", "code": "41112511" },
      { "name": "Flow computers or totalizers", "code": "41112512" },
      { "name": "Orifice plate", "code": "41112513" },
      { "name": "Oil gauges", "code": "41112514" },
      { "name": "Flow transmitters", "code": "41112516" },
      { "name": "Optical flowmeter and accessories", "code": "41112517" },
      { "name": "Pump efficiency testing equipment", "code": "41112518" },
      { "name": "Pitot gauge", "code": "41112519" },
      { "name": "Fire pump flow meter", "code": "41112520" },
      { "name": "Calibration column", "code": "41112521" },
      { "name": "Water meter tester", "code": "41112522" },
      {
        "name": "Hygiene monitoring and testing equipment",
        "code": "41112600"
      },
      { "name": "Manual swab test kits", "code": "41112601" },
      { "name": "Automated swab test kits", "code": "41112602" },
      {
        "name": "Laboratory seed and feed testing equipment",
        "code": "41112700"
      },
      { "name": "Grain analyzers", "code": "41112701" },
      { "name": "Seed counters", "code": "41112702" },
      { "name": "Feed analyzers", "code": "41112704" },
      {
        "name": "Transportation related equipment and instruments",
        "code": "41112800"
      },
      { "name": "Speedometers", "code": "41112801" },
      { "name": "Tachometers", "code": "41112802" },
      { "name": "Tachometer disks", "code": "41112803" },
      { "name": "Rail abrasion measuring equipment", "code": "41112804" },
      { "name": "Rail downthrow measuring instrument", "code": "41112805" },
      { "name": "Rail joint gap measuring instrument", "code": "41112806" },
      {
        "name": "Rail sleeper holding force measuring instrument",
        "code": "41112807"
      },
      { "name": "Electric rail car tester", "code": "41112808" },
      { "name": "Taximeter tester", "code": "41112809" },
      { "name": "Navigational equipment and instruments", "code": "41112900" },
      { "name": "Direction finding compasses", "code": "41112901" },
      { "name": "Radio navigation instruments", "code": "41112902" },
      { "name": "Sextants", "code": "41112903" },
      { "name": "Complex controlling devices", "code": "41112904" },
      { "name": "Infrared beacon", "code": "41112905" },
      { "name": "Radio beacon", "code": "41112906" },
      { "name": "Radio buoy", "code": "41112907" },
      { "name": "Echo sounder", "code": "41112908" },
      {
        "name": "Chemical evaluation instruments and supplies",
        "code": "41113000"
      },
      { "name": "Digital Analyzer controllers", "code": "41113001" },
      {
        "name": "Chemiluminescence or bioluminescence analyzers",
        "code": "41113002"
      },
      { "name": "Electrogravimetry analyzers", "code": "41113003" },
      { "name": "Flame ionization analyzers", "code": "41113004" },
      { "name": "Ion analyzers", "code": "41113005" },
      { "name": "Radiometry analyzers", "code": "41113006" },
      { "name": "Random access analyzers", "code": "41113007" },
      { "name": "Cintigraphic analyzers", "code": "41113008" },
      { "name": "Thermal differential analyzers", "code": "41113009" },
      { "name": "Thermo gravimetry analyzers", "code": "41113010" },
      { "name": "Gel partition equipment", "code": "41113023" },
      { "name": "Hydrometers", "code": "41113024" },
      { "name": "Monochromators", "code": "41113025" },
      { "name": "Nephelometers", "code": "41113026" },
      { "name": "Osmometers", "code": "41113027" },
      { "name": "Polarographs", "code": "41113029" },
      { "name": "Radiochromatographic scanner", "code": "41113030" },
      { "name": "Saccharometers", "code": "41113031" },
      { "name": "Volumeters", "code": "41113033" },
      { "name": "pH test strips or papers", "code": "41113034" },
      { "name": "Chemical test strips or papers", "code": "41113035" },
      { "name": "Microplates", "code": "41113036" },
      { "name": "Microplate readers", "code": "41113037" },
      { "name": "Alcoholometer", "code": "41113038" },
      { "name": "Osmometer accessories", "code": "41113039" },
      { "name": "Colony counter", "code": "41113040" },
      { "name": "Elemental analyzer", "code": "41113041" },
      { "name": "Milk analyzer", "code": "41113042" },
      { "name": "Dietary fiber determination system", "code": "41113043" },
      { "name": "Alcohol hydrometer", "code": "41113044" },
      { "name": "Oil film tester", "code": "41113045" },
      { "name": "Adhesion tester", "code": "41113046" },
      { "name": "Oil foaming characteristics tester", "code": "41113047" },
      { "name": "Color fastness tester", "code": "41113048" },
      { "name": "Combustion analyzer", "code": "41113049" },
      { "name": "Arsenic detector", "code": "41113050" },
      { "name": "Oxidation reduction tester", "code": "41113051" },
      { "name": "Chalking tester", "code": "41113052" },
      { "name": "Rust prevention tester", "code": "41113053" },
      { "name": "Demulsibility tester", "code": "41113054" },
      { "name": "Fuel gum tester", "code": "41113055" },
      { "name": "Flame experiment wire", "code": "41113056" },
      { "name": "Sludge densitometer", "code": "41113057" },
      { "name": "Gas analyzers and monitors", "code": "41113100" },
      { "name": "Automotive exhaust emission analyzers", "code": "41113101" },
      { "name": "Catalytic combustion analyzers", "code": "41113102" },
      { "name": "Chemical absorption gas analyzers", "code": "41113103" },
      { "name": "Explosimeters", "code": "41113104" },
      { "name": "Hydrocarbons analyzers or detectors", "code": "41113105" },
      {
        "name": "Infra red or ultra violet absorption analyzers",
        "code": "41113106"
      },
      { "name": "Nitrogen gas analyzers", "code": "41113107" },
      { "name": "Nitrogen oxide analyzers", "code": "41113108" },
      { "name": "ORSAT equipment", "code": "41113109" },
      { "name": "Oxygen gas analyzers", "code": "41113110" },
      { "name": "Ozone analyzers", "code": "41113111" },
      { "name": "Paramagnetic susceptibility analyzers", "code": "41113112" },
      { "name": "Sulfur dioxide analyzers or detectors", "code": "41113113" },
      { "name": "Thermal conductivity analyzers", "code": "41113114" },
      { "name": "Radon detectors", "code": "41113115" },
      { "name": "Gas detector tubes", "code": "41113116" },
      { "name": "Single gas monitors", "code": "41113117" },
      { "name": "Multi gas monitors", "code": "41113118" },
      { "name": "Dissolved carbon dioxide analyzers", "code": "41113119" },
      { "name": "Carbon monoxide analyzer", "code": "41113120" },
      { "name": "Vehicle vapor gas analyzing system", "code": "41113121" },
      { "name": "Olfactometer", "code": "41113122" },
      { "name": "Smoke tester", "code": "41113123" },
      { "name": "Respirometer", "code": "41113124" },
      {
        "name": "Liquid and solid and elemental analyzers",
        "code": "41113300"
      },
      { "name": "Acid or base analyzers", "code": "41113301" },
      { "name": "Albuminometers", "code": "41113302" },
      { "name": "Bauxite analyzers", "code": "41113304" },
      { "name": "Calcium analyzers", "code": "41113305" },
      { "name": "Chloride analyzers", "code": "41113306" },
      { "name": "Electrolyte analyzers", "code": "41113308" },
      { "name": "Enzyme analyzers", "code": "41113309" },
      { "name": "Fatty acid analyzers", "code": "41113310" },
      { "name": "Halide detector lamp", "code": "41113311" },
      { "name": "Lactate analyzers", "code": "41113312" },
      { "name": "Mineral oil testing instruments", "code": "41113313" },
      { "name": "Oil content monitors analyzers", "code": "41113314" },
      { "name": "Organic carbon analyzers", "code": "41113315" },
      { "name": "Petroleum testing equipment", "code": "41113316" },
      { "name": "Uranium analyzers", "code": "41113318" },
      { "name": "Water analyzers", "code": "41113319" },
      { "name": "Lubricating oil testing kit", "code": "41113320" },
      { "name": "Paint tester", "code": "41113321" },
      { "name": "Nitrogen or nitrate or nitrite analyzer", "code": "41113322" },
      { "name": "Sugar analyzers", "code": "41113323" },
      { "name": "Paint concealment force tester", "code": "41113324" },
      { "name": "Washability tester", "code": "41113325" },
      { "name": "Paint grain measuring instrument", "code": "41113326" },
      { "name": "Film applicator", "code": "41113327" },
      { "name": "Mercury analyzer", "code": "41113328" },
      { "name": "Suspended solids SS tester", "code": "41113329" },
      { "name": "Sludge thickness measuring instrument", "code": "41113330" },
      { "name": "Freezing and thawing tester", "code": "41113331" },
      { "name": "Liquid ration analyzer", "code": "41113332" },
      { "name": "Zeta potential analyzer", "code": "41113333" },
      { "name": "Paint coating test equipment", "code": "41113334" },
      {
        "name": "Dissolved compounds multiple component analyzer",
        "code": "41113335"
      },
      { "name": "Steam emulsion number tester", "code": "41113336" },
      { "name": "Dispersion or grain size tester", "code": "41113337" },
      { "name": "Biochemical oxygen demand BOD meter", "code": "41113338" },
      { "name": "Chemical oxygen demand COD meter", "code": "41113339" },
      { "name": "Nuclear evaluation instruments", "code": "41113400" },
      { "name": "Alpha counters", "code": "41113401" },
      { "name": "Alpha beta counters", "code": "41113402" },
      { "name": "Beta counters", "code": "41113403" },
      { "name": "Beta gamma counters", "code": "41113404" },
      { "name": "Gamma counters", "code": "41113405" },
      { "name": "KVP meters", "code": "41113406" },
      { "name": "X ray microanalysers", "code": "41113407" },
      {
        "name": "Electrical measuring and testing equipment and accessories",
        "code": "41113600"
      },
      { "name": "Ammeters", "code": "41113601" },
      { "name": "Phasemeters", "code": "41113602" },
      { "name": "Laboratory bridges", "code": "41113603" },
      { "name": "Capacitance meters", "code": "41113604" },
      { "name": "Thermoanalysis derivatographs", "code": "41113605" },
      { "name": "Freeze watch indicators", "code": "41113606" },
      { "name": "Heat stress monitors", "code": "41113607" },
      { "name": "Coincidence or anticoincidence counters", "code": "41113608" },
      { "name": "Cross talk meters", "code": "41113611" },
      { "name": "Earth resistance testers", "code": "41113612" },
      { "name": "Electrical value recorders", "code": "41113613" },
      { "name": "Electromagnetic field meters", "code": "41113614" },
      { "name": "Electrometers", "code": "41113615" },
      { "name": "Electronic loads", "code": "41113616" },
      { "name": "Field strength measuring equipment", "code": "41113617" },
      { "name": "Gain measuring instruments", "code": "41113618" },
      { "name": "Galvanometers", "code": "41113619" },
      { "name": "High voltage cable detection", "code": "41113620" },
      { "name": "Impedance meters", "code": "41113621" },
      { "name": "Calibrated inductance coils or boxes", "code": "41113622" },
      { "name": "Insulation resistance meters", "code": "41113623" },
      { "name": "Insulation testers", "code": "41113624" },
      { "name": "Ionization chambers", "code": "41113625" },
      { "name": "Ionmeters", "code": "41113626" },
      { "name": "Line earth loop testers", "code": "41113627" },
      { "name": "Megohmmeters", "code": "41113628" },
      { "name": "Microwave leakage meters", "code": "41113629" },
      { "name": "Multimeters", "code": "41113630" },
      { "name": "Ohmmeters", "code": "41113631" },
      { "name": "Oscillographs", "code": "41113632" },
      { "name": "Potentiometers", "code": "41113633" },
      { "name": "Q Meters", "code": "41113634" },
      {
        "name": "Calibrated resistance measuring equipment",
        "code": "41113635"
      },
      { "name": "Level generators", "code": "41113636" },
      { "name": "Voltage or current meters", "code": "41113637" },
      { "name": "Oscilloscopes", "code": "41113638" },
      { "name": "Accelerometers", "code": "41113639" },
      { "name": "Wattmeters", "code": "41113640" },
      { "name": "GFI circuit testers", "code": "41113641" },
      { "name": "Circuit tester", "code": "41113642" },
      { "name": "Demand meters or registers", "code": "41113643" },
      { "name": "Circuit tracers", "code": "41113644" },
      { "name": "Earth leakage devices", "code": "41113645" },
      { "name": "Temperature calibrator or simulator", "code": "41113646" },
      { "name": "Frequency calibrator or simulator", "code": "41113647" },
      { "name": "Psophometer", "code": "41113649" },
      { "name": "Functional tester", "code": "41113650" },
      { "name": "Ring out board", "code": "41113651" },
      { "name": "Wire assembly board", "code": "41113652" },
      { "name": "In circuit tester ICT", "code": "41113653" },
      { "name": "Current loop meter", "code": "41113654" },
      { "name": "Generator test set", "code": "41113655" },
      { "name": "Servo system tester", "code": "41113656" },
      { "name": "Circuit breaker tester", "code": "41113657" },
      { "name": "Switch durability tester", "code": "41113658" },
      { "name": "Var meter", "code": "41113659" },
      { "name": "Oscillator tester", "code": "41113660" },
      { "name": "Voltage regulator tester", "code": "41113661" },
      { "name": "Cavity resonator", "code": "41113662" },
      { "name": "Transformer tester", "code": "41113663" },
      { "name": "Phase sequence indicator", "code": "41113664" },
      { "name": "Contact resistance tester", "code": "41113665" },
      { "name": "Arrester tester", "code": "41113666" },
      { "name": "Watt hour meter test equipment", "code": "41113667" },
      { "name": "Capacitor tester", "code": "41113668" },
      { "name": "Line voltage detector", "code": "41113669" },
      { "name": "Resister test equipment", "code": "41113670" },
      { "name": "Current divider", "code": "41113671" },
      { "name": "Relay tester", "code": "41113672" },
      { "name": "Panel and switchboard meter", "code": "41113673" },
      { "name": "Blasting machine tester", "code": "41113674" },
      { "name": "Frequency control test equipment", "code": "41113675" },
      { "name": "Low voltage tester", "code": "41113676" },
      { "name": "Withstand voltage tester", "code": "41113677" },
      { "name": "Clamp tester", "code": "41113678" },
      {
        "name": "Current transformer and potential transformer test equipment",
        "code": "41113679"
      },
      { "name": "Power factor meter", "code": "41113680" },
      { "name": "Combi tester", "code": "41113681" },
      { "name": "Electric power tester", "code": "41113682" },
      { "name": "Lamp tester", "code": "41113683" },
      { "name": "Voltage and current meter calibrator", "code": "41113684" },
      { "name": "Static electricity measuring equipment", "code": "41113685" },
      { "name": "Pulse meter", "code": "41113686" },
      { "name": "Brake testing machine", "code": "41113687" },
      { "name": "Converter testing equipment", "code": "41113688" },
      { "name": "Controller testing equipment", "code": "41113689" },
      {
        "name": "Electronic and communication measuring and testing instruments",
        "code": "41113700"
      },
      { "name": "Cathode ray tube tester", "code": "41113701" },
      { "name": "Comparators", "code": "41113702" },
      { "name": "Directional coupler", "code": "41113703" },
      { "name": "Integrated circuit testers", "code": "41113704" },
      { "name": "Logic state testers", "code": "41113705" },
      { "name": "Semiconductor testers", "code": "41113706" },
      { "name": "Transistor circuit testers", "code": "41113707" },
      { "name": "Power meters", "code": "41113708" },
      { "name": "Modulation meters", "code": "41113709" },
      { "name": "Level meter", "code": "41113710" },
      { "name": "Network analyzers", "code": "41113711" },
      { "name": "Tape testers", "code": "41113712" },
      { "name": "Tapespeed testers", "code": "41113713" },
      { "name": "Differentiator", "code": "41113714" },
      {
        "name": "Integrated services digital network ISDN testers",
        "code": "41113715"
      },
      { "name": "Fiber optic fault locators", "code": "41113716" },
      { "name": "Fiber optic test sources", "code": "41113717" },
      { "name": "Protocol analyzers", "code": "41113718" },
      { "name": "Optical loss tester", "code": "41113719" },
      { "name": "Traffic intensity testing equipment", "code": "41113720" },
      { "name": "Video signal measuring instrument", "code": "41113721" },
      { "name": "Electromagnetic interference EMI tester", "code": "41113722" },
      { "name": "Radio equipment tester", "code": "41113723" },
      { "name": "Telephone test set", "code": "41113724" },
      { "name": "Optical power meter", "code": "41113725" },
      { "name": "Heat resistance tester", "code": "41113726" },
      { "name": "Distortion meter", "code": "41113727" },
      {
        "name": "Electromagnetic shield environmental chamber",
        "code": "41113728"
      },
      { "name": "Amplifier output meter", "code": "41113729" },
      { "name": "Television tester", "code": "41113730" },
      { "name": "Crystal tester", "code": "41113731" },
      { "name": "Amplifier tester", "code": "41113732" },
      { "name": "Antenna tester", "code": "41113733" },
      { "name": "Frequency deviation meter", "code": "41113734" },
      { "name": "Microwave equipment tester", "code": "41113735" },
      {
        "name": "Electronic and communication refractometer",
        "code": "41113736"
      },
      { "name": "Noise meter", "code": "41113737" },
      { "name": "Communication line overhaul tester", "code": "41113738" },
      { "name": "Electromagnetic susceptibility tester", "code": "41113739" },
      { "name": "Voice data video cable tester", "code": "41113740" },
      {
        "name": "Geophysical and geotechnical instruments",
        "code": "41113800"
      },
      { "name": "Geological compasses", "code": "41113801" },
      { "name": "Geological prospecting apparatus", "code": "41113802" },
      { "name": "Electromagnetic geophysical instruments", "code": "41113803" },
      { "name": "Gravity geophysical instruments", "code": "41113804" },
      {
        "name": "Induced polarization IP geophysical instruments",
        "code": "41113805"
      },
      { "name": "Magnetometer geophysical instruments", "code": "41113806" },
      { "name": "Resistivity geophysical instruments", "code": "41113807" },
      { "name": "Gravimeters", "code": "41113808" },
      { "name": "Ground penetrating radar", "code": "41113809" },
      { "name": "Hydrothermal testing equipment", "code": "41113810" },
      { "name": "Solid bearing tester", "code": "41113811" },
      { "name": "Ground friction tester", "code": "41113812" },
      { "name": "Standard aggregate", "code": "41113813" },
      { "name": "Soil plastic limit tester", "code": "41113814" },
      { "name": "Soil shrinkage limit tester", "code": "41113815" },
      { "name": "Consolidation tester", "code": "41113816" },
      { "name": "Soil liquid limit tester", "code": "41113817" },
      { "name": "Plate bearing tester", "code": "41113818" },
      { "name": "Soil analyzer", "code": "41113819" },
      { "name": "Aggregate specific gravity tester", "code": "41113820" },
      { "name": "Soil penetration tester", "code": "41113821" },
      { "name": "California bearing ratio tester", "code": "41113822" },
      { "name": "Specimen expansion tester", "code": "41113823" },
      { "name": "Soil head permeability tester", "code": "41113824" },
      { "name": "Soil aggregate analyzer", "code": "41113825" },
      { "name": "Soil texture analyzer", "code": "41113826" },
      { "name": "Soil unconfined compression apparatus", "code": "41113827" },
      { "name": "Sand density cone apparatus", "code": "41113828" },
      { "name": "Underwater soil picking equipment", "code": "41113829" },
      {
        "name": "Aggregate unit weight measuring instrument",
        "code": "41113830"
      },
      { "name": "Facing sand water measuring instrument", "code": "41113831" },
      { "name": "Soil measuring equipment", "code": "41113900" },
      { "name": "Bore measuring instruments", "code": "41113901" },
      { "name": "Dissolution or disintegration testers", "code": "41113902" },
      { "name": "Particle size measuring apparatus", "code": "41113903" },
      { "name": "Penetrometers", "code": "41113904" },
      { "name": "Permeability testing apparatus", "code": "41113905" },
      {
        "name": "Permeability or porosity estimation apparatus",
        "code": "41113906"
      },
      { "name": "Porosimeters", "code": "41113907" },
      { "name": "Sand testing apparatus", "code": "41113908" },
      { "name": "Soil core sampling apparatus", "code": "41113909" },
      { "name": "Soil testing kits", "code": "41113910" },
      { "name": "Rock and strata measuring equipment", "code": "41114000" },
      { "name": "Clinometers", "code": "41114001" },
      { "name": "Seismological instruments", "code": "41114100" },
      { "name": "Earthquake simulators", "code": "41114102" },
      { "name": "Seismic alarm modules", "code": "41114103" },
      { "name": "Seismic amplifiers", "code": "41114104" },
      { "name": "Portable seismic apparatus", "code": "41114105" },
      { "name": "Seismic recorders or seismographs", "code": "41114106" },
      { "name": "Seismometers", "code": "41114107" },
      { "name": "Vibrometers", "code": "41114108" },
      { "name": "Land surveying instruments", "code": "41114200" },
      { "name": "Measuring tapes", "code": "41114201" },
      { "name": "Measuring rods", "code": "41114202" },
      { "name": "Measuring tables", "code": "41114203" },
      { "name": "Theodolites", "code": "41114204" },
      { "name": "Location stake", "code": "41114205" },
      { "name": "Location hub", "code": "41114206" },
      { "name": "Total station", "code": "41114207" },
      { "name": "Longimetry instrument", "code": "41114208" },
      { "name": "Tachymeter or tacheometer", "code": "41114209" },
      { "name": "Optical level", "code": "41114210" },
      { "name": "Altimeter", "code": "41114211" },
      { "name": "Mirror stereoscope", "code": "41114212" },
      { "name": "Coordinate comparator", "code": "41114213" },
      { "name": "Survey template", "code": "41114214" },
      { "name": "Dendrometer", "code": "41114215" },
      { "name": "Alidade", "code": "41114216" },
      { "name": "Lead line", "code": "41114217" },
      { "name": "Planimeter", "code": "41114218" },
      { "name": "Plumbing arm", "code": "41114219" },
      { "name": "Transit", "code": "41114220" },
      { "name": "Optical square", "code": "41114221" },
      { "name": "Hydrological instruments", "code": "41114300" },
      { "name": "Open stream current meters", "code": "41114301" },
      { "name": "Logging instruments for water wells", "code": "41114302" },
      { "name": "Open stream water level recorders", "code": "41114303" },
      { "name": "Meteorological instruments", "code": "41114400" },
      { "name": "Anemometers", "code": "41114401" },
      { "name": "Barometers", "code": "41114402" },
      { "name": "Precipitation or evaporation recorders", "code": "41114403" },
      { "name": "Radiosonde apparatus", "code": "41114404" },
      { "name": "Rainfall recorders", "code": "41114405" },
      {
        "name": "Precipitation or evaporation surface observing apparatus",
        "code": "41114406"
      },
      {
        "name": "Solar radiation surface observing apparatus",
        "code": "41114407"
      },
      {
        "name": "Temperature or humidity surface observing apparatus",
        "code": "41114408"
      },
      { "name": "Wind surface observing apparatus", "code": "41114409" },
      { "name": "Weather stations", "code": "41114410" },
      { "name": "Meteorology instrument accessories", "code": "41114411" },
      { "name": "Radio acoustic sounding system", "code": "41114412" },
      { "name": "Ceilometer", "code": "41114413" },
      {
        "name": "Meteorological satellite receiving equipment",
        "code": "41114414"
      },
      { "name": "Lightning analysis system", "code": "41114415" },
      { "name": "Weather chart recorder or scanner", "code": "41114416" },
      { "name": "Meteorological buoy robot", "code": "41114417" },
      {
        "name": "Meteorological satellite data receiving and analyzing system",
        "code": "41114418"
      },
      { "name": "Low level wind shear alert system", "code": "41114419" },
      { "name": "Barometer calibrator", "code": "41114420" },
      { "name": "Hygrometer calibrator", "code": "41114421" },
      { "name": "Rain gauge calibrator", "code": "41114422" },
      { "name": "Automatic weather system calibrator", "code": "41114423" },
      { "name": "Anenometer calibrator", "code": "41114424" },
      { "name": "Actinometer calibrator", "code": "41114425" },
      { "name": "Baroswitch", "code": "41114426" },
      { "name": "Wind vane", "code": "41114427" },
      { "name": "Mechanical instruments", "code": "41114500" },
      { "name": "Dynamometers", "code": "41114501" },
      { "name": "Elastometers", "code": "41114502" },
      { "name": "Extensometers", "code": "41114503" },
      { "name": "Pitch measuring instruments", "code": "41114504" },
      { "name": "Roundness testing instruments", "code": "41114505" },
      { "name": "Spherometers", "code": "41114506" },
      { "name": "Spring testing machines", "code": "41114507" },
      { "name": "Surface testers", "code": "41114508" },
      { "name": "Tensiometers", "code": "41114509" },
      { "name": "Torque limiter", "code": "41114510" },
      { "name": "Softness tester", "code": "41114511" },
      { "name": "High speed rotational balance tester", "code": "41114512" },
      { "name": "Eccentricity measuring instrument", "code": "41114513" },
      { "name": "Twist vibration measuring instrument", "code": "41114514" },
      { "name": "Centrifugal force tester", "code": "41114515" },
      { "name": "Cutting force measuring instrument", "code": "41114516" },
      { "name": "Hydraulic bulge tester", "code": "41114517" },
      { "name": "Zipper endurance tester", "code": "41114518" },
      { "name": "Tug and trolley system", "code": "41114519" },
      { "name": "Drop tester", "code": "41114520" },
      { "name": "Air compressor tester", "code": "41114521" },
      { "name": "Gear tester", "code": "41114522" },
      { "name": "Test bar", "code": "41114523" },
      { "name": "Hydraulic tester", "code": "41114524" },
      { "name": "Oil filter tester", "code": "41114525" },
      { "name": "Air cleaner tester", "code": "41114526" },
      { "name": "Belt tester", "code": "41114527" },
      {
        "name": "Planer motion mechanism measuring instrument",
        "code": "41114528"
      },
      { "name": "Tire running tester", "code": "41114529" },
      { "name": "Bonding stress tester", "code": "41114530" },
      {
        "name": "Metals and metallurgy and structural materials testing instruments",
        "code": "41114600"
      },
      { "name": "Abrasion testers", "code": "41114601" },
      { "name": "Compression testers", "code": "41114602" },
      { "name": "Concrete or cement testing instruments", "code": "41114603" },
      { "name": "Corrosion testers", "code": "41114604" },
      { "name": "Crack or corrosion detectors", "code": "41114605" },
      { "name": "Creep testers", "code": "41114606" },
      { "name": "Ductility testing machines", "code": "41114607" },
      { "name": "Fatigue testers", "code": "41114608" },
      { "name": "Forging testing apparatus", "code": "41114609" },
      { "name": "Foundry testing apparatus", "code": "41114610" },
      { "name": "Hardness testers", "code": "41114611" },
      { "name": "Impact testers", "code": "41114612" },
      { "name": "Load frame", "code": "41114613" },
      { "name": "Metal testing instruments", "code": "41114614" },
      { "name": "Photoelastic testing instruments", "code": "41114615" },
      { "name": "Proofstress indicators", "code": "41114616" },
      { "name": "Relaxation testers", "code": "41114617" },
      { "name": "Roughness measuring instruments", "code": "41114618" },
      { "name": "Shear strength testers", "code": "41114619" },
      { "name": "Shock testing apparatus", "code": "41114620" },
      { "name": "Tension testers", "code": "41114621" },
      { "name": "Torsion testers", "code": "41114622" },
      { "name": "Flexure or transverse testing machines", "code": "41114623" },
      { "name": "Vibration testers", "code": "41114624" },
      { "name": "Wear testers", "code": "41114625" },
      { "name": "Welding testing apparatus", "code": "41114626" },
      { "name": "Proving ring", "code": "41114627" },
      { "name": "Plasticity tester", "code": "41114628" },
      { "name": "Heat distortion tester", "code": "41114629" },
      { "name": "Thermal shock tester", "code": "41114630" },
      { "name": "Stress tester", "code": "41114631" },
      { "name": "Tensile strength tester", "code": "41114632" },
      { "name": "Thermal expansion tester", "code": "41114633" },
      { "name": "Erichsen tester", "code": "41114634" },
      { "name": "Jewel appraising tester", "code": "41114635" },
      {
        "name": "Polymer molding condition measuring instrument",
        "code": "41114636"
      },
      { "name": "Beam test apparatus", "code": "41114637" },
      { "name": "Load tester", "code": "41114638" },
      { "name": "Cement flow tester", "code": "41114639" },
      { "name": "Road plane measuring equipment", "code": "41114640" },
      { "name": "Laboratory cement curing equipment", "code": "41114641" },
      { "name": "Pinhole detector", "code": "41114642" },
      { "name": "Cement soundness tester", "code": "41114643" },
      { "name": "Concrete air measuring instrument", "code": "41114644" },
      { "name": "Mortar length tester", "code": "41114645" },
      { "name": "Vicat needle tester", "code": "41114646" },
      { "name": "Slump tester", "code": "41114647" },
      { "name": "Concrete or cement vibration tester", "code": "41114648" },
      { "name": "Mortar permeability tester", "code": "41114649" },
      { "name": "Concrete cylinder mold", "code": "41114650" },
      {
        "name": "Paper and wood and textile testing instruments",
        "code": "41114700"
      },
      { "name": "Cardboard testing instruments", "code": "41114701" },
      { "name": "Textiles fastness testers", "code": "41114702" },
      { "name": "Leather testing instruments", "code": "41114703" },
      { "name": "Paper testing instruments", "code": "41114704" },
      { "name": "Textile testing instruments", "code": "41114705" },
      { "name": "Wood testing instruments", "code": "41114706" },
      { "name": "Textile or paper strength tester", "code": "41114707" },
      { "name": "Water vapor permeability tester", "code": "41114708" },
      { "name": "Yarn testing instrument", "code": "41114709" },
      { "name": "Thermal transmittance tester", "code": "41114710" },
      { "name": "Textile drape tester", "code": "41114711" },
      { "name": "Textile water repellency tester", "code": "41114712" },
      { "name": "Textile crease recovery tester", "code": "41114713" },
      { "name": "Textile maturity tester", "code": "41114714" },
      {
        "name": "Textile or paper air permeability tester",
        "code": "41114715"
      },
      { "name": "Textile shrinkage tester", "code": "41114716" },
      { "name": "Textile baking tester", "code": "41114717" },
      { "name": "Textile pilling degree tester", "code": "41114718" },
      { "name": "Paper sheet testing machine", "code": "41114719" },
      { "name": "Paper and cloth water absoption tester", "code": "41114720" },
      { "name": "Fiber length tester", "code": "41114721" },
      { "name": "Tearing strength tester", "code": "41114722" },
      { "name": "Bursting strength tester", "code": "41114723" },
      { "name": "Ceramics and glass testing instruments", "code": "41114800" },
      { "name": "Ceramics testing instruments", "code": "41114801" },
      { "name": "Glass testing instruments", "code": "41114802" },
      { "name": "Pottery testing instruments", "code": "41114803" },
      { "name": "Coal and ore testing instruments", "code": "41115100" },
      { "name": "Coal testing instruments", "code": "41115101" },
      { "name": "Goniometer", "code": "41115102" },
      { "name": "Radar and sonar systems and components", "code": "41115200" },
      { "name": "Radarbased surveillance systems", "code": "41115201" },
      { "name": "Feed horns", "code": "41115202" },
      { "name": "Meteorology radar", "code": "41115203" },
      {
        "name": "Light and wave generating and measuring equipment",
        "code": "41115300"
      },
      { "name": "Light absorption meters", "code": "41115301" },
      { "name": "Anechoic chambers", "code": "41115302" },
      { "name": "Frequency analyzers", "code": "41115303" },
      { "name": "Frequency counters or timer or dividers", "code": "41115304" },
      { "name": "Electrical frequency meters", "code": "41115305" },
      { "name": "Interferometers", "code": "41115306" },
      { "name": "Lasers", "code": "41115307" },
      { "name": "Lightmeters", "code": "41115308" },
      { "name": "Luxmeters", "code": "41115309" },
      { "name": "Optical calibration sets", "code": "41115310" },
      { "name": "Photometers", "code": "41115311" },
      { "name": "Bench refractometers or polarimeters", "code": "41115312" },
      { "name": "Handheld refractometers or polarimeters", "code": "41115313" },
      { "name": "Polarimeters", "code": "41115314" },
      { "name": "Polariscopes", "code": "41115315" },
      { "name": "Reflectometers", "code": "41115316" },
      { "name": "Stroboscopes", "code": "41115317" },
      { "name": "Colorimeters", "code": "41115318" },
      { "name": "Tube or plate readers", "code": "41115319" },
      { "name": "Signal generators", "code": "41115320" },
      { "name": "Infrared imagers", "code": "41115321" },
      { "name": "Laser beam analyzers", "code": "41115322" },
      { "name": "Function generator", "code": "41115323" },
      { "name": "Ultraviolet sensor", "code": "41115324" },
      { "name": "Thermal imager", "code": "41115325" },
      { "name": "Optic collimator", "code": "41115326" },
      { "name": "Photoelectric measuring equipment", "code": "41115327" },
      { "name": "Wave form synthesizer", "code": "41115328" },
      {
        "name": "Radiowave propagation measuring equipment",
        "code": "41115329"
      },
      { "name": "Haze meter", "code": "41115330" },
      { "name": "Glossiness measuring instrument", "code": "41115331" },
      { "name": "Optical wavelength meter", "code": "41115332" },
      { "name": "Photon measurement instrument", "code": "41115333" },
      { "name": "Whiteness tester", "code": "41115334" },
      { "name": "Vectorscope", "code": "41115335" },
      { "name": "Laboratory ultraviolet ray lamp", "code": "41115336" },
      { "name": "Wave measuring instrument", "code": "41115337" },
      { "name": "Illuminance meter", "code": "41115338" },
      { "name": "Ellipsometer", "code": "41115339" },
      { "name": "Photometer accessories", "code": "41115340" },
      { "name": "Spectroscopic equipment", "code": "41115400" },
      { "name": "Spectrofluorimeters or fluorimeters", "code": "41115401" },
      { "name": "Spectrographs", "code": "41115402" },
      { "name": "Spectrometers", "code": "41115403" },
      { "name": "Mass spectrometers", "code": "41115404" },
      { "name": "Proton spectrometers", "code": "41115405" },
      { "name": "Spectrophotometers", "code": "41115406" },
      { "name": "Atomic absorption AA spectrometers", "code": "41115407" },
      { "name": "Infrared spectrometers", "code": "41115408" },
      {
        "name": "Nuclear magnetic resonance NMR spectrometers",
        "code": "41115409"
      },
      {
        "name": "Inductively coupled plasma ICP spectrometers",
        "code": "41115411"
      },
      { "name": "Spectrobolometer", "code": "41115412" },
      { "name": "Flow cytometer", "code": "41115413" },
      { "name": "Radio ray spectroscopy system", "code": "41115414" },
      {
        "name": "Electron spectroscopy system for chemical analysis",
        "code": "41115415"
      },
      { "name": "Electron spin resonance spectrometer", "code": "41115416" },
      {
        "name": "Atomic absorption AA spectrometer accessories",
        "code": "41115417"
      },
      { "name": "Spectrophotometer accessories", "code": "41115418" },
      {
        "name": "Sound generating and measuring equipment",
        "code": "41115500"
      },
      { "name": "Sonars", "code": "41115501" },
      { "name": "Sonometers", "code": "41115502" },
      {
        "name": "Sound measuring apparatus or decibel meter",
        "code": "41115503"
      },
      { "name": "Sound velocity analyzers", "code": "41115504" },
      { "name": "Acoustic testing rooms", "code": "41115505" },
      { "name": "Noise generator", "code": "41115506" },
      { "name": "Voice analyzer", "code": "41115507" },
      { "name": "Sound detector", "code": "41115508" },
      { "name": "Underground pipe and cable detector", "code": "41115509" },
      { "name": "Waterpipe leak detector", "code": "41115510" },
      { "name": "Hearing aid tester", "code": "41115511" },
      { "name": "Pinger", "code": "41115512" },
      { "name": "Acoustic generator", "code": "41115513" },
      { "name": "Sonobuoy", "code": "41115514" },
      { "name": "Hydrophone", "code": "41115515" },
      {
        "name": "Electrochemical measuring instruments and accessories",
        "code": "41115600"
      },
      { "name": "Karl Fischer titration equipment", "code": "41115601" },
      { "name": "Titration equipment", "code": "41115602" },
      { "name": "pH meters", "code": "41115603" },
      { "name": "pH electrodes", "code": "41115604" },
      { "name": "Ion selective electrode ISE meters", "code": "41115606" },
      { "name": "Ion selective test strips", "code": "41115607" },
      { "name": "Ion selective electrode", "code": "41115608" },
      { "name": "Conductivity meters", "code": "41115609" },
      { "name": "Conductivity cells", "code": "41115610" },
      { "name": "Dissolved oxygen meters", "code": "41115611" },
      { "name": "Dissolved oxygen probes", "code": "41115612" },
      { "name": "Salinity meter", "code": "41115613" },
      { "name": "pH transmitters", "code": "41115614" },
      { "name": "pH controller", "code": "41115615" },
      {
        "name": "Chromatographic measuring instruments and accessories",
        "code": "41115700"
      },
      { "name": "Chromatographic detectors", "code": "41115701" },
      { "name": "Chromatographic scanners", "code": "41115702" },
      { "name": "Gas chromatographs", "code": "41115703" },
      { "name": "Ion chromatographs", "code": "41115704" },
      { "name": "Liquid chromatographs", "code": "41115705" },
      { "name": "Thinlayer chromatographs", "code": "41115706" },
      {
        "name": "High pressure liquid chromatograph chromatography",
        "code": "41115707"
      },
      {
        "name": "High pressure thin layer chromatograph TLC",
        "code": "41115708"
      },
      {
        "name": "High pressure liquid chromatography HPLC columns",
        "code": "41115709"
      },
      { "name": "Gas chromatography GC columns", "code": "41115710" },
      { "name": "Liquid chromatography LC columns", "code": "41115711" },
      { "name": "Solid phase extraction SPE columns", "code": "41115712" },
      { "name": "Thin layer chromatography tanks", "code": "41115713" },
      { "name": "Autosamplers", "code": "41115714" },
      { "name": "Injectors", "code": "41115715" },
      { "name": "Liquid chromatography fittings", "code": "41115716" },
      { "name": "Gas chromatography fittings", "code": "41115717" },
      { "name": "Injector septa", "code": "41115718" },
      { "name": "Gas chromatography liners", "code": "41115719" },
      { "name": "Chromatography tubing", "code": "41115720" },
      {
        "name": "Fast protein liquid chromatography FPLC system",
        "code": "41115721"
      },
      {
        "name": "Fast protein liquid chromatography FPLC column",
        "code": "41115722"
      },
      { "name": "Supercritical fluid chromatograph", "code": "41115723" },
      { "name": "Chromatography vial or insert or cap", "code": "41115724" },
      { "name": "Thin layer chromatography plate", "code": "41115725" },
      { "name": "Thin layer chromatography fitting", "code": "41115726" },
      { "name": "Chromatography blotting paper", "code": "41115727" },
      { "name": "Ion chromatography IC column", "code": "41115728" },
      { "name": "Ion chromatography IC guard column", "code": "41115729" },
      {
        "name": "High pressure liquid chromatography HPLC guard column",
        "code": "41115730"
      },
      { "name": "QuEChERS Dispersive SPE", "code": "41115731" },
      {
        "name": "Liquid chromatograph LC fraction collector",
        "code": "41115732"
      },
      { "name": "Ion chromatography IC eluent generator", "code": "41115733" },
      {
        "name": "Clinical and diagnostic analyzers and accessories and supplies",
        "code": "41115800"
      },
      { "name": "Amino acid analyzers", "code": "41115801" },
      {
        "name": "Amino acid analyzer accessories or supplies",
        "code": "41115802"
      },
      { "name": "Blood bank analyzers", "code": "41115803" },
      {
        "name": "Blood bank analyzer accessories or supplies",
        "code": "41115804"
      },
      { "name": "Blood gas analyzers", "code": "41115805" },
      {
        "name": "Blood gas analyzer accessories or supplies",
        "code": "41115806"
      },
      { "name": "Chemistry analyzers", "code": "41115807" },
      {
        "name": "Chemistry analyzer accessories or supplies",
        "code": "41115808"
      },
      { "name": "Coagulation analyzers", "code": "41115809" },
      {
        "name": "Coagulation analyzer accessories or supplies",
        "code": "41115810"
      },
      { "name": "Deoxyribonucleic sequence analyzers", "code": "41115811" },
      {
        "name": "Deoxyribonucleic sequence analyzer accessories or supplies",
        "code": "41115812"
      },
      { "name": "Toxicology analyzers", "code": "41115813" },
      {
        "name": "Toxicology analyzers accessories or supplies",
        "code": "41115814"
      },
      { "name": "Hematology analyzers", "code": "41115815" },
      {
        "name": "Hematology analyzer accessories or supplies",
        "code": "41115816"
      },
      { "name": "Histology analyzers", "code": "41115817" },
      {
        "name": "Histology analyzer accessories or supplies",
        "code": "41115818"
      },
      { "name": "Immunology analyzers", "code": "41115819" },
      {
        "name": "Immunology analyzer accessories or supplies",
        "code": "41115820"
      },
      { "name": "Microbiology analyzers", "code": "41115821" },
      {
        "name": "Microbiology analyzer accessories or supplies",
        "code": "41115822"
      },
      { "name": "Protein analyzers", "code": "41115823" },
      {
        "name": "Protein analyzer accessories or supplies",
        "code": "41115824"
      },
      { "name": "Radioisotopic analyzers", "code": "41115825" },
      {
        "name": "Radioisotopic analyzer accessories or supplies",
        "code": "41115826"
      },
      { "name": "Urinalysis analyzers", "code": "41115827" },
      {
        "name": "Urinalysis analyzer accessories or supplies",
        "code": "41115828"
      },
      { "name": "Meat or dairy product analyzers", "code": "41115829" },
      { "name": "Glucose analyzers", "code": "41115830" },
      {
        "name": "Stacker or magazine for microplate handling",
        "code": "41115831"
      },
      { "name": "Antibiotic detection equipment", "code": "41115832" },
      { "name": "Skin analyzer", "code": "41115833" },
      { "name": "Flow cytometer accessories or supplies", "code": "41115834" },
      {
        "name": "Androgeny and fertility automated counter",
        "code": "41115835"
      },
      {
        "name": "Androgeny and fertility automated counter accessories and supplies",
        "code": "41115836"
      },
      { "name": "Particle counter and sizer", "code": "41115837" },
      {
        "name": "Particle counter and sizer accessories and supplies",
        "code": "41115838"
      },
      {
        "name": "Blood bank apheresis and donor unit processing analyzer",
        "code": "41115839"
      },
      {
        "name": "Blood bank apheresis and donor unit processing analyzer accessories and supplies",
        "code": "41115840"
      },
      { "name": "Automated blood culture system", "code": "41115841" },
      {
        "name": "Automated blood culture system analyzer accessories or supplies",
        "code": "41115842"
      },
      { "name": "Molecular diagnostics analyzer", "code": "41115843" },
      {
        "name": "Molecular diagnostics analyzer accessories",
        "code": "41115844"
      },
      { "name": "Cell metabolism analyzer", "code": "41115845" },
      { "name": "Cell metabolism analyzer accessories", "code": "41115846" },
      { "name": "Sweat chloride iontophoresis analyzer", "code": "41115847" },
      {
        "name": "Sweat chloride iontophoresis analyzer accessories",
        "code": "41115848"
      },
      { "name": "Electrolyte analyzer", "code": "41115849" },
      {
        "name": "Pipettor delivery calibration and verification analyzer",
        "code": "41115850"
      },
      {
        "name": "Pipettor delivery calibration and verification analyzer accessories",
        "code": "41115851"
      },
      { "name": "Flow cytometry analyzer", "code": "41115852" },
      {
        "name": "Automated high performance chromatography HPLC analyzer system",
        "code": "41115853"
      },
      {
        "name": "Automated high performance chromatography HPLC analyzer system accessories",
        "code": "41115854"
      },
      { "name": "Transplant diagnostics analyzer", "code": "41115855" },
      {
        "name": "Transplant diagnostics analyzer accessories",
        "code": "41115856"
      },
      { "name": "Flame photometer analyzer", "code": "41115857" },
      { "name": "Flame photometer analyzer accessories", "code": "41115858" },
      {
        "name": "Gas-liquid chromatography and mass spectrometry analyzer",
        "code": "41115859"
      },
      { "name": "Genomic analysis analyzer", "code": "41115860" },
      { "name": "Genomic analysis analyzer accessories", "code": "41115861" },
      {
        "name": "Nucleic acid extraction, isolation and purification analyzer",
        "code": "41115862"
      },
      {
        "name": "Nucleic acid extraction isolation and purification analyzer accessories",
        "code": "41115863"
      },
      { "name": "Multiplex analyzer", "code": "41115864" },
      { "name": "Multiplex analyzer accessories", "code": "41115865" },
      { "name": "Biomagnetic separation analyzer", "code": "41115866" },
      {
        "name": "Biomagnetic separation analyzer accessories",
        "code": "41115867"
      },
      {
        "name": "Automated quality control manager system",
        "code": "41115868"
      },
      {
        "name": "Clinical and diagnostic analyzer reagents",
        "code": "41116000"
      },
      { "name": "Amino acid analyzer reagents", "code": "41116001" },
      { "name": "Blood bank analyzer reagents", "code": "41116002" },
      { "name": "Blood gas analyzer reagents", "code": "41116003" },
      { "name": "Chemistry analyzer reagents", "code": "41116004" },
      { "name": "Coagulation analyzer reagents", "code": "41116005" },
      {
        "name": "Deoxyribonucleic acid DNA sequence analyzer reagents",
        "code": "41116006"
      },
      { "name": "Toxicology analyzer reagents", "code": "41116007" },
      { "name": "Hematology analyzer reagents", "code": "41116008" },
      { "name": "Histology analyzer reagents", "code": "41116009" },
      { "name": "Immunology analyzer reagents", "code": "41116010" },
      { "name": "Microbiology analyzer reagents", "code": "41116011" },
      { "name": "Protein analyzer reagents", "code": "41116012" },
      { "name": "Radioisotopic analyzer reagents", "code": "41116013" },
      { "name": "Urinalysis analyzer reagents", "code": "41116014" },
      {
        "name": "Flow cytometry analyzers reagents or antibodies",
        "code": "41116015"
      },
      { "name": "Electrolyte analyzer reagent", "code": "41116016" },
      {
        "name": "Androgeny and fertility automated counter reagent",
        "code": "41116017"
      },
      {
        "name": "Particle sizer and counter analyzer reagent",
        "code": "41116018"
      },
      { "name": "Hormone analyzer reagent", "code": "41116019" },
      { "name": "Virology analyzer reagent", "code": "41116020" },
      {
        "name": "Automated blood culture system analyzer reagent",
        "code": "41116021"
      },
      {
        "name": "Pipettor delivery calibration and verification analyzer reagent",
        "code": "41116022"
      },
      {
        "name": "Automated high performance chromatography HPLC analyzer system reagent or kit",
        "code": "41116023"
      },
      { "name": "Transplant analyzer reagent or kit", "code": "41116024" },
      {
        "name": "Flame photometer analyzer reagent or kit",
        "code": "41116025"
      },
      {
        "name": "Gas-liquid chromatography and mass spectrometry analyzer reagent",
        "code": "41116026"
      },
      {
        "name": "Nucleic acid extraction isolation and purification analyzer reagent and kit",
        "code": "41116027"
      },
      {
        "name": "Manual test kits and quality controls and calibrators and standards",
        "code": "41116100"
      },
      { "name": "Blood bank test kits or supplies", "code": "41116101" },
      { "name": "Blood bank reagents or solutions", "code": "41116102" },
      {
        "name": "Blood bank quality controls or calibrators or standards",
        "code": "41116103"
      },
      { "name": "Chemistry test kits or supplies", "code": "41116104" },
      { "name": "Chemistry reagents or solutions", "code": "41116105" },
      { "name": "Chemistry test strips or test paper", "code": "41116106" },
      {
        "name": "Chemistry quality controls or calibrators or standards",
        "code": "41116107"
      },
      { "name": "Coagulation test kits or supplies", "code": "41116108" },
      { "name": "Coagulation reagents or solutions", "code": "41116109" },
      {
        "name": "Coagulation quality controls or calibrators or standards",
        "code": "41116110"
      },
      { "name": "Cytology test kits or supplies", "code": "41116111" },
      {
        "name": "Cytology quality controls or calibrators or standards",
        "code": "41116112"
      },
      {
        "name": "Cytology reagents or solutions or stains",
        "code": "41116113"
      },
      { "name": "Environmental test kits or supplies", "code": "41116116" },
      {
        "name": "Environmental reagents or solutions or stains",
        "code": "41116117"
      },
      { "name": "Food test kits or supplies", "code": "41116118" },
      {
        "name": "Food test kits reagents or solutions or stains",
        "code": "41116119"
      },
      { "name": "Hematology test kits or supplies", "code": "41116120" },
      {
        "name": "Hematology reagents or solutions or stains",
        "code": "41116121"
      },
      {
        "name": "Hematology quality controls or calibrators or standards",
        "code": "41116122"
      },
      { "name": "Histology test kits or supplies", "code": "41116123" },
      {
        "name": "Histology reagents or solutions or stains",
        "code": "41116124"
      },
      {
        "name": "Histology quality controls or calibrators or standards",
        "code": "41116125"
      },
      {
        "name": "Immunology or serology test kits or supplies",
        "code": "41116126"
      },
      {
        "name": "Immunology or serology reagents or solutions or stains",
        "code": "41116127"
      },
      {
        "name": "Immunology or serology quality controls or calibrators or standards",
        "code": "41116128"
      },
      {
        "name": "Microbiology or bacteriology test kits or supplies",
        "code": "41116129"
      },
      {
        "name": "Microbiology or bacteriology reagents or solutions or stains",
        "code": "41116130"
      },
      {
        "name": "Microbiology or bacteriology identification or sensitivity disks or panels",
        "code": "41116131"
      },
      {
        "name": "Microbiology or bacteriology quality controls or calibrators or standards",
        "code": "41116132"
      },
      { "name": "Molecular biology test kits or supplies", "code": "41116133" },
      {
        "name": "Molecular biology reagents or solutions or stains",
        "code": "41116134"
      },
      {
        "name": "Molecular biology quality controls or calibrators or standards",
        "code": "41116135"
      },
      { "name": "Urinalysis test kits or supplies", "code": "41116136" },
      {
        "name": "Urinalysis reagents or solutions or stains",
        "code": "41116137"
      },
      { "name": "Urinalysis test strips", "code": "41116138" },
      {
        "name": "Urinalysis quality controls or calibrators or standards",
        "code": "41116139"
      },
      {
        "name": "Parasitology or mycology test kits or supplies",
        "code": "41116140"
      },
      {
        "name": "Parasitology or mycology reagents or solutions or stains",
        "code": "41116141"
      },
      { "name": "Parasitology or mycology media", "code": "41116142" },
      {
        "name": "Parasitology or mycology quality controls or calibrators or standards",
        "code": "41116143"
      },
      { "name": "Virology test kits or supplies", "code": "41116144" },
      {
        "name": "Virology quality controls or calibrators or standards",
        "code": "41116145"
      },
      { "name": "Toxicology test kits or supplies", "code": "41116146" },
      {
        "name": "Toxicology quality controls or calibrators or standards",
        "code": "41116147"
      },
      { "name": "Flow cytometry test kits or supplies", "code": "41116148" },
      { "name": "Limulus amebocyte lysate LAL tester", "code": "41116149" },
      {
        "name": "Androgeny and fertility test kits and supplies",
        "code": "41116150"
      },
      {
        "name": "Androgeny and fertility quality controls and calibrators and standards",
        "code": "41116151"
      },
      {
        "name": "Flow cytometry quality controls and calibrators and standards",
        "code": "41116152"
      },
      {
        "name": "Immunohistochemistry quality controls and calibrators and standards",
        "code": "41116153"
      },
      {
        "name": "Particle counter and sizer quality controls and calibrators and standards",
        "code": "41116154"
      },
      {
        "name": "Molecular biology and cell culture growth media",
        "code": "41116155"
      },
      {
        "name": "Virology reagents or solutions or stains",
        "code": "41116156"
      },
      { "name": "Virology cell and tissue culture media", "code": "41116157" },
      {
        "name": "Enzyme linked immunosorbent assay ELISA kit",
        "code": "41116158"
      },
      { "name": "Transplant diagnostics test kit", "code": "41116159" },
      {
        "name": "Transplant diagnostics reagent or solution",
        "code": "41116160"
      },
      {
        "name": "Primary and secondary antibodies for multiple methodology immunostaining detection application",
        "code": "41116161"
      },
      {
        "name": "Androgeny and fertility reagent or solution or stain",
        "code": "41116162"
      },
      {
        "name": "Pipettor delivery manual calibration and verification test kit",
        "code": "41116163"
      },
      {
        "name": "Test kit or probe for laboratory proficiency assessment or laboratory performance improvement tracking",
        "code": "41116164"
      },
      { "name": "Manual blood culture system", "code": "41116165" },
      {
        "name": "Ph meter and conductivity meter calibrant and standard",
        "code": "41116166"
      },
      {
        "name": "Radio labeled chemical for radiometric detection",
        "code": "41116167"
      },
      { "name": "Fecal test kit or supplies", "code": "41116168" },
      {
        "name": "Patient point of care testing supplies and equipment",
        "code": "41116200"
      },
      { "name": "Glucose monitors or meters", "code": "41116201" },
      { "name": "Cholesterol monitors or meters", "code": "41116202" },
      { "name": "Monitor or meter accessories", "code": "41116203" },
      { "name": "Rapid test kits", "code": "41116205" },
      {
        "name": "Diagnostic beverages for laboratory testing",
        "code": "41116206"
      },
      {
        "name": "Hemoglobin or hematocrit monitor or meter",
        "code": "41116207"
      },
      {
        "name": "Blood chemistry multiple parameter monitor or meter",
        "code": "41116208"
      },
      {
        "name": "Blood coagulation multiple parameter monitor or meter",
        "code": "41116209"
      },
      {
        "name": "Metabolic disorder breathalyzer monitor or meter",
        "code": "41116210"
      },
      { "name": "Ethanol breathalyzer", "code": "41116211" },
      {
        "name": "Monitor or meter quality controls calibrators or standards",
        "code": "41116212"
      },
      {
        "name": "Glycosolated hemoglobin HBA1C test meter",
        "code": "41116213"
      },
      {
        "name": "Glycosolated hemoglobin HBA1C test meter accessories",
        "code": "41116214"
      },
      {
        "name": "Glucose test monitor or meter accessories",
        "code": "41116215"
      },
      { "name": "Blood lead test monitor or meter", "code": "41116216" },
      {
        "name": "Blood lead test monitor or meter accessories",
        "code": "41116217"
      },
      {
        "name": "Cardiac marker panel test monitor or meter",
        "code": "41116218"
      },
      {
        "name": "Cardiac marker panel test monitor or meter accessories",
        "code": "41116219"
      },
      {
        "name": "Lipid profile or at risk liver enzyme test monitor or meter",
        "code": "41116220"
      },
      {
        "name": "Lipid profile or at risk liver enzyme test monitor or meter accessories",
        "code": "41116221"
      },
      { "name": "Blood bilirubin test monitor or meter", "code": "41116222" },
      {
        "name": "Blood bilirubin test monitor or meter accessories",
        "code": "41116223"
      },
      {
        "name": "Glucose and beta hemoglobin test monitor or meter accessories",
        "code": "41116224"
      },
      { "name": "Laboratory flash point testers", "code": "41116300" },
      { "name": "Flash point testers", "code": "41116301" },
      { "name": "Incombustibility tester", "code": "41116302" },
      { "name": "Flammability tester", "code": "41116303" },
      { "name": "Fire point tester", "code": "41116304" },
      {
        "name": "Acceleration and vibration measuring instruments",
        "code": "41116400"
      },
      { "name": "Impact hammers", "code": "41116401" },
      { "name": "Instrument parts and accessories", "code": "41116500" },
      { "name": "Meter dials or dial kits", "code": "41116501" },
      { "name": "Electrical clip", "code": "41116502" },
      { "name": "Laboratory supplies and fixtures", "code": "41120000" },
      {
        "name": "Pipettes and liquid handling equipment and supplies",
        "code": "41121500"
      },
      {
        "name": "Robotic or automated liquid handling systems",
        "code": "41121501"
      },
      { "name": "Laboratory diluters", "code": "41121502" },
      {
        "name": "Manual multichannel air displacement pipetters",
        "code": "41121503"
      },
      {
        "name": "Manual single channel air displacement pipetters",
        "code": "41121504"
      },
      {
        "name": "Manual single channel positive displacement pipetters",
        "code": "41121505"
      },
      {
        "name": "Manual single channel repeating pipetters",
        "code": "41121506"
      },
      { "name": "Electronic single channel pipetters", "code": "41121507" },
      { "name": "Electronic multichannel pipetters", "code": "41121508" },
      { "name": "Pasteur or transfer pipettes", "code": "41121509" },
      { "name": "Volumetric pipettes", "code": "41121510" },
      { "name": "Serological pipettes", "code": "41121511" },
      { "name": "Dropping pipettes", "code": "41121513" },
      { "name": "Pipette pumps", "code": "41121514" },
      { "name": "Pipette bulbs", "code": "41121515" },
      { "name": "Bottle top dispensers", "code": "41121516" },
      { "name": "Pipetter inserts or accessories", "code": "41121517" },
      { "name": "Aspirating pipette", "code": "41121518" },
      { "name": "Positive displacment repeating pipettor", "code": "41121519" },
      {
        "name": "Automated vial or tube decapper recapper",
        "code": "41121520"
      },
      {
        "name": "Automated liquid handling system accessories",
        "code": "41121521"
      },
      { "name": "Pipettor reagent reservoir", "code": "41121522" },
      { "name": "Pipette tips", "code": "41121600" },
      { "name": "Filter tip pipette tips", "code": "41121601" },
      { "name": "Aerosol barrier pipette tips", "code": "41121602" },
      { "name": "Low retention pipette tips", "code": "41121603" },
      { "name": "Reference pipette tips", "code": "41121604" },
      { "name": "Ultramicro pipette tips", "code": "41121605" },
      { "name": "Gel loading pipette tips", "code": "41121606" },
      { "name": "Universal pipette tips", "code": "41121607" },
      { "name": "Robotic pipette tips", "code": "41121608" },
      { "name": "Variable volume pipette tips", "code": "41121609" },
      {
        "name": "Repeating pipettor reservoir pipette tip",
        "code": "41121610"
      },
      { "name": "Test Tubes", "code": "41121700" },
      { "name": "Multipurpose or general test tubes", "code": "41121701" },
      { "name": "Microcentrifuge tubes", "code": "41121702" },
      { "name": "Centrifuge tubes", "code": "41121703" },
      { "name": "Cryogenic tubes", "code": "41121704" },
      { "name": "Nuclear magnetic resonance NMR tubes", "code": "41121705" },
      { "name": "Culture tubes", "code": "41121706" },
      { "name": "Separator test tubes", "code": "41121707" },
      { "name": "Anti coagulant test tubes", "code": "41121708" },
      { "name": "Capillary or hematocrit tubes", "code": "41121709" },
      { "name": "Test tube closures or caps", "code": "41121710" },
      { "name": "Urinalysis testing tubes or accessories", "code": "41121711" },
      { "name": "Viscometer tube", "code": "41121712" },
      { "name": "Sand size analyzer", "code": "41121713" },
      { "name": "Cluster tube or tube strip or cap", "code": "41121714" },
      { "name": "Specimen transport tube or aliquot tube", "code": "41121715" },
      {
        "name": "General laboratory glassware and plasticware and supplies",
        "code": "41121800"
      },
      { "name": "Laboratory watch glasses", "code": "41121801" },
      { "name": "Laboratory stirring rods", "code": "41121802" },
      { "name": "Laboratory beakers", "code": "41121803" },
      { "name": "Laboratory flasks", "code": "41121804" },
      { "name": "Laboratory graduated cylinders", "code": "41121805" },
      { "name": "Laboratory vials", "code": "41121806" },
      { "name": "Laboratory ampoules", "code": "41121807" },
      { "name": "Laboratory burets", "code": "41121808" },
      { "name": "Laboratory funnels", "code": "41121809" },
      { "name": "Laboratory staining dishes or jars", "code": "41121810" },
      { "name": "Laboratory microchemistry kits", "code": "41121811" },
      { "name": "Laboratory dishes", "code": "41121812" },
      { "name": "Cuvettes", "code": "41121813" },
      { "name": "Laboratory lids or covers or coverslips", "code": "41121814" },
      {
        "name": "Laboratory adapters or connectors or fittings",
        "code": "41121815"
      },
      { "name": "Laboratory glass tube", "code": "41121816" },
      { "name": "Glass bell jar", "code": "41121817" },
      { "name": "Laboratory storage bottle", "code": "41121818" },
      {
        "name": "Laboratory dropper bottle and dropper cap",
        "code": "41121819"
      },
      { "name": "Laboratory carboy or spout", "code": "41121820" },
      { "name": "Vial closure cap or seal or stopper", "code": "41121821" },
      { "name": "Laboratory or sampling syringes", "code": "41122000" },
      { "name": "Chromatography syringes", "code": "41122001" },
      { "name": "Chromatography syringe needles", "code": "41122002" },
      { "name": "Syringe adapters or accessories", "code": "41122003" },
      { "name": "Sampling syringes", "code": "41122004" },
      {
        "name": "Tissue culture and high throughput screening supplies",
        "code": "41122100"
      },
      { "name": "Petri plates or dishes", "code": "41122101" },
      { "name": "Multiwell plates", "code": "41122102" },
      { "name": "Cell scrapers", "code": "41122103" },
      { "name": "Tissue culture flasks", "code": "41122104" },
      { "name": "Roller bottles", "code": "41122105" },
      { "name": "Inoculating devices", "code": "41122106" },
      {
        "name": "Tissue culture coated plates or dishes or inserts",
        "code": "41122107"
      },
      {
        "name": "Microbiology inoculation loops or needles",
        "code": "41122108"
      },
      { "name": "Petri pads", "code": "41122109" },
      { "name": "Petri pad dispenser", "code": "41122110" },
      { "name": "Tissue culture chambered slide", "code": "41122111" },
      { "name": "Cell culture glass capillary tube", "code": "41122112" },
      { "name": "Capillary pipette or tube puller", "code": "41122113" },
      { "name": "In situ culture harvester", "code": "41122114" },
      { "name": "Crucibles", "code": "41122200" },
      { "name": "Glass crucibles", "code": "41122201" },
      { "name": "Ceramic crucibles", "code": "41122202" },
      { "name": "Metal crucibles", "code": "41122203" },
      { "name": "Laboratory bench protectors and liners", "code": "41122300" },
      { "name": "Bench protectors or liners", "code": "41122301" },
      { "name": "Laboratory implements", "code": "41122400" },
      {
        "name": "Magnetic spin bars or stir bars or stirring beads",
        "code": "41122401"
      },
      {
        "name": "Magnetic spin bar retrievers or stir bar retrievers",
        "code": "41122402"
      },
      { "name": "Laboratory spatulas", "code": "41122403" },
      { "name": "Laboratory tongs", "code": "41122404" },
      { "name": "Laboratory forceps", "code": "41122405" },
      { "name": "Laboratory knives", "code": "41122406" },
      { "name": "Laboratory scalpels", "code": "41122407" },
      { "name": "Laboratory scissors", "code": "41122408" },
      { "name": "Laboratory tools", "code": "41122409" },
      { "name": "Laboratory sealing film", "code": "41122410" },
      { "name": "Laboratory timers or watches", "code": "41122411" },
      { "name": "Laboratory tube sealants", "code": "41122412" },
      { "name": "Laboratory clamps", "code": "41122413" },
      { "name": "Laboratory spoon", "code": "41122414" },
      { "name": "Centrifugal bioseparation crystals", "code": "41122415" },
      { "name": "Scalpel blade and knife blade remover", "code": "41122416" },
      { "name": "Blood drop dispenser device", "code": "41122417" },
      { "name": "Donor blood unit segment sampler", "code": "41122418" },
      { "name": "Laboratory scoop", "code": "41122419" },
      { "name": "Laboratory bottle carrier", "code": "41122420" },
      { "name": "Flask ring stabilizer or weight", "code": "41122421" },
      {
        "name": "Laboratory corks and stoppers and accessories",
        "code": "41122500"
      },
      { "name": "Laboratory corks", "code": "41122501" },
      { "name": "Laboratory stoppers", "code": "41122502" },
      { "name": "Laboratory cork borers", "code": "41122503" },
      {
        "name": "Laboratory microscope slides and supplies",
        "code": "41122600"
      },
      { "name": "Microscope slides", "code": "41122601" },
      { "name": "Microscopes slide coverslips", "code": "41122602" },
      { "name": "Microscope lens paper", "code": "41122603" },
      { "name": "Hemocytometers", "code": "41122604" },
      { "name": "Microscope immersion oil", "code": "41122605" },
      { "name": "Microscope slide dispensers", "code": "41122606" },
      { "name": "Microscope lens cleaner solution", "code": "41122607" },
      { "name": "Hemocytometer cover glass", "code": "41122608" },
      { "name": "Laboratory tapes and labels", "code": "41122700" },
      { "name": "Slide or specimen labels", "code": "41122701" },
      { "name": "Labeling tapes", "code": "41122702" },
      { "name": "Safety tapes", "code": "41122703" },
      { "name": "Tamper proof tapes", "code": "41122704" },
      { "name": "Laboratory stands and racks and trays", "code": "41122800" },
      { "name": "Pipette racks or stands", "code": "41122801" },
      { "name": "Microscope slide racks", "code": "41122802" },
      { "name": "Sedimentation tube racks or stands", "code": "41122803" },
      { "name": "Test tube racks", "code": "41122804" },
      { "name": "Drying racks", "code": "41122805" },
      { "name": "Cryoracks", "code": "41122806" },
      { "name": "Dissecting trays", "code": "41122807" },
      { "name": "General purpose trays", "code": "41122808" },
      { "name": "Petri dish racks", "code": "41122809" },
      { "name": "Benchtop reagent storage rack", "code": "41122810" },
      { "name": "Test and culture tube dispenser", "code": "41122811" },
      { "name": "Laboratory desiccators and desiccants", "code": "41123000" },
      { "name": "Jar desiccators", "code": "41123001" },
      { "name": "Cabinet desiccators", "code": "41123002" },
      { "name": "Desiccants", "code": "41123003" },
      { "name": "Vacuum desiccators", "code": "41123004" },
      {
        "name": "Microbial susceptibility disc desiccant storage container",
        "code": "41123005"
      },
      { "name": "Desiccator accessories", "code": "41123006" },
      { "name": "Laboratory dialysis supplies", "code": "41123100" },
      { "name": "Dialysis tubing", "code": "41123101" },
      { "name": "Dialysis clamps", "code": "41123102" },
      { "name": "Dialysis cassette", "code": "41123103" },
      { "name": "Dialysis membrane", "code": "41123104" },
      { "name": "Dialysis cassette holder", "code": "41123105" },
      { "name": "Dialysis flask", "code": "41123106" },
      { "name": "Preserved specimens and supplies", "code": "41123200" },
      { "name": "Preserved prepared slides", "code": "41123201" },
      { "name": "Preserved animals and organisms", "code": "41123202" },
      { "name": "Virology stock control culture", "code": "41123203" },
      {
        "name": "Microbial quality control QC reference organism",
        "code": "41123204"
      },
      {
        "name": "General laboratory storage containers and cabinets",
        "code": "41123300"
      },
      { "name": "Microscope slide boxes or folders", "code": "41123302" },
      { "name": "Microscope slide cabinets", "code": "41123303" },
      { "name": "Cryogenic storage boxes", "code": "41123304" },
      { "name": "Histology or tissue cassette cabinets", "code": "41123305" },
      { "name": "Histology tissue cassette accessories", "code": "41123306" },
      {
        "name": "Histology tissue cassette storage tray or box",
        "code": "41123307"
      },
      { "name": "Pipette storage canister or box", "code": "41123308" },
      { "name": "Pasteur or transfer pipette dispenser", "code": "41123309" },
      { "name": "Blood collection tube dispenser", "code": "41123310" },
      {
        "name": "Phlebotomy area organization rack and dispenser",
        "code": "41123311"
      },
      {
        "name": "Bioprocess container or bag for molecular biology platform",
        "code": "41123312"
      },
      {
        "name": "Histology specimen container storage tray",
        "code": "41123313"
      },
      {
        "name": "Microscope slide and tissue cabinet marker and accessories",
        "code": "41123314"
      },
      { "name": "Dosing devices", "code": "41123400" },
      { "name": "Dosing cups", "code": "41123401" },
      { "name": "Dosing spoons", "code": "41123402" },
      { "name": "Dosing droppers", "code": "41123403" }
    ]
  },
  {
    "name": "Medical Equipment and Accessories and Supplies",
    "code": "42000000",
    "subcategories": [
      { "name": "Veterinary equipment and supplies", "code": "42120000" },
      { "name": "Veterinary equipment", "code": "42121500" },
      { "name": "Veterinary blood pressure testers", "code": "42121501" },
      { "name": "Veterinary kymograph tester", "code": "42121502" },
      { "name": "Veterinary pyrogenic tester", "code": "42121503" },
      { "name": "Veterinary stereotoxic equipment", "code": "42121504" },
      { "name": "Veterinary electrocardiograph ECG", "code": "42121505" },
      { "name": "Veterinary surgical instrument sets", "code": "42121506" },
      {
        "name": "Veterinary injection or suction units or accessories",
        "code": "42121507"
      },
      { "name": "Veterinary bottle sets", "code": "42121508" },
      { "name": "Veterinary clinical thermometers", "code": "42121509" },
      {
        "name": "Cases for veterinary instruments or accessories",
        "code": "42121510"
      },
      {
        "name": "Instrument rolls for veterinary instruments or accessories",
        "code": "42121511"
      },
      { "name": "Veterinary speculums", "code": "42121512" },
      { "name": "Veterinary castration instruments", "code": "42121513" },
      { "name": "Veterinary external fixation kits", "code": "42121514" },
      { "name": "Veterinary nail trimmers or cutters", "code": "42121515" },
      { "name": "Veterinary products", "code": "42121600" },
      { "name": "Gastrointestinal veterinary products", "code": "42121601" },
      {
        "name": "Blood or blood forming veterinary products",
        "code": "42121602"
      },
      { "name": "Respiratory system veterinary products", "code": "42121603" },
      {
        "name": "Musculo skeletal or nervous system veterinary products",
        "code": "42121604"
      },
      {
        "name": "Cardiovascular system veterinary products",
        "code": "42121605"
      },
      {
        "name": "Dermatologicals or antiprotozoa veterinary products",
        "code": "42121606"
      },
      {
        "name": "Genito urinary system sex or hormone veterinary products",
        "code": "42121607"
      },
      { "name": "Dental veterinary products", "code": "42121608" },
      { "name": "Veterinary clinical furniture", "code": "42121700" },
      { "name": "Veterinary surgical tables", "code": "42121701" },
      { "name": "Veterinary storage chests", "code": "42121702" },
      {
        "name": "Veterinary artificial insemination equipment and supplies",
        "code": "42121800"
      },
      { "name": "Artificial inseminating machine", "code": "42121801" },
      { "name": "Semen collection instrument", "code": "42121802" },
      { "name": "Electric ejaculation stimulator", "code": "42121803" },
      { "name": "Semen packaging instrument", "code": "42121804" },
      { "name": "Medical apparel and textiles", "code": "42130000" },
      { "name": "Patient clothing", "code": "42131500" },
      { "name": "Patient bibs", "code": "42131501" },
      { "name": "Patient caps", "code": "42131502" },
      { "name": "Patient exam capes", "code": "42131503" },
      { "name": "Patient gowns", "code": "42131504" },
      { "name": "Patient infant shirts or vests", "code": "42131505" },
      { "name": "Patient jackets", "code": "42131506" },
      { "name": "Patient slippers", "code": "42131507" },
      { "name": "Patient pajamas", "code": "42131508" },
      { "name": "Hospital robes", "code": "42131509" },
      { "name": "Patient trousers", "code": "42131510" },
      { "name": "Hydrotherapy garment", "code": "42131511" },
      {
        "name": "Patient infant cap or hat or material for infant cap",
        "code": "42131512"
      },
      {
        "name": "Medical staff clothing and related articles",
        "code": "42131600"
      },
      { "name": "Medical staff aprons or bibs", "code": "42131601" },
      { "name": "Medical staff beard covers", "code": "42131602" },
      { "name": "Medical staff blouses or smocks", "code": "42131603" },
      { "name": "Medical staff bouffant caps", "code": "42131604" },
      { "name": "Medical staff coveralls", "code": "42131605" },
      {
        "name": "Medical staff isolation or surgical masks",
        "code": "42131606"
      },
      { "name": "Medical staff jackets or coats", "code": "42131607" },
      { "name": "Medical staff scrubs", "code": "42131608" },
      { "name": "Medical staff shoe covers", "code": "42131609" },
      { "name": "Medical staff sleeve protection", "code": "42131610" },
      { "name": "Surgeon caps or hoods", "code": "42131611" },
      { "name": "Medical staff isolation or cover gown", "code": "42131612" },
      { "name": "Medical staff eye shield or visor", "code": "42131613" },
      { "name": "Surgical textiles", "code": "42131700" },
      { "name": "Surgical drapes", "code": "42131701" },
      { "name": "Surgical gown", "code": "42131702" },
      { "name": "Surgical towels", "code": "42131704" },
      { "name": "Surgical leggings", "code": "42131705" },
      { "name": "Surgical jumpsuits", "code": "42131706" },
      {
        "name": "Surgical isolation suits or helmets or facemasks or accessories",
        "code": "42131707"
      },
      {
        "name": "Surgical cleanup or room turnover pack or kit",
        "code": "42131708"
      },
      { "name": "Hospital housekeeping textiles", "code": "42132100" },
      { "name": "Hospital underpads", "code": "42132101" },
      { "name": "Medical stretcher sheets", "code": "42132102" },
      { "name": "Patient barrier drapes", "code": "42132103" },
      {
        "name": "Hospital antimicrobial pillows for general use",
        "code": "42132104"
      },
      { "name": "Hospital bed sheets", "code": "42132105" },
      { "name": "Hospital bedspreads", "code": "42132106" },
      { "name": "Hospital blankets", "code": "42132107" },
      { "name": "Hospital protective pillow covers", "code": "42132108" },
      { "name": "Medical gloves and accessories", "code": "42132200" },
      { "name": "Medical glove boxes or dispensers", "code": "42132201" },
      { "name": "Finger cots", "code": "42132202" },
      {
        "name": "Medical exam or non surgical procedure gloves",
        "code": "42132203"
      },
      { "name": "Medical glove liners", "code": "42132204" },
      { "name": "Surgical gloves", "code": "42132205" },
      {
        "name": "Patient care and treatment products and supplies",
        "code": "42140000"
      },
      { "name": "Applicator swabs and cotton balls", "code": "42141500" },
      { "name": "Cotton ball or fiber", "code": "42141501" },
      { "name": "Fiber tipped sticks", "code": "42141502" },
      { "name": "Skin preparation wipes", "code": "42141503" },
      { "name": "Medicated applicators or absorbents", "code": "42141504" },
      {
        "name": "Basins and bedpans and urinals and admission kits",
        "code": "42141600"
      },
      { "name": "Patient care admission kit", "code": "42141601" },
      { "name": "Bedpans for general use", "code": "42141602" },
      { "name": "Emesis basins", "code": "42141603" },
      {
        "name": "Medical mixing or solution basins or bowls",
        "code": "42141605"
      },
      { "name": "Medical multipurpose basins", "code": "42141606" },
      { "name": "Patient urinals for general use", "code": "42141607" },
      { "name": "Bedpan liner or bag", "code": "42141608" },
      { "name": "Decubitus prevention products", "code": "42141700" },
      { "name": "Alternating pressure systems", "code": "42141701" },
      { "name": "Blanket frames or lifters", "code": "42141702" },
      { "name": "Extremities cradles", "code": "42141703" },
      { "name": "Mattress overlays", "code": "42141704" },
      {
        "name": "Patient positioning cushions or pads or pillows",
        "code": "42141705"
      },
      { "name": "Electrotherapy equipment", "code": "42141800" },
      { "name": "Electrotherapy combination units", "code": "42141801" },
      {
        "name": "Electrotherapy electrodes or accessories",
        "code": "42141802"
      },
      { "name": "Electrotherapy lead wires or cables", "code": "42141803" },
      { "name": "Galvanic or faradic stimulators", "code": "42141804" },
      { "name": "Neuromuscular stimulators or kits", "code": "42141805" },
      { "name": "Short wave diathermy units", "code": "42141806" },
      {
        "name": "Transcutaneous electric nerve stimulation units",
        "code": "42141807"
      },
      { "name": "Diathermy electrodes", "code": "42141808" },
      { "name": "Muscle stimulator lead sets", "code": "42141809" },
      { "name": "Iontophoresis electrode", "code": "42141810" },
      {
        "name": "Extracorporeal shock wave therapy system",
        "code": "42141811"
      },
      {
        "name": "Static electricity generator for electric stimulation",
        "code": "42141812"
      },
      { "name": "Electroconvulsive device", "code": "42141813" },
      { "name": "Static electricity stimulator", "code": "42141814" },
      {
        "name": "High frequency electromagnetic wave stimulator",
        "code": "42141815"
      },
      { "name": "Electromagnetic therapy stimulator", "code": "42141816" },
      { "name": "Magnetic pulse stimulator", "code": "42141817" },
      { "name": "Enema administration supplies", "code": "42141900" },
      { "name": "Enema buckets", "code": "42141901" },
      { "name": "Enema bags", "code": "42141902" },
      { "name": "Enema kits or accessories", "code": "42141903" },
      { "name": "Enema tubing or tips or clamps", "code": "42141904" },
      { "name": "Enema soaps", "code": "42141905" },
      { "name": "Floor grade instruments", "code": "42142000" },
      { "name": "Floor grade forceps or hemostats", "code": "42142001" },
      { "name": "Floor grade knife", "code": "42142002" },
      { "name": "Floor grade knife handles", "code": "42142003" },
      { "name": "Floor grade nail nippers", "code": "42142004" },
      { "name": "Floor grade needle holders", "code": "42142005" },
      { "name": "Floor grade retractors", "code": "42142006" },
      { "name": "Floor grade scissors", "code": "42142007" },
      { "name": "Heat and cold therapy products", "code": "42142100" },
      {
        "name": "Covers for heat or cold therapy products",
        "code": "42142101"
      },
      {
        "name": "Medical cold storage chilling units or accessories",
        "code": "42142102"
      },
      { "name": "Medical heat lamps or accessories", "code": "42142103" },
      { "name": "Medical hydrocollators or accessories", "code": "42142104" },
      {
        "name": "Therapeutic heating or cooling units or systems",
        "code": "42142105"
      },
      {
        "name": "Therapeutic heating or cooling blankets or drapes",
        "code": "42142106"
      },
      {
        "name": "Therapeutic cryo compression therapy system and accessories",
        "code": "42142107"
      },
      {
        "name": "Therapeutic heating or cooling pads or compresses or packs",
        "code": "42142108"
      },
      { "name": "Therapeutic hot or cold therapy mitts", "code": "42142109" },
      { "name": "Therapeutic hot or cold water bottles", "code": "42142110" },
      { "name": "Therapeutic ice packs or pillows", "code": "42142111" },
      {
        "name": "Therapeutic paraffin baths or accessories",
        "code": "42142112"
      },
      { "name": "Therapeutic sinus masks", "code": "42142113" },
      { "name": "Therapeutic hot or cold therapy pants", "code": "42142114" },
      { "name": "Hypothermia apparatus", "code": "42142119" },
      {
        "name": "Therapeutic cryo compression therapy cuff",
        "code": "42142120"
      },
      { "name": "Moist steam cabinet", "code": "42142121" },
      { "name": "Hydrotherapy products", "code": "42142200" },
      { "name": "Extremity hydrotherapy baths or tanks", "code": "42142201" },
      {
        "name": "Full body immersion hydrotherapy baths or tanks",
        "code": "42142202"
      },
      { "name": "Hydrotherapy bath or tank accessories", "code": "42142203" },
      { "name": "Hydrotherapy bath chairs", "code": "42142204" },
      { "name": "Medical documentation products", "code": "42142300" },
      { "name": "General use medical labels", "code": "42142301" },
      {
        "name": "Medical charting systems components or accessories",
        "code": "42142302"
      },
      {
        "name": "Patient identification and information products",
        "code": "42142303"
      },
      { "name": "Medical suction and vacuum products", "code": "42142400" },
      { "name": "Medical fluid solidifiers", "code": "42142401" },
      {
        "name": "Medical suction cannulas or tubes or accessories",
        "code": "42142402"
      },
      { "name": "Medical suction containers", "code": "42142403" },
      { "name": "Medical suction or vacuum appliances", "code": "42142404" },
      { "name": "Medical suction sets or kits", "code": "42142406" },
      { "name": "Cases for medical suction cannula", "code": "42142407" },
      {
        "name": "Injection and aspiration needles and accessories",
        "code": "42142500"
      },
      { "name": "Amniocentesis needles", "code": "42142501" },
      { "name": "Anesthesia needles", "code": "42142502" },
      { "name": "Arterial needles", "code": "42142503" },
      { "name": "Biopsy needles", "code": "42142504" },
      { "name": "Blood collection needle holders", "code": "42142505" },
      { "name": "Blunt needles", "code": "42142506" },
      { "name": "Butterfly needles", "code": "42142507" },
      { "name": "Epidural anesthesia kit or tray", "code": "42142509" },
      { "name": "Filter needles", "code": "42142510" },
      {
        "name": "Needle caps or protection devices or accessories",
        "code": "42142511"
      },
      { "name": "Fistula needles", "code": "42142512" },
      { "name": "Radiology procedural needles", "code": "42142513" },
      { "name": "Spinal anesthesia kit or tray", "code": "42142514" },
      { "name": "Vacuum tube needles", "code": "42142515" },
      { "name": "Vented needles", "code": "42142516" },
      { "name": "Extension tubes", "code": "42142517" },
      {
        "name": "Biopsy aspirator products or accessories",
        "code": "42142518"
      },
      { "name": "Needle guides", "code": "42142519" },
      { "name": "Dispensing pins", "code": "42142520" },
      { "name": "Blood collection needle", "code": "42142521" },
      {
        "name": "Surgical needles for ears or noses or throats",
        "code": "42142522"
      },
      { "name": "Hypodermic needle", "code": "42142523" },
      { "name": "Etching needles or sets", "code": "42142524" },
      { "name": "Irrigation needles", "code": "42142525" },
      { "name": "Needle protectors", "code": "42142526" },
      { "name": "Needle cleaning wires", "code": "42142527" },
      { "name": "Sternum puncture needles or sets", "code": "42142528" },
      { "name": "Needle trays or holders", "code": "42142529" },
      { "name": "Diagnostic procedure needles", "code": "42142530" },
      {
        "name": "Needle or blade or sharps disposal container or cart",
        "code": "42142531"
      },
      {
        "name": "Pericardiocentesis needles or kits or accessories",
        "code": "42142532"
      },
      {
        "name": "Needle for intravenous or arterial administration ports",
        "code": "42142533"
      },
      { "name": "Intraosseous needle", "code": "42142534" },
      { "name": "Anesthesia nerve block tray or needle", "code": "42142535" },
      {
        "name": "Thoracentesis or paracentesis needle and catheter",
        "code": "42142536"
      },
      { "name": "Thoracentesis set or tray", "code": "42142537" },
      { "name": "Paracentesis set or tray", "code": "42142538" },
      { "name": "Syringes and accessories", "code": "42142600" },
      {
        "name": "Medical aspiration or irrigation syringes",
        "code": "42142601"
      },
      { "name": "Medical bulb syringes", "code": "42142602" },
      { "name": "Medical cartridge syringes", "code": "42142603" },
      { "name": "Medical catheter tip syringes", "code": "42142604" },
      { "name": "Medical ear syringes", "code": "42142605" },
      { "name": "Medical metered delivery syringes", "code": "42142606" },
      { "name": "Medical micro syringes", "code": "42142607" },
      { "name": "Medical syringe without needle", "code": "42142608" },
      { "name": "Medical syringe with needle", "code": "42142609" },
      { "name": "Oral liquid medication syringes", "code": "42142610" },
      { "name": "Tuberculin syringes", "code": "42142611" },
      { "name": "Irrigation syringe sets", "code": "42142612" },
      { "name": "Injection guns", "code": "42142613" },
      {
        "name": "Hypodermic injection apparatus or accessories",
        "code": "42142614"
      },
      { "name": "Syringe accessories", "code": "42142615" },
      { "name": "Blood collection syringes", "code": "42142616" },
      { "name": "Fountain syringes", "code": "42142617" },
      { "name": "Blood gas analysis syringe kits", "code": "42142618" },
      { "name": "Prefilled flush syringe", "code": "42142619" },
      {
        "name": "Intraosseous needle driver or injection gun",
        "code": "42142620"
      },
      { "name": "Urological supplies", "code": "42142700" },
      { "name": "Suprapubic urinary catheters", "code": "42142701" },
      { "name": "Urethral urinary catheters", "code": "42142702" },
      { "name": "Urinary catheter plugs or clamps", "code": "42142703" },
      { "name": "Urinary drainage bags or meters", "code": "42142704" },
      {
        "name": "Urinary drainage bag straps or fasteners",
        "code": "42142705"
      },
      {
        "name": "Urological procedure trays or packs or kits",
        "code": "42142706"
      },
      { "name": "Urinary irrigation tubings", "code": "42142707" },
      { "name": "Urological instrument adapters", "code": "42142708" },
      { "name": "Nephrostomy sets or accessories", "code": "42142709" },
      { "name": "Urinary drainage tubes or accessories", "code": "42142710" },
      { "name": "Urethral sound sets", "code": "42142711" },
      { "name": "Stone remover sets or accessories", "code": "42142712" },
      { "name": "Urological sheaths or sets", "code": "42142713" },
      { "name": "Urological percolators", "code": "42142714" },
      { "name": "Urinary catheterization kit", "code": "42142715" },
      { "name": "Pessary device", "code": "42142716" },
      { "name": "Nephrostomy catheter or drain", "code": "42142717" },
      { "name": "Penile support and lengthening device", "code": "42142718" },
      { "name": "Urethrotome", "code": "42142719" },
      { "name": "Male external urinary catheter", "code": "42142720" },
      { "name": "Urological guidewire or glidewire", "code": "42142721" },
      {
        "name": "Vascular and compression therapy equipment and supplies",
        "code": "42142800"
      },
      {
        "name": "Vascular sequential compression devices or tubing",
        "code": "42142801"
      },
      {
        "name": "Vascular or compression apparel or support",
        "code": "42142802"
      },
      {
        "name": "Vision correction or cosmetic eyewear and related products",
        "code": "42142900"
      },
      { "name": "Eyeglasses", "code": "42142901" },
      { "name": "Eyeglass lenses", "code": "42142902" },
      { "name": "Eyeglass frames", "code": "42142903" },
      { "name": "Eyeglass hardware", "code": "42142904" },
      { "name": "Sunglasses", "code": "42142905" },
      { "name": "Eyeglass cases", "code": "42142906" },
      { "name": "Eyeglass cleaning cloths", "code": "42142907" },
      { "name": "Eyeglass cleaning kits", "code": "42142908" },
      { "name": "Eyeglass retainers", "code": "42142909" },
      { "name": "Contact lens cases", "code": "42142910" },
      { "name": "Contact lens inserters or removers", "code": "42142911" },
      { "name": "Contact lens radius gauges", "code": "42142912" },
      { "name": "Contact lenses", "code": "42142913" },
      {
        "name": "Contact lens cleaning or moisterizing solution",
        "code": "42142914"
      },
      {
        "name": "Obstetrical and gynecological equipment and supplies",
        "code": "42143100"
      },
      {
        "name": "Intrauterine catheters or catheterization kits",
        "code": "42143101"
      },
      { "name": "Uterine devices or accessories", "code": "42143102" },
      {
        "name": "Contraceptive devices or accessories for female users",
        "code": "42143103"
      },
      { "name": "Amniocentesis kits or accessories", "code": "42143104" },
      {
        "name": "Obstetrical extraction units or accessories",
        "code": "42143105"
      },
      { "name": "Gynecology drainage kits or accessories", "code": "42143106" },
      { "name": "Perinometer", "code": "42143107" },
      { "name": "Abdominal decompression chamber", "code": "42143108" },
      {
        "name": "Fertility and Sterility treatment equipment and supplies",
        "code": "42143200"
      },
      { "name": "Semen preparation kits or accessories", "code": "42143201" },
      {
        "name": "Impotence treatment products or kits or accessories",
        "code": "42143202"
      },
      { "name": "Artificial insemination catheter", "code": "42143203" },
      { "name": "Embryo transfer catheter", "code": "42143204" },
      { "name": "Chemotherapy equipment and supplies", "code": "42143300" },
      {
        "name": "Chemotherapy administration sets or kits",
        "code": "42143301"
      },
      { "name": "Chemotherapy prep mat", "code": "42143302" },
      { "name": "Chemotherapy transport bag", "code": "42143303" },
      { "name": "Chemotherapy preparation pad", "code": "42143304" },
      {
        "name": "Hyperhidrosis control equipment and supplies",
        "code": "42143400"
      },
      { "name": "Sweat control equipment or accessories", "code": "42143401" },
      {
        "name": "Ear nose and throat ENT treatment products and accessories",
        "code": "42143500"
      },
      { "name": "Earmold cleaning aids", "code": "42143501" },
      { "name": "Nasal irrigation devices or accessories", "code": "42143502" },
      {
        "name": "Nasal bleeding control devices or balloons",
        "code": "42143503"
      },
      { "name": "Earmolds or its accessories", "code": "42143504" },
      { "name": "Earmold repair kits", "code": "42143505" },
      { "name": "Eustachian filiform sets", "code": "42143506" },
      { "name": "Medication ear packs", "code": "42143507" },
      { "name": "Ear windhoods", "code": "42143508" },
      { "name": "Earmold glues or cements", "code": "42143509" },
      { "name": "Ear windshield screens", "code": "42143510" },
      { "name": "Cerumen hooks", "code": "42143511" },
      { "name": "Ear wicks", "code": "42143512" },
      { "name": "Otological instruments or accessories", "code": "42143513" },
      { "name": "Ear wash system", "code": "42143514" },
      { "name": "Restraints and accessories", "code": "42143600" },
      { "name": "Restraint vests and jackets", "code": "42143601" },
      { "name": "Torso and belt restraints", "code": "42143602" },
      { "name": "Extremity restraints", "code": "42143603" },
      { "name": "Non EMS head restraints", "code": "42143604" },
      {
        "name": "Restraint straps or buckles or accessories or supplies",
        "code": "42143605"
      },
      { "name": "Full body restraints", "code": "42143606" },
      {
        "name": "Patient motion sensors or alarms or accessories",
        "code": "42143607"
      },
      {
        "name": "Patient stabilization or fall prevention devices or accessories",
        "code": "42143608"
      },
      { "name": "Patient restraint boards or accessories", "code": "42143609" },
      { "name": "Phototherapy systems and accessories", "code": "42143700" },
      {
        "name": "Phototherapy air circulators or accessories",
        "code": "42143701"
      },
      { "name": "Phototherapy blankets", "code": "42143702" },
      { "name": "Phototherapy bulbs", "code": "42143703" },
      { "name": "Phototherapy light mats", "code": "42143704" },
      { "name": "Phototherapy patient protection devices", "code": "42143705" },
      { "name": "Phototherapy power units", "code": "42143706" },
      { "name": "Phototherapy warmer beds or accessories", "code": "42143707" },
      { "name": "Phototherapy vest", "code": "42143708" },
      { "name": "Visible light radiator", "code": "42143709" },
      {
        "name": "Urological pressure measurement instruments and supplies and equipment",
        "code": "42143800"
      },
      { "name": "Cystometry systems or accessories", "code": "42143801" },
      { "name": "Cystometry transducers", "code": "42143802" },
      { "name": "Urodynamic catheters", "code": "42143803" },
      { "name": "Fecal incontinence products", "code": "42143900" },
      {
        "name": "Fecal incontinence collection bag or accessory",
        "code": "42143901"
      },
      { "name": "Fecal management system or kit", "code": "42143902" },
      { "name": "Colon catheter or rectal tube", "code": "42143903" },
      {
        "name": "External hearing device parts and accessories",
        "code": "42144000"
      },
      {
        "name": "Implanted hearing device external sound processor",
        "code": "42144001"
      },
      { "name": "Implanted hearing device external cable", "code": "42144002" },
      {
        "name": "Implanted hearing device external sound processor earphones",
        "code": "42144003"
      },
      {
        "name": "Implanted hearing device external magnet",
        "code": "42144004"
      },
      {
        "name": "Implanted hearing device external microphone",
        "code": "42144005"
      },
      { "name": "Pleural cavity drain products", "code": "42144100" },
      {
        "name": "Pleural cavity drainage unit or container or accessories",
        "code": "42144101"
      },
      { "name": "Chest tube", "code": "42144102" },
      { "name": "Chest tube kit", "code": "42144103" },
      { "name": "Acupuncture equipment", "code": "42144200" },
      { "name": "Acupuncture needle", "code": "42144201" },
      { "name": "Acupuncture magnet pellet or seed", "code": "42144202" },
      { "name": "Acupressure bracelet", "code": "42144203" },
      { "name": "External counterpulsation ECP products", "code": "42144300" },
      { "name": "External counterpulsation ECP system", "code": "42144301" },
      {
        "name": "External counterpulsation ECP cuff or pants",
        "code": "42144302"
      },
      { "name": "Dental equipment and supplies", "code": "42150000" },
      {
        "name": "Cosmetic dentistry equipment and supplies",
        "code": "42151500"
      },
      {
        "name": "Cosmetic dentistry curing lights or accessories",
        "code": "42151501"
      },
      { "name": "Cosmetic dentistry mixing wells", "code": "42151502" },
      { "name": "Crowns or crown forms", "code": "42151503" },
      { "name": "Dental veneer", "code": "42151504" },
      { "name": "Tooth etching supplies", "code": "42151505" },
      { "name": "Tooth whitening or bleaching supplies", "code": "42151506" },
      { "name": "Dental shades", "code": "42151507" },
      {
        "name": "Dental and subspecialty instruments and devices",
        "code": "42151600"
      },
      {
        "name": "Attachments or replacement parts for dental instruments",
        "code": "42151601"
      },
      { "name": "Bands for dental matrix", "code": "42151602" },
      { "name": "Calcium hydroxide placement tools", "code": "42151603" },
      { "name": "Composite placement tools", "code": "42151604" },
      { "name": "Crown or bridge removers", "code": "42151605" },
      { "name": "Dental amalgam carvers", "code": "42151606" },
      { "name": "Dental instrument cassettes", "code": "42151607" },
      { "name": "Dental instrument trays or tubs", "code": "42151608" },
      { "name": "Dental instruments mats", "code": "42151609" },
      { "name": "Dental nippers", "code": "42151610" },
      { "name": "Dental operative brushes", "code": "42151611" },
      { "name": "Dental retractors", "code": "42151612" },
      { "name": "Dental burnishers", "code": "42151613" },
      { "name": "Dental burs", "code": "42151614" },
      { "name": "Dental cryosurgical units", "code": "42151615" },
      { "name": "Dental depth gauges", "code": "42151616" },
      { "name": "Dental drills or drill bits", "code": "42151617" },
      { "name": "Dental elevators", "code": "42151618" },
      { "name": "Dental excavators", "code": "42151619" },
      { "name": "Dental files or rasps", "code": "42151620" },
      { "name": "Dental filler contouring instruments", "code": "42151621" },
      { "name": "Dental finger protectors", "code": "42151622" },
      { "name": "Dental forceps", "code": "42151623" },
      { "name": "Dental hand pieces or accessories", "code": "42151624" },
      { "name": "Dental hygiene instruments", "code": "42151625" },
      {
        "name": "Dental instrument sharpening accessories",
        "code": "42151626"
      },
      { "name": "Dental mirrors or mirror handles", "code": "42151627" },
      { "name": "Dental mixing slabs", "code": "42151628" },
      { "name": "Dental organizers", "code": "42151629" },
      { "name": "Dental placement instruments", "code": "42151630" },
      { "name": "Dental probes", "code": "42151631" },
      { "name": "Dental reamers", "code": "42151632" },
      {
        "name": "Dental retraction cord packing instruments",
        "code": "42151633"
      },
      { "name": "Dental root tip picks", "code": "42151634" },
      {
        "name": "Dental saliva ejectors or oral suction devices or supplies",
        "code": "42151635"
      },
      { "name": "Dental scalers or accessories", "code": "42151636" },
      { "name": "Dental scales", "code": "42151637" },
      { "name": "Dental scissors", "code": "42151638" },
      { "name": "Dental spatulas", "code": "42151639" },
      { "name": "Dental tweezers", "code": "42151640" },
      { "name": "Dental wax carvers", "code": "42151641" },
      { "name": "Gingivectomy knives", "code": "42151642" },
      { "name": "Dental margin trimmers", "code": "42151643" },
      { "name": "Dental mouth props", "code": "42151644" },
      {
        "name": "Preassembled reusable dental operative kits or trays",
        "code": "42151645"
      },
      { "name": "Protective devices for teeth", "code": "42151646" },
      { "name": "Dental absorbent holders", "code": "42151647" },
      { "name": "Dental calipers", "code": "42151648" },
      { "name": "Dental fracture detecting tools", "code": "42151650" },
      { "name": "Dental tooth separators", "code": "42151651" },
      { "name": "Dental pin drivers", "code": "42151652" },
      { "name": "Dental pin benders", "code": "42151653" },
      { "name": "Dental guides", "code": "42151654" },
      { "name": "Dental pulp or vitality testers", "code": "42151655" },
      { "name": "Dental spreaders", "code": "42151656" },
      { "name": "Dental tubings or accessories", "code": "42151657" },
      { "name": "Dental floss threaders", "code": "42151658" },
      { "name": "Ripping tooth parts kit", "code": "42151659" },
      { "name": "Dental applicators or absorbents", "code": "42151660" },
      { "name": "Dental instrument cases or bags", "code": "42151661" },
      { "name": "Dental anesthesia electrodes or refills", "code": "42151662" },
      { "name": "Dental wedges or sets", "code": "42151663" },
      { "name": "Dental cutting or separating discs", "code": "42151664" },
      { "name": "Dental gages or accessories", "code": "42151665" },
      { "name": "Dental buccal holders", "code": "42151666" },
      { "name": "Dental matrices or sets", "code": "42151667" },
      {
        "name": "Instrument rolls for dental instruments or accessories",
        "code": "42151668"
      },
      { "name": "Dental dehydrators", "code": "42151669" },
      { "name": "Dental heat carriers", "code": "42151670" },
      {
        "name": "Refills or refill kits for dental post systems",
        "code": "42151671"
      },
      { "name": "Dental expanders", "code": "42151672" },
      { "name": "Dental mallets", "code": "42151673" },
      { "name": "Dental bur holders", "code": "42151674" },
      { "name": "Dental restorative kits", "code": "42151675" },
      { "name": "Dental knives", "code": "42151676" },
      { "name": "Dental pressure indicating kits", "code": "42151677" },
      { "name": "Holders for mixing pads or slabs", "code": "42151678" },
      { "name": "Dental mixing bags", "code": "42151679" },
      { "name": "Endodontic stops or accessories", "code": "42151680" },
      { "name": "Dental anesthesia sets or accessories", "code": "42151681" },
      { "name": "Endodontic broach", "code": "42151682" },
      { "name": "Facebow", "code": "42151683" },
      { "name": "Dental clinical furniture", "code": "42151700" },
      {
        "name": "Dental examination chairs or related parts or accessories",
        "code": "42151701"
      },
      { "name": "Dental stools", "code": "42151702" },
      { "name": "Dental cabinets", "code": "42151703" },
      { "name": "Dental tables or accessories", "code": "42151704" },
      {
        "name": "Combination furniture sets for dental procedures",
        "code": "42151705"
      },
      {
        "name": "Dental fillers and finishing and polishing supplies",
        "code": "42151800"
      },
      { "name": "Amalgam carriers", "code": "42151801" },
      {
        "name": "Dental material pluggers or tips or accessories",
        "code": "42151802"
      },
      { "name": "Dental amalgam capsules", "code": "42151803" },
      { "name": "Dental cord packers", "code": "42151804" },
      { "name": "Dental finishing or polishing discs", "code": "42151805" },
      { "name": "Dental finishing or polishing strips", "code": "42151806" },
      { "name": "Dental finishing or polishing tips", "code": "42151807" },
      { "name": "Dental burnishing compounds", "code": "42151808" },
      { "name": "Dental squeeze cloths", "code": "42151809" },
      { "name": "Dental finishing or polishing kits", "code": "42151810" },
      { "name": "Dental polishing cups or sets", "code": "42151811" },
      {
        "name": "Springs for dental grinding or polishing machines",
        "code": "42151812"
      },
      { "name": "Dental filling tubes", "code": "42151813" },
      {
        "name": "Chuck adapters for dental grinding or polishing machines",
        "code": "42151814"
      },
      {
        "name": "Cones for dental grinding or polishing machines",
        "code": "42151815"
      },
      { "name": "Gold foil carriers", "code": "42151816" },
      {
        "name": "Dental hygiene and preventive care equipment and supplies",
        "code": "42151900"
      },
      { "name": "Dental plate or denture brushes", "code": "42151901" },
      { "name": "Dental prophylaxis kits", "code": "42151902" },
      { "name": "Denture cups or containers", "code": "42151903" },
      { "name": "Disclosing solutions or tablets", "code": "42151904" },
      { "name": "Fluoride gels or rinses", "code": "42151905" },
      { "name": "Fluoride tablets or drops", "code": "42151906" },
      { "name": "Dental prophylaxis tanks", "code": "42151907" },
      { "name": "Denture flasks or sets", "code": "42151908" },
      { "name": "Preventive dentistry pastes or kits", "code": "42151909" },
      { "name": "Teeth cleaning devices or accessories", "code": "42151910" },
      { "name": "Dental tissue conditioner sets", "code": "42151911" },
      { "name": "Endodontic sprays", "code": "42151912" },
      { "name": "Dental imaging equipment and supplies", "code": "42152000" },
      { "name": "Bite wing holders", "code": "42152001" },
      { "name": "Dental bite blocks or wings or tabs", "code": "42152002" },
      { "name": "Dental film processors", "code": "42152003" },
      { "name": "Dental radiology film hangers", "code": "42152004" },
      { "name": "Dental radiology film holders", "code": "42152005" },
      { "name": "Dental radiology film mounts", "code": "42152006" },
      { "name": "Dental x ray duplicators", "code": "42152007" },
      { "name": "Dental x ray units", "code": "42152008" },
      { "name": "Dental x ray viewers or accessories", "code": "42152009" },
      { "name": "Dental radiology film", "code": "42152010" },
      { "name": "Dental x ray film adapters", "code": "42152011" },
      {
        "name": "Dental x ray apparatus parts or kits or accessories",
        "code": "42152012"
      },
      { "name": "Dental radiography film analyzers", "code": "42152013" },
      { "name": "Dental radioactive tracers", "code": "42152014" },
      {
        "name": "Dental impression and forming equipment and supplies",
        "code": "42152100"
      },
      {
        "name": "Dental casting rings or related supplies",
        "code": "42152101"
      },
      { "name": "Dental formers", "code": "42152102" },
      { "name": "Dental impression tray adhesives", "code": "42152103" },
      { "name": "Dental impression tray cleaners", "code": "42152104" },
      { "name": "Dental impression trays", "code": "42152105" },
      { "name": "Dental plaster knives", "code": "42152106" },
      { "name": "Dental waxing instruments", "code": "42152107" },
      {
        "name": "Dental impression material syringes or accessories",
        "code": "42152108"
      },
      {
        "name": "Dental impression material water baths or accessories",
        "code": "42152109"
      },
      { "name": "Dental marking devices", "code": "42152110" },
      { "name": "Dental impression material hardeners", "code": "42152111" },
      {
        "name": "Dental drying instruments or accessories",
        "code": "42152112"
      },
      { "name": "Denture base forming kits", "code": "42152113" },
      {
        "name": "Dental impression materials delivery kits",
        "code": "42152114"
      },
      { "name": "Dental impression baskets", "code": "42152115" },
      {
        "name": "Dental laboratory and sterilization equipment and supplies",
        "code": "42152200"
      },
      { "name": "Dental foils", "code": "42152201" },
      { "name": "Dental laboratory air abrasion units", "code": "42152202" },
      { "name": "Dental laboratory burners or torches", "code": "42152203" },
      {
        "name": "Dental laboratory casting machines or its parts or accessories",
        "code": "42152204"
      },
      { "name": "Dental laboratory curing units", "code": "42152205" },
      { "name": "Dental laboratory dies", "code": "42152206" },
      { "name": "Dental laboratory dust collectors", "code": "42152207" },
      {
        "name": "Dental laboratory engines or accessories",
        "code": "42152208"
      },
      { "name": "Dental laboratory furnaces", "code": "42152209" },
      {
        "name": "Dental laboratory gold platers or supplies",
        "code": "42152210"
      },
      { "name": "Dental laboratory lathes or accessories", "code": "42152211" },
      {
        "name": "Dental laboratory model trimmers or accessories",
        "code": "42152212"
      },
      { "name": "Dental laboratory models", "code": "42152213" },
      { "name": "Dental laboratory plaster traps", "code": "42152214" },
      {
        "name": "Dental laboratory sandblasters or supplies",
        "code": "42152215"
      },
      {
        "name": "Dental laboratory soldering machines or supplies",
        "code": "42152216"
      },
      {
        "name": "Dental laboratory vacuum units or supplies",
        "code": "42152217"
      },
      { "name": "Dental laboratory vibrators", "code": "42152218" },
      { "name": "Dental laboratory waxing units", "code": "42152219" },
      { "name": "Dental stones", "code": "42152220" },
      {
        "name": "Dental laboratory model protectors or cases",
        "code": "42152221"
      },
      { "name": "Crucibles for dental casting machines", "code": "42152222" },
      {
        "name": "Dental resins processing units or accessories",
        "code": "42152223"
      },
      { "name": "Dental laboratory flasks", "code": "42152224" },
      {
        "name": "Dental lasers and illumination and fiber optic equipment and supplies",
        "code": "42152300"
      },
      { "name": "Dental lasers", "code": "42152301" },
      { "name": "Dentoscopes", "code": "42152302" },
      {
        "name": "General dental lights or its accessories",
        "code": "42152303"
      },
      { "name": "Intraoral lights", "code": "42152304" },
      { "name": "Tripods for dental operating lights", "code": "42152305" },
      {
        "name": "Dental fiber optic lights or accessories",
        "code": "42152306"
      },
      { "name": "Dental operating illumination sets", "code": "42152307" },
      { "name": "Dental materials", "code": "42152400" },
      { "name": "Alloys for dental amalgam", "code": "42152401" },
      { "name": "Athletic mouth protector materials", "code": "42152402" },
      {
        "name": "Cast cobalt chromium molybdenum alloys for dental implants",
        "code": "42152403"
      },
      {
        "name": "Combined reversible irreversible hydrocolloid impression materials",
        "code": "42152404"
      },
      { "name": "Crown or bridge plastics", "code": "42152405" },
      { "name": "Dental abrasive pastes", "code": "42152406" },
      { "name": "Dental abrasive powders", "code": "42152407" },
      { "name": "Dental absorbent points", "code": "42152408" },
      { "name": "Dental agar impression materials", "code": "42152409" },
      { "name": "Dental alginate impression materials", "code": "42152410" },
      { "name": "Dental base metal casting alloys", "code": "42152411" },
      { "name": "Dental baseplate waxes", "code": "42152412" },
      { "name": "Dental brazing alloys", "code": "42152413" },
      { "name": "Dental casting alloys", "code": "42152414" },
      { "name": "Dental ceramics", "code": "42152415" },
      { "name": "Dental duplicating materials", "code": "42152416" },
      { "name": "Dental ethyl silicate investments", "code": "42152417" },
      { "name": "Dental gypsum products", "code": "42152418" },
      {
        "name": "Dental impression paste zinc oxide eugenol materials",
        "code": "42152419"
      },
      { "name": "Dental inlay casting waxes", "code": "42152420" },
      { "name": "Dental mercury", "code": "42152421" },
      { "name": "Dental obturating points", "code": "42152422" },
      { "name": "Dental pit or fissure sealants", "code": "42152423" },
      { "name": "Dental water based cements", "code": "42152424" },
      { "name": "Denture base resins", "code": "42152425" },
      { "name": "Denture base temporary relining resins", "code": "42152426" },
      { "name": "Denture cold curing repair resins", "code": "42152427" },
      { "name": "Direct filling resins", "code": "42152428" },
      { "name": "Elastomeric dental impression materials", "code": "42152429" },
      { "name": "Endodontic filling materials", "code": "42152430" },
      {
        "name": "Extraoral maxillofacial prosthesis elastomers",
        "code": "42152431"
      },
      {
        "name": "Gypsum bonded casting Investments for dental gold alloys",
        "code": "42152432"
      },
      { "name": "Intraoral dental radiographic film", "code": "42152433" },
      { "name": "Dental amalgamators", "code": "42152434" },
      { "name": "Orthodontic elastomeric materials", "code": "42152435" },
      { "name": "Phosphate bonded investments", "code": "42152436" },
      { "name": "Porcelain teeth", "code": "42152437" },
      { "name": "Refractory die materials", "code": "42152438" },
      {
        "name": "Resilient lining materials for removable dentures",
        "code": "42152439"
      },
      { "name": "Soldering investments", "code": "42152440" },
      { "name": "Synthetic polymer teeth", "code": "42152441" },
      { "name": "Unalloyed titanium for dental implants", "code": "42152442" },
      {
        "name": "Zinc oxide eugenol and non eugenol cements",
        "code": "42152443"
      },
      { "name": "Gingival retraction liquid", "code": "42152444" },
      { "name": "Tooth facing or backing sets", "code": "42152445" },
      {
        "name": "Dental cavities lining or thinner materials or sets",
        "code": "42152446"
      },
      { "name": "Dental stains or supplies", "code": "42152447" },
      { "name": "Dental impression material catalysts", "code": "42152449" },
      { "name": "Dental instrument cleaning compounds", "code": "42152450" },
      { "name": "Dental pressure indicating compounds", "code": "42152451" },
      {
        "name": "Dental pastes insulating kits or accessories",
        "code": "42152452"
      },
      { "name": "Dental restorative compounds", "code": "42152453" },
      { "name": "Dental pulp protectors", "code": "42152454" },
      {
        "name": "Stands or holders for dental abrasive products",
        "code": "42152455"
      },
      { "name": "Dental masking agents", "code": "42152456" },
      { "name": "Dental cementing kits", "code": "42152457" },
      { "name": "Dental die spacers", "code": "42152458" },
      { "name": "Endodontic post kits", "code": "42152459" },
      { "name": "Denture coating compounds", "code": "42152460" },
      { "name": "Coating compounds for dental models", "code": "42152461" },
      { "name": "Denture reliner kits", "code": "42152462" },
      { "name": "Dental wax solvents", "code": "42152463" },
      { "name": "Kits for dental implants", "code": "42152464" },
      { "name": "Dental lubricants", "code": "42152465" },
      { "name": "Material fit checker", "code": "42152466" },
      {
        "name": "Electrically operated alginate dental impression material mixer",
        "code": "42152468"
      },
      {
        "name": "Electrically operated rubber based dental impression material mixer",
        "code": "42152469"
      },
      { "name": "Resin for orthodontic appliance", "code": "42152470" },
      { "name": "General dental supplies", "code": "42152500" },
      {
        "name": "Dental preassembled disposable kits or trays",
        "code": "42152501"
      },
      { "name": "Dental bibs", "code": "42152502" },
      { "name": "Dental dam supplies", "code": "42152503" },
      { "name": "Dental dressings", "code": "42152504" },
      {
        "name": "Dental examination chair headrest covers",
        "code": "42152505"
      },
      { "name": "Dental absorbent pellets", "code": "42152506" },
      { "name": "Dental rolls", "code": "42152507" },
      {
        "name": "Dental syringes or needles or syringes with needles",
        "code": "42152508"
      },
      { "name": "Disposable dental tray covers", "code": "42152509" },
      {
        "name": "Cases or covers for dental instruments or sets",
        "code": "42152510"
      },
      { "name": "Dental dishes", "code": "42152511" },
      { "name": "Dental tongs", "code": "42152512" },
      { "name": "Dental measuring cups", "code": "42152513" },
      { "name": "Dental waste containers or accessories", "code": "42152514" },
      { "name": "Dental mixing bowls", "code": "42152516" },
      { "name": "Dental napkin holders or dispensers", "code": "42152517" },
      { "name": "Dental materials dispensers", "code": "42152518" },
      { "name": "Dental syringe accessory kits", "code": "42152519" },
      { "name": "Dental cuspidors", "code": "42152520" },
      { "name": "Pantograph", "code": "42152521" },
      { "name": "Dental operatory specific supplies", "code": "42152600" },
      {
        "name": "Dental operatory articulating papers or related products",
        "code": "42152601"
      },
      {
        "name": "Dental operatory infection control barriers",
        "code": "42152602"
      },
      {
        "name": "Dental operatory retention pins or related products",
        "code": "42152603"
      },
      { "name": "Dental operatory retraction cords", "code": "42152604" },
      { "name": "Dental sialography sets", "code": "42152605" },
      { "name": "Dental splints or sets", "code": "42152606" },
      { "name": "Dental instruments rings", "code": "42152607" },
      { "name": "Orthodontic ligature cartridges", "code": "42152608" },
      {
        "name": "Orthodontic and prosthodontic equipment and supplies",
        "code": "42152700"
      },
      { "name": "Dental articulators or accessories", "code": "42152701" },
      { "name": "Dental hemostatic solutions", "code": "42152702" },
      {
        "name": "Dental pins or posts or related supplies",
        "code": "42152703"
      },
      { "name": "Dental retainers", "code": "42152704" },
      { "name": "Orthodontic appliance clasps", "code": "42152705" },
      { "name": "Orthodontic brackets", "code": "42152706" },
      { "name": "Orthodontic buccal tubes", "code": "42152707" },
      { "name": "Orthodontic coil springs", "code": "42152708" },
      { "name": "Orthodontic elastics", "code": "42152709" },
      { "name": "Orthodontic elastomerics", "code": "42152710" },
      { "name": "Orthodontic expansion screws", "code": "42152711" },
      { "name": "Orthodontic molar bands", "code": "42152712" },
      { "name": "Orthodontic pliers", "code": "42152713" },
      { "name": "Orthodontic retainer cases", "code": "42152714" },
      { "name": "Orthodontic setter bands", "code": "42152715" },
      { "name": "Orthodontic stops or locks", "code": "42152716" },
      { "name": "Orthodontic wire", "code": "42152717" },
      { "name": "Orthodontic archwire", "code": "42152718" },
      { "name": "Periodontal equipment and supplies", "code": "42152800" },
      { "name": "Periodontal chisels", "code": "42152801" },
      { "name": "Periodontal files", "code": "42152802" },
      { "name": "Periodontal hoes", "code": "42152803" },
      {
        "name": "Periodontal implant to bone interface testing instruments",
        "code": "42152804"
      },
      { "name": "Periodontium control instruments", "code": "42152805" },
      { "name": "Periodontal tissue dissectors", "code": "42152806" },
      { "name": "Periodontal protective pastes", "code": "42152807" },
      { "name": "Periodontal curettes", "code": "42152808" },
      { "name": "Periodontal knives", "code": "42152809" },
      { "name": "Periodontal membranes", "code": "42152810" },
      { "name": "Dialysis equipment and supplies", "code": "42160000" },
      {
        "name": "Peritoneal and equilibrium dialysis equipment and supplies",
        "code": "42161500"
      },
      {
        "name": "Continuous ambulatory peritoneal dialysis CAPD transfer sets",
        "code": "42161501"
      },
      { "name": "Peritoneal dialysate warmers", "code": "42161502" },
      {
        "name": "Peritoneal dialysis administration or catheterization sets",
        "code": "42161503"
      },
      {
        "name": "Peritoneal dialysis catheter adapters or clamps or connectors",
        "code": "42161504"
      },
      {
        "name": "Peritoneal dialysis drainage bags or containers",
        "code": "42161505"
      },
      {
        "name": "Peritoneal dialysis shunts or catheters or indwelling access devices",
        "code": "42161506"
      },
      { "name": "Peritoneal dialysis solutions", "code": "42161507" },
      {
        "name": "Peritoneal dialysis unit straps or harnesses",
        "code": "42161508"
      },
      { "name": "Peritoneal dialysis units", "code": "42161509" },
      { "name": "Peritoneal lavage kits", "code": "42161510" },
      {
        "name": "Extracorporeal hemodialysis equipment and supplies",
        "code": "42161600"
      },
      { "name": "Hemodialysis administration kit or set", "code": "42161601" },
      { "name": "Hemodialysis blood bag samplers", "code": "42161602" },
      {
        "name": "Hemodialysis blood oxygen demand apparatus",
        "code": "42161603"
      },
      { "name": "Hemodialysis chairs", "code": "42161604" },
      {
        "name": "Hemodialysis dialysate conductivity meters",
        "code": "42161605"
      },
      { "name": "Hemodialysis dialysate level detectors", "code": "42161606" },
      { "name": "Hemodialysis dialysate pressure pumps", "code": "42161607" },
      { "name": "Hemodialysis dialysate solutions", "code": "42161608" },
      { "name": "Hemodialysis dialysate tanks", "code": "42161609" },
      { "name": "Hemodialysis dialysate tubing", "code": "42161610" },
      { "name": "Hemodialysis dialysate warming baths", "code": "42161611" },
      { "name": "Hemodialysis dialyzer celluloid filters", "code": "42161612" },
      { "name": "Hemodialysis dialyzer collodion filters", "code": "42161613" },
      {
        "name": "Hemodialysis dialyzer hollow filament filters",
        "code": "42161614"
      },
      {
        "name": "Hemodialysis dialyzer reprocessing systems",
        "code": "42161615"
      },
      {
        "name": "Hemodialysis unit air bubble or foam or clot detectors or traps or alarms",
        "code": "42161616"
      },
      {
        "name": "Hemodialysis unit arterial pressure monitors",
        "code": "42161617"
      },
      { "name": "Hemodialysis unit blood line clamps", "code": "42161618" },
      { "name": "Hemodialysis unit blood port caps", "code": "42161619" },
      { "name": "Hemodialysis unit blood pumps", "code": "42161620" },
      { "name": "Hemodialysis unit dialysate port caps", "code": "42161621" },
      {
        "name": "Hemodialysis unit disinfectants or cleansers",
        "code": "42161622"
      },
      {
        "name": "Hemodialysis unit heparin infusion pumps",
        "code": "42161623"
      },
      {
        "name": "Hemodialysis unit single needle controllers",
        "code": "42161624"
      },
      {
        "name": "Hemodialysis unit single pass converters",
        "code": "42161625"
      },
      {
        "name": "Hemodialysis unit stands or supports or carts",
        "code": "42161626"
      },
      { "name": "Hemodialysis unit temperature monitors", "code": "42161627" },
      { "name": "Hemodialysis unit test equipment", "code": "42161628" },
      { "name": "Hemodialysis unit transducer filters", "code": "42161629" },
      { "name": "Hemodialysis unit transducer protectors", "code": "42161630" },
      {
        "name": "Hemodialysis unit water purification systems",
        "code": "42161631"
      },
      { "name": "Hemodialysis units", "code": "42161632" },
      {
        "name": "Hemodialysis apparatus dialyzer membranes",
        "code": "42161633"
      },
      {
        "name": "Hemodialysis procedure trays or accessories",
        "code": "42161634"
      },
      { "name": "Hemodialysis apparatus cartridges", "code": "42161635" },
      { "name": "Hemofiltration equipment and supplies", "code": "42161700" },
      { "name": "Hemofilter", "code": "42161701" },
      { "name": "Hemofiltrate collection bags", "code": "42161702" },
      { "name": "Hemofiltration infusion ports", "code": "42161703" },
      { "name": "Hemofiltration sampling ports", "code": "42161704" },
      {
        "name": "Continuous renal replacement therapy CRRT equipment and supplies",
        "code": "42161800"
      },
      {
        "name": "Continuous arteriovenous dialysis CAVHD units or related products",
        "code": "42161801"
      },
      {
        "name": "Continuous arteriovenous hemofiltration CAVH units or related products",
        "code": "42161802"
      },
      {
        "name": "Continuous venovenous hemofiltration or hemodialysis units or related products",
        "code": "42161803"
      },
      {
        "name": "Slow continuous ultrafiltration SCUF units or related products",
        "code": "42161804"
      },
      {
        "name": "Therapeutic apheresis equipment and supplies",
        "code": "42161900"
      },
      { "name": "Therapeutic apheresis procedure kit", "code": "42161901" },
      {
        "name": "Emergency and field medical services products",
        "code": "42170000"
      },
      {
        "name": "Emergency medical services disaster management products",
        "code": "42171500"
      },
      {
        "name": "Emergency medical services disaster body pouches",
        "code": "42171501"
      },
      { "name": "Emergency medical services triage tags", "code": "42171502" },
      {
        "name": "Emergency medical services extricating and immobilizing and transporting products",
        "code": "42171600"
      },
      {
        "name": "Air evacuation stretchers or accessories",
        "code": "42171601"
      },
      { "name": "Ambulance cots or accessories", "code": "42171602" },
      { "name": "Anti shock garments", "code": "42171603" },
      { "name": "Basket stretchers or accessories", "code": "42171604" },
      { "name": "Cinch rescue loops", "code": "42171605" },
      { "name": "Emergency medical services air splints", "code": "42171606" },
      {
        "name": "Emergency medical services cervical or extrication collars",
        "code": "42171607"
      },
      {
        "name": "Emergency medical services head immobilizers",
        "code": "42171608"
      },
      {
        "name": "Emergency medical services restraint or spine board straps",
        "code": "42171609"
      },
      {
        "name": "Emergency medical services torso immobilizers",
        "code": "42171610"
      },
      {
        "name": "Emergency response litters or stretchers or accessories",
        "code": "42171611"
      },
      { "name": "Scoop stretchers or accessories", "code": "42171612" },
      { "name": "Spine boards", "code": "42171613" },
      { "name": "Water rescue tubes or cans", "code": "42171614" },
      { "name": "Emergency medical services blankets", "code": "42171700" },
      { "name": "Emergency or rescue blankets", "code": "42171701" },
      { "name": "First aid blankets", "code": "42171702" },
      {
        "name": "Emergency medical services heat shielding wraps or blankets",
        "code": "42171703"
      },
      {
        "name": "Emergency medical services infant swaddlers or buntings",
        "code": "42171704"
      },
      {
        "name": "Emergency medical services airway management equipment",
        "code": "42171800"
      },
      {
        "name": "Emergency medical services oropharyngeal airway or intubation kits",
        "code": "42171801"
      },
      {
        "name": "Emergency medical services laryngoscope kits",
        "code": "42171802"
      },
      {
        "name": "Emergency medical services suction units or accessories",
        "code": "42171803"
      },
      {
        "name": "Emergency medical services tracheal tube or cricothyrotomy kits",
        "code": "42171804"
      },
      { "name": "Jaw spreaders", "code": "42171805" },
      { "name": "Seizure sticks", "code": "42171806" },
      {
        "name": "Emergency medical services storage cases and bags",
        "code": "42171900"
      },
      {
        "name": "Emergency medical services airway management bags",
        "code": "42171901"
      },
      {
        "name": "Emergency medical services defibrillator cases",
        "code": "42171902"
      },
      { "name": "Emergency medical services drug cases", "code": "42171903" },
      {
        "name": "Emergency medical services extrication products cases or bags",
        "code": "42171904"
      },
      {
        "name": "Emergency medical services intravenous IV cases",
        "code": "42171905"
      },
      {
        "name": "Emergency medical services intubation cases",
        "code": "42171906"
      },
      {
        "name": "Emergency medical services laryngoscope bags",
        "code": "42171907"
      },
      {
        "name": "Emergency medical services life support cases",
        "code": "42171908"
      },
      {
        "name": "Emergency medical services long distance response LDR trauma packs",
        "code": "42171909"
      },
      {
        "name": "Emergency medical services paramedic cases",
        "code": "42171910"
      },
      {
        "name": "Emergency medical services portable oxygen or resuscitation cases",
        "code": "42171911"
      },
      {
        "name": "Emergency medical services rescue bags or cases",
        "code": "42171912"
      },
      {
        "name": "Emergency medical services response cases or bags",
        "code": "42171913"
      },
      {
        "name": "Emergency medical services trauma cases or bags",
        "code": "42171914"
      },
      {
        "name": "Emergency medical technician EMT cases or bags",
        "code": "42171915"
      },
      {
        "name": "Emergency medical services immobilization set carrying or storage cases",
        "code": "42171916"
      },
      {
        "name": "Emergency medical services first aid kit cases or bags or accessories",
        "code": "42171917"
      },
      {
        "name": "Emergency medical services gas casualty treatment set cases",
        "code": "42171918"
      },
      {
        "name": "Emergency medical services evacuation bags or liners",
        "code": "42171919"
      },
      {
        "name": "Emergency medical services clothing cases or accessories",
        "code": "42171920"
      },
      {
        "name": "Emergency and field medical services kits",
        "code": "42172000"
      },
      {
        "name": "Emergency medical services first aid kits",
        "code": "42172001"
      },
      {
        "name": "Emergency medical services first response kits",
        "code": "42172002"
      },
      {
        "name": "Emergency medical service intravenous IV kit",
        "code": "42172003"
      },
      {
        "name": "Emergency medical services life support kits",
        "code": "42172004"
      },
      {
        "name": "Emergency medical services long distance response LDR trauma kits",
        "code": "42172005"
      },
      {
        "name": "Emergency medical services obstetrics kits",
        "code": "42172006"
      },
      {
        "name": "Emergency medical services oxygen or resuscitation kits",
        "code": "42172007"
      },
      { "name": "Emergency medical services rappel kits", "code": "42172008" },
      {
        "name": "Emergency medical services search and rescue kits",
        "code": "42172009"
      },
      { "name": "Emergency medical services trauma kits", "code": "42172010" },
      { "name": "Emergency medical technician EMT kits", "code": "42172011" },
      {
        "name": "Emergency medical services ventriculostomy kits",
        "code": "42172012"
      },
      {
        "name": "Emergency medical services evacuation kits",
        "code": "42172013"
      },
      {
        "name": "Emergency medical services patient transport kits or supplies",
        "code": "42172014"
      },
      {
        "name": "Emergency medical services dental treatment kits",
        "code": "42172015"
      },
      {
        "name": "Emergency medical services fracture kits",
        "code": "42172016"
      },
      {
        "name": "Field medical or laboratory medical equipment kits or related products",
        "code": "42172017"
      },
      { "name": "Gastric lavage kit", "code": "42172018" },
      {
        "name": "Emergency medical services resuscitation products",
        "code": "42172100"
      },
      {
        "name": "Automated external defibrillators AED or hard paddles",
        "code": "42172101"
      },
      {
        "name": "Cardiopulmonary resuscitation CPR protective shields or masks",
        "code": "42172102"
      },
      {
        "name": "Emergency resuscitator or aspirator kits",
        "code": "42172103"
      },
      {
        "name": "Cardio pulmonary resuscitation CPR boards",
        "code": "42172104"
      },
      {
        "name": "Automated external defibrillator AED accessories",
        "code": "42172105"
      },
      { "name": "Emergency medical services supplies", "code": "42172200" },
      {
        "name": "Emergency medical services tourniquets or clamps",
        "code": "42172201"
      },
      { "name": "Patient exam and monitoring products", "code": "42180000" },
      {
        "name": "Diagnostic assessment and exam products for general use",
        "code": "42181500"
      },
      { "name": "Tongue depressors or blades or sticks", "code": "42181501" },
      { "name": "Medical exam transilluminators", "code": "42181502" },
      { "name": "Exam or personal lubricants or jellies", "code": "42181503" },
      { "name": "Hemacytometer sets", "code": "42181504" },
      {
        "name": "Endometrial cell sampler sets or accessories",
        "code": "42181505"
      },
      { "name": "Sexual assault determination kits", "code": "42181506" },
      {
        "name": "Medical examining mirror headbands or accessories",
        "code": "42181507"
      },
      { "name": "Cleaning wipes for diagnostic equipment", "code": "42181508" },
      {
        "name": "Flight surgeon physical examination sets",
        "code": "42181509"
      },
      { "name": "Transcutaneous jaundice meters", "code": "42181510" },
      { "name": "Male impotence gages", "code": "42181511" },
      { "name": "Typhoid carrier examination spoons", "code": "42181512" },
      { "name": "Sinus irrigation kits", "code": "42181513" },
      { "name": "Hemoglobin photometers", "code": "42181514" },
      { "name": "Exam procedure trays", "code": "42181515" },
      {
        "name": "Electromyography EMG units or accessories",
        "code": "42181516"
      },
      {
        "name": "Case for medical instrument set or its accessories",
        "code": "42181518"
      },
      { "name": "Drop carrier", "code": "42181519" },
      { "name": "Intrathoracic pressure meter", "code": "42181520" },
      { "name": "Spinal fluid pressure monitor", "code": "42181521" },
      { "name": "Apparatus for gait analysis", "code": "42181522" },
      {
        "name": "Isokinetic testing and evaluation system",
        "code": "42181523"
      },
      { "name": "Rigidity analyzer", "code": "42181524" },
      { "name": "Ataxiagraph", "code": "42181525" },
      { "name": "Algesimeter", "code": "42181526" },
      { "name": "Medical dynamometer", "code": "42181527" },
      { "name": "Skin resistance measuring device", "code": "42181528" },
      { "name": "Esthesiometer", "code": "42181529" },
      { "name": "Iontophoresis sweat induction system", "code": "42181530" },
      {
        "name": "Skin electrical conductivity measuring instrument",
        "code": "42181531"
      },
      { "name": "Galvanic skin response measurement", "code": "42181532" },
      {
        "name": "Nerve conduction velocity measurement device",
        "code": "42181533"
      },
      { "name": "Electroglottograph", "code": "42181534" },
      {
        "name": "Physiological electronic signal conditioner",
        "code": "42181535"
      },
      { "name": "Caries electrical detection device", "code": "42181536" },
      { "name": "Dental bite force measuring instrument", "code": "42181537" },
      { "name": "Dental occlusal sound measuring device", "code": "42181538" },
      {
        "name": "Blood pressure units and related products",
        "code": "42181600"
      },
      { "name": "Aneroid blood pressure units", "code": "42181601" },
      { "name": "Electronic blood pressure units", "code": "42181602" },
      { "name": "Mercury blood pressure units", "code": "42181603" },
      {
        "name": "Blood pressure air release valves or inflation bulbs",
        "code": "42181604"
      },
      { "name": "Blood pressure cuff or bladder", "code": "42181605" },
      {
        "name": "Blood pressure inflation hoses or pneumatic hoses or adapters",
        "code": "42181606"
      },
      { "name": "Blood pressure recording units", "code": "42181607" },
      {
        "name": "Blood pressure measuring instrument accessories",
        "code": "42181608"
      },
      { "name": "Blood pressure monitor dome kits", "code": "42181609" },
      { "name": "Blood pressure cuff kits", "code": "42181610" },
      { "name": "Central venous pressure CVP manometer", "code": "42181611" },
      { "name": "Blood pressure mobile stand", "code": "42181612" },
      {
        "name": "Electrocardiography EKG units and related products",
        "code": "42181700"
      },
      { "name": "Electrocardiography EKG units", "code": "42181701" },
      {
        "name": "Electrocardiography EKG adapters or cables or leads",
        "code": "42181702"
      },
      {
        "name": "Electrocardiography EKG cable or lead or unit testers",
        "code": "42181703"
      },
      {
        "name": "Electrocardiography EKG calipers or rulers",
        "code": "42181704"
      },
      {
        "name": "Electrocardiography EKG graphic recorders",
        "code": "42181705"
      },
      { "name": "Electrocardiography EKG monitor screens", "code": "42181706" },
      {
        "name": "Electrocardiography EKG neonatal strap or ring electrodes",
        "code": "42181707"
      },
      { "name": "Electrocardiography EKG electrode patch", "code": "42181708" },
      {
        "name": "Electrocardiography EKG surface electrode testers",
        "code": "42181710"
      },
      {
        "name": "Electrocardiography EKG telephone transmitter or receiver systems",
        "code": "42181711"
      },
      { "name": "Electrocardiography EKG unit analyzers", "code": "42181712" },
      {
        "name": "Long term continuous electrocardiography EKG or holter monitoring systems",
        "code": "42181713"
      },
      {
        "name": "Electrocardiography EKG monitor accessory kits",
        "code": "42181714"
      },
      { "name": "Electrode solutions or creams", "code": "42181715" },
      { "name": "Electrocardiography EKG accessories", "code": "42181716" },
      { "name": "Electrocardiography EKG electrode bulbs", "code": "42181717" },
      { "name": "Electrocardiography EKG recording pens", "code": "42181718" },
      {
        "name": "Electrocardiography EKG transmitter or telemetry or accessories",
        "code": "42181719"
      },
      { "name": "Stress exercise monitoring system", "code": "42181720" },
      { "name": "Sphygmograph", "code": "42181721" },
      { "name": "Pulse wave analyzer", "code": "42181722" },
      { "name": "Pulse oximeters", "code": "42181800" },
      { "name": "Pulse oximeter units", "code": "42181801" },
      { "name": "Pulse oximeter cables", "code": "42181802" },
      { "name": "Pulse oximeter probe or sensor", "code": "42181803" },
      {
        "name": "Pulse oximeter probe or sensor accessories",
        "code": "42181804"
      },
      { "name": "Pulse oximeter unit accessories", "code": "42181805" },
      {
        "name": "Acute care monitoring units and related products",
        "code": "42181900"
      },
      {
        "name": "Acute care fetal or maternal monitoring units or accessories",
        "code": "42181901"
      },
      {
        "name": "Intracranial pressure ICP monitoring units or accessories",
        "code": "42181902"
      },
      {
        "name": "Cardiac output CO monitoring units or accessories",
        "code": "42181903"
      },
      {
        "name": "Multiparameter vital sign unit or accessories",
        "code": "42181904"
      },
      { "name": "Medical transducer monitor cables", "code": "42181905" },
      {
        "name": "Intrauterine pressure monitoring catheters",
        "code": "42181906"
      },
      { "name": "Basal metabolism apparatus", "code": "42181907" },
      {
        "name": "Intracompartmental pressure monitoring sets or accessories",
        "code": "42181908"
      },
      { "name": "Transcervical intrauterine kits", "code": "42181910" },
      { "name": "Fetal scalp electrode", "code": "42181911" },
      { "name": "Fetal electroencephalographic monitor", "code": "42181912" },
      {
        "name": "Scopes and specula and accessories for medical exam diagnostic use",
        "code": "42182000"
      },
      { "name": "Anal or rectal exam specula", "code": "42182001" },
      { "name": "Anoscopes or proctoscopes", "code": "42182002" },
      {
        "name": "Colposcopes or vaginoscopes or accessories",
        "code": "42182003"
      },
      { "name": "Dermatoscopes", "code": "42182004" },
      {
        "name": "Ophthalmoscopes or otoscopes or scope sets",
        "code": "42182005"
      },
      { "name": "Laryngeal or oropharyngial exam specula", "code": "42182006" },
      { "name": "Medical exam scope bulbs or lamps", "code": "42182007" },
      {
        "name": "Medical exam scope handles or handle chargers",
        "code": "42182008"
      },
      {
        "name": "Medical exam scope specula tips or specula tip dispensers",
        "code": "42182009"
      },
      { "name": "Medical exam specula holders or stands", "code": "42182010" },
      {
        "name": "Medical exam specula or dilator tips or tip dispensers",
        "code": "42182011"
      },
      { "name": "Nasal exam specula or dilators", "code": "42182012" },
      { "name": "Vaginal exam specula", "code": "42182013" },
      { "name": "Otoscope or opthalmoscope accessories", "code": "42182014" },
      { "name": "Otoscope speculums", "code": "42182015" },
      { "name": "Nasopharyngoscopes or accessories", "code": "42182016" },
      { "name": "Ear specula sets", "code": "42182017" },
      { "name": "Bronchoscopes or accessories", "code": "42182018" },
      { "name": "Dilator kits", "code": "42182019" },
      { "name": "Angioscopes or accessories", "code": "42182020" },
      { "name": "Stethoscopes and related products", "code": "42182100" },
      { "name": "Electronic stethoscopes or accessories", "code": "42182101" },
      { "name": "Hand held doppler or accessories", "code": "42182102" },
      {
        "name": "Medical acoustic stethoscope or accessory",
        "code": "42182103"
      },
      { "name": "Stethoscope head", "code": "42182104" },
      { "name": "Stethoscope head covers", "code": "42182105" },
      { "name": "Stethoscopic phonocardiographs", "code": "42182106" },
      { "name": "Stethoscope headsets", "code": "42182107" },
      { "name": "Patient thermoregulators", "code": "42182108" },
      { "name": "Medical thermometers and accessories", "code": "42182200" },
      { "name": "Electronic medical thermometers", "code": "42182201" },
      { "name": "Fiberoptic medical thermometers", "code": "42182202" },
      {
        "name": "Medical thermometer carrying cases or covers",
        "code": "42182203"
      },
      { "name": "Medical thermometer racks", "code": "42182204" },
      { "name": "Medical thermometer tip or probe covers", "code": "42182205" },
      { "name": "Mercury medical thermometers", "code": "42182206" },
      {
        "name": "Patient temperature continuous or trend monitors",
        "code": "42182207"
      },
      { "name": "Patient temperature strips", "code": "42182208" },
      { "name": "Thermometer probes", "code": "42182209" },
      { "name": "Non mercury glass medical thermometer", "code": "42182210" },
      { "name": "Infrared thermography system", "code": "42182211" },
      { "name": "Liquid crystal thermometer", "code": "42182212" },
      { "name": "Neurological exam products", "code": "42182300" },
      { "name": "Medical diagnostic pinwheels", "code": "42182301" },
      { "name": "Reflex hammers or mallets", "code": "42182302" },
      { "name": "Neuropsychiatry exam cards", "code": "42182303" },
      { "name": "Psychodiagnostic test sets or kits", "code": "42182304" },
      { "name": "Neurological discriminators", "code": "42182306" },
      { "name": "Neurological pins", "code": "42182307" },
      {
        "name": "Electroencephalograph EEG or accessories",
        "code": "42182308"
      },
      { "name": "Electromyographs", "code": "42182310" },
      { "name": "Neurologic sensor", "code": "42182311" },
      { "name": "Electromyograph electrodes or sets", "code": "42182312" },
      { "name": "Neurological diagnostic sets", "code": "42182313" },
      { "name": "Biofeedback device", "code": "42182314" },
      {
        "name": "Electroencephalographic spectrum analyser",
        "code": "42182315"
      },
      { "name": "Evoked response detector", "code": "42182316" },
      { "name": "Hearing testing products", "code": "42182400" },
      { "name": "Audiometers or accessories", "code": "42182401" },
      {
        "name": "Audiometric bone vibrators or middle ear analyzers",
        "code": "42182402"
      },
      {
        "name": "Audiometric booths or acoustic hearing test chambers",
        "code": "42182403"
      },
      {
        "name": "Auditory function screening unit calibration sets",
        "code": "42182404"
      },
      { "name": "Auditory function screening units", "code": "42182405" },
      { "name": "Auditory test graphic recorders", "code": "42182406" },
      { "name": "Ear fenestrometers", "code": "42182407" },
      { "name": "Electrocochleographs", "code": "42182408" },
      { "name": "Hearing aid analyzers or test systems", "code": "42182409" },
      { "name": "Medical tuning fork cases", "code": "42182410" },
      { "name": "Medical tuning fork hammers", "code": "42182411" },
      { "name": "Medical tuning forks", "code": "42182412" },
      { "name": "Medical tuning fork sets", "code": "42182413" },
      { "name": "Tinnitus analyzers", "code": "42182414" },
      { "name": "Toynbee diagnostic tubes", "code": "42182415" },
      { "name": "Tympanometers or accessories", "code": "42182416" },
      { "name": "Audiometer ribbons", "code": "42182417" },
      { "name": "Fenestrometer guides", "code": "42182418" },
      { "name": "Hearing control apparatus", "code": "42182419" },
      { "name": "Aural probes", "code": "42182420" },
      { "name": "Ear plug gages or accessories", "code": "42182421" },
      { "name": "Ear inflating bags", "code": "42182422" },
      { "name": "Nasal function meters", "code": "42182500" },
      { "name": "Olfactometers", "code": "42182501" },
      { "name": "Nasal flowmeters or rhinoanemometers", "code": "42182502" },
      { "name": "Medical exam lights or lamps", "code": "42182600" },
      {
        "name": "Freestanding medical exam lights or lamps",
        "code": "42182601"
      },
      { "name": "Installed medical exam lights or lamps", "code": "42182602" },
      {
        "name": "Medical exam headlights or headlamps or accessories",
        "code": "42182603"
      },
      { "name": "Medical exam penlights", "code": "42182604" },
      { "name": "Medical exam size measuring devices", "code": "42182700" },
      { "name": "Goniometers", "code": "42182701" },
      { "name": "Medical tape measures", "code": "42182702" },
      { "name": "Patient height rulers", "code": "42182703" },
      { "name": "Skinfold calipers", "code": "42182704" },
      { "name": "Body composition analyzer", "code": "42182705" },
      { "name": "Wound measuring grid or device", "code": "42182706" },
      { "name": "Inclinometer", "code": "42182707" },
      { "name": "Medical weight scales", "code": "42182800" },
      { "name": "Diaper weight scales", "code": "42182801" },
      { "name": "Infant scales", "code": "42182802" },
      {
        "name": "Patient bed or table scales for general use",
        "code": "42182803"
      },
      { "name": "Patient chair scales", "code": "42182804" },
      { "name": "Patient floor scales", "code": "42182805" },
      { "name": "Patient sling scales", "code": "42182806" },
      { "name": "Wheelchair platform scales", "code": "42182807" },
      { "name": "Covers or liners for weighing scales", "code": "42182808" },
      {
        "name": "Specialty exam tables and related products",
        "code": "42182900"
      },
      {
        "name": "Obstetrical or gynecological exam tables",
        "code": "42182901"
      },
      {
        "name": "Obstetrical or gynecological exam table foot stirrups",
        "code": "42182902"
      },
      { "name": "Pediatric examination tables", "code": "42182903" },
      {
        "name": "Pediatric examination table restraint or measurement systems",
        "code": "42182904"
      },
      { "name": "Ophthalmic diagnostic exam products", "code": "42183000" },
      { "name": "Eye charts or vision cards", "code": "42183001" },
      { "name": "Corneal topographers", "code": "42183002" },
      { "name": "Exophthalmometers", "code": "42183003" },
      { "name": "Keratoscopes", "code": "42183004" },
      { "name": "Ophthalmic colorimeters", "code": "42183005" },
      { "name": "Ophthalmic distometers", "code": "42183006" },
      { "name": "Ophthalmic drums or its accessories", "code": "42183007" },
      { "name": "Ophthalmic euthyscopes", "code": "42183008" },
      {
        "name": "Ophthalmic eye test lenses or accessories",
        "code": "42183009"
      },
      { "name": "Ophthalmic lensometers", "code": "42183010" },
      { "name": "Ophthalmic perimeters", "code": "42183011" },
      { "name": "Ophthalmic photometers", "code": "42183012" },
      { "name": "Ophthalmic prisms", "code": "42183013" },
      { "name": "Ophthalmic retinoscopes", "code": "42183014" },
      { "name": "Ophthalmic slit lamps", "code": "42183015" },
      { "name": "Ophthalmic spectrophotometers", "code": "42183016" },
      { "name": "Ophthalmic specula", "code": "42183017" },
      { "name": "Ophthalmic tonometers or accessories", "code": "42183018" },
      { "name": "Ophthalmic transilluminators", "code": "42183019" },
      { "name": "Ophthalmic visual field plotters", "code": "42183020" },
      { "name": "Ophthalmic visual function analyzers", "code": "42183021" },
      { "name": "Ophthalmic visuometers", "code": "42183022" },
      { "name": "Ophthalmometers", "code": "42183023" },
      {
        "name": "Ophthalmic instrument tables or accessories",
        "code": "42183024"
      },
      { "name": "Ophthalmodynamometers", "code": "42183026" },
      {
        "name": "Tangent screen test objects kits or accessories",
        "code": "42183027"
      },
      { "name": "Ophthalmic retinoscope accessories", "code": "42183028" },
      { "name": "Phoropter units", "code": "42183029" },
      { "name": "Eye occluders", "code": "42183030" },
      {
        "name": "Pseudoisochromatic plate sets or accessories",
        "code": "42183031"
      },
      { "name": "Tachistoscopes", "code": "42183032" },
      { "name": "Spectacle fitting sets", "code": "42183033" },
      { "name": "Vision testing stereoscopes", "code": "42183034" },
      { "name": "Combination refractor keratometers", "code": "42183035" },
      { "name": "Opthalmometer base plates", "code": "42183036" },
      { "name": "Chart projectors or accessories", "code": "42183037" },
      { "name": "Ophthalmic instrument pads", "code": "42183038" },
      { "name": "Ophthalmic lens holders", "code": "42183039" },
      { "name": "Opticians tools or accessories", "code": "42183040" },
      { "name": "Color perception testing lanterns", "code": "42183041" },
      { "name": "Depth perception apparatus", "code": "42183042" },
      { "name": "Ophthalmometer bulbs", "code": "42183043" },
      { "name": "Tangent screens", "code": "42183044" },
      { "name": "Electroretinogram systems", "code": "42183045" },
      {
        "name": "Binocular vision test sets or accessories",
        "code": "42183046"
      },
      {
        "name": "Viewing stands for vision acuity testing",
        "code": "42183047"
      },
      { "name": "Ophthalmic child fixation bars", "code": "42183048" },
      { "name": "Fundus camera", "code": "42183049" },
      { "name": "Nystagmograph", "code": "42183050" },
      { "name": "Pupillometer", "code": "42183051" },
      { "name": "Nearpoint ruler", "code": "42183052" },
      { "name": "Interpupillary distance meter", "code": "42183053" },
      { "name": "Ocular plethysmography", "code": "42183054" },
      { "name": "Endothelial cell counter", "code": "42183055" },
      { "name": "Anterior camera", "code": "42183056" },
      { "name": "Eye movement monitor", "code": "42183057" },
      { "name": "Visual field laser instrument", "code": "42183058" },
      { "name": "Photosimulator", "code": "42183059" },
      { "name": "Schirmer strip", "code": "42183060" },
      { "name": "Ocular pressure applicator", "code": "42183061" },
      { "name": "Iris camera", "code": "42183062" },
      { "name": "Ophthalmic electrolysis unit", "code": "42183063" },
      { "name": "Therapeutic contact lense", "code": "42183064" },
      { "name": "Diagnostic contact lense", "code": "42183065" },
      { "name": "Corneal camera", "code": "42183066" },
      { "name": "Ophthalmic diagnostic viewing exam lens", "code": "42183067" },
      { "name": "Taste function meters", "code": "42183100" },
      { "name": "Gustometers", "code": "42183101" },
      {
        "name": "Allergy examining equipment and supplies",
        "code": "42183200"
      },
      {
        "name": "Allergy detecting or testing instruments or accessories",
        "code": "42183201"
      },
      {
        "name": "Ear nose throat ENT examining unit accessories and related products",
        "code": "42183300"
      },
      {
        "name": "Ear nose throat ENT examining mirror accessories",
        "code": "42183301"
      },
      { "name": "Medical facility products", "code": "42190000" },
      {
        "name": "Medical facility materials handling and distribution equipment",
        "code": "42191500"
      },
      { "name": "Clinical pneumatic tube system", "code": "42191501" },
      { "name": "Medicine trays or covers", "code": "42191502" },
      { "name": "Medical facility building systems", "code": "42191600" },
      { "name": "Patient room lighting or accessories", "code": "42191601" },
      { "name": "Operating room lighting or accessories", "code": "42191602" },
      { "name": "Hospital equipment instrument panels", "code": "42191603" },
      { "name": "Clinical monitor arms", "code": "42191604" },
      { "name": "Hospital equipment power columns", "code": "42191605" },
      { "name": "Medical facility ceiling arms", "code": "42191606" },
      {
        "name": "Patient cubicle curtains or screens or curtain track hardware",
        "code": "42191607"
      },
      { "name": "Nursing controls or exit monitors", "code": "42191608" },
      { "name": "Clinical headwall systems", "code": "42191609" },
      { "name": "Clinical modular casework", "code": "42191610" },
      { "name": "Nurse communication modules or systems", "code": "42191611" },
      { "name": "Hospital intercom systems", "code": "42191612" },
      { "name": "Infant or child security transmitter", "code": "42191613" },
      { "name": "Medical gas products", "code": "42191700" },
      { "name": "Medical gas or electric service tracks", "code": "42191701" },
      { "name": "Medical gas delivery columns", "code": "42191702" },
      { "name": "Medical gas outlets", "code": "42191703" },
      { "name": "Medical gas air compressor systems", "code": "42191704" },
      { "name": "Medical gas alarms", "code": "42191705" },
      { "name": "Medical gas manifold", "code": "42191706" },
      { "name": "Medical vacuum systems", "code": "42191707" },
      { "name": "Medical air pressure control cabinets", "code": "42191708" },
      {
        "name": "Medical gas shutoff valves or valve boxes",
        "code": "42191709"
      },
      {
        "name": "Medical gas cylinder carts or stands or accessories",
        "code": "42191710"
      },
      {
        "name": "Surgical compressed air tanks or accessories",
        "code": "42191711"
      },
      { "name": "Patient beds and accessories", "code": "42191800" },
      { "name": "Overbed tables or accessories", "code": "42191801" },
      { "name": "Clinical incubators or infant warmers", "code": "42191802" },
      {
        "name": "Clinical bassinets or cribs or pediatric beds or accessories",
        "code": "42191803"
      },
      { "name": "Medical or surgical bedside rails", "code": "42191804" },
      { "name": "Medical suspended columns", "code": "42191805" },
      { "name": "Clinical trapeze bars", "code": "42191806" },
      {
        "name": "Patient care beds or accessories for general use",
        "code": "42191807"
      },
      {
        "name": "Patient care beds or accessories for specialty care",
        "code": "42191808"
      },
      { "name": "Patient care mattresses or accessories", "code": "42191810" },
      { "name": "Infant positioning cradles", "code": "42191811" },
      { "name": "Infant incubator supply kits", "code": "42191812" },
      { "name": "Patient care cot covers", "code": "42191813" },
      {
        "name": "Clinical incubator or infant warmer accessories",
        "code": "42191814"
      },
      { "name": "Clinical cabinetry", "code": "42191900" },
      {
        "name": "Bedside clinical cabinets or accessories",
        "code": "42191901"
      },
      { "name": "Hospital armoires", "code": "42191902" },
      { "name": "Medical monitor cabinets", "code": "42191903" },
      { "name": "Narcotic cabinets or safes", "code": "42191904" },
      { "name": "Medical treatment cabinets", "code": "42191905" },
      { "name": "Blanket or solution warmer cabinets", "code": "42191906" },
      {
        "name": "Medical instrument storage cabinets or chests",
        "code": "42191907"
      },
      { "name": "Medical chart caddies or accessories", "code": "42191908" },
      {
        "name": "Computerized medication dispensing cabinet",
        "code": "42191909"
      },
      {
        "name": "Clinical procedure and examination tables",
        "code": "42192000"
      },
      {
        "name": "Medical exam or procedure tables for general use",
        "code": "42192001"
      },
      {
        "name": "Medical exam or procedure table accessories for general use excluding cover sheets",
        "code": "42192002"
      },
      { "name": "Physical therapy massage table", "code": "42192003" },
      {
        "name": "Clinical seating and stools and related products",
        "code": "42192100"
      },
      {
        "name": "Blood drawing or phlebotomy chairs or accessories",
        "code": "42192101"
      },
      { "name": "Hospital recliners or accessories", "code": "42192102" },
      { "name": "Patient chairs", "code": "42192103" },
      { "name": "Physician stools and accessories", "code": "42192104" },
      { "name": "Medical facility visitor chairs", "code": "42192106" },
      {
        "name": "Clinical examination chairs or accessories",
        "code": "42192107"
      },
      { "name": "Patient transport products", "code": "42192200" },
      {
        "name": "Patient transport trolleys or accessories",
        "code": "42192201"
      },
      { "name": "Gurneys or scissor lifts", "code": "42192202" },
      { "name": "Geriatric chairs or accessories", "code": "42192203" },
      {
        "name": "Patient transport incubators or accessories",
        "code": "42192204"
      },
      { "name": "Patient scooter accessories", "code": "42192205" },
      { "name": "Patient scooters", "code": "42192206" },
      {
        "name": "Patient stretchers or stretcher accessories",
        "code": "42192207"
      },
      { "name": "Wheelchair accessories", "code": "42192208" },
      { "name": "Wheelchair ramps", "code": "42192209" },
      { "name": "Wheelchairs", "code": "42192210" },
      { "name": "Patient shifting boards or accessories", "code": "42192211" },
      { "name": "Patient transfer mat or sheet", "code": "42192212" },
      {
        "name": "Patient holding or evacuation system heater unit or accessories",
        "code": "42192213"
      },
      { "name": "Wheelchair lift", "code": "42192214" },
      { "name": "Patient lifts", "code": "42192300" },
      { "name": "Patient lifts or accessories", "code": "42192301" },
      { "name": "Clinical hydraulic lifts or accessories", "code": "42192302" },
      { "name": "Patient suspended seats or slings", "code": "42192303" },
      { "name": "Patient ceiling hoists", "code": "42192304" },
      { "name": "Infant slings or accessories", "code": "42192305" },
      {
        "name": "Medical equipment transport and transfer products",
        "code": "42192400"
      },
      { "name": "Emergency or resuscitation carts", "code": "42192401" },
      {
        "name": "Diagnostic or monitoring equipment specific carts",
        "code": "42192402"
      },
      { "name": "Medical isolation carts", "code": "42192403" },
      { "name": "Medical carts or accessories", "code": "42192404" },
      { "name": "Irrigator mobile stands", "code": "42192405" },
      { "name": "Urinal carrying carts", "code": "42192406" },
      { "name": "Medical equipment protectors", "code": "42192500" },
      { "name": "Medical equipment covers", "code": "42192501" },
      { "name": "Medical equipment bags", "code": "42192502" },
      {
        "name": "Medication dispensing and measuring devices and supplies",
        "code": "42192600"
      },
      { "name": "Suppository molds", "code": "42192601" },
      {
        "name": "Medication or pill dispensers or accessories",
        "code": "42192602"
      },
      {
        "name": "Medicine feeding cups or bottles or accessories",
        "code": "42192603"
      },
      { "name": "Drug delivery system or accessories", "code": "42192604" },
      { "name": "Powder blower", "code": "42192606" },
      {
        "name": "Medical diagnostic imaging and nuclear medicine products",
        "code": "42200000"
      },
      {
        "name": "Medical computed tomography CT or CAT systems and related products",
        "code": "42201500"
      },
      {
        "name": "Medical computed tomography CT or CAT complete stationary unit installation",
        "code": "42201501"
      },
      {
        "name": "Medical computed tomography CT or CAT mobile or transportable or van units",
        "code": "42201502"
      },
      {
        "name": "Medical computed tomography CT or CAT 3 dimensional system components",
        "code": "42201503"
      },
      {
        "name": "Medical computed tomography CT or CAT bone mineral content components",
        "code": "42201504"
      },
      {
        "name": "Medical computed tomography CT or CAT consoles",
        "code": "42201505"
      },
      {
        "name": "Medical computed tomography CT or CAT helical system components",
        "code": "42201507"
      },
      {
        "name": "Medical computed tomography CT or CAT monitors",
        "code": "42201508"
      },
      {
        "name": "Medical computed tomography CT or CAT power conditioners",
        "code": "42201509"
      },
      {
        "name": "Medical computed tomography CT or CAT quality assurance or calibration phantoms or devices",
        "code": "42201510"
      },
      {
        "name": "Medical computed tomography CT or CAT scanners or tubes",
        "code": "42201511"
      },
      {
        "name": "Medical computed tomography CT or CAT tables or stands or chairs",
        "code": "42201512"
      },
      {
        "name": "Medical computed tomography CT or CAT ultrafast system components",
        "code": "42201513"
      },
      {
        "name": "Medical magnetic resonance imaging MRI products",
        "code": "42201600"
      },
      {
        "name": "Medical magnetic resonance imaging MRI complete stationary unit installation",
        "code": "42201601"
      },
      {
        "name": "Medical magnetic resonance imaging MRI mobile or transportable or van systems",
        "code": "42201602"
      },
      {
        "name": "Medical magnetic resonance imaging MRI 3 dimensional system components",
        "code": "42201603"
      },
      {
        "name": "Medical magnetic resonance imaging MRI quality assurance or calibration phantoms or devices",
        "code": "42201604"
      },
      {
        "name": "Medical magnetic resonance imaging MRI coils",
        "code": "42201605"
      },
      {
        "name": "Medical magnetic resonance imaging MRI monitors",
        "code": "42201607"
      },
      {
        "name": "Medical magnetic resonance imaging MRI primary or remote or secondary consoles",
        "code": "42201608"
      },
      {
        "name": "Medical magnetic resonance imaging MRI scanners",
        "code": "42201609"
      },
      {
        "name": "Medical magnetic resonance imaging MRI surgical instruments or guidance systems",
        "code": "42201610"
      },
      {
        "name": "Medical magnetic resonance imaging MRI tables",
        "code": "42201611"
      },
      {
        "name": "Medical ultrasound and doppler and echo imaging products",
        "code": "42201700"
      },
      {
        "name": "Cardiac ultrasound or doppler or echo units or cardioscopes",
        "code": "42201701"
      },
      {
        "name": "Fetal or gynecological ultrasound or echo units",
        "code": "42201702"
      },
      { "name": "Mammographic ultrasound or echo units", "code": "42201703" },
      { "name": "Medical ultrasound bone densitometers", "code": "42201704" },
      {
        "name": "Medical ultrasound or doppler or echo probe covers",
        "code": "42201705"
      },
      {
        "name": "Medical ultrasound or doppler or echo probes",
        "code": "42201706"
      },
      {
        "name": "Medical ultrasound or doppler or echo gel warmers",
        "code": "42201707"
      },
      {
        "name": "Medical ultrasound or doppler or echo gels",
        "code": "42201708"
      },
      {
        "name": "Medical ultrasound or doppler or echo monitors",
        "code": "42201709"
      },
      {
        "name": "Medical ultrasound or doppler or echo printers",
        "code": "42201710"
      },
      {
        "name": "Medical ultrasound or doppler or echo transducers or accessories",
        "code": "42201711"
      },
      {
        "name": "Medical ultrasound or doppler or pulse echo or echography units for general diagnostic use",
        "code": "42201712"
      },
      {
        "name": "Medical ultrasound or doppler or echo three dimensional components",
        "code": "42201713"
      },
      { "name": "Thesiometers", "code": "42201714" },
      {
        "name": "Vaginal ultrasound or echo probes or accessories",
        "code": "42201715"
      },
      { "name": "Vascular ultrasound units", "code": "42201716" },
      { "name": "Medical ultrasound transmission pads", "code": "42201717" },
      { "name": "Medical ultrasound ophthalmic scanners", "code": "42201718" },
      { "name": "Medical ultrasound scanning lotions", "code": "42201719" },
      { "name": "Medical diagnostic x ray products", "code": "42201800" },
      { "name": "Cardiology x ray films", "code": "42201801" },
      { "name": "Chest x ray equipment", "code": "42201802" },
      { "name": "Mammography x ray equipment", "code": "42201803" },
      { "name": "Medical c arm x ray units", "code": "42201804" },
      { "name": "Medical cine fluoroscopy equipment", "code": "42201805" },
      {
        "name": "Medical radiology and fluoroscopy RF equipment",
        "code": "42201806"
      },
      { "name": "Medical radioisotope scanners", "code": "42201807" },
      { "name": "Medical x ray buckys", "code": "42201808" },
      {
        "name": "Medical imaging contrast agent injectors or accessories",
        "code": "42201809"
      },
      { "name": "Medical x ray film or cassette", "code": "42201810" },
      {
        "name": "Medical x ray quality assurance or calibration devices",
        "code": "42201811"
      },
      {
        "name": "Medical x ray tables or stands or chairs or cabinets or accessories",
        "code": "42201812"
      },
      { "name": "Medical x ray tomography units", "code": "42201813" },
      { "name": "Medical x ray tubes", "code": "42201814" },
      {
        "name": "Medical x ray units for general diagnostic use",
        "code": "42201815"
      },
      { "name": "Medical xeroradiography units", "code": "42201816" },
      { "name": "X ray bone densitometers", "code": "42201817" },
      {
        "name": "Combination step wedges and spin tops for radiographic equipment",
        "code": "42201818"
      },
      {
        "name": "Medical x ray film hangers or its accessories",
        "code": "42201819"
      },
      { "name": "Medical radiographic equipment grids", "code": "42201820" },
      { "name": "Radiographic film cassette holders", "code": "42201821" },
      {
        "name": "Medical x ray equipment cases or covers or accessories",
        "code": "42201822"
      },
      { "name": "Medical x ray tube and transfomer unit", "code": "42201823" },
      { "name": "Medical arthrography sets", "code": "42201824" },
      { "name": "Medical x ray apparatus tube inserts", "code": "42201825" },
      { "name": "Medical x ray apparatus repair kits", "code": "42201826" },
      { "name": "Medical x ray darkroom tent repair kits", "code": "42201827" },
      { "name": "Medical x ray apparatus filters", "code": "42201828" },
      { "name": "Radiographic locators", "code": "42201829" },
      { "name": "Medical x ray intensifying screens", "code": "42201830" },
      { "name": "Medical x ray films or cassettes masks", "code": "42201831" },
      { "name": "Radiographic film or cassette covers", "code": "42201832" },
      { "name": "Radiographic film or cassette changers", "code": "42201833" },
      {
        "name": "Medical radiographic x ray apparatus rectifier assemblies",
        "code": "42201834"
      },
      {
        "name": "Medical x ray apparatus tube unit assemblies",
        "code": "42201835"
      },
      {
        "name": "Medical x ray apparatus compression band assemblies",
        "code": "42201836"
      },
      { "name": "Medical x ray water coolers", "code": "42201837" },
      {
        "name": "Medical enteroclysis catheters or catheters kits",
        "code": "42201838"
      },
      { "name": "Medical imaging procedure trays", "code": "42201839" },
      { "name": "Vascular sealing devices", "code": "42201840" },
      { "name": "Medical diagnostic x ray papers", "code": "42201841" },
      { "name": "Medical x ray skin marker", "code": "42201843" },
      { "name": "Medical diagnostic x ray ruler", "code": "42201844" },
      {
        "name": "Radiology film jacket or insert or mailer",
        "code": "42201845"
      },
      {
        "name": "Radiographic and fluoroscopic x ray collimator",
        "code": "42201846"
      },
      { "name": "Mobile x ray system", "code": "42201847" },
      { "name": "Digital x ray imaging system", "code": "42201848" },
      { "name": "Fluorescent scanner", "code": "42201849" },
      { "name": "Nuclear tomography system", "code": "42201850" },
      { "name": "Beta or gamma counter for clinical use", "code": "42201851" },
      { "name": "Fluorescent screen", "code": "42201852" },
      { "name": "Myelographic procedure set", "code": "42201853" },
      {
        "name": "Medical x ray film illuminators and viewing equipment",
        "code": "42201900"
      },
      { "name": "Medical x ray film hot spot lights", "code": "42201901" },
      {
        "name": "Medical x ray film large rack viewing systems",
        "code": "42201902"
      },
      { "name": "Medical x ray film view boxes", "code": "42201903" },
      {
        "name": "Medical x ray film illuminator windows or screens",
        "code": "42201904"
      },
      { "name": "Medical x ray film transfer cases", "code": "42201905" },
      { "name": "Medical x ray film illuminator clips", "code": "42201906" },
      { "name": "Medical x ray film stereoscopes", "code": "42201907" },
      { "name": "Medical fluoroscopic screen hoods", "code": "42201908" },
      {
        "name": "Medical diagnostic gamma cameras and related products",
        "code": "42202000"
      },
      { "name": "Medical gamma cameras for general use", "code": "42202001" },
      {
        "name": "Lymphatic mapping navigator systems or accessories",
        "code": "42202002"
      },
      { "name": "Lymphatic mapping probes", "code": "42202003" },
      { "name": "Lymphatic mapping collimators", "code": "42202004" },
      { "name": "Lymphatic mapping procedure pack", "code": "42202005" },
      { "name": "Bone densitometer", "code": "42202006" },
      { "name": "Brachytherapy products", "code": "42202100" },
      {
        "name": "Brachytherapy intracavity containers or seeds",
        "code": "42202101"
      },
      {
        "name": "Brachytherapy catheters or syringes or inserters or applicators",
        "code": "42202102"
      },
      { "name": "Brachytherapy seed storage containers", "code": "42202103" },
      { "name": "Brachytherapy seed capture kits", "code": "42202104" },
      { "name": "Brachytherapy units", "code": "42202105" },
      { "name": "Manual radionuclide applicator system", "code": "42202106" },
      { "name": "Gamma radiation therapy products", "code": "42202200" },
      {
        "name": "Radiosurgical gamma knife collimators or helmets",
        "code": "42202201"
      },
      {
        "name": "Radiosurgical gamma knife units or scintillators",
        "code": "42202202"
      },
      { "name": "Cushion sets for radiosurgical helmets", "code": "42202203" },
      { "name": "Radionuclide rebreathing system", "code": "42202204" },
      { "name": "Radiation therapy beam shaping block", "code": "42202205" },
      {
        "name": "Medical linear accelerator intensity modulated radiation therapy IMRT products",
        "code": "42202300"
      },
      {
        "name": "Medical linear accelerator intensity modulated radiation therapy IMRT two dimensional units",
        "code": "42202301"
      },
      {
        "name": "Medical linear accelerator intensity modulated radiation therapy IMRT three dimensional units",
        "code": "42202302"
      },
      {
        "name": "Medical linear accelerator intensity modulated radiation therapy IMRT collimators",
        "code": "42202303"
      },
      {
        "name": "Medical positron emission tomography PET equipment and related products",
        "code": "42202400"
      },
      {
        "name": "Medical positron emission tomography PET units",
        "code": "42202401"
      },
      {
        "name": "Medical single photon emission computed tomography SPECT equipment and related products",
        "code": "42202500"
      },
      {
        "name": "Medical single photon emission computed tomography SPECT units",
        "code": "42202501"
      },
      {
        "name": "Radioimmunotherapy and radioisotope administration products",
        "code": "42202600"
      },
      { "name": "Thyroid irradiation therapy supplies", "code": "42202601" },
      { "name": "Test kits for radioimmunotherapy", "code": "42202602" },
      { "name": "Radiotherapy teletherapy products", "code": "42202700" },
      {
        "name": "Radiotherapy teletherapy cobalt 60 equipment",
        "code": "42202701"
      },
      {
        "name": "Radiotherapy teletherapy linear accelerators",
        "code": "42202702"
      },
      {
        "name": "Radiotherapy teletherapy orthovoltage x ray machines",
        "code": "42202703"
      },
      {
        "name": "Radiotherapy teletherapy superficial x ray machines",
        "code": "42202704"
      },
      { "name": "Low energy medical x ray equipment", "code": "42202900" },
      { "name": "Low energy medical x ray units", "code": "42202901" },
      {
        "name": "Medical linear accelerators and related products",
        "code": "42203000"
      },
      {
        "name": "Mobile or transportable medical linear accelerators",
        "code": "42203001"
      },
      { "name": "Radiobiological instruments", "code": "42203100" },
      { "name": "Radiobiological effect microdosimeters", "code": "42203101" },
      { "name": "Radiotherapy simulators", "code": "42203200" },
      {
        "name": "X ray and fluoroscopy RF radiotherapy planning simulators",
        "code": "42203201"
      },
      {
        "name": "Computed tomography CT or CAT radiotherapy simulators",
        "code": "42203202"
      },
      { "name": "Medical stereotactic systems", "code": "42203300" },
      { "name": "Frameless stereotactic therapy systems", "code": "42203301" },
      { "name": "Stereotactic therapy headframes", "code": "42203302" },
      { "name": "Stereotactic biopsy systems", "code": "42203303" },
      {
        "name": "Vascular imaging and interventional cardiology and cardiac catheterization lab products",
        "code": "42203400"
      },
      { "name": "Coronary stent", "code": "42203401" },
      {
        "name": "Diagnostic or interventional vascular catheters or sets",
        "code": "42203402"
      },
      {
        "name": "Diagnostic or interventional vascular catheter introducers or sets",
        "code": "42203403"
      },
      { "name": "Vascular imaging guidewire", "code": "42203404" },
      { "name": "Angioplasty balloon catheter", "code": "42203405" },
      {
        "name": "Removal devices of diagnostic or interventional vascular catheters or sets",
        "code": "42203406"
      },
      {
        "name": "Angiography contrast medium delivery set",
        "code": "42203407"
      },
      { "name": "Cardiovascular sheath kits", "code": "42203408" },
      { "name": "Angioscopic valvulotomes", "code": "42203409" },
      { "name": "Cardiovascular catheter sheath", "code": "42203410" },
      { "name": "Catheter carts", "code": "42203411" },
      { "name": "Peripheral stent", "code": "42203412" },
      { "name": "Vascular coil", "code": "42203413" },
      { "name": "Vascular filter", "code": "42203414" },
      { "name": "Cardiac catheterization lab equipment", "code": "42203415" },
      { "name": "Cardiac ablation catheter", "code": "42203416" },
      { "name": "Endograft", "code": "42203417" },
      { "name": "Artherectomy catheter", "code": "42203418" },
      { "name": "Biliary stent", "code": "42203419" },
      { "name": "Vascular imaging snare", "code": "42203420" },
      {
        "name": "Diagnostic or interventional vascular tray or pack",
        "code": "42203421"
      },
      { "name": "Embolization glue", "code": "42203422" },
      { "name": "Embolization sphere", "code": "42203423" },
      { "name": "Arteriotomy site closure device", "code": "42203424" },
      {
        "name": "Thrombectomy or embolectomy catheter or catheter set",
        "code": "42203425"
      },
      { "name": "Angiography guidewire torque device", "code": "42203426" },
      { "name": "Angiography introducer needle", "code": "42203427" },
      {
        "name": "Angiography control valve and hemostatic valve",
        "code": "42203428"
      },
      { "name": "Pericardiocentesis catheter", "code": "42203429" },
      { "name": "Electrophysiology mapping catheter", "code": "42203430" },
      { "name": "Cardiac pacemakers and related products", "code": "42203500" },
      {
        "name": "Cardiac pacemaker generator or cardiac resynchronization therapy pacemaker CRT-P",
        "code": "42203501"
      },
      {
        "name": "Cardiac pacing leads or electrodes or accessories",
        "code": "42203502"
      },
      { "name": "Cardiac pacing lead introducers or sets", "code": "42203503" },
      { "name": "Cardiac recorder", "code": "42203504" },
      {
        "name": "Implantable cardioverter defibrillator ICD or cardiac resynchronization therapy defibrillator CRT-D",
        "code": "42203505"
      },
      {
        "name": "Implantable cardiac defibrillator device ICD or cardiac resynchronization therapy defibrillator CRT D lead or electrode",
        "code": "42203506"
      },
      {
        "name": "Medical radiological imaging information and archiving products",
        "code": "42203600"
      },
      {
        "name": "Defense Digital Imaging Network DIN system equipment",
        "code": "42203601"
      },
      {
        "name": "Digital Imaging Communications in Medicine DICOM standard system equipment",
        "code": "42203602"
      },
      {
        "name": "Medical picture archiving computer systems PACS",
        "code": "42203603"
      },
      {
        "name": "Medical x ray film archiving system hardware",
        "code": "42203604"
      },
      {
        "name": "Medical x ray film archiving system software",
        "code": "42203605"
      },
      { "name": "Teleradiography system", "code": "42203606" },
      {
        "name": "Medical imaging processing equipment and supplies",
        "code": "42203700"
      },
      {
        "name": "Medical x ray film daylight stampers or identification printers",
        "code": "42203701"
      },
      {
        "name": "Medical imaging wet darkroom or daylight processors",
        "code": "42203702"
      },
      { "name": "Medical x ray passthrough boxes", "code": "42203703" },
      { "name": "Medical toners or developers", "code": "42203704" },
      {
        "name": "Medical imaging dry laser printers or imagers",
        "code": "42203705"
      },
      {
        "name": "Medical x ray film processing chemical kits",
        "code": "42203706"
      },
      {
        "name": "Medical x ray darkroom equipment or supplies",
        "code": "42203707"
      },
      { "name": "Medical x ray film processing fixers", "code": "42203708" },
      {
        "name": "Combination displays and printers for x ray system calibrator sets",
        "code": "42203709"
      },
      { "name": "Medical x ray film markers", "code": "42203710" },
      { "name": "Medical radiological positioning aids", "code": "42203800" },
      {
        "name": "Medical computed tomography CT or CAT positioning aids",
        "code": "42203801"
      },
      {
        "name": "Medical magnetic resonance imaging MRI positioning aids",
        "code": "42203802"
      },
      {
        "name": "Medical radiological positioning aids for general radiological use",
        "code": "42203803"
      },
      { "name": "Radiation therapy positioning aid", "code": "42203804" },
      {
        "name": "Medical radiation detection or monitoring products",
        "code": "42203900"
      },
      { "name": "Medical radiation dosimeters", "code": "42203901" },
      { "name": "Medical radiation films or badges", "code": "42203902" },
      {
        "name": "Medical neutron radiation therapy system",
        "code": "42203903"
      },
      {
        "name": "Medical radiological shielding and protection products",
        "code": "42204000"
      },
      {
        "name": "Medical radiological shielding apron racks",
        "code": "42204001"
      },
      {
        "name": "Medical radiological shielding aprons or masks or drapes",
        "code": "42204002"
      },
      {
        "name": "Medical radiological shielding portable containers for radioactive materials",
        "code": "42204003"
      },
      { "name": "Medical radiological shielding earplugs", "code": "42204004" },
      { "name": "Medical radiological shielding gloves", "code": "42204005" },
      {
        "name": "Medical radiological shielding freestanding or portable screens",
        "code": "42204006"
      },
      {
        "name": "Medical radiological shielding wall or ceiling or floor installed panels",
        "code": "42204007"
      },
      {
        "name": "Medical radiological shielding chambers or rooms or safes",
        "code": "42204008"
      },
      { "name": "Medical radiological shielding eyewear", "code": "42204009" },
      {
        "name": "Nuclear medicine hot lab equipment and related products",
        "code": "42204100"
      },
      { "name": "Syringe shield or holder or carrier", "code": "42204101" },
      {
        "name": "Independent living aids for the physically challenged",
        "code": "42210000"
      },
      {
        "name": "Ambulation and transfer and positioning aids for the physically challenged",
        "code": "42211500"
      },
      { "name": "Canes or cane accessories", "code": "42211501" },
      { "name": "Crutches or crutch accessories", "code": "42211502" },
      { "name": "Positioning devices", "code": "42211503" },
      { "name": "Standers or standing aids", "code": "42211504" },
      { "name": "Walker or rollator accessories", "code": "42211505" },
      { "name": "Walkers or rollators", "code": "42211506" },
      { "name": "Whole body sliding or turning devices", "code": "42211507" },
      {
        "name": "Multifunctional mobility devices or accessories",
        "code": "42211508"
      },
      {
        "name": "Head or face protective helmets or devices or accessories for the physically challenged",
        "code": "42211509"
      },
      {
        "name": "Bathroom and bathing aids for the physically challenged",
        "code": "42211600"
      },
      {
        "name": "Bathboards for the physically challenged",
        "code": "42211601"
      },
      {
        "name": "Bath brushes or sponges or scrubbers for the physically challenged",
        "code": "42211602"
      },
      {
        "name": "Bath lifts or accessories for the physically challenged",
        "code": "42211603"
      },
      {
        "name": "Bath mitts for the physically challenged",
        "code": "42211604"
      },
      {
        "name": "Bath pillows for the physically challenged",
        "code": "42211605"
      },
      {
        "name": "Commodes or accessories for the physically challenged",
        "code": "42211606"
      },
      {
        "name": "Elevated toilet seats for the physically challenged",
        "code": "42211607"
      },
      {
        "name": "Grab bars or tub safety rails for the physically challenged",
        "code": "42211608"
      },
      {
        "name": "Shower or bath chairs or seats for the physically challenged",
        "code": "42211610"
      },
      {
        "name": "Sitz baths for the physically challenged",
        "code": "42211611"
      },
      {
        "name": "Toilet arm supports for the physically challenged",
        "code": "42211612"
      },
      {
        "name": "Toilet frames for the physically challenged",
        "code": "42211613"
      },
      {
        "name": "Toilet seats for the physically challenged",
        "code": "42211614"
      },
      {
        "name": "Toilet seat lifters for the physically challenged",
        "code": "42211615"
      },
      {
        "name": "Hygiene or stimulation toileting aids for the physically challenged",
        "code": "42211616"
      },
      {
        "name": "Transfer benches for the physically challenged",
        "code": "42211617"
      },
      {
        "name": "Bedwetting wrist alarm for the physically challenged",
        "code": "42211618"
      },
      {
        "name": "Soap protector for eyes and ears for the physically challenged",
        "code": "42211619"
      },
      {
        "name": "Shampoo tray or basin for the physically challenged",
        "code": "42211620"
      },
      {
        "name": "Communication aids for the physically challenged",
        "code": "42211700"
      },
      {
        "name": "Adaptive communication switches for the physically challenged",
        "code": "42211701"
      },
      {
        "name": "Braille devices for the physically challenged",
        "code": "42211702"
      },
      {
        "name": "Braille writing paper or plastic for physically challenged",
        "code": "42211703"
      },
      {
        "name": "Headpointers or mouthsticks for the physically challenged",
        "code": "42211704"
      },
      {
        "name": "Hearing aids for the physically challenged",
        "code": "42211705"
      },
      {
        "name": "Letter or symbol boards for the physically challenged",
        "code": "42211706"
      },
      {
        "name": "Telecommunication devices TDD or teletypewriters TTY for the physically challenged",
        "code": "42211707"
      },
      {
        "name": "Telephone aids for the physically challenged",
        "code": "42211708"
      },
      {
        "name": "Typing aids for the physically challenged",
        "code": "42211709"
      },
      {
        "name": "Writing aids for the physically challenged",
        "code": "42211710"
      },
      {
        "name": "Voice synthesizers for the physically challenged",
        "code": "42211711"
      },
      { "name": "Cases for hearing aids", "code": "42211712" },
      {
        "name": "Dressing and grooming aids for the physically challenged",
        "code": "42211800"
      },
      {
        "name": "Button hooks for the physically challenged",
        "code": "42211801"
      },
      {
        "name": "Dressing kits for the physically challenged",
        "code": "42211802"
      },
      {
        "name": "Dressing sticks for the physically challenged",
        "code": "42211803"
      },
      {
        "name": "Hairbrushes or combs for the physically challenged",
        "code": "42211804"
      },
      {
        "name": "Inspection mirror for the physically challenged",
        "code": "42211805"
      },
      {
        "name": "Lotion applicators for the physically challenged",
        "code": "42211806"
      },
      {
        "name": "Mouth care for the physically challenged",
        "code": "42211807"
      },
      {
        "name": "Nail clippers or files for the physically challenged",
        "code": "42211808"
      },
      {
        "name": "Pant clips for the physically challenged",
        "code": "42211809"
      },
      {
        "name": "Shoe fasteners for the physically challenged",
        "code": "42211810"
      },
      {
        "name": "Shoe horns for the physically challenged",
        "code": "42211811"
      },
      {
        "name": "Sock or stocking aids for the physically challenged",
        "code": "42211812"
      },
      {
        "name": "Zipper pulls for the physically challenged",
        "code": "42211813"
      },
      {
        "name": "Eating and drinking and food preparation aids for the physically challenged",
        "code": "42211900"
      },
      {
        "name": "Anti slip materials for the physically challenged",
        "code": "42211901"
      },
      {
        "name": "Assistive cooking devices for the physically challenged",
        "code": "42211902"
      },
      {
        "name": "Can openers for the physically challenged",
        "code": "42211903"
      },
      { "name": "Choppers for the physically challenged", "code": "42211904" },
      {
        "name": "Cups or mugs for the physically challenged",
        "code": "42211905"
      },
      {
        "name": "Cutlery or utensil holders for the physically challenged",
        "code": "42211906"
      },
      {
        "name": "Cutlery or utensils for the physically challenged",
        "code": "42211907"
      },
      {
        "name": "Cutting or paring boards for the physically challenged",
        "code": "42211908"
      },
      {
        "name": "Drink holders for the physically challenged",
        "code": "42211909"
      },
      {
        "name": "Food catchers or bibs for the physically challenged",
        "code": "42211910"
      },
      {
        "name": "Food guards for the physically challenged",
        "code": "42211911"
      },
      {
        "name": "Holders for kitchen devices for the physically challenged",
        "code": "42211912"
      },
      {
        "name": "Jumbo digit timers for the physically challenged",
        "code": "42211913"
      },
      {
        "name": "Measuring aids for the physically challenged",
        "code": "42211914"
      },
      {
        "name": "Self feeders or accessories for the physically challenged",
        "code": "42211915"
      },
      {
        "name": "Straws or straw holders for the physically challenged",
        "code": "42211916"
      },
      { "name": "Tableware for the physically challenged", "code": "42211917" },
      {
        "name": "Talking food scales for the physically challenged",
        "code": "42211918"
      },
      {
        "name": "Housekeeping and homemaking aids for the physically challenged",
        "code": "42212000"
      },
      {
        "name": "Door openers for the physically challenged",
        "code": "42212001"
      },
      {
        "name": "Key turners or holders for the physically challenged",
        "code": "42212002"
      },
      {
        "name": "Knob turners for the physically challenged",
        "code": "42212003"
      },
      {
        "name": "Light switch extensions for the physically challenged",
        "code": "42212004"
      },
      {
        "name": "Long handled dustpans or brushes for the physically challenged",
        "code": "42212005"
      },
      {
        "name": "Self opening scissors for the physically challenged",
        "code": "42212006"
      },
      {
        "name": "Suction brushes for the physically challenged",
        "code": "42212007"
      },
      {
        "name": "Leisure and recreational aids for the physically challenged",
        "code": "42212100"
      },
      {
        "name": "Automatic card shufflers for the physically challenged",
        "code": "42212101"
      },
      {
        "name": "Board games for the physically challenged",
        "code": "42212102"
      },
      {
        "name": "Book holders for the physically challenged",
        "code": "42212103"
      },
      {
        "name": "Braille or large face playing cards for the physically challenged",
        "code": "42212104"
      },
      {
        "name": "Camping equipment for the physically challenged",
        "code": "42212105"
      },
      {
        "name": "Fishing or hunting aids for the physically challenged",
        "code": "42212106"
      },
      {
        "name": "Flotation or swimming aids for the physically challenged",
        "code": "42212107"
      },
      {
        "name": "Gardening tools for the physically challenged",
        "code": "42212108"
      },
      {
        "name": "Handicraft tools or materials or equipment for the physically challenged",
        "code": "42212109"
      },
      {
        "name": "Page turners for the physically challenged",
        "code": "42212110"
      },
      {
        "name": "Playing card holders for the physically challenged",
        "code": "42212111"
      },
      {
        "name": "Sewing aids for the physically challenged",
        "code": "42212112"
      },
      {
        "name": "Smokers aids for the physically challenged",
        "code": "42212113"
      },
      {
        "name": "Medication handling aids for the physically challenged",
        "code": "42212200"
      },
      {
        "name": "Pill crushers or splitters for the physically challenged",
        "code": "42212201"
      },
      {
        "name": "Pill organizers for the physically challenged",
        "code": "42212202"
      },
      {
        "name": "Pill reminders for the physically challenged",
        "code": "42212203"
      },
      {
        "name": "Tube squeezers for the physically challenged",
        "code": "42212204"
      },
      {
        "name": "Reaching and gripping aids for the physically challenged",
        "code": "42212300"
      },
      {
        "name": "Grip materials or devices for the physically challenged",
        "code": "42212301"
      },
      { "name": "Holders for the physically challenged", "code": "42212302" },
      {
        "name": "Container openers for the physically challenged",
        "code": "42212303"
      },
      { "name": "Reachers for the physically challenged", "code": "42212304" },
      {
        "name": "Intravenous and arterial administration products",
        "code": "42220000"
      },
      {
        "name": "Intravenous and arterial cannulas and catheters and accessories",
        "code": "42221500"
      },
      { "name": "Arterial line catheters", "code": "42221501" },
      {
        "name": "Arterial line continuous catheter flush valves",
        "code": "42221502"
      },
      { "name": "Central venous catheters", "code": "42221503" },
      {
        "name": "Peripheral intravenous catheters for general use",
        "code": "42221504"
      },
      {
        "name": "Pediatric or microflow or scalp vein intravenous or arterial catheters",
        "code": "42221505"
      },
      { "name": "Umbilical catheters", "code": "42221506" },
      {
        "name": "Intravenous or arterial start kit without catheter",
        "code": "42221507"
      },
      {
        "name": "Intravenous or arterial catheter skin care kits",
        "code": "42221508"
      },
      { "name": "Intravenous or arterial catheter tray", "code": "42221509" },
      {
        "name": "Intravenous or arterial cannulas or accessories",
        "code": "42221512"
      },
      { "name": "Cardiovascular catheterization kits", "code": "42221513" },
      { "name": "Pulmonary artery catheter", "code": "42221514" },
      { "name": "Central venous catheter repair kit", "code": "42221515" },
      { "name": "Catheter tip occluder", "code": "42221516" },
      { "name": "Hemodialysis catheter", "code": "42221517" },
      { "name": "Splint strapping material", "code": "42221518" },
      {
        "name": "Intravenous and arterial tubing and administration sets and related products",
        "code": "42221600"
      },
      {
        "name": "Intravenous or arterial administration air bubble detectors",
        "code": "42221601"
      },
      {
        "name": "Intravenous or arterial administration ports or injection sites or caps or protectors",
        "code": "42221602"
      },
      {
        "name": "Intravenous or arterial extension tubing",
        "code": "42221603"
      },
      {
        "name": "Intravenous or arterial tubing adapters or connectors or locks or caps or protectors",
        "code": "42221604"
      },
      {
        "name": "Intravenous or arterial tubing check valves",
        "code": "42221605"
      },
      { "name": "Intravenous or arterial tubing clamps", "code": "42221606" },
      {
        "name": "Intravenous or arterial tubing filters or screens for general use",
        "code": "42221607"
      },
      {
        "name": "Intravenous or arterial tubing identification labels or tapes",
        "code": "42221608"
      },
      {
        "name": "Intravenous or arterial tubing administration set",
        "code": "42221609"
      },
      {
        "name": "Intravenous tubing spike entry ports or caps or protectors",
        "code": "42221610"
      },
      {
        "name": "Intravenous tubing spikes or caps or protectors",
        "code": "42221611"
      },
      {
        "name": "Intravenous or arterial tubing needleless injection ports or stopcocks or manifolds",
        "code": "42221612"
      },
      {
        "name": "Intravenous tubing transfer leg closures or snaps",
        "code": "42221613"
      },
      {
        "name": "Intravenous tubing with catheter administration kits",
        "code": "42221614"
      },
      {
        "name": "Secondary or piggyback medication intravenous tubing",
        "code": "42221615"
      },
      {
        "name": "Intravenous or arterial tubing extension set",
        "code": "42221616"
      },
      { "name": "Needle resheathers", "code": "42221617" },
      { "name": "Intravenous or arterial procedure trays", "code": "42221618" },
      { "name": "Needleless injection manifold kit", "code": "42221619" },
      {
        "name": "Intravenous and arterial infusion bags and containers and related products",
        "code": "42221700"
      },
      {
        "name": "Intravenous or arterial infusion single port bags or containers",
        "code": "42221701"
      },
      {
        "name": "Intravenous or arterial infusion transfer bags or containers",
        "code": "42221702"
      },
      {
        "name": "Intravenous or arterial fluid warmers or accessories",
        "code": "42221703"
      },
      {
        "name": "Intravenous or arterial pressure infusion bags",
        "code": "42221704"
      },
      { "name": "Analgesia infusion vial assemblies", "code": "42221705" },
      {
        "name": "Intravenous or arterial infusion transfer bags or container spouts",
        "code": "42221706"
      },
      { "name": "Analgesic infusion sets or kits", "code": "42221707" },
      {
        "name": "Intravenous and arterial catheter and needle positioning aids",
        "code": "42221800"
      },
      {
        "name": "Intravenous or arterial arm board covers",
        "code": "42221801"
      },
      { "name": "Intravenous or arterial arm boards", "code": "42221802" },
      {
        "name": "Intravenous or arterial catheter positioning tapes or dressings or straps or cuffs",
        "code": "42221803"
      },
      {
        "name": "Intravenous or arterial flow measurement and regulation products",
        "code": "42221900"
      },
      {
        "name": "Dial calibrated intravenous flowmeters or regulators",
        "code": "42221901"
      },
      { "name": "Intravenous drop counters or regulators", "code": "42221902" },
      { "name": "Ultrasonic blood flow detectors", "code": "42221903" },
      { "name": "Electromagnetic blood flowmeter", "code": "42221904" },
      { "name": "Laser blood flowmeter", "code": "42221905" },
      { "name": "Magnetic resonance blood flowmeter", "code": "42221906" },
      {
        "name": "Intravenous infusion pumps and its analyzers and sensors and its accessories",
        "code": "42222000"
      },
      {
        "name": "Intravenous infusion pumps for general use",
        "code": "42222001"
      },
      { "name": "Intravenous syringe infusion pumps", "code": "42222002" },
      { "name": "Multichannel intravenous infusion pumps", "code": "42222003" },
      {
        "name": "Patient controlled analgesia infusion pumps",
        "code": "42222004"
      },
      { "name": "Intravenous pump parts or accessories", "code": "42222005" },
      {
        "name": "Intravenous infusion pump analyzers or sensors",
        "code": "42222006"
      },
      { "name": "Intravenous infusion pump transducers", "code": "42222007" },
      { "name": "Infusion pump kits or accessories", "code": "42222008" },
      { "name": "Syringe actuator for an injector", "code": "42222009" },
      {
        "name": "Intravenous and arterial equipment suspension and transport systems",
        "code": "42222100"
      },
      {
        "name": "Intravenous or arterial line equipment hangers",
        "code": "42222101"
      },
      {
        "name": "Mounted tracks or racks for intravenous infusion gravity systems",
        "code": "42222102"
      },
      {
        "name": "Intravenous infusion poles for wheelchairs",
        "code": "42222103"
      },
      {
        "name": "Intravenous or arterial line poles or stands",
        "code": "42222104"
      },
      {
        "name": "Needleless intravenous injection and withdrawal systems",
        "code": "42222200"
      },
      {
        "name": "Needleless intravenous injection syringe set or injection cannula",
        "code": "42222201"
      },
      {
        "name": "Needleless vial or bag withdrawal cannulas or adapters or decanters",
        "code": "42222202"
      },
      {
        "name": "Blood administration and transfusion products",
        "code": "42222300"
      },
      { "name": "Blood transfusion administration kits", "code": "42222301" },
      {
        "name": "Blood transfusion filters or screens or accessories",
        "code": "42222302"
      },
      {
        "name": "Blood administration or transfusion identification systems",
        "code": "42222303"
      },
      {
        "name": "Blood administration or transfusion tubing",
        "code": "42222304"
      },
      {
        "name": "Blood administration or transfusion tubing clamps",
        "code": "42222305"
      },
      {
        "name": "Blood administration or transfusion waste collection systems",
        "code": "42222306"
      },
      { "name": "Blood warming or transfusion systems", "code": "42222307" },
      {
        "name": "Blood administration or transfusion bags or containers",
        "code": "42222308"
      },
      {
        "name": "Blood administration or transfusion conservation systems",
        "code": "42222309"
      },
      { "name": "Clinical nutrition", "code": "42230000" },
      { "name": "Enteral feeding equipment and supplies", "code": "42231500" },
      { "name": "Enteral feeding infusion pumps", "code": "42231501" },
      { "name": "Enteral feeding administration sets", "code": "42231502" },
      {
        "name": "Enteral feeding irrigation sets or trays",
        "code": "42231503"
      },
      { "name": "Enteral nutrition bags or containers", "code": "42231504" },
      {
        "name": "Enteral feeding set adapters or connectors or extensions",
        "code": "42231505"
      },
      { "name": "Nasoenteric tube securing devices", "code": "42231506" },
      { "name": "Enteral tube cleaning brush", "code": "42231507" },
      { "name": "Enteral feeding set valves", "code": "42231508" },
      { "name": "Enteral nutrition weighing chambers", "code": "42231509" },
      {
        "name": "Enteral feeding infusion pump tubing sets",
        "code": "42231510"
      },
      {
        "name": "Gastrostomy and jejunostomy access devices or accessories",
        "code": "42231600"
      },
      { "name": "Gastrostomy tubes for general use", "code": "42231601" },
      {
        "name": "Percutaneous Endoscopic Gastrostomy tubes",
        "code": "42231602"
      },
      { "name": "Jejunostomy tubes", "code": "42231603" },
      { "name": "Gastric access buttons", "code": "42231604" },
      { "name": "Gastrostomy or jejunostomy tube holders", "code": "42231605" },
      { "name": "Colon decompression kits", "code": "42231606" },
      { "name": "Jejunostomy catheter and needle sets", "code": "42231608" },
      { "name": "Gastrostomy feeding tube kits", "code": "42231609" },
      { "name": "Nasoenteric tubes", "code": "42231700" },
      { "name": "Nasogastric tubes", "code": "42231701" },
      { "name": "Nasojejunal tubes", "code": "42231702" },
      { "name": "Gastric decompression tubes", "code": "42231703" },
      {
        "name": "Nasoenteric tubes not elsewhere classified",
        "code": "42231704"
      },
      { "name": "Nasogastric filters", "code": "42231705" },
      {
        "name": "Formulas and products for nutritional support",
        "code": "42231800"
      },
      {
        "name": "Adult supplemental formulas for general use",
        "code": "42231801"
      },
      { "name": "Pediatric supplemental formulas", "code": "42231802" },
      {
        "name": "Adult disease specific supplemental formulas",
        "code": "42231803"
      },
      {
        "name": "Pediatric disease specific supplemental formulas",
        "code": "42231804"
      },
      {
        "name": "Nutritional bars or pudding or other supplements",
        "code": "42231805"
      },
      {
        "name": "Medical nutrition food or liquid thickeners",
        "code": "42231806"
      },
      { "name": "Nursing bottle nipples", "code": "42231807" },
      { "name": "Feeding bottles or accessories", "code": "42231808" },
      { "name": "Breast feeding equipment and supplies", "code": "42231900" },
      { "name": "Breast pumps or its accessories", "code": "42231901" },
      { "name": "Breast shells or shields", "code": "42231902" },
      { "name": "Breast pump kits", "code": "42231903" },
      { "name": "Breast feeding pillow", "code": "42231904" },
      {
        "name": "Tablet crushers and cutters and related products",
        "code": "42232000"
      },
      { "name": "Tablet crushers or accessories", "code": "42232001" },
      {
        "name": "Tablet crusher dispensers or accessories",
        "code": "42232002"
      },
      { "name": "Tablet cutters or accessories", "code": "42232003" },
      {
        "name": "Orthopedic and prosthetic and sports medicine products",
        "code": "42240000"
      },
      { "name": "Casting and splinting supplies", "code": "42241500" },
      { "name": "Cast footwear", "code": "42241501" },
      { "name": "Cast or splint padding materials", "code": "42241502" },
      { "name": "Cast or splint protectors", "code": "42241503" },
      { "name": "Cast or splint stockinet or liners", "code": "42241504" },
      { "name": "Orthopedic casting rolls or tapes", "code": "42241505" },
      { "name": "Orthopedic casting material for splints", "code": "42241506" },
      { "name": "Orthopedic splint systems", "code": "42241507" },
      { "name": "Thermoplastic orthoses components", "code": "42241509" },
      { "name": "Thermoplastic splint kits or materials", "code": "42241510" },
      { "name": "Traction splint sets", "code": "42241511" },
      { "name": "Cast or splint bonding materials", "code": "42241512" },
      {
        "name": "Carrier and storage cases for splints or precut splints or splint systems",
        "code": "42241513"
      },
      {
        "name": "Instrument rolls for splint sets or accessories",
        "code": "42241514"
      },
      { "name": "Cases for splint or accessories", "code": "42241515" },
      { "name": "Marker for splinting material", "code": "42241516" },
      { "name": "Cast spreader", "code": "42241517" },
      {
        "name": "Casting equipment and parts and accessories",
        "code": "42241600"
      },
      { "name": "Cast or splint carts", "code": "42241601" },
      { "name": "Cast cutters or saws", "code": "42241602" },
      { "name": "Cast removal systems", "code": "42241603" },
      { "name": "Cast vacuums", "code": "42241604" },
      { "name": "Cast impression trays", "code": "42241606" },
      { "name": "Cast stands", "code": "42241607" },
      {
        "name": "Orthopedic softgoods for lower extremity",
        "code": "42241700"
      },
      { "name": "Ankle or foot orthopedic softgoods", "code": "42241701" },
      { "name": "Hip orthopedic softgoods", "code": "42241702" },
      { "name": "Knee brace or support", "code": "42241703" },
      { "name": "Knee immobilizers or arthroscopic wraps", "code": "42241704" },
      { "name": "Leg orthopedic softgoods or accessories", "code": "42241705" },
      { "name": "Orthotics or foot care products", "code": "42241706" },
      { "name": "Walking braces", "code": "42241707" },
      { "name": "Femoral fracture pillows", "code": "42241708" },
      { "name": "Post operative shoes or accessories", "code": "42241709" },
      {
        "name": "Orthopedic softgoods for upper extremity and torso",
        "code": "42241800"
      },
      { "name": "Arm orthopedic softgoods", "code": "42241801" },
      {
        "name": "Back or lumbar or sacral orthopedic softgoods",
        "code": "42241802"
      },
      { "name": "Cervical collars or neck braces", "code": "42241803" },
      { "name": "Clavicle orthopedic softgoods", "code": "42241804" },
      { "name": "Elbow orthopedic softgoods", "code": "42241805" },
      {
        "name": "Forearm or wrist or thumb orthopedic softgoods",
        "code": "42241806"
      },
      { "name": "Hand or finger orthopedic softgoods", "code": "42241807" },
      { "name": "Ribs or abdomen orthopedic softgoods", "code": "42241808" },
      { "name": "Shoulder orthopedic softgoods", "code": "42241809" },
      { "name": "Hernia truss", "code": "42241811" },
      {
        "name": "Outrigger and dynamic splinting supplies",
        "code": "42241900"
      },
      { "name": "Outrigger splint kits", "code": "42241901" },
      { "name": "Outrigger splint parts or accessories", "code": "42241902" },
      {
        "name": "Prosthetic devices or accessories and supplies",
        "code": "42242000"
      },
      { "name": "Lower extremity prosthetic devices", "code": "42242001" },
      { "name": "Upper extremity prosthetic devices", "code": "42242002" },
      {
        "name": "Prosthetic clamping devices or accessories",
        "code": "42242003"
      },
      { "name": "Prosthesis stump sock or cover", "code": "42242004" },
      {
        "name": "Orthopedic traction supplies and accessories",
        "code": "42242100"
      },
      { "name": "Arm traction supplies", "code": "42242101" },
      { "name": "Hand or finger traction supplies", "code": "42242102" },
      { "name": "Head or neck traction supplies", "code": "42242103" },
      { "name": "Leg traction supplies", "code": "42242104" },
      { "name": "Mobile traction carts", "code": "42242105" },
      { "name": "Pelvis or back traction supplies", "code": "42242106" },
      { "name": "Pivotal traction therapy supplies", "code": "42242107" },
      { "name": "Orthopedic traction hardware or weights", "code": "42242108" },
      {
        "name": "Orthopedic traction softgoods for general use",
        "code": "42242109"
      },
      { "name": "Orthopedic equipment and supplies", "code": "42242300" },
      { "name": "Orthopedic scar pumps", "code": "42242301" },
      { "name": "Orthopedic upper limb appliances", "code": "42242302" },
      {
        "name": "Physical and occupational therapy and rehabilitation products",
        "code": "42250000"
      },
      {
        "name": "Cognitive and dexterity and perceptual and sensory evaluation and therapy products",
        "code": "42251500"
      },
      { "name": "Dressing education products", "code": "42251501" },
      {
        "name": "Cognitive or dexterity or perceptual or sensory evaluation or testing products",
        "code": "42251502"
      },
      { "name": "Therapeutic games", "code": "42251503" },
      {
        "name": "Therapeutic pegboards or activity boards",
        "code": "42251504"
      },
      { "name": "Therapeutic puzzles", "code": "42251505" },
      { "name": "Therapeutic decorating boxes", "code": "42251506" },
      {
        "name": "Rehabilitation exercise devices and equipment",
        "code": "42251600"
      },
      {
        "name": "Balance beams or boards or bolsters or rockers for rehabilitation or therapy",
        "code": "42251601"
      },
      {
        "name": "Climbing devices for rehabilitation or therapy",
        "code": "42251602"
      },
      {
        "name": "Continuous passive motion CPM devices or accessories",
        "code": "42251603"
      },
      {
        "name": "Extremity exercise skates for rehabilitation or therapy",
        "code": "42251604"
      },
      {
        "name": "Pedal exercisers for rehabilitation or therapy",
        "code": "42251605"
      },
      {
        "name": "Powder boards for rehabilitation or therapy",
        "code": "42251606"
      },
      {
        "name": "Pulleys or accessories for rehabilitation or therapy",
        "code": "42251607"
      },
      {
        "name": "Resistive exercise bands or putty or tubing or accessories for rehabilitation or therapy",
        "code": "42251608"
      },
      {
        "name": "Skateboards or figure eight boards for rehabilitation or therapy",
        "code": "42251609"
      },
      { "name": "Therapeutic balls or accessories", "code": "42251610" },
      {
        "name": "Vestibular motion devices for rehabilitation or therapy",
        "code": "42251611"
      },
      {
        "name": "Weights or sets or accessories for rehabilitation or therapy",
        "code": "42251612"
      },
      {
        "name": "Treadmill exercisers for rehabilitation or therapy",
        "code": "42251613"
      },
      {
        "name": "Sandbags or sandbag sets for rehabilitation or therapy",
        "code": "42251614"
      },
      {
        "name": "Weight belts or kits for rehabilitation or therapy",
        "code": "42251615"
      },
      {
        "name": "Electric vibrators for rehabilitation or therapy",
        "code": "42251616"
      },
      {
        "name": "Therapeutic cushion seats or accessories",
        "code": "42251617"
      },
      {
        "name": "Wrist exercisers for rehabilitation or therapy",
        "code": "42251618"
      },
      { "name": "Therapeutic ceramic clay or accessories", "code": "42251619" },
      {
        "name": "Mats or platforms for rehabilitation or therapy",
        "code": "42251620"
      },
      {
        "name": "Boot exercisers for rehabilitation or therapy",
        "code": "42251621"
      },
      {
        "name": "Lung exercisers for rehabilitation or therapy",
        "code": "42251622"
      },
      {
        "name": "Ultrasonic therapy apparatus or supplies",
        "code": "42251623"
      },
      {
        "name": "Weight machines for rehabilitation or therapy",
        "code": "42251624"
      },
      { "name": "Jaw exercise equipment", "code": "42251625" },
      { "name": "Gait training products", "code": "42251700" },
      {
        "name": "Gait belts for rehabilitation or therapy",
        "code": "42251701"
      },
      {
        "name": "Training ramps for rehabilitation or therapy",
        "code": "42251702"
      },
      {
        "name": "Training stairs for rehabilitation or therapy",
        "code": "42251703"
      },
      { "name": "Gait bars for rehabilitation or therapy", "code": "42251704" },
      {
        "name": "Parallel bars for rehabilitation or therapy",
        "code": "42251705"
      },
      {
        "name": "Gait training walkers or bikers or exercisers or accessories",
        "code": "42251706"
      },
      {
        "name": "Work hardening equipment for rehabilitation or therapy",
        "code": "42251800"
      },
      {
        "name": "Bolt boards or accessories for rehabilitation or therapy",
        "code": "42251801"
      },
      {
        "name": "Lift boxes or accessories for rehabilitation or therapy",
        "code": "42251802"
      },
      {
        "name": "Pipe trees or accessories fore rehabilitation or therapy",
        "code": "42251803"
      },
      {
        "name": "Push or pull carts or accessories for rehabilitation or therapy",
        "code": "42251804"
      },
      {
        "name": "Work tables or stations or accessories for rehabilitation or therapy",
        "code": "42251805"
      },
      {
        "name": "Postmortem and mortuary equipment and supplies",
        "code": "42260000"
      },
      {
        "name": "Pathology dissection instruments and supplies",
        "code": "42261500"
      },
      { "name": "Autopsy scissors", "code": "42261501" },
      {
        "name": "Autopsy dissection forceps for general use",
        "code": "42261502"
      },
      { "name": "Autopsy bullet probes", "code": "42261503" },
      { "name": "Autopsy thread or needle pullers", "code": "42261504" },
      { "name": "Autopsy knives or blades", "code": "42261505" },
      { "name": "Autopsy chisels or osteotomes", "code": "42261506" },
      { "name": "Postmortem thread", "code": "42261507" },
      { "name": "Postmortem needles", "code": "42261508" },
      { "name": "Autopsy dissection kits", "code": "42261509" },
      { "name": "Postmortem incision clips", "code": "42261510" },
      { "name": "Autopsy vein directors", "code": "42261511" },
      { "name": "Autopsy saws", "code": "42261512" },
      { "name": "Autopsy saw blades or accessories", "code": "42261513" },
      { "name": "Dissection boards or pads", "code": "42261514" },
      {
        "name": "Cases for postmortem surgical instruments or accessories",
        "code": "42261515"
      },
      {
        "name": "Instrument rolls for postmortem surgical instruments or accessories",
        "code": "42261516"
      },
      { "name": "Autopsy equipment and supplies", "code": "42261600" },
      { "name": "Bone dust collectors", "code": "42261601" },
      { "name": "Medical body bag", "code": "42261602" },
      { "name": "Autopsy head rests", "code": "42261604" },
      { "name": "Autopsy body boards", "code": "42261605" },
      { "name": "Autopsy hanging scales", "code": "42261606" },
      { "name": "Autopsy specimen bags or containers", "code": "42261607" },
      { "name": "Autopsy infectious disease kits", "code": "42261608" },
      {
        "name": "Postmortem identification tags or bracelets",
        "code": "42261609"
      },
      {
        "name": "Autopsy fluid collection vacuum aspirators or tubing",
        "code": "42261610"
      },
      { "name": "Postmortem rectal thermometers", "code": "42261611" },
      { "name": "Postmortem finger straighteners", "code": "42261612" },
      { "name": "Cadaver tissue builder kits", "code": "42261613" },
      { "name": "Autopsy furniture", "code": "42261700" },
      {
        "name": "Autopsy grossing workstations or accessories",
        "code": "42261701"
      },
      { "name": "Autopsy sinks or accessories", "code": "42261702" },
      { "name": "Autopsy tables or accessories", "code": "42261703" },
      { "name": "Necropsy tables or accessories", "code": "42261704" },
      {
        "name": "Postmortem animal dissection tables or accessories",
        "code": "42261705"
      },
      { "name": "Embalming workstations or accessories", "code": "42261706" },
      {
        "name": "Autopsy down draft workstations or accessories",
        "code": "42261707"
      },
      {
        "name": "Cadaver transport and storage equipment and supplies",
        "code": "42261800"
      },
      { "name": "Cadaver storage racks", "code": "42261801" },
      { "name": "Cadaver carriers", "code": "42261802" },
      { "name": "Cadaver scissor lift trolleys", "code": "42261803" },
      { "name": "Morgue cabinet refrigerators", "code": "42261804" },
      { "name": "Morgue walk in refrigerators", "code": "42261805" },
      { "name": "Morgue freezers", "code": "42261806" },
      { "name": "Autopsy carts", "code": "42261807" },
      { "name": "Cadaver trays", "code": "42261808" },
      { "name": "Cadaver lifter or transfer devices", "code": "42261809" },
      { "name": "Body transport containers", "code": "42261810" },
      {
        "name": "Clinical forensics equipment and supplies",
        "code": "42261900"
      },
      {
        "name": "Postmortem fingerprint or impression materials",
        "code": "42261901"
      },
      { "name": "Antiputrefaction masks", "code": "42261902" },
      {
        "name": "Postmortem blood detection kits or supplies",
        "code": "42261903"
      },
      { "name": "Biological evidence collection kits", "code": "42261904" },
      { "name": "Embalming equipment and supplies", "code": "42262000" },
      { "name": "Embalming cavity injectors", "code": "42262001" },
      { "name": "Embalming vein drainage tubes", "code": "42262002" },
      { "name": "Embalming fluids or chemical treatments", "code": "42262003" },
      { "name": "Embalming injecting tubes", "code": "42262004" },
      { "name": "Embalming sinks or accessories", "code": "42262005" },
      { "name": "Embalming kits", "code": "42262006" },
      { "name": "Embalming injector needles", "code": "42262007" },
      { "name": "Eye caps", "code": "42262008" },
      { "name": "Mortuary equipment and supplies", "code": "42262100" },
      { "name": "Mortuary outfits", "code": "42262101" },
      { "name": "Mortuary packs", "code": "42262102" },
      { "name": "Mortuary wraps", "code": "42262103" },
      { "name": "Mortuary aspirators", "code": "42262104" },
      { "name": "Mortuary hardening compounds", "code": "42262105" },
      {
        "name": "Respiratory and anesthesia and resuscitation products",
        "code": "42270000"
      },
      { "name": "Respiratory monitoring products", "code": "42271500" },
      { "name": "Apnea monitors or accessories", "code": "42271501" },
      {
        "name": "Arterial blood gas monitors or accessories",
        "code": "42271502"
      },
      {
        "name": "End tidal carbon dioxide monitors or supplies",
        "code": "42271503"
      },
      { "name": "Esophageal stethoscopes", "code": "42271504" },
      {
        "name": "Respiratory monitoring kits or its accessories",
        "code": "42271505"
      },
      { "name": "Oxygen monitors or supplies", "code": "42271506" },
      {
        "name": "Pulmonary function testing and treatment products",
        "code": "42271600"
      },
      { "name": "Body plethysmographs", "code": "42271601" },
      {
        "name": "Spirometers or its accessories or its supplies",
        "code": "42271602"
      },
      { "name": "Bedside pulmonary function screeners", "code": "42271603" },
      { "name": "Peak flowmeters", "code": "42271604" },
      { "name": "Pulmonary function calculators", "code": "42271605" },
      { "name": "Pulmonary calibration devices", "code": "42271606" },
      {
        "name": "Pulmonary functioning tubing or accessories",
        "code": "42271607"
      },
      { "name": "Pulmonary stress test products", "code": "42271608" },
      { "name": "Sleep study monitors or accessories", "code": "42271609" },
      {
        "name": "Transcutaneous monitors or related products",
        "code": "42271610"
      },
      { "name": "Pulmonary ventilation monitors", "code": "42271611" },
      { "name": "Pulmonary gas analyzers or monitors", "code": "42271612" },
      { "name": "Pulmonary pressure monitors", "code": "42271613" },
      { "name": "Respiratory temperature monitors", "code": "42271614" },
      { "name": "Pneumotachs", "code": "42271615" },
      { "name": "Pulmonary function monitor filters", "code": "42271616" },
      { "name": "Chest percussors", "code": "42271617" },
      { "name": "Spirometer recording pens", "code": "42271618" },
      { "name": "Inspiratory muscle trainer", "code": "42271619" },
      { "name": "Lung water monitor", "code": "42271620" },
      {
        "name": "Positive expiratory pressure PEP therapy device",
        "code": "42271621"
      },
      { "name": "Airway clearance vest", "code": "42271622" },
      {
        "name": "Oxygen therapy delivery systems and devices",
        "code": "42271700"
      },
      {
        "name": "Medical gas cylinders or related devices",
        "code": "42271701"
      },
      { "name": "Oxygen concentrators", "code": "42271702" },
      { "name": "Oxygen air blenders", "code": "42271703" },
      { "name": "Oxygen timers", "code": "42271704" },
      { "name": "Oxygen delivery connectors or adapters", "code": "42271705" },
      { "name": "Respiratory therapy compressors", "code": "42271706" },
      {
        "name": "Flow sensors or regulators or components",
        "code": "42271707"
      },
      { "name": "Medical oxygen masks or parts", "code": "42271708" },
      { "name": "Medical nasal cannulae", "code": "42271709" },
      {
        "name": "Medical nasal catheters or catheterization kits",
        "code": "42271710"
      },
      { "name": "Medical head hoods", "code": "42271711" },
      { "name": "Medical aerosol tents", "code": "42271712" },
      { "name": "Medical hyperbaric chambers", "code": "42271713" },
      { "name": "Medical incubators", "code": "42271714" },
      { "name": "Medical oxygen tubing or connectors", "code": "42271715" },
      { "name": "Cases for nasal insufflators", "code": "42271716" },
      { "name": "Inhalators or sets", "code": "42271717" },
      {
        "name": "Oxygen therapy delivery system products accessories or its supplies",
        "code": "42271718"
      },
      { "name": "Oxygen insufflator or its accessories", "code": "42271719" },
      { "name": "Liquid oxygen converters", "code": "42271720" },
      { "name": "Oxygen concentrator filters", "code": "42271721" },
      { "name": "Oxygen uptake computer", "code": "42271722" },
      {
        "name": "Respiratory humidity and aerosol therapy products",
        "code": "42271800"
      },
      { "name": "Respiratory humidifiers or vaporizers", "code": "42271801" },
      { "name": "Nebulizer or accessories", "code": "42271802" },
      { "name": "Transfer sets for respiratory therapy", "code": "42271803" },
      { "name": "Airway management products", "code": "42271900" },
      { "name": "Esophageal tubes", "code": "42271902" },
      { "name": "Endotracheal tubes", "code": "42271903" },
      { "name": "Tracheostomy tubes", "code": "42271904" },
      { "name": "Endobronchial tubes", "code": "42271905" },
      {
        "name": "Endotracheal or tracheostomy tube repair kits",
        "code": "42271906"
      },
      {
        "name": "Respiratory aspirator products or accessories",
        "code": "42271907"
      },
      { "name": "Artificial airway holders", "code": "42271908" },
      { "name": "Artificial airway accessories", "code": "42271909" },
      { "name": "Endotracheal or tracheotomy sets", "code": "42271910" },
      { "name": "Respiratory manometer kits", "code": "42271911" },
      { "name": "Nasopharyngeal tubes", "code": "42271912" },
      { "name": "Pharyngeal airways or airways kits", "code": "42271913" },
      { "name": "Airway pressure gages", "code": "42271914" },
      { "name": "Tracheostomy accessories", "code": "42271915" },
      { "name": "Pharyngometer or accessory", "code": "42271916" },
      { "name": "Intubation supplies", "code": "42272000" },
      { "name": "Laryngoscopes or accessories", "code": "42272001" },
      { "name": "Laryngeal sprays", "code": "42272002" },
      { "name": "Bite blocks", "code": "42272003" },
      { "name": "Intubation stylets", "code": "42272004" },
      { "name": "Intubation forceps", "code": "42272005" },
      { "name": "Introducers", "code": "42272006" },
      { "name": "Bender tools", "code": "42272007" },
      { "name": "Intubation gauges or guides", "code": "42272008" },
      { "name": "Patient carbon dioxide detectors", "code": "42272009" },
      { "name": "Suction catheters or its accessories", "code": "42272011" },
      { "name": "Laryngeal keels or accessories", "code": "42272016" },
      { "name": "Intubator components or accessories", "code": "42272017" },
      {
        "name": "Negative mechanical pressure ventilators",
        "code": "42272100"
      },
      { "name": "Iron lung", "code": "42272101" },
      { "name": "Chest cuirass products", "code": "42272102" },
      {
        "name": "Positive mechanical pressure ventilators and accessories",
        "code": "42272200"
      },
      {
        "name": "Intermittent positive pressure breathing IPPB machines",
        "code": "42272201"
      },
      {
        "name": "Non invasive continuous positive air pressure machines",
        "code": "42272202"
      },
      { "name": "Non invasive bi level machines", "code": "42272203" },
      { "name": "Transport ventilators", "code": "42272204" },
      {
        "name": "Adult or pediatric intensive care ventilators",
        "code": "42272205"
      },
      { "name": "Infant intensive care ventilators", "code": "42272206" },
      { "name": "High frequency ventilators", "code": "42272207" },
      { "name": "Home care ventilators", "code": "42272208" },
      { "name": "Ventilator or breathing circuit", "code": "42272209" },
      { "name": "Breathing circuit bags", "code": "42272210" },
      { "name": "Hyperinflation products", "code": "42272211" },
      {
        "name": "Positive end expiratory pressure PEEP valves",
        "code": "42272212"
      },
      {
        "name": "Continuous positive airway pressure CPAP masks or straps",
        "code": "42272213"
      },
      {
        "name": "Circuit connectors or adapters or valves",
        "code": "42272214"
      },
      { "name": "Ventilator testing supplies", "code": "42272215" },
      { "name": "Ventilator thermometers", "code": "42272216" },
      { "name": "Ventilator water traps", "code": "42272217" },
      { "name": "Ventilator gas sampling ports or lines", "code": "42272218" },
      {
        "name": "Ventilator heat or moisture exchangers or filters",
        "code": "42272219"
      },
      { "name": "Ventilator accessories", "code": "42272220" },
      { "name": "Ventilator humidification products", "code": "42272221" },
      { "name": "Ventilator weaning products", "code": "42272222" },
      {
        "name": "Breathing apparatus accessories or supplies",
        "code": "42272223"
      },
      { "name": "Ventilator circuit kits", "code": "42272224" },
      {
        "name": "Bi level positive airway pressure Bi PAP accessories",
        "code": "42272225"
      },
      { "name": "Resuscitation supplies", "code": "42272300" },
      { "name": "Manual resuscitators", "code": "42272301" },
      { "name": "Pneumatic resuscitators", "code": "42272302" },
      { "name": "Resuscitation masks or accessories", "code": "42272303" },
      { "name": "Resuscitator components or accessories", "code": "42272304" },
      { "name": "Resuscitation connectors", "code": "42272305" },
      { "name": "Resuscitation kits", "code": "42272306" },
      {
        "name": "Cases for resuscitation apparatus or accessories",
        "code": "42272307"
      },
      {
        "name": "Anesthesia apparatus and accessories and supplies",
        "code": "42272500"
      },
      { "name": "Gas anesthesia apparatus", "code": "42272501" },
      {
        "name": "Absorber units for gas anesthesia apparatus",
        "code": "42272502"
      },
      {
        "name": "Anesthesia inhalers or inhaler units or accessories",
        "code": "42272503"
      },
      {
        "name": "Gas anesthesia apparatus tubes or tubing assemblies or tube fittings or accessories",
        "code": "42272505"
      },
      { "name": "Anesthesia apparatus screen filters", "code": "42272506" },
      {
        "name": "Temperature control for anesthesia apparatus",
        "code": "42272507"
      },
      {
        "name": "Anesthesia machine calibrators or accessories",
        "code": "42272509"
      },
      {
        "name": "Anesthesia machine upgrade kits or accessories",
        "code": "42272510"
      },
      { "name": "Medical sterilization products", "code": "42280000" },
      {
        "name": "Autoclave and sterilizer equipment and accessories",
        "code": "42281500"
      },
      { "name": "Chemical or gas sterilizers", "code": "42281501" },
      { "name": "Dry heat or hot air sterilizers", "code": "42281502" },
      { "name": "Filter sterilizers", "code": "42281503" },
      { "name": "Glass bead sterilizers", "code": "42281504" },
      {
        "name": "Lifting handles for sterilizer containers or trays",
        "code": "42281505"
      },
      {
        "name": "Powered instrument cleaning devices or accessories",
        "code": "42281506"
      },
      { "name": "Radiation sterilizers", "code": "42281507" },
      { "name": "Steam autoclaves or sterilizers", "code": "42281508" },
      { "name": "Sterilization containers or trays", "code": "42281509" },
      {
        "name": "Sterilization instrument clips or racks or stringers or holders",
        "code": "42281510"
      },
      { "name": "Sterilization lamps", "code": "42281511" },
      { "name": "Sterilization lids", "code": "42281512" },
      { "name": "Sterilization nameplates", "code": "42281513" },
      { "name": "Sanitizer heaters or accessories", "code": "42281514" },
      { "name": "Sterilization cabinets", "code": "42281515" },
      { "name": "Sterilization filters", "code": "42281516" },
      { "name": "Sterilization water recovery systems", "code": "42281517" },
      { "name": "Sterilization cannula bars", "code": "42281518" },
      { "name": "Needle sterilizers", "code": "42281519" },
      { "name": "Sterilization sets", "code": "42281521" },
      {
        "name": "Sterilization instruments or sterilization cases inserts",
        "code": "42281522"
      },
      { "name": "Sterilization filter sleeves", "code": "42281523" },
      {
        "name": "Sterilization adapters or adapter assemblies",
        "code": "42281524"
      },
      {
        "name": "Sterilization gas or chemical or radiation aerator",
        "code": "42281525"
      },
      { "name": "Biological indicator test incubator", "code": "42281526" },
      {
        "name": "Sterilizer sterilant concentrate or cassette or cartridge",
        "code": "42281527"
      },
      { "name": "Chemical or gas sterilizer accessories", "code": "42281528" },
      {
        "name": "Dry heat or hot air sterilizer accessories",
        "code": "42281529"
      },
      {
        "name": "Steam autoclave or sterilizer accessories",
        "code": "42281530"
      },
      { "name": "Ultraviolet sterilizer", "code": "42281531" },
      { "name": "Gas plasma sterilizer", "code": "42281532" },
      {
        "name": "Cold sterilization and disinfectant solutions",
        "code": "42281600"
      },
      { "name": "Instrument disinfectant or sterilant", "code": "42281603" },
      { "name": "Medical surface disinfectants", "code": "42281604" },
      { "name": "Medical antisetting compounds", "code": "42281605" },
      { "name": "Medical gas fumigators", "code": "42281606" },
      {
        "name": "Disinfecting or presterilization cleaning equipment and solutions",
        "code": "42281700"
      },
      {
        "name": "Chamber cleaners for autoclaves or sterilizers",
        "code": "42281701"
      },
      { "name": "Disinfectant soaking pans", "code": "42281702" },
      { "name": "Instrument care kits", "code": "42281703" },
      { "name": "Instrument cleaners or detergents", "code": "42281704" },
      {
        "name": "Medical equipment and instrument disinfectant washing equipment",
        "code": "42281705"
      },
      { "name": "Instrument lubricants or milk", "code": "42281706" },
      { "name": "Instrument stain remover pads", "code": "42281707" },
      { "name": "Sterilization cart cleaners", "code": "42281708" },
      { "name": "Sterilization cleaning brushes", "code": "42281709" },
      { "name": "Sterilization deodorants", "code": "42281710" },
      { "name": "Sterilization liquid descalers", "code": "42281711" },
      { "name": "Ultrasonic cleaning equipment", "code": "42281712" },
      { "name": "Sterilization drain pans", "code": "42281713" },
      { "name": "Sterilization indicators and controls", "code": "42281800" },
      { "name": "Disinfectant test strips", "code": "42281801" },
      { "name": "Sterilization labels", "code": "42281802" },
      { "name": "Sterilization biological kit", "code": "42281803" },
      { "name": "Sterilization controls", "code": "42281804" },
      { "name": "Sterilization indicator records", "code": "42281805" },
      { "name": "Sterilization indicator strips", "code": "42281806" },
      { "name": "Sterilization indicator tapes", "code": "42281807" },
      { "name": "Sterilization papers or sheets", "code": "42281808" },
      { "name": "Sterilization record storage envelopes", "code": "42281809" },
      {
        "name": "Sterilization test packs and accessories",
        "code": "42281810"
      },
      { "name": "Sterilization container lock", "code": "42281811" },
      {
        "name": "Sterilization wraps and packaging supplies",
        "code": "42281900"
      },
      {
        "name": "Holders or carts for sterilization wrappers or pouches",
        "code": "42281901"
      },
      { "name": "Sterilization wrap or overwrap", "code": "42281902" },
      { "name": "Sterilization dust covers", "code": "42281903" },
      { "name": "Sterilization pouches", "code": "42281904" },
      { "name": "Sterilization heat sealers", "code": "42281905" },
      { "name": "Sterilization instrument bands", "code": "42281906" },
      { "name": "Sterilization instrument protectors", "code": "42281907" },
      { "name": "Sterilization instrument tray liners", "code": "42281908" },
      {
        "name": "Sterilization labeling guns or tapes or pens",
        "code": "42281909"
      },
      { "name": "Sterilization towels", "code": "42281912" },
      { "name": "Sterilization tubing", "code": "42281913" },
      { "name": "Sterilization disposable containers", "code": "42281914" },
      { "name": "Sterilization reels", "code": "42281915" },
      { "name": "Sterilization bags", "code": "42281916" },
      { "name": "Surgical products", "code": "42290000" },
      {
        "name": "Surgical bone biopsy instruments and related products",
        "code": "42291500"
      },
      {
        "name": "Surgical bone biopsy mills or related products",
        "code": "42291501"
      },
      { "name": "Surgical bone biopsy trephines", "code": "42291502" },
      {
        "name": "Surgical cutting instruments and snares and related products",
        "code": "42291600"
      },
      {
        "name": "Laser surgery scalpels or knives or knife handles",
        "code": "42291601"
      },
      {
        "name": "Surgical bolt or cable or pin or wire cutter instruments",
        "code": "42291602"
      },
      { "name": "Surgical bone cutting forceps", "code": "42291603" },
      {
        "name": "Surgical bone hand saws or wire saws or saw handles",
        "code": "42291604"
      },
      { "name": "Surgical broaches", "code": "42291605" },
      { "name": "Surgical chisels or gouges", "code": "42291606" },
      { "name": "Surgical curettes or loops", "code": "42291607" },
      {
        "name": "Surgical cutting blocks or boards or platforms",
        "code": "42291608"
      },
      { "name": "Surgical nippers", "code": "42291609" },
      { "name": "Surgical planes", "code": "42291610" },
      { "name": "Surgical rasps", "code": "42291611" },
      { "name": "Surgical rongeurs", "code": "42291612" },
      {
        "name": "Surgical scalpels or knives or blades or trephines or accessories",
        "code": "42291613"
      },
      { "name": "Surgical scissors", "code": "42291614" },
      { "name": "Surgical shears", "code": "42291615" },
      { "name": "Surgical snares or snare wires", "code": "42291616" },
      { "name": "Surgical spuds", "code": "42291617" },
      { "name": "Surgical tomes", "code": "42291619" },
      {
        "name": "Surgical trocars for general use or accessories",
        "code": "42291620"
      },
      { "name": "Finger ring removers", "code": "42291621" },
      { "name": "Adenotomes", "code": "42291622" },
      { "name": "Periosteotomes", "code": "42291623" },
      { "name": "Meniscotomes", "code": "42291624" },
      { "name": "Infant heel incision instruments", "code": "42291625" },
      { "name": "Surgical burr or its accessories", "code": "42291627" },
      {
        "name": "Surgical hand drills and reamers and puncturing instruments and accessories and related products",
        "code": "42291700"
      },
      {
        "name": "Surgical hand or twist drills or drill kits or accessories",
        "code": "42291701"
      },
      { "name": "Surgical hand reamers or awls", "code": "42291702" },
      { "name": "Surgical perforators", "code": "42291703" },
      {
        "name": "Surgical punches or punch holder or accessories",
        "code": "42291704"
      },
      { "name": "Surgical reamer adapters", "code": "42291705" },
      { "name": "Craniotomy kits", "code": "42291707" },
      { "name": "Surgical drill bit or accessories", "code": "42291708" },
      { "name": "Surgical saw blades or accessories", "code": "42291709" },
      { "name": "Amnio hook", "code": "42291710" },
      {
        "name": "Surgical clamps and forceps and surgical ligators and related instruments",
        "code": "42291800"
      },
      {
        "name": "Surgical band ligator appliers or bands or related products",
        "code": "42291801"
      },
      {
        "name": "Surgical clamps or clips or forceps or accessories",
        "code": "42291802"
      },
      { "name": "Surgical laser clamps or forceps", "code": "42291803" },
      { "name": "Circumcision clamp or bell", "code": "42291804" },
      { "name": "Newborn umbilical cord clamp", "code": "42291805" },
      {
        "name": "Surgical instrument and tube holders and positioners",
        "code": "42291900"
      },
      {
        "name": "Surgical instrument holders or positioners",
        "code": "42291901"
      },
      { "name": "Surgical tube holders or positioners", "code": "42291902" },
      { "name": "Surgical mirrors", "code": "42292000" },
      {
        "name": "Surgical otolaryngological mirrors or accessories",
        "code": "42292001"
      },
      {
        "name": "Surgical inserters and extractors and related products",
        "code": "42292100"
      },
      { "name": "Surgical inserters or inserters kits", "code": "42292101" },
      { "name": "Surgical extractors", "code": "42292102" },
      { "name": "Surgical chucks or keys", "code": "42292103" },
      {
        "name": "Surgical approximators and compressors and depressors and related products",
        "code": "42292200"
      },
      { "name": "Surgical approximators", "code": "42292201" },
      { "name": "Surgical compressors", "code": "42292202" },
      { "name": "Surgical depressors", "code": "42292203" },
      {
        "name": "Surgical bending irons and crimpers and pliers and tensioners and wrenches and related products",
        "code": "42292300"
      },
      { "name": "Surgical bending instruments", "code": "42292301" },
      { "name": "Surgical crimpers", "code": "42292302" },
      { "name": "Surgical pliers", "code": "42292303" },
      { "name": "Surgical tensioners", "code": "42292304" },
      { "name": "Surgical vice grips", "code": "42292305" },
      {
        "name": "Surgical wire holding forceps or twisters",
        "code": "42292306"
      },
      { "name": "Surgical wrenches", "code": "42292307" },
      {
        "name": "Surgical taps and drivers and related products",
        "code": "42292400"
      },
      { "name": "Surgical taps", "code": "42292401" },
      {
        "name": "Surgical drivers or its parts or accessories",
        "code": "42292402"
      },
      { "name": "Surgical ratchet handles", "code": "42292403" },
      {
        "name": "Surgical hammers and mallets and impactors and presses and related products",
        "code": "42292500"
      },
      { "name": "Surgical tamps", "code": "42292501" },
      { "name": "Surgical hammers or mallets", "code": "42292502" },
      { "name": "Surgical impactors or packers", "code": "42292503" },
      { "name": "Surgical presses", "code": "42292504" },
      { "name": "Surgical hammer or mallet caps", "code": "42292505" },
      {
        "name": "Surgical dilators and probes and grooves and related products",
        "code": "42292600"
      },
      { "name": "Surgical dilators or accessories", "code": "42292601" },
      { "name": "Surgical grooves", "code": "42292602" },
      { "name": "Surgical probes or directors", "code": "42292603" },
      {
        "name": "Surgical dissectors and elevators and picks and related products",
        "code": "42292700"
      },
      { "name": "Surgical dissectors", "code": "42292701" },
      { "name": "Surgical elevators or levers", "code": "42292702" },
      { "name": "Surgical lifters", "code": "42292703" },
      { "name": "Surgical picks", "code": "42292704" },
      { "name": "Surgical marking instruments", "code": "42292800" },
      { "name": "Ophthalmic marking instruments", "code": "42292801" },
      {
        "name": "Surgical marking instruments for general use",
        "code": "42292802"
      },
      { "name": "Biopsy sealing and marking device", "code": "42292803" },
      {
        "name": "Suture and surgical tissue closure instruments and related products",
        "code": "42292900"
      },
      { "name": "Surgical cerclage instruments", "code": "42292901" },
      { "name": "Surgical laser needle holders", "code": "42292902" },
      { "name": "Surgical needle holders for general use", "code": "42292903" },
      {
        "name": "Surgical suture or wire passers or related products",
        "code": "42292904"
      },
      { "name": "Skin stretching systems", "code": "42292907" },
      { "name": "Surgical purstring devices", "code": "42292908" },
      {
        "name": "Surgical measuring devices and related products",
        "code": "42293000"
      },
      { "name": "Surgical calipers or rulers", "code": "42293001" },
      { "name": "Surgical measuring gauges or rods", "code": "42293002" },
      { "name": "Surgical graft measuring instruments", "code": "42293003" },
      {
        "name": "Surgical sizing instruments for general use",
        "code": "42293004"
      },
      { "name": "Surgical valve sizing instruments", "code": "42293005" },
      { "name": "Surgical measuring tapes", "code": "42293006" },
      {
        "name": "Surgical retractors and related products",
        "code": "42293100"
      },
      { "name": "Laser surgery retractors", "code": "42293101" },
      { "name": "Surgical retraction hooks", "code": "42293102" },
      { "name": "Surgical lighted fiberoptic retractors", "code": "42293103" },
      { "name": "Surgical mouth gags or accessories", "code": "42293104" },
      { "name": "Surgical rake retractors", "code": "42293105" },
      { "name": "Surgical retractor sets", "code": "42293106" },
      { "name": "Surgical retractors for general use", "code": "42293107" },
      { "name": "Surgical stabilizers", "code": "42293108" },
      { "name": "Surgical tissue protectors", "code": "42293109" },
      { "name": "Orthopedic retractors", "code": "42293110" },
      { "name": "Ophthalmic retractors", "code": "42293111" },
      { "name": "Cardiovascular or thoracic retractors", "code": "42293112" },
      { "name": "Vein retractors", "code": "42293113" },
      { "name": "Oral retractors", "code": "42293114" },
      { "name": "Tracheal retractors", "code": "42293115" },
      { "name": "Rectal retractors", "code": "42293116" },
      { "name": "Gastrointestinal retractors", "code": "42293117" },
      { "name": "Uterine retractors", "code": "42293118" },
      { "name": "Abdominal retractors", "code": "42293119" },
      { "name": "Spine or neuro retractors", "code": "42293120" },
      { "name": "Gland retractors", "code": "42293121" },
      { "name": "Ear retractors", "code": "42293122" },
      { "name": "Plastic surgery retractors", "code": "42293123" },
      { "name": "Nerve retractors", "code": "42293124" },
      { "name": "Sternum retractors", "code": "42293125" },
      { "name": "Amputation retractors", "code": "42293126" },
      { "name": "Tissue retractors", "code": "42293127" },
      { "name": "Skin retractors", "code": "42293128" },
      { "name": "Microsurgical retractors", "code": "42293129" },
      { "name": "Lung retractors", "code": "42293130" },
      { "name": "Eyelid retractors", "code": "42293131" },
      { "name": "Finger retractors", "code": "42293132" },
      { "name": "Surgical retractor rings", "code": "42293133" },
      { "name": "Cervical retractors", "code": "42293134" },
      { "name": "Lip retractors", "code": "42293135" },
      { "name": "Retractor adapters", "code": "42293136" },
      { "name": "Orthopedic retractor blades", "code": "42293137" },
      {
        "name": "Surgical urology retractors or its accessories",
        "code": "42293138"
      },
      { "name": "Retractor attachments", "code": "42293139" },
      { "name": "Surgical myoma instruments", "code": "42293200" },
      { "name": "Surgical myoma screws", "code": "42293201" },
      {
        "name": "Surgical distractors and spreaders and separators and related products",
        "code": "42293300"
      },
      { "name": "Surgical distractors or accessories", "code": "42293301" },
      { "name": "Surgical separators", "code": "42293302" },
      { "name": "Surgical specula", "code": "42293303" },
      { "name": "Surgical spreaders", "code": "42293304" },
      {
        "name": "Surgical manipulators and implant positioners and related products",
        "code": "42293400"
      },
      { "name": "Surgical guides", "code": "42293401" },
      { "name": "Surgical implant holders", "code": "42293403" },
      { "name": "Surgical pushers", "code": "42293404" },
      { "name": "Surgical manipulating instruments", "code": "42293405" },
      { "name": "Surgical implant positioners", "code": "42293406" },
      { "name": "Ureteral or urethral filiform", "code": "42293407" },
      { "name": "Urethral or ureteral follower", "code": "42293408" },
      {
        "name": "Surgical suction and irrigation cannulas and tips and stylets and related products",
        "code": "42293500"
      },
      {
        "name": "Surgical irrigation or suction handpiece or cannula or tip",
        "code": "42293501"
      },
      {
        "name": "Surgical laser suction or irrigation cannulas or tips or related products",
        "code": "42293502"
      },
      {
        "name": "Surgical vacuum extraction devices or curettes or related products",
        "code": "42293503"
      },
      {
        "name": "Ophthalmic irrigation or aspiration supplies or accessories",
        "code": "42293504"
      },
      { "name": "Surgical suction drain probes", "code": "42293505" },
      { "name": "Surgical suction bulbs", "code": "42293506" },
      { "name": "Surgical suction reservoirs", "code": "42293507" },
      {
        "name": "Ear nose and throat ENT irrigation or aspiration supplies or accessories",
        "code": "42293508"
      },
      { "name": "Surgical irrigation sets or accessories", "code": "42293509" },
      {
        "name": "Surgical bougies and sounds and obturators and related products",
        "code": "42293600"
      },
      { "name": "Surgical bougies", "code": "42293601" },
      { "name": "Surgical obturators", "code": "42293602" },
      { "name": "Surgical sounds", "code": "42293603" },
      {
        "name": "Surgical crushers and excavators and morselizers and related products",
        "code": "42293700"
      },
      { "name": "Surgical crushers", "code": "42293701" },
      { "name": "Surgical excavators", "code": "42293702" },
      { "name": "Surgical morselizers", "code": "42293703" },
      {
        "name": "Surgical passers and searchers and tunnelers and strippers and related products",
        "code": "42293800"
      },
      { "name": "Surgical passers", "code": "42293801" },
      { "name": "Surgical searchers", "code": "42293802" },
      { "name": "Surgical strippers", "code": "42293803" },
      { "name": "Surgical tunnelers or accessories", "code": "42293804" },
      {
        "name": "Surgical wound packing instruments and related products",
        "code": "42293900"
      },
      { "name": "Surgical laparotomy rings", "code": "42293901" },
      { "name": "Surgical wound packing instruments", "code": "42293902" },
      {
        "name": "Surgical spatulas and spoons and scoops and related products",
        "code": "42294000"
      },
      { "name": "Surgical scoops", "code": "42294001" },
      { "name": "Surgical spatulas", "code": "42294002" },
      { "name": "Surgical spoons", "code": "42294003" },
      {
        "name": "Surgical skeletal traction devices and related products",
        "code": "42294100"
      },
      {
        "name": "Surgical traction bows or pin tractor loops or related products",
        "code": "42294101"
      },
      {
        "name": "Surgical skull traction devices or related products",
        "code": "42294102"
      },
      { "name": "Surgical traction halters", "code": "42294103" },
      {
        "name": "Surgical instrument sets and systems and trays",
        "code": "42294200"
      },
      {
        "name": "Cardiovascular or thoracic surgical instrument sets",
        "code": "42294201"
      },
      {
        "name": "External fixation instrument sets or systems",
        "code": "42294202"
      },
      { "name": "General surgical instrument sets", "code": "42294203" },
      {
        "name": "Micro or delicate or plastic surgery instrument sets",
        "code": "42294204"
      },
      { "name": "Neurosurgical or spinal instrument sets", "code": "42294205" },
      { "name": "Ophthalmic surgery instrument sets", "code": "42294206" },
      { "name": "Maxillofacial surgical instrument sets", "code": "42294207" },
      {
        "name": "Orthopedic revision or total joint instrument systems",
        "code": "42294208"
      },
      {
        "name": "Orthopedic trauma fixation instrument systems",
        "code": "42294209"
      },
      {
        "name": "Otolaryngological surgical instrument sets",
        "code": "42294210"
      },
      {
        "name": "Surgical custom or specialty instrument or procedure trays",
        "code": "42294211"
      },
      { "name": "Urological surgical instrument sets", "code": "42294212" },
      { "name": "Laparotomy surgical instrument sets", "code": "42294213" },
      { "name": "Tracheotomy surgical instrument sets", "code": "42294214" },
      { "name": "Craniotomy surgical instrument sets", "code": "42294215" },
      { "name": "Angiography surgical instrument sets", "code": "42294216" },
      { "name": "Gastroscopy surgical instrument sets", "code": "42294217" },
      {
        "name": "Ear nose and throat ENT surgical instrument sets",
        "code": "42294218"
      },
      { "name": "Orthopedic surgical instrument sets", "code": "42294219" },
      { "name": "Blood recovery and delivery systems", "code": "42294220" },
      {
        "name": "Minimally invasive breast biopsy instruments and supplies and equipment",
        "code": "42294300"
      },
      {
        "name": "Minimally invasive breast biopsy drivers or firing modules or accessories",
        "code": "42294301"
      },
      {
        "name": "Minimally invasive breast biopsy premium loading units",
        "code": "42294302"
      },
      {
        "name": "Minimally invasive breast biopsy needle guides",
        "code": "42294303"
      },
      {
        "name": "Minimally invasive breast biopsy marker instruments",
        "code": "42294304"
      },
      {
        "name": "Minimally invasive breast biopsy vacuum units or accessories",
        "code": "42294305"
      },
      { "name": "Breast localization needle", "code": "42294306" },
      { "name": "Vascular and cardiac systems", "code": "42294400" },
      { "name": "Vein harvest kit or system", "code": "42294401" },
      { "name": "Coronary visualization systems", "code": "42294402" },
      {
        "name": "Ophthalmic specialty instruments and related products",
        "code": "42294500"
      },
      {
        "name": "Conformers or shields for ophthalmic surgery",
        "code": "42294501"
      },
      { "name": "Eyelid weights for ophthalmic surgery", "code": "42294502" },
      { "name": "Fixation rings for ophthalmic surgery", "code": "42294503" },
      {
        "name": "Intraocular membrane instruments for ophthalmic surgery",
        "code": "42294504"
      },
      { "name": "Lid plates for ophthalmic surgery", "code": "42294505" },
      { "name": "Nucleus rotators for ophthalmic surgery", "code": "42294506" },
      {
        "name": "Ophthalmic burs or handles or rust ring removers",
        "code": "42294507"
      },
      {
        "name": "Ophthalmic needle irrigating or aspirating tips",
        "code": "42294508"
      },
      { "name": "Needles for ophthalmic surgery", "code": "42294509" },
      { "name": "Sponges for ophthalmic surgery", "code": "42294510" },
      {
        "name": "Ophthalmic surgical knives or blades or scissors or accessories",
        "code": "42294511"
      },
      { "name": "Eye protector or its accessories", "code": "42294512" },
      { "name": "Ophthalmic vitrectomy kits", "code": "42294513" },
      { "name": "Hemostatic eraser probes", "code": "42294514" },
      { "name": "Ophthalmic lens polishers", "code": "42294515" },
      { "name": "Eye holders or accessories", "code": "42294516" },
      { "name": "Optical inserts", "code": "42294517" },
      { "name": "Optical insert fitting sets", "code": "42294518" },
      { "name": "Ophthalmic spoons or curettes", "code": "42294519" },
      { "name": "Ophthalmic lens holders or accessories", "code": "42294520" },
      { "name": "Scleral buckling components", "code": "42294521" },
      { "name": "Ophthalmic plug punctum sets", "code": "42294522" },
      {
        "name": "Ophthalmic plastic surgery supplies or its related products",
        "code": "42294523"
      },
      { "name": "Eyemagnets for ophthalmic surgery", "code": "42294524" },
      { "name": "Ophthalmic medical instrument sets", "code": "42294525" },
      { "name": "Lachrymal dilators or sets", "code": "42294526" },
      { "name": "Intraocular gas", "code": "42294527" },
      {
        "name": "Viscoelastic agent or viscosurgical device",
        "code": "42294528"
      },
      { "name": "Cystotome", "code": "42294529" },
      { "name": "Vitrectomy lens", "code": "42294530" },
      { "name": "Ophthalmic laser lens", "code": "42294531" },
      { "name": "Autotransfusion products", "code": "42294600" },
      { "name": "Autotransfusion blood or transfer bags", "code": "42294601" },
      {
        "name": "Autotransfusion bowl kit or centrifugal kit",
        "code": "42294602"
      },
      { "name": "Autotransfusion units", "code": "42294603" },
      { "name": "Autotransfusion filters", "code": "42294604" },
      {
        "name": "Autotransfusion reservoir or its accessories",
        "code": "42294605"
      },
      { "name": "Autotransfusion tubing sets or kits", "code": "42294606" },
      { "name": "Autotransfusion valves", "code": "42294607" },
      {
        "name": "Open heart perfusion equipment and monitors and accessories and related products",
        "code": "42294700"
      },
      { "name": "Heart and lung machines or accessories", "code": "42294701" },
      {
        "name": "Intraaortic balloon pump and accessories",
        "code": "42294702"
      },
      {
        "name": "Intracardiac suction devices or accessories",
        "code": "42294703"
      },
      { "name": "Perfusion filters or related products", "code": "42294704" },
      {
        "name": "Perfusion blood parameter monitors or accessories or related products",
        "code": "42294705"
      },
      { "name": "Perfusion bubble traps", "code": "42294706" },
      { "name": "Perfusion cardioplegia sets", "code": "42294707" },
      { "name": "Perfusion cardiotomy reservoirs", "code": "42294708" },
      {
        "name": "Perfusion centrifugal systems or accessories",
        "code": "42294709"
      },
      {
        "name": "Perfusion heater or cooler or dual heater and cooler equipment or accessories",
        "code": "42294710"
      },
      {
        "name": "Perfusion haemoconcentrators or accessories",
        "code": "42294711"
      },
      {
        "name": "Perfusion oxygen or hematocrit saturation monitors or accessories",
        "code": "42294712"
      },
      { "name": "Perfusion oxygenators or accessories", "code": "42294713" },
      { "name": "Perfusion pump heads", "code": "42294714" },
      { "name": "Perfusion pump pack tubing", "code": "42294715" },
      { "name": "Perfusion venous reservoirs", "code": "42294716" },
      { "name": "Ventricular assist devices", "code": "42294717" },
      { "name": "Perfusion pumps", "code": "42294718" },
      { "name": "Cardiovascular reservoirs", "code": "42294719" },
      { "name": "Temperature monitoring needles", "code": "42294720" },
      { "name": "Aortic punches", "code": "42294721" },
      { "name": "Ventricular drainage sets", "code": "42294722" },
      { "name": "Intraaortic balloon pump insertion kit", "code": "42294723" },
      { "name": "Intraaortic balloon pump introducer", "code": "42294724" },
      {
        "name": "Endoscopes and accessories and related products",
        "code": "42294800"
      },
      {
        "name": "Rigid endoscopes or accessories or related products",
        "code": "42294801"
      },
      {
        "name": "Flexible endoscopes or accessories or related products",
        "code": "42294802"
      },
      { "name": "Cystourethroscopes", "code": "42294803" },
      { "name": "Resectoscopes", "code": "42294804" },
      { "name": "Laparoscopes or laparoscopic telescopes", "code": "42294805" },
      { "name": "Cystoscopes", "code": "42294806" },
      { "name": "Endoscopic sphincterotome", "code": "42294807" },
      { "name": "Esophagoscopes or its accessories", "code": "42294808" },
      {
        "name": "Endoscopic instruments and supplies and accessories and related products",
        "code": "42294900"
      },
      {
        "name": "Endoscope or instrument positioners or holders",
        "code": "42294901"
      },
      { "name": "Endoscopic applicators or elevators", "code": "42294902" },
      { "name": "Endoscopic aspiration or biopsy needles", "code": "42294903" },
      { "name": "Endoscopic bite blocks or straps", "code": "42294904" },
      {
        "name": "Endoscopic cleaning brushes or related products",
        "code": "42294905"
      },
      { "name": "Endoscopic cutting instrument", "code": "42294906" },
      {
        "name": "Endoscopic cytology or microbiology brushes",
        "code": "42294907"
      },
      {
        "name": "Endoscopic clamp or dissector or grasper or forceps",
        "code": "42294908"
      },
      {
        "name": "Endoscopic dilators or inflation devices",
        "code": "42294909"
      },
      { "name": "Endoscopic monopolar or bipolar cable", "code": "42294910" },
      {
        "name": "Endoscopic fluid management systems or accessories",
        "code": "42294912"
      },
      {
        "name": "Endoscopic hemostatic balloons or needles or tubes or accessories",
        "code": "42294913"
      },
      { "name": "Endoscopic instrument sets", "code": "42294914" },
      { "name": "Endoscopic instrument spreaders", "code": "42294915" },
      { "name": "Endoscopic insufflation filters", "code": "42294916" },
      { "name": "Endoscopic insufflation needles", "code": "42294917" },
      { "name": "Endoscopic insufflation tubing", "code": "42294918" },
      { "name": "Endoscopic guidewire or glidewire", "code": "42294919" },
      {
        "name": "Endoscopic laser instruments or accessories",
        "code": "42294920"
      },
      {
        "name": "Endoscopic knot pushers or delivery systems",
        "code": "42294921"
      },
      { "name": "Endoscopic ligators", "code": "42294922" },
      { "name": "Endoscopic manipulators", "code": "42294923" },
      {
        "name": "Endoscopic monopolar or bipolar hand instrument or accessories",
        "code": "42294924"
      },
      { "name": "Endoscopic needles or punches", "code": "42294925" },
      { "name": "Endoscopic overtubes", "code": "42294926" },
      {
        "name": "Endoscopic instrument packs or trays or kits",
        "code": "42294927"
      },
      { "name": "Endoscopic probes", "code": "42294928" },
      { "name": "Endoscopic retractors", "code": "42294929" },
      {
        "name": "Endoscopic snares or snare wires or accessories",
        "code": "42294930"
      },
      { "name": "Endoscopic specimen retrieval forceps", "code": "42294931" },
      {
        "name": "Endoscopic suction or irrigation tips or coagulation probes or accessories",
        "code": "42294933"
      },
      { "name": "Endoscopic suturing device", "code": "42294934" },
      {
        "name": "Endoscopic trocar or sheath or obturator or cannula",
        "code": "42294935"
      },
      {
        "name": "Endoscopic working elements or working channels",
        "code": "42294936"
      },
      {
        "name": "Fog reduction devices for endoscopes or mirrors",
        "code": "42294937"
      },
      { "name": "Sealing caps for endoscopes", "code": "42294938" },
      { "name": "Endoscopic valves or accessories", "code": "42294939" },
      { "name": "Endoscopic converters", "code": "42294940" },
      { "name": "Endoscopic biliary drainage sets", "code": "42294941" },
      { "name": "Endoscopic instrument seals", "code": "42294942" },
      { "name": "Endoscopic valve units", "code": "42294943" },
      { "name": "Endoscopic accessory kits", "code": "42294944" },
      { "name": "Endoscopic sponges", "code": "42294945" },
      { "name": "Endoscopic gages", "code": "42294946" },
      { "name": "Endoscopic diaphragms", "code": "42294947" },
      { "name": "Endoscopic mouthpieces", "code": "42294948" },
      { "name": "Endoscopic guidewire handles", "code": "42294949" },
      { "name": "Endoscopic drills or drill bits", "code": "42294950" },
      { "name": "Endoscopic small joint instrument sets", "code": "42294951" },
      { "name": "Endoscopic retrievers or sets", "code": "42294952" },
      { "name": "Endoscopic extractors", "code": "42294953" },
      {
        "name": "Endoscopic tissue or specimen removing devices or accessories",
        "code": "42294954"
      },
      { "name": "Endoscopic hooks or accessories", "code": "42294955" },
      { "name": "Endoscopic guidewire tracers", "code": "42294956" },
      { "name": "Endoscopic shaver blade or accessories", "code": "42294957" },
      {
        "name": "Endoscopic vessel sealing and cutting attachments and accessories",
        "code": "42294958"
      },
      {
        "name": "Endoscopic equipment and accessories and related products",
        "code": "42295000"
      },
      {
        "name": "Endoscope maintenance units or accessories",
        "code": "42295001"
      },
      {
        "name": "Endoscope storage cabinets or accessories",
        "code": "42295002"
      },
      { "name": "Endoscope wall hangers or accessories", "code": "42295003" },
      {
        "name": "Endoscopic equipment or procedure carts or accessories",
        "code": "42295004"
      },
      { "name": "Endoscopic equipment sets", "code": "42295005" },
      {
        "name": "Endoscopic heater probe units or heater probes or accessories",
        "code": "42295006"
      },
      {
        "name": "Endoscopic imaging systems or accessories",
        "code": "42295007"
      },
      {
        "name": "Endoscopic insufflation or distention units or accessories",
        "code": "42295008"
      },
      {
        "name": "Endoscopic or surgical light sources or accessories",
        "code": "42295009"
      },
      { "name": "Endoscopic printers or accessories", "code": "42295010" },
      {
        "name": "Endoscopic video cameras or recorders or adapters or accessories",
        "code": "42295011"
      },
      { "name": "Endoscopic water bottles or accessories", "code": "42295012" },
      { "name": "Endoscope tip protector or covers", "code": "42295013" },
      { "name": "Endoscopic instrument cases", "code": "42295014" },
      { "name": "Endoscopic lenses", "code": "42295015" },
      { "name": "Surgical or endoscopic video cable", "code": "42295016" },
      {
        "name": "Surgical equipment and accessories and related products",
        "code": "42295100"
      },
      { "name": "Basin stands for surgical use", "code": "42295101" },
      { "name": "Cryosurgery equipment or accessories", "code": "42295102" },
      {
        "name": "Delivery room or cesarean section patient procedure tables or accessories or related products",
        "code": "42295103"
      },
      {
        "name": "Electrosurgical or electrocautery equipment",
        "code": "42295104"
      },
      {
        "name": "Instrument tables for surgical or obstetrical delivery use or accessories or related products",
        "code": "42295105"
      },
      {
        "name": "Lap mayo trays or mayo stands for surgical use or accessories",
        "code": "42295106"
      },
      {
        "name": "Operating room case carts or procedure carts or wall racks or accessories",
        "code": "42295107"
      },
      {
        "name": "Operating room patient fracture tables or orthopedic tables or accessories or related products",
        "code": "42295108"
      },
      {
        "name": "Operating room kick buckets or accessories or related products",
        "code": "42295109"
      },
      {
        "name": "Operating room patient positioning devices or accessories",
        "code": "42295111"
      },
      {
        "name": "Operating room patient procedure tables or accessories or related products",
        "code": "42295112"
      },
      {
        "name": "Phacoemulsification or extrusion equipment or accessories for ophthalmic surgery",
        "code": "42295114"
      },
      { "name": "Surgeon stools or accessories", "code": "42295115" },
      {
        "name": "Step stools for surgical use or accessories",
        "code": "42295116"
      },
      {
        "name": "Surgical irrigation pump equipment or pulsed lavage or accessories with or without suction",
        "code": "42295118"
      },
      { "name": "Surgical lasers or accessories", "code": "42295119" },
      { "name": "Surgical lithotripters or accessories", "code": "42295120" },
      {
        "name": "Surgical microscopes or loops or magnifiers or accessories",
        "code": "42295121"
      },
      {
        "name": "Surgical pneumatic or electric tourniquets or accessories",
        "code": "42295122"
      },
      {
        "name": "Surgical suction machines or vacuum extractors or ultrasonic surgical aspirators or regulators or accessories",
        "code": "42295123"
      },
      {
        "name": "Surgical smoke evacuators or accessories",
        "code": "42295124"
      },
      {
        "name": "Surgical urological tables or accessories",
        "code": "42295125"
      },
      {
        "name": "Vitreo retinal fragmatome surgery equipment or accessories for ophthalmic surgery",
        "code": "42295126"
      },
      { "name": "Microsurgery equipment or accessories", "code": "42295127" },
      {
        "name": "Maxillofacial surgical instruments or accessories",
        "code": "42295128"
      },
      {
        "name": "Operating room medication dispensers or related products",
        "code": "42295129"
      },
      {
        "name": "Surgical instrument connecting tubes or accessories",
        "code": "42295130"
      },
      {
        "name": "Surgical equipment stands or accessories",
        "code": "42295131"
      },
      {
        "name": "Surgical instrument cases or accessories",
        "code": "42295132"
      },
      { "name": "Orthopedic pin racks or accessories", "code": "42295133" },
      {
        "name": "General surgical supply sets or accessories",
        "code": "42295134"
      },
      {
        "name": "Orthopedic target devices or accessories",
        "code": "42295135"
      },
      { "name": "Surgical controller instruments", "code": "42295136" },
      {
        "name": "Gastroenterology equipment or supplies or accessories",
        "code": "42295137"
      },
      {
        "name": "Surgical urological dilation devices or accessories",
        "code": "42295138"
      },
      {
        "name": "Instrument rolls for surgical instruments or accessories",
        "code": "42295139"
      },
      { "name": "Surgical instrument cabinets or chests", "code": "42295140" },
      { "name": "Intraoperative blood salvage kit", "code": "42295141" },
      { "name": "Intraoperative blood salvage machine", "code": "42295142" },
      {
        "name": "Electrosurgical or electrocautery accessories or attachments",
        "code": "42295143"
      },
      { "name": "Microwave surgical unit", "code": "42295144" },
      { "name": "Needle type epilator", "code": "42295145" },
      { "name": "Tweezer type epilator", "code": "42295146" },
      { "name": "Ultrasonic surgical instrument", "code": "42295147" },
      { "name": "Surgical navigation system", "code": "42295148" },
      {
        "name": "Surgical power equipment and accessories and related products",
        "code": "42295200"
      },
      {
        "name": "Surgical dermatomes or dermabraders or dermameshers or accessories",
        "code": "42295201"
      },
      {
        "name": "Surgical pneumatic or battery or electric saws or drills or pin drivers or accessories",
        "code": "42295202"
      },
      {
        "name": "Surgical power equipment sets or accessories",
        "code": "42295203"
      },
      { "name": "Surgical reamers", "code": "42295204" },
      {
        "name": "Surgical shaver equipment or handpiece or accessories",
        "code": "42295205"
      },
      { "name": "Endoscopic surgical units", "code": "42295206" },
      { "name": "Surgical drill guide or sleeve", "code": "42295207" },
      {
        "name": "Open heart surgical supplies and accessories and related products",
        "code": "42295300"
      },
      {
        "name": "Surgical coronary artery blowers or misters or accessories",
        "code": "42295301"
      },
      { "name": "Surgical perfusion cannulas", "code": "42295302" },
      {
        "name": "Surgical perfusion catheters or connectors or accessories",
        "code": "42295303"
      },
      {
        "name": "Surgical phrenic nerve pads or heart pillows",
        "code": "42295304"
      },
      {
        "name": "Surgical tourniquets or vascular occluders or ligators or accessories",
        "code": "42295305"
      },
      {
        "name": "Surgical vessel loops or retraction tapes",
        "code": "42295306"
      },
      {
        "name": "Surgical carotid artery strippers or accessories",
        "code": "42295307"
      },
      { "name": "Coronary perfusion sets", "code": "42295308" },
      { "name": "Surgical support supplies", "code": "42295400" },
      {
        "name": "Battery operated surgical cautery pencils",
        "code": "42295401"
      },
      { "name": "Surgical marking pens", "code": "42295402" },
      {
        "name": "Introducer or guide pin or guidewire or glidewire for non endoscopic surgical or open heart procedures",
        "code": "42295405"
      },
      {
        "name": "Laparotomy or x-ray detectable or surgical specialty sponge",
        "code": "42295406"
      },
      { "name": "Patient masks for surgical use", "code": "42295407" },
      { "name": "Surgical scrub brush or accessories", "code": "42295408" },
      { "name": "Surgical applicators", "code": "42295409" },
      { "name": "Surgical basin set or pack", "code": "42295410" },
      { "name": "Surgical blade holders or breakers", "code": "42295411" },
      { "name": "Surgical canal brushes", "code": "42295413" },
      {
        "name": "Surgical custom or specialty procedure packs",
        "code": "42295414"
      },
      { "name": "Surgical equipment cover", "code": "42295415" },
      { "name": "Surgical evacuators", "code": "42295416" },
      { "name": "Surgical light handles or covers", "code": "42295417" },
      { "name": "Surgical magnetic mats", "code": "42295418" },
      {
        "name": "Surgical nerve stimulators or accessories",
        "code": "42295419"
      },
      {
        "name": "Surgical or endoscopic catheters or catheterization kits or drainage bags",
        "code": "42295420"
      },
      { "name": "Surgical prep scrub or paint solutions", "code": "42295421" },
      { "name": "Surgical scrub or prep kit for patient", "code": "42295422" },
      { "name": "Surgical sharps or sponge counters", "code": "42295423" },
      {
        "name": "Surgical shave kits or prep razors or clippers",
        "code": "42295424"
      },
      {
        "name": "Surgical silver nitrate sticks or pencils or crystals",
        "code": "42295425"
      },
      {
        "name": "Surgical specimen collection traps or containers",
        "code": "42295426"
      },
      {
        "name": "Surgical sterile instrument brushes or instrument stylets or instrument wipes",
        "code": "42295427"
      },
      {
        "name": "Surgical suction or irrigation tubing or accessories",
        "code": "42295428"
      },
      {
        "name": "Surgical transparent incise drapes or instrument pouches",
        "code": "42295431"
      },
      {
        "name": "Urological surgical catheters or accessories",
        "code": "42295433"
      },
      { "name": "Ear protectors or shields", "code": "42295435" },
      { "name": "Anastomosis rings", "code": "42295436" },
      {
        "name": "Surgeon utensil sets or cabinets or accessories",
        "code": "42295437"
      },
      { "name": "Orthopedic bone smoothers", "code": "42295439" },
      { "name": "Endoscopic catheter adapters", "code": "42295440" },
      { "name": "Surgical retriever sets", "code": "42295441" },
      { "name": "Tray inserts for surgical apparatus", "code": "42295445" },
      { "name": "Internal organ retainers", "code": "42295446" },
      { "name": "Surgical splash guards or accessories", "code": "42295448" },
      { "name": "Surgical pail carriages or accessories", "code": "42295450" },
      { "name": "Surgical preparation mits", "code": "42295451" },
      { "name": "Skin preparation cups", "code": "42295452" },
      { "name": "Surgical drains or sets or accessories", "code": "42295453" },
      { "name": "Surgical hand protectors", "code": "42295454" },
      { "name": "Cranial repair resin kits", "code": "42295455" },
      {
        "name": "Surgical felt or fabric or patch or pledget",
        "code": "42295456"
      },
      { "name": "Surgical helmet lenses or hoods", "code": "42295457" },
      {
        "name": "Drying or powdering equipment for surgical gloves",
        "code": "42295458"
      },
      {
        "name": "Fluid decanting devices for surgical use",
        "code": "42295459"
      },
      { "name": "Protective caps for orthopedic implants", "code": "42295460" },
      {
        "name": "Tissue glue or systems or applicators or accessories",
        "code": "42295461"
      },
      { "name": "Urodynamic catheters or accessories", "code": "42295462" },
      { "name": "Ureteral catheter or catheter set", "code": "42295463" },
      { "name": "Organ preservation solution", "code": "42295464" },
      { "name": "Neurophysiological monitoring system", "code": "42295465" },
      { "name": "Surgical bone cement", "code": "42295466" },
      { "name": "Surgical bone cement mixer", "code": "42295467" },
      {
        "name": "Surgical bone cement injector or accessory",
        "code": "42295468"
      },
      {
        "name": "Surgical bone cement kits or accessories",
        "code": "42295469"
      },
      { "name": "Platelet concentration system", "code": "42295470" },
      { "name": "Bone marrow aspiration system", "code": "42295471" },
      {
        "name": "Surgical implants and expanders and extenders and surgical wires and related products",
        "code": "42295500"
      },
      { "name": "Human tissue implant", "code": "42295502" },
      {
        "name": "Implantable infusion port or accessories",
        "code": "42295503"
      },
      { "name": "Opthalmic implant", "code": "42295505" },
      { "name": "Oral maxillofacial implants or sets", "code": "42295506" },
      { "name": "Otolaryngology implants or sets", "code": "42295508" },
      {
        "name": "Plastic or cosmetic implant or tissue expander or set",
        "code": "42295509"
      },
      { "name": "Surgical adhesion barrier", "code": "42295510" },
      { "name": "Surgical bone stimulator", "code": "42295511" },
      {
        "name": "Surgical implantable shunts or shunt extenders",
        "code": "42295512"
      },
      { "name": "Surgical mesh or tissue barrier", "code": "42295513" },
      { "name": "Silicone sheeting", "code": "42295514" },
      { "name": "Synthetic tissue implants", "code": "42295515" },
      { "name": "Urological implant or set", "code": "42295516" },
      { "name": "Cardiovascular extenders", "code": "42295517" },
      { "name": "Graft protectors", "code": "42295518" },
      { "name": "Preparation kit for penile prosthesis", "code": "42295519" },
      { "name": "Ventriculostomy kit", "code": "42295520" },
      { "name": "Gastrointestinal implant", "code": "42295521" },
      {
        "name": "Implantable nerve stimulator system or kit or accessories",
        "code": "42295522"
      },
      { "name": "Gynecological implant or sling", "code": "42295523" },
      { "name": "Intraocular lens IOL", "code": "42295524" },
      { "name": "Biological tissue implant", "code": "42295525" },
      { "name": "Hair implant system", "code": "42295526" },
      {
        "name": "Implantable intrathecal infusion pump and accessories",
        "code": "42295527"
      },
      {
        "name": "Cerebral spinal fluid CSF drainage products and accessories",
        "code": "42295600"
      },
      {
        "name": "Cerebral ventricular drainage catheter or adapter",
        "code": "42295601"
      },
      {
        "name": "External cerebral ventricular drainage bag or reservoir",
        "code": "42295602"
      },
      { "name": "Lumbar cerebrospinal fluid drain or kit", "code": "42295603" },
      {
        "name": "Surgical thermo ablation systems and accessories",
        "code": "42295800"
      },
      { "name": "Surgical thermo ablation cassette", "code": "42295801" },
      { "name": "Surgical thermo ablation tubing set", "code": "42295802" },
      { "name": "Surgical thermo ablation catheter", "code": "42295803" },
      { "name": "Surgical stents", "code": "42295900" },
      { "name": "Gastrointestinal stent", "code": "42295901" },
      { "name": "Tracheal stents", "code": "42295902" },
      { "name": "Urological stents", "code": "42295903" },
      { "name": "Vaginal or uterine stents", "code": "42295904" },
      { "name": "Endoprosthesis or wall stents or tubes", "code": "42295905" },
      { "name": "Esophageal stent", "code": "42295906" },
      { "name": "Cardiovascular implants", "code": "42296000" },
      { "name": "Aortic valve", "code": "42296001" },
      { "name": "Cardiovascular conduit", "code": "42296002" },
      { "name": "Mitral valve", "code": "42296003" },
      { "name": "Tricuspid valve", "code": "42296004" },
      { "name": "Annuloplasty ring", "code": "42296005" },
      { "name": "Vascular graft", "code": "42296006" },
      { "name": "Cardiovascular occluder", "code": "42296007" },
      { "name": "Pulmonary valve", "code": "42296008" },
      { "name": "Neurosurgical implants", "code": "42296100" },
      { "name": "Implantable aneurysm clip", "code": "42296101" },
      { "name": "Temporary aneurysm clip", "code": "42296102" },
      { "name": "Dural replacement or repair device", "code": "42296103" },
      { "name": "Cranial plate or bur hole cover", "code": "42296104" },
      { "name": "Cranial mesh", "code": "42296105" },
      { "name": "Nerve repair graft", "code": "42296106" },
      { "name": "Cranial clamp", "code": "42296107" },
      { "name": "Cerebral aneurysm encapsulating kit", "code": "42296108" },
      {
        "name": "Surgical robotic equipment and accessories and related products",
        "code": "42296200"
      },
      {
        "name": "Surgical robotic clip application instrument",
        "code": "42296201"
      },
      { "name": "Surgical robotic dissection instrument", "code": "42296202" },
      {
        "name": "Surgical robotic electrocautery instrument",
        "code": "42296203"
      },
      { "name": "Surgical robotic graspers or forceps", "code": "42296204" },
      { "name": "Surgical robotic hook instrument", "code": "42296205" },
      {
        "name": "Surgical robotic knot pusher or delivery system",
        "code": "42296206"
      },
      { "name": "Surgical robotic needle driver", "code": "42296207" },
      { "name": "Surgical robotic retractor instrument", "code": "42296208" },
      { "name": "Surgical robotic scalpel and blade", "code": "42296209" },
      { "name": "Surgical robotic scissors", "code": "42296210" },
      {
        "name": "Surgical robotic ultrasonic energy instrument",
        "code": "42296211"
      },
      {
        "name": "Surgical robotic trocar or sheath or obturator or cannula",
        "code": "42296212"
      },
      { "name": "Medical training and education supplies", "code": "42300000" },
      { "name": "Aids for medical training", "code": "42301500" },
      {
        "name": "Anatomical human models for medical education or training",
        "code": "42301501"
      },
      {
        "name": "Anatomical human mannequins for medical education or training",
        "code": "42301502"
      },
      {
        "name": "Cardio pulmonary resuscitation CPR training aids",
        "code": "42301503"
      },
      { "name": "Kits for medical education or training", "code": "42301504" },
      { "name": "Nursing or medical clipboards", "code": "42301505" },
      { "name": "Dual earpiece stethoscopes", "code": "42301506" },
      {
        "name": "Training videos for medical staff education",
        "code": "42301507"
      },
      {
        "name": "Operational or instructional videos for medical equipment",
        "code": "42301508"
      },
      { "name": "Wound care products", "code": "42310000" },
      {
        "name": "Bandages and dressings and related products",
        "code": "42311500"
      },
      { "name": "Clips for bandages or dressings", "code": "42311501" },
      { "name": "Bandage or dressing starter kits", "code": "42311502" },
      { "name": "Bandage rollers", "code": "42311503" },
      { "name": "Bandages or dressings for burn care", "code": "42311504" },
      { "name": "Bandages or dressings for general use", "code": "42311505" },
      { "name": "Compression bandages or kits", "code": "42311506" },
      { "name": "Dressing trays", "code": "42311508" },
      { "name": "Foam dressings", "code": "42311510" },
      { "name": "Gauze bandages", "code": "42311511" },
      { "name": "Gauze sponges", "code": "42311512" },
      { "name": "Gel dressings", "code": "42311513" },
      { "name": "Germicidal dressings", "code": "42311514" },
      { "name": "Hydrocolloid dressings", "code": "42311515" },
      {
        "name": "Liquid adhesives for bandages or dressings",
        "code": "42311517"
      },
      { "name": "Medical eye pads or patches", "code": "42311518" },
      { "name": "Medical non adherent straps", "code": "42311519" },
      { "name": "Medical non adherent tapes", "code": "42311520" },
      { "name": "Negative pressure dressings", "code": "42311521" },
      { "name": "Occlusive dressings", "code": "42311522" },
      { "name": "Paste bandages", "code": "42311523" },
      { "name": "Petrolatum dressings", "code": "42311524" },
      { "name": "Transparent film dressings", "code": "42311527" },
      { "name": "Wet dressing systems", "code": "42311528" },
      { "name": "Dressing covers", "code": "42311531" },
      { "name": "Bandage scissors or its supplies", "code": "42311537" },
      { "name": "Hyperalimentation kits", "code": "42311538" },
      { "name": "Bandage applicators", "code": "42311539" },
      { "name": "Alginate dressing", "code": "42311540" },
      { "name": "Biological dressing", "code": "42311541" },
      { "name": "Odor control dressing", "code": "42311542" },
      { "name": "Dressing retainer", "code": "42311543" },
      { "name": "Exogenous topical hemostatic agents", "code": "42311600" },
      { "name": "Absorbable gelatin sponge", "code": "42311601" },
      { "name": "Bone wax", "code": "42311602" },
      {
        "name": "Oxidized cellulose or polysaccharide based hemostatic products",
        "code": "42311603"
      },
      {
        "name": "Collagen hemostatics or microfibrillar collagen",
        "code": "42311604"
      },
      { "name": "Thrombin non-absorbable patch", "code": "42311605" },
      {
        "name": "Medical and surgical adherent tapes and related products for specialty use",
        "code": "42311700"
      },
      { "name": "Infant umbilical tapes", "code": "42311702" },
      {
        "name": "Medical or surgical tapes for skin attachment",
        "code": "42311703"
      },
      { "name": "Medical or surgical tape dispensers", "code": "42311704" },
      { "name": "Medical or surgical tape removers", "code": "42311705" },
      { "name": "Surgical tissue tapes", "code": "42311707" },
      {
        "name": "Medical and surgical adherent tapes for general use",
        "code": "42311708"
      },
      {
        "name": "Medical incision drains and drainage bags and reservoirs and related products",
        "code": "42311900"
      },
      { "name": "Medical incision drain accessories", "code": "42311901" },
      {
        "name": "Medical incision drainage bags or reservoirs",
        "code": "42311902"
      },
      { "name": "Medical incision drains", "code": "42311903" },
      {
        "name": "Medical tissue closure and related products",
        "code": "42312000"
      },
      { "name": "Butterfly skin closures", "code": "42312001" },
      { "name": "Clips for skin closure", "code": "42312002" },
      { "name": "Wound or skin closure strips", "code": "42312003" },
      { "name": "Medical adhesive or glue removers", "code": "42312004" },
      {
        "name": "Medical adhesive or glue for skin closure",
        "code": "42312005"
      },
      { "name": "Medical clip appliers for internal use", "code": "42312006" },
      { "name": "Medical clips for internal use", "code": "42312007" },
      { "name": "Medical staple or clip removers", "code": "42312008" },
      { "name": "Medical stapler for internal use", "code": "42312009" },
      { "name": "Medical stapler for external use", "code": "42312010" },
      { "name": "Medical staples for internal use", "code": "42312011" },
      { "name": "Staples for skin closure", "code": "42312012" },
      {
        "name": "Ostomy supplies and non surgical wound drainage products",
        "code": "42312100"
      },
      { "name": "Ostomy appliance adhesives", "code": "42312101" },
      { "name": "Ostomy appliances", "code": "42312102" },
      { "name": "Ostomy cleaners or deodorants", "code": "42312103" },
      { "name": "Ostomy collection supplies", "code": "42312104" },
      {
        "name": "Ostomy skin barriers or protective care kits",
        "code": "42312105"
      },
      { "name": "Ostomy inserts", "code": "42312106" },
      { "name": "Ostomy wafers", "code": "42312107" },
      { "name": "Wound drainage pouches", "code": "42312108" },
      { "name": "Ostomy bag rings", "code": "42312109" },
      { "name": "Ostomy starter kits", "code": "42312110" },
      { "name": "Ostomy bag covers", "code": "42312111" },
      { "name": "Ostomy belt or accessories", "code": "42312112" },
      { "name": "Ostomy bag plugs", "code": "42312113" },
      { "name": "Ostomy irrigation sleeves", "code": "42312115" },
      { "name": "Suture and related products", "code": "42312200" },
      { "name": "Suture", "code": "42312201" },
      { "name": "Suturing kits or trays or packs or sets", "code": "42312202" },
      {
        "name": "Suture buttons or bridges or related accessories",
        "code": "42312203"
      },
      { "name": "Suture boots and capturing devices", "code": "42312204" },
      { "name": "Suture carts or racks", "code": "42312205" },
      { "name": "Suture needles", "code": "42312206" },
      {
        "name": "Suture removal kits or trays or packs or sets",
        "code": "42312207"
      },
      { "name": "Suture removers", "code": "42312208" },
      { "name": "Wound cleaning and debridement products", "code": "42312300" },
      { "name": "Absorbers for wound cleansing", "code": "42312301" },
      { "name": "Debridement sponges", "code": "42312302" },
      {
        "name": "Pulsed lavage systems or related accessories for wound treatment",
        "code": "42312303"
      },
      { "name": "Medical autolytic debridement products", "code": "42312304" },
      { "name": "Medical enzymatic debridement products", "code": "42312305" },
      { "name": "Medical mechanical debridement products", "code": "42312306" },
      { "name": "Medical surgical debridement products", "code": "42312307" },
      { "name": "Wound irrigation systems", "code": "42312309" },
      { "name": "Cleansing bottles", "code": "42312310" },
      { "name": "Disinfectant kits", "code": "42312311" },
      { "name": "Wound care or cleansing trays", "code": "42312312" },
      { "name": "Wound cleaning solutions", "code": "42312313" },
      { "name": "Wound packing products", "code": "42312400" },
      { "name": "Calcium alginate wound packing", "code": "42312401" },
      { "name": "Nasal splints or stents", "code": "42312402" },
      { "name": "Packing strips for wound care", "code": "42312403" },
      {
        "name": "Wound supports and supplies and accessories",
        "code": "42312500"
      },
      { "name": "Mammary support binders", "code": "42312501" },
      { "name": "Abdominal binders", "code": "42312502" },
      { "name": "Scrotal supports", "code": "42312503" },
      { "name": "Facial support garment", "code": "42312504" },
      {
        "name": "Negative pressure wound care therapy products",
        "code": "42312600"
      },
      {
        "name": "Negative pressure wound therapy equipment",
        "code": "42312601"
      },
      {
        "name": "Negative pressure wound therapy system or kit",
        "code": "42312602"
      },
      { "name": "Topical wound oxygen therapy products", "code": "42312700" },
      {
        "name": "Topical wound oxygen therapy single use chamber",
        "code": "42312701"
      },
      { "name": "Orthopedic surgical implants", "code": "42320000" },
      { "name": "Orthopedic trauma implants", "code": "42321500" },
      { "name": "Surgical wire and its accessories", "code": "42321501" },
      {
        "name": "Orthopedic guidewire or guide pin or guide rod",
        "code": "42321502"
      },
      {
        "name": "Bone graft extender or void filler or substitute",
        "code": "42321503"
      },
      { "name": "Intramedullary nail or rod", "code": "42321504" },
      { "name": "Bone plate", "code": "42321505" },
      { "name": "Bone screw", "code": "42321506" },
      { "name": "Internal fixation set", "code": "42321507" },
      { "name": "Orthopedic nut", "code": "42321508" },
      { "name": "Orthopedic washer", "code": "42321509" },
      { "name": "Orthopedic cap", "code": "42321510" },
      { "name": "Bone fixation pin or peg", "code": "42321512" },
      { "name": "Temporary fixation pin or wire", "code": "42321513" },
      { "name": "Bone fixation staple", "code": "42321514" },
      { "name": "Intramedullary nail blade", "code": "42321515" },
      { "name": "Intramedullary nail locking bolt", "code": "42321516" },
      { "name": "Spinal implants", "code": "42321600" },
      { "name": "Intervertebral disc", "code": "42321601" },
      { "name": "Spinal cable", "code": "42321602" },
      { "name": "Kyphoplasty balloon", "code": "42321603" },
      { "name": "Spinal crosslinking device", "code": "42321604" },
      { "name": "Spinal interbody cage", "code": "42321605" },
      { "name": "Spinal decompression device", "code": "42321606" },
      { "name": "Spinal hook", "code": "42321607" },
      { "name": "Spinal rod", "code": "42321609" },
      { "name": "Spinal screw", "code": "42321610" },
      { "name": "Spinal plate", "code": "42321611" },
      { "name": "Spinal sleeve ring or end cap", "code": "42321612" },
      { "name": "Spinal set screw or blocker or plug", "code": "42321613" },
      { "name": "Spinal nut", "code": "42321614" },
      { "name": "Spinal saddle or cradle", "code": "42321615" },
      { "name": "Spinal connector or coupler", "code": "42321616" },
      { "name": "Spinal staple", "code": "42321617" },
      { "name": "Spinal washer", "code": "42321618" },
      { "name": "Spinal cement restrictor", "code": "42321619" },
      { "name": "Spinal bolt", "code": "42321620" },
      { "name": "Temporary spinal fixation pin or wire", "code": "42321621" },
      { "name": "Spinal implant kit or system", "code": "42321622" },
      { "name": "Hip joint implants", "code": "42321700" },
      { "name": "One piece acetabular cup", "code": "42321701" },
      { "name": "Acetabular cup shell", "code": "42321702" },
      { "name": "Acetabular cup liner", "code": "42321703" },
      { "name": "Femoral head", "code": "42321704" },
      { "name": "Femoral stem distal centralizer", "code": "42321705" },
      { "name": "Femoral hip stem extension", "code": "42321706" },
      { "name": "Total hip replacement kit or system", "code": "42321707" },
      { "name": "Femoral hip stem", "code": "42321708" },
      {
        "name": "One piece hip hemiarthroplasty prosthesis",
        "code": "42321709"
      },
      { "name": "Femoral modular hip component adapter", "code": "42321710" },
      { "name": "Resurfacing femoral component", "code": "42321711" },
      { "name": "Femoral stem proximal centralizer", "code": "42321712" },
      { "name": "Acetabular screw", "code": "42321713" },
      { "name": "Acetabular augment", "code": "42321714" },
      { "name": "Bi polar or uni polar hip component", "code": "42321715" },
      { "name": "Femoral canal cement restrictor", "code": "42321716" },
      { "name": "Hip component screw", "code": "42321717" },
      { "name": "Acetabular cage", "code": "42321718" },
      { "name": "Acetabular mesh", "code": "42321719" },
      { "name": "Acetabular screw hole cover", "code": "42321720" },
      { "name": "Acetabular hole eliminator or plug", "code": "42321721" },
      { "name": "Acetabular ring", "code": "42321722" },
      { "name": "Femoral neck", "code": "42321723" },
      { "name": "Knee joint implants", "code": "42321800" },
      { "name": "Femoral knee component", "code": "42321801" },
      { "name": "Femoral knee extension stem", "code": "42321802" },
      { "name": "Tibial extension stem", "code": "42321803" },
      { "name": "Patellar implant", "code": "42321804" },
      { "name": "Tibial baseplate", "code": "42321805" },
      { "name": "Tibial Insert", "code": "42321806" },
      { "name": "Total knee replacement kit or system", "code": "42321808" },
      { "name": "Tibial or femoral or patellar augment", "code": "42321809" },
      { "name": "Tibial baseplate screw", "code": "42321810" },
      { "name": "Augment connection screw", "code": "42321811" },
      { "name": "Knee joint spacer", "code": "42321812" },
      { "name": "Femoral knee wedge", "code": "42321813" },
      { "name": "Shoulder joint implants", "code": "42321900" },
      { "name": "Humeral head", "code": "42321902" },
      { "name": "Humeral stem", "code": "42321903" },
      { "name": "Resurfacing humeral component", "code": "42321904" },
      { "name": "Glenoid sphere", "code": "42321905" },
      { "name": "Glenoid fixation component", "code": "42321906" },
      { "name": "Humeral insert liner or cup", "code": "42321907" },
      { "name": "Humeral canal cement restrictor", "code": "42321908" },
      { "name": "Humeral shell or socket", "code": "42321909" },
      { "name": "Humeral stem centralizer or sleeve", "code": "42321910" },
      { "name": "Humeral body", "code": "42321911" },
      {
        "name": "Total or partial shoulder replacement kit or system",
        "code": "42321912"
      },
      { "name": "Distal joint orthopedic implants", "code": "42322000" },
      { "name": "Ankle joint implant", "code": "42322001" },
      { "name": "Wrist joint implant", "code": "42322002" },
      { "name": "Elbow joint implant", "code": "42322003" },
      { "name": "Finger joint implant", "code": "42322004" },
      { "name": "Toe joint implant", "code": "42322005" },
      { "name": "Tendon rod or spacer", "code": "42322006" },
      { "name": "Orthopedic trial implants", "code": "42322100" },
      { "name": "Knee trial implant", "code": "42322101" },
      { "name": "Hip trial implant", "code": "42322102" },
      { "name": "Shoulder trial implant", "code": "42322103" },
      { "name": "Distal joint trial implant", "code": "42322104" },
      { "name": "Spinal trial implant", "code": "42322105" },
      { "name": "Trauma trial implant", "code": "42322106" },
      { "name": "Soft tissue fixation products", "code": "42322200" },
      { "name": "Interference screw", "code": "42322201" },
      { "name": "Soft tissue non-suture anchor", "code": "42322202" },
      { "name": "Suture anchor", "code": "42322203" },
      { "name": "Soft tissue fixation washer", "code": "42322204" },
      { "name": "Soft tissue fixation pin", "code": "42322205" },
      { "name": "Soft tissue staple", "code": "42322206" }
    ]
  },
  {
    "name": "Information Technology Broadcasting and Telecommunications",
    "code": "43000000",
    "subcategories": [
      { "name": "Communications Devices and Accessories", "code": "43190000" },
      { "name": "Personal communication devices", "code": "43191500" },
      { "name": "Mobile phones", "code": "43191501" },
      { "name": "Pagers", "code": "43191502" },
      { "name": "Pay phones", "code": "43191503" },
      { "name": "Fixed phones", "code": "43191504" },
      { "name": "Answering machines", "code": "43191505" },
      { "name": "Special purpose telephones", "code": "43191507" },
      { "name": "Digital telephones", "code": "43191508" },
      { "name": "Analog telephones", "code": "43191509" },
      { "name": "Two way radios", "code": "43191510" },
      { "name": "IP phones", "code": "43191511" },
      {
        "name": "Digital enhanced cordless telecommunications DECT cordless phones",
        "code": "43191512"
      },
      { "name": "Video phone", "code": "43191513" },
      { "name": "Satellite phone", "code": "43191514" },
      { "name": "Speaker phone", "code": "43191515" },
      { "name": "Keyphone system", "code": "43191516" },
      {
        "name": "Personal communications device accessories or parts",
        "code": "43191600"
      },
      { "name": "Mobile phone face plates", "code": "43191601" },
      { "name": "Phone dialers", "code": "43191602" },
      { "name": "Phone extension cords", "code": "43191603" },
      { "name": "Phone face plates", "code": "43191604" },
      { "name": "Phone handset cords", "code": "43191605" },
      { "name": "Phone handsets", "code": "43191606" },
      { "name": "Phone headset ear or speaker cushions", "code": "43191607" },
      { "name": "Phone headset voice tubes", "code": "43191608" },
      { "name": "Phone headsets", "code": "43191609" },
      {
        "name": "Personal communication holders or mounts, stands",
        "code": "43191610"
      },
      { "name": "Phone line protectors", "code": "43191611" },
      { "name": "Phone rests", "code": "43191612" },
      { "name": "Phone voice converters", "code": "43191614" },
      { "name": "Vehicle handsfree phone set", "code": "43191615" },
      { "name": "Centrex phone console", "code": "43191616" },
      { "name": "Conversation recording units", "code": "43191618" },
      { "name": "Telephone signaling devices", "code": "43191619" },
      { "name": "Handset adapters", "code": "43191621" },
      { "name": "Pager modules or accessories", "code": "43191622" },
      { "name": "Pay phone coin mechanisms", "code": "43191623" },
      { "name": "Pay phone coin chutes", "code": "43191624" },
      { "name": "Pay phone coin boxes", "code": "43191625" },
      { "name": "Pay phone hoppers", "code": "43191626" },
      { "name": "Pay phone coin box vault doors", "code": "43191627" },
      { "name": "Pay phone microphone windscreens", "code": "43191628" },
      {
        "name": "Notebook or palmtop skins or face plates",
        "code": "43191629"
      },
      { "name": "Mobile phone starter kits", "code": "43191630" },
      {
        "name": "Phone or modem jack adapters or country kits or travel kits",
        "code": "43191631"
      },
      { "name": "Phone antenna", "code": "43191632" },
      { "name": "Key phone subset", "code": "43191633" },
      { "name": "Extension board for keyphone system", "code": "43191634" },
      {
        "name": "Components for information technology or broadcasting or telecommunications",
        "code": "43200000"
      },
      { "name": "System Cards", "code": "43201400" },
      { "name": "Graphics or video accelerator cards", "code": "43201401" },
      { "name": "Memory module cards", "code": "43201402" },
      { "name": "Modem cards", "code": "43201403" },
      { "name": "Network interface cards", "code": "43201404" },
      { "name": "Optical network receive cards", "code": "43201405" },
      { "name": "Optical network transmit cards", "code": "43201406" },
      { "name": "Peripheral controller cards", "code": "43201407" },
      { "name": "Wireless network interface cards", "code": "43201409" },
      { "name": "Switch ports or cards", "code": "43201410" },
      {
        "name": "Peripheral component interconnect PCI card",
        "code": "43201411"
      },
      { "name": "PCMCIA or PC card", "code": "43201412" },
      { "name": "Magnetic stripe card", "code": "43201413" },
      { "name": "Hard disk protector", "code": "43201414" },
      { "name": "Subscriber identity module SIM card", "code": "43201415" },
      { "name": "Data acquisition board", "code": "43201416" },
      { "name": "Multimedia integrated board", "code": "43201417" },
      {
        "name": "System boards processors interfaces or modules",
        "code": "43201500"
      },
      {
        "name": "Asynchronous transfer mode ATM telecommunications interface cards",
        "code": "43201501"
      },
      { "name": "Audio accelerator cards", "code": "43201502" },
      { "name": "Central processing unit CPU processors", "code": "43201503" },
      { "name": "Daughterboards", "code": "43201507" },
      {
        "name": "Dispersion compensation fiber module DCFMs",
        "code": "43201508"
      },
      { "name": "Exchange datacom modules", "code": "43201509" },
      { "name": "Motherboards", "code": "43201513" },
      { "name": "Parallel port cards", "code": "43201522" },
      { "name": "Video capture boards", "code": "43201531" },
      {
        "name": "Musical instrument digital interface MIDI interfaces",
        "code": "43201533"
      },
      { "name": "Exchange component CODEC interfaces", "code": "43201534" },
      { "name": "Serial infrared ports", "code": "43201535" },
      { "name": "Print servers", "code": "43201537" },
      { "name": "Central processing unit coolers", "code": "43201538" },
      { "name": "Console controller mainframe", "code": "43201539" },
      { "name": "Channel converter", "code": "43201540" },
      { "name": "Channel to channel interface mainframe", "code": "43201541" },
      { "name": "Control unit", "code": "43201542" },
      { "name": "Coupler facility mainframe", "code": "43201543" },
      { "name": "Interface bus converter or controller", "code": "43201544" },
      { "name": "Fax boards", "code": "43201545" },
      { "name": "Audio conferencing boards", "code": "43201546" },
      { "name": "Voice boards", "code": "43201547" },
      { "name": "Interface bus switches", "code": "43201549" },
      {
        "name": "Network packet data synchronization device",
        "code": "43201550"
      },
      { "name": "Hardware or telephony adapters", "code": "43201552" },
      { "name": "Transceivers and media converters", "code": "43201553" },
      {
        "name": "Personal computer television PC TV tuners",
        "code": "43201554"
      },
      { "name": "PC radio tuners", "code": "43201555" },
      {
        "name": "Small computer system interconnect SCSI adapters",
        "code": "43201556"
      },
      {
        "name": "Redundant array of independent disks RAID controllers",
        "code": "43201557"
      },
      { "name": "Fibre channel controller", "code": "43201558" },
      { "name": "Serial port cards", "code": "43201559" },
      { "name": "Coprocessor", "code": "43201560" },
      { "name": "Chassis components", "code": "43201600" },
      { "name": "Computer chassis", "code": "43201601" },
      { "name": "Network equipment chassis", "code": "43201602" },
      { "name": "Chassis stacking components", "code": "43201603" },
      { "name": "Electronic equipment bays or baskets", "code": "43201604" },
      { "name": "Expanders", "code": "43201605" },
      { "name": "Removable drive frames", "code": "43201608" },
      { "name": "Storage device trays or assemblies", "code": "43201609" },
      { "name": "Backplane or panels or assemblies", "code": "43201610" },
      { "name": "Computer cradles", "code": "43201611" },
      { "name": "Computer faceplates", "code": "43201612" },
      { "name": "Console extenders", "code": "43201614" },
      { "name": "Drive bay cover kits", "code": "43201615" },
      { "name": "Hard disk drive array towers", "code": "43201616" },
      { "name": "Card cages", "code": "43201617" },
      { "name": "Computer rack component", "code": "43201618" },
      { "name": "Computer cooling fan", "code": "43201619" },
      { "name": "Media storage devices", "code": "43201800" },
      { "name": "Floppy drives", "code": "43201801" },
      { "name": "Hard disk arrays", "code": "43201802" },
      { "name": "Hard disk drives", "code": "43201803" },
      { "name": "Tape arrays", "code": "43201806" },
      { "name": "Tape drives", "code": "43201807" },
      { "name": "Read only compact disc CD", "code": "43201808" },
      { "name": "Read write compact disc CD", "code": "43201809" },
      { "name": "Read only digital versatile disc DVD", "code": "43201810" },
      { "name": "Read write digital versatile disc DVD", "code": "43201811" },
      { "name": "Magneto optical MO drives", "code": "43201812" },
      { "name": "High capacity removable media drives", "code": "43201813" },
      {
        "name": "Electronic media or data duplicating equipment",
        "code": "43201814"
      },
      {
        "name": "Peripheral component microchannel interconnect architecture reader and writer drives",
        "code": "43201815"
      },
      {
        "name": "Ultra density optical UDO drives or autoloaders or libraries",
        "code": "43201816"
      },
      { "name": "Compact disc CD drive", "code": "43201817" },
      { "name": "Digital video disc DVD drive", "code": "43201818" },
      {
        "name": "Read only high definition digital versatile disc HD DVD",
        "code": "43201819"
      },
      {
        "name": "Read write high definition digital versatile disc HD DVD",
        "code": "43201820"
      },
      { "name": "Read only blu-ray disc BD", "code": "43201821" },
      { "name": "Read write blu-ray disc BD", "code": "43201822" },
      { "name": "Multimedia hard disc drive HDD player", "code": "43201823" },
      { "name": "Disc publisher", "code": "43201825" },
      {
        "name": "Compact disk read only memory CD ROM array",
        "code": "43201826"
      },
      { "name": "Portable hard disk storage device", "code": "43201827" },
      { "name": "Digital video disc audio DVD A", "code": "43201828" },
      { "name": "Digital video disc video DVD V", "code": "43201829" },
      { "name": "Solid state drive SSD", "code": "43201830" },
      { "name": "Telecommunications memory unit", "code": "43201831" },
      { "name": "Optical or compact disc juke box", "code": "43201832" },
      { "name": "Digital to analog conversion system", "code": "43201833" },
      { "name": "Storage device controller", "code": "43201834" },
      { "name": "Network attached storage NAS device", "code": "43201835" },
      { "name": "Media storage device accessories", "code": "43201900" },
      { "name": "Optical disk changers", "code": "43201902" },
      { "name": "Tape drive libraries", "code": "43201903" },
      { "name": "Digital video disc audio video DVD AV", "code": "43201904" },
      { "name": "Tape unit cleaning kit", "code": "43201905" },
      { "name": "Removable storage media", "code": "43202000" },
      { "name": "Compact disks CDs", "code": "43202001" },
      { "name": "Blank tapes", "code": "43202002" },
      { "name": "Digital versatile disks DVDs", "code": "43202003" },
      { "name": "Floppy disks", "code": "43202004" },
      { "name": "Flash memory storage card", "code": "43202005" },
      { "name": "Magneto Optical MO disks", "code": "43202006" },
      {
        "name": "High capacity removable media blank disks",
        "code": "43202007"
      },
      { "name": "Ultra density optical UDO blank disks", "code": "43202008" },
      { "name": "Blank audio tape", "code": "43202009" },
      { "name": "Pen or flash drive", "code": "43202010" },
      { "name": "Removable storage media accessories", "code": "43202100" },
      { "name": "Compact disk cases", "code": "43202101" },
      { "name": "Floppy disk cases", "code": "43202102" },
      { "name": "Multimedia storage holders", "code": "43202103" },
      {
        "name": "Vertical helix scan VHS videotape storage or accessories",
        "code": "43202104"
      },
      { "name": "Multiple media cabinets", "code": "43202105" },
      { "name": "Portable media case or wallet", "code": "43202106" },
      {
        "name": "Compact disc or digital versatile disc CD/DVD briefcase",
        "code": "43202107"
      },
      { "name": "Memory card case", "code": "43202108" },
      { "name": "Sub assemblies for electronic devices", "code": "43202200" },
      { "name": "Telephone piece parts", "code": "43202201" },
      { "name": "Telephone ring generators", "code": "43202202" },
      { "name": "External ringer or its parts", "code": "43202204" },
      { "name": "Keycaps or keytops or keys", "code": "43202205" },
      {
        "name": "Storage drive or input device components",
        "code": "43202206"
      },
      { "name": "Head mount arms and assembly", "code": "43202207" },
      { "name": "Lead assemblies", "code": "43202208" },
      { "name": "Head stack assemblies", "code": "43202209" },
      { "name": "Crash stops", "code": "43202210" },
      { "name": "Platters or disks", "code": "43202211" },
      { "name": "Read write head assemblies", "code": "43202212" },
      { "name": "Disc motor drives", "code": "43202213" },
      { "name": "Comb assemblies", "code": "43202214" },
      { "name": "Keyboard or mouse cable", "code": "43202215" },
      { "name": "Printer connection cable", "code": "43202216" },
      { "name": "Computer cable holder", "code": "43202217" },
      { "name": "Computer cable cover", "code": "43202218" },
      {
        "name": "Automotive heating ventilation air conditioning HVAC control head assembly",
        "code": "43202219"
      },
      {
        "name": "Automotive heating ventilation air conditioning HVAC power module",
        "code": "43202220"
      },
      {
        "name": "Automotive heating ventilation air conditioning HVAC resistor assembly",
        "code": "43202221"
      },
      { "name": "Computer cable", "code": "43202222" },
      { "name": "Computer Equipment and Accessories", "code": "43210000" },
      { "name": "Computers", "code": "43211500" },
      { "name": "Computer servers", "code": "43211501" },
      { "name": "High end computer servers", "code": "43211502" },
      { "name": "Notebook computers", "code": "43211503" },
      {
        "name": "Personal digital assistant PDAs or organizers",
        "code": "43211504"
      },
      { "name": "Point of sale POS terminal", "code": "43211505" },
      { "name": "Thin client computers", "code": "43211506" },
      { "name": "Desktop computers", "code": "43211507" },
      { "name": "Personal computers", "code": "43211508" },
      { "name": "Tablet computers", "code": "43211509" },
      { "name": "Mainframe console or dumb terminals", "code": "43211510" },
      { "name": "Wearable computing devices", "code": "43211511" },
      { "name": "Mainframe computers", "code": "43211512" },
      { "name": "Ultra mobile personal computer", "code": "43211513" },
      { "name": "Computer kiosk", "code": "43211514" },
      { "name": "Computer workstation", "code": "43211515" },
      { "name": "Minicomputer", "code": "43211516" },
      { "name": "Analog computer", "code": "43211517" },
      { "name": "Multi screen computer", "code": "43211518" },
      { "name": "Electronic reader or E-reader", "code": "43211519" },
      { "name": "Barebone computer", "code": "43211520" },
      { "name": "All in one desktop computer", "code": "43211521" },
      { "name": "Computer accessories", "code": "43211600" },
      { "name": "Computer switch boxes", "code": "43211601" },
      { "name": "Docking stations", "code": "43211602" },
      { "name": "Port replicators", "code": "43211603" },
      { "name": "Peripheral switch boxes", "code": "43211604" },
      { "name": "Signal processor upgrades", "code": "43211605" },
      { "name": "Multimedia kits", "code": "43211606" },
      { "name": "Computer speakers", "code": "43211607" },
      { "name": "Encoder decoder equipment", "code": "43211608" },
      { "name": "Universal serial bus hubs or connectors", "code": "43211609" },
      { "name": "Computer country or localization kits", "code": "43211610" },
      { "name": "Handheld PDA Starter Kits", "code": "43211611" },
      { "name": "Computer accessory kits", "code": "43211612" },
      { "name": "Computer or notebook stands", "code": "43211613" },
      {
        "name": "Bluetooth universal serial bus USB adapter",
        "code": "43211614"
      },
      { "name": "Notebook computer expansion dock", "code": "43211615" },
      { "name": "Personal digital assistant PDA holder", "code": "43211616" },
      {
        "name": "Universal serial bus USB extension cable",
        "code": "43211617"
      },
      {
        "name": "Universal serial bus general packet radio service USB GPRS modem",
        "code": "43211618"
      },
      { "name": "Notebook computer carrying case", "code": "43211619" },
      { "name": "Computer data input devices", "code": "43211700" },
      { "name": "Bar code reader equipment", "code": "43211701" },
      { "name": "Magnetic stripe readers and encoders", "code": "43211702" },
      { "name": "Currency recognition equipment", "code": "43211704" },
      { "name": "Game pads or joy sticks", "code": "43211705" },
      { "name": "Keyboards", "code": "43211706" },
      { "name": "Light stylus", "code": "43211707" },
      { "name": "Computer mouse or trackballs", "code": "43211708" },
      { "name": "Pressure stylus", "code": "43211709" },
      { "name": "Radio frequency identification devices", "code": "43211710" },
      { "name": "Scanners", "code": "43211711" },
      { "name": "Graphics tablets", "code": "43211712" },
      { "name": "Touch pads", "code": "43211713" },
      { "name": "Biometric identification equipment", "code": "43211714" },
      { "name": "Portable data input terminals", "code": "43211715" },
      { "name": "Optical character recognition systems", "code": "43211717" },
      {
        "name": "Camera based vision systems for automated data collection",
        "code": "43211718"
      },
      { "name": "Voice microphones for computers", "code": "43211719" },
      { "name": "Point of sale payment terminal", "code": "43211720" },
      { "name": "Punch card readers", "code": "43211721" },
      { "name": "Business card scanner", "code": "43211722" },
      {
        "name": "Electronic voting or vote-counting equipment",
        "code": "43211723"
      },
      { "name": "Computer peripheral kit", "code": "43211724" },
      {
        "name": "Compact disc or digital versatile disc CD/DVD cleaner",
        "code": "43211725"
      },
      { "name": "Digital pen", "code": "43211726" },
      {
        "name": "Magnetic ink character recognition MICR device",
        "code": "43211727"
      },
      { "name": "Smart card dummy terminal", "code": "43211728" },
      { "name": "Optical mark reader", "code": "43211729" },
      { "name": "Data acquisition system", "code": "43211730" },
      { "name": "Image analyzer", "code": "43211731" },
      { "name": "Digital film reader", "code": "43211732" },
      { "name": "Computer data input device accessories", "code": "43211800" },
      { "name": "Computer data input device covers", "code": "43211801" },
      { "name": "Mouse pads", "code": "43211802" },
      { "name": "Key board skins", "code": "43211803" },
      { "name": "Keyboard drawers or shelves", "code": "43211804" },
      { "name": "Service kits for storage devices", "code": "43211805" },
      { "name": "Keyboard wrist rest", "code": "43211806" },
      { "name": "Mouse wrist rest", "code": "43211807" },
      { "name": "Computer displays", "code": "43211900" },
      { "name": "Cathode ray tube CRT monitors", "code": "43211901" },
      {
        "name": "Liquid crystal display LCD panels or monitors",
        "code": "43211902"
      },
      { "name": "Touch screen monitors", "code": "43211903" },
      { "name": "Plasma display panels PDP", "code": "43211904" },
      { "name": "Organic light emitting displays", "code": "43211905" },
      { "name": "Character displays", "code": "43211906" },
      { "name": "Head mounted displays", "code": "43211907" },
      { "name": "Vacuum fluorescent display", "code": "43211908" },
      { "name": "Poly light emitting diode LED display", "code": "43211909" },
      { "name": "Display tilt mechanism", "code": "43211910" },
      { "name": "Touchscreen glass", "code": "43211911" },
      { "name": "Touchscreen film", "code": "43211912" },
      { "name": "LCD active display", "code": "43211913" },
      { "name": "LCD passive display", "code": "43211914" },
      { "name": "Computer display accessories", "code": "43212000" },
      { "name": "Computer display glare screens", "code": "43212001" },
      { "name": "Monitor arms or stands", "code": "43212002" },
      { "name": "Monitor components", "code": "43212003" },
      { "name": "Computer printers", "code": "43212100" },
      { "name": "Band printers", "code": "43212101" },
      { "name": "Dot matrix printers", "code": "43212102" },
      { "name": "Dye sublimination printers", "code": "43212103" },
      { "name": "Inkjet printers", "code": "43212104" },
      { "name": "Laser printers", "code": "43212105" },
      { "name": "Line matrix printers", "code": "43212106" },
      { "name": "Plotter printers", "code": "43212107" },
      { "name": "Thermal tape printers", "code": "43212108" },
      { "name": "Bag tag printer", "code": "43212109" },
      { "name": "Multi function printers", "code": "43212110" },
      {
        "name": "Airline ticket or boarding pass ATB printers",
        "code": "43212111"
      },
      { "name": "Point of sale POS receipt printers", "code": "43212112" },
      { "name": "Compact disc CD or labeling printers", "code": "43212113" },
      { "name": "Digital image printers", "code": "43212114" },
      { "name": "Bar code printer", "code": "43212115" },
      {
        "name": "Radio frequency identification RFID tag printer",
        "code": "43212116"
      },
      { "name": "Printer controller", "code": "43212117" },
      {
        "name": "Computer data storage management systems",
        "code": "43212200"
      },
      { "name": "Storage virtualization system", "code": "43212201" },
      {
        "name": "Data Voice or Multimedia Network Equipment or Platforms and Accessories",
        "code": "43220000"
      },
      { "name": "Call management systems or accessories", "code": "43221500" },
      { "name": "Automated attendant systems", "code": "43221501" },
      { "name": "Automatic call distributor ACD", "code": "43221502" },
      { "name": "Telecom announcers", "code": "43221503" },
      { "name": "Premise branch exchange PBX systems", "code": "43221504" },
      {
        "name": "Standalone telephone caller identification",
        "code": "43221505"
      },
      { "name": "Teleconferencing console", "code": "43221506" },
      { "name": "Autodialers", "code": "43221507" },
      { "name": "Telephone busy lamp fields", "code": "43221508" },
      { "name": "Telephone call accounting systems", "code": "43221509" },
      { "name": "Telephone call diverter or forwarder", "code": "43221510" },
      { "name": "Telephone call sequencers", "code": "43221513" },
      { "name": "Telephone dial backup units", "code": "43221514" },
      { "name": "Telephone line sharing devices", "code": "43221515" },
      { "name": "Telephone line status monitors", "code": "43221516" },
      {
        "name": "Telephony equipment service observing units",
        "code": "43221517"
      },
      { "name": "Telephony equipment toll restrictors", "code": "43221518" },
      { "name": "Voice mail systems", "code": "43221519" },
      { "name": "Interactive voice recognition equipment", "code": "43221520" },
      { "name": "Telecommunications remote access unit", "code": "43221521" },
      { "name": "Teleconference equipment", "code": "43221522" },
      { "name": "Music or message on hold player", "code": "43221523" },
      { "name": "Music on hold adapter", "code": "43221524" },
      { "name": "Intercom systems", "code": "43221525" },
      { "name": "Telephone entry systems", "code": "43221526" },
      { "name": "Wire tapping protector", "code": "43221527" },
      { "name": "Supervisory signal unit", "code": "43221528" },
      { "name": "Call meter", "code": "43221529" },
      { "name": "Switch board signal device", "code": "43221530" },
      {
        "name": "Digital subscriber loop DSL access equipment and components and accessories",
        "code": "43221600"
      },
      {
        "name": "Digital subscriber loop DSL captive office plain old telephone system POTS splitter",
        "code": "43221601"
      },
      {
        "name": "Digital subscriber loop DSL captive office splitter shelf",
        "code": "43221602"
      },
      {
        "name": "Digital subscriber loop DSL customer premise equipment CPE plain old telephone system POTS splitter",
        "code": "43221603"
      },
      {
        "name": "Digital subscriber loop or line XDSL equipment",
        "code": "43221604"
      },
      { "name": "Fixed network equipment and components", "code": "43221700" },
      { "name": "Television core equipment", "code": "43221701" },
      { "name": "Television access equipment", "code": "43221702" },
      { "name": "Television antennas", "code": "43221703" },
      { "name": "Radio core equipment", "code": "43221704" },
      { "name": "Radio access equipment", "code": "43221705" },
      { "name": "Radio antennas", "code": "43221706" },
      { "name": "Microwave core equipment", "code": "43221707" },
      { "name": "Microwave access equipment", "code": "43221708" },
      { "name": "Microwave antennas", "code": "43221709" },
      { "name": "Satellite core equipment", "code": "43221710" },
      { "name": "Satellite access equipment", "code": "43221711" },
      { "name": "Satellite antennas", "code": "43221712" },
      { "name": "Shortwave core equipment", "code": "43221713" },
      { "name": "Shortwave access equipment", "code": "43221714" },
      { "name": "Shortwave antennas", "code": "43221715" },
      { "name": "Pager core equipment", "code": "43221716" },
      { "name": "Pager access equipment", "code": "43221717" },
      { "name": "Radar antennas", "code": "43221718" },
      { "name": "Aircraft antennas", "code": "43221719" },
      { "name": "Automotive antennas", "code": "43221720" },
      {
        "name": "Radio frequency data communication equipment",
        "code": "43221721"
      },
      { "name": "Global positioning system GPS antenna", "code": "43221722" },
      { "name": "Cellular antenna", "code": "43221723" },
      { "name": "Time synchronized generator", "code": "43221724" },
      { "name": "Signal divider", "code": "43221725" },
      { "name": "Reflector for antenna", "code": "43221726" },
      { "name": "Antenna accessory", "code": "43221727" },
      { "name": "Radome or radar dome", "code": "43221728" },
      { "name": "Portable antenna", "code": "43221729" },
      { "name": "Wired telecommunications transmitter", "code": "43221730" },
      { "name": "Underwater communication system", "code": "43221731" },
      { "name": "Speaker control unit", "code": "43221732" },
      { "name": "Remote automatic meter reading system", "code": "43221733" },
      { "name": "Optical network devices", "code": "43221800" },
      { "name": "Optical amplifiers", "code": "43221801" },
      {
        "name": "Optical network or communication filters",
        "code": "43221802"
      },
      { "name": "Optical adapters", "code": "43221803" },
      { "name": "Optical networking lasers", "code": "43221804" },
      {
        "name": "Asynchronous transfer mode ATM network equipment",
        "code": "43221805"
      },
      {
        "name": "Synchronous optical network SONET network equipment",
        "code": "43221806"
      },
      {
        "name": "Telecommunications dense wavelength division multiplexing DWDM filters",
        "code": "43221807"
      },
      {
        "name": "Synchronous digital hierarchy SDH telecom equipment",
        "code": "43221808"
      },
      { "name": "Optical\u00a0 transmitter", "code": "43221809" },
      { "name": "Optical multiplexer", "code": "43221810" },
      { "name": "Optical switch", "code": "43221811" },
      { "name": "Network security equipment", "code": "43222500" },
      { "name": "Firewall network security equipment", "code": "43222501" },
      { "name": "VPN network security equipment", "code": "43222502" },
      {
        "name": "Vulnerability Assessment Security Equipment",
        "code": "43222503"
      },
      { "name": "Radio jamming transmitter", "code": "43222504" },
      { "name": "Network service equipment", "code": "43222600" },
      { "name": "Cable head end equipment", "code": "43222602" },
      { "name": "Content delivery networking equipment", "code": "43222604" },
      { "name": "Network gateway", "code": "43222605" },
      { "name": "Internet service node startup kits", "code": "43222606" },
      { "name": "Cache engine equipment", "code": "43222607" },
      { "name": "Network repeaters", "code": "43222608" },
      { "name": "Network routers", "code": "43222609" },
      { "name": "Network service concentrators or hubs", "code": "43222610" },
      { "name": "Network channel or data service units", "code": "43222611" },
      { "name": "Network switches", "code": "43222612" },
      { "name": "Storage area network SAN switch", "code": "43222615" },
      { "name": "Video networking equipment", "code": "43222619" },
      { "name": "Multiservice switch", "code": "43222620" },
      { "name": "Content switch", "code": "43222621" },
      { "name": "Server load balancer", "code": "43222622" },
      { "name": "Digital cross connects DCX equipment", "code": "43222623" },
      { "name": "Optical cross connects equipment", "code": "43222624" },
      { "name": "Access servers", "code": "43222625" },
      { "name": "Cable modems", "code": "43222626" },
      {
        "name": "Integrated services digital network ISDN access devices",
        "code": "43222627"
      },
      { "name": "Modems", "code": "43222628" },
      { "name": "Modem banks", "code": "43222629" },
      { "name": "Multistation access units", "code": "43222630" },
      { "name": "Wireless fidelity base stations Wifi", "code": "43222631" },
      { "name": "Broadband aggregators", "code": "43222632" },
      { "name": "Remote management adapters", "code": "43222633" },
      { "name": "Network management or monitoring device", "code": "43222634" },
      { "name": "Network equipment upgrade kit", "code": "43222635" },
      { "name": "Network application engine", "code": "43222636" },
      { "name": "Gigabyte passive optical network GPON", "code": "43222637" },
      {
        "name": "Managed lease line network MLLN equipment",
        "code": "43222638"
      },
      {
        "name": "Internet protocol IP multimedia subsystem hardware",
        "code": "43222639"
      },
      { "name": "Wireless access point", "code": "43222640" },
      { "name": "Internet protocol sharing device", "code": "43222641" },
      { "name": "Routing switcher", "code": "43222642" },
      { "name": "Cable network tester", "code": "43222643" },
      { "name": "Network traffic controller", "code": "43222644" },
      { "name": "Telegraph equipment", "code": "43222700" },
      { "name": "Telegraph keys", "code": "43222701" },
      { "name": "Telegraph electromagnets", "code": "43222702" },
      { "name": "Telegraph sounders", "code": "43222703" },
      { "name": "Telegraph switch board", "code": "43222704" },
      { "name": "Telephony equipment", "code": "43222800" },
      {
        "name": "Broadband or narrowband digital cross connect DCX equipment",
        "code": "43222801"
      },
      { "name": "Circuit switchboard equipment", "code": "43222802" },
      { "name": "Digital loop carrier DLCs", "code": "43222803" },
      { "name": "Private branch exchange PBX equipment", "code": "43222805" },
      { "name": "Punch down blocks", "code": "43222806" },
      { "name": "Telephony equipment alarm units", "code": "43222811" },
      { "name": "Telephone switchboard part kits", "code": "43222813" },
      {
        "name": "Telecommunication equipment installation or modification kits",
        "code": "43222814"
      },
      { "name": "Telecommunication terminal units", "code": "43222815" },
      { "name": "Telephony keyers", "code": "43222816" },
      { "name": "Telecommunication repeaters", "code": "43222817" },
      { "name": "Telephone distributing terminal frame", "code": "43222818" },
      { "name": "Port connection panels", "code": "43222819" },
      { "name": "Voice echo cancellers", "code": "43222820" },
      { "name": "Time division multiplexer TDM", "code": "43222822" },
      { "name": "Wave division mulitplexer WDM", "code": "43222823" },
      { "name": "Aerial cable rollers", "code": "43222824" },
      { "name": "Telephone modification kits", "code": "43222825" },
      { "name": "Carrier terminal", "code": "43222826" },
      { "name": "Multiplexer or MUX", "code": "43222827" },
      { "name": "Telephony equipment accessories", "code": "43222900" },
      { "name": "Line conditioners", "code": "43222901" },
      { "name": "Telephony cable air dryers", "code": "43222902" },
      { "name": "Telecommunication tower or support", "code": "43222903" },
      { "name": "Teletype equipment", "code": "43223000" },
      { "name": "Teletype input devices", "code": "43223001" },
      {
        "name": "Digital mobile network infrastructure equipment and components",
        "code": "43223100"
      },
      {
        "name": "2G GSM mobile core network equipment and components",
        "code": "43223101"
      },
      {
        "name": "2G GSM wireless access network equipment and components",
        "code": "43223102"
      },
      {
        "name": "2,5G GPRS mobile core network equipment and components",
        "code": "43223103"
      },
      {
        "name": "2,5G GPRS wireless access network equipment and components",
        "code": "43223104"
      },
      {
        "name": "3G UMTS mobile core network equipment and components",
        "code": "43223105"
      },
      {
        "name": "3G UMTS wireless access network equipment and components",
        "code": "43223106"
      },
      {
        "name": "WLAN mobile core network equipment and components",
        "code": "43223107"
      },
      {
        "name": "WLAN wireless access network equipment and components",
        "code": "43223108"
      },
      { "name": "IN SSP switching equipment", "code": "43223109" },
      { "name": "IN mobile core equipment", "code": "43223110" },
      {
        "name": "OSS mobile core network equipment and components",
        "code": "43223111"
      },
      {
        "name": "OSS wireless access network equipment and components",
        "code": "43223112"
      },
      { "name": "GSM UMT LAN antenna", "code": "43223113" },
      { "name": "Mobile messaging platforms", "code": "43223200" },
      { "name": "Voice messaging portal", "code": "43223201" },
      { "name": "Short message service center", "code": "43223202" },
      { "name": "Multimedia service center", "code": "43223203" },
      { "name": "Unified messaging platform", "code": "43223204" },
      { "name": "Instant messaging platform", "code": "43223205" },
      { "name": "Wireless internet gateway", "code": "43223206" },
      { "name": "Video streaming system", "code": "43223207" },
      { "name": "Mobile or messaging game platform", "code": "43223208" },
      {
        "name": "Location based messaging service platforms",
        "code": "43223209"
      },
      { "name": "Micropayment messaging systems", "code": "43223210" },
      { "name": "Paging controllers", "code": "43223211" },
      { "name": "Paging terminals", "code": "43223212" },
      {
        "name": "Datacom and network connectivity installation devices and equipment",
        "code": "43223300"
      },
      {
        "name": "Datacom cross connect system and accessories",
        "code": "43223301"
      },
      { "name": "Datacom label", "code": "43223302" },
      { "name": "Datacom patch cord", "code": "43223303" },
      { "name": "Network cable management panel assembly", "code": "43223305" },
      { "name": "Network system cabinet or enclosure", "code": "43223306" },
      { "name": "Network system cabling box", "code": "43223307" },
      { "name": "Network system equipment rack", "code": "43223308" },
      { "name": "Patch panel", "code": "43223309" },
      { "name": "Fiber optic connector", "code": "43223310" },
      { "name": "Fiber optic attenuator", "code": "43223311" },
      { "name": "Fiber optic cable stripper", "code": "43223312" },
      { "name": "Fiber optic crimper", "code": "43223313" },
      { "name": "Fiber optic polishing fixture", "code": "43223314" },
      { "name": "Fiber optic tool blade set", "code": "43223315" },
      { "name": "Fiber optic scribe", "code": "43223316" },
      { "name": "Fiber optic buffer remover", "code": "43223317" },
      { "name": "Fiber optic emitter", "code": "43223318" },
      { "name": "Fiber optic detector", "code": "43223319" },
      { "name": "Fiber optic continuity tester", "code": "43223320" },
      { "name": "Fiber optic connector clip", "code": "43223321" },
      { "name": "Fiber optic support grip", "code": "43223322" },
      { "name": "Fiber optic adapter", "code": "43223323" },
      { "name": "Fiber optic polishing film", "code": "43223324" },
      { "name": "Fiber optic swab", "code": "43223325" },
      { "name": "Fiber optic patch cord", "code": "43223326" },
      { "name": "Fiber optic fault locator", "code": "43223327" },
      { "name": "Fiber optic patch panel", "code": "43223328" },
      { "name": "Fiber optic light source", "code": "43223329" },
      { "name": "Fiber optic connector assembly tool", "code": "43223330" },
      { "name": "Fiber optic insert", "code": "43223331" },
      { "name": "Fiber optic tool kit", "code": "43223332" },
      { "name": "Fiber optic scope eyepiece", "code": "43223333" },
      { "name": "Fiber optic tool and die set", "code": "43223334" },
      { "name": "Fiber optic music wire", "code": "43223335" },
      { "name": "Fiber optic scope adapter", "code": "43223336" },
      { "name": "Fiber optic enclosure", "code": "43223337" },
      { "name": "Fiber optic connection closure", "code": "43223338" },
      { "name": "Fiber optic distribution box", "code": "43223339" },
      { "name": "Fiber optic fusion splicer", "code": "43223340" },
      { "name": "Combiner", "code": "43223341" },
      { "name": "Waveguide and connection kit", "code": "43223342" },
      { "name": "Network punchdown tool", "code": "43223343" },
      { "name": "Software", "code": "43230000" },
      { "name": "Business function specific software", "code": "43231500" },
      { "name": "Helpdesk or call center software", "code": "43231501" },
      { "name": "Procurement software", "code": "43231503" },
      { "name": "Human resources software", "code": "43231505" },
      {
        "name": "Materials requirements planning logistics and supply chain software",
        "code": "43231506"
      },
      { "name": "Project management software", "code": "43231507" },
      { "name": "Inventory management software", "code": "43231508" },
      { "name": "Bar coding software", "code": "43231509" },
      { "name": "Label making software", "code": "43231510" },
      { "name": "Expert system software", "code": "43231511" },
      { "name": "License management software", "code": "43231512" },
      { "name": "Office suite software", "code": "43231513" },
      { "name": "Sales and marketing software", "code": "43231514" },
      { "name": "Mailing and shipping software", "code": "43231515" },
      { "name": "Audit software", "code": "43231516" },
      { "name": "Procedure management software", "code": "43231517" },
      {
        "name": "Finance accounting and enterprise resource planning ERP software",
        "code": "43231600"
      },
      { "name": "Accounting software", "code": "43231601" },
      {
        "name": "Enterprise resource planning ERP software",
        "code": "43231602"
      },
      { "name": "Tax preparation software", "code": "43231603" },
      { "name": "Financial analysis software", "code": "43231604" },
      { "name": "Time accounting software", "code": "43231605" },
      { "name": "Computer game or entertainment software", "code": "43232000" },
      { "name": "Action games", "code": "43232001" },
      { "name": "Adventure games", "code": "43232002" },
      { "name": "Sports games", "code": "43232003" },
      { "name": "Family software", "code": "43232004" },
      { "name": "Music or sound editing software", "code": "43232005" },
      { "name": "Content authoring and editing software", "code": "43232100" },
      { "name": "Pattern design software", "code": "43232101" },
      { "name": "Graphics or photo imaging software", "code": "43232102" },
      { "name": "Video creation and editing software", "code": "43232103" },
      { "name": "Word processing software", "code": "43232104" },
      { "name": "Charting software", "code": "43232105" },
      { "name": "Presentation software", "code": "43232106" },
      { "name": "Web page creation and editing software", "code": "43232107" },
      { "name": "Calendar and scheduling software", "code": "43232108" },
      { "name": "Spreadsheet software", "code": "43232110" },
      {
        "name": "Optical character reader OCR or scanning software",
        "code": "43232111"
      },
      { "name": "Desktop publishing software", "code": "43232112" },
      { "name": "Content management software", "code": "43232200" },
      { "name": "Content workflow software", "code": "43232201" },
      { "name": "Document management software", "code": "43232202" },
      { "name": "File versioning software", "code": "43232203" },
      { "name": "Embedded text entry software", "code": "43232204" },
      { "name": "Fonts software", "code": "43232205" },
      { "name": "Data management and query software", "code": "43232300" },
      {
        "name": "Categorization or classification software",
        "code": "43232301"
      },
      { "name": "Clustering software", "code": "43232302" },
      {
        "name": "Customer relationship management CRM software",
        "code": "43232303"
      },
      { "name": "Data base management system software", "code": "43232304" },
      { "name": "Data base reporting software", "code": "43232305" },
      {
        "name": "Data base user interface and query software",
        "code": "43232306"
      },
      { "name": "Data mining software", "code": "43232307" },
      {
        "name": "Information retrieval or search software",
        "code": "43232309"
      },
      { "name": "Metadata management software", "code": "43232310" },
      {
        "name": "Object oriented data base management software",
        "code": "43232311"
      },
      { "name": "Portal server software", "code": "43232312" },
      { "name": "Transaction server software", "code": "43232313" },
      {
        "name": "Business intelligence and data analysis software",
        "code": "43232314"
      },
      { "name": "Development software", "code": "43232400" },
      { "name": "Configuration management software", "code": "43232401" },
      { "name": "Development environment software", "code": "43232402" },
      {
        "name": "Enterprise application integration software",
        "code": "43232403"
      },
      {
        "name": "Graphical user interface development software",
        "code": "43232404"
      },
      {
        "name": "Object or component oriented development software",
        "code": "43232405"
      },
      { "name": "Program testing software", "code": "43232406" },
      {
        "name": "Requirements analysis and system architecture software",
        "code": "43232407"
      },
      { "name": "Web platform development software", "code": "43232408" },
      { "name": "Compiler and decompiler software", "code": "43232409" },
      { "name": "Educational or reference software", "code": "43232500" },
      { "name": "Foreign language software", "code": "43232501" },
      { "name": "Computer based training software", "code": "43232502" },
      { "name": "Spell checkers", "code": "43232503" },
      { "name": "Route navigation software", "code": "43232504" },
      { "name": "Multi-media educational software", "code": "43232505" },
      { "name": "Encyclopedia software", "code": "43232506" },
      { "name": "Dictionary software", "code": "43232507" },
      { "name": "Phonebook software", "code": "43232508" },
      {
        "name": "Voice synthesizer and recognition software",
        "code": "43232509"
      },
      { "name": "Geographic information system", "code": "43232510" },
      { "name": "Industry specific software", "code": "43232600" },
      { "name": "Aviation ground support software", "code": "43232601" },
      { "name": "Aviation test software", "code": "43232602" },
      { "name": "Facilities management software", "code": "43232603" },
      { "name": "Computer aided design CAD software", "code": "43232604" },
      { "name": "Analytical or scientific software", "code": "43232605" },
      { "name": "Compliance software", "code": "43232606" },
      { "name": "Flight control software", "code": "43232607" },
      { "name": "Industrial control software", "code": "43232608" },
      { "name": "Library software", "code": "43232609" },
      { "name": "Medical software", "code": "43232610" },
      { "name": "Point of sale POS software", "code": "43232611" },
      {
        "name": "Computer aided manufacturing CAM software",
        "code": "43232612"
      },
      {
        "name": "Manufacturing execution system MES software",
        "code": "43232613"
      },
      {
        "name": "Computer aided design CAD and computer aided manufacturing CAM system",
        "code": "43232614"
      },
      { "name": "Facial recognition software", "code": "43232615" },
      { "name": "Legal management software", "code": "43232616" },
      { "name": "Meteorological control software", "code": "43232617" },
      { "name": "Radar image treatment software", "code": "43232618" },
      { "name": "Satellite image treatment software", "code": "43232619" },
      { "name": "Network applications software", "code": "43232700" },
      { "name": "Application server software", "code": "43232701" },
      { "name": "Desktop communications software", "code": "43232702" },
      { "name": "Interactive voice response software", "code": "43232703" },
      { "name": "Internet directory services software", "code": "43232704" },
      { "name": "Internet browser software", "code": "43232705" },
      { "name": "Network management software", "code": "43232800" },
      { "name": "Network monitoring software", "code": "43232801" },
      {
        "name": "Network operating system enhancement software",
        "code": "43232802"
      },
      { "name": "Optical network management software", "code": "43232803" },
      { "name": "Administration software", "code": "43232804" },
      {
        "name": "Internet protocol IP multimedia subsystem software",
        "code": "43232805"
      },
      { "name": "Networking software", "code": "43232900" },
      { "name": "Access software", "code": "43232901" },
      { "name": "Communications server software", "code": "43232902" },
      { "name": "Contact center software", "code": "43232903" },
      { "name": "Fax software", "code": "43232904" },
      { "name": "LAN software", "code": "43232905" },
      { "name": "Multiplexer software", "code": "43232906" },
      { "name": "Storage networking software", "code": "43232907" },
      { "name": "Switch or router software", "code": "43232908" },
      { "name": "WAN switching software and firmware", "code": "43232909" },
      { "name": "Wireless software", "code": "43232910" },
      {
        "name": "Network connectivity terminal emulation software",
        "code": "43232911"
      },
      { "name": "Gateway software", "code": "43232912" },
      { "name": "Bridge software", "code": "43232913" },
      { "name": "Modem software", "code": "43232914" },
      { "name": "Platform interconnectivity software", "code": "43232915" },
      { "name": "Infrared data transfer irda software", "code": "43232916" },
      { "name": "Operating environment software", "code": "43233000" },
      { "name": "Filesystem software", "code": "43233001" },
      { "name": "Network operation system software", "code": "43233002" },
      { "name": "Operating system software", "code": "43233004" },
      { "name": "Computer firmware", "code": "43233005" },
      { "name": "Virtual machine software", "code": "43233006" },
      { "name": "Security and protection software", "code": "43233200" },
      { "name": "Authentication server software", "code": "43233201" },
      {
        "name": "Network security or virtual private network VPN management software",
        "code": "43233203"
      },
      {
        "name": "Network security and virtual private network VPN equipment software",
        "code": "43233204"
      },
      {
        "name": "Transaction security and virus protection software",
        "code": "43233205"
      },
      { "name": "Utility and device driver software", "code": "43233400" },
      { "name": "Compact disc CD server software", "code": "43233401" },
      { "name": "Data conversion software", "code": "43233402" },
      { "name": "Data compression software", "code": "43233403" },
      {
        "name": "Compact disc CD or DVD or sound card software",
        "code": "43233404"
      },
      { "name": "Device drivers or system software", "code": "43233405" },
      { "name": "Ethernet driver software", "code": "43233406" },
      { "name": "Graphics card driver software", "code": "43233407" },
      { "name": "Printer driver software", "code": "43233410" },
      { "name": "Screen saver software", "code": "43233411" },
      { "name": "Voice recognition software", "code": "43233413" },
      { "name": "Storage media loading software", "code": "43233414" },
      { "name": "Backup or archival software", "code": "43233415" },
      { "name": "Codec stacks", "code": "43233416" },
      {
        "name": "Handwriting recognition software components",
        "code": "43233417"
      },
      { "name": "Memory drivers", "code": "43233418" },
      { "name": "Multimedia stacks", "code": "43233419" },
      { "name": "Text to speech conversion software", "code": "43233420" },
      { "name": "Video drivers", "code": "43233421" },
      { "name": "Information exchange software", "code": "43233500" },
      { "name": "Electronic mail software", "code": "43233501" },
      { "name": "Video conferencing software", "code": "43233502" },
      { "name": "Network conferencing software", "code": "43233503" },
      { "name": "Instant messaging software", "code": "43233504" },
      {
        "name": "Ambient music or advertising messaging software",
        "code": "43233505"
      },
      { "name": "Map creation software", "code": "43233506" },
      {
        "name": "Mobile operator specific standard software",
        "code": "43233507"
      },
      {
        "name": "Mobile operator specific application software",
        "code": "43233508"
      },
      { "name": "Mobile messaging service software", "code": "43233509" },
      { "name": "Mobile internet services software", "code": "43233510" },
      { "name": "Mobile location based services software", "code": "43233511" },
      { "name": "Ring tone software", "code": "43233512" },
      { "name": "Electrical Equipment software", "code": "43233600" },
      { "name": "Motor Drive Software", "code": "43233601" },
      { "name": "Power Monitor Software", "code": "43233602" },
      { "name": "Programmable Logic Control Software", "code": "43233603" },
      { "name": "System management software", "code": "43233700" },
      { "name": "Enterprise system management software", "code": "43233701" }
    ]
  },
  {
    "name": "Office Equipment and Accessories and Supplies",
    "code": "44000000",
    "subcategories": [
      {
        "name": "Office machines and their supplies and accessories",
        "code": "44100000"
      },
      { "name": "Duplicating machines", "code": "44101500" },
      { "name": "Photocopiers", "code": "44101501" },
      { "name": "Multifunction machines", "code": "44101503" },
      { "name": "Digital senders", "code": "44101504" },
      { "name": "Digital duplicators", "code": "44101505" },
      { "name": "Faxswitch machines", "code": "44101506" },
      { "name": "Inkjet fax machine", "code": "44101507" },
      { "name": "Laser fax machine", "code": "44101508" },
      { "name": "Thermal fax machine", "code": "44101509" },
      { "name": "Weather facsimile or radiofax", "code": "44101510" },
      { "name": "Mimeograph and accessories", "code": "44101511" },
      {
        "name": "Paper processing machines and accessories",
        "code": "44101600"
      },
      { "name": "Paper cutting machines or accessories", "code": "44101601" },
      { "name": "Paper punching or binding machines", "code": "44101602" },
      { "name": "Paper shredding machines or accessories", "code": "44101603" },
      { "name": "Base protection boards", "code": "44101604" },
      { "name": "Paper jogging machines", "code": "44101605" },
      { "name": "Paper sorting machines", "code": "44101606" },
      { "name": "Paper press machine", "code": "44101607" },
      { "name": "Paper shredder bag", "code": "44101608" },
      {
        "name": "Printer and photocopier and facsimile accessories",
        "code": "44101700"
      },
      { "name": "Color options or upgrades", "code": "44101701" },
      { "name": "Duplexer trays", "code": "44101702" },
      { "name": "Duplexer units", "code": "44101703" },
      { "name": "Facsimile handsets", "code": "44101704" },
      { "name": "Office machine trays or feeders", "code": "44101705" },
      { "name": "Photoconductor or imaging units", "code": "44101706" },
      { "name": "Stapler units", "code": "44101707" },
      { "name": "Ozone filters", "code": "44101708" },
      { "name": "Mirror assemblies", "code": "44101709" },
      { "name": "Magnetic pickup assemblies", "code": "44101710" },
      { "name": "Compressor assembly", "code": "44101711" },
      { "name": "Mailbox stackers", "code": "44101712" },
      { "name": "Copy counters", "code": "44101713" },
      { "name": "Facsimile units for office machines", "code": "44101714" },
      { "name": "Platen covers", "code": "44101715" },
      { "name": "Hole punching units", "code": "44101716" },
      { "name": "Infrared adapters", "code": "44101718" },
      { "name": "Copy or scan accessories", "code": "44101719" },
      { "name": "Language fonts", "code": "44101720" },
      { "name": "Media spindles", "code": "44101721" },
      { "name": "Multi bin mailbox power upgrades", "code": "44101722" },
      { "name": "Multi bin mailboxes", "code": "44101723" },
      { "name": "Multi function upgrades", "code": "44101724" },
      { "name": "Printer cabinets", "code": "44101725" },
      { "name": "Printer emulation upgrades", "code": "44101726" },
      { "name": "Printer stands", "code": "44101727" },
      { "name": "Roll feeds", "code": "44101728" },
      { "name": "Output stackers", "code": "44101729" },
      { "name": "Automatic document sorter", "code": "44101730" },
      { "name": "Calculating machines and accessories", "code": "44101800" },
      { "name": "Adding machines", "code": "44101802" },
      { "name": "Accounting machines", "code": "44101803" },
      { "name": "Cash registers", "code": "44101804" },
      { "name": "Calculator ribbons", "code": "44101805" },
      { "name": "Cash register ribbons", "code": "44101806" },
      { "name": "Pocket calculator", "code": "44101807" },
      { "name": "Scientific calculator", "code": "44101808" },
      { "name": "Desktop calculator", "code": "44101809" },
      { "name": "Printer calculator", "code": "44101810" },
      { "name": "Check endorsing and writing machines", "code": "44101900" },
      { "name": "Check endorsing machines", "code": "44101901" },
      { "name": "Check writing machines", "code": "44101902" },
      { "name": "Bankbook or passbook update machine", "code": "44101903" },
      { "name": "Protectograph", "code": "44101904" },
      { "name": "Laminating supplies", "code": "44102000" },
      { "name": "Lamination film", "code": "44102001" },
      { "name": "Laminator pouches", "code": "44102002" },
      { "name": "Transfer foils", "code": "44102003" },
      { "name": "Creative foils", "code": "44102004" },
      { "name": "Mail machines", "code": "44102100" },
      { "name": "Franking or postage machines", "code": "44102101" },
      { "name": "Mail opening machines", "code": "44102102" },
      { "name": "Mail sealing machines", "code": "44102103" },
      { "name": "Stamp canceling machines", "code": "44102104" },
      { "name": "Addressing machines", "code": "44102105" },
      { "name": "Letter folders", "code": "44102106" },
      { "name": "Stamp affixers", "code": "44102107" },
      { "name": "Mail machine accessories", "code": "44102108" },
      { "name": "Automatic postal or mailing machine", "code": "44102109" },
      { "name": "Scanner accessories", "code": "44102200" },
      { "name": "Endorsers", "code": "44102201" },
      { "name": "Scanner document feeders", "code": "44102202" },
      { "name": "Scanner transparency adapters", "code": "44102203" },
      { "name": "Packing machines", "code": "44102300" },
      { "name": "Bundling machines", "code": "44102301" },
      { "name": "Parcel wrapping machines", "code": "44102302" },
      { "name": "Seal presses", "code": "44102303" },
      { "name": "Sealing machines", "code": "44102304" },
      { "name": "Strapping tensioners or sealers", "code": "44102305" },
      { "name": "Tying machines", "code": "44102306" },
      { "name": "Collators", "code": "44102307" },
      { "name": "Labeling machines", "code": "44102400" },
      { "name": "Dating or numbering machines", "code": "44102402" },
      { "name": "Identification ID press machines", "code": "44102403" },
      { "name": "Label applying machines", "code": "44102404" },
      { "name": "Label making machines", "code": "44102405" },
      { "name": "Lettering equipment", "code": "44102406" },
      { "name": "Tape embosser", "code": "44102407" },
      { "name": "Automatic labeling systems", "code": "44102408" },
      { "name": "Semi automatic labeling systems", "code": "44102409" },
      { "name": "Label dispensers", "code": "44102411" },
      { "name": "Adhesive label cartridges", "code": "44102412" },
      {
        "name": "Compact disc or digital versatile disc CD/DVD labeling system kit",
        "code": "44102413"
      },
      { "name": "Self-inking stamp", "code": "44102414" },
      { "name": "Sorting machines", "code": "44102500" },
      { "name": "Money counting machines", "code": "44102501" },
      { "name": "Sorters", "code": "44102502" },
      { "name": "Coin wrapper machines", "code": "44102503" },
      { "name": "Paper and plastic counter", "code": "44102504" },
      { "name": "Typing machines and accessories", "code": "44102600" },
      { "name": "Typewriters", "code": "44102602" },
      { "name": "Printwheels", "code": "44102603" },
      { "name": "Stenotype machines", "code": "44102604" },
      { "name": "Dictation machines", "code": "44102605" },
      { "name": "Typewriter ribbon", "code": "44102606" },
      { "name": "Word processors", "code": "44102607" },
      { "name": "Typewriter printing elements", "code": "44102608" },
      {
        "name": "Accessory or supply kits for typewriters",
        "code": "44102609"
      },
      { "name": "Typewriter starter kits", "code": "44102610" },
      { "name": "Binding and lamination machines", "code": "44102800" },
      { "name": "Laminators", "code": "44102801" },
      { "name": "Thermal binding machine", "code": "44102802" },
      { "name": "Spiral binding machine", "code": "44102803" },
      { "name": "Comb binding machine", "code": "44102804" },
      { "name": "Binding punch machine", "code": "44102805" },
      { "name": "Wire binding machine", "code": "44102806" },
      { "name": "Office machine accessories", "code": "44102900" },
      { "name": "Travel kits for office machines", "code": "44102901" },
      { "name": "Storage accessories for office machines", "code": "44102902" },
      { "name": "Tape cleaners", "code": "44102903" },
      { "name": "Air compressed spray", "code": "44102904" },
      { "name": "Small paper bags of wet absorbing salts", "code": "44102905" },
      {
        "name": "Computer or office equipment cleaning kit",
        "code": "44102906"
      },
      { "name": "Equipment dust covers", "code": "44102907" },
      {
        "name": "Compact disc cleaners or scratch removers",
        "code": "44102908"
      },
      { "name": "Compact disc drive cleaner", "code": "44102909" },
      { "name": "Laminator trays", "code": "44102910" },
      { "name": "Cleaning wipes for office machines", "code": "44102911" },
      { "name": "Cleaning solutions for office equipment", "code": "44102912" },
      { "name": "Compact disc destroyer", "code": "44102913" },
      { "name": "Fusers and accessories", "code": "44103000" },
      { "name": "Fuser cleaning pads or filters", "code": "44103001" },
      { "name": "Fuser oil", "code": "44103002" },
      { "name": "Fuser wiper", "code": "44103003" },
      { "name": "Fusers", "code": "44103004" },
      { "name": "Fuser lamps or assemblies", "code": "44103005" },
      {
        "name": "Printer and facsimile and photocopier supplies",
        "code": "44103100"
      },
      {
        "name": "Printer or facsimile or photocopier belts",
        "code": "44103101"
      },
      { "name": "Printer or facsimile toner", "code": "44103103" },
      { "name": "Transfer rolls", "code": "44103104" },
      { "name": "Ink cartridges", "code": "44103105" },
      { "name": "Ink sticks", "code": "44103106" },
      {
        "name": "Printer or facsimile or photocopier cleaning supplies",
        "code": "44103107"
      },
      { "name": "Developer for printers or photocopiers", "code": "44103108" },
      {
        "name": "Printer or facsimile or photocopier drums",
        "code": "44103109"
      },
      { "name": "Print heads", "code": "44103110" },
      { "name": "Ink rolls", "code": "44103111" },
      { "name": "Printer ribbon", "code": "44103112" },
      { "name": "Phasers or inkjet kits", "code": "44103113" },
      { "name": "Laserjet coating kits", "code": "44103114" },
      { "name": "Kit for printer", "code": "44103116" },
      { "name": "Facsimile ribbons", "code": "44103117" },
      { "name": "Transparency film", "code": "44103118" },
      { "name": "Heat transfer paper for copiers", "code": "44103119" },
      { "name": "Toner collectors", "code": "44103120" },
      {
        "name": "Printer or facsimile or photocopier roller guides",
        "code": "44103121"
      },
      { "name": "Print bands", "code": "44103122" },
      { "name": "Plotter pens", "code": "44103123" },
      { "name": "Thermal ribbon", "code": "44103124" },
      { "name": "Printer maintenance kit", "code": "44103125" },
      { "name": "Digital duplicating machine ink", "code": "44103126" },
      { "name": "Photocopier toner", "code": "44103127" },
      {
        "name": "Office time recording machines and accessories",
        "code": "44103200"
      },
      { "name": "Time card machines", "code": "44103201" },
      { "name": "Time stamping machines", "code": "44103202" },
      { "name": "Time card machine replacement ribbon", "code": "44103203" },
      { "name": "Time card racks", "code": "44103204" },
      { "name": "Time cards or sheets", "code": "44103205" },
      {
        "name": "Fingerprint time attendance and access control machine",
        "code": "44103206"
      },
      {
        "name": "Time card or time clock machine accessories",
        "code": "44103207"
      },
      { "name": "Binding machine supplies", "code": "44103500" },
      { "name": "Binding covers", "code": "44103502" },
      { "name": "Binding spines or snaps", "code": "44103503" },
      { "name": "Binding coils or wire loops", "code": "44103504" },
      { "name": "Binding combs or strips", "code": "44103505" },
      { "name": "Binding tape", "code": "44103506" },
      { "name": "Binding Kits", "code": "44103507" },
      { "name": "Binding die punch", "code": "44103508" },
      {
        "name": "Cassette disposal equipment and accessories",
        "code": "44103600"
      },
      { "name": "Cassette or tape shredders", "code": "44103601" },
      { "name": "Office and desk accessories", "code": "44110000" },
      { "name": "Organizers and accessories", "code": "44111500" },
      { "name": "Message holders or dispensers", "code": "44111501" },
      { "name": "Desk drawer organizers", "code": "44111502" },
      { "name": "Desktop trays or organizers", "code": "44111503" },
      { "name": "Paper or pad holder or dispensers", "code": "44111506" },
      { "name": "Book ends", "code": "44111507" },
      { "name": "Pen or pencil holders", "code": "44111509" },
      { "name": "Hanging organizers or accessories", "code": "44111510" },
      { "name": "Display systems or its accessories", "code": "44111511" },
      { "name": "Literature rack", "code": "44111512" },
      { "name": "Supports for diaries or calendars", "code": "44111513" },
      { "name": "Stamp racks or organizers", "code": "44111514" },
      { "name": "File storage boxes or organizers", "code": "44111515" },
      { "name": "Personal organizers", "code": "44111516" },
      { "name": "Study stands", "code": "44111517" },
      { "name": "Business card holders", "code": "44111518" },
      { "name": "Collating racks", "code": "44111519" },
      { "name": "Surface protectors or pads", "code": "44111520" },
      { "name": "Copy holders", "code": "44111521" },
      { "name": "Book holder", "code": "44111522" },
      { "name": "Desk organizer accessories kit", "code": "44111523" },
      { "name": "Cash handling supplies", "code": "44111600" },
      { "name": "Currency bags or wallets", "code": "44111601" },
      { "name": "Coin sorters", "code": "44111603" },
      { "name": "Coin wrappers or bill straps", "code": "44111604" },
      { "name": "Cash or ticket boxes", "code": "44111605" },
      { "name": "Cash box trays", "code": "44111606" },
      { "name": "Check files", "code": "44111607" },
      { "name": "Coin banks", "code": "44111608" },
      { "name": "Counterfeit bill detectors or supplies", "code": "44111609" },
      { "name": "Coin trays", "code": "44111610" },
      { "name": "Money clips", "code": "44111611" },
      { "name": "Coin bag seal", "code": "44111612" },
      { "name": "Credit card holders", "code": "44111613" },
      { "name": "Bill strap racks", "code": "44111614" },
      { "name": "Deposit bags", "code": "44111615" },
      { "name": "Check separators", "code": "44111616" },
      { "name": "Coin inspection machine", "code": "44111617" },
      { "name": "Public telephone card or phone card", "code": "44111618" },
      { "name": "Drafting supplies", "code": "44111800" },
      { "name": "Stencils or lettering aids", "code": "44111801" },
      { "name": "Drafting films", "code": "44111802" },
      { "name": "Compasses", "code": "44111803" },
      { "name": "Drafting papers", "code": "44111804" },
      { "name": "Curves", "code": "44111805" },
      { "name": "Protractors", "code": "44111806" },
      { "name": "Scales", "code": "44111807" },
      { "name": "T squares", "code": "44111808" },
      { "name": "Templates", "code": "44111809" },
      { "name": "Triangles", "code": "44111810" },
      { "name": "Drafting kits or sets", "code": "44111812" },
      { "name": "Drafting dots or tapes", "code": "44111813" },
      { "name": "Work surface protection covers", "code": "44111814" },
      { "name": "Drafting table covers", "code": "44111815" },
      { "name": "Multiplex drafting machine", "code": "44111816" },
      { "name": "Drafting divider", "code": "44111817" },
      { "name": "Eidograph", "code": "44111818" },
      { "name": "Boards", "code": "44111900" },
      { "name": "Planning boards or accessories", "code": "44111901" },
      { "name": "Electronic copyboards or accessories", "code": "44111902" },
      { "name": "Easels or accessories", "code": "44111903" },
      { "name": "Letter boards or accessories", "code": "44111904" },
      { "name": "Dry erase boards or accessories", "code": "44111905" },
      { "name": "Chalk boards or accessories", "code": "44111906" },
      { "name": "Bulletin boards or accessories", "code": "44111907" },
      { "name": "Magnetic boards or accessories", "code": "44111908" },
      { "name": "Board cleaning kits or accessories", "code": "44111909" },
      { "name": "Hanging rails or holders", "code": "44111910" },
      { "name": "Interactive whiteboards or accessories", "code": "44111911" },
      { "name": "Whiteboard eraser", "code": "44111912" },
      { "name": "Battery driven whiteboard eraser", "code": "44111913" },
      { "name": "Chart hanger", "code": "44111914" },
      { "name": "Planning systems", "code": "44112000" },
      { "name": "Address books or refills", "code": "44112001" },
      { "name": "Calendars", "code": "44112002" },
      { "name": "Meeting planners", "code": "44112004" },
      { "name": "Appointment books or refills", "code": "44112005" },
      { "name": "Diaries or refills", "code": "44112006" },
      { "name": "Suggestion box", "code": "44112007" },
      { "name": "Wall planners or refills", "code": "44112008" },
      { "name": "Office supplies", "code": "44120000" },
      { "name": "Mailing supplies", "code": "44121500" },
      { "name": "Mailer tubes", "code": "44121501" },
      { "name": "Mailers", "code": "44121503" },
      { "name": "Window envelopes", "code": "44121504" },
      { "name": "Specialty envelopes", "code": "44121505" },
      { "name": "Standard envelopes", "code": "44121506" },
      { "name": "Catalog or clasp envelopes", "code": "44121507" },
      { "name": "Message droppers", "code": "44121508" },
      { "name": "Mailing bags", "code": "44121509" },
      { "name": "Mailing seals", "code": "44121510" },
      { "name": "Mailing boxes", "code": "44121511" },
      { "name": "Mailer tube caps", "code": "44121512" },
      { "name": "Postage stamp", "code": "44121513" },
      { "name": "Desk supplies", "code": "44121600" },
      { "name": "Stamps", "code": "44121604" },
      { "name": "Tape dispensers", "code": "44121605" },
      { "name": "Paper or eyelet punches", "code": "44121611" },
      { "name": "Paper cutters or refills", "code": "44121612" },
      { "name": "Staple removers", "code": "44121613" },
      { "name": "Call bells", "code": "44121614" },
      { "name": "Staplers", "code": "44121615" },
      { "name": "Manual letter openers", "code": "44121617" },
      { "name": "Scissors", "code": "44121618" },
      { "name": "Manual pencil sharpener", "code": "44121619" },
      { "name": "Finger stalls", "code": "44121620" },
      { "name": "Desk pads or its accessories", "code": "44121621" },
      { "name": "Moisteners", "code": "44121622" },
      { "name": "Mechanical letter opener", "code": "44121623" },
      { "name": "Embossing tools", "code": "44121624" },
      { "name": "Paper weights", "code": "44121625" },
      { "name": "Adhesive remover", "code": "44121626" },
      { "name": "Bookmarks", "code": "44121627" },
      { "name": "Clip holders or dispensers", "code": "44121628" },
      { "name": "Stapler kit", "code": "44121630" },
      { "name": "Glue dispensers or refills", "code": "44121631" },
      { "name": "Scissor sharpener", "code": "44121632" },
      { "name": "Postage stamp dispensers", "code": "44121633" },
      { "name": "Adhesive rollers", "code": "44121634" },
      { "name": "Adhesive tape spindles", "code": "44121635" },
      { "name": "Electric pencil sharpener", "code": "44121636" },
      { "name": "Writing instruments", "code": "44121700" },
      { "name": "Rollerball pens", "code": "44121701" },
      { "name": "Pen or pencil sets", "code": "44121702" },
      { "name": "Fountain pens", "code": "44121703" },
      { "name": "Ball point pens", "code": "44121704" },
      { "name": "Mechanical pencils", "code": "44121705" },
      { "name": "Wooden pencils", "code": "44121706" },
      { "name": "Colored pencils", "code": "44121707" },
      { "name": "Markers", "code": "44121708" },
      { "name": "Crayons", "code": "44121709" },
      { "name": "Writing chalk or accessories", "code": "44121710" },
      { "name": "Felt pen", "code": "44121711" },
      { "name": "Marker refills", "code": "44121712" },
      { "name": "Pen nibs", "code": "44121713" },
      { "name": "Pencil or pen grips", "code": "44121714" },
      { "name": "Combination pen or pencil", "code": "44121715" },
      { "name": "Highlighters", "code": "44121716" },
      { "name": "Combination pen and highlighter", "code": "44121717" },
      { "name": "Secured pen sets", "code": "44121718" },
      { "name": "Disposable fountain pen", "code": "44121719" },
      { "name": "Multifunction pen", "code": "44121720" },
      { "name": "Erasable ink pen", "code": "44121721" },
      { "name": "Correction media", "code": "44121800" },
      { "name": "Correction film or tape", "code": "44121801" },
      { "name": "Correction fluid", "code": "44121802" },
      { "name": "Erasers", "code": "44121804" },
      { "name": "Correction pens", "code": "44121805" },
      { "name": "Correction pen refills", "code": "44121806" },
      { "name": "Eraser refills", "code": "44121807" },
      { "name": "Electrical erasers", "code": "44121808" },
      { "name": "Eraser holder", "code": "44121809" },
      { "name": "Ink and lead refills", "code": "44121900" },
      { "name": "Lead refills", "code": "44121902" },
      { "name": "Ink refills", "code": "44121904" },
      { "name": "Ink or stamp pads", "code": "44121905" },
      { "name": "Felt pen cartridge", "code": "44121906" },
      { "name": "Fountain pen ink refill", "code": "44121907" },
      { "name": "Ballpoint pen ink refill", "code": "44121908" },
      { "name": "Folders and binders and indexes", "code": "44122000" },
      { "name": "Index card files", "code": "44122001" },
      { "name": "Sheet protectors", "code": "44122002" },
      { "name": "Binders", "code": "44122003" },
      { "name": "Magazine or book covers", "code": "44122005" },
      { "name": "Tab indexes", "code": "44122008" },
      { "name": "Rotary or business card files", "code": "44122009" },
      { "name": "Dividers", "code": "44122010" },
      { "name": "Folders", "code": "44122011" },
      { "name": "Clipboards", "code": "44122012" },
      { "name": "Report covers", "code": "44122013" },
      { "name": "Sheet lifters", "code": "44122014" },
      { "name": "File backers", "code": "44122015" },
      { "name": "Document holder", "code": "44122016" },
      { "name": "Hanging folders or accessories", "code": "44122017" },
      { "name": "File inserts or tabs", "code": "44122018" },
      { "name": "File pockets or accessories", "code": "44122019" },
      { "name": "Card pockets", "code": "44122020" },
      { "name": "Postage stamp albums", "code": "44122021" },
      { "name": "Folder binding accessories", "code": "44122022" },
      { "name": "Archboards", "code": "44122023" },
      { "name": "Binder handles", "code": "44122024" },
      { "name": "Binder pockets or accessories", "code": "44122025" },
      { "name": "Paper claws", "code": "44122026" },
      { "name": "Expandable file folders", "code": "44122027" },
      { "name": "Binder mounting channels", "code": "44122028" },
      { "name": "Classification folder", "code": "44122029" },
      { "name": "Folder kit", "code": "44122030" },
      { "name": "Side rails for hanging folder frame", "code": "44122031" },
      { "name": "Conference folder", "code": "44122032" },
      { "name": "Accordion file folder", "code": "44122033" },
      { "name": "Book page separator", "code": "44122034" },
      { "name": "Lever arch file", "code": "44122035" },
      { "name": "Box file", "code": "44122036" },
      { "name": "Ring binder", "code": "44122037" },
      { "name": "Fastening supplies", "code": "44122100" },
      { "name": "Rubber bands", "code": "44122101" },
      { "name": "Clasp fasteners", "code": "44122103" },
      { "name": "Paper clips", "code": "44122104" },
      { "name": "Binder or bulldog clips", "code": "44122105" },
      { "name": "Pins or tacks", "code": "44122106" },
      { "name": "Staples", "code": "44122107" },
      { "name": "Hook and loop fastener", "code": "44122109" },
      { "name": "Adhesive mounts", "code": "44122110" },
      { "name": "Hole reinforcements", "code": "44122111" },
      { "name": "Round head fasteners", "code": "44122112" },
      { "name": "Tag fasteners", "code": "44122113" },
      { "name": "Screw posts", "code": "44122114" },
      { "name": "Adhesive corners", "code": "44122115" },
      { "name": "Bag clips", "code": "44122116" },
      { "name": "Book rings", "code": "44122117" },
      { "name": "Prong fasteners", "code": "44122118" },
      { "name": "Self adhesive fasteners", "code": "44122119" },
      { "name": "Binder posts", "code": "44122120" },
      { "name": "Wall or board clips", "code": "44122121" },
      { "name": "Magnetic thumbtack", "code": "44122122" }
    ]
  },
  {
    "name": "Printing and Photographic and Audio and Visual Equipment and Supplies",
    "code": "45000000",
    "subcategories": [
      { "name": "Printing and publishing equipment", "code": "45100000" },
      { "name": "Printing machinery and equipment", "code": "45101500" },
      { "name": "Heliographic printers", "code": "45101501" },
      { "name": "Offset printing presses", "code": "45101502" },
      { "name": "Letterpress equipment", "code": "45101503" },
      { "name": "Lithographic equipment", "code": "45101504" },
      { "name": "Photogravure printing machines", "code": "45101505" },
      { "name": "Silk screen printing machines", "code": "45101506" },
      { "name": "Printing presses", "code": "45101507" },
      { "name": "Perforating machines", "code": "45101508" },
      { "name": "Ultraviolet UV rotary printer", "code": "45101509" },
      { "name": "Flexographic printer", "code": "45101510" },
      {
        "name": "Inkjet printer for commercial printing applications",
        "code": "45101511"
      },
      {
        "name": "Thermal transfer printer for commercial printing applications",
        "code": "45101512"
      },
      { "name": "Hot stamp printer", "code": "45101513" },
      { "name": "Pad printer", "code": "45101514" },
      { "name": "Core printer", "code": "45101515" },
      { "name": "Pad printing clich\u00e9", "code": "45101516" },
      { "name": "Offset proof press", "code": "45101517" },
      { "name": "Printing plate press", "code": "45101518" },
      {
        "name": "Industrial sign and label benchtop printer",
        "code": "45101519"
      },
      {
        "name": "Industrial sign and label portable printer",
        "code": "45101520"
      },
      { "name": "Printing machinery accessories", "code": "45101600" },
      { "name": "Offset darkroom equipment", "code": "45101602" },
      { "name": "Offset printing consumables", "code": "45101603" },
      { "name": "Offset printing plate processors", "code": "45101604" },
      { "name": "Offset film processors", "code": "45101606" },
      { "name": "Silk screen arc lamps", "code": "45101607" },
      { "name": "Silk screen screens", "code": "45101608" },
      { "name": "Silk screen printing racks", "code": "45101609" },
      { "name": "Silk screen vacuum printing frames", "code": "45101610" },
      { "name": "Silk screen squeegees", "code": "45101611" },
      { "name": "Doctor blade", "code": "45101612" },
      {
        "name": "Industrial sign and label benchtop printer accessories",
        "code": "45101613"
      },
      {
        "name": "Industrial sign and label portable printer accessories",
        "code": "45101614"
      },
      { "name": "Offset printing machine parts", "code": "45101615" },
      { "name": "Offset printing cleaning accessories", "code": "45101616" },
      { "name": "Printing accessories", "code": "45101700" },
      { "name": "Printing assemblers", "code": "45101701" },
      { "name": "Printing guillotines", "code": "45101702" },
      { "name": "Printing collators or decollators", "code": "45101703" },
      { "name": "Printing cutters", "code": "45101704" },
      { "name": "Printing trimmers", "code": "45101705" },
      { "name": "Printing punches", "code": "45101706" },
      { "name": "Printing plates", "code": "45101707" },
      { "name": "Printing awls", "code": "45101708" },
      { "name": "Printing film", "code": "45101709" },
      { "name": "Industrial sign and label printer ink", "code": "45101710" },
      {
        "name": "Industrial sign and label printer ribbon",
        "code": "45101711"
      },
      {
        "name": "Book binding and sewing equipment and accessories",
        "code": "45101800"
      },
      { "name": "Book creasing machines", "code": "45101801" },
      { "name": "Book cutting machines", "code": "45101802" },
      { "name": "Book punching machines", "code": "45101803" },
      { "name": "Book stitching machines", "code": "45101804" },
      { "name": "Book jogging machines", "code": "45101805" },
      { "name": "Book gathering machines", "code": "45101806" },
      { "name": "Book folding machines", "code": "45101807" },
      { "name": "Thermal book binding machines", "code": "45101808" },
      {
        "name": "Printing laboratory equipment and accessories",
        "code": "45101900"
      },
      { "name": "Banding machines", "code": "45101901" },
      { "name": "Platemakers", "code": "45101902" },
      { "name": "Paper drilling machines", "code": "45101903" },
      { "name": "Enlargers", "code": "45101904" },
      { "name": "Drawing or retouching boards", "code": "45101905" },
      { "name": "Composing machines and accessories", "code": "45102000" },
      { "name": "Intertype composing machines", "code": "45102001" },
      { "name": "Linotype composing machines", "code": "45102002" },
      { "name": "Photocomposition materials", "code": "45102003" },
      { "name": "Monotype composing machines", "code": "45102004" },
      { "name": "Phototypesetting machines", "code": "45102005" },
      {
        "name": "Audio and visual presentation and composing equipment",
        "code": "45110000"
      },
      {
        "name": "Lecterns and sound systems and accessories",
        "code": "45111500"
      },
      { "name": "Free standing lecterns", "code": "45111501" },
      { "name": "Table top lecterns", "code": "45111502" },
      { "name": "Gavels or sounding blocks", "code": "45111503" },
      {
        "name": "Lighting or power or data components for lecterns",
        "code": "45111504"
      },
      { "name": "Projectors and supplies", "code": "45111600" },
      { "name": "Pointers", "code": "45111601" },
      { "name": "Projection lamps", "code": "45111602" },
      { "name": "Projection screens or displays", "code": "45111603" },
      { "name": "Slide projectors", "code": "45111604" },
      { "name": "Transparency equipment or supplies", "code": "45111605" },
      {
        "name": "Liquid crystal display projection panels",
        "code": "45111606"
      },
      { "name": "Overhead projectors", "code": "45111607" },
      { "name": "Film projectors", "code": "45111608" },
      { "name": "Multimedia projectors", "code": "45111609" },
      { "name": "Epidiascopes", "code": "45111610" },
      { "name": "Dissolve controls", "code": "45111612" },
      { "name": "Cathode ray tube projector", "code": "45111613" },
      { "name": "Liquid crystal display projector", "code": "45111614" },
      { "name": "Projection lenses", "code": "45111615" },
      { "name": "Video projectors", "code": "45111616" },
      { "name": "Overhead projector or video trolleys", "code": "45111617" },
      { "name": "Presentation light boxes", "code": "45111618" },
      { "name": "Film reel", "code": "45111619" },
      { "name": "Slide film copier", "code": "45111620" },
      {
        "name": "Audio presentation and composing equipment and hardware and controllers",
        "code": "45111700"
      },
      { "name": "Assistive listening devices", "code": "45111701" },
      { "name": "Audio jack boxes", "code": "45111702" },
      { "name": "Listening centers", "code": "45111703" },
      { "name": "Audio mixing consoles", "code": "45111704" },
      { "name": "Public address systems", "code": "45111705" },
      { "name": "Combination audio mixer and amplifier", "code": "45111706" },
      { "name": "Audio equipment connector and stage box", "code": "45111707" },
      { "name": "Broadcast domain controller", "code": "45111708" },
      { "name": "Remote amplifier", "code": "45111709" },
      { "name": "Chime and siren unit", "code": "45111710" },
      { "name": "Speaker baffle", "code": "45111711" },
      { "name": "Tape duplicator", "code": "45111712" },
      { "name": "Audio distribution amplifier", "code": "45111713" },
      { "name": "Audio analog to digital AD converter", "code": "45111714" },
      { "name": "Audio digital to analog DA converter", "code": "45111715" },
      { "name": "Digital audio workstation DAW", "code": "45111716" },
      { "name": "Reverberator", "code": "45111717" },
      { "name": "Delay unit", "code": "45111718" },
      { "name": "Audio monitor", "code": "45111719" },
      { "name": "Audio spectrum analyzer", "code": "45111720" },
      { "name": "Reel to reel tape recorder", "code": "45111721" },
      { "name": "Cassette deck", "code": "45111722" },
      { "name": "Hard disk recorder", "code": "45111723" },
      { "name": "Magnetic head", "code": "45111724" },
      { "name": "Acoustic box", "code": "45111725" },
      {
        "name": "Video and combination video and audio presentation equipment and hardware and controllers",
        "code": "45111800"
      },
      { "name": "Media control systems", "code": "45111801" },
      { "name": "Television mounts", "code": "45111802" },
      { "name": "Scan converters", "code": "45111803" },
      { "name": "Line doublers", "code": "45111804" },
      { "name": "Video editors", "code": "45111805" },
      { "name": "Distance learning systems", "code": "45111806" },
      { "name": "Interfaces", "code": "45111807" },
      { "name": "Lighting controls", "code": "45111808" },
      { "name": "Television mount accessories", "code": "45111809" },
      { "name": "Visual presenters", "code": "45111810" },
      { "name": "Video precision monitor", "code": "45111811" },
      { "name": "Visual information display device", "code": "45111812" },
      { "name": "Down stream keyer", "code": "45111813" },
      { "name": "Digital video effects DVE equipment", "code": "45111814" },
      { "name": "Audio vidio console", "code": "45111815" },
      { "name": "Logo generator", "code": "45111816" },
      { "name": "Character generator", "code": "45111817" },
      { "name": "Video noise reducer", "code": "45111818" },
      { "name": "Video mixer", "code": "45111819" },
      { "name": "Video processing amplifier", "code": "45111820" },
      { "name": "Synchronizing or sync generator", "code": "45111821" },
      { "name": "Television aspect ratio converter", "code": "45111822" },
      { "name": "Video standards converter", "code": "45111823" },
      { "name": "Synchronizing or sync converter", "code": "45111824" },
      { "name": "Frame synchronizer", "code": "45111825" },
      {
        "name": "Motion picture experts group MPEG encoder",
        "code": "45111826"
      },
      { "name": "Video tape checker", "code": "45111827" },
      { "name": "Prompter", "code": "45111828" },
      { "name": "Process camera", "code": "45111829" },
      {
        "name": "Phone and video conference equipment and hardware and controllers",
        "code": "45111900"
      },
      { "name": "Audioconferencing systems", "code": "45111901" },
      { "name": "Videoconferencing systems", "code": "45111902" },
      { "name": "Microfilm equipment and supplies", "code": "45112000" },
      { "name": "Microfiche or microfilm viewers", "code": "45112001" },
      { "name": "Microfiche reader printers", "code": "45112002" },
      {
        "name": "Microfiche or microfilm viewer components or accessories",
        "code": "45112003"
      },
      {
        "name": "Microfiche reader printer components or accessories",
        "code": "45112004"
      },
      {
        "name": "Photographic or filming or video equipment",
        "code": "45120000"
      },
      { "name": "Cameras", "code": "45121500" },
      { "name": "Still cameras", "code": "45121501" },
      { "name": "Instant print cameras", "code": "45121502" },
      { "name": "Disposable cameras", "code": "45121503" },
      { "name": "Digital cameras", "code": "45121504" },
      { "name": "Cinematographic cameras", "code": "45121505" },
      { "name": "Video conference cameras", "code": "45121506" },
      { "name": "Aerial cameras", "code": "45121510" },
      { "name": "High speed cameras", "code": "45121511" },
      { "name": "Underwater cameras", "code": "45121512" },
      { "name": "Offset cameras", "code": "45121513" },
      { "name": "Photocopier cameras", "code": "45121514" },
      { "name": "Hand held camcorders or video cameras", "code": "45121515" },
      { "name": "Digital camcorders or video cameras", "code": "45121516" },
      { "name": "Document camera", "code": "45121517" },
      { "name": "Camera kits", "code": "45121518" },
      { "name": "Low light camera", "code": "45121519" },
      { "name": "Web cameras", "code": "45121520" },
      { "name": "Inspection camera", "code": "45121521" },
      { "name": "Infrared camera", "code": "45121522" },
      { "name": "Astronomical camera", "code": "45121523" },
      { "name": "Camera accessories", "code": "45121600" },
      { "name": "Camera flashes or lighting", "code": "45121601" },
      { "name": "Camera tripods", "code": "45121602" },
      { "name": "Camera lens", "code": "45121603" },
      { "name": "Camera shutters", "code": "45121604" },
      { "name": "Screen frames", "code": "45121605" },
      { "name": "Camera harnesses", "code": "45121606" },
      { "name": "Camera blocks or holders", "code": "45121607" },
      { "name": "Camera assemblies", "code": "45121608" },
      { "name": "Camera brackets", "code": "45121609" },
      { "name": "Camera cables", "code": "45121610" },
      { "name": "Lens cover", "code": "45121611" },
      { "name": "Camera tables", "code": "45121612" },
      { "name": "Camera enclosures or covers", "code": "45121613" },
      { "name": "Retrofit kits", "code": "45121614" },
      { "name": "Camera rings", "code": "45121615" },
      { "name": "Pan heads", "code": "45121616" },
      { "name": "Camera bags", "code": "45121617" },
      { "name": "Camera lens adapters", "code": "45121618" },
      { "name": "Picture card wallets", "code": "45121619" },
      { "name": "Camera power adapters", "code": "45121620" },
      { "name": "Picture card adapters", "code": "45121621" },
      { "name": "Camera lens cleaners", "code": "45121622" },
      { "name": "Camera controllers", "code": "45121623" },
      { "name": "Photography light reflector", "code": "45121624" },
      { "name": "Shutter release", "code": "45121625" },
      { "name": "Digital camera back", "code": "45121626" },
      { "name": "Camera flash diffuser", "code": "45121627" },
      { "name": "Camera lens filter", "code": "45121628" },
      { "name": "Camera track motor", "code": "45121629" },
      { "name": "Camera parasol reflector", "code": "45121630" },
      { "name": "Electronic viewfinder", "code": "45121631" },
      { "name": "Photographic flashlight apparatus", "code": "45121632" },
      { "name": "Photographic processing equipment", "code": "45121700" },
      { "name": "Film driers", "code": "45121701" },
      { "name": "Film washers", "code": "45121702" },
      { "name": "Film splicers", "code": "45121703" },
      { "name": "Film editors", "code": "45121704" },
      { "name": "Photographic enlargers", "code": "45121705" },
      { "name": "Photo cutters or trimmers", "code": "45121706" },
      { "name": "Photo print dryer", "code": "45121707" },
      { "name": "Photo print washer", "code": "45121708" },
      { "name": "Automated film processor", "code": "45121709" },
      { "name": "Sheet film drum", "code": "45121710" },
      { "name": "Color controller", "code": "45121711" },
      { "name": "Film editing desk", "code": "45121712" },
      { "name": "Film inspection machine", "code": "45121713" },
      { "name": "Film reader", "code": "45121714" },
      { "name": "Film hanger", "code": "45121715" },
      { "name": "Contact printer", "code": "45121716" },
      { "name": "Film platemaking system", "code": "45121717" },
      { "name": "Film densitometer", "code": "45121718" },
      { "name": "Digital photo printing kiosk", "code": "45121719" },
      { "name": "Film loader", "code": "45121720" },
      { "name": "Monocomparator and accessories", "code": "45121721" },
      {
        "name": "Microfilm production equipment and supplies",
        "code": "45121800"
      },
      { "name": "Microfilm cameras", "code": "45121801" },
      { "name": "Microfilm duplicators", "code": "45121802" },
      { "name": "Microfilm jacket fillers", "code": "45121803" },
      { "name": "Microfilm processors", "code": "45121804" },
      {
        "name": "Microfilm camera components or accessories",
        "code": "45121805"
      },
      {
        "name": "Microfilm duplicator components or accessories",
        "code": "45121806"
      },
      {
        "name": "Microfilm jacket filler components or accessories",
        "code": "45121807"
      },
      {
        "name": "Microfilm processor components or accessories",
        "code": "45121808"
      },
      { "name": "Microfilm film supplies", "code": "45121809" },
      {
        "name": "Microfilm sundry components or accessories",
        "code": "45121810"
      },
      { "name": "Photographic and recording media", "code": "45130000" },
      { "name": "Still picture film", "code": "45131500" },
      { "name": "Color film", "code": "45131501" },
      { "name": "Black and white film", "code": "45131502" },
      { "name": "Instant picture film", "code": "45131503" },
      { "name": "X ray film", "code": "45131505" },
      { "name": "Slide film", "code": "45131506" },
      { "name": "Processed microfilm", "code": "45131507" },
      { "name": "Negative film", "code": "45131508" },
      { "name": "Film spotting color", "code": "45131509" },
      { "name": "Photographic dye", "code": "45131510" },
      { "name": "Moving picture media", "code": "45131600" },
      { "name": "Motion picture camera film", "code": "45131601" },
      { "name": "Blank video tapes", "code": "45131604" },
      { "name": "Media storage", "code": "45131700" },
      { "name": "Slide trays or organizers", "code": "45131701" },
      { "name": "Photographic filmmaking supplies", "code": "45140000" },
      { "name": "Photographic processing chemicals", "code": "45141500" },
      { "name": "Developer solution", "code": "45141501" },
      { "name": "Fixative", "code": "45141502" },
      { "name": "Photo development processing kit", "code": "45141503" },
      { "name": "Photo enhancing and correcting solution", "code": "45141504" },
      { "name": "Darkroom supplies", "code": "45141600" },
      { "name": "Developing trays", "code": "45141601" },
      { "name": "Developing tanks", "code": "45141602" },
      { "name": "Developing tongs", "code": "45141603" },
      { "name": "Photographic print processor", "code": "45141604" },
      { "name": "Photograph purifier", "code": "45141605" },
      { "name": "Developing tank reel", "code": "45141606" },
      { "name": "Camera obscura", "code": "45141607" },
      { "name": "Film squeegee", "code": "45141608" },
      { "name": "Film developing machine worktable", "code": "45141609" },
      { "name": "Photographic timer", "code": "45141610" },
      {
        "name": "Photographic processing temperature regulator",
        "code": "45141611"
      },
      { "name": "Portable darkroom", "code": "45141612" }
    ]
  },
  {
    "name": "Defense and Law Enforcement and Security and Safety Equipment and Supplies",
    "code": "46000000",
    "subcategories": [
      { "name": "Light weapons and ammunition", "code": "46100000" },
      { "name": "Firearms", "code": "46101500" },
      { "name": "Machine guns", "code": "46101501" },
      { "name": "Police or security shotguns", "code": "46101502" },
      { "name": "Military rifles", "code": "46101503" },
      { "name": "Handguns", "code": "46101504" },
      { "name": "Air rifles or air handguns", "code": "46101505" },
      { "name": "Parts of guns or pistols", "code": "46101506" },
      { "name": "Ammunition", "code": "46101600" },
      { "name": "Defense or law enforcement ammunition", "code": "46101601" },
      { "name": "Ammunition handling systems", "code": "46101700" },
      { "name": "Tank ammunition handling systems", "code": "46101701" },
      { "name": "Aircraft ammunition handling systems", "code": "46101702" },
      { "name": "Explosive container", "code": "46101703" },
      { "name": "Arms and ammunition accessories", "code": "46101800" },
      { "name": "Gun cases", "code": "46101801" },
      { "name": "Cartridge belt", "code": "46101802" },
      { "name": "Bladed weapons and accessories", "code": "46101900" },
      { "name": "Sword", "code": "46101901" },
      { "name": "Military knife or dagger", "code": "46101902" },
      { "name": "Bladed weapon accessories", "code": "46101903" },
      { "name": "Conventional war weapons", "code": "46110000" },
      { "name": "Bombs and grenades", "code": "46111500" },
      { "name": "Grenades", "code": "46111501" },
      { "name": "Mines", "code": "46111502" },
      { "name": "Mortar bombs", "code": "46111503" },
      { "name": "Abandoned Explosive Ordnance AXO", "code": "46111504" },
      { "name": "Explosive remnants of war ERW", "code": "46111505" },
      { "name": "Unexploded ordnance UXO", "code": "46111506" },
      { "name": "Gun systems", "code": "46111600" },
      { "name": "Chain gun systems", "code": "46111601" },
      { "name": "Gatling gun systems", "code": "46111602" },
      { "name": "Infrared IR sensors", "code": "46111700" },
      { "name": "Infrared IR coolers", "code": "46111701" },
      { "name": "Infrared IR detectors", "code": "46111702" },
      { "name": "Infrared IR illuminator", "code": "46111703" },
      { "name": "Infrared IR receiver", "code": "46111704" },
      { "name": "Infrared IR telescope", "code": "46111705" },
      { "name": "Naval weapons", "code": "46111800" },
      { "name": "Torpedoes", "code": "46111801" },
      { "name": "Missiles", "code": "46120000" },
      { "name": "Guided missiles", "code": "46121500" },
      { "name": "Air to air missiles", "code": "46121501" },
      { "name": "Antiaircraft missiles", "code": "46121502" },
      { "name": "Antimissile missiles", "code": "46121503" },
      { "name": "Antiship missiles", "code": "46121504" },
      { "name": "Antitank missiles", "code": "46121505" },
      { "name": "Ballistic missiles", "code": "46121506" },
      { "name": "Cruise missiles", "code": "46121507" },
      { "name": "Surface to air missiles", "code": "46121508" },
      { "name": "Antiballistic missiles", "code": "46121509" },
      { "name": "Surface to surface missiles", "code": "46121510" },
      { "name": "Air to surface missiles", "code": "46121511" },
      { "name": "Training missiles", "code": "46121512" },
      { "name": "Missile subsystems", "code": "46121600" },
      { "name": "Electronic safe or arm devices", "code": "46121601" },
      { "name": "Solid missile boosters", "code": "46121602" },
      { "name": "Missile warheads", "code": "46121603" },
      { "name": "Safety pin pullers", "code": "46121604" },
      { "name": "Jet reaction control assemblies", "code": "46121605" },
      { "name": "Rockets and subsystems", "code": "46130000" },
      { "name": "Launch vehicles and rockets", "code": "46131500" },
      { "name": "Multi stage rockets", "code": "46131501" },
      { "name": "Reusable rockets", "code": "46131502" },
      { "name": "Single stage rockets", "code": "46131503" },
      { "name": "Liquid rockets", "code": "46131504" },
      { "name": "Solid rockets", "code": "46131505" },
      { "name": "Boosters", "code": "46131600" },
      { "name": "Reusable boosters", "code": "46131601" },
      { "name": "Solid boosters", "code": "46131602" },
      { "name": "Multi stage boosters", "code": "46131603" },
      { "name": "Liquid boosters", "code": "46131604" },
      { "name": "Launchers", "code": "46140000" },
      { "name": "Missile and rocket launchers", "code": "46141500" },
      { "name": "Missile launchers", "code": "46141501" },
      { "name": "Rocket launchers", "code": "46141502" },
      { "name": "Law enforcement", "code": "46150000" },
      { "name": "Crowd control equipment", "code": "46151500" },
      { "name": "Barricades", "code": "46151501" },
      { "name": "Riot helmets", "code": "46151502" },
      { "name": "Riot shields", "code": "46151503" },
      { "name": "Body armour", "code": "46151504" },
      { "name": "Barriers", "code": "46151505" },
      { "name": "Riot batons", "code": "46151506" },
      { "name": "Queuing control system", "code": "46151507" },
      { "name": "Helmet, bullet proof", "code": "46151508" },
      { "name": "Security and control equipment", "code": "46151600" },
      { "name": "Handcuffs", "code": "46151601" },
      { "name": "Night sticks", "code": "46151602" },
      { "name": "Alcohol analysers", "code": "46151604" },
      {
        "name": "Weapon or explosives detectors and supplies",
        "code": "46151605"
      },
      { "name": "Narcotic test kits", "code": "46151606" },
      { "name": "Security lanyards", "code": "46151607" },
      { "name": "Bomb protection devices and supplies", "code": "46151608" },
      { "name": "Identification card discriminator", "code": "46151609" },
      {
        "name": "Forensic equipment and supplies and accessories",
        "code": "46151700"
      },
      { "name": "Fingerprint applicators or brushes", "code": "46151702" },
      { "name": "Fingerprint ink", "code": "46151703" },
      { "name": "Fingerprint ink removers", "code": "46151704" },
      { "name": "Fingerprint or palmprint ink rollers", "code": "46151705" },
      { "name": "Fingerprint latent print kits", "code": "46151706" },
      { "name": "Fingerprint lifters", "code": "46151707" },
      { "name": "Forensic magnifiers", "code": "46151708" },
      { "name": "Fingerprint marking pens", "code": "46151709" },
      { "name": "Fingerprint powders", "code": "46151710" },
      { "name": "Footprint lifters", "code": "46151711" },
      { "name": "Forensic chemical workstations", "code": "46151712" },
      { "name": "Forensic latent print chemicals", "code": "46151713" },
      { "name": "Evidence drying cabinets", "code": "46151714" },
      { "name": "Fingerprint equipment", "code": "46151715" },
      { "name": "Lie detector", "code": "46151716" },
      {
        "name": "Explosives control equipment and accessories and supplies",
        "code": "46151800"
      },
      { "name": "Blast guard container", "code": "46151801" },
      {
        "name": "Chemical biological control equipment and accessories and supplies",
        "code": "46151900"
      },
      {
        "name": "Chemical biological decontamination trailer",
        "code": "46151901"
      },
      { "name": "Public safety and control", "code": "46160000" },
      { "name": "Traffic control", "code": "46161500" },
      { "name": "Airport signaling systems", "code": "46161501" },
      { "name": "Railway signaling systems", "code": "46161502" },
      { "name": "Marine signaling systems", "code": "46161503" },
      { "name": "Traffic signals", "code": "46161504" },
      { "name": "Parking meters", "code": "46161505" },
      { "name": "Snow or ice melter", "code": "46161506" },
      { "name": "Barrier tapes or chains", "code": "46161507" },
      { "name": "Traffic cones or delineators", "code": "46161508" },
      { "name": "Speed stoppers", "code": "46161509" },
      { "name": "Gate barrier systems", "code": "46161510" },
      { "name": "Traffic beacon", "code": "46161511" },
      { "name": "Traffic chain", "code": "46161512" },
      { "name": "Traffic safety fence", "code": "46161513" },
      { "name": "Cats eye road stud", "code": "46161514" },
      { "name": "Crash cushion", "code": "46161515" },
      { "name": "License plate recognition system", "code": "46161516" },
      { "name": "Lane dividing rail", "code": "46161517" },
      { "name": "Traffic control channelizer drum", "code": "46161518" },
      { "name": "Traffic lane tape", "code": "46161519" },
      { "name": "Variable message sign", "code": "46161520" },
      { "name": "Parking lot entry exit warning light", "code": "46161521" },
      { "name": "Parking lot proximity warning light", "code": "46161522" },
      { "name": "Parking lot snow melter", "code": "46161523" },
      { "name": "Traffic flow analyzer", "code": "46161524" },
      { "name": "Vehicle detection loop coil", "code": "46161525" },
      { "name": "Pedestrian sensor", "code": "46161526" },
      { "name": "Traffic flow sensor", "code": "46161527" },
      { "name": "Airport warning light", "code": "46161528" },
      { "name": "Voice guide device for blind person", "code": "46161529" },
      { "name": "Parking barrier gate", "code": "46161530" },
      { "name": "Traffic safety mirror", "code": "46161531" },
      { "name": "Retracting or moveable traffic bollard", "code": "46161532" },
      { "name": "Fixed traffic bollard", "code": "46161533" },
      { "name": "Vehicle parking concave plate", "code": "46161534" },
      { "name": "Water safety", "code": "46161600" },
      { "name": "Rope float lines", "code": "46161601" },
      { "name": "Life rings", "code": "46161602" },
      { "name": "Pool alarms", "code": "46161603" },
      { "name": "Life vests or preservers", "code": "46161604" },
      { "name": "Life buoy", "code": "46161605" },
      { "name": "Rescue equipment and accessories", "code": "46161700" },
      { "name": "Descending life line", "code": "46161701" },
      { "name": "Rescue air bag", "code": "46161702" },
      { "name": "Safety air mat", "code": "46161703" },
      { "name": "Rescue line launcher", "code": "46161704" },
      { "name": "Hydraulic rescue equipment set", "code": "46161705" },
      { "name": "Air tent", "code": "46161706" },
      { "name": "Rescue door opener", "code": "46161707" },
      { "name": "Rescue net", "code": "46161708" },
      { "name": "Rescue light", "code": "46161710" },
      { "name": "Life detector", "code": "46161711" },
      { "name": "Personal safety alert", "code": "46161712" },
      { "name": "Smoke signal device", "code": "46161713" },
      { "name": "Life line", "code": "46161714" },
      { "name": "Confined space rescue equipment", "code": "46161715" },
      { "name": "Security surveillance and detection", "code": "46170000" },
      {
        "name": "Locks and security hardware and accessories",
        "code": "46171500"
      },
      { "name": "Padlocks", "code": "46171501" },
      { "name": "Cable locks", "code": "46171502" },
      { "name": "Lock sets", "code": "46171503" },
      { "name": "Pushbutton locks", "code": "46171504" },
      { "name": "Keys", "code": "46171505" },
      { "name": "Safes", "code": "46171506" },
      { "name": "Security bars", "code": "46171507" },
      { "name": "Number locks", "code": "46171508" },
      { "name": "Key cabinets or organizers", "code": "46171509" },
      { "name": "Time locks", "code": "46171510" },
      { "name": "Lockout devices", "code": "46171511" },
      { "name": "Instrument locks", "code": "46171512" },
      { "name": "Locking cam", "code": "46171513" },
      { "name": "Security chains or accessories", "code": "46171514" },
      { "name": "Key chains or key cases", "code": "46171515" },
      { "name": "Door guards", "code": "46171516" },
      { "name": "Keyhole signals", "code": "46171517" },
      { "name": "Electric strike plate", "code": "46171518" },
      { "name": "Lock cylinder accessory", "code": "46171519" },
      { "name": "Electric lock", "code": "46171520" },
      { "name": "Non electric strike plate", "code": "46171521" },
      { "name": "Lock housing", "code": "46171522" },
      { "name": "Mechanical lock system", "code": "46171523" },
      { "name": "Card key lock", "code": "46171524" },
      { "name": "Key card for lock", "code": "46171525" },
      { "name": "Surveillance and detection equipment", "code": "46171600" },
      { "name": "Safety horns", "code": "46171602" },
      { "name": "Clock timers", "code": "46171603" },
      { "name": "Alarm systems", "code": "46171604" },
      { "name": "Door chimes", "code": "46171605" },
      { "name": "Sirens", "code": "46171606" },
      { "name": "Buzzers", "code": "46171607" },
      { "name": "Motion detectors", "code": "46171608" },
      { "name": "Convex security mirrors", "code": "46171609" },
      { "name": "Security cameras", "code": "46171610" },
      { "name": "Video identification systems", "code": "46171611" },
      { "name": "Video monitors", "code": "46171612" },
      { "name": "Gas detectors", "code": "46171613" },
      {
        "name": "Light enhancing cameras or vision devices",
        "code": "46171615"
      },
      { "name": "Radar detectors", "code": "46171616" },
      { "name": "Door eyes", "code": "46171617" },
      { "name": "Door bells", "code": "46171618" },
      { "name": "Security or access control systems", "code": "46171619" },
      { "name": "Safety light curtains", "code": "46171620" },
      { "name": "Surveillance video or audio recorders", "code": "46171621" },
      { "name": "Closed circuit television CCTV system", "code": "46171622" },
      { "name": "Home security alarm", "code": "46171623" },
      { "name": "X ray baggage inspection system", "code": "46171624" },
      { "name": "Radio communication monitoring system", "code": "46171625" },
      { "name": "Night vision equipment", "code": "46171626" },
      {
        "name": "Wireless signaler system for hearing impaired",
        "code": "46171627"
      },
      { "name": "Automatic time of day indicator", "code": "46171628" },
      { "name": "Signal mirror", "code": "46171629" },
      { "name": "Vehicle rain and water level sensor", "code": "46171630" },
      { "name": "Seismic alarm", "code": "46171631" },
      { "name": "Passive infrared sensor", "code": "46171632" },
      { "name": "Security metal detector", "code": "46171633" },
      { "name": "Chemical agent detector paper", "code": "46171634" },
      { "name": "Chemical agent detector", "code": "46171635" },
      {
        "name": "Security facsimile transmission equipment",
        "code": "46171636"
      },
      { "name": "Wiretap device", "code": "46171637" },
      { "name": "Radiation detector and alarm", "code": "46171638" },
      { "name": "Traffic video compensator", "code": "46171639" },
      { "name": "Automatic traffic monitoring system", "code": "46171640" },
      { "name": "Vehicle access control", "code": "46171700" },
      { "name": "Vehicle parking permit", "code": "46171701" },
      { "name": "Personal safety and protection", "code": "46180000" },
      { "name": "Safety apparel", "code": "46181500" },
      { "name": "Protective aprons", "code": "46181501" },
      { "name": "Bullet proof vests", "code": "46181502" },
      { "name": "Protective coveralls", "code": "46181503" },
      { "name": "Protective gloves", "code": "46181504" },
      { "name": "Protective knee pads", "code": "46181505" },
      { "name": "Protective ponchos", "code": "46181506" },
      { "name": "Safety vests", "code": "46181507" },
      { "name": "Fire retardant apparel", "code": "46181508" },
      { "name": "Hazardous material protective apparel", "code": "46181509" },
      { "name": "Cleanroom apparel", "code": "46181512" },
      { "name": "Elbow protectors", "code": "46181514" },
      { "name": "Safety sleeves", "code": "46181516" },
      { "name": "Insulated or flotation suits", "code": "46181517" },
      { "name": "Heat resistant clothing", "code": "46181518" },
      { "name": "Leg protectors", "code": "46181520" },
      { "name": "Safety hoods", "code": "46181522" },
      { "name": "Protective shirts", "code": "46181526" },
      { "name": "Protective pants", "code": "46181527" },
      { "name": "Protective frock", "code": "46181528" },
      {
        "name": "Insulated clothing for cold environments",
        "code": "46181529"
      },
      { "name": "Protective finger cots", "code": "46181530" },
      { "name": "Reflective apparel or accessories", "code": "46181531" },
      { "name": "Lab coats", "code": "46181532" },
      { "name": "Protective coats", "code": "46181533" },
      { "name": "Protective wristbands", "code": "46181534" },
      { "name": "Protective socks or hosiery", "code": "46181535" },
      { "name": "Anti cut gloves", "code": "46181536" },
      { "name": "Insulated gloves", "code": "46181537" },
      { "name": "Thermal gloves", "code": "46181538" },
      { "name": "Anti vibratory gloves", "code": "46181539" },
      { "name": "Welder gloves", "code": "46181540" },
      { "name": "Chemical resistant gloves", "code": "46181541" },
      { "name": "Protective mittens", "code": "46181542" },
      { "name": "Waterproof jacket or raincoat", "code": "46181543" },
      { "name": "Waterproof trousers or pants", "code": "46181544" },
      { "name": "Waterproof suit", "code": "46181545" },
      { "name": "Waterproof cap", "code": "46181546" },
      { "name": "Waterproof cap cover", "code": "46181547" },
      { "name": "Welder bib", "code": "46181548" },
      { "name": "Neck gaitor", "code": "46181549" },
      { "name": "Protective scarf", "code": "46181550" },
      { "name": "Protective mesh jacket", "code": "46181551" },
      { "name": "Mining headlamp", "code": "46181552" },
      { "name": "Protective wear dispenser", "code": "46181553" },
      { "name": "Safety footwear", "code": "46181600" },
      { "name": "Fire retardant footwear", "code": "46181601" },
      { "name": "Hazardous material protective footwear", "code": "46181602" },
      { "name": "Cleanroom footwear", "code": "46181603" },
      { "name": "Safety boots", "code": "46181604" },
      { "name": "Safety shoes", "code": "46181605" },
      { "name": "Footwear covers", "code": "46181606" },
      { "name": "Protective clogs", "code": "46181607" },
      { "name": "Protective sandals", "code": "46181608" },
      { "name": "Protective insole", "code": "46181609" },
      { "name": "Insulated cold weather shoe", "code": "46181610" },
      { "name": "Waterproof boot", "code": "46181611" },
      { "name": "Military boot", "code": "46181612" },
      { "name": "Mountain climbing boot", "code": "46181613" },
      { "name": "Face and head protection", "code": "46181700" },
      { "name": "Hard hats", "code": "46181701" },
      { "name": "Facial shields", "code": "46181702" },
      { "name": "Welding masks", "code": "46181703" },
      { "name": "Safety helmets", "code": "46181704" },
      { "name": "Motorcycle helmets", "code": "46181705" },
      { "name": "Helmet parts or accessories", "code": "46181706" },
      { "name": "Facial shields parts or accessories", "code": "46181707" },
      { "name": "Protective hair net", "code": "46181708" },
      { "name": "Face protection kit", "code": "46181709" },
      { "name": "Protective hood", "code": "46181710" },
      { "name": "Welders helmet", "code": "46181711" },
      { "name": "Vision protection and accessories", "code": "46181800" },
      { "name": "Eyewear holders or cases", "code": "46181801" },
      { "name": "Safety glasses", "code": "46181802" },
      { "name": "Eye shields", "code": "46181803" },
      { "name": "Goggles", "code": "46181804" },
      { "name": "Video display filters", "code": "46181805" },
      { "name": "Lens cleaner", "code": "46181806" },
      { "name": "Goggle protective covers", "code": "46181808" },
      { "name": "Eye shield garters", "code": "46181809" },
      { "name": "Eyewashers or eye wash stations", "code": "46181810" },
      { "name": "Protective lens", "code": "46181811" },
      { "name": "Hearing protectors", "code": "46181900" },
      { "name": "Ear plugs", "code": "46181901" },
      { "name": "Ear muffs", "code": "46181902" },
      {
        "name": "Earmuff replacement parts or accessories",
        "code": "46181903"
      },
      { "name": "Ear plug dispenser", "code": "46181904" },
      { "name": "Respiratory protection", "code": "46182000" },
      { "name": "Masks or accessories", "code": "46182001" },
      { "name": "Respirators", "code": "46182002" },
      { "name": "Gas masks", "code": "46182003" },
      {
        "name": "Respiration air supplying self contained breathing apparatus or accessories",
        "code": "46182004"
      },
      {
        "name": "Mask or respirators filters or accessories",
        "code": "46182005"
      },
      { "name": "Protective films", "code": "46182006" },
      {
        "name": "Powered air purifying respirator system PAPRs or accessories",
        "code": "46182007"
      },
      { "name": "Anti static equipment and supplies", "code": "46182100" },
      { "name": "Antistatic wrist straps", "code": "46182101" },
      { "name": "Heel grounding straps", "code": "46182102" },
      { "name": "Grounding hardware", "code": "46182103" },
      { "name": "Anti static floor mats", "code": "46182104" },
      { "name": "Anti static worktable mats", "code": "46182105" },
      { "name": "Antistatic belts", "code": "46182106" },
      { "name": "Antistatic maintenance kits", "code": "46182107" },
      { "name": "Antistatic toe straps", "code": "46182108" },
      { "name": "Ergonomic support aids", "code": "46182200" },
      { "name": "Back support belts", "code": "46182201" },
      { "name": "Elbow supports", "code": "46182202" },
      { "name": "Back support rests", "code": "46182203" },
      { "name": "Wrist braces", "code": "46182204" },
      { "name": "Foot rests", "code": "46182205" },
      { "name": "Wrist rests", "code": "46182206" },
      { "name": "Ankle supports", "code": "46182207" },
      { "name": "Shoe insoles", "code": "46182208" },
      { "name": "Knee supports", "code": "46182209" },
      { "name": "Thigh protector or brace", "code": "46182210" },
      { "name": "Lumbar protector or back brace", "code": "46182211" },
      { "name": "Shoulder protector or support", "code": "46182212" },
      { "name": "Cervical pillow", "code": "46182213" },
      { "name": "Fall protection and rescue equipment", "code": "46182300" },
      { "name": "Lifelines or lifeline equipment", "code": "46182301" },
      { "name": "Fall protection lanyard", "code": "46182302" },
      { "name": "Safety harness winders", "code": "46182303" },
      { "name": "Anchorage connector", "code": "46182304" },
      { "name": "Self retracting lanyard", "code": "46182305" },
      { "name": "Safety harnesses or belts", "code": "46182306" },
      { "name": "Evacuation harnesses", "code": "46182307" },
      { "name": "Pulling grips", "code": "46182308" },
      {
        "name": "Safety rope ladders and wire rope ladders",
        "code": "46182309"
      },
      { "name": "Ascender", "code": "46182310" },
      { "name": "Belay device", "code": "46182311" },
      { "name": "Descender", "code": "46182312" },
      { "name": "Swivel carabiner", "code": "46182313" },
      { "name": "Safety sling", "code": "46182314" },
      { "name": "Rubber hand rail", "code": "46182315" },
      {
        "name": "Decontamination aids and safety cleaning equipment",
        "code": "46182400"
      },
      { "name": "Decontamination shower", "code": "46182401" },
      { "name": "Safety wash units", "code": "46182402" },
      { "name": "Clean booth", "code": "46182403" },
      { "name": "Clean locker", "code": "46182404" },
      { "name": "Air shower", "code": "46182405" },
      { "name": "Pass box", "code": "46182406" },
      { "name": "Chemical spill safety kit", "code": "46182407" },
      { "name": "Personal safety devices or weapons", "code": "46182500" },
      { "name": "Repellents for canine attacks", "code": "46182501" },
      { "name": "Personal safety light", "code": "46182502" },
      { "name": "Undervoltage alarm", "code": "46182503" },
      { "name": "Fire alarm control panel", "code": "46182504" },
      { "name": "Earth leakage detector and fire alarm", "code": "46182505" },
      {
        "name": "Electromagnetic interference EMI shielding equipment",
        "code": "46182506"
      },
      {
        "name": "Electromagnetic interference EMI shielding material",
        "code": "46182507"
      },
      { "name": "Fire protection", "code": "46190000" },
      { "name": "Fire prevention", "code": "46191500" },
      { "name": "Smoke detectors", "code": "46191501" },
      { "name": "Heat detectors", "code": "46191502" },
      {
        "name": "Fire resistant coatings or putties or sealants",
        "code": "46191503"
      },
      { "name": "Flame detectors", "code": "46191504" },
      { "name": "Fire alarm systems", "code": "46191505" },
      { "name": "Flame arrestor", "code": "46191506" },
      { "name": "Passive firestop system", "code": "46191507" },
      { "name": "Belt monitoring system", "code": "46191508" },
      { "name": "Toxic vapor detector", "code": "46191509" },
      { "name": "Thermal observation device TOD", "code": "46191510" },
      { "name": "Fire hot spot detector", "code": "46191511" },
      { "name": "Fire fighting equipment", "code": "46191600" },
      { "name": "Fire extinguishers", "code": "46191601" },
      { "name": "Fire sprinkler systems", "code": "46191602" },
      { "name": "Fire hoses or nozzles", "code": "46191603" },
      { "name": "Fire blankets", "code": "46191604" },
      { "name": "Fire suppression hand tools", "code": "46191605" },
      {
        "name": "Fire suppression foam or similar compounds",
        "code": "46191606"
      },
      { "name": "Fire breathing apparatus", "code": "46191607" },
      { "name": "Fire suppression system", "code": "46191608" },
      { "name": "Fire escape equipment", "code": "46191609" },
      { "name": "Fire sprinkler heads", "code": "46191610" },
      { "name": "Fire hose cart", "code": "46191611" },
      { "name": "Backpack water pump", "code": "46191612" },
      { "name": "Fire extinguishing agent", "code": "46191613" },
      { "name": "Fire foam liquid proportioner", "code": "46191614" },
      { "name": "Firefighting standpipe", "code": "46191615" },
      {
        "name": "Helicopter fire extinguishing water tank",
        "code": "46191616"
      },
      { "name": "Fire hose washer", "code": "46191617" },
      { "name": "Fire extinguisher base", "code": "46191618" },
      { "name": "Fire water monitor", "code": "46191619" },
      { "name": "Smoke exhaust fan", "code": "46191620" },
      { "name": "Fire extinguisher storage box", "code": "46191621" },
      {
        "name": "Defense and law enforcement and security and safety training equipment",
        "code": "46200000"
      },
      { "name": "Public safety training equipment", "code": "46201000" },
      { "name": "Rescue mannequin", "code": "46201001" },
      {
        "name": "Disaster management training simulation system",
        "code": "46201002"
      },
      { "name": "Small arms weapons training equipment", "code": "46201100" },
      { "name": "Imitation rifle", "code": "46201101" },
      { "name": "Firing or shooting range", "code": "46201102" },
      {
        "name": "Workplace safety equipment and supplies and training materials",
        "code": "46210000"
      },
      {
        "name": "Workplace safety training aids and materials",
        "code": "46211500"
      },
      { "name": "Workplace safety training kit", "code": "46211501" },
      { "name": "Workplace safety training video", "code": "46211502" },
      {
        "name": "Workplace safety training manual or handbook",
        "code": "46211503"
      },
      {
        "name": "Workplace safety internet based training",
        "code": "46211504"
      },
      { "name": "Work area marking and visual indicators", "code": "46211600" },
      { "name": "Work area barricade tape and flags", "code": "46211601" },
      { "name": "Work area warning posts and chains", "code": "46211602" },
      { "name": "Minefield or mine hazard area marker", "code": "46211603" },
      {
        "name": "Minefield or mine hazard area marking signage",
        "code": "46211604"
      },
      {
        "name": "Military weapon and ammunition disarmament and disposal equipment and related products",
        "code": "46220000"
      },
      { "name": "Demining equipment and related products", "code": "46221500" },
      { "name": "Mine anti-tampering device", "code": "46221501" },
      { "name": "Demining machine", "code": "46221502" },
      { "name": "Anti explosive device detonator", "code": "46221503" },
      { "name": "Mine dispenser", "code": "46221504" },
      { "name": "Intrusive demining machine", "code": "46221505" },
      { "name": "Non intrusive demining machine", "code": "46221506" },
      { "name": "Demining machine parts and accessories", "code": "46221507" },
      { "name": "Mine detecting dog MDD", "code": "46221508" },
      { "name": "Mine self destruction mechanism", "code": "46221509" },
      { "name": "Mine self neutralization equipment", "code": "46221510" }
    ]
  },
  {
    "name": "Cleaning Equipment and Supplies",
    "code": "47000000",
    "subcategories": [
      {
        "name": "Water and wastewater treatment supply and disposal",
        "code": "47100000"
      },
      { "name": "Water treatment and supply equipment", "code": "47101500" },
      { "name": "Activated carbon equipment", "code": "47101501" },
      { "name": "Ammonia removal equipment", "code": "47101502" },
      { "name": "Carbon filtration equipment", "code": "47101503" },
      { "name": "Bacterial removal equipment", "code": "47101504" },
      { "name": "Chlorine handling equipment", "code": "47101505" },
      { "name": "Corrosion control equipment", "code": "47101506" },
      { "name": "Grit chambers", "code": "47101507" },
      { "name": "Desalination equipment", "code": "47101508" },
      { "name": "Fluoridation equipment", "code": "47101509" },
      { "name": "Iron removal equipment", "code": "47101510" },
      { "name": "Ion exchange equipment", "code": "47101511" },
      { "name": "Mixers or agitators", "code": "47101512" },
      { "name": "Oxygen generators", "code": "47101513" },
      { "name": "Water purification equipment", "code": "47101514" },
      { "name": "Turbidimeters", "code": "47101516" },
      { "name": "Ultraviolet disinfection equipment", "code": "47101517" },
      { "name": "Water conditioners", "code": "47101518" },
      { "name": "Water softening accessories", "code": "47101519" },
      { "name": "Ultrafiltration equipment", "code": "47101521" },
      { "name": "Packaged water treatment systems", "code": "47101522" },
      { "name": "Collection tanks", "code": "47101523" },
      { "name": "Sludge or sewage composting equipment", "code": "47101524" },
      { "name": "Dewatering equipment", "code": "47101525" },
      { "name": "Sludge pelletizers", "code": "47101526" },
      { "name": "Sludge shredders", "code": "47101527" },
      { "name": "Water treatment dryers", "code": "47101528" },
      { "name": "Incinerators", "code": "47101529" },
      { "name": "Odor control equipment", "code": "47101530" },
      { "name": "Septic tanks", "code": "47101531" },
      { "name": "Settling tanks", "code": "47101532" },
      { "name": "Lift stations", "code": "47101533" },
      { "name": "Sewage distributors", "code": "47101534" },
      { "name": "Sludge disposal equipment", "code": "47101535" },
      { "name": "Sludge collectors", "code": "47101536" },
      { "name": "Sludge conditioning equipment", "code": "47101537" },
      { "name": "Sludge or sewage digesters", "code": "47101538" },
      { "name": "Sludge or sewage removal equipment", "code": "47101539" },
      { "name": "Chemical feeder", "code": "47101540" },
      { "name": "Ozone water treatment equipment", "code": "47101541" },
      { "name": "Air flotation equipment", "code": "47101542" },
      { "name": "Surface aerator", "code": "47101543" },
      { "name": "Air diffuser for sewage treatment", "code": "47101544" },
      { "name": "Rotating biological contactor RBC", "code": "47101545" },
      { "name": "Grit removal system", "code": "47101546" },
      { "name": "Surface washing device", "code": "47101547" },
      { "name": "Filter control console", "code": "47101548" },
      { "name": "Gravity filter", "code": "47101549" },
      { "name": "Compression filter", "code": "47101550" },
      { "name": "Up flow filter", "code": "47101551" },
      { "name": "Moving bed filter", "code": "47101552" },
      { "name": "Fiber filter", "code": "47101553" },
      { "name": "Jar tester", "code": "47101554" },
      { "name": "Baffle wall", "code": "47101555" },
      { "name": "Water trough", "code": "47101556" },
      { "name": "Inclined plate settler", "code": "47101557" },
      { "name": "Foam breaker", "code": "47101558" },
      { "name": "Scum removal equipment", "code": "47101559" },
      { "name": "Filter underdrain equipment", "code": "47101560" },
      { "name": "Adulteration treatment equipment", "code": "47101561" },
      { "name": "Drum screen", "code": "47101562" },
      { "name": "Wastewater coalescer", "code": "47101563" },
      { "name": "Waste crusher", "code": "47101564" },
      { "name": "Waste gas burner", "code": "47101565" },
      { "name": "Multiple media filter", "code": "47101566" },
      { "name": "Electrodialysis machine", "code": "47101567" },
      { "name": "Swimming pool cleaning unit", "code": "47101568" },
      { "name": "Wastewater plane screen", "code": "47101569" },
      { "name": "Oxidation ditch", "code": "47101570" },
      { "name": "Chlorine gas neutralization unit", "code": "47101571" },
      { "name": "Disc screen", "code": "47101572" },
      { "name": "Flocculator", "code": "47101573" },
      { "name": "Sequencing batch reactor", "code": "47101574" },
      { "name": "Water treatment consumables", "code": "47101600" },
      { "name": "Algaecides", "code": "47101601" },
      { "name": "Antiscalants", "code": "47101602" },
      { "name": "Descalers", "code": "47101603" },
      { "name": "Boiler feed chemicals", "code": "47101604" },
      { "name": "Bacterial removal chemicals", "code": "47101605" },
      { "name": "Corrosion control chemicals", "code": "47101606" },
      { "name": "Odor control chemicals", "code": "47101607" },
      { "name": "Flocculents", "code": "47101608" },
      { "name": "Microbiocides", "code": "47101609" },
      { "name": "Water softening compounds", "code": "47101610" },
      { "name": "Demulsifiers", "code": "47101611" },
      { "name": "Polyelectrolytes", "code": "47101612" },
      { "name": "Buffer solutions", "code": "47101613" },
      { "name": "Sludge bulking agent", "code": "47101614" },
      { "name": "Water fluoridation chemical", "code": "47101615" },
      {
        "name": "Industrial laundry and dry cleaning equipment",
        "code": "47110000"
      },
      { "name": "Washing and drying equipment", "code": "47111500" },
      {
        "name": "Laundry type combined washing or drying machines",
        "code": "47111501"
      },
      { "name": "Laundry type washing machines", "code": "47111502" },
      { "name": "Clothes dryers", "code": "47111503" },
      { "name": "Laundry equipment stands", "code": "47111505" },
      { "name": "Centrifugal laundry extractor", "code": "47111506" },
      { "name": "Laundry drying stand", "code": "47111507" },
      { "name": "Laundry spotting table", "code": "47111508" },
      { "name": "Laundry work table", "code": "47111509" },
      { "name": "Laundry wash tub or basin", "code": "47111510" },
      { "name": "Laundry starching machine", "code": "47111511" },
      { "name": "Ironing equipment", "code": "47111600" },
      { "name": "Ironing machines or presses", "code": "47111601" },
      { "name": "Folding machines", "code": "47111602" },
      { "name": "Steam pressing machines", "code": "47111603" },
      { "name": "Dry cleaning equipment", "code": "47111700" },
      { "name": "Dry cleaning machines", "code": "47111701" },
      { "name": "Janitorial equipment", "code": "47120000" },
      {
        "name": "Cleaning and janitorial carts and accessories",
        "code": "47121500"
      },
      { "name": "Cleaning or janitorial cart", "code": "47121501" },
      { "name": "Cleaning or janitorial cart accessories", "code": "47121502" },
      { "name": "Floor machines and accessories", "code": "47121600" },
      { "name": "Vacuum cleaners", "code": "47121602" },
      { "name": "Floor polishers", "code": "47121603" },
      { "name": "Wet or dry combination vacuum cleaners", "code": "47121604" },
      { "name": "Floor scrubbers", "code": "47121605" },
      { "name": "Carpet sweepers", "code": "47121606" },
      { "name": "Vacuum cleaner supplies or accessories", "code": "47121607" },
      { "name": "Floor machine pads", "code": "47121608" },
      { "name": "Carpet cleaning equipment", "code": "47121609" },
      { "name": "Floor washing machine", "code": "47121610" },
      { "name": "Floor scrapers", "code": "47121611" },
      { "name": "Floor sweepers", "code": "47121612" },
      { "name": "Floor polisher accessories", "code": "47121613" },
      { "name": "Waste containers and accessories", "code": "47121700" },
      { "name": "Trash bags", "code": "47121701" },
      { "name": "Waste containers or rigid liners", "code": "47121702" },
      { "name": "Smoking urns or accessories", "code": "47121703" },
      { "name": "Waste container lids", "code": "47121704" },
      { "name": "Urn sand bags", "code": "47121705" },
      { "name": "Ash trays", "code": "47121706" },
      { "name": "Motion sickness bags", "code": "47121707" },
      { "name": "Hygienic bags", "code": "47121708" },
      { "name": "Hazardous waste container", "code": "47121709" },
      { "name": "Food waste meter", "code": "47121710" },
      { "name": "Cleaning equipment", "code": "47121800" },
      { "name": "Cleaning dusters", "code": "47121801" },
      { "name": "Lint removers", "code": "47121802" },
      { "name": "Squeegees or washers", "code": "47121803" },
      { "name": "Cleaning pails or buckets", "code": "47121804" },
      { "name": "Pressure or steam cleaners", "code": "47121805" },
      { "name": "Mop wringer", "code": "47121806" },
      { "name": "Drain or toilet plunger", "code": "47121807" },
      { "name": "Drain or pipe cleaning equipment", "code": "47121808" },
      { "name": "Degreasing pans", "code": "47121809" },
      { "name": "Cleaning rag dispenser", "code": "47121810" },
      { "name": "Duct cleaning machines", "code": "47121811" },
      { "name": "Cleaning scrapers", "code": "47121812" },
      { "name": "Scraper replacement blades", "code": "47121813" },
      { "name": "Dust separator", "code": "47121814" },
      { "name": "Pool cleaner", "code": "47121815" },
      {
        "name": "Cleaning solution dispenser and accessories",
        "code": "47121816"
      },
      { "name": "Cleaning equipment accessories", "code": "47121900" },
      { "name": "Squeege or washer holsters", "code": "47121901" },
      { "name": "Squeege or washer accessories", "code": "47121902" },
      { "name": "Pressure or steam cleaner accessories", "code": "47121903" },
      { "name": "Spray ball", "code": "47121904" },
      { "name": "Cleaning and janitorial supplies", "code": "47130000" },
      { "name": "Cleaning rags and cloths and wipes", "code": "47131500" },
      { "name": "Rags", "code": "47131501" },
      { "name": "Cleaning cloths or wipes", "code": "47131502" },
      { "name": "Chamois or wash leathers", "code": "47131503" },
      {
        "name": "Brooms and mops and brushes and accessories",
        "code": "47131600"
      },
      { "name": "Dust brushes or pans", "code": "47131601" },
      { "name": "Scouring pads", "code": "47131602" },
      { "name": "Sponges", "code": "47131603" },
      { "name": "Brooms", "code": "47131604" },
      { "name": "Cleaning brushes", "code": "47131605" },
      { "name": "Toilet brushes", "code": "47131608" },
      { "name": "Broom or mop handles", "code": "47131609" },
      { "name": "Floor finish applicator", "code": "47131610" },
      { "name": "Trash picker", "code": "47131611" },
      { "name": "Replacement rubbers", "code": "47131612" },
      { "name": "Mop or broom holder", "code": "47131613" },
      { "name": "Cleaning equipment clamps", "code": "47131614" },
      { "name": "Broom heads", "code": "47131615" },
      { "name": "Cleaning pad holders", "code": "47131616" },
      { "name": "Dust mops", "code": "47131617" },
      { "name": "Wet mops", "code": "47131618" },
      { "name": "Mop heads", "code": "47131619" },
      { "name": "Restroom supplies", "code": "47131700" },
      { "name": "Paper towel dispensers", "code": "47131701" },
      { "name": "Sanitary goods dispensers", "code": "47131702" },
      { "name": "Sanitary waste receptacles", "code": "47131703" },
      { "name": "Institutional soap or lotion dispensers", "code": "47131704" },
      { "name": "Urinal or toilet accessories", "code": "47131705" },
      { "name": "Air freshener dispensers", "code": "47131706" },
      { "name": "Institutional hand dryers", "code": "47131707" },
      { "name": "Facial tissue dispensers", "code": "47131709" },
      { "name": "Toilet tissue dispensers", "code": "47131710" },
      { "name": "Cleaner dispenser", "code": "47131711" },
      { "name": "Cleaning and disinfecting solutions", "code": "47131800" },
      { "name": "Floor cleaners", "code": "47131801" },
      { "name": "Floor finishes or polishes", "code": "47131802" },
      { "name": "Household disinfectants", "code": "47131803" },
      { "name": "Ammonia cleaners", "code": "47131804" },
      { "name": "General purpose cleaners", "code": "47131805" },
      { "name": "Furniture polish or waxes", "code": "47131806" },
      { "name": "Bleaches", "code": "47131807" },
      { "name": "Dry germicidal", "code": "47131808" },
      { "name": "Shoe cleaning or polishing products", "code": "47131809" },
      { "name": "Dishwashing products", "code": "47131810" },
      { "name": "Laundry products", "code": "47131811" },
      { "name": "Air freshener", "code": "47131812" },
      { "name": "Screen cleaner", "code": "47131813" },
      { "name": "Metal cleaners or polishes", "code": "47131814" },
      { "name": "Drain cleaner", "code": "47131815" },
      { "name": "Deodorizers", "code": "47131816" },
      { "name": "Household or automotive protectants", "code": "47131817" },
      { "name": "Air sanitizer", "code": "47131818" },
      { "name": "Caustic cleaners", "code": "47131819" },
      { "name": "Petroleum derivative cleaners", "code": "47131820" },
      { "name": "Degreasing compounds", "code": "47131821" },
      { "name": "Carbon removing compounds", "code": "47131822" },
      { "name": "Deicers or defrosters", "code": "47131823" },
      { "name": "Glass or window cleaners", "code": "47131824" },
      { "name": "Contact surface cleaners", "code": "47131825" },
      { "name": "Carpet or upholstery cleaners", "code": "47131826" },
      { "name": "Stain cleaners or removers", "code": "47131827" },
      { "name": "Automotive cleaners", "code": "47131828" },
      { "name": "Toilet cleaners", "code": "47131829" },
      { "name": "Furniture cleaners", "code": "47131830" },
      { "name": "Muriatic acid", "code": "47131831" },
      { "name": "Anti dust products", "code": "47131832" },
      { "name": "Food grade sanitizers", "code": "47131833" },
      { "name": "Jewelry cleaning solutions", "code": "47131834" },
      { "name": "Desulfurizing agent", "code": "47131835" },
      { "name": "Flourescent cleanliness marking gel", "code": "47131836" },
      { "name": "Absorbents", "code": "47131900" },
      { "name": "Absorbent mats", "code": "47131901" },
      { "name": "Granular absorbent", "code": "47131902" },
      { "name": "Plugging compound", "code": "47131903" },
      { "name": "Absorbent socks", "code": "47131904" },
      { "name": "Spill kits", "code": "47131905" },
      { "name": "Absorbent pans", "code": "47131906" },
      { "name": "Absorbent booms", "code": "47131907" },
      { "name": "Absorbent pillows", "code": "47131908" },
      { "name": "Sorbent pads or rolls", "code": "47131909" },
      { "name": "Super absorbent polymer", "code": "47131910" },
      { "name": "Cleaning kits", "code": "47132100" },
      { "name": "Industrial cleaning kits", "code": "47132101" },
      { "name": "General purpose cleaning kits", "code": "47132102" }
    ]
  },
  {
    "name": "Service Industry Machinery and Equipment and Supplies",
    "code": "48000000",
    "subcategories": [
      { "name": "Institutional food services equipment", "code": "48100000" },
      { "name": "Cooking and warming equipment", "code": "48101500" },
      { "name": "Commercial use bain maries", "code": "48101501" },
      { "name": "Commercial use barbeque ovens", "code": "48101502" },
      { "name": "Commercial use broilers", "code": "48101503" },
      { "name": "Commercial use charcoal grills", "code": "48101504" },
      {
        "name": "Commercial use coffee or iced tea makers",
        "code": "48101505"
      },
      { "name": "Commercial use coffee warmers", "code": "48101506" },
      { "name": "Commercial use convection ovens", "code": "48101507" },
      { "name": "Commercial use conveyer toasters", "code": "48101508" },
      { "name": "Commercial use deep fryers", "code": "48101509" },
      { "name": "Commercial use food warmers", "code": "48101510" },
      { "name": "Commercial use griddles", "code": "48101511" },
      { "name": "Commercial use grills", "code": "48101512" },
      { "name": "Commercial use heat lamps", "code": "48101513" },
      { "name": "Commercial use high pressure steamers", "code": "48101514" },
      { "name": "Commercial use hot dog grills", "code": "48101515" },
      { "name": "Commercial use microwave ovens", "code": "48101516" },
      { "name": "Commercial use ovens", "code": "48101517" },
      { "name": "Commercial use pasta cookers", "code": "48101518" },
      { "name": "Commercial use pizza ovens", "code": "48101519" },
      { "name": "Commercial use popcorn machines", "code": "48101520" },
      { "name": "Commercial use ranges", "code": "48101521" },
      { "name": "Commercial use rotisseries", "code": "48101522" },
      { "name": "Commercial use smokers or smoke ovens", "code": "48101523" },
      { "name": "Commercial use steamers", "code": "48101524" },
      { "name": "Commercial use toasters", "code": "48101525" },
      { "name": "Commercial use waffle irons", "code": "48101526" },
      { "name": "Barbecues", "code": "48101527" },
      { "name": "Commercial use crepe machines", "code": "48101528" },
      { "name": "Pressure cookers or pressure fryers", "code": "48101529" },
      { "name": "Commercial use rice cookers", "code": "48101530" },
      { "name": "Commercial salmon poachers or kettles", "code": "48101531" },
      {
        "name": "Commercial use cotton candy machines or accessories",
        "code": "48101532"
      },
      { "name": "Commercial use combination oven", "code": "48101533" },
      { "name": "Commercial use boiling table", "code": "48101534" },
      { "name": "Commercial use salamander grill", "code": "48101535" },
      { "name": "Commercial use pastry oven", "code": "48101536" },
      { "name": "Commercial use conveyor oven", "code": "48101537" },
      { "name": "Commercial use double contact grill", "code": "48101538" },
      { "name": "Commercial use oil fat filtration unit", "code": "48101539" },
      { "name": "Commercial use wok heating unit", "code": "48101540" },
      {
        "name": "Commercial use chip and french fry storage and scuttle",
        "code": "48101541"
      },
      { "name": "Commercial use jacketed tilting kettle", "code": "48101542" },
      { "name": "Commercial use jacketed boiling pan", "code": "48101543" },
      { "name": "Commercial use direct heat boiling pan", "code": "48101544" },
      { "name": "Commercial use oven stand", "code": "48101545" },
      { "name": "Commercial decoction machine", "code": "48101546" },
      { "name": "Food preparation equipment", "code": "48101600" },
      { "name": "Commercial use blenders", "code": "48101601" },
      { "name": "Commercial use electric can openers", "code": "48101602" },
      {
        "name": "Commercial use food choppers or cubers or dicers",
        "code": "48101603"
      },
      { "name": "Commercial use coffee grinders", "code": "48101604" },
      { "name": "Commercial use food grinders", "code": "48101605" },
      { "name": "Commercial use graters", "code": "48101606" },
      { "name": "Commercial use juicers", "code": "48101607" },
      { "name": "Commercial use mixers", "code": "48101608" },
      { "name": "Commercial use pasta machines", "code": "48101609" },
      { "name": "Commercial use peelers", "code": "48101610" },
      { "name": "Commercial use scales", "code": "48101611" },
      { "name": "Commercial use food processors", "code": "48101612" },
      { "name": "Commercial use dough machines", "code": "48101613" },
      { "name": "Commercial use icing sets or bags", "code": "48101614" },
      { "name": "Commercial use dishwashers", "code": "48101615" },
      { "name": "Commercial use food slicers", "code": "48101616" },
      { "name": "Commercial use plastic shovels", "code": "48101617" },
      {
        "name": "Commercial use dishwashing machine parts",
        "code": "48101618"
      },
      { "name": "Water sterilization system", "code": "48101619" },
      { "name": "Food and beverage dispensing equipment", "code": "48101700" },
      { "name": "Carbonated beverage dispenser", "code": "48101701" },
      { "name": "Non carbonated beverage dispenser", "code": "48101702" },
      { "name": "Milk dispensers", "code": "48101703" },
      { "name": "Syrup pumps", "code": "48101704" },
      { "name": "Cappuccino or espresso machines", "code": "48101705" },
      { "name": "Milkshake machines", "code": "48101706" },
      { "name": "Soft serve machines", "code": "48101707" },
      { "name": "Slush machines", "code": "48101708" },
      { "name": "Ice dispensers", "code": "48101709" },
      { "name": "Drinking fountains or bubblers", "code": "48101710" },
      { "name": "Bottled water dispensers or accessories", "code": "48101711" },
      { "name": "Cup dispenser", "code": "48101712" },
      { "name": "Cocktail shakers or accessories", "code": "48101713" },
      { "name": "Hot water dispenser", "code": "48101714" },
      { "name": "Ice shaver machines or accessories", "code": "48101715" },
      { "name": "Water purifier", "code": "48101716" },
      { "name": "Cookware and kitchen tools", "code": "48101800" },
      { "name": "Commercial use cutlery", "code": "48101801" },
      { "name": "Commercial use molds", "code": "48101802" },
      { "name": "Commercial use scoops", "code": "48101803" },
      { "name": "Commercial use measuring cups", "code": "48101804" },
      { "name": "Commercial use mixing bowls", "code": "48101805" },
      { "name": "Commercial use cake or pie pans", "code": "48101806" },
      { "name": "Commercial use pizza pans", "code": "48101807" },
      { "name": "Commercial use sauce or saute pans", "code": "48101808" },
      { "name": "Commercial use stock or sauce pots", "code": "48101809" },
      { "name": "Commercial use pot or pan covers", "code": "48101810" },
      { "name": "Commercial use rolling pins", "code": "48101811" },
      { "name": "Commercial use strainers", "code": "48101812" },
      { "name": "Commercial use whisks", "code": "48101813" },
      { "name": "Commercial use woks", "code": "48101814" },
      { "name": "Commercial use ladles", "code": "48101815" },
      { "name": "Commercial use cutlery pouch", "code": "48101816" },
      { "name": "Cake decorating equipment or moulds", "code": "48101817" },
      { "name": "Tableware disinfector", "code": "48101818" },
      { "name": "Hand sterilizer", "code": "48101819" },
      { "name": "Commercial kitchen hood", "code": "48101820" },
      { "name": "Commercial nut cracker or opener", "code": "48101821" },
      { "name": "Tabletop and serving equipment", "code": "48101900" },
      { "name": "Food service dinnerware", "code": "48101901" },
      { "name": "Food service flatware", "code": "48101902" },
      { "name": "Food service glasses", "code": "48101903" },
      { "name": "Food service stemware", "code": "48101904" },
      { "name": "Food service cups or mugs", "code": "48101905" },
      { "name": "Food service serving baskets", "code": "48101906" },
      { "name": "Food service pitchers", "code": "48101907" },
      { "name": "Food service chafers", "code": "48101908" },
      { "name": "Food service coffee or tea pots", "code": "48101909" },
      { "name": "Food service soup crocks", "code": "48101910" },
      {
        "name": "Food service ice buckets or wine coolers",
        "code": "48101911"
      },
      { "name": "Food service condiment dispensers", "code": "48101912" },
      { "name": "Food service punch bowls", "code": "48101913" },
      { "name": "Food service wine carafes", "code": "48101914" },
      { "name": "Food service trays", "code": "48101915" },
      { "name": "Food service napkin dispensers", "code": "48101916" },
      { "name": "Food service fondue pots", "code": "48101917" },
      { "name": "Food service table covering rolls", "code": "48101918" },
      {
        "name": "Food service glasses or cups or mugs or container lids",
        "code": "48101919"
      },
      { "name": "Straw dispensers", "code": "48101920" },
      { "name": "Restaurant furniture", "code": "48102000" },
      { "name": "Restaurant chairs", "code": "48102001" },
      { "name": "Booths", "code": "48102002" },
      { "name": "Salad bars", "code": "48102003" },
      { "name": "Tabletops", "code": "48102004" },
      { "name": "Bar stools", "code": "48102005" },
      { "name": "Permanent bars", "code": "48102006" },
      { "name": "Portable bars", "code": "48102007" },
      { "name": "Condiment counters", "code": "48102008" },
      { "name": "Food serving or dispensing table", "code": "48102009" },
      { "name": "Cup collection cart", "code": "48102010" },
      { "name": "Commercial use kitchen worktop", "code": "48102011" },
      { "name": "Commercial use over the sink board", "code": "48102012" },
      {
        "name": "Storage and handling equipment and supplies",
        "code": "48102100"
      },
      { "name": "Display cases", "code": "48102101" },
      { "name": "Heated display cases", "code": "48102102" },
      { "name": "Refrigerated display cases", "code": "48102103" },
      { "name": "Ice cream display cases", "code": "48102104" },
      { "name": "Glass chilling equipment", "code": "48102105" },
      { "name": "Cool containers", "code": "48102106" },
      { "name": "Catering gloves or glove dispensers", "code": "48102107" },
      { "name": "Aluminum food wrapping foil", "code": "48102108" },
      { "name": "Plastic food wrap", "code": "48102109" },
      { "name": "Food hamper", "code": "48102110" },
      { "name": "Vending machines", "code": "48110000" },
      { "name": "Liquid vending machines", "code": "48111000" },
      {
        "name": "Machines dispensing single servings with cups",
        "code": "48111001"
      },
      { "name": "Machines dispensing bulk quantities", "code": "48111002" },
      { "name": "Piece and part vending machines", "code": "48111100" },
      { "name": "Bottle or can vending machines", "code": "48111101" },
      {
        "name": "Gumball candy or childrens novelties machines",
        "code": "48111102"
      },
      {
        "name": "Snack or small package goods display machines",
        "code": "48111103"
      },
      { "name": "A la carte foods vending machines", "code": "48111104" },
      { "name": "Frozen confections machines", "code": "48111105" },
      { "name": "Personal accommodation item dispensers", "code": "48111106" },
      { "name": "Cigarette machines", "code": "48111107" },
      { "name": "Drug dispensers", "code": "48111108" },
      { "name": "Shoe shining machine", "code": "48111109" },
      { "name": "Prepared to order food vending machines", "code": "48111200" },
      { "name": "French fry vending machines", "code": "48111201" },
      { "name": "Popcorn vending machines", "code": "48111202" },
      { "name": "Service and ticket vending machines", "code": "48111300" },
      { "name": "Ticket dispensing machines", "code": "48111301" },
      { "name": "Insurance policy vending machines", "code": "48111302" },
      { "name": "Stamp machines", "code": "48111303" },
      {
        "name": "Automatic ticket checking and collecting machine",
        "code": "48111304"
      },
      { "name": "Automatic coin change machine", "code": "48111305" },
      { "name": "Restaurant customer management system", "code": "48111306" },
      { "name": "Currency vending machines", "code": "48111400" },
      { "name": "Automatic teller machines ATMs", "code": "48111401" },
      { "name": "Bill to coin changers", "code": "48111402" },
      { "name": "Foreign currency exchange machines", "code": "48111403" },
      {
        "name": "Electronic funds transfer point of sale equipment",
        "code": "48111404"
      },
      { "name": "Automatic teller machine accessories", "code": "48111405" },
      { "name": "Queue numbering system", "code": "48111406" },
      { "name": "Gambling or wagering equipment", "code": "48120000" },
      { "name": "Coin operated gambling machines", "code": "48121100" },
      { "name": "Poker or slot machines", "code": "48121101" },
      { "name": "Lottery machine", "code": "48121102" },
      { "name": "Gambling tables and games", "code": "48121200" },
      { "name": "Roulette wheels", "code": "48121201" },
      { "name": "Card tables", "code": "48121202" },
      {
        "name": "Gambling management and networked wagering systems",
        "code": "48121300"
      },
      { "name": "Table gambling management systems", "code": "48121301" },
      { "name": "Networked wagering games", "code": "48121302" },
      { "name": "Funeral equipment and materials", "code": "48130000" },
      { "name": "Burial or grave products", "code": "48131500" },
      { "name": "Funeral ash box or cremation urn", "code": "48131501" },
      { "name": "Coffin", "code": "48131502" },
      { "name": "Shroud", "code": "48131503" },
      { "name": "Tombstone", "code": "48131504" },
      { "name": "Stone offering table", "code": "48131505" }
    ]
  },
  {
    "name": "Sports and Recreational Equipment and Supplies and Accessories",
    "code": "49000000",
    "subcategories": [
      { "name": "Collectibles and awards", "code": "49100000" },
      { "name": "Collectibles", "code": "49101600" },
      { "name": "Antiques", "code": "49101601" },
      { "name": "Souvenirs", "code": "49101602" },
      { "name": "Mint coin collections", "code": "49101603" },
      { "name": "Stamp collections", "code": "49101604" },
      { "name": "Antique rugs", "code": "49101605" },
      { "name": "Diggings", "code": "49101606" },
      { "name": "Comic book collections", "code": "49101607" },
      { "name": "Antique musical instruments", "code": "49101608" },
      { "name": "Ornaments or decorations", "code": "49101609" },
      { "name": "Charms", "code": "49101611" },
      { "name": "Holograms", "code": "49101612" },
      { "name": "Glass crystals", "code": "49101613" },
      { "name": "Ritual or performance mask", "code": "49101614" },
      { "name": "Awards", "code": "49101700" },
      { "name": "Medals", "code": "49101701" },
      { "name": "Trophies", "code": "49101702" },
      { "name": "Plaques", "code": "49101704" },
      { "name": "Certificates", "code": "49101705" },
      { "name": "Photo award", "code": "49101706" },
      { "name": "Achievement certificate", "code": "49101707" },
      { "name": "Crowns", "code": "49101708" },
      { "name": "Memorial tablet", "code": "49101709" },
      {
        "name": "Camping and outdoor equipment and accessories",
        "code": "49120000"
      },
      { "name": "Camping and outdoor equipment", "code": "49121500" },
      { "name": "Sleeping pads", "code": "49121502" },
      { "name": "Tents", "code": "49121503" },
      { "name": "Sleeping bags", "code": "49121504" },
      { "name": "Ice chests", "code": "49121505" },
      { "name": "Tent repair kits", "code": "49121506" },
      { "name": "Pneumatic mattresses", "code": "49121507" },
      { "name": "Mosquito nets", "code": "49121508" },
      { "name": "Camping or outdoor stoves", "code": "49121509" },
      { "name": "Drink coolers", "code": "49121510" },
      { "name": "Camping or outdoor flatware", "code": "49121511" },
      { "name": "Tent post", "code": "49121512" },
      { "name": "Tent pin", "code": "49121513" },
      { "name": "Camping furniture", "code": "49121600" },
      { "name": "Camping chairs or stools", "code": "49121601" },
      { "name": "Camping tables", "code": "49121602" },
      { "name": "Camping cots", "code": "49121603" },
      { "name": "Fishing and hunting equipment", "code": "49130000" },
      { "name": "Fishing tackle", "code": "49131500" },
      { "name": "Fishing rods", "code": "49131501" },
      { "name": "Fishing line", "code": "49131502" },
      { "name": "Fishing reels", "code": "49131503" },
      { "name": "Fishing lures", "code": "49131504" },
      { "name": "Fishing bait", "code": "49131505" },
      { "name": "Fishing weights or sinkers", "code": "49131506" },
      { "name": "Hunting products", "code": "49131600" },
      { "name": "Animal calls", "code": "49131601" },
      { "name": "Sporting decoys", "code": "49131602" },
      { "name": "Sporting traps", "code": "49131603" },
      { "name": "Sporting shotguns", "code": "49131604" },
      { "name": "Sporting rifles", "code": "49131605" },
      { "name": "Sporting ammunition", "code": "49131606" },
      { "name": "Gun barrel", "code": "49131607" },
      { "name": "Watersports equipment", "code": "49140000" },
      { "name": "Scuba and snorkeling gear", "code": "49141500" },
      { "name": "Bouyancy compensators", "code": "49141501" },
      { "name": "Scuba tanks", "code": "49141502" },
      { "name": "Scuba regulators", "code": "49141503" },
      { "name": "Diving instruments or accessories", "code": "49141504" },
      { "name": "Masks or fins or snorkels", "code": "49141505" },
      { "name": "Wetsuits", "code": "49141506" },
      { "name": "Drysuits", "code": "49141507" },
      { "name": "Diving boot", "code": "49141508" },
      { "name": "Diver hood", "code": "49141509" },
      { "name": "Surf and swim equipment and accessories", "code": "49141600" },
      {
        "name": "Wakeboards or kneeboards or boogieboards",
        "code": "49141602"
      },
      { "name": "Water skis or accessories", "code": "49141603" },
      { "name": "Windsurfing equipment", "code": "49141604" },
      { "name": "Surfboards", "code": "49141605" },
      { "name": "Swim goggles or swim fins", "code": "49141606" },
      { "name": "Parasailing equipment", "code": "49141607" },
      { "name": "Winter sports equipment", "code": "49150000" },
      { "name": "Skiing and snowboarding equipment", "code": "49151500" },
      { "name": "Ski boots", "code": "49151501" },
      { "name": "Skis", "code": "49151502" },
      { "name": "Ski poles", "code": "49151503" },
      { "name": "Bindings", "code": "49151504" },
      { "name": "Snowboards", "code": "49151505" },
      { "name": "Ski glove", "code": "49151506" },
      { "name": "Skating and ice hockey equipment", "code": "49151600" },
      { "name": "Hockey pucks", "code": "49151601" },
      { "name": "Ice skates", "code": "49151602" },
      { "name": "Hockey sticks", "code": "49151603" },
      { "name": "Field and court sports equipment", "code": "49160000" },
      { "name": "Field sports equipment", "code": "49161500" },
      { "name": "Football blocking sleds", "code": "49161501" },
      { "name": "Baseball gloves", "code": "49161502" },
      { "name": "Baseballs", "code": "49161503" },
      { "name": "Footballs", "code": "49161504" },
      { "name": "Soccer balls", "code": "49161505" },
      { "name": "Baseball bats", "code": "49161506" },
      { "name": "Baseball bases", "code": "49161507" },
      { "name": "Pitching machines", "code": "49161508" },
      { "name": "Softballs", "code": "49161509" },
      { "name": "Football tackling dummies", "code": "49161510" },
      { "name": "Lacrosse sticks", "code": "49161511" },
      { "name": "Lacrosse balls", "code": "49161512" },
      { "name": "Field hockey sticks", "code": "49161513" },
      { "name": "Field hockey balls", "code": "49161514" },
      { "name": "Team handball balls", "code": "49161515" },
      { "name": "Team handball school sets", "code": "49161516" },
      { "name": "Baseball or softball protective gear", "code": "49161517" },
      { "name": "Baseball batting aids", "code": "49161518" },
      { "name": "Baseball backstops or fences", "code": "49161519" },
      { "name": "Softball bats", "code": "49161520" },
      { "name": "Softball gloves", "code": "49161521" },
      { "name": "Football kicking tees", "code": "49161522" },
      { "name": "Flag football gear", "code": "49161523" },
      { "name": "Soccer field marking equipment", "code": "49161524" },
      { "name": "Soccer protective equipment", "code": "49161525" },
      { "name": "Soccer training aids", "code": "49161526" },
      { "name": "Gateball stick", "code": "49161527" },
      { "name": "Racquet and court sports equipment", "code": "49161600" },
      { "name": "Racquetball rackets", "code": "49161601" },
      { "name": "Badminton rackets", "code": "49161602" },
      { "name": "Basketballs", "code": "49161603" },
      { "name": "Tennis balls", "code": "49161604" },
      { "name": "Racquet balls", "code": "49161605" },
      { "name": "Squash balls", "code": "49161606" },
      { "name": "Tennis racquets", "code": "49161607" },
      { "name": "Volleyballs", "code": "49161608" },
      { "name": "Badminton birdies or shuttlecocks", "code": "49161609" },
      { "name": "Squash racquets", "code": "49161610" },
      { "name": "Tennis training aids", "code": "49161611" },
      { "name": "Tennis court equipment", "code": "49161612" },
      { "name": "Volleyball storage for balls or nets", "code": "49161613" },
      { "name": "Volleyball gymnasium standards", "code": "49161614" },
      { "name": "Basketball complete game systems", "code": "49161615" },
      { "name": "Floor hockey protective equipment", "code": "49161616" },
      { "name": "Tether poles", "code": "49161617" },
      { "name": "Tether balls", "code": "49161618" },
      { "name": "Racquet strings", "code": "49161619" },
      { "name": "Racquet grips", "code": "49161620" },
      { "name": "Stringing machine", "code": "49161621" },
      { "name": "Track sports equipment", "code": "49161700" },
      { "name": "Javelins", "code": "49161701" },
      { "name": "Jumping bars", "code": "49161702" },
      { "name": "Discus", "code": "49161703" },
      { "name": "Shotputs", "code": "49161704" },
      { "name": "Vaulting poles", "code": "49161705" },
      { "name": "Hurdles", "code": "49161706" },
      { "name": "Batons", "code": "49161707" },
      { "name": "Jumping and pole vaulting bar", "code": "49161708" },
      { "name": "Discus throwing circle", "code": "49161709" },
      { "name": "Throwing hammer", "code": "49161710" },
      { "name": "Shot put circle", "code": "49161711" },
      { "name": "Gymnastics and boxing equipment", "code": "49170000" },
      { "name": "Gymnastics equipment", "code": "49171500" },
      { "name": "Gymnastic bars or beams", "code": "49171501" },
      {
        "name": "Gymnastic ropes or rings or climbing accessories",
        "code": "49171502"
      },
      { "name": "Gymnastic vaulting equipment", "code": "49171503" },
      { "name": "Gymnastic trampolines", "code": "49171504" },
      { "name": "Balance equipment", "code": "49171505" },
      { "name": "Gymnastic pommel horse", "code": "49171506" },
      { "name": "Gymnastic indian club", "code": "49171507" },
      {
        "name": "Gymnastic vault springboard or beatboard",
        "code": "49171508"
      },
      { "name": "Gymnastic vaulting box", "code": "49171509" },
      { "name": "Boxing equipment", "code": "49171600" },
      { "name": "Boxing rings", "code": "49171601" },
      { "name": "Punching bags", "code": "49171602" },
      { "name": "Boxing gloves", "code": "49171603" },
      { "name": "Boxing bell", "code": "49171604" },
      { "name": "Target and table games and equipment", "code": "49180000" },
      { "name": "Table games and equipment", "code": "49181500" },
      { "name": "Billiard tables", "code": "49181501" },
      { "name": "Pool cues", "code": "49181502" },
      { "name": "Shuffleboard", "code": "49181503" },
      { "name": "Pinball games", "code": "49181504" },
      { "name": "Billiard balls", "code": "49181505" },
      { "name": "Air hockey tables or accessories", "code": "49181506" },
      { "name": "Tennis tables", "code": "49181507" },
      { "name": "Table tennis paddles", "code": "49181508" },
      { "name": "Table tennis balls", "code": "49181509" },
      { "name": "Foosball tables", "code": "49181510" },
      { "name": "Foosballs", "code": "49181511" },
      { "name": "Foosball replacement players", "code": "49181512" },
      { "name": "Billiard cue tips", "code": "49181513" },
      { "name": "Billiard chalk", "code": "49181514" },
      { "name": "Billiard racks", "code": "49181515" },
      { "name": "Target games and equipment", "code": "49181600" },
      { "name": "Archery targets", "code": "49181601" },
      { "name": "Archery bows", "code": "49181602" },
      { "name": "Archery arrows", "code": "49181603" },
      { "name": "Darts", "code": "49181604" },
      { "name": "Dart boards", "code": "49181605" },
      { "name": "Trapshooting equipment", "code": "49181606" },
      { "name": "Throwing targets", "code": "49181607" },
      { "name": "Archery bow strings", "code": "49181608" },
      { "name": "Archery gloves", "code": "49181609" },
      { "name": "Archery arm guards", "code": "49181610" },
      { "name": "Archery target stands", "code": "49181611" },
      { "name": "Archery backstops", "code": "49181612" },
      { "name": "Gukgung", "code": "49181613" },
      { "name": "Archery crossbow", "code": "49181614" },
      { "name": "Fitness equipment", "code": "49200000" },
      { "name": "Aerobic training equipment", "code": "49201500" },
      { "name": "Treadmills", "code": "49201501" },
      { "name": "Stair climbers", "code": "49201502" },
      { "name": "Stationary bicycles", "code": "49201503" },
      { "name": "Rowing machines", "code": "49201504" },
      { "name": "Jump ropes", "code": "49201512" },
      { "name": "Exercise trampolines", "code": "49201513" },
      { "name": "Exercise balls", "code": "49201514" },
      { "name": "Step aerobic equipment", "code": "49201515" },
      { "name": "Cross trainers", "code": "49201516" },
      { "name": "Inversion machine", "code": "49201517" },
      { "name": "Wall bars", "code": "49201518" },
      { "name": "Body twister", "code": "49201519" },
      { "name": "Traveling ping set", "code": "49201520" },
      {
        "name": "Weight and resistance training equipment",
        "code": "49201600"
      },
      { "name": "Dumbbells", "code": "49201601" },
      { "name": "Barbells", "code": "49201602" },
      { "name": "Lower body resistance machines", "code": "49201603" },
      { "name": "Weight benches or racks", "code": "49201604" },
      { "name": "Upper body resistance machines", "code": "49201605" },
      { "name": "Fitness weights", "code": "49201606" },
      { "name": "Pilates machines", "code": "49201607" },
      { "name": "Grip strengthener", "code": "49201608" },
      { "name": "Resistance bands", "code": "49201609" },
      { "name": "Resistance tubes", "code": "49201610" },
      { "name": "Multi gyms", "code": "49201611" },
      { "name": "Training weight", "code": "49201612" },
      { "name": "Other sports", "code": "49210000" },
      { "name": "Golf equipment", "code": "49211600" },
      { "name": "Golf bags", "code": "49211601" },
      { "name": "Golf balls", "code": "49211602" },
      { "name": "Golf clubs", "code": "49211603" },
      { "name": "Golf tees", "code": "49211604" },
      { "name": "Golf club head covers", "code": "49211605" },
      { "name": "Golf gloves", "code": "49211606" },
      { "name": "Divot fixers", "code": "49211607" },
      { "name": "Golfscopes", "code": "49211608" },
      { "name": "Golf putting partner", "code": "49211609" },
      { "name": "Golf ball dispenser", "code": "49211610" },
      {
        "name": "Bowling equipment and supplies and accessories",
        "code": "49211700"
      },
      { "name": "Bowling equipment", "code": "49211701" },
      { "name": "Bowling supplies", "code": "49211702" },
      { "name": "Bowling accessories", "code": "49211703" },
      { "name": "Physical education classroom equipment", "code": "49211800" },
      { "name": "Parachute equipment", "code": "49211801" },
      { "name": "Hula hoops or hoop equipment", "code": "49211802" },
      { "name": "Orienteering equipment", "code": "49211803" },
      {
        "name": "Team identification materials or markers",
        "code": "49211804"
      },
      { "name": "Lanyards", "code": "49211805" },
      { "name": "Physical education equipment storage", "code": "49211806" },
      { "name": "Physical education assessment tools", "code": "49211807" },
      { "name": "Obesity measurement tool", "code": "49211808" },
      { "name": "Body measurement tool", "code": "49211809" },
      { "name": "Radar speed gun", "code": "49211810" },
      { "name": "Ergometer", "code": "49211811" },
      { "name": "Trunk flexion measurement device", "code": "49211812" },
      { "name": "Jump meter", "code": "49211813" },
      { "name": "Fatigue measurement instrument", "code": "49211814" },
      { "name": "Whole body reaction measurement system", "code": "49211815" },
      { "name": "Running analyzer", "code": "49211816" },
      {
        "name": "Muscular strength measurement instrument",
        "code": "49211817"
      },
      { "name": "Medicine ball", "code": "49211818" },
      { "name": "Motion analysis system", "code": "49211819" },
      { "name": "Grip dynamometer", "code": "49211820" },
      { "name": "Back leg chest dynamometer", "code": "49211821" },
      { "name": "Hand muscle evaluation kit", "code": "49211822" },
      { "name": "Measuring instrument for human body", "code": "49211823" },
      { "name": "Posture evaluation kit", "code": "49211824" },
      { "name": "Intelligence testing device", "code": "49211825" },
      { "name": "Body feature measuring equipment", "code": "49211826" },
      { "name": "Finger tapping tester", "code": "49211827" },
      { "name": "Hand stability tester", "code": "49211828" },
      { "name": "Blood circulation analyzer", "code": "49211829" },
      { "name": "Side step tester", "code": "49211830" },
      { "name": "Sit up tester", "code": "49211831" },
      { "name": "Balance tester", "code": "49211832" },
      { "name": "Heart lung endurance tester", "code": "49211833" },
      { "name": "Skydive simulator", "code": "49211834" },
      { "name": "Sports equipment and accessories", "code": "49220000" },
      { "name": "Sport accessories", "code": "49221500" },
      { "name": "Sport scoreboards", "code": "49221501" },
      { "name": "Sport goals", "code": "49221502" },
      {
        "name": "Sport safety equipment other than headgear",
        "code": "49221503"
      },
      { "name": "Sport safety headgear", "code": "49221504" },
      { "name": "Sport nets or netting", "code": "49221505" },
      { "name": "Sport mats or padding", "code": "49221506" },
      { "name": "Basketball backboards", "code": "49221507" },
      { "name": "Basketball hoop", "code": "49221508" },
      { "name": "Roller skates or roller blades", "code": "49221509" },
      { "name": "Sport caps", "code": "49221510" },
      { "name": "Sports equipment bags", "code": "49221511" },
      { "name": "Skateboard", "code": "49221512" },
      { "name": "Player bench", "code": "49221513" },
      { "name": "Judging chair or stand", "code": "49221514" },
      { "name": "Ball pump", "code": "49221515" },
      { "name": "Starting gun", "code": "49221516" },
      { "name": "Starting block", "code": "49221517" },
      { "name": "Net post", "code": "49221518" },
      { "name": "Fencing sword or foil", "code": "49221519" },
      { "name": "Athletic tool stand", "code": "49221520" },
      { "name": "Sporting goods cart", "code": "49221521" },
      { "name": "Athletic training dummy", "code": "49221522" },
      { "name": "Award podium", "code": "49221523" },
      { "name": "Fencing jacket", "code": "49221524" },
      { "name": "Rubber inner tube for athletics", "code": "49221525" },
      { "name": "Weight lifting belt", "code": "49221526" },
      { "name": "Bamboo sword", "code": "49221527" },
      { "name": "Basketball backstop", "code": "49221528" },
      { "name": "Fencing judging machine", "code": "49221529" },
      { "name": "Sports timer", "code": "49221530" },
      { "name": "Swinging pole", "code": "49221531" },
      { "name": "Playground system", "code": "49221532" },
      { "name": "Swimming kickboard", "code": "49221533" },
      {
        "name": "Recreation and playground and swimming and spa equipment and supplies",
        "code": "49240000"
      },
      { "name": "Playground equipment", "code": "49241500" },
      { "name": "Playground swings", "code": "49241501" },
      { "name": "Playground climbing apparatus", "code": "49241502" },
      { "name": "Playground merry go rounds", "code": "49241503" },
      { "name": "Playground slides", "code": "49241504" },
      { "name": "Playground see saws", "code": "49241505" },
      { "name": "Playground tunnels", "code": "49241506" },
      { "name": "Playground sandboxes", "code": "49241507" },
      { "name": "Playground bleachers", "code": "49241508" },
      { "name": "Wall climbing equipment", "code": "49241509" },
      { "name": "Rope climbing equipment", "code": "49241510" },
      { "name": "Pergoal", "code": "49241511" },
      { "name": "Playground rocking equipment", "code": "49241512" },
      { "name": "Recreational equipment", "code": "49241600" },
      { "name": "Croquet sets", "code": "49241601" },
      { "name": "Lawn bowling equipment", "code": "49241602" },
      { "name": "Horseshoe equipment", "code": "49241603" },
      { "name": "Lawn darts", "code": "49241604" },
      {
        "name": "Swimming pool and spa equipment and supplies",
        "code": "49241700"
      },
      { "name": "Diving boards", "code": "49241701" },
      { "name": "Pool slides", "code": "49241702" },
      { "name": "Spa blower", "code": "49241703" },
      { "name": "Water test kit or solutions", "code": "49241704" },
      { "name": "Automatic pool cleaner", "code": "49241705" },
      { "name": "Solar blanket", "code": "49241706" },
      { "name": "Pool or spa heater", "code": "49241707" },
      { "name": "Ozone generator", "code": "49241708" },
      { "name": "Solar blanket reels", "code": "49241709" },
      { "name": "Spa pillow", "code": "49241710" },
      { "name": "Spa cover", "code": "49241711" },
      { "name": "Pool or spa or whirlpool chemical", "code": "49241712" },
      { "name": "Sauna equipment", "code": "49241800" },
      { "name": "Sauna heater", "code": "49241801" }
    ]
  },
  {
    "name": "Food Beverage and Tobacco Products",
    "code": "50000000",
    "subcategories": [
      { "name": "Nuts and seeds", "code": "50100000" },
      { "name": "Nuts and seeds", "code": "50101700" },
      { "name": "Whole nuts or seeds", "code": "50101716" },
      { "name": "Shelled nuts or seeds", "code": "50101717" },
      {
        "name": "Nuts excluding wild edible nuts and groundnuts",
        "code": "50102500"
      },
      { "name": "Palm nuts and kernels", "code": "50102501" },
      { "name": "Brazil nuts, in shell", "code": "50102502" },
      { "name": "Walnuts, in shell", "code": "50102503" },
      { "name": "Pistachio nuts, in shell", "code": "50102504" },
      { "name": "Hazelnuts, in shell", "code": "50102505" },
      { "name": "Chestnuts, in shell", "code": "50102506" },
      { "name": "Cashew nuts, in shell", "code": "50102507" },
      { "name": "Almonds, in shell", "code": "50102508" },
      { "name": "Meat and poultry products", "code": "50110000" },
      {
        "name": "Minimally processed meat and poultry products",
        "code": "50111500"
      },
      {
        "name": "Beef, minimally processed without additions",
        "code": "50111513"
      },
      {
        "name": "Pork, minimally processed without additions",
        "code": "50111514"
      },
      {
        "name": "Chicken, minimally processed without additions",
        "code": "50111515"
      },
      {
        "name": "Beef, minimally processed with additions",
        "code": "50111516"
      },
      {
        "name": "Veal, minimally processed without additions",
        "code": "50111517"
      },
      {
        "name": "Veal, minimally processed with additions",
        "code": "50111518"
      },
      {
        "name": "Pork, minimally processed with additions",
        "code": "50111519"
      },
      {
        "name": "Chicken, minimally processed with additions",
        "code": "50111520"
      },
      {
        "name": "Turkey, minimally processed without additions",
        "code": "50111521"
      },
      {
        "name": "Turkey, minimally processed with additions",
        "code": "50111522"
      },
      {
        "name": "Lamb or mutton, minimally processed without additions",
        "code": "50111523"
      },
      {
        "name": "Lamb or mutton, minimally processed with additions",
        "code": "50111524"
      },
      {
        "name": "Goat, minimally processed without additions",
        "code": "50111525"
      },
      {
        "name": "Goat, minimally processed with additions",
        "code": "50111526"
      },
      {
        "name": "Specialty meat, minimally processed without additions",
        "code": "50111527"
      },
      {
        "name": "Specialty meat, minimally processed with additions",
        "code": "50111528"
      },
      {
        "name": "Specialty poultry, minimally processed without additions",
        "code": "50111529"
      },
      {
        "name": "Specialty poultry, minimally processed with additions",
        "code": "50111530"
      },
      {
        "name": "Mixed species meat, minimally processed without additions",
        "code": "50111531"
      },
      {
        "name": "Mixed species meat, minimally processed with additions",
        "code": "50111532"
      },
      {
        "name": "Duck, minimally processed with additions",
        "code": "50111533"
      },
      {
        "name": "Duck, minimally processed without additions",
        "code": "50111534"
      },
      {
        "name": "Horse, minimally processed without additions",
        "code": "50111535"
      },
      {
        "name": "Horse, minimally processed with additions",
        "code": "50111536"
      },
      {
        "name": "Rabbit, minimally processed without additions",
        "code": "50111537"
      },
      {
        "name": "Rabbit, minimally processed with additions",
        "code": "50111538"
      },
      {
        "name": "Frog, minimally processed without additions",
        "code": "50111539"
      },
      {
        "name": "Frog, minimally processed with additions",
        "code": "50111540"
      },
      {
        "name": "Land snail, minimally processed without additions",
        "code": "50111541"
      },
      {
        "name": "Land snail, minimally processed with additions",
        "code": "50111542"
      },
      {
        "name": "Snake, minimally processed without additions",
        "code": "50111543"
      },
      {
        "name": "Snake, minimally processed with additions",
        "code": "50111544"
      },
      {
        "name": "Turtle, minimally processed without additions",
        "code": "50111545"
      },
      {
        "name": "Turtle, minimally processed with additions",
        "code": "50111546"
      },
      {
        "name": "Bison or buffalo, minimally processed without additions",
        "code": "50111547"
      },
      {
        "name": "Bison or buffalo, minimally processed with additions",
        "code": "50111548"
      },
      {
        "name": "Llama or alpaca, minimally processed without additions",
        "code": "50111549"
      },
      {
        "name": "Llama or alpaca, minimally processed with additions",
        "code": "50111550"
      },
      {
        "name": "Goose, minimally processed without additions",
        "code": "50111551"
      },
      {
        "name": "Goose, minimally processed with additions",
        "code": "50111552"
      },
      {
        "name": "Ostrich, minimally processed without additions",
        "code": "50111553"
      },
      {
        "name": "Ostrich, minimally processed with additions",
        "code": "50111554"
      },
      {
        "name": "Boar, minimally processed without additions",
        "code": "50111555"
      },
      {
        "name": "Boar, minimally processed with additions",
        "code": "50111556"
      },
      {
        "name": "Deer, minimally processed without additions",
        "code": "50111557"
      },
      {
        "name": "Deer, minimally processed with additions",
        "code": "50111558"
      },
      {
        "name": "Pheasant, minimally processed without additions",
        "code": "50111559"
      },
      {
        "name": "Pheasant, minimally processed with additions",
        "code": "50111560"
      },
      {
        "name": "Quail, minimally processed without additions",
        "code": "50111561"
      },
      {
        "name": "Quail, minimally processed with additions",
        "code": "50111562"
      },
      { "name": "Processed meat and poultry products", "code": "50112000" },
      { "name": "Beef, processed without additions", "code": "50112004" },
      { "name": "Beef, processed with additions", "code": "50112005" },
      { "name": "Veal, processed without additions", "code": "50112006" },
      { "name": "Veal, processed with additions", "code": "50112007" },
      { "name": "Pork, processed without additions", "code": "50112008" },
      { "name": "Pork, processed with additions", "code": "50112009" },
      { "name": "Chicken, processed without additions", "code": "50112010" },
      { "name": "Chicken, processed with additions", "code": "50112011" },
      { "name": "Turkey, processed without additions", "code": "50112012" },
      { "name": "Turkey, processed with additions", "code": "50112013" },
      {
        "name": "Lamb or mutton, processed without additions",
        "code": "50112014"
      },
      {
        "name": "Lamb or mutton, processed with additions",
        "code": "50112015"
      },
      { "name": "Goat, processed without additions", "code": "50112016" },
      { "name": "Goat, processed with additions", "code": "50112017" },
      {
        "name": "Specialty meat, processed without additions",
        "code": "50112018"
      },
      {
        "name": "Specialty meat, processed with additions",
        "code": "50112019"
      },
      {
        "name": "Specialty poultry, processed without additions",
        "code": "50112020"
      },
      {
        "name": "Specialty poultry, processed with additions",
        "code": "50112021"
      },
      {
        "name": "Mixed species meat, processed without additions",
        "code": "50112022"
      },
      {
        "name": "Mixed species meat, processed with additions",
        "code": "50112023"
      },
      { "name": "Duck, processed with additions", "code": "50112024" },
      { "name": "Duck, processed without additions", "code": "50112025" },
      { "name": "Horse, processed without additions", "code": "50112026" },
      { "name": "Horse, processed with additions", "code": "50112027" },
      { "name": "Rabbit, processed without additions", "code": "50112028" },
      { "name": "Rabbit, processed with additions", "code": "50112029" },
      { "name": "Frog, processed without additions", "code": "50112030" },
      { "name": "Frog, processed with additions", "code": "50112031" },
      { "name": "Land snail, processed without additions", "code": "50112032" },
      { "name": "Land snail, processed with additions", "code": "50112033" },
      { "name": "Snake, processed without additions", "code": "50112034" },
      { "name": "Snake, processed with additions", "code": "50112035" },
      { "name": "Turtle, processed without additions", "code": "50112036" },
      { "name": "Turtle, processed with additions", "code": "50112037" },
      {
        "name": "Bison or buffalo, processed without additions",
        "code": "50112038"
      },
      {
        "name": "Bison or buffalo, processed with additions",
        "code": "50112039"
      },
      {
        "name": "Llama or alpaca, processed without additions",
        "code": "50112040"
      },
      {
        "name": "Llama or alpaca, processed with additions",
        "code": "50112041"
      },
      { "name": "Goose, processed without additions", "code": "50112042" },
      { "name": "Goose, processed with additions", "code": "50112043" },
      { "name": "Ostrich, processed without additions", "code": "50112044" },
      { "name": "Ostrich, processed with additions", "code": "50112045" },
      { "name": "Boar, processed without additions", "code": "50112046" },
      { "name": "Boar, processed with additions", "code": "50112047" },
      { "name": "Deer, processed without additions", "code": "50112048" },
      { "name": "Deer, processed with additions", "code": "50112049" },
      { "name": "Pheasant, processed without additions", "code": "50112050" },
      { "name": "Pheasant, processed with additions", "code": "50112051" },
      { "name": "Quail, processed without additions", "code": "50112052" },
      { "name": "Quail, processed with additions", "code": "50112053" },
      { "name": "Seafood", "code": "50120000" },
      { "name": "Fish", "code": "50121500" },
      { "name": "Frozen fish", "code": "50121537" },
      { "name": "Shelf stable fish", "code": "50121538" },
      { "name": "Fresh fish", "code": "50121539" },
      { "name": "Caviar and caviar substitute", "code": "50121540" },
      { "name": "Shellfish", "code": "50121600" },
      { "name": "Fresh shellfish", "code": "50121611" },
      { "name": "Frozen shellfish", "code": "50121612" },
      { "name": "Shelf stable shellfish", "code": "50121613" },
      { "name": "Aquatic invertebrates", "code": "50121700" },
      { "name": "Fresh aquatic invertebrates", "code": "50121705" },
      { "name": "Frozen aquatic invertebrates", "code": "50121706" },
      { "name": "Shelf stable aquatic invertebrates", "code": "50121707" },
      { "name": "Aquatic plants", "code": "50121800" },
      { "name": "Fresh aquatic plants", "code": "50121802" },
      { "name": "Frozen aquatic plants", "code": "50121803" },
      { "name": "Shelf stable aquatic plants", "code": "50121804" },
      { "name": "Salt preserved seafoods", "code": "50121900" },
      { "name": "Pickled squid", "code": "50121901" },
      { "name": "Salted pollack roe", "code": "50121902" },
      { "name": "Salted shrimp", "code": "50121903" },
      { "name": "Dairy products and eggs", "code": "50130000" },
      { "name": "Eggs and egg substitutes", "code": "50131600" },
      { "name": "Prepared eggs", "code": "50131609" },
      { "name": "In shell table egg from chickens", "code": "50131612" },
      { "name": "In shell table egg from ducks", "code": "50131613" },
      { "name": "In shell table egg from emus", "code": "50131614" },
      { "name": "In shell table egg from geese", "code": "50131615" },
      { "name": "In shell table egg from guineafowls", "code": "50131616" },
      { "name": "In shell table egg from ostriches", "code": "50131617" },
      { "name": "In shell table egg from quails", "code": "50131618" },
      { "name": "In shell table egg from rheas", "code": "50131619" },
      {
        "name": "In shell table egg from squabs or pigeons",
        "code": "50131620"
      },
      { "name": "In shell table egg from turkeys", "code": "50131621" },
      { "name": "In shell table egg from specialty birds", "code": "50131622" },
      { "name": "In shell nest run egg from chickens", "code": "50131623" },
      {
        "name": "In shell nest run egg from birds other than chickens",
        "code": "50131624"
      },
      {
        "name": "In shell checked and dirty egg industrial from chickens",
        "code": "50131625"
      },
      {
        "name": "In shell checked and dirty egg industrial from birds other than chickens",
        "code": "50131626"
      },
      { "name": "Cooked egg from chickens", "code": "50131627" },
      {
        "name": "Cooked egg from birds other than chickens",
        "code": "50131628"
      },
      {
        "name": "Egg products and substitutes from chickens",
        "code": "50131629"
      },
      {
        "name": "Decharacterized egg products from chickens",
        "code": "50131630"
      },
      {
        "name": "Egg products and substitutes from birds other than chickens",
        "code": "50131631"
      },
      {
        "name": "Decharacterized egg products from birds other than chickens",
        "code": "50131632"
      },
      { "name": "Egg extract from chickens", "code": "50131633" },
      {
        "name": "Egg extract from birds other than chickens",
        "code": "50131634"
      },
      { "name": "Egg imitation", "code": "50131635" },
      { "name": "Milk and butter products", "code": "50131700" },
      { "name": "Fresh milk or butter products", "code": "50131701" },
      { "name": "Shelf stable milk or butter products", "code": "50131702" },
      { "name": "Frozen milk and butter products", "code": "50131703" },
      { "name": "Powdered milk", "code": "50131704" },
      { "name": "Whey", "code": "50131705" },
      { "name": "Lactose", "code": "50131706" },
      { "name": "Cream, fresh", "code": "50131707" },
      { "name": "Cheese", "code": "50131800" },
      { "name": "Natural cheese", "code": "50131801" },
      { "name": "Processed cheese", "code": "50131802" },
      { "name": "Imitation cheese", "code": "50131803" },
      { "name": "Edible oils and fats", "code": "50150000" },
      {
        "name": "Edible vegetable and plant oils and fats",
        "code": "50151500"
      },
      { "name": "Edible vegetable or plant oils", "code": "50151513" },
      { "name": "Edible vegetable or plant fats", "code": "50151514" },
      { "name": "Soy milk", "code": "50151515" },
      { "name": "Margarine and similar preparations", "code": "50151516" },
      { "name": "Edible animal oils and fats", "code": "50151600" },
      { "name": "Edible animal oils", "code": "50151604" },
      { "name": "Edible animal fats", "code": "50151605" },
      { "name": "Vegetable waxes", "code": "50151700" },
      { "name": "Degras", "code": "50151701" },
      {
        "name": "Chocolate and sugars and sweeteners and confectionary products",
        "code": "50160000"
      },
      {
        "name": "Chocolate and sugars and sweetening products",
        "code": "50161500"
      },
      { "name": "Natural sugars or sweetening products", "code": "50161509" },
      { "name": "Artificial sweetening agents", "code": "50161510" },
      { "name": "Chocolate or chocolate substitute", "code": "50161511" },
      { "name": "Syrups", "code": "50161512" },
      { "name": "Confectionary products", "code": "50161800" },
      { "name": "Chocolate or chocolate substitute candy", "code": "50161813" },
      { "name": "Sugar or sugar substitute candy", "code": "50161814" },
      { "name": "Chewing gum", "code": "50161815" },
      { "name": "Sugar products", "code": "50161900" },
      { "name": "Glycerol", "code": "50161901" },
      { "name": "Cane sugar", "code": "50161902" },
      { "name": "Seasonings and preservatives", "code": "50170000" },
      { "name": "Herbs and spices and extracts", "code": "50171500" },
      { "name": "Fresh herbs", "code": "50171548" },
      { "name": "Spices or extracts", "code": "50171550" },
      { "name": "Cooking or table salt", "code": "50171551" },
      { "name": "Seasoning mix", "code": "50171552" },
      { "name": "Red pepper powder", "code": "50171553" },
      { "name": "Herbs stems and seeds for brews", "code": "50171554" },
      { "name": "Clove, raw", "code": "50171555" },
      { "name": "Hop cone, raw", "code": "50171556" },
      { "name": "Vanilla, raw", "code": "50171557" },
      { "name": "Ginger, raw", "code": "50171558" },
      { "name": "Cinnamon or canella, raw", "code": "50171559" },
      { "name": "Nutmeg, raw", "code": "50171560" },
      { "name": "Vinegars and cooking wines", "code": "50171700" },
      { "name": "Vinegars", "code": "50171707" },
      { "name": "Cooking wines", "code": "50171708" },
      { "name": "Sauces and spreads and condiments", "code": "50171800" },
      {
        "name": "Dipping sauces or condiments or spreads or marinades",
        "code": "50171830"
      },
      { "name": "Cooking sauce", "code": "50171831" },
      { "name": "Salad dressing or dips", "code": "50171832" },
      { "name": "Savory spread or pate", "code": "50171833" },
      { "name": "Chinese bean paste", "code": "50171834" },
      { "name": "Pickles and relish and olives", "code": "50171900" },
      { "name": "Pickles", "code": "50171901" },
      { "name": "Relish", "code": "50171902" },
      { "name": "Chutneys", "code": "50171904" },
      { "name": "Fermented soybean products", "code": "50172000" },
      { "name": "Soy sauce", "code": "50172001" },
      { "name": "Soy based hot pepper paste", "code": "50172002" },
      { "name": "Soybean paste", "code": "50172003" },
      { "name": "Soy based mixed paste", "code": "50172004" },
      { "name": "Mejoo or fermented soybeans", "code": "50172005" },
      { "name": "Tofu or bean curd", "code": "50172006" },
      { "name": "Bread and bakery products", "code": "50180000" },
      { "name": "Baking mixes and supplies", "code": "50181700" },
      { "name": "Baking mixes", "code": "50181708" },
      { "name": "Baking supplies", "code": "50181709" },
      { "name": "Bakers yeast", "code": "50181710" },
      { "name": "Baking powder", "code": "50181711" },
      { "name": "Tapioca", "code": "50181712" },
      { "name": "Bread and biscuits and cookies", "code": "50181900" },
      { "name": "Fresh bread", "code": "50181901" },
      { "name": "Frozen bread", "code": "50181902" },
      { "name": "Plain savory biscuits", "code": "50181903" },
      {
        "name": "Dried breads or bread shells or croutons",
        "code": "50181904"
      },
      { "name": "Sweet biscuits or cookies", "code": "50181905" },
      { "name": "Shelf stable bread", "code": "50181906" },
      { "name": "Frozen cookie dough", "code": "50181907" },
      { "name": "Frozen bread dough", "code": "50181908" },
      { "name": "Crackers", "code": "50181909" },
      { "name": "Cakes and pies and pastries", "code": "50182000" },
      { "name": "Fresh cakes or pies or pastries", "code": "50182001" },
      { "name": "Frozen cakes or pies or pastries", "code": "50182002" },
      { "name": "Frozen pastry dough", "code": "50182003" },
      { "name": "Frozen savory biscuit dough", "code": "50182004" },
      { "name": "Rice cake", "code": "50182005" },
      { "name": "Prepared and preserved foods", "code": "50190000" },
      { "name": "Prepared soups and stews", "code": "50191500" },
      { "name": "Fresh prepared soups or stews", "code": "50191505" },
      { "name": "Frozen prepared soups or stews", "code": "50191506" },
      { "name": "Shelf stable prepared soups or stews", "code": "50191507" },
      { "name": "Snack foods", "code": "50192100" },
      { "name": "Crisps or chips or pretzels or mixes", "code": "50192109" },
      { "name": "Nuts or dried fruits", "code": "50192110" },
      { "name": "Dried or processed meats", "code": "50192111" },
      { "name": "Popped corn", "code": "50192112" },
      { "name": "Dried shellfish and fish fillet", "code": "50192113" },
      { "name": "Desserts and dessert toppings", "code": "50192300" },
      { "name": "Prepared desserts", "code": "50192301" },
      { "name": "Dessert toppings", "code": "50192302" },
      {
        "name": "Flavored ices or ice cream or ice cream desserts or frozen yogurts",
        "code": "50192303"
      },
      { "name": "Edible ice cream cups or cones", "code": "50192304" },
      {
        "name": "Jams and jellies and nut and sweet spreads and fruit conserves",
        "code": "50192400"
      },
      { "name": "Jams or jellies or fruit preserves", "code": "50192401" },
      { "name": "Nut or mixed spreads", "code": "50192402" },
      { "name": "Honey", "code": "50192403" },
      { "name": "Gelatin or jelly crystals", "code": "50192404" },
      { "name": "Processed royal jelly food", "code": "50192405" },
      { "name": "Mooks or acorn starch jelly", "code": "50192406" },
      { "name": "Sandwiches and filled rolls", "code": "50192500" },
      { "name": "Fresh sandwiches or filled rolls", "code": "50192501" },
      { "name": "Frozen sandwiches or filled rolls", "code": "50192502" },
      { "name": "Fresh sandwich fillers", "code": "50192503" },
      { "name": "Frozen sandwich fillers", "code": "50192504" },
      { "name": "Prepared side dishes", "code": "50192600" },
      {
        "name": "Fresh prepared potatoes or rice or pasta or stuffing",
        "code": "50192601"
      },
      {
        "name": "Frozen prepared potatoes or rice or pasta or stuffing",
        "code": "50192602"
      },
      {
        "name": "Shelf stable prepared potatoes or rice or pasta or stuffing",
        "code": "50192603"
      },
      { "name": "Packaged combination meals", "code": "50192700" },
      { "name": "Fresh combination meals", "code": "50192701" },
      { "name": "Frozen combination meals", "code": "50192702" },
      { "name": "Shelf stable combination meals", "code": "50192703" },
      { "name": "Savory pies and quiches and pasties", "code": "50192800" },
      { "name": "Fresh savory pies or quiches or pasties", "code": "50192801" },
      {
        "name": "Frozen savory pies or quiches or pasties",
        "code": "50192802"
      },
      {
        "name": "Shelf stable savory pies or quiches or pasties",
        "code": "50192803"
      },
      { "name": "Plain pasta and noodles", "code": "50192900" },
      { "name": "Fresh plain pasta or noodles", "code": "50192901" },
      { "name": "Shelf stable plain pasta or noodles", "code": "50192902" },
      { "name": "Infant foods and beverages", "code": "50193000" },
      { "name": "Infant foods", "code": "50193001" },
      { "name": "Infant beverages", "code": "50193002" },
      { "name": "Instant mixes and supplies", "code": "50193100" },
      { "name": "Instant snack mixes", "code": "50193101" },
      { "name": "Dessert mix", "code": "50193102" },
      { "name": "Gravy mix", "code": "50193103" },
      { "name": "Soup bases", "code": "50193104" },
      { "name": "Batter or breading mixes", "code": "50193105" },
      { "name": "Instant purees", "code": "50193106" },
      { "name": "Instant mashed potatoes", "code": "50193107" },
      { "name": "Instant paps", "code": "50193108" },
      { "name": "Prepared salads", "code": "50193200" },
      { "name": "Fresh prepared salads", "code": "50193201" },
      { "name": "Frozen prepared salads", "code": "50193202" },
      { "name": "Shelf stable prepared salads", "code": "50193203" },
      { "name": "Flour tortillas", "code": "50193300" },
      { "name": "Corn or maize tortilla", "code": "50193301" },
      { "name": "Wheat tortilla", "code": "50193302" },
      { "name": "Beverages", "code": "50200000" },
      { "name": "Coffee and tea", "code": "50201700" },
      { "name": "Coffee", "code": "50201706" },
      { "name": "Coffee substitutes", "code": "50201707" },
      { "name": "Coffee drinks", "code": "50201708" },
      { "name": "Instant coffee", "code": "50201709" },
      { "name": "Leaf tea", "code": "50201710" },
      { "name": "Instant tea", "code": "50201711" },
      { "name": "Tea drinks", "code": "50201712" },
      { "name": "Tea bags", "code": "50201713" },
      { "name": "Non dairy creamers", "code": "50201714" },
      { "name": "Fruit tea", "code": "50201715" },
      { "name": "Mate leaf", "code": "50201716" },
      { "name": "Coffee, green", "code": "50201717" },
      { "name": "Alcoholic beverages", "code": "50202200" },
      { "name": "Beer", "code": "50202201" },
      { "name": "Cider or perry", "code": "50202202" },
      { "name": "Wine", "code": "50202203" },
      { "name": "Fortified wine", "code": "50202204" },
      { "name": "Sparkling wine", "code": "50202205" },
      { "name": "Spirits or liquors", "code": "50202206" },
      { "name": "Alcohol cocktails or drink mixes", "code": "50202207" },
      { "name": "Takju", "code": "50202208" },
      { "name": "Soju", "code": "50202209" },
      { "name": "Clear strained rice wine", "code": "50202210" },
      { "name": "Non alcoholic beverages", "code": "50202300" },
      { "name": "Water", "code": "50202301" },
      { "name": "Ice", "code": "50202302" },
      { "name": "Frozen juices", "code": "50202303" },
      { "name": "Shelf stable juice", "code": "50202304" },
      { "name": "Fresh juice", "code": "50202305" },
      { "name": "Soft drinks", "code": "50202306" },
      {
        "name": "Chocolate or malt or other hot beverages",
        "code": "50202307"
      },
      { "name": "Alcohol free cocktails or drink mixes", "code": "50202308" },
      { "name": "Sport or energy drink", "code": "50202309" },
      { "name": "Spring or mineral water", "code": "50202310" },
      { "name": "Powdered drink mix", "code": "50202311" },
      { "name": "Fresh citrus juice or concentrate", "code": "50202400" },
      { "name": "Clementine juice", "code": "50202401" },
      { "name": "Kumquat juice", "code": "50202403" },
      { "name": "Lemon juice", "code": "50202404" },
      { "name": "Key lime juice", "code": "50202405" },
      { "name": "Lime juice", "code": "50202406" },
      { "name": "Mandarin juice", "code": "50202407" },
      { "name": "Minneola tangelo juice", "code": "50202408" },
      { "name": "Orange juice", "code": "50202409" },
      { "name": "Pummelo juice", "code": "50202410" },
      { "name": "Satsuma juice", "code": "50202411" },
      { "name": "Tangelo juice", "code": "50202412" },
      { "name": "Tangerine juice", "code": "50202413" },
      { "name": "Temple juice", "code": "50202414" },
      { "name": "Ugli juice", "code": "50202415" },
      { "name": "Clementine concentrate", "code": "50202416" },
      { "name": "Kumquat concentrate", "code": "50202418" },
      { "name": "Lemon concentrate", "code": "50202419" },
      { "name": "Lime concentrate", "code": "50202420" },
      { "name": "Mandarin concentrate", "code": "50202421" },
      { "name": "Minneola concentrate", "code": "50202422" },
      { "name": "Orange concentrate", "code": "50202423" },
      { "name": "Pummelo concentrate", "code": "50202424" },
      { "name": "Satsuma concentrate", "code": "50202425" },
      { "name": "Tangelo concentrate", "code": "50202426" },
      { "name": "Tangerine concentrate", "code": "50202427" },
      { "name": "Temple concentrate", "code": "50202428" },
      { "name": "Ugli concentrate", "code": "50202429" },
      { "name": "Grapefruit juice", "code": "50202430" },
      { "name": "Fresh berry juice or concentrate", "code": "50202500" },
      { "name": "Blackberry concentrate", "code": "50202501" },
      { "name": "Blueberry concentrate", "code": "50202502" },
      { "name": "Cranberry concentrate", "code": "50202503" },
      { "name": "Grape concentrate", "code": "50202504" },
      { "name": "Raspberry concentrate", "code": "50202505" },
      { "name": "Strawberry concentrate", "code": "50202506" },
      { "name": "Boysenberry juice", "code": "50202507" },
      { "name": "Blackcurrant juice", "code": "50202508" },
      { "name": "Blackberry juice", "code": "50202509" },
      { "name": "Blueberry juice", "code": "50202510" },
      { "name": "Raspberry juice", "code": "50202511" },
      { "name": "Strawberry juice", "code": "50202512" },
      { "name": "Grape juice", "code": "50202513" },
      { "name": "Fresh pome fruit juice or concentrate", "code": "50202600" },
      { "name": "Apple juice", "code": "50202601" },
      { "name": "Pear juice", "code": "50202602" },
      {
        "name": "Fresh drupe or stone fruit juice or concentrate",
        "code": "50202700"
      },
      { "name": "Apricot juice", "code": "50202701" },
      { "name": "Cherry juice", "code": "50202702" },
      { "name": "Nectarine juice", "code": "50202703" },
      { "name": "Peach juice", "code": "50202704" },
      { "name": "Plum juice", "code": "50202705" },
      { "name": "Prune juice", "code": "50202706" },
      {
        "name": "Fresh tropical fruit juice or concentrate",
        "code": "50202800"
      },
      { "name": "Cream of coconut concentrate", "code": "50202801" },
      { "name": "Kiwi fruit concentrate", "code": "50202802" },
      { "name": "Mango concentrate", "code": "50202803" },
      { "name": "Passion fruit concentrate", "code": "50202804" },
      { "name": "Pineapple concentrate", "code": "50202805" },
      { "name": "Star fruit concentrate", "code": "50202806" },
      { "name": "Pineapple Juice", "code": "50202807" },
      { "name": "Fresh melon juice or concentrates", "code": "50203000" },
      { "name": "Cantaloupe concentrate", "code": "50203001" },
      { "name": "Honeydew melon concentrate", "code": "50203002" },
      { "name": "Watermelon concentrate", "code": "50203003" },
      { "name": "Fresh vegetable juice or concentrate", "code": "50203100" },
      { "name": "Tomato juice", "code": "50203101" },
      { "name": "Raw milk products", "code": "50203200" },
      { "name": "Raw camel milk", "code": "50203201" },
      { "name": "Raw goat milk", "code": "50203202" },
      { "name": "Raw sheep milk", "code": "50203203" },
      { "name": "Raw buffalo milk", "code": "50203204" },
      { "name": "Raw cow milk", "code": "50203205" },
      {
        "name": "Tobacco and smoking products and substitutes",
        "code": "50210000"
      },
      { "name": "Tobacco and substitutes", "code": "50211500" },
      { "name": "Cigarettes or cigars", "code": "50211502" },
      { "name": "Pipe tobacco or leaf tobacco", "code": "50211503" },
      { "name": "Chewing tobacco", "code": "50211504" },
      { "name": "Herbal cigarettes", "code": "50211505" },
      { "name": "Snuff", "code": "50211506" },
      { "name": "Electronic cigarette", "code": "50211507" },
      {
        "name": "Tobacco product accessories and supplies",
        "code": "50211600"
      },
      { "name": "Cigarette papers or filters", "code": "50211607" },
      { "name": "Cigarette lighters or flints", "code": "50211608" },
      { "name": "Smoking pipes", "code": "50211609" },
      { "name": "Tobacco pipe cleaners", "code": "50211610" },
      { "name": "Smoking sets", "code": "50211611" },
      { "name": "Cigarette lighter housings", "code": "50211612" },
      { "name": "Cereal and pulse products", "code": "50220000" },
      { "name": "Pulses", "code": "50221000" },
      { "name": "Pulse grains", "code": "50221001" },
      { "name": "Pulse flour", "code": "50221002" },
      { "name": "Cereals", "code": "50221100" },
      { "name": "Cereal grains", "code": "50221101" },
      { "name": "Cereal flour", "code": "50221102" },
      { "name": "Millet grain", "code": "50221103" },
      { "name": "Oat grain", "code": "50221104" },
      { "name": "Rye grain", "code": "50221105" },
      { "name": "Barley grain", "code": "50221106" },
      { "name": "Sorghum grain", "code": "50221107" },
      { "name": "Rice grain", "code": "50221108" },
      { "name": "Maize or corn grain", "code": "50221109" },
      { "name": "Wheat grain", "code": "50221110" },
      { "name": "Processed cereals", "code": "50221200" },
      { "name": "Ready to eat or hot cereals", "code": "50221201" },
      { "name": "Health or breakfast bars", "code": "50221202" },
      { "name": "Flour and milled products", "code": "50221300" },
      { "name": "Vegetable flour", "code": "50221301" },
      { "name": "Barley malt", "code": "50221302" },
      { "name": "Corn starch or corn flour", "code": "50221303" },
      { "name": "Potato flour", "code": "50221304" },
      { "name": "Fresh fruits", "code": "50300000" },
      { "name": "Apples", "code": "50301500" },
      { "name": "Akane apples", "code": "50301501" },
      { "name": "Ambrosia apples", "code": "50301502" },
      { "name": "Api apples", "code": "50301503" },
      { "name": "Baldwin apples", "code": "50301504" },
      { "name": "Braeburn apples", "code": "50301505" },
      { "name": "Bramley apples", "code": "50301506" },
      { "name": "Bramley seedling apples", "code": "50301507" },
      { "name": "Calville blanche d'hiver apples", "code": "50301508" },
      { "name": "Cameo apples", "code": "50301509" },
      { "name": "Charles ross apples", "code": "50301510" },
      { "name": "Codlin apples", "code": "50301511" },
      { "name": "Cortland apples", "code": "50301512" },
      { "name": "Costard apples", "code": "50301513" },
      { "name": "Court pendu plat apples", "code": "50301514" },
      { "name": "Cox's orange pippin apples", "code": "50301515" },
      { "name": "Crab apples", "code": "50301516" },
      { "name": "Crispin apples", "code": "50301517" },
      { "name": "Delicious apples", "code": "50301518" },
      { "name": "Duchess apples", "code": "50301519" },
      { "name": "Earligold apples", "code": "50301520" },
      { "name": "Early mcintosh apples", "code": "50301521" },
      { "name": "Elstar apples", "code": "50301522" },
      { "name": "Empire apples", "code": "50301523" },
      { "name": "Flower of kent apples", "code": "50301524" },
      { "name": "Fuji apples", "code": "50301525" },
      { "name": "Gala apples", "code": "50301526" },
      { "name": "Gascoyne's scarlet apples", "code": "50301527" },
      { "name": "Gilliflower apples", "code": "50301528" },
      { "name": "Ginger gold apples", "code": "50301529" },
      { "name": "Gladstone apples", "code": "50301530" },
      { "name": "Gloster apples", "code": "50301531" },
      { "name": "Gold supreme apples", "code": "50301532" },
      { "name": "Golden delicious apples", "code": "50301533" },
      { "name": "Golden noble apples", "code": "50301534" },
      { "name": "Granny smith apples", "code": "50301535" },
      { "name": "Gravenstein apples", "code": "50301536" },
      { "name": "Greening apples", "code": "50301537" },
      { "name": "Greensleeves apples", "code": "50301538" },
      { "name": "Honeycrisp apples", "code": "50301539" },
      { "name": "Howgate wonder apples", "code": "50301540" },
      { "name": "Ida red apples", "code": "50301541" },
      { "name": "James grieve apples", "code": "50301542" },
      { "name": "Jersey mac apples", "code": "50301543" },
      { "name": "Jester apples", "code": "50301544" },
      { "name": "Jonagold apples", "code": "50301545" },
      { "name": "Jonamac apples", "code": "50301546" },
      { "name": "Jonathan apples", "code": "50301547" },
      { "name": "Katy apples", "code": "50301548" },
      { "name": "Kidd's orange red apples", "code": "50301549" },
      { "name": "Lady apples", "code": "50301550" },
      { "name": "Law rome apples", "code": "50301551" },
      { "name": "Laxton apples", "code": "50301552" },
      { "name": "Lord derby apples", "code": "50301553" },
      { "name": "Macoun apples", "code": "50301554" },
      { "name": "Mcintosh apples", "code": "50301555" },
      { "name": "Mutsu apples", "code": "50301556" },
      { "name": "Newtown pippin apples", "code": "50301557" },
      { "name": "Northern spy apples", "code": "50301558" },
      { "name": "Orleans reinette apples", "code": "50301559" },
      { "name": "Ozark gold apples", "code": "50301560" },
      { "name": "Pacific rose apples", "code": "50301561" },
      { "name": "Paula red apples", "code": "50301562" },
      { "name": "Pearmain apples", "code": "50301563" },
      { "name": "Pink lady apples", "code": "50301564" },
      { "name": "Pippin apples", "code": "50301565" },
      { "name": "Pitmaston pineapple apples", "code": "50301566" },
      { "name": "Pomme d'api apples", "code": "50301567" },
      { "name": "Prime gold apples", "code": "50301568" },
      { "name": "Red astrachan apples", "code": "50301569" },
      { "name": "Red boscoop apples", "code": "50301570" },
      { "name": "Red chief apples", "code": "50301571" },
      { "name": "Red delicious apples", "code": "50301572" },
      { "name": "Red gravenstein apples", "code": "50301573" },
      { "name": "Red rome apples", "code": "50301574" },
      { "name": "Red stayman apples", "code": "50301575" },
      { "name": "Red york apples", "code": "50301576" },
      { "name": "Reinette apples", "code": "50301577" },
      { "name": "Rome beauty apples", "code": "50301578" },
      { "name": "Russet apples", "code": "50301579" },
      { "name": "Sierra beauty apples", "code": "50301580" },
      { "name": "Spartan apples", "code": "50301581" },
      { "name": "Stark crimson apples", "code": "50301582" },
      { "name": "Starking apples", "code": "50301583" },
      { "name": "Stayman apples", "code": "50301584" },
      { "name": "Stayman winesap apples", "code": "50301585" },
      { "name": "Summer rambo apples", "code": "50301586" },
      { "name": "Tsugaru apples", "code": "50301587" },
      { "name": "Twenty ounce apples", "code": "50301588" },
      { "name": "Tydeman red apples", "code": "50301589" },
      { "name": "Vistabella apples", "code": "50301590" },
      { "name": "Wealthy apples", "code": "50301591" },
      { "name": "White joaneting apples", "code": "50301592" },
      { "name": "White transparent apples", "code": "50301593" },
      { "name": "Winesap apples", "code": "50301594" },
      { "name": "Worcester apples", "code": "50301595" },
      { "name": "York imperial apples", "code": "50301596" },
      { "name": "Anna apples", "code": "50301597" },
      { "name": "Winter apple", "code": "50301598" },
      { "name": "Pear apple", "code": "50301599" },
      { "name": "Apricots", "code": "50301600" },
      { "name": "Ambercot apricots", "code": "50301601" },
      { "name": "Apache apricots", "code": "50301602" },
      { "name": "Brittany gold apricots", "code": "50301603" },
      { "name": "Black apricots", "code": "50301604" },
      { "name": "Blenheim apricots", "code": "50301605" },
      { "name": "Bonny apricots", "code": "50301606" },
      { "name": "Bulida apricots", "code": "50301607" },
      { "name": "Castlebrite apricots", "code": "50301608" },
      { "name": "Clutha gold apricots", "code": "50301609" },
      { "name": "Clutha sun apricots", "code": "50301610" },
      { "name": "Darby royal apricots", "code": "50301611" },
      { "name": "Dina apricots", "code": "50301612" },
      { "name": "Earlicot apricots", "code": "50301613" },
      { "name": "Earliman apricots", "code": "50301614" },
      { "name": "Early bright apricots", "code": "50301615" },
      { "name": "Flaming gold apricots", "code": "50301616" },
      { "name": "Fresno apricots", "code": "50301617" },
      { "name": "Gold brite apricots", "code": "50301618" },
      { "name": "Goldbar apricots", "code": "50301619" },
      { "name": "Golden sweet apricots", "code": "50301620" },
      { "name": "Goldrich apricots", "code": "50301621" },
      { "name": "Helena apricots", "code": "50301622" },
      { "name": "Honeycot apricots", "code": "50301623" },
      { "name": "Imperial apricots", "code": "50301624" },
      { "name": "Jordanne apricots", "code": "50301625" },
      { "name": "Jumbo cot apricots", "code": "50301626" },
      { "name": "Kandy kot apricots", "code": "50301627" },
      { "name": "Katy apricots", "code": "50301628" },
      { "name": "King apricots", "code": "50301629" },
      { "name": "Lambertin apricots", "code": "50301630" },
      { "name": "Lorna apricots", "code": "50301631" },
      { "name": "Lulu belle apricots", "code": "50301632" },
      { "name": "Modesto apricots", "code": "50301633" },
      { "name": "Moorpark apricots", "code": "50301634" },
      { "name": "Orangered apricots", "code": "50301635" },
      { "name": "Palstein apricots", "code": "50301636" },
      { "name": "Patterson apricots", "code": "50301637" },
      { "name": "Perfection apricots", "code": "50301638" },
      { "name": "Poppy apricots", "code": "50301639" },
      { "name": "Poppycot apricots", "code": "50301640" },
      { "name": "Queen apricots", "code": "50301641" },
      { "name": "Riland apricots", "code": "50301642" },
      { "name": "Rival apricots", "code": "50301643" },
      { "name": "Robada apricots", "code": "50301644" },
      { "name": "Royal apricots", "code": "50301645" },
      { "name": "Royal blenheim apricots", "code": "50301646" },
      { "name": "Royal orange apricots", "code": "50301647" },
      { "name": "Sundrop apricots", "code": "50301648" },
      { "name": "Tilton apricots", "code": "50301649" },
      { "name": "Tomcot apricots", "code": "50301650" },
      { "name": "Tracy apricots", "code": "50301651" },
      { "name": "Tri gem apricots", "code": "50301652" },
      { "name": "Valley gold apricots", "code": "50301653" },
      { "name": "Westley apricots", "code": "50301654" },
      { "name": "York apricots", "code": "50301655" },
      { "name": "Bananas", "code": "50301700" },
      { "name": "Apple bananas", "code": "50301701" },
      { "name": "Baby bananas", "code": "50301702" },
      { "name": "Burro bananas", "code": "50301703" },
      { "name": "Cavendish bananas", "code": "50301704" },
      { "name": "Dominico bananas", "code": "50301705" },
      { "name": "Green bananas", "code": "50301706" },
      { "name": "Gros michel bananas", "code": "50301707" },
      { "name": "Lacatan bananas", "code": "50301708" },
      { "name": "Lady finger banana", "code": "50301709" },
      { "name": "Manzano bananas", "code": "50301710" },
      { "name": "Mysore bananas", "code": "50301711" },
      { "name": "Pisang mas bananas", "code": "50301712" },
      { "name": "Red bananas", "code": "50301713" },
      { "name": "Saba bananas", "code": "50301714" },
      { "name": "Sucrier bananas", "code": "50301715" },
      { "name": "Palillo bananas", "code": "50301716" },
      { "name": "Purple bananas", "code": "50301717" },
      { "name": "Isla bananas", "code": "50301718" },
      { "name": "Bizcocho banana", "code": "50301719" },
      { "name": "Barberries", "code": "50301800" },
      { "name": "Paleleaf barberries", "code": "50301801" },
      { "name": "Chenault barberries", "code": "50301802" },
      { "name": "Red barberries", "code": "50301803" },
      { "name": "Wintergreen barberries", "code": "50301804" },
      { "name": "Korean barberries", "code": "50301805" },
      { "name": "Mentor barberries", "code": "50301806" },
      { "name": "Japanese barberries", "code": "50301807" },
      { "name": "Atropurpurea barberries", "code": "50301808" },
      { "name": "Aurea barberries", "code": "50301809" },
      { "name": "Bagatelle barberries", "code": "50301810" },
      { "name": "Crimson pygmy barberries", "code": "50301811" },
      { "name": "Kobold barberries", "code": "50301812" },
      { "name": "Warty barberries", "code": "50301813" },
      { "name": "European barberries", "code": "50301814" },
      { "name": "Bearberries", "code": "50301900" },
      { "name": "Alpine bearberries", "code": "50301901" },
      { "name": "Red bearberries", "code": "50301902" },
      { "name": "Common bearberries", "code": "50301903" },
      { "name": "Blackberries", "code": "50302000" },
      { "name": "Apache blackberries", "code": "50302001" },
      { "name": "Black satin blackberries", "code": "50302002" },
      { "name": "Boysenberries", "code": "50302003" },
      { "name": "Cherokee blackberries", "code": "50302004" },
      { "name": "Chester blackberries", "code": "50302005" },
      { "name": "Dirksen blackberries", "code": "50302006" },
      { "name": "Jostaberries", "code": "50302007" },
      { "name": "Loganberries", "code": "50302008" },
      { "name": "Marionberries", "code": "50302009" },
      { "name": "Navaho blackberries", "code": "50302010" },
      { "name": "Nectarberries", "code": "50302011" },
      { "name": "Olallie blackberries", "code": "50302012" },
      { "name": "Tayberries", "code": "50302013" },
      { "name": "Thornless hull blackberries", "code": "50302014" },
      { "name": "Youngberries", "code": "50302015" },
      { "name": "Bilberries", "code": "50302100" },
      { "name": "Bog bilberries", "code": "50302101" },
      { "name": "Dwarf bilberries", "code": "50302102" },
      { "name": "Mountain bilberries", "code": "50302103" },
      { "name": "Oval-leaved bilberries", "code": "50302104" },
      { "name": "Blueberries", "code": "50302200" },
      { "name": "Bluecrop blueberries", "code": "50302201" },
      { "name": "Bluetta blueberries", "code": "50302202" },
      { "name": "Brigitta blueberries", "code": "50302203" },
      { "name": "Chandler blueberries", "code": "50302204" },
      { "name": "Duke blueberries", "code": "50302205" },
      { "name": "Hardyblue blueberries", "code": "50302206" },
      { "name": "Legacy blueberries", "code": "50302207" },
      { "name": "Misty blueberries", "code": "50302208" },
      { "name": "Nelson blueberries", "code": "50302209" },
      { "name": "Northblue blueberries", "code": "50302210" },
      { "name": "Northcountry blueberries", "code": "50302211" },
      { "name": "Northsky blueberries", "code": "50302212" },
      { "name": "Patriot blueberries", "code": "50302213" },
      { "name": "Spartan blueberries", "code": "50302214" },
      { "name": "Toro blueberries", "code": "50302215" },
      { "name": "Breadfruit", "code": "50302300" },
      { "name": "Chataigne breadfruit", "code": "50302301" },
      { "name": "Seedless breadfruit", "code": "50302302" },
      { "name": "White heart breadfruit", "code": "50302303" },
      { "name": "Yellow heart breadfruit", "code": "50302304" },
      { "name": "Cherimoyas", "code": "50302400" },
      { "name": "Bays cherimoya", "code": "50302401" },
      { "name": "Bronceada cherimoya", "code": "50302402" },
      { "name": "Burtons cherimoya", "code": "50302403" },
      { "name": "Burtons favorite cherimoya", "code": "50302404" },
      { "name": "Jete cherimoya", "code": "50302405" },
      { "name": "Reretai cherimoya", "code": "50302406" },
      { "name": "Smoothey cherimoya", "code": "50302407" },
      { "name": "Spain cherimoya", "code": "50302408" },
      { "name": "White cherimoya", "code": "50302409" },
      { "name": "Cherries", "code": "50302500" },
      { "name": "Amarelle cherries", "code": "50302501" },
      { "name": "Brooks cherries", "code": "50302502" },
      { "name": "Bigarreu cherries", "code": "50302503" },
      { "name": "Bing cherries", "code": "50302504" },
      { "name": "Black republic cherries", "code": "50302505" },
      { "name": "Black schmidt cherries", "code": "50302506" },
      { "name": "Black tartarian cherries", "code": "50302507" },
      { "name": "Fiesta bing cherries", "code": "50302508" },
      { "name": "Garnet cherries", "code": "50302509" },
      { "name": "King cherries", "code": "50302510" },
      { "name": "Chapman cherries", "code": "50302511" },
      { "name": "Lapin cherries", "code": "50302512" },
      { "name": "Larian cherries", "code": "50302513" },
      { "name": "Dark guines cherries", "code": "50302514" },
      { "name": "Montmorency cherries", "code": "50302515" },
      { "name": "Duke cherries", "code": "50302516" },
      { "name": "Early rivers cherries", "code": "50302517" },
      { "name": "Ruby bing cherries", "code": "50302518" },
      { "name": "Santina cherries", "code": "50302519" },
      { "name": "Geans/guines cherries", "code": "50302520" },
      { "name": "Sonata cherries", "code": "50302521" },
      { "name": "Lambert cherries", "code": "50302522" },
      { "name": "Stella cherries", "code": "50302523" },
      { "name": "Sweetheart cherries", "code": "50302524" },
      { "name": "Tartarian cherries", "code": "50302525" },
      { "name": "Maraschino cherries", "code": "50302527" },
      { "name": "Van cherries", "code": "50302528" },
      { "name": "Morello cherries", "code": "50302529" },
      { "name": "Royal ann cherries", "code": "50302530" },
      { "name": "Ranier cherries", "code": "50302531" },
      { "name": "Royal cherries", "code": "50302532" },
      { "name": "Green cherries", "code": "50302533" },
      { "name": "Citrons", "code": "50302600" },
      { "name": "Buddha's hand citrons", "code": "50302601" },
      { "name": "Fingered citrons", "code": "50302602" },
      { "name": "Fo shoukan citrons", "code": "50302603" },
      { "name": "Bushakan citrons", "code": "50302604" },
      { "name": "Diamante citrons", "code": "50302605" },
      { "name": "Etrog citrons", "code": "50302606" },
      { "name": "Ponderosa citrons", "code": "50302607" },
      { "name": "Cranberries", "code": "50302700" },
      { "name": "Ben lear cranberries", "code": "50302701" },
      { "name": "Early black cranberries", "code": "50302702" },
      { "name": "Grycleski cranberries", "code": "50302703" },
      { "name": "Howe cranberries", "code": "50302704" },
      { "name": "Lingonberries", "code": "50302705" },
      { "name": "Mcfarlin cranberries", "code": "50302706" },
      { "name": "Mountain cranberries", "code": "50302707" },
      { "name": "Pilgrim cranberries", "code": "50302708" },
      { "name": "Searless cranberries", "code": "50302709" },
      { "name": "Stevens cranberries", "code": "50302710" },
      { "name": "Currants", "code": "50302800" },
      { "name": "Hudson bay currants", "code": "50302801" },
      { "name": "Waxy currants", "code": "50302802" },
      { "name": "Desert currants", "code": "50302803" },
      { "name": "Black currants", "code": "50302804" },
      { "name": "Red currants", "code": "50302805" },
      { "name": "White currants", "code": "50302806" },
      { "name": "Dates", "code": "50302900" },
      { "name": "Asharasi dates", "code": "50302901" },
      { "name": "Barhi or barhee dates", "code": "50302902" },
      { "name": "Deglet noor dates", "code": "50302903" },
      { "name": "Fardh dates", "code": "50302904" },
      { "name": "Gundila dates", "code": "50302905" },
      { "name": "Halawi/halawy dates", "code": "50302906" },
      { "name": "Hilali dates", "code": "50302907" },
      { "name": "Khadrawi/khadrawy dates", "code": "50302908" },
      { "name": "Khalas dates", "code": "50302909" },
      { "name": "Khustawi dates", "code": "50302910" },
      { "name": "Khidri dates", "code": "50302911" },
      { "name": "Medjool/medjul dates", "code": "50302912" },
      { "name": "Mactoum dates", "code": "50302913" },
      { "name": "Neghal dates", "code": "50302914" },
      { "name": "Yatimeh dates", "code": "50302915" },
      { "name": "Zahidi dates", "code": "50302916" },
      { "name": "Dragonfruit", "code": "50303000" },
      { "name": "Pink dragonfruit", "code": "50303001" },
      { "name": "Yellow dragonfruit", "code": "50303002" },
      { "name": "Figs", "code": "50303100" },
      { "name": "Bardajic figs", "code": "50303101" },
      { "name": "Brown turkey figs", "code": "50303102" },
      { "name": "Calimyrna figs", "code": "50303103" },
      { "name": "Conadria figs", "code": "50303104" },
      { "name": "Dottado figs", "code": "50303105" },
      { "name": "Kadota figs", "code": "50303106" },
      { "name": "Mediterranean figs", "code": "50303107" },
      { "name": "Mission figs", "code": "50303108" },
      { "name": "Smyrna figs", "code": "50303109" },
      { "name": "Verdona figs", "code": "50303110" },
      { "name": "White king figs", "code": "50303111" },
      { "name": "Gooseberries", "code": "50303200" },
      { "name": "Early sulphur gooseberries", "code": "50303201" },
      { "name": "Goldendrop gooseberries", "code": "50303202" },
      { "name": "Langley gage gooseberries", "code": "50303203" },
      { "name": "Leveller gooseberries", "code": "50303204" },
      { "name": "London gooseberries", "code": "50303205" },
      { "name": "Worcestershire gooseberries", "code": "50303206" },
      { "name": "American worcesterberry gooseberries", "code": "50303207" },
      { "name": "Grapefruit", "code": "50303300" },
      { "name": "Burgundy grapefruit", "code": "50303301" },
      { "name": "Duncan grapefruit", "code": "50303302" },
      { "name": "Foster grapefruit", "code": "50303303" },
      { "name": "Marsh grapefruit", "code": "50303304" },
      { "name": "New zealand grapefruit", "code": "50303305" },
      { "name": "Rio red grapefruit", "code": "50303306" },
      { "name": "Ruby red grapefruit", "code": "50303307" },
      { "name": "Star ruby grapefruit", "code": "50303308" },
      { "name": "Triumph grapefruit", "code": "50303309" },
      { "name": "Table grapes", "code": "50303400" },
      { "name": "Alicante grapes", "code": "50303401" },
      { "name": "Almeria grapes", "code": "50303402" },
      { "name": "Alphonse lavalle grapes", "code": "50303403" },
      { "name": "Autumn king grapes", "code": "50303404" },
      { "name": "Autumn royal grapes", "code": "50303405" },
      { "name": "Autumn seedless grapes", "code": "50303406" },
      { "name": "Baresana grapes", "code": "50303407" },
      { "name": "Barlinka grapes", "code": "50303408" },
      { "name": "Beauty seedless grapes", "code": "50303409" },
      { "name": "Black beauty seedless grapes", "code": "50303410" },
      { "name": "Black emerald grapes", "code": "50303411" },
      { "name": "Black giant grapes", "code": "50303412" },
      { "name": "Black globe grapes", "code": "50303413" },
      { "name": "Black monukka grapes", "code": "50303414" },
      { "name": "Black pearl grapes", "code": "50303415" },
      { "name": "Black seedless grapes", "code": "50303416" },
      { "name": "Bonheur grapes", "code": "50303417" },
      { "name": "Calmeria grapes", "code": "50303418" },
      { "name": "Cardinal grapes", "code": "50303419" },
      { "name": "Catawba grapes", "code": "50303420" },
      { "name": "Chasselas/golden chasselas grapes", "code": "50303421" },
      { "name": "Christmas rose grapes", "code": "50303422" },
      { "name": "Concord grapes", "code": "50303423" },
      { "name": "Concord seedless grapes", "code": "50303424" },
      { "name": "Crimson seedless grapes", "code": "50303425" },
      { "name": "Dauphine grapes", "code": "50303426" },
      { "name": "Delaware grapes", "code": "50303427" },
      { "name": "Early muscat grapes", "code": "50303428" },
      { "name": "Early sweet grapes", "code": "50303429" },
      { "name": "Emerald seedless grapes", "code": "50303430" },
      { "name": "Emperatriz grapes", "code": "50303431" },
      { "name": "Emperor grapes", "code": "50303432" },
      { "name": "Empress grapes", "code": "50303433" },
      { "name": "Exotic grapes", "code": "50303434" },
      { "name": "Fantasy grapes", "code": "50303435" },
      { "name": "Fantasy seedless grapes", "code": "50303436" },
      { "name": "Flame grapes", "code": "50303437" },
      { "name": "Flame seedless grapes", "code": "50303438" },
      { "name": "Flame tokay grapes", "code": "50303439" },
      { "name": "Flaming red grapes", "code": "50303440" },
      { "name": "Galaxy seedless grapes", "code": "50303441" },
      { "name": "Gamay grapes", "code": "50303442" },
      { "name": "Gold grapes", "code": "50303443" },
      { "name": "Hanepoot or honeypot grapes", "code": "50303444" },
      { "name": "Italia grapes", "code": "50303445" },
      { "name": "Jade seedless grapes", "code": "50303446" },
      { "name": "Jubilee grapes", "code": "50303447" },
      { "name": "King ruby grapes", "code": "50303448" },
      { "name": "Kyoho grapes", "code": "50303449" },
      { "name": "La rochelle grapes", "code": "50303450" },
      { "name": "Lady finger grapes", "code": "50303451" },
      { "name": "Late seedless grapes", "code": "50303452" },
      { "name": "Majestic seedless grapes", "code": "50303453" },
      { "name": "Malaga grapes", "code": "50303454" },
      { "name": "Marroo seedless grapes", "code": "50303455" },
      { "name": "Muscadine grapes", "code": "50303456" },
      { "name": "Muscat flame grapes", "code": "50303457" },
      { "name": "Muscat grapes", "code": "50303458" },
      { "name": "Muscat seedless grapes", "code": "50303459" },
      { "name": "Napoleon grapes", "code": "50303460" },
      { "name": "Negria grapes", "code": "50303461" },
      { "name": "New cross grapes", "code": "50303462" },
      { "name": "Niabell grapes", "code": "50303463" },
      { "name": "Niagara grapes", "code": "50303464" },
      { "name": "Olivette grapes", "code": "50303465" },
      { "name": "Perlette grapes", "code": "50303466" },
      { "name": "Perlon grapes", "code": "50303467" },
      { "name": "Prima black seedless grapes", "code": "50303468" },
      { "name": "Princess grapes", "code": "50303469" },
      { "name": "Queen grapes", "code": "50303470" },
      { "name": "Red blush grapes", "code": "50303471" },
      { "name": "Red globe grapes", "code": "50303472" },
      { "name": "Red malaga grapes", "code": "50303473" },
      { "name": "Red seedless grapes", "code": "50303474" },
      { "name": "Regina grapes", "code": "50303475" },
      { "name": "Ribier grapes", "code": "50303476" },
      { "name": "Rosita grapes", "code": "50303477" },
      { "name": "Rouge grapes", "code": "50303478" },
      { "name": "Royal black seedless grapes", "code": "50303479" },
      { "name": "Ruby red seedless grapes", "code": "50303480" },
      { "name": "Ruby seedless grapes", "code": "50303481" },
      { "name": "Scarlet royal grapes", "code": "50303482" },
      { "name": "Scuppernong grapes", "code": "50303483" },
      { "name": "Sugarose grapes", "code": "50303484" },
      { "name": "Sugarthirteen grapes", "code": "50303485" },
      { "name": "Sugraone grapes", "code": "50303486" },
      { "name": "Sugrasixteen grapes", "code": "50303487" },
      { "name": "Sultana sun red grapes", "code": "50303488" },
      { "name": "Summer royal grapes", "code": "50303489" },
      { "name": "Sunset grapes", "code": "50303490" },
      { "name": "Superior seedless grapes", "code": "50303491" },
      { "name": "Thompson seedless grapes", "code": "50303492" },
      { "name": "Tokay/pinot gris grapes", "code": "50303493" },
      { "name": "Waltman cross grapes", "code": "50303494" },
      { "name": "White seedless grapes", "code": "50303495" },
      { "name": "Zante current grapes", "code": "50303496" },
      { "name": "Quebranta grapes", "code": "50303497" },
      { "name": "Burgundy grapes", "code": "50303498" },
      { "name": "Torontel grapes", "code": "50303499" },
      { "name": "Raisin grapes", "code": "50303500" },
      { "name": "Black corinth grapes", "code": "50303501" },
      { "name": "Canner grapes", "code": "50303502" },
      { "name": "Dovine grapes", "code": "50303503" },
      { "name": "Fiesta grapes", "code": "50303504" },
      { "name": "Selma pete grapes", "code": "50303505" },
      { "name": "Sultana grapes", "code": "50303506" },
      { "name": "Wine grapes", "code": "50303600" },
      { "name": "Alicante bouschet grapes", "code": "50303601" },
      { "name": "Barbera grapes", "code": "50303602" },
      { "name": "Burger grapes", "code": "50303603" },
      { "name": "Cabernet franc grapes", "code": "50303604" },
      { "name": "Cabernet sauvignon grapes", "code": "50303605" },
      { "name": "Carignane grapes", "code": "50303606" },
      { "name": "Carnelian grapes", "code": "50303607" },
      { "name": "Catarratto grapes", "code": "50303608" },
      { "name": "Centurian grapes", "code": "50303609" },
      { "name": "Charbono grapes", "code": "50303610" },
      { "name": "Chardonnay grapes", "code": "50303611" },
      { "name": "Chenin blanc grapes", "code": "50303612" },
      { "name": "Cinsaut grapes", "code": "50303613" },
      { "name": "Dolcetto grapes", "code": "50303614" },
      { "name": "Emerald riesling grapes", "code": "50303615" },
      { "name": "French colombard grapes", "code": "50303616" },
      { "name": "Gamay napa grapes", "code": "50303617" },
      { "name": "Gamay beaujolais grapes", "code": "50303618" },
      { "name": "Gewurztraminer grapes", "code": "50303619" },
      { "name": "Grenache grapes", "code": "50303620" },
      { "name": "Grenache blanc grapes", "code": "50303621" },
      { "name": "Lagrein grapes", "code": "50303622" },
      { "name": "Lambrusco grapes", "code": "50303623" },
      { "name": "Malbec grapes", "code": "50303624" },
      { "name": "Malvasia bianca grapes", "code": "50303625" },
      { "name": "Marsanne grapes", "code": "50303626" },
      { "name": "Mataro grapes", "code": "50303627" },
      { "name": "Merlot grapes", "code": "50303628" },
      { "name": "Meunier grapes", "code": "50303629" },
      { "name": "Mission grapes", "code": "50303630" },
      { "name": "Montepulciano grapes", "code": "50303631" },
      { "name": "Muscat blanc grapes", "code": "50303632" },
      { "name": "Muscat hamburg grapes", "code": "50303633" },
      { "name": "Muscat of alexandria grapes", "code": "50303634" },
      { "name": "Muscat orange grapes", "code": "50303635" },
      { "name": "Nebbiolo grapes", "code": "50303636" },
      { "name": "Palomino grapes", "code": "50303637" },
      { "name": "Petit verdot grapes", "code": "50303638" },
      { "name": "Petite sirah grapes", "code": "50303639" },
      { "name": "Pinot blanc grapes", "code": "50303640" },
      { "name": "Pinot gris grapes", "code": "50303641" },
      { "name": "Pinot noir grapes", "code": "50303642" },
      { "name": "Primitivo grapes", "code": "50303643" },
      { "name": "Roussanne grapes", "code": "50303644" },
      { "name": "Royalty grapes", "code": "50303645" },
      { "name": "Rubired grapes", "code": "50303646" },
      { "name": "Ruby cabernet grapes", "code": "50303647" },
      { "name": "Salvador grapes", "code": "50303648" },
      { "name": "Sangiovese grapes", "code": "50303649" },
      { "name": "Sauvignon blanc grapes", "code": "50303650" },
      { "name": "Sauvignon musque grapes", "code": "50303651" },
      { "name": "Semillon grapes", "code": "50303652" },
      { "name": "Souzao grapes", "code": "50303653" },
      { "name": "St emilion grapes", "code": "50303654" },
      { "name": "Symphony grapes", "code": "50303655" },
      { "name": "Syrah grapes", "code": "50303656" },
      { "name": "Tannat grapes", "code": "50303657" },
      { "name": "Tempranillo grapes", "code": "50303658" },
      { "name": "Teroldego grapes", "code": "50303659" },
      { "name": "Tocai friulano grapes", "code": "50303660" },
      { "name": "Touriga nacional grapes", "code": "50303661" },
      { "name": "Triplett blanc grapes", "code": "50303662" },
      { "name": "Viognier grapes", "code": "50303663" },
      { "name": "White riesling grapes", "code": "50303664" },
      { "name": "Zinfandel grapes", "code": "50303665" },
      { "name": "Guavas", "code": "50303700" },
      { "name": "Beaumont guavas", "code": "50303701" },
      { "name": "Carrley guavas", "code": "50303702" },
      { "name": "Lucida guavas", "code": "50303703" },
      { "name": "Pineapple guava", "code": "50303704" },
      { "name": "Huckleberries", "code": "50303800" },
      { "name": "Black winter huckleberries", "code": "50303801" },
      { "name": "Cascade huckleberries", "code": "50303802" },
      { "name": "Dwarf huckleberries", "code": "50303803" },
      { "name": "Mountain huckleberries", "code": "50303804" },
      { "name": "Red huckleberries", "code": "50303805" },
      { "name": "Kiwi fruit", "code": "50303900" },
      { "name": "Ananasnaja kiwi fruit", "code": "50303901" },
      { "name": "Arctic beauty kiwi fruit", "code": "50303902" },
      { "name": "Blake kiwi fruit", "code": "50303903" },
      { "name": "Hayward kiwi fruit", "code": "50303904" },
      { "name": "Issai kiwi fruit", "code": "50303905" },
      { "name": "Siberian kiwi fruit", "code": "50303906" },
      { "name": "Kumquats", "code": "50304000" },
      { "name": "Hong kong kumquats", "code": "50304001" },
      { "name": "Limequat kumquats", "code": "50304002" },
      { "name": "Long fruit kumquats", "code": "50304003" },
      { "name": "Malayan kumquats", "code": "50304004" },
      { "name": "Meiwa kumquats", "code": "50304005" },
      { "name": "Nagami kumquats", "code": "50304006" },
      { "name": "Lemons", "code": "50304100" },
      { "name": "Baboon lemons", "code": "50304101" },
      { "name": "Bearss sicilian lemons", "code": "50304102" },
      { "name": "Cameron highlands lemons", "code": "50304103" },
      { "name": "Escondido lemons", "code": "50304104" },
      { "name": "Eureka lemons", "code": "50304105" },
      { "name": "Lisbon lemons", "code": "50304106" },
      { "name": "Meyer lemons", "code": "50304107" },
      { "name": "Volkamer lemons", "code": "50304108" },
      { "name": "Limes", "code": "50304200" },
      { "name": "Indian sweet limes", "code": "50304201" },
      { "name": "Key limes", "code": "50304202" },
      { "name": "Mandarin limes", "code": "50304203" },
      { "name": "Philippine limes", "code": "50304204" },
      { "name": "Tahitian limes", "code": "50304205" },
      { "name": "Bearss limes", "code": "50304206" },
      { "name": "Persian limes", "code": "50304207" },
      { "name": "Seedless limes", "code": "50304208" },
      { "name": "Loquats", "code": "50304300" },
      { "name": "Advance loquats", "code": "50304301" },
      { "name": "Benlehr loquats", "code": "50304302" },
      { "name": "Big jim loquats", "code": "50304303" },
      { "name": "Champagne loquats", "code": "50304304" },
      { "name": "Early red loquats", "code": "50304305" },
      { "name": "Gold nugget loquats", "code": "50304306" },
      { "name": "Herd's mammoth loquats", "code": "50304307" },
      { "name": "Mogi loquats", "code": "50304308" },
      { "name": "Mrs cooksey loquats", "code": "50304309" },
      { "name": "Strawberry loquats", "code": "50304310" },
      { "name": "Tanaka loquats", "code": "50304311" },
      { "name": "Victory vista white loquats", "code": "50304312" },
      { "name": "Wolfe loquats", "code": "50304313" },
      { "name": "Mandarin oranges or tangerines", "code": "50304400" },
      { "name": "Clauselinas oranges", "code": "50304401" },
      { "name": "Clementine tangerines", "code": "50304402" },
      { "name": "Cleopatra mandarin oranges", "code": "50304403" },
      { "name": "Dancy tangerines", "code": "50304404" },
      { "name": "Ellensdale oranges", "code": "50304405" },
      { "name": "Fairchild oranges", "code": "50304406" },
      { "name": "Fallglo oranges", "code": "50304407" },
      { "name": "Fortune oranges", "code": "50304408" },
      { "name": "Fremont mandarin oranges", "code": "50304409" },
      { "name": "Fremont oranges", "code": "50304410" },
      { "name": "Golden nugget oranges", "code": "50304411" },
      { "name": "Honey mandarin oranges", "code": "50304412" },
      { "name": "Honey oranges", "code": "50304413" },
      { "name": "Honey tangerines", "code": "50304414" },
      { "name": "Honeybelle tangelo oranges", "code": "50304415" },
      { "name": "King mandarin oranges", "code": "50304416" },
      { "name": "Kinnow oranges", "code": "50304417" },
      { "name": "Lee mandarin oranges", "code": "50304418" },
      { "name": "Makokkee oranges", "code": "50304419" },
      { "name": "Malvasios oranges", "code": "50304420" },
      { "name": "Mediterranean mandarin oranges", "code": "50304421" },
      { "name": "Minneola tangelo oranges", "code": "50304422" },
      { "name": "Monica oranges", "code": "50304423" },
      { "name": "Murcott honey oranges", "code": "50304424" },
      { "name": "Murcott tangors", "code": "50304425" },
      { "name": "Natsudaidai mandarin oranges", "code": "50304426" },
      { "name": "Natsumikan mandarin oranges", "code": "50304427" },
      { "name": "Nocatee tangelo oranges", "code": "50304428" },
      { "name": "Orlando tangelo oranges", "code": "50304429" },
      { "name": "Ortanique tangerines", "code": "50304430" },
      { "name": "Page mandarin oranges", "code": "50304431" },
      { "name": "Pixie oranges", "code": "50304432" },
      { "name": "Ponkan bantangas mandarin oranges", "code": "50304433" },
      { "name": "Reyna oranges", "code": "50304434" },
      { "name": "Robinson oranges", "code": "50304435" },
      { "name": "Saltenitas oranges", "code": "50304436" },
      { "name": "Sampson tangelo oranges", "code": "50304437" },
      { "name": "Satsuma mandarin oranges", "code": "50304438" },
      { "name": "Sunburst mandarin oranges", "code": "50304439" },
      { "name": "Tangelos", "code": "50304440" },
      { "name": "Tangerina oranges", "code": "50304441" },
      { "name": "Temple oranges", "code": "50304442" },
      { "name": "Thornton oranges", "code": "50304443" },
      { "name": "Wekiwa tangerines", "code": "50304444" },
      { "name": "Wilkins tangerines", "code": "50304445" },
      { "name": "Willowleaf mediterranean tangerines", "code": "50304446" },
      { "name": "Mangoes", "code": "50304500" },
      { "name": "Alphonso mangoes", "code": "50304501" },
      { "name": "Ataulfo mangoes", "code": "50304502" },
      { "name": "Criollo mangoes", "code": "50304503" },
      { "name": "Edwards mangoes", "code": "50304504" },
      { "name": "Francine mangoes", "code": "50304505" },
      { "name": "Francis mangoes", "code": "50304506" },
      { "name": "Gandaria mangoes", "code": "50304507" },
      { "name": "Haden mangoes", "code": "50304508" },
      { "name": "Irwin mangoes", "code": "50304509" },
      { "name": "Keitt mangoes", "code": "50304510" },
      { "name": "Kent mangoes", "code": "50304511" },
      { "name": "Kesar mangoes", "code": "50304512" },
      { "name": "Kuini mangoes", "code": "50304513" },
      { "name": "Manila super mangoes", "code": "50304514" },
      { "name": "Manila mangoes", "code": "50304515" },
      { "name": "Mayaguez mangoes", "code": "50304516" },
      { "name": "Mulgoba mangoes", "code": "50304517" },
      { "name": "Oro mangoes", "code": "50304518" },
      { "name": "Palmer mangoes", "code": "50304519" },
      { "name": "Parvin mangoes", "code": "50304520" },
      { "name": "Sandersha mangoes", "code": "50304521" },
      { "name": "Sensation mangoes", "code": "50304522" },
      { "name": "Smith mangoes", "code": "50304523" },
      { "name": "Tommy atkins mangoes", "code": "50304524" },
      { "name": "Van dyke mangoes", "code": "50304525" },
      { "name": "Melons", "code": "50304600" },
      { "name": "Allsweet melons", "code": "50304601" },
      { "name": "Athena melons", "code": "50304602" },
      { "name": "Black diamond melons", "code": "50304603" },
      { "name": "Cal sweet melons", "code": "50304604" },
      { "name": "Cantaloupe melons", "code": "50304605" },
      { "name": "Carnical melons", "code": "50304606" },
      { "name": "Casaba melons", "code": "50304607" },
      { "name": "Cavaillon melons", "code": "50304608" },
      { "name": "Charentais melons", "code": "50304609" },
      { "name": "Charleston gray watermelon", "code": "50304610" },
      { "name": "Crenshaw melons", "code": "50304611" },
      { "name": "Crimson sweet melons", "code": "50304612" },
      { "name": "Dixie lee melons", "code": "50304613" },
      { "name": "Eclipse melons", "code": "50304614" },
      { "name": "Ein d'or melons", "code": "50304615" },
      { "name": "Fiesta melons", "code": "50304616" },
      { "name": "Galia melons", "code": "50304617" },
      { "name": "Gaya melons", "code": "50304618" },
      { "name": "Hami melons", "code": "50304619" },
      { "name": "Honeydew melons", "code": "50304620" },
      { "name": "Icebox melons", "code": "50304621" },
      { "name": "Ida pride melons", "code": "50304622" },
      { "name": "Juan canary melons", "code": "50304623" },
      { "name": "Jubilee melons", "code": "50304624" },
      { "name": "Jubilation melons", "code": "50304625" },
      { "name": "Kakhi/kakri melons", "code": "50304626" },
      { "name": "Kiwano melons", "code": "50304627" },
      { "name": "Korean melons", "code": "50304628" },
      { "name": "Long gray melons", "code": "50304629" },
      { "name": "Mayan melons", "code": "50304630" },
      { "name": "Micky lee melons", "code": "50304631" },
      { "name": "Mirage melons", "code": "50304632" },
      { "name": "Moon and stars watermelon", "code": "50304633" },
      { "name": "Ogen melons", "code": "50304634" },
      { "name": "Patriot melons", "code": "50304635" },
      { "name": "Peacock melons", "code": "50304636" },
      { "name": "Pepino melons", "code": "50304637" },
      { "name": "Persian melons", "code": "50304638" },
      { "name": "Picnic melons", "code": "50304639" },
      { "name": "Piel de sapo melons", "code": "50304640" },
      { "name": "Pineapple melons", "code": "50304641" },
      { "name": "Quetzali melons", "code": "50304642" },
      { "name": "Red goblin melons", "code": "50304643" },
      { "name": "Regency melons", "code": "50304644" },
      { "name": "Royal majestic melons", "code": "50304645" },
      { "name": "Royal star melons", "code": "50304646" },
      { "name": "Royal sweet melons", "code": "50304647" },
      { "name": "Santa claus melons", "code": "50304648" },
      { "name": "Sharlyn melons", "code": "50304649" },
      { "name": "Spanish melons", "code": "50304650" },
      { "name": "Sprite melons", "code": "50304651" },
      { "name": "Starbright melons", "code": "50304652" },
      { "name": "Stars n stripes melons", "code": "50304653" },
      { "name": "Sugar baby melons", "code": "50304654" },
      { "name": "Sugar baby watermelon", "code": "50304655" },
      { "name": "Sunsweet melons", "code": "50304656" },
      { "name": "Sweet heart seedless watermelon", "code": "50304657" },
      { "name": "Temptation melons", "code": "50304658" },
      { "name": "Tiger baby melons", "code": "50304659" },
      { "name": "Tuscan type melons", "code": "50304660" },
      { "name": "Yellow baby watermelon", "code": "50304661" },
      { "name": "Mulberries", "code": "50304700" },
      { "name": "Black mulberries", "code": "50304701" },
      { "name": "White mulberries", "code": "50304702" },
      { "name": "Bayberries or myrtles", "code": "50304800" },
      { "name": "Bog myrtle", "code": "50304801" },
      { "name": "Bayberries", "code": "50304802" },
      { "name": "Nectarines", "code": "50304900" },
      { "name": "April glo nectarines", "code": "50304901" },
      { "name": "Arctic mist nectarines", "code": "50304902" },
      { "name": "Arctic snow nectarines", "code": "50304903" },
      { "name": "Arctic star nectarines", "code": "50304904" },
      { "name": "Arctic sweet nectarines", "code": "50304905" },
      { "name": "Arctic glo nectarines", "code": "50304906" },
      { "name": "August fire nectarines", "code": "50304907" },
      { "name": "August pearl nectarines", "code": "50304908" },
      { "name": "August red nectarines", "code": "50304909" },
      { "name": "Autumn star nectarines", "code": "50304910" },
      { "name": "Big john nectarines", "code": "50304911" },
      { "name": "Bright pearl nectarines", "code": "50304912" },
      { "name": "Diamond bright nectarines", "code": "50304913" },
      { "name": "Diamond ray nectarines", "code": "50304914" },
      { "name": "Earliglo nectarines", "code": "50304915" },
      { "name": "Early diamond nectarines", "code": "50304916" },
      { "name": "Fairlane nectarines", "code": "50304917" },
      { "name": "Fantasia nectarines", "code": "50304918" },
      { "name": "Fire pearl nectarines", "code": "50304919" },
      { "name": "Fire sweet nectarines", "code": "50304920" },
      { "name": "Flamekist nectarines", "code": "50304921" },
      { "name": "Flat type nectarines", "code": "50304922" },
      { "name": "Garden delight nectarines", "code": "50304923" },
      { "name": "Goldmine nectarines", "code": "50304924" },
      { "name": "Grand pearl nectarines", "code": "50304925" },
      { "name": "Hardired nectarines", "code": "50304926" },
      { "name": "Honey blaze nectarines", "code": "50304927" },
      { "name": "July red nectarines", "code": "50304928" },
      { "name": "Kay pearl nectarines", "code": "50304929" },
      { "name": "Kay sweet nectarines", "code": "50304930" },
      { "name": "May diamond nectarines", "code": "50304931" },
      { "name": "Mayfire nectarines", "code": "50304932" },
      { "name": "Mayglo nectarines", "code": "50304933" },
      { "name": "Mericrest nectarines", "code": "50304934" },
      { "name": "Red diamond nectarines", "code": "50304935" },
      { "name": "Red gold nectarines", "code": "50304936" },
      { "name": "Red jim nectarines", "code": "50304937" },
      { "name": "Red roy nectarines", "code": "50304938" },
      { "name": "Rio red nectarines", "code": "50304939" },
      { "name": "Rose diamond nectarines", "code": "50304940" },
      { "name": "Royal glo nectarines", "code": "50304941" },
      { "name": "Ruby diamond nectarines", "code": "50304942" },
      { "name": "Ruby sweet nectarines", "code": "50304943" },
      { "name": "Ruddy jewel nectarines", "code": "50304944" },
      { "name": "September red nectarines", "code": "50304945" },
      { "name": "Snowqueen nectarines", "code": "50304946" },
      { "name": "Spring bright nectarines", "code": "50304947" },
      { "name": "Spring red nectarines", "code": "50304948" },
      { "name": "Summer blush nectarines", "code": "50304949" },
      { "name": "Summer brite nectarines", "code": "50304950" },
      { "name": "Summer diamond nectarines", "code": "50304951" },
      { "name": "Summer fire nectarines", "code": "50304952" },
      { "name": "Summer grand nectarines", "code": "50304953" },
      { "name": "Sunglo nectarines", "code": "50304954" },
      { "name": "Zee fire nectarines", "code": "50304955" },
      { "name": "Zee glo nectarines", "code": "50304956" },
      { "name": "Zeegrand nectarines", "code": "50304957" },
      { "name": "Oranges", "code": "50305000" },
      { "name": "African sour oranges", "code": "50305001" },
      { "name": "Ambersweet oranges", "code": "50305002" },
      { "name": "Argentine sour oranges", "code": "50305003" },
      { "name": "Bahianinha oranges", "code": "50305004" },
      { "name": "Bergamot oranges", "code": "50305005" },
      { "name": "Berna oranges", "code": "50305006" },
      { "name": "Bigaradier apepu oranges", "code": "50305007" },
      { "name": "Bittersweet daidai oranges", "code": "50305008" },
      { "name": "Blonde oranges", "code": "50305009" },
      { "name": "Blood oranges", "code": "50305010" },
      { "name": "California navel oranges", "code": "50305011" },
      { "name": "Cara cara oranges", "code": "50305012" },
      { "name": "Chinotto oranges", "code": "50305013" },
      { "name": "Dream navel oranges", "code": "50305014" },
      { "name": "Gou tou oranges", "code": "50305015" },
      { "name": "Hamlin oranges", "code": "50305016" },
      { "name": "Jaffa oranges", "code": "50305017" },
      { "name": "Jincheng oranges", "code": "50305018" },
      { "name": "K-early oranges", "code": "50305019" },
      { "name": "Kona oranges", "code": "50305020" },
      { "name": "Late navel oranges", "code": "50305021" },
      { "name": "Late valencia oranges", "code": "50305022" },
      { "name": "Limequat oranges", "code": "50305023" },
      { "name": "Marr oranges", "code": "50305024" },
      { "name": "Melogold oranges", "code": "50305025" },
      { "name": "Moro oranges", "code": "50305026" },
      { "name": "Moro tarocco oranges", "code": "50305027" },
      { "name": "Navel oranges", "code": "50305028" },
      { "name": "Navelina oranges", "code": "50305029" },
      { "name": "Oro blanco oranges", "code": "50305030" },
      { "name": "Osceola oranges", "code": "50305031" },
      { "name": "Parson brown oranges", "code": "50305032" },
      { "name": "Pera oranges", "code": "50305033" },
      { "name": "Pummulo oranges", "code": "50305034" },
      { "name": "Rhode red oranges", "code": "50305035" },
      { "name": "Roble oranges", "code": "50305036" },
      { "name": "Salustianas oranges", "code": "50305037" },
      { "name": "Sanguine oranges", "code": "50305038" },
      { "name": "Sanguinelli oranges", "code": "50305039" },
      { "name": "Seville oranges", "code": "50305040" },
      { "name": "Shamouti jaffa oranges", "code": "50305041" },
      { "name": "Tunis oranges", "code": "50305042" },
      { "name": "Valencia oranges", "code": "50305043" },
      { "name": "Washington navel oranges", "code": "50305044" },
      { "name": "Papayas", "code": "50305100" },
      { "name": "Green cooking papayas", "code": "50305101" },
      { "name": "Maradol papayas", "code": "50305102" },
      { "name": "Mexican yellow papayas", "code": "50305103" },
      { "name": "Mountain papayas", "code": "50305104" },
      { "name": "Solo papayas", "code": "50305105" },
      { "name": "Tainung papayas", "code": "50305106" },
      { "name": "Peruvian papaya", "code": "50305107" },
      { "name": "Passion fruit", "code": "50305200" },
      { "name": "Banana passion fruit", "code": "50305201" },
      { "name": "Blue passion flower", "code": "50305202" },
      { "name": "Crackerjack passion fruit", "code": "50305203" },
      { "name": "Giant granadilla passion fruit", "code": "50305204" },
      { "name": "Golden granadilla passion fruit", "code": "50305205" },
      { "name": "Maypops passion fruit", "code": "50305206" },
      { "name": "Red granadilla passion fruit", "code": "50305207" },
      { "name": "Sweet granadilla passion fruit", "code": "50305208" },
      { "name": "Water lemon passion fruit", "code": "50305209" },
      { "name": "Wing-stemmed passion flower", "code": "50305210" },
      { "name": "Peaches", "code": "50305300" },
      { "name": "Amber crest peaches", "code": "50305301" },
      { "name": "April snow peaches", "code": "50305302" },
      { "name": "August lady peaches", "code": "50305303" },
      { "name": "Autumn flame peaches", "code": "50305304" },
      { "name": "Autumn lady peaches", "code": "50305305" },
      { "name": "Babcock peaches", "code": "50305306" },
      { "name": "Brittney lane peaches", "code": "50305307" },
      { "name": "Cary mac peaches", "code": "50305308" },
      { "name": "Classic peaches", "code": "50305309" },
      { "name": "Country sweet peaches", "code": "50305310" },
      { "name": "Crest haven peaches", "code": "50305311" },
      { "name": "Crimson lady peaches", "code": "50305312" },
      { "name": "Crown princess peaches", "code": "50305313" },
      { "name": "David sun peaches", "code": "50305314" },
      { "name": "Diamond princess peaches", "code": "50305315" },
      { "name": "Earlirich peaches", "code": "50305316" },
      { "name": "Early majestic peaches", "code": "50305317" },
      { "name": "Early treat peaches", "code": "50305318" },
      { "name": "Elegant lady peaches", "code": "50305319" },
      { "name": "Empress peaches", "code": "50305320" },
      { "name": "Encore peaches", "code": "50305321" },
      { "name": "Fancy lady peaches", "code": "50305322" },
      { "name": "Fire prince peaches", "code": "50305323" },
      { "name": "Flame crest peaches", "code": "50305324" },
      { "name": "Flat type peaches", "code": "50305325" },
      { "name": "Flavorcrest peaches", "code": "50305326" },
      { "name": "Florida prince peaches", "code": "50305327" },
      { "name": "Full moon peaches", "code": "50305328" },
      { "name": "Harvester peaches", "code": "50305329" },
      { "name": "Ice princess peaches", "code": "50305330" },
      { "name": "Ivory princess peaches", "code": "50305331" },
      { "name": "Jersey queen peaches", "code": "50305332" },
      { "name": "John henry peaches", "code": "50305333" },
      { "name": "June prince peaches", "code": "50305334" },
      { "name": "Kaweah peaches", "code": "50305335" },
      { "name": "Klondike peaches", "code": "50305336" },
      { "name": "Lindo peaches", "code": "50305337" },
      { "name": "Loring peaches", "code": "50305338" },
      { "name": "Majestic peaches", "code": "50305339" },
      { "name": "O'henry peaches", "code": "50305340" },
      { "name": "Queencrest peaches", "code": "50305341" },
      { "name": "Red lady peaches", "code": "50305342" },
      { "name": "Redglobe peaches", "code": "50305343" },
      { "name": "Redhaven peaches", "code": "50305344" },
      { "name": "Redtop peaches", "code": "50305345" },
      { "name": "Regina peaches", "code": "50305346" },
      { "name": "Rich lady peaches", "code": "50305347" },
      { "name": "Rich may peaches", "code": "50305348" },
      { "name": "Royal glory peaches", "code": "50305349" },
      { "name": "Royal lady peaches", "code": "50305350" },
      { "name": "September snow peaches", "code": "50305351" },
      { "name": "September sun peaches", "code": "50305352" },
      { "name": "Sierra gem peaches", "code": "50305353" },
      { "name": "Snow angel peaches", "code": "50305354" },
      { "name": "Snow gem peaches", "code": "50305355" },
      { "name": "Snow king peaches", "code": "50305356" },
      { "name": "Spring lady peaches", "code": "50305357" },
      { "name": "Spring snow peaches", "code": "50305358" },
      { "name": "Springcrest peaches", "code": "50305359" },
      { "name": "Sugar giant peaches", "code": "50305360" },
      { "name": "Sugar lady peaches", "code": "50305361" },
      { "name": "Sun bright peaches", "code": "50305362" },
      { "name": "Sunhigh peaches", "code": "50305363" },
      { "name": "Super lady peaches", "code": "50305364" },
      { "name": "Super rich peaches", "code": "50305365" },
      { "name": "Surecrop peaches", "code": "50305366" },
      { "name": "Sweet dream peaches", "code": "50305367" },
      { "name": "Sweet september peaches", "code": "50305368" },
      { "name": "Vista peaches", "code": "50305369" },
      { "name": "White lady peaches", "code": "50305370" },
      { "name": "Zee lady peaches", "code": "50305371" },
      { "name": "Pears", "code": "50305400" },
      { "name": "Abate fetel pears", "code": "50305401" },
      { "name": "Anjou pears", "code": "50305402" },
      { "name": "Asian pears", "code": "50305403" },
      { "name": "Bartlett pears", "code": "50305404" },
      { "name": "Best ever pears", "code": "50305405" },
      { "name": "Beth pears", "code": "50305406" },
      { "name": "Beurre pears", "code": "50305407" },
      { "name": "Bosc pears", "code": "50305408" },
      { "name": "Clapp favorite pears", "code": "50305409" },
      { "name": "Comice pears", "code": "50305410" },
      { "name": "Concorde pears", "code": "50305411" },
      { "name": "Conference pears", "code": "50305412" },
      { "name": "Crimson red pears", "code": "50305413" },
      { "name": "D'anjou pears", "code": "50305414" },
      { "name": "Dr jules guyot pears", "code": "50305415" },
      { "name": "Early pears", "code": "50305416" },
      { "name": "Emperor brown pears", "code": "50305417" },
      { "name": "Forelle pears", "code": "50305418" },
      { "name": "French butter pears", "code": "50305419" },
      { "name": "Glou morceau pears", "code": "50305420" },
      { "name": "Hosui pears", "code": "50305421" },
      { "name": "Italian butter pears", "code": "50305422" },
      { "name": "Jargonelle pears", "code": "50305423" },
      { "name": "Juno pears", "code": "50305424" },
      { "name": "Kaiserlouise bonne de jersey pears", "code": "50305425" },
      { "name": "Keiffer pears", "code": "50305426" },
      { "name": "Kings royal pears", "code": "50305427" },
      { "name": "Limonera pears", "code": "50305428" },
      { "name": "Merton pride pears", "code": "50305429" },
      { "name": "Mountain bartlett pears", "code": "50305430" },
      { "name": "Olivier de serres pears", "code": "50305431" },
      { "name": "Onward pears", "code": "50305432" },
      { "name": "Packham's triumph pears", "code": "50305433" },
      { "name": "Paraiso pears", "code": "50305434" },
      { "name": "Passe crasanne pears", "code": "50305435" },
      { "name": "Perry pears", "code": "50305436" },
      { "name": "Red bartlett pears", "code": "50305437" },
      { "name": "Red d'anjou pears", "code": "50305438" },
      { "name": "Rocha pears", "code": "50305439" },
      { "name": "Rosey red pears", "code": "50305440" },
      { "name": "Rosy red pears", "code": "50305441" },
      { "name": "Royal majestic pears", "code": "50305442" },
      { "name": "Ruby red pears", "code": "50305443" },
      { "name": "Santa maria pears", "code": "50305444" },
      { "name": "Seckel pears", "code": "50305445" },
      { "name": "Sensation pears", "code": "50305446" },
      { "name": "Star crimson pears", "code": "50305447" },
      { "name": "Stark crimson pears", "code": "50305448" },
      { "name": "Summer bartlett pears", "code": "50305449" },
      { "name": "Summer gold pears", "code": "50305450" },
      { "name": "Sun gold pears", "code": "50305451" },
      { "name": "Sunsprite pears", "code": "50305452" },
      { "name": "Taylors gold pears", "code": "50305453" },
      { "name": "Taylors red pears", "code": "50305454" },
      { "name": "Tientsin pears", "code": "50305455" },
      { "name": "Tosca pears", "code": "50305456" },
      { "name": "Warden pears", "code": "50305457" },
      { "name": "Williams bon chretien pears", "code": "50305458" },
      { "name": "Williams pears", "code": "50305459" },
      { "name": "Winter nelis pears", "code": "50305460" },
      { "name": "Persimmons", "code": "50305500" },
      { "name": "American persimmon", "code": "50305501" },
      { "name": "Black sapote persimmon", "code": "50305502" },
      { "name": "Chapote/black persimmon", "code": "50305503" },
      { "name": "Date plum persimmon", "code": "50305504" },
      { "name": "Fuyu persimmons", "code": "50305505" },
      { "name": "Giant fuyu persimmons", "code": "50305506" },
      { "name": "Hachiya persimmons", "code": "50305507" },
      { "name": "Mabolo/butter fruit persimmon", "code": "50305508" },
      { "name": "Principe ito persimmons", "code": "50305509" },
      { "name": "Royal brillante persimmons", "code": "50305510" },
      { "name": "Sharon fruit persimmon", "code": "50305511" },
      { "name": "Triumph persimmons", "code": "50305512" },
      { "name": "Pineapples", "code": "50305600" },
      { "name": "Cherimoya pineapple", "code": "50305601" },
      { "name": "Golden pineapple", "code": "50305602" },
      { "name": "Hilo pineapple", "code": "50305603" },
      { "name": "Kona sugarloaf pineapple", "code": "50305604" },
      { "name": "Natal queen pineapple", "code": "50305605" },
      { "name": "Pernambuco pineapple", "code": "50305606" },
      { "name": "Red spanish pineapple", "code": "50305607" },
      { "name": "Smooth cayenne pineapple", "code": "50305608" },
      { "name": "Sugarloaf pineapple", "code": "50305609" },
      { "name": "Variegated pineapple", "code": "50305610" },
      { "name": "Plucots", "code": "50305700" },
      { "name": "Black kat plucot", "code": "50305701" },
      { "name": "Blue gusto plucot", "code": "50305702" },
      { "name": "Crimson heart plucot", "code": "50305703" },
      { "name": "Dapple dandy plucot", "code": "50305704" },
      { "name": "Dapple fire plucot", "code": "50305705" },
      { "name": "Early dapple plucot", "code": "50305706" },
      { "name": "Flavor fall plucot", "code": "50305707" },
      { "name": "Flavor gold plucot", "code": "50305708" },
      { "name": "Flavor grenade plucot", "code": "50305709" },
      { "name": "Flavor heart plucot", "code": "50305710" },
      { "name": "Flavor jewel plucot", "code": "50305711" },
      { "name": "Flavor king plucot", "code": "50305712" },
      { "name": "Flavor queen plucot", "code": "50305713" },
      { "name": "Flavor supreme plucot", "code": "50305714" },
      { "name": "Flavor treat plucot", "code": "50305715" },
      { "name": "Flavorella plucot", "code": "50305716" },
      { "name": "Flavorich plucot", "code": "50305717" },
      { "name": "Flavorosa plucot", "code": "50305718" },
      { "name": "Geo pride plucot", "code": "50305719" },
      { "name": "Red kat plucot", "code": "50305720" },
      { "name": "Royal treat plucot", "code": "50305721" },
      { "name": "Sierra rose plucot", "code": "50305722" },
      { "name": "Sweet geisha plucot", "code": "50305723" },
      { "name": "Plums", "code": "50305800" },
      { "name": "Amber jewel plums", "code": "50305801" },
      { "name": "Angeleno plums", "code": "50305802" },
      { "name": "Aurora plums", "code": "50305803" },
      { "name": "Autumn beaut plums", "code": "50305804" },
      { "name": "Autumn giant plums", "code": "50305805" },
      { "name": "Autumn pride plums", "code": "50305806" },
      { "name": "Autumn rosa plums", "code": "50305807" },
      { "name": "Beach plum", "code": "50305808" },
      { "name": "Betty anne plums", "code": "50305809" },
      { "name": "Black beaut plums", "code": "50305810" },
      { "name": "Black bullace plum", "code": "50305811" },
      { "name": "Black diamond plums", "code": "50305812" },
      { "name": "Black giant plums", "code": "50305813" },
      { "name": "Black ice plums", "code": "50305814" },
      { "name": "Black splendor plums", "code": "50305815" },
      { "name": "Blackamber plums", "code": "50305816" },
      { "name": "Burgundy plums", "code": "50305817" },
      { "name": "Carlsbad plum", "code": "50305818" },
      { "name": "Casselman plums", "code": "50305819" },
      { "name": "Catalina plums", "code": "50305820" },
      { "name": "Damson plum", "code": "50305821" },
      { "name": "Dolly plums", "code": "50305822" },
      { "name": "Earliqueen plums", "code": "50305823" },
      { "name": "Early rosa plums", "code": "50305824" },
      { "name": "Ebony may plums", "code": "50305825" },
      { "name": "Ebony plums", "code": "50305826" },
      { "name": "Elephant heart plums", "code": "50305827" },
      { "name": "Emerald beaut plums", "code": "50305828" },
      { "name": "Empress plums", "code": "50305829" },
      { "name": "Freedom plums", "code": "50305830" },
      { "name": "Friar plums", "code": "50305831" },
      { "name": "Gar red plums", "code": "50305832" },
      { "name": "Governor's plum", "code": "50305833" },
      { "name": "Grand rosa plums", "code": "50305834" },
      { "name": "Green gage plum", "code": "50305835" },
      { "name": "Greengage plums", "code": "50305836" },
      { "name": "Hiromi plums", "code": "50305837" },
      { "name": "Hiromi red plums", "code": "50305838" },
      { "name": "Holiday plums", "code": "50305839" },
      { "name": "Howard sun plums", "code": "50305840" },
      { "name": "Interspecific type plums", "code": "50305841" },
      { "name": "Jamaican plum", "code": "50305842" },
      { "name": "Joanna red plums", "code": "50305843" },
      { "name": "Kelsey plums", "code": "50305844" },
      { "name": "King james plums", "code": "50305845" },
      { "name": "Laroda plums", "code": "50305846" },
      { "name": "Late rosa plums", "code": "50305847" },
      { "name": "Linda rosa plums", "code": "50305848" },
      { "name": "Lone star red plums", "code": "50305849" },
      { "name": "Mariposa plums", "code": "50305850" },
      { "name": "Marked black plums", "code": "50305851" },
      { "name": "Marked red plums", "code": "50305852" },
      { "name": "Mirabelle plum", "code": "50305853" },
      { "name": "October sun plums", "code": "50305854" },
      { "name": "Owen t plums", "code": "50305855" },
      { "name": "Perdrigon plum", "code": "50305856" },
      { "name": "Pink delight plums", "code": "50305857" },
      { "name": "President plums", "code": "50305858" },
      { "name": "Primetime plums", "code": "50305859" },
      { "name": "Purple majesty plums", "code": "50305860" },
      { "name": "Queen rosa plums", "code": "50305861" },
      { "name": "Quetsch plum", "code": "50305862" },
      { "name": "Red beaut plums", "code": "50305863" },
      { "name": "Red lane plums", "code": "50305864" },
      { "name": "Red ram plums", "code": "50305865" },
      { "name": "Red rosa plums", "code": "50305866" },
      { "name": "Rich red plums", "code": "50305867" },
      { "name": "Rosemary plums", "code": "50305868" },
      { "name": "Royal diamond plums", "code": "50305869" },
      { "name": "Royal red plums", "code": "50305870" },
      { "name": "Royal zee plums", "code": "50305871" },
      { "name": "Roysum plums", "code": "50305872" },
      { "name": "Santa rosa plums", "code": "50305873" },
      { "name": "Saphire plums", "code": "50305874" },
      { "name": "Sloe plum", "code": "50305875" },
      { "name": "St catherine plum", "code": "50305876" },
      { "name": "White bullace plum", "code": "50305877" },
      { "name": "Creole plum", "code": "50305878" },
      { "name": "Pomegranates", "code": "50305900" },
      { "name": "Foothill pommegranates", "code": "50305901" },
      { "name": "Granada pommegranates", "code": "50305902" },
      { "name": "Jolly red pommegranates", "code": "50305903" },
      { "name": "Nana pommegranates", "code": "50305904" },
      { "name": "Pat's red pommegranates", "code": "50305905" },
      { "name": "Pinkhan pommegranates", "code": "50305906" },
      { "name": "Purple velvet pommegranates", "code": "50305907" },
      { "name": "Wonderful pommegranates", "code": "50305908" },
      { "name": "Pomelos", "code": "50306000" },
      { "name": "Chandler pomelo", "code": "50306001" },
      { "name": "Hirado buntan pomelo", "code": "50306002" },
      { "name": "Liang ping yau pomelo", "code": "50306003" },
      { "name": "Pandan wangi pomelo", "code": "50306004" },
      { "name": "Pink pomelo", "code": "50306005" },
      { "name": "Red shaddock pomelo", "code": "50306006" },
      { "name": "Siamese sweet pomelo", "code": "50306007" },
      { "name": "Wainwright pomelo", "code": "50306008" },
      { "name": "Quinces", "code": "50306100" },
      { "name": "Champion quince", "code": "50306101" },
      { "name": "Pineapple quince", "code": "50306102" },
      { "name": "Smyrna quince", "code": "50306103" },
      { "name": "Raspberries", "code": "50306200" },
      { "name": "American red raspberry", "code": "50306201" },
      { "name": "Bailey queensland raspberry", "code": "50306202" },
      { "name": "Black raspberry", "code": "50306203" },
      { "name": "Dark raspberry", "code": "50306204" },
      { "name": "Delicious raspberry", "code": "50306205" },
      { "name": "Focke dwarf raspberry", "code": "50306206" },
      { "name": "Focke grayleaf red raspberry", "code": "50306207" },
      { "name": "Focke strawberry raspberry", "code": "50306208" },
      { "name": "Focke yellow himalayan raspberry", "code": "50306209" },
      { "name": "Gold raspberry", "code": "50306210" },
      { "name": "Gray new mexico raspberry", "code": "50306211" },
      { "name": "Jepson whitebark raspberry", "code": "50306212" },
      { "name": "Kellogg san diego raspberry", "code": "50306213" },
      { "name": "Leucodermis whitebark raspberry", "code": "50306214" },
      { "name": "Munz cuyamaca raspberry", "code": "50306215" },
      { "name": "Peck barton's raspberry", "code": "50306216" },
      { "name": "Purpleflowering raspberry", "code": "50306217" },
      { "name": "Roadside raspberry", "code": "50306218" },
      { "name": "San diego raspberry", "code": "50306219" },
      { "name": "Snow raspberry", "code": "50306220" },
      { "name": "Snowpeaks raspberry", "code": "50306221" },
      { "name": "Strawberryleaf raspberry", "code": "50306222" },
      { "name": "Sweet cultivated raspberry", "code": "50306223" },
      { "name": "Torr and gray whitebark raspberry", "code": "50306224" },
      { "name": "West indian raspberry", "code": "50306225" },
      { "name": "Whitebark raspberry", "code": "50306226" },
      { "name": "Wine raspberry", "code": "50306227" },
      { "name": "Yellow himalayan raspberry", "code": "50306228" },
      { "name": "Yu-shan raspberry", "code": "50306229" },
      { "name": "Rhubarb", "code": "50306300" },
      { "name": "Crimson red rhubarb", "code": "50306301" },
      { "name": "Early champagne rhubarb", "code": "50306302" },
      { "name": "Glaskin's perpetual rhubarb", "code": "50306303" },
      { "name": "Sutton rhubarb", "code": "50306304" },
      { "name": "Timperley early rhubarb", "code": "50306305" },
      { "name": "Valentine rhubarb", "code": "50306306" },
      { "name": "Victoria rhubarb", "code": "50306307" },
      { "name": "Zwolle seedling rhubarb", "code": "50306308" },
      { "name": "Macdonald rhubarb", "code": "50306309" },
      { "name": "Tilden rhubarb", "code": "50306310" },
      { "name": "Rose hips", "code": "50306400" },
      { "name": "Brier rose hips", "code": "50306401" },
      { "name": "Elgantine rose hips", "code": "50306402" },
      { "name": "Rugosa rose hips", "code": "50306403" },
      { "name": "Scotch or burnet rose hips", "code": "50306404" },
      { "name": "Sapotes", "code": "50306500" },
      { "name": "White sapotes", "code": "50306501" },
      { "name": "Black sapotes", "code": "50306502" },
      { "name": "Saskatoon berries", "code": "50306600" },
      { "name": "Honeywood saskatoon berries", "code": "50306601" },
      { "name": "Northline saskatoon berries", "code": "50306602" },
      { "name": "Smoky saskatoon berries", "code": "50306603" },
      { "name": "Thiessen saskatoon berries", "code": "50306604" },
      { "name": "Strawberries", "code": "50306700" },
      { "name": "Chandler strawberries", "code": "50306701" },
      { "name": "June bearing strawberries", "code": "50306702" },
      { "name": "Ever bearing strawberries", "code": "50306703" },
      { "name": "Sugar apple", "code": "50306800" },
      { "name": "Kampong mauve sugar apple", "code": "50306801" },
      { "name": "Seedless sugar apple", "code": "50306802" },
      { "name": "Thai lessard sugar apple", "code": "50306803" },
      { "name": "Tamarillo", "code": "50306900" },
      { "name": "Amberlea gold tamarillo", "code": "50306901" },
      { "name": "Bold gold tamarillo", "code": "50306902" },
      { "name": "Goldmine tamarillo", "code": "50306903" },
      { "name": "Oratia red tamarillo", "code": "50306904" },
      { "name": "Red beau tamarillo", "code": "50306905" },
      { "name": "Red delight tamarillo", "code": "50306906" },
      { "name": "Nominant fruits", "code": "50307000" },
      { "name": "Akee", "code": "50307001" },
      { "name": "Babaco", "code": "50307002" },
      { "name": "Banana flowers", "code": "50307003" },
      { "name": "Baobab", "code": "50307004" },
      { "name": "Bitter oranges", "code": "50307005" },
      { "name": "Canistel", "code": "50307006" },
      { "name": "Cloudberries", "code": "50307007" },
      { "name": "Coconuts", "code": "50307008" },
      { "name": "Dewberries", "code": "50307009" },
      { "name": "Durian", "code": "50307010" },
      { "name": "Elderberries", "code": "50307011" },
      { "name": "Feijoa", "code": "50307012" },
      { "name": "Hackberries", "code": "50307013" },
      { "name": "Hawthorn", "code": "50307014" },
      { "name": "Honeyberries", "code": "50307015" },
      { "name": "Jackfruit", "code": "50307016" },
      { "name": "Jambolan", "code": "50307017" },
      { "name": "Jujube", "code": "50307018" },
      { "name": "Lychee", "code": "50307019" },
      { "name": "Mangosteens", "code": "50307020" },
      { "name": "Medlars", "code": "50307021" },
      { "name": "Mombins", "code": "50307022" },
      { "name": "Monstera", "code": "50307023" },
      { "name": "Pepinos", "code": "50307024" },
      { "name": "Plantains", "code": "50307025" },
      { "name": "Prickly pears", "code": "50307026" },
      { "name": "Quenepas", "code": "50307027" },
      { "name": "Rambutan", "code": "50307028" },
      { "name": "Rose apples", "code": "50307029" },
      { "name": "Roselle", "code": "50307030" },
      { "name": "Rowanberries", "code": "50307031" },
      { "name": "Sea buckhorn berries", "code": "50307032" },
      { "name": "Silverberries", "code": "50307033" },
      { "name": "Sorb berries", "code": "50307034" },
      { "name": "Soursops", "code": "50307035" },
      { "name": "Star apples", "code": "50307036" },
      { "name": "Tamarindo", "code": "50307037" },
      { "name": "Camu camu", "code": "50307038" },
      { "name": "Lucuma", "code": "50307039" },
      { "name": "Araza", "code": "50307040" },
      { "name": "Copoazu", "code": "50307041" },
      { "name": "Poma rosa", "code": "50307042" },
      { "name": "Aguaje", "code": "50307043" },
      { "name": "Cocona", "code": "50307044" },
      { "name": "Guaba", "code": "50307045" },
      { "name": "Star fruit", "code": "50307046" },
      { "name": "Tarepiba", "code": "50307047" },
      { "name": "Casho", "code": "50307048" },
      { "name": "Taperiba", "code": "50307049" },
      { "name": "Humari", "code": "50307050" },
      { "name": "Chokeberries", "code": "50307100" },
      { "name": "Autumn magic chokeberries", "code": "50307101" },
      { "name": "Brillantisima chokeberries", "code": "50307102" },
      { "name": "Nero chokeberries", "code": "50307103" },
      { "name": "Viking chokeberries", "code": "50307104" },
      { "name": "Olives", "code": "50307200" },
      { "name": "Agrinion olives", "code": "50307201" },
      { "name": "Aleppo olives", "code": "50307202" },
      { "name": "Alphonso olives", "code": "50307203" },
      { "name": "Amphissa olives", "code": "50307204" },
      { "name": "Arauco olives", "code": "50307205" },
      { "name": "Arbequina olives", "code": "50307206" },
      { "name": "Atalanta olives", "code": "50307207" },
      { "name": "Cerignola olives", "code": "50307208" },
      { "name": "Cracked provencal olives", "code": "50307209" },
      { "name": "Empeltre olives", "code": "50307210" },
      { "name": "Gaeta olives", "code": "50307211" },
      { "name": "Hondroelia olives", "code": "50307212" },
      { "name": "Kalamata olives", "code": "50307213" },
      { "name": "Kura olives", "code": "50307214" },
      { "name": "Ligurian olives", "code": "50307215" },
      { "name": "Lucque olives", "code": "50307216" },
      { "name": "Lugano olives", "code": "50307217" },
      { "name": "Manzanilla olives", "code": "50307218" },
      { "name": "Marche olives", "code": "50307219" },
      { "name": "Mission olives", "code": "50307220" },
      { "name": "Nafplion green olives", "code": "50307221" },
      { "name": "Nicoise olives", "code": "50307222" },
      { "name": "Nyons olives", "code": "50307223" },
      { "name": "Picholine olives", "code": "50307224" },
      { "name": "Ponentine olives", "code": "50307225" },
      { "name": "Royal olives", "code": "50307226" },
      { "name": "Seracena olives", "code": "50307227" },
      { "name": "Sevillano olives", "code": "50307228" },
      { "name": "Sicilian olives", "code": "50307229" },
      { "name": "Toscanelle olives", "code": "50307230" },
      { "name": "Fresh fruit byproducts", "code": "50307500" },
      { "name": "Citrus fruit rinds", "code": "50307501" },
      {
        "name": "Water extracted soluble orange solids wesos",
        "code": "50307502"
      },
      { "name": "Pulp", "code": "50307503" },
      { "name": "Organic fresh fruits", "code": "50310000" },
      { "name": "Organic apples", "code": "50311500" },
      { "name": "Organic akane apples", "code": "50311501" },
      { "name": "Organic ambrosia apples", "code": "50311502" },
      { "name": "Organic api apples", "code": "50311503" },
      { "name": "Organic baldwin apples", "code": "50311504" },
      { "name": "Organic braeburn apples", "code": "50311505" },
      { "name": "Organic bramley apples", "code": "50311506" },
      { "name": "Organic bramley seedling apples", "code": "50311507" },
      { "name": "Organic calville blanche d'hiver apples", "code": "50311508" },
      { "name": "Organic cameo apples", "code": "50311509" },
      { "name": "Organic charles ross apples", "code": "50311510" },
      { "name": "Organic codlin apples", "code": "50311511" },
      { "name": "Organic cortland apples", "code": "50311512" },
      { "name": "Organic costard apples", "code": "50311513" },
      { "name": "Organic court pendu plat apples", "code": "50311514" },
      { "name": "Organic cox's orange pippin apples", "code": "50311515" },
      { "name": "Organic crab apples", "code": "50311516" },
      { "name": "Organic crispin apples", "code": "50311517" },
      { "name": "Organic delicious apples", "code": "50311518" },
      { "name": "Organic duchess apples", "code": "50311519" },
      { "name": "Organic earligold apples", "code": "50311520" },
      { "name": "Organic early mcintosh apples", "code": "50311521" },
      { "name": "Organic elstar apples", "code": "50311522" },
      { "name": "Organic empire apples", "code": "50311523" },
      { "name": "Organic flower of kent apples", "code": "50311524" },
      { "name": "Organic fuji apples", "code": "50311525" },
      { "name": "Organic gala apples", "code": "50311526" },
      { "name": "Organic gascoyne's scarlet apples", "code": "50311527" },
      { "name": "Organic giliflower apples", "code": "50311528" },
      { "name": "Organic ginger gold apples", "code": "50311529" },
      { "name": "Organic gladstone apples", "code": "50311530" },
      { "name": "Organic gloster apples", "code": "50311531" },
      { "name": "Organic gold supreme apples", "code": "50311532" },
      { "name": "Organic golden delicious apples", "code": "50311533" },
      { "name": "Organic golden noble apples", "code": "50311534" },
      { "name": "Organic granny smith apples", "code": "50311535" },
      { "name": "Organic gravenstein apples", "code": "50311536" },
      { "name": "Organic greening apples", "code": "50311537" },
      { "name": "Organic greensleeves apples", "code": "50311538" },
      { "name": "Organic honeycrisp apples", "code": "50311539" },
      { "name": "Organic howgate wonder apples", "code": "50311540" },
      { "name": "Organic ida red apples", "code": "50311541" },
      { "name": "Organic james grieve apples", "code": "50311542" },
      { "name": "Organic jersey mac apples", "code": "50311543" },
      { "name": "Organic jester apples", "code": "50311544" },
      { "name": "Organic jonagold apples", "code": "50311545" },
      { "name": "Organic jonamac apples", "code": "50311546" },
      { "name": "Organic jonathan apples", "code": "50311547" },
      { "name": "Organic katy apples", "code": "50311548" },
      { "name": "Organic kidd's orange red apples", "code": "50311549" },
      { "name": "Organic lady apples", "code": "50311550" },
      { "name": "Organic law rome apples", "code": "50311551" },
      { "name": "Organic laxton apples", "code": "50311552" },
      { "name": "Organic lord derby apples", "code": "50311553" },
      { "name": "Organic macoun apples", "code": "50311554" },
      { "name": "Organic mcintosh apples", "code": "50311555" },
      { "name": "Organic mutsu apples", "code": "50311556" },
      { "name": "Organic newtown pippin apples", "code": "50311557" },
      { "name": "Organic northern spy apples", "code": "50311558" },
      { "name": "Organic orleans reinette apples", "code": "50311559" },
      { "name": "Organic ozark gold apples", "code": "50311560" },
      { "name": "Organic pacific rose apples", "code": "50311561" },
      { "name": "Organic paula red apples", "code": "50311562" },
      { "name": "Organic pearmain apples", "code": "50311563" },
      { "name": "Organic pink lady apples", "code": "50311564" },
      { "name": "Organic pippin apples", "code": "50311565" },
      { "name": "Organic pitmaston pineapple apples", "code": "50311566" },
      { "name": "Organic pomme d'api apples", "code": "50311567" },
      { "name": "Organic prime gold apples", "code": "50311568" },
      { "name": "Organic red astrachan apples", "code": "50311569" },
      { "name": "Organic red boscoop apples", "code": "50311570" },
      { "name": "Organic red chief apples", "code": "50311571" },
      { "name": "Organic red delicious apples", "code": "50311572" },
      { "name": "Organic red gravenstein apples", "code": "50311573" },
      { "name": "Organic red rome apples", "code": "50311574" },
      { "name": "Organic red stayman apples", "code": "50311575" },
      { "name": "Organic red york apples", "code": "50311576" },
      { "name": "Organic reinette apples", "code": "50311577" },
      { "name": "Organic rome beauty apples", "code": "50311578" },
      { "name": "Organic russet apples", "code": "50311579" },
      { "name": "Organic sierra beauty apples", "code": "50311580" },
      { "name": "Organic spartan apples", "code": "50311581" },
      { "name": "Organic stark crimson apples", "code": "50311582" },
      { "name": "Organic starking apples", "code": "50311583" },
      { "name": "Organic stayman apples", "code": "50311584" },
      { "name": "Organic stayman winesap apples", "code": "50311585" },
      { "name": "Organic summer rambo apples", "code": "50311586" },
      { "name": "Organic tsugaru apples", "code": "50311587" },
      { "name": "Organic twenty ounce apples", "code": "50311588" },
      { "name": "Organic tydeman red apples", "code": "50311589" },
      { "name": "Organic vistabella apples", "code": "50311590" },
      { "name": "Organic wealthy apples", "code": "50311591" },
      { "name": "Organic white joaneting apples", "code": "50311592" },
      { "name": "Organic white transparent apples", "code": "50311593" },
      { "name": "Organic winesap apples", "code": "50311594" },
      { "name": "Organic worcester apples", "code": "50311595" },
      { "name": "Organic york imperial apples", "code": "50311596" },
      { "name": "Organic apricots", "code": "50311600" },
      { "name": "Organic ambercot apricots", "code": "50311601" },
      { "name": "Organic apache apricots", "code": "50311602" },
      { "name": "Organic birttany gold apricots", "code": "50311603" },
      { "name": "Organic black apricots", "code": "50311604" },
      { "name": "Organic blenheim apricots", "code": "50311605" },
      { "name": "Organic bonny apricots", "code": "50311606" },
      { "name": "Organic bulida apricots", "code": "50311607" },
      { "name": "Organic castlebrite apricots", "code": "50311608" },
      { "name": "Organic clutha gold apricots", "code": "50311609" },
      { "name": "Organic cluthasun apricots", "code": "50311610" },
      { "name": "Organic darby royal apricots", "code": "50311611" },
      { "name": "Organic dina apricots", "code": "50311612" },
      { "name": "Organic earlicot apricots", "code": "50311613" },
      { "name": "Organic earliman apricots", "code": "50311614" },
      { "name": "Organic early bright apricots", "code": "50311615" },
      { "name": "Organic flaming gold apricots", "code": "50311616" },
      { "name": "Organic fresno apricots", "code": "50311617" },
      { "name": "Organic gold brite apricots", "code": "50311618" },
      { "name": "Organic goldbar apricots", "code": "50311619" },
      { "name": "Organic golden sweet apricots", "code": "50311620" },
      { "name": "Organic goldrich apricots", "code": "50311621" },
      { "name": "Organic helena apricots", "code": "50311622" },
      { "name": "Organic honeycot apricots", "code": "50311623" },
      { "name": "Organic imperial apricots", "code": "50311624" },
      { "name": "Organic jordanne apricots", "code": "50311625" },
      { "name": "Organic jumbo cot apricots", "code": "50311626" },
      { "name": "Organic kandy kot apricots", "code": "50311627" },
      { "name": "Organic katy apricots", "code": "50311628" },
      { "name": "Organic king apricots", "code": "50311629" },
      { "name": "Organic lambertin apricots", "code": "50311630" },
      { "name": "Organic lorna apricots", "code": "50311631" },
      { "name": "Organic lulu belle apricots", "code": "50311632" },
      { "name": "Organic modesto apricots", "code": "50311633" },
      { "name": "Organic moorpark apricots", "code": "50311634" },
      { "name": "Organic orangered apricots", "code": "50311635" },
      { "name": "Organic palstein apricots", "code": "50311636" },
      { "name": "Organic patterson apricots", "code": "50311637" },
      { "name": "Organic perfection apricots", "code": "50311638" },
      { "name": "Organic poppy apricots", "code": "50311639" },
      { "name": "Organic poppycot apricots", "code": "50311640" },
      { "name": "Organic queen apricots", "code": "50311641" },
      { "name": "Organic riland apricots", "code": "50311642" },
      { "name": "Organic rival apricots", "code": "50311643" },
      { "name": "Organic robada apricots", "code": "50311644" },
      { "name": "Organic royal apricots", "code": "50311645" },
      { "name": "Organic royal blenheim apricots", "code": "50311646" },
      { "name": "Organic royal orange apricots", "code": "50311647" },
      { "name": "Organic sundrop apricots", "code": "50311648" },
      { "name": "Organic tilton apricots", "code": "50311649" },
      { "name": "Organic tomcot apricots", "code": "50311650" },
      { "name": "Organic tracy apricots", "code": "50311651" },
      { "name": "Organic tri gem apricots", "code": "50311652" },
      { "name": "Organic valley gold apricots", "code": "50311653" },
      { "name": "Organic westley apricots", "code": "50311654" },
      { "name": "Organic york apricots", "code": "50311655" },
      { "name": "Organic bananas", "code": "50311700" },
      { "name": "Organic apple bananas", "code": "50311701" },
      { "name": "Organic baby bananas", "code": "50311702" },
      { "name": "Organic burro bananas", "code": "50311703" },
      { "name": "Organic cavendish bananas", "code": "50311704" },
      { "name": "Organic dominico bananas", "code": "50311705" },
      { "name": "Organic green bananas", "code": "50311706" },
      { "name": "Organic gros michel bananas", "code": "50311707" },
      { "name": "Organic lacatan bananas", "code": "50311708" },
      { "name": "Organic lady finger banana", "code": "50311709" },
      { "name": "Organic manzano bananas", "code": "50311710" },
      { "name": "Organic mysore bananas", "code": "50311711" },
      { "name": "Organic pisang mas bananas", "code": "50311712" },
      { "name": "Organic red bananas", "code": "50311713" },
      { "name": "Organic saba bananas", "code": "50311714" },
      { "name": "Organic sucrier bananas", "code": "50311715" },
      { "name": "Organic barberries", "code": "50311800" },
      { "name": "Organic paleleaf barberries", "code": "50311801" },
      { "name": "Organic chenault barberries", "code": "50311802" },
      { "name": "Organic red barberries", "code": "50311803" },
      { "name": "Organic wintergreen barberries", "code": "50311804" },
      { "name": "Organic korean barberries", "code": "50311805" },
      { "name": "Organic mentor barberries", "code": "50311806" },
      { "name": "Organic japanese barberries", "code": "50311807" },
      { "name": "Organic atropurpurea barberries", "code": "50311808" },
      { "name": "Organic aurea barberries", "code": "50311809" },
      { "name": "Organic bagatelle barberries", "code": "50311810" },
      { "name": "Organic crimson pygmy barberries", "code": "50311811" },
      { "name": "Organic kobold barberries", "code": "50311812" },
      { "name": "Organic warty barberries", "code": "50311813" },
      { "name": "Organic european barberries", "code": "50311814" },
      { "name": "Organic bearberries", "code": "50311900" },
      { "name": "Organic alpine bearberries", "code": "50311901" },
      { "name": "Organic red bearberries", "code": "50311902" },
      { "name": "Organic common bearberries", "code": "50311903" },
      { "name": "Organic blackberries", "code": "50312000" },
      { "name": "Organic apache blackberries", "code": "50312001" },
      { "name": "Organic black satin blackberries", "code": "50312002" },
      { "name": "Organic boysenberries", "code": "50312003" },
      { "name": "Organic cherokee blackberries", "code": "50312004" },
      { "name": "Organic chester blackberries", "code": "50312005" },
      { "name": "Organic dirksen blackberries", "code": "50312006" },
      { "name": "Organic jostaberries", "code": "50312007" },
      { "name": "Organic loganberries", "code": "50312008" },
      { "name": "Organic marionberries", "code": "50312009" },
      { "name": "Organic navaho blackberries", "code": "50312010" },
      { "name": "Organic nectarberries", "code": "50312011" },
      { "name": "Organic olallie blackberries", "code": "50312012" },
      { "name": "Organic tayberries", "code": "50312013" },
      { "name": "Organic thornless hull blackberries", "code": "50312014" },
      { "name": "Organic youngberries", "code": "50312015" },
      { "name": "Organic billberries", "code": "50312100" },
      { "name": "Organic bog bilberries", "code": "50312101" },
      { "name": "Organic dwarf bilberries", "code": "50312102" },
      { "name": "Organic mountain bilberries", "code": "50312103" },
      { "name": "Organic oval-leaved bilberries", "code": "50312104" },
      { "name": "Organic blueberries", "code": "50312200" },
      { "name": "Organic bluetta blueberries", "code": "50312201" },
      { "name": "Organic duke blueberries", "code": "50312202" },
      { "name": "Organic spartan blueberries", "code": "50312203" },
      { "name": "Organic patriot blueberries", "code": "50312204" },
      { "name": "Organic toro blueberries", "code": "50312205" },
      { "name": "Organic hardyblue blueberries", "code": "50312206" },
      { "name": "Organic bluecrop blueberries", "code": "50312207" },
      { "name": "Organic legacy blueberries", "code": "50312208" },
      { "name": "Organic nelson blueberries", "code": "50312209" },
      { "name": "Organic chandler blueberries", "code": "50312210" },
      { "name": "Organic brigitta blueberries", "code": "50312211" },
      { "name": "Organic northcountry blueberries", "code": "50312212" },
      { "name": "Organic northsky blueberries", "code": "50312213" },
      { "name": "Organic northblue blueberries", "code": "50312214" },
      { "name": "Organic misty blueberries", "code": "50312215" },
      { "name": "Organic breadfruit", "code": "50312300" },
      { "name": "Organic chataigne breadfruit", "code": "50312301" },
      { "name": "Organic seedless breadfruit", "code": "50312302" },
      { "name": "Organic white heart breadfruit", "code": "50312303" },
      { "name": "Organic yellow heart breadfruit", "code": "50312304" },
      { "name": "Organic cherimoyas", "code": "50312400" },
      { "name": "Organic bays cherimoya", "code": "50312401" },
      { "name": "Organic bronceada cherimoya", "code": "50312402" },
      { "name": "Organic burtons cherimoya", "code": "50312403" },
      { "name": "Organic burtons favorite cherimoya", "code": "50312404" },
      { "name": "Organic jete cherimoya", "code": "50312405" },
      { "name": "Organic reretai cherimoya", "code": "50312406" },
      { "name": "Organic smoothey cherimoya", "code": "50312407" },
      { "name": "Organic spain cherimoya", "code": "50312408" },
      { "name": "Organic white cherimoya", "code": "50312409" },
      { "name": "Organic cherries", "code": "50312500" },
      { "name": "Organic amarelle cherries", "code": "50312501" },
      { "name": "Organic brooks cherries", "code": "50312502" },
      { "name": "Organic bigarreu cherries", "code": "50312503" },
      { "name": "Organic bing cherries", "code": "50312504" },
      { "name": "Organic black republic cherries", "code": "50312505" },
      { "name": "Organic black schmidt cherries", "code": "50312506" },
      { "name": "Organic black tartarian cherries", "code": "50312507" },
      { "name": "Organic fiesta bing cherries", "code": "50312508" },
      { "name": "Organic garnet cherries", "code": "50312509" },
      { "name": "Organic king cherries", "code": "50312510" },
      { "name": "Organic chapman cherries", "code": "50312511" },
      { "name": "Organic lapin cherries", "code": "50312512" },
      { "name": "Organic larian cherries", "code": "50312513" },
      { "name": "Organic dark guines cherries", "code": "50312514" },
      { "name": "Organic montmorency cherries", "code": "50312515" },
      { "name": "Organic duke cherries", "code": "50312516" },
      { "name": "Organic early rivers cherries", "code": "50312517" },
      { "name": "Organic ruby bing cherries", "code": "50312518" },
      { "name": "Organic santina cherries", "code": "50312519" },
      { "name": "Organic geans/guines cherries", "code": "50312520" },
      { "name": "Organic sonata cherries", "code": "50312521" },
      { "name": "Organic lambert cherries", "code": "50312522" },
      { "name": "Organic stella cherries", "code": "50312523" },
      { "name": "Organic sweetheart cherries", "code": "50312524" },
      { "name": "Organic tartarian cherries", "code": "50312525" },
      { "name": "Organic maraschino cherries", "code": "50312527" },
      { "name": "Organic van cherries", "code": "50312528" },
      { "name": "Organic morello cherries", "code": "50312529" },
      { "name": "Organic royal ann cherries", "code": "50312530" },
      { "name": "Organic ranier cherries", "code": "50312531" },
      { "name": "Organic royal cherries", "code": "50312532" },
      { "name": "Organic citrons", "code": "50312600" },
      { "name": "Organic buddha's hand citrons", "code": "50312601" },
      { "name": "Organic fingered citrons", "code": "50312602" },
      { "name": "Organic fo shoukan citrons", "code": "50312603" },
      { "name": "Organic bushakan citrons", "code": "50312604" },
      { "name": "Organic diamante citrons", "code": "50312605" },
      { "name": "Organic etrog citrons", "code": "50312606" },
      { "name": "Organic ponderosa citrons", "code": "50312607" },
      { "name": "Organic cranberries", "code": "50312700" },
      { "name": "Organic ben lear cranberries", "code": "50312701" },
      { "name": "Organic early black cranberries", "code": "50312702" },
      { "name": "Organic grycleski cranberries", "code": "50312703" },
      { "name": "Organic howe cranberries", "code": "50312704" },
      { "name": "Organic lingonberries", "code": "50312705" },
      { "name": "Organic mcfarlin cranberries", "code": "50312706" },
      { "name": "Organic mountain cranberries", "code": "50312707" },
      { "name": "Organic pilgrim cranberries", "code": "50312708" },
      { "name": "Organic searless cranberries", "code": "50312709" },
      { "name": "Organic stevens cranberries", "code": "50312710" },
      { "name": "Organic currants", "code": "50312800" },
      { "name": "Organic hudson bay currants", "code": "50312801" },
      { "name": "Organic waxy currants", "code": "50312802" },
      { "name": "Organic desert currants", "code": "50312803" },
      { "name": "Organic black currants", "code": "50312804" },
      { "name": "Organic red currants", "code": "50312805" },
      { "name": "Organic white currants", "code": "50312806" },
      { "name": "Organic dates", "code": "50312900" },
      { "name": "Organic asharasi dates", "code": "50312901" },
      { "name": "Organic barhi or barhee dates", "code": "50312902" },
      { "name": "Organic deglet noor dates", "code": "50312903" },
      { "name": "Organic fardh dates", "code": "50312904" },
      { "name": "Organic gundila dates", "code": "50312905" },
      { "name": "Organic halawi/halawy dates", "code": "50312906" },
      { "name": "Organic hilali dates", "code": "50312907" },
      { "name": "Organic khadrawi/khadrawy dates", "code": "50312908" },
      { "name": "Organic khalas dates", "code": "50312909" },
      { "name": "Organic khustawi dates", "code": "50312910" },
      { "name": "Organic khidri dates", "code": "50312911" },
      { "name": "Organic medjool/medjul dates", "code": "50312912" },
      { "name": "Organic mactoum dates", "code": "50312913" },
      { "name": "Organic neghal dates", "code": "50312914" },
      { "name": "Organic yatimeh dates", "code": "50312915" },
      { "name": "Organic zahidi dates", "code": "50312916" },
      { "name": "Organic dragonfruit", "code": "50313000" },
      { "name": "Organic pink dragonfruit", "code": "50313001" },
      { "name": "Organic yellow dragonfruit", "code": "50313002" },
      { "name": "Organic figs", "code": "50313100" },
      { "name": "Organic bardajic figs", "code": "50313101" },
      { "name": "Organic brown turkey figs", "code": "50313102" },
      { "name": "Organic calimyrna figs", "code": "50313103" },
      { "name": "Organic conadria figs", "code": "50313104" },
      { "name": "Organic dottado figs", "code": "50313105" },
      { "name": "Organic kadota figs", "code": "50313106" },
      { "name": "Organic mediterranean figs", "code": "50313107" },
      { "name": "Organic mission figs", "code": "50313108" },
      { "name": "Organic smyrna figs", "code": "50313109" },
      { "name": "Organic verdona figs", "code": "50313110" },
      { "name": "Organic white king figs", "code": "50313111" },
      { "name": "Organic gooseberries", "code": "50313200" },
      { "name": "Organic early sulphur gooseberries", "code": "50313201" },
      { "name": "Organic goldendrop gooseberries", "code": "50313202" },
      { "name": "Organic langley gage gooseberries", "code": "50313203" },
      { "name": "Organic leveller gooseberries", "code": "50313204" },
      { "name": "Organic london gooseberries", "code": "50313205" },
      { "name": "Organic worcestershire gooseberries", "code": "50313206" },
      {
        "name": "Organic american worcesterberry gooseberries",
        "code": "50313207"
      },
      { "name": "Organic grapefruit", "code": "50313300" },
      { "name": "Organic burgundy grapefruit", "code": "50313301" },
      { "name": "Organic duncan grapefruit", "code": "50313302" },
      { "name": "Organic foster grapefruit", "code": "50313303" },
      { "name": "Organic marsh grapefruit", "code": "50313304" },
      { "name": "Organic new zealand grapefruit", "code": "50313305" },
      { "name": "Organic rio red grapefruit", "code": "50313306" },
      { "name": "Organic ruby red grapefruit", "code": "50313307" },
      { "name": "Organic star ruby grapefruit", "code": "50313308" },
      { "name": "Organic triumph grapefruit", "code": "50313309" },
      { "name": "Organic table grapes", "code": "50313400" },
      { "name": "Organic alicante grapes", "code": "50313401" },
      { "name": "Organic almeria grapes", "code": "50313402" },
      { "name": "Organic alphonse lavalle grapes", "code": "50313403" },
      { "name": "Organic autumn king grapes", "code": "50313404" },
      { "name": "Organic autumn royal grapes", "code": "50313405" },
      { "name": "Organic autumn seedless grapes", "code": "50313406" },
      { "name": "Organic baresana grapes", "code": "50313407" },
      { "name": "Organic barlinka grapes", "code": "50313408" },
      { "name": "Organic beauty seedless grapes", "code": "50313409" },
      { "name": "Organic black beauty seedless grapes", "code": "50313410" },
      { "name": "Organic black emerald grapes", "code": "50313411" },
      { "name": "Organic black giant grapes", "code": "50313412" },
      { "name": "Organic black globe grapes", "code": "50313413" },
      { "name": "Organic black monukka grapes", "code": "50313414" },
      { "name": "Organic black pearl grapes", "code": "50313415" },
      { "name": "Organic black seedless grapes", "code": "50313416" },
      { "name": "Organic bonheur grapes", "code": "50313417" },
      { "name": "Organic calmeria grapes", "code": "50313418" },
      { "name": "Organic cardinal grapes", "code": "50313419" },
      { "name": "Organic catawba grapes", "code": "50313420" },
      {
        "name": "Organic chasselas/golden chasselas grapes",
        "code": "50313421"
      },
      { "name": "Organic christmas rose grapes", "code": "50313422" },
      { "name": "Organic concord grapes", "code": "50313423" },
      { "name": "Organic concord seedless grapes", "code": "50313424" },
      { "name": "Organic crimson seedless grapes", "code": "50313425" },
      { "name": "Organic dauphine grapes", "code": "50313426" },
      { "name": "Organic delaware grapes", "code": "50313427" },
      { "name": "Organic early muscat grapes", "code": "50313428" },
      { "name": "Organic early sweet grapes", "code": "50313429" },
      { "name": "Organic emerald seedless grapes", "code": "50313430" },
      { "name": "Organic emperatriz grapes", "code": "50313431" },
      { "name": "Organic emperor grapes", "code": "50313432" },
      { "name": "Organic empress grapes", "code": "50313433" },
      { "name": "Organic exotic grapes", "code": "50313434" },
      { "name": "Organic fantasy grapes", "code": "50313435" },
      { "name": "Organic fantasy seedless grapes", "code": "50313436" },
      { "name": "Organic flame grapes", "code": "50313437" },
      { "name": "Organic flame seedless grapes", "code": "50313438" },
      { "name": "Organic flame tokay grapes", "code": "50313439" },
      { "name": "Organic flaming red grapes", "code": "50313440" },
      { "name": "Organic galaxy seedless grapes", "code": "50313441" },
      { "name": "Organic gamay grapes", "code": "50313442" },
      { "name": "Organic gold grapes", "code": "50313443" },
      { "name": "Organic hanepoot or honeypot grapes", "code": "50313444" },
      { "name": "Organic italia grapes", "code": "50313445" },
      { "name": "Organic jade seedless grapes", "code": "50313446" },
      { "name": "Organic jubilee grapes", "code": "50313447" },
      { "name": "Organic king ruby grapes", "code": "50313448" },
      { "name": "Organic kyoho grapes", "code": "50313449" },
      { "name": "Organic la rochelle grapes", "code": "50313450" },
      { "name": "Organic lady finger grapes", "code": "50313451" },
      { "name": "Organic late seedless grapes", "code": "50313452" },
      { "name": "Organic majestic seedless grapes", "code": "50313453" },
      { "name": "Organic malaga grapes", "code": "50313454" },
      { "name": "Organic marroo seedless grapes", "code": "50313455" },
      { "name": "Organic muscadine grapes", "code": "50313456" },
      { "name": "Organic muscat flame grapes", "code": "50313457" },
      { "name": "Organic muscat grapes", "code": "50313458" },
      { "name": "Organic muscat seedless grapes", "code": "50313459" },
      { "name": "Organic napoleon grapes", "code": "50313460" },
      { "name": "Organic negria grapes", "code": "50313461" },
      { "name": "Organic new cross grapes", "code": "50313462" },
      { "name": "Organic niabell grapes", "code": "50313463" },
      { "name": "Organic niagara grapes", "code": "50313464" },
      { "name": "Organic olivette grapes", "code": "50313465" },
      { "name": "Organic perlette grapes", "code": "50313466" },
      { "name": "Organic perlon grapes", "code": "50313467" },
      { "name": "Organic prima black seedless grapes", "code": "50313468" },
      { "name": "Organic princess grapes", "code": "50313469" },
      { "name": "Organic queen grapes", "code": "50313470" },
      { "name": "Organic red blush grapes", "code": "50313471" },
      { "name": "Organic red globe grapes", "code": "50313472" },
      { "name": "Organic red malaga grapes", "code": "50313473" },
      { "name": "Organic red seedless grapes", "code": "50313474" },
      { "name": "Organic regina grapes", "code": "50313475" },
      { "name": "Organic ribier grapes", "code": "50313476" },
      { "name": "Organic rosita grapes", "code": "50313477" },
      { "name": "Organic rouge grapes", "code": "50313478" },
      { "name": "Organic royal black seedless grapes", "code": "50313479" },
      { "name": "Organic ruby red seedless grapes", "code": "50313480" },
      { "name": "Organic ruby seedless grapes", "code": "50313481" },
      { "name": "Organic scarlet royal grapes", "code": "50313482" },
      { "name": "Organic scuppernong grapes", "code": "50313483" },
      { "name": "Organic sugarose grapes", "code": "50313484" },
      { "name": "Organic sugarthirteen grapes", "code": "50313485" },
      { "name": "Organic sugraone grapes", "code": "50313486" },
      { "name": "Organic sugrasixteen grapes", "code": "50313487" },
      { "name": "Organic sultana sun red grapes", "code": "50313488" },
      { "name": "Organic summer royal grapes", "code": "50313489" },
      { "name": "Organic sunset grapes", "code": "50313490" },
      { "name": "Organic superior seedless grapes", "code": "50313491" },
      { "name": "Organic thompson seedless grapes", "code": "50313492" },
      { "name": "Organic tokay/pinot gris grapes", "code": "50313493" },
      { "name": "Organic waltman cross grapes", "code": "50313494" },
      { "name": "Organic white seedless grapes", "code": "50313495" },
      { "name": "Organic zante current grapes", "code": "50313496" },
      { "name": "Organic raisin grapes", "code": "50313500" },
      { "name": "Organic black corinth grapes", "code": "50313501" },
      { "name": "Organic canner grapes", "code": "50313502" },
      { "name": "Organic dovine grapes", "code": "50313503" },
      { "name": "Organic fiesta grapes", "code": "50313504" },
      { "name": "Organic selma pete grapes", "code": "50313505" },
      { "name": "Organic sultana grapes", "code": "50313506" },
      { "name": "Organic wine grapes", "code": "50313600" },
      { "name": "Organic alicante bouschet grapes", "code": "50313601" },
      { "name": "Organic barbera grapes", "code": "50313602" },
      { "name": "Organic burger grapes", "code": "50313603" },
      { "name": "Organic cabernet franc grapes", "code": "50313604" },
      { "name": "Organic cabernet sauvignon grapes", "code": "50313605" },
      { "name": "Organic carignane grapes", "code": "50313606" },
      { "name": "Organic carnelian grapes", "code": "50313607" },
      { "name": "Organic catarratto grapes", "code": "50313608" },
      { "name": "Organic centurian grapes", "code": "50313609" },
      { "name": "Organic charbono grapes", "code": "50313610" },
      { "name": "Organic chardonnay grapes", "code": "50313611" },
      { "name": "Organic chenin blanc grapes", "code": "50313612" },
      { "name": "Organic cinsaut grapes", "code": "50313613" },
      { "name": "Organic dolcetto grapes", "code": "50313614" },
      { "name": "Organic emerald riesling grapes", "code": "50313615" },
      { "name": "Organic french colombard grapes", "code": "50313616" },
      { "name": "Organic gamay or napa grapes", "code": "50313617" },
      { "name": "Organic gamay beaujolais grapes", "code": "50313618" },
      { "name": "Organic gewurztraminer grapes", "code": "50313619" },
      { "name": "Organic grenache grapes", "code": "50313620" },
      { "name": "Organic grenache blanc grapes", "code": "50313621" },
      { "name": "Organic lagrein grapes", "code": "50313622" },
      { "name": "Organic lambrusco grapes", "code": "50313623" },
      { "name": "Organic malbec grapes", "code": "50313624" },
      { "name": "Organic malvasia bianca grapes", "code": "50313625" },
      { "name": "Organic marsanne grapes", "code": "50313626" },
      { "name": "Organic mataro grapes", "code": "50313627" },
      { "name": "Organic merlot grapes", "code": "50313628" },
      { "name": "Organic meunier grapes", "code": "50313629" },
      { "name": "Organic mission grapes", "code": "50313630" },
      { "name": "Organic montepulciano grapes", "code": "50313631" },
      { "name": "Organic muscat blanc grapes", "code": "50313632" },
      { "name": "Organic muscat hamburg grapes", "code": "50313633" },
      { "name": "Organic muscat of alexandria grapes", "code": "50313634" },
      { "name": "Organic muscat orange grapes", "code": "50313635" },
      { "name": "Organic nebbiolo grapes", "code": "50313636" },
      { "name": "Organic palomino grapes", "code": "50313637" },
      { "name": "Organic petit verdot grapes", "code": "50313638" },
      { "name": "Organic petite sirah grapes", "code": "50313639" },
      { "name": "Organic pinot blanc grapes", "code": "50313640" },
      { "name": "Organic pinot gris grapes", "code": "50313641" },
      { "name": "Organic pinot noir grapes", "code": "50313642" },
      { "name": "Organic primitivo grapes", "code": "50313643" },
      { "name": "Organic roussanne grapes", "code": "50313644" },
      { "name": "Organic royalty grapes", "code": "50313645" },
      { "name": "Organic rubired grapes", "code": "50313646" },
      { "name": "Organic ruby cabernet grapes", "code": "50313647" },
      { "name": "Organic salvador grapes", "code": "50313648" },
      { "name": "Organic sangiovese grapes", "code": "50313649" },
      { "name": "Organic sauvignon blanc grapes", "code": "50313650" },
      { "name": "Organic sauvignon musque grapes", "code": "50313651" },
      { "name": "Organic semillon grapes", "code": "50313652" },
      { "name": "Organic souzao grapes", "code": "50313653" },
      { "name": "Organic st emilion grapes", "code": "50313654" },
      { "name": "Organic symphony grapes", "code": "50313655" },
      { "name": "Organic syrah grapes", "code": "50313656" },
      { "name": "Organic tannat grapes", "code": "50313657" },
      { "name": "Organic tempranillo grapes", "code": "50313658" },
      { "name": "Organic teroldego grapes", "code": "50313659" },
      { "name": "Organic tocai friulano grapes", "code": "50313660" },
      { "name": "Organic touriga nacional grapes", "code": "50313661" },
      { "name": "Organic triplett blanc grapes", "code": "50313662" },
      { "name": "Organic viognier grapes", "code": "50313663" },
      { "name": "Organic white riesling grapes", "code": "50313664" },
      { "name": "Organic zinfandel grapes", "code": "50313665" },
      { "name": "Organic guavas", "code": "50313700" },
      { "name": "Organic beaumont guavas", "code": "50313701" },
      { "name": "Organic carrley guavas", "code": "50313702" },
      { "name": "Organic lucida guavas", "code": "50313703" },
      { "name": "Organic pineapple guava", "code": "50313704" },
      { "name": "Organic huckleberries", "code": "50313800" },
      { "name": "Organic black winter huckleberries", "code": "50313801" },
      { "name": "Organic cascade huckleberries", "code": "50313802" },
      { "name": "Organic dwarf huckleberries", "code": "50313803" },
      { "name": "Organic mountain huckleberries", "code": "50313804" },
      { "name": "Organic red huckleberries", "code": "50313805" },
      { "name": "Organic kiwi fruit", "code": "50313900" },
      { "name": "Organic ananasnaja kiwi fruit", "code": "50313901" },
      { "name": "Organic arctic beauty kiwi fruit", "code": "50313902" },
      { "name": "Organic blake kiwi fruit", "code": "50313903" },
      { "name": "Organic hayward kiwi fruit", "code": "50313904" },
      { "name": "Organic issai kiwi fruit", "code": "50313905" },
      { "name": "Organic siberian kiwi fruit", "code": "50313906" },
      { "name": "Organic kumquats", "code": "50314000" },
      { "name": "Organic hong kong kumquats", "code": "50314001" },
      { "name": "Organic limequat kumquats", "code": "50314002" },
      { "name": "Organic long fruit kumquats", "code": "50314003" },
      { "name": "Organic malayan kumquats", "code": "50314004" },
      { "name": "Organic meiwa kumquats", "code": "50314005" },
      { "name": "Organic nagami kumquats", "code": "50314006" },
      { "name": "Organic lemons", "code": "50314100" },
      { "name": "Organic baboon lemons", "code": "50314101" },
      { "name": "Organic bearss sicilian lemons", "code": "50314102" },
      { "name": "Organic cameron highlands lemons", "code": "50314103" },
      { "name": "Organic escondido lemons", "code": "50314104" },
      { "name": "Organic eureka lemons", "code": "50314105" },
      { "name": "Organic lisbon lemons", "code": "50314106" },
      { "name": "Organic meyer lemons", "code": "50314107" },
      { "name": "Organic volkamer lemons", "code": "50314108" },
      { "name": "Organic limes", "code": "50314200" },
      { "name": "Organic indian sweet limes", "code": "50314201" },
      { "name": "Organic key limes", "code": "50314202" },
      { "name": "Organic mandarin limes", "code": "50314203" },
      { "name": "Organic philippine limes", "code": "50314204" },
      { "name": "Organic tahitian limes", "code": "50314205" },
      { "name": "Organic bearss limes", "code": "50314206" },
      { "name": "Organic persian limes", "code": "50314207" },
      { "name": "Organic seedless limes", "code": "50314208" },
      { "name": "Organic loquats", "code": "50314300" },
      { "name": "Organic advance loquats", "code": "50314301" },
      { "name": "Organic benlehr loquats", "code": "50314302" },
      { "name": "Organic big jim loquats", "code": "50314303" },
      { "name": "Organic champagne loquats", "code": "50314304" },
      { "name": "Organic early red loquats", "code": "50314305" },
      { "name": "Organic gold nugget loquats", "code": "50314306" },
      { "name": "Organic herd's mammoth loquats", "code": "50314307" },
      { "name": "Organic mogi loquats", "code": "50314308" },
      { "name": "Organic mrs cooksey loquats", "code": "50314309" },
      { "name": "Organic strawberry loquats", "code": "50314310" },
      { "name": "Organic tanaka loquats", "code": "50314311" },
      { "name": "Organic victory vista white loquats", "code": "50314312" },
      { "name": "Organic wolfe loquats", "code": "50314313" },
      {
        "name": "Organic mandarin oranges or organic tangerines",
        "code": "50314400"
      },
      { "name": "Organic clauselinas oranges", "code": "50314401" },
      { "name": "Organic clementine tangerines", "code": "50314402" },
      { "name": "Organic cleopatra mandarin oranges", "code": "50314403" },
      { "name": "Organic dancy tangerines", "code": "50314404" },
      { "name": "Organic ellensdale oranges", "code": "50314405" },
      { "name": "Organic fairchild oranges", "code": "50314406" },
      { "name": "Organic fallglo oranges", "code": "50314407" },
      { "name": "Organic fortune oranges", "code": "50314408" },
      { "name": "Organic fremont mandarin oranges", "code": "50314409" },
      { "name": "Organic fremont oranges", "code": "50314410" },
      { "name": "Organic golden nugget oranges", "code": "50314411" },
      { "name": "Organic honey mandarin oranges", "code": "50314412" },
      { "name": "Organic honey oranges", "code": "50314413" },
      { "name": "Organic honey tangerines", "code": "50314414" },
      { "name": "Organic honeybelle tangelo oranges", "code": "50314415" },
      { "name": "Organic king mandarin oranges", "code": "50314416" },
      { "name": "Organic kinnow oranges", "code": "50314417" },
      { "name": "Organic lee mandarin oranges", "code": "50314418" },
      { "name": "Organic makokkee oranges", "code": "50314419" },
      { "name": "Organic malvasios oranges", "code": "50314420" },
      { "name": "Organic mediterranean mandarin oranges", "code": "50314421" },
      { "name": "Organic minneola tangelo oranges", "code": "50314422" },
      { "name": "Organic monica oranges", "code": "50314423" },
      { "name": "Organic murcott honey oranges", "code": "50314424" },
      { "name": "Organic murcott tangors", "code": "50314425" },
      { "name": "Organic natsudaidai mandarin oranges", "code": "50314426" },
      { "name": "Organic natsumikan mandarin oranges", "code": "50314427" },
      { "name": "Organic nocatee tangelo oranges", "code": "50314428" },
      { "name": "Organic orlando tangelo oranges", "code": "50314429" },
      { "name": "Organic ortanique tangerines", "code": "50314430" },
      { "name": "Organic page mandarin oranges", "code": "50314431" },
      { "name": "Organic pixie oranges", "code": "50314432" },
      {
        "name": "Organic ponkan bantangas mandarin oranges",
        "code": "50314433"
      },
      { "name": "Organic reyna oranges", "code": "50314434" },
      { "name": "Organic robinson oranges", "code": "50314435" },
      { "name": "Organic saltenitas oranges", "code": "50314436" },
      { "name": "Organic sampson tangelo oranges", "code": "50314437" },
      { "name": "Organic satsuma mandarin oranges", "code": "50314438" },
      { "name": "Organic sunburst mandarin oranges", "code": "50314439" },
      { "name": "Organic tangelos", "code": "50314440" },
      { "name": "Organic tangerina oranges", "code": "50314441" },
      { "name": "Organic temple oranges", "code": "50314442" },
      { "name": "Organic thornton oranges", "code": "50314443" },
      { "name": "Organic wekiwa tangerines", "code": "50314444" },
      { "name": "Organic wilkins tangerines", "code": "50314445" },
      {
        "name": "Organic willowleaf mediterranean tangerines",
        "code": "50314446"
      },
      { "name": "Organic mangoes", "code": "50314500" },
      { "name": "Organic alphonso mangoes", "code": "50314501" },
      { "name": "Organic ataulfo mangoes", "code": "50314502" },
      { "name": "Organic criollo mangoes", "code": "50314503" },
      { "name": "Organic edwards mangoes", "code": "50314504" },
      { "name": "Organic francine mangoes", "code": "50314505" },
      { "name": "Organic francis mangoes", "code": "50314506" },
      { "name": "Organic gandaria mangoes", "code": "50314507" },
      { "name": "Organic haden mangoes", "code": "50314508" },
      { "name": "Organic irwin mangoes", "code": "50314509" },
      { "name": "Organic keitt mangoes", "code": "50314510" },
      { "name": "Organic kent mangoes", "code": "50314511" },
      { "name": "Organic kesar mangoes", "code": "50314512" },
      { "name": "Organic kuini mangoes", "code": "50314513" },
      { "name": "Organic manila super mangoes", "code": "50314514" },
      { "name": "Organic manila mangoes", "code": "50314515" },
      { "name": "Organic mayaguez mangoes", "code": "50314516" },
      { "name": "Organic mulgoba mangoes", "code": "50314517" },
      { "name": "Organic oro mangoes", "code": "50314518" },
      { "name": "Organic palmer mangoes", "code": "50314519" },
      { "name": "Organic parvin mangoes", "code": "50314520" },
      { "name": "Organic sandersha mangoes", "code": "50314521" },
      { "name": "Organic sensation mangoes", "code": "50314522" },
      { "name": "Organic smith mangoes", "code": "50314523" },
      { "name": "Organic tommy atkins mangoes", "code": "50314524" },
      { "name": "Organic van dyke mangoes", "code": "50314525" },
      { "name": "Organic melons", "code": "50314600" },
      { "name": "Organic allsweet melons", "code": "50314601" },
      { "name": "Organic athena melons", "code": "50314602" },
      { "name": "Organic black diamond melons", "code": "50314603" },
      { "name": "Organic cal sweet melons", "code": "50314604" },
      { "name": "Organic cantaloupe melons", "code": "50314605" },
      { "name": "Organic carnical melons", "code": "50314606" },
      { "name": "Organic casaba melons", "code": "50314607" },
      { "name": "Organic cavaillon melons", "code": "50314608" },
      { "name": "Organic charentais melons", "code": "50314609" },
      { "name": "Organic charleston gray watermelon", "code": "50314610" },
      { "name": "Organic crenshaw melons", "code": "50314611" },
      { "name": "Organic crimson sweet melons", "code": "50314612" },
      { "name": "Organic dixie lee melons", "code": "50314613" },
      { "name": "Organic eclipse melons", "code": "50314614" },
      { "name": "Organic ein d'or melons", "code": "50314615" },
      { "name": "Organic fiesta melons", "code": "50314616" },
      { "name": "Organic galia melons", "code": "50314617" },
      { "name": "Organic gaya melons", "code": "50314618" },
      { "name": "Organic hami melons", "code": "50314619" },
      { "name": "Organic honeydew melons", "code": "50314620" },
      { "name": "Organic icebox melons", "code": "50314621" },
      { "name": "Organic ida pride melons", "code": "50314622" },
      { "name": "Organic juan canary melons", "code": "50314623" },
      { "name": "Organic jubilee melons", "code": "50314624" },
      { "name": "Organic jubilation melons", "code": "50314625" },
      { "name": "Organic kakhi/kakri melons", "code": "50314626" },
      { "name": "Organic kiwano melons", "code": "50314627" },
      { "name": "Organic korean melons", "code": "50314628" },
      { "name": "Organic long gray melons", "code": "50314629" },
      { "name": "Organic mayan melons", "code": "50314630" },
      { "name": "Organic micky lee melons", "code": "50314631" },
      { "name": "Organic mirage melons", "code": "50314632" },
      { "name": "Organic moon and stars watermelon", "code": "50314633" },
      { "name": "Organic ogen melons", "code": "50314634" },
      { "name": "Organic patriot melons", "code": "50314635" },
      { "name": "Organic peacock melons", "code": "50314636" },
      { "name": "Organic pepino melons", "code": "50314637" },
      { "name": "Organic persian melons", "code": "50314638" },
      { "name": "Organic picnic melons", "code": "50314639" },
      { "name": "Organic piel de sapo melons", "code": "50314640" },
      { "name": "Organic pineapple melons", "code": "50314641" },
      { "name": "Organic quetzali melons", "code": "50314642" },
      { "name": "Organic red goblin melons", "code": "50314643" },
      { "name": "Organic regency melons", "code": "50314644" },
      { "name": "Organic royal majestic melons", "code": "50314645" },
      { "name": "Organic royal star melons", "code": "50314646" },
      { "name": "Organic royal sweet melons", "code": "50314647" },
      { "name": "Organic santa claus melons", "code": "50314648" },
      { "name": "Organic sharlyn melons", "code": "50314649" },
      { "name": "Organic spanish melons", "code": "50314650" },
      { "name": "Organic sprite melons", "code": "50314651" },
      { "name": "Organic starbright melons", "code": "50314652" },
      { "name": "Organic stars n stripes melons", "code": "50314653" },
      { "name": "Organic sugar baby melons", "code": "50314654" },
      { "name": "Organic sugar baby watermelon", "code": "50314655" },
      { "name": "Organic sunsweet melons", "code": "50314656" },
      { "name": "Organic sweet heart seedless watermelon", "code": "50314657" },
      { "name": "Organic temptation melons", "code": "50314658" },
      { "name": "Organic tiger baby melons", "code": "50314659" },
      { "name": "Organic tuscan type melons", "code": "50314660" },
      { "name": "Organic yellow baby watermelon", "code": "50314661" },
      { "name": "Organic mulberries", "code": "50314700" },
      { "name": "Organic black mulberries", "code": "50314701" },
      { "name": "Organic white mulberries", "code": "50314702" },
      { "name": "Organic bayberries or myrtles", "code": "50314800" },
      { "name": "organic bog myrtle", "code": "50314801" },
      { "name": "Organic bayberries", "code": "50314802" },
      { "name": "Organic nectarines", "code": "50314900" },
      { "name": "Organic april glo nectarines", "code": "50314901" },
      { "name": "Organic arctic mist nectarines", "code": "50314902" },
      { "name": "Organic arctic snow nectarines", "code": "50314903" },
      { "name": "Organic arctic star nectarines", "code": "50314904" },
      { "name": "Organic arctic sweet nectarines", "code": "50314905" },
      { "name": "Organic arctic glo nectarines", "code": "50314906" },
      { "name": "Organic august fire nectarines", "code": "50314907" },
      { "name": "Organic august pearl nectarines", "code": "50314908" },
      { "name": "Organic august red nectarines", "code": "50314909" },
      { "name": "Organic autumn star nectarines", "code": "50314910" },
      { "name": "Organic big john nectarines", "code": "50314911" },
      { "name": "Organic bright pearl nectarines", "code": "50314912" },
      { "name": "Organic diamond bright nectarines", "code": "50314913" },
      { "name": "Organic diamond ray nectarines", "code": "50314914" },
      { "name": "Organic earliglo nectarines", "code": "50314915" },
      { "name": "Organic early diamond nectarines", "code": "50314916" },
      { "name": "Organic fairlane nectarines", "code": "50314917" },
      { "name": "Organic fantasia nectarines", "code": "50314918" },
      { "name": "Organic fire pearl nectarines", "code": "50314919" },
      { "name": "Organic fire sweet nectarines", "code": "50314920" },
      { "name": "Organic flamekist nectarines", "code": "50314921" },
      { "name": "Organic flat type nectarines", "code": "50314922" },
      { "name": "Organic garden delight nectarines", "code": "50314923" },
      { "name": "Organic goldmine nectarines", "code": "50314924" },
      { "name": "Organic grand pearl nectarines", "code": "50314925" },
      { "name": "Organic hardired nectarines", "code": "50314926" },
      { "name": "Organic honey blaze nectarines", "code": "50314927" },
      { "name": "Organic july red nectarines", "code": "50314928" },
      { "name": "Organic kay pearl nectarines", "code": "50314929" },
      { "name": "Organic kay sweet nectarines", "code": "50314930" },
      { "name": "Organic may diamond nectarines", "code": "50314931" },
      { "name": "Organic mayfire nectarines", "code": "50314932" },
      { "name": "Organic mayglo nectarines", "code": "50314933" },
      { "name": "Organic mericrest nectarines", "code": "50314934" },
      { "name": "Organic red diamond nectarines", "code": "50314935" },
      { "name": "Organic red gold nectarines", "code": "50314936" },
      { "name": "Organic red jim nectarines", "code": "50314937" },
      { "name": "Organic red roy nectarines", "code": "50314938" },
      { "name": "Organic rio red nectarines", "code": "50314939" },
      { "name": "Organic rose diamond nectarines", "code": "50314940" },
      { "name": "Organic royal glo nectarines", "code": "50314941" },
      { "name": "Organic ruby diamond nectarines", "code": "50314942" },
      { "name": "Organic ruby sweet nectarines", "code": "50314943" },
      { "name": "Organic ruddy jewel nectarines", "code": "50314944" },
      { "name": "Organic september red nectarines", "code": "50314945" },
      { "name": "Organic snowqueen nectarines", "code": "50314946" },
      { "name": "Organic spring bright nectarines", "code": "50314947" },
      { "name": "Organic spring red nectarines", "code": "50314948" },
      { "name": "Organic summer blush nectarines", "code": "50314949" },
      { "name": "Organic summer brite nectarines", "code": "50314950" },
      { "name": "Organic summer diamond nectarines", "code": "50314951" },
      { "name": "Organic summer fire nectarines", "code": "50314952" },
      { "name": "Organic summer grand nectarines", "code": "50314953" },
      { "name": "Organic sunglo nectarines", "code": "50314954" },
      { "name": "Organic zee fire nectarines", "code": "50314955" },
      { "name": "Organic zee glo nectarines", "code": "50314956" },
      { "name": "Organic zeegrand nectarines", "code": "50314957" },
      { "name": "Organic oranges", "code": "50315000" },
      { "name": "Organic african sour oranges", "code": "50315001" },
      { "name": "Organic ambersweet oranges", "code": "50315002" },
      { "name": "Organic argentine sour oranges", "code": "50315003" },
      { "name": "Organic bahianinha oranges", "code": "50315004" },
      { "name": "Organic bergamot oranges", "code": "50315005" },
      { "name": "Organic berna oranges", "code": "50315006" },
      { "name": "Organic bigaradier apepu oranges", "code": "50315007" },
      { "name": "Organic bittersweet daidai oranges", "code": "50315008" },
      { "name": "Organic blonde oranges", "code": "50315009" },
      { "name": "Organic blood oranges", "code": "50315010" },
      { "name": "Organic california navel oranges", "code": "50315011" },
      { "name": "Organic cara cara oranges", "code": "50315012" },
      { "name": "Organic chinotto oranges", "code": "50315013" },
      { "name": "Organic dream navel oranges", "code": "50315014" },
      { "name": "Organic gou tou oranges", "code": "50315015" },
      { "name": "Organic hamlin oranges", "code": "50315016" },
      { "name": "Organic jaffa oranges", "code": "50315017" },
      { "name": "Organic jincheng oranges", "code": "50315018" },
      { "name": "Organic k-early oranges", "code": "50315019" },
      { "name": "Organic kona oranges", "code": "50315020" },
      { "name": "Organic late navel oranges", "code": "50315021" },
      { "name": "Organic late valencia oranges", "code": "50315022" },
      { "name": "Organic limequat oranges", "code": "50315023" },
      { "name": "Organic marr oranges", "code": "50315024" },
      { "name": "Organic melogold oranges", "code": "50315025" },
      { "name": "Organic moro oranges", "code": "50315026" },
      { "name": "Organic moro tarocco oranges", "code": "50315027" },
      { "name": "Organic navel oranges", "code": "50315028" },
      { "name": "Organic navelina oranges", "code": "50315029" },
      { "name": "Organic oro blanco oranges", "code": "50315030" },
      { "name": "Organic osceola oranges", "code": "50315031" },
      { "name": "Organic parson brown oranges", "code": "50315032" },
      { "name": "Organic pera oranges", "code": "50315033" },
      { "name": "Organic pummulo oranges", "code": "50315034" },
      { "name": "Organic rhode red oranges", "code": "50315035" },
      { "name": "Organic roble oranges", "code": "50315036" },
      { "name": "Organic salustianas oranges", "code": "50315037" },
      { "name": "Organic sanguine oranges", "code": "50315038" },
      { "name": "Organic sanguinelli oranges", "code": "50315039" },
      { "name": "Organic seville oranges", "code": "50315040" },
      { "name": "Organic shamouti jaffa oranges", "code": "50315041" },
      { "name": "Organic tunis oranges", "code": "50315042" },
      { "name": "Organic valencia oranges", "code": "50315043" },
      { "name": "Organic washington navel oranges", "code": "50315044" },
      { "name": "Organic papayas", "code": "50315100" },
      { "name": "Organic green cooking papayas", "code": "50315101" },
      { "name": "Organic maradol papayas", "code": "50315102" },
      { "name": "Organic mexican yellow papayas", "code": "50315103" },
      { "name": "Organic mountain papayas", "code": "50315104" },
      { "name": "Organic solo papayas", "code": "50315105" },
      { "name": "Organic tainung papayas", "code": "50315106" },
      { "name": "Organic passion fruit", "code": "50315200" },
      { "name": "Organic banana passion fruit", "code": "50315201" },
      { "name": "Organic blue passion flower", "code": "50315202" },
      { "name": "Organic crackerjack passion fruit", "code": "50315203" },
      { "name": "Organic giant granadilla passion fruit", "code": "50315204" },
      { "name": "Organic golden granadilla passion fruit", "code": "50315205" },
      { "name": "Organic maypops passion fruit", "code": "50315206" },
      { "name": "Organic red granadilla passion fruit", "code": "50315207" },
      { "name": "Organic sweet granadilla passion fruit", "code": "50315208" },
      { "name": "Organic water lemon passion fruit", "code": "50315209" },
      { "name": "Organic wing-stemmed passion flower", "code": "50315210" },
      { "name": "Organic peaches", "code": "50315300" },
      { "name": "Organic amber crest peaches", "code": "50315301" },
      { "name": "Organic april snow peaches", "code": "50315302" },
      { "name": "Organic august lady peaches", "code": "50315303" },
      { "name": "Organic autumn flame peaches", "code": "50315304" },
      { "name": "Organic autumn lady peaches", "code": "50315305" },
      { "name": "Organic babcock peaches", "code": "50315306" },
      { "name": "Organic brittney lane peaches", "code": "50315307" },
      { "name": "Organic cary mac peaches", "code": "50315308" },
      { "name": "Organic classic peaches", "code": "50315309" },
      { "name": "Organic country sweet peaches", "code": "50315310" },
      { "name": "Organic crest haven peaches", "code": "50315311" },
      { "name": "Organic crimson lady peaches", "code": "50315312" },
      { "name": "Organic crown princess peaches", "code": "50315313" },
      { "name": "Organic david sun peaches", "code": "50315314" },
      { "name": "Organic diamond princess peaches", "code": "50315315" },
      { "name": "Organic earlirich peaches", "code": "50315316" },
      { "name": "Organic early majestic peaches", "code": "50315317" },
      { "name": "Organic early treat peaches", "code": "50315318" },
      { "name": "Organic elegant lady peaches", "code": "50315319" },
      { "name": "Organic empress peaches", "code": "50315320" },
      { "name": "Organic encore peaches", "code": "50315321" },
      { "name": "Organic fancy lady peaches", "code": "50315322" },
      { "name": "Organic fire prince peaches", "code": "50315323" },
      { "name": "Organic flame crest peaches", "code": "50315324" },
      { "name": "Organic flat type peaches", "code": "50315325" },
      { "name": "Organic flavorcrest peaches", "code": "50315326" },
      { "name": "Organic florida prince peaches", "code": "50315327" },
      { "name": "Organic full moon peaches", "code": "50315328" },
      { "name": "Organic harvester peaches", "code": "50315329" },
      { "name": "Organic ice princess peaches", "code": "50315330" },
      { "name": "Organic ivory princess peaches", "code": "50315331" },
      { "name": "Organic jersey queen peaches", "code": "50315332" },
      { "name": "Organic john henry peaches", "code": "50315333" },
      { "name": "Organic june prince peaches", "code": "50315334" },
      { "name": "Organic kaweah peaches", "code": "50315335" },
      { "name": "Organic klondike peaches", "code": "50315336" },
      { "name": "Organic lindo peaches", "code": "50315337" },
      { "name": "Organic loring peaches", "code": "50315338" },
      { "name": "Organic majestic peaches", "code": "50315339" },
      { "name": "Organic o'henry peaches", "code": "50315340" },
      { "name": "Organic queencrest peaches", "code": "50315341" },
      { "name": "Organic red lady peaches", "code": "50315342" },
      { "name": "Organic redglobe peaches", "code": "50315343" },
      { "name": "Organic redhaven peaches", "code": "50315344" },
      { "name": "Organic redtop peaches", "code": "50315345" },
      { "name": "Organic regina peaches", "code": "50315346" },
      { "name": "Organic rich lady peaches", "code": "50315347" },
      { "name": "Organic rich may peaches", "code": "50315348" },
      { "name": "Organic royal glory peaches", "code": "50315349" },
      { "name": "Organic royal lady peaches", "code": "50315350" },
      { "name": "Organic september snow peaches", "code": "50315351" },
      { "name": "Organic september sun peaches", "code": "50315352" },
      { "name": "Organic sierra gem peaches", "code": "50315353" },
      { "name": "Organic snow angel peaches", "code": "50315354" },
      { "name": "Organic snow gem peaches", "code": "50315355" },
      { "name": "Organic snow king peaches", "code": "50315356" },
      { "name": "Organic spring lady peaches", "code": "50315357" },
      { "name": "Organic spring snow peaches", "code": "50315358" },
      { "name": "Organic springcrest peaches", "code": "50315359" },
      { "name": "Organic sugar giant peaches", "code": "50315360" },
      { "name": "Organic sugar lady peaches", "code": "50315361" },
      { "name": "Organic sun bright peaches", "code": "50315362" },
      { "name": "Organic sunhigh peaches", "code": "50315363" },
      { "name": "Organic super lady peaches", "code": "50315364" },
      { "name": "Organic super rich peaches", "code": "50315365" },
      { "name": "Organic surecrop peaches", "code": "50315366" },
      { "name": "Organic sweet dream peaches", "code": "50315367" },
      { "name": "Organic sweet september peaches", "code": "50315368" },
      { "name": "Organic vista peaches", "code": "50315369" },
      { "name": "Organic white lady peaches", "code": "50315370" },
      { "name": "Organic zee lady peaches", "code": "50315371" },
      { "name": "Organic pears", "code": "50315400" },
      { "name": "Organic abate fetel pears", "code": "50315401" },
      { "name": "Organic anjou pears", "code": "50315402" },
      { "name": "Organic asian pears", "code": "50315403" },
      { "name": "Organic bartlett pears", "code": "50315404" },
      { "name": "Organic best ever pears", "code": "50315405" },
      { "name": "Organic beth pears", "code": "50315406" },
      { "name": "Organic beurr\u00e9 pears", "code": "50315407" },
      { "name": "Organic bosc pears", "code": "50315408" },
      { "name": "Organic clapp favorite pears", "code": "50315409" },
      { "name": "Organic comice pears", "code": "50315410" },
      { "name": "Organic concorde pears", "code": "50315411" },
      { "name": "Organic conference pears", "code": "50315412" },
      { "name": "Organic crimson red pears", "code": "50315413" },
      { "name": "Organic d'anjou pears", "code": "50315414" },
      { "name": "Organic dr jules guyot pears", "code": "50315415" },
      { "name": "Organic early pears", "code": "50315416" },
      { "name": "Organic emperor brown pears", "code": "50315417" },
      { "name": "Organic forelle pears", "code": "50315418" },
      { "name": "Organic french butter pears", "code": "50315419" },
      { "name": "Organic glou morceau pears", "code": "50315420" },
      { "name": "Organic hosui pears", "code": "50315421" },
      { "name": "Organic italian butter pears", "code": "50315422" },
      { "name": "Organic jargonelle pears", "code": "50315423" },
      { "name": "Organic juno pears", "code": "50315424" },
      {
        "name": "Organic kaiserlouise bonne de jersey pears",
        "code": "50315425"
      },
      { "name": "Organic keiffer pears", "code": "50315426" },
      { "name": "Organic kings royal pears", "code": "50315427" },
      { "name": "Organic limonera pears", "code": "50315428" },
      { "name": "Organic merton pride pears", "code": "50315429" },
      { "name": "Organic mountain bartlett pears", "code": "50315430" },
      { "name": "Organic olivier de serres pears", "code": "50315431" },
      { "name": "Organic onward pears", "code": "50315432" },
      { "name": "Organic packham's triumph pears", "code": "50315433" },
      { "name": "Organic paraiso pears", "code": "50315434" },
      { "name": "Organic passe crasanne pears", "code": "50315435" },
      { "name": "Organic perry pears", "code": "50315436" },
      { "name": "Organic red bartlett pears", "code": "50315437" },
      { "name": "Organic red d'anjou pears", "code": "50315438" },
      { "name": "Organic rocha pears", "code": "50315439" },
      { "name": "Organic rosey red pears", "code": "50315440" },
      { "name": "Organic rosy red pears", "code": "50315441" },
      { "name": "Organic royal majestic pears", "code": "50315442" },
      { "name": "Organic ruby red pears", "code": "50315443" },
      { "name": "Organic santa maria pears", "code": "50315444" },
      { "name": "Organic seckel pears", "code": "50315445" },
      { "name": "Organic sensation pears", "code": "50315446" },
      { "name": "Organic star crimson pears", "code": "50315447" },
      { "name": "Organic stark crimson pears", "code": "50315448" },
      { "name": "Organic summer bartlett pears", "code": "50315449" },
      { "name": "Organic summer gold pears", "code": "50315450" },
      { "name": "Organic sun gold pears", "code": "50315451" },
      { "name": "Organic sunsprite pears", "code": "50315452" },
      { "name": "Organic taylors gold pears", "code": "50315453" },
      { "name": "Organic taylors red pears", "code": "50315454" },
      { "name": "Organic tientsin pears", "code": "50315455" },
      { "name": "Organic tosca pears", "code": "50315456" },
      { "name": "Organic warden pears", "code": "50315457" },
      { "name": "Organic williams bon chretien pears", "code": "50315458" },
      { "name": "Organic williams pears", "code": "50315459" },
      { "name": "Organic winter nelis pears", "code": "50315460" },
      { "name": "Organic persimmons", "code": "50315500" },
      { "name": "Organic american persimmon", "code": "50315501" },
      { "name": "Organic black sapote persimmon", "code": "50315502" },
      { "name": "Organic chapote/black persimmon", "code": "50315503" },
      { "name": "Organic date plum persimmon", "code": "50315504" },
      { "name": "Organic fuyu persimmons", "code": "50315505" },
      { "name": "Organic giant fuyu persimmons", "code": "50315506" },
      { "name": "Organic hachiya persimmons", "code": "50315507" },
      { "name": "Organic mabolo/butter fruit persimmon", "code": "50315508" },
      { "name": "Organic principe ito persimmons", "code": "50315509" },
      { "name": "Organic royal brillante persimmons", "code": "50315510" },
      { "name": "Organic sharon fruit persimmon", "code": "50315511" },
      { "name": "Organic triumph persimmons", "code": "50315512" },
      { "name": "Organic pineapples", "code": "50315600" },
      { "name": "Organic cherimoya pineapple", "code": "50315601" },
      { "name": "Organic golden pineapple", "code": "50315602" },
      { "name": "Organic hilo pineapple", "code": "50315603" },
      { "name": "Organic kona sugarloaf pineapple", "code": "50315604" },
      { "name": "Organic natal queen pineapple", "code": "50315605" },
      { "name": "Organic pernambuco pineapple", "code": "50315606" },
      { "name": "Organic red spanish pineapple", "code": "50315607" },
      { "name": "Organic smooth cayenne pineapple", "code": "50315608" },
      { "name": "Organic sugarloaf pineapple", "code": "50315609" },
      { "name": "Organic variegated pineapple", "code": "50315610" },
      { "name": "Organic plucots", "code": "50315700" },
      { "name": "Organic black kat plucot", "code": "50315701" },
      { "name": "Organic blue gusto plucot", "code": "50315702" },
      { "name": "Organic crimson heart plucot", "code": "50315703" },
      { "name": "Organic dapple dandy plucot", "code": "50315704" },
      { "name": "Organic dapple fire plucot", "code": "50315705" },
      { "name": "Organic early dapple plucot", "code": "50315706" },
      { "name": "Organic flavor fall plucot", "code": "50315707" },
      { "name": "Organic flavor gold plucot", "code": "50315708" },
      { "name": "Organic flavor grenade plucot", "code": "50315709" },
      { "name": "Organic flavor heart plucot", "code": "50315710" },
      { "name": "Organic flavor jewel plucot", "code": "50315711" },
      { "name": "Organic flavor king plucot", "code": "50315712" },
      { "name": "Organic flavor queen plucot", "code": "50315713" },
      { "name": "Organic flavor supreme plucot", "code": "50315714" },
      { "name": "Organic flavor treat plucot", "code": "50315715" },
      { "name": "Organic flavorella plucot", "code": "50315716" },
      { "name": "Organic flavorich plucot", "code": "50315717" },
      { "name": "Organic flavorosa plucot", "code": "50315718" },
      { "name": "Organic geo pride plucot", "code": "50315719" },
      { "name": "Organic red kat plucot", "code": "50315720" },
      { "name": "Organic royal treat plucot", "code": "50315721" },
      { "name": "Organic sierra rose plucot", "code": "50315722" },
      { "name": "Organic sweet geisha plucot", "code": "50315723" },
      { "name": "Organic plums", "code": "50315800" },
      { "name": "Organic amber jewel plums", "code": "50315801" },
      { "name": "Organic angeleno plums", "code": "50315802" },
      { "name": "Organic aurora plums", "code": "50315803" },
      { "name": "Organic autumn beaut plums", "code": "50315804" },
      { "name": "Organic autumn giant plums", "code": "50315805" },
      { "name": "Organic autumn pride plums", "code": "50315806" },
      { "name": "Organic autumn rosa plums", "code": "50315807" },
      { "name": "Organic beach plum", "code": "50315808" },
      { "name": "Organic betty anne plums", "code": "50315809" },
      { "name": "Organic black beaut plums", "code": "50315810" },
      { "name": "Organic black bullace plum", "code": "50315811" },
      { "name": "Organic black diamond plums", "code": "50315812" },
      { "name": "Organic black giant plums", "code": "50315813" },
      { "name": "Organic black ice plums", "code": "50315814" },
      { "name": "Organic black splendor plums", "code": "50315815" },
      { "name": "Organic blackamber plums", "code": "50315816" },
      { "name": "Organic burgundy plums", "code": "50315817" },
      { "name": "Organic carlsbad plum", "code": "50315818" },
      { "name": "Organic casselman plums", "code": "50315819" },
      { "name": "Organic catalina plums", "code": "50315820" },
      { "name": "Organic damson plum", "code": "50315821" },
      { "name": "Organic dolly plums", "code": "50315822" },
      { "name": "Organic earliqueen plums", "code": "50315823" },
      { "name": "Organic early rosa plums", "code": "50315824" },
      { "name": "Organic ebony may plums", "code": "50315825" },
      { "name": "Organic ebony plums", "code": "50315826" },
      { "name": "Organic elephant heart plums", "code": "50315827" },
      { "name": "Organic emerald beaut plums", "code": "50315828" },
      { "name": "Organic empress plums", "code": "50315829" },
      { "name": "Organic freedom plums", "code": "50315830" },
      { "name": "Organic friar plums", "code": "50315831" },
      { "name": "Organic gar red plums", "code": "50315832" },
      { "name": "Organic governor's plum", "code": "50315833" },
      { "name": "Organic grand rosa plums", "code": "50315834" },
      { "name": "Organic green gage plum", "code": "50315835" },
      { "name": "Organic greengage plums", "code": "50315836" },
      { "name": "Organic hiromi plums", "code": "50315837" },
      { "name": "Organic hiromi red plums", "code": "50315838" },
      { "name": "Organic holiday plums", "code": "50315839" },
      { "name": "Organic howard sun plums", "code": "50315840" },
      { "name": "Organic interspecific type plums", "code": "50315841" },
      { "name": "Organic jamaican plum", "code": "50315842" },
      { "name": "Organic joanna red plums", "code": "50315843" },
      { "name": "Organic kelsey plums", "code": "50315844" },
      { "name": "Organic king james plums", "code": "50315845" },
      { "name": "Organic laroda plums", "code": "50315846" },
      { "name": "Organic late rosa plums", "code": "50315847" },
      { "name": "Organic linda rosa plums", "code": "50315848" },
      { "name": "Organic lone star red plums", "code": "50315849" },
      { "name": "Organic mariposa plums", "code": "50315850" },
      { "name": "Organic marked black plums", "code": "50315851" },
      { "name": "Organic marked red plums", "code": "50315852" },
      { "name": "Organic mirabelle plum", "code": "50315853" },
      { "name": "Organic october sun plums", "code": "50315854" },
      { "name": "Organic owen t plums", "code": "50315855" },
      { "name": "Organic perdrigon plum", "code": "50315856" },
      { "name": "Organic pink delight plums", "code": "50315857" },
      { "name": "Organic president plums", "code": "50315858" },
      { "name": "Organic primetime plums", "code": "50315859" },
      { "name": "Organic purple majesty plums", "code": "50315860" },
      { "name": "Organic queen rosa plums", "code": "50315861" },
      { "name": "Organic quetsch plum", "code": "50315862" },
      { "name": "Organic red beaut plums", "code": "50315863" },
      { "name": "Organic red lane plums", "code": "50315864" },
      { "name": "Organic red ram plums", "code": "50315865" },
      { "name": "Organic red rosa plums", "code": "50315866" },
      { "name": "Organic rich red plums", "code": "50315867" },
      { "name": "Organic rosemary plums", "code": "50315868" },
      { "name": "Organic royal diamond plums", "code": "50315869" },
      { "name": "Organic royal red plums", "code": "50315870" },
      { "name": "Organic royal zee plums", "code": "50315871" },
      { "name": "Organic roysum plums", "code": "50315872" },
      { "name": "Organic santa rosa plums", "code": "50315873" },
      { "name": "Organic saphire plums", "code": "50315874" },
      { "name": "Organic sloe plum", "code": "50315875" },
      { "name": "Organic st catherine plum", "code": "50315876" },
      { "name": "Organic white bullace plum", "code": "50315877" },
      { "name": "Organic pommegranates", "code": "50315900" },
      { "name": "Organic foothill pommegranates", "code": "50315901" },
      { "name": "Organic granada pommegranates", "code": "50315902" },
      { "name": "Organic jolly red pommegranates", "code": "50315903" },
      { "name": "Organic nana pommegranates", "code": "50315904" },
      { "name": "Organic pat's red pommegranates", "code": "50315905" },
      { "name": "Organic pinkhan pommegranates", "code": "50315906" },
      { "name": "Organic purple velvet pommegranates", "code": "50315907" },
      { "name": "Organic wonderful pommegranates", "code": "50315908" },
      { "name": "Organic pomelos", "code": "50316000" },
      { "name": "Organic chandler pomelo", "code": "50316001" },
      { "name": "Organic hirado buntan pomelo", "code": "50316002" },
      { "name": "Organic liang ping yau pomelo", "code": "50316003" },
      { "name": "Organic pandan wangi pomelo", "code": "50316004" },
      { "name": "Organic pink pomelo", "code": "50316005" },
      { "name": "Organic red shaddock pomelo", "code": "50316006" },
      { "name": "Organic siamese sweet pomelo", "code": "50316007" },
      { "name": "Organic wainwright pomelo", "code": "50316008" },
      { "name": "Organic quinces", "code": "50316100" },
      { "name": "Organic champion quince", "code": "50316101" },
      { "name": "Organic pineapple quince", "code": "50316102" },
      { "name": "Organic smyrna quince", "code": "50316103" },
      { "name": "Organic raspberries", "code": "50316200" },
      { "name": "Organic american red raspberry", "code": "50316201" },
      { "name": "Organic bailey queensland raspberry", "code": "50316202" },
      { "name": "Organic black raspberry", "code": "50316203" },
      { "name": "Organic dark raspberry", "code": "50316204" },
      { "name": "Organic delicious raspberry", "code": "50316205" },
      { "name": "Organic focke dwarf raspberry", "code": "50316206" },
      { "name": "Organic focke grayleaf red raspberry", "code": "50316207" },
      { "name": "Organic focke strawberry raspberry", "code": "50316208" },
      {
        "name": "Organic focke yellow himalayan raspberry",
        "code": "50316209"
      },
      { "name": "Organic gold raspberry", "code": "50316210" },
      { "name": "Organic gray new mexico raspberry", "code": "50316211" },
      { "name": "Organic jepson whitebark raspberry", "code": "50316212" },
      { "name": "Organic kellogg san diego raspberry", "code": "50316213" },
      { "name": "Organic leucodermis whitebark raspberry", "code": "50316214" },
      { "name": "Organic munz cuyamaca raspberry", "code": "50316215" },
      { "name": "Organic peck barton's raspberry", "code": "50316216" },
      { "name": "Organic purpleflowering raspberry", "code": "50316217" },
      { "name": "Organic roadside raspberry", "code": "50316218" },
      { "name": "Organic san diego raspberry", "code": "50316219" },
      { "name": "Organic snow raspberry", "code": "50316220" },
      { "name": "Organic snowpeaks raspberry", "code": "50316221" },
      { "name": "Organic strawberryleaf raspberry", "code": "50316222" },
      { "name": "Organic sweet cultivated raspberry", "code": "50316223" },
      {
        "name": "Organic torr and gray whitebark raspberry",
        "code": "50316224"
      },
      { "name": "Organic west indian raspberry", "code": "50316225" },
      { "name": "Organic whitebark raspberry", "code": "50316226" },
      { "name": "Organic wine raspberry", "code": "50316227" },
      { "name": "Organic yellow himalayan raspberry", "code": "50316228" },
      { "name": "Organic yu-shan raspberry", "code": "50316229" },
      { "name": "Organic rhubarb", "code": "50316300" },
      { "name": "Organic crimson red rhubarb", "code": "50316301" },
      { "name": "Organic early champagne rhubarb", "code": "50316302" },
      { "name": "Organic glaskin's perpetual rhubarb", "code": "50316303" },
      { "name": "Organic sutton rhubarb", "code": "50316304" },
      { "name": "Organic timperley early rhubarb", "code": "50316305" },
      { "name": "Organic valentine rhubarb", "code": "50316306" },
      { "name": "Organic victoria rhubarb", "code": "50316307" },
      { "name": "Organic zwolle seedling rhubarb", "code": "50316308" },
      { "name": "Organic macdonald rhubarb", "code": "50316309" },
      { "name": "Organic tilden rhubarb", "code": "50316310" },
      { "name": "Organic rose hips", "code": "50316400" },
      { "name": "Organic brier rose hips", "code": "50316401" },
      { "name": "Organic elgantine rose hips", "code": "50316402" },
      { "name": "Organic rugosa rose hips", "code": "50316403" },
      { "name": "Organic scotch or burnet rose hips", "code": "50316404" },
      { "name": "Organic sapotes", "code": "50316500" },
      { "name": "Organic white sapotes", "code": "50316501" },
      { "name": "Organic black sapotes", "code": "50316502" },
      { "name": "Organic saskatoon berries", "code": "50316600" },
      { "name": "Organic honeywood saskatoon berries", "code": "50316601" },
      { "name": "Organic northline saskatoon berries", "code": "50316602" },
      { "name": "Organic smoky saskatoon berries", "code": "50316603" },
      { "name": "Organic thiessen saskatoon berries", "code": "50316604" },
      { "name": "Organic strawberries", "code": "50316700" },
      { "name": "Organic chandler strawberries", "code": "50316701" },
      { "name": "Organic june bearing strawberries", "code": "50316702" },
      { "name": "Organic ever bearing strawberries", "code": "50316703" },
      { "name": "Organic sugar apple", "code": "50316800" },
      { "name": "Organic kampong mauve sugar apple", "code": "50316801" },
      { "name": "Organic seedless sugar apple", "code": "50316802" },
      { "name": "Organic thai lessard sugar apple", "code": "50316803" },
      { "name": "Organic tamarillo", "code": "50316900" },
      { "name": "Organic amberlea gold tamarillo", "code": "50316901" },
      { "name": "Organic bold gold tamarillo", "code": "50316902" },
      { "name": "Organic goldmine tamarillo", "code": "50316903" },
      { "name": "Organic oratia red tamarillo", "code": "50316904" },
      { "name": "Organic red beau tamarillo", "code": "50316905" },
      { "name": "Organic red delight tamarillo", "code": "50316906" },
      { "name": "Nominant organic fruits", "code": "50317000" },
      { "name": "Organic akee", "code": "50317001" },
      { "name": "Organic babaco", "code": "50317002" },
      { "name": "Organic banana flowers", "code": "50317003" },
      { "name": "Organic baobab", "code": "50317004" },
      { "name": "Organic bitter oranges", "code": "50317005" },
      { "name": "Organic canistel", "code": "50317006" },
      { "name": "Organic cloudberries", "code": "50317007" },
      { "name": "Organic coconuts", "code": "50317008" },
      { "name": "Organic dewberries", "code": "50317009" },
      { "name": "Organic durian", "code": "50317010" },
      { "name": "Organic elderberries", "code": "50317011" },
      { "name": "Organic feijoa", "code": "50317012" },
      { "name": "Organic hackberries", "code": "50317013" },
      { "name": "Organic hawthorn", "code": "50317014" },
      { "name": "Organic honeyberries", "code": "50317015" },
      { "name": "Organic jackfruit", "code": "50317016" },
      { "name": "Organic jambolan", "code": "50317017" },
      { "name": "Organic jujube", "code": "50317018" },
      { "name": "Organic lychee", "code": "50317019" },
      { "name": "Organic mangosteens", "code": "50317020" },
      { "name": "Organic medlars", "code": "50317021" },
      { "name": "Organic mombins", "code": "50317022" },
      { "name": "Organic monstera", "code": "50317023" },
      { "name": "Organic pepinos", "code": "50317024" },
      { "name": "Organic plantains", "code": "50317025" },
      { "name": "Organic prickly pears", "code": "50317026" },
      { "name": "Organic quenepas", "code": "50317027" },
      { "name": "Organic rambutan", "code": "50317028" },
      { "name": "Organic rose apples", "code": "50317029" },
      { "name": "Organic roselle", "code": "50317030" },
      { "name": "Organic rowanberries", "code": "50317031" },
      { "name": "Organic sea buckhorn berries", "code": "50317032" },
      { "name": "Organic silverberries", "code": "50317033" },
      { "name": "Organic sorb berries", "code": "50317034" },
      { "name": "Organic soursops", "code": "50317035" },
      { "name": "Organic star apples", "code": "50317036" },
      { "name": "Organic tamarindo", "code": "50317037" },
      { "name": "Organic chokeberries", "code": "50317100" },
      { "name": "Organic autumn magic chokeberries", "code": "50317101" },
      { "name": "Organic brillantisima chokeberries", "code": "50317102" },
      { "name": "Organic nero chokeberries", "code": "50317103" },
      { "name": "Organic viking chokeberries", "code": "50317104" },
      { "name": "Organic olives", "code": "50317200" },
      { "name": "Organic agrinion olives", "code": "50317201" },
      { "name": "Organic aleppo olives", "code": "50317202" },
      { "name": "Organic alphonso olives", "code": "50317203" },
      { "name": "Organic amphissa olives", "code": "50317204" },
      { "name": "Organic arauco olives", "code": "50317205" },
      { "name": "Organic arbequina olives", "code": "50317206" },
      { "name": "Organic atalanta olives", "code": "50317207" },
      { "name": "Organic cerignola olives", "code": "50317208" },
      { "name": "Organic cracked provencal olives", "code": "50317209" },
      { "name": "Organic empeltre olives", "code": "50317210" },
      { "name": "Organic gaeta olives", "code": "50317211" },
      { "name": "Organic hondroelia olives", "code": "50317212" },
      { "name": "Organic kalamata olives", "code": "50317213" },
      { "name": "Organic kura olives", "code": "50317214" },
      { "name": "Organic ligurian olives", "code": "50317215" },
      { "name": "Organic lucque olives", "code": "50317216" },
      { "name": "Organic lugano olives", "code": "50317217" },
      { "name": "Organic manzanilla olives", "code": "50317218" },
      { "name": "Organic marche olives", "code": "50317219" },
      { "name": "Organic mission olives", "code": "50317220" },
      { "name": "Organic nafplion green olives", "code": "50317221" },
      { "name": "Organic nicoise olives", "code": "50317222" },
      { "name": "Organic nyons olives", "code": "50317223" },
      { "name": "Organic picholine olives", "code": "50317224" },
      { "name": "Organic ponentine olives", "code": "50317225" },
      { "name": "Organic royal olives", "code": "50317226" },
      { "name": "Organic seracena olives", "code": "50317227" },
      { "name": "Organic sevillano olives", "code": "50317228" },
      { "name": "Organic sicilian olives", "code": "50317229" },
      { "name": "Organic toscanelle olives", "code": "50317230" },
      { "name": "Dried fruit", "code": "50320000" },
      { "name": "Dried apples", "code": "50321500" },
      { "name": "Dried akane apples", "code": "50321501" },
      { "name": "Dried ambrosia apples", "code": "50321502" },
      { "name": "Dried api apples", "code": "50321503" },
      { "name": "Dried baldwin apples", "code": "50321504" },
      { "name": "Dried braeburn apples", "code": "50321505" },
      { "name": "Dried bramley apples", "code": "50321506" },
      { "name": "Dried bramley seedling apples", "code": "50321507" },
      { "name": "Dried calville blanche d'hiver apples", "code": "50321508" },
      { "name": "Dried cameo apples", "code": "50321509" },
      { "name": "Dried charles ross apples", "code": "50321510" },
      { "name": "Dried codlin apples", "code": "50321511" },
      { "name": "Dried cortland apples", "code": "50321512" },
      { "name": "Dried costard apples", "code": "50321513" },
      { "name": "Dried court pendu plat apples", "code": "50321514" },
      { "name": "Dried cox's orange pippin apples", "code": "50321515" },
      { "name": "Dried crab apples", "code": "50321516" },
      { "name": "Dried crispin apples", "code": "50321517" },
      { "name": "Dried delicious apples", "code": "50321518" },
      { "name": "Dried duchess apples", "code": "50321519" },
      { "name": "Dried earligold apples", "code": "50321520" },
      { "name": "Dried early mcintosh apples", "code": "50321521" },
      { "name": "Dried elstar apples", "code": "50321522" },
      { "name": "Dried empire apples", "code": "50321523" },
      { "name": "Dried flower of kent apples", "code": "50321524" },
      { "name": "Dried fuji apples", "code": "50321525" },
      { "name": "Dried gala apples", "code": "50321526" },
      { "name": "Dried gascoyne's scarlet apples", "code": "50321527" },
      { "name": "Dried gilliflower apples", "code": "50321528" },
      { "name": "Dried ginger gold apples", "code": "50321529" },
      { "name": "Dried gladstone apples", "code": "50321530" },
      { "name": "Dried gloster apples", "code": "50321531" },
      { "name": "Dried gold supreme apples", "code": "50321532" },
      { "name": "Dried golden delicious apples", "code": "50321533" },
      { "name": "Dried golden noble apples", "code": "50321534" },
      { "name": "Dried granny smith apples", "code": "50321535" },
      { "name": "Dried gravenstein apples", "code": "50321536" },
      { "name": "Dried greening apples", "code": "50321537" },
      { "name": "Dried greensleeves apples", "code": "50321538" },
      { "name": "Dried honeycrisp apples", "code": "50321539" },
      { "name": "Dried howgate wonder apples", "code": "50321540" },
      { "name": "Dried ida red apples", "code": "50321541" },
      { "name": "Dried james grieve apples", "code": "50321542" },
      { "name": "Dried jersey mac apples", "code": "50321543" },
      { "name": "Dried jester apples", "code": "50321544" },
      { "name": "Dried jonagold apples", "code": "50321545" },
      { "name": "Dried jonamac apples", "code": "50321546" },
      { "name": "Dried jonathan apples", "code": "50321547" },
      { "name": "Dried katy apples", "code": "50321548" },
      { "name": "Dried kidd's orange red apples", "code": "50321549" },
      { "name": "Dried lady apples", "code": "50321550" },
      { "name": "Dried law rome apples", "code": "50321551" },
      { "name": "Dried laxton apples", "code": "50321552" },
      { "name": "Dried lord derby apples", "code": "50321553" },
      { "name": "Dried macoun apples", "code": "50321554" },
      { "name": "Dried mcintosh apples", "code": "50321555" },
      { "name": "Dried mutsu apples", "code": "50321556" },
      { "name": "Dried newtown pippin apples", "code": "50321557" },
      { "name": "Dried northern spy apples", "code": "50321558" },
      { "name": "Dried orleans reinette apples", "code": "50321559" },
      { "name": "Dried ozark gold apples", "code": "50321560" },
      { "name": "Dried pacific rose apples", "code": "50321561" },
      { "name": "Dried paula red apples", "code": "50321562" },
      { "name": "Dried pearmain apples", "code": "50321563" },
      { "name": "Dried pink lady apples", "code": "50321564" },
      { "name": "Dried pippin apples", "code": "50321565" },
      { "name": "Dried pitmaston pineapple apples", "code": "50321566" },
      { "name": "Dried pomme d'api apples", "code": "50321567" },
      { "name": "Dried prime gold apples", "code": "50321568" },
      { "name": "Dried red astrachan apples", "code": "50321569" },
      { "name": "Dried red boscoop apples", "code": "50321570" },
      { "name": "Dried red chief apples", "code": "50321571" },
      { "name": "Dried red delicious apples", "code": "50321572" },
      { "name": "Dried red gravenstein apples", "code": "50321573" },
      { "name": "Dried red rome apples", "code": "50321574" },
      { "name": "Dried red stayman apples", "code": "50321575" },
      { "name": "Dried red york apples", "code": "50321576" },
      { "name": "Dried reinette apples", "code": "50321577" },
      { "name": "Dried rome beauty apples", "code": "50321578" },
      { "name": "Dried russet apples", "code": "50321579" },
      { "name": "Dried sierra beauty apples", "code": "50321580" },
      { "name": "Dried spartan apples", "code": "50321581" },
      { "name": "Dried stark crimson apples", "code": "50321582" },
      { "name": "Dried starking apples", "code": "50321583" },
      { "name": "Dried stayman apples", "code": "50321584" },
      { "name": "Dried stayman winesap apples", "code": "50321585" },
      { "name": "Dried summer rambo apples", "code": "50321586" },
      { "name": "Dried tsugaru apples", "code": "50321587" },
      { "name": "Dried twenty ounce apples", "code": "50321588" },
      { "name": "Dried tydeman red apples", "code": "50321589" },
      { "name": "Dried vistabella apples", "code": "50321590" },
      { "name": "Dried wealthy apples", "code": "50321591" },
      { "name": "Dried white joaneting apples", "code": "50321592" },
      { "name": "Dried white transparent apples", "code": "50321593" },
      { "name": "Dried winesap apples", "code": "50321594" },
      { "name": "Dried worcester apples", "code": "50321595" },
      { "name": "Dried york imperial apples", "code": "50321596" },
      { "name": "Dried apricots", "code": "50321600" },
      { "name": "Dried ambercot apricots", "code": "50321601" },
      { "name": "Dried apache apricots", "code": "50321602" },
      { "name": "Dried brittany gold apricots", "code": "50321603" },
      { "name": "Dried black apricots", "code": "50321604" },
      { "name": "Dried blenheim apricots", "code": "50321605" },
      { "name": "Dried bonny apricots", "code": "50321606" },
      { "name": "Dried bulida apricots", "code": "50321607" },
      { "name": "Dried castlebrite apricots", "code": "50321608" },
      { "name": "Dried clutha gold apricots", "code": "50321609" },
      { "name": "Dried clutha sun apricots", "code": "50321610" },
      { "name": "Dried darby royal apricots", "code": "50321611" },
      { "name": "Dried dina apricots", "code": "50321612" },
      { "name": "Dried earlicot apricots", "code": "50321613" },
      { "name": "Dried earliman apricots", "code": "50321614" },
      { "name": "Dried early bright apricots", "code": "50321615" },
      { "name": "Dried flaming gold apricots", "code": "50321616" },
      { "name": "Dried fresno apricots", "code": "50321617" },
      { "name": "Dried gold brite apricots", "code": "50321618" },
      { "name": "Dried goldbar apricots", "code": "50321619" },
      { "name": "Dried golden sweet apricots", "code": "50321620" },
      { "name": "Dried goldrich apricots", "code": "50321621" },
      { "name": "Dried helena apricots", "code": "50321622" },
      { "name": "Dried honeycot apricots", "code": "50321623" },
      { "name": "Dried imperial apricots", "code": "50321624" },
      { "name": "Dried jordanne apricots", "code": "50321625" },
      { "name": "Dried jumbo cot apricots", "code": "50321626" },
      { "name": "Dried kandy kot apricots", "code": "50321627" },
      { "name": "Dried katy apricots", "code": "50321628" },
      { "name": "Dried king apricots", "code": "50321629" },
      { "name": "Dried lambertin apricots", "code": "50321630" },
      { "name": "Dried lorna apricots", "code": "50321631" },
      { "name": "Dried lulu belle apricots", "code": "50321632" },
      { "name": "Dried modesto apricots", "code": "50321633" },
      { "name": "Dried moorpark apricots", "code": "50321634" },
      { "name": "Dried orangered apricots", "code": "50321635" },
      { "name": "Dried palstein apricots", "code": "50321636" },
      { "name": "Dried patterson apricots", "code": "50321637" },
      { "name": "Dried perfection apricots", "code": "50321638" },
      { "name": "Dried poppy apricots", "code": "50321639" },
      { "name": "Dried poppycot apricots", "code": "50321640" },
      { "name": "Dried queen apricots", "code": "50321641" },
      { "name": "Dried riland apricots", "code": "50321642" },
      { "name": "Dried rival apricots", "code": "50321643" },
      { "name": "Dried robada apricots", "code": "50321644" },
      { "name": "Dried royal apricots", "code": "50321645" },
      { "name": "Dried royal blenheim apricots", "code": "50321646" },
      { "name": "Dried royal orange apricots", "code": "50321647" },
      { "name": "Dried sundrop apricots", "code": "50321648" },
      { "name": "Dried tilton apricots", "code": "50321649" },
      { "name": "Dried tomcot apricots", "code": "50321650" },
      { "name": "Dried tracy apricots", "code": "50321651" },
      { "name": "Dried tri gem apricots", "code": "50321652" },
      { "name": "Dried valley gold apricots", "code": "50321653" },
      { "name": "Dried westley apricots", "code": "50321654" },
      { "name": "Dried york apricots", "code": "50321655" },
      { "name": "Dried bananas", "code": "50321700" },
      { "name": "Dried apple bananas", "code": "50321701" },
      { "name": "Dried baby bananas", "code": "50321702" },
      { "name": "Dried burro bananas", "code": "50321703" },
      { "name": "Dried cavendish bananas", "code": "50321704" },
      { "name": "Dried dominico bananas", "code": "50321705" },
      { "name": "Dried green bananas", "code": "50321706" },
      { "name": "Dried gros michel bananas", "code": "50321707" },
      { "name": "Dried lacatan bananas", "code": "50321708" },
      { "name": "Dried lady finger banana", "code": "50321709" },
      { "name": "Dried manzano bananas", "code": "50321710" },
      { "name": "Dried mysore bananas", "code": "50321711" },
      { "name": "Dried pisang mas bananas", "code": "50321712" },
      { "name": "Dried red bananas", "code": "50321713" },
      { "name": "Dried saba bananas", "code": "50321714" },
      { "name": "Dried sucrier bananas", "code": "50321715" },
      { "name": "Dried barberries", "code": "50321800" },
      { "name": "Dried paleleaf barberries", "code": "50321801" },
      { "name": "Dried chenault barberries", "code": "50321802" },
      { "name": "Dried red barberries", "code": "50321803" },
      { "name": "Dried wintergreen barberries", "code": "50321804" },
      { "name": "Dried korean barberries", "code": "50321805" },
      { "name": "Dried mentor barberries", "code": "50321806" },
      { "name": "Dried japanese barberries", "code": "50321807" },
      { "name": "Dried atropurpurea barberries", "code": "50321808" },
      { "name": "Dried aurea barberries", "code": "50321809" },
      { "name": "Dried bagatelle barberries", "code": "50321810" },
      { "name": "Dried crimson pygmy barberries", "code": "50321811" },
      { "name": "Dried kobold barberries", "code": "50321812" },
      { "name": "Dried warty barberries", "code": "50321813" },
      { "name": "Dried european barberries", "code": "50321814" },
      { "name": "Dried bearberries", "code": "50321900" },
      { "name": "Dried alpine bearberries", "code": "50321901" },
      { "name": "Dried red bearberries", "code": "50321902" },
      { "name": "Dried common bearberries", "code": "50321903" },
      { "name": "Dried blackberries", "code": "50322000" },
      { "name": "Dried apache blackberries", "code": "50322001" },
      { "name": "Dried black satin blackberries", "code": "50322002" },
      { "name": "Dried boysenberries", "code": "50322003" },
      { "name": "Dried cherokee blackberries", "code": "50322004" },
      { "name": "Dried chester blackberries", "code": "50322005" },
      { "name": "Dried dirksen blackberries", "code": "50322006" },
      { "name": "Dried jostaberries", "code": "50322007" },
      { "name": "Dried loganberries", "code": "50322008" },
      { "name": "Dried marionberries", "code": "50322009" },
      { "name": "Dried navaho blackberries", "code": "50322010" },
      { "name": "Dried nectarberries", "code": "50322011" },
      { "name": "Dried olallie blackberries", "code": "50322012" },
      { "name": "Dried tayberries", "code": "50322013" },
      { "name": "Dried thornless hull blackberries", "code": "50322014" },
      { "name": "Dried youngberries", "code": "50322015" },
      { "name": "Dried bilberries", "code": "50322100" },
      { "name": "Dried bog bilberries", "code": "50322101" },
      { "name": "Dried dwarf bilberries", "code": "50322102" },
      { "name": "Dried mountain bilberries", "code": "50322103" },
      { "name": "Dried oval-leaved bilberries", "code": "50322104" },
      { "name": "Dried blueberries", "code": "50322200" },
      { "name": "Dried bluecrop blueberries", "code": "50322201" },
      { "name": "Dried bluetta blueberries", "code": "50322202" },
      { "name": "Dried brigitta blueberries", "code": "50322203" },
      { "name": "Dried chandler blueberries", "code": "50322204" },
      { "name": "Dried duke blueberries", "code": "50322205" },
      { "name": "Dried hardyblue blueberries", "code": "50322206" },
      { "name": "Dried legacy blueberries", "code": "50322207" },
      { "name": "Dried misty blueberries", "code": "50322208" },
      { "name": "Dried nelson blueberries", "code": "50322209" },
      { "name": "Dried northblue blueberries", "code": "50322210" },
      { "name": "Dried northcountry blueberries", "code": "50322211" },
      { "name": "Dried northsky blueberries", "code": "50322212" },
      { "name": "Dried patriot blueberries", "code": "50322213" },
      { "name": "Dried spartan blueberries", "code": "50322214" },
      { "name": "Dried toro blueberries", "code": "50322215" },
      { "name": "Dried breadfruit", "code": "50322300" },
      { "name": "Dried chataigne breadfruit", "code": "50322301" },
      { "name": "Dried seedless breadfruit", "code": "50322302" },
      { "name": "Dried white heart breadfruit", "code": "50322303" },
      { "name": "Dried yellow heart breadfruit", "code": "50322304" },
      { "name": "Dried cherimoyas", "code": "50322400" },
      { "name": "Dried bays cherimoya", "code": "50322401" },
      { "name": "Dried bronceada cherimoya", "code": "50322402" },
      { "name": "Dried burtons cherimoya", "code": "50322403" },
      { "name": "Dried burtons favorite cherimoya", "code": "50322404" },
      { "name": "Dried jete cherimoya", "code": "50322405" },
      { "name": "Dried reretai cherimoya", "code": "50322406" },
      { "name": "Dried smoothey cherimoya", "code": "50322407" },
      { "name": "Dried spain cherimoya", "code": "50322408" },
      { "name": "Dried white cherimoya", "code": "50322409" },
      { "name": "Dried cherries", "code": "50322500" },
      { "name": "Dried amarelle cherries", "code": "50322501" },
      { "name": "Dried brooks cherries", "code": "50322502" },
      { "name": "Dried bigarreu cherries", "code": "50322503" },
      { "name": "Dried bing cherries", "code": "50322504" },
      { "name": "Dried black republic cherries", "code": "50322505" },
      { "name": "Dried black schmidt cherries", "code": "50322506" },
      { "name": "Dried black tartarian cherries", "code": "50322507" },
      { "name": "Dried fiesta bing cherries", "code": "50322508" },
      { "name": "Dried garnet cherries", "code": "50322509" },
      { "name": "Dried king cherries", "code": "50322510" },
      { "name": "Dried chapman cherries", "code": "50322511" },
      { "name": "Dried lapin cherries", "code": "50322512" },
      { "name": "Dried larian cherries", "code": "50322513" },
      { "name": "Dried dark guines cherries", "code": "50322514" },
      { "name": "Dried montmorency cherries", "code": "50322515" },
      { "name": "Dried duke cherries", "code": "50322516" },
      { "name": "Dried early rivers cherries", "code": "50322517" },
      { "name": "Dried ruby bing cherries", "code": "50322518" },
      { "name": "Dried santina cherries", "code": "50322519" },
      { "name": "Dried geans/guines cherries", "code": "50322520" },
      { "name": "Dried sonata cherries", "code": "50322521" },
      { "name": "Dried lambert cherries", "code": "50322522" },
      { "name": "Dried stella cherries", "code": "50322523" },
      { "name": "Dried sweetheart cherries", "code": "50322524" },
      { "name": "Dried tartarian cherries", "code": "50322525" },
      { "name": "Dried maraschino cherries", "code": "50322527" },
      { "name": "Dried van cherries", "code": "50322528" },
      { "name": "Dried morello cherries", "code": "50322529" },
      { "name": "Dried royal ann cherries", "code": "50322530" },
      { "name": "Dried ranier cherries", "code": "50322531" },
      { "name": "Dried royal cherries", "code": "50322532" },
      { "name": "Dried citrons", "code": "50322600" },
      { "name": "Dried buddha's hand citrons", "code": "50322601" },
      { "name": "Dried fingered citrons", "code": "50322602" },
      { "name": "Dried fo shoukan citrons", "code": "50322603" },
      { "name": "Dried bushakan citrons", "code": "50322604" },
      { "name": "Dried diamante citrons", "code": "50322605" },
      { "name": "Dried etrog citrons", "code": "50322606" },
      { "name": "Dried ponderosa citrons", "code": "50322607" },
      { "name": "Dried cranberries", "code": "50322700" },
      { "name": "Dried ben lear cranberries", "code": "50322701" },
      { "name": "Dried early black cranberries", "code": "50322702" },
      { "name": "Dried grycleski cranberries", "code": "50322703" },
      { "name": "Dried howe cranberries", "code": "50322704" },
      { "name": "Dried lingonberries", "code": "50322705" },
      { "name": "Dried mcfarlin cranberries", "code": "50322706" },
      { "name": "Dried mountain cranberries", "code": "50322707" },
      { "name": "Dried pilgrim cranberries", "code": "50322708" },
      { "name": "Dried searless cranberries", "code": "50322709" },
      { "name": "Dried stevens cranberries", "code": "50322710" },
      { "name": "Dried currants", "code": "50322800" },
      { "name": "Dried hudson bay currants", "code": "50322801" },
      { "name": "Dried waxy currants", "code": "50322802" },
      { "name": "Dried desert currants", "code": "50322803" },
      { "name": "Dried black currants", "code": "50322804" },
      { "name": "Dried red currants", "code": "50322805" },
      { "name": "Dried white currants", "code": "50322806" },
      { "name": "Dried dates", "code": "50322900" },
      { "name": "Dried asharasi dates", "code": "50322901" },
      { "name": "Dried barhi or barhee dates", "code": "50322902" },
      { "name": "Dried deglet noor dates", "code": "50322903" },
      { "name": "Dried fardh dates", "code": "50322904" },
      { "name": "Dried gundila dates", "code": "50322905" },
      { "name": "Dried halawi/halawy dates", "code": "50322906" },
      { "name": "Dried hilali dates", "code": "50322907" },
      { "name": "Dried khadrawi/khadrawy dates", "code": "50322908" },
      { "name": "Dried khalas dates", "code": "50322909" },
      { "name": "Dried khustawi dates", "code": "50322910" },
      { "name": "Dried khidri dates", "code": "50322911" },
      { "name": "Dried medjool/medjul dates", "code": "50322912" },
      { "name": "Dried mactoum dates", "code": "50322913" },
      { "name": "Dried neghal dates", "code": "50322914" },
      { "name": "Dried yatimeh dates", "code": "50322915" },
      { "name": "Dried zahidi dates", "code": "50322916" },
      { "name": "Dried dragonfruit", "code": "50323000" },
      { "name": "Dried pink dragonfruit", "code": "50323001" },
      { "name": "Dried yellow dragonfruit", "code": "50323002" },
      { "name": "Dried figs", "code": "50323100" },
      { "name": "Dried bardajic figs", "code": "50323101" },
      { "name": "Dried brown turkey figs", "code": "50323102" },
      { "name": "Dried calimyrna figs", "code": "50323103" },
      { "name": "Dried conadria figs", "code": "50323104" },
      { "name": "Dried dottado figs", "code": "50323105" },
      { "name": "Dried kadota figs", "code": "50323106" },
      { "name": "Dried mediterranean figs", "code": "50323107" },
      { "name": "Dried mission figs", "code": "50323108" },
      { "name": "Dried smyrna figs", "code": "50323109" },
      { "name": "Dried verdona figs", "code": "50323110" },
      { "name": "Dried white king figs", "code": "50323111" },
      { "name": "Dried gooseberries", "code": "50323200" },
      { "name": "Dried early sulphur gooseberries", "code": "50323201" },
      { "name": "Dried goldendrop gooseberries", "code": "50323202" },
      { "name": "Dried langley gage gooseberries", "code": "50323203" },
      { "name": "Dried leveller gooseberries", "code": "50323204" },
      { "name": "Dried london gooseberries", "code": "50323205" },
      { "name": "Dried worcestershire gooseberries", "code": "50323206" },
      {
        "name": "Dried american worcesterberry gooseberries",
        "code": "50323207"
      },
      { "name": "Dried grapefruit", "code": "50323300" },
      { "name": "Dried burgundy grapefruit", "code": "50323301" },
      { "name": "Dried duncan grapefruit", "code": "50323302" },
      { "name": "Dried foster grapefruit", "code": "50323303" },
      { "name": "Dried marsh grapefruit", "code": "50323304" },
      { "name": "Dried new zealand grapefruit", "code": "50323305" },
      { "name": "Dried rio red grapefruit", "code": "50323306" },
      { "name": "Dried ruby red grapefruit", "code": "50323307" },
      { "name": "Dried star ruby grapefruit", "code": "50323308" },
      { "name": "Dried triumph grapefruit", "code": "50323309" },
      { "name": "Dried table grapes", "code": "50323400" },
      { "name": "Dried alicante grapes", "code": "50323401" },
      { "name": "Dried almeria grapes", "code": "50323402" },
      { "name": "Dried alphonse lavalle grapes", "code": "50323403" },
      { "name": "Dried autumn king grapes", "code": "50323404" },
      { "name": "Dried autumn royal grapes", "code": "50323405" },
      { "name": "Dried autumn seedless grapes", "code": "50323406" },
      { "name": "Dried baresana grapes", "code": "50323407" },
      { "name": "Dried barlinka grapes", "code": "50323408" },
      { "name": "Dried beauty seedless grapes", "code": "50323409" },
      { "name": "Dried black beauty seedless grapes", "code": "50323410" },
      { "name": "Dried black emerald grapes", "code": "50323411" },
      { "name": "Dried black giant grapes", "code": "50323412" },
      { "name": "Dried black globe grapes", "code": "50323413" },
      { "name": "Dried black monukka grapes", "code": "50323414" },
      { "name": "Dried black pearl grapes", "code": "50323415" },
      { "name": "Dried black seedless grapes", "code": "50323416" },
      { "name": "Dried bonheur grapes", "code": "50323417" },
      { "name": "Dried calmeria grapes", "code": "50323418" },
      { "name": "Dried cardinal grapes", "code": "50323419" },
      { "name": "Dried catawba grapes", "code": "50323420" },
      { "name": "Dried chasselas/golden chasselas grapes", "code": "50323421" },
      { "name": "Dried christmas rose grapes", "code": "50323422" },
      { "name": "Dried concord grapes", "code": "50323423" },
      { "name": "Dried concord seedless grapes", "code": "50323424" },
      { "name": "Dried crimson seedless grapes", "code": "50323425" },
      { "name": "Dried dauphine grapes", "code": "50323426" },
      { "name": "Dried delaware grapes", "code": "50323427" },
      { "name": "Dried early muscat grapes", "code": "50323428" },
      { "name": "Dried early sweet grapes", "code": "50323429" },
      { "name": "Dried emerald seedless grapes", "code": "50323430" },
      { "name": "Dried emperatriz grapes", "code": "50323431" },
      { "name": "Dried emperor grapes", "code": "50323432" },
      { "name": "Dried empress grapes", "code": "50323433" },
      { "name": "Dried exotic grapes", "code": "50323434" },
      { "name": "Dried fantasy grapes", "code": "50323435" },
      { "name": "Dried fantasy seedless grapes", "code": "50323436" },
      { "name": "Dried flame grapes", "code": "50323437" },
      { "name": "Dried flame seedless grapes", "code": "50323438" },
      { "name": "Dried flame tokay grapes", "code": "50323439" },
      { "name": "Dried flaming red grapes", "code": "50323440" },
      { "name": "Dried galaxy seedless grapes", "code": "50323441" },
      { "name": "Dried gamay grapes", "code": "50323442" },
      { "name": "Dried gold grapes", "code": "50323443" },
      { "name": "Dried hanepoot or honeypot grapes", "code": "50323444" },
      { "name": "Dried italia grapes", "code": "50323445" },
      { "name": "Dried jade seedless grapes", "code": "50323446" },
      { "name": "Dried jubilee grapes", "code": "50323447" },
      { "name": "Dried king ruby grapes", "code": "50323448" },
      { "name": "Dried kyoho grapes", "code": "50323449" },
      { "name": "Dried la rochelle grapes", "code": "50323450" },
      { "name": "Dried lady finger grapes", "code": "50323451" },
      { "name": "Dried late seedless grapes", "code": "50323452" },
      { "name": "Dried majestic seedless grapes", "code": "50323453" },
      { "name": "Dried malaga grapes", "code": "50323454" },
      { "name": "Dried marroo seedless grapes", "code": "50323455" },
      { "name": "Dried muscadine grapes", "code": "50323456" },
      { "name": "Dried muscat flame grapes", "code": "50323457" },
      { "name": "Dried muscat grapes", "code": "50323458" },
      { "name": "Dried muscat seedless grapes", "code": "50323459" },
      { "name": "Dried napoleon grapes", "code": "50323460" },
      { "name": "Dried negria grapes", "code": "50323461" },
      { "name": "Dried new cross grapes", "code": "50323462" },
      { "name": "Dried niabell grapes", "code": "50323463" },
      { "name": "Dried niagara grapes", "code": "50323464" },
      { "name": "Dried olivette grapes", "code": "50323465" },
      { "name": "Dried perlette grapes", "code": "50323466" },
      { "name": "Dried perlon grapes", "code": "50323467" },
      { "name": "Dried prima black seedless grapes", "code": "50323468" },
      { "name": "Dried princess grapes", "code": "50323469" },
      { "name": "Dried queen grapes", "code": "50323470" },
      { "name": "Dried red blush grapes", "code": "50323471" },
      { "name": "Dried red globe grapes", "code": "50323472" },
      { "name": "Dried red malaga grapes", "code": "50323473" },
      { "name": "Dried red seedless grapes", "code": "50323474" },
      { "name": "Dried regina grapes", "code": "50323475" },
      { "name": "Dried ribier grapes", "code": "50323476" },
      { "name": "Dried rosita grapes", "code": "50323477" },
      { "name": "Dried rouge grapes", "code": "50323478" },
      { "name": "Dried royal black seedless grapes", "code": "50323479" },
      { "name": "Dried ruby red seedless grapes", "code": "50323480" },
      { "name": "Dried ruby seedless grapes", "code": "50323481" },
      { "name": "Dried scarlet royal grapes", "code": "50323482" },
      { "name": "Dried scuppernong grapes", "code": "50323483" },
      { "name": "Dried sugarose grapes", "code": "50323484" },
      { "name": "Dried sugarthirteen grapes", "code": "50323485" },
      { "name": "Dried sugraone grapes", "code": "50323486" },
      { "name": "Dried sugrasixteen grapes", "code": "50323487" },
      { "name": "Dried sultana sun red grapes", "code": "50323488" },
      { "name": "Dried summer royal grapes", "code": "50323489" },
      { "name": "Dried sunset grapes", "code": "50323490" },
      { "name": "Dried superior seedless grapes", "code": "50323491" },
      { "name": "Dried thompson seedless grapes", "code": "50323492" },
      { "name": "Dried tokay/pinot gris grapes", "code": "50323493" },
      { "name": "Dried waltman cross grapes", "code": "50323494" },
      { "name": "Dried white seedless grapes", "code": "50323495" },
      { "name": "Dried zante current grapes", "code": "50323496" },
      { "name": "Dried raisin grapes", "code": "50323500" },
      { "name": "Dried black corinth grapes", "code": "50323501" },
      { "name": "Dried canner grapes", "code": "50323502" },
      { "name": "Dried dovine grapes", "code": "50323503" },
      { "name": "Dried fiesta grapes", "code": "50323504" },
      { "name": "Dried selma pete grapes", "code": "50323505" },
      { "name": "Dried sultana grapes", "code": "50323506" },
      { "name": "Dried wine grapes", "code": "50323600" },
      { "name": "Dried alicante bouschet grapes", "code": "50323601" },
      { "name": "Dried barbera grapes", "code": "50323602" },
      { "name": "Dried burger grapes", "code": "50323603" },
      { "name": "Dried cabernet franc grapes", "code": "50323604" },
      { "name": "Dried cabernet sauvignon grapes", "code": "50323605" },
      { "name": "Dried carignane grapes", "code": "50323606" },
      { "name": "Dried carnelian grapes", "code": "50323607" },
      { "name": "Dried catarratto grapes", "code": "50323608" },
      { "name": "Dried centurian grapes", "code": "50323609" },
      { "name": "Dried charbono grapes", "code": "50323610" },
      { "name": "Dried chardonnay grapes", "code": "50323611" },
      { "name": "Dried chenin blanc grapes", "code": "50323612" },
      { "name": "Dried cinsaut grapes", "code": "50323613" },
      { "name": "Dried dolcetto grapes", "code": "50323614" },
      { "name": "Dried emerald riesling grapes", "code": "50323615" },
      { "name": "Dried french colombard grapes", "code": "50323616" },
      { "name": "Dried gamay napa grapes", "code": "50323617" },
      { "name": "Dried gamay beaujolais grapes", "code": "50323618" },
      { "name": "Dried gewurztraminer grapes", "code": "50323619" },
      { "name": "Dried grenache grapes", "code": "50323620" },
      { "name": "Dried grenache blanc grapes", "code": "50323621" },
      { "name": "Dried lagrein grapes", "code": "50323622" },
      { "name": "Dried lambrusco grapes", "code": "50323623" },
      { "name": "Dried malbec grapes", "code": "50323624" },
      { "name": "Dried malvasia bianca grapes", "code": "50323625" },
      { "name": "Dried marsanne grapes", "code": "50323626" },
      { "name": "Dried mataro grapes", "code": "50323627" },
      { "name": "Dried merlot grapes", "code": "50323628" },
      { "name": "Dried meunier grapes", "code": "50323629" },
      { "name": "Dried mission grapes", "code": "50323630" },
      { "name": "Dried montepulciano grapes", "code": "50323631" },
      { "name": "Dried muscat blanc grapes", "code": "50323632" },
      { "name": "Dried muscat hamburg grapes", "code": "50323633" },
      { "name": "Dried muscat of alexandria grapes", "code": "50323634" },
      { "name": "Dried muscat orange grapes", "code": "50323635" },
      { "name": "Dried nebbiolo grapes", "code": "50323636" },
      { "name": "Dried palomino grapes", "code": "50323637" },
      { "name": "Dried petit verdot grapes", "code": "50323638" },
      { "name": "Dried petite sirah grapes", "code": "50323639" },
      { "name": "Dried pinot blanc grapes", "code": "50323640" },
      { "name": "Dried pinot gris grapes", "code": "50323641" },
      { "name": "Dried pinot noir grapes", "code": "50323642" },
      { "name": "Dried primitivo grapes", "code": "50323643" },
      { "name": "Dried roussanne grapes", "code": "50323644" },
      { "name": "Dried royalty grapes", "code": "50323645" },
      { "name": "Dried rubired grapes", "code": "50323646" },
      { "name": "Dried ruby cabernet grapes", "code": "50323647" },
      { "name": "Dried salvador grapes", "code": "50323648" },
      { "name": "Dried sangiovese grapes", "code": "50323649" },
      { "name": "Dried sauvignon blanc grapes", "code": "50323650" },
      { "name": "Dried sauvignon musque grapes", "code": "50323651" },
      { "name": "Dried semillon grapes", "code": "50323652" },
      { "name": "Dried souzao grapes", "code": "50323653" },
      { "name": "Dried st emilion grapes", "code": "50323654" },
      { "name": "Dried symphony grapes", "code": "50323655" },
      { "name": "Dried syrah grapes", "code": "50323656" },
      { "name": "Dried tannat grapes", "code": "50323657" },
      { "name": "Dried tempranillo grapes", "code": "50323658" },
      { "name": "Dried teroldego grapes", "code": "50323659" },
      { "name": "Dried tocai friulano grapes", "code": "50323660" },
      { "name": "Dried touriga nacional grapes", "code": "50323661" },
      { "name": "Dried triplett blanc grapes", "code": "50323662" },
      { "name": "Dried viognier grapes", "code": "50323663" },
      { "name": "Dried white riesling grapes", "code": "50323664" },
      { "name": "Dried zinfandel grapes", "code": "50323665" },
      { "name": "Dried guavas", "code": "50323700" },
      { "name": "Dried beaumont guavas", "code": "50323701" },
      { "name": "Dried carrley guavas", "code": "50323702" },
      { "name": "Dried lucida guavas", "code": "50323703" },
      { "name": "Dried pineapple guava", "code": "50323704" },
      { "name": "Dried huckleberries", "code": "50323800" },
      { "name": "Dried black winter huckleberries", "code": "50323801" },
      { "name": "Dried cascade huckleberries", "code": "50323802" },
      { "name": "Dried dwarf huckleberries", "code": "50323803" },
      { "name": "Dried mountain huckleberries", "code": "50323804" },
      { "name": "Dried red huckleberries", "code": "50323805" },
      { "name": "Dried kiwi fruit", "code": "50323900" },
      { "name": "Dried ananasnaja kiwi fruit", "code": "50323901" },
      { "name": "Dried arctic beauty kiwi fruit", "code": "50323902" },
      { "name": "Dried blake kiwi fruit", "code": "50323903" },
      { "name": "Dried hayward kiwi fruit", "code": "50323904" },
      { "name": "Dried issai kiwi fruit", "code": "50323905" },
      { "name": "Dried siberian kiwi fruit", "code": "50323906" },
      { "name": "Dried kumquats", "code": "50324000" },
      { "name": "Dried hong kong kumquats", "code": "50324001" },
      { "name": "Dried limequat kumquats", "code": "50324002" },
      { "name": "Dried long fruit kumquats", "code": "50324003" },
      { "name": "Dried malayan kumquats", "code": "50324004" },
      { "name": "Dried meiwa kumquats", "code": "50324005" },
      { "name": "Dried nagami kumquats", "code": "50324006" },
      { "name": "Dried lemons", "code": "50324100" },
      { "name": "Dried baboon lemons", "code": "50324101" },
      { "name": "Dried bearss sicilian lemons", "code": "50324102" },
      { "name": "Dried cameron highlands lemons", "code": "50324103" },
      { "name": "Dried escondido lemons", "code": "50324104" },
      { "name": "Dried eureka lemons", "code": "50324105" },
      { "name": "Dried lisbon lemons", "code": "50324106" },
      { "name": "Dried meyer lemons", "code": "50324107" },
      { "name": "Dried volkamer lemons", "code": "50324108" },
      { "name": "Dried limes", "code": "50324200" },
      { "name": "Dried indian sweet limes", "code": "50324201" },
      { "name": "Dried key limes", "code": "50324202" },
      { "name": "Dried mandarin limes", "code": "50324203" },
      { "name": "Dried philippine limes", "code": "50324204" },
      { "name": "Dried tahitian limes", "code": "50324205" },
      { "name": "Dried bearss limes", "code": "50324206" },
      { "name": "Dried persian limes", "code": "50324207" },
      { "name": "Dried seedless limes", "code": "50324208" },
      { "name": "Dried loquats", "code": "50324300" },
      { "name": "Dried advance loquats", "code": "50324301" },
      { "name": "Dried benlehr loquats", "code": "50324302" },
      { "name": "Dried big jim loquats", "code": "50324303" },
      { "name": "Dried champagne loquats", "code": "50324304" },
      { "name": "Dried early red loquats", "code": "50324305" },
      { "name": "Dried gold nugget loquats", "code": "50324306" },
      { "name": "Dried herd's mammoth loquats", "code": "50324307" },
      { "name": "Dried mogi loquats", "code": "50324308" },
      { "name": "Dried mrs cooksey loquats", "code": "50324309" },
      { "name": "Dried strawberry loquats", "code": "50324310" },
      { "name": "Dried tanaka loquats", "code": "50324311" },
      { "name": "Dried victory vista white loquats", "code": "50324312" },
      { "name": "Dried wolfe loquats", "code": "50324313" },
      { "name": "Dried mandarin oranges or tangerines", "code": "50324400" },
      { "name": "Dried clauselinas oranges", "code": "50324401" },
      { "name": "Dried clementine tangerines", "code": "50324402" },
      { "name": "Dried cleopatra mandarin oranges", "code": "50324403" },
      { "name": "Dried dancy tangerines", "code": "50324404" },
      { "name": "Dried ellensdale oranges", "code": "50324405" },
      { "name": "Dried fairchild oranges", "code": "50324406" },
      { "name": "Dried fallglo oranges", "code": "50324407" },
      { "name": "Dried fortune oranges", "code": "50324408" },
      { "name": "Dried fremont mandarin oranges", "code": "50324409" },
      { "name": "Dried fremont oranges", "code": "50324410" },
      { "name": "Dried golden nugget oranges", "code": "50324411" },
      { "name": "Dried honey mandarin oranges", "code": "50324412" },
      { "name": "Dried honey oranges", "code": "50324413" },
      { "name": "Dried honey tangerines", "code": "50324414" },
      { "name": "Dried honeybelle tangelo oranges", "code": "50324415" },
      { "name": "Dried king mandarin oranges", "code": "50324416" },
      { "name": "Dried kinnow oranges", "code": "50324417" },
      { "name": "Dried lee mandarin oranges", "code": "50324418" },
      { "name": "Dried makokkee oranges", "code": "50324419" },
      { "name": "Dried malvasios oranges", "code": "50324420" },
      { "name": "Dried mediterranean mandarin oranges", "code": "50324421" },
      { "name": "Dried minneola tangelo oranges", "code": "50324422" },
      { "name": "Dried monica oranges", "code": "50324423" },
      { "name": "Dried murcott honey oranges", "code": "50324424" },
      { "name": "Dried murcott tangors", "code": "50324425" },
      { "name": "Dried natsudaidai mandarin oranges", "code": "50324426" },
      { "name": "Dried natsumikan mandarin oranges", "code": "50324427" },
      { "name": "Dried nocatee tangelo oranges", "code": "50324428" },
      { "name": "Dried orlando tangelo oranges", "code": "50324429" },
      { "name": "Dried ortanique tangerines", "code": "50324430" },
      { "name": "Dried page mandarin oranges", "code": "50324431" },
      { "name": "Dried pixie oranges", "code": "50324432" },
      { "name": "Dried ponkan bantangas mandarin oranges", "code": "50324433" },
      { "name": "Dried reyna oranges", "code": "50324434" },
      { "name": "Dried robinson oranges", "code": "50324435" },
      { "name": "Dried saltenitas oranges", "code": "50324436" },
      { "name": "Dried sampson tangelo oranges", "code": "50324437" },
      { "name": "Dried satsuma mandarin oranges", "code": "50324438" },
      { "name": "Dried sunburst mandarin oranges", "code": "50324439" },
      { "name": "Dried tangelos", "code": "50324440" },
      { "name": "Dried tangerina oranges", "code": "50324441" },
      { "name": "Dried temple oranges", "code": "50324442" },
      { "name": "Dried thornton oranges", "code": "50324443" },
      { "name": "Dried wekiwa tangerines", "code": "50324444" },
      { "name": "Dried wilkins tangerines", "code": "50324445" },
      {
        "name": "Dried willowleaf mediterranean tangerines",
        "code": "50324446"
      },
      { "name": "Dried mangoes", "code": "50324500" },
      { "name": "Dried alphonso mangoes", "code": "50324501" },
      { "name": "Dried ataulfo mangoes", "code": "50324502" },
      { "name": "Dried criollo mangoes", "code": "50324503" },
      { "name": "Dried edwards mangoes", "code": "50324504" },
      { "name": "Dried francine mangoes", "code": "50324505" },
      { "name": "Dried francis mangoes", "code": "50324506" },
      { "name": "Dried gandaria mangoes", "code": "50324507" },
      { "name": "Dried haden mangoes", "code": "50324508" },
      { "name": "Dried irwin mangoes", "code": "50324509" },
      { "name": "Dried keitt mangoes", "code": "50324510" },
      { "name": "Dried kent mangoes", "code": "50324511" },
      { "name": "Dried kesar mangoes", "code": "50324512" },
      { "name": "Dried kuini mangoes", "code": "50324513" },
      { "name": "Dried manila super mangoes", "code": "50324514" },
      { "name": "Dried manila mangoes", "code": "50324515" },
      { "name": "Dried mayaguez mangoes", "code": "50324516" },
      { "name": "Dried mulgoba mangoes", "code": "50324517" },
      { "name": "Dried oro mangoes", "code": "50324518" },
      { "name": "Dried palmer mangoes", "code": "50324519" },
      { "name": "Dried parvin mangoes", "code": "50324520" },
      { "name": "Dried sandersha mangoes", "code": "50324521" },
      { "name": "Dried sensation mangoes", "code": "50324522" },
      { "name": "Dried smith mangoes", "code": "50324523" },
      { "name": "Dried tommy atkins mangoes", "code": "50324524" },
      { "name": "Dried van dyke mangoes", "code": "50324525" },
      { "name": "Dried melons", "code": "50324600" },
      { "name": "Dried allsweet melons", "code": "50324601" },
      { "name": "Dried athena melons", "code": "50324602" },
      { "name": "Dried black diamond melons", "code": "50324603" },
      { "name": "Dried cal sweet melons", "code": "50324604" },
      { "name": "Dried carnical melons", "code": "50324605" },
      { "name": "Dried cantaloupe melons", "code": "50324606" },
      { "name": "Dried casaba melons", "code": "50324607" },
      { "name": "Dried cavaillon melons", "code": "50324608" },
      { "name": "Dried charentais melons", "code": "50324609" },
      { "name": "Dried charleston gray watermelon", "code": "50324610" },
      { "name": "Dried crenshaw melons", "code": "50324611" },
      { "name": "Dried crimson sweet melons", "code": "50324612" },
      { "name": "Dried dixie lee melons", "code": "50324613" },
      { "name": "Dried eclipse melons", "code": "50324614" },
      { "name": "Dried ein d'or melons", "code": "50324615" },
      { "name": "Dried fiesta melons", "code": "50324616" },
      { "name": "Dried galia melons", "code": "50324617" },
      { "name": "Dried gaya melons", "code": "50324618" },
      { "name": "Dried hami melons", "code": "50324619" },
      { "name": "Dried honeydew melons", "code": "50324620" },
      { "name": "Dried icebox melons", "code": "50324621" },
      { "name": "Dried ida pride melons", "code": "50324622" },
      { "name": "Dried juan canary melons", "code": "50324623" },
      { "name": "Dried jubilee melons", "code": "50324624" },
      { "name": "Dried jubilation melons", "code": "50324625" },
      { "name": "Dried kakhi/kakri melons", "code": "50324626" },
      { "name": "Dried kiwano melons", "code": "50324627" },
      { "name": "Dried korean melons", "code": "50324628" },
      { "name": "Dried long gray melons", "code": "50324629" },
      { "name": "Dried mayan melons", "code": "50324630" },
      { "name": "Dried micky lee melons", "code": "50324631" },
      { "name": "Dried mirage melons", "code": "50324632" },
      { "name": "Dried moon and stars watermelon", "code": "50324633" },
      { "name": "Dried ogen melons", "code": "50324634" },
      { "name": "Dried patriot melons", "code": "50324635" },
      { "name": "Dried peacock melons", "code": "50324636" },
      { "name": "Dried pepino melons", "code": "50324637" },
      { "name": "Dried persian melons", "code": "50324638" },
      { "name": "Dried picnic melons", "code": "50324639" },
      { "name": "Dried piel de sapo melons", "code": "50324640" },
      { "name": "Dried pineapple melons", "code": "50324641" },
      { "name": "Dried quetzali melons", "code": "50324642" },
      { "name": "Dried red goblin melons", "code": "50324643" },
      { "name": "Dried regency melons", "code": "50324644" },
      { "name": "Dried royal majestic melons", "code": "50324645" },
      { "name": "Dried royal star melons", "code": "50324646" },
      { "name": "Dried royal sweet melons", "code": "50324647" },
      { "name": "Dried santa claus melons", "code": "50324648" },
      { "name": "Dried sharlyn melons", "code": "50324649" },
      { "name": "Dried spanish melons", "code": "50324650" },
      { "name": "Dried sprite melons", "code": "50324651" },
      { "name": "Dried starbright melons", "code": "50324652" },
      { "name": "Dried stars n stripes melons", "code": "50324653" },
      { "name": "Dried sugar baby melons", "code": "50324654" },
      { "name": "Dried sugar baby watermelon", "code": "50324655" },
      { "name": "Dried sunsweet melons", "code": "50324656" },
      { "name": "Dried sweet heart seedless watermelon", "code": "50324657" },
      { "name": "Dried temptation melons", "code": "50324658" },
      { "name": "Dried tiger baby melons", "code": "50324659" },
      { "name": "Dried tuscan type melons", "code": "50324660" },
      { "name": "Dried yellow baby watermelon", "code": "50324661" },
      { "name": "Dried mulberries", "code": "50324700" },
      { "name": "Dried black mulberries", "code": "50324701" },
      { "name": "Dried white mulberries", "code": "50324702" },
      { "name": "Dried myrtle", "code": "50324800" },
      { "name": "Dried bog myrtle", "code": "50324801" },
      { "name": "Dried bayberries", "code": "50324802" },
      { "name": "Dried nectarines", "code": "50324900" },
      { "name": "Dried april glo nectarines", "code": "50324901" },
      { "name": "Dried arctic mist nectarines", "code": "50324902" },
      { "name": "Dried arctic snow nectarines", "code": "50324903" },
      { "name": "Dried arctic star nectarines", "code": "50324904" },
      { "name": "Dried arctic sweet nectarines", "code": "50324905" },
      { "name": "Dried arctic glo nectarines", "code": "50324906" },
      { "name": "Dried august fire nectarines", "code": "50324907" },
      { "name": "Dried august pearl nectarines", "code": "50324908" },
      { "name": "Dried august red nectarines", "code": "50324909" },
      { "name": "Dried autumn star nectarines", "code": "50324910" },
      { "name": "Dried big john nectarines", "code": "50324911" },
      { "name": "Dried bright pearl nectarines", "code": "50324912" },
      { "name": "Dried diamond bright nectarines", "code": "50324913" },
      { "name": "Dried diamond ray nectarines", "code": "50324914" },
      { "name": "Dried earliglo nectarines", "code": "50324915" },
      { "name": "Dried early diamond nectarines", "code": "50324916" },
      { "name": "Dried fairlane nectarines", "code": "50324917" },
      { "name": "Dried fantasia nectarines", "code": "50324918" },
      { "name": "Dried fire pearl nectarines", "code": "50324919" },
      { "name": "Dried fire sweet nectarines", "code": "50324920" },
      { "name": "Dried flamekist nectarines", "code": "50324921" },
      { "name": "Dried flat type nectarines", "code": "50324922" },
      { "name": "Dried garden delight nectarines", "code": "50324923" },
      { "name": "Dried goldmine nectarines", "code": "50324924" },
      { "name": "Dried grand pearl nectarines", "code": "50324925" },
      { "name": "Dried hardired nectarines", "code": "50324926" },
      { "name": "Dried honey blaze nectarines", "code": "50324927" },
      { "name": "Dried july red nectarines", "code": "50324928" },
      { "name": "Dried kay pearl nectarines", "code": "50324929" },
      { "name": "Dried kay sweet nectarines", "code": "50324930" },
      { "name": "Dried may diamond nectarines", "code": "50324931" },
      { "name": "Dried mayfire nectarines", "code": "50324932" },
      { "name": "Dried mayglo nectarines", "code": "50324933" },
      { "name": "Dried mericrest nectarines", "code": "50324934" },
      { "name": "Dried red diamond nectarines", "code": "50324935" },
      { "name": "Dried red gold nectarines", "code": "50324936" },
      { "name": "Dried red jim nectarines", "code": "50324937" },
      { "name": "Dried red roy nectarines", "code": "50324938" },
      { "name": "Dried rio red nectarines", "code": "50324939" },
      { "name": "Dried rose diamond nectarines", "code": "50324940" },
      { "name": "Dried royal glo nectarines", "code": "50324941" },
      { "name": "Dried ruby diamond nectarines", "code": "50324942" },
      { "name": "Dried ruby sweet nectarines", "code": "50324943" },
      { "name": "Dried ruddy jewel nectarines", "code": "50324944" },
      { "name": "Dried september red nectarines", "code": "50324945" },
      { "name": "Dried snowqueen nectarines", "code": "50324946" },
      { "name": "Dried spring bright nectarines", "code": "50324947" },
      { "name": "Dried spring red nectarines", "code": "50324948" },
      { "name": "Dried summer blush nectarines", "code": "50324949" },
      { "name": "Dried summer brite nectarines", "code": "50324950" },
      { "name": "Dried summer diamond nectarines", "code": "50324951" },
      { "name": "Dried summer fire nectarines", "code": "50324952" },
      { "name": "Dried summer grand nectarines", "code": "50324953" },
      { "name": "Dried sunglo nectarines", "code": "50324954" },
      { "name": "Dried zee fire nectarines", "code": "50324955" },
      { "name": "Dried zee glo nectarines", "code": "50324956" },
      { "name": "Dried zeegrand nectarines", "code": "50324957" },
      { "name": "Dried oranges", "code": "50325000" },
      { "name": "Dried african sour oranges", "code": "50325001" },
      { "name": "Dried ambersweet oranges", "code": "50325002" },
      { "name": "Dried argentine sour oranges", "code": "50325003" },
      { "name": "Dried bahianinha oranges", "code": "50325004" },
      { "name": "Dried bergamot oranges", "code": "50325005" },
      { "name": "Dried berna oranges", "code": "50325006" },
      { "name": "Dried bigaradier apepu oranges", "code": "50325007" },
      { "name": "Dried bittersweet daidai oranges", "code": "50325008" },
      { "name": "Dried blonde oranges", "code": "50325009" },
      { "name": "Dried blood oranges", "code": "50325010" },
      { "name": "Dried california navel oranges", "code": "50325011" },
      { "name": "Dried cara cara oranges", "code": "50325012" },
      { "name": "Dried chinotto oranges", "code": "50325013" },
      { "name": "Dried dream navel oranges", "code": "50325014" },
      { "name": "Dried gou tou oranges", "code": "50325015" },
      { "name": "Dried hamlin oranges", "code": "50325016" },
      { "name": "Dried jaffa oranges", "code": "50325017" },
      { "name": "Dried jincheng oranges", "code": "50325018" },
      { "name": "Dried k-early oranges", "code": "50325019" },
      { "name": "Dried kona oranges", "code": "50325020" },
      { "name": "Dried late navel oranges", "code": "50325021" },
      { "name": "Dried late valencia oranges", "code": "50325022" },
      { "name": "Dried limequat oranges", "code": "50325023" },
      { "name": "Dried marr oranges", "code": "50325024" },
      { "name": "Dried melogold oranges", "code": "50325025" },
      { "name": "Dried moro oranges", "code": "50325026" },
      { "name": "Dried moro tarocco oranges", "code": "50325027" },
      { "name": "Dried navel oranges", "code": "50325028" },
      { "name": "Dried navelina oranges", "code": "50325029" },
      { "name": "Dried oro blanco oranges", "code": "50325030" },
      { "name": "Dried osceola oranges", "code": "50325031" },
      { "name": "Dried parson brown oranges", "code": "50325032" },
      { "name": "Dried pera oranges", "code": "50325033" },
      { "name": "Dried pummulo oranges", "code": "50325034" },
      { "name": "Dried rhode red oranges", "code": "50325035" },
      { "name": "Dried roble oranges", "code": "50325036" },
      { "name": "Dried salustianas oranges", "code": "50325037" },
      { "name": "Dried sanguine oranges", "code": "50325038" },
      { "name": "Dried sanguinelli oranges", "code": "50325039" },
      { "name": "Dried seville oranges", "code": "50325040" },
      { "name": "Dried shamouti jaffa oranges", "code": "50325041" },
      { "name": "Dried tunis oranges", "code": "50325042" },
      { "name": "Dried valencia oranges", "code": "50325043" },
      { "name": "Dried washington navel oranges", "code": "50325044" },
      { "name": "Dried papayas", "code": "50325100" },
      { "name": "Dried green cooking papayas", "code": "50325101" },
      { "name": "Dried maradol papayas", "code": "50325102" },
      { "name": "Dried mexican yellow papayas", "code": "50325103" },
      { "name": "Dried mountain papayas", "code": "50325104" },
      { "name": "Dried solo papayas", "code": "50325105" },
      { "name": "Dried tainung papayas", "code": "50325106" },
      { "name": "Dried passion fruit", "code": "50325200" },
      { "name": "Dried banana passion fruit", "code": "50325201" },
      { "name": "Dried blue passion flower", "code": "50325202" },
      { "name": "Dried crackerjack passion fruit", "code": "50325203" },
      { "name": "Dried giant granadilla passion fruit", "code": "50325204" },
      { "name": "Dried golden granadilla passion fruit", "code": "50325205" },
      { "name": "Dried maypops passion fruit", "code": "50325206" },
      { "name": "Dried red granadilla passion fruit", "code": "50325207" },
      { "name": "Dried sweet granadilla passion fruit", "code": "50325208" },
      { "name": "Dried water lemon passion fruit", "code": "50325209" },
      { "name": "Dried wing-stemmed passion flower", "code": "50325210" },
      { "name": "Dried peaches", "code": "50325300" },
      { "name": "Dried amber crest peaches", "code": "50325301" },
      { "name": "Dried april snow peaches", "code": "50325302" },
      { "name": "Dried august lady peaches", "code": "50325303" },
      { "name": "Dried autumn flame peaches", "code": "50325304" },
      { "name": "Dried autumn lady peaches", "code": "50325305" },
      { "name": "Dried babcock peaches", "code": "50325306" },
      { "name": "Dried brittney lane peaches", "code": "50325307" },
      { "name": "Dried cary mac peaches", "code": "50325308" },
      { "name": "Dried classic peaches", "code": "50325309" },
      { "name": "Dried country sweet peaches", "code": "50325310" },
      { "name": "Dried crest haven peaches", "code": "50325311" },
      { "name": "Dried crimson lady peaches", "code": "50325312" },
      { "name": "Dried crown princess peaches", "code": "50325313" },
      { "name": "Dried david sun peaches", "code": "50325314" },
      { "name": "Dried diamond princess peaches", "code": "50325315" },
      { "name": "Dried earlirich peaches", "code": "50325316" },
      { "name": "Dried early majestic peaches", "code": "50325317" },
      { "name": "Dried early treat peaches", "code": "50325318" },
      { "name": "Dried elegant lady peaches", "code": "50325319" },
      { "name": "Dried empress peaches", "code": "50325320" },
      { "name": "Dried encore peaches", "code": "50325321" },
      { "name": "Dried fancy lady peaches", "code": "50325322" },
      { "name": "Dried fire prince peaches", "code": "50325323" },
      { "name": "Dried flame crest peaches", "code": "50325324" },
      { "name": "Dried flat type peaches", "code": "50325325" },
      { "name": "Dried flavorcrest peaches", "code": "50325326" },
      { "name": "Dried florida prince peaches", "code": "50325327" },
      { "name": "Dried full moon peaches", "code": "50325328" },
      { "name": "Dried harvester peaches", "code": "50325329" },
      { "name": "Dried ice princess peaches", "code": "50325330" },
      { "name": "Dried ivory princess peaches", "code": "50325331" },
      { "name": "Dried jersey queen peaches", "code": "50325332" },
      { "name": "Dried john henry peaches", "code": "50325333" },
      { "name": "Dried june prince peaches", "code": "50325334" },
      { "name": "Dried kaweah peaches", "code": "50325335" },
      { "name": "Dried klondike peaches", "code": "50325336" },
      { "name": "Dried lindo peaches", "code": "50325337" },
      { "name": "Dried loring peaches", "code": "50325338" },
      { "name": "Dried majestic peaches", "code": "50325339" },
      { "name": "Dried o'henry peaches", "code": "50325340" },
      { "name": "Dried queencrest peaches", "code": "50325341" },
      { "name": "Dried red lady peaches", "code": "50325342" },
      { "name": "Dried redglobe peaches", "code": "50325343" },
      { "name": "Dried redhaven peaches", "code": "50325344" },
      { "name": "Dried redtop peaches", "code": "50325345" },
      { "name": "Dried regina peaches", "code": "50325346" },
      { "name": "Dried rich lady peaches", "code": "50325347" },
      { "name": "Dried rich may peaches", "code": "50325348" },
      { "name": "Dried royal glory peaches", "code": "50325349" },
      { "name": "Dried royal lady peaches", "code": "50325350" },
      { "name": "Dried september snow peaches", "code": "50325351" },
      { "name": "Dried september sun peaches", "code": "50325352" },
      { "name": "Dried sierra gem peaches", "code": "50325353" },
      { "name": "Dried snow angel peaches", "code": "50325354" },
      { "name": "Dried snow gem peaches", "code": "50325355" },
      { "name": "Dried snow king peaches", "code": "50325356" },
      { "name": "Dried spring lady peaches", "code": "50325357" },
      { "name": "Dried spring snow peaches", "code": "50325358" },
      { "name": "Dried springcrest peaches", "code": "50325359" },
      { "name": "Dried sugar giant peaches", "code": "50325360" },
      { "name": "Dried sugar lady peaches", "code": "50325361" },
      { "name": "Dried sun bright peaches", "code": "50325362" },
      { "name": "Dried sunhigh peaches", "code": "50325363" },
      { "name": "Dried super lady peaches", "code": "50325364" },
      { "name": "Dried super rich peaches", "code": "50325365" },
      { "name": "Dried surecrop peaches", "code": "50325366" },
      { "name": "Dried sweet dream peaches", "code": "50325367" },
      { "name": "Dried sweet september peaches", "code": "50325368" },
      { "name": "Dried vista peaches", "code": "50325369" },
      { "name": "Dried white lady peaches", "code": "50325370" },
      { "name": "Dried zee lady peaches", "code": "50325371" },
      { "name": "Dried pears", "code": "50325400" },
      { "name": "Dried abate fetel pears", "code": "50325401" },
      { "name": "Dried anjou pears", "code": "50325402" },
      { "name": "Dried asian pears", "code": "50325403" },
      { "name": "Dried bartlett pears", "code": "50325404" },
      { "name": "Dried best ever pears", "code": "50325405" },
      { "name": "Dried beth pears", "code": "50325406" },
      { "name": "Dried beurre pears", "code": "50325407" },
      { "name": "Dried bosc pears", "code": "50325408" },
      { "name": "Dried clapp favorite pears", "code": "50325409" },
      { "name": "Dried comice pears", "code": "50325410" },
      { "name": "Dried concorde pears", "code": "50325411" },
      { "name": "Dried conference pears", "code": "50325412" },
      { "name": "Dried crimson red pears", "code": "50325413" },
      { "name": "Dried d'anjou pears", "code": "50325414" },
      { "name": "Dried dr jules guyot pears", "code": "50325415" },
      { "name": "Dried early pears", "code": "50325416" },
      { "name": "Dried emperor brown pears", "code": "50325417" },
      { "name": "Dried forelle pears", "code": "50325418" },
      { "name": "Dried french butter pears", "code": "50325419" },
      { "name": "Dried glou morceau pears", "code": "50325420" },
      { "name": "Dried hosui pears", "code": "50325421" },
      { "name": "Dried italian butter pears", "code": "50325422" },
      { "name": "Dried jargonelle pears", "code": "50325423" },
      { "name": "Dried juno pears", "code": "50325424" },
      {
        "name": "Dried kaiserlouise bonne de jersey pears",
        "code": "50325425"
      },
      { "name": "Dried keiffer pears", "code": "50325426" },
      { "name": "Dried kings royal pears", "code": "50325427" },
      { "name": "Dried limonera pears", "code": "50325428" },
      { "name": "Dried merton pride pears", "code": "50325429" },
      { "name": "Dried mountain bartlett pears", "code": "50325430" },
      { "name": "Dried olivier de serres pears", "code": "50325431" },
      { "name": "Dried onward pears", "code": "50325432" },
      { "name": "Dried packham's triumph pears", "code": "50325433" },
      { "name": "Dried paraiso pears", "code": "50325434" },
      { "name": "Dried passe crasanne pears", "code": "50325435" },
      { "name": "Dried perry pears", "code": "50325436" },
      { "name": "Dried red bartlett pears", "code": "50325437" },
      { "name": "Dried red d'anjou pears", "code": "50325438" },
      { "name": "Dried rocha pears", "code": "50325439" },
      { "name": "Dried rosey red pears", "code": "50325440" },
      { "name": "Dried rosy red pears", "code": "50325441" },
      { "name": "Dried royal majestic pears", "code": "50325442" },
      { "name": "Dried ruby red pears", "code": "50325443" },
      { "name": "Dried santa maria pears", "code": "50325444" },
      { "name": "Dried seckel pears", "code": "50325445" },
      { "name": "Dried sensation pears", "code": "50325446" },
      { "name": "Dried star crimson pears", "code": "50325447" },
      { "name": "Dried stark crimson pears", "code": "50325448" },
      { "name": "Dried summer bartlett pears", "code": "50325449" },
      { "name": "Dried summer gold pears", "code": "50325450" },
      { "name": "Dried sun gold pears", "code": "50325451" },
      { "name": "Dried sunsprite pears", "code": "50325452" },
      { "name": "Dried taylors gold pears", "code": "50325453" },
      { "name": "Dried taylors red pears", "code": "50325454" },
      { "name": "Dried tientsin pears", "code": "50325455" },
      { "name": "Dried tosca pears", "code": "50325456" },
      { "name": "Dried warden pears", "code": "50325457" },
      { "name": "Dried williams bon chretien pears", "code": "50325458" },
      { "name": "Dried williams pears", "code": "50325459" },
      { "name": "Dried winter nelis pears", "code": "50325460" },
      { "name": "Dried persimmons", "code": "50325500" },
      { "name": "Dried american persimmon", "code": "50325501" },
      { "name": "Dried black sapote persimmon", "code": "50325502" },
      { "name": "Dried chapote/black persimmon", "code": "50325503" },
      { "name": "Dried date plum persimmon", "code": "50325504" },
      { "name": "Dried fuyu persimmons", "code": "50325505" },
      { "name": "Dried giant fuyu persimmons", "code": "50325506" },
      { "name": "Dried hachiya persimmons", "code": "50325507" },
      { "name": "Dried mabolo/butter fruit persimmon", "code": "50325508" },
      { "name": "Dried principe ito persimmons", "code": "50325509" },
      { "name": "Dried royal brillante persimmons", "code": "50325510" },
      { "name": "Dried sharon fruit persimmon", "code": "50325511" },
      { "name": "Dried triumph persimmons", "code": "50325512" },
      { "name": "Dried pineapples", "code": "50325600" },
      { "name": "Dried cherimoya pineapple", "code": "50325601" },
      { "name": "Dried golden pineapple", "code": "50325602" },
      { "name": "Dried hilo pineapple", "code": "50325603" },
      { "name": "Dried kona sugarloaf pineapple", "code": "50325604" },
      { "name": "Dried natal queen pineapple", "code": "50325605" },
      { "name": "Dried pernambuco pineapple", "code": "50325606" },
      { "name": "Dried red spanish pineapple", "code": "50325607" },
      { "name": "Dried smooth cayenne pineapple", "code": "50325608" },
      { "name": "Dried sugarloaf pineapple", "code": "50325609" },
      { "name": "Dried variegated pineapple", "code": "50325610" },
      { "name": "Dried plucots", "code": "50325700" },
      { "name": "Dried black kat plucot", "code": "50325701" },
      { "name": "Dried blue gusto plucot", "code": "50325702" },
      { "name": "Dried crimson heart plucot", "code": "50325703" },
      { "name": "Dried dapple dandy plucot", "code": "50325704" },
      { "name": "Dried dapple fire plucot", "code": "50325705" },
      { "name": "Dried early dapple plucot", "code": "50325706" },
      { "name": "Dried flavor fall plucot", "code": "50325707" },
      { "name": "Dried flavor gold plucot", "code": "50325708" },
      { "name": "Dried flavor grenade plucot", "code": "50325709" },
      { "name": "Dried flavor heart plucot", "code": "50325710" },
      { "name": "Dried flavor jewel plucot", "code": "50325711" },
      { "name": "Dried flavor king plucot", "code": "50325712" },
      { "name": "Dried flavor queen plucot", "code": "50325713" },
      { "name": "Dried flavor supreme plucot", "code": "50325714" },
      { "name": "Dried flavor treat plucot", "code": "50325715" },
      { "name": "Dried flavorella plucot", "code": "50325716" },
      { "name": "Dried flavorich plucot", "code": "50325717" },
      { "name": "Dried flavorosa plucot", "code": "50325718" },
      { "name": "Dried geo pride plucot", "code": "50325719" },
      { "name": "Dried red kat plucot", "code": "50325720" },
      { "name": "Dried royal treat plucot", "code": "50325721" },
      { "name": "Dried sierra rose plucot", "code": "50325722" },
      { "name": "Dried sweet geisha plucot", "code": "50325723" },
      { "name": "Dried plums", "code": "50325800" },
      { "name": "Dried amber jewel plums", "code": "50325801" },
      { "name": "Dried angeleno plums", "code": "50325802" },
      { "name": "Dried aurora plums", "code": "50325803" },
      { "name": "Dried autumn beaut plums", "code": "50325804" },
      { "name": "Dried autumn giant plums", "code": "50325805" },
      { "name": "Dried autumn pride plums", "code": "50325806" },
      { "name": "Dried autumn rosa plums", "code": "50325807" },
      { "name": "Dried beach plum", "code": "50325808" },
      { "name": "Dried betty anne plums", "code": "50325809" },
      { "name": "Dried black beaut plums", "code": "50325810" },
      { "name": "Dried black bullace plum", "code": "50325811" },
      { "name": "Dried black diamond plums", "code": "50325812" },
      { "name": "Dried black giant plums", "code": "50325813" },
      { "name": "Dried black ice plums", "code": "50325814" },
      { "name": "Dried black splendor plums", "code": "50325815" },
      { "name": "Dried blackamber plums", "code": "50325816" },
      { "name": "Dried burgundy plums", "code": "50325817" },
      { "name": "Dried carlsbad plum", "code": "50325818" },
      { "name": "Dried casselman plums", "code": "50325819" },
      { "name": "Dried catalina plums", "code": "50325820" },
      { "name": "Dried damson plum", "code": "50325821" },
      { "name": "Dried dolly plums", "code": "50325822" },
      { "name": "Dried earliqueen plums", "code": "50325823" },
      { "name": "Dried early rosa plums", "code": "50325824" },
      { "name": "Dried ebony may plums", "code": "50325825" },
      { "name": "Dried ebony plums", "code": "50325826" },
      { "name": "Dried elephant heart plums", "code": "50325827" },
      { "name": "Dried emerald beaut plums", "code": "50325828" },
      { "name": "Dried empress plums", "code": "50325829" },
      { "name": "Dried freedom plums", "code": "50325830" },
      { "name": "Dried friar plums", "code": "50325831" },
      { "name": "Dried gar red plums", "code": "50325832" },
      { "name": "Dried governor's plum", "code": "50325833" },
      { "name": "Dried grand rosa plums", "code": "50325834" },
      { "name": "Dried green gage plum", "code": "50325835" },
      { "name": "Dried greengage plums", "code": "50325836" },
      { "name": "Dried hiromi plums", "code": "50325837" },
      { "name": "Dried hiromi red plums", "code": "50325838" },
      { "name": "Dried holiday plums", "code": "50325839" },
      { "name": "Dried howard sun plums", "code": "50325840" },
      { "name": "Dried interspecific type plums", "code": "50325841" },
      { "name": "Dried jamaican plum", "code": "50325842" },
      { "name": "Dried joanna red plums", "code": "50325843" },
      { "name": "Dried kelsey plums", "code": "50325844" },
      { "name": "Dried king james plums", "code": "50325845" },
      { "name": "Dried laroda plums", "code": "50325846" },
      { "name": "Dried late rosa plums", "code": "50325847" },
      { "name": "Dried linda rosa plums", "code": "50325848" },
      { "name": "Dried lone star red plums", "code": "50325849" },
      { "name": "Dried mariposa plums", "code": "50325850" },
      { "name": "Dried marked black plums", "code": "50325851" },
      { "name": "Dried marked red plums", "code": "50325852" },
      { "name": "Dried mirabelle plum", "code": "50325853" },
      { "name": "Dried october sun plums", "code": "50325854" },
      { "name": "Dried owen t plums", "code": "50325855" },
      { "name": "Dried perdrigon plum", "code": "50325856" },
      { "name": "Dried pink delight plums", "code": "50325857" },
      { "name": "Dried president plums", "code": "50325858" },
      { "name": "Dried primetime plums", "code": "50325859" },
      { "name": "Dried purple majesty plums", "code": "50325860" },
      { "name": "Dried queen rosa plums", "code": "50325861" },
      { "name": "Dried quetsch plum", "code": "50325862" },
      { "name": "Dried red beaut plums", "code": "50325863" },
      { "name": "Dried red lane plums", "code": "50325864" },
      { "name": "Dried red ram plums", "code": "50325865" },
      { "name": "Dried red rosa plums", "code": "50325866" },
      { "name": "Dried rich red plums", "code": "50325867" },
      { "name": "Dried rosemary plums", "code": "50325868" },
      { "name": "Dried royal diamond plums", "code": "50325869" },
      { "name": "Dried royal red plums", "code": "50325870" },
      { "name": "Dried royal zee plums", "code": "50325871" },
      { "name": "Dried roysum plums", "code": "50325872" },
      { "name": "Dried santa rosa plums", "code": "50325873" },
      { "name": "Dried saphire plums", "code": "50325874" },
      { "name": "Dried sloe plum", "code": "50325875" },
      { "name": "Dried st catherine plum", "code": "50325876" },
      { "name": "Dried white bullace plum", "code": "50325877" },
      { "name": "Dried pomegranates", "code": "50325900" },
      { "name": "Dried foothill pomegranates", "code": "50325901" },
      { "name": "Dried granada pomegranates", "code": "50325902" },
      { "name": "Dried jolly red pomegranates", "code": "50325903" },
      { "name": "Dried nana pomegranates", "code": "50325904" },
      { "name": "Dried pat's red pomegranates", "code": "50325905" },
      { "name": "Dried pinkhan pomegranates", "code": "50325906" },
      { "name": "Dried purple velvet pomegranates", "code": "50325907" },
      { "name": "Dried wonderful pomegranates", "code": "50325908" },
      { "name": "Dried pomelos", "code": "50326000" },
      { "name": "Dried chandler pomelo", "code": "50326001" },
      { "name": "Dried hirado buntan pomelo", "code": "50326002" },
      { "name": "Dried liang ping yau pomelo", "code": "50326003" },
      { "name": "Dried pandan wangi pomelo", "code": "50326004" },
      { "name": "Dried pink pomelo", "code": "50326005" },
      { "name": "Dried red shaddock pomelo", "code": "50326006" },
      { "name": "Dried siamese sweet pomelo", "code": "50326007" },
      { "name": "Dried wainwright pomelo", "code": "50326008" },
      { "name": "Dried quinces", "code": "50326100" },
      { "name": "Dried champion quince", "code": "50326101" },
      { "name": "Dried pineapple quince", "code": "50326102" },
      { "name": "Dried smyrna quince", "code": "50326103" },
      { "name": "Dried raspberries", "code": "50326200" },
      { "name": "Dried american red raspberry", "code": "50326201" },
      { "name": "Dried bailey queensland raspberry", "code": "50326202" },
      { "name": "Dried black raspberry", "code": "50326203" },
      { "name": "Dried dark raspberry", "code": "50326204" },
      { "name": "Dried delicious raspberry", "code": "50326205" },
      { "name": "Dried focke dwarf raspberry", "code": "50326206" },
      { "name": "Dried focke grayleaf red raspberry", "code": "50326207" },
      { "name": "Dried focke strawberry raspberry", "code": "50326208" },
      { "name": "Dried focke yellow himalayan raspberry", "code": "50326209" },
      { "name": "Dried gold raspberry", "code": "50326210" },
      { "name": "Dried gray new mexico raspberry", "code": "50326211" },
      { "name": "Dried jepson whitebark raspberry", "code": "50326212" },
      { "name": "Dried kellogg san diego raspberry", "code": "50326213" },
      { "name": "Dried leucodermis whitebark raspberry", "code": "50326214" },
      { "name": "Dried munz cuyamaca raspberry", "code": "50326215" },
      { "name": "Dried peck barton's raspberry", "code": "50326216" },
      { "name": "Dried purpleflowering raspberry", "code": "50326217" },
      { "name": "Dried roadside raspberry", "code": "50326218" },
      { "name": "Dried san diego raspberry", "code": "50326219" },
      { "name": "Dried snow raspberry", "code": "50326220" },
      { "name": "Dried snowpeaks raspberry", "code": "50326221" },
      { "name": "Dried strawberryleaf raspberry", "code": "50326222" },
      { "name": "Dried sweet cultivated raspberry", "code": "50326223" },
      { "name": "Dried torr and gray whitebark raspberry", "code": "50326224" },
      { "name": "Dried west indian raspberry", "code": "50326225" },
      { "name": "Dried whitebark raspberry", "code": "50326226" },
      { "name": "Dried wine raspberry", "code": "50326227" },
      { "name": "Dried yellow himalayan raspberry", "code": "50326228" },
      { "name": "Dried yu-shan raspberry", "code": "50326229" },
      { "name": "Dried rhubarb", "code": "50326300" },
      { "name": "Dried crimson red rhubarb", "code": "50326301" },
      { "name": "Dried early champagne rhubarb", "code": "50326302" },
      { "name": "Dried glaskin's perpetual rhubarb", "code": "50326303" },
      { "name": "Dried sutton rhubarb", "code": "50326304" },
      { "name": "Dried timperley early rhubarb", "code": "50326305" },
      { "name": "Dried valentine rhubarb", "code": "50326306" },
      { "name": "Dried victoria rhubarb", "code": "50326307" },
      { "name": "Dried zwolle seedling rhubarb", "code": "50326308" },
      { "name": "Dried macdonald rhubarb", "code": "50326309" },
      { "name": "Dried tilden rhubarb", "code": "50326310" },
      { "name": "Dried rose hips", "code": "50326400" },
      { "name": "Dried brier rose hips", "code": "50326401" },
      { "name": "Dried elgantine rose hips", "code": "50326402" },
      { "name": "Dried rugosa rose hips", "code": "50326403" },
      { "name": "Dried scotch or burnet rose hips", "code": "50326404" },
      { "name": "Dried sapotes", "code": "50326500" },
      { "name": "Dried white sapotes", "code": "50326501" },
      { "name": "Dried black sapotes", "code": "50326502" },
      { "name": "Dried saskatoon berries", "code": "50326600" },
      { "name": "Dried honeywood saskatoon berries", "code": "50326601" },
      { "name": "Dried northline saskatoon berries", "code": "50326602" },
      { "name": "Dried smoky saskatoon berries", "code": "50326603" },
      { "name": "Dried thiessen saskatoon berries", "code": "50326604" },
      { "name": "Dried strawberries", "code": "50326700" },
      { "name": "Dried chandler strawberries", "code": "50326701" },
      { "name": "Dried june bearing strawberries", "code": "50326702" },
      { "name": "Dried ever bearing strawberries", "code": "50326703" },
      { "name": "Dried sugar apple", "code": "50326800" },
      { "name": "Dried kampong mauve sugar apple", "code": "50326801" },
      { "name": "Dried seedless sugar apple", "code": "50326802" },
      { "name": "Dried thai lessard sugar apple", "code": "50326803" },
      { "name": "Dried tamarillo", "code": "50326900" },
      { "name": "Dried amberlea gold tamarillo", "code": "50326901" },
      { "name": "Dried bold gold tamarillo", "code": "50326902" },
      { "name": "Dried goldmine tamarillo", "code": "50326903" },
      { "name": "Dried oratia red tamarillo", "code": "50326904" },
      { "name": "Dried red beau tamarillo", "code": "50326905" },
      { "name": "Dried red delight tamarillo", "code": "50326906" },
      { "name": "Dried nominant fruits", "code": "50327000" },
      { "name": "Dried akee", "code": "50327001" },
      { "name": "Dried babaco", "code": "50327002" },
      { "name": "Dried banana flowers", "code": "50327003" },
      { "name": "Dried baobab", "code": "50327004" },
      { "name": "Dried bitter oranges", "code": "50327005" },
      { "name": "Dried canistel", "code": "50327006" },
      { "name": "Dried coconuts", "code": "50327007" },
      { "name": "Dried cloudberries", "code": "50327008" },
      { "name": "Dried dewberries", "code": "50327009" },
      { "name": "Dried durian", "code": "50327010" },
      { "name": "Dried elderberries", "code": "50327011" },
      { "name": "Dried feijoa", "code": "50327012" },
      { "name": "Dried hackberries", "code": "50327013" },
      { "name": "Dried hawthorn", "code": "50327014" },
      { "name": "Dried honeyberries", "code": "50327015" },
      { "name": "Dried jackfruit", "code": "50327016" },
      { "name": "Dried jambolan", "code": "50327017" },
      { "name": "Dried jujube", "code": "50327018" },
      { "name": "Dried lychee", "code": "50327019" },
      { "name": "Dried mangosteens", "code": "50327020" },
      { "name": "Dried medlars", "code": "50327021" },
      { "name": "Dried mombins", "code": "50327022" },
      { "name": "Dried monstera", "code": "50327023" },
      { "name": "Dried pepinos", "code": "50327024" },
      { "name": "Dried plantains", "code": "50327025" },
      { "name": "Dried prickly pears", "code": "50327026" },
      { "name": "Dried quenepas", "code": "50327027" },
      { "name": "Dried rambutan", "code": "50327028" },
      { "name": "Dried rose apples", "code": "50327029" },
      { "name": "Dried roselle", "code": "50327030" },
      { "name": "Dried rowanberries", "code": "50327031" },
      { "name": "Dried sea buckhorn berries", "code": "50327032" },
      { "name": "Dried silverberries", "code": "50327033" },
      { "name": "Dried sorb berries", "code": "50327034" },
      { "name": "Dried soursops", "code": "50327035" },
      { "name": "Dried star apples", "code": "50327036" },
      { "name": "Dried tamarindo", "code": "50327037" },
      { "name": "Dried chokeberries", "code": "50327100" },
      { "name": "Dried autumn magic chokeberries", "code": "50327101" },
      { "name": "Dried brillantisima chokeberries", "code": "50327102" },
      { "name": "Dried nero chokeberries", "code": "50327103" },
      { "name": "Dried viking chokeberries", "code": "50327104" },
      { "name": "Dried olives", "code": "50327200" },
      { "name": "Dried agrinion olives", "code": "50327201" },
      { "name": "Dried aleppo olives", "code": "50327202" },
      { "name": "Dried alphonso olives", "code": "50327203" },
      { "name": "Dried amphissa olives", "code": "50327204" },
      { "name": "Dried arauco olives", "code": "50327205" },
      { "name": "Dried arbequina olives", "code": "50327206" },
      { "name": "Dried atalanta olives", "code": "50327207" },
      { "name": "Dried cerignola olives", "code": "50327208" },
      { "name": "Dried cracked provencal olives", "code": "50327209" },
      { "name": "Dried empeltre olives", "code": "50327210" },
      { "name": "Dried gaeta olives", "code": "50327211" },
      { "name": "Dried hondroelia olives", "code": "50327212" },
      { "name": "Dried kalamata olives", "code": "50327213" },
      { "name": "Dried kura olives", "code": "50327214" },
      { "name": "Dried ligurian olives", "code": "50327215" },
      { "name": "Dried lucque olives", "code": "50327216" },
      { "name": "Dried lugano olives", "code": "50327217" },
      { "name": "Dried manzanilla olives", "code": "50327218" },
      { "name": "Dried marche olives", "code": "50327219" },
      { "name": "Dried mission olives", "code": "50327220" },
      { "name": "Dried nafplion green olives", "code": "50327221" },
      { "name": "Dried nicoise olives", "code": "50327222" },
      { "name": "Dried nyons olives", "code": "50327223" },
      { "name": "Dried picholine olives", "code": "50327224" },
      { "name": "Dried ponentine olives", "code": "50327225" },
      { "name": "Dried royal olives", "code": "50327226" },
      { "name": "Dried seracena olives", "code": "50327227" },
      { "name": "Dried sevillano olives", "code": "50327228" },
      { "name": "Dried sicilian olives", "code": "50327229" },
      { "name": "Dried toscanelle olives", "code": "50327230" },
      { "name": "Dried organic fruit", "code": "50330000" },
      { "name": "Dried organic apples", "code": "50331500" },
      { "name": "Dried organic akane apples", "code": "50331501" },
      { "name": "Dried organic ambrosia apples", "code": "50331502" },
      { "name": "Dried organic api apples", "code": "50331503" },
      { "name": "Dried organic baldwin apples", "code": "50331504" },
      { "name": "Dried organic braeburn apples", "code": "50331505" },
      { "name": "Dried organic bramley apples", "code": "50331506" },
      { "name": "Dried organic bramley seedling apples", "code": "50331507" },
      {
        "name": "Dried organic calville blanche d'hiver apples",
        "code": "50331508"
      },
      { "name": "Dried organic cameo apples", "code": "50331509" },
      { "name": "Dried organic charles ross apples", "code": "50331510" },
      { "name": "Dried organic codlin apples", "code": "50331511" },
      { "name": "Dried organic cortland apples", "code": "50331512" },
      { "name": "Dried organic costard apples", "code": "50331513" },
      { "name": "Dried organic court pendu plat apples", "code": "50331514" },
      {
        "name": "Dried organic cox's orange pippin apples",
        "code": "50331515"
      },
      { "name": "Dried organic crab apples", "code": "50331516" },
      { "name": "Dried organic crispin apples", "code": "50331517" },
      { "name": "Dried organic delicious apples", "code": "50331518" },
      { "name": "Dried organic duchess apples", "code": "50331519" },
      { "name": "Dried organic earligold apples", "code": "50331520" },
      { "name": "Dried organic early mcintosh apples", "code": "50331521" },
      { "name": "Dried organic elstar apples", "code": "50331522" },
      { "name": "Dried organic empire apples", "code": "50331523" },
      { "name": "Dried organic flower of kent apples", "code": "50331524" },
      { "name": "Dried organic fuji apples", "code": "50331525" },
      { "name": "Dried organic gala apples", "code": "50331526" },
      { "name": "Dried organic gascoyne's scarlet apples", "code": "50331527" },
      { "name": "Dried organic gilliflower apples", "code": "50331528" },
      { "name": "Dried organic ginger gold apples", "code": "50331529" },
      { "name": "Dried organic gladstone apples", "code": "50331530" },
      { "name": "Dried organic gloster apples", "code": "50331531" },
      { "name": "Dried organic gold supreme apples", "code": "50331532" },
      { "name": "Dried organic golden delicious apples", "code": "50331533" },
      { "name": "Dried organic golden noble apples", "code": "50331534" },
      { "name": "Dried organic granny smith apples", "code": "50331535" },
      { "name": "Dried organic gravenstein apples", "code": "50331536" },
      { "name": "Dried organic greening apples", "code": "50331537" },
      { "name": "Dried organic greensleeves apples", "code": "50331538" },
      { "name": "Dried organic honeycrisp apples", "code": "50331539" },
      { "name": "Dried organic howgate wonder apples", "code": "50331540" },
      { "name": "Dried organic ida red apples", "code": "50331541" },
      { "name": "Dried organic james grieve apples", "code": "50331542" },
      { "name": "Dried organic jersey mac apples", "code": "50331543" },
      { "name": "Dried organic jester apples", "code": "50331544" },
      { "name": "Dried organic jonagold apples", "code": "50331545" },
      { "name": "Dried organic jonamac apples", "code": "50331546" },
      { "name": "Dried organic jonathan apples", "code": "50331547" },
      { "name": "Dried organic katy apples", "code": "50331548" },
      { "name": "Dried organic kidd's orange red apples", "code": "50331549" },
      { "name": "Dried organic lady apples", "code": "50331550" },
      { "name": "Dried organic law rome apples", "code": "50331551" },
      { "name": "Dried organic laxton apples", "code": "50331552" },
      { "name": "Dried organic lord derby apples", "code": "50331553" },
      { "name": "Dried organic macoun apples", "code": "50331554" },
      { "name": "Dried organic mcintosh apples", "code": "50331555" },
      { "name": "Dried organic mutsu apples", "code": "50331556" },
      { "name": "Dried organic newtown pippin apples", "code": "50331557" },
      { "name": "Dried organic northern spy apples", "code": "50331558" },
      { "name": "Dried organic orleans reinette apples", "code": "50331559" },
      { "name": "Dried organic ozark gold apples", "code": "50331560" },
      { "name": "Dried organic pacific rose apples", "code": "50331561" },
      { "name": "Dried organic paula red apples", "code": "50331562" },
      { "name": "Dried organic pearmain apples", "code": "50331563" },
      { "name": "Dried organic pink lady apples", "code": "50331564" },
      { "name": "Dried organic pippin apples", "code": "50331565" },
      {
        "name": "Dried organic pitmaston pineapple apples",
        "code": "50331566"
      },
      { "name": "Dried organic pomme d'api apples", "code": "50331567" },
      { "name": "Dried organic prime gold apples", "code": "50331568" },
      { "name": "Dried organic red astrachan apples", "code": "50331569" },
      { "name": "Dried organic red boscoop apples", "code": "50331570" },
      { "name": "Dried organic red chief apples", "code": "50331571" },
      { "name": "Dried organic red delicious apples", "code": "50331572" },
      { "name": "Dried organic red gravenstein apples", "code": "50331573" },
      { "name": "Dried organic red rome apples", "code": "50331574" },
      { "name": "Dried organic red stayman apples", "code": "50331575" },
      { "name": "Dried organic red york apples", "code": "50331576" },
      { "name": "Dried organic reinette apples", "code": "50331577" },
      { "name": "Dried organic rome beauty apples", "code": "50331578" },
      { "name": "Dried organic russet apples", "code": "50331579" },
      { "name": "Dried organic sierra beauty apples", "code": "50331580" },
      { "name": "Dried organic spartan apples", "code": "50331581" },
      { "name": "Dried organic stark crimson apples", "code": "50331582" },
      { "name": "Dried organic starking apples", "code": "50331583" },
      { "name": "Dried organic stayman apples", "code": "50331584" },
      { "name": "Dried organic stayman winesap apples", "code": "50331585" },
      { "name": "Dried organic summer rambo apples", "code": "50331586" },
      { "name": "Dried organic tsugaru apples", "code": "50331587" },
      { "name": "Dried organic twenty ounce apples", "code": "50331588" },
      { "name": "Dried organic tydeman red apples", "code": "50331589" },
      { "name": "Dried organic vistabella apples", "code": "50331590" },
      { "name": "Dried organic wealthy apples", "code": "50331591" },
      { "name": "Dried organic white joaneting apples", "code": "50331592" },
      { "name": "Dried organic white transparent apples", "code": "50331593" },
      { "name": "Dried organic winesap apples", "code": "50331594" },
      { "name": "Dried organic worcester apples", "code": "50331595" },
      { "name": "Dried organic york imperial apples", "code": "50331596" },
      { "name": "Dried organic apricots", "code": "50331600" },
      { "name": "Dried organic ambercot apricots", "code": "50331601" },
      { "name": "Dried organic apache apricots", "code": "50331602" },
      { "name": "Dried organic brittany gold apricots", "code": "50331603" },
      { "name": "Dried organic black apricots", "code": "50331604" },
      { "name": "Dried organic blenheim apricots", "code": "50331605" },
      { "name": "Dried organic bonny apricots", "code": "50331606" },
      { "name": "Dried organic bulida apricots", "code": "50331607" },
      { "name": "Dried organic castlebrite apricots", "code": "50331608" },
      { "name": "Dried organic clutha gold apricots", "code": "50331609" },
      { "name": "Dried organic clutha sun apricots", "code": "50331610" },
      { "name": "Dried organic darby royal apricots", "code": "50331611" },
      { "name": "Dried organic dina apricots", "code": "50331612" },
      { "name": "Dried organic earlicot apricots", "code": "50331613" },
      { "name": "Dried organic earliman apricots", "code": "50331614" },
      { "name": "Dried organic early bright apricots", "code": "50331615" },
      { "name": "Dried organic flaming gold apricots", "code": "50331616" },
      { "name": "Dried organic fresno apricots", "code": "50331617" },
      { "name": "Dried organic gold brite apricots", "code": "50331618" },
      { "name": "Dried organic goldbar apricots", "code": "50331619" },
      { "name": "Dried organic golden sweet apricots", "code": "50331620" },
      { "name": "Dried organic goldrich apricots", "code": "50331621" },
      { "name": "Dried organic helena apricots", "code": "50331622" },
      { "name": "Dried organic honeycot apricots", "code": "50331623" },
      { "name": "Dried organic imperial apricots", "code": "50331624" },
      { "name": "Dried organic jordanne apricots", "code": "50331625" },
      { "name": "Dried organic jumbo cot apricots", "code": "50331626" },
      { "name": "Dried organic kandy kot apricots", "code": "50331627" },
      { "name": "Dried organic katy apricots", "code": "50331628" },
      { "name": "Dried organic king apricots", "code": "50331629" },
      { "name": "Dried organic lambertin apricots", "code": "50331630" },
      { "name": "Dried organic lorna apricots", "code": "50331631" },
      { "name": "Dried organic lulu belle apricots", "code": "50331632" },
      { "name": "Dried organic modesto apricots", "code": "50331633" },
      { "name": "Dried organic moorpark apricots", "code": "50331634" },
      { "name": "Dried organic orangered apricots", "code": "50331635" },
      { "name": "Dried organic palstein apricots", "code": "50331636" },
      { "name": "Dried organic patterson apricots", "code": "50331637" },
      { "name": "Dried organic perfection apricots", "code": "50331638" },
      { "name": "Dried organic poppy apricots", "code": "50331639" },
      { "name": "Dried organic poppycot apricots", "code": "50331640" },
      { "name": "Dried organic queen apricots", "code": "50331641" },
      { "name": "Dried organic riland apricots", "code": "50331642" },
      { "name": "Dried organic rival apricots", "code": "50331643" },
      { "name": "Dried organic robada apricots", "code": "50331644" },
      { "name": "Dried organic royal apricots", "code": "50331645" },
      { "name": "Dried organic royal blenheim apricots", "code": "50331646" },
      { "name": "Dried organic royal orange apricots", "code": "50331647" },
      { "name": "Dried organic sundrop apricots", "code": "50331648" },
      { "name": "Dried organic tilton apricots", "code": "50331649" },
      { "name": "Dried organic tomcot apricots", "code": "50331650" },
      { "name": "Dried organic tracy apricots", "code": "50331651" },
      { "name": "Dried organic tri gem apricots", "code": "50331652" },
      { "name": "Dried organic valley gold apricots", "code": "50331653" },
      { "name": "Dried organic westley apricots", "code": "50331654" },
      { "name": "Dried organic york apricots", "code": "50331655" },
      { "name": "Dried organic bananas", "code": "50331700" },
      { "name": "Dried organic apple bananas", "code": "50331701" },
      { "name": "Dried organic baby bananas", "code": "50331702" },
      { "name": "Dried organic burro bananas", "code": "50331703" },
      { "name": "Dried organic cavendish bananas", "code": "50331704" },
      { "name": "Dried organic dominico bananas", "code": "50331705" },
      { "name": "Dried organic green bananas", "code": "50331706" },
      { "name": "Dried organic gros michel bananas", "code": "50331707" },
      { "name": "Dried organic lacatan bananas", "code": "50331708" },
      { "name": "Dried organic lady finger banana", "code": "50331709" },
      { "name": "Dried organic manzano bananas", "code": "50331710" },
      { "name": "Dried organic mysore bananas", "code": "50331711" },
      { "name": "Dried organic pisang mas bananas", "code": "50331712" },
      { "name": "Dried organic red bananas", "code": "50331713" },
      { "name": "Dried organic saba bananas", "code": "50331714" },
      { "name": "Dried organic sucrier bananas", "code": "50331715" },
      { "name": "Dried organic barberries", "code": "50331800" },
      { "name": "Dried organic paleleaf barberries", "code": "50331801" },
      { "name": "Dried organic chenault barberries", "code": "50331802" },
      { "name": "Dried organic red barberries", "code": "50331803" },
      { "name": "Dried organic wintergreen barberries", "code": "50331804" },
      { "name": "Dried organic korean barberries", "code": "50331805" },
      { "name": "Dried organic mentor barberries", "code": "50331806" },
      { "name": "Dried organic japanese barberries", "code": "50331807" },
      { "name": "Dried organic atropurpurea barberries", "code": "50331808" },
      { "name": "Dried organic aurea barberries", "code": "50331809" },
      { "name": "Dried organic bagatelle barberries", "code": "50331810" },
      { "name": "Dried organic crimson pygmy barberries", "code": "50331811" },
      { "name": "Dried organic kobold barberries", "code": "50331812" },
      { "name": "Dried organic warty barberries", "code": "50331813" },
      { "name": "Dried organic european barberries", "code": "50331814" },
      { "name": "Dried organic bearberries", "code": "50331900" },
      { "name": "Dried organic alpine bearberries", "code": "50331901" },
      { "name": "Dried organic red bearberries", "code": "50331902" },
      { "name": "Dried organic common bearberries", "code": "50331903" },
      { "name": "Dried organic blackberries", "code": "50332000" },
      { "name": "Dried organic apache blackberries", "code": "50332001" },
      { "name": "Dried organic black satin blackberries", "code": "50332002" },
      { "name": "Dried organic boysenberries", "code": "50332003" },
      { "name": "Dried organic cherokee blackberries", "code": "50332004" },
      { "name": "Dried organic chester blackberries", "code": "50332005" },
      { "name": "Dried organic dirksen blackberries", "code": "50332006" },
      { "name": "Dried organic jostaberries", "code": "50332007" },
      { "name": "Dried organic loganberries", "code": "50332008" },
      { "name": "Dried organic marionberries", "code": "50332009" },
      { "name": "Dried organic navaho blackberries", "code": "50332010" },
      { "name": "Dried organic nectarberries", "code": "50332011" },
      { "name": "Dried organic olallie blackberries", "code": "50332012" },
      { "name": "Dried organic tayberries", "code": "50332013" },
      {
        "name": "Dried organic thornless hull blackberries",
        "code": "50332014"
      },
      { "name": "Dried organic youngberries", "code": "50332015" },
      { "name": "Dried organic bilberries", "code": "50332100" },
      { "name": "Dried organic bog bilberries", "code": "50332101" },
      { "name": "Dried organic dwarf bilberries", "code": "50332102" },
      { "name": "Dried organic mountain bilberries", "code": "50332103" },
      { "name": "Dried organic oval-leaved bilberries", "code": "50332104" },
      { "name": "Dried organic blueberries", "code": "50332200" },
      { "name": "Dried organic bluecrop blueberries", "code": "50332201" },
      { "name": "Dried organic bluetta blueberries", "code": "50332202" },
      { "name": "Dried organic brigitta blueberries", "code": "50332203" },
      { "name": "Dried organic chandler blueberries", "code": "50332204" },
      { "name": "Dried organic duke blueberries", "code": "50332205" },
      { "name": "Dried organic hardyblue blueberries", "code": "50332206" },
      { "name": "Dried organic legacy blueberries", "code": "50332207" },
      { "name": "Dried organic misty blueberries", "code": "50332208" },
      { "name": "Dried organic nelson blueberries", "code": "50332209" },
      { "name": "Dried organic northblue blueberries", "code": "50332210" },
      { "name": "Dried organic northcountry blueberries", "code": "50332211" },
      { "name": "Dried organic northsky blueberries", "code": "50332212" },
      { "name": "Dried organic patriot blueberries", "code": "50332213" },
      { "name": "Dried organic spartan blueberries", "code": "50332214" },
      { "name": "Dried organic toro blueberries", "code": "50332215" },
      { "name": "Dried organic breadfruit", "code": "50332300" },
      { "name": "Dried organic chataigne breadfruit", "code": "50332301" },
      { "name": "Dried organic seedless breadfruit", "code": "50332302" },
      { "name": "Dried organic white heart breadfruit", "code": "50332303" },
      { "name": "Dried organic yellow heart breadfruit", "code": "50332304" },
      { "name": "Dried organic cherimoyas", "code": "50332400" },
      { "name": "Dried organic bays cherimoya", "code": "50332401" },
      { "name": "Dried organic bronceada cherimoya", "code": "50332402" },
      { "name": "Dried organic burtons cherimoya", "code": "50332403" },
      {
        "name": "Dried organic burtons favorite cherimoya",
        "code": "50332404"
      },
      { "name": "Dried organic jete cherimoya", "code": "50332405" },
      { "name": "Dried organic reretai cherimoya", "code": "50332406" },
      { "name": "Dried organic smoothey cherimoya", "code": "50332407" },
      { "name": "Dried organic spain cherimoya", "code": "50332408" },
      { "name": "Dried organic white cherimoya", "code": "50332409" },
      { "name": "Dried organic cherries", "code": "50332500" },
      { "name": "Dried organic amarelle cherries", "code": "50332501" },
      { "name": "Dried organic brooks cherries", "code": "50332502" },
      { "name": "Dried organic bigarreu cherries", "code": "50332503" },
      { "name": "Dried organic bing cherries", "code": "50332504" },
      { "name": "Dried organic black republic cherries", "code": "50332505" },
      { "name": "Dried organic black schmidt cherries", "code": "50332506" },
      { "name": "Dried organic black tartarian cherries", "code": "50332507" },
      { "name": "Dried organic fiesta bing cherries", "code": "50332508" },
      { "name": "Dried organic garnet cherries", "code": "50332509" },
      { "name": "Dried organic king cherries", "code": "50332510" },
      { "name": "Dried organic chapman cherries", "code": "50332511" },
      { "name": "Dried organic lapin cherries", "code": "50332512" },
      { "name": "Dried organic larian cherries", "code": "50332513" },
      { "name": "Dried organic dark guines cherries", "code": "50332514" },
      { "name": "Dried organic montmorency cherries", "code": "50332515" },
      { "name": "Dried organic duke cherries", "code": "50332516" },
      { "name": "Dried organic early rivers cherries", "code": "50332517" },
      { "name": "Dried organic ruby bing cherries", "code": "50332518" },
      { "name": "Dried organic santina cherries", "code": "50332519" },
      { "name": "Dried organic geans/guines cherries", "code": "50332520" },
      { "name": "Dried organic sonata cherries", "code": "50332521" },
      { "name": "Dried organic lambert cherries", "code": "50332522" },
      { "name": "Dried organic stella cherries", "code": "50332523" },
      { "name": "Dried organic sweetheart cherries", "code": "50332524" },
      { "name": "Dried organic tartarian cherries", "code": "50332525" },
      { "name": "Dried organic maraschino cherries", "code": "50332527" },
      { "name": "Dried organic van cherries", "code": "50332528" },
      { "name": "Dried organic morello cherries", "code": "50332529" },
      { "name": "Dried organic royal ann cherries", "code": "50332530" },
      { "name": "Dried organic ranier cherries", "code": "50332531" },
      { "name": "Dried organic royal cherries", "code": "50332532" },
      { "name": "Dried organic citrons", "code": "50332600" },
      { "name": "Dried organic buddha's hand citrons", "code": "50332601" },
      { "name": "Dried organic fingered citrons", "code": "50332602" },
      { "name": "Dried organic fo shoukan citrons", "code": "50332603" },
      { "name": "Dried organic bushakan citrons", "code": "50332604" },
      { "name": "Dried organic diamante citrons", "code": "50332605" },
      { "name": "Dried organic etrog citrons", "code": "50332606" },
      { "name": "Dried organic ponderosa citrons", "code": "50332607" },
      { "name": "Dried organic cranberries", "code": "50332700" },
      { "name": "Dried organic ben lear cranberries", "code": "50332701" },
      { "name": "Dried organic early black cranberries", "code": "50332702" },
      { "name": "Dried organic grycleski cranberries", "code": "50332703" },
      { "name": "Dried organic howe cranberries", "code": "50332704" },
      { "name": "Dried organic lingonberries", "code": "50332705" },
      { "name": "Dried organic mcfarlin cranberries", "code": "50332706" },
      { "name": "Dried organic mountain cranberries", "code": "50332707" },
      { "name": "Dried organic pilgrim cranberries", "code": "50332708" },
      { "name": "Dried organic searless cranberries", "code": "50332709" },
      { "name": "Dried organic stevens cranberries", "code": "50332710" },
      { "name": "Dried organic currants", "code": "50332800" },
      { "name": "Dried organic hudson bay currants", "code": "50332801" },
      { "name": "Dried organic waxy currants", "code": "50332802" },
      { "name": "Dried organic desert currants", "code": "50332803" },
      { "name": "Dried organic black currants", "code": "50332804" },
      { "name": "Dried organic red currants", "code": "50332805" },
      { "name": "Dried organic white currants", "code": "50332806" },
      { "name": "Dried organic dates", "code": "50332900" },
      { "name": "Dried organic asharasi dates", "code": "50332901" },
      { "name": "Dried organic barhi or barhee dates", "code": "50332902" },
      { "name": "Dried organic deglet noor dates", "code": "50332903" },
      { "name": "Dried organic fardh dates", "code": "50332904" },
      { "name": "Dried organic gundila dates", "code": "50332905" },
      { "name": "Dried organic halawi/halawy dates", "code": "50332906" },
      { "name": "Dried organic hilali dates", "code": "50332907" },
      { "name": "Dried organic khadrawi/khadrawy dates", "code": "50332908" },
      { "name": "Dried organic khalas dates", "code": "50332909" },
      { "name": "Dried organic khustawi dates", "code": "50332910" },
      { "name": "Dried organic khidri dates", "code": "50332911" },
      { "name": "Dried organic medjool/medjul dates", "code": "50332912" },
      { "name": "Dried organic mactoum dates", "code": "50332913" },
      { "name": "Dried organic neghal dates", "code": "50332914" },
      { "name": "Dried organic yatimeh dates", "code": "50332915" },
      { "name": "Dried organic zahidi dates", "code": "50332916" },
      { "name": "Dried organic dragonfruit", "code": "50333000" },
      { "name": "Dried organic pink dragonfruit", "code": "50333001" },
      { "name": "Dried organic yellow dragonfruit", "code": "50333002" },
      { "name": "Dried organic figs", "code": "50333100" },
      { "name": "Dried organic bardajic figs", "code": "50333101" },
      { "name": "Dried organic brown turkey figs", "code": "50333102" },
      { "name": "Dried organic calimyrna figs", "code": "50333103" },
      { "name": "Dried organic conadria figs", "code": "50333104" },
      { "name": "Dried organic dottado figs", "code": "50333105" },
      { "name": "Dried organic kadota figs", "code": "50333106" },
      { "name": "Dried organic mediterranean figs", "code": "50333107" },
      { "name": "Dried organic mission figs", "code": "50333108" },
      { "name": "Dried organic smyrna figs", "code": "50333109" },
      { "name": "Dried organic verdona figs", "code": "50333110" },
      { "name": "Dried organic white king figs", "code": "50333111" },
      { "name": "Dried organic gooseberries", "code": "50333200" },
      {
        "name": "Dried organic early sulphur gooseberries",
        "code": "50333201"
      },
      { "name": "Dried organic goldendrop gooseberries", "code": "50333202" },
      { "name": "Dried organic langley gage gooseberries", "code": "50333203" },
      { "name": "Dried organic leveller gooseberries", "code": "50333204" },
      { "name": "Dried organic london gooseberries", "code": "50333205" },
      {
        "name": "Dried organic worcestershire gooseberries",
        "code": "50333206"
      },
      {
        "name": "Dried organic american worcesterberry gooseberries",
        "code": "50333207"
      },
      { "name": "Dried organic grapefruit", "code": "50333300" },
      { "name": "Dried organic burgundy grapefruit", "code": "50333301" },
      { "name": "Dried organic duncan grapefruit", "code": "50333302" },
      { "name": "Dried organic foster grapefruit", "code": "50333303" },
      { "name": "Dried organic marsh grapefruit", "code": "50333304" },
      { "name": "Dried organic new zealand grapefruit", "code": "50333305" },
      { "name": "Dried organic rio red grapefruit", "code": "50333306" },
      { "name": "Dried organic ruby red grapefruit", "code": "50333307" },
      { "name": "Dried organic star ruby grapefruit", "code": "50333308" },
      { "name": "Dried organic triumph grapefruit", "code": "50333309" },
      { "name": "Dried organic table grapes", "code": "50333400" },
      { "name": "Dried organic alicante grapes", "code": "50333401" },
      { "name": "Dried organic almeria grapes", "code": "50333402" },
      { "name": "Dried organic alphonse lavalle grapes", "code": "50333403" },
      { "name": "Dried organic autumn king grapes", "code": "50333404" },
      { "name": "Dried organic autumn royal grapes", "code": "50333405" },
      { "name": "Dried organic autumn seedless grapes", "code": "50333406" },
      { "name": "Dried organic baresana grapes", "code": "50333407" },
      { "name": "Dried organic barlinka grapes", "code": "50333408" },
      { "name": "Dried organic beauty seedless grapes", "code": "50333409" },
      {
        "name": "Dried organic black beauty seedless grapes",
        "code": "50333410"
      },
      { "name": "Dried organic black emerald grapes", "code": "50333411" },
      { "name": "Dried organic black giant grapes", "code": "50333412" },
      { "name": "Dried organic black globe grapes", "code": "50333413" },
      { "name": "Dried organic black monukka grapes", "code": "50333414" },
      { "name": "Dried organic black pearl grapes", "code": "50333415" },
      { "name": "Dried organic black seedless grapes", "code": "50333416" },
      { "name": "Dried organic bonheur grapes", "code": "50333417" },
      { "name": "Dried organic calmeria grapes", "code": "50333418" },
      { "name": "Dried organic cardinal grapes", "code": "50333419" },
      { "name": "Dried organic catawba grapes", "code": "50333420" },
      {
        "name": "Dried organic chasselas/golden chasselas grapes",
        "code": "50333421"
      },
      { "name": "Dried organic christmas rose grapes", "code": "50333422" },
      { "name": "Dried organic concord grapes", "code": "50333423" },
      { "name": "Dried organic concord seedless grapes", "code": "50333424" },
      { "name": "Dried organic crimson seedless grapes", "code": "50333425" },
      { "name": "Dried organic dauphine grapes", "code": "50333426" },
      { "name": "Dried organic delaware grapes", "code": "50333427" },
      { "name": "Dried organic early muscat grapes", "code": "50333428" },
      { "name": "Dried organic early sweet grapes", "code": "50333429" },
      { "name": "Dried organic emerald seedless grapes", "code": "50333430" },
      { "name": "Dried organic emperatriz grapes", "code": "50333431" },
      { "name": "Dried organic emperor grapes", "code": "50333432" },
      { "name": "Dried organic empress grapes", "code": "50333433" },
      { "name": "Dried organic exotic grapes", "code": "50333434" },
      { "name": "Dried organic fantasy grapes", "code": "50333435" },
      { "name": "Dried organic fantasy seedless grapes", "code": "50333436" },
      { "name": "Dried organic flame grapes", "code": "50333437" },
      { "name": "Dried organic flame seedless grapes", "code": "50333438" },
      { "name": "Dried organic flame tokay grapes", "code": "50333439" },
      { "name": "Dried organic flaming red grapes", "code": "50333440" },
      { "name": "Dried organic galaxy seedless grapes", "code": "50333441" },
      { "name": "Dried organic gamay grapes", "code": "50333442" },
      { "name": "Dried organic gold grapes", "code": "50333443" },
      {
        "name": "Dried organic hanepoot or honeypot grapes",
        "code": "50333444"
      },
      { "name": "Dried organic italia grapes", "code": "50333445" },
      { "name": "Dried organic jade seedless grapes", "code": "50333446" },
      { "name": "Dried organic jubilee grapes", "code": "50333447" },
      { "name": "Dried organic king ruby grapes", "code": "50333448" },
      { "name": "Dried organic kyoho grapes", "code": "50333449" },
      { "name": "Dried organic la rochelle grapes", "code": "50333450" },
      { "name": "Dried organic lady finger grapes", "code": "50333451" },
      { "name": "Dried organic late seedless grapes", "code": "50333452" },
      { "name": "Dried organic majestic seedless grapes", "code": "50333453" },
      { "name": "Dried organic malaga grapes", "code": "50333454" },
      { "name": "Dried organic marroo seedless grapes", "code": "50333455" },
      { "name": "Dried organic muscadine grapes", "code": "50333456" },
      { "name": "Dried organic muscat flame grapes", "code": "50333457" },
      { "name": "Dried organic muscat grapes", "code": "50333458" },
      { "name": "Dried organic muscat seedless grapes", "code": "50333459" },
      { "name": "Dried organic napoleon grapes", "code": "50333460" },
      { "name": "Dried organic negria grapes", "code": "50333461" },
      { "name": "Dried organic new cross grapes", "code": "50333462" },
      { "name": "Dried organic niabell grapes", "code": "50333463" },
      { "name": "Dried organic niagara grapes", "code": "50333464" },
      { "name": "Dried organic olivette grapes", "code": "50333465" },
      { "name": "Dried organic perlette grapes", "code": "50333466" },
      { "name": "Dried organic perlon grapes", "code": "50333467" },
      {
        "name": "Dried organic prima black seedless grapes",
        "code": "50333468"
      },
      { "name": "Dried organic princess grapes", "code": "50333469" },
      { "name": "Dried organic queen grapes", "code": "50333470" },
      { "name": "Dried organic red blush grapes", "code": "50333471" },
      { "name": "Dried organic red globe grapes", "code": "50333472" },
      { "name": "Dried organic red malaga grapes", "code": "50333473" },
      { "name": "Dried organic red seedless grapes", "code": "50333474" },
      { "name": "Dried organic regina grapes", "code": "50333475" },
      { "name": "Dried organic ribier grapes", "code": "50333476" },
      { "name": "Dried organic rosita grapes", "code": "50333477" },
      { "name": "Dried organic rouge grapes", "code": "50333478" },
      {
        "name": "Dried organic royal black seedless grapes",
        "code": "50333479"
      },
      { "name": "Dried organic ruby red seedless grapes", "code": "50333480" },
      { "name": "Dried organic ruby seedless grapes", "code": "50333481" },
      { "name": "Dried organic scarlet royal grapes", "code": "50333482" },
      { "name": "Dried organic scuppernong grapes", "code": "50333483" },
      { "name": "Dried organic sugarose grapes", "code": "50333484" },
      { "name": "Dried organic sugarthirteen grapes", "code": "50333485" },
      { "name": "Dried organic sugraone grapes", "code": "50333486" },
      { "name": "Dried organic sugrasixteen grapes", "code": "50333487" },
      { "name": "Dried organic sultana sun red grapes", "code": "50333488" },
      { "name": "Dried organic summer royal grapes", "code": "50333489" },
      { "name": "Dried organic sunset grapes", "code": "50333490" },
      { "name": "Dried organic superior seedless grapes", "code": "50333491" },
      { "name": "Dried organic thompson seedless grapes", "code": "50333492" },
      { "name": "Dried organic tokay/pinot gris grapes", "code": "50333493" },
      { "name": "Dried organic waltman cross grapes", "code": "50333494" },
      { "name": "Dried organic white seedless grapes", "code": "50333495" },
      { "name": "Dried organic zante current grapes", "code": "50333496" },
      { "name": "Dried organic raisin grapes", "code": "50333500" },
      { "name": "Dried organic black corinth grapes", "code": "50333501" },
      { "name": "Dried organic canner grapes", "code": "50333502" },
      { "name": "Dried organic dovine grapes", "code": "50333503" },
      { "name": "Dried organic fiesta grapes", "code": "50333504" },
      { "name": "Dried organic selma pete grapes", "code": "50333505" },
      { "name": "Dried organic sultana grapes", "code": "50333506" },
      { "name": "Dried organic wine grapes", "code": "50333600" },
      { "name": "Dried organic alicante bouschet grapes", "code": "50333601" },
      { "name": "Dried organic barbera grapes", "code": "50333602" },
      { "name": "Dried organic burger grapes", "code": "50333603" },
      { "name": "Dried organic cabernet franc grapes", "code": "50333604" },
      { "name": "Dried organic cabernet sauvignon grapes", "code": "50333605" },
      { "name": "Dried organic carignane grapes", "code": "50333606" },
      { "name": "Dried organic carnelian grapes", "code": "50333607" },
      { "name": "Dried organic catarratto grapes", "code": "50333608" },
      { "name": "Dried organic centurian grapes", "code": "50333609" },
      { "name": "Dried organic charbono grapes", "code": "50333610" },
      { "name": "Dried organic chardonnay grapes", "code": "50333611" },
      { "name": "Dried organic chenin blanc grapes", "code": "50333612" },
      { "name": "Dried organic cinsaut grapes", "code": "50333613" },
      { "name": "Dried organic dolcetto grapes", "code": "50333614" },
      { "name": "Dried organic emerald riesling grapes", "code": "50333615" },
      { "name": "Dried organic french colombard grapes", "code": "50333616" },
      { "name": "Dried organic gamay napa grapes", "code": "50333617" },
      { "name": "Dried organic gamay beaujolais grapes", "code": "50333618" },
      { "name": "Dried organic gewurztraminer grapes", "code": "50333619" },
      { "name": "Dried organic grenache grapes", "code": "50333620" },
      { "name": "Dried organic grenache blanc grapes", "code": "50333621" },
      { "name": "Dried organic lagrein grapes", "code": "50333622" },
      { "name": "Dried organic lambrusco grapes", "code": "50333623" },
      { "name": "Dried organic malbec grapes", "code": "50333624" },
      { "name": "Dried organic malvasia bianca grapes", "code": "50333625" },
      { "name": "Dried organic marsanne grapes", "code": "50333626" },
      { "name": "Dried organic mataro grapes", "code": "50333627" },
      { "name": "Dried organic merlot grapes", "code": "50333628" },
      { "name": "Dried organic meunier grapes", "code": "50333629" },
      { "name": "Dried organic mission grapes", "code": "50333630" },
      { "name": "Dried organic montepulciano grapes", "code": "50333631" },
      { "name": "Dried organic muscat blanc grapes", "code": "50333632" },
      { "name": "Dried organic muscat hamburg grapes", "code": "50333633" },
      {
        "name": "Dried organic muscat of alexandria grapes",
        "code": "50333634"
      },
      { "name": "Dried organic muscat orange grapes", "code": "50333635" },
      { "name": "Dried organic nebbiolo grapes", "code": "50333636" },
      { "name": "Dried organic palomino grapes", "code": "50333637" },
      { "name": "Dried organic petit verdot grapes", "code": "50333638" },
      { "name": "Dried organic petite sirah grapes", "code": "50333639" },
      { "name": "Dried organic pinot blanc grapes", "code": "50333640" },
      { "name": "Dried organic pinot gris grapes", "code": "50333641" },
      { "name": "Dried organic pinot noir grapes", "code": "50333642" },
      { "name": "Dried organic primitivo grapes", "code": "50333643" },
      { "name": "Dried organic roussanne grapes", "code": "50333644" },
      { "name": "Dried organic royalty grapes", "code": "50333645" },
      { "name": "Dried organic rubired grapes", "code": "50333646" },
      { "name": "Dried organic ruby cabernet grapes", "code": "50333647" },
      { "name": "Dried organic salvador grapes", "code": "50333648" },
      { "name": "Dried organic sangiovese grapes", "code": "50333649" },
      { "name": "Dried organic sauvignon blanc grapes", "code": "50333650" },
      { "name": "Dried organic sauvignon musque grapes", "code": "50333651" },
      { "name": "Dried organic semillon grapes", "code": "50333652" },
      { "name": "Dried organic souzao grapes", "code": "50333653" },
      { "name": "Dried organic st emilion grapes", "code": "50333654" },
      { "name": "Dried organic symphony grapes", "code": "50333655" },
      { "name": "Dried organic syrah grapes", "code": "50333656" },
      { "name": "Dried organic tannat grapes", "code": "50333657" },
      { "name": "Dried organic tempranillo grapes", "code": "50333658" },
      { "name": "Dried organic teroldego grapes", "code": "50333659" },
      { "name": "Dried organic tocai friulano grapes", "code": "50333660" },
      { "name": "Dried organic touriga nacional grapes", "code": "50333661" },
      { "name": "Dried organic triplett blanc grapes", "code": "50333662" },
      { "name": "Dried organic viognier grapes", "code": "50333663" },
      { "name": "Dried organic white riesling grapes", "code": "50333664" },
      { "name": "Dried organic zinfandel grapes", "code": "50333665" },
      { "name": "Dried organic guavas", "code": "50333700" },
      { "name": "Dried organic beaumont guavas", "code": "50333701" },
      { "name": "Dried organic carrley guavas", "code": "50333702" },
      { "name": "Dried organic lucida guavas", "code": "50333703" },
      { "name": "Dried organic pineapple guava", "code": "50333704" },
      { "name": "Dried organic huckleberries", "code": "50333800" },
      {
        "name": "Dried organic black winter huckleberries",
        "code": "50333801"
      },
      { "name": "Dried organic cascade huckleberries", "code": "50333802" },
      { "name": "Dried organic dwarf huckleberries", "code": "50333803" },
      { "name": "Dried organic mountain huckleberries", "code": "50333804" },
      { "name": "Dried organic red huckleberries", "code": "50333805" },
      { "name": "Dried organic kiwi fruit", "code": "50333900" },
      { "name": "Dried organic ananasnaja kiwi fruit", "code": "50333901" },
      { "name": "Dried organic arctic beauty kiwi fruit", "code": "50333902" },
      { "name": "Dried organic blake kiwi fruit", "code": "50333903" },
      { "name": "Dried organic hayward kiwi fruit", "code": "50333904" },
      { "name": "Dried organic issai kiwi fruit", "code": "50333905" },
      { "name": "Dried organic siberian kiwi fruit", "code": "50333906" },
      { "name": "Dried organic kumquats", "code": "50334000" },
      { "name": "Dried organic hong kong kumquats", "code": "50334001" },
      { "name": "Dried organic limequat kumquats", "code": "50334002" },
      { "name": "Dried organic long fruit kumquats", "code": "50334003" },
      { "name": "Dried organic malayan kumquats", "code": "50334004" },
      { "name": "Dried organic meiwa kumquats", "code": "50334005" },
      { "name": "Dried organic nagami kumquats", "code": "50334006" },
      { "name": "Dried organic lemons", "code": "50334100" },
      { "name": "Dried organic baboon lemons", "code": "50334101" },
      { "name": "Dried organic bearss sicilian lemons", "code": "50334102" },
      { "name": "Dried organic cameron highlands lemons", "code": "50334103" },
      { "name": "Dried organic escondido lemons", "code": "50334104" },
      { "name": "Dried organic eureka lemons", "code": "50334105" },
      { "name": "Dried organic lisbon lemons", "code": "50334106" },
      { "name": "Dried organic meyer lemons", "code": "50334107" },
      { "name": "Dried organic volkamer lemons", "code": "50334108" },
      { "name": "Dried organic limes", "code": "50334200" },
      { "name": "Dried organic indian sweet limes", "code": "50334201" },
      { "name": "Dried organic key limes", "code": "50334202" },
      { "name": "Dried organic mandarin limes", "code": "50334203" },
      { "name": "Dried organic philippine limes", "code": "50334204" },
      { "name": "Dried organic tahitian limes", "code": "50334205" },
      { "name": "Dried organic bearss limes", "code": "50334206" },
      { "name": "Dried organic persian limes", "code": "50334207" },
      { "name": "Dried organic seedless limes", "code": "50334208" },
      { "name": "Dried organic loquats", "code": "50334300" },
      { "name": "Dried organic advance loquats", "code": "50334301" },
      { "name": "Dried organic benlehr loquats", "code": "50334302" },
      { "name": "Dried organic big jim loquats", "code": "50334303" },
      { "name": "Dried organic champagne loquats", "code": "50334304" },
      { "name": "Dried organic early red loquats", "code": "50334305" },
      { "name": "Dried organic gold nugget loquats", "code": "50334306" },
      { "name": "Dried organic herd's mammoth loquats", "code": "50334307" },
      { "name": "Dried organic mogi loquats", "code": "50334308" },
      { "name": "Dried organic mrs cooksey loquats", "code": "50334309" },
      { "name": "Dried organic strawberry loquats", "code": "50334310" },
      { "name": "Dried organic tanaka loquats", "code": "50334311" },
      {
        "name": "Dried organic victory vista white loquats",
        "code": "50334312"
      },
      { "name": "Dried organic wolfe loquats", "code": "50334313" },
      {
        "name": "Dried organic mandarin oranges or tangerines",
        "code": "50334400"
      },
      { "name": "Dried organic clauselinas oranges", "code": "50334401" },
      { "name": "Dried organic clementine tangerines", "code": "50334402" },
      {
        "name": "Dried organic cleopatra mandarin oranges",
        "code": "50334403"
      },
      { "name": "Dried organic dancy tangerines", "code": "50334404" },
      { "name": "Dried organic ellensdale oranges", "code": "50334405" },
      { "name": "Dried organic fairchild oranges", "code": "50334406" },
      { "name": "Dried organic fallglo oranges", "code": "50334407" },
      { "name": "Dried organic fortune oranges", "code": "50334408" },
      { "name": "Dried organic fremont mandarin oranges", "code": "50334409" },
      { "name": "Dried organic fremont oranges", "code": "50334410" },
      { "name": "Dried organic golden nugget oranges", "code": "50334411" },
      { "name": "Dried organic honey mandarin oranges", "code": "50334412" },
      { "name": "Dried organic honey oranges", "code": "50334413" },
      { "name": "Dried organic honey tangerines", "code": "50334414" },
      {
        "name": "Dried organic honeybelle tangelo oranges",
        "code": "50334415"
      },
      { "name": "Dried organic king mandarin oranges", "code": "50334416" },
      { "name": "Dried organic kinnow oranges", "code": "50334417" },
      { "name": "Dried organic lee mandarin oranges", "code": "50334418" },
      { "name": "Dried organic makokkee oranges", "code": "50334419" },
      { "name": "Dried organic malvasios oranges", "code": "50334420" },
      {
        "name": "Dried organic mediterranean mandarin oranges",
        "code": "50334421"
      },
      { "name": "Dried organic minneola tangelo oranges", "code": "50334422" },
      { "name": "Dried organic monica oranges", "code": "50334423" },
      { "name": "Dried organic murcott honey oranges", "code": "50334424" },
      { "name": "Dried organic murcott tangors", "code": "50334425" },
      {
        "name": "Dried organic natsudaidai mandarin oranges",
        "code": "50334426"
      },
      {
        "name": "Dried organic natsumikan mandarin oranges",
        "code": "50334427"
      },
      { "name": "Dried organic nocatee tangelo oranges", "code": "50334428" },
      { "name": "Dried organic orlando tangelo oranges", "code": "50334429" },
      { "name": "Dried organic ortanique tangerines", "code": "50334430" },
      { "name": "Dried organic page mandarin oranges", "code": "50334431" },
      { "name": "Dried organic pixie oranges", "code": "50334432" },
      {
        "name": "Dried organic ponkan bantangas mandarin oranges",
        "code": "50334433"
      },
      { "name": "Dried organic reyna oranges", "code": "50334434" },
      { "name": "Dried organic robinson oranges", "code": "50334435" },
      { "name": "Dried organic saltenitas oranges", "code": "50334436" },
      { "name": "Dried organic sampson tangelo oranges", "code": "50334437" },
      { "name": "Dried organic satsuma mandarin oranges", "code": "50334438" },
      { "name": "Dried organic sunburst mandarin oranges", "code": "50334439" },
      { "name": "Dried organic tangelos", "code": "50334440" },
      { "name": "Dried organic tangerina oranges", "code": "50334441" },
      { "name": "Dried organic temple oranges", "code": "50334442" },
      { "name": "Dried organic thornton oranges", "code": "50334443" },
      { "name": "Dried organic wekiwa tangerines", "code": "50334444" },
      { "name": "Dried organic wilkins tangerines", "code": "50334445" },
      {
        "name": "Dried organic willowleaf mediterranean tangerines",
        "code": "50334446"
      },
      { "name": "Dried organic mangoes", "code": "50334500" },
      { "name": "Dried organic alphonso mangoes", "code": "50334501" },
      { "name": "Dried organic ataulfo mangoes", "code": "50334502" },
      { "name": "Dried organic criollo mangoes", "code": "50334503" },
      { "name": "Dried organic edwards mangoes", "code": "50334504" },
      { "name": "Dried organic francine mangoes", "code": "50334505" },
      { "name": "Dried organic francis mangoes", "code": "50334506" },
      { "name": "Dried organic gandaria mangoes", "code": "50334507" },
      { "name": "Dried organic haden mangoes", "code": "50334508" },
      { "name": "Dried organic irwin mangoes", "code": "50334509" },
      { "name": "Dried organic keitt mangoes", "code": "50334510" },
      { "name": "Dried organic kent mangoes", "code": "50334511" },
      { "name": "Dried organic kesar mangoes", "code": "50334512" },
      { "name": "Dried organic kuini mangoes", "code": "50334513" },
      { "name": "Dried organic manila super mangoes", "code": "50334514" },
      { "name": "Dried organic manila mangoes", "code": "50334515" },
      { "name": "Dried organic mayaguez mangoes", "code": "50334516" },
      { "name": "Dried organic mulgoba mangoes", "code": "50334517" },
      { "name": "Dried organic oro mangoes", "code": "50334518" },
      { "name": "Dried organic palmer mangoes", "code": "50334519" },
      { "name": "Dried organic parvin mangoes", "code": "50334520" },
      { "name": "Dried organic sandersha mangoes", "code": "50334521" },
      { "name": "Dried organic sensation mangoes", "code": "50334522" },
      { "name": "Dried organic smith mangoes", "code": "50334523" },
      { "name": "Dried organic tommy atkins mangoes", "code": "50334524" },
      { "name": "Dried organic van dyke mangoes", "code": "50334525" },
      { "name": "Dried organic melons", "code": "50334600" },
      { "name": "Dried organic allsweet melons", "code": "50334601" },
      { "name": "Dried organic athena melons", "code": "50334602" },
      { "name": "Dried organic black diamond melons", "code": "50334603" },
      { "name": "Dried organic cal sweet melons", "code": "50334604" },
      { "name": "Dried organic carnical melons", "code": "50334605" },
      { "name": "Dried organic cantaloupe melons", "code": "50334606" },
      { "name": "Dried organic casaba melons", "code": "50334607" },
      { "name": "Dried organic cavaillon melons", "code": "50334608" },
      { "name": "Dried organic charentais melons", "code": "50334609" },
      {
        "name": "Dried organic charleston gray watermelon",
        "code": "50334610"
      },
      { "name": "Dried organic crenshaw melons", "code": "50334611" },
      { "name": "Dried organic crimson sweet melons", "code": "50334612" },
      { "name": "Dried organic dixie lee melons", "code": "50334613" },
      { "name": "Dried organic eclipse melons", "code": "50334614" },
      { "name": "Dried organic ein d'or melons", "code": "50334615" },
      { "name": "Dried organic fiesta melons", "code": "50334616" },
      { "name": "Dried organic galia melons", "code": "50334617" },
      { "name": "Dried organic gaya melons", "code": "50334618" },
      { "name": "Dried organic hami melons", "code": "50334619" },
      { "name": "Dried organic honeydew melons", "code": "50334620" },
      { "name": "Dried organic icebox melons", "code": "50334621" },
      { "name": "Dried organic ida pride melons", "code": "50334622" },
      { "name": "Dried organic juan canary melons", "code": "50334623" },
      { "name": "Dried organic jubilee melons", "code": "50334624" },
      { "name": "Dried organic jubilation melons", "code": "50334625" },
      { "name": "Dried organic kakhi/kakri melons", "code": "50334626" },
      { "name": "Dried organic kiwano melons", "code": "50334627" },
      { "name": "Dried organic korean melons", "code": "50334628" },
      { "name": "Dried organic long gray melons", "code": "50334629" },
      { "name": "Dried organic mayan melons", "code": "50334630" },
      { "name": "Dried organic micky lee melons", "code": "50334631" },
      { "name": "Dried organic mirage melons", "code": "50334632" },
      { "name": "Dried organic moon and stars watermelon", "code": "50334633" },
      { "name": "Dried organic ogen melons", "code": "50334634" },
      { "name": "Dried organic patriot melons", "code": "50334635" },
      { "name": "Dried organic peacock melons", "code": "50334636" },
      { "name": "Dried organic pepino melons", "code": "50334637" },
      { "name": "Dried organic persian melons", "code": "50334638" },
      { "name": "Dried organic picnic melons", "code": "50334639" },
      { "name": "Dried organic piel de sapo melons", "code": "50334640" },
      { "name": "Dried organic pineapple melons", "code": "50334641" },
      { "name": "Dried organic quetzali melons", "code": "50334642" },
      { "name": "Dried organic red goblin melons", "code": "50334643" },
      { "name": "Dried organic regency melons", "code": "50334644" },
      { "name": "Dried organic royal majestic melons", "code": "50334645" },
      { "name": "Dried organic royal star melons", "code": "50334646" },
      { "name": "Dried organic royal sweet melons", "code": "50334647" },
      { "name": "Dried organic santa claus melons", "code": "50334648" },
      { "name": "Dried organic sharlyn melons", "code": "50334649" },
      { "name": "Dried organic spanish melons", "code": "50334650" },
      { "name": "Dried organic sprite melons", "code": "50334651" },
      { "name": "Dried organic starbright melons", "code": "50334652" },
      { "name": "Dried organic stars n stripes melons", "code": "50334653" },
      { "name": "Dried organic sugar baby melons", "code": "50334654" },
      { "name": "Dried organic sugar baby watermelon", "code": "50334655" },
      { "name": "Dried organic sunsweet melons", "code": "50334656" },
      {
        "name": "Dried organic sweet heart seedless watermelon",
        "code": "50334657"
      },
      { "name": "Dried organic temptation melons", "code": "50334658" },
      { "name": "Dried organic tiger baby melons", "code": "50334659" },
      { "name": "Dried organic tuscan type melons", "code": "50334660" },
      { "name": "Dried organic yellow baby watermelon", "code": "50334661" },
      { "name": "Dried organic mulberries", "code": "50334700" },
      { "name": "Dried organic black mulberries", "code": "50334701" },
      { "name": "Dried organic white mulberries", "code": "50334702" },
      { "name": "Dried organic bayberries or myrtles", "code": "50334800" },
      { "name": "Dried organic bog myrtle", "code": "50334801" },
      { "name": "Dried organic bayberries", "code": "50334802" },
      { "name": "Dried organic nectarines", "code": "50334900" },
      { "name": "Dried organic april glo nectarines", "code": "50334901" },
      { "name": "Dried organic arctic mist nectarines", "code": "50334902" },
      { "name": "Dried organic arctic snow nectarines", "code": "50334903" },
      { "name": "Dried organic arctic star nectarines", "code": "50334904" },
      { "name": "Dried organic arctic sweet nectarines", "code": "50334905" },
      { "name": "Dried organic arctic glo nectarines", "code": "50334906" },
      { "name": "Dried organic august fire nectarines", "code": "50334907" },
      { "name": "Dried organic august pearl nectarines", "code": "50334908" },
      { "name": "Dried organic august red nectarines", "code": "50334909" },
      { "name": "Dried organic autumn star nectarines", "code": "50334910" },
      { "name": "Dried organic big john nectarines", "code": "50334911" },
      { "name": "Dried organic bright pearl nectarines", "code": "50334912" },
      { "name": "Dried organic diamond bright nectarines", "code": "50334913" },
      { "name": "Dried organic diamond ray nectarines", "code": "50334914" },
      { "name": "Dried organic earliglo nectarines", "code": "50334915" },
      { "name": "Dried organic early diamond nectarines", "code": "50334916" },
      { "name": "Dried organic fairlane nectarines", "code": "50334917" },
      { "name": "Dried organic fantasia nectarines", "code": "50334918" },
      { "name": "Dried organic fire pearl nectarines", "code": "50334919" },
      { "name": "Dried organic fire sweet nectarines", "code": "50334920" },
      { "name": "Dried organic flamekist nectarines", "code": "50334921" },
      { "name": "Dried organic flat type nectarines", "code": "50334922" },
      { "name": "Dried organic garden delight nectarines", "code": "50334923" },
      { "name": "Dried organic goldmine nectarines", "code": "50334924" },
      { "name": "Dried organic grand pearl nectarines", "code": "50334925" },
      { "name": "Dried organic hardired nectarines", "code": "50334926" },
      { "name": "Dried organic honey blaze nectarines", "code": "50334927" },
      { "name": "Dried organic july red nectarines", "code": "50334928" },
      { "name": "Dried organic kay pearl nectarines", "code": "50334929" },
      { "name": "Dried organic kay sweet nectarines", "code": "50334930" },
      { "name": "Dried organic may diamond nectarines", "code": "50334931" },
      { "name": "Dried organic mayfire nectarines", "code": "50334932" },
      { "name": "Dried organic mayglo nectarines", "code": "50334933" },
      { "name": "Dried organic mericrest nectarines", "code": "50334934" },
      { "name": "Dried organic red diamond nectarines", "code": "50334935" },
      { "name": "Dried organic red gold nectarines", "code": "50334936" },
      { "name": "Dried organic red jim nectarines", "code": "50334937" },
      { "name": "Dried organic red roy nectarines", "code": "50334938" },
      { "name": "Dried organic rio red nectarines", "code": "50334939" },
      { "name": "Dried organic rose diamond nectarines", "code": "50334940" },
      { "name": "Dried organic royal glo nectarines", "code": "50334941" },
      { "name": "Dried organic ruby diamond nectarines", "code": "50334942" },
      { "name": "Dried organic ruby sweet nectarines", "code": "50334943" },
      { "name": "Dried organic ruddy jewel nectarines", "code": "50334944" },
      { "name": "Dried organic september red nectarines", "code": "50334945" },
      { "name": "Dried organic snowqueen nectarines", "code": "50334946" },
      { "name": "Dried organic spring bright nectarines", "code": "50334947" },
      { "name": "Dried organic spring red nectarines", "code": "50334948" },
      { "name": "Dried organic summer blush nectarines", "code": "50334949" },
      { "name": "Dried organic summer brite nectarines", "code": "50334950" },
      { "name": "Dried organic summer diamond nectarines", "code": "50334951" },
      { "name": "Dried organic summer fire nectarines", "code": "50334952" },
      { "name": "Dried organic summer grand nectarines", "code": "50334953" },
      { "name": "Dried organic sunglo nectarines", "code": "50334954" },
      { "name": "Dried organic zee fire nectarines", "code": "50334955" },
      { "name": "Dried organic zee glo nectarines", "code": "50334956" },
      { "name": "Dried organic zeegrand nectarines", "code": "50334957" },
      { "name": "Dried organic oranges", "code": "50335000" },
      { "name": "Dried organic african sour oranges", "code": "50335001" },
      { "name": "Dried organic ambersweet oranges", "code": "50335002" },
      { "name": "Dried organic argentine sour oranges", "code": "50335003" },
      { "name": "Dried organic bahianinha oranges", "code": "50335004" },
      { "name": "Dried organic bergamot oranges", "code": "50335005" },
      { "name": "Dried organic berna oranges", "code": "50335006" },
      { "name": "Dried organic bigaradier apepu oranges", "code": "50335007" },
      {
        "name": "Dried organic bittersweet daidai oranges",
        "code": "50335008"
      },
      { "name": "Dried organic blonde oranges", "code": "50335009" },
      { "name": "Dried organic blood oranges", "code": "50335010" },
      { "name": "Dried organic california navel oranges", "code": "50335011" },
      { "name": "Dried organic cara cara oranges", "code": "50335012" },
      { "name": "Dried organic chinotto oranges", "code": "50335013" },
      { "name": "Dried organic dream navel oranges", "code": "50335014" },
      { "name": "Dried organic gou tou oranges", "code": "50335015" },
      { "name": "Dried organic hamlin oranges", "code": "50335016" },
      { "name": "Dried organic jaffa oranges", "code": "50335017" },
      { "name": "Dried organic jincheng oranges", "code": "50335018" },
      { "name": "Dried organic k-early oranges", "code": "50335019" },
      { "name": "Dried organic kona oranges", "code": "50335020" },
      { "name": "Dried organic late navel oranges", "code": "50335021" },
      { "name": "Dried organic late valencia oranges", "code": "50335022" },
      { "name": "Dried organic limequat oranges", "code": "50335023" },
      { "name": "Dried organic marr oranges", "code": "50335024" },
      { "name": "Dried organic melogold oranges", "code": "50335025" },
      { "name": "Dried organic moro oranges", "code": "50335026" },
      { "name": "Dried organic moro tarocco oranges", "code": "50335027" },
      { "name": "Dried organic navel oranges", "code": "50335028" },
      { "name": "Dried organic navelina oranges", "code": "50335029" },
      { "name": "Dried organic oro blanco oranges", "code": "50335030" },
      { "name": "Dried organic osceola oranges", "code": "50335031" },
      { "name": "Dried organic parson brown oranges", "code": "50335032" },
      { "name": "Dried organic pera oranges", "code": "50335033" },
      { "name": "Dried organic pummulo oranges", "code": "50335034" },
      { "name": "Dried organic rhode red oranges", "code": "50335035" },
      { "name": "Dried organic roble oranges", "code": "50335036" },
      { "name": "Dried organic salustianas oranges", "code": "50335037" },
      { "name": "Dried organic sanguine oranges", "code": "50335038" },
      { "name": "Dried organic sanguinelli oranges", "code": "50335039" },
      { "name": "Dried organic seville oranges", "code": "50335040" },
      { "name": "Dried organic shamouti jaffa oranges", "code": "50335041" },
      { "name": "Dried organic tunis oranges", "code": "50335042" },
      { "name": "Dried organic valencia oranges", "code": "50335043" },
      { "name": "Dried organic washington navel oranges", "code": "50335044" },
      { "name": "Dried organic papayas", "code": "50335100" },
      { "name": "Dried organic green cooking papayas", "code": "50335101" },
      { "name": "Dried organic maradol papayas", "code": "50335102" },
      { "name": "Dried organic mexican yellow papayas", "code": "50335103" },
      { "name": "Dried organic mountain papayas", "code": "50335104" },
      { "name": "Dried organic solo papayas", "code": "50335105" },
      { "name": "Dried organic tainung papayas", "code": "50335106" },
      { "name": "Dried organic passion fruit", "code": "50335200" },
      { "name": "Dried organic banana passion fruit", "code": "50335201" },
      { "name": "Dried organic blue passion flower", "code": "50335202" },
      { "name": "Dried organic crackerjack passion fruit", "code": "50335203" },
      {
        "name": "Dried organic giant granadilla passion fruit",
        "code": "50335204"
      },
      {
        "name": "Dried organic golden granadilla passion fruit",
        "code": "50335205"
      },
      { "name": "Dried organic maypops passion fruit", "code": "50335206" },
      {
        "name": "Dried organic red granadilla passion fruit",
        "code": "50335207"
      },
      {
        "name": "Dried organic sweet granadilla passion fruit",
        "code": "50335208"
      },
      { "name": "Dried organic water lemon passion fruit", "code": "50335209" },
      {
        "name": "Dried organic wing-stemmed passion flower",
        "code": "50335210"
      },
      { "name": "Dried organic peaches", "code": "50335300" },
      { "name": "Dried organic amber crest peaches", "code": "50335301" },
      { "name": "Dried organic april snow peaches", "code": "50335302" },
      { "name": "Dried organic august lady peaches", "code": "50335303" },
      { "name": "Dried organic autumn flame peaches", "code": "50335304" },
      { "name": "Dried organic autumn lady peaches", "code": "50335305" },
      { "name": "Dried organic babcock peaches", "code": "50335306" },
      { "name": "Dried organic brittney lane peaches", "code": "50335307" },
      { "name": "Dried organic cary mac peaches", "code": "50335308" },
      { "name": "Dried organic classic peaches", "code": "50335309" },
      { "name": "Dried organic country sweet peaches", "code": "50335310" },
      { "name": "Dried organic crest haven peaches", "code": "50335311" },
      { "name": "Dried organic crimson lady peaches", "code": "50335312" },
      { "name": "Dried organic crown princess peaches", "code": "50335313" },
      { "name": "Dried organic david sun peaches", "code": "50335314" },
      { "name": "Dried organic diamond princess peaches", "code": "50335315" },
      { "name": "Dried organic earlirich peaches", "code": "50335316" },
      { "name": "Dried organic early majestic peaches", "code": "50335317" },
      { "name": "Dried organic early treat peaches", "code": "50335318" },
      { "name": "Dried organic elegant lady peaches", "code": "50335319" },
      { "name": "Dried organic empress peaches", "code": "50335320" },
      { "name": "Dried organic encore peaches", "code": "50335321" },
      { "name": "Dried organic fancy lady peaches", "code": "50335322" },
      { "name": "Dried organic fire prince peaches", "code": "50335323" },
      { "name": "Dried organic flame crest peaches", "code": "50335324" },
      { "name": "Dried organic flat type peaches", "code": "50335325" },
      { "name": "Dried organic flavorcrest peaches", "code": "50335326" },
      { "name": "Dried organic florida prince peaches", "code": "50335327" },
      { "name": "Dried organic full moon peaches", "code": "50335328" },
      { "name": "Dried organic harvester peaches", "code": "50335329" },
      { "name": "Dried organic ice princess peaches", "code": "50335330" },
      { "name": "Dried organic ivory princess peaches", "code": "50335331" },
      { "name": "Dried organic jersey queen peaches", "code": "50335332" },
      { "name": "Dried organic john henry peaches", "code": "50335333" },
      { "name": "Dried organic june prince peaches", "code": "50335334" },
      { "name": "Dried organic kaweah peaches", "code": "50335335" },
      { "name": "Dried organic klondike peaches", "code": "50335336" },
      { "name": "Dried organic lindo peaches", "code": "50335337" },
      { "name": "Dried organic loring peaches", "code": "50335338" },
      { "name": "Dried organic majestic peaches", "code": "50335339" },
      { "name": "Dried organic o'henry peaches", "code": "50335340" },
      { "name": "Dried organic queencrest peaches", "code": "50335341" },
      { "name": "Dried organic red lady peaches", "code": "50335342" },
      { "name": "Dried organic redglobe peaches", "code": "50335343" },
      { "name": "Dried organic redhaven peaches", "code": "50335344" },
      { "name": "Dried organic redtop peaches", "code": "50335345" },
      { "name": "Dried organic regina peaches", "code": "50335346" },
      { "name": "Dried organic rich lady peaches", "code": "50335347" },
      { "name": "Dried organic rich may peaches", "code": "50335348" },
      { "name": "Dried organic royal glory peaches", "code": "50335349" },
      { "name": "Dried organic royal lady peaches", "code": "50335350" },
      { "name": "Dried organic september snow peaches", "code": "50335351" },
      { "name": "Dried organic september sun peaches", "code": "50335352" },
      { "name": "Dried organic sierra gem peaches", "code": "50335353" },
      { "name": "Dried organic snow angel peaches", "code": "50335354" },
      { "name": "Dried organic snow gem peaches", "code": "50335355" },
      { "name": "Dried organic snow king peaches", "code": "50335356" },
      { "name": "Dried organic spring lady peaches", "code": "50335357" },
      { "name": "Dried organic spring snow peaches", "code": "50335358" },
      { "name": "Dried organic springcrest peaches", "code": "50335359" },
      { "name": "Dried organic sugar giant peaches", "code": "50335360" },
      { "name": "Dried organic sugar lady peaches", "code": "50335361" },
      { "name": "Dried organic sun bright peaches", "code": "50335362" },
      { "name": "Dried organic sunhigh peaches", "code": "50335363" },
      { "name": "Dried organic super lady peaches", "code": "50335364" },
      { "name": "Dried organic super rich peaches", "code": "50335365" },
      { "name": "Dried organic surecrop peaches", "code": "50335366" },
      { "name": "Dried organic sweet dream peaches", "code": "50335367" },
      { "name": "Dried organic sweet september peaches", "code": "50335368" },
      { "name": "Dried organic vista peaches", "code": "50335369" },
      { "name": "Dried organic white lady peaches", "code": "50335370" },
      { "name": "Dried organic zee lady peaches", "code": "50335371" },
      { "name": "Dried organic pears", "code": "50335400" },
      { "name": "Dried organic abate fetel pears", "code": "50335401" },
      { "name": "Dried organic anjou pears", "code": "50335402" },
      { "name": "Dried organic asian pears", "code": "50335403" },
      { "name": "Dried organic bartlett pears", "code": "50335404" },
      { "name": "Dried organic best ever pears", "code": "50335405" },
      { "name": "Dried organic beth pears", "code": "50335406" },
      { "name": "Dried organic beurre pears", "code": "50335407" },
      { "name": "Dried organic bosc pears", "code": "50335408" },
      { "name": "Dried organic clapp favorite pears", "code": "50335409" },
      { "name": "Dried organic comice pears", "code": "50335410" },
      { "name": "Dried organic concorde pears", "code": "50335411" },
      { "name": "Dried organic conference pears", "code": "50335412" },
      { "name": "Dried organic crimson red pears", "code": "50335413" },
      { "name": "Dried organic d'anjou pears", "code": "50335414" },
      { "name": "Dried organic dr jules guyot pears", "code": "50335415" },
      { "name": "Dried organic early pears", "code": "50335416" },
      { "name": "Dried organic emperor brown pears", "code": "50335417" },
      { "name": "Dried organic forelle pears", "code": "50335418" },
      { "name": "Dried organic french butter pears", "code": "50335419" },
      { "name": "Dried organic glou morceau pears", "code": "50335420" },
      { "name": "Dried organic hosui pears", "code": "50335421" },
      { "name": "Dried organic italian butter pears", "code": "50335422" },
      { "name": "Dried organic jargonelle pears", "code": "50335423" },
      { "name": "Dried organic juno pears", "code": "50335424" },
      {
        "name": "Dried organic kaiserlouise bonne de jersey pears",
        "code": "50335425"
      },
      { "name": "Dried organic keiffer pears", "code": "50335426" },
      { "name": "Dried organic kings royal pears", "code": "50335427" },
      { "name": "Dried organic limonera pears", "code": "50335428" },
      { "name": "Dried organic merton pride pears", "code": "50335429" },
      { "name": "Dried organic mountain bartlett pears", "code": "50335430" },
      { "name": "Dried organic olivier de serres pears", "code": "50335431" },
      { "name": "Dried organic onward pears", "code": "50335432" },
      { "name": "Dried organic packham's triumph pears", "code": "50335433" },
      { "name": "Dried organic paraiso pears", "code": "50335434" },
      { "name": "Dried organic passe crasanne pears", "code": "50335435" },
      { "name": "Dried organic perry pears", "code": "50335436" },
      { "name": "Dried organic red bartlett pears", "code": "50335437" },
      { "name": "Dried organic red d'anjou pears", "code": "50335438" },
      { "name": "Dried organic rocha pears", "code": "50335439" },
      { "name": "Dried organic rosey red pears", "code": "50335440" },
      { "name": "Dried organic rosy red pears", "code": "50335441" },
      { "name": "Dried organic royal majestic pears", "code": "50335442" },
      { "name": "Dried organic ruby red pears", "code": "50335443" },
      { "name": "Dried organic santa maria pears", "code": "50335444" },
      { "name": "Dried organic seckel pears", "code": "50335445" },
      { "name": "Dried organic sensation pears", "code": "50335446" },
      { "name": "Dried organic star crimson pears", "code": "50335447" },
      { "name": "Dried organic stark crimson pears", "code": "50335448" },
      { "name": "Dried organic summer bartlett pears", "code": "50335449" },
      { "name": "Dried organic summer gold pears", "code": "50335450" },
      { "name": "Dried organic sun gold pears", "code": "50335451" },
      { "name": "Dried organic sunsprite pears", "code": "50335452" },
      { "name": "Dried organic taylors gold pears", "code": "50335453" },
      { "name": "Dried organic taylors red pears", "code": "50335454" },
      { "name": "Dried organic tientsin pears", "code": "50335455" },
      { "name": "Dried organic tosca pears", "code": "50335456" },
      { "name": "Dried organic warden pears", "code": "50335457" },
      {
        "name": "Dried organic williams bon chretien pears",
        "code": "50335458"
      },
      { "name": "Dried organic williams pears", "code": "50335459" },
      { "name": "Dried organic winter nelis pears", "code": "50335460" },
      { "name": "Dried organic persimmons", "code": "50335500" },
      { "name": "Dried organic american persimmon", "code": "50335501" },
      { "name": "Dried organic black sapote persimmon", "code": "50335502" },
      { "name": "Dried organic chapote/black persimmon", "code": "50335503" },
      { "name": "Dried organic date plum persimmon", "code": "50335504" },
      { "name": "Dried organic fuyu persimmons", "code": "50335505" },
      { "name": "Dried organic giant fuyu persimmons", "code": "50335506" },
      { "name": "Dried organic hachiya persimmons", "code": "50335507" },
      {
        "name": "Dried organic mabolo/butter fruit persimmon",
        "code": "50335508"
      },
      { "name": "Dried organic principe ito persimmons", "code": "50335509" },
      {
        "name": "Dried organic royal brillante persimmons",
        "code": "50335510"
      },
      { "name": "Dried organic sharon fruit persimmon", "code": "50335511" },
      { "name": "Dried organic triumph persimmons", "code": "50335512" },
      { "name": "Dried organic pineapples", "code": "50335600" },
      { "name": "Dried organic cherimoya pineapple", "code": "50335601" },
      { "name": "Dried organic golden pineapple", "code": "50335602" },
      { "name": "Dried organic hilo pineapple", "code": "50335603" },
      { "name": "Dried organic kona sugarloaf pineapple", "code": "50335604" },
      { "name": "Dried organic natal queen pineapple", "code": "50335605" },
      { "name": "Dried organic pernambuco pineapple", "code": "50335606" },
      { "name": "Dried organic red spanish pineapple", "code": "50335607" },
      { "name": "Dried organic smooth cayenne pineapple", "code": "50335608" },
      { "name": "Dried organic sugarloaf pineapple", "code": "50335609" },
      { "name": "Dried organic variegated pineapple", "code": "50335610" },
      { "name": "Dried organic plucots", "code": "50335700" },
      { "name": "Dried organic black kat plucot", "code": "50335701" },
      { "name": "Dried organic blue gusto plucot", "code": "50335702" },
      { "name": "Dried organic crimson heart plucot", "code": "50335703" },
      { "name": "Dried organic dapple dandy plucot", "code": "50335704" },
      { "name": "Dried organic dapple fire plucot", "code": "50335705" },
      { "name": "Dried organic early dapple plucot", "code": "50335706" },
      { "name": "Dried organic flavor fall plucot", "code": "50335707" },
      { "name": "Dried organic flavor gold plucot", "code": "50335708" },
      { "name": "Dried organic flavor grenade plucot", "code": "50335709" },
      { "name": "Dried organic flavor heart plucot", "code": "50335710" },
      { "name": "Dried organic flavor jewel plucot", "code": "50335711" },
      { "name": "Dried organic flavor king plucot", "code": "50335712" },
      { "name": "Dried organic flavor queen plucot", "code": "50335713" },
      { "name": "Dried organic flavor supreme plucot", "code": "50335714" },
      { "name": "Dried organic flavor treat plucot", "code": "50335715" },
      { "name": "Dried organic flavorella plucot", "code": "50335716" },
      { "name": "Dried organic flavorich plucot", "code": "50335717" },
      { "name": "Dried organic flavorosa plucot", "code": "50335718" },
      { "name": "Dried organic geo pride plucot", "code": "50335719" },
      { "name": "Dried organic red kat plucot", "code": "50335720" },
      { "name": "Dried organic royal treat plucot", "code": "50335721" },
      { "name": "Dried organic sierra rose plucot", "code": "50335722" },
      { "name": "Dried organic sweet geisha plucot", "code": "50335723" },
      { "name": "Dried organic plums", "code": "50335800" },
      { "name": "Dried organic amber jewel plums", "code": "50335801" },
      { "name": "Dried organic angeleno plums", "code": "50335802" },
      { "name": "Dried organic aurora plums", "code": "50335803" },
      { "name": "Dried organic autumn beaut plums", "code": "50335804" },
      { "name": "Dried organic autumn giant plums", "code": "50335805" },
      { "name": "Dried organic autumn pride plums", "code": "50335806" },
      { "name": "Dried organic autumn rosa plums", "code": "50335807" },
      { "name": "Dried organic beach plum", "code": "50335808" },
      { "name": "Dried organic betty anne plums", "code": "50335809" },
      { "name": "Dried organic black beaut plums", "code": "50335810" },
      { "name": "Dried organic black bullace plum", "code": "50335811" },
      { "name": "Dried organic black diamond plums", "code": "50335812" },
      { "name": "Dried organic black giant plums", "code": "50335813" },
      { "name": "Dried organic black ice plums", "code": "50335814" },
      { "name": "Dried organic black splendor plums", "code": "50335815" },
      { "name": "Dried organic blackamber plums", "code": "50335816" },
      { "name": "Dried organic burgundy plums", "code": "50335817" },
      { "name": "Dried organic carlsbad plum", "code": "50335818" },
      { "name": "Dried organic casselman plums", "code": "50335819" },
      { "name": "Dried organic catalina plums", "code": "50335820" },
      { "name": "Dried organic damson plum", "code": "50335821" },
      { "name": "Dried organic dolly plums", "code": "50335822" },
      { "name": "Dried organic earliqueen plums", "code": "50335823" },
      { "name": "Dried organic early rosa plums", "code": "50335824" },
      { "name": "Dried organic ebony may plums", "code": "50335825" },
      { "name": "Dried organic ebony plums", "code": "50335826" },
      { "name": "Dried organic elephant heart plums", "code": "50335827" },
      { "name": "Dried organic emerald beaut plums", "code": "50335828" },
      { "name": "Dried organic empress plums", "code": "50335829" },
      { "name": "Dried organic freedom plums", "code": "50335830" },
      { "name": "Dried organic friar plums", "code": "50335831" },
      { "name": "Dried organic gar red plums", "code": "50335832" },
      { "name": "Dried organic governor's plum", "code": "50335833" },
      { "name": "Dried organic grand rosa plums", "code": "50335834" },
      { "name": "Dried organic green gage plum", "code": "50335835" },
      { "name": "Dried organic greengage plums", "code": "50335836" },
      { "name": "Dried organic hiromi plums", "code": "50335837" },
      { "name": "Dried organic hiromi red plums", "code": "50335838" },
      { "name": "Dried organic holiday plums", "code": "50335839" },
      { "name": "Dried organic howard sun plums", "code": "50335840" },
      { "name": "Dried organic interspecific type plums", "code": "50335841" },
      { "name": "Dried organic jamaican plum", "code": "50335842" },
      { "name": "Dried organic joanna red plums", "code": "50335843" },
      { "name": "Dried organic kelsey plums", "code": "50335844" },
      { "name": "Dried organic king james plums", "code": "50335845" },
      { "name": "Dried organic laroda plums", "code": "50335846" },
      { "name": "Dried organic late rosa plums", "code": "50335847" },
      { "name": "Dried organic linda rosa plums", "code": "50335848" },
      { "name": "Dried organic lone star red plums", "code": "50335849" },
      { "name": "Dried organic mariposa plums", "code": "50335850" },
      { "name": "Dried organic marked black plums", "code": "50335851" },
      { "name": "Dried organic marked red plums", "code": "50335852" },
      { "name": "Dried organic mirabelle plum", "code": "50335853" },
      { "name": "Dried organic october sun plums", "code": "50335854" },
      { "name": "Dried organic owen t plums", "code": "50335855" },
      { "name": "Dried organic perdrigon plum", "code": "50335856" },
      { "name": "Dried organic pink delight plums", "code": "50335857" },
      { "name": "Dried organic president plums", "code": "50335858" },
      { "name": "Dried organic primetime plums", "code": "50335859" },
      { "name": "Dried organic purple majesty plums", "code": "50335860" },
      { "name": "Dried organic queen rosa plums", "code": "50335861" },
      { "name": "Dried organic quetsch plum", "code": "50335862" },
      { "name": "Dried organic red beaut plums", "code": "50335863" },
      { "name": "Dried organic red lane plums", "code": "50335864" },
      { "name": "Dried organic red ram plums", "code": "50335865" },
      { "name": "Dried organic red rosa plums", "code": "50335866" },
      { "name": "Dried organic rich red plums", "code": "50335867" },
      { "name": "Dried organic rosemary plums", "code": "50335868" },
      { "name": "Dried organic royal diamond plums", "code": "50335869" },
      { "name": "Dried organic royal red plums", "code": "50335870" },
      { "name": "Dried organic royal zee plums", "code": "50335871" },
      { "name": "Dried organic roysum plums", "code": "50335872" },
      { "name": "Dried organic santa rosa plums", "code": "50335873" },
      { "name": "Dried organic saphire plums", "code": "50335874" },
      { "name": "Dried organic sloe plum", "code": "50335875" },
      { "name": "Dried organic st catherine plum", "code": "50335876" },
      { "name": "Dried organic white bullace plum", "code": "50335877" },
      { "name": "Dried organic pomegranates", "code": "50335900" },
      { "name": "Dried organic foothill pomegranates", "code": "50335901" },
      { "name": "Dried organic granada pomegranates", "code": "50335902" },
      { "name": "Dried organic jolly red pomegranates", "code": "50335903" },
      { "name": "Dried organic nana pomegranates", "code": "50335904" },
      { "name": "Dried organic pat's red pomegranates", "code": "50335905" },
      { "name": "Dried organic pinkhan pomegranates", "code": "50335906" },
      {
        "name": "Dried organic purple velvet pomegranates",
        "code": "50335907"
      },
      { "name": "Dried organic wonderful pomegranates", "code": "50335908" },
      { "name": "Dried organic pomelos", "code": "50336000" },
      { "name": "Dried organic chandler pomelo", "code": "50336001" },
      { "name": "Dried organic hirado buntan pomelo", "code": "50336002" },
      { "name": "Dried organic liang ping yau pomelo", "code": "50336003" },
      { "name": "Dried organic pandan wangi pomelo", "code": "50336004" },
      { "name": "Dried organic pink pomelo", "code": "50336005" },
      { "name": "Dried organic red shaddock pomelo", "code": "50336006" },
      { "name": "Dried organic siamese sweet pomelo", "code": "50336007" },
      { "name": "Dried organic wainwright pomelo", "code": "50336008" },
      { "name": "Dried organic quinces", "code": "50336100" },
      { "name": "Dried organic champion quince", "code": "50336101" },
      { "name": "Dried organic pineapple quince", "code": "50336102" },
      { "name": "Dried organic smyrna quince", "code": "50336103" },
      { "name": "Dried organic raspberries", "code": "50336200" },
      { "name": "Dried organic american red raspberry", "code": "50336201" },
      {
        "name": "Dried organic bailey queensland raspberry",
        "code": "50336202"
      },
      { "name": "Dried organic black raspberry", "code": "50336203" },
      { "name": "Dried organic dark raspberry", "code": "50336204" },
      { "name": "Dried organic delicious raspberry", "code": "50336205" },
      { "name": "Dried organic focke dwarf raspberry", "code": "50336206" },
      {
        "name": "Dried organic focke grayleaf red raspberry",
        "code": "50336207"
      },
      {
        "name": "Dried organic focke strawberry raspberry",
        "code": "50336208"
      },
      {
        "name": "Dried organic focke yellow himalayan raspberry",
        "code": "50336209"
      },
      { "name": "Dried organic gold raspberry", "code": "50336210" },
      { "name": "Dried organic gray new mexico raspberry", "code": "50336211" },
      {
        "name": "Dried organic jepson whitebark raspberry",
        "code": "50336212"
      },
      {
        "name": "Dried organic kellogg san diego raspberry",
        "code": "50336213"
      },
      {
        "name": "Dried organic leucodermis whitebark raspberry",
        "code": "50336214"
      },
      { "name": "Dried organic munz cuyamaca raspberry", "code": "50336215" },
      { "name": "Dried organic peck barton's raspberry", "code": "50336216" },
      { "name": "Dried organic purpleflowering raspberry", "code": "50336217" },
      { "name": "Dried organic roadside raspberry", "code": "50336218" },
      { "name": "Dried organic san diego raspberry", "code": "50336219" },
      { "name": "Dried organic snow raspberry", "code": "50336220" },
      { "name": "Dried organic snowpeaks raspberry", "code": "50336221" },
      { "name": "Dried organic strawberryleaf raspberry", "code": "50336222" },
      {
        "name": "Dried organic sweet cultivated raspberry",
        "code": "50336223"
      },
      {
        "name": "Dried organic torr and gray whitebark raspberry",
        "code": "50336224"
      },
      { "name": "Dried organic west indian raspberry", "code": "50336225" },
      { "name": "Dried organic whitebark raspberry", "code": "50336226" },
      { "name": "Dried organic wine raspberry", "code": "50336227" },
      {
        "name": "Dried organic yellow himalayan raspberry",
        "code": "50336228"
      },
      { "name": "Dried organic yu-shan raspberry", "code": "50336229" },
      { "name": "Dried organic rhubarb", "code": "50336300" },
      { "name": "Dried organic crimson red rhubarb", "code": "50336301" },
      { "name": "Dried organic early champagne rhubarb", "code": "50336302" },
      {
        "name": "Dried organic glaskin's perpetual rhubarb",
        "code": "50336303"
      },
      { "name": "Dried organic sutton rhubarb", "code": "50336304" },
      { "name": "Dried organic timperley early rhubarb", "code": "50336305" },
      { "name": "Dried organic valentine rhubarb", "code": "50336306" },
      { "name": "Dried organic victoria rhubarb", "code": "50336307" },
      { "name": "Dried organic zwolle seedling rhubarb", "code": "50336308" },
      { "name": "Dried organic macdonald rhubarb", "code": "50336309" },
      { "name": "Dried organic tilden rhubarb", "code": "50336310" },
      { "name": "Dried organic rose hips", "code": "50336400" },
      { "name": "Dried organic brier rose hips", "code": "50336401" },
      { "name": "Dried organic elgantine rose hips", "code": "50336402" },
      { "name": "Dried organic rugosa rose hips", "code": "50336403" },
      {
        "name": "Dried organic scotch or burnet rose hips",
        "code": "50336404"
      },
      { "name": "Dried organic sapotes", "code": "50336500" },
      { "name": "Dried organic white sapotes", "code": "50336501" },
      { "name": "Dried organic black sapotes", "code": "50336502" },
      { "name": "Dried organic saskatoon berries", "code": "50336600" },
      {
        "name": "Dried organic honeywood saskatoon berries",
        "code": "50336601"
      },
      {
        "name": "Dried organic northline saskatoon berries",
        "code": "50336602"
      },
      { "name": "Dried organic smoky saskatoon berries", "code": "50336603" },
      {
        "name": "Dried organic thiessen saskatoon berries",
        "code": "50336604"
      },
      { "name": "Dried organic strawberries", "code": "50336700" },
      { "name": "Dried organic chandler strawberries", "code": "50336701" },
      { "name": "Dried organic june bearing strawberries", "code": "50336702" },
      { "name": "Dried organic ever bearing strawberries", "code": "50336703" },
      { "name": "Dried organic sugar apple", "code": "50336800" },
      { "name": "Dried organic kampong mauve sugar apple", "code": "50336801" },
      { "name": "Dried organic seedless sugar apple", "code": "50336802" },
      { "name": "Dried organic thai lessard sugar apple", "code": "50336803" },
      { "name": "Dried organic tamarillo", "code": "50336900" },
      { "name": "Dried organic amberlea gold tamarillo", "code": "50336901" },
      { "name": "Dried organic bold gold tamarillo", "code": "50336902" },
      { "name": "Dried organic goldmine tamarillo", "code": "50336903" },
      { "name": "Dried organic oratia red tamarillo", "code": "50336904" },
      { "name": "Dried organic red beau tamarillo", "code": "50336905" },
      { "name": "Dried organic red delight tamarillo", "code": "50336906" },
      { "name": "Dried organic nominant fruits", "code": "50337000" },
      { "name": "Dried organic akee", "code": "50337001" },
      { "name": "Dried organic babaco", "code": "50337002" },
      { "name": "Dried organic banana flowers", "code": "50337003" },
      { "name": "Dried organic baobab", "code": "50337004" },
      { "name": "Dried organic bitter oranges", "code": "50337005" },
      { "name": "Dried organic canistel", "code": "50337006" },
      { "name": "Dried organic coconuts", "code": "50337007" },
      { "name": "Dried organic cloudberries", "code": "50337008" },
      { "name": "Dried organic dewberries", "code": "50337009" },
      { "name": "Dried organic durian", "code": "50337010" },
      { "name": "Dried organic elderberries", "code": "50337011" },
      { "name": "Dried organic feijoa", "code": "50337012" },
      { "name": "Dried organic hackberries", "code": "50337013" },
      { "name": "Dried organic hawthorn", "code": "50337014" },
      { "name": "Dried organic honeyberries", "code": "50337015" },
      { "name": "Dried organic jackfruit", "code": "50337016" },
      { "name": "Dried organic jambolan", "code": "50337017" },
      { "name": "Dried organic jujube", "code": "50337018" },
      { "name": "Dried organic lychee", "code": "50337019" },
      { "name": "Dried organic mangosteens", "code": "50337020" },
      { "name": "Dried organic medlars", "code": "50337021" },
      { "name": "Dried organic mombins", "code": "50337022" },
      { "name": "Dried organic monstera", "code": "50337023" },
      { "name": "Dried organic pepinos", "code": "50337024" },
      { "name": "Dried organic plantains", "code": "50337025" },
      { "name": "Dried organic prickly pears", "code": "50337026" },
      { "name": "Dried organic quenepas", "code": "50337027" },
      { "name": "Dried organic rambutan", "code": "50337028" },
      { "name": "Dried organic rose apples", "code": "50337029" },
      { "name": "Dried organic roselle", "code": "50337030" },
      { "name": "Dried organic rowanberries", "code": "50337031" },
      { "name": "Dried organic sea buckhorn berries", "code": "50337032" },
      { "name": "Dried organic silverberries", "code": "50337033" },
      { "name": "Dried organic sorb berries", "code": "50337034" },
      { "name": "Dried organic soursops", "code": "50337035" },
      { "name": "Dried organic star apples", "code": "50337036" },
      { "name": "Dried organic tamarindo", "code": "50337037" },
      { "name": "Dried organic chokeberries", "code": "50337100" },
      { "name": "Dried organic autumn magic chokeberries", "code": "50337101" },
      {
        "name": "Dried organic brillantisima chokeberries",
        "code": "50337102"
      },
      { "name": "Dried organic nero chokeberries", "code": "50337103" },
      { "name": "Dried organic viking chokeberries", "code": "50337104" },
      { "name": "Dried organic olives", "code": "50337200" },
      { "name": "Dried organic agrinion olives", "code": "50337201" },
      { "name": "Dried organic aleppo olives", "code": "50337202" },
      { "name": "Dried organic alphonso olives", "code": "50337203" },
      { "name": "Dried organic amphissa olives", "code": "50337204" },
      { "name": "Dried organic arauco olives", "code": "50337205" },
      { "name": "Dried organic arbequina olives", "code": "50337206" },
      { "name": "Dried organic atalanta olives", "code": "50337207" },
      { "name": "Dried organic cerignola olives", "code": "50337208" },
      { "name": "Dried organic cracked provencal olives", "code": "50337209" },
      { "name": "Dried organic empeltre olives", "code": "50337210" },
      { "name": "Dried organic gaeta olives", "code": "50337211" },
      { "name": "Dried organic hondroelia olives", "code": "50337212" },
      { "name": "Dried organic kalamata olives", "code": "50337213" },
      { "name": "Dried organic kura olives", "code": "50337214" },
      { "name": "Dried organic ligurian olives", "code": "50337215" },
      { "name": "Dried organic lucque olives", "code": "50337216" },
      { "name": "Dried organic lugano olives", "code": "50337217" },
      { "name": "Dried organic manzanilla olives", "code": "50337218" },
      { "name": "Dried organic marche olives", "code": "50337219" },
      { "name": "Dried organic mission olives", "code": "50337220" },
      { "name": "Dried organic nafplion green olives", "code": "50337221" },
      { "name": "Dried organic nicoise olives", "code": "50337222" },
      { "name": "Dried organic nyons olives", "code": "50337223" },
      { "name": "Dried organic picholine olives", "code": "50337224" },
      { "name": "Dried organic ponentine olives", "code": "50337225" },
      { "name": "Dried organic royal olives", "code": "50337226" },
      { "name": "Dried organic seracena olives", "code": "50337227" },
      { "name": "Dried organic sevillano olives", "code": "50337228" },
      { "name": "Dried organic sicilian olives", "code": "50337229" },
      { "name": "Dried organic toscanelle olives", "code": "50337230" },
      { "name": "Frozen fruit", "code": "50340000" },
      { "name": "Frozen apples", "code": "50341500" },
      { "name": "Frozen akane apples", "code": "50341501" },
      { "name": "Frozen ambrosia apples", "code": "50341502" },
      { "name": "Frozen api apples", "code": "50341503" },
      { "name": "Frozen baldwin apples", "code": "50341504" },
      { "name": "Frozen braeburn apples", "code": "50341505" },
      { "name": "Frozen bramley apples", "code": "50341506" },
      { "name": "Frozen bramley seedling apples", "code": "50341507" },
      { "name": "Frozen calville blanche d'hiver apples", "code": "50341508" },
      { "name": "Frozen cameo apples", "code": "50341509" },
      { "name": "Frozen charles ross apples", "code": "50341510" },
      { "name": "Frozen codlin apples", "code": "50341511" },
      { "name": "Frozen cortland apples", "code": "50341512" },
      { "name": "Frozen costard apples", "code": "50341513" },
      { "name": "Frozen court pendu plat apples", "code": "50341514" },
      { "name": "Frozen cox's orange pippin apples", "code": "50341515" },
      { "name": "Frozen crab apples", "code": "50341516" },
      { "name": "Frozen crispin apples", "code": "50341517" },
      { "name": "Frozen delicious apples", "code": "50341518" },
      { "name": "Frozen duchess apples", "code": "50341519" },
      { "name": "Frozen earligold apples", "code": "50341520" },
      { "name": "Frozen early mcintosh apples", "code": "50341521" },
      { "name": "Frozen elstar apples", "code": "50341522" },
      { "name": "Frozen empire apples", "code": "50341523" },
      { "name": "Frozen flower of kent apples", "code": "50341524" },
      { "name": "Frozen fuji apples", "code": "50341525" },
      { "name": "Frozen gala apples", "code": "50341526" },
      { "name": "Frozen gascoyne's scarlet apples", "code": "50341527" },
      { "name": "Frozen gilliflower apples", "code": "50341528" },
      { "name": "Frozen ginger gold apples", "code": "50341529" },
      { "name": "Frozen gladstone apples", "code": "50341530" },
      { "name": "Frozen gloster apples", "code": "50341531" },
      { "name": "Frozen gold supreme apples", "code": "50341532" },
      { "name": "Frozen golden delicious apples", "code": "50341533" },
      { "name": "Frozen golden noble apples", "code": "50341534" },
      { "name": "Frozen granny smith apples", "code": "50341535" },
      { "name": "Frozen gravenstein apples", "code": "50341536" },
      { "name": "Frozen greening apples", "code": "50341537" },
      { "name": "Frozen greensleeves apples", "code": "50341538" },
      { "name": "Frozen honeycrisp apples", "code": "50341539" },
      { "name": "Frozen howgate wonder apples", "code": "50341540" },
      { "name": "Frozen ida red apples", "code": "50341541" },
      { "name": "Frozen james grieve apples", "code": "50341542" },
      { "name": "Frozen jersey mac apples", "code": "50341543" },
      { "name": "Frozen jester apples", "code": "50341544" },
      { "name": "Frozen jonagold apples", "code": "50341545" },
      { "name": "Frozen jonamac apples", "code": "50341546" },
      { "name": "Frozen jonathan apples", "code": "50341547" },
      { "name": "Frozen katy apples", "code": "50341548" },
      { "name": "Frozen kidd's orange red apples", "code": "50341549" },
      { "name": "Frozen lady apples", "code": "50341550" },
      { "name": "Frozen law rome apples", "code": "50341551" },
      { "name": "Frozen laxton apples", "code": "50341552" },
      { "name": "Frozen lord derby apples", "code": "50341553" },
      { "name": "Frozen macoun apples", "code": "50341554" },
      { "name": "Frozen mcintosh apples", "code": "50341555" },
      { "name": "Frozen mutsu apples", "code": "50341556" },
      { "name": "Frozen newtown pippin apples", "code": "50341557" },
      { "name": "Frozen northern spy apples", "code": "50341558" },
      { "name": "Frozen orleans reinette apples", "code": "50341559" },
      { "name": "Frozen ozark gold apples", "code": "50341560" },
      { "name": "Frozen pacific rose apples", "code": "50341561" },
      { "name": "Frozen paula red apples", "code": "50341562" },
      { "name": "Frozen pearmain apples", "code": "50341563" },
      { "name": "Frozen pink lady apples", "code": "50341564" },
      { "name": "Frozen pippin apples", "code": "50341565" },
      { "name": "Frozen pitmaston pineapple apples", "code": "50341566" },
      { "name": "Frozen pomme d'api apples", "code": "50341567" },
      { "name": "Frozen prime gold apples", "code": "50341568" },
      { "name": "Frozen red astrachan apples", "code": "50341569" },
      { "name": "Frozen red boscoop apples", "code": "50341570" },
      { "name": "Frozen red chief apples", "code": "50341571" },
      { "name": "Frozen red delicious apples", "code": "50341572" },
      { "name": "Frozen red gravenstein apples", "code": "50341573" },
      { "name": "Frozen red rome apples", "code": "50341574" },
      { "name": "Frozen red stayman apples", "code": "50341575" },
      { "name": "Frozen red york apples", "code": "50341576" },
      { "name": "Frozen reinette apples", "code": "50341577" },
      { "name": "Frozen rome beauty apples", "code": "50341578" },
      { "name": "Frozen russet apples", "code": "50341579" },
      { "name": "Frozen sierra beauty apples", "code": "50341580" },
      { "name": "Frozen spartan apples", "code": "50341581" },
      { "name": "Frozen stark crimson apples", "code": "50341582" },
      { "name": "Frozen starking apples", "code": "50341583" },
      { "name": "Frozen stayman apples", "code": "50341584" },
      { "name": "Frozen stayman winesap apples", "code": "50341585" },
      { "name": "Frozen summer rambo apples", "code": "50341586" },
      { "name": "Frozen tsugaru apples", "code": "50341587" },
      { "name": "Frozen twenty ounce apples", "code": "50341588" },
      { "name": "Frozen tydeman red apples", "code": "50341589" },
      { "name": "Frozen vistabella apples", "code": "50341590" },
      { "name": "Frozen wealthy apples", "code": "50341591" },
      { "name": "Frozen white joaneting apples", "code": "50341592" },
      { "name": "Frozen white transparent apples", "code": "50341593" },
      { "name": "Frozen winesap apples", "code": "50341594" },
      { "name": "Frozen worcester apples", "code": "50341595" },
      { "name": "Frozen york imperial apples", "code": "50341596" },
      { "name": "Frozen apricots", "code": "50341600" },
      { "name": "Frozen ambercot apricots", "code": "50341601" },
      { "name": "Frozen apache apricots", "code": "50341602" },
      { "name": "Frozen brittany gold apricots", "code": "50341603" },
      { "name": "Frozen black apricots", "code": "50341604" },
      { "name": "Frozen blenheim apricots", "code": "50341605" },
      { "name": "Frozen bonny apricots", "code": "50341606" },
      { "name": "Frozen bulida apricots", "code": "50341607" },
      { "name": "Frozen castlebrite apricots", "code": "50341608" },
      { "name": "Frozen clutha gold apricots", "code": "50341609" },
      { "name": "Frozen clutha sun apricots", "code": "50341610" },
      { "name": "Frozen darby royal apricots", "code": "50341611" },
      { "name": "Frozen dina apricots", "code": "50341612" },
      { "name": "Frozen earlicot apricots", "code": "50341613" },
      { "name": "Frozen earliman apricots", "code": "50341614" },
      { "name": "Frozen early bright apricots", "code": "50341615" },
      { "name": "Frozen flaming gold apricots", "code": "50341616" },
      { "name": "Frozen fresno apricots", "code": "50341617" },
      { "name": "Frozen gold brite apricots", "code": "50341618" },
      { "name": "Frozen goldbar apricots", "code": "50341619" },
      { "name": "Frozen golden sweet apricots", "code": "50341620" },
      { "name": "Frozen goldrich apricots", "code": "50341621" },
      { "name": "Frozen helena apricots", "code": "50341622" },
      { "name": "Frozen honeycot apricots", "code": "50341623" },
      { "name": "Frozen imperial apricots", "code": "50341624" },
      { "name": "Frozen jordanne apricots", "code": "50341625" },
      { "name": "Frozen jumbo cot apricots", "code": "50341626" },
      { "name": "Frozen kandy kot apricots", "code": "50341627" },
      { "name": "Frozen katy apricots", "code": "50341628" },
      { "name": "Frozen king apricots", "code": "50341629" },
      { "name": "Frozen lambertin apricots", "code": "50341630" },
      { "name": "Frozen lorna apricots", "code": "50341631" },
      { "name": "Frozen lulu belle apricots", "code": "50341632" },
      { "name": "Frozen modesto apricots", "code": "50341633" },
      { "name": "Frozen moorpark apricots", "code": "50341634" },
      { "name": "Frozen orangered apricots", "code": "50341635" },
      { "name": "Frozen palstein apricots", "code": "50341636" },
      { "name": "Frozen patterson apricots", "code": "50341637" },
      { "name": "Frozen perfection apricots", "code": "50341638" },
      { "name": "Frozen poppy apricots", "code": "50341639" },
      { "name": "Frozen poppycot apricots", "code": "50341640" },
      { "name": "Frozen queen apricots", "code": "50341641" },
      { "name": "Frozen riland apricots", "code": "50341642" },
      { "name": "Frozen rival apricots", "code": "50341643" },
      { "name": "Frozen robada apricots", "code": "50341644" },
      { "name": "Frozen royal apricots", "code": "50341645" },
      { "name": "Frozen royal blenheim apricots", "code": "50341646" },
      { "name": "Frozen royal orange apricots", "code": "50341647" },
      { "name": "Frozen sundrop apricots", "code": "50341648" },
      { "name": "Frozen tilton apricots", "code": "50341649" },
      { "name": "Frozen tomcot apricots", "code": "50341650" },
      { "name": "Frozen tracy apricots", "code": "50341651" },
      { "name": "Frozen tri gem apricots", "code": "50341652" },
      { "name": "Frozen valley gold apricots", "code": "50341653" },
      { "name": "Frozen westley apricots", "code": "50341654" },
      { "name": "Frozen york apricots", "code": "50341655" },
      { "name": "Frozen bananas", "code": "50341700" },
      { "name": "Frozen apple bananas", "code": "50341701" },
      { "name": "Frozen baby bananas", "code": "50341702" },
      { "name": "Frozen burro bananas", "code": "50341703" },
      { "name": "Frozen cavendish bananas", "code": "50341704" },
      { "name": "Frozen dominico bananas", "code": "50341705" },
      { "name": "Frozen green bananas", "code": "50341706" },
      { "name": "Frozen gros michel bananas", "code": "50341707" },
      { "name": "Frozen lacatan bananas", "code": "50341708" },
      { "name": "Frozen lady finger banana", "code": "50341709" },
      { "name": "Frozen manzano bananas", "code": "50341710" },
      { "name": "Frozen mysore bananas", "code": "50341711" },
      { "name": "Frozen pisang mas bananas", "code": "50341712" },
      { "name": "Frozen red bananas", "code": "50341713" },
      { "name": "Frozen saba bananas", "code": "50341714" },
      { "name": "Frozen sucrier bananas", "code": "50341715" },
      { "name": "Frozen barberries", "code": "50341800" },
      { "name": "Frozen paleleaf barberries", "code": "50341801" },
      { "name": "Frozen chenault barberries", "code": "50341802" },
      { "name": "Frozen red barberries", "code": "50341803" },
      { "name": "Frozen wintergreen barberries", "code": "50341804" },
      { "name": "Frozen korean barberries", "code": "50341805" },
      { "name": "Frozen mentor barberries", "code": "50341806" },
      { "name": "Frozen japanese barberries", "code": "50341807" },
      { "name": "Frozen atropurpurea barberries", "code": "50341808" },
      { "name": "Frozen aurea barberries", "code": "50341809" },
      { "name": "Frozen bagatelle barberries", "code": "50341810" },
      { "name": "Frozen crimson pygmy barberries", "code": "50341811" },
      { "name": "Frozen kobold barberries", "code": "50341812" },
      { "name": "Frozen warty barberries", "code": "50341813" },
      { "name": "Frozen european barberries", "code": "50341814" },
      { "name": "Frozen bearberries", "code": "50341900" },
      { "name": "Frozen alpine bearberries", "code": "50341901" },
      { "name": "Frozen red bearberries", "code": "50341902" },
      { "name": "Frozen common bearberries", "code": "50341903" },
      { "name": "Frozen blackberries", "code": "50342000" },
      { "name": "Frozen apache blackberries", "code": "50342001" },
      { "name": "Frozen black satin blackberries", "code": "50342002" },
      { "name": "Frozen boysenberries", "code": "50342003" },
      { "name": "Frozen cherokee blackberries", "code": "50342004" },
      { "name": "Frozen chester blackberries", "code": "50342005" },
      { "name": "Frozen dirksen blackberries", "code": "50342006" },
      { "name": "Frozen jostaberries", "code": "50342007" },
      { "name": "Frozen loganberries", "code": "50342008" },
      { "name": "Frozen marionberries", "code": "50342009" },
      { "name": "Frozen navaho blackberries", "code": "50342010" },
      { "name": "Frozen nectarberries", "code": "50342011" },
      { "name": "Frozen olallie blackberries", "code": "50342012" },
      { "name": "Frozen tayberries", "code": "50342013" },
      { "name": "Frozen thornless hull blackberries", "code": "50342014" },
      { "name": "Frozen youngberries", "code": "50342015" },
      { "name": "Frozen bilberries", "code": "50342100" },
      { "name": "Frozen bog bilberries", "code": "50342101" },
      { "name": "Frozen dwarf bilberries", "code": "50342102" },
      { "name": "Frozen mountain bilberries", "code": "50342103" },
      { "name": "Frozen oval-leaved bilberries", "code": "50342104" },
      { "name": "Frozen blueberries", "code": "50342200" },
      { "name": "Frozen bluecrop blueberries", "code": "50342201" },
      { "name": "Frozen bluetta blueberries", "code": "50342202" },
      { "name": "Frozen brigitta blueberries", "code": "50342203" },
      { "name": "Frozen chandler blueberries", "code": "50342204" },
      { "name": "Frozen duke blueberries", "code": "50342205" },
      { "name": "Frozen hardyblue blueberries", "code": "50342206" },
      { "name": "Frozen legacy blueberries", "code": "50342207" },
      { "name": "Frozen misty blueberries", "code": "50342208" },
      { "name": "Frozen nelson blueberries", "code": "50342209" },
      { "name": "Frozen northblue blueberries", "code": "50342210" },
      { "name": "Frozen northcountry blueberries", "code": "50342211" },
      { "name": "Frozen northsky blueberries", "code": "50342212" },
      { "name": "Frozen patriot blueberries", "code": "50342213" },
      { "name": "Frozen spartan blueberries", "code": "50342214" },
      { "name": "Frozen toro blueberries", "code": "50342215" },
      { "name": "Frozen breadfruit", "code": "50342300" },
      { "name": "Frozen chataigne breadfruit", "code": "50342301" },
      { "name": "Frozen seedless breadfruit", "code": "50342302" },
      { "name": "Frozen white heart breadfruit", "code": "50342303" },
      { "name": "Frozen yellow heart breadfruit", "code": "50342304" },
      { "name": "Frozen cherimoyas", "code": "50342400" },
      { "name": "Frozen bays cherimoya", "code": "50342401" },
      { "name": "Frozen bronceada cherimoya", "code": "50342402" },
      { "name": "Frozen burtons cherimoya", "code": "50342403" },
      { "name": "Frozen burtons favorite cherimoya", "code": "50342404" },
      { "name": "Frozen jete cherimoya", "code": "50342405" },
      { "name": "Frozen reretai cherimoya", "code": "50342406" },
      { "name": "Frozen smoothey cherimoya", "code": "50342407" },
      { "name": "Frozen spain cherimoya", "code": "50342408" },
      { "name": "Frozen white cherimoya", "code": "50342409" },
      { "name": "Frozen cherries", "code": "50342500" },
      { "name": "Frozen amarelle cherries", "code": "50342501" },
      { "name": "Frozen brooks cherries", "code": "50342502" },
      { "name": "Frozen bigarreu cherries", "code": "50342503" },
      { "name": "Frozen bing cherries", "code": "50342504" },
      { "name": "Frozen black republic cherries", "code": "50342505" },
      { "name": "Frozen black schmidt cherries", "code": "50342506" },
      { "name": "Frozen black tartarian cherries", "code": "50342507" },
      { "name": "Frozen fiesta bing cherries", "code": "50342508" },
      { "name": "Frozen garnet cherries", "code": "50342509" },
      { "name": "Frozen king cherries", "code": "50342510" },
      { "name": "Frozen chapman cherries", "code": "50342511" },
      { "name": "Frozen lapin cherries", "code": "50342512" },
      { "name": "Frozen larian cherries", "code": "50342513" },
      { "name": "Frozen dark guines cherries", "code": "50342514" },
      { "name": "Frozen montmorency cherries", "code": "50342515" },
      { "name": "Frozen duke cherries", "code": "50342516" },
      { "name": "Frozen early rivers cherries", "code": "50342517" },
      { "name": "Frozen ruby bing cherries", "code": "50342518" },
      { "name": "Frozen santina cherries", "code": "50342519" },
      { "name": "Frozen geans/guines cherries", "code": "50342520" },
      { "name": "Frozen sonata cherries", "code": "50342521" },
      { "name": "Frozen lambert cherries", "code": "50342522" },
      { "name": "Frozen stella cherries", "code": "50342523" },
      { "name": "Frozen sweetheart cherries", "code": "50342524" },
      { "name": "Frozen tartarian cherries", "code": "50342525" },
      { "name": "Frozen maraschino cherries", "code": "50342527" },
      { "name": "Frozen van cherries", "code": "50342528" },
      { "name": "Frozen morello cherries", "code": "50342529" },
      { "name": "Frozen royal ann cherries", "code": "50342530" },
      { "name": "Frozen ranier cherries", "code": "50342531" },
      { "name": "Frozen royal cherries", "code": "50342532" },
      { "name": "Frozen citrons", "code": "50342600" },
      { "name": "Frozen buddha's hand citrons", "code": "50342601" },
      { "name": "Frozen fingered citrons", "code": "50342602" },
      { "name": "Frozen fo shoukan citrons", "code": "50342603" },
      { "name": "Frozen bushakan citrons", "code": "50342604" },
      { "name": "Frozen diamante citrons", "code": "50342605" },
      { "name": "Frozen etrog citrons", "code": "50342606" },
      { "name": "Frozen ponderosa citrons", "code": "50342607" },
      { "name": "Frozen cranberries", "code": "50342700" },
      { "name": "Frozen ben lear cranberries", "code": "50342701" },
      { "name": "Frozen early black cranberries", "code": "50342702" },
      { "name": "Frozen grycleski cranberries", "code": "50342703" },
      { "name": "Frozen howe cranberries", "code": "50342704" },
      { "name": "Frozen lingonberries", "code": "50342705" },
      { "name": "Frozen mcfarlin cranberries", "code": "50342706" },
      { "name": "Frozen mountain cranberries", "code": "50342707" },
      { "name": "Frozen pilgrim cranberries", "code": "50342708" },
      { "name": "Frozen searless cranberries", "code": "50342709" },
      { "name": "Frozen stevens cranberries", "code": "50342710" },
      { "name": "Frozen currants", "code": "50342800" },
      { "name": "Frozen hudson bay currants", "code": "50342801" },
      { "name": "Frozen waxy currants", "code": "50342802" },
      { "name": "Frozen desert currants", "code": "50342803" },
      { "name": "Frozen black currants", "code": "50342804" },
      { "name": "Frozen red currants", "code": "50342805" },
      { "name": "Frozen white currants", "code": "50342806" },
      { "name": "Frozen dates", "code": "50342900" },
      { "name": "Frozen asharasi dates", "code": "50342901" },
      { "name": "Frozen barhi or barhee dates", "code": "50342902" },
      { "name": "Frozen deglet noor dates", "code": "50342903" },
      { "name": "Frozen fardh dates", "code": "50342904" },
      { "name": "Frozen gundila dates", "code": "50342905" },
      { "name": "Frozen halawi/halawy dates", "code": "50342906" },
      { "name": "Frozen hilali dates", "code": "50342907" },
      { "name": "Frozen khadrawi/khadrawy dates", "code": "50342908" },
      { "name": "Frozen khalas dates", "code": "50342909" },
      { "name": "Frozen khustawi dates", "code": "50342910" },
      { "name": "Frozen khidri dates", "code": "50342911" },
      { "name": "Frozen medjool/medjul dates", "code": "50342912" },
      { "name": "Frozen mactoum dates", "code": "50342913" },
      { "name": "Frozen neghal dates", "code": "50342914" },
      { "name": "Frozen yatimeh dates", "code": "50342915" },
      { "name": "Frozen zahidi dates", "code": "50342916" },
      { "name": "Frozen dragonfruit", "code": "50343000" },
      { "name": "Frozen pink dragonfruit", "code": "50343001" },
      { "name": "Frozen yellow dragonfruit", "code": "50343002" },
      { "name": "Frozen figs", "code": "50343100" },
      { "name": "Frozen bardajic figs", "code": "50343101" },
      { "name": "Frozen brown turkey figs", "code": "50343102" },
      { "name": "Frozen calimyrna figs", "code": "50343103" },
      { "name": "Frozen conadria figs", "code": "50343104" },
      { "name": "Frozen dottado figs", "code": "50343105" },
      { "name": "Frozen kadota figs", "code": "50343106" },
      { "name": "Frozen mediterranean figs", "code": "50343107" },
      { "name": "Frozen mission figs", "code": "50343108" },
      { "name": "Frozen smyrna figs", "code": "50343109" },
      { "name": "Frozen verdona figs", "code": "50343110" },
      { "name": "Frozen white king figs", "code": "50343111" },
      { "name": "Frozen gooseberries", "code": "50343200" },
      { "name": "Frozen early sulphur gooseberries", "code": "50343201" },
      { "name": "Frozen goldendrop gooseberries", "code": "50343202" },
      { "name": "Frozen langley gage gooseberries", "code": "50343203" },
      { "name": "Frozen leveller gooseberries", "code": "50343204" },
      { "name": "Frozen london gooseberries", "code": "50343205" },
      { "name": "Frozen worcestershire gooseberries", "code": "50343206" },
      {
        "name": "Frozen american worcesterberry gooseberries",
        "code": "50343207"
      },
      { "name": "Frozen grapefruit", "code": "50343300" },
      { "name": "Frozen burgundy grapefruit", "code": "50343301" },
      { "name": "Frozen duncan grapefruit", "code": "50343302" },
      { "name": "Frozen foster grapefruit", "code": "50343303" },
      { "name": "Frozen marsh grapefruit", "code": "50343304" },
      { "name": "Frozen new zealand grapefruit", "code": "50343305" },
      { "name": "Frozen rio red grapefruit", "code": "50343306" },
      { "name": "Frozen ruby red grapefruit", "code": "50343307" },
      { "name": "Frozen star ruby grapefruit", "code": "50343308" },
      { "name": "Frozen triumph grapefruit", "code": "50343309" },
      { "name": "Frozen table grapes", "code": "50343400" },
      { "name": "Frozen alicante grapes", "code": "50343401" },
      { "name": "Frozen almeria grapes", "code": "50343402" },
      { "name": "Frozen alphonse lavalle grapes", "code": "50343403" },
      { "name": "Frozen autumn king grapes", "code": "50343404" },
      { "name": "Frozen autumn royal grapes", "code": "50343405" },
      { "name": "Frozen autumn seedless grapes", "code": "50343406" },
      { "name": "Frozen baresana grapes", "code": "50343407" },
      { "name": "Frozen barlinka grapes", "code": "50343408" },
      { "name": "Frozen beauty seedless grapes", "code": "50343409" },
      { "name": "Frozen black beauty seedless grapes", "code": "50343410" },
      { "name": "Frozen black emerald grapes", "code": "50343411" },
      { "name": "Frozen black giant grapes", "code": "50343412" },
      { "name": "Frozen black globe grapes", "code": "50343413" },
      { "name": "Frozen black monukka grapes", "code": "50343414" },
      { "name": "Frozen black pearl grapes", "code": "50343415" },
      { "name": "Frozen black seedless grapes", "code": "50343416" },
      { "name": "Frozen bonheur grapes", "code": "50343417" },
      { "name": "Frozen calmeria grapes", "code": "50343418" },
      { "name": "Frozen cardinal grapes", "code": "50343419" },
      { "name": "Frozen catawba grapes", "code": "50343420" },
      {
        "name": "Frozen chasselas/golden chasselas grapes",
        "code": "50343421"
      },
      { "name": "Frozen christmas rose grapes", "code": "50343422" },
      { "name": "Frozen concord grapes", "code": "50343423" },
      { "name": "Frozen concord seedless grapes", "code": "50343424" },
      { "name": "Frozen crimson seedless grapes", "code": "50343425" },
      { "name": "Frozen dauphine grapes", "code": "50343426" },
      { "name": "Frozen delaware grapes", "code": "50343427" },
      { "name": "Frozen early muscat grapes", "code": "50343428" },
      { "name": "Frozen early sweet grapes", "code": "50343429" },
      { "name": "Frozen emerald seedless grapes", "code": "50343430" },
      { "name": "Frozen emperatriz grapes", "code": "50343431" },
      { "name": "Frozen emperor grapes", "code": "50343432" },
      { "name": "Frozen empress grapes", "code": "50343433" },
      { "name": "Frozen exotic grapes", "code": "50343434" },
      { "name": "Frozen fantasy grapes", "code": "50343435" },
      { "name": "Frozen fantasy seedless grapes", "code": "50343436" },
      { "name": "Frozen flame grapes", "code": "50343437" },
      { "name": "Frozen flame seedless grapes", "code": "50343438" },
      { "name": "Frozen flame tokay grapes", "code": "50343439" },
      { "name": "Frozen flaming red grapes", "code": "50343440" },
      { "name": "Frozen galaxy seedless grapes", "code": "50343441" },
      { "name": "Frozen gamay grapes", "code": "50343442" },
      { "name": "Frozen gold grapes", "code": "50343443" },
      { "name": "Frozen hanepoot or honeypot grapes", "code": "50343444" },
      { "name": "Frozen italia grapes", "code": "50343445" },
      { "name": "Frozen jade seedless grapes", "code": "50343446" },
      { "name": "Frozen jubilee grapes", "code": "50343447" },
      { "name": "Frozen king ruby grapes", "code": "50343448" },
      { "name": "Frozen kyoho grapes", "code": "50343449" },
      { "name": "Frozen la rochelle grapes", "code": "50343450" },
      { "name": "Frozen lady finger grapes", "code": "50343451" },
      { "name": "Frozen late seedless grapes", "code": "50343452" },
      { "name": "Frozen majestic seedless grapes", "code": "50343453" },
      { "name": "Frozen malaga grapes", "code": "50343454" },
      { "name": "Frozen marroo seedless grapes", "code": "50343455" },
      { "name": "Frozen muscadine grapes", "code": "50343456" },
      { "name": "Frozen muscat flame grapes", "code": "50343457" },
      { "name": "Frozen muscat grapes", "code": "50343458" },
      { "name": "Frozen muscat seedless grapes", "code": "50343459" },
      { "name": "Frozen napoleon grapes", "code": "50343460" },
      { "name": "Frozen negria grapes", "code": "50343461" },
      { "name": "Frozen new cross grapes", "code": "50343462" },
      { "name": "Frozen niabell grapes", "code": "50343463" },
      { "name": "Frozen niagara grapes", "code": "50343464" },
      { "name": "Frozen olivette grapes", "code": "50343465" },
      { "name": "Frozen perlette grapes", "code": "50343466" },
      { "name": "Frozen perlon grapes", "code": "50343467" },
      { "name": "Frozen prima black seedless grapes", "code": "50343468" },
      { "name": "Frozen princess grapes", "code": "50343469" },
      { "name": "Frozen queen grapes", "code": "50343470" },
      { "name": "Frozen red blush grapes", "code": "50343471" },
      { "name": "Frozen red globe grapes", "code": "50343472" },
      { "name": "Frozen red malaga grapes", "code": "50343473" },
      { "name": "Frozen red seedless grapes", "code": "50343474" },
      { "name": "Frozen regina grapes", "code": "50343475" },
      { "name": "Frozen ribier grapes", "code": "50343476" },
      { "name": "Frozen rosita grapes", "code": "50343477" },
      { "name": "Frozen rouge grapes", "code": "50343478" },
      { "name": "Frozen royal black seedless grapes", "code": "50343479" },
      { "name": "Frozen ruby red seedless grapes", "code": "50343480" },
      { "name": "Frozen ruby seedless grapes", "code": "50343481" },
      { "name": "Frozen scarlet royal grapes", "code": "50343482" },
      { "name": "Frozen scuppernong grapes", "code": "50343483" },
      { "name": "Frozen sugarose grapes", "code": "50343484" },
      { "name": "Frozen sugarthirteen grapes", "code": "50343485" },
      { "name": "Frozen sugraone grapes", "code": "50343486" },
      { "name": "Frozen sugrasixteen grapes", "code": "50343487" },
      { "name": "Frozen sultana sun red grapes", "code": "50343488" },
      { "name": "Frozen summer royal grapes", "code": "50343489" },
      { "name": "Frozen sunset grapes", "code": "50343490" },
      { "name": "Frozen superior seedless grapes", "code": "50343491" },
      { "name": "Frozen thompson seedless grapes", "code": "50343492" },
      { "name": "Frozen tokay/pinot gris grapes", "code": "50343493" },
      { "name": "Frozen waltman cross grapes", "code": "50343494" },
      { "name": "Frozen white seedless grapes", "code": "50343495" },
      { "name": "Frozen zante current grapes", "code": "50343496" },
      { "name": "Frozen raisin grapes", "code": "50343500" },
      { "name": "Frozen black corinth grapes", "code": "50343501" },
      { "name": "Frozen canner grapes", "code": "50343502" },
      { "name": "Frozen dovine grapes", "code": "50343503" },
      { "name": "Frozen fiesta grapes", "code": "50343504" },
      { "name": "Frozen selma pete grapes", "code": "50343505" },
      { "name": "Frozen sultana grapes", "code": "50343506" },
      { "name": "Frozen wine grapes", "code": "50343600" },
      { "name": "Frozen alicante bouschet grapes", "code": "50343601" },
      { "name": "Frozen barbera grapes", "code": "50343602" },
      { "name": "Frozen burger grapes", "code": "50343603" },
      { "name": "Frozen cabernet franc grapes", "code": "50343604" },
      { "name": "Frozen cabernet sauvignon grapes", "code": "50343605" },
      { "name": "Frozen carignane grapes", "code": "50343606" },
      { "name": "Frozen carnelian grapes", "code": "50343607" },
      { "name": "Frozen catarratto grapes", "code": "50343608" },
      { "name": "Frozen centurian grapes", "code": "50343609" },
      { "name": "Frozen charbono grapes", "code": "50343610" },
      { "name": "Frozen chardonnay grapes", "code": "50343611" },
      { "name": "Frozen chenin blanc grapes", "code": "50343612" },
      { "name": "Frozen cinsaut grapes", "code": "50343613" },
      { "name": "Frozen dolcetto grapes", "code": "50343614" },
      { "name": "Frozen emerald riesling grapes", "code": "50343615" },
      { "name": "Frozen french colombard grapes", "code": "50343616" },
      { "name": "Frozen gamay napa grapes", "code": "50343617" },
      { "name": "Frozen gamay beaujolais grapes", "code": "50343618" },
      { "name": "Frozen gewurztraminer grapes", "code": "50343619" },
      { "name": "Frozen grenache grapes", "code": "50343620" },
      { "name": "Frozen grenache blanc grapes", "code": "50343621" },
      { "name": "Frozen lagrein grapes", "code": "50343622" },
      { "name": "Frozen lambrusco grapes", "code": "50343623" },
      { "name": "Frozen malbec grapes", "code": "50343624" },
      { "name": "Frozen malvasia bianca grapes", "code": "50343625" },
      { "name": "Frozen marsanne grapes", "code": "50343626" },
      { "name": "Frozen mataro grapes", "code": "50343627" },
      { "name": "Frozen merlot grapes", "code": "50343628" },
      { "name": "Frozen meunier grapes", "code": "50343629" },
      { "name": "Frozen mission grapes", "code": "50343630" },
      { "name": "Frozen montepulciano grapes", "code": "50343631" },
      { "name": "Frozen muscat blanc grapes", "code": "50343632" },
      { "name": "Frozen muscat hamburg grapes", "code": "50343633" },
      { "name": "Frozen muscat of alexandria grapes", "code": "50343634" },
      { "name": "Frozen muscat orange grapes", "code": "50343635" },
      { "name": "Frozen nebbiolo grapes", "code": "50343636" },
      { "name": "Frozen palomino grapes", "code": "50343637" },
      { "name": "Frozen petit verdot grapes", "code": "50343638" },
      { "name": "Frozen petite sirah grapes", "code": "50343639" },
      { "name": "Frozen pinot blanc grapes", "code": "50343640" },
      { "name": "Frozen pinot gris grapes", "code": "50343641" },
      { "name": "Frozen pinot noir grapes", "code": "50343642" },
      { "name": "Frozen primitivo grapes", "code": "50343643" },
      { "name": "Frozen roussanne grapes", "code": "50343644" },
      { "name": "Frozen royalty grapes", "code": "50343645" },
      { "name": "Frozen rubired grapes", "code": "50343646" },
      { "name": "Frozen ruby cabernet grapes", "code": "50343647" },
      { "name": "Frozen salvador grapes", "code": "50343648" },
      { "name": "Frozen sangiovese grapes", "code": "50343649" },
      { "name": "Frozen sauvignon blanc grapes", "code": "50343650" },
      { "name": "Frozen sauvignon musque grapes", "code": "50343651" },
      { "name": "Frozen semillon grapes", "code": "50343652" },
      { "name": "Frozen souzao grapes", "code": "50343653" },
      { "name": "Frozen st emilion grapes", "code": "50343654" },
      { "name": "Frozen symphony grapes", "code": "50343655" },
      { "name": "Frozen syrah grapes", "code": "50343656" },
      { "name": "Frozen tannat grapes", "code": "50343657" },
      { "name": "Frozen tempranillo grapes", "code": "50343658" },
      { "name": "Frozen teroldego grapes", "code": "50343659" },
      { "name": "Frozen tocai friulano grapes", "code": "50343660" },
      { "name": "Frozen touriga nacional grapes", "code": "50343661" },
      { "name": "Frozen triplett blanc grapes", "code": "50343662" },
      { "name": "Frozen viognier grapes", "code": "50343663" },
      { "name": "Frozen white riesling grapes", "code": "50343664" },
      { "name": "Frozen zinfandel grapes", "code": "50343665" },
      { "name": "Frozen guavas", "code": "50343700" },
      { "name": "Frozen beaumont guavas", "code": "50343701" },
      { "name": "Frozen carrley guavas", "code": "50343702" },
      { "name": "Frozen lucida guavas", "code": "50343703" },
      { "name": "Frozen pineapple guava", "code": "50343704" },
      { "name": "Frozen huckleberries", "code": "50343800" },
      { "name": "Frozen black winter huckleberries", "code": "50343801" },
      { "name": "Frozen cascade huckleberries", "code": "50343802" },
      { "name": "Frozen dwarf huckleberries", "code": "50343803" },
      { "name": "Frozen mountain huckleberries", "code": "50343804" },
      { "name": "Frozen red huckleberries", "code": "50343805" },
      { "name": "Frozen kiwi fruit", "code": "50343900" },
      { "name": "Frozen ananasnaja kiwi fruit", "code": "50343901" },
      { "name": "Frozen arctic beauty kiwi fruit", "code": "50343902" },
      { "name": "Frozen blake kiwi fruit", "code": "50343903" },
      { "name": "Frozen hayward kiwi fruit", "code": "50343904" },
      { "name": "Frozen issai kiwi fruit", "code": "50343905" },
      { "name": "Frozen siberian kiwi fruit", "code": "50343906" },
      { "name": "Frozen kumquats", "code": "50344000" },
      { "name": "Frozen hong kong kumquats", "code": "50344001" },
      { "name": "Frozen limequat kumquats", "code": "50344002" },
      { "name": "Frozen long fruit kumquats", "code": "50344003" },
      { "name": "Frozen malayan kumquats", "code": "50344004" },
      { "name": "Frozen meiwa kumquats", "code": "50344005" },
      { "name": "Frozen nagami kumquats", "code": "50344006" },
      { "name": "Frozen lemons", "code": "50344100" },
      { "name": "Frozen baboon lemons", "code": "50344101" },
      { "name": "Frozen bearss sicilian lemons", "code": "50344102" },
      { "name": "Frozen cameron highlands lemons", "code": "50344103" },
      { "name": "Frozen escondido lemons", "code": "50344104" },
      { "name": "Frozen eureka lemons", "code": "50344105" },
      { "name": "Frozen lisbon lemons", "code": "50344106" },
      { "name": "Frozen meyer lemons", "code": "50344107" },
      { "name": "Frozen volkamer lemons", "code": "50344108" },
      { "name": "Frozen limes", "code": "50344200" },
      { "name": "Frozen indian sweet limes", "code": "50344201" },
      { "name": "Frozen key limes", "code": "50344202" },
      { "name": "Frozen mandarin limes", "code": "50344203" },
      { "name": "Frozen philippine limes", "code": "50344204" },
      { "name": "Frozen tahitian limes", "code": "50344205" },
      { "name": "Frozen bearss limes", "code": "50344206" },
      { "name": "Frozen persian limes", "code": "50344207" },
      { "name": "Frozen seedless limes", "code": "50344208" },
      { "name": "Frozen loquats", "code": "50344300" },
      { "name": "Frozen advance loquats", "code": "50344301" },
      { "name": "Frozen benlehr loquats", "code": "50344302" },
      { "name": "Frozen big jim loquats", "code": "50344303" },
      { "name": "Frozen champagne loquats", "code": "50344304" },
      { "name": "Frozen early red loquats", "code": "50344305" },
      { "name": "Frozen gold nugget loquats", "code": "50344306" },
      { "name": "Frozen herd's mammoth loquats", "code": "50344307" },
      { "name": "Frozen mogi loquats", "code": "50344308" },
      { "name": "Frozen mrs cooksey loquats", "code": "50344309" },
      { "name": "Frozen strawberry loquats", "code": "50344310" },
      { "name": "Frozen tanaka loquats", "code": "50344311" },
      { "name": "Frozen victory vista white loquats", "code": "50344312" },
      { "name": "Frozen wolfe loquats", "code": "50344313" },
      { "name": "Frozen mandarin oranges or tangerines", "code": "50344400" },
      { "name": "Frozen clauselinas oranges", "code": "50344401" },
      { "name": "Frozen clementine tangerines", "code": "50344402" },
      { "name": "Frozen cleopatra mandarin oranges", "code": "50344403" },
      { "name": "Frozen dancy tangerines", "code": "50344404" },
      { "name": "Frozen ellensdale oranges", "code": "50344405" },
      { "name": "Frozen fairchild oranges", "code": "50344406" },
      { "name": "Frozen fallglo oranges", "code": "50344407" },
      { "name": "Frozen fortune oranges", "code": "50344408" },
      { "name": "Frozen fremont mandarin oranges", "code": "50344409" },
      { "name": "Frozen fremont oranges", "code": "50344410" },
      { "name": "Frozen golden nugget oranges", "code": "50344411" },
      { "name": "Frozen honey mandarin oranges", "code": "50344412" },
      { "name": "Frozen honey oranges", "code": "50344413" },
      { "name": "Frozen honey tangerines", "code": "50344414" },
      { "name": "Frozen honeybelle tangelo oranges", "code": "50344415" },
      { "name": "Frozen king mandarin oranges", "code": "50344416" },
      { "name": "Frozen kinnow oranges", "code": "50344417" },
      { "name": "Frozen lee mandarin oranges", "code": "50344418" },
      { "name": "Frozen makokkee oranges", "code": "50344419" },
      { "name": "Frozen malvasios oranges", "code": "50344420" },
      { "name": "Frozen mediterranean mandarin oranges", "code": "50344421" },
      { "name": "Frozen minneola tangelo oranges", "code": "50344422" },
      { "name": "Frozen monica oranges", "code": "50344423" },
      { "name": "Frozen murcott honey oranges", "code": "50344424" },
      { "name": "Frozen murcott tangors", "code": "50344425" },
      { "name": "Frozen natsudaidai mandarin oranges", "code": "50344426" },
      { "name": "Frozen natsumikan mandarin oranges", "code": "50344427" },
      { "name": "Frozen nocatee tangelo oranges", "code": "50344428" },
      { "name": "Frozen orlando tangelo oranges", "code": "50344429" },
      { "name": "Frozen ortanique tangerines", "code": "50344430" },
      { "name": "Frozen page mandarin oranges", "code": "50344431" },
      { "name": "Frozen pixie oranges", "code": "50344432" },
      {
        "name": "Frozen ponkan bantangas mandarin oranges",
        "code": "50344433"
      },
      { "name": "Frozen reyna oranges", "code": "50344434" },
      { "name": "Frozen robinson oranges", "code": "50344435" },
      { "name": "Frozen saltenitas oranges", "code": "50344436" },
      { "name": "Frozen sampson tangelo oranges", "code": "50344437" },
      { "name": "Frozen satsuma mandarin oranges", "code": "50344438" },
      { "name": "Frozen sunburst mandarin oranges", "code": "50344439" },
      { "name": "Frozen tangelos", "code": "50344440" },
      { "name": "Frozen tangerina oranges", "code": "50344441" },
      { "name": "Frozen temple oranges", "code": "50344442" },
      { "name": "Frozen thornton oranges", "code": "50344443" },
      { "name": "Frozen wekiwa tangerines", "code": "50344444" },
      { "name": "Frozen wilkins tangerines", "code": "50344445" },
      {
        "name": "Frozen willowleaf mediterranean tangerines",
        "code": "50344446"
      },
      { "name": "Frozen mangoes", "code": "50344500" },
      { "name": "Frozen alphonso mangoes", "code": "50344501" },
      { "name": "Frozen ataulfo mangoes", "code": "50344502" },
      { "name": "Frozen criollo mangoes", "code": "50344503" },
      { "name": "Frozen edwards mangoes", "code": "50344504" },
      { "name": "Frozen francine mangoes", "code": "50344505" },
      { "name": "Frozen francis mangoes", "code": "50344506" },
      { "name": "Frozen gandaria mangoes", "code": "50344507" },
      { "name": "Frozen haden mangoes", "code": "50344508" },
      { "name": "Frozen irwin mangoes", "code": "50344509" },
      { "name": "Frozen keitt mangoes", "code": "50344510" },
      { "name": "Frozen kent mangoes", "code": "50344511" },
      { "name": "Frozen kesar mangoes", "code": "50344512" },
      { "name": "Frozen kuini mangoes", "code": "50344513" },
      { "name": "Frozen manila super mangoes", "code": "50344514" },
      { "name": "Frozen manila mangoes", "code": "50344515" },
      { "name": "Frozen mayaguez mangoes", "code": "50344516" },
      { "name": "Frozen mulgoba mangoes", "code": "50344517" },
      { "name": "Frozen oro mangoes", "code": "50344518" },
      { "name": "Frozen palmer mangoes", "code": "50344519" },
      { "name": "Frozen parvin mangoes", "code": "50344520" },
      { "name": "Frozen sandersha mangoes", "code": "50344521" },
      { "name": "Frozen sensation mangoes", "code": "50344522" },
      { "name": "Frozen smith mangoes", "code": "50344523" },
      { "name": "Frozen tommy atkins mangoes", "code": "50344524" },
      { "name": "Frozen van dyke mangoes", "code": "50344525" },
      { "name": "Frozen melons", "code": "50344600" },
      { "name": "Frozen allsweet melons", "code": "50344601" },
      { "name": "Frozen athena melons", "code": "50344602" },
      { "name": "Frozen black diamond melons", "code": "50344603" },
      { "name": "Frozen cal sweet melons", "code": "50344604" },
      { "name": "Frozen carnical melons", "code": "50344605" },
      { "name": "Frozen cantaloupe melons", "code": "50344606" },
      { "name": "Frozen casaba melons", "code": "50344607" },
      { "name": "Frozen cavaillon melons", "code": "50344608" },
      { "name": "Frozen charentais melons", "code": "50344609" },
      { "name": "Frozen charleston gray watermelon", "code": "50344610" },
      { "name": "Frozen crenshaw melons", "code": "50344611" },
      { "name": "Frozen crimson sweet melons", "code": "50344612" },
      { "name": "Frozen dixie lee melons", "code": "50344613" },
      { "name": "Frozen eclipse melons", "code": "50344614" },
      { "name": "Frozen ein d'or melons", "code": "50344615" },
      { "name": "Frozen fiesta melons", "code": "50344616" },
      { "name": "Frozen galia melons", "code": "50344617" },
      { "name": "Frozen gaya melons", "code": "50344618" },
      { "name": "Frozen hami melons", "code": "50344619" },
      { "name": "Frozen honeydew melons", "code": "50344620" },
      { "name": "Frozen icebox melons", "code": "50344621" },
      { "name": "Frozen ida pride melons", "code": "50344622" },
      { "name": "Frozen juan canary melons", "code": "50344623" },
      { "name": "Frozen jubilee melons", "code": "50344624" },
      { "name": "Frozen jubilation melons", "code": "50344625" },
      { "name": "Frozen kakhi/kakri melons", "code": "50344626" },
      { "name": "Frozen kiwano melons", "code": "50344627" },
      { "name": "Frozen korean melons", "code": "50344628" },
      { "name": "Frozen long gray melons", "code": "50344629" },
      { "name": "Frozen mayan melons", "code": "50344630" },
      { "name": "Frozen micky lee melons", "code": "50344631" },
      { "name": "Frozen mirage melons", "code": "50344632" },
      { "name": "Frozen moon and stars watermelon", "code": "50344633" },
      { "name": "Frozen ogen melons", "code": "50344634" },
      { "name": "Frozen patriot melons", "code": "50344635" },
      { "name": "Frozen peacock melons", "code": "50344636" },
      { "name": "Frozen pepino melons", "code": "50344637" },
      { "name": "Frozen persian melons", "code": "50344638" },
      { "name": "Frozen picnic melons", "code": "50344639" },
      { "name": "Frozen piel de sapo melons", "code": "50344640" },
      { "name": "Frozen pineapple melons", "code": "50344641" },
      { "name": "Frozen quetzali melons", "code": "50344642" },
      { "name": "Frozen red goblin melons", "code": "50344643" },
      { "name": "Frozen regency melons", "code": "50344644" },
      { "name": "Frozen royal majestic melons", "code": "50344645" },
      { "name": "Frozen royal star melons", "code": "50344646" },
      { "name": "Frozen royal sweet melons", "code": "50344647" },
      { "name": "Frozen santa claus melons", "code": "50344648" },
      { "name": "Frozen sharlyn melons", "code": "50344649" },
      { "name": "Frozen spanish melons", "code": "50344650" },
      { "name": "Frozen sprite melons", "code": "50344651" },
      { "name": "Frozen starbright melons", "code": "50344652" },
      { "name": "Frozen stars n stripes melons", "code": "50344653" },
      { "name": "Frozen sugar baby melons", "code": "50344654" },
      { "name": "Frozen sugar baby watermelon", "code": "50344655" },
      { "name": "Frozen sunsweet melons", "code": "50344656" },
      { "name": "Frozen sweet heart seedless watermelon", "code": "50344657" },
      { "name": "Frozen temptation melons", "code": "50344658" },
      { "name": "Frozen tiger baby melons", "code": "50344659" },
      { "name": "Frozen tuscan type melons", "code": "50344660" },
      { "name": "Frozen yellow baby watermelon", "code": "50344661" },
      { "name": "Frozen mulberries", "code": "50344700" },
      { "name": "Frozen black mulberries", "code": "50344701" },
      { "name": "Frozen white mulberries", "code": "50344702" },
      { "name": "Frozen bayberries or myrtle", "code": "50344800" },
      { "name": "Frozen bog myrtle", "code": "50344801" },
      { "name": "Frozen bayberries", "code": "50344802" },
      { "name": "Frozen nectarines", "code": "50344900" },
      { "name": "Frozen april glo nectarines", "code": "50344901" },
      { "name": "Frozen arctic mist nectarines", "code": "50344902" },
      { "name": "Frozen arctic snow nectarines", "code": "50344903" },
      { "name": "Frozen arctic star nectarines", "code": "50344904" },
      { "name": "Frozen arctic sweet nectarines", "code": "50344905" },
      { "name": "Frozen arctic glo nectarines", "code": "50344906" },
      { "name": "Frozen august fire nectarines", "code": "50344907" },
      { "name": "Frozen august pearl nectarines", "code": "50344908" },
      { "name": "Frozen august red nectarines", "code": "50344909" },
      { "name": "Frozen autumn star nectarines", "code": "50344910" },
      { "name": "Frozen big john nectarines", "code": "50344911" },
      { "name": "Frozen bright pearl nectarines", "code": "50344912" },
      { "name": "Frozen diamond bright nectarines", "code": "50344913" },
      { "name": "Frozen diamond ray nectarines", "code": "50344914" },
      { "name": "Frozen earliglo nectarines", "code": "50344915" },
      { "name": "Frozen early diamond nectarines", "code": "50344916" },
      { "name": "Frozen fairlane nectarines", "code": "50344917" },
      { "name": "Frozen fantasia nectarines", "code": "50344918" },
      { "name": "Frozen fire pearl nectarines", "code": "50344919" },
      { "name": "Frozen fire sweet nectarines", "code": "50344920" },
      { "name": "Frozen flamekist nectarines", "code": "50344921" },
      { "name": "Frozen flat type nectarines", "code": "50344922" },
      { "name": "Frozen garden delight nectarines", "code": "50344923" },
      { "name": "Frozen goldmine nectarines", "code": "50344924" },
      { "name": "Frozen grand pearl nectarines", "code": "50344925" },
      { "name": "Frozen hardired nectarines", "code": "50344926" },
      { "name": "Frozen honey blaze nectarines", "code": "50344927" },
      { "name": "Frozen july red nectarines", "code": "50344928" },
      { "name": "Frozen kay pearl nectarines", "code": "50344929" },
      { "name": "Frozen kay sweet nectarines", "code": "50344930" },
      { "name": "Frozen may diamond nectarines", "code": "50344931" },
      { "name": "Frozen mayfire nectarines", "code": "50344932" },
      { "name": "Frozen mayglo nectarines", "code": "50344933" },
      { "name": "Frozen mericrest nectarines", "code": "50344934" },
      { "name": "Frozen red diamond nectarines", "code": "50344935" },
      { "name": "Frozen red gold nectarines", "code": "50344936" },
      { "name": "Frozen red jim nectarines", "code": "50344937" },
      { "name": "Frozen red roy nectarines", "code": "50344938" },
      { "name": "Frozen rio red nectarines", "code": "50344939" },
      { "name": "Frozen rose diamond nectarines", "code": "50344940" },
      { "name": "Frozen royal glo nectarines", "code": "50344941" },
      { "name": "Frozen ruby diamond nectarines", "code": "50344942" },
      { "name": "Frozen ruby sweet nectarines", "code": "50344943" },
      { "name": "Frozen ruddy jewel nectarines", "code": "50344944" },
      { "name": "Frozen september red nectarines", "code": "50344945" },
      { "name": "Frozen snowqueen nectarines", "code": "50344946" },
      { "name": "Frozen spring bright nectarines", "code": "50344947" },
      { "name": "Frozen spring red nectarines", "code": "50344948" },
      { "name": "Frozen summer blush nectarines", "code": "50344949" },
      { "name": "Frozen summer brite nectarines", "code": "50344950" },
      { "name": "Frozen summer diamond nectarines", "code": "50344951" },
      { "name": "Frozen summer fire nectarines", "code": "50344952" },
      { "name": "Frozen summer grand nectarines", "code": "50344953" },
      { "name": "Frozen sunglo nectarines", "code": "50344954" },
      { "name": "Frozen zee fire nectarines", "code": "50344955" },
      { "name": "Frozen zee glo nectarines", "code": "50344956" },
      { "name": "Frozen zeegrand nectarines", "code": "50344957" },
      { "name": "Frozen oranges", "code": "50345000" },
      { "name": "Frozen african sour oranges", "code": "50345001" },
      { "name": "Frozen ambersweet oranges", "code": "50345002" },
      { "name": "Frozen argentine sour oranges", "code": "50345003" },
      { "name": "Frozen bahianinha oranges", "code": "50345004" },
      { "name": "Frozen bergamot oranges", "code": "50345005" },
      { "name": "Frozen berna oranges", "code": "50345006" },
      { "name": "Frozen bigaradier apepu oranges", "code": "50345007" },
      { "name": "Frozen bittersweet daidai oranges", "code": "50345008" },
      { "name": "Frozen blonde oranges", "code": "50345009" },
      { "name": "Frozen blood oranges", "code": "50345010" },
      { "name": "Frozen california navel oranges", "code": "50345011" },
      { "name": "Frozen cara cara oranges", "code": "50345012" },
      { "name": "Frozen chinotto oranges", "code": "50345013" },
      { "name": "Frozen dream navel oranges", "code": "50345014" },
      { "name": "Frozen gou tou oranges", "code": "50345015" },
      { "name": "Frozen hamlin oranges", "code": "50345016" },
      { "name": "Frozen jaffa oranges", "code": "50345017" },
      { "name": "Frozen jincheng oranges", "code": "50345018" },
      { "name": "Frozen k-early oranges", "code": "50345019" },
      { "name": "Frozen kona oranges", "code": "50345020" },
      { "name": "Frozen late navel oranges", "code": "50345021" },
      { "name": "Frozen late valencia oranges", "code": "50345022" },
      { "name": "Frozen limequat oranges", "code": "50345023" },
      { "name": "Frozen marr oranges", "code": "50345024" },
      { "name": "Frozen melogold oranges", "code": "50345025" },
      { "name": "Frozen moro oranges", "code": "50345026" },
      { "name": "Frozen moro tarocco oranges", "code": "50345027" },
      { "name": "Frozen navel oranges", "code": "50345028" },
      { "name": "Frozen navelina oranges", "code": "50345029" },
      { "name": "Frozen oro blanco oranges", "code": "50345030" },
      { "name": "Frozen osceola oranges", "code": "50345031" },
      { "name": "Frozen parson brown oranges", "code": "50345032" },
      { "name": "Frozen pera oranges", "code": "50345033" },
      { "name": "Frozen pummulo oranges", "code": "50345034" },
      { "name": "Frozen rhode red oranges", "code": "50345035" },
      { "name": "Frozen roble oranges", "code": "50345036" },
      { "name": "Frozen salustianas oranges", "code": "50345037" },
      { "name": "Frozen sanguine oranges", "code": "50345038" },
      { "name": "Frozen sanguinelli oranges", "code": "50345039" },
      { "name": "Frozen seville oranges", "code": "50345040" },
      { "name": "Frozen shamouti jaffa oranges", "code": "50345041" },
      { "name": "Frozen tunis oranges", "code": "50345042" },
      { "name": "Frozen valencia oranges", "code": "50345043" },
      { "name": "Frozen washington navel oranges", "code": "50345044" },
      { "name": "Frozen papayas", "code": "50345100" },
      { "name": "Frozen green cooking papayas", "code": "50345101" },
      { "name": "Frozen maradol papayas", "code": "50345102" },
      { "name": "Frozen mexican yellow papayas", "code": "50345103" },
      { "name": "Frozen mountain papayas", "code": "50345104" },
      { "name": "Frozen solo papayas", "code": "50345105" },
      { "name": "Frozen tainung papayas", "code": "50345106" },
      { "name": "Frozen passion fruit", "code": "50345200" },
      { "name": "Frozen banana passion fruit", "code": "50345201" },
      { "name": "Frozen blue passion flower", "code": "50345202" },
      { "name": "Frozen crackerjack passion fruit", "code": "50345203" },
      { "name": "Frozen giant granadilla passion fruit", "code": "50345204" },
      { "name": "Frozen golden granadilla passion fruit", "code": "50345205" },
      { "name": "Frozen maypops passion fruit", "code": "50345206" },
      { "name": "Frozen red granadilla passion fruit", "code": "50345207" },
      { "name": "Frozen sweet granadilla passion fruit", "code": "50345208" },
      { "name": "Frozen water lemon passion fruit", "code": "50345209" },
      { "name": "Frozen wing-stemmed passion flower", "code": "50345210" },
      { "name": "Frozen peaches", "code": "50345300" },
      { "name": "Frozen amber crest peaches", "code": "50345301" },
      { "name": "Frozen april snow peaches", "code": "50345302" },
      { "name": "Frozen august lady peaches", "code": "50345303" },
      { "name": "Frozen autumn flame peaches", "code": "50345304" },
      { "name": "Frozen autumn lady peaches", "code": "50345305" },
      { "name": "Frozen babcock peaches", "code": "50345306" },
      { "name": "Frozen brittney lane peaches", "code": "50345307" },
      { "name": "Frozen cary mac peaches", "code": "50345308" },
      { "name": "Frozen classic peaches", "code": "50345309" },
      { "name": "Frozen country sweet peaches", "code": "50345310" },
      { "name": "Frozen crest haven peaches", "code": "50345311" },
      { "name": "Frozen crimson lady peaches", "code": "50345312" },
      { "name": "Frozen crown princess peaches", "code": "50345313" },
      { "name": "Frozen david sun peaches", "code": "50345314" },
      { "name": "Frozen diamond princess peaches", "code": "50345315" },
      { "name": "Frozen earlirich peaches", "code": "50345316" },
      { "name": "Frozen early majestic peaches", "code": "50345317" },
      { "name": "Frozen early treat peaches", "code": "50345318" },
      { "name": "Frozen elegant lady peaches", "code": "50345319" },
      { "name": "Frozen empress peaches", "code": "50345320" },
      { "name": "Frozen encore peaches", "code": "50345321" },
      { "name": "Frozen fancy lady peaches", "code": "50345322" },
      { "name": "Frozen fire prince peaches", "code": "50345323" },
      { "name": "Frozen flame crest peaches", "code": "50345324" },
      { "name": "Frozen flat type peaches", "code": "50345325" },
      { "name": "Frozen flavorcrest peaches", "code": "50345326" },
      { "name": "Frozen florida prince peaches", "code": "50345327" },
      { "name": "Frozen full moon peaches", "code": "50345328" },
      { "name": "Frozen harvester peaches", "code": "50345329" },
      { "name": "Frozen ice princess peaches", "code": "50345330" },
      { "name": "Frozen ivory princess peaches", "code": "50345331" },
      { "name": "Frozen jersey queen peaches", "code": "50345332" },
      { "name": "Frozen john henry peaches", "code": "50345333" },
      { "name": "Frozen june prince peaches", "code": "50345334" },
      { "name": "Frozen kaweah peaches", "code": "50345335" },
      { "name": "Frozen klondike peaches", "code": "50345336" },
      { "name": "Frozen lindo peaches", "code": "50345337" },
      { "name": "Frozen loring peaches", "code": "50345338" },
      { "name": "Frozen majestic peaches", "code": "50345339" },
      { "name": "Frozen o'henry peaches", "code": "50345340" },
      { "name": "Frozen queencrest peaches", "code": "50345341" },
      { "name": "Frozen red lady peaches", "code": "50345342" },
      { "name": "Frozen redglobe peaches", "code": "50345343" },
      { "name": "Frozen redhaven peaches", "code": "50345344" },
      { "name": "Frozen redtop peaches", "code": "50345345" },
      { "name": "Frozen regina peaches", "code": "50345346" },
      { "name": "Frozen rich lady peaches", "code": "50345347" },
      { "name": "Frozen rich may peaches", "code": "50345348" },
      { "name": "Frozen royal glory peaches", "code": "50345349" },
      { "name": "Frozen royal lady peaches", "code": "50345350" },
      { "name": "Frozen september snow peaches", "code": "50345351" },
      { "name": "Frozen september sun peaches", "code": "50345352" },
      { "name": "Frozen sierra gem peaches", "code": "50345353" },
      { "name": "Frozen snow angel peaches", "code": "50345354" },
      { "name": "Frozen snow gem peaches", "code": "50345355" },
      { "name": "Frozen snow king peaches", "code": "50345356" },
      { "name": "Frozen spring lady peaches", "code": "50345357" },
      { "name": "Frozen spring snow peaches", "code": "50345358" },
      { "name": "Frozen springcrest peaches", "code": "50345359" },
      { "name": "Frozen sugar giant peaches", "code": "50345360" },
      { "name": "Frozen sugar lady peaches", "code": "50345361" },
      { "name": "Frozen sun bright peaches", "code": "50345362" },
      { "name": "Frozen sunhigh peaches", "code": "50345363" },
      { "name": "Frozen super lady peaches", "code": "50345364" },
      { "name": "Frozen super rich peaches", "code": "50345365" },
      { "name": "Frozen surecrop peaches", "code": "50345366" },
      { "name": "Frozen sweet dream peaches", "code": "50345367" },
      { "name": "Frozen sweet september peaches", "code": "50345368" },
      { "name": "Frozen vista peaches", "code": "50345369" },
      { "name": "Frozen white lady peaches", "code": "50345370" },
      { "name": "Frozen zee lady peaches", "code": "50345371" },
      { "name": "Frozen pears", "code": "50345400" },
      { "name": "Frozen abate fetel pears", "code": "50345401" },
      { "name": "Frozen anjou pears", "code": "50345402" },
      { "name": "Frozen asian pears", "code": "50345403" },
      { "name": "Frozen bartlett pears", "code": "50345404" },
      { "name": "Frozen best ever pears", "code": "50345405" },
      { "name": "Frozen beth pears", "code": "50345406" },
      { "name": "Frozen beurre pears", "code": "50345407" },
      { "name": "Frozen bosc pears", "code": "50345408" },
      { "name": "Frozen clapp favorite pears", "code": "50345409" },
      { "name": "Frozen comice pears", "code": "50345410" },
      { "name": "Frozen concorde pears", "code": "50345411" },
      { "name": "Frozen conference pears", "code": "50345412" },
      { "name": "Frozen crimson red pears", "code": "50345413" },
      { "name": "Frozen d'anjou pears", "code": "50345414" },
      { "name": "Frozen dr jules guyot pears", "code": "50345415" },
      { "name": "Frozen early pears", "code": "50345416" },
      { "name": "Frozen emperor brown pears", "code": "50345417" },
      { "name": "Frozen forelle pears", "code": "50345418" },
      { "name": "Frozen french butter pears", "code": "50345419" },
      { "name": "Frozen glou morceau pears", "code": "50345420" },
      { "name": "Frozen hosui pears", "code": "50345421" },
      { "name": "Frozen italian butter pears", "code": "50345422" },
      { "name": "Frozen jargonelle pears", "code": "50345423" },
      { "name": "Frozen juno pears", "code": "50345424" },
      {
        "name": "Frozen kaiserlouise bonne de jersey pears",
        "code": "50345425"
      },
      { "name": "Frozen keiffer pears", "code": "50345426" },
      { "name": "Frozen kings royal pears", "code": "50345427" },
      { "name": "Frozen limonera pears", "code": "50345428" },
      { "name": "Frozen merton pride pears", "code": "50345429" },
      { "name": "Frozen mountain bartlett pears", "code": "50345430" },
      { "name": "Frozen olivier de serres pears", "code": "50345431" },
      { "name": "Frozen onward pears", "code": "50345432" },
      { "name": "Frozen packham's triumph pears", "code": "50345433" },
      { "name": "Frozen paraiso pears", "code": "50345434" },
      { "name": "Frozen passe crasanne pears", "code": "50345435" },
      { "name": "Frozen perry pears", "code": "50345436" },
      { "name": "Frozen red bartlett pears", "code": "50345437" },
      { "name": "Frozen red d'anjou pears", "code": "50345438" },
      { "name": "Frozen rocha pears", "code": "50345439" },
      { "name": "Frozen rosey red pears", "code": "50345440" },
      { "name": "Frozen rosy red pears", "code": "50345441" },
      { "name": "Frozen royal majestic pears", "code": "50345442" },
      { "name": "Frozen ruby red pears", "code": "50345443" },
      { "name": "Frozen santa maria pears", "code": "50345444" },
      { "name": "Frozen seckel pears", "code": "50345445" },
      { "name": "Frozen sensation pears", "code": "50345446" },
      { "name": "Frozen star crimson pears", "code": "50345447" },
      { "name": "Frozen stark crimson pears", "code": "50345448" },
      { "name": "Frozen summer bartlett pears", "code": "50345449" },
      { "name": "Frozen summer gold pears", "code": "50345450" },
      { "name": "Frozen sun gold pears", "code": "50345451" },
      { "name": "Frozen sunsprite pears", "code": "50345452" },
      { "name": "Frozen taylors gold pears", "code": "50345453" },
      { "name": "Frozen taylors red pears", "code": "50345454" },
      { "name": "Frozen tientsin pears", "code": "50345455" },
      { "name": "Frozen tosca pears", "code": "50345456" },
      { "name": "Frozen warden pears", "code": "50345457" },
      { "name": "Frozen williams bon chretien pears", "code": "50345458" },
      { "name": "Frozen williams pears", "code": "50345459" },
      { "name": "Frozen winter nelis pears", "code": "50345460" },
      { "name": "Frozen persimmons", "code": "50345500" },
      { "name": "Frozen american persimmon", "code": "50345501" },
      { "name": "Frozen black sapote persimmon", "code": "50345502" },
      { "name": "Frozen chapote/black persimmon", "code": "50345503" },
      { "name": "Frozen date plum persimmon", "code": "50345504" },
      { "name": "Frozen fuyu persimmons", "code": "50345505" },
      { "name": "Frozen giant fuyu persimmons", "code": "50345506" },
      { "name": "Frozen hachiya persimmons", "code": "50345507" },
      { "name": "Frozen mabolo/butter fruit persimmon", "code": "50345508" },
      { "name": "Frozen principe ito persimmons", "code": "50345509" },
      { "name": "Frozen royal brillante persimmons", "code": "50345510" },
      { "name": "Frozen sharon fruit persimmon", "code": "50345511" },
      { "name": "Frozen triumph persimmons", "code": "50345512" },
      { "name": "Frozen pineapples", "code": "50345600" },
      { "name": "Frozen cherimoya pineapple", "code": "50345601" },
      { "name": "Frozen golden pineapple", "code": "50345602" },
      { "name": "Frozen hilo pineapple", "code": "50345603" },
      { "name": "Frozen kona sugarloaf pineapple", "code": "50345604" },
      { "name": "Frozen natal queen pineapple", "code": "50345605" },
      { "name": "Frozen pernambuco pineapple", "code": "50345606" },
      { "name": "Frozen red spanish pineapple", "code": "50345607" },
      { "name": "Frozen smooth cayenne pineapple", "code": "50345608" },
      { "name": "Frozen sugarloaf pineapple", "code": "50345609" },
      { "name": "Frozen variegated pineapple", "code": "50345610" },
      { "name": "Frozen plucots", "code": "50345700" },
      { "name": "Frozen black kat plucot", "code": "50345701" },
      { "name": "Frozen blue gusto plucot", "code": "50345702" },
      { "name": "Frozen crimson heart plucot", "code": "50345703" },
      { "name": "Frozen dapple dandy plucot", "code": "50345704" },
      { "name": "Frozen dapple fire plucot", "code": "50345705" },
      { "name": "Frozen early dapple plucot", "code": "50345706" },
      { "name": "Frozen flavor fall plucot", "code": "50345707" },
      { "name": "Frozen flavor gold plucot", "code": "50345708" },
      { "name": "Frozen flavor grenade plucot", "code": "50345709" },
      { "name": "Frozen flavor heart plucot", "code": "50345710" },
      { "name": "Frozen flavor jewel plucot", "code": "50345711" },
      { "name": "Frozen flavor king plucot", "code": "50345712" },
      { "name": "Frozen flavor queen plucot", "code": "50345713" },
      { "name": "Frozen flavor supreme plucot", "code": "50345714" },
      { "name": "Frozen flavor treat plucot", "code": "50345715" },
      { "name": "Frozen flavorella plucot", "code": "50345716" },
      { "name": "Frozen flavorich plucot", "code": "50345717" },
      { "name": "Frozen flavorosa plucot", "code": "50345718" },
      { "name": "Frozen geo pride plucot", "code": "50345719" },
      { "name": "Frozen red kat plucot", "code": "50345720" },
      { "name": "Frozen royal treat plucot", "code": "50345721" },
      { "name": "Frozen sierra rose plucot", "code": "50345722" },
      { "name": "Frozen sweet geisha plucot", "code": "50345723" },
      { "name": "Frozen plums", "code": "50345800" },
      { "name": "Frozen amber jewel plums", "code": "50345801" },
      { "name": "Frozen angeleno plums", "code": "50345802" },
      { "name": "Frozen aurora plums", "code": "50345803" },
      { "name": "Frozen autumn beaut plums", "code": "50345804" },
      { "name": "Frozen autumn giant plums", "code": "50345805" },
      { "name": "Frozen autumn pride plums", "code": "50345806" },
      { "name": "Frozen autumn rosa plums", "code": "50345807" },
      { "name": "Frozen beach plum", "code": "50345808" },
      { "name": "Frozen betty anne plums", "code": "50345809" },
      { "name": "Frozen black beaut plums", "code": "50345810" },
      { "name": "Frozen black bullace plum", "code": "50345811" },
      { "name": "Frozen black diamond plums", "code": "50345812" },
      { "name": "Frozen black giant plums", "code": "50345813" },
      { "name": "Frozen black ice plums", "code": "50345814" },
      { "name": "Frozen black splendor plums", "code": "50345815" },
      { "name": "Frozen blackamber plums", "code": "50345816" },
      { "name": "Frozen burgundy plums", "code": "50345817" },
      { "name": "Frozen carlsbad plum", "code": "50345818" },
      { "name": "Frozen casselman plums", "code": "50345819" },
      { "name": "Frozen catalina plums", "code": "50345820" },
      { "name": "Frozen damson plum", "code": "50345821" },
      { "name": "Frozen dolly plums", "code": "50345822" },
      { "name": "Frozen earliqueen plums", "code": "50345823" },
      { "name": "Frozen early rosa plums", "code": "50345824" },
      { "name": "Frozen ebony may plums", "code": "50345825" },
      { "name": "Frozen ebony plums", "code": "50345826" },
      { "name": "Frozen elephant heart plums", "code": "50345827" },
      { "name": "Frozen emerald beaut plums", "code": "50345828" },
      { "name": "Frozen empress plums", "code": "50345829" },
      { "name": "Frozen freedom plums", "code": "50345830" },
      { "name": "Frozen friar plums", "code": "50345831" },
      { "name": "Frozen gar red plums", "code": "50345832" },
      { "name": "Frozen governor's plum", "code": "50345833" },
      { "name": "Frozen grand rosa plums", "code": "50345834" },
      { "name": "Frozen green gage plum", "code": "50345835" },
      { "name": "Frozen greengage plums", "code": "50345836" },
      { "name": "Frozen hiromi plums", "code": "50345837" },
      { "name": "Frozen hiromi red plums", "code": "50345838" },
      { "name": "Frozen holiday plums", "code": "50345839" },
      { "name": "Frozen howard sun plums", "code": "50345840" },
      { "name": "Frozen interspecific type plums", "code": "50345841" },
      { "name": "Frozen jamaican plum", "code": "50345842" },
      { "name": "Frozen joanna red plums", "code": "50345843" },
      { "name": "Frozen kelsey plums", "code": "50345844" },
      { "name": "Frozen king james plums", "code": "50345845" },
      { "name": "Frozen laroda plums", "code": "50345846" },
      { "name": "Frozen late rosa plums", "code": "50345847" },
      { "name": "Frozen linda rosa plums", "code": "50345848" },
      { "name": "Frozen lone star red plums", "code": "50345849" },
      { "name": "Frozen mariposa plums", "code": "50345850" },
      { "name": "Frozen marked black plums", "code": "50345851" },
      { "name": "Frozen marked red plums", "code": "50345852" },
      { "name": "Frozen mirabelle plum", "code": "50345853" },
      { "name": "Frozen october sun plums", "code": "50345854" },
      { "name": "Frozen owen t plums", "code": "50345855" },
      { "name": "Frozen perdrigon plum", "code": "50345856" },
      { "name": "Frozen pink delight plums", "code": "50345857" },
      { "name": "Frozen president plums", "code": "50345858" },
      { "name": "Frozen primetime plums", "code": "50345859" },
      { "name": "Frozen purple majesty plums", "code": "50345860" },
      { "name": "Frozen queen rosa plums", "code": "50345861" },
      { "name": "Frozen quetsch plum", "code": "50345862" },
      { "name": "Frozen red beaut plums", "code": "50345863" },
      { "name": "Frozen red lane plums", "code": "50345864" },
      { "name": "Frozen red ram plums", "code": "50345865" },
      { "name": "Frozen red rosa plums", "code": "50345866" },
      { "name": "Frozen rich red plums", "code": "50345867" },
      { "name": "Frozen rosemary plums", "code": "50345868" },
      { "name": "Frozen royal diamond plums", "code": "50345869" },
      { "name": "Frozen royal red plums", "code": "50345870" },
      { "name": "Frozen royal zee plums", "code": "50345871" },
      { "name": "Frozen roysum plums", "code": "50345872" },
      { "name": "Frozen santa rosa plums", "code": "50345873" },
      { "name": "Frozen saphire plums", "code": "50345874" },
      { "name": "Frozen sloe plum", "code": "50345875" },
      { "name": "Frozen st catherine plum", "code": "50345876" },
      { "name": "Frozen white bullace plum", "code": "50345877" },
      { "name": "Frozen pomegranates", "code": "50345900" },
      { "name": "Frozen foothill pomegranates", "code": "50345901" },
      { "name": "Frozen granada pomegranates", "code": "50345902" },
      { "name": "Frozen jolly red pomegranates", "code": "50345903" },
      { "name": "Frozen nana pomegranates", "code": "50345904" },
      { "name": "Frozen pat's red pomegranates", "code": "50345905" },
      { "name": "Frozen pinkhan pomegranates", "code": "50345906" },
      { "name": "Frozen purple velvet pomegranates", "code": "50345907" },
      { "name": "Frozen wonderful pomegranates", "code": "50345908" },
      { "name": "Frozen pomelos", "code": "50346000" },
      { "name": "Frozen chandler pomelo", "code": "50346001" },
      { "name": "Frozen hirado buntan pomelo", "code": "50346002" },
      { "name": "Frozen liang ping yau pomelo", "code": "50346003" },
      { "name": "Frozen pandan wangi pomelo", "code": "50346004" },
      { "name": "Frozen pink pomelo", "code": "50346005" },
      { "name": "Frozen red shaddock pomelo", "code": "50346006" },
      { "name": "Frozen siamese sweet pomelo", "code": "50346007" },
      { "name": "Frozen wainwright pomelo", "code": "50346008" },
      { "name": "Frozen quinces", "code": "50346100" },
      { "name": "Frozen champion quince", "code": "50346101" },
      { "name": "Frozen pineapple quince", "code": "50346102" },
      { "name": "Frozen smyrna quince", "code": "50346103" },
      { "name": "Frozen raspberries", "code": "50346200" },
      { "name": "Frozen american red raspberry", "code": "50346201" },
      { "name": "Frozen bailey queensland raspberry", "code": "50346202" },
      { "name": "Frozen black raspberry", "code": "50346203" },
      { "name": "Frozen dark raspberry", "code": "50346204" },
      { "name": "Frozen delicious raspberry", "code": "50346205" },
      { "name": "Frozen focke dwarf raspberry", "code": "50346206" },
      { "name": "Frozen focke grayleaf red raspberry", "code": "50346207" },
      { "name": "Frozen focke strawberry raspberry", "code": "50346208" },
      { "name": "Frozen focke yellow himalayan raspberry", "code": "50346209" },
      { "name": "Frozen gold raspberry", "code": "50346210" },
      { "name": "Frozen gray new mexico raspberry", "code": "50346211" },
      { "name": "Frozen jepson whitebark raspberry", "code": "50346212" },
      { "name": "Frozen kellogg san diego raspberry", "code": "50346213" },
      { "name": "Frozen leucodermis whitebark raspberry", "code": "50346214" },
      { "name": "Frozen munz cuyamaca raspberry", "code": "50346215" },
      { "name": "Frozen peck barton's raspberry", "code": "50346216" },
      { "name": "Frozen purpleflowering raspberry", "code": "50346217" },
      { "name": "Frozen roadside raspberry", "code": "50346218" },
      { "name": "Frozen san diego raspberry", "code": "50346219" },
      { "name": "Frozen snow raspberry", "code": "50346220" },
      { "name": "Frozen snowpeaks raspberry", "code": "50346221" },
      { "name": "Frozen strawberryleaf raspberry", "code": "50346222" },
      { "name": "Frozen sweet cultivated raspberry", "code": "50346223" },
      {
        "name": "Frozen torr and gray whitebark raspberry",
        "code": "50346224"
      },
      { "name": "Frozen west indian raspberry", "code": "50346225" },
      { "name": "Frozen whitebark raspberry", "code": "50346226" },
      { "name": "Frozen wine raspberry", "code": "50346227" },
      { "name": "Frozen yellow himalayan raspberry", "code": "50346228" },
      { "name": "Frozen yu-shan raspberry", "code": "50346229" },
      { "name": "Frozen rhubarb", "code": "50346300" },
      { "name": "Frozen crimson red rhubarb", "code": "50346301" },
      { "name": "Frozen early champagne rhubarb", "code": "50346302" },
      { "name": "Frozen glaskin's perpetual rhubarb", "code": "50346303" },
      { "name": "Frozen sutton rhubarb", "code": "50346304" },
      { "name": "Frozen timperley early rhubarb", "code": "50346305" },
      { "name": "Frozen valentine rhubarb", "code": "50346306" },
      { "name": "Frozen victoria rhubarb", "code": "50346307" },
      { "name": "Frozen zwolle seedling rhubarb", "code": "50346308" },
      { "name": "Frozen macdonald rhubarb", "code": "50346309" },
      { "name": "Frozen tilden rhubarb", "code": "50346310" },
      { "name": "Frozen rose hips", "code": "50346400" },
      { "name": "Frozen brier rose hips", "code": "50346401" },
      { "name": "Frozen elgantine rose hips", "code": "50346402" },
      { "name": "Frozen rugosa rose hips", "code": "50346403" },
      { "name": "Frozen scotch or burnet rose hips", "code": "50346404" },
      { "name": "Frozen sapotes", "code": "50346500" },
      { "name": "Frozen white sapotes", "code": "50346501" },
      { "name": "Frozen black sapotes", "code": "50346502" },
      { "name": "Frozen saskatoon berries", "code": "50346600" },
      { "name": "Frozen honeywood saskatoon berries", "code": "50346601" },
      { "name": "Frozen northline saskatoon berries", "code": "50346602" },
      { "name": "Frozen smoky saskatoon berries", "code": "50346603" },
      { "name": "Frozen thiessen saskatoon berries", "code": "50346604" },
      { "name": "Frozen strawberries", "code": "50346700" },
      { "name": "Frozen chandler strawberries", "code": "50346701" },
      { "name": "Frozen june bearing strawberries", "code": "50346702" },
      { "name": "Frozen ever bearing strawberries", "code": "50346703" },
      { "name": "Frozen sugar apple", "code": "50346800" },
      { "name": "Frozen kampong mauve sugar apple", "code": "50346801" },
      { "name": "Frozen seedless sugar apple", "code": "50346802" },
      { "name": "Frozen thai lessard sugar apple", "code": "50346803" },
      { "name": "Frozen tamarillo", "code": "50346900" },
      { "name": "Frozen amberlea gold tamarillo", "code": "50346901" },
      { "name": "Frozen bold gold tamarillo", "code": "50346902" },
      { "name": "Frozen goldmine tamarillo", "code": "50346903" },
      { "name": "Frozen oratia red tamarillo", "code": "50346904" },
      { "name": "Frozen red beau tamarillo", "code": "50346905" },
      { "name": "Frozen red delight tamarillo", "code": "50346906" },
      { "name": "Frozen nominant fruits", "code": "50347000" },
      { "name": "Frozen akee", "code": "50347001" },
      { "name": "Frozen babaco", "code": "50347002" },
      { "name": "Frozen banana flowers", "code": "50347003" },
      { "name": "Frozen baobab", "code": "50347004" },
      { "name": "Frozen bitter oranges", "code": "50347005" },
      { "name": "Frozen canistel", "code": "50347006" },
      { "name": "Frozen coconuts", "code": "50347007" },
      { "name": "Frozen cloudberries", "code": "50347008" },
      { "name": "Frozen dewberries", "code": "50347009" },
      { "name": "Frozen durian", "code": "50347010" },
      { "name": "Frozen elderberries", "code": "50347011" },
      { "name": "Frozen feijoa", "code": "50347012" },
      { "name": "Frozen hackberries", "code": "50347013" },
      { "name": "Frozen hawthorn", "code": "50347014" },
      { "name": "Frozen honeyberries", "code": "50347015" },
      { "name": "Frozen jackfruit", "code": "50347016" },
      { "name": "Frozen jambolan", "code": "50347017" },
      { "name": "Frozen jujube", "code": "50347018" },
      { "name": "Frozen lychee", "code": "50347019" },
      { "name": "Frozen mangosteens", "code": "50347020" },
      { "name": "Frozen medlars", "code": "50347021" },
      { "name": "Frozen mombins", "code": "50347022" },
      { "name": "Frozen monstera", "code": "50347023" },
      { "name": "Frozen pepinos", "code": "50347024" },
      { "name": "Frozen plantains", "code": "50347025" },
      { "name": "Frozen prickly pears", "code": "50347026" },
      { "name": "Frozen quenepas", "code": "50347027" },
      { "name": "Frozen rambutan", "code": "50347028" },
      { "name": "Frozen rose apples", "code": "50347029" },
      { "name": "Frozen roselle", "code": "50347030" },
      { "name": "Frozen rowanberries", "code": "50347031" },
      { "name": "Frozen sea buckhorn berries", "code": "50347032" },
      { "name": "Frozen silverberries", "code": "50347033" },
      { "name": "Frozen sorb berries", "code": "50347034" },
      { "name": "Frozen soursops", "code": "50347035" },
      { "name": "Frozen star apples", "code": "50347036" },
      { "name": "Frozen tamarindo", "code": "50347037" },
      { "name": "Frozen chokeberries", "code": "50347100" },
      { "name": "Frozen autumn magic chokeberries", "code": "50347101" },
      { "name": "Frozen brillantisima chokeberries", "code": "50347102" },
      { "name": "Frozen nero chokeberries", "code": "50347103" },
      { "name": "Frozen viking chokeberries", "code": "50347104" },
      { "name": "Frozen olives", "code": "50347200" },
      { "name": "Frozen agrinion olives", "code": "50347201" },
      { "name": "Frozen aleppo olives", "code": "50347202" },
      { "name": "Frozen alphonso olives", "code": "50347203" },
      { "name": "Frozen amphissa olives", "code": "50347204" },
      { "name": "Frozen arauco olives", "code": "50347205" },
      { "name": "Frozen arbequina olives", "code": "50347206" },
      { "name": "Frozen atalanta olives", "code": "50347207" },
      { "name": "Frozen cerignola olives", "code": "50347208" },
      { "name": "Frozen cracked provencal olives", "code": "50347209" },
      { "name": "Frozen empeltre olives", "code": "50347210" },
      { "name": "Frozen gaeta olives", "code": "50347211" },
      { "name": "Frozen hondroelia olives", "code": "50347212" },
      { "name": "Frozen kalamata olives", "code": "50347213" },
      { "name": "Frozen kura olives", "code": "50347214" },
      { "name": "Frozen ligurian olives", "code": "50347215" },
      { "name": "Frozen lucque olives", "code": "50347216" },
      { "name": "Frozen lugano olives", "code": "50347217" },
      { "name": "Frozen manzanilla olives", "code": "50347218" },
      { "name": "Frozen marche olives", "code": "50347219" },
      { "name": "Frozen mission olives", "code": "50347220" },
      { "name": "Frozen nafplion green olives", "code": "50347221" },
      { "name": "Frozen nicoise olives", "code": "50347222" },
      { "name": "Frozen nyons olives", "code": "50347223" },
      { "name": "Frozen picholine olives", "code": "50347224" },
      { "name": "Frozen ponentine olives", "code": "50347225" },
      { "name": "Frozen royal olives", "code": "50347226" },
      { "name": "Frozen seracena olives", "code": "50347227" },
      { "name": "Frozen sevillano olives", "code": "50347228" },
      { "name": "Frozen sicilian olives", "code": "50347229" },
      { "name": "Frozen toscanelle olives", "code": "50347230" },
      { "name": "Frozen organic fruit", "code": "50350000" },
      { "name": "Frozen organic apples", "code": "50351500" },
      { "name": "Frozen organic akane apples", "code": "50351501" },
      { "name": "Frozen organic ambrosia apples", "code": "50351502" },
      { "name": "Frozen organic api apples", "code": "50351503" },
      { "name": "Frozen organic baldwin apples", "code": "50351504" },
      { "name": "Frozen organic braeburn apples", "code": "50351505" },
      { "name": "Frozen organic bramley apples", "code": "50351506" },
      { "name": "Frozen organic bramley seedling apples", "code": "50351507" },
      {
        "name": "Frozen organic calville blanche d'hiver apples",
        "code": "50351508"
      },
      { "name": "Frozen organic cameo apples", "code": "50351509" },
      { "name": "Frozen organic charles ross apples", "code": "50351510" },
      { "name": "Frozen organic codlin apples", "code": "50351511" },
      { "name": "Frozen organic cortland apples", "code": "50351512" },
      { "name": "Frozen organic costard apples", "code": "50351513" },
      { "name": "Frozen organic court pendu plat apples", "code": "50351514" },
      {
        "name": "Frozen organic cox's orange pippin apples",
        "code": "50351515"
      },
      { "name": "Frozen organic crab apples", "code": "50351516" },
      { "name": "Frozen organic crispin apples", "code": "50351517" },
      { "name": "Frozen organic delicious apples", "code": "50351518" },
      { "name": "Frozen organic duchess apples", "code": "50351519" },
      { "name": "Frozen organic earligold apples", "code": "50351520" },
      { "name": "Frozen organic early mcintosh apples", "code": "50351521" },
      { "name": "Frozen organic elstar apples", "code": "50351522" },
      { "name": "Frozen organic empire apples", "code": "50351523" },
      { "name": "Frozen organic flower of kent apples", "code": "50351524" },
      { "name": "Frozen organic fuji apples", "code": "50351525" },
      { "name": "Frozen organic gala apples", "code": "50351526" },
      {
        "name": "Frozen organic gascoyne's scarlet apples",
        "code": "50351527"
      },
      { "name": "Frozen organic gilliflower apples", "code": "50351528" },
      { "name": "Frozen organic ginger gold apples", "code": "50351529" },
      { "name": "Frozen organic gladstone apples", "code": "50351530" },
      { "name": "Frozen organic gloster apples", "code": "50351531" },
      { "name": "Frozen organic gold supreme apples", "code": "50351532" },
      { "name": "Frozen organic golden delicious apples", "code": "50351533" },
      { "name": "Frozen organic golden noble apples", "code": "50351534" },
      { "name": "Frozen organic granny smith apples", "code": "50351535" },
      { "name": "Frozen organic gravenstein apples", "code": "50351536" },
      { "name": "Frozen organic greening apples", "code": "50351537" },
      { "name": "Frozen organic greensleeves apples", "code": "50351538" },
      { "name": "Frozen organic honeycrisp apples", "code": "50351539" },
      { "name": "Frozen organic howgate wonder apples", "code": "50351540" },
      { "name": "Frozen organic ida red apples", "code": "50351541" },
      { "name": "Frozen organic james grieve apples", "code": "50351542" },
      { "name": "Frozen organic jersey mac apples", "code": "50351543" },
      { "name": "Frozen organic jester apples", "code": "50351544" },
      { "name": "Frozen organic jonagold apples", "code": "50351545" },
      { "name": "Frozen organic jonamac apples", "code": "50351546" },
      { "name": "Frozen organic jonathan apples", "code": "50351547" },
      { "name": "Frozen organic katy apples", "code": "50351548" },
      { "name": "Frozen organic kidd's orange red apples", "code": "50351549" },
      { "name": "Frozen organic lady apples", "code": "50351550" },
      { "name": "Frozen organic law rome apples", "code": "50351551" },
      { "name": "Frozen organic laxton apples", "code": "50351552" },
      { "name": "Frozen organic lord derby apples", "code": "50351553" },
      { "name": "Frozen organic macoun apples", "code": "50351554" },
      { "name": "Frozen organic mcintosh apples", "code": "50351555" },
      { "name": "Frozen organic mutsu apples", "code": "50351556" },
      { "name": "Frozen organic newtown pippin apples", "code": "50351557" },
      { "name": "Frozen organic northern spy apples", "code": "50351558" },
      { "name": "Frozen organic orleans reinette apples", "code": "50351559" },
      { "name": "Frozen organic ozark gold apples", "code": "50351560" },
      { "name": "Frozen organic pacific rose apples", "code": "50351561" },
      { "name": "Frozen organic paula red apples", "code": "50351562" },
      { "name": "Frozen organic pearmain apples", "code": "50351563" },
      { "name": "Frozen organic pink lady apples", "code": "50351564" },
      { "name": "Frozen organic pippin apples", "code": "50351565" },
      {
        "name": "Frozen organic pitmaston pineapple apples",
        "code": "50351566"
      },
      { "name": "Frozen organic pomme d'api apples", "code": "50351567" },
      { "name": "Frozen organic prime gold apples", "code": "50351568" },
      { "name": "Frozen organic red astrachan apples", "code": "50351569" },
      { "name": "Frozen organic red boscoop apples", "code": "50351570" },
      { "name": "Frozen organic red chief apples", "code": "50351571" },
      { "name": "Frozen organic red delicious apples", "code": "50351572" },
      { "name": "Frozen organic red gravenstein apples", "code": "50351573" },
      { "name": "Frozen organic red rome apples", "code": "50351574" },
      { "name": "Frozen organic red stayman apples", "code": "50351575" },
      { "name": "Frozen organic red york apples", "code": "50351576" },
      { "name": "Frozen organic reinette apples", "code": "50351577" },
      { "name": "Frozen organic rome beauty apples", "code": "50351578" },
      { "name": "Frozen organic russet apples", "code": "50351579" },
      { "name": "Frozen organic sierra beauty apples", "code": "50351580" },
      { "name": "Frozen organic spartan apples", "code": "50351581" },
      { "name": "Frozen organic stark crimson apples", "code": "50351582" },
      { "name": "Frozen organic starking apples", "code": "50351583" },
      { "name": "Frozen organic stayman apples", "code": "50351584" },
      { "name": "Frozen organic stayman winesap apples", "code": "50351585" },
      { "name": "Frozen organic summer rambo apples", "code": "50351586" },
      { "name": "Frozen organic tsugaru apples", "code": "50351587" },
      { "name": "Frozen organic twenty ounce apples", "code": "50351588" },
      { "name": "Frozen organic tydeman red apples", "code": "50351589" },
      { "name": "Frozen organic vistabella apples", "code": "50351590" },
      { "name": "Frozen organic wealthy apples", "code": "50351591" },
      { "name": "Frozen organic white joaneting apples", "code": "50351592" },
      { "name": "Frozen organic white transparent apples", "code": "50351593" },
      { "name": "Frozen organic winesap apples", "code": "50351594" },
      { "name": "Frozen organic worcester apples", "code": "50351595" },
      { "name": "Frozen organic york imperial apples", "code": "50351596" },
      { "name": "Frozen organic apricots", "code": "50351600" },
      { "name": "Frozen organic ambercot apricots", "code": "50351601" },
      { "name": "Frozen organic apache apricots", "code": "50351602" },
      { "name": "Frozen organic brittany gold apricots", "code": "50351603" },
      { "name": "Frozen organic black apricots", "code": "50351604" },
      { "name": "Frozen organic blenheim apricots", "code": "50351605" },
      { "name": "Frozen organic bonny apricots", "code": "50351606" },
      { "name": "Frozen organic bulida apricots", "code": "50351607" },
      { "name": "Frozen organic castlebrite apricots", "code": "50351608" },
      { "name": "Frozen organic clutha gold apricots", "code": "50351609" },
      { "name": "Frozen organic clutha sun apricots", "code": "50351610" },
      { "name": "Frozen organic darby royal apricots", "code": "50351611" },
      { "name": "Frozen organic dina apricots", "code": "50351612" },
      { "name": "Frozen organic earlicot apricots", "code": "50351613" },
      { "name": "Frozen organic earliman apricots", "code": "50351614" },
      { "name": "Frozen organic early bright apricots", "code": "50351615" },
      { "name": "Frozen organic flaming gold apricots", "code": "50351616" },
      { "name": "Frozen organic fresno apricots", "code": "50351617" },
      { "name": "Frozen organic gold brite apricots", "code": "50351618" },
      { "name": "Frozen organic goldbar apricots", "code": "50351619" },
      { "name": "Frozen organic golden sweet apricots", "code": "50351620" },
      { "name": "Frozen organic goldrich apricots", "code": "50351621" },
      { "name": "Frozen organic helena apricots", "code": "50351622" },
      { "name": "Frozen organic honeycot apricots", "code": "50351623" },
      { "name": "Frozen organic imperial apricots", "code": "50351624" },
      { "name": "Frozen organic jordanne apricots", "code": "50351625" },
      { "name": "Frozen organic jumbo cot apricots", "code": "50351626" },
      { "name": "Frozen organic kandy kot apricots", "code": "50351627" },
      { "name": "Frozen organic katy apricots", "code": "50351628" },
      { "name": "Frozen organic king apricots", "code": "50351629" },
      { "name": "Frozen organic lambertin apricots", "code": "50351630" },
      { "name": "Frozen organic lorna apricots", "code": "50351631" },
      { "name": "Frozen organic lulu belle apricots", "code": "50351632" },
      { "name": "Frozen organic modesto apricots", "code": "50351633" },
      { "name": "Frozen organic moorpark apricots", "code": "50351634" },
      { "name": "Frozen organic orangered apricots", "code": "50351635" },
      { "name": "Frozen organic palstein apricots", "code": "50351636" },
      { "name": "Frozen organic patterson apricots", "code": "50351637" },
      { "name": "Frozen organic perfection apricots", "code": "50351638" },
      { "name": "Frozen organic poppy apricots", "code": "50351639" },
      { "name": "Frozen organic poppycot apricots", "code": "50351640" },
      { "name": "Frozen organic queen apricots", "code": "50351641" },
      { "name": "Frozen organic riland apricots", "code": "50351642" },
      { "name": "Frozen organic rival apricots", "code": "50351643" },
      { "name": "Frozen organic robada apricots", "code": "50351644" },
      { "name": "Frozen organic royal apricots", "code": "50351645" },
      { "name": "Frozen organic royal blenheim apricots", "code": "50351646" },
      { "name": "Frozen organic royal orange apricots", "code": "50351647" },
      { "name": "Frozen organic sundrop apricots", "code": "50351648" },
      { "name": "Frozen organic tilton apricots", "code": "50351649" },
      { "name": "Frozen organic tomcot apricots", "code": "50351650" },
      { "name": "Frozen organic tracy apricots", "code": "50351651" },
      { "name": "Frozen organic tri gem apricots", "code": "50351652" },
      { "name": "Frozen organic valley gold apricots", "code": "50351653" },
      { "name": "Frozen organic westley apricots", "code": "50351654" },
      { "name": "Frozen organic york apricots", "code": "50351655" },
      { "name": "Frozen organic bananas", "code": "50351700" },
      { "name": "Frozen organic apple bananas", "code": "50351701" },
      { "name": "Frozen organic baby bananas", "code": "50351702" },
      { "name": "Frozen organic burro bananas", "code": "50351703" },
      { "name": "Frozen organic cavendish bananas", "code": "50351704" },
      { "name": "Frozen organic dominico bananas", "code": "50351705" },
      { "name": "Frozen organic green bananas", "code": "50351706" },
      { "name": "Frozen organic gros michel bananas", "code": "50351707" },
      { "name": "Frozen organic lacatan bananas", "code": "50351708" },
      { "name": "Frozen organic lady finger banana", "code": "50351709" },
      { "name": "Frozen organic manzano bananas", "code": "50351710" },
      { "name": "Frozen organic mysore bananas", "code": "50351711" },
      { "name": "Frozen organic pisang mas bananas", "code": "50351712" },
      { "name": "Frozen organic red bananas", "code": "50351713" },
      { "name": "Frozen organic saba bananas", "code": "50351714" },
      { "name": "Frozen organic sucrier bananas", "code": "50351715" },
      { "name": "Frozen organic barberries", "code": "50351800" },
      { "name": "Frozen organic paleleaf barberries", "code": "50351801" },
      { "name": "Frozen organic chenault barberries", "code": "50351802" },
      { "name": "Frozen organic red barberries", "code": "50351803" },
      { "name": "Frozen organic wintergreen barberries", "code": "50351804" },
      { "name": "Frozen organic korean barberries", "code": "50351805" },
      { "name": "Frozen organic mentor barberries", "code": "50351806" },
      { "name": "Frozen organic japanese barberries", "code": "50351807" },
      { "name": "Frozen organic atropurpurea barberries", "code": "50351808" },
      { "name": "Frozen organic aurea barberries", "code": "50351809" },
      { "name": "Frozen organic bagatelle barberries", "code": "50351810" },
      { "name": "Frozen organic crimson pygmy barberries", "code": "50351811" },
      { "name": "Frozen organic kobold barberries", "code": "50351812" },
      { "name": "Frozen organic warty barberries", "code": "50351813" },
      { "name": "Frozen organic european barberries", "code": "50351814" },
      { "name": "Frozen organic bearberries", "code": "50351900" },
      { "name": "Frozen organic alpine bearberries", "code": "50351901" },
      { "name": "Frozen organic red bearberries", "code": "50351902" },
      { "name": "Frozen organic common bearberries", "code": "50351903" },
      { "name": "Frozen organic blackberries", "code": "50352000" },
      { "name": "Frozen organic apache blackberries", "code": "50352001" },
      { "name": "Frozen organic black satin blackberries", "code": "50352002" },
      { "name": "Frozen organic boysenberries", "code": "50352003" },
      { "name": "Frozen organic cherokee blackberries", "code": "50352004" },
      { "name": "Frozen organic chester blackberries", "code": "50352005" },
      { "name": "Frozen organic dirksen blackberries", "code": "50352006" },
      { "name": "Frozen organic jostaberries", "code": "50352007" },
      { "name": "Frozen organic loganberries", "code": "50352008" },
      { "name": "Frozen organic marionberries", "code": "50352009" },
      { "name": "Frozen organic navaho blackberries", "code": "50352010" },
      { "name": "Frozen organic nectarberries", "code": "50352011" },
      { "name": "Frozen organic olallie blackberries", "code": "50352012" },
      { "name": "Frozen organic tayberries", "code": "50352013" },
      {
        "name": "Frozen organic thornless hull blackberries",
        "code": "50352014"
      },
      { "name": "Frozen organic youngberries", "code": "50352015" },
      { "name": "Frozen organic bilberries", "code": "50352100" },
      { "name": "Frozen organic bog bilberries", "code": "50352101" },
      { "name": "Frozen organic dwarf bilberries", "code": "50352102" },
      { "name": "Frozen organic mountain bilberries", "code": "50352103" },
      { "name": "Frozen organic oval-leaved bilberries", "code": "50352104" },
      { "name": "Frozen organic blueberries", "code": "50352200" },
      { "name": "Frozen organic bluecrop blueberries", "code": "50352201" },
      { "name": "Frozen organic bluetta blueberries", "code": "50352202" },
      { "name": "Frozen organic brigitta blueberries", "code": "50352203" },
      { "name": "Frozen organic chandler blueberries", "code": "50352204" },
      { "name": "Frozen organic duke blueberries", "code": "50352205" },
      { "name": "Frozen organic hardyblue blueberries", "code": "50352206" },
      { "name": "Frozen organic legacy blueberries", "code": "50352207" },
      { "name": "Frozen organic misty blueberries", "code": "50352208" },
      { "name": "Frozen organic nelson blueberries", "code": "50352209" },
      { "name": "Frozen organic northblue blueberries", "code": "50352210" },
      { "name": "Frozen organic northcountry blueberries", "code": "50352211" },
      { "name": "Frozen organic northsky blueberries", "code": "50352212" },
      { "name": "Frozen organic patriot blueberries", "code": "50352213" },
      { "name": "Frozen organic spartan blueberries", "code": "50352214" },
      { "name": "Frozen organic toro blueberries", "code": "50352215" },
      { "name": "Frozen organic breadfruit", "code": "50352300" },
      { "name": "Frozen organic chataigne breadfruit", "code": "50352301" },
      { "name": "Frozen organic seedless breadfruit", "code": "50352302" },
      { "name": "Frozen organic white heart breadfruit", "code": "50352303" },
      { "name": "Frozen organic yellow heart breadfruit", "code": "50352304" },
      { "name": "Frozen organic cherimoyas", "code": "50352400" },
      { "name": "Frozen organic bays cherimoya", "code": "50352401" },
      { "name": "Frozen organic bronceada cherimoya", "code": "50352402" },
      { "name": "Frozen organic burtons cherimoya", "code": "50352403" },
      {
        "name": "Frozen organic burtons favorite cherimoya",
        "code": "50352404"
      },
      { "name": "Frozen organic jete cherimoya", "code": "50352405" },
      { "name": "Frozen organic reretai cherimoya", "code": "50352406" },
      { "name": "Frozen organic smoothey cherimoya", "code": "50352407" },
      { "name": "Frozen organic spain cherimoya", "code": "50352408" },
      { "name": "Frozen organic white cherimoya", "code": "50352409" },
      { "name": "Frozen organic cherries", "code": "50352500" },
      { "name": "Frozen organic amarelle cherries", "code": "50352501" },
      { "name": "Frozen organic brooks cherries", "code": "50352502" },
      { "name": "Frozen organic bigarreu cherries", "code": "50352503" },
      { "name": "Frozen organic bing cherries", "code": "50352504" },
      { "name": "Frozen organic black republic cherries", "code": "50352505" },
      { "name": "Frozen organic black schmidt cherries", "code": "50352506" },
      { "name": "Frozen organic black tartarian cherries", "code": "50352507" },
      { "name": "Frozen organic fiesta bing cherries", "code": "50352508" },
      { "name": "Frozen organic garnet cherries", "code": "50352509" },
      { "name": "Frozen organic king cherries", "code": "50352510" },
      { "name": "Frozen organic chapman cherries", "code": "50352511" },
      { "name": "Frozen organic lapin cherries", "code": "50352512" },
      { "name": "Frozen organic larian cherries", "code": "50352513" },
      { "name": "Frozen organic dark guines cherries", "code": "50352514" },
      { "name": "Frozen organic montmorency cherries", "code": "50352515" },
      { "name": "Frozen organic duke cherries", "code": "50352516" },
      { "name": "Frozen organic early rivers cherries", "code": "50352517" },
      { "name": "Frozen organic ruby bing cherries", "code": "50352518" },
      { "name": "Frozen organic santina cherries", "code": "50352519" },
      { "name": "Frozen organic geans/guines cherries", "code": "50352520" },
      { "name": "Frozen organic sonata cherries", "code": "50352521" },
      { "name": "Frozen organic lambert cherries", "code": "50352522" },
      { "name": "Frozen organic stella cherries", "code": "50352523" },
      { "name": "Frozen organic sweetheart cherries", "code": "50352524" },
      { "name": "Frozen organic tartarian cherries", "code": "50352525" },
      { "name": "Frozen organic maraschino cherries", "code": "50352527" },
      { "name": "Frozen organic van cherries", "code": "50352528" },
      { "name": "Frozen organic morello cherries", "code": "50352529" },
      { "name": "Frozen organic royal ann cherries", "code": "50352530" },
      { "name": "Frozen organic ranier cherries", "code": "50352531" },
      { "name": "Frozen organic royal cherries", "code": "50352532" },
      { "name": "Frozen organic citrons", "code": "50352600" },
      { "name": "Frozen organic buddha's hand citrons", "code": "50352601" },
      { "name": "Frozen organic fingered citrons", "code": "50352602" },
      { "name": "Frozen organic fo shoukan citrons", "code": "50352603" },
      { "name": "Frozen organic bushakan citrons", "code": "50352604" },
      { "name": "Frozen organic diamante citrons", "code": "50352605" },
      { "name": "Frozen organic etrog citrons", "code": "50352606" },
      { "name": "Frozen organic ponderosa citrons", "code": "50352607" },
      { "name": "Frozen organic cranberries", "code": "50352700" },
      { "name": "Frozen organic ben lear cranberries", "code": "50352701" },
      { "name": "Frozen organic early black cranberries", "code": "50352702" },
      { "name": "Frozen organic grycleski cranberries", "code": "50352703" },
      { "name": "Frozen organic howe cranberries", "code": "50352704" },
      { "name": "Frozen organic lingonberries", "code": "50352705" },
      { "name": "Frozen organic mcfarlin cranberries", "code": "50352706" },
      { "name": "Frozen organic mountain cranberries", "code": "50352707" },
      { "name": "Frozen organic pilgrim cranberries", "code": "50352708" },
      { "name": "Frozen organic searless cranberries", "code": "50352709" },
      { "name": "Frozen organic stevens cranberries", "code": "50352710" },
      { "name": "Frozen organic currants", "code": "50352800" },
      { "name": "Frozen organic hudson bay currants", "code": "50352801" },
      { "name": "Frozen organic waxy currants", "code": "50352802" },
      { "name": "Frozen organic desert currants", "code": "50352803" },
      { "name": "Frozen organic black currants", "code": "50352804" },
      { "name": "Frozen organic red currants", "code": "50352805" },
      { "name": "Frozen organic white currants", "code": "50352806" },
      { "name": "Frozen organic dates", "code": "50352900" },
      { "name": "Frozen organic asharasi dates", "code": "50352901" },
      { "name": "Frozen organic barhi or barhee dates", "code": "50352902" },
      { "name": "Frozen organic deglet noor dates", "code": "50352903" },
      { "name": "Frozen organic fardh dates", "code": "50352904" },
      { "name": "Frozen organic gundila dates", "code": "50352905" },
      { "name": "Frozen organic halawi/halawy dates", "code": "50352906" },
      { "name": "Frozen organic hilali dates", "code": "50352907" },
      { "name": "Frozen organic khadrawi/khadrawy dates", "code": "50352908" },
      { "name": "Frozen organic khalas dates", "code": "50352909" },
      { "name": "Frozen organic khustawi dates", "code": "50352910" },
      { "name": "Frozen organic khidri dates", "code": "50352911" },
      { "name": "Frozen organic medjool/medjul dates", "code": "50352912" },
      { "name": "Frozen organic mactoum dates", "code": "50352913" },
      { "name": "Frozen organic neghal dates", "code": "50352914" },
      { "name": "Frozen organic yatimeh dates", "code": "50352915" },
      { "name": "Frozen organic zahidi dates", "code": "50352916" },
      { "name": "Frozen organic dragonfruit", "code": "50353000" },
      { "name": "Frozen organic pink dragonfruit", "code": "50353001" },
      { "name": "Frozen organic yellow dragonfruit", "code": "50353002" },
      { "name": "Frozen organic figs", "code": "50353100" },
      { "name": "Frozen organic bardajic figs", "code": "50353101" },
      { "name": "Frozen organic brown turkey figs", "code": "50353102" },
      { "name": "Frozen organic calimyrna figs", "code": "50353103" },
      { "name": "Frozen organic conadria figs", "code": "50353104" },
      { "name": "Frozen organic dottado figs", "code": "50353105" },
      { "name": "Frozen organic kadota figs", "code": "50353106" },
      { "name": "Frozen organic mediterranean figs", "code": "50353107" },
      { "name": "Frozen organic mission figs", "code": "50353108" },
      { "name": "Frozen organic smyrna figs", "code": "50353109" },
      { "name": "Frozen organic verdona figs", "code": "50353110" },
      { "name": "Frozen organic white king figs", "code": "50353111" },
      { "name": "Frozen organic gooseberries", "code": "50353200" },
      {
        "name": "Frozen organic early sulphur gooseberries",
        "code": "50353201"
      },
      { "name": "Frozen organic goldendrop gooseberries", "code": "50353202" },
      {
        "name": "Frozen organic langley gage gooseberries",
        "code": "50353203"
      },
      { "name": "Frozen organic leveller gooseberries", "code": "50353204" },
      { "name": "Frozen organic london gooseberries", "code": "50353205" },
      {
        "name": "Frozen organic worcestershire gooseberries",
        "code": "50353206"
      },
      {
        "name": "Frozen organic american worcesterberry gooseberries",
        "code": "50353207"
      },
      { "name": "Frozen organic grapefruit", "code": "50353300" },
      { "name": "Frozen organic burgundy grapefruit", "code": "50353301" },
      { "name": "Frozen organic duncan grapefruit", "code": "50353302" },
      { "name": "Frozen organic foster grapefruit", "code": "50353303" },
      { "name": "Frozen organic marsh grapefruit", "code": "50353304" },
      { "name": "Frozen organic new zealand grapefruit", "code": "50353305" },
      { "name": "Frozen organic rio red grapefruit", "code": "50353306" },
      { "name": "Frozen organic ruby red grapefruit", "code": "50353307" },
      { "name": "Frozen organic star ruby grapefruit", "code": "50353308" },
      { "name": "Frozen organic triumph grapefruit", "code": "50353309" },
      { "name": "Frozen organic table grapes", "code": "50353400" },
      { "name": "Frozen organic alicante grapes", "code": "50353401" },
      { "name": "Frozen organic almeria grapes", "code": "50353402" },
      { "name": "Frozen organic alphonse lavalle grapes", "code": "50353403" },
      { "name": "Frozen organic autumn king grapes", "code": "50353404" },
      { "name": "Frozen organic autumn royal grapes", "code": "50353405" },
      { "name": "Frozen organic autumn seedless grapes", "code": "50353406" },
      { "name": "Frozen organic baresana grapes", "code": "50353407" },
      { "name": "Frozen organic barlinka grapes", "code": "50353408" },
      { "name": "Frozen organic beauty seedless grapes", "code": "50353409" },
      {
        "name": "Frozen organic black beauty seedless grapes",
        "code": "50353410"
      },
      { "name": "Frozen organic black emerald grapes", "code": "50353411" },
      { "name": "Frozen organic black giant grapes", "code": "50353412" },
      { "name": "Frozen organic black globe grapes", "code": "50353413" },
      { "name": "Frozen organic black monukka grapes", "code": "50353414" },
      { "name": "Frozen organic black pearl grapes", "code": "50353415" },
      { "name": "Frozen organic black seedless grapes", "code": "50353416" },
      { "name": "Frozen organic bonheur grapes", "code": "50353417" },
      { "name": "Frozen organic calmeria grapes", "code": "50353418" },
      { "name": "Frozen organic cardinal grapes", "code": "50353419" },
      { "name": "Frozen organic catawba grapes", "code": "50353420" },
      {
        "name": "Frozen organic chasselas/golden chasselas grapes",
        "code": "50353421"
      },
      { "name": "Frozen organic christmas rose grapes", "code": "50353422" },
      { "name": "Frozen organic concord grapes", "code": "50353423" },
      { "name": "Frozen organic concord seedless grapes", "code": "50353424" },
      { "name": "Frozen organic crimson seedless grapes", "code": "50353425" },
      { "name": "Frozen organic dauphine grapes", "code": "50353426" },
      { "name": "Frozen organic delaware grapes", "code": "50353427" },
      { "name": "Frozen organic early muscat grapes", "code": "50353428" },
      { "name": "Frozen organic early sweet grapes", "code": "50353429" },
      { "name": "Frozen organic emerald seedless grapes", "code": "50353430" },
      { "name": "Frozen organic emperatriz grapes", "code": "50353431" },
      { "name": "Frozen organic emperor grapes", "code": "50353432" },
      { "name": "Frozen organic empress grapes", "code": "50353433" },
      { "name": "Frozen organic exotic grapes", "code": "50353434" },
      { "name": "Frozen organic fantasy grapes", "code": "50353435" },
      { "name": "Frozen organic fantasy seedless grapes", "code": "50353436" },
      { "name": "Frozen organic flame grapes", "code": "50353437" },
      { "name": "Frozen organic flame seedless grapes", "code": "50353438" },
      { "name": "Frozen organic flame tokay grapes", "code": "50353439" },
      { "name": "Frozen organic flaming red grapes", "code": "50353440" },
      { "name": "Frozen organic galaxy seedless grapes", "code": "50353441" },
      { "name": "Frozen organic gamay grapes", "code": "50353442" },
      { "name": "Frozen organic gold grapes", "code": "50353443" },
      {
        "name": "Frozen organic hanepoot or honeypot grapes",
        "code": "50353444"
      },
      { "name": "Frozen organic italia grapes", "code": "50353445" },
      { "name": "Frozen organic jade seedless grapes", "code": "50353446" },
      { "name": "Frozen organic jubilee grapes", "code": "50353447" },
      { "name": "Frozen organic king ruby grapes", "code": "50353448" },
      { "name": "Frozen organic kyoho grapes", "code": "50353449" },
      { "name": "Frozen organic la rochelle grapes", "code": "50353450" },
      { "name": "Frozen organic lady finger grapes", "code": "50353451" },
      { "name": "Frozen organic late seedless grapes", "code": "50353452" },
      { "name": "Frozen organic majestic seedless grapes", "code": "50353453" },
      { "name": "Frozen organic malaga grapes", "code": "50353454" },
      { "name": "Frozen organic marroo seedless grapes", "code": "50353455" },
      { "name": "Frozen organic muscadine grapes", "code": "50353456" },
      { "name": "Frozen organic muscat flame grapes", "code": "50353457" },
      { "name": "Frozen organic muscat grapes", "code": "50353458" },
      { "name": "Frozen organic muscat seedless grapes", "code": "50353459" },
      { "name": "Frozen organic napoleon grapes", "code": "50353460" },
      { "name": "Frozen organic negria grapes", "code": "50353461" },
      { "name": "Frozen organic new cross grapes", "code": "50353462" },
      { "name": "Frozen organic niabell grapes", "code": "50353463" },
      { "name": "Frozen organic niagara grapes", "code": "50353464" },
      { "name": "Frozen organic olivette grapes", "code": "50353465" },
      { "name": "Frozen organic perlette grapes", "code": "50353466" },
      { "name": "Frozen organic perlon grapes", "code": "50353467" },
      {
        "name": "Frozen organic prima black seedless grapes",
        "code": "50353468"
      },
      { "name": "Frozen organic princess grapes", "code": "50353469" },
      { "name": "Frozen organic queen grapes", "code": "50353470" },
      { "name": "Frozen organic red blush grapes", "code": "50353471" },
      { "name": "Frozen organic red globe grapes", "code": "50353472" },
      { "name": "Frozen organic red malaga grapes", "code": "50353473" },
      { "name": "Frozen organic red seedless grapes", "code": "50353474" },
      { "name": "Frozen organic regina grapes", "code": "50353475" },
      { "name": "Frozen organic ribier grapes", "code": "50353476" },
      { "name": "Frozen organic rosita grapes", "code": "50353477" },
      { "name": "Frozen organic rouge grapes", "code": "50353478" },
      {
        "name": "Frozen organic royal black seedless grapes",
        "code": "50353479"
      },
      { "name": "Frozen organic ruby red seedless grapes", "code": "50353480" },
      { "name": "Frozen organic ruby seedless grapes", "code": "50353481" },
      { "name": "Frozen organic scarlet royal grapes", "code": "50353482" },
      { "name": "Frozen organic scuppernong grapes", "code": "50353483" },
      { "name": "Frozen organic sugarose grapes", "code": "50353484" },
      { "name": "Frozen organic sugarthirteen grapes", "code": "50353485" },
      { "name": "Frozen organic sugraone grapes", "code": "50353486" },
      { "name": "Frozen organic sugrasixteen grapes", "code": "50353487" },
      { "name": "Frozen organic sultana sun red grapes", "code": "50353488" },
      { "name": "Frozen organic summer royal grapes", "code": "50353489" },
      { "name": "Frozen organic sunset grapes", "code": "50353490" },
      { "name": "Frozen organic superior seedless grapes", "code": "50353491" },
      { "name": "Frozen organic thompson seedless grapes", "code": "50353492" },
      { "name": "Frozen organic tokay/pinot gris grapes", "code": "50353493" },
      { "name": "Frozen organic waltman cross grapes", "code": "50353494" },
      { "name": "Frozen organic white seedless grapes", "code": "50353495" },
      { "name": "Frozen organic zante current grapes", "code": "50353496" },
      { "name": "Frozen organic raisin grapes", "code": "50353500" },
      { "name": "Frozen organic black corinth grapes", "code": "50353501" },
      { "name": "Frozen organic canner grapes", "code": "50353502" },
      { "name": "Frozen organic dovine grapes", "code": "50353503" },
      { "name": "Frozen organic fiesta grapes", "code": "50353504" },
      { "name": "Frozen organic selma pete grapes", "code": "50353505" },
      { "name": "Frozen organic sultana grapes", "code": "50353506" },
      { "name": "Frozen organic wine grapes", "code": "50353600" },
      { "name": "Frozen organic alicante bouschet grapes", "code": "50353601" },
      { "name": "Frozen organic barbera grapes", "code": "50353602" },
      { "name": "Frozen organic burger grapes", "code": "50353603" },
      { "name": "Frozen organic cabernet franc grapes", "code": "50353604" },
      {
        "name": "Frozen organic cabernet sauvignon grapes",
        "code": "50353605"
      },
      { "name": "Frozen organic carignane grapes", "code": "50353606" },
      { "name": "Frozen organic carnelian grapes", "code": "50353607" },
      { "name": "Frozen organic catarratto grapes", "code": "50353608" },
      { "name": "Frozen organic centurian grapes", "code": "50353609" },
      { "name": "Frozen organic charbono grapes", "code": "50353610" },
      { "name": "Frozen organic chardonnay grapes", "code": "50353611" },
      { "name": "Frozen organic chenin blanc grapes", "code": "50353612" },
      { "name": "Frozen organic cinsaut grapes", "code": "50353613" },
      { "name": "Frozen organic dolcetto grapes", "code": "50353614" },
      { "name": "Frozen organic emerald riesling grapes", "code": "50353615" },
      { "name": "Frozen organic french colombard grapes", "code": "50353616" },
      { "name": "Frozen organic gamay napa grapes", "code": "50353617" },
      { "name": "Frozen organic gamay beaujolais grapes", "code": "50353618" },
      { "name": "Frozen organic gewurztraminer grapes", "code": "50353619" },
      { "name": "Frozen organic grenache grapes", "code": "50353620" },
      { "name": "Frozen organic grenache blanc grapes", "code": "50353621" },
      { "name": "Frozen organic lagrein grapes", "code": "50353622" },
      { "name": "Frozen organic lambrusco grapes", "code": "50353623" },
      { "name": "Frozen organic malbec grapes", "code": "50353624" },
      { "name": "Frozen organic malvasia bianca grapes", "code": "50353625" },
      { "name": "Frozen organic marsanne grapes", "code": "50353626" },
      { "name": "Frozen organic mataro grapes", "code": "50353627" },
      { "name": "Frozen organic merlot grapes", "code": "50353628" },
      { "name": "Frozen organic meunier grapes", "code": "50353629" },
      { "name": "Frozen organic mission grapes", "code": "50353630" },
      { "name": "Frozen organic montepulciano grapes", "code": "50353631" },
      { "name": "Frozen organic muscat blanc grapes", "code": "50353632" },
      { "name": "Frozen organic muscat hamburg grapes", "code": "50353633" },
      {
        "name": "Frozen organic muscat of alexandria grapes",
        "code": "50353634"
      },
      { "name": "Frozen organic muscat orange grapes", "code": "50353635" },
      { "name": "Frozen organic nebbiolo grapes", "code": "50353636" },
      { "name": "Frozen organic palomino grapes", "code": "50353637" },
      { "name": "Frozen organic petit verdot grapes", "code": "50353638" },
      { "name": "Frozen organic petite sirah grapes", "code": "50353639" },
      { "name": "Frozen organic pinot blanc grapes", "code": "50353640" },
      { "name": "Frozen organic pinot gris grapes", "code": "50353641" },
      { "name": "Frozen organic pinot noir grapes", "code": "50353642" },
      { "name": "Frozen organic primitivo grapes", "code": "50353643" },
      { "name": "Frozen organic roussanne grapes", "code": "50353644" },
      { "name": "Frozen organic royalty grapes", "code": "50353645" },
      { "name": "Frozen organic rubired grapes", "code": "50353646" },
      { "name": "Frozen organic ruby cabernet grapes", "code": "50353647" },
      { "name": "Frozen organic salvador grapes", "code": "50353648" },
      { "name": "Frozen organic sangiovese grapes", "code": "50353649" },
      { "name": "Frozen organic sauvignon blanc grapes", "code": "50353650" },
      { "name": "Frozen organic sauvignon musque grapes", "code": "50353651" },
      { "name": "Frozen organic semillon grapes", "code": "50353652" },
      { "name": "Frozen organic souzao grapes", "code": "50353653" },
      { "name": "Frozen organic st emilion grapes", "code": "50353654" },
      { "name": "Frozen organic symphony grapes", "code": "50353655" },
      { "name": "Frozen organic syrah grapes", "code": "50353656" },
      { "name": "Frozen organic tannat grapes", "code": "50353657" },
      { "name": "Frozen organic tempranillo grapes", "code": "50353658" },
      { "name": "Frozen organic teroldego grapes", "code": "50353659" },
      { "name": "Frozen organic tocai friulano grapes", "code": "50353660" },
      { "name": "Frozen organic touriga nacional grapes", "code": "50353661" },
      { "name": "Frozen organic triplett blanc grapes", "code": "50353662" },
      { "name": "Frozen organic viognier grapes", "code": "50353663" },
      { "name": "Frozen organic white riesling grapes", "code": "50353664" },
      { "name": "Frozen organic zinfandel grapes", "code": "50353665" },
      { "name": "Frozen organic guavas", "code": "50353700" },
      { "name": "Frozen organic beaumont guavas", "code": "50353701" },
      { "name": "Frozen organic carrley guavas", "code": "50353702" },
      { "name": "Frozen organic lucida guavas", "code": "50353703" },
      { "name": "Frozen organic pineapple guava", "code": "50353704" },
      { "name": "Frozen organic huckleberries", "code": "50353800" },
      {
        "name": "Frozen organic black winter huckleberries",
        "code": "50353801"
      },
      { "name": "Frozen organic cascade huckleberries", "code": "50353802" },
      { "name": "Frozen organic dwarf huckleberries", "code": "50353803" },
      { "name": "Frozen organic mountain huckleberries", "code": "50353804" },
      { "name": "Frozen organic red huckleberries", "code": "50353805" },
      { "name": "Frozen organic kiwi fruit", "code": "50353900" },
      { "name": "Frozen organic ananasnaja kiwi fruit", "code": "50353901" },
      { "name": "Frozen organic arctic beauty kiwi fruit", "code": "50353902" },
      { "name": "Frozen organic blake kiwi fruit", "code": "50353903" },
      { "name": "Frozen organic hayward kiwi fruit", "code": "50353904" },
      { "name": "Frozen organic issai kiwi fruit", "code": "50353905" },
      { "name": "Frozen organic siberian kiwi fruit", "code": "50353906" },
      { "name": "Frozen organic kumquats", "code": "50354000" },
      { "name": "Frozen organic hong kong kumquats", "code": "50354001" },
      { "name": "Frozen organic limequat kumquats", "code": "50354002" },
      { "name": "Frozen organic long fruit kumquats", "code": "50354003" },
      { "name": "Frozen organic malayan kumquats", "code": "50354004" },
      { "name": "Frozen organic meiwa kumquats", "code": "50354005" },
      { "name": "Frozen organic nagami kumquats", "code": "50354006" },
      { "name": "Frozen organic lemons", "code": "50354100" },
      { "name": "Frozen organic baboon lemons", "code": "50354101" },
      { "name": "Frozen organic bearss sicilian lemons", "code": "50354102" },
      { "name": "Frozen organic cameron highlands lemons", "code": "50354103" },
      { "name": "Frozen organic escondido lemons", "code": "50354104" },
      { "name": "Frozen organic eureka lemons", "code": "50354105" },
      { "name": "Frozen organic lisbon lemons", "code": "50354106" },
      { "name": "Frozen organic meyer lemons", "code": "50354107" },
      { "name": "Frozen organic volkamer lemons", "code": "50354108" },
      { "name": "Frozen organic limes", "code": "50354200" },
      { "name": "Frozen organic indian sweet limes", "code": "50354201" },
      { "name": "Frozen organic key limes", "code": "50354202" },
      { "name": "Frozen organic mandarin limes", "code": "50354203" },
      { "name": "Frozen organic philippine limes", "code": "50354204" },
      { "name": "Frozen organic tahitian limes", "code": "50354205" },
      { "name": "Frozen organic bearss limes", "code": "50354206" },
      { "name": "Frozen organic persian limes", "code": "50354207" },
      { "name": "Frozen organic seedless limes", "code": "50354208" },
      { "name": "Frozen organic loquats", "code": "50354300" },
      { "name": "Frozen organic advance loquats", "code": "50354301" },
      { "name": "Frozen organic benlehr loquats", "code": "50354302" },
      { "name": "Frozen organic big jim loquats", "code": "50354303" },
      { "name": "Frozen organic champagne loquats", "code": "50354304" },
      { "name": "Frozen organic early red loquats", "code": "50354305" },
      { "name": "Frozen organic gold nugget loquats", "code": "50354306" },
      { "name": "Frozen organic herd's mammoth loquats", "code": "50354307" },
      { "name": "Frozen organic mogi loquats", "code": "50354308" },
      { "name": "Frozen organic mrs cooksey loquats", "code": "50354309" },
      { "name": "Frozen organic strawberry loquats", "code": "50354310" },
      { "name": "Frozen organic tanaka loquats", "code": "50354311" },
      {
        "name": "Frozen organic victory vista white loquats",
        "code": "50354312"
      },
      { "name": "Frozen organic wolfe loquats", "code": "50354313" },
      {
        "name": "Frozen organic mandarin oranges or tangerines",
        "code": "50354400"
      },
      { "name": "Frozen organic clauselinas oranges", "code": "50354401" },
      { "name": "Frozen organic clementine tangerines", "code": "50354402" },
      {
        "name": "Frozen organic cleopatra mandarin oranges",
        "code": "50354403"
      },
      { "name": "Frozen organic dancy tangerines", "code": "50354404" },
      { "name": "Frozen organic ellensdale oranges", "code": "50354405" },
      { "name": "Frozen organic fairchild oranges", "code": "50354406" },
      { "name": "Frozen organic fallglo oranges", "code": "50354407" },
      { "name": "Frozen organic fortune oranges", "code": "50354408" },
      { "name": "Frozen organic fremont mandarin oranges", "code": "50354409" },
      { "name": "Frozen organic fremont oranges", "code": "50354410" },
      { "name": "Frozen organic golden nugget oranges", "code": "50354411" },
      { "name": "Frozen organic honey mandarin oranges", "code": "50354412" },
      { "name": "Frozen organic honey oranges", "code": "50354413" },
      { "name": "Frozen organic honey tangerines", "code": "50354414" },
      {
        "name": "Frozen organic honeybelle tangelo oranges",
        "code": "50354415"
      },
      { "name": "Frozen organic king mandarin oranges", "code": "50354416" },
      { "name": "Frozen organic kinnow oranges", "code": "50354417" },
      { "name": "Frozen organic lee mandarin oranges", "code": "50354418" },
      { "name": "Frozen organic makokkee oranges", "code": "50354419" },
      { "name": "Frozen organic malvasios oranges", "code": "50354420" },
      {
        "name": "Frozen organic mediterranean mandarin oranges",
        "code": "50354421"
      },
      { "name": "Frozen organic minneola tangelo oranges", "code": "50354422" },
      { "name": "Frozen organic monica oranges", "code": "50354423" },
      { "name": "Frozen organic murcott honey oranges", "code": "50354424" },
      { "name": "Frozen organic murcott tangors", "code": "50354425" },
      {
        "name": "Frozen organic natsudaidai mandarin oranges",
        "code": "50354426"
      },
      {
        "name": "Frozen organic natsumikan mandarin oranges",
        "code": "50354427"
      },
      { "name": "Frozen organic nocatee tangelo oranges", "code": "50354428" },
      { "name": "Frozen organic orlando tangelo oranges", "code": "50354429" },
      { "name": "Frozen organic ortanique tangerines", "code": "50354430" },
      { "name": "Frozen organic page mandarin oranges", "code": "50354431" },
      { "name": "Frozen organic pixie oranges", "code": "50354432" },
      {
        "name": "Frozen organic ponkan bantangas mandarin oranges",
        "code": "50354433"
      },
      { "name": "Frozen organic reyna oranges", "code": "50354434" },
      { "name": "Frozen organic robinson oranges", "code": "50354435" },
      { "name": "Frozen organic saltenitas oranges", "code": "50354436" },
      { "name": "Frozen organic sampson tangelo oranges", "code": "50354437" },
      { "name": "Frozen organic satsuma mandarin oranges", "code": "50354438" },
      {
        "name": "Frozen organic sunburst mandarin oranges",
        "code": "50354439"
      },
      { "name": "Frozen organic tangelos", "code": "50354440" },
      { "name": "Frozen organic tangerina oranges", "code": "50354441" },
      { "name": "Frozen organic temple oranges", "code": "50354442" },
      { "name": "Frozen organic thornton oranges", "code": "50354443" },
      { "name": "Frozen organic wekiwa tangerines", "code": "50354444" },
      { "name": "Frozen organic wilkins tangerines", "code": "50354445" },
      {
        "name": "Frozen organic willowleaf mediterranean tangerines",
        "code": "50354446"
      },
      { "name": "Frozen organic mangoes", "code": "50354500" },
      { "name": "Frozen organic alphonso mangoes", "code": "50354501" },
      { "name": "Frozen organic ataulfo mangoes", "code": "50354502" },
      { "name": "Frozen organic criollo mangoes", "code": "50354503" },
      { "name": "Frozen organic edwards mangoes", "code": "50354504" },
      { "name": "Frozen organic francine mangoes", "code": "50354505" },
      { "name": "Frozen organic francis mangoes", "code": "50354506" },
      { "name": "Frozen organic gandaria mangoes", "code": "50354507" },
      { "name": "Frozen organic haden mangoes", "code": "50354508" },
      { "name": "Frozen organic irwin mangoes", "code": "50354509" },
      { "name": "Frozen organic keitt mangoes", "code": "50354510" },
      { "name": "Frozen organic kent mangoes", "code": "50354511" },
      { "name": "Frozen organic kesar mangoes", "code": "50354512" },
      { "name": "Frozen organic kuini mangoes", "code": "50354513" },
      { "name": "Frozen organic manila super mangoes", "code": "50354514" },
      { "name": "Frozen organic manila mangoes", "code": "50354515" },
      { "name": "Frozen organic mayaguez mangoes", "code": "50354516" },
      { "name": "Frozen organic mulgoba mangoes", "code": "50354517" },
      { "name": "Frozen organic oro mangoes", "code": "50354518" },
      { "name": "Frozen organic palmer mangoes", "code": "50354519" },
      { "name": "Frozen organic parvin mangoes", "code": "50354520" },
      { "name": "Frozen organic sandersha mangoes", "code": "50354521" },
      { "name": "Frozen organic sensation mangoes", "code": "50354522" },
      { "name": "Frozen organic smith mangoes", "code": "50354523" },
      { "name": "Frozen organic tommy atkins mangoes", "code": "50354524" },
      { "name": "Frozen organic van dyke mangoes", "code": "50354525" },
      { "name": "Frozen organic melons", "code": "50354600" },
      { "name": "Frozen organic allsweet melons", "code": "50354601" },
      { "name": "Frozen organic athena melons", "code": "50354602" },
      { "name": "Frozen organic black diamond melons", "code": "50354603" },
      { "name": "Frozen organic cal sweet melons", "code": "50354604" },
      { "name": "Frozen organic carnical melons", "code": "50354605" },
      { "name": "Frozen organic cantaloupe melons", "code": "50354606" },
      { "name": "Frozen organic casaba melons", "code": "50354607" },
      { "name": "Frozen organic cavaillon melons", "code": "50354608" },
      { "name": "Frozen organic charentais melons", "code": "50354609" },
      {
        "name": "Frozen organic charleston gray watermelon",
        "code": "50354610"
      },
      { "name": "Frozen organic crenshaw melons", "code": "50354611" },
      { "name": "Frozen organic crimson sweet melons", "code": "50354612" },
      { "name": "Frozen organic dixie lee melons", "code": "50354613" },
      { "name": "Frozen organic eclipse melons", "code": "50354614" },
      { "name": "Frozen organic ein d'or melons", "code": "50354615" },
      { "name": "Frozen organic fiesta melons", "code": "50354616" },
      { "name": "Frozen organic galia melons", "code": "50354617" },
      { "name": "Frozen organic gaya melons", "code": "50354618" },
      { "name": "Frozen organic hami melons", "code": "50354619" },
      { "name": "Frozen organic honeydew melons", "code": "50354620" },
      { "name": "Frozen organic icebox melons", "code": "50354621" },
      { "name": "Frozen organic ida pride melons", "code": "50354622" },
      { "name": "Frozen organic juan canary melons", "code": "50354623" },
      { "name": "Frozen organic jubilee melons", "code": "50354624" },
      { "name": "Frozen organic jubilation melons", "code": "50354625" },
      { "name": "Frozen organic kakhi/kakri melons", "code": "50354626" },
      { "name": "Frozen organic kiwano melons", "code": "50354627" },
      { "name": "Frozen organic korean melons", "code": "50354628" },
      { "name": "Frozen organic long gray melons", "code": "50354629" },
      { "name": "Frozen organic mayan melons", "code": "50354630" },
      { "name": "Frozen organic micky lee melons", "code": "50354631" },
      { "name": "Frozen organic mirage melons", "code": "50354632" },
      {
        "name": "Frozen organic moon and stars watermelon",
        "code": "50354633"
      },
      { "name": "Frozen organic ogen melons", "code": "50354634" },
      { "name": "Frozen organic patriot melons", "code": "50354635" },
      { "name": "Frozen organic peacock melons", "code": "50354636" },
      { "name": "Frozen organic pepino melons", "code": "50354637" },
      { "name": "Frozen organic persian melons", "code": "50354638" },
      { "name": "Frozen organic picnic melons", "code": "50354639" },
      { "name": "Frozen organic piel de sapo melons", "code": "50354640" },
      { "name": "Frozen organic pineapple melons", "code": "50354641" },
      { "name": "Frozen organic quetzali melons", "code": "50354642" },
      { "name": "Frozen organic red goblin melons", "code": "50354643" },
      { "name": "Frozen organic regency melons", "code": "50354644" },
      { "name": "Frozen organic royal majestic melons", "code": "50354645" },
      { "name": "Frozen organic royal star melons", "code": "50354646" },
      { "name": "Frozen organic royal sweet melons", "code": "50354647" },
      { "name": "Frozen organic santa claus melons", "code": "50354648" },
      { "name": "Frozen organic sharlyn melons", "code": "50354649" },
      { "name": "Frozen organic spanish melons", "code": "50354650" },
      { "name": "Frozen organic sprite melons", "code": "50354651" },
      { "name": "Frozen organic starbright melons", "code": "50354652" },
      { "name": "Frozen organic stars n stripes melons", "code": "50354653" },
      { "name": "Frozen organic sugar baby melons", "code": "50354654" },
      { "name": "Frozen organic sugar baby watermelon", "code": "50354655" },
      { "name": "Frozen organic sunsweet melons", "code": "50354656" },
      {
        "name": "Frozen organic sweet heart seedless watermelon",
        "code": "50354657"
      },
      { "name": "Frozen organic temptation melons", "code": "50354658" },
      { "name": "Frozen organic tiger baby melons", "code": "50354659" },
      { "name": "Frozen organic tuscan type melons", "code": "50354660" },
      { "name": "Frozen organic yellow baby watermelon", "code": "50354661" },
      { "name": "Frozen organic mulberries", "code": "50354700" },
      { "name": "Frozen organic black mulberries", "code": "50354701" },
      { "name": "Frozen organic white mulberries", "code": "50354702" },
      { "name": "Frozen organic bayberries or myrtles", "code": "50354800" },
      { "name": "Frozen organic bog myrtle", "code": "50354801" },
      { "name": "Frozen organic bayberries", "code": "50354802" },
      { "name": "Frozen organic nectarines", "code": "50354900" },
      { "name": "Frozen organic april glo nectarines", "code": "50354901" },
      { "name": "Frozen organic arctic mist nectarines", "code": "50354902" },
      { "name": "Frozen organic arctic snow nectarines", "code": "50354903" },
      { "name": "Frozen organic arctic star nectarines", "code": "50354904" },
      { "name": "Frozen organic arctic sweet nectarines", "code": "50354905" },
      { "name": "Frozen organic arctic glo nectarines", "code": "50354906" },
      { "name": "Frozen organic august fire nectarines", "code": "50354907" },
      { "name": "Frozen organic august pearl nectarines", "code": "50354908" },
      { "name": "Frozen organic august red nectarines", "code": "50354909" },
      { "name": "Frozen organic autumn star nectarines", "code": "50354910" },
      { "name": "Frozen organic big john nectarines", "code": "50354911" },
      { "name": "Frozen organic bright pearl nectarines", "code": "50354912" },
      {
        "name": "Frozen organic diamond bright nectarines",
        "code": "50354913"
      },
      { "name": "Frozen organic diamond ray nectarines", "code": "50354914" },
      { "name": "Frozen organic earliglo nectarines", "code": "50354915" },
      { "name": "Frozen organic early diamond nectarines", "code": "50354916" },
      { "name": "Frozen organic fairlane nectarines", "code": "50354917" },
      { "name": "Frozen organic fantasia nectarines", "code": "50354918" },
      { "name": "Frozen organic fire pearl nectarines", "code": "50354919" },
      { "name": "Frozen organic fire sweet nectarines", "code": "50354920" },
      { "name": "Frozen organic flamekist nectarines", "code": "50354921" },
      { "name": "Frozen organic flat type nectarines", "code": "50354922" },
      {
        "name": "Frozen organic garden delight nectarines",
        "code": "50354923"
      },
      { "name": "Frozen organic goldmine nectarines", "code": "50354924" },
      { "name": "Frozen organic grand pearl nectarines", "code": "50354925" },
      { "name": "Frozen organic hardired nectarines", "code": "50354926" },
      { "name": "Frozen organic honey blaze nectarines", "code": "50354927" },
      { "name": "Frozen organic july red nectarines", "code": "50354928" },
      { "name": "Frozen organic kay pearl nectarines", "code": "50354929" },
      { "name": "Frozen organic kay sweet nectarines", "code": "50354930" },
      { "name": "Frozen organic may diamond nectarines", "code": "50354931" },
      { "name": "Frozen organic mayfire nectarines", "code": "50354932" },
      { "name": "Frozen organic mayglo nectarines", "code": "50354933" },
      { "name": "Frozen organic mericrest nectarines", "code": "50354934" },
      { "name": "Frozen organic red diamond nectarines", "code": "50354935" },
      { "name": "Frozen organic red gold nectarines", "code": "50354936" },
      { "name": "Frozen organic red jim nectarines", "code": "50354937" },
      { "name": "Frozen organic red roy nectarines", "code": "50354938" },
      { "name": "Frozen organic rio red nectarines", "code": "50354939" },
      { "name": "Frozen organic rose diamond nectarines", "code": "50354940" },
      { "name": "Frozen organic royal glo nectarines", "code": "50354941" },
      { "name": "Frozen organic ruby diamond nectarines", "code": "50354942" },
      { "name": "Frozen organic ruby sweet nectarines", "code": "50354943" },
      { "name": "Frozen organic ruddy jewel nectarines", "code": "50354944" },
      { "name": "Frozen organic september red nectarines", "code": "50354945" },
      { "name": "Frozen organic snowqueen nectarines", "code": "50354946" },
      { "name": "Frozen organic spring bright nectarines", "code": "50354947" },
      { "name": "Frozen organic spring red nectarines", "code": "50354948" },
      { "name": "Frozen organic summer blush nectarines", "code": "50354949" },
      { "name": "Frozen organic summer brite nectarines", "code": "50354950" },
      {
        "name": "Frozen organic summer diamond nectarines",
        "code": "50354951"
      },
      { "name": "Frozen organic summer fire nectarines", "code": "50354952" },
      { "name": "Frozen organic summer grand nectarines", "code": "50354953" },
      { "name": "Frozen organic sunglo nectarines", "code": "50354954" },
      { "name": "Frozen organic zee fire nectarines", "code": "50354955" },
      { "name": "Frozen organic zee glo nectarines", "code": "50354956" },
      { "name": "Frozen organic zeegrand nectarines", "code": "50354957" },
      { "name": "Frozen organic oranges", "code": "50355000" },
      { "name": "Frozen organic african sour oranges", "code": "50355001" },
      { "name": "Frozen organic ambersweet oranges", "code": "50355002" },
      { "name": "Frozen organic argentine sour oranges", "code": "50355003" },
      { "name": "Frozen organic bahianinha oranges", "code": "50355004" },
      { "name": "Frozen organic bergamot oranges", "code": "50355005" },
      { "name": "Frozen organic berna oranges", "code": "50355006" },
      { "name": "Frozen organic bigaradier apepu oranges", "code": "50355007" },
      {
        "name": "Frozen organic bittersweet daidai oranges",
        "code": "50355008"
      },
      { "name": "Frozen organic blonde oranges", "code": "50355009" },
      { "name": "Frozen organic blood oranges", "code": "50355010" },
      { "name": "Frozen organic california navel oranges", "code": "50355011" },
      { "name": "Frozen organic cara cara oranges", "code": "50355012" },
      { "name": "Frozen organic chinotto oranges", "code": "50355013" },
      { "name": "Frozen organic dream navel oranges", "code": "50355014" },
      { "name": "Frozen organic gou tou oranges", "code": "50355015" },
      { "name": "Frozen organic hamlin oranges", "code": "50355016" },
      { "name": "Frozen organic jaffa oranges", "code": "50355017" },
      { "name": "Frozen organic jincheng oranges", "code": "50355018" },
      { "name": "Frozen organic k-early oranges", "code": "50355019" },
      { "name": "Frozen organic kona oranges", "code": "50355020" },
      { "name": "Frozen organic late navel oranges", "code": "50355021" },
      { "name": "Frozen organic late valencia oranges", "code": "50355022" },
      { "name": "Frozen organic limequat oranges", "code": "50355023" },
      { "name": "Frozen organic marr oranges", "code": "50355024" },
      { "name": "Frozen organic melogold oranges", "code": "50355025" },
      { "name": "Frozen organic moro oranges", "code": "50355026" },
      { "name": "Frozen organic moro tarocco oranges", "code": "50355027" },
      { "name": "Frozen organic navel oranges", "code": "50355028" },
      { "name": "Frozen organic navelina oranges", "code": "50355029" },
      { "name": "Frozen organic oro blanco oranges", "code": "50355030" },
      { "name": "Frozen organic osceola oranges", "code": "50355031" },
      { "name": "Frozen organic parson brown oranges", "code": "50355032" },
      { "name": "Frozen organic pera oranges", "code": "50355033" },
      { "name": "Frozen organic pummulo oranges", "code": "50355034" },
      { "name": "Frozen organic rhode red oranges", "code": "50355035" },
      { "name": "Frozen organic roble oranges", "code": "50355036" },
      { "name": "Frozen organic salustianas oranges", "code": "50355037" },
      { "name": "Frozen organic sanguine oranges", "code": "50355038" },
      { "name": "Frozen organic sanguinelli oranges", "code": "50355039" },
      { "name": "Frozen organic seville oranges", "code": "50355040" },
      { "name": "Frozen organic shamouti jaffa oranges", "code": "50355041" },
      { "name": "Frozen organic tunis oranges", "code": "50355042" },
      { "name": "Frozen organic valencia oranges", "code": "50355043" },
      { "name": "Frozen organic washington navel oranges", "code": "50355044" },
      { "name": "Frozen organic papayas", "code": "50355100" },
      { "name": "Frozen organic green cooking papayas", "code": "50355101" },
      { "name": "Frozen organic maradol papayas", "code": "50355102" },
      { "name": "Frozen organic mexican yellow papayas", "code": "50355103" },
      { "name": "Frozen organic mountain papayas", "code": "50355104" },
      { "name": "Frozen organic solo papayas", "code": "50355105" },
      { "name": "Frozen organic tainung papayas", "code": "50355106" },
      { "name": "Frozen organic passion fruit", "code": "50355200" },
      { "name": "Frozen organic banana passion fruit", "code": "50355201" },
      { "name": "Frozen organic blue passion flower", "code": "50355202" },
      {
        "name": "Frozen organic crackerjack passion fruit",
        "code": "50355203"
      },
      {
        "name": "Frozen organic giant granadilla passion fruit",
        "code": "50355204"
      },
      {
        "name": "Frozen organic golden granadilla passion fruit",
        "code": "50355205"
      },
      { "name": "Frozen organic maypops passion fruit", "code": "50355206" },
      {
        "name": "Frozen organic red granadilla passion fruit",
        "code": "50355207"
      },
      {
        "name": "Frozen organic sweet granadilla passion fruit",
        "code": "50355208"
      },
      {
        "name": "Frozen organic water lemon passion fruit",
        "code": "50355209"
      },
      {
        "name": "Frozen organic wing-stemmed passion flower",
        "code": "50355210"
      },
      { "name": "Frozen organic peaches", "code": "50355300" },
      { "name": "Frozen organic amber crest peaches", "code": "50355301" },
      { "name": "Frozen organic april snow peaches", "code": "50355302" },
      { "name": "Frozen organic august lady peaches", "code": "50355303" },
      { "name": "Frozen organic autumn flame peaches", "code": "50355304" },
      { "name": "Frozen organic autumn lady peaches", "code": "50355305" },
      { "name": "Frozen organic babcock peaches", "code": "50355306" },
      { "name": "Frozen organic brittney lane peaches", "code": "50355307" },
      { "name": "Frozen organic cary mac peaches", "code": "50355308" },
      { "name": "Frozen organic classic peaches", "code": "50355309" },
      { "name": "Frozen organic country sweet peaches", "code": "50355310" },
      { "name": "Frozen organic crest haven peaches", "code": "50355311" },
      { "name": "Frozen organic crimson lady peaches", "code": "50355312" },
      { "name": "Frozen organic crown princess peaches", "code": "50355313" },
      { "name": "Frozen organic david sun peaches", "code": "50355314" },
      { "name": "Frozen organic diamond princess peaches", "code": "50355315" },
      { "name": "Frozen organic earlirich peaches", "code": "50355316" },
      { "name": "Frozen organic early majestic peaches", "code": "50355317" },
      { "name": "Frozen organic early treat peaches", "code": "50355318" },
      { "name": "Frozen organic elegant lady peaches", "code": "50355319" },
      { "name": "Frozen organic empress peaches", "code": "50355320" },
      { "name": "Frozen organic encore peaches", "code": "50355321" },
      { "name": "Frozen organic fancy lady peaches", "code": "50355322" },
      { "name": "Frozen organic fire prince peaches", "code": "50355323" },
      { "name": "Frozen organic flame crest peaches", "code": "50355324" },
      { "name": "Frozen organic flat type peaches", "code": "50355325" },
      { "name": "Frozen organic flavorcrest peaches", "code": "50355326" },
      { "name": "Frozen organic florida prince peaches", "code": "50355327" },
      { "name": "Frozen organic full moon peaches", "code": "50355328" },
      { "name": "Frozen organic harvester peaches", "code": "50355329" },
      { "name": "Frozen organic ice princess peaches", "code": "50355330" },
      { "name": "Frozen organic ivory princess peaches", "code": "50355331" },
      { "name": "Frozen organic jersey queen peaches", "code": "50355332" },
      { "name": "Frozen organic john henry peaches", "code": "50355333" },
      { "name": "Frozen organic june prince peaches", "code": "50355334" },
      { "name": "Frozen organic kaweah peaches", "code": "50355335" },
      { "name": "Frozen organic klondike peaches", "code": "50355336" },
      { "name": "Frozen organic lindo peaches", "code": "50355337" },
      { "name": "Frozen organic loring peaches", "code": "50355338" },
      { "name": "Frozen organic majestic peaches", "code": "50355339" },
      { "name": "Frozen organic o'henry peaches", "code": "50355340" },
      { "name": "Frozen organic queencrest peaches", "code": "50355341" },
      { "name": "Frozen organic red lady peaches", "code": "50355342" },
      { "name": "Frozen organic redglobe peaches", "code": "50355343" },
      { "name": "Frozen organic redhaven peaches", "code": "50355344" },
      { "name": "Frozen organic redtop peaches", "code": "50355345" },
      { "name": "Frozen organic regina peaches", "code": "50355346" },
      { "name": "Frozen organic rich lady peaches", "code": "50355347" },
      { "name": "Frozen organic rich may peaches", "code": "50355348" },
      { "name": "Frozen organic royal glory peaches", "code": "50355349" },
      { "name": "Frozen organic royal lady peaches", "code": "50355350" },
      { "name": "Frozen organic september snow peaches", "code": "50355351" },
      { "name": "Frozen organic september sun peaches", "code": "50355352" },
      { "name": "Frozen organic sierra gem peaches", "code": "50355353" },
      { "name": "Frozen organic snow angel peaches", "code": "50355354" },
      { "name": "Frozen organic snow gem peaches", "code": "50355355" },
      { "name": "Frozen organic snow king peaches", "code": "50355356" },
      { "name": "Frozen organic spring lady peaches", "code": "50355357" },
      { "name": "Frozen organic spring snow peaches", "code": "50355358" },
      { "name": "Frozen organic springcrest peaches", "code": "50355359" },
      { "name": "Frozen organic sugar giant peaches", "code": "50355360" },
      { "name": "Frozen organic sugar lady peaches", "code": "50355361" },
      { "name": "Frozen organic sun bright peaches", "code": "50355362" },
      { "name": "Frozen organic sunhigh peaches", "code": "50355363" },
      { "name": "Frozen organic super lady peaches", "code": "50355364" },
      { "name": "Frozen organic super rich peaches", "code": "50355365" },
      { "name": "Frozen organic surecrop peaches", "code": "50355366" },
      { "name": "Frozen organic sweet dream peaches", "code": "50355367" },
      { "name": "Frozen organic sweet september peaches", "code": "50355368" },
      { "name": "Frozen organic vista peaches", "code": "50355369" },
      { "name": "Frozen organic white lady peaches", "code": "50355370" },
      { "name": "Frozen organic zee lady peaches", "code": "50355371" },
      { "name": "Frozen organic pears", "code": "50355400" },
      { "name": "Frozen organic abate fetel pears", "code": "50355401" },
      { "name": "Frozen organic anjou pears", "code": "50355402" },
      { "name": "Frozen organic asian pears", "code": "50355403" },
      { "name": "Frozen organic bartlett pears", "code": "50355404" },
      { "name": "Frozen organic best ever pears", "code": "50355405" },
      { "name": "Frozen organic beth pears", "code": "50355406" },
      { "name": "Frozen organic beurre pears", "code": "50355407" },
      { "name": "Frozen organic bosc pears", "code": "50355408" },
      { "name": "Frozen organic clapp favorite pears", "code": "50355409" },
      { "name": "Frozen organic comice pears", "code": "50355410" },
      { "name": "Frozen organic concorde pears", "code": "50355411" },
      { "name": "Frozen organic conference pears", "code": "50355412" },
      { "name": "Frozen organic crimson red pears", "code": "50355413" },
      { "name": "Frozen organic d'anjou pears", "code": "50355414" },
      { "name": "Frozen organic dr jules guyot pears", "code": "50355415" },
      { "name": "Frozen organic early pears", "code": "50355416" },
      { "name": "Frozen organic emperor brown pears", "code": "50355417" },
      { "name": "Frozen organic forelle pears", "code": "50355418" },
      { "name": "Frozen organic french butter pears", "code": "50355419" },
      { "name": "Frozen organic glou morceau pears", "code": "50355420" },
      { "name": "Frozen organic hosui pears", "code": "50355421" },
      { "name": "Frozen organic italian butter pears", "code": "50355422" },
      { "name": "Frozen organic jargonelle pears", "code": "50355423" },
      { "name": "Frozen organic juno pears", "code": "50355424" },
      {
        "name": "Frozen organic kaiserlouise bonne de jersey pears",
        "code": "50355425"
      },
      { "name": "Frozen organic keiffer pears", "code": "50355426" },
      { "name": "Frozen organic kings royal pears", "code": "50355427" },
      { "name": "Frozen organic limonera pears", "code": "50355428" },
      { "name": "Frozen organic merton pride pears", "code": "50355429" },
      { "name": "Frozen organic mountain bartlett pears", "code": "50355430" },
      { "name": "Frozen organic olivier de serres pears", "code": "50355431" },
      { "name": "Frozen organic onward pears", "code": "50355432" },
      { "name": "Frozen organic packham's triumph pears", "code": "50355433" },
      { "name": "Frozen organic paraiso pears", "code": "50355434" },
      { "name": "Frozen organic passe crasanne pears", "code": "50355435" },
      { "name": "Frozen organic perry pears", "code": "50355436" },
      { "name": "Frozen organic red bartlett pears", "code": "50355437" },
      { "name": "Frozen organic red d'anjou pears", "code": "50355438" },
      { "name": "Frozen organic rocha pears", "code": "50355439" },
      { "name": "Frozen organic rosey red pears", "code": "50355440" },
      { "name": "Frozen organic rosy red pears", "code": "50355441" },
      { "name": "Frozen organic royal majestic pears", "code": "50355442" },
      { "name": "Frozen organic ruby red pears", "code": "50355443" },
      { "name": "Frozen organic santa maria pears", "code": "50355444" },
      { "name": "Frozen organic seckel pears", "code": "50355445" },
      { "name": "Frozen organic sensation pears", "code": "50355446" },
      { "name": "Frozen organic star crimson pears", "code": "50355447" },
      { "name": "Frozen organic stark crimson pears", "code": "50355448" },
      { "name": "Frozen organic summer bartlett pears", "code": "50355449" },
      { "name": "Frozen organic summer gold pears", "code": "50355450" },
      { "name": "Frozen organic sun gold pears", "code": "50355451" },
      { "name": "Frozen organic sunsprite pears", "code": "50355452" },
      { "name": "Frozen organic taylors gold pears", "code": "50355453" },
      { "name": "Frozen organic taylors red pears", "code": "50355454" },
      { "name": "Frozen organic tientsin pears", "code": "50355455" },
      { "name": "Frozen organic tosca pears", "code": "50355456" },
      { "name": "Frozen organic warden pears", "code": "50355457" },
      {
        "name": "Frozen organic williams bon chretien pears",
        "code": "50355458"
      },
      { "name": "Frozen organic williams pears", "code": "50355459" },
      { "name": "Frozen organic winter nelis pears", "code": "50355460" },
      { "name": "Frozen organic persimmons", "code": "50355500" },
      { "name": "Frozen organic american persimmon", "code": "50355501" },
      { "name": "Frozen organic black sapote persimmon", "code": "50355502" },
      { "name": "Frozen organic chapote/black persimmon", "code": "50355503" },
      { "name": "Frozen organic date plum persimmon", "code": "50355504" },
      { "name": "Frozen organic fuyu persimmons", "code": "50355505" },
      { "name": "Frozen organic giant fuyu persimmons", "code": "50355506" },
      { "name": "Frozen organic hachiya persimmons", "code": "50355507" },
      {
        "name": "Frozen organic mabolo/butter fruit persimmon",
        "code": "50355508"
      },
      { "name": "Frozen organic principe ito persimmons", "code": "50355509" },
      {
        "name": "Frozen organic royal brillante persimmons",
        "code": "50355510"
      },
      { "name": "Frozen organic sharon fruit persimmon", "code": "50355511" },
      { "name": "Frozen organic triumph persimmons", "code": "50355512" },
      { "name": "Frozen organic pineapples", "code": "50355600" },
      { "name": "Frozen organic cherimoya pineapple", "code": "50355601" },
      { "name": "Frozen organic golden pineapple", "code": "50355602" },
      { "name": "Frozen organic hilo pineapple", "code": "50355603" },
      { "name": "Frozen organic kona sugarloaf pineapple", "code": "50355604" },
      { "name": "Frozen organic natal queen pineapple", "code": "50355605" },
      { "name": "Frozen organic pernambuco pineapple", "code": "50355606" },
      { "name": "Frozen organic red spanish pineapple", "code": "50355607" },
      { "name": "Frozen organic smooth cayenne pineapple", "code": "50355608" },
      { "name": "Frozen organic sugarloaf pineapple", "code": "50355609" },
      { "name": "Frozen organic variegated pineapple", "code": "50355610" },
      { "name": "Frozen organic plucots", "code": "50355700" },
      { "name": "Frozen organic black kat plucot", "code": "50355701" },
      { "name": "Frozen organic blue gusto plucot", "code": "50355702" },
      { "name": "Frozen organic crimson heart plucot", "code": "50355703" },
      { "name": "Frozen organic dapple dandy plucot", "code": "50355704" },
      { "name": "Frozen organic dapple fire plucot", "code": "50355705" },
      { "name": "Frozen organic early dapple plucot", "code": "50355706" },
      { "name": "Frozen organic flavor fall plucot", "code": "50355707" },
      { "name": "Frozen organic flavor gold plucot", "code": "50355708" },
      { "name": "Frozen organic flavor grenade plucot", "code": "50355709" },
      { "name": "Frozen organic flavor heart plucot", "code": "50355710" },
      { "name": "Frozen organic flavor jewel plucot", "code": "50355711" },
      { "name": "Frozen organic flavor king plucot", "code": "50355712" },
      { "name": "Frozen organic flavor queen plucot", "code": "50355713" },
      { "name": "Frozen organic flavor supreme plucot", "code": "50355714" },
      { "name": "Frozen organic flavor treat plucot", "code": "50355715" },
      { "name": "Frozen organic flavorella plucot", "code": "50355716" },
      { "name": "Frozen organic flavorich plucot", "code": "50355717" },
      { "name": "Frozen organic flavorosa plucot", "code": "50355718" },
      { "name": "Frozen organic geo pride plucot", "code": "50355719" },
      { "name": "Frozen organic red kat plucot", "code": "50355720" },
      { "name": "Frozen organic royal treat plucot", "code": "50355721" },
      { "name": "Frozen organic sierra rose plucot", "code": "50355722" },
      { "name": "Frozen organic sweet geisha plucot", "code": "50355723" },
      { "name": "Frozen organic plums", "code": "50355800" },
      { "name": "Frozen organic amber jewel plums", "code": "50355801" },
      { "name": "Frozen organic angeleno plums", "code": "50355802" },
      { "name": "Frozen organic aurora plums", "code": "50355803" },
      { "name": "Frozen organic autumn beaut plums", "code": "50355804" },
      { "name": "Frozen organic autumn giant plums", "code": "50355805" },
      { "name": "Frozen organic autumn pride plums", "code": "50355806" },
      { "name": "Frozen organic autumn rosa plums", "code": "50355807" },
      { "name": "Frozen organic beach plum", "code": "50355808" },
      { "name": "Frozen organic betty anne plums", "code": "50355809" },
      { "name": "Frozen organic black beaut plums", "code": "50355810" },
      { "name": "Frozen organic black bullace plum", "code": "50355811" },
      { "name": "Frozen organic black diamond plums", "code": "50355812" },
      { "name": "Frozen organic black giant plums", "code": "50355813" },
      { "name": "Frozen organic black ice plums", "code": "50355814" },
      { "name": "Frozen organic black splendor plums", "code": "50355815" },
      { "name": "Frozen organic blackamber plums", "code": "50355816" },
      { "name": "Frozen organic burgundy plums", "code": "50355817" },
      { "name": "Frozen organic carlsbad plum", "code": "50355818" },
      { "name": "Frozen organic casselman plums", "code": "50355819" },
      { "name": "Frozen organic catalina plums", "code": "50355820" },
      { "name": "Frozen organic damson plum", "code": "50355821" },
      { "name": "Frozen organic dolly plums", "code": "50355822" },
      { "name": "Frozen organic earliqueen plums", "code": "50355823" },
      { "name": "Frozen organic early rosa plums", "code": "50355824" },
      { "name": "Frozen organic ebony may plums", "code": "50355825" },
      { "name": "Frozen organic ebony plums", "code": "50355826" },
      { "name": "Frozen organic elephant heart plums", "code": "50355827" },
      { "name": "Frozen organic emerald beaut plums", "code": "50355828" },
      { "name": "Frozen organic empress plums", "code": "50355829" },
      { "name": "Frozen organic freedom plums", "code": "50355830" },
      { "name": "Frozen organic friar plums", "code": "50355831" },
      { "name": "Frozen organic gar red plums", "code": "50355832" },
      { "name": "Frozen organic governor's plum", "code": "50355833" },
      { "name": "Frozen organic grand rosa plums", "code": "50355834" },
      { "name": "Frozen organic green gage plum", "code": "50355835" },
      { "name": "Frozen organic greengage plums", "code": "50355836" },
      { "name": "Frozen organic hiromi plums", "code": "50355837" },
      { "name": "Frozen organic hiromi red plums", "code": "50355838" },
      { "name": "Frozen organic holiday plums", "code": "50355839" },
      { "name": "Frozen organic howard sun plums", "code": "50355840" },
      { "name": "Frozen organic interspecific type plums", "code": "50355841" },
      { "name": "Frozen organic jamaican plum", "code": "50355842" },
      { "name": "Frozen organic joanna red plums", "code": "50355843" },
      { "name": "Frozen organic kelsey plums", "code": "50355844" },
      { "name": "Frozen organic king james plums", "code": "50355845" },
      { "name": "Frozen organic laroda plums", "code": "50355846" },
      { "name": "Frozen organic late rosa plums", "code": "50355847" },
      { "name": "Frozen organic linda rosa plums", "code": "50355848" },
      { "name": "Frozen organic lone star red plums", "code": "50355849" },
      { "name": "Frozen organic mariposa plums", "code": "50355850" },
      { "name": "Frozen organic marked black plums", "code": "50355851" },
      { "name": "Frozen organic marked red plums", "code": "50355852" },
      { "name": "Frozen organic mirabelle plum", "code": "50355853" },
      { "name": "Frozen organic october sun plums", "code": "50355854" },
      { "name": "Frozen organic owen t plums", "code": "50355855" },
      { "name": "Frozen organic perdrigon plum", "code": "50355856" },
      { "name": "Frozen organic pink delight plums", "code": "50355857" },
      { "name": "Frozen organic president plums", "code": "50355858" },
      { "name": "Frozen organic primetime plums", "code": "50355859" },
      { "name": "Frozen organic purple majesty plums", "code": "50355860" },
      { "name": "Frozen organic queen rosa plums", "code": "50355861" },
      { "name": "Frozen organic quetsch plum", "code": "50355862" },
      { "name": "Frozen organic red beaut plums", "code": "50355863" },
      { "name": "Frozen organic red lane plums", "code": "50355864" },
      { "name": "Frozen organic red ram plums", "code": "50355865" },
      { "name": "Frozen organic red rosa plums", "code": "50355866" },
      { "name": "Frozen organic rich red plums", "code": "50355867" },
      { "name": "Frozen organic rosemary plums", "code": "50355868" },
      { "name": "Frozen organic royal diamond plums", "code": "50355869" },
      { "name": "Frozen organic royal red plums", "code": "50355870" },
      { "name": "Frozen organic royal zee plums", "code": "50355871" },
      { "name": "Frozen organic roysum plums", "code": "50355872" },
      { "name": "Frozen organic santa rosa plums", "code": "50355873" },
      { "name": "Frozen organic saphire plums", "code": "50355874" },
      { "name": "Frozen organic sloe plum", "code": "50355875" },
      { "name": "Frozen organic st catherine plum", "code": "50355876" },
      { "name": "Frozen organic white bullace plum", "code": "50355877" },
      { "name": "Frozen organic pomegranates", "code": "50355900" },
      { "name": "Frozen organic foothill pomegranates", "code": "50355901" },
      { "name": "Frozen organic granada pomegranates", "code": "50355902" },
      { "name": "Frozen organic jolly red pomegranates", "code": "50355903" },
      { "name": "Frozen organic nana pomegranates", "code": "50355904" },
      { "name": "Frozen organic pat's red pomegranates", "code": "50355905" },
      { "name": "Frozen organic pinkhan pomegranates", "code": "50355906" },
      {
        "name": "Frozen organic purple velvet pomegranates",
        "code": "50355907"
      },
      { "name": "Frozen organic wonderful pomegranates", "code": "50355908" },
      { "name": "Frozen organic pomelos", "code": "50356000" },
      { "name": "Frozen organic chandler pomelo", "code": "50356001" },
      { "name": "Frozen organic hirado buntan pomelo", "code": "50356002" },
      { "name": "Frozen organic liang ping yau pomelo", "code": "50356003" },
      { "name": "Frozen organic pandan wangi pomelo", "code": "50356004" },
      { "name": "Frozen organic pink pomelo", "code": "50356005" },
      { "name": "Frozen organic red shaddock pomelo", "code": "50356006" },
      { "name": "Frozen organic siamese sweet pomelo", "code": "50356007" },
      { "name": "Frozen organic wainwright pomelo", "code": "50356008" },
      { "name": "Frozen organic quinces", "code": "50356100" },
      { "name": "Frozen organic champion quince", "code": "50356101" },
      { "name": "Frozen organic pineapple quince", "code": "50356102" },
      { "name": "Frozen organic smyrna quince", "code": "50356103" },
      { "name": "Frozen organic raspberries", "code": "50356200" },
      { "name": "Frozen organic american red raspberry", "code": "50356201" },
      {
        "name": "Frozen organic bailey queensland raspberry",
        "code": "50356202"
      },
      { "name": "Frozen organic black raspberry", "code": "50356203" },
      { "name": "Frozen organic dark raspberry", "code": "50356204" },
      { "name": "Frozen organic delicious raspberry", "code": "50356205" },
      { "name": "Frozen organic focke dwarf raspberry", "code": "50356206" },
      {
        "name": "Frozen organic focke grayleaf red raspberry",
        "code": "50356207"
      },
      {
        "name": "Frozen organic focke strawberry raspberry",
        "code": "50356208"
      },
      {
        "name": "Frozen organic focke yellow himalayan raspberry",
        "code": "50356209"
      },
      { "name": "Frozen organic gold raspberry", "code": "50356210" },
      {
        "name": "Frozen organic gray new mexico raspberry",
        "code": "50356211"
      },
      {
        "name": "Frozen organic jepson whitebark raspberry",
        "code": "50356212"
      },
      {
        "name": "Frozen organic kellogg san diego raspberry",
        "code": "50356213"
      },
      {
        "name": "Frozen organic leucodermis whitebark raspberry",
        "code": "50356214"
      },
      { "name": "Frozen organic munz cuyamaca raspberry", "code": "50356215" },
      { "name": "Frozen organic peck barton's raspberry", "code": "50356216" },
      {
        "name": "Frozen organic purpleflowering raspberry",
        "code": "50356217"
      },
      { "name": "Frozen organic roadside raspberry", "code": "50356218" },
      { "name": "Frozen organic san diego raspberry", "code": "50356219" },
      { "name": "Frozen organic snow raspberry", "code": "50356220" },
      { "name": "Frozen organic snowpeaks raspberry", "code": "50356221" },
      { "name": "Frozen organic strawberryleaf raspberry", "code": "50356222" },
      {
        "name": "Frozen organic sweet cultivated raspberry",
        "code": "50356223"
      },
      {
        "name": "Frozen organic torr and gray whitebark raspberry",
        "code": "50356224"
      },
      { "name": "Frozen organic west indian raspberry", "code": "50356225" },
      { "name": "Frozen organic whitebark raspberry", "code": "50356226" },
      { "name": "Frozen organic wine raspberry", "code": "50356227" },
      {
        "name": "Frozen organic yellow himalayan raspberry",
        "code": "50356228"
      },
      { "name": "Frozen organic yu-shan raspberry", "code": "50356229" },
      { "name": "Frozen organic rhubarb", "code": "50356300" },
      { "name": "Frozen organic crimson red rhubarb", "code": "50356301" },
      { "name": "Frozen organic early champagne rhubarb", "code": "50356302" },
      {
        "name": "Frozen organic glaskin's perpetual rhubarb",
        "code": "50356303"
      },
      { "name": "Frozen organic sutton rhubarb", "code": "50356304" },
      { "name": "Frozen organic timperley early rhubarb", "code": "50356305" },
      { "name": "Frozen organic valentine rhubarb", "code": "50356306" },
      { "name": "Frozen organic victoria rhubarb", "code": "50356307" },
      { "name": "Frozen organic zwolle seedling rhubarb", "code": "50356308" },
      { "name": "Frozen organic macdonald rhubarb", "code": "50356309" },
      { "name": "Frozen organic tilden rhubarb", "code": "50356310" },
      { "name": "Frozen organic rose hips", "code": "50356400" },
      { "name": "Frozen organic brier rose hips", "code": "50356401" },
      { "name": "Frozen organic elgantine rose hips", "code": "50356402" },
      { "name": "Frozen organic rugosa rose hips", "code": "50356403" },
      {
        "name": "Frozen organic scotch or burnet rose hips",
        "code": "50356404"
      },
      { "name": "Frozen organic sapotes", "code": "50356500" },
      { "name": "Frozen organic white sapotes", "code": "50356501" },
      { "name": "Frozen organic black sapotes", "code": "50356502" },
      { "name": "Frozen organic saskatoon berries", "code": "50356600" },
      {
        "name": "Frozen organic honeywood saskatoon berries",
        "code": "50356601"
      },
      {
        "name": "Frozen organic northline saskatoon berries",
        "code": "50356602"
      },
      { "name": "Frozen organic smoky saskatoon berries", "code": "50356603" },
      {
        "name": "Frozen organic thiessen saskatoon berries",
        "code": "50356604"
      },
      { "name": "Frozen organic strawberries", "code": "50356700" },
      { "name": "Frozen organic chandler strawberries", "code": "50356701" },
      {
        "name": "Frozen organic june bearing strawberries",
        "code": "50356702"
      },
      {
        "name": "Frozen organic ever bearing strawberries",
        "code": "50356703"
      },
      { "name": "Frozen organic sugar apple", "code": "50356800" },
      {
        "name": "Frozen organic kampong mauve sugar apple",
        "code": "50356801"
      },
      { "name": "Frozen organic seedless sugar apple", "code": "50356802" },
      { "name": "Frozen organic thai lessard sugar apple", "code": "50356803" },
      { "name": "Frozen organic tamarillo", "code": "50356900" },
      { "name": "Frozen organic amberlea gold tamarillo", "code": "50356901" },
      { "name": "Frozen organic bold gold tamarillo", "code": "50356902" },
      { "name": "Frozen organic goldmine tamarillo", "code": "50356903" },
      { "name": "Frozen organic oratia red tamarillo", "code": "50356904" },
      { "name": "Frozen organic red beau tamarillo", "code": "50356905" },
      { "name": "Frozen organic red delight tamarillo", "code": "50356906" },
      { "name": "Frozen organic nominant fruits", "code": "50357000" },
      { "name": "Frozen organic akee", "code": "50357001" },
      { "name": "Frozen organic babaco", "code": "50357002" },
      { "name": "Frozen organic banana flowers", "code": "50357003" },
      { "name": "Frozen organic baobab", "code": "50357004" },
      { "name": "Frozen organic bitter oranges", "code": "50357005" },
      { "name": "Frozen organic canistel", "code": "50357006" },
      { "name": "Frozen organic coconuts", "code": "50357007" },
      { "name": "Frozen organic cloudberries", "code": "50357008" },
      { "name": "Frozen organic dewberries", "code": "50357009" },
      { "name": "Frozen organic durian", "code": "50357010" },
      { "name": "Frozen organic elderberries", "code": "50357011" },
      { "name": "Frozen organic feijoa", "code": "50357012" },
      { "name": "Frozen organic hackberries", "code": "50357013" },
      { "name": "Frozen organic hawthorn", "code": "50357014" },
      { "name": "Frozen organic honeyberries", "code": "50357015" },
      { "name": "Frozen organic jackfruit", "code": "50357016" },
      { "name": "Frozen organic jambolan", "code": "50357017" },
      { "name": "Frozen organic jujube", "code": "50357018" },
      { "name": "Frozen organic lychee", "code": "50357019" },
      { "name": "Frozen organic mangosteens", "code": "50357020" },
      { "name": "Frozen organic medlars", "code": "50357021" },
      { "name": "Frozen organic mombins", "code": "50357022" },
      { "name": "Frozen organic monstera", "code": "50357023" },
      { "name": "Frozen organic pepinos", "code": "50357024" },
      { "name": "Frozen organic plantains", "code": "50357025" },
      { "name": "Frozen organic prickly pears", "code": "50357026" },
      { "name": "Frozen organic quenepas", "code": "50357027" },
      { "name": "Frozen organic rambutan", "code": "50357028" },
      { "name": "Frozen organic rose apples", "code": "50357029" },
      { "name": "Frozen organic roselle", "code": "50357030" },
      { "name": "Frozen organic rowanberries", "code": "50357031" },
      { "name": "Frozen organic sea buckhorn berries", "code": "50357032" },
      { "name": "Frozen organic silverberries", "code": "50357033" },
      { "name": "Frozen organic sorb berries", "code": "50357034" },
      { "name": "Frozen organic soursops", "code": "50357035" },
      { "name": "Frozen organic star apples", "code": "50357036" },
      { "name": "Frozen organic tamarindo", "code": "50357037" },
      { "name": "Frozen organic chokeberries", "code": "50357100" },
      {
        "name": "Frozen organic autumn magic chokeberries",
        "code": "50357101"
      },
      {
        "name": "Frozen organic brillantisima chokeberries",
        "code": "50357102"
      },
      { "name": "Frozen organic nero chokeberries", "code": "50357103" },
      { "name": "Frozen organic viking chokeberries", "code": "50357104" },
      { "name": "Frozen organic olives", "code": "50357200" },
      { "name": "Frozen organic agrinion olives", "code": "50357201" },
      { "name": "Frozen organic aleppo olives", "code": "50357202" },
      { "name": "Frozen organic alphonso olives", "code": "50357203" },
      { "name": "Frozen organic amphissa olives", "code": "50357204" },
      { "name": "Frozen organic arauco olives", "code": "50357205" },
      { "name": "Frozen organic arbequina olives", "code": "50357206" },
      { "name": "Frozen organic atalanta olives", "code": "50357207" },
      { "name": "Frozen organic cerignola olives", "code": "50357208" },
      { "name": "Frozen organic cracked provencal olives", "code": "50357209" },
      { "name": "Frozen organic empeltre olives", "code": "50357210" },
      { "name": "Frozen organic gaeta olives", "code": "50357211" },
      { "name": "Frozen organic hondroelia olives", "code": "50357212" },
      { "name": "Frozen organic kalamata olives", "code": "50357213" },
      { "name": "Frozen organic kura olives", "code": "50357214" },
      { "name": "Frozen organic ligurian olives", "code": "50357215" },
      { "name": "Frozen organic lucque olives", "code": "50357216" },
      { "name": "Frozen organic lugano olives", "code": "50357217" },
      { "name": "Frozen organic manzanilla olives", "code": "50357218" },
      { "name": "Frozen organic marche olives", "code": "50357219" },
      { "name": "Frozen organic mission olives", "code": "50357220" },
      { "name": "Frozen organic nafplion green olives", "code": "50357221" },
      { "name": "Frozen organic nicoise olives", "code": "50357222" },
      { "name": "Frozen organic nyons olives", "code": "50357223" },
      { "name": "Frozen organic picholine olives", "code": "50357224" },
      { "name": "Frozen organic ponentine olives", "code": "50357225" },
      { "name": "Frozen organic royal olives", "code": "50357226" },
      { "name": "Frozen organic seracena olives", "code": "50357227" },
      { "name": "Frozen organic sevillano olives", "code": "50357228" },
      { "name": "Frozen organic sicilian olives", "code": "50357229" },
      { "name": "Frozen organic toscanelle olives", "code": "50357230" },
      { "name": "Canned or jarred fruit", "code": "50360000" },
      { "name": "Canned or jarred apples", "code": "50361500" },
      { "name": "Canned or jarred akane apples", "code": "50361501" },
      { "name": "Canned or jarred ambrosia apples", "code": "50361502" },
      { "name": "Canned or jarred api apples", "code": "50361503" },
      { "name": "Canned or jarred baldwin apples", "code": "50361504" },
      { "name": "Canned or jarred braeburn apples", "code": "50361505" },
      { "name": "Canned or jarred bramley apples", "code": "50361506" },
      {
        "name": "Canned or jarred bramley seedling apples",
        "code": "50361507"
      },
      {
        "name": "Canned or jarred calville blanche d'hiver apples",
        "code": "50361508"
      },
      { "name": "Canned or jarred cameo apples", "code": "50361509" },
      { "name": "Canned or jarred charles ross apples", "code": "50361510" },
      { "name": "Canned or jarred codlin apples", "code": "50361511" },
      { "name": "Canned or jarred cortland apples", "code": "50361512" },
      { "name": "Canned or jarred costard apples", "code": "50361513" },
      {
        "name": "Canned or jarred court pendu plat apples",
        "code": "50361514"
      },
      {
        "name": "Canned or jarred cox's orange pippin apples",
        "code": "50361515"
      },
      { "name": "Canned or jarred crab apples", "code": "50361516" },
      { "name": "Canned or jarred crispin apples", "code": "50361517" },
      { "name": "Canned or jarred delicious apples", "code": "50361518" },
      { "name": "Canned or jarred duchess apples", "code": "50361519" },
      { "name": "Canned or jarred earligold apples", "code": "50361520" },
      { "name": "Canned or jarred early mcintosh apples", "code": "50361521" },
      { "name": "Canned or jarred elstar apples", "code": "50361522" },
      { "name": "Canned or jarred empire apples", "code": "50361523" },
      { "name": "Canned or jarred flower of kent apples", "code": "50361524" },
      { "name": "Canned or jarred fuji apples", "code": "50361525" },
      { "name": "Canned or jarred gala apples", "code": "50361526" },
      {
        "name": "Canned or jarred gascoyne's scarlet apples",
        "code": "50361527"
      },
      { "name": "Canned or jarred gilliflower apples", "code": "50361528" },
      { "name": "Canned or jarred ginger gold apples", "code": "50361529" },
      { "name": "Canned or jarred gladstone apples", "code": "50361530" },
      { "name": "Canned or jarred gloster apples", "code": "50361531" },
      { "name": "Canned or jarred gold supreme apples", "code": "50361532" },
      {
        "name": "Canned or jarred golden delicious apples",
        "code": "50361533"
      },
      { "name": "Canned or jarred golden noble apples", "code": "50361534" },
      { "name": "Canned or jarred granny smith apples", "code": "50361535" },
      { "name": "Canned or jarred gravenstein apples", "code": "50361536" },
      { "name": "Canned or jarred greening apples", "code": "50361537" },
      { "name": "Canned or jarred greensleeves apples", "code": "50361538" },
      { "name": "Canned or jarred honeycrisp apples", "code": "50361539" },
      { "name": "Canned or jarred howgate wonder apples", "code": "50361540" },
      { "name": "Canned or jarred ida red apples", "code": "50361541" },
      { "name": "Canned or jarred james grieve apples", "code": "50361542" },
      { "name": "Canned or jarred jersey mac apples", "code": "50361543" },
      { "name": "Canned or jarred jester apples", "code": "50361544" },
      { "name": "Canned or jarred jonagold apples", "code": "50361545" },
      { "name": "Canned or jarred jonamac apples", "code": "50361546" },
      { "name": "Canned or jarred jonathan apples", "code": "50361547" },
      { "name": "Canned or jarred katy apples", "code": "50361548" },
      {
        "name": "Canned or jarred kidd's orange red apples",
        "code": "50361549"
      },
      { "name": "Canned or jarred lady apples", "code": "50361550" },
      { "name": "Canned or jarred law rome apples", "code": "50361551" },
      { "name": "Canned or jarred laxton apples", "code": "50361552" },
      { "name": "Canned or jarred lord derby apples", "code": "50361553" },
      { "name": "Canned or jarred macoun apples", "code": "50361554" },
      { "name": "Canned or jarred mcintosh apples", "code": "50361555" },
      { "name": "Canned or jarred mutsu apples", "code": "50361556" },
      { "name": "Canned or jarred newtown pippin apples", "code": "50361557" },
      { "name": "Canned or jarred northern spy apples", "code": "50361558" },
      {
        "name": "Canned or jarred orleans reinette apples",
        "code": "50361559"
      },
      { "name": "Canned or jarred ozark gold apples", "code": "50361560" },
      { "name": "Canned or jarred pacific rose apples", "code": "50361561" },
      { "name": "Canned or jarred paula red apples", "code": "50361562" },
      { "name": "Canned or jarred pearmain apples", "code": "50361563" },
      { "name": "Canned or jarred pink lady apples", "code": "50361564" },
      { "name": "Canned or jarred pippin apples", "code": "50361565" },
      {
        "name": "Canned or jarred pitmaston pineapple apples",
        "code": "50361566"
      },
      { "name": "Canned or jarred pomme d'api apples", "code": "50361567" },
      { "name": "Canned or jarred prime gold apples", "code": "50361568" },
      { "name": "Canned or jarred red astrachan apples", "code": "50361569" },
      { "name": "Canned or jarred red boscoop apples", "code": "50361570" },
      { "name": "Canned or jarred red chief apples", "code": "50361571" },
      { "name": "Canned or jarred red delicious apples", "code": "50361572" },
      { "name": "Canned or jarred red gravenstein apples", "code": "50361573" },
      { "name": "Canned or jarred red rome apples", "code": "50361574" },
      { "name": "Canned or jarred red stayman apples", "code": "50361575" },
      { "name": "Canned or jarred red york apples", "code": "50361576" },
      { "name": "Canned or jarred reinette apples", "code": "50361577" },
      { "name": "Canned or jarred rome beauty apples", "code": "50361578" },
      { "name": "Canned or jarred russet apples", "code": "50361579" },
      { "name": "Canned or jarred sierra beauty apples", "code": "50361580" },
      { "name": "Canned or jarred spartan apples", "code": "50361581" },
      { "name": "Canned or jarred stark crimson apples", "code": "50361582" },
      { "name": "Canned or jarred starking apples", "code": "50361583" },
      { "name": "Canned or jarred stayman apples", "code": "50361584" },
      { "name": "Canned or jarred stayman winesap apples", "code": "50361585" },
      { "name": "Canned or jarred summer rambo apples", "code": "50361586" },
      { "name": "Canned or jarred tsugaru apples", "code": "50361587" },
      { "name": "Canned or jarred twenty ounce apples", "code": "50361588" },
      { "name": "Canned or jarred tydeman red apples", "code": "50361589" },
      { "name": "Canned or jarred vistabella apples", "code": "50361590" },
      { "name": "Canned or jarred wealthy apples", "code": "50361591" },
      { "name": "Canned or jarred white joaneting apples", "code": "50361592" },
      {
        "name": "Canned or jarred white transparent apples",
        "code": "50361593"
      },
      { "name": "Canned or jarred winesap apples", "code": "50361594" },
      { "name": "Canned or jarred worcester apples", "code": "50361595" },
      { "name": "Canned or jarred york imperial apples", "code": "50361596" },
      { "name": "Canned or jarred apricots", "code": "50361600" },
      { "name": "Canned or jarred ambercot apricots", "code": "50361601" },
      { "name": "Canned or jarred apache apricots", "code": "50361602" },
      { "name": "Canned or jarred brittany gold apricots", "code": "50361603" },
      { "name": "Canned or jarred black apricots", "code": "50361604" },
      { "name": "Canned or jarred blenheim apricots", "code": "50361605" },
      { "name": "Canned or jarred bonny apricots", "code": "50361606" },
      { "name": "Canned or jarred bulida apricots", "code": "50361607" },
      { "name": "Canned or jarred castlebrite apricots", "code": "50361608" },
      { "name": "Canned or jarred clutha gold apricots", "code": "50361609" },
      { "name": "Canned or jarred clutha sun apricots", "code": "50361610" },
      { "name": "Canned or jarred darby royal apricots", "code": "50361611" },
      { "name": "Canned or jarred dina apricots", "code": "50361612" },
      { "name": "Canned or jarred earlicot apricots", "code": "50361613" },
      { "name": "Canned or jarred earliman apricots", "code": "50361614" },
      { "name": "Canned or jarred early bright apricots", "code": "50361615" },
      { "name": "Canned or jarred flaming gold apricots", "code": "50361616" },
      { "name": "Canned or jarred fresno apricots", "code": "50361617" },
      { "name": "Canned or jarred gold brite apricots", "code": "50361618" },
      { "name": "Canned or jarred goldbar apricots", "code": "50361619" },
      { "name": "Canned or jarred golden sweet apricots", "code": "50361620" },
      { "name": "Canned or jarred goldrich apricots", "code": "50361621" },
      { "name": "Canned or jarred helena apricots", "code": "50361622" },
      { "name": "Canned or jarred honeycot apricots", "code": "50361623" },
      { "name": "Canned or jarred imperial apricots", "code": "50361624" },
      { "name": "Canned or jarred jordanne apricots", "code": "50361625" },
      { "name": "Canned or jarred jumbo cot apricots", "code": "50361626" },
      { "name": "Canned or jarred kandy kot apricots", "code": "50361627" },
      { "name": "Canned or jarred katy apricots", "code": "50361628" },
      { "name": "Canned or jarred king apricots", "code": "50361629" },
      { "name": "Canned or jarred lambertin apricots", "code": "50361630" },
      { "name": "Canned or jarred lorna apricots", "code": "50361631" },
      { "name": "Canned or jarred lulu belle apricots", "code": "50361632" },
      { "name": "Canned or jarred modesto apricots", "code": "50361633" },
      { "name": "Canned or jarred moorpark apricots", "code": "50361634" },
      { "name": "Canned or jarred orangered apricots", "code": "50361635" },
      { "name": "Canned or jarred palstein apricots", "code": "50361636" },
      { "name": "Canned or jarred patterson apricots", "code": "50361637" },
      { "name": "Canned or jarred perfection apricots", "code": "50361638" },
      { "name": "Canned or jarred poppy apricots", "code": "50361639" },
      { "name": "Canned or jarred poppycot apricots", "code": "50361640" },
      { "name": "Canned or jarred queen apricots", "code": "50361641" },
      { "name": "Canned or jarred riland apricots", "code": "50361642" },
      { "name": "Canned or jarred rival apricots", "code": "50361643" },
      { "name": "Canned or jarred robada apricots", "code": "50361644" },
      { "name": "Canned or jarred royal apricots", "code": "50361645" },
      {
        "name": "Canned or jarred royal blenheim apricots",
        "code": "50361646"
      },
      { "name": "Canned or jarred royal orange apricots", "code": "50361647" },
      { "name": "Canned or jarred sundrop apricots", "code": "50361648" },
      { "name": "Canned or jarred tilton apricots", "code": "50361649" },
      { "name": "Canned or jarred tomcot apricots", "code": "50361650" },
      { "name": "Canned or jarred tracy apricots", "code": "50361651" },
      { "name": "Canned or jarred tri gem apricots", "code": "50361652" },
      { "name": "Canned or jarred valley gold apricots", "code": "50361653" },
      { "name": "Canned or jarred westley apricots", "code": "50361654" },
      { "name": "Canned or jarred york apricots", "code": "50361655" },
      { "name": "Canned or jarred bananas", "code": "50361700" },
      { "name": "Canned or jarred apple bananas", "code": "50361701" },
      { "name": "Canned or jarred baby bananas", "code": "50361702" },
      { "name": "Canned or jarred burro bananas", "code": "50361703" },
      { "name": "Canned or jarred cavendish bananas", "code": "50361704" },
      { "name": "Canned or jarred dominico bananas", "code": "50361705" },
      { "name": "Canned or jarred green bananas", "code": "50361706" },
      { "name": "Canned or jarred gros michel bananas", "code": "50361707" },
      { "name": "Canned or jarred lacatan bananas", "code": "50361708" },
      { "name": "Canned or jarred lady finger banana", "code": "50361709" },
      { "name": "Canned or jarred manzano bananas", "code": "50361710" },
      { "name": "Canned or jarred mysore bananas", "code": "50361711" },
      { "name": "Canned or jarred pisang mas bananas", "code": "50361712" },
      { "name": "Canned or jarred red bananas", "code": "50361713" },
      { "name": "Canned or jarred saba bananas", "code": "50361714" },
      { "name": "Canned or jarred sucrier bananas", "code": "50361715" },
      { "name": "Canned or jarred barberries", "code": "50361800" },
      { "name": "Canned or jarred paleleaf barberries", "code": "50361801" },
      { "name": "Canned or jarred chenault barberries", "code": "50361802" },
      { "name": "Canned or jarred red barberries", "code": "50361803" },
      { "name": "Canned or jarred wintergreen barberries", "code": "50361804" },
      { "name": "Canned or jarred korean barberries", "code": "50361805" },
      { "name": "Canned or jarred mentor barberries", "code": "50361806" },
      { "name": "Canned or jarred japanese barberries", "code": "50361807" },
      {
        "name": "Canned or jarred atropurpurea barberries",
        "code": "50361808"
      },
      { "name": "Canned or jarred aurea barberries", "code": "50361809" },
      { "name": "Canned or jarred bagatelle barberries", "code": "50361810" },
      {
        "name": "Canned or jarred crimson pygmy barberries",
        "code": "50361811"
      },
      { "name": "Canned or jarred kobold barberries", "code": "50361812" },
      { "name": "Canned or jarred warty barberries", "code": "50361813" },
      { "name": "Canned or jarred european barberries", "code": "50361814" },
      { "name": "Canned or jarred bearberries", "code": "50361900" },
      { "name": "Canned or jarred alpine bearberries", "code": "50361901" },
      { "name": "Canned or jarred red bearberries", "code": "50361902" },
      { "name": "Canned or jarred common bearberries", "code": "50361903" },
      { "name": "Canned or jarred blackberries", "code": "50362000" },
      { "name": "Canned or jarred apache blackberries", "code": "50362001" },
      {
        "name": "Canned or jarred black satin blackberries",
        "code": "50362002"
      },
      { "name": "Canned or jarred boysenberries", "code": "50362003" },
      { "name": "Canned or jarred cherokee blackberries", "code": "50362004" },
      { "name": "Canned or jarred chester blackberries", "code": "50362005" },
      { "name": "Canned or jarred dirksen blackberries", "code": "50362006" },
      { "name": "Canned or jarred jostaberries", "code": "50362007" },
      { "name": "Canned or jarred loganberries", "code": "50362008" },
      { "name": "Canned or jarred marionberries", "code": "50362009" },
      { "name": "Canned or jarred navaho blackberries", "code": "50362010" },
      { "name": "Canned or jarred nectarberries", "code": "50362011" },
      { "name": "Canned or jarred olallie blackberries", "code": "50362012" },
      { "name": "Canned or jarred tayberries", "code": "50362013" },
      {
        "name": "Canned or jarred thornless hull blackberries",
        "code": "50362014"
      },
      { "name": "Canned or jarred youngberries", "code": "50362015" },
      { "name": "Canned or jarred bilberries", "code": "50362100" },
      { "name": "Canned or jarred bog bilberries", "code": "50362101" },
      { "name": "Canned or jarred dwarf bilberries", "code": "50362102" },
      { "name": "Canned or jarred mountain bilberries", "code": "50362103" },
      { "name": "Canned or jarred oval-leaved bilberries", "code": "50362104" },
      { "name": "Canned or jarred blueberries", "code": "50362200" },
      { "name": "Canned or jarred bluecrop blueberries", "code": "50362201" },
      { "name": "Canned or jarred bluetta blueberries", "code": "50362202" },
      { "name": "Canned or jarred brigitta blueberries", "code": "50362203" },
      { "name": "Canned or jarred chandler blueberries", "code": "50362204" },
      { "name": "Canned or jarred duke blueberries", "code": "50362205" },
      { "name": "Canned or jarred hardyblue blueberries", "code": "50362206" },
      { "name": "Canned or jarred legacy blueberries", "code": "50362207" },
      { "name": "Canned or jarred misty blueberries", "code": "50362208" },
      { "name": "Canned or jarred nelson blueberries", "code": "50362209" },
      { "name": "Canned or jarred northblue blueberries", "code": "50362210" },
      {
        "name": "Canned or jarred northcountry blueberries",
        "code": "50362211"
      },
      { "name": "Canned or jarred northsky blueberries", "code": "50362212" },
      { "name": "Canned or jarred patriot blueberries", "code": "50362213" },
      { "name": "Canned or jarred spartan blueberries", "code": "50362214" },
      { "name": "Canned or jarred toro blueberries", "code": "50362215" },
      { "name": "Canned or jarred breadfruit", "code": "50362300" },
      { "name": "Canned or jarred chataigne breadfruit", "code": "50362301" },
      { "name": "Canned or jarred seedless breadfruit", "code": "50362302" },
      { "name": "Canned or jarred white heart breadfruit", "code": "50362303" },
      {
        "name": "Canned or jarred yellow heart breadfruit",
        "code": "50362304"
      },
      { "name": "Canned or jarred cherimoyas", "code": "50362400" },
      { "name": "Canned or jarred bays cherimoya", "code": "50362401" },
      { "name": "Canned or jarred bronceada cherimoya", "code": "50362402" },
      { "name": "Canned or jarred burtons cherimoya", "code": "50362403" },
      {
        "name": "Canned or jarred burtons favorite cherimoya",
        "code": "50362404"
      },
      { "name": "Canned or jarred jete cherimoya", "code": "50362405" },
      { "name": "Canned or jarred reretai cherimoya", "code": "50362406" },
      { "name": "Canned or jarred smoothey cherimoya", "code": "50362407" },
      { "name": "Canned or jarred spain cherimoya", "code": "50362408" },
      { "name": "Canned or jarred white cherimoya", "code": "50362409" },
      { "name": "Canned or jarred cherries", "code": "50362500" },
      { "name": "Canned or jarred amarelle cherries", "code": "50362501" },
      { "name": "Canned or jarred brooks cherries", "code": "50362502" },
      { "name": "Canned or jarred bigarreu cherries", "code": "50362503" },
      { "name": "Canned or jarred bing cherries", "code": "50362504" },
      {
        "name": "Canned or jarred black republic cherries",
        "code": "50362505"
      },
      { "name": "Canned or jarred black schmidt cherries", "code": "50362506" },
      {
        "name": "Canned or jarred black tartarian cherries",
        "code": "50362507"
      },
      { "name": "Canned or jarred fiesta bing cherries", "code": "50362508" },
      { "name": "Canned or jarred garnet cherries", "code": "50362509" },
      { "name": "Canned or jarred king cherries", "code": "50362510" },
      { "name": "Canned or jarred chapman cherries", "code": "50362511" },
      { "name": "Canned or jarred lapin cherries", "code": "50362512" },
      { "name": "Canned or jarred larian cherries", "code": "50362513" },
      { "name": "Canned or jarred dark guines cherries", "code": "50362514" },
      { "name": "Canned or jarred montmorency cherries", "code": "50362515" },
      { "name": "Canned or jarred duke cherries", "code": "50362516" },
      { "name": "Canned or jarred early rivers cherries", "code": "50362517" },
      { "name": "Canned or jarred ruby bing cherries", "code": "50362518" },
      { "name": "Canned or jarred santina cherries", "code": "50362519" },
      { "name": "Canned or jarred geans/guines cherries", "code": "50362520" },
      { "name": "Canned or jarred sonata cherries", "code": "50362521" },
      { "name": "Canned or jarred lambert cherries", "code": "50362522" },
      { "name": "Canned or jarred stella cherries", "code": "50362523" },
      { "name": "Canned or jarred sweetheart cherries", "code": "50362524" },
      { "name": "Canned or jarred tartarian cherries", "code": "50362525" },
      { "name": "Canned or jarred maraschino cherries", "code": "50362527" },
      { "name": "Canned or jarred van cherries", "code": "50362528" },
      { "name": "Canned or jarred morello cherries", "code": "50362529" },
      { "name": "Canned or jarred royal ann cherries", "code": "50362530" },
      { "name": "Canned or jarred ranier cherries", "code": "50362531" },
      { "name": "Canned or jarred royal cherries", "code": "50362532" },
      { "name": "Canned or jarred citrons", "code": "50362600" },
      { "name": "Canned or jarred buddha's hand citrons", "code": "50362601" },
      { "name": "Canned or jarred fingered citrons", "code": "50362602" },
      { "name": "Canned or jarred fo shoukan citrons", "code": "50362603" },
      { "name": "Canned or jarred bushakan citrons", "code": "50362604" },
      { "name": "Canned or jarred diamante citrons", "code": "50362605" },
      { "name": "Canned or jarred etrog citrons", "code": "50362606" },
      { "name": "Canned or jarred ponderosa citrons", "code": "50362607" },
      { "name": "Canned or jarred cranberries", "code": "50362700" },
      { "name": "Canned or jarred ben lear cranberries", "code": "50362701" },
      {
        "name": "Canned or jarred early black cranberries",
        "code": "50362702"
      },
      { "name": "Canned or jarred grycleski cranberries", "code": "50362703" },
      { "name": "Canned or jarred howe cranberries", "code": "50362704" },
      { "name": "Canned or jarred lingonberries", "code": "50362705" },
      { "name": "Canned or jarred mcfarlin cranberries", "code": "50362706" },
      { "name": "Canned or jarred mountain cranberries", "code": "50362707" },
      { "name": "Canned or jarred pilgrim cranberries", "code": "50362708" },
      { "name": "Canned or jarred searless cranberries", "code": "50362709" },
      { "name": "Canned or jarred stevens cranberries", "code": "50362710" },
      { "name": "Canned or jarred currants", "code": "50362800" },
      { "name": "Canned or jarred hudson bay currants", "code": "50362801" },
      { "name": "Canned or jarred waxy currants", "code": "50362802" },
      { "name": "Canned or jarred desert currants", "code": "50362803" },
      { "name": "Canned or jarred black currants", "code": "50362804" },
      { "name": "Canned or jarred red currants", "code": "50362805" },
      { "name": "Canned or jarred white currants", "code": "50362806" },
      { "name": "Canned or jarred dates", "code": "50362900" },
      { "name": "Canned or jarred asharasi dates", "code": "50362901" },
      { "name": "Canned or jarred barhi or barhee dates", "code": "50362902" },
      { "name": "Canned or jarred deglet noor dates", "code": "50362903" },
      { "name": "Canned or jarred fardh dates", "code": "50362904" },
      { "name": "Canned or jarred gundila dates", "code": "50362905" },
      { "name": "Canned or jarred halawi/halawy dates", "code": "50362906" },
      { "name": "Canned or jarred hilali dates", "code": "50362907" },
      {
        "name": "Canned or jarred khadrawi/khadrawy dates",
        "code": "50362908"
      },
      { "name": "Canned or jarred khalas dates", "code": "50362909" },
      { "name": "Canned or jarred khustawi dates", "code": "50362910" },
      { "name": "Canned or jarred khidri dates", "code": "50362911" },
      { "name": "Canned or jarred medjool/medjul dates", "code": "50362912" },
      { "name": "Canned or jarred mactoum dates", "code": "50362913" },
      { "name": "Canned or jarred neghal dates", "code": "50362914" },
      { "name": "Canned or jarred yatimeh dates", "code": "50362915" },
      { "name": "Canned or jarred zahidi dates", "code": "50362916" },
      { "name": "Canned or jarred dragonfruit", "code": "50363000" },
      { "name": "Canned or jarred pink dragonfruit", "code": "50363001" },
      { "name": "Canned or jarred yellow dragonfruit", "code": "50363002" },
      { "name": "Canned or jarred figs", "code": "50363100" },
      { "name": "Canned or jarred bardajic figs", "code": "50363101" },
      { "name": "Canned or jarred brown turkey figs", "code": "50363102" },
      { "name": "Canned or jarred calimyrna figs", "code": "50363103" },
      { "name": "Canned or jarred conadria figs", "code": "50363104" },
      { "name": "Canned or jarred dottado figs", "code": "50363105" },
      { "name": "Canned or jarred kadota figs", "code": "50363106" },
      { "name": "Canned or jarred mediterranean figs", "code": "50363107" },
      { "name": "Canned or jarred mission figs", "code": "50363108" },
      { "name": "Canned or jarred smyrna figs", "code": "50363109" },
      { "name": "Canned or jarred verdona figs", "code": "50363110" },
      { "name": "Canned or jarred white king figs", "code": "50363111" },
      { "name": "Canned or jarred gooseberries", "code": "50363200" },
      {
        "name": "Canned or jarred early sulphur gooseberries",
        "code": "50363201"
      },
      {
        "name": "Canned or jarred goldendrop gooseberries",
        "code": "50363202"
      },
      {
        "name": "Canned or jarred langley gage gooseberries",
        "code": "50363203"
      },
      { "name": "Canned or jarred leveller gooseberries", "code": "50363204" },
      { "name": "Canned or jarred london gooseberries", "code": "50363205" },
      {
        "name": "Canned or jarred worcestershire gooseberries",
        "code": "50363206"
      },
      {
        "name": "Canned or jarred american worcesterberry gooseberries",
        "code": "50363207"
      },
      { "name": "Canned or jarred grapefruit", "code": "50363300" },
      { "name": "Canned or jarred burgundy grapefruit", "code": "50363301" },
      { "name": "Canned or jarred duncan grapefruit", "code": "50363302" },
      { "name": "Canned or jarred foster grapefruit", "code": "50363303" },
      { "name": "Canned or jarred marsh grapefruit", "code": "50363304" },
      { "name": "Canned or jarred new zealand grapefruit", "code": "50363305" },
      { "name": "Canned or jarred rio red grapefruit", "code": "50363306" },
      { "name": "Canned or jarred ruby red grapefruit", "code": "50363307" },
      { "name": "Canned or jarred star ruby grapefruit", "code": "50363308" },
      { "name": "Canned or jarred triumph grapefruit", "code": "50363309" },
      { "name": "Canned or jarred table grapes", "code": "50363400" },
      { "name": "Canned or jarred alicante grapes", "code": "50363401" },
      { "name": "Canned or jarred almeria grapes", "code": "50363402" },
      {
        "name": "Canned or jarred alphonse lavalle grapes",
        "code": "50363403"
      },
      { "name": "Canned or jarred autumn king grapes", "code": "50363404" },
      { "name": "Canned or jarred autumn royal grapes", "code": "50363405" },
      { "name": "Canned or jarred autumn seedless grapes", "code": "50363406" },
      { "name": "Canned or jarred baresana grapes", "code": "50363407" },
      { "name": "Canned or jarred barlinka grapes", "code": "50363408" },
      { "name": "Canned or jarred beauty seedless grapes", "code": "50363409" },
      {
        "name": "Canned or jarred black beauty seedless grapes",
        "code": "50363410"
      },
      { "name": "Canned or jarred black emerald grapes", "code": "50363411" },
      { "name": "Canned or jarred black giant grapes", "code": "50363412" },
      { "name": "Canned or jarred black globe grapes", "code": "50363413" },
      { "name": "Canned or jarred black monukka grapes", "code": "50363414" },
      { "name": "Canned or jarred black pearl grapes", "code": "50363415" },
      { "name": "Canned or jarred black seedless grapes", "code": "50363416" },
      { "name": "Canned or jarred bonheur grapes", "code": "50363417" },
      { "name": "Canned or jarred calmeria grapes", "code": "50363418" },
      { "name": "Canned or jarred cardinal grapes", "code": "50363419" },
      { "name": "Canned or jarred catawba grapes", "code": "50363420" },
      {
        "name": "Canned or jarred chasselas/golden chasselas grapes",
        "code": "50363421"
      },
      { "name": "Canned or jarred christmas rose grapes", "code": "50363422" },
      { "name": "Canned or jarred concord grapes", "code": "50363423" },
      {
        "name": "Canned or jarred concord seedless grapes",
        "code": "50363424"
      },
      {
        "name": "Canned or jarred crimson seedless grapes",
        "code": "50363425"
      },
      { "name": "Canned or jarred dauphine grapes", "code": "50363426" },
      { "name": "Canned or jarred delaware grapes", "code": "50363427" },
      { "name": "Canned or jarred early muscat grapes", "code": "50363428" },
      { "name": "Canned or jarred early sweet grapes", "code": "50363429" },
      {
        "name": "Canned or jarred emerald seedless grapes",
        "code": "50363430"
      },
      { "name": "Canned or jarred emperatriz grapes", "code": "50363431" },
      { "name": "Canned or jarred emperor grapes", "code": "50363432" },
      { "name": "Canned or jarred empress grapes", "code": "50363433" },
      { "name": "Canned or jarred exotic grapes", "code": "50363434" },
      { "name": "Canned or jarred fantasy grapes", "code": "50363435" },
      {
        "name": "Canned or jarred fantasy seedless grapes",
        "code": "50363436"
      },
      { "name": "Canned or jarred flame grapes", "code": "50363437" },
      { "name": "Canned or jarred flame seedless grapes", "code": "50363438" },
      { "name": "Canned or jarred flame tokay grapes", "code": "50363439" },
      { "name": "Canned or jarred flaming red grapes", "code": "50363440" },
      { "name": "Canned or jarred galaxy seedless grapes", "code": "50363441" },
      { "name": "Canned or jarred gamay grapes", "code": "50363442" },
      { "name": "Canned or jarred gold grapes", "code": "50363443" },
      {
        "name": "Canned or jarred hanepoot or honeypot grapes",
        "code": "50363444"
      },
      { "name": "Canned or jarred italia grapes", "code": "50363445" },
      { "name": "Canned or jarred jade seedless grapes", "code": "50363446" },
      { "name": "Canned or jarred jubilee grapes", "code": "50363447" },
      { "name": "Canned or jarred king ruby grapes", "code": "50363448" },
      { "name": "Canned or jarred kyoho grapes", "code": "50363449" },
      { "name": "Canned or jarred la rochelle grapes", "code": "50363450" },
      { "name": "Canned or jarred lady finger grapes", "code": "50363451" },
      { "name": "Canned or jarred late seedless grapes", "code": "50363452" },
      {
        "name": "Canned or jarred majestic seedless grapes",
        "code": "50363453"
      },
      { "name": "Canned or jarred malaga grapes", "code": "50363454" },
      { "name": "Canned or jarred marroo seedless grapes", "code": "50363455" },
      { "name": "Canned or jarred muscadine grapes", "code": "50363456" },
      { "name": "Canned or jarred muscat flame grapes", "code": "50363457" },
      { "name": "Canned or jarred muscat grapes", "code": "50363458" },
      { "name": "Canned or jarred muscat seedless grapes", "code": "50363459" },
      { "name": "Canned or jarred napoleon grapes", "code": "50363460" },
      { "name": "Canned or jarred negria grapes", "code": "50363461" },
      { "name": "Canned or jarred new cross grapes", "code": "50363462" },
      { "name": "Canned or jarred niabell grapes", "code": "50363463" },
      { "name": "Canned or jarred niagara grapes", "code": "50363464" },
      { "name": "Canned or jarred olivette grapes", "code": "50363465" },
      { "name": "Canned or jarred perlette grapes", "code": "50363466" },
      { "name": "Canned or jarred perlon grapes", "code": "50363467" },
      {
        "name": "Canned or jarred prima black seedless grapes",
        "code": "50363468"
      },
      { "name": "Canned or jarred princess grapes", "code": "50363469" },
      { "name": "Canned or jarred queen grapes", "code": "50363470" },
      { "name": "Canned or jarred red blush grapes", "code": "50363471" },
      { "name": "Canned or jarred red globe grapes", "code": "50363472" },
      { "name": "Canned or jarred red malaga grapes", "code": "50363473" },
      { "name": "Canned or jarred red seedless grapes", "code": "50363474" },
      { "name": "Canned or jarred regina grapes", "code": "50363475" },
      { "name": "Canned or jarred ribier grapes", "code": "50363476" },
      { "name": "Canned or jarred rosita grapes", "code": "50363477" },
      { "name": "Canned or jarred rouge grapes", "code": "50363478" },
      {
        "name": "Canned or jarred royal black seedless grapes",
        "code": "50363479"
      },
      {
        "name": "Canned or jarred ruby red seedless grapes",
        "code": "50363480"
      },
      { "name": "Canned or jarred ruby seedless grapes", "code": "50363481" },
      { "name": "Canned or jarred scarlet royal grapes", "code": "50363482" },
      { "name": "Canned or jarred scuppernong grapes", "code": "50363483" },
      { "name": "Canned or jarred sugarose grapes", "code": "50363484" },
      { "name": "Canned or jarred sugarthirteen grapes", "code": "50363485" },
      { "name": "Canned or jarred sugraone grapes", "code": "50363486" },
      { "name": "Canned or jarred sugrasixteen grapes", "code": "50363487" },
      { "name": "Canned or jarred sultana sun red grapes", "code": "50363488" },
      { "name": "Canned or jarred summer royal grapes", "code": "50363489" },
      { "name": "Canned or jarred sunset grapes", "code": "50363490" },
      {
        "name": "Canned or jarred superior seedless grapes",
        "code": "50363491"
      },
      {
        "name": "Canned or jarred thompson seedless grapes",
        "code": "50363492"
      },
      {
        "name": "Canned or jarred tokay/pinot gris grapes",
        "code": "50363493"
      },
      { "name": "Canned or jarred waltman cross grapes", "code": "50363494" },
      { "name": "Canned or jarred white seedless grapes", "code": "50363495" },
      { "name": "Canned or jarred zante current grapes", "code": "50363496" },
      { "name": "Canned or jarred raisin grapes", "code": "50363500" },
      { "name": "Canned or jarred black corinth grapes", "code": "50363501" },
      { "name": "Canned or jarred canner grapes", "code": "50363502" },
      { "name": "Canned or jarred dovine grapes", "code": "50363503" },
      { "name": "Canned or jarred fiesta grapes", "code": "50363504" },
      { "name": "Canned or jarred selma pete grapes", "code": "50363505" },
      { "name": "Canned or jarred sultana grapes", "code": "50363506" },
      { "name": "Canned or jarred wine grapes", "code": "50363600" },
      {
        "name": "Canned or jarred alicante bouschet grapes",
        "code": "50363601"
      },
      { "name": "Canned or jarred barbera grapes", "code": "50363602" },
      { "name": "Canned or jarred burger grapes", "code": "50363603" },
      { "name": "Canned or jarred cabernet franc grapes", "code": "50363604" },
      {
        "name": "Canned or jarred cabernet sauvignon grapes",
        "code": "50363605"
      },
      { "name": "Canned or jarred carignane grapes", "code": "50363606" },
      { "name": "Canned or jarred carnelian grapes", "code": "50363607" },
      { "name": "Canned or jarred catarratto grapes", "code": "50363608" },
      { "name": "Canned or jarred centurian grapes", "code": "50363609" },
      { "name": "Canned or jarred charbono grapes", "code": "50363610" },
      { "name": "Canned or jarred chardonnay grapes", "code": "50363611" },
      { "name": "Canned or jarred chenin blanc grapes", "code": "50363612" },
      { "name": "Canned or jarred cinsaut grapes", "code": "50363613" },
      { "name": "Canned or jarred dolcetto grapes", "code": "50363614" },
      {
        "name": "Canned or jarred emerald riesling grapes",
        "code": "50363615"
      },
      {
        "name": "Canned or jarred french colombard grapes",
        "code": "50363616"
      },
      { "name": "Canned or jarred gamay napa grapes", "code": "50363617" },
      {
        "name": "Canned or jarred gamay beaujolais grapes",
        "code": "50363618"
      },
      { "name": "Canned or jarred gewurztraminer grapes", "code": "50363619" },
      { "name": "Canned or jarred grenache grapes", "code": "50363620" },
      { "name": "Canned or jarred grenache blanc grapes", "code": "50363621" },
      { "name": "Canned or jarred lagrein grapes", "code": "50363622" },
      { "name": "Canned or jarred lambrusco grapes", "code": "50363623" },
      { "name": "Canned or jarred malbec grapes", "code": "50363624" },
      { "name": "Canned or jarred malvasia bianca grapes", "code": "50363625" },
      { "name": "Canned or jarred marsanne grapes", "code": "50363626" },
      { "name": "Canned or jarred mataro grapes", "code": "50363627" },
      { "name": "Canned or jarred merlot grapes", "code": "50363628" },
      { "name": "Canned or jarred meunier grapes", "code": "50363629" },
      { "name": "Canned or jarred mission grapes", "code": "50363630" },
      { "name": "Canned or jarred montepulciano grapes", "code": "50363631" },
      { "name": "Canned or jarred muscat blanc grapes", "code": "50363632" },
      { "name": "Canned or jarred muscat hamburg grapes", "code": "50363633" },
      {
        "name": "Canned or jarred muscat of alexandria grapes",
        "code": "50363634"
      },
      { "name": "Canned or jarred muscat orange grapes", "code": "50363635" },
      { "name": "Canned or jarred nebbiolo grapes", "code": "50363636" },
      { "name": "Canned or jarred palomino grapes", "code": "50363637" },
      { "name": "Canned or jarred petit verdot grapes", "code": "50363638" },
      { "name": "Canned or jarred petite sirah grapes", "code": "50363639" },
      { "name": "Canned or jarred pinot blanc grapes", "code": "50363640" },
      { "name": "Canned or jarred pinot gris grapes", "code": "50363641" },
      { "name": "Canned or jarred pinot noir grapes", "code": "50363642" },
      { "name": "Canned or jarred primitivo grapes", "code": "50363643" },
      { "name": "Canned or jarred roussanne grapes", "code": "50363644" },
      { "name": "Canned or jarred royalty grapes", "code": "50363645" },
      { "name": "Canned or jarred rubired grapes", "code": "50363646" },
      { "name": "Canned or jarred ruby cabernet grapes", "code": "50363647" },
      { "name": "Canned or jarred salvador grapes", "code": "50363648" },
      { "name": "Canned or jarred sangiovese grapes", "code": "50363649" },
      { "name": "Canned or jarred sauvignon blanc grapes", "code": "50363650" },
      {
        "name": "Canned or jarred sauvignon musque grapes",
        "code": "50363651"
      },
      { "name": "Canned or jarred semillon grapes", "code": "50363652" },
      { "name": "Canned or jarred souzao grapes", "code": "50363653" },
      { "name": "Canned or jarred st emilion grapes", "code": "50363654" },
      { "name": "Canned or jarred symphony grapes", "code": "50363655" },
      { "name": "Canned or jarred syrah grapes", "code": "50363656" },
      { "name": "Canned or jarred tannat grapes", "code": "50363657" },
      { "name": "Canned or jarred tempranillo grapes", "code": "50363658" },
      { "name": "Canned or jarred teroldego grapes", "code": "50363659" },
      { "name": "Canned or jarred tocai friulano grapes", "code": "50363660" },
      {
        "name": "Canned or jarred touriga nacional grapes",
        "code": "50363661"
      },
      { "name": "Canned or jarred triplett blanc grapes", "code": "50363662" },
      { "name": "Canned or jarred viognier grapes", "code": "50363663" },
      { "name": "Canned or jarred white riesling grapes", "code": "50363664" },
      { "name": "Canned or jarred zinfandel grapes", "code": "50363665" },
      { "name": "Canned or jarred guavas", "code": "50363700" },
      { "name": "Canned or jarred beaumont guavas", "code": "50363701" },
      { "name": "Canned or jarred carrley guavas", "code": "50363702" },
      { "name": "Canned or jarred lucida guavas", "code": "50363703" },
      { "name": "Canned or jarred pineapple guava", "code": "50363704" },
      { "name": "Canned or jarred huckleberries", "code": "50363800" },
      {
        "name": "Canned or jarred black winter huckleberries",
        "code": "50363801"
      },
      { "name": "Canned or jarred cascade huckleberries", "code": "50363802" },
      { "name": "Canned or jarred dwarf huckleberries", "code": "50363803" },
      { "name": "Canned or jarred mountain huckleberries", "code": "50363804" },
      { "name": "Canned or jarred red huckleberries", "code": "50363805" },
      { "name": "Canned or jarred kiwi fruit", "code": "50363900" },
      { "name": "Canned or jarred ananasnaja kiwi fruit", "code": "50363901" },
      {
        "name": "Canned or jarred arctic beauty kiwi fruit",
        "code": "50363902"
      },
      { "name": "Canned or jarred blake kiwi fruit", "code": "50363903" },
      { "name": "Canned or jarred hayward kiwi fruit", "code": "50363904" },
      { "name": "Canned or jarred issai kiwi fruit", "code": "50363905" },
      { "name": "Canned or jarred siberian kiwi fruit", "code": "50363906" },
      { "name": "Canned or jarred kumquats", "code": "50364000" },
      { "name": "Canned or jarred hong kong kumquats", "code": "50364001" },
      { "name": "Canned or jarred limequat kumquats", "code": "50364002" },
      { "name": "Canned or jarred long fruit kumquats", "code": "50364003" },
      { "name": "Canned or jarred malayan kumquats", "code": "50364004" },
      { "name": "Canned or jarred meiwa kumquats", "code": "50364005" },
      { "name": "Canned or jarred nagami kumquats", "code": "50364006" },
      { "name": "Canned or jarred lemons", "code": "50364100" },
      { "name": "Canned or jarred baboon lemons", "code": "50364101" },
      { "name": "Canned or jarred bearss sicilian lemons", "code": "50364102" },
      {
        "name": "Canned or jarred cameron highlands lemons",
        "code": "50364103"
      },
      { "name": "Canned or jarred escondido lemons", "code": "50364104" },
      { "name": "Canned or jarred eureka lemons", "code": "50364105" },
      { "name": "Canned or jarred lisbon lemons", "code": "50364106" },
      { "name": "Canned or jarred meyer lemons", "code": "50364107" },
      { "name": "Canned or jarred volkamer lemons", "code": "50364108" },
      { "name": "Canned or jarred limes", "code": "50364200" },
      { "name": "Canned or jarred indian sweet limes", "code": "50364201" },
      { "name": "Canned or jarred key limes", "code": "50364202" },
      { "name": "Canned or jarred mandarin limes", "code": "50364203" },
      { "name": "Canned or jarred philippine limes", "code": "50364204" },
      { "name": "Canned or jarred tahitian limes", "code": "50364205" },
      { "name": "Canned or jarred bearss limes", "code": "50364206" },
      { "name": "Canned or jarred persian limes", "code": "50364207" },
      { "name": "Canned or jarred seedless limes", "code": "50364208" },
      { "name": "Canned or jarred loquats", "code": "50364300" },
      { "name": "Canned or jarred advance loquats", "code": "50364301" },
      { "name": "Canned or jarred benlehr loquats", "code": "50364302" },
      { "name": "Canned or jarred big jim loquats", "code": "50364303" },
      { "name": "Canned or jarred champagne loquats", "code": "50364304" },
      { "name": "Canned or jarred early red loquats", "code": "50364305" },
      { "name": "Canned or jarred gold nugget loquats", "code": "50364306" },
      { "name": "Canned or jarred herd's mammoth loquats", "code": "50364307" },
      { "name": "Canned or jarred mogi loquats", "code": "50364308" },
      { "name": "Canned or jarred mrs cooksey loquats", "code": "50364309" },
      { "name": "Canned or jarred strawberry loquats", "code": "50364310" },
      { "name": "Canned or jarred tanaka loquats", "code": "50364311" },
      {
        "name": "Canned or jarred victory vista white loquats",
        "code": "50364312"
      },
      { "name": "Canned or jarred wolfe loquats", "code": "50364313" },
      {
        "name": "Canned or jarred mandarin oranges or tangerines",
        "code": "50364400"
      },
      { "name": "Canned or jarred clauselinas oranges", "code": "50364401" },
      { "name": "Canned or jarred clementine tangerines", "code": "50364402" },
      {
        "name": "Canned or jarred cleopatra mandarin oranges",
        "code": "50364403"
      },
      { "name": "Canned or jarred dancy tangerines", "code": "50364404" },
      { "name": "Canned or jarred ellensdale oranges", "code": "50364405" },
      { "name": "Canned or jarred fairchild oranges", "code": "50364406" },
      { "name": "Canned or jarred fallglo oranges", "code": "50364407" },
      { "name": "Canned or jarred fortune oranges", "code": "50364408" },
      {
        "name": "Canned or jarred fremont mandarin oranges",
        "code": "50364409"
      },
      { "name": "Canned or jarred fremont oranges", "code": "50364410" },
      { "name": "Canned or jarred golden nugget oranges", "code": "50364411" },
      { "name": "Canned or jarred honey mandarin oranges", "code": "50364412" },
      { "name": "Canned or jarred honey oranges", "code": "50364413" },
      { "name": "Canned or jarred honey tangerines", "code": "50364414" },
      {
        "name": "Canned or jarred honeybelle tangelo oranges",
        "code": "50364415"
      },
      { "name": "Canned or jarred king mandarin oranges", "code": "50364416" },
      { "name": "Canned or jarred kinnow oranges", "code": "50364417" },
      { "name": "Canned or jarred lee mandarin oranges", "code": "50364418" },
      { "name": "Canned or jarred makokkee oranges", "code": "50364419" },
      { "name": "Canned or jarred malvasios oranges", "code": "50364420" },
      {
        "name": "Canned or jarred mediterranean mandarin oranges",
        "code": "50364421"
      },
      {
        "name": "Canned or jarred minneola tangelo oranges",
        "code": "50364422"
      },
      { "name": "Canned or jarred monica oranges", "code": "50364423" },
      { "name": "Canned or jarred murcott honey oranges", "code": "50364424" },
      { "name": "Canned or jarred murcott tangors", "code": "50364425" },
      {
        "name": "Canned or jarred natsudaidai mandarin oranges",
        "code": "50364426"
      },
      {
        "name": "Canned or jarred natsumikan mandarin oranges",
        "code": "50364427"
      },
      {
        "name": "Canned or jarred nocatee tangelo oranges",
        "code": "50364428"
      },
      {
        "name": "Canned or jarred orlando tangelo oranges",
        "code": "50364429"
      },
      { "name": "Canned or jarred ortanique tangerines", "code": "50364430" },
      { "name": "Canned or jarred page mandarin oranges", "code": "50364431" },
      { "name": "Canned or jarred pixie oranges", "code": "50364432" },
      {
        "name": "Canned or jarred ponkan bantangas mandarin oranges",
        "code": "50364433"
      },
      { "name": "Canned or jarred reyna oranges", "code": "50364434" },
      { "name": "Canned or jarred robinson oranges", "code": "50364435" },
      { "name": "Canned or jarred saltenitas oranges", "code": "50364436" },
      {
        "name": "Canned or jarred sampson tangelo oranges",
        "code": "50364437"
      },
      {
        "name": "Canned or jarred satsuma mandarin oranges",
        "code": "50364438"
      },
      {
        "name": "Canned or jarred sunburst mandarin oranges",
        "code": "50364439"
      },
      { "name": "Canned or jarred tangelos", "code": "50364440" },
      { "name": "Canned or jarred tangerina oranges", "code": "50364441" },
      { "name": "Canned or jarred temple oranges", "code": "50364442" },
      { "name": "Canned or jarred thornton oranges", "code": "50364443" },
      { "name": "Canned or jarred wekiwa tangerines", "code": "50364444" },
      { "name": "Canned or jarred wilkins tangerines", "code": "50364445" },
      {
        "name": "Canned or jarred willowleaf mediterranean tangerines",
        "code": "50364446"
      },
      { "name": "Canned or jarred mangoes", "code": "50364500" },
      { "name": "Canned or jarred alphonso mangoes", "code": "50364501" },
      { "name": "Canned or jarred ataulfo mangoes", "code": "50364502" },
      { "name": "Canned or jarred criollo mangoes", "code": "50364503" },
      { "name": "Canned or jarred edwards mangoes", "code": "50364504" },
      { "name": "Canned or jarred francine mangoes", "code": "50364505" },
      { "name": "Canned or jarred francis mangoes", "code": "50364506" },
      { "name": "Canned or jarred gandaria mangoes", "code": "50364507" },
      { "name": "Canned or jarred haden mangoes", "code": "50364508" },
      { "name": "Canned or jarred irwin mangoes", "code": "50364509" },
      { "name": "Canned or jarred keitt mangoes", "code": "50364510" },
      { "name": "Canned or jarred kent mangoes", "code": "50364511" },
      { "name": "Canned or jarred kesar mangoes", "code": "50364512" },
      { "name": "Canned or jarred kuini mangoes", "code": "50364513" },
      { "name": "Canned or jarred manila super mangoes", "code": "50364514" },
      { "name": "Canned or jarred manila mangoes", "code": "50364515" },
      { "name": "Canned or jarred mayaguez mangoes", "code": "50364516" },
      { "name": "Canned or jarred mulgoba mangoes", "code": "50364517" },
      { "name": "Canned or jarred oro mangoes", "code": "50364518" },
      { "name": "Canned or jarred palmer mangoes", "code": "50364519" },
      { "name": "Canned or jarred parvin mangoes", "code": "50364520" },
      { "name": "Canned or jarred sandersha mangoes", "code": "50364521" },
      { "name": "Canned or jarred sensation mangoes", "code": "50364522" },
      { "name": "Canned or jarred smith mangoes", "code": "50364523" },
      { "name": "Canned or jarred tommy atkins mangoes", "code": "50364524" },
      { "name": "Canned or jarred van dyke mangoes", "code": "50364525" },
      { "name": "Canned or jarred melons", "code": "50364600" },
      { "name": "Canned or jarred allsweet melons", "code": "50364601" },
      { "name": "Canned or jarred athena melons", "code": "50364602" },
      { "name": "Canned or jarred black diamond melons", "code": "50364603" },
      { "name": "Canned or jarred cal sweet melons", "code": "50364604" },
      { "name": "Canned or jarred carnical melons", "code": "50364605" },
      { "name": "Canned or jarred cantaloupe melons", "code": "50364606" },
      { "name": "Canned or jarred casaba melons", "code": "50364607" },
      { "name": "Canned or jarred cavaillon melons", "code": "50364608" },
      { "name": "Canned or jarred charentais melons", "code": "50364609" },
      {
        "name": "Canned or jarred charleston gray watermelon",
        "code": "50364610"
      },
      { "name": "Canned or jarred crenshaw melons", "code": "50364611" },
      { "name": "Canned or jarred crimson sweet melons", "code": "50364612" },
      { "name": "Canned or jarred dixie lee melons", "code": "50364613" },
      { "name": "Canned or jarred eclipse melons", "code": "50364614" },
      { "name": "Canned or jarred ein d'or melons", "code": "50364615" },
      { "name": "Canned or jarred fiesta melons", "code": "50364616" },
      { "name": "Canned or jarred galia melons", "code": "50364617" },
      { "name": "Canned or jarred gaya melons", "code": "50364618" },
      { "name": "Canned or jarred hami melons", "code": "50364619" },
      { "name": "Canned or jarred honeydew melons", "code": "50364620" },
      { "name": "Canned or jarred icebox melons", "code": "50364621" },
      { "name": "Canned or jarred ida pride melons", "code": "50364622" },
      { "name": "Canned or jarred juan canary melons", "code": "50364623" },
      { "name": "Canned or jarred jubilee melons", "code": "50364624" },
      { "name": "Canned or jarred jubilation melons", "code": "50364625" },
      { "name": "Canned or jarred kakhi/kakri melons", "code": "50364626" },
      { "name": "Canned or jarred kiwano melons", "code": "50364627" },
      { "name": "Canned or jarred korean melons", "code": "50364628" },
      { "name": "Canned or jarred long gray melons", "code": "50364629" },
      { "name": "Canned or jarred mayan melons", "code": "50364630" },
      { "name": "Canned or jarred micky lee melons", "code": "50364631" },
      { "name": "Canned or jarred mirage melons", "code": "50364632" },
      {
        "name": "Canned or jarred moon and stars watermelon",
        "code": "50364633"
      },
      { "name": "Canned or jarred ogen melons", "code": "50364634" },
      { "name": "Canned or jarred patriot melons", "code": "50364635" },
      { "name": "Canned or jarred peacock melons", "code": "50364636" },
      { "name": "Canned or jarred pepino melons", "code": "50364637" },
      { "name": "Canned or jarred persian melons", "code": "50364638" },
      { "name": "Canned or jarred picnic melons", "code": "50364639" },
      { "name": "Canned or jarred piel de sapo melons", "code": "50364640" },
      { "name": "Canned or jarred pineapple melons", "code": "50364641" },
      { "name": "Canned or jarred quetzali melons", "code": "50364642" },
      { "name": "Canned or jarred red goblin melons", "code": "50364643" },
      { "name": "Canned or jarred regency melons", "code": "50364644" },
      { "name": "Canned or jarred royal majestic melons", "code": "50364645" },
      { "name": "Canned or jarred royal star melons", "code": "50364646" },
      { "name": "Canned or jarred royal sweet melons", "code": "50364647" },
      { "name": "Canned or jarred santa claus melons", "code": "50364648" },
      { "name": "Canned or jarred sharlyn melons", "code": "50364649" },
      { "name": "Canned or jarred spanish melons", "code": "50364650" },
      { "name": "Canned or jarred sprite melons", "code": "50364651" },
      { "name": "Canned or jarred starbright melons", "code": "50364652" },
      { "name": "Canned or jarred stars n stripes melons", "code": "50364653" },
      { "name": "Canned or jarred sugar baby melons", "code": "50364654" },
      { "name": "Canned or jarred sugar baby watermelon", "code": "50364655" },
      { "name": "Canned or jarred sunsweet melons", "code": "50364656" },
      {
        "name": "Canned or jarred sweet heart seedless watermelon",
        "code": "50364657"
      },
      { "name": "Canned or jarred temptation melons", "code": "50364658" },
      { "name": "Canned or jarred tiger baby melons", "code": "50364659" },
      { "name": "Canned or jarred tuscan type melons", "code": "50364660" },
      { "name": "Canned or jarred yellow baby watermelon", "code": "50364661" },
      { "name": "Canned or jarred mulberries", "code": "50364700" },
      { "name": "Canned or jarred black mulberries", "code": "50364701" },
      { "name": "Canned or jarred white mulberries", "code": "50364702" },
      { "name": "Canned or jarred bayberries or myrtles", "code": "50364800" },
      { "name": "Canned or jarred bog myrtle", "code": "50364801" },
      { "name": "Canned or jarred bayberries", "code": "50364802" },
      { "name": "Canned or jarred nectarines", "code": "50364900" },
      { "name": "Canned or jarred april glo nectarines", "code": "50364901" },
      { "name": "Canned or jarred arctic mist nectarines", "code": "50364902" },
      { "name": "Canned or jarred arctic snow nectarines", "code": "50364903" },
      { "name": "Canned or jarred arctic star nectarines", "code": "50364904" },
      {
        "name": "Canned or jarred arctic sweet nectarines",
        "code": "50364905"
      },
      { "name": "Canned or jarred arctic glo nectarines", "code": "50364906" },
      { "name": "Canned or jarred august fire nectarines", "code": "50364907" },
      {
        "name": "Canned or jarred august pearl nectarines",
        "code": "50364908"
      },
      { "name": "Canned or jarred august red nectarines", "code": "50364909" },
      { "name": "Canned or jarred autumn star nectarines", "code": "50364910" },
      { "name": "Canned or jarred big john nectarines", "code": "50364911" },
      {
        "name": "Canned or jarred bright pearl nectarines",
        "code": "50364912"
      },
      {
        "name": "Canned or jarred diamond bright nectarines",
        "code": "50364913"
      },
      { "name": "Canned or jarred diamond ray nectarines", "code": "50364914" },
      { "name": "Canned or jarred earliglo nectarines", "code": "50364915" },
      {
        "name": "Canned or jarred early diamond nectarines",
        "code": "50364916"
      },
      { "name": "Canned or jarred fairlane nectarines", "code": "50364917" },
      { "name": "Canned or jarred fantasia nectarines", "code": "50364918" },
      { "name": "Canned or jarred fire pearl nectarines", "code": "50364919" },
      { "name": "Canned or jarred fire sweet nectarines", "code": "50364920" },
      { "name": "Canned or jarred flamekist nectarines", "code": "50364921" },
      { "name": "Canned or jarred flat type nectarines", "code": "50364922" },
      {
        "name": "Canned or jarred garden delight nectarines",
        "code": "50364923"
      },
      { "name": "Canned or jarred goldmine nectarines", "code": "50364924" },
      { "name": "Canned or jarred grand pearl nectarines", "code": "50364925" },
      { "name": "Canned or jarred hardired nectarines", "code": "50364926" },
      { "name": "Canned or jarred honey blaze nectarines", "code": "50364927" },
      { "name": "Canned or jarred july red nectarines", "code": "50364928" },
      { "name": "Canned or jarred kay pearl nectarines", "code": "50364929" },
      { "name": "Canned or jarred kay sweet nectarines", "code": "50364930" },
      { "name": "Canned or jarred may diamond nectarines", "code": "50364931" },
      { "name": "Canned or jarred mayfire nectarines", "code": "50364932" },
      { "name": "Canned or jarred mayglo nectarines", "code": "50364933" },
      { "name": "Canned or jarred mericrest nectarines", "code": "50364934" },
      { "name": "Canned or jarred red diamond nectarines", "code": "50364935" },
      { "name": "Canned or jarred red gold nectarines", "code": "50364936" },
      { "name": "Canned or jarred red jim nectarines", "code": "50364937" },
      { "name": "Canned or jarred red roy nectarines", "code": "50364938" },
      { "name": "Canned or jarred rio red nectarines", "code": "50364939" },
      {
        "name": "Canned or jarred rose diamond nectarines",
        "code": "50364940"
      },
      { "name": "Canned or jarred royal glo nectarines", "code": "50364941" },
      {
        "name": "Canned or jarred ruby diamond nectarines",
        "code": "50364942"
      },
      { "name": "Canned or jarred ruby sweet nectarines", "code": "50364943" },
      { "name": "Canned or jarred ruddy jewel nectarines", "code": "50364944" },
      {
        "name": "Canned or jarred september red nectarines",
        "code": "50364945"
      },
      { "name": "Canned or jarred snowqueen nectarines", "code": "50364946" },
      {
        "name": "Canned or jarred spring bright nectarines",
        "code": "50364947"
      },
      { "name": "Canned or jarred spring red nectarines", "code": "50364948" },
      {
        "name": "Canned or jarred summer blush nectarines",
        "code": "50364949"
      },
      {
        "name": "Canned or jarred summer brite nectarines",
        "code": "50364950"
      },
      {
        "name": "Canned or jarred summer diamond nectarines",
        "code": "50364951"
      },
      { "name": "Canned or jarred summer fire nectarines", "code": "50364952" },
      {
        "name": "Canned or jarred summer grand nectarines",
        "code": "50364953"
      },
      { "name": "Canned or jarred sunglo nectarines", "code": "50364954" },
      { "name": "Canned or jarred zee fire nectarines", "code": "50364955" },
      { "name": "Canned or jarred zee glo nectarines", "code": "50364956" },
      { "name": "Canned or jarred zeegrand nectarines", "code": "50364957" },
      { "name": "Canned or jarred oranges", "code": "50365000" },
      { "name": "Canned or jarred african sour oranges", "code": "50365001" },
      { "name": "Canned or jarred ambersweet oranges", "code": "50365002" },
      { "name": "Canned or jarred argentine sour oranges", "code": "50365003" },
      { "name": "Canned or jarred bahianinha oranges", "code": "50365004" },
      { "name": "Canned or jarred bergamot oranges", "code": "50365005" },
      { "name": "Canned or jarred berna oranges", "code": "50365006" },
      {
        "name": "Canned or jarred bigaradier apepu oranges",
        "code": "50365007"
      },
      {
        "name": "Canned or jarred bittersweet daidai oranges",
        "code": "50365008"
      },
      { "name": "Canned or jarred blonde oranges", "code": "50365009" },
      { "name": "Canned or jarred blood oranges", "code": "50365010" },
      {
        "name": "Canned or jarred california navel oranges",
        "code": "50365011"
      },
      { "name": "Canned or jarred cara cara oranges", "code": "50365012" },
      { "name": "Canned or jarred chinotto oranges", "code": "50365013" },
      { "name": "Canned or jarred dream navel oranges", "code": "50365014" },
      { "name": "Canned or jarred gou tou oranges", "code": "50365015" },
      { "name": "Canned or jarred hamlin oranges", "code": "50365016" },
      { "name": "Canned or jarred jaffa oranges", "code": "50365017" },
      { "name": "Canned or jarred jincheng oranges", "code": "50365018" },
      { "name": "Canned or jarred k-early oranges", "code": "50365019" },
      { "name": "Canned or jarred kona oranges", "code": "50365020" },
      { "name": "Canned or jarred late navel oranges", "code": "50365021" },
      { "name": "Canned or jarred late valencia oranges", "code": "50365022" },
      { "name": "Canned or jarred limequat oranges", "code": "50365023" },
      { "name": "Canned or jarred marr oranges", "code": "50365024" },
      { "name": "Canned or jarred melogold oranges", "code": "50365025" },
      { "name": "Canned or jarred moro oranges", "code": "50365026" },
      { "name": "Canned or jarred moro tarocco oranges", "code": "50365027" },
      { "name": "Canned or jarred navel oranges", "code": "50365028" },
      { "name": "Canned or jarred navelina oranges", "code": "50365029" },
      { "name": "Canned or jarred oro blanco oranges", "code": "50365030" },
      { "name": "Canned or jarred osceola oranges", "code": "50365031" },
      { "name": "Canned or jarred parson brown oranges", "code": "50365032" },
      { "name": "Canned or jarred pera oranges", "code": "50365033" },
      { "name": "Canned or jarred pummulo oranges", "code": "50365034" },
      { "name": "Canned or jarred rhode red oranges", "code": "50365035" },
      { "name": "Canned or jarred roble oranges", "code": "50365036" },
      { "name": "Canned or jarred salustianas oranges", "code": "50365037" },
      { "name": "Canned or jarred sanguine oranges", "code": "50365038" },
      { "name": "Canned or jarred sanguinelli oranges", "code": "50365039" },
      { "name": "Canned or jarred seville oranges", "code": "50365040" },
      { "name": "Canned or jarred shamouti jaffa oranges", "code": "50365041" },
      { "name": "Canned or jarred tunis oranges", "code": "50365042" },
      { "name": "Canned or jarred valencia oranges", "code": "50365043" },
      {
        "name": "Canned or jarred washington navel oranges",
        "code": "50365044"
      },
      { "name": "Canned or jarred papayas", "code": "50365100" },
      { "name": "Canned or jarred green cooking papayas", "code": "50365101" },
      { "name": "Canned or jarred maradol papayas", "code": "50365102" },
      { "name": "Canned or jarred mexican yellow papayas", "code": "50365103" },
      { "name": "Canned or jarred mountain papayas", "code": "50365104" },
      { "name": "Canned or jarred solo papayas", "code": "50365105" },
      { "name": "Canned or jarred tainung papayas", "code": "50365106" },
      { "name": "Canned or jarred passion fruit", "code": "50365200" },
      { "name": "Canned or jarred banana passion fruit", "code": "50365201" },
      { "name": "Canned or jarred blue passion flower", "code": "50365202" },
      {
        "name": "Canned or jarred crackerjack passion fruit",
        "code": "50365203"
      },
      {
        "name": "Canned or jarred giant granadilla passion fruit",
        "code": "50365204"
      },
      {
        "name": "Canned or jarred golden granadilla passion fruit",
        "code": "50365205"
      },
      { "name": "Canned or jarred maypops passion fruit", "code": "50365206" },
      {
        "name": "Canned or jarred red granadilla passion fruit",
        "code": "50365207"
      },
      {
        "name": "Canned or jarred sweet granadilla passion fruit",
        "code": "50365208"
      },
      {
        "name": "Canned or jarred water lemon passion fruit",
        "code": "50365209"
      },
      {
        "name": "Canned or jarred wing-stemmed passion flower",
        "code": "50365210"
      },
      { "name": "Canned or jarred peaches", "code": "50365300" },
      { "name": "Canned or jarred amber crest peaches", "code": "50365301" },
      { "name": "Canned or jarred april snow peaches", "code": "50365302" },
      { "name": "Canned or jarred august lady peaches", "code": "50365303" },
      { "name": "Canned or jarred autumn flame peaches", "code": "50365304" },
      { "name": "Canned or jarred autumn lady peaches", "code": "50365305" },
      { "name": "Canned or jarred babcock peaches", "code": "50365306" },
      { "name": "Canned or jarred brittney lane peaches", "code": "50365307" },
      { "name": "Canned or jarred cary mac peaches", "code": "50365308" },
      { "name": "Canned or jarred classic peaches", "code": "50365309" },
      { "name": "Canned or jarred country sweet peaches", "code": "50365310" },
      { "name": "Canned or jarred crest haven peaches", "code": "50365311" },
      { "name": "Canned or jarred crimson lady peaches", "code": "50365312" },
      { "name": "Canned or jarred crown princess peaches", "code": "50365313" },
      { "name": "Canned or jarred david sun peaches", "code": "50365314" },
      {
        "name": "Canned or jarred diamond princess peaches",
        "code": "50365315"
      },
      { "name": "Canned or jarred earlirich peaches", "code": "50365316" },
      { "name": "Canned or jarred early majestic peaches", "code": "50365317" },
      { "name": "Canned or jarred early treat peaches", "code": "50365318" },
      { "name": "Canned or jarred elegant lady peaches", "code": "50365319" },
      { "name": "Canned or jarred empress peaches", "code": "50365320" },
      { "name": "Canned or jarred encore peaches", "code": "50365321" },
      { "name": "Canned or jarred fancy lady peaches", "code": "50365322" },
      { "name": "Canned or jarred fire prince peaches", "code": "50365323" },
      { "name": "Canned or jarred flame crest peaches", "code": "50365324" },
      { "name": "Canned or jarred flat type peaches", "code": "50365325" },
      { "name": "Canned or jarred flavorcrest peaches", "code": "50365326" },
      { "name": "Canned or jarred florida prince peaches", "code": "50365327" },
      { "name": "Canned or jarred full moon peaches", "code": "50365328" },
      { "name": "Canned or jarred harvester peaches", "code": "50365329" },
      { "name": "Canned or jarred ice princess peaches", "code": "50365330" },
      { "name": "Canned or jarred ivory princess peaches", "code": "50365331" },
      { "name": "Canned or jarred jersey queen peaches", "code": "50365332" },
      { "name": "Canned or jarred john henry peaches", "code": "50365333" },
      { "name": "Canned or jarred june prince peaches", "code": "50365334" },
      { "name": "Canned or jarred kaweah peaches", "code": "50365335" },
      { "name": "Canned or jarred klondike peaches", "code": "50365336" },
      { "name": "Canned or jarred lindo peaches", "code": "50365337" },
      { "name": "Canned or jarred loring peaches", "code": "50365338" },
      { "name": "Canned or jarred majestic peaches", "code": "50365339" },
      { "name": "Canned or jarred o'henry peaches", "code": "50365340" },
      { "name": "Canned or jarred queencrest peaches", "code": "50365341" },
      { "name": "Canned or jarred red lady peaches", "code": "50365342" },
      { "name": "Canned or jarred redglobe peaches", "code": "50365343" },
      { "name": "Canned or jarred redhaven peaches", "code": "50365344" },
      { "name": "Canned or jarred redtop peaches", "code": "50365345" },
      { "name": "Canned or jarred regina peaches", "code": "50365346" },
      { "name": "Canned or jarred rich lady peaches", "code": "50365347" },
      { "name": "Canned or jarred rich may peaches", "code": "50365348" },
      { "name": "Canned or jarred royal glory peaches", "code": "50365349" },
      { "name": "Canned or jarred royal lady peaches", "code": "50365350" },
      { "name": "Canned or jarred september snow peaches", "code": "50365351" },
      { "name": "Canned or jarred september sun peaches", "code": "50365352" },
      { "name": "Canned or jarred sierra gem peaches", "code": "50365353" },
      { "name": "Canned or jarred snow angel peaches", "code": "50365354" },
      { "name": "Canned or jarred snow gem peaches", "code": "50365355" },
      { "name": "Canned or jarred snow king peaches", "code": "50365356" },
      { "name": "Canned or jarred spring lady peaches", "code": "50365357" },
      { "name": "Canned or jarred spring snow peaches", "code": "50365358" },
      { "name": "Canned or jarred springcrest peaches", "code": "50365359" },
      { "name": "Canned or jarred sugar giant peaches", "code": "50365360" },
      { "name": "Canned or jarred sugar lady peaches", "code": "50365361" },
      { "name": "Canned or jarred sun bright peaches", "code": "50365362" },
      { "name": "Canned or jarred sunhigh peaches", "code": "50365363" },
      { "name": "Canned or jarred super lady peaches", "code": "50365364" },
      { "name": "Canned or jarred super rich peaches", "code": "50365365" },
      { "name": "Canned or jarred surecrop peaches", "code": "50365366" },
      { "name": "Canned or jarred sweet dream peaches", "code": "50365367" },
      {
        "name": "Canned or jarred sweet september peaches",
        "code": "50365368"
      },
      { "name": "Canned or jarred vista peaches", "code": "50365369" },
      { "name": "Canned or jarred white lady peaches", "code": "50365370" },
      { "name": "Canned or jarred zee lady peaches", "code": "50365371" },
      { "name": "Canned or jarred pears", "code": "50365400" },
      { "name": "Canned or jarred abate fetel pears", "code": "50365401" },
      { "name": "Canned or jarred anjou pears", "code": "50365402" },
      { "name": "Canned or jarred asian pears", "code": "50365403" },
      { "name": "Canned or jarred bartlett pears", "code": "50365404" },
      { "name": "Canned or jarred best ever pears", "code": "50365405" },
      { "name": "Canned or jarred beth pears", "code": "50365406" },
      { "name": "Canned or jarred beurre pears", "code": "50365407" },
      { "name": "Canned or jarred bosc pears", "code": "50365408" },
      { "name": "Canned or jarred clapp favorite pears", "code": "50365409" },
      { "name": "Canned or jarred comice pears", "code": "50365410" },
      { "name": "Canned or jarred concorde pears", "code": "50365411" },
      { "name": "Canned or jarred conference pears", "code": "50365412" },
      { "name": "Canned or jarred crimson red pears", "code": "50365413" },
      { "name": "Canned or jarred d'anjou pears", "code": "50365414" },
      { "name": "Canned or jarred dr jules guyot pears", "code": "50365415" },
      { "name": "Canned or jarred early pears", "code": "50365416" },
      { "name": "Canned or jarred emperor brown pears", "code": "50365417" },
      { "name": "Canned or jarred forelle pears", "code": "50365418" },
      { "name": "Canned or jarred french butter pears", "code": "50365419" },
      { "name": "Canned or jarred glou morceau pears", "code": "50365420" },
      { "name": "Canned or jarred hosui pears", "code": "50365421" },
      { "name": "Canned or jarred italian butter pears", "code": "50365422" },
      { "name": "Canned or jarred jargonelle pears", "code": "50365423" },
      { "name": "Canned or jarred juno pears", "code": "50365424" },
      {
        "name": "Canned or jarred kaiserlouise bonne de jersey pears",
        "code": "50365425"
      },
      { "name": "Canned or jarred keiffer pears", "code": "50365426" },
      { "name": "Canned or jarred kings royal pears", "code": "50365427" },
      { "name": "Canned or jarred limonera pears", "code": "50365428" },
      { "name": "Canned or jarred merton pride pears", "code": "50365429" },
      {
        "name": "Canned or jarred mountain bartlett pears",
        "code": "50365430"
      },
      {
        "name": "Canned or jarred olivier de serres pears",
        "code": "50365431"
      },
      { "name": "Canned or jarred onward pears", "code": "50365432" },
      {
        "name": "Canned or jarred packham's triumph pears",
        "code": "50365433"
      },
      { "name": "Canned or jarred paraiso pears", "code": "50365434" },
      { "name": "Canned or jarred passe crasanne pears", "code": "50365435" },
      { "name": "Canned or jarred perry pears", "code": "50365436" },
      { "name": "Canned or jarred red bartlett pears", "code": "50365437" },
      { "name": "Canned or jarred red d'anjou pears", "code": "50365438" },
      { "name": "Canned or jarred rocha pears", "code": "50365439" },
      { "name": "Canned or jarred rosey red pears", "code": "50365440" },
      { "name": "Canned or jarred rosy red pears", "code": "50365441" },
      { "name": "Canned or jarred royal majestic pears", "code": "50365442" },
      { "name": "Canned or jarred ruby red pears", "code": "50365443" },
      { "name": "Canned or jarred santa maria pears", "code": "50365444" },
      { "name": "Canned or jarred seckel pears", "code": "50365445" },
      { "name": "Canned or jarred sensation pears", "code": "50365446" },
      { "name": "Canned or jarred star crimson pears", "code": "50365447" },
      { "name": "Canned or jarred stark crimson pears", "code": "50365448" },
      { "name": "Canned or jarred summer bartlett pears", "code": "50365449" },
      { "name": "Canned or jarred summer gold pears", "code": "50365450" },
      { "name": "Canned or jarred sun gold pears", "code": "50365451" },
      { "name": "Canned or jarred sunsprite pears", "code": "50365452" },
      { "name": "Canned or jarred taylors gold pears", "code": "50365453" },
      { "name": "Canned or jarred taylors red pears", "code": "50365454" },
      { "name": "Canned or jarred tientsin pears", "code": "50365455" },
      { "name": "Canned or jarred tosca pears", "code": "50365456" },
      { "name": "Canned or jarred warden pears", "code": "50365457" },
      {
        "name": "Canned or jarred williams bon chretien pears",
        "code": "50365458"
      },
      { "name": "Canned or jarred williams pears", "code": "50365459" },
      { "name": "Canned or jarred winter nelis pears", "code": "50365460" },
      { "name": "Canned or jarred persimmons", "code": "50365500" },
      { "name": "Canned or jarred american persimmon", "code": "50365501" },
      { "name": "Canned or jarred black sapote persimmon", "code": "50365502" },
      {
        "name": "Canned or jarred chapote/black persimmon",
        "code": "50365503"
      },
      { "name": "Canned or jarred date plum persimmon", "code": "50365504" },
      { "name": "Canned or jarred fuyu persimmons", "code": "50365505" },
      { "name": "Canned or jarred giant fuyu persimmons", "code": "50365506" },
      { "name": "Canned or jarred hachiya persimmons", "code": "50365507" },
      {
        "name": "Canned or jarred mabolo/butter fruit persimmon",
        "code": "50365508"
      },
      {
        "name": "Canned or jarred principe ito persimmons",
        "code": "50365509"
      },
      {
        "name": "Canned or jarred royal brillante persimmons",
        "code": "50365510"
      },
      { "name": "Canned or jarred sharon fruit persimmon", "code": "50365511" },
      { "name": "Canned or jarred triumph persimmons", "code": "50365512" },
      { "name": "Canned or jarred pineapples", "code": "50365600" },
      { "name": "Canned or jarred cherimoya pineapple", "code": "50365601" },
      { "name": "Canned or jarred golden pineapple", "code": "50365602" },
      { "name": "Canned or jarred hilo pineapple", "code": "50365603" },
      {
        "name": "Canned or jarred kona sugarloaf pineapple",
        "code": "50365604"
      },
      { "name": "Canned or jarred natal queen pineapple", "code": "50365605" },
      { "name": "Canned or jarred pernambuco pineapple", "code": "50365606" },
      { "name": "Canned or jarred red spanish pineapple", "code": "50365607" },
      {
        "name": "Canned or jarred smooth cayenne pineapple",
        "code": "50365608"
      },
      { "name": "Canned or jarred sugarloaf pineapple", "code": "50365609" },
      { "name": "Canned or jarred variegated pineapple", "code": "50365610" },
      { "name": "Canned or jarred plucots", "code": "50365700" },
      { "name": "Canned or jarred black kat plucot", "code": "50365701" },
      { "name": "Canned or jarred blue gusto plucot", "code": "50365702" },
      { "name": "Canned or jarred crimson heart plucot", "code": "50365703" },
      { "name": "Canned or jarred dapple dandy plucot", "code": "50365704" },
      { "name": "Canned or jarred dapple fire plucot", "code": "50365705" },
      { "name": "Canned or jarred early dapple plucot", "code": "50365706" },
      { "name": "Canned or jarred flavor fall plucot", "code": "50365707" },
      { "name": "Canned or jarred flavor gold plucot", "code": "50365708" },
      { "name": "Canned or jarred flavor grenade plucot", "code": "50365709" },
      { "name": "Canned or jarred flavor heart plucot", "code": "50365710" },
      { "name": "Canned or jarred flavor jewel plucot", "code": "50365711" },
      { "name": "Canned or jarred flavor king plucot", "code": "50365712" },
      { "name": "Canned or jarred flavor queen plucot", "code": "50365713" },
      { "name": "Canned or jarred flavor supreme plucot", "code": "50365714" },
      { "name": "Canned or jarred flavor treat plucot", "code": "50365715" },
      { "name": "Canned or jarred flavorella plucot", "code": "50365716" },
      { "name": "Canned or jarred flavorich plucot", "code": "50365717" },
      { "name": "Canned or jarred flavorosa plucot", "code": "50365718" },
      { "name": "Canned or jarred geo pride plucot", "code": "50365719" },
      { "name": "Canned or jarred red kat plucot", "code": "50365720" },
      { "name": "Canned or jarred royal treat plucot", "code": "50365721" },
      { "name": "Canned or jarred sierra rose plucot", "code": "50365722" },
      { "name": "Canned or jarred sweet geisha plucot", "code": "50365723" },
      { "name": "Canned or jarred plums", "code": "50365800" },
      { "name": "Canned or jarred amber jewel plums", "code": "50365801" },
      { "name": "Canned or jarred angeleno plums", "code": "50365802" },
      { "name": "Canned or jarred aurora plums", "code": "50365803" },
      { "name": "Canned or jarred autumn beaut plums", "code": "50365804" },
      { "name": "Canned or jarred autumn giant plums", "code": "50365805" },
      { "name": "Canned or jarred autumn pride plums", "code": "50365806" },
      { "name": "Canned or jarred autumn rosa plums", "code": "50365807" },
      { "name": "Canned or jarred beach plum", "code": "50365808" },
      { "name": "Canned or jarred betty anne plums", "code": "50365809" },
      { "name": "Canned or jarred black beaut plums", "code": "50365810" },
      { "name": "Canned or jarred black bullace plum", "code": "50365811" },
      { "name": "Canned or jarred black diamond plums", "code": "50365812" },
      { "name": "Canned or jarred black giant plums", "code": "50365813" },
      { "name": "Canned or jarred black ice plums", "code": "50365814" },
      { "name": "Canned or jarred black splendor plums", "code": "50365815" },
      { "name": "Canned or jarred blackamber plums", "code": "50365816" },
      { "name": "Canned or jarred burgundy plums", "code": "50365817" },
      { "name": "Canned or jarred carlsbad plum", "code": "50365818" },
      { "name": "Canned or jarred casselman plums", "code": "50365819" },
      { "name": "Canned or jarred catalina plums", "code": "50365820" },
      { "name": "Canned or jarred damson plum", "code": "50365821" },
      { "name": "Canned or jarred dolly plums", "code": "50365822" },
      { "name": "Canned or jarred earliqueen plums", "code": "50365823" },
      { "name": "Canned or jarred early rosa plums", "code": "50365824" },
      { "name": "Canned or jarred ebony may plums", "code": "50365825" },
      { "name": "Canned or jarred ebony plums", "code": "50365826" },
      { "name": "Canned or jarred elephant heart plums", "code": "50365827" },
      { "name": "Canned or jarred emerald beaut plums", "code": "50365828" },
      { "name": "Canned or jarred empress plums", "code": "50365829" },
      { "name": "Canned or jarred freedom plums", "code": "50365830" },
      { "name": "Canned or jarred friar plums", "code": "50365831" },
      { "name": "Canned or jarred gar red plums", "code": "50365832" },
      { "name": "Canned or jarred governor's plum", "code": "50365833" },
      { "name": "Canned or jarred grand rosa plums", "code": "50365834" },
      { "name": "Canned or jarred green gage plum", "code": "50365835" },
      { "name": "Canned or jarred greengage plums", "code": "50365836" },
      { "name": "Canned or jarred hiromi plums", "code": "50365837" },
      { "name": "Canned or jarred hiromi red plums", "code": "50365838" },
      { "name": "Canned or jarred holiday plums", "code": "50365839" },
      { "name": "Canned or jarred howard sun plums", "code": "50365840" },
      {
        "name": "Canned or jarred interspecific type plums",
        "code": "50365841"
      },
      { "name": "Canned or jarred jamaican plum", "code": "50365842" },
      { "name": "Canned or jarred joanna red plums", "code": "50365843" },
      { "name": "Canned or jarred kelsey plums", "code": "50365844" },
      { "name": "Canned or jarred king james plums", "code": "50365845" },
      { "name": "Canned or jarred laroda plums", "code": "50365846" },
      { "name": "Canned or jarred late rosa plums", "code": "50365847" },
      { "name": "Canned or jarred linda rosa plums", "code": "50365848" },
      { "name": "Canned or jarred lone star red plums", "code": "50365849" },
      { "name": "Canned or jarred mariposa plums", "code": "50365850" },
      { "name": "Canned or jarred marked black plums", "code": "50365851" },
      { "name": "Canned or jarred marked red plums", "code": "50365852" },
      { "name": "Canned or jarred mirabelle plum", "code": "50365853" },
      { "name": "Canned or jarred october sun plums", "code": "50365854" },
      { "name": "Canned or jarred owen t plums", "code": "50365855" },
      { "name": "Canned or jarred perdrigon plum", "code": "50365856" },
      { "name": "Canned or jarred pink delight plums", "code": "50365857" },
      { "name": "Canned or jarred president plums", "code": "50365858" },
      { "name": "Canned or jarred primetime plums", "code": "50365859" },
      { "name": "Canned or jarred purple majesty plums", "code": "50365860" },
      { "name": "Canned or jarred queen rosa plums", "code": "50365861" },
      { "name": "Canned or jarred quetsch plum", "code": "50365862" },
      { "name": "Canned or jarred red beaut plums", "code": "50365863" },
      { "name": "Canned or jarred red lane plums", "code": "50365864" },
      { "name": "Canned or jarred red ram plums", "code": "50365865" },
      { "name": "Canned or jarred red rosa plums", "code": "50365866" },
      { "name": "Canned or jarred rich red plums", "code": "50365867" },
      { "name": "Canned or jarred rosemary plums", "code": "50365868" },
      { "name": "Canned or jarred royal diamond plums", "code": "50365869" },
      { "name": "Canned or jarred royal red plums", "code": "50365870" },
      { "name": "Canned or jarred royal zee plums", "code": "50365871" },
      { "name": "Canned or jarred roysum plums", "code": "50365872" },
      { "name": "Canned or jarred santa rosa plums", "code": "50365873" },
      { "name": "Canned or jarred saphire plums", "code": "50365874" },
      { "name": "Canned or jarred sloe plum", "code": "50365875" },
      { "name": "Canned or jarred st catherine plum", "code": "50365876" },
      { "name": "Canned or jarred white bullace plum", "code": "50365877" },
      { "name": "Canned or jarred pomegranates", "code": "50365900" },
      { "name": "Canned or jarred foothill pomegranates", "code": "50365901" },
      { "name": "Canned or jarred granada pomegranates", "code": "50365902" },
      { "name": "Canned or jarred jolly red pomegranates", "code": "50365903" },
      { "name": "Canned or jarred nana pomegranates", "code": "50365904" },
      { "name": "Canned or jarred pat's red pomegranates", "code": "50365905" },
      { "name": "Canned or jarred pinkhan pomegranates", "code": "50365906" },
      {
        "name": "Canned or jarred purple velvet pomegranates",
        "code": "50365907"
      },
      { "name": "Canned or jarred wonderful pomegranates", "code": "50365908" },
      { "name": "Canned or jarred pomelos", "code": "50366000" },
      { "name": "Canned or jarred chandler pomelo", "code": "50366001" },
      { "name": "Canned or jarred hirado buntan pomelo", "code": "50366002" },
      { "name": "Canned or jarred liang ping yau pomelo", "code": "50366003" },
      { "name": "Canned or jarred pandan wangi pomelo", "code": "50366004" },
      { "name": "Canned or jarred pink pomelo", "code": "50366005" },
      { "name": "Canned or jarred red shaddock pomelo", "code": "50366006" },
      { "name": "Canned or jarred siamese sweet pomelo", "code": "50366007" },
      { "name": "Canned or jarred wainwright pomelo", "code": "50366008" },
      { "name": "Canned or jarred quinces", "code": "50366100" },
      { "name": "Canned or jarred champion quince", "code": "50366101" },
      { "name": "Canned or jarred pineapple quince", "code": "50366102" },
      { "name": "Canned or jarred smyrna quince", "code": "50366103" },
      { "name": "Canned or jarred raspberries", "code": "50366200" },
      { "name": "Canned or jarred american red raspberry", "code": "50366201" },
      {
        "name": "Canned or jarred bailey queensland raspberry",
        "code": "50366202"
      },
      { "name": "Canned or jarred black raspberry", "code": "50366203" },
      { "name": "Canned or jarred dark raspberry", "code": "50366204" },
      { "name": "Canned or jarred delicious raspberry", "code": "50366205" },
      { "name": "Canned or jarred focke dwarf raspberry", "code": "50366206" },
      {
        "name": "Canned or jarred focke grayleaf red raspberry",
        "code": "50366207"
      },
      {
        "name": "Canned or jarred focke strawberry raspberry",
        "code": "50366208"
      },
      {
        "name": "Canned or jarred focke yellow himalayan raspberry",
        "code": "50366209"
      },
      { "name": "Canned or jarred gold raspberry", "code": "50366210" },
      {
        "name": "Canned or jarred gray new mexico raspberry",
        "code": "50366211"
      },
      {
        "name": "Canned or jarred jepson whitebark raspberry",
        "code": "50366212"
      },
      {
        "name": "Canned or jarred kellogg san diego raspberry",
        "code": "50366213"
      },
      {
        "name": "Canned or jarred leucodermis whitebark raspberry",
        "code": "50366214"
      },
      {
        "name": "Canned or jarred munz cuyamaca raspberry",
        "code": "50366215"
      },
      {
        "name": "Canned or jarred peck barton's raspberry",
        "code": "50366216"
      },
      {
        "name": "Canned or jarred purpleflowering raspberry",
        "code": "50366217"
      },
      { "name": "Canned or jarred roadside raspberry", "code": "50366218" },
      { "name": "Canned or jarred san diego raspberry", "code": "50366219" },
      { "name": "Canned or jarred snow raspberry", "code": "50366220" },
      { "name": "Canned or jarred snowpeaks raspberry", "code": "50366221" },
      {
        "name": "Canned or jarred strawberryleaf raspberry",
        "code": "50366222"
      },
      {
        "name": "Canned or jarred sweet cultivated raspberry",
        "code": "50366223"
      },
      {
        "name": "Canned or jarred torr and gray whitebark raspberry",
        "code": "50366224"
      },
      { "name": "Canned or jarred west indian raspberry", "code": "50366225" },
      { "name": "Canned or jarred whitebark raspberry", "code": "50366226" },
      { "name": "Canned or jarred wine raspberry", "code": "50366227" },
      {
        "name": "Canned or jarred yellow himalayan raspberry",
        "code": "50366228"
      },
      { "name": "Canned or jarred yu-shan raspberry", "code": "50366229" },
      { "name": "Canned or jarred rhubarb", "code": "50366300" },
      { "name": "Canned or jarred crimson red rhubarb", "code": "50366301" },
      {
        "name": "Canned or jarred early champagne rhubarb",
        "code": "50366302"
      },
      {
        "name": "Canned or jarred glaskin's perpetual rhubarb",
        "code": "50366303"
      },
      { "name": "Canned or jarred sutton rhubarb", "code": "50366304" },
      {
        "name": "Canned or jarred timperley early rhubarb",
        "code": "50366305"
      },
      { "name": "Canned or jarred valentine rhubarb", "code": "50366306" },
      { "name": "Canned or jarred victoria rhubarb", "code": "50366307" },
      {
        "name": "Canned or jarred zwolle seedling rhubarb",
        "code": "50366308"
      },
      { "name": "Canned or jarred macdonald rhubarb", "code": "50366309" },
      { "name": "Canned or jarred tilden rhubarb", "code": "50366310" },
      { "name": "Canned or jarred rose hips", "code": "50366400" },
      { "name": "Canned or jarred brier rose hips", "code": "50366401" },
      { "name": "Canned or jarred elgantine rose hips", "code": "50366402" },
      { "name": "Canned or jarred rugosa rose hips", "code": "50366403" },
      {
        "name": "Canned or jarred scotch or burnet rose hips",
        "code": "50366404"
      },
      { "name": "Canned or jarred sapotes", "code": "50366500" },
      { "name": "Canned or jarred white sapotes", "code": "50366501" },
      { "name": "Canned or jarred black sapotes", "code": "50366502" },
      { "name": "Canned or jarred saskatoon berries", "code": "50366600" },
      {
        "name": "Canned or jarred honeywood saskatoon berries",
        "code": "50366601"
      },
      {
        "name": "Canned or jarred northline saskatoon berries",
        "code": "50366602"
      },
      {
        "name": "Canned or jarred smoky saskatoon berries",
        "code": "50366603"
      },
      {
        "name": "Canned or jarred thiessen saskatoon berries",
        "code": "50366604"
      },
      { "name": "Canned or jarred strawberries", "code": "50366700" },
      { "name": "Canned or jarred chandler strawberries", "code": "50366701" },
      {
        "name": "Canned or jarred june bearing strawberries",
        "code": "50366702"
      },
      {
        "name": "Canned or jarred ever bearing strawberries",
        "code": "50366703"
      },
      { "name": "Canned or jarred sugar apple", "code": "50366800" },
      {
        "name": "Canned or jarred kampong mauve sugar apple",
        "code": "50366801"
      },
      { "name": "Canned or jarred seedless sugar apple", "code": "50366802" },
      {
        "name": "Canned or jarred thai lessard sugar apple",
        "code": "50366803"
      },
      { "name": "Canned or jarred tamarillo", "code": "50366900" },
      {
        "name": "Canned or jarred amberlea gold tamarillo",
        "code": "50366901"
      },
      { "name": "Canned or jarred bold gold tamarillo", "code": "50366902" },
      { "name": "Canned or jarred goldmine tamarillo", "code": "50366903" },
      { "name": "Canned or jarred oratia red tamarillo", "code": "50366904" },
      { "name": "Canned or jarred red beau tamarillo", "code": "50366905" },
      { "name": "Canned or jarred red delight tamarillo", "code": "50366906" },
      { "name": "Canned or jarred nominant fruits", "code": "50367000" },
      { "name": "Canned or jarred akee", "code": "50367001" },
      { "name": "Canned or jarred babaco", "code": "50367002" },
      { "name": "Canned or jarred banana flowers", "code": "50367003" },
      { "name": "Canned or jarred baobab", "code": "50367004" },
      { "name": "Canned or jarred bitter oranges", "code": "50367005" },
      { "name": "Canned or jarred canistel", "code": "50367006" },
      { "name": "Canned or jarred coconuts", "code": "50367007" },
      { "name": "Canned or jarred cloudberries", "code": "50367008" },
      { "name": "Canned or jarred dewberries", "code": "50367009" },
      { "name": "Canned or jarred durian", "code": "50367010" },
      { "name": "Canned or jarred elderberries", "code": "50367011" },
      { "name": "Canned or jarred feijoa", "code": "50367012" },
      { "name": "Canned or jarred hackberries", "code": "50367013" },
      { "name": "Canned or jarred hawthorn", "code": "50367014" },
      { "name": "Canned or jarred honeyberries", "code": "50367015" },
      { "name": "Canned or jarred jackfruit", "code": "50367016" },
      { "name": "Canned or jarred jambolan", "code": "50367017" },
      { "name": "Canned or jarred jujube", "code": "50367018" },
      { "name": "Canned or jarred lychee", "code": "50367019" },
      { "name": "Canned or jarred mangosteens", "code": "50367020" },
      { "name": "Canned or jarred medlars", "code": "50367021" },
      { "name": "Canned or jarred mombins", "code": "50367022" },
      { "name": "Canned or jarred monstera", "code": "50367023" },
      { "name": "Canned or jarred pepinos", "code": "50367024" },
      { "name": "Canned or jarred plantains", "code": "50367025" },
      { "name": "Canned or jarred prickly pears", "code": "50367026" },
      { "name": "Canned or jarred quenepas", "code": "50367027" },
      { "name": "Canned or jarred rambutan", "code": "50367028" },
      { "name": "Canned or jarred rose apples", "code": "50367029" },
      { "name": "Canned or jarred roselle", "code": "50367030" },
      { "name": "Canned or jarred rowanberries", "code": "50367031" },
      { "name": "Canned or jarred sea buckhorn berries", "code": "50367032" },
      { "name": "Canned or jarred silverberries", "code": "50367033" },
      { "name": "Canned or jarred sorb berries", "code": "50367034" },
      { "name": "Canned or jarred soursops", "code": "50367035" },
      { "name": "Canned or jarred star apples", "code": "50367036" },
      { "name": "Canned or jarred tamarindo", "code": "50367037" },
      { "name": "Canned or jarred chokeberries", "code": "50367100" },
      {
        "name": "Canned or jarred autumn magic chokeberries",
        "code": "50367101"
      },
      {
        "name": "Canned or jarred brillantisima chokeberries",
        "code": "50367102"
      },
      { "name": "Canned or jarred nero chokeberries", "code": "50367103" },
      { "name": "Canned or jarred viking chokeberries", "code": "50367104" },
      { "name": "Canned or jarred olives", "code": "50367200" },
      { "name": "Canned or jarred agrinion olives", "code": "50367201" },
      { "name": "Canned or jarred aleppo olives", "code": "50367202" },
      { "name": "Canned or jarred alphonso olives", "code": "50367203" },
      { "name": "Canned or jarred amphissa olives", "code": "50367204" },
      { "name": "Canned or jarred arauco olives", "code": "50367205" },
      { "name": "Canned or jarred arbequina olives", "code": "50367206" },
      { "name": "Canned or jarred atalanta olives", "code": "50367207" },
      { "name": "Canned or jarred cerignola olives", "code": "50367208" },
      {
        "name": "Canned or jarred cracked provencal olives",
        "code": "50367209"
      },
      { "name": "Canned or jarred empeltre olives", "code": "50367210" },
      { "name": "Canned or jarred gaeta olives", "code": "50367211" },
      { "name": "Canned or jarred hondroelia olives", "code": "50367212" },
      { "name": "Canned or jarred kalamata olives", "code": "50367213" },
      { "name": "Canned or jarred kura olives", "code": "50367214" },
      { "name": "Canned or jarred ligurian olives", "code": "50367215" },
      { "name": "Canned or jarred lucque olives", "code": "50367216" },
      { "name": "Canned or jarred lugano olives", "code": "50367217" },
      { "name": "Canned or jarred manzanilla olives", "code": "50367218" },
      { "name": "Canned or jarred marche olives", "code": "50367219" },
      { "name": "Canned or jarred mission olives", "code": "50367220" },
      { "name": "Canned or jarred nafplion green olives", "code": "50367221" },
      { "name": "Canned or jarred nicoise olives", "code": "50367222" },
      { "name": "Canned or jarred nyons olives", "code": "50367223" },
      { "name": "Canned or jarred picholine olives", "code": "50367224" },
      { "name": "Canned or jarred ponentine olives", "code": "50367225" },
      { "name": "Canned or jarred royal olives", "code": "50367226" },
      { "name": "Canned or jarred seracena olives", "code": "50367227" },
      { "name": "Canned or jarred sevillano olives", "code": "50367228" },
      { "name": "Canned or jarred sicilian olives", "code": "50367229" },
      { "name": "Canned or jarred toscanelle olives", "code": "50367230" },
      { "name": "Canned or jarred organic fruit", "code": "50370000" },
      { "name": "Canned or jarred organic apples", "code": "50371500" },
      { "name": "Canned or jarred organic akane apples", "code": "50371501" },
      {
        "name": "Canned or jarred organic ambrosia apples",
        "code": "50371502"
      },
      { "name": "Canned or jarred organic api apples", "code": "50371503" },
      { "name": "Canned or jarred organic baldwin apples", "code": "50371504" },
      {
        "name": "Canned or jarred organic braeburn apples",
        "code": "50371505"
      },
      { "name": "Canned or jarred organic bramley apples", "code": "50371506" },
      {
        "name": "Canned or jarred organic bramley seedling apples",
        "code": "50371507"
      },
      {
        "name": "Canned or jarred organic calville blanche d'hiver apples",
        "code": "50371508"
      },
      { "name": "Canned or jarred organic cameo apples", "code": "50371509" },
      {
        "name": "Canned or jarred organic charles ross apples",
        "code": "50371510"
      },
      { "name": "Canned or jarred organic codlin apples", "code": "50371511" },
      {
        "name": "Canned or jarred organic cortland apples",
        "code": "50371512"
      },
      { "name": "Canned or jarred organic costard apples", "code": "50371513" },
      {
        "name": "Canned or jarred organic court pendu plat apples",
        "code": "50371514"
      },
      {
        "name": "Canned or jarred organic cox's orange pippin apples",
        "code": "50371515"
      },
      { "name": "Canned or jarred organic crab apples", "code": "50371516" },
      { "name": "Canned or jarred organic crispin apples", "code": "50371517" },
      {
        "name": "Canned or jarred organic delicious apples",
        "code": "50371518"
      },
      { "name": "Canned or jarred organic duchess apples", "code": "50371519" },
      {
        "name": "Canned or jarred organic earligold apples",
        "code": "50371520"
      },
      {
        "name": "Canned or jarred organic early mcintosh apples",
        "code": "50371521"
      },
      { "name": "Canned or jarred organic elstar apples", "code": "50371522" },
      { "name": "Canned or jarred organic empire apples", "code": "50371523" },
      {
        "name": "Canned or jarred organic flower of kent apples",
        "code": "50371524"
      },
      { "name": "Canned or jarred organic fuji apples", "code": "50371525" },
      { "name": "Canned or jarred organic gala apples", "code": "50371526" },
      {
        "name": "Canned or jarred organic gascoyne's scarlet apples",
        "code": "50371527"
      },
      {
        "name": "Canned or jarred organic gilliflower apples",
        "code": "50371528"
      },
      {
        "name": "Canned or jarred organic ginger gold apples",
        "code": "50371529"
      },
      {
        "name": "Canned or jarred organic gladstone apples",
        "code": "50371530"
      },
      { "name": "Canned or jarred organic gloster apples", "code": "50371531" },
      {
        "name": "Canned or jarred organic gold supreme apples",
        "code": "50371532"
      },
      {
        "name": "Canned or jarred organic golden delicious apples",
        "code": "50371533"
      },
      {
        "name": "Canned or jarred organic golden noble apples",
        "code": "50371534"
      },
      {
        "name": "Canned or jarred organic granny smith apples",
        "code": "50371535"
      },
      {
        "name": "Canned or jarred organic gravenstein apples",
        "code": "50371536"
      },
      {
        "name": "Canned or jarred organic greening apples",
        "code": "50371537"
      },
      {
        "name": "Canned or jarred organic greensleeves apples",
        "code": "50371538"
      },
      {
        "name": "Canned or jarred organic honeycrisp apples",
        "code": "50371539"
      },
      {
        "name": "Canned or jarred organic howgate wonder apples",
        "code": "50371540"
      },
      { "name": "Canned or jarred organic ida red apples", "code": "50371541" },
      {
        "name": "Canned or jarred organic james grieve apples",
        "code": "50371542"
      },
      {
        "name": "Canned or jarred organic jersey mac apples",
        "code": "50371543"
      },
      { "name": "Canned or jarred organic jester apples", "code": "50371544" },
      {
        "name": "Canned or jarred organic jonagold apples",
        "code": "50371545"
      },
      { "name": "Canned or jarred organic jonamac apples", "code": "50371546" },
      {
        "name": "Canned or jarred organic jonathan apples",
        "code": "50371547"
      },
      { "name": "Canned or jarred organic katy apples", "code": "50371548" },
      {
        "name": "Canned or jarred organic kidd's orange red apples",
        "code": "50371549"
      },
      { "name": "Canned or jarred organic lady apples", "code": "50371550" },
      {
        "name": "Canned or jarred organic law rome apples",
        "code": "50371551"
      },
      { "name": "Canned or jarred organic laxton apples", "code": "50371552" },
      {
        "name": "Canned or jarred organic lord derby apples",
        "code": "50371553"
      },
      { "name": "Canned or jarred organic macoun apples", "code": "50371554" },
      {
        "name": "Canned or jarred organic mcintosh apples",
        "code": "50371555"
      },
      { "name": "Canned or jarred organic mutsu apples", "code": "50371556" },
      {
        "name": "Canned or jarred organic newtown pippin apples",
        "code": "50371557"
      },
      {
        "name": "Canned or jarred organic northern spy apples",
        "code": "50371558"
      },
      {
        "name": "Canned or jarred organic orleans reinette apples",
        "code": "50371559"
      },
      {
        "name": "Canned or jarred organic ozark gold apples",
        "code": "50371560"
      },
      {
        "name": "Canned or jarred organic pacific rose apples",
        "code": "50371561"
      },
      {
        "name": "Canned or jarred organic paula red apples",
        "code": "50371562"
      },
      {
        "name": "Canned or jarred organic pearmain apples",
        "code": "50371563"
      },
      {
        "name": "Canned or jarred organic pink lady apples",
        "code": "50371564"
      },
      { "name": "Canned or jarred organic pippin apples", "code": "50371565" },
      {
        "name": "Canned or jarred organic pitmaston pineapple apples",
        "code": "50371566"
      },
      {
        "name": "Canned or jarred organic pomme d'api apples",
        "code": "50371567"
      },
      {
        "name": "Canned or jarred organic prime gold apples",
        "code": "50371568"
      },
      {
        "name": "Canned or jarred organic red astrachan apples",
        "code": "50371569"
      },
      {
        "name": "Canned or jarred organic red boscoop apples",
        "code": "50371570"
      },
      {
        "name": "Canned or jarred organic red chief apples",
        "code": "50371571"
      },
      {
        "name": "Canned or jarred organic red delicious apples",
        "code": "50371572"
      },
      {
        "name": "Canned or jarred organic red gravenstein apples",
        "code": "50371573"
      },
      {
        "name": "Canned or jarred organic red rome apples",
        "code": "50371574"
      },
      {
        "name": "Canned or jarred organic red stayman apples",
        "code": "50371575"
      },
      {
        "name": "Canned or jarred organic red york apples",
        "code": "50371576"
      },
      {
        "name": "Canned or jarred organic reinette apples",
        "code": "50371577"
      },
      {
        "name": "Canned or jarred organic rome beauty apples",
        "code": "50371578"
      },
      { "name": "Canned or jarred organic russet apples", "code": "50371579" },
      {
        "name": "Canned or jarred organic sierra beauty apples",
        "code": "50371580"
      },
      { "name": "Canned or jarred organic spartan apples", "code": "50371581" },
      {
        "name": "Canned or jarred organic stark crimson apples",
        "code": "50371582"
      },
      {
        "name": "Canned or jarred organic starking apples",
        "code": "50371583"
      },
      { "name": "Canned or jarred organic stayman apples", "code": "50371584" },
      {
        "name": "Canned or jarred organic stayman winesap apples",
        "code": "50371585"
      },
      {
        "name": "Canned or jarred organic summer rambo apples",
        "code": "50371586"
      },
      { "name": "Canned or jarred organic tsugaru apples", "code": "50371587" },
      {
        "name": "Canned or jarred organic twenty ounce apples",
        "code": "50371588"
      },
      {
        "name": "Canned or jarred organic tydeman red apples",
        "code": "50371589"
      },
      {
        "name": "Canned or jarred organic vistabella apples",
        "code": "50371590"
      },
      { "name": "Canned or jarred organic wealthy apples", "code": "50371591" },
      {
        "name": "Canned or jarred organic white joaneting apples",
        "code": "50371592"
      },
      {
        "name": "Canned or jarred organic white transparent apples",
        "code": "50371593"
      },
      { "name": "Canned or jarred organic winesap apples", "code": "50371594" },
      {
        "name": "Canned or jarred organic worcester apples",
        "code": "50371595"
      },
      {
        "name": "Canned or jarred organic york imperial apples",
        "code": "50371596"
      },
      { "name": "Canned or jarred organic apricots", "code": "50371600" },
      {
        "name": "Canned or jarred organic ambercot apricots",
        "code": "50371601"
      },
      {
        "name": "Canned or jarred organic apache apricots",
        "code": "50371602"
      },
      {
        "name": "Canned or jarred organic brittany gold apricots",
        "code": "50371603"
      },
      { "name": "Canned or jarred organic black apricots", "code": "50371604" },
      {
        "name": "Canned or jarred organic blenheim apricots",
        "code": "50371605"
      },
      { "name": "Canned or jarred organic bonny apricots", "code": "50371606" },
      {
        "name": "Canned or jarred organic bulida apricots",
        "code": "50371607"
      },
      {
        "name": "Canned or jarred organic castlebrite apricots",
        "code": "50371608"
      },
      {
        "name": "Canned or jarred organic clutha gold apricots",
        "code": "50371609"
      },
      {
        "name": "Canned or jarred organic clutha sun apricots",
        "code": "50371610"
      },
      {
        "name": "Canned or jarred organic darby royal apricots",
        "code": "50371611"
      },
      { "name": "Canned or jarred organic dina apricots", "code": "50371612" },
      {
        "name": "Canned or jarred organic earlicot apricots",
        "code": "50371613"
      },
      {
        "name": "Canned or jarred organic earliman apricots",
        "code": "50371614"
      },
      {
        "name": "Canned or jarred organic early bright apricots",
        "code": "50371615"
      },
      {
        "name": "Canned or jarred organic flaming gold apricots",
        "code": "50371616"
      },
      {
        "name": "Canned or jarred organic fresno apricots",
        "code": "50371617"
      },
      {
        "name": "Canned or jarred organic gold brite apricots",
        "code": "50371618"
      },
      {
        "name": "Canned or jarred organic goldbar apricots",
        "code": "50371619"
      },
      {
        "name": "Canned or jarred organic golden sweet apricots",
        "code": "50371620"
      },
      {
        "name": "Canned or jarred organic goldrich apricots",
        "code": "50371621"
      },
      {
        "name": "Canned or jarred organic helena apricots",
        "code": "50371622"
      },
      {
        "name": "Canned or jarred organic honeycot apricots",
        "code": "50371623"
      },
      {
        "name": "Canned or jarred organic imperial apricots",
        "code": "50371624"
      },
      {
        "name": "Canned or jarred organic jordanne apricots",
        "code": "50371625"
      },
      {
        "name": "Canned or jarred organic jumbo cot apricots",
        "code": "50371626"
      },
      {
        "name": "Canned or jarred organic kandy kot apricots",
        "code": "50371627"
      },
      { "name": "Canned or jarred organic katy apricots", "code": "50371628" },
      { "name": "Canned or jarred organic king apricots", "code": "50371629" },
      {
        "name": "Canned or jarred organic lambertin apricots",
        "code": "50371630"
      },
      { "name": "Canned or jarred organic lorna apricots", "code": "50371631" },
      {
        "name": "Canned or jarred organic lulu belle apricots",
        "code": "50371632"
      },
      {
        "name": "Canned or jarred organic modesto apricots",
        "code": "50371633"
      },
      {
        "name": "Canned or jarred organic moorpark apricots",
        "code": "50371634"
      },
      {
        "name": "Canned or jarred organic orangered apricots",
        "code": "50371635"
      },
      {
        "name": "Canned or jarred organic palstein apricots",
        "code": "50371636"
      },
      {
        "name": "Canned or jarred organic patterson apricots",
        "code": "50371637"
      },
      {
        "name": "Canned or jarred organic perfection apricots",
        "code": "50371638"
      },
      { "name": "Canned or jarred organic poppy apricots", "code": "50371639" },
      {
        "name": "Canned or jarred organic poppycot apricots",
        "code": "50371640"
      },
      { "name": "Canned or jarred organic queen apricots", "code": "50371641" },
      {
        "name": "Canned or jarred organic riland apricots",
        "code": "50371642"
      },
      { "name": "Canned or jarred organic rival apricots", "code": "50371643" },
      {
        "name": "Canned or jarred organic robada apricots",
        "code": "50371644"
      },
      { "name": "Canned or jarred organic royal apricots", "code": "50371645" },
      {
        "name": "Canned or jarred organic royal blenheim apricots",
        "code": "50371646"
      },
      {
        "name": "Canned or jarred organic royal orange apricots",
        "code": "50371647"
      },
      {
        "name": "Canned or jarred organic sundrop apricots",
        "code": "50371648"
      },
      {
        "name": "Canned or jarred organic tilton apricots",
        "code": "50371649"
      },
      {
        "name": "Canned or jarred organic tomcot apricots",
        "code": "50371650"
      },
      { "name": "Canned or jarred organic tracy apricots", "code": "50371651" },
      {
        "name": "Canned or jarred organic tri gem apricots",
        "code": "50371652"
      },
      {
        "name": "Canned or jarred organic valley gold apricots",
        "code": "50371653"
      },
      {
        "name": "Canned or jarred organic westley apricots",
        "code": "50371654"
      },
      { "name": "Canned or jarred organic york apricots", "code": "50371655" },
      { "name": "Canned or jarred organic bananas", "code": "50371700" },
      { "name": "Canned or jarred organic apple bananas", "code": "50371701" },
      { "name": "Canned or jarred organic baby bananas", "code": "50371702" },
      { "name": "Canned or jarred organic burro bananas", "code": "50371703" },
      {
        "name": "Canned or jarred organic cavendish bananas",
        "code": "50371704"
      },
      {
        "name": "Canned or jarred organic dominico bananas",
        "code": "50371705"
      },
      { "name": "Canned or jarred organic green bananas", "code": "50371706" },
      {
        "name": "Canned or jarred organic gros michel bananas",
        "code": "50371707"
      },
      {
        "name": "Canned or jarred organic lacatan bananas",
        "code": "50371708"
      },
      {
        "name": "Canned or jarred organic lady finger banana",
        "code": "50371709"
      },
      {
        "name": "Canned or jarred organic manzano bananas",
        "code": "50371710"
      },
      { "name": "Canned or jarred organic mysore bananas", "code": "50371711" },
      {
        "name": "Canned or jarred organic pisang mas bananas",
        "code": "50371712"
      },
      { "name": "Canned or jarred organic red bananas", "code": "50371713" },
      { "name": "Canned or jarred organic saba bananas", "code": "50371714" },
      {
        "name": "Canned or jarred organic sucrier bananas",
        "code": "50371715"
      },
      { "name": "Canned or jarred organic barberries", "code": "50371800" },
      {
        "name": "Canned or jarred organic paleleaf barberries",
        "code": "50371801"
      },
      {
        "name": "Canned or jarred organic chenault barberries",
        "code": "50371802"
      },
      { "name": "Canned or jarred organic red barberries", "code": "50371803" },
      {
        "name": "Canned or jarred organic wintergreen barberries",
        "code": "50371804"
      },
      {
        "name": "Canned or jarred organic korean barberries",
        "code": "50371805"
      },
      {
        "name": "Canned or jarred organic mentor barberries",
        "code": "50371806"
      },
      {
        "name": "Canned or jarred organic japanese barberries",
        "code": "50371807"
      },
      {
        "name": "Canned or jarred organic atropurpurea barberries",
        "code": "50371808"
      },
      {
        "name": "Canned or jarred organic aurea barberries",
        "code": "50371809"
      },
      {
        "name": "Canned or jarred organic bagatelle barberries",
        "code": "50371810"
      },
      {
        "name": "Canned or jarred organic crimson pygmy barberries",
        "code": "50371811"
      },
      {
        "name": "Canned or jarred organic kobold barberries",
        "code": "50371812"
      },
      {
        "name": "Canned or jarred organic warty barberries",
        "code": "50371813"
      },
      {
        "name": "Canned or jarred organic european barberries",
        "code": "50371814"
      },
      { "name": "Canned or jarred organic bearberries", "code": "50371900" },
      {
        "name": "Canned or jarred organic alpine bearberries",
        "code": "50371901"
      },
      {
        "name": "Canned or jarred organic red bearberries",
        "code": "50371902"
      },
      {
        "name": "Canned or jarred organic common bearberries",
        "code": "50371903"
      },
      { "name": "Canned or jarred organic blackberries", "code": "50372000" },
      {
        "name": "Canned or jarred organic apache blackberries",
        "code": "50372001"
      },
      {
        "name": "Canned or jarred organic black satin blackberries",
        "code": "50372002"
      },
      { "name": "Canned or jarred organic boysenberries", "code": "50372003" },
      {
        "name": "Canned or jarred organic cherokee blackberries",
        "code": "50372004"
      },
      {
        "name": "Canned or jarred organic chester blackberries",
        "code": "50372005"
      },
      {
        "name": "Canned or jarred organic dirksen blackberries",
        "code": "50372006"
      },
      { "name": "Canned or jarred organic jostaberries", "code": "50372007" },
      { "name": "Canned or jarred organic loganberries", "code": "50372008" },
      { "name": "Canned or jarred organic marionberries", "code": "50372009" },
      {
        "name": "Canned or jarred organic navaho blackberries",
        "code": "50372010"
      },
      { "name": "Canned or jarred organic nectarberries", "code": "50372011" },
      {
        "name": "Canned or jarred organic olallie blackberries",
        "code": "50372012"
      },
      { "name": "Canned or jarred organic tayberries", "code": "50372013" },
      {
        "name": "Canned or jarred organic thornless hull blackberries",
        "code": "50372014"
      },
      { "name": "Canned or jarred organic youngberries", "code": "50372015" },
      { "name": "Canned or jarred organic bilberries", "code": "50372100" },
      { "name": "Canned or jarred organic bog bilberries", "code": "50372101" },
      {
        "name": "Canned or jarred organic dwarf bilberries",
        "code": "50372102"
      },
      {
        "name": "Canned or jarred organic mountain bilberries",
        "code": "50372103"
      },
      {
        "name": "Canned or jarred organic oval-leaved bilberries",
        "code": "50372104"
      },
      { "name": "Canned or jarred organic blueberries", "code": "50372200" },
      {
        "name": "Canned or jarred organic bluecrop blueberries",
        "code": "50372201"
      },
      {
        "name": "Canned or jarred organic bluetta blueberries",
        "code": "50372202"
      },
      {
        "name": "Canned or jarred organic brigitta blueberries",
        "code": "50372203"
      },
      {
        "name": "Canned or jarred organic chandler blueberries",
        "code": "50372204"
      },
      {
        "name": "Canned or jarred organic duke blueberries",
        "code": "50372205"
      },
      {
        "name": "Canned or jarred organic hardyblue blueberries",
        "code": "50372206"
      },
      {
        "name": "Canned or jarred organic legacy blueberries",
        "code": "50372207"
      },
      {
        "name": "Canned or jarred organic misty blueberries",
        "code": "50372208"
      },
      {
        "name": "Canned or jarred organic nelson blueberries",
        "code": "50372209"
      },
      {
        "name": "Canned or jarred organic northblue blueberries",
        "code": "50372210"
      },
      {
        "name": "Canned or jarred organic northcountry blueberries",
        "code": "50372211"
      },
      {
        "name": "Canned or jarred organic northsky blueberries",
        "code": "50372212"
      },
      {
        "name": "Canned or jarred organic patriot blueberries",
        "code": "50372213"
      },
      {
        "name": "Canned or jarred organic spartan blueberries",
        "code": "50372214"
      },
      {
        "name": "Canned or jarred organic toro blueberries",
        "code": "50372215"
      },
      { "name": "Canned or jarred organic breadfruit", "code": "50372300" },
      {
        "name": "Canned or jarred organic chataigne breadfruit",
        "code": "50372301"
      },
      {
        "name": "Canned or jarred organic seedless breadfruit",
        "code": "50372302"
      },
      {
        "name": "Canned or jarred organic white heart breadfruit",
        "code": "50372303"
      },
      {
        "name": "Canned or jarred organic yellow heart breadfruit",
        "code": "50372304"
      },
      { "name": "Canned or jarred organic cherimoyas", "code": "50372400" },
      { "name": "Canned or jarred organic bays cherimoya", "code": "50372401" },
      {
        "name": "Canned or jarred organic bronceada cherimoya",
        "code": "50372402"
      },
      {
        "name": "Canned or jarred organic burtons cherimoya",
        "code": "50372403"
      },
      {
        "name": "Canned or jarred organic burtons favorite cherimoya",
        "code": "50372404"
      },
      { "name": "Canned or jarred organic jete cherimoya", "code": "50372405" },
      {
        "name": "Canned or jarred organic reretai cherimoya",
        "code": "50372406"
      },
      {
        "name": "Canned or jarred organic smoothey cherimoya",
        "code": "50372407"
      },
      {
        "name": "Canned or jarred organic spain cherimoya",
        "code": "50372408"
      },
      {
        "name": "Canned or jarred organic white cherimoya",
        "code": "50372409"
      },
      { "name": "Canned or jarred organic cherries", "code": "50372500" },
      {
        "name": "Canned or jarred organic amarelle cherries",
        "code": "50372501"
      },
      {
        "name": "Canned or jarred organic brooks cherries",
        "code": "50372502"
      },
      {
        "name": "Canned or jarred organic bigarreu cherries",
        "code": "50372503"
      },
      { "name": "Canned or jarred organic bing cherries", "code": "50372504" },
      {
        "name": "Canned or jarred organic black republic cherries",
        "code": "50372505"
      },
      {
        "name": "Canned or jarred organic black schmidt cherries",
        "code": "50372506"
      },
      {
        "name": "Canned or jarred organic black tartarian cherries",
        "code": "50372507"
      },
      {
        "name": "Canned or jarred organic fiesta bing cherries",
        "code": "50372508"
      },
      {
        "name": "Canned or jarred organic garnet cherries",
        "code": "50372509"
      },
      { "name": "Canned or jarred organic king cherries", "code": "50372510" },
      {
        "name": "Canned or jarred organic chapman cherries",
        "code": "50372511"
      },
      { "name": "Canned or jarred organic lapin cherries", "code": "50372512" },
      {
        "name": "Canned or jarred organic larian cherries",
        "code": "50372513"
      },
      {
        "name": "Canned or jarred organic dark guines cherries",
        "code": "50372514"
      },
      {
        "name": "Canned or jarred organic montmorency cherries",
        "code": "50372515"
      },
      { "name": "Canned or jarred organic duke cherries", "code": "50372516" },
      {
        "name": "Canned or jarred organic early rivers cherries",
        "code": "50372517"
      },
      {
        "name": "Canned or jarred organic ruby bing cherries",
        "code": "50372518"
      },
      {
        "name": "Canned or jarred organic santina cherries",
        "code": "50372519"
      },
      {
        "name": "Canned or jarred organic geans/guines cherries",
        "code": "50372520"
      },
      {
        "name": "Canned or jarred organic sonata cherries",
        "code": "50372521"
      },
      {
        "name": "Canned or jarred organic lambert cherries",
        "code": "50372522"
      },
      {
        "name": "Canned or jarred organic stella cherries",
        "code": "50372523"
      },
      {
        "name": "Canned or jarred organic sweetheart cherries",
        "code": "50372524"
      },
      {
        "name": "Canned or jarred organic tartarian cherries",
        "code": "50372525"
      },
      {
        "name": "Canned or jarred organic maraschino cherries",
        "code": "50372527"
      },
      { "name": "Canned or jarred organic van cherries", "code": "50372528" },
      {
        "name": "Canned or jarred organic morello cherries",
        "code": "50372529"
      },
      {
        "name": "Canned or jarred organic royal ann cherries",
        "code": "50372530"
      },
      {
        "name": "Canned or jarred organic ranier cherries",
        "code": "50372531"
      },
      { "name": "Canned or jarred organic royal cherries", "code": "50372532" },
      { "name": "Canned or jarred organic citrons", "code": "50372600" },
      {
        "name": "Canned or jarred organic buddha's hand citrons",
        "code": "50372601"
      },
      {
        "name": "Canned or jarred organic fingered citrons",
        "code": "50372602"
      },
      {
        "name": "Canned or jarred organic fo shoukan citrons",
        "code": "50372603"
      },
      {
        "name": "Canned or jarred organic bushakan citrons",
        "code": "50372604"
      },
      {
        "name": "Canned or jarred organic diamante citrons",
        "code": "50372605"
      },
      { "name": "Canned or jarred organic etrog citrons", "code": "50372606" },
      {
        "name": "Canned or jarred organic ponderosa citrons",
        "code": "50372607"
      },
      { "name": "Canned or jarred organic cranberries", "code": "50372700" },
      {
        "name": "Canned or jarred organic ben lear cranberries",
        "code": "50372701"
      },
      {
        "name": "Canned or jarred organic early black cranberries",
        "code": "50372702"
      },
      {
        "name": "Canned or jarred organic grycleski cranberries",
        "code": "50372703"
      },
      {
        "name": "Canned or jarred organic howe cranberries",
        "code": "50372704"
      },
      { "name": "Canned or jarred organic lingonberries", "code": "50372705" },
      {
        "name": "Canned or jarred organic mcfarlin cranberries",
        "code": "50372706"
      },
      {
        "name": "Canned or jarred organic mountain cranberries",
        "code": "50372707"
      },
      {
        "name": "Canned or jarred organic pilgrim cranberries",
        "code": "50372708"
      },
      {
        "name": "Canned or jarred organic searless cranberries",
        "code": "50372709"
      },
      {
        "name": "Canned or jarred organic stevens cranberries",
        "code": "50372710"
      },
      { "name": "Canned or jarred organic currants", "code": "50372800" },
      {
        "name": "Canned or jarred organic hudson bay currants",
        "code": "50372801"
      },
      { "name": "Canned or jarred organic waxy currants", "code": "50372802" },
      {
        "name": "Canned or jarred organic desert currants",
        "code": "50372803"
      },
      { "name": "Canned or jarred organic black currants", "code": "50372804" },
      { "name": "Canned or jarred organic red currants", "code": "50372805" },
      { "name": "Canned or jarred organic white currants", "code": "50372806" },
      { "name": "Canned or jarred organic dates", "code": "50372900" },
      { "name": "Canned or jarred organic asharasi dates", "code": "50372901" },
      {
        "name": "Canned or jarred organic barhi or barhee dates",
        "code": "50372902"
      },
      {
        "name": "Canned or jarred organic deglet noor dates",
        "code": "50372903"
      },
      { "name": "Canned or jarred organic fardh dates", "code": "50372904" },
      { "name": "Canned or jarred organic gundila dates", "code": "50372905" },
      {
        "name": "Canned or jarred organic halawi/halawy dates",
        "code": "50372906"
      },
      { "name": "Canned or jarred organic hilali dates", "code": "50372907" },
      {
        "name": "Canned or jarred organic khadrawi/khadrawy dates",
        "code": "50372908"
      },
      { "name": "Canned or jarred organic khalas dates", "code": "50372909" },
      { "name": "Canned or jarred organic khustawi dates", "code": "50372910" },
      { "name": "Canned or jarred organic khidri dates", "code": "50372911" },
      {
        "name": "Canned or jarred organic medjool/medjul dates",
        "code": "50372912"
      },
      { "name": "Canned or jarred organic mactoum dates", "code": "50372913" },
      { "name": "Canned or jarred organic neghal dates", "code": "50372914" },
      { "name": "Canned or jarred organic yatimeh dates", "code": "50372915" },
      { "name": "Canned or jarred organic zahidi dates", "code": "50372916" },
      { "name": "Canned or jarred organic dragonfruit", "code": "50373000" },
      {
        "name": "Canned or jarred organic pink dragonfruit",
        "code": "50373001"
      },
      {
        "name": "Canned or jarred organic yellow dragonfruit",
        "code": "50373002"
      },
      { "name": "Canned or jarred organic figs", "code": "50373100" },
      { "name": "Canned or jarred organic bardajic figs", "code": "50373101" },
      {
        "name": "Canned or jarred organic brown turkey figs",
        "code": "50373102"
      },
      { "name": "Canned or jarred organic calimyrna figs", "code": "50373103" },
      { "name": "Canned or jarred organic conadria figs", "code": "50373104" },
      { "name": "Canned or jarred organic dottado figs", "code": "50373105" },
      { "name": "Canned or jarred organic kadota figs", "code": "50373106" },
      {
        "name": "Canned or jarred organic mediterranean figs",
        "code": "50373107"
      },
      { "name": "Canned or jarred organic mission figs", "code": "50373108" },
      { "name": "Canned or jarred organic smyrna figs", "code": "50373109" },
      { "name": "Canned or jarred organic verdona figs", "code": "50373110" },
      {
        "name": "Canned or jarred organic white king figs",
        "code": "50373111"
      },
      { "name": "Canned or jarred organic gooseberries", "code": "50373200" },
      {
        "name": "Canned or jarred organic early sulphur gooseberries",
        "code": "50373201"
      },
      {
        "name": "Canned or jarred organic goldendrop gooseberries",
        "code": "50373202"
      },
      {
        "name": "Canned or jarred organic langley gage gooseberries",
        "code": "50373203"
      },
      {
        "name": "Canned or jarred organic leveller gooseberries",
        "code": "50373204"
      },
      {
        "name": "Canned or jarred organic london gooseberries",
        "code": "50373205"
      },
      {
        "name": "Canned or jarred organic worcestershire gooseberries",
        "code": "50373206"
      },
      {
        "name": "Canned or jarred organic american worcesterberry gooseberries",
        "code": "50373207"
      },
      { "name": "Canned or jarred organic grapefruit", "code": "50373300" },
      {
        "name": "Canned or jarred organic burgundy grapefruit",
        "code": "50373301"
      },
      {
        "name": "Canned or jarred organic duncan grapefruit",
        "code": "50373302"
      },
      {
        "name": "Canned or jarred organic foster grapefruit",
        "code": "50373303"
      },
      {
        "name": "Canned or jarred organic marsh grapefruit",
        "code": "50373304"
      },
      {
        "name": "Canned or jarred organic new zealand grapefruit",
        "code": "50373305"
      },
      {
        "name": "Canned or jarred organic rio red grapefruit",
        "code": "50373306"
      },
      {
        "name": "Canned or jarred organic ruby red grapefruit",
        "code": "50373307"
      },
      {
        "name": "Canned or jarred organic star ruby grapefruit",
        "code": "50373308"
      },
      {
        "name": "Canned or jarred organic triumph grapefruit",
        "code": "50373309"
      },
      { "name": "Canned or jarred organic table grapes", "code": "50373400" },
      {
        "name": "Canned or jarred organic alicante grapes",
        "code": "50373401"
      },
      { "name": "Canned or jarred organic almeria grapes", "code": "50373402" },
      {
        "name": "Canned or jarred organic alphonse lavalle grapes",
        "code": "50373403"
      },
      {
        "name": "Canned or jarred organic autumn king grapes",
        "code": "50373404"
      },
      {
        "name": "Canned or jarred organic autumn royal grapes",
        "code": "50373405"
      },
      {
        "name": "Canned or jarred organic autumn seedless grapes",
        "code": "50373406"
      },
      {
        "name": "Canned or jarred organic baresana grapes",
        "code": "50373407"
      },
      {
        "name": "Canned or jarred organic barlinka grapes",
        "code": "50373408"
      },
      {
        "name": "Canned or jarred organic beauty seedless grapes",
        "code": "50373409"
      },
      {
        "name": "Canned or jarred organic black beauty seedless grapes",
        "code": "50373410"
      },
      {
        "name": "Canned or jarred organic black emerald grapes",
        "code": "50373411"
      },
      {
        "name": "Canned or jarred organic black giant grapes",
        "code": "50373412"
      },
      {
        "name": "Canned or jarred organic black globe grapes",
        "code": "50373413"
      },
      {
        "name": "Canned or jarred organic black monukka grapes",
        "code": "50373414"
      },
      {
        "name": "Canned or jarred organic black pearl grapes",
        "code": "50373415"
      },
      {
        "name": "Canned or jarred organic black seedless grapes",
        "code": "50373416"
      },
      { "name": "Canned or jarred organic bonheur grapes", "code": "50373417" },
      {
        "name": "Canned or jarred organic calmeria grapes",
        "code": "50373418"
      },
      {
        "name": "Canned or jarred organic cardinal grapes",
        "code": "50373419"
      },
      { "name": "Canned or jarred organic catawba grapes", "code": "50373420" },
      {
        "name": "Canned or jarred organic chasselas/golden chasselas grapes",
        "code": "50373421"
      },
      {
        "name": "Canned or jarred organic christmas rose grapes",
        "code": "50373422"
      },
      { "name": "Canned or jarred organic concord grapes", "code": "50373423" },
      {
        "name": "Canned or jarred organic concord seedless grapes",
        "code": "50373424"
      },
      {
        "name": "Canned or jarred organic crimson seedless grapes",
        "code": "50373425"
      },
      {
        "name": "Canned or jarred organic dauphine grapes",
        "code": "50373426"
      },
      {
        "name": "Canned or jarred organic delaware grapes",
        "code": "50373427"
      },
      {
        "name": "Canned or jarred organic early muscat grapes",
        "code": "50373428"
      },
      {
        "name": "Canned or jarred organic early sweet grapes",
        "code": "50373429"
      },
      {
        "name": "Canned or jarred organic emerald seedless grapes",
        "code": "50373430"
      },
      {
        "name": "Canned or jarred organic emperatriz grapes",
        "code": "50373431"
      },
      { "name": "Canned or jarred organic emperor grapes", "code": "50373432" },
      { "name": "Canned or jarred organic empress grapes", "code": "50373433" },
      { "name": "Canned or jarred organic exotic grapes", "code": "50373434" },
      { "name": "Canned or jarred organic fantasy grapes", "code": "50373435" },
      {
        "name": "Canned or jarred organic fantasy seedless grapes",
        "code": "50373436"
      },
      { "name": "Canned or jarred organic flame grapes", "code": "50373437" },
      {
        "name": "Canned or jarred organic flame seedless grapes",
        "code": "50373438"
      },
      {
        "name": "Canned or jarred organic flame tokay grapes",
        "code": "50373439"
      },
      {
        "name": "Canned or jarred organic flaming red grapes",
        "code": "50373440"
      },
      {
        "name": "Canned or jarred organic galaxy seedless grapes",
        "code": "50373441"
      },
      { "name": "Canned or jarred organic gamay grapes", "code": "50373442" },
      { "name": "Canned or jarred organic gold grapes", "code": "50373443" },
      {
        "name": "Canned or jarred organic hanepoot or honeypot grapes",
        "code": "50373444"
      },
      { "name": "Canned or jarred organic italia grapes", "code": "50373445" },
      {
        "name": "Canned or jarred organic jade seedless grapes",
        "code": "50373446"
      },
      { "name": "Canned or jarred organic jubilee grapes", "code": "50373447" },
      {
        "name": "Canned or jarred organic king ruby grapes",
        "code": "50373448"
      },
      { "name": "Canned or jarred organic kyoho grapes", "code": "50373449" },
      {
        "name": "Canned or jarred organic la rochelle grapes",
        "code": "50373450"
      },
      {
        "name": "Canned or jarred organic lady finger grapes",
        "code": "50373451"
      },
      {
        "name": "Canned or jarred organic late seedless grapes",
        "code": "50373452"
      },
      {
        "name": "Canned or jarred organic majestic seedless grapes",
        "code": "50373453"
      },
      { "name": "Canned or jarred organic malaga grapes", "code": "50373454" },
      {
        "name": "Canned or jarred organic marroo seedless grapes",
        "code": "50373455"
      },
      {
        "name": "Canned or jarred organic muscadine grapes",
        "code": "50373456"
      },
      {
        "name": "Canned or jarred organic muscat flame grapes",
        "code": "50373457"
      },
      { "name": "Canned or jarred organic muscat grapes", "code": "50373458" },
      {
        "name": "Canned or jarred organic muscat seedless grapes",
        "code": "50373459"
      },
      {
        "name": "Canned or jarred organic napoleon grapes",
        "code": "50373460"
      },
      { "name": "Canned or jarred organic negria grapes", "code": "50373461" },
      {
        "name": "Canned or jarred organic new cross grapes",
        "code": "50373462"
      },
      { "name": "Canned or jarred organic niabell grapes", "code": "50373463" },
      { "name": "Canned or jarred organic niagara grapes", "code": "50373464" },
      {
        "name": "Canned or jarred organic olivette grapes",
        "code": "50373465"
      },
      {
        "name": "Canned or jarred organic perlette grapes",
        "code": "50373466"
      },
      { "name": "Canned or jarred organic perlon grapes", "code": "50373467" },
      {
        "name": "Canned or jarred organic prima black seedless grapes",
        "code": "50373468"
      },
      {
        "name": "Canned or jarred organic princess grapes",
        "code": "50373469"
      },
      { "name": "Canned or jarred organic queen grapes", "code": "50373470" },
      {
        "name": "Canned or jarred organic red blush grapes",
        "code": "50373471"
      },
      {
        "name": "Canned or jarred organic red globe grapes",
        "code": "50373472"
      },
      {
        "name": "Canned or jarred organic red malaga grapes",
        "code": "50373473"
      },
      {
        "name": "Canned or jarred organic red seedless grapes",
        "code": "50373474"
      },
      { "name": "Canned or jarred organic regina grapes", "code": "50373475" },
      { "name": "Canned or jarred organic ribier grapes", "code": "50373476" },
      { "name": "Canned or jarred organic rosita grapes", "code": "50373477" },
      { "name": "Canned or jarred organic rouge grapes", "code": "50373478" },
      {
        "name": "Canned or jarred organic royal black seedless grapes",
        "code": "50373479"
      },
      {
        "name": "Canned or jarred organic ruby red seedless grapes",
        "code": "50373480"
      },
      {
        "name": "Canned or jarred organic ruby seedless grapes",
        "code": "50373481"
      },
      {
        "name": "Canned or jarred organic scarlet royal grapes",
        "code": "50373482"
      },
      {
        "name": "Canned or jarred organic scuppernong grapes",
        "code": "50373483"
      },
      {
        "name": "Canned or jarred organic sugarose grapes",
        "code": "50373484"
      },
      {
        "name": "Canned or jarred organic sugarthirteen grapes",
        "code": "50373485"
      },
      {
        "name": "Canned or jarred organic sugraone grapes",
        "code": "50373486"
      },
      {
        "name": "Canned or jarred organic sugrasixteen grapes",
        "code": "50373487"
      },
      {
        "name": "Canned or jarred organic sultana sun red grapes",
        "code": "50373488"
      },
      {
        "name": "Canned or jarred organic summer royal grapes",
        "code": "50373489"
      },
      { "name": "Canned or jarred organic sunset grapes", "code": "50373490" },
      {
        "name": "Canned or jarred organic superior seedless grapes",
        "code": "50373491"
      },
      {
        "name": "Canned or jarred organic thompson seedless grapes",
        "code": "50373492"
      },
      {
        "name": "Canned or jarred organic tokay/pinot gris grapes",
        "code": "50373493"
      },
      {
        "name": "Canned or jarred organic waltman cross grapes",
        "code": "50373494"
      },
      {
        "name": "Canned or jarred organic white seedless grapes",
        "code": "50373495"
      },
      {
        "name": "Canned or jarred organic zante current grapes",
        "code": "50373496"
      },
      { "name": "Canned or jarred organic raisin grapes", "code": "50373500" },
      {
        "name": "Canned or jarred organic black corinth grapes",
        "code": "50373501"
      },
      { "name": "Canned or jarred organic canner grapes", "code": "50373502" },
      { "name": "Canned or jarred organic dovine grapes", "code": "50373503" },
      { "name": "Canned or jarred organic fiesta grapes", "code": "50373504" },
      {
        "name": "Canned or jarred organic selma pete grapes",
        "code": "50373505"
      },
      { "name": "Canned or jarred organic sultana grapes", "code": "50373506" },
      { "name": "Canned or jarred organic wine grapes", "code": "50373600" },
      {
        "name": "Canned or jarred organic alicante bouschet grapes",
        "code": "50373601"
      },
      { "name": "Canned or jarred organic barbera grapes", "code": "50373602" },
      { "name": "Canned or jarred organic burger grapes", "code": "50373603" },
      {
        "name": "Canned or jarred organic cabernet franc grapes",
        "code": "50373604"
      },
      {
        "name": "Canned or jarred organic cabernet sauvignon grapes",
        "code": "50373605"
      },
      {
        "name": "Canned or jarred organic carignane grapes",
        "code": "50373606"
      },
      {
        "name": "Canned or jarred organic carnelian grapes",
        "code": "50373607"
      },
      {
        "name": "Canned or jarred organic catarratto grapes",
        "code": "50373608"
      },
      {
        "name": "Canned or jarred organic centurian grapes",
        "code": "50373609"
      },
      {
        "name": "Canned or jarred organic charbono grapes",
        "code": "50373610"
      },
      {
        "name": "Canned or jarred organic chardonnay grapes",
        "code": "50373611"
      },
      {
        "name": "Canned or jarred organic chenin blanc grapes",
        "code": "50373612"
      },
      { "name": "Canned or jarred organic cinsaut grapes", "code": "50373613" },
      {
        "name": "Canned or jarred organic dolcetto grapes",
        "code": "50373614"
      },
      {
        "name": "Canned or jarred organic emerald riesling grapes",
        "code": "50373615"
      },
      {
        "name": "Canned or jarred organic french colombard grapes",
        "code": "50373616"
      },
      {
        "name": "Canned or jarred organic gamay napa grapes",
        "code": "50373617"
      },
      {
        "name": "Canned or jarred organic gamay beaujolais grapes",
        "code": "50373618"
      },
      {
        "name": "Canned or jarred organic gewurztraminer grapes",
        "code": "50373619"
      },
      {
        "name": "Canned or jarred organic grenache grapes",
        "code": "50373620"
      },
      {
        "name": "Canned or jarred organic grenache blanc grapes",
        "code": "50373621"
      },
      { "name": "Canned or jarred organic lagrein grapes", "code": "50373622" },
      {
        "name": "Canned or jarred organic lambrusco grapes",
        "code": "50373623"
      },
      { "name": "Canned or jarred organic malbec grapes", "code": "50373624" },
      {
        "name": "Canned or jarred organic malvasia bianca grapes",
        "code": "50373625"
      },
      {
        "name": "Canned or jarred organic marsanne grapes",
        "code": "50373626"
      },
      { "name": "Canned or jarred organic mataro grapes", "code": "50373627" },
      { "name": "Canned or jarred organic merlot grapes", "code": "50373628" },
      { "name": "Canned or jarred organic meunier grapes", "code": "50373629" },
      { "name": "Canned or jarred organic mission grapes", "code": "50373630" },
      {
        "name": "Canned or jarred organic montepulciano grapes",
        "code": "50373631"
      },
      {
        "name": "Canned or jarred organic muscat blanc grapes",
        "code": "50373632"
      },
      {
        "name": "Canned or jarred organic muscat hamburg grapes",
        "code": "50373633"
      },
      {
        "name": "Canned or jarred organic muscat of alexandria grapes",
        "code": "50373634"
      },
      {
        "name": "Canned or jarred organic muscat orange grapes",
        "code": "50373635"
      },
      {
        "name": "Canned or jarred organic nebbiolo grapes",
        "code": "50373636"
      },
      {
        "name": "Canned or jarred organic palomino grapes",
        "code": "50373637"
      },
      {
        "name": "Canned or jarred organic petit verdot grapes",
        "code": "50373638"
      },
      {
        "name": "Canned or jarred organic petite sirah grapes",
        "code": "50373639"
      },
      {
        "name": "Canned or jarred organic pinot blanc grapes",
        "code": "50373640"
      },
      {
        "name": "Canned or jarred organic pinot gris grapes",
        "code": "50373641"
      },
      {
        "name": "Canned or jarred organic pinot noir grapes",
        "code": "50373642"
      },
      {
        "name": "Canned or jarred organic primitivo grapes",
        "code": "50373643"
      },
      {
        "name": "Canned or jarred organic roussanne grapes",
        "code": "50373644"
      },
      { "name": "Canned or jarred organic royalty grapes", "code": "50373645" },
      { "name": "Canned or jarred organic rubired grapes", "code": "50373646" },
      {
        "name": "Canned or jarred organic ruby cabernet grapes",
        "code": "50373647"
      },
      {
        "name": "Canned or jarred organic salvador grapes",
        "code": "50373648"
      },
      {
        "name": "Canned or jarred organic sangiovese grapes",
        "code": "50373649"
      },
      {
        "name": "Canned or jarred organic sauvignon blanc grapes",
        "code": "50373650"
      },
      {
        "name": "Canned or jarred organic sauvignon musque grapes",
        "code": "50373651"
      },
      {
        "name": "Canned or jarred organic semillon grapes",
        "code": "50373652"
      },
      { "name": "Canned or jarred organic souzao grapes", "code": "50373653" },
      {
        "name": "Canned or jarred organic st emilion grapes",
        "code": "50373654"
      },
      {
        "name": "Canned or jarred organic symphony grapes",
        "code": "50373655"
      },
      { "name": "Canned or jarred organic syrah grapes", "code": "50373656" },
      { "name": "Canned or jarred organic tannat grapes", "code": "50373657" },
      {
        "name": "Canned or jarred organic tempranillo grapes",
        "code": "50373658"
      },
      {
        "name": "Canned or jarred organic teroldego grapes",
        "code": "50373659"
      },
      {
        "name": "Canned or jarred organic tocai friulano grapes",
        "code": "50373660"
      },
      {
        "name": "Canned or jarred organic touriga nacional grapes",
        "code": "50373661"
      },
      {
        "name": "Canned or jarred organic triplett blanc grapes",
        "code": "50373662"
      },
      {
        "name": "Canned or jarred organic viognier grapes",
        "code": "50373663"
      },
      {
        "name": "Canned or jarred organic white riesling grapes",
        "code": "50373664"
      },
      {
        "name": "Canned or jarred organic zinfandel grapes",
        "code": "50373665"
      },
      { "name": "Canned or jarred organic guavas", "code": "50373700" },
      {
        "name": "Canned or jarred organic beaumont guavas",
        "code": "50373701"
      },
      { "name": "Canned or jarred organic carrley guavas", "code": "50373702" },
      { "name": "Canned or jarred organic lucida guavas", "code": "50373703" },
      {
        "name": "Canned or jarred organic pineapple guava",
        "code": "50373704"
      },
      { "name": "Canned or jarred organic huckleberries", "code": "50373800" },
      {
        "name": "Canned or jarred organic black winter huckleberries",
        "code": "50373801"
      },
      {
        "name": "Canned or jarred organic cascade huckleberries",
        "code": "50373802"
      },
      {
        "name": "Canned or jarred organic dwarf huckleberries",
        "code": "50373803"
      },
      {
        "name": "Canned or jarred organic mountain huckleberries",
        "code": "50373804"
      },
      {
        "name": "Canned or jarred organic red huckleberries",
        "code": "50373805"
      },
      { "name": "Canned or jarred organic kiwi fruit", "code": "50373900" },
      {
        "name": "Canned or jarred organic ananasnaja kiwi fruit",
        "code": "50373901"
      },
      {
        "name": "Canned or jarred organic arctic beauty kiwi fruit",
        "code": "50373902"
      },
      {
        "name": "Canned or jarred organic blake kiwi fruit",
        "code": "50373903"
      },
      {
        "name": "Canned or jarred organic hayward kiwi fruit",
        "code": "50373904"
      },
      {
        "name": "Canned or jarred organic issai kiwi fruit",
        "code": "50373905"
      },
      {
        "name": "Canned or jarred organic siberian kiwi fruit",
        "code": "50373906"
      },
      { "name": "Canned or jarred organic kumquats", "code": "50374000" },
      {
        "name": "Canned or jarred organic hong kong kumquats",
        "code": "50374001"
      },
      {
        "name": "Canned or jarred organic limequat kumquats",
        "code": "50374002"
      },
      {
        "name": "Canned or jarred organic long fruit kumquats",
        "code": "50374003"
      },
      {
        "name": "Canned or jarred organic malayan kumquats",
        "code": "50374004"
      },
      { "name": "Canned or jarred organic meiwa kumquats", "code": "50374005" },
      {
        "name": "Canned or jarred organic nagami kumquats",
        "code": "50374006"
      },
      { "name": "Canned or jarred organic lemons", "code": "50374100" },
      { "name": "Canned or jarred organic baboon lemons", "code": "50374101" },
      {
        "name": "Canned or jarred organic bearss sicilian lemons",
        "code": "50374102"
      },
      {
        "name": "Canned or jarred organic cameron highlands lemons",
        "code": "50374103"
      },
      {
        "name": "Canned or jarred organic escondido lemons",
        "code": "50374104"
      },
      { "name": "Canned or jarred organic eureka lemons", "code": "50374105" },
      { "name": "Canned or jarred organic lisbon lemons", "code": "50374106" },
      { "name": "Canned or jarred organic meyer lemons", "code": "50374107" },
      {
        "name": "Canned or jarred organic volkamer lemons",
        "code": "50374108"
      },
      { "name": "Canned or jarred organic limes", "code": "50374200" },
      {
        "name": "Canned or jarred organic indian sweet limes",
        "code": "50374201"
      },
      { "name": "Canned or jarred organic key limes", "code": "50374202" },
      { "name": "Canned or jarred organic mandarin limes", "code": "50374203" },
      {
        "name": "Canned or jarred organic philippine limes",
        "code": "50374204"
      },
      { "name": "Canned or jarred organic tahitian limes", "code": "50374205" },
      { "name": "Canned or jarred organic bearss limes", "code": "50374206" },
      { "name": "Canned or jarred organic persian limes", "code": "50374207" },
      { "name": "Canned or jarred organic seedless limes", "code": "50374208" },
      { "name": "Canned or jarred organic loquats", "code": "50374300" },
      {
        "name": "Canned or jarred organic advance loquats",
        "code": "50374301"
      },
      {
        "name": "Canned or jarred organic benlehr loquats",
        "code": "50374302"
      },
      {
        "name": "Canned or jarred organic big jim loquats",
        "code": "50374303"
      },
      {
        "name": "Canned or jarred organic champagne loquats",
        "code": "50374304"
      },
      {
        "name": "Canned or jarred organic early red loquats",
        "code": "50374305"
      },
      {
        "name": "Canned or jarred organic gold nugget loquats",
        "code": "50374306"
      },
      {
        "name": "Canned or jarred organic herd's mammoth loquats",
        "code": "50374307"
      },
      { "name": "Canned or jarred organic mogi loquats", "code": "50374308" },
      {
        "name": "Canned or jarred organic mrs cooksey loquats",
        "code": "50374309"
      },
      {
        "name": "Canned or jarred organic strawberry loquats",
        "code": "50374310"
      },
      { "name": "Canned or jarred organic tanaka loquats", "code": "50374311" },
      {
        "name": "Canned or jarred organic victory vista white loquats",
        "code": "50374312"
      },
      { "name": "Canned or jarred organic wolfe loquats", "code": "50374313" },
      {
        "name": "Canned or jarred organic mandarin oranges or tangerines",
        "code": "50374400"
      },
      {
        "name": "Canned or jarred organic clauselinas oranges",
        "code": "50374401"
      },
      {
        "name": "Canned or jarred organic clementine tangerines",
        "code": "50374402"
      },
      {
        "name": "Canned or jarred organic cleopatra mandarin oranges",
        "code": "50374403"
      },
      {
        "name": "Canned or jarred organic dancy tangerines",
        "code": "50374404"
      },
      {
        "name": "Canned or jarred organic ellensdale oranges",
        "code": "50374405"
      },
      {
        "name": "Canned or jarred organic fairchild oranges",
        "code": "50374406"
      },
      {
        "name": "Canned or jarred organic fallglo oranges",
        "code": "50374407"
      },
      {
        "name": "Canned or jarred organic fortune oranges",
        "code": "50374408"
      },
      {
        "name": "Canned or jarred organic fremont mandarin oranges",
        "code": "50374409"
      },
      {
        "name": "Canned or jarred organic fremont oranges",
        "code": "50374410"
      },
      {
        "name": "Canned or jarred organic golden nugget oranges",
        "code": "50374411"
      },
      {
        "name": "Canned or jarred organic honey mandarin oranges",
        "code": "50374412"
      },
      { "name": "Canned or jarred organic honey oranges", "code": "50374413" },
      {
        "name": "Canned or jarred organic honey tangerines",
        "code": "50374414"
      },
      {
        "name": "Canned or jarred organic honeybelle tangelo oranges",
        "code": "50374415"
      },
      {
        "name": "Canned or jarred organic king mandarin oranges",
        "code": "50374416"
      },
      { "name": "Canned or jarred organic kinnow oranges", "code": "50374417" },
      {
        "name": "Canned or jarred organic lee mandarin oranges",
        "code": "50374418"
      },
      {
        "name": "Canned or jarred organic makokkee oranges",
        "code": "50374419"
      },
      {
        "name": "Canned or jarred organic malvasios oranges",
        "code": "50374420"
      },
      {
        "name": "Canned or jarred organic mediterranean mandarin oranges",
        "code": "50374421"
      },
      {
        "name": "Canned or jarred organic minneola tangelo oranges",
        "code": "50374422"
      },
      { "name": "Canned or jarred organic monica oranges", "code": "50374423" },
      {
        "name": "Canned or jarred organic murcott honey oranges",
        "code": "50374424"
      },
      {
        "name": "Canned or jarred organic murcott tangors",
        "code": "50374425"
      },
      {
        "name": "Canned or jarred organic natsudaidai mandarin oranges",
        "code": "50374426"
      },
      {
        "name": "Canned or jarred organic natsumikan mandarin oranges",
        "code": "50374427"
      },
      {
        "name": "Canned or jarred organic nocatee tangelo oranges",
        "code": "50374428"
      },
      {
        "name": "Canned or jarred organic orlando tangelo oranges",
        "code": "50374429"
      },
      {
        "name": "Canned or jarred organic ortanique tangerines",
        "code": "50374430"
      },
      {
        "name": "Canned or jarred organic page mandarin oranges",
        "code": "50374431"
      },
      { "name": "Canned or jarred organic pixie oranges", "code": "50374432" },
      {
        "name": "Canned or jarred organic ponkan bantangas mandarin oranges",
        "code": "50374433"
      },
      { "name": "Canned or jarred organic reyna oranges", "code": "50374434" },
      {
        "name": "Canned or jarred organic robinson oranges",
        "code": "50374435"
      },
      {
        "name": "Canned or jarred organic saltenitas oranges",
        "code": "50374436"
      },
      {
        "name": "Canned or jarred organic sampson tangelo oranges",
        "code": "50374437"
      },
      {
        "name": "Canned or jarred organic satsuma mandarin oranges",
        "code": "50374438"
      },
      {
        "name": "Canned or jarred organic sunburst mandarin oranges",
        "code": "50374439"
      },
      { "name": "Canned or jarred organic tangelos", "code": "50374440" },
      {
        "name": "Canned or jarred organic tangerina oranges",
        "code": "50374441"
      },
      { "name": "Canned or jarred organic temple oranges", "code": "50374442" },
      {
        "name": "Canned or jarred organic thornton oranges",
        "code": "50374443"
      },
      {
        "name": "Canned or jarred organic wekiwa tangerines",
        "code": "50374444"
      },
      {
        "name": "Canned or jarred organic wilkins tangerines",
        "code": "50374445"
      },
      {
        "name": "Canned or jarred organic willowleaf mediterranean tangerines",
        "code": "50374446"
      },
      { "name": "Canned or jarred organic mangoes", "code": "50374500" },
      {
        "name": "Canned or jarred organic alphonso mangoes",
        "code": "50374501"
      },
      {
        "name": "Canned or jarred organic ataulfo mangoes",
        "code": "50374502"
      },
      {
        "name": "Canned or jarred organic criollo mangoes",
        "code": "50374503"
      },
      {
        "name": "Canned or jarred organic edwards mangoes",
        "code": "50374504"
      },
      {
        "name": "Canned or jarred organic francine mangoes",
        "code": "50374505"
      },
      {
        "name": "Canned or jarred organic francis mangoes",
        "code": "50374506"
      },
      {
        "name": "Canned or jarred organic gandaria mangoes",
        "code": "50374507"
      },
      { "name": "Canned or jarred organic haden mangoes", "code": "50374508" },
      { "name": "Canned or jarred organic irwin mangoes", "code": "50374509" },
      { "name": "Canned or jarred organic keitt mangoes", "code": "50374510" },
      { "name": "Canned or jarred organic kent mangoes", "code": "50374511" },
      { "name": "Canned or jarred organic kesar mangoes", "code": "50374512" },
      { "name": "Canned or jarred organic kuini mangoes", "code": "50374513" },
      {
        "name": "Canned or jarred organic manila super mangoes",
        "code": "50374514"
      },
      { "name": "Canned or jarred organic manila mangoes", "code": "50374515" },
      {
        "name": "Canned or jarred organic mayaguez mangoes",
        "code": "50374516"
      },
      {
        "name": "Canned or jarred organic mulgoba mangoes",
        "code": "50374517"
      },
      { "name": "Canned or jarred organic oro mangoes", "code": "50374518" },
      { "name": "Canned or jarred organic palmer mangoes", "code": "50374519" },
      { "name": "Canned or jarred organic parvin mangoes", "code": "50374520" },
      {
        "name": "Canned or jarred organic sandersha mangoes",
        "code": "50374521"
      },
      {
        "name": "Canned or jarred organic sensation mangoes",
        "code": "50374522"
      },
      { "name": "Canned or jarred organic smith mangoes", "code": "50374523" },
      {
        "name": "Canned or jarred organic tommy atkins mangoes",
        "code": "50374524"
      },
      {
        "name": "Canned or jarred organic van dyke mangoes",
        "code": "50374525"
      },
      { "name": "Canned or jarred organic melons", "code": "50374600" },
      {
        "name": "Canned or jarred organic allsweet melons",
        "code": "50374601"
      },
      { "name": "Canned or jarred organic athena melons", "code": "50374602" },
      {
        "name": "Canned or jarred organic black diamond melons",
        "code": "50374603"
      },
      {
        "name": "Canned or jarred organic cal sweet melons",
        "code": "50374604"
      },
      {
        "name": "Canned or jarred organic carnical melons",
        "code": "50374605"
      },
      {
        "name": "Canned or jarred organic cantaloupe melons",
        "code": "50374606"
      },
      { "name": "Canned or jarred organic casaba melons", "code": "50374607" },
      {
        "name": "Canned or jarred organic cavaillon melons",
        "code": "50374608"
      },
      {
        "name": "Canned or jarred organic charentais melons",
        "code": "50374609"
      },
      {
        "name": "Canned or jarred organic charleston gray watermelon",
        "code": "50374610"
      },
      {
        "name": "Canned or jarred organic crenshaw melons",
        "code": "50374611"
      },
      {
        "name": "Canned or jarred organic crimson sweet melons",
        "code": "50374612"
      },
      {
        "name": "Canned or jarred organic dixie lee melons",
        "code": "50374613"
      },
      { "name": "Canned or jarred organic eclipse melons", "code": "50374614" },
      {
        "name": "Canned or jarred organic ein d'or melons",
        "code": "50374615"
      },
      { "name": "Canned or jarred organic fiesta melons", "code": "50374616" },
      { "name": "Canned or jarred organic galia melons", "code": "50374617" },
      { "name": "Canned or jarred organic gaya melons", "code": "50374618" },
      { "name": "Canned or jarred organic hami melons", "code": "50374619" },
      {
        "name": "Canned or jarred organic honeydew melons",
        "code": "50374620"
      },
      { "name": "Canned or jarred organic icebox melons", "code": "50374621" },
      {
        "name": "Canned or jarred organic ida pride melons",
        "code": "50374622"
      },
      {
        "name": "Canned or jarred organic juan canary melons",
        "code": "50374623"
      },
      { "name": "Canned or jarred organic jubilee melons", "code": "50374624" },
      {
        "name": "Canned or jarred organic jubilation melons",
        "code": "50374625"
      },
      {
        "name": "Canned or jarred organic kakhi/kakri melons",
        "code": "50374626"
      },
      { "name": "Canned or jarred organic kiwano melons", "code": "50374627" },
      { "name": "Canned or jarred organic korean melons", "code": "50374628" },
      {
        "name": "Canned or jarred organic long gray melons",
        "code": "50374629"
      },
      { "name": "Canned or jarred organic mayan melons", "code": "50374630" },
      {
        "name": "Canned or jarred organic micky lee melons",
        "code": "50374631"
      },
      { "name": "Canned or jarred organic mirage melons", "code": "50374632" },
      {
        "name": "Canned or jarred organic moon and stars watermelon",
        "code": "50374633"
      },
      { "name": "Canned or jarred organic ogen melons", "code": "50374634" },
      { "name": "Canned or jarred organic patriot melons", "code": "50374635" },
      { "name": "Canned or jarred organic peacock melons", "code": "50374636" },
      { "name": "Canned or jarred organic pepino melons", "code": "50374637" },
      { "name": "Canned or jarred organic persian melons", "code": "50374638" },
      { "name": "Canned or jarred organic picnic melons", "code": "50374639" },
      {
        "name": "Canned or jarred organic piel de sapo melons",
        "code": "50374640"
      },
      {
        "name": "Canned or jarred organic pineapple melons",
        "code": "50374641"
      },
      {
        "name": "Canned or jarred organic quetzali melons",
        "code": "50374642"
      },
      {
        "name": "Canned or jarred organic red goblin melons",
        "code": "50374643"
      },
      { "name": "Canned or jarred organic regency melons", "code": "50374644" },
      {
        "name": "Canned or jarred organic royal majestic melons",
        "code": "50374645"
      },
      {
        "name": "Canned or jarred organic royal star melons",
        "code": "50374646"
      },
      {
        "name": "Canned or jarred organic royal sweet melons",
        "code": "50374647"
      },
      {
        "name": "Canned or jarred organic santa claus melons",
        "code": "50374648"
      },
      { "name": "Canned or jarred organic sharlyn melons", "code": "50374649" },
      { "name": "Canned or jarred organic spanish melons", "code": "50374650" },
      { "name": "Canned or jarred organic sprite melons", "code": "50374651" },
      {
        "name": "Canned or jarred organic starbright melons",
        "code": "50374652"
      },
      {
        "name": "Canned or jarred organic stars n stripes melons",
        "code": "50374653"
      },
      {
        "name": "Canned or jarred organic sugar baby melons",
        "code": "50374654"
      },
      {
        "name": "Canned or jarred organic sugar baby watermelon",
        "code": "50374655"
      },
      {
        "name": "Canned or jarred organic sunsweet melons",
        "code": "50374656"
      },
      {
        "name": "Canned or jarred organic sweet heart seedless watermelon",
        "code": "50374657"
      },
      {
        "name": "Canned or jarred organic temptation melons",
        "code": "50374658"
      },
      {
        "name": "Canned or jarred organic tiger baby melons",
        "code": "50374659"
      },
      {
        "name": "Canned or jarred organic tuscan type melons",
        "code": "50374660"
      },
      {
        "name": "Canned or jarred organic yellow baby watermelon",
        "code": "50374661"
      },
      { "name": "Canned or jarred organic mulberries", "code": "50374700" },
      {
        "name": "Canned or jarred organic black mulberries",
        "code": "50374701"
      },
      {
        "name": "Canned or jarred organic white mulberries",
        "code": "50374702"
      },
      {
        "name": "Canned or jarred organic bayberries or myrtles",
        "code": "50374800"
      },
      { "name": "Canned or jarred organic bog myrtle", "code": "50374801" },
      { "name": "Canned or jarred organic bayberries", "code": "50374802" },
      { "name": "Canned or jarred organic nectarines", "code": "50374900" },
      {
        "name": "Canned or jarred organic april glo nectarines",
        "code": "50374901"
      },
      {
        "name": "Canned or jarred organic arctic mist nectarines",
        "code": "50374902"
      },
      {
        "name": "Canned or jarred organic arctic snow nectarines",
        "code": "50374903"
      },
      {
        "name": "Canned or jarred organic arctic star nectarines",
        "code": "50374904"
      },
      {
        "name": "Canned or jarred organic arctic sweet nectarines",
        "code": "50374905"
      },
      {
        "name": "Canned or jarred organic arctic glo nectarines",
        "code": "50374906"
      },
      {
        "name": "Canned or jarred organic august fire nectarines",
        "code": "50374907"
      },
      {
        "name": "Canned or jarred organic august pearl nectarines",
        "code": "50374908"
      },
      {
        "name": "Canned or jarred organic august red nectarines",
        "code": "50374909"
      },
      {
        "name": "Canned or jarred organic autumn star nectarines",
        "code": "50374910"
      },
      {
        "name": "Canned or jarred organic big john nectarines",
        "code": "50374911"
      },
      {
        "name": "Canned or jarred organic bright pearl nectarines",
        "code": "50374912"
      },
      {
        "name": "Canned or jarred organic diamond bright nectarines",
        "code": "50374913"
      },
      {
        "name": "Canned or jarred organic diamond ray nectarines",
        "code": "50374914"
      },
      {
        "name": "Canned or jarred organic earliglo nectarines",
        "code": "50374915"
      },
      {
        "name": "Canned or jarred organic early diamond nectarines",
        "code": "50374916"
      },
      {
        "name": "Canned or jarred organic fairlane nectarines",
        "code": "50374917"
      },
      {
        "name": "Canned or jarred organic fantasia nectarines",
        "code": "50374918"
      },
      {
        "name": "Canned or jarred organic fire pearl nectarines",
        "code": "50374919"
      },
      {
        "name": "Canned or jarred organic fire sweet nectarines",
        "code": "50374920"
      },
      {
        "name": "Canned or jarred organic flamekist nectarines",
        "code": "50374921"
      },
      {
        "name": "Canned or jarred organic flat type nectarines",
        "code": "50374922"
      },
      {
        "name": "Canned or jarred organic garden delight nectarines",
        "code": "50374923"
      },
      {
        "name": "Canned or jarred organic goldmine nectarines",
        "code": "50374924"
      },
      {
        "name": "Canned or jarred organic grand pearl nectarines",
        "code": "50374925"
      },
      {
        "name": "Canned or jarred organic hardired nectarines",
        "code": "50374926"
      },
      {
        "name": "Canned or jarred organic honey blaze nectarines",
        "code": "50374927"
      },
      {
        "name": "Canned or jarred organic july red nectarines",
        "code": "50374928"
      },
      {
        "name": "Canned or jarred organic kay pearl nectarines",
        "code": "50374929"
      },
      {
        "name": "Canned or jarred organic kay sweet nectarines",
        "code": "50374930"
      },
      {
        "name": "Canned or jarred organic may diamond nectarines",
        "code": "50374931"
      },
      {
        "name": "Canned or jarred organic mayfire nectarines",
        "code": "50374932"
      },
      {
        "name": "Canned or jarred organic mayglo nectarines",
        "code": "50374933"
      },
      {
        "name": "Canned or jarred organic mericrest nectarines",
        "code": "50374934"
      },
      {
        "name": "Canned or jarred organic red diamond nectarines",
        "code": "50374935"
      },
      {
        "name": "Canned or jarred organic red gold nectarines",
        "code": "50374936"
      },
      {
        "name": "Canned or jarred organic red jim nectarines",
        "code": "50374937"
      },
      {
        "name": "Canned or jarred organic red roy nectarines",
        "code": "50374938"
      },
      {
        "name": "Canned or jarred organic rio red nectarines",
        "code": "50374939"
      },
      {
        "name": "Canned or jarred organic rose diamond nectarines",
        "code": "50374940"
      },
      {
        "name": "Canned or jarred organic royal glo nectarines",
        "code": "50374941"
      },
      {
        "name": "Canned or jarred organic ruby diamond nectarines",
        "code": "50374942"
      },
      {
        "name": "Canned or jarred organic ruby sweet nectarines",
        "code": "50374943"
      },
      {
        "name": "Canned or jarred organic ruddy jewel nectarines",
        "code": "50374944"
      },
      {
        "name": "Canned or jarred organic september red nectarines",
        "code": "50374945"
      },
      {
        "name": "Canned or jarred organic snowqueen nectarines",
        "code": "50374946"
      },
      {
        "name": "Canned or jarred organic spring bright nectarines",
        "code": "50374947"
      },
      {
        "name": "Canned or jarred organic spring red nectarines",
        "code": "50374948"
      },
      {
        "name": "Canned or jarred organic summer blush nectarines",
        "code": "50374949"
      },
      {
        "name": "Canned or jarred organic summer brite nectarines",
        "code": "50374950"
      },
      {
        "name": "Canned or jarred organic summer diamond nectarines",
        "code": "50374951"
      },
      {
        "name": "Canned or jarred organic summer fire nectarines",
        "code": "50374952"
      },
      {
        "name": "Canned or jarred organic summer grand nectarines",
        "code": "50374953"
      },
      {
        "name": "Canned or jarred organic sunglo nectarines",
        "code": "50374954"
      },
      {
        "name": "Canned or jarred organic zee fire nectarines",
        "code": "50374955"
      },
      {
        "name": "Canned or jarred organic zee glo nectarines",
        "code": "50374956"
      },
      {
        "name": "Canned or jarred organic zeegrand nectarines",
        "code": "50374957"
      },
      { "name": "Canned or jarred organic oranges", "code": "50375000" },
      {
        "name": "Canned or jarred organic african sour oranges",
        "code": "50375001"
      },
      {
        "name": "Canned or jarred organic ambersweet oranges",
        "code": "50375002"
      },
      {
        "name": "Canned or jarred organic argentine sour oranges",
        "code": "50375003"
      },
      {
        "name": "Canned or jarred organic bahianinha oranges",
        "code": "50375004"
      },
      {
        "name": "Canned or jarred organic bergamot oranges",
        "code": "50375005"
      },
      { "name": "Canned or jarred organic berna oranges", "code": "50375006" },
      {
        "name": "Canned or jarred organic bigaradier apepu oranges",
        "code": "50375007"
      },
      {
        "name": "Canned or jarred organic bittersweet daidai oranges",
        "code": "50375008"
      },
      { "name": "Canned or jarred organic blonde oranges", "code": "50375009" },
      { "name": "Canned or jarred organic blood oranges", "code": "50375010" },
      {
        "name": "Canned or jarred organic california navel oranges",
        "code": "50375011"
      },
      {
        "name": "Canned or jarred organic cara cara oranges",
        "code": "50375012"
      },
      {
        "name": "Canned or jarred organic chinotto oranges",
        "code": "50375013"
      },
      {
        "name": "Canned or jarred organic dream navel oranges",
        "code": "50375014"
      },
      {
        "name": "Canned or jarred organic gou tou oranges",
        "code": "50375015"
      },
      { "name": "Canned or jarred organic hamlin oranges", "code": "50375016" },
      { "name": "Canned or jarred organic jaffa oranges", "code": "50375017" },
      {
        "name": "Canned or jarred organic jincheng oranges",
        "code": "50375018"
      },
      {
        "name": "Canned or jarred organic k-early oranges",
        "code": "50375019"
      },
      { "name": "Canned or jarred organic kona oranges", "code": "50375020" },
      {
        "name": "Canned or jarred organic late navel oranges",
        "code": "50375021"
      },
      {
        "name": "Canned or jarred organic late valencia oranges",
        "code": "50375022"
      },
      {
        "name": "Canned or jarred organic limequat oranges",
        "code": "50375023"
      },
      { "name": "Canned or jarred organic marr oranges", "code": "50375024" },
      {
        "name": "Canned or jarred organic melogold oranges",
        "code": "50375025"
      },
      { "name": "Canned or jarred organic moro oranges", "code": "50375026" },
      {
        "name": "Canned or jarred organic moro tarocco oranges",
        "code": "50375027"
      },
      { "name": "Canned or jarred organic navel oranges", "code": "50375028" },
      {
        "name": "Canned or jarred organic navelina oranges",
        "code": "50375029"
      },
      {
        "name": "Canned or jarred organic oro blanco oranges",
        "code": "50375030"
      },
      {
        "name": "Canned or jarred organic osceola oranges",
        "code": "50375031"
      },
      {
        "name": "Canned or jarred organic parson brown oranges",
        "code": "50375032"
      },
      { "name": "Canned or jarred organic pera oranges", "code": "50375033" },
      {
        "name": "Canned or jarred organic pummulo oranges",
        "code": "50375034"
      },
      {
        "name": "Canned or jarred organic rhode red oranges",
        "code": "50375035"
      },
      { "name": "Canned or jarred organic roble oranges", "code": "50375036" },
      {
        "name": "Canned or jarred organic salustianas oranges",
        "code": "50375037"
      },
      {
        "name": "Canned or jarred organic sanguine oranges",
        "code": "50375038"
      },
      {
        "name": "Canned or jarred organic sanguinelli oranges",
        "code": "50375039"
      },
      {
        "name": "Canned or jarred organic seville oranges",
        "code": "50375040"
      },
      {
        "name": "Canned or jarred organic shamouti jaffa oranges",
        "code": "50375041"
      },
      { "name": "Canned or jarred organic tunis oranges", "code": "50375042" },
      {
        "name": "Canned or jarred organic valencia oranges",
        "code": "50375043"
      },
      {
        "name": "Canned or jarred organic washington navel oranges",
        "code": "50375044"
      },
      { "name": "Canned or jarred organic papayas", "code": "50375100" },
      {
        "name": "Canned or jarred organic green cooking papayas",
        "code": "50375101"
      },
      {
        "name": "Canned or jarred organic maradol papayas",
        "code": "50375102"
      },
      {
        "name": "Canned or jarred organic mexican yellow papayas",
        "code": "50375103"
      },
      {
        "name": "Canned or jarred organic mountain papayas",
        "code": "50375104"
      },
      { "name": "Canned or jarred organic solo papayas", "code": "50375105" },
      {
        "name": "Canned or jarred organic tainung papayas",
        "code": "50375106"
      },
      { "name": "Canned or jarred organic passion fruit", "code": "50375200" },
      {
        "name": "Canned or jarred organic banana passion fruit",
        "code": "50375201"
      },
      {
        "name": "Canned or jarred organic blue passion flower",
        "code": "50375202"
      },
      {
        "name": "Canned or jarred organic crackerjack passion fruit",
        "code": "50375203"
      },
      {
        "name": "Canned or jarred organic giant granadilla passion fruit",
        "code": "50375204"
      },
      {
        "name": "Canned or jarred organic golden granadilla passion fruit",
        "code": "50375205"
      },
      {
        "name": "Canned or jarred organic maypops passion fruit",
        "code": "50375206"
      },
      {
        "name": "Canned or jarred organic red granadilla passion fruit",
        "code": "50375207"
      },
      {
        "name": "Canned or jarred organic sweet granadilla passion fruit",
        "code": "50375208"
      },
      {
        "name": "Canned or jarred organic water lemon passion fruit",
        "code": "50375209"
      },
      {
        "name": "Canned or jarred organic wing-stemmed passion flower",
        "code": "50375210"
      },
      { "name": "Canned or jarred organic peaches", "code": "50375300" },
      {
        "name": "Canned or jarred organic amber crest peaches",
        "code": "50375301"
      },
      {
        "name": "Canned or jarred organic april snow peaches",
        "code": "50375302"
      },
      {
        "name": "Canned or jarred organic august lady peaches",
        "code": "50375303"
      },
      {
        "name": "Canned or jarred organic autumn flame peaches",
        "code": "50375304"
      },
      {
        "name": "Canned or jarred organic autumn lady peaches",
        "code": "50375305"
      },
      {
        "name": "Canned or jarred organic babcock peaches",
        "code": "50375306"
      },
      {
        "name": "Canned or jarred organic brittney lane peaches",
        "code": "50375307"
      },
      {
        "name": "Canned or jarred organic cary mac peaches",
        "code": "50375308"
      },
      {
        "name": "Canned or jarred organic classic peaches",
        "code": "50375309"
      },
      {
        "name": "Canned or jarred organic country sweet peaches",
        "code": "50375310"
      },
      {
        "name": "Canned or jarred organic crest haven peaches",
        "code": "50375311"
      },
      {
        "name": "Canned or jarred organic crimson lady peaches",
        "code": "50375312"
      },
      {
        "name": "Canned or jarred organic crown princess peaches",
        "code": "50375313"
      },
      {
        "name": "Canned or jarred organic david sun peaches",
        "code": "50375314"
      },
      {
        "name": "Canned or jarred organic diamond princess peaches",
        "code": "50375315"
      },
      {
        "name": "Canned or jarred organic earlirich peaches",
        "code": "50375316"
      },
      {
        "name": "Canned or jarred organic early majestic peaches",
        "code": "50375317"
      },
      {
        "name": "Canned or jarred organic early treat peaches",
        "code": "50375318"
      },
      {
        "name": "Canned or jarred organic elegant lady peaches",
        "code": "50375319"
      },
      {
        "name": "Canned or jarred organic empress peaches",
        "code": "50375320"
      },
      { "name": "Canned or jarred organic encore peaches", "code": "50375321" },
      {
        "name": "Canned or jarred organic fancy lady peaches",
        "code": "50375322"
      },
      {
        "name": "Canned or jarred organic fire prince peaches",
        "code": "50375323"
      },
      {
        "name": "Canned or jarred organic flame crest peaches",
        "code": "50375324"
      },
      {
        "name": "Canned or jarred organic flat type peaches",
        "code": "50375325"
      },
      {
        "name": "Canned or jarred organic flavorcrest peaches",
        "code": "50375326"
      },
      {
        "name": "Canned or jarred organic florida prince peaches",
        "code": "50375327"
      },
      {
        "name": "Canned or jarred organic full moon peaches",
        "code": "50375328"
      },
      {
        "name": "Canned or jarred organic harvester peaches",
        "code": "50375329"
      },
      {
        "name": "Canned or jarred organic ice princess peaches",
        "code": "50375330"
      },
      {
        "name": "Canned or jarred organic ivory princess peaches",
        "code": "50375331"
      },
      {
        "name": "Canned or jarred organic jersey queen peaches",
        "code": "50375332"
      },
      {
        "name": "Canned or jarred organic john henry peaches",
        "code": "50375333"
      },
      {
        "name": "Canned or jarred organic june prince peaches",
        "code": "50375334"
      },
      { "name": "Canned or jarred organic kaweah peaches", "code": "50375335" },
      {
        "name": "Canned or jarred organic klondike peaches",
        "code": "50375336"
      },
      { "name": "Canned or jarred organic lindo peaches", "code": "50375337" },
      { "name": "Canned or jarred organic loring peaches", "code": "50375338" },
      {
        "name": "Canned or jarred organic majestic peaches",
        "code": "50375339"
      },
      {
        "name": "Canned or jarred organic o'henry peaches",
        "code": "50375340"
      },
      {
        "name": "Canned or jarred organic queencrest peaches",
        "code": "50375341"
      },
      {
        "name": "Canned or jarred organic red lady peaches",
        "code": "50375342"
      },
      {
        "name": "Canned or jarred organic redglobe peaches",
        "code": "50375343"
      },
      {
        "name": "Canned or jarred organic redhaven peaches",
        "code": "50375344"
      },
      { "name": "Canned or jarred organic redtop peaches", "code": "50375345" },
      { "name": "Canned or jarred organic regina peaches", "code": "50375346" },
      {
        "name": "Canned or jarred organic rich lady peaches",
        "code": "50375347"
      },
      {
        "name": "Canned or jarred organic rich may peaches",
        "code": "50375348"
      },
      {
        "name": "Canned or jarred organic royal glory peaches",
        "code": "50375349"
      },
      {
        "name": "Canned or jarred organic royal lady peaches",
        "code": "50375350"
      },
      {
        "name": "Canned or jarred organic september snow peaches",
        "code": "50375351"
      },
      {
        "name": "Canned or jarred organic september sun peaches",
        "code": "50375352"
      },
      {
        "name": "Canned or jarred organic sierra gem peaches",
        "code": "50375353"
      },
      {
        "name": "Canned or jarred organic snow angel peaches",
        "code": "50375354"
      },
      {
        "name": "Canned or jarred organic snow gem peaches",
        "code": "50375355"
      },
      {
        "name": "Canned or jarred organic snow king peaches",
        "code": "50375356"
      },
      {
        "name": "Canned or jarred organic spring lady peaches",
        "code": "50375357"
      },
      {
        "name": "Canned or jarred organic spring snow peaches",
        "code": "50375358"
      },
      {
        "name": "Canned or jarred organic springcrest peaches",
        "code": "50375359"
      },
      {
        "name": "Canned or jarred organic sugar giant peaches",
        "code": "50375360"
      },
      {
        "name": "Canned or jarred organic sugar lady peaches",
        "code": "50375361"
      },
      {
        "name": "Canned or jarred organic sun bright peaches",
        "code": "50375362"
      },
      {
        "name": "Canned or jarred organic sunhigh peaches",
        "code": "50375363"
      },
      {
        "name": "Canned or jarred organic super lady peaches",
        "code": "50375364"
      },
      {
        "name": "Canned or jarred organic super rich peaches",
        "code": "50375365"
      },
      {
        "name": "Canned or jarred organic surecrop peaches",
        "code": "50375366"
      },
      {
        "name": "Canned or jarred organic sweet dream peaches",
        "code": "50375367"
      },
      {
        "name": "Canned or jarred organic sweet september peaches",
        "code": "50375368"
      },
      { "name": "Canned or jarred organic vista peaches", "code": "50375369" },
      {
        "name": "Canned or jarred organic white lady peaches",
        "code": "50375370"
      },
      {
        "name": "Canned or jarred organic zee lady peaches",
        "code": "50375371"
      },
      { "name": "Canned or jarred organic pears", "code": "50375400" },
      {
        "name": "Canned or jarred organic abate fetel pears",
        "code": "50375401"
      },
      { "name": "Canned or jarred organic anjou pears", "code": "50375402" },
      { "name": "Canned or jarred organic asian pears", "code": "50375403" },
      { "name": "Canned or jarred organic bartlett pears", "code": "50375404" },
      {
        "name": "Canned or jarred organic best ever pears",
        "code": "50375405"
      },
      { "name": "Canned or jarred organic beth pears", "code": "50375406" },
      { "name": "Canned or jarred organic beurre pears", "code": "50375407" },
      { "name": "Canned or jarred organic bosc pears", "code": "50375408" },
      {
        "name": "Canned or jarred organic clapp favorite pears",
        "code": "50375409"
      },
      { "name": "Canned or jarred organic comice pears", "code": "50375410" },
      { "name": "Canned or jarred organic concorde pears", "code": "50375411" },
      {
        "name": "Canned or jarred organic conference pears",
        "code": "50375412"
      },
      {
        "name": "Canned or jarred organic crimson red pears",
        "code": "50375413"
      },
      { "name": "Canned or jarred organic d'anjou pears", "code": "50375414" },
      {
        "name": "Canned or jarred organic dr jules guyot pears",
        "code": "50375415"
      },
      { "name": "Canned or jarred organic early pears", "code": "50375416" },
      {
        "name": "Canned or jarred organic emperor brown pears",
        "code": "50375417"
      },
      { "name": "Canned or jarred organic forelle pears", "code": "50375418" },
      {
        "name": "Canned or jarred organic french butter pears",
        "code": "50375419"
      },
      {
        "name": "Canned or jarred organic glou morceau pears",
        "code": "50375420"
      },
      { "name": "Canned or jarred organic hosui pears", "code": "50375421" },
      {
        "name": "Canned or jarred organic italian butter pears",
        "code": "50375422"
      },
      {
        "name": "Canned or jarred organic jargonelle pears",
        "code": "50375423"
      },
      { "name": "Canned or jarred organic juno pears", "code": "50375424" },
      {
        "name": "Canned or jarred organic kaiserlouise bonne de jersey pears",
        "code": "50375425"
      },
      { "name": "Canned or jarred organic keiffer pears", "code": "50375426" },
      {
        "name": "Canned or jarred organic kings royal pears",
        "code": "50375427"
      },
      { "name": "Canned or jarred organic limonera pears", "code": "50375428" },
      {
        "name": "Canned or jarred organic merton pride pears",
        "code": "50375429"
      },
      {
        "name": "Canned or jarred organic mountain bartlett pears",
        "code": "50375430"
      },
      {
        "name": "Canned or jarred organic olivier de serres pears",
        "code": "50375431"
      },
      { "name": "Canned or jarred organic onward pears", "code": "50375432" },
      {
        "name": "Canned or jarred organic packham's triumph pears",
        "code": "50375433"
      },
      { "name": "Canned or jarred organic paraiso pears", "code": "50375434" },
      {
        "name": "Canned or jarred organic passe crasanne pears",
        "code": "50375435"
      },
      { "name": "Canned or jarred organic perry pears", "code": "50375436" },
      {
        "name": "Canned or jarred organic red bartlett pears",
        "code": "50375437"
      },
      {
        "name": "Canned or jarred organic red d'anjou pears",
        "code": "50375438"
      },
      { "name": "Canned or jarred organic rocha pears", "code": "50375439" },
      {
        "name": "Canned or jarred organic rosey red pears",
        "code": "50375440"
      },
      { "name": "Canned or jarred organic rosy red pears", "code": "50375441" },
      {
        "name": "Canned or jarred organic royal majestic pears",
        "code": "50375442"
      },
      { "name": "Canned or jarred organic ruby red pears", "code": "50375443" },
      {
        "name": "Canned or jarred organic santa maria pears",
        "code": "50375444"
      },
      { "name": "Canned or jarred organic seckel pears", "code": "50375445" },
      {
        "name": "Canned or jarred organic sensation pears",
        "code": "50375446"
      },
      {
        "name": "Canned or jarred organic star crimson pears",
        "code": "50375447"
      },
      {
        "name": "Canned or jarred organic stark crimson pears",
        "code": "50375448"
      },
      {
        "name": "Canned or jarred organic summer bartlett pears",
        "code": "50375449"
      },
      {
        "name": "Canned or jarred organic summer gold pears",
        "code": "50375450"
      },
      { "name": "Canned or jarred organic sun gold pears", "code": "50375451" },
      {
        "name": "Canned or jarred organic sunsprite pears",
        "code": "50375452"
      },
      {
        "name": "Canned or jarred organic taylors gold pears",
        "code": "50375453"
      },
      {
        "name": "Canned or jarred organic taylors red pears",
        "code": "50375454"
      },
      { "name": "Canned or jarred organic tientsin pears", "code": "50375455" },
      { "name": "Canned or jarred organic tosca pears", "code": "50375456" },
      { "name": "Canned or jarred organic warden pears", "code": "50375457" },
      {
        "name": "Canned or jarred organic williams bon chretien pears",
        "code": "50375458"
      },
      { "name": "Canned or jarred organic williams pears", "code": "50375459" },
      {
        "name": "Canned or jarred organic winter nelis pears",
        "code": "50375460"
      },
      { "name": "Canned or jarred organic persimmons", "code": "50375500" },
      {
        "name": "Canned or jarred organic american persimmon",
        "code": "50375501"
      },
      {
        "name": "Canned or jarred organic black sapote persimmon",
        "code": "50375502"
      },
      {
        "name": "Canned or jarred organic chapote/black persimmon",
        "code": "50375503"
      },
      {
        "name": "Canned or jarred organic date plum persimmon",
        "code": "50375504"
      },
      {
        "name": "Canned or jarred organic fuyu persimmons",
        "code": "50375505"
      },
      {
        "name": "Canned or jarred organic giant fuyu persimmons",
        "code": "50375506"
      },
      {
        "name": "Canned or jarred organic hachiya persimmons",
        "code": "50375507"
      },
      {
        "name": "Canned or jarred organic mabolo/butter fruit persimmon",
        "code": "50375508"
      },
      {
        "name": "Canned or jarred organic principe ito persimmons",
        "code": "50375509"
      },
      {
        "name": "Canned or jarred organic royal brillante persimmons",
        "code": "50375510"
      },
      {
        "name": "Canned or jarred organic sharon fruit persimmon",
        "code": "50375511"
      },
      {
        "name": "Canned or jarred organic triumph persimmons",
        "code": "50375512"
      },
      { "name": "Canned or jarred organic pineapples", "code": "50375600" },
      {
        "name": "Canned or jarred organic cherimoya pineapple",
        "code": "50375601"
      },
      {
        "name": "Canned or jarred organic golden pineapple",
        "code": "50375602"
      },
      { "name": "Canned or jarred organic hilo pineapple", "code": "50375603" },
      {
        "name": "Canned or jarred organic kona sugarloaf pineapple",
        "code": "50375604"
      },
      {
        "name": "Canned or jarred organic natal queen pineapple",
        "code": "50375605"
      },
      {
        "name": "Canned or jarred organic pernambuco pineapple",
        "code": "50375606"
      },
      {
        "name": "Canned or jarred organic red spanish pineapple",
        "code": "50375607"
      },
      {
        "name": "Canned or jarred organic smooth cayenne pineapple",
        "code": "50375608"
      },
      {
        "name": "Canned or jarred organic sugarloaf pineapple",
        "code": "50375609"
      },
      {
        "name": "Canned or jarred organic variegated pineapple",
        "code": "50375610"
      },
      { "name": "Canned or jarred organic plucots", "code": "50375700" },
      {
        "name": "Canned or jarred organic black kat plucot",
        "code": "50375701"
      },
      {
        "name": "Canned or jarred organic blue gusto plucot",
        "code": "50375702"
      },
      {
        "name": "Canned or jarred organic crimson heart plucot",
        "code": "50375703"
      },
      {
        "name": "Canned or jarred organic dapple dandy plucot",
        "code": "50375704"
      },
      {
        "name": "Canned or jarred organic dapple fire plucot",
        "code": "50375705"
      },
      {
        "name": "Canned or jarred organic early dapple plucot",
        "code": "50375706"
      },
      {
        "name": "Canned or jarred organic flavor fall plucot",
        "code": "50375707"
      },
      {
        "name": "Canned or jarred organic flavor gold plucot",
        "code": "50375708"
      },
      {
        "name": "Canned or jarred organic flavor grenade plucot",
        "code": "50375709"
      },
      {
        "name": "Canned or jarred organic flavor heart plucot",
        "code": "50375710"
      },
      {
        "name": "Canned or jarred organic flavor jewel plucot",
        "code": "50375711"
      },
      {
        "name": "Canned or jarred organic flavor king plucot",
        "code": "50375712"
      },
      {
        "name": "Canned or jarred organic flavor queen plucot",
        "code": "50375713"
      },
      {
        "name": "Canned or jarred organic flavor supreme plucot",
        "code": "50375714"
      },
      {
        "name": "Canned or jarred organic flavor treat plucot",
        "code": "50375715"
      },
      {
        "name": "Canned or jarred organic flavorella plucot",
        "code": "50375716"
      },
      {
        "name": "Canned or jarred organic flavorich plucot",
        "code": "50375717"
      },
      {
        "name": "Canned or jarred organic flavorosa plucot",
        "code": "50375718"
      },
      {
        "name": "Canned or jarred organic geo pride plucot",
        "code": "50375719"
      },
      { "name": "Canned or jarred organic red kat plucot", "code": "50375720" },
      {
        "name": "Canned or jarred organic royal treat plucot",
        "code": "50375721"
      },
      {
        "name": "Canned or jarred organic sierra rose plucot",
        "code": "50375722"
      },
      {
        "name": "Canned or jarred organic sweet geisha plucot",
        "code": "50375723"
      },
      { "name": "Canned or jarred organic plums", "code": "50375800" },
      {
        "name": "Canned or jarred organic amber jewel plums",
        "code": "50375801"
      },
      { "name": "Canned or jarred organic angeleno plums", "code": "50375802" },
      { "name": "Canned or jarred organic aurora plums", "code": "50375803" },
      {
        "name": "Canned or jarred organic autumn beaut plums",
        "code": "50375804"
      },
      {
        "name": "Canned or jarred organic autumn giant plums",
        "code": "50375805"
      },
      {
        "name": "Canned or jarred organic autumn pride plums",
        "code": "50375806"
      },
      {
        "name": "Canned or jarred organic autumn rosa plums",
        "code": "50375807"
      },
      { "name": "Canned or jarred organic beach plum", "code": "50375808" },
      {
        "name": "Canned or jarred organic betty anne plums",
        "code": "50375809"
      },
      {
        "name": "Canned or jarred organic black beaut plums",
        "code": "50375810"
      },
      {
        "name": "Canned or jarred organic black bullace plum",
        "code": "50375811"
      },
      {
        "name": "Canned or jarred organic black diamond plums",
        "code": "50375812"
      },
      {
        "name": "Canned or jarred organic black giant plums",
        "code": "50375813"
      },
      {
        "name": "Canned or jarred organic black ice plums",
        "code": "50375814"
      },
      {
        "name": "Canned or jarred organic black splendor plums",
        "code": "50375815"
      },
      {
        "name": "Canned or jarred organic blackamber plums",
        "code": "50375816"
      },
      { "name": "Canned or jarred organic burgundy plums", "code": "50375817" },
      { "name": "Canned or jarred organic carlsbad plum", "code": "50375818" },
      {
        "name": "Canned or jarred organic casselman plums",
        "code": "50375819"
      },
      { "name": "Canned or jarred organic catalina plums", "code": "50375820" },
      { "name": "Canned or jarred organic damson plum", "code": "50375821" },
      { "name": "Canned or jarred organic dolly plums", "code": "50375822" },
      {
        "name": "Canned or jarred organic earliqueen plums",
        "code": "50375823"
      },
      {
        "name": "Canned or jarred organic early rosa plums",
        "code": "50375824"
      },
      {
        "name": "Canned or jarred organic ebony may plums",
        "code": "50375825"
      },
      { "name": "Canned or jarred organic ebony plums", "code": "50375826" },
      {
        "name": "Canned or jarred organic elephant heart plums",
        "code": "50375827"
      },
      {
        "name": "Canned or jarred organic emerald beaut plums",
        "code": "50375828"
      },
      { "name": "Canned or jarred organic empress plums", "code": "50375829" },
      { "name": "Canned or jarred organic freedom plums", "code": "50375830" },
      { "name": "Canned or jarred organic friar plums", "code": "50375831" },
      { "name": "Canned or jarred organic gar red plums", "code": "50375832" },
      {
        "name": "Canned or jarred organic governor's plum",
        "code": "50375833"
      },
      {
        "name": "Canned or jarred organic grand rosa plums",
        "code": "50375834"
      },
      {
        "name": "Canned or jarred organic green gage plum",
        "code": "50375835"
      },
      {
        "name": "Canned or jarred organic greengage plums",
        "code": "50375836"
      },
      { "name": "Canned or jarred organic hiromi plums", "code": "50375837" },
      {
        "name": "Canned or jarred organic hiromi red plums",
        "code": "50375838"
      },
      { "name": "Canned or jarred organic holiday plums", "code": "50375839" },
      {
        "name": "Canned or jarred organic howard sun plums",
        "code": "50375840"
      },
      {
        "name": "Canned or jarred organic interspecific type plums",
        "code": "50375841"
      },
      { "name": "Canned or jarred organic jamaican plum", "code": "50375842" },
      {
        "name": "Canned or jarred organic joanna red plums",
        "code": "50375843"
      },
      { "name": "Canned or jarred organic kelsey plums", "code": "50375844" },
      {
        "name": "Canned or jarred organic king james plums",
        "code": "50375845"
      },
      { "name": "Canned or jarred organic laroda plums", "code": "50375846" },
      {
        "name": "Canned or jarred organic late rosa plums",
        "code": "50375847"
      },
      {
        "name": "Canned or jarred organic linda rosa plums",
        "code": "50375848"
      },
      {
        "name": "Canned or jarred organic lone star red plums",
        "code": "50375849"
      },
      { "name": "Canned or jarred organic mariposa plums", "code": "50375850" },
      {
        "name": "Canned or jarred organic marked black plums",
        "code": "50375851"
      },
      {
        "name": "Canned or jarred organic marked red plums",
        "code": "50375852"
      },
      { "name": "Canned or jarred organic mirabelle plum", "code": "50375853" },
      {
        "name": "Canned or jarred organic october sun plums",
        "code": "50375854"
      },
      { "name": "Canned or jarred organic owen t plums", "code": "50375855" },
      { "name": "Canned or jarred organic perdrigon plum", "code": "50375856" },
      {
        "name": "Canned or jarred organic pink delight plums",
        "code": "50375857"
      },
      {
        "name": "Canned or jarred organic president plums",
        "code": "50375858"
      },
      {
        "name": "Canned or jarred organic primetime plums",
        "code": "50375859"
      },
      {
        "name": "Canned or jarred organic purple majesty plums",
        "code": "50375860"
      },
      {
        "name": "Canned or jarred organic queen rosa plums",
        "code": "50375861"
      },
      { "name": "Canned or jarred organic quetsch plum", "code": "50375862" },
      {
        "name": "Canned or jarred organic red beaut plums",
        "code": "50375863"
      },
      { "name": "Canned or jarred organic red lane plums", "code": "50375864" },
      { "name": "Canned or jarred organic red ram plums", "code": "50375865" },
      { "name": "Canned or jarred organic red rosa plums", "code": "50375866" },
      { "name": "Canned or jarred organic rich red plums", "code": "50375867" },
      { "name": "Canned or jarred organic rosemary plums", "code": "50375868" },
      {
        "name": "Canned or jarred organic royal diamond plums",
        "code": "50375869"
      },
      {
        "name": "Canned or jarred organic royal red plums",
        "code": "50375870"
      },
      {
        "name": "Canned or jarred organic royal zee plums",
        "code": "50375871"
      },
      { "name": "Canned or jarred organic roysum plums", "code": "50375872" },
      {
        "name": "Canned or jarred organic santa rosa plums",
        "code": "50375873"
      },
      { "name": "Canned or jarred organic saphire plums", "code": "50375874" },
      { "name": "Canned or jarred organic sloe plum", "code": "50375875" },
      {
        "name": "Canned or jarred organic st catherine plum",
        "code": "50375876"
      },
      {
        "name": "Canned or jarred organic white bullace plum",
        "code": "50375877"
      },
      { "name": "Canned or jarred organic pomegranates", "code": "50375900" },
      {
        "name": "Canned or jarred organic foothill pomegranates",
        "code": "50375901"
      },
      {
        "name": "Canned or jarred organic granada pomegranates",
        "code": "50375902"
      },
      {
        "name": "Canned or jarred organic jolly red pomegranates",
        "code": "50375903"
      },
      {
        "name": "Canned or jarred organic nana pomegranates",
        "code": "50375904"
      },
      {
        "name": "Canned or jarred organic pat's red pomegranates",
        "code": "50375905"
      },
      {
        "name": "Canned or jarred organic pinkhan pomegranates",
        "code": "50375906"
      },
      {
        "name": "Canned or jarred organic purple velvet pomegranates",
        "code": "50375907"
      },
      {
        "name": "Canned or jarred organic wonderful pomegranates",
        "code": "50375908"
      },
      { "name": "Canned or jarred organic pomelos", "code": "50376000" },
      {
        "name": "Canned or jarred organic chandler pomelo",
        "code": "50376001"
      },
      {
        "name": "Canned or jarred organic hirado buntan pomelo",
        "code": "50376002"
      },
      {
        "name": "Canned or jarred organic liang ping yau pomelo",
        "code": "50376003"
      },
      {
        "name": "Canned or jarred organic pandan wangi pomelo",
        "code": "50376004"
      },
      { "name": "Canned or jarred organic pink pomelo", "code": "50376005" },
      {
        "name": "Canned or jarred organic red shaddock pomelo",
        "code": "50376006"
      },
      {
        "name": "Canned or jarred organic siamese sweet pomelo",
        "code": "50376007"
      },
      {
        "name": "Canned or jarred organic wainwright pomelo",
        "code": "50376008"
      },
      { "name": "Canned or jarred organic quinces", "code": "50376100" },
      {
        "name": "Canned or jarred organic champion quince",
        "code": "50376101"
      },
      {
        "name": "Canned or jarred organic pineapple quince",
        "code": "50376102"
      },
      { "name": "Canned or jarred organic smyrna quince", "code": "50376103" },
      { "name": "Canned or jarred organic raspberries", "code": "50376200" },
      {
        "name": "Canned or jarred organic american red raspberry",
        "code": "50376201"
      },
      {
        "name": "Canned or jarred organic bailey queensland raspberry",
        "code": "50376202"
      },
      {
        "name": "Canned or jarred organic black raspberry",
        "code": "50376203"
      },
      { "name": "Canned or jarred organic dark raspberry", "code": "50376204" },
      {
        "name": "Canned or jarred organic delicious raspberry",
        "code": "50376205"
      },
      {
        "name": "Canned or jarred organic focke dwarf raspberry",
        "code": "50376206"
      },
      {
        "name": "Canned or jarred organic focke grayleaf red raspberry",
        "code": "50376207"
      },
      {
        "name": "Canned or jarred organic focke strawberry raspberry",
        "code": "50376208"
      },
      {
        "name": "Canned or jarred organic focke yellow himalayan raspberry",
        "code": "50376209"
      },
      { "name": "Canned or jarred organic gold raspberry", "code": "50376210" },
      {
        "name": "Canned or jarred organic gray new mexico raspberry",
        "code": "50376211"
      },
      {
        "name": "Canned or jarred organic jepson whitebark raspberry",
        "code": "50376212"
      },
      {
        "name": "Canned or jarred organic kellogg san diego raspberry",
        "code": "50376213"
      },
      {
        "name": "Canned or jarred organic leucodermis whitebark raspberry",
        "code": "50376214"
      },
      {
        "name": "Canned or jarred organic munz cuyamaca raspberry",
        "code": "50376215"
      },
      {
        "name": "Canned or jarred organic peck barton's raspberry",
        "code": "50376216"
      },
      {
        "name": "Canned or jarred organic purpleflowering raspberry",
        "code": "50376217"
      },
      {
        "name": "Canned or jarred organic roadside raspberry",
        "code": "50376218"
      },
      {
        "name": "Canned or jarred organic san diego raspberry",
        "code": "50376219"
      },
      { "name": "Canned or jarred organic snow raspberry", "code": "50376220" },
      {
        "name": "Canned or jarred organic snowpeaks raspberry",
        "code": "50376221"
      },
      {
        "name": "Canned or jarred organic strawberryleaf raspberry",
        "code": "50376222"
      },
      {
        "name": "Canned or jarred organic sweet cultivated raspberry",
        "code": "50376223"
      },
      {
        "name": "Canned or jarred organic torr and gray whitebark raspberry",
        "code": "50376224"
      },
      {
        "name": "Canned or jarred organic west indian raspberry",
        "code": "50376225"
      },
      {
        "name": "Canned or jarred organic whitebark raspberry",
        "code": "50376226"
      },
      { "name": "Canned or jarred organic wine raspberry", "code": "50376227" },
      {
        "name": "Canned or jarred organic yellow himalayan raspberry",
        "code": "50376228"
      },
      {
        "name": "Canned or jarred organic yu-shan raspberry",
        "code": "50376229"
      },
      { "name": "Canned or jarred organic rhubarb", "code": "50376300" },
      {
        "name": "Canned or jarred organic crimson red rhubarb",
        "code": "50376301"
      },
      {
        "name": "Canned or jarred organic early champagne rhubarb",
        "code": "50376302"
      },
      {
        "name": "Canned or jarred organic glaskin's perpetual rhubarb",
        "code": "50376303"
      },
      { "name": "Canned or jarred organic sutton rhubarb", "code": "50376304" },
      {
        "name": "Canned or jarred organic timperley early rhubarb",
        "code": "50376305"
      },
      {
        "name": "Canned or jarred organic valentine rhubarb",
        "code": "50376306"
      },
      {
        "name": "Canned or jarred organic victoria rhubarb",
        "code": "50376307"
      },
      {
        "name": "Canned or jarred organic zwolle seedling rhubarb",
        "code": "50376308"
      },
      {
        "name": "Canned or jarred organic macdonald rhubarb",
        "code": "50376309"
      },
      { "name": "Canned or jarred organic tilden rhubarb", "code": "50376310" },
      { "name": "Canned or jarred organic rose hips", "code": "50376400" },
      {
        "name": "Canned or jarred organic brier rose hips",
        "code": "50376401"
      },
      {
        "name": "Canned or jarred organic elgantine rose hips",
        "code": "50376402"
      },
      {
        "name": "Canned or jarred organic rugosa rose hips",
        "code": "50376403"
      },
      {
        "name": "Canned or jarred organic scotch or burnet rose hips",
        "code": "50376404"
      },
      { "name": "Canned or jarred organic sapotes", "code": "50376500" },
      { "name": "Canned or jarred organic white sapotes", "code": "50376501" },
      { "name": "Canned or jarred organic black sapotes", "code": "50376502" },
      {
        "name": "Canned or jarred organic saskatoon berries",
        "code": "50376600"
      },
      {
        "name": "Canned or jarred organic honeywood saskatoon berries",
        "code": "50376601"
      },
      {
        "name": "Canned or jarred organic northline saskatoon berries",
        "code": "50376602"
      },
      {
        "name": "Canned or jarred organic smoky saskatoon berries",
        "code": "50376603"
      },
      {
        "name": "Canned or jarred organic thiessen saskatoon berries",
        "code": "50376604"
      },
      { "name": "Canned or jarred organic strawberries", "code": "50376700" },
      {
        "name": "Canned or jarred organic chandler strawberries",
        "code": "50376701"
      },
      {
        "name": "Canned or jarred organic june bearing strawberries",
        "code": "50376702"
      },
      {
        "name": "Canned or jarred organic ever bearing strawberries",
        "code": "50376703"
      },
      { "name": "Canned or jarred organic sugar apple", "code": "50376800" },
      {
        "name": "Canned or jarred organic kampong mauve sugar apple",
        "code": "50376801"
      },
      {
        "name": "Canned or jarred organic seedless sugar apple",
        "code": "50376802"
      },
      {
        "name": "Canned or jarred organic thai lessard sugar apple",
        "code": "50376803"
      },
      { "name": "Canned or jarred organic tamarillo", "code": "50376900" },
      {
        "name": "Canned or jarred organic amberlea gold tamarillo",
        "code": "50376901"
      },
      {
        "name": "Canned or jarred organic bold gold tamarillo",
        "code": "50376902"
      },
      {
        "name": "Canned or jarred organic goldmine tamarillo",
        "code": "50376903"
      },
      {
        "name": "Canned or jarred organic oratia red tamarillo",
        "code": "50376904"
      },
      {
        "name": "Canned or jarred organic red beau tamarillo",
        "code": "50376905"
      },
      {
        "name": "Canned or jarred organic red delight tamarillo",
        "code": "50376906"
      },
      {
        "name": "Canned or jarred organic nominant fruits",
        "code": "50377000"
      },
      { "name": "Canned or jarred organic akee", "code": "50377001" },
      { "name": "Canned or jarred organic babaco", "code": "50377002" },
      { "name": "Canned or jarred organic banana flowers", "code": "50377003" },
      { "name": "Canned or jarred organic baobab", "code": "50377004" },
      { "name": "Canned or jarred organic bitter oranges", "code": "50377005" },
      { "name": "Canned or jarred organic canistel", "code": "50377006" },
      { "name": "Canned or jarred organic coconuts", "code": "50377007" },
      { "name": "Canned or jarred organic cloudberries", "code": "50377008" },
      { "name": "Canned or jarred organic dewberries", "code": "50377009" },
      { "name": "Canned or jarred organic durian", "code": "50377010" },
      { "name": "Canned or jarred organic elderberries", "code": "50377011" },
      { "name": "Canned or jarred organic feijoa", "code": "50377012" },
      { "name": "Canned or jarred organic hackberries", "code": "50377013" },
      { "name": "Canned or jarred organic hawthorn", "code": "50377014" },
      { "name": "Canned or jarred organic honeyberries", "code": "50377015" },
      { "name": "Canned or jarred organic jackfruit", "code": "50377016" },
      { "name": "Canned or jarred organic jambolan", "code": "50377017" },
      { "name": "Canned or jarred organic jujube", "code": "50377018" },
      { "name": "Canned or jarred organic lychee", "code": "50377019" },
      { "name": "Canned or jarred organic mangosteens", "code": "50377020" },
      { "name": "Canned or jarred organic medlars", "code": "50377021" },
      { "name": "Canned or jarred organic mombins", "code": "50377022" },
      { "name": "Canned or jarred organic monstera", "code": "50377023" },
      { "name": "Canned or jarred organic pepinos", "code": "50377024" },
      { "name": "Canned or jarred organic plantains", "code": "50377025" },
      { "name": "Canned or jarred organic prickly pears", "code": "50377026" },
      { "name": "Canned or jarred organic quenepas", "code": "50377027" },
      { "name": "Canned or jarred organic rambutan", "code": "50377028" },
      { "name": "Canned or jarred organic rose apples", "code": "50377029" },
      { "name": "Canned or jarred organic roselle", "code": "50377030" },
      { "name": "Canned or jarred organic rowanberries", "code": "50377031" },
      {
        "name": "Canned or jarred organic sea buckhorn berries",
        "code": "50377032"
      },
      { "name": "Canned or jarred organic silverberries", "code": "50377033" },
      { "name": "Canned or jarred organic sorb berries", "code": "50377034" },
      { "name": "Canned or jarred organic soursops", "code": "50377035" },
      { "name": "Canned or jarred organic star apples", "code": "50377036" },
      { "name": "Canned or jarred organic tamarindo", "code": "50377037" },
      { "name": "Canned or jarred organic chokeberries", "code": "50377100" },
      {
        "name": "Canned or jarred organic autumn magic chokeberries",
        "code": "50377101"
      },
      {
        "name": "Canned or jarred organic brillantisima chokeberries",
        "code": "50377102"
      },
      {
        "name": "Canned or jarred organic nero chokeberries",
        "code": "50377103"
      },
      {
        "name": "Canned or jarred organic viking chokeberries",
        "code": "50377104"
      },
      { "name": "Canned or jarred organic olives", "code": "50377200" },
      {
        "name": "Canned or jarred organic agrinion olives",
        "code": "50377201"
      },
      { "name": "Canned or jarred organic aleppo olives", "code": "50377202" },
      {
        "name": "Canned or jarred organic alphonso olives",
        "code": "50377203"
      },
      {
        "name": "Canned or jarred organic amphissa olives",
        "code": "50377204"
      },
      { "name": "Canned or jarred organic arauco olives", "code": "50377205" },
      {
        "name": "Canned or jarred organic arbequina olives",
        "code": "50377206"
      },
      {
        "name": "Canned or jarred organic atalanta olives",
        "code": "50377207"
      },
      {
        "name": "Canned or jarred organic cerignola olives",
        "code": "50377208"
      },
      {
        "name": "Canned or jarred organic cracked provencal olives",
        "code": "50377209"
      },
      {
        "name": "Canned or jarred organic empeltre olives",
        "code": "50377210"
      },
      { "name": "Canned or jarred organic gaeta olives", "code": "50377211" },
      {
        "name": "Canned or jarred organic hondroelia olives",
        "code": "50377212"
      },
      {
        "name": "Canned or jarred organic kalamata olives",
        "code": "50377213"
      },
      { "name": "Canned or jarred organic kura olives", "code": "50377214" },
      {
        "name": "Canned or jarred organic ligurian olives",
        "code": "50377215"
      },
      { "name": "Canned or jarred organic lucque olives", "code": "50377216" },
      { "name": "Canned or jarred organic lugano olives", "code": "50377217" },
      {
        "name": "Canned or jarred organic manzanilla olives",
        "code": "50377218"
      },
      { "name": "Canned or jarred organic marche olives", "code": "50377219" },
      { "name": "Canned or jarred organic mission olives", "code": "50377220" },
      {
        "name": "Canned or jarred organic nafplion green olives",
        "code": "50377221"
      },
      { "name": "Canned or jarred organic nicoise olives", "code": "50377222" },
      { "name": "Canned or jarred organic nyons olives", "code": "50377223" },
      {
        "name": "Canned or jarred organic picholine olives",
        "code": "50377224"
      },
      {
        "name": "Canned or jarred organic ponentine olives",
        "code": "50377225"
      },
      { "name": "Canned or jarred organic royal olives", "code": "50377226" },
      {
        "name": "Canned or jarred organic seracena olives",
        "code": "50377227"
      },
      {
        "name": "Canned or jarred organic sevillano olives",
        "code": "50377228"
      },
      {
        "name": "Canned or jarred organic sicilian olives",
        "code": "50377229"
      },
      {
        "name": "Canned or jarred organic toscanelle olives",
        "code": "50377230"
      },
      { "name": "Fresh fruit purees", "code": "50380000" },
      { "name": "Apple purees", "code": "50381900" },
      { "name": "Akane apple purees", "code": "50381901" },
      { "name": "Ambrosia apple purees", "code": "50381902" },
      { "name": "Api apple purees", "code": "50381903" },
      { "name": "Baldwin apple purees", "code": "50381904" },
      { "name": "Braeburn apple purees", "code": "50381905" },
      { "name": "Bramley apple purees", "code": "50381906" },
      { "name": "Bramley seedling apple purees", "code": "50381907" },
      { "name": "Calville blanche d'hiver apple purees", "code": "50381908" },
      { "name": "Cameo apple purees", "code": "50381909" },
      { "name": "Charles ross apple purees", "code": "50381910" },
      { "name": "Codlin apple purees", "code": "50381911" },
      { "name": "Cortland apple purees", "code": "50381912" },
      { "name": "Costard apple purees", "code": "50381913" },
      { "name": "Court pendu plat apple purees", "code": "50381914" },
      { "name": "Cox's orange pippin apple purees", "code": "50381915" },
      { "name": "Crab apple purees", "code": "50381916" },
      { "name": "Crispin apple purees", "code": "50381917" },
      { "name": "Delicious apple purees", "code": "50381918" },
      { "name": "Duchess apple purees", "code": "50381919" },
      { "name": "Earligold apple purees", "code": "50381920" },
      { "name": "Early mcintosh apple purees", "code": "50381921" },
      { "name": "Elstar apple purees", "code": "50381922" },
      { "name": "Empire apple purees", "code": "50381923" },
      { "name": "Flower of kent apple purees", "code": "50381924" },
      { "name": "Fuji apple purees", "code": "50381925" },
      { "name": "Gala apple purees", "code": "50381926" },
      { "name": "Gascoyne's scarlet apple purees", "code": "50381927" },
      { "name": "Gilliflower apple purees", "code": "50381928" },
      { "name": "Ginger gold apple purees", "code": "50381929" },
      { "name": "Gladstone apple purees", "code": "50381930" },
      { "name": "Gloster apple purees", "code": "50381931" },
      { "name": "Gold supreme apple purees", "code": "50381932" },
      { "name": "Golden delicious apple purees", "code": "50381933" },
      { "name": "Golden noble apple purees", "code": "50381934" },
      { "name": "Granny smith apple purees", "code": "50381935" },
      { "name": "Gravenstein apple purees", "code": "50381936" },
      { "name": "Greening apple purees", "code": "50381937" },
      { "name": "Greensleeves apple purees", "code": "50381938" },
      { "name": "Honeycrisp apple purees", "code": "50381939" },
      { "name": "Howgate wonder apple purees", "code": "50381940" },
      { "name": "Ida red apple purees", "code": "50381941" },
      { "name": "James grieve apple purees", "code": "50381942" },
      { "name": "Jersey mac apple purees", "code": "50381943" },
      { "name": "Jester apple purees", "code": "50381944" },
      { "name": "Jonagold apple purees", "code": "50381945" },
      { "name": "Jonamac apple purees", "code": "50381946" },
      { "name": "Jonathan apple purees", "code": "50381947" },
      { "name": "Katy apple purees", "code": "50381948" },
      { "name": "Kidd's orange red apple purees", "code": "50381949" },
      { "name": "Lady apple purees", "code": "50381950" },
      { "name": "Law rome apple purees", "code": "50381951" },
      { "name": "Laxton apple purees", "code": "50381952" },
      { "name": "Lord derby apple purees", "code": "50381953" },
      { "name": "Macoun apple purees", "code": "50381954" },
      { "name": "Mcintosh apple purees", "code": "50381955" },
      { "name": "Mutsu apple purees", "code": "50381956" },
      { "name": "Newtown pippin apple purees", "code": "50381957" },
      { "name": "Northern spy apple purees", "code": "50381958" },
      { "name": "Orleans reinette apple purees", "code": "50381959" },
      { "name": "Ozark gold apple purees", "code": "50381960" },
      { "name": "Pacific rose apple purees", "code": "50381961" },
      { "name": "Paula red apple purees", "code": "50381962" },
      { "name": "Pearmain apple purees", "code": "50381963" },
      { "name": "Pink lady apple purees", "code": "50381964" },
      { "name": "Pippin apple purees", "code": "50381965" },
      { "name": "Pitmaston pineapple apple purees", "code": "50381966" },
      { "name": "Pomme d'api apple purees", "code": "50381967" },
      { "name": "Prime gold apple purees", "code": "50381968" },
      { "name": "Red astrachan apple purees", "code": "50381969" },
      { "name": "Red boscoop apple purees", "code": "50381970" },
      { "name": "Red chief apple purees", "code": "50381971" },
      { "name": "Red delicious apple purees", "code": "50381972" },
      { "name": "Red gravenstein apple purees", "code": "50381973" },
      { "name": "Red rome apple purees", "code": "50381974" },
      { "name": "Red stayman apple purees", "code": "50381975" },
      { "name": "Red york apple purees", "code": "50381976" },
      { "name": "Reinette apple purees", "code": "50381977" },
      { "name": "Rome beauty apple purees", "code": "50381978" },
      { "name": "Russet apple purees", "code": "50381979" },
      { "name": "Sierra beauty apple purees", "code": "50381980" },
      { "name": "Spartan apple purees", "code": "50381981" },
      { "name": "Stark crimson apple purees", "code": "50381982" },
      { "name": "Starking apple purees", "code": "50381983" },
      { "name": "Stayman apple purees", "code": "50381984" },
      { "name": "Stayman winesap apple purees", "code": "50381985" },
      { "name": "Summer rambo apple purees", "code": "50381986" },
      { "name": "Tsugaru apple purees", "code": "50381987" },
      { "name": "Twenty ounce apple purees", "code": "50381988" },
      { "name": "Tydeman red apple purees", "code": "50381989" },
      { "name": "Vistabella apple purees", "code": "50381990" },
      { "name": "Wealthy apple purees", "code": "50381991" },
      { "name": "White joaneting apple purees", "code": "50381992" },
      { "name": "White transparent apple purees", "code": "50381993" },
      { "name": "Winesap apple purees", "code": "50381994" },
      { "name": "Worcester apple purees", "code": "50381995" },
      { "name": "York imperial apple purees", "code": "50381996" },
      { "name": "Anna apple purees", "code": "50381997" },
      { "name": "Winter apple purees", "code": "50381998" },
      { "name": "Pear apple purees", "code": "50381999" },
      { "name": "Apricot purees", "code": "50382000" },
      { "name": "Ambercot apricot purees", "code": "50382001" },
      { "name": "Apache apricot purees", "code": "50382002" },
      { "name": "Brittany gold apricot purees", "code": "50382003" },
      { "name": "Black apricot purees", "code": "50382004" },
      { "name": "Blenheim apricot purees", "code": "50382005" },
      { "name": "Bonny apricot purees", "code": "50382006" },
      { "name": "Bulida apricot purees", "code": "50382007" },
      { "name": "Castlebrite apricot purees", "code": "50382008" },
      { "name": "Clutha gold apricot purees", "code": "50382009" },
      { "name": "Clutha sun apricot purees", "code": "50382010" },
      { "name": "Darby royal apricot purees", "code": "50382011" },
      { "name": "Dina apricot purees", "code": "50382012" },
      { "name": "Earlicot apricot purees", "code": "50382013" },
      { "name": "Earliman apricot purees", "code": "50382014" },
      { "name": "Early bright apricot purees", "code": "50382015" },
      { "name": "Flaming gold apricot purees", "code": "50382016" },
      { "name": "Fresno apricot purees", "code": "50382017" },
      { "name": "Gold brite apricot purees", "code": "50382018" },
      { "name": "Goldbar apricot purees", "code": "50382019" },
      { "name": "Golden sweet apricot purees", "code": "50382020" },
      { "name": "Goldrich apricot purees", "code": "50382021" },
      { "name": "Helena apricot purees", "code": "50382022" },
      { "name": "Honeycot apricot purees", "code": "50382023" },
      { "name": "Imperial apricot purees", "code": "50382024" },
      { "name": "Jordanne apricot purees", "code": "50382025" },
      { "name": "Jumbo cot apricot purees", "code": "50382026" },
      { "name": "Kandy kot apricot purees", "code": "50382027" },
      { "name": "Katy apricot purees", "code": "50382028" },
      { "name": "King apricot purees", "code": "50382029" },
      { "name": "Lambertin apricot purees", "code": "50382030" },
      { "name": "Lorna apricot purees", "code": "50382031" },
      { "name": "Lulu belle apricot purees", "code": "50382032" },
      { "name": "Modesto apricot purees", "code": "50382033" },
      { "name": "Moorpark apricot purees", "code": "50382034" },
      { "name": "Orangered apricot purees", "code": "50382035" },
      { "name": "Palstein apricot purees", "code": "50382036" },
      { "name": "Patterson apricot purees", "code": "50382037" },
      { "name": "Perfection apricot purees", "code": "50382038" },
      { "name": "Poppy apricot purees", "code": "50382039" },
      { "name": "Poppycot apricot purees", "code": "50382040" },
      { "name": "Queen apricot purees", "code": "50382041" },
      { "name": "Riland apricot purees", "code": "50382042" },
      { "name": "Rival apricot purees", "code": "50382043" },
      { "name": "Robada apricot purees", "code": "50382044" },
      { "name": "Royal apricot purees", "code": "50382045" },
      { "name": "Royal blenheim apricot purees", "code": "50382046" },
      { "name": "Royal orange apricot purees", "code": "50382047" },
      { "name": "Sundrop apricot purees", "code": "50382048" },
      { "name": "Tilton apricot purees", "code": "50382049" },
      { "name": "Tomcot apricot purees", "code": "50382050" },
      { "name": "Tracy apricot purees", "code": "50382051" },
      { "name": "Tri gem apricot purees", "code": "50382052" },
      { "name": "Valley gold apricot purees", "code": "50382053" },
      { "name": "Westley apricot purees", "code": "50382054" },
      { "name": "York apricot purees", "code": "50382055" },
      { "name": "Banana purees", "code": "50382100" },
      { "name": "Apple banana purees", "code": "50382101" },
      { "name": "Baby banana purees", "code": "50382102" },
      { "name": "Burro banana purees", "code": "50382103" },
      { "name": "Cavendish banana purees", "code": "50382104" },
      { "name": "Dominico banana purees", "code": "50382105" },
      { "name": "Green banana purees", "code": "50382106" },
      { "name": "Gros michel banana purees", "code": "50382107" },
      { "name": "Lacatan banana purees", "code": "50382108" },
      { "name": "Lady finger banana purees", "code": "50382109" },
      { "name": "Manzano banana purees", "code": "50382110" },
      { "name": "Mysore banana purees", "code": "50382111" },
      { "name": "Pisang mas banana purees", "code": "50382112" },
      { "name": "Red banana purees", "code": "50382113" },
      { "name": "Saba banana purees", "code": "50382114" },
      { "name": "Sucrier banana purees", "code": "50382115" },
      { "name": "Palillo banana purees", "code": "50382116" },
      { "name": "Purple banana purees", "code": "50382117" },
      { "name": "Isla banana purees", "code": "50382118" },
      { "name": "Bizcocho banana purees", "code": "50382119" },
      { "name": "Barberry purees", "code": "50382200" },
      { "name": "Paleleaf barberry purees", "code": "50382201" },
      { "name": "Chenault barberry purees", "code": "50382202" },
      { "name": "Red barberry purees", "code": "50382203" },
      { "name": "Wintergreen barberry purees", "code": "50382204" },
      { "name": "Korean barberry purees", "code": "50382205" },
      { "name": "Mentor barberry purees", "code": "50382206" },
      { "name": "Japanese barberry purees", "code": "50382207" },
      { "name": "Atropurpurea barberry purees", "code": "50382208" },
      { "name": "Aurea barberry purees", "code": "50382209" },
      { "name": "Bagatelle barberry purees", "code": "50382210" },
      { "name": "Crimson pygmy barberry purees", "code": "50382211" },
      { "name": "Kobold barberry purees", "code": "50382212" },
      { "name": "Warty barberry purees", "code": "50382213" },
      { "name": "European barberry purees", "code": "50382214" },
      { "name": "Bearberry purees", "code": "50382300" },
      { "name": "Alpine bearberry purees", "code": "50382301" },
      { "name": "Red bearberry purees", "code": "50382302" },
      { "name": "Common bearberry purees", "code": "50382303" },
      { "name": "Blackberry purees", "code": "50382400" },
      { "name": "Apache blackberry purees", "code": "50382401" },
      { "name": "Black satin blackberry purees", "code": "50382402" },
      { "name": "Boysenberry purees", "code": "50382403" },
      { "name": "Cherokee blackberry purees", "code": "50382404" },
      { "name": "Chester blackberry purees", "code": "50382405" },
      { "name": "Dirksen blackberry purees", "code": "50382406" },
      { "name": "Jostaberry purees", "code": "50382407" },
      { "name": "Loganberry purees", "code": "50382408" },
      { "name": "Marionberry purees", "code": "50382409" },
      { "name": "Navaho blackberry purees", "code": "50382410" },
      { "name": "Nectarberry purees", "code": "50382411" },
      { "name": "Olallie blackberry purees", "code": "50382412" },
      { "name": "Tayberry purees", "code": "50382413" },
      { "name": "Thornless hull blackberry purees", "code": "50382414" },
      { "name": "Youngberry purees", "code": "50382415" },
      { "name": "Bilberry purees", "code": "50382500" },
      { "name": "Bog bilberry purees", "code": "50382501" },
      { "name": "Dwarf bilberry purees", "code": "50382502" },
      { "name": "Mountain bilberry purees", "code": "50382503" },
      { "name": "Oval-leaved bilberry purees", "code": "50382504" },
      { "name": "Blueberry purees", "code": "50382600" },
      { "name": "Bluecrop blueberry purees", "code": "50382601" },
      { "name": "Bluetta blueberry purees", "code": "50382602" },
      { "name": "Brigitta blueberry purees", "code": "50382603" },
      { "name": "Chandler blueberry purees", "code": "50382604" },
      { "name": "Duke blueberry purees", "code": "50382605" },
      { "name": "Hardyblue blueberry purees", "code": "50382606" },
      { "name": "Legacy blueberry purees", "code": "50382607" },
      { "name": "Misty blueberry purees", "code": "50382608" },
      { "name": "Nelson blueberry purees", "code": "50382609" },
      { "name": "Northblue blueberry purees", "code": "50382610" },
      { "name": "Northcountry blueberry purees", "code": "50382611" },
      { "name": "Northsky blueberry purees", "code": "50382612" },
      { "name": "Patriot blueberry purees", "code": "50382613" },
      { "name": "Spartan blueberry purees", "code": "50382614" },
      { "name": "Toro blueberry purees", "code": "50382615" },
      { "name": "Breadfruit purees", "code": "50382700" },
      { "name": "Chataigne breadfruit purees", "code": "50382701" },
      { "name": "Seedless breadfruit purees", "code": "50382702" },
      { "name": "White heart breadfruit purees", "code": "50382703" },
      { "name": "Yellow heart breadfruit purees", "code": "50382704" },
      { "name": "Cherimoyas purees", "code": "50382800" },
      { "name": "Bays cherimoya purees", "code": "50382801" },
      { "name": "Bronceada cherimoya purees", "code": "50382802" },
      { "name": "Burtons cherimoya purees", "code": "50382803" },
      { "name": "Burtons favorite cherimoya purees", "code": "50382804" },
      { "name": "Jete cherimoya purees", "code": "50382805" },
      { "name": "Reretai cherimoya purees", "code": "50382806" },
      { "name": "Smoothey cherimoya purees", "code": "50382807" },
      { "name": "Spain cherimoya purees", "code": "50382808" },
      { "name": "White cherimoya purees", "code": "50382809" },
      { "name": "Cherry purees", "code": "50382900" },
      { "name": "Amarelle cherry purees", "code": "50382901" },
      { "name": "Brooks cherry purees", "code": "50382902" },
      { "name": "Bigarreu cherry purees", "code": "50382903" },
      { "name": "Bing cherry purees", "code": "50382904" },
      { "name": "Black republic cherry purees", "code": "50382905" },
      { "name": "Black schmidt cherry purees", "code": "50382906" },
      { "name": "Black tartarian cherry purees", "code": "50382907" },
      { "name": "Fiesta bing cherry purees", "code": "50382908" },
      { "name": "Garnet cherry purees", "code": "50382909" },
      { "name": "King cherry purees", "code": "50382910" },
      { "name": "Chapman cherry purees", "code": "50382911" },
      { "name": "Lapin cherry purees", "code": "50382912" },
      { "name": "Larian cherry purees", "code": "50382913" },
      { "name": "Dark guines cherry purees", "code": "50382914" },
      { "name": "Montmorency cherry purees", "code": "50382915" },
      { "name": "Duke cherry purees", "code": "50382916" },
      { "name": "Early rivers cherry purees", "code": "50382917" },
      { "name": "Ruby bing cherry purees", "code": "50382918" },
      { "name": "Santina cherry purees", "code": "50382919" },
      { "name": "Geans guines cherry purees", "code": "50382920" },
      { "name": "Sonata cherry purees", "code": "50382921" },
      { "name": "Lambert cherry purees", "code": "50382922" },
      { "name": "Stella cherry purees", "code": "50382923" },
      { "name": "Sweetheart cherry purees", "code": "50382924" },
      { "name": "Tartarian cherry purees", "code": "50382925" },
      { "name": "Maraschino cherry purees", "code": "50382926" },
      { "name": "Van cherry purees", "code": "50382927" },
      { "name": "Morello cherry purees", "code": "50382928" },
      { "name": "Royal ann cherry purees", "code": "50382929" },
      { "name": "Ranier cherry purees", "code": "50382930" },
      { "name": "Royal cherry purees", "code": "50382931" },
      { "name": "Green cherry purees", "code": "50382932" },
      { "name": "Citron purees", "code": "50383000" },
      { "name": "Buddha's hand citron purees", "code": "50383001" },
      { "name": "Fingered citron purees", "code": "50383002" },
      { "name": "Fo shoukan citron purees", "code": "50383003" },
      { "name": "Bushakan citron purees", "code": "50383004" },
      { "name": "Diamante citron purees", "code": "50383005" },
      { "name": "Etrog citron purees", "code": "50383006" },
      { "name": "Ponderosa citron purees", "code": "50383007" },
      { "name": "Cranberry purees", "code": "50383100" },
      { "name": "Ben lear cranberry purees", "code": "50383101" },
      { "name": "Early black cranberry purees", "code": "50383102" },
      { "name": "Grycleski cranberry purees", "code": "50383103" },
      { "name": "Howe cranberry purees", "code": "50383104" },
      { "name": "Lingonberry purees", "code": "50383105" },
      { "name": "Mcfarlin cranberry purees", "code": "50383106" },
      { "name": "Mountain cranberry purees", "code": "50383107" },
      { "name": "Pilgrim cranberry purees", "code": "50383108" },
      { "name": "Searless cranberry purees", "code": "50383109" },
      { "name": "Stevens cranberry purees", "code": "50383110" },
      { "name": "Currant purees", "code": "50383200" },
      { "name": "Hudson bay currant purees", "code": "50383201" },
      { "name": "Waxy currant purees", "code": "50383202" },
      { "name": "Desert currant purees", "code": "50383203" },
      { "name": "Black currant purees", "code": "50383204" },
      { "name": "Red currant purees", "code": "50383205" },
      { "name": "White currant purees", "code": "50383206" },
      { "name": "Date purees", "code": "50383300" },
      { "name": "Asharasi date purees", "code": "50383301" },
      { "name": "Barhi or barhee date purees", "code": "50383302" },
      { "name": "Deglet noor date purees", "code": "50383303" },
      { "name": "Fardh date purees", "code": "50383304" },
      { "name": "Gundila date purees", "code": "50383305" },
      { "name": "Halawi halawy date purees", "code": "50383306" },
      { "name": "Hilali date purees", "code": "50383307" },
      { "name": "Khadrawi khadrawy date purees", "code": "50383308" },
      { "name": "Khalas date purees", "code": "50383309" },
      { "name": "Khustawi date purees", "code": "50383310" },
      { "name": "Khidri date purees", "code": "50383311" },
      { "name": "Medjool medjul date purees", "code": "50383312" },
      { "name": "Mactoum date purees", "code": "50383313" },
      { "name": "Neghal date purees", "code": "50383314" },
      { "name": "Yatimeh date purees", "code": "50383315" },
      { "name": "Zahidi date purees", "code": "50383316" },
      { "name": "Dragonfruit purees", "code": "50383400" },
      { "name": "Pink dragonfruit purees", "code": "50383401" },
      { "name": "Yellow dragonfruit purees", "code": "50383402" },
      { "name": "Fig purees", "code": "50383500" },
      { "name": "Bardajic fig purees", "code": "50383501" },
      { "name": "Brown turkey fig purees", "code": "50383502" },
      { "name": "Calimyrna fig purees", "code": "50383503" },
      { "name": "Conadria fig purees", "code": "50383504" },
      { "name": "Dottado fig purees", "code": "50383505" },
      { "name": "Kadota fig purees", "code": "50383506" },
      { "name": "Mediterranean fig purees", "code": "50383507" },
      { "name": "Mission fig purees", "code": "50383508" },
      { "name": "Smyrna fig purees", "code": "50383509" },
      { "name": "Verdona fig purees", "code": "50383510" },
      { "name": "White king fig purees", "code": "50383511" },
      { "name": "Gooseberry purees", "code": "50383600" },
      { "name": "Early sulphur gooseberry purees", "code": "50383601" },
      { "name": "Goldendrop gooseberry purees", "code": "50383602" },
      { "name": "Langley gage gooseberry purees", "code": "50383603" },
      { "name": "Leveller gooseberry purees", "code": "50383604" },
      { "name": "London gooseberry purees", "code": "50383605" },
      { "name": "Worcestershire gooseberry purees", "code": "50383606" },
      {
        "name": "American worcesterberry gooseberry purees",
        "code": "50383607"
      },
      { "name": "Grapefruit purees", "code": "50383700" },
      { "name": "Burgundy grapefruit purees", "code": "50383701" },
      { "name": "Duncan grapefruit purees", "code": "50383702" },
      { "name": "Foster grapefruit purees", "code": "50383703" },
      { "name": "Marsh grapefruit purees", "code": "50383704" },
      { "name": "New zealand grapefruit purees", "code": "50383705" },
      { "name": "Rio red grapefruit purees", "code": "50383706" },
      { "name": "Ruby red grapefruit purees", "code": "50383707" },
      { "name": "Star ruby grapefruit purees", "code": "50383708" },
      { "name": "Triumph grapefruit purees", "code": "50383709" },
      { "name": "Table grape purees", "code": "50383800" },
      { "name": "Alicante grape purees", "code": "50383801" },
      { "name": "Almeria grape purees", "code": "50383802" },
      { "name": "Alphonse lavalle grape purees", "code": "50383803" },
      { "name": "Autumn king grape purees", "code": "50383804" },
      { "name": "Autumn royal grape purees", "code": "50383805" },
      { "name": "Autumn seedless grape purees", "code": "50383806" },
      { "name": "Baresana grape purees", "code": "50383807" },
      { "name": "Barlinka grape purees", "code": "50383808" },
      { "name": "Beauty seedless grape purees", "code": "50383809" },
      { "name": "Black beauty seedless grape purees", "code": "50383810" },
      { "name": "Black emerald grape purees", "code": "50383811" },
      { "name": "Black giant grape purees", "code": "50383812" },
      { "name": "Black globe grape purees", "code": "50383813" },
      { "name": "Black monukka grape purees", "code": "50383814" },
      { "name": "Black pearl grape purees", "code": "50383815" },
      { "name": "Black seedless grape purees", "code": "50383816" },
      { "name": "Bonheur grape purees", "code": "50383817" },
      { "name": "Calmeria grape purees", "code": "50383818" },
      { "name": "Cardinal grape purees", "code": "50383819" },
      { "name": "Catawba grape purees", "code": "50383820" },
      { "name": "Chasselas golden chasselas grape purees", "code": "50383821" },
      { "name": "Christmas rose grape purees", "code": "50383822" },
      { "name": "Concord grape purees", "code": "50383823" },
      { "name": "Concord seedless grape purees", "code": "50383824" },
      { "name": "Crimson seedless grape purees", "code": "50383825" },
      { "name": "Dauphine grape purees", "code": "50383826" },
      { "name": "Delaware grape purees", "code": "50383827" },
      { "name": "Early muscat grape purees", "code": "50383828" },
      { "name": "Early sweet grape purees", "code": "50383829" },
      { "name": "Emerald seedless grape purees", "code": "50383830" },
      { "name": "Emperatriz grape purees", "code": "50383831" },
      { "name": "Emperor grape purees", "code": "50383832" },
      { "name": "Empress grape purees", "code": "50383833" },
      { "name": "Exotic grape purees", "code": "50383834" },
      { "name": "Fantasy grape purees", "code": "50383835" },
      { "name": "Fantasy seedless grape purees", "code": "50383836" },
      { "name": "Flame grape purees", "code": "50383837" },
      { "name": "Flame seedless grape purees", "code": "50383838" },
      { "name": "Flame tokay grape purees", "code": "50383839" },
      { "name": "Flaming red grape purees", "code": "50383840" },
      { "name": "Galaxy seedless grape purees", "code": "50383841" },
      { "name": "Gamay grape purees", "code": "50383842" },
      { "name": "Gold grape purees", "code": "50383843" },
      { "name": "Hanepoot or honeypot grape purees", "code": "50383844" },
      { "name": "Italia grape purees", "code": "50383845" },
      { "name": "Jade seedless grape purees", "code": "50383846" },
      { "name": "Jubilee grape purees", "code": "50383847" },
      { "name": "King ruby grape purees", "code": "50383848" },
      { "name": "Kyoho grape purees", "code": "50383849" },
      { "name": "La rochelle grape purees", "code": "50383850" },
      { "name": "Lady finger grape purees", "code": "50383851" },
      { "name": "Late seedless grape purees", "code": "50383852" },
      { "name": "Majestic seedless grape purees", "code": "50383853" },
      { "name": "Malaga grape purees", "code": "50383854" },
      { "name": "Marroot seedless grape purees", "code": "50383855" },
      { "name": "Muscadine grape purees", "code": "50383856" },
      { "name": "Muscat flame grape purees", "code": "50383857" },
      { "name": "Muscat grape purees", "code": "50383858" },
      { "name": "Muscat seedless grape purees", "code": "50383859" },
      { "name": "Napoleon grape purees", "code": "50383860" },
      { "name": "Negria grape purees", "code": "50383861" },
      { "name": "New cross grape purees", "code": "50383862" },
      { "name": "Niabell grape purees", "code": "50383863" },
      { "name": "Niagara grape purees", "code": "50383864" },
      { "name": "Olivette grape purees", "code": "50383865" },
      { "name": "Perlette grape purees", "code": "50383866" },
      { "name": "Perlon grape purees", "code": "50383867" },
      { "name": "Prima black seedless grape purees", "code": "50383868" },
      { "name": "Princess grape purees", "code": "50383869" },
      { "name": "Queen grape purees", "code": "50383870" },
      { "name": "Red blush grape purees", "code": "50383871" },
      { "name": "Red globe grape purees", "code": "50383872" },
      { "name": "Red malaga grape purees", "code": "50383873" },
      { "name": "Red seedless grape purees", "code": "50383874" },
      { "name": "Regina grape purees", "code": "50383875" },
      { "name": "Ribier grape purees", "code": "50383876" },
      { "name": "Rosita grape purees", "code": "50383877" },
      { "name": "Rouge grape purees", "code": "50383878" },
      { "name": "Royal black seedless grape purees", "code": "50383879" },
      { "name": "Ruby red seedless grape purees", "code": "50383880" },
      { "name": "Ruby seedless grape purees", "code": "50383881" },
      { "name": "Scarlet royal grape purees", "code": "50383882" },
      { "name": "Scuppernong grape purees", "code": "50383883" },
      { "name": "Sugarose grape purees", "code": "50383884" },
      { "name": "Sugarthirteen grape purees", "code": "50383885" },
      { "name": "Sugraone grape purees", "code": "50383886" },
      { "name": "Sugrasixteen grape purees", "code": "50383887" },
      { "name": "Sultana sun red grape purees", "code": "50383888" },
      { "name": "Summer royal grape purees", "code": "50383889" },
      { "name": "Sunset grape purees", "code": "50383890" },
      { "name": "Superior seedless grape purees", "code": "50383891" },
      { "name": "Thompson seedless grape purees", "code": "50383892" },
      { "name": "Tokay pinot gris grape purees", "code": "50383893" },
      { "name": "Waltman cross grape purees", "code": "50383894" },
      { "name": "White seedless grape purees", "code": "50383895" },
      { "name": "Zante current grape purees", "code": "50383896" },
      { "name": "Quebranta grape purees", "code": "50383897" },
      { "name": "Burgundy grape purees", "code": "50383898" },
      { "name": "Torontel grape purees", "code": "50383899" },
      { "name": "Wine grape purees", "code": "50383900" },
      { "name": "Alicante bouschet grape purees", "code": "50383901" },
      { "name": "Barbera grape purees", "code": "50383902" },
      { "name": "Burger grape purees", "code": "50383903" },
      { "name": "Cabernet franc grape purees", "code": "50383904" },
      { "name": "Cabernet sauvignon grape purees", "code": "50383905" },
      { "name": "Carignane grape purees", "code": "50383906" },
      { "name": "Carnelian grape purees", "code": "50383907" },
      { "name": "Catarratto grape purees", "code": "50383908" },
      { "name": "Centurian grape purees", "code": "50383909" },
      { "name": "Charbono grape purees", "code": "50383910" },
      { "name": "Chardonnay grape purees", "code": "50383911" },
      { "name": "Chenin blanc grape purees", "code": "50383912" },
      { "name": "Cinsaut grape purees", "code": "50383913" },
      { "name": "Dolcetto grape purees", "code": "50383914" },
      { "name": "Emerald riesling grape purees", "code": "50383915" },
      { "name": "French colombard grape purees", "code": "50383916" },
      { "name": "Gamay napa grape purees", "code": "50383917" },
      { "name": "Gamay beaujolais grape purees", "code": "50383918" },
      { "name": "Gewurztraminer grape purees", "code": "50383919" },
      { "name": "Grenache grape purees", "code": "50383920" },
      { "name": "Grenache blanc grape purees", "code": "50383921" },
      { "name": "Lagrein grape purees", "code": "50383922" },
      { "name": "Lambrusco grape purees", "code": "50383923" },
      { "name": "Malbec grape purees", "code": "50383924" },
      { "name": "Malvasia bianca grape purees", "code": "50383925" },
      { "name": "Marsanne grape purees", "code": "50383926" },
      { "name": "Mataro grape purees", "code": "50383927" },
      { "name": "Merlot grape purees", "code": "50383928" },
      { "name": "Meunier grape purees", "code": "50383929" },
      { "name": "Mission grape purees", "code": "50383930" },
      { "name": "Montepulciano grape purees", "code": "50383931" },
      { "name": "Muscat blanc grape purees", "code": "50383932" },
      { "name": "Muscat hamburg grape purees", "code": "50383933" },
      { "name": "Muscat of alexandria grape purees", "code": "50383934" },
      { "name": "Muscat orange grape purees", "code": "50383935" },
      { "name": "Nebbiolo grape purees", "code": "50383936" },
      { "name": "Palomino grape purees", "code": "50383937" },
      { "name": "Petit verdot grape purees", "code": "50383938" },
      { "name": "Petite sirah grape purees", "code": "50383939" },
      { "name": "Pinot blanc grape purees", "code": "50383940" },
      { "name": "Pinot gris grape purees", "code": "50383941" },
      { "name": "Pinot noir grape purees", "code": "50383942" },
      { "name": "Primitivo grape purees", "code": "50383943" },
      { "name": "Roussanne grape purees", "code": "50383944" },
      { "name": "Royalty grape purees", "code": "50383945" },
      { "name": "Rubired grape purees", "code": "50383946" },
      { "name": "Ruby cabernet grape purees", "code": "50383947" },
      { "name": "Salvador grape purees", "code": "50383948" },
      { "name": "Sangiovese grape purees", "code": "50383949" },
      { "name": "Sauvignon blanc grape purees", "code": "50383950" },
      { "name": "Sauvignon musque grape purees", "code": "50383951" },
      { "name": "Semillon grape purees", "code": "50383952" },
      { "name": "Souzao grape purees", "code": "50383953" },
      { "name": "St emilion grape purees", "code": "50383954" },
      { "name": "Symphony grape purees", "code": "50383955" },
      { "name": "Syrah grape purees", "code": "50383956" },
      { "name": "Tannat grape purees", "code": "50383957" },
      { "name": "Tempranillo grape purees", "code": "50383958" },
      { "name": "Teroldego grape purees", "code": "50383959" },
      { "name": "Tocai friulano grape purees", "code": "50383960" },
      { "name": "Touriga nacional grape purees", "code": "50383961" },
      { "name": "Triplett blanc grape purees", "code": "50383962" },
      { "name": "Viognier grape purees", "code": "50383963" },
      { "name": "White riesling grape purees", "code": "50383964" },
      { "name": "Zinfandel grape purees", "code": "50383965" },
      { "name": "Raisin grape purees", "code": "50384000" },
      { "name": "Black corinth grape purees", "code": "50384001" },
      { "name": "Canner grape purees", "code": "50384002" },
      { "name": "Dovine grape purees", "code": "50384003" },
      { "name": "Fiesta grape purees", "code": "50384004" },
      { "name": "Selma pete grape purees", "code": "50384005" },
      { "name": "Sultana grape purees", "code": "50384006" },
      { "name": "Guava purees", "code": "50384100" },
      { "name": "Beaumont guava purees", "code": "50384101" },
      { "name": "Carrley guava purees", "code": "50384102" },
      { "name": "Lucida guava purees", "code": "50384103" },
      { "name": "Pineapple guava purees", "code": "50384104" },
      { "name": "Huckleberry purees", "code": "50384200" },
      { "name": "Black winter huckleberry purees", "code": "50384201" },
      { "name": "Cascade huckleberry purees", "code": "50384202" },
      { "name": "Dwarf huckleberry purees", "code": "50384203" },
      { "name": "Mountain huckleberry purees", "code": "50384204" },
      { "name": "Red huckleberry purees", "code": "50384205" },
      { "name": "Kiwi fruit purees", "code": "50384300" },
      { "name": "Ananasnaja kiwi fruit purees", "code": "50384301" },
      { "name": "Arctic beauty kiwi fruit purees", "code": "50384302" },
      { "name": "Blake kiwi fruit purees", "code": "50384303" },
      { "name": "Hayward kiwi fruit purees", "code": "50384304" },
      { "name": "Issai kiwi fruit purees", "code": "50384305" },
      { "name": "Siberian kiwi fruit purees", "code": "50384306" },
      { "name": "Kumquat purees", "code": "50384400" },
      { "name": "Hong kong kumquat purees", "code": "50384401" },
      { "name": "Limequat kumquat purees", "code": "50384402" },
      { "name": "Long fruit kumquat purees", "code": "50384403" },
      { "name": "Malayan kumquat purees", "code": "50384404" },
      { "name": "Meiwa kumquat purees", "code": "50384405" },
      { "name": "Nagami kumquat purees", "code": "50384406" },
      { "name": "Lemon purees", "code": "50384500" },
      { "name": "Baboon lemon purees", "code": "50384501" },
      { "name": "Bearss sicilian lemon purees", "code": "50384502" },
      { "name": "Cameron highlands lemon purees", "code": "50384503" },
      { "name": "Escondido lemon purees", "code": "50384504" },
      { "name": "Eureka lemon purees", "code": "50384505" },
      { "name": "Lisbon lemon purees", "code": "50384506" },
      { "name": "Meyer lemon purees", "code": "50384507" },
      { "name": "Volkamer lemon purees", "code": "50384508" },
      { "name": "Lime purees", "code": "50384600" },
      { "name": "Indian sweet lime purees", "code": "50384601" },
      { "name": "Key lime purees", "code": "50384602" },
      { "name": "Mandarin lime purees", "code": "50384603" },
      { "name": "Philippine lime purees", "code": "50384604" },
      { "name": "Tahitian lime purees", "code": "50384605" },
      { "name": "Bearss lime purees", "code": "50384606" },
      { "name": "Persian lime purees", "code": "50384607" },
      { "name": "Seedless lime purees", "code": "50384608" },
      { "name": "Loquat purees", "code": "50384700" },
      { "name": "Advance loquat purees", "code": "50384701" },
      { "name": "Benlehr loquat purees", "code": "50384702" },
      { "name": "Big jim loquat purees", "code": "50384703" },
      { "name": "Champagne loquat purees", "code": "50384704" },
      { "name": "Early red loquat purees", "code": "50384705" },
      { "name": "Gold nugget loquat purees", "code": "50384706" },
      { "name": "Herd's mammoth loquat purees", "code": "50384707" },
      { "name": "Mogi loquat purees", "code": "50384708" },
      { "name": "Mrs cooksey loquat purees", "code": "50384709" },
      { "name": "Strawberry loquat purees", "code": "50384710" },
      { "name": "Tanaka loquat purees", "code": "50384711" },
      { "name": "Victory vista white loquat purees", "code": "50384712" },
      { "name": "Wolfe loquat purees", "code": "50384713" },
      { "name": "Mandarin oranges or tangerine purees", "code": "50384800" },
      { "name": "Clauselinas orange purees", "code": "50384801" },
      { "name": "Clementine tangerine purees", "code": "50384802" },
      { "name": "Cleopatra mandarin orange purees", "code": "50384803" },
      { "name": "Dancy tangerine purees", "code": "50384804" },
      { "name": "Ellensdale orange purees", "code": "50384805" },
      { "name": "Fairchild orange purees", "code": "50384806" },
      { "name": "Fallglo orange purees", "code": "50384807" },
      { "name": "Fortune orange purees", "code": "50384808" },
      { "name": "Fremont mandarin orange purees", "code": "50384809" },
      { "name": "Fremont orange purees", "code": "50384810" },
      { "name": "Golden nugget orange purees", "code": "50384811" },
      { "name": "Honey mandarin orange purees", "code": "50384812" },
      { "name": "Honey orange purees", "code": "50384813" },
      { "name": "Honey tangerine purees", "code": "50384814" },
      { "name": "Honeybelle tangelo orange purees", "code": "50384815" },
      { "name": "King mandarin orange purees", "code": "50384816" },
      { "name": "Kinnow orange purees", "code": "50384817" },
      { "name": "Lee mandarin orange purees", "code": "50384818" },
      { "name": "Makokkee orange purees", "code": "50384819" },
      { "name": "Malvasios orange purees", "code": "50384820" },
      { "name": "Mediterranean mandarin orange purees", "code": "50384821" },
      { "name": "Minneola tangelo orange purees", "code": "50384822" },
      { "name": "Monica orange purees", "code": "50384823" },
      { "name": "Murcott honey orange purees", "code": "50384824" },
      { "name": "Murcott tangor purees", "code": "50384825" },
      { "name": "Natsudaidai mandarin orange purees", "code": "50384826" },
      { "name": "Natsumikan mandarin orange purees", "code": "50384827" },
      { "name": "Nocatee tangelo orange purees", "code": "50384828" },
      { "name": "Orlando tangelo orange purees", "code": "50384829" },
      { "name": "Ortanique tangerine purees", "code": "50384830" },
      { "name": "Page mandarin orange purees", "code": "50384831" },
      { "name": "Pixie orange purees", "code": "50384832" },
      { "name": "Ponkan bantangas mandarin orange purees", "code": "50384833" },
      { "name": "Reyna orange purees", "code": "50384834" },
      { "name": "Robinson orange purees", "code": "50384835" },
      { "name": "Saltenitas orange purees", "code": "50384836" },
      { "name": "Sampson tangelo orange purees", "code": "50384837" },
      { "name": "Satsuma mandarin orange purees", "code": "50384838" },
      { "name": "Sunburst mandarin orange purees", "code": "50384839" },
      { "name": "Tangelo purees", "code": "50384900" },
      { "name": "Tangerina orange purees", "code": "50384901" },
      { "name": "Temple orange purees", "code": "50384902" },
      { "name": "Thornton orange purees", "code": "50384903" },
      { "name": "Wekiwa tangerine purees", "code": "50384904" },
      { "name": "Wilkins tangerine purees", "code": "50384905" },
      {
        "name": "Willowleaf mediterranean tangerine purees",
        "code": "50384906"
      },
      { "name": "Mango purees", "code": "50385000" },
      { "name": "Alphonso mango purees", "code": "50385001" },
      { "name": "Ataulfo mango purees", "code": "50385002" },
      { "name": "Criollo mango purees", "code": "50385003" },
      { "name": "Edwards mango purees", "code": "50385004" },
      { "name": "Francine mango purees", "code": "50385005" },
      { "name": "Francis mango purees", "code": "50385006" },
      { "name": "Gandaria mango purees", "code": "50385007" },
      { "name": "Haden mango purees", "code": "50385008" },
      { "name": "Irwin mango purees", "code": "50385009" },
      { "name": "Keitt mango purees", "code": "50385010" },
      { "name": "Kent mango purees", "code": "50385011" },
      { "name": "Kesar mango purees", "code": "50385012" },
      { "name": "Kuini mango purees", "code": "50385013" },
      { "name": "Manila super mango purees", "code": "50385014" },
      { "name": "Manila mango purees", "code": "50385015" },
      { "name": "Mayaguez mango purees", "code": "50385016" },
      { "name": "Mulgoba mango purees", "code": "50385017" },
      { "name": "Oro mango purees", "code": "50385018" },
      { "name": "Palmer mango purees", "code": "50385019" },
      { "name": "Parvin mango purees", "code": "50385020" },
      { "name": "Sandersha mango purees", "code": "50385021" },
      { "name": "Sensation mango purees", "code": "50385022" },
      { "name": "Smith mango purees", "code": "50385023" },
      { "name": "Tommy atkins mango purees", "code": "50385024" },
      { "name": "Van dyke mango purees", "code": "50385025" },
      { "name": "Melon purees", "code": "50385100" },
      { "name": "Allsweet melon purees", "code": "50385101" },
      { "name": "Athena melon purees", "code": "50385102" },
      { "name": "Black diamond melon purees", "code": "50385103" },
      { "name": "Cal sweet melon purees", "code": "50385104" },
      { "name": "Cantaloupe melon purees", "code": "50385105" },
      { "name": "Carnical melon purees", "code": "50385106" },
      { "name": "Casaba melon purees", "code": "50385107" },
      { "name": "Cavaillon melon purees", "code": "50385108" },
      { "name": "Charentais melon purees", "code": "50385109" },
      { "name": "Charleston gray watermelon purees", "code": "50385110" },
      { "name": "Crenshaw melon purees", "code": "50385111" },
      { "name": "Crimson sweet melon purees", "code": "50385112" },
      { "name": "Dixie lee melon purees", "code": "50385113" },
      { "name": "Eclipse melon purees", "code": "50385114" },
      { "name": "Ein d'or melon purees", "code": "50385115" },
      { "name": "Fiesta melon purees", "code": "50385116" },
      { "name": "Galia melon purees", "code": "50385117" },
      { "name": "Gaya melon purees", "code": "50385118" },
      { "name": "Hami melon purees", "code": "50385119" },
      { "name": "Honeydew melon purees", "code": "50385120" },
      { "name": "Icebox melon purees", "code": "50385121" },
      { "name": "Ida pride melon purees", "code": "50385122" },
      { "name": "Juan canary melon purees", "code": "50385123" },
      { "name": "Jubilee melon purees", "code": "50385124" },
      { "name": "Jubilation melon purees", "code": "50385125" },
      { "name": "Kakhi kakri melon purees", "code": "50385126" },
      { "name": "Kiwano melon purees", "code": "50385127" },
      { "name": "Korean melon purees", "code": "50385128" },
      { "name": "Long gray melon purees", "code": "50385129" },
      { "name": "Mayan melon purees", "code": "50385130" },
      { "name": "Micky lee melon purees", "code": "50385131" },
      { "name": "Mirage melon purees", "code": "50385132" },
      { "name": "Moon and stars watermelon purees", "code": "50385133" },
      { "name": "Ogen melon purees", "code": "50385134" },
      { "name": "Patriot melon purees", "code": "50385135" },
      { "name": "Peacock melon purees", "code": "50385136" },
      { "name": "Pepino melon purees", "code": "50385137" },
      { "name": "Persian melon purees", "code": "50385138" },
      { "name": "Picnic melon purees", "code": "50385139" },
      { "name": "Piel de sapo melon purees", "code": "50385140" },
      { "name": "Pineapple melon purees", "code": "50385141" },
      { "name": "Quetzali melon purees", "code": "50385142" },
      { "name": "Red goblin melon purees", "code": "50385143" },
      { "name": "Regency melon purees", "code": "50385144" },
      { "name": "Royal majestic melon purees", "code": "50385145" },
      { "name": "Royal star melon purees", "code": "50385146" },
      { "name": "Royal sweet melon purees", "code": "50385147" },
      { "name": "Santa claus melon purees", "code": "50385148" },
      { "name": "Sharlyn melon purees", "code": "50385149" },
      { "name": "Spanish melon purees", "code": "50385150" },
      { "name": "Sprite melon purees", "code": "50385151" },
      { "name": "Starbright melon purees", "code": "50385152" },
      { "name": "Stars n stripes melon purees", "code": "50385153" },
      { "name": "Sugar baby melon purees", "code": "50385154" },
      { "name": "Sugar baby watermelon purees", "code": "50385155" },
      { "name": "Sunsweet melon purees", "code": "50385156" },
      { "name": "Sweet heart seedless watermelon purees", "code": "50385157" },
      { "name": "Temptation melon purees", "code": "50385158" },
      { "name": "Tiger baby melon purees", "code": "50385159" },
      { "name": "Tuscan type melon purees", "code": "50385160" },
      { "name": "Yellow baby watermelon purees", "code": "50385161" },
      { "name": "Mulberry purees", "code": "50385200" },
      { "name": "Black mulberry purees", "code": "50385201" },
      { "name": "White mulberry purees", "code": "50385202" },
      { "name": "Bayberry and myrtle purees", "code": "50385300" },
      { "name": "Bog myrtle purees", "code": "50385301" },
      { "name": "Bayberry purees", "code": "50385302" },
      { "name": "Nectarine purees", "code": "50385400" },
      { "name": "April glo nectarine purees", "code": "50385401" },
      { "name": "Arctic mist nectarine purees", "code": "50385402" },
      { "name": "Arctic snow nectarine purees", "code": "50385403" },
      { "name": "Arctic star nectarine purees", "code": "50385404" },
      { "name": "Arctic sweet nectarine purees", "code": "50385405" },
      { "name": "Arctic glo nectarine purees", "code": "50385406" },
      { "name": "August fire nectarine purees", "code": "50385407" },
      { "name": "August pearl nectarine purees", "code": "50385408" },
      { "name": "August red nectarine purees", "code": "50385409" },
      { "name": "Autumn star nectarine purees", "code": "50385410" },
      { "name": "Big john nectarine purees", "code": "50385411" },
      { "name": "Bright pearl nectarine purees", "code": "50385412" },
      { "name": "Diamond bright nectarine purees", "code": "50385413" },
      { "name": "Diamond ray nectarine purees", "code": "50385414" },
      { "name": "Earliglo nectarine purees", "code": "50385415" },
      { "name": "Early diamond nectarine purees", "code": "50385416" },
      { "name": "Fairlane nectarine purees", "code": "50385417" },
      { "name": "Fantasia nectarine purees", "code": "50385418" },
      { "name": "Fire pearl nectarine purees", "code": "50385419" },
      { "name": "Fire sweet nectarine purees", "code": "50385420" },
      { "name": "Flamekist nectarine purees", "code": "50385421" },
      { "name": "Flat type nectarine purees", "code": "50385422" },
      { "name": "Garden delight nectarine purees", "code": "50385423" },
      { "name": "Goldmine nectarine purees", "code": "50385424" },
      { "name": "Grand pearl nectarine purees", "code": "50385425" },
      { "name": "Hardired nectarine purees", "code": "50385426" },
      { "name": "Honey blaze nectarine purees", "code": "50385427" },
      { "name": "July red nectarine purees", "code": "50385428" },
      { "name": "Kay pearl nectarine purees", "code": "50385429" },
      { "name": "Kay sweet nectarine purees", "code": "50385430" },
      { "name": "May diamond nectarine purees", "code": "50385431" },
      { "name": "Mayfire nectarine purees", "code": "50385432" },
      { "name": "Mayglo nectarine purees", "code": "50385433" },
      { "name": "Mericrest nectarine purees", "code": "50385434" },
      { "name": "Red diamond nectarine purees", "code": "50385435" },
      { "name": "Red gold nectarine purees", "code": "50385436" },
      { "name": "Red jim nectarine purees", "code": "50385437" },
      { "name": "Red roy nectarine purees", "code": "50385438" },
      { "name": "Rio red nectarine purees", "code": "50385439" },
      { "name": "Rose diamond nectarine purees", "code": "50385440" },
      { "name": "Royal glo nectarine purees", "code": "50385441" },
      { "name": "Ruby diamond nectarine purees", "code": "50385442" },
      { "name": "Ruby sweet nectarine purees", "code": "50385443" },
      { "name": "Ruddy jewel nectarine purees", "code": "50385444" },
      { "name": "September red nectarine purees", "code": "50385445" },
      { "name": "Snowqueen nectarine purees", "code": "50385446" },
      { "name": "Spring bright nectarine purees", "code": "50385447" },
      { "name": "Spring red nectarine purees", "code": "50385448" },
      { "name": "Summer blush nectarine purees", "code": "50385449" },
      { "name": "Summer brite nectarine purees", "code": "50385450" },
      { "name": "Summer diamond nectarine purees", "code": "50385451" },
      { "name": "Summer fire nectarine purees", "code": "50385452" },
      { "name": "Summer grand nectarine purees", "code": "50385453" },
      { "name": "Sunglo nectarine purees", "code": "50385454" },
      { "name": "Zee fire nectarine purees", "code": "50385455" },
      { "name": "Zee glo nectarine purees", "code": "50385456" },
      { "name": "Zeegrand nectarine purees", "code": "50385457" },
      { "name": "Orange purees", "code": "50385500" },
      { "name": "African sour orange purees", "code": "50385501" },
      { "name": "Ambersweet orange purees", "code": "50385502" },
      { "name": "Argentine sour orange purees", "code": "50385503" },
      { "name": "Bahianinha orange purees", "code": "50385504" },
      { "name": "Bergamot orange purees", "code": "50385505" },
      { "name": "Berna orange purees", "code": "50385506" },
      { "name": "Bigaradier apepu orange purees", "code": "50385507" },
      { "name": "Bittersweet daidai orange purees", "code": "50385508" },
      { "name": "Blonde orange purees", "code": "50385509" },
      { "name": "Blood orange purees", "code": "50385510" },
      { "name": "California navel orange purees", "code": "50385511" },
      { "name": "Cara cara orange purees", "code": "50385512" },
      { "name": "Chinotto orange purees", "code": "50385513" },
      { "name": "Dream navel orange purees", "code": "50385514" },
      { "name": "Gou tou orange purees", "code": "50385515" },
      { "name": "Hamlin orange purees", "code": "50385516" },
      { "name": "Jaffa orange purees", "code": "50385517" },
      { "name": "Jincheng orange purees", "code": "50385518" },
      { "name": "K-early orange purees", "code": "50385519" },
      { "name": "Kona orange purees", "code": "50385520" },
      { "name": "Late navel orange purees", "code": "50385521" },
      { "name": "Late valencia orange purees", "code": "50385522" },
      { "name": "Limequat orange purees", "code": "50385523" },
      { "name": "Marr orange purees", "code": "50385524" },
      { "name": "Melogold orange purees", "code": "50385525" },
      { "name": "Moro orange purees", "code": "50385526" },
      { "name": "Moro tarocco orange purees", "code": "50385527" },
      { "name": "Navel orange purees", "code": "50385528" },
      { "name": "Navelina orange purees", "code": "50385529" },
      { "name": "Oro blanco orange purees", "code": "50385530" },
      { "name": "Osceola orange purees", "code": "50385531" },
      { "name": "Parson brown orange purees", "code": "50385532" },
      { "name": "Pera orange purees", "code": "50385533" },
      { "name": "Pummulo orange purees", "code": "50385534" },
      { "name": "Rhode red orange purees", "code": "50385535" },
      { "name": "Roble orange purees", "code": "50385536" },
      { "name": "Salustianas orange purees", "code": "50385537" },
      { "name": "Sanguine orange purees", "code": "50385538" },
      { "name": "Sanguinelli orange purees", "code": "50385539" },
      { "name": "Seville orange purees", "code": "50385540" },
      { "name": "Shamouti jaffa orange purees", "code": "50385541" },
      { "name": "Tunis orange purees", "code": "50385542" },
      { "name": "Valencia orange purees", "code": "50385543" },
      { "name": "Washington navel orange purees", "code": "50385544" },
      { "name": "Papaya purees", "code": "50385600" },
      { "name": "Green cooking papaya purees", "code": "50385601" },
      { "name": "Maradol papaya purees", "code": "50385602" },
      { "name": "Mexican yellow papaya purees", "code": "50385603" },
      { "name": "Mountain papaya purees", "code": "50385604" },
      { "name": "Solo papaya purees", "code": "50385605" },
      { "name": "Tainung papaya purees", "code": "50385606" },
      { "name": "Passion fruit purees", "code": "50385700" },
      { "name": "Banana passion fruit purees", "code": "50385701" },
      { "name": "Blue passion flower purees", "code": "50385702" },
      { "name": "Crackerjack passion fruit purees", "code": "50385703" },
      { "name": "Giant granadilla passion fruit purees", "code": "50385704" },
      { "name": "Golden granadilla passion fruit purees", "code": "50385705" },
      { "name": "Maypops passion fruit purees", "code": "50385706" },
      { "name": "Red granadilla passion fruit purees", "code": "50385707" },
      { "name": "Sweet granadilla passion fruit purees", "code": "50385708" },
      { "name": "Water lemon passion fruit purees", "code": "50385709" },
      { "name": "Wing-stemmed passion flower purees", "code": "50385710" },
      { "name": "Peach purees", "code": "50385800" },
      { "name": "Amber crest peach purees", "code": "50385801" },
      { "name": "April snow peach purees", "code": "50385802" },
      { "name": "August lady peach purees", "code": "50385803" },
      { "name": "Autumn flame peach purees", "code": "50385804" },
      { "name": "Autumn lady peach purees", "code": "50385805" },
      { "name": "Babcock peach purees", "code": "50385806" },
      { "name": "Brittney lane peach purees", "code": "50385807" },
      { "name": "Cary mac peach purees", "code": "50385808" },
      { "name": "Classic peach purees", "code": "50385809" },
      { "name": "Country sweet peach purees", "code": "50385810" },
      { "name": "Crest haven peach purees", "code": "50385811" },
      { "name": "Crimson lady peach purees", "code": "50385812" },
      { "name": "Crown princess peach purees", "code": "50385813" },
      { "name": "David sun peach purees", "code": "50385814" },
      { "name": "Diamond princess peach purees", "code": "50385815" },
      { "name": "Earlirich peach purees", "code": "50385816" },
      { "name": "Early majestic peach purees", "code": "50385817" },
      { "name": "Early treat peach purees", "code": "50385818" },
      { "name": "Elegant lady peach purees", "code": "50385819" },
      { "name": "Empress peach purees", "code": "50385820" },
      { "name": "Encore peach purees", "code": "50385821" },
      { "name": "Fancy lady peach purees", "code": "50385822" },
      { "name": "Fire prince peach purees", "code": "50385823" },
      { "name": "Flame crest peach purees", "code": "50385824" },
      { "name": "Flat type peach purees", "code": "50385825" },
      { "name": "Flavorcrest peach purees", "code": "50385826" },
      { "name": "Florida prince peach purees", "code": "50385827" },
      { "name": "Full moon peach purees", "code": "50385828" },
      { "name": "Harvester peach purees", "code": "50385829" },
      { "name": "Ice princess peach purees", "code": "50385830" },
      { "name": "Ivory princess peach purees", "code": "50385831" },
      { "name": "Jersey queen peach purees", "code": "50385832" },
      { "name": "John henry peach purees", "code": "50385833" },
      { "name": "June prince peach purees", "code": "50385834" },
      { "name": "Kaweah peach purees", "code": "50385835" },
      { "name": "Klondike peach purees", "code": "50385836" },
      { "name": "Lindo peach purees", "code": "50385837" },
      { "name": "Loring peach purees", "code": "50385838" },
      { "name": "Majestic peach purees", "code": "50385839" },
      { "name": "O'henry peach purees", "code": "50385840" },
      { "name": "Queencrest peach purees", "code": "50385841" },
      { "name": "Red lady peach purees", "code": "50385842" },
      { "name": "Redglobe peach purees", "code": "50385843" },
      { "name": "Redhaven peach purees", "code": "50385844" },
      { "name": "Redtop peach purees", "code": "50385845" },
      { "name": "Regina peach purees", "code": "50385846" },
      { "name": "Rich lady peach purees", "code": "50385847" },
      { "name": "Rich may peach purees", "code": "50385848" },
      { "name": "Royal glory peach purees", "code": "50385849" },
      { "name": "Royal lady peach purees", "code": "50385850" },
      { "name": "September snow peach purees", "code": "50385851" },
      { "name": "September sun peach purees", "code": "50385852" },
      { "name": "Sierra gem peach purees", "code": "50385853" },
      { "name": "Snow angel peach purees", "code": "50385854" },
      { "name": "Snow gem peach purees", "code": "50385855" },
      { "name": "Snow king peach purees", "code": "50385856" },
      { "name": "Spring lady peach purees", "code": "50385857" },
      { "name": "Spring snow peach purees", "code": "50385858" },
      { "name": "Springcrest peach purees", "code": "50385859" },
      { "name": "Sugar giant peach purees", "code": "50385860" },
      { "name": "Sugar lady peach purees", "code": "50385861" },
      { "name": "Sun bright peach purees", "code": "50385862" },
      { "name": "Sunhigh peach purees", "code": "50385863" },
      { "name": "Super lady peach purees", "code": "50385864" },
      { "name": "Super rich peach purees", "code": "50385865" },
      { "name": "Surecrop peach purees", "code": "50385866" },
      { "name": "Sweet dream peach purees", "code": "50385867" },
      { "name": "Sweet september peach purees", "code": "50385868" },
      { "name": "Vista peach purees", "code": "50385869" },
      { "name": "White lady peach purees", "code": "50385870" },
      { "name": "Zee lady peach purees", "code": "50385871" },
      { "name": "Pear purees", "code": "50385900" },
      { "name": "Abate fetel pear purees", "code": "50385901" },
      { "name": "Anjou pear purees", "code": "50385902" },
      { "name": "Asian pear purees", "code": "50385903" },
      { "name": "Bartlett pear purees", "code": "50385904" },
      { "name": "Best ever pear purees", "code": "50385905" },
      { "name": "Beth pear purees", "code": "50385906" },
      { "name": "Beurre pear purees", "code": "50385907" },
      { "name": "Bosc pear purees", "code": "50385908" },
      { "name": "Clapp favorite pear purees", "code": "50385909" },
      { "name": "Comice pear purees", "code": "50385910" },
      { "name": "Concorde pear purees", "code": "50385911" },
      { "name": "Conference pear purees", "code": "50385912" },
      { "name": "Crimson red pear purees", "code": "50385913" },
      { "name": "D'anjou pear purees", "code": "50385914" },
      { "name": "Dr jules guyot pear purees", "code": "50385915" },
      { "name": "Early pear purees", "code": "50385916" },
      { "name": "Emperor brown pear purees", "code": "50385917" },
      { "name": "Forelle pear purees", "code": "50385918" },
      { "name": "French butter pear purees", "code": "50385919" },
      { "name": "Glou morceau pear purees", "code": "50385920" },
      { "name": "Hosui pear purees", "code": "50385921" },
      { "name": "Italian butter pear purees", "code": "50385922" },
      { "name": "Jargonelle pear purees", "code": "50385923" },
      { "name": "Juno pear purees", "code": "50385924" },
      {
        "name": "Kaiserlouise bonne de jersey pear purees",
        "code": "50385925"
      },
      { "name": "Keiffer pear purees", "code": "50385926" },
      { "name": "Kings royal pear purees", "code": "50385927" },
      { "name": "Limonera pear purees", "code": "50385928" },
      { "name": "Merton pride pear purees", "code": "50385929" },
      { "name": "Mountain bartlett pear purees", "code": "50385930" },
      { "name": "Olivier de serres pear purees", "code": "50385931" },
      { "name": "Onward pear purees", "code": "50385932" },
      { "name": "Packham's triumph pear purees", "code": "50385933" },
      { "name": "Paraiso pear purees", "code": "50385934" },
      { "name": "Passe crasanne pear purees", "code": "50385935" },
      { "name": "Perry pear purees", "code": "50385936" },
      { "name": "Red bartlett pear purees", "code": "50385937" },
      { "name": "Red d'anjou pear purees", "code": "50385938" },
      { "name": "Rocha pear purees", "code": "50385939" },
      { "name": "Rosey red pear purees", "code": "50385940" },
      { "name": "Rosy red pear purees", "code": "50385941" },
      { "name": "Royal majestic pear purees", "code": "50385942" },
      { "name": "Ruby red pear purees", "code": "50385943" },
      { "name": "Santa maria pear purees", "code": "50385944" },
      { "name": "Seckelp pear purees", "code": "50385945" },
      { "name": "Sensation pear purees", "code": "50385946" },
      { "name": "Star crimson pear purees", "code": "50385947" },
      { "name": "Stark crimson pear purees", "code": "50385948" },
      { "name": "Summer bartlett pear purees", "code": "50385949" },
      { "name": "Summer gold pear purees", "code": "50385950" },
      { "name": "Sun gold pear purees", "code": "50385951" },
      { "name": "Sunsprite pear purees", "code": "50385952" },
      { "name": "Taylors gold pear purees", "code": "50385953" },
      { "name": "Taylors red pear purees", "code": "50385954" },
      { "name": "Tientsin pear purees", "code": "50385955" },
      { "name": "Tosca pear purees", "code": "50385956" },
      { "name": "Warden pear purees", "code": "50385957" },
      { "name": "Williams bon chretien pear purees", "code": "50385958" },
      { "name": "Williams pear purees", "code": "50385959" },
      { "name": "Winter nelis pear purees", "code": "50385960" },
      { "name": "Persimmon purees", "code": "50386000" },
      { "name": "American persimmon purees", "code": "50386001" },
      { "name": "Black sapote persimmon purees", "code": "50386002" },
      { "name": "Chapote black persimmon purees", "code": "50386003" },
      { "name": "Date plum persimmon purees", "code": "50386004" },
      { "name": "Fuyu persimmon purees", "code": "50386005" },
      { "name": "Giant fuyu persimmon purees", "code": "50386006" },
      { "name": "Hachiya persimmon purees", "code": "50386007" },
      { "name": "Mabolo butter fruit persimmon purees", "code": "50386008" },
      { "name": "Principe ito persimmon purees", "code": "50386009" },
      { "name": "Royal brillante persimmon purees", "code": "50386010" },
      { "name": "Sharon fruit persimmon purees", "code": "50386011" },
      { "name": "Triumph persimmon purees", "code": "50386012" },
      { "name": "Pineapple purees", "code": "50386100" },
      { "name": "Cherimoya pineapple purees", "code": "50386101" },
      { "name": "Golden pineapple purees", "code": "50386102" },
      { "name": "Hilo pineapple purees", "code": "50386103" },
      { "name": "Kona sugarloaf pineapple purees", "code": "50386104" },
      { "name": "Natal queen pineapple purees", "code": "50386105" },
      { "name": "Pernambuco pineapple purees", "code": "50386106" },
      { "name": "Red spanish pineapple purees", "code": "50386107" },
      { "name": "Smooth cayenne pineapple purees", "code": "50386108" },
      { "name": "Sugarloaf pineapple purees", "code": "50386109" },
      { "name": "Variegated pineapple purees", "code": "50386110" },
      { "name": "Plucot purees", "code": "50386200" },
      { "name": "Black kat plucot purees", "code": "50386201" },
      { "name": "Blue gusto plucot purees", "code": "50386202" },
      { "name": "Crimson heart plucot purees", "code": "50386203" },
      { "name": "Dapple dandy plucot purees", "code": "50386204" },
      { "name": "Dapple fire plucot purees", "code": "50386205" },
      { "name": "Early dapple plucot purees", "code": "50386206" },
      { "name": "Flavor fall plucot purees", "code": "50386207" },
      { "name": "Flavor gold plucot purees", "code": "50386208" },
      { "name": "Flavor grenade plucot purees", "code": "50386209" },
      { "name": "Flavor heart plucot purees", "code": "50386210" },
      { "name": "Flavor jewel plucot purees", "code": "50386211" },
      { "name": "Flavor king plucot purees", "code": "50386212" },
      { "name": "Flavor queen plucot purees", "code": "50386213" },
      { "name": "Flavor supreme plucot purees", "code": "50386214" },
      { "name": "Flavor treat plucot purees", "code": "50386215" },
      { "name": "Flavorella plucot purees", "code": "50386216" },
      { "name": "Flavorich plucot purees", "code": "50386217" },
      { "name": "Flavorosa plucot purees", "code": "50386218" },
      { "name": "Geo pride plucot purees", "code": "50386219" },
      { "name": "Red kat plucot purees", "code": "50386220" },
      { "name": "Royal treat plucot purees", "code": "50386221" },
      { "name": "Sierra rose plucot purees", "code": "50386222" },
      { "name": "Sweet geisha plucot purees", "code": "50386223" },
      { "name": "Plum purees", "code": "50386300" },
      { "name": "Amber jewel plum purees", "code": "50386301" },
      { "name": "Angeleno plum purees", "code": "50386302" },
      { "name": "Aurora plum purees", "code": "50386303" },
      { "name": "Autumn beaut plum purees", "code": "50386304" },
      { "name": "Autumn giant plum purees", "code": "50386305" },
      { "name": "Autumn pride plum purees", "code": "50386306" },
      { "name": "Autumn rosa plum purees", "code": "50386307" },
      { "name": "Beach plum purees", "code": "50386308" },
      { "name": "Betty anne plum purees", "code": "50386309" },
      { "name": "Black beaut plum purees", "code": "50386310" },
      { "name": "Black bullace plum purees", "code": "50386311" },
      { "name": "Black diamond plum purees", "code": "50386312" },
      { "name": "Black giant plum purees", "code": "50386313" },
      { "name": "Black ice plum purees", "code": "50386314" },
      { "name": "Black splendor plum purees", "code": "50386315" },
      { "name": "Blackamber plum purees", "code": "50386316" },
      { "name": "Burgundy plum purees", "code": "50386317" },
      { "name": "Carlsbad plum purees", "code": "50386318" },
      { "name": "Casselman plum purees", "code": "50386319" },
      { "name": "Catalina plum purees", "code": "50386320" },
      { "name": "Damson plum purees", "code": "50386321" },
      { "name": "Dolly plum purees", "code": "50386322" },
      { "name": "Earliqueen plum purees", "code": "50386323" },
      { "name": "Early rosa plum purees", "code": "50386324" },
      { "name": "Ebony may plum purees", "code": "50386325" },
      { "name": "Ebony plum purees", "code": "50386326" },
      { "name": "Elephant heart plum purees", "code": "50386327" },
      { "name": "Emerald beaut plum purees", "code": "50386328" },
      { "name": "Empress plum purees", "code": "50386329" },
      { "name": "Freedom plum purees", "code": "50386330" },
      { "name": "Friar plum purees", "code": "50386331" },
      { "name": "Gar red plum purees", "code": "50386332" },
      { "name": "Governor's plum purees", "code": "50386333" },
      { "name": "Grand rosa plum purees", "code": "50386334" },
      { "name": "Green gage plum purees", "code": "50386335" },
      { "name": "Greengage plum purees", "code": "50386336" },
      { "name": "Hiromi plum purees", "code": "50386337" },
      { "name": "Hiromi red plum purees", "code": "50386338" },
      { "name": "Holiday plum purees", "code": "50386339" },
      { "name": "Howard sun plum purees", "code": "50386340" },
      { "name": "Interspecific type plum purees", "code": "50386341" },
      { "name": "Jamaican plum purees", "code": "50386342" },
      { "name": "Joanna red plum purees", "code": "50386343" },
      { "name": "Kelsey plum purees", "code": "50386344" },
      { "name": "King james plum purees", "code": "50386345" },
      { "name": "Laroda plum purees", "code": "50386346" },
      { "name": "Late rosa plum purees", "code": "50386347" },
      { "name": "Linda rosa plum purees", "code": "50386348" },
      { "name": "Lone star red plum purees", "code": "50386349" },
      { "name": "Mariposa plum purees", "code": "50386350" },
      { "name": "Marked black plum purees", "code": "50386351" },
      { "name": "Marked red plum purees", "code": "50386352" },
      { "name": "Mirabelle plum purees", "code": "50386353" },
      { "name": "October sun plum purees", "code": "50386354" },
      { "name": "Owen t plum purees", "code": "50386355" },
      { "name": "Perdrigon plum purees", "code": "50386356" },
      { "name": "Pink delight plum purees", "code": "50386357" },
      { "name": "President plum purees", "code": "50386358" },
      { "name": "Primetime plum purees", "code": "50386359" },
      { "name": "Purple majesty plum purees", "code": "50386360" },
      { "name": "Queen rosa plum purees", "code": "50386361" },
      { "name": "Quetsch plum purees", "code": "50386362" },
      { "name": "Red beaut plum purees", "code": "50386363" },
      { "name": "Red lane plum purees", "code": "50386364" },
      { "name": "Red ram plum purees", "code": "50386365" },
      { "name": "Red rosa plum purees", "code": "50386366" },
      { "name": "Rich red plum purees", "code": "50386367" },
      { "name": "Rosemary plum purees", "code": "50386368" },
      { "name": "Royal diamond plum purees", "code": "50386369" },
      { "name": "Royal red plum purees", "code": "50386370" },
      { "name": "Royal zee plum purees", "code": "50386371" },
      { "name": "Roysum plum purees", "code": "50386372" },
      { "name": "Santa rosa plum purees", "code": "50386373" },
      { "name": "Saphire plum purees", "code": "50386374" },
      { "name": "Sloe plum purees", "code": "50386375" },
      { "name": "St catherine plum purees", "code": "50386376" },
      { "name": "White bullace plum purees", "code": "50386377" },
      { "name": "Creole plum purees", "code": "50386378" },
      { "name": "Pomegranate purees", "code": "50386400" },
      { "name": "Foothill pommegranate purees", "code": "50386401" },
      { "name": "Granada pommegranate purees", "code": "50386402" },
      { "name": "Jolly red pommegranate purees", "code": "50386403" },
      { "name": "Nana pommegranate purees", "code": "50386404" },
      { "name": "Pat's red pommegranate purees", "code": "50386405" },
      { "name": "Pinkhan pommegranate purees", "code": "50386406" },
      { "name": "Purple velvet pommegranate purees", "code": "50386407" },
      { "name": "Wonderful pommegranate purees", "code": "50386408" },
      { "name": "Pomelo purees", "code": "50386500" },
      { "name": "Chandler pomelo purees", "code": "50386501" },
      { "name": "Hirado buntan pomelo purees", "code": "50386502" },
      { "name": "Liang ping yau pomelo purees", "code": "50386503" },
      { "name": "Pandan wangi pomelo purees", "code": "50386504" },
      { "name": "Pink pomelo purees", "code": "50386505" },
      { "name": "Red shaddock pomelo purees", "code": "50386506" },
      { "name": "Siamese sweet pomelo purees", "code": "50386507" },
      { "name": "Wainwright pomelo purees", "code": "50386508" },
      { "name": "Quince purees", "code": "50386600" },
      { "name": "Champion quince purees", "code": "50386601" },
      { "name": "Pineapple quince purees", "code": "50386602" },
      { "name": "Smyrna quince purees", "code": "50386603" },
      { "name": "Raspberry purees", "code": "50386700" },
      { "name": "American red raspberry purees", "code": "50386701" },
      { "name": "Bailey queensland raspberry purees", "code": "50386702" },
      { "name": "Black raspberry purees", "code": "50386703" },
      { "name": "Dark raspberry purees", "code": "50386704" },
      { "name": "Delicious raspberry purees", "code": "50386705" },
      { "name": "Focke dwarf raspberry purees", "code": "50386706" },
      { "name": "Focke grayleaf red raspberry purees", "code": "50386707" },
      { "name": "Focke strawberry raspberry purees", "code": "50386708" },
      { "name": "Focke yellow himalayan raspberry purees", "code": "50386709" },
      { "name": "Gold raspberry purees", "code": "50386710" },
      { "name": "Gray new mexico raspberry purees", "code": "50386711" },
      { "name": "Jepson whitebark raspberry purees", "code": "50386712" },
      { "name": "Kellogg san diego raspberry purees", "code": "50386713" },
      { "name": "Leucodermis whitebark raspberry purees", "code": "50386714" },
      { "name": "Munz cuyamaca raspberry purees", "code": "50386715" },
      { "name": "Peck barton's raspberry purees", "code": "50386716" },
      { "name": "Purpleflowering raspberry purees", "code": "50386717" },
      { "name": "Roadside raspberry purees", "code": "50386718" },
      { "name": "San diego raspberry purees", "code": "50386719" },
      { "name": "Snow raspberry purees", "code": "50386720" },
      { "name": "Snowpeaks raspberry purees", "code": "50386721" },
      { "name": "Strawberryleaf raspberry purees", "code": "50386722" },
      { "name": "Sweet cultivated raspberry purees", "code": "50386723" },
      {
        "name": "Torr and gray whitebark raspberry purees",
        "code": "50386724"
      },
      { "name": "West indian raspberry purees", "code": "50386725" },
      { "name": "Whitebark raspberry purees", "code": "50386726" },
      { "name": "Wine raspberry purees", "code": "50386727" },
      { "name": "Yellow himalayan raspberry purees", "code": "50386728" },
      { "name": "Yu-shan raspberry purees", "code": "50386729" },
      { "name": "Rhubarb purees", "code": "50386800" },
      { "name": "Crimson red rhubarb purees", "code": "50386801" },
      { "name": "Early champagne rhubarb purees", "code": "50386802" },
      { "name": "Glaskin's perpetual rhubarb purees", "code": "50386803" },
      { "name": "Sutton rhubarb purees", "code": "50386804" },
      { "name": "Timperley early rhubarb purees", "code": "50386805" },
      { "name": "Valentine rhubarb purees", "code": "50386806" },
      { "name": "Victoria rhubarb purees", "code": "50386807" },
      { "name": "Zwolle seedling rhubarb purees", "code": "50386808" },
      { "name": "Macdonald rhubarb purees", "code": "50386809" },
      { "name": "Tilden rhubarb purees", "code": "50386810" },
      { "name": "Rose hip purees", "code": "50386900" },
      { "name": "Brier rose hip purees", "code": "50386901" },
      { "name": "Elgantine rose hip purees", "code": "50386902" },
      { "name": "Rugosa rose hip purees", "code": "50386903" },
      { "name": "Scotch or burnet rose hip purees", "code": "50386904" },
      { "name": "Sapote purees", "code": "50387000" },
      { "name": "White sapote purees", "code": "50387001" },
      { "name": "Black sapote purees", "code": "50387002" },
      { "name": "Saskatoon berry purees", "code": "50387100" },
      { "name": "Honeywood saskatoon berry purees", "code": "50387101" },
      { "name": "Northline saskatoon berry purees", "code": "50387102" },
      { "name": "Smoky saskatoon berry purees", "code": "50387103" },
      { "name": "Thiessen saskatoon berry purees", "code": "50387104" },
      { "name": "Strawberry purees", "code": "50387200" },
      { "name": "Chandler strawberry purees", "code": "50387201" },
      { "name": "June bearing strawberry purees", "code": "50387202" },
      { "name": "Ever bearing strawberry purees", "code": "50387203" },
      { "name": "Sugar apple purees", "code": "50387300" },
      { "name": "Kampong mauve sugar apple purees", "code": "50387301" },
      { "name": "Seedless sugar apple purees", "code": "50387302" },
      { "name": "Thai lessard sugar apple purees", "code": "50387303" },
      { "name": "Tamarillo purees", "code": "50387400" },
      { "name": "Amberlea gold tamarillo purees", "code": "50387401" },
      { "name": "Bold gold tamarillo purees", "code": "50387402" },
      { "name": "Goldmine tamarillo purees", "code": "50387403" },
      { "name": "Oratia red tamarillo purees", "code": "50387404" },
      { "name": "Red beau tamarillo purees", "code": "50387405" },
      { "name": "Red delight tamarillo purees", "code": "50387406" },
      { "name": "Nominant fruit purees", "code": "50387500" },
      { "name": "Akee purees", "code": "50387501" },
      { "name": "Babaco purees", "code": "50387502" },
      { "name": "Banana flower purees", "code": "50387503" },
      { "name": "Baoba purees", "code": "50387504" },
      { "name": "Bitter orange purees", "code": "50387505" },
      { "name": "Canistel purees", "code": "50387506" },
      { "name": "Cloudberry purees", "code": "50387507" },
      { "name": "Coconut purees", "code": "50387508" },
      { "name": "Dewberry purees", "code": "50387509" },
      { "name": "Durian purees", "code": "50387510" },
      { "name": "Elderberry purees", "code": "50387511" },
      { "name": "Feijoa purees", "code": "50387512" },
      { "name": "Hackberry purees", "code": "50387513" },
      { "name": "Hawthorn purees", "code": "50387514" },
      { "name": "Honeyberry purees", "code": "50387515" },
      { "name": "Jackfruit purees", "code": "50387516" },
      { "name": "Jambolan purees", "code": "50387517" },
      { "name": "Jujube purees", "code": "50387518" },
      { "name": "Lychee purees", "code": "50387519" },
      { "name": "Mangosteen purees", "code": "50387520" },
      { "name": "Medlar purees", "code": "50387521" },
      { "name": "Mombin purees", "code": "50387522" },
      { "name": "Monstera purees", "code": "50387523" },
      { "name": "Pepino purees", "code": "50387524" },
      { "name": "Plantain purees", "code": "50387525" },
      { "name": "Prickly pear purees", "code": "50387526" },
      { "name": "Quenepa purees", "code": "50387527" },
      { "name": "Rambutan purees", "code": "50387528" },
      { "name": "Rose apple purees", "code": "50387529" },
      { "name": "Roselle purees", "code": "50387530" },
      { "name": "Rowanberry purees", "code": "50387531" },
      { "name": "Sea buckhorn berry purees", "code": "50387532" },
      { "name": "Silverberry purees", "code": "50387533" },
      { "name": "Sorb berry purees", "code": "50387534" },
      { "name": "Soursop purees", "code": "50387535" },
      { "name": "Star apple purees", "code": "50387536" },
      { "name": "Tamarindo purees", "code": "50387537" },
      { "name": "Camu camu purees", "code": "50387538" },
      { "name": "Lucuma purees", "code": "50387539" },
      { "name": "Araza purees", "code": "50387540" },
      { "name": "Copoazu purees", "code": "50387541" },
      { "name": "Poma rosa purees", "code": "50387542" },
      { "name": "Aguaje purees", "code": "50387543" },
      { "name": "Cocona purees", "code": "50387544" },
      { "name": "Guaba purees", "code": "50387545" },
      { "name": "Star fruit purees", "code": "50387546" },
      { "name": "Tarepiba purees", "code": "50387547" },
      { "name": "Casho purees", "code": "50387548" },
      { "name": "Taperiba purees", "code": "50387549" },
      { "name": "Humari purees", "code": "50387550" },
      { "name": "Chokeberry purees", "code": "50387600" },
      { "name": "Autumn magic chokeberry purees", "code": "50387601" },
      { "name": "Brillantisima chokeberry purees", "code": "50387602" },
      { "name": "Nero chokeberry purees", "code": "50387603" },
      { "name": "Viking chokeberry purees", "code": "50387604" },
      { "name": "Olive purees", "code": "50387700" },
      { "name": "Agrinion olive purees", "code": "50387701" },
      { "name": "Aleppo olive purees", "code": "50387702" },
      { "name": "Alphonso olive purees", "code": "50387703" },
      { "name": "Amphissa olive purees", "code": "50387704" },
      { "name": "Arauco olive purees", "code": "50387705" },
      { "name": "Arbequina olive purees", "code": "50387706" },
      { "name": "Atalanta olive purees", "code": "50387707" },
      { "name": "Cerignola olive purees", "code": "50387708" },
      { "name": "Cracked provencal olive purees", "code": "50387709" },
      { "name": "Empeltre olive purees", "code": "50387710" },
      { "name": "Gaeta olive purees", "code": "50387711" },
      { "name": "Hondroelia olive purees", "code": "50387712" },
      { "name": "Kalamata olive purees", "code": "50387713" },
      { "name": "Kura olive purees", "code": "50387714" },
      { "name": "Ligurian olive purees", "code": "50387715" },
      { "name": "Lucque olive purees", "code": "50387716" },
      { "name": "Lugano olive purees", "code": "50387717" },
      { "name": "Manzanilla olive purees", "code": "50387718" },
      { "name": "Marche olive purees", "code": "50387719" },
      { "name": "Mission olive purees", "code": "50387720" },
      { "name": "Nafplion green olive purees", "code": "50387721" },
      { "name": "Nicoise olive purees", "code": "50387722" },
      { "name": "Nyons olive purees", "code": "50387723" },
      { "name": "Picholine olive purees", "code": "50387724" },
      { "name": "Ponentine olive purees", "code": "50387725" },
      { "name": "Royal olive purees", "code": "50387726" },
      { "name": "Seracena olive purees", "code": "50387727" },
      { "name": "Sevillano olive purees", "code": "50387728" },
      { "name": "Sicilian olive purees", "code": "50387729" },
      { "name": "Toscanelle olive purees", "code": "50387730" },
      { "name": "Organic fresh fruit purees", "code": "50390000" },
      { "name": "Organic apple purees", "code": "50391500" },
      { "name": "Organic akane apple purees", "code": "50391501" },
      { "name": "Organic ambrosia apple purees", "code": "50391502" },
      { "name": "Organic api apple purees", "code": "50391503" },
      { "name": "Organic baldwin apple purees", "code": "50391504" },
      { "name": "Organic braeburn apple purees", "code": "50391505" },
      { "name": "Organic bramley apple purees", "code": "50391506" },
      { "name": "Organic bramley seedling apple purees", "code": "50391507" },
      {
        "name": "Organic calville blanche d'hiver apple purees",
        "code": "50391508"
      },
      { "name": "Organic cameo apple purees", "code": "50391509" },
      { "name": "Organic charles ross apple purees", "code": "50391510" },
      { "name": "Organic codlin apple purees", "code": "50391511" },
      { "name": "Organic cortland apple purees", "code": "50391512" },
      { "name": "Organic costard apple purees", "code": "50391513" },
      { "name": "Organic court pendu plat apple purees", "code": "50391514" },
      {
        "name": "Organic cox's orange pippin apple purees",
        "code": "50391515"
      },
      { "name": "Organic crab apple purees", "code": "50391516" },
      { "name": "Organic crispin apple purees", "code": "50391517" },
      { "name": "Organic delicious apple purees", "code": "50391518" },
      { "name": "Organic duchess apple purees", "code": "50391519" },
      { "name": "Organic earligold apple purees", "code": "50391520" },
      { "name": "Organic early mcintosh apple purees", "code": "50391521" },
      { "name": "Organic elstar apple purees", "code": "50391522" },
      { "name": "Organic empire apple purees", "code": "50391523" },
      { "name": "Organic flower of kent apple purees", "code": "50391524" },
      { "name": "Organic fuji apple purees", "code": "50391525" },
      { "name": "Organic gala apple purees", "code": "50391526" },
      { "name": "Organic gascoyne's scarlet apple purees", "code": "50391527" },
      { "name": "Organic giliflower apple purees", "code": "50391528" },
      { "name": "Organic ginger gold apple purees", "code": "50391529" },
      { "name": "Organic gladstone apple purees", "code": "50391530" },
      { "name": "Organic gloster apple purees", "code": "50391531" },
      { "name": "Organic gold supreme apple purees", "code": "50391532" },
      { "name": "Organic golden delicious apple purees", "code": "50391533" },
      { "name": "Organic golden noble apple purees", "code": "50391534" },
      { "name": "Organic granny smith apple purees", "code": "50391535" },
      { "name": "Organic gravenstein apple purees", "code": "50391536" },
      { "name": "Organic greening apple purees", "code": "50391537" },
      { "name": "Organic greensleeves apple purees", "code": "50391538" },
      { "name": "Organic honeycrisp apple purees", "code": "50391539" },
      { "name": "Organic howgate wonder apple purees", "code": "50391540" },
      { "name": "Organic ida red apple purees", "code": "50391541" },
      { "name": "Organic james grieve apple purees", "code": "50391542" },
      { "name": "Organic jersey mac apple purees", "code": "50391543" },
      { "name": "Organic jester apple purees", "code": "50391544" },
      { "name": "Organic jonagold apple purees", "code": "50391545" },
      { "name": "Organic jonamac apple purees", "code": "50391546" },
      { "name": "Organic jonathan apple purees", "code": "50391547" },
      { "name": "Organic katy apple purees", "code": "50391548" },
      { "name": "Organic kidd's orange red apple purees", "code": "50391549" },
      { "name": "Organic lady apple purees", "code": "50391550" },
      { "name": "Organic law rome apple purees", "code": "50391551" },
      { "name": "Organic laxton apple purees", "code": "50391552" },
      { "name": "Organic lord derby apple purees", "code": "50391553" },
      { "name": "Organic macoun apple purees", "code": "50391554" },
      { "name": "Organic mcintosh apple purees", "code": "50391555" },
      { "name": "Organic mutsu apple purees", "code": "50391556" },
      { "name": "Organic newtown pippin apple purees", "code": "50391557" },
      { "name": "Organic northern spy apple purees", "code": "50391558" },
      { "name": "Organic orleans reinette apple purees", "code": "50391559" },
      { "name": "Organic ozark gold apple purees", "code": "50391560" },
      { "name": "Organic pacific rose apple purees", "code": "50391561" },
      { "name": "Organic paula red apple purees", "code": "50391562" },
      { "name": "Organic pearmain apple purees", "code": "50391563" },
      { "name": "Organic pink lady apple purees", "code": "50391564" },
      { "name": "Organic pippin apple purees", "code": "50391565" },
      {
        "name": "Organic pitmaston pineapple apple purees",
        "code": "50391566"
      },
      { "name": "Organic pomme d'api apple purees", "code": "50391567" },
      { "name": "Organic prime gold apple purees", "code": "50391568" },
      { "name": "Organic red astrachan apple purees", "code": "50391569" },
      { "name": "Organic red boscoop apple purees", "code": "50391570" },
      { "name": "Organic red chief apple purees", "code": "50391571" },
      { "name": "Organic red delicious apple purees", "code": "50391572" },
      { "name": "Organic red gravenstein apple purees", "code": "50391573" },
      { "name": "Organic red rome apple purees", "code": "50391574" },
      { "name": "Organic red stayman apple purees", "code": "50391575" },
      { "name": "Organic red york apple purees", "code": "50391576" },
      { "name": "Organic reinette apple purees", "code": "50391577" },
      { "name": "Organic rome beauty apple purees", "code": "50391578" },
      { "name": "Organic russet apple purees", "code": "50391579" },
      { "name": "Organic sierra beauty apple purees", "code": "50391580" },
      { "name": "Organic spartan apple purees", "code": "50391581" },
      { "name": "Organic stark crimson apple purees", "code": "50391582" },
      { "name": "Organic starking apple purees", "code": "50391583" },
      { "name": "Organic stayman apple purees", "code": "50391584" },
      { "name": "Organic stayman winesap apple purees", "code": "50391585" },
      { "name": "Organic summer rambo apple purees", "code": "50391586" },
      { "name": "Organic tsugaru apple purees", "code": "50391587" },
      { "name": "Organic twenty ounce apple purees", "code": "50391588" },
      { "name": "Organic tydeman red apple purees", "code": "50391589" },
      { "name": "Organic vistabella apple purees", "code": "50391590" },
      { "name": "Organic wealthy apple purees", "code": "50391591" },
      { "name": "Organic white joaneting apple purees", "code": "50391592" },
      { "name": "Organic white transparent apple purees", "code": "50391593" },
      { "name": "Organic winesap apple purees", "code": "50391594" },
      { "name": "Organic worcester apple purees", "code": "50391595" },
      { "name": "Organic york imperial apple purees", "code": "50391596" },
      { "name": "Organic apricot purees", "code": "50391600" },
      { "name": "Organic ambercot apricot purees", "code": "50391601" },
      { "name": "Organic apache apricot purees", "code": "50391602" },
      { "name": "Organic birttany gold apricot purees", "code": "50391603" },
      { "name": "Organic black apricot purees", "code": "50391604" },
      { "name": "Organic blenheim apricot purees", "code": "50391605" },
      { "name": "Organic bonny apricot purees", "code": "50391606" },
      { "name": "Organic bulida apricot purees", "code": "50391607" },
      { "name": "Organic castlebrite apricot purees", "code": "50391608" },
      { "name": "Organic clutha gold apricot purees", "code": "50391609" },
      { "name": "Organic cluthasun apricot purees", "code": "50391610" },
      { "name": "Organic darby royal apricot purees", "code": "50391611" },
      { "name": "Organic dina apricot purees", "code": "50391612" },
      { "name": "Organic earlicot apricot purees", "code": "50391613" },
      { "name": "Organic earliman apricot purees", "code": "50391614" },
      { "name": "Organic early bright apricot purees", "code": "50391615" },
      { "name": "Organic flaming gold apricot purees", "code": "50391616" },
      { "name": "Organic fresno apricot purees", "code": "50391617" },
      { "name": "Organic gold brite apricot purees", "code": "50391618" },
      { "name": "Organic goldbar apricot purees", "code": "50391619" },
      { "name": "Organic golden sweet apricot purees", "code": "50391620" },
      { "name": "Organic goldrich apricot purees", "code": "50391621" },
      { "name": "Organic helena apricot purees", "code": "50391622" },
      { "name": "Organic honeycot apricot purees", "code": "50391623" },
      { "name": "Organic imperial apricot purees", "code": "50391624" },
      { "name": "Organic jordanne apricot purees", "code": "50391625" },
      { "name": "Organic jumbo cot apricot purees", "code": "50391626" },
      { "name": "Organic kandy kot apricot purees", "code": "50391627" },
      { "name": "Organic katy apricot purees", "code": "50391628" },
      { "name": "Organic king apricot purees", "code": "50391629" },
      { "name": "Organic lambertin apricot purees", "code": "50391630" },
      { "name": "Organic lorna apricot purees", "code": "50391631" },
      { "name": "Organic lulu belle apricot purees", "code": "50391632" },
      { "name": "Organic modesto apricot purees", "code": "50391633" },
      { "name": "Organic moorpark apricot purees", "code": "50391634" },
      { "name": "Organic orangered apricot purees", "code": "50391635" },
      { "name": "Organic palstein apricot purees", "code": "50391636" },
      { "name": "Organic patterson apricot purees", "code": "50391637" },
      { "name": "Organic perfection apricot purees", "code": "50391638" },
      { "name": "Organic poppy apricot purees", "code": "50391639" },
      { "name": "Organic poppycot apricot purees", "code": "50391640" },
      { "name": "Organic queen apricot purees", "code": "50391641" },
      { "name": "Organic riland apricot purees", "code": "50391642" },
      { "name": "Organic rival apricot purees", "code": "50391643" },
      { "name": "Organic robada apricot purees", "code": "50391644" },
      { "name": "Organic royal apricot purees", "code": "50391645" },
      { "name": "Organic royal blenheim apricot purees", "code": "50391646" },
      { "name": "Organic royal orange apricot purees", "code": "50391647" },
      { "name": "Organic sundrop apricot purees", "code": "50391648" },
      { "name": "Organic tilton apricot purees", "code": "50391649" },
      { "name": "Organic tomcot apricot purees", "code": "50391650" },
      { "name": "Organic tracy apricot purees", "code": "50391651" },
      { "name": "Organic tri gem apricot purees", "code": "50391652" },
      { "name": "Organic valley gold apricot purees", "code": "50391653" },
      { "name": "Organic westley apricot purees", "code": "50391654" },
      { "name": "Organic york apricot purees", "code": "50391655" },
      { "name": "Organic banana purees", "code": "50391700" },
      { "name": "Organic apple banana purees", "code": "50391701" },
      { "name": "Organic baby banana purees", "code": "50391702" },
      { "name": "Organic burro banana purees", "code": "50391703" },
      { "name": "Organic cavendish banana purees", "code": "50391704" },
      { "name": "Organic dominico banana purees", "code": "50391705" },
      { "name": "Organic green banana purees", "code": "50391706" },
      { "name": "Organic gros michel banana purees", "code": "50391707" },
      { "name": "Organic lacatan banana purees", "code": "50391708" },
      { "name": "Organic lady finger banan purees", "code": "50391709" },
      { "name": "Organic manzano banana purees", "code": "50391710" },
      { "name": "Organic mysore banana purees", "code": "50391711" },
      { "name": "Organic pisang mas banana purees", "code": "50391712" },
      { "name": "Organic red banana purees", "code": "50391713" },
      { "name": "Organic saba banana purees", "code": "50391714" },
      { "name": "Organic sucrier banana purees", "code": "50391715" },
      { "name": "Organic barberry purees", "code": "50391800" },
      { "name": "Organic paleleaf barberry purees", "code": "50391801" },
      { "name": "Organic chenault barberry purees", "code": "50391802" },
      { "name": "Organic red barberry purees", "code": "50391803" },
      { "name": "Organic wintergreen barberry purees", "code": "50391804" },
      { "name": "Organic korean barberry purees", "code": "50391805" },
      { "name": "Organic mentor barberry purees", "code": "50391806" },
      { "name": "Organic japanese barberry purees", "code": "50391807" },
      { "name": "Organic atropurpurea barberry purees", "code": "50391808" },
      { "name": "Organic aurea barberry purees", "code": "50391809" },
      { "name": "Organic bagatelle barberry purees", "code": "50391810" },
      { "name": "Organic crimson pygmy barberry purees", "code": "50391811" },
      { "name": "Organic kobold barberry purees", "code": "50391812" },
      { "name": "Organic warty barberry purees", "code": "50391813" },
      { "name": "Organic european barberry purees", "code": "50391814" },
      { "name": "Organic bearberry purees", "code": "50391900" },
      { "name": "Organic alpine bearberry purees", "code": "50391901" },
      { "name": "Organic red bearberry purees", "code": "50391902" },
      { "name": "Organic common bearberry purees", "code": "50391903" },
      { "name": "Organic blackberry purees", "code": "50392000" },
      { "name": "Organic apache blackberry purees", "code": "50392001" },
      { "name": "Organic black satin blackberry purees", "code": "50392002" },
      { "name": "Organic boysenberry purees", "code": "50392003" },
      { "name": "Organic cherokee blackberry purees", "code": "50392004" },
      { "name": "Organic chester blackberry purees", "code": "50392005" },
      { "name": "Organic dirksen blackberry purees", "code": "50392006" },
      { "name": "Organic jostaberry purees", "code": "50392007" },
      { "name": "Organic loganberry purees", "code": "50392008" },
      { "name": "Organic marionberry purees", "code": "50392009" },
      { "name": "Organic navaho blackberry purees", "code": "50392010" },
      { "name": "Organic nectarberry purees", "code": "50392011" },
      { "name": "Organic olallie blackberry purees", "code": "50392012" },
      { "name": "Organic tayberry purees", "code": "50392013" },
      {
        "name": "Organic thornless hull blackberry purees",
        "code": "50392014"
      },
      { "name": "Organic youngberry purees", "code": "50392015" },
      { "name": "Organic billberry purees", "code": "50392100" },
      { "name": "Organic bog bilberry purees", "code": "50392101" },
      { "name": "Organic dwarf bilberry purees", "code": "50392102" },
      { "name": "Organic mountain bilberry purees", "code": "50392103" },
      { "name": "Organic oval-leaved bilberry purees", "code": "50392104" },
      { "name": "Organic blueberry purees", "code": "50392200" },
      { "name": "Organic bluetta blueberry purees", "code": "50392201" },
      { "name": "Organic duke blueberry purees", "code": "50392202" },
      { "name": "Organic spartan blueberry purees", "code": "50392203" },
      { "name": "Organic patriot blueberry purees", "code": "50392204" },
      { "name": "Organic toro blueberry purees", "code": "50392205" },
      { "name": "Organic hardyblue blueberry purees", "code": "50392206" },
      { "name": "Organic bluecrop blueberry purees", "code": "50392207" },
      { "name": "Organic legacy blueberry purees", "code": "50392208" },
      { "name": "Organic nelson blueberry purees", "code": "50392209" },
      { "name": "Organic chandler blueberry purees", "code": "50392210" },
      { "name": "Organic brigitta blueberry purees", "code": "50392211" },
      { "name": "Organic northcountry blueberry purees", "code": "50392212" },
      { "name": "Organic northsky blueberry purees", "code": "50392213" },
      { "name": "Organic northblue blueberry purees", "code": "50392214" },
      { "name": "Organic misty blueberry purees", "code": "50392215" },
      { "name": "Organic breadfruit purees", "code": "50392300" },
      { "name": "Organic chataigne breadfruit purees", "code": "50392301" },
      { "name": "Organic seedless breadfruit purees", "code": "50392302" },
      { "name": "Organic white heart breadfruit purees", "code": "50392303" },
      { "name": "Organic yellow heart breadfruit purees", "code": "50392304" },
      { "name": "Organic cherimoya purees", "code": "50392400" },
      { "name": "Organic bays cherimoy purees", "code": "50392401" },
      { "name": "Organic bronceada cherimoy purees", "code": "50392402" },
      { "name": "Organic burtons cherimoy purees", "code": "50392403" },
      {
        "name": "Organic burtons favorite cherimoy purees",
        "code": "50392404"
      },
      { "name": "Organic jete cherimoy purees", "code": "50392405" },
      { "name": "Organic reretai cherimoy purees", "code": "50392406" },
      { "name": "Organic smoothey cherimoy purees", "code": "50392407" },
      { "name": "Organic spain cherimoy purees", "code": "50392408" },
      { "name": "Organic white cherimoy purees", "code": "50392409" },
      { "name": "Organic cherry purees", "code": "50392500" },
      { "name": "Organic amarelle cherry purees", "code": "50392501" },
      { "name": "Organic brooks cherry purees", "code": "50392502" },
      { "name": "Organic bigarreu cherry purees", "code": "50392503" },
      { "name": "Organic bing cherry purees", "code": "50392504" },
      { "name": "Organic black republic cherry purees", "code": "50392505" },
      { "name": "Organic black schmidt cherry purees", "code": "50392506" },
      { "name": "Organic black tartarian cherry purees", "code": "50392507" },
      { "name": "Organic fiesta bing cherry purees", "code": "50392508" },
      { "name": "Organic garnet cherry purees", "code": "50392509" },
      { "name": "Organic king cherry purees", "code": "50392510" },
      { "name": "Organic chapman cherry purees", "code": "50392511" },
      { "name": "Organic lapin cherry purees", "code": "50392512" },
      { "name": "Organic larian cherry purees", "code": "50392513" },
      { "name": "Organic dark guines cherry purees", "code": "50392514" },
      { "name": "Organic montmorency cherry purees", "code": "50392515" },
      { "name": "Organic duke cherry purees", "code": "50392516" },
      { "name": "Organic early rivers cherry purees", "code": "50392517" },
      { "name": "Organic ruby bing cherry purees", "code": "50392518" },
      { "name": "Organic santina cherry purees", "code": "50392519" },
      { "name": "Organic geans guines cherry purees", "code": "50392520" },
      { "name": "Organic sonata cherry purees", "code": "50392521" },
      { "name": "Organic lambert cherry purees", "code": "50392522" },
      { "name": "Organic stella cherry purees", "code": "50392523" },
      { "name": "Organic sweetheart cherry purees", "code": "50392524" },
      { "name": "Organic tartarian cherry purees", "code": "50392525" },
      { "name": "Organic maraschino cherry purees", "code": "50392526" },
      { "name": "Organic van cherry purees", "code": "50392527" },
      { "name": "Organic morello cherry purees", "code": "50392528" },
      { "name": "Organic royal ann cherry purees", "code": "50392529" },
      { "name": "Organic ranier cherry purees", "code": "50392530" },
      { "name": "Organic royal cherry purees", "code": "50392531" },
      { "name": "Organic citron purees", "code": "50392600" },
      { "name": "Organic buddha's hand citron purees", "code": "50392601" },
      { "name": "Organic fingered citron purees", "code": "50392602" },
      { "name": "Organic fo shoukan citron purees", "code": "50392603" },
      { "name": "Organic bushakan citron purees", "code": "50392604" },
      { "name": "Organic diamante citron purees", "code": "50392605" },
      { "name": "Organic etrog citron purees", "code": "50392606" },
      { "name": "Organic ponderosa citron purees", "code": "50392607" },
      { "name": "Organic cranberry purees", "code": "50392700" },
      { "name": "Organic ben lear cranberry purees", "code": "50392701" },
      { "name": "Organic early black cranberry purees", "code": "50392702" },
      { "name": "Organic grycleski cranberry purees", "code": "50392703" },
      { "name": "Organic howe cranberry purees", "code": "50392704" },
      { "name": "Organic lingonberry purees", "code": "50392705" },
      { "name": "Organic mcfarlin cranberry purees", "code": "50392706" },
      { "name": "Organic mountain cranberry purees", "code": "50392707" },
      { "name": "Organic pilgrim cranberry purees", "code": "50392708" },
      { "name": "Organic searless cranberry purees", "code": "50392709" },
      { "name": "Organic stevens cranberry purees", "code": "50392710" },
      { "name": "Organic currant purees", "code": "50392800" },
      { "name": "Organic hudson bay currant purees", "code": "50392801" },
      { "name": "Organic waxy currant purees", "code": "50392802" },
      { "name": "Organic desert currant purees", "code": "50392803" },
      { "name": "Organic black currant purees", "code": "50392804" },
      { "name": "Organic red currant purees", "code": "50392805" },
      { "name": "Organic white currant purees", "code": "50392806" },
      { "name": "Organic date purees", "code": "50392900" },
      { "name": "Organic asharasi date purees", "code": "50392901" },
      { "name": "Organic barhi or barhee date purees", "code": "50392902" },
      { "name": "Organic deglet noor date purees", "code": "50392903" },
      { "name": "Organic fardh date purees", "code": "50392904" },
      { "name": "Organic gundila date purees", "code": "50392905" },
      { "name": "Organic halawi halawy date purees", "code": "50392906" },
      { "name": "Organic hilali date purees", "code": "50392907" },
      { "name": "Organic khadrawi khadrawy date purees", "code": "50392908" },
      { "name": "Organic khalas date purees", "code": "50392909" },
      { "name": "Organic khustawi date purees", "code": "50392910" },
      { "name": "Organic khidri date purees", "code": "50392911" },
      { "name": "Organic medjool medjul date purees", "code": "50392912" },
      { "name": "Organic mactoum date purees", "code": "50392913" },
      { "name": "Organic neghal date purees", "code": "50392914" },
      { "name": "Organic yatimeh date purees", "code": "50392915" },
      { "name": "Organic zahidi date purees", "code": "50392916" },
      { "name": "Organic dragonfruit purees", "code": "50393000" },
      { "name": "Organic pink dragonfruit purees", "code": "50393001" },
      { "name": "Organic yellow dragonfruit purees", "code": "50393002" },
      { "name": "Organic fig purees", "code": "50393100" },
      { "name": "Organic bardajic fig purees", "code": "50393101" },
      { "name": "Organic brown turkey fig purees", "code": "50393102" },
      { "name": "Organic calimyrna fig purees", "code": "50393103" },
      { "name": "Organic conadria fig purees", "code": "50393104" },
      { "name": "Organic dottado fig purees", "code": "50393105" },
      { "name": "Organic kadota fig purees", "code": "50393106" },
      { "name": "Organic mediterranean fig purees", "code": "50393107" },
      { "name": "Organic mission fig purees", "code": "50393108" },
      { "name": "Organic smyrna fig purees", "code": "50393109" },
      { "name": "Organic verdona fig purees", "code": "50393110" },
      { "name": "Organic white king fig purees", "code": "50393111" },
      { "name": "Organic gooseberry purees", "code": "50393200" },
      { "name": "Organic early sulphur gooseberry purees", "code": "50393201" },
      { "name": "Organic goldendrop gooseberry purees", "code": "50393202" },
      { "name": "Organic langley gage gooseberry purees", "code": "50393203" },
      { "name": "Organic leveller gooseberry purees", "code": "50393204" },
      { "name": "Organic london gooseberry purees", "code": "50393205" },
      {
        "name": "Organic worcestershire gooseberry purees",
        "code": "50393206"
      },
      {
        "name": "Organic american worcesterberry gooseberry purees",
        "code": "50393207"
      },
      { "name": "Organic grapefruit purees", "code": "50393300" },
      { "name": "Organic burgundy grapefruit purees", "code": "50393301" },
      { "name": "Organic duncan grapefruit purees", "code": "50393302" },
      { "name": "Organic foster grapefruit purees", "code": "50393303" },
      { "name": "Organic marsh grapefruit purees", "code": "50393304" },
      { "name": "Organic new zealand grapefruit purees", "code": "50393305" },
      { "name": "Organic rio red grapefruit purees", "code": "50393306" },
      { "name": "Organic ruby red grapefruit purees", "code": "50393307" },
      { "name": "Organic star ruby grapefruit purees", "code": "50393308" },
      { "name": "Organic triumph grapefruit purees", "code": "50393309" },
      { "name": "Organic table grape purees", "code": "50393400" },
      { "name": "Organic alicante grape purees", "code": "50393401" },
      { "name": "Organic almeria grape purees", "code": "50393402" },
      { "name": "Organic alphonse lavalle grape purees", "code": "50393403" },
      { "name": "Organic autumn king grape purees", "code": "50393404" },
      { "name": "Organic autumn royal grape purees", "code": "50393405" },
      { "name": "Organic autumn seedless grape purees", "code": "50393406" },
      { "name": "Organic baresana grape purees", "code": "50393407" },
      { "name": "Organic barlinka grape purees", "code": "50393408" },
      { "name": "Organic beauty seedless grape purees", "code": "50393409" },
      {
        "name": "Organic black beauty seedless grape purees",
        "code": "50393410"
      },
      { "name": "Organic black emerald grape purees", "code": "50393411" },
      { "name": "Organic black giant grape purees", "code": "50393412" },
      { "name": "Organic black globe grape purees", "code": "50393413" },
      { "name": "Organic black monukka grape purees", "code": "50393414" },
      { "name": "Organic black pearl grape purees", "code": "50393415" },
      { "name": "Organic black seedless grape purees", "code": "50393416" },
      { "name": "Organic bonheur grape purees", "code": "50393417" },
      { "name": "Organic calmeria grape purees", "code": "50393418" },
      { "name": "Organic cardinal grape purees", "code": "50393419" },
      { "name": "Organic catawba grape purees", "code": "50393420" },
      {
        "name": "Organic chasselas golden chasselas grape purees",
        "code": "50393421"
      },
      { "name": "Organic christmas rose grape purees", "code": "50393422" },
      { "name": "Organic concord grape purees", "code": "50393423" },
      { "name": "Organic concord seedless grape purees", "code": "50393424" },
      { "name": "Organic crimson seedless grape purees", "code": "50393425" },
      { "name": "Organic dauphine grape purees", "code": "50393426" },
      { "name": "Organic delaware grape purees", "code": "50393427" },
      { "name": "Organic early muscat grape purees", "code": "50393428" },
      { "name": "Organic early sweet grape purees", "code": "50393429" },
      { "name": "Organic emerald seedless grape purees", "code": "50393430" },
      { "name": "Organic emperatriz grape purees", "code": "50393431" },
      { "name": "Organic emperor grape purees", "code": "50393432" },
      { "name": "Organic empress grape purees", "code": "50393433" },
      { "name": "Organic exotic grape purees", "code": "50393434" },
      { "name": "Organic fantasy grape purees", "code": "50393435" },
      { "name": "Organic fantasy seedless grape purees", "code": "50393436" },
      { "name": "Organic flame grape purees", "code": "50393437" },
      { "name": "Organic flame seedless grape purees", "code": "50393438" },
      { "name": "Organic flame tokay grape purees", "code": "50393439" },
      { "name": "Organic flaming red grape purees", "code": "50393440" },
      { "name": "Organic galaxy seedless grape purees", "code": "50393441" },
      { "name": "Organic gamay grape purees", "code": "50393442" },
      { "name": "Organic gold grape purees", "code": "50393443" },
      {
        "name": "Organic hanepoot or honeypot grape purees",
        "code": "50393444"
      },
      { "name": "Organic italia grape purees", "code": "50393445" },
      { "name": "Organic jade seedless grape purees", "code": "50393446" },
      { "name": "Organic jubilee grape purees", "code": "50393447" },
      { "name": "Organic king ruby grape purees", "code": "50393448" },
      { "name": "Organic kyoho grape purees", "code": "50393449" },
      { "name": "Organic la rochelle grape purees", "code": "50393450" },
      { "name": "Organic lady finger grape purees", "code": "50393451" },
      { "name": "Organic late seedless grape purees", "code": "50393452" },
      { "name": "Organic majestic seedless grape purees", "code": "50393453" },
      { "name": "Organic malaga grape purees", "code": "50393454" },
      { "name": "Organic marroot seedless grape purees", "code": "50393455" },
      { "name": "Organic muscadine grape purees", "code": "50393456" },
      { "name": "Organic muscat flame grape purees", "code": "50393457" },
      { "name": "Organic muscat grape purees", "code": "50393458" },
      { "name": "Organic muscat seedless grape purees", "code": "50393459" },
      { "name": "Organic napoleon grape purees", "code": "50393460" },
      { "name": "Organic negria grape purees", "code": "50393461" },
      { "name": "Organic new cross grape purees", "code": "50393462" },
      { "name": "Organic niabell grape purees", "code": "50393463" },
      { "name": "Organic niagara grape purees", "code": "50393464" },
      { "name": "Organic olivette grape purees", "code": "50393465" },
      { "name": "Organic perlette grape purees", "code": "50393466" },
      { "name": "Organic perlon grape purees", "code": "50393467" },
      {
        "name": "Organic prima black seedless grape purees",
        "code": "50393468"
      },
      { "name": "Organic princess grape purees", "code": "50393469" },
      { "name": "Organic queen grape purees", "code": "50393470" },
      { "name": "Organic red blush grape purees", "code": "50393471" },
      { "name": "Organic red globe grape purees", "code": "50393472" },
      { "name": "Organic red malaga grape purees", "code": "50393473" },
      { "name": "Organic red seedless grape purees", "code": "50393474" },
      { "name": "Organic regina grape purees", "code": "50393475" },
      { "name": "Organic ribier grape purees", "code": "50393476" },
      { "name": "Organic rosita grape purees", "code": "50393477" },
      { "name": "Organic rouge grape purees", "code": "50393478" },
      {
        "name": "Organic royal black seedless grape purees",
        "code": "50393479"
      },
      { "name": "Organic ruby red seedless grape purees", "code": "50393480" },
      { "name": "Organic ruby seedless grape purees", "code": "50393481" },
      { "name": "Organic scarlet royal grape purees", "code": "50393482" },
      { "name": "Organic scuppernong grape purees", "code": "50393483" },
      { "name": "Organic sugarose grape purees", "code": "50393484" },
      { "name": "Organic sugarthirteen grape purees", "code": "50393485" },
      { "name": "Organic sugraone grape purees", "code": "50393486" },
      { "name": "Organic sugrasixteen grape purees", "code": "50393487" },
      { "name": "Organic sultana sun red grape purees", "code": "50393488" },
      { "name": "Organic summer royal grape purees", "code": "50393489" },
      { "name": "Organic sunset grape purees", "code": "50393490" },
      { "name": "Organic superior seedless grape purees", "code": "50393491" },
      { "name": "Organic thompson seedless grape purees", "code": "50393492" },
      { "name": "Organic tokay pinot gris grape purees", "code": "50393493" },
      { "name": "Organic waltman cross grape purees", "code": "50393494" },
      { "name": "Organic white seedless grape purees", "code": "50393495" },
      { "name": "Organic zante current grape purees", "code": "50393496" },
      { "name": "Organic raisin grape purees", "code": "50393500" },
      { "name": "Organic black corinth grape purees", "code": "50393501" },
      { "name": "Organic canner grape purees", "code": "50393502" },
      { "name": "Organic dovine grape purees", "code": "50393503" },
      { "name": "Organic fiesta grape purees", "code": "50393504" },
      { "name": "Organic selma pete grape purees", "code": "50393505" },
      { "name": "Organic sultana grape purees", "code": "50393506" },
      { "name": "Organic wine grape purees", "code": "50393600" },
      { "name": "Organic alicante bouschet grape purees", "code": "50393601" },
      { "name": "Organic barbera grape purees", "code": "50393602" },
      { "name": "Organic burger grape purees", "code": "50393603" },
      { "name": "Organic cabernet franc grape purees", "code": "50393604" },
      { "name": "Organic cabernet sauvignon grape purees", "code": "50393605" },
      { "name": "Organic carignane grape purees", "code": "50393606" },
      { "name": "Organic carnelian grape purees", "code": "50393607" },
      { "name": "Organic catarratto grape purees", "code": "50393608" },
      { "name": "Organic centurian grape purees", "code": "50393609" },
      { "name": "Organic charbono grape purees", "code": "50393610" },
      { "name": "Organic chardonnay grape purees", "code": "50393611" },
      { "name": "Organic chenin blanc grape purees", "code": "50393612" },
      { "name": "Organic cinsaut grape purees", "code": "50393613" },
      { "name": "Organic dolcetto grape purees", "code": "50393614" },
      { "name": "Organic emerald riesling grape purees", "code": "50393615" },
      { "name": "Organic french colombard grape purees", "code": "50393616" },
      { "name": "Organic gamay or napa grape purees", "code": "50393617" },
      { "name": "Organic gamay beaujolais grape purees", "code": "50393618" },
      { "name": "Organic gewurztraminer grape purees", "code": "50393619" },
      { "name": "Organic grenache grape purees", "code": "50393620" },
      { "name": "Organic grenache blanc grape purees", "code": "50393621" },
      { "name": "Organic lagrein grape purees", "code": "50393622" },
      { "name": "Organic lambrusco grape purees", "code": "50393623" },
      { "name": "Organic malbec grape purees", "code": "50393624" },
      { "name": "Organic malvasia bianca grape purees", "code": "50393625" },
      { "name": "Organic marsanne grape purees", "code": "50393626" },
      { "name": "Organic mataro grape purees", "code": "50393627" },
      { "name": "Organic merlot grape purees", "code": "50393628" },
      { "name": "Organic meunier grape purees", "code": "50393629" },
      { "name": "Organic mission grape purees", "code": "50393630" },
      { "name": "Organic montepulciano grape purees", "code": "50393631" },
      { "name": "Organic muscat blanc grape purees", "code": "50393632" },
      { "name": "Organic muscat hamburg grape purees", "code": "50393633" },
      {
        "name": "Organic muscat of alexandria grape purees",
        "code": "50393634"
      },
      { "name": "Organic muscat orange grape purees", "code": "50393635" },
      { "name": "Organic nebbiolo grape purees", "code": "50393636" },
      { "name": "Organic palomino grape purees", "code": "50393637" },
      { "name": "Organic petit verdot grape purees", "code": "50393638" },
      { "name": "Organic petite sirah grape purees", "code": "50393639" },
      { "name": "Organic pinot blanc grape purees", "code": "50393640" },
      { "name": "Organic pinot gris grape purees", "code": "50393641" },
      { "name": "Organic pinot noir grape purees", "code": "50393642" },
      { "name": "Organic primitivo grape purees", "code": "50393643" },
      { "name": "Organic roussanne grape purees", "code": "50393644" },
      { "name": "Organic royalty grape purees", "code": "50393645" },
      { "name": "Organic rubired grape purees", "code": "50393646" },
      { "name": "Organic ruby cabernet grape purees", "code": "50393647" },
      { "name": "Organic salvador grape purees", "code": "50393648" },
      { "name": "Organic sangiovese grape purees", "code": "50393649" },
      { "name": "Organic sauvignon blanc grape purees", "code": "50393650" },
      { "name": "Organic sauvignon musque grape purees", "code": "50393651" },
      { "name": "Organic semillon grape purees", "code": "50393652" },
      { "name": "Organic souzao grape purees", "code": "50393653" },
      { "name": "Organic st emilion grape purees", "code": "50393654" },
      { "name": "Organic symphony grape purees", "code": "50393655" },
      { "name": "Organic syrah grape purees", "code": "50393656" },
      { "name": "Organic tannat grape purees", "code": "50393657" },
      { "name": "Organic tempranillo grape purees", "code": "50393658" },
      { "name": "Organic teroldego grape purees", "code": "50393659" },
      { "name": "Organic tocai friulano grape purees", "code": "50393660" },
      { "name": "Organic touriga nacional grape purees", "code": "50393661" },
      { "name": "Organic triplett blanc grape purees", "code": "50393662" },
      { "name": "Organic viognier grape purees", "code": "50393663" },
      { "name": "Organic white riesling grape purees", "code": "50393664" },
      { "name": "Organic zinfandel grape purees", "code": "50393665" },
      { "name": "Organic guava purees", "code": "50393700" },
      { "name": "Organic beaumont guava purees", "code": "50393701" },
      { "name": "Organic carrley guava purees", "code": "50393702" },
      { "name": "Organic lucida guava purees", "code": "50393703" },
      { "name": "Organic pineapple guav purees", "code": "50393704" },
      { "name": "Organic huckleberry purees", "code": "50393800" },
      { "name": "Organic black winter huckleberry purees", "code": "50393801" },
      { "name": "Organic cascade huckleberry purees", "code": "50393802" },
      { "name": "Organic dwarf huckleberry purees", "code": "50393803" },
      { "name": "Organic mountain huckleberry purees", "code": "50393804" },
      { "name": "Organic red huckleberry purees", "code": "50393805" },
      { "name": "Organic kiwi fruit purees", "code": "50393900" },
      { "name": "Organic ananasnaja kiwi fruit purees", "code": "50393901" },
      { "name": "Organic arctic beauty kiwi fruit purees", "code": "50393902" },
      { "name": "Organic blake kiwi fruit purees", "code": "50393903" },
      { "name": "Organic hayward kiwi fruit purees", "code": "50393904" },
      { "name": "Organic issai kiwi fruit purees", "code": "50393905" },
      { "name": "Organic siberian kiwi fruit purees", "code": "50393906" },
      { "name": "Organic kumquat purees", "code": "50394000" },
      { "name": "Organic hong kong kumquat purees", "code": "50394001" },
      { "name": "Organic limequat kumquat purees", "code": "50394002" },
      { "name": "Organic long fruit kumquat purees", "code": "50394003" },
      { "name": "Organic malayan kumquat purees", "code": "50394004" },
      { "name": "Organic meiwa kumquat purees", "code": "50394005" },
      { "name": "Organic nagami kumquat purees", "code": "50394006" },
      { "name": "Organic lemon purees", "code": "50394100" },
      { "name": "Organic baboon lemon purees", "code": "50394101" },
      { "name": "Organic bearss sicilian lemon purees", "code": "50394102" },
      { "name": "Organic cameron highlands lemon purees", "code": "50394103" },
      { "name": "Organic escondido lemon purees", "code": "50394104" },
      { "name": "Organic eureka lemon purees", "code": "50394105" },
      { "name": "Organic lisbon lemon purees", "code": "50394106" },
      { "name": "Organic meyer lemon purees", "code": "50394107" },
      { "name": "Organic volkamer lemon purees", "code": "50394108" },
      { "name": "Organic lime purees", "code": "50394200" },
      { "name": "Organic indian sweet lime purees", "code": "50394201" },
      { "name": "Organic key lime purees", "code": "50394202" },
      { "name": "Organic mandarin lime purees", "code": "50394203" },
      { "name": "Organic philippine lime purees", "code": "50394204" },
      { "name": "Organic tahitian lime purees", "code": "50394205" },
      { "name": "Organic bearss lime purees", "code": "50394206" },
      { "name": "Organic persian lime purees", "code": "50394207" },
      { "name": "Organic seedless lime purees", "code": "50394208" },
      { "name": "Organic loquat purees", "code": "50394300" },
      { "name": "Organic advance loquat purees", "code": "50394301" },
      { "name": "Organic benlehr loquat purees", "code": "50394302" },
      { "name": "Organic big jim loquat purees", "code": "50394303" },
      { "name": "Organic champagne loquat purees", "code": "50394304" },
      { "name": "Organic early red loquat purees", "code": "50394305" },
      { "name": "Organic gold nugget loquat purees", "code": "50394306" },
      { "name": "Organic herd's mammoth loquat purees", "code": "50394307" },
      { "name": "Organic mogi loquat purees", "code": "50394308" },
      { "name": "Organic mrs cooksey loquat purees", "code": "50394309" },
      { "name": "Organic strawberry loquat purees", "code": "50394310" },
      { "name": "Organic tanaka loquat purees", "code": "50394311" },
      {
        "name": "Organic victory vista white loquat purees",
        "code": "50394312"
      },
      { "name": "Organic wolfe loquat purees", "code": "50394313" },
      {
        "name": "Organic mandarin oranges or organic tangerine purees",
        "code": "50394400"
      },
      { "name": "Organic clauselinas orange purees", "code": "50394401" },
      { "name": "Organic clementine tangerine purees", "code": "50394402" },
      {
        "name": "Organic cleopatra mandarin orange purees",
        "code": "50394403"
      },
      { "name": "Organic dancy tangerine purees", "code": "50394404" },
      { "name": "Organic ellensdale orange purees", "code": "50394405" },
      { "name": "Organic fairchild orange purees", "code": "50394406" },
      { "name": "Organic fallglo orange purees", "code": "50394407" },
      { "name": "Organic fortune orange purees", "code": "50394408" },
      { "name": "Organic fremont mandarin orange purees", "code": "50394409" },
      { "name": "Organic fremont orange purees", "code": "50394410" },
      { "name": "Organic golden nugget orange purees", "code": "50394411" },
      { "name": "Organic honey mandarin orange purees", "code": "50394412" },
      { "name": "Organic honey orange purees", "code": "50394413" },
      { "name": "Organic honey tangerine purees", "code": "50394414" },
      {
        "name": "Organic honeybelle tangelo orange purees",
        "code": "50394415"
      },
      { "name": "Organic king mandarin orange purees", "code": "50394416" },
      { "name": "Organic kinnow orange purees", "code": "50394417" },
      { "name": "Organic lee mandarin orange purees", "code": "50394418" },
      { "name": "Organic makokkee orange purees", "code": "50394419" },
      { "name": "Organic malvasios orange purees", "code": "50394420" },
      {
        "name": "Organic mediterranean mandarin orange purees",
        "code": "50394421"
      },
      { "name": "Organic minneola tangelo orange purees", "code": "50394422" },
      { "name": "Organic monica orange purees", "code": "50394423" },
      { "name": "Organic murcott honey orange purees", "code": "50394424" },
      { "name": "Organic murcott tangor purees", "code": "50394425" },
      {
        "name": "Organic natsudaidai mandarin orange purees",
        "code": "50394426"
      },
      {
        "name": "Organic natsumikan mandarin orange purees",
        "code": "50394427"
      },
      { "name": "Organic nocatee tangelo orange purees", "code": "50394428" },
      { "name": "Organic orlando tangelo orange purees", "code": "50394429" },
      { "name": "Organic ortanique tangerine purees", "code": "50394430" },
      { "name": "Organic page mandarin orange purees", "code": "50394431" },
      { "name": "Organic pixie orange purees", "code": "50394432" },
      {
        "name": "Organic ponkan bantangas mandarin orange purees",
        "code": "50394433"
      },
      { "name": "Organic reyna orange purees", "code": "50394434" },
      { "name": "Organic robinson orange purees", "code": "50394435" },
      { "name": "Organic saltenitas orange purees", "code": "50394436" },
      { "name": "Organic sampson tangelo orange purees", "code": "50394437" },
      { "name": "Organic satsuma mandarin orange purees", "code": "50394438" },
      { "name": "Organic sunburst mandarin orange purees", "code": "50394439" },
      { "name": "Organic tangelo purees", "code": "50394500" },
      { "name": "Organic tangerina orange purees", "code": "50394501" },
      { "name": "Organic temple orange purees", "code": "50394502" },
      { "name": "Organic thornton orange purees", "code": "50394503" },
      { "name": "Organic wekiwa tangerine purees", "code": "50394504" },
      { "name": "Organic wilkins tangerine purees", "code": "50394505" },
      {
        "name": "Organic willowleaf mediterranean tangerine purees",
        "code": "50394506"
      },
      { "name": "Organic mango purees", "code": "50394600" },
      { "name": "Organic alphonso mango purees", "code": "50394601" },
      { "name": "Organic ataulfo mango purees", "code": "50394602" },
      { "name": "Organic criollo mango purees", "code": "50394603" },
      { "name": "Organic edwards mango purees", "code": "50394604" },
      { "name": "Organic francine mango purees", "code": "50394605" },
      { "name": "Organic francis mango purees", "code": "50394606" },
      { "name": "Organic gandaria mango purees", "code": "50394607" },
      { "name": "Organic haden mango purees", "code": "50394608" },
      { "name": "Organic irwin mango purees", "code": "50394609" },
      { "name": "Organic keitt mango purees", "code": "50394610" },
      { "name": "Organic kent mango purees", "code": "50394611" },
      { "name": "Organic kesar mango purees", "code": "50394612" },
      { "name": "Organic kuini mango purees", "code": "50394613" },
      { "name": "Organic manila super mango purees", "code": "50394614" },
      { "name": "Organic manila mango purees", "code": "50394615" },
      { "name": "Organic mayaguez mango purees", "code": "50394616" },
      { "name": "Organic mulgoba mango purees", "code": "50394617" },
      { "name": "Organic oro mango purees", "code": "50394618" },
      { "name": "Organic palmer mango purees", "code": "50394619" },
      { "name": "Organic parvin mango purees", "code": "50394620" },
      { "name": "Organic sandersha mango purees", "code": "50394621" },
      { "name": "Organic sensation mango purees", "code": "50394622" },
      { "name": "Organic smith mango purees", "code": "50394623" },
      { "name": "Organic tommy atkins mango purees", "code": "50394624" },
      { "name": "Organic van dyke mango purees", "code": "50394625" },
      { "name": "Organic melon purees", "code": "50394700" },
      { "name": "Organic allsweet melon purees", "code": "50394701" },
      { "name": "Organic athena melon purees", "code": "50394702" },
      { "name": "Organic black diamond melon purees", "code": "50394703" },
      { "name": "Organic cal sweet melon purees", "code": "50394704" },
      { "name": "Organic cantaloupe melon purees", "code": "50394705" },
      { "name": "Organic carnical melon purees", "code": "50394706" },
      { "name": "Organic casaba melon purees", "code": "50394707" },
      { "name": "Organic cavaillon melon purees", "code": "50394708" },
      { "name": "Organic charentais melon purees", "code": "50394709" },
      {
        "name": "Organic charleston gray watermelon purees",
        "code": "50394710"
      },
      { "name": "Organic crenshaw melon purees", "code": "50394711" },
      { "name": "Organic crimson sweet melon purees", "code": "50394712" },
      { "name": "Organic dixie lee melon purees", "code": "50394713" },
      { "name": "Organic eclipse melon purees", "code": "50394714" },
      { "name": "Organic ein d'or melon purees", "code": "50394715" },
      { "name": "Organic fiesta melon purees", "code": "50394716" },
      { "name": "Organic galia melon purees", "code": "50394717" },
      { "name": "Organic gaya melon purees", "code": "50394718" },
      { "name": "Organic hami melon purees", "code": "50394719" },
      { "name": "Organic honeydew melon purees", "code": "50394720" },
      { "name": "Organic icebox melon purees", "code": "50394721" },
      { "name": "Organic ida pride melon purees", "code": "50394722" },
      { "name": "Organic juan canary melon purees", "code": "50394723" },
      { "name": "Organic jubilee melon purees", "code": "50394724" },
      { "name": "Organic jubilation melon purees", "code": "50394725" },
      { "name": "Organic kakhi kakri melon purees", "code": "50394726" },
      { "name": "Organic kiwano melon purees", "code": "50394727" },
      { "name": "Organic korean melon purees", "code": "50394728" },
      { "name": "Organic long gray melon purees", "code": "50394729" },
      { "name": "Organic mayan melon purees", "code": "50394730" },
      { "name": "Organic micky lee melon purees", "code": "50394731" },
      { "name": "Organic mirage melon purees", "code": "50394732" },
      {
        "name": "Organic moon and stars watermelon purees",
        "code": "50394733"
      },
      { "name": "Organic ogen melon purees", "code": "50394734" },
      { "name": "Organic patriot melon purees", "code": "50394735" },
      { "name": "Organic peacock melon purees", "code": "50394736" },
      { "name": "Organic pepino melon purees", "code": "50394737" },
      { "name": "Organic persian melon purees", "code": "50394738" },
      { "name": "Organic picnic melon purees", "code": "50394739" },
      { "name": "Organic piel de sapo melon purees", "code": "50394740" },
      { "name": "Organic pineapple melon purees", "code": "50394741" },
      { "name": "Organic quetzali melon purees", "code": "50394742" },
      { "name": "Organic red goblin melon purees", "code": "50394743" },
      { "name": "Organic regency melon purees", "code": "50394744" },
      { "name": "Organic royal majestic melon purees", "code": "50394745" },
      { "name": "Organic royal star melon purees", "code": "50394746" },
      { "name": "Organic royal sweet melon purees", "code": "50394747" },
      { "name": "Organic santa claus melon purees", "code": "50394748" },
      { "name": "Organic sharlyn melon purees", "code": "50394749" },
      { "name": "Organic spanish melon purees", "code": "50394750" },
      { "name": "Organic sprite melon purees", "code": "50394751" },
      { "name": "Organic starbright melon purees", "code": "50394752" },
      { "name": "Organic stars n stripes melon purees", "code": "50394753" },
      { "name": "Organic sugar baby melon purees", "code": "50394754" },
      { "name": "Organic sugar baby watermelon purees", "code": "50394755" },
      { "name": "Organic sunsweet melon purees", "code": "50394756" },
      {
        "name": "Organic sweet heart seedless watermelon purees",
        "code": "50394757"
      },
      { "name": "Organic temptation melon purees", "code": "50394758" },
      { "name": "Organic tiger baby melon purees", "code": "50394759" },
      { "name": "Organic tuscan type melon purees", "code": "50394760" },
      { "name": "Organic yellow baby watermelon purees", "code": "50394761" },
      { "name": "Organic mulberry purees", "code": "50394800" },
      { "name": "Organic black mulberry purees", "code": "50394801" },
      { "name": "Organic white mulberry purees", "code": "50394802" },
      { "name": "Organic bayberry and myrtle purees", "code": "50394900" },
      { "name": "Organic bayberry purees", "code": "50394901" },
      { "name": "Organic bog myrtle purees", "code": "50394902" },
      { "name": "Organic nectarine purees", "code": "50395000" },
      { "name": "Organic april glo nectarine purees", "code": "50395001" },
      { "name": "Organic arctic mist nectarine purees", "code": "50395002" },
      { "name": "Organic arctic snow nectarine purees", "code": "50395003" },
      { "name": "Organic arctic star nectarine purees", "code": "50395004" },
      { "name": "Organic arctic sweet nectarine purees", "code": "50395005" },
      { "name": "Organic arctic glo nectarine purees", "code": "50395006" },
      { "name": "Organic august fire nectarine purees", "code": "50395007" },
      { "name": "Organic august pearl nectarine purees", "code": "50395008" },
      { "name": "Organic august red nectarine purees", "code": "50395009" },
      { "name": "Organic autumn star nectarine purees", "code": "50395010" },
      { "name": "Organic big john nectarine purees", "code": "50395011" },
      { "name": "Organic bright pearl nectarine purees", "code": "50395012" },
      { "name": "Organic diamond bright nectarine purees", "code": "50395013" },
      { "name": "Organic diamond ray nectarine purees", "code": "50395014" },
      { "name": "Organic earliglo nectarine purees", "code": "50395015" },
      { "name": "Organic early diamond nectarine purees", "code": "50395016" },
      { "name": "Organic fairlane nectarine purees", "code": "50395017" },
      { "name": "Organic fantasia nectarine purees", "code": "50395018" },
      { "name": "Organic fire pearl nectarine purees", "code": "50395019" },
      { "name": "Organic fire sweet nectarine purees", "code": "50395020" },
      { "name": "Organic flamekist nectarine purees", "code": "50395021" },
      { "name": "Organic flat type nectarine purees", "code": "50395022" },
      { "name": "Organic garden delight nectarine purees", "code": "50395023" },
      { "name": "Organic goldmine nectarine purees", "code": "50395024" },
      { "name": "Organic grand pearl nectarine purees", "code": "50395025" },
      { "name": "Organic hardired nectarine purees", "code": "50395026" },
      { "name": "Organic honey blaze nectarine purees", "code": "50395027" },
      { "name": "Organic july red nectarine purees", "code": "50395028" },
      { "name": "Organic kay pearl nectarine purees", "code": "50395029" },
      { "name": "Organic kay sweet nectarine purees", "code": "50395030" },
      { "name": "Organic may diamond nectarine purees", "code": "50395031" },
      { "name": "Organic mayfire nectarine purees", "code": "50395032" },
      { "name": "Organic mayglo nectarine purees", "code": "50395033" },
      { "name": "Organic mericrest nectarine purees", "code": "50395034" },
      { "name": "Organic red diamond nectarine purees", "code": "50395035" },
      { "name": "Organic red gold nectarine purees", "code": "50395036" },
      { "name": "Organic red jim nectarine purees", "code": "50395037" },
      { "name": "Organic red roy nectarine purees", "code": "50395038" },
      { "name": "Organic rio red nectarine purees", "code": "50395039" },
      { "name": "Organic rose diamond nectarine purees", "code": "50395040" },
      { "name": "Organic royal glo nectarine purees", "code": "50395041" },
      { "name": "Organic ruby diamond nectarine purees", "code": "50395042" },
      { "name": "Organic ruby sweet nectarine purees", "code": "50395043" },
      { "name": "Organic ruddy jewel nectarine purees", "code": "50395044" },
      { "name": "Organic september red nectarine purees", "code": "50395045" },
      { "name": "Organic snowqueen nectarine purees", "code": "50395046" },
      { "name": "Organic spring bright nectarine purees", "code": "50395047" },
      { "name": "Organic spring red nectarine purees", "code": "50395048" },
      { "name": "Organic summer blush nectarine purees", "code": "50395049" },
      { "name": "Organic summer brite nectarine purees", "code": "50395050" },
      { "name": "Organic summer diamond nectarine purees", "code": "50395051" },
      { "name": "Organic summer fire nectarine purees", "code": "50395052" },
      { "name": "Organic summer grand nectarine purees", "code": "50395053" },
      { "name": "Organic sunglo nectarine purees", "code": "50395054" },
      { "name": "Organic zee fire nectarine purees", "code": "50395055" },
      { "name": "Organic zee glo nectarine purees", "code": "50395056" },
      { "name": "Organic zeegrand nectarine purees", "code": "50395057" },
      { "name": "Organic orange purees", "code": "50395100" },
      { "name": "Organic african sour orange purees", "code": "50395101" },
      { "name": "Organic ambersweet orange purees", "code": "50395102" },
      { "name": "Organic argentine sour orange purees", "code": "50395103" },
      { "name": "Organic bahianinha orange purees", "code": "50395104" },
      { "name": "Organic bergamot orange purees", "code": "50395105" },
      { "name": "Organic berna orange purees", "code": "50395106" },
      { "name": "Organic bigaradier apepu orange purees", "code": "50395107" },
      {
        "name": "Organic bittersweet daidai orange purees",
        "code": "50395108"
      },
      { "name": "Organic blonde orange purees", "code": "50395109" },
      { "name": "Organic blood orange purees", "code": "50395110" },
      { "name": "Organic california navel orange purees", "code": "50395111" },
      { "name": "Organic cara cara orange purees", "code": "50395112" },
      { "name": "Organic chinotto orange purees", "code": "50395113" },
      { "name": "Organic dream navel orange purees", "code": "50395114" },
      { "name": "Organic gou tou orange purees", "code": "50395115" },
      { "name": "Organic hamlin orange purees", "code": "50395116" },
      { "name": "Organic jaffa orange purees", "code": "50395117" },
      { "name": "Organic jincheng orange purees", "code": "50395118" },
      { "name": "Organic k-early orange purees", "code": "50395119" },
      { "name": "Organic kona orange purees", "code": "50395120" },
      { "name": "Organic late navel orange purees", "code": "50395121" },
      { "name": "Organic late valencia orange purees", "code": "50395122" },
      { "name": "Organic limequat orange purees", "code": "50395123" },
      { "name": "Organic marr orange purees", "code": "50395124" },
      { "name": "Organic melogold orange purees", "code": "50395125" },
      { "name": "Organic moro orange purees", "code": "50395126" },
      { "name": "Organic moro tarocco orange purees", "code": "50395127" },
      { "name": "Organic navel orange purees", "code": "50395128" },
      { "name": "Organic navelina orange purees", "code": "50395129" },
      { "name": "Organic oro blanco orange purees", "code": "50395130" },
      { "name": "Organic osceola orange purees", "code": "50395131" },
      { "name": "Organic parson brown orange purees", "code": "50395132" },
      { "name": "Organic pera orange purees", "code": "50395133" },
      { "name": "Organic pummulo orange purees", "code": "50395134" },
      { "name": "Organic rhode red orange purees", "code": "50395135" },
      { "name": "Organic roble orange purees", "code": "50395136" },
      { "name": "Organic salustianas orange purees", "code": "50395137" },
      { "name": "Organic sanguine orange purees", "code": "50395138" },
      { "name": "Organic sanguinelli orange purees", "code": "50395139" },
      { "name": "Organic seville orange purees", "code": "50395140" },
      { "name": "Organic shamouti jaffa orange purees", "code": "50395141" },
      { "name": "Organic tunis orange purees", "code": "50395142" },
      { "name": "Organic valencia orange purees", "code": "50395143" },
      { "name": "Organic washington navel orange purees", "code": "50395144" },
      { "name": "Organic papaya purees", "code": "50395200" },
      { "name": "Organic green cooking papaya purees", "code": "50395201" },
      { "name": "Organic maradol papaya purees", "code": "50395202" },
      { "name": "Organic mexican yellow papaya purees", "code": "50395203" },
      { "name": "Organic mountain papaya purees", "code": "50395204" },
      { "name": "Organic solo papaya purees", "code": "50395205" },
      { "name": "Organic tainung papaya purees", "code": "50395206" },
      { "name": "Organic passion fruit purees", "code": "50395300" },
      { "name": "Organic banana passion fruit purees", "code": "50395301" },
      { "name": "Organic blue passion flowe purees", "code": "50395302" },
      {
        "name": "Organic crackerjack passion fruit purees",
        "code": "50395303"
      },
      {
        "name": "Organic giant granadilla passion fruit purees",
        "code": "50395304"
      },
      {
        "name": "Organic golden granadilla passion fruit purees",
        "code": "50395305"
      },
      { "name": "Organic maypops passion fruit purees", "code": "50395306" },
      {
        "name": "Organic red granadilla passion fruit purees",
        "code": "50395307"
      },
      {
        "name": "Organic sweet granadilla passion fruit purees",
        "code": "50395308"
      },
      {
        "name": "Organic water lemon passion fruit purees",
        "code": "50395309"
      },
      {
        "name": "Organic wing-stemmed passion flowe purees",
        "code": "50395310"
      },
      { "name": "Organic peach purees", "code": "50395400" },
      { "name": "Organic amber crest peach purees", "code": "50395401" },
      { "name": "Organic april snow peach purees", "code": "50395402" },
      { "name": "Organic august lady peach purees", "code": "50395403" },
      { "name": "Organic autumn flame peach purees", "code": "50395404" },
      { "name": "Organic autumn lady peach purees", "code": "50395405" },
      { "name": "Organic babcock peach purees", "code": "50395406" },
      { "name": "Organic brittney lane peach purees", "code": "50395407" },
      { "name": "Organic cary mac peach purees", "code": "50395408" },
      { "name": "Organic classic peach purees", "code": "50395409" },
      { "name": "Organic country sweet peach purees", "code": "50395410" },
      { "name": "Organic crest haven peach purees", "code": "50395411" },
      { "name": "Organic crimson lady peach purees", "code": "50395412" },
      { "name": "Organic crown princess peach purees", "code": "50395413" },
      { "name": "Organic david sun peach purees", "code": "50395414" },
      { "name": "Organic diamond princess peach purees", "code": "50395415" },
      { "name": "Organic earlirich peach purees", "code": "50395416" },
      { "name": "Organic early majestic peach purees", "code": "50395417" },
      { "name": "Organic early treat peach purees", "code": "50395418" },
      { "name": "Organic elegant lady peach purees", "code": "50395419" },
      { "name": "Organic empress peach purees", "code": "50395420" },
      { "name": "Organic encore peach purees", "code": "50395421" },
      { "name": "Organic fancy lady peach purees", "code": "50395422" },
      { "name": "Organic fire prince peach purees", "code": "50395423" },
      { "name": "Organic flame crest peach purees", "code": "50395424" },
      { "name": "Organic flat type peach purees", "code": "50395425" },
      { "name": "Organic flavorcrest peach purees", "code": "50395426" },
      { "name": "Organic florida prince peach purees", "code": "50395427" },
      { "name": "Organic full moon peach purees", "code": "50395428" },
      { "name": "Organic harvester peach purees", "code": "50395429" },
      { "name": "Organic ice princess peach purees", "code": "50395430" },
      { "name": "Organic ivory princess peach purees", "code": "50395431" },
      { "name": "Organic jersey queen peach purees", "code": "50395432" },
      { "name": "Organic john henry peach purees", "code": "50395433" },
      { "name": "Organic june prince peach purees", "code": "50395434" },
      { "name": "Organic kaweah peach purees", "code": "50395435" },
      { "name": "Organic klondike peach purees", "code": "50395436" },
      { "name": "Organic lindo peach purees", "code": "50395437" },
      { "name": "Organic loring peach purees", "code": "50395438" },
      { "name": "Organic majestic peach purees", "code": "50395439" },
      { "name": "Organic o'henry peach purees", "code": "50395440" },
      { "name": "Organic queencrest peach purees", "code": "50395441" },
      { "name": "Organic red lady peach purees", "code": "50395442" },
      { "name": "Organic redglobe peach purees", "code": "50395443" },
      { "name": "Organic redhaven peach purees", "code": "50395444" },
      { "name": "Organic redtop peach purees", "code": "50395445" },
      { "name": "Organic regina peach purees", "code": "50395446" },
      { "name": "Organic rich lady peach purees", "code": "50395447" },
      { "name": "Organic rich may peach purees", "code": "50395448" },
      { "name": "Organic royal glory peach purees", "code": "50395449" },
      { "name": "Organic royal lady peach purees", "code": "50395450" },
      { "name": "Organic september snow peach purees", "code": "50395451" },
      { "name": "Organic september sun peach purees", "code": "50395452" },
      { "name": "Organic sierra gem peach purees", "code": "50395453" },
      { "name": "Organic snow angel peach purees", "code": "50395454" },
      { "name": "Organic snow gem peach purees", "code": "50395455" },
      { "name": "Organic snow king peach purees", "code": "50395456" },
      { "name": "Organic spring lady peach purees", "code": "50395457" },
      { "name": "Organic spring snow peach purees", "code": "50395458" },
      { "name": "Organic springcrest peach purees", "code": "50395459" },
      { "name": "Organic sugar giant peach purees", "code": "50395460" },
      { "name": "Organic sugar lady peach purees", "code": "50395461" },
      { "name": "Organic sun bright peach purees", "code": "50395462" },
      { "name": "Organic sunhigh peach purees", "code": "50395463" },
      { "name": "Organic super lady peach purees", "code": "50395464" },
      { "name": "Organic super rich peach purees", "code": "50395465" },
      { "name": "Organic surecrop peach purees", "code": "50395466" },
      { "name": "Organic sweet dream peach purees", "code": "50395467" },
      { "name": "Organic sweet september peach purees", "code": "50395468" },
      { "name": "Organic vista peach purees", "code": "50395469" },
      { "name": "Organic white lady peach purees", "code": "50395470" },
      { "name": "Organic zee lady peach purees", "code": "50395471" },
      { "name": "Organic pear purees", "code": "50395500" },
      { "name": "Organic abate fetel pear purees", "code": "50395501" },
      { "name": "Organic anjou pear purees", "code": "50395502" },
      { "name": "Organic asian pear purees", "code": "50395503" },
      { "name": "Organic bartlett pear purees", "code": "50395504" },
      { "name": "Organic best ever pear purees", "code": "50395505" },
      { "name": "Organic beth pear purees", "code": "50395506" },
      { "name": "Organic beurr\u00e9 pear purees", "code": "50395507" },
      { "name": "Organic bosc pear purees", "code": "50395508" },
      { "name": "Organic clapp favorite pear purees", "code": "50395509" },
      { "name": "Organic comice pear purees", "code": "50395510" },
      { "name": "Organic concorde pear purees", "code": "50395511" },
      { "name": "Organic conference pear purees", "code": "50395512" },
      { "name": "Organic crimson red pear purees", "code": "50395513" },
      { "name": "Organic d'anjou pear purees", "code": "50395514" },
      { "name": "Organic dr jules guyot pear purees", "code": "50395515" },
      { "name": "Organic early pear purees", "code": "50395516" },
      { "name": "Organic emperor brown pear purees", "code": "50395517" },
      { "name": "Organic forelle pear purees", "code": "50395518" },
      { "name": "Organic french butter pear purees", "code": "50395519" },
      { "name": "Organic glou morceau pear purees", "code": "50395520" },
      { "name": "Organic hosui pear purees", "code": "50395521" },
      { "name": "Organic italian butter pear purees", "code": "50395522" },
      { "name": "Organic jargonelle pear purees", "code": "50395523" },
      { "name": "Organic juno pear purees", "code": "50395524" },
      {
        "name": "Organic kaiserlouise bonne de jersey pear purees",
        "code": "50395525"
      },
      { "name": "Organic keiffer pear purees", "code": "50395526" },
      { "name": "Organic kings royal pear purees", "code": "50395527" },
      { "name": "Organic limonera pear purees", "code": "50395528" },
      { "name": "Organic merton pride pear purees", "code": "50395529" },
      { "name": "Organic mountain bartlett pear purees", "code": "50395530" },
      { "name": "Organic olivier de serres pear purees", "code": "50395531" },
      { "name": "Organic onward pear purees", "code": "50395532" },
      { "name": "Organic packham's triumph pear purees", "code": "50395533" },
      { "name": "Organic paraiso pear purees", "code": "50395534" },
      { "name": "Organic passe crasanne pear purees", "code": "50395535" },
      { "name": "Organic perry pear purees", "code": "50395536" },
      { "name": "Organic red bartlett pear purees", "code": "50395537" },
      { "name": "Organic red d'anjou pear purees", "code": "50395538" },
      { "name": "Organic rocha pear purees", "code": "50395539" },
      { "name": "Organic rosey red pear purees", "code": "50395540" },
      { "name": "Organic rosy red pear purees", "code": "50395541" },
      { "name": "Organic royal majestic pear purees", "code": "50395542" },
      { "name": "Organic ruby red pear purees", "code": "50395543" },
      { "name": "Organic santa maria pear purees", "code": "50395544" },
      { "name": "Organic seckelp pear purees", "code": "50395545" },
      { "name": "Organic sensation pear purees", "code": "50395546" },
      { "name": "Organic star crimson pear purees", "code": "50395547" },
      { "name": "Organic stark crimson pear purees", "code": "50395548" },
      { "name": "Organic summer bartlett pear purees", "code": "50395549" },
      { "name": "Organic summer gold pear purees", "code": "50395550" },
      { "name": "Organic sun gold pear purees", "code": "50395551" },
      { "name": "Organic sunsprite pear purees", "code": "50395552" },
      { "name": "Organic taylors gold pear purees", "code": "50395553" },
      { "name": "Organic taylors red pear purees", "code": "50395554" },
      { "name": "Organic tientsin pear purees", "code": "50395555" },
      { "name": "Organic tosca pear purees", "code": "50395556" },
      { "name": "Organic warden pear purees", "code": "50395557" },
      {
        "name": "Organic williams bon chretien pear purees",
        "code": "50395558"
      },
      { "name": "Organic williams pear purees", "code": "50395559" },
      { "name": "Organic winter nelis pear purees", "code": "50395560" },
      { "name": "Organic persimmon purees", "code": "50395600" },
      { "name": "Organic american persimmo purees", "code": "50395601" },
      { "name": "Organic black sapote persimmo purees", "code": "50395602" },
      { "name": "Organic chapote black persimmo purees", "code": "50395603" },
      { "name": "Organic date plum persimmo purees", "code": "50395604" },
      { "name": "Organic fuyu persimmon purees", "code": "50395605" },
      { "name": "Organic giant fuyu persimmon purees", "code": "50395606" },
      { "name": "Organic hachiya persimmon purees", "code": "50395607" },
      {
        "name": "Organic mabolo butter fruit persimmo purees",
        "code": "50395608"
      },
      { "name": "Organic principe ito persimmon purees", "code": "50395609" },
      {
        "name": "Organic royal brillante persimmon purees",
        "code": "50395610"
      },
      { "name": "Organic sharon fruit persimmo purees", "code": "50395611" },
      { "name": "Organic triumph persimmon purees", "code": "50395612" },
      { "name": "Organic pineapple purees", "code": "50395700" },
      { "name": "Organic cherimoya pineapple purees", "code": "50395701" },
      { "name": "Organic golden pineapple purees", "code": "50395702" },
      { "name": "Organic hilo pineapple purees", "code": "50395703" },
      { "name": "Organic kona sugarloaf pineapple purees", "code": "50395704" },
      { "name": "Organic natal queen pineapple purees", "code": "50395705" },
      { "name": "Organic pernambuco pineapple purees", "code": "50395706" },
      { "name": "Organic red spanish pineapple purees", "code": "50395707" },
      { "name": "Organic smooth cayenne pineapple purees", "code": "50395708" },
      { "name": "Organic sugarloaf pineapple purees", "code": "50395709" },
      { "name": "Organic variegated pineapple purees", "code": "50395710" },
      { "name": "Organic plucot purees", "code": "50395800" },
      { "name": "Organic black kat plucot purees", "code": "50395801" },
      { "name": "Organic blue gusto plucot purees", "code": "50395802" },
      { "name": "Organic crimson heart plucot purees", "code": "50395803" },
      { "name": "Organic dapple dandy plucot purees", "code": "50395804" },
      { "name": "Organic dapple fire plucot purees", "code": "50395805" },
      { "name": "Organic early dapple plucot purees", "code": "50395806" },
      { "name": "Organic flavor fall plucot purees", "code": "50395807" },
      { "name": "Organic flavor gold plucot purees", "code": "50395808" },
      { "name": "Organic flavor grenade plucot purees", "code": "50395809" },
      { "name": "Organic flavor heart plucot purees", "code": "50395810" },
      { "name": "Organic flavor jewel plucot purees", "code": "50395811" },
      { "name": "Organic flavor king plucot purees", "code": "50395812" },
      { "name": "Organic flavor queen plucot purees", "code": "50395813" },
      { "name": "Organic flavor supreme plucot purees", "code": "50395814" },
      { "name": "Organic flavor treat plucot purees", "code": "50395815" },
      { "name": "Organic flavorella plucot purees", "code": "50395816" },
      { "name": "Organic flavorich plucot purees", "code": "50395817" },
      { "name": "Organic flavorosa plucot purees", "code": "50395818" },
      { "name": "Organic geo pride plucot purees", "code": "50395819" },
      { "name": "Organic red kat plucot purees", "code": "50395820" },
      { "name": "Organic royal treat plucot purees", "code": "50395821" },
      { "name": "Organic sierra rose plucot purees", "code": "50395822" },
      { "name": "Organic sweet geisha plucot purees", "code": "50395823" },
      { "name": "Organic plum purees", "code": "50395900" },
      { "name": "Organic amber jewel plum purees", "code": "50395901" },
      { "name": "Organic angeleno plum purees", "code": "50395902" },
      { "name": "Organic aurora plum purees", "code": "50395903" },
      { "name": "Organic autumn beaut plum purees", "code": "50395904" },
      { "name": "Organic autumn giant plum purees", "code": "50395905" },
      { "name": "Organic autumn pride plum purees", "code": "50395906" },
      { "name": "Organic autumn rosa plum purees", "code": "50395907" },
      { "name": "Organic beach plum purees", "code": "50395908" },
      { "name": "Organic betty anne plum purees", "code": "50395909" },
      { "name": "Organic black beaut plum purees", "code": "50395910" },
      { "name": "Organic black bullace plum purees", "code": "50395911" },
      { "name": "Organic black diamond plum purees", "code": "50395912" },
      { "name": "Organic black giant plum purees", "code": "50395913" },
      { "name": "Organic black ice plum purees", "code": "50395914" },
      { "name": "Organic black splendor plum purees", "code": "50395915" },
      { "name": "Organic blackamber plum purees", "code": "50395916" },
      { "name": "Organic burgundy plum purees", "code": "50395917" },
      { "name": "Organic carlsbad plum purees", "code": "50395918" },
      { "name": "Organic casselman plum purees", "code": "50395919" },
      { "name": "Organic catalina plum purees", "code": "50395920" },
      { "name": "Organic damson plum purees", "code": "50395921" },
      { "name": "Organic dolly plum purees", "code": "50395922" },
      { "name": "Organic earliqueen plum purees", "code": "50395923" },
      { "name": "Organic early rosa plum purees", "code": "50395924" },
      { "name": "Organic ebony may plum purees", "code": "50395925" },
      { "name": "Organic ebony plum purees", "code": "50395926" },
      { "name": "Organic elephant heart plum purees", "code": "50395927" },
      { "name": "Organic emerald beaut plum purees", "code": "50395928" },
      { "name": "Organic empress plum purees", "code": "50395929" },
      { "name": "Organic freedom plum purees", "code": "50395930" },
      { "name": "Organic friar plum purees", "code": "50395931" },
      { "name": "Organic gar red plum purees", "code": "50395932" },
      { "name": "Organic governor's plum purees", "code": "50395933" },
      { "name": "Organic grand rosa plum purees", "code": "50395934" },
      { "name": "Organic green gage plum purees", "code": "50395935" },
      { "name": "Organic greengage plum purees", "code": "50395936" },
      { "name": "Organic hiromi plum purees", "code": "50395937" },
      { "name": "Organic hiromi red plum purees", "code": "50395938" },
      { "name": "Organic holiday plum purees", "code": "50395939" },
      { "name": "Organic howard sun plum purees", "code": "50395940" },
      { "name": "Organic interspecific type plum purees", "code": "50395941" },
      { "name": "Organic jamaican plum purees", "code": "50395942" },
      { "name": "Organic joanna red plum purees", "code": "50395943" },
      { "name": "Organic kelsey plum purees", "code": "50395944" },
      { "name": "Organic king james plum purees", "code": "50395945" },
      { "name": "Organic laroda plum purees", "code": "50395946" },
      { "name": "Organic late rosa plum purees", "code": "50395947" },
      { "name": "Organic linda rosa plum purees", "code": "50395948" },
      { "name": "Organic lone star red plum purees", "code": "50395949" },
      { "name": "Organic mariposa plum purees", "code": "50395950" },
      { "name": "Organic marked black plum purees", "code": "50395951" },
      { "name": "Organic marked red plum purees", "code": "50395952" },
      { "name": "Organic mirabelle plum purees", "code": "50395953" },
      { "name": "Organic october sun plum purees", "code": "50395954" },
      { "name": "Organic owen t plum purees", "code": "50395955" },
      { "name": "Organic perdrigon plum purees", "code": "50395956" },
      { "name": "Organic pink delight plum purees", "code": "50395957" },
      { "name": "Organic president plum purees", "code": "50395958" },
      { "name": "Organic primetime plum purees", "code": "50395959" },
      { "name": "Organic purple majesty plum purees", "code": "50395960" },
      { "name": "Organic queen rosa plum purees", "code": "50395961" },
      { "name": "Organic quetsch plum purees", "code": "50395962" },
      { "name": "Organic red beaut plum purees", "code": "50395963" },
      { "name": "Organic red lane plum purees", "code": "50395964" },
      { "name": "Organic red ram plum purees", "code": "50395965" },
      { "name": "Organic red rosa plum purees", "code": "50395966" },
      { "name": "Organic rich red plum purees", "code": "50395967" },
      { "name": "Organic rosemary plum purees", "code": "50395968" },
      { "name": "Organic royal diamond plum purees", "code": "50395969" },
      { "name": "Organic royal red plum purees", "code": "50395970" },
      { "name": "Organic royal zee plum purees", "code": "50395971" },
      { "name": "Organic roysum plum purees", "code": "50395972" },
      { "name": "Organic santa rosa plum purees", "code": "50395973" },
      { "name": "Organic saphire plum purees", "code": "50395974" },
      { "name": "Organic sloe plum purees", "code": "50395975" },
      { "name": "Organic st catherine plum purees", "code": "50395976" },
      { "name": "Organic white bullace plum purees", "code": "50395977" },
      { "name": "Organic pommegranate purees", "code": "50396000" },
      { "name": "Organic foothill pommegranate purees", "code": "50396001" },
      { "name": "Organic granada pommegranate purees", "code": "50396002" },
      { "name": "Organic jolly red pommegranate purees", "code": "50396003" },
      { "name": "Organic nana pommegranate purees", "code": "50396004" },
      { "name": "Organic pat's red pommegranate purees", "code": "50396005" },
      { "name": "Organic pinkhan pommegranate purees", "code": "50396006" },
      {
        "name": "Organic purple velvet pommegranate purees",
        "code": "50396007"
      },
      { "name": "Organic wonderful pommegranate purees", "code": "50396008" },
      { "name": "Organic pomelo purees", "code": "50396100" },
      { "name": "Organic chandler pomelo purees", "code": "50396101" },
      { "name": "Organic hirado buntan pomelo purees", "code": "50396102" },
      { "name": "Organic liang ping yau pomelo purees", "code": "50396103" },
      { "name": "Organic pandan wangi pomelo purees", "code": "50396104" },
      { "name": "Organic pink pomelo purees", "code": "50396105" },
      { "name": "Organic red shaddock pomelo purees", "code": "50396106" },
      { "name": "Organic siamese sweet pomelo purees", "code": "50396107" },
      { "name": "Organic wainwright pomelo purees", "code": "50396108" },
      { "name": "Organic quince purees", "code": "50396200" },
      { "name": "Organic champion quinc purees", "code": "50396201" },
      { "name": "Organic pineapple quinc purees", "code": "50396202" },
      { "name": "Organic smyrna quinc purees", "code": "50396203" },
      { "name": "Organic raspberry purees", "code": "50396300" },
      { "name": "Organic american red raspberry purees", "code": "50396301" },
      {
        "name": "Organic bailey queensland raspberry purees",
        "code": "50396302"
      },
      { "name": "Organic black raspberry purees", "code": "50396303" },
      { "name": "Organic dark raspberry purees", "code": "50396304" },
      { "name": "Organic delicious raspberry purees", "code": "50396305" },
      { "name": "Organic focke dwarf raspberry purees", "code": "50396306" },
      {
        "name": "Organic focke grayleaf red raspberry purees",
        "code": "50396307"
      },
      {
        "name": "Organic focke strawberry raspberry purees",
        "code": "50396308"
      },
      {
        "name": "Organic focke yellow himalayan raspberry purees",
        "code": "50396309"
      },
      { "name": "Organic gold raspberry purees", "code": "50396310" },
      {
        "name": "Organic gray new mexico raspberry purees",
        "code": "50396311"
      },
      {
        "name": "Organic jepson whitebark raspberry purees",
        "code": "50396312"
      },
      {
        "name": "Organic kellogg san diego raspberry purees",
        "code": "50396313"
      },
      {
        "name": "Organic leucodermis whitebark raspberry purees",
        "code": "50396314"
      },
      { "name": "Organic munz cuyamaca raspberry purees", "code": "50396315" },
      { "name": "Organic peck barton's raspberry purees", "code": "50396316" },
      {
        "name": "Organic purpleflowering raspberry purees",
        "code": "50396317"
      },
      { "name": "Organic roadside raspberry purees", "code": "50396318" },
      { "name": "Organic san diego raspberry purees", "code": "50396319" },
      { "name": "Organic snow raspberry purees", "code": "50396320" },
      { "name": "Organic snowpeaks raspberry purees", "code": "50396321" },
      { "name": "Organic strawberryleaf raspberry purees", "code": "50396322" },
      {
        "name": "Organic sweet cultivated raspberry purees",
        "code": "50396323"
      },
      {
        "name": "Organic torr and gray whitebark raspberry purees",
        "code": "50396324"
      },
      { "name": "Organic west indian raspberry purees", "code": "50396325" },
      { "name": "Organic whitebark raspberry purees", "code": "50396326" },
      { "name": "Organic wine raspberry purees", "code": "50396327" },
      {
        "name": "Organic yellow himalayan raspberry purees",
        "code": "50396328"
      },
      { "name": "Organic yu-shan raspberry purees", "code": "50396329" },
      { "name": "Organic rhubarb purees", "code": "50396400" },
      { "name": "Organic crimson red rhubarb purees", "code": "50396401" },
      { "name": "Organic early champagne rhubarb purees", "code": "50396402" },
      {
        "name": "Organic glaskin's perpetual rhubarb purees",
        "code": "50396403"
      },
      { "name": "Organic sutton rhubarb purees", "code": "50396404" },
      { "name": "Organic timperley early rhubarb purees", "code": "50396405" },
      { "name": "Organic valentine rhubarb purees", "code": "50396406" },
      { "name": "Organic victoria rhubarb purees", "code": "50396407" },
      { "name": "Organic zwolle seedling rhubarb purees", "code": "50396408" },
      { "name": "Organic macdonald rhubarb purees", "code": "50396409" },
      { "name": "Organic tilden rhubarb purees", "code": "50396410" },
      { "name": "Organic rose hip purees", "code": "50396500" },
      { "name": "Organic brier rose hip purees", "code": "50396501" },
      { "name": "Organic elgantine rose hip purees", "code": "50396502" },
      { "name": "Organic rugosa rose hip purees", "code": "50396503" },
      {
        "name": "Organic scotch or burnet rose hip purees",
        "code": "50396504"
      },
      { "name": "Organic sapote purees", "code": "50396600" },
      { "name": "Organic white sapote purees", "code": "50396601" },
      { "name": "Organic black sapote purees", "code": "50396602" },
      { "name": "Organic saskatoon berry purees", "code": "50396700" },
      {
        "name": "Organic honeywood saskatoon berry purees",
        "code": "50396701"
      },
      {
        "name": "Organic northline saskatoon berry purees",
        "code": "50396702"
      },
      { "name": "Organic smoky saskatoon berry purees", "code": "50396703" },
      { "name": "Organic thiessen saskatoon berry purees", "code": "50396704" },
      { "name": "Organic strawberry purees", "code": "50396800" },
      { "name": "Organic chandler strawberry purees", "code": "50396801" },
      { "name": "Organic june bearing strawberry purees", "code": "50396802" },
      { "name": "Organic ever bearing strawberry purees", "code": "50396803" },
      { "name": "Organic sugar apple purees", "code": "50396900" },
      {
        "name": "Organic kampong mauve sugar apple purees",
        "code": "50396901"
      },
      { "name": "Organic seedless sugar apple purees", "code": "50396902" },
      { "name": "Organic thai lessard sugar apple purees", "code": "50396903" },
      { "name": "Organic tamarillo purees", "code": "50397000" },
      { "name": "Organic amberlea gold tamarillo purees", "code": "50397001" },
      { "name": "Organic bold gold tamarillo purees", "code": "50397002" },
      { "name": "Organic goldmine tamarillo purees", "code": "50397003" },
      { "name": "Organic oratia red tamarillo purees", "code": "50397004" },
      { "name": "Organic red beau tamarillo purees", "code": "50397005" },
      { "name": "Organic red delight tamarillo purees", "code": "50397006" },
      { "name": "Nominant organic fruit purees", "code": "50397100" },
      { "name": "Organic ake purees", "code": "50397101" },
      { "name": "Organic babac purees", "code": "50397102" },
      { "name": "Organic banana flower purees", "code": "50397103" },
      { "name": "Organic baoba purees", "code": "50397104" },
      { "name": "Organic bitter orange purees", "code": "50397105" },
      { "name": "Organic caniste purees", "code": "50397106" },
      { "name": "Organic cloudberry purees", "code": "50397107" },
      { "name": "Organic coconut purees", "code": "50397108" },
      { "name": "Organic dewberry purees", "code": "50397109" },
      { "name": "Organic duria purees", "code": "50397110" },
      { "name": "Organic elderberry purees", "code": "50397111" },
      { "name": "Organic feijo purees", "code": "50397112" },
      { "name": "Organic hackberry purees", "code": "50397113" },
      { "name": "Organic hawthorn purees", "code": "50397114" },
      { "name": "Organic honeyberry purees", "code": "50397115" },
      { "name": "Organic jackfruit purees", "code": "50397116" },
      { "name": "Organic jambola purees", "code": "50397117" },
      { "name": "Organic jujub purees", "code": "50397118" },
      { "name": "Organic lyche purees", "code": "50397119" },
      { "name": "Organic mangosteen purees", "code": "50397120" },
      { "name": "Organic medlar purees", "code": "50397121" },
      { "name": "Organic mombin purees", "code": "50397122" },
      { "name": "Organic monster purees", "code": "50397123" },
      { "name": "Organic pepino purees", "code": "50397124" },
      { "name": "Organic plantain purees", "code": "50397125" },
      { "name": "Organic prickly pear purees", "code": "50397126" },
      { "name": "Organic quenepa purees", "code": "50397127" },
      { "name": "Organic rambuta purees", "code": "50397128" },
      { "name": "Organic rose apple purees", "code": "50397129" },
      { "name": "Organic rosell purees", "code": "50397130" },
      { "name": "Organic rowanberry purees", "code": "50397131" },
      { "name": "Organic sea buckhorn berry purees", "code": "50397132" },
      { "name": "Organic silverberry purees", "code": "50397133" },
      { "name": "Organic sorb berry purees", "code": "50397134" },
      { "name": "Organic soursop purees", "code": "50397135" },
      { "name": "Organic star apple purees", "code": "50397136" },
      { "name": "Organic tamarind purees", "code": "50397137" },
      { "name": "Organic chokeberry purees", "code": "50397200" },
      { "name": "Organic autumn magic chokeberry purees", "code": "50397201" },
      { "name": "Organic brillantisima chokeberry purees", "code": "50397202" },
      { "name": "Organic nero chokeberry purees", "code": "50397203" },
      { "name": "Organic viking chokeberry purees", "code": "50397204" },
      { "name": "Organic olive purees", "code": "50397300" },
      { "name": "Organic agrinion olive purees", "code": "50397301" },
      { "name": "Organic aleppo olive purees", "code": "50397302" },
      { "name": "Organic alphonso olive purees", "code": "50397303" },
      { "name": "Organic amphissa olive purees", "code": "50397304" },
      { "name": "Organic arauco olive purees", "code": "50397305" },
      { "name": "Organic arbequina olive purees", "code": "50397306" },
      { "name": "Organic atalanta olive purees", "code": "50397307" },
      { "name": "Organic cerignola olive purees", "code": "50397308" },
      { "name": "Organic cracked provencal olive purees", "code": "50397309" },
      { "name": "Organic empeltre olive purees", "code": "50397310" },
      { "name": "Organic gaeta olive purees", "code": "50397311" },
      { "name": "Organic hondroelia olive purees", "code": "50397312" },
      { "name": "Organic kalamata olive purees", "code": "50397313" },
      { "name": "Organic kura olive purees", "code": "50397314" },
      { "name": "Organic ligurian olive purees", "code": "50397315" },
      { "name": "Organic lucque olive purees", "code": "50397316" },
      { "name": "Organic lugano olive purees", "code": "50397317" },
      { "name": "Organic manzanilla olive purees", "code": "50397318" },
      { "name": "Organic marche olive purees", "code": "50397319" },
      { "name": "Organic mission olive purees", "code": "50397320" },
      { "name": "Organic nafplion green olive purees", "code": "50397321" },
      { "name": "Organic nicoise olive purees", "code": "50397322" },
      { "name": "Organic nyons olive purees", "code": "50397323" },
      { "name": "Organic picholine olive purees", "code": "50397324" },
      { "name": "Organic ponentine olive purees", "code": "50397325" },
      { "name": "Organic royal olive purees", "code": "50397326" },
      { "name": "Organic seracena olive purees", "code": "50397327" },
      { "name": "Organic sevillano olive purees", "code": "50397328" },
      { "name": "Organic sicilian olive purees", "code": "50397329" },
      { "name": "Organic toscanelle olive purees", "code": "50397330" },
      { "name": "Fresh vegetables", "code": "50400000" },
      { "name": "Artichokes", "code": "50401500" },
      { "name": "Brittany artichokes", "code": "50401501" },
      { "name": "Catanese artichokes", "code": "50401502" },
      { "name": "French artichokes", "code": "50401503" },
      { "name": "Green globe artichokes", "code": "50401504" },
      { "name": "Gros camus de bretagne artichokes", "code": "50401505" },
      { "name": "Midi artichokes", "code": "50401506" },
      { "name": "Purple globe artichokes", "code": "50401507" },
      { "name": "Purple sicilian artichokes", "code": "50401508" },
      { "name": "Romanesco artichokes", "code": "50401509" },
      { "name": "Spinoso sardo artichokes", "code": "50401510" },
      { "name": "Vert de laon artichokes", "code": "50401511" },
      { "name": "Violetta di chioggia artichokes", "code": "50401512" },
      { "name": "Violetto di toscana artichokes", "code": "50401513" },
      { "name": "Asparagus", "code": "50401600" },
      { "name": "Connover's colossal asparagus", "code": "50401601" },
      { "name": "Franklin asparagus", "code": "50401602" },
      { "name": "Giant mammoth asparagus", "code": "50401603" },
      { "name": "Lucullus asparagus", "code": "50401604" },
      { "name": "Martha washington asparagus", "code": "50401605" },
      { "name": "Avocados", "code": "50401700" },
      { "name": "Ajax b-7 avocados", "code": "50401701" },
      { "name": "Arue avocados", "code": "50401702" },
      { "name": "Bacon avocados", "code": "50401703" },
      { "name": "Benik avocados", "code": "50401704" },
      { "name": "Bernecker avocados", "code": "50401705" },
      { "name": "Beta avocados", "code": "50401706" },
      { "name": "Biondo avocados", "code": "50401707" },
      { "name": "Black prince avocados", "code": "50401708" },
      { "name": "Blair avocados", "code": "50401709" },
      { "name": "Blair booth avocados", "code": "50401710" },
      { "name": "Booth 1 avocados", "code": "50401711" },
      { "name": "Booth 3 avocados", "code": "50401712" },
      { "name": "Booth 5 avocados", "code": "50401713" },
      { "name": "Booth 7 avocados", "code": "50401714" },
      { "name": "Booth 8 avocados", "code": "50401715" },
      { "name": "Brooks 1978 avocados", "code": "50401716" },
      { "name": "Brookslate avocados", "code": "50401717" },
      { "name": "California haas avocados", "code": "50401718" },
      { "name": "Catalina avocados", "code": "50401719" },
      { "name": "Chica avocados", "code": "50401720" },
      { "name": "Choquette avocados", "code": "50401721" },
      { "name": "Christina avocados", "code": "50401722" },
      { "name": "Collinson avocados", "code": "50401723" },
      { "name": "Donnie avocados", "code": "50401724" },
      { "name": "Dr dupuis number 2 avocados", "code": "50401725" },
      { "name": "Dr dupuis avocados", "code": "50401726" },
      { "name": "Ettinger avocados", "code": "50401727" },
      { "name": "Fuchs avocados", "code": "50401728" },
      { "name": "Fuchs gwen avocados", "code": "50401729" },
      { "name": "Fuerte avocados", "code": "50401730" },
      { "name": "Gorham avocados", "code": "50401731" },
      { "name": "Gossman avocados", "code": "50401732" },
      { "name": "Guatemalan seedling avocados", "code": "50401733" },
      { "name": "Hall avocados", "code": "50401734" },
      { "name": "Hardee avocados", "code": "50401735" },
      { "name": "haas avocados", "code": "50401736" },
      { "name": "Herman avocados", "code": "50401737" },
      { "name": "Hickson avocados", "code": "50401738" },
      { "name": "K-5 avocados", "code": "50401739" },
      { "name": "K-9 avocados", "code": "50401740" },
      { "name": "Lamb haas avocados", "code": "50401741" },
      { "name": "Leona avocados", "code": "50401742" },
      { "name": "Leona linda avocados", "code": "50401743" },
      { "name": "Lisa p avocados", "code": "50401744" },
      { "name": "Lisa loretta avocados", "code": "50401745" },
      { "name": "Loretta avocados", "code": "50401746" },
      { "name": "Lula avocados", "code": "50401747" },
      { "name": "Lula macarthur avocados", "code": "50401748" },
      { "name": "Marcus avocados", "code": "50401749" },
      { "name": "Melendez avocados", "code": "50401750" },
      { "name": "Meya p avocados", "code": "50401751" },
      { "name": "Miguel p avocados", "code": "50401752" },
      { "name": "Monroe avocados", "code": "50401753" },
      { "name": "Murrieta green avocados", "code": "50401754" },
      { "name": "Nabal avocados", "code": "50401755" },
      { "name": "Nadir avocados", "code": "50401756" },
      { "name": "Nesbitt avocados", "code": "50401757" },
      { "name": "Peterson avocados", "code": "50401758" },
      { "name": "Pinelli avocados", "code": "50401759" },
      { "name": "Pinkerton avocados", "code": "50401760" },
      { "name": "Pollock avocados", "code": "50401761" },
      { "name": "Puebla avocados", "code": "50401762" },
      { "name": "Reed avocados", "code": "50401763" },
      { "name": "Rue avocados", "code": "50401764" },
      { "name": "Ruehle avocados", "code": "50401765" },
      { "name": "Ryan avocados", "code": "50401766" },
      { "name": "Semil 34 avocados", "code": "50401767" },
      { "name": "Semil 43 avocados", "code": "50401768" },
      { "name": "Simmonds avocados", "code": "50401769" },
      { "name": "Simpson avocados", "code": "50401770" },
      { "name": "Taylor avocados", "code": "50401771" },
      { "name": "Tonnage avocados", "code": "50401772" },
      { "name": "Tower avocados", "code": "50401773" },
      { "name": "Tower li avocados", "code": "50401774" },
      { "name": "Trapp avocados", "code": "50401775" },
      { "name": "West indian seedling avocado", "code": "50401776" },
      { "name": "Wagner avocados", "code": "50401777" },
      { "name": "Waldin avocados", "code": "50401778" },
      { "name": "Wurtz avocados", "code": "50401779" },
      { "name": "Zio p avocados", "code": "50401780" },
      { "name": "Ziu avocados", "code": "50401781" },
      { "name": "Zutano avocados", "code": "50401782" },
      { "name": "Beans", "code": "50401800" },
      { "name": "Anasazi or aztec beans", "code": "50401801" },
      { "name": "Appaloosa beans", "code": "50401802" },
      { "name": "Azuki beans", "code": "50401803" },
      { "name": "Barlotti beans", "code": "50401804" },
      { "name": "Black appaloosa beans", "code": "50401805" },
      { "name": "Black beans", "code": "50401806" },
      { "name": "Black gram beans", "code": "50401807" },
      { "name": "Black shackamaxon beans", "code": "50401808" },
      { "name": "Blackeyed beans", "code": "50401809" },
      { "name": "Bobby beans", "code": "50401810" },
      { "name": "Bolita beans", "code": "50401811" },
      { "name": "Brown lazy wife beans", "code": "50401812" },
      { "name": "Calypso beans", "code": "50401813" },
      { "name": "Cannellini beans", "code": "50401814" },
      { "name": "Castor beans", "code": "50401815" },
      { "name": "China yellow beans", "code": "50401816" },
      { "name": "Dragon tongue beans", "code": "50401817" },
      { "name": "European soldier beans", "code": "50401818" },
      { "name": "Fava or broad beans", "code": "50401819" },
      { "name": "Flageolet beans", "code": "50401820" },
      { "name": "French horticultural beans", "code": "50401821" },
      { "name": "French navy beans", "code": "50401822" },
      { "name": "Giant white coco beans", "code": "50401823" },
      { "name": "Green beans", "code": "50401824" },
      { "name": "Green romano beans", "code": "50401825" },
      { "name": "Guar gum beans", "code": "50401826" },
      { "name": "Haricot beans", "code": "50401827" },
      { "name": "Hyacinth beans", "code": "50401828" },
      { "name": "Italian type beans", "code": "50401829" },
      { "name": "Jackson wonder beans", "code": "50401830" },
      { "name": "Jacob's cattle beans", "code": "50401831" },
      { "name": "Kentucky wonder beans", "code": "50401832" },
      { "name": "Kidney beans", "code": "50401833" },
      { "name": "Lima beans", "code": "50401834" },
      { "name": "Madeira/madera beans", "code": "50401835" },
      { "name": "Marrow beans", "code": "50401836" },
      { "name": "Mat beans", "code": "50401837" },
      { "name": "Monstoller wild goose beans", "code": "50401838" },
      { "name": "Mortgage lifter beans", "code": "50401839" },
      { "name": "Moth beans", "code": "50401840" },
      { "name": "Mung beans", "code": "50401841" },
      { "name": "Munsi wolf bean", "code": "50401842" },
      { "name": "Nuna beans", "code": "50401843" },
      { "name": "Pinto beans", "code": "50401844" },
      { "name": "Pole beans", "code": "50401845" },
      { "name": "Runner beans", "code": "50401846" },
      { "name": "String beans", "code": "50401847" },
      { "name": "Tamarind beans", "code": "50401848" },
      { "name": "Tonka beans", "code": "50401849" },
      { "name": "Wax beans", "code": "50401850" },
      { "name": "Winged beans", "code": "50401851" },
      { "name": "Yard long beans", "code": "50401852" },
      { "name": "Peruvian canary bean", "code": "50401853" },
      { "name": "Panamito bean", "code": "50401854" },
      { "name": "Beets", "code": "50401900" },
      { "name": "Action beets", "code": "50401901" },
      { "name": "Albina vereduna beets", "code": "50401902" },
      { "name": "Barbabietola di chioggia beets", "code": "50401903" },
      { "name": "Boltardy beets", "code": "50401904" },
      { "name": "Bonel beets", "code": "50401905" },
      { "name": "Burpees golden beets", "code": "50401906" },
      { "name": "Cheltenham green top beets", "code": "50401907" },
      { "name": "Cheltenham mono beets", "code": "50401908" },
      { "name": "Chioggia beets", "code": "50401909" },
      { "name": "Cylindra beets", "code": "50401910" },
      { "name": "D'egypte beets", "code": "50401911" },
      { "name": "Detroit 2 dark red beets", "code": "50401912" },
      { "name": "Detroit 2 little ball beets", "code": "50401913" },
      { "name": "Egyptian flat beets", "code": "50401914" },
      { "name": "Egyptian turnip rooted beets", "code": "50401915" },
      { "name": "Formanova beets", "code": "50401916" },
      { "name": "Forono beets", "code": "50401917" },
      { "name": "Monaco beets", "code": "50401918" },
      { "name": "Monogram beets", "code": "50401919" },
      { "name": "Pronto beets", "code": "50401920" },
      { "name": "Regalia beets", "code": "50401921" },
      { "name": "Sugar beets", "code": "50401922" },
      { "name": "Broccoli", "code": "50402000" },
      { "name": "Broccolini", "code": "50402001" },
      { "name": "Broccoli romanesco", "code": "50402002" },
      { "name": "Broccoli raab", "code": "50402003" },
      { "name": "Chinese broccoli", "code": "50402004" },
      { "name": "Brussel sprouts", "code": "50402100" },
      { "name": "citadel brussel sprouts", "code": "50402101" },
      { "name": "Falstaff brussel sprouts", "code": "50402102" },
      { "name": "Oliver brussel sprouts", "code": "50402103" },
      { "name": "Peer gynt brussel sprouts", "code": "50402104" },
      { "name": "Rampart brussel sprouts", "code": "50402105" },
      { "name": "Rubine brussel sprouts", "code": "50402106" },
      { "name": "Widgeon brussel sprouts", "code": "50402107" },
      { "name": "Bunching onions", "code": "50402200" },
      { "name": "Beltsville bunching onions", "code": "50402201" },
      { "name": "Feast bunching onions", "code": "50402202" },
      { "name": "Ishikura bunching onions", "code": "50402203" },
      { "name": "Kyoto market bunching onions", "code": "50402204" },
      { "name": "Red beard bunching onions", "code": "50402205" },
      { "name": "Redmate bunching onions", "code": "50402206" },
      { "name": "Santa claus bunching onions", "code": "50402207" },
      { "name": "Tokyo bunching onions", "code": "50402208" },
      { "name": "White lisbon bunching onions", "code": "50402209" },
      { "name": "Winter white bunching onions", "code": "50402210" },
      { "name": "Winter-over bunching onions", "code": "50402211" },
      { "name": "Cabbages", "code": "50402300" },
      { "name": "Black cabbages", "code": "50402301" },
      { "name": "Savoy cabbages", "code": "50402302" },
      { "name": "Skunk cabbages", "code": "50402303" },
      { "name": "White cabbages", "code": "50402304" },
      { "name": "Purple cabbage", "code": "50402305" },
      { "name": "Cardoons", "code": "50402400" },
      { "name": "Lunghi cardoons", "code": "50402401" },
      { "name": "Gobbi cardoons", "code": "50402402" },
      { "name": "Carrots", "code": "50402500" },
      { "name": "Amsterdam carrots", "code": "50402501" },
      { "name": "Autumn king carrots", "code": "50402502" },
      { "name": "Berlicum carrots", "code": "50402503" },
      { "name": "Chantenay carrots", "code": "50402504" },
      { "name": "Nantes carrots", "code": "50402505" },
      { "name": "Paris market carrots", "code": "50402506" },
      { "name": "Baby carrots", "code": "50402507" },
      { "name": "Cauliflowers", "code": "50402600" },
      { "name": "All the year round cauliflowers", "code": "50402601" },
      { "name": "Alverda cauliflowers", "code": "50402602" },
      { "name": "Autumn giant 3 cauliflowers", "code": "50402603" },
      { "name": "Dok elgon cauliflowers", "code": "50402604" },
      { "name": "Early snowball cauliflowers", "code": "50402605" },
      { "name": "Limelight cauliflowers", "code": "50402606" },
      { "name": "Minaret cauliflowers", "code": "50402607" },
      { "name": "Orange bouquet cauliflowers", "code": "50402608" },
      { "name": "Purple cape cauliflowers", "code": "50402609" },
      { "name": "Snowball cauliflowers", "code": "50402610" },
      { "name": "Walcheren winter 3 cauliflowers", "code": "50402611" },
      { "name": "White rock cauliflowers", "code": "50402612" },
      { "name": "Celery", "code": "50402700" },
      { "name": "Celebrity celery", "code": "50402701" },
      { "name": "Celeriac", "code": "50402702" },
      { "name": "Chinese celery", "code": "50402703" },
      { "name": "French dinant celery", "code": "50402704" },
      { "name": "Giant pink celery", "code": "50402705" },
      { "name": "Giant red celery", "code": "50402706" },
      { "name": "Giant white celery", "code": "50402707" },
      { "name": "Golden self-blanching celery", "code": "50402708" },
      { "name": "Greensleeves celery", "code": "50402709" },
      { "name": "Hopkins fenlander celery", "code": "50402710" },
      { "name": "Ivory tower celery", "code": "50402711" },
      { "name": "Lathom self-blanching celery", "code": "50402712" },
      { "name": "Soup celery d'amsterdam", "code": "50402713" },
      { "name": "Standard bearer celery", "code": "50402714" },
      { "name": "Tall utah triumph celery", "code": "50402715" },
      { "name": "Chards", "code": "50402800" },
      { "name": "Bright lights chard", "code": "50402801" },
      { "name": "Fordhook giant chard", "code": "50402802" },
      { "name": "Lucullus chard", "code": "50402803" },
      { "name": "Perpetual spinach chard", "code": "50402804" },
      { "name": "Rhubarb chard", "code": "50402805" },
      { "name": "Swiss chard", "code": "50402806" },
      { "name": "Vulcan chard", "code": "50402807" },
      { "name": "White king chard", "code": "50402808" },
      { "name": "Chicories", "code": "50402900" },
      { "name": "Broad leaved batavian chicory", "code": "50402901" },
      { "name": "En cornet de bordeaux chicory", "code": "50402902" },
      { "name": "Green curled ruffee chicory", "code": "50402903" },
      { "name": "Green curled chicory", "code": "50402904" },
      { "name": "Ione limnos chicory", "code": "50402905" },
      { "name": "Riccia pancalieri chicory", "code": "50402906" },
      { "name": "Salad king chicory", "code": "50402907" },
      { "name": "Sanda chicory", "code": "50402908" },
      { "name": "Scarola verde chicory", "code": "50402909" },
      { "name": "Tres fine maraichere chicory", "code": "50402910" },
      { "name": "Wallone freisee weschelkopf chicory", "code": "50402911" },
      { "name": "Chinese cabbages", "code": "50403000" },
      { "name": "Bok choy", "code": "50403001" },
      { "name": "Chinese flat-headed cabbage", "code": "50403002" },
      { "name": "Chinese flowering cabbage", "code": "50403003" },
      { "name": "Choy sum", "code": "50403004" },
      { "name": "Dwarf bok choy", "code": "50403005" },
      { "name": "Fengshan bok choy", "code": "50403006" },
      { "name": "Jade pagoda bok choy", "code": "50403007" },
      { "name": "Kasumi bok choy", "code": "50403008" },
      { "name": "Nerva bok choy", "code": "50403009" },
      { "name": "Rosette bok choy", "code": "50403010" },
      { "name": "Ruffles bok choy", "code": "50403011" },
      { "name": "Santo serrated leaved cabbage", "code": "50403012" },
      { "name": "Shanghai d bok choy", "code": "50403013" },
      { "name": "Shantung", "code": "50403014" },
      { "name": "Tip top cabbage", "code": "50403015" },
      { "name": "Yau choy sum", "code": "50403016" },
      { "name": "Chives", "code": "50403100" },
      { "name": "Chinese chives", "code": "50403101" },
      { "name": "Common chives", "code": "50403102" },
      { "name": "Corn", "code": "50403200" },
      { "name": "Aloha corn", "code": "50403201" },
      { "name": "Alpine corn", "code": "50403202" },
      { "name": "Ambrosia corn", "code": "50403203" },
      { "name": "Argent corn", "code": "50403204" },
      { "name": "Aspen corn", "code": "50403205" },
      { "name": "Avalanche corn", "code": "50403206" },
      { "name": "Biqueen corn", "code": "50403207" },
      { "name": "Bodacious corn", "code": "50403208" },
      { "name": "Butter and sugar corn", "code": "50403209" },
      { "name": "Calico belle corn", "code": "50403210" },
      { "name": "Camelot corn", "code": "50403211" },
      { "name": "Challengercrisp \u2018n sweet corn", "code": "50403212" },
      { "name": "Champ corn", "code": "50403213" },
      { "name": "Cotton candy corn", "code": "50403214" },
      { "name": "D\u2019artagnan corn", "code": "50403215" },
      { "name": "Dazzle corn", "code": "50403216" },
      { "name": "Diamonds and gold corn", "code": "50403217" },
      { "name": "Divinity corn", "code": "50403218" },
      { "name": "Double delight corn", "code": "50403219" },
      { "name": "Double gem corn", "code": "50403220" },
      { "name": "Earlivee corn", "code": "50403221" },
      { "name": "Early xtra sweet corn", "code": "50403222" },
      { "name": "Excel corn", "code": "50403223" },
      { "name": "Golden cross bantam corn", "code": "50403224" },
      { "name": "Honey and cream corn", "code": "50403225" },
      { "name": "Honey \u2018n pearl corn", "code": "50403226" },
      { "name": "How sweet it is corn", "code": "50403227" },
      { "name": "Hudson corn", "code": "50403228" },
      { "name": "Illini gold corn", "code": "50403229" },
      { "name": "Illini xtra sweet corn", "code": "50403230" },
      { "name": "Incredible corn", "code": "50403231" },
      { "name": "Iochief corn", "code": "50403232" },
      { "name": "Jubilee corn", "code": "50403233" },
      { "name": "Jubilee supersweet corn", "code": "50403234" },
      { "name": "Kandy korn corn", "code": "50403235" },
      { "name": "Kiss \u2018n tell corn", "code": "50403236" },
      { "name": "Lancelot corn", "code": "50403237" },
      { "name": "Maple sweet corn", "code": "50403238" },
      { "name": "Medley corn", "code": "50403239" },
      { "name": "Merlin corn", "code": "50403240" },
      { "name": "Miracle corn", "code": "50403241" },
      { "name": "Nk-199 corn", "code": "50403242" },
      { "name": "Peaches and cream corn", "code": "50403243" },
      { "name": "Pearl white corn", "code": "50403244" },
      { "name": "Pegasus corn", "code": "50403245" },
      { "name": "Phenomenal corn", "code": "50403246" },
      { "name": "Platinum lady corn", "code": "50403247" },
      { "name": "Precocious corn", "code": "50403248" },
      { "name": "Pristine corn", "code": "50403249" },
      { "name": "Quickie corn", "code": "50403250" },
      { "name": "Radiance corn", "code": "50403251" },
      { "name": "Seneca brave corn", "code": "50403252" },
      { "name": "Seneca dawn corn", "code": "50403253" },
      { "name": "Seneca horizon corn", "code": "50403254" },
      { "name": "Seneca starshine corn", "code": "50403255" },
      { "name": "Seneca white knight corn", "code": "50403256" },
      { "name": "Showcase corn", "code": "50403257" },
      { "name": "Silver queen corn", "code": "50403258" },
      { "name": "Snowbelle corn", "code": "50403259" },
      { "name": "Spring snow corn", "code": "50403260" },
      { "name": "Spring treat corn", "code": "50403261" },
      { "name": "Sugar and gold corn", "code": "50403262" },
      { "name": "Sugar buns corn", "code": "50403263" },
      { "name": "Sugar snow corn", "code": "50403264" },
      { "name": "Sundance corn", "code": "50403265" },
      { "name": "Telstar corn", "code": "50403266" },
      { "name": "Terminator corn", "code": "50403267" },
      { "name": "Treasure corn", "code": "50403268" },
      { "name": "Tuxedo corn", "code": "50403269" },
      { "name": "Cresses", "code": "50403300" },
      { "name": "Land cress", "code": "50403301" },
      { "name": "Nasturtium", "code": "50403302" },
      { "name": "Watercress", "code": "50403303" },
      { "name": "Wintercress", "code": "50403304" },
      { "name": "Cucumbers", "code": "50403400" },
      { "name": "Arena cucumbers", "code": "50403401" },
      { "name": "Armenian cucumbers", "code": "50403402" },
      { "name": "Athene cucumbers", "code": "50403403" },
      { "name": "Bianco lungo di parigi cucumbers", "code": "50403404" },
      { "name": "Burpless tasty green cucumbers", "code": "50403405" },
      { "name": "Chicago pickling cucumbers", "code": "50403406" },
      { "name": "Crystal apple cucumbers", "code": "50403407" },
      { "name": "Crystal lemon cucumbers", "code": "50403408" },
      { "name": "Danimas cucumbers", "code": "50403409" },
      { "name": "Gherkin cucumbers", "code": "50403410" },
      { "name": "Hokus cucumbers", "code": "50403411" },
      { "name": "Japanese cucumbers", "code": "50403412" },
      { "name": "Karela cucumbers", "code": "50403413" },
      { "name": "Korila cucumbers", "code": "50403414" },
      { "name": "Long green improved cucumbers", "code": "50403415" },
      { "name": "Marketmore cucumbers", "code": "50403416" },
      { "name": "Midget cucumbers", "code": "50403417" },
      { "name": "National pickling cucumbers", "code": "50403418" },
      { "name": "Persian cucumbers", "code": "50403419" },
      { "name": "Telegraph cucumbers", "code": "50403420" },
      { "name": "Telegraph improved cucumbers", "code": "50403421" },
      { "name": "Vert de massy cornichon cucumbers", "code": "50403422" },
      { "name": "Yamato cucumbers", "code": "50403423" },
      { "name": "Eggplants", "code": "50403500" },
      { "name": "Bambino eggplants", "code": "50403501" },
      { "name": "Black beauty eggplants", "code": "50403502" },
      { "name": "Black enorma eggplants", "code": "50403503" },
      { "name": "Chinese eggplants", "code": "50403504" },
      { "name": "Easter egg eggplants", "code": "50403505" },
      { "name": "Filipino eggplants", "code": "50403506" },
      { "name": "Florida market eggplants", "code": "50403507" },
      { "name": "Indian eggplants", "code": "50403508" },
      { "name": "Italian eggplants", "code": "50403509" },
      { "name": "Japanese eggplants", "code": "50403510" },
      { "name": "Long purple eggplants", "code": "50403511" },
      { "name": "Long striped eggplants", "code": "50403512" },
      { "name": "Moneymaker eggplants", "code": "50403513" },
      { "name": "Ova eggplants", "code": "50403514" },
      { "name": "Pea eggplants", "code": "50403515" },
      { "name": "Short tom eggplants", "code": "50403516" },
      { "name": "Sicilian eggplants", "code": "50403517" },
      { "name": "Thai eggplants", "code": "50403518" },
      { "name": "Violette di firenze eggplants", "code": "50403519" },
      { "name": "White eggplants", "code": "50403520" },
      { "name": "Endives", "code": "50403600" },
      { "name": "Brussels witloof endives", "code": "50403601" },
      { "name": "Castelfranco endives", "code": "50403602" },
      { "name": "Catalogna di galatina endives", "code": "50403603" },
      { "name": "Chioggia endives", "code": "50403604" },
      { "name": "Grumolo verde endives", "code": "50403605" },
      { "name": "Large rooted magdeburg endives", "code": "50403606" },
      { "name": "Palla rossa zorzi precoce endives", "code": "50403607" },
      { "name": "Radice amare endives", "code": "50403608" },
      { "name": "Rossa di treviso endives", "code": "50403609" },
      { "name": "Rossa di verona endives", "code": "50403610" },
      { "name": "Soncino endives", "code": "50403611" },
      { "name": "Sugarhat endives", "code": "50403612" },
      { "name": "Verona endives", "code": "50403613" },
      { "name": "Witloof zoom endives", "code": "50403614" },
      { "name": "Fennels", "code": "50403700" },
      { "name": "Cantino fennel", "code": "50403701" },
      { "name": "Fino fennel", "code": "50403702" },
      { "name": "Herald fennel", "code": "50403703" },
      { "name": "Perfection fennel", "code": "50403704" },
      { "name": "Sirio fennel", "code": "50403705" },
      { "name": "Sweet florence fennel", "code": "50403706" },
      { "name": "Tardo fennel", "code": "50403707" },
      { "name": "Garlics", "code": "50403800" },
      { "name": "California late garlic", "code": "50403801" },
      { "name": "Chinese garlic stems", "code": "50403802" },
      { "name": "Garlic chives", "code": "50403803" },
      { "name": "Germidor garlic", "code": "50403804" },
      { "name": "Long keeper garlic", "code": "50403805" },
      { "name": "Ramson garlic", "code": "50403806" },
      { "name": "Rocambole garlic", "code": "50403807" },
      { "name": "Rose de lautrec garlic", "code": "50403808" },
      { "name": "Solent wight garlic", "code": "50403809" },
      { "name": "Spanish morado garlic", "code": "50403810" },
      { "name": "Venetian/italian garlic", "code": "50403811" },
      { "name": "Gourds", "code": "50403900" },
      { "name": "Angled loofah", "code": "50403901" },
      { "name": "Bitter gourd", "code": "50403902" },
      { "name": "Bottle gourd", "code": "50403903" },
      { "name": "Calabash gourds", "code": "50403904" },
      { "name": "Fuzzy/hairy melon", "code": "50403905" },
      { "name": "Musky gourd", "code": "50403906" },
      { "name": "Smooth loofah", "code": "50403907" },
      { "name": "Snake gourd", "code": "50403908" },
      { "name": "Spiny bitter gourd", "code": "50403909" },
      { "name": "Tinda gourds", "code": "50403910" },
      { "name": "Tindoori gourds", "code": "50403911" },
      { "name": "Green peas", "code": "50404000" },
      { "name": "China peas", "code": "50404001" },
      { "name": "English peas", "code": "50404002" },
      { "name": "Garden peas", "code": "50404003" },
      { "name": "Snow peas", "code": "50404004" },
      { "name": "Sugar snap peas", "code": "50404006" },
      { "name": "Herbs", "code": "50404100" },
      { "name": "Basil", "code": "50404101" },
      { "name": "Bay leaves", "code": "50404102" },
      { "name": "Borage", "code": "50404103" },
      { "name": "Caraway", "code": "50404104" },
      { "name": "Chervil", "code": "50404105" },
      { "name": "Cilantro", "code": "50404106" },
      { "name": "Cipolinos", "code": "50404107" },
      { "name": "Curry leaves", "code": "50404108" },
      { "name": "Dill", "code": "50404109" },
      { "name": "Epazote", "code": "50404110" },
      { "name": "Fenugreek", "code": "50404111" },
      { "name": "Lemon grass", "code": "50404112" },
      { "name": "Marjoram", "code": "50404113" },
      { "name": "Mint", "code": "50404114" },
      { "name": "Oregano", "code": "50404115" },
      { "name": "Papalo", "code": "50404116" },
      { "name": "Pepicha", "code": "50404117" },
      { "name": "Perilla", "code": "50404118" },
      { "name": "Recao", "code": "50404119" },
      { "name": "Rosemary", "code": "50404120" },
      { "name": "Sage", "code": "50404121" },
      { "name": "Salsify", "code": "50404122" },
      { "name": "Savory", "code": "50404123" },
      { "name": "Tarragon", "code": "50404124" },
      { "name": "Thyme", "code": "50404125" },
      { "name": "Tumeric", "code": "50404126" },
      { "name": "Verdulaga", "code": "50404127" },
      { "name": "Kale", "code": "50404200" },
      { "name": "Curly kale", "code": "50404201" },
      { "name": "Collard greens", "code": "50404202" },
      { "name": "Kohlrabi", "code": "50404300" },
      { "name": "Azur star kohlrabi", "code": "50404301" },
      { "name": "Green vienna kohlrabi", "code": "50404302" },
      { "name": "Lanro kohlrabi", "code": "50404303" },
      { "name": "Purple vienna kohlrabi", "code": "50404304" },
      { "name": "Rowel trero kohlrabi", "code": "50404305" },
      { "name": "White vienna kohlrabi", "code": "50404306" },
      { "name": "Leeks", "code": "50404400" },
      { "name": "Autumn giant-cobra leeks", "code": "50404401" },
      { "name": "Autumn mammoth 2 leeks", "code": "50404402" },
      { "name": "Bleu de solaise leeks", "code": "50404403" },
      { "name": "Cortina leeks", "code": "50404404" },
      { "name": "Prelina leeks", "code": "50404405" },
      { "name": "Wild leek ramp", "code": "50404406" },
      { "name": "Lentils", "code": "50404500" },
      { "name": "Beluga lentils", "code": "50404501" },
      { "name": "French green lentils", "code": "50404502" },
      { "name": "Green lentils", "code": "50404503" },
      { "name": "Petite crimson lentils", "code": "50404504" },
      { "name": "Spanish pardina lentils", "code": "50404505" },
      { "name": "Split red lentils", "code": "50404506" },
      { "name": "Split yellow lentils", "code": "50404507" },
      { "name": "Tarahumara pinks lentils", "code": "50404508" },
      { "name": "Lettuces", "code": "50404600" },
      { "name": "Bibb lettuce", "code": "50404601" },
      { "name": "Boston lettuce", "code": "50404602" },
      { "name": "Frisee lettuce", "code": "50404603" },
      { "name": "Lolla rossa lettuce", "code": "50404604" },
      { "name": "Mesculin mix lettuce", "code": "50404605" },
      { "name": "Mizuna lettuce", "code": "50404606" },
      { "name": "Red leaf lettuce", "code": "50404607" },
      { "name": "Red oak leaf lettuce", "code": "50404608" },
      { "name": "Ruby romaine lettuce", "code": "50404609" },
      { "name": "Baby red romaine lettuce", "code": "50404610" },
      { "name": "Butterhead lettuces", "code": "50404611" },
      { "name": "Chinese lettuces", "code": "50404612" },
      { "name": "Crisphead lettuces", "code": "50404613" },
      { "name": "Green leaf lettuce", "code": "50404614" },
      { "name": "Iceberg lettuce", "code": "50404615" },
      { "name": "Lamb\u2019s lettuces", "code": "50404616" },
      { "name": "Looseleaf lettuces", "code": "50404617" },
      { "name": "Mache lettuce", "code": "50404618" },
      { "name": "Red boston lettuce", "code": "50404619" },
      { "name": "Red headed lettuces", "code": "50404620" },
      { "name": "Romaine lettuces", "code": "50404621" },
      { "name": "Russian red mustard lettuce", "code": "50404622" },
      { "name": "Tatsoi lettuce", "code": "50404623" },
      { "name": "Malanga", "code": "50404700" },
      { "name": "Amarilla malanga", "code": "50404701" },
      { "name": "Blanca malanga", "code": "50404702" },
      { "name": "Coco malanga", "code": "50404703" },
      { "name": "Eddoes malanga", "code": "50404704" },
      { "name": "Islena malanga", "code": "50404705" },
      { "name": "Lila malanga", "code": "50404706" },
      { "name": "Mushrooms", "code": "50404800" },
      { "name": "Black trumpet mushrooms", "code": "50404801" },
      { "name": "Brown mushrooms", "code": "50404802" },
      { "name": "Champinion mushrooms", "code": "50404803" },
      { "name": "Chanterelle mushrooms", "code": "50404804" },
      { "name": "Cremini mushrooms", "code": "50404805" },
      { "name": "Enoki mushrooms", "code": "50404806" },
      { "name": "Hedge hog mushrooms", "code": "50404807" },
      { "name": "Hen of the woods mushrooms", "code": "50404808" },
      { "name": "Lobster mushrooms", "code": "50404809" },
      { "name": "Morels mushrooms", "code": "50404810" },
      { "name": "Oyster mushrooms", "code": "50404811" },
      { "name": "Pleurotus mushrooms", "code": "50404812" },
      { "name": "Pompom mushrooms", "code": "50404813" },
      { "name": "Porcieni mushrooms", "code": "50404814" },
      { "name": "Portobella mushrooms", "code": "50404815" },
      { "name": "Shiitake mushrooms", "code": "50404816" },
      { "name": "Shimeji mushrooms", "code": "50404817" },
      { "name": "St george's mushrooms", "code": "50404818" },
      { "name": "White mushrooms", "code": "50404819" },
      { "name": "White trumpet mushrooms", "code": "50404820" },
      { "name": "Woodear mushrooms", "code": "50404821" },
      { "name": "Seta mushrooms", "code": "50404822" },
      { "name": "Tonku mushrooms", "code": "50404823" },
      { "name": "Mustards", "code": "50404900" },
      { "name": "Bamboo mustard", "code": "50404901" },
      { "name": "Garlic mustard", "code": "50404902" },
      { "name": "Giantleafed mustard", "code": "50404903" },
      { "name": "Red in snow mustard", "code": "50404904" },
      { "name": "Southern mustard", "code": "50404905" },
      { "name": "Wrapped heart mustard", "code": "50404906" },
      { "name": "Nightshades", "code": "50405100" },
      { "name": "Chinese lantern", "code": "50405101" },
      { "name": "Garden huckleberry", "code": "50405102" },
      { "name": "Naranjilla", "code": "50405103" },
      { "name": "Tomatillo", "code": "50405104" },
      { "name": "Okras", "code": "50405200" },
      { "name": "Artist okra", "code": "50405201" },
      { "name": "Burgundy okra", "code": "50405202" },
      { "name": "Clemson spineless okra", "code": "50405203" },
      { "name": "Dwarf green long pod okra", "code": "50405204" },
      { "name": "Mammoth spineless long pod okra", "code": "50405205" },
      { "name": "Red velvet okra", "code": "50405206" },
      { "name": "Star of david heirloom okra", "code": "50405207" },
      { "name": "Onions", "code": "50405300" },
      { "name": "Albion onions", "code": "50405301" },
      { "name": "Alisa craig onions", "code": "50405302" },
      { "name": "Boiling onions", "code": "50405303" },
      { "name": "Buffalo onions", "code": "50405304" },
      { "name": "Bulb onions", "code": "50405305" },
      { "name": "Creaming onions", "code": "50405306" },
      { "name": "Express yellow o-x onions", "code": "50405307" },
      { "name": "Kelsae onions", "code": "50405308" },
      { "name": "Marshalls giant fen globe onions", "code": "50405309" },
      { "name": "Pearl onions", "code": "50405310" },
      { "name": "Red baron onions", "code": "50405311" },
      { "name": "Red onions", "code": "50405312" },
      { "name": "Rijnsberger onions", "code": "50405313" },
      { "name": "Senshyu semi-globe yellow onions", "code": "50405314" },
      { "name": "Sturon onions", "code": "50405315" },
      { "name": "Stuttgarter giant onions", "code": "50405316" },
      { "name": "Sweet onions", "code": "50405317" },
      { "name": "Torpedo or red italian onions", "code": "50405318" },
      { "name": "Red storage onions", "code": "50405319" },
      { "name": "White storage onions", "code": "50405320" },
      { "name": "Yellow storage onions", "code": "50405321" },
      { "name": "Pink onions", "code": "50405322" },
      { "name": "Green onions", "code": "50405323" },
      { "name": "Peas", "code": "50405400" },
      { "name": "Purple hull peas", "code": "50405401" },
      { "name": "Pinkeye peas", "code": "50405402" },
      { "name": "Crowder peas", "code": "50405403" },
      { "name": "White acre peas", "code": "50405404" },
      { "name": "Blackeyed peas", "code": "50405405" },
      { "name": "Zipper cream peas", "code": "50405406" },
      { "name": "Peanuts", "code": "50405500" },
      { "name": "Bambarra groundnut peanuts", "code": "50405501" },
      { "name": "Florunner peanuts", "code": "50405502" },
      { "name": "Hausa/kersting's ground nut peanuts", "code": "50405503" },
      { "name": "Spanish peanuts", "code": "50405504" },
      { "name": "Valencia peanuts", "code": "50405505" },
      { "name": "Virginia peanuts", "code": "50405506" },
      { "name": "Peppers", "code": "50405600" },
      { "name": "Ajies peppers", "code": "50405601" },
      { "name": "Arbol peppers", "code": "50405602" },
      { "name": "Cheese peppers", "code": "50405603" },
      { "name": "Chilaca peppers", "code": "50405604" },
      { "name": "Cubanelles peppers", "code": "50405605" },
      { "name": "Fresno peppers", "code": "50405606" },
      { "name": "Kapia peppers", "code": "50405607" },
      { "name": "Korean peppers", "code": "50405608" },
      { "name": "Manzano peppers", "code": "50405609" },
      { "name": "Melrose peppers", "code": "50405610" },
      { "name": "Yellow chile peppers", "code": "50405611" },
      { "name": "Aji dulces peppers", "code": "50405612" },
      { "name": "Anaheim peppers", "code": "50405613" },
      { "name": "Ancho peppers", "code": "50405614" },
      { "name": "Bell peppers", "code": "50405615" },
      { "name": "Cascabel peppers", "code": "50405616" },
      { "name": "Cayenne peppers", "code": "50405617" },
      { "name": "Cherry hots peppers", "code": "50405618" },
      { "name": "Chiltecpin peppers", "code": "50405619" },
      { "name": "Finger hot peppers", "code": "50405620" },
      { "name": "Guajillo peppers", "code": "50405621" },
      { "name": "Guerro peppers", "code": "50405622" },
      { "name": "Habanero peppers", "code": "50405623" },
      { "name": "Hungarian wax peppers", "code": "50405624" },
      { "name": "Jalapeno peppers", "code": "50405625" },
      { "name": "Long hot peppers", "code": "50405626" },
      { "name": "Mirasol peppers", "code": "50405627" },
      { "name": "Pasilla peppers", "code": "50405628" },
      { "name": "Peperoncini peppers", "code": "50405629" },
      { "name": "Pequin peppers", "code": "50405630" },
      { "name": "Pimiento peppers", "code": "50405631" },
      { "name": "Poblano peppers", "code": "50405632" },
      { "name": "Scotch bonnet peppers", "code": "50405633" },
      { "name": "Serrano peppers", "code": "50405634" },
      { "name": "Tabasco peppers", "code": "50405635" },
      { "name": "Tai peppers", "code": "50405636" },
      { "name": "Tepin peppers", "code": "50405637" },
      { "name": "Arnaucho chili peppers", "code": "50405638" },
      { "name": "Mochero chili peppers", "code": "50405639" },
      { "name": "Limo chili peppers", "code": "50405640" },
      { "name": "Aji montana", "code": "50405641" },
      { "name": "Aji chuncho", "code": "50405642" },
      { "name": "Potatoes", "code": "50405700" },
      { "name": "Long white potatoes", "code": "50405701" },
      { "name": "Round white potatoes", "code": "50405702" },
      { "name": "Round red potatoes", "code": "50405703" },
      { "name": "Russet potatoes", "code": "50405704" },
      { "name": "Purple potatoes", "code": "50405705" },
      { "name": "Yellow potatoes", "code": "50405706" },
      { "name": "New potatoes", "code": "50405707" },
      { "name": "Specialty potatoes", "code": "50405708" },
      { "name": "Huayro potatoes", "code": "50405709" },
      { "name": "Peruanita potatoes", "code": "50405710" },
      { "name": "Yungay potatoes", "code": "50405711" },
      { "name": "Canch\u00e1n potatoe", "code": "50405712" },
      { "name": "Perricholi potatoe", "code": "50405713" },
      { "name": "Huamantanga potatoe", "code": "50405714" },
      { "name": "Tomasa potatoe", "code": "50405715" },
      { "name": "Black potatoe", "code": "50405716" },
      { "name": "Native potato", "code": "50405717" },
      { "name": "C\u00f3ctel potato", "code": "50405718" },
      { "name": "Rutabagas", "code": "50405800" },
      { "name": "Acme rutabagas", "code": "50405801" },
      { "name": "Angela rutabagas", "code": "50405802" },
      { "name": "Best of all rutabagas", "code": "50405803" },
      { "name": "Marian rutabagas", "code": "50405804" },
      { "name": "Sea vegetables", "code": "50405900" },
      { "name": "Agar-agar", "code": "50405901" },
      { "name": "Arame", "code": "50405902" },
      { "name": "Dulse", "code": "50405903" },
      { "name": "Haricot vert de mer", "code": "50405904" },
      { "name": "Hijiki", "code": "50405905" },
      { "name": "Irish moss", "code": "50405906" },
      { "name": "Kelp", "code": "50405907" },
      { "name": "Laver", "code": "50405908" },
      { "name": "Nori", "code": "50405909" },
      { "name": "Red algae", "code": "50405910" },
      { "name": "Sea kale", "code": "50405911" },
      { "name": "Sea lettuce", "code": "50405912" },
      { "name": "Seaweeds", "code": "50405913" },
      { "name": "Spirulina", "code": "50405914" },
      { "name": "Susabi nori", "code": "50405915" },
      { "name": "Wakame", "code": "50405916" },
      { "name": "Cushuro", "code": "50405917" },
      { "name": "Shallots", "code": "50406000" },
      { "name": "Atlantic shallots", "code": "50406001" },
      { "name": "Creation shallots", "code": "50406002" },
      { "name": "Drittler white nest shallots", "code": "50406003" },
      { "name": "Giant yellow improved shallots", "code": "50406004" },
      { "name": "Golden gourmet shallots", "code": "50406005" },
      { "name": "Grise de bagnolet shallots", "code": "50406006" },
      { "name": "Hative de niort shallots", "code": "50406007" },
      { "name": "Pikant shallots", "code": "50406008" },
      { "name": "Red potato onions", "code": "50406009" },
      { "name": "Sante shallots", "code": "50406010" },
      { "name": "Topper shallots", "code": "50406011" },
      { "name": "Sorrels", "code": "50406100" },
      { "name": "Dock sorrel", "code": "50406101" },
      { "name": "Garden sorrel", "code": "50406102" },
      { "name": "Sheep sorrel", "code": "50406103" },
      { "name": "Wood sorrel", "code": "50406104" },
      { "name": "Spinaches", "code": "50406200" },
      { "name": "America spinach", "code": "50406201" },
      { "name": "Bloomsdale spinach", "code": "50406202" },
      { "name": "Giant winter spinach", "code": "50406203" },
      { "name": "Horenso spinach", "code": "50406204" },
      { "name": "Lamb's quarters spinach", "code": "50406205" },
      { "name": "Malabar spinach", "code": "50406206" },
      { "name": "Medania spinach", "code": "50406207" },
      { "name": "Orach spinach", "code": "50406208" },
      { "name": "Savoy spinach", "code": "50406209" },
      { "name": "Sigmaleaf spinach", "code": "50406210" },
      { "name": "Space spinach", "code": "50406211" },
      { "name": "Trinidad spinach", "code": "50406212" },
      { "name": "Wild spinach", "code": "50406213" },
      { "name": "New zealand spinach", "code": "50406224" },
      { "name": "Iceplant spinach", "code": "50406225" },
      { "name": "Summer squashes and summer pumpkins", "code": "50406300" },
      { "name": "Boston marrow squash", "code": "50406301" },
      { "name": "Butternut squash", "code": "50406302" },
      { "name": "Costata romanesca squash", "code": "50406303" },
      { "name": "Crookneck squash", "code": "50406304" },
      { "name": "Cucuzza squash", "code": "50406305" },
      { "name": "Delicata squash", "code": "50406306" },
      { "name": "Delicious squash", "code": "50406307" },
      { "name": "Early golden summer crookneck squash", "code": "50406308" },
      { "name": "Early prolific straight neck squash", "code": "50406309" },
      { "name": "Gold squash", "code": "50406310" },
      { "name": "Jack be little squash", "code": "50406311" },
      { "name": "Kentucky field squash", "code": "50406312" },
      { "name": "Marrow squash", "code": "50406313" },
      { "name": "Middle eastern squash", "code": "50406314" },
      { "name": "Miniature squash", "code": "50406315" },
      { "name": "Orangetti squash", "code": "50406316" },
      { "name": "Pattypan squash", "code": "50406317" },
      { "name": "Rondini squash", "code": "50406318" },
      { "name": "Round squash", "code": "50406319" },
      { "name": "Spaghetti squash", "code": "50406320" },
      { "name": "Stripetti squash", "code": "50406321" },
      { "name": "Sugar loaf squash", "code": "50406322" },
      { "name": "Sweet dumpling squash", "code": "50406323" },
      { "name": "Triple treat squash", "code": "50406324" },
      { "name": "Waltham butternut squash", "code": "50406325" },
      { "name": "Yellow bush scallop squash", "code": "50406326" },
      { "name": "Yellow straightneck squash", "code": "50406327" },
      { "name": "Zephyr squash", "code": "50406328" },
      { "name": "Zucchini squash", "code": "50406329" },
      { "name": "Sweet potatoes", "code": "50406400" },
      { "name": "Beauregard sweet potatoes", "code": "50406401" },
      { "name": "Centennial sweet potatoes", "code": "50406402" },
      { "name": "Diane sweet potatoes", "code": "50406403" },
      { "name": "Garnet sweet potatoes", "code": "50406404" },
      { "name": "Georgia red sweet potatoes", "code": "50406405" },
      { "name": "Goldensweet sweet potatoes", "code": "50406406" },
      { "name": "Hanna sweet potatoes", "code": "50406407" },
      { "name": "Japanese sweet potatoes", "code": "50406408" },
      { "name": "Jersey sweet potatoes", "code": "50406409" },
      { "name": "Jewel sweet potatoes", "code": "50406410" },
      { "name": "Maryland red sweet potatoes", "code": "50406411" },
      { "name": "Nemagold sweet potatoes", "code": "50406412" },
      { "name": "O'henry sweet potatoes", "code": "50406413" },
      { "name": "Okinawan sweet potatoes", "code": "50406414" },
      { "name": "Orange sweet potatoes", "code": "50406415" },
      { "name": "Oriental sweet potatoes", "code": "50406416" },
      { "name": "Red jersey sweet potatoes", "code": "50406417" },
      { "name": "Red mar sweet potatoes", "code": "50406418" },
      { "name": "Redglow sweet potatoes", "code": "50406419" },
      { "name": "Yellow jersey sweet potatoes", "code": "50406420" },
      { "name": "Purple sweet potato", "code": "50406421" },
      { "name": "Tomatoes", "code": "50406500" },
      { "name": "Ailsa craig tomatoes", "code": "50406501" },
      { "name": "Alicante tomatoes", "code": "50406502" },
      { "name": "Black plum tomatoes", "code": "50406503" },
      { "name": "Brandywine tomatoes", "code": "50406504" },
      { "name": "Cherry belle tomatoes", "code": "50406505" },
      { "name": "Cherry tomatoes", "code": "50406506" },
      { "name": "Delicious tomatoes", "code": "50406507" },
      { "name": "Dombito tomatoes", "code": "50406508" },
      { "name": "Gardener's delight tomatoes", "code": "50406509" },
      { "name": "Grape tomatoes", "code": "50406510" },
      { "name": "Green tomatoes", "code": "50406511" },
      { "name": "Marmande super tomatoes", "code": "50406512" },
      { "name": "Marvel striped traditional tomatoes", "code": "50406513" },
      { "name": "Minibel tomatoes", "code": "50406514" },
      { "name": "Oaxacan pink tomatoes", "code": "50406515" },
      { "name": "Red alert tomatoes", "code": "50406516" },
      { "name": "Roma vf tomatoes", "code": "50406517" },
      { "name": "San marzano tomatoes", "code": "50406518" },
      { "name": "Shirley tomatoes", "code": "50406519" },
      { "name": "Siberia tomato tomatoes", "code": "50406520" },
      { "name": "Super beefsteak tomatoes", "code": "50406521" },
      { "name": "Tigerella tomatoes", "code": "50406522" },
      { "name": "Tiny tim tomatoes", "code": "50406523" },
      { "name": "Tumbler tomatoes", "code": "50406524" },
      { "name": "Yellow cocktail tomatoes", "code": "50406525" },
      { "name": "Yellow pear-shaped tomatoes", "code": "50406526" },
      { "name": "Yellow perfection tomatoes", "code": "50406527" },
      { "name": "Turnip greens", "code": "50406600" },
      { "name": "Green globe turnips", "code": "50406601" },
      { "name": "Golden ball turnips", "code": "50406602" },
      { "name": "Manchester market turnips", "code": "50406603" },
      { "name": "Purple top milan turnips", "code": "50406604" },
      { "name": "Purple top white turnips", "code": "50406605" },
      { "name": "Snowball turnips", "code": "50406606" },
      { "name": "Tokyo turnip", "code": "50406607" },
      { "name": "Tokyo cross turnips", "code": "50406608" },
      { "name": "Winter squashes and winter pumpkins", "code": "50406700" },
      { "name": "Acorn squash", "code": "50406701" },
      { "name": "Atlantic giant squash", "code": "50406702" },
      { "name": "Banana pink squash", "code": "50406703" },
      { "name": "Big max squash", "code": "50406704" },
      { "name": "Calabaza squash", "code": "50406705" },
      { "name": "Carnival squash", "code": "50406706" },
      { "name": "Cheese pumpkin", "code": "50406707" },
      { "name": "Crown prince squash", "code": "50406708" },
      { "name": "Curcibita squash", "code": "50406709" },
      { "name": "Cushaw squash", "code": "50406710" },
      { "name": "Giant pumpkin squash", "code": "50406711" },
      { "name": "Hubbard squash", "code": "50406712" },
      { "name": "Jarrahdale squash", "code": "50406713" },
      { "name": "Kabocha squash", "code": "50406714" },
      { "name": "Queensland blue squash", "code": "50406715" },
      { "name": "Rouge vif d'etampes squash", "code": "50406716" },
      { "name": "Turks turban squash", "code": "50406717" },
      { "name": "Valenciano squash", "code": "50406718" },
      { "name": "Warted hubbard squash", "code": "50406719" },
      { "name": "Whangaparoa crown pumpkin", "code": "50406720" },
      { "name": "Chinese pumpkin", "code": "50406721" },
      { "name": "Loche pumpkin", "code": "50406722" },
      { "name": "Macre pumpkin", "code": "50406723" },
      { "name": "Yams", "code": "50406800" },
      { "name": "African bitter yams", "code": "50406801" },
      { "name": "Asiatic bitter yams", "code": "50406802" },
      { "name": "Chinese yams", "code": "50406803" },
      { "name": "Globe yams", "code": "50406804" },
      { "name": "Greater yams", "code": "50406805" },
      { "name": "Japanese yams", "code": "50406806" },
      { "name": "Lesser yams", "code": "50406807" },
      { "name": "Potato yams", "code": "50406808" },
      { "name": "White guinea yams", "code": "50406809" },
      { "name": "Yellow guinea yams", "code": "50406810" },
      { "name": "Nominant vegetables", "code": "50407000" },
      { "name": "Alfalfa", "code": "50407001" },
      { "name": "Aloe leaves", "code": "50407002" },
      { "name": "Apio", "code": "50407003" },
      { "name": "Arrow root", "code": "50407004" },
      { "name": "Arrowhead", "code": "50407005" },
      { "name": "Arugula", "code": "50407006" },
      { "name": "Arum", "code": "50407007" },
      { "name": "Bamboo shoots", "code": "50407008" },
      { "name": "Banana leaves", "code": "50407009" },
      { "name": "Batatas", "code": "50407010" },
      { "name": "Bean sprouts", "code": "50407011" },
      { "name": "Beet tops", "code": "50407012" },
      { "name": "Bittermelon", "code": "50407013" },
      { "name": "Caperberries", "code": "50407014" },
      { "name": "Carob", "code": "50407015" },
      { "name": "Cha-om", "code": "50407016" },
      { "name": "Chaoyotes", "code": "50407017" },
      { "name": "Chickpeas", "code": "50407018" },
      { "name": "Chrysanthemum greens", "code": "50407019" },
      { "name": "Dandelion greens", "code": "50407020" },
      { "name": "Dandelions", "code": "50407021" },
      { "name": "Dasheen", "code": "50407022" },
      { "name": "Dau mue or pea tips", "code": "50407023" },
      { "name": "Diakon", "code": "50407024" },
      { "name": "Donqua", "code": "50407025" },
      { "name": "Fiddlehead ferns", "code": "50407026" },
      { "name": "Gai choy", "code": "50407027" },
      { "name": "Gailon", "code": "50407028" },
      { "name": "Galanga", "code": "50407029" },
      { "name": "Ginger root", "code": "50407030" },
      { "name": "Gobo", "code": "50407031" },
      { "name": "Hop sprouts", "code": "50407032" },
      { "name": "Horseradish", "code": "50407033" },
      { "name": "Jicama", "code": "50407034" },
      { "name": "Kudzu", "code": "50407035" },
      { "name": "Lily bulb", "code": "50407036" },
      { "name": "Linkok", "code": "50407037" },
      { "name": "Lo bok", "code": "50407038" },
      { "name": "Long beans", "code": "50407039" },
      { "name": "Lotus root", "code": "50407040" },
      { "name": "Maguey leaves", "code": "50407041" },
      { "name": "Mallows", "code": "50407042" },
      { "name": "Mamey sapote", "code": "50407043" },
      { "name": "Moap", "code": "50407044" },
      { "name": "Moo", "code": "50407045" },
      { "name": "Moqua", "code": "50407046" },
      { "name": "Opos", "code": "50407047" },
      { "name": "Palm hearts", "code": "50407048" },
      { "name": "Paprika", "code": "50407049" },
      { "name": "Purslane", "code": "50407050" },
      { "name": "Raddichios", "code": "50407051" },
      { "name": "Sinquas", "code": "50407052" },
      { "name": "Soybeans", "code": "50407053" },
      { "name": "Spoonwart", "code": "50407054" },
      { "name": "Tassle grape-hyacinth", "code": "50407055" },
      { "name": "Taro", "code": "50407056" },
      { "name": "Taro leaf", "code": "50407057" },
      { "name": "Taro shoot", "code": "50407058" },
      { "name": "Tepeguaje", "code": "50407059" },
      { "name": "Tendergreen", "code": "50407060" },
      { "name": "Tindora", "code": "50407061" },
      { "name": "Tree onion", "code": "50407062" },
      { "name": "Udo", "code": "50407063" },
      { "name": "Water chestnuts", "code": "50407064" },
      { "name": "Water spinach", "code": "50407065" },
      { "name": "Yampi", "code": "50407066" },
      { "name": "Yautia", "code": "50407067" },
      { "name": "Yu choy", "code": "50407068" },
      { "name": "Yuca", "code": "50407070" },
      { "name": "Caigua", "code": "50407071" },
      { "name": "Sicua", "code": "50407072" },
      { "name": "Qawinca", "code": "50407073" },
      { "name": "Cayota", "code": "50407074" },
      { "name": "Sugar peas", "code": "50407100" },
      { "name": "Bikini peas", "code": "50407101" },
      { "name": "Cavalier peas", "code": "50407102" },
      { "name": "Daisy peas", "code": "50407103" },
      { "name": "Darfon peas", "code": "50407104" },
      { "name": "Early onward peas", "code": "50407105" },
      { "name": "Feltham first peas", "code": "50407106" },
      { "name": "Hurst green shaft peas", "code": "50407107" },
      { "name": "Oregon sugar pod peas", "code": "50407108" },
      { "name": "Prince albert peas", "code": "50407109" },
      { "name": "Reuzensuiker peas", "code": "50407110" },
      { "name": "Tubers", "code": "50407200" },
      { "name": "Arracacha", "code": "50407201" },
      { "name": "Maca", "code": "50407202" },
      { "name": "Oca", "code": "50407203" },
      { "name": "Olluco", "code": "50407204" },
      { "name": "Mashua", "code": "50407205" },
      { "name": "Organic fresh vegetables", "code": "50410000" },
      { "name": "Organic artichokes", "code": "50411500" },
      { "name": "Organic brittany artichokes", "code": "50411501" },
      { "name": "Organic catanese artichokes", "code": "50411502" },
      { "name": "Organic french artichokes", "code": "50411503" },
      { "name": "Organic green globe artichokes", "code": "50411504" },
      {
        "name": "Organic gros camus de bretagne artichokes",
        "code": "50411505"
      },
      { "name": "Organic midi artichokes", "code": "50411506" },
      { "name": "Organic purple globe artichokes", "code": "50411507" },
      { "name": "Organic purple sicilian artichokes", "code": "50411508" },
      { "name": "Organic romanesco artichokes", "code": "50411509" },
      { "name": "Organic spinoso sardo artichokes", "code": "50411510" },
      { "name": "Organic vert de laon artichokes", "code": "50411511" },
      { "name": "Organic violetta di chioggia artichokes", "code": "50411512" },
      { "name": "Organic violetto di toscana artichokes", "code": "50411513" },
      { "name": "Organic asparagus", "code": "50411600" },
      { "name": "Organic connover's colossal asparagus", "code": "50411601" },
      { "name": "Organic franklin asparagus", "code": "50411602" },
      { "name": "Organic giant mammoth asparagus", "code": "50411603" },
      { "name": "Organic lucullus asparagus", "code": "50411604" },
      { "name": "Organic martha washington asparagus", "code": "50411605" },
      { "name": "Organic avocados", "code": "50411700" },
      { "name": "Organic ajax b-7 avocados", "code": "50411701" },
      { "name": "Organic arue avocados", "code": "50411702" },
      { "name": "Organic bacon avocados", "code": "50411703" },
      { "name": "Organic benik avocados", "code": "50411704" },
      { "name": "Organic bernecker avocados", "code": "50411705" },
      { "name": "Organic beta avocados", "code": "50411706" },
      { "name": "Organic biondo avocados", "code": "50411707" },
      { "name": "Organic black prince avocados", "code": "50411708" },
      { "name": "Organic blair avocados", "code": "50411709" },
      { "name": "Organic blair booth avocados", "code": "50411710" },
      { "name": "Organic booth 1 avocados", "code": "50411711" },
      { "name": "Organic booth 3 avocados", "code": "50411712" },
      { "name": "Organic booth 5 avocados", "code": "50411713" },
      { "name": "Organic booth 7 avocados", "code": "50411714" },
      { "name": "Organic booth 8 avocados", "code": "50411715" },
      { "name": "Organic brooks 1978 avocados", "code": "50411716" },
      { "name": "Organic brookslate avocados", "code": "50411717" },
      { "name": "Organic california haas avocados", "code": "50411718" },
      { "name": "Organic catalina avocados", "code": "50411719" },
      { "name": "Organic chica avocados", "code": "50411720" },
      { "name": "Organic choquette avocados", "code": "50411721" },
      { "name": "Organic christina avocados", "code": "50411722" },
      { "name": "Organic collinson avocados", "code": "50411723" },
      { "name": "Organic donnie avocados", "code": "50411724" },
      { "name": "Organic dr dupuis number 2 avocados", "code": "50411725" },
      { "name": "Organic dr dupuis avocados", "code": "50411726" },
      { "name": "Organic ettinger avocados", "code": "50411727" },
      { "name": "Organic fuchs avocados", "code": "50411728" },
      { "name": "Organic fuchs gwen avocados", "code": "50411729" },
      { "name": "Organic fuerte avocados", "code": "50411730" },
      { "name": "Organic gorham avocados", "code": "50411731" },
      { "name": "Organic gossman avocados", "code": "50411732" },
      { "name": "Organic guatemalan seedling avocados", "code": "50411733" },
      { "name": "Organic hall avocados", "code": "50411734" },
      { "name": "Organic hardee avocados", "code": "50411735" },
      { "name": "Organic haas avocados", "code": "50411736" },
      { "name": "Organic herman avocados", "code": "50411737" },
      { "name": "Organic hickson avocados", "code": "50411738" },
      { "name": "Organic k-5 avocados", "code": "50411739" },
      { "name": "Organic k-9 avocados", "code": "50411740" },
      { "name": "Organic lamb haas avocados", "code": "50411741" },
      { "name": "Organic leona avocados", "code": "50411742" },
      { "name": "Organic leona linda avocados", "code": "50411743" },
      { "name": "Organic lisa p avocados", "code": "50411744" },
      { "name": "Organic lisa loretta avocados", "code": "50411745" },
      { "name": "Organic loretta avocados", "code": "50411746" },
      { "name": "Organic lula avocados", "code": "50411747" },
      { "name": "Organic lula macarthur avocados", "code": "50411748" },
      { "name": "Organic marcus avocados", "code": "50411749" },
      { "name": "Organic melendez avocados", "code": "50411750" },
      { "name": "Organic meya p avocados", "code": "50411751" },
      { "name": "Organic miguel p avocados", "code": "50411752" },
      { "name": "Organic monroe avocados", "code": "50411753" },
      { "name": "Organic murrieta green avocados", "code": "50411754" },
      { "name": "Organic nabal avocados", "code": "50411755" },
      { "name": "Organic nadir avocados", "code": "50411756" },
      { "name": "Organic nesbitt avocados", "code": "50411757" },
      { "name": "Organic peterson avocados", "code": "50411758" },
      { "name": "Organic pinelli avocados", "code": "50411759" },
      { "name": "Organic pinkerton avocados", "code": "50411760" },
      { "name": "Organic pollock avocados", "code": "50411761" },
      { "name": "Organic puebla avocados", "code": "50411762" },
      { "name": "Organic reed avocados", "code": "50411763" },
      { "name": "Organic rue avocados", "code": "50411764" },
      { "name": "Organic ruehle avocados", "code": "50411765" },
      { "name": "Organic ryan avocados", "code": "50411766" },
      { "name": "Organic semil 34 avocados", "code": "50411767" },
      { "name": "Organic semil 43 avocados", "code": "50411768" },
      { "name": "Organic simmonds avocados", "code": "50411769" },
      { "name": "Organic simpson avocados", "code": "50411770" },
      { "name": "Organic taylor avocados", "code": "50411771" },
      { "name": "Organic tonnage avocados", "code": "50411772" },
      { "name": "Organic tower avocados", "code": "50411773" },
      { "name": "Organic tower li avocados", "code": "50411774" },
      { "name": "Organic trapp avocados", "code": "50411775" },
      { "name": "Organic west indian seedling avocado", "code": "50411776" },
      { "name": "Organic wagner avocados", "code": "50411777" },
      { "name": "Organic waldin avocados", "code": "50411778" },
      { "name": "Organic wurtz avocados", "code": "50411779" },
      { "name": "Organic zio p avocados", "code": "50411780" },
      { "name": "Organic ziu avocados", "code": "50411781" },
      { "name": "Organic zutano avocados", "code": "50411782" },
      { "name": "Organic beans", "code": "50411800" },
      { "name": "Organic anasazi or aztec beans", "code": "50411801" },
      { "name": "Organic appaloosa beans", "code": "50411802" },
      { "name": "Organic azuki beans", "code": "50411803" },
      { "name": "Organic barlotti beans", "code": "50411804" },
      { "name": "Organic black appaloosa beans", "code": "50411805" },
      { "name": "Organic black beans", "code": "50411806" },
      { "name": "Organic black gram beans", "code": "50411807" },
      { "name": "Organic black shackamaxon beans", "code": "50411808" },
      { "name": "Organic blackeyed beans", "code": "50411809" },
      { "name": "Organic bobby beans", "code": "50411810" },
      { "name": "Organic bolita beans", "code": "50411811" },
      { "name": "Organic brown lazy wife beans", "code": "50411812" },
      { "name": "Organic calypso beans", "code": "50411813" },
      { "name": "Organic cannellini beans", "code": "50411814" },
      { "name": "Organic castor beans", "code": "50411815" },
      { "name": "Organic china yellow beans", "code": "50411816" },
      { "name": "Organic dragon tongue beans", "code": "50411817" },
      { "name": "Organic european soldier beans", "code": "50411818" },
      { "name": "Organic fava beans", "code": "50411819" },
      { "name": "Organic flageolet beans", "code": "50411820" },
      { "name": "Organic french horticultural beans", "code": "50411821" },
      { "name": "Organic french navy beans", "code": "50411822" },
      { "name": "Organic giant white coco beans", "code": "50411823" },
      { "name": "Organic green beans", "code": "50411824" },
      { "name": "Organic green romano beans", "code": "50411825" },
      { "name": "Organic guar gum beans", "code": "50411826" },
      { "name": "Organic haricot beans", "code": "50411827" },
      { "name": "Organic hyacinth beans", "code": "50411828" },
      { "name": "Organic italian type beans", "code": "50411829" },
      { "name": "Organic jackson wonder beans", "code": "50411830" },
      { "name": "Organic jacob's cattle beans", "code": "50411831" },
      { "name": "Organic kentucky wonder beans", "code": "50411832" },
      { "name": "Organic kidney beans", "code": "50411833" },
      { "name": "Organic lima beans", "code": "50411834" },
      { "name": "Organic madeira/madera beans", "code": "50411835" },
      { "name": "Organic marrow beans", "code": "50411836" },
      { "name": "Organic mat beans", "code": "50411837" },
      { "name": "Organic monstoller wild goose beans", "code": "50411838" },
      { "name": "Organic mortgage lifter beans", "code": "50411839" },
      { "name": "Organic moth beans", "code": "50411840" },
      { "name": "Organic mung beans", "code": "50411841" },
      { "name": "Organic munsi wolf bean", "code": "50411842" },
      { "name": "Organic nuna beans", "code": "50411843" },
      { "name": "Organic pinto beans", "code": "50411844" },
      { "name": "Organic pole beans", "code": "50411845" },
      { "name": "Organic runner beans", "code": "50411846" },
      { "name": "Organic string beans", "code": "50411847" },
      { "name": "Organic tamarind beans", "code": "50411848" },
      { "name": "Organic tonka beans", "code": "50411849" },
      { "name": "Organic wax beans", "code": "50411850" },
      { "name": "Organic winged beans", "code": "50411851" },
      { "name": "Organic yard long beans", "code": "50411852" },
      { "name": "Organic beets", "code": "50411900" },
      { "name": "Organic action beets", "code": "50411901" },
      { "name": "Organic albina vereduna beets", "code": "50411902" },
      { "name": "Organic barbabietola di chioggia beets", "code": "50411903" },
      { "name": "Organic boltardy beets", "code": "50411904" },
      { "name": "Organic bonel beets", "code": "50411905" },
      { "name": "Organic burpees golden beets", "code": "50411906" },
      { "name": "Organic cheltenham green top beets", "code": "50411907" },
      { "name": "Organic cheltenham mono beets", "code": "50411908" },
      { "name": "Organic chioggia beets", "code": "50411909" },
      { "name": "Organic cylindra beets", "code": "50411910" },
      { "name": "Organic d'egypte beets", "code": "50411911" },
      { "name": "Organic detroit 2 dark red beets", "code": "50411912" },
      { "name": "Organic detroit 2 little ball beets", "code": "50411913" },
      { "name": "Organic egyptian flat beets", "code": "50411914" },
      { "name": "Organic egyptian turnip rooted beets", "code": "50411915" },
      { "name": "Organic formanova beets", "code": "50411916" },
      { "name": "Organic forono beets", "code": "50411917" },
      { "name": "Organic monaco beets", "code": "50411918" },
      { "name": "Organic monogram beets", "code": "50411919" },
      { "name": "Organic pronto beets", "code": "50411920" },
      { "name": "Organic regalia beets", "code": "50411921" },
      { "name": "Organic sugar beets", "code": "50411922" },
      { "name": "Organic broccoli", "code": "50412000" },
      { "name": "Organic broccolini", "code": "50412001" },
      { "name": "Organic broccoli romanesco", "code": "50412002" },
      { "name": "Organic broccoli raab", "code": "50412003" },
      { "name": "Organic chinese broccoli", "code": "50412004" },
      { "name": "Organic brussel sprouts", "code": "50412100" },
      { "name": "Organic citadel brussel sprouts", "code": "50412101" },
      { "name": "Organic falstaff brussel sprouts", "code": "50412102" },
      { "name": "Organic oliver brussel sprouts", "code": "50412103" },
      { "name": "Organic peer gynt brussel sprouts", "code": "50412104" },
      { "name": "Organic rampart brussel sprouts", "code": "50412105" },
      { "name": "Organic rubine brussel sprouts", "code": "50412106" },
      { "name": "Organic widgeon brussel sprouts", "code": "50412107" },
      { "name": "Organic bunching onions", "code": "50412200" },
      { "name": "Organic beltsville bunching onions", "code": "50412201" },
      { "name": "Organic feast bunching onions", "code": "50412202" },
      { "name": "Organic ishikura bunching onions", "code": "50412203" },
      { "name": "Organic kyoto market bunching onions", "code": "50412204" },
      { "name": "Organic red beard bunching onions", "code": "50412205" },
      { "name": "Organic redmate bunching onions", "code": "50412206" },
      { "name": "Organic santa claus bunching onions", "code": "50412207" },
      { "name": "Organic tokyo bunching onions", "code": "50412208" },
      { "name": "Organic white lisbon bunching onions", "code": "50412209" },
      { "name": "Organic winter white bunching onions", "code": "50412210" },
      { "name": "Organic winter-over bunching onions", "code": "50412211" },
      { "name": "Organic cabbages", "code": "50412300" },
      { "name": "Organic black cabbages", "code": "50412301" },
      { "name": "Organic savoy cabbages", "code": "50412302" },
      { "name": "Organic skunk cabbages", "code": "50412303" },
      { "name": "Organic white cabbages", "code": "50412304" },
      { "name": "Organic cardoons", "code": "50412400" },
      { "name": "Organic lunghi cardoons", "code": "50412401" },
      { "name": "Organic gobbi cardoons", "code": "50412402" },
      { "name": "Organic carrots", "code": "50412500" },
      { "name": "Organic amsterdam carrots", "code": "50412501" },
      { "name": "Organic autumn king carrots", "code": "50412502" },
      { "name": "Organic berlicum carrots", "code": "50412503" },
      { "name": "Organic chantenay carrots", "code": "50412504" },
      { "name": "Organic nantes carrots", "code": "50412505" },
      { "name": "Organic paris market carrots", "code": "50412506" },
      { "name": "Organic cauliflowers", "code": "50412600" },
      { "name": "Organic all the year round cauliflowers", "code": "50412601" },
      { "name": "Organic alverda cauliflowers", "code": "50412602" },
      { "name": "Organic autumn giant 3 cauliflowers", "code": "50412603" },
      { "name": "Organic dok elgon cauliflowers", "code": "50412604" },
      { "name": "Organic early snowball cauliflowers", "code": "50412605" },
      { "name": "Organic limelight cauliflowers", "code": "50412606" },
      { "name": "Organic minaret cauliflowers", "code": "50412607" },
      { "name": "Organic orange bouquet cauliflowers", "code": "50412608" },
      { "name": "Organic purple cape cauliflowers", "code": "50412609" },
      { "name": "Organic snowball cauliflowers", "code": "50412610" },
      { "name": "Organic walcheren winter 3 cauliflowers", "code": "50412611" },
      { "name": "Organic white rock cauliflowers", "code": "50412612" },
      { "name": "Organic celery", "code": "50412700" },
      { "name": "Organic celebrity celery", "code": "50412701" },
      { "name": "Organic celeriac", "code": "50412702" },
      { "name": "Organic chinese celery", "code": "50412703" },
      { "name": "Organic french dinant celery", "code": "50412704" },
      { "name": "Organic giant pink celery", "code": "50412705" },
      { "name": "Organic giant red celery", "code": "50412706" },
      { "name": "Organic giant white celery", "code": "50412707" },
      { "name": "Organic golden self-blanching celery", "code": "50412708" },
      { "name": "Organic greensleeves celery", "code": "50412709" },
      { "name": "Organic hopkins fenlander celery", "code": "50412710" },
      { "name": "Organic ivory tower celery", "code": "50412711" },
      { "name": "Organic lathom self-blanching celery", "code": "50412712" },
      { "name": "Organic soup celery d'amsterdam", "code": "50412713" },
      { "name": "Organic standard bearer celery", "code": "50412714" },
      { "name": "Organic tall utah triumph celery", "code": "50412715" },
      { "name": "Organic chards", "code": "50412800" },
      { "name": "Organic fordhook giant chard", "code": "50412801" },
      { "name": "Organic lucullus chard", "code": "50412802" },
      { "name": "Organic perpetual spinach chard", "code": "50412803" },
      { "name": "Organic rhubarb chard", "code": "50412804" },
      { "name": "Organic swiss chard", "code": "50412805" },
      { "name": "Organic vulcan chard", "code": "50412806" },
      { "name": "Organic white king chard", "code": "50412807" },
      { "name": "Organic chicories", "code": "50412900" },
      { "name": "Organic broad leaved batavian chicory", "code": "50412901" },
      { "name": "Organic en cornet de bordeaux chicory", "code": "50412902" },
      { "name": "Organic green curled ruffee chicory", "code": "50412903" },
      { "name": "Organic green curled chicory", "code": "50412904" },
      { "name": "Organic ione limnos chicory", "code": "50412905" },
      { "name": "Organic riccia pancalieri chicory", "code": "50412906" },
      { "name": "Organic salad king chicory", "code": "50412907" },
      { "name": "Organic sanda chicory", "code": "50412908" },
      { "name": "Organic scarola verde chicory", "code": "50412909" },
      { "name": "Organic tres fine maraichere chicory", "code": "50412910" },
      {
        "name": "Organic wallone freisee weschelkopf chicory",
        "code": "50412911"
      },
      { "name": "Organic chinese cabbages", "code": "50413000" },
      { "name": "Organic bok choy", "code": "50413001" },
      { "name": "Organic chinese flat-headed cabbage", "code": "50413002" },
      { "name": "Organic chinese flowering cabbage", "code": "50413003" },
      { "name": "Organic choy sum", "code": "50413004" },
      { "name": "Organic dwarf bok choy", "code": "50413005" },
      { "name": "Organic fengshan bok choy", "code": "50413006" },
      { "name": "Organic jade pagoda bok choy", "code": "50413007" },
      { "name": "Organic kasumi bok choy", "code": "50413008" },
      { "name": "Organic nerva bok choy", "code": "50413009" },
      { "name": "Organic rosette bok choy", "code": "50413010" },
      { "name": "Organic ruffles bok choy", "code": "50413011" },
      { "name": "Organic santo serrated leaved", "code": "50413012" },
      { "name": "Organic shanghai d bok choy", "code": "50413013" },
      { "name": "Organic shantung", "code": "50413014" },
      { "name": "Organic tip top cabbage", "code": "50413015" },
      { "name": "Organic yau choy sum", "code": "50413016" },
      { "name": "Organic chives", "code": "50413100" },
      { "name": "Organic chinese chives", "code": "50413101" },
      { "name": "Organic common chives", "code": "50413102" },
      { "name": "Organic cresses", "code": "50413200" },
      { "name": "Organic land cress", "code": "50413201" },
      { "name": "Organic nasturtium", "code": "50413202" },
      { "name": "Organic watercress", "code": "50413203" },
      { "name": "Organic wintercress", "code": "50413204" },
      { "name": "Organic cucumbers", "code": "50413300" },
      { "name": "Organic arena cucumbers", "code": "50413301" },
      { "name": "Organic armenian cucumbers", "code": "50413302" },
      { "name": "Organic athene cucumbers", "code": "50413303" },
      {
        "name": "Organic bianco lungo di parigi cucumbers",
        "code": "50413304"
      },
      { "name": "Organic burpless tasty green cucumbers", "code": "50413305" },
      { "name": "Organic chicago pickling cucumbers", "code": "50413306" },
      { "name": "Organic crystal apple cucumbers", "code": "50413307" },
      { "name": "Organic crystal lemon cucumbers", "code": "50413308" },
      { "name": "Organic danimas cucumbers", "code": "50413309" },
      { "name": "Organic gherkin cucumbers", "code": "50413310" },
      { "name": "Organic hokus cucumbers", "code": "50413311" },
      { "name": "Organic japanese cucumbers", "code": "50413312" },
      { "name": "Organic karela cucumbers", "code": "50413313" },
      { "name": "Organic korila cucumbers", "code": "50413314" },
      { "name": "Organic long green improved cucumbers", "code": "50413315" },
      { "name": "Organic marketmore cucumbers", "code": "50413316" },
      { "name": "Organic midget cucumbers", "code": "50413317" },
      { "name": "Organic national pickling cucumbers", "code": "50413318" },
      { "name": "Organic persian cucumbers", "code": "50413319" },
      { "name": "Organic telegraph cucumbers", "code": "50413320" },
      { "name": "Organic telegraph improved cucumbers", "code": "50413321" },
      {
        "name": "Organic vert de massy cornichon cucumbers",
        "code": "50413322"
      },
      { "name": "Organic yamato cucumbers", "code": "50413323" },
      { "name": "Organic eggplants", "code": "50413400" },
      { "name": "Organic bambino eggplants", "code": "50413401" },
      { "name": "Organic black beauty eggplants", "code": "50413402" },
      { "name": "Organic black enorma eggplants", "code": "50413403" },
      { "name": "Organic chinese eggplants", "code": "50413404" },
      { "name": "Organic easter egg eggplants", "code": "50413405" },
      { "name": "Organic filipino eggplants", "code": "50413406" },
      { "name": "Organic florida market eggplants", "code": "50413407" },
      { "name": "Organic indian eggplants", "code": "50413408" },
      { "name": "Organic italian eggplants", "code": "50413409" },
      { "name": "Organic japanese eggplants", "code": "50413410" },
      { "name": "Organic long purple eggplants", "code": "50413411" },
      { "name": "Organic long striped eggplants", "code": "50413412" },
      { "name": "Organic moneymaker eggplants", "code": "50413413" },
      { "name": "Organic ova eggplants", "code": "50413414" },
      { "name": "Organic pea eggplants", "code": "50413415" },
      { "name": "Organic short tom eggplants", "code": "50413416" },
      { "name": "Organic sicilian eggplants", "code": "50413417" },
      { "name": "Organic thai eggplants", "code": "50413418" },
      { "name": "Organic violette di firenze eggplants", "code": "50413419" },
      { "name": "Organic white eggplants", "code": "50413420" },
      { "name": "Organic endives", "code": "50413500" },
      { "name": "Organic brussels witloof endives", "code": "50413501" },
      { "name": "Organic castelfranco endives", "code": "50413502" },
      { "name": "Organic catalogna di galatina endives", "code": "50413503" },
      { "name": "Organic chioggia endives", "code": "50413504" },
      { "name": "Organic grumolo verde endives", "code": "50413505" },
      { "name": "Organic large rooted magdeburg endives", "code": "50413506" },
      {
        "name": "Organic palla rossa zorzi precoce endives",
        "code": "50413507"
      },
      { "name": "Organic radice amare endives", "code": "50413508" },
      { "name": "Organic rossa di treviso endives", "code": "50413509" },
      { "name": "Organic rossa di verona endives", "code": "50413510" },
      { "name": "Organic soncino endives", "code": "50413511" },
      { "name": "Organic sugarhat endives", "code": "50413512" },
      { "name": "Organic verona endives", "code": "50413513" },
      { "name": "Organic witloof zoom endives", "code": "50413514" },
      { "name": "Organic fennels", "code": "50413600" },
      { "name": "Organic cantino fennel", "code": "50413601" },
      { "name": "Organic fino fennel", "code": "50413602" },
      { "name": "Organic herald fennel", "code": "50413603" },
      { "name": "Organic perfection fennel", "code": "50413604" },
      { "name": "Organic sirio fennel", "code": "50413605" },
      { "name": "Organic sweet florence fennel", "code": "50413606" },
      { "name": "Organic tardo fennel", "code": "50413607" },
      { "name": "Organic garlics", "code": "50413700" },
      { "name": "Organic california late garlic", "code": "50413701" },
      { "name": "Organic chinese garlic stems", "code": "50413702" },
      { "name": "Organic garlic chives", "code": "50413703" },
      { "name": "Organic germidor garlic", "code": "50413704" },
      { "name": "Organic long keeper garlic", "code": "50413705" },
      { "name": "Organic ramson garlic", "code": "50413706" },
      { "name": "Organic rocambole garlic", "code": "50413707" },
      { "name": "Organic rose de lautrec garlic", "code": "50413708" },
      { "name": "Organic solent wight garlic", "code": "50413709" },
      { "name": "Organic spanish morado garlic", "code": "50413710" },
      { "name": "Organic venetian/italian garlic", "code": "50413711" },
      { "name": "Organic gourds", "code": "50413800" },
      { "name": "Organic angled loofah", "code": "50413801" },
      { "name": "Organic bitter gourd", "code": "50413802" },
      { "name": "Organic bottle gourd", "code": "50413803" },
      { "name": "Organic calabash gourds", "code": "50413804" },
      { "name": "Organic fuzzy/hairy melon", "code": "50413805" },
      { "name": "Organic musky gourd", "code": "50413806" },
      { "name": "Organic smooth loofah", "code": "50413807" },
      { "name": "Organic snake gourd", "code": "50413808" },
      { "name": "Organic spiny bitter gourd", "code": "50413809" },
      { "name": "Organic tinda gourds", "code": "50413810" },
      { "name": "Organic tindoori gourds", "code": "50413811" },
      { "name": "Organic green peas", "code": "50413900" },
      { "name": "Organic china peas", "code": "50413901" },
      { "name": "Organic english peas", "code": "50413902" },
      { "name": "Organic garden peas", "code": "50413903" },
      { "name": "Organic snow peas", "code": "50413904" },
      { "name": "Organic sugar snap peas", "code": "50413906" },
      { "name": "Organic herbs", "code": "50414000" },
      { "name": "Organic basil", "code": "50414001" },
      { "name": "Organic bay leaves", "code": "50414002" },
      { "name": "Organic borage", "code": "50414003" },
      { "name": "Organic caraway", "code": "50414004" },
      { "name": "Organic chervil", "code": "50414005" },
      { "name": "Organic cilantro", "code": "50414006" },
      { "name": "Organic cipolinos", "code": "50414007" },
      { "name": "Organic curry leaves", "code": "50414008" },
      { "name": "Organic dill", "code": "50414009" },
      { "name": "Organic epazote", "code": "50414010" },
      { "name": "Organic fenugreek", "code": "50414011" },
      { "name": "Organic lemon grass", "code": "50414012" },
      { "name": "Organic marjoram", "code": "50414013" },
      { "name": "Organic mint", "code": "50414014" },
      { "name": "Organic oregano", "code": "50414015" },
      { "name": "Organic papalo", "code": "50414016" },
      { "name": "Organic pepicha", "code": "50414017" },
      { "name": "Organic perilla", "code": "50414018" },
      { "name": "Organic recao", "code": "50414019" },
      { "name": "Organic rosemary", "code": "50414020" },
      { "name": "Organic sage", "code": "50414021" },
      { "name": "Organic salsify", "code": "50414022" },
      { "name": "Organic savory", "code": "50414023" },
      { "name": "Organic tarragon", "code": "50414024" },
      { "name": "Organic thyme", "code": "50414025" },
      { "name": "Organic tumeric", "code": "50414026" },
      { "name": "Organic verdulaga", "code": "50414027" },
      { "name": "Organic kale", "code": "50414100" },
      { "name": "Organic curly kale", "code": "50414101" },
      { "name": "Organic collard greens", "code": "50414102" },
      { "name": "Organic kohlrabi", "code": "50414200" },
      { "name": "Organic azur star kohlrabi", "code": "50414201" },
      { "name": "Organic green vienna kohlrabi", "code": "50414202" },
      { "name": "Organic lanro kohlrabi", "code": "50414203" },
      { "name": "Organic purple vienna kohlrabi", "code": "50414204" },
      { "name": "Organic rowel trero kohlrabi", "code": "50414205" },
      { "name": "Organic white vienna kohlrabi", "code": "50414206" },
      { "name": "Organic leeks", "code": "50414300" },
      { "name": "Organic autumn giant-cobra leeks", "code": "50414301" },
      { "name": "Organic autumn mammoth 2 leeks", "code": "50414302" },
      { "name": "Organic bleu de solaise leeks", "code": "50414303" },
      { "name": "Organic cortina leeks", "code": "50414304" },
      { "name": "Organic prelina leeks", "code": "50414305" },
      { "name": "Organic wild leek ramp", "code": "50414306" },
      { "name": "Organic lentils", "code": "50414400" },
      { "name": "Organic beluga lentils", "code": "50414401" },
      { "name": "Organic french green lentils", "code": "50414402" },
      { "name": "Organic green lentils", "code": "50414403" },
      { "name": "Organic petite crimson lentils", "code": "50414404" },
      { "name": "Organic spanish pardina lentils", "code": "50414405" },
      { "name": "Organic split red lentils", "code": "50414406" },
      { "name": "Organic split yellow lentils", "code": "50414407" },
      { "name": "Organic tarahumara pinks lentils", "code": "50414408" },
      { "name": "Organic lettuces", "code": "50414500" },
      { "name": "Organic bibb lettuce", "code": "50414501" },
      { "name": "Organic boston lettuce", "code": "50414502" },
      { "name": "Organic frisee lettuce", "code": "50414503" },
      { "name": "Organic lolla rossa lettuce", "code": "50414504" },
      { "name": "Organic mesculin mix lettuce", "code": "50414505" },
      { "name": "Organic mizuna lettuce", "code": "50414506" },
      { "name": "Organic red leaf lettuce", "code": "50414507" },
      { "name": "Organic red oak leaf lettuce", "code": "50414508" },
      { "name": "Organic ruby romaine lettuce", "code": "50414509" },
      { "name": "Organic baby red romaine lettuce", "code": "50414510" },
      { "name": "Organic butterhead lettuces", "code": "50414511" },
      { "name": "Organic chinese lettuces", "code": "50414512" },
      { "name": "Organic crisphead lettuces", "code": "50414513" },
      { "name": "Organic green leaf lettuce", "code": "50414514" },
      { "name": "Organic iceberg lettuce", "code": "50414515" },
      { "name": "Organic lamb\u2019s lettuces", "code": "50414516" },
      { "name": "Organic looseleaf lettuces", "code": "50414517" },
      { "name": "Organic mache lettuce", "code": "50414518" },
      { "name": "Organic red boston lettuce", "code": "50414519" },
      { "name": "Organic red headed lettuces", "code": "50414520" },
      { "name": "Organic romaine lettuces", "code": "50414521" },
      { "name": "Organic russian red mustard lettuce", "code": "50414522" },
      { "name": "Organic tatsoi lettuce", "code": "50414523" },
      { "name": "Organic malanga", "code": "50414600" },
      { "name": "Organic blanca malanga", "code": "50414601" },
      { "name": "Organic coco malanga", "code": "50414602" },
      { "name": "Organic eddoes malanga", "code": "50414603" },
      { "name": "Organic islena malanga", "code": "50414604" },
      { "name": "Organic lila malanga", "code": "50414605" },
      { "name": "Organic amarilla malanga", "code": "50414606" },
      { "name": "Organic mushrooms", "code": "50414700" },
      { "name": "Organic black trumpet mushrooms", "code": "50414701" },
      { "name": "Organic brown mushrooms", "code": "50414702" },
      { "name": "Organic champinion mushrooms", "code": "50414703" },
      { "name": "Organic chanterelle mushrooms", "code": "50414704" },
      { "name": "Organic cremini mushrooms", "code": "50414705" },
      { "name": "Organic enoki mushrooms", "code": "50414706" },
      { "name": "Organic hedge hog mushrooms", "code": "50414707" },
      { "name": "Organic hen of the woods mushrooms", "code": "50414708" },
      { "name": "Organic lobster mushrooms", "code": "50414709" },
      { "name": "Organic morels mushrooms", "code": "50414710" },
      { "name": "Organic oyster mushrooms", "code": "50414711" },
      { "name": "Organic pleurotus mushrooms", "code": "50414712" },
      { "name": "Organic pompom mushrooms", "code": "50414713" },
      { "name": "Organic porcieni mushrooms", "code": "50414714" },
      { "name": "Organic portobella mushrooms", "code": "50414715" },
      { "name": "Organic shiitake mushrooms", "code": "50414716" },
      { "name": "Organic shimeji mushrooms", "code": "50414717" },
      { "name": "Organic st george's mushrooms", "code": "50414718" },
      { "name": "Organic white mushrooms", "code": "50414719" },
      { "name": "Organic white trumpet mushrooms", "code": "50414720" },
      { "name": "Organic woodear mushrooms", "code": "50414721" },
      { "name": "Organic mustards", "code": "50414800" },
      { "name": "Organic bamboo mustard", "code": "50414801" },
      { "name": "Organic garlic mustard", "code": "50414802" },
      { "name": "Organic giantleafed mustard", "code": "50414803" },
      { "name": "Organic red in snow mustard", "code": "50414804" },
      { "name": "Organic southern mustard", "code": "50414805" },
      { "name": "Organic wrapped heart mustard", "code": "50414806" },
      { "name": "Organic nightshades", "code": "50415000" },
      { "name": "Organic chinese lantern", "code": "50415001" },
      { "name": "Organic garden huckleberry", "code": "50415002" },
      { "name": "Organic naranjilla", "code": "50415003" },
      { "name": "Organic tomatillo", "code": "50415004" },
      { "name": "Organic okras", "code": "50415100" },
      { "name": "Organic artist okra", "code": "50415101" },
      { "name": "Organic burgundy okra", "code": "50415102" },
      { "name": "Organic clemson spineless okra", "code": "50415103" },
      { "name": "Organic dwarf green long pod okra", "code": "50415104" },
      { "name": "Organic mammoth spineless long pod okra", "code": "50415105" },
      { "name": "Organic red velvet okra", "code": "50415106" },
      { "name": "Organic star of david heirloom okra", "code": "50415107" },
      { "name": "Organic onions", "code": "50415200" },
      { "name": "Organic albion onions", "code": "50415201" },
      { "name": "Organic alisa craig onions", "code": "50415202" },
      { "name": "Organic boiling onions", "code": "50415203" },
      { "name": "Organic buffalo onions", "code": "50415204" },
      { "name": "Organic bulb onions", "code": "50415205" },
      { "name": "Organic creaming onions", "code": "50415206" },
      { "name": "Organic express yellow o-x onions", "code": "50415207" },
      { "name": "Organic kelsae onions", "code": "50415208" },
      {
        "name": "Organic marshalls giant fen globe onions",
        "code": "50415209"
      },
      { "name": "Organic pearl onions", "code": "50415210" },
      { "name": "Organic red baron onions", "code": "50415211" },
      { "name": "Organic red onions", "code": "50415212" },
      { "name": "Organic rijnsberger onions", "code": "50415213" },
      {
        "name": "Organic senshyu semi-globe yellow onions",
        "code": "50415214"
      },
      { "name": "Organic sturon onions", "code": "50415215" },
      { "name": "Organic stuttgarter giant onions", "code": "50415216" },
      { "name": "Organic sweet onions", "code": "50415217" },
      { "name": "Organic torpedo or red italian onions", "code": "50415218" },
      { "name": "Organic red storage onions", "code": "50415219" },
      { "name": "Organic white storage onions", "code": "50415220" },
      { "name": "Organic yellow storage onions", "code": "50415221" },
      { "name": "Organic peanuts", "code": "50415300" },
      { "name": "Organic bambarra groundnut peanuts", "code": "50415301" },
      { "name": "Organic florunner peanuts", "code": "50415302" },
      {
        "name": "Organic hausa/kersting's ground nut peanuts",
        "code": "50415303"
      },
      { "name": "Organic spanish peanuts", "code": "50415304" },
      { "name": "Organic valencia peanuts", "code": "50415305" },
      { "name": "Organic virginia peanuts", "code": "50415306" },
      { "name": "Organic peas", "code": "50415400" },
      { "name": "Organic purple hull peas", "code": "50415401" },
      { "name": "Organic pinkeye peas", "code": "50415402" },
      { "name": "Organic crowder peas", "code": "50415403" },
      { "name": "Organic white acre peas", "code": "50415404" },
      { "name": "Organic blackeyed peas", "code": "50415405" },
      { "name": "Organic zipper cream peas", "code": "50415406" },
      { "name": "Organic peppers", "code": "50415500" },
      { "name": "Organic ajies peppers", "code": "50415501" },
      { "name": "Organic arbol peppers", "code": "50415502" },
      { "name": "Organic cheese peppers", "code": "50415503" },
      { "name": "Organic chilaca peppers", "code": "50415504" },
      { "name": "Organic cubanelles peppers", "code": "50415505" },
      { "name": "Organic fresno peppers", "code": "50415506" },
      { "name": "Organic kapia peppers", "code": "50415507" },
      { "name": "Organic korean peppers", "code": "50415508" },
      { "name": "Organic manzano peppers", "code": "50415509" },
      { "name": "Organic melrose peppers", "code": "50415510" },
      { "name": "Organic yellow chile peppers", "code": "50415511" },
      { "name": "Organic aji dulces peppers", "code": "50415512" },
      { "name": "Organic anaheim peppers", "code": "50415513" },
      { "name": "Organic ancho peppers", "code": "50415514" },
      { "name": "Organic bell peppers", "code": "50415515" },
      { "name": "Organic cascabel peppers", "code": "50415516" },
      { "name": "Organic cayenne peppers", "code": "50415517" },
      { "name": "Organic cherry hots peppers", "code": "50415518" },
      { "name": "Organic chiltecpin peppers", "code": "50415519" },
      { "name": "Organic finger hot peppers", "code": "50415520" },
      { "name": "Organic guajillo peppers", "code": "50415521" },
      { "name": "Organic guerro peppers", "code": "50415522" },
      { "name": "Organic habanero peppers", "code": "50415523" },
      { "name": "Organic hungarian wax peppers", "code": "50415524" },
      { "name": "Organic jalape\u00f1o peppers", "code": "50415525" },
      { "name": "Organic long hot peppers", "code": "50415526" },
      { "name": "Organic mirasol peppers", "code": "50415527" },
      { "name": "Organic pasilla peppers", "code": "50415528" },
      { "name": "Organic peperoncini peppers", "code": "50415529" },
      { "name": "Organic pequin peppers", "code": "50415530" },
      { "name": "Organic pimiento peppers", "code": "50415531" },
      { "name": "Organic poblano peppers", "code": "50415532" },
      { "name": "Organic scotch bonnet peppers", "code": "50415533" },
      { "name": "Organic serrano peppers", "code": "50415534" },
      { "name": "Organic tabasco peppers", "code": "50415535" },
      { "name": "Organic tai peppers", "code": "50415536" },
      { "name": "Organic tepin peppers", "code": "50415537" },
      { "name": "Organic potatoes", "code": "50415600" },
      { "name": "Organic long white potatoes", "code": "50415601" },
      { "name": "Organic round white potatoes", "code": "50415602" },
      { "name": "Organic round red potatoes", "code": "50415603" },
      { "name": "Organic russet potatoes", "code": "50415604" },
      { "name": "Organic purple potatoes", "code": "50415605" },
      { "name": "Organic yellow potatoes", "code": "50415606" },
      { "name": "Organic new potatoes", "code": "50415607" },
      { "name": "Organic specialty potatoes", "code": "50415608" },
      { "name": "Organic rutabagas", "code": "50415700" },
      { "name": "Organic acme rutabagas", "code": "50415701" },
      { "name": "Organic angela rutabagas", "code": "50415702" },
      { "name": "Organic best of all rutabagas", "code": "50415703" },
      { "name": "Organic marian rutabagas", "code": "50415704" },
      { "name": "Organic sea vegetables", "code": "50415800" },
      { "name": "Organic agar-agar", "code": "50415801" },
      { "name": "Organic arame", "code": "50415802" },
      { "name": "Organic dulse", "code": "50415803" },
      { "name": "Organic haricot vert de mer", "code": "50415804" },
      { "name": "Organic hijiki", "code": "50415805" },
      { "name": "Organic irish moss", "code": "50415806" },
      { "name": "Organic kelp", "code": "50415807" },
      { "name": "Organic laver", "code": "50415808" },
      { "name": "Organic nori", "code": "50415809" },
      { "name": "Organic red algae", "code": "50415810" },
      { "name": "Organic sea kale", "code": "50415811" },
      { "name": "Organic sea lettuce", "code": "50415812" },
      { "name": "Organic seaweeds", "code": "50415813" },
      { "name": "Organic spirulina", "code": "50415814" },
      { "name": "Organic susabi nori", "code": "50415815" },
      { "name": "Organic wakame", "code": "50415816" },
      { "name": "Organic shallots", "code": "50415900" },
      { "name": "Organic atlantic shallots", "code": "50415901" },
      { "name": "Organic creation shallots", "code": "50415902" },
      { "name": "Organic drittler white nest shallots", "code": "50415903" },
      { "name": "Organic giant yellow improved shallots", "code": "50415904" },
      { "name": "Organic golden gourmet shallots", "code": "50415905" },
      { "name": "Organic grise de bagnolet shallots", "code": "50415906" },
      { "name": "Organic hative de niort shallots", "code": "50415907" },
      { "name": "Organic pikant shallots", "code": "50415908" },
      { "name": "Organic red potato onions", "code": "50415909" },
      { "name": "Organic sante shallots", "code": "50415910" },
      { "name": "Organic topper shallots", "code": "50415911" },
      { "name": "Organic sorrels", "code": "50416000" },
      { "name": "Organic dock sorrel", "code": "50416001" },
      { "name": "Organic garden sorrel", "code": "50416002" },
      { "name": "Organic sheep sorrel", "code": "50416003" },
      { "name": "Organic wood sorrel", "code": "50416004" },
      { "name": "Organic spinaches", "code": "50416100" },
      { "name": "Organic america spinach", "code": "50416101" },
      { "name": "Organic bloomsdale spinach", "code": "50416102" },
      { "name": "Organic giant winter spinach", "code": "50416103" },
      { "name": "Organic horenso spinach", "code": "50416104" },
      { "name": "Organic lamb's quarters spinach", "code": "50416105" },
      { "name": "Organic malabar spinach", "code": "50416106" },
      { "name": "Organic medania spinach", "code": "50416107" },
      { "name": "Organic orach spinach", "code": "50416108" },
      { "name": "Organic savoy spinach", "code": "50416109" },
      { "name": "Organic sigmaleaf spinach", "code": "50416110" },
      { "name": "Organic space spinach", "code": "50416111" },
      { "name": "Organic trinidad spinach", "code": "50416112" },
      { "name": "Organic wild spinach", "code": "50416113" },
      { "name": "Organic new zealand spinach", "code": "50416114" },
      { "name": "Organic iceplant spinach", "code": "50416115" },
      {
        "name": "Organic summer squashes and summer pumpkins",
        "code": "50416200"
      },
      { "name": "Organic boston marrow squash", "code": "50416201" },
      { "name": "Organic butternut squash", "code": "50416202" },
      { "name": "Organic costata romanesca squash", "code": "50416203" },
      { "name": "Organic crookneck squash", "code": "50416204" },
      { "name": "Organic cucuzza squash", "code": "50416205" },
      { "name": "Organic delicata squash", "code": "50416206" },
      { "name": "Organic delicious squash", "code": "50416207" },
      {
        "name": "Organic early golden summer crookneck squash",
        "code": "50416208"
      },
      {
        "name": "Organic early prolific straight neck squash",
        "code": "50416209"
      },
      { "name": "Organic gold squash", "code": "50416210" },
      { "name": "Organic jack be little squash", "code": "50416211" },
      { "name": "Organic kentucky field squash", "code": "50416212" },
      { "name": "Organic marrow squash", "code": "50416213" },
      { "name": "Organic middle eastern squash", "code": "50416214" },
      { "name": "Organic miniature squash", "code": "50416215" },
      { "name": "Organic orangetti squash", "code": "50416216" },
      { "name": "Organic pattypan squash", "code": "50416217" },
      { "name": "Organic rondini squash", "code": "50416218" },
      { "name": "Organic round squash", "code": "50416219" },
      { "name": "Organic spaghetti squash", "code": "50416220" },
      { "name": "Organic stripetti squash", "code": "50416221" },
      { "name": "Organic sugar loaf squash", "code": "50416222" },
      { "name": "Organic sweet dumpling squash", "code": "50416223" },
      { "name": "Organic triple treat squash", "code": "50416224" },
      { "name": "Organic waltham butternut squash", "code": "50416225" },
      { "name": "Organic yellow bush scallop squash", "code": "50416226" },
      { "name": "Organic yellow straightneck squash", "code": "50416227" },
      { "name": "Organic zephyr squash", "code": "50416228" },
      { "name": "Organic zucchini squash", "code": "50416229" },
      { "name": "Organic sweet potatoes", "code": "50416300" },
      { "name": "Organic beauregard sweet potatoes", "code": "50416301" },
      { "name": "Organic centennial sweet potatoes", "code": "50416302" },
      { "name": "Organic diane sweet potatoes", "code": "50416303" },
      { "name": "Organic garnet sweet potatoes", "code": "50416304" },
      { "name": "Organic georgia red sweet potatoes", "code": "50416305" },
      { "name": "Organic goldensweet sweet potatoes", "code": "50416306" },
      { "name": "Organic hanna sweet potatoes", "code": "50416307" },
      { "name": "Organic japanese sweet potatoes", "code": "50416308" },
      { "name": "Organic jersey sweet potatoes", "code": "50416309" },
      { "name": "Organic jewel sweet potatoes", "code": "50416310" },
      { "name": "Organic maryland red sweet potatoes", "code": "50416311" },
      { "name": "Organic nemagold sweet potatoes", "code": "50416312" },
      { "name": "Organic o'henry sweet potatoes", "code": "50416313" },
      { "name": "Organic okinawan sweet potatoes", "code": "50416314" },
      { "name": "Organic orange sweet potatoes", "code": "50416315" },
      { "name": "Organic oriental sweet potatoes", "code": "50416316" },
      { "name": "Organic red jersey sweet potatoes", "code": "50416317" },
      { "name": "Organic red mar sweet potatoes", "code": "50416318" },
      { "name": "Organic redglow sweet potatoes", "code": "50416319" },
      { "name": "Organic yellow jersey sweet potatoes", "code": "50416320" },
      { "name": "Organic tomatoes", "code": "50416400" },
      { "name": "Organic ailsa craig tomatoes", "code": "50416401" },
      { "name": "Organic alicante tomatoes", "code": "50416402" },
      { "name": "Organic black plum tomatoes", "code": "50416403" },
      { "name": "Organic brandywine tomatoes", "code": "50416404" },
      { "name": "Organic cherry belle tomatoes", "code": "50416405" },
      { "name": "Organic cherry tomatoes", "code": "50416406" },
      { "name": "Organic delicious tomatoes", "code": "50416407" },
      { "name": "Organic dombito tomatoes", "code": "50416408" },
      { "name": "Organic gardener's delight tomatoes", "code": "50416409" },
      { "name": "Organic grape tomatoes", "code": "50416410" },
      { "name": "Organic green tomatoes", "code": "50416411" },
      { "name": "Organic marmande super tomatoes", "code": "50416412" },
      {
        "name": "Organic marvel striped traditional tomatoes",
        "code": "50416413"
      },
      { "name": "Organic minibel tomatoes", "code": "50416414" },
      { "name": "Organic oaxacan pink tomatoes", "code": "50416415" },
      { "name": "Organic red alert tomatoes", "code": "50416416" },
      { "name": "Organic roma vf tomatoes", "code": "50416417" },
      { "name": "Organic san marzano tomatoes", "code": "50416418" },
      { "name": "Organic shirley tomatoes", "code": "50416419" },
      { "name": "Organic siberia tomatoes", "code": "50416420" },
      { "name": "Organic super beefsteak tomatoes", "code": "50416421" },
      { "name": "Organic tigerella tomatoes", "code": "50416422" },
      { "name": "Organic tiny tim tomatoes", "code": "50416423" },
      { "name": "Organic tumbler tomatoes", "code": "50416424" },
      { "name": "Organic yellow cocktail tomatoes", "code": "50416425" },
      { "name": "Organic yellow pear-shaped tomatoes", "code": "50416426" },
      { "name": "Organic yellow perfection tomatoes", "code": "50416427" },
      { "name": "Organic turnip greens", "code": "50416500" },
      { "name": "Organic green globe turnips", "code": "50416501" },
      { "name": "Organic golden ball turnips", "code": "50416502" },
      { "name": "Organic manchester market turnips", "code": "50416503" },
      { "name": "Organic purple top milan turnips", "code": "50416504" },
      { "name": "Organic purple top white turnips", "code": "50416505" },
      { "name": "Organic snowball turnips", "code": "50416506" },
      { "name": "Organic tokyo turnip", "code": "50416507" },
      { "name": "Organic tokyo cross turnips", "code": "50416508" },
      {
        "name": "Organic winter squashes and winter pumpkins",
        "code": "50416600"
      },
      { "name": "Organic acorn squash", "code": "50416601" },
      { "name": "Organic atlantic giant squash", "code": "50416602" },
      { "name": "Organic banana pink squash", "code": "50416603" },
      { "name": "Organic big max squash", "code": "50416604" },
      { "name": "Organic calabaza squash", "code": "50416605" },
      { "name": "Organic carnival squash", "code": "50416606" },
      { "name": "Organic cheese pumpkin", "code": "50416607" },
      { "name": "Organic crown prince squash", "code": "50416608" },
      { "name": "Organic curcibita squash", "code": "50416609" },
      { "name": "Organic cushaw squash", "code": "50416610" },
      { "name": "Organic giant pumpkin squash", "code": "50416611" },
      { "name": "Organic hubbard squash", "code": "50416612" },
      { "name": "Organic jarrahdale squash", "code": "50416613" },
      { "name": "Organic kabocha squash", "code": "50416614" },
      { "name": "Organic queensland blue squash", "code": "50416615" },
      { "name": "Organic rouge vif d'etampes squash", "code": "50416616" },
      { "name": "Organic turk's turban squash", "code": "50416617" },
      { "name": "Organic valenciano squash", "code": "50416618" },
      { "name": "Organic warted hubbard squash", "code": "50416619" },
      { "name": "Organic whangaparoa crown pumpkin", "code": "50416620" },
      { "name": "Organic yams", "code": "50416700" },
      { "name": "Organic african bitter yams", "code": "50416701" },
      { "name": "Organic asiatic bitter yams", "code": "50416702" },
      { "name": "Organic chinese yams", "code": "50416703" },
      { "name": "Organic globe yams", "code": "50416704" },
      { "name": "Organic greater yams", "code": "50416705" },
      { "name": "Organic japanese yams", "code": "50416706" },
      { "name": "Organic lesser yams", "code": "50416707" },
      { "name": "Organic potato yams", "code": "50416708" },
      { "name": "Organic white guinea yams", "code": "50416709" },
      { "name": "Organic yellow guinea yams", "code": "50416710" },
      { "name": "Organic corn", "code": "50416800" },
      { "name": "Organic aloha corn", "code": "50416801" },
      { "name": "Organic alpine corn", "code": "50416802" },
      { "name": "Organic ambrosia corn", "code": "50416803" },
      { "name": "Organic argent corn", "code": "50416804" },
      { "name": "Organic aspen corn", "code": "50416805" },
      { "name": "Organic avalanche corn", "code": "50416806" },
      { "name": "Organic biqueen corn", "code": "50416807" },
      { "name": "Organic bodacious corn", "code": "50416808" },
      { "name": "Organic butter and sugar corn", "code": "50416809" },
      { "name": "Organic calico belle corn", "code": "50416810" },
      { "name": "Organic camelot corn", "code": "50416811" },
      {
        "name": "Organic challengercrisp \u2018n sweet corn",
        "code": "50416812"
      },
      { "name": "Organic champ corn", "code": "50416813" },
      { "name": "Organic cotton candy corn", "code": "50416814" },
      { "name": "Organic d\u2019artagnan corn", "code": "50416815" },
      { "name": "Organic dazzle corn", "code": "50416816" },
      { "name": "Organic diamonds and gold corn", "code": "50416817" },
      { "name": "Organic divinity corn", "code": "50416818" },
      { "name": "Organic double delight corn", "code": "50416819" },
      { "name": "Organic double gem corn", "code": "50416820" },
      { "name": "Organic earlivee corn", "code": "50416821" },
      { "name": "Organic early xtra sweet corn", "code": "50416822" },
      { "name": "Organic excel corn", "code": "50416823" },
      { "name": "Organic golden cross bantam corn", "code": "50416824" },
      { "name": "Organic honey and cream corn", "code": "50416825" },
      { "name": "Organic honey \u2018n pearl corn", "code": "50416826" },
      { "name": "Organic how sweet it is corn", "code": "50416827" },
      { "name": "Organic hudson corn", "code": "50416828" },
      { "name": "Organic illini gold corn", "code": "50416829" },
      { "name": "Organic illini xtra sweet corn", "code": "50416830" },
      { "name": "Organic incredible corn", "code": "50416831" },
      { "name": "Organic iochief corn", "code": "50416832" },
      { "name": "Organic jubilee corn", "code": "50416833" },
      { "name": "Organic jubilee supersweet corn", "code": "50416834" },
      { "name": "Organic kandy korn corn", "code": "50416835" },
      { "name": "Organic kiss \u2018n tell corn", "code": "50416836" },
      { "name": "Organic lancelot corn", "code": "50416837" },
      { "name": "Organic maple sweet corn", "code": "50416838" },
      { "name": "Organic medley corn", "code": "50416839" },
      { "name": "Organic merlin corn", "code": "50416840" },
      { "name": "Organic miracle corn", "code": "50416841" },
      { "name": "Organic nk-199 corn", "code": "50416842" },
      { "name": "Organic peaches and cream corn", "code": "50416843" },
      { "name": "Organic pearl white corn", "code": "50416844" },
      { "name": "Organic pegasus corn", "code": "50416845" },
      { "name": "Organic phenomenal corn", "code": "50416846" },
      { "name": "Organic platinum lady corn", "code": "50416847" },
      { "name": "Organic precocious corn", "code": "50416848" },
      { "name": "Organic pristine corn", "code": "50416849" },
      { "name": "Organic quickie corn", "code": "50416850" },
      { "name": "Organic radiance corn", "code": "50416851" },
      { "name": "Organic seneca brave corn", "code": "50416852" },
      { "name": "Organic seneca dawn corn", "code": "50416853" },
      { "name": "Organic seneca horizon corn", "code": "50416854" },
      { "name": "Organic seneca starshine corn", "code": "50416855" },
      { "name": "Organic seneca white knight corn", "code": "50416856" },
      { "name": "Organic showcase corn", "code": "50416857" },
      { "name": "Organic silver queen corn", "code": "50416858" },
      { "name": "Organic snowbelle corn", "code": "50416859" },
      { "name": "Organic spring snow corn", "code": "50416860" },
      { "name": "Organic spring treat corn", "code": "50416861" },
      { "name": "Organic sugar and gold corn", "code": "50416862" },
      { "name": "Organic sugar buns corn", "code": "50416863" },
      { "name": "Organic sugar snow corn", "code": "50416864" },
      { "name": "Organic sundance corn", "code": "50416865" },
      { "name": "Organic telstar corn", "code": "50416866" },
      { "name": "Organic terminator corn", "code": "50416867" },
      { "name": "Organic treasure corn", "code": "50416868" },
      { "name": "Organic tuxedo corn", "code": "50416869" },
      { "name": "Nominant organic vegetables", "code": "50417000" },
      { "name": "Organic alfalfa", "code": "50417001" },
      { "name": "Organic aloe leaves", "code": "50417002" },
      { "name": "Organic apio", "code": "50417003" },
      { "name": "Organic arrow root", "code": "50417004" },
      { "name": "Organic arrowhead", "code": "50417005" },
      { "name": "Organic arugula", "code": "50417006" },
      { "name": "Organic arum", "code": "50417007" },
      { "name": "Organic bamboo shoots", "code": "50417008" },
      { "name": "Organic banana leaves", "code": "50417009" },
      { "name": "Organic batatas", "code": "50417010" },
      { "name": "Organic bean sprouts", "code": "50417011" },
      { "name": "Organic beet tops", "code": "50417012" },
      { "name": "Organic bittermelon", "code": "50417013" },
      { "name": "Organic caperberries", "code": "50417014" },
      { "name": "Organic carob", "code": "50417015" },
      { "name": "Organic cha-om", "code": "50417016" },
      { "name": "Organic chaoyotes", "code": "50417017" },
      { "name": "Organic chickpeas", "code": "50417018" },
      { "name": "Organic chrysanthemum greens", "code": "50417019" },
      { "name": "Organic dandelion greens", "code": "50417020" },
      { "name": "Organic dandelions", "code": "50417021" },
      { "name": "Organic dasheen", "code": "50417022" },
      { "name": "Organic dau mue or pea tips", "code": "50417023" },
      { "name": "Organic diakon", "code": "50417024" },
      { "name": "Organic donqua", "code": "50417025" },
      { "name": "Organic fiddlehead ferns", "code": "50417026" },
      { "name": "Organic gai choy", "code": "50417027" },
      { "name": "Organic gailon", "code": "50417028" },
      { "name": "Organic galanga", "code": "50417029" },
      { "name": "Organic ginger root", "code": "50417030" },
      { "name": "Organic gobo", "code": "50417031" },
      { "name": "Organic hop sprouts", "code": "50417032" },
      { "name": "Organic horseradish", "code": "50417033" },
      { "name": "Organic jicama", "code": "50417034" },
      { "name": "Organic kudzu", "code": "50417035" },
      { "name": "Organic lily bulb", "code": "50417036" },
      { "name": "Organic linkok", "code": "50417037" },
      { "name": "Organic lo bok", "code": "50417038" },
      { "name": "Organic long beans", "code": "50417039" },
      { "name": "Organic lotus root", "code": "50417040" },
      { "name": "Organic maguey leaves", "code": "50417041" },
      { "name": "Organic mallows", "code": "50417042" },
      { "name": "Organic mamey sapote", "code": "50417043" },
      { "name": "Organic moap", "code": "50417044" },
      { "name": "Organic moo", "code": "50417045" },
      { "name": "Organic moqua", "code": "50417046" },
      { "name": "Organic opos", "code": "50417047" },
      { "name": "Organic palm hearts", "code": "50417048" },
      { "name": "Organic paprika", "code": "50417049" },
      { "name": "Organic purslane", "code": "50417050" },
      { "name": "Organic raddichios", "code": "50417051" },
      { "name": "Organic sinquas", "code": "50417052" },
      { "name": "Organic soybeans", "code": "50417053" },
      { "name": "Organic spoonwart", "code": "50417054" },
      { "name": "Organic taro", "code": "50417055" },
      { "name": "Organic taro leaf", "code": "50417056" },
      { "name": "Organic taro shoot", "code": "50417057" },
      { "name": "Organic tassle grape-hyacinth", "code": "50417058" },
      { "name": "Organic tendergreen", "code": "50417059" },
      { "name": "Organic tepeguaje", "code": "50417060" },
      { "name": "Organic tindora", "code": "50417061" },
      { "name": "Organic tree onion", "code": "50417062" },
      { "name": "Organic udo", "code": "50417063" },
      { "name": "Organic water chestnuts", "code": "50417064" },
      { "name": "Organic water spinach", "code": "50417065" },
      { "name": "Organic yampi", "code": "50417066" },
      { "name": "Organic yautia", "code": "50417067" },
      { "name": "Organic yu choy", "code": "50417068" },
      { "name": "Organic yuca", "code": "50417069" },
      { "name": "Organic sugar peas", "code": "50417100" },
      { "name": "Organic bikini peas", "code": "50417101" },
      { "name": "Organic cavalier peas", "code": "50417102" },
      { "name": "Organic daisy peas", "code": "50417103" },
      { "name": "Organic darfon peas", "code": "50417104" },
      { "name": "Organic early onward peas", "code": "50417105" },
      { "name": "Organic feltham first peas", "code": "50417106" },
      { "name": "Organic hurst green shaft peas", "code": "50417107" },
      { "name": "Organic oregon sugar pod peas", "code": "50417108" },
      { "name": "Organic prince albert peas", "code": "50417109" },
      { "name": "Organic reuzensuiker peas", "code": "50417110" },
      { "name": "Dried vegetables", "code": "50420000" },
      { "name": "Dried artichokes", "code": "50421500" },
      { "name": "Dried brittany artichokes", "code": "50421501" },
      { "name": "Dried catanese artichokes", "code": "50421502" },
      { "name": "Dried french artichokes", "code": "50421503" },
      { "name": "Dried green globe artichokes", "code": "50421504" },
      { "name": "Dried gros camus de bretagne artichokes", "code": "50421505" },
      { "name": "Dried midi artichokes", "code": "50421506" },
      { "name": "Dried purple globe artichokes", "code": "50421507" },
      { "name": "Dried purple sicilian artichokes", "code": "50421508" },
      { "name": "Dried romanesco artichokes", "code": "50421509" },
      { "name": "Dried spinoso sardo artichokes", "code": "50421510" },
      { "name": "Dried vert de laon artichokes", "code": "50421511" },
      { "name": "Dried violetta di chioggia artichokes", "code": "50421512" },
      { "name": "Dried violetto di toscana artichokes", "code": "50421513" },
      { "name": "Dried asparagus", "code": "50421600" },
      { "name": "Dried connover's colossal asparagus", "code": "50421601" },
      { "name": "Dried franklin asparagus", "code": "50421602" },
      { "name": "Dried giant mammoth asparagus", "code": "50421603" },
      { "name": "Dried lucullus asparagus", "code": "50421604" },
      { "name": "Dried martha washington asparagus", "code": "50421605" },
      { "name": "Dried avocados", "code": "50421700" },
      { "name": "Dried ajax b-7 avocados", "code": "50421701" },
      { "name": "Dried arue avocados", "code": "50421702" },
      { "name": "Dried bacon avocados", "code": "50421703" },
      { "name": "Dried benik avocados", "code": "50421704" },
      { "name": "Dried bernecker avocados", "code": "50421705" },
      { "name": "Dried beta avocados", "code": "50421706" },
      { "name": "Dried biondo avocados", "code": "50421707" },
      { "name": "Dried black prince avocados", "code": "50421708" },
      { "name": "Dried blair avocados", "code": "50421709" },
      { "name": "Dried blair booth avocados", "code": "50421710" },
      { "name": "Dried booth 1 avocados", "code": "50421711" },
      { "name": "Dried booth 3 avocados", "code": "50421712" },
      { "name": "Dried booth 5 avocados", "code": "50421713" },
      { "name": "Dried booth 7 avocados", "code": "50421714" },
      { "name": "Dried booth 8 avocados", "code": "50421715" },
      { "name": "Dried brooks 1978 avocados", "code": "50421716" },
      { "name": "Dried brookslate avocados", "code": "50421717" },
      { "name": "Dried california haas avocados", "code": "50421718" },
      { "name": "Dried catalina avocados", "code": "50421719" },
      { "name": "Dried chica avocados", "code": "50421720" },
      { "name": "Dried choquette avocados", "code": "50421721" },
      { "name": "Dried christina avocados", "code": "50421722" },
      { "name": "Dried collinson avocados", "code": "50421723" },
      { "name": "Dried donnie avocados", "code": "50421724" },
      { "name": "Dried dr dupuis number 2 avocados", "code": "50421725" },
      { "name": "Dried dr dupuis avocados", "code": "50421726" },
      { "name": "Dried ettinger avocados", "code": "50421727" },
      { "name": "Dried fuchs avocados", "code": "50421728" },
      { "name": "Dried fuchs gwen avocados", "code": "50421729" },
      { "name": "Dried fuerte avocados", "code": "50421730" },
      { "name": "Dried gorham avocados", "code": "50421731" },
      { "name": "Dried gossman avocados", "code": "50421732" },
      { "name": "Dried guatemalan seedling avocados", "code": "50421733" },
      { "name": "Dried hall avocados", "code": "50421734" },
      { "name": "Dried hardee avocados", "code": "50421735" },
      { "name": "Dried haas avocados", "code": "50421736" },
      { "name": "Dried herman avocados", "code": "50421737" },
      { "name": "Dried hickson avocados", "code": "50421738" },
      { "name": "Dried k-5 avocados", "code": "50421739" },
      { "name": "Dried k-9 avocados", "code": "50421740" },
      { "name": "Dried lamb haas avocados", "code": "50421741" },
      { "name": "Dried leona avocados", "code": "50421742" },
      { "name": "Dried leona linda avocados", "code": "50421743" },
      { "name": "Dried lisa p avocados", "code": "50421744" },
      { "name": "Dried lisa loretta avocados", "code": "50421745" },
      { "name": "Dried loretta avocados", "code": "50421746" },
      { "name": "Dried lula avocados", "code": "50421747" },
      { "name": "Dried lula macarthur avocados", "code": "50421748" },
      { "name": "Dried marcus avocados", "code": "50421749" },
      { "name": "Dried melendez avocados", "code": "50421750" },
      { "name": "Dried meya p avocados", "code": "50421751" },
      { "name": "Dried miguel p avocados", "code": "50421752" },
      { "name": "Dried monroe avocados", "code": "50421753" },
      { "name": "Dried murrieta green avocados", "code": "50421754" },
      { "name": "Dried nabal avocados", "code": "50421755" },
      { "name": "Dried nadir avocados", "code": "50421756" },
      { "name": "Dried nesbitt avocados", "code": "50421757" },
      { "name": "Dried peterson avocados", "code": "50421758" },
      { "name": "Dried pinelli avocados", "code": "50421759" },
      { "name": "Dried pinkerton avocados", "code": "50421760" },
      { "name": "Dried pollock avocados", "code": "50421761" },
      { "name": "Dried puebla avocados", "code": "50421762" },
      { "name": "Dried reed avocados", "code": "50421763" },
      { "name": "Dried rue avocados", "code": "50421764" },
      { "name": "Dried ruehle avocados", "code": "50421765" },
      { "name": "Dried ryan avocados", "code": "50421766" },
      { "name": "Dried semil 34 avocados", "code": "50421767" },
      { "name": "Dried semil 43 avocados", "code": "50421768" },
      { "name": "Dried simmonds avocados", "code": "50421769" },
      { "name": "Dried simpson avocados", "code": "50421770" },
      { "name": "Dried taylor avocados", "code": "50421771" },
      { "name": "Dried tonnage avocados", "code": "50421772" },
      { "name": "Dried tower avocados", "code": "50421773" },
      { "name": "Dried tower li avocados", "code": "50421774" },
      { "name": "Dried trapp avocados", "code": "50421775" },
      { "name": "Dried west indian seedling avocados", "code": "50421776" },
      { "name": "Dried wagner avocados", "code": "50421777" },
      { "name": "Dried waldin avocados", "code": "50421778" },
      { "name": "Dried wurtz avocados", "code": "50421779" },
      { "name": "Dried zio p avocados", "code": "50421780" },
      { "name": "Dried ziu avocados", "code": "50421781" },
      { "name": "Dried zutano avocados", "code": "50421782" },
      { "name": "Dried beans", "code": "50421800" },
      { "name": "Dried anasazi or aztec beans", "code": "50421801" },
      { "name": "Dried appaloosa beans", "code": "50421802" },
      { "name": "Dried azuki beans", "code": "50421803" },
      { "name": "Dried barlotti beans", "code": "50421804" },
      { "name": "Dried black appaloosa beans", "code": "50421805" },
      { "name": "Dried black beans", "code": "50421806" },
      { "name": "Dried black gram beans", "code": "50421807" },
      { "name": "Dried black shackamaxon beans", "code": "50421808" },
      { "name": "Dried blackeyed beans", "code": "50421809" },
      { "name": "Dried bobby beans", "code": "50421810" },
      { "name": "Dried bolita beans", "code": "50421811" },
      { "name": "Dried brown lazy wife beans", "code": "50421812" },
      { "name": "Dried calypso beans", "code": "50421813" },
      { "name": "Dried cannellini beans", "code": "50421814" },
      { "name": "Dried castor beans", "code": "50421815" },
      { "name": "Dried china yellow beans", "code": "50421816" },
      { "name": "Dried dragon tongue beans", "code": "50421817" },
      { "name": "Dried european soldier beans", "code": "50421818" },
      { "name": "Dried fava or broad beans", "code": "50421819" },
      { "name": "Dried flageolet beans", "code": "50421820" },
      { "name": "Dried french horticultural beans", "code": "50421821" },
      { "name": "Dried french navy beans", "code": "50421822" },
      { "name": "Dried giant white coco beans", "code": "50421823" },
      { "name": "Dried green beans", "code": "50421824" },
      { "name": "Dried green romano beans", "code": "50421825" },
      { "name": "Dried guar gum beans", "code": "50421826" },
      { "name": "Dried haricot beans", "code": "50421827" },
      { "name": "Dried hyacinth beans", "code": "50421828" },
      { "name": "Dried italian type beans", "code": "50421829" },
      { "name": "Dried jackson wonder beans", "code": "50421830" },
      { "name": "Dried jacob's cattle beans", "code": "50421831" },
      { "name": "Dried kentucky wonder beans", "code": "50421832" },
      { "name": "Dried kidney beans", "code": "50421833" },
      { "name": "Dried lima beans", "code": "50421834" },
      { "name": "Dried madeira/madera beans", "code": "50421835" },
      { "name": "Dried marrow beans", "code": "50421836" },
      { "name": "Dried mat beans", "code": "50421837" },
      { "name": "Dried monstoller wild goose beans", "code": "50421838" },
      { "name": "Dried mortgage lifter beans", "code": "50421839" },
      { "name": "Dried moth beans", "code": "50421840" },
      { "name": "Dried mung beans", "code": "50421841" },
      { "name": "Dried munsi wolf bean", "code": "50421842" },
      { "name": "Dried nuna beans", "code": "50421843" },
      { "name": "Dried pinto beans", "code": "50421844" },
      { "name": "Dried pole beans", "code": "50421845" },
      { "name": "Dried runner beans", "code": "50421846" },
      { "name": "Dried string beans", "code": "50421847" },
      { "name": "Dried tamarind beans", "code": "50421848" },
      { "name": "Dried tonka beans", "code": "50421849" },
      { "name": "Dried wax beans", "code": "50421850" },
      { "name": "Dried winged beans", "code": "50421851" },
      { "name": "Dried yard long beans", "code": "50421852" },
      { "name": "Dried beets", "code": "50421900" },
      { "name": "Dried action beets", "code": "50421901" },
      { "name": "Dried albina vereduna beets", "code": "50421902" },
      { "name": "Dried barbabietola di chioggia beets", "code": "50421903" },
      { "name": "Dried boltardy beets", "code": "50421904" },
      { "name": "Dried bonel beets", "code": "50421905" },
      { "name": "Dried burpees golden beets", "code": "50421906" },
      { "name": "Dried cheltenham green top beets", "code": "50421907" },
      { "name": "Dried cheltenham mono beets", "code": "50421908" },
      { "name": "Dried chioggia beets", "code": "50421909" },
      { "name": "Dried cylindra beets", "code": "50421910" },
      { "name": "Dried d'egypte beets", "code": "50421911" },
      { "name": "Dried detroit 2 dark red beets", "code": "50421912" },
      { "name": "Dried detroit 2 little ball beets", "code": "50421913" },
      { "name": "Dried egyptian flat beets", "code": "50421914" },
      { "name": "Dried egyptian turnip rooted beets", "code": "50421915" },
      { "name": "Dried formanova beets", "code": "50421916" },
      { "name": "Dried forono beets", "code": "50421917" },
      { "name": "Dried monaco beets", "code": "50421918" },
      { "name": "Dried monogram beets", "code": "50421919" },
      { "name": "Dried pronto beets", "code": "50421920" },
      { "name": "Dried regalia beets", "code": "50421921" },
      { "name": "Dried sugar beets", "code": "50421922" },
      { "name": "Dried broccoli", "code": "50422000" },
      { "name": "Dried broccolini", "code": "50422001" },
      { "name": "Dried broccoli romanesco", "code": "50422002" },
      { "name": "Dried broccoli raab", "code": "50422003" },
      { "name": "Dried chinese broccoli", "code": "50422004" },
      { "name": "Dried brussel sprouts", "code": "50422100" },
      { "name": "Dried citadel brussel sprouts", "code": "50422101" },
      { "name": "Dried falstaff brussel sprouts", "code": "50422102" },
      { "name": "Dried oliver brussel sprouts", "code": "50422103" },
      { "name": "Dried peer gynt brussel sprouts", "code": "50422104" },
      { "name": "Dried rampart brussel sprouts", "code": "50422105" },
      { "name": "Dried rubine brussel sprouts", "code": "50422106" },
      { "name": "Dried widgeon brussel sprouts", "code": "50422107" },
      { "name": "Dried bunching onions", "code": "50422200" },
      { "name": "Dried beltsville bunching onions", "code": "50422201" },
      { "name": "Dried feast bunching onions", "code": "50422202" },
      { "name": "Dried ishikura bunching onions", "code": "50422203" },
      { "name": "Dried kyoto market bunching onions", "code": "50422204" },
      { "name": "Dried red beard bunching onions", "code": "50422205" },
      { "name": "Dried redmate bunching onions", "code": "50422206" },
      { "name": "Dried santa claus bunching onions", "code": "50422207" },
      { "name": "Dried tokyo bunching onions", "code": "50422208" },
      { "name": "Dried white lisbon bunching onions", "code": "50422209" },
      { "name": "Dried winter white bunching onions", "code": "50422210" },
      { "name": "Dried winter-over bunching onions", "code": "50422211" },
      { "name": "Dried cabbages", "code": "50422300" },
      { "name": "Dried black cabbages", "code": "50422301" },
      { "name": "Dried savoy cabbages", "code": "50422302" },
      { "name": "Dried skunk cabbages", "code": "50422303" },
      { "name": "Dried white cabbages", "code": "50422304" },
      { "name": "Dried cardoons", "code": "50422400" },
      { "name": "Dried lunghi cardoons", "code": "50422401" },
      { "name": "Dried gobbi cardoons", "code": "50422402" },
      { "name": "Dried carrots", "code": "50422500" },
      { "name": "Dried amsterdam carrots", "code": "50422501" },
      { "name": "Dried autumn king carrots", "code": "50422502" },
      { "name": "Dried berlicum carrots", "code": "50422503" },
      { "name": "Dried chantenay carrots", "code": "50422504" },
      { "name": "Dried nantes carrots", "code": "50422505" },
      { "name": "Dried paris market carrots", "code": "50422506" },
      { "name": "Dried cauliflowers", "code": "50422600" },
      { "name": "Dried all the year round cauliflowers", "code": "50422601" },
      { "name": "Dried alverda cauliflowers", "code": "50422602" },
      { "name": "Dried autumn giant 3 cauliflowers", "code": "50422603" },
      { "name": "Dried dok elgon cauliflowers", "code": "50422604" },
      { "name": "Dried early snowball cauliflowers", "code": "50422605" },
      { "name": "Dried limelight cauliflowers", "code": "50422606" },
      { "name": "Dried minaret cauliflowers", "code": "50422607" },
      { "name": "Dried orange bouquet cauliflowers", "code": "50422608" },
      { "name": "Dried purple cape cauliflowers", "code": "50422609" },
      { "name": "Dried snowball cauliflowers", "code": "50422610" },
      { "name": "Dried walcheren winter 3 cauliflowers", "code": "50422611" },
      { "name": "Dried white rock cauliflowers", "code": "50422612" },
      { "name": "Dried celery", "code": "50422700" },
      { "name": "Dried celebrity celery", "code": "50422701" },
      { "name": "Dried celeriac", "code": "50422702" },
      { "name": "Dried chinese celery", "code": "50422703" },
      { "name": "Dried french dinant celery", "code": "50422704" },
      { "name": "Dried giant pink celery", "code": "50422705" },
      { "name": "Dried giant red celery", "code": "50422706" },
      { "name": "Dried giant white celery", "code": "50422707" },
      { "name": "Dried golden self-blanching celery", "code": "50422708" },
      { "name": "Dried greensleeves celery", "code": "50422709" },
      { "name": "Dried hopkins fenlander celery", "code": "50422710" },
      { "name": "Dried ivory tower celery", "code": "50422711" },
      { "name": "Dried lathom self-blanching celery", "code": "50422712" },
      { "name": "Dried soup celery d'amsterdam", "code": "50422713" },
      { "name": "Dried standard bearer celery", "code": "50422714" },
      { "name": "Dried tall utah triumph celery", "code": "50422715" },
      { "name": "Dried chards", "code": "50422800" },
      { "name": "Dried bright lights chard", "code": "50422801" },
      { "name": "Dried fordhook giant chard", "code": "50422802" },
      { "name": "Dried lucullus chard", "code": "50422803" },
      { "name": "Dried perpetual spinach chard", "code": "50422804" },
      { "name": "Dried rhubarb chard", "code": "50422805" },
      { "name": "Dried swiss chard", "code": "50422806" },
      { "name": "Dried vulcan chard", "code": "50422807" },
      { "name": "Dried white king chard", "code": "50422808" },
      { "name": "Dried chicories", "code": "50422900" },
      { "name": "Dried broad leaved batavian chicory", "code": "50422901" },
      { "name": "Dried en cornet de bordeaux chicory", "code": "50422902" },
      { "name": "Dried green curled ruffee chicory", "code": "50422903" },
      { "name": "Dried green curled chicory", "code": "50422904" },
      { "name": "Dried ione limnos chicory", "code": "50422905" },
      { "name": "Dried riccia pancalieri chicory", "code": "50422906" },
      { "name": "Dried salad king chicory", "code": "50422907" },
      { "name": "Dried sanda chicory", "code": "50422908" },
      { "name": "Dried scarola verde chicory", "code": "50422909" },
      { "name": "Dried tres fine maraichere chicory", "code": "50422910" },
      {
        "name": "Dried wallone freisee weschelkopf chicory",
        "code": "50422911"
      },
      { "name": "Dried chinese cabbages", "code": "50423000" },
      { "name": "Dried bok choy", "code": "50423001" },
      { "name": "Dried chinese flat-headed cabbage", "code": "50423002" },
      { "name": "Dried chinese flowering cabbage", "code": "50423003" },
      { "name": "Dried choy sum", "code": "50423004" },
      { "name": "Dried dwarf bok choy", "code": "50423005" },
      { "name": "Dried fengshan bok choy", "code": "50423006" },
      { "name": "Dried jade pagoda bok choy", "code": "50423007" },
      { "name": "Dried kasumi bok choy", "code": "50423008" },
      { "name": "Dried nerva bok choy", "code": "50423009" },
      { "name": "Dried rosette bok choy", "code": "50423010" },
      { "name": "Dried ruffles bok choy", "code": "50423011" },
      { "name": "Dried santo serrated leaved cabbage", "code": "50423012" },
      { "name": "Dried shanghai d bok choy", "code": "50423013" },
      { "name": "Dried shantung cabbage", "code": "50423014" },
      { "name": "Dried tip top cabbage", "code": "50423015" },
      { "name": "Dried yau choy sum", "code": "50423016" },
      { "name": "Dried chives", "code": "50423100" },
      { "name": "Dried chinese chives", "code": "50423101" },
      { "name": "Dried common chives", "code": "50423102" },
      { "name": "Dried corn", "code": "50423200" },
      { "name": "Dried aloha corn", "code": "50423201" },
      { "name": "Dried alpine corn", "code": "50423202" },
      { "name": "Dried ambrosia corn", "code": "50423203" },
      { "name": "Dried argent corn", "code": "50423204" },
      { "name": "Dried aspen corn", "code": "50423205" },
      { "name": "Dried avalanche corn", "code": "50423206" },
      { "name": "Dried biqueen corn", "code": "50423207" },
      { "name": "Dried bodacious corn", "code": "50423208" },
      { "name": "Dried butter and sugar corn", "code": "50423209" },
      { "name": "Dried calico belle corn", "code": "50423210" },
      { "name": "Dried camelot corn", "code": "50423211" },
      {
        "name": "Dried challenger crisp \u2018n sweet corn",
        "code": "50423212"
      },
      { "name": "Dried champ corn", "code": "50423213" },
      { "name": "Dried cotton candy corn", "code": "50423214" },
      { "name": "Dried d\u2019artagnan corn", "code": "50423215" },
      { "name": "Dried dazzle corn", "code": "50423216" },
      { "name": "Dried diamonds and gold corn", "code": "50423217" },
      { "name": "Dried divinity corn", "code": "50423218" },
      { "name": "Dried double delight corn", "code": "50423219" },
      { "name": "Dried double gem corn", "code": "50423220" },
      { "name": "Dried earlivee corn", "code": "50423221" },
      { "name": "Dried early xtra sweet corn", "code": "50423222" },
      { "name": "Dried excel corn", "code": "50423223" },
      { "name": "Dried golden cross bantam corn", "code": "50423224" },
      { "name": "Dried honey and cream corn", "code": "50423225" },
      { "name": "Dried honey \u2018n pearl corn", "code": "50423226" },
      { "name": "Dried how sweet it is corn", "code": "50423227" },
      { "name": "Dried hudson corn", "code": "50423228" },
      { "name": "Dried illini gold corn", "code": "50423229" },
      { "name": "Dried illini xtra sweet corn", "code": "50423230" },
      { "name": "Dried incredible corn", "code": "50423231" },
      { "name": "Dried iochief corn", "code": "50423232" },
      { "name": "Dried jubilee corn", "code": "50423233" },
      { "name": "Dried jubilee supersweet corn", "code": "50423234" },
      { "name": "Dried kandy korn corn", "code": "50423235" },
      { "name": "Dried kiss \u2018n tell corn", "code": "50423236" },
      { "name": "Dried lancelot corn", "code": "50423237" },
      { "name": "Dried maple sweet corn", "code": "50423238" },
      { "name": "Dried medley corn", "code": "50423239" },
      { "name": "Dried merlin corn", "code": "50423240" },
      { "name": "Dried miracle corn", "code": "50423241" },
      { "name": "Dried nk-199 corn", "code": "50423242" },
      { "name": "Dried peaches and cream corn", "code": "50423243" },
      { "name": "Dried pearl white corn", "code": "50423244" },
      { "name": "Dried pegasus corn", "code": "50423245" },
      { "name": "Dried phenomenal corn", "code": "50423246" },
      { "name": "Dried platinum lady corn", "code": "50423247" },
      { "name": "Dried precocious corn", "code": "50423248" },
      { "name": "Dried pristine corn", "code": "50423249" },
      { "name": "Dried quickie corn", "code": "50423250" },
      { "name": "Dried radiance corn", "code": "50423251" },
      { "name": "Dried seneca brave corn", "code": "50423252" },
      { "name": "Dried seneca dawn corn", "code": "50423253" },
      { "name": "Dried seneca horizon corn", "code": "50423254" },
      { "name": "Dried seneca starshine corn", "code": "50423255" },
      { "name": "Dried seneca white knight corn", "code": "50423256" },
      { "name": "Dried showcase corn", "code": "50423257" },
      { "name": "Dried silver queen corn", "code": "50423258" },
      { "name": "Dried snowbelle corn", "code": "50423259" },
      { "name": "Dried spring snow corn", "code": "50423260" },
      { "name": "Dried spring treat corn", "code": "50423261" },
      { "name": "Dried sugar and gold corn", "code": "50423262" },
      { "name": "Dried sugar buns corn", "code": "50423263" },
      { "name": "Dried sugar snow corn", "code": "50423264" },
      { "name": "Dried sundance corn", "code": "50423265" },
      { "name": "Dried telstar corn", "code": "50423266" },
      { "name": "Dried terminator corn", "code": "50423267" },
      { "name": "Dried treasure corn", "code": "50423268" },
      { "name": "Dried tuxedo corn", "code": "50423269" },
      { "name": "Dried cresses", "code": "50423300" },
      { "name": "Dried land cress", "code": "50423301" },
      { "name": "Dried nasturtium", "code": "50423302" },
      { "name": "Dried watercress", "code": "50423303" },
      { "name": "Dried wintercress", "code": "50423304" },
      { "name": "Dried cucumbers", "code": "50423400" },
      { "name": "Dried arena cucumbers", "code": "50423401" },
      { "name": "Dried armenian cucumbers", "code": "50423402" },
      { "name": "Dried athene cucumbers", "code": "50423403" },
      { "name": "Dried bianco lungo di parigi cucumbers", "code": "50423404" },
      { "name": "Dried burpless tasty green cucumbers", "code": "50423405" },
      { "name": "Dried chicago pickling cucumbers", "code": "50423406" },
      { "name": "Dried crystal apple cucumbers", "code": "50423407" },
      { "name": "Dried crystal lemon cucumbers", "code": "50423408" },
      { "name": "Dried danimas cucumbers", "code": "50423409" },
      { "name": "Dried gherkin cucumbers", "code": "50423410" },
      { "name": "Dried hokus cucumbers", "code": "50423411" },
      { "name": "Dried japanese cucumbers", "code": "50423412" },
      { "name": "Dried karela cucumbers", "code": "50423413" },
      { "name": "Dried korila cucumbers", "code": "50423414" },
      { "name": "Dried long green improved cucumbers", "code": "50423415" },
      { "name": "Dried marketmore cucumbers", "code": "50423416" },
      { "name": "Dried midget cucumbers", "code": "50423417" },
      { "name": "Dried national pickling cucumbers", "code": "50423418" },
      { "name": "Dried persian cucumbers", "code": "50423419" },
      { "name": "Dried telegraph cucumbers", "code": "50423420" },
      { "name": "Dried telegraph improved cucumbers", "code": "50423421" },
      { "name": "Dried vert de massy cornichon cucumbers", "code": "50423422" },
      { "name": "Dried yamato cucumbers", "code": "50423423" },
      { "name": "Dried eggplants", "code": "50423500" },
      { "name": "Dried bambino eggplants", "code": "50423501" },
      { "name": "Dried black beauty eggplants", "code": "50423502" },
      { "name": "Dried black enorma eggplants", "code": "50423503" },
      { "name": "Dried chinese eggplants", "code": "50423504" },
      { "name": "Dried easter egg eggplants", "code": "50423505" },
      { "name": "Dried filipino eggplants", "code": "50423506" },
      { "name": "Dried florida market eggplants", "code": "50423507" },
      { "name": "Dried indian eggplants", "code": "50423508" },
      { "name": "Dried italian eggplants", "code": "50423509" },
      { "name": "Dried japanese eggplants", "code": "50423510" },
      { "name": "Dried long purple eggplants", "code": "50423511" },
      { "name": "Dried long striped eggplants", "code": "50423512" },
      { "name": "Dried moneymaker eggplants", "code": "50423513" },
      { "name": "Dried ova eggplants", "code": "50423514" },
      { "name": "Dried pea eggplants", "code": "50423515" },
      { "name": "Dried short tom eggplants", "code": "50423516" },
      { "name": "Dried sicilian eggplants", "code": "50423517" },
      { "name": "Dried thai eggplants", "code": "50423518" },
      { "name": "Dried violette di firenze eggplants", "code": "50423519" },
      { "name": "Dried white eggplants", "code": "50423520" },
      { "name": "Dried endives", "code": "50423600" },
      { "name": "Dried brussels witloof endives", "code": "50423601" },
      { "name": "Dried castelfranco endives", "code": "50423602" },
      { "name": "Dried catalogna di galatina endives", "code": "50423603" },
      { "name": "Dried chioggia endives", "code": "50423604" },
      { "name": "Dried grumolo verde endives", "code": "50423605" },
      { "name": "Dried large rooted magdeburg endives", "code": "50423606" },
      { "name": "Dried palla rossa zorzi precoce endives", "code": "50423607" },
      { "name": "Dried radice amare endives", "code": "50423608" },
      { "name": "Dried rossa di treviso endives", "code": "50423609" },
      { "name": "Dried rossa di verona endives", "code": "50423610" },
      { "name": "Dried soncino endives", "code": "50423611" },
      { "name": "Dried sugarhat endives", "code": "50423612" },
      { "name": "Dried verona endives", "code": "50423613" },
      { "name": "Dried witloof zoom endives", "code": "50423614" },
      { "name": "Dried fennels", "code": "50423700" },
      { "name": "Dried cantino fennel", "code": "50423701" },
      { "name": "Dried fino fennel", "code": "50423702" },
      { "name": "Dried herald fennel", "code": "50423703" },
      { "name": "Dried perfection fennel", "code": "50423704" },
      { "name": "Dried sirio fennel", "code": "50423705" },
      { "name": "Dried sweet florence fennel", "code": "50423706" },
      { "name": "Dried tardo fennel", "code": "50423707" },
      { "name": "Dried garlics", "code": "50423800" },
      { "name": "Dried california late garlic", "code": "50423801" },
      { "name": "Dried chinese garlic stems", "code": "50423802" },
      { "name": "Dried garlic chives", "code": "50423803" },
      { "name": "Dried germidor garlic", "code": "50423804" },
      { "name": "Dried long keeper garlic", "code": "50423805" },
      { "name": "Dried ramson garlic", "code": "50423806" },
      { "name": "Dried rocambole garlic", "code": "50423807" },
      { "name": "Dried rose de lautrec garlic", "code": "50423808" },
      { "name": "Dried solent wight garlic", "code": "50423809" },
      { "name": "Dried spanish morado garlic", "code": "50423810" },
      { "name": "Dried venetian/italian garlic", "code": "50423811" },
      { "name": "Dried gourds", "code": "50423900" },
      { "name": "Dried angled loofah", "code": "50423901" },
      { "name": "Dried bitter gourd", "code": "50423902" },
      { "name": "Dried bottle gourd", "code": "50423903" },
      { "name": "Dried calabash gourds", "code": "50423904" },
      { "name": "Dried fuzzy/hairy melon", "code": "50423905" },
      { "name": "Dried musky gourd", "code": "50423906" },
      { "name": "Dried smooth loofah", "code": "50423907" },
      { "name": "Dried snake gourd", "code": "50423908" },
      { "name": "Dried spiny bitter gourd", "code": "50423909" },
      { "name": "Dried tinda gourds", "code": "50423910" },
      { "name": "Dried tindoori gourds", "code": "50423911" },
      { "name": "Dried green peas", "code": "50424000" },
      { "name": "Dried china peas", "code": "50424001" },
      { "name": "Dried english peas", "code": "50424002" },
      { "name": "Dried garden peas", "code": "50424003" },
      { "name": "Dried snow peas", "code": "50424004" },
      { "name": "Dried sugar snap peas", "code": "50424005" },
      { "name": "Dried herbs", "code": "50424100" },
      { "name": "Dried basil", "code": "50424101" },
      { "name": "Dried bay leaves", "code": "50424102" },
      { "name": "Dried borage", "code": "50424103" },
      { "name": "Dried caraway", "code": "50424104" },
      { "name": "Dried chervil", "code": "50424105" },
      { "name": "Dried cilantro", "code": "50424106" },
      { "name": "Dried cipolinos", "code": "50424107" },
      { "name": "Dried curry leaves", "code": "50424108" },
      { "name": "Dried dill", "code": "50424109" },
      { "name": "Dried epazote", "code": "50424110" },
      { "name": "Dried fenugreek", "code": "50424111" },
      { "name": "Dried lemon grass", "code": "50424112" },
      { "name": "Dried marjoram", "code": "50424113" },
      { "name": "Dried mint", "code": "50424114" },
      { "name": "Dried oregano", "code": "50424115" },
      { "name": "Dried papalo", "code": "50424116" },
      { "name": "Dried pepicha", "code": "50424117" },
      { "name": "Dried perilla", "code": "50424118" },
      { "name": "Dried recao", "code": "50424119" },
      { "name": "Dried rosemary", "code": "50424120" },
      { "name": "Dried sage", "code": "50424121" },
      { "name": "Dried salsify", "code": "50424122" },
      { "name": "Dried savory", "code": "50424123" },
      { "name": "Dried tarragon", "code": "50424124" },
      { "name": "Dried thyme", "code": "50424125" },
      { "name": "Dried tumeric", "code": "50424126" },
      { "name": "Dried verdulaga", "code": "50424127" },
      { "name": "Dried kale", "code": "50424200" },
      { "name": "Dried curly kale", "code": "50424201" },
      { "name": "Dried collard greens", "code": "50424202" },
      { "name": "Dried kohlrabi", "code": "50424300" },
      { "name": "Dried azur star kohlrabi", "code": "50424301" },
      { "name": "Dried green vienna kohlrabi", "code": "50424302" },
      { "name": "Dried lanro kohlrabi", "code": "50424303" },
      { "name": "Dried purple vienna kohlrabi", "code": "50424304" },
      { "name": "Dried rowel trero kohlrabi", "code": "50424305" },
      { "name": "Dried white vienna kohlrabi", "code": "50424306" },
      { "name": "Dried leeks", "code": "50424400" },
      { "name": "Dried autumn giant-cobra leeks", "code": "50424401" },
      { "name": "Dried autumn mammoth 2 leeks", "code": "50424402" },
      { "name": "Dried bleu de solaise leeks", "code": "50424403" },
      { "name": "Dried cortina leeks", "code": "50424404" },
      { "name": "Dried prelina leeks", "code": "50424405" },
      { "name": "Dried wild leek ramp", "code": "50424406" },
      { "name": "Dried lentils", "code": "50424500" },
      { "name": "Dried beluga lentils", "code": "50424501" },
      { "name": "Dried french green lentils", "code": "50424502" },
      { "name": "Dried green lentils", "code": "50424503" },
      { "name": "Dried petite crimson lentils", "code": "50424504" },
      { "name": "Dried spanish pardina lentils", "code": "50424505" },
      { "name": "Dried split red lentils", "code": "50424506" },
      { "name": "Dried split yellow lentils", "code": "50424507" },
      { "name": "Dried tarahumara pinks lentils", "code": "50424508" },
      { "name": "Dried lettuces", "code": "50424600" },
      { "name": "Dried bibb lettuce", "code": "50424601" },
      { "name": "Dried boston lettuce", "code": "50424602" },
      { "name": "Dried frisee lettuce", "code": "50424603" },
      { "name": "Dried lolla rossa lettuce", "code": "50424604" },
      { "name": "Dried mesculin mix lettuce", "code": "50424605" },
      { "name": "Dried mizuna lettuce", "code": "50424606" },
      { "name": "Dried red leaf lettuce", "code": "50424607" },
      { "name": "Dried red oak leaf lettuce", "code": "50424608" },
      { "name": "Dried ruby romaine lettuce", "code": "50424609" },
      { "name": "Dried baby red romaine lettuce", "code": "50424610" },
      { "name": "Dried butterhead lettuces", "code": "50424611" },
      { "name": "Dried chinese lettuces", "code": "50424612" },
      { "name": "Dried crisphead lettuces", "code": "50424613" },
      { "name": "Dried green leaf lettuce", "code": "50424614" },
      { "name": "Dried iceberg lettuce", "code": "50424615" },
      { "name": "Dried lamb\u2019s lettuces", "code": "50424616" },
      { "name": "Dried looseleaf lettuces", "code": "50424617" },
      { "name": "Dried mache lettuce", "code": "50424618" },
      { "name": "Dried red boston lettuce", "code": "50424619" },
      { "name": "Dried red headed lettuces", "code": "50424620" },
      { "name": "Dried romaine lettuces", "code": "50424621" },
      { "name": "Dried russian red mustard lettuce", "code": "50424622" },
      { "name": "Dried tatsoi lettuce", "code": "50424623" },
      { "name": "Dried malanga", "code": "50424700" },
      { "name": "Dried amarilla malanga", "code": "50424701" },
      { "name": "Dried blanca malanga", "code": "50424702" },
      { "name": "Dried coco malanga", "code": "50424703" },
      { "name": "Dried eddoes malanga", "code": "50424704" },
      { "name": "Dried islena malanga", "code": "50424705" },
      { "name": "Dried lila malanga", "code": "50424706" },
      { "name": "Dried mushrooms", "code": "50424800" },
      { "name": "Dried black trumpet mushrooms", "code": "50424801" },
      { "name": "Dried brown mushrooms", "code": "50424802" },
      { "name": "Dried champinion mushrooms", "code": "50424803" },
      { "name": "Dried chanterelle mushrooms", "code": "50424804" },
      { "name": "Dried cremini mushrooms", "code": "50424805" },
      { "name": "Dried enoki mushrooms", "code": "50424806" },
      { "name": "Dried hedge hog mushrooms", "code": "50424807" },
      { "name": "Dried hen of the woods mushrooms", "code": "50424808" },
      { "name": "Dried lobster mushrooms", "code": "50424809" },
      { "name": "Dried morels mushrooms", "code": "50424810" },
      { "name": "Dried oyster mushrooms", "code": "50424811" },
      { "name": "Dried pleurotus mushrooms", "code": "50424812" },
      { "name": "Dried pompom mushrooms", "code": "50424813" },
      { "name": "Dried porcieni mushrooms", "code": "50424814" },
      { "name": "Dried portobella mushrooms", "code": "50424815" },
      { "name": "Dried shiitake mushrooms", "code": "50424816" },
      { "name": "Dried shimeji mushrooms", "code": "50424817" },
      { "name": "Dried st george's mushrooms", "code": "50424818" },
      { "name": "Dried white mushrooms", "code": "50424819" },
      { "name": "Dried white trumpet mushrooms", "code": "50424820" },
      { "name": "Dried woodear mushrooms", "code": "50424821" },
      { "name": "Dried mustards", "code": "50424900" },
      { "name": "Dried bamboo mustard", "code": "50424901" },
      { "name": "Dried garlic mustard", "code": "50424902" },
      { "name": "Dried giantleafed mustard", "code": "50424903" },
      { "name": "Dried red in snow mustard", "code": "50424904" },
      { "name": "Dried southern mustard", "code": "50424905" },
      { "name": "Dried wrapped heart mustard", "code": "50424906" },
      { "name": "Dried nightshades", "code": "50425000" },
      { "name": "Dried chinese lantern", "code": "50425001" },
      { "name": "Dried garden huckleberry", "code": "50425002" },
      { "name": "Dried naranjilla", "code": "50425003" },
      { "name": "Dried tomatillo", "code": "50425004" },
      { "name": "Dried okras", "code": "50425100" },
      { "name": "Dried artist okra", "code": "50425101" },
      { "name": "Dried burgundy okra", "code": "50425102" },
      { "name": "Dried clemson spineless okra", "code": "50425103" },
      { "name": "Dried dwarf green long pod okra", "code": "50425104" },
      { "name": "Dried mammoth spineless long pod okra", "code": "50425105" },
      { "name": "Dried red velvet okra", "code": "50425106" },
      { "name": "Dried star of david heirloom okra", "code": "50425107" },
      { "name": "Dried onions", "code": "50425200" },
      { "name": "Dried albion onions", "code": "50425201" },
      { "name": "Dried alisa craig onions", "code": "50425202" },
      { "name": "Dried boiling onions", "code": "50425203" },
      { "name": "Dried buffalo onions", "code": "50425204" },
      { "name": "Dried bulb onions", "code": "50425205" },
      { "name": "Dried creaming onions", "code": "50425206" },
      { "name": "Dried express yellow o-x onions", "code": "50425207" },
      { "name": "Dried kelsae onions", "code": "50425208" },
      { "name": "Dried marshalls giant fen globe onions", "code": "50425209" },
      { "name": "Dried pearl onions", "code": "50425210" },
      { "name": "Dried red baron onions", "code": "50425211" },
      { "name": "Dried red onions", "code": "50425212" },
      { "name": "Dried rijnsberger onions", "code": "50425213" },
      { "name": "Dried senshyu semi-globe yellow onions", "code": "50425214" },
      { "name": "Dried sturon onions", "code": "50425215" },
      { "name": "Dried stuttgarter giant onions", "code": "50425216" },
      { "name": "Dried sweet onions", "code": "50425217" },
      { "name": "Dried torpedo or red italian onions", "code": "50425218" },
      { "name": "Dried red storage onions", "code": "50425219" },
      { "name": "Dried white storage onions", "code": "50425220" },
      { "name": "Dried yellow storage onions", "code": "50425221" },
      { "name": "Dried peanuts", "code": "50425300" },
      { "name": "Dried bambarra groundnut peanuts", "code": "50425301" },
      { "name": "Dried florunner peanuts", "code": "50425302" },
      {
        "name": "Dried hausa/kersting's ground nut peanuts",
        "code": "50425303"
      },
      { "name": "Dried spanish peanuts", "code": "50425304" },
      { "name": "Dried valencia peanuts", "code": "50425305" },
      { "name": "Dried virginia peanuts", "code": "50425306" },
      { "name": "Dried peas", "code": "50425400" },
      { "name": "Dried purple hull peas", "code": "50425401" },
      { "name": "Dried pinkeye peas", "code": "50425402" },
      { "name": "Dried crowder peas", "code": "50425403" },
      { "name": "Dried white acre peas", "code": "50425404" },
      { "name": "Dried blackeyed peas", "code": "50425405" },
      { "name": "Dried zipper cream peas", "code": "50425406" },
      { "name": "Dried peppers", "code": "50425500" },
      { "name": "Dried ajies peppers", "code": "50425501" },
      { "name": "Dried arbol peppers", "code": "50425502" },
      { "name": "Dried cheese peppers", "code": "50425503" },
      { "name": "Dried chilaca peppers", "code": "50425504" },
      { "name": "Dried cubanelles peppers", "code": "50425505" },
      { "name": "Dried fresno peppers", "code": "50425506" },
      { "name": "Dried kapia peppers", "code": "50425507" },
      { "name": "Dried korean peppers", "code": "50425508" },
      { "name": "Dried manzano peppers", "code": "50425509" },
      { "name": "Dried melrose peppers", "code": "50425510" },
      { "name": "Dried yellow chile peppers", "code": "50425511" },
      { "name": "Dried aji dulces peppers", "code": "50425512" },
      { "name": "Dried anaheim peppers", "code": "50425513" },
      { "name": "Dried ancho peppers", "code": "50425514" },
      { "name": "Dried bell peppers", "code": "50425515" },
      { "name": "Dried cascabel peppers", "code": "50425516" },
      { "name": "Dried cayenne peppers", "code": "50425517" },
      { "name": "Dried cherry hots peppers", "code": "50425518" },
      { "name": "Dried chiltecpin peppers", "code": "50425519" },
      { "name": "Dried finger hot peppers", "code": "50425520" },
      { "name": "Dried guajillo peppers", "code": "50425521" },
      { "name": "Dried guerro peppers", "code": "50425522" },
      { "name": "Dried habanero peppers", "code": "50425523" },
      { "name": "Dried hungarian wax peppers", "code": "50425524" },
      { "name": "Dried jalapeno peppers", "code": "50425525" },
      { "name": "Dried long hot peppers", "code": "50425526" },
      { "name": "Dried mirasol peppers", "code": "50425527" },
      { "name": "Dried pasilla peppers", "code": "50425528" },
      { "name": "Dried peperoncini peppers", "code": "50425529" },
      { "name": "Dried pequin peppers", "code": "50425530" },
      { "name": "Dried pimiento peppers", "code": "50425531" },
      { "name": "Dried poblano peppers", "code": "50425532" },
      { "name": "Dried scotch bonnet peppers", "code": "50425533" },
      { "name": "Dried serrano peppers", "code": "50425534" },
      { "name": "Dried tabasco peppers", "code": "50425535" },
      { "name": "Dried tai peppers", "code": "50425536" },
      { "name": "Dried tepin peppers", "code": "50425537" },
      { "name": "Dried panca chili pepper", "code": "50425538" },
      { "name": "Dried potatoes", "code": "50425600" },
      { "name": "Dried long white potatoes", "code": "50425601" },
      { "name": "Dried round white potatoes", "code": "50425602" },
      { "name": "Dried round red potatoes", "code": "50425603" },
      { "name": "Dried russet potatoes", "code": "50425604" },
      { "name": "Dried purple potatoes", "code": "50425605" },
      { "name": "Dried yellow potatoes", "code": "50425606" },
      { "name": "Dried new potatoes", "code": "50425607" },
      { "name": "Dried specialty potatoes", "code": "50425608" },
      { "name": "Tocosh", "code": "50425609" },
      { "name": "Dried rutabagas", "code": "50425700" },
      { "name": "Dried acme rutabagas", "code": "50425701" },
      { "name": "Dried angela rutabagas", "code": "50425702" },
      { "name": "Dried best of all rutabagas", "code": "50425703" },
      { "name": "Dried marian rutabagas", "code": "50425704" },
      { "name": "Dried sea vegetables", "code": "50425800" },
      { "name": "Dried agar-agar", "code": "50425801" },
      { "name": "Dried arame", "code": "50425802" },
      { "name": "Dried dulse", "code": "50425803" },
      { "name": "Dried haricot vert de mer", "code": "50425804" },
      { "name": "Dried hijiki", "code": "50425805" },
      { "name": "Dried irish moss", "code": "50425806" },
      { "name": "Dried kelp", "code": "50425807" },
      { "name": "Dried laver", "code": "50425808" },
      { "name": "Dried nori", "code": "50425809" },
      { "name": "Dried red algae", "code": "50425810" },
      { "name": "Dried sea kale", "code": "50425811" },
      { "name": "Dried sea lettuce", "code": "50425812" },
      { "name": "Dried seaweeds", "code": "50425813" },
      { "name": "Dried spirulina", "code": "50425814" },
      { "name": "Dried susabi nori", "code": "50425815" },
      { "name": "Dried wakame", "code": "50425816" },
      { "name": "Dried shallots", "code": "50425900" },
      { "name": "Dried atlantic shallots", "code": "50425901" },
      { "name": "Dried creation shallots", "code": "50425902" },
      { "name": "Dried drittler white nest shallots", "code": "50425903" },
      { "name": "Dried giant yellow improved shallots", "code": "50425904" },
      { "name": "Dried golden gourmet shallots", "code": "50425905" },
      { "name": "Dried grise de bagnolet shallots", "code": "50425906" },
      { "name": "Dried hative de niort shallots", "code": "50425907" },
      { "name": "Dried pikant shallots", "code": "50425908" },
      { "name": "Dried red potato onions", "code": "50425909" },
      { "name": "Dried sante shallots", "code": "50425910" },
      { "name": "Dried topper shallots", "code": "50425911" },
      { "name": "Dried sorrels", "code": "50426000" },
      { "name": "Dried dock sorrel", "code": "50426001" },
      { "name": "Dried garden sorrel", "code": "50426002" },
      { "name": "Dried sheep sorrel", "code": "50426003" },
      { "name": "Dried wood sorrel", "code": "50426004" },
      { "name": "Dried spinaches", "code": "50426100" },
      { "name": "Dried america spinach", "code": "50426101" },
      { "name": "Dried bloomsdale spinach", "code": "50426102" },
      { "name": "Dried giant winter spinach", "code": "50426103" },
      { "name": "Dried horenso spinach", "code": "50426104" },
      { "name": "Dried iceplant spinach", "code": "50426105" },
      { "name": "Dried lamb's quarters spinach", "code": "50426106" },
      { "name": "Dried malabar spinach", "code": "50426107" },
      { "name": "Dried medania spinach", "code": "50426108" },
      { "name": "Dried new zealand spinach", "code": "50426109" },
      { "name": "Dried orach spinach", "code": "50426110" },
      { "name": "Dried savoy spinach", "code": "50426111" },
      { "name": "Dried sigmaleaf spinach", "code": "50426112" },
      { "name": "Dried space spinach", "code": "50426113" },
      { "name": "Dried trinidad spinach", "code": "50426114" },
      { "name": "Dried water spinach", "code": "50426115" },
      { "name": "Dried wild spinach", "code": "50426116" },
      {
        "name": "Dried summer squashes and summer pumpkins",
        "code": "50426200"
      },
      { "name": "Dried boston marrow squash", "code": "50426201" },
      { "name": "Dried butternut squash", "code": "50426202" },
      { "name": "Dried costata romanesca squash", "code": "50426203" },
      { "name": "Dried crookneck squash", "code": "50426204" },
      { "name": "Dried cucuzza squash", "code": "50426205" },
      { "name": "Dried delicata squash", "code": "50426206" },
      { "name": "Dried delicious squash", "code": "50426207" },
      {
        "name": "Dried early golden summer crookneck squash",
        "code": "50426208"
      },
      {
        "name": "Dried early prolific straight neck squash",
        "code": "50426209"
      },
      { "name": "Dried gold squash", "code": "50426210" },
      { "name": "Dried jack be little squash", "code": "50426211" },
      { "name": "Dried kentucky field squash", "code": "50426212" },
      { "name": "Dried marrow squash", "code": "50426213" },
      { "name": "Dried middle eastern squash", "code": "50426214" },
      { "name": "Dried miniature squash", "code": "50426215" },
      { "name": "Dried orangetti squash", "code": "50426216" },
      { "name": "Dried pattypan squash", "code": "50426217" },
      { "name": "Dried rondini squash", "code": "50426218" },
      { "name": "Dried round squash", "code": "50426219" },
      { "name": "Dried spaghetti squash", "code": "50426220" },
      { "name": "Dried stripetti squash", "code": "50426221" },
      { "name": "Dried sugar loaf squash", "code": "50426222" },
      { "name": "Dried sweet dumpling squash", "code": "50426223" },
      { "name": "Dried triple treat squash", "code": "50426224" },
      { "name": "Dried waltham butternut squash", "code": "50426225" },
      { "name": "Dried yellow bush scallop squash", "code": "50426226" },
      { "name": "Dried yellow straightneck squash", "code": "50426227" },
      { "name": "Dried zephyr squash", "code": "50426228" },
      { "name": "Dried zucchini squash", "code": "50426229" },
      { "name": "Dried sweet potatoes", "code": "50426300" },
      { "name": "Dried beauregard sweet potatoes", "code": "50426301" },
      { "name": "Dried centennial sweet potatoes", "code": "50426302" },
      { "name": "Dried diane sweet potatoes", "code": "50426303" },
      { "name": "Dried garnet sweet potatoes", "code": "50426304" },
      { "name": "Dried georgia red sweet potatoes", "code": "50426305" },
      { "name": "Dried goldensweet sweet potatoes", "code": "50426306" },
      { "name": "Dried hanna sweet potatoes", "code": "50426307" },
      { "name": "Dried japanese sweet potatoes", "code": "50426308" },
      { "name": "Dried jersey sweet potatoes", "code": "50426309" },
      { "name": "Dried jewel sweet potatoes", "code": "50426310" },
      { "name": "Dried maryland red sweet potatoes", "code": "50426311" },
      { "name": "Dried nemagold sweet potatoes", "code": "50426312" },
      { "name": "Dried o'henry sweet potatoes", "code": "50426313" },
      { "name": "Dried okinawan sweet potatoes", "code": "50426314" },
      { "name": "Dried orange sweet potatoes", "code": "50426315" },
      { "name": "Dried oriental sweet potatoes", "code": "50426316" },
      { "name": "Dried red jersey sweet potatoes", "code": "50426317" },
      { "name": "Dried red mar sweet potatoes", "code": "50426318" },
      { "name": "Dried redglow sweet potatoes", "code": "50426319" },
      { "name": "Dried yellow jersey sweet potatoes", "code": "50426320" },
      { "name": "Dried tomatoes", "code": "50426400" },
      { "name": "Dried ailsa craig tomatoes", "code": "50426401" },
      { "name": "Dried alicante tomatoes", "code": "50426402" },
      { "name": "Dried black plum tomatoes", "code": "50426403" },
      { "name": "Dried brandywine tomatoes", "code": "50426404" },
      { "name": "Dried cherry belle tomatoes", "code": "50426405" },
      { "name": "Dried cherry tomatoes", "code": "50426406" },
      { "name": "Dried delicious tomatoes", "code": "50426407" },
      { "name": "Dried dombito tomatoes", "code": "50426408" },
      { "name": "Dried gardener's delight tomatoes", "code": "50426409" },
      { "name": "Dried grape tomatoes", "code": "50426410" },
      { "name": "Dried green tomatoes", "code": "50426411" },
      { "name": "Dried marmande super tomatoes", "code": "50426412" },
      {
        "name": "Dried marvel striped traditional tomatoes",
        "code": "50426413"
      },
      { "name": "Dried minibel tomatoes", "code": "50426414" },
      { "name": "Dried oaxacan pink tomatoes", "code": "50426415" },
      { "name": "Dried red alert tomatoes", "code": "50426416" },
      { "name": "Dried roma vf tomatoes", "code": "50426417" },
      { "name": "Dried san marzano tomatoes", "code": "50426418" },
      { "name": "Dried shirley tomatoes", "code": "50426419" },
      { "name": "Dried siberia tomato tomatoes", "code": "50426420" },
      { "name": "Dried super beefsteak tomatoes", "code": "50426421" },
      { "name": "Dried tigerella tomatoes", "code": "50426422" },
      { "name": "Dried tiny tim tomatoes", "code": "50426423" },
      { "name": "Dried tumbler tomatoes", "code": "50426424" },
      { "name": "Dried yellow cocktail tomatoes", "code": "50426425" },
      { "name": "Dried yellow pear-shaped tomatoes", "code": "50426426" },
      { "name": "Dried yellow perfection tomatoes", "code": "50426427" },
      { "name": "Dried turnip greens", "code": "50426500" },
      { "name": "Dried green globe turnips", "code": "50426501" },
      { "name": "Dried golden ball turnips", "code": "50426502" },
      { "name": "Dried manchester market turnips", "code": "50426503" },
      { "name": "Dried purple top milan turnips", "code": "50426504" },
      { "name": "Dried purple top white turnips", "code": "50426505" },
      { "name": "Dried snowball turnips", "code": "50426506" },
      { "name": "Dried tokyo turnip", "code": "50426507" },
      { "name": "Dried tokyo cross turnips", "code": "50426508" },
      {
        "name": "Dried winter squashes and winter pumpkins",
        "code": "50426600"
      },
      { "name": "Dried acorn squash", "code": "50426601" },
      { "name": "Dried atlantic giant squash", "code": "50426602" },
      { "name": "Dried banana pink squash", "code": "50426603" },
      { "name": "Dried big max squash", "code": "50426604" },
      { "name": "Dried calabaza squash", "code": "50426605" },
      { "name": "Dried carnival squash", "code": "50426606" },
      { "name": "Dried cheese pumpkin", "code": "50426607" },
      { "name": "Dried crown prince squash", "code": "50426608" },
      { "name": "Dried curcibita squash", "code": "50426609" },
      { "name": "Dried cushaw squash", "code": "50426610" },
      { "name": "Dried giant pumpkin squash", "code": "50426611" },
      { "name": "Dried hubbard squash", "code": "50426612" },
      { "name": "Dried jarrahdale squash", "code": "50426613" },
      { "name": "Dried kabocha squash", "code": "50426614" },
      { "name": "Dried queensland blue squash", "code": "50426615" },
      { "name": "Dried rouge vif d'etampes squash", "code": "50426616" },
      { "name": "Dried turks turban squash", "code": "50426617" },
      { "name": "Dried valenciano squash", "code": "50426618" },
      { "name": "Dried warted hubbard squash", "code": "50426619" },
      { "name": "Dried whangaparoa crown pumpkin", "code": "50426620" },
      { "name": "Dried yams", "code": "50426700" },
      { "name": "Dried african bitter yams", "code": "50426701" },
      { "name": "Dried asiatic bitter yams", "code": "50426702" },
      { "name": "Dried chinese yams", "code": "50426703" },
      { "name": "Dried globe yams", "code": "50426704" },
      { "name": "Dried greater yams", "code": "50426705" },
      { "name": "Dried japanese yams", "code": "50426706" },
      { "name": "Dried lesser yams", "code": "50426707" },
      { "name": "Dried potato yams", "code": "50426708" },
      { "name": "Dried white guinea yams", "code": "50426709" },
      { "name": "Dried yellow guinea yams", "code": "50426710" },
      { "name": "Dried nominant vegetables", "code": "50426800" },
      { "name": "Dried alfalfa", "code": "50426801" },
      { "name": "Dried aloe leaves", "code": "50426802" },
      { "name": "Dried apio", "code": "50426803" },
      { "name": "Dried arrow root", "code": "50426804" },
      { "name": "Dried arrowhead", "code": "50426805" },
      { "name": "Dried arugula", "code": "50426806" },
      { "name": "Dried arum", "code": "50426807" },
      { "name": "Dried bamboo shoots", "code": "50426808" },
      { "name": "Dried banana leaves", "code": "50426809" },
      { "name": "Dried batatas", "code": "50426810" },
      { "name": "Dried bean sprouts", "code": "50426811" },
      { "name": "Dried beet tops", "code": "50426812" },
      { "name": "Dried bittermelon", "code": "50426813" },
      { "name": "Dried caperberries", "code": "50426814" },
      { "name": "Dried carob", "code": "50426815" },
      { "name": "Dried cha-om", "code": "50426816" },
      { "name": "Dried chaoyotes", "code": "50426817" },
      { "name": "Dried chickpeas", "code": "50426818" },
      { "name": "Dried chrysanthemum greens", "code": "50426819" },
      { "name": "Dried dandelion greens", "code": "50426820" },
      { "name": "Dried dandelions", "code": "50426821" },
      { "name": "Dried dasheen", "code": "50426822" },
      { "name": "Dried dau mue or pea tips", "code": "50426823" },
      { "name": "Dried diakon", "code": "50426824" },
      { "name": "Dried donqua", "code": "50426825" },
      { "name": "Dried fiddlehead ferns", "code": "50426826" },
      { "name": "Dried gai choy", "code": "50426827" },
      { "name": "Dried gailon", "code": "50426828" },
      { "name": "Dried galanga", "code": "50426829" },
      { "name": "Dried ginger root", "code": "50426830" },
      { "name": "Dried gobo", "code": "50426831" },
      { "name": "Dried hop sprouts", "code": "50426832" },
      { "name": "Dried horseradish", "code": "50426833" },
      { "name": "Dried jicama", "code": "50426834" },
      { "name": "Dried kudzu", "code": "50426835" },
      { "name": "Dried lily bulb", "code": "50426836" },
      { "name": "Dried linkok", "code": "50426837" },
      { "name": "Dried lo bok", "code": "50426838" },
      { "name": "Dried long beans", "code": "50426839" },
      { "name": "Dried lotus root", "code": "50426840" },
      { "name": "Dried maguey leaves", "code": "50426841" },
      { "name": "Dried mallows", "code": "50426842" },
      { "name": "Dried mamey sapote", "code": "50426843" },
      { "name": "Dried moap", "code": "50426844" },
      { "name": "Dried moo", "code": "50426845" },
      { "name": "Dried moqua", "code": "50426846" },
      { "name": "Dried opos", "code": "50426847" },
      { "name": "Dried palm hearts", "code": "50426848" },
      { "name": "Dried paprika", "code": "50426849" },
      { "name": "Dried purslane", "code": "50426850" },
      { "name": "Dried raddichios", "code": "50426851" },
      { "name": "Dried sinquas", "code": "50426852" },
      { "name": "Dried soybeans", "code": "50426853" },
      { "name": "Dried spoonwart", "code": "50426854" },
      { "name": "Dried tassle grape-hyacinth", "code": "50426855" },
      { "name": "Dried taro", "code": "50426856" },
      { "name": "Dried taro leaf", "code": "50426857" },
      { "name": "Dried taro shoot", "code": "50426858" },
      { "name": "Dried tepeguaje", "code": "50426859" },
      { "name": "Dried tendergreen", "code": "50426860" },
      { "name": "Dried tindora", "code": "50426861" },
      { "name": "Dried tree onion", "code": "50426862" },
      { "name": "Dried udo", "code": "50426863" },
      { "name": "Dried water chestnuts", "code": "50426864" },
      { "name": "Dried yampi", "code": "50426865" },
      { "name": "Dried yautia", "code": "50426866" },
      { "name": "Dried yu choy", "code": "50426867" },
      { "name": "Dried yuca", "code": "50426868" },
      { "name": "Dried sugar peas", "code": "50426900" },
      { "name": "Dried bikini peas", "code": "50426901" },
      { "name": "Dried cavalier peas", "code": "50426902" },
      { "name": "Dried daisy peas", "code": "50426903" },
      { "name": "Dried darfon peas", "code": "50426904" },
      { "name": "Dried early onward peas", "code": "50426905" },
      { "name": "Dried feltham first peas", "code": "50426906" },
      { "name": "Dried hurst green shaft peas", "code": "50426907" },
      { "name": "Dried oregon sugar pod peas", "code": "50426908" },
      { "name": "Dried prince albert peas", "code": "50426909" },
      { "name": "Dried reuzensuiker peas", "code": "50426910" },
      { "name": "Dried organic vegetables", "code": "50430000" },
      { "name": "Dried organic artichokes", "code": "50431500" },
      { "name": "Dried organic brittany artichokes", "code": "50431501" },
      { "name": "Dried organic catanese artichokes", "code": "50431502" },
      { "name": "Dried organic french artichokes", "code": "50431503" },
      { "name": "Dried organic green globe artichokes", "code": "50431504" },
      {
        "name": "Dried organic gros camus de bretagne artichokes",
        "code": "50431505"
      },
      { "name": "Dried organic midi artichokes", "code": "50431506" },
      { "name": "Dried organic purple globe artichokes", "code": "50431507" },
      {
        "name": "Dried organic purple sicilian artichokes",
        "code": "50431508"
      },
      { "name": "Dried organic romanesco artichokes", "code": "50431509" },
      { "name": "Dried organic spinoso sardo artichokes", "code": "50431510" },
      { "name": "Dried organic vert de laon artichokes", "code": "50431511" },
      {
        "name": "Dried organic violetta di chioggia artichokes",
        "code": "50431512"
      },
      {
        "name": "Dried organic violetto di toscana artichokes",
        "code": "50431513"
      },
      { "name": "Dried organic asparagus", "code": "50431600" },
      {
        "name": "Dried organic connover's colossal asparagus",
        "code": "50431601"
      },
      { "name": "Dried organic franklin asparagus", "code": "50431602" },
      { "name": "Dried organic giant mammoth asparagus", "code": "50431603" },
      { "name": "Dried organic lucullus asparagus", "code": "50431604" },
      {
        "name": "Dried organic martha washington asparagus",
        "code": "50431605"
      },
      { "name": "Dried organic avocados", "code": "50431700" },
      { "name": "Dried organic ajax b-7 avocados", "code": "50431701" },
      { "name": "Dried organic arue avocados", "code": "50431702" },
      { "name": "Dried organic bacon avocados", "code": "50431703" },
      { "name": "Dried organic benik avocados", "code": "50431704" },
      { "name": "Dried organic bernecker avocados", "code": "50431705" },
      { "name": "Dried organic beta avocados", "code": "50431706" },
      { "name": "Dried organic biondo avocados", "code": "50431707" },
      { "name": "Dried organic black prince avocados", "code": "50431708" },
      { "name": "Dried organic blair avocados", "code": "50431709" },
      { "name": "Dried organic blair booth avocados", "code": "50431710" },
      { "name": "Dried organic booth 1 avocados", "code": "50431711" },
      { "name": "Dried organic booth 3 avocados", "code": "50431712" },
      { "name": "Dried organic booth 5 avocados", "code": "50431713" },
      { "name": "Dried organic booth 7 avocados", "code": "50431714" },
      { "name": "Dried organic booth 8 avocados", "code": "50431715" },
      { "name": "Dried organic brooks 1978 avocados", "code": "50431716" },
      { "name": "Dried organic brookslate avocados", "code": "50431717" },
      { "name": "Dried organic california haas avocados", "code": "50431718" },
      { "name": "Dried organic catalina avocados", "code": "50431719" },
      { "name": "Dried organic chica avocados", "code": "50431720" },
      { "name": "Dried organic choquette avocados", "code": "50431721" },
      { "name": "Dried organic christina avocados", "code": "50431722" },
      { "name": "Dried organic collinson avocados", "code": "50431723" },
      { "name": "Dried organic donnie avocados", "code": "50431724" },
      {
        "name": "Dried organic dr dupuis number 2 avocados",
        "code": "50431725"
      },
      { "name": "Dried organic dr dupuis avocados", "code": "50431726" },
      { "name": "Dried organic ettinger avocados", "code": "50431727" },
      { "name": "Dried organic fuchs avocados", "code": "50431728" },
      { "name": "Dried organic fuchs gwen avocados", "code": "50431729" },
      { "name": "Dried organic fuerte avocados", "code": "50431730" },
      { "name": "Dried organic gorham avocados", "code": "50431731" },
      { "name": "Dried organic gossman avocados", "code": "50431732" },
      {
        "name": "Dried organic guatemalan seedling avocados",
        "code": "50431733"
      },
      { "name": "Dried organic hall avocados", "code": "50431734" },
      { "name": "Dried organic hardee avocados", "code": "50431735" },
      { "name": "Dried organic haas avocados", "code": "50431736" },
      { "name": "Dried organic herman avocados", "code": "50431737" },
      { "name": "Dried organic hickson avocados", "code": "50431738" },
      { "name": "Dried organic k-5 avocados", "code": "50431739" },
      { "name": "Dried organic k-9 avocados", "code": "50431740" },
      { "name": "Dried organic lamb haas avocados", "code": "50431741" },
      { "name": "Dried organic leona avocados", "code": "50431742" },
      { "name": "Dried organic leona linda avocados", "code": "50431743" },
      { "name": "Dried organic lisa p avocados", "code": "50431744" },
      { "name": "Dried organic lisa loretta avocados", "code": "50431745" },
      { "name": "Dried organic loretta avocados", "code": "50431746" },
      { "name": "Dried organic lula avocados", "code": "50431747" },
      { "name": "Dried organic lula macarthur avocados", "code": "50431748" },
      { "name": "Dried organic marcus avocados", "code": "50431749" },
      { "name": "Dried organic melendez avocados", "code": "50431750" },
      { "name": "Dried organic meya p avocados", "code": "50431751" },
      { "name": "Dried organic miguel p avocados", "code": "50431752" },
      { "name": "Dried organic monroe avocados", "code": "50431753" },
      { "name": "Dried organic murrieta green avocados", "code": "50431754" },
      { "name": "Dried organic nabal avocados", "code": "50431755" },
      { "name": "Dried organic nadir avocados", "code": "50431756" },
      { "name": "Dried organic nesbitt avocados", "code": "50431757" },
      { "name": "Dried organic peterson avocados", "code": "50431758" },
      { "name": "Dried organic pinelli avocados", "code": "50431759" },
      { "name": "Dried organic pinkerton avocados", "code": "50431760" },
      { "name": "Dried organic pollock avocados", "code": "50431761" },
      { "name": "Dried organic puebla avocados", "code": "50431762" },
      { "name": "Dried organic reed avocados", "code": "50431763" },
      { "name": "Dried organic rue avocados", "code": "50431764" },
      { "name": "Dried organic ruehle avocados", "code": "50431765" },
      { "name": "Dried organic ryan avocados", "code": "50431766" },
      { "name": "Dried organic semil 34 avocados", "code": "50431767" },
      { "name": "Dried organic semil 43 avocados", "code": "50431768" },
      { "name": "Dried organic simmonds avocados", "code": "50431769" },
      { "name": "Dried organic simpson avocados", "code": "50431770" },
      { "name": "Dried organic taylor avocados", "code": "50431771" },
      { "name": "Dried organic tonnage avocados", "code": "50431772" },
      { "name": "Dried organic tower avocados", "code": "50431773" },
      { "name": "Dried organic tower li avocados", "code": "50431774" },
      { "name": "Dried organic trapp avocados", "code": "50431775" },
      {
        "name": "Dried organic west indian seedling avocados",
        "code": "50431776"
      },
      { "name": "Dried organic wagner avocados", "code": "50431777" },
      { "name": "Dried organic waldin avocados", "code": "50431778" },
      { "name": "Dried organic wurtz avocados", "code": "50431779" },
      { "name": "Dried organic zio p avocados", "code": "50431780" },
      { "name": "Dried organic ziu avocados", "code": "50431781" },
      { "name": "Dried organic zutano avocados", "code": "50431782" },
      { "name": "Dried organic beans", "code": "50431800" },
      { "name": "Dried organic anasazi or aztec beans", "code": "50431801" },
      { "name": "Dried organic appaloosa beans", "code": "50431802" },
      { "name": "Dried organic azuki beans", "code": "50431803" },
      { "name": "Dried organic barlotti beans", "code": "50431804" },
      { "name": "Dried organic black appaloosa beans", "code": "50431805" },
      { "name": "Dried organic black beans", "code": "50431806" },
      { "name": "Dried organic black gram beans", "code": "50431807" },
      { "name": "Dried organic black shackamaxon beans", "code": "50431808" },
      { "name": "Dried organic blackeyed beans", "code": "50431809" },
      { "name": "Dried organic bobby beans", "code": "50431810" },
      { "name": "Dried organic bolita beans", "code": "50431811" },
      { "name": "Dried organic brown lazy wife beans", "code": "50431812" },
      { "name": "Dried organic calypso beans", "code": "50431813" },
      { "name": "Dried organic cannellini beans", "code": "50431814" },
      { "name": "Dried organic castor beans", "code": "50431815" },
      { "name": "Dried organic china yellow beans", "code": "50431816" },
      { "name": "Dried organic dragon tongue beans", "code": "50431817" },
      { "name": "Dried organic european soldier beans", "code": "50431818" },
      { "name": "Dried organic fava beans", "code": "50431819" },
      { "name": "Dried organic flageolet beans", "code": "50431820" },
      {
        "name": "Dried organic french horticultural beans",
        "code": "50431821"
      },
      { "name": "Dried organic french navy beans", "code": "50431822" },
      { "name": "Dried organic giant white coco beans", "code": "50431823" },
      { "name": "Dried organic green beans", "code": "50431824" },
      { "name": "Dried organic green romano beans", "code": "50431825" },
      { "name": "Dried organic guar gum beans", "code": "50431826" },
      { "name": "Dried organic haricot beans", "code": "50431827" },
      { "name": "Dried organic hyacinth beans", "code": "50431828" },
      { "name": "Dried organic italian type beans", "code": "50431829" },
      { "name": "Dried organic jackson wonder beans", "code": "50431830" },
      { "name": "Dried organic jacob's cattle beans", "code": "50431831" },
      { "name": "Dried organic kentucky wonder beans", "code": "50431832" },
      { "name": "Dried organic kidney beans", "code": "50431833" },
      { "name": "Dried organic lima beans", "code": "50431834" },
      { "name": "Dried organic madeira/madera beans", "code": "50431835" },
      { "name": "Dried organic marrow beans", "code": "50431836" },
      { "name": "Dried organic mat beans", "code": "50431837" },
      {
        "name": "Dried organic monstoller wild goose beans",
        "code": "50431838"
      },
      { "name": "Dried organic mortgage lifter beans", "code": "50431839" },
      { "name": "Dried organic moth beans", "code": "50431840" },
      { "name": "Dried organic mung beans", "code": "50431841" },
      { "name": "Dried organic munsi wolf bean", "code": "50431842" },
      { "name": "Dried organic nuna beans", "code": "50431843" },
      { "name": "Dried organic pinto beans", "code": "50431844" },
      { "name": "Dried organic pole beans", "code": "50431845" },
      { "name": "Dried organic runner beans", "code": "50431846" },
      { "name": "Dried organic string beans", "code": "50431847" },
      { "name": "Dried organic tamarind beans", "code": "50431848" },
      { "name": "Dried organic tonka beans", "code": "50431849" },
      { "name": "Dried organic wax beans", "code": "50431850" },
      { "name": "Dried organic winged beans", "code": "50431851" },
      { "name": "Dried organic yard long beans", "code": "50431852" },
      { "name": "Dried organic beets", "code": "50431900" },
      { "name": "Dried organic action beets", "code": "50431901" },
      { "name": "Dried organic albina vereduna beets", "code": "50431902" },
      {
        "name": "Dried organic barbabietola di chioggia beets",
        "code": "50431903"
      },
      { "name": "Dried organic boltardy beets", "code": "50431904" },
      { "name": "Dried organic bonel beets", "code": "50431905" },
      { "name": "Dried organic burpees golden beets", "code": "50431906" },
      {
        "name": "Dried organic cheltenham green top beets",
        "code": "50431907"
      },
      { "name": "Dried organic cheltenham mono beets", "code": "50431908" },
      { "name": "Dried organic chioggia beets", "code": "50431909" },
      { "name": "Dried organic cylindra beets", "code": "50431910" },
      { "name": "Dried organic d'egypte beets", "code": "50431911" },
      { "name": "Dried organic detroit 2 dark red beets", "code": "50431912" },
      {
        "name": "Dried organic detroit 2 little ball beets",
        "code": "50431913"
      },
      { "name": "Dried organic egyptian flat beets", "code": "50431914" },
      {
        "name": "Dried organic egyptian turnip rooted beets",
        "code": "50431915"
      },
      { "name": "Dried organic formanova beets", "code": "50431916" },
      { "name": "Dried organic forono beets", "code": "50431917" },
      { "name": "Dried organic monaco beets", "code": "50431918" },
      { "name": "Dried organic monogram beets", "code": "50431919" },
      { "name": "Dried organic pronto beets", "code": "50431920" },
      { "name": "Dried organic regalia beets", "code": "50431921" },
      { "name": "Dried organic sugar beets", "code": "50431922" },
      { "name": "Dried organic broccoli", "code": "50432000" },
      { "name": "Dried organic broccolini", "code": "50432001" },
      { "name": "Dried organic broccoli romanesco", "code": "50432002" },
      { "name": "Dried organic broccoli raab", "code": "50432003" },
      { "name": "Dried organic chinese broccoli", "code": "50432004" },
      { "name": "Dried organic brussel sprouts", "code": "50432100" },
      { "name": "Dried organic citadel brussel sprouts", "code": "50432101" },
      { "name": "Dried organic falstaff brussel sprouts", "code": "50432102" },
      { "name": "Dried organic oliver brussel sprouts", "code": "50432103" },
      { "name": "Dried organic peer gynt brussel sprouts", "code": "50432104" },
      { "name": "Dried organic rampart brussel sprouts", "code": "50432105" },
      { "name": "Dried organic rubine brussel sprouts", "code": "50432106" },
      { "name": "Dried organic widgeon brussel sprouts", "code": "50432107" },
      { "name": "Dried organic bunching onions", "code": "50432200" },
      {
        "name": "Dried organic beltsville bunching onions",
        "code": "50432201"
      },
      { "name": "Dried organic feast bunching onions", "code": "50432202" },
      { "name": "Dried organic ishikura bunching onions", "code": "50432203" },
      {
        "name": "Dried organic kyoto market bunching onions",
        "code": "50432204"
      },
      { "name": "Dried organic red beard bunching onions", "code": "50432205" },
      { "name": "Dried organic redmate bunching onions", "code": "50432206" },
      {
        "name": "Dried organic santa claus bunching onions",
        "code": "50432207"
      },
      { "name": "Dried organic tokyo bunching onions", "code": "50432208" },
      {
        "name": "Dried organic white lisbon bunching onions",
        "code": "50432209"
      },
      {
        "name": "Dried organic winter white bunching onions",
        "code": "50432210"
      },
      {
        "name": "Dried organic winter-over bunching onions",
        "code": "50432211"
      },
      { "name": "Dried organic cabbages", "code": "50432300" },
      { "name": "Dried organic black cabbages", "code": "50432301" },
      { "name": "Dried organic savoy cabbages", "code": "50432302" },
      { "name": "Dried organic skunk cabbages", "code": "50432303" },
      { "name": "Dried organic white cabbages", "code": "50432304" },
      { "name": "Dried organic cardoons", "code": "50432400" },
      { "name": "Dried organic lunghi cardoons", "code": "50432401" },
      { "name": "Dried organic gobbi cardoons", "code": "50432402" },
      { "name": "Dried organic carrots", "code": "50432500" },
      { "name": "Dried organic amsterdam carrots", "code": "50432501" },
      { "name": "Dried organic autumn king carrots", "code": "50432502" },
      { "name": "Dried organic berlicum carrots", "code": "50432503" },
      { "name": "Dried organic chantenay carrots", "code": "50432504" },
      { "name": "Dried organic nantes carrots", "code": "50432505" },
      { "name": "Dried organic paris market carrots", "code": "50432506" },
      { "name": "Dried organic cauliflowers", "code": "50432600" },
      {
        "name": "Dried organic all the year round cauliflowers",
        "code": "50432601"
      },
      { "name": "Dried organic alverda cauliflowers", "code": "50432602" },
      {
        "name": "Dried organic autumn giant 3 cauliflowers",
        "code": "50432603"
      },
      { "name": "Dried organic dok elgon cauliflowers", "code": "50432604" },
      {
        "name": "Dried organic early snowball cauliflowers",
        "code": "50432605"
      },
      { "name": "Dried organic limelight cauliflowers", "code": "50432606" },
      { "name": "Dried organic minaret cauliflowers", "code": "50432607" },
      {
        "name": "Dried organic orange bouquet cauliflowers",
        "code": "50432608"
      },
      { "name": "Dried organic purple cape cauliflowers", "code": "50432609" },
      { "name": "Dried organic snowball cauliflowers", "code": "50432610" },
      {
        "name": "Dried organic walcheren winter 3 cauliflowers",
        "code": "50432611"
      },
      { "name": "Dried organic white rock cauliflowers", "code": "50432612" },
      { "name": "Dried organic celery", "code": "50432700" },
      { "name": "Dried organic celebrity celery", "code": "50432701" },
      { "name": "Dried organic celeriac", "code": "50432702" },
      { "name": "Dried organic chinese celery", "code": "50432703" },
      { "name": "Dried organic french dinant celery", "code": "50432704" },
      { "name": "Dried organic giant pink celery", "code": "50432705" },
      { "name": "Dried organic giant red celery", "code": "50432706" },
      { "name": "Dried organic giant white celery", "code": "50432707" },
      {
        "name": "Dried organic golden self-blanching celery",
        "code": "50432708"
      },
      { "name": "Dried organic greensleeves celery", "code": "50432709" },
      { "name": "Dried organic hopkins fenlander celery", "code": "50432710" },
      { "name": "Dried organic ivory tower celery", "code": "50432711" },
      {
        "name": "Dried organic lathom self-blanching celery",
        "code": "50432712"
      },
      { "name": "Dried organic soup celery d'amsterdam", "code": "50432713" },
      { "name": "Dried organic standard bearer celery", "code": "50432714" },
      { "name": "Dried organic tall utah triumph celery", "code": "50432715" },
      { "name": "Dried organic chards", "code": "50432800" },
      { "name": "Dried organic bright lights chard", "code": "50432801" },
      { "name": "Dried organic fordhook giant chard", "code": "50432802" },
      { "name": "Dried organic lucullus chard", "code": "50432803" },
      { "name": "Dried organic perpetual spinach chard", "code": "50432804" },
      { "name": "Dried organic rhubarb chard", "code": "50432805" },
      { "name": "Dried organic swiss chard", "code": "50432806" },
      { "name": "Dried organic vulcan chard", "code": "50432807" },
      { "name": "Dried organic white king chard", "code": "50432808" },
      { "name": "Dried organic chicories", "code": "50432900" },
      {
        "name": "Dried organic broad leaved batavian chicory",
        "code": "50432901"
      },
      {
        "name": "Dried organic en cornet de bordeaux chicory",
        "code": "50432902"
      },
      {
        "name": "Dried organic green curled ruffee chicory",
        "code": "50432903"
      },
      { "name": "Dried organic green curled chicory", "code": "50432904" },
      { "name": "Dried organic ione limnos chicory", "code": "50432905" },
      { "name": "Dried organic riccia pancalieri chicory", "code": "50432906" },
      { "name": "Dried organic salad king chicory", "code": "50432907" },
      { "name": "Dried organic sanda chicory", "code": "50432908" },
      { "name": "Dried organic scarola verde chicory", "code": "50432909" },
      {
        "name": "Dried organic tres fine maraichere chicory",
        "code": "50432910"
      },
      {
        "name": "Dried organic wallone freisee weschelkopf chicory",
        "code": "50432911"
      },
      { "name": "Dried organic chinese cabbages", "code": "50433000" },
      { "name": "Dried organic bok choy", "code": "50433001" },
      {
        "name": "Dried organic chinese flat-headed cabbage",
        "code": "50433002"
      },
      { "name": "Dried organic chinese flowering cabbage", "code": "50433003" },
      { "name": "Dried organic choy sum", "code": "50433004" },
      { "name": "Dried organic dwarf bok choy", "code": "50433005" },
      { "name": "Dried organic fengshan bok choy", "code": "50433006" },
      { "name": "Dried organic jade pagoda bok choy", "code": "50433007" },
      { "name": "Dried organic kasumi bok choy", "code": "50433008" },
      { "name": "Dried organic nerva bok choy", "code": "50433009" },
      { "name": "Dried organic rosette bok choy", "code": "50433010" },
      { "name": "Dried organic ruffles bok choy", "code": "50433011" },
      {
        "name": "Dried organic santo serrated leaved cabbage",
        "code": "50433012"
      },
      { "name": "Dried organic shanghai d bok choy", "code": "50433013" },
      { "name": "Dried organic shantung cabbage", "code": "50433014" },
      { "name": "Dried organic tip top cabbage", "code": "50433015" },
      { "name": "Dried organic yau choy sum", "code": "50433016" },
      { "name": "Dried organic chives", "code": "50433100" },
      { "name": "Dried organic chinese chives", "code": "50433101" },
      { "name": "Dried organic common chives", "code": "50433102" },
      { "name": "Dried organic corn", "code": "50433200" },
      { "name": "Dried organic aloha corn", "code": "50433201" },
      { "name": "Dried organic alpine corn", "code": "50433202" },
      { "name": "Dried organic ambrosia corn", "code": "50433203" },
      { "name": "Dried organic argent corn", "code": "50433204" },
      { "name": "Dried organic aspen corn", "code": "50433205" },
      { "name": "Dried organic avalanche corn", "code": "50433206" },
      { "name": "Dried organic biqueen corn", "code": "50433207" },
      { "name": "Dried organic bodacious corn", "code": "50433208" },
      { "name": "Dried organic butter and sugar corn", "code": "50433209" },
      { "name": "Dried organic calico belle corn", "code": "50433210" },
      { "name": "Dried organic camelot corn", "code": "50433211" },
      {
        "name": "Dried organic challenger crisp \u2018n sweet corn",
        "code": "50433212"
      },
      { "name": "Dried organic champ corn", "code": "50433213" },
      { "name": "Dried organic cotton candy corn", "code": "50433214" },
      { "name": "Dried organic d\u2019artagnan corn", "code": "50433215" },
      { "name": "Dried organic dazzle corn", "code": "50433216" },
      { "name": "Dried organic diamonds and gold corn", "code": "50433217" },
      { "name": "Dried organic divinity corn", "code": "50433218" },
      { "name": "Dried organic double delight corn", "code": "50433219" },
      { "name": "Dried organic double gem corn", "code": "50433220" },
      { "name": "Dried organic earlivee corn", "code": "50433221" },
      { "name": "Dried organic early xtra sweet corn", "code": "50433222" },
      { "name": "Dried organic excel corn", "code": "50433223" },
      { "name": "Dried organic golden cross bantam corn", "code": "50433224" },
      { "name": "Dried organic honey and cream corn", "code": "50433225" },
      { "name": "Dried organic honey \u2018n pearl corn", "code": "50433226" },
      { "name": "Dried organic how sweet it is corn", "code": "50433227" },
      { "name": "Dried organic hudson corn", "code": "50433228" },
      { "name": "Dried organic illini gold corn", "code": "50433229" },
      { "name": "Dried organic illini xtra sweet corn", "code": "50433230" },
      { "name": "Dried organic incredible corn", "code": "50433231" },
      { "name": "Dried organic iochief corn", "code": "50433232" },
      { "name": "Dried organic jubilee corn", "code": "50433233" },
      { "name": "Dried organic jubilee supersweet corn", "code": "50433234" },
      { "name": "Dried organic kandy korn corn", "code": "50433235" },
      { "name": "Dried organic kiss \u2018n tell corn", "code": "50433236" },
      { "name": "Dried organic lancelot corn", "code": "50433237" },
      { "name": "Dried organic maple sweet corn", "code": "50433238" },
      { "name": "Dried organic medley corn", "code": "50433239" },
      { "name": "Dried organic merlin corn", "code": "50433240" },
      { "name": "Dried organic miracle corn", "code": "50433241" },
      { "name": "Dried organic nk-199 corn", "code": "50433242" },
      { "name": "Dried organic peaches and cream corn", "code": "50433243" },
      { "name": "Dried organic pearl white corn", "code": "50433244" },
      { "name": "Dried organic pegasus corn", "code": "50433245" },
      { "name": "Dried organic phenomenal corn", "code": "50433246" },
      { "name": "Dried organic platinum lady corn", "code": "50433247" },
      { "name": "Dried organic precocious corn", "code": "50433248" },
      { "name": "Dried organic pristine corn", "code": "50433249" },
      { "name": "Dried organic quickie corn", "code": "50433250" },
      { "name": "Dried organic radiance corn", "code": "50433251" },
      { "name": "Dried organic seneca brave corn", "code": "50433252" },
      { "name": "Dried organic seneca dawn corn", "code": "50433253" },
      { "name": "Dried organic seneca horizon corn", "code": "50433254" },
      { "name": "Dried organic seneca starshine corn", "code": "50433255" },
      { "name": "Dried organic seneca white knight corn", "code": "50433256" },
      { "name": "Dried organic showcase corn", "code": "50433257" },
      { "name": "Dried organic silver queen corn", "code": "50433258" },
      { "name": "Dried organic snowbelle corn", "code": "50433259" },
      { "name": "Dried organic spring snow corn", "code": "50433260" },
      { "name": "Dried organic spring treat corn", "code": "50433261" },
      { "name": "Dried organic sugar and gold corn", "code": "50433262" },
      { "name": "Dried organic sugar buns corn", "code": "50433263" },
      { "name": "Dried organic sugar snow corn", "code": "50433264" },
      { "name": "Dried organic sundance corn", "code": "50433265" },
      { "name": "Dried organic telstar corn", "code": "50433266" },
      { "name": "Dried organic terminator corn", "code": "50433267" },
      { "name": "Dried organic treasure corn", "code": "50433268" },
      { "name": "Dried organic tuxedo corn", "code": "50433269" },
      { "name": "Dried organic cresses", "code": "50433300" },
      { "name": "Dried organic land cress", "code": "50433301" },
      { "name": "Dried organic nasturtium", "code": "50433302" },
      { "name": "Dried organic watercress", "code": "50433303" },
      { "name": "Dried organic wintercress", "code": "50433304" },
      { "name": "Dried organic cucumbers", "code": "50433400" },
      { "name": "Dried organic arena cucumbers", "code": "50433401" },
      { "name": "Dried organic armenian cucumbers", "code": "50433402" },
      { "name": "Dried organic athene cucumbers", "code": "50433403" },
      {
        "name": "Dried organic bianco lungo di parigi cucumbers",
        "code": "50433404"
      },
      {
        "name": "Dried organic burpless tasty green cucumbers",
        "code": "50433405"
      },
      {
        "name": "Dried organic chicago pickling cucumbers",
        "code": "50433406"
      },
      { "name": "Dried organic crystal apple cucumbers", "code": "50433407" },
      { "name": "Dried organic crystal lemon cucumbers", "code": "50433408" },
      { "name": "Dried organic danimas cucumbers", "code": "50433409" },
      { "name": "Dried organic gherkin cucumbers", "code": "50433410" },
      { "name": "Dried organic hokus cucumbers", "code": "50433411" },
      { "name": "Dried organic japanese cucumbers", "code": "50433412" },
      { "name": "Dried organic karela cucumbers", "code": "50433413" },
      { "name": "Dried organic korila cucumbers", "code": "50433414" },
      {
        "name": "Dried organic long green improved cucumbers",
        "code": "50433415"
      },
      { "name": "Dried organic marketmore cucumbers", "code": "50433416" },
      { "name": "Dried organic midget cucumbers", "code": "50433417" },
      {
        "name": "Dried organic national pickling cucumbers",
        "code": "50433418"
      },
      { "name": "Dried organic persian cucumbers", "code": "50433419" },
      { "name": "Dried organic telegraph cucumbers", "code": "50433420" },
      {
        "name": "Dried organic telegraph improved cucumbers",
        "code": "50433421"
      },
      {
        "name": "Dried organic vert de massy cornichon cucumbers",
        "code": "50433422"
      },
      { "name": "Dried organic yamato cucumbers", "code": "50433423" },
      { "name": "Dried organic eggplants", "code": "50433500" },
      { "name": "Dried organic bambino eggplants", "code": "50433501" },
      { "name": "Dried organic black beauty eggplants", "code": "50433502" },
      { "name": "Dried organic black enorma eggplants", "code": "50433503" },
      { "name": "Dried organic chinese eggplants", "code": "50433504" },
      { "name": "Dried organic easter egg eggplants", "code": "50433505" },
      { "name": "Dried organic filipino eggplants", "code": "50433506" },
      { "name": "Dried organic florida market eggplants", "code": "50433507" },
      { "name": "Dried organic indian eggplants", "code": "50433508" },
      { "name": "Dried organic italian eggplants", "code": "50433509" },
      { "name": "Dried organic japanese eggplants", "code": "50433510" },
      { "name": "Dried organic long purple eggplants", "code": "50433511" },
      { "name": "Dried organic long striped eggplants", "code": "50433512" },
      { "name": "Dried organic moneymaker eggplants", "code": "50433513" },
      { "name": "Dried organic ova eggplants", "code": "50433514" },
      { "name": "Dried organic pea eggplants", "code": "50433515" },
      { "name": "Dried organic short tom eggplants", "code": "50433516" },
      { "name": "Dried organic sicilian eggplants", "code": "50433517" },
      { "name": "Dried organic thai eggplants", "code": "50433518" },
      {
        "name": "Dried organic violette di firenze eggplants",
        "code": "50433519"
      },
      { "name": "Dried organic white eggplants", "code": "50433520" },
      { "name": "Dried organic endives", "code": "50433600" },
      { "name": "Dried organic brussels witloof endives", "code": "50433601" },
      { "name": "Dried organic castelfranco endives", "code": "50433602" },
      {
        "name": "Dried organic catalogna di galatina endives",
        "code": "50433603"
      },
      { "name": "Dried organic chioggia endives", "code": "50433604" },
      { "name": "Dried organic grumolo verde endives", "code": "50433605" },
      {
        "name": "Dried organic large rooted magdeburg endives",
        "code": "50433606"
      },
      {
        "name": "Dried organic palla rossa zorzi precoce endives",
        "code": "50433607"
      },
      { "name": "Dried organic radice amare endives", "code": "50433608" },
      { "name": "Dried organic rossa di treviso endives", "code": "50433609" },
      { "name": "Dried organic rossa di verona endives", "code": "50433610" },
      { "name": "Dried organic soncino endives", "code": "50433611" },
      { "name": "Dried organic sugarhat endives", "code": "50433612" },
      { "name": "Dried organic verona endives", "code": "50433613" },
      { "name": "Dried organic witloof zoom endives", "code": "50433614" },
      { "name": "Dried organic fennels", "code": "50433700" },
      { "name": "Dried organic cantino fennel", "code": "50433701" },
      { "name": "Dried organic fino fennel", "code": "50433702" },
      { "name": "Dried organic herald fennel", "code": "50433703" },
      { "name": "Dried organic perfection fennel", "code": "50433704" },
      { "name": "Dried organic sirio fennel", "code": "50433705" },
      { "name": "Dried organic sweet florence fennel", "code": "50433706" },
      { "name": "Dried organic tardo fennel", "code": "50433707" },
      { "name": "Dried organic garlics", "code": "50433800" },
      { "name": "Dried organic california late garlic", "code": "50433801" },
      { "name": "Dried organic chinese garlic stems", "code": "50433802" },
      { "name": "Dried organic garlic chives", "code": "50433803" },
      { "name": "Dried organic germidor garlic", "code": "50433804" },
      { "name": "Dried organic long keeper garlic", "code": "50433805" },
      { "name": "Dried organic ramson garlic", "code": "50433806" },
      { "name": "Dried organic rocambole garlic", "code": "50433807" },
      { "name": "Dried organic rose de lautrec garlic", "code": "50433808" },
      { "name": "Dried organic solent wight garlic", "code": "50433809" },
      { "name": "Dried organic spanish morado garlic", "code": "50433810" },
      { "name": "Dried organic venetian/italian garlic", "code": "50433811" },
      { "name": "Dried organic gourds", "code": "50433900" },
      { "name": "Dried organic angled loofah", "code": "50433901" },
      { "name": "Dried organic bitter gourd", "code": "50433902" },
      { "name": "Dried organic bottle gourd", "code": "50433903" },
      { "name": "Dried organic calabash gourds", "code": "50433904" },
      { "name": "Dried organic fuzzy/hairy melon", "code": "50433905" },
      { "name": "Dried organic musky gourd", "code": "50433906" },
      { "name": "Dried organic smooth loofah", "code": "50433907" },
      { "name": "Dried organic snake gourd", "code": "50433908" },
      { "name": "Dried organic spiny bitter gourd", "code": "50433909" },
      { "name": "Dried organic tinda gourds", "code": "50433910" },
      { "name": "Dried organic tindoori gourds", "code": "50433911" },
      { "name": "Dried organic green peas", "code": "50434000" },
      { "name": "Dried organic china peas", "code": "50434001" },
      { "name": "Dried organic english peas", "code": "50434002" },
      { "name": "Dried organic garden peas", "code": "50434003" },
      { "name": "Dried organic snow peas", "code": "50434004" },
      { "name": "Dried organic sugar snap peas", "code": "50434005" },
      { "name": "Dried organic herbs", "code": "50434100" },
      { "name": "Dried organic basil", "code": "50434101" },
      { "name": "Dried organic bay leaves", "code": "50434102" },
      { "name": "Dried organic borage", "code": "50434103" },
      { "name": "Dried organic caraway", "code": "50434104" },
      { "name": "Dried organic chervil", "code": "50434105" },
      { "name": "Dried organic cilantro", "code": "50434106" },
      { "name": "Dried organic cipolinos", "code": "50434107" },
      { "name": "Dried organic curry leaves", "code": "50434108" },
      { "name": "Dried organic dill", "code": "50434109" },
      { "name": "Dried organic epazote", "code": "50434110" },
      { "name": "Dried organic fenugreek", "code": "50434111" },
      { "name": "Dried organic lemon grass", "code": "50434112" },
      { "name": "Dried organic marjoram", "code": "50434113" },
      { "name": "Dried organic mint", "code": "50434114" },
      { "name": "Dried organic oregano", "code": "50434115" },
      { "name": "Dried organic papalo", "code": "50434116" },
      { "name": "Dried organic pepicha", "code": "50434117" },
      { "name": "Dried organic perilla", "code": "50434118" },
      { "name": "Dried organic recao", "code": "50434119" },
      { "name": "Dried organic rosemary", "code": "50434120" },
      { "name": "Dried organic sage", "code": "50434121" },
      { "name": "Dried organic salsify", "code": "50434122" },
      { "name": "Dried organic savory", "code": "50434123" },
      { "name": "Dried organic tarragon", "code": "50434124" },
      { "name": "Dried organic thyme", "code": "50434125" },
      { "name": "Dried organic tumeric", "code": "50434126" },
      { "name": "Dried organic verdulaga", "code": "50434127" },
      { "name": "Dried organic kale", "code": "50434200" },
      { "name": "Dried organic curly kale", "code": "50434201" },
      { "name": "Dried organic collard greens", "code": "50434202" },
      { "name": "Dried organic kohlrabi", "code": "50434300" },
      { "name": "Dried organic azur star kohlrabi", "code": "50434301" },
      { "name": "Dried organic green vienna kohlrabi", "code": "50434302" },
      { "name": "Dried organic lanro kohlrabi", "code": "50434303" },
      { "name": "Dried organic purple vienna kohlrabi", "code": "50434304" },
      { "name": "Dried organic rowel trero kohlrabi", "code": "50434305" },
      { "name": "Dried organic white vienna kohlrabi", "code": "50434306" },
      { "name": "Dried organic leeks", "code": "50434400" },
      { "name": "Dried organic autumn giant-cobra leeks", "code": "50434401" },
      { "name": "Dried organic autumn mammoth 2 leeks", "code": "50434402" },
      { "name": "Dried organic bleu de solaise leeks", "code": "50434403" },
      { "name": "Dried organic cortina leeks", "code": "50434404" },
      { "name": "Dried organic prelina leeks", "code": "50434405" },
      { "name": "Dried organic wild leek ramp", "code": "50434406" },
      { "name": "Dried organic lentils", "code": "50434500" },
      { "name": "Dried organic beluga lentils", "code": "50434501" },
      { "name": "Dried organic french green lentils", "code": "50434502" },
      { "name": "Dried organic green lentils", "code": "50434503" },
      { "name": "Dried organic petite crimson lentils", "code": "50434504" },
      { "name": "Dried organic spanish pardina lentils", "code": "50434505" },
      { "name": "Dried organic split red lentils", "code": "50434506" },
      { "name": "Dried organic split yellow lentils", "code": "50434507" },
      { "name": "Dried organic tarahumara pinks lentils", "code": "50434508" },
      { "name": "Dried organic lettuces", "code": "50434600" },
      { "name": "Dried organic bibb lettuce", "code": "50434601" },
      { "name": "Dried organic boston lettuce", "code": "50434602" },
      { "name": "Dried organic frisee lettuce", "code": "50434603" },
      { "name": "Dried organic lolla rossa lettuce", "code": "50434604" },
      { "name": "Dried organic mesculin mix lettuce", "code": "50434605" },
      { "name": "Dried organic mizuna lettuce", "code": "50434606" },
      { "name": "Dried organic red leaf lettuce", "code": "50434607" },
      { "name": "Dried organic red oak leaf lettuce", "code": "50434608" },
      { "name": "Dried organic ruby romaine lettuce", "code": "50434609" },
      { "name": "Dried organic baby red romaine lettuce", "code": "50434610" },
      { "name": "Dried organic butterhead lettuces", "code": "50434611" },
      { "name": "Dried organic chinese lettuces", "code": "50434612" },
      { "name": "Dried organic crisphead lettuces", "code": "50434613" },
      { "name": "Dried organic green leaf lettuce", "code": "50434614" },
      { "name": "Dried organic iceberg lettuce", "code": "50434615" },
      { "name": "Dried organic lamb\u2019s lettuces", "code": "50434616" },
      { "name": "Dried organic looseleaf lettuces", "code": "50434617" },
      { "name": "Dried organic mache lettuce", "code": "50434618" },
      { "name": "Dried organic red boston lettuce", "code": "50434619" },
      { "name": "Dried organic red headed lettuces", "code": "50434620" },
      { "name": "Dried organic romaine lettuces", "code": "50434621" },
      {
        "name": "Dried organic russian red mustard lettuce",
        "code": "50434622"
      },
      { "name": "Dried organic tatsoi lettuce", "code": "50434623" },
      { "name": "Dried organic malanga", "code": "50434700" },
      { "name": "Dried organic amarilla malanga", "code": "50434701" },
      { "name": "Dried organic blanca malanga", "code": "50434702" },
      { "name": "Dried organic coco malanga", "code": "50434703" },
      { "name": "Dried organic eddoes malanga", "code": "50434704" },
      { "name": "Dried organic islena malanga", "code": "50434705" },
      { "name": "Dried organic lila malanga", "code": "50434706" },
      { "name": "Dried organic mushrooms", "code": "50434800" },
      { "name": "Dried organic black trumpet mushrooms", "code": "50434801" },
      { "name": "Dried organic brown mushrooms", "code": "50434802" },
      { "name": "Dried organic champinion mushrooms", "code": "50434803" },
      { "name": "Dried organic chanterelle mushrooms", "code": "50434804" },
      { "name": "Dried organic cremini mushrooms", "code": "50434805" },
      { "name": "Dried organic enoki mushrooms", "code": "50434806" },
      { "name": "Dried organic hedge hog mushrooms", "code": "50434807" },
      {
        "name": "Dried organic hen of the woods mushrooms",
        "code": "50434808"
      },
      { "name": "Dried organic lobster mushrooms", "code": "50434809" },
      { "name": "Dried organic morels mushrooms", "code": "50434810" },
      { "name": "Dried organic oyster mushrooms", "code": "50434811" },
      { "name": "Dried organic pleurotus mushrooms", "code": "50434812" },
      { "name": "Dried organic pompom mushrooms", "code": "50434813" },
      { "name": "Dried organic porcieni mushrooms", "code": "50434814" },
      { "name": "Dried organic portobella mushrooms", "code": "50434815" },
      { "name": "Dried organic shiitake mushrooms", "code": "50434816" },
      { "name": "Dried organic shimeji mushrooms", "code": "50434817" },
      { "name": "Dried organic st george's mushrooms", "code": "50434818" },
      { "name": "Dried organic white mushrooms", "code": "50434819" },
      { "name": "Dried organic white trumpet mushrooms", "code": "50434820" },
      { "name": "Dried organic woodear mushrooms", "code": "50434821" },
      { "name": "Dried organic mustards", "code": "50434900" },
      { "name": "Dried organic bamboo mustard", "code": "50434901" },
      { "name": "Dried organic garlic mustard", "code": "50434902" },
      { "name": "Dried organic giantleafed mustard", "code": "50434903" },
      { "name": "Dried organic red in snow mustard", "code": "50434904" },
      { "name": "Dried organic southern mustard", "code": "50434905" },
      { "name": "Dried organic wrapped heart mustard", "code": "50434906" },
      { "name": "Dried organic nightshades", "code": "50435000" },
      { "name": "Dried organic chinese lantern", "code": "50435001" },
      { "name": "Dried organic garden huckleberry", "code": "50435002" },
      { "name": "Dried organic naranjilla", "code": "50435003" },
      { "name": "Dried organic tomatillo", "code": "50435004" },
      { "name": "Dried organic okras", "code": "50435100" },
      { "name": "Dried organic artist okra", "code": "50435101" },
      { "name": "Dried organic burgundy okra", "code": "50435102" },
      { "name": "Dried organic clemson spineless okra", "code": "50435103" },
      { "name": "Dried organic dwarf green long pod okra", "code": "50435104" },
      {
        "name": "Dried organic mammoth spineless long pod okra",
        "code": "50435105"
      },
      { "name": "Dried organic red velvet okra", "code": "50435106" },
      {
        "name": "Dried organic star of david heirloom okra",
        "code": "50435107"
      },
      { "name": "Dried organic onions", "code": "50435200" },
      { "name": "Dried organic albion onions", "code": "50435201" },
      { "name": "Dried organic alisa craig onions", "code": "50435202" },
      { "name": "Dried organic boiling onions", "code": "50435203" },
      { "name": "Dried organic buffalo onions", "code": "50435204" },
      { "name": "Dried organic bulb onions", "code": "50435205" },
      { "name": "Dried organic creaming onions", "code": "50435206" },
      { "name": "Dried organic express yellow o-x onions", "code": "50435207" },
      { "name": "Dried organic kelsae onions", "code": "50435208" },
      {
        "name": "Dried organic marshalls giant fen globe onions",
        "code": "50435209"
      },
      { "name": "Dried organic pearl onions", "code": "50435210" },
      { "name": "Dried organic red baron onions", "code": "50435211" },
      { "name": "Dried organic red onions", "code": "50435212" },
      { "name": "Dried organic rijnsberger onions", "code": "50435213" },
      {
        "name": "Dried organic senshyu semi-globe yellow onions",
        "code": "50435214"
      },
      { "name": "Dried organic sturon onions", "code": "50435215" },
      { "name": "Dried organic stuttgarter giant onions", "code": "50435216" },
      { "name": "Dried organic sweet onions", "code": "50435217" },
      {
        "name": "Dried organic torpedo or red italian onions",
        "code": "50435218"
      },
      { "name": "Dried organic red storage onions", "code": "50435219" },
      { "name": "Dried organic white storage onions", "code": "50435220" },
      { "name": "Dried organic yellow storage onions", "code": "50435221" },
      { "name": "Dried organic peanuts", "code": "50435300" },
      {
        "name": "Dried organic bambarra groundnut peanuts",
        "code": "50435301"
      },
      { "name": "Dried organic florunner peanuts", "code": "50435302" },
      {
        "name": "Dried organic hausa/kersting's ground nut peanuts",
        "code": "50435303"
      },
      { "name": "Dried organic spanish peanuts", "code": "50435304" },
      { "name": "Dried organic valencia peanuts", "code": "50435305" },
      { "name": "Dried organic virginia peanuts", "code": "50435306" },
      { "name": "Dried organic peas", "code": "50435400" },
      { "name": "Dried organic purple hull peas", "code": "50435401" },
      { "name": "Dried organic pinkeye peas", "code": "50435402" },
      { "name": "Dried organic crowder peas", "code": "50435403" },
      { "name": "Dried organic white acre peas", "code": "50435404" },
      { "name": "Dried organic blackeyed peas", "code": "50435405" },
      { "name": "Dried organic zipper cream peas", "code": "50435406" },
      { "name": "Dried organic peppers", "code": "50435500" },
      { "name": "Dried organic ajies peppers", "code": "50435501" },
      { "name": "Dried organic arbol peppers", "code": "50435502" },
      { "name": "Dried organic cheese peppers", "code": "50435503" },
      { "name": "Dried organic chilaca peppers", "code": "50435504" },
      { "name": "Dried organic cubanelles peppers", "code": "50435505" },
      { "name": "Dried organic fresno peppers", "code": "50435506" },
      { "name": "Dried organic kapia peppers", "code": "50435507" },
      { "name": "Dried organic korean peppers", "code": "50435508" },
      { "name": "Dried organic manzano peppers", "code": "50435509" },
      { "name": "Dried organic melrose peppers", "code": "50435510" },
      { "name": "Dried organic yellow chile peppers", "code": "50435511" },
      { "name": "Dried organic aji dulces peppers", "code": "50435512" },
      { "name": "Dried organic anaheim peppers", "code": "50435513" },
      { "name": "Dried organic ancho peppers", "code": "50435514" },
      { "name": "Dried organic bell peppers", "code": "50435515" },
      { "name": "Dried organic cascabel peppers", "code": "50435516" },
      { "name": "Dried organic cayenne peppers", "code": "50435517" },
      { "name": "Dried organic cherry hots peppers", "code": "50435518" },
      { "name": "Dried organic chiltecpin peppers", "code": "50435519" },
      { "name": "Dried organic finger hot peppers", "code": "50435520" },
      { "name": "Dried organic guajillo peppers", "code": "50435521" },
      { "name": "Dried organic guerro peppers", "code": "50435522" },
      { "name": "Dried organic habanero peppers", "code": "50435523" },
      { "name": "Dried organic hungarian wax peppers", "code": "50435524" },
      { "name": "Dried organic jalapeno peppers", "code": "50435525" },
      { "name": "Dried organic long hot peppers", "code": "50435526" },
      { "name": "Dried organic mirasol peppers", "code": "50435527" },
      { "name": "Dried organic pasilla peppers", "code": "50435528" },
      { "name": "Dried organic peperoncini peppers", "code": "50435529" },
      { "name": "Dried organic pequin peppers", "code": "50435530" },
      { "name": "Dried organic pimiento peppers", "code": "50435531" },
      { "name": "Dried organic poblano peppers", "code": "50435532" },
      { "name": "Dried organic scotch bonnet peppers", "code": "50435533" },
      { "name": "Dried organic serrano peppers", "code": "50435534" },
      { "name": "Dried organic tabasco peppers", "code": "50435535" },
      { "name": "Dried organic tai peppers", "code": "50435536" },
      { "name": "Dried organic tepin peppers", "code": "50435537" },
      { "name": "Dried organic potatoes", "code": "50435600" },
      { "name": "Dried organic long white potatoes", "code": "50435601" },
      { "name": "Dried organic round white potatoes", "code": "50435602" },
      { "name": "Dried organic round red potatoes", "code": "50435603" },
      { "name": "Dried organic russet potatoes", "code": "50435604" },
      { "name": "Dried organic purple potatoes", "code": "50435605" },
      { "name": "Dried organic yellow potatoes", "code": "50435606" },
      { "name": "Dried organic new potatoes", "code": "50435607" },
      { "name": "Dried organic specialty potatoes", "code": "50435608" },
      { "name": "Dried organic rutabagas", "code": "50435700" },
      { "name": "Dried organic acme rutabagas", "code": "50435701" },
      { "name": "Dried organic angela rutabagas", "code": "50435702" },
      { "name": "Dried organic best of all rutabagas", "code": "50435703" },
      { "name": "Dried organic marian rutabagas", "code": "50435704" },
      { "name": "Dried organic sea vegetables", "code": "50435800" },
      { "name": "Dried organic agar-agar", "code": "50435801" },
      { "name": "Dried organic arame", "code": "50435802" },
      { "name": "Dried organic dulse", "code": "50435803" },
      { "name": "Dried organic haricot vert de mer", "code": "50435804" },
      { "name": "Dried organic hijiki", "code": "50435805" },
      { "name": "Dried organic irish moss", "code": "50435806" },
      { "name": "Dried organic kelp", "code": "50435807" },
      { "name": "Dried organic laver", "code": "50435808" },
      { "name": "Dried organic nori", "code": "50435809" },
      { "name": "Dried organic red algae", "code": "50435810" },
      { "name": "Dried organic sea kale", "code": "50435811" },
      { "name": "Dried organic sea lettuce", "code": "50435812" },
      { "name": "Dried organic seaweeds", "code": "50435813" },
      { "name": "Dried organic spirulina", "code": "50435814" },
      { "name": "Dried organic susabi nori", "code": "50435815" },
      { "name": "Dried organic wakame", "code": "50435816" },
      { "name": "Dried organic shallots", "code": "50435900" },
      { "name": "Dried organic atlantic shallots", "code": "50435901" },
      { "name": "Dried organic creation shallots", "code": "50435902" },
      {
        "name": "Dried organic drittler white nest shallots",
        "code": "50435903"
      },
      {
        "name": "Dried organic giant yellow improved shallots",
        "code": "50435904"
      },
      { "name": "Dried organic golden gourmet shallots", "code": "50435905" },
      {
        "name": "Dried organic grise de bagnolet shallots",
        "code": "50435906"
      },
      { "name": "Dried organic hative de niort shallots", "code": "50435907" },
      { "name": "Dried organic pikant shallots", "code": "50435908" },
      { "name": "Dried organic red potato onions", "code": "50435909" },
      { "name": "Dried organic sante shallots", "code": "50435910" },
      { "name": "Dried organic topper shallots", "code": "50435911" },
      { "name": "Dried organic sorrels", "code": "50436000" },
      { "name": "Dried organic dock sorrel", "code": "50436001" },
      { "name": "Dried organic garden sorrel", "code": "50436002" },
      { "name": "Dried organic sheep sorrel", "code": "50436003" },
      { "name": "Dried organic wood sorrel", "code": "50436004" },
      { "name": "Dried organic spinaches", "code": "50436100" },
      { "name": "Dried organic america spinach", "code": "50436101" },
      { "name": "Dried organic bloomsdale spinach", "code": "50436102" },
      { "name": "Dried organic giant winter spinach", "code": "50436103" },
      { "name": "Dried organic horenso spinach", "code": "50436104" },
      { "name": "Dried organic iceplant spinach", "code": "50436105" },
      { "name": "Dried organic lamb's quarters spinach", "code": "50436106" },
      { "name": "Dried organic malabar spinach", "code": "50436107" },
      { "name": "Dried organic medania spinach", "code": "50436108" },
      { "name": "Dried organic new zealand spinach", "code": "50436109" },
      { "name": "Dried organic orach spinach", "code": "50436110" },
      { "name": "Dried organic savoy spinach", "code": "50436111" },
      { "name": "Dried organic sigmaleaf spinach", "code": "50436112" },
      { "name": "Dried organic space spinach", "code": "50436113" },
      { "name": "Dried organic trinidad spinach", "code": "50436114" },
      { "name": "Dried organic water spinach", "code": "50436115" },
      { "name": "Dried organic wild spinach", "code": "50436116" },
      {
        "name": "Dried organic summer squashes and summer pumpkins",
        "code": "50436200"
      },
      { "name": "Dried organic boston marrow squash", "code": "50436201" },
      { "name": "Dried organic butternut squash", "code": "50436202" },
      { "name": "Dried organic costata romanesca squash", "code": "50436203" },
      { "name": "Dried organic crookneck squash", "code": "50436204" },
      { "name": "Dried organic cucuzza squash", "code": "50436205" },
      { "name": "Dried organic delicata squash", "code": "50436206" },
      { "name": "Dried organic delicious squash", "code": "50436207" },
      {
        "name": "Dried organic early golden summer crookneck squash",
        "code": "50436208"
      },
      {
        "name": "Dried organic early prolific straight neck squash",
        "code": "50436209"
      },
      { "name": "Dried organic gold squash", "code": "50436210" },
      { "name": "Dried organic jack be little squash", "code": "50436211" },
      { "name": "Dried organic kentucky field squash", "code": "50436212" },
      { "name": "Dried organic marrow squash", "code": "50436213" },
      { "name": "Dried organic middle eastern squash", "code": "50436214" },
      { "name": "Dried organic miniature squash", "code": "50436215" },
      { "name": "Dried organic orangetti squash", "code": "50436216" },
      { "name": "Dried organic pattypan squash", "code": "50436217" },
      { "name": "Dried organic rondini squash", "code": "50436218" },
      { "name": "Dried organic round squash", "code": "50436219" },
      { "name": "Dried organic spaghetti squash", "code": "50436220" },
      { "name": "Dried organic stripetti squash", "code": "50436221" },
      { "name": "Dried organic sugar loaf squash", "code": "50436222" },
      { "name": "Dried organic sweet dumpling squash", "code": "50436223" },
      { "name": "Dried organic triple treat squash", "code": "50436224" },
      { "name": "Dried organic waltham butternut squash", "code": "50436225" },
      {
        "name": "Dried organic yellow bush scallop squash",
        "code": "50436226"
      },
      {
        "name": "Dried organic yellow straightneck squash",
        "code": "50436227"
      },
      { "name": "Dried organic zephyr squash", "code": "50436228" },
      { "name": "Dried organic zucchini squash", "code": "50436229" },
      { "name": "Dried organic sweet potatoes", "code": "50436300" },
      { "name": "Dried organic beauregard sweet potatoes", "code": "50436301" },
      { "name": "Dried organic centennial sweet potatoes", "code": "50436302" },
      { "name": "Dried organic diane sweet potatoes", "code": "50436303" },
      { "name": "Dried organic garnet sweet potatoes", "code": "50436304" },
      {
        "name": "Dried organic georgia red sweet potatoes",
        "code": "50436305"
      },
      {
        "name": "Dried organic goldensweet sweet potatoes",
        "code": "50436306"
      },
      { "name": "Dried organic hanna sweet potatoes", "code": "50436307" },
      { "name": "Dried organic japanese sweet potatoes", "code": "50436308" },
      { "name": "Dried organic jersey sweet potatoes", "code": "50436309" },
      { "name": "Dried organic jewel sweet potatoes", "code": "50436310" },
      {
        "name": "Dried organic maryland red sweet potatoes",
        "code": "50436311"
      },
      { "name": "Dried organic nemagold sweet potatoes", "code": "50436312" },
      { "name": "Dried organic o'henry sweet potatoes", "code": "50436313" },
      { "name": "Dried organic okinawan sweet potatoes", "code": "50436314" },
      { "name": "Dried organic orange sweet potatoes", "code": "50436315" },
      { "name": "Dried organic oriental sweet potatoes", "code": "50436316" },
      { "name": "Dried organic red jersey sweet potatoes", "code": "50436317" },
      { "name": "Dried organic red mar sweet potatoes", "code": "50436318" },
      { "name": "Dried organic redglow sweet potatoes", "code": "50436319" },
      {
        "name": "Dried organic yellow jersey sweet potatoes",
        "code": "50436320"
      },
      { "name": "Dried organic tomatoes", "code": "50436400" },
      { "name": "Dried organic ailsa craig tomatoes", "code": "50436401" },
      { "name": "Dried organic alicante tomatoes", "code": "50436402" },
      { "name": "Dried organic black plum tomatoes", "code": "50436403" },
      { "name": "Dried organic brandywine tomatoes", "code": "50436404" },
      { "name": "Dried organic cherry belle tomatoes", "code": "50436405" },
      { "name": "Dried organic cherry tomatoes", "code": "50436406" },
      { "name": "Dried organic delicious tomatoes", "code": "50436407" },
      { "name": "Dried organic dombito tomatoes", "code": "50436408" },
      {
        "name": "Dried organic gardener's delight tomatoes",
        "code": "50436409"
      },
      { "name": "Dried organic grape tomatoes", "code": "50436410" },
      { "name": "Dried organic green tomatoes", "code": "50436411" },
      { "name": "Dried organic marmande super tomatoes", "code": "50436412" },
      {
        "name": "Dried organic marvel striped traditional tomatoes",
        "code": "50436413"
      },
      { "name": "Dried organic minibel tomatoes", "code": "50436414" },
      { "name": "Dried organic oaxacan pink tomatoes", "code": "50436415" },
      { "name": "Dried organic red alert tomatoes", "code": "50436416" },
      { "name": "Dried organic roma vf tomatoes", "code": "50436417" },
      { "name": "Dried organic san marzano tomatoes", "code": "50436418" },
      { "name": "Dried organic shirley tomatoes", "code": "50436419" },
      { "name": "Dried organic siberia tomato tomatoes", "code": "50436420" },
      { "name": "Dried organic super beefsteak tomatoes", "code": "50436421" },
      { "name": "Dried organic tigerella tomatoes", "code": "50436422" },
      { "name": "Dried organic tiny tim tomatoes", "code": "50436423" },
      { "name": "Dried organic tumbler tomatoes", "code": "50436424" },
      { "name": "Dried organic yellow cocktail tomatoes", "code": "50436425" },
      {
        "name": "Dried organic yellow pear-shaped tomatoes",
        "code": "50436426"
      },
      {
        "name": "Dried organic yellow perfection tomatoes",
        "code": "50436427"
      },
      { "name": "Dried organic turnip greens", "code": "50436500" },
      { "name": "Dried organic green globe turnips", "code": "50436501" },
      { "name": "Dried organic golden ball turnips", "code": "50436502" },
      { "name": "Dried organic manchester market turnips", "code": "50436503" },
      { "name": "Dried organic purple top milan turnips", "code": "50436504" },
      { "name": "Dried organic purple top white turnips", "code": "50436505" },
      { "name": "Dried organic snowball turnips", "code": "50436506" },
      { "name": "Dried organic tokyo turnip", "code": "50436507" },
      { "name": "Dried organic tokyo cross turnips", "code": "50436508" },
      {
        "name": "Dried organic winter squashes and winter pumpkins",
        "code": "50436600"
      },
      { "name": "Dried organic acorn squash", "code": "50436601" },
      { "name": "Dried organic atlantic giant squash", "code": "50436602" },
      { "name": "Dried organic banana pink squash", "code": "50436603" },
      { "name": "Dried organic big max squash", "code": "50436604" },
      { "name": "Dried organic calabaza squash", "code": "50436605" },
      { "name": "Dried organic carnival squash", "code": "50436606" },
      { "name": "Dried organic cheese pumpkin", "code": "50436607" },
      { "name": "Dried organic crown prince squash", "code": "50436608" },
      { "name": "Dried organic curcibita squash", "code": "50436609" },
      { "name": "Dried organic cushaw squash", "code": "50436610" },
      { "name": "Dried organic giant pumpkin squash", "code": "50436611" },
      { "name": "Dried organic hubbard squash", "code": "50436612" },
      { "name": "Dried organic jarrahdale squash", "code": "50436613" },
      { "name": "Dried organic kabocha squash", "code": "50436614" },
      { "name": "Dried organic queensland blue squash", "code": "50436615" },
      {
        "name": "Dried organic rouge vif d'etampes squash",
        "code": "50436616"
      },
      { "name": "Dried organic turks turban squash", "code": "50436617" },
      { "name": "Dried organic valenciano squash", "code": "50436618" },
      { "name": "Dried organic warted hubbard squash", "code": "50436619" },
      { "name": "Dried organic whangaparoa crown pumpkin", "code": "50436620" },
      { "name": "Dried organic yams", "code": "50436700" },
      { "name": "Dried organic african bitter yams", "code": "50436701" },
      { "name": "Dried organic asiatic bitter yams", "code": "50436702" },
      { "name": "Dried organic chinese yams", "code": "50436703" },
      { "name": "Dried organic globe yams", "code": "50436704" },
      { "name": "Dried organic greater yams", "code": "50436705" },
      { "name": "Dried organic japanese yams", "code": "50436706" },
      { "name": "Dried organic lesser yams", "code": "50436707" },
      { "name": "Dried organic potato yams", "code": "50436708" },
      { "name": "Dried organic white guinea yams", "code": "50436709" },
      { "name": "Dried organic yellow guinea yams", "code": "50436710" },
      { "name": "Dried organic nominant vegetables", "code": "50436800" },
      { "name": "Dried organic alfalfa", "code": "50436801" },
      { "name": "Dried organic aloe leaves", "code": "50436802" },
      { "name": "Dried organic apio", "code": "50436803" },
      { "name": "Dried organic arrow root", "code": "50436804" },
      { "name": "Dried organic arrowhead", "code": "50436805" },
      { "name": "Dried organic arugula", "code": "50436806" },
      { "name": "Dried organic arum", "code": "50436807" },
      { "name": "Dried organic bamboo shoots", "code": "50436808" },
      { "name": "Dried organic banana leaves", "code": "50436809" },
      { "name": "Dried organic batatas", "code": "50436810" },
      { "name": "Dried organic bean sprouts", "code": "50436811" },
      { "name": "Dried organic beet tops", "code": "50436812" },
      { "name": "Dried organic bittermelon", "code": "50436813" },
      { "name": "Dried organic caperberries", "code": "50436814" },
      { "name": "Dried organic carob", "code": "50436815" },
      { "name": "Dried organic cha-om", "code": "50436816" },
      { "name": "Dried organic chaoyotes", "code": "50436817" },
      { "name": "Dried organic chickpeas", "code": "50436818" },
      { "name": "Dried organic chrysanthemum greens", "code": "50436819" },
      { "name": "Dried organic dandelion greens", "code": "50436820" },
      { "name": "Dried organic dandelions", "code": "50436821" },
      { "name": "Dried organic dasheen", "code": "50436822" },
      { "name": "Dried organic dau mue or pea tips", "code": "50436823" },
      { "name": "Dried organic diakon", "code": "50436824" },
      { "name": "Dried organic donqua", "code": "50436825" },
      { "name": "Dried organic fiddlehead ferns", "code": "50436826" },
      { "name": "Dried organic gai choy", "code": "50436827" },
      { "name": "Dried organic gailon", "code": "50436828" },
      { "name": "Dried organic galanga", "code": "50436829" },
      { "name": "Dried organic ginger root", "code": "50436830" },
      { "name": "Dried organic gobo", "code": "50436831" },
      { "name": "Dried organic hop sprouts", "code": "50436832" },
      { "name": "Dried organic horseradish", "code": "50436833" },
      { "name": "Dried organic jicama", "code": "50436834" },
      { "name": "Dried organic kudzu", "code": "50436835" },
      { "name": "Dried organic lily bulb", "code": "50436836" },
      { "name": "Dried organic linkok", "code": "50436837" },
      { "name": "Dried organic lo bok", "code": "50436838" },
      { "name": "Dried organic long beans", "code": "50436839" },
      { "name": "Dried organic lotus root", "code": "50436840" },
      { "name": "Dried organic maguey leaves", "code": "50436841" },
      { "name": "Dried organic mallows", "code": "50436842" },
      { "name": "Dried organic mamey sapote", "code": "50436843" },
      { "name": "Dried organic moap", "code": "50436844" },
      { "name": "Dried organic moo", "code": "50436845" },
      { "name": "Dried organic moqua", "code": "50436846" },
      { "name": "Dried organic opos", "code": "50436847" },
      { "name": "Dried organic palm hearts", "code": "50436848" },
      { "name": "Dried organic paprika", "code": "50436849" },
      { "name": "Dried organic purslane", "code": "50436850" },
      { "name": "Dried organic raddichios", "code": "50436851" },
      { "name": "Dried organic sinquas", "code": "50436852" },
      { "name": "Dried organic soybeans", "code": "50436853" },
      { "name": "Dried organic spoonwart", "code": "50436854" },
      { "name": "Dried organic tassle grape-hyacinth", "code": "50436855" },
      { "name": "Dried organic taro", "code": "50436856" },
      { "name": "Dried organic taro leaf", "code": "50436857" },
      { "name": "Dried organic taro shoot", "code": "50436858" },
      { "name": "Dried organic tepeguaje", "code": "50436859" },
      { "name": "Dried organic tendergreen", "code": "50436860" },
      { "name": "Dried organic tindora", "code": "50436861" },
      { "name": "Dried organic tree onion", "code": "50436862" },
      { "name": "Dried organic udo", "code": "50436863" },
      { "name": "Dried organic water chestnuts", "code": "50436864" },
      { "name": "Dried organic yampi", "code": "50436865" },
      { "name": "Dried organic yautia", "code": "50436866" },
      { "name": "Dried organic yu choy", "code": "50436867" },
      { "name": "Dried organic yuca", "code": "50436868" },
      { "name": "Dried organic sugar peas", "code": "50436900" },
      { "name": "Dried organic bikini peas", "code": "50436901" },
      { "name": "Dried organic cavalier peas", "code": "50436902" },
      { "name": "Dried organic daisy peas", "code": "50436903" },
      { "name": "Dried organic darfon peas", "code": "50436904" },
      { "name": "Dried organic early onward peas", "code": "50436905" },
      { "name": "Dried organic feltham first peas", "code": "50436906" },
      { "name": "Dried organic hurst green shaft peas", "code": "50436907" },
      { "name": "Dried organic oregon sugar pod peas", "code": "50436908" },
      { "name": "Dried organic prince albert peas", "code": "50436909" },
      { "name": "Dried organic reuzensuiker peas", "code": "50436910" },
      { "name": "Frozen vegetables", "code": "50440000" },
      { "name": "Frozen artichokes", "code": "50441500" },
      { "name": "Frozen brittany artichokes", "code": "50441501" },
      { "name": "Frozen catanese artichokes", "code": "50441502" },
      { "name": "Frozen french artichokes", "code": "50441503" },
      { "name": "Frozen green globe artichokes", "code": "50441504" },
      {
        "name": "Frozen gros camus de bretagne artichokes",
        "code": "50441505"
      },
      { "name": "Frozen midi artichokes", "code": "50441506" },
      { "name": "Frozen purple globe artichokes", "code": "50441507" },
      { "name": "Frozen purple sicilian artichokes", "code": "50441508" },
      { "name": "Frozen romanesco artichokes", "code": "50441509" },
      { "name": "Frozen spinoso sardo artichokes", "code": "50441510" },
      { "name": "Frozen vert de laon artichokes", "code": "50441511" },
      { "name": "Frozen violetta di chioggia artichokes", "code": "50441512" },
      { "name": "Frozen violetto di toscana artichokes", "code": "50441513" },
      { "name": "Frozen asparagus", "code": "50441600" },
      { "name": "Frozen connover's colossal asparagus", "code": "50441601" },
      { "name": "Frozen franklin asparagus", "code": "50441602" },
      { "name": "Frozen giant mammoth asparagus", "code": "50441603" },
      { "name": "Frozen lucullus asparagus", "code": "50441604" },
      { "name": "Frozen martha washington asparagus", "code": "50441605" },
      { "name": "Frozen avocados", "code": "50441700" },
      { "name": "Frozen ajax b-7 avocados", "code": "50441701" },
      { "name": "Frozen arue avocados", "code": "50441702" },
      { "name": "Frozen bacon avocados", "code": "50441703" },
      { "name": "Frozen benik avocados", "code": "50441704" },
      { "name": "Frozen bernecker avocados", "code": "50441705" },
      { "name": "Frozen beta avocados", "code": "50441706" },
      { "name": "Frozen biondo avocados", "code": "50441707" },
      { "name": "Frozen black prince avocados", "code": "50441708" },
      { "name": "Frozen blair avocados", "code": "50441709" },
      { "name": "Frozen blair booth avocados", "code": "50441710" },
      { "name": "Frozen booth 1 avocados", "code": "50441711" },
      { "name": "Frozen booth 3 avocados", "code": "50441712" },
      { "name": "Frozen booth 5 avocados", "code": "50441713" },
      { "name": "Frozen booth 7 avocados", "code": "50441714" },
      { "name": "Frozen booth 8 avocados", "code": "50441715" },
      { "name": "Frozen brooks 1978 avocados", "code": "50441716" },
      { "name": "Frozen brookslate avocados", "code": "50441717" },
      { "name": "Frozen california haas avocados", "code": "50441718" },
      { "name": "Frozen catalina avocados", "code": "50441719" },
      { "name": "Frozen chica avocados", "code": "50441720" },
      { "name": "Frozen choquette avocados", "code": "50441721" },
      { "name": "Frozen christina avocados", "code": "50441722" },
      { "name": "Frozen collinson avocados", "code": "50441723" },
      { "name": "Frozen donnie avocados", "code": "50441724" },
      { "name": "Frozen dr dupuis number 2 avocados", "code": "50441725" },
      { "name": "Frozen dr dupuis avocados", "code": "50441726" },
      { "name": "Frozen ettinger avocados", "code": "50441727" },
      { "name": "Frozen fuchs avocados", "code": "50441728" },
      { "name": "Frozen fuchs gwen avocados", "code": "50441729" },
      { "name": "Frozen fuerte avocados", "code": "50441730" },
      { "name": "Frozen gorham avocados", "code": "50441731" },
      { "name": "Frozen gossman avocados", "code": "50441732" },
      { "name": "Frozen guatemalan seedling avocados", "code": "50441733" },
      { "name": "Frozen hall avocados", "code": "50441734" },
      { "name": "Frozen hardee avocados", "code": "50441735" },
      { "name": "Frozen haas avocados", "code": "50441736" },
      { "name": "Frozen herman avocados", "code": "50441737" },
      { "name": "Frozen hickson avocados", "code": "50441738" },
      { "name": "Frozen k-5 avocados", "code": "50441739" },
      { "name": "Frozen k-9 avocados", "code": "50441740" },
      { "name": "Frozen lamb haas avocados", "code": "50441741" },
      { "name": "Frozen leona avocados", "code": "50441742" },
      { "name": "Frozen leona linda avocados", "code": "50441743" },
      { "name": "Frozen lisa p avocados", "code": "50441744" },
      { "name": "Frozen lisa loretta avocados", "code": "50441745" },
      { "name": "Frozen loretta avocados", "code": "50441746" },
      { "name": "Frozen lula avocados", "code": "50441747" },
      { "name": "Frozen lula macarthur avocados", "code": "50441748" },
      { "name": "Frozen marcus avocados", "code": "50441749" },
      { "name": "Frozen melendez avocados", "code": "50441750" },
      { "name": "Frozen meya p avocados", "code": "50441751" },
      { "name": "Frozen miguel p avocados", "code": "50441752" },
      { "name": "Frozen monroe avocados", "code": "50441753" },
      { "name": "Frozen murrieta green avocados", "code": "50441754" },
      { "name": "Frozen nabal avocados", "code": "50441755" },
      { "name": "Frozen nadir avocados", "code": "50441756" },
      { "name": "Frozen nesbitt avocados", "code": "50441757" },
      { "name": "Frozen peterson avocados", "code": "50441758" },
      { "name": "Frozen pinelli avocados", "code": "50441759" },
      { "name": "Frozen pinkerton avocados", "code": "50441760" },
      { "name": "Frozen pollock avocados", "code": "50441761" },
      { "name": "Frozen puebla avocados", "code": "50441762" },
      { "name": "Frozen reed avocados", "code": "50441763" },
      { "name": "Frozen rue avocados", "code": "50441764" },
      { "name": "Frozen ruehle avocados", "code": "50441765" },
      { "name": "Frozen ryan avocados", "code": "50441766" },
      { "name": "Frozen semil 34 avocados", "code": "50441767" },
      { "name": "Frozen semil 43 avocados", "code": "50441768" },
      { "name": "Frozen simmonds avocados", "code": "50441769" },
      { "name": "Frozen simpson avocados", "code": "50441770" },
      { "name": "Frozen taylor avocados", "code": "50441771" },
      { "name": "Frozen tonnage avocados", "code": "50441772" },
      { "name": "Frozen tower avocados", "code": "50441773" },
      { "name": "Frozen tower li avocados", "code": "50441774" },
      { "name": "Frozen trapp avocados", "code": "50441775" },
      { "name": "Frozen west indian seedling avocados", "code": "50441776" },
      { "name": "Frozen wagner avocados", "code": "50441777" },
      { "name": "Frozen waldin avocados", "code": "50441778" },
      { "name": "Frozen wurtz avocados", "code": "50441779" },
      { "name": "Frozen zio p avocados", "code": "50441780" },
      { "name": "Frozen ziu avocados", "code": "50441781" },
      { "name": "Frozen zutano avocados", "code": "50441782" },
      { "name": "Frozen beans", "code": "50441800" },
      { "name": "Frozen anasazi or aztec beans", "code": "50441801" },
      { "name": "Frozen appaloosa beans", "code": "50441802" },
      { "name": "Frozen azuki beans", "code": "50441803" },
      { "name": "Frozen barlotti beans", "code": "50441804" },
      { "name": "Frozen black appaloosa beans", "code": "50441805" },
      { "name": "Frozen black beans", "code": "50441806" },
      { "name": "Frozen black gram beans", "code": "50441807" },
      { "name": "Frozen black shackamaxon beans", "code": "50441808" },
      { "name": "Frozen blackeyed beans", "code": "50441809" },
      { "name": "Frozen bobby beans", "code": "50441810" },
      { "name": "Frozen bolita beans", "code": "50441811" },
      { "name": "Frozen brown lazy wife beans", "code": "50441812" },
      { "name": "Frozen calypso beans", "code": "50441813" },
      { "name": "Frozen cannellini beans", "code": "50441814" },
      { "name": "Frozen castor beans", "code": "50441815" },
      { "name": "Frozen china yellow beans", "code": "50441816" },
      { "name": "Frozen dragon tongue beans", "code": "50441817" },
      { "name": "Frozen european soldier beans", "code": "50441818" },
      { "name": "Frozen fava beans", "code": "50441819" },
      { "name": "Frozen flageolet beans", "code": "50441820" },
      { "name": "Frozen french horticultural beans", "code": "50441821" },
      { "name": "Frozen french navy beans", "code": "50441822" },
      { "name": "Frozen giant white coco beans", "code": "50441823" },
      { "name": "Frozen green beans", "code": "50441824" },
      { "name": "Frozen green romano beans", "code": "50441825" },
      { "name": "Frozen guar gum beans", "code": "50441826" },
      { "name": "Frozen haricot beans", "code": "50441827" },
      { "name": "Frozen hyacinth beans", "code": "50441828" },
      { "name": "Frozen italian type beans", "code": "50441829" },
      { "name": "Frozen jackson wonder beans", "code": "50441830" },
      { "name": "Frozen jacob's cattle beans", "code": "50441831" },
      { "name": "Frozen kentucky wonder beans", "code": "50441832" },
      { "name": "Frozen kidney beans", "code": "50441833" },
      { "name": "Frozen lima beans", "code": "50441834" },
      { "name": "Frozen madeira/madera beans", "code": "50441835" },
      { "name": "Frozen marrow beans", "code": "50441836" },
      { "name": "Frozen mat beans", "code": "50441837" },
      { "name": "Frozen monstoller wild goose beans", "code": "50441838" },
      { "name": "Frozen mortgage lifter beans", "code": "50441839" },
      { "name": "Frozen moth beans", "code": "50441840" },
      { "name": "Frozen mung beans", "code": "50441841" },
      { "name": "Frozen munsi wolf bean", "code": "50441842" },
      { "name": "Frozen nuna beans", "code": "50441843" },
      { "name": "Frozen pinto beans", "code": "50441844" },
      { "name": "Frozen pole beans", "code": "50441845" },
      { "name": "Frozen runner beans", "code": "50441846" },
      { "name": "Frozen string beans", "code": "50441847" },
      { "name": "Frozen tamarind beans", "code": "50441848" },
      { "name": "Frozen tonka beans", "code": "50441849" },
      { "name": "Frozen wax beans", "code": "50441850" },
      { "name": "Frozen winged beans", "code": "50441851" },
      { "name": "Frozen yard long beans", "code": "50441852" },
      { "name": "Frozen beets", "code": "50441900" },
      { "name": "Frozen action beets", "code": "50441901" },
      { "name": "Frozen albina vereduna beets", "code": "50441902" },
      { "name": "Frozen barbabietola di chioggia beets", "code": "50441903" },
      { "name": "Frozen boltardy beets", "code": "50441904" },
      { "name": "Frozen bonel beets", "code": "50441905" },
      { "name": "Frozen burpees golden beets", "code": "50441906" },
      { "name": "Frozen cheltenham green top beets", "code": "50441907" },
      { "name": "Frozen cheltenham mono beets", "code": "50441908" },
      { "name": "Frozen chioggia beets", "code": "50441909" },
      { "name": "Frozen cylindra beets", "code": "50441910" },
      { "name": "Frozen d'egypte beets", "code": "50441911" },
      { "name": "Frozen detroit 2 dark red beets", "code": "50441912" },
      { "name": "Frozen detroit 2 little ball beets", "code": "50441913" },
      { "name": "Frozen egyptian flat beets", "code": "50441914" },
      { "name": "Frozen egyptian turnip rooted beets", "code": "50441915" },
      { "name": "Frozen formanova beets", "code": "50441916" },
      { "name": "Frozen forono beets", "code": "50441917" },
      { "name": "Frozen monaco beets", "code": "50441918" },
      { "name": "Frozen monogram beets", "code": "50441919" },
      { "name": "Frozen pronto beets", "code": "50441920" },
      { "name": "Frozen regalia beets", "code": "50441921" },
      { "name": "Frozen sugar beets", "code": "50441922" },
      { "name": "Frozen broccoli", "code": "50442000" },
      { "name": "Frozen broccolini", "code": "50442001" },
      { "name": "Frozen broccoli romanesco", "code": "50442002" },
      { "name": "Frozen broccoli raab", "code": "50442003" },
      { "name": "Frozen chinese broccoli", "code": "50442004" },
      { "name": "Frozen brussel sprouts", "code": "50442100" },
      { "name": "Frozen citadel brussel sprouts", "code": "50442101" },
      { "name": "Frozen falstaff brussel sprouts", "code": "50442102" },
      { "name": "Frozen oliver brussel sprouts", "code": "50442103" },
      { "name": "Frozen peer gynt brussel sprouts", "code": "50442104" },
      { "name": "Frozen rampart brussel sprouts", "code": "50442105" },
      { "name": "Frozen rubine brussel sprouts", "code": "50442106" },
      { "name": "Frozen widgeon brussel sprouts", "code": "50442107" },
      { "name": "Frozen bunching onions", "code": "50442200" },
      { "name": "Frozen beltsville bunching onions", "code": "50442201" },
      { "name": "Frozen feast bunching onions", "code": "50442202" },
      { "name": "Frozen ishikura bunching onions", "code": "50442203" },
      { "name": "Frozen kyoto market bunching onions", "code": "50442204" },
      { "name": "Frozen red beard bunching onions", "code": "50442205" },
      { "name": "Frozen redmate bunching onions", "code": "50442206" },
      { "name": "Frozen santa claus bunching onions", "code": "50442207" },
      { "name": "Frozen tokyo bunching onions", "code": "50442208" },
      { "name": "Frozen white lisbon bunching onions", "code": "50442209" },
      { "name": "Frozen winter white bunching onions", "code": "50442210" },
      { "name": "Frozen winter-over bunching onions", "code": "50442211" },
      { "name": "Frozen cabbages", "code": "50442300" },
      { "name": "Frozen black cabbages", "code": "50442301" },
      { "name": "Frozen savoy cabbages", "code": "50442302" },
      { "name": "Frozen skunk cabbages", "code": "50442303" },
      { "name": "Frozen white cabbages", "code": "50442304" },
      { "name": "Frozen cardoons", "code": "50442400" },
      { "name": "Frozen lunghi cardoons", "code": "50442401" },
      { "name": "Frozen gobbi cardoons", "code": "50442402" },
      { "name": "Frozen carrots", "code": "50442500" },
      { "name": "Frozen amsterdam carrots", "code": "50442501" },
      { "name": "Frozen autumn king carrots", "code": "50442502" },
      { "name": "Frozen berlicum carrots", "code": "50442503" },
      { "name": "Frozen chantenay carrots", "code": "50442504" },
      { "name": "Frozen nantes carrots", "code": "50442505" },
      { "name": "Frozen paris market carrots", "code": "50442506" },
      { "name": "Frozen cauliflowers", "code": "50442600" },
      { "name": "Frozen all the year round cauliflowers", "code": "50442601" },
      { "name": "Frozen alverda cauliflowers", "code": "50442602" },
      { "name": "Frozen autumn giant 3 cauliflowers", "code": "50442603" },
      { "name": "Frozen dok elgon cauliflowers", "code": "50442604" },
      { "name": "Frozen early snowball cauliflowers", "code": "50442605" },
      { "name": "Frozen limelight cauliflowers", "code": "50442606" },
      { "name": "Frozen minaret cauliflowers", "code": "50442607" },
      { "name": "Frozen orange bouquet cauliflowers", "code": "50442608" },
      { "name": "Frozen purple cape cauliflowers", "code": "50442609" },
      { "name": "Frozen snowball cauliflowers", "code": "50442610" },
      { "name": "Frozen walcheren winter 3 cauliflowers", "code": "50442611" },
      { "name": "Frozen white rock cauliflowers", "code": "50442612" },
      { "name": "Frozen celery", "code": "50442700" },
      { "name": "Frozen celebrity celery", "code": "50442701" },
      { "name": "Frozen celeriac", "code": "50442702" },
      { "name": "Frozen chinese celery", "code": "50442703" },
      { "name": "Frozen french dinant celery", "code": "50442704" },
      { "name": "Frozen giant pink celery", "code": "50442705" },
      { "name": "Frozen giant red celery", "code": "50442706" },
      { "name": "Frozen giant white celery", "code": "50442707" },
      { "name": "Frozen golden self-blanching celery", "code": "50442708" },
      { "name": "Frozen greensleeves celery", "code": "50442709" },
      { "name": "Frozen hopkins fenlander celery", "code": "50442710" },
      { "name": "Frozen ivory tower celery", "code": "50442711" },
      { "name": "Frozen lathom self-blanching celery", "code": "50442712" },
      { "name": "Frozen soup celery d'amsterdam", "code": "50442713" },
      { "name": "Frozen standard bearer celery", "code": "50442714" },
      { "name": "Frozen tall utah triumph celery", "code": "50442715" },
      { "name": "Frozen chards", "code": "50442800" },
      { "name": "Frozen bright lights chard", "code": "50442801" },
      { "name": "Frozen fordhook giant chard", "code": "50442802" },
      { "name": "Frozen lucullus chard", "code": "50442803" },
      { "name": "Frozen perpetual spinach chard", "code": "50442804" },
      { "name": "Frozen rhubarb chard", "code": "50442805" },
      { "name": "Frozen swiss chard", "code": "50442806" },
      { "name": "Frozen vulcan chard", "code": "50442807" },
      { "name": "Frozen white king chard", "code": "50442808" },
      { "name": "Frozen chicories", "code": "50442900" },
      { "name": "Frozen broad leaved batavian chicory", "code": "50442901" },
      { "name": "Frozen en cornet de bordeaux chicory", "code": "50442902" },
      { "name": "Frozen green curled ruffee chicory", "code": "50442903" },
      { "name": "Frozen green curled chicory", "code": "50442904" },
      { "name": "Frozen ione limnos chicory", "code": "50442905" },
      { "name": "Frozen riccia pancalieri chicory", "code": "50442906" },
      { "name": "Frozen salad king chicory", "code": "50442907" },
      { "name": "Frozen sanda chicory", "code": "50442908" },
      { "name": "Frozen scarola verde chicory", "code": "50442909" },
      { "name": "Frozen tres fine maraichere chicory", "code": "50442910" },
      {
        "name": "Frozen wallone freisee weschelkopf chicory",
        "code": "50442911"
      },
      { "name": "Frozen chinese cabbages", "code": "50443000" },
      { "name": "Frozen bok choy", "code": "50443001" },
      { "name": "Frozen chinese flat-headed cabbage", "code": "50443002" },
      { "name": "Frozen chinese flowering cabbage", "code": "50443003" },
      { "name": "Frozen choy sum", "code": "50443004" },
      { "name": "Frozen dwarf bok choy", "code": "50443005" },
      { "name": "Frozen fengshan bok choy", "code": "50443006" },
      { "name": "Frozen jade pagoda bok choy", "code": "50443007" },
      { "name": "Frozen kasumi bok choy", "code": "50443008" },
      { "name": "Frozen nerva bok choy", "code": "50443009" },
      { "name": "Frozen rosette bok choy", "code": "50443010" },
      { "name": "Frozen ruffles bok choy", "code": "50443011" },
      { "name": "Frozen santo serrated leaved cabbage", "code": "50443012" },
      { "name": "Frozen shanghai d bok choy", "code": "50443013" },
      { "name": "Frozen shantung cabbage", "code": "50443014" },
      { "name": "Frozen tip top cabbage", "code": "50443015" },
      { "name": "Frozen yau choy sum", "code": "50443016" },
      { "name": "Frozen chives", "code": "50443100" },
      { "name": "Frozen chinese chives", "code": "50443101" },
      { "name": "Frozen common chives", "code": "50443102" },
      { "name": "Frozen corn", "code": "50443200" },
      { "name": "Frozen aloha corn", "code": "50443201" },
      { "name": "Frozen alpine corn", "code": "50443202" },
      { "name": "Frozen ambrosia corn", "code": "50443203" },
      { "name": "Frozen argent corn", "code": "50443204" },
      { "name": "Frozen aspen corn", "code": "50443205" },
      { "name": "Frozen avalanche corn", "code": "50443206" },
      { "name": "Frozen biqueen corn", "code": "50443207" },
      { "name": "Frozen bodacious corn", "code": "50443208" },
      { "name": "Frozen butter and sugar corn", "code": "50443209" },
      { "name": "Frozen calico belle corn", "code": "50443210" },
      { "name": "Frozen camelot corn", "code": "50443211" },
      {
        "name": "Frozen challenger crisp \u2018n sweet corn",
        "code": "50443212"
      },
      { "name": "Frozen champ corn", "code": "50443213" },
      { "name": "Frozen cotton candy corn", "code": "50443214" },
      { "name": "Frozen d\u2019artagnan corn", "code": "50443215" },
      { "name": "Frozen dazzle corn", "code": "50443216" },
      { "name": "Frozen diamonds and gold corn", "code": "50443217" },
      { "name": "Frozen divinity corn", "code": "50443218" },
      { "name": "Frozen double delight corn", "code": "50443219" },
      { "name": "Frozen double gem corn", "code": "50443220" },
      { "name": "Frozen earlivee corn", "code": "50443221" },
      { "name": "Frozen early xtra sweet corn", "code": "50443222" },
      { "name": "Frozen excel corn", "code": "50443223" },
      { "name": "Frozen golden cross bantam corn", "code": "50443224" },
      { "name": "Frozen honey and cream corn", "code": "50443225" },
      { "name": "Frozen honey \u2018n pearl corn", "code": "50443226" },
      { "name": "Frozen how sweet it is corn", "code": "50443227" },
      { "name": "Frozen hudson corn", "code": "50443228" },
      { "name": "Frozen illini gold corn", "code": "50443229" },
      { "name": "Frozen illini xtra sweet corn", "code": "50443230" },
      { "name": "Frozen incredible corn", "code": "50443231" },
      { "name": "Frozen iochief corn", "code": "50443232" },
      { "name": "Frozen jubilee corn", "code": "50443233" },
      { "name": "Frozen jubilee supersweet corn", "code": "50443234" },
      { "name": "Frozen kandy korn corn", "code": "50443235" },
      { "name": "Frozen kiss \u2018n tell corn", "code": "50443236" },
      { "name": "Frozen lancelot corn", "code": "50443237" },
      { "name": "Frozen maple sweet corn", "code": "50443238" },
      { "name": "Frozen medley corn", "code": "50443239" },
      { "name": "Frozen merlin corn", "code": "50443240" },
      { "name": "Frozen miracle corn", "code": "50443241" },
      { "name": "Frozen nk-199 corn", "code": "50443242" },
      { "name": "Frozen peaches and cream corn", "code": "50443243" },
      { "name": "Frozen pearl white corn", "code": "50443244" },
      { "name": "Frozen pegasus corn", "code": "50443245" },
      { "name": "Frozen phenomenal corn", "code": "50443246" },
      { "name": "Frozen platinum lady corn", "code": "50443247" },
      { "name": "Frozen precocious corn", "code": "50443248" },
      { "name": "Frozen pristine corn", "code": "50443249" },
      { "name": "Frozen quickie corn", "code": "50443250" },
      { "name": "Frozen radiance corn", "code": "50443251" },
      { "name": "Frozen seneca brave corn", "code": "50443252" },
      { "name": "Frozen seneca dawn corn", "code": "50443253" },
      { "name": "Frozen seneca horizon corn", "code": "50443254" },
      { "name": "Frozen seneca starshine corn", "code": "50443255" },
      { "name": "Frozen seneca white knight corn", "code": "50443256" },
      { "name": "Frozen showcase corn", "code": "50443257" },
      { "name": "Frozen silver queen corn", "code": "50443258" },
      { "name": "Frozen snowbelle corn", "code": "50443259" },
      { "name": "Frozen spring snow corn", "code": "50443260" },
      { "name": "Frozen spring treat corn", "code": "50443261" },
      { "name": "Frozen sugar and gold corn", "code": "50443262" },
      { "name": "Frozen sugar buns corn", "code": "50443263" },
      { "name": "Frozen sugar snow corn", "code": "50443264" },
      { "name": "Frozen sundance corn", "code": "50443265" },
      { "name": "Frozen telstar corn", "code": "50443266" },
      { "name": "Frozen terminator corn", "code": "50443267" },
      { "name": "Frozen treasure corn", "code": "50443268" },
      { "name": "Frozen tuxedo corn", "code": "50443269" },
      { "name": "Frozen cresses", "code": "50443300" },
      { "name": "Frozen land cress", "code": "50443301" },
      { "name": "Frozen nasturtium", "code": "50443302" },
      { "name": "Frozen watercress", "code": "50443303" },
      { "name": "Frozen wintercress", "code": "50443304" },
      { "name": "Frozen cucumbers", "code": "50443400" },
      { "name": "Frozen arena cucumbers", "code": "50443401" },
      { "name": "Frozen armenian cucumbers", "code": "50443402" },
      { "name": "Frozen athene cucumbers", "code": "50443403" },
      { "name": "Frozen bianco lungo di parigi cucumbers", "code": "50443404" },
      { "name": "Frozen burpless tasty green cucumbers", "code": "50443405" },
      { "name": "Frozen chicago pickling cucumbers", "code": "50443406" },
      { "name": "Frozen crystal apple cucumbers", "code": "50443407" },
      { "name": "Frozen crystal lemon cucumbers", "code": "50443408" },
      { "name": "Frozen danimas cucumbers", "code": "50443409" },
      { "name": "Frozen gherkin cucumbers", "code": "50443410" },
      { "name": "Frozen hokus cucumbers", "code": "50443411" },
      { "name": "Frozen japanese cucumbers", "code": "50443412" },
      { "name": "Frozen karela cucumbers", "code": "50443413" },
      { "name": "Frozen korila cucumbers", "code": "50443414" },
      { "name": "Frozen long green improved cucumbers", "code": "50443415" },
      { "name": "Frozen marketmore cucumbers", "code": "50443416" },
      { "name": "Frozen midget cucumbers", "code": "50443417" },
      { "name": "Frozen national pickling cucumbers", "code": "50443418" },
      { "name": "Frozen persian cucumbers", "code": "50443419" },
      { "name": "Frozen telegraph cucumbers", "code": "50443420" },
      { "name": "Frozen telegraph improved cucumbers", "code": "50443421" },
      {
        "name": "Frozen vert de massy cornichon cucumbers",
        "code": "50443422"
      },
      { "name": "Frozen yamato cucumbers", "code": "50443423" },
      { "name": "Frozen eggplants", "code": "50443500" },
      { "name": "Frozen bambino eggplants", "code": "50443501" },
      { "name": "Frozen black beauty eggplants", "code": "50443502" },
      { "name": "Frozen black enorma eggplants", "code": "50443503" },
      { "name": "Frozen chinese eggplants", "code": "50443504" },
      { "name": "Frozen easter egg eggplants", "code": "50443505" },
      { "name": "Frozen filipino eggplants", "code": "50443506" },
      { "name": "Frozen florida market eggplants", "code": "50443507" },
      { "name": "Frozen indian eggplants", "code": "50443508" },
      { "name": "Frozen italian eggplants", "code": "50443509" },
      { "name": "Frozen japanese eggplants", "code": "50443510" },
      { "name": "Frozen long purple eggplants", "code": "50443511" },
      { "name": "Frozen long striped eggplants", "code": "50443512" },
      { "name": "Frozen moneymaker eggplants", "code": "50443513" },
      { "name": "Frozen ova eggplants", "code": "50443514" },
      { "name": "Frozen pea eggplants", "code": "50443515" },
      { "name": "Frozen short tom eggplants", "code": "50443516" },
      { "name": "Frozen sicilian eggplants", "code": "50443517" },
      { "name": "Frozen thai eggplants", "code": "50443518" },
      { "name": "Frozen violette di firenze eggplants", "code": "50443519" },
      { "name": "Frozen white eggplants", "code": "50443520" },
      { "name": "Frozen endives", "code": "50443600" },
      { "name": "Frozen brussels witloof endives", "code": "50443601" },
      { "name": "Frozen castelfranco endives", "code": "50443602" },
      { "name": "Frozen catalogna di galatina endives", "code": "50443603" },
      { "name": "Frozen chioggia endives", "code": "50443604" },
      { "name": "Frozen grumolo verde endives", "code": "50443605" },
      { "name": "Frozen large rooted magdeburg endives", "code": "50443606" },
      {
        "name": "Frozen palla rossa zorzi precoce endives",
        "code": "50443607"
      },
      { "name": "Frozen radice amare endives", "code": "50443608" },
      { "name": "Frozen rossa di treviso endives", "code": "50443609" },
      { "name": "Frozen rossa di verona endives", "code": "50443610" },
      { "name": "Frozen soncino endives", "code": "50443611" },
      { "name": "Frozen sugarhat endives", "code": "50443612" },
      { "name": "Frozen verona endives", "code": "50443613" },
      { "name": "Frozen witloof zoom endives", "code": "50443614" },
      { "name": "Frozen fennels", "code": "50443700" },
      { "name": "Frozen cantino fennel", "code": "50443701" },
      { "name": "Frozen fino fennel", "code": "50443702" },
      { "name": "Frozen herald fennel", "code": "50443703" },
      { "name": "Frozen perfection fennel", "code": "50443704" },
      { "name": "Frozen sirio fennel", "code": "50443705" },
      { "name": "Frozen sweet florence fennel", "code": "50443706" },
      { "name": "Frozen tardo fennel", "code": "50443707" },
      { "name": "Frozen garlics", "code": "50443800" },
      { "name": "Frozen california late garlic", "code": "50443801" },
      { "name": "Frozen chinese garlic stems", "code": "50443802" },
      { "name": "Frozen garlic chives", "code": "50443803" },
      { "name": "Frozen germidor garlic", "code": "50443804" },
      { "name": "Frozen long keeper garlic", "code": "50443805" },
      { "name": "Frozen ramson garlic", "code": "50443806" },
      { "name": "Frozen rocambole garlic", "code": "50443807" },
      { "name": "Frozen rose de lautrec garlic", "code": "50443808" },
      { "name": "Frozen solent wight garlic", "code": "50443809" },
      { "name": "Frozen spanish morado garlic", "code": "50443810" },
      { "name": "Frozen venetian/italian garlic", "code": "50443811" },
      { "name": "Frozen gourds", "code": "50443900" },
      { "name": "Frozen angled loofah", "code": "50443901" },
      { "name": "Frozen bitter gourd", "code": "50443902" },
      { "name": "Frozen bottle gourd", "code": "50443903" },
      { "name": "Frozen calabash gourds", "code": "50443904" },
      { "name": "Frozen fuzzy/hairy melon", "code": "50443905" },
      { "name": "Frozen musky gourd", "code": "50443906" },
      { "name": "Frozen smooth loofah", "code": "50443907" },
      { "name": "Frozen snake gourd", "code": "50443908" },
      { "name": "Frozen spiny bitter gourd", "code": "50443909" },
      { "name": "Frozen tinda gourds", "code": "50443910" },
      { "name": "Frozen tindoori gourds", "code": "50443911" },
      { "name": "Frozen green peas", "code": "50444000" },
      { "name": "Frozen china peas", "code": "50444001" },
      { "name": "Frozen english peas", "code": "50444002" },
      { "name": "Frozen garden peas", "code": "50444003" },
      { "name": "Frozen snow peas", "code": "50444004" },
      { "name": "Frozen sugar snap peas", "code": "50444005" },
      { "name": "Frozen herbs", "code": "50444100" },
      { "name": "Frozen basil", "code": "50444101" },
      { "name": "Frozen bay leaves", "code": "50444102" },
      { "name": "Frozen borage", "code": "50444103" },
      { "name": "Frozen caraway", "code": "50444104" },
      { "name": "Frozen chervil", "code": "50444105" },
      { "name": "Frozen cilantro", "code": "50444106" },
      { "name": "Frozen cipolinos", "code": "50444107" },
      { "name": "Frozen curry leaves", "code": "50444108" },
      { "name": "Frozen dill", "code": "50444109" },
      { "name": "Frozen epazote", "code": "50444110" },
      { "name": "Frozen fenugreek", "code": "50444111" },
      { "name": "Frozen lemon grass", "code": "50444112" },
      { "name": "Frozen marjoram", "code": "50444113" },
      { "name": "Frozen mint", "code": "50444114" },
      { "name": "Frozen oregano", "code": "50444115" },
      { "name": "Frozen papalo", "code": "50444116" },
      { "name": "Frozen pepicha", "code": "50444117" },
      { "name": "Frozen perilla", "code": "50444118" },
      { "name": "Frozen recao", "code": "50444119" },
      { "name": "Frozen rosemary", "code": "50444120" },
      { "name": "Frozen sage", "code": "50444121" },
      { "name": "Frozen salsify", "code": "50444122" },
      { "name": "Frozen savory", "code": "50444123" },
      { "name": "Frozen tarragon", "code": "50444124" },
      { "name": "Frozen thyme", "code": "50444125" },
      { "name": "Frozen tumeric", "code": "50444126" },
      { "name": "Frozen verdulaga", "code": "50444127" },
      { "name": "Frozen kale", "code": "50444200" },
      { "name": "Frozen curly kale", "code": "50444201" },
      { "name": "Frozen collard greens", "code": "50444202" },
      { "name": "Frozen kohlrabi", "code": "50444300" },
      { "name": "Frozen azur star kohlrabi", "code": "50444301" },
      { "name": "Frozen green vienna kohlrabi", "code": "50444302" },
      { "name": "Frozen lanro kohlrabi", "code": "50444303" },
      { "name": "Frozen purple vienna kohlrabi", "code": "50444304" },
      { "name": "Frozen rowel trero kohlrabi", "code": "50444305" },
      { "name": "Frozen white vienna kohlrabi", "code": "50444306" },
      { "name": "Frozen leeks", "code": "50444400" },
      { "name": "Frozen autumn giant-cobra leeks", "code": "50444401" },
      { "name": "Frozen autumn mammoth 2 leeks", "code": "50444402" },
      { "name": "Frozen bleu de solaise leeks", "code": "50444403" },
      { "name": "Frozen cortina leeks", "code": "50444404" },
      { "name": "Frozen prelina leeks", "code": "50444405" },
      { "name": "Frozen wild leek ramp", "code": "50444406" },
      { "name": "Frozen lentils", "code": "50444500" },
      { "name": "Frozen beluga lentils", "code": "50444501" },
      { "name": "Frozen french green lentils", "code": "50444502" },
      { "name": "Frozen green lentils", "code": "50444503" },
      { "name": "Frozen petite crimson lentils", "code": "50444504" },
      { "name": "Frozen spanish pardina lentils", "code": "50444505" },
      { "name": "Frozen split red lentils", "code": "50444506" },
      { "name": "Frozen split yellow lentils", "code": "50444507" },
      { "name": "Frozen tarahumara pinks lentils", "code": "50444508" },
      { "name": "Frozen lettuces", "code": "50444600" },
      { "name": "Frozen bibb lettuce", "code": "50444601" },
      { "name": "Frozen boston lettuce", "code": "50444602" },
      { "name": "Frozen frisee lettuce", "code": "50444603" },
      { "name": "Frozen lolla rossa lettuce", "code": "50444604" },
      { "name": "Frozen mesculin mix lettuce", "code": "50444605" },
      { "name": "Frozen mizuna lettuce", "code": "50444606" },
      { "name": "Frozen red leaf lettuce", "code": "50444607" },
      { "name": "Frozen red oak leaf lettuce", "code": "50444608" },
      { "name": "Frozen ruby romaine lettuce", "code": "50444609" },
      { "name": "Frozen baby red romaine lettuce", "code": "50444610" },
      { "name": "Frozen butterhead lettuces", "code": "50444611" },
      { "name": "Frozen chinese lettuces", "code": "50444612" },
      { "name": "Frozen crisphead lettuces", "code": "50444613" },
      { "name": "Frozen green leaf lettuce", "code": "50444614" },
      { "name": "Frozen iceberg lettuce", "code": "50444615" },
      { "name": "Frozen lamb\u2019s lettuces", "code": "50444616" },
      { "name": "Frozen looseleaf lettuces", "code": "50444617" },
      { "name": "Frozen mache lettuce", "code": "50444618" },
      { "name": "Frozen red boston lettuce", "code": "50444619" },
      { "name": "Frozen red headed lettuces", "code": "50444620" },
      { "name": "Frozen romaine lettuces", "code": "50444621" },
      { "name": "Frozen russian red mustard lettuce", "code": "50444622" },
      { "name": "Frozen tatsoi lettuce", "code": "50444623" },
      { "name": "Frozen malanga", "code": "50444700" },
      { "name": "Frozen amarilla malanga", "code": "50444701" },
      { "name": "Frozen blanca malanga", "code": "50444702" },
      { "name": "Frozen coco malanga", "code": "50444703" },
      { "name": "Frozen eddoes malanga", "code": "50444704" },
      { "name": "Frozen islena malanga", "code": "50444705" },
      { "name": "Frozen lila malanga", "code": "50444706" },
      { "name": "Frozen mushrooms", "code": "50444800" },
      { "name": "Frozen black trumpet mushrooms", "code": "50444801" },
      { "name": "Frozen brown mushrooms", "code": "50444802" },
      { "name": "Frozen champinion mushrooms", "code": "50444803" },
      { "name": "Frozen chanterelle mushrooms", "code": "50444804" },
      { "name": "Frozen cremini mushrooms", "code": "50444805" },
      { "name": "Frozen enoki mushrooms", "code": "50444806" },
      { "name": "Frozen hedge hog mushrooms", "code": "50444807" },
      { "name": "Frozen hen of the woods mushrooms", "code": "50444808" },
      { "name": "Frozen lobster mushrooms", "code": "50444809" },
      { "name": "Frozen morels mushrooms", "code": "50444810" },
      { "name": "Frozen oyster mushrooms", "code": "50444811" },
      { "name": "Frozen pleurotus mushrooms", "code": "50444812" },
      { "name": "Frozen pompom mushrooms", "code": "50444813" },
      { "name": "Frozen porcieni mushrooms", "code": "50444814" },
      { "name": "Frozen portobella mushrooms", "code": "50444815" },
      { "name": "Frozen shiitake mushrooms", "code": "50444816" },
      { "name": "Frozen shimeji mushrooms", "code": "50444817" },
      { "name": "Frozen st george's mushrooms", "code": "50444818" },
      { "name": "Frozen white mushrooms", "code": "50444819" },
      { "name": "Frozen white trumpet mushrooms", "code": "50444820" },
      { "name": "Frozen woodear mushrooms", "code": "50444821" },
      { "name": "Frozen mustards", "code": "50444900" },
      { "name": "Frozen bamboo mustard", "code": "50444901" },
      { "name": "Frozen garlic mustard", "code": "50444902" },
      { "name": "Frozen giantleafed mustard", "code": "50444903" },
      { "name": "Frozen red in snow mustard", "code": "50444904" },
      { "name": "Frozen southern mustard", "code": "50444905" },
      { "name": "Frozen wrapped heart mustard", "code": "50444906" },
      { "name": "Frozen nightshades", "code": "50445000" },
      { "name": "Frozen chinese lantern", "code": "50445001" },
      { "name": "Frozen garden huckleberry", "code": "50445002" },
      { "name": "Frozen naranjilla", "code": "50445003" },
      { "name": "Frozen tomatillo", "code": "50445004" },
      { "name": "Frozen okras", "code": "50445100" },
      { "name": "Frozen artist okra", "code": "50445101" },
      { "name": "Frozen burgundy okra", "code": "50445102" },
      { "name": "Frozen clemson spineless okra", "code": "50445103" },
      { "name": "Frozen dwarf green long pod okra", "code": "50445104" },
      { "name": "Frozen mammoth spineless long pod okra", "code": "50445105" },
      { "name": "Frozen red velvet okra", "code": "50445106" },
      { "name": "Frozen star of david heirloom okra", "code": "50445107" },
      { "name": "Frozen onions", "code": "50445200" },
      { "name": "Frozen albion onions", "code": "50445201" },
      { "name": "Frozen alisa craig onions", "code": "50445202" },
      { "name": "Frozen boiling onions", "code": "50445203" },
      { "name": "Frozen buffalo onions", "code": "50445204" },
      { "name": "Frozen bulb onions", "code": "50445205" },
      { "name": "Frozen creaming onions", "code": "50445206" },
      { "name": "Frozen express yellow o-x onions", "code": "50445207" },
      { "name": "Frozen kelsae onions", "code": "50445208" },
      { "name": "Frozen marshalls giant fen globe onions", "code": "50445209" },
      { "name": "Frozen pearl onions", "code": "50445210" },
      { "name": "Frozen red baron onions", "code": "50445211" },
      { "name": "Frozen red onions", "code": "50445212" },
      { "name": "Frozen rijnsberger onions", "code": "50445213" },
      { "name": "Frozen senshyu semi-globe yellow onions", "code": "50445214" },
      { "name": "Frozen sturon onions", "code": "50445215" },
      { "name": "Frozen stuttgarter giant onions", "code": "50445216" },
      { "name": "Frozen sweet onions", "code": "50445217" },
      { "name": "Frozen torpedo or red italian onions", "code": "50445218" },
      { "name": "Frozen red storage onions", "code": "50445219" },
      { "name": "Frozen white storage onions", "code": "50445220" },
      { "name": "Frozen yellow storage onions", "code": "50445221" },
      { "name": "Frozen peanuts", "code": "50445300" },
      { "name": "Frozen bambarra groundnut peanuts", "code": "50445301" },
      { "name": "Frozen florunner peanuts", "code": "50445302" },
      {
        "name": "Frozen hausa/kersting's ground nut peanuts",
        "code": "50445303"
      },
      { "name": "Frozen spanish peanuts", "code": "50445304" },
      { "name": "Frozen valencia peanuts", "code": "50445305" },
      { "name": "Frozen virginia peanuts", "code": "50445306" },
      { "name": "Frozen peas", "code": "50445400" },
      { "name": "Frozen purple hull peas", "code": "50445401" },
      { "name": "Frozen pinkeye peas", "code": "50445402" },
      { "name": "Frozen crowder peas", "code": "50445403" },
      { "name": "Frozen white acre peas", "code": "50445404" },
      { "name": "Frozen blackeyed peas", "code": "50445405" },
      { "name": "Frozen zipper cream peas", "code": "50445406" },
      { "name": "Frozen peppers", "code": "50445500" },
      { "name": "Frozen ajies peppers", "code": "50445501" },
      { "name": "Frozen arbol peppers", "code": "50445502" },
      { "name": "Frozen cheese peppers", "code": "50445503" },
      { "name": "Frozen chilaca peppers", "code": "50445504" },
      { "name": "Frozen cubanelles peppers", "code": "50445505" },
      { "name": "Frozen fresno peppers", "code": "50445506" },
      { "name": "Frozen kapia peppers", "code": "50445507" },
      { "name": "Frozen korean peppers", "code": "50445508" },
      { "name": "Frozen manzano peppers", "code": "50445509" },
      { "name": "Frozen melrose peppers", "code": "50445510" },
      { "name": "Frozen yellow chile peppers", "code": "50445511" },
      { "name": "Frozen aji dulces peppers", "code": "50445512" },
      { "name": "Frozen anaheim peppers", "code": "50445513" },
      { "name": "Frozen ancho peppers", "code": "50445514" },
      { "name": "Frozen bell peppers", "code": "50445515" },
      { "name": "Frozen cascabel peppers", "code": "50445516" },
      { "name": "Frozen cayenne peppers", "code": "50445517" },
      { "name": "Frozen cherry hots peppers", "code": "50445518" },
      { "name": "Frozen chiltecpin peppers", "code": "50445519" },
      { "name": "Frozen finger hot peppers", "code": "50445520" },
      { "name": "Frozen guajillo peppers", "code": "50445521" },
      { "name": "Frozen guerro peppers", "code": "50445522" },
      { "name": "Frozen habanero peppers", "code": "50445523" },
      { "name": "Frozen hungarian wax peppers", "code": "50445524" },
      { "name": "Frozen jalapeno peppers", "code": "50445525" },
      { "name": "Frozen long hot peppers", "code": "50445526" },
      { "name": "Frozen mirasol peppers", "code": "50445527" },
      { "name": "Frozen pasilla peppers", "code": "50445528" },
      { "name": "Frozen peperoncini peppers", "code": "50445529" },
      { "name": "Frozen pequin peppers", "code": "50445530" },
      { "name": "Frozen pimiento peppers", "code": "50445531" },
      { "name": "Frozen poblano peppers", "code": "50445532" },
      { "name": "Frozen scotch bonnet peppers", "code": "50445533" },
      { "name": "Frozen serrano peppers", "code": "50445534" },
      { "name": "Frozen tabasco peppers", "code": "50445535" },
      { "name": "Frozen tai peppers", "code": "50445536" },
      { "name": "Frozen tepin peppers", "code": "50445537" },
      { "name": "Frozen potatoes", "code": "50445600" },
      { "name": "Frozen long white potatoes", "code": "50445601" },
      { "name": "Frozen round white potatoes", "code": "50445602" },
      { "name": "Frozen round red potatoes", "code": "50445603" },
      { "name": "Frozen russet potatoes", "code": "50445604" },
      { "name": "Frozen purple potatoes", "code": "50445605" },
      { "name": "Frozen yellow potatoes", "code": "50445606" },
      { "name": "Frozen new potatoes", "code": "50445607" },
      { "name": "Frozen specialty potatoes", "code": "50445608" },
      { "name": "Cocktail frozen potatoes", "code": "50445609" },
      { "name": "Frozen peruanita potatoes", "code": "50445610" },
      { "name": "Frozen huayro potatoes", "code": "50445611" },
      { "name": "Frozen rutabagas", "code": "50445700" },
      { "name": "Frozen acme rutabagas", "code": "50445701" },
      { "name": "Frozen angela rutabagas", "code": "50445702" },
      { "name": "Frozen best of all rutabagas", "code": "50445703" },
      { "name": "Frozen marian rutabagas", "code": "50445704" },
      { "name": "Frozen sea vegetables", "code": "50445800" },
      { "name": "Frozen agar-agar", "code": "50445801" },
      { "name": "Frozen arame", "code": "50445802" },
      { "name": "Frozen dulse", "code": "50445803" },
      { "name": "Frozen haricot vert de mer", "code": "50445804" },
      { "name": "Frozen hijiki", "code": "50445805" },
      { "name": "Frozen irish moss", "code": "50445806" },
      { "name": "Frozen kelp", "code": "50445807" },
      { "name": "Frozen laver", "code": "50445808" },
      { "name": "Frozen nori", "code": "50445809" },
      { "name": "Frozen red algae", "code": "50445810" },
      { "name": "Frozen sea kale", "code": "50445811" },
      { "name": "Frozen sea lettuce", "code": "50445812" },
      { "name": "Frozen seaweeds", "code": "50445813" },
      { "name": "Frozen spirulina", "code": "50445814" },
      { "name": "Frozen susabi nori", "code": "50445815" },
      { "name": "Frozen wakame", "code": "50445816" },
      { "name": "Frozen shallots", "code": "50445900" },
      { "name": "Frozen atlantic shallots", "code": "50445901" },
      { "name": "Frozen creation shallots", "code": "50445902" },
      { "name": "Frozen drittler white nest shallots", "code": "50445903" },
      { "name": "Frozen giant yellow improved shallots", "code": "50445904" },
      { "name": "Frozen golden gourmet shallots", "code": "50445905" },
      { "name": "Frozen grise de bagnolet shallots", "code": "50445906" },
      { "name": "Frozen hative de niort shallots", "code": "50445907" },
      { "name": "Frozen pikant shallots", "code": "50445908" },
      { "name": "Frozen red potato onions", "code": "50445909" },
      { "name": "Frozen sante shallots", "code": "50445910" },
      { "name": "Frozen topper shallots", "code": "50445911" },
      { "name": "Frozen sorrels", "code": "50446000" },
      { "name": "Frozen dock sorrel", "code": "50446001" },
      { "name": "Frozen garden sorrel", "code": "50446002" },
      { "name": "Frozen sheep sorrel", "code": "50446003" },
      { "name": "Frozen wood sorrel", "code": "50446004" },
      { "name": "Frozen spinaches", "code": "50446100" },
      { "name": "Frozen america spinach", "code": "50446101" },
      { "name": "Frozen bloomsdale spinach", "code": "50446102" },
      { "name": "Frozen giant winter spinach", "code": "50446103" },
      { "name": "Frozen horenso spinach", "code": "50446104" },
      { "name": "Frozen iceplant spinach", "code": "50446105" },
      { "name": "Frozen lamb's quarters spinach", "code": "50446106" },
      { "name": "Frozen malabar spinach", "code": "50446107" },
      { "name": "Frozen medania spinach", "code": "50446108" },
      { "name": "Frozen new zealand spinach", "code": "50446109" },
      { "name": "Frozen orach spinach", "code": "50446110" },
      { "name": "Frozen savoy spinach", "code": "50446111" },
      { "name": "Frozen sigmaleaf spinach", "code": "50446112" },
      { "name": "Frozen space spinach", "code": "50446113" },
      { "name": "Frozen trinidad spinach", "code": "50446114" },
      { "name": "Frozen water spinach", "code": "50446115" },
      { "name": "Frozen wild spinach", "code": "50446116" },
      {
        "name": "Frozen summer squashes and summer pumpkins",
        "code": "50446200"
      },
      { "name": "Frozen boston marrow squash", "code": "50446201" },
      { "name": "Frozen butternut squash", "code": "50446202" },
      { "name": "Frozen costata romanesca squash", "code": "50446203" },
      { "name": "Frozen crookneck squash", "code": "50446204" },
      { "name": "Frozen cucuzza squash", "code": "50446205" },
      { "name": "Frozen delicata squash", "code": "50446206" },
      { "name": "Frozen delicious squash", "code": "50446207" },
      {
        "name": "Frozen early golden summer crookneck squash",
        "code": "50446208"
      },
      {
        "name": "Frozen early prolific straight neck squash",
        "code": "50446209"
      },
      { "name": "Frozen gold squash", "code": "50446210" },
      { "name": "Frozen jack be little squash", "code": "50446211" },
      { "name": "Frozen kentucky field squash", "code": "50446212" },
      { "name": "Frozen marrow squash", "code": "50446213" },
      { "name": "Frozen middle eastern squash", "code": "50446214" },
      { "name": "Frozen miniature squash", "code": "50446215" },
      { "name": "Frozen orangetti squash", "code": "50446216" },
      { "name": "Frozen pattypan squash", "code": "50446217" },
      { "name": "Frozen rondini squash", "code": "50446218" },
      { "name": "Frozen round squash", "code": "50446219" },
      { "name": "Frozen spaghetti squash", "code": "50446220" },
      { "name": "Frozen stripetti squash", "code": "50446221" },
      { "name": "Frozen sugar loaf squash", "code": "50446222" },
      { "name": "Frozen sweet dumpling squash", "code": "50446223" },
      { "name": "Frozen triple treat squash", "code": "50446224" },
      { "name": "Frozen waltham butternut squash", "code": "50446225" },
      { "name": "Frozen yellow bush scallop squash", "code": "50446226" },
      { "name": "Frozen yellow straightneck squash", "code": "50446227" },
      { "name": "Frozen zephyr squash", "code": "50446228" },
      { "name": "Frozen zucchini squash", "code": "50446229" },
      { "name": "Frozen sweet potatoes", "code": "50446300" },
      { "name": "Frozen beauregard sweet potatoes", "code": "50446301" },
      { "name": "Frozen centennial sweet potatoes", "code": "50446302" },
      { "name": "Frozen diane sweet potatoes", "code": "50446303" },
      { "name": "Frozen garnet sweet potatoes", "code": "50446304" },
      { "name": "Frozen georgia red sweet potatoes", "code": "50446305" },
      { "name": "Frozen goldensweet sweet potatoes", "code": "50446306" },
      { "name": "Frozen hanna sweet potatoes", "code": "50446307" },
      { "name": "Frozen japanese sweet potatoes", "code": "50446308" },
      { "name": "Frozen jersey sweet potatoes", "code": "50446309" },
      { "name": "Frozen jewel sweet potatoes", "code": "50446310" },
      { "name": "Frozen maryland red sweet potatoes", "code": "50446311" },
      { "name": "Frozen nemagold sweet potatoes", "code": "50446312" },
      { "name": "Frozen o'henry sweet potatoes", "code": "50446313" },
      { "name": "Frozen okinawan sweet potatoes", "code": "50446314" },
      { "name": "Frozen orange sweet potatoes", "code": "50446315" },
      { "name": "Frozen oriental sweet potatoes", "code": "50446316" },
      { "name": "Frozen red jersey sweet potatoes", "code": "50446317" },
      { "name": "Frozen red mar sweet potatoes", "code": "50446318" },
      { "name": "Frozen redglow sweet potatoes", "code": "50446319" },
      { "name": "Frozen yellow jersey sweet potatoes", "code": "50446320" },
      { "name": "Frozen purple sweet potatoes", "code": "50446321" },
      { "name": "Frozen tomatoes", "code": "50446400" },
      { "name": "Frozen ailsa craig tomatoes", "code": "50446401" },
      { "name": "Frozen alicante tomatoes", "code": "50446402" },
      { "name": "Frozen black plum tomatoes", "code": "50446403" },
      { "name": "Frozen brandywine tomatoes", "code": "50446404" },
      { "name": "Frozen cherry belle tomatoes", "code": "50446405" },
      { "name": "Frozen cherry tomatoes", "code": "50446406" },
      { "name": "Frozen delicious tomatoes", "code": "50446407" },
      { "name": "Frozen dombito tomatoes", "code": "50446408" },
      { "name": "Frozen gardener's delight tomatoes", "code": "50446409" },
      { "name": "Frozen grape tomatoes", "code": "50446410" },
      { "name": "Frozen green tomatoes", "code": "50446411" },
      { "name": "Frozen marmande super tomatoes", "code": "50446412" },
      {
        "name": "Frozen marvel striped traditional tomatoes",
        "code": "50446413"
      },
      { "name": "Frozen minibel tomatoes", "code": "50446414" },
      { "name": "Frozen oaxacan pink tomatoes", "code": "50446415" },
      { "name": "Frozen red alert tomatoes", "code": "50446416" },
      { "name": "Frozen roma vf tomatoes", "code": "50446417" },
      { "name": "Frozen san marzano tomatoes", "code": "50446418" },
      { "name": "Frozen shirley tomatoes", "code": "50446419" },
      { "name": "Frozen siberia tomato tomatoes", "code": "50446420" },
      { "name": "Frozen super beefsteak tomatoes", "code": "50446421" },
      { "name": "Frozen tigerella tomatoes", "code": "50446422" },
      { "name": "Frozen tiny tim tomatoes", "code": "50446423" },
      { "name": "Frozen tumbler tomatoes", "code": "50446424" },
      { "name": "Frozen yellow cocktail tomatoes", "code": "50446425" },
      { "name": "Frozen yellow pear-shaped tomatoes", "code": "50446426" },
      { "name": "Frozen yellow perfection tomatoes", "code": "50446427" },
      { "name": "Frozen turnip greens", "code": "50446500" },
      { "name": "Frozen green globe turnips", "code": "50446501" },
      { "name": "Frozen golden ball turnips", "code": "50446502" },
      { "name": "Frozen manchester market turnips", "code": "50446503" },
      { "name": "Frozen purple top milan turnips", "code": "50446504" },
      { "name": "Frozen purple top white turnips", "code": "50446505" },
      { "name": "Frozen snowball turnips", "code": "50446506" },
      { "name": "Frozen tokyo turnip", "code": "50446507" },
      { "name": "Frozen tokyo cross turnips", "code": "50446508" },
      {
        "name": "Frozen winter squashes and winter pumpkins",
        "code": "50446600"
      },
      { "name": "Frozen acorn squash", "code": "50446601" },
      { "name": "Frozen atlantic giant squash", "code": "50446602" },
      { "name": "Frozen banana pink squash", "code": "50446603" },
      { "name": "Frozen big max squash", "code": "50446604" },
      { "name": "Frozen calabaza squash", "code": "50446605" },
      { "name": "Frozen carnival squash", "code": "50446606" },
      { "name": "Frozen cheese pumpkin", "code": "50446607" },
      { "name": "Frozen crown prince squash", "code": "50446608" },
      { "name": "Frozen curcibita squash", "code": "50446609" },
      { "name": "Frozen cushaw squash", "code": "50446610" },
      { "name": "Frozen giant pumpkin squash", "code": "50446611" },
      { "name": "Frozen hubbard squash", "code": "50446612" },
      { "name": "Frozen jarrahdale squash", "code": "50446613" },
      { "name": "Frozen kabocha squash", "code": "50446614" },
      { "name": "Frozen queensland blue squash", "code": "50446615" },
      { "name": "Frozen rouge vif d'etampes squash", "code": "50446616" },
      { "name": "Frozen turks turban squash", "code": "50446617" },
      { "name": "Frozen valenciano squash", "code": "50446618" },
      { "name": "Frozen warted hubbard squash", "code": "50446619" },
      { "name": "Frozen whangaparoa crown pumpkin", "code": "50446620" },
      { "name": "Frozen yams", "code": "50446700" },
      { "name": "Frozen african bitter yams", "code": "50446701" },
      { "name": "Frozen asiatic bitter yams", "code": "50446702" },
      { "name": "Frozen chinese yams", "code": "50446703" },
      { "name": "Frozen globe yams", "code": "50446704" },
      { "name": "Frozen greater yams", "code": "50446705" },
      { "name": "Frozen japanese yams", "code": "50446706" },
      { "name": "Frozen lesser yams", "code": "50446707" },
      { "name": "Frozen potato yams", "code": "50446708" },
      { "name": "Frozen white guinea yams", "code": "50446709" },
      { "name": "Frozen yellow guinea yams", "code": "50446710" },
      { "name": "Frozen nominant vegetables", "code": "50446800" },
      { "name": "Frozen alfalfa", "code": "50446801" },
      { "name": "Frozen aloe leaves", "code": "50446802" },
      { "name": "Frozen apio", "code": "50446803" },
      { "name": "Frozen arrow root", "code": "50446804" },
      { "name": "Frozen arrowhead", "code": "50446805" },
      { "name": "Frozen arugula", "code": "50446806" },
      { "name": "Frozen arum", "code": "50446807" },
      { "name": "Frozen bamboo shoots", "code": "50446808" },
      { "name": "Frozen banana leaves", "code": "50446809" },
      { "name": "Frozen batatas", "code": "50446810" },
      { "name": "Frozen bean sprouts", "code": "50446811" },
      { "name": "Frozen beet tops", "code": "50446812" },
      { "name": "Frozen bittermelon", "code": "50446813" },
      { "name": "Frozen caperberries", "code": "50446814" },
      { "name": "Frozen carob", "code": "50446815" },
      { "name": "Frozen cha-om", "code": "50446816" },
      { "name": "Frozen chaoyotes", "code": "50446817" },
      { "name": "Frozen chickpeas", "code": "50446818" },
      { "name": "Frozen chrysanthemum greens", "code": "50446819" },
      { "name": "Frozen dandelion greens", "code": "50446820" },
      { "name": "Frozen dandelions", "code": "50446821" },
      { "name": "Frozen dasheen", "code": "50446822" },
      { "name": "Frozen dau mue or pea tips", "code": "50446823" },
      { "name": "Frozen diakon", "code": "50446824" },
      { "name": "Frozen donqua", "code": "50446825" },
      { "name": "Frozen fiddlehead ferns", "code": "50446826" },
      { "name": "Frozen gai choy", "code": "50446827" },
      { "name": "Frozen gailon", "code": "50446828" },
      { "name": "Frozen galanga", "code": "50446829" },
      { "name": "Frozen ginger root", "code": "50446830" },
      { "name": "Frozen gobo", "code": "50446831" },
      { "name": "Frozen hop sprouts", "code": "50446832" },
      { "name": "Frozen horseradish", "code": "50446833" },
      { "name": "Frozen jicama", "code": "50446834" },
      { "name": "Frozen kudzu", "code": "50446835" },
      { "name": "Frozen lily bulb", "code": "50446836" },
      { "name": "Frozen linkok", "code": "50446837" },
      { "name": "Frozen lo bok", "code": "50446838" },
      { "name": "Frozen long beans", "code": "50446839" },
      { "name": "Frozen lotus root", "code": "50446840" },
      { "name": "Frozen maguey leaves", "code": "50446841" },
      { "name": "Frozen mallows", "code": "50446842" },
      { "name": "Frozen mamey sapote", "code": "50446843" },
      { "name": "Frozen moap", "code": "50446844" },
      { "name": "Frozen moo", "code": "50446845" },
      { "name": "Frozen moqua", "code": "50446846" },
      { "name": "Frozen opos", "code": "50446847" },
      { "name": "Frozen palm hearts", "code": "50446848" },
      { "name": "Frozen paprika", "code": "50446849" },
      { "name": "Frozen purslane", "code": "50446850" },
      { "name": "Frozen raddichios", "code": "50446851" },
      { "name": "Frozen sinquas", "code": "50446852" },
      { "name": "Frozen soybeans", "code": "50446853" },
      { "name": "Frozen spoonwart", "code": "50446854" },
      { "name": "Frozen tassle grape-hyacinth", "code": "50446855" },
      { "name": "Frozen taro", "code": "50446856" },
      { "name": "Frozen taro leaf", "code": "50446857" },
      { "name": "Frozen taro shoot", "code": "50446858" },
      { "name": "Frozen tepeguaje", "code": "50446859" },
      { "name": "Frozen tendergreen", "code": "50446860" },
      { "name": "Frozen tindora", "code": "50446861" },
      { "name": "Frozen tree onion", "code": "50446862" },
      { "name": "Frozen udo", "code": "50446863" },
      { "name": "Frozen water chestnuts", "code": "50446864" },
      { "name": "Frozen yampi", "code": "50446865" },
      { "name": "Frozen yautia", "code": "50446866" },
      { "name": "Frozen yu choy", "code": "50446867" },
      { "name": "Frozen yuca", "code": "50446868" },
      { "name": "Frozen sugar peas", "code": "50446900" },
      { "name": "Frozen bikini peas", "code": "50446901" },
      { "name": "Frozen cavalier peas", "code": "50446902" },
      { "name": "Frozen daisy peas", "code": "50446903" },
      { "name": "Frozen darfon peas", "code": "50446904" },
      { "name": "Frozen early onward peas", "code": "50446905" },
      { "name": "Frozen feltham first peas", "code": "50446906" },
      { "name": "Frozen hurst green shaft peas", "code": "50446907" },
      { "name": "Frozen oregon sugar pod peas", "code": "50446908" },
      { "name": "Frozen prince albert peas", "code": "50446909" },
      { "name": "Frozen reuzensuiker peas", "code": "50446910" },
      { "name": "Frozen organic vegetables", "code": "50450000" },
      { "name": "Frozen organic artichokes", "code": "50451500" },
      { "name": "Frozen organic brittany artichokes", "code": "50451501" },
      { "name": "Frozen organic catanese artichokes", "code": "50451502" },
      { "name": "Frozen organic french artichokes", "code": "50451503" },
      { "name": "Frozen organic green globe artichokes", "code": "50451504" },
      {
        "name": "Frozen organic gros camus de bretagne artichokes",
        "code": "50451505"
      },
      { "name": "Frozen organic midi artichokes", "code": "50451506" },
      { "name": "Frozen organic purple globe artichokes", "code": "50451507" },
      {
        "name": "Frozen organic purple sicilian artichokes",
        "code": "50451508"
      },
      { "name": "Frozen organic romanesco artichokes", "code": "50451509" },
      { "name": "Frozen organic spinoso sardo artichokes", "code": "50451510" },
      { "name": "Frozen organic vert de laon artichokes", "code": "50451511" },
      {
        "name": "Frozen organic violetta di chioggia artichokes",
        "code": "50451512"
      },
      {
        "name": "Frozen organic violetto di toscana artichokes",
        "code": "50451513"
      },
      { "name": "Frozen organic asparagus", "code": "50451600" },
      {
        "name": "Frozen organic connover's colossal asparagus",
        "code": "50451601"
      },
      { "name": "Frozen organic franklin asparagus", "code": "50451602" },
      { "name": "Frozen organic giant mammoth asparagus", "code": "50451603" },
      { "name": "Frozen organic lucullus asparagus", "code": "50451604" },
      {
        "name": "Frozen organic martha washington asparagus",
        "code": "50451605"
      },
      { "name": "Frozen organic avocados", "code": "50451700" },
      { "name": "Frozen organic ajax b-7 avocados", "code": "50451701" },
      { "name": "Frozen organic arue avocados", "code": "50451702" },
      { "name": "Frozen organic bacon avocados", "code": "50451703" },
      { "name": "Frozen organic benik avocados", "code": "50451704" },
      { "name": "Frozen organic bernecker avocados", "code": "50451705" },
      { "name": "Frozen organic beta avocados", "code": "50451706" },
      { "name": "Frozen organic biondo avocados", "code": "50451707" },
      { "name": "Frozen organic black prince avocados", "code": "50451708" },
      { "name": "Frozen organic blair avocados", "code": "50451709" },
      { "name": "Frozen organic blair booth avocados", "code": "50451710" },
      { "name": "Frozen organic booth 1 avocados", "code": "50451711" },
      { "name": "Frozen organic booth 3 avocados", "code": "50451712" },
      { "name": "Frozen organic booth 5 avocados", "code": "50451713" },
      { "name": "Frozen organic booth 7 avocados", "code": "50451714" },
      { "name": "Frozen organic booth 8 avocados", "code": "50451715" },
      { "name": "Frozen organic brooks 1978 avocados", "code": "50451716" },
      { "name": "Frozen organic brookslate avocados", "code": "50451717" },
      { "name": "Frozen organic california haas avocados", "code": "50451718" },
      { "name": "Frozen organic catalina avocados", "code": "50451719" },
      { "name": "Frozen organic chica avocados", "code": "50451720" },
      { "name": "Frozen organic choquette avocados", "code": "50451721" },
      { "name": "Frozen organic christina avocados", "code": "50451722" },
      { "name": "Frozen organic collinson avocados", "code": "50451723" },
      { "name": "Frozen organic donnie avocados", "code": "50451724" },
      {
        "name": "Frozen organic dr dupuis number 2 avocados",
        "code": "50451725"
      },
      { "name": "Frozen organic dr dupuis avocados", "code": "50451726" },
      { "name": "Frozen organic ettinger avocados", "code": "50451727" },
      { "name": "Frozen organic fuchs avocados", "code": "50451728" },
      { "name": "Frozen organic fuchs gwen avocados", "code": "50451729" },
      { "name": "Frozen organic fuerte avocados", "code": "50451730" },
      { "name": "Frozen organic gorham avocados", "code": "50451731" },
      { "name": "Frozen organic gossman avocados", "code": "50451732" },
      {
        "name": "Frozen organic guatemalan seedling avocados",
        "code": "50451733"
      },
      { "name": "Frozen organic hall avocados", "code": "50451734" },
      { "name": "Frozen organic hardee avocados", "code": "50451735" },
      { "name": "Frozen organic haas avocados", "code": "50451736" },
      { "name": "Frozen organic herman avocados", "code": "50451737" },
      { "name": "Frozen organic hickson avocados", "code": "50451738" },
      { "name": "Frozen organic k-5 avocados", "code": "50451739" },
      { "name": "Frozen organic k-9 avocados", "code": "50451740" },
      { "name": "Frozen organic lamb haas avocados", "code": "50451741" },
      { "name": "Frozen organic leona avocados", "code": "50451742" },
      { "name": "Frozen organic leona linda avocados", "code": "50451743" },
      { "name": "Frozen organic lisa p avocados", "code": "50451744" },
      { "name": "Frozen organic lisa loretta avocados", "code": "50451745" },
      { "name": "Frozen organic loretta avocados", "code": "50451746" },
      { "name": "Frozen organic lula avocados", "code": "50451747" },
      { "name": "Frozen organic lula macarthur avocados", "code": "50451748" },
      { "name": "Frozen organic marcus avocados", "code": "50451749" },
      { "name": "Frozen organic melendez avocados", "code": "50451750" },
      { "name": "Frozen organic meya p avocados", "code": "50451751" },
      { "name": "Frozen organic miguel p avocados", "code": "50451752" },
      { "name": "Frozen organic monroe avocados", "code": "50451753" },
      { "name": "Frozen organic murrieta green avocados", "code": "50451754" },
      { "name": "Frozen organic nabal avocados", "code": "50451755" },
      { "name": "Frozen organic nadir avocados", "code": "50451756" },
      { "name": "Frozen organic nesbitt avocados", "code": "50451757" },
      { "name": "Frozen organic peterson avocados", "code": "50451758" },
      { "name": "Frozen organic pinelli avocados", "code": "50451759" },
      { "name": "Frozen organic pinkerton avocados", "code": "50451760" },
      { "name": "Frozen organic pollock avocados", "code": "50451761" },
      { "name": "Frozen organic puebla avocados", "code": "50451762" },
      { "name": "Frozen organic reed avocados", "code": "50451763" },
      { "name": "Frozen organic rue avocados", "code": "50451764" },
      { "name": "Frozen organic ruehle avocados", "code": "50451765" },
      { "name": "Frozen organic ryan avocados", "code": "50451766" },
      { "name": "Frozen organic semil 34 avocados", "code": "50451767" },
      { "name": "Frozen organic semil 43 avocados", "code": "50451768" },
      { "name": "Frozen organic simmonds avocados", "code": "50451769" },
      { "name": "Frozen organic simpson avocados", "code": "50451770" },
      { "name": "Frozen organic taylor avocados", "code": "50451771" },
      { "name": "Frozen organic tonnage avocados", "code": "50451772" },
      { "name": "Frozen organic tower avocados", "code": "50451773" },
      { "name": "Frozen organic tower li avocados", "code": "50451774" },
      { "name": "Frozen organic trapp avocados", "code": "50451775" },
      {
        "name": "Frozen organic west indian seedling avocados",
        "code": "50451776"
      },
      { "name": "Frozen organic wagner avocados", "code": "50451777" },
      { "name": "Frozen organic waldin avocados", "code": "50451778" },
      { "name": "Frozen organic wurtz avocados", "code": "50451779" },
      { "name": "Frozen organic zio p avocados", "code": "50451780" },
      { "name": "Frozen organic ziu avocados", "code": "50451781" },
      { "name": "Frozen organic zutano avocados", "code": "50451782" },
      { "name": "Frozen organic beans", "code": "50451800" },
      { "name": "Frozen organic anasazi or aztec beans", "code": "50451801" },
      { "name": "Frozen organic appaloosa beans", "code": "50451802" },
      { "name": "Frozen organic azuki beans", "code": "50451803" },
      { "name": "Frozen organic barlotti beans", "code": "50451804" },
      { "name": "Frozen organic black appaloosa beans", "code": "50451805" },
      { "name": "Frozen organic black beans", "code": "50451806" },
      { "name": "Frozen organic black gram beans", "code": "50451807" },
      { "name": "Frozen organic black shackamaxon beans", "code": "50451808" },
      { "name": "Frozen organic blackeyed beans", "code": "50451809" },
      { "name": "Frozen organic bobby beans", "code": "50451810" },
      { "name": "Frozen organic bolita beans", "code": "50451811" },
      { "name": "Frozen organic brown lazy wife beans", "code": "50451812" },
      { "name": "Frozen organic calypso beans", "code": "50451813" },
      { "name": "Frozen organic cannellini beans", "code": "50451814" },
      { "name": "Frozen organic castor beans", "code": "50451815" },
      { "name": "Frozen organic china yellow beans", "code": "50451816" },
      { "name": "Frozen organic dragon tongue beans", "code": "50451817" },
      { "name": "Frozen organic european soldier beans", "code": "50451818" },
      { "name": "Frozen organic fava beans", "code": "50451819" },
      { "name": "Frozen organic flageolet beans", "code": "50451820" },
      {
        "name": "Frozen organic french horticultural beans",
        "code": "50451821"
      },
      { "name": "Frozen organic french navy beans", "code": "50451822" },
      { "name": "Frozen organic giant white coco beans", "code": "50451823" },
      { "name": "Frozen organic green beans", "code": "50451824" },
      { "name": "Frozen organic green romano beans", "code": "50451825" },
      { "name": "Frozen organic guar gum beans", "code": "50451826" },
      { "name": "Frozen organic haricot beans", "code": "50451827" },
      { "name": "Frozen organic hyacinth beans", "code": "50451828" },
      { "name": "Frozen organic italian type beans", "code": "50451829" },
      { "name": "Frozen organic jackson wonder beans", "code": "50451830" },
      { "name": "Frozen organic jacob's cattle beans", "code": "50451831" },
      { "name": "Frozen organic kentucky wonder beans", "code": "50451832" },
      { "name": "Frozen organic kidney beans", "code": "50451833" },
      { "name": "Frozen organic lima beans", "code": "50451834" },
      { "name": "Frozen organic madeira/madera beans", "code": "50451835" },
      { "name": "Frozen organic marrow beans", "code": "50451836" },
      { "name": "Frozen organic mat beans", "code": "50451837" },
      {
        "name": "Frozen organic monstoller wild goose beans",
        "code": "50451838"
      },
      { "name": "Frozen organic mortgage lifter beans", "code": "50451839" },
      { "name": "Frozen organic moth beans", "code": "50451840" },
      { "name": "Frozen organic mung beans", "code": "50451841" },
      { "name": "Frozen organic munsi wolf bean", "code": "50451842" },
      { "name": "Frozen organic nuna beans", "code": "50451843" },
      { "name": "Frozen organic pinto beans", "code": "50451844" },
      { "name": "Frozen organic pole beans", "code": "50451845" },
      { "name": "Frozen organic runner beans", "code": "50451846" },
      { "name": "Frozen organic string beans", "code": "50451847" },
      { "name": "Frozen organic tamarind beans", "code": "50451848" },
      { "name": "Frozen organic tonka beans", "code": "50451849" },
      { "name": "Frozen organic wax beans", "code": "50451850" },
      { "name": "Frozen organic winged beans", "code": "50451851" },
      { "name": "Frozen organic yard long beans", "code": "50451852" },
      { "name": "Frozen organic beets", "code": "50451900" },
      { "name": "Frozen organic action beets", "code": "50451901" },
      { "name": "Frozen organic albina vereduna beets", "code": "50451902" },
      {
        "name": "Frozen organic barbabietola di chioggia beets",
        "code": "50451903"
      },
      { "name": "Frozen organic boltardy beets", "code": "50451904" },
      { "name": "Frozen organic bonel beets", "code": "50451905" },
      { "name": "Frozen organic burpees golden beets", "code": "50451906" },
      {
        "name": "Frozen organic cheltenham green top beets",
        "code": "50451907"
      },
      { "name": "Frozen organic cheltenham mono beets", "code": "50451908" },
      { "name": "Frozen organic chioggia beets", "code": "50451909" },
      { "name": "Frozen organic cylindra beets", "code": "50451910" },
      { "name": "Frozen organic d'egypte beets", "code": "50451911" },
      { "name": "Frozen organic detroit 2 dark red beets", "code": "50451912" },
      {
        "name": "Frozen organic detroit 2 little ball beets",
        "code": "50451913"
      },
      { "name": "Frozen organic egyptian flat beets", "code": "50451914" },
      {
        "name": "Frozen organic egyptian turnip rooted beets",
        "code": "50451915"
      },
      { "name": "Frozen organic formanova beets", "code": "50451916" },
      { "name": "Frozen organic forono beets", "code": "50451917" },
      { "name": "Frozen organic monaco beets", "code": "50451918" },
      { "name": "Frozen organic monogram beets", "code": "50451919" },
      { "name": "Frozen organic pronto beets", "code": "50451920" },
      { "name": "Frozen organic regalia beets", "code": "50451921" },
      { "name": "Frozen organic sugar beets", "code": "50451922" },
      { "name": "Frozen organic broccoli", "code": "50452000" },
      { "name": "Frozen organic broccolini", "code": "50452001" },
      { "name": "Frozen organic broccoli romanesco", "code": "50452002" },
      { "name": "Frozen organic broccoli raab", "code": "50452003" },
      { "name": "Frozen organic chinese broccoli", "code": "50452004" },
      { "name": "Frozen organic brussel sprouts", "code": "50452100" },
      { "name": "Frozen organic citadel brussel sprouts", "code": "50452101" },
      { "name": "Frozen organic falstaff brussel sprouts", "code": "50452102" },
      { "name": "Frozen organic oliver brussel sprouts", "code": "50452103" },
      {
        "name": "Frozen organic peer gynt brussel sprouts",
        "code": "50452104"
      },
      { "name": "Frozen organic rampart brussel sprouts", "code": "50452105" },
      { "name": "Frozen organic rubine brussel sprouts", "code": "50452106" },
      { "name": "Frozen organic widgeon brussel sprouts", "code": "50452107" },
      { "name": "Frozen organic bunching onions", "code": "50452200" },
      {
        "name": "Frozen organic beltsville bunching onions",
        "code": "50452201"
      },
      { "name": "Frozen organic feast bunching onions", "code": "50452202" },
      { "name": "Frozen organic ishikura bunching onions", "code": "50452203" },
      {
        "name": "Frozen organic kyoto market bunching onions",
        "code": "50452204"
      },
      {
        "name": "Frozen organic red beard bunching onions",
        "code": "50452205"
      },
      { "name": "Frozen organic redmate bunching onions", "code": "50452206" },
      {
        "name": "Frozen organic santa claus bunching onions",
        "code": "50452207"
      },
      { "name": "Frozen organic tokyo bunching onions", "code": "50452208" },
      {
        "name": "Frozen organic white lisbon bunching onions",
        "code": "50452209"
      },
      {
        "name": "Frozen organic winter white bunching onions",
        "code": "50452210"
      },
      {
        "name": "Frozen organic winter-over bunching onions",
        "code": "50452211"
      },
      { "name": "Frozen organic cabbages", "code": "50452300" },
      { "name": "Frozen organic black cabbages", "code": "50452301" },
      { "name": "Frozen organic savoy cabbages", "code": "50452302" },
      { "name": "Frozen organic skunk cabbages", "code": "50452303" },
      { "name": "Frozen organic white cabbages", "code": "50452304" },
      { "name": "Frozen organic cardoons", "code": "50452400" },
      { "name": "Frozen organic lunghi cardoons", "code": "50452401" },
      { "name": "Frozen organic gobbi cardoons", "code": "50452402" },
      { "name": "Frozen organic carrots", "code": "50452500" },
      { "name": "Frozen organic amsterdam carrots", "code": "50452501" },
      { "name": "Frozen organic autumn king carrots", "code": "50452502" },
      { "name": "Frozen organic berlicum carrots", "code": "50452503" },
      { "name": "Frozen organic chantenay carrots", "code": "50452504" },
      { "name": "Frozen organic nantes carrots", "code": "50452505" },
      { "name": "Frozen organic paris market carrots", "code": "50452506" },
      { "name": "Frozen organic cauliflowers", "code": "50452600" },
      {
        "name": "Frozen organic all the year round cauliflowers",
        "code": "50452601"
      },
      { "name": "Frozen organic alverda cauliflowers", "code": "50452602" },
      {
        "name": "Frozen organic autumn giant 3 cauliflowers",
        "code": "50452603"
      },
      { "name": "Frozen organic dok elgon cauliflowers", "code": "50452604" },
      {
        "name": "Frozen organic early snowball cauliflowers",
        "code": "50452605"
      },
      { "name": "Frozen organic limelight cauliflowers", "code": "50452606" },
      { "name": "Frozen organic minaret cauliflowers", "code": "50452607" },
      {
        "name": "Frozen organic orange bouquet cauliflowers",
        "code": "50452608"
      },
      { "name": "Frozen organic purple cape cauliflowers", "code": "50452609" },
      { "name": "Frozen organic snowball cauliflowers", "code": "50452610" },
      {
        "name": "Frozen organic walcheren winter 3 cauliflowers",
        "code": "50452611"
      },
      { "name": "Frozen organic white rock cauliflowers", "code": "50452612" },
      { "name": "Frozen organic celery", "code": "50452700" },
      { "name": "Frozen organic celebrity celery", "code": "50452701" },
      { "name": "Frozen organic celeriac", "code": "50452702" },
      { "name": "Frozen organic chinese celery", "code": "50452703" },
      { "name": "Frozen organic french dinant celery", "code": "50452704" },
      { "name": "Frozen organic giant pink celery", "code": "50452705" },
      { "name": "Frozen organic giant red celery", "code": "50452706" },
      { "name": "Frozen organic giant white celery", "code": "50452707" },
      {
        "name": "Frozen organic golden self-blanching celery",
        "code": "50452708"
      },
      { "name": "Frozen organic greensleeves celery", "code": "50452709" },
      { "name": "Frozen organic hopkins fenlander celery", "code": "50452710" },
      { "name": "Frozen organic ivory tower celery", "code": "50452711" },
      {
        "name": "Frozen organic lathom self-blanching celery",
        "code": "50452712"
      },
      { "name": "Frozen organic soup celery d'amsterdam", "code": "50452713" },
      { "name": "Frozen organic standard bearer celery", "code": "50452714" },
      { "name": "Frozen organic tall utah triumph celery", "code": "50452715" },
      { "name": "Frozen organic chards", "code": "50452800" },
      { "name": "Frozen organic bright lights chard", "code": "50452801" },
      { "name": "Frozen organic fordhook giant chard", "code": "50452802" },
      { "name": "Frozen organic lucullus chard", "code": "50452803" },
      { "name": "Frozen organic perpetual spinach chard", "code": "50452804" },
      { "name": "Frozen organic rhubarb chard", "code": "50452805" },
      { "name": "Frozen organic swiss chard", "code": "50452806" },
      { "name": "Frozen organic vulcan chard", "code": "50452807" },
      { "name": "Frozen organic white king chard", "code": "50452808" },
      { "name": "Frozen organic chicories", "code": "50452900" },
      {
        "name": "Frozen organic broad leaved batavian chicory",
        "code": "50452901"
      },
      {
        "name": "Frozen organic en cornet de bordeaux chicory",
        "code": "50452902"
      },
      {
        "name": "Frozen organic green curled ruffee chicory",
        "code": "50452903"
      },
      { "name": "Frozen organic green curled chicory", "code": "50452904" },
      { "name": "Frozen organic ione limnos chicory", "code": "50452905" },
      {
        "name": "Frozen organic riccia pancalieri chicory",
        "code": "50452906"
      },
      { "name": "Frozen organic salad king chicory", "code": "50452907" },
      { "name": "Frozen organic sanda chicory", "code": "50452908" },
      { "name": "Frozen organic scarola verde chicory", "code": "50452909" },
      {
        "name": "Frozen organic tres fine maraichere chicory",
        "code": "50452910"
      },
      {
        "name": "Frozen organic wallone freisee weschelkopf chicory",
        "code": "50452911"
      },
      { "name": "Frozen organic chinese cabbages", "code": "50453000" },
      { "name": "Frozen organic bok choy", "code": "50453001" },
      {
        "name": "Frozen organic chinese flat-headed cabbage",
        "code": "50453002"
      },
      {
        "name": "Frozen organic chinese flowering cabbage",
        "code": "50453003"
      },
      { "name": "Frozen organic choy sum", "code": "50453004" },
      { "name": "Frozen organic dwarf bok choy", "code": "50453005" },
      { "name": "Frozen organic fengshan bok choy", "code": "50453006" },
      { "name": "Frozen organic jade pagoda bok choy", "code": "50453007" },
      { "name": "Frozen organic kasumi bok choy", "code": "50453008" },
      { "name": "Frozen organic nerva bok choy", "code": "50453009" },
      { "name": "Frozen organic rosette bok choy", "code": "50453010" },
      { "name": "Frozen organic ruffles bok choy", "code": "50453011" },
      {
        "name": "Frozen organic santo serrated leaved cabbage",
        "code": "50453012"
      },
      { "name": "Frozen organic shanghai d bok choy", "code": "50453013" },
      { "name": "Frozen organic shantung cabbage", "code": "50453014" },
      { "name": "Frozen organic tip top cabbage", "code": "50453015" },
      { "name": "Frozen organic yau choy sum", "code": "50453016" },
      { "name": "Frozen organic chives", "code": "50453100" },
      { "name": "Frozen organic chinese chives", "code": "50453101" },
      { "name": "Frozen organic common chives", "code": "50453102" },
      { "name": "Frozen organic corn", "code": "50453200" },
      { "name": "Frozen organic aloha corn", "code": "50453201" },
      { "name": "Frozen organic alpine corn", "code": "50453202" },
      { "name": "Frozen organic ambrosia corn", "code": "50453203" },
      { "name": "Frozen organic argent corn", "code": "50453204" },
      { "name": "Frozen organic aspen corn", "code": "50453205" },
      { "name": "Frozen organic avalanche corn", "code": "50453206" },
      { "name": "Frozen organic biqueen corn", "code": "50453207" },
      { "name": "Frozen organic bodacious corn", "code": "50453208" },
      { "name": "Frozen organic butter and sugar corn", "code": "50453209" },
      { "name": "Frozen organic calico belle corn", "code": "50453210" },
      { "name": "Frozen organic camelot corn", "code": "50453211" },
      {
        "name": "Frozen organic challenger crisp \u2018n sweet corn",
        "code": "50453212"
      },
      { "name": "Frozen organic champ corn", "code": "50453213" },
      { "name": "Frozen organic cotton candy corn", "code": "50453214" },
      { "name": "Frozen organic d\u2019artagnan corn", "code": "50453215" },
      { "name": "Frozen organic dazzle corn", "code": "50453216" },
      { "name": "Frozen organic diamonds and gold corn", "code": "50453217" },
      { "name": "Frozen organic divinity corn", "code": "50453218" },
      { "name": "Frozen organic double delight corn", "code": "50453219" },
      { "name": "Frozen organic double gem corn", "code": "50453220" },
      { "name": "Frozen organic earlivee corn", "code": "50453221" },
      { "name": "Frozen organic early xtra sweet corn", "code": "50453222" },
      { "name": "Frozen organic excel corn", "code": "50453223" },
      { "name": "Frozen organic golden cross bantam corn", "code": "50453224" },
      { "name": "Frozen organic honey and cream corn", "code": "50453225" },
      { "name": "Frozen organic honey \u2018n pearl corn", "code": "50453226" },
      { "name": "Frozen organic how sweet it is corn", "code": "50453227" },
      { "name": "Frozen organic hudson corn", "code": "50453228" },
      { "name": "Frozen organic illini gold corn", "code": "50453229" },
      { "name": "Frozen organic illini xtra sweet corn", "code": "50453230" },
      { "name": "Frozen organic incredible corn", "code": "50453231" },
      { "name": "Frozen organic iochief corn", "code": "50453232" },
      { "name": "Frozen organic jubilee corn", "code": "50453233" },
      { "name": "Frozen organic jubilee supersweet corn", "code": "50453234" },
      { "name": "Frozen organic kandy korn corn", "code": "50453235" },
      { "name": "Frozen organic kiss \u2018n tell corn", "code": "50453236" },
      { "name": "Frozen organic lancelot corn", "code": "50453237" },
      { "name": "Frozen organic maple sweet corn", "code": "50453238" },
      { "name": "Frozen organic medley corn", "code": "50453239" },
      { "name": "Frozen organic merlin corn", "code": "50453240" },
      { "name": "Frozen organic miracle corn", "code": "50453241" },
      { "name": "Frozen organic nk-199 corn", "code": "50453242" },
      { "name": "Frozen organic peaches and cream corn", "code": "50453243" },
      { "name": "Frozen organic pearl white corn", "code": "50453244" },
      { "name": "Frozen organic pegasus corn", "code": "50453245" },
      { "name": "Frozen organic phenomenal corn", "code": "50453246" },
      { "name": "Frozen organic platinum lady corn", "code": "50453247" },
      { "name": "Frozen organic precocious corn", "code": "50453248" },
      { "name": "Frozen organic pristine corn", "code": "50453249" },
      { "name": "Frozen organic quickie corn", "code": "50453250" },
      { "name": "Frozen organic radiance corn", "code": "50453251" },
      { "name": "Frozen organic seneca brave corn", "code": "50453252" },
      { "name": "Frozen organic seneca dawn corn", "code": "50453253" },
      { "name": "Frozen organic seneca horizon corn", "code": "50453254" },
      { "name": "Frozen organic seneca starshine corn", "code": "50453255" },
      { "name": "Frozen organic seneca white knight corn", "code": "50453256" },
      { "name": "Frozen organic showcase corn", "code": "50453257" },
      { "name": "Frozen organic silver queen corn", "code": "50453258" },
      { "name": "Frozen organic snowbelle corn", "code": "50453259" },
      { "name": "Frozen organic spring snow corn", "code": "50453260" },
      { "name": "Frozen organic spring treat corn", "code": "50453261" },
      { "name": "Frozen organic sugar and gold corn", "code": "50453262" },
      { "name": "Frozen organic sugar buns corn", "code": "50453263" },
      { "name": "Frozen organic sugar snow corn", "code": "50453264" },
      { "name": "Frozen organic sundance corn", "code": "50453265" },
      { "name": "Frozen organic telstar corn", "code": "50453266" },
      { "name": "Frozen organic terminator corn", "code": "50453267" },
      { "name": "Frozen organic treasure corn", "code": "50453268" },
      { "name": "Frozen organic tuxedo corn", "code": "50453269" },
      { "name": "Frozen organic cresses", "code": "50453300" },
      { "name": "Frozen organic land cress", "code": "50453301" },
      { "name": "Frozen organic nasturtium", "code": "50453302" },
      { "name": "Frozen organic watercress", "code": "50453303" },
      { "name": "Frozen organic wintercress", "code": "50453304" },
      { "name": "Frozen organic cucumbers", "code": "50453400" },
      { "name": "Frozen organic arena cucumbers", "code": "50453401" },
      { "name": "Frozen organic armenian cucumbers", "code": "50453402" },
      { "name": "Frozen organic athene cucumbers", "code": "50453403" },
      {
        "name": "Frozen organic bianco lungo di parigi cucumbers",
        "code": "50453404"
      },
      {
        "name": "Frozen organic burpless tasty green cucumbers",
        "code": "50453405"
      },
      {
        "name": "Frozen organic chicago pickling cucumbers",
        "code": "50453406"
      },
      { "name": "Frozen organic crystal apple cucumbers", "code": "50453407" },
      { "name": "Frozen organic crystal lemon cucumbers", "code": "50453408" },
      { "name": "Frozen organic danimas cucumbers", "code": "50453409" },
      { "name": "Frozen organic gherkin cucumbers", "code": "50453410" },
      { "name": "Frozen organic hokus cucumbers", "code": "50453411" },
      { "name": "Frozen organic japanese cucumbers", "code": "50453412" },
      { "name": "Frozen organic karela cucumbers", "code": "50453413" },
      { "name": "Frozen organic korila cucumbers", "code": "50453414" },
      {
        "name": "Frozen organic long green improved cucumbers",
        "code": "50453415"
      },
      { "name": "Frozen organic marketmore cucumbers", "code": "50453416" },
      { "name": "Frozen organic midget cucumbers", "code": "50453417" },
      {
        "name": "Frozen organic national pickling cucumbers",
        "code": "50453418"
      },
      { "name": "Frozen organic persian cucumbers", "code": "50453419" },
      { "name": "Frozen organic telegraph cucumbers", "code": "50453420" },
      {
        "name": "Frozen organic telegraph improved cucumbers",
        "code": "50453421"
      },
      {
        "name": "Frozen organic vert de massy cornichon cucumbers",
        "code": "50453422"
      },
      { "name": "Frozen organic yamato cucumbers", "code": "50453423" },
      { "name": "Frozen organic eggplants", "code": "50453500" },
      { "name": "Frozen organic bambino eggplants", "code": "50453501" },
      { "name": "Frozen organic black beauty eggplants", "code": "50453502" },
      { "name": "Frozen organic black enorma eggplants", "code": "50453503" },
      { "name": "Frozen organic chinese eggplants", "code": "50453504" },
      { "name": "Frozen organic easter egg eggplants", "code": "50453505" },
      { "name": "Frozen organic filipino eggplants", "code": "50453506" },
      { "name": "Frozen organic florida market eggplants", "code": "50453507" },
      { "name": "Frozen organic indian eggplants", "code": "50453508" },
      { "name": "Frozen organic italian eggplants", "code": "50453509" },
      { "name": "Frozen organic japanese eggplants", "code": "50453510" },
      { "name": "Frozen organic long purple eggplants", "code": "50453511" },
      { "name": "Frozen organic long striped eggplants", "code": "50453512" },
      { "name": "Frozen organic moneymaker eggplants", "code": "50453513" },
      { "name": "Frozen organic ova eggplants", "code": "50453514" },
      { "name": "Frozen organic pea eggplants", "code": "50453515" },
      { "name": "Frozen organic short tom eggplants", "code": "50453516" },
      { "name": "Frozen organic sicilian eggplants", "code": "50453517" },
      { "name": "Frozen organic thai eggplants", "code": "50453518" },
      {
        "name": "Frozen organic violette di firenze eggplants",
        "code": "50453519"
      },
      { "name": "Frozen organic white eggplants", "code": "50453520" },
      { "name": "Frozen organic endives", "code": "50453600" },
      { "name": "Frozen organic brussels witloof endives", "code": "50453601" },
      { "name": "Frozen organic castelfranco endives", "code": "50453602" },
      {
        "name": "Frozen organic catalogna di galatina endives",
        "code": "50453603"
      },
      { "name": "Frozen organic chioggia endives", "code": "50453604" },
      { "name": "Frozen organic grumolo verde endives", "code": "50453605" },
      {
        "name": "Frozen organic large rooted magdeburg endives",
        "code": "50453606"
      },
      {
        "name": "Frozen organic palla rossa zorzi precoce endives",
        "code": "50453607"
      },
      { "name": "Frozen organic radice amare endives", "code": "50453608" },
      { "name": "Frozen organic rossa di treviso endives", "code": "50453609" },
      { "name": "Frozen organic rossa di verona endives", "code": "50453610" },
      { "name": "Frozen organic soncino endives", "code": "50453611" },
      { "name": "Frozen organic sugarhat endives", "code": "50453612" },
      { "name": "Frozen organic verona endives", "code": "50453613" },
      { "name": "Frozen organic witloof zoom endives", "code": "50453614" },
      { "name": "Frozen organic fennels", "code": "50453700" },
      { "name": "Frozen organic cantino fennel", "code": "50453701" },
      { "name": "Frozen organic fino fennel", "code": "50453702" },
      { "name": "Frozen organic herald fennel", "code": "50453703" },
      { "name": "Frozen organic perfection fennel", "code": "50453704" },
      { "name": "Frozen organic sirio fennel", "code": "50453705" },
      { "name": "Frozen organic sweet florence fennel", "code": "50453706" },
      { "name": "Frozen organic tardo fennel", "code": "50453707" },
      { "name": "Frozen organic garlics", "code": "50453800" },
      { "name": "Frozen organic california late garlic", "code": "50453801" },
      { "name": "Frozen organic chinese garlic stems", "code": "50453802" },
      { "name": "Frozen organic garlic chives", "code": "50453803" },
      { "name": "Frozen organic germidor garlic", "code": "50453804" },
      { "name": "Frozen organic long keeper garlic", "code": "50453805" },
      { "name": "Frozen organic ramson garlic", "code": "50453806" },
      { "name": "Frozen organic rocambole garlic", "code": "50453807" },
      { "name": "Frozen organic rose de lautrec garlic", "code": "50453808" },
      { "name": "Frozen organic solent wight garlic", "code": "50453809" },
      { "name": "Frozen organic spanish morado garlic", "code": "50453810" },
      { "name": "Frozen organic venetian/italian garlic", "code": "50453811" },
      { "name": "Frozen organic gourds", "code": "50453900" },
      { "name": "Frozen organic angled loofah", "code": "50453901" },
      { "name": "Frozen organic bitter gourd", "code": "50453902" },
      { "name": "Frozen organic bottle gourd", "code": "50453903" },
      { "name": "Frozen organic calabash gourds", "code": "50453904" },
      { "name": "Frozen organic fuzzy/hairy melon", "code": "50453905" },
      { "name": "Frozen organic musky gourd", "code": "50453906" },
      { "name": "Frozen organic smooth loofah", "code": "50453907" },
      { "name": "Frozen organic snake gourd", "code": "50453908" },
      { "name": "Frozen organic spiny bitter gourd", "code": "50453909" },
      { "name": "Frozen organic tinda gourds", "code": "50453910" },
      { "name": "Frozen organic tindoori gourds", "code": "50453911" },
      { "name": "Frozen organic green peas", "code": "50454000" },
      { "name": "Frozen organic china peas", "code": "50454001" },
      { "name": "Frozen organic english peas", "code": "50454002" },
      { "name": "Frozen organic garden peas", "code": "50454003" },
      { "name": "Frozen organic snow peas", "code": "50454004" },
      { "name": "Frozen organic sugar snap peas", "code": "50454005" },
      { "name": "Frozen organic herbs", "code": "50454100" },
      { "name": "Frozen organic basil", "code": "50454101" },
      { "name": "Frozen organic bay leaves", "code": "50454102" },
      { "name": "Frozen organic borage", "code": "50454103" },
      { "name": "Frozen organic caraway", "code": "50454104" },
      { "name": "Frozen organic chervil", "code": "50454105" },
      { "name": "Frozen organic cilantro", "code": "50454106" },
      { "name": "Frozen organic cipolinos", "code": "50454107" },
      { "name": "Frozen organic curry leaves", "code": "50454108" },
      { "name": "Frozen organic dill", "code": "50454109" },
      { "name": "Frozen organic epazote", "code": "50454110" },
      { "name": "Frozen organic fenugreek", "code": "50454111" },
      { "name": "Frozen organic lemon grass", "code": "50454112" },
      { "name": "Frozen organic marjoram", "code": "50454113" },
      { "name": "Frozen organic mint", "code": "50454114" },
      { "name": "Frozen organic oregano", "code": "50454115" },
      { "name": "Frozen organic papalo", "code": "50454116" },
      { "name": "Frozen organic pepicha", "code": "50454117" },
      { "name": "Frozen organic perilla", "code": "50454118" },
      { "name": "Frozen organic recao", "code": "50454119" },
      { "name": "Frozen organic rosemary", "code": "50454120" },
      { "name": "Frozen organic sage", "code": "50454121" },
      { "name": "Frozen organic salsify", "code": "50454122" },
      { "name": "Frozen organic savory", "code": "50454123" },
      { "name": "Frozen organic tarragon", "code": "50454124" },
      { "name": "Frozen organic thyme", "code": "50454125" },
      { "name": "Frozen organic tumeric", "code": "50454126" },
      { "name": "Frozen organic verdulaga", "code": "50454127" },
      { "name": "Frozen organic kale", "code": "50454200" },
      { "name": "Frozen organic curly kale", "code": "50454201" },
      { "name": "Frozen organic collard greens", "code": "50454202" },
      { "name": "Frozen organic kohlrabi", "code": "50454300" },
      { "name": "Frozen organic azur star kohlrabi", "code": "50454301" },
      { "name": "Frozen organic green vienna kohlrabi", "code": "50454302" },
      { "name": "Frozen organic lanro kohlrabi", "code": "50454303" },
      { "name": "Frozen organic purple vienna kohlrabi", "code": "50454304" },
      { "name": "Frozen organic rowel trero kohlrabi", "code": "50454305" },
      { "name": "Frozen organic white vienna kohlrabi", "code": "50454306" },
      { "name": "Frozen organic leeks", "code": "50454400" },
      { "name": "Frozen organic autumn giant-cobra leeks", "code": "50454401" },
      { "name": "Frozen organic autumn mammoth 2 leeks", "code": "50454402" },
      { "name": "Frozen organic bleu de solaise leeks", "code": "50454403" },
      { "name": "Frozen organic cortina leeks", "code": "50454404" },
      { "name": "Frozen organic prelina leeks", "code": "50454405" },
      { "name": "Frozen organic wild leek ramp", "code": "50454406" },
      { "name": "Frozen organic lentils", "code": "50454500" },
      { "name": "Frozen organic beluga lentils", "code": "50454501" },
      { "name": "Frozen organic french green lentils", "code": "50454502" },
      { "name": "Frozen organic green lentils", "code": "50454503" },
      { "name": "Frozen organic petite crimson lentils", "code": "50454504" },
      { "name": "Frozen organic spanish pardina lentils", "code": "50454505" },
      { "name": "Frozen organic split red lentils", "code": "50454506" },
      { "name": "Frozen organic split yellow lentils", "code": "50454507" },
      { "name": "Frozen organic tarahumara pinks lentils", "code": "50454508" },
      { "name": "Frozen organic lettuces", "code": "50454600" },
      { "name": "Frozen organic bibb lettuce", "code": "50454601" },
      { "name": "Frozen organic boston lettuce", "code": "50454602" },
      { "name": "Frozen organic frisee lettuce", "code": "50454603" },
      { "name": "Frozen organic lolla rossa lettuce", "code": "50454604" },
      { "name": "Frozen organic mesculin mix lettuce", "code": "50454605" },
      { "name": "Frozen organic mizuna lettuce", "code": "50454606" },
      { "name": "Frozen organic red leaf lettuce", "code": "50454607" },
      { "name": "Frozen organic red oak leaf lettuce", "code": "50454608" },
      { "name": "Frozen organic ruby romaine lettuce", "code": "50454609" },
      { "name": "Frozen organic baby red romaine lettuce", "code": "50454610" },
      { "name": "Frozen organic butterhead lettuces", "code": "50454611" },
      { "name": "Frozen organic chinese lettuces", "code": "50454612" },
      { "name": "Frozen organic crisphead lettuces", "code": "50454613" },
      { "name": "Frozen organic green leaf lettuce", "code": "50454614" },
      { "name": "Frozen organic iceberg lettuce", "code": "50454615" },
      { "name": "Frozen organic lamb\u2019s lettuces", "code": "50454616" },
      { "name": "Frozen organic looseleaf lettuces", "code": "50454617" },
      { "name": "Frozen organic mache lettuce", "code": "50454618" },
      { "name": "Frozen organic red boston lettuce", "code": "50454619" },
      { "name": "Frozen organic red headed lettuces", "code": "50454620" },
      { "name": "Frozen organic romaine lettuces", "code": "50454621" },
      {
        "name": "Frozen organic russian red mustard lettuce",
        "code": "50454622"
      },
      { "name": "Frozen organic tatsoi lettuce", "code": "50454623" },
      { "name": "Frozen organic malanga", "code": "50454700" },
      { "name": "Frozen organic amarilla malanga", "code": "50454701" },
      { "name": "Frozen organic blanca malanga", "code": "50454702" },
      { "name": "Frozen organic coco malanga", "code": "50454703" },
      { "name": "Frozen organic eddoes malanga", "code": "50454704" },
      { "name": "Frozen organic islena malanga", "code": "50454705" },
      { "name": "Frozen organic lila malanga", "code": "50454706" },
      { "name": "Frozen organic mushrooms", "code": "50454800" },
      { "name": "Frozen organic black trumpet mushrooms", "code": "50454801" },
      { "name": "Frozen organic brown mushrooms", "code": "50454802" },
      { "name": "Frozen organic champinion mushrooms", "code": "50454803" },
      { "name": "Frozen organic chanterelle mushrooms", "code": "50454804" },
      { "name": "Frozen organic cremini mushrooms", "code": "50454805" },
      { "name": "Frozen organic enoki mushrooms", "code": "50454806" },
      { "name": "Frozen organic hedge hog mushrooms", "code": "50454807" },
      {
        "name": "Frozen organic hen of the woods mushrooms",
        "code": "50454808"
      },
      { "name": "Frozen organic lobster mushrooms", "code": "50454809" },
      { "name": "Frozen organic morels mushrooms", "code": "50454810" },
      { "name": "Frozen organic oyster mushrooms", "code": "50454811" },
      { "name": "Frozen organic pleurotus mushrooms", "code": "50454812" },
      { "name": "Frozen organic pompom mushrooms", "code": "50454813" },
      { "name": "Frozen organic porcieni mushrooms", "code": "50454814" },
      { "name": "Frozen organic portobella mushrooms", "code": "50454815" },
      { "name": "Frozen organic shiitake mushrooms", "code": "50454816" },
      { "name": "Frozen organic shimeji mushrooms", "code": "50454817" },
      { "name": "Frozen organic st george's mushrooms", "code": "50454818" },
      { "name": "Frozen organic white mushrooms", "code": "50454819" },
      { "name": "Frozen organic white trumpet mushrooms", "code": "50454820" },
      { "name": "Frozen organic woodear mushrooms", "code": "50454821" },
      { "name": "Frozen organic mustards", "code": "50454900" },
      { "name": "Frozen organic bamboo mustard", "code": "50454901" },
      { "name": "Frozen organic garlic mustard", "code": "50454902" },
      { "name": "Frozen organic giantleafed mustard", "code": "50454903" },
      { "name": "Frozen organic red in snow mustard", "code": "50454904" },
      { "name": "Frozen organic southern mustard", "code": "50454905" },
      { "name": "Frozen organic wrapped heart mustard", "code": "50454906" },
      { "name": "Frozen organic nightshades", "code": "50455000" },
      { "name": "Frozen organic chinese lantern", "code": "50455001" },
      { "name": "Frozen organic garden huckleberry", "code": "50455002" },
      { "name": "Frozen organic naranjilla", "code": "50455003" },
      { "name": "Frozen organic tomatillo", "code": "50455004" },
      { "name": "Frozen organic okras", "code": "50455100" },
      { "name": "Frozen organic artist okra", "code": "50455101" },
      { "name": "Frozen organic burgundy okra", "code": "50455102" },
      { "name": "Frozen organic clemson spineless okra", "code": "50455103" },
      {
        "name": "Frozen organic dwarf green long pod okra",
        "code": "50455104"
      },
      {
        "name": "Frozen organic mammoth spineless long pod okra",
        "code": "50455105"
      },
      { "name": "Frozen organic red velvet okra", "code": "50455106" },
      {
        "name": "Frozen organic star of david heirloom okra",
        "code": "50455107"
      },
      { "name": "Frozen organic onions", "code": "50455200" },
      { "name": "Frozen organic albion onions", "code": "50455201" },
      { "name": "Frozen organic alisa craig onions", "code": "50455202" },
      { "name": "Frozen organic boiling onions", "code": "50455203" },
      { "name": "Frozen organic buffalo onions", "code": "50455204" },
      { "name": "Frozen organic bulb onions", "code": "50455205" },
      { "name": "Frozen organic creaming onions", "code": "50455206" },
      {
        "name": "Frozen organic express yellow o-x onions",
        "code": "50455207"
      },
      { "name": "Frozen organic kelsae onions", "code": "50455208" },
      {
        "name": "Frozen organic marshalls giant fen globe onions",
        "code": "50455209"
      },
      { "name": "Frozen organic pearl onions", "code": "50455210" },
      { "name": "Frozen organic red baron onions", "code": "50455211" },
      { "name": "Frozen organic red onions", "code": "50455212" },
      { "name": "Frozen organic rijnsberger onions", "code": "50455213" },
      {
        "name": "Frozen organic senshyu semi-globe yellow onions",
        "code": "50455214"
      },
      { "name": "Frozen organic sturon onions", "code": "50455215" },
      { "name": "Frozen organic stuttgarter giant onions", "code": "50455216" },
      { "name": "Frozen organic sweet onions", "code": "50455217" },
      {
        "name": "Frozen organic torpedo or red italian onions",
        "code": "50455218"
      },
      { "name": "Frozen organic red storage onions", "code": "50455219" },
      { "name": "Frozen organic white storage onions", "code": "50455220" },
      { "name": "Frozen organic yellow storage onions", "code": "50455221" },
      { "name": "Frozen organic peanuts", "code": "50455300" },
      {
        "name": "Frozen organic bambarra groundnut peanuts",
        "code": "50455301"
      },
      { "name": "Frozen organic florunner peanuts", "code": "50455302" },
      {
        "name": "Frozen organic hausa/kersting's ground nut peanuts",
        "code": "50455303"
      },
      { "name": "Frozen organic spanish peanuts", "code": "50455304" },
      { "name": "Frozen organic valencia peanuts", "code": "50455305" },
      { "name": "Frozen organic virginia peanuts", "code": "50455306" },
      { "name": "Frozen organic peas", "code": "50455400" },
      { "name": "Frozen organic purple hull peas", "code": "50455401" },
      { "name": "Frozen organic pinkeye peas", "code": "50455402" },
      { "name": "Frozen organic crowder peas", "code": "50455403" },
      { "name": "Frozen organic white acre peas", "code": "50455404" },
      { "name": "Frozen organic blackeyed peas", "code": "50455405" },
      { "name": "Frozen organic zipper cream peas", "code": "50455406" },
      { "name": "Frozen organic peppers", "code": "50455500" },
      { "name": "Frozen organic ajies peppers", "code": "50455501" },
      { "name": "Frozen organic arbol peppers", "code": "50455502" },
      { "name": "Frozen organic cheese peppers", "code": "50455503" },
      { "name": "Frozen organic chilaca peppers", "code": "50455504" },
      { "name": "Frozen organic cubanelles peppers", "code": "50455505" },
      { "name": "Frozen organic fresno peppers", "code": "50455506" },
      { "name": "Frozen organic kapia peppers", "code": "50455507" },
      { "name": "Frozen organic korean peppers", "code": "50455508" },
      { "name": "Frozen organic manzano peppers", "code": "50455509" },
      { "name": "Frozen organic melrose peppers", "code": "50455510" },
      { "name": "Frozen organic yellow chile peppers", "code": "50455511" },
      { "name": "Frozen organic aji dulces peppers", "code": "50455512" },
      { "name": "Frozen organic anaheim peppers", "code": "50455513" },
      { "name": "Frozen organic ancho peppers", "code": "50455514" },
      { "name": "Frozen organic bell peppers", "code": "50455515" },
      { "name": "Frozen organic cascabel peppers", "code": "50455516" },
      { "name": "Frozen organic cayenne peppers", "code": "50455517" },
      { "name": "Frozen organic cherry hots peppers", "code": "50455518" },
      { "name": "Frozen organic chiltecpin peppers", "code": "50455519" },
      { "name": "Frozen organic finger hot peppers", "code": "50455520" },
      { "name": "Frozen organic guajillo peppers", "code": "50455521" },
      { "name": "Frozen organic guerro peppers", "code": "50455522" },
      { "name": "Frozen organic habanero peppers", "code": "50455523" },
      { "name": "Frozen organic hungarian wax peppers", "code": "50455524" },
      { "name": "Frozen organic jalapeno peppers", "code": "50455525" },
      { "name": "Frozen organic long hot peppers", "code": "50455526" },
      { "name": "Frozen organic mirasol peppers", "code": "50455527" },
      { "name": "Frozen organic pasilla peppers", "code": "50455528" },
      { "name": "Frozen organic peperoncini peppers", "code": "50455529" },
      { "name": "Frozen organic pequin peppers", "code": "50455530" },
      { "name": "Frozen organic pimiento peppers", "code": "50455531" },
      { "name": "Frozen organic poblano peppers", "code": "50455532" },
      { "name": "Frozen organic scotch bonnet peppers", "code": "50455533" },
      { "name": "Frozen organic serrano peppers", "code": "50455534" },
      { "name": "Frozen organic tabasco peppers", "code": "50455535" },
      { "name": "Frozen organic tai peppers", "code": "50455536" },
      { "name": "Frozen organic tepin peppers", "code": "50455537" },
      { "name": "Frozen organic potatoes", "code": "50455600" },
      { "name": "Frozen organic long white potatoes", "code": "50455601" },
      { "name": "Frozen organic round white potatoes", "code": "50455602" },
      { "name": "Frozen organic round red potatoes", "code": "50455603" },
      { "name": "Frozen organic russet potatoes", "code": "50455604" },
      { "name": "Frozen organic purple potatoes", "code": "50455605" },
      { "name": "Frozen organic yellow potatoes", "code": "50455606" },
      { "name": "Frozen organic new potatoes", "code": "50455607" },
      { "name": "Frozen organic specialty potatoes", "code": "50455608" },
      { "name": "Frozen organic rutabagas", "code": "50455700" },
      { "name": "Frozen organic acme rutabagas", "code": "50455701" },
      { "name": "Frozen organic angela rutabagas", "code": "50455702" },
      { "name": "Frozen organic best of all rutabagas", "code": "50455703" },
      { "name": "Frozen organic marian rutabagas", "code": "50455704" },
      { "name": "Frozen organic sea vegetables", "code": "50455800" },
      { "name": "Frozen organic agar-agar", "code": "50455801" },
      { "name": "Frozen organic arame", "code": "50455802" },
      { "name": "Frozen organic dulse", "code": "50455803" },
      { "name": "Frozen organic haricot vert de mer", "code": "50455804" },
      { "name": "Frozen organic hijiki", "code": "50455805" },
      { "name": "Frozen organic irish moss", "code": "50455806" },
      { "name": "Frozen organic kelp", "code": "50455807" },
      { "name": "Frozen organic laver", "code": "50455808" },
      { "name": "Frozen organic nori", "code": "50455809" },
      { "name": "Frozen organic red algae", "code": "50455810" },
      { "name": "Frozen organic sea kale", "code": "50455811" },
      { "name": "Frozen organic sea lettuce", "code": "50455812" },
      { "name": "Frozen organic seaweeds", "code": "50455813" },
      { "name": "Frozen organic spirulina", "code": "50455814" },
      { "name": "Frozen organic susabi nori", "code": "50455815" },
      { "name": "Frozen organic wakame", "code": "50455816" },
      { "name": "Frozen organic shallots", "code": "50455900" },
      { "name": "Frozen organic atlantic shallots", "code": "50455901" },
      { "name": "Frozen organic creation shallots", "code": "50455902" },
      {
        "name": "Frozen organic drittler white nest shallots",
        "code": "50455903"
      },
      {
        "name": "Frozen organic giant yellow improved shallots",
        "code": "50455904"
      },
      { "name": "Frozen organic golden gourmet shallots", "code": "50455905" },
      {
        "name": "Frozen organic grise de bagnolet shallots",
        "code": "50455906"
      },
      { "name": "Frozen organic hative de niort shallots", "code": "50455907" },
      { "name": "Frozen organic pikant shallots", "code": "50455908" },
      { "name": "Frozen organic red potato onions", "code": "50455909" },
      { "name": "Frozen organic sante shallots", "code": "50455910" },
      { "name": "Frozen organic topper shallots", "code": "50455911" },
      { "name": "Frozen organic sorrels", "code": "50456000" },
      { "name": "Frozen organic dock sorrel", "code": "50456001" },
      { "name": "Frozen organic garden sorrel", "code": "50456002" },
      { "name": "Frozen organic sheep sorrel", "code": "50456003" },
      { "name": "Frozen organic wood sorrel", "code": "50456004" },
      { "name": "Frozen organic spinaches", "code": "50456100" },
      { "name": "Frozen organic america spinach", "code": "50456101" },
      { "name": "Frozen organic bloomsdale spinach", "code": "50456102" },
      { "name": "Frozen organic giant winter spinach", "code": "50456103" },
      { "name": "Frozen organic horenso spinach", "code": "50456104" },
      { "name": "Frozen organic iceplant spinach", "code": "50456105" },
      { "name": "Frozen organic lamb's quarters spinach", "code": "50456106" },
      { "name": "Frozen organic malabar spinach", "code": "50456107" },
      { "name": "Frozen organic medania spinach", "code": "50456108" },
      { "name": "Frozen organic new zealand spinach", "code": "50456109" },
      { "name": "Frozen organic orach spinach", "code": "50456110" },
      { "name": "Frozen organic savoy spinach", "code": "50456111" },
      { "name": "Frozen organic sigmaleaf spinach", "code": "50456112" },
      { "name": "Frozen organic space spinach", "code": "50456113" },
      { "name": "Frozen organic trinidad spinach", "code": "50456114" },
      { "name": "Frozen organic water spinach", "code": "50456115" },
      { "name": "Frozen organic wild spinach", "code": "50456116" },
      {
        "name": "Frozen organic summer squashes and summer pumpkins",
        "code": "50456200"
      },
      { "name": "Frozen organic boston marrow squash", "code": "50456201" },
      { "name": "Frozen organic butternut squash", "code": "50456202" },
      { "name": "Frozen organic costata romanesca squash", "code": "50456203" },
      { "name": "Frozen organic crookneck squash", "code": "50456204" },
      { "name": "Frozen organic cucuzza squash", "code": "50456205" },
      { "name": "Frozen organic delicata squash", "code": "50456206" },
      { "name": "Frozen organic delicious squash", "code": "50456207" },
      {
        "name": "Frozen organic early golden summer crookneck squash",
        "code": "50456208"
      },
      {
        "name": "Frozen organic early prolific straight neck squash",
        "code": "50456209"
      },
      { "name": "Frozen organic gold squash", "code": "50456210" },
      { "name": "Frozen organic jack be little squash", "code": "50456211" },
      { "name": "Frozen organic kentucky field squash", "code": "50456212" },
      { "name": "Frozen organic marrow squash", "code": "50456213" },
      { "name": "Frozen organic middle eastern squash", "code": "50456214" },
      { "name": "Frozen organic miniature squash", "code": "50456215" },
      { "name": "Frozen organic orangetti squash", "code": "50456216" },
      { "name": "Frozen organic pattypan squash", "code": "50456217" },
      { "name": "Frozen organic rondini squash", "code": "50456218" },
      { "name": "Frozen organic round squash", "code": "50456219" },
      { "name": "Frozen organic spaghetti squash", "code": "50456220" },
      { "name": "Frozen organic stripetti squash", "code": "50456221" },
      { "name": "Frozen organic sugar loaf squash", "code": "50456222" },
      { "name": "Frozen organic sweet dumpling squash", "code": "50456223" },
      { "name": "Frozen organic triple treat squash", "code": "50456224" },
      { "name": "Frozen organic waltham butternut squash", "code": "50456225" },
      {
        "name": "Frozen organic yellow bush scallop squash",
        "code": "50456226"
      },
      {
        "name": "Frozen organic yellow straightneck squash",
        "code": "50456227"
      },
      { "name": "Frozen organic zephyr squash", "code": "50456228" },
      { "name": "Frozen organic zucchini squash", "code": "50456229" },
      { "name": "Frozen organic sweet potatoes", "code": "50456300" },
      {
        "name": "Frozen organic beauregard sweet potatoes",
        "code": "50456301"
      },
      {
        "name": "Frozen organic centennial sweet potatoes",
        "code": "50456302"
      },
      { "name": "Frozen organic diane sweet potatoes", "code": "50456303" },
      { "name": "Frozen organic garnet sweet potatoes", "code": "50456304" },
      {
        "name": "Frozen organic georgia red sweet potatoes",
        "code": "50456305"
      },
      {
        "name": "Frozen organic goldensweet sweet potatoes",
        "code": "50456306"
      },
      { "name": "Frozen organic hanna sweet potatoes", "code": "50456307" },
      { "name": "Frozen organic japanese sweet potatoes", "code": "50456308" },
      { "name": "Frozen organic jersey sweet potatoes", "code": "50456309" },
      { "name": "Frozen organic jewel sweet potatoes", "code": "50456310" },
      {
        "name": "Frozen organic maryland red sweet potatoes",
        "code": "50456311"
      },
      { "name": "Frozen organic nemagold sweet potatoes", "code": "50456312" },
      { "name": "Frozen organic o'henry sweet potatoes", "code": "50456313" },
      { "name": "Frozen organic okinawan sweet potatoes", "code": "50456314" },
      { "name": "Frozen organic orange sweet potatoes", "code": "50456315" },
      { "name": "Frozen organic oriental sweet potatoes", "code": "50456316" },
      {
        "name": "Frozen organic red jersey sweet potatoes",
        "code": "50456317"
      },
      { "name": "Frozen organic red mar sweet potatoes", "code": "50456318" },
      { "name": "Frozen organic redglow sweet potatoes", "code": "50456319" },
      {
        "name": "Frozen organic yellow jersey sweet potatoes",
        "code": "50456320"
      },
      { "name": "Frozen organic tomatoes", "code": "50456400" },
      { "name": "Frozen organic ailsa craig tomatoes", "code": "50456401" },
      { "name": "Frozen organic alicante tomatoes", "code": "50456402" },
      { "name": "Frozen organic black plum tomatoes", "code": "50456403" },
      { "name": "Frozen organic brandywine tomatoes", "code": "50456404" },
      { "name": "Frozen organic cherry belle tomatoes", "code": "50456405" },
      { "name": "Frozen organic cherry tomatoes", "code": "50456406" },
      { "name": "Frozen organic delicious tomatoes", "code": "50456407" },
      { "name": "Frozen organic dombito tomatoes", "code": "50456408" },
      {
        "name": "Frozen organic gardener's delight tomatoes",
        "code": "50456409"
      },
      { "name": "Frozen organic grape tomatoes", "code": "50456410" },
      { "name": "Frozen organic green tomatoes", "code": "50456411" },
      { "name": "Frozen organic marmande super tomatoes", "code": "50456412" },
      {
        "name": "Frozen organic marvel striped traditional tomatoes",
        "code": "50456413"
      },
      { "name": "Frozen organic minibel tomatoes", "code": "50456414" },
      { "name": "Frozen organic oaxacan pink tomatoes", "code": "50456415" },
      { "name": "Frozen organic red alert tomatoes", "code": "50456416" },
      { "name": "Frozen organic roma vf tomatoes", "code": "50456417" },
      { "name": "Frozen organic san marzano tomatoes", "code": "50456418" },
      { "name": "Frozen organic shirley tomatoes", "code": "50456419" },
      { "name": "Frozen organic siberia tomato tomatoes", "code": "50456420" },
      { "name": "Frozen organic super beefsteak tomatoes", "code": "50456421" },
      { "name": "Frozen organic tigerella tomatoes", "code": "50456422" },
      { "name": "Frozen organic tiny tim tomatoes", "code": "50456423" },
      { "name": "Frozen organic tumbler tomatoes", "code": "50456424" },
      { "name": "Frozen organic yellow cocktail tomatoes", "code": "50456425" },
      {
        "name": "Frozen organic yellow pear-shaped tomatoes",
        "code": "50456426"
      },
      {
        "name": "Frozen organic yellow perfection tomatoes",
        "code": "50456427"
      },
      { "name": "Frozen organic turnip greens", "code": "50456500" },
      { "name": "Frozen organic green globe turnips", "code": "50456501" },
      { "name": "Frozen organic golden ball turnips", "code": "50456502" },
      {
        "name": "Frozen organic manchester market turnips",
        "code": "50456503"
      },
      { "name": "Frozen organic purple top milan turnips", "code": "50456504" },
      { "name": "Frozen organic purple top white turnips", "code": "50456505" },
      { "name": "Frozen organic snowball turnips", "code": "50456506" },
      { "name": "Frozen organic tokyo turnip", "code": "50456507" },
      { "name": "Frozen organic tokyo cross turnips", "code": "50456508" },
      {
        "name": "Frozen organic winter squashes and winter pumpkins",
        "code": "50456600"
      },
      { "name": "Frozen organic acorn squash", "code": "50456601" },
      { "name": "Frozen organic atlantic giant squash", "code": "50456602" },
      { "name": "Frozen organic banana pink squash", "code": "50456603" },
      { "name": "Frozen organic big max squash", "code": "50456604" },
      { "name": "Frozen organic calabaza squash", "code": "50456605" },
      { "name": "Frozen organic carnival squash", "code": "50456606" },
      { "name": "Frozen organic cheese pumpkin", "code": "50456607" },
      { "name": "Frozen organic crown prince squash", "code": "50456608" },
      { "name": "Frozen organic curcibita squash", "code": "50456609" },
      { "name": "Frozen organic cushaw squash", "code": "50456610" },
      { "name": "Frozen organic giant pumpkin squash", "code": "50456611" },
      { "name": "Frozen organic hubbard squash", "code": "50456612" },
      { "name": "Frozen organic jarrahdale squash", "code": "50456613" },
      { "name": "Frozen organic kabocha squash", "code": "50456614" },
      { "name": "Frozen organic queensland blue squash", "code": "50456615" },
      {
        "name": "Frozen organic rouge vif d'etampes squash",
        "code": "50456616"
      },
      { "name": "Frozen organic turks turban squash", "code": "50456617" },
      { "name": "Frozen organic valenciano squash", "code": "50456618" },
      { "name": "Frozen organic warted hubbard squash", "code": "50456619" },
      {
        "name": "Frozen organic whangaparoa crown pumpkin",
        "code": "50456620"
      },
      { "name": "Frozen organic yams", "code": "50456700" },
      { "name": "Frozen organic african bitter yams", "code": "50456701" },
      { "name": "Frozen organic asiatic bitter yams", "code": "50456702" },
      { "name": "Frozen organic chinese yams", "code": "50456703" },
      { "name": "Frozen organic globe yams", "code": "50456704" },
      { "name": "Frozen organic greater yams", "code": "50456705" },
      { "name": "Frozen organic japanese yams", "code": "50456706" },
      { "name": "Frozen organic lesser yams", "code": "50456707" },
      { "name": "Frozen organic potato yams", "code": "50456708" },
      { "name": "Frozen organic white guinea yams", "code": "50456709" },
      { "name": "Frozen organic yellow guinea yams", "code": "50456710" },
      { "name": "Frozen organic nominant vegetables", "code": "50456800" },
      { "name": "Frozen organic alfalfa", "code": "50456801" },
      { "name": "Frozen organic aloe leaves", "code": "50456802" },
      { "name": "Frozen organic apio", "code": "50456803" },
      { "name": "Frozen organic arrow root", "code": "50456804" },
      { "name": "Frozen organic arrowhead", "code": "50456805" },
      { "name": "Frozen organic arugula", "code": "50456806" },
      { "name": "Frozen organic arum", "code": "50456807" },
      { "name": "Frozen organic bamboo shoots", "code": "50456808" },
      { "name": "Frozen organic banana leaves", "code": "50456809" },
      { "name": "Frozen organic batatas", "code": "50456810" },
      { "name": "Frozen organic bean sprouts", "code": "50456811" },
      { "name": "Frozen organic beet tops", "code": "50456812" },
      { "name": "Frozen organic bittermelon", "code": "50456813" },
      { "name": "Frozen organic caperberries", "code": "50456814" },
      { "name": "Frozen organic carob", "code": "50456815" },
      { "name": "Frozen organic cha-om", "code": "50456816" },
      { "name": "Frozen organic chaoyotes", "code": "50456817" },
      { "name": "Frozen organic chickpeas", "code": "50456818" },
      { "name": "Frozen organic chrysanthemum greens", "code": "50456819" },
      { "name": "Frozen organic dandelion greens", "code": "50456820" },
      { "name": "Frozen organic dandelions", "code": "50456821" },
      { "name": "Frozen organic dasheen", "code": "50456822" },
      { "name": "Frozen organic dau mue or pea tips", "code": "50456823" },
      { "name": "Frozen organic diakon", "code": "50456824" },
      { "name": "Frozen organic donqua", "code": "50456825" },
      { "name": "Frozen organic fiddlehead ferns", "code": "50456826" },
      { "name": "Frozen organic gai choy", "code": "50456827" },
      { "name": "Frozen organic gailon", "code": "50456828" },
      { "name": "Frozen organic galanga", "code": "50456829" },
      { "name": "Frozen organic ginger root", "code": "50456830" },
      { "name": "Frozen organic gobo", "code": "50456831" },
      { "name": "Frozen organic hop sprouts", "code": "50456832" },
      { "name": "Frozen organic horseradish", "code": "50456833" },
      { "name": "Frozen organic jicama", "code": "50456834" },
      { "name": "Frozen organic kudzu", "code": "50456835" },
      { "name": "Frozen organic lily bulb", "code": "50456836" },
      { "name": "Frozen organic linkok", "code": "50456837" },
      { "name": "Frozen organic lo bok", "code": "50456838" },
      { "name": "Frozen organic long beans", "code": "50456839" },
      { "name": "Frozen organic lotus root", "code": "50456840" },
      { "name": "Frozen organic maguey leaves", "code": "50456841" },
      { "name": "Frozen organic mallows", "code": "50456842" },
      { "name": "Frozen organic mamey sapote", "code": "50456843" },
      { "name": "Frozen organic moap", "code": "50456844" },
      { "name": "Frozen organic moo", "code": "50456845" },
      { "name": "Frozen organic moqua", "code": "50456846" },
      { "name": "Frozen organic opos", "code": "50456847" },
      { "name": "Frozen organic palm hearts", "code": "50456848" },
      { "name": "Frozen organic paprika", "code": "50456849" },
      { "name": "Frozen organic purslane", "code": "50456850" },
      { "name": "Frozen organic raddichios", "code": "50456851" },
      { "name": "Frozen organic sinquas", "code": "50456852" },
      { "name": "Frozen organic soybeans", "code": "50456853" },
      { "name": "Frozen organic spoonwart", "code": "50456854" },
      { "name": "Frozen organic tassle grape-hyacinth", "code": "50456855" },
      { "name": "Frozen organic taro", "code": "50456856" },
      { "name": "Frozen organic taro leaf", "code": "50456857" },
      { "name": "Frozen organic taro shoot", "code": "50456858" },
      { "name": "Frozen organic tepeguaje", "code": "50456859" },
      { "name": "Frozen organic tendergreen", "code": "50456860" },
      { "name": "Frozen organic tindora", "code": "50456861" },
      { "name": "Frozen organic tree onion", "code": "50456862" },
      { "name": "Frozen organic udo", "code": "50456863" },
      { "name": "Frozen organic water chestnuts", "code": "50456864" },
      { "name": "Frozen organic yampi", "code": "50456865" },
      { "name": "Frozen organic yautia", "code": "50456866" },
      { "name": "Frozen organic yu choy", "code": "50456867" },
      { "name": "Frozen organic yuca", "code": "50456868" },
      { "name": "Frozen organic sugar peas", "code": "50456900" },
      { "name": "Frozen organic bikini peas", "code": "50456901" },
      { "name": "Frozen organic cavalier peas", "code": "50456902" },
      { "name": "Frozen organic daisy peas", "code": "50456903" },
      { "name": "Frozen organic darfon peas", "code": "50456904" },
      { "name": "Frozen organic early onward peas", "code": "50456905" },
      { "name": "Frozen organic feltham first peas", "code": "50456906" },
      { "name": "Frozen organic hurst green shaft peas", "code": "50456907" },
      { "name": "Frozen organic oregon sugar pod peas", "code": "50456908" },
      { "name": "Frozen organic prince albert peas", "code": "50456909" },
      { "name": "Frozen organic reuzensuiker peas", "code": "50456910" },
      { "name": "Canned or jarred vegetables", "code": "50460000" },
      { "name": "Canned or jarred artichokes", "code": "50461500" },
      { "name": "Canned or jarred brittany artichokes", "code": "50461501" },
      { "name": "Canned or jarred catanese artichokes", "code": "50461502" },
      { "name": "Canned or jarred french artichokes", "code": "50461503" },
      { "name": "Canned or jarred green globe artichokes", "code": "50461504" },
      {
        "name": "Canned or jarred gros camus de bretagne artichokes",
        "code": "50461505"
      },
      { "name": "Canned or jarred midi artichokes", "code": "50461506" },
      {
        "name": "Canned or jarred purple globe artichokes",
        "code": "50461507"
      },
      {
        "name": "Canned or jarred purple sicilian artichokes",
        "code": "50461508"
      },
      { "name": "Canned or jarred romanesco artichokes", "code": "50461509" },
      {
        "name": "Canned or jarred spinoso sardo artichokes",
        "code": "50461510"
      },
      {
        "name": "Canned or jarred vert de laon artichokes",
        "code": "50461511"
      },
      {
        "name": "Canned or jarred violetta di chioggia artichokes",
        "code": "50461512"
      },
      {
        "name": "Canned or jarred violetto di toscana artichokes",
        "code": "50461513"
      },
      { "name": "Canned or jarred asparagus", "code": "50461600" },
      {
        "name": "Canned or jarred connover's colossal asparagus",
        "code": "50461601"
      },
      { "name": "Canned or jarred franklin asparagus", "code": "50461602" },
      {
        "name": "Canned or jarred giant mammoth asparagus",
        "code": "50461603"
      },
      { "name": "Canned or jarred lucullus asparagus", "code": "50461604" },
      {
        "name": "Canned or jarred martha washington asparagus",
        "code": "50461605"
      },
      { "name": "Canned or jarred avocados", "code": "50461700" },
      { "name": "Canned or jarred ajax b-7 avocados", "code": "50461701" },
      { "name": "Canned or jarred arue avocados", "code": "50461702" },
      { "name": "Canned or jarred bacon avocados", "code": "50461703" },
      { "name": "Canned or jarred benik avocados", "code": "50461704" },
      { "name": "Canned or jarred bernecker avocados", "code": "50461705" },
      { "name": "Canned or jarred beta avocados", "code": "50461706" },
      { "name": "Canned or jarred biondo avocados", "code": "50461707" },
      { "name": "Canned or jarred black prince avocados", "code": "50461708" },
      { "name": "Canned or jarred blair avocados", "code": "50461709" },
      { "name": "Canned or jarred blair booth avocados", "code": "50461710" },
      { "name": "Canned or jarred booth 1 avocados", "code": "50461711" },
      { "name": "Canned or jarred booth 3 avocados", "code": "50461712" },
      { "name": "Canned or jarred booth 5 avocados", "code": "50461713" },
      { "name": "Canned or jarred booth 7 avocados", "code": "50461714" },
      { "name": "Canned or jarred booth 8 avocados", "code": "50461715" },
      { "name": "Canned or jarred brooks 1978 avocados", "code": "50461716" },
      { "name": "Canned or jarred brookslate avocados", "code": "50461717" },
      {
        "name": "Canned or jarred california haas avocados",
        "code": "50461718"
      },
      { "name": "Canned or jarred catalina avocados", "code": "50461719" },
      { "name": "Canned or jarred chica avocados", "code": "50461720" },
      { "name": "Canned or jarred choquette avocados", "code": "50461721" },
      { "name": "Canned or jarred christina avocados", "code": "50461722" },
      { "name": "Canned or jarred collinson avocados", "code": "50461723" },
      { "name": "Canned or jarred donnie avocados", "code": "50461724" },
      {
        "name": "Canned or jarred dr dupuis number 2 avocados",
        "code": "50461725"
      },
      { "name": "Canned or jarred dr dupuis avocados", "code": "50461726" },
      { "name": "Canned or jarred ettinger avocados", "code": "50461727" },
      { "name": "Canned or jarred fuchs avocados", "code": "50461728" },
      { "name": "Canned or jarred fuchs gwen avocados", "code": "50461729" },
      { "name": "Canned or jarred fuerte avocados", "code": "50461730" },
      { "name": "Canned or jarred gorham avocados", "code": "50461731" },
      { "name": "Canned or jarred gossman avocados", "code": "50461732" },
      {
        "name": "Canned or jarred guatemalan seedling avocados",
        "code": "50461733"
      },
      { "name": "Canned or jarred hall avocados", "code": "50461734" },
      { "name": "Canned or jarred hardee avocados", "code": "50461735" },
      { "name": "Canned or jarred haas avocados", "code": "50461736" },
      { "name": "Canned or jarred herman avocados", "code": "50461737" },
      { "name": "Canned or jarred hickson avocados", "code": "50461738" },
      { "name": "Canned or jarred k-5 avocados", "code": "50461739" },
      { "name": "Canned or jarred k-9 avocados", "code": "50461740" },
      { "name": "Canned or jarred lamb haas avocados", "code": "50461741" },
      { "name": "Canned or jarred leona avocados", "code": "50461742" },
      { "name": "Canned or jarred leona linda avocados", "code": "50461743" },
      { "name": "Canned or jarred lisa p avocados", "code": "50461744" },
      { "name": "Canned or jarred lisa loretta avocados", "code": "50461745" },
      { "name": "Canned or jarred loretta avocados", "code": "50461746" },
      { "name": "Canned or jarred lula avocados", "code": "50461747" },
      {
        "name": "Canned or jarred lula macarthur avocados",
        "code": "50461748"
      },
      { "name": "Canned or jarred marcus avocados", "code": "50461749" },
      { "name": "Canned or jarred melendez avocados", "code": "50461750" },
      { "name": "Canned or jarred meya p avocados", "code": "50461751" },
      { "name": "Canned or jarred miguel p avocados", "code": "50461752" },
      { "name": "Canned or jarred monroe avocados", "code": "50461753" },
      {
        "name": "Canned or jarred murrieta green avocados",
        "code": "50461754"
      },
      { "name": "Canned or jarred nabal avocados", "code": "50461755" },
      { "name": "Canned or jarred nadir avocados", "code": "50461756" },
      { "name": "Canned or jarred nesbitt avocados", "code": "50461757" },
      { "name": "Canned or jarred peterson avocados", "code": "50461758" },
      { "name": "Canned or jarred pinelli avocados", "code": "50461759" },
      { "name": "Canned or jarred pinkerton avocados", "code": "50461760" },
      { "name": "Canned or jarred pollock avocados", "code": "50461761" },
      { "name": "Canned or jarred puebla avocados", "code": "50461762" },
      { "name": "Canned or jarred reed avocados", "code": "50461763" },
      { "name": "Canned or jarred rue avocados", "code": "50461764" },
      { "name": "Canned or jarred ruehle avocados", "code": "50461765" },
      { "name": "Canned or jarred ryan avocados", "code": "50461766" },
      { "name": "Canned or jarred semil 34 avocados", "code": "50461767" },
      { "name": "Canned or jarred semil 43 avocados", "code": "50461768" },
      { "name": "Canned or jarred simmonds avocados", "code": "50461769" },
      { "name": "Canned or jarred simpson avocados", "code": "50461770" },
      { "name": "Canned or jarred taylor avocados", "code": "50461771" },
      { "name": "Canned or jarred tonnage avocados", "code": "50461772" },
      { "name": "Canned or jarred tower avocados", "code": "50461773" },
      { "name": "Canned or jarred tower li avocados", "code": "50461774" },
      { "name": "Canned or jarred trapp avocados", "code": "50461775" },
      {
        "name": "Canned or jarred west indian seedling avocados",
        "code": "50461776"
      },
      { "name": "Canned or jarred wagner avocados", "code": "50461777" },
      { "name": "Canned or jarred waldin avocados", "code": "50461778" },
      { "name": "Canned or jarred wurtz avocados", "code": "50461779" },
      { "name": "Canned or jarred zio p avocados", "code": "50461780" },
      { "name": "Canned or jarred ziu avocados", "code": "50461781" },
      { "name": "Canned or jarred zutano avocados", "code": "50461782" },
      { "name": "Canned or jarred beans", "code": "50461800" },
      { "name": "Canned or jarred anasazi or aztec beans", "code": "50461801" },
      { "name": "Canned or jarred appaloosa beans", "code": "50461802" },
      { "name": "Canned or jarred azuki beans", "code": "50461803" },
      { "name": "Canned or jarred barlotti beans", "code": "50461804" },
      { "name": "Canned or jarred black appaloosa beans", "code": "50461805" },
      { "name": "Canned or jarred black beans", "code": "50461806" },
      { "name": "Canned or jarred black gram beans", "code": "50461807" },
      {
        "name": "Canned or jarred black shackamaxon beans",
        "code": "50461808"
      },
      { "name": "Canned or jarred blackeyed beans", "code": "50461809" },
      { "name": "Canned or jarred bobby beans", "code": "50461810" },
      { "name": "Canned or jarred bolita beans", "code": "50461811" },
      { "name": "Canned or jarred brown lazy wife beans", "code": "50461812" },
      { "name": "Canned or jarred calypso beans", "code": "50461813" },
      { "name": "Canned or jarred cannellini beans", "code": "50461814" },
      { "name": "Canned or jarred castor beans", "code": "50461815" },
      { "name": "Canned or jarred china yellow beans", "code": "50461816" },
      { "name": "Canned or jarred dragon tongue beans", "code": "50461817" },
      { "name": "Canned or jarred european soldier beans", "code": "50461818" },
      { "name": "Canned or jarred fava beans", "code": "50461819" },
      { "name": "Canned or jarred flageolet beans", "code": "50461820" },
      {
        "name": "Canned or jarred french horticultural beans",
        "code": "50461821"
      },
      { "name": "Canned or jarred french navy beans", "code": "50461822" },
      { "name": "Canned or jarred giant white coco beans", "code": "50461823" },
      { "name": "Canned or jarred green beans", "code": "50461824" },
      { "name": "Canned or jarred green romano beans", "code": "50461825" },
      { "name": "Canned or jarred guar gum beans", "code": "50461826" },
      { "name": "Canned or jarred haricot beans", "code": "50461827" },
      { "name": "Canned or jarred hyacinth beans", "code": "50461828" },
      { "name": "Canned or jarred italian type beans", "code": "50461829" },
      { "name": "Canned or jarred jackson wonder beans", "code": "50461830" },
      { "name": "Canned or jarred jacob's cattle beans", "code": "50461831" },
      { "name": "Canned or jarred kentucky wonder beans", "code": "50461832" },
      { "name": "Canned or jarred kidney beans", "code": "50461833" },
      { "name": "Canned or jarred lima beans", "code": "50461834" },
      { "name": "Canned or jarred madeira/madera beans", "code": "50461835" },
      { "name": "Canned or jarred marrow beans", "code": "50461836" },
      { "name": "Canned or jarred mat beans", "code": "50461837" },
      {
        "name": "Canned or jarred monstoller wild goose beans",
        "code": "50461838"
      },
      { "name": "Canned or jarred mortgage lifter beans", "code": "50461839" },
      { "name": "Canned or jarred moth beans", "code": "50461840" },
      { "name": "Canned or jarred mung beans", "code": "50461841" },
      { "name": "Canned or jarred munsi wolf bean", "code": "50461842" },
      { "name": "Canned or jarred nuna beans", "code": "50461843" },
      { "name": "Canned or jarred pinto beans", "code": "50461844" },
      { "name": "Canned or jarred pole beans", "code": "50461845" },
      { "name": "Canned or jarred runner beans", "code": "50461846" },
      { "name": "Canned or jarred string beans", "code": "50461847" },
      { "name": "Canned or jarred tamarind beans", "code": "50461848" },
      { "name": "Canned or jarred tonka beans", "code": "50461849" },
      { "name": "Canned or jarred wax beans", "code": "50461850" },
      { "name": "Canned or jarred winged beans", "code": "50461851" },
      { "name": "Canned or jarred yard long beans", "code": "50461852" },
      { "name": "Canned or jarred beets", "code": "50461900" },
      { "name": "Canned or jarred action beets", "code": "50461901" },
      { "name": "Canned or jarred albina vereduna beets", "code": "50461902" },
      {
        "name": "Canned or jarred barbabietola di chioggia beets",
        "code": "50461903"
      },
      { "name": "Canned or jarred boltardy beets", "code": "50461904" },
      { "name": "Canned or jarred bonel beets", "code": "50461905" },
      { "name": "Canned or jarred burpees golden beets", "code": "50461906" },
      {
        "name": "Canned or jarred cheltenham green top beets",
        "code": "50461907"
      },
      { "name": "Canned or jarred cheltenham mono beets", "code": "50461908" },
      { "name": "Canned or jarred chioggia beets", "code": "50461909" },
      { "name": "Canned or jarred cylindra beets", "code": "50461910" },
      { "name": "Canned or jarred d'egypte beets", "code": "50461911" },
      {
        "name": "Canned or jarred detroit 2 dark red beets",
        "code": "50461912"
      },
      {
        "name": "Canned or jarred detroit 2 little ball beets",
        "code": "50461913"
      },
      { "name": "Canned or jarred egyptian flat beets", "code": "50461914" },
      {
        "name": "Canned or jarred egyptian turnip rooted beets",
        "code": "50461915"
      },
      { "name": "Canned or jarred formanova beets", "code": "50461916" },
      { "name": "Canned or jarred forono beets", "code": "50461917" },
      { "name": "Canned or jarred monaco beets", "code": "50461918" },
      { "name": "Canned or jarred monogram beets", "code": "50461919" },
      { "name": "Canned or jarred pronto beets", "code": "50461920" },
      { "name": "Canned or jarred regalia beets", "code": "50461921" },
      { "name": "Canned or jarred sugar beets", "code": "50461922" },
      { "name": "Canned or jarred broccoli", "code": "50462000" },
      { "name": "Canned or jarred broccolini", "code": "50462001" },
      { "name": "Canned or jarred broccoli romanesco", "code": "50462002" },
      { "name": "Canned or jarred broccoli raab", "code": "50462003" },
      { "name": "Canned or jarred chinese broccoli", "code": "50462004" },
      { "name": "Canned or jarred brussel sprouts", "code": "50462100" },
      {
        "name": "Canned or jarred citadel brussel sprouts",
        "code": "50462101"
      },
      {
        "name": "Canned or jarred falstaff brussel sprouts",
        "code": "50462102"
      },
      { "name": "Canned or jarred oliver brussel sprouts", "code": "50462103" },
      {
        "name": "Canned or jarred peer gynt brussel sprouts",
        "code": "50462104"
      },
      {
        "name": "Canned or jarred rampart brussel sprouts",
        "code": "50462105"
      },
      { "name": "Canned or jarred rubine brussel sprouts", "code": "50462106" },
      {
        "name": "Canned or jarred widgeon brussel sprouts",
        "code": "50462107"
      },
      { "name": "Canned or jarred bunching onions", "code": "50462200" },
      {
        "name": "Canned or jarred beltsville bunching onions",
        "code": "50462201"
      },
      { "name": "Canned or jarred feast bunching onions", "code": "50462202" },
      {
        "name": "Canned or jarred ishikura bunching onions",
        "code": "50462203"
      },
      {
        "name": "Canned or jarred kyoto market bunching onions",
        "code": "50462204"
      },
      {
        "name": "Canned or jarred red beard bunching onions",
        "code": "50462205"
      },
      {
        "name": "Canned or jarred redmate bunching onions",
        "code": "50462206"
      },
      {
        "name": "Canned or jarred santa claus bunching onions",
        "code": "50462207"
      },
      { "name": "Canned or jarred tokyo bunching onions", "code": "50462208" },
      {
        "name": "Canned or jarred white lisbon bunching onions",
        "code": "50462209"
      },
      {
        "name": "Canned or jarred winter white bunching onions",
        "code": "50462210"
      },
      {
        "name": "Canned or jarred winter-over bunching onions",
        "code": "50462211"
      },
      { "name": "Canned or jarred cabbages", "code": "50462300" },
      { "name": "Canned or jarred black cabbages", "code": "50462301" },
      { "name": "Canned or jarred savoy cabbages", "code": "50462302" },
      { "name": "Canned or jarred skunk cabbages", "code": "50462303" },
      { "name": "Canned or jarred white cabbages", "code": "50462304" },
      { "name": "Canned or jarred cardoons", "code": "50462400" },
      { "name": "Canned or jarred lunghi cardoons", "code": "50462401" },
      { "name": "Canned or jarred gobbi cardoons", "code": "50462402" },
      { "name": "Canned or jarred carrots", "code": "50462500" },
      { "name": "Canned or jarred amsterdam carrots", "code": "50462501" },
      { "name": "Canned or jarred autumn king carrots", "code": "50462502" },
      { "name": "Canned or jarred berlicum carrots", "code": "50462503" },
      { "name": "Canned or jarred chantenay carrots", "code": "50462504" },
      { "name": "Canned or jarred nantes carrots", "code": "50462505" },
      { "name": "Canned or jarred paris market carrots", "code": "50462506" },
      { "name": "Canned or jarred cauliflowers", "code": "50462600" },
      {
        "name": "Canned or jarred all the year round cauliflowers",
        "code": "50462601"
      },
      { "name": "Canned or jarred alverda cauliflowers", "code": "50462602" },
      {
        "name": "Canned or jarred autumn giant 3 cauliflowers",
        "code": "50462603"
      },
      { "name": "Canned or jarred dok elgon cauliflowers", "code": "50462604" },
      {
        "name": "Canned or jarred early snowball cauliflowers",
        "code": "50462605"
      },
      { "name": "Canned or jarred limelight cauliflowers", "code": "50462606" },
      { "name": "Canned or jarred minaret cauliflowers", "code": "50462607" },
      {
        "name": "Canned or jarred orange bouquet cauliflowers",
        "code": "50462608"
      },
      {
        "name": "Canned or jarred purple cape cauliflowers",
        "code": "50462609"
      },
      { "name": "Canned or jarred snowball cauliflowers", "code": "50462610" },
      {
        "name": "Canned or jarred walcheren winter 3 cauliflowers",
        "code": "50462611"
      },
      {
        "name": "Canned or jarred white rock cauliflowers",
        "code": "50462612"
      },
      { "name": "Canned or jarred celery", "code": "50462700" },
      { "name": "Canned or jarred celebrity celery", "code": "50462701" },
      { "name": "Canned or jarred celeriac", "code": "50462702" },
      { "name": "Canned or jarred chinese celery", "code": "50462703" },
      { "name": "Canned or jarred french dinant celery", "code": "50462704" },
      { "name": "Canned or jarred giant pink celery", "code": "50462705" },
      { "name": "Canned or jarred giant red celery", "code": "50462706" },
      { "name": "Canned or jarred giant white celery", "code": "50462707" },
      {
        "name": "Canned or jarred golden self-blanching celery",
        "code": "50462708"
      },
      { "name": "Canned or jarred greensleeves celery", "code": "50462709" },
      {
        "name": "Canned or jarred hopkins fenlander celery",
        "code": "50462710"
      },
      { "name": "Canned or jarred ivory tower celery", "code": "50462711" },
      {
        "name": "Canned or jarred lathom self-blanching celery",
        "code": "50462712"
      },
      {
        "name": "Canned or jarred soup celery d'amsterdam",
        "code": "50462713"
      },
      { "name": "Canned or jarred standard bearer celery", "code": "50462714" },
      {
        "name": "Canned or jarred tall utah triumph celery",
        "code": "50462715"
      },
      { "name": "Canned or jarred chards", "code": "50462800" },
      { "name": "Canned or jarred bright lights chard", "code": "50462801" },
      { "name": "Canned or jarred fordhook giant chard", "code": "50462802" },
      { "name": "Canned or jarred lucullus chard", "code": "50462803" },
      {
        "name": "Canned or jarred perpetual spinach chard",
        "code": "50462804"
      },
      { "name": "Canned or jarred rhubarb chard", "code": "50462805" },
      { "name": "Canned or jarred swiss chard", "code": "50462806" },
      { "name": "Canned or jarred vulcan chard", "code": "50462807" },
      { "name": "Canned or jarred white king chard", "code": "50462808" },
      { "name": "Canned or jarred chicories", "code": "50462900" },
      {
        "name": "Canned or jarred broad leaved batavian chicory",
        "code": "50462901"
      },
      {
        "name": "Canned or jarred en cornet de bordeaux chicory",
        "code": "50462902"
      },
      {
        "name": "Canned or jarred green curled ruffee chicory",
        "code": "50462903"
      },
      { "name": "Canned or jarred green curled chicory", "code": "50462904" },
      { "name": "Canned or jarred ione limnos chicory", "code": "50462905" },
      {
        "name": "Canned or jarred riccia pancalieri chicory",
        "code": "50462906"
      },
      { "name": "Canned or jarred salad king chicory", "code": "50462907" },
      { "name": "Canned or jarred sanda chicory", "code": "50462908" },
      { "name": "Canned or jarred scarola verde chicory", "code": "50462909" },
      {
        "name": "Canned or jarred tres fine maraichere chicory",
        "code": "50462910"
      },
      {
        "name": "Canned or jarred wallone freisee weschelkopf chicory",
        "code": "50462911"
      },
      { "name": "Canned or jarred chinese cabbages", "code": "50463000" },
      { "name": "Canned or jarred bok choy", "code": "50463001" },
      {
        "name": "Canned or jarred chinese flat-headed cabbage",
        "code": "50463002"
      },
      {
        "name": "Canned or jarred chinese flowering cabbage",
        "code": "50463003"
      },
      { "name": "Canned or jarred choy sum", "code": "50463004" },
      { "name": "Canned or jarred dwarf bok choy", "code": "50463005" },
      { "name": "Canned or jarred fengshan bok choy", "code": "50463006" },
      { "name": "Canned or jarred jade pagoda bok choy", "code": "50463007" },
      { "name": "Canned or jarred kasumi bok choy", "code": "50463008" },
      { "name": "Canned or jarred nerva bok choy", "code": "50463009" },
      { "name": "Canned or jarred rosette bok choy", "code": "50463010" },
      { "name": "Canned or jarred ruffles bok choy", "code": "50463011" },
      {
        "name": "Canned or jarred santo serrated leaved cabbage",
        "code": "50463012"
      },
      { "name": "Canned or jarred shanghai d bok choy", "code": "50463013" },
      { "name": "Canned or jarred shantung cabbage", "code": "50463014" },
      { "name": "Canned or jarred tip top cabbage", "code": "50463015" },
      { "name": "Canned or jarred yau choy sum", "code": "50463016" },
      { "name": "Canned or jarred chives", "code": "50463100" },
      { "name": "Canned or jarred chinese chives", "code": "50463101" },
      { "name": "Canned or jarred common chives", "code": "50463102" },
      { "name": "Canned or jarred corn", "code": "50463200" },
      { "name": "Canned or jarred aloha corn", "code": "50463201" },
      { "name": "Canned or jarred alpine corn", "code": "50463202" },
      { "name": "Canned or jarred ambrosia corn", "code": "50463203" },
      { "name": "Canned or jarred argent corn", "code": "50463204" },
      { "name": "Canned or jarred aspen corn", "code": "50463205" },
      { "name": "Canned or jarred avalanche corn", "code": "50463206" },
      { "name": "Canned or jarred biqueen corn", "code": "50463207" },
      { "name": "Canned or jarred bodacious corn", "code": "50463208" },
      { "name": "Canned or jarred butter and sugar corn", "code": "50463209" },
      { "name": "Canned or jarred calico belle corn", "code": "50463210" },
      { "name": "Canned or jarred camelot corn", "code": "50463211" },
      {
        "name": "Canned or jarred challenger crisp \u2018n sweet corn",
        "code": "50463212"
      },
      { "name": "Canned or jarred champ corn", "code": "50463213" },
      { "name": "Canned or jarred cotton candy corn", "code": "50463214" },
      { "name": "Canned or jarred d\u2019artagnan corn", "code": "50463215" },
      { "name": "Canned or jarred dazzle corn", "code": "50463216" },
      { "name": "Canned or jarred diamonds and gold corn", "code": "50463217" },
      { "name": "Canned or jarred divinity corn", "code": "50463218" },
      { "name": "Canned or jarred double delight corn", "code": "50463219" },
      { "name": "Canned or jarred double gem corn", "code": "50463220" },
      { "name": "Canned or jarred earlivee corn", "code": "50463221" },
      { "name": "Canned or jarred early xtra sweet corn", "code": "50463222" },
      { "name": "Canned or jarred excel corn", "code": "50463223" },
      {
        "name": "Canned or jarred golden cross bantam corn",
        "code": "50463224"
      },
      { "name": "Canned or jarred honey and cream corn", "code": "50463225" },
      {
        "name": "Canned or jarred honey \u2018n pearl corn",
        "code": "50463226"
      },
      { "name": "Canned or jarred how sweet it is corn", "code": "50463227" },
      { "name": "Canned or jarred hudson corn", "code": "50463228" },
      { "name": "Canned or jarred illini gold corn", "code": "50463229" },
      { "name": "Canned or jarred illini xtra sweet corn", "code": "50463230" },
      { "name": "Canned or jarred incredible corn", "code": "50463231" },
      { "name": "Canned or jarred iochief corn", "code": "50463232" },
      { "name": "Canned or jarred jubilee corn", "code": "50463233" },
      {
        "name": "Canned or jarred jubilee supersweet corn",
        "code": "50463234"
      },
      { "name": "Canned or jarred kandy korn corn", "code": "50463235" },
      { "name": "Canned or jarred kiss \u2018n tell corn", "code": "50463236" },
      { "name": "Canned or jarred lancelot corn", "code": "50463237" },
      { "name": "Canned or jarred maple sweet corn", "code": "50463238" },
      { "name": "Canned or jarred medley corn", "code": "50463239" },
      { "name": "Canned or jarred merlin corn", "code": "50463240" },
      { "name": "Canned or jarred miracle corn", "code": "50463241" },
      { "name": "Canned or jarred nk-199 corn", "code": "50463242" },
      { "name": "Canned or jarred peaches and cream corn", "code": "50463243" },
      { "name": "Canned or jarred pearl white corn", "code": "50463244" },
      { "name": "Canned or jarred pegasus corn", "code": "50463245" },
      { "name": "Canned or jarred phenomenal corn", "code": "50463246" },
      { "name": "Canned or jarred platinum lady corn", "code": "50463247" },
      { "name": "Canned or jarred precocious corn", "code": "50463248" },
      { "name": "Canned or jarred pristine corn", "code": "50463249" },
      { "name": "Canned or jarred quickie corn", "code": "50463250" },
      { "name": "Canned or jarred radiance corn", "code": "50463251" },
      { "name": "Canned or jarred seneca brave corn", "code": "50463252" },
      { "name": "Canned or jarred seneca dawn corn", "code": "50463253" },
      { "name": "Canned or jarred seneca horizon corn", "code": "50463254" },
      { "name": "Canned or jarred seneca starshine corn", "code": "50463255" },
      {
        "name": "Canned or jarred seneca white knight corn",
        "code": "50463256"
      },
      { "name": "Canned or jarred showcase corn", "code": "50463257" },
      { "name": "Canned or jarred silver queen corn", "code": "50463258" },
      { "name": "Canned or jarred snowbelle corn", "code": "50463259" },
      { "name": "Canned or jarred spring snow corn", "code": "50463260" },
      { "name": "Canned or jarred spring treat corn", "code": "50463261" },
      { "name": "Canned or jarred sugar and gold corn", "code": "50463262" },
      { "name": "Canned or jarred sugar buns corn", "code": "50463263" },
      { "name": "Canned or jarred sugar snow corn", "code": "50463264" },
      { "name": "Canned or jarred sundance corn", "code": "50463265" },
      { "name": "Canned or jarred telstar corn", "code": "50463266" },
      { "name": "Canned or jarred terminator corn", "code": "50463267" },
      { "name": "Canned or jarred treasure corn", "code": "50463268" },
      { "name": "Canned or jarred tuxedo corn", "code": "50463269" },
      { "name": "Canned or jarred cresses", "code": "50463300" },
      { "name": "Canned or jarred land cress", "code": "50463301" },
      { "name": "Canned or jarred nasturtium", "code": "50463302" },
      { "name": "Canned or jarred watercress", "code": "50463303" },
      { "name": "Canned or jarred wintercress", "code": "50463304" },
      { "name": "Canned or jarred cucumbers", "code": "50463400" },
      { "name": "Canned or jarred arena cucumbers", "code": "50463401" },
      { "name": "Canned or jarred armenian cucumbers", "code": "50463402" },
      { "name": "Canned or jarred athene cucumbers", "code": "50463403" },
      {
        "name": "Canned or jarred bianco lungo di parigi cucumbers",
        "code": "50463404"
      },
      {
        "name": "Canned or jarred burpless tasty green cucumbers",
        "code": "50463405"
      },
      {
        "name": "Canned or jarred chicago pickling cucumbers",
        "code": "50463406"
      },
      {
        "name": "Canned or jarred crystal apple cucumbers",
        "code": "50463407"
      },
      {
        "name": "Canned or jarred crystal lemon cucumbers",
        "code": "50463408"
      },
      { "name": "Canned or jarred danimas cucumbers", "code": "50463409" },
      { "name": "Canned or jarred gherkin cucumbers", "code": "50463410" },
      { "name": "Canned or jarred hokus cucumbers", "code": "50463411" },
      { "name": "Canned or jarred japanese cucumbers", "code": "50463412" },
      { "name": "Canned or jarred karela cucumbers", "code": "50463413" },
      { "name": "Canned or jarred korila cucumbers", "code": "50463414" },
      {
        "name": "Canned or jarred long green improved cucumbers",
        "code": "50463415"
      },
      { "name": "Canned or jarred marketmore cucumbers", "code": "50463416" },
      { "name": "Canned or jarred midget cucumbers", "code": "50463417" },
      {
        "name": "Canned or jarred national pickling cucumbers",
        "code": "50463418"
      },
      { "name": "Canned or jarred persian cucumbers", "code": "50463419" },
      { "name": "Canned or jarred telegraph cucumbers", "code": "50463420" },
      {
        "name": "Canned or jarred telegraph improved cucumbers",
        "code": "50463421"
      },
      {
        "name": "Canned or jarred vert de massy cornichon cucumbers",
        "code": "50463422"
      },
      { "name": "Canned or jarred yamato cucumbers", "code": "50463423" },
      { "name": "Canned or jarred eggplants", "code": "50463500" },
      { "name": "Canned or jarred bambino eggplants", "code": "50463501" },
      { "name": "Canned or jarred black beauty eggplants", "code": "50463502" },
      { "name": "Canned or jarred black enorma eggplants", "code": "50463503" },
      { "name": "Canned or jarred chinese eggplants", "code": "50463504" },
      { "name": "Canned or jarred easter egg eggplants", "code": "50463505" },
      { "name": "Canned or jarred filipino eggplants", "code": "50463506" },
      {
        "name": "Canned or jarred florida market eggplants",
        "code": "50463507"
      },
      { "name": "Canned or jarred indian eggplants", "code": "50463508" },
      { "name": "Canned or jarred italian eggplants", "code": "50463509" },
      { "name": "Canned or jarred japanese eggplants", "code": "50463510" },
      { "name": "Canned or jarred long purple eggplants", "code": "50463511" },
      { "name": "Canned or jarred long striped eggplants", "code": "50463512" },
      { "name": "Canned or jarred moneymaker eggplants", "code": "50463513" },
      { "name": "Canned or jarred ova eggplants", "code": "50463514" },
      { "name": "Canned or jarred pea eggplants", "code": "50463515" },
      { "name": "Canned or jarred short tom eggplants", "code": "50463516" },
      { "name": "Canned or jarred sicilian eggplants", "code": "50463517" },
      { "name": "Canned or jarred thai eggplants", "code": "50463518" },
      {
        "name": "Canned or jarred violette di firenze eggplants",
        "code": "50463519"
      },
      { "name": "Canned or jarred white eggplants", "code": "50463520" },
      { "name": "Canned or jarred endives", "code": "50463600" },
      {
        "name": "Canned or jarred brussels witloof endives",
        "code": "50463601"
      },
      { "name": "Canned or jarred castelfranco endives", "code": "50463602" },
      {
        "name": "Canned or jarred catalogna di galatina endives",
        "code": "50463603"
      },
      { "name": "Canned or jarred chioggia endives", "code": "50463604" },
      { "name": "Canned or jarred grumolo verde endives", "code": "50463605" },
      {
        "name": "Canned or jarred large rooted magdeburg endives",
        "code": "50463606"
      },
      {
        "name": "Canned or jarred palla rossa zorzi precoce endives",
        "code": "50463607"
      },
      { "name": "Canned or jarred radice amare endives", "code": "50463608" },
      {
        "name": "Canned or jarred rossa di treviso endives",
        "code": "50463609"
      },
      {
        "name": "Canned or jarred rossa di verona endives",
        "code": "50463610"
      },
      { "name": "Canned or jarred soncino endives", "code": "50463611" },
      { "name": "Canned or jarred sugarhat endives", "code": "50463612" },
      { "name": "Canned or jarred verona endives", "code": "50463613" },
      { "name": "Canned or jarred witloof zoom endives", "code": "50463614" },
      { "name": "Canned or jarred fennels", "code": "50463700" },
      { "name": "Canned or jarred cantino fennel", "code": "50463701" },
      { "name": "Canned or jarred fino fennel", "code": "50463702" },
      { "name": "Canned or jarred herald fennel", "code": "50463703" },
      { "name": "Canned or jarred perfection fennel", "code": "50463704" },
      { "name": "Canned or jarred sirio fennel", "code": "50463705" },
      { "name": "Canned or jarred sweet florence fennel", "code": "50463706" },
      { "name": "Canned or jarred tardo fennel", "code": "50463707" },
      { "name": "Canned or jarred garlics", "code": "50463800" },
      { "name": "Canned or jarred california late garlic", "code": "50463801" },
      { "name": "Canned or jarred chinese garlic stems", "code": "50463802" },
      { "name": "Canned or jarred garlic chives", "code": "50463803" },
      { "name": "Canned or jarred germidor garlic", "code": "50463804" },
      { "name": "Canned or jarred long keeper garlic", "code": "50463805" },
      { "name": "Canned or jarred ramson garlic", "code": "50463806" },
      { "name": "Canned or jarred rocambole garlic", "code": "50463807" },
      { "name": "Canned or jarred rose de lautrec garlic", "code": "50463808" },
      { "name": "Canned or jarred solent wight garlic", "code": "50463809" },
      { "name": "Canned or jarred spanish morado garlic", "code": "50463810" },
      {
        "name": "Canned or jarred venetian/italian garlic",
        "code": "50463811"
      },
      { "name": "Canned or jarred gourds", "code": "50463900" },
      { "name": "Canned or jarred angled loofah", "code": "50463901" },
      { "name": "Canned or jarred bitter gourd", "code": "50463902" },
      { "name": "Canned or jarred bottle gourd", "code": "50463903" },
      { "name": "Canned or jarred calabash gourds", "code": "50463904" },
      { "name": "Canned or jarred fuzzy/hairy melon", "code": "50463905" },
      { "name": "Canned or jarred musky gourd", "code": "50463906" },
      { "name": "Canned or jarred smooth loofah", "code": "50463907" },
      { "name": "Canned or jarred snake gourd", "code": "50463908" },
      { "name": "Canned or jarred spiny bitter gourd", "code": "50463909" },
      { "name": "Canned or jarred tinda gourds", "code": "50463910" },
      { "name": "Canned or jarred tindoori gourds", "code": "50463911" },
      { "name": "Canned or jarred green peas", "code": "50464000" },
      { "name": "Canned or jarred china peas", "code": "50464001" },
      { "name": "Canned or jarred english peas", "code": "50464002" },
      { "name": "Canned or jarred garden peas", "code": "50464003" },
      { "name": "Canned or jarred snow peas", "code": "50464004" },
      { "name": "Canned or jarred sugar snap peas", "code": "50464005" },
      { "name": "Canned or jarred herbs", "code": "50464100" },
      { "name": "Canned or jarred basil", "code": "50464101" },
      { "name": "Canned or jarred bay leaves", "code": "50464102" },
      { "name": "Canned or jarred borage", "code": "50464103" },
      { "name": "Canned or jarred caraway", "code": "50464104" },
      { "name": "Canned or jarred chervil", "code": "50464105" },
      { "name": "Canned or jarred cilantro", "code": "50464106" },
      { "name": "Canned or jarred cipolinos", "code": "50464107" },
      { "name": "Canned or jarred curry leaves", "code": "50464108" },
      { "name": "Canned or jarred dill", "code": "50464109" },
      { "name": "Canned or jarred epazote", "code": "50464110" },
      { "name": "Canned or jarred fenugreek", "code": "50464111" },
      { "name": "Canned or jarred lemon grass", "code": "50464112" },
      { "name": "Canned or jarred marjoram", "code": "50464113" },
      { "name": "Canned or jarred mint", "code": "50464114" },
      { "name": "Canned or jarred oregano", "code": "50464115" },
      { "name": "Canned or jarred papalo", "code": "50464116" },
      { "name": "Canned or jarred pepicha", "code": "50464117" },
      { "name": "Canned or jarred perilla", "code": "50464118" },
      { "name": "Canned or jarred recao", "code": "50464119" },
      { "name": "Canned or jarred rosemary", "code": "50464120" },
      { "name": "Canned or jarred sage", "code": "50464121" },
      { "name": "Canned or jarred salsify", "code": "50464122" },
      { "name": "Canned or jarred savory", "code": "50464123" },
      { "name": "Canned or jarred tarragon", "code": "50464124" },
      { "name": "Canned or jarred thyme", "code": "50464125" },
      { "name": "Canned or jarred tumeric", "code": "50464126" },
      { "name": "Canned or jarred verdulaga", "code": "50464127" },
      { "name": "Canned or jarred kale", "code": "50464200" },
      { "name": "Canned or jarred curly kale", "code": "50464201" },
      { "name": "Canned or jarred collard greens", "code": "50464202" },
      { "name": "Canned or jarred kohlrabi", "code": "50464300" },
      { "name": "Canned or jarred azur star kohlrabi", "code": "50464301" },
      { "name": "Canned or jarred green vienna kohlrabi", "code": "50464302" },
      { "name": "Canned or jarred lanro kohlrabi", "code": "50464303" },
      { "name": "Canned or jarred purple vienna kohlrabi", "code": "50464304" },
      { "name": "Canned or jarred rowel trero kohlrabi", "code": "50464305" },
      { "name": "Canned or jarred white vienna kohlrabi", "code": "50464306" },
      { "name": "Canned or jarred leeks", "code": "50464400" },
      {
        "name": "Canned or jarred autumn giant-cobra leeks",
        "code": "50464401"
      },
      { "name": "Canned or jarred autumn mammoth 2 leeks", "code": "50464402" },
      { "name": "Canned or jarred bleu de solaise leeks", "code": "50464403" },
      { "name": "Canned or jarred cortina leeks", "code": "50464404" },
      { "name": "Canned or jarred prelina leeks", "code": "50464405" },
      { "name": "Canned or jarred wild leek ramp", "code": "50464406" },
      { "name": "Canned or jarred lentils", "code": "50464500" },
      { "name": "Canned or jarred beluga lentils", "code": "50464501" },
      { "name": "Canned or jarred french green lentils", "code": "50464502" },
      { "name": "Canned or jarred green lentils", "code": "50464503" },
      { "name": "Canned or jarred petite crimson lentils", "code": "50464504" },
      {
        "name": "Canned or jarred spanish pardina lentils",
        "code": "50464505"
      },
      { "name": "Canned or jarred split red lentils", "code": "50464506" },
      { "name": "Canned or jarred split yellow lentils", "code": "50464507" },
      {
        "name": "Canned or jarred tarahumara pinks lentils",
        "code": "50464508"
      },
      { "name": "Canned or jarred lettuces", "code": "50464600" },
      { "name": "Canned or jarred bibb lettuce", "code": "50464601" },
      { "name": "Canned or jarred boston lettuce", "code": "50464602" },
      { "name": "Canned or jarred frisee lettuce", "code": "50464603" },
      { "name": "Canned or jarred lolla rossa lettuce", "code": "50464604" },
      { "name": "Canned or jarred mesculin mix lettuce", "code": "50464605" },
      { "name": "Canned or jarred mizuna lettuce", "code": "50464606" },
      { "name": "Canned or jarred red leaf lettuce", "code": "50464607" },
      { "name": "Canned or jarred red oak leaf lettuce", "code": "50464608" },
      { "name": "Canned or jarred ruby romaine lettuce", "code": "50464609" },
      {
        "name": "Canned or jarred baby red romaine lettuce",
        "code": "50464610"
      },
      { "name": "Canned or jarred butterhead lettuces", "code": "50464611" },
      { "name": "Canned or jarred chinese lettuces", "code": "50464612" },
      { "name": "Canned or jarred crisphead lettuces", "code": "50464613" },
      { "name": "Canned or jarred green leaf lettuce", "code": "50464614" },
      { "name": "Canned or jarred iceberg lettuce", "code": "50464615" },
      { "name": "Canned or jarred lamb\u2019s lettuces", "code": "50464616" },
      { "name": "Canned or jarred looseleaf lettuces", "code": "50464617" },
      { "name": "Canned or jarred mache lettuce", "code": "50464618" },
      { "name": "Canned or jarred red boston lettuce", "code": "50464619" },
      { "name": "Canned or jarred red headed lettuces", "code": "50464620" },
      { "name": "Canned or jarred romaine lettuces", "code": "50464621" },
      {
        "name": "Canned or jarred russian red mustard lettuce",
        "code": "50464622"
      },
      { "name": "Canned or jarred tatsoi lettuce", "code": "50464623" },
      { "name": "Canned or jarred malanga", "code": "50464700" },
      { "name": "Canned or jarred amarilla malanga", "code": "50464701" },
      { "name": "Canned or jarred blanca malanga", "code": "50464702" },
      { "name": "Canned or jarred coco malanga", "code": "50464703" },
      { "name": "Canned or jarred eddoes malanga", "code": "50464704" },
      { "name": "Canned or jarred islena malanga", "code": "50464705" },
      { "name": "Canned or jarred lila malanga", "code": "50464706" },
      { "name": "Canned or jarred mushrooms", "code": "50464800" },
      {
        "name": "Canned or jarred black trumpet mushrooms",
        "code": "50464801"
      },
      { "name": "Canned or jarred brown mushrooms", "code": "50464802" },
      { "name": "Canned or jarred champinion mushrooms", "code": "50464803" },
      { "name": "Canned or jarred chanterelle mushrooms", "code": "50464804" },
      { "name": "Canned or jarred cremini mushrooms", "code": "50464805" },
      { "name": "Canned or jarred enoki mushrooms", "code": "50464806" },
      { "name": "Canned or jarred hedge hog mushrooms", "code": "50464807" },
      {
        "name": "Canned or jarred hen of the woods mushrooms",
        "code": "50464808"
      },
      { "name": "Canned or jarred lobster mushrooms", "code": "50464809" },
      { "name": "Canned or jarred morels mushrooms", "code": "50464810" },
      { "name": "Canned or jarred oyster mushrooms", "code": "50464811" },
      { "name": "Canned or jarred pleurotus mushrooms", "code": "50464812" },
      { "name": "Canned or jarred pompom mushrooms", "code": "50464813" },
      { "name": "Canned or jarred porcieni mushrooms", "code": "50464814" },
      { "name": "Canned or jarred portobella mushrooms", "code": "50464815" },
      { "name": "Canned or jarred shiitake mushrooms", "code": "50464816" },
      { "name": "Canned or jarred shimeji mushrooms", "code": "50464817" },
      { "name": "Canned or jarred st george's mushrooms", "code": "50464818" },
      { "name": "Canned or jarred white mushrooms", "code": "50464819" },
      {
        "name": "Canned or jarred white trumpet mushrooms",
        "code": "50464820"
      },
      { "name": "Canned or jarred woodear mushrooms", "code": "50464821" },
      { "name": "Canned or jarred mustards", "code": "50464900" },
      { "name": "Canned or jarred bamboo mustard", "code": "50464901" },
      { "name": "Canned or jarred garlic mustard", "code": "50464902" },
      { "name": "Canned or jarred giantleafed mustard", "code": "50464903" },
      { "name": "Canned or jarred red in snow mustard", "code": "50464904" },
      { "name": "Canned or jarred southern mustard", "code": "50464905" },
      { "name": "Canned or jarred wrapped heart mustard", "code": "50464906" },
      { "name": "Canned or jarred nightshades", "code": "50465000" },
      { "name": "Canned or jarred chinese lantern", "code": "50465001" },
      { "name": "Canned or jarred garden huckleberry", "code": "50465002" },
      { "name": "Canned or jarred naranjilla", "code": "50465003" },
      { "name": "Canned or jarred tomatillo", "code": "50465004" },
      { "name": "Canned or jarred okras", "code": "50465100" },
      { "name": "Canned or jarred artist okra", "code": "50465101" },
      { "name": "Canned or jarred burgundy okra", "code": "50465102" },
      { "name": "Canned or jarred clemson spineless okra", "code": "50465103" },
      {
        "name": "Canned or jarred dwarf green long pod okra",
        "code": "50465104"
      },
      {
        "name": "Canned or jarred mammoth spineless long pod okra",
        "code": "50465105"
      },
      { "name": "Canned or jarred red velvet okra", "code": "50465106" },
      {
        "name": "Canned or jarred star of david heirloom okra",
        "code": "50465107"
      },
      { "name": "Canned or jarred onions", "code": "50465200" },
      { "name": "Canned or jarred albion onions", "code": "50465201" },
      { "name": "Canned or jarred alisa craig onions", "code": "50465202" },
      { "name": "Canned or jarred boiling onions", "code": "50465203" },
      { "name": "Canned or jarred buffalo onions", "code": "50465204" },
      { "name": "Canned or jarred bulb onions", "code": "50465205" },
      { "name": "Canned or jarred creaming onions", "code": "50465206" },
      {
        "name": "Canned or jarred express yellow o-x onions",
        "code": "50465207"
      },
      { "name": "Canned or jarred kelsae onions", "code": "50465208" },
      {
        "name": "Canned or jarred marshalls giant fen globe onions",
        "code": "50465209"
      },
      { "name": "Canned or jarred pearl onions", "code": "50465210" },
      { "name": "Canned or jarred red baron onions", "code": "50465211" },
      { "name": "Canned or jarred red onions", "code": "50465212" },
      { "name": "Canned or jarred rijnsberger onions", "code": "50465213" },
      {
        "name": "Canned or jarred senshyu semi-globe yellow onions",
        "code": "50465214"
      },
      { "name": "Canned or jarred sturon onions", "code": "50465215" },
      {
        "name": "Canned or jarred stuttgarter giant onions",
        "code": "50465216"
      },
      { "name": "Canned or jarred sweet onions", "code": "50465217" },
      {
        "name": "Canned or jarred torpedo or red italian onions",
        "code": "50465218"
      },
      { "name": "Canned or jarred red storage onions", "code": "50465219" },
      { "name": "Canned or jarred white storage onions", "code": "50465220" },
      { "name": "Canned or jarred yellow storage onions", "code": "50465221" },
      { "name": "Canned or jarred peanuts", "code": "50465300" },
      {
        "name": "Canned or jarred bambarra groundnut peanuts",
        "code": "50465301"
      },
      { "name": "Canned or jarred florunner peanuts", "code": "50465302" },
      {
        "name": "Canned or jarred hausa/kersting's ground nut peanuts",
        "code": "50465303"
      },
      { "name": "Canned or jarred spanish peanuts", "code": "50465304" },
      { "name": "Canned or jarred valencia peanuts", "code": "50465305" },
      { "name": "Canned or jarred virginia peanuts", "code": "50465306" },
      { "name": "Canned or jarred peas", "code": "50465400" },
      { "name": "Canned or jarred purple hull peas", "code": "50465401" },
      { "name": "Canned or jarred pinkeye peas", "code": "50465402" },
      { "name": "Canned or jarred crowder peas", "code": "50465403" },
      { "name": "Canned or jarred white acre peas", "code": "50465404" },
      { "name": "Canned or jarred blackeyed peas", "code": "50465405" },
      { "name": "Canned or jarred zipper cream peas", "code": "50465406" },
      { "name": "Canned or jarred peppers", "code": "50465500" },
      { "name": "Canned or jarred ajies peppers", "code": "50465501" },
      { "name": "Canned or jarred arbol peppers", "code": "50465502" },
      { "name": "Canned or jarred cheese peppers", "code": "50465503" },
      { "name": "Canned or jarred chilaca peppers", "code": "50465504" },
      { "name": "Canned or jarred cubanelles peppers", "code": "50465505" },
      { "name": "Canned or jarred fresno peppers", "code": "50465506" },
      { "name": "Canned or jarred kapia peppers", "code": "50465507" },
      { "name": "Canned or jarred korean peppers", "code": "50465508" },
      { "name": "Canned or jarred manzano peppers", "code": "50465509" },
      { "name": "Canned or jarred melrose peppers", "code": "50465510" },
      { "name": "Canned or jarred yellow chile peppers", "code": "50465511" },
      { "name": "Canned or jarred aji dulces peppers", "code": "50465512" },
      { "name": "Canned or jarred anaheim peppers", "code": "50465513" },
      { "name": "Canned or jarred ancho peppers", "code": "50465514" },
      { "name": "Canned or jarred bell peppers", "code": "50465515" },
      { "name": "Canned or jarred cascabel peppers", "code": "50465516" },
      { "name": "Canned or jarred cayenne peppers", "code": "50465517" },
      { "name": "Canned or jarred cherry hots peppers", "code": "50465518" },
      { "name": "Canned or jarred chiltecpin peppers", "code": "50465519" },
      { "name": "Canned or jarred finger hot peppers", "code": "50465520" },
      { "name": "Canned or jarred guajillo peppers", "code": "50465521" },
      { "name": "Canned or jarred guerro peppers", "code": "50465522" },
      { "name": "Canned or jarred habanero peppers", "code": "50465523" },
      { "name": "Canned or jarred hungarian wax peppers", "code": "50465524" },
      { "name": "Canned or jarred jalapeno peppers", "code": "50465525" },
      { "name": "Canned or jarred long hot peppers", "code": "50465526" },
      { "name": "Canned or jarred mirasol peppers", "code": "50465527" },
      { "name": "Canned or jarred pasilla peppers", "code": "50465528" },
      { "name": "Canned or jarred peperoncini peppers", "code": "50465529" },
      { "name": "Canned or jarred pequin peppers", "code": "50465530" },
      { "name": "Canned or jarred pimiento peppers", "code": "50465531" },
      { "name": "Canned or jarred poblano peppers", "code": "50465532" },
      { "name": "Canned or jarred scotch bonnet peppers", "code": "50465533" },
      { "name": "Canned or jarred serrano peppers", "code": "50465534" },
      { "name": "Canned or jarred tabasco peppers", "code": "50465535" },
      { "name": "Canned or jarred tai peppers", "code": "50465536" },
      { "name": "Canned or jarred tepin peppers", "code": "50465537" },
      { "name": "Canned or jarred potatoes", "code": "50465600" },
      { "name": "Canned or jarred long white potatoes", "code": "50465601" },
      { "name": "Canned or jarred round white potatoes", "code": "50465602" },
      { "name": "Canned or jarred round red potatoes", "code": "50465603" },
      { "name": "Canned or jarred russet potatoes", "code": "50465604" },
      { "name": "Canned or jarred purple potatoes", "code": "50465605" },
      { "name": "Canned or jarred yellow potatoes", "code": "50465606" },
      { "name": "Canned or jarred new potatoes", "code": "50465607" },
      { "name": "Canned or jarred specialty potatoes", "code": "50465608" },
      { "name": "Canned or jarred rutabagas", "code": "50465700" },
      { "name": "Canned or jarred acme rutabagas", "code": "50465701" },
      { "name": "Canned or jarred angela rutabagas", "code": "50465702" },
      { "name": "Canned or jarred best of all rutabagas", "code": "50465703" },
      { "name": "Canned or jarred marian rutabagas", "code": "50465704" },
      { "name": "Canned or jarred sea vegetables", "code": "50465800" },
      { "name": "Canned or jarred agar-agar", "code": "50465801" },
      { "name": "Canned or jarred arame", "code": "50465802" },
      { "name": "Canned or jarred dulse", "code": "50465803" },
      { "name": "Canned or jarred haricot vert de mer", "code": "50465804" },
      { "name": "Canned or jarred hijiki", "code": "50465805" },
      { "name": "Canned or jarred irish moss", "code": "50465806" },
      { "name": "Canned or jarred kelp", "code": "50465807" },
      { "name": "Canned or jarred laver", "code": "50465808" },
      { "name": "Canned or jarred nori", "code": "50465809" },
      { "name": "Canned or jarred red algae", "code": "50465810" },
      { "name": "Canned or jarred sea kale", "code": "50465811" },
      { "name": "Canned or jarred sea lettuce", "code": "50465812" },
      { "name": "Canned or jarred seaweeds", "code": "50465813" },
      { "name": "Canned or jarred spirulina", "code": "50465814" },
      { "name": "Canned or jarred susabi nori", "code": "50465815" },
      { "name": "Canned or jarred wakame", "code": "50465816" },
      { "name": "Canned or jarred shallots", "code": "50465900" },
      { "name": "Canned or jarred atlantic shallots", "code": "50465901" },
      { "name": "Canned or jarred creation shallots", "code": "50465902" },
      {
        "name": "Canned or jarred drittler white nest shallots",
        "code": "50465903"
      },
      {
        "name": "Canned or jarred giant yellow improved shallots",
        "code": "50465904"
      },
      {
        "name": "Canned or jarred golden gourmet shallots",
        "code": "50465905"
      },
      {
        "name": "Canned or jarred grise de bagnolet shallots",
        "code": "50465906"
      },
      {
        "name": "Canned or jarred hative de niort shallots",
        "code": "50465907"
      },
      { "name": "Canned or jarred pikant shallots", "code": "50465908" },
      { "name": "Canned or jarred red potato onions", "code": "50465909" },
      { "name": "Canned or jarred sante shallots", "code": "50465910" },
      { "name": "Canned or jarred topper shallots", "code": "50465911" },
      { "name": "Canned or jarred sorrels", "code": "50466000" },
      { "name": "Canned or jarred dock sorrel", "code": "50466001" },
      { "name": "Canned or jarred garden sorrel", "code": "50466002" },
      { "name": "Canned or jarred sheep sorrel", "code": "50466003" },
      { "name": "Canned or jarred wood sorrel", "code": "50466004" },
      { "name": "Canned or jarred spinaches", "code": "50466100" },
      { "name": "Canned or jarred america spinach", "code": "50466101" },
      { "name": "Canned or jarred bloomsdale spinach", "code": "50466102" },
      { "name": "Canned or jarred giant winter spinach", "code": "50466103" },
      { "name": "Canned or jarred horenso spinach", "code": "50466104" },
      { "name": "Canned or jarred iceplant spinach", "code": "50466105" },
      {
        "name": "Canned or jarred lamb's quarters spinach",
        "code": "50466106"
      },
      { "name": "Canned or jarred malabar spinach", "code": "50466107" },
      { "name": "Canned or jarred medania spinach", "code": "50466108" },
      { "name": "Canned or jarred new zealand spinach", "code": "50466109" },
      { "name": "Canned or jarred orach spinach", "code": "50466110" },
      { "name": "Canned or jarred savoy spinach", "code": "50466111" },
      { "name": "Canned or jarred sigmaleaf spinach", "code": "50466112" },
      { "name": "Canned or jarred space spinach", "code": "50466113" },
      { "name": "Canned or jarred trinidad spinach", "code": "50466114" },
      { "name": "Canned or jarred water spinach", "code": "50466115" },
      { "name": "Canned or jarred wild spinach", "code": "50466116" },
      {
        "name": "Canned or jarred summer squashes and summer pumpkins",
        "code": "50466200"
      },
      { "name": "Canned or jarred boston marrow squash", "code": "50466201" },
      { "name": "Canned or jarred butternut squash", "code": "50466202" },
      {
        "name": "Canned or jarred costata romanesca squash",
        "code": "50466203"
      },
      { "name": "Canned or jarred crookneck squash", "code": "50466204" },
      { "name": "Canned or jarred cucuzza squash", "code": "50466205" },
      { "name": "Canned or jarred delicata squash", "code": "50466206" },
      { "name": "Canned or jarred delicious squash", "code": "50466207" },
      {
        "name": "Canned or jarred early golden summer crookneck squash",
        "code": "50466208"
      },
      {
        "name": "Canned or jarred early prolific straight neck squash",
        "code": "50466209"
      },
      { "name": "Canned or jarred gold squash", "code": "50466210" },
      { "name": "Canned or jarred jack be little squash", "code": "50466211" },
      { "name": "Canned or jarred kentucky field squash", "code": "50466212" },
      { "name": "Canned or jarred marrow squash", "code": "50466213" },
      { "name": "Canned or jarred middle eastern squash", "code": "50466214" },
      { "name": "Canned or jarred miniature squash", "code": "50466215" },
      { "name": "Canned or jarred orangetti squash", "code": "50466216" },
      { "name": "Canned or jarred pattypan squash", "code": "50466217" },
      { "name": "Canned or jarred rondini squash", "code": "50466218" },
      { "name": "Canned or jarred round squash", "code": "50466219" },
      { "name": "Canned or jarred spaghetti squash", "code": "50466220" },
      { "name": "Canned or jarred stripetti squash", "code": "50466221" },
      { "name": "Canned or jarred sugar loaf squash", "code": "50466222" },
      { "name": "Canned or jarred sweet dumpling squash", "code": "50466223" },
      { "name": "Canned or jarred triple treat squash", "code": "50466224" },
      {
        "name": "Canned or jarred waltham butternut squash",
        "code": "50466225"
      },
      {
        "name": "Canned or jarred yellow bush scallop squash",
        "code": "50466226"
      },
      {
        "name": "Canned or jarred yellow straightneck squash",
        "code": "50466227"
      },
      { "name": "Canned or jarred zephyr squash", "code": "50466228" },
      { "name": "Canned or jarred zucchini squash", "code": "50466229" },
      { "name": "Canned or jarred sweet potatoes", "code": "50466300" },
      {
        "name": "Canned or jarred beauregard sweet potatoes",
        "code": "50466301"
      },
      {
        "name": "Canned or jarred centennial sweet potatoes",
        "code": "50466302"
      },
      { "name": "Canned or jarred diane sweet potatoes", "code": "50466303" },
      { "name": "Canned or jarred garnet sweet potatoes", "code": "50466304" },
      {
        "name": "Canned or jarred georgia red sweet potatoes",
        "code": "50466305"
      },
      {
        "name": "Canned or jarred goldensweet sweet potatoes",
        "code": "50466306"
      },
      { "name": "Canned or jarred hanna sweet potatoes", "code": "50466307" },
      {
        "name": "Canned or jarred japanese sweet potatoes",
        "code": "50466308"
      },
      { "name": "Canned or jarred jersey sweet potatoes", "code": "50466309" },
      { "name": "Canned or jarred jewel sweet potatoes", "code": "50466310" },
      {
        "name": "Canned or jarred maryland red sweet potatoes",
        "code": "50466311"
      },
      {
        "name": "Canned or jarred nemagold sweet potatoes",
        "code": "50466312"
      },
      { "name": "Canned or jarred o'henry sweet potatoes", "code": "50466313" },
      {
        "name": "Canned or jarred okinawan sweet potatoes",
        "code": "50466314"
      },
      { "name": "Canned or jarred orange sweet potatoes", "code": "50466315" },
      {
        "name": "Canned or jarred oriental sweet potatoes",
        "code": "50466316"
      },
      {
        "name": "Canned or jarred red jersey sweet potatoes",
        "code": "50466317"
      },
      { "name": "Canned or jarred red mar sweet potatoes", "code": "50466318" },
      { "name": "Canned or jarred redglow sweet potatoes", "code": "50466319" },
      {
        "name": "Canned or jarred yellow jersey sweet potatoes",
        "code": "50466320"
      },
      { "name": "Canned or jarred tomatoes", "code": "50466400" },
      { "name": "Canned or jarred ailsa craig tomatoes", "code": "50466401" },
      { "name": "Canned or jarred alicante tomatoes", "code": "50466402" },
      { "name": "Canned or jarred black plum tomatoes", "code": "50466403" },
      { "name": "Canned or jarred brandywine tomatoes", "code": "50466404" },
      { "name": "Canned or jarred cherry belle tomatoes", "code": "50466405" },
      { "name": "Canned or jarred cherry tomatoes", "code": "50466406" },
      { "name": "Canned or jarred delicious tomatoes", "code": "50466407" },
      { "name": "Canned or jarred dombito tomatoes", "code": "50466408" },
      {
        "name": "Canned or jarred gardener's delight tomatoes",
        "code": "50466409"
      },
      { "name": "Canned or jarred grape tomatoes", "code": "50466410" },
      { "name": "Canned or jarred green tomatoes", "code": "50466411" },
      {
        "name": "Canned or jarred marmande super tomatoes",
        "code": "50466412"
      },
      {
        "name": "Canned or jarred marvel striped traditional tomatoes",
        "code": "50466413"
      },
      { "name": "Canned or jarred minibel tomatoes", "code": "50466414" },
      { "name": "Canned or jarred oaxacan pink tomatoes", "code": "50466415" },
      { "name": "Canned or jarred red alert tomatoes", "code": "50466416" },
      { "name": "Canned or jarred roma vf tomatoes", "code": "50466417" },
      { "name": "Canned or jarred san marzano tomatoes", "code": "50466418" },
      { "name": "Canned or jarred shirley tomatoes", "code": "50466419" },
      {
        "name": "Canned or jarred siberia tomato tomatoes",
        "code": "50466420"
      },
      {
        "name": "Canned or jarred super beefsteak tomatoes",
        "code": "50466421"
      },
      { "name": "Canned or jarred tigerella tomatoes", "code": "50466422" },
      { "name": "Canned or jarred tiny tim tomatoes", "code": "50466423" },
      { "name": "Canned or jarred tumbler tomatoes", "code": "50466424" },
      {
        "name": "Canned or jarred yellow cocktail tomatoes",
        "code": "50466425"
      },
      {
        "name": "Canned or jarred yellow pear-shaped tomatoes",
        "code": "50466426"
      },
      {
        "name": "Canned or jarred yellow perfection tomatoes",
        "code": "50466427"
      },
      { "name": "Canned or jarred turnip greens", "code": "50466500" },
      { "name": "Canned or jarred green globe turnips", "code": "50466501" },
      { "name": "Canned or jarred golden ball turnips", "code": "50466502" },
      {
        "name": "Canned or jarred manchester market turnips",
        "code": "50466503"
      },
      {
        "name": "Canned or jarred purple top milan turnips",
        "code": "50466504"
      },
      {
        "name": "Canned or jarred purple top white turnips",
        "code": "50466505"
      },
      { "name": "Canned or jarred snowball turnips", "code": "50466506" },
      { "name": "Canned or jarred tokyo turnip", "code": "50466507" },
      { "name": "Canned or jarred tokyo cross turnips", "code": "50466508" },
      {
        "name": "Canned or jarred winter squashes and winter pumpkins",
        "code": "50466600"
      },
      { "name": "Canned or jarred acorn squash", "code": "50466601" },
      { "name": "Canned or jarred atlantic giant squash", "code": "50466602" },
      { "name": "Canned or jarred banana pink squash", "code": "50466603" },
      { "name": "Canned or jarred big max squash", "code": "50466604" },
      { "name": "Canned or jarred calabaza squash", "code": "50466605" },
      { "name": "Canned or jarred carnival squash", "code": "50466606" },
      { "name": "Canned or jarred cheese pumpkin", "code": "50466607" },
      { "name": "Canned or jarred crown prince squash", "code": "50466608" },
      { "name": "Canned or jarred curcibita squash", "code": "50466609" },
      { "name": "Canned or jarred cushaw squash", "code": "50466610" },
      { "name": "Canned or jarred giant pumpkin squash", "code": "50466611" },
      { "name": "Canned or jarred hubbard squash", "code": "50466612" },
      { "name": "Canned or jarred jarrahdale squash", "code": "50466613" },
      { "name": "Canned or jarred kabocha squash", "code": "50466614" },
      { "name": "Canned or jarred queensland blue squash", "code": "50466615" },
      {
        "name": "Canned or jarred rouge vif d'etampes squash",
        "code": "50466616"
      },
      { "name": "Canned or jarred turks turban squash", "code": "50466617" },
      { "name": "Canned or jarred valenciano squash", "code": "50466618" },
      { "name": "Canned or jarred warted hubbard squash", "code": "50466619" },
      {
        "name": "Canned or jarred whangaparoa crown pumpkin",
        "code": "50466620"
      },
      { "name": "Canned or jarred yams", "code": "50466700" },
      { "name": "Canned or jarred african bitter yams", "code": "50466701" },
      { "name": "Canned or jarred asiatic bitter yams", "code": "50466702" },
      { "name": "Canned or jarred chinese yams", "code": "50466703" },
      { "name": "Canned or jarred globe yams", "code": "50466704" },
      { "name": "Canned or jarred greater yams", "code": "50466705" },
      { "name": "Canned or jarred japanese yams", "code": "50466706" },
      { "name": "Canned or jarred lesser yams", "code": "50466707" },
      { "name": "Canned or jarred potato yams", "code": "50466708" },
      { "name": "Canned or jarred white guinea yams", "code": "50466709" },
      { "name": "Canned or jarred yellow guinea yams", "code": "50466710" },
      { "name": "Canned or jarred nominant vegetables", "code": "50466800" },
      { "name": "Canned or jarred alfalfa", "code": "50466801" },
      { "name": "Canned or jarred aloe leaves", "code": "50466802" },
      { "name": "Canned or jarred apio", "code": "50466803" },
      { "name": "Canned or jarred arrow root", "code": "50466804" },
      { "name": "Canned or jarred arrowhead", "code": "50466805" },
      { "name": "Canned or jarred arugula", "code": "50466806" },
      { "name": "Canned or jarred arum", "code": "50466807" },
      { "name": "Canned or jarred bamboo shoots", "code": "50466808" },
      { "name": "Canned or jarred banana leaves", "code": "50466809" },
      { "name": "Canned or jarred batatas", "code": "50466810" },
      { "name": "Canned or jarred bean sprouts", "code": "50466811" },
      { "name": "Canned or jarred beet tops", "code": "50466812" },
      { "name": "Canned or jarred bittermelon", "code": "50466813" },
      { "name": "Canned or jarred caperberries", "code": "50466814" },
      { "name": "Canned or jarred carob", "code": "50466815" },
      { "name": "Canned or jarred cha-om", "code": "50466816" },
      { "name": "Canned or jarred chaoyotes", "code": "50466817" },
      { "name": "Canned or jarred chickpeas", "code": "50466818" },
      { "name": "Canned or jarred chrysanthemum greens", "code": "50466819" },
      { "name": "Canned or jarred dandelion greens", "code": "50466820" },
      { "name": "Canned or jarred dandelions", "code": "50466821" },
      { "name": "Canned or jarred dasheen", "code": "50466822" },
      { "name": "Canned or jarred dau mue or pea tips", "code": "50466823" },
      { "name": "Canned or jarred diakon", "code": "50466824" },
      { "name": "Canned or jarred donqua", "code": "50466825" },
      { "name": "Canned or jarred fiddlehead ferns", "code": "50466826" },
      { "name": "Canned or jarred gai choy", "code": "50466827" },
      { "name": "Canned or jarred gailon", "code": "50466828" },
      { "name": "Canned or jarred galanga", "code": "50466829" },
      { "name": "Canned or jarred ginger root", "code": "50466830" },
      { "name": "Canned or jarred gobo", "code": "50466831" },
      { "name": "Canned or jarred hop sprouts", "code": "50466832" },
      { "name": "Canned or jarred horseradish", "code": "50466833" },
      { "name": "Canned or jarred jicama", "code": "50466834" },
      { "name": "Canned or jarred kudzu", "code": "50466835" },
      { "name": "Canned or jarred lily bulb", "code": "50466836" },
      { "name": "Canned or jarred linkok", "code": "50466837" },
      { "name": "Canned or jarred lo bok", "code": "50466838" },
      { "name": "Canned or jarred long beans", "code": "50466839" },
      { "name": "Canned or jarred lotus root", "code": "50466840" },
      { "name": "Canned or jarred maguey leaves", "code": "50466841" },
      { "name": "Canned or jarred mallows", "code": "50466842" },
      { "name": "Canned or jarred mamey sapote", "code": "50466843" },
      { "name": "Canned or jarred moap", "code": "50466844" },
      { "name": "Canned or jarred moo", "code": "50466845" },
      { "name": "Canned or jarred moqua", "code": "50466846" },
      { "name": "Canned or jarred opos", "code": "50466847" },
      { "name": "Canned or jarred palm hearts", "code": "50466848" },
      { "name": "Canned or jarred paprika", "code": "50466849" },
      { "name": "Canned or jarred purslane", "code": "50466850" },
      { "name": "Canned or jarred raddichios", "code": "50466851" },
      { "name": "Canned or jarred sinquas", "code": "50466852" },
      { "name": "Canned or jarred soybeans", "code": "50466853" },
      { "name": "Canned or jarred spoonwart", "code": "50466854" },
      { "name": "Canned or jarred tassle grape-hyacinth", "code": "50466855" },
      { "name": "Canned or jarred taro", "code": "50466856" },
      { "name": "Canned or jarred taro leaf", "code": "50466857" },
      { "name": "Canned or jarred taro shoot", "code": "50466858" },
      { "name": "Canned or jarred tepeguaje", "code": "50466859" },
      { "name": "Canned or jarred tendergreen", "code": "50466860" },
      { "name": "Canned or jarred tindora", "code": "50466861" },
      { "name": "Canned or jarred tree onion", "code": "50466862" },
      { "name": "Canned or jarred udo", "code": "50466863" },
      { "name": "Canned or jarred water chestnuts", "code": "50466864" },
      { "name": "Canned or jarred yampi", "code": "50466865" },
      { "name": "Canned or jarred yautia", "code": "50466866" },
      { "name": "Canned or jarred yu choy", "code": "50466867" },
      { "name": "Canned or jarred yuca", "code": "50466868" },
      { "name": "Canned or jarred sugar peas", "code": "50466900" },
      { "name": "Canned or jarred bikini peas", "code": "50466901" },
      { "name": "Canned or jarred cavalier peas", "code": "50466902" },
      { "name": "Canned or jarred daisy peas", "code": "50466903" },
      { "name": "Canned or jarred darfon peas", "code": "50466904" },
      { "name": "Canned or jarred early onward peas", "code": "50466905" },
      { "name": "Canned or jarred feltham first peas", "code": "50466906" },
      { "name": "Canned or jarred hurst green shaft peas", "code": "50466907" },
      { "name": "Canned or jarred oregon sugar pod peas", "code": "50466908" },
      { "name": "Canned or jarred prince albert peas", "code": "50466909" },
      { "name": "Canned or jarred reuzensuiker peas", "code": "50466910" },
      { "name": "Canned or jarred kimchis", "code": "50467000" },
      { "name": "Baechukimchis", "code": "50467001" },
      { "name": "Young radish kimchis", "code": "50467002" },
      { "name": "Watery radish kimchis", "code": "50467003" },
      { "name": "Pickled young radishes", "code": "50467004" },
      { "name": "Diced radish kimchis", "code": "50467005" },
      { "name": "Canned jangjorims", "code": "50467006" },
      { "name": "Canned or jarred organic vegetables", "code": "50470000" },
      { "name": "Canned or jarred organic artichokes", "code": "50471500" },
      {
        "name": "Canned or jarred organic brittany artichokes",
        "code": "50471501"
      },
      {
        "name": "Canned or jarred organic catanese artichokes",
        "code": "50471502"
      },
      {
        "name": "Canned or jarred organic french artichokes",
        "code": "50471503"
      },
      {
        "name": "Canned or jarred organic green globe artichokes",
        "code": "50471504"
      },
      {
        "name": "Canned or jarred organic gros camus de bretagne artichokes",
        "code": "50471505"
      },
      {
        "name": "Canned or jarred organic midi artichokes",
        "code": "50471506"
      },
      {
        "name": "Canned or jarred organic purple globe artichokes",
        "code": "50471507"
      },
      {
        "name": "Canned or jarred organic purple sicilian artichokes",
        "code": "50471508"
      },
      {
        "name": "Canned or jarred organic romanesco artichokes",
        "code": "50471509"
      },
      {
        "name": "Canned or jarred organic spinoso sardo artichokes",
        "code": "50471510"
      },
      {
        "name": "Canned or jarred organic vert de laon artichokes",
        "code": "50471511"
      },
      {
        "name": "Canned or jarred organic violetta di chioggia artichokes",
        "code": "50471512"
      },
      {
        "name": "Canned or jarred organic violetto di toscana artichokes",
        "code": "50471513"
      },
      { "name": "Canned or jarred organic asparagus", "code": "50471600" },
      {
        "name": "Canned or jarred organic connover's colossal asparagus",
        "code": "50471601"
      },
      {
        "name": "Canned or jarred organic franklin asparagus",
        "code": "50471602"
      },
      {
        "name": "Canned or jarred organic giant mammoth asparagus",
        "code": "50471603"
      },
      {
        "name": "Canned or jarred organic lucullus asparagus",
        "code": "50471604"
      },
      {
        "name": "Canned or jarred organic martha washington asparagus",
        "code": "50471605"
      },
      { "name": "Canned or jarred organic avocados", "code": "50471700" },
      {
        "name": "Canned or jarred organic ajax b-7 avocados",
        "code": "50471701"
      },
      { "name": "Canned or jarred organic arue avocados", "code": "50471702" },
      { "name": "Canned or jarred organic bacon avocados", "code": "50471703" },
      { "name": "Canned or jarred organic benik avocados", "code": "50471704" },
      {
        "name": "Canned or jarred organic bernecker avocados",
        "code": "50471705"
      },
      { "name": "Canned or jarred organic beta avocados", "code": "50471706" },
      {
        "name": "Canned or jarred organic biondo avocados",
        "code": "50471707"
      },
      {
        "name": "Canned or jarred organic black prince avocados",
        "code": "50471708"
      },
      { "name": "Canned or jarred organic blair avocados", "code": "50471709" },
      {
        "name": "Canned or jarred organic blair booth avocados",
        "code": "50471710"
      },
      {
        "name": "Canned or jarred organic booth 1 avocados",
        "code": "50471711"
      },
      {
        "name": "Canned or jarred organic booth 3 avocados",
        "code": "50471712"
      },
      {
        "name": "Canned or jarred organic booth 5 avocados",
        "code": "50471713"
      },
      {
        "name": "Canned or jarred organic booth 7 avocados",
        "code": "50471714"
      },
      {
        "name": "Canned or jarred organic booth 8 avocados",
        "code": "50471715"
      },
      {
        "name": "Canned or jarred organic brooks 1978 avocados",
        "code": "50471716"
      },
      {
        "name": "Canned or jarred organic brookslate avocados",
        "code": "50471717"
      },
      {
        "name": "Canned or jarred organic california haas avocados",
        "code": "50471718"
      },
      {
        "name": "Canned or jarred organic catalina avocados",
        "code": "50471719"
      },
      { "name": "Canned or jarred organic chica avocados", "code": "50471720" },
      {
        "name": "Canned or jarred organic choquette avocados",
        "code": "50471721"
      },
      {
        "name": "Canned or jarred organic christina avocados",
        "code": "50471722"
      },
      {
        "name": "Canned or jarred organic collinson avocados",
        "code": "50471723"
      },
      {
        "name": "Canned or jarred organic donnie avocados",
        "code": "50471724"
      },
      {
        "name": "Canned or jarred organic dr dupuis number 2 avocados",
        "code": "50471725"
      },
      {
        "name": "Canned or jarred organic dr dupuis avocados",
        "code": "50471726"
      },
      {
        "name": "Canned or jarred organic ettinger avocados",
        "code": "50471727"
      },
      { "name": "Canned or jarred organic fuchs avocados", "code": "50471728" },
      {
        "name": "Canned or jarred organic fuchs gwen avocados",
        "code": "50471729"
      },
      {
        "name": "Canned or jarred organic fuerte avocados",
        "code": "50471730"
      },
      {
        "name": "Canned or jarred organic gorham avocados",
        "code": "50471731"
      },
      {
        "name": "Canned or jarred organic gossman avocados",
        "code": "50471732"
      },
      {
        "name": "Canned or jarred organic guatemalan seedling avocados",
        "code": "50471733"
      },
      { "name": "Canned or jarred organic hall avocados", "code": "50471734" },
      {
        "name": "Canned or jarred organic hardee avocados",
        "code": "50471735"
      },
      { "name": "Canned or jarred organic haas avocados", "code": "50471736" },
      {
        "name": "Canned or jarred organic herman avocados",
        "code": "50471737"
      },
      {
        "name": "Canned or jarred organic hickson avocados",
        "code": "50471738"
      },
      { "name": "Canned or jarred organic k-5 avocados", "code": "50471739" },
      { "name": "Canned or jarred organic k-9 avocados", "code": "50471740" },
      {
        "name": "Canned or jarred organic lamb haas avocados",
        "code": "50471741"
      },
      { "name": "Canned or jarred organic leona avocados", "code": "50471742" },
      {
        "name": "Canned or jarred organic leona linda avocados",
        "code": "50471743"
      },
      {
        "name": "Canned or jarred organic lisa p avocados",
        "code": "50471744"
      },
      {
        "name": "Canned or jarred organic lisa loretta avocados",
        "code": "50471745"
      },
      {
        "name": "Canned or jarred organic loretta avocados",
        "code": "50471746"
      },
      { "name": "Canned or jarred organic lula avocados", "code": "50471747" },
      {
        "name": "Canned or jarred organic lula macarthur avocados",
        "code": "50471748"
      },
      {
        "name": "Canned or jarred organic marcus avocados",
        "code": "50471749"
      },
      {
        "name": "Canned or jarred organic melendez avocados",
        "code": "50471750"
      },
      {
        "name": "Canned or jarred organic meya p avocados",
        "code": "50471751"
      },
      {
        "name": "Canned or jarred organic miguel p avocados",
        "code": "50471752"
      },
      {
        "name": "Canned or jarred organic monroe avocados",
        "code": "50471753"
      },
      {
        "name": "Canned or jarred organic murrieta green avocados",
        "code": "50471754"
      },
      { "name": "Canned or jarred organic nabal avocados", "code": "50471755" },
      { "name": "Canned or jarred organic nadir avocados", "code": "50471756" },
      {
        "name": "Canned or jarred organic nesbitt avocados",
        "code": "50471757"
      },
      {
        "name": "Canned or jarred organic peterson avocados",
        "code": "50471758"
      },
      {
        "name": "Canned or jarred organic pinelli avocados",
        "code": "50471759"
      },
      {
        "name": "Canned or jarred organic pinkerton avocados",
        "code": "50471760"
      },
      {
        "name": "Canned or jarred organic pollock avocados",
        "code": "50471761"
      },
      {
        "name": "Canned or jarred organic puebla avocados",
        "code": "50471762"
      },
      { "name": "Canned or jarred organic reed avocados", "code": "50471763" },
      { "name": "Canned or jarred organic rue avocados", "code": "50471764" },
      {
        "name": "Canned or jarred organic ruehle avocados",
        "code": "50471765"
      },
      { "name": "Canned or jarred organic ryan avocados", "code": "50471766" },
      {
        "name": "Canned or jarred organic semil 34 avocados",
        "code": "50471767"
      },
      {
        "name": "Canned or jarred organic semil 43 avocados",
        "code": "50471768"
      },
      {
        "name": "Canned or jarred organic simmonds avocados",
        "code": "50471769"
      },
      {
        "name": "Canned or jarred organic simpson avocados",
        "code": "50471770"
      },
      {
        "name": "Canned or jarred organic taylor avocados",
        "code": "50471771"
      },
      {
        "name": "Canned or jarred organic tonnage avocados",
        "code": "50471772"
      },
      { "name": "Canned or jarred organic tower avocados", "code": "50471773" },
      {
        "name": "Canned or jarred organic tower li avocados",
        "code": "50471774"
      },
      { "name": "Canned or jarred organic trapp avocados", "code": "50471775" },
      {
        "name": "Canned or jarred organic west indian seedling avocados",
        "code": "50471776"
      },
      {
        "name": "Canned or jarred organic wagner avocados",
        "code": "50471777"
      },
      {
        "name": "Canned or jarred organic waldin avocados",
        "code": "50471778"
      },
      { "name": "Canned or jarred organic wurtz avocados", "code": "50471779" },
      { "name": "Canned or jarred organic zio p avocados", "code": "50471780" },
      { "name": "Canned or jarred organic ziu avocados", "code": "50471781" },
      {
        "name": "Canned or jarred organic zutano avocados",
        "code": "50471782"
      },
      { "name": "Canned or jarred organic beans", "code": "50471800" },
      {
        "name": "Canned or jarred organic anasazi or aztec beans",
        "code": "50471801"
      },
      {
        "name": "Canned or jarred organic appaloosa beans",
        "code": "50471802"
      },
      { "name": "Canned or jarred organic azuki beans", "code": "50471803" },
      { "name": "Canned or jarred organic barlotti beans", "code": "50471804" },
      {
        "name": "Canned or jarred organic black appaloosa beans",
        "code": "50471805"
      },
      { "name": "Canned or jarred organic black beans", "code": "50471806" },
      {
        "name": "Canned or jarred organic black gram beans",
        "code": "50471807"
      },
      {
        "name": "Canned or jarred organic black shackamaxon beans",
        "code": "50471808"
      },
      {
        "name": "Canned or jarred organic blackeyed beans",
        "code": "50471809"
      },
      { "name": "Canned or jarred organic bobby beans", "code": "50471810" },
      { "name": "Canned or jarred organic bolita beans", "code": "50471811" },
      {
        "name": "Canned or jarred organic brown lazy wife beans",
        "code": "50471812"
      },
      { "name": "Canned or jarred organic calypso beans", "code": "50471813" },
      {
        "name": "Canned or jarred organic cannellini beans",
        "code": "50471814"
      },
      { "name": "Canned or jarred organic castor beans", "code": "50471815" },
      {
        "name": "Canned or jarred organic china yellow beans",
        "code": "50471816"
      },
      {
        "name": "Canned or jarred organic dragon tongue beans",
        "code": "50471817"
      },
      {
        "name": "Canned or jarred organic european soldier beans",
        "code": "50471818"
      },
      { "name": "Canned or jarred organic fava beans", "code": "50471819" },
      {
        "name": "Canned or jarred organic flageolet beans",
        "code": "50471820"
      },
      {
        "name": "Canned or jarred organic french horticultural beans",
        "code": "50471821"
      },
      {
        "name": "Canned or jarred organic french navy beans",
        "code": "50471822"
      },
      {
        "name": "Canned or jarred organic giant white coco beans",
        "code": "50471823"
      },
      { "name": "Canned or jarred organic green beans", "code": "50471824" },
      {
        "name": "Canned or jarred organic green romano beans",
        "code": "50471825"
      },
      { "name": "Canned or jarred organic guar gum beans", "code": "50471826" },
      { "name": "Canned or jarred organic haricot beans", "code": "50471827" },
      { "name": "Canned or jarred organic hyacinth beans", "code": "50471828" },
      {
        "name": "Canned or jarred organic italian type beans",
        "code": "50471829"
      },
      {
        "name": "Canned or jarred organic jackson wonder beans",
        "code": "50471830"
      },
      {
        "name": "Canned or jarred organic jacob's cattle beans",
        "code": "50471831"
      },
      {
        "name": "Canned or jarred organic kentucky wonder beans",
        "code": "50471832"
      },
      { "name": "Canned or jarred organic kidney beans", "code": "50471833" },
      { "name": "Canned or jarred organic lima beans", "code": "50471834" },
      {
        "name": "Canned or jarred organic madeira/madera beans",
        "code": "50471835"
      },
      { "name": "Canned or jarred organic marrow beans", "code": "50471836" },
      { "name": "Canned or jarred organic mat beans", "code": "50471837" },
      {
        "name": "Canned or jarred organic monstoller wild goose beans",
        "code": "50471838"
      },
      {
        "name": "Canned or jarred organic mortgage lifter beans",
        "code": "50471839"
      },
      { "name": "Canned or jarred organic moth beans", "code": "50471840" },
      { "name": "Canned or jarred organic mung beans", "code": "50471841" },
      {
        "name": "Canned or jarred organic munsi wolf bean",
        "code": "50471842"
      },
      { "name": "Canned or jarred organic nuna beans", "code": "50471843" },
      { "name": "Canned or jarred organic pinto beans", "code": "50471844" },
      { "name": "Canned or jarred organic pole beans", "code": "50471845" },
      { "name": "Canned or jarred organic runner beans", "code": "50471846" },
      { "name": "Canned or jarred organic string beans", "code": "50471847" },
      { "name": "Canned or jarred organic tamarind beans", "code": "50471848" },
      { "name": "Canned or jarred organic tonka beans", "code": "50471849" },
      { "name": "Canned or jarred organic wax beans", "code": "50471850" },
      { "name": "Canned or jarred organic winged beans", "code": "50471851" },
      {
        "name": "Canned or jarred organic yard long beans",
        "code": "50471852"
      },
      { "name": "Canned or jarred organic beets", "code": "50471900" },
      { "name": "Canned or jarred organic action beets", "code": "50471901" },
      {
        "name": "Canned or jarred organic albina vereduna beets",
        "code": "50471902"
      },
      {
        "name": "Canned or jarred organic barbabietola di chioggia beets",
        "code": "50471903"
      },
      { "name": "Canned or jarred organic boltardy beets", "code": "50471904" },
      { "name": "Canned or jarred organic bonel beets", "code": "50471905" },
      {
        "name": "Canned or jarred organic burpees golden beets",
        "code": "50471906"
      },
      {
        "name": "Canned or jarred organic cheltenham green top beets",
        "code": "50471907"
      },
      {
        "name": "Canned or jarred organic cheltenham mono beets",
        "code": "50471908"
      },
      { "name": "Canned or jarred organic chioggia beets", "code": "50471909" },
      { "name": "Canned or jarred organic cylindra beets", "code": "50471910" },
      { "name": "Canned or jarred organic d'egypte beets", "code": "50471911" },
      {
        "name": "Canned or jarred organic detroit 2 dark red beets",
        "code": "50471912"
      },
      {
        "name": "Canned or jarred organic detroit 2 little ball beets",
        "code": "50471913"
      },
      {
        "name": "Canned or jarred organic egyptian flat beets",
        "code": "50471914"
      },
      {
        "name": "Canned or jarred organic egyptian turnip rooted beets",
        "code": "50471915"
      },
      {
        "name": "Canned or jarred organic formanova beets",
        "code": "50471916"
      },
      { "name": "Canned or jarred organic forono beets", "code": "50471917" },
      { "name": "Canned or jarred organic monaco beets", "code": "50471918" },
      { "name": "Canned or jarred organic monogram beets", "code": "50471919" },
      { "name": "Canned or jarred organic pronto beets", "code": "50471920" },
      { "name": "Canned or jarred organic regalia beets", "code": "50471921" },
      { "name": "Canned or jarred organic sugar beets", "code": "50471922" },
      { "name": "Canned or jarred organic broccoli", "code": "50472000" },
      { "name": "Canned or jarred organic broccolini", "code": "50472001" },
      {
        "name": "Canned or jarred organic broccoli romanesco",
        "code": "50472002"
      },
      { "name": "Canned or jarred organic broccoli raab", "code": "50472003" },
      {
        "name": "Canned or jarred organic chinese broccoli",
        "code": "50472004"
      },
      {
        "name": "Canned or jarred organic brussel sprouts",
        "code": "50472100"
      },
      {
        "name": "Canned or jarred organic citadel brussel sprouts",
        "code": "50472101"
      },
      {
        "name": "Canned or jarred organic falstaff brussel sprouts",
        "code": "50472102"
      },
      {
        "name": "Canned or jarred organic oliver brussel sprouts",
        "code": "50472103"
      },
      {
        "name": "Canned or jarred organic peer gynt brussel sprouts",
        "code": "50472104"
      },
      {
        "name": "Canned or jarred organic rampart brussel sprouts",
        "code": "50472105"
      },
      {
        "name": "Canned or jarred organic rubine brussel sprouts",
        "code": "50472106"
      },
      {
        "name": "Canned or jarred organic widgeon brussel sprouts",
        "code": "50472107"
      },
      {
        "name": "Canned or jarred organic bunching onions",
        "code": "50472200"
      },
      {
        "name": "Canned or jarred organic beltsville bunching onions",
        "code": "50472201"
      },
      {
        "name": "Canned or jarred organic feast bunching onions",
        "code": "50472202"
      },
      {
        "name": "Canned or jarred organic ishikura bunching onions",
        "code": "50472203"
      },
      {
        "name": "Canned or jarred organic kyoto market bunching onions",
        "code": "50472204"
      },
      {
        "name": "Canned or jarred organic red beard bunching onions",
        "code": "50472205"
      },
      {
        "name": "Canned or jarred organic redmate bunching onions",
        "code": "50472206"
      },
      {
        "name": "Canned or jarred organic santa claus bunching onions",
        "code": "50472207"
      },
      {
        "name": "Canned or jarred organic tokyo bunching onions",
        "code": "50472208"
      },
      {
        "name": "Canned or jarred organic white lisbon bunching onions",
        "code": "50472209"
      },
      {
        "name": "Canned or jarred organic winter white bunching onions",
        "code": "50472210"
      },
      {
        "name": "Canned or jarred organic winter-over bunching onions",
        "code": "50472211"
      },
      { "name": "Canned or jarred organic cabbages", "code": "50472300" },
      { "name": "Canned or jarred organic black cabbages", "code": "50472301" },
      { "name": "Canned or jarred organic savoy cabbages", "code": "50472302" },
      { "name": "Canned or jarred organic skunk cabbages", "code": "50472303" },
      { "name": "Canned or jarred organic white cabbages", "code": "50472304" },
      { "name": "Canned or jarred organic cardoons", "code": "50472400" },
      {
        "name": "Canned or jarred organic lunghi cardoons",
        "code": "50472401"
      },
      { "name": "Canned or jarred organic gobbi cardoons", "code": "50472402" },
      { "name": "Canned or jarred organic carrots", "code": "50472500" },
      {
        "name": "Canned or jarred organic amsterdam carrots",
        "code": "50472501"
      },
      {
        "name": "Canned or jarred organic autumn king carrots",
        "code": "50472502"
      },
      {
        "name": "Canned or jarred organic berlicum carrots",
        "code": "50472503"
      },
      {
        "name": "Canned or jarred organic chantenay carrots",
        "code": "50472504"
      },
      { "name": "Canned or jarred organic nantes carrots", "code": "50472505" },
      {
        "name": "Canned or jarred organic paris market carrots",
        "code": "50472506"
      },
      { "name": "Canned or jarred organic cauliflowers", "code": "50472600" },
      {
        "name": "Canned or jarred organic all the year round cauliflowers",
        "code": "50472601"
      },
      {
        "name": "Canned or jarred organic alverda cauliflowers",
        "code": "50472602"
      },
      {
        "name": "Canned or jarred organic autumn giant 3 cauliflowers",
        "code": "50472603"
      },
      {
        "name": "Canned or jarred organic dok elgon cauliflowers",
        "code": "50472604"
      },
      {
        "name": "Canned or jarred organic early snowball cauliflowers",
        "code": "50472605"
      },
      {
        "name": "Canned or jarred organic limelight cauliflowers",
        "code": "50472606"
      },
      {
        "name": "Canned or jarred organic minaret cauliflowers",
        "code": "50472607"
      },
      {
        "name": "Canned or jarred organic orange bouquet cauliflowers",
        "code": "50472608"
      },
      {
        "name": "Canned or jarred organic purple cape cauliflowers",
        "code": "50472609"
      },
      {
        "name": "Canned or jarred organic snowball cauliflowers",
        "code": "50472610"
      },
      {
        "name": "Canned or jarred organic walcheren winter 3 cauliflowers",
        "code": "50472611"
      },
      {
        "name": "Canned or jarred organic white rock cauliflowers",
        "code": "50472612"
      },
      { "name": "Canned or jarred organic celery", "code": "50472700" },
      {
        "name": "Canned or jarred organic celebrity celery",
        "code": "50472701"
      },
      { "name": "Canned or jarred organic celeriac", "code": "50472702" },
      { "name": "Canned or jarred organic chinese celery", "code": "50472703" },
      {
        "name": "Canned or jarred organic french dinant celery",
        "code": "50472704"
      },
      {
        "name": "Canned or jarred organic giant pink celery",
        "code": "50472705"
      },
      {
        "name": "Canned or jarred organic giant red celery",
        "code": "50472706"
      },
      {
        "name": "Canned or jarred organic giant white celery",
        "code": "50472707"
      },
      {
        "name": "Canned or jarred organic golden self-blanching celery",
        "code": "50472708"
      },
      {
        "name": "Canned or jarred organic greensleeves celery",
        "code": "50472709"
      },
      {
        "name": "Canned or jarred organic hopkins fenlander celery",
        "code": "50472710"
      },
      {
        "name": "Canned or jarred organic ivory tower celery",
        "code": "50472711"
      },
      {
        "name": "Canned or jarred organic lathom self-blanching celery",
        "code": "50472712"
      },
      {
        "name": "Canned or jarred organic soup celery d'amsterdam",
        "code": "50472713"
      },
      {
        "name": "Canned or jarred organic standard bearer celery",
        "code": "50472714"
      },
      {
        "name": "Canned or jarred organic tall utah triumph celery",
        "code": "50472715"
      },
      { "name": "Canned or jarred organic chards", "code": "50472800" },
      {
        "name": "Canned or jarred organic bright lights chard",
        "code": "50472801"
      },
      {
        "name": "Canned or jarred organic fordhook giant chard",
        "code": "50472802"
      },
      { "name": "Canned or jarred organic lucullus chard", "code": "50472803" },
      {
        "name": "Canned or jarred organic perpetual spinach chard",
        "code": "50472804"
      },
      { "name": "Canned or jarred organic rhubarb chard", "code": "50472805" },
      { "name": "Canned or jarred organic swiss chard", "code": "50472806" },
      { "name": "Canned or jarred organic vulcan chard", "code": "50472807" },
      {
        "name": "Canned or jarred organic white king chard",
        "code": "50472808"
      },
      { "name": "Canned or jarred organic chicories", "code": "50472900" },
      {
        "name": "Canned or jarred organic broad leaved batavian chicory",
        "code": "50472901"
      },
      {
        "name": "Canned or jarred organic en cornet de bordeaux chicory",
        "code": "50472902"
      },
      {
        "name": "Canned or jarred organic green curled ruffee chicory",
        "code": "50472903"
      },
      {
        "name": "Canned or jarred organic green curled chicory",
        "code": "50472904"
      },
      {
        "name": "Canned or jarred organic ione limnos chicory",
        "code": "50472905"
      },
      {
        "name": "Canned or jarred organic riccia pancalieri chicory",
        "code": "50472906"
      },
      {
        "name": "Canned or jarred organic salad king chicory",
        "code": "50472907"
      },
      { "name": "Canned or jarred organic sanda chicory", "code": "50472908" },
      {
        "name": "Canned or jarred organic scarola verde chicory",
        "code": "50472909"
      },
      {
        "name": "Canned or jarred organic tres fine maraichere chicory",
        "code": "50472910"
      },
      {
        "name": "Canned or jarred organic wallone freisee weschelkopf chicory",
        "code": "50472911"
      },
      {
        "name": "Canned or jarred organic chinese cabbages",
        "code": "50473000"
      },
      { "name": "Canned or jarred organic bok choy", "code": "50473001" },
      {
        "name": "Canned or jarred organic chinese flat-headed cabbage",
        "code": "50473002"
      },
      {
        "name": "Canned or jarred organic chinese flowering cabbage",
        "code": "50473003"
      },
      { "name": "Canned or jarred organic choy sum", "code": "50473004" },
      { "name": "Canned or jarred organic dwarf bok choy", "code": "50473005" },
      {
        "name": "Canned or jarred organic fengshan bok choy",
        "code": "50473006"
      },
      {
        "name": "Canned or jarred organic jade pagoda bok choy",
        "code": "50473007"
      },
      {
        "name": "Canned or jarred organic kasumi bok choy",
        "code": "50473008"
      },
      { "name": "Canned or jarred organic nerva bok choy", "code": "50473009" },
      {
        "name": "Canned or jarred organic rosette bok choy",
        "code": "50473010"
      },
      {
        "name": "Canned or jarred organic ruffles bok choy",
        "code": "50473011"
      },
      {
        "name": "Canned or jarred organic santo serrated leaved cabbage",
        "code": "50473012"
      },
      {
        "name": "Canned or jarred organic shanghai d bok choy",
        "code": "50473013"
      },
      {
        "name": "Canned or jarred organic shantung cabbage",
        "code": "50473014"
      },
      {
        "name": "Canned or jarred organic tip top cabbage",
        "code": "50473015"
      },
      { "name": "Canned or jarred organic yau choy sum", "code": "50473016" },
      { "name": "Canned or jarred organic chives", "code": "50473100" },
      { "name": "Canned or jarred organic chinese chives", "code": "50473101" },
      { "name": "Canned or jarred organic common chives", "code": "50473102" },
      { "name": "Canned or jarred organic corn", "code": "50473200" },
      { "name": "Canned or jarred organic aloha corn", "code": "50473201" },
      { "name": "Canned or jarred organic alpine corn", "code": "50473202" },
      { "name": "Canned or jarred organic ambrosia corn", "code": "50473203" },
      { "name": "Canned or jarred organic argent corn", "code": "50473204" },
      { "name": "Canned or jarred organic aspen corn", "code": "50473205" },
      { "name": "Canned or jarred organic avalanche corn", "code": "50473206" },
      { "name": "Canned or jarred organic biqueen corn", "code": "50473207" },
      { "name": "Canned or jarred organic bodacious corn", "code": "50473208" },
      {
        "name": "Canned or jarred organic butter and sugar corn",
        "code": "50473209"
      },
      {
        "name": "Canned or jarred organic calico belle corn",
        "code": "50473210"
      },
      { "name": "Canned or jarred organic camelot corn", "code": "50473211" },
      {
        "name": "Canned or jarred organic challenger crisp \u2018n sweet corn",
        "code": "50473212"
      },
      { "name": "Canned or jarred organic champ corn", "code": "50473213" },
      {
        "name": "Canned or jarred organic cotton candy corn",
        "code": "50473214"
      },
      {
        "name": "Canned or jarred organic d\u2019artagnan corn",
        "code": "50473215"
      },
      { "name": "Canned or jarred organic dazzle corn", "code": "50473216" },
      {
        "name": "Canned or jarred organic diamonds and gold corn",
        "code": "50473217"
      },
      { "name": "Canned or jarred organic divinity corn", "code": "50473218" },
      {
        "name": "Canned or jarred organic double delight corn",
        "code": "50473219"
      },
      {
        "name": "Canned or jarred organic double gem corn",
        "code": "50473220"
      },
      { "name": "Canned or jarred organic earlivee corn", "code": "50473221" },
      {
        "name": "Canned or jarred organic early xtra sweet corn",
        "code": "50473222"
      },
      { "name": "Canned or jarred organic excel corn", "code": "50473223" },
      {
        "name": "Canned or jarred organic golden cross bantam corn",
        "code": "50473224"
      },
      {
        "name": "Canned or jarred organic honey and cream corn",
        "code": "50473225"
      },
      {
        "name": "Canned or jarred organic honey \u2018n pearl corn",
        "code": "50473226"
      },
      {
        "name": "Canned or jarred organic how sweet it is corn",
        "code": "50473227"
      },
      { "name": "Canned or jarred organic hudson corn", "code": "50473228" },
      {
        "name": "Canned or jarred organic illini gold corn",
        "code": "50473229"
      },
      {
        "name": "Canned or jarred organic illini xtra sweet corn",
        "code": "50473230"
      },
      {
        "name": "Canned or jarred organic incredible corn",
        "code": "50473231"
      },
      { "name": "Canned or jarred organic iochief corn", "code": "50473232" },
      { "name": "Canned or jarred organic jubilee corn", "code": "50473233" },
      {
        "name": "Canned or jarred organic jubilee supersweet corn",
        "code": "50473234"
      },
      {
        "name": "Canned or jarred organic kandy korn corn",
        "code": "50473235"
      },
      {
        "name": "Canned or jarred organic kiss \u2018n tell corn",
        "code": "50473236"
      },
      { "name": "Canned or jarred organic lancelot corn", "code": "50473237" },
      {
        "name": "Canned or jarred organic maple sweet corn",
        "code": "50473238"
      },
      { "name": "Canned or jarred organic medley corn", "code": "50473239" },
      { "name": "Canned or jarred organic merlin corn", "code": "50473240" },
      { "name": "Canned or jarred organic miracle corn", "code": "50473241" },
      { "name": "Canned or jarred organic nk-199 corn", "code": "50473242" },
      {
        "name": "Canned or jarred organic peaches and cream corn",
        "code": "50473243"
      },
      {
        "name": "Canned or jarred organic pearl white corn",
        "code": "50473244"
      },
      { "name": "Canned or jarred organic pegasus corn", "code": "50473245" },
      {
        "name": "Canned or jarred organic phenomenal corn",
        "code": "50473246"
      },
      {
        "name": "Canned or jarred organic platinum lady corn",
        "code": "50473247"
      },
      {
        "name": "Canned or jarred organic precocious corn",
        "code": "50473248"
      },
      { "name": "Canned or jarred organic pristine corn", "code": "50473249" },
      { "name": "Canned or jarred organic quickie corn", "code": "50473250" },
      { "name": "Canned or jarred organic radiance corn", "code": "50473251" },
      {
        "name": "Canned or jarred organic seneca brave corn",
        "code": "50473252"
      },
      {
        "name": "Canned or jarred organic seneca dawn corn",
        "code": "50473253"
      },
      {
        "name": "Canned or jarred organic seneca horizon corn",
        "code": "50473254"
      },
      {
        "name": "Canned or jarred organic seneca starshine corn",
        "code": "50473255"
      },
      {
        "name": "Canned or jarred organic seneca white knight corn",
        "code": "50473256"
      },
      { "name": "Canned or jarred organic showcase corn", "code": "50473257" },
      {
        "name": "Canned or jarred organic silver queen corn",
        "code": "50473258"
      },
      { "name": "Canned or jarred organic snowbelle corn", "code": "50473259" },
      {
        "name": "Canned or jarred organic spring snow corn",
        "code": "50473260"
      },
      {
        "name": "Canned or jarred organic spring treat corn",
        "code": "50473261"
      },
      {
        "name": "Canned or jarred organic sugar and gold corn",
        "code": "50473262"
      },
      {
        "name": "Canned or jarred organic sugar buns corn",
        "code": "50473263"
      },
      {
        "name": "Canned or jarred organic sugar snow corn",
        "code": "50473264"
      },
      { "name": "Canned or jarred organic sundance corn", "code": "50473265" },
      { "name": "Canned or jarred organic telstar corn", "code": "50473266" },
      {
        "name": "Canned or jarred organic terminator corn",
        "code": "50473267"
      },
      { "name": "Canned or jarred organic treasure corn", "code": "50473268" },
      { "name": "Canned or jarred organic tuxedo corn", "code": "50473269" },
      { "name": "Canned or jarred organic cresses", "code": "50473300" },
      { "name": "Canned or jarred organic land cress", "code": "50473301" },
      { "name": "Canned or jarred organic nasturtium", "code": "50473302" },
      { "name": "Canned or jarred organic watercress", "code": "50473303" },
      { "name": "Canned or jarred organic wintercress", "code": "50473304" },
      { "name": "Canned or jarred organic cucumbers", "code": "50473400" },
      {
        "name": "Canned or jarred organic arena cucumbers",
        "code": "50473401"
      },
      {
        "name": "Canned or jarred organic armenian cucumbers",
        "code": "50473402"
      },
      {
        "name": "Canned or jarred organic athene cucumbers",
        "code": "50473403"
      },
      {
        "name": "Canned or jarred organic bianco lungo di parigi cucumbers",
        "code": "50473404"
      },
      {
        "name": "Canned or jarred organic burpless tasty green cucumbers",
        "code": "50473405"
      },
      {
        "name": "Canned or jarred organic chicago pickling cucumbers",
        "code": "50473406"
      },
      {
        "name": "Canned or jarred organic crystal apple cucumbers",
        "code": "50473407"
      },
      {
        "name": "Canned or jarred organic crystal lemon cucumbers",
        "code": "50473408"
      },
      {
        "name": "Canned or jarred organic danimas cucumbers",
        "code": "50473409"
      },
      {
        "name": "Canned or jarred organic gherkin cucumbers",
        "code": "50473410"
      },
      {
        "name": "Canned or jarred organic hokus cucumbers",
        "code": "50473411"
      },
      {
        "name": "Canned or jarred organic japanese cucumbers",
        "code": "50473412"
      },
      {
        "name": "Canned or jarred organic karela cucumbers",
        "code": "50473413"
      },
      {
        "name": "Canned or jarred organic korila cucumbers",
        "code": "50473414"
      },
      {
        "name": "Canned or jarred organic long green improved cucumbers",
        "code": "50473415"
      },
      {
        "name": "Canned or jarred organic marketmore cucumbers",
        "code": "50473416"
      },
      {
        "name": "Canned or jarred organic midget cucumbers",
        "code": "50473417"
      },
      {
        "name": "Canned or jarred organic national pickling cucumbers",
        "code": "50473418"
      },
      {
        "name": "Canned or jarred organic persian cucumbers",
        "code": "50473419"
      },
      {
        "name": "Canned or jarred organic telegraph cucumbers",
        "code": "50473420"
      },
      {
        "name": "Canned or jarred organic telegraph improved cucumbers",
        "code": "50473421"
      },
      {
        "name": "Canned or jarred organic vert de massy cornichon cucumbers",
        "code": "50473422"
      },
      {
        "name": "Canned or jarred organic yamato cucumbers",
        "code": "50473423"
      },
      { "name": "Canned or jarred organic eggplants", "code": "50473500" },
      {
        "name": "Canned or jarred organic bambino eggplants",
        "code": "50473501"
      },
      {
        "name": "Canned or jarred organic black beauty eggplants",
        "code": "50473502"
      },
      {
        "name": "Canned or jarred organic black enorma eggplants",
        "code": "50473503"
      },
      {
        "name": "Canned or jarred organic chinese eggplants",
        "code": "50473504"
      },
      {
        "name": "Canned or jarred organic easter egg eggplants",
        "code": "50473505"
      },
      {
        "name": "Canned or jarred organic filipino eggplants",
        "code": "50473506"
      },
      {
        "name": "Canned or jarred organic florida market eggplants",
        "code": "50473507"
      },
      {
        "name": "Canned or jarred organic indian eggplants",
        "code": "50473508"
      },
      {
        "name": "Canned or jarred organic italian eggplants",
        "code": "50473509"
      },
      {
        "name": "Canned or jarred organic japanese eggplants",
        "code": "50473510"
      },
      {
        "name": "Canned or jarred organic long purple eggplants",
        "code": "50473511"
      },
      {
        "name": "Canned or jarred organic long striped eggplants",
        "code": "50473512"
      },
      {
        "name": "Canned or jarred organic moneymaker eggplants",
        "code": "50473513"
      },
      { "name": "Canned or jarred organic ova eggplants", "code": "50473514" },
      { "name": "Canned or jarred organic pea eggplants", "code": "50473515" },
      {
        "name": "Canned or jarred organic short tom eggplants",
        "code": "50473516"
      },
      {
        "name": "Canned or jarred organic sicilian eggplants",
        "code": "50473517"
      },
      { "name": "Canned or jarred organic thai eggplants", "code": "50473518" },
      {
        "name": "Canned or jarred organic violette di firenze eggplants",
        "code": "50473519"
      },
      {
        "name": "Canned or jarred organic white eggplants",
        "code": "50473520"
      },
      { "name": "Canned or jarred organic endives", "code": "50473600" },
      {
        "name": "Canned or jarred organic brussels witloof endives",
        "code": "50473601"
      },
      {
        "name": "Canned or jarred organic castelfranco endives",
        "code": "50473602"
      },
      {
        "name": "Canned or jarred organic catalogna di galatina endives",
        "code": "50473603"
      },
      {
        "name": "Canned or jarred organic chioggia endives",
        "code": "50473604"
      },
      {
        "name": "Canned or jarred organic grumolo verde endives",
        "code": "50473605"
      },
      {
        "name": "Canned or jarred organic large rooted magdeburg endives",
        "code": "50473606"
      },
      {
        "name": "Canned or jarred organic palla rossa zorzi precoce endives",
        "code": "50473607"
      },
      {
        "name": "Canned or jarred organic radice amare endives",
        "code": "50473608"
      },
      {
        "name": "Canned or jarred organic rossa di treviso endives",
        "code": "50473609"
      },
      {
        "name": "Canned or jarred organic rossa di verona endives",
        "code": "50473610"
      },
      {
        "name": "Canned or jarred organic soncino endives",
        "code": "50473611"
      },
      {
        "name": "Canned or jarred organic sugarhat endives",
        "code": "50473612"
      },
      { "name": "Canned or jarred organic verona endives", "code": "50473613" },
      {
        "name": "Canned or jarred organic witloof zoom endives",
        "code": "50473614"
      },
      { "name": "Canned or jarred organic fennels", "code": "50473700" },
      { "name": "Canned or jarred organic cantino fennel", "code": "50473701" },
      { "name": "Canned or jarred organic fino fennel", "code": "50473702" },
      { "name": "Canned or jarred organic herald fennel", "code": "50473703" },
      {
        "name": "Canned or jarred organic perfection fennel",
        "code": "50473704"
      },
      { "name": "Canned or jarred organic sirio fennel", "code": "50473705" },
      {
        "name": "Canned or jarred organic sweet florence fennel",
        "code": "50473706"
      },
      { "name": "Canned or jarred organic tardo fennel", "code": "50473707" },
      { "name": "Canned or jarred organic garlics", "code": "50473800" },
      {
        "name": "Canned or jarred organic california late garlic",
        "code": "50473801"
      },
      {
        "name": "Canned or jarred organic chinese garlic stems",
        "code": "50473802"
      },
      { "name": "Canned or jarred organic garlic chives", "code": "50473803" },
      {
        "name": "Canned or jarred organic germidor garlic",
        "code": "50473804"
      },
      {
        "name": "Canned or jarred organic long keeper garlic",
        "code": "50473805"
      },
      { "name": "Canned or jarred organic ramson garlic", "code": "50473806" },
      {
        "name": "Canned or jarred organic rocambole garlic",
        "code": "50473807"
      },
      {
        "name": "Canned or jarred organic rose de lautrec garlic",
        "code": "50473808"
      },
      {
        "name": "Canned or jarred organic solent wight garlic",
        "code": "50473809"
      },
      {
        "name": "Canned or jarred organic spanish morado garlic",
        "code": "50473810"
      },
      {
        "name": "Canned or jarred organic venetian/italian garlic",
        "code": "50473811"
      },
      { "name": "Canned or jarred organic gourds", "code": "50473900" },
      { "name": "Canned or jarred organic angled loofah", "code": "50473901" },
      { "name": "Canned or jarred organic bitter gourd", "code": "50473902" },
      { "name": "Canned or jarred organic bottle gourd", "code": "50473903" },
      {
        "name": "Canned or jarred organic calabash gourds",
        "code": "50473904"
      },
      {
        "name": "Canned or jarred organic fuzzy/hairy melon",
        "code": "50473905"
      },
      { "name": "Canned or jarred organic musky gourd", "code": "50473906" },
      { "name": "Canned or jarred organic smooth loofah", "code": "50473907" },
      { "name": "Canned or jarred organic snake gourd", "code": "50473908" },
      {
        "name": "Canned or jarred organic spiny bitter gourd",
        "code": "50473909"
      },
      { "name": "Canned or jarred organic tinda gourds", "code": "50473910" },
      {
        "name": "Canned or jarred organic tindoori gourds",
        "code": "50473911"
      },
      { "name": "Canned or jarred organic green peas", "code": "50474000" },
      { "name": "Canned or jarred organic china peas", "code": "50474001" },
      { "name": "Canned or jarred organic english peas", "code": "50474002" },
      { "name": "Canned or jarred organic garden peas", "code": "50474003" },
      { "name": "Canned or jarred organic snow peas", "code": "50474004" },
      {
        "name": "Canned or jarred organic sugar snap peas",
        "code": "50474005"
      },
      { "name": "Canned or jarred organic herbs", "code": "50474100" },
      { "name": "Canned or jarred organic basil", "code": "50474101" },
      { "name": "Canned or jarred organic bay leaves", "code": "50474102" },
      { "name": "Canned or jarred organic borage", "code": "50474103" },
      { "name": "Canned or jarred organic caraway", "code": "50474104" },
      { "name": "Canned or jarred organic chervil", "code": "50474105" },
      { "name": "Canned or jarred organic cilantro", "code": "50474106" },
      { "name": "Canned or jarred organic cipolinos", "code": "50474107" },
      { "name": "Canned or jarred organic curry leaves", "code": "50474108" },
      { "name": "Canned or jarred organic dill", "code": "50474109" },
      { "name": "Canned or jarred organic epazote", "code": "50474110" },
      { "name": "Canned or jarred organic fenugreek", "code": "50474111" },
      { "name": "Canned or jarred organic lemon grass", "code": "50474112" },
      { "name": "Canned or jarred organic marjoram", "code": "50474113" },
      { "name": "Canned or jarred organic mint", "code": "50474114" },
      { "name": "Canned or jarred organic oregano", "code": "50474115" },
      { "name": "Canned or jarred organic papalo", "code": "50474116" },
      { "name": "Canned or jarred organic pepicha", "code": "50474117" },
      { "name": "Canned or jarred organic perilla", "code": "50474118" },
      { "name": "Canned or jarred organic recao", "code": "50474119" },
      { "name": "Canned or jarred organic rosemary", "code": "50474120" },
      { "name": "Canned or jarred organic sage", "code": "50474121" },
      { "name": "Canned or jarred organic salsify", "code": "50474122" },
      { "name": "Canned or jarred organic savory", "code": "50474123" },
      { "name": "Canned or jarred organic tarragon", "code": "50474124" },
      { "name": "Canned or jarred organic thyme", "code": "50474125" },
      { "name": "Canned or jarred organic tumeric", "code": "50474126" },
      { "name": "Canned or jarred organic verdulaga", "code": "50474127" },
      { "name": "Canned or jarred organic kale", "code": "50474200" },
      { "name": "Canned or jarred organic curly kale", "code": "50474201" },
      { "name": "Canned or jarred organic collard greens", "code": "50474202" },
      { "name": "Canned or jarred organic kohlrabi", "code": "50474300" },
      {
        "name": "Canned or jarred organic azur star kohlrabi",
        "code": "50474301"
      },
      {
        "name": "Canned or jarred organic green vienna kohlrabi",
        "code": "50474302"
      },
      { "name": "Canned or jarred organic lanro kohlrabi", "code": "50474303" },
      {
        "name": "Canned or jarred organic purple vienna kohlrabi",
        "code": "50474304"
      },
      {
        "name": "Canned or jarred organic rowel trero kohlrabi",
        "code": "50474305"
      },
      {
        "name": "Canned or jarred organic white vienna kohlrabi",
        "code": "50474306"
      },
      { "name": "Canned or jarred organic leeks", "code": "50474400" },
      {
        "name": "Canned or jarred organic autumn giant-cobra leeks",
        "code": "50474401"
      },
      {
        "name": "Canned or jarred organic autumn mammoth 2 leeks",
        "code": "50474402"
      },
      {
        "name": "Canned or jarred organic bleu de solaise leeks",
        "code": "50474403"
      },
      { "name": "Canned or jarred organic cortina leeks", "code": "50474404" },
      { "name": "Canned or jarred organic prelina leeks", "code": "50474405" },
      { "name": "Canned or jarred organic wild leek ramp", "code": "50474406" },
      { "name": "Canned or jarred organic lentils", "code": "50474500" },
      { "name": "Canned or jarred organic beluga lentils", "code": "50474501" },
      {
        "name": "Canned or jarred organic french green lentils",
        "code": "50474502"
      },
      { "name": "Canned or jarred organic green lentils", "code": "50474503" },
      {
        "name": "Canned or jarred organic petite crimson lentils",
        "code": "50474504"
      },
      {
        "name": "Canned or jarred organic spanish pardina lentils",
        "code": "50474505"
      },
      {
        "name": "Canned or jarred organic split red lentils",
        "code": "50474506"
      },
      {
        "name": "Canned or jarred organic split yellow lentils",
        "code": "50474507"
      },
      {
        "name": "Canned or jarred organic tarahumara pinks lentils",
        "code": "50474508"
      },
      { "name": "Canned or jarred organic lettuces", "code": "50474600" },
      { "name": "Canned or jarred organic bibb lettuce", "code": "50474601" },
      { "name": "Canned or jarred organic boston lettuce", "code": "50474602" },
      { "name": "Canned or jarred organic frisee lettuce", "code": "50474603" },
      {
        "name": "Canned or jarred organic lolla rossa lettuce",
        "code": "50474604"
      },
      {
        "name": "Canned or jarred organic mesculin mix lettuce",
        "code": "50474605"
      },
      { "name": "Canned or jarred organic mizuna lettuce", "code": "50474606" },
      {
        "name": "Canned or jarred organic red leaf lettuce",
        "code": "50474607"
      },
      {
        "name": "Canned or jarred organic red oak leaf lettuce",
        "code": "50474608"
      },
      {
        "name": "Canned or jarred organic ruby romaine lettuce",
        "code": "50474609"
      },
      {
        "name": "Canned or jarred organic baby red romaine lettuce",
        "code": "50474610"
      },
      {
        "name": "Canned or jarred organic butterhead lettuces",
        "code": "50474611"
      },
      {
        "name": "Canned or jarred organic chinese lettuces",
        "code": "50474612"
      },
      {
        "name": "Canned or jarred organic crisphead lettuces",
        "code": "50474613"
      },
      {
        "name": "Canned or jarred organic green leaf lettuce",
        "code": "50474614"
      },
      {
        "name": "Canned or jarred organic iceberg lettuce",
        "code": "50474615"
      },
      {
        "name": "Canned or jarred organic lamb\u2019s lettuces",
        "code": "50474616"
      },
      {
        "name": "Canned or jarred organic looseleaf lettuces",
        "code": "50474617"
      },
      { "name": "Canned or jarred organic mache lettuce", "code": "50474618" },
      {
        "name": "Canned or jarred organic red boston lettuce",
        "code": "50474619"
      },
      {
        "name": "Canned or jarred organic red headed lettuces",
        "code": "50474620"
      },
      {
        "name": "Canned or jarred organic romaine lettuces",
        "code": "50474621"
      },
      {
        "name": "Canned or jarred organic russian red mustard lettuce",
        "code": "50474622"
      },
      { "name": "Canned or jarred organic tatsoi lettuce", "code": "50474623" },
      { "name": "Canned or jarred organic malanga", "code": "50474700" },
      {
        "name": "Canned or jarred organic amarilla malanga",
        "code": "50474701"
      },
      { "name": "Canned or jarred organic blanca malanga", "code": "50474702" },
      { "name": "Canned or jarred organic coco malanga", "code": "50474703" },
      { "name": "Canned or jarred organic eddoes malanga", "code": "50474704" },
      { "name": "Canned or jarred organic islena malanga", "code": "50474705" },
      { "name": "Canned or jarred organic lila malanga", "code": "50474706" },
      { "name": "Canned or jarred organic mushrooms", "code": "50474800" },
      {
        "name": "Canned or jarred organic black trumpet mushrooms",
        "code": "50474801"
      },
      {
        "name": "Canned or jarred organic brown mushrooms",
        "code": "50474802"
      },
      {
        "name": "Canned or jarred organic champinion mushrooms",
        "code": "50474803"
      },
      {
        "name": "Canned or jarred organic chanterelle mushrooms",
        "code": "50474804"
      },
      {
        "name": "Canned or jarred organic cremini mushrooms",
        "code": "50474805"
      },
      {
        "name": "Canned or jarred organic enoki mushrooms",
        "code": "50474806"
      },
      {
        "name": "Canned or jarred organic hedge hog mushrooms",
        "code": "50474807"
      },
      {
        "name": "Canned or jarred organic hen of the woods mushrooms",
        "code": "50474808"
      },
      {
        "name": "Canned or jarred organic lobster mushrooms",
        "code": "50474809"
      },
      {
        "name": "Canned or jarred organic morels mushrooms",
        "code": "50474810"
      },
      {
        "name": "Canned or jarred organic oyster mushrooms",
        "code": "50474811"
      },
      {
        "name": "Canned or jarred organic pleurotus mushrooms",
        "code": "50474812"
      },
      {
        "name": "Canned or jarred organic pompom mushrooms",
        "code": "50474813"
      },
      {
        "name": "Canned or jarred organic porcieni mushrooms",
        "code": "50474814"
      },
      {
        "name": "Canned or jarred organic portobella mushrooms",
        "code": "50474815"
      },
      {
        "name": "Canned or jarred organic shiitake mushrooms",
        "code": "50474816"
      },
      {
        "name": "Canned or jarred organic shimeji mushrooms",
        "code": "50474817"
      },
      {
        "name": "Canned or jarred organic st george's mushrooms",
        "code": "50474818"
      },
      {
        "name": "Canned or jarred organic white mushrooms",
        "code": "50474819"
      },
      {
        "name": "Canned or jarred organic white trumpet mushrooms",
        "code": "50474820"
      },
      {
        "name": "Canned or jarred organic woodear mushrooms",
        "code": "50474821"
      },
      { "name": "Canned or jarred organic mustards", "code": "50474900" },
      { "name": "Canned or jarred organic bamboo mustard", "code": "50474901" },
      { "name": "Canned or jarred organic garlic mustard", "code": "50474902" },
      {
        "name": "Canned or jarred organic giantleafed mustard",
        "code": "50474903"
      },
      {
        "name": "Canned or jarred organic red in snow mustard",
        "code": "50474904"
      },
      {
        "name": "Canned or jarred organic southern mustard",
        "code": "50474905"
      },
      {
        "name": "Canned or jarred organic wrapped heart mustard",
        "code": "50474906"
      },
      { "name": "Canned or jarred organic nightshades", "code": "50475000" },
      {
        "name": "Canned or jarred organic chinese lantern",
        "code": "50475001"
      },
      {
        "name": "Canned or jarred organic garden huckleberry",
        "code": "50475002"
      },
      { "name": "Canned or jarred organic naranjilla", "code": "50475003" },
      { "name": "Canned or jarred organic tomatillo", "code": "50475004" },
      { "name": "Canned or jarred organic okras", "code": "50475100" },
      { "name": "Canned or jarred organic artist okra", "code": "50475101" },
      { "name": "Canned or jarred organic burgundy okra", "code": "50475102" },
      {
        "name": "Canned or jarred organic clemson spineless okra",
        "code": "50475103"
      },
      {
        "name": "Canned or jarred organic dwarf green long pod okra",
        "code": "50475104"
      },
      {
        "name": "Canned or jarred organic mammoth spineless long pod okra",
        "code": "50475105"
      },
      {
        "name": "Canned or jarred organic red velvet okra",
        "code": "50475106"
      },
      {
        "name": "Canned or jarred organic star of david heirloom okra",
        "code": "50475107"
      },
      { "name": "Canned or jarred organic onions", "code": "50475200" },
      { "name": "Canned or jarred organic albion onions", "code": "50475201" },
      {
        "name": "Canned or jarred organic alisa craig onions",
        "code": "50475202"
      },
      { "name": "Canned or jarred organic boiling onions", "code": "50475203" },
      { "name": "Canned or jarred organic buffalo onions", "code": "50475204" },
      { "name": "Canned or jarred organic bulb onions", "code": "50475205" },
      {
        "name": "Canned or jarred organic creaming onions",
        "code": "50475206"
      },
      {
        "name": "Canned or jarred organic express yellow o-x onions",
        "code": "50475207"
      },
      { "name": "Canned or jarred organic kelsae onions", "code": "50475208" },
      {
        "name": "Canned or jarred organic marshalls giant fen globe onions",
        "code": "50475209"
      },
      { "name": "Canned or jarred organic pearl onions", "code": "50475210" },
      {
        "name": "Canned or jarred organic red baron onions",
        "code": "50475211"
      },
      { "name": "Canned or jarred organic red onions", "code": "50475212" },
      {
        "name": "Canned or jarred organic rijnsberger onions",
        "code": "50475213"
      },
      {
        "name": "Canned or jarred organic senshyu semi-globe yellow onions",
        "code": "50475214"
      },
      { "name": "Canned or jarred organic sturon onions", "code": "50475215" },
      {
        "name": "Canned or jarred organic stuttgarter giant onions",
        "code": "50475216"
      },
      { "name": "Canned or jarred organic sweet onions", "code": "50475217" },
      {
        "name": "Canned or jarred organic torpedo or red italian onions",
        "code": "50475218"
      },
      {
        "name": "Canned or jarred organic red storage onions",
        "code": "50475219"
      },
      {
        "name": "Canned or jarred organic white storage onions",
        "code": "50475220"
      },
      {
        "name": "Canned or jarred organic yellow storage onions",
        "code": "50475221"
      },
      { "name": "Canned or jarred organic peanuts", "code": "50475300" },
      {
        "name": "Canned or jarred organic bambarra groundnut peanuts",
        "code": "50475301"
      },
      {
        "name": "Canned or jarred organic florunner peanuts",
        "code": "50475302"
      },
      {
        "name": "Canned or jarred organic hausa/kersting's ground nut peanuts",
        "code": "50475303"
      },
      {
        "name": "Canned or jarred organic spanish peanuts",
        "code": "50475304"
      },
      {
        "name": "Canned or jarred organic valencia peanuts",
        "code": "50475305"
      },
      {
        "name": "Canned or jarred organic virginia peanuts",
        "code": "50475306"
      },
      { "name": "Canned or jarred organic peas", "code": "50475400" },
      {
        "name": "Canned or jarred organic purple hull peas",
        "code": "50475401"
      },
      { "name": "Canned or jarred organic pinkeye peas", "code": "50475402" },
      { "name": "Canned or jarred organic crowder peas", "code": "50475403" },
      {
        "name": "Canned or jarred organic white acre peas",
        "code": "50475404"
      },
      { "name": "Canned or jarred organic blackeyed peas", "code": "50475405" },
      {
        "name": "Canned or jarred organic zipper cream peas",
        "code": "50475406"
      },
      { "name": "Canned or jarred organic peppers", "code": "50475500" },
      { "name": "Canned or jarred organic ajies peppers", "code": "50475501" },
      { "name": "Canned or jarred organic arbol peppers", "code": "50475502" },
      { "name": "Canned or jarred organic cheese peppers", "code": "50475503" },
      {
        "name": "Canned or jarred organic chilaca peppers",
        "code": "50475504"
      },
      {
        "name": "Canned or jarred organic cubanelles peppers",
        "code": "50475505"
      },
      { "name": "Canned or jarred organic fresno peppers", "code": "50475506" },
      { "name": "Canned or jarred organic kapia peppers", "code": "50475507" },
      { "name": "Canned or jarred organic korean peppers", "code": "50475508" },
      {
        "name": "Canned or jarred organic manzano peppers",
        "code": "50475509"
      },
      {
        "name": "Canned or jarred organic melrose peppers",
        "code": "50475510"
      },
      {
        "name": "Canned or jarred organic yellow chile peppers",
        "code": "50475511"
      },
      {
        "name": "Canned or jarred organic aji dulces peppers",
        "code": "50475512"
      },
      {
        "name": "Canned or jarred organic anaheim peppers",
        "code": "50475513"
      },
      { "name": "Canned or jarred organic ancho peppers", "code": "50475514" },
      { "name": "Canned or jarred organic bell peppers", "code": "50475515" },
      {
        "name": "Canned or jarred organic cascabel peppers",
        "code": "50475516"
      },
      {
        "name": "Canned or jarred organic cayenne peppers",
        "code": "50475517"
      },
      {
        "name": "Canned or jarred organic cherry hots peppers",
        "code": "50475518"
      },
      {
        "name": "Canned or jarred organic chiltecpin peppers",
        "code": "50475519"
      },
      {
        "name": "Canned or jarred organic finger hot peppers",
        "code": "50475520"
      },
      {
        "name": "Canned or jarred organic guajillo peppers",
        "code": "50475521"
      },
      { "name": "Canned or jarred organic guerro peppers", "code": "50475522" },
      {
        "name": "Canned or jarred organic habanero peppers",
        "code": "50475523"
      },
      {
        "name": "Canned or jarred organic hungarian wax peppers",
        "code": "50475524"
      },
      {
        "name": "Canned or jarred organic jalapeno peppers",
        "code": "50475525"
      },
      {
        "name": "Canned or jarred organic long hot peppers",
        "code": "50475526"
      },
      {
        "name": "Canned or jarred organic mirasol peppers",
        "code": "50475527"
      },
      {
        "name": "Canned or jarred organic pasilla peppers",
        "code": "50475528"
      },
      {
        "name": "Canned or jarred organic peperoncini peppers",
        "code": "50475529"
      },
      { "name": "Canned or jarred organic pequin peppers", "code": "50475530" },
      {
        "name": "Canned or jarred organic pimiento peppers",
        "code": "50475531"
      },
      {
        "name": "Canned or jarred organic poblano peppers",
        "code": "50475532"
      },
      {
        "name": "Canned or jarred organic scotch bonnet peppers",
        "code": "50475533"
      },
      {
        "name": "Canned or jarred organic serrano peppers",
        "code": "50475534"
      },
      {
        "name": "Canned or jarred organic tabasco peppers",
        "code": "50475535"
      },
      { "name": "Canned or jarred organic tai peppers", "code": "50475536" },
      { "name": "Canned or jarred organic tepin peppers", "code": "50475537" },
      { "name": "Canned or jarred organic potatoes", "code": "50475600" },
      {
        "name": "Canned or jarred organic long white potatoes",
        "code": "50475601"
      },
      {
        "name": "Canned or jarred organic round white potatoes",
        "code": "50475602"
      },
      {
        "name": "Canned or jarred organic round red potatoes",
        "code": "50475603"
      },
      {
        "name": "Canned or jarred organic russet potatoes",
        "code": "50475604"
      },
      {
        "name": "Canned or jarred organic purple potatoes",
        "code": "50475605"
      },
      {
        "name": "Canned or jarred organic yellow potatoes",
        "code": "50475606"
      },
      { "name": "Canned or jarred organic new potatoes", "code": "50475607" },
      {
        "name": "Canned or jarred organic specialty potatoes",
        "code": "50475608"
      },
      { "name": "Canned or jarred organic rutabagas", "code": "50475700" },
      { "name": "Canned or jarred organic acme rutabagas", "code": "50475701" },
      {
        "name": "Canned or jarred organic angela rutabagas",
        "code": "50475702"
      },
      {
        "name": "Canned or jarred organic best of all rutabagas",
        "code": "50475703"
      },
      {
        "name": "Canned or jarred organic marian rutabagas",
        "code": "50475704"
      },
      { "name": "Canned or jarred organic sea vegetables", "code": "50475800" },
      { "name": "Canned or jarred organic agar-agar", "code": "50475801" },
      { "name": "Canned or jarred organic arame", "code": "50475802" },
      { "name": "Canned or jarred organic dulse", "code": "50475803" },
      {
        "name": "Canned or jarred organic haricot vert de mer",
        "code": "50475804"
      },
      { "name": "Canned or jarred organic hijiki", "code": "50475805" },
      { "name": "Canned or jarred organic irish moss", "code": "50475806" },
      { "name": "Canned or jarred organic kelp", "code": "50475807" },
      { "name": "Canned or jarred organic laver", "code": "50475808" },
      { "name": "Canned or jarred organic nori", "code": "50475809" },
      { "name": "Canned or jarred organic red algae", "code": "50475810" },
      { "name": "Canned or jarred organic sea kale", "code": "50475811" },
      { "name": "Canned or jarred organic sea lettuce", "code": "50475812" },
      { "name": "Canned or jarred organic seaweeds", "code": "50475813" },
      { "name": "Canned or jarred organic spirulina", "code": "50475814" },
      { "name": "Canned or jarred organic susabi nori", "code": "50475815" },
      { "name": "Canned or jarred organic wakame", "code": "50475816" },
      { "name": "Canned or jarred organic shallots", "code": "50475900" },
      {
        "name": "Canned or jarred organic atlantic shallots",
        "code": "50475901"
      },
      {
        "name": "Canned or jarred organic creation shallots",
        "code": "50475902"
      },
      {
        "name": "Canned or jarred organic drittler white nest shallots",
        "code": "50475903"
      },
      {
        "name": "Canned or jarred organic giant yellow improved shallots",
        "code": "50475904"
      },
      {
        "name": "Canned or jarred organic golden gourmet shallots",
        "code": "50475905"
      },
      {
        "name": "Canned or jarred organic grise de bagnolet shallots",
        "code": "50475906"
      },
      {
        "name": "Canned or jarred organic hative de niort shallots",
        "code": "50475907"
      },
      {
        "name": "Canned or jarred organic pikant shallots",
        "code": "50475908"
      },
      {
        "name": "Canned or jarred organic red potato onions",
        "code": "50475909"
      },
      { "name": "Canned or jarred organic sante shallots", "code": "50475910" },
      {
        "name": "Canned or jarred organic topper shallots",
        "code": "50475911"
      },
      { "name": "Canned or jarred organic sorrels", "code": "50476000" },
      { "name": "Canned or jarred organic dock sorrel", "code": "50476001" },
      { "name": "Canned or jarred organic garden sorrel", "code": "50476002" },
      { "name": "Canned or jarred organic sheep sorrel", "code": "50476003" },
      { "name": "Canned or jarred organic wood sorrel", "code": "50476004" },
      { "name": "Canned or jarred organic spinaches", "code": "50476100" },
      {
        "name": "Canned or jarred organic america spinach",
        "code": "50476101"
      },
      {
        "name": "Canned or jarred organic bloomsdale spinach",
        "code": "50476102"
      },
      {
        "name": "Canned or jarred organic giant winter spinach",
        "code": "50476103"
      },
      {
        "name": "Canned or jarred organic horenso spinach",
        "code": "50476104"
      },
      {
        "name": "Canned or jarred organic iceplant spinach",
        "code": "50476105"
      },
      {
        "name": "Canned or jarred organic lamb's quarters spinach",
        "code": "50476106"
      },
      {
        "name": "Canned or jarred organic malabar spinach",
        "code": "50476107"
      },
      {
        "name": "Canned or jarred organic medania spinach",
        "code": "50476108"
      },
      {
        "name": "Canned or jarred organic new zealand spinach",
        "code": "50476109"
      },
      { "name": "Canned or jarred organic orach spinach", "code": "50476110" },
      { "name": "Canned or jarred organic savoy spinach", "code": "50476111" },
      {
        "name": "Canned or jarred organic sigmaleaf spinach",
        "code": "50476112"
      },
      { "name": "Canned or jarred organic space spinach", "code": "50476113" },
      {
        "name": "Canned or jarred organic trinidad spinach",
        "code": "50476114"
      },
      { "name": "Canned or jarred organic water spinach", "code": "50476115" },
      { "name": "Canned or jarred organic wild spinach", "code": "50476116" },
      {
        "name": "Canned or jarred organic summer squashes and summer pumpkins",
        "code": "50476200"
      },
      {
        "name": "Canned or jarred organic boston marrow squash",
        "code": "50476201"
      },
      {
        "name": "Canned or jarred organic butternut squash",
        "code": "50476202"
      },
      {
        "name": "Canned or jarred organic costata romanesca squash",
        "code": "50476203"
      },
      {
        "name": "Canned or jarred organic crookneck squash",
        "code": "50476204"
      },
      { "name": "Canned or jarred organic cucuzza squash", "code": "50476205" },
      {
        "name": "Canned or jarred organic delicata squash",
        "code": "50476206"
      },
      {
        "name": "Canned or jarred organic delicious squash",
        "code": "50476207"
      },
      {
        "name": "Canned or jarred organic early golden summer crookneck squash",
        "code": "50476208"
      },
      {
        "name": "Canned or jarred organic early prolific straight neck squash",
        "code": "50476209"
      },
      { "name": "Canned or jarred organic gold squash", "code": "50476210" },
      {
        "name": "Canned or jarred organic jack be little squash",
        "code": "50476211"
      },
      {
        "name": "Canned or jarred organic kentucky field squash",
        "code": "50476212"
      },
      { "name": "Canned or jarred organic marrow squash", "code": "50476213" },
      {
        "name": "Canned or jarred organic middle eastern squash",
        "code": "50476214"
      },
      {
        "name": "Canned or jarred organic miniature squash",
        "code": "50476215"
      },
      {
        "name": "Canned or jarred organic orangetti squash",
        "code": "50476216"
      },
      {
        "name": "Canned or jarred organic pattypan squash",
        "code": "50476217"
      },
      { "name": "Canned or jarred organic rondini squash", "code": "50476218" },
      { "name": "Canned or jarred organic round squash", "code": "50476219" },
      {
        "name": "Canned or jarred organic spaghetti squash",
        "code": "50476220"
      },
      {
        "name": "Canned or jarred organic stripetti squash",
        "code": "50476221"
      },
      {
        "name": "Canned or jarred organic sugar loaf squash",
        "code": "50476222"
      },
      {
        "name": "Canned or jarred organic sweet dumpling squash",
        "code": "50476223"
      },
      {
        "name": "Canned or jarred organic triple treat squash",
        "code": "50476224"
      },
      {
        "name": "Canned or jarred organic waltham butternut squash",
        "code": "50476225"
      },
      {
        "name": "Canned or jarred organic yellow bush scallop squash",
        "code": "50476226"
      },
      {
        "name": "Canned or jarred organic yellow straightneck squash",
        "code": "50476227"
      },
      { "name": "Canned or jarred organic zephyr squash", "code": "50476228" },
      {
        "name": "Canned or jarred organic zucchini squash",
        "code": "50476229"
      },
      { "name": "Canned or jarred organic sweet potatoes", "code": "50476300" },
      {
        "name": "Canned or jarred organic beauregard sweet potatoes",
        "code": "50476301"
      },
      {
        "name": "Canned or jarred organic centennial sweet potatoes",
        "code": "50476302"
      },
      {
        "name": "Canned or jarred organic diane sweet potatoes",
        "code": "50476303"
      },
      {
        "name": "Canned or jarred organic garnet sweet potatoes",
        "code": "50476304"
      },
      {
        "name": "Canned or jarred organic georgia red sweet potatoes",
        "code": "50476305"
      },
      {
        "name": "Canned or jarred organic goldensweet sweet potatoes",
        "code": "50476306"
      },
      {
        "name": "Canned or jarred organic hanna sweet potatoes",
        "code": "50476307"
      },
      {
        "name": "Canned or jarred organic japanese sweet potatoes",
        "code": "50476308"
      },
      {
        "name": "Canned or jarred organic jersey sweet potatoes",
        "code": "50476309"
      },
      {
        "name": "Canned or jarred organic jewel sweet potatoes",
        "code": "50476310"
      },
      {
        "name": "Canned or jarred organic maryland red sweet potatoes",
        "code": "50476311"
      },
      {
        "name": "Canned or jarred organic nemagold sweet potatoes",
        "code": "50476312"
      },
      {
        "name": "Canned or jarred organic o'henry sweet potatoes",
        "code": "50476313"
      },
      {
        "name": "Canned or jarred organic okinawan sweet potatoes",
        "code": "50476314"
      },
      {
        "name": "Canned or jarred organic orange sweet potatoes",
        "code": "50476315"
      },
      {
        "name": "Canned or jarred organic oriental sweet potatoes",
        "code": "50476316"
      },
      {
        "name": "Canned or jarred organic red jersey sweet potatoes",
        "code": "50476317"
      },
      {
        "name": "Canned or jarred organic red mar sweet potatoes",
        "code": "50476318"
      },
      {
        "name": "Canned or jarred organic redglow sweet potatoes",
        "code": "50476319"
      },
      {
        "name": "Canned or jarred organic yellow jersey sweet potatoes",
        "code": "50476320"
      },
      { "name": "Canned or jarred organic tomatoes", "code": "50476400" },
      {
        "name": "Canned or jarred organic ailsa craig tomatoes",
        "code": "50476401"
      },
      {
        "name": "Canned or jarred organic alicante tomatoes",
        "code": "50476402"
      },
      {
        "name": "Canned or jarred organic black plum tomatoes",
        "code": "50476403"
      },
      {
        "name": "Canned or jarred organic brandywine tomatoes",
        "code": "50476404"
      },
      {
        "name": "Canned or jarred organic cherry belle tomatoes",
        "code": "50476405"
      },
      {
        "name": "Canned or jarred organic cherry tomatoes",
        "code": "50476406"
      },
      {
        "name": "Canned or jarred organic delicious tomatoes",
        "code": "50476407"
      },
      {
        "name": "Canned or jarred organic dombito tomatoes",
        "code": "50476408"
      },
      {
        "name": "Canned or jarred organic gardener's delight tomatoes",
        "code": "50476409"
      },
      { "name": "Canned or jarred organic grape tomatoes", "code": "50476410" },
      { "name": "Canned or jarred organic green tomatoes", "code": "50476411" },
      {
        "name": "Canned or jarred organic marmande super tomatoes",
        "code": "50476412"
      },
      {
        "name": "Canned or jarred organic marvel striped traditional tomatoes",
        "code": "50476413"
      },
      {
        "name": "Canned or jarred organic minibel tomatoes",
        "code": "50476414"
      },
      {
        "name": "Canned or jarred organic oaxacan pink tomatoes",
        "code": "50476415"
      },
      {
        "name": "Canned or jarred organic red alert tomatoes",
        "code": "50476416"
      },
      {
        "name": "Canned or jarred organic roma vf tomatoes",
        "code": "50476417"
      },
      {
        "name": "Canned or jarred organic san marzano tomatoes",
        "code": "50476418"
      },
      {
        "name": "Canned or jarred organic shirley tomatoes",
        "code": "50476419"
      },
      {
        "name": "Canned or jarred organic siberia tomato tomatoes",
        "code": "50476420"
      },
      {
        "name": "Canned or jarred organic super beefsteak tomatoes",
        "code": "50476421"
      },
      {
        "name": "Canned or jarred organic tigerella tomatoes",
        "code": "50476422"
      },
      {
        "name": "Canned or jarred organic tiny tim tomatoes",
        "code": "50476423"
      },
      {
        "name": "Canned or jarred organic tumbler tomatoes",
        "code": "50476424"
      },
      {
        "name": "Canned or jarred organic yellow cocktail tomatoes",
        "code": "50476425"
      },
      {
        "name": "Canned or jarred organic yellow pear-shaped tomatoes",
        "code": "50476426"
      },
      {
        "name": "Canned or jarred organic yellow perfection tomatoes",
        "code": "50476427"
      },
      { "name": "Canned or jarred organic turnip greens", "code": "50476500" },
      {
        "name": "Canned or jarred organic green globe turnips",
        "code": "50476501"
      },
      {
        "name": "Canned or jarred organic golden ball turnips",
        "code": "50476502"
      },
      {
        "name": "Canned or jarred organic manchester market turnips",
        "code": "50476503"
      },
      {
        "name": "Canned or jarred organic purple top milan turnips",
        "code": "50476504"
      },
      {
        "name": "Canned or jarred organic purple top white turnips",
        "code": "50476505"
      },
      {
        "name": "Canned or jarred organic snowball turnips",
        "code": "50476506"
      },
      { "name": "Canned or jarred organic tokyo turnip", "code": "50476507" },
      {
        "name": "Canned or jarred organic tokyo cross turnips",
        "code": "50476508"
      },
      {
        "name": "Canned or jarred organic winter squashes and winter pumpkins",
        "code": "50476600"
      },
      { "name": "Canned or jarred organic acorn squash", "code": "50476601" },
      {
        "name": "Canned or jarred organic atlantic giant squash",
        "code": "50476602"
      },
      {
        "name": "Canned or jarred organic banana pink squash",
        "code": "50476603"
      },
      { "name": "Canned or jarred organic big max squash", "code": "50476604" },
      {
        "name": "Canned or jarred organic calabaza squash",
        "code": "50476605"
      },
      {
        "name": "Canned or jarred organic carnival squash",
        "code": "50476606"
      },
      { "name": "Canned or jarred organic cheese pumpkin", "code": "50476607" },
      {
        "name": "Canned or jarred organic crown prince squash",
        "code": "50476608"
      },
      {
        "name": "Canned or jarred organic curcibita squash",
        "code": "50476609"
      },
      { "name": "Canned or jarred organic cushaw squash", "code": "50476610" },
      {
        "name": "Canned or jarred organic giant pumpkin squash",
        "code": "50476611"
      },
      { "name": "Canned or jarred organic hubbard squash", "code": "50476612" },
      {
        "name": "Canned or jarred organic jarrahdale squash",
        "code": "50476613"
      },
      { "name": "Canned or jarred organic kabocha squash", "code": "50476614" },
      {
        "name": "Canned or jarred organic queensland blue squash",
        "code": "50476615"
      },
      {
        "name": "Canned or jarred organic rouge vif d'etampes squash",
        "code": "50476616"
      },
      {
        "name": "Canned or jarred organic turks turban squash",
        "code": "50476617"
      },
      {
        "name": "Canned or jarred organic valenciano squash",
        "code": "50476618"
      },
      {
        "name": "Canned or jarred organic warted hubbard squash",
        "code": "50476619"
      },
      {
        "name": "Canned or jarred organic whangaparoa crown pumpkin",
        "code": "50476620"
      },
      { "name": "Canned or jarred organic yams", "code": "50476700" },
      {
        "name": "Canned or jarred organic african bitter yams",
        "code": "50476701"
      },
      {
        "name": "Canned or jarred organic asiatic bitter yams",
        "code": "50476702"
      },
      { "name": "Canned or jarred organic chinese yams", "code": "50476703" },
      { "name": "Canned or jarred organic globe yams", "code": "50476704" },
      { "name": "Canned or jarred organic greater yams", "code": "50476705" },
      { "name": "Canned or jarred organic japanese yams", "code": "50476706" },
      { "name": "Canned or jarred organic lesser yams", "code": "50476707" },
      { "name": "Canned or jarred organic potato yams", "code": "50476708" },
      {
        "name": "Canned or jarred organic white guinea yams",
        "code": "50476709"
      },
      {
        "name": "Canned or jarred organic yellow guinea yams",
        "code": "50476710"
      },
      {
        "name": "Canned or jarred organic nominant vegetables",
        "code": "50476800"
      },
      { "name": "Canned or jarred organic alfalfa", "code": "50476801" },
      { "name": "Canned or jarred organic aloe leaves", "code": "50476802" },
      { "name": "Canned or jarred organic apio", "code": "50476803" },
      { "name": "Canned or jarred organic arrow root", "code": "50476804" },
      { "name": "Canned or jarred organic arrowhead", "code": "50476805" },
      { "name": "Canned or jarred organic arugula", "code": "50476806" },
      { "name": "Canned or jarred organic arum", "code": "50476807" },
      { "name": "Canned or jarred organic bamboo shoots", "code": "50476808" },
      { "name": "Canned or jarred organic banana leaves", "code": "50476809" },
      { "name": "Canned or jarred organic batatas", "code": "50476810" },
      { "name": "Canned or jarred organic bean sprouts", "code": "50476811" },
      { "name": "Canned or jarred organic beet tops", "code": "50476812" },
      { "name": "Canned or jarred organic bittermelon", "code": "50476813" },
      { "name": "Canned or jarred organic caperberries", "code": "50476814" },
      { "name": "Canned or jarred organic carob", "code": "50476815" },
      { "name": "Canned or jarred organic cha-om", "code": "50476816" },
      { "name": "Canned or jarred organic chaoyotes", "code": "50476817" },
      { "name": "Canned or jarred organic chickpeas", "code": "50476818" },
      {
        "name": "Canned or jarred organic chrysanthemum greens",
        "code": "50476819"
      },
      {
        "name": "Canned or jarred organic dandelion greens",
        "code": "50476820"
      },
      { "name": "Canned or jarred organic dandelions", "code": "50476821" },
      { "name": "Canned or jarred organic dasheen", "code": "50476822" },
      {
        "name": "Canned or jarred organic dau mue or pea tips",
        "code": "50476823"
      },
      { "name": "Canned or jarred organic diakon", "code": "50476824" },
      { "name": "Canned or jarred organic donqua", "code": "50476825" },
      {
        "name": "Canned or jarred organic fiddlehead ferns",
        "code": "50476826"
      },
      { "name": "Canned or jarred organic gai choy", "code": "50476827" },
      { "name": "Canned or jarred organic gailon", "code": "50476828" },
      { "name": "Canned or jarred organic galanga", "code": "50476829" },
      { "name": "Canned or jarred organic ginger root", "code": "50476830" },
      { "name": "Canned or jarred organic gobo", "code": "50476831" },
      { "name": "Canned or jarred organic hop sprouts", "code": "50476832" },
      { "name": "Canned or jarred organic horseradish", "code": "50476833" },
      { "name": "Canned or jarred organic jicama", "code": "50476834" },
      { "name": "Canned or jarred organic kudzu", "code": "50476835" },
      { "name": "Canned or jarred organic lily bulb", "code": "50476836" },
      { "name": "Canned or jarred organic linkok", "code": "50476837" },
      { "name": "Canned or jarred organic lo bok", "code": "50476838" },
      { "name": "Canned or jarred organic long beans", "code": "50476839" },
      { "name": "Canned or jarred organic lotus root", "code": "50476840" },
      { "name": "Canned or jarred organic maguey leaves", "code": "50476841" },
      { "name": "Canned or jarred organic mallows", "code": "50476842" },
      { "name": "Canned or jarred organic mamey sapote", "code": "50476843" },
      { "name": "Canned or jarred organic moap", "code": "50476844" },
      { "name": "Canned or jarred organic moo", "code": "50476845" },
      { "name": "Canned or jarred organic moqua", "code": "50476846" },
      { "name": "Canned or jarred organic opos", "code": "50476847" },
      { "name": "Canned or jarred organic palm hearts", "code": "50476848" },
      { "name": "Canned or jarred organic paprika", "code": "50476849" },
      { "name": "Canned or jarred organic purslane", "code": "50476850" },
      { "name": "Canned or jarred organic raddichios", "code": "50476851" },
      { "name": "Canned or jarred organic sinquas", "code": "50476852" },
      { "name": "Canned or jarred organic soybeans", "code": "50476853" },
      { "name": "Canned or jarred organic spoonwart", "code": "50476854" },
      {
        "name": "Canned or jarred organic tassle grape-hyacinth",
        "code": "50476855"
      },
      { "name": "Canned or jarred organic taro", "code": "50476856" },
      { "name": "Canned or jarred organic taro leaf", "code": "50476857" },
      { "name": "Canned or jarred organic taro shoot", "code": "50476858" },
      { "name": "Canned or jarred organic tepeguaje", "code": "50476859" },
      { "name": "Canned or jarred organic tendergreen", "code": "50476860" },
      { "name": "Canned or jarred organic tindora", "code": "50476861" },
      { "name": "Canned or jarred organic tree onion", "code": "50476862" },
      { "name": "Canned or jarred organic udo", "code": "50476863" },
      {
        "name": "Canned or jarred organic water chestnuts",
        "code": "50476864"
      },
      { "name": "Canned or jarred organic yampi", "code": "50476865" },
      { "name": "Canned or jarred organic yautia", "code": "50476866" },
      { "name": "Canned or jarred organic yu choy", "code": "50476867" },
      { "name": "Canned or jarred organic yuca", "code": "50476868" },
      { "name": "Canned or jarred organic sugar peas", "code": "50476900" },
      { "name": "Canned or jarred organic bikini peas", "code": "50476901" },
      { "name": "Canned or jarred organic cavalier peas", "code": "50476902" },
      { "name": "Canned or jarred organic daisy peas", "code": "50476903" },
      { "name": "Canned or jarred organic darfon peas", "code": "50476904" },
      {
        "name": "Canned or jarred organic early onward peas",
        "code": "50476905"
      },
      {
        "name": "Canned or jarred organic feltham first peas",
        "code": "50476906"
      },
      {
        "name": "Canned or jarred organic hurst green shaft peas",
        "code": "50476907"
      },
      {
        "name": "Canned or jarred organic oregon sugar pod peas",
        "code": "50476908"
      },
      {
        "name": "Canned or jarred organic prince albert peas",
        "code": "50476909"
      },
      {
        "name": "Canned or jarred organic reuzensuiker peas",
        "code": "50476910"
      },
      { "name": "Fresh vegetable purees", "code": "50480000" },
      { "name": "Artichoke purees", "code": "50481500" },
      { "name": "Brittany artichoke purees", "code": "50481501" },
      { "name": "Catanese artichoke purees", "code": "50481502" },
      { "name": "French artichoke purees", "code": "50481503" },
      { "name": "Green globe artichoke purees", "code": "50481504" },
      { "name": "Gros camus de bretagne artichoke purees", "code": "50481505" },
      { "name": "Midi artichoke purees", "code": "50481506" },
      { "name": "Purple globe artichoke purees", "code": "50481507" },
      { "name": "Purple sicilian artichoke purees", "code": "50481508" },
      { "name": "Romanesco artichoke purees", "code": "50481509" },
      { "name": "Spinoso sardo artichoke purees", "code": "50481510" },
      { "name": "Vert de laon artichoke purees", "code": "50481511" },
      { "name": "Violetta di chioggia artichoke purees", "code": "50481512" },
      { "name": "Violetto di toscana artichoke purees", "code": "50481513" },
      { "name": "Chive purees", "code": "50481600" },
      { "name": "Chinese chive purees", "code": "50481601" },
      { "name": "Common chive purees", "code": "50481602" },
      { "name": "Asparagus purees", "code": "50481700" },
      { "name": "Connover's colossal asparagus purees", "code": "50481701" },
      { "name": "Franklin asparagus purees", "code": "50481702" },
      { "name": "Giant mammoth asparagus purees", "code": "50481703" },
      { "name": "Lucullus asparagus purees", "code": "50481704" },
      { "name": "Martha washington asparagus purees", "code": "50481705" },
      { "name": "Avocado purees", "code": "50481800" },
      { "name": "Ajax b-7 avocado purees", "code": "50481801" },
      { "name": "Arue avocado purees", "code": "50481802" },
      { "name": "Bacon avocado purees", "code": "50481803" },
      { "name": "Benik avocado purees", "code": "50481804" },
      { "name": "Bernecker avocado purees", "code": "50481805" },
      { "name": "Beta avocado purees", "code": "50481806" },
      { "name": "Biondo avocado purees", "code": "50481807" },
      { "name": "Black prince avocado purees", "code": "50481808" },
      { "name": "Blair avocado purees", "code": "50481809" },
      { "name": "Blair booth avocado purees", "code": "50481810" },
      { "name": "Booth 1 avocado purees", "code": "50481811" },
      { "name": "Booth 3 avocado purees", "code": "50481812" },
      { "name": "Booth 5 avocado purees", "code": "50481813" },
      { "name": "Booth 7 avocado purees", "code": "50481814" },
      { "name": "Booth 8 avocado purees", "code": "50481815" },
      { "name": "Brooks 1978 avocado purees", "code": "50481816" },
      { "name": "Brookslate avocado purees", "code": "50481817" },
      { "name": "California haas avocado purees", "code": "50481818" },
      { "name": "Catalina avocado purees", "code": "50481819" },
      { "name": "Chica avocado purees", "code": "50481820" },
      { "name": "Choquette avocado purees", "code": "50481821" },
      { "name": "Christina avocado purees", "code": "50481822" },
      { "name": "Collinson avocado purees", "code": "50481823" },
      { "name": "Donnie avocado purees", "code": "50481824" },
      { "name": "Dr dupuis number 2 avocado purees", "code": "50481825" },
      { "name": "Dr dupuis avocado purees", "code": "50481826" },
      { "name": "Ettinger avocado purees", "code": "50481827" },
      { "name": "Fuchs avocado purees", "code": "50481828" },
      { "name": "Fuchs gwen avocado purees", "code": "50481829" },
      { "name": "Fuerte avocado purees", "code": "50481830" },
      { "name": "Gorham avocado purees", "code": "50481831" },
      { "name": "Gossman avocado purees", "code": "50481832" },
      { "name": "Guatemalan seedling avocado purees", "code": "50481833" },
      { "name": "Hall avocado purees", "code": "50481834" },
      { "name": "Hardee avocado purees", "code": "50481835" },
      { "name": "Haas avocado purees", "code": "50481836" },
      { "name": "Herman avocado purees", "code": "50481837" },
      { "name": "Hickson avocado purees", "code": "50481838" },
      { "name": "K-5 avocado purees", "code": "50481839" },
      { "name": "K-9 avocado purees", "code": "50481840" },
      { "name": "Lamb haas avocado purees", "code": "50481841" },
      { "name": "Leona avocado purees", "code": "50481842" },
      { "name": "Leona linda avocado purees", "code": "50481843" },
      { "name": "Lisa p avocado purees", "code": "50481844" },
      { "name": "Lisa loretta avocado purees", "code": "50481845" },
      { "name": "Loretta avocado purees", "code": "50481846" },
      { "name": "Lula avocado purees", "code": "50481847" },
      { "name": "Lula macarthur avocado purees", "code": "50481848" },
      { "name": "Marcus avocado purees", "code": "50481849" },
      { "name": "Melendez avocado purees", "code": "50481850" },
      { "name": "Meya p avocado purees", "code": "50481851" },
      { "name": "Miguel p avocado purees", "code": "50481852" },
      { "name": "Monroe avocado purees", "code": "50481853" },
      { "name": "Murrieta green avocado purees", "code": "50481854" },
      { "name": "Nabal avocado purees", "code": "50481855" },
      { "name": "Nadir avocado purees", "code": "50481856" },
      { "name": "Nesbitt avocado purees", "code": "50481857" },
      { "name": "Peterson avocado purees", "code": "50481858" },
      { "name": "Pinelli avocado purees", "code": "50481859" },
      { "name": "Pinkerton avocado purees", "code": "50481860" },
      { "name": "Pollock avocado purees", "code": "50481861" },
      { "name": "Puebla avocado purees", "code": "50481862" },
      { "name": "Reed avocado purees", "code": "50481863" },
      { "name": "Rue avocado purees", "code": "50481864" },
      { "name": "Ruehle avocado purees", "code": "50481865" },
      { "name": "Ryan avocado purees", "code": "50481866" },
      { "name": "Semil 34 avocado purees", "code": "50481867" },
      { "name": "Semil 43 avocado purees", "code": "50481868" },
      { "name": "Simmonds avocado purees", "code": "50481869" },
      { "name": "Simpson avocado purees", "code": "50481870" },
      { "name": "Taylor avocado purees", "code": "50481871" },
      { "name": "Tonnage avocado purees", "code": "50481872" },
      { "name": "Tower avocado purees", "code": "50481873" },
      { "name": "Tower li avocado purees", "code": "50481874" },
      { "name": "Trapp avocado purees", "code": "50481875" },
      { "name": "West indian seedling avocado purees", "code": "50481876" },
      { "name": "Wagner avocado purees", "code": "50481877" },
      { "name": "Waldin avocado purees", "code": "50481878" },
      { "name": "Wurtz avocado purees", "code": "50481879" },
      { "name": "Zio p avocado purees", "code": "50481880" },
      { "name": "Ziu avocado purees", "code": "50481881" },
      { "name": "Zutano avocado purees", "code": "50481882" },
      { "name": "Bean purees", "code": "50481900" },
      { "name": "Anasazi or aztec bean purees", "code": "50481901" },
      { "name": "Appaloosa bean purees", "code": "50481902" },
      { "name": "Azuki bean purees", "code": "50481903" },
      { "name": "Barlotti bean purees", "code": "50481904" },
      { "name": "Black appaloosa bean purees", "code": "50481905" },
      { "name": "Black bean purees", "code": "50481906" },
      { "name": "Black gram bean purees", "code": "50481907" },
      { "name": "Black shackamaxon bean purees", "code": "50481908" },
      { "name": "Blackeyed bean purees", "code": "50481909" },
      { "name": "Bobby bean purees", "code": "50481910" },
      { "name": "Bolita bean purees", "code": "50481911" },
      { "name": "Brown lazy wife bean purees", "code": "50481912" },
      { "name": "Calypso bean purees", "code": "50481913" },
      { "name": "Cannellini bean purees", "code": "50481914" },
      { "name": "Castor bean purees", "code": "50481915" },
      { "name": "China yellow bean purees", "code": "50481916" },
      { "name": "Dragon tongue bean purees", "code": "50481917" },
      { "name": "European soldier bean purees", "code": "50481918" },
      { "name": "Fava or broad bean purees", "code": "50481919" },
      { "name": "Flageolet bean purees", "code": "50481920" },
      { "name": "French horticultural bean purees", "code": "50481921" },
      { "name": "French navy bean purees", "code": "50481922" },
      { "name": "Giant white coco bean purees", "code": "50481923" },
      { "name": "Green bean purees", "code": "50481924" },
      { "name": "Green romano bean purees", "code": "50481925" },
      { "name": "Guar gum bean purees", "code": "50481926" },
      { "name": "Haricot bean purees", "code": "50481927" },
      { "name": "Hyacinth bean purees", "code": "50481928" },
      { "name": "Italian type bean purees", "code": "50481929" },
      { "name": "Jackson wonder bean purees", "code": "50481930" },
      { "name": "Jacob's cattle bean purees", "code": "50481931" },
      { "name": "Kentucky wonder bean purees", "code": "50481932" },
      { "name": "Kidney bean purees", "code": "50481933" },
      { "name": "Lima bean purees", "code": "50481934" },
      { "name": "Madeira madera bean purees", "code": "50481935" },
      { "name": "Marrow bean purees", "code": "50481936" },
      { "name": "Mat bean purees", "code": "50481937" },
      { "name": "Monstoller wild goose bean purees", "code": "50481938" },
      { "name": "Mortgage lifter bean purees", "code": "50481939" },
      { "name": "Moth bean purees", "code": "50481940" },
      { "name": "Mung bean purees", "code": "50481941" },
      { "name": "Munsi wolf bea purees", "code": "50481942" },
      { "name": "Nuna bean purees", "code": "50481943" },
      { "name": "Pinto bean purees", "code": "50481944" },
      { "name": "Pole bean purees", "code": "50481945" },
      { "name": "Runner bean purees", "code": "50481946" },
      { "name": "String bean purees", "code": "50481947" },
      { "name": "Tamarind bean purees", "code": "50481948" },
      { "name": "Tonka bean purees", "code": "50481949" },
      { "name": "Wax bean purees", "code": "50481950" },
      { "name": "Winged bean purees", "code": "50481951" },
      { "name": "Yard long bean purees", "code": "50481952" },
      { "name": "Peruvian canary bean purees", "code": "50481953" },
      { "name": "Beet purees", "code": "50482000" },
      { "name": "Action beet purees", "code": "50482001" },
      { "name": "Albina vereduna beet purees", "code": "50482002" },
      { "name": "Barbabietola di chioggia beet purees", "code": "50482003" },
      { "name": "Boltardy beet purees", "code": "50482004" },
      { "name": "Bonel beet purees", "code": "50482005" },
      { "name": "Burpees golden beet purees", "code": "50482006" },
      { "name": "Cheltenham green top beet purees", "code": "50482007" },
      { "name": "Cheltenham mono beet purees", "code": "50482008" },
      { "name": "Chioggia beet purees", "code": "50482009" },
      { "name": "Cylindra beet purees", "code": "50482010" },
      { "name": "D'egypte beet purees", "code": "50482011" },
      { "name": "Detroit 2 dark red beet purees", "code": "50482012" },
      { "name": "Detroit 2 little ball beet purees", "code": "50482013" },
      { "name": "Egyptian flat beet purees", "code": "50482014" },
      { "name": "Egyptian turnip rooted beet purees", "code": "50482015" },
      { "name": "Formanova beet purees", "code": "50482016" },
      { "name": "Forono beet purees", "code": "50482017" },
      { "name": "Monaco beet purees", "code": "50482018" },
      { "name": "Monogram beet purees", "code": "50482019" },
      { "name": "Pronto beet purees", "code": "50482020" },
      { "name": "Regalia beet purees", "code": "50482021" },
      { "name": "Sugar beet purees", "code": "50482022" },
      { "name": "Broccoli purees", "code": "50482100" },
      { "name": "Broccolini purees", "code": "50482101" },
      { "name": "Broccoli romanesco purees", "code": "50482102" },
      { "name": "Broccoli raab purees", "code": "50482103" },
      { "name": "Brussel sprout purees", "code": "50482200" },
      { "name": "Oliver brussel sprout purees", "code": "50482201" },
      { "name": "Peer gynt brussel sprout purees", "code": "50482202" },
      { "name": "Rampart brussel sprout purees", "code": "50482203" },
      { "name": "Rubine brussel sprout purees", "code": "50482204" },
      { "name": "Widgeon brussel sprout purees", "code": "50482205" },
      { "name": "Bunching onion purees", "code": "50482300" },
      { "name": "Beltsville bunching onion purees", "code": "50482301" },
      { "name": "Feast bunching onion purees", "code": "50482302" },
      { "name": "Ishikura bunching onion purees", "code": "50482303" },
      { "name": "Kyoto market bunching onion purees", "code": "50482304" },
      { "name": "Red beard bunching onion purees", "code": "50482305" },
      { "name": "Redmate bunching onion purees", "code": "50482306" },
      { "name": "Santa claus bunching onion purees", "code": "50482307" },
      { "name": "Tokyo bunching onion purees", "code": "50482308" },
      { "name": "White lisbon bunching onion purees", "code": "50482309" },
      { "name": "Winter white bunching onion purees", "code": "50482310" },
      { "name": "Winter-over bunching onion purees", "code": "50482311" },
      { "name": "Cabbage purees", "code": "50482400" },
      { "name": "Black cabbage purees", "code": "50482401" },
      { "name": "Savoy cabbage purees", "code": "50482402" },
      { "name": "Skunk cabbage purees", "code": "50482403" },
      { "name": "White cabbage purees", "code": "50482404" },
      { "name": "Purple cabbage purees", "code": "50482405" },
      { "name": "Cardoon purees", "code": "50482500" },
      { "name": "Lunghi cardoon purees", "code": "50482501" },
      { "name": "Gobbi cardoon purees", "code": "50482502" },
      { "name": "Carrot purees", "code": "50482600" },
      { "name": "Amsterdam carrot purees", "code": "50482601" },
      { "name": "Autumn king carrot purees", "code": "50482602" },
      { "name": "Berlicum carrot purees", "code": "50482603" },
      { "name": "Chantenay carrot purees", "code": "50482604" },
      { "name": "Nantes carrot purees", "code": "50482605" },
      { "name": "Paris market carrot purees", "code": "50482606" },
      { "name": "Baby carrot purees", "code": "50482607" },
      { "name": "Cauliflower purees", "code": "50482700" },
      { "name": "All the year round cauliflower purees", "code": "50482701" },
      { "name": "Alverda cauliflower purees", "code": "50482702" },
      { "name": "Autumn giant 3 cauliflower purees", "code": "50482703" },
      { "name": "Dok elgon cauliflower purees", "code": "50482704" },
      { "name": "Early snowball cauliflower purees", "code": "50482705" },
      { "name": "Limelight cauliflower purees", "code": "50482706" },
      { "name": "Minaret cauliflower purees", "code": "50482707" },
      { "name": "Orange bouquet cauliflower purees", "code": "50482708" },
      { "name": "Purple cape cauliflower purees", "code": "50482709" },
      { "name": "Snowball cauliflower purees", "code": "50482710" },
      { "name": "Walcheren winter 3 cauliflower purees", "code": "50482711" },
      { "name": "White rock cauliflower purees", "code": "50482712" },
      { "name": "Celery purees", "code": "50482800" },
      { "name": "Celebrity celery purees", "code": "50482801" },
      { "name": "Celeriac purees", "code": "50482802" },
      { "name": "Chinese celery purees", "code": "50482803" },
      { "name": "French dinant celery purees", "code": "50482804" },
      { "name": "Giant pink celery purees", "code": "50482805" },
      { "name": "Giant red celery purees", "code": "50482806" },
      { "name": "Giant white celery purees", "code": "50482807" },
      { "name": "Golden self-blanching celery purees", "code": "50482808" },
      { "name": "Greensleeves celery purees", "code": "50482809" },
      { "name": "Hopkins fenlander celery purees", "code": "50482810" },
      { "name": "Ivory tower celery purees", "code": "50482811" },
      { "name": "Lathom self-blanching celery purees", "code": "50482812" },
      { "name": "Soup celery d'amsterdam purees", "code": "50482813" },
      { "name": "Standard bearer celery purees", "code": "50482814" },
      { "name": "Tall utah triumph celery purees", "code": "50482815" },
      { "name": "Chard purees", "code": "50482900" },
      { "name": "Bright lights chard purees", "code": "50482901" },
      { "name": "Fordhook giant chard purees", "code": "50482902" },
      { "name": "Lucullus chard purees", "code": "50482903" },
      { "name": "Perpetual spinach chard purees", "code": "50482904" },
      { "name": "Rhubarb chard purees", "code": "50482905" },
      { "name": "Swiss chard purees", "code": "50482906" },
      { "name": "Vulcan chard purees", "code": "50482907" },
      { "name": "White king chard purees", "code": "50482908" },
      { "name": "Chicory purees", "code": "50483000" },
      { "name": "Broad leaved batavian chicory purees", "code": "50483001" },
      { "name": "En cornet de bordeaux chicory purees", "code": "50483002" },
      { "name": "Green curled ruffee chicory purees", "code": "50483003" },
      { "name": "Green curled chicory purees", "code": "50483004" },
      { "name": "Ione limnos chicory purees", "code": "50483005" },
      { "name": "Riccia pancalieri chicory purees", "code": "50483006" },
      { "name": "Salad king chicory purees", "code": "50483007" },
      { "name": "Sanda chicory purees", "code": "50483008" },
      { "name": "Scarola verde chicory purees", "code": "50483009" },
      { "name": "Tres fine maraichere chicory purees", "code": "50483010" },
      {
        "name": "Wallone freisee weschelkopf chicory purees",
        "code": "50483011"
      },
      { "name": "Chinese cabbage purees", "code": "50483100" },
      { "name": "Bok choy purees", "code": "50483101" },
      { "name": "Chinese flat-headed cabbage purees", "code": "50483102" },
      { "name": "Chinese flowering cabbage purees", "code": "50483103" },
      { "name": "Choy sum purees", "code": "50483104" },
      { "name": "Dwarf bok choy purees", "code": "50483105" },
      { "name": "Fengshan bok choy purees", "code": "50483106" },
      { "name": "Jade pagoda bok choy purees", "code": "50483107" },
      { "name": "Kasumi bok choy purees", "code": "50483108" },
      { "name": "Nerva bok choy purees", "code": "50483109" },
      { "name": "Rosette bok choy purees", "code": "50483110" },
      { "name": "Ruffles bok choy purees", "code": "50483111" },
      { "name": "Santo serrated leaved cabbage purees", "code": "50483112" },
      { "name": "Shanghai d bok choy purees", "code": "50483113" },
      { "name": "Shantung purees", "code": "50483114" },
      { "name": "Tip top cabbage purees", "code": "50483115" },
      { "name": "Yau choy sum purees", "code": "50483116" },
      { "name": "Corn purees", "code": "50483200" },
      { "name": "Aloha corn purees", "code": "50483201" },
      { "name": "Alpine corn purees", "code": "50483202" },
      { "name": "Ambrosia corn purees", "code": "50483203" },
      { "name": "Argent corn purees", "code": "50483204" },
      { "name": "Aspen corn purees", "code": "50483205" },
      { "name": "Avalanche corn purees", "code": "50483206" },
      { "name": "Biqueen corn purees", "code": "50483207" },
      { "name": "Bodacious corn purees", "code": "50483208" },
      { "name": "Butter and sugar corn purees", "code": "50483209" },
      { "name": "Calico belle corn purees", "code": "50483210" },
      { "name": "Camelot corn purees", "code": "50483211" },
      {
        "name": "Challengercrisp \u2018n sweet corn purees",
        "code": "50483212"
      },
      { "name": "Champ corn purees", "code": "50483213" },
      { "name": "Cotton candy corn purees", "code": "50483214" },
      { "name": "D\u2019artagnan corn purees", "code": "50483215" },
      { "name": "Dazzle corn purees", "code": "50483216" },
      { "name": "Diamond and gold corn purees", "code": "50483217" },
      { "name": "Divinity corn purees", "code": "50483218" },
      { "name": "Double delight corn purees", "code": "50483219" },
      { "name": "Double gem corn purees", "code": "50483220" },
      { "name": "Earlivee corn purees", "code": "50483221" },
      { "name": "Early xtra sweet corn purees", "code": "50483222" },
      { "name": "Excel corn purees", "code": "50483223" },
      { "name": "Golden cross bantam corn purees", "code": "50483224" },
      { "name": "Honey and cream corn purees", "code": "50483225" },
      { "name": "Honey \u2018n pearl corn purees", "code": "50483226" },
      { "name": "How sweet it is corn purees", "code": "50483227" },
      { "name": "Hudson corn purees", "code": "50483228" },
      { "name": "Illini gold corn purees", "code": "50483229" },
      { "name": "Illini xtra sweet corn purees", "code": "50483230" },
      { "name": "Incredible corn purees", "code": "50483231" },
      { "name": "Iochief corn purees", "code": "50483232" },
      { "name": "Jubilee corn purees", "code": "50483233" },
      { "name": "Jubilee supersweet corn purees", "code": "50483234" },
      { "name": "Kandy korn corn purees", "code": "50483235" },
      { "name": "Kiss \u2018n tell corn purees", "code": "50483236" },
      { "name": "Lancelot corn purees", "code": "50483237" },
      { "name": "Maple sweet corn purees", "code": "50483238" },
      { "name": "Medley corn purees", "code": "50483239" },
      { "name": "Merlin corn purees", "code": "50483240" },
      { "name": "Miracle corn purees", "code": "50483241" },
      { "name": "Nk-199 corn purees", "code": "50483242" },
      { "name": "Peaches and cream corn purees", "code": "50483243" },
      { "name": "Pearl white corn purees", "code": "50483244" },
      { "name": "Pegasus corn purees", "code": "50483245" },
      { "name": "Phenomenal corn purees", "code": "50483246" },
      { "name": "Platinum lady corn purees", "code": "50483247" },
      { "name": "Precocious corn purees", "code": "50483248" },
      { "name": "Pristine corn purees", "code": "50483249" },
      { "name": "Quickie corn purees", "code": "50483250" },
      { "name": "Radiance corn purees", "code": "50483251" },
      { "name": "Seneca brave corn purees", "code": "50483252" },
      { "name": "Seneca dawn corn purees", "code": "50483253" },
      { "name": "Seneca horizon corn purees", "code": "50483254" },
      { "name": "Seneca starshine corn purees", "code": "50483255" },
      { "name": "Seneca white knight corn purees", "code": "50483256" },
      { "name": "Showcase corn purees", "code": "50483257" },
      { "name": "Silver queen corn purees", "code": "50483258" },
      { "name": "Snowbelle corn purees", "code": "50483259" },
      { "name": "Spring snow corn purees", "code": "50483260" },
      { "name": "Spring treat corn purees", "code": "50483261" },
      { "name": "Sugar and gold corn purees", "code": "50483262" },
      { "name": "Sugar buns corn purees", "code": "50483263" },
      { "name": "Sugar snow corn purees", "code": "50483264" },
      { "name": "Sundance corn purees", "code": "50483265" },
      { "name": "Telstar corn purees", "code": "50483266" },
      { "name": "Terminator corn purees", "code": "50483267" },
      { "name": "Treasure corn purees", "code": "50483268" },
      { "name": "Tuxedo corn purees", "code": "50483269" },
      { "name": "Cress purees", "code": "50483300" },
      { "name": "Land cress purees", "code": "50483301" },
      { "name": "Nasturtium purees", "code": "50483302" },
      { "name": "Watercress purees", "code": "50483303" },
      { "name": "Wintercress purees", "code": "50483304" },
      { "name": "Cucumber purees", "code": "50483400" },
      { "name": "Arena cucumber purees", "code": "50483401" },
      { "name": "Armenian cucumber purees", "code": "50483402" },
      { "name": "Athene cucumber purees", "code": "50483403" },
      { "name": "Bianco lungo di parigi cucumber purees", "code": "50483404" },
      { "name": "Burpless tasty green cucumber purees", "code": "50483405" },
      { "name": "Chicago pickling cucumber purees", "code": "50483406" },
      { "name": "Crystal apple cucumber purees", "code": "50483407" },
      { "name": "Crystal lemon cucumber purees", "code": "50483408" },
      { "name": "Danimas cucumber purees", "code": "50483409" },
      { "name": "Gherkin cucumber purees", "code": "50483410" },
      { "name": "Hokus cucumber purees", "code": "50483411" },
      { "name": "Japanese cucumber purees", "code": "50483412" },
      { "name": "Karela cucumber purees", "code": "50483413" },
      { "name": "Korila cucumber purees", "code": "50483414" },
      { "name": "Long green improved cucumber purees", "code": "50483415" },
      { "name": "Marketmore cucumber purees", "code": "50483416" },
      { "name": "Midget cucumber purees", "code": "50483417" },
      { "name": "National pickling cucumber purees", "code": "50483418" },
      { "name": "Persian cucumber purees", "code": "50483419" },
      { "name": "Telegraph cucumber purees", "code": "50483420" },
      { "name": "Telegraph improved cucumber purees", "code": "50483421" },
      { "name": "Vert de massy cornichon cucumber purees", "code": "50483422" },
      { "name": "Yamato cucumber purees", "code": "50483423" },
      { "name": "Eggplant purees", "code": "50483500" },
      { "name": "Bambino eggplant purees", "code": "50483501" },
      { "name": "Black beauty eggplant purees", "code": "50483502" },
      { "name": "Black enorma eggplant purees", "code": "50483503" },
      { "name": "Chinese eggplant purees", "code": "50483504" },
      { "name": "Easter egg eggplant purees", "code": "50483505" },
      { "name": "Filipino eggplant purees", "code": "50483506" },
      { "name": "Florida market eggplant purees", "code": "50483507" },
      { "name": "Indian eggplant purees", "code": "50483508" },
      { "name": "Italian eggplant purees", "code": "50483509" },
      { "name": "Japanese eggplant purees", "code": "50483510" },
      { "name": "Long purple eggplant purees", "code": "50483511" },
      { "name": "Long striped eggplant purees", "code": "50483512" },
      { "name": "Moneymaker eggplant purees", "code": "50483513" },
      { "name": "Ova eggplant purees", "code": "50483514" },
      { "name": "Pea eggplant purees", "code": "50483515" },
      { "name": "Short tom eggplant purees", "code": "50483516" },
      { "name": "Sicilian eggplant purees", "code": "50483517" },
      { "name": "Thai eggplant purees", "code": "50483518" },
      { "name": "Violette di firenze eggplant purees", "code": "50483519" },
      { "name": "White eggplant purees", "code": "50483520" },
      { "name": "Endive purees", "code": "50483600" },
      { "name": "Brussels witloof endive purees", "code": "50483601" },
      { "name": "Castelfranco endive purees", "code": "50483602" },
      { "name": "Catalogna di galatina endive purees", "code": "50483603" },
      { "name": "Chioggia endive purees", "code": "50483604" },
      { "name": "Grumolo verde endive purees", "code": "50483605" },
      { "name": "Large rooted magdeburg endive purees", "code": "50483606" },
      { "name": "Palla rossa zorzi precoce endive purees", "code": "50483607" },
      { "name": "Radice amare endive purees", "code": "50483608" },
      { "name": "Rossa di treviso endive purees", "code": "50483609" },
      { "name": "Rossa di verona endive purees", "code": "50483610" },
      { "name": "Soncino endive purees", "code": "50483611" },
      { "name": "Sugarhat endive purees", "code": "50483612" },
      { "name": "Verona endive purees", "code": "50483613" },
      { "name": "Witloof zoom endive purees", "code": "50483614" },
      { "name": "Fennel purees", "code": "50483700" },
      { "name": "Cantino fennel purees", "code": "50483701" },
      { "name": "Fino fennel purees", "code": "50483702" },
      { "name": "Herald fennel purees", "code": "50483703" },
      { "name": "Perfection fennel purees", "code": "50483704" },
      { "name": "Sirio fennel purees", "code": "50483705" },
      { "name": "Sweet florence fennel purees", "code": "50483706" },
      { "name": "Tardo fennel purees", "code": "50483707" },
      { "name": "Garlic purees", "code": "50483800" },
      { "name": "California late garlic purees", "code": "50483801" },
      { "name": "Chinese garlic stem purees", "code": "50483802" },
      { "name": "Garlic chive purees", "code": "50483803" },
      { "name": "Germidor garlic purees", "code": "50483804" },
      { "name": "Long keeper garlic purees", "code": "50483805" },
      { "name": "Ramson garlic purees", "code": "50483806" },
      { "name": "Rocambole garlic purees", "code": "50483807" },
      { "name": "Rose de lautrec garlic purees", "code": "50483808" },
      { "name": "Solent wight garlic purees", "code": "50483809" },
      { "name": "Spanish morado garlic purees", "code": "50483810" },
      { "name": "Venetian italian garlic purees", "code": "50483811" },
      { "name": "Gourd purees", "code": "50483900" },
      { "name": "Angled loofah purees", "code": "50483901" },
      { "name": "Bitter gourd purees", "code": "50483902" },
      { "name": "Bottle gourd purees", "code": "50483903" },
      { "name": "Calabash gourd purees", "code": "50483904" },
      { "name": "Fuzzy hairy melon purees", "code": "50483905" },
      { "name": "Musky gourd purees", "code": "50483906" },
      { "name": "Smooth loofah purees", "code": "50483907" },
      { "name": "Snake gourd purees", "code": "50483908" },
      { "name": "Spiny bitter gourd purees", "code": "50483909" },
      { "name": "Tinda gourd purees", "code": "50483910" },
      { "name": "Tindoori gourd purees", "code": "50483911" },
      { "name": "Green pea purees", "code": "50484000" },
      { "name": "China pea purees", "code": "50484001" },
      { "name": "English pea purees", "code": "50484002" },
      { "name": "Garden pea purees", "code": "50484003" },
      { "name": "Snow pea purees", "code": "50484004" },
      { "name": "Sugar snap pea purees", "code": "50484005" },
      { "name": "Herb purees", "code": "50484100" },
      { "name": "Basil purees", "code": "50484101" },
      { "name": "Bay leaf purees", "code": "50484102" },
      { "name": "Broage purees", "code": "50484103" },
      { "name": "Caraway purees", "code": "50484104" },
      { "name": "Chervil purees", "code": "50484105" },
      { "name": "Cilantro purees", "code": "50484106" },
      { "name": "Cipolino purees", "code": "50484107" },
      { "name": "Curry leaf purees", "code": "50484108" },
      { "name": "Dill purees", "code": "50484109" },
      { "name": "Epazote purees", "code": "50484110" },
      { "name": "Fenugreek purees", "code": "50484111" },
      { "name": "Lemon gras purees", "code": "50484112" },
      { "name": "Marjoram purees", "code": "50484113" },
      { "name": "Mint purees", "code": "50484114" },
      { "name": "Oregano purees", "code": "50484115" },
      { "name": "Papalo purees", "code": "50484116" },
      { "name": "Pepicha purees", "code": "50484117" },
      { "name": "Perilla purees", "code": "50484118" },
      { "name": "Recao purees", "code": "50484119" },
      { "name": "Rosemary purees", "code": "50484120" },
      { "name": "Sage purees", "code": "50484121" },
      { "name": "Salsify purees", "code": "50484122" },
      { "name": "Savory purees", "code": "50484123" },
      { "name": "Tarragon purees", "code": "50484124" },
      { "name": "Thyme purees", "code": "50484125" },
      { "name": "Tumeric purees", "code": "50484126" },
      { "name": "Verdulaga purees", "code": "50484127" },
      { "name": "Kale purees", "code": "50484200" },
      { "name": "Curly kale purees", "code": "50484201" },
      { "name": "Collard green purees", "code": "50484202" },
      { "name": "Kohlrabi purees", "code": "50484300" },
      { "name": "Azur star kohlrabi purees", "code": "50484301" },
      { "name": "Green vienna kohlrabi purees", "code": "50484302" },
      { "name": "Lanro kohlrabi purees", "code": "50484303" },
      { "name": "Purple vienna kohlrabi purees", "code": "50484304" },
      { "name": "Rowel trero kohlrabi purees", "code": "50484305" },
      { "name": "White vienna kohlrabi purees", "code": "50484306" },
      { "name": "Leek purees", "code": "50484400" },
      { "name": "Autumn giant-cobra leek purees", "code": "50484401" },
      { "name": "Autumn mammoth 2 leek purees", "code": "50484402" },
      { "name": "Bleu de solaise leek purees", "code": "50484403" },
      { "name": "Cortina leek purees", "code": "50484404" },
      { "name": "Prelina leek purees", "code": "50484405" },
      { "name": "Wild leek ramp purees", "code": "50484406" },
      { "name": "Lentil purees", "code": "50484500" },
      { "name": "Beluga lentil purees", "code": "50484501" },
      { "name": "French green lentil purees", "code": "50484502" },
      { "name": "Green lentil purees", "code": "50484503" },
      { "name": "Petite crimson lentil purees", "code": "50484504" },
      { "name": "Spanish pardina lentil purees", "code": "50484505" },
      { "name": "Split red lentil purees", "code": "50484506" },
      { "name": "Split yellow lentil purees", "code": "50484507" },
      { "name": "Tarahumara pinks lentil purees", "code": "50484508" },
      { "name": "Lettuce purees", "code": "50484600" },
      { "name": "Bibb lettuce purees", "code": "50484601" },
      { "name": "Boston lettuce purees", "code": "50484602" },
      { "name": "Frisee lettuce purees", "code": "50484603" },
      { "name": "Lolla rossa lettuce purees", "code": "50484604" },
      { "name": "Mesculin mix lettuce purees", "code": "50484605" },
      { "name": "Mizuna lettuce purees", "code": "50484606" },
      { "name": "Red leaf lettuce purees", "code": "50484607" },
      { "name": "Red oak leaf lettuce purees", "code": "50484608" },
      { "name": "Ruby romaine lettuce purees", "code": "50484609" },
      { "name": "Baby red romaine lettuce purees", "code": "50484610" },
      { "name": "Butterhead lettuce purees", "code": "50484611" },
      { "name": "Chinese lettuce purees", "code": "50484612" },
      { "name": "Crisphead lettuce purees", "code": "50484613" },
      { "name": "Green leaf lettuce purees", "code": "50484614" },
      { "name": "Iceberg lettuce purees", "code": "50484615" },
      { "name": "Lamb\u2019s lettuce purees", "code": "50484616" },
      { "name": "Looseleaf lettuce purees", "code": "50484617" },
      { "name": "Mache lettuce purees", "code": "50484618" },
      { "name": "Red boston lettuce purees", "code": "50484619" },
      { "name": "Red headed lettuce purees", "code": "50484620" },
      { "name": "Romaine lettuce purees", "code": "50484621" },
      { "name": "Russian red mustard lettuce purees", "code": "50484622" },
      { "name": "Tatsoi lettuce purees", "code": "50484623" },
      { "name": "Malanga purees", "code": "50484700" },
      { "name": "Amarilla malanga purees", "code": "50484701" },
      { "name": "Blanca malanga purees", "code": "50484702" },
      { "name": "Coco malanga purees", "code": "50484703" },
      { "name": "Eddoes malanga purees", "code": "50484704" },
      { "name": "Islena malanga purees", "code": "50484705" },
      { "name": "Lila malanga purees", "code": "50484706" },
      { "name": "Mushroom purees", "code": "50484800" },
      { "name": "Black trumpet mushroom purees", "code": "50484801" },
      { "name": "Brown mushroom purees", "code": "50484802" },
      { "name": "Champinion mushroom purees", "code": "50484803" },
      { "name": "Chanterelle mushroom purees", "code": "50484804" },
      { "name": "Cremini mushroom purees", "code": "50484805" },
      { "name": "Enoki mushroom purees", "code": "50484806" },
      { "name": "Hedge hog mushroom purees", "code": "50484807" },
      { "name": "Hen of the woods mushroom purees", "code": "50484808" },
      { "name": "Lobster mushroom purees", "code": "50484809" },
      { "name": "Morels mushroom purees", "code": "50484810" },
      { "name": "Oyster mushroom purees", "code": "50484811" },
      { "name": "Pleurotus mushroom purees", "code": "50484812" },
      { "name": "Pompom mushroom purees", "code": "50484813" },
      { "name": "Porcieni mushroom purees", "code": "50484814" },
      { "name": "Portobella mushroom purees", "code": "50484815" },
      { "name": "Shiitake mushroom purees", "code": "50484816" },
      { "name": "Shimeji mushroom purees", "code": "50484817" },
      { "name": "St george's mushroom purees", "code": "50484818" },
      { "name": "White mushroom purees", "code": "50484819" },
      { "name": "White trumpet mushroom purees", "code": "50484820" },
      { "name": "Woodear mushroom purees", "code": "50484821" },
      { "name": "Seta mushroom purees", "code": "50484822" },
      { "name": "Tonku mushroom purees", "code": "50484823" },
      { "name": "Mustard purees", "code": "50484900" },
      { "name": "Bamboo mustard purees", "code": "50484901" },
      { "name": "Garlic mustard purees", "code": "50484902" },
      { "name": "Giantleafed mustard purees", "code": "50484903" },
      { "name": "Red in snow mustard purees", "code": "50484904" },
      { "name": "Southern mustard purees", "code": "50484905" },
      { "name": "Wrapped heart mustard purees", "code": "50484906" },
      { "name": "Nightshade purees", "code": "50485000" },
      { "name": "Chinese lantern purees", "code": "50485001" },
      { "name": "Garden huckleberry purees", "code": "50485002" },
      { "name": "Naranjillo purees", "code": "50485003" },
      { "name": "Tomatillo purees", "code": "50485004" },
      { "name": "Okra purees", "code": "50485100" },
      { "name": "Artist okra purees", "code": "50485101" },
      { "name": "Burgundy okra purees", "code": "50485102" },
      { "name": "Clemson spineless okra purees", "code": "50485103" },
      { "name": "Dwarf green long pod okra purees", "code": "50485104" },
      { "name": "Mammoth spineless long pod okra purees", "code": "50485105" },
      { "name": "Red velvet okra purees", "code": "50485106" },
      { "name": "Star of david heirloom okra purees", "code": "50485107" },
      { "name": "Onion purees", "code": "50485200" },
      { "name": "Albion onion purees", "code": "50485201" },
      { "name": "Alisa craig onion purees", "code": "50485202" },
      { "name": "Boiling onion purees", "code": "50485203" },
      { "name": "Buffalo onion purees", "code": "50485204" },
      { "name": "Bulb onion purees", "code": "50485205" },
      { "name": "Creaming onion purees", "code": "50485206" },
      { "name": "Express yellow o-x onion purees", "code": "50485207" },
      { "name": "Kelsae onion purees", "code": "50485208" },
      { "name": "Marshalls giant fen globe onion purees", "code": "50485209" },
      { "name": "Pearl onion purees", "code": "50485210" },
      { "name": "Red baron onion purees", "code": "50485211" },
      { "name": "Red onion purees", "code": "50485212" },
      { "name": "Rijnsberger onion purees", "code": "50485213" },
      { "name": "Senshyu semi-globe yellow onion purees", "code": "50485214" },
      { "name": "Sturon onion purees", "code": "50485215" },
      { "name": "Stuttgarter giant onion purees", "code": "50485216" },
      { "name": "Sweet onion purees", "code": "50485217" },
      { "name": "Torpedo or red italian onion purees", "code": "50485218" },
      { "name": "Red storage onion purees", "code": "50485219" },
      { "name": "White storage onion purees", "code": "50485220" },
      { "name": "Yellow storage onion purees", "code": "50485221" },
      { "name": "Pink onion purees", "code": "50485222" },
      { "name": "Green onion purees", "code": "50485223" },
      { "name": "Pea purees", "code": "50485300" },
      { "name": "Purple hull pea purees", "code": "50485301" },
      { "name": "Pinkeye pea purees", "code": "50485302" },
      { "name": "Crowder pea purees", "code": "50485303" },
      { "name": "White acre pea purees", "code": "50485304" },
      { "name": "Blackeyed pea purees", "code": "50485305" },
      { "name": "Zipper cream pea purees", "code": "50485306" },
      { "name": "Peanut purees", "code": "50485400" },
      { "name": "Bambarra groundnut peanut purees", "code": "50485401" },
      { "name": "Florunner peanut purees", "code": "50485402" },
      {
        "name": "Hausa kersting's ground nut peanut purees",
        "code": "50485403"
      },
      { "name": "Spanish peanut purees", "code": "50485404" },
      { "name": "Valencia peanut purees", "code": "50485405" },
      { "name": "Virginia peanut purees", "code": "50485406" },
      { "name": "Pepper purees", "code": "50485500" },
      { "name": "Ajies pepper purees", "code": "50485501" },
      { "name": "Arbol pepper purees", "code": "50485502" },
      { "name": "Cheese pepper purees", "code": "50485503" },
      { "name": "Chilaca pepper purees", "code": "50485504" },
      { "name": "Cubanelles pepper purees", "code": "50485505" },
      { "name": "Fresno pepper purees", "code": "50485506" },
      { "name": "Kapia pepper purees", "code": "50485507" },
      { "name": "Korean pepper purees", "code": "50485508" },
      { "name": "Manzano pepper purees", "code": "50485509" },
      { "name": "Melrose pepper purees", "code": "50485510" },
      { "name": "Yellow chile pepper purees", "code": "50485511" },
      { "name": "Aji dulces pepper purees", "code": "50485512" },
      { "name": "Anaheim pepper purees", "code": "50485513" },
      { "name": "Ancho pepper purees", "code": "50485514" },
      { "name": "Bell pepper purees", "code": "50485515" },
      { "name": "Cascabel pepper purees", "code": "50485516" },
      { "name": "Cayenne pepper purees", "code": "50485517" },
      { "name": "Cherry hots pepper purees", "code": "50485518" },
      { "name": "Chiltecpin pepper purees", "code": "50485519" },
      { "name": "Finger hot pepper purees", "code": "50485520" },
      { "name": "Guajillo pepper purees", "code": "50485521" },
      { "name": "Guerro pepper purees", "code": "50485522" },
      { "name": "Habanero pepper purees", "code": "50485523" },
      { "name": "Hungarian wax pepper purees", "code": "50485524" },
      { "name": "Jalape\u00f1o pepper purees", "code": "50485525" },
      { "name": "Long hot pepper purees", "code": "50485526" },
      { "name": "Mirasol pepper purees", "code": "50485527" },
      { "name": "Pasilla pepper purees", "code": "50485528" },
      { "name": "Peperoncini pepper purees", "code": "50485529" },
      { "name": "Pequin pepper purees", "code": "50485530" },
      { "name": "Pimiento pepper purees", "code": "50485531" },
      { "name": "Poblano pepper purees", "code": "50485532" },
      { "name": "Scotch bonnet pepper purees", "code": "50485533" },
      { "name": "Serrano pepper purees", "code": "50485534" },
      { "name": "Tabasco pepper purees", "code": "50485535" },
      { "name": "Tai pepper purees", "code": "50485536" },
      { "name": "Tepin pepper purees", "code": "50485537" },
      { "name": "Arnaucho chili pepper purees", "code": "50485538" },
      { "name": "Mochero chili pepper purees", "code": "50485539" },
      { "name": "Limo chili pepper purees", "code": "50485540" },
      { "name": "Aji montan purees", "code": "50485541" },
      { "name": "Aji chunch purees", "code": "50485542" },
      { "name": "Potato purees", "code": "50485600" },
      { "name": "Long white potato purees", "code": "50485601" },
      { "name": "Round white potato purees", "code": "50485602" },
      { "name": "Round red potato purees", "code": "50485603" },
      { "name": "Russet potato purees", "code": "50485604" },
      { "name": "Purple potato purees", "code": "50485605" },
      { "name": "Yellow potato purees", "code": "50485606" },
      { "name": "New potato purees", "code": "50485607" },
      { "name": "Specialty potato purees", "code": "50485608" },
      { "name": "Huayro potato purees", "code": "50485609" },
      { "name": "Peruanita potato purees", "code": "50485610" },
      { "name": "Yungay potato purees", "code": "50485611" },
      { "name": "Rutabaga purees", "code": "50485700" },
      { "name": "Acme rutabaga purees", "code": "50485701" },
      { "name": "Angela rutabaga purees", "code": "50485702" },
      { "name": "Best of all rutabaga purees", "code": "50485703" },
      { "name": "Marian rutabaga purees", "code": "50485704" },
      { "name": "Sea vegetable purees", "code": "50485800" },
      { "name": "Agar-agar purees", "code": "50485801" },
      { "name": "Arame purees", "code": "50485802" },
      { "name": "Dulse purees", "code": "50485803" },
      { "name": "Haricot vert de mer purees", "code": "50485804" },
      { "name": "Hijiki purees", "code": "50485805" },
      { "name": "Irish moss purees", "code": "50485806" },
      { "name": "Kelp purees", "code": "50485807" },
      { "name": "Laver purees", "code": "50485808" },
      { "name": "Nori purees", "code": "50485809" },
      { "name": "Red alga purees", "code": "50485810" },
      { "name": "Sea kale purees", "code": "50485811" },
      { "name": "Sea lettuce purees", "code": "50485812" },
      { "name": "Seaweed purees", "code": "50485813" },
      { "name": "Spirulina purees", "code": "50485814" },
      { "name": "Susabi nori purees", "code": "50485815" },
      { "name": "Wakame purees", "code": "50485816" },
      { "name": "Cushuro purees", "code": "50485817" },
      { "name": "Shallot purees", "code": "50485900" },
      { "name": "Atlantic shallot purees", "code": "50485901" },
      { "name": "Creation shallot purees", "code": "50485902" },
      { "name": "Drittler white nest shallot purees", "code": "50485903" },
      { "name": "Giant yellow improved shallot purees", "code": "50485904" },
      { "name": "Golden gourmet shallot purees", "code": "50485905" },
      { "name": "Grise de bagnolet shallot purees", "code": "50485906" },
      { "name": "Hative de niort shallot purees", "code": "50485907" },
      { "name": "Pikant shallot purees", "code": "50485908" },
      { "name": "Red potato onion purees", "code": "50485909" },
      { "name": "Sante shallot purees", "code": "50485910" },
      { "name": "Topper shallot purees", "code": "50485911" },
      { "name": "Sorrel purees", "code": "50486000" },
      { "name": "Dock sorrel purees", "code": "50486001" },
      { "name": "Garden sorrel purees", "code": "50486002" },
      { "name": "Sheep sorrel purees", "code": "50486003" },
      { "name": "Wood sorrel purees", "code": "50486004" },
      { "name": "Spinach purees", "code": "50486100" },
      { "name": "America spinach purees", "code": "50486101" },
      { "name": "Bloomsdale spinach purees", "code": "50486102" },
      { "name": "Giant winter spinach purees", "code": "50486103" },
      { "name": "Horenso spinach purees", "code": "50486104" },
      { "name": "Lamb's quarters spinach purees", "code": "50486105" },
      { "name": "Malabar spinach purees", "code": "50486106" },
      { "name": "Medania spinach purees", "code": "50486107" },
      { "name": "Orach spinach purees", "code": "50486108" },
      { "name": "Savoy spinach purees", "code": "50486109" },
      { "name": "Sigmaleaf spinach purees", "code": "50486110" },
      { "name": "Space spinach purees", "code": "50486111" },
      { "name": "Trinidad spinach purees", "code": "50486112" },
      { "name": "Wild spinach purees", "code": "50486113" },
      { "name": "New zealand spinach purees", "code": "50486114" },
      { "name": "Iceplant spinach purees", "code": "50486115" },
      { "name": "Summer squash and summer pumpkin purees", "code": "50486200" },
      { "name": "Boston marrow squash purees", "code": "50486201" },
      { "name": "Butternut squash purees", "code": "50486202" },
      { "name": "Costata romanesca squash purees", "code": "50486203" },
      { "name": "Crookneck squash purees", "code": "50486204" },
      { "name": "Cucuzza squash purees", "code": "50486205" },
      { "name": "Delicata squash purees", "code": "50486206" },
      { "name": "Delicious squash purees", "code": "50486207" },
      {
        "name": "Early golden summer crookneck squash purees",
        "code": "50486208"
      },
      {
        "name": "Early prolific straight neck squash purees",
        "code": "50486209"
      },
      { "name": "Gold squash purees", "code": "50486210" },
      { "name": "Jack be little squash purees", "code": "50486211" },
      { "name": "Kentucky field squash purees", "code": "50486212" },
      { "name": "Marrow squash purees", "code": "50486213" },
      { "name": "Middle eastern squash purees", "code": "50486214" },
      { "name": "Miniature squash purees", "code": "50486215" },
      { "name": "Orangetti squash purees", "code": "50486216" },
      { "name": "Pattypan squash purees", "code": "50486217" },
      { "name": "Rondini squash purees", "code": "50486218" },
      { "name": "Round squash purees", "code": "50486219" },
      { "name": "Spaghetti squash purees", "code": "50486220" },
      { "name": "Stripetti squash purees", "code": "50486221" },
      { "name": "Sugar loaf squash purees", "code": "50486222" },
      { "name": "Sweet dumpling squash purees", "code": "50486223" },
      { "name": "Triple treat squash purees", "code": "50486224" },
      { "name": "Waltham butternut squash purees", "code": "50486225" },
      { "name": "Yellow bush scallop squash purees", "code": "50486226" },
      { "name": "Yellow straightneck squash purees", "code": "50486227" },
      { "name": "Zephyr squash purees", "code": "50486228" },
      { "name": "Zucchini squash purees", "code": "50486229" },
      { "name": "Sweet potato purees", "code": "50486300" },
      { "name": "Beauregard sweet potato purees", "code": "50486301" },
      { "name": "Centennial sweet potato purees", "code": "50486302" },
      { "name": "Diane sweet potato purees", "code": "50486303" },
      { "name": "Garnet sweet potato purees", "code": "50486304" },
      { "name": "Georgia red sweet potato purees", "code": "50486305" },
      { "name": "Goldensweet sweet potato purees", "code": "50486306" },
      { "name": "Hanna sweet potato purees", "code": "50486307" },
      { "name": "Japanese sweet potato purees", "code": "50486308" },
      { "name": "Jersey sweet potato purees", "code": "50486309" },
      { "name": "Jewel sweet potato purees", "code": "50486310" },
      { "name": "Maryland red sweet potato purees", "code": "50486311" },
      { "name": "Nemagold sweet potato purees", "code": "50486312" },
      { "name": "O'henry sweet potato purees", "code": "50486313" },
      { "name": "Okinawan sweet potato purees", "code": "50486314" },
      { "name": "Orange sweet potato purees", "code": "50486315" },
      { "name": "Oriental sweet potato purees", "code": "50486316" },
      { "name": "Red jersey sweet potato purees", "code": "50486317" },
      { "name": "Red mar sweet potato purees", "code": "50486318" },
      { "name": "Redglow sweet potato purees", "code": "50486319" },
      { "name": "Yellow jersey sweet potato purees", "code": "50486320" },
      { "name": "Purple sweet potato purees", "code": "50486321" },
      { "name": "Tomato purees", "code": "50486400" },
      { "name": "Ailsa craig tomato purees", "code": "50486401" },
      { "name": "Alicante tomato purees", "code": "50486402" },
      { "name": "Black plum tomato purees", "code": "50486403" },
      { "name": "Brandywine tomato purees", "code": "50486404" },
      { "name": "Cherry belle tomato purees", "code": "50486405" },
      { "name": "Cherry tomato purees", "code": "50486406" },
      { "name": "Delicious tomato purees", "code": "50486407" },
      { "name": "Dombito tomato purees", "code": "50486408" },
      { "name": "Gardener's delight tomato purees", "code": "50486409" },
      { "name": "Grape tomato purees", "code": "50486410" },
      { "name": "Green tomato purees", "code": "50486411" },
      { "name": "Marmande super tomato purees", "code": "50486412" },
      {
        "name": "Marvel striped traditional tomato purees",
        "code": "50486413"
      },
      { "name": "Minibel tomato purees", "code": "50486414" },
      { "name": "Oaxacan pink tomato purees", "code": "50486415" },
      { "name": "Red alert tomato purees", "code": "50486416" },
      { "name": "Roma vf tomato purees", "code": "50486417" },
      { "name": "San marzano tomato purees", "code": "50486418" },
      { "name": "Shirley tomato purees", "code": "50486419" },
      { "name": "Siberia tomato tomato purees", "code": "50486420" },
      { "name": "Super beefsteak tomato purees", "code": "50486421" },
      { "name": "Tigerella tomato purees", "code": "50486422" },
      { "name": "Tiny tim tomato purees", "code": "50486423" },
      { "name": "Tumbler tomato purees", "code": "50486424" },
      { "name": "Yellow cocktail tomato purees", "code": "50486425" },
      { "name": "Yellow pear-shaped tomato purees", "code": "50486426" },
      { "name": "Yellow perfection tomato purees", "code": "50486427" },
      { "name": "Turnip green purees", "code": "50486500" },
      { "name": "Green globe turnip purees", "code": "50486501" },
      { "name": "Golden ball turnip purees", "code": "50486502" },
      { "name": "Manchester market turnip purees", "code": "50486503" },
      { "name": "Purple top milan turnip purees", "code": "50486504" },
      { "name": "Purple top white turnip purees", "code": "50486505" },
      { "name": "Snowball turnip purees", "code": "50486506" },
      { "name": "Tokyo turnip purees", "code": "50486507" },
      { "name": "Tokyo cross turnip purees", "code": "50486508" },
      { "name": "Winter squash and winter pumpkin purees", "code": "50486600" },
      { "name": "Acorn squash purees", "code": "50486601" },
      { "name": "Atlantic giant squash purees", "code": "50486602" },
      { "name": "Banana pink squash purees", "code": "50486603" },
      { "name": "Big max squash purees", "code": "50486604" },
      { "name": "Calabaza squash purees", "code": "50486605" },
      { "name": "Carnival squash purees", "code": "50486606" },
      { "name": "Cheese pumpkin purees", "code": "50486607" },
      { "name": "Crown prince squash purees", "code": "50486608" },
      { "name": "Curcibita squash purees", "code": "50486609" },
      { "name": "Cushaw squash purees", "code": "50486610" },
      { "name": "Giant pumpkin squash purees", "code": "50486611" },
      { "name": "Hubbard squash purees", "code": "50486612" },
      { "name": "Jarrahdale squash purees", "code": "50486613" },
      { "name": "Kabocha squash purees", "code": "50486614" },
      { "name": "Queensland blue squash purees", "code": "50486615" },
      { "name": "Rouge vif d'etampes squash purees", "code": "50486616" },
      { "name": "Turks turban squash purees", "code": "50486617" },
      { "name": "Valenciano squash purees", "code": "50486618" },
      { "name": "Warted hubbard squash purees", "code": "50486619" },
      { "name": "Whangaparoa crown pumpkin purees", "code": "50486620" },
      { "name": "Chinese pumpkin purees", "code": "50486621" },
      { "name": "Loche pumpkin purees", "code": "50486622" },
      { "name": "Macre pumpkin purees", "code": "50486623" },
      { "name": "Yam purees", "code": "50486700" },
      { "name": "African bitter yam purees", "code": "50486701" },
      { "name": "Asiatic bitter yam purees", "code": "50486702" },
      { "name": "Chinese yam purees", "code": "50486703" },
      { "name": "Globe yam purees", "code": "50486704" },
      { "name": "Greater yam purees", "code": "50486705" },
      { "name": "Japanese yam purees", "code": "50486706" },
      { "name": "Lesser yam purees", "code": "50486707" },
      { "name": "Potato yam purees", "code": "50486708" },
      { "name": "White guinea yam purees", "code": "50486709" },
      { "name": "Yellow guinea yam purees", "code": "50486710" },
      { "name": "Nominant vegetable purees", "code": "50486800" },
      { "name": "Alfalfa purees", "code": "50486801" },
      { "name": "Aloe leaf purees", "code": "50486802" },
      { "name": "Apio purees", "code": "50486803" },
      { "name": "Arrow root purees", "code": "50486804" },
      { "name": "Arrowhead purees", "code": "50486805" },
      { "name": "Arugula purees", "code": "50486806" },
      { "name": "Arum purees", "code": "50486807" },
      { "name": "Bamboo shoots purees", "code": "50486808" },
      { "name": "Banana leaf purees", "code": "50486809" },
      { "name": "Batatas purees", "code": "50486810" },
      { "name": "Bean sprouts purees", "code": "50486811" },
      { "name": "Beet tops purees", "code": "50486812" },
      { "name": "Bittermelon purees", "code": "50486813" },
      { "name": "Caperberries purees", "code": "50486814" },
      { "name": "Carob purees", "code": "50486815" },
      { "name": "Cha-om purees", "code": "50486816" },
      { "name": "Chaoyotes purees", "code": "50486817" },
      { "name": "Chickpeas purees", "code": "50486818" },
      { "name": "Chrysanthemum greens purees", "code": "50486819" },
      { "name": "Dandelion greens purees", "code": "50486820" },
      { "name": "Dandelions purees", "code": "50486821" },
      { "name": "Dasheen purees", "code": "50486822" },
      { "name": "Dau mue or pea tips purees", "code": "50486823" },
      { "name": "Diakon purees", "code": "50486824" },
      { "name": "Donqua purees", "code": "50486825" },
      { "name": "Fiddlehead ferns purees", "code": "50486826" },
      { "name": "Gai choy purees", "code": "50486827" },
      { "name": "Gailon purees", "code": "50486828" },
      { "name": "Galanga purees", "code": "50486829" },
      { "name": "Ginger root purees", "code": "50486830" },
      { "name": "Gobo purees", "code": "50486831" },
      { "name": "Hop sprouts purees", "code": "50486832" },
      { "name": "Horseradish purees", "code": "50486833" },
      { "name": "Jicama purees", "code": "50486834" },
      { "name": "Kudzu purees", "code": "50486835" },
      { "name": "Lily bulb purees", "code": "50486836" },
      { "name": "Linkok purees", "code": "50486837" },
      { "name": "Lo bok purees", "code": "50486838" },
      { "name": "Long beans purees", "code": "50486839" },
      { "name": "Lotus root purees", "code": "50486840" },
      { "name": "Maguey leaf purees", "code": "50486841" },
      { "name": "Mallows purees", "code": "50486842" },
      { "name": "Mamey sapote purees", "code": "50486843" },
      { "name": "Moap purees", "code": "50486844" },
      { "name": "Moo purees", "code": "50486845" },
      { "name": "Moqua purees", "code": "50486846" },
      { "name": "Opos purees", "code": "50486847" },
      { "name": "Palm hearts purees", "code": "50486848" },
      { "name": "Paprika purees", "code": "50486849" },
      { "name": "Purslane purees", "code": "50486850" },
      { "name": "Raddichios purees", "code": "50486851" },
      { "name": "Sinquas purees", "code": "50486852" },
      { "name": "Soybeans purees", "code": "50486853" },
      { "name": "Spoonwart purees", "code": "50486854" },
      { "name": "Tassle grape-hyacinth purees", "code": "50486855" },
      { "name": "Taro purees", "code": "50486856" },
      { "name": "Taro leaf purees", "code": "50486857" },
      { "name": "Taro shoot purees", "code": "50486858" },
      { "name": "Tepeguaje purees", "code": "50486859" },
      { "name": "Tendergreen purees", "code": "50486860" },
      { "name": "Tindora purees", "code": "50486861" },
      { "name": "Tree onion purees", "code": "50486862" },
      { "name": "Udo purees", "code": "50486863" },
      { "name": "Water chestnuts purees", "code": "50486864" },
      { "name": "Water spinach purees", "code": "50486865" },
      { "name": "Yampi purees", "code": "50486866" },
      { "name": "Yautia purees", "code": "50486867" },
      { "name": "Yu choy purees", "code": "50486868" },
      { "name": "Yuca purees", "code": "50486869" },
      { "name": "Caigua purees", "code": "50486870" },
      { "name": "Sicua purees", "code": "50486871" },
      { "name": "Qawinca purees", "code": "50486872" },
      { "name": "Cayot purees", "code": "50486873" },
      { "name": "Sugar pea purees", "code": "50486900" },
      { "name": "Bikini pea purees", "code": "50486901" },
      { "name": "Cavalier pea purees", "code": "50486902" },
      { "name": "Daisy pea purees", "code": "50486903" },
      { "name": "Darfon pea purees", "code": "50486904" },
      { "name": "Early onward pea purees", "code": "50486905" },
      { "name": "Feltham first pea purees", "code": "50486906" },
      { "name": "Hurst green shaft pea purees", "code": "50486907" },
      { "name": "Oregon sugar pod pea purees", "code": "50486908" },
      { "name": "Prince albert pea purees", "code": "50486909" },
      { "name": "Reuzensuiker pea purees", "code": "50486910" },
      { "name": "Tuber purees", "code": "50487000" },
      { "name": "Arracacha purees", "code": "50487001" },
      { "name": "Maca purees", "code": "50487002" },
      { "name": "Oca purees", "code": "50487003" },
      { "name": "Olluco purees", "code": "50487004" },
      { "name": "Mashua purees", "code": "50487005" },
      { "name": "Chinese broccoli purees", "code": "50487006" },
      { "name": "Citadel brussel sprout purees", "code": "50487007" },
      { "name": "Falstaff brussel sprout purees", "code": "50487008" },
      { "name": "Organic fresh vegetable purees", "code": "50490000" },
      { "name": "Organic artichoke purees", "code": "50491500" },
      { "name": "Organic brittany artichoke purees", "code": "50491501" },
      { "name": "Organic catanese artichoke purees", "code": "50491502" },
      { "name": "Organic french artichoke purees", "code": "50491503" },
      { "name": "Organic green globe artichoke purees", "code": "50491504" },
      {
        "name": "Organic gros camus de bretagne artichoke purees",
        "code": "50491505"
      },
      { "name": "Organic midi artichoke purees", "code": "50491506" },
      { "name": "Organic purple globe artichoke purees", "code": "50491507" },
      {
        "name": "Organic purple sicilian artichoke purees",
        "code": "50491508"
      },
      { "name": "Organic romanesco artichoke purees", "code": "50491509" },
      { "name": "Organic spinoso sardo artichoke purees", "code": "50491510" },
      { "name": "Organic vert de laon artichoke purees", "code": "50491511" },
      {
        "name": "Organic violetta di chioggia artichoke purees",
        "code": "50491512"
      },
      {
        "name": "Organic violetto di toscana artichoke purees",
        "code": "50491513"
      },
      { "name": "Organic asparagus purees", "code": "50491600" },
      {
        "name": "Organic connover's colossal asparagus purees",
        "code": "50491601"
      },
      { "name": "Organic franklin asparagus purees", "code": "50491602" },
      { "name": "Organic giant mammoth asparagus purees", "code": "50491603" },
      { "name": "Organic lucullus asparagus purees", "code": "50491604" },
      {
        "name": "Organic martha washington asparagus purees",
        "code": "50491605"
      },
      { "name": "Organic avocado purees", "code": "50491700" },
      { "name": "Organic ajax b-7 avocado purees", "code": "50491701" },
      { "name": "Organic arue avocado purees", "code": "50491702" },
      { "name": "Organic bacon avocado purees", "code": "50491703" },
      { "name": "Organic benik avocado purees", "code": "50491704" },
      { "name": "Organic bernecker avocado purees", "code": "50491705" },
      { "name": "Organic beta avocado purees", "code": "50491706" },
      { "name": "Organic biondo avocado purees", "code": "50491707" },
      { "name": "Organic black prince avocado purees", "code": "50491708" },
      { "name": "Organic blair avocado purees", "code": "50491709" },
      { "name": "Organic blair booth avocado purees", "code": "50491710" },
      { "name": "Organic booth 1 avocado purees", "code": "50491711" },
      { "name": "Organic booth 3 avocado purees", "code": "50491712" },
      { "name": "Organic booth 5 avocado purees", "code": "50491713" },
      { "name": "Organic booth 7 avocado purees", "code": "50491714" },
      { "name": "Organic booth 8 avocado purees", "code": "50491715" },
      { "name": "Organic brooks 1978 avocado purees", "code": "50491716" },
      { "name": "Organic brookslate avocado purees", "code": "50491717" },
      { "name": "Organic california haas avocado purees", "code": "50491718" },
      { "name": "Organic catalina avocado purees", "code": "50491719" },
      { "name": "Organic chica avocado purees", "code": "50491720" },
      { "name": "Organic choquette avocado purees", "code": "50491721" },
      { "name": "Organic christina avocado purees", "code": "50491722" },
      { "name": "Organic collinson avocado purees", "code": "50491723" },
      { "name": "Organic donnie avocado purees", "code": "50491724" },
      {
        "name": "Organic dr dupuis number 2 avocado purees",
        "code": "50491725"
      },
      { "name": "Organic dr dupuis avocado purees", "code": "50491726" },
      { "name": "Organic ettinger avocado purees", "code": "50491727" },
      { "name": "Organic fuchs avocado purees", "code": "50491728" },
      { "name": "Organic fuchs gwen avocado purees", "code": "50491729" },
      { "name": "Organic fuerte avocado purees", "code": "50491730" },
      { "name": "Organic gorham avocado purees", "code": "50491731" },
      { "name": "Organic gossman avocado purees", "code": "50491732" },
      {
        "name": "Organic guatemalan seedling avocado purees",
        "code": "50491733"
      },
      { "name": "Organic hall avocado purees", "code": "50491734" },
      { "name": "Organic hardee avocado purees", "code": "50491735" },
      { "name": "Organic haas avocado purees", "code": "50491736" },
      { "name": "Organic herman avocado purees", "code": "50491737" },
      { "name": "Organic hickson avocado purees", "code": "50491738" },
      { "name": "Organic k-5 avocado purees", "code": "50491739" },
      { "name": "Organic k-9 avocado purees", "code": "50491740" },
      { "name": "Organic lamb haas avocado purees", "code": "50491741" },
      { "name": "Organic leona avocado purees", "code": "50491742" },
      { "name": "Organic leona linda avocado purees", "code": "50491743" },
      { "name": "Organic lisa p avocado purees", "code": "50491744" },
      { "name": "Organic lisa loretta avocado purees", "code": "50491745" },
      { "name": "Organic loretta avocado purees", "code": "50491746" },
      { "name": "Organic lula avocado purees", "code": "50491747" },
      { "name": "Organic lula macarthur avocado purees", "code": "50491748" },
      { "name": "Organic marcus avocado purees", "code": "50491749" },
      { "name": "Organic melendez avocado purees", "code": "50491750" },
      { "name": "Organic meya p avocado purees", "code": "50491751" },
      { "name": "Organic miguel p avocado purees", "code": "50491752" },
      { "name": "Organic monroe avocado purees", "code": "50491753" },
      { "name": "Organic murrieta green avocado purees", "code": "50491754" },
      { "name": "Organic nabal avocado purees", "code": "50491755" },
      { "name": "Organic nadir avocado purees", "code": "50491756" },
      { "name": "Organic nesbitt avocado purees", "code": "50491757" },
      { "name": "Organic peterson avocado purees", "code": "50491758" },
      { "name": "Organic pinelli avocado purees", "code": "50491759" },
      { "name": "Organic pinkerton avocado purees", "code": "50491760" },
      { "name": "Organic pollock avocado purees", "code": "50491761" },
      { "name": "Organic puebla avocado purees", "code": "50491762" },
      { "name": "Organic reed avocado purees", "code": "50491763" },
      { "name": "Organic rue avocado purees", "code": "50491764" },
      { "name": "Organic ruehle avocado purees", "code": "50491765" },
      { "name": "Organic ryan avocado purees", "code": "50491766" },
      { "name": "Organic semil 34 avocado purees", "code": "50491767" },
      { "name": "Organic semil 43 avocado purees", "code": "50491768" },
      { "name": "Organic simmonds avocado purees", "code": "50491769" },
      { "name": "Organic simpson avocado purees", "code": "50491770" },
      { "name": "Organic taylor avocado purees", "code": "50491771" },
      { "name": "Organic tonnage avocado purees", "code": "50491772" },
      { "name": "Organic tower avocado purees", "code": "50491773" },
      { "name": "Organic tower li avocado purees", "code": "50491774" },
      { "name": "Organic trapp avocado purees", "code": "50491775" },
      {
        "name": "Organic west indian seedling avocado purees",
        "code": "50491776"
      },
      { "name": "Organic wagner avocado purees", "code": "50491777" },
      { "name": "Organic waldin avocado purees", "code": "50491778" },
      { "name": "Organic wurtz avocado purees", "code": "50491779" },
      { "name": "Organic zio p avocado purees", "code": "50491780" },
      { "name": "Organic ziu avocado purees", "code": "50491781" },
      { "name": "Organic zutano avocado purees", "code": "50491782" },
      { "name": "Organic bean purees", "code": "50491800" },
      { "name": "Organic anasazi or aztec bean purees", "code": "50491801" },
      { "name": "Organic appaloosa bean purees", "code": "50491802" },
      { "name": "Organic azuki bean purees", "code": "50491803" },
      { "name": "Organic barlotti bean purees", "code": "50491804" },
      { "name": "Organic black appaloosa bean purees", "code": "50491805" },
      { "name": "Organic black bean purees", "code": "50491806" },
      { "name": "Organic black gram bean purees", "code": "50491807" },
      { "name": "Organic black shackamaxon bean purees", "code": "50491808" },
      { "name": "Organic blackeyed bean purees", "code": "50491809" },
      { "name": "Organic bobby bean purees", "code": "50491810" },
      { "name": "Organic bolita bean purees", "code": "50491811" },
      { "name": "Organic brown lazy wife bean purees", "code": "50491812" },
      { "name": "Organic calypso bean purees", "code": "50491813" },
      { "name": "Organic cannellini bean purees", "code": "50491814" },
      { "name": "Organic castor bean purees", "code": "50491815" },
      { "name": "Organic china yellow bean purees", "code": "50491816" },
      { "name": "Organic dragon tongue bean purees", "code": "50491817" },
      { "name": "Organic european soldier bean purees", "code": "50491818" },
      { "name": "Organic fava or broad bean purees", "code": "50491819" },
      { "name": "Organic flageolet bean purees", "code": "50491820" },
      {
        "name": "Organic french horticultural bean purees",
        "code": "50491821"
      },
      { "name": "Organic french navy bean purees", "code": "50491822" },
      { "name": "Organic giant white coco bean purees", "code": "50491823" },
      { "name": "Organic green bean purees", "code": "50491824" },
      { "name": "Organic green romano bean purees", "code": "50491825" },
      { "name": "Organic guar gum bean purees", "code": "50491826" },
      { "name": "Organic haricot bean purees", "code": "50491827" },
      { "name": "Organic hyacinth bean purees", "code": "50491828" },
      { "name": "Organic italian type bean purees", "code": "50491829" },
      { "name": "Organic jackson wonder bean purees", "code": "50491830" },
      { "name": "Organic jacob's cattle bean purees", "code": "50491831" },
      { "name": "Organic kentucky wonder bean purees", "code": "50491832" },
      { "name": "Organic kidney bean purees", "code": "50491833" },
      { "name": "Organic lima bean purees", "code": "50491834" },
      { "name": "Organic madeira madera bean purees", "code": "50491835" },
      { "name": "Organic marrow bean purees", "code": "50491836" },
      { "name": "Organic mat bean purees", "code": "50491837" },
      {
        "name": "Organic monstoller wild goose bean purees",
        "code": "50491838"
      },
      { "name": "Organic mortgage lifter bean purees", "code": "50491839" },
      { "name": "Organic moth bean purees", "code": "50491840" },
      { "name": "Organic mung bean purees", "code": "50491841" },
      { "name": "Organic munsi wolf bea purees", "code": "50491842" },
      { "name": "Organic nuna bean purees", "code": "50491843" },
      { "name": "Organic pinto bean purees", "code": "50491844" },
      { "name": "Organic pole bean purees", "code": "50491845" },
      { "name": "Organic runner bean purees", "code": "50491846" },
      { "name": "Organic string bean purees", "code": "50491847" },
      { "name": "Organic tamarind bean purees", "code": "50491848" },
      { "name": "Organic tonka bean purees", "code": "50491849" },
      { "name": "Organic wax bean purees", "code": "50491850" },
      { "name": "Organic winged bean purees", "code": "50491851" },
      { "name": "Organic yard long bean purees", "code": "50491852" },
      { "name": "Organic beet purees", "code": "50491900" },
      { "name": "Organic action beet purees", "code": "50491901" },
      { "name": "Organic albina vereduna beet purees", "code": "50491902" },
      {
        "name": "Organic barbabietola di chioggia beet purees",
        "code": "50491903"
      },
      { "name": "Organic boltardy beet purees", "code": "50491904" },
      { "name": "Organic bonel beet purees", "code": "50491905" },
      { "name": "Organic burpees golden beet purees", "code": "50491906" },
      {
        "name": "Organic cheltenham green top beet purees",
        "code": "50491907"
      },
      { "name": "Organic cheltenham mono beet purees", "code": "50491908" },
      { "name": "Organic chioggia beet purees", "code": "50491909" },
      { "name": "Organic cylindra beet purees", "code": "50491910" },
      { "name": "Organic d'egypte beet purees", "code": "50491911" },
      { "name": "Organic detroit 2 dark red beet purees", "code": "50491912" },
      {
        "name": "Organic detroit 2 little ball beet purees",
        "code": "50491913"
      },
      { "name": "Organic egyptian flat beet purees", "code": "50491914" },
      {
        "name": "Organic egyptian turnip rooted beet purees",
        "code": "50491915"
      },
      { "name": "Organic formanova beet purees", "code": "50491916" },
      { "name": "Organic forono beet purees", "code": "50491917" },
      { "name": "Organic monaco beet purees", "code": "50491918" },
      { "name": "Organic monogram beet purees", "code": "50491919" },
      { "name": "Organic pronto beet purees", "code": "50491920" },
      { "name": "Organic regalia beet purees", "code": "50491921" },
      { "name": "Organic sugar beet purees", "code": "50491922" },
      { "name": "Organic broccoli purees", "code": "50492000" },
      { "name": "Organic broccolini purees", "code": "50492001" },
      { "name": "Organic broccoli romanesco purees", "code": "50492002" },
      { "name": "Organic broccoli raab purees", "code": "50492003" },
      { "name": "Organic brussel sprout purees", "code": "50492100" },
      { "name": "Organic oliver brussel sprout purees", "code": "50492101" },
      { "name": "Organic peer gynt brussel sprout purees", "code": "50492102" },
      { "name": "Organic rampart brussel sprout purees", "code": "50492103" },
      { "name": "Organic rubine brussel sprout purees", "code": "50492104" },
      { "name": "Organic widgeon brussel sprout purees", "code": "50492105" },
      { "name": "Organic bunching onion purees", "code": "50492200" },
      {
        "name": "Organic beltsville bunching onion purees",
        "code": "50492201"
      },
      { "name": "Organic feast bunching onion purees", "code": "50492202" },
      { "name": "Organic ishikura bunching onion purees", "code": "50492203" },
      {
        "name": "Organic kyoto market bunching onion purees",
        "code": "50492204"
      },
      { "name": "Organic red beard bunching onion purees", "code": "50492205" },
      { "name": "Organic redmate bunching onion purees", "code": "50492206" },
      {
        "name": "Organic santa claus bunching onion purees",
        "code": "50492207"
      },
      { "name": "Organic tokyo bunching onion purees", "code": "50492208" },
      {
        "name": "Organic white lisbon bunching onion purees",
        "code": "50492209"
      },
      {
        "name": "Organic winter white bunching onion purees",
        "code": "50492210"
      },
      {
        "name": "Organic winter-over bunching onion purees",
        "code": "50492211"
      },
      { "name": "Organic cabbage purees", "code": "50492300" },
      { "name": "Organic black cabbage purees", "code": "50492301" },
      { "name": "Organic savoy cabbage purees", "code": "50492302" },
      { "name": "Organic skunk cabbage purees", "code": "50492303" },
      { "name": "Organic white cabbage purees", "code": "50492304" },
      { "name": "Organic cardoon purees", "code": "50492400" },
      { "name": "Organic lunghi cardoon purees", "code": "50492401" },
      { "name": "Organic gobbi cardoon purees", "code": "50492402" },
      { "name": "Organic carrot purees", "code": "50492500" },
      { "name": "Organic amsterdam carrot purees", "code": "50492501" },
      { "name": "Organic autumn king carrot purees", "code": "50492502" },
      { "name": "Organic berlicum carrot purees", "code": "50492503" },
      { "name": "Organic chantenay carrot purees", "code": "50492504" },
      { "name": "Organic nantes carrot purees", "code": "50492505" },
      { "name": "Organic paris market carrot purees", "code": "50492506" },
      { "name": "Organic cauliflower purees", "code": "50492600" },
      {
        "name": "Organic all the year round cauliflower purees",
        "code": "50492601"
      },
      { "name": "Organic alverda cauliflower purees", "code": "50492602" },
      {
        "name": "Organic autumn giant 3 cauliflower purees",
        "code": "50492603"
      },
      { "name": "Organic dok elgon cauliflower purees", "code": "50492604" },
      {
        "name": "Organic early snowball cauliflower purees",
        "code": "50492605"
      },
      { "name": "Organic limelight cauliflower purees", "code": "50492606" },
      { "name": "Organic minaret cauliflower purees", "code": "50492607" },
      {
        "name": "Organic orange bouquet cauliflower purees",
        "code": "50492608"
      },
      { "name": "Organic purple cape cauliflower purees", "code": "50492609" },
      { "name": "Organic snowball cauliflower purees", "code": "50492610" },
      {
        "name": "Organic walcheren winter 3 cauliflower purees",
        "code": "50492611"
      },
      { "name": "Organic white rock cauliflower purees", "code": "50492612" },
      { "name": "Organic celery purees", "code": "50492700" },
      { "name": "Organic celebrity celery purees", "code": "50492701" },
      { "name": "Organic celeriac purees", "code": "50492702" },
      { "name": "Organic chinese celery purees", "code": "50492703" },
      { "name": "Organic french dinant celery purees", "code": "50492704" },
      { "name": "Organic giant pink celery purees", "code": "50492705" },
      { "name": "Organic giant red celery purees", "code": "50492706" },
      { "name": "Organic giant white celery purees", "code": "50492707" },
      {
        "name": "Organic golden self-blanching celery purees",
        "code": "50492708"
      },
      { "name": "Organic greensleeves celery purees", "code": "50492709" },
      { "name": "Organic hopkins fenlander celery purees", "code": "50492710" },
      { "name": "Organic ivory tower celery purees", "code": "50492711" },
      {
        "name": "Organic lathom self-blanching celery purees",
        "code": "50492712"
      },
      { "name": "Organic soup celery d'amsterdam purees", "code": "50492713" },
      { "name": "Organic standard bearer celery purees", "code": "50492714" },
      { "name": "Organic tall utah triumph celery purees", "code": "50492715" },
      { "name": "Organic chard purees", "code": "50492800" },
      { "name": "Organic fordhook giant chard purees", "code": "50492801" },
      { "name": "Organic lucullus chard purees", "code": "50492802" },
      { "name": "Organic perpetual spinach chard purees", "code": "50492803" },
      { "name": "Organic rhubarb chard purees", "code": "50492804" },
      { "name": "Organic swiss chard purees", "code": "50492805" },
      { "name": "Organic vulcan chard purees", "code": "50492806" },
      { "name": "Organic white king chard purees", "code": "50492807" },
      { "name": "Organic chicory purees", "code": "50492900" },
      {
        "name": "Organic broad leaved batavian chicory purees",
        "code": "50492901"
      },
      {
        "name": "Organic en cornet de bordeaux chicory purees",
        "code": "50492902"
      },
      {
        "name": "Organic green curled ruffee chicory purees",
        "code": "50492903"
      },
      { "name": "Organic green curled chicory purees", "code": "50492904" },
      { "name": "Organic ione limnos chicory purees", "code": "50492905" },
      {
        "name": "Organic riccia pancalieri chicory purees",
        "code": "50492906"
      },
      { "name": "Organic salad king chicory purees", "code": "50492907" },
      { "name": "Organic sanda chicory purees", "code": "50492908" },
      { "name": "Organic scarola verde chicory purees", "code": "50492909" },
      {
        "name": "Organic tres fine maraichere chicory purees",
        "code": "50492910"
      },
      {
        "name": "Organic wallone freisee weschelkopf chicory purees",
        "code": "50492911"
      },
      { "name": "Organic chinese cabbage purees", "code": "50493000" },
      { "name": "Organic bok choy purees", "code": "50493001" },
      {
        "name": "Organic chinese flat-headed cabbage purees",
        "code": "50493002"
      },
      {
        "name": "Organic chinese flowering cabbage purees",
        "code": "50493003"
      },
      { "name": "Organic choy sum purees", "code": "50493004" },
      { "name": "Organic dwarf bok choy purees", "code": "50493005" },
      { "name": "Organic fengshan bok choy purees", "code": "50493006" },
      { "name": "Organic jade pagoda bok choy purees", "code": "50493007" },
      { "name": "Organic kasumi bok choy purees", "code": "50493008" },
      { "name": "Organic nerva bok choy purees", "code": "50493009" },
      { "name": "Organic rosette bok choy purees", "code": "50493010" },
      { "name": "Organic ruffles bok choy purees", "code": "50493011" },
      { "name": "Organic santo serrated leaf purees", "code": "50493012" },
      { "name": "Organic shanghai d bok choy purees", "code": "50493013" },
      { "name": "Organic shantung purees", "code": "50493014" },
      { "name": "Organic tip top cabbage purees", "code": "50493015" },
      { "name": "Organic yau choy sum purees", "code": "50493016" },
      { "name": "Organic chive purees", "code": "50493100" },
      { "name": "Organic chinese chive purees", "code": "50493101" },
      { "name": "Organic common chive purees", "code": "50493102" },
      { "name": "Organic cress purees", "code": "50493200" },
      { "name": "Organic land cress purees", "code": "50493201" },
      { "name": "Organic Nasturtium purees", "code": "50493202" },
      { "name": "Organic watercress purees", "code": "50493203" },
      { "name": "Organic wintercress purees", "code": "50493204" },
      { "name": "Organic cucumber purees", "code": "50493300" },
      { "name": "Organic arena cucumber purees", "code": "50493301" },
      { "name": "Organic armenian cucumber purees", "code": "50493302" },
      { "name": "Organic athene cucumber purees", "code": "50493303" },
      {
        "name": "Organic bianco lungo di parigi cucumber purees",
        "code": "50493304"
      },
      {
        "name": "Organic burpless tasty green cucumber purees",
        "code": "50493305"
      },
      {
        "name": "Organic chicago pickling cucumber purees",
        "code": "50493306"
      },
      { "name": "Organic crystal apple cucumber purees", "code": "50493307" },
      { "name": "Organic crystal lemon cucumber purees", "code": "50493308" },
      { "name": "Organic danimas cucumber purees", "code": "50493309" },
      { "name": "Organic gherkin cucumber purees", "code": "50493310" },
      { "name": "Organic hokus cucumber purees", "code": "50493311" },
      { "name": "Organic japanese cucumber purees", "code": "50493312" },
      { "name": "Organic karela cucumber purees", "code": "50493313" },
      { "name": "Organic korila cucumber purees", "code": "50493314" },
      {
        "name": "Organic long green improved cucumber purees",
        "code": "50493315"
      },
      { "name": "Organic marketmore cucumber purees", "code": "50493316" },
      { "name": "Organic midget cucumber purees", "code": "50493317" },
      {
        "name": "Organic national pickling cucumber purees",
        "code": "50493318"
      },
      { "name": "Organic persian cucumber purees", "code": "50493319" },
      { "name": "Organic telegraph cucumber purees", "code": "50493320" },
      {
        "name": "Organic telegraph improved cucumber purees",
        "code": "50493321"
      },
      {
        "name": "Organic vert de massy cornichon cucumber purees",
        "code": "50493322"
      },
      { "name": "Organic yamato cucumber purees", "code": "50493323" },
      { "name": "Organic eggplant purees", "code": "50493400" },
      { "name": "Organic bambino eggplant purees", "code": "50493401" },
      { "name": "Organic black beauty eggplant purees", "code": "50493402" },
      { "name": "Organic black enorma eggplant purees", "code": "50493403" },
      { "name": "Organic chinese eggplant purees", "code": "50493404" },
      { "name": "Organic easter egg eggplant purees", "code": "50493405" },
      { "name": "Organic filipino eggplant purees", "code": "50493406" },
      { "name": "Organic florida market eggplant purees", "code": "50493407" },
      { "name": "Organic indian eggplant purees", "code": "50493408" },
      { "name": "Organic italian eggplant purees", "code": "50493409" },
      { "name": "Organic japanese eggplant purees", "code": "50493410" },
      { "name": "Organic long purple eggplant purees", "code": "50493411" },
      { "name": "Organic long striped eggplant purees", "code": "50493412" },
      { "name": "Organic moneymaker eggplant purees", "code": "50493413" },
      { "name": "Organic ova eggplant purees", "code": "50493414" },
      { "name": "Organic pea eggplant purees", "code": "50493415" },
      { "name": "Organic short tom eggplant purees", "code": "50493416" },
      { "name": "Organic sicilian eggplant purees", "code": "50493417" },
      { "name": "Organic thai eggplant purees", "code": "50493418" },
      {
        "name": "Organic violette di firenze eggplant purees",
        "code": "50493419"
      },
      { "name": "Organic white eggplant purees", "code": "50493420" },
      { "name": "Organic endive purees", "code": "50493500" },
      { "name": "Organic brussels witloof endive purees", "code": "50493501" },
      { "name": "Organic castelfranco endive purees", "code": "50493502" },
      {
        "name": "Organic catalogna di galatina endive purees",
        "code": "50493503"
      },
      { "name": "Organic chioggia endive purees", "code": "50493504" },
      { "name": "Organic grumolo verde endive purees", "code": "50493505" },
      {
        "name": "Organic large rooted magdeburg endive purees",
        "code": "50493506"
      },
      {
        "name": "Organic palla rossa zorzi precoce endive purees",
        "code": "50493507"
      },
      { "name": "Organic radice amare endive purees", "code": "50493508" },
      { "name": "Organic rossa di treviso endive purees", "code": "50493509" },
      { "name": "Organic rossa di verona endive purees", "code": "50493510" },
      { "name": "Organic soncino endive purees", "code": "50493511" },
      { "name": "Organic sugarhat endive purees", "code": "50493512" },
      { "name": "Organic verona endive purees", "code": "50493513" },
      { "name": "Organic witloof zoom endive purees", "code": "50493514" },
      { "name": "Organic fennel purees", "code": "50493600" },
      { "name": "Organic cantino fennel purees", "code": "50493601" },
      { "name": "Organic fino fennel purees", "code": "50493602" },
      { "name": "Organic herald fennel purees", "code": "50493603" },
      { "name": "Organic perfection fennel purees", "code": "50493604" },
      { "name": "Organic sirio fennel purees", "code": "50493605" },
      { "name": "Organic sweet florence fennel purees", "code": "50493606" },
      { "name": "Organic tardo fennel purees", "code": "50493607" },
      { "name": "Organic garlic purees", "code": "50493700" },
      { "name": "Organic california late garlic purees", "code": "50493701" },
      { "name": "Organic chinese garlic stem purees", "code": "50493702" },
      { "name": "Organic garlic chive purees", "code": "50493703" },
      { "name": "Organic germidor garlic purees", "code": "50493704" },
      { "name": "Organic long keeper garlic purees", "code": "50493705" },
      { "name": "Organic ramson garlic purees", "code": "50493706" },
      { "name": "Organic rocambole garlic purees", "code": "50493707" },
      { "name": "Organic rose de lautrec garlic purees", "code": "50493708" },
      { "name": "Organic solent wight garlic purees", "code": "50493709" },
      { "name": "Organic spanish morado garlic purees", "code": "50493710" },
      { "name": "Organic venetian italian garlic purees", "code": "50493711" },
      { "name": "Organic gourd purees", "code": "50493800" },
      { "name": "Organic angled loofah purees", "code": "50493801" },
      { "name": "Organic bitter gourd purees", "code": "50493802" },
      { "name": "Organic bottle gourd purees", "code": "50493803" },
      { "name": "Organic calabash gourd purees", "code": "50493804" },
      { "name": "Organic fuzzy hairy melon purees", "code": "50493805" },
      { "name": "Organic musky gourd purees", "code": "50493806" },
      { "name": "Organic smooth loofah purees", "code": "50493807" },
      { "name": "Organic snake gourd purees", "code": "50493808" },
      { "name": "Organic spiny bitter gourd purees", "code": "50493809" },
      { "name": "Organic tinda gourd purees", "code": "50493810" },
      { "name": "Organic tindoori gourd purees", "code": "50493811" },
      { "name": "Organic green pea purees", "code": "50493900" },
      { "name": "Organic china pea purees", "code": "50493901" },
      { "name": "Organic english pea purees", "code": "50493902" },
      { "name": "Organic garden pea purees", "code": "50493903" },
      { "name": "Organic snow pea purees", "code": "50493904" },
      { "name": "Organic sugar snap pea purees", "code": "50493905" },
      { "name": "Organic herb purees", "code": "50494000" },
      { "name": "Organic basil purees", "code": "50494001" },
      { "name": "Organic bay leaf purees", "code": "50494002" },
      { "name": "Organic broage purees", "code": "50494003" },
      { "name": "Organic caraway purees", "code": "50494004" },
      { "name": "Organic chervil purees", "code": "50494005" },
      { "name": "Organic cilantro purees", "code": "50494006" },
      { "name": "Organic cipolino purees", "code": "50494007" },
      { "name": "Organic curry leaf purees", "code": "50494008" },
      { "name": "Organic dill purees", "code": "50494009" },
      { "name": "Organic epazote purees", "code": "50494010" },
      { "name": "Organic fenugreek purees", "code": "50494011" },
      { "name": "Organic lemon gras purees", "code": "50494012" },
      { "name": "Organic marjoram purees", "code": "50494013" },
      { "name": "Organic mint purees", "code": "50494014" },
      { "name": "Organic oregano purees", "code": "50494015" },
      { "name": "Organic papalo purees", "code": "50494016" },
      { "name": "Organic pepicha purees", "code": "50494017" },
      { "name": "Organic perilla purees", "code": "50494018" },
      { "name": "Organic recao purees", "code": "50494019" },
      { "name": "Organic rosemary purees", "code": "50494020" },
      { "name": "Organic sage purees", "code": "50494021" },
      { "name": "Organic salsify purees", "code": "50494022" },
      { "name": "Organic savory purees", "code": "50494023" },
      { "name": "Organic tarragon purees", "code": "50494024" },
      { "name": "Organic thyme purees", "code": "50494025" },
      { "name": "Organic tumeric purees", "code": "50494026" },
      { "name": "Organic verdulaga purees", "code": "50494027" },
      { "name": "Organic kale purees", "code": "50494100" },
      { "name": "Organic curly kale purees", "code": "50494101" },
      { "name": "Organic collard green purees", "code": "50494102" },
      { "name": "Organic kohlrabi purees", "code": "50494200" },
      { "name": "Organic azur star kohlrabi purees", "code": "50494201" },
      { "name": "Organic green vienna kohlrabi purees", "code": "50494202" },
      { "name": "Organic lanro kohlrabi purees", "code": "50494203" },
      { "name": "Organic purple vienna kohlrabi purees", "code": "50494204" },
      { "name": "Organic rowel trero kohlrabi purees", "code": "50494205" },
      { "name": "Organic white vienna kohlrabi purees", "code": "50494206" },
      { "name": "Organic leek purees", "code": "50494300" },
      { "name": "Organic autumn giant-cobra leek purees", "code": "50494301" },
      { "name": "Organic autumn mammoth 2 leek purees", "code": "50494302" },
      { "name": "Organic bleu de solaise leek purees", "code": "50494303" },
      { "name": "Organic cortina leek purees", "code": "50494304" },
      { "name": "Organic prelina leek purees", "code": "50494305" },
      { "name": "Organic wild leek ramp purees", "code": "50494306" },
      { "name": "Organic lentil purees", "code": "50494400" },
      { "name": "Organic beluga lentil purees", "code": "50494401" },
      { "name": "Organic french green lentil purees", "code": "50494402" },
      { "name": "Organic green lentil purees", "code": "50494403" },
      { "name": "Organic petite crimson lentil purees", "code": "50494404" },
      { "name": "Organic spanish pardina lentil purees", "code": "50494405" },
      { "name": "Organic split red lentil purees", "code": "50494406" },
      { "name": "Organic split yellow lentil purees", "code": "50494407" },
      { "name": "Organic tarahumara pinks lentil purees", "code": "50494408" },
      { "name": "Organic lettuce purees", "code": "50494500" },
      { "name": "Organic bibb lettuce purees", "code": "50494501" },
      { "name": "Organic boston lettuce purees", "code": "50494502" },
      { "name": "Organic frisee lettuce purees", "code": "50494503" },
      { "name": "Organic lolla rossa lettuce purees", "code": "50494504" },
      { "name": "Organic mesculin mix lettuce purees", "code": "50494505" },
      { "name": "Organic mizuna lettuce purees", "code": "50494506" },
      { "name": "Organic red leaf lettuce purees", "code": "50494507" },
      { "name": "Organic red oak leaf lettuce purees", "code": "50494508" },
      { "name": "Organic ruby romaine lettuce purees", "code": "50494509" },
      { "name": "Organic baby red romaine lettuce purees", "code": "50494510" },
      { "name": "Organic butterhead lettuce purees", "code": "50494511" },
      { "name": "Organic chinese lettuce purees", "code": "50494512" },
      { "name": "Organic crisphead lettuce purees", "code": "50494513" },
      { "name": "Organic green leaf lettuce purees", "code": "50494514" },
      { "name": "Organic iceberg lettuce purees", "code": "50494515" },
      { "name": "Organic lamb\u2019s lettuce purees", "code": "50494516" },
      { "name": "Organic looseleaf lettuce purees", "code": "50494517" },
      { "name": "Organic mache lettuce purees", "code": "50494518" },
      { "name": "Organic red boston lettuce purees", "code": "50494519" },
      { "name": "Organic red headed lettuce purees", "code": "50494520" },
      { "name": "Organic romaine lettuce purees", "code": "50494521" },
      {
        "name": "Organic russian red mustard lettuce purees",
        "code": "50494522"
      },
      { "name": "Organic tatsoi lettuce purees", "code": "50494523" },
      { "name": "Organic malanga purees", "code": "50494600" },
      { "name": "Organic blanca malanga purees", "code": "50494601" },
      { "name": "Organic coco malanga purees", "code": "50494602" },
      { "name": "Organic eddoes malanga purees", "code": "50494603" },
      { "name": "Organic islena malanga purees", "code": "50494604" },
      { "name": "Organic lila malanga purees", "code": "50494605" },
      { "name": "Organic amarilla malanga purees", "code": "50494606" },
      { "name": "Organic mushroom purees", "code": "50494700" },
      { "name": "Organic black trumpet mushroom purees", "code": "50494701" },
      { "name": "Organic brown mushroom purees", "code": "50494702" },
      { "name": "Organic champinion mushroom purees", "code": "50494703" },
      { "name": "Organic chanterelle mushroom purees", "code": "50494704" },
      { "name": "Organic cremini mushroom purees", "code": "50494705" },
      { "name": "Organic enoki mushroom purees", "code": "50494706" },
      { "name": "Organic hedge hog mushroom purees", "code": "50494707" },
      {
        "name": "Organic hen of the woods mushroom purees",
        "code": "50494708"
      },
      { "name": "Organic lobster mushroom purees", "code": "50494709" },
      { "name": "Organic morels mushroom purees", "code": "50494710" },
      { "name": "Organic oyster mushroom purees", "code": "50494711" },
      { "name": "Organic pleurotus mushroom purees", "code": "50494712" },
      { "name": "Organic pompom mushroom purees", "code": "50494713" },
      { "name": "Organic porcieni mushroom purees", "code": "50494714" },
      { "name": "Organic portobella mushroom purees", "code": "50494715" },
      { "name": "Organic shiitake mushroom purees", "code": "50494716" },
      { "name": "Organic shimeji mushroom purees", "code": "50494717" },
      { "name": "Organic st george's mushroom purees", "code": "50494718" },
      { "name": "Organic white mushroom purees", "code": "50494719" },
      { "name": "Organic white trumpet mushroom purees", "code": "50494720" },
      { "name": "Organic woodear mushroom purees", "code": "50494721" },
      { "name": "Organic mustard purees", "code": "50494800" },
      { "name": "Organic bamboo mustard purees", "code": "50494801" },
      { "name": "Organic garlic mustard purees", "code": "50494802" },
      { "name": "Organic giantleafed mustard purees", "code": "50494803" },
      { "name": "Organic red in snow mustard purees", "code": "50494804" },
      { "name": "Organic southern mustard purees", "code": "50494805" },
      { "name": "Organic wrapped heart mustard purees", "code": "50494806" },
      { "name": "Organic nightshade purees", "code": "50494900" },
      { "name": "Organic chinese lantern purees", "code": "50494901" },
      { "name": "Organic garden huckleberry purees", "code": "50494902" },
      { "name": "Organic naranjilla purees", "code": "50494903" },
      { "name": "Organic tomatillo purees", "code": "50494904" },
      { "name": "Organic okra purees", "code": "50495000" },
      { "name": "Organic artist okra purees", "code": "50495001" },
      { "name": "Organic burgundy okra purees", "code": "50495002" },
      { "name": "Organic clemson spineless okra purees", "code": "50495003" },
      {
        "name": "Organic dwarf green long pod okra purees",
        "code": "50495004"
      },
      {
        "name": "Organic mammoth spineless long pod okra purees",
        "code": "50495005"
      },
      { "name": "Organic red velvet okra purees", "code": "50495006" },
      {
        "name": "Organic star of david heirloom okra purees",
        "code": "50495007"
      },
      { "name": "Organic onion purees", "code": "50495100" },
      { "name": "Organic albion onion purees", "code": "50495101" },
      { "name": "Organic alisa craig onion purees", "code": "50495102" },
      { "name": "Organic boiling onion purees", "code": "50495103" },
      { "name": "Organic buffalo onion purees", "code": "50495104" },
      { "name": "Organic bulb onion purees", "code": "50495105" },
      { "name": "Organic creaming onion purees", "code": "50495106" },
      { "name": "Organic express yellow o-x onion purees", "code": "50495107" },
      { "name": "Organic kelsae onion purees", "code": "50495108" },
      {
        "name": "Organic marshalls giant fen globe onion purees",
        "code": "50495109"
      },
      { "name": "Organic pearl onion purees", "code": "50495110" },
      { "name": "Organic red baron onion purees", "code": "50495111" },
      { "name": "Organic red onion purees", "code": "50495112" },
      { "name": "Organic rijnsberger onion purees", "code": "50495113" },
      {
        "name": "Organic senshyu semi-globe yellow onion purees",
        "code": "50495114"
      },
      { "name": "Organic sturon onion purees", "code": "50495115" },
      { "name": "Organic stuttgarter giant onion purees", "code": "50495116" },
      { "name": "Organic sweet onion purees", "code": "50495117" },
      {
        "name": "Organic torpedo or red italian onion purees",
        "code": "50495118"
      },
      { "name": "Organic red storage onion purees", "code": "50495119" },
      { "name": "Organic white storage onion purees", "code": "50495120" },
      { "name": "Organic yellow storage onion purees", "code": "50495121" },
      { "name": "Organic peanut purees", "code": "50495200" },
      {
        "name": "Organic bambarra groundnut peanut purees",
        "code": "50495201"
      },
      { "name": "Organic florunner peanut purees", "code": "50495202" },
      {
        "name": "Organic hausa kersting's ground nut peanut purees",
        "code": "50495203"
      },
      { "name": "Organic spanish peanut purees", "code": "50495204" },
      { "name": "Organic valencia peanut purees", "code": "50495205" },
      { "name": "Organic virginia peanut purees", "code": "50495206" },
      { "name": "Organic pea purees", "code": "50495300" },
      { "name": "Organic purple hull pea purees", "code": "50495301" },
      { "name": "Organic pinkeye pea purees", "code": "50495302" },
      { "name": "Organic crowder pea purees", "code": "50495303" },
      { "name": "Organic white acre pea purees", "code": "50495304" },
      { "name": "Organic blackeyed pea purees", "code": "50495305" },
      { "name": "Organic zipper cream pea purees", "code": "50495306" },
      { "name": "Organic pepper purees", "code": "50495400" },
      { "name": "Organic ajies pepper purees", "code": "50495401" },
      { "name": "Organic arbol pepper purees", "code": "50495402" },
      { "name": "Organic cheese pepper purees", "code": "50495403" },
      { "name": "Organic chilaca pepper purees", "code": "50495404" },
      { "name": "Organic cubanelles pepper purees", "code": "50495405" },
      { "name": "Organic fresno pepper purees", "code": "50495406" },
      { "name": "Organic kapia pepper purees", "code": "50495407" },
      { "name": "Organic korean pepper purees", "code": "50495408" },
      { "name": "Organic manzano pepper purees", "code": "50495409" },
      { "name": "Organic melrose pepper purees", "code": "50495410" },
      { "name": "Organic yellow chile pepper purees", "code": "50495411" },
      { "name": "Organic aji dulces pepper purees", "code": "50495412" },
      { "name": "Organic anaheim pepper purees", "code": "50495413" },
      { "name": "Organic ancho pepper purees", "code": "50495414" },
      { "name": "Organic bell pepper purees", "code": "50495415" },
      { "name": "Organic cascabel pepper purees", "code": "50495416" },
      { "name": "Organic cayenne pepper purees", "code": "50495417" },
      { "name": "Organic cherry hots pepper purees", "code": "50495418" },
      { "name": "Organic chiltecpin pepper purees", "code": "50495419" },
      { "name": "Organic finger hot pepper purees", "code": "50495420" },
      { "name": "Organic guajillo pepper purees", "code": "50495421" },
      { "name": "Organic guerro pepper purees", "code": "50495422" },
      { "name": "Organic habanero pepper purees", "code": "50495423" },
      { "name": "Organic hungarian wax pepper purees", "code": "50495424" },
      { "name": "Organic jalape\u00f1o pepper purees", "code": "50495425" },
      { "name": "Organic long hot pepper purees", "code": "50495426" },
      { "name": "Organic mirasol pepper purees", "code": "50495427" },
      { "name": "Organic pasilla pepper purees", "code": "50495428" },
      { "name": "Organic peperoncini pepper purees", "code": "50495429" },
      { "name": "Organic pequin pepper purees", "code": "50495430" },
      { "name": "Organic pimiento pepper purees", "code": "50495431" },
      { "name": "Organic poblano pepper purees", "code": "50495432" },
      { "name": "Organic scotch bonnet pepper purees", "code": "50495433" },
      { "name": "Organic serrano pepper purees", "code": "50495434" },
      { "name": "Organic tabasco pepper purees", "code": "50495435" },
      { "name": "Organic tai pepper purees", "code": "50495436" },
      { "name": "Organic tepin pepper purees", "code": "50495437" },
      { "name": "Organic potato purees", "code": "50495500" },
      { "name": "Organic long white potato purees", "code": "50495501" },
      { "name": "Organic round white potato purees", "code": "50495502" },
      { "name": "Organic round red potato purees", "code": "50495503" },
      { "name": "Organic russet potato purees", "code": "50495504" },
      { "name": "Organic purple potato purees", "code": "50495505" },
      { "name": "Organic yellow potato purees", "code": "50495506" },
      { "name": "Organic new potato purees", "code": "50495507" },
      { "name": "Organic specialty potato purees", "code": "50495508" },
      { "name": "Organic rutabaga purees", "code": "50495600" },
      { "name": "Organic acme rutabaga purees", "code": "50495601" },
      { "name": "Organic angela rutabaga purees", "code": "50495602" },
      { "name": "Organic best of all rutabaga purees", "code": "50495603" },
      { "name": "Organic marian rutabaga purees", "code": "50495604" },
      { "name": "Organic sea vegetable purees", "code": "50495700" },
      { "name": "Organic agar-agar purees", "code": "50495701" },
      { "name": "Organic arame purees", "code": "50495702" },
      { "name": "Organic dulse purees", "code": "50495703" },
      { "name": "Organic haricot vert de mer purees", "code": "50495704" },
      { "name": "Organic hijiki purees", "code": "50495705" },
      { "name": "Organic irish moss purees", "code": "50495706" },
      { "name": "Organic kelp purees", "code": "50495707" },
      { "name": "Organic laver purees", "code": "50495708" },
      { "name": "Organic nori purees", "code": "50495709" },
      { "name": "Organic red alga purees", "code": "50495710" },
      { "name": "Organic sea kale purees", "code": "50495711" },
      { "name": "Organic sea lettuce purees", "code": "50495712" },
      { "name": "Organic seaweed purees", "code": "50495713" },
      { "name": "Organic spirulina purees", "code": "50495714" },
      { "name": "Organic susabi nori purees", "code": "50495715" },
      { "name": "Organic wakame purees", "code": "50495716" },
      { "name": "Organic shallot purees", "code": "50495800" },
      { "name": "Organic atlantic shallot purees", "code": "50495801" },
      { "name": "Organic creation shallot purees", "code": "50495802" },
      {
        "name": "Organic drittler white nest shallot purees",
        "code": "50495803"
      },
      {
        "name": "Organic giant yellow improved shallot purees",
        "code": "50495804"
      },
      { "name": "Organic golden gourmet shallot purees", "code": "50495805" },
      {
        "name": "Organic grise de bagnolet shallot purees",
        "code": "50495806"
      },
      { "name": "Organic hative de niort shallot purees", "code": "50495807" },
      { "name": "Organic pikant shallot purees", "code": "50495808" },
      { "name": "Organic red potato onion purees", "code": "50495809" },
      { "name": "Organic sante shallot purees", "code": "50495810" },
      { "name": "Organic topper shallot purees", "code": "50495811" },
      { "name": "Organic sorrel purees", "code": "50495900" },
      { "name": "Organic dock sorrel purees", "code": "50495901" },
      { "name": "Organic garden sorrel purees", "code": "50495902" },
      { "name": "Organic sheep sorrel purees", "code": "50495903" },
      { "name": "Organic wood sorrel purees", "code": "50495904" },
      { "name": "Organic spinach purees", "code": "50496000" },
      { "name": "Organic america spinach purees", "code": "50496001" },
      { "name": "Organic bloomsdale spinach purees", "code": "50496002" },
      { "name": "Organic giant winter spinach purees", "code": "50496003" },
      { "name": "Organic horenso spinach purees", "code": "50496004" },
      { "name": "Organic lamb's quarters spinach purees", "code": "50496005" },
      { "name": "Organic malabar spinach purees", "code": "50496006" },
      { "name": "Organic medania spinach purees", "code": "50496007" },
      { "name": "Organic orach spinach purees", "code": "50496008" },
      { "name": "Organic savoy spinach purees", "code": "50496009" },
      { "name": "Organic sigmaleaf spinach purees", "code": "50496010" },
      { "name": "Organic space spinach purees", "code": "50496011" },
      { "name": "Organic trinidad spinach purees", "code": "50496012" },
      { "name": "Organic wild spinach purees", "code": "50496013" },
      { "name": "Organic new zealand spinach purees", "code": "50496014" },
      { "name": "Organic iceplant spinach purees", "code": "50496015" },
      {
        "name": "Organic summer squash and summer pumpkin purees",
        "code": "50496100"
      },
      { "name": "Organic boston marrow squash purees", "code": "50496101" },
      { "name": "Organic butternut squash purees", "code": "50496102" },
      { "name": "Organic costata romanesca squash purees", "code": "50496103" },
      { "name": "Organic crookneck squash purees", "code": "50496104" },
      { "name": "Organic cucuzza squash purees", "code": "50496105" },
      { "name": "Organic delicata squash purees", "code": "50496106" },
      { "name": "Organic delicious squash purees", "code": "50496107" },
      {
        "name": "Organic early golden summer crookneck squash purees",
        "code": "50496108"
      },
      {
        "name": "Organic early prolific straight neck squash purees",
        "code": "50496109"
      },
      { "name": "Organic gold squash purees", "code": "50496110" },
      { "name": "Organic jack be little squash purees", "code": "50496111" },
      { "name": "Organic kentucky field squash purees", "code": "50496112" },
      { "name": "Organic marrow squash purees", "code": "50496113" },
      { "name": "Organic middle eastern squash purees", "code": "50496114" },
      { "name": "Organic miniature squash purees", "code": "50496115" },
      { "name": "Organic orangetti squash purees", "code": "50496116" },
      { "name": "Organic pattypan squash purees", "code": "50496117" },
      { "name": "Organic rondini squash purees", "code": "50496118" },
      { "name": "Organic round squash purees", "code": "50496119" },
      { "name": "Organic spaghetti squash purees", "code": "50496120" },
      { "name": "Organic stripetti squash purees", "code": "50496121" },
      { "name": "Organic sugar loaf squash purees", "code": "50496122" },
      { "name": "Organic sweet dumpling squash purees", "code": "50496123" },
      { "name": "Organic triple treat squash purees", "code": "50496124" },
      { "name": "Organic waltham butternut squash purees", "code": "50496125" },
      {
        "name": "Organic yellow bush scallop squash purees",
        "code": "50496126"
      },
      {
        "name": "Organic yellow straightneck squash purees",
        "code": "50496127"
      },
      { "name": "Organic zephyr squash purees", "code": "50496128" },
      { "name": "Organic zucchini squash purees", "code": "50496129" },
      { "name": "Organic sweet potato purees", "code": "50496200" },
      { "name": "Organic beauregard sweet potato purees", "code": "50496201" },
      { "name": "Organic centennial sweet potato purees", "code": "50496202" },
      { "name": "Organic diane sweet potato purees", "code": "50496203" },
      { "name": "Organic garnet sweet potato purees", "code": "50496204" },
      { "name": "Organic georgia red sweet potato purees", "code": "50496205" },
      { "name": "Organic goldensweet sweet potato purees", "code": "50496206" },
      { "name": "Organic hanna sweet potato purees", "code": "50496207" },
      { "name": "Organic japanese sweet potato purees", "code": "50496208" },
      { "name": "Organic jersey sweet potato purees", "code": "50496209" },
      { "name": "Organic jewel sweet potato purees", "code": "50496210" },
      {
        "name": "Organic maryland red sweet potato purees",
        "code": "50496211"
      },
      { "name": "Organic nemagold sweet potato purees", "code": "50496212" },
      { "name": "Organic o'henry sweet potato purees", "code": "50496213" },
      { "name": "Organic okinawan sweet potato purees", "code": "50496214" },
      { "name": "Organic orange sweet potato purees", "code": "50496215" },
      { "name": "Organic oriental sweet potato purees", "code": "50496216" },
      { "name": "Organic red jersey sweet potato purees", "code": "50496217" },
      { "name": "Organic red mar sweet potato purees", "code": "50496218" },
      { "name": "Organic redglow sweet potato purees", "code": "50496219" },
      {
        "name": "Organic yellow jersey sweet potato purees",
        "code": "50496220"
      },
      { "name": "Organic tomato purees", "code": "50496300" },
      { "name": "Organic ailsa craig tomato purees", "code": "50496301" },
      { "name": "Organic alicante tomato purees", "code": "50496302" },
      { "name": "Organic black plum tomato purees", "code": "50496303" },
      { "name": "Organic brandywine tomato purees", "code": "50496304" },
      { "name": "Organic cherry belle tomato purees", "code": "50496305" },
      { "name": "Organic cherry tomato purees", "code": "50496306" },
      { "name": "Organic delicious tomato purees", "code": "50496307" },
      { "name": "Organic dombito tomato purees", "code": "50496308" },
      {
        "name": "Organic gardener's delight tomato purees",
        "code": "50496309"
      },
      { "name": "Organic grape tomato purees", "code": "50496310" },
      { "name": "Organic green tomato purees", "code": "50496311" },
      { "name": "Organic marmande super tomato purees", "code": "50496312" },
      {
        "name": "Organic marvel striped traditional tomato purees",
        "code": "50496313"
      },
      { "name": "Organic minibel tomato purees", "code": "50496314" },
      { "name": "Organic oaxacan pink tomato purees", "code": "50496315" },
      { "name": "Organic red alert tomato purees", "code": "50496316" },
      { "name": "Organic roma vf tomato purees", "code": "50496317" },
      { "name": "Organic san marzano tomato purees", "code": "50496318" },
      { "name": "Organic shirley tomato purees", "code": "50496319" },
      { "name": "Organic siberia tomato purees", "code": "50496320" },
      { "name": "Organic super beefsteak tomato purees", "code": "50496321" },
      { "name": "Organic tigerella tomato purees", "code": "50496322" },
      { "name": "Organic tiny tim tomato purees", "code": "50496323" },
      { "name": "Organic tumbler tomato purees", "code": "50496324" },
      { "name": "Organic yellow cocktail tomato purees", "code": "50496325" },
      {
        "name": "Organic yellow pear-shaped tomato purees",
        "code": "50496326"
      },
      { "name": "Organic yellow perfection tomato purees", "code": "50496327" },
      { "name": "Organic turnip green purees", "code": "50496400" },
      { "name": "Organic green globe turnip purees", "code": "50496401" },
      { "name": "Organic golden ball turnip purees", "code": "50496402" },
      { "name": "Organic manchester market turnip purees", "code": "50496403" },
      { "name": "Organic purple top milan turnip purees", "code": "50496404" },
      { "name": "Organic purple top white turnip purees", "code": "50496405" },
      { "name": "Organic snowball turnip purees", "code": "50496406" },
      { "name": "Organic tokyo turnip purees", "code": "50496407" },
      { "name": "Organic tokyo cross turnip purees", "code": "50496408" },
      {
        "name": "Organic winter squash and winter pumpkin purees",
        "code": "50496500"
      },
      { "name": "Organic acorn squash purees", "code": "50496501" },
      { "name": "Organic atlantic giant squash purees", "code": "50496502" },
      { "name": "Organic banana pink squash purees", "code": "50496503" },
      { "name": "Organic big max squash purees", "code": "50496504" },
      { "name": "Organic calabaza squash purees", "code": "50496505" },
      { "name": "Organic carnival squash purees", "code": "50496506" },
      { "name": "Organic cheese pumpkin purees", "code": "50496507" },
      { "name": "Organic crown prince squash purees", "code": "50496508" },
      { "name": "Organic curcibita squash purees", "code": "50496509" },
      { "name": "Organic cushaw squash purees", "code": "50496510" },
      { "name": "Organic giant pumpkin squash purees", "code": "50496511" },
      { "name": "Organic hubbard squash purees", "code": "50496512" },
      { "name": "Organic jarrahdale squash purees", "code": "50496513" },
      { "name": "Organic kabocha squash purees", "code": "50496514" },
      { "name": "Organic queensland blue squash purees", "code": "50496515" },
      {
        "name": "Organic rouge vif d'etampes squash purees",
        "code": "50496516"
      },
      { "name": "Organic turk's turban squash purees", "code": "50496517" },
      { "name": "Organic valenciano squash purees", "code": "50496518" },
      { "name": "Organic warted hubbard squash purees", "code": "50496519" },
      {
        "name": "Organic whangaparoa crown pumpkin purees",
        "code": "50496520"
      },
      { "name": "Organic yam purees", "code": "50496600" },
      { "name": "Organic african bitter yam purees", "code": "50496601" },
      { "name": "Organic asiatic bitter yam purees", "code": "50496602" },
      { "name": "Organic chinese yam purees", "code": "50496603" },
      { "name": "Organic globe yam purees", "code": "50496604" },
      { "name": "Organic greater yam purees", "code": "50496605" },
      { "name": "Organic japanese yam purees", "code": "50496606" },
      { "name": "Organic lesser yam purees", "code": "50496607" },
      { "name": "Organic potato yam purees", "code": "50496608" },
      { "name": "Organic white guinea yam purees", "code": "50496609" },
      { "name": "Organic yellow guinea yam purees", "code": "50496610" },
      { "name": "Organic corn purees", "code": "50496700" },
      { "name": "Organic aloha corn purees", "code": "50496701" },
      { "name": "Organic alpine corn purees", "code": "50496702" },
      { "name": "Organic ambrosia corn purees", "code": "50496703" },
      { "name": "Organic argent corn purees", "code": "50496704" },
      { "name": "Organic aspen corn purees", "code": "50496705" },
      { "name": "Organic avalanche corn purees", "code": "50496706" },
      { "name": "Organic biqueen corn purees", "code": "50496707" },
      { "name": "Organic bodacious corn purees", "code": "50496708" },
      { "name": "Organic butter and sugar corn purees", "code": "50496709" },
      { "name": "Organic calico belle corn purees", "code": "50496710" },
      { "name": "Organic camelot corn purees", "code": "50496711" },
      {
        "name": "Organic challengercrisp \u2018n sweet corn purees",
        "code": "50496712"
      },
      { "name": "Organic champ corn purees", "code": "50496713" },
      { "name": "Organic cotton candy corn purees", "code": "50496714" },
      { "name": "Organic d\u2019artagnan corn purees", "code": "50496715" },
      { "name": "Organic dazzle corn purees", "code": "50496716" },
      { "name": "Organic diamond and gold corn purees", "code": "50496717" },
      { "name": "Organic divinity corn purees", "code": "50496718" },
      { "name": "Organic double delight corn purees", "code": "50496719" },
      { "name": "Organic double gem corn purees", "code": "50496720" },
      { "name": "Organic earlivee corn purees", "code": "50496721" },
      { "name": "Organic early xtra sweet corn purees", "code": "50496722" },
      { "name": "Organic excel corn purees", "code": "50496723" },
      { "name": "Organic golden cross bantam corn purees", "code": "50496724" },
      { "name": "Organic honey and cream corn purees", "code": "50496725" },
      { "name": "Organic honey \u2018n pearl corn purees", "code": "50496726" },
      { "name": "Organic how sweet it is corn purees", "code": "50496727" },
      { "name": "Organic hudson corn purees", "code": "50496728" },
      { "name": "Organic illini gold corn purees", "code": "50496729" },
      { "name": "Organic illini xtra sweet corn purees", "code": "50496730" },
      { "name": "Organic incredible corn purees", "code": "50496731" },
      { "name": "Organic iochief corn purees", "code": "50496732" },
      { "name": "Organic jubilee corn purees", "code": "50496733" },
      { "name": "Organic jubilee supersweet corn purees", "code": "50496734" },
      { "name": "Organic kandy korn corn purees", "code": "50496735" },
      { "name": "Organic kiss \u2018n tell corn purees", "code": "50496736" },
      { "name": "Organic lancelot corn purees", "code": "50496737" },
      { "name": "Organic maple sweet corn purees", "code": "50496738" },
      { "name": "Organic medley corn purees", "code": "50496739" },
      { "name": "Organic merlin corn purees", "code": "50496740" },
      { "name": "Organic miracle corn purees", "code": "50496741" },
      { "name": "Organic nk-199 corn purees", "code": "50496742" },
      { "name": "Organic peaches and cream corn purees", "code": "50496743" },
      { "name": "Organic pearl white corn purees", "code": "50496744" },
      { "name": "Organic pegasus corn purees", "code": "50496745" },
      { "name": "Organic phenomenal corn purees", "code": "50496746" },
      { "name": "Organic platinum lady corn purees", "code": "50496747" },
      { "name": "Organic precocious corn purees", "code": "50496748" },
      { "name": "Organic pristine corn purees", "code": "50496749" },
      { "name": "Organic quickie corn purees", "code": "50496750" },
      { "name": "Organic radiance corn purees", "code": "50496751" },
      { "name": "Organic seneca brave corn purees", "code": "50496752" },
      { "name": "Organic seneca dawn corn purees", "code": "50496753" },
      { "name": "Organic seneca horizon corn purees", "code": "50496754" },
      { "name": "Organic seneca starshine corn purees", "code": "50496755" },
      { "name": "Organic seneca white knight corn purees", "code": "50496756" },
      { "name": "Organic showcase corn purees", "code": "50496757" },
      { "name": "Organic silver queen corn purees", "code": "50496758" },
      { "name": "Organic snowbelle corn purees", "code": "50496759" },
      { "name": "Organic spring snow corn purees", "code": "50496760" },
      { "name": "Organic spring treat corn purees", "code": "50496761" },
      { "name": "Organic sugar and gold corn purees", "code": "50496762" },
      { "name": "Organic sugar buns corn purees", "code": "50496763" },
      { "name": "Organic sugar snow corn purees", "code": "50496764" },
      { "name": "Organic sundance corn purees", "code": "50496765" },
      { "name": "Organic telstar corn purees", "code": "50496766" },
      { "name": "Organic terminator corn purees", "code": "50496767" },
      { "name": "Organic treasure corn purees", "code": "50496768" },
      { "name": "Organic tuxedo corn purees", "code": "50496769" },
      { "name": "Organic nominant vegetable purees", "code": "50496800" },
      { "name": "Organic alfalfa purees", "code": "50496801" },
      { "name": "Organic aloe leaf purees", "code": "50496802" },
      { "name": "Organic apio purees", "code": "50496803" },
      { "name": "Organic arrow root purees", "code": "50496804" },
      { "name": "Organic arrowhead purees", "code": "50496805" },
      { "name": "Organic arugula purees", "code": "50496806" },
      { "name": "Organic arum purees", "code": "50496807" },
      { "name": "Organic bamboo shoot purees", "code": "50496808" },
      { "name": "Organic banana leaf purees", "code": "50496809" },
      { "name": "Organic batata purees", "code": "50496810" },
      { "name": "Organic bean sprout purees", "code": "50496811" },
      { "name": "Organic beet top purees", "code": "50496812" },
      { "name": "Organic bittermelon purees", "code": "50496813" },
      { "name": "Organic caperberry purees", "code": "50496814" },
      { "name": "Organic carob purees", "code": "50496815" },
      { "name": "Organic cha-om purees", "code": "50496816" },
      { "name": "Organic chaoyote purees", "code": "50496817" },
      { "name": "Organic chickpea purees", "code": "50496818" },
      { "name": "Organic chrysanthemum green purees", "code": "50496819" },
      { "name": "Organic dandelion green purees", "code": "50496820" },
      { "name": "Organic dandelion purees", "code": "50496821" },
      { "name": "Organic dasheen purees", "code": "50496822" },
      { "name": "Organic dau mue or pea tip purees", "code": "50496823" },
      { "name": "Organic diakon purees", "code": "50496824" },
      { "name": "Organic donqua purees", "code": "50496825" },
      { "name": "Organic fiddlehead fern purees", "code": "50496826" },
      { "name": "Organic gai choy purees", "code": "50496827" },
      { "name": "Organic gailon purees", "code": "50496828" },
      { "name": "Organic galang purees", "code": "50496829" },
      { "name": "Organic ginger root purees", "code": "50496830" },
      { "name": "Organic gobo purees", "code": "50496831" },
      { "name": "Organic hop sprout purees", "code": "50496832" },
      { "name": "Organic horseradish purees", "code": "50496833" },
      { "name": "Organic jicama purees", "code": "50496834" },
      { "name": "Organic kudzu purees", "code": "50496835" },
      { "name": "Organic lily bul purees", "code": "50496836" },
      { "name": "Organic linkok purees", "code": "50496837" },
      { "name": "Organic lo bok purees", "code": "50496838" },
      { "name": "Organic long bean purees", "code": "50496839" },
      { "name": "Organic lotus root purees", "code": "50496840" },
      { "name": "Organic maguey leaf purees", "code": "50496841" },
      { "name": "Organic mallow purees", "code": "50496842" },
      { "name": "Organic mamey sapot purees", "code": "50496843" },
      { "name": "Organic moap purees", "code": "50496844" },
      { "name": "Organic moo purees", "code": "50496845" },
      { "name": "Organic moqua purees", "code": "50496846" },
      { "name": "Organic opo purees", "code": "50496847" },
      { "name": "Organic palm heart purees", "code": "50496848" },
      { "name": "Organic paprika purees", "code": "50496849" },
      { "name": "Organic purslanepurees", "code": "50496850" },
      { "name": "Organic raddichio purees", "code": "50496851" },
      { "name": "Organic sinquas purees", "code": "50496852" },
      { "name": "Organic soybean purees", "code": "50496853" },
      { "name": "Organic spoonwart purees", "code": "50496854" },
      { "name": "Organic taro purees", "code": "50496855" },
      { "name": "Organic taro lea purees", "code": "50496856" },
      { "name": "Organic taro shoot purees", "code": "50496857" },
      { "name": "Organic tassle grape-hyacint purees", "code": "50496858" },
      { "name": "Organic tendergreen purees", "code": "50496859" },
      { "name": "Organic tepeguaje purees", "code": "50496860" },
      { "name": "Organic tindora purees", "code": "50496861" },
      { "name": "Organic tree onion purees", "code": "50496862" },
      { "name": "Organic udo purees", "code": "50496863" },
      { "name": "Organic water chestnut purees", "code": "50496864" },
      { "name": "Organic water spinach purees", "code": "50496865" },
      { "name": "Organic yamp purees", "code": "50496866" },
      { "name": "Organic yautia purees", "code": "50496867" },
      { "name": "Organic yu choy purees", "code": "50496868" },
      { "name": "Organic yuca purees", "code": "50496869" },
      { "name": "Organic sugar pea purees", "code": "50496900" },
      { "name": "Organic bikini pea purees", "code": "50496901" },
      { "name": "Organic cavalier pea purees", "code": "50496902" },
      { "name": "Organic daisy pea purees", "code": "50496903" },
      { "name": "Organic darfon pea purees", "code": "50496904" },
      { "name": "Organic early onward pea purees", "code": "50496905" },
      { "name": "Organic feltham first pea purees", "code": "50496906" },
      { "name": "Organic hurst green shaft pea purees", "code": "50496907" },
      { "name": "Organic oregon sugar pod pea purees", "code": "50496908" },
      { "name": "Organic prince albert pea purees", "code": "50496909" },
      { "name": "Organic reuzensuiker pea purees", "code": "50496910" },
      { "name": "Organic chinese broccoli purees", "code": "50496911" },
      { "name": "Organic citadel brussel sprout purees", "code": "50496912" },
      { "name": "Organic falstaff brussel sprout purees", "code": "50496913" },
      { "name": "Nutritional supplements", "code": "50500000" },
      { "name": "Vitamin supplements", "code": "50501500" },
      { "name": "Vitamin A supplement", "code": "50501501" },
      { "name": "Vitamin B1 supplement", "code": "50501502" },
      { "name": "Vitamin B2 supplement", "code": "50501503" },
      { "name": "Vitamin B3 supplement", "code": "50501504" },
      { "name": "Vitamin B5 supplement", "code": "50501505" },
      { "name": "Vitamin B6 supplement", "code": "50501506" },
      { "name": "Vitamin B7 supplement", "code": "50501507" },
      { "name": "Vitamin B9 supplement", "code": "50501508" },
      { "name": "Vitamin B12 supplement", "code": "50501509" },
      { "name": "Vitamin C supplement", "code": "50501510" },
      { "name": "Vitamin D supplement", "code": "50501511" },
      { "name": "Vitamin E supplement", "code": "50501512" },
      { "name": "Vitamin K supplement", "code": "50501513" },
      { "name": "Choline", "code": "50501514" },
      { "name": "Retinol", "code": "50501515" },
      { "name": "Nutritional mineral supplements", "code": "50501600" },
      { "name": "Nutritional calcium supplement", "code": "50501601" },
      { "name": "Nutritional copper supplement", "code": "50501602" },
      { "name": "Nutritional fluoride supplement", "code": "50501603" },
      { "name": "Nutritional iodine supplement", "code": "50501604" },
      { "name": "Nutritional iron supplement", "code": "50501605" },
      { "name": "Nutritional magnesium supplement", "code": "50501606" },
      { "name": "Nutritional manganese supplement", "code": "50501607" },
      { "name": "Nutritional molybdenum supplement", "code": "50501608" },
      { "name": "Nutritional phosphorus supplement", "code": "50501609" },
      { "name": "Nutritional potassium supplement", "code": "50501610" },
      { "name": "Nutritional selenium supplement", "code": "50501611" },
      { "name": "Nutritional sodium supplement", "code": "50501612" },
      { "name": "Nutritional zinc supplement", "code": "50501613" },
      { "name": "Macro nutrient supplements", "code": "50501700" },
      { "name": "Amino acid nutritional supplement", "code": "50501701" },
      { "name": "Complete meal replacement drink or mix", "code": "50501702" },
      { "name": "Nutritional protein supplement", "code": "50501703" },
      { "name": "Omega 3 fatty acid supplement", "code": "50501704" },
      { "name": "Omega 6 fatty acid supplement", "code": "50501705" },
      { "name": "Dietary fiber supplement", "code": "50501706" },
      { "name": "Combination nutritional supplements", "code": "50501800" },
      { "name": "Multi vitamin supplement", "code": "50501801" },
      { "name": "Multi mineral nutritional supplement", "code": "50501802" },
      {
        "name": "Combination fatty acid nutritional supplement",
        "code": "50501803"
      },
      {
        "name": "Combination vitamin and mineral nutritional supplement",
        "code": "50501804"
      },
      { "name": "Veterinary nutritional supplements", "code": "50501900" },
      { "name": "Mineral salt lick", "code": "50501901" },
      { "name": "Veterinary multinutrient block", "code": "50501902" },
      { "name": "Veterinary vitamin", "code": "50501903" },
      { "name": "Veterinary vitamin and mineral mixture", "code": "50501904" },
      { "name": "Non GMO fresh fruits", "code": "50520000" },
      { "name": "Non GMO apples", "code": "50521500" },
      { "name": "Non GMO akane apples", "code": "50521501" },
      { "name": "Non GMO ambrosia apples", "code": "50521502" },
      { "name": "Non GMO api apples", "code": "50521503" },
      { "name": "Non GMO baldwin apples", "code": "50521504" },
      { "name": "Non GMO braeburn apples", "code": "50521505" },
      { "name": "Non GMO bramley apples", "code": "50521506" },
      { "name": "Non GMO bramley seedling apples", "code": "50521507" },
      { "name": "Non GMO calville blanche d'hiver apples", "code": "50521508" },
      { "name": "Non GMO cameo apples", "code": "50521509" },
      { "name": "Non GMO charles ross apples", "code": "50521510" },
      { "name": "Non GMO codlin apples", "code": "50521511" },
      { "name": "Non GMO cortland apples", "code": "50521512" },
      { "name": "Non GMO costard apples", "code": "50521513" },
      { "name": "Non GMO court pendu plat apples", "code": "50521514" },
      { "name": "Non GMO cox's orange pippin apples", "code": "50521515" },
      { "name": "Non GMO crab apples", "code": "50521516" },
      { "name": "Non GMO crispin apples", "code": "50521517" },
      { "name": "Non GMO delicious apples", "code": "50521518" },
      { "name": "Non GMO duchess apples", "code": "50521519" },
      { "name": "Non GMO earligold apples", "code": "50521520" },
      { "name": "Non GMO early mcintosh apples", "code": "50521521" },
      { "name": "Non GMO elstar apples", "code": "50521522" },
      { "name": "Non GMO empire apples", "code": "50521523" },
      { "name": "Non GMO flower of kent apples", "code": "50521524" },
      { "name": "Non GMO fuji apples", "code": "50521525" },
      { "name": "Non GMO gala apples", "code": "50521526" },
      { "name": "Non GMO gascoyne's scarlet apples", "code": "50521527" },
      { "name": "Non GMO giliflower apples", "code": "50521528" },
      { "name": "Non GMO ginger gold apples", "code": "50521529" },
      { "name": "Non GMO gladstone apples", "code": "50521530" },
      { "name": "Non GMO gloster apples", "code": "50521531" },
      { "name": "Non GMO gold supreme apples", "code": "50521532" },
      { "name": "Non GMO golden delicious apples", "code": "50521533" },
      { "name": "Non GMO golden noble apples", "code": "50521534" },
      { "name": "Non GMO granny smith apples", "code": "50521535" },
      { "name": "Non GMO gravenstein apples", "code": "50521536" },
      { "name": "Non GMO greening apples", "code": "50521537" },
      { "name": "Non GMO greensleeves apples", "code": "50521538" },
      { "name": "Non GMO honeycrisp apples", "code": "50521539" },
      { "name": "Non GMO howgate wonder apples", "code": "50521540" },
      { "name": "Non GMO ida red apples", "code": "50521541" },
      { "name": "Non GMO james grieve apples", "code": "50521542" },
      { "name": "Non GMO jersey mac apples", "code": "50521543" },
      { "name": "Non GMO jester apples", "code": "50521544" },
      { "name": "Non GMO jonagold apples", "code": "50521545" },
      { "name": "Non GMO jonamac apples", "code": "50521546" },
      { "name": "Non GMO jonathan apples", "code": "50521547" },
      { "name": "Non GMO katy apples", "code": "50521548" },
      { "name": "Non GMO kidd's orange red apples", "code": "50521549" },
      { "name": "Non GMO lady apples", "code": "50521550" },
      { "name": "Non GMO law rome apples", "code": "50521551" },
      { "name": "Non GMO laxton apples", "code": "50521552" },
      { "name": "Non GMO lord derby apples", "code": "50521553" },
      { "name": "Non GMO macoun apples", "code": "50521554" },
      { "name": "Non GMO mcintosh apples", "code": "50521555" },
      { "name": "Non GMO mutsu apples", "code": "50521556" },
      { "name": "Non GMO newtown pippin apples", "code": "50521557" },
      { "name": "Non GMO northern spy apples", "code": "50521558" },
      { "name": "Non GMO orleans reinette apples", "code": "50521559" },
      { "name": "Non GMO ozark gold apples", "code": "50521560" },
      { "name": "Non GMO pacific rose apples", "code": "50521561" },
      { "name": "Non GMO paula red apples", "code": "50521562" },
      { "name": "Non GMO pearmain apples", "code": "50521563" },
      { "name": "Non GMO pink lady apples", "code": "50521564" },
      { "name": "Non GMO pippin apples", "code": "50521565" },
      { "name": "Non GMO pitmaston pineapple apples", "code": "50521566" },
      { "name": "Non GMO pomme d'api apples", "code": "50521567" },
      { "name": "Non GMO prime gold apples", "code": "50521568" },
      { "name": "Non GMO red astrachan apples", "code": "50521569" },
      { "name": "Non GMO red boscoop apples", "code": "50521570" },
      { "name": "Non GMO red chief apples", "code": "50521571" },
      { "name": "Non GMO red delicious apples", "code": "50521572" },
      { "name": "Non GMO red gravenstein apples", "code": "50521573" },
      { "name": "Non GMO red rome apples", "code": "50521574" },
      { "name": "Non GMO red stayman apples", "code": "50521575" },
      { "name": "Non GMO red york apples", "code": "50521576" },
      { "name": "Non GMO reinette apples", "code": "50521577" },
      { "name": "Non GMO rome beauty apples", "code": "50521578" },
      { "name": "Non GMO russet apples", "code": "50521579" },
      { "name": "Non GMO sierra beauty apples", "code": "50521580" },
      { "name": "Non GMO spartan apples", "code": "50521581" },
      { "name": "Non GMO stark crimson apples", "code": "50521582" },
      { "name": "Non GMO starking apples", "code": "50521583" },
      { "name": "Non GMO stayman apples", "code": "50521584" },
      { "name": "Non GMO stayman winesap apples", "code": "50521585" },
      { "name": "Non GMO summer rambo apples", "code": "50521586" },
      { "name": "Non GMO tsugaru apples", "code": "50521587" },
      { "name": "Non GMO twenty ounce apples", "code": "50521588" },
      { "name": "Non GMO tydeman red apples", "code": "50521589" },
      { "name": "Non GMO vistabella apples", "code": "50521590" },
      { "name": "Non GMO wealthy apples", "code": "50521591" },
      { "name": "Non GMO white joaneting apples", "code": "50521592" },
      { "name": "Non GMO white transparent apples", "code": "50521593" },
      { "name": "Non GMO winesap apples", "code": "50521594" },
      { "name": "Non GMO worcester apples", "code": "50521595" },
      { "name": "Non GMO york imperial apples", "code": "50521596" },
      { "name": "Non GMO apricots", "code": "50521600" },
      { "name": "Non GMO ambercot apricots", "code": "50521601" },
      { "name": "Non GMO apache apricots", "code": "50521602" },
      { "name": "Non GMO birttany gold apricots", "code": "50521603" },
      { "name": "Non GMO black apricots", "code": "50521604" },
      { "name": "Non GMO blenheim apricots", "code": "50521605" },
      { "name": "Non GMO bonny apricots", "code": "50521606" },
      { "name": "Non GMO bulida apricots", "code": "50521607" },
      { "name": "Non GMO castlebrite apricots", "code": "50521608" },
      { "name": "Non GMO clutha gold apricots", "code": "50521609" },
      { "name": "Non GMO cluthasun apricots", "code": "50521610" },
      { "name": "Non GMO darby royal apricots", "code": "50521611" },
      { "name": "Non GMO dina apricots", "code": "50521612" },
      { "name": "Non GMO earlicot apricots", "code": "50521613" },
      { "name": "Non GMO earliman apricots", "code": "50521614" },
      { "name": "Non GMO early bright apricots", "code": "50521615" },
      { "name": "Non GMO flaming gold apricots", "code": "50521616" },
      { "name": "Non GMO fresno apricots", "code": "50521617" },
      { "name": "Non GMO gold brite apricots", "code": "50521618" },
      { "name": "Non GMO goldbar apricots", "code": "50521619" },
      { "name": "Non GMO golden sweet apricots", "code": "50521620" },
      { "name": "Non GMO goldrich apricots", "code": "50521621" },
      { "name": "Non GMO helena apricots", "code": "50521622" },
      { "name": "Non GMO honeycot apricots", "code": "50521623" },
      { "name": "Non GMO imperial apricots", "code": "50521624" },
      { "name": "Non GMO jordanne apricots", "code": "50521625" },
      { "name": "Non GMO jumbo cot apricots", "code": "50521626" },
      { "name": "Non GMO kandy kot apricots", "code": "50521627" },
      { "name": "Non GMO katy apricots", "code": "50521628" },
      { "name": "Non GMO king apricots", "code": "50521629" },
      { "name": "Non GMO lambertin apricots", "code": "50521630" },
      { "name": "Non GMO lorna apricots", "code": "50521631" },
      { "name": "Non GMO lulu belle apricots", "code": "50521632" },
      { "name": "Non GMO modesto apricots", "code": "50521633" },
      { "name": "Non GMO moorpark apricots", "code": "50521634" },
      { "name": "Non GMO orangered apricots", "code": "50521635" },
      { "name": "Non GMO palstein apricots", "code": "50521636" },
      { "name": "Non GMO patterson apricots", "code": "50521637" },
      { "name": "Non GMO perfection apricots", "code": "50521638" },
      { "name": "Non GMO poppy apricots", "code": "50521639" },
      { "name": "Non GMO poppycot apricots", "code": "50521640" },
      { "name": "Non GMO queen apricots", "code": "50521641" },
      { "name": "Non GMO riland apricots", "code": "50521642" },
      { "name": "Non GMO rival apricots", "code": "50521643" },
      { "name": "Non GMO robada apricots", "code": "50521644" },
      { "name": "Non GMO royal apricots", "code": "50521645" },
      { "name": "Non GMO royal blenheim apricots", "code": "50521646" },
      { "name": "Non GMO royal orange apricots", "code": "50521647" },
      { "name": "Non GMO sundrop apricots", "code": "50521648" },
      { "name": "Non GMO tilton apricots", "code": "50521649" },
      { "name": "Non GMO tomcot apricots", "code": "50521650" },
      { "name": "Non GMO tracy apricots", "code": "50521651" },
      { "name": "Non GMO tri gem apricots", "code": "50521652" },
      { "name": "Non GMO valley gold apricots", "code": "50521653" },
      { "name": "Non GMO westley apricots", "code": "50521654" },
      { "name": "Non GMO york apricots", "code": "50521655" },
      { "name": "Non GMO bananas", "code": "50521700" },
      { "name": "Non GMO apple bananas", "code": "50521701" },
      { "name": "Non GMO baby bananas", "code": "50521702" },
      { "name": "Non GMO burro bananas", "code": "50521703" },
      { "name": "Non GMO cavendish bananas", "code": "50521704" },
      { "name": "Non GMO dominico bananas", "code": "50521705" },
      { "name": "Non GMO green bananas", "code": "50521706" },
      { "name": "Non GMO gros michel bananas", "code": "50521707" },
      { "name": "Non GMO lacatan bananas", "code": "50521708" },
      { "name": "Non GMO lady finger bananas", "code": "50521709" },
      { "name": "Non GMO manzano bananas", "code": "50521710" },
      { "name": "Non GMO mysore bananas", "code": "50521711" },
      { "name": "Non GMO pisang mas bananas", "code": "50521712" },
      { "name": "Non GMO red bananas", "code": "50521713" },
      { "name": "Non GMO saba bananas", "code": "50521714" },
      { "name": "Non GMO sucrier bananas", "code": "50521715" },
      { "name": "Non GMO barberries", "code": "50521800" },
      { "name": "Non GMO paleleaf barberries", "code": "50521801" },
      { "name": "Non GMO chenault barberries", "code": "50521802" },
      { "name": "Non GMO red barberries", "code": "50521803" },
      { "name": "Non GMO wintergreen barberries", "code": "50521804" },
      { "name": "Non GMO korean barberries", "code": "50521805" },
      { "name": "Non GMO mentor barberries", "code": "50521806" },
      { "name": "Non GMO japanese barberries", "code": "50521807" },
      { "name": "Non GMO atropurpurea barberries", "code": "50521808" },
      { "name": "Non GMO aurea barberries", "code": "50521809" },
      { "name": "Non GMO bagatelle barberries", "code": "50521810" },
      { "name": "Non GMO crimson pygmy barberries", "code": "50521811" },
      { "name": "Non GMO kobold barberries", "code": "50521812" },
      { "name": "Non GMO warty barberries", "code": "50521813" },
      { "name": "Non GMO european barberries", "code": "50521814" },
      { "name": "Non GMO bearberries", "code": "50521900" },
      { "name": "Non GMO alpine bearberries", "code": "50521901" },
      { "name": "Non GMO red bearberries", "code": "50521902" },
      { "name": "Non GMO common bearberries", "code": "50521903" },
      { "name": "Non GMO blackberries", "code": "50522000" },
      { "name": "Non GMO apache blackberries", "code": "50522001" },
      { "name": "Non GMO black satin blackberries", "code": "50522002" },
      { "name": "Non GMO boysenberries", "code": "50522003" },
      { "name": "Non GMO cherokee blackberries", "code": "50522004" },
      { "name": "Non GMO chester blackberries", "code": "50522005" },
      { "name": "Non GMO dirksen blackberries", "code": "50522006" },
      { "name": "Non GMO jostaberries", "code": "50522007" },
      { "name": "Non GMO loganberries", "code": "50522008" },
      { "name": "Non GMO marionberries", "code": "50522009" },
      { "name": "Non GMO navaho blackberries", "code": "50522010" },
      { "name": "Non GMO nectarberries", "code": "50522011" },
      { "name": "Non GMO olallie blackberries", "code": "50522012" },
      { "name": "Non GMO tayberries", "code": "50522013" },
      { "name": "Non GMO thornless hull blackberries", "code": "50522014" },
      { "name": "Non GMO youngberries", "code": "50522015" },
      { "name": "Non GMO billberries", "code": "50522100" },
      { "name": "Non GMO bog bilberries", "code": "50522101" },
      { "name": "Non GMO dwarf bilberries", "code": "50522102" },
      { "name": "Non GMO mountain bilberries", "code": "50522103" },
      { "name": "Non GMO oval-leaved bilberries", "code": "50522104" },
      { "name": "Non GMO blueberries", "code": "50522200" },
      { "name": "Non GMO bluetta blueberries", "code": "50522201" },
      { "name": "Non GMO duke blueberries", "code": "50522202" },
      { "name": "Non GMO spartan blueberries", "code": "50522203" },
      { "name": "Non GMO patriot blueberries", "code": "50522204" },
      { "name": "Non GMO toro blueberries", "code": "50522205" },
      { "name": "Non GMO hardyblue blueberries", "code": "50522206" },
      { "name": "Non GMO bluecrop blueberries", "code": "50522207" },
      { "name": "Non GMO legacy blueberries", "code": "50522208" },
      { "name": "Non GMO nelson blueberries", "code": "50522209" },
      { "name": "Non GMO chandler blueberries", "code": "50522210" },
      { "name": "Non GMO brigitta blueberries", "code": "50522211" },
      { "name": "Non GMO northcountry blueberries", "code": "50522212" },
      { "name": "Non GMO northsky blueberries", "code": "50522213" },
      { "name": "Non GMO northblue blueberries", "code": "50522214" },
      { "name": "Non GMO misty blueberries", "code": "50522215" },
      { "name": "Non GMO breadfruits", "code": "50522300" },
      { "name": "Non GMO chataigne breadfruits", "code": "50522301" },
      { "name": "Non GMO seedless breadfruits", "code": "50522302" },
      { "name": "Non GMO white heart breadfruits", "code": "50522303" },
      { "name": "Non GMO yellow heart breadfruits", "code": "50522304" },
      { "name": "Non GMO cherimoyas", "code": "50522400" },
      { "name": "Non GMO bays cherimoyas", "code": "50522401" },
      { "name": "Non GMO bronceada cherimoyas", "code": "50522402" },
      { "name": "Non GMO burtons cherimoyas", "code": "50522403" },
      { "name": "Non GMO burtons favorite cherimoyas", "code": "50522404" },
      { "name": "Non GMO jete cherimoyas", "code": "50522405" },
      { "name": "Non GMO reretai cherimoyas", "code": "50522406" },
      { "name": "Non GMO smoothey cherimoyas", "code": "50522407" },
      { "name": "Non GMO spain cherimoyas", "code": "50522408" },
      { "name": "Non GMO white cherimoyas", "code": "50522409" },
      { "name": "Non GMO cherries", "code": "50522500" },
      { "name": "Non GMO amarelle cherries", "code": "50522501" },
      { "name": "Non GMO brooks cherries", "code": "50522502" },
      { "name": "Non GMO bigarreu cherries", "code": "50522503" },
      { "name": "Non GMO bing cherries", "code": "50522504" },
      { "name": "Non GMO black republic cherries", "code": "50522505" },
      { "name": "Non GMO black schmidt cherries", "code": "50522506" },
      { "name": "Non GMO black tartarian cherries", "code": "50522507" },
      { "name": "Non GMO fiesta bing cherries", "code": "50522508" },
      { "name": "Non GMO garnet cherries", "code": "50522509" },
      { "name": "Non GMO king cherries", "code": "50522510" },
      { "name": "Non GMO chapman cherries", "code": "50522511" },
      { "name": "Non GMO lapin cherries", "code": "50522512" },
      { "name": "Non GMO larian cherries", "code": "50522513" },
      { "name": "Non GMO dark guines cherries", "code": "50522514" },
      { "name": "Non GMO montmorency cherries", "code": "50522515" },
      { "name": "Non GMO duke cherries", "code": "50522516" },
      { "name": "Non GMO early rivers cherries", "code": "50522517" },
      { "name": "Non GMO ruby bing cherries", "code": "50522518" },
      { "name": "Non GMO santina cherries", "code": "50522519" },
      { "name": "Non GMO geans/guines cherries", "code": "50522520" },
      { "name": "Non GMO sonata cherries", "code": "50522521" },
      { "name": "Non GMO lambert cherries", "code": "50522522" },
      { "name": "Non GMO stella cherries", "code": "50522523" },
      { "name": "Non GMO sweetheart cherries", "code": "50522524" },
      { "name": "Non GMO tartarian cherries", "code": "50522525" },
      { "name": "Non GMO maraschino cherries", "code": "50522526" },
      { "name": "Non GMO van cherries", "code": "50522527" },
      { "name": "Non GMO morello cherries", "code": "50522528" },
      { "name": "Non GMO royal ann cherries", "code": "50522529" },
      { "name": "Non GMO ranier cherries", "code": "50522530" },
      { "name": "Non GMO royal cherries", "code": "50522531" },
      { "name": "Non GMO citrons", "code": "50522600" },
      { "name": "Non GMO buddha's hand citrons", "code": "50522601" },
      { "name": "Non GMO fingered citrons", "code": "50522602" },
      { "name": "Non GMO fo shoukan citrons", "code": "50522603" },
      { "name": "Non GMO bushakan citrons", "code": "50522604" },
      { "name": "Non GMO diamante citrons", "code": "50522605" },
      { "name": "Non GMO etrog citrons", "code": "50522606" },
      { "name": "Non GMO ponderosa citrons", "code": "50522607" },
      { "name": "Non GMO cranberries", "code": "50522700" },
      { "name": "Non GMO ben lear cranberries", "code": "50522701" },
      { "name": "Non GMO early black cranberries", "code": "50522702" },
      { "name": "Non GMO grycleski cranberries", "code": "50522703" },
      { "name": "Non GMO howe cranberries", "code": "50522704" },
      { "name": "Non GMO lingonberries", "code": "50522705" },
      { "name": "Non GMO mcfarlin cranberries", "code": "50522706" },
      { "name": "Non GMO mountain cranberries", "code": "50522707" },
      { "name": "Non GMO pilgrim cranberries", "code": "50522708" },
      { "name": "Non GMO searless cranberries", "code": "50522709" },
      { "name": "Non GMO stevens cranberries", "code": "50522710" },
      { "name": "Non GMO currants", "code": "50522800" },
      { "name": "Non GMO hudson bay currants", "code": "50522801" },
      { "name": "Non GMO waxy currants", "code": "50522802" },
      { "name": "Non GMO desert currants", "code": "50522803" },
      { "name": "Non GMO black currants", "code": "50522804" },
      { "name": "Non GMO red currants", "code": "50522805" },
      { "name": "Non GMO white currants", "code": "50522806" },
      { "name": "Non GMO dates", "code": "50522900" },
      { "name": "Non GMO asharasi dates", "code": "50522901" },
      { "name": "Non GMO barhi or barhee dates", "code": "50522902" },
      { "name": "Non GMO deglet noor dates", "code": "50522903" },
      { "name": "Non GMO fardh dates", "code": "50522904" },
      { "name": "Non GMO gundila dates", "code": "50522905" },
      { "name": "Non GMO halawi/halawy dates", "code": "50522906" },
      { "name": "Non GMO hilali dates", "code": "50522907" },
      { "name": "Non GMO khadrawi/khadrawy dates", "code": "50522908" },
      { "name": "Non GMO khalas dates", "code": "50522909" },
      { "name": "Non GMO khustawi dates", "code": "50522910" },
      { "name": "Non GMO khidri dates", "code": "50522911" },
      { "name": "Non GMO medjool/medjul dates", "code": "50522912" },
      { "name": "Non GMO mactoum dates", "code": "50522913" },
      { "name": "Non GMO neghal dates", "code": "50522914" },
      { "name": "Non GMO yatimeh dates", "code": "50522915" },
      { "name": "Non GMO zahidi dates", "code": "50522916" },
      { "name": "Non GMO dragonfruits", "code": "50523000" },
      { "name": "Non GMO pink dragonfruits", "code": "50523001" },
      { "name": "Non GMO yellow dragonfruits", "code": "50523002" },
      { "name": "Non GMO figs", "code": "50523100" },
      { "name": "Non GMO bardajic figs", "code": "50523101" },
      { "name": "Non GMO brown turkey figs", "code": "50523102" },
      { "name": "Non GMO calimyrna figs", "code": "50523103" },
      { "name": "Non GMO conadria figs", "code": "50523104" },
      { "name": "Non GMO dottado figs", "code": "50523105" },
      { "name": "Non GMO kadota figs", "code": "50523106" },
      { "name": "Non GMO mediterranean figs", "code": "50523107" },
      { "name": "Non GMO mission figs", "code": "50523108" },
      { "name": "Non GMO smyrna figs", "code": "50523109" },
      { "name": "Non GMO verdona figs", "code": "50523110" },
      { "name": "Non GMO white king figs", "code": "50523111" },
      { "name": "Non GMO gooseberries", "code": "50523200" },
      { "name": "Non GMO early sulphur gooseberries", "code": "50523201" },
      { "name": "Non GMO goldendrop gooseberries", "code": "50523202" },
      { "name": "Non GMO langley gage gooseberries", "code": "50523203" },
      { "name": "Non GMO leveller gooseberries", "code": "50523204" },
      { "name": "Non GMO london gooseberries", "code": "50523205" },
      { "name": "Non GMO worcestershire gooseberries", "code": "50523206" },
      {
        "name": "Non GMO american worcesterberry gooseberries",
        "code": "50523207"
      },
      { "name": "Non GMO grapefruits", "code": "50523300" },
      { "name": "Non GMO burgundy grapefruits", "code": "50523301" },
      { "name": "Non GMO duncan grapefruits", "code": "50523302" },
      { "name": "Non GMO foster grapefruits", "code": "50523303" },
      { "name": "Non GMO marsh grapefruits", "code": "50523304" },
      { "name": "Non GMO new zealand grapefruits", "code": "50523305" },
      { "name": "Non GMO rio red grapefruits", "code": "50523306" },
      { "name": "Non GMO ruby red grapefruits", "code": "50523307" },
      { "name": "Non GMO star ruby grapefruits", "code": "50523308" },
      { "name": "Non GMO triumph grapefruits", "code": "50523309" },
      { "name": "Non GMO table grapes", "code": "50523400" },
      { "name": "Non GMO alicante grapes", "code": "50523401" },
      { "name": "Non GMO almeria grapes", "code": "50523402" },
      { "name": "Non GMO alphonse lavalle grapes", "code": "50523403" },
      { "name": "Non GMO autumn king grapes", "code": "50523404" },
      { "name": "Non GMO autumn royal grapes", "code": "50523405" },
      { "name": "Non GMO autumn seedless grapes", "code": "50523406" },
      { "name": "Non GMO baresana grapes", "code": "50523407" },
      { "name": "Non GMO barlinka grapes", "code": "50523408" },
      { "name": "Non GMO beauty seedless grapes", "code": "50523409" },
      { "name": "Non GMO black beauty seedless grapes", "code": "50523410" },
      { "name": "Non GMO black emerald grapes", "code": "50523411" },
      { "name": "Non GMO black giant grapes", "code": "50523412" },
      { "name": "Non GMO black globe grapes", "code": "50523413" },
      { "name": "Non GMO black monukka grapes", "code": "50523414" },
      { "name": "Non GMO black pearl grapes", "code": "50523415" },
      { "name": "Non GMO black seedless grapes", "code": "50523416" },
      { "name": "Non GMO bonheur grapes", "code": "50523417" },
      { "name": "Non GMO calmeria grapes", "code": "50523418" },
      { "name": "Non GMO cardinal grapes", "code": "50523419" },
      { "name": "Non GMO catawba grapes", "code": "50523420" },
      {
        "name": "Non GMO chasselas/golden chasselas grapes",
        "code": "50523421"
      },
      { "name": "Non GMO christmas rose grapes", "code": "50523422" },
      { "name": "Non GMO concord grapes", "code": "50523423" },
      { "name": "Non GMO concord seedless grapes", "code": "50523424" },
      { "name": "Non GMO crimson seedless grapes", "code": "50523425" },
      { "name": "Non GMO dauphine grapes", "code": "50523426" },
      { "name": "Non GMO delaware grapes", "code": "50523427" },
      { "name": "Non GMO early muscat grapes", "code": "50523428" },
      { "name": "Non GMO early sweet grapes", "code": "50523429" },
      { "name": "Non GMO emerald seedless grapes", "code": "50523430" },
      { "name": "Non GMO emperatriz grapes", "code": "50523431" },
      { "name": "Non GMO emperor grapes", "code": "50523432" },
      { "name": "Non GMO empress grapes", "code": "50523433" },
      { "name": "Non GMO exotic grapes", "code": "50523434" },
      { "name": "Non GMO fantasy grapes", "code": "50523435" },
      { "name": "Non GMO fantasy seedless grapes", "code": "50523436" },
      { "name": "Non GMO flame grapes", "code": "50523437" },
      { "name": "Non GMO flame seedless grapes", "code": "50523438" },
      { "name": "Non GMO flame tokay grapes", "code": "50523439" },
      { "name": "Non GMO flaming red grapes", "code": "50523440" },
      { "name": "Non GMO galaxy seedless grapes", "code": "50523441" },
      { "name": "Non GMO gamay grapes", "code": "50523442" },
      { "name": "Non GMO gold grapes", "code": "50523443" },
      { "name": "Non GMO hanepoot or honeypot grapes", "code": "50523444" },
      { "name": "Non GMO italia grapes", "code": "50523445" },
      { "name": "Non GMO jade seedless grapes", "code": "50523446" },
      { "name": "Non GMO jubilee grapes", "code": "50523447" },
      { "name": "Non GMO king ruby grapes", "code": "50523448" },
      { "name": "Non GMO kyoho grapes", "code": "50523449" },
      { "name": "Non GMO la rochelle grapes", "code": "50523450" },
      { "name": "Non GMO lady finger grapes", "code": "50523451" },
      { "name": "Non GMO late seedless grapes", "code": "50523452" },
      { "name": "Non GMO majestic seedless grapes", "code": "50523453" },
      { "name": "Non GMO malaga grapes", "code": "50523454" },
      { "name": "Non GMO marroo seedless grapes", "code": "50523455" },
      { "name": "Non GMO muscadine grapes", "code": "50523456" },
      { "name": "Non GMO muscat flame grapes", "code": "50523457" },
      { "name": "Non GMO muscat grapes", "code": "50523458" },
      { "name": "Non GMO muscat seedless grapes", "code": "50523459" },
      { "name": "Non GMO napoleon grapes", "code": "50523460" },
      { "name": "Non GMO negria grapes", "code": "50523461" },
      { "name": "Non GMO new cross grapes", "code": "50523462" },
      { "name": "Non GMO niabell grapes", "code": "50523463" },
      { "name": "Non GMO niagara grapes", "code": "50523464" },
      { "name": "Non GMO olivette grapes", "code": "50523465" },
      { "name": "Non GMO perlette grapes", "code": "50523466" },
      { "name": "Non GMO perlon grapes", "code": "50523467" },
      { "name": "Non GMO prima black seedless grapes", "code": "50523468" },
      { "name": "Non GMO princess grapes", "code": "50523469" },
      { "name": "Non GMO queen grapes", "code": "50523470" },
      { "name": "Non GMO red blush grapes", "code": "50523471" },
      { "name": "Non GMO red globe grapes", "code": "50523472" },
      { "name": "Non GMO red malaga grapes", "code": "50523473" },
      { "name": "Non GMO red seedless grapes", "code": "50523474" },
      { "name": "Non GMO regina grapes", "code": "50523475" },
      { "name": "Non GMO ribier grapes", "code": "50523476" },
      { "name": "Non GMO rosita grapes", "code": "50523477" },
      { "name": "Non GMO rouge grapes", "code": "50523478" },
      { "name": "Non GMO royal black seedless grapes", "code": "50523479" },
      { "name": "Non GMO ruby red seedless grapes", "code": "50523480" },
      { "name": "Non GMO ruby seedless grapes", "code": "50523481" },
      { "name": "Non GMO scarlet royal grapes", "code": "50523482" },
      { "name": "Non GMO scuppernong grapes", "code": "50523483" },
      { "name": "Non GMO sugarose grapes", "code": "50523484" },
      { "name": "Non GMO sugarthirteen grapes", "code": "50523485" },
      { "name": "Non GMO sugraone grapes", "code": "50523486" },
      { "name": "Non GMO sugrasixteen grapes", "code": "50523487" },
      { "name": "Non GMO sultana sun red grapes", "code": "50523488" },
      { "name": "Non GMO summer royal grapes", "code": "50523489" },
      { "name": "Non GMO sunset grapes", "code": "50523490" },
      { "name": "Non GMO superior seedless grapes", "code": "50523491" },
      { "name": "Non GMO thompson seedless grapes", "code": "50523492" },
      { "name": "Non GMO tokay/pinot gris grapes", "code": "50523493" },
      { "name": "Non GMO waltman cross grapes", "code": "50523494" },
      { "name": "Non GMO white seedless grapes", "code": "50523495" },
      { "name": "Non GMO zante current grapes", "code": "50523496" },
      { "name": "Non GMO raisin grapes", "code": "50523500" },
      { "name": "Non GMO black corinth grapes", "code": "50523501" },
      { "name": "Non GMO canner grapes", "code": "50523502" },
      { "name": "Non GMO dovine grapes", "code": "50523503" },
      { "name": "Non GMO fiesta grapes", "code": "50523504" },
      { "name": "Non GMO selma pete grapes", "code": "50523505" },
      { "name": "Non GMO sultana grapes", "code": "50523506" },
      { "name": "Non GMO wine grapes", "code": "50523600" },
      { "name": "Non GMO alicante bouschet grapes", "code": "50523601" },
      { "name": "Non GMO barbera grapes", "code": "50523602" },
      { "name": "Non GMO burger grapes", "code": "50523603" },
      { "name": "Non GMO cabernet franc grapes", "code": "50523604" },
      { "name": "Non GMO cabernet sauvignon grapes", "code": "50523605" },
      { "name": "Non GMO carignane grapes", "code": "50523606" },
      { "name": "Non GMO carnelian grapes", "code": "50523607" },
      { "name": "Non GMO catarratto grapes", "code": "50523608" },
      { "name": "Non GMO centurian grapes", "code": "50523609" },
      { "name": "Non GMO charbono grapes", "code": "50523610" },
      { "name": "Non GMO chardonnay grapes", "code": "50523611" },
      { "name": "Non GMO chenin blanc grapes", "code": "50523612" },
      { "name": "Non GMO cinsaut grapes", "code": "50523613" },
      { "name": "Non GMO dolcetto grapes", "code": "50523614" },
      { "name": "Non GMO emerald riesling grapes", "code": "50523615" },
      { "name": "Non GMO french colombard grapes", "code": "50523616" },
      { "name": "Non GMO gamay or napa grapes", "code": "50523617" },
      { "name": "Non GMO gamay beaujolais grapes", "code": "50523618" },
      { "name": "Non GMO gewurztraminer grapes", "code": "50523619" },
      { "name": "Non GMO grenache grapes", "code": "50523620" },
      { "name": "Non GMO grenache blanc grapes", "code": "50523621" },
      { "name": "Non GMO lagrein grapes", "code": "50523622" },
      { "name": "Non GMO lambrusco grapes", "code": "50523623" },
      { "name": "Non GMO malbec grapes", "code": "50523624" },
      { "name": "Non GMO malvasia bianca grapes", "code": "50523625" },
      { "name": "Non GMO marsanne grapes", "code": "50523626" },
      { "name": "Non GMO mataro grapes", "code": "50523627" },
      { "name": "Non GMO merlot grapes", "code": "50523628" },
      { "name": "Non GMO meunier grapes", "code": "50523629" },
      { "name": "Non GMO mission grapes", "code": "50523630" },
      { "name": "Non GMO montepulciano grapes", "code": "50523631" },
      { "name": "Non GMO muscat blanc grapes", "code": "50523632" },
      { "name": "Non GMO muscat hamburg grapes", "code": "50523633" },
      { "name": "Non GMO muscat of alexandria grapes", "code": "50523634" },
      { "name": "Non GMO muscat orange grapes", "code": "50523635" },
      { "name": "Non GMO nebbiolo grapes", "code": "50523636" },
      { "name": "Non GMO palomino grapes", "code": "50523637" },
      { "name": "Non GMO petit verdot grapes", "code": "50523638" },
      { "name": "Non GMO petite sirah grapes", "code": "50523639" },
      { "name": "Non GMO pinot blanc grapes", "code": "50523640" },
      { "name": "Non GMO pinot gris grapes", "code": "50523641" },
      { "name": "Non GMO pinot noir grapes", "code": "50523642" },
      { "name": "Non GMO primitivo grapes", "code": "50523643" },
      { "name": "Non GMO roussanne grapes", "code": "50523644" },
      { "name": "Non GMO royalty grapes", "code": "50523645" },
      { "name": "Non GMO rubired grapes", "code": "50523646" },
      { "name": "Non GMO ruby cabernet grapes", "code": "50523647" },
      { "name": "Non GMO salvador grapes", "code": "50523648" },
      { "name": "Non GMO sangiovese grapes", "code": "50523649" },
      { "name": "Non GMO sauvignon blanc grapes", "code": "50523650" },
      { "name": "Non GMO sauvignon musque grapes", "code": "50523651" },
      { "name": "Non GMO semillon grapes", "code": "50523652" },
      { "name": "Non GMO souzao grapes", "code": "50523653" },
      { "name": "Non GMO st emilion grapes", "code": "50523654" },
      { "name": "Non GMO symphony grapes", "code": "50523655" },
      { "name": "Non GMO syrah grapes", "code": "50523656" },
      { "name": "Non GMO tannat grapes", "code": "50523657" },
      { "name": "Non GMO tempranillo grapes", "code": "50523658" },
      { "name": "Non GMO teroldego grapes", "code": "50523659" },
      { "name": "Non GMO tocai friulano grapes", "code": "50523660" },
      { "name": "Non GMO touriga nacional grapes", "code": "50523661" },
      { "name": "Non GMO triplett blanc grapes", "code": "50523662" },
      { "name": "Non GMO viognier grapes", "code": "50523663" },
      { "name": "Non GMO white riesling grapes", "code": "50523664" },
      { "name": "Non GMO zinfandel grapes", "code": "50523665" },
      { "name": "Non GMO guavas", "code": "50523700" },
      { "name": "Non GMO beaumont guavas", "code": "50523701" },
      { "name": "Non GMO carrley guavas", "code": "50523702" },
      { "name": "Non GMO lucida guavas", "code": "50523703" },
      { "name": "Non GMO pineapple guavas", "code": "50523704" },
      { "name": "Non GMO huckleberries", "code": "50523800" },
      { "name": "Non GMO black winter huckleberries", "code": "50523801" },
      { "name": "Non GMO cascade huckleberries", "code": "50523802" },
      { "name": "Non GMO dwarf huckleberries", "code": "50523803" },
      { "name": "Non GMO mountain huckleberries", "code": "50523804" },
      { "name": "Non GMO red huckleberries", "code": "50523805" },
      { "name": "Non GMO kiwi fruits", "code": "50523900" },
      { "name": "Non GMO ananasnaja kiwi fruits", "code": "50523901" },
      { "name": "Non GMO arctic beauty kiwi fruits", "code": "50523902" },
      { "name": "Non GMO blake kiwi fruits", "code": "50523903" },
      { "name": "Non GMO hayward kiwi fruits", "code": "50523904" },
      { "name": "Non GMO issai kiwi fruits", "code": "50523905" },
      { "name": "Non GMO siberian kiwi fruits", "code": "50523906" },
      { "name": "Non GMO kumquats", "code": "50524000" },
      { "name": "Non GMO hong kong kumquats", "code": "50524001" },
      { "name": "Non GMO limequat kumquats", "code": "50524002" },
      { "name": "Non GMO long fruit kumquats", "code": "50524003" },
      { "name": "Non GMO malayan kumquats", "code": "50524004" },
      { "name": "Non GMO meiwa kumquats", "code": "50524005" },
      { "name": "Non GMO nagami kumquats", "code": "50524006" },
      { "name": "Non GMO lemons", "code": "50524100" },
      { "name": "Non GMO baboon lemons", "code": "50524101" },
      { "name": "Non GMO bearss sicilian lemons", "code": "50524102" },
      { "name": "Non GMO cameron highlands lemons", "code": "50524103" },
      { "name": "Non GMO escondido lemons", "code": "50524104" },
      { "name": "Non GMO eureka lemons", "code": "50524105" },
      { "name": "Non GMO lisbon lemons", "code": "50524106" },
      { "name": "Non GMO meyer lemons", "code": "50524107" },
      { "name": "Non GMO volkamer lemons", "code": "50524108" },
      { "name": "Non GMO limes", "code": "50524200" },
      { "name": "Non GMO indian sweet limes", "code": "50524201" },
      { "name": "Non GMO key limes", "code": "50524202" },
      { "name": "Non GMO mandarin limes", "code": "50524203" },
      { "name": "Non GMO philippine limes", "code": "50524204" },
      { "name": "Non GMO tahitian limes", "code": "50524205" },
      { "name": "Non GMO bearss limes", "code": "50524206" },
      { "name": "Non GMO persian limes", "code": "50524207" },
      { "name": "Non GMO seedless limes", "code": "50524208" },
      { "name": "Non GMO loquats", "code": "50524300" },
      { "name": "Non GMO advance loquats", "code": "50524301" },
      { "name": "Non GMO benlehr loquats", "code": "50524302" },
      { "name": "Non GMO big jim loquats", "code": "50524303" },
      { "name": "Non GMO champagne loquats", "code": "50524304" },
      { "name": "Non GMO early red loquats", "code": "50524305" },
      { "name": "Non GMO gold nugget loquats", "code": "50524306" },
      { "name": "Non GMO herd's mammoth loquats", "code": "50524307" },
      { "name": "Non GMO mogi loquats", "code": "50524308" },
      { "name": "Non GMO mrs cooksey loquats", "code": "50524309" },
      { "name": "Non GMO strawberry loquats", "code": "50524310" },
      { "name": "Non GMO tanaka loquats", "code": "50524311" },
      { "name": "Non GMO victory vista white loquats", "code": "50524312" },
      { "name": "Non GMO wolfe loquats", "code": "50524313" },
      {
        "name": "Non GMO mandarin oranges or non GMO tangerines",
        "code": "50524400"
      },
      { "name": "Non GMO clauselinas oranges", "code": "50524401" },
      { "name": "Non GMO clementine tangerines", "code": "50524402" },
      { "name": "Non GMO cleopatra mandarin oranges", "code": "50524403" },
      { "name": "Non GMO dancy tangerines", "code": "50524404" },
      { "name": "Non GMO ellensdale oranges", "code": "50524405" },
      { "name": "Non GMO fairchild oranges", "code": "50524406" },
      { "name": "Non GMO fallglo oranges", "code": "50524407" },
      { "name": "Non GMO fortune oranges", "code": "50524408" },
      { "name": "Non GMO fremont mandarin oranges", "code": "50524409" },
      { "name": "Non GMO fremont oranges", "code": "50524410" },
      { "name": "Non GMO golden nugget oranges", "code": "50524411" },
      { "name": "Non GMO honey mandarin oranges", "code": "50524412" },
      { "name": "Non GMO honey oranges", "code": "50524413" },
      { "name": "Non GMO honey tangerines", "code": "50524414" },
      { "name": "Non GMO honeybelle tangelo oranges", "code": "50524415" },
      { "name": "Non GMO king mandarin oranges", "code": "50524416" },
      { "name": "Non GMO kinnow oranges", "code": "50524417" },
      { "name": "Non GMO lee mandarin oranges", "code": "50524418" },
      { "name": "Non GMO makokkee oranges", "code": "50524419" },
      { "name": "Non GMO malvasios oranges", "code": "50524420" },
      { "name": "Non GMO mediterranean mandarin oranges", "code": "50524421" },
      { "name": "Non GMO minneola tangelo oranges", "code": "50524422" },
      { "name": "Non GMO monica oranges", "code": "50524423" },
      { "name": "Non GMO murcott honey oranges", "code": "50524424" },
      { "name": "Non GMO murcott tangors", "code": "50524425" },
      { "name": "Non GMO natsudaidai mandarin oranges", "code": "50524426" },
      { "name": "Non GMO natsumikan mandarin oranges", "code": "50524427" },
      { "name": "Non GMO nocatee tangelo oranges", "code": "50524428" },
      { "name": "Non GMO orlando tangelo oranges", "code": "50524429" },
      { "name": "Non GMO ortanique tangerines", "code": "50524430" },
      { "name": "Non GMO page mandarin oranges", "code": "50524431" },
      { "name": "Non GMO pixie oranges", "code": "50524432" },
      {
        "name": "Non GMO ponkan bantangas mandarin oranges",
        "code": "50524433"
      },
      { "name": "Non GMO reyna oranges", "code": "50524434" },
      { "name": "Non GMO robinson oranges", "code": "50524435" },
      { "name": "Non GMO saltenitas oranges", "code": "50524436" },
      { "name": "Non GMO sampson tangelo oranges", "code": "50524437" },
      { "name": "Non GMO satsuma mandarin oranges", "code": "50524438" },
      { "name": "Non GMO sunburst mandarin oranges", "code": "50524439" },
      { "name": "Non GMO tangelos", "code": "50524440" },
      { "name": "Non GMO tangerina oranges", "code": "50524441" },
      { "name": "Non GMO temple oranges", "code": "50524442" },
      { "name": "Non GMO thornton oranges", "code": "50524443" },
      { "name": "Non GMO wekiwa tangerines", "code": "50524444" },
      { "name": "Non GMO wilkins tangerines", "code": "50524445" },
      {
        "name": "Non GMO willowleaf mediterranean tangerines",
        "code": "50524446"
      },
      { "name": "Non GMO mangoes", "code": "50524500" },
      { "name": "Non GMO alphonso mangoes", "code": "50524501" },
      { "name": "Non GMO ataulfo mangoes", "code": "50524502" },
      { "name": "Non GMO criollo mangoes", "code": "50524503" },
      { "name": "Non GMO edwards mangoes", "code": "50524504" },
      { "name": "Non GMO francine mangoes", "code": "50524505" },
      { "name": "Non GMO francis mangoes", "code": "50524506" },
      { "name": "Non GMO gandaria mangoes", "code": "50524507" },
      { "name": "Non GMO haden mangoes", "code": "50524508" },
      { "name": "Non GMO irwin mangoes", "code": "50524509" },
      { "name": "Non GMO keitt mangoes", "code": "50524510" },
      { "name": "Non GMO kent mangoes", "code": "50524511" },
      { "name": "Non GMO kesar mangoes", "code": "50524512" },
      { "name": "Non GMO kuini mangoes", "code": "50524513" },
      { "name": "Non GMO manila super mangoes", "code": "50524514" },
      { "name": "Non GMO manila mangoes", "code": "50524515" },
      { "name": "Non GMO mayaguez mangoes", "code": "50524516" },
      { "name": "Non GMO mulgoba mangoes", "code": "50524517" },
      { "name": "Non GMO oro mangoes", "code": "50524518" },
      { "name": "Non GMO palmer mangoes", "code": "50524519" },
      { "name": "Non GMO parvin mangoes", "code": "50524520" },
      { "name": "Non GMO sandersha mangoes", "code": "50524521" },
      { "name": "Non GMO sensation mangoes", "code": "50524522" },
      { "name": "Non GMO smith mangoes", "code": "50524523" },
      { "name": "Non GMO tommy atkins mangoes", "code": "50524524" },
      { "name": "Non GMO van dyke mangoes", "code": "50524525" },
      { "name": "Non GMO melons", "code": "50524600" },
      { "name": "Non GMO allsweet melons", "code": "50524601" },
      { "name": "Non GMO athena melons", "code": "50524602" },
      { "name": "Non GMO black diamond melons", "code": "50524603" },
      { "name": "Non GMO cal sweet melons", "code": "50524604" },
      { "name": "Non GMO cantaloupe melons", "code": "50524605" },
      { "name": "Non GMO carnical melons", "code": "50524606" },
      { "name": "Non GMO casaba melons", "code": "50524607" },
      { "name": "Non GMO cavaillon melons", "code": "50524608" },
      { "name": "Non GMO charentais melons", "code": "50524609" },
      { "name": "Non GMO charleston gray watermelons", "code": "50524610" },
      { "name": "Non GMO crenshaw melons", "code": "50524611" },
      { "name": "Non GMO crimson sweet melons", "code": "50524612" },
      { "name": "Non GMO dixie lee melons", "code": "50524613" },
      { "name": "Non GMO eclipse melons", "code": "50524614" },
      { "name": "Non GMO ein d'or melons", "code": "50524615" },
      { "name": "Non GMO fiesta melons", "code": "50524616" },
      { "name": "Non GMO galia melons", "code": "50524617" },
      { "name": "Non GMO gaya melons", "code": "50524618" },
      { "name": "Non GMO hami melons", "code": "50524619" },
      { "name": "Non GMO honeydew melons", "code": "50524620" },
      { "name": "Non GMO icebox melons", "code": "50524621" },
      { "name": "Non GMO ida pride melons", "code": "50524622" },
      { "name": "Non GMO juan canary melons", "code": "50524623" },
      { "name": "Non GMO jubilee melons", "code": "50524624" },
      { "name": "Non GMO jubilation melons", "code": "50524625" },
      { "name": "Non GMO kakhi/kakri melons", "code": "50524626" },
      { "name": "Non GMO kiwano melons", "code": "50524627" },
      { "name": "Non GMO korean melons", "code": "50524628" },
      { "name": "Non GMO long gray melons", "code": "50524629" },
      { "name": "Non GMO mayan melons", "code": "50524630" },
      { "name": "Non GMO micky lee melons", "code": "50524631" },
      { "name": "Non GMO mirage melons", "code": "50524632" },
      { "name": "Non GMO moon and stars watermelon", "code": "50524633" },
      { "name": "Non GMO ogen melons", "code": "50524634" },
      { "name": "Non GMO patriot melons", "code": "50524635" },
      { "name": "Non GMO peacock melons", "code": "50524636" },
      { "name": "Non GMO pepino melons", "code": "50524637" },
      { "name": "Non GMO persian melons", "code": "50524638" },
      { "name": "Non GMO picnic melons", "code": "50524639" },
      { "name": "Non GMO piel de sapo melons", "code": "50524640" },
      { "name": "Non GMO pineapple melons", "code": "50524641" },
      { "name": "Non GMO quetzali melons", "code": "50524642" },
      { "name": "Non GMO red goblin melons", "code": "50524643" },
      { "name": "Non GMO regency melons", "code": "50524644" },
      { "name": "Non GMO royal majestic melons", "code": "50524645" },
      { "name": "Non GMO royal star melons", "code": "50524646" },
      { "name": "Non GMO royal sweet melons", "code": "50524647" },
      { "name": "Non GMO santa claus melons", "code": "50524648" },
      { "name": "Non GMO sharlyn melons", "code": "50524649" },
      { "name": "Non GMO spanish melons", "code": "50524650" },
      { "name": "Non GMO sprite melons", "code": "50524651" },
      { "name": "Non GMO starbright melons", "code": "50524652" },
      { "name": "Non GMO stars n stripes melons", "code": "50524653" },
      { "name": "Non GMO sugar baby melons", "code": "50524654" },
      { "name": "Non GMO sugar baby watermelon", "code": "50524655" },
      { "name": "Non GMO sunsweet melons", "code": "50524656" },
      { "name": "Non GMO sweet heart seedless watermelon", "code": "50524657" },
      { "name": "Non GMO temptation melons", "code": "50524658" },
      { "name": "Non GMO tiger baby melons", "code": "50524659" },
      { "name": "Non GMO tuscan type melons", "code": "50524660" },
      { "name": "Non GMO yellow baby watermelon", "code": "50524661" },
      { "name": "Non GMO mulberries", "code": "50524700" },
      { "name": "Non GMO black mulberries", "code": "50524701" },
      { "name": "Non GMO white mulberries", "code": "50524702" },
      { "name": "Non GMO bayberries and myrtles", "code": "50524800" },
      { "name": "Non GMO bog myrtles", "code": "50524801" },
      { "name": "Non GMO bayberries", "code": "50524802" },
      { "name": "Non GMO nectarines", "code": "50524900" },
      { "name": "Non GMO april glo nectarines", "code": "50524901" },
      { "name": "Non GMO arctic mist nectarines", "code": "50524902" },
      { "name": "Non GMO arctic snow nectarines", "code": "50524903" },
      { "name": "Non GMO arctic star nectarines", "code": "50524904" },
      { "name": "Non GMO arctic sweet nectarines", "code": "50524905" },
      { "name": "Non GMO arctic glo nectarines", "code": "50524906" },
      { "name": "Non GMO august fire nectarines", "code": "50524907" },
      { "name": "Non GMO august pearl nectarines", "code": "50524908" },
      { "name": "Non GMO august red nectarines", "code": "50524909" },
      { "name": "Non GMO autumn star nectarines", "code": "50524910" },
      { "name": "Non GMO big john nectarines", "code": "50524911" },
      { "name": "Non GMO bright pearl nectarines", "code": "50524912" },
      { "name": "Non GMO diamond bright nectarines", "code": "50524913" },
      { "name": "Non GMO diamond ray nectarines", "code": "50524914" },
      { "name": "Non GMO earliglo nectarines", "code": "50524915" },
      { "name": "Non GMO early diamond nectarines", "code": "50524916" },
      { "name": "Non GMO fairlane nectarines", "code": "50524917" },
      { "name": "Non GMO fantasia nectarines", "code": "50524918" },
      { "name": "Non GMO fire pearl nectarines", "code": "50524919" },
      { "name": "Non GMO fire sweet nectarines", "code": "50524920" },
      { "name": "Non GMO flamekist nectarines", "code": "50524921" },
      { "name": "Non GMO flat type nectarines", "code": "50524922" },
      { "name": "Non GMO garden delight nectarines", "code": "50524923" },
      { "name": "Non GMO goldmine nectarines", "code": "50524924" },
      { "name": "Non GMO grand pearl nectarines", "code": "50524925" },
      { "name": "Non GMO hardired nectarines", "code": "50524926" },
      { "name": "Non GMO honey blaze nectarines", "code": "50524927" },
      { "name": "Non GMO july red nectarines", "code": "50524928" },
      { "name": "Non GMO kay pearl nectarines", "code": "50524929" },
      { "name": "Non GMO kay sweet nectarines", "code": "50524930" },
      { "name": "Non GMO may diamond nectarines", "code": "50524931" },
      { "name": "Non GMO mayfire nectarines", "code": "50524932" },
      { "name": "Non GMO mayglo nectarines", "code": "50524933" },
      { "name": "Non GMO mericrest nectarines", "code": "50524934" },
      { "name": "Non GMO red diamond nectarines", "code": "50524935" },
      { "name": "Non GMO red gold nectarines", "code": "50524936" },
      { "name": "Non GMO red jim nectarines", "code": "50524937" },
      { "name": "Non GMO red roy nectarines", "code": "50524938" },
      { "name": "Non GMO rio red nectarines", "code": "50524939" },
      { "name": "Non GMO rose diamond nectarines", "code": "50524940" },
      { "name": "Non GMO royal glo nectarines", "code": "50524941" },
      { "name": "Non GMO ruby diamond nectarines", "code": "50524942" },
      { "name": "Non GMO ruby sweet nectarines", "code": "50524943" },
      { "name": "Non GMO ruddy jewel nectarines", "code": "50524944" },
      { "name": "Non GMO september red nectarines", "code": "50524945" },
      { "name": "Non GMO snowqueen nectarines", "code": "50524946" },
      { "name": "Non GMO spring bright nectarines", "code": "50524947" },
      { "name": "Non GMO spring red nectarines", "code": "50524948" },
      { "name": "Non GMO summer blush nectarines", "code": "50524949" },
      { "name": "Non GMO summer brite nectarines", "code": "50524950" },
      { "name": "Non GMO summer diamond nectarines", "code": "50524951" },
      { "name": "Non GMO summer fire nectarines", "code": "50524952" },
      { "name": "Non GMO summer grand nectarines", "code": "50524953" },
      { "name": "Non GMO sunglo nectarines", "code": "50524954" },
      { "name": "Non GMO zee fire nectarines", "code": "50524955" },
      { "name": "Non GMO zee glo nectarines", "code": "50524956" },
      { "name": "Non GMO zeegrand nectarines", "code": "50524957" },
      { "name": "Non GMO oranges", "code": "50525000" },
      { "name": "Non GMO african sour oranges", "code": "50525001" },
      { "name": "Non GMO ambersweet oranges", "code": "50525002" },
      { "name": "Non GMO argentine sour oranges", "code": "50525003" },
      { "name": "Non GMO bahianinha oranges", "code": "50525004" },
      { "name": "Non GMO bergamot oranges", "code": "50525005" },
      { "name": "Non GMO berna oranges", "code": "50525006" },
      { "name": "Non GMO bigaradier apepu oranges", "code": "50525007" },
      { "name": "Non GMO bittersweet daidai oranges", "code": "50525008" },
      { "name": "Non GMO blonde oranges", "code": "50525009" },
      { "name": "Non GMO blood oranges", "code": "50525010" },
      { "name": "Non GMO california navel oranges", "code": "50525011" },
      { "name": "Non GMO cara cara oranges", "code": "50525012" },
      { "name": "Non GMO chinotto oranges", "code": "50525013" },
      { "name": "Non GMO dream navel oranges", "code": "50525014" },
      { "name": "Non GMO gou tou oranges", "code": "50525015" },
      { "name": "Non GMO hamlin oranges", "code": "50525016" },
      { "name": "Non GMO jaffa oranges", "code": "50525017" },
      { "name": "Non GMO jincheng oranges", "code": "50525018" },
      { "name": "Non GMO k-early oranges", "code": "50525019" },
      { "name": "Non GMO kona oranges", "code": "50525020" },
      { "name": "Non GMO late navel oranges", "code": "50525021" },
      { "name": "Non GMO late valencia oranges", "code": "50525022" },
      { "name": "Non GMO limequat oranges", "code": "50525023" },
      { "name": "Non GMO marr oranges", "code": "50525024" },
      { "name": "Non GMO melogold oranges", "code": "50525025" },
      { "name": "Non GMO moro oranges", "code": "50525026" },
      { "name": "Non GMO moro tarocco oranges", "code": "50525027" },
      { "name": "Non GMO navel oranges", "code": "50525028" },
      { "name": "Non GMO navelina oranges", "code": "50525029" },
      { "name": "Non GMO oro blanco oranges", "code": "50525030" },
      { "name": "Non GMO osceola oranges", "code": "50525031" },
      { "name": "Non GMO parson brown oranges", "code": "50525032" },
      { "name": "Non GMO pera oranges", "code": "50525033" },
      { "name": "Non GMO pummulo oranges", "code": "50525034" },
      { "name": "Non GMO rhode red oranges", "code": "50525035" },
      { "name": "Non GMO roble oranges", "code": "50525036" },
      { "name": "Non GMO salustianas oranges", "code": "50525037" },
      { "name": "Non GMO sanguine oranges", "code": "50525038" },
      { "name": "Non GMO sanguinelli oranges", "code": "50525039" },
      { "name": "Non GMO seville oranges", "code": "50525040" },
      { "name": "Non GMO shamouti jaffa oranges", "code": "50525041" },
      { "name": "Non GMO tunis oranges", "code": "50525042" },
      { "name": "Non GMO valencia oranges", "code": "50525043" },
      { "name": "Non GMO washington navel oranges", "code": "50525044" },
      { "name": "Non GMO papayas", "code": "50525100" },
      { "name": "Non GMO green cooking papayas", "code": "50525101" },
      { "name": "Non GMO maradol papayas", "code": "50525102" },
      { "name": "Non GMO mexican yellow papayas", "code": "50525103" },
      { "name": "Non GMO mountain papayas", "code": "50525104" },
      { "name": "Non GMO solo papayas", "code": "50525105" },
      { "name": "Non GMO tainung papayas", "code": "50525106" },
      { "name": "Non GMO passion fruits", "code": "50525200" },
      { "name": "Non GMO banana passion fruits", "code": "50525201" },
      { "name": "Non GMO blue passion flowers", "code": "50525202" },
      { "name": "Non GMO crackerjack passion fruits", "code": "50525203" },
      { "name": "Non GMO giant granadilla passion fruits", "code": "50525204" },
      {
        "name": "Non GMO golden granadilla passion fruits",
        "code": "50525205"
      },
      { "name": "Non GMO maypops passion fruits", "code": "50525206" },
      { "name": "Non GMO red granadilla passion fruits", "code": "50525207" },
      { "name": "Non GMO sweet granadilla passion fruits", "code": "50525208" },
      { "name": "Non GMO water lemon passion fruits", "code": "50525209" },
      { "name": "Non GMO wing-stemmed passion flowers", "code": "50525210" },
      { "name": "Non GMO peaches", "code": "50525300" },
      { "name": "Non GMO amber crest peaches", "code": "50525301" },
      { "name": "Non GMO april snow peaches", "code": "50525302" },
      { "name": "Non GMO august lady peaches", "code": "50525303" },
      { "name": "Non GMO autumn flame peaches", "code": "50525304" },
      { "name": "Non GMO autumn lady peaches", "code": "50525305" },
      { "name": "Non GMO babcock peaches", "code": "50525306" },
      { "name": "Non GMO brittney lane peaches", "code": "50525307" },
      { "name": "Non GMO cary mac peaches", "code": "50525308" },
      { "name": "Non GMO classic peaches", "code": "50525309" },
      { "name": "Non GMO country sweet peaches", "code": "50525310" },
      { "name": "Non GMO crest haven peaches", "code": "50525311" },
      { "name": "Non GMO crimson lady peaches", "code": "50525312" },
      { "name": "Non GMO crown princess peaches", "code": "50525313" },
      { "name": "Non GMO david sun peaches", "code": "50525314" },
      { "name": "Non GMO diamond princess peaches", "code": "50525315" },
      { "name": "Non GMO earlirich peaches", "code": "50525316" },
      { "name": "Non GMO early majestic peaches", "code": "50525317" },
      { "name": "Non GMO early treat peaches", "code": "50525318" },
      { "name": "Non GMO elegant lady peaches", "code": "50525319" },
      { "name": "Non GMO empress peaches", "code": "50525320" },
      { "name": "Non GMO encore peaches", "code": "50525321" },
      { "name": "Non GMO fancy lady peaches", "code": "50525322" },
      { "name": "Non GMO fire prince peaches", "code": "50525323" },
      { "name": "Non GMO flame crest peaches", "code": "50525324" },
      { "name": "Non GMO flat type peaches", "code": "50525325" },
      { "name": "Non GMO flavorcrest peaches", "code": "50525326" },
      { "name": "Non GMO florida prince peaches", "code": "50525327" },
      { "name": "Non GMO full moon peaches", "code": "50525328" },
      { "name": "Non GMO harvester peaches", "code": "50525329" },
      { "name": "Non GMO ice princess peaches", "code": "50525330" },
      { "name": "Non GMO ivory princess peaches", "code": "50525331" },
      { "name": "Non GMO jersey queen peaches", "code": "50525332" },
      { "name": "Non GMO john henry peaches", "code": "50525333" },
      { "name": "Non GMO june prince peaches", "code": "50525334" },
      { "name": "Non GMO kaweah peaches", "code": "50525335" },
      { "name": "Non GMO klondike peaches", "code": "50525336" },
      { "name": "Non GMO lindo peaches", "code": "50525337" },
      { "name": "Non GMO loring peaches", "code": "50525338" },
      { "name": "Non GMO majestic peaches", "code": "50525339" },
      { "name": "Non GMO o'henry peaches", "code": "50525340" },
      { "name": "Non GMO queencrest peaches", "code": "50525341" },
      { "name": "Non GMO red lady peaches", "code": "50525342" },
      { "name": "Non GMO redglobe peaches", "code": "50525343" },
      { "name": "Non GMO redhaven peaches", "code": "50525344" },
      { "name": "Non GMO redtop peaches", "code": "50525345" },
      { "name": "Non GMO regina peaches", "code": "50525346" },
      { "name": "Non GMO rich lady peaches", "code": "50525347" },
      { "name": "Non GMO rich may peaches", "code": "50525348" },
      { "name": "Non GMO royal glory peaches", "code": "50525349" },
      { "name": "Non GMO royal lady peaches", "code": "50525350" },
      { "name": "Non GMO september snow peaches", "code": "50525351" },
      { "name": "Non GMO september sun peaches", "code": "50525352" },
      { "name": "Non GMO sierra gem peaches", "code": "50525353" },
      { "name": "Non GMO snow angel peaches", "code": "50525354" },
      { "name": "Non GMO snow gem peaches", "code": "50525355" },
      { "name": "Non GMO snow king peaches", "code": "50525356" },
      { "name": "Non GMO spring lady peaches", "code": "50525357" },
      { "name": "Non GMO spring snow peaches", "code": "50525358" },
      { "name": "Non GMO springcrest peaches", "code": "50525359" },
      { "name": "Non GMO sugar giant peaches", "code": "50525360" },
      { "name": "Non GMO sugar lady peaches", "code": "50525361" },
      { "name": "Non GMO sun bright peaches", "code": "50525362" },
      { "name": "Non GMO sunhigh peaches", "code": "50525363" },
      { "name": "Non GMO super lady peaches", "code": "50525364" },
      { "name": "Non GMO super rich peaches", "code": "50525365" },
      { "name": "Non GMO surecrop peaches", "code": "50525366" },
      { "name": "Non GMO sweet dream peaches", "code": "50525367" },
      { "name": "Non GMO sweet september peaches", "code": "50525368" },
      { "name": "Non GMO vista peaches", "code": "50525369" },
      { "name": "Non GMO white lady peaches", "code": "50525370" },
      { "name": "Non GMO zee lady peaches", "code": "50525371" },
      { "name": "Non GMO pears", "code": "50525400" },
      { "name": "Non GMO abate fetel pears", "code": "50525401" },
      { "name": "Non GMO anjou pears", "code": "50525402" },
      { "name": "Non GMO asian pears", "code": "50525403" },
      { "name": "Non GMO bartlett pears", "code": "50525404" },
      { "name": "Non GMO best ever pears", "code": "50525405" },
      { "name": "Non GMO beth pears", "code": "50525406" },
      { "name": "Non GMO beurr\u00e9 pears", "code": "50525407" },
      { "name": "Non GMO bosc pears", "code": "50525408" },
      { "name": "Non GMO clapp favorite pears", "code": "50525409" },
      { "name": "Non GMO comice pears", "code": "50525410" },
      { "name": "Non GMO concorde pears", "code": "50525411" },
      { "name": "Non GMO conference pears", "code": "50525412" },
      { "name": "Non GMO crimson red pears", "code": "50525413" },
      { "name": "Non GMO d'anjou pears", "code": "50525414" },
      { "name": "Non GMO dr jules guyot pears", "code": "50525415" },
      { "name": "Non GMO early pears", "code": "50525416" },
      { "name": "Non GMO emperor brown pears", "code": "50525417" },
      { "name": "Non GMO forelle pears", "code": "50525418" },
      { "name": "Non GMO french butter pears", "code": "50525419" },
      { "name": "Non GMO glou morceau pears", "code": "50525420" },
      { "name": "Non GMO hosui pears", "code": "50525421" },
      { "name": "Non GMO italian butter pears", "code": "50525422" },
      { "name": "Non GMO jargonelle pears", "code": "50525423" },
      { "name": "Non GMO juno pears", "code": "50525424" },
      {
        "name": "Non GMO kaiserlouise bonne de jersey pears",
        "code": "50525425"
      },
      { "name": "Non GMO keiffer pears", "code": "50525426" },
      { "name": "Non GMO kings royal pears", "code": "50525427" },
      { "name": "Non GMO limonera pears", "code": "50525428" },
      { "name": "Non GMO merton pride pears", "code": "50525429" },
      { "name": "Non GMO mountain bartlett pears", "code": "50525430" },
      { "name": "Non GMO olivier de serres pears", "code": "50525431" },
      { "name": "Non GMO onward pears", "code": "50525432" },
      { "name": "Non GMO packham's triumph pears", "code": "50525433" },
      { "name": "Non GMO paraiso pears", "code": "50525434" },
      { "name": "Non GMO passe crasanne pears", "code": "50525435" },
      { "name": "Non GMO perry pears", "code": "50525436" },
      { "name": "Non GMO red bartlett pears", "code": "50525437" },
      { "name": "Non GMO red d'anjou pears", "code": "50525438" },
      { "name": "Non GMO rocha pears", "code": "50525439" },
      { "name": "Non GMO rosey red pears", "code": "50525440" },
      { "name": "Non GMO rosy red pears", "code": "50525441" },
      { "name": "Non GMO royal majestic pears", "code": "50525442" },
      { "name": "Non GMO ruby red pears", "code": "50525443" },
      { "name": "Non GMO santa maria pears", "code": "50525444" },
      { "name": "Non GMO seckel pears", "code": "50525445" },
      { "name": "Non GMO sensation pears", "code": "50525446" },
      { "name": "Non GMO star crimson pears", "code": "50525447" },
      { "name": "Non GMO stark crimson pears", "code": "50525448" },
      { "name": "Non GMO summer bartlett pears", "code": "50525449" },
      { "name": "Non GMO summer gold pears", "code": "50525450" },
      { "name": "Non GMO sun gold pears", "code": "50525451" },
      { "name": "Non GMO sunsprite pears", "code": "50525452" },
      { "name": "Non GMO taylors gold pears", "code": "50525453" },
      { "name": "Non GMO taylors red pears", "code": "50525454" },
      { "name": "Non GMO tientsin pears", "code": "50525455" },
      { "name": "Non GMO tosca pears", "code": "50525456" },
      { "name": "Non GMO warden pears", "code": "50525457" },
      { "name": "Non GMO williams bon chretien pears", "code": "50525458" },
      { "name": "Non GMO williams pears", "code": "50525459" },
      { "name": "Non GMO winter nelis pears", "code": "50525460" },
      { "name": "Non GMO persimmons", "code": "50525500" },
      { "name": "Non GMO american persimmon", "code": "50525501" },
      { "name": "Non GMO black sapote persimmon", "code": "50525502" },
      { "name": "Non GMO chapote/black persimmon", "code": "50525503" },
      { "name": "Non GMO date plum persimmon", "code": "50525504" },
      { "name": "Non GMO fuyu persimmons", "code": "50525505" },
      { "name": "Non GMO giant fuyu persimmons", "code": "50525506" },
      { "name": "Non GMO hachiya persimmons", "code": "50525507" },
      { "name": "Non GMO mabolo/butter fruit persimmon", "code": "50525508" },
      { "name": "Non GMO principe ito persimmons", "code": "50525509" },
      { "name": "Non GMO royal brillante persimmons", "code": "50525510" },
      { "name": "Non GMO sharon fruit persimmon", "code": "50525511" },
      { "name": "Non GMO triumph persimmons", "code": "50525512" },
      { "name": "Non GMO pineapples", "code": "50525600" },
      { "name": "Non GMO cherimoya pineapple", "code": "50525601" },
      { "name": "Non GMO golden pineapple", "code": "50525602" },
      { "name": "Non GMO hilo pineapple", "code": "50525603" },
      { "name": "Non GMO kona sugarloaf pineapple", "code": "50525604" },
      { "name": "Non GMO natal queen pineapple", "code": "50525605" },
      { "name": "Non GMO pernambuco pineapple", "code": "50525606" },
      { "name": "Non GMO red spanish pineapple", "code": "50525607" },
      { "name": "Non GMO smooth cayenne pineapple", "code": "50525608" },
      { "name": "Non GMO sugarloaf pineapple", "code": "50525609" },
      { "name": "Non GMO variegated pineapple", "code": "50525610" },
      { "name": "Non GMO plucots", "code": "50525700" },
      { "name": "Non GMO black kat plucot", "code": "50525701" },
      { "name": "Non GMO blue gusto plucot", "code": "50525702" },
      { "name": "Non GMO crimson heart plucot", "code": "50525703" },
      { "name": "Non GMO dapple dandy plucot", "code": "50525704" },
      { "name": "Non GMO dapple fire plucot", "code": "50525705" },
      { "name": "Non GMO early dapple plucot", "code": "50525706" },
      { "name": "Non GMO flavor fall plucot", "code": "50525707" },
      { "name": "Non GMO flavor gold plucot", "code": "50525708" },
      { "name": "Non GMO flavor grenade plucot", "code": "50525709" },
      { "name": "Non GMO flavor heart plucot", "code": "50525710" },
      { "name": "Non GMO flavor jewel plucot", "code": "50525711" },
      { "name": "Non GMO flavor king plucot", "code": "50525712" },
      { "name": "Non GMO flavor queen plucot", "code": "50525713" },
      { "name": "Non GMO flavor supreme plucot", "code": "50525714" },
      { "name": "Non GMO flavor treat plucot", "code": "50525715" },
      { "name": "Non GMO flavorella plucot", "code": "50525716" },
      { "name": "Non GMO flavorich plucot", "code": "50525717" },
      { "name": "Non GMO flavorosa plucot", "code": "50525718" },
      { "name": "Non GMO geo pride plucot", "code": "50525719" },
      { "name": "Non GMO red kat plucot", "code": "50525720" },
      { "name": "Non GMO royal treat plucot", "code": "50525721" },
      { "name": "Non GMO sierra rose plucot", "code": "50525722" },
      { "name": "Non GMO sweet geisha plucot", "code": "50525723" },
      { "name": "Non GMO plums", "code": "50525800" },
      { "name": "Non GMO amber jewel plums", "code": "50525801" },
      { "name": "Non GMO angeleno plums", "code": "50525802" },
      { "name": "Non GMO aurora plums", "code": "50525803" },
      { "name": "Non GMO autumn beaut plums", "code": "50525804" },
      { "name": "Non GMO autumn giant plums", "code": "50525805" },
      { "name": "Non GMO autumn pride plums", "code": "50525806" },
      { "name": "Non GMO autumn rosa plums", "code": "50525807" },
      { "name": "Non GMO beach plum", "code": "50525808" },
      { "name": "Non GMO betty anne plums", "code": "50525809" },
      { "name": "Non GMO black beaut plums", "code": "50525810" },
      { "name": "Non GMO black bullace plum", "code": "50525811" },
      { "name": "Non GMO black diamond plums", "code": "50525812" },
      { "name": "Non GMO black giant plums", "code": "50525813" },
      { "name": "Non GMO black ice plums", "code": "50525814" },
      { "name": "Non GMO black splendor plums", "code": "50525815" },
      { "name": "Non GMO blackamber plums", "code": "50525816" },
      { "name": "Non GMO burgundy plums", "code": "50525817" },
      { "name": "Non GMO carlsbad plum", "code": "50525818" },
      { "name": "Non GMO casselman plums", "code": "50525819" },
      { "name": "Non GMO catalina plums", "code": "50525820" },
      { "name": "Non GMO damson plum", "code": "50525821" },
      { "name": "Non GMO dolly plums", "code": "50525822" },
      { "name": "Non GMO earliqueen plums", "code": "50525823" },
      { "name": "Non GMO early rosa plums", "code": "50525824" },
      { "name": "Non GMO ebony may plums", "code": "50525825" },
      { "name": "Non GMO ebony plums", "code": "50525826" },
      { "name": "Non GMO elephant heart plums", "code": "50525827" },
      { "name": "Non GMO emerald beaut plums", "code": "50525828" },
      { "name": "Non GMO empress plums", "code": "50525829" },
      { "name": "Non GMO freedom plums", "code": "50525830" },
      { "name": "Non GMO friar plums", "code": "50525831" },
      { "name": "Non GMO gar red plums", "code": "50525832" },
      { "name": "Non GMO governor's plum", "code": "50525833" },
      { "name": "Non GMO grand rosa plums", "code": "50525834" },
      { "name": "Non GMO green gage plum", "code": "50525835" },
      { "name": "Non GMO greengage plums", "code": "50525836" },
      { "name": "Non GMO hiromi plums", "code": "50525837" },
      { "name": "Non GMO hiromi red plums", "code": "50525838" },
      { "name": "Non GMO holiday plums", "code": "50525839" },
      { "name": "Non GMO howard sun plums", "code": "50525840" },
      { "name": "Non GMO interspecific type plums", "code": "50525841" },
      { "name": "Non GMO jamaican plum", "code": "50525842" },
      { "name": "Non GMO joanna red plums", "code": "50525843" },
      { "name": "Non GMO kelsey plums", "code": "50525844" },
      { "name": "Non GMO king james plums", "code": "50525845" },
      { "name": "Non GMO laroda plums", "code": "50525846" },
      { "name": "Non GMO late rosa plums", "code": "50525847" },
      { "name": "Non GMO linda rosa plums", "code": "50525848" },
      { "name": "Non GMO lone star red plums", "code": "50525849" },
      { "name": "Non GMO mariposa plums", "code": "50525850" },
      { "name": "Non GMO marked black plums", "code": "50525851" },
      { "name": "Non GMO marked red plums", "code": "50525852" },
      { "name": "Non GMO mirabelle plum", "code": "50525853" },
      { "name": "Non GMO october sun plums", "code": "50525854" },
      { "name": "Non GMO owen t plums", "code": "50525855" },
      { "name": "Non GMO perdrigon plum", "code": "50525856" },
      { "name": "Non GMO pink delight plums", "code": "50525857" },
      { "name": "Non GMO president plums", "code": "50525858" },
      { "name": "Non GMO primetime plums", "code": "50525859" },
      { "name": "Non GMO purple majesty plums", "code": "50525860" },
      { "name": "Non GMO queen rosa plums", "code": "50525861" },
      { "name": "Non GMO quetsch plum", "code": "50525862" },
      { "name": "Non GMO red beaut plums", "code": "50525863" },
      { "name": "Non GMO red lane plums", "code": "50525864" },
      { "name": "Non GMO red ram plums", "code": "50525865" },
      { "name": "Non GMO red rosa plums", "code": "50525866" },
      { "name": "Non GMO rich red plums", "code": "50525867" },
      { "name": "Non GMO rosemary plums", "code": "50525868" },
      { "name": "Non GMO royal diamond plums", "code": "50525869" },
      { "name": "Non GMO royal red plums", "code": "50525870" },
      { "name": "Non GMO royal zee plums", "code": "50525871" },
      { "name": "Non GMO roysum plums", "code": "50525872" },
      { "name": "Non GMO santa rosa plums", "code": "50525873" },
      { "name": "Non GMO saphire plums", "code": "50525874" },
      { "name": "Non GMO sloe plum", "code": "50525875" },
      { "name": "Non GMO st catherine plum", "code": "50525876" },
      { "name": "Non GMO white bullace plum", "code": "50525877" },
      { "name": "Non GMO pommegranates", "code": "50525900" },
      { "name": "Non GMO foothill pommegranates", "code": "50525901" },
      { "name": "Non GMO granada pommegranates", "code": "50525902" },
      { "name": "Non GMO jolly red pommegranates", "code": "50525903" },
      { "name": "Non GMO nana pommegranates", "code": "50525904" },
      { "name": "Non GMO pat's red pommegranates", "code": "50525905" },
      { "name": "Non GMO pinkhan pommegranates", "code": "50525906" },
      { "name": "Non GMO purple velvet pommegranates", "code": "50525907" },
      { "name": "Non GMO wonderful pommegranates", "code": "50525908" },
      { "name": "Non GMO pomelos", "code": "50526000" },
      { "name": "Non GMO chandler pomelo", "code": "50526001" },
      { "name": "Non GMO hirado buntan pomelo", "code": "50526002" },
      { "name": "Non GMO liang ping yau pomelo", "code": "50526003" },
      { "name": "Non GMO pandan wangi pomelo", "code": "50526004" },
      { "name": "Non GMO pink pomelo", "code": "50526005" },
      { "name": "Non GMO red shaddock pomelo", "code": "50526006" },
      { "name": "Non GMO siamese sweet pomelo", "code": "50526007" },
      { "name": "Non GMO wainwright pomelo", "code": "50526008" },
      { "name": "Non GMO quinces", "code": "50526100" },
      { "name": "Non GMO champion quince", "code": "50526101" },
      { "name": "Non GMO pineapple quince", "code": "50526102" },
      { "name": "Non GMO smyrna quince", "code": "50526103" },
      { "name": "Non GMO raspberries", "code": "50526200" },
      { "name": "Non GMO american red raspberry", "code": "50526201" },
      { "name": "Non GMO bailey queensland raspberry", "code": "50526202" },
      { "name": "Non GMO black raspberry", "code": "50526203" },
      { "name": "Non GMO dark raspberry", "code": "50526204" },
      { "name": "Non GMO delicious raspberry", "code": "50526205" },
      { "name": "Non GMO focke dwarf raspberry", "code": "50526206" },
      { "name": "Non GMO focke grayleaf red raspberry", "code": "50526207" },
      { "name": "Non GMO focke strawberry raspberry", "code": "50526208" },
      {
        "name": "Non GMO focke yellow himalayan raspberry",
        "code": "50526209"
      },
      { "name": "Non GMO gold raspberry", "code": "50526210" },
      { "name": "Non GMO gray new mexico raspberry", "code": "50526211" },
      { "name": "Non GMO jepson whitebark raspberry", "code": "50526212" },
      { "name": "Non GMO kellogg san diego raspberry", "code": "50526213" },
      { "name": "Non GMO leucodermis whitebark raspberry", "code": "50526214" },
      { "name": "Non GMO munz cuyamaca raspberry", "code": "50526215" },
      { "name": "Non GMO peck barton's raspberry", "code": "50526216" },
      { "name": "Non GMO purpleflowering raspberry", "code": "50526217" },
      { "name": "Non GMO roadside raspberry", "code": "50526218" },
      { "name": "Non GMO san diego raspberry", "code": "50526219" },
      { "name": "Non GMO snow raspberry", "code": "50526220" },
      { "name": "Non GMO snowpeaks raspberry", "code": "50526221" },
      { "name": "Non GMO strawberryleaf raspberry", "code": "50526222" },
      { "name": "Non GMO sweet cultivated raspberry", "code": "50526223" },
      {
        "name": "Non GMO torr and gray whitebark raspberry",
        "code": "50526224"
      },
      { "name": "Non GMO west indian raspberry", "code": "50526225" },
      { "name": "Non GMO whitebark raspberry", "code": "50526226" },
      { "name": "Non GMO wine raspberry", "code": "50526227" },
      { "name": "Non GMO yellow himalayan raspberry", "code": "50526228" },
      { "name": "Non GMO yu-shan raspberry", "code": "50526229" },
      { "name": "Non GMO rhubarbs", "code": "50526300" },
      { "name": "Non GMO crimson red rhubarb", "code": "50526301" },
      { "name": "Non GMO early champagne rhubarb", "code": "50526302" },
      { "name": "Non GMO glaskin's perpetual rhubarb", "code": "50526303" },
      { "name": "Non GMO sutton rhubarb", "code": "50526304" },
      { "name": "Non GMO timperley early rhubarb", "code": "50526305" },
      { "name": "Non GMO valentine rhubarb", "code": "50526306" },
      { "name": "Non GMO victoria rhubarb", "code": "50526307" },
      { "name": "Non GMO zwolle seedling rhubarb", "code": "50526308" },
      { "name": "Non GMO macdonald rhubarb", "code": "50526309" },
      { "name": "Non GMO tilden rhubarb", "code": "50526310" },
      { "name": "Non GMO rose hips", "code": "50526400" },
      { "name": "Non GMO brier rose hips", "code": "50526401" },
      { "name": "Non GMO elgantine rose hips", "code": "50526402" },
      { "name": "Non GMO rugosa rose hips", "code": "50526403" },
      { "name": "Non GMO scotch or burnet rose hips", "code": "50526404" },
      { "name": "Non GMO sapotes", "code": "50526500" },
      { "name": "Non GMO white sapotes", "code": "50526501" },
      { "name": "Non GMO saskatoon berries", "code": "50526600" },
      { "name": "Non GMO honeywood saskatoon berries", "code": "50526601" },
      { "name": "Non GMO northline saskatoon berries", "code": "50526602" },
      { "name": "Non GMO smoky saskatoon berries", "code": "50526603" },
      { "name": "Non GMO thiessen saskatoon berries", "code": "50526604" },
      { "name": "Non GMO strawberries", "code": "50526700" },
      { "name": "Non GMO chandler strawberries", "code": "50526701" },
      { "name": "Non GMO june bearing strawberries", "code": "50526702" },
      { "name": "Non GMO ever bearing strawberries", "code": "50526703" },
      { "name": "Non GMO sugar apples", "code": "50526800" },
      { "name": "Non GMO kampong mauve sugar apples", "code": "50526801" },
      { "name": "Non GMO seedless sugar apples", "code": "50526802" },
      { "name": "Non GMO thai lessard sugar apples", "code": "50526803" },
      { "name": "Non GMO tamarillos", "code": "50526900" },
      { "name": "Non GMO amberlea gold tamarillos", "code": "50526901" },
      { "name": "Non GMO bold gold tamarillos", "code": "50526902" },
      { "name": "Non GMO goldmine tamarillos", "code": "50526903" },
      { "name": "Non GMO oratia red tamarillos", "code": "50526904" },
      { "name": "Non GMO red beau tamarillos", "code": "50526905" },
      { "name": "Non GMO red delight tamarillos", "code": "50526906" },
      { "name": "Nominant non GMO fruits", "code": "50527000" },
      { "name": "Non GMO akees", "code": "50527001" },
      { "name": "Non GMO babacos", "code": "50527002" },
      { "name": "Non GMO banana flowers", "code": "50527003" },
      { "name": "Non GMO baobabs", "code": "50527004" },
      { "name": "Non GMO bitter oranges", "code": "50527005" },
      { "name": "Non GMO canistels", "code": "50527006" },
      { "name": "Non GMO cloudberries", "code": "50527007" },
      { "name": "Non GMO coconuts", "code": "50527008" },
      { "name": "Non GMO dewberries", "code": "50527009" },
      { "name": "Non GMO durian", "code": "50527010" },
      { "name": "Non GMO elderberries", "code": "50527011" },
      { "name": "Non GMO feijoa", "code": "50527012" },
      { "name": "Non GMO hackberries", "code": "50527013" },
      { "name": "Non GMO hawthorn", "code": "50527014" },
      { "name": "Non GMO honeyberries", "code": "50527015" },
      { "name": "Non GMO jackfruit", "code": "50527016" },
      { "name": "Non GMO jambolan", "code": "50527017" },
      { "name": "Non GMO jujube", "code": "50527018" },
      { "name": "Non GMO lychee", "code": "50527019" },
      { "name": "Non GMO mangosteens", "code": "50527020" },
      { "name": "Non GMO medlars", "code": "50527021" },
      { "name": "Non GMO mombins", "code": "50527022" },
      { "name": "Non GMO monstera", "code": "50527023" },
      { "name": "Non GMO pepinos", "code": "50527024" },
      { "name": "Non GMO plantains", "code": "50527025" },
      { "name": "Non GMO prickly pears", "code": "50527026" },
      { "name": "Non GMO quenepas", "code": "50527027" },
      { "name": "Non GMO rambutan", "code": "50527028" },
      { "name": "Non GMO rose apples", "code": "50527029" },
      { "name": "Non GMO roselle", "code": "50527030" },
      { "name": "Non GMO rowanberries", "code": "50527031" },
      { "name": "Non GMO sea buckhorn berries", "code": "50527032" },
      { "name": "Non GMO silverberries", "code": "50527033" },
      { "name": "Non GMO sorb berries", "code": "50527034" },
      { "name": "Non GMO soursops", "code": "50527035" },
      { "name": "Non GMO star apples", "code": "50527036" },
      { "name": "Non GMO tamarindo", "code": "50527037" },
      { "name": "Non GMO chokeberries", "code": "50527100" },
      { "name": "Non GMO autumn magic chokeberries", "code": "50527101" },
      { "name": "Non GMO brillantisima chokeberries", "code": "50527102" },
      { "name": "Non GMO nero chokeberries", "code": "50527103" },
      { "name": "Non GMO viking chokeberries", "code": "50527104" },
      { "name": "Non GMO olives", "code": "50527200" },
      { "name": "Non GMO agrinion olives", "code": "50527201" },
      { "name": "Non GMO aleppo olives", "code": "50527202" },
      { "name": "Non GMO alphonso olives", "code": "50527203" },
      { "name": "Non GMO amphissa olives", "code": "50527204" },
      { "name": "Non GMO arauco olives", "code": "50527205" },
      { "name": "Non GMO arbequina olives", "code": "50527206" },
      { "name": "Non GMO atalanta olives", "code": "50527207" },
      { "name": "Non GMO cerignola olives", "code": "50527208" },
      { "name": "Non GMO cracked provencal olives", "code": "50527209" },
      { "name": "Non GMO empeltre olives", "code": "50527210" },
      { "name": "Non GMO gaeta olives", "code": "50527211" },
      { "name": "Non GMO hondroelia olives", "code": "50527212" },
      { "name": "Non GMO kalamata olives", "code": "50527213" },
      { "name": "Non GMO kura olives", "code": "50527214" },
      { "name": "Non GMO ligurian olives", "code": "50527215" },
      { "name": "Non GMO lucque olives", "code": "50527216" },
      { "name": "Non GMO lugano olives", "code": "50527217" },
      { "name": "Non GMO manzanilla olives", "code": "50527218" },
      { "name": "Non GMO marche olives", "code": "50527219" },
      { "name": "Non GMO mission olives", "code": "50527220" },
      { "name": "Non GMO nafplion green olives", "code": "50527221" },
      { "name": "Non GMO nicoise olives", "code": "50527222" },
      { "name": "Non GMO nyons olives", "code": "50527223" },
      { "name": "Non GMO picholine olives", "code": "50527224" },
      { "name": "Non GMO ponentine olives", "code": "50527225" },
      { "name": "Non GMO royal olives", "code": "50527226" },
      { "name": "Non GMO seracena olives", "code": "50527227" },
      { "name": "Non GMO sevillano olives", "code": "50527228" },
      { "name": "Non GMO sicilian olives", "code": "50527229" },
      { "name": "Non GMO toscanelle olives", "code": "50527230" },
      { "name": "Dried non GMO fruits", "code": "50530000" },
      { "name": "Dried non GMO apples", "code": "50531500" },
      { "name": "Dried non GMO akane apples", "code": "50531501" },
      { "name": "Dried non GMO ambrosia apples", "code": "50531502" },
      { "name": "Dried non GMO api apples", "code": "50531503" },
      { "name": "Dried non GMO baldwin apples", "code": "50531504" },
      { "name": "Dried non GMO braeburn apples", "code": "50531505" },
      { "name": "Dried non GMO bramley apples", "code": "50531506" },
      { "name": "Dried non GMO bramley seedling apples", "code": "50531507" },
      {
        "name": "Dried non GMO calville blanche d'hiver apples",
        "code": "50531508"
      },
      { "name": "Dried non GMO cameo apples", "code": "50531509" },
      { "name": "Dried non GMO charles ross apples", "code": "50531510" },
      { "name": "Dried non GMO codlin apples", "code": "50531511" },
      { "name": "Dried non GMO cortland apples", "code": "50531512" },
      { "name": "Dried non GMO costard apples", "code": "50531513" },
      { "name": "Dried non GMO court pendu plat apples", "code": "50531514" },
      {
        "name": "Dried non GMO cox's orange pippin apples",
        "code": "50531515"
      },
      { "name": "Dried non GMO crab apples", "code": "50531516" },
      { "name": "Dried non GMO crispin apples", "code": "50531517" },
      { "name": "Dried non GMO delicious apples", "code": "50531518" },
      { "name": "Dried non GMO duchess apples", "code": "50531519" },
      { "name": "Dried non GMO earligold apples", "code": "50531520" },
      { "name": "Dried non GMO early mcintosh apples", "code": "50531521" },
      { "name": "Dried non GMO elstar apples", "code": "50531522" },
      { "name": "Dried non GMO empire apples", "code": "50531523" },
      { "name": "Dried non GMO flower of kent apples", "code": "50531524" },
      { "name": "Dried non GMO fuji apples", "code": "50531525" },
      { "name": "Dried non GMO gala apples", "code": "50531526" },
      { "name": "Dried non GMO gascoyne's scarlet apples", "code": "50531527" },
      { "name": "Dried non GMO gilliflower apples", "code": "50531528" },
      { "name": "Dried non GMO ginger gold apples", "code": "50531529" },
      { "name": "Dried non GMO gladstone apples", "code": "50531530" },
      { "name": "Dried non GMO gloster apples", "code": "50531531" },
      { "name": "Dried non GMO gold supreme apples", "code": "50531532" },
      { "name": "Dried non GMO golden delicious apples", "code": "50531533" },
      { "name": "Dried non GMO golden noble apples", "code": "50531534" },
      { "name": "Dried non GMO granny smith apples", "code": "50531535" },
      { "name": "Dried non GMO gravenstein apples", "code": "50531536" },
      { "name": "Dried non GMO greening apples", "code": "50531537" },
      { "name": "Dried non GMO greensleeves apples", "code": "50531538" },
      { "name": "Dried non GMO honeycrisp apples", "code": "50531539" },
      { "name": "Dried non GMO howgate wonder apples", "code": "50531540" },
      { "name": "Dried non GMO ida red apples", "code": "50531541" },
      { "name": "Dried non GMO james grieve apples", "code": "50531542" },
      { "name": "Dried non GMO jersey mac apples", "code": "50531543" },
      { "name": "Dried non GMO jester apples", "code": "50531544" },
      { "name": "Dried non GMO jonagold apples", "code": "50531545" },
      { "name": "Dried non GMO jonamac apples", "code": "50531546" },
      { "name": "Dried non GMO jonathan apples", "code": "50531547" },
      { "name": "Dried non GMO katy apples", "code": "50531548" },
      { "name": "Dried non GMO kidd's orange red apples", "code": "50531549" },
      { "name": "Dried non GMO lady apples", "code": "50531550" },
      { "name": "Dried non GMO law rome apples", "code": "50531551" },
      { "name": "Dried non GMO laxton apples", "code": "50531552" },
      { "name": "Dried non GMO lord derby apples", "code": "50531553" },
      { "name": "Dried non GMO macoun apples", "code": "50531554" },
      { "name": "Dried non GMO mcintosh apples", "code": "50531555" },
      { "name": "Dried non GMO mutsu apples", "code": "50531556" },
      { "name": "Dried non GMO newtown pippin apples", "code": "50531557" },
      { "name": "Dried non GMO northern spy apples", "code": "50531558" },
      { "name": "Dried non GMO orleans reinette apples", "code": "50531559" },
      { "name": "Dried non GMO ozark gold apples", "code": "50531560" },
      { "name": "Dried non GMO pacific rose apples", "code": "50531561" },
      { "name": "Dried non GMO paula red apples", "code": "50531562" },
      { "name": "Dried non GMO pearmain apples", "code": "50531563" },
      { "name": "Dried non GMO pink lady apples", "code": "50531564" },
      { "name": "Dried non GMO pippin apples", "code": "50531565" },
      {
        "name": "Dried non GMO pitmaston pineapple apples",
        "code": "50531566"
      },
      { "name": "Dried non GMO pomme d'api apples", "code": "50531567" },
      { "name": "Dried non GMO prime gold apples", "code": "50531568" },
      { "name": "Dried non GMO red astrachan apples", "code": "50531569" },
      { "name": "Dried non GMO red boscoop apples", "code": "50531570" },
      { "name": "Dried non GMO red chief apples", "code": "50531571" },
      { "name": "Dried non GMO red delicious apples", "code": "50531572" },
      { "name": "Dried non GMO red gravenstein apples", "code": "50531573" },
      { "name": "Dried non GMO red rome apples", "code": "50531574" },
      { "name": "Dried non GMO red stayman apples", "code": "50531575" },
      { "name": "Dried non GMO red york apples", "code": "50531576" },
      { "name": "Dried non GMO reinette apples", "code": "50531577" },
      { "name": "Dried non GMO rome beauty apples", "code": "50531578" },
      { "name": "Dried non GMO russet apples", "code": "50531579" },
      { "name": "Dried non GMO sierra beauty apples", "code": "50531580" },
      { "name": "Dried non GMO spartan apples", "code": "50531581" },
      { "name": "Dried non GMO stark crimson apples", "code": "50531582" },
      { "name": "Dried non GMO starking apples", "code": "50531583" },
      { "name": "Dried non GMO stayman apples", "code": "50531584" },
      { "name": "Dried non GMO stayman winesap apples", "code": "50531585" },
      { "name": "Dried non GMO summer rambo apples", "code": "50531586" },
      { "name": "Dried non GMO tsugaru apples", "code": "50531587" },
      { "name": "Dried non GMO twenty ounce apples", "code": "50531588" },
      { "name": "Dried non GMO tydeman red apples", "code": "50531589" },
      { "name": "Dried non GMO vistabella apples", "code": "50531590" },
      { "name": "Dried non GMO wealthy apples", "code": "50531591" },
      { "name": "Dried non GMO white joaneting apples", "code": "50531592" },
      { "name": "Dried non GMO white transparent apples", "code": "50531593" },
      { "name": "Dried non GMO winesap apples", "code": "50531594" },
      { "name": "Dried non GMO worcester apples", "code": "50531595" },
      { "name": "Dried non GMO york imperial apples", "code": "50531596" },
      { "name": "Dried non GMO apricots", "code": "50531600" },
      { "name": "Dried non GMO ambercot apricots", "code": "50531601" },
      { "name": "Dried non GMO apache apricots", "code": "50531602" },
      { "name": "Dried non GMO brittany gold apricots", "code": "50531603" },
      { "name": "Dried non GMO black apricots", "code": "50531604" },
      { "name": "Dried non GMO blenheim apricots", "code": "50531605" },
      { "name": "Dried non GMO bonny apricots", "code": "50531606" },
      { "name": "Dried non GMO bulida apricots", "code": "50531607" },
      { "name": "Dried non GMO castlebrite apricots", "code": "50531608" },
      { "name": "Dried non GMO clutha gold apricots", "code": "50531609" },
      { "name": "Dried non GMO clutha sun apricots", "code": "50531610" },
      { "name": "Dried non GMO darby royal apricots", "code": "50531611" },
      { "name": "Dried non GMO dina apricots", "code": "50531612" },
      { "name": "Dried non GMO earlicot apricots", "code": "50531613" },
      { "name": "Dried non GMO earliman apricots", "code": "50531614" },
      { "name": "Dried non GMO early bright apricots", "code": "50531615" },
      { "name": "Dried non GMO flaming gold apricots", "code": "50531616" },
      { "name": "Dried non GMO fresno apricots", "code": "50531617" },
      { "name": "Dried non GMO gold brite apricots", "code": "50531618" },
      { "name": "Dried non GMO goldbar apricots", "code": "50531619" },
      { "name": "Dried non GMO golden sweet apricots", "code": "50531620" },
      { "name": "Dried non GMO goldrich apricots", "code": "50531621" },
      { "name": "Dried non GMO helena apricots", "code": "50531622" },
      { "name": "Dried non GMO honeycot apricots", "code": "50531623" },
      { "name": "Dried non GMO imperial apricots", "code": "50531624" },
      { "name": "Dried non GMO jordanne apricots", "code": "50531625" },
      { "name": "Dried non GMO jumbo cot apricots", "code": "50531626" },
      { "name": "Dried non GMO kandy kot apricots", "code": "50531627" },
      { "name": "Dried non GMO katy apricots", "code": "50531628" },
      { "name": "Dried non GMO king apricots", "code": "50531629" },
      { "name": "Dried non GMO lambertin apricots", "code": "50531630" },
      { "name": "Dried non GMO lorna apricots", "code": "50531631" },
      { "name": "Dried non GMO lulu belle apricots", "code": "50531632" },
      { "name": "Dried non GMO modesto apricots", "code": "50531633" },
      { "name": "Dried non GMO moorpark apricots", "code": "50531634" },
      { "name": "Dried non GMO orangered apricots", "code": "50531635" },
      { "name": "Dried non GMO palstein apricots", "code": "50531636" },
      { "name": "Dried non GMO patterson apricots", "code": "50531637" },
      { "name": "Dried non GMO perfection apricots", "code": "50531638" },
      { "name": "Dried non GMO poppy apricots", "code": "50531639" },
      { "name": "Dried non GMO poppycot apricots", "code": "50531640" },
      { "name": "Dried non GMO queen apricots", "code": "50531641" },
      { "name": "Dried non GMO riland apricots", "code": "50531642" },
      { "name": "Dried non GMO rival apricots", "code": "50531643" },
      { "name": "Dried non GMO robada apricots", "code": "50531644" },
      { "name": "Dried non GMO royal apricots", "code": "50531645" },
      { "name": "Dried non GMO royal blenheim apricots", "code": "50531646" },
      { "name": "Dried non GMO royal orange apricots", "code": "50531647" },
      { "name": "Dried non GMO sundrop apricots", "code": "50531648" },
      { "name": "Dried non GMO tilton apricots", "code": "50531649" },
      { "name": "Dried non GMO tomcot apricots", "code": "50531650" },
      { "name": "Dried non GMO tracy apricots", "code": "50531651" },
      { "name": "Dried non GMO tri gem apricots", "code": "50531652" },
      { "name": "Dried non GMO valley gold apricots", "code": "50531653" },
      { "name": "Dried non GMO westley apricots", "code": "50531654" },
      { "name": "Dried non GMO york apricots", "code": "50531655" },
      { "name": "Dried non GMO bananas", "code": "50531700" },
      { "name": "Dried non GMO apple bananas", "code": "50531701" },
      { "name": "Dried non GMO baby bananas", "code": "50531702" },
      { "name": "Dried non GMO burro bananas", "code": "50531703" },
      { "name": "Dried non GMO cavendish bananas", "code": "50531704" },
      { "name": "Dried non GMO dominico bananas", "code": "50531705" },
      { "name": "Dried non GMO green bananas", "code": "50531706" },
      { "name": "Dried non GMO gros michel bananas", "code": "50531707" },
      { "name": "Dried non GMO lacatan bananas", "code": "50531708" },
      { "name": "Dried non GMO lady finger banana", "code": "50531709" },
      { "name": "Dried non GMO manzano bananas", "code": "50531710" },
      { "name": "Dried non GMO mysore bananas", "code": "50531711" },
      { "name": "Dried non GMO pisang mas bananas", "code": "50531712" },
      { "name": "Dried non GMO red bananas", "code": "50531713" },
      { "name": "Dried non GMO saba bananas", "code": "50531714" },
      { "name": "Dried non GMO sucrier bananas", "code": "50531715" },
      { "name": "Dried non GMO barberries", "code": "50531800" },
      { "name": "Dried non GMO paleleaf barberries", "code": "50531801" },
      { "name": "Dried non GMO chenault barberries", "code": "50531802" },
      { "name": "Dried non GMO red barberries", "code": "50531803" },
      { "name": "Dried non GMO wintergreen barberries", "code": "50531804" },
      { "name": "Dried non GMO korean barberries", "code": "50531805" },
      { "name": "Dried non GMO mentor barberries", "code": "50531806" },
      { "name": "Dried non GMO japanese barberries", "code": "50531807" },
      { "name": "Dried non GMO atropurpurea barberries", "code": "50531808" },
      { "name": "Dried non GMO aurea barberries", "code": "50531809" },
      { "name": "Dried non GMO bagatelle barberries", "code": "50531810" },
      { "name": "Dried non GMO crimson pygmy barberries", "code": "50531811" },
      { "name": "Dried non GMO kobold barberries", "code": "50531812" },
      { "name": "Dried non GMO warty barberries", "code": "50531813" },
      { "name": "Dried non GMO european barberries", "code": "50531814" },
      { "name": "Dried non GMO bearberries", "code": "50531900" },
      { "name": "Dried non GMO alpine bearberries", "code": "50531901" },
      { "name": "Dried non GMO red bearberries", "code": "50531902" },
      { "name": "Dried non GMO common bearberries", "code": "50531903" },
      { "name": "Dried non GMO blackberries", "code": "50532000" },
      { "name": "Dried non GMO apache blackberries", "code": "50532001" },
      { "name": "Dried non GMO black satin blackberries", "code": "50532002" },
      { "name": "Dried non GMO boysenberries", "code": "50532003" },
      { "name": "Dried non GMO cherokee blackberries", "code": "50532004" },
      { "name": "Dried non GMO chester blackberries", "code": "50532005" },
      { "name": "Dried non GMO dirksen blackberries", "code": "50532006" },
      { "name": "Dried non GMO jostaberries", "code": "50532007" },
      { "name": "Dried non GMO loganberries", "code": "50532008" },
      { "name": "Dried non GMO marionberries", "code": "50532009" },
      { "name": "Dried non GMO navaho blackberries", "code": "50532010" },
      { "name": "Dried non GMO nectarberries", "code": "50532011" },
      { "name": "Dried non GMO olallie blackberries", "code": "50532012" },
      { "name": "Dried non GMO tayberries", "code": "50532013" },
      {
        "name": "Dried non GMO thornless hull blackberries",
        "code": "50532014"
      },
      { "name": "Dried non GMO youngberries", "code": "50532015" },
      { "name": "Dried non GMO bilberries", "code": "50532100" },
      { "name": "Dried non GMO bog bilberries", "code": "50532101" },
      { "name": "Dried non GMO dwarf bilberries", "code": "50532102" },
      { "name": "Dried non GMO mountain bilberries", "code": "50532103" },
      { "name": "Dried non GMO oval-leaved bilberries", "code": "50532104" },
      { "name": "Dried non GMO blueberries", "code": "50532200" },
      { "name": "Dried non GMO bluecrop blueberries", "code": "50532201" },
      { "name": "Dried non GMO bluetta blueberries", "code": "50532202" },
      { "name": "Dried non GMO brigitta blueberries", "code": "50532203" },
      { "name": "Dried non GMO chandler blueberries", "code": "50532204" },
      { "name": "Dried non GMO duke blueberries", "code": "50532205" },
      { "name": "Dried non GMO hardyblue blueberries", "code": "50532206" },
      { "name": "Dried non GMO legacy blueberries", "code": "50532207" },
      { "name": "Dried non GMO misty blueberries", "code": "50532208" },
      { "name": "Dried non GMO nelson blueberries", "code": "50532209" },
      { "name": "Dried non GMO northblue blueberries", "code": "50532210" },
      { "name": "Dried non GMO northcountry blueberries", "code": "50532211" },
      { "name": "Dried non GMO northsky blueberries", "code": "50532212" },
      { "name": "Dried non GMO patriot blueberries", "code": "50532213" },
      { "name": "Dried non GMO spartan blueberries", "code": "50532214" },
      { "name": "Dried non GMO toro blueberries", "code": "50532215" },
      { "name": "Dried non GMO breadfruits", "code": "50532300" },
      { "name": "Dried non GMO chataigne breadfruit", "code": "50532301" },
      { "name": "Dried non GMO seedless breadfruit", "code": "50532302" },
      { "name": "Dried non GMO white heart breadfruit", "code": "50532303" },
      { "name": "Dried non GMO yellow heart breadfruit", "code": "50532304" },
      { "name": "Dried non GMO cherimoyas", "code": "50532400" },
      { "name": "Dried non GMO bays cherimoya", "code": "50532401" },
      { "name": "Dried non GMO bronceada cherimoya", "code": "50532402" },
      { "name": "Dried non GMO burtons cherimoya", "code": "50532403" },
      { "name": "Dried non GMO jete cherimoya", "code": "50532404" },
      { "name": "Dried non GMO reretai cherimoya", "code": "50532405" },
      { "name": "Dried non GMO smoothey cherimoya", "code": "50532406" },
      { "name": "Dried non GMO spain cherimoya", "code": "50532407" },
      { "name": "Dried non GMO white cherimoya", "code": "50532408" },
      { "name": "Dried non GMO cherries", "code": "50532500" },
      { "name": "Dried non GMO amarelle cherries", "code": "50532501" },
      { "name": "Dried non GMO brooks cherries", "code": "50532502" },
      { "name": "Dried non GMO bigarreu cherries", "code": "50532503" },
      { "name": "Dried non GMO bing cherries", "code": "50532504" },
      { "name": "Dried non GMO black republic cherries", "code": "50532505" },
      { "name": "Dried non GMO black schmidt cherries", "code": "50532506" },
      { "name": "Dried non GMO black tartarian cherries", "code": "50532507" },
      { "name": "Dried non GMO fiesta bing cherries", "code": "50532508" },
      { "name": "Dried non GMO garnet cherries", "code": "50532509" },
      { "name": "Dried non GMO king cherries", "code": "50532510" },
      { "name": "Dried non GMO chapman cherries", "code": "50532511" },
      { "name": "Dried non GMO lapin cherries", "code": "50532512" },
      { "name": "Dried non GMO larian cherries", "code": "50532513" },
      { "name": "Dried non GMO dark guines cherries", "code": "50532514" },
      { "name": "Dried non GMO montmorency cherries", "code": "50532515" },
      { "name": "Dried non GMO duke cherries", "code": "50532516" },
      { "name": "Dried non GMO early rivers cherries", "code": "50532517" },
      { "name": "Dried non GMO ruby bing cherries", "code": "50532518" },
      { "name": "Dried non GMO santina cherries", "code": "50532519" },
      { "name": "Dried non GMO geans/guines cherries", "code": "50532520" },
      { "name": "Dried non GMO sonata cherries", "code": "50532521" },
      { "name": "Dried non GMO lambert cherries", "code": "50532522" },
      { "name": "Dried non GMO stella cherries", "code": "50532523" },
      { "name": "Dried non GMO sweetheart cherries", "code": "50532524" },
      { "name": "Dried non GMO tartarian cherries", "code": "50532525" },
      { "name": "Dried non GMO maraschino cherries", "code": "50532526" },
      { "name": "Dried non GMO van cherries", "code": "50532527" },
      { "name": "Dried non GMO morello cherries", "code": "50532528" },
      { "name": "Dried non GMO royal ann cherries", "code": "50532529" },
      { "name": "Dried non GMO ranier cherries", "code": "50532530" },
      { "name": "Dried non GMO royal cherries", "code": "50532531" },
      { "name": "Dried non GMO citrons", "code": "50532600" },
      { "name": "Dried non GMO buddha's hand citrons", "code": "50532601" },
      { "name": "Dried non GMO fingered citrons", "code": "50532602" },
      { "name": "Dried non GMO fo shoukan citrons", "code": "50532603" },
      { "name": "Dried non GMO bushakan citrons", "code": "50532604" },
      { "name": "Dried non GMO diamante citrons", "code": "50532605" },
      { "name": "Dried non GMO etrog citrons", "code": "50532606" },
      { "name": "Dried non GMO ponderosa citrons", "code": "50532607" },
      { "name": "Dried non GMO cranberries", "code": "50532700" },
      { "name": "Dried non GMO ben lear cranberries", "code": "50532701" },
      { "name": "Dried non GMO early black cranberries", "code": "50532702" },
      { "name": "Dried non GMO grycleski cranberries", "code": "50532703" },
      { "name": "Dried non GMO howe cranberries", "code": "50532704" },
      { "name": "Dried non GMO lingonberries", "code": "50532705" },
      { "name": "Dried non GMO mcfarlin cranberries", "code": "50532706" },
      { "name": "Dried non GMO mountain cranberries", "code": "50532707" },
      { "name": "Dried non GMO pilgrim cranberries", "code": "50532708" },
      { "name": "Dried non GMO searless cranberries", "code": "50532709" },
      { "name": "Dried non GMO stevens cranberries", "code": "50532710" },
      { "name": "Dried non GMO currants", "code": "50532800" },
      { "name": "Dried non GMO hudson bay currants", "code": "50532801" },
      { "name": "Dried non GMO waxy currants", "code": "50532802" },
      { "name": "Dried non GMO desert currants", "code": "50532803" },
      { "name": "Dried non GMO black currants", "code": "50532804" },
      { "name": "Dried non GMO red currants", "code": "50532805" },
      { "name": "Dried non GMO white currants", "code": "50532806" },
      { "name": "Dried non GMO dates", "code": "50532900" },
      { "name": "Dried non GMO asharasi dates", "code": "50532901" },
      { "name": "Dried non GMO barhi or barhee dates", "code": "50532902" },
      { "name": "Dried non GMO deglet noor dates", "code": "50532903" },
      { "name": "Dried non GMO fardh dates", "code": "50532904" },
      { "name": "Dried non GMO gundila dates", "code": "50532905" },
      { "name": "Dried non GMO halawi/halawy dates", "code": "50532906" },
      { "name": "Dried non GMO hilali dates", "code": "50532907" },
      { "name": "Dried non GMO khadrawi/khadrawy dates", "code": "50532908" },
      { "name": "Dried non GMO khalas dates", "code": "50532909" },
      { "name": "Dried non GMO khustawi dates", "code": "50532910" },
      { "name": "Dried non GMO khidri dates", "code": "50532911" },
      { "name": "Dried non GMO medjool/medjul dates", "code": "50532912" },
      { "name": "Dried non GMO mactoum dates", "code": "50532913" },
      { "name": "Dried non GMO neghal dates", "code": "50532914" },
      { "name": "Dried non GMO yatimeh dates", "code": "50532915" },
      { "name": "Dried non GMO zahidi dates", "code": "50532916" },
      { "name": "Dried non GMO dragonfruits", "code": "50533000" },
      { "name": "Dried non GMO pink dragonfruit", "code": "50533001" },
      { "name": "Dried non GMO yellow dragonfruit", "code": "50533002" },
      { "name": "Dried non GMO figs", "code": "50533100" },
      { "name": "Dried non GMO bardajic figs", "code": "50533101" },
      { "name": "Dried non GMO brown turkey figs", "code": "50533102" },
      { "name": "Dried non GMO calimyrna figs", "code": "50533103" },
      { "name": "Dried non GMO conadria figs", "code": "50533104" },
      { "name": "Dried non GMO dottado figs", "code": "50533105" },
      { "name": "Dried non GMO kadota figs", "code": "50533106" },
      { "name": "Dried non GMO mediterranean figs", "code": "50533107" },
      { "name": "Dried non GMO mission figs", "code": "50533108" },
      { "name": "Dried non GMO smyrna figs", "code": "50533109" },
      { "name": "Dried non GMO verdona figs", "code": "50533110" },
      { "name": "Dried non GMO white king figs", "code": "50533111" },
      { "name": "Dried non GMO gooseberries", "code": "50533200" },
      {
        "name": "Dried non GMO early sulphur gooseberries",
        "code": "50533201"
      },
      { "name": "Dried non GMO goldendrop gooseberries", "code": "50533202" },
      { "name": "Dried non GMO langley gage gooseberries", "code": "50533203" },
      { "name": "Dried non GMO leveller gooseberries", "code": "50533204" },
      { "name": "Dried non GMO london gooseberries", "code": "50533205" },
      {
        "name": "Dried non GMO worcestershire gooseberries",
        "code": "50533206"
      },
      {
        "name": "Dried non GMO american worcesterberry gooseberries",
        "code": "50533207"
      },
      { "name": "Dried non GMO grapefruits", "code": "50533300" },
      { "name": "Dried non GMO burgundy grapefruit", "code": "50533301" },
      { "name": "Dried non GMO duncan grapefruit", "code": "50533302" },
      { "name": "Dried non GMO foster grapefruit", "code": "50533303" },
      { "name": "Dried non GMO marsh grapefruit", "code": "50533304" },
      { "name": "Dried non GMO new zealand grapefruit", "code": "50533305" },
      { "name": "Dried non GMO rio red grapefruit", "code": "50533306" },
      { "name": "Dried non GMO ruby red grapefruit", "code": "50533307" },
      { "name": "Dried non GMO star ruby grapefruit", "code": "50533308" },
      { "name": "Dried non GMO triumph grapefruit", "code": "50533309" },
      { "name": "Dried non GMO table grapes", "code": "50533400" },
      { "name": "Dried non GMO alicante grapes", "code": "50533401" },
      { "name": "Dried non GMO almeria grapes", "code": "50533402" },
      { "name": "Dried non GMO alphonse lavalle grapes", "code": "50533403" },
      { "name": "Dried non GMO autumn king grapes", "code": "50533404" },
      { "name": "Dried non GMO autumn royal grapes", "code": "50533405" },
      { "name": "Dried non GMO autumn seedless grapes", "code": "50533406" },
      { "name": "Dried non GMO baresana grapes", "code": "50533407" },
      { "name": "Dried non GMO barlinka grapes", "code": "50533408" },
      { "name": "Dried non GMO beauty seedless grapes", "code": "50533409" },
      {
        "name": "Dried non GMO black beauty seedless grapes",
        "code": "50533410"
      },
      { "name": "Dried non GMO black emerald grapes", "code": "50533411" },
      { "name": "Dried non GMO black giant grapes", "code": "50533412" },
      { "name": "Dried non GMO black globe grapes", "code": "50533413" },
      { "name": "Dried non GMO black monukka grapes", "code": "50533414" },
      { "name": "Dried non GMO black pearl grapes", "code": "50533415" },
      { "name": "Dried non GMO black seedless grapes", "code": "50533416" },
      { "name": "Dried non GMO bonheur grapes", "code": "50533417" },
      { "name": "Dried non GMO calmeria grapes", "code": "50533418" },
      { "name": "Dried non GMO cardinal grapes", "code": "50533419" },
      { "name": "Dried non GMO catawba grapes", "code": "50533420" },
      {
        "name": "Dried non GMO chasselas/golden chasselas grapes",
        "code": "50533421"
      },
      { "name": "Dried non GMO christmas rose grapes", "code": "50533422" },
      { "name": "Dried non GMO concord grapes", "code": "50533423" },
      { "name": "Dried non GMO concord seedless grapes", "code": "50533424" },
      { "name": "Dried non GMO crimson seedless grapes", "code": "50533425" },
      { "name": "Dried non GMO dauphine grapes", "code": "50533426" },
      { "name": "Dried non GMO delaware grapes", "code": "50533427" },
      { "name": "Dried non GMO early muscat grapes", "code": "50533428" },
      { "name": "Dried non GMO early sweet grapes", "code": "50533429" },
      { "name": "Dried non GMO emerald seedless grapes", "code": "50533430" },
      { "name": "Dried non GMO emperatriz grapes", "code": "50533431" },
      { "name": "Dried non GMO emperor grapes", "code": "50533432" },
      { "name": "Dried non GMO empress grapes", "code": "50533433" },
      { "name": "Dried non GMO exotic grapes", "code": "50533434" },
      { "name": "Dried non GMO fantasy grapes", "code": "50533435" },
      { "name": "Dried non GMO fantasy seedless grapes", "code": "50533436" },
      { "name": "Dried non GMO flame grapes", "code": "50533437" },
      { "name": "Dried non GMO flame seedless grapes", "code": "50533438" },
      { "name": "Dried non GMO flame tokay grapes", "code": "50533439" },
      { "name": "Dried non GMO flaming red grapes", "code": "50533440" },
      { "name": "Dried non GMO galaxy seedless grapes", "code": "50533441" },
      { "name": "Dried non GMO gamay grapes", "code": "50533442" },
      { "name": "Dried non GMO gold grapes", "code": "50533443" },
      {
        "name": "Dried non GMO hanepoot or honeypot grapes",
        "code": "50533444"
      },
      { "name": "Dried non GMO italia grapes", "code": "50533445" },
      { "name": "Dried non GMO jade seedless grapes", "code": "50533446" },
      { "name": "Dried non GMO jubilee grapes", "code": "50533447" },
      { "name": "Dried non GMO king ruby grapes", "code": "50533448" },
      { "name": "Dried non GMO kyoho grapes", "code": "50533449" },
      { "name": "Dried non GMO la rochelle grapes", "code": "50533450" },
      { "name": "Dried non GMO lady finger grapes", "code": "50533451" },
      { "name": "Dried non GMO late seedless grapes", "code": "50533452" },
      { "name": "Dried non GMO majestic seedless grapes", "code": "50533453" },
      { "name": "Dried non GMO malaga grapes", "code": "50533454" },
      { "name": "Dried non GMO marroo seedless grapes", "code": "50533455" },
      { "name": "Dried non GMO muscadine grapes", "code": "50533456" },
      { "name": "Dried non GMO muscat flame grapes", "code": "50533457" },
      { "name": "Dried non GMO muscat grapes", "code": "50533458" },
      { "name": "Dried non GMO muscat seedless grapes", "code": "50533459" },
      { "name": "Dried non GMO napoleon grapes", "code": "50533460" },
      { "name": "Dried non GMO negria grapes", "code": "50533461" },
      { "name": "Dried non GMO new cross grapes", "code": "50533462" },
      { "name": "Dried non GMO niabell grapes", "code": "50533463" },
      { "name": "Dried non GMO niagara grapes", "code": "50533464" },
      { "name": "Dried non GMO olivette grapes", "code": "50533465" },
      { "name": "Dried non GMO perlette grapes", "code": "50533466" },
      { "name": "Dried non GMO perlon grapes", "code": "50533467" },
      {
        "name": "Dried non GMO prima black seedless grapes",
        "code": "50533468"
      },
      { "name": "Dried non GMO princess grapes", "code": "50533469" },
      { "name": "Dried non GMO queen grapes", "code": "50533470" },
      { "name": "Dried non GMO red blush grapes", "code": "50533471" },
      { "name": "Dried non GMO red globe grapes", "code": "50533472" },
      { "name": "Dried non GMO red malaga grapes", "code": "50533473" },
      { "name": "Dried non GMO red seedless grapes", "code": "50533474" },
      { "name": "Dried non GMO regina grapes", "code": "50533475" },
      { "name": "Dried non GMO ribier grapes", "code": "50533476" },
      { "name": "Dried non GMO rosita grapes", "code": "50533477" },
      { "name": "Dried non GMO rouge grapes", "code": "50533478" },
      {
        "name": "Dried non GMO royal black seedless grapes",
        "code": "50533479"
      },
      { "name": "Dried non GMO ruby red seedless grapes", "code": "50533480" },
      { "name": "Dried non GMO ruby seedless grapes", "code": "50533481" },
      { "name": "Dried non GMO scarlet royal grapes", "code": "50533482" },
      { "name": "Dried non GMO scuppernong grapes", "code": "50533483" },
      { "name": "Dried non GMO sugarose grapes", "code": "50533484" },
      { "name": "Dried non GMO sugarthirteen grapes", "code": "50533485" },
      { "name": "Dried non GMO sugraone grapes", "code": "50533486" },
      { "name": "Dried non GMO sugrasixteen grapes", "code": "50533487" },
      { "name": "Dried non GMO sultana sun red grapes", "code": "50533488" },
      { "name": "Dried non GMO summer royal grapes", "code": "50533489" },
      { "name": "Dried non GMO sunset grapes", "code": "50533490" },
      { "name": "Dried non GMO superior seedless grapes", "code": "50533491" },
      { "name": "Dried non GMO thompson seedless grapes", "code": "50533492" },
      { "name": "Dried non GMO tokay/pinot gris grapes", "code": "50533493" },
      { "name": "Dried non GMO waltman cross grapes", "code": "50533494" },
      { "name": "Dried non GMO white seedless grapes", "code": "50533495" },
      { "name": "Dried non GMO zante current grapes", "code": "50533496" },
      { "name": "Dried non GMO raisin grapes", "code": "50533500" },
      { "name": "Dried non GMO black corinth grapes", "code": "50533501" },
      { "name": "Dried non GMO canner grapes", "code": "50533502" },
      { "name": "Dried non GMO dovine grapes", "code": "50533503" },
      { "name": "Dried non GMO fiesta grapes", "code": "50533504" },
      { "name": "Dried non GMO selma pete grapes", "code": "50533505" },
      { "name": "Dried non GMO sultana grapes", "code": "50533506" },
      { "name": "Dried non GMO wine grapes", "code": "50533600" },
      { "name": "Dried non GMO alicante bouschet grapes", "code": "50533601" },
      { "name": "Dried non GMO barbera grapes", "code": "50533602" },
      { "name": "Dried non GMO burger grapes", "code": "50533603" },
      { "name": "Dried non GMO cabernet franc grapes", "code": "50533604" },
      { "name": "Dried non GMO cabernet sauvignon grapes", "code": "50533605" },
      { "name": "Dried non GMO carignane grapes", "code": "50533606" },
      { "name": "Dried non GMO carnelian grapes", "code": "50533607" },
      { "name": "Dried non GMO catarratto grapes", "code": "50533608" },
      { "name": "Dried non GMO centurian grapes", "code": "50533609" },
      { "name": "Dried non GMO charbono grapes", "code": "50533610" },
      { "name": "Dried non GMO chardonnay grapes", "code": "50533611" },
      { "name": "Dried non GMO chenin blanc grapes", "code": "50533612" },
      { "name": "Dried non GMO cinsaut grapes", "code": "50533613" },
      { "name": "Dried non GMO dolcetto grapes", "code": "50533614" },
      { "name": "Dried non GMO emerald riesling grapes", "code": "50533615" },
      { "name": "Dried non GMO french colombard grapes", "code": "50533616" },
      { "name": "Dried non GMO gamay napa grapes", "code": "50533617" },
      { "name": "Dried non GMO gamay beaujolais grapes", "code": "50533618" },
      { "name": "Dried non GMO gewurztraminer grapes", "code": "50533619" },
      { "name": "Dried non GMO grenache grapes", "code": "50533620" },
      { "name": "Dried non GMO grenache blanc grapes", "code": "50533621" },
      { "name": "Dried non GMO lagrein grapes", "code": "50533622" },
      { "name": "Dried non GMO lambrusco grapes", "code": "50533623" },
      { "name": "Dried non GMO malbec grapes", "code": "50533624" },
      { "name": "Dried non GMO malvasia bianca grapes", "code": "50533625" },
      { "name": "Dried non GMO marsanne grapes", "code": "50533626" },
      { "name": "Dried non GMO mataro grapes", "code": "50533627" },
      { "name": "Dried non GMO merlot grapes", "code": "50533628" },
      { "name": "Dried non GMO meunier grapes", "code": "50533629" },
      { "name": "Dried non GMO mission grapes", "code": "50533630" },
      { "name": "Dried non GMO montepulciano grapes", "code": "50533631" },
      { "name": "Dried non GMO muscat blanc grapes", "code": "50533632" },
      { "name": "Dried non GMO muscat hamburg grapes", "code": "50533633" },
      {
        "name": "Dried non GMO muscat of alexandria grapes",
        "code": "50533634"
      },
      { "name": "Dried non GMO muscat orange grapes", "code": "50533635" },
      { "name": "Dried non GMO nebbiolo grapes", "code": "50533636" },
      { "name": "Dried non GMO palomino grapes", "code": "50533637" },
      { "name": "Dried non GMO petit verdot grapes", "code": "50533638" },
      { "name": "Dried non GMO petite sirah grapes", "code": "50533639" },
      { "name": "Dried non GMO pinot blanc grapes", "code": "50533640" },
      { "name": "Dried non GMO pinot gris grapes", "code": "50533641" },
      { "name": "Dried non GMO pinot noir grapes", "code": "50533642" },
      { "name": "Dried non GMO primitivo grapes", "code": "50533643" },
      { "name": "Dried non GMO roussanne grapes", "code": "50533644" },
      { "name": "Dried non GMO royalty grapes", "code": "50533645" },
      { "name": "Dried non GMO rubired grapes", "code": "50533646" },
      { "name": "Dried non GMO ruby cabernet grapes", "code": "50533647" },
      { "name": "Dried non GMO salvador grapes", "code": "50533648" },
      { "name": "Dried non GMO sangiovese grapes", "code": "50533649" },
      { "name": "Dried non GMO sauvignon blanc grapes", "code": "50533650" },
      { "name": "Dried non GMO sauvignon musque grapes", "code": "50533651" },
      { "name": "Dried non GMO semillon grapes", "code": "50533652" },
      { "name": "Dried non GMO souzao grapes", "code": "50533653" },
      { "name": "Dried non GMO st emilion grapes", "code": "50533654" },
      { "name": "Dried non GMO symphony grapes", "code": "50533655" },
      { "name": "Dried non GMO syrah grapes", "code": "50533656" },
      { "name": "Dried non GMO tannat grapes", "code": "50533657" },
      { "name": "Dried non GMO tempranillo grapes", "code": "50533658" },
      { "name": "Dried non GMO teroldego grapes", "code": "50533659" },
      { "name": "Dried non GMO tocai friulano grapes", "code": "50533660" },
      { "name": "Dried non GMO touriga nacional grapes", "code": "50533661" },
      { "name": "Dried non GMO triplett blanc grapes", "code": "50533662" },
      { "name": "Dried non GMO viognier grapes", "code": "50533663" },
      { "name": "Dried non GMO white riesling grapes", "code": "50533664" },
      { "name": "Dried non GMO zinfandel grapes", "code": "50533665" },
      { "name": "Dried non GMO guavas", "code": "50533700" },
      { "name": "Dried non GMO beaumont guavas", "code": "50533701" },
      { "name": "Dried non GMO carrley guavas", "code": "50533702" },
      { "name": "Dried non GMO lucida guavas", "code": "50533703" },
      { "name": "Dried non GMO pineapple guava", "code": "50533704" },
      { "name": "Dried non GMO huckleberries", "code": "50533800" },
      {
        "name": "Dried non GMO black winter huckleberries",
        "code": "50533801"
      },
      { "name": "Dried non GMO cascade huckleberries", "code": "50533802" },
      { "name": "Dried non GMO dwarf huckleberries", "code": "50533803" },
      { "name": "Dried non GMO mountain huckleberries", "code": "50533804" },
      { "name": "Dried non GMO red huckleberries", "code": "50533805" },
      { "name": "Dried non GMO kiwi fruits", "code": "50533900" },
      { "name": "Dried non GMO ananasnaja kiwi fruits", "code": "50533901" },
      { "name": "Dried non GMO arctic beauty kiwi fruits", "code": "50533902" },
      { "name": "Dried non GMO blake kiwi fruits", "code": "50533903" },
      { "name": "Dried non GMO issai kiwi fruits", "code": "50533904" },
      { "name": "Dried non GMO siberian kiwi fruits", "code": "50533905" },
      { "name": "Dried non GMO kumquats", "code": "50534000" },
      { "name": "Dried non GMO hong kong kumquats", "code": "50534001" },
      { "name": "Dried non GMO limequat kumquats", "code": "50534002" },
      { "name": "Dried non GMO long fruit kumquats", "code": "50534003" },
      { "name": "Dried non GMO malayan kumquats", "code": "50534004" },
      { "name": "Dried non GMO meiwa kumquats", "code": "50534005" },
      { "name": "Dried non GMO nagami kumquats", "code": "50534006" },
      { "name": "Dried non GMO lemons", "code": "50534100" },
      { "name": "Dried non GMO baboon lemons", "code": "50534101" },
      { "name": "Dried non GMO bearss sicilian lemons", "code": "50534102" },
      { "name": "Dried non GMO cameron highlands lemons", "code": "50534103" },
      { "name": "Dried non GMO escondido lemons", "code": "50534104" },
      { "name": "Dried non GMO eureka lemons", "code": "50534105" },
      { "name": "Dried non GMO lisbon lemons", "code": "50534106" },
      { "name": "Dried non GMO meyer lemons", "code": "50534107" },
      { "name": "Dried non GMO volkamer lemons", "code": "50534108" },
      { "name": "Dried non GMO limes", "code": "50534200" },
      { "name": "Dried non GMO indian sweet limes", "code": "50534201" },
      { "name": "Dried non GMO key limes", "code": "50534202" },
      { "name": "Dried non GMO mandarin limes", "code": "50534203" },
      { "name": "Dried non GMO philippine limes", "code": "50534204" },
      { "name": "Dried non GMO tahitian limes", "code": "50534205" },
      { "name": "Dried non GMO bearss limes", "code": "50534206" },
      { "name": "Dried non GMO persian limes", "code": "50534207" },
      { "name": "Dried non GMO seedless limes", "code": "50534208" },
      { "name": "Dried non GMO loquats", "code": "50534300" },
      { "name": "Dried non GMO advance loquats", "code": "50534301" },
      { "name": "Dried non GMO benlehr loquats", "code": "50534302" },
      { "name": "Dried non GMO big jim loquats", "code": "50534303" },
      { "name": "Dried non GMO champagne loquats", "code": "50534304" },
      { "name": "Dried non GMO early red loquats", "code": "50534305" },
      { "name": "Dried non GMO gold nugget loquats", "code": "50534306" },
      { "name": "Dried non GMO herd's mammoth loquats", "code": "50534307" },
      { "name": "Dried non GMO mogi loquats", "code": "50534308" },
      { "name": "Dried non GMO mrs cooksey loquats", "code": "50534309" },
      { "name": "Dried non GMO strawberry loquats", "code": "50534310" },
      { "name": "Dried non GMO tanaka loquats", "code": "50534311" },
      {
        "name": "Dried non GMO victory vista white loquats",
        "code": "50534312"
      },
      { "name": "Dried non GMO wolfe loquats", "code": "50534313" },
      {
        "name": "Dried non GMO mandarin oranges or tangerines",
        "code": "50534400"
      },
      { "name": "Dried non GMO clauselinas oranges", "code": "50534401" },
      { "name": "Dried non GMO clementine tangerines", "code": "50534402" },
      {
        "name": "Dried non GMO cleopatra mandarin oranges",
        "code": "50534403"
      },
      { "name": "Dried non GMO dancy tangerines", "code": "50534404" },
      { "name": "Dried non GMO ellensdale oranges", "code": "50534405" },
      { "name": "Dried non GMO fairchild oranges", "code": "50534406" },
      { "name": "Dried non GMO fallglo oranges", "code": "50534407" },
      { "name": "Dried non GMO fortune oranges", "code": "50534408" },
      { "name": "Dried non GMO fremont mandarin oranges", "code": "50534409" },
      { "name": "Dried non GMO fremont oranges", "code": "50534410" },
      { "name": "Dried non GMO golden nugget oranges", "code": "50534411" },
      { "name": "Dried non GMO honey mandarin oranges", "code": "50534412" },
      { "name": "Dried non GMO honey oranges", "code": "50534413" },
      { "name": "Dried non GMO honey tangerines", "code": "50534414" },
      {
        "name": "Dried non GMO honeybelle tangelo oranges",
        "code": "50534415"
      },
      { "name": "Dried non GMO king mandarin oranges", "code": "50534416" },
      { "name": "Dried non GMO kinnow oranges", "code": "50534417" },
      { "name": "Dried non GMO lee mandarin oranges", "code": "50534418" },
      { "name": "Dried non GMO makokkee oranges", "code": "50534419" },
      { "name": "Dried non GMO malvasios oranges", "code": "50534420" },
      {
        "name": "Dried non GMO mediterranean mandarin oranges",
        "code": "50534421"
      },
      { "name": "Dried non GMO minneola tangelo oranges", "code": "50534422" },
      { "name": "Dried non GMO monica oranges", "code": "50534423" },
      { "name": "Dried non GMO murcott honey oranges", "code": "50534424" },
      { "name": "Dried non GMO murcott tangors", "code": "50534425" },
      {
        "name": "Dried non GMO natsudaidai mandarin oranges",
        "code": "50534426"
      },
      {
        "name": "Dried non GMO natsumikan mandarin oranges",
        "code": "50534427"
      },
      { "name": "Dried non GMO nocatee tangelo oranges", "code": "50534428" },
      { "name": "Dried non GMO orlando tangelo oranges", "code": "50534429" },
      { "name": "Dried non GMO ortanique tangerines", "code": "50534430" },
      { "name": "Dried non GMO page mandarin oranges", "code": "50534431" },
      { "name": "Dried non GMO pixie oranges", "code": "50534432" },
      {
        "name": "Dried non GMO ponkan bantangas mandarin oranges",
        "code": "50534433"
      },
      { "name": "Dried non GMO reyna oranges", "code": "50534434" },
      { "name": "Dried non GMO robinson oranges", "code": "50534435" },
      { "name": "Dried non GMO saltenitas oranges", "code": "50534436" },
      { "name": "Dried non GMO sampson tangelo oranges", "code": "50534437" },
      { "name": "Dried non GMO satsuma mandarin oranges", "code": "50534438" },
      { "name": "Dried non GMO sunburst mandarin oranges", "code": "50534439" },
      { "name": "Dried non GMO tangelos", "code": "50534440" },
      { "name": "Dried non GMO tangerina oranges", "code": "50534441" },
      { "name": "Dried non GMO temple oranges", "code": "50534442" },
      { "name": "Dried non GMO thornton oranges", "code": "50534443" },
      { "name": "Dried non GMO wekiwa tangerines", "code": "50534444" },
      { "name": "Dried non GMO wilkins tangerines", "code": "50534445" },
      {
        "name": "Dried non GMO willowleaf mediterranean tangerines",
        "code": "50534446"
      },
      { "name": "Dried non GMO mangoes", "code": "50534500" },
      { "name": "Dried non GMO alphonso mangoes", "code": "50534501" },
      { "name": "Dried non GMO ataulfo mangoes", "code": "50534502" },
      { "name": "Dried non GMO criollo mangoes", "code": "50534503" },
      { "name": "Dried non GMO edwards mangoes", "code": "50534504" },
      { "name": "Dried non GMO francine mangoes", "code": "50534505" },
      { "name": "Dried non GMO francis mangoes", "code": "50534506" },
      { "name": "Dried non GMO gandaria mangoes", "code": "50534507" },
      { "name": "Dried non GMO haden mangoes", "code": "50534508" },
      { "name": "Dried non GMO irwin mangoes", "code": "50534509" },
      { "name": "Dried non GMO keitt mangoes", "code": "50534510" },
      { "name": "Dried non GMO kent mangoes", "code": "50534511" },
      { "name": "Dried non GMO kesar mangoes", "code": "50534512" },
      { "name": "Dried non GMO kuini mangoes", "code": "50534513" },
      { "name": "Dried non GMO manila super mangoes", "code": "50534514" },
      { "name": "Dried non GMO manila mangoes", "code": "50534515" },
      { "name": "Dried non GMO mayaguez mangoes", "code": "50534516" },
      { "name": "Dried non GMO mulgoba mangoes", "code": "50534517" },
      { "name": "Dried non GMO oro mangoes", "code": "50534518" },
      { "name": "Dried non GMO palmer mangoes", "code": "50534519" },
      { "name": "Dried non GMO parvin mangoes", "code": "50534520" },
      { "name": "Dried non GMO sandersha mangoes", "code": "50534521" },
      { "name": "Dried non GMO sensation mangoes", "code": "50534522" },
      { "name": "Dried non GMO smith mangoes", "code": "50534523" },
      { "name": "Dried non GMO tommy atkins mangoes", "code": "50534524" },
      { "name": "Dried non GMO van dyke mangoes", "code": "50534525" },
      { "name": "Dried non GMO melons", "code": "50534600" },
      { "name": "Dried non GMO allsweet melons", "code": "50534601" },
      { "name": "Dried non GMO athena melons", "code": "50534602" },
      { "name": "Dried non GMO black diamond melons", "code": "50534603" },
      { "name": "Dried non GMO cal sweet melons", "code": "50534604" },
      { "name": "Dried non GMO carnical melons", "code": "50534605" },
      { "name": "Dried non GMO cantaloupe melons", "code": "50534606" },
      { "name": "Dried non GMO casaba melons", "code": "50534607" },
      { "name": "Dried non GMO cavaillon melons", "code": "50534608" },
      { "name": "Dried non GMO charentais melons", "code": "50534609" },
      {
        "name": "Dried non GMO charleston gray watermelon",
        "code": "50534610"
      },
      { "name": "Dried non GMO crenshaw melons", "code": "50534611" },
      { "name": "Dried non GMO crimson sweet melons", "code": "50534612" },
      { "name": "Dried non GMO dixie lee melons", "code": "50534613" },
      { "name": "Dried non GMO eclipse melons", "code": "50534614" },
      { "name": "Dried non GMO ein d'or melons", "code": "50534615" },
      { "name": "Dried non GMO fiesta melons", "code": "50534616" },
      { "name": "Dried non GMO galia melons", "code": "50534617" },
      { "name": "Dried non GMO gaya melons", "code": "50534618" },
      { "name": "Dried non GMO hami melons", "code": "50534619" },
      { "name": "Dried non GMO honeydew melons", "code": "50534620" },
      { "name": "Dried non GMO icebox melons", "code": "50534621" },
      { "name": "Dried non GMO ida pride melons", "code": "50534622" },
      { "name": "Dried non GMO juan canary melons", "code": "50534623" },
      { "name": "Dried non GMO jubilee melons", "code": "50534624" },
      { "name": "Dried non GMO jubilation melons", "code": "50534625" },
      { "name": "Dried non GMO kakhi/kakri melons", "code": "50534626" },
      { "name": "Dried non GMO kiwano melons", "code": "50534627" },
      { "name": "Dried non GMO korean melons", "code": "50534628" },
      { "name": "Dried non GMO long gray melons", "code": "50534629" },
      { "name": "Dried non GMO mayan melons", "code": "50534630" },
      { "name": "Dried non GMO micky lee melons", "code": "50534631" },
      { "name": "Dried non GMO mirage melons", "code": "50534632" },
      { "name": "Dried non GMO moon and stars watermelon", "code": "50534633" },
      { "name": "Dried non GMO ogen melons", "code": "50534634" },
      { "name": "Dried non GMO patriot melons", "code": "50534635" },
      { "name": "Dried non GMO peacock melons", "code": "50534636" },
      { "name": "Dried non GMO pepino melons", "code": "50534637" },
      { "name": "Dried non GMO persian melons", "code": "50534638" },
      { "name": "Dried non GMO picnic melons", "code": "50534639" },
      { "name": "Dried non GMO piel de sapo melons", "code": "50534640" },
      { "name": "Dried non GMO pineapple melons", "code": "50534641" },
      { "name": "Dried non GMO quetzali melons", "code": "50534642" },
      { "name": "Dried non GMO red goblin melons", "code": "50534643" },
      { "name": "Dried non GMO regency melons", "code": "50534644" },
      { "name": "Dried non GMO royal majestic melons", "code": "50534645" },
      { "name": "Dried non GMO royal star melons", "code": "50534646" },
      { "name": "Dried non GMO royal sweet melons", "code": "50534647" },
      { "name": "Dried non GMO santa claus melons", "code": "50534648" },
      { "name": "Dried non GMO sharlyn melons", "code": "50534649" },
      { "name": "Dried non GMO spanish melons", "code": "50534650" },
      { "name": "Dried non GMO sprite melons", "code": "50534651" },
      { "name": "Dried non GMO starbright melons", "code": "50534652" },
      { "name": "Dried non GMO stars n stripes melons", "code": "50534653" },
      { "name": "Dried non GMO sugar baby melons", "code": "50534654" },
      { "name": "Dried non GMO sugar baby watermelon", "code": "50534655" },
      { "name": "Dried non GMO sunsweet melons", "code": "50534656" },
      {
        "name": "Dried non GMO sweet heart seedless watermelon",
        "code": "50534657"
      },
      { "name": "Dried non GMO temptation melons", "code": "50534658" },
      { "name": "Dried non GMO tiger baby melons", "code": "50534659" },
      { "name": "Dried non GMO tuscan type melons", "code": "50534660" },
      { "name": "Dried non GMO yellow baby watermelon", "code": "50534661" },
      { "name": "Dried non GMO mulberries", "code": "50534700" },
      { "name": "Dried non GMO black mulberries", "code": "50534701" },
      { "name": "Dried non GMO white mulberries", "code": "50534702" },
      { "name": "Dried non GMO bayberries and myrtles", "code": "50534800" },
      { "name": "Dried non GMO bog myrtles", "code": "50534801" },
      { "name": "Dried non GMO bayberries", "code": "50534802" },
      { "name": "Dried non GMO nectarines", "code": "50534900" },
      { "name": "Dried non GMO april glo nectarines", "code": "50534901" },
      { "name": "Dried non GMO arctic mist nectarines", "code": "50534902" },
      { "name": "Dried non GMO arctic snow nectarines", "code": "50534903" },
      { "name": "Dried non GMO arctic star nectarines", "code": "50534904" },
      { "name": "Dried non GMO arctic sweet nectarines", "code": "50534905" },
      { "name": "Dried non GMO arctic glo nectarines", "code": "50534906" },
      { "name": "Dried non GMO august fire nectarines", "code": "50534907" },
      { "name": "Dried non GMO august pearl nectarines", "code": "50534908" },
      { "name": "Dried non GMO august red nectarines", "code": "50534909" },
      { "name": "Dried non GMO autumn star nectarines", "code": "50534910" },
      { "name": "Dried non GMO big john nectarines", "code": "50534911" },
      { "name": "Dried non GMO bright pearl nectarines", "code": "50534912" },
      { "name": "Dried non GMO diamond bright nectarines", "code": "50534913" },
      { "name": "Dried non GMO diamond ray nectarines", "code": "50534914" },
      { "name": "Dried non GMO earliglo nectarines", "code": "50534915" },
      { "name": "Dried non GMO early diamond nectarines", "code": "50534916" },
      { "name": "Dried non GMO fairlane nectarines", "code": "50534917" },
      { "name": "Dried non GMO fantasia nectarines", "code": "50534918" },
      { "name": "Dried non GMO fire pearl nectarines", "code": "50534919" },
      { "name": "Dried non GMO fire sweet nectarines", "code": "50534920" },
      { "name": "Dried non GMO flamekist nectarines", "code": "50534921" },
      { "name": "Dried non GMO flat type nectarines", "code": "50534922" },
      { "name": "Dried non GMO garden delight nectarines", "code": "50534923" },
      { "name": "Dried non GMO goldmine nectarines", "code": "50534924" },
      { "name": "Dried non GMO grand pearl nectarines", "code": "50534925" },
      { "name": "Dried non GMO hardired nectarines", "code": "50534926" },
      { "name": "Dried non GMO honey blaze nectarines", "code": "50534927" },
      { "name": "Dried non GMO july red nectarines", "code": "50534928" },
      { "name": "Dried non GMO kay pearl nectarines", "code": "50534929" },
      { "name": "Dried non GMO kay sweet nectarines", "code": "50534930" },
      { "name": "Dried non GMO may diamond nectarines", "code": "50534931" },
      { "name": "Dried non GMO mayfire nectarines", "code": "50534932" },
      { "name": "Dried non GMO mayglo nectarines", "code": "50534933" },
      { "name": "Dried non GMO mericrest nectarines", "code": "50534934" },
      { "name": "Dried non GMO red diamond nectarines", "code": "50534935" },
      { "name": "Dried non GMO red gold nectarines", "code": "50534936" },
      { "name": "Dried non GMO red jim nectarines", "code": "50534937" },
      { "name": "Dried non GMO red roy nectarines", "code": "50534938" },
      { "name": "Dried non GMO rio red nectarines", "code": "50534939" },
      { "name": "Dried non GMO rose diamond nectarines", "code": "50534940" },
      { "name": "Dried non GMO royal glo nectarines", "code": "50534941" },
      { "name": "Dried non GMO ruby diamond nectarines", "code": "50534942" },
      { "name": "Dried non GMO ruby sweet nectarines", "code": "50534943" },
      { "name": "Dried non GMO ruddy jewel nectarines", "code": "50534944" },
      { "name": "Dried non GMO september red nectarines", "code": "50534945" },
      { "name": "Dried non GMO snowqueen nectarines", "code": "50534946" },
      { "name": "Dried non GMO spring bright nectarines", "code": "50534947" },
      { "name": "Dried non GMO spring red nectarines", "code": "50534948" },
      { "name": "Dried non GMO summer blush nectarines", "code": "50534949" },
      { "name": "Dried non GMO summer brite nectarines", "code": "50534950" },
      { "name": "Dried non GMO summer diamond nectarines", "code": "50534951" },
      { "name": "Dried non GMO summer fire nectarines", "code": "50534952" },
      { "name": "Dried non GMO summer grand nectarines", "code": "50534953" },
      { "name": "Dried non GMO sunglo nectarines", "code": "50534954" },
      { "name": "Dried non GMO zee fire nectarines", "code": "50534955" },
      { "name": "Dried non GMO zee glo nectarines", "code": "50534956" },
      { "name": "Dried non GMO zeegrand nectarines", "code": "50534957" },
      { "name": "Dried non GMO oranges", "code": "50535000" },
      { "name": "Dried non GMO african sour oranges", "code": "50535001" },
      { "name": "Dried non GMO ambersweet oranges", "code": "50535002" },
      { "name": "Dried non GMO argentine sour oranges", "code": "50535003" },
      { "name": "Dried non GMO bahianinha oranges", "code": "50535004" },
      { "name": "Dried non GMO bergamot oranges", "code": "50535005" },
      { "name": "Dried non GMO berna oranges", "code": "50535006" },
      { "name": "Dried non GMO bigaradier apepu oranges", "code": "50535007" },
      {
        "name": "Dried non GMO bittersweet daidai oranges",
        "code": "50535008"
      },
      { "name": "Dried non GMO blonde oranges", "code": "50535009" },
      { "name": "Dried non GMO blood oranges", "code": "50535010" },
      { "name": "Dried non GMO california navel oranges", "code": "50535011" },
      { "name": "Dried non GMO cara cara oranges", "code": "50535012" },
      { "name": "Dried non GMO chinotto oranges", "code": "50535013" },
      { "name": "Dried non GMO dream navel oranges", "code": "50535014" },
      { "name": "Dried non GMO gou tou oranges", "code": "50535015" },
      { "name": "Dried non GMO hamlin oranges", "code": "50535016" },
      { "name": "Dried non GMO jaffa oranges", "code": "50535017" },
      { "name": "Dried non GMO jincheng oranges", "code": "50535018" },
      { "name": "Dried non GMO k-early oranges", "code": "50535019" },
      { "name": "Dried non GMO kona oranges", "code": "50535020" },
      { "name": "Dried non GMO late navel oranges", "code": "50535021" },
      { "name": "Dried non GMO late valencia oranges", "code": "50535022" },
      { "name": "Dried non GMO limequat oranges", "code": "50535023" },
      { "name": "Dried non GMO marr oranges", "code": "50535024" },
      { "name": "Dried non GMO melogold oranges", "code": "50535025" },
      { "name": "Dried non GMO moro oranges", "code": "50535026" },
      { "name": "Dried non GMO moro tarocco oranges", "code": "50535027" },
      { "name": "Dried non GMO navel oranges", "code": "50535028" },
      { "name": "Dried non GMO navelina oranges", "code": "50535029" },
      { "name": "Dried non GMO oro blanco oranges", "code": "50535030" },
      { "name": "Dried non GMO osceola oranges", "code": "50535031" },
      { "name": "Dried non GMO parson brown oranges", "code": "50535032" },
      { "name": "Dried non GMO pera oranges", "code": "50535033" },
      { "name": "Dried non GMO pummulo oranges", "code": "50535034" },
      { "name": "Dried non GMO rhode red oranges", "code": "50535035" },
      { "name": "Dried non GMO roble oranges", "code": "50535036" },
      { "name": "Dried non GMO salustianas oranges", "code": "50535037" },
      { "name": "Dried non GMO sanguine oranges", "code": "50535038" },
      { "name": "Dried non GMO sanguinelli oranges", "code": "50535039" },
      { "name": "Dried non GMO seville oranges", "code": "50535040" },
      { "name": "Dried non GMO shamouti jaffa oranges", "code": "50535041" },
      { "name": "Dried non GMO tunis oranges", "code": "50535042" },
      { "name": "Dried non GMO valencia oranges", "code": "50535043" },
      { "name": "Dried non GMO washington navel oranges", "code": "50535044" },
      { "name": "Dried non GMO papayas", "code": "50535100" },
      { "name": "Dried non GMO green cooking papayas", "code": "50535101" },
      { "name": "Dried non GMO maradol papayas", "code": "50535102" },
      { "name": "Dried non GMO mexican yellow papayas", "code": "50535103" },
      { "name": "Dried non GMO mountain papayas", "code": "50535104" },
      { "name": "Dried non GMO solo papayas", "code": "50535105" },
      { "name": "Dried non GMO tainung papayas", "code": "50535106" },
      { "name": "Dried non GMO passion fruits", "code": "50535200" },
      { "name": "Dried non GMO banana passion fruit", "code": "50535201" },
      { "name": "Dried non GMO blue passion flower", "code": "50535202" },
      { "name": "Dried non GMO crackerjack passion fruit", "code": "50535203" },
      {
        "name": "Dried non GMO giant granadilla passion fruit",
        "code": "50535204"
      },
      {
        "name": "Dried non GMO golden granadilla passion fruit",
        "code": "50535205"
      },
      { "name": "Dried non GMO maypops passion fruit", "code": "50535206" },
      {
        "name": "Dried non GMO red granadilla passion fruit",
        "code": "50535207"
      },
      {
        "name": "Dried non GMO sweet granadilla passion fruit",
        "code": "50535208"
      },
      { "name": "Dried non GMO water lemon passion fruit", "code": "50535209" },
      {
        "name": "Dried non GMO wing-stemmed passion flower",
        "code": "50535210"
      },
      { "name": "Dried non GMO peaches", "code": "50535300" },
      { "name": "Dried non GMO amber crest peaches", "code": "50535301" },
      { "name": "Dried non GMO april snow peaches", "code": "50535302" },
      { "name": "Dried non GMO august lady peaches", "code": "50535303" },
      { "name": "Dried non GMO autumn flame peaches", "code": "50535304" },
      { "name": "Dried non GMO autumn lady peaches", "code": "50535305" },
      { "name": "Dried non GMO babcock peaches", "code": "50535306" },
      { "name": "Dried non GMO brittney lane peaches", "code": "50535307" },
      { "name": "Dried non GMO cary mac peaches", "code": "50535308" },
      { "name": "Dried non GMO classic peaches", "code": "50535309" },
      { "name": "Dried non GMO country sweet peaches", "code": "50535310" },
      { "name": "Dried non GMO crest haven peaches", "code": "50535311" },
      { "name": "Dried non GMO crimson lady peaches", "code": "50535312" },
      { "name": "Dried non GMO crown princess peaches", "code": "50535313" },
      { "name": "Dried non GMO david sun peaches", "code": "50535314" },
      { "name": "Dried non GMO diamond princess peaches", "code": "50535315" },
      { "name": "Dried non GMO earlirich peaches", "code": "50535316" },
      { "name": "Dried non GMO early majestic peaches", "code": "50535317" },
      { "name": "Dried non GMO early treat peaches", "code": "50535318" },
      { "name": "Dried non GMO elegant lady peaches", "code": "50535319" },
      { "name": "Dried non GMO empress peaches", "code": "50535320" },
      { "name": "Dried non GMO encore peaches", "code": "50535321" },
      { "name": "Dried non GMO fancy lady peaches", "code": "50535322" },
      { "name": "Dried non GMO fire prince peaches", "code": "50535323" },
      { "name": "Dried non GMO flame crest peaches", "code": "50535324" },
      { "name": "Dried non GMO flat type peaches", "code": "50535325" },
      { "name": "Dried non GMO flavorcrest peaches", "code": "50535326" },
      { "name": "Dried non GMO florida prince peaches", "code": "50535327" },
      { "name": "Dried non GMO full moon peaches", "code": "50535328" },
      { "name": "Dried non GMO harvester peaches", "code": "50535329" },
      { "name": "Dried non GMO ice princess peaches", "code": "50535330" },
      { "name": "Dried non GMO ivory princess peaches", "code": "50535331" },
      { "name": "Dried non GMO jersey queen peaches", "code": "50535332" },
      { "name": "Dried non GMO john henry peaches", "code": "50535333" },
      { "name": "Dried non GMO june prince peaches", "code": "50535334" },
      { "name": "Dried non GMO kaweah peaches", "code": "50535335" },
      { "name": "Dried non GMO klondike peaches", "code": "50535336" },
      { "name": "Dried non GMO lindo peaches", "code": "50535337" },
      { "name": "Dried non GMO loring peaches", "code": "50535338" },
      { "name": "Dried non GMO majestic peaches", "code": "50535339" },
      { "name": "Dried non GMO o'henry peaches", "code": "50535340" },
      { "name": "Dried non GMO queencrest peaches", "code": "50535341" },
      { "name": "Dried non GMO red lady peaches", "code": "50535342" },
      { "name": "Dried non GMO redglobe peaches", "code": "50535343" },
      { "name": "Dried non GMO redhaven peaches", "code": "50535344" },
      { "name": "Dried non GMO redtop peaches", "code": "50535345" },
      { "name": "Dried non GMO regina peaches", "code": "50535346" },
      { "name": "Dried non GMO rich lady peaches", "code": "50535347" },
      { "name": "Dried non GMO rich may peaches", "code": "50535348" },
      { "name": "Dried non GMO royal glory peaches", "code": "50535349" },
      { "name": "Dried non GMO royal lady peaches", "code": "50535350" },
      { "name": "Dried non GMO september snow peaches", "code": "50535351" },
      { "name": "Dried non GMO september sun peaches", "code": "50535352" },
      { "name": "Dried non GMO sierra gem peaches", "code": "50535353" },
      { "name": "Dried non GMO snow angel peaches", "code": "50535354" },
      { "name": "Dried non GMO snow gem peaches", "code": "50535355" },
      { "name": "Dried non GMO snow king peaches", "code": "50535356" },
      { "name": "Dried non GMO spring lady peaches", "code": "50535357" },
      { "name": "Dried non GMO spring snow peaches", "code": "50535358" },
      { "name": "Dried non GMO springcrest peaches", "code": "50535359" },
      { "name": "Dried non GMO sugar giant peaches", "code": "50535360" },
      { "name": "Dried non GMO sugar lady peaches", "code": "50535361" },
      { "name": "Dried non GMO sun bright peaches", "code": "50535362" },
      { "name": "Dried non GMO sunhigh peaches", "code": "50535363" },
      { "name": "Dried non GMO super lady peaches", "code": "50535364" },
      { "name": "Dried non GMO super rich peaches", "code": "50535365" },
      { "name": "Dried non GMO surecrop peaches", "code": "50535366" },
      { "name": "Dried non GMO sweet dream peaches", "code": "50535367" },
      { "name": "Dried non GMO sweet september peaches", "code": "50535368" },
      { "name": "Dried non GMO vista peaches", "code": "50535369" },
      { "name": "Dried non GMO white lady peaches", "code": "50535370" },
      { "name": "Dried non GMO zee lady peaches", "code": "50535371" },
      { "name": "Dried non GMO pears", "code": "50535400" },
      { "name": "Dried non GMO abate fetel pears", "code": "50535401" },
      { "name": "Dried non GMO anjou pears", "code": "50535402" },
      { "name": "Dried non GMO asian pears", "code": "50535403" },
      { "name": "Dried non GMO bartlett pears", "code": "50535404" },
      { "name": "Dried non GMO best ever pears", "code": "50535405" },
      { "name": "Dried non GMO beth pears", "code": "50535406" },
      { "name": "Dried non GMO beurre pears", "code": "50535407" },
      { "name": "Dried non GMO bosc pears", "code": "50535408" },
      { "name": "Dried non GMO clapp favorite pears", "code": "50535409" },
      { "name": "Dried non GMO comice pears", "code": "50535410" },
      { "name": "Dried non GMO concorde pears", "code": "50535411" },
      { "name": "Dried non GMO conference pears", "code": "50535412" },
      { "name": "Dried non GMO crimson red pears", "code": "50535413" },
      { "name": "Dried non GMO d'anjou pears", "code": "50535414" },
      { "name": "Dried non GMO dr jules guyot pears", "code": "50535415" },
      { "name": "Dried non GMO early pears", "code": "50535416" },
      { "name": "Dried non GMO emperor brown pears", "code": "50535417" },
      { "name": "Dried non GMO forelle pears", "code": "50535418" },
      { "name": "Dried non GMO french butter pears", "code": "50535419" },
      { "name": "Dried non GMO glou morceau pears", "code": "50535420" },
      { "name": "Dried non GMO hosui pears", "code": "50535421" },
      { "name": "Dried non GMO italian butter pears", "code": "50535422" },
      { "name": "Dried non GMO jargonelle pears", "code": "50535423" },
      { "name": "Dried non GMO juno pears", "code": "50535424" },
      {
        "name": "Dried non GMO kaiserlouise bonne de jersey pears",
        "code": "50535425"
      },
      { "name": "Dried non GMO keiffer pears", "code": "50535426" },
      { "name": "Dried non GMO kings royal pears", "code": "50535427" },
      { "name": "Dried non GMO limonera pears", "code": "50535428" },
      { "name": "Dried non GMO merton pride pears", "code": "50535429" },
      { "name": "Dried non GMO mountain bartlett pears", "code": "50535430" },
      { "name": "Dried non GMO olivier de serres pears", "code": "50535431" },
      { "name": "Dried non GMO onward pears", "code": "50535432" },
      { "name": "Dried non GMO packham's triumph pears", "code": "50535433" },
      { "name": "Dried non GMO paraiso pears", "code": "50535434" },
      { "name": "Dried non GMO passe crasanne pears", "code": "50535435" },
      { "name": "Dried non GMO perry pears", "code": "50535436" },
      { "name": "Dried non GMO red bartlett pears", "code": "50535437" },
      { "name": "Dried non GMO red d'anjou pears", "code": "50535438" },
      { "name": "Dried non GMO rocha pears", "code": "50535439" },
      { "name": "Dried non GMO rosey red pears", "code": "50535440" },
      { "name": "Dried non GMO rosy red pears", "code": "50535441" },
      { "name": "Dried non GMO royal majestic pears", "code": "50535442" },
      { "name": "Dried non GMO ruby red pears", "code": "50535443" },
      { "name": "Dried non GMO santa maria pears", "code": "50535444" },
      { "name": "Dried non GMO seckel pears", "code": "50535445" },
      { "name": "Dried non GMO sensation pears", "code": "50535446" },
      { "name": "Dried non GMO star crimson pears", "code": "50535447" },
      { "name": "Dried non GMO stark crimson pears", "code": "50535448" },
      { "name": "Dried non GMO summer bartlett pears", "code": "50535449" },
      { "name": "Dried non GMO summer gold pears", "code": "50535450" },
      { "name": "Dried non GMO sun gold pears", "code": "50535451" },
      { "name": "Dried non GMO sunsprite pears", "code": "50535452" },
      { "name": "Dried non GMO taylors gold pears", "code": "50535453" },
      { "name": "Dried non GMO taylors red pears", "code": "50535454" },
      { "name": "Dried non GMO tientsin pears", "code": "50535455" },
      { "name": "Dried non GMO tosca pears", "code": "50535456" },
      { "name": "Dried non GMO warden pears", "code": "50535457" },
      {
        "name": "Dried non GMO williams bon chretien pears",
        "code": "50535458"
      },
      { "name": "Dried non GMO williams pears", "code": "50535459" },
      { "name": "Dried non GMO winter nelis pears", "code": "50535460" },
      { "name": "Dried non GMO persimmons", "code": "50535500" },
      { "name": "Dried non GMO american persimmon", "code": "50535501" },
      { "name": "Dried non GMO black sapote persimmon", "code": "50535502" },
      { "name": "Dried non GMO chapote/black persimmon", "code": "50535503" },
      { "name": "Dried non GMO date plum persimmon", "code": "50535504" },
      { "name": "Dried non GMO fuyu persimmons", "code": "50535505" },
      { "name": "Dried non GMO giant fuyu persimmons", "code": "50535506" },
      { "name": "Dried non GMO hachiya persimmons", "code": "50535507" },
      {
        "name": "Dried non GMO mabolo/butter fruit persimmon",
        "code": "50535508"
      },
      { "name": "Dried non GMO principe ito persimmons", "code": "50535509" },
      {
        "name": "Dried non GMO royal brillante persimmons",
        "code": "50535510"
      },
      { "name": "Dried non GMO sharon fruit persimmon", "code": "50535511" },
      { "name": "Dried non GMO triumph persimmons", "code": "50535512" },
      { "name": "Dried non GMO pineapples", "code": "50535600" },
      { "name": "Dried non GMO cherimoya pineapple", "code": "50535601" },
      { "name": "Dried non GMO golden pineapple", "code": "50535602" },
      { "name": "Dried non GMO hilo pineapple", "code": "50535603" },
      { "name": "Dried non GMO kona sugarloaf pineapple", "code": "50535604" },
      { "name": "Dried non GMO natal queen pineapple", "code": "50535605" },
      { "name": "Dried non GMO pernambuco pineapple", "code": "50535606" },
      { "name": "Dried non GMO red spanish pineapple", "code": "50535607" },
      { "name": "Dried non GMO smooth cayenne pineapple", "code": "50535608" },
      { "name": "Dried non GMO sugarloaf pineapple", "code": "50535609" },
      { "name": "Dried non GMO variegated pineapple", "code": "50535610" },
      { "name": "Dried non GMO plucots", "code": "50535700" },
      { "name": "Dried non GMO black kat plucot", "code": "50535701" },
      { "name": "Dried non GMO blue gusto plucot", "code": "50535702" },
      { "name": "Dried non GMO crimson heart plucot", "code": "50535703" },
      { "name": "Dried non GMO dapple dandy plucot", "code": "50535704" },
      { "name": "Dried non GMO dapple fire plucot", "code": "50535705" },
      { "name": "Dried non GMO early dapple plucot", "code": "50535706" },
      { "name": "Dried non GMO flavor fall plucot", "code": "50535707" },
      { "name": "Dried non GMO flavor gold plucot", "code": "50535708" },
      { "name": "Dried non GMO flavor grenade plucot", "code": "50535709" },
      { "name": "Dried non GMO flavor heart plucot", "code": "50535710" },
      { "name": "Dried non GMO flavor jewel plucot", "code": "50535711" },
      { "name": "Dried non GMO flavor king plucot", "code": "50535712" },
      { "name": "Dried non GMO flavor queen plucot", "code": "50535713" },
      { "name": "Dried non GMO flavor supreme plucot", "code": "50535714" },
      { "name": "Dried non GMO flavor treat plucot", "code": "50535715" },
      { "name": "Dried non GMO flavorella plucot", "code": "50535716" },
      { "name": "Dried non GMO flavorich plucot", "code": "50535717" },
      { "name": "Dried non GMO flavorosa plucot", "code": "50535718" },
      { "name": "Dried non GMO geo pride plucot", "code": "50535719" },
      { "name": "Dried non GMO red kat plucot", "code": "50535720" },
      { "name": "Dried non GMO royal treat plucot", "code": "50535721" },
      { "name": "Dried non GMO sierra rose plucot", "code": "50535722" },
      { "name": "Dried non GMO sweet geisha plucot", "code": "50535723" },
      { "name": "Dried non GMO plums", "code": "50535800" },
      { "name": "Dried non GMO amber jewel plums", "code": "50535801" },
      { "name": "Dried non GMO angeleno plums", "code": "50535802" },
      { "name": "Dried non GMO aurora plums", "code": "50535803" },
      { "name": "Dried non GMO autumn beaut plums", "code": "50535804" },
      { "name": "Dried non GMO autumn giant plums", "code": "50535805" },
      { "name": "Dried non GMO autumn pride plums", "code": "50535806" },
      { "name": "Dried non GMO autumn rosa plums", "code": "50535807" },
      { "name": "Dried non GMO beach plum", "code": "50535808" },
      { "name": "Dried non GMO betty anne plums", "code": "50535809" },
      { "name": "Dried non GMO black beaut plums", "code": "50535810" },
      { "name": "Dried non GMO black bullace plum", "code": "50535811" },
      { "name": "Dried non GMO black diamond plums", "code": "50535812" },
      { "name": "Dried non GMO black giant plums", "code": "50535813" },
      { "name": "Dried non GMO black ice plums", "code": "50535814" },
      { "name": "Dried non GMO black splendor plums", "code": "50535815" },
      { "name": "Dried non GMO blackamber plums", "code": "50535816" },
      { "name": "Dried non GMO burgundy plums", "code": "50535817" },
      { "name": "Dried non GMO carlsbad plum", "code": "50535818" },
      { "name": "Dried non GMO casselman plums", "code": "50535819" },
      { "name": "Dried non GMO catalina plums", "code": "50535820" },
      { "name": "Dried non GMO damson plum", "code": "50535821" },
      { "name": "Dried non GMO dolly plums", "code": "50535822" },
      { "name": "Dried non GMO earliqueen plums", "code": "50535823" },
      { "name": "Dried non GMO early rosa plums", "code": "50535824" },
      { "name": "Dried non GMO ebony may plums", "code": "50535825" },
      { "name": "Dried non GMO ebony plums", "code": "50535826" },
      { "name": "Dried non GMO elephant heart plums", "code": "50535827" },
      { "name": "Dried non GMO emerald beaut plums", "code": "50535828" },
      { "name": "Dried non GMO empress plums", "code": "50535829" },
      { "name": "Dried non GMO freedom plums", "code": "50535830" },
      { "name": "Dried non GMO friar plums", "code": "50535831" },
      { "name": "Dried non GMO gar red plums", "code": "50535832" },
      { "name": "Dried non GMO governor's plum", "code": "50535833" },
      { "name": "Dried non GMO grand rosa plums", "code": "50535834" },
      { "name": "Dried non GMO green gage plum", "code": "50535835" },
      { "name": "Dried non GMO greengage plums", "code": "50535836" },
      { "name": "Dried non GMO hiromi plums", "code": "50535837" },
      { "name": "Dried non GMO hiromi red plums", "code": "50535838" },
      { "name": "Dried non GMO holiday plums", "code": "50535839" },
      { "name": "Dried non GMO howard sun plums", "code": "50535840" },
      { "name": "Dried non GMO interspecific type plums", "code": "50535841" },
      { "name": "Dried non GMO jamaican plum", "code": "50535842" },
      { "name": "Dried non GMO joanna red plums", "code": "50535843" },
      { "name": "Dried non GMO kelsey plums", "code": "50535844" },
      { "name": "Dried non GMO king james plums", "code": "50535845" },
      { "name": "Dried non GMO laroda plums", "code": "50535846" },
      { "name": "Dried non GMO late rosa plums", "code": "50535847" },
      { "name": "Dried non GMO linda rosa plums", "code": "50535848" },
      { "name": "Dried non GMO lone star red plums", "code": "50535849" },
      { "name": "Dried non GMO mariposa plums", "code": "50535850" },
      { "name": "Dried non GMO marked black plums", "code": "50535851" },
      { "name": "Dried non GMO marked red plums", "code": "50535852" },
      { "name": "Dried non GMO mirabelle plum", "code": "50535853" },
      { "name": "Dried non GMO october sun plums", "code": "50535854" },
      { "name": "Dried non GMO owen t plums", "code": "50535855" },
      { "name": "Dried non GMO perdrigon plum", "code": "50535856" },
      { "name": "Dried non GMO pink delight plums", "code": "50535857" },
      { "name": "Dried non GMO president plums", "code": "50535858" },
      { "name": "Dried non GMO primetime plums", "code": "50535859" },
      { "name": "Dried non GMO purple majesty plums", "code": "50535860" },
      { "name": "Dried non GMO queen rosa plums", "code": "50535861" },
      { "name": "Dried non GMO quetsch plum", "code": "50535862" },
      { "name": "Dried non GMO red beaut plums", "code": "50535863" },
      { "name": "Dried non GMO red lane plums", "code": "50535864" },
      { "name": "Dried non GMO red ram plums", "code": "50535865" },
      { "name": "Dried non GMO red rosa plums", "code": "50535866" },
      { "name": "Dried non GMO rich red plums", "code": "50535867" },
      { "name": "Dried non GMO rosemary plums", "code": "50535868" },
      { "name": "Dried non GMO royal diamond plums", "code": "50535869" },
      { "name": "Dried non GMO royal red plums", "code": "50535870" },
      { "name": "Dried non GMO royal zee plums", "code": "50535871" },
      { "name": "Dried non GMO roysum plums", "code": "50535872" },
      { "name": "Dried non GMO santa rosa plums", "code": "50535873" },
      { "name": "Dried non GMO saphire plums", "code": "50535874" },
      { "name": "Dried non GMO sloe plum", "code": "50535875" },
      { "name": "Dried non GMO st catherine plum", "code": "50535876" },
      { "name": "Dried non GMO white bullace plum", "code": "50535877" },
      { "name": "Dried non GMO pomegranates", "code": "50535900" },
      { "name": "Dried non GMO foothill pomegranates", "code": "50535901" },
      { "name": "Dried non GMO granada pomegranates", "code": "50535902" },
      { "name": "Dried non GMO jolly red pomegranates", "code": "50535903" },
      { "name": "Dried non GMO nana pomegranates", "code": "50535904" },
      { "name": "Dried non GMO pat's red pomegranates", "code": "50535905" },
      { "name": "Dried non GMO pinkhan pomegranates", "code": "50535906" },
      {
        "name": "Dried non GMO purple velvet pomegranates",
        "code": "50535907"
      },
      { "name": "Dried non GMO wonderful pomegranates", "code": "50535908" },
      { "name": "Dried non GMO pomelos", "code": "50536000" },
      { "name": "Dried non GMO chandler pomelo", "code": "50536001" },
      { "name": "Dried non GMO hirado buntan pomelo", "code": "50536002" },
      { "name": "Dried non GMO liang ping yau pomelo", "code": "50536003" },
      { "name": "Dried non GMO pandan wangi pomelo", "code": "50536004" },
      { "name": "Dried non GMO pink pomelo", "code": "50536005" },
      { "name": "Dried non GMO red shaddock pomelo", "code": "50536006" },
      { "name": "Dried non GMO siamese sweet pomelo", "code": "50536007" },
      { "name": "Dried non GMO wainwright pomelo", "code": "50536008" },
      { "name": "Dried non GMO quinces", "code": "50536100" },
      { "name": "Dried non GMO champion quince", "code": "50536101" },
      { "name": "Dried non GMO pineapple quince", "code": "50536102" },
      { "name": "Dried non GMO smyrna quince", "code": "50536103" },
      { "name": "Dried non GMO raspberries", "code": "50536200" },
      { "name": "Dried non GMO american red raspberry", "code": "50536201" },
      {
        "name": "Dried non GMO bailey queensland raspberry",
        "code": "50536202"
      },
      { "name": "Dried non GMO black raspberry", "code": "50536203" },
      { "name": "Dried non GMO dark raspberry", "code": "50536204" },
      { "name": "Dried non GMO delicious raspberry", "code": "50536205" },
      { "name": "Dried non GMO focke dwarf raspberry", "code": "50536206" },
      {
        "name": "Dried non GMO focke grayleaf red raspberry",
        "code": "50536207"
      },
      {
        "name": "Dried non GMO focke strawberry raspberry",
        "code": "50536208"
      },
      {
        "name": "Dried non GMO focke yellow himalayan raspberry",
        "code": "50536209"
      },
      { "name": "Dried non GMO gold raspberry", "code": "50536210" },
      { "name": "Dried non GMO gray new mexico raspberry", "code": "50536211" },
      {
        "name": "Dried non GMO jepson whitebark raspberry",
        "code": "50536212"
      },
      {
        "name": "Dried non GMO kellogg san diego raspberry",
        "code": "50536213"
      },
      {
        "name": "Dried non GMO leucodermis whitebark raspberry",
        "code": "50536214"
      },
      { "name": "Dried non GMO munz cuyamaca raspberry", "code": "50536215" },
      { "name": "Dried non GMO peck barton's raspberry", "code": "50536216" },
      { "name": "Dried non GMO purpleflowering raspberry", "code": "50536217" },
      { "name": "Dried non GMO roadside raspberry", "code": "50536218" },
      { "name": "Dried non GMO san diego raspberry", "code": "50536219" },
      { "name": "Dried non GMO snow raspberry", "code": "50536220" },
      { "name": "Dried non GMO snowpeaks raspberry", "code": "50536221" },
      { "name": "Dried non GMO strawberryleaf raspberry", "code": "50536222" },
      {
        "name": "Dried non GMO sweet cultivated raspberry",
        "code": "50536223"
      },
      {
        "name": "Dried non GMO torr and gray whitebark raspberry",
        "code": "50536224"
      },
      { "name": "Dried non GMO west indian raspberry", "code": "50536225" },
      { "name": "Dried non GMO whitebark raspberry", "code": "50536226" },
      { "name": "Dried non GMO wine raspberry", "code": "50536227" },
      {
        "name": "Dried non GMO yellow himalayan raspberry",
        "code": "50536228"
      },
      { "name": "Dried non GMO yu-shan raspberry", "code": "50536229" },
      { "name": "Dried non GMO rhubarbs", "code": "50536300" },
      { "name": "Dried non GMO crimson red rhubarb", "code": "50536301" },
      { "name": "Dried non GMO early champagne rhubarb", "code": "50536302" },
      {
        "name": "Dried non GMO glaskin's perpetual rhubarb",
        "code": "50536303"
      },
      { "name": "Dried non GMO sutton rhubarb", "code": "50536304" },
      { "name": "Dried non GMO timperley early rhubarb", "code": "50536305" },
      { "name": "Dried non GMO valentine rhubarb", "code": "50536306" },
      { "name": "Dried non GMO victoria rhubarb", "code": "50536307" },
      { "name": "Dried non GMO zwolle seedling rhubarb", "code": "50536308" },
      { "name": "Dried non GMO macdonald rhubarb", "code": "50536309" },
      { "name": "Dried non GMO tilden rhubarb", "code": "50536310" },
      { "name": "Dried non GMO rose hips", "code": "50536400" },
      { "name": "Dried non GMO brier rose hips", "code": "50536401" },
      { "name": "Dried non GMO elgantine rose hips", "code": "50536402" },
      { "name": "Dried non GMO rugosa rose hips", "code": "50536403" },
      {
        "name": "Dried non GMO scotch or burnet rose hips",
        "code": "50536404"
      },
      { "name": "Dried non GMO sapotes", "code": "50536500" },
      { "name": "Dried non GMO white sapotes", "code": "50536501" },
      { "name": "Dried non GMO black sapotes", "code": "50536502" },
      { "name": "Dried non GMO saskatoon berries", "code": "50536600" },
      {
        "name": "Dried non GMO honeywood saskatoon berries",
        "code": "50536601"
      },
      {
        "name": "Dried non GMO northline saskatoon berries",
        "code": "50536602"
      },
      { "name": "Dried non GMO smoky saskatoon berries", "code": "50536603" },
      {
        "name": "Dried non GMO thiessen saskatoon berries",
        "code": "50536604"
      },
      { "name": "Dried non GMO strawberries", "code": "50536700" },
      { "name": "Dried non GMO chandler strawberries", "code": "50536701" },
      { "name": "Dried non GMO june bearing strawberries", "code": "50536702" },
      { "name": "Dried non GMO ever bearing strawberries", "code": "50536703" },
      { "name": "Dried non GMO sugar apples", "code": "50536800" },
      { "name": "Dried non GMO kampong mauve sugar apple", "code": "50536801" },
      { "name": "Dried non GMO seedless sugar apple", "code": "50536802" },
      { "name": "Dried non GMO thai lessard sugar apple", "code": "50536803" },
      { "name": "Dried non GMO tamarillos", "code": "50536900" },
      { "name": "Dried non GMO amberlea gold tamarillo", "code": "50536901" },
      { "name": "Dried non GMO bold gold tamarillo", "code": "50536902" },
      { "name": "Dried non GMO goldmine tamarillo", "code": "50536903" },
      { "name": "Dried non GMO oratia red tamarillo", "code": "50536904" },
      { "name": "Dried non GMO red beau tamarillo", "code": "50536905" },
      { "name": "Dried non GMO red delight tamarillo", "code": "50536906" },
      { "name": "Dried non GMO nominant fruits", "code": "50537000" },
      { "name": "Dried non GMO akee", "code": "50537001" },
      { "name": "Dried non GMO babaco", "code": "50537002" },
      { "name": "Dried non GMO banana flowers", "code": "50537003" },
      { "name": "Dried non GMO baobab", "code": "50537004" },
      { "name": "Dried non GMO bitter oranges", "code": "50537005" },
      { "name": "Dried non GMO canistel", "code": "50537006" },
      { "name": "Dried non GMO coconuts", "code": "50537007" },
      { "name": "Dried non GMO cloudberries", "code": "50537008" },
      { "name": "Dried non GMO dewberries", "code": "50537009" },
      { "name": "Dried non GMO durian", "code": "50537010" },
      { "name": "Dried non GMO elderberries", "code": "50537011" },
      { "name": "Dried non GMO feijoa", "code": "50537012" },
      { "name": "Dried non GMO hackberries", "code": "50537013" },
      { "name": "Dried non GMO hawthorn", "code": "50537014" },
      { "name": "Dried non GMO honeyberries", "code": "50537015" },
      { "name": "Dried non GMO jackfruit", "code": "50537016" },
      { "name": "Dried non GMO jambolan", "code": "50537017" },
      { "name": "Dried non GMO jujube", "code": "50537018" },
      { "name": "Dried non GMO lychee", "code": "50537019" },
      { "name": "Dried non GMO mangosteens", "code": "50537020" },
      { "name": "Dried non GMO medlars", "code": "50537021" },
      { "name": "Dried non GMO mombins", "code": "50537022" },
      { "name": "Dried non GMO monstera", "code": "50537023" },
      { "name": "Dried non GMO pepinos", "code": "50537024" },
      { "name": "Dried non GMO plantains", "code": "50537025" },
      { "name": "Dried non GMO prickly pears", "code": "50537026" },
      { "name": "Dried non GMO quenepas", "code": "50537027" },
      { "name": "Dried non GMO rambutan", "code": "50537028" },
      { "name": "Dried non GMO rose apples", "code": "50537029" },
      { "name": "Dried non GMO roselle", "code": "50537030" },
      { "name": "Dried non GMO rowanberries", "code": "50537031" },
      { "name": "Dried non GMO sea buckhorn berries", "code": "50537032" },
      { "name": "Dried non GMO silverberries", "code": "50537033" },
      { "name": "Dried non GMO sorb berries", "code": "50537034" },
      { "name": "Dried non GMO soursops", "code": "50537035" },
      { "name": "Dried non GMO star apples", "code": "50537036" },
      { "name": "Dried non GMO tamarindo", "code": "50537037" },
      { "name": "Dried non GMO chokeberries", "code": "50537100" },
      { "name": "Dried non GMO autumn magic chokeberries", "code": "50537101" },
      {
        "name": "Dried non GMO brillantisima chokeberries",
        "code": "50537102"
      },
      { "name": "Dried non GMO nero chokeberries", "code": "50537103" },
      { "name": "Dried non GMO viking chokeberries", "code": "50537104" },
      { "name": "Dried non GMO olives", "code": "50537200" },
      { "name": "Dried non GMO agrinion olives", "code": "50537201" },
      { "name": "Dried non GMO aleppo olives", "code": "50537202" },
      { "name": "Dried non GMO alphonso olives", "code": "50537203" },
      { "name": "Dried non GMO amphissa olives", "code": "50537204" },
      { "name": "Dried non GMO arauco olives", "code": "50537205" },
      { "name": "Dried non GMO arbequina olives", "code": "50537206" },
      { "name": "Dried non GMO atalanta olives", "code": "50537207" },
      { "name": "Dried non GMO cerignola olives", "code": "50537208" },
      { "name": "Dried non GMO cracked provencal olives", "code": "50537209" },
      { "name": "Dried non GMO empeltre olives", "code": "50537210" },
      { "name": "Dried non GMO gaeta olives", "code": "50537211" },
      { "name": "Dried non GMO hondroelia olives", "code": "50537212" },
      { "name": "Dried non GMO kalamata olives", "code": "50537213" },
      { "name": "Dried non GMO kura olives", "code": "50537214" },
      { "name": "Dried non GMO ligurian olives", "code": "50537215" },
      { "name": "Dried non GMO lucque olives", "code": "50537216" },
      { "name": "Dried non GMO lugano olives", "code": "50537217" },
      { "name": "Dried non GMO manzanilla olives", "code": "50537218" },
      { "name": "Dried non GMO marche olives", "code": "50537219" },
      { "name": "Dried non GMO mission olives", "code": "50537220" },
      { "name": "Dried non GMO nafplion green olives", "code": "50537221" },
      { "name": "Dried non GMO nicoise olives", "code": "50537222" },
      { "name": "Dried non GMO nyons olives", "code": "50537223" },
      { "name": "Dried non GMO picholine olives", "code": "50537224" },
      { "name": "Dried non GMO ponentine olives", "code": "50537225" },
      { "name": "Dried non GMO royal olives", "code": "50537226" },
      { "name": "Dried non GMO seracena olives", "code": "50537227" },
      { "name": "Dried non GMO sevillano olives", "code": "50537228" },
      { "name": "Dried non GMO sicilian olives", "code": "50537229" },
      { "name": "Dried non GMO toscanelle olives", "code": "50537230" },
      { "name": "Frozen non GMO fruits", "code": "50540000" },
      { "name": "Frozen non GMO apples", "code": "50541500" },
      { "name": "Frozen non GMO akane apples", "code": "50541501" },
      { "name": "Frozen non GMO ambrosia apples", "code": "50541502" },
      { "name": "Frozen non GMO api apples", "code": "50541503" },
      { "name": "Frozen non GMO baldwin apples", "code": "50541504" },
      { "name": "Frozen non GMO braeburn apples", "code": "50541505" },
      { "name": "Frozen non GMO bramley apples", "code": "50541506" },
      { "name": "Frozen non GMO bramley seedling apples", "code": "50541507" },
      {
        "name": "Frozen non GMO calville blanche d'hiver apples",
        "code": "50541508"
      },
      { "name": "Frozen non GMO cameo apples", "code": "50541509" },
      { "name": "Frozen non GMO charles ross apples", "code": "50541510" },
      { "name": "Frozen non GMO codlin apples", "code": "50541511" },
      { "name": "Frozen non GMO cortland apples", "code": "50541512" },
      { "name": "Frozen non GMO costard apples", "code": "50541513" },
      { "name": "Frozen non GMO court pendu plat apples", "code": "50541514" },
      {
        "name": "Frozen non GMO cox's orange pippin apples",
        "code": "50541515"
      },
      { "name": "Frozen non GMO crab apples", "code": "50541516" },
      { "name": "Frozen non GMO crispin apples", "code": "50541517" },
      { "name": "Frozen non GMO delicious apples", "code": "50541518" },
      { "name": "Frozen non GMO duchess apples", "code": "50541519" },
      { "name": "Frozen non GMO earligold apples", "code": "50541520" },
      { "name": "Frozen non GMO early mcintosh apples", "code": "50541521" },
      { "name": "Frozen non GMO elstar apples", "code": "50541522" },
      { "name": "Frozen non GMO empire apples", "code": "50541523" },
      { "name": "Frozen non GMO flower of kent apples", "code": "50541524" },
      { "name": "Frozen non GMO fuji apples", "code": "50541525" },
      { "name": "Frozen non GMO gala apples", "code": "50541526" },
      {
        "name": "Frozen non GMO gascoyne's scarlet apples",
        "code": "50541527"
      },
      { "name": "Frozen non GMO gilliflower apples", "code": "50541528" },
      { "name": "Frozen non GMO ginger gold apples", "code": "50541529" },
      { "name": "Frozen non GMO gladstone apples", "code": "50541530" },
      { "name": "Frozen non GMO gloster apples", "code": "50541531" },
      { "name": "Frozen non GMO gold supreme apples", "code": "50541532" },
      { "name": "Frozen non GMO golden delicious apples", "code": "50541533" },
      { "name": "Frozen non GMO golden noble apples", "code": "50541534" },
      { "name": "Frozen non GMO granny smith apples", "code": "50541535" },
      { "name": "Frozen non GMO gravenstein apples", "code": "50541536" },
      { "name": "Frozen non GMO greening apples", "code": "50541537" },
      { "name": "Frozen non GMO greensleeves apples", "code": "50541538" },
      { "name": "Frozen non GMO honeycrisp apples", "code": "50541539" },
      { "name": "Frozen non GMO howgate wonder apples", "code": "50541540" },
      { "name": "Frozen non GMO ida red apples", "code": "50541541" },
      { "name": "Frozen non GMO james grieve apples", "code": "50541542" },
      { "name": "Frozen non GMO jersey mac apples", "code": "50541543" },
      { "name": "Frozen non GMO jester apples", "code": "50541544" },
      { "name": "Frozen non GMO jonagold apples", "code": "50541545" },
      { "name": "Frozen non GMO jonamac apples", "code": "50541546" },
      { "name": "Frozen non GMO jonathan apples", "code": "50541547" },
      { "name": "Frozen non GMO katy apples", "code": "50541548" },
      { "name": "Frozen non GMO kidd's orange red apples", "code": "50541549" },
      { "name": "Frozen non GMO lady apples", "code": "50541550" },
      { "name": "Frozen non GMO law rome apples", "code": "50541551" },
      { "name": "Frozen non GMO laxton apples", "code": "50541552" },
      { "name": "Frozen non GMO lord derby apples", "code": "50541553" },
      { "name": "Frozen non GMO macoun apples", "code": "50541554" },
      { "name": "Frozen non GMO mcintosh apples", "code": "50541555" },
      { "name": "Frozen non GMO mutsu apples", "code": "50541556" },
      { "name": "Frozen non GMO newtown pippin apples", "code": "50541557" },
      { "name": "Frozen non GMO northern spy apples", "code": "50541558" },
      { "name": "Frozen non GMO orleans reinette apples", "code": "50541559" },
      { "name": "Frozen non GMO ozark gold apples", "code": "50541560" },
      { "name": "Frozen non GMO pacific rose apples", "code": "50541561" },
      { "name": "Frozen non GMO paula red apples", "code": "50541562" },
      { "name": "Frozen non GMO pearmain apples", "code": "50541563" },
      { "name": "Frozen non GMO pink lady apples", "code": "50541564" },
      { "name": "Frozen non GMO pippin apples", "code": "50541565" },
      {
        "name": "Frozen non GMO pitmaston pineapple apples",
        "code": "50541566"
      },
      { "name": "Frozen non GMO pomme d'api apples", "code": "50541567" },
      { "name": "Frozen non GMO prime gold apples", "code": "50541568" },
      { "name": "Frozen non GMO red astrachan apples", "code": "50541569" },
      { "name": "Frozen non GMO red boscoop apples", "code": "50541570" },
      { "name": "Frozen non GMO red chief apples", "code": "50541571" },
      { "name": "Frozen non GMO red delicious apples", "code": "50541572" },
      { "name": "Frozen non GMO red gravenstein apples", "code": "50541573" },
      { "name": "Frozen non GMO red rome apples", "code": "50541574" },
      { "name": "Frozen non GMO red stayman apples", "code": "50541575" },
      { "name": "Frozen non GMO red york apples", "code": "50541576" },
      { "name": "Frozen non GMO reinette apples", "code": "50541577" },
      { "name": "Frozen non GMO rome beauty apples", "code": "50541578" },
      { "name": "Frozen non GMO russet apples", "code": "50541579" },
      { "name": "Frozen non GMO sierra beauty apples", "code": "50541580" },
      { "name": "Frozen non GMO spartan apples", "code": "50541581" },
      { "name": "Frozen non GMO stark crimson apples", "code": "50541582" },
      { "name": "Frozen non GMO starking apples", "code": "50541583" },
      { "name": "Frozen non GMO stayman apples", "code": "50541584" },
      { "name": "Frozen non GMO stayman winesap apples", "code": "50541585" },
      { "name": "Frozen non GMO summer rambo apples", "code": "50541586" },
      { "name": "Frozen non GMO tsugaru apples", "code": "50541587" },
      { "name": "Frozen non GMO twenty ounce apples", "code": "50541588" },
      { "name": "Frozen non GMO tydeman red apples", "code": "50541589" },
      { "name": "Frozen non GMO vistabella apples", "code": "50541590" },
      { "name": "Frozen non GMO wealthy apples", "code": "50541591" },
      { "name": "Frozen non GMO white joaneting apples", "code": "50541592" },
      { "name": "Frozen non GMO white transparent apples", "code": "50541593" },
      { "name": "Frozen non GMO winesap apples", "code": "50541594" },
      { "name": "Frozen non GMO worcester apples", "code": "50541595" },
      { "name": "Frozen non GMO york imperial apples", "code": "50541596" },
      { "name": "Frozen non GMO apricots", "code": "50541600" },
      { "name": "Frozen non GMO ambercot apricots", "code": "50541601" },
      { "name": "Frozen non GMO apache apricots", "code": "50541602" },
      { "name": "Frozen non GMO brittany gold apricots", "code": "50541603" },
      { "name": "Frozen non GMO black apricots", "code": "50541604" },
      { "name": "Frozen non GMO blenheim apricots", "code": "50541605" },
      { "name": "Frozen non GMO bonny apricots", "code": "50541606" },
      { "name": "Frozen non GMO bulida apricots", "code": "50541607" },
      { "name": "Frozen non GMO castlebrite apricots", "code": "50541608" },
      { "name": "Frozen non GMO clutha gold apricots", "code": "50541609" },
      { "name": "Frozen non GMO clutha sun apricots", "code": "50541610" },
      { "name": "Frozen non GMO darby royal apricots", "code": "50541611" },
      { "name": "Frozen non GMO dina apricots", "code": "50541612" },
      { "name": "Frozen non GMO earlicot apricots", "code": "50541613" },
      { "name": "Frozen non GMO earliman apricots", "code": "50541614" },
      { "name": "Frozen non GMO early bright apricots", "code": "50541615" },
      { "name": "Frozen non GMO flaming gold apricots", "code": "50541616" },
      { "name": "Frozen non GMO fresno apricots", "code": "50541617" },
      { "name": "Frozen non GMO gold brite apricots", "code": "50541618" },
      { "name": "Frozen non GMO goldbar apricots", "code": "50541619" },
      { "name": "Frozen non GMO golden sweet apricots", "code": "50541620" },
      { "name": "Frozen non GMO goldrich apricots", "code": "50541621" },
      { "name": "Frozen non GMO helena apricots", "code": "50541622" },
      { "name": "Frozen non GMO honeycot apricots", "code": "50541623" },
      { "name": "Frozen non GMO imperial apricots", "code": "50541624" },
      { "name": "Frozen non GMO jordanne apricots", "code": "50541625" },
      { "name": "Frozen non GMO jumbo cot apricots", "code": "50541626" },
      { "name": "Frozen non GMO kandy kot apricots", "code": "50541627" },
      { "name": "Frozen non GMO katy apricots", "code": "50541628" },
      { "name": "Frozen non GMO king apricots", "code": "50541629" },
      { "name": "Frozen non GMO lambertin apricots", "code": "50541630" },
      { "name": "Frozen non GMO lorna apricots", "code": "50541631" },
      { "name": "Frozen non GMO lulu belle apricots", "code": "50541632" },
      { "name": "Frozen non GMO modesto apricots", "code": "50541633" },
      { "name": "Frozen non GMO moorpark apricots", "code": "50541634" },
      { "name": "Frozen non GMO orangered apricots", "code": "50541635" },
      { "name": "Frozen non GMO palstein apricots", "code": "50541636" },
      { "name": "Frozen non GMO patterson apricots", "code": "50541637" },
      { "name": "Frozen non GMO perfection apricots", "code": "50541638" },
      { "name": "Frozen non GMO poppy apricots", "code": "50541639" },
      { "name": "Frozen non GMO poppycot apricots", "code": "50541640" },
      { "name": "Frozen non GMO queen apricots", "code": "50541641" },
      { "name": "Frozen non GMO riland apricots", "code": "50541642" },
      { "name": "Frozen non GMO rival apricots", "code": "50541643" },
      { "name": "Frozen non GMO robada apricots", "code": "50541644" },
      { "name": "Frozen non GMO royal apricots", "code": "50541645" },
      { "name": "Frozen non GMO royal blenheim apricots", "code": "50541646" },
      { "name": "Frozen non GMO royal orange apricots", "code": "50541647" },
      { "name": "Frozen non GMO sundrop apricots", "code": "50541648" },
      { "name": "Frozen non GMO tilton apricots", "code": "50541649" },
      { "name": "Frozen non GMO tomcot apricots", "code": "50541650" },
      { "name": "Frozen non GMO tracy apricots", "code": "50541651" },
      { "name": "Frozen non GMO tri gem apricots", "code": "50541652" },
      { "name": "Frozen non GMO valley gold apricots", "code": "50541653" },
      { "name": "Frozen non GMO westley apricots", "code": "50541654" },
      { "name": "Frozen non GMO york apricots", "code": "50541655" },
      { "name": "Frozen non GMO bananas", "code": "50541700" },
      { "name": "Frozen non GMO apple bananas", "code": "50541701" },
      { "name": "Frozen non GMO baby bananas", "code": "50541702" },
      { "name": "Frozen non GMO burro bananas", "code": "50541703" },
      { "name": "Frozen non GMO cavendish bananas", "code": "50541704" },
      { "name": "Frozen non GMO dominico bananas", "code": "50541705" },
      { "name": "Frozen non GMO green bananas", "code": "50541706" },
      { "name": "Frozen non GMO gros michel bananas", "code": "50541707" },
      { "name": "Frozen non GMO lacatan bananas", "code": "50541708" },
      { "name": "Frozen non GMO lady finger banana", "code": "50541709" },
      { "name": "Frozen non GMO manzano bananas", "code": "50541710" },
      { "name": "Frozen non GMO mysore bananas", "code": "50541711" },
      { "name": "Frozen non GMO pisang mas bananas", "code": "50541712" },
      { "name": "Frozen non GMO red bananas", "code": "50541713" },
      { "name": "Frozen non GMO saba bananas", "code": "50541714" },
      { "name": "Frozen non GMO sucrier bananas", "code": "50541715" },
      { "name": "Frozen non GMO barberries", "code": "50541800" },
      { "name": "Frozen non GMO paleleaf barberries", "code": "50541801" },
      { "name": "Frozen non GMO chenault barberries", "code": "50541802" },
      { "name": "Frozen non GMO red barberries", "code": "50541803" },
      { "name": "Frozen non GMO wintergreen barberries", "code": "50541804" },
      { "name": "Frozen non GMO korean barberries", "code": "50541805" },
      { "name": "Frozen non GMO mentor barberries", "code": "50541806" },
      { "name": "Frozen non GMO japanese barberries", "code": "50541807" },
      { "name": "Frozen non GMO atropurpurea barberries", "code": "50541808" },
      { "name": "Frozen non GMO aurea barberries", "code": "50541809" },
      { "name": "Frozen non GMO bagatelle barberries", "code": "50541810" },
      { "name": "Frozen non GMO crimson pygmy barberries", "code": "50541811" },
      { "name": "Frozen non GMO kobold barberries", "code": "50541812" },
      { "name": "Frozen non GMO warty barberries", "code": "50541813" },
      { "name": "Frozen non GMO european barberries", "code": "50541814" },
      { "name": "Frozen non GMO bearberries", "code": "50541900" },
      { "name": "Frozen non GMO alpine bearberries", "code": "50541901" },
      { "name": "Frozen non GMO red bearberries", "code": "50541902" },
      { "name": "Frozen non GMO common bearberries", "code": "50541903" },
      { "name": "Frozen non GMO blackberries", "code": "50542000" },
      { "name": "Frozen non GMO apache blackberries", "code": "50542001" },
      { "name": "Frozen non GMO black satin blackberries", "code": "50542002" },
      { "name": "Frozen non GMO boysenberries", "code": "50542003" },
      { "name": "Frozen non GMO cherokee blackberries", "code": "50542004" },
      { "name": "Frozen non GMO chester blackberries", "code": "50542005" },
      { "name": "Frozen non GMO dirksen blackberries", "code": "50542006" },
      { "name": "Frozen non GMO jostaberries", "code": "50542007" },
      { "name": "Frozen non GMO loganberries", "code": "50542008" },
      { "name": "Frozen non GMO marionberries", "code": "50542009" },
      { "name": "Frozen non GMO navaho blackberries", "code": "50542010" },
      { "name": "Frozen non GMO nectarberries", "code": "50542011" },
      { "name": "Frozen non GMO olallie blackberries", "code": "50542012" },
      { "name": "Frozen non GMO tayberries", "code": "50542013" },
      {
        "name": "Frozen non GMO thornless hull blackberries",
        "code": "50542014"
      },
      { "name": "Frozen non GMO youngberries", "code": "50542015" },
      { "name": "Frozen non GMO bilberries", "code": "50542100" },
      { "name": "Frozen non GMO bog bilberries", "code": "50542101" },
      { "name": "Frozen non GMO dwarf bilberries", "code": "50542102" },
      { "name": "Frozen non GMO mountain bilberries", "code": "50542103" },
      { "name": "Frozen non GMO oval-leaved bilberries", "code": "50542104" },
      { "name": "Frozen non GMO blueberries", "code": "50542200" },
      { "name": "Frozen non GMO bluecrop blueberries", "code": "50542201" },
      { "name": "Frozen non GMO bluetta blueberries", "code": "50542202" },
      { "name": "Frozen non GMO brigitta blueberries", "code": "50542203" },
      { "name": "Frozen non GMO chandler blueberries", "code": "50542204" },
      { "name": "Frozen non GMO duke blueberries", "code": "50542205" },
      { "name": "Frozen non GMO hardyblue blueberries", "code": "50542206" },
      { "name": "Frozen non GMO legacy blueberries", "code": "50542207" },
      { "name": "Frozen non GMO misty blueberries", "code": "50542208" },
      { "name": "Frozen non GMO nelson blueberries", "code": "50542209" },
      { "name": "Frozen non GMO northblue blueberries", "code": "50542210" },
      { "name": "Frozen non GMO northcountry blueberries", "code": "50542211" },
      { "name": "Frozen non GMO northsky blueberries", "code": "50542212" },
      { "name": "Frozen non GMO patriot blueberries", "code": "50542213" },
      { "name": "Frozen non GMO spartan blueberries", "code": "50542214" },
      { "name": "Frozen non GMO toro blueberries", "code": "50542215" },
      { "name": "Frozen non GMO breadfruit", "code": "50542300" },
      { "name": "Frozen non GMO chataigne breadfruit", "code": "50542301" },
      { "name": "Frozen non GMO seedless breadfruit", "code": "50542302" },
      { "name": "Frozen non GMO white heart breadfruit", "code": "50542303" },
      { "name": "Frozen non GMO yellow heart breadfruit", "code": "50542304" },
      { "name": "Frozen non GMO cherimoyas", "code": "50542400" },
      { "name": "Frozen non GMO bays cherimoya", "code": "50542401" },
      { "name": "Frozen non GMO bronceada cherimoya", "code": "50542402" },
      { "name": "Frozen non GMO burtons cherimoya", "code": "50542403" },
      {
        "name": "Frozen non GMO burtons favorite cherimoya",
        "code": "50542404"
      },
      { "name": "Frozen non GMO jete cherimoya", "code": "50542405" },
      { "name": "Frozen non GMO reretai cherimoya", "code": "50542406" },
      { "name": "Frozen non GMO smoothey cherimoya", "code": "50542407" },
      { "name": "Frozen non GMO spain cherimoya", "code": "50542408" },
      { "name": "Frozen non GMO white cherimoya", "code": "50542409" },
      { "name": "Frozen non GMO cherries", "code": "50542500" },
      { "name": "Frozen non GMO amarelle cherries", "code": "50542501" },
      { "name": "Frozen non GMO brooks cherries", "code": "50542502" },
      { "name": "Frozen non GMO bigarreu cherries", "code": "50542503" },
      { "name": "Frozen non GMO bing cherries", "code": "50542504" },
      { "name": "Frozen non GMO black republic cherries", "code": "50542505" },
      { "name": "Frozen non GMO black schmidt cherries", "code": "50542506" },
      { "name": "Frozen non GMO black tartarian cherries", "code": "50542507" },
      { "name": "Frozen non GMO fiesta bing cherries", "code": "50542508" },
      { "name": "Frozen non GMO garnet cherries", "code": "50542509" },
      { "name": "Frozen non GMO king cherries", "code": "50542510" },
      { "name": "Frozen non GMO chapman cherries", "code": "50542511" },
      { "name": "Frozen non GMO lapin cherries", "code": "50542512" },
      { "name": "Frozen non GMO larian cherries", "code": "50542513" },
      { "name": "Frozen non GMO dark guines cherries", "code": "50542514" },
      { "name": "Frozen non GMO montmorency cherries", "code": "50542515" },
      { "name": "Frozen non GMO duke cherries", "code": "50542516" },
      { "name": "Frozen non GMO early rivers cherries", "code": "50542517" },
      { "name": "Frozen non GMO ruby bing cherries", "code": "50542518" },
      { "name": "Frozen non GMO santina cherries", "code": "50542519" },
      { "name": "Frozen non GMO geans/guines cherries", "code": "50542520" },
      { "name": "Frozen non GMO sonata cherries", "code": "50542521" },
      { "name": "Frozen non GMO lambert cherries", "code": "50542522" },
      { "name": "Frozen non GMO stella cherries", "code": "50542523" },
      { "name": "Frozen non GMO sweetheart cherries", "code": "50542524" },
      { "name": "Frozen non GMO tartarian cherries", "code": "50542525" },
      { "name": "Frozen non GMO maraschino cherries", "code": "50542526" },
      { "name": "Frozen non GMO van cherries", "code": "50542527" },
      { "name": "Frozen non GMO morello cherries", "code": "50542528" },
      { "name": "Frozen non GMO royal ann cherries", "code": "50542529" },
      { "name": "Frozen non GMO ranier cherries", "code": "50542530" },
      { "name": "Frozen non GMO royal cherries", "code": "50542531" },
      { "name": "Frozen non GMO citrons", "code": "50542600" },
      { "name": "Frozen non GMO buddha's hand citrons", "code": "50542601" },
      { "name": "Frozen non GMO fingered citrons", "code": "50542602" },
      { "name": "Frozen non GMO fo shoukan citrons", "code": "50542603" },
      { "name": "Frozen non GMO bushakan citrons", "code": "50542604" },
      { "name": "Frozen non GMO diamante citrons", "code": "50542605" },
      { "name": "Frozen non GMO etrog citrons", "code": "50542606" },
      { "name": "Frozen non GMO ponderosa citrons", "code": "50542607" },
      { "name": "Frozen non GMO cranberries", "code": "50542700" },
      { "name": "Frozen non GMO ben lear cranberries", "code": "50542701" },
      { "name": "Frozen non GMO early black cranberries", "code": "50542702" },
      { "name": "Frozen non GMO grycleski cranberries", "code": "50542703" },
      { "name": "Frozen non GMO howe cranberries", "code": "50542704" },
      { "name": "Frozen non GMO lingonberries", "code": "50542705" },
      { "name": "Frozen non GMO mcfarlin cranberries", "code": "50542706" },
      { "name": "Frozen non GMO mountain cranberries", "code": "50542707" },
      { "name": "Frozen non GMO pilgrim cranberries", "code": "50542708" },
      { "name": "Frozen non GMO searless cranberries", "code": "50542709" },
      { "name": "Frozen non GMO stevens cranberries", "code": "50542710" },
      { "name": "Frozen non GMO currants", "code": "50542800" },
      { "name": "Frozen non GMO hudson bay currants", "code": "50542801" },
      { "name": "Frozen non GMO waxy currants", "code": "50542802" },
      { "name": "Frozen non GMO desert currants", "code": "50542803" },
      { "name": "Frozen non GMO black currants", "code": "50542804" },
      { "name": "Frozen non GMO red currants", "code": "50542805" },
      { "name": "Frozen non GMO white currants", "code": "50542806" },
      { "name": "Frozen non GMO dates", "code": "50542900" },
      { "name": "Frozen non GMO asharasi dates", "code": "50542901" },
      { "name": "Frozen non GMO barhi or barhee dates", "code": "50542902" },
      { "name": "Frozen non GMO deglet noor dates", "code": "50542903" },
      { "name": "Frozen non GMO fardh dates", "code": "50542904" },
      { "name": "Frozen non GMO gundila dates", "code": "50542905" },
      { "name": "Frozen non GMO halawi/halawy dates", "code": "50542906" },
      { "name": "Frozen non GMO hilali dates", "code": "50542907" },
      { "name": "Frozen non GMO khadrawi/khadrawy dates", "code": "50542908" },
      { "name": "Frozen non GMO khalas dates", "code": "50542909" },
      { "name": "Frozen non GMO khustawi dates", "code": "50542910" },
      { "name": "Frozen non GMO khidri dates", "code": "50542911" },
      { "name": "Frozen non GMO medjool/medjul dates", "code": "50542912" },
      { "name": "Frozen non GMO mactoum dates", "code": "50542913" },
      { "name": "Frozen non GMO neghal dates", "code": "50542914" },
      { "name": "Frozen non GMO yatimeh dates", "code": "50542915" },
      { "name": "Frozen non GMO zahidi dates", "code": "50542916" },
      { "name": "Frozen non GMO dragonfruits", "code": "50543000" },
      { "name": "Frozen non GMO pink dragonfruit", "code": "50543001" },
      { "name": "Frozen non GMO yellow dragonfruit", "code": "50543002" },
      { "name": "Frozen non GMO figs", "code": "50543100" },
      { "name": "Frozen non GMO bardajic figs", "code": "50543101" },
      { "name": "Frozen non GMO brown turkey figs", "code": "50543102" },
      { "name": "Frozen non GMO calimyrna figs", "code": "50543103" },
      { "name": "Frozen non GMO conadria figs", "code": "50543104" },
      { "name": "Frozen non GMO dottado figs", "code": "50543105" },
      { "name": "Frozen non GMO kadota figs", "code": "50543106" },
      { "name": "Frozen non GMO mediterranean figs", "code": "50543107" },
      { "name": "Frozen non GMO mission figs", "code": "50543108" },
      { "name": "Frozen non GMO smyrna figs", "code": "50543109" },
      { "name": "Frozen non GMO verdona figs", "code": "50543110" },
      { "name": "Frozen non GMO white king figs", "code": "50543111" },
      { "name": "Frozen non GMO gooseberries", "code": "50543200" },
      {
        "name": "Frozen non GMO early sulphur gooseberries",
        "code": "50543201"
      },
      { "name": "Frozen non GMO goldendrop gooseberries", "code": "50543202" },
      {
        "name": "Frozen non GMO langley gage gooseberries",
        "code": "50543203"
      },
      { "name": "Frozen non GMO leveller gooseberries", "code": "50543204" },
      { "name": "Frozen non GMO london gooseberries", "code": "50543205" },
      {
        "name": "Frozen non GMO worcestershire gooseberries",
        "code": "50543206"
      },
      {
        "name": "Frozen non GMO american worcesterberry gooseberries",
        "code": "50543207"
      },
      { "name": "Frozen non GMO grapefruits", "code": "50543300" },
      { "name": "Frozen non GMO burgundy grapefruit", "code": "50543301" },
      { "name": "Frozen non GMO duncan grapefruit", "code": "50543302" },
      { "name": "Frozen non GMO foster grapefruit", "code": "50543303" },
      { "name": "Frozen non GMO marsh grapefruit", "code": "50543304" },
      { "name": "Frozen non GMO new zealand grapefruit", "code": "50543305" },
      { "name": "Frozen non GMO rio red grapefruit", "code": "50543306" },
      { "name": "Frozen non GMO ruby red grapefruit", "code": "50543307" },
      { "name": "Frozen non GMO star ruby grapefruit", "code": "50543308" },
      { "name": "Frozen non GMO triumph grapefruit", "code": "50543309" },
      { "name": "Frozen non GMO table grapes", "code": "50543400" },
      { "name": "Frozen non GMO alicante grapes", "code": "50543401" },
      { "name": "Frozen non GMO almeria grapes", "code": "50543402" },
      { "name": "Frozen non GMO alphonse lavalle grapes", "code": "50543403" },
      { "name": "Frozen non GMO autumn king grapes", "code": "50543404" },
      { "name": "Frozen non GMO autumn royal grapes", "code": "50543405" },
      { "name": "Frozen non GMO autumn seedless grapes", "code": "50543406" },
      { "name": "Frozen non GMO baresana grapes", "code": "50543407" },
      { "name": "Frozen non GMO barlinka grapes", "code": "50543408" },
      { "name": "Frozen non GMO beauty seedless grapes", "code": "50543409" },
      {
        "name": "Frozen non GMO black beauty seedless grapes",
        "code": "50543410"
      },
      { "name": "Frozen non GMO black emerald grapes", "code": "50543411" },
      { "name": "Frozen non GMO black giant grapes", "code": "50543412" },
      { "name": "Frozen non GMO black globe grapes", "code": "50543413" },
      { "name": "Frozen non GMO black monukka grapes", "code": "50543414" },
      { "name": "Frozen non GMO black pearl grapes", "code": "50543415" },
      { "name": "Frozen non GMO black seedless grapes", "code": "50543416" },
      { "name": "Frozen non GMO bonheur grapes", "code": "50543417" },
      { "name": "Frozen non GMO calmeria grapes", "code": "50543418" },
      { "name": "Frozen non GMO cardinal grapes", "code": "50543419" },
      { "name": "Frozen non GMO catawba grapes", "code": "50543420" },
      {
        "name": "Frozen non GMO chasselas/golden chasselas grapes",
        "code": "50543421"
      },
      { "name": "Frozen non GMO christmas rose grapes", "code": "50543422" },
      { "name": "Frozen non GMO concord grapes", "code": "50543423" },
      { "name": "Frozen non GMO concord seedless grapes", "code": "50543424" },
      { "name": "Frozen non GMO crimson seedless grapes", "code": "50543425" },
      { "name": "Frozen non GMO dauphine grapes", "code": "50543426" },
      { "name": "Frozen non GMO delaware grapes", "code": "50543427" },
      { "name": "Frozen non GMO early muscat grapes", "code": "50543428" },
      { "name": "Frozen non GMO early sweet grapes", "code": "50543429" },
      { "name": "Frozen non GMO emerald seedless grapes", "code": "50543430" },
      { "name": "Frozen non GMO emperatriz grapes", "code": "50543431" },
      { "name": "Frozen non GMO emperor grapes", "code": "50543432" },
      { "name": "Frozen non GMO empress grapes", "code": "50543433" },
      { "name": "Frozen non GMO exotic grapes", "code": "50543434" },
      { "name": "Frozen non GMO fantasy grapes", "code": "50543435" },
      { "name": "Frozen non GMO fantasy seedless grapes", "code": "50543436" },
      { "name": "Frozen non GMO flame grapes", "code": "50543437" },
      { "name": "Frozen non GMO flame seedless grapes", "code": "50543438" },
      { "name": "Frozen non GMO flame tokay grapes", "code": "50543439" },
      { "name": "Frozen non GMO flaming red grapes", "code": "50543440" },
      { "name": "Frozen non GMO galaxy seedless grapes", "code": "50543441" },
      { "name": "Frozen non GMO gamay grapes", "code": "50543442" },
      { "name": "Frozen non GMO gold grapes", "code": "50543443" },
      {
        "name": "Frozen non GMO hanepoot or honeypot grapes",
        "code": "50543444"
      },
      { "name": "Frozen non GMO italia grapes", "code": "50543445" },
      { "name": "Frozen non GMO jade seedless grapes", "code": "50543446" },
      { "name": "Frozen non GMO jubilee grapes", "code": "50543447" },
      { "name": "Frozen non GMO king ruby grapes", "code": "50543448" },
      { "name": "Frozen non GMO kyoho grapes", "code": "50543449" },
      { "name": "Frozen non GMO la rochelle grapes", "code": "50543450" },
      { "name": "Frozen non GMO lady finger grapes", "code": "50543451" },
      { "name": "Frozen non GMO late seedless grapes", "code": "50543452" },
      { "name": "Frozen non GMO majestic seedless grapes", "code": "50543453" },
      { "name": "Frozen non GMO malaga grapes", "code": "50543454" },
      { "name": "Frozen non GMO marroo seedless grapes", "code": "50543455" },
      { "name": "Frozen non GMO muscadine grapes", "code": "50543456" },
      { "name": "Frozen non GMO muscat flame grapes", "code": "50543457" },
      { "name": "Frozen non GMO muscat grapes", "code": "50543458" },
      { "name": "Frozen non GMO muscat seedless grapes", "code": "50543459" },
      { "name": "Frozen non GMO napoleon grapes", "code": "50543460" },
      { "name": "Frozen non GMO negria grapes", "code": "50543461" },
      { "name": "Frozen non GMO new cross grapes", "code": "50543462" },
      { "name": "Frozen non GMO niabell grapes", "code": "50543463" },
      { "name": "Frozen non GMO niagara grapes", "code": "50543464" },
      { "name": "Frozen non GMO olivette grapes", "code": "50543465" },
      { "name": "Frozen non GMO perlette grapes", "code": "50543466" },
      { "name": "Frozen non GMO perlon grapes", "code": "50543467" },
      {
        "name": "Frozen non GMO prima black seedless grapes",
        "code": "50543468"
      },
      { "name": "Frozen non GMO princess grapes", "code": "50543469" },
      { "name": "Frozen non GMO queen grapes", "code": "50543470" },
      { "name": "Frozen non GMO red blush grapes", "code": "50543471" },
      { "name": "Frozen non GMO red globe grapes", "code": "50543472" },
      { "name": "Frozen non GMO red malaga grapes", "code": "50543473" },
      { "name": "Frozen non GMO red seedless grapes", "code": "50543474" },
      { "name": "Frozen non GMO regina grapes", "code": "50543475" },
      { "name": "Frozen non GMO ribier grapes", "code": "50543476" },
      { "name": "Frozen non GMO rosita grapes", "code": "50543477" },
      { "name": "Frozen non GMO rouge grapes", "code": "50543478" },
      {
        "name": "Frozen non GMO royal black seedless grapes",
        "code": "50543479"
      },
      { "name": "Frozen non GMO ruby red seedless grapes", "code": "50543480" },
      { "name": "Frozen non GMO ruby seedless grapes", "code": "50543481" },
      { "name": "Frozen non GMO scarlet royal grapes", "code": "50543482" },
      { "name": "Frozen non GMO scuppernong grapes", "code": "50543483" },
      { "name": "Frozen non GMO sugarose grapes", "code": "50543484" },
      { "name": "Frozen non GMO sugarthirteen grapes", "code": "50543485" },
      { "name": "Frozen non GMO sugraone grapes", "code": "50543486" },
      { "name": "Frozen non GMO sugrasixteen grapes", "code": "50543487" },
      { "name": "Frozen non GMO sultana sun red grapes", "code": "50543488" },
      { "name": "Frozen non GMO summer royal grapes", "code": "50543489" },
      { "name": "Frozen non GMO sunset grapes", "code": "50543490" },
      { "name": "Frozen non GMO superior seedless grapes", "code": "50543491" },
      { "name": "Frozen non GMO thompson seedless grapes", "code": "50543492" },
      { "name": "Frozen non GMO tokay/pinot gris grapes", "code": "50543493" },
      { "name": "Frozen non GMO waltman cross grapes", "code": "50543494" },
      { "name": "Frozen non GMO white seedless grapes", "code": "50543495" },
      { "name": "Frozen non GMO zante current grapes", "code": "50543496" },
      { "name": "Frozen non GMO raisin grapes", "code": "50543500" },
      { "name": "Frozen non GMO black corinth grapes", "code": "50543501" },
      { "name": "Frozen non GMO canner grapes", "code": "50543502" },
      { "name": "Frozen non GMO dovine grapes", "code": "50543503" },
      { "name": "Frozen non GMO fiesta grapes", "code": "50543504" },
      { "name": "Frozen non GMO selma pete grapes", "code": "50543505" },
      { "name": "Frozen non GMO sultana grapes", "code": "50543506" },
      { "name": "Frozen non GMO wine grapes", "code": "50543600" },
      { "name": "Frozen non GMO alicante bouschet grapes", "code": "50543601" },
      { "name": "Frozen non GMO barbera grapes", "code": "50543602" },
      { "name": "Frozen non GMO burger grapes", "code": "50543603" },
      { "name": "Frozen non GMO cabernet franc grapes", "code": "50543604" },
      {
        "name": "Frozen non GMO cabernet sauvignon grapes",
        "code": "50543605"
      },
      { "name": "Frozen non GMO carignane grapes", "code": "50543606" },
      { "name": "Frozen non GMO carnelian grapes", "code": "50543607" },
      { "name": "Frozen non GMO catarratto grapes", "code": "50543608" },
      { "name": "Frozen non GMO centurian grapes", "code": "50543609" },
      { "name": "Frozen non GMO charbono grapes", "code": "50543610" },
      { "name": "Frozen non GMO chardonnay grapes", "code": "50543611" },
      { "name": "Frozen non GMO chenin blanc grapes", "code": "50543612" },
      { "name": "Frozen non GMO cinsaut grapes", "code": "50543613" },
      { "name": "Frozen non GMO dolcetto grapes", "code": "50543614" },
      { "name": "Frozen non GMO emerald riesling grapes", "code": "50543615" },
      { "name": "Frozen non GMO french colombard grapes", "code": "50543616" },
      { "name": "Frozen non GMO gamay napa grapes", "code": "50543617" },
      { "name": "Frozen non GMO gamay beaujolais grapes", "code": "50543618" },
      { "name": "Frozen non GMO gewurztraminer grapes", "code": "50543619" },
      { "name": "Frozen non GMO grenache grapes", "code": "50543620" },
      { "name": "Frozen non GMO grenache blanc grapes", "code": "50543621" },
      { "name": "Frozen non GMO lagrein grapes", "code": "50543622" },
      { "name": "Frozen non GMO lambrusco grapes", "code": "50543623" },
      { "name": "Frozen non GMO malbec grapes", "code": "50543624" },
      { "name": "Frozen non GMO malvasia bianca grapes", "code": "50543625" },
      { "name": "Frozen non GMO marsanne grapes", "code": "50543626" },
      { "name": "Frozen non GMO mataro grapes", "code": "50543627" },
      { "name": "Frozen non GMO merlot grapes", "code": "50543628" },
      { "name": "Frozen non GMO meunier grapes", "code": "50543629" },
      { "name": "Frozen non GMO mission grapes", "code": "50543630" },
      { "name": "Frozen non GMO montepulciano grapes", "code": "50543631" },
      { "name": "Frozen non GMO muscat blanc grapes", "code": "50543632" },
      { "name": "Frozen non GMO muscat hamburg grapes", "code": "50543633" },
      {
        "name": "Frozen non GMO muscat of alexandria grapes",
        "code": "50543634"
      },
      { "name": "Frozen non GMO muscat orange grapes", "code": "50543635" },
      { "name": "Frozen non GMO nebbiolo grapes", "code": "50543636" },
      { "name": "Frozen non GMO palomino grapes", "code": "50543637" },
      { "name": "Frozen non GMO petit verdot grapes", "code": "50543638" },
      { "name": "Frozen non GMO petite sirah grapes", "code": "50543639" },
      { "name": "Frozen non GMO pinot blanc grapes", "code": "50543640" },
      { "name": "Frozen non GMO pinot gris grapes", "code": "50543641" },
      { "name": "Frozen non GMO pinot noir grapes", "code": "50543642" },
      { "name": "Frozen non GMO primitivo grapes", "code": "50543643" },
      { "name": "Frozen non GMO roussanne grapes", "code": "50543644" },
      { "name": "Frozen non GMO royalty grapes", "code": "50543645" },
      { "name": "Frozen non GMO rubired grapes", "code": "50543646" },
      { "name": "Frozen non GMO ruby cabernet grapes", "code": "50543647" },
      { "name": "Frozen non GMO salvador grapes", "code": "50543648" },
      { "name": "Frozen non GMO sangiovese grapes", "code": "50543649" },
      { "name": "Frozen non GMO sauvignon blanc grapes", "code": "50543650" },
      { "name": "Frozen non GMO sauvignon musque grapes", "code": "50543651" },
      { "name": "Frozen non GMO semillon grapes", "code": "50543652" },
      { "name": "Frozen non GMO souzao grapes", "code": "50543653" },
      { "name": "Frozen non GMO st emilion grapes", "code": "50543654" },
      { "name": "Frozen non GMO symphony grapes", "code": "50543655" },
      { "name": "Frozen non GMO syrah grapes", "code": "50543656" },
      { "name": "Frozen non GMO tannat grapes", "code": "50543657" },
      { "name": "Frozen non GMO tempranillo grapes", "code": "50543658" },
      { "name": "Frozen non GMO teroldego grapes", "code": "50543659" },
      { "name": "Frozen non GMO tocai friulano grapes", "code": "50543660" },
      { "name": "Frozen non GMO touriga nacional grapes", "code": "50543661" },
      { "name": "Frozen non GMO triplett blanc grapes", "code": "50543662" },
      { "name": "Frozen non GMO viognier grapes", "code": "50543663" },
      { "name": "Frozen non GMO white riesling grapes", "code": "50543664" },
      { "name": "Frozen non GMO zinfandel grapes", "code": "50543665" },
      { "name": "Frozen non GMO guavas", "code": "50543700" },
      { "name": "Frozen non GMO beaumont guavas", "code": "50543701" },
      { "name": "Frozen non GMO carrley guavas", "code": "50543702" },
      { "name": "Frozen non GMO lucida guavas", "code": "50543703" },
      { "name": "Frozen non GMO pineapple guava", "code": "50543704" },
      { "name": "Frozen non GMO huckleberries", "code": "50543800" },
      {
        "name": "Frozen non GMO black winter huckleberries",
        "code": "50543801"
      },
      { "name": "Frozen non GMO cascade huckleberries", "code": "50543802" },
      { "name": "Frozen non GMO dwarf huckleberries", "code": "50543803" },
      { "name": "Frozen non GMO mountain huckleberries", "code": "50543804" },
      { "name": "Frozen non GMO red huckleberries", "code": "50543805" },
      { "name": "Frozen non GMO kiwi fruits", "code": "50543900" },
      { "name": "Frozen non GMO ananasnaja kiwi fruit", "code": "50543901" },
      { "name": "Frozen non GMO arctic beauty kiwi fruit", "code": "50543902" },
      { "name": "Frozen non GMO blake kiwi fruit", "code": "50543903" },
      { "name": "Frozen non GMO hayward kiwi fruit", "code": "50543904" },
      { "name": "Frozen non GMO issai kiwi fruit", "code": "50543905" },
      { "name": "Frozen non GMO siberian kiwi fruit", "code": "50543906" },
      { "name": "Frozen non GMO kumquats", "code": "50544000" },
      { "name": "Frozen non GMO hong kong kumquats", "code": "50544001" },
      { "name": "Frozen non GMO limequat kumquats", "code": "50544002" },
      { "name": "Frozen non GMO long fruit kumquats", "code": "50544003" },
      { "name": "Frozen non GMO malayan kumquats", "code": "50544004" },
      { "name": "Frozen non GMO meiwa kumquats", "code": "50544005" },
      { "name": "Frozen non GMO nagami kumquats", "code": "50544006" },
      {
        "name": "Frozen non GMO mandarin oranges or tangerines",
        "code": "50544400"
      },
      { "name": "Frozen non GMO clauselinas oranges", "code": "50544401" },
      { "name": "Frozen non GMO clementine tangerines", "code": "50544402" },
      {
        "name": "Frozen non GMO cleopatra mandarin oranges",
        "code": "50544403"
      },
      { "name": "Frozen non GMO dancy tangerines", "code": "50544404" },
      { "name": "Frozen non GMO ellensdale oranges", "code": "50544405" },
      { "name": "Frozen non GMO fairchild oranges", "code": "50544406" },
      { "name": "Frozen non GMO fallglo oranges", "code": "50544407" },
      { "name": "Frozen non GMO fortune oranges", "code": "50544408" },
      { "name": "Frozen non GMO fremont mandarin oranges", "code": "50544409" },
      { "name": "Frozen non GMO fremont oranges", "code": "50544410" },
      { "name": "Frozen non GMO golden nugget oranges", "code": "50544411" },
      { "name": "Frozen non GMO honey mandarin oranges", "code": "50544412" },
      { "name": "Frozen non GMO honey oranges", "code": "50544413" },
      { "name": "Frozen non GMO honey tangerines", "code": "50544414" },
      {
        "name": "Frozen non GMO honeybelle tangelo oranges",
        "code": "50544415"
      },
      { "name": "Frozen non GMO king mandarin oranges", "code": "50544416" },
      { "name": "Frozen non GMO kinnow oranges", "code": "50544417" },
      { "name": "Frozen non GMO lee mandarin oranges", "code": "50544418" },
      { "name": "Frozen non GMO makokkee oranges", "code": "50544419" },
      { "name": "Frozen non GMO malvasios oranges", "code": "50544420" },
      {
        "name": "Frozen non GMO mediterranean mandarin oranges",
        "code": "50544421"
      },
      { "name": "Frozen non GMO minneola tangelo oranges", "code": "50544422" },
      { "name": "Frozen non GMO monica oranges", "code": "50544423" },
      { "name": "Frozen non GMO murcott honey oranges", "code": "50544424" },
      { "name": "Frozen non GMO murcott tangors", "code": "50544425" },
      {
        "name": "Frozen non GMO natsudaidai mandarin oranges",
        "code": "50544426"
      },
      {
        "name": "Frozen non GMO natsumikan mandarin oranges",
        "code": "50544427"
      },
      { "name": "Frozen non GMO nocatee tangelo oranges", "code": "50544428" },
      { "name": "Frozen non GMO orlando tangelo oranges", "code": "50544429" },
      { "name": "Frozen non GMO ortanique tangerines", "code": "50544430" },
      { "name": "Frozen non GMO page mandarin oranges", "code": "50544431" },
      { "name": "Frozen non GMO pixie oranges", "code": "50544432" },
      {
        "name": "Frozen non GMO ponkan bantangas mandarin oranges",
        "code": "50544433"
      },
      { "name": "Frozen non GMO reyna oranges", "code": "50544434" },
      { "name": "Frozen non GMO robinson oranges", "code": "50544435" },
      { "name": "Frozen non GMO saltenitas oranges", "code": "50544436" },
      { "name": "Frozen non GMO sampson tangelo oranges", "code": "50544437" },
      { "name": "Frozen non GMO satsuma mandarin oranges", "code": "50544438" },
      {
        "name": "Frozen non GMO sunburst mandarin oranges",
        "code": "50544439"
      },
      { "name": "Frozen non GMO tangelos", "code": "50544440" },
      { "name": "Frozen non GMO tangerina oranges", "code": "50544441" },
      { "name": "Frozen non GMO temple oranges", "code": "50544442" },
      { "name": "Frozen non GMO thornton oranges", "code": "50544443" },
      { "name": "Frozen non GMO wekiwa tangerines", "code": "50544444" },
      { "name": "Frozen non GMO wilkins tangerines", "code": "50544445" },
      {
        "name": "Frozen non GMO willowleaf mediterranean tangerines",
        "code": "50544446"
      },
      { "name": "Frozen non GMO mangoes", "code": "50544500" },
      { "name": "Frozen non GMO alphonso mangoes", "code": "50544501" },
      { "name": "Frozen non GMO ataulfo mangoes", "code": "50544502" },
      { "name": "Frozen non GMO criollo mangoes", "code": "50544503" },
      { "name": "Frozen non GMO edwards mangoes", "code": "50544504" },
      { "name": "Frozen non GMO francine mangoes", "code": "50544505" },
      { "name": "Frozen non GMO francis mangoes", "code": "50544506" },
      { "name": "Frozen non GMO gandaria mangoes", "code": "50544507" },
      { "name": "Frozen non GMO haden mangoes", "code": "50544508" },
      { "name": "Frozen non GMO irwin mangoes", "code": "50544509" },
      { "name": "Frozen non GMO keitt mangoes", "code": "50544510" },
      { "name": "Frozen non GMO kent mangoes", "code": "50544511" },
      { "name": "Frozen non GMO kesar mangoes", "code": "50544512" },
      { "name": "Frozen non GMO kuini mangoes", "code": "50544513" },
      { "name": "Frozen non GMO manila super mangoes", "code": "50544514" },
      { "name": "Frozen non GMO manila mangoes", "code": "50544515" },
      { "name": "Frozen non GMO mayaguez mangoes", "code": "50544516" },
      { "name": "Frozen non GMO mulgoba mangoes", "code": "50544517" },
      { "name": "Frozen non GMO oro mangoes", "code": "50544518" },
      { "name": "Frozen non GMO palmer mangoes", "code": "50544519" },
      { "name": "Frozen non GMO parvin mangoes", "code": "50544520" },
      { "name": "Frozen non GMO sandersha mangoes", "code": "50544521" },
      { "name": "Frozen non GMO sensation mangoes", "code": "50544522" },
      { "name": "Frozen non GMO smith mangoes", "code": "50544523" },
      { "name": "Frozen non GMO tommy atkins mangoes", "code": "50544524" },
      { "name": "Frozen non GMO van dyke mangoes", "code": "50544525" },
      { "name": "Frozen non GMO melons", "code": "50544600" },
      { "name": "Frozen non GMO allsweet melons", "code": "50544601" },
      { "name": "Frozen non GMO athena melons", "code": "50544602" },
      { "name": "Frozen non GMO black diamond melons", "code": "50544603" },
      { "name": "Frozen non GMO cal sweet melons", "code": "50544604" },
      { "name": "Frozen non GMO carnical melons", "code": "50544605" },
      { "name": "Frozen non GMO cantaloupe melons", "code": "50544606" },
      { "name": "Frozen non GMO casaba melons", "code": "50544607" },
      { "name": "Frozen non GMO cavaillon melons", "code": "50544608" },
      { "name": "Frozen non GMO charentais melons", "code": "50544609" },
      {
        "name": "Frozen non GMO charleston gray watermelon",
        "code": "50544610"
      },
      { "name": "Frozen non GMO crenshaw melons", "code": "50544611" },
      { "name": "Frozen non GMO crimson sweet melons", "code": "50544612" },
      { "name": "Frozen non GMO dixie lee melons", "code": "50544613" },
      { "name": "Frozen non GMO eclipse melons", "code": "50544614" },
      { "name": "Frozen non GMO ein d'or melons", "code": "50544615" },
      { "name": "Frozen non GMO fiesta melons", "code": "50544616" },
      { "name": "Frozen non GMO galia melons", "code": "50544617" },
      { "name": "Frozen non GMO gaya melons", "code": "50544618" },
      { "name": "Frozen non GMO hami melons", "code": "50544619" },
      { "name": "Frozen non GMO honeydew melons", "code": "50544620" },
      { "name": "Frozen non GMO icebox melons", "code": "50544621" },
      { "name": "Frozen non GMO ida pride melons", "code": "50544622" },
      { "name": "Frozen non GMO juan canary melons", "code": "50544623" },
      { "name": "Frozen non GMO jubilee melons", "code": "50544624" },
      { "name": "Frozen non GMO jubilation melons", "code": "50544625" },
      { "name": "Frozen non GMO kakhi/kakri melons", "code": "50544626" },
      { "name": "Frozen non GMO kiwano melons", "code": "50544627" },
      { "name": "Frozen non GMO korean melons", "code": "50544628" },
      { "name": "Frozen non GMO long gray melons", "code": "50544629" },
      { "name": "Frozen non GMO mayan melons", "code": "50544630" },
      { "name": "Frozen non GMO micky lee melons", "code": "50544631" },
      { "name": "Frozen non GMO mirage melons", "code": "50544632" },
      {
        "name": "Frozen non GMO moon and stars watermelon",
        "code": "50544633"
      },
      { "name": "Frozen non GMO ogen melons", "code": "50544634" },
      { "name": "Frozen non GMO patriot melons", "code": "50544635" },
      { "name": "Frozen non GMO peacock melons", "code": "50544636" },
      { "name": "Frozen non GMO pepino melons", "code": "50544637" },
      { "name": "Frozen non GMO persian melons", "code": "50544638" },
      { "name": "Frozen non GMO picnic melons", "code": "50544639" },
      { "name": "Frozen non GMO piel de sapo melons", "code": "50544640" },
      { "name": "Frozen non GMO pineapple melons", "code": "50544641" },
      { "name": "Frozen non GMO quetzali melons", "code": "50544642" },
      { "name": "Frozen non GMO red goblin melons", "code": "50544643" },
      { "name": "Frozen non GMO regency melons", "code": "50544644" },
      { "name": "Frozen non GMO royal majestic melons", "code": "50544645" },
      { "name": "Frozen non GMO royal star melons", "code": "50544646" },
      { "name": "Frozen non GMO royal sweet melons", "code": "50544647" },
      { "name": "Frozen non GMO santa claus melons", "code": "50544648" },
      { "name": "Frozen non GMO sharlyn melons", "code": "50544649" },
      { "name": "Frozen non GMO spanish melons", "code": "50544650" },
      { "name": "Frozen non GMO sprite melons", "code": "50544651" },
      { "name": "Frozen non GMO starbright melons", "code": "50544652" },
      { "name": "Frozen non GMO stars n stripes melons", "code": "50544653" },
      { "name": "Frozen non GMO sugar baby melons", "code": "50544654" },
      { "name": "Frozen non GMO sugar baby watermelon", "code": "50544655" },
      { "name": "Frozen non GMO sunsweet melons", "code": "50544656" },
      {
        "name": "Frozen non GMO sweet heart seedless watermelon",
        "code": "50544657"
      },
      { "name": "Frozen non GMO temptation melons", "code": "50544658" },
      { "name": "Frozen non GMO tiger baby melons", "code": "50544659" },
      { "name": "Frozen non GMO tuscan type melons", "code": "50544660" },
      { "name": "Frozen non GMO yellow baby watermelon", "code": "50544661" },
      { "name": "Frozen non GMO mulberries", "code": "50544700" },
      { "name": "Frozen non GMO black mulberries", "code": "50544701" },
      { "name": "Frozen non GMO white mulberries", "code": "50544702" },
      { "name": "Frozen non GMO bayberries and myrtles", "code": "50544800" },
      { "name": "Frozen non GMO bog myrtle", "code": "50544801" },
      { "name": "Frozen non GMO bayberries", "code": "50544802" },
      { "name": "Frozen non GMO nectarines", "code": "50544900" },
      { "name": "Frozen non GMO april glo nectarines", "code": "50544901" },
      { "name": "Frozen non GMO arctic mist nectarines", "code": "50544902" },
      { "name": "Frozen non GMO arctic snow nectarines", "code": "50544903" },
      { "name": "Frozen non GMO arctic star nectarines", "code": "50544904" },
      { "name": "Frozen non GMO arctic sweet nectarines", "code": "50544905" },
      { "name": "Frozen non GMO arctic glo nectarines", "code": "50544906" },
      { "name": "Frozen non GMO august fire nectarines", "code": "50544907" },
      { "name": "Frozen non GMO august pearl nectarines", "code": "50544908" },
      { "name": "Frozen non GMO august red nectarines", "code": "50544909" },
      { "name": "Frozen non GMO autumn star nectarines", "code": "50544910" },
      { "name": "Frozen non GMO big john nectarines", "code": "50544911" },
      { "name": "Frozen non GMO bright pearl nectarines", "code": "50544912" },
      {
        "name": "Frozen non GMO diamond bright nectarines",
        "code": "50544913"
      },
      { "name": "Frozen non GMO diamond ray nectarines", "code": "50544914" },
      { "name": "Frozen non GMO earliglo nectarines", "code": "50544915" },
      { "name": "Frozen non GMO early diamond nectarines", "code": "50544916" },
      { "name": "Frozen non GMO fairlane nectarines", "code": "50544917" },
      { "name": "Frozen non GMO fantasia nectarines", "code": "50544918" },
      { "name": "Frozen non GMO fire pearl nectarines", "code": "50544919" },
      { "name": "Frozen non GMO fire sweet nectarines", "code": "50544920" },
      { "name": "Frozen non GMO flamekist nectarines", "code": "50544921" },
      { "name": "Frozen non GMO flat type nectarines", "code": "50544922" },
      {
        "name": "Frozen non GMO garden delight nectarines",
        "code": "50544923"
      },
      { "name": "Frozen non GMO goldmine nectarines", "code": "50544924" },
      { "name": "Frozen non GMO grand pearl nectarines", "code": "50544925" },
      { "name": "Frozen non GMO hardired nectarines", "code": "50544926" },
      { "name": "Frozen non GMO honey blaze nectarines", "code": "50544927" },
      { "name": "Frozen non GMO july red nectarines", "code": "50544928" },
      { "name": "Frozen non GMO kay pearl nectarines", "code": "50544929" },
      { "name": "Frozen non GMO kay sweet nectarines", "code": "50544930" },
      { "name": "Frozen non GMO may diamond nectarines", "code": "50544931" },
      { "name": "Frozen non GMO mayfire nectarines", "code": "50544932" },
      { "name": "Frozen non GMO mayglo nectarines", "code": "50544933" },
      { "name": "Frozen non GMO mericrest nectarines", "code": "50544934" },
      { "name": "Frozen non GMO red diamond nectarines", "code": "50544935" },
      { "name": "Frozen non GMO red gold nectarines", "code": "50544936" },
      { "name": "Frozen non GMO red jim nectarines", "code": "50544937" },
      { "name": "Frozen non GMO red roy nectarines", "code": "50544938" },
      { "name": "Frozen non GMO rio red nectarines", "code": "50544939" },
      { "name": "Frozen non GMO rose diamond nectarines", "code": "50544940" },
      { "name": "Frozen non GMO royal glo nectarines", "code": "50544941" },
      { "name": "Frozen non GMO ruby diamond nectarines", "code": "50544942" },
      { "name": "Frozen non GMO ruby sweet nectarines", "code": "50544943" },
      { "name": "Frozen non GMO ruddy jewel nectarines", "code": "50544944" },
      { "name": "Frozen non GMO september red nectarines", "code": "50544945" },
      { "name": "Frozen non GMO snowqueen nectarines", "code": "50544946" },
      { "name": "Frozen non GMO spring bright nectarines", "code": "50544947" },
      { "name": "Frozen non GMO spring red nectarines", "code": "50544948" },
      { "name": "Frozen non GMO summer blush nectarines", "code": "50544949" },
      { "name": "Frozen non GMO summer brite nectarines", "code": "50544950" },
      {
        "name": "Frozen non GMO summer diamond nectarines",
        "code": "50544951"
      },
      { "name": "Frozen non GMO summer fire nectarines", "code": "50544952" },
      { "name": "Frozen non GMO summer grand nectarines", "code": "50544953" },
      { "name": "Frozen non GMO sunglo nectarines", "code": "50544954" },
      { "name": "Frozen non GMO zee fire nectarines", "code": "50544955" },
      { "name": "Frozen non GMO zee glo nectarines", "code": "50544956" },
      { "name": "Frozen non GMO zeegrand nectarines", "code": "50544957" },
      { "name": "Frozen non GMO oranges", "code": "50545000" },
      { "name": "Frozen non GMO african sour oranges", "code": "50545001" },
      { "name": "Frozen non GMO ambersweet oranges", "code": "50545002" },
      { "name": "Frozen non GMO argentine sour oranges", "code": "50545003" },
      { "name": "Frozen non GMO bahianinha oranges", "code": "50545004" },
      { "name": "Frozen non GMO bergamot oranges", "code": "50545005" },
      { "name": "Frozen non GMO berna oranges", "code": "50545006" },
      { "name": "Frozen non GMO bigaradier apepu oranges", "code": "50545007" },
      {
        "name": "Frozen non GMO bittersweet daidai oranges",
        "code": "50545008"
      },
      { "name": "Frozen non GMO blonde oranges", "code": "50545009" },
      { "name": "Frozen non GMO blood oranges", "code": "50545010" },
      { "name": "Frozen non GMO california navel oranges", "code": "50545011" },
      { "name": "Frozen non GMO cara cara oranges", "code": "50545012" },
      { "name": "Frozen non GMO chinotto oranges", "code": "50545013" },
      { "name": "Frozen non GMO dream navel oranges", "code": "50545014" },
      { "name": "Frozen non GMO gou tou oranges", "code": "50545015" },
      { "name": "Frozen non GMO hamlin oranges", "code": "50545016" },
      { "name": "Frozen non GMO jaffa oranges", "code": "50545017" },
      { "name": "Frozen non GMO jincheng oranges", "code": "50545018" },
      { "name": "Frozen non GMO k-early oranges", "code": "50545019" },
      { "name": "Frozen non GMO kona oranges", "code": "50545020" },
      { "name": "Frozen non GMO late navel oranges", "code": "50545021" },
      { "name": "Frozen non GMO late valencia oranges", "code": "50545022" },
      { "name": "Frozen non GMO limequat oranges", "code": "50545023" },
      { "name": "Frozen non GMO marr oranges", "code": "50545024" },
      { "name": "Frozen non GMO melogold oranges", "code": "50545025" },
      { "name": "Frozen non GMO moro oranges", "code": "50545026" },
      { "name": "Frozen non GMO moro tarocco oranges", "code": "50545027" },
      { "name": "Frozen non GMO navel oranges", "code": "50545028" },
      { "name": "Frozen non GMO navelina oranges", "code": "50545029" },
      { "name": "Frozen non GMO oro blanco oranges", "code": "50545030" },
      { "name": "Frozen non GMO osceola oranges", "code": "50545031" },
      { "name": "Frozen non GMO parson brown oranges", "code": "50545032" },
      { "name": "Frozen non GMO pera oranges", "code": "50545033" },
      { "name": "Frozen non GMO pummulo oranges", "code": "50545034" },
      { "name": "Frozen non GMO rhode red oranges", "code": "50545035" },
      { "name": "Frozen non GMO roble oranges", "code": "50545036" },
      { "name": "Frozen non GMO salustianas oranges", "code": "50545037" },
      { "name": "Frozen non GMO sanguine oranges", "code": "50545038" },
      { "name": "Frozen non GMO sanguinelli oranges", "code": "50545039" },
      { "name": "Frozen non GMO seville oranges", "code": "50545040" },
      { "name": "Frozen non GMO shamouti jaffa oranges", "code": "50545041" },
      { "name": "Frozen non GMO tunis oranges", "code": "50545042" },
      { "name": "Frozen non GMO valencia oranges", "code": "50545043" },
      { "name": "Frozen non GMO washington navel oranges", "code": "50545044" },
      { "name": "Frozen non GMO papayas", "code": "50545100" },
      { "name": "Frozen non GMO green cooking papayas", "code": "50545101" },
      { "name": "Frozen non GMO maradol papayas", "code": "50545102" },
      { "name": "Frozen non GMO mexican yellow papayas", "code": "50545103" },
      { "name": "Frozen non GMO mountain papayas", "code": "50545104" },
      { "name": "Frozen non GMO solo papayas", "code": "50545105" },
      { "name": "Frozen non GMO tainung papayas", "code": "50545106" },
      { "name": "Frozen non GMO passion fruits", "code": "50545200" },
      { "name": "Frozen non GMO banana passion fruit", "code": "50545201" },
      { "name": "Frozen non GMO blue passion flower", "code": "50545202" },
      {
        "name": "Frozen non GMO crackerjack passion fruit",
        "code": "50545203"
      },
      {
        "name": "Frozen non GMO giant granadilla passion fruit",
        "code": "50545204"
      },
      {
        "name": "Frozen non GMO golden granadilla passion fruit",
        "code": "50545205"
      },
      { "name": "Frozen non GMO maypops passion fruit", "code": "50545206" },
      {
        "name": "Frozen non GMO red granadilla passion fruit",
        "code": "50545207"
      },
      {
        "name": "Frozen non GMO sweet granadilla passion fruit",
        "code": "50545208"
      },
      {
        "name": "Frozen non GMO water lemon passion fruit",
        "code": "50545209"
      },
      {
        "name": "Frozen non GMO wing-stemmed passion flower",
        "code": "50545210"
      },
      { "name": "Frozen non GMO peaches", "code": "50545300" },
      { "name": "Frozen non GMO amber crest peaches", "code": "50545301" },
      { "name": "Frozen non GMO april snow peaches", "code": "50545302" },
      { "name": "Frozen non GMO august lady peaches", "code": "50545303" },
      { "name": "Frozen non GMO autumn flame peaches", "code": "50545304" },
      { "name": "Frozen non GMO autumn lady peaches", "code": "50545305" },
      { "name": "Frozen non GMO babcock peaches", "code": "50545306" },
      { "name": "Frozen non GMO brittney lane peaches", "code": "50545307" },
      { "name": "Frozen non GMO cary mac peaches", "code": "50545308" },
      { "name": "Frozen non GMO classic peaches", "code": "50545309" },
      { "name": "Frozen non GMO country sweet peaches", "code": "50545310" },
      { "name": "Frozen non GMO crest haven peaches", "code": "50545311" },
      { "name": "Frozen non GMO crimson lady peaches", "code": "50545312" },
      { "name": "Frozen non GMO crown princess peaches", "code": "50545313" },
      { "name": "Frozen non GMO david sun peaches", "code": "50545314" },
      { "name": "Frozen non GMO diamond princess peaches", "code": "50545315" },
      { "name": "Frozen non GMO earlirich peaches", "code": "50545316" },
      { "name": "Frozen non GMO early majestic peaches", "code": "50545317" },
      { "name": "Frozen non GMO early treat peaches", "code": "50545318" },
      { "name": "Frozen non GMO elegant lady peaches", "code": "50545319" },
      { "name": "Frozen non GMO empress peaches", "code": "50545320" },
      { "name": "Frozen non GMO encore peaches", "code": "50545321" },
      { "name": "Frozen non GMO fancy lady peaches", "code": "50545322" },
      { "name": "Frozen non GMO fire prince peaches", "code": "50545323" },
      { "name": "Frozen non GMO flame crest peaches", "code": "50545324" },
      { "name": "Frozen non GMO flat type peaches", "code": "50545325" },
      { "name": "Frozen non GMO flavorcrest peaches", "code": "50545326" },
      { "name": "Frozen non GMO florida prince peaches", "code": "50545327" },
      { "name": "Frozen non GMO full moon peaches", "code": "50545328" },
      { "name": "Frozen non GMO harvester peaches", "code": "50545329" },
      { "name": "Frozen non GMO ice princess peaches", "code": "50545330" },
      { "name": "Frozen non GMO ivory princess peaches", "code": "50545331" },
      { "name": "Frozen non GMO jersey queen peaches", "code": "50545332" },
      { "name": "Frozen non GMO john henry peaches", "code": "50545333" },
      { "name": "Frozen non GMO june prince peaches", "code": "50545334" },
      { "name": "Frozen non GMO kaweah peaches", "code": "50545335" },
      { "name": "Frozen non GMO klondike peaches", "code": "50545336" },
      { "name": "Frozen non GMO lindo peaches", "code": "50545337" },
      { "name": "Frozen non GMO loring peaches", "code": "50545338" },
      { "name": "Frozen non GMO majestic peaches", "code": "50545339" },
      { "name": "Frozen non GMO o'henry peaches", "code": "50545340" },
      { "name": "Frozen non GMO queencrest peaches", "code": "50545341" },
      { "name": "Frozen non GMO red lady peaches", "code": "50545342" },
      { "name": "Frozen non GMO redglobe peaches", "code": "50545343" },
      { "name": "Frozen non GMO redhaven peaches", "code": "50545344" },
      { "name": "Frozen non GMO redtop peaches", "code": "50545345" },
      { "name": "Frozen non GMO regina peaches", "code": "50545346" },
      { "name": "Frozen non GMO rich lady peaches", "code": "50545347" },
      { "name": "Frozen non GMO rich may peaches", "code": "50545348" },
      { "name": "Frozen non GMO royal glory peaches", "code": "50545349" },
      { "name": "Frozen non GMO royal lady peaches", "code": "50545350" },
      { "name": "Frozen non GMO september snow peaches", "code": "50545351" },
      { "name": "Frozen non GMO september sun peaches", "code": "50545352" },
      { "name": "Frozen non GMO sierra gem peaches", "code": "50545353" },
      { "name": "Frozen non GMO snow angel peaches", "code": "50545354" },
      { "name": "Frozen non GMO snow gem peaches", "code": "50545355" },
      { "name": "Frozen non GMO snow king peaches", "code": "50545356" },
      { "name": "Frozen non GMO spring lady peaches", "code": "50545357" },
      { "name": "Frozen non GMO spring snow peaches", "code": "50545358" },
      { "name": "Frozen non GMO springcrest peaches", "code": "50545359" },
      { "name": "Frozen non GMO sugar giant peaches", "code": "50545360" },
      { "name": "Frozen non GMO sugar lady peaches", "code": "50545361" },
      { "name": "Frozen non GMO sun bright peaches", "code": "50545362" },
      { "name": "Frozen non GMO sunhigh peaches", "code": "50545363" },
      { "name": "Frozen non GMO super lady peaches", "code": "50545364" },
      { "name": "Frozen non GMO super rich peaches", "code": "50545365" },
      { "name": "Frozen non GMO surecrop peaches", "code": "50545366" },
      { "name": "Frozen non GMO sweet dream peaches", "code": "50545367" },
      { "name": "Frozen non GMO sweet september peaches", "code": "50545368" },
      { "name": "Frozen non GMO vista peaches", "code": "50545369" },
      { "name": "Frozen non GMO white lady peaches", "code": "50545370" },
      { "name": "Frozen non GMO zee lady peaches", "code": "50545371" },
      { "name": "Frozen non GMO pears", "code": "50545400" },
      { "name": "Frozen non GMO abate fetel pears", "code": "50545401" },
      { "name": "Frozen non GMO anjou pears", "code": "50545402" },
      { "name": "Frozen non GMO asian pears", "code": "50545403" },
      { "name": "Frozen non GMO bartlett pears", "code": "50545404" },
      { "name": "Frozen non GMO best ever pears", "code": "50545405" },
      { "name": "Frozen non GMO beth pears", "code": "50545406" },
      { "name": "Frozen non GMO beurre pears", "code": "50545407" },
      { "name": "Frozen non GMO bosc pears", "code": "50545408" },
      { "name": "Frozen non GMO clapp favorite pears", "code": "50545409" },
      { "name": "Frozen non GMO comice pears", "code": "50545410" },
      { "name": "Frozen non GMO concorde pears", "code": "50545411" },
      { "name": "Frozen non GMO conference pears", "code": "50545412" },
      { "name": "Frozen non GMO crimson red pears", "code": "50545413" },
      { "name": "Frozen non GMO d'anjou pears", "code": "50545414" },
      { "name": "Frozen non GMO dr jules guyot pears", "code": "50545415" },
      { "name": "Frozen non GMO early pears", "code": "50545416" },
      { "name": "Frozen non GMO emperor brown pears", "code": "50545417" },
      { "name": "Frozen non GMO forelle pears", "code": "50545418" },
      { "name": "Frozen non GMO french butter pears", "code": "50545419" },
      { "name": "Frozen non GMO glou morceau pears", "code": "50545420" },
      { "name": "Frozen non GMO hosui pears", "code": "50545421" },
      { "name": "Frozen non GMO italian butter pears", "code": "50545422" },
      { "name": "Frozen non GMO jargonelle pears", "code": "50545423" },
      { "name": "Frozen non GMO juno pears", "code": "50545424" },
      {
        "name": "Frozen non GMO kaiserlouise bonne de jersey pears",
        "code": "50545425"
      },
      { "name": "Frozen non GMO keiffer pears", "code": "50545426" },
      { "name": "Frozen non GMO kings royal pears", "code": "50545427" },
      { "name": "Frozen non GMO limonera pears", "code": "50545428" },
      { "name": "Frozen non GMO merton pride pears", "code": "50545429" },
      { "name": "Frozen non GMO mountain bartlett pears", "code": "50545430" },
      { "name": "Frozen non GMO olivier de serres pears", "code": "50545431" },
      { "name": "Frozen non GMO onward pears", "code": "50545432" },
      { "name": "Frozen non GMO packham's triumph pears", "code": "50545433" },
      { "name": "Frozen non GMO paraiso pears", "code": "50545434" },
      { "name": "Frozen non GMO passe crasanne pears", "code": "50545435" },
      { "name": "Frozen non GMO perry pears", "code": "50545436" },
      { "name": "Frozen non GMO red bartlett pears", "code": "50545437" },
      { "name": "Frozen non GMO red d'anjou pears", "code": "50545438" },
      { "name": "Frozen non GMO rocha pears", "code": "50545439" },
      { "name": "Frozen non GMO rosey red pears", "code": "50545440" },
      { "name": "Frozen non GMO rosy red pears", "code": "50545441" },
      { "name": "Frozen non GMO royal majestic pears", "code": "50545442" },
      { "name": "Frozen non GMO ruby red pears", "code": "50545443" },
      { "name": "Frozen non GMO santa maria pears", "code": "50545444" },
      { "name": "Frozen non GMO seckel pears", "code": "50545445" },
      { "name": "Frozen non GMO sensation pears", "code": "50545446" },
      { "name": "Frozen non GMO star crimson pears", "code": "50545447" },
      { "name": "Frozen non GMO stark crimson pears", "code": "50545448" },
      { "name": "Frozen non GMO summer bartlett pears", "code": "50545449" },
      { "name": "Frozen non GMO summer gold pears", "code": "50545450" },
      { "name": "Frozen non GMO sun gold pears", "code": "50545451" },
      { "name": "Frozen non GMO sunsprite pears", "code": "50545452" },
      { "name": "Frozen non GMO taylors gold pears", "code": "50545453" },
      { "name": "Frozen non GMO taylors red pears", "code": "50545454" },
      { "name": "Frozen non GMO tientsin pears", "code": "50545455" },
      { "name": "Frozen non GMO tosca pears", "code": "50545456" },
      { "name": "Frozen non GMO warden pears", "code": "50545457" },
      {
        "name": "Frozen non GMO williams bon chretien pears",
        "code": "50545458"
      },
      { "name": "Frozen non GMO williams pears", "code": "50545459" },
      { "name": "Frozen non GMO winter nelis pears", "code": "50545460" },
      { "name": "Frozen non GMO persimmons", "code": "50545500" },
      { "name": "Frozen non GMO american persimmon", "code": "50545501" },
      { "name": "Frozen non GMO black sapote persimmon", "code": "50545502" },
      { "name": "Frozen non GMO chapote/black persimmon", "code": "50545503" },
      { "name": "Frozen non GMO date plum persimmon", "code": "50545504" },
      { "name": "Frozen non GMO fuyu persimmons", "code": "50545505" },
      { "name": "Frozen non GMO giant fuyu persimmons", "code": "50545506" },
      { "name": "Frozen non GMO hachiya persimmons", "code": "50545507" },
      {
        "name": "Frozen non GMO mabolo/butter fruit persimmon",
        "code": "50545508"
      },
      { "name": "Frozen non GMO principe ito persimmons", "code": "50545509" },
      {
        "name": "Frozen non GMO royal brillante persimmons",
        "code": "50545510"
      },
      { "name": "Frozen non GMO sharon fruit persimmon", "code": "50545511" },
      { "name": "Frozen non GMO triumph persimmons", "code": "50545512" },
      { "name": "Frozen non GMO pineapples", "code": "50545600" },
      { "name": "Frozen non GMO cherimoya pineapple", "code": "50545601" },
      { "name": "Frozen non GMO golden pineapple", "code": "50545602" },
      { "name": "Frozen non GMO hilo pineapple", "code": "50545603" },
      { "name": "Frozen non GMO kona sugarloaf pineapple", "code": "50545604" },
      { "name": "Frozen non GMO natal queen pineapple", "code": "50545605" },
      { "name": "Frozen non GMO pernambuco pineapple", "code": "50545606" },
      { "name": "Frozen non GMO red spanish pineapple", "code": "50545607" },
      { "name": "Frozen non GMO smooth cayenne pineapple", "code": "50545608" },
      { "name": "Frozen non GMO sugarloaf pineapple", "code": "50545609" },
      { "name": "Frozen non GMO variegated pineapple", "code": "50545610" },
      { "name": "Frozen non GMO plucots", "code": "50545700" },
      { "name": "Frozen non GMO black kat plucot", "code": "50545701" },
      { "name": "Frozen non GMO blue gusto plucot", "code": "50545702" },
      { "name": "Frozen non GMO crimson heart plucot", "code": "50545703" },
      { "name": "Frozen non GMO dapple dandy plucot", "code": "50545704" },
      { "name": "Frozen non GMO dapple fire plucot", "code": "50545705" },
      { "name": "Frozen non GMO early dapple plucot", "code": "50545706" },
      { "name": "Frozen non GMO flavor fall plucot", "code": "50545707" },
      { "name": "Frozen non GMO flavor gold plucot", "code": "50545708" },
      { "name": "Frozen non GMO flavor grenade plucot", "code": "50545709" },
      { "name": "Frozen non GMO flavor heart plucot", "code": "50545710" },
      { "name": "Frozen non GMO flavor jewel plucot", "code": "50545711" },
      { "name": "Frozen non GMO flavor king plucot", "code": "50545712" },
      { "name": "Frozen non GMO flavor queen plucot", "code": "50545713" },
      { "name": "Frozen non GMO flavor supreme plucot", "code": "50545714" },
      { "name": "Frozen non GMO flavor treat plucot", "code": "50545715" },
      { "name": "Frozen non GMO flavorella plucot", "code": "50545716" },
      { "name": "Frozen non GMO flavorich plucot", "code": "50545717" },
      { "name": "Frozen non GMO flavorosa plucot", "code": "50545718" },
      { "name": "Frozen non GMO geo pride plucot", "code": "50545719" },
      { "name": "Frozen non GMO red kat plucot", "code": "50545720" },
      { "name": "Frozen non GMO royal treat plucot", "code": "50545721" },
      { "name": "Frozen non GMO sierra rose plucot", "code": "50545722" },
      { "name": "Frozen non GMO sweet geisha plucot", "code": "50545723" },
      { "name": "Frozen non GMO plums", "code": "50545800" },
      { "name": "Frozen non GMO amber jewel plums", "code": "50545801" },
      { "name": "Frozen non GMO angeleno plums", "code": "50545802" },
      { "name": "Frozen non GMO aurora plums", "code": "50545803" },
      { "name": "Frozen non GMO autumn beaut plums", "code": "50545804" },
      { "name": "Frozen non GMO autumn giant plums", "code": "50545805" },
      { "name": "Frozen non GMO autumn pride plums", "code": "50545806" },
      { "name": "Frozen non GMO autumn rosa plums", "code": "50545807" },
      { "name": "Frozen non GMO beach plum", "code": "50545808" },
      { "name": "Frozen non GMO betty anne plums", "code": "50545809" },
      { "name": "Frozen non GMO black beaut plums", "code": "50545810" },
      { "name": "Frozen non GMO black bullace plum", "code": "50545811" },
      { "name": "Frozen non GMO black diamond plums", "code": "50545812" },
      { "name": "Frozen non GMO black giant plums", "code": "50545813" },
      { "name": "Frozen non GMO black ice plums", "code": "50545814" },
      { "name": "Frozen non GMO black splendor plums", "code": "50545815" },
      { "name": "Frozen non GMO blackamber plums", "code": "50545816" },
      { "name": "Frozen non GMO burgundy plums", "code": "50545817" },
      { "name": "Frozen non GMO carlsbad plum", "code": "50545818" },
      { "name": "Frozen non GMO casselman plums", "code": "50545819" },
      { "name": "Frozen non GMO catalina plums", "code": "50545820" },
      { "name": "Frozen non GMO damson plum", "code": "50545821" },
      { "name": "Frozen non GMO dolly plums", "code": "50545822" },
      { "name": "Frozen non GMO earliqueen plums", "code": "50545823" },
      { "name": "Frozen non GMO early rosa plums", "code": "50545824" },
      { "name": "Frozen non GMO ebony may plums", "code": "50545825" },
      { "name": "Frozen non GMO ebony plums", "code": "50545826" },
      { "name": "Frozen non GMO elephant heart plums", "code": "50545827" },
      { "name": "Frozen non GMO emerald beaut plums", "code": "50545828" },
      { "name": "Frozen non GMO empress plums", "code": "50545829" },
      { "name": "Frozen non GMO freedom plums", "code": "50545830" },
      { "name": "Frozen non GMO friar plums", "code": "50545831" },
      { "name": "Frozen non GMO gar red plums", "code": "50545832" },
      { "name": "Frozen non GMO governor's plum", "code": "50545833" },
      { "name": "Frozen non GMO grand rosa plums", "code": "50545834" },
      { "name": "Frozen non GMO green gage plum", "code": "50545835" },
      { "name": "Frozen non GMO greengage plums", "code": "50545836" },
      { "name": "Frozen non GMO hiromi plums", "code": "50545837" },
      { "name": "Frozen non GMO hiromi red plums", "code": "50545838" },
      { "name": "Frozen non GMO holiday plums", "code": "50545839" },
      { "name": "Frozen non GMO howard sun plums", "code": "50545840" },
      { "name": "Frozen non GMO interspecific type plums", "code": "50545841" },
      { "name": "Frozen non GMO jamaican plum", "code": "50545842" },
      { "name": "Frozen non GMO joanna red plums", "code": "50545843" },
      { "name": "Frozen non GMO kelsey plums", "code": "50545844" },
      { "name": "Frozen non GMO king james plums", "code": "50545845" },
      { "name": "Frozen non GMO laroda plums", "code": "50545846" },
      { "name": "Frozen non GMO late rosa plums", "code": "50545847" },
      { "name": "Frozen non GMO linda rosa plums", "code": "50545848" },
      { "name": "Frozen non GMO lone star red plums", "code": "50545849" },
      { "name": "Frozen non GMO mariposa plums", "code": "50545850" },
      { "name": "Frozen non GMO marked black plums", "code": "50545851" },
      { "name": "Frozen non GMO marked red plums", "code": "50545852" },
      { "name": "Frozen non GMO mirabelle plum", "code": "50545853" },
      { "name": "Frozen non GMO october sun plums", "code": "50545854" },
      { "name": "Frozen non GMO owen t plums", "code": "50545855" },
      { "name": "Frozen non GMO perdrigon plum", "code": "50545856" },
      { "name": "Frozen non GMO pink delight plums", "code": "50545857" },
      { "name": "Frozen non GMO president plums", "code": "50545858" },
      { "name": "Frozen non GMO primetime plums", "code": "50545859" },
      { "name": "Frozen non GMO purple majesty plums", "code": "50545860" },
      { "name": "Frozen non GMO queen rosa plums", "code": "50545861" },
      { "name": "Frozen non GMO quetsch plum", "code": "50545862" },
      { "name": "Frozen non GMO red beaut plums", "code": "50545863" },
      { "name": "Frozen non GMO red lane plums", "code": "50545864" },
      { "name": "Frozen non GMO red ram plums", "code": "50545865" },
      { "name": "Frozen non GMO red rosa plums", "code": "50545866" },
      { "name": "Frozen non GMO rich red plums", "code": "50545867" },
      { "name": "Frozen non GMO rosemary plums", "code": "50545868" },
      { "name": "Frozen non GMO royal diamond plums", "code": "50545869" },
      { "name": "Frozen non GMO royal red plums", "code": "50545870" },
      { "name": "Frozen non GMO royal zee plums", "code": "50545871" },
      { "name": "Frozen non GMO roysum plums", "code": "50545872" },
      { "name": "Frozen non GMO santa rosa plums", "code": "50545873" },
      { "name": "Frozen non GMO saphire plums", "code": "50545874" },
      { "name": "Frozen non GMO sloe plum", "code": "50545875" },
      { "name": "Frozen non GMO st catherine plum", "code": "50545876" },
      { "name": "Frozen non GMO white bullace plum", "code": "50545877" },
      { "name": "Frozen non GMO pomegranates", "code": "50545900" },
      { "name": "Frozen non GMO foothill pomegranates", "code": "50545901" },
      { "name": "Frozen non GMO granada pomegranates", "code": "50545902" },
      { "name": "Frozen non GMO jolly red pomegranates", "code": "50545903" },
      { "name": "Frozen non GMO nana pomegranates", "code": "50545904" },
      { "name": "Frozen non GMO pat's red pomegranates", "code": "50545905" },
      { "name": "Frozen non GMO pinkhan pomegranates", "code": "50545906" },
      {
        "name": "Frozen non GMO purple velvet pomegranates",
        "code": "50545907"
      },
      { "name": "Frozen non GMO wonderful pomegranates", "code": "50545908" },
      { "name": "Frozen non GMO pomelos", "code": "50546000" },
      { "name": "Frozen non GMO chandler pomelo", "code": "50546001" },
      { "name": "Frozen non GMO hirado buntan pomelo", "code": "50546002" },
      { "name": "Frozen non GMO liang ping yau pomelo", "code": "50546003" },
      { "name": "Frozen non GMO pandan wangi pomelo", "code": "50546004" },
      { "name": "Frozen non GMO pink pomelo", "code": "50546005" },
      { "name": "Frozen non GMO red shaddock pomelo", "code": "50546006" },
      { "name": "Frozen non GMO siamese sweet pomelo", "code": "50546007" },
      { "name": "Frozen non GMO wainwright pomelo", "code": "50546008" },
      { "name": "Frozen non GMO quinces", "code": "50546100" },
      { "name": "Frozen non GMO champion quince", "code": "50546101" },
      { "name": "Frozen non GMO pineapple quince", "code": "50546102" },
      { "name": "Frozen non GMO smyrna quince", "code": "50546103" },
      { "name": "Frozen non GMO raspberries", "code": "50546200" },
      { "name": "Frozen non GMO american red raspberry", "code": "50546201" },
      {
        "name": "Frozen non GMO bailey queensland raspberry",
        "code": "50546202"
      },
      { "name": "Frozen non GMO black raspberry", "code": "50546203" },
      { "name": "Frozen non GMO dark raspberry", "code": "50546204" },
      { "name": "Frozen non GMO delicious raspberry", "code": "50546205" },
      { "name": "Frozen non GMO focke dwarf raspberry", "code": "50546206" },
      {
        "name": "Frozen non GMO focke grayleaf red raspberry",
        "code": "50546207"
      },
      {
        "name": "Frozen non GMO focke strawberry raspberry",
        "code": "50546208"
      },
      {
        "name": "Frozen non GMO focke yellow himalayan raspberry",
        "code": "50546209"
      },
      { "name": "Frozen non GMO gold raspberry", "code": "50546210" },
      {
        "name": "Frozen non GMO gray new mexico raspberry",
        "code": "50546211"
      },
      {
        "name": "Frozen non GMO jepson whitebark raspberry",
        "code": "50546212"
      },
      {
        "name": "Frozen non GMO kellogg san diego raspberry",
        "code": "50546213"
      },
      {
        "name": "Frozen non GMO leucodermis whitebark raspberry",
        "code": "50546214"
      },
      { "name": "Frozen non GMO munz cuyamaca raspberry", "code": "50546215" },
      { "name": "Frozen non GMO peck barton's raspberry", "code": "50546216" },
      {
        "name": "Frozen non GMO purpleflowering raspberry",
        "code": "50546217"
      },
      { "name": "Frozen non GMO roadside raspberry", "code": "50546218" },
      { "name": "Frozen non GMO san diego raspberry", "code": "50546219" },
      { "name": "Frozen non GMO snow raspberry", "code": "50546220" },
      { "name": "Frozen non GMO snowpeaks raspberry", "code": "50546221" },
      { "name": "Frozen non GMO strawberryleaf raspberry", "code": "50546222" },
      {
        "name": "Frozen non GMO sweet cultivated raspberry",
        "code": "50546223"
      },
      {
        "name": "Frozen non GMO torr and gray whitebark raspberry",
        "code": "50546224"
      },
      { "name": "Frozen non GMO west indian raspberry", "code": "50546225" },
      { "name": "Frozen non GMO whitebark raspberry", "code": "50546226" },
      { "name": "Frozen non GMO wine raspberry", "code": "50546227" },
      {
        "name": "Frozen non GMO yellow himalayan raspberry",
        "code": "50546228"
      },
      { "name": "Frozen non GMO yu-shan raspberry", "code": "50546229" },
      { "name": "Frozen non GMO rhubarbs", "code": "50546300" },
      { "name": "Frozen non GMO crimson red rhubarb", "code": "50546301" },
      { "name": "Frozen non GMO early champagne rhubarb", "code": "50546302" },
      {
        "name": "Frozen non GMO glaskin's perpetual rhubarb",
        "code": "50546303"
      },
      { "name": "Frozen non GMO sutton rhubarb", "code": "50546304" },
      { "name": "Frozen non GMO timperley early rhubarb", "code": "50546305" },
      { "name": "Frozen non GMO valentine rhubarb", "code": "50546306" },
      { "name": "Frozen non GMO victoria rhubarb", "code": "50546307" },
      { "name": "Frozen non GMO zwolle seedling rhubarb", "code": "50546308" },
      { "name": "Frozen non GMO macdonald rhubarb", "code": "50546309" },
      { "name": "Frozen non GMO tilden rhubarb", "code": "50546310" },
      { "name": "Frozen non GMO rose hips", "code": "50546400" },
      { "name": "Frozen non GMO brier rose hips", "code": "50546401" },
      { "name": "Frozen non GMO elgantine rose hips", "code": "50546402" },
      { "name": "Frozen non GMO rugosa rose hips", "code": "50546403" },
      {
        "name": "Frozen non GMO scotch or burnet rose hips",
        "code": "50546404"
      },
      { "name": "Frozen non GMO sapotes", "code": "50546500" },
      { "name": "Frozen non GMO white sapotes", "code": "50546501" },
      { "name": "Frozen non GMO black sapotes", "code": "50546502" },
      { "name": "Frozen non GMO saskatoon berries", "code": "50546600" },
      {
        "name": "Frozen non GMO honeywood saskatoon berries",
        "code": "50546601"
      },
      {
        "name": "Frozen non GMO northline saskatoon berries",
        "code": "50546602"
      },
      { "name": "Frozen non GMO smoky saskatoon berries", "code": "50546603" },
      {
        "name": "Frozen non GMO thiessen saskatoon berries",
        "code": "50546604"
      },
      { "name": "Frozen non GMO strawberries", "code": "50546700" },
      { "name": "Frozen non GMO chandler strawberries", "code": "50546701" },
      {
        "name": "Frozen non GMO june bearing strawberries",
        "code": "50546702"
      },
      {
        "name": "Frozen non GMO ever bearing strawberries",
        "code": "50546703"
      },
      { "name": "Frozen non GMO sugar apples", "code": "50546800" },
      {
        "name": "Frozen non GMO kampong mauve sugar apple",
        "code": "50546801"
      },
      { "name": "Frozen non GMO seedless sugar apple", "code": "50546802" },
      { "name": "Frozen non GMO thai lessard sugar apple", "code": "50546803" },
      { "name": "Frozen non GMO tamarillos", "code": "50546900" },
      { "name": "Frozen non GMO amberlea gold tamarillo", "code": "50546901" },
      { "name": "Frozen non GMO bold gold tamarillo", "code": "50546902" },
      { "name": "Frozen non GMO goldmine tamarillo", "code": "50546903" },
      { "name": "Frozen non GMO oratia red tamarillo", "code": "50546904" },
      { "name": "Frozen non GMO red beau tamarillo", "code": "50546905" },
      { "name": "Frozen non GMO red delight tamarillo", "code": "50546906" },
      { "name": "Frozen non GMO nominant fruits", "code": "50547000" },
      { "name": "Frozen non GMO akee", "code": "50547001" },
      { "name": "Frozen non GMO babaco", "code": "50547002" },
      { "name": "Frozen non GMO banana flowers", "code": "50547003" },
      { "name": "Frozen non GMO baobab", "code": "50547004" },
      { "name": "Frozen non GMO bitter oranges", "code": "50547005" },
      { "name": "Frozen non GMO canistel", "code": "50547006" },
      { "name": "Frozen non GMO coconuts", "code": "50547007" },
      { "name": "Frozen non GMO cloudberries", "code": "50547008" },
      { "name": "Frozen non GMO dewberries", "code": "50547009" },
      { "name": "Frozen non GMO durian", "code": "50547010" },
      { "name": "Frozen non GMO elderberries", "code": "50547011" },
      { "name": "Frozen non GMO feijoa", "code": "50547012" },
      { "name": "Frozen non GMO hackberries", "code": "50547013" },
      { "name": "Frozen non GMO hawthorn", "code": "50547014" },
      { "name": "Frozen non GMO honeyberries", "code": "50547015" },
      { "name": "Frozen non GMO jackfruit", "code": "50547016" },
      { "name": "Frozen non GMO jambolan", "code": "50547017" },
      { "name": "Frozen non GMO jujube", "code": "50547018" },
      { "name": "Frozen non GMO lychee", "code": "50547019" },
      { "name": "Frozen non GMO mangosteens", "code": "50547020" },
      { "name": "Frozen non GMO medlars", "code": "50547021" },
      { "name": "Frozen non GMO mombins", "code": "50547022" },
      { "name": "Frozen non GMO monstera", "code": "50547023" },
      { "name": "Frozen non GMO pepinos", "code": "50547024" },
      { "name": "Frozen non GMO plantains", "code": "50547025" },
      { "name": "Frozen non GMO prickly pears", "code": "50547026" },
      { "name": "Frozen non GMO quenepas", "code": "50547027" },
      { "name": "Frozen non GMO rambutan", "code": "50547028" },
      { "name": "Frozen non GMO rose apples", "code": "50547029" },
      { "name": "Frozen non GMO roselle", "code": "50547030" },
      { "name": "Frozen non GMO rowanberries", "code": "50547031" },
      { "name": "Frozen non GMO sea buckhorn berries", "code": "50547032" },
      { "name": "Frozen non GMO silverberries", "code": "50547033" },
      { "name": "Frozen non GMO sorb berries", "code": "50547034" },
      { "name": "Frozen non GMO soursops", "code": "50547035" },
      { "name": "Frozen non GMO star apples", "code": "50547036" },
      { "name": "Frozen non GMO tamarindo", "code": "50547037" },
      { "name": "Frozen non GMO chokeberries", "code": "50547100" },
      {
        "name": "Frozen non GMO autumn magic chokeberries",
        "code": "50547101"
      },
      {
        "name": "Frozen non GMO brillantisima chokeberries",
        "code": "50547102"
      },
      { "name": "Frozen non GMO nero chokeberries", "code": "50547103" },
      { "name": "Frozen non GMO viking chokeberries", "code": "50547104" },
      { "name": "Frozen non GMO olives", "code": "50547200" },
      { "name": "Frozen non GMO agrinion olives", "code": "50547201" },
      { "name": "Frozen non GMO aleppo olives", "code": "50547202" },
      { "name": "Frozen non GMO alphonso olives", "code": "50547203" },
      { "name": "Frozen non GMO amphissa olives", "code": "50547204" },
      { "name": "Frozen non GMO arauco olives", "code": "50547205" },
      { "name": "Frozen non GMO arbequina olives", "code": "50547206" },
      { "name": "Frozen non GMO atalanta olives", "code": "50547207" },
      { "name": "Frozen non GMO cerignola olives", "code": "50547208" },
      { "name": "Frozen non GMO cracked provencal olives", "code": "50547209" },
      { "name": "Frozen non GMO empeltre olives", "code": "50547210" },
      { "name": "Frozen non GMO gaeta olives", "code": "50547211" },
      { "name": "Frozen non GMO hondroelia olives", "code": "50547212" },
      { "name": "Frozen non GMO kalamata olives", "code": "50547213" },
      { "name": "Frozen non GMO kura olives", "code": "50547214" },
      { "name": "Frozen non GMO ligurian olives", "code": "50547215" },
      { "name": "Frozen non GMO lucque olives", "code": "50547216" },
      { "name": "Frozen non GMO lugano olives", "code": "50547217" },
      { "name": "Frozen non GMO manzanilla olives", "code": "50547218" },
      { "name": "Frozen non GMO marche olives", "code": "50547219" },
      { "name": "Frozen non GMO mission olives", "code": "50547220" },
      { "name": "Frozen non GMO nafplion green olives", "code": "50547221" },
      { "name": "Frozen non GMO nicoise olives", "code": "50547222" },
      { "name": "Frozen non GMO nyons olives", "code": "50547223" },
      { "name": "Frozen non GMO picholine olives", "code": "50547224" },
      { "name": "Frozen non GMO ponentine olives", "code": "50547225" },
      { "name": "Frozen non GMO royal olives", "code": "50547226" },
      { "name": "Frozen non GMO seracena olives", "code": "50547227" },
      { "name": "Frozen non GMO sevillano olives", "code": "50547228" },
      { "name": "Frozen non GMO sicilian olives", "code": "50547229" },
      { "name": "Frozen non GMO toscanelle olives", "code": "50547230" },
      { "name": "Canned or jarred non GMO fruits", "code": "50550000" },
      { "name": "Canned or jarred non GMO apples", "code": "50551500" },
      { "name": "Canned or jarred non GMO akane apples", "code": "50551501" },
      {
        "name": "Canned or jarred non GMO ambrosia apples",
        "code": "50551502"
      },
      { "name": "Canned or jarred non GMO api apples", "code": "50551503" },
      { "name": "Canned or jarred non GMO baldwin apples", "code": "50551504" },
      {
        "name": "Canned or jarred non GMO braeburn apples",
        "code": "50551505"
      },
      { "name": "Canned or jarred non GMO bramley apples", "code": "50551506" },
      {
        "name": "Canned or jarred non GMO bramley seedling apples",
        "code": "50551507"
      },
      {
        "name": "Canned or jarred non GMO calville blanche d'hiver apples",
        "code": "50551508"
      },
      { "name": "Canned or jarred non GMO cameo apples", "code": "50551509" },
      {
        "name": "Canned or jarred non GMO charles ross apples",
        "code": "50551510"
      },
      { "name": "Canned or jarred non GMO codlin apples", "code": "50551511" },
      {
        "name": "Canned or jarred non GMO cortland apples",
        "code": "50551512"
      },
      { "name": "Canned or jarred non GMO costard apples", "code": "50551513" },
      {
        "name": "Canned or jarred non GMO court pendu plat apples",
        "code": "50551514"
      },
      {
        "name": "Canned or jarred non GMO cox's orange pippin apples",
        "code": "50551515"
      },
      { "name": "Canned or jarred non GMO crab apples", "code": "50551516" },
      { "name": "Canned or jarred non GMO crispin apples", "code": "50551517" },
      {
        "name": "Canned or jarred non GMO delicious apples",
        "code": "50551518"
      },
      { "name": "Canned or jarred non GMO duchess apples", "code": "50551519" },
      {
        "name": "Canned or jarred non GMO earligold apples",
        "code": "50551520"
      },
      {
        "name": "Canned or jarred non GMO early mcintosh apples",
        "code": "50551521"
      },
      { "name": "Canned or jarred non GMO elstar apples", "code": "50551522" },
      { "name": "Canned or jarred non GMO empire apples", "code": "50551523" },
      {
        "name": "Canned or jarred non GMO flower of kent apples",
        "code": "50551524"
      },
      { "name": "Canned or jarred non GMO fuji apples", "code": "50551525" },
      { "name": "Canned or jarred non GMO gala apples", "code": "50551526" },
      {
        "name": "Canned or jarred non GMO gascoyne's scarlet apples",
        "code": "50551527"
      },
      {
        "name": "Canned or jarred non GMO gilliflower apples",
        "code": "50551528"
      },
      {
        "name": "Canned or jarred non GMO ginger gold apples",
        "code": "50551529"
      },
      {
        "name": "Canned or jarred non GMO gladstone apples",
        "code": "50551530"
      },
      { "name": "Canned or jarred non GMO gloster apples", "code": "50551531" },
      {
        "name": "Canned or jarred non GMO gold supreme apples",
        "code": "50551532"
      },
      {
        "name": "Canned or jarred non GMO golden delicious apples",
        "code": "50551533"
      },
      {
        "name": "Canned or jarred non GMO golden noble apples",
        "code": "50551534"
      },
      {
        "name": "Canned or jarred non GMO granny smith apples",
        "code": "50551535"
      },
      {
        "name": "Canned or jarred non GMO gravenstein apples",
        "code": "50551536"
      },
      {
        "name": "Canned or jarred non GMO greening apples",
        "code": "50551537"
      },
      {
        "name": "Canned or jarred non GMO greensleeves apples",
        "code": "50551538"
      },
      {
        "name": "Canned or jarred non GMO honeycrisp apples",
        "code": "50551539"
      },
      {
        "name": "Canned or jarred non GMO howgate wonder apples",
        "code": "50551540"
      },
      { "name": "Canned or jarred non GMO ida red apples", "code": "50551541" },
      {
        "name": "Canned or jarred non GMO james grieve apples",
        "code": "50551542"
      },
      {
        "name": "Canned or jarred non GMO jersey mac apples",
        "code": "50551543"
      },
      { "name": "Canned or jarred non GMO jester apples", "code": "50551544" },
      {
        "name": "Canned or jarred non GMO jonagold apples",
        "code": "50551545"
      },
      { "name": "Canned or jarred non GMO jonamac apples", "code": "50551546" },
      {
        "name": "Canned or jarred non GMO jonathan apples",
        "code": "50551547"
      },
      { "name": "Canned or jarred non GMO katy apples", "code": "50551548" },
      {
        "name": "Canned or jarred non GMO kidd's orange red apples",
        "code": "50551549"
      },
      { "name": "Canned or jarred non GMO lady apples", "code": "50551550" },
      {
        "name": "Canned or jarred non GMO law rome apples",
        "code": "50551551"
      },
      { "name": "Canned or jarred non GMO laxton apples", "code": "50551552" },
      {
        "name": "Canned or jarred non GMO lord derby apples",
        "code": "50551553"
      },
      { "name": "Canned or jarred non GMO macoun apples", "code": "50551554" },
      {
        "name": "Canned or jarred non GMO mcintosh apples",
        "code": "50551555"
      },
      { "name": "Canned or jarred non GMO mutsu apples", "code": "50551556" },
      {
        "name": "Canned or jarred non GMO newtown pippin apples",
        "code": "50551557"
      },
      {
        "name": "Canned or jarred non GMO northern spy apples",
        "code": "50551558"
      },
      {
        "name": "Canned or jarred non GMO orleans reinette apples",
        "code": "50551559"
      },
      {
        "name": "Canned or jarred non GMO ozark gold apples",
        "code": "50551560"
      },
      {
        "name": "Canned or jarred non GMO pacific rose apples",
        "code": "50551561"
      },
      {
        "name": "Canned or jarred non GMO paula red apples",
        "code": "50551562"
      },
      {
        "name": "Canned or jarred non GMO pearmain apples",
        "code": "50551563"
      },
      {
        "name": "Canned or jarred non GMO pink lady apples",
        "code": "50551564"
      },
      { "name": "Canned or jarred non GMO pippin apples", "code": "50551565" },
      {
        "name": "Canned or jarred non GMO pitmaston pineapple apples",
        "code": "50551566"
      },
      {
        "name": "Canned or jarred non GMO pomme d'api apples",
        "code": "50551567"
      },
      {
        "name": "Canned or jarred non GMO prime gold apples",
        "code": "50551568"
      },
      {
        "name": "Canned or jarred non GMO red astrachan apples",
        "code": "50551569"
      },
      {
        "name": "Canned or jarred non GMO red boscoop apples",
        "code": "50551570"
      },
      {
        "name": "Canned or jarred non GMO red chief apples",
        "code": "50551571"
      },
      {
        "name": "Canned or jarred non GMO red delicious apples",
        "code": "50551572"
      },
      {
        "name": "Canned or jarred non GMO red gravenstein apples",
        "code": "50551573"
      },
      {
        "name": "Canned or jarred non GMO red rome apples",
        "code": "50551574"
      },
      {
        "name": "Canned or jarred non GMO red stayman apples",
        "code": "50551575"
      },
      {
        "name": "Canned or jarred non GMO red york apples",
        "code": "50551576"
      },
      {
        "name": "Canned or jarred non GMO reinette apples",
        "code": "50551577"
      },
      {
        "name": "Canned or jarred non GMO rome beauty apples",
        "code": "50551578"
      },
      { "name": "Canned or jarred non GMO russet apples", "code": "50551579" },
      {
        "name": "Canned or jarred non GMO sierra beauty apples",
        "code": "50551580"
      },
      { "name": "Canned or jarred non GMO spartan apples", "code": "50551581" },
      {
        "name": "Canned or jarred non GMO stark crimson apples",
        "code": "50551582"
      },
      {
        "name": "Canned or jarred non GMO starking apples",
        "code": "50551583"
      },
      { "name": "Canned or jarred non GMO stayman apples", "code": "50551584" },
      {
        "name": "Canned or jarred non GMO stayman winesap apples",
        "code": "50551585"
      },
      {
        "name": "Canned or jarred non GMO summer rambo apples",
        "code": "50551586"
      },
      { "name": "Canned or jarred non GMO tsugaru apples", "code": "50551587" },
      {
        "name": "Canned or jarred non GMO twenty ounce apples",
        "code": "50551588"
      },
      {
        "name": "Canned or jarred non GMO tydeman red apples",
        "code": "50551589"
      },
      {
        "name": "Canned or jarred non GMO vistabella apples",
        "code": "50551590"
      },
      { "name": "Canned or jarred non GMO wealthy apples", "code": "50551591" },
      {
        "name": "Canned or jarred non GMO white joaneting apples",
        "code": "50551592"
      },
      {
        "name": "Canned or jarred non GMO white transparent apples",
        "code": "50551593"
      },
      { "name": "Canned or jarred non GMO winesap apples", "code": "50551594" },
      {
        "name": "Canned or jarred non GMO worcester apples",
        "code": "50551595"
      },
      {
        "name": "Canned or jarred non GMO york imperial apples",
        "code": "50551596"
      },
      { "name": "Canned or jarred non GMO apricots", "code": "50551600" },
      {
        "name": "Canned or jarred non GMO ambercot apricots",
        "code": "50551601"
      },
      {
        "name": "Canned or jarred non GMO apache apricots",
        "code": "50551602"
      },
      {
        "name": "Canned or jarred non GMO brittany gold apricots",
        "code": "50551603"
      },
      { "name": "Canned or jarred non GMO black apricots", "code": "50551604" },
      {
        "name": "Canned or jarred non GMO blenheim apricots",
        "code": "50551605"
      },
      { "name": "Canned or jarred non GMO bonny apricots", "code": "50551606" },
      {
        "name": "Canned or jarred non GMO bulida apricots",
        "code": "50551607"
      },
      {
        "name": "Canned or jarred non GMO castlebrite apricots",
        "code": "50551608"
      },
      {
        "name": "Canned or jarred non GMO clutha gold apricots",
        "code": "50551609"
      },
      {
        "name": "Canned or jarred non GMO clutha sun apricots",
        "code": "50551610"
      },
      {
        "name": "Canned or jarred non GMO darby royal apricots",
        "code": "50551611"
      },
      { "name": "Canned or jarred non GMO dina apricots", "code": "50551612" },
      {
        "name": "Canned or jarred non GMO earlicot apricots",
        "code": "50551613"
      },
      {
        "name": "Canned or jarred non GMO earliman apricots",
        "code": "50551614"
      },
      {
        "name": "Canned or jarred non GMO early bright apricots",
        "code": "50551615"
      },
      {
        "name": "Canned or jarred non GMO flaming gold apricots",
        "code": "50551616"
      },
      {
        "name": "Canned or jarred non GMO fresno apricots",
        "code": "50551617"
      },
      {
        "name": "Canned or jarred non GMO gold brite apricots",
        "code": "50551618"
      },
      {
        "name": "Canned or jarred non GMO goldbar apricots",
        "code": "50551619"
      },
      {
        "name": "Canned or jarred non GMO golden sweet apricots",
        "code": "50551620"
      },
      {
        "name": "Canned or jarred non GMO goldrich apricots",
        "code": "50551621"
      },
      {
        "name": "Canned or jarred non GMO helena apricots",
        "code": "50551622"
      },
      {
        "name": "Canned or jarred non GMO honeycot apricots",
        "code": "50551623"
      },
      {
        "name": "Canned or jarred non GMO imperial apricots",
        "code": "50551624"
      },
      {
        "name": "Canned or jarred non GMO jordanne apricots",
        "code": "50551625"
      },
      {
        "name": "Canned or jarred non GMO jumbo cot apricots",
        "code": "50551626"
      },
      {
        "name": "Canned or jarred non GMO kandy kot apricots",
        "code": "50551627"
      },
      { "name": "Canned or jarred non GMO katy apricots", "code": "50551628" },
      { "name": "Canned or jarred non GMO king apricots", "code": "50551629" },
      {
        "name": "Canned or jarred non GMO lambertin apricots",
        "code": "50551630"
      },
      { "name": "Canned or jarred non GMO lorna apricots", "code": "50551631" },
      {
        "name": "Canned or jarred non GMO lulu belle apricots",
        "code": "50551632"
      },
      {
        "name": "Canned or jarred non GMO modesto apricots",
        "code": "50551633"
      },
      {
        "name": "Canned or jarred non GMO moorpark apricots",
        "code": "50551634"
      },
      {
        "name": "Canned or jarred non GMO orangered apricots",
        "code": "50551635"
      },
      {
        "name": "Canned or jarred non GMO palstein apricots",
        "code": "50551636"
      },
      {
        "name": "Canned or jarred non GMO patterson apricots",
        "code": "50551637"
      },
      {
        "name": "Canned or jarred non GMO perfection apricots",
        "code": "50551638"
      },
      { "name": "Canned or jarred non GMO poppy apricots", "code": "50551639" },
      {
        "name": "Canned or jarred non GMO poppycot apricots",
        "code": "50551640"
      },
      { "name": "Canned or jarred non GMO queen apricots", "code": "50551641" },
      {
        "name": "Canned or jarred non GMO riland apricots",
        "code": "50551642"
      },
      { "name": "Canned or jarred non GMO rival apricots", "code": "50551643" },
      {
        "name": "Canned or jarred non GMO robada apricots",
        "code": "50551644"
      },
      { "name": "Canned or jarred non GMO royal apricots", "code": "50551645" },
      {
        "name": "Canned or jarred non GMO royal blenheim apricots",
        "code": "50551646"
      },
      {
        "name": "Canned or jarred non GMO royal orange apricots",
        "code": "50551647"
      },
      {
        "name": "Canned or jarred non GMO sundrop apricots",
        "code": "50551648"
      },
      {
        "name": "Canned or jarred non GMO tilton apricots",
        "code": "50551649"
      },
      {
        "name": "Canned or jarred non GMO tomcot apricots",
        "code": "50551650"
      },
      { "name": "Canned or jarred non GMO tracy apricots", "code": "50551651" },
      {
        "name": "Canned or jarred non GMO tri gem apricots",
        "code": "50551652"
      },
      {
        "name": "Canned or jarred non GMO valley gold apricots",
        "code": "50551653"
      },
      {
        "name": "Canned or jarred non GMO westley apricots",
        "code": "50551654"
      },
      { "name": "Canned or jarred non GMO york apricots", "code": "50551655" },
      { "name": "Canned or jarred non GMO bananas", "code": "50551700" },
      { "name": "Canned or jarred non GMO apple bananas", "code": "50551701" },
      { "name": "Canned or jarred non GMO baby bananas", "code": "50551702" },
      { "name": "Canned or jarred non GMO burro bananas", "code": "50551703" },
      {
        "name": "Canned or jarred non GMO cavendish bananas",
        "code": "50551704"
      },
      {
        "name": "Canned or jarred non GMO dominico bananas",
        "code": "50551705"
      },
      { "name": "Canned or jarred non GMO green bananas", "code": "50551706" },
      {
        "name": "Canned or jarred non GMO gros michel bananas",
        "code": "50551707"
      },
      {
        "name": "Canned or jarred non GMO lacatan bananas",
        "code": "50551708"
      },
      {
        "name": "Canned or jarred non GMO lady finger banana",
        "code": "50551709"
      },
      {
        "name": "Canned or jarred non GMO manzano bananas",
        "code": "50551710"
      },
      { "name": "Canned or jarred non GMO mysore bananas", "code": "50551711" },
      {
        "name": "Canned or jarred non GMO pisang mas bananas",
        "code": "50551712"
      },
      { "name": "Canned or jarred non GMO red bananas", "code": "50551713" },
      { "name": "Canned or jarred non GMO saba bananas", "code": "50551714" },
      {
        "name": "Canned or jarred non GMO sucrier bananas",
        "code": "50551715"
      },
      { "name": "Canned or jarred non GMO barberries", "code": "50551800" },
      {
        "name": "Canned or jarred non GMO paleleaf barberries",
        "code": "50551801"
      },
      {
        "name": "Canned or jarred non GMO chenault barberries",
        "code": "50551802"
      },
      { "name": "Canned or jarred non GMO red barberries", "code": "50551803" },
      {
        "name": "Canned or jarred non GMO wintergreen barberries",
        "code": "50551804"
      },
      {
        "name": "Canned or jarred non GMO korean barberries",
        "code": "50551805"
      },
      {
        "name": "Canned or jarred non GMO mentor barberries",
        "code": "50551806"
      },
      {
        "name": "Canned or jarred non GMO japanese barberries",
        "code": "50551807"
      },
      {
        "name": "Canned or jarred non GMO atropurpurea barberries",
        "code": "50551808"
      },
      {
        "name": "Canned or jarred non GMO aurea barberries",
        "code": "50551809"
      },
      {
        "name": "Canned or jarred non GMO bagatelle barberries",
        "code": "50551810"
      },
      {
        "name": "Canned or jarred non GMO crimson pygmy barberries",
        "code": "50551811"
      },
      {
        "name": "Canned or jarred non GMO kobold barberries",
        "code": "50551812"
      },
      {
        "name": "Canned or jarred non GMO warty barberries",
        "code": "50551813"
      },
      {
        "name": "Canned or jarred non GMO european barberries",
        "code": "50551814"
      },
      { "name": "Canned or jarred non GMO bearberries", "code": "50551900" },
      {
        "name": "Canned or jarred non GMO alpine bearberries",
        "code": "50551901"
      },
      {
        "name": "Canned or jarred non GMO red bearberries",
        "code": "50551902"
      },
      {
        "name": "Canned or jarred non GMO common bearberries",
        "code": "50551903"
      },
      { "name": "Canned or jarred non GMO blackberries", "code": "50552000" },
      {
        "name": "Canned or jarred non GMO apache blackberries",
        "code": "50552001"
      },
      {
        "name": "Canned or jarred non GMO black satin blackberries",
        "code": "50552002"
      },
      { "name": "Canned or jarred non GMO boysenberries", "code": "50552003" },
      {
        "name": "Canned or jarred non GMO cherokee blackberries",
        "code": "50552004"
      },
      {
        "name": "Canned or jarred non GMO chester blackberries",
        "code": "50552005"
      },
      {
        "name": "Canned or jarred non GMO dirksen blackberries",
        "code": "50552006"
      },
      { "name": "Canned or jarred non GMO jostaberries", "code": "50552007" },
      { "name": "Canned or jarred non GMO loganberries", "code": "50552008" },
      { "name": "Canned or jarred non GMO marionberries", "code": "50552009" },
      {
        "name": "Canned or jarred non GMO navaho blackberries",
        "code": "50552010"
      },
      { "name": "Canned or jarred non GMO nectarberries", "code": "50552011" },
      {
        "name": "Canned or jarred non GMO olallie blackberries",
        "code": "50552012"
      },
      { "name": "Canned or jarred non GMO tayberries", "code": "50552013" },
      {
        "name": "Canned or jarred non GMO thornless hull blackberries",
        "code": "50552014"
      },
      { "name": "Canned or jarred non GMO youngberries", "code": "50552015" },
      { "name": "Canned or jarred non GMO bilberries", "code": "50552100" },
      { "name": "Canned or jarred non GMO bog bilberries", "code": "50552101" },
      {
        "name": "Canned or jarred non GMO dwarf bilberries",
        "code": "50552102"
      },
      {
        "name": "Canned or jarred non GMO mountain bilberries",
        "code": "50552103"
      },
      {
        "name": "Canned or jarred non GMO oval-leaved bilberries",
        "code": "50552104"
      },
      { "name": "Canned or jarred non GMO blueberries", "code": "50552200" },
      {
        "name": "Canned or jarred non GMO bluecrop blueberries",
        "code": "50552201"
      },
      {
        "name": "Canned or jarred non GMO bluetta blueberries",
        "code": "50552202"
      },
      {
        "name": "Canned or jarred non GMO brigitta blueberries",
        "code": "50552203"
      },
      {
        "name": "Canned or jarred non GMO chandler blueberries",
        "code": "50552204"
      },
      {
        "name": "Canned or jarred non GMO duke blueberries",
        "code": "50552205"
      },
      {
        "name": "Canned or jarred non GMO hardyblue blueberries",
        "code": "50552206"
      },
      {
        "name": "Canned or jarred non GMO legacy blueberries",
        "code": "50552207"
      },
      {
        "name": "Canned or jarred non GMO misty blueberries",
        "code": "50552208"
      },
      {
        "name": "Canned or jarred non GMO nelson blueberries",
        "code": "50552209"
      },
      {
        "name": "Canned or jarred non GMO northblue blueberries",
        "code": "50552210"
      },
      {
        "name": "Canned or jarred non GMO northcountry blueberries",
        "code": "50552211"
      },
      {
        "name": "Canned or jarred non GMO northsky blueberries",
        "code": "50552212"
      },
      {
        "name": "Canned or jarred non GMO patriot blueberries",
        "code": "50552213"
      },
      {
        "name": "Canned or jarred non GMO spartan blueberries",
        "code": "50552214"
      },
      {
        "name": "Canned or jarred non GMO toro blueberries",
        "code": "50552215"
      },
      { "name": "Canned or jarred non GMO breadfruits", "code": "50552300" },
      {
        "name": "Canned or jarred non GMO chataigne breadfruit",
        "code": "50552301"
      },
      {
        "name": "Canned or jarred non GMO seedless breadfruit",
        "code": "50552302"
      },
      {
        "name": "Canned or jarred non GMO white heart breadfruit",
        "code": "50552303"
      },
      {
        "name": "Canned or jarred non GMO yellow heart breadfruit",
        "code": "50552304"
      },
      { "name": "Canned or jarred non GMO cherimoyas", "code": "50552400" },
      { "name": "Canned or jarred non GMO bays cherimoya", "code": "50552401" },
      {
        "name": "Canned or jarred non GMO bronceada cherimoya",
        "code": "50552402"
      },
      {
        "name": "Canned or jarred non GMO burtons cherimoya",
        "code": "50552403"
      },
      {
        "name": "Canned or jarred non GMO burtons favorite cherimoya",
        "code": "50552404"
      },
      { "name": "Canned or jarred non GMO jete cherimoya", "code": "50552405" },
      {
        "name": "Canned or jarred non GMO reretai cherimoya",
        "code": "50552406"
      },
      {
        "name": "Canned or jarred non GMO smoothey cherimoya",
        "code": "50552407"
      },
      {
        "name": "Canned or jarred non GMO spain cherimoya",
        "code": "50552408"
      },
      {
        "name": "Canned or jarred non GMO white cherimoya",
        "code": "50552409"
      },
      { "name": "Canned or jarred non GMO cherries", "code": "50552500" },
      {
        "name": "Canned or jarred non GMO amarelle cherries",
        "code": "50552501"
      },
      {
        "name": "Canned or jarred non GMO brooks cherries",
        "code": "50552502"
      },
      {
        "name": "Canned or jarred non GMO bigarreu cherries",
        "code": "50552503"
      },
      { "name": "Canned or jarred non GMO bing cherries", "code": "50552504" },
      {
        "name": "Canned or jarred non GMO black republic cherries",
        "code": "50552505"
      },
      {
        "name": "Canned or jarred non GMO black schmidt cherries",
        "code": "50552506"
      },
      {
        "name": "Canned or jarred non GMO black tartarian cherries",
        "code": "50552507"
      },
      {
        "name": "Canned or jarred non GMO fiesta bing cherries",
        "code": "50552508"
      },
      {
        "name": "Canned or jarred non GMO garnet cherries",
        "code": "50552509"
      },
      { "name": "Canned or jarred non GMO king cherries", "code": "50552510" },
      {
        "name": "Canned or jarred non GMO chapman cherries",
        "code": "50552511"
      },
      { "name": "Canned or jarred non GMO lapin cherries", "code": "50552512" },
      {
        "name": "Canned or jarred non GMO larian cherries",
        "code": "50552513"
      },
      {
        "name": "Canned or jarred non GMO dark guines cherries",
        "code": "50552514"
      },
      {
        "name": "Canned or jarred non GMO montmorency cherries",
        "code": "50552515"
      },
      { "name": "Canned or jarred non GMO duke cherries", "code": "50552516" },
      {
        "name": "Canned or jarred non GMO early rivers cherries",
        "code": "50552517"
      },
      {
        "name": "Canned or jarred non GMO ruby bing cherries",
        "code": "50552518"
      },
      {
        "name": "Canned or jarred non GMO santina cherries",
        "code": "50552519"
      },
      {
        "name": "Canned or jarred non GMO geans/guines cherries",
        "code": "50552520"
      },
      {
        "name": "Canned or jarred non GMO sonata cherries",
        "code": "50552521"
      },
      {
        "name": "Canned or jarred non GMO lambert cherries",
        "code": "50552522"
      },
      {
        "name": "Canned or jarred non GMO stella cherries",
        "code": "50552523"
      },
      {
        "name": "Canned or jarred non GMO sweetheart cherries",
        "code": "50552524"
      },
      {
        "name": "Canned or jarred non GMO tartarian cherries",
        "code": "50552525"
      },
      {
        "name": "Canned or jarred non GMO maraschino cherries",
        "code": "50552526"
      },
      { "name": "Canned or jarred non GMO van cherries", "code": "50552527" },
      {
        "name": "Canned or jarred non GMO morello cherries",
        "code": "50552528"
      },
      {
        "name": "Canned or jarred non GMO royal ann cherries",
        "code": "50552529"
      },
      {
        "name": "Canned or jarred non GMO ranier cherries",
        "code": "50552530"
      },
      { "name": "Canned or jarred non GMO royal cherries", "code": "50552531" },
      { "name": "Canned or jarred non GMO citrons", "code": "50552600" },
      {
        "name": "Canned or jarred non GMO buddha's hand citrons",
        "code": "50552601"
      },
      {
        "name": "Canned or jarred non GMO fingered citrons",
        "code": "50552602"
      },
      {
        "name": "Canned or jarred non GMO fo shoukan citrons",
        "code": "50552603"
      },
      {
        "name": "Canned or jarred non GMO bushakan citrons",
        "code": "50552604"
      },
      {
        "name": "Canned or jarred non GMO diamante citrons",
        "code": "50552605"
      },
      { "name": "Canned or jarred non GMO etrog citrons", "code": "50552606" },
      {
        "name": "Canned or jarred non GMO ponderosa citrons",
        "code": "50552607"
      },
      { "name": "Canned or jarred non GMO cranberries", "code": "50552700" },
      {
        "name": "Canned or jarred non GMO ben lear cranberries",
        "code": "50552701"
      },
      {
        "name": "Canned or jarred non GMO early black cranberries",
        "code": "50552702"
      },
      {
        "name": "Canned or jarred non GMO grycleski cranberries",
        "code": "50552703"
      },
      {
        "name": "Canned or jarred non GMO howe cranberries",
        "code": "50552704"
      },
      { "name": "Canned or jarred non GMO lingonberries", "code": "50552705" },
      {
        "name": "Canned or jarred non GMO mcfarlin cranberries",
        "code": "50552706"
      },
      {
        "name": "Canned or jarred non GMO mountain cranberries",
        "code": "50552707"
      },
      {
        "name": "Canned or jarred non GMO pilgrim cranberries",
        "code": "50552708"
      },
      {
        "name": "Canned or jarred non GMO searless cranberries",
        "code": "50552709"
      },
      {
        "name": "Canned or jarred non GMO stevens cranberries",
        "code": "50552710"
      },
      { "name": "Canned or jarred non GMO currants", "code": "50552800" },
      {
        "name": "Canned or jarred non GMO hudson bay currants",
        "code": "50552801"
      },
      { "name": "Canned or jarred non GMO waxy currants", "code": "50552802" },
      {
        "name": "Canned or jarred non GMO desert currants",
        "code": "50552803"
      },
      { "name": "Canned or jarred non GMO black currants", "code": "50552804" },
      { "name": "Canned or jarred non GMO red currants", "code": "50552805" },
      { "name": "Canned or jarred non GMO white currants", "code": "50552806" },
      { "name": "Canned or jarred non GMO dates", "code": "50552900" },
      { "name": "Canned or jarred non GMO asharasi dates", "code": "50552901" },
      {
        "name": "Canned or jarred non GMO barhi or barhee dates",
        "code": "50552902"
      },
      {
        "name": "Canned or jarred non GMO deglet noor dates",
        "code": "50552903"
      },
      { "name": "Canned or jarred non GMO fardh dates", "code": "50552904" },
      { "name": "Canned or jarred non GMO gundila dates", "code": "50552905" },
      {
        "name": "Canned or jarred non GMO halawi/halawy dates",
        "code": "50552906"
      },
      { "name": "Canned or jarred non GMO hilali dates", "code": "50552907" },
      {
        "name": "Canned or jarred non GMO khadrawi/khadrawy dates",
        "code": "50552908"
      },
      { "name": "Canned or jarred non GMO khalas dates", "code": "50552909" },
      { "name": "Canned or jarred non GMO khustawi dates", "code": "50552910" },
      { "name": "Canned or jarred non GMO khidri dates", "code": "50552911" },
      {
        "name": "Canned or jarred non GMO medjool/medjul dates",
        "code": "50552912"
      },
      { "name": "Canned or jarred non GMO mactoum dates", "code": "50552913" },
      { "name": "Canned or jarred non GMO neghal dates", "code": "50552914" },
      { "name": "Canned or jarred non GMO yatimeh dates", "code": "50552915" },
      { "name": "Canned or jarred non GMO zahidi dates", "code": "50552916" },
      { "name": "Canned or jarred non GMO dragonfruits", "code": "50553000" },
      {
        "name": "Canned or jarred non GMO pink dragonfruit",
        "code": "50553001"
      },
      {
        "name": "Canned or jarred non GMO yellow dragonfruit",
        "code": "50553002"
      },
      { "name": "Canned or jarred non GMO figs", "code": "50553100" },
      { "name": "Canned or jarred non GMO bardajic figs", "code": "50553101" },
      {
        "name": "Canned or jarred non GMO brown turkey figs",
        "code": "50553102"
      },
      { "name": "Canned or jarred non GMO calimyrna figs", "code": "50553103" },
      { "name": "Canned or jarred non GMO conadria figs", "code": "50553104" },
      { "name": "Canned or jarred non GMO dottado figs", "code": "50553105" },
      { "name": "Canned or jarred non GMO kadota figs", "code": "50553106" },
      {
        "name": "Canned or jarred non GMO mediterranean figs",
        "code": "50553107"
      },
      { "name": "Canned or jarred non GMO mission figs", "code": "50553108" },
      { "name": "Canned or jarred non GMO smyrna figs", "code": "50553109" },
      { "name": "Canned or jarred non GMO verdona figs", "code": "50553110" },
      {
        "name": "Canned or jarred non GMO white king figs",
        "code": "50553111"
      },
      { "name": "Canned or jarred non GMO gooseberries", "code": "50553200" },
      {
        "name": "Canned or jarred non GMO early sulphur gooseberries",
        "code": "50553201"
      },
      {
        "name": "Canned or jarred non GMO goldendrop gooseberries",
        "code": "50553202"
      },
      {
        "name": "Canned or jarred non GMO langley gage gooseberries",
        "code": "50553203"
      },
      {
        "name": "Canned or jarred non GMO leveller gooseberries",
        "code": "50553204"
      },
      {
        "name": "Canned or jarred non GMO london gooseberries",
        "code": "50553205"
      },
      {
        "name": "Canned or jarred non GMO worcestershire gooseberries",
        "code": "50553206"
      },
      {
        "name": "Canned or jarred non GMO american worcesterberry gooseberries",
        "code": "50553207"
      },
      { "name": "Canned or jarred non GMO grapefruits", "code": "50553300" },
      {
        "name": "Canned or jarred non GMO burgundy grapefruit",
        "code": "50553301"
      },
      {
        "name": "Canned or jarred non GMO duncan grapefruit",
        "code": "50553302"
      },
      {
        "name": "Canned or jarred non GMO foster grapefruit",
        "code": "50553303"
      },
      {
        "name": "Canned or jarred non GMO marsh grapefruit",
        "code": "50553304"
      },
      {
        "name": "Canned or jarred non GMO new zealand grapefruit",
        "code": "50553305"
      },
      {
        "name": "Canned or jarred non GMO rio red grapefruit",
        "code": "50553306"
      },
      {
        "name": "Canned or jarred non GMO ruby red grapefruit",
        "code": "50553307"
      },
      {
        "name": "Canned or jarred non GMO star ruby grapefruit",
        "code": "50553308"
      },
      {
        "name": "Canned or jarred non GMO triumph grapefruit",
        "code": "50553309"
      },
      { "name": "Canned or jarred non GMO table grapes", "code": "50553400" },
      {
        "name": "Canned or jarred non GMO alicante grapes",
        "code": "50553401"
      },
      { "name": "Canned or jarred non GMO almeria grapes", "code": "50553402" },
      {
        "name": "Canned or jarred non GMO alphonse lavalle grapes",
        "code": "50553403"
      },
      {
        "name": "Canned or jarred non GMO autumn king grapes",
        "code": "50553404"
      },
      {
        "name": "Canned or jarred non GMO autumn royal grapes",
        "code": "50553405"
      },
      {
        "name": "Canned or jarred non GMO autumn seedless grapes",
        "code": "50553406"
      },
      {
        "name": "Canned or jarred non GMO baresana grapes",
        "code": "50553407"
      },
      {
        "name": "Canned or jarred non GMO barlinka grapes",
        "code": "50553408"
      },
      {
        "name": "Canned or jarred non GMO beauty seedless grapes",
        "code": "50553409"
      },
      {
        "name": "Canned or jarred non GMO black beauty seedless grapes",
        "code": "50553410"
      },
      {
        "name": "Canned or jarred non GMO black emerald grapes",
        "code": "50553411"
      },
      {
        "name": "Canned or jarred non GMO black giant grapes",
        "code": "50553412"
      },
      {
        "name": "Canned or jarred non GMO black globe grapes",
        "code": "50553413"
      },
      {
        "name": "Canned or jarred non GMO black monukka grapes",
        "code": "50553414"
      },
      {
        "name": "Canned or jarred non GMO black pearl grapes",
        "code": "50553415"
      },
      {
        "name": "Canned or jarred non GMO black seedless grapes",
        "code": "50553416"
      },
      { "name": "Canned or jarred non GMO bonheur grapes", "code": "50553417" },
      {
        "name": "Canned or jarred non GMO calmeria grapes",
        "code": "50553418"
      },
      {
        "name": "Canned or jarred non GMO cardinal grapes",
        "code": "50553419"
      },
      { "name": "Canned or jarred non GMO catawba grapes", "code": "50553420" },
      {
        "name": "Canned or jarred non GMO chasselas/golden chasselas grapes",
        "code": "50553421"
      },
      {
        "name": "Canned or jarred non GMO christmas rose grapes",
        "code": "50553422"
      },
      { "name": "Canned or jarred non GMO concord grapes", "code": "50553423" },
      {
        "name": "Canned or jarred non GMO concord seedless grapes",
        "code": "50553424"
      },
      {
        "name": "Canned or jarred non GMO crimson seedless grapes",
        "code": "50553425"
      },
      {
        "name": "Canned or jarred non GMO dauphine grapes",
        "code": "50553426"
      },
      {
        "name": "Canned or jarred non GMO delaware grapes",
        "code": "50553427"
      },
      {
        "name": "Canned or jarred non GMO early muscat grapes",
        "code": "50553428"
      },
      {
        "name": "Canned or jarred non GMO early sweet grapes",
        "code": "50553429"
      },
      {
        "name": "Canned or jarred non GMO emerald seedless grapes",
        "code": "50553430"
      },
      {
        "name": "Canned or jarred non GMO emperatriz grapes",
        "code": "50553431"
      },
      { "name": "Canned or jarred non GMO emperor grapes", "code": "50553432" },
      { "name": "Canned or jarred non GMO empress grapes", "code": "50553433" },
      { "name": "Canned or jarred non GMO exotic grapes", "code": "50553434" },
      { "name": "Canned or jarred non GMO fantasy grapes", "code": "50553435" },
      {
        "name": "Canned or jarred non GMO fantasy seedless grapes",
        "code": "50553436"
      },
      { "name": "Canned or jarred non GMO flame grapes", "code": "50553437" },
      {
        "name": "Canned or jarred non GMO flame seedless grapes",
        "code": "50553438"
      },
      {
        "name": "Canned or jarred non GMO flame tokay grapes",
        "code": "50553439"
      },
      {
        "name": "Canned or jarred non GMO flaming red grapes",
        "code": "50553440"
      },
      {
        "name": "Canned or jarred non GMO galaxy seedless grapes",
        "code": "50553441"
      },
      { "name": "Canned or jarred non GMO gamay grapes", "code": "50553442" },
      { "name": "Canned or jarred non GMO gold grapes", "code": "50553443" },
      {
        "name": "Canned or jarred non GMO hanepoot or honeypot grapes",
        "code": "50553444"
      },
      { "name": "Canned or jarred non GMO italia grapes", "code": "50553445" },
      {
        "name": "Canned or jarred non GMO jade seedless grapes",
        "code": "50553446"
      },
      { "name": "Canned or jarred non GMO jubilee grapes", "code": "50553447" },
      {
        "name": "Canned or jarred non GMO king ruby grapes",
        "code": "50553448"
      },
      { "name": "Canned or jarred non GMO kyoho grapes", "code": "50553449" },
      {
        "name": "Canned or jarred non GMO la rochelle grapes",
        "code": "50553450"
      },
      {
        "name": "Canned or jarred non GMO lady finger grapes",
        "code": "50553451"
      },
      {
        "name": "Canned or jarred non GMO late seedless grapes",
        "code": "50553452"
      },
      {
        "name": "Canned or jarred non GMO majestic seedless grapes",
        "code": "50553453"
      },
      { "name": "Canned or jarred non GMO malaga grapes", "code": "50553454" },
      {
        "name": "Canned or jarred non GMO marroo seedless grapes",
        "code": "50553455"
      },
      {
        "name": "Canned or jarred non GMO muscadine grapes",
        "code": "50553456"
      },
      {
        "name": "Canned or jarred non GMO muscat flame grapes",
        "code": "50553457"
      },
      { "name": "Canned or jarred non GMO muscat grapes", "code": "50553458" },
      {
        "name": "Canned or jarred non GMO muscat seedless grapes",
        "code": "50553459"
      },
      {
        "name": "Canned or jarred non GMO napoleon grapes",
        "code": "50553460"
      },
      { "name": "Canned or jarred non GMO negria grapes", "code": "50553461" },
      {
        "name": "Canned or jarred non GMO new cross grapes",
        "code": "50553462"
      },
      { "name": "Canned or jarred non GMO niabell grapes", "code": "50553463" },
      { "name": "Canned or jarred non GMO niagara grapes", "code": "50553464" },
      {
        "name": "Canned or jarred non GMO olivette grapes",
        "code": "50553465"
      },
      {
        "name": "Canned or jarred non GMO perlette grapes",
        "code": "50553466"
      },
      { "name": "Canned or jarred non GMO perlon grapes", "code": "50553467" },
      {
        "name": "Canned or jarred non GMO prima black seedless grapes",
        "code": "50553468"
      },
      {
        "name": "Canned or jarred non GMO princess grapes",
        "code": "50553469"
      },
      { "name": "Canned or jarred non GMO queen grapes", "code": "50553470" },
      {
        "name": "Canned or jarred non GMO red blush grapes",
        "code": "50553471"
      },
      {
        "name": "Canned or jarred non GMO red globe grapes",
        "code": "50553472"
      },
      {
        "name": "Canned or jarred non GMO red malaga grapes",
        "code": "50553473"
      },
      {
        "name": "Canned or jarred non GMO red seedless grapes",
        "code": "50553474"
      },
      { "name": "Canned or jarred non GMO regina grapes", "code": "50553475" },
      { "name": "Canned or jarred non GMO ribier grapes", "code": "50553476" },
      { "name": "Canned or jarred non GMO rosita grapes", "code": "50553477" },
      { "name": "Canned or jarred non GMO rouge grapes", "code": "50553478" },
      {
        "name": "Canned or jarred non GMO royal black seedless grapes",
        "code": "50553479"
      },
      {
        "name": "Canned or jarred non GMO ruby red seedless grapes",
        "code": "50553480"
      },
      {
        "name": "Canned or jarred non GMO ruby seedless grapes",
        "code": "50553481"
      },
      {
        "name": "Canned or jarred non GMO scarlet royal grapes",
        "code": "50553482"
      },
      {
        "name": "Canned or jarred non GMO scuppernong grapes",
        "code": "50553483"
      },
      {
        "name": "Canned or jarred non GMO sugarose grapes",
        "code": "50553484"
      },
      {
        "name": "Canned or jarred non GMO sugarthirteen grapes",
        "code": "50553485"
      },
      {
        "name": "Canned or jarred non GMO sugraone grapes",
        "code": "50553486"
      },
      {
        "name": "Canned or jarred non GMO sugrasixteen grapes",
        "code": "50553487"
      },
      {
        "name": "Canned or jarred non GMO sultana sun red grapes",
        "code": "50553488"
      },
      {
        "name": "Canned or jarred non GMO summer royal grapes",
        "code": "50553489"
      },
      { "name": "Canned or jarred non GMO sunset grapes", "code": "50553490" },
      {
        "name": "Canned or jarred non GMO superior seedless grapes",
        "code": "50553491"
      },
      {
        "name": "Canned or jarred non GMO thompson seedless grapes",
        "code": "50553492"
      },
      {
        "name": "Canned or jarred non GMO tokay/pinot gris grapes",
        "code": "50553493"
      },
      {
        "name": "Canned or jarred non GMO waltman cross grapes",
        "code": "50553494"
      },
      {
        "name": "Canned or jarred non GMO white seedless grapes",
        "code": "50553495"
      },
      {
        "name": "Canned or jarred non GMO zante current grapes",
        "code": "50553496"
      },
      { "name": "Canned or jarred non GMO raisin grapes", "code": "50553500" },
      {
        "name": "Canned or jarred non GMO black corinth grapes",
        "code": "50553501"
      },
      { "name": "Canned or jarred non GMO canner grapes", "code": "50553502" },
      { "name": "Canned or jarred non GMO dovine grapes", "code": "50553503" },
      { "name": "Canned or jarred non GMO fiesta grapes", "code": "50553504" },
      {
        "name": "Canned or jarred non GMO selma pete grapes",
        "code": "50553505"
      },
      { "name": "Canned or jarred non GMO sultana grapes", "code": "50553506" },
      { "name": "Canned or jarred non GMO wine grapes", "code": "50553600" },
      {
        "name": "Canned or jarred non GMO alicante bouschet grapes",
        "code": "50553601"
      },
      { "name": "Canned or jarred non GMO barbera grapes", "code": "50553602" },
      { "name": "Canned or jarred non GMO burger grapes", "code": "50553603" },
      {
        "name": "Canned or jarred non GMO cabernet franc grapes",
        "code": "50553604"
      },
      {
        "name": "Canned or jarred non GMO cabernet sauvignon grapes",
        "code": "50553605"
      },
      {
        "name": "Canned or jarred non GMO carignane grapes",
        "code": "50553606"
      },
      {
        "name": "Canned or jarred non GMO carnelian grapes",
        "code": "50553607"
      },
      {
        "name": "Canned or jarred non GMO catarratto grapes",
        "code": "50553608"
      },
      {
        "name": "Canned or jarred non GMO centurian grapes",
        "code": "50553609"
      },
      {
        "name": "Canned or jarred non GMO charbono grapes",
        "code": "50553610"
      },
      {
        "name": "Canned or jarred non GMO chardonnay grapes",
        "code": "50553611"
      },
      {
        "name": "Canned or jarred non GMO chenin blanc grapes",
        "code": "50553612"
      },
      { "name": "Canned or jarred non GMO cinsaut grapes", "code": "50553613" },
      {
        "name": "Canned or jarred non GMO dolcetto grapes",
        "code": "50553614"
      },
      {
        "name": "Canned or jarred non GMO emerald riesling grapes",
        "code": "50553615"
      },
      {
        "name": "Canned or jarred non GMO french colombard grapes",
        "code": "50553616"
      },
      {
        "name": "Canned or jarred non GMO gamay napa grapes",
        "code": "50553617"
      },
      {
        "name": "Canned or jarred non GMO gamay beaujolais grapes",
        "code": "50553618"
      },
      {
        "name": "Canned or jarred non GMO gewurztraminer grapes",
        "code": "50553619"
      },
      {
        "name": "Canned or jarred non GMO grenache grapes",
        "code": "50553620"
      },
      {
        "name": "Canned or jarred non GMO grenache blanc grapes",
        "code": "50553621"
      },
      { "name": "Canned or jarred non GMO lagrein grapes", "code": "50553622" },
      {
        "name": "Canned or jarred non GMO lambrusco grapes",
        "code": "50553623"
      },
      { "name": "Canned or jarred non GMO malbec grapes", "code": "50553624" },
      {
        "name": "Canned or jarred non GMO malvasia bianca grapes",
        "code": "50553625"
      },
      {
        "name": "Canned or jarred non GMO marsanne grapes",
        "code": "50553626"
      },
      { "name": "Canned or jarred non GMO mataro grapes", "code": "50553627" },
      { "name": "Canned or jarred non GMO merlot grapes", "code": "50553628" },
      { "name": "Canned or jarred non GMO meunier grapes", "code": "50553629" },
      { "name": "Canned or jarred non GMO mission grapes", "code": "50553630" },
      {
        "name": "Canned or jarred non GMO montepulciano grapes",
        "code": "50553631"
      },
      {
        "name": "Canned or jarred non GMO muscat blanc grapes",
        "code": "50553632"
      },
      {
        "name": "Canned or jarred non GMO muscat hamburg grapes",
        "code": "50553633"
      },
      {
        "name": "Canned or jarred non GMO muscat of alexandria grapes",
        "code": "50553634"
      },
      {
        "name": "Canned or jarred non GMO muscat orange grapes",
        "code": "50553635"
      },
      {
        "name": "Canned or jarred non GMO nebbiolo grapes",
        "code": "50553636"
      },
      {
        "name": "Canned or jarred non GMO palomino grapes",
        "code": "50553637"
      },
      {
        "name": "Canned or jarred non GMO petit verdot grapes",
        "code": "50553638"
      },
      {
        "name": "Canned or jarred non GMO petite sirah grapes",
        "code": "50553639"
      },
      {
        "name": "Canned or jarred non GMO pinot blanc grapes",
        "code": "50553640"
      },
      {
        "name": "Canned or jarred non GMO pinot gris grapes",
        "code": "50553641"
      },
      {
        "name": "Canned or jarred non GMO pinot noir grapes",
        "code": "50553642"
      },
      {
        "name": "Canned or jarred non GMO primitivo grapes",
        "code": "50553643"
      },
      {
        "name": "Canned or jarred non GMO roussanne grapes",
        "code": "50553644"
      },
      { "name": "Canned or jarred non GMO royalty grapes", "code": "50553645" },
      { "name": "Canned or jarred non GMO rubired grapes", "code": "50553646" },
      {
        "name": "Canned or jarred non GMO ruby cabernet grapes",
        "code": "50553647"
      },
      {
        "name": "Canned or jarred non GMO salvador grapes",
        "code": "50553648"
      },
      {
        "name": "Canned or jarred non GMO sangiovese grapes",
        "code": "50553649"
      },
      {
        "name": "Canned or jarred non GMO sauvignon blanc grapes",
        "code": "50553650"
      },
      {
        "name": "Canned or jarred non GMO sauvignon musque grapes",
        "code": "50553651"
      },
      {
        "name": "Canned or jarred non GMO semillon grapes",
        "code": "50553652"
      },
      { "name": "Canned or jarred non GMO souzao grapes", "code": "50553653" },
      {
        "name": "Canned or jarred non GMO st emilion grapes",
        "code": "50553654"
      },
      {
        "name": "Canned or jarred non GMO symphony grapes",
        "code": "50553655"
      },
      { "name": "Canned or jarred non GMO syrah grapes", "code": "50553656" },
      { "name": "Canned or jarred non GMO tannat grapes", "code": "50553657" },
      {
        "name": "Canned or jarred non GMO tempranillo grapes",
        "code": "50553658"
      },
      {
        "name": "Canned or jarred non GMO teroldego grapes",
        "code": "50553659"
      },
      {
        "name": "Canned or jarred non GMO tocai friulano grapes",
        "code": "50553660"
      },
      {
        "name": "Canned or jarred non GMO touriga nacional grapes",
        "code": "50553661"
      },
      {
        "name": "Canned or jarred non GMO triplett blanc grapes",
        "code": "50553662"
      },
      {
        "name": "Canned or jarred non GMO viognier grapes",
        "code": "50553663"
      },
      {
        "name": "Canned or jarred non GMO white riesling grapes",
        "code": "50553664"
      },
      {
        "name": "Canned or jarred non GMO zinfandel grapes",
        "code": "50553665"
      },
      { "name": "Canned or jarred non GMO guavas", "code": "50553700" },
      {
        "name": "Canned or jarred non GMO beaumont guavas",
        "code": "50553701"
      },
      { "name": "Canned or jarred non GMO carrley guavas", "code": "50553702" },
      { "name": "Canned or jarred non GMO lucida guavas", "code": "50553703" },
      {
        "name": "Canned or jarred non GMO pineapple guava",
        "code": "50553704"
      },
      { "name": "Canned or jarred non GMO huckleberries", "code": "50553800" },
      {
        "name": "Canned or jarred non GMO black winter huckleberries",
        "code": "50553801"
      },
      {
        "name": "Canned or jarred non GMO cascade huckleberries",
        "code": "50553802"
      },
      {
        "name": "Canned or jarred non GMO dwarf huckleberries",
        "code": "50553803"
      },
      {
        "name": "Canned or jarred non GMO mountain huckleberries",
        "code": "50553804"
      },
      {
        "name": "Canned or jarred non GMO red huckleberries",
        "code": "50553805"
      },
      { "name": "Canned or jarred non GMO kiwi fruits", "code": "50553900" },
      {
        "name": "Canned or jarred non GMO ananasnaja kiwi fruit",
        "code": "50553901"
      },
      {
        "name": "Canned or jarred non GMO arctic beauty kiwi fruit",
        "code": "50553902"
      },
      {
        "name": "Canned or jarred non GMO blake kiwi fruit",
        "code": "50553903"
      },
      {
        "name": "Canned or jarred non GMO hayward kiwi fruit",
        "code": "50553904"
      },
      {
        "name": "Canned or jarred non GMO issai kiwi fruit",
        "code": "50553905"
      },
      {
        "name": "Canned or jarred non GMO siberian kiwi fruit",
        "code": "50553906"
      },
      { "name": "Canned or jarred non GMO kumquats", "code": "50554000" },
      {
        "name": "Canned or jarred non GMO hong kong kumquats",
        "code": "50554001"
      },
      {
        "name": "Canned or jarred non GMO limequat kumquats",
        "code": "50554002"
      },
      {
        "name": "Canned or jarred non GMO long fruit kumquats",
        "code": "50554003"
      },
      {
        "name": "Canned or jarred non GMO malayan kumquats",
        "code": "50554004"
      },
      { "name": "Canned or jarred non GMO meiwa kumquats", "code": "50554005" },
      {
        "name": "Canned or jarred non GMO nagami kumquats",
        "code": "50554006"
      },
      { "name": "Canned or jarred non GMO lemons", "code": "50554100" },
      { "name": "Canned or jarred non GMO baboon lemons", "code": "50554101" },
      {
        "name": "Canned or jarred non GMO bearss sicilian lemons",
        "code": "50554102"
      },
      {
        "name": "Canned or jarred non GMO cameron highlands lemons",
        "code": "50554103"
      },
      {
        "name": "Canned or jarred non GMO escondido lemons",
        "code": "50554104"
      },
      { "name": "Canned or jarred non GMO eureka lemons", "code": "50554105" },
      { "name": "Canned or jarred non GMO lisbon lemons", "code": "50554106" },
      { "name": "Canned or jarred non GMO meyer lemons", "code": "50554107" },
      {
        "name": "Canned or jarred non GMO volkamer lemons",
        "code": "50554108"
      },
      { "name": "Canned or jarred non GMO limes", "code": "50554200" },
      {
        "name": "Canned or jarred non GMO indian sweet limes",
        "code": "50554201"
      },
      { "name": "Canned or jarred non GMO key limes", "code": "50554202" },
      { "name": "Canned or jarred non GMO mandarin limes", "code": "50554203" },
      {
        "name": "Canned or jarred non GMO philippine limes",
        "code": "50554204"
      },
      { "name": "Canned or jarred non GMO tahitian limes", "code": "50554205" },
      { "name": "Canned or jarred non GMO bearss limes", "code": "50554206" },
      { "name": "Canned or jarred non GMO persian limes", "code": "50554207" },
      { "name": "Canned or jarred non GMO seedless limes", "code": "50554208" },
      { "name": "Canned or jarred non GMO loquats", "code": "50554300" },
      {
        "name": "Canned or jarred non GMO advance loquats",
        "code": "50554301"
      },
      {
        "name": "Canned or jarred non GMO benlehr loquats",
        "code": "50554302"
      },
      {
        "name": "Canned or jarred non GMO big jim loquats",
        "code": "50554303"
      },
      {
        "name": "Canned or jarred non GMO champagne loquats",
        "code": "50554304"
      },
      {
        "name": "Canned or jarred non GMO early red loquats",
        "code": "50554305"
      },
      {
        "name": "Canned or jarred non GMO gold nugget loquats",
        "code": "50554306"
      },
      {
        "name": "Canned or jarred non GMO herd's mammoth loquats",
        "code": "50554307"
      },
      { "name": "Canned or jarred non GMO mogi loquats", "code": "50554308" },
      {
        "name": "Canned or jarred non GMO mrs cooksey loquats",
        "code": "50554309"
      },
      {
        "name": "Canned or jarred non GMO strawberry loquats",
        "code": "50554310"
      },
      { "name": "Canned or jarred non GMO tanaka loquats", "code": "50554311" },
      {
        "name": "Canned or jarred non GMO victory vista white loquats",
        "code": "50554312"
      },
      { "name": "Canned or jarred non GMO wolfe loquats", "code": "50554313" },
      {
        "name": "Canned or jarred non GMO mandarin oranges or tangerines",
        "code": "50554400"
      },
      {
        "name": "Canned or jarred non GMO clauselinas oranges",
        "code": "50554401"
      },
      {
        "name": "Canned or jarred non GMO clementine tangerines",
        "code": "50554402"
      },
      {
        "name": "Canned or jarred non GMO cleopatra mandarin oranges",
        "code": "50554403"
      },
      {
        "name": "Canned or jarred non GMO dancy tangerines",
        "code": "50554404"
      },
      {
        "name": "Canned or jarred non GMO ellensdale oranges",
        "code": "50554405"
      },
      {
        "name": "Canned or jarred non GMO fairchild oranges",
        "code": "50554406"
      },
      {
        "name": "Canned or jarred non GMO fallglo oranges",
        "code": "50554407"
      },
      {
        "name": "Canned or jarred non GMO fortune oranges",
        "code": "50554408"
      },
      {
        "name": "Canned or jarred non GMO fremont mandarin oranges",
        "code": "50554409"
      },
      {
        "name": "Canned or jarred non GMO fremont oranges",
        "code": "50554410"
      },
      {
        "name": "Canned or jarred non GMO golden nugget oranges",
        "code": "50554411"
      },
      {
        "name": "Canned or jarred non GMO honey mandarin oranges",
        "code": "50554412"
      },
      { "name": "Canned or jarred non GMO honey oranges", "code": "50554413" },
      {
        "name": "Canned or jarred non GMO honey tangerines",
        "code": "50554414"
      },
      {
        "name": "Canned or jarred non GMO honeybelle tangelo oranges",
        "code": "50554415"
      },
      {
        "name": "Canned or jarred non GMO king mandarin oranges",
        "code": "50554416"
      },
      { "name": "Canned or jarred non GMO kinnow oranges", "code": "50554417" },
      {
        "name": "Canned or jarred non GMO lee mandarin oranges",
        "code": "50554418"
      },
      {
        "name": "Canned or jarred non GMO makokkee oranges",
        "code": "50554419"
      },
      {
        "name": "Canned or jarred non GMO malvasios oranges",
        "code": "50554420"
      },
      {
        "name": "Canned or jarred non GMO mediterranean mandarin oranges",
        "code": "50554421"
      },
      {
        "name": "Canned or jarred non GMO minneola tangelo oranges",
        "code": "50554422"
      },
      { "name": "Canned or jarred non GMO monica oranges", "code": "50554423" },
      {
        "name": "Canned or jarred non GMO murcott honey oranges",
        "code": "50554424"
      },
      {
        "name": "Canned or jarred non GMO murcott tangors",
        "code": "50554425"
      },
      {
        "name": "Canned or jarred non GMO natsudaidai mandarin oranges",
        "code": "50554426"
      },
      {
        "name": "Canned or jarred non GMO natsumikan mandarin oranges",
        "code": "50554427"
      },
      {
        "name": "Canned or jarred non GMO nocatee tangelo oranges",
        "code": "50554428"
      },
      {
        "name": "Canned or jarred non GMO orlando tangelo oranges",
        "code": "50554429"
      },
      {
        "name": "Canned or jarred non GMO ortanique tangerines",
        "code": "50554430"
      },
      {
        "name": "Canned or jarred non GMO page mandarin oranges",
        "code": "50554431"
      },
      { "name": "Canned or jarred non GMO pixie oranges", "code": "50554432" },
      {
        "name": "Canned or jarred non GMO ponkan bantangas mandarin oranges",
        "code": "50554433"
      },
      { "name": "Canned or jarred non GMO reyna oranges", "code": "50554434" },
      {
        "name": "Canned or jarred non GMO robinson oranges",
        "code": "50554435"
      },
      {
        "name": "Canned or jarred non GMO saltenitas oranges",
        "code": "50554436"
      },
      {
        "name": "Canned or jarred non GMO sampson tangelo oranges",
        "code": "50554437"
      },
      {
        "name": "Canned or jarred non GMO satsuma mandarin oranges",
        "code": "50554438"
      },
      {
        "name": "Canned or jarred non GMO sunburst mandarin oranges",
        "code": "50554439"
      },
      { "name": "Canned or jarred non GMO tangelos", "code": "50554440" },
      {
        "name": "Canned or jarred non GMO tangerina oranges",
        "code": "50554441"
      },
      { "name": "Canned or jarred non GMO temple oranges", "code": "50554442" },
      {
        "name": "Canned or jarred non GMO thornton oranges",
        "code": "50554443"
      },
      {
        "name": "Canned or jarred non GMO wekiwa tangerines",
        "code": "50554444"
      },
      {
        "name": "Canned or jarred non GMO wilkins tangerines",
        "code": "50554445"
      },
      {
        "name": "Canned or jarred non GMO willowleaf mediterranean tangerines",
        "code": "50554446"
      },
      { "name": "Canned or jarred non GMO mangoes", "code": "50554500" },
      {
        "name": "Canned or jarred non GMO alphonso mangoes",
        "code": "50554501"
      },
      {
        "name": "Canned or jarred non GMO ataulfo mangoes",
        "code": "50554502"
      },
      {
        "name": "Canned or jarred non GMO criollo mangoes",
        "code": "50554503"
      },
      {
        "name": "Canned or jarred non GMO edwards mangoes",
        "code": "50554504"
      },
      {
        "name": "Canned or jarred non GMO francine mangoes",
        "code": "50554505"
      },
      {
        "name": "Canned or jarred non GMO francis mangoes",
        "code": "50554506"
      },
      {
        "name": "Canned or jarred non GMO gandaria mangoes",
        "code": "50554507"
      },
      { "name": "Canned or jarred non GMO haden mangoes", "code": "50554508" },
      { "name": "Canned or jarred non GMO irwin mangoes", "code": "50554509" },
      { "name": "Canned or jarred non GMO keitt mangoes", "code": "50554510" },
      { "name": "Canned or jarred non GMO kent mangoes", "code": "50554511" },
      { "name": "Canned or jarred non GMO kesar mangoes", "code": "50554512" },
      { "name": "Canned or jarred non GMO kuini mangoes", "code": "50554513" },
      {
        "name": "Canned or jarred non GMO manila super mangoes",
        "code": "50554514"
      },
      { "name": "Canned or jarred non GMO manila mangoes", "code": "50554515" },
      {
        "name": "Canned or jarred non GMO mayaguez mangoes",
        "code": "50554516"
      },
      {
        "name": "Canned or jarred non GMO mulgoba mangoes",
        "code": "50554517"
      },
      { "name": "Canned or jarred non GMO oro mangoes", "code": "50554518" },
      { "name": "Canned or jarred non GMO palmer mangoes", "code": "50554519" },
      { "name": "Canned or jarred non GMO parvin mangoes", "code": "50554520" },
      {
        "name": "Canned or jarred non GMO sandersha mangoes",
        "code": "50554521"
      },
      {
        "name": "Canned or jarred non GMO sensation mangoes",
        "code": "50554522"
      },
      { "name": "Canned or jarred non GMO smith mangoes", "code": "50554523" },
      {
        "name": "Canned or jarred non GMO tommy atkins mangoes",
        "code": "50554524"
      },
      {
        "name": "Canned or jarred non GMO van dyke mangoes",
        "code": "50554525"
      },
      { "name": "Canned or jarred non GMO melons", "code": "50554600" },
      {
        "name": "Canned or jarred non GMO allsweet melons",
        "code": "50554601"
      },
      { "name": "Canned or jarred non GMO athena melons", "code": "50554602" },
      {
        "name": "Canned or jarred non GMO black diamond melons",
        "code": "50554603"
      },
      {
        "name": "Canned or jarred non GMO cal sweet melons",
        "code": "50554604"
      },
      {
        "name": "Canned or jarred non GMO carnical melons",
        "code": "50554605"
      },
      {
        "name": "Canned or jarred non GMO cantaloupe melons",
        "code": "50554606"
      },
      { "name": "Canned or jarred non GMO casaba melons", "code": "50554607" },
      {
        "name": "Canned or jarred non GMO cavaillon melons",
        "code": "50554608"
      },
      {
        "name": "Canned or jarred non GMO charentais melons",
        "code": "50554609"
      },
      {
        "name": "Canned or jarred non GMO charleston gray watermelon",
        "code": "50554610"
      },
      {
        "name": "Canned or jarred non GMO crenshaw melons",
        "code": "50554611"
      },
      {
        "name": "Canned or jarred non GMO crimson sweet melons",
        "code": "50554612"
      },
      {
        "name": "Canned or jarred non GMO dixie lee melons",
        "code": "50554613"
      },
      { "name": "Canned or jarred non GMO eclipse melons", "code": "50554614" },
      {
        "name": "Canned or jarred non GMO ein d'or melons",
        "code": "50554615"
      },
      { "name": "Canned or jarred non GMO fiesta melons", "code": "50554616" },
      { "name": "Canned or jarred non GMO galia melons", "code": "50554617" },
      { "name": "Canned or jarred non GMO gaya melons", "code": "50554618" },
      { "name": "Canned or jarred non GMO hami melons", "code": "50554619" },
      {
        "name": "Canned or jarred non GMO honeydew melons",
        "code": "50554620"
      },
      { "name": "Canned or jarred non GMO icebox melons", "code": "50554621" },
      {
        "name": "Canned or jarred non GMO ida pride melons",
        "code": "50554622"
      },
      {
        "name": "Canned or jarred non GMO juan canary melons",
        "code": "50554623"
      },
      { "name": "Canned or jarred non GMO jubilee melons", "code": "50554624" },
      {
        "name": "Canned or jarred non GMO jubilation melons",
        "code": "50554625"
      },
      {
        "name": "Canned or jarred non GMO kakhi/kakri melons",
        "code": "50554626"
      },
      { "name": "Canned or jarred non GMO kiwano melons", "code": "50554627" },
      { "name": "Canned or jarred non GMO korean melons", "code": "50554628" },
      {
        "name": "Canned or jarred non GMO long gray melons",
        "code": "50554629"
      },
      { "name": "Canned or jarred non GMO mayan melons", "code": "50554630" },
      {
        "name": "Canned or jarred non GMO micky lee melons",
        "code": "50554631"
      },
      { "name": "Canned or jarred non GMO mirage melons", "code": "50554632" },
      {
        "name": "Canned or jarred non GMO moon and stars watermelon",
        "code": "50554633"
      },
      { "name": "Canned or jarred non GMO ogen melons", "code": "50554634" },
      { "name": "Canned or jarred non GMO patriot melons", "code": "50554635" },
      { "name": "Canned or jarred non GMO peacock melons", "code": "50554636" },
      { "name": "Canned or jarred non GMO pepino melons", "code": "50554637" },
      { "name": "Canned or jarred non GMO persian melons", "code": "50554638" },
      { "name": "Canned or jarred non GMO picnic melons", "code": "50554639" },
      {
        "name": "Canned or jarred non GMO piel de sapo melons",
        "code": "50554640"
      },
      {
        "name": "Canned or jarred non GMO pineapple melons",
        "code": "50554641"
      },
      {
        "name": "Canned or jarred non GMO quetzali melons",
        "code": "50554642"
      },
      {
        "name": "Canned or jarred non GMO red goblin melons",
        "code": "50554643"
      },
      { "name": "Canned or jarred non GMO regency melons", "code": "50554644" },
      {
        "name": "Canned or jarred non GMO royal majestic melons",
        "code": "50554645"
      },
      {
        "name": "Canned or jarred non GMO royal star melons",
        "code": "50554646"
      },
      {
        "name": "Canned or jarred non GMO royal sweet melons",
        "code": "50554647"
      },
      {
        "name": "Canned or jarred non GMO santa claus melons",
        "code": "50554648"
      },
      { "name": "Canned or jarred non GMO sharlyn melons", "code": "50554649" },
      { "name": "Canned or jarred non GMO spanish melons", "code": "50554650" },
      { "name": "Canned or jarred non GMO sprite melons", "code": "50554651" },
      {
        "name": "Canned or jarred non GMO starbright melons",
        "code": "50554652"
      },
      {
        "name": "Canned or jarred non GMO stars n stripes melons",
        "code": "50554653"
      },
      {
        "name": "Canned or jarred non GMO sugar baby melons",
        "code": "50554654"
      },
      {
        "name": "Canned or jarred non GMO sugar baby watermelon",
        "code": "50554655"
      },
      {
        "name": "Canned or jarred non GMO sunsweet melons",
        "code": "50554656"
      },
      {
        "name": "Canned or jarred non GMO sweet heart seedless watermelon",
        "code": "50554657"
      },
      {
        "name": "Canned or jarred non GMO temptation melons",
        "code": "50554658"
      },
      {
        "name": "Canned or jarred non GMO tiger baby melons",
        "code": "50554659"
      },
      {
        "name": "Canned or jarred non GMO tuscan type melons",
        "code": "50554660"
      },
      {
        "name": "Canned or jarred non GMO yellow baby watermelon",
        "code": "50554661"
      },
      { "name": "Canned or jarred non GMO mulberries", "code": "50554700" },
      {
        "name": "Canned or jarred non GMO black mulberries",
        "code": "50554701"
      },
      {
        "name": "Canned or jarred non GMO white mulberries",
        "code": "50554702"
      },
      {
        "name": "Canned or jarred non GMO bayberries and myrtles",
        "code": "50554800"
      },
      { "name": "Canned or jarred non GMO bog myrtle", "code": "50554801" },
      { "name": "Canned or jarred non GMO bayberries", "code": "50554802" },
      { "name": "Canned or jarred non GMO nectarines", "code": "50554900" },
      {
        "name": "Canned or jarred non GMO april glo nectarines",
        "code": "50554901"
      },
      {
        "name": "Canned or jarred non GMO arctic mist nectarines",
        "code": "50554902"
      },
      {
        "name": "Canned or jarred non GMO arctic snow nectarines",
        "code": "50554903"
      },
      {
        "name": "Canned or jarred non GMO arctic star nectarines",
        "code": "50554904"
      },
      {
        "name": "Canned or jarred non GMO arctic sweet nectarines",
        "code": "50554905"
      },
      {
        "name": "Canned or jarred non GMO arctic glo nectarines",
        "code": "50554906"
      },
      {
        "name": "Canned or jarred non GMO august fire nectarines",
        "code": "50554907"
      },
      {
        "name": "Canned or jarred non GMO august pearl nectarines",
        "code": "50554908"
      },
      {
        "name": "Canned or jarred non GMO august red nectarines",
        "code": "50554909"
      },
      {
        "name": "Canned or jarred non GMO autumn star nectarines",
        "code": "50554910"
      },
      {
        "name": "Canned or jarred non GMO big john nectarines",
        "code": "50554911"
      },
      {
        "name": "Canned or jarred non GMO bright pearl nectarines",
        "code": "50554912"
      },
      {
        "name": "Canned or jarred non GMO diamond bright nectarines",
        "code": "50554913"
      },
      {
        "name": "Canned or jarred non GMO diamond ray nectarines",
        "code": "50554914"
      },
      {
        "name": "Canned or jarred non GMO earliglo nectarines",
        "code": "50554915"
      },
      {
        "name": "Canned or jarred non GMO early diamond nectarines",
        "code": "50554916"
      },
      {
        "name": "Canned or jarred non GMO fairlane nectarines",
        "code": "50554917"
      },
      {
        "name": "Canned or jarred non GMO fantasia nectarines",
        "code": "50554918"
      },
      {
        "name": "Canned or jarred non GMO fire pearl nectarines",
        "code": "50554919"
      },
      {
        "name": "Canned or jarred non GMO fire sweet nectarines",
        "code": "50554920"
      },
      {
        "name": "Canned or jarred non GMO flamekist nectarines",
        "code": "50554921"
      },
      {
        "name": "Canned or jarred non GMO flat type nectarines",
        "code": "50554922"
      },
      {
        "name": "Canned or jarred non GMO garden delight nectarines",
        "code": "50554923"
      },
      {
        "name": "Canned or jarred non GMO goldmine nectarines",
        "code": "50554924"
      },
      {
        "name": "Canned or jarred non GMO grand pearl nectarines",
        "code": "50554925"
      },
      {
        "name": "Canned or jarred non GMO hardired nectarines",
        "code": "50554926"
      },
      {
        "name": "Canned or jarred non GMO honey blaze nectarines",
        "code": "50554927"
      },
      {
        "name": "Canned or jarred non GMO july red nectarines",
        "code": "50554928"
      },
      {
        "name": "Canned or jarred non GMO kay pearl nectarines",
        "code": "50554929"
      },
      {
        "name": "Canned or jarred non GMO kay sweet nectarines",
        "code": "50554930"
      },
      {
        "name": "Canned or jarred non GMO may diamond nectarines",
        "code": "50554931"
      },
      {
        "name": "Canned or jarred non GMO mayfire nectarines",
        "code": "50554932"
      },
      {
        "name": "Canned or jarred non GMO mayglo nectarines",
        "code": "50554933"
      },
      {
        "name": "Canned or jarred non GMO mericrest nectarines",
        "code": "50554934"
      },
      {
        "name": "Canned or jarred non GMO red diamond nectarines",
        "code": "50554935"
      },
      {
        "name": "Canned or jarred non GMO red gold nectarines",
        "code": "50554936"
      },
      {
        "name": "Canned or jarred non GMO red jim nectarines",
        "code": "50554937"
      },
      {
        "name": "Canned or jarred non GMO red roy nectarines",
        "code": "50554938"
      },
      {
        "name": "Canned or jarred non GMO rio red nectarines",
        "code": "50554939"
      },
      {
        "name": "Canned or jarred non GMO rose diamond nectarines",
        "code": "50554940"
      },
      {
        "name": "Canned or jarred non GMO royal glo nectarines",
        "code": "50554941"
      },
      {
        "name": "Canned or jarred non GMO ruby diamond nectarines",
        "code": "50554942"
      },
      {
        "name": "Canned or jarred non GMO ruby sweet nectarines",
        "code": "50554943"
      },
      {
        "name": "Canned or jarred non GMO ruddy jewel nectarines",
        "code": "50554944"
      },
      {
        "name": "Canned or jarred non GMO september red nectarines",
        "code": "50554945"
      },
      {
        "name": "Canned or jarred non GMO snowqueen nectarines",
        "code": "50554946"
      },
      {
        "name": "Canned or jarred non GMO spring bright nectarines",
        "code": "50554947"
      },
      {
        "name": "Canned or jarred non GMO spring red nectarines",
        "code": "50554948"
      },
      {
        "name": "Canned or jarred non GMO summer blush nectarines",
        "code": "50554949"
      },
      {
        "name": "Canned or jarred non GMO summer brite nectarines",
        "code": "50554950"
      },
      {
        "name": "Canned or jarred non GMO summer diamond nectarines",
        "code": "50554951"
      },
      {
        "name": "Canned or jarred non GMO summer fire nectarines",
        "code": "50554952"
      },
      {
        "name": "Canned or jarred non GMO summer grand nectarines",
        "code": "50554953"
      },
      {
        "name": "Canned or jarred non GMO sunglo nectarines",
        "code": "50554954"
      },
      {
        "name": "Canned or jarred non GMO zee fire nectarines",
        "code": "50554955"
      },
      {
        "name": "Canned or jarred non GMO zee glo nectarines",
        "code": "50554956"
      },
      {
        "name": "Canned or jarred non GMO zeegrand nectarines",
        "code": "50554957"
      },
      { "name": "Canned or jarred non GMO oranges", "code": "50555000" },
      {
        "name": "Canned or jarred non GMO african sour oranges",
        "code": "50555001"
      },
      {
        "name": "Canned or jarred non GMO ambersweet oranges",
        "code": "50555002"
      },
      {
        "name": "Canned or jarred non GMO argentine sour oranges",
        "code": "50555003"
      },
      {
        "name": "Canned or jarred non GMO bahianinha oranges",
        "code": "50555004"
      },
      {
        "name": "Canned or jarred non GMO bergamot oranges",
        "code": "50555005"
      },
      { "name": "Canned or jarred non GMO berna oranges", "code": "50555006" },
      {
        "name": "Canned or jarred non GMO bigaradier apepu oranges",
        "code": "50555007"
      },
      {
        "name": "Canned or jarred non GMO bittersweet daidai oranges",
        "code": "50555008"
      },
      { "name": "Canned or jarred non GMO blonde oranges", "code": "50555009" },
      { "name": "Canned or jarred non GMO blood oranges", "code": "50555010" },
      {
        "name": "Canned or jarred non GMO california navel oranges",
        "code": "50555011"
      },
      {
        "name": "Canned or jarred non GMO cara cara oranges",
        "code": "50555012"
      },
      {
        "name": "Canned or jarred non GMO chinotto oranges",
        "code": "50555013"
      },
      {
        "name": "Canned or jarred non GMO dream navel oranges",
        "code": "50555014"
      },
      {
        "name": "Canned or jarred non GMO gou tou oranges",
        "code": "50555015"
      },
      { "name": "Canned or jarred non GMO hamlin oranges", "code": "50555016" },
      { "name": "Canned or jarred non GMO jaffa oranges", "code": "50555017" },
      {
        "name": "Canned or jarred non GMO jincheng oranges",
        "code": "50555018"
      },
      {
        "name": "Canned or jarred non GMO k-early oranges",
        "code": "50555019"
      },
      { "name": "Canned or jarred non GMO kona oranges", "code": "50555020" },
      {
        "name": "Canned or jarred non GMO late navel oranges",
        "code": "50555021"
      },
      {
        "name": "Canned or jarred non GMO late valencia oranges",
        "code": "50555022"
      },
      {
        "name": "Canned or jarred non GMO limequat oranges",
        "code": "50555023"
      },
      { "name": "Canned or jarred non GMO marr oranges", "code": "50555024" },
      {
        "name": "Canned or jarred non GMO melogold oranges",
        "code": "50555025"
      },
      { "name": "Canned or jarred non GMO moro oranges", "code": "50555026" },
      {
        "name": "Canned or jarred non GMO moro tarocco oranges",
        "code": "50555027"
      },
      { "name": "Canned or jarred non GMO navel oranges", "code": "50555028" },
      {
        "name": "Canned or jarred non GMO navelina oranges",
        "code": "50555029"
      },
      {
        "name": "Canned or jarred non GMO oro blanco oranges",
        "code": "50555030"
      },
      {
        "name": "Canned or jarred non GMO osceola oranges",
        "code": "50555031"
      },
      {
        "name": "Canned or jarred non GMO parson brown oranges",
        "code": "50555032"
      },
      { "name": "Canned or jarred non GMO pera oranges", "code": "50555033" },
      {
        "name": "Canned or jarred non GMO pummulo oranges",
        "code": "50555034"
      },
      {
        "name": "Canned or jarred non GMO rhode red oranges",
        "code": "50555035"
      },
      { "name": "Canned or jarred non GMO roble oranges", "code": "50555036" },
      {
        "name": "Canned or jarred non GMO salustianas oranges",
        "code": "50555037"
      },
      {
        "name": "Canned or jarred non GMO sanguine oranges",
        "code": "50555038"
      },
      {
        "name": "Canned or jarred non GMO sanguinelli oranges",
        "code": "50555039"
      },
      {
        "name": "Canned or jarred non GMO seville oranges",
        "code": "50555040"
      },
      {
        "name": "Canned or jarred non GMO shamouti jaffa oranges",
        "code": "50555041"
      },
      { "name": "Canned or jarred non GMO tunis oranges", "code": "50555042" },
      {
        "name": "Canned or jarred non GMO valencia oranges",
        "code": "50555043"
      },
      {
        "name": "Canned or jarred non GMO washington navel oranges",
        "code": "50555044"
      },
      { "name": "Canned or jarred non GMO papayas", "code": "50555100" },
      {
        "name": "Canned or jarred non GMO green cooking papayas",
        "code": "50555101"
      },
      {
        "name": "Canned or jarred non GMO maradol papayas",
        "code": "50555102"
      },
      {
        "name": "Canned or jarred non GMO mexican yellow papayas",
        "code": "50555103"
      },
      {
        "name": "Canned or jarred non GMO mountain papayas",
        "code": "50555104"
      },
      { "name": "Canned or jarred non GMO solo papayas", "code": "50555105" },
      {
        "name": "Canned or jarred non GMO tainung papayas",
        "code": "50555106"
      },
      { "name": "Canned or jarred non GMO passion fruits", "code": "50555200" },
      {
        "name": "Canned or jarred non GMO banana passion fruit",
        "code": "50555201"
      },
      {
        "name": "Canned or jarred non GMO blue passion flower",
        "code": "50555202"
      },
      {
        "name": "Canned or jarred non GMO crackerjack passion fruit",
        "code": "50555203"
      },
      {
        "name": "Canned or jarred non GMO giant granadilla passion fruit",
        "code": "50555204"
      },
      {
        "name": "Canned or jarred non GMO golden granadilla passion fruit",
        "code": "50555205"
      },
      {
        "name": "Canned or jarred non GMO maypops passion fruit",
        "code": "50555206"
      },
      {
        "name": "Canned or jarred non GMO red granadilla passion fruit",
        "code": "50555207"
      },
      {
        "name": "Canned or jarred non GMO sweet granadilla passion fruit",
        "code": "50555208"
      },
      {
        "name": "Canned or jarred non GMO water lemon passion fruit",
        "code": "50555209"
      },
      {
        "name": "Canned or jarred non GMO wing-stemmed passion flower",
        "code": "50555210"
      },
      { "name": "Canned or jarred non GMO peaches", "code": "50555300" },
      {
        "name": "Canned or jarred non GMO amber crest peaches",
        "code": "50555301"
      },
      {
        "name": "Canned or jarred non GMO april snow peaches",
        "code": "50555302"
      },
      {
        "name": "Canned or jarred non GMO august lady peaches",
        "code": "50555303"
      },
      {
        "name": "Canned or jarred non GMO autumn flame peaches",
        "code": "50555304"
      },
      {
        "name": "Canned or jarred non GMO autumn lady peaches",
        "code": "50555305"
      },
      {
        "name": "Canned or jarred non GMO babcock peaches",
        "code": "50555306"
      },
      {
        "name": "Canned or jarred non GMO brittney lane peaches",
        "code": "50555307"
      },
      {
        "name": "Canned or jarred non GMO cary mac peaches",
        "code": "50555308"
      },
      {
        "name": "Canned or jarred non GMO classic peaches",
        "code": "50555309"
      },
      {
        "name": "Canned or jarred non GMO country sweet peaches",
        "code": "50555310"
      },
      {
        "name": "Canned or jarred non GMO crest haven peaches",
        "code": "50555311"
      },
      {
        "name": "Canned or jarred non GMO crimson lady peaches",
        "code": "50555312"
      },
      {
        "name": "Canned or jarred non GMO crown princess peaches",
        "code": "50555313"
      },
      {
        "name": "Canned or jarred non GMO david sun peaches",
        "code": "50555314"
      },
      {
        "name": "Canned or jarred non GMO diamond princess peaches",
        "code": "50555315"
      },
      {
        "name": "Canned or jarred non GMO earlirich peaches",
        "code": "50555316"
      },
      {
        "name": "Canned or jarred non GMO early majestic peaches",
        "code": "50555317"
      },
      {
        "name": "Canned or jarred non GMO early treat peaches",
        "code": "50555318"
      },
      {
        "name": "Canned or jarred non GMO elegant lady peaches",
        "code": "50555319"
      },
      {
        "name": "Canned or jarred non GMO empress peaches",
        "code": "50555320"
      },
      { "name": "Canned or jarred non GMO encore peaches", "code": "50555321" },
      {
        "name": "Canned or jarred non GMO fancy lady peaches",
        "code": "50555322"
      },
      {
        "name": "Canned or jarred non GMO fire prince peaches",
        "code": "50555323"
      },
      {
        "name": "Canned or jarred non GMO flame crest peaches",
        "code": "50555324"
      },
      {
        "name": "Canned or jarred non GMO flat type peaches",
        "code": "50555325"
      },
      {
        "name": "Canned or jarred non GMO flavorcrest peaches",
        "code": "50555326"
      },
      {
        "name": "Canned or jarred non GMO florida prince peaches",
        "code": "50555327"
      },
      {
        "name": "Canned or jarred non GMO full moon peaches",
        "code": "50555328"
      },
      {
        "name": "Canned or jarred non GMO harvester peaches",
        "code": "50555329"
      },
      {
        "name": "Canned or jarred non GMO ice princess peaches",
        "code": "50555330"
      },
      {
        "name": "Canned or jarred non GMO ivory princess peaches",
        "code": "50555331"
      },
      {
        "name": "Canned or jarred non GMO jersey queen peaches",
        "code": "50555332"
      },
      {
        "name": "Canned or jarred non GMO john henry peaches",
        "code": "50555333"
      },
      {
        "name": "Canned or jarred non GMO june prince peaches",
        "code": "50555334"
      },
      { "name": "Canned or jarred non GMO kaweah peaches", "code": "50555335" },
      {
        "name": "Canned or jarred non GMO klondike peaches",
        "code": "50555336"
      },
      { "name": "Canned or jarred non GMO lindo peaches", "code": "50555337" },
      { "name": "Canned or jarred non GMO loring peaches", "code": "50555338" },
      {
        "name": "Canned or jarred non GMO majestic peaches",
        "code": "50555339"
      },
      {
        "name": "Canned or jarred non GMO o'henry peaches",
        "code": "50555340"
      },
      {
        "name": "Canned or jarred non GMO queencrest peaches",
        "code": "50555341"
      },
      {
        "name": "Canned or jarred non GMO red lady peaches",
        "code": "50555342"
      },
      {
        "name": "Canned or jarred non GMO redglobe peaches",
        "code": "50555343"
      },
      {
        "name": "Canned or jarred non GMO redhaven peaches",
        "code": "50555344"
      },
      { "name": "Canned or jarred non GMO redtop peaches", "code": "50555345" },
      { "name": "Canned or jarred non GMO regina peaches", "code": "50555346" },
      {
        "name": "Canned or jarred non GMO rich lady peaches",
        "code": "50555347"
      },
      {
        "name": "Canned or jarred non GMO rich may peaches",
        "code": "50555348"
      },
      {
        "name": "Canned or jarred non GMO royal glory peaches",
        "code": "50555349"
      },
      {
        "name": "Canned or jarred non GMO royal lady peaches",
        "code": "50555350"
      },
      {
        "name": "Canned or jarred non GMO september snow peaches",
        "code": "50555351"
      },
      {
        "name": "Canned or jarred non GMO september sun peaches",
        "code": "50555352"
      },
      {
        "name": "Canned or jarred non GMO sierra gem peaches",
        "code": "50555353"
      },
      {
        "name": "Canned or jarred non GMO snow angel peaches",
        "code": "50555354"
      },
      {
        "name": "Canned or jarred non GMO snow gem peaches",
        "code": "50555355"
      },
      {
        "name": "Canned or jarred non GMO snow king peaches",
        "code": "50555356"
      },
      {
        "name": "Canned or jarred non GMO spring lady peaches",
        "code": "50555357"
      },
      {
        "name": "Canned or jarred non GMO spring snow peaches",
        "code": "50555358"
      },
      {
        "name": "Canned or jarred non GMO springcrest peaches",
        "code": "50555359"
      },
      {
        "name": "Canned or jarred non GMO sugar giant peaches",
        "code": "50555360"
      },
      {
        "name": "Canned or jarred non GMO sugar lady peaches",
        "code": "50555361"
      },
      {
        "name": "Canned or jarred non GMO sun bright peaches",
        "code": "50555362"
      },
      {
        "name": "Canned or jarred non GMO sunhigh peaches",
        "code": "50555363"
      },
      {
        "name": "Canned or jarred non GMO super lady peaches",
        "code": "50555364"
      },
      {
        "name": "Canned or jarred non GMO super rich peaches",
        "code": "50555365"
      },
      {
        "name": "Canned or jarred non GMO surecrop peaches",
        "code": "50555366"
      },
      {
        "name": "Canned or jarred non GMO sweet dream peaches",
        "code": "50555367"
      },
      {
        "name": "Canned or jarred non GMO sweet september peaches",
        "code": "50555368"
      },
      { "name": "Canned or jarred non GMO vista peaches", "code": "50555369" },
      {
        "name": "Canned or jarred non GMO white lady peaches",
        "code": "50555370"
      },
      {
        "name": "Canned or jarred non GMO zee lady peaches",
        "code": "50555371"
      },
      { "name": "Canned or jarred non GMO pears", "code": "50555400" },
      {
        "name": "Canned or jarred non GMO abate fetel pears",
        "code": "50555401"
      },
      { "name": "Canned or jarred non GMO anjou pears", "code": "50555402" },
      { "name": "Canned or jarred non GMO asian pears", "code": "50555403" },
      { "name": "Canned or jarred non GMO bartlett pears", "code": "50555404" },
      {
        "name": "Canned or jarred non GMO best ever pears",
        "code": "50555405"
      },
      { "name": "Canned or jarred non GMO beth pears", "code": "50555406" },
      { "name": "Canned or jarred non GMO beurre pears", "code": "50555407" },
      { "name": "Canned or jarred non GMO bosc pears", "code": "50555408" },
      {
        "name": "Canned or jarred non GMO clapp favorite pears",
        "code": "50555409"
      },
      { "name": "Canned or jarred non GMO comice pears", "code": "50555410" },
      { "name": "Canned or jarred non GMO concorde pears", "code": "50555411" },
      {
        "name": "Canned or jarred non GMO conference pears",
        "code": "50555412"
      },
      {
        "name": "Canned or jarred non GMO crimson red pears",
        "code": "50555413"
      },
      { "name": "Canned or jarred non GMO d'anjou pears", "code": "50555414" },
      {
        "name": "Canned or jarred non GMO dr jules guyot pears",
        "code": "50555415"
      },
      { "name": "Canned or jarred non GMO early pears", "code": "50555416" },
      {
        "name": "Canned or jarred non GMO emperor brown pears",
        "code": "50555417"
      },
      { "name": "Canned or jarred non GMO forelle pears", "code": "50555418" },
      {
        "name": "Canned or jarred non GMO french butter pears",
        "code": "50555419"
      },
      {
        "name": "Canned or jarred non GMO glou morceau pears",
        "code": "50555420"
      },
      { "name": "Canned or jarred non GMO hosui pears", "code": "50555421" },
      {
        "name": "Canned or jarred non GMO italian butter pears",
        "code": "50555422"
      },
      {
        "name": "Canned or jarred non GMO jargonelle pears",
        "code": "50555423"
      },
      { "name": "Canned or jarred non GMO juno pears", "code": "50555424" },
      {
        "name": "Canned or jarred non GMO kaiserlouise bonne de jersey pears",
        "code": "50555425"
      },
      { "name": "Canned or jarred non GMO keiffer pears", "code": "50555426" },
      {
        "name": "Canned or jarred non GMO kings royal pears",
        "code": "50555427"
      },
      { "name": "Canned or jarred non GMO limonera pears", "code": "50555428" },
      {
        "name": "Canned or jarred non GMO merton pride pears",
        "code": "50555429"
      },
      {
        "name": "Canned or jarred non GMO mountain bartlett pears",
        "code": "50555430"
      },
      {
        "name": "Canned or jarred non GMO olivier de serres pears",
        "code": "50555431"
      },
      { "name": "Canned or jarred non GMO onward pears", "code": "50555432" },
      {
        "name": "Canned or jarred non GMO packham's triumph pears",
        "code": "50555433"
      },
      { "name": "Canned or jarred non GMO paraiso pears", "code": "50555434" },
      {
        "name": "Canned or jarred non GMO passe crasanne pears",
        "code": "50555435"
      },
      { "name": "Canned or jarred non GMO perry pears", "code": "50555436" },
      {
        "name": "Canned or jarred non GMO red bartlett pears",
        "code": "50555437"
      },
      {
        "name": "Canned or jarred non GMO red d'anjou pears",
        "code": "50555438"
      },
      { "name": "Canned or jarred non GMO rocha pears", "code": "50555439" },
      {
        "name": "Canned or jarred non GMO rosey red pears",
        "code": "50555440"
      },
      { "name": "Canned or jarred non GMO rosy red pears", "code": "50555441" },
      {
        "name": "Canned or jarred non GMO royal majestic pears",
        "code": "50555442"
      },
      { "name": "Canned or jarred non GMO ruby red pears", "code": "50555443" },
      {
        "name": "Canned or jarred non GMO santa maria pears",
        "code": "50555444"
      },
      { "name": "Canned or jarred non GMO seckel pears", "code": "50555445" },
      {
        "name": "Canned or jarred non GMO sensation pears",
        "code": "50555446"
      },
      {
        "name": "Canned or jarred non GMO star crimson pears",
        "code": "50555447"
      },
      {
        "name": "Canned or jarred non GMO stark crimson pears",
        "code": "50555448"
      },
      {
        "name": "Canned or jarred non GMO summer bartlett pears",
        "code": "50555449"
      },
      {
        "name": "Canned or jarred non GMO summer gold pears",
        "code": "50555450"
      },
      { "name": "Canned or jarred non GMO sun gold pears", "code": "50555451" },
      {
        "name": "Canned or jarred non GMO sunsprite pears",
        "code": "50555452"
      },
      {
        "name": "Canned or jarred non GMO taylors gold pears",
        "code": "50555453"
      },
      {
        "name": "Canned or jarred non GMO taylors red pears",
        "code": "50555454"
      },
      { "name": "Canned or jarred non GMO tientsin pears", "code": "50555455" },
      { "name": "Canned or jarred non GMO tosca pears", "code": "50555456" },
      { "name": "Canned or jarred non GMO warden pears", "code": "50555457" },
      {
        "name": "Canned or jarred non GMO williams bon chretien pears",
        "code": "50555458"
      },
      { "name": "Canned or jarred non GMO williams pears", "code": "50555459" },
      {
        "name": "Canned or jarred non GMO winter nelis pears",
        "code": "50555460"
      },
      { "name": "Canned or jarred non GMO persimmons", "code": "50555500" },
      {
        "name": "Canned or jarred non GMO american persimmon",
        "code": "50555501"
      },
      {
        "name": "Canned or jarred non GMO black sapote persimmon",
        "code": "50555502"
      },
      {
        "name": "Canned or jarred non GMO chapote/black persimmon",
        "code": "50555503"
      },
      {
        "name": "Canned or jarred non GMO date plum persimmon",
        "code": "50555504"
      },
      {
        "name": "Canned or jarred non GMO fuyu persimmons",
        "code": "50555505"
      },
      {
        "name": "Canned or jarred non GMO giant fuyu persimmons",
        "code": "50555506"
      },
      {
        "name": "Canned or jarred non GMO hachiya persimmons",
        "code": "50555507"
      },
      {
        "name": "Canned or jarred non GMO mabolo/butter fruit persimmon",
        "code": "50555508"
      },
      {
        "name": "Canned or jarred non GMO principe ito persimmons",
        "code": "50555509"
      },
      {
        "name": "Canned or jarred non GMO royal brillante persimmons",
        "code": "50555510"
      },
      {
        "name": "Canned or jarred non GMO sharon fruit persimmon",
        "code": "50555511"
      },
      {
        "name": "Canned or jarred non GMO triumph persimmons",
        "code": "50555512"
      },
      { "name": "Canned or jarred non GMO pineapples", "code": "50555600" },
      {
        "name": "Canned or jarred non GMO cherimoya pineapple",
        "code": "50555601"
      },
      {
        "name": "Canned or jarred non GMO golden pineapple",
        "code": "50555602"
      },
      { "name": "Canned or jarred non GMO hilo pineapple", "code": "50555603" },
      {
        "name": "Canned or jarred non GMO kona sugarloaf pineapple",
        "code": "50555604"
      },
      {
        "name": "Canned or jarred non GMO natal queen pineapple",
        "code": "50555605"
      },
      {
        "name": "Canned or jarred non GMO pernambuco pineapple",
        "code": "50555606"
      },
      {
        "name": "Canned or jarred non GMO red spanish pineapple",
        "code": "50555607"
      },
      {
        "name": "Canned or jarred non GMO smooth cayenne pineapple",
        "code": "50555608"
      },
      {
        "name": "Canned or jarred non GMO sugarloaf pineapple",
        "code": "50555609"
      },
      {
        "name": "Canned or jarred non GMO variegated pineapple",
        "code": "50555610"
      },
      { "name": "Canned or jarred non GMO plucots", "code": "50555700" },
      {
        "name": "Canned or jarred non GMO black kat plucot",
        "code": "50555701"
      },
      {
        "name": "Canned or jarred non GMO blue gusto plucot",
        "code": "50555702"
      },
      {
        "name": "Canned or jarred non GMO crimson heart plucot",
        "code": "50555703"
      },
      {
        "name": "Canned or jarred non GMO dapple dandy plucot",
        "code": "50555704"
      },
      {
        "name": "Canned or jarred non GMO dapple fire plucot",
        "code": "50555705"
      },
      {
        "name": "Canned or jarred non GMO early dapple plucot",
        "code": "50555706"
      },
      {
        "name": "Canned or jarred non GMO flavor fall plucot",
        "code": "50555707"
      },
      {
        "name": "Canned or jarred non GMO flavor gold plucot",
        "code": "50555708"
      },
      {
        "name": "Canned or jarred non GMO flavor grenade plucot",
        "code": "50555709"
      },
      {
        "name": "Canned or jarred non GMO flavor heart plucot",
        "code": "50555710"
      },
      {
        "name": "Canned or jarred non GMO flavor jewel plucot",
        "code": "50555711"
      },
      {
        "name": "Canned or jarred non GMO flavor king plucot",
        "code": "50555712"
      },
      {
        "name": "Canned or jarred non GMO flavor queen plucot",
        "code": "50555713"
      },
      {
        "name": "Canned or jarred non GMO flavor supreme plucot",
        "code": "50555714"
      },
      {
        "name": "Canned or jarred non GMO flavor treat plucot",
        "code": "50555715"
      },
      {
        "name": "Canned or jarred non GMO flavorella plucot",
        "code": "50555716"
      },
      {
        "name": "Canned or jarred non GMO flavorich plucot",
        "code": "50555717"
      },
      {
        "name": "Canned or jarred non GMO flavorosa plucot",
        "code": "50555718"
      },
      {
        "name": "Canned or jarred non GMO geo pride plucot",
        "code": "50555719"
      },
      { "name": "Canned or jarred non GMO red kat plucot", "code": "50555720" },
      {
        "name": "Canned or jarred non GMO royal treat plucot",
        "code": "50555721"
      },
      {
        "name": "Canned or jarred non GMO sierra rose plucot",
        "code": "50555722"
      },
      {
        "name": "Canned or jarred non GMO sweet geisha plucot",
        "code": "50555723"
      },
      { "name": "Canned or jarred non GMO plums", "code": "50555800" },
      {
        "name": "Canned or jarred non GMO amber jewel plums",
        "code": "50555801"
      },
      { "name": "Canned or jarred non GMO angeleno plums", "code": "50555802" },
      { "name": "Canned or jarred non GMO aurora plums", "code": "50555803" },
      {
        "name": "Canned or jarred non GMO autumn beaut plums",
        "code": "50555804"
      },
      {
        "name": "Canned or jarred non GMO autumn giant plums",
        "code": "50555805"
      },
      {
        "name": "Canned or jarred non GMO autumn pride plums",
        "code": "50555806"
      },
      {
        "name": "Canned or jarred non GMO autumn rosa plums",
        "code": "50555807"
      },
      { "name": "Canned or jarred non GMO beach plum", "code": "50555808" },
      {
        "name": "Canned or jarred non GMO betty anne plums",
        "code": "50555809"
      },
      {
        "name": "Canned or jarred non GMO black beaut plums",
        "code": "50555810"
      },
      {
        "name": "Canned or jarred non GMO black bullace plum",
        "code": "50555811"
      },
      {
        "name": "Canned or jarred non GMO black diamond plums",
        "code": "50555812"
      },
      {
        "name": "Canned or jarred non GMO black giant plums",
        "code": "50555813"
      },
      {
        "name": "Canned or jarred non GMO black ice plums",
        "code": "50555814"
      },
      {
        "name": "Canned or jarred non GMO black splendor plums",
        "code": "50555815"
      },
      {
        "name": "Canned or jarred non GMO blackamber plums",
        "code": "50555816"
      },
      { "name": "Canned or jarred non GMO burgundy plums", "code": "50555817" },
      { "name": "Canned or jarred non GMO carlsbad plum", "code": "50555818" },
      {
        "name": "Canned or jarred non GMO casselman plums",
        "code": "50555819"
      },
      { "name": "Canned or jarred non GMO catalina plums", "code": "50555820" },
      { "name": "Canned or jarred non GMO damson plum", "code": "50555821" },
      { "name": "Canned or jarred non GMO dolly plums", "code": "50555822" },
      {
        "name": "Canned or jarred non GMO earliqueen plums",
        "code": "50555823"
      },
      {
        "name": "Canned or jarred non GMO early rosa plums",
        "code": "50555824"
      },
      {
        "name": "Canned or jarred non GMO ebony may plums",
        "code": "50555825"
      },
      { "name": "Canned or jarred non GMO ebony plums", "code": "50555826" },
      {
        "name": "Canned or jarred non GMO elephant heart plums",
        "code": "50555827"
      },
      {
        "name": "Canned or jarred non GMO emerald beaut plums",
        "code": "50555828"
      },
      { "name": "Canned or jarred non GMO empress plums", "code": "50555829" },
      { "name": "Canned or jarred non GMO freedom plums", "code": "50555830" },
      { "name": "Canned or jarred non GMO friar plums", "code": "50555831" },
      { "name": "Canned or jarred non GMO gar red plums", "code": "50555832" },
      {
        "name": "Canned or jarred non GMO governor's plum",
        "code": "50555833"
      },
      {
        "name": "Canned or jarred non GMO grand rosa plums",
        "code": "50555834"
      },
      {
        "name": "Canned or jarred non GMO green gage plum",
        "code": "50555835"
      },
      {
        "name": "Canned or jarred non GMO greengage plums",
        "code": "50555836"
      },
      { "name": "Canned or jarred non GMO hiromi plums", "code": "50555837" },
      {
        "name": "Canned or jarred non GMO hiromi red plums",
        "code": "50555838"
      },
      { "name": "Canned or jarred non GMO holiday plums", "code": "50555839" },
      {
        "name": "Canned or jarred non GMO howard sun plums",
        "code": "50555840"
      },
      {
        "name": "Canned or jarred non GMO interspecific type plums",
        "code": "50555841"
      },
      { "name": "Canned or jarred non GMO jamaican plum", "code": "50555842" },
      {
        "name": "Canned or jarred non GMO joanna red plums",
        "code": "50555843"
      },
      { "name": "Canned or jarred non GMO kelsey plums", "code": "50555844" },
      {
        "name": "Canned or jarred non GMO king james plums",
        "code": "50555845"
      },
      { "name": "Canned or jarred non GMO laroda plums", "code": "50555846" },
      {
        "name": "Canned or jarred non GMO late rosa plums",
        "code": "50555847"
      },
      {
        "name": "Canned or jarred non GMO linda rosa plums",
        "code": "50555848"
      },
      {
        "name": "Canned or jarred non GMO lone star red plums",
        "code": "50555849"
      },
      { "name": "Canned or jarred non GMO mariposa plums", "code": "50555850" },
      {
        "name": "Canned or jarred non GMO marked black plums",
        "code": "50555851"
      },
      {
        "name": "Canned or jarred non GMO marked red plums",
        "code": "50555852"
      },
      { "name": "Canned or jarred non GMO mirabelle plum", "code": "50555853" },
      {
        "name": "Canned or jarred non GMO october sun plums",
        "code": "50555854"
      },
      { "name": "Canned or jarred non GMO owen t plums", "code": "50555855" },
      { "name": "Canned or jarred non GMO perdrigon plum", "code": "50555856" },
      {
        "name": "Canned or jarred non GMO pink delight plums",
        "code": "50555857"
      },
      {
        "name": "Canned or jarred non GMO president plums",
        "code": "50555858"
      },
      {
        "name": "Canned or jarred non GMO primetime plums",
        "code": "50555859"
      },
      {
        "name": "Canned or jarred non GMO purple majesty plums",
        "code": "50555860"
      },
      {
        "name": "Canned or jarred non GMO queen rosa plums",
        "code": "50555861"
      },
      { "name": "Canned or jarred non GMO quetsch plum", "code": "50555862" },
      {
        "name": "Canned or jarred non GMO red beaut plums",
        "code": "50555863"
      },
      { "name": "Canned or jarred non GMO red lane plums", "code": "50555864" },
      { "name": "Canned or jarred non GMO red ram plums", "code": "50555865" },
      { "name": "Canned or jarred non GMO red rosa plums", "code": "50555866" },
      { "name": "Canned or jarred non GMO rich red plums", "code": "50555867" },
      { "name": "Canned or jarred non GMO rosemary plums", "code": "50555868" },
      {
        "name": "Canned or jarred non GMO royal diamond plums",
        "code": "50555869"
      },
      {
        "name": "Canned or jarred non GMO royal red plums",
        "code": "50555870"
      },
      {
        "name": "Canned or jarred non GMO royal zee plums",
        "code": "50555871"
      },
      { "name": "Canned or jarred non GMO roysum plums", "code": "50555872" },
      {
        "name": "Canned or jarred non GMO santa rosa plums",
        "code": "50555873"
      },
      { "name": "Canned or jarred non GMO saphire plums", "code": "50555874" },
      { "name": "Canned or jarred non GMO sloe plum", "code": "50555875" },
      {
        "name": "Canned or jarred non GMO st catherine plum",
        "code": "50555876"
      },
      {
        "name": "Canned or jarred non GMO white bullace plum",
        "code": "50555877"
      },
      { "name": "Canned or jarred non GMO pomegranates", "code": "50555900" },
      {
        "name": "Canned or jarred non GMO foothill pomegranates",
        "code": "50555901"
      },
      {
        "name": "Canned or jarred non GMO granada pomegranates",
        "code": "50555902"
      },
      {
        "name": "Canned or jarred non GMO jolly red pomegranates",
        "code": "50555903"
      },
      {
        "name": "Canned or jarred non GMO nana pomegranates",
        "code": "50555904"
      },
      {
        "name": "Canned or jarred non GMO pat's red pomegranates",
        "code": "50555905"
      },
      {
        "name": "Canned or jarred non GMO pinkhan pomegranates",
        "code": "50555906"
      },
      {
        "name": "Canned or jarred non GMO purple velvet pomegranates",
        "code": "50555907"
      },
      {
        "name": "Canned or jarred non GMO wonderful pomegranates",
        "code": "50555908"
      },
      { "name": "Canned or jarred non GMO pomelos", "code": "50556000" },
      {
        "name": "Canned or jarred non GMO chandler pomelo",
        "code": "50556001"
      },
      {
        "name": "Canned or jarred non GMO hirado buntan pomelo",
        "code": "50556002"
      },
      {
        "name": "Canned or jarred non GMO liang ping yau pomelo",
        "code": "50556003"
      },
      {
        "name": "Canned or jarred non GMO pandan wangi pomelo",
        "code": "50556004"
      },
      { "name": "Canned or jarred non GMO pink pomelo", "code": "50556005" },
      {
        "name": "Canned or jarred non GMO red shaddock pomelo",
        "code": "50556006"
      },
      {
        "name": "Canned or jarred non GMO siamese sweet pomelo",
        "code": "50556007"
      },
      {
        "name": "Canned or jarred non GMO wainwright pomelo",
        "code": "50556008"
      },
      { "name": "Canned or jarred non GMO quinces", "code": "50556100" },
      {
        "name": "Canned or jarred non GMO champion quince",
        "code": "50556101"
      },
      {
        "name": "Canned or jarred non GMO pineapple quince",
        "code": "50556102"
      },
      { "name": "Canned or jarred non GMO smyrna quince", "code": "50556103" },
      { "name": "Canned or jarred non GMO raspberries", "code": "50556200" },
      {
        "name": "Canned or jarred non GMO american red raspberry",
        "code": "50556201"
      },
      {
        "name": "Canned or jarred non GMO bailey queensland raspberry",
        "code": "50556202"
      },
      {
        "name": "Canned or jarred non GMO black raspberry",
        "code": "50556203"
      },
      { "name": "Canned or jarred non GMO dark raspberry", "code": "50556204" },
      {
        "name": "Canned or jarred non GMO delicious raspberry",
        "code": "50556205"
      },
      {
        "name": "Canned or jarred non GMO focke dwarf raspberry",
        "code": "50556206"
      },
      {
        "name": "Canned or jarred non GMO focke grayleaf red raspberry",
        "code": "50556207"
      },
      {
        "name": "Canned or jarred non GMO focke strawberry raspberry",
        "code": "50556208"
      },
      {
        "name": "Canned or jarred non GMO focke yellow himalayan raspberry",
        "code": "50556209"
      },
      { "name": "Canned or jarred non GMO gold raspberry", "code": "50556210" },
      {
        "name": "Canned or jarred non GMO gray new mexico raspberry",
        "code": "50556211"
      },
      {
        "name": "Canned or jarred non GMO jepson whitebark raspberry",
        "code": "50556212"
      },
      {
        "name": "Canned or jarred non GMO kellogg san diego raspberry",
        "code": "50556213"
      },
      {
        "name": "Canned or jarred non GMO leucodermis whitebark raspberry",
        "code": "50556214"
      },
      {
        "name": "Canned or jarred non GMO munz cuyamaca raspberry",
        "code": "50556215"
      },
      {
        "name": "Canned or jarred non GMO peck barton's raspberry",
        "code": "50556216"
      },
      {
        "name": "Canned or jarred non GMO purpleflowering raspberry",
        "code": "50556217"
      },
      {
        "name": "Canned or jarred non GMO roadside raspberry",
        "code": "50556218"
      },
      {
        "name": "Canned or jarred non GMO san diego raspberry",
        "code": "50556219"
      },
      { "name": "Canned or jarred non GMO snow raspberry", "code": "50556220" },
      {
        "name": "Canned or jarred non GMO snowpeaks raspberry",
        "code": "50556221"
      },
      {
        "name": "Canned or jarred non GMO strawberryleaf raspberry",
        "code": "50556222"
      },
      {
        "name": "Canned or jarred non GMO sweet cultivated raspberry",
        "code": "50556223"
      },
      {
        "name": "Canned or jarred non GMO torr and gray whitebark raspberry",
        "code": "50556224"
      },
      {
        "name": "Canned or jarred non GMO west indian raspberry",
        "code": "50556225"
      },
      {
        "name": "Canned or jarred non GMO whitebark raspberry",
        "code": "50556226"
      },
      { "name": "Canned or jarred non GMO wine raspberry", "code": "50556227" },
      {
        "name": "Canned or jarred non GMO yellow himalayan raspberry",
        "code": "50556228"
      },
      {
        "name": "Canned or jarred non GMO yu-shan raspberry",
        "code": "50556229"
      },
      { "name": "Canned or jarred non GMO rhubarbs", "code": "50556300" },
      {
        "name": "Canned or jarred non GMO crimson red rhubarb",
        "code": "50556301"
      },
      {
        "name": "Canned or jarred non GMO early champagne rhubarb",
        "code": "50556302"
      },
      {
        "name": "Canned or jarred non GMO glaskin's perpetual rhubarb",
        "code": "50556303"
      },
      { "name": "Canned or jarred non GMO sutton rhubarb", "code": "50556304" },
      {
        "name": "Canned or jarred non GMO timperley early rhubarb",
        "code": "50556305"
      },
      {
        "name": "Canned or jarred non GMO valentine rhubarb",
        "code": "50556306"
      },
      {
        "name": "Canned or jarred non GMO victoria rhubarb",
        "code": "50556307"
      },
      {
        "name": "Canned or jarred non GMO zwolle seedling rhubarb",
        "code": "50556308"
      },
      {
        "name": "Canned or jarred non GMO macdonald rhubarb",
        "code": "50556309"
      },
      { "name": "Canned or jarred non GMO tilden rhubarb", "code": "50556310" },
      { "name": "Canned or jarred non GMO rose hips", "code": "50556400" },
      {
        "name": "Canned or jarred non GMO brier rose hips",
        "code": "50556401"
      },
      {
        "name": "Canned or jarred non GMO elgantine rose hips",
        "code": "50556402"
      },
      {
        "name": "Canned or jarred non GMO rugosa rose hips",
        "code": "50556403"
      },
      {
        "name": "Canned or jarred non GMO scotch or burnet rose hips",
        "code": "50556404"
      },
      { "name": "Canned or jarred non GMO sapotes", "code": "50556500" },
      { "name": "Canned or jarred non GMO white sapotes", "code": "50556501" },
      { "name": "Canned or jarred non GMO black sapotes", "code": "50556502" },
      {
        "name": "Canned or jarred non GMO saskatoon berries",
        "code": "50556600"
      },
      {
        "name": "Canned or jarred non GMO honeywood saskatoon berries",
        "code": "50556601"
      },
      {
        "name": "Canned or jarred non GMO northline saskatoon berries",
        "code": "50556602"
      },
      {
        "name": "Canned or jarred non GMO smoky saskatoon berries",
        "code": "50556603"
      },
      {
        "name": "Canned or jarred non GMO thiessen saskatoon berries",
        "code": "50556604"
      },
      { "name": "Canned or jarred non GMO strawberries", "code": "50556700" },
      {
        "name": "Canned or jarred non GMO chandler strawberries",
        "code": "50556701"
      },
      {
        "name": "Canned or jarred non GMO june bearing strawberries",
        "code": "50556702"
      },
      {
        "name": "Canned or jarred non GMO ever bearing strawberries",
        "code": "50556703"
      },
      { "name": "Canned or jarred non GMO sugar apples", "code": "50556800" },
      {
        "name": "Canned or jarred non GMO kampong mauve sugar apple",
        "code": "50556801"
      },
      {
        "name": "Canned or jarred non GMO seedless sugar apple",
        "code": "50556802"
      },
      {
        "name": "Canned or jarred non GMO thai lessard sugar apple",
        "code": "50556803"
      },
      { "name": "Canned or jarred non GMO tamarillos", "code": "50556900" },
      {
        "name": "Canned or jarred non GMO amberlea gold tamarillo",
        "code": "50556901"
      },
      {
        "name": "Canned or jarred non GMO bold gold tamarillo",
        "code": "50556902"
      },
      {
        "name": "Canned or jarred non GMO goldmine tamarillo",
        "code": "50556903"
      },
      {
        "name": "Canned or jarred non GMO oratia red tamarillo",
        "code": "50556904"
      },
      {
        "name": "Canned or jarred non GMO red beau tamarillo",
        "code": "50556905"
      },
      {
        "name": "Canned or jarred non GMO red delight tamarillo",
        "code": "50556906"
      },
      {
        "name": "Canned or jarred non GMO nominant fruits",
        "code": "50557000"
      },
      { "name": "Canned or jarred non GMO akee", "code": "50557001" },
      { "name": "Canned or jarred non GMO babaco", "code": "50557002" },
      { "name": "Canned or jarred non GMO banana flowers", "code": "50557003" },
      { "name": "Canned or jarred non GMO baobab", "code": "50557004" },
      { "name": "Canned or jarred non GMO bitter oranges", "code": "50557005" },
      { "name": "Canned or jarred non GMO canistel", "code": "50557006" },
      { "name": "Canned or jarred non GMO coconuts", "code": "50557007" },
      { "name": "Canned or jarred non GMO cloudberries", "code": "50557008" },
      { "name": "Canned or jarred non GMO dewberries", "code": "50557009" },
      { "name": "Canned or jarred non GMO durian", "code": "50557010" },
      { "name": "Canned or jarred non GMO elderberries", "code": "50557011" },
      { "name": "Canned or jarred non GMO feijoa", "code": "50557012" },
      { "name": "Canned or jarred non GMO hackberries", "code": "50557013" },
      { "name": "Canned or jarred non GMO hawthorn", "code": "50557014" },
      { "name": "Canned or jarred non GMO honeyberries", "code": "50557015" },
      { "name": "Canned or jarred non GMO jackfruit", "code": "50557016" },
      { "name": "Canned or jarred non GMO jambolan", "code": "50557017" },
      { "name": "Canned or jarred non GMO jujube", "code": "50557018" },
      { "name": "Canned or jarred non GMO lychee", "code": "50557019" },
      { "name": "Canned or jarred non GMO mangosteens", "code": "50557020" },
      { "name": "Canned or jarred non GMO medlars", "code": "50557021" },
      { "name": "Canned or jarred non GMO mombins", "code": "50557022" },
      { "name": "Canned or jarred non GMO monstera", "code": "50557023" },
      { "name": "Canned or jarred non GMO pepinos", "code": "50557024" },
      { "name": "Canned or jarred non GMO plantains", "code": "50557025" },
      { "name": "Canned or jarred non GMO prickly pears", "code": "50557026" },
      { "name": "Canned or jarred non GMO quenepas", "code": "50557027" },
      { "name": "Canned or jarred non GMO rambutan", "code": "50557028" },
      { "name": "Canned or jarred non GMO rose apples", "code": "50557029" },
      { "name": "Canned or jarred non GMO roselle", "code": "50557030" },
      { "name": "Canned or jarred non GMO rowanberries", "code": "50557031" },
      {
        "name": "Canned or jarred non GMO sea buckhorn berries",
        "code": "50557032"
      },
      { "name": "Canned or jarred non GMO silverberries", "code": "50557033" },
      { "name": "Canned or jarred non GMO sorb berries", "code": "50557034" },
      { "name": "Canned or jarred non GMO soursops", "code": "50557035" },
      { "name": "Canned or jarred non GMO star apples", "code": "50557036" },
      { "name": "Canned or jarred non GMO tamarindo", "code": "50557037" },
      { "name": "Canned or jarred non GMO chokeberries", "code": "50557100" },
      {
        "name": "Canned or jarred non GMO autumn magic chokeberries",
        "code": "50557101"
      },
      {
        "name": "Canned or jarred non GMO brillantisima chokeberries",
        "code": "50557102"
      },
      {
        "name": "Canned or jarred non GMO nero chokeberries",
        "code": "50557103"
      },
      {
        "name": "Canned or jarred non GMO viking chokeberries",
        "code": "50557104"
      },
      { "name": "Canned or jarred non GMO olives", "code": "50557200" },
      {
        "name": "Canned or jarred non GMO agrinion olives",
        "code": "50557201"
      },
      { "name": "Canned or jarred non GMO aleppo olives", "code": "50557202" },
      {
        "name": "Canned or jarred non GMO alphonso olives",
        "code": "50557203"
      },
      {
        "name": "Canned or jarred non GMO amphissa olives",
        "code": "50557204"
      },
      { "name": "Canned or jarred non GMO arauco olives", "code": "50557205" },
      {
        "name": "Canned or jarred non GMO arbequina olives",
        "code": "50557206"
      },
      {
        "name": "Canned or jarred non GMO atalanta olives",
        "code": "50557207"
      },
      {
        "name": "Canned or jarred non GMO cerignola olives",
        "code": "50557208"
      },
      {
        "name": "Canned or jarred non GMO cracked provencal olives",
        "code": "50557209"
      },
      {
        "name": "Canned or jarred non GMO empeltre olives",
        "code": "50557210"
      },
      { "name": "Canned or jarred non GMO gaeta olives", "code": "50557211" },
      {
        "name": "Canned or jarred non GMO hondroelia olives",
        "code": "50557212"
      },
      {
        "name": "Canned or jarred non GMO kalamata olives",
        "code": "50557213"
      },
      { "name": "Canned or jarred non GMO kura olives", "code": "50557214" },
      {
        "name": "Canned or jarred non GMO ligurian olives",
        "code": "50557215"
      },
      { "name": "Canned or jarred non GMO lucque olives", "code": "50557216" },
      { "name": "Canned or jarred non GMO lugano olives", "code": "50557217" },
      {
        "name": "Canned or jarred non GMO manzanilla olives",
        "code": "50557218"
      },
      { "name": "Canned or jarred non GMO marche olives", "code": "50557219" },
      { "name": "Canned or jarred non GMO mission olives", "code": "50557220" },
      {
        "name": "Canned or jarred non GMO nafplion green olives",
        "code": "50557221"
      },
      { "name": "Canned or jarred non GMO nicoise olives", "code": "50557222" },
      { "name": "Canned or jarred non GMO nyons olives", "code": "50557223" },
      {
        "name": "Canned or jarred non GMO picholine olives",
        "code": "50557224"
      },
      {
        "name": "Canned or jarred non GMO ponentine olives",
        "code": "50557225"
      },
      { "name": "Canned or jarred non GMO royal olives", "code": "50557226" },
      {
        "name": "Canned or jarred non GMO seracena olives",
        "code": "50557227"
      },
      {
        "name": "Canned or jarred non GMO sevillano olives",
        "code": "50557228"
      },
      {
        "name": "Canned or jarred non GMO sicilian olives",
        "code": "50557229"
      },
      {
        "name": "Canned or jarred non GMO toscanelle olives",
        "code": "50557230"
      },
      { "name": "Non GMO fresh fruit purees", "code": "50560000" },
      { "name": "Non GMO apple purees", "code": "50561500" },
      { "name": "Non GMO akane apple purees", "code": "50561501" },
      { "name": "Non GMO ambrosia apple purees", "code": "50561502" },
      { "name": "Non GMO api apple purees", "code": "50561503" },
      { "name": "Non GMO baldwin apple purees", "code": "50561504" },
      { "name": "Non GMO braeburn apple purees", "code": "50561505" },
      { "name": "Non GMO bramley apple purees", "code": "50561506" },
      { "name": "Non GMO bramley seedling apple purees", "code": "50561507" },
      {
        "name": "Non GMO calville blanche d'hiver apple purees",
        "code": "50561508"
      },
      { "name": "Non GMO cameo apple purees", "code": "50561509" },
      { "name": "Non GMO charles ross apple purees", "code": "50561510" },
      { "name": "Non GMO codlin apple purees", "code": "50561511" },
      { "name": "Non GMO cortland apple purees", "code": "50561512" },
      { "name": "Non GMO costard apple purees", "code": "50561513" },
      { "name": "Non GMO court pendu plat apple purees", "code": "50561514" },
      {
        "name": "Non GMO cox's orange pippin apple purees",
        "code": "50561515"
      },
      { "name": "Non GMO crab apple purees", "code": "50561516" },
      { "name": "Non GMO crispin apple purees", "code": "50561517" },
      { "name": "Non GMO delicious apple purees", "code": "50561518" },
      { "name": "Non GMO duchess apple purees", "code": "50561519" },
      { "name": "Non GMO earligold apple purees", "code": "50561520" },
      { "name": "Non GMO early mcintosh apple purees", "code": "50561521" },
      { "name": "Non GMO elstar apple purees", "code": "50561522" },
      { "name": "Non GMO empire apple purees", "code": "50561523" },
      { "name": "Non GMO flower of kent apple purees", "code": "50561524" },
      { "name": "Non GMO fuji apple purees", "code": "50561525" },
      { "name": "Non GMO gala apple purees", "code": "50561526" },
      { "name": "Non GMO gascoyne's scarlet apple purees", "code": "50561527" },
      { "name": "Non GMO giliflower apple purees", "code": "50561528" },
      { "name": "Non GMO ginger gold apple purees", "code": "50561529" },
      { "name": "Non GMO gladstone apple purees", "code": "50561530" },
      { "name": "Non GMO gloster apple purees", "code": "50561531" },
      { "name": "Non GMO gold supreme apple purees", "code": "50561532" },
      { "name": "Non GMO golden delicious apple purees", "code": "50561533" },
      { "name": "Non GMO golden noble apple purees", "code": "50561534" },
      { "name": "Non GMO granny smith apple purees", "code": "50561535" },
      { "name": "Non GMO gravenstein apple purees", "code": "50561536" },
      { "name": "Non GMO greening apple purees", "code": "50561537" },
      { "name": "Non GMO greensleeves apple purees", "code": "50561538" },
      { "name": "Non GMO honeycrisp apple purees", "code": "50561539" },
      { "name": "Non GMO howgate wonder apple purees", "code": "50561540" },
      { "name": "Non GMO ida red apple purees", "code": "50561541" },
      { "name": "Non GMO james grieve apple purees", "code": "50561542" },
      { "name": "Non GMO jersey mac apple purees", "code": "50561543" },
      { "name": "Non GMO jester apple purees", "code": "50561544" },
      { "name": "Non GMO jonagold apple purees", "code": "50561545" },
      { "name": "Non GMO jonamac apple purees", "code": "50561546" },
      { "name": "Non GMO jonathan apple purees", "code": "50561547" },
      { "name": "Non GMO katy apple purees", "code": "50561548" },
      { "name": "Non GMO kidd's orange red apple purees", "code": "50561549" },
      { "name": "Non GMO lady apple purees", "code": "50561550" },
      { "name": "Non GMO law rome apple purees", "code": "50561551" },
      { "name": "Non GMO laxton apple purees", "code": "50561552" },
      { "name": "Non GMO lord derby apple purees", "code": "50561553" },
      { "name": "Non GMO macoun apple purees", "code": "50561554" },
      { "name": "Non GMO mcintosh apple purees", "code": "50561555" },
      { "name": "Non GMO mutsu apple purees", "code": "50561556" },
      { "name": "Non GMO newtown pippin apple purees", "code": "50561557" },
      { "name": "Non GMO northern spy apple purees", "code": "50561558" },
      { "name": "Non GMO orleans reinette apple purees", "code": "50561559" },
      { "name": "Non GMO ozark gold apple purees", "code": "50561560" },
      { "name": "Non GMO pacific rose apple purees", "code": "50561561" },
      { "name": "Non GMO paula red apple purees", "code": "50561562" },
      { "name": "Non GMO pearmain apple purees", "code": "50561563" },
      { "name": "Non GMO pink lady apple purees", "code": "50561564" },
      { "name": "Non GMO pippin apple purees", "code": "50561565" },
      {
        "name": "Non GMO pitmaston pineapple apple purees",
        "code": "50561566"
      },
      { "name": "Non GMO pomme d'api apple purees", "code": "50561567" },
      { "name": "Non GMO prime gold apple purees", "code": "50561568" },
      { "name": "Non GMO red astrachan apple purees", "code": "50561569" },
      { "name": "Non GMO red boscoop apple purees", "code": "50561570" },
      { "name": "Non GMO red chief apple purees", "code": "50561571" },
      { "name": "Non GMO red delicious apple purees", "code": "50561572" },
      { "name": "Non GMO red gravenstein apple purees", "code": "50561573" },
      { "name": "Non GMO red rome apple purees", "code": "50561574" },
      { "name": "Non GMO red stayman apple purees", "code": "50561575" },
      { "name": "Non GMO red york apple purees", "code": "50561576" },
      { "name": "Non GMO reinette apple purees", "code": "50561577" },
      { "name": "Non GMO rome beauty apple purees", "code": "50561578" },
      { "name": "Non GMO russet apple purees", "code": "50561579" },
      { "name": "Non GMO sierra beauty apple purees", "code": "50561580" },
      { "name": "Non GMO spartan apple purees", "code": "50561581" },
      { "name": "Non GMO stark crimson apple purees", "code": "50561582" },
      { "name": "Non GMO starking apple purees", "code": "50561583" },
      { "name": "Non GMO stayman apple purees", "code": "50561584" },
      { "name": "Non GMO stayman winesap apple purees", "code": "50561585" },
      { "name": "Non GMO summer rambo apple purees", "code": "50561586" },
      { "name": "Non GMO tsugaru apple purees", "code": "50561587" },
      { "name": "Non GMO twenty ounce apple purees", "code": "50561588" },
      { "name": "Non GMO tydeman red apple purees", "code": "50561589" },
      { "name": "Non GMO vistabella apple purees", "code": "50561590" },
      { "name": "Non GMO wealthy apple purees", "code": "50561591" },
      { "name": "Non GMO white joaneting apple purees", "code": "50561592" },
      { "name": "Non GMO white transparent apple purees", "code": "50561593" },
      { "name": "Non GMO winesap apple purees", "code": "50561594" },
      { "name": "Non GMO worcester apple purees", "code": "50561595" },
      { "name": "Non GMO york imperial apple purees", "code": "50561596" },
      { "name": "Non GMO apricot purees", "code": "50561600" },
      { "name": "Non GMO ambercot apricot purees", "code": "50561601" },
      { "name": "Non GMO apache apricot purees", "code": "50561602" },
      { "name": "Non GMO birttany gold apricot purees", "code": "50561603" },
      { "name": "Non GMO black apricot purees", "code": "50561604" },
      { "name": "Non GMO blenheim apricot purees", "code": "50561605" },
      { "name": "Non GMO bonny apricot purees", "code": "50561606" },
      { "name": "Non GMO bulida apricot purees", "code": "50561607" },
      { "name": "Non GMO castlebrite apricot purees", "code": "50561608" },
      { "name": "Non GMO clutha gold apricot purees", "code": "50561609" },
      { "name": "Non GMO cluthasun apricot purees", "code": "50561610" },
      { "name": "Non GMO darby royal apricot purees", "code": "50561611" },
      { "name": "Non GMO dina apricot purees", "code": "50561612" },
      { "name": "Non GMO earlicot apricot purees", "code": "50561613" },
      { "name": "Non GMO earliman apricot purees", "code": "50561614" },
      { "name": "Non GMO early bright apricot purees", "code": "50561615" },
      { "name": "Non GMO flaming gold apricot purees", "code": "50561616" },
      { "name": "Non GMO fresno apricot purees", "code": "50561617" },
      { "name": "Non GMO gold brite apricot purees", "code": "50561618" },
      { "name": "Non GMO goldbar apricot purees", "code": "50561619" },
      { "name": "Non GMO golden sweet apricot purees", "code": "50561620" },
      { "name": "Non GMO goldrich apricot purees", "code": "50561621" },
      { "name": "Non GMO helena apricot purees", "code": "50561622" },
      { "name": "Non GMO honeycot apricot purees", "code": "50561623" },
      { "name": "Non GMO imperial apricot purees", "code": "50561624" },
      { "name": "Non GMO jordanne apricot purees", "code": "50561625" },
      { "name": "Non GMO jumbo cot apricot purees", "code": "50561626" },
      { "name": "Non GMO kandy kot apricot purees", "code": "50561627" },
      { "name": "Non GMO katy apricot purees", "code": "50561628" },
      { "name": "Non GMO king apricot purees", "code": "50561629" },
      { "name": "Non GMO lambertin apricot purees", "code": "50561630" },
      { "name": "Non GMO lorna apricot purees", "code": "50561631" },
      { "name": "Non GMO lulu belle apricot purees", "code": "50561632" },
      { "name": "Non GMO modesto apricot purees", "code": "50561633" },
      { "name": "Non GMO moorpark apricot purees", "code": "50561634" },
      { "name": "Non GMO orangered apricot purees", "code": "50561635" },
      { "name": "Non GMO palstein apricot purees", "code": "50561636" },
      { "name": "Non GMO patterson apricot purees", "code": "50561637" },
      { "name": "Non GMO perfection apricot purees", "code": "50561638" },
      { "name": "Non GMO poppy apricot purees", "code": "50561639" },
      { "name": "Non GMO poppycot apricot purees", "code": "50561640" },
      { "name": "Non GMO queen apricot purees", "code": "50561641" },
      { "name": "Non GMO riland apricot purees", "code": "50561642" },
      { "name": "Non GMO rival apricot purees", "code": "50561643" },
      { "name": "Non GMO robada apricot purees", "code": "50561644" },
      { "name": "Non GMO royal apricot purees", "code": "50561645" },
      { "name": "Non GMO royal blenheim apricot purees", "code": "50561646" },
      { "name": "Non GMO royal orange apricot purees", "code": "50561647" },
      { "name": "Non GMO sundrop apricot purees", "code": "50561648" },
      { "name": "Non GMO tilton apricot purees", "code": "50561649" },
      { "name": "Non GMO tomcot apricot purees", "code": "50561650" },
      { "name": "Non GMO tracy apricot purees", "code": "50561651" },
      { "name": "Non GMO tri gem apricot purees", "code": "50561652" },
      { "name": "Non GMO valley gold apricot purees", "code": "50561653" },
      { "name": "Non GMO westley apricot purees", "code": "50561654" },
      { "name": "Non GMO york apricot purees", "code": "50561655" },
      { "name": "Non GMO banana purees", "code": "50561700" },
      { "name": "Non GMO apple banana purees", "code": "50561701" },
      { "name": "Non GMO baby banana purees", "code": "50561702" },
      { "name": "Non GMO burro banana purees", "code": "50561703" },
      { "name": "Non GMO cavendish banana purees", "code": "50561704" },
      { "name": "Non GMO dominico banana purees", "code": "50561705" },
      { "name": "Non GMO green banana purees", "code": "50561706" },
      { "name": "Non GMO gros michel banana purees", "code": "50561707" },
      { "name": "Non GMO lacatan banana purees", "code": "50561708" },
      { "name": "Non GMO lady finger banan purees", "code": "50561709" },
      { "name": "Non GMO manzano banana purees", "code": "50561710" },
      { "name": "Non GMO mysore banana purees", "code": "50561711" },
      { "name": "Non GMO pisang mas banana purees", "code": "50561712" },
      { "name": "Non GMO red banana purees", "code": "50561713" },
      { "name": "Non GMO saba banana purees", "code": "50561714" },
      { "name": "Non GMO sucrier banana purees", "code": "50561715" },
      { "name": "Non GMO barberry purees", "code": "50561800" },
      { "name": "Non GMO paleleaf barberry purees", "code": "50561801" },
      { "name": "Non GMO chenault barberry purees", "code": "50561802" },
      { "name": "Non GMO red barberry purees", "code": "50561803" },
      { "name": "Non GMO wintergreen barberry purees", "code": "50561804" },
      { "name": "Non GMO korean barberry purees", "code": "50561805" },
      { "name": "Non GMO mentor barberry purees", "code": "50561806" },
      { "name": "Non GMO japanese barberry purees", "code": "50561807" },
      { "name": "Non GMO atropurpurea barberry purees", "code": "50561808" },
      { "name": "Non GMO aurea barberry purees", "code": "50561809" },
      { "name": "Non GMO bagatelle barberry purees", "code": "50561810" },
      { "name": "Non GMO crimson pygmy barberry purees", "code": "50561811" },
      { "name": "Non GMO kobold barberry purees", "code": "50561812" },
      { "name": "Non GMO warty barberry purees", "code": "50561813" },
      { "name": "Non GMO european barberry purees", "code": "50561814" },
      { "name": "Non GMO blackberry purees", "code": "50561900" },
      { "name": "Non GMO apache blackberry purees", "code": "50561901" },
      { "name": "Non GMO black satin blackberry purees", "code": "50561902" },
      { "name": "Non GMO boysenberry purees", "code": "50561903" },
      { "name": "Non GMO cherokee blackberry purees", "code": "50561904" },
      { "name": "Non GMO chester blackberry purees", "code": "50561905" },
      { "name": "Non GMO dirksen blackberry purees", "code": "50561906" },
      { "name": "Non GMO jostaberry purees", "code": "50561907" },
      { "name": "Non GMO loganberry purees", "code": "50561908" },
      { "name": "Non GMO marionberry purees", "code": "50561909" },
      { "name": "Non GMO navaho blackberry purees", "code": "50561910" },
      { "name": "Non GMO nectarberry purees", "code": "50561911" },
      { "name": "Non GMO olallie blackberry purees", "code": "50561912" },
      { "name": "Non GMO tayberry purees", "code": "50561913" },
      {
        "name": "Non GMO thornless hull blackberry purees",
        "code": "50561914"
      },
      { "name": "Non GMO youngberry purees", "code": "50561915" },
      { "name": "Non GMO billberry purees", "code": "50562000" },
      { "name": "Non GMO bog bilberry purees", "code": "50562001" },
      { "name": "Non GMO dwarf bilberry purees", "code": "50562002" },
      { "name": "Non GMO mountain bilberry purees", "code": "50562003" },
      { "name": "Non GMO oval-leaved bilberry purees", "code": "50562004" },
      { "name": "Non GMO blueberry purees", "code": "50562100" },
      { "name": "Non GMO bluetta blueberry purees", "code": "50562101" },
      { "name": "Non GMO duke blueberry purees", "code": "50562102" },
      { "name": "Non GMO spartan blueberry purees", "code": "50562103" },
      { "name": "Non GMO patriot blueberry purees", "code": "50562104" },
      { "name": "Non GMO toro blueberry purees", "code": "50562105" },
      { "name": "Non GMO hardyblue blueberry purees", "code": "50562106" },
      { "name": "Non GMO bluecrop blueberry purees", "code": "50562107" },
      { "name": "Non GMO legacy blueberry purees", "code": "50562108" },
      { "name": "Non GMO nelson blueberry purees", "code": "50562109" },
      { "name": "Non GMO chandler blueberry purees", "code": "50562110" },
      { "name": "Non GMO brigitta blueberry purees", "code": "50562111" },
      { "name": "Non GMO northcountry blueberry purees", "code": "50562112" },
      { "name": "Non GMO northsky blueberry purees", "code": "50562113" },
      { "name": "Non GMO northblue blueberry purees", "code": "50562114" },
      { "name": "Non GMO misty blueberry purees", "code": "50562115" },
      { "name": "Non GMO breadfruit purees", "code": "50562200" },
      { "name": "Non GMO chataigne breadfruit purees", "code": "50562201" },
      { "name": "Non GMO seedless breadfruit purees", "code": "50562202" },
      { "name": "Non GMO white heart breadfruit purees", "code": "50562203" },
      { "name": "Non GMO yellow heart breadfruit purees", "code": "50562204" },
      { "name": "Non GMO cherimoya purees", "code": "50562300" },
      { "name": "Non GMO bays cherimoy purees", "code": "50562301" },
      { "name": "Non GMO bronceada cherimoy purees", "code": "50562302" },
      { "name": "Non GMO burtons cherimoy purees", "code": "50562303" },
      {
        "name": "Non GMO burtons favorite cherimoy purees",
        "code": "50562304"
      },
      { "name": "Non GMO jete cherimoy purees", "code": "50562305" },
      { "name": "Non GMO reretai cherimoy purees", "code": "50562306" },
      { "name": "Non GMO smoothey cherimoy purees", "code": "50562307" },
      { "name": "Non GMO spain cherimoy purees", "code": "50562308" },
      { "name": "Non GMO white cherimoy purees", "code": "50562309" },
      { "name": "Non GMO cherry purees", "code": "50562400" },
      { "name": "Non GMO amarelle cherry purees", "code": "50562401" },
      { "name": "Non GMO brooks cherry purees", "code": "50562402" },
      { "name": "Non GMO bigarreu cherry purees", "code": "50562403" },
      { "name": "Non GMO bing cherry purees", "code": "50562404" },
      { "name": "Non GMO black republic cherry purees", "code": "50562405" },
      { "name": "Non GMO black schmidt cherry purees", "code": "50562406" },
      { "name": "Non GMO black tartarian cherry purees", "code": "50562407" },
      { "name": "Non GMO fiesta bing cherry purees", "code": "50562408" },
      { "name": "Non GMO garnet cherry purees", "code": "50562409" },
      { "name": "Non GMO king cherry purees", "code": "50562410" },
      { "name": "Non GMO chapman cherry purees", "code": "50562411" },
      { "name": "Non GMO lapin cherry purees", "code": "50562412" },
      { "name": "Non GMO larian cherry purees", "code": "50562413" },
      { "name": "Non GMO dark guines cherry purees", "code": "50562414" },
      { "name": "Non GMO montmorency cherry purees", "code": "50562415" },
      { "name": "Non GMO duke cherry purees", "code": "50562416" },
      { "name": "Non GMO early rivers cherry purees", "code": "50562417" },
      { "name": "Non GMO ruby bing cherry purees", "code": "50562418" },
      { "name": "Non GMO santina cherry purees", "code": "50562419" },
      { "name": "Non GMO geans guines cherry purees", "code": "50562420" },
      { "name": "Non GMO sonata cherry purees", "code": "50562421" },
      { "name": "Non GMO lambert cherry purees", "code": "50562422" },
      { "name": "Non GMO stella cherry purees", "code": "50562423" },
      { "name": "Non GMO sweetheart cherry purees", "code": "50562424" },
      { "name": "Non GMO tartarian cherry purees", "code": "50562425" },
      { "name": "Non GMO maraschino cherry purees", "code": "50562426" },
      { "name": "Non GMO van cherry purees", "code": "50562427" },
      { "name": "Non GMO morello cherry purees", "code": "50562428" },
      { "name": "Non GMO royal ann cherry purees", "code": "50562429" },
      { "name": "Non GMO ranier cherry purees", "code": "50562430" },
      { "name": "Non GMO royal cherry purees", "code": "50562431" },
      { "name": "Non GMO citron purees", "code": "50562500" },
      { "name": "Non GMO buddha's hand citron purees", "code": "50562501" },
      { "name": "Non GMO fingered citron purees", "code": "50562502" },
      { "name": "Non GMO fo shoukan citron purees", "code": "50562503" },
      { "name": "Non GMO bushakan citron purees", "code": "50562504" },
      { "name": "Non GMO diamante citron purees", "code": "50562505" },
      { "name": "Non GMO etrog citron purees", "code": "50562506" },
      { "name": "Non GMO ponderosa citron purees", "code": "50562507" },
      { "name": "Non GMO cranberry purees", "code": "50562600" },
      { "name": "Non GMO ben lear cranberry purees", "code": "50562601" },
      { "name": "Non GMO early black cranberry purees", "code": "50562602" },
      { "name": "Non GMO grycleski cranberry purees", "code": "50562603" },
      { "name": "Non GMO howe cranberry purees", "code": "50562604" },
      { "name": "Non GMO lingonberry purees", "code": "50562605" },
      { "name": "Non GMO mcfarlin cranberry purees", "code": "50562606" },
      { "name": "Non GMO mountain cranberry purees", "code": "50562607" },
      { "name": "Non GMO pilgrim cranberry purees", "code": "50562608" },
      { "name": "Non GMO searless cranberry purees", "code": "50562609" },
      { "name": "Non GMO stevens cranberry purees", "code": "50562610" },
      { "name": "Non GMO currant purees", "code": "50562700" },
      { "name": "Non GMO hudson bay currant purees", "code": "50562701" },
      { "name": "Non GMO waxy currant purees", "code": "50562702" },
      { "name": "Non GMO desert currant purees", "code": "50562703" },
      { "name": "Non GMO black currant purees", "code": "50562704" },
      { "name": "Non GMO red currant purees", "code": "50562705" },
      { "name": "Non GMO white currant purees", "code": "50562706" },
      { "name": "Non GMO date purees", "code": "50562800" },
      { "name": "Non GMO asharasi date purees", "code": "50562801" },
      { "name": "Non GMO barhi or barhee date purees", "code": "50562802" },
      { "name": "Non GMO deglet noor date purees", "code": "50562803" },
      { "name": "Non GMO fardh date purees", "code": "50562804" },
      { "name": "Non GMO gundila date purees", "code": "50562805" },
      { "name": "Non GMO halawi halawy date purees", "code": "50562806" },
      { "name": "Non GMO hilali date purees", "code": "50562807" },
      { "name": "Non GMO khadrawi khadrawy date purees", "code": "50562808" },
      { "name": "Non GMO khalas date purees", "code": "50562809" },
      { "name": "Non GMO khustawi date purees", "code": "50562810" },
      { "name": "Non GMO khidri date purees", "code": "50562811" },
      { "name": "Non GMO medjool medjul date purees", "code": "50562812" },
      { "name": "Non GMO mactoum date purees", "code": "50562813" },
      { "name": "Non GMO neghal date purees", "code": "50562814" },
      { "name": "Non GMO yatimeh date purees", "code": "50562815" },
      { "name": "Non GMO zahidi date purees", "code": "50562816" },
      { "name": "Non GMO fig purees", "code": "50562900" },
      { "name": "Non GMO bardajic fig purees", "code": "50562901" },
      { "name": "Non GMO brown turkey fig purees", "code": "50562902" },
      { "name": "Non GMO calimyrna fig purees", "code": "50562903" },
      { "name": "Non GMO conadria fig purees", "code": "50562904" },
      { "name": "Non GMO dottado fig purees", "code": "50562905" },
      { "name": "Non GMO kadota fig purees", "code": "50562906" },
      { "name": "Non GMO mediterranean fig purees", "code": "50562907" },
      { "name": "Non GMO mission fig purees", "code": "50562908" },
      { "name": "Non GMO smyrna fig purees", "code": "50562909" },
      { "name": "Non GMO verdona fig purees", "code": "50562910" },
      { "name": "Non GMO white king fig purees", "code": "50562911" },
      { "name": "Non GMO gooseberry purees", "code": "50563000" },
      { "name": "Non GMO early sulphur gooseberry purees", "code": "50563001" },
      { "name": "Non GMO goldendrop gooseberry purees", "code": "50563002" },
      { "name": "Non GMO langley gage gooseberry purees", "code": "50563003" },
      { "name": "Non GMO leveller gooseberry purees", "code": "50563004" },
      { "name": "Non GMO london gooseberry purees", "code": "50563005" },
      {
        "name": "Non GMO worcestershire gooseberry purees",
        "code": "50563006"
      },
      {
        "name": "Non GMO american worcesterberry gooseberry purees",
        "code": "50563007"
      },
      { "name": "Non GMO grapefruit purees", "code": "50563100" },
      { "name": "Non GMO burgundy grapefruit purees", "code": "50563101" },
      { "name": "Non GMO duncan grapefruit purees", "code": "50563102" },
      { "name": "Non GMO foster grapefruit purees", "code": "50563103" },
      { "name": "Non GMO marsh grapefruit purees", "code": "50563104" },
      { "name": "Non GMO new zealand grapefruit purees", "code": "50563105" },
      { "name": "Non GMO rio red grapefruit purees", "code": "50563106" },
      { "name": "Non GMO ruby red grapefruit purees", "code": "50563107" },
      { "name": "Non GMO star ruby grapefruit purees", "code": "50563108" },
      { "name": "Non GMO triumph grapefruit purees", "code": "50563109" },
      { "name": "Non GMO table grape purees", "code": "50563200" },
      { "name": "Non GMO alicante grape purees", "code": "50563201" },
      { "name": "Non GMO fiesta grape purees", "code": "50563202" },
      { "name": "Non GMO almeria grape purees", "code": "50563203" },
      { "name": "Non GMO black corinth grape purees", "code": "50563204" },
      { "name": "Non GMO selma pete grape purees", "code": "50563205" },
      { "name": "Non GMO alphonse lavalle grape purees", "code": "50563206" },
      { "name": "Non GMO canner grape purees", "code": "50563207" },
      { "name": "Non GMO sultana grape purees", "code": "50563208" },
      { "name": "Non GMO autumn king grape purees", "code": "50563209" },
      { "name": "Non GMO dovine grape purees", "code": "50563210" },
      { "name": "Non GMO autumn royal grape purees", "code": "50563211" },
      { "name": "Non GMO autumn seedless grape purees", "code": "50563212" },
      { "name": "Non GMO baresana grape purees", "code": "50563213" },
      { "name": "Non GMO barlinka grape purees", "code": "50563214" },
      { "name": "Non GMO beauty seedless grape purees", "code": "50563215" },
      {
        "name": "Non GMO black beauty seedless grape purees",
        "code": "50563216"
      },
      { "name": "Non GMO black emerald grape purees", "code": "50563217" },
      { "name": "Non GMO black giant grape purees", "code": "50563218" },
      { "name": "Non GMO black globe grape purees", "code": "50563219" },
      { "name": "Non GMO black monukka grape purees", "code": "50563220" },
      { "name": "Non GMO black pearl grape purees", "code": "50563221" },
      { "name": "Non GMO black seedless grape purees", "code": "50563222" },
      { "name": "Non GMO bonheur grape purees", "code": "50563223" },
      { "name": "Non GMO calmeria grape purees", "code": "50563224" },
      { "name": "Non GMO cardinal grape purees", "code": "50563225" },
      { "name": "Non GMO catawba grape purees", "code": "50563226" },
      {
        "name": "Non GMO chasselas golden chasselas grape purees",
        "code": "50563227"
      },
      { "name": "Non GMO christmas rose grape purees", "code": "50563228" },
      { "name": "Non GMO concord grape purees", "code": "50563229" },
      { "name": "Non GMO concord seedless grape purees", "code": "50563230" },
      { "name": "Non GMO crimson seedless grape purees", "code": "50563231" },
      { "name": "Non GMO dauphine grape purees", "code": "50563232" },
      { "name": "Non GMO delaware grape purees", "code": "50563233" },
      { "name": "Non GMO early muscat grape purees", "code": "50563234" },
      { "name": "Non GMO early sweet grape purees", "code": "50563235" },
      { "name": "Non GMO emerald seedless grape purees", "code": "50563236" },
      { "name": "Non GMO emperatriz grape purees", "code": "50563237" },
      { "name": "Non GMO emperor grape purees", "code": "50563238" },
      { "name": "Non GMO empress grape purees", "code": "50563239" },
      { "name": "Non GMO exotic grape purees", "code": "50563240" },
      { "name": "Non GMO fantasy grape purees", "code": "50563241" },
      { "name": "Non GMO fantasy seedless grape purees", "code": "50563242" },
      { "name": "Non GMO flame grape purees", "code": "50563243" },
      { "name": "Non GMO flame seedless grape purees", "code": "50563244" },
      { "name": "Non GMO flame tokay grape purees", "code": "50563245" },
      { "name": "Non GMO flaming red grape purees", "code": "50563246" },
      { "name": "Non GMO galaxy seedless grape purees", "code": "50563247" },
      { "name": "Non GMO gamay grape purees", "code": "50563248" },
      { "name": "Non GMO gold grape purees", "code": "50563249" },
      {
        "name": "Non GMO hanepoot or honeypot grape purees",
        "code": "50563250"
      },
      { "name": "Non GMO italia grape purees", "code": "50563251" },
      { "name": "Non GMO jade seedless grape purees", "code": "50563252" },
      { "name": "Non GMO jubilee grape purees", "code": "50563253" },
      { "name": "Non GMO king ruby grape purees", "code": "50563254" },
      { "name": "Non GMO kyoho grape purees", "code": "50563255" },
      { "name": "Non GMO la rochelle grape purees", "code": "50563256" },
      { "name": "Non GMO lady finger grape purees", "code": "50563257" },
      { "name": "Non GMO late seedless grape purees", "code": "50563258" },
      { "name": "Non GMO majestic seedless grape purees", "code": "50563259" },
      { "name": "Non GMO malaga grape purees", "code": "50563260" },
      { "name": "Non GMO marroot seedless grape purees", "code": "50563261" },
      { "name": "Non GMO muscadine grape purees", "code": "50563262" },
      { "name": "Non GMO muscat flame grape purees", "code": "50563263" },
      { "name": "Non GMO muscat grape purees", "code": "50563264" },
      { "name": "Non GMO muscat seedless grape purees", "code": "50563265" },
      { "name": "Non GMO napoleon grape purees", "code": "50563266" },
      { "name": "Non GMO negria grape purees", "code": "50563267" },
      { "name": "Non GMO new cross grape purees", "code": "50563268" },
      { "name": "Non GMO niabell grape purees", "code": "50563269" },
      { "name": "Non GMO niagara grape purees", "code": "50563270" },
      { "name": "Non GMO olivette grape purees", "code": "50563271" },
      { "name": "Non GMO perlette grape purees", "code": "50563272" },
      { "name": "Non GMO perlon grape purees", "code": "50563273" },
      {
        "name": "Non GMO prima black seedless grape purees",
        "code": "50563274"
      },
      { "name": "Non GMO princess grape purees", "code": "50563275" },
      { "name": "Non GMO queen grape purees", "code": "50563276" },
      { "name": "Non GMO red blush grape purees", "code": "50563277" },
      { "name": "Non GMO red globe grape purees", "code": "50563278" },
      { "name": "Non GMO red malaga grape purees", "code": "50563279" },
      { "name": "Non GMO red seedless grape purees", "code": "50563280" },
      { "name": "Non GMO regina grape purees", "code": "50563281" },
      { "name": "Non GMO ribier grape purees", "code": "50563282" },
      { "name": "Non GMO rosita grape purees", "code": "50563283" },
      { "name": "Non GMO rouge grape purees", "code": "50563284" },
      {
        "name": "Non GMO royal black seedless grape purees",
        "code": "50563285"
      },
      { "name": "Non GMO ruby red seedless grape purees", "code": "50563286" },
      { "name": "Non GMO ruby seedless grape purees", "code": "50563287" },
      { "name": "Non GMO scarlet royal grape purees", "code": "50563288" },
      { "name": "Non GMO scuppernong grape purees", "code": "50563289" },
      { "name": "Non GMO sugarose grape purees", "code": "50563290" },
      { "name": "Non GMO sugarthirteen grape purees", "code": "50563291" },
      { "name": "Non GMO sugraone grape purees", "code": "50563292" },
      { "name": "Non GMO sugrasixteen grape purees", "code": "50563293" },
      { "name": "Non GMO sultana sun red grape purees", "code": "50563294" },
      { "name": "Non GMO summer royal grape purees", "code": "50563295" },
      { "name": "Non GMO sunset grape purees", "code": "50563296" },
      { "name": "Non GMO superior seedless grape purees", "code": "50563297" },
      { "name": "Non GMO thompson seedless grape purees", "code": "50563298" },
      { "name": "Non GMO tokay pinot gris grape purees", "code": "50563299" },
      { "name": "Non GMO wine grape purees", "code": "50563300" },
      { "name": "Non GMO alicante bouschet grape purees", "code": "50563301" },
      { "name": "Non GMO barbera grape purees", "code": "50563302" },
      { "name": "Non GMO burger grape purees", "code": "50563303" },
      { "name": "Non GMO cabernet franc grape purees", "code": "50563304" },
      { "name": "Non GMO cabernet sauvignon grape purees", "code": "50563305" },
      { "name": "Non GMO carignane grape purees", "code": "50563306" },
      { "name": "Non GMO carnelian grape purees", "code": "50563307" },
      { "name": "Non GMO catarratto grape purees", "code": "50563308" },
      { "name": "Non GMO centurian grape purees", "code": "50563309" },
      { "name": "Non GMO charbono grape purees", "code": "50563310" },
      { "name": "Non GMO chardonnay grape purees", "code": "50563311" },
      { "name": "Non GMO chenin blanc grape purees", "code": "50563312" },
      { "name": "Non GMO cinsaut grape purees", "code": "50563313" },
      { "name": "Non GMO dolcetto grape purees", "code": "50563314" },
      { "name": "Non GMO emerald riesling grape purees", "code": "50563315" },
      { "name": "Non GMO french colombard grape purees", "code": "50563316" },
      { "name": "Non GMO gamay or napa grape purees", "code": "50563317" },
      { "name": "Non GMO gamay beaujolais grape purees", "code": "50563318" },
      { "name": "Non GMO gewurztraminer grape purees", "code": "50563319" },
      { "name": "Non GMO grenache grape purees", "code": "50563320" },
      { "name": "Non GMO grenache blanc grape purees", "code": "50563321" },
      { "name": "Non GMO lagrein grape purees", "code": "50563322" },
      { "name": "Non GMO lambrusco grape purees", "code": "50563323" },
      { "name": "Non GMO malbec grape purees", "code": "50563324" },
      { "name": "Non GMO malvasia bianca grape purees", "code": "50563325" },
      { "name": "Non GMO marsanne grape purees", "code": "50563326" },
      { "name": "Non GMO mataro grape purees", "code": "50563327" },
      { "name": "Non GMO merlot grape purees", "code": "50563328" },
      { "name": "Non GMO meunier grape purees", "code": "50563329" },
      { "name": "Non GMO mission grape purees", "code": "50563330" },
      { "name": "Non GMO montepulciano grape purees", "code": "50563331" },
      { "name": "Non GMO muscat blanc grape purees", "code": "50563332" },
      { "name": "Non GMO muscat hamburg grape purees", "code": "50563333" },
      {
        "name": "Non GMO muscat of alexandria grape purees",
        "code": "50563334"
      },
      { "name": "Non GMO muscat orange grape purees", "code": "50563335" },
      { "name": "Non GMO nebbiolo grape purees", "code": "50563336" },
      { "name": "Non GMO palomino grape purees", "code": "50563337" },
      { "name": "Non GMO petit verdot grape purees", "code": "50563338" },
      { "name": "Non GMO petite sirah grape purees", "code": "50563339" },
      { "name": "Non GMO pinot blanc grape purees", "code": "50563340" },
      { "name": "Non GMO pinot gris grape purees", "code": "50563341" },
      { "name": "Non GMO pinot noir grape purees", "code": "50563342" },
      { "name": "Non GMO primitivo grape purees", "code": "50563343" },
      { "name": "Non GMO roussanne grape purees", "code": "50563344" },
      { "name": "Non GMO royalty grape purees", "code": "50563345" },
      { "name": "Non GMO rubired grape purees", "code": "50563346" },
      { "name": "Non GMO ruby cabernet grape purees", "code": "50563347" },
      { "name": "Non GMO salvador grape purees", "code": "50563348" },
      { "name": "Non GMO sangiovese grape purees", "code": "50563349" },
      { "name": "Non GMO sauvignon blanc grape purees", "code": "50563350" },
      { "name": "Non GMO sauvignon musque grape purees", "code": "50563351" },
      { "name": "Non GMO semillon grape purees", "code": "50563352" },
      { "name": "Non GMO souzao grape purees", "code": "50563353" },
      { "name": "Non GMO st emilion grape purees", "code": "50563354" },
      { "name": "Non GMO symphony grape purees", "code": "50563355" },
      { "name": "Non GMO syrah grape purees", "code": "50563356" },
      { "name": "Non GMO tannat grape purees", "code": "50563357" },
      { "name": "Non GMO tempranillo grape purees", "code": "50563358" },
      { "name": "Non GMO teroldego grape purees", "code": "50563359" },
      { "name": "Non GMO tocai friulano grape purees", "code": "50563360" },
      { "name": "Non GMO touriga nacional grape purees", "code": "50563361" },
      { "name": "Non GMO triplett blanc grape purees", "code": "50563362" },
      { "name": "Non GMO viognier grape purees", "code": "50563363" },
      { "name": "Non GMO white riesling grape purees", "code": "50563364" },
      { "name": "Non GMO zinfandel grape purees", "code": "50563365" },
      { "name": "Non GMO guava purees", "code": "50563500" },
      { "name": "Non GMO beaumont guava purees", "code": "50563501" },
      { "name": "Non GMO carrley guava purees", "code": "50563502" },
      { "name": "Non GMO lucida guava purees", "code": "50563503" },
      { "name": "Non GMO pineapple guav purees", "code": "50563504" },
      { "name": "Non GMO huckleberry purees", "code": "50563600" },
      { "name": "Non GMO black winter huckleberry purees", "code": "50563601" },
      { "name": "Non GMO cascade huckleberry purees", "code": "50563602" },
      { "name": "Non GMO dwarf huckleberry purees", "code": "50563603" },
      { "name": "Non GMO mountain huckleberry purees", "code": "50563604" },
      { "name": "Non GMO red huckleberry purees", "code": "50563605" },
      { "name": "Non GMO kiwi fruit purees", "code": "50563700" },
      { "name": "Non GMO ananasnaja kiwi fruit purees", "code": "50563701" },
      { "name": "Non GMO arctic beauty kiwi fruit purees", "code": "50563702" },
      { "name": "Non GMO blake kiwi fruit purees", "code": "50563703" },
      { "name": "Non GMO hayward kiwi fruit purees", "code": "50563704" },
      { "name": "Non GMO issai kiwi fruit purees", "code": "50563705" },
      { "name": "Non GMO siberian kiwi fruit purees", "code": "50563706" },
      { "name": "Non GMO kumquat purees", "code": "50563800" },
      { "name": "Non GMO hong kong kumquat purees", "code": "50563801" },
      { "name": "Non GMO limequat kumquat purees", "code": "50563802" },
      { "name": "Non GMO long fruit kumquat purees", "code": "50563803" },
      { "name": "Non GMO malayan kumquat purees", "code": "50563804" },
      { "name": "Non GMO meiwa kumquat purees", "code": "50563805" },
      { "name": "Non GMO nagami kumquat purees", "code": "50563806" },
      { "name": "Non GMO lemon purees", "code": "50563900" },
      { "name": "Non GMO baboon lemon purees", "code": "50563901" },
      { "name": "Non GMO bearss sicilian lemon purees", "code": "50563902" },
      { "name": "Non GMO cameron highlands lemon purees", "code": "50563903" },
      { "name": "Non GMO escondido lemon purees", "code": "50563904" },
      { "name": "Non GMO eureka lemon purees", "code": "50563905" },
      { "name": "Non GMO lisbon lemon purees", "code": "50563906" },
      { "name": "Non GMO meyer lemon purees", "code": "50563907" },
      { "name": "Non GMO volkamer lemon purees", "code": "50563908" },
      { "name": "Non GMO lime purees", "code": "50564000" },
      { "name": "Non GMO indian sweet lime purees", "code": "50564001" },
      { "name": "Non GMO key lime purees", "code": "50564002" },
      { "name": "Non GMO mandarin lime purees", "code": "50564003" },
      { "name": "Non GMO philippine lime purees", "code": "50564004" },
      { "name": "Non GMO tahitian lime purees", "code": "50564005" },
      { "name": "Non GMO bearss lime purees", "code": "50564006" },
      { "name": "Non GMO persian lime purees", "code": "50564007" },
      { "name": "Non GMO seedless lime purees", "code": "50564008" },
      { "name": "Non GMO loquat purees", "code": "50564100" },
      { "name": "Non GMO advance loquat purees", "code": "50564101" },
      { "name": "Non GMO benlehr loquat purees", "code": "50564102" },
      { "name": "Non GMO big jim loquat purees", "code": "50564103" },
      { "name": "Non GMO champagne loquat purees", "code": "50564104" },
      { "name": "Non GMO early red loquat purees", "code": "50564105" },
      { "name": "Non GMO gold nugget loquat purees", "code": "50564106" },
      { "name": "Non GMO herd's mammoth loquat purees", "code": "50564107" },
      { "name": "Non GMO mogi loquat purees", "code": "50564108" },
      { "name": "Non GMO mrs cooksey loquat purees", "code": "50564109" },
      { "name": "Non GMO strawberry loquat purees", "code": "50564110" },
      { "name": "Non GMO tanaka loquat purees", "code": "50564111" },
      {
        "name": "Non GMO victory vista white loquat purees",
        "code": "50564112"
      },
      { "name": "Non GMO wolfe loquat purees", "code": "50564113" },
      {
        "name": "Non GMO mandarin oranges or Non GMO tangerine purees",
        "code": "50564200"
      },
      { "name": "Non GMO clauselinas orange purees", "code": "50564201" },
      { "name": "Non GMO clementine tangerine purees", "code": "50564202" },
      {
        "name": "Non GMO cleopatra mandarin orange purees",
        "code": "50564203"
      },
      { "name": "Non GMO dancy tangerine purees", "code": "50564204" },
      { "name": "Non GMO ellensdale orange purees", "code": "50564205" },
      { "name": "Non GMO fairchild orange purees", "code": "50564206" },
      { "name": "Non GMO fallglo orange purees", "code": "50564207" },
      { "name": "Non GMO fortune orange purees", "code": "50564208" },
      { "name": "Non GMO fremont mandarin orange purees", "code": "50564209" },
      { "name": "Non GMO fremont orange purees", "code": "50564210" },
      { "name": "Non GMO golden nugget orange purees", "code": "50564211" },
      { "name": "Non GMO honey mandarin orange purees", "code": "50564212" },
      { "name": "Non GMO honey orange purees", "code": "50564213" },
      { "name": "Non GMO honey tangerine purees", "code": "50564214" },
      {
        "name": "Non GMO honeybelle tangelo orange purees",
        "code": "50564215"
      },
      { "name": "Non GMO king mandarin orange purees", "code": "50564216" },
      { "name": "Non GMO kinnow orange purees", "code": "50564217" },
      { "name": "Non GMO lee mandarin orange purees", "code": "50564218" },
      { "name": "Non GMO makokkee orange purees", "code": "50564219" },
      { "name": "Non GMO malvasios orange purees", "code": "50564220" },
      {
        "name": "Non GMO mediterranean mandarin orange purees",
        "code": "50564221"
      },
      { "name": "Non GMO minneola tangelo orange purees", "code": "50564222" },
      { "name": "Non GMO monica orange purees", "code": "50564223" },
      { "name": "Non GMO murcott honey orange purees", "code": "50564224" },
      { "name": "Non GMO murcott tangor purees", "code": "50564225" },
      {
        "name": "Non GMO natsudaidai mandarin orange purees",
        "code": "50564226"
      },
      {
        "name": "Non GMO natsumikan mandarin orange purees",
        "code": "50564227"
      },
      { "name": "Non GMO nocatee tangelo orange purees", "code": "50564228" },
      { "name": "Non GMO orlando tangelo orange purees", "code": "50564229" },
      { "name": "Non GMO ortanique tangerine purees", "code": "50564230" },
      { "name": "Non GMO page mandarin orange purees", "code": "50564231" },
      { "name": "Non GMO pixie orange purees", "code": "50564232" },
      {
        "name": "Non GMO ponkan bantangas mandarin orange purees",
        "code": "50564233"
      },
      { "name": "Non GMO reyna orange purees", "code": "50564234" },
      { "name": "Non GMO robinson orange purees", "code": "50564235" },
      { "name": "Non GMO saltenitas orange purees", "code": "50564236" },
      { "name": "Non GMO sampson tangelo orange purees", "code": "50564237" },
      { "name": "Non GMO satsuma mandarin orange purees", "code": "50564238" },
      { "name": "Non GMO sunburst mandarin orange purees", "code": "50564239" },
      { "name": "Non GMO tangelo purees", "code": "50564300" },
      { "name": "Non GMO tangerina orange purees", "code": "50564301" },
      { "name": "Non GMO temple orange purees", "code": "50564302" },
      { "name": "Non GMO thornton orange purees", "code": "50564303" },
      { "name": "Non GMO wekiwa tangerine purees", "code": "50564304" },
      { "name": "Non GMO wilkins tangerine purees", "code": "50564305" },
      {
        "name": "Non GMO willowleaf mediterranean tangerine purees",
        "code": "50564306"
      },
      { "name": "Non GMO mango purees", "code": "50564400" },
      { "name": "Non GMO alphonso mango purees", "code": "50564401" },
      { "name": "Non GMO ataulfo mango purees", "code": "50564402" },
      { "name": "Non GMO criollo mango purees", "code": "50564403" },
      { "name": "Non GMO edwards mango purees", "code": "50564404" },
      { "name": "Non GMO francine mango purees", "code": "50564405" },
      { "name": "Non GMO francis mango purees", "code": "50564406" },
      { "name": "Non GMO gandaria mango purees", "code": "50564407" },
      { "name": "Non GMO haden mango purees", "code": "50564408" },
      { "name": "Non GMO irwin mango purees", "code": "50564409" },
      { "name": "Non GMO keitt mango purees", "code": "50564410" },
      { "name": "Non GMO kent mango purees", "code": "50564411" },
      { "name": "Non GMO kesar mango purees", "code": "50564412" },
      { "name": "Non GMO kuini mango purees", "code": "50564413" },
      { "name": "Non GMO manila super mango purees", "code": "50564414" },
      { "name": "Non GMO manila mango purees", "code": "50564415" },
      { "name": "Non GMO mayaguez mango purees", "code": "50564416" },
      { "name": "Non GMO mulgoba mango purees", "code": "50564417" },
      { "name": "Non GMO oro mango purees", "code": "50564418" },
      { "name": "Non GMO palmer mango purees", "code": "50564419" },
      { "name": "Non GMO parvin mango purees", "code": "50564420" },
      { "name": "Non GMO sandersha mango purees", "code": "50564421" },
      { "name": "Non GMO sensation mango purees", "code": "50564422" },
      { "name": "Non GMO smith mango purees", "code": "50564423" },
      { "name": "Non GMO tommy atkins mango purees", "code": "50564424" },
      { "name": "Non GMO van dyke mango purees", "code": "50564425" },
      { "name": "Non GMO melon purees", "code": "50564500" },
      { "name": "Non GMO allsweet melon purees", "code": "50564501" },
      { "name": "Non GMO athena melon purees", "code": "50564502" },
      { "name": "Non GMO black diamond melon purees", "code": "50564503" },
      { "name": "Non GMO cal sweet melon purees", "code": "50564504" },
      { "name": "Non GMO cantaloupe melon purees", "code": "50564505" },
      { "name": "Non GMO carnical melon purees", "code": "50564506" },
      { "name": "Non GMO casaba melon purees", "code": "50564507" },
      { "name": "Non GMO cavaillon melon purees", "code": "50564508" },
      { "name": "Non GMO charentais melon purees", "code": "50564509" },
      {
        "name": "Non GMO charleston gray watermelon purees",
        "code": "50564510"
      },
      { "name": "Non GMO crenshaw melon purees", "code": "50564511" },
      { "name": "Non GMO crimson sweet melon purees", "code": "50564512" },
      { "name": "Non GMO dixie lee melon purees", "code": "50564513" },
      { "name": "Non GMO eclipse melon purees", "code": "50564514" },
      { "name": "Non GMO ein d'or melon purees", "code": "50564515" },
      { "name": "Non GMO fiesta melon purees", "code": "50564516" },
      { "name": "Non GMO galia melon purees", "code": "50564517" },
      { "name": "Non GMO gaya melon purees", "code": "50564518" },
      { "name": "Non GMO hami melon purees", "code": "50564519" },
      { "name": "Non GMO honeydew melon purees", "code": "50564520" },
      { "name": "Non GMO icebox melon purees", "code": "50564521" },
      { "name": "Non GMO ida pride melon purees", "code": "50564522" },
      { "name": "Non GMO juan canary melon purees", "code": "50564523" },
      { "name": "Non GMO jubilee melon purees", "code": "50564524" },
      { "name": "Non GMO jubilation melon purees", "code": "50564525" },
      { "name": "Non GMO kakhi kakri melon purees", "code": "50564526" },
      { "name": "Non GMO kiwano melon purees", "code": "50564527" },
      { "name": "Non GMO korean melon purees", "code": "50564528" },
      { "name": "Non GMO long gray melon purees", "code": "50564529" },
      { "name": "Non GMO mayan melon purees", "code": "50564530" },
      { "name": "Non GMO micky lee melon purees", "code": "50564531" },
      { "name": "Non GMO mirage melon purees", "code": "50564532" },
      {
        "name": "Non GMO moon and stars watermelon purees",
        "code": "50564533"
      },
      { "name": "Non GMO ogen melon purees", "code": "50564534" },
      { "name": "Non GMO patriot melon purees", "code": "50564535" },
      { "name": "Non GMO peacock melon purees", "code": "50564536" },
      { "name": "Non GMO pepino melon purees", "code": "50564537" },
      { "name": "Non GMO persian melon purees", "code": "50564538" },
      { "name": "Non GMO picnic melon purees", "code": "50564539" },
      { "name": "Non GMO piel de sapo melon purees", "code": "50564540" },
      { "name": "Non GMO pineapple melon purees", "code": "50564541" },
      { "name": "Non GMO quetzali melon purees", "code": "50564542" },
      { "name": "Non GMO red goblin melon purees", "code": "50564543" },
      { "name": "Non GMO regency melon purees", "code": "50564544" },
      { "name": "Non GMO royal majestic melon purees", "code": "50564545" },
      { "name": "Non GMO royal star melon purees", "code": "50564546" },
      { "name": "Non GMO royal sweet melon purees", "code": "50564547" },
      { "name": "Non GMO santa claus melon purees", "code": "50564548" },
      { "name": "Non GMO sharlyn melon purees", "code": "50564549" },
      { "name": "Non GMO spanish melon purees", "code": "50564550" },
      { "name": "Non GMO sprite melon purees", "code": "50564551" },
      { "name": "Non GMO starbright melon purees", "code": "50564552" },
      { "name": "Non GMO stars n stripes melon purees", "code": "50564553" },
      { "name": "Non GMO sugar baby melon purees", "code": "50564554" },
      { "name": "Non GMO sugar baby watermelon purees", "code": "50564555" },
      { "name": "Non GMO sunsweet melon purees", "code": "50564556" },
      {
        "name": "Non GMO sweet heart seedless watermelon purees",
        "code": "50564557"
      },
      { "name": "Non GMO temptation melon purees", "code": "50564558" },
      { "name": "Non GMO tiger baby melon purees", "code": "50564559" },
      { "name": "Non GMO tuscan type melon purees", "code": "50564560" },
      { "name": "Non GMO yellow baby watermelon purees", "code": "50564561" },
      { "name": "Non GMO mulberry purees", "code": "50564600" },
      { "name": "Non GMO black mulberry purees", "code": "50564601" },
      { "name": "Non GMO white mulberry purees", "code": "50564602" },
      { "name": "Non GMO bayberry and myrtle purees", "code": "50564700" },
      { "name": "Non GMO bog myrtle purees", "code": "50564701" },
      { "name": "Non GMO bayberry purees", "code": "50564702" },
      { "name": "Non GMO nectarine purees", "code": "50564800" },
      { "name": "Non GMO april glo nectarine purees", "code": "50564801" },
      { "name": "Non GMO arctic mist nectarine purees", "code": "50564802" },
      { "name": "Non GMO arctic snow nectarine purees", "code": "50564803" },
      { "name": "Non GMO arctic star nectarine purees", "code": "50564804" },
      { "name": "Non GMO arctic sweet nectarine purees", "code": "50564805" },
      { "name": "Non GMO arctic glo nectarine purees", "code": "50564806" },
      { "name": "Non GMO august fire nectarine purees", "code": "50564807" },
      { "name": "Non GMO august pearl nectarine purees", "code": "50564808" },
      { "name": "Non GMO august red nectarine purees", "code": "50564809" },
      { "name": "Non GMO autumn star nectarine purees", "code": "50564810" },
      { "name": "Non GMO big john nectarine purees", "code": "50564811" },
      { "name": "Non GMO bright pearl nectarine purees", "code": "50564812" },
      { "name": "Non GMO diamond bright nectarine purees", "code": "50564813" },
      { "name": "Non GMO diamond ray nectarine purees", "code": "50564814" },
      { "name": "Non GMO earliglo nectarine purees", "code": "50564815" },
      { "name": "Non GMO early diamond nectarine purees", "code": "50564816" },
      { "name": "Non GMO fairlane nectarine purees", "code": "50564817" },
      { "name": "Non GMO fantasia nectarine purees", "code": "50564818" },
      { "name": "Non GMO fire pearl nectarine purees", "code": "50564819" },
      { "name": "Non GMO fire sweet nectarine purees", "code": "50564820" },
      { "name": "Non GMO flamekist nectarine purees", "code": "50564821" },
      { "name": "Non GMO flat type nectarine purees", "code": "50564822" },
      { "name": "Non GMO garden delight nectarine purees", "code": "50564823" },
      { "name": "Non GMO goldmine nectarine purees", "code": "50564824" },
      { "name": "Non GMO grand pearl nectarine purees", "code": "50564825" },
      { "name": "Non GMO hardired nectarine purees", "code": "50564826" },
      { "name": "Non GMO honey blaze nectarine purees", "code": "50564827" },
      { "name": "Non GMO july red nectarine purees", "code": "50564828" },
      { "name": "Non GMO kay pearl nectarine purees", "code": "50564829" },
      { "name": "Non GMO kay sweet nectarine purees", "code": "50564830" },
      { "name": "Non GMO may diamond nectarine purees", "code": "50564831" },
      { "name": "Non GMO mayfire nectarine purees", "code": "50564832" },
      { "name": "Non GMO mayglo nectarine purees", "code": "50564833" },
      { "name": "Non GMO mericrest nectarine purees", "code": "50564834" },
      { "name": "Non GMO red diamond nectarine purees", "code": "50564835" },
      { "name": "Non GMO red gold nectarine purees", "code": "50564836" },
      { "name": "Non GMO red jim nectarine purees", "code": "50564837" },
      { "name": "Non GMO red roy nectarine purees", "code": "50564838" },
      { "name": "Non GMO rio red nectarine purees", "code": "50564839" },
      { "name": "Non GMO rose diamond nectarine purees", "code": "50564840" },
      { "name": "Non GMO royal glo nectarine purees", "code": "50564841" },
      { "name": "Non GMO ruby diamond nectarine purees", "code": "50564842" },
      { "name": "Non GMO ruby sweet nectarine purees", "code": "50564843" },
      { "name": "Non GMO ruddy jewel nectarine purees", "code": "50564844" },
      { "name": "Non GMO september red nectarine purees", "code": "50564845" },
      { "name": "Non GMO snowqueen nectarine purees", "code": "50564846" },
      { "name": "Non GMO spring bright nectarine purees", "code": "50564847" },
      { "name": "Non GMO spring red nectarine purees", "code": "50564848" },
      { "name": "Non GMO summer blush nectarine purees", "code": "50564849" },
      { "name": "Non GMO summer brite nectarine purees", "code": "50564850" },
      { "name": "Non GMO summer diamond nectarine purees", "code": "50564851" },
      { "name": "Non GMO summer fire nectarine purees", "code": "50564852" },
      { "name": "Non GMO summer grand nectarine purees", "code": "50564853" },
      { "name": "Non GMO sunglo nectarine purees", "code": "50564854" },
      { "name": "Non GMO zee fire nectarine purees", "code": "50564855" },
      { "name": "Non GMO zee glo nectarine purees", "code": "50564856" },
      { "name": "Non GMO zeegrand nectarine purees", "code": "50564857" },
      { "name": "Non GMO orange purees", "code": "50564900" },
      { "name": "Non GMO african sour orange purees", "code": "50564901" },
      { "name": "Non GMO ambersweet orange purees", "code": "50564902" },
      { "name": "Non GMO argentine sour orange purees", "code": "50564903" },
      { "name": "Non GMO bahianinha orange purees", "code": "50564904" },
      { "name": "Non GMO bergamot orange purees", "code": "50564905" },
      { "name": "Non GMO berna orange purees", "code": "50564906" },
      { "name": "Non GMO bigaradier apepu orange purees", "code": "50564907" },
      {
        "name": "Non GMO bittersweet daidai orange purees",
        "code": "50564908"
      },
      { "name": "Non GMO blonde orange purees", "code": "50564909" },
      { "name": "Non GMO blood orange purees", "code": "50564910" },
      { "name": "Non GMO california navel orange purees", "code": "50564911" },
      { "name": "Non GMO cara cara orange purees", "code": "50564912" },
      { "name": "Non GMO chinotto orange purees", "code": "50564913" },
      { "name": "Non GMO dream navel orange purees", "code": "50564914" },
      { "name": "Non GMO gou tou orange purees", "code": "50564915" },
      { "name": "Non GMO hamlin orange purees", "code": "50564916" },
      { "name": "Non GMO jaffa orange purees", "code": "50564917" },
      { "name": "Non GMO jincheng orange purees", "code": "50564918" },
      { "name": "Non GMO k-early orange purees", "code": "50564919" },
      { "name": "Non GMO kona orange purees", "code": "50564920" },
      { "name": "Non GMO late navel orange purees", "code": "50564921" },
      { "name": "Non GMO late valencia orange purees", "code": "50564922" },
      { "name": "Non GMO limequat orange purees", "code": "50564923" },
      { "name": "Non GMO marr orange purees", "code": "50564924" },
      { "name": "Non GMO melogold orange purees", "code": "50564925" },
      { "name": "Non GMO moro orange purees", "code": "50564926" },
      { "name": "Non GMO moro tarocco orange purees", "code": "50564927" },
      { "name": "Non GMO navel orange purees", "code": "50564928" },
      { "name": "Non GMO navelina orange purees", "code": "50564929" },
      { "name": "Non GMO oro blanco orange purees", "code": "50564930" },
      { "name": "Non GMO osceola orange purees", "code": "50564931" },
      { "name": "Non GMO parson brown orange purees", "code": "50564932" },
      { "name": "Non GMO pera orange purees", "code": "50564933" },
      { "name": "Non GMO pummulo orange purees", "code": "50564934" },
      { "name": "Non GMO rhode red orange purees", "code": "50564935" },
      { "name": "Non GMO roble orange purees", "code": "50564936" },
      { "name": "Non GMO salustianas orange purees", "code": "50564937" },
      { "name": "Non GMO sanguine orange purees", "code": "50564938" },
      { "name": "Non GMO sanguinelli orange purees", "code": "50564939" },
      { "name": "Non GMO seville orange purees", "code": "50564940" },
      { "name": "Non GMO shamouti jaffa orange purees", "code": "50564941" },
      { "name": "Non GMO tunis orange purees", "code": "50564942" },
      { "name": "Non GMO valencia orange purees", "code": "50564943" },
      { "name": "Non GMO washington navel orange purees", "code": "50564944" },
      { "name": "Non GMO papaya purees", "code": "50565000" },
      { "name": "Non GMO green cooking papaya purees", "code": "50565001" },
      { "name": "Non GMO maradol papaya purees", "code": "50565002" },
      { "name": "Non GMO mexican yellow papaya purees", "code": "50565003" },
      { "name": "Non GMO mountain papaya purees", "code": "50565004" },
      { "name": "Non GMO solo papaya purees", "code": "50565005" },
      { "name": "Non GMO tainung papaya purees", "code": "50565006" },
      { "name": "Non GMO passion fruit purees", "code": "50565100" },
      { "name": "Non GMO banana passion fruit purees", "code": "50565101" },
      { "name": "Non GMO blue passion flowe purees", "code": "50565102" },
      {
        "name": "Non GMO crackerjack passion fruit purees",
        "code": "50565103"
      },
      {
        "name": "Non GMO giant granadilla passion fruit purees",
        "code": "50565104"
      },
      {
        "name": "Non GMO golden granadilla passion fruit purees",
        "code": "50565105"
      },
      { "name": "Non GMO maypops passion fruit purees", "code": "50565106" },
      {
        "name": "Non GMO red granadilla passion fruit purees",
        "code": "50565107"
      },
      {
        "name": "Non GMO sweet granadilla passion fruit purees",
        "code": "50565108"
      },
      {
        "name": "Non GMO water lemon passion fruit purees",
        "code": "50565109"
      },
      {
        "name": "Non GMO wing-stemmed passion flowe purees",
        "code": "50565110"
      },
      { "name": "Non GMO peach purees", "code": "50565200" },
      { "name": "Non GMO amber crest peach purees", "code": "50565201" },
      { "name": "Non GMO april snow peach purees", "code": "50565202" },
      { "name": "Non GMO august lady peach purees", "code": "50565203" },
      { "name": "Non GMO autumn flame peach purees", "code": "50565204" },
      { "name": "Non GMO autumn lady peach purees", "code": "50565205" },
      { "name": "Non GMO babcock peach purees", "code": "50565206" },
      { "name": "Non GMO brittney lane peach purees", "code": "50565207" },
      { "name": "Non GMO cary mac peach purees", "code": "50565208" },
      { "name": "Non GMO classic peach purees", "code": "50565209" },
      { "name": "Non GMO country sweet peach purees", "code": "50565210" },
      { "name": "Non GMO crest haven peach purees", "code": "50565211" },
      { "name": "Non GMO crimson lady peach purees", "code": "50565212" },
      { "name": "Non GMO crown princess peach purees", "code": "50565213" },
      { "name": "Non GMO david sun peach purees", "code": "50565214" },
      { "name": "Non GMO diamond princess peach purees", "code": "50565215" },
      { "name": "Non GMO earlirich peach purees", "code": "50565216" },
      { "name": "Non GMO early majestic peach purees", "code": "50565217" },
      { "name": "Non GMO early treat peach purees", "code": "50565218" },
      { "name": "Non GMO elegant lady peach purees", "code": "50565219" },
      { "name": "Non GMO empress peach purees", "code": "50565220" },
      { "name": "Non GMO encore peach purees", "code": "50565221" },
      { "name": "Non GMO fancy lady peach purees", "code": "50565222" },
      { "name": "Non GMO fire prince peach purees", "code": "50565223" },
      { "name": "Non GMO flame crest peach purees", "code": "50565224" },
      { "name": "Non GMO flat type peach purees", "code": "50565225" },
      { "name": "Non GMO flavorcrest peach purees", "code": "50565226" },
      { "name": "Non GMO florida prince peach purees", "code": "50565227" },
      { "name": "Non GMO full moon peach purees", "code": "50565228" },
      { "name": "Non GMO harvester peach purees", "code": "50565229" },
      { "name": "Non GMO ice princess peach purees", "code": "50565230" },
      { "name": "Non GMO ivory princess peach purees", "code": "50565231" },
      { "name": "Non GMO jersey queen peach purees", "code": "50565232" },
      { "name": "Non GMO john henry peach purees", "code": "50565233" },
      { "name": "Non GMO june prince peach purees", "code": "50565234" },
      { "name": "Non GMO kaweah peach purees", "code": "50565235" },
      { "name": "Non GMO klondike peach purees", "code": "50565236" },
      { "name": "Non GMO lindo peach purees", "code": "50565237" },
      { "name": "Non GMO loring peach purees", "code": "50565238" },
      { "name": "Non GMO majestic peach purees", "code": "50565239" },
      { "name": "Non GMO o'henry peach purees", "code": "50565240" },
      { "name": "Non GMO queencrest peach purees", "code": "50565241" },
      { "name": "Non GMO red lady peach purees", "code": "50565242" },
      { "name": "Non GMO redglobe peach purees", "code": "50565243" },
      { "name": "Non GMO redhaven peach purees", "code": "50565244" },
      { "name": "Non GMO redtop peach purees", "code": "50565245" },
      { "name": "Non GMO regina peach purees", "code": "50565246" },
      { "name": "Non GMO rich lady peach purees", "code": "50565247" },
      { "name": "Non GMO rich may peach purees", "code": "50565248" },
      { "name": "Non GMO royal glory peach purees", "code": "50565249" },
      { "name": "Non GMO royal lady peach purees", "code": "50565250" },
      { "name": "Non GMO september snow peach purees", "code": "50565251" },
      { "name": "Non GMO september sun peach purees", "code": "50565252" },
      { "name": "Non GMO sierra gem peach purees", "code": "50565253" },
      { "name": "Non GMO snow angel peach purees", "code": "50565254" },
      { "name": "Non GMO snow gem peach purees", "code": "50565255" },
      { "name": "Non GMO snow king peach purees", "code": "50565256" },
      { "name": "Non GMO spring lady peach purees", "code": "50565257" },
      { "name": "Non GMO spring snow peach purees", "code": "50565258" },
      { "name": "Non GMO springcrest peach purees", "code": "50565259" },
      { "name": "Non GMO sugar giant peach purees", "code": "50565260" },
      { "name": "Non GMO sugar lady peach purees", "code": "50565261" },
      { "name": "Non GMO sun bright peach purees", "code": "50565262" },
      { "name": "Non GMO sunhigh peach purees", "code": "50565263" },
      { "name": "Non GMO super lady peach purees", "code": "50565264" },
      { "name": "Non GMO super rich peach purees", "code": "50565265" },
      { "name": "Non GMO surecrop peach purees", "code": "50565266" },
      { "name": "Non GMO sweet dream peach purees", "code": "50565267" },
      { "name": "Non GMO sweet september peach purees", "code": "50565268" },
      { "name": "Non GMO vista peach purees", "code": "50565269" },
      { "name": "Non GMO white lady peach purees", "code": "50565270" },
      { "name": "Non GMO zee lady peach purees", "code": "50565271" },
      { "name": "Non GMO pear purees", "code": "50565300" },
      { "name": "Non GMO abate fetel pear purees", "code": "50565301" },
      { "name": "Non GMO anjou pear purees", "code": "50565302" },
      { "name": "Non GMO asian pear purees", "code": "50565303" },
      { "name": "Non GMO bartlett pear purees", "code": "50565304" },
      { "name": "Non GMO best ever pear purees", "code": "50565305" },
      { "name": "Non GMO beth pear purees", "code": "50565306" },
      { "name": "Non GMO beurr\u00e9 pear purees", "code": "50565307" },
      { "name": "Non GMO bosc pear purees", "code": "50565308" },
      { "name": "Non GMO clapp favorite pear purees", "code": "50565309" },
      { "name": "Non GMO comice pear purees", "code": "50565310" },
      { "name": "Non GMO concorde pear purees", "code": "50565311" },
      { "name": "Non GMO conference pear purees", "code": "50565312" },
      { "name": "Non GMO crimson red pear purees", "code": "50565313" },
      { "name": "Non GMO d'anjou pear purees", "code": "50565314" },
      { "name": "Non GMO dr jules guyot pear purees", "code": "50565315" },
      { "name": "Non GMO early pear purees", "code": "50565316" },
      { "name": "Non GMO emperor brown pear purees", "code": "50565317" },
      { "name": "Non GMO forelle pear purees", "code": "50565318" },
      { "name": "Non GMO french butter pear purees", "code": "50565319" },
      { "name": "Non GMO glou morceau pear purees", "code": "50565320" },
      { "name": "Non GMO hosui pear purees", "code": "50565321" },
      { "name": "Non GMO italian butter pear purees", "code": "50565322" },
      { "name": "Non GMO jargonelle pear purees", "code": "50565323" },
      { "name": "Non GMO juno pear purees", "code": "50565324" },
      {
        "name": "Non GMO kaiserlouise bonne de jersey pear purees",
        "code": "50565325"
      },
      { "name": "Non GMO keiffer pear purees", "code": "50565326" },
      { "name": "Non GMO kings royal pear purees", "code": "50565327" },
      { "name": "Non GMO limonera pear purees", "code": "50565328" },
      { "name": "Non GMO merton pride pear purees", "code": "50565329" },
      { "name": "Non GMO mountain bartlett pear purees", "code": "50565330" },
      { "name": "Non GMO olivier de serres pear purees", "code": "50565331" },
      { "name": "Non GMO onward pear purees", "code": "50565332" },
      { "name": "Non GMO packham's triumph pear purees", "code": "50565333" },
      { "name": "Non GMO paraiso pear purees", "code": "50565334" },
      { "name": "Non GMO passe crasanne pear purees", "code": "50565335" },
      { "name": "Non GMO perry pear purees", "code": "50565336" },
      { "name": "Non GMO red bartlett pear purees", "code": "50565337" },
      { "name": "Non GMO red d'anjou pear purees", "code": "50565338" },
      { "name": "Non GMO rocha pear purees", "code": "50565339" },
      { "name": "Non GMO rosey red pear purees", "code": "50565340" },
      { "name": "Non GMO rosy red pear purees", "code": "50565341" },
      { "name": "Non GMO royal majestic pear purees", "code": "50565342" },
      { "name": "Non GMO ruby red pear purees", "code": "50565343" },
      { "name": "Non GMO santa maria pear purees", "code": "50565344" },
      { "name": "Non GMO seckelp pear purees", "code": "50565345" },
      { "name": "Non GMO sensation pear purees", "code": "50565346" },
      { "name": "Non GMO star crimson pear purees", "code": "50565347" },
      { "name": "Non GMO stark crimson pear purees", "code": "50565348" },
      { "name": "Non GMO summer bartlett pear purees", "code": "50565349" },
      { "name": "Non GMO summer gold pear purees", "code": "50565350" },
      { "name": "Non GMO sun gold pear purees", "code": "50565351" },
      { "name": "Non GMO sunsprite pear purees", "code": "50565352" },
      { "name": "Non GMO taylors gold pear purees", "code": "50565353" },
      { "name": "Non GMO taylors red pear purees", "code": "50565354" },
      { "name": "Non GMO tientsin pear purees", "code": "50565355" },
      { "name": "Non GMO tosca pear purees", "code": "50565356" },
      { "name": "Non GMO warden pear purees", "code": "50565357" },
      {
        "name": "Non GMO williams bon chretien pear purees",
        "code": "50565358"
      },
      { "name": "Non GMO williams pear purees", "code": "50565359" },
      { "name": "Non GMO winter nelis pear purees", "code": "50565360" },
      { "name": "Non GMO persimmon purees", "code": "50565400" },
      { "name": "Non GMO american persimmo purees", "code": "50565401" },
      { "name": "Non GMO black sapote persimmo purees", "code": "50565402" },
      { "name": "Non GMO chapote black persimmo purees", "code": "50565403" },
      { "name": "Non GMO date plum persimmo purees", "code": "50565404" },
      { "name": "Non GMO fuyu persimmon purees", "code": "50565405" },
      { "name": "Non GMO giant fuyu persimmon purees", "code": "50565406" },
      { "name": "Non GMO hachiya persimmon purees", "code": "50565407" },
      {
        "name": "Non GMO mabolo butter fruit persimmo purees",
        "code": "50565408"
      },
      { "name": "Non GMO principe ito persimmon purees", "code": "50565409" },
      {
        "name": "Non GMO royal brillante persimmon purees",
        "code": "50565410"
      },
      { "name": "Non GMO sharon fruit persimmo purees", "code": "50565411" },
      { "name": "Non GMO triumph persimmon purees", "code": "50565412" },
      { "name": "Non GMO pineapple purees", "code": "50565500" },
      { "name": "Non GMO cherimoya pineapple purees", "code": "50565501" },
      { "name": "Non GMO golden pineapple purees", "code": "50565502" },
      { "name": "Non GMO hilo pineapple purees", "code": "50565503" },
      { "name": "Non GMO kona sugarloaf pineapple purees", "code": "50565504" },
      { "name": "Non GMO natal queen pineapple purees", "code": "50565505" },
      { "name": "Non GMO pernambuco pineapple purees", "code": "50565506" },
      { "name": "Non GMO red spanish pineapple purees", "code": "50565507" },
      { "name": "Non GMO smooth cayenne pineapple purees", "code": "50565508" },
      { "name": "Non GMO sugarloaf pineapple purees", "code": "50565509" },
      { "name": "Non GMO variegated pineapple purees", "code": "50565510" },
      { "name": "Non GMO plucot purees", "code": "50565600" },
      { "name": "Non GMO black kat plucot purees", "code": "50565601" },
      { "name": "Non GMO blue gusto plucot purees", "code": "50565602" },
      { "name": "Non GMO crimson heart plucot purees", "code": "50565603" },
      { "name": "Non GMO dapple dandy plucot purees", "code": "50565604" },
      { "name": "Non GMO dapple fire plucot purees", "code": "50565605" },
      { "name": "Non GMO early dapple plucot purees", "code": "50565606" },
      { "name": "Non GMO flavor fall plucot purees", "code": "50565607" },
      { "name": "Non GMO flavor gold plucot purees", "code": "50565608" },
      { "name": "Non GMO flavor grenade plucot purees", "code": "50565609" },
      { "name": "Non GMO flavor heart plucot purees", "code": "50565610" },
      { "name": "Non GMO flavor jewel plucot purees", "code": "50565611" },
      { "name": "Non GMO flavor king plucot purees", "code": "50565612" },
      { "name": "Non GMO flavor queen plucot purees", "code": "50565613" },
      { "name": "Non GMO flavor supreme plucot purees", "code": "50565614" },
      { "name": "Non GMO flavor treat plucot purees", "code": "50565615" },
      { "name": "Non GMO flavorella plucot purees", "code": "50565616" },
      { "name": "Non GMO flavorich plucot purees", "code": "50565617" },
      { "name": "Non GMO flavorosa plucot purees", "code": "50565618" },
      { "name": "Non GMO geo pride plucot purees", "code": "50565619" },
      { "name": "Non GMO red kat plucot purees", "code": "50565620" },
      { "name": "Non GMO royal treat plucot purees", "code": "50565621" },
      { "name": "Non GMO sierra rose plucot purees", "code": "50565622" },
      { "name": "Non GMO sweet geisha plucot purees", "code": "50565623" },
      { "name": "Non GMO plum purees", "code": "50565700" },
      { "name": "Non GMO amber jewel plum purees", "code": "50565701" },
      { "name": "Non GMO angeleno plum purees", "code": "50565702" },
      { "name": "Non GMO aurora plum purees", "code": "50565703" },
      { "name": "Non GMO autumn beaut plum purees", "code": "50565704" },
      { "name": "Non GMO autumn giant plum purees", "code": "50565705" },
      { "name": "Non GMO autumn pride plum purees", "code": "50565706" },
      { "name": "Non GMO autumn rosa plum purees", "code": "50565707" },
      { "name": "Non GMO beach plum purees", "code": "50565708" },
      { "name": "Non GMO betty anne plum purees", "code": "50565709" },
      { "name": "Non GMO black beaut plum purees", "code": "50565710" },
      { "name": "Non GMO black bullace plum purees", "code": "50565711" },
      { "name": "Non GMO black diamond plum purees", "code": "50565712" },
      { "name": "Non GMO black giant plum purees", "code": "50565713" },
      { "name": "Non GMO black ice plum purees", "code": "50565714" },
      { "name": "Non GMO black splendor plum purees", "code": "50565715" },
      { "name": "Non GMO blackamber plum purees", "code": "50565716" },
      { "name": "Non GMO burgundy plum purees", "code": "50565717" },
      { "name": "Non GMO carlsbad plum purees", "code": "50565718" },
      { "name": "Non GMO casselman plum purees", "code": "50565719" },
      { "name": "Non GMO catalina plum purees", "code": "50565720" },
      { "name": "Non GMO damson plum purees", "code": "50565721" },
      { "name": "Non GMO dolly plum purees", "code": "50565722" },
      { "name": "Non GMO earliqueen plum purees", "code": "50565723" },
      { "name": "Non GMO early rosa plum purees", "code": "50565724" },
      { "name": "Non GMO ebony may plum purees", "code": "50565725" },
      { "name": "Non GMO ebony plum purees", "code": "50565726" },
      { "name": "Non GMO elephant heart plum purees", "code": "50565727" },
      { "name": "Non GMO emerald beaut plum purees", "code": "50565728" },
      { "name": "Non GMO empress plum purees", "code": "50565729" },
      { "name": "Non GMO freedom plum purees", "code": "50565730" },
      { "name": "Non GMO friar plum purees", "code": "50565731" },
      { "name": "Non GMO gar red plum purees", "code": "50565732" },
      { "name": "Non GMO governor's plum purees", "code": "50565733" },
      { "name": "Non GMO grand rosa plum purees", "code": "50565734" },
      { "name": "Non GMO green gage plum purees", "code": "50565735" },
      { "name": "Non GMO greengage plum purees", "code": "50565736" },
      { "name": "Non GMO hiromi plum purees", "code": "50565737" },
      { "name": "Non GMO hiromi red plum purees", "code": "50565738" },
      { "name": "Non GMO holiday plum purees", "code": "50565739" },
      { "name": "Non GMO howard sun plum purees", "code": "50565740" },
      { "name": "Non GMO interspecific type plum purees", "code": "50565741" },
      { "name": "Non GMO jamaican plum purees", "code": "50565742" },
      { "name": "Non GMO joanna red plum purees", "code": "50565743" },
      { "name": "Non GMO kelsey plum purees", "code": "50565744" },
      { "name": "Non GMO king james plum purees", "code": "50565745" },
      { "name": "Non GMO laroda plum purees", "code": "50565746" },
      { "name": "Non GMO late rosa plum purees", "code": "50565747" },
      { "name": "Non GMO linda rosa plum purees", "code": "50565748" },
      { "name": "Non GMO lone star red plum purees", "code": "50565749" },
      { "name": "Non GMO mariposa plum purees", "code": "50565750" },
      { "name": "Non GMO marked black plum purees", "code": "50565751" },
      { "name": "Non GMO marked red plum purees", "code": "50565752" },
      { "name": "Non GMO mirabelle plum purees", "code": "50565753" },
      { "name": "Non GMO october sun plum purees", "code": "50565754" },
      { "name": "Non GMO owen t plum purees", "code": "50565755" },
      { "name": "Non GMO perdrigon plum purees", "code": "50565756" },
      { "name": "Non GMO pink delight plum purees", "code": "50565757" },
      { "name": "Non GMO president plum purees", "code": "50565758" },
      { "name": "Non GMO primetime plum purees", "code": "50565759" },
      { "name": "Non GMO purple majesty plum purees", "code": "50565760" },
      { "name": "Non GMO queen rosa plum purees", "code": "50565761" },
      { "name": "Non GMO quetsch plum purees", "code": "50565762" },
      { "name": "Non GMO red beaut plum purees", "code": "50565763" },
      { "name": "Non GMO red lane plum purees", "code": "50565764" },
      { "name": "Non GMO red ram plum purees", "code": "50565765" },
      { "name": "Non GMO red rosa plum purees", "code": "50565766" },
      { "name": "Non GMO rich red plum purees", "code": "50565767" },
      { "name": "Non GMO rosemary plum purees", "code": "50565768" },
      { "name": "Non GMO royal diamond plum purees", "code": "50565769" },
      { "name": "Non GMO royal red plum purees", "code": "50565770" },
      { "name": "Non GMO royal zee plum purees", "code": "50565771" },
      { "name": "Non GMO roysum plum purees", "code": "50565772" },
      { "name": "Non GMO santa rosa plum purees", "code": "50565773" },
      { "name": "Non GMO saphire plum purees", "code": "50565774" },
      { "name": "Non GMO sloe plum purees", "code": "50565775" },
      { "name": "Non GMO st catherine plum purees", "code": "50565776" },
      { "name": "Non GMO white bullace plum purees", "code": "50565777" },
      { "name": "Non GMO pommegranate purees", "code": "50565800" },
      { "name": "Non GMO foothill pommegranate purees", "code": "50565801" },
      { "name": "Non GMO granada pommegranate purees", "code": "50565802" },
      { "name": "Non GMO jolly red pommegranate purees", "code": "50565803" },
      { "name": "Non GMO nana pommegranate purees", "code": "50565804" },
      { "name": "Non GMO pat's red pommegranate purees", "code": "50565805" },
      { "name": "Non GMO pinkhan pommegranate purees", "code": "50565806" },
      {
        "name": "Non GMO purple velvet pommegranate purees",
        "code": "50565807"
      },
      { "name": "Non GMO wonderful pommegranate purees", "code": "50565808" },
      { "name": "Non GMO pomelo purees", "code": "50565900" },
      { "name": "Non GMO chandler pomelo purees", "code": "50565901" },
      { "name": "Non GMO hirado buntan pomelo purees", "code": "50565902" },
      { "name": "Non GMO liang ping yau pomelo purees", "code": "50565903" },
      { "name": "Non GMO pandan wangi pomelo purees", "code": "50565904" },
      { "name": "Non GMO pink pomelo purees", "code": "50565905" },
      { "name": "Non GMO red shaddock pomelo purees", "code": "50565906" },
      { "name": "Non GMO siamese sweet pomelo purees", "code": "50565907" },
      { "name": "Non GMO wainwright pomelo purees", "code": "50565908" },
      { "name": "Non GMO quince purees", "code": "50566000" },
      { "name": "Non GMO champion quinc purees", "code": "50566001" },
      { "name": "Non GMO pineapple quinc purees", "code": "50566002" },
      { "name": "Non GMO smyrna quinc purees", "code": "50566003" },
      { "name": "Non GMO raspberry purees", "code": "50566100" },
      { "name": "Non GMO american red raspberry purees", "code": "50566101" },
      {
        "name": "Non GMO bailey queensland raspberry purees",
        "code": "50566102"
      },
      { "name": "Non GMO black raspberry purees", "code": "50566103" },
      { "name": "Non GMO dark raspberry purees", "code": "50566104" },
      { "name": "Non GMO delicious raspberry purees", "code": "50566105" },
      { "name": "Non GMO focke dwarf raspberry purees", "code": "50566106" },
      {
        "name": "Non GMO focke grayleaf red raspberry purees",
        "code": "50566107"
      },
      {
        "name": "Non GMO focke strawberry raspberry purees",
        "code": "50566108"
      },
      {
        "name": "Non GMO focke yellow himalayan raspberry purees",
        "code": "50566109"
      },
      { "name": "Non GMO gold raspberry purees", "code": "50566110" },
      {
        "name": "Non GMO gray new mexico raspberry purees",
        "code": "50566111"
      },
      {
        "name": "Non GMO jepson whitebark raspberry purees",
        "code": "50566112"
      },
      {
        "name": "Non GMO kellogg san diego raspberry purees",
        "code": "50566113"
      },
      {
        "name": "Non GMO leucodermis whitebark raspberry purees",
        "code": "50566114"
      },
      { "name": "Non GMO munz cuyamaca raspberry purees", "code": "50566115" },
      { "name": "Non GMO peck barton's raspberry purees", "code": "50566116" },
      {
        "name": "Non GMO purpleflowering raspberry purees",
        "code": "50566117"
      },
      { "name": "Non GMO roadside raspberry purees", "code": "50566118" },
      { "name": "Non GMO san diego raspberry purees", "code": "50566119" },
      { "name": "Non GMO snow raspberry purees", "code": "50566120" },
      { "name": "Non GMO snowpeaks raspberry purees", "code": "50566121" },
      { "name": "Non GMO strawberryleaf raspberry purees", "code": "50566122" },
      {
        "name": "Non GMO sweet cultivated raspberry purees",
        "code": "50566123"
      },
      {
        "name": "Non GMO torr and gray whitebark raspberry purees",
        "code": "50566124"
      },
      { "name": "Non GMO west indian raspberry purees", "code": "50566125" },
      { "name": "Non GMO whitebark raspberry purees", "code": "50566126" },
      { "name": "Non GMO wine raspberry purees", "code": "50566127" },
      {
        "name": "Non GMO yellow himalayan raspberry purees",
        "code": "50566128"
      },
      { "name": "Non GMO yu-shan raspberry purees", "code": "50566129" },
      { "name": "Non GMO rhubarb purees", "code": "50566200" },
      { "name": "Non GMO crimson red rhubarb purees", "code": "50566201" },
      { "name": "Non GMO early champagne rhubarb purees", "code": "50566202" },
      {
        "name": "Non GMO glaskin's perpetual rhubarb purees",
        "code": "50566203"
      },
      { "name": "Non GMO sutton rhubarb purees", "code": "50566204" },
      { "name": "Non GMO timperley early rhubarb purees", "code": "50566205" },
      { "name": "Non GMO valentine rhubarb purees", "code": "50566206" },
      { "name": "Non GMO victoria rhubarb purees", "code": "50566207" },
      { "name": "Non GMO zwolle seedling rhubarb purees", "code": "50566208" },
      { "name": "Non GMO macdonald rhubarb purees", "code": "50566209" },
      { "name": "Non GMO tilden rhubarb purees", "code": "50566210" },
      { "name": "Non GMO rose hip purees", "code": "50566300" },
      { "name": "Non GMO brier rose hip purees", "code": "50566301" },
      { "name": "Non GMO elgantine rose hip purees", "code": "50566302" },
      { "name": "Non GMO rugosa rose hip purees", "code": "50566303" },
      {
        "name": "Non GMO scotch or burnet rose hip purees",
        "code": "50566304"
      },
      { "name": "Non GMO sapote purees", "code": "50566400" },
      { "name": "Non GMO white sapote purees", "code": "50566401" },
      { "name": "Non GMO black sapote purees", "code": "50566402" },
      { "name": "Non GMO saskatoon berry purees", "code": "50566500" },
      {
        "name": "Non GMO honeywood saskatoon berry purees",
        "code": "50566501"
      },
      {
        "name": "Non GMO northline saskatoon berry purees",
        "code": "50566502"
      },
      { "name": "Non GMO smoky saskatoon berry purees", "code": "50566503" },
      { "name": "Non GMO thiessen saskatoon berry purees", "code": "50566504" },
      { "name": "Non GMO strawberry purees", "code": "50566600" },
      { "name": "Non GMO chandler strawberry purees", "code": "50566601" },
      { "name": "Non GMO june bearing strawberry purees", "code": "50566602" },
      { "name": "Non GMO ever bearing strawberry purees", "code": "50566603" },
      { "name": "Non GMO sugar apple purees", "code": "50566700" },
      {
        "name": "Non GMO kampong mauve sugar apple purees",
        "code": "50566701"
      },
      { "name": "Non GMO seedless sugar apple purees", "code": "50566702" },
      { "name": "Non GMO thai lessard sugar apple purees", "code": "50566703" },
      { "name": "Non GMO tamarillo purees", "code": "50566800" },
      { "name": "Non GMO amberlea gold tamarillo purees", "code": "50566801" },
      { "name": "Non GMO bold gold tamarillo purees", "code": "50566802" },
      { "name": "Non GMO goldmine tamarillo purees", "code": "50566803" },
      { "name": "Non GMO oratia red tamarillo purees", "code": "50566804" },
      { "name": "Non GMO red beau tamarillo purees", "code": "50566805" },
      { "name": "Non GMO red delight tamarillo purees", "code": "50566806" },
      { "name": "Nominant Non GMO fruit purees", "code": "50566900" },
      { "name": "Non GMO ake purees", "code": "50566901" },
      { "name": "Non GMO babac purees", "code": "50566902" },
      { "name": "Non GMO banana flower purees", "code": "50566903" },
      { "name": "Non GMO baoba purees", "code": "50566904" },
      { "name": "Non GMO bitter orange purees", "code": "50566905" },
      { "name": "Non GMO caniste purees", "code": "50566906" },
      { "name": "Non GMO cloudberry purees", "code": "50566907" },
      { "name": "Non GMO coconut purees", "code": "50566908" },
      { "name": "Non GMO dewberry purees", "code": "50566909" },
      { "name": "Non GMO duria purees", "code": "50566910" },
      { "name": "Non GMO elderberry purees", "code": "50566911" },
      { "name": "Non GMO feijo purees", "code": "50566912" },
      { "name": "Non GMO hackberry purees", "code": "50566913" },
      { "name": "Non GMO hawthorn purees", "code": "50566914" },
      { "name": "Non GMO honeyberry purees", "code": "50566915" },
      { "name": "Non GMO jackfruit purees", "code": "50566916" },
      { "name": "Non GMO jambola purees", "code": "50566917" },
      { "name": "Non GMO jujub purees", "code": "50566918" },
      { "name": "Non GMO lyche purees", "code": "50566919" },
      { "name": "Non GMO mangosteen purees", "code": "50566920" },
      { "name": "Non GMO medlar purees", "code": "50566921" },
      { "name": "Non GMO mombin purees", "code": "50566922" },
      { "name": "Non GMO monster purees", "code": "50566923" },
      { "name": "Non GMO pepino purees", "code": "50566924" },
      { "name": "Non GMO plantain purees", "code": "50566925" },
      { "name": "Non GMO prickly pear purees", "code": "50566926" },
      { "name": "Non GMO quenepa purees", "code": "50566927" },
      { "name": "Non GMO rambuta purees", "code": "50566928" },
      { "name": "Non GMO rose apple purees", "code": "50566929" },
      { "name": "Non GMO rosell purees", "code": "50566930" },
      { "name": "Non GMO rowanberry purees", "code": "50566931" },
      { "name": "Non GMO sea buckhorn berry purees", "code": "50566932" },
      { "name": "Non GMO silverberry purees", "code": "50566933" },
      { "name": "Non GMO sorb berry purees", "code": "50566934" },
      { "name": "Non GMO soursop purees", "code": "50566935" },
      { "name": "Non GMO star apple purees", "code": "50566936" },
      { "name": "Non GMO tamarind purees", "code": "50566937" },
      { "name": "Non GMO chokeberry purees", "code": "50567000" },
      { "name": "Non GMO autumn magic chokeberry purees", "code": "50567001" },
      { "name": "Non GMO brillantisima chokeberry purees", "code": "50567002" },
      { "name": "Non GMO nero chokeberry purees", "code": "50567003" },
      { "name": "Non GMO viking chokeberry purees", "code": "50567004" },
      { "name": "Non GMO olive purees", "code": "50567100" },
      { "name": "Non GMO agrinion olive purees", "code": "50567101" },
      { "name": "Non GMO aleppo olive purees", "code": "50567102" },
      { "name": "Non GMO alphonso olive purees", "code": "50567103" },
      { "name": "Non GMO amphissa olive purees", "code": "50567104" },
      { "name": "Non GMO arauco olive purees", "code": "50567105" },
      { "name": "Non GMO arbequina olive purees", "code": "50567106" },
      { "name": "Non GMO atalanta olive purees", "code": "50567107" },
      { "name": "Non GMO cerignola olive purees", "code": "50567108" },
      { "name": "Non GMO cracked provencal olive purees", "code": "50567109" },
      { "name": "Non GMO empeltre olive purees", "code": "50567110" },
      { "name": "Non GMO gaeta olive purees", "code": "50567111" },
      { "name": "Non GMO hondroelia olive purees", "code": "50567112" },
      { "name": "Non GMO kalamata olive purees", "code": "50567113" },
      { "name": "Non GMO kura olive purees", "code": "50567114" },
      { "name": "Non GMO ligurian olive purees", "code": "50567115" },
      { "name": "Non GMO lucque olive purees", "code": "50567116" },
      { "name": "Non GMO lugano olive purees", "code": "50567117" },
      { "name": "Non GMO manzanilla olive purees", "code": "50567118" },
      { "name": "Non GMO marche olive purees", "code": "50567119" },
      { "name": "Non GMO mission olive purees", "code": "50567120" },
      { "name": "Non GMO nafplion green olive purees", "code": "50567121" },
      { "name": "Non GMO nicoise olive purees", "code": "50567122" },
      { "name": "Non GMO nyons olive purees", "code": "50567123" },
      { "name": "Non GMO picholine olive purees", "code": "50567124" },
      { "name": "Non GMO ponentine olive purees", "code": "50567125" },
      { "name": "Non GMO royal olive purees", "code": "50567126" },
      { "name": "Non GMO seracena olive purees", "code": "50567127" },
      { "name": "Non GMO sevillano olive purees", "code": "50567128" },
      { "name": "Non GMO sicilian olive purees", "code": "50567129" },
      { "name": "Non GMO toscanelle olive purees", "code": "50567130" },
      { "name": "Non GMO bearberry purees", "code": "50567200" },
      { "name": "Non GMO alpine bearberry purees", "code": "50567201" },
      { "name": "Non GMO red bearberry purees", "code": "50567202" },
      { "name": "Non GMO common bearberry purees", "code": "50567203" },
      { "name": "Non GMO dragonfruit purees", "code": "50567300" },
      { "name": "Non GMO pink dragonfruit purees", "code": "50567301" },
      { "name": "Non GMO yellow dragonfruit purees", "code": "50567302" },
      { "name": "Non GMO fresh vegetables", "code": "50580000" },
      { "name": "Non GMO artichokes", "code": "50581500" },
      { "name": "Non GMO brittany artichokes", "code": "50581501" },
      { "name": "Non GMO catanese artichokes", "code": "50581502" },
      { "name": "Non GMO french artichokes", "code": "50581503" },
      { "name": "Non GMO green globe artichokes", "code": "50581504" },
      {
        "name": "Non GMO gros camus de bretagne artichokes",
        "code": "50581505"
      },
      { "name": "Non GMO midi artichokes", "code": "50581506" },
      { "name": "Non GMO purple globe artichokes", "code": "50581507" },
      { "name": "Non GMO purple sicilian artichokes", "code": "50581508" },
      { "name": "Non GMO romanesco artichokes", "code": "50581509" },
      { "name": "Non GMO spinoso sardo artichokes", "code": "50581510" },
      { "name": "Non GMO vert de laon artichokes", "code": "50581511" },
      { "name": "Non GMO violetta di chioggia artichokes", "code": "50581512" },
      { "name": "Non GMO violetto di toscana artichokes", "code": "50581513" },
      { "name": "Non GMO asparagus", "code": "50581600" },
      { "name": "Non GMO connover's colossal asparagus", "code": "50581601" },
      { "name": "Non GMO franklin asparagus", "code": "50581602" },
      { "name": "Non GMO giant mammoth asparagus", "code": "50581603" },
      { "name": "Non GMO lucullus asparagus", "code": "50581604" },
      { "name": "Non GMO martha washington asparagus", "code": "50581605" },
      { "name": "Non GMO avocados", "code": "50581700" },
      { "name": "Non GMO ajax b-7 avocados", "code": "50581701" },
      { "name": "Non GMO arue avocados", "code": "50581702" },
      { "name": "Non GMO bacon avocados", "code": "50581703" },
      { "name": "Non GMO benik avocados", "code": "50581704" },
      { "name": "Non GMO bernecker avocados", "code": "50581705" },
      { "name": "Non GMO beta avocados", "code": "50581706" },
      { "name": "Non GMO biondo avocados", "code": "50581707" },
      { "name": "Non GMO black prince avocados", "code": "50581708" },
      { "name": "Non GMO blair avocados", "code": "50581709" },
      { "name": "Non GMO blair booth avocados", "code": "50581710" },
      { "name": "Non GMO booth 1 avocados", "code": "50581711" },
      { "name": "Non GMO booth 3 avocados", "code": "50581712" },
      { "name": "Non GMO booth 5 avocados", "code": "50581713" },
      { "name": "Non GMO booth 7 avocados", "code": "50581714" },
      { "name": "Non GMO booth 8 avocados", "code": "50581715" },
      { "name": "Non GMO brooks 1978 avocados", "code": "50581716" },
      { "name": "Non GMO brookslate avocados", "code": "50581717" },
      { "name": "Non GMO california haas avocados", "code": "50581718" },
      { "name": "Non GMO catalina avocados", "code": "50581719" },
      { "name": "Non GMO chica avocados", "code": "50581720" },
      { "name": "Non GMO choquette avocados", "code": "50581721" },
      { "name": "Non GMO christina avocados", "code": "50581722" },
      { "name": "Non GMO collinson avocados", "code": "50581723" },
      { "name": "Non GMO donnie avocados", "code": "50581724" },
      { "name": "Non GMO dr dupuis number 2 avocados", "code": "50581725" },
      { "name": "Non GMO dr dupuis avocados", "code": "50581726" },
      { "name": "Non GMO ettinger avocados", "code": "50581727" },
      { "name": "Non GMO fuchs avocados", "code": "50581728" },
      { "name": "Non GMO fuchs gwen avocados", "code": "50581729" },
      { "name": "Non GMO fuerte avocados", "code": "50581730" },
      { "name": "Non GMO gorham avocados", "code": "50581731" },
      { "name": "Non GMO gossman avocados", "code": "50581732" },
      { "name": "Non GMO guatemalan seedling avocados", "code": "50581733" },
      { "name": "Non GMO hall avocados", "code": "50581734" },
      { "name": "Non GMO hardee avocados", "code": "50581735" },
      { "name": "Non GMO haas avocados", "code": "50581736" },
      { "name": "Non GMO herman avocados", "code": "50581737" },
      { "name": "Non GMO hickson avocados", "code": "50581738" },
      { "name": "Non GMO k-5 avocados", "code": "50581739" },
      { "name": "Non GMO k-9 avocados", "code": "50581740" },
      { "name": "Non GMO lamb haas avocados", "code": "50581741" },
      { "name": "Non GMO leona avocados", "code": "50581742" },
      { "name": "Non GMO leona linda avocados", "code": "50581743" },
      { "name": "Non GMO lisa p avocados", "code": "50581744" },
      { "name": "Non GMO lisa loretta avocados", "code": "50581745" },
      { "name": "Non GMO loretta avocados", "code": "50581746" },
      { "name": "Non GMO lula avocados", "code": "50581747" },
      { "name": "Non GMO lula macarthur avocados", "code": "50581748" },
      { "name": "Non GMO marcus avocados", "code": "50581749" },
      { "name": "Non GMO melendez avocados", "code": "50581750" },
      { "name": "Non GMO meya p avocados", "code": "50581751" },
      { "name": "Non GMO miguel p avocados", "code": "50581752" },
      { "name": "Non GMO monroe avocados", "code": "50581753" },
      { "name": "Non GMO murrieta green avocados", "code": "50581754" },
      { "name": "Non GMO nabal avocados", "code": "50581755" },
      { "name": "Non GMO nadir avocados", "code": "50581756" },
      { "name": "Non GMO nesbitt avocados", "code": "50581757" },
      { "name": "Non GMO peterson avocados", "code": "50581758" },
      { "name": "Non GMO pinelli avocados", "code": "50581759" },
      { "name": "Non GMO pinkerton avocados", "code": "50581760" },
      { "name": "Non GMO pollock avocados", "code": "50581761" },
      { "name": "Non GMO puebla avocados", "code": "50581762" },
      { "name": "Non GMO reed avocados", "code": "50581763" },
      { "name": "Non GMO rue avocados", "code": "50581764" },
      { "name": "Non GMO ruehle avocados", "code": "50581765" },
      { "name": "Non GMO ryan avocados", "code": "50581766" },
      { "name": "Non GMO semil 34 avocados", "code": "50581767" },
      { "name": "Non GMO semil 43 avocados", "code": "50581768" },
      { "name": "Non GMO simmonds avocados", "code": "50581769" },
      { "name": "Non GMO simpson avocados", "code": "50581770" },
      { "name": "Non GMO taylor avocados", "code": "50581771" },
      { "name": "Non GMO tonnage avocados", "code": "50581772" },
      { "name": "Non GMO tower avocados", "code": "50581773" },
      { "name": "Non GMO tower li avocados", "code": "50581774" },
      { "name": "Non GMO trapp avocados", "code": "50581775" },
      { "name": "Non GMO west indian seedling avocado", "code": "50581776" },
      { "name": "Non GMO wagner avocados", "code": "50581777" },
      { "name": "Non GMO waldin avocados", "code": "50581778" },
      { "name": "Non GMO wurtz avocados", "code": "50581779" },
      { "name": "Non GMO zio p avocados", "code": "50581780" },
      { "name": "Non GMO ziu avocados", "code": "50581781" },
      { "name": "Non GMO zutano avocados", "code": "50581782" },
      { "name": "Non GMO beans", "code": "50581800" },
      { "name": "Non GMO anasazi or aztec beans", "code": "50581801" },
      { "name": "Non GMO appaloosa beans", "code": "50581802" },
      { "name": "Non GMO azuki beans", "code": "50581803" },
      { "name": "Non GMO barlotti beans", "code": "50581804" },
      { "name": "Non GMO black appaloosa beans", "code": "50581805" },
      { "name": "Non GMO black beans", "code": "50581806" },
      { "name": "Non GMO black gram beans", "code": "50581807" },
      { "name": "Non GMO black shackamaxon beans", "code": "50581808" },
      { "name": "Non GMO blackeyed beans", "code": "50581809" },
      { "name": "Non GMO bobby beans", "code": "50581810" },
      { "name": "Non GMO bolita beans", "code": "50581811" },
      { "name": "Non GMO brown lazy wife beans", "code": "50581812" },
      { "name": "Non GMO calypso beans", "code": "50581813" },
      { "name": "Non GMO cannellini beans", "code": "50581814" },
      { "name": "Non GMO castor beans", "code": "50581815" },
      { "name": "Non GMO china yellow beans", "code": "50581816" },
      { "name": "Non GMO dragon tongue beans", "code": "50581817" },
      { "name": "Non GMO european soldier beans", "code": "50581818" },
      { "name": "Non GMO fava beans", "code": "50581819" },
      { "name": "Non GMO flageolet beans", "code": "50581820" },
      { "name": "Non GMO french horticultural beans", "code": "50581821" },
      { "name": "Non GMO french navy beans", "code": "50581822" },
      { "name": "Non GMO giant white coco beans", "code": "50581823" },
      { "name": "Non GMO green beans", "code": "50581824" },
      { "name": "Non GMO green romano beans", "code": "50581825" },
      { "name": "Non GMO guar gum beans", "code": "50581826" },
      { "name": "Non GMO haricot beans", "code": "50581827" },
      { "name": "Non GMO hyacinth beans", "code": "50581828" },
      { "name": "Non GMO italian type beans", "code": "50581829" },
      { "name": "Non GMO jackson wonder beans", "code": "50581830" },
      { "name": "Non GMO jacob's cattle beans", "code": "50581831" },
      { "name": "Non GMO kentucky wonder beans", "code": "50581832" },
      { "name": "Non GMO kidney beans", "code": "50581833" },
      { "name": "Non GMO lima beans", "code": "50581834" },
      { "name": "Non GMO madeira/madera beans", "code": "50581835" },
      { "name": "Non GMO marrow beans", "code": "50581836" },
      { "name": "Non GMO mat beans", "code": "50581837" },
      { "name": "Non GMO monstoller wild goose beans", "code": "50581838" },
      { "name": "Non GMO mortgage lifter beans", "code": "50581839" },
      { "name": "Non GMO moth beans", "code": "50581840" },
      { "name": "Non GMO mung beans", "code": "50581841" },
      { "name": "Non GMO munsi wolf bean", "code": "50581842" },
      { "name": "Non GMO nuna beans", "code": "50581843" },
      { "name": "Non GMO pinto beans", "code": "50581844" },
      { "name": "Non GMO pole beans", "code": "50581845" },
      { "name": "Non GMO runner beans", "code": "50581846" },
      { "name": "Non GMO string beans", "code": "50581847" },
      { "name": "Non GMO tamarind beans", "code": "50581848" },
      { "name": "Non GMO tonka beans", "code": "50581849" },
      { "name": "Non GMO wax beans", "code": "50581850" },
      { "name": "Non GMO winged beans", "code": "50581851" },
      { "name": "Non GMO yard long beans", "code": "50581852" },
      { "name": "Non GMO beets", "code": "50581900" },
      { "name": "Non GMO action beets", "code": "50581901" },
      { "name": "Non GMO albina vereduna beets", "code": "50581902" },
      { "name": "Non GMO barbabietola di chioggia beets", "code": "50581903" },
      { "name": "Non GMO boltardy beets", "code": "50581904" },
      { "name": "Non GMO bonel beets", "code": "50581905" },
      { "name": "Non GMO burpees golden beets", "code": "50581906" },
      { "name": "Non GMO cheltenham green top beets", "code": "50581907" },
      { "name": "Non GMO cheltenham mono beets", "code": "50581908" },
      { "name": "Non GMO chioggia beets", "code": "50581909" },
      { "name": "Non GMO cylindra beets", "code": "50581910" },
      { "name": "Non GMO d'egypte beets", "code": "50581911" },
      { "name": "Non GMO detroit 2 dark red beets", "code": "50581912" },
      { "name": "Non GMO detroit 2 little ball beets", "code": "50581913" },
      { "name": "Non GMO egyptian flat beets", "code": "50581914" },
      { "name": "Non GMO egyptian turnip rooted beets", "code": "50581915" },
      { "name": "Non GMO formanova beets", "code": "50581916" },
      { "name": "Non GMO forono beets", "code": "50581917" },
      { "name": "Non GMO monaco beets", "code": "50581918" },
      { "name": "Non GMO monogram beets", "code": "50581919" },
      { "name": "Non GMO pronto beets", "code": "50581920" },
      { "name": "Non GMO regalia beets", "code": "50581921" },
      { "name": "Non GMO sugar beets", "code": "50581922" },
      { "name": "Non GMO broccoli", "code": "50582000" },
      { "name": "Non GMO broccolini", "code": "50582001" },
      { "name": "Non GMO broccoli romanesco", "code": "50582002" },
      { "name": "Non GMO broccoli raab", "code": "50582003" },
      { "name": "Non GMO chinese broccoli", "code": "50582004" },
      { "name": "Non GMO brussel sprouts", "code": "50582100" },
      { "name": "Non GMO citadel brussel sprouts", "code": "50582101" },
      { "name": "Non GMO falstaff brussel sprouts", "code": "50582102" },
      { "name": "Non GMO oliver brussel sprouts", "code": "50582103" },
      { "name": "Non GMO peer gynt brussel sprouts", "code": "50582104" },
      { "name": "Non GMO rampart brussel sprouts", "code": "50582105" },
      { "name": "Non GMO rubine brussel sprouts", "code": "50582106" },
      { "name": "Non GMO widgeon brussel sprouts", "code": "50582107" },
      { "name": "Non GMO bunching onions", "code": "50582200" },
      { "name": "Non GMO beltsville bunching onions", "code": "50582201" },
      { "name": "Non GMO feast bunching onions", "code": "50582202" },
      { "name": "Non GMO ishikura bunching onions", "code": "50582203" },
      { "name": "Non GMO kyoto market bunching onions", "code": "50582204" },
      { "name": "Non GMO red beard bunching onions", "code": "50582205" },
      { "name": "Non GMO redmate bunching onions", "code": "50582206" },
      { "name": "Non GMO santa claus bunching onions", "code": "50582207" },
      { "name": "Non GMO tokyo bunching onions", "code": "50582208" },
      { "name": "Non GMO white lisbon bunching onions", "code": "50582209" },
      { "name": "Non GMO winter white bunching onions", "code": "50582210" },
      { "name": "Non GMO winter-over bunching onions", "code": "50582211" },
      { "name": "Non GMO cabbages", "code": "50582300" },
      { "name": "Non GMO black cabbages", "code": "50582301" },
      { "name": "Non GMO savoy cabbages", "code": "50582302" },
      { "name": "Non GMO skunk cabbages", "code": "50582303" },
      { "name": "Non GMO white cabbages", "code": "50582304" },
      { "name": "Non GMO cardoons", "code": "50582400" },
      { "name": "Non GMO lunghi cardoons", "code": "50582401" },
      { "name": "Non GMO gobbi cardoons", "code": "50582402" },
      { "name": "Non GMO carrots", "code": "50582500" },
      { "name": "Non GMO amsterdam carrots", "code": "50582501" },
      { "name": "Non GMO autumn king carrots", "code": "50582502" },
      { "name": "Non GMO berlicum carrots", "code": "50582503" },
      { "name": "Non GMO chantenay carrots", "code": "50582504" },
      { "name": "Non GMO nantes carrots", "code": "50582505" },
      { "name": "Non GMO paris market carrots", "code": "50582506" },
      { "name": "Non GMO cauliflowers", "code": "50582600" },
      { "name": "Non GMO all the year round cauliflowers", "code": "50582601" },
      { "name": "Non GMO alverda cauliflowers", "code": "50582602" },
      { "name": "Non GMO autumn giant 3 cauliflowers", "code": "50582603" },
      { "name": "Non GMO dok elgon cauliflowers", "code": "50582604" },
      { "name": "Non GMO early snowball cauliflowers", "code": "50582605" },
      { "name": "Non GMO limelight cauliflowers", "code": "50582606" },
      { "name": "Non GMO minaret cauliflowers", "code": "50582607" },
      { "name": "Non GMO orange bouquet cauliflowers", "code": "50582608" },
      { "name": "Non GMO purple cape cauliflowers", "code": "50582609" },
      { "name": "Non GMO snowball cauliflowers", "code": "50582610" },
      { "name": "Non GMO walcheren winter 3 cauliflowers", "code": "50582611" },
      { "name": "Non GMO white rock cauliflowers", "code": "50582612" },
      { "name": "Non GMO celery", "code": "50582700" },
      { "name": "Non GMO celebrity celery", "code": "50582701" },
      { "name": "Non GMO celeriac", "code": "50582702" },
      { "name": "Non GMO chinese celery", "code": "50582703" },
      { "name": "Non GMO french dinant celery", "code": "50582704" },
      { "name": "Non GMO giant pink celery", "code": "50582705" },
      { "name": "Non GMO giant red celery", "code": "50582706" },
      { "name": "Non GMO giant white celery", "code": "50582707" },
      { "name": "Non GMO golden self-blanching celery", "code": "50582708" },
      { "name": "Non GMO greensleeves celery", "code": "50582709" },
      { "name": "Non GMO hopkins fenlander celery", "code": "50582710" },
      { "name": "Non GMO ivory tower celery", "code": "50582711" },
      { "name": "Non GMO lathom self-blanching celery", "code": "50582712" },
      { "name": "Non GMO soup celery d'amsterdam", "code": "50582713" },
      { "name": "Non GMO standard bearer celery", "code": "50582714" },
      { "name": "Non GMO tall utah triumph celery", "code": "50582715" },
      { "name": "Non GMO chards", "code": "50582800" },
      { "name": "Non GMO fordhook giant chard", "code": "50582801" },
      { "name": "Non GMO lucullus chard", "code": "50582802" },
      { "name": "Non GMO perpetual spinach chard", "code": "50582803" },
      { "name": "Non GMO rhubarb chard", "code": "50582804" },
      { "name": "Non GMO swiss chard", "code": "50582805" },
      { "name": "Non GMO vulcan chard", "code": "50582806" },
      { "name": "Non GMO white king chard", "code": "50582807" },
      { "name": "Non GMO chicories", "code": "50582900" },
      { "name": "Non GMO broad leaved batavian chicory", "code": "50582901" },
      { "name": "Non GMO en cornet de bordeaux chicory", "code": "50582902" },
      { "name": "Non GMO green curled ruffee chicory", "code": "50582903" },
      { "name": "Non GMO green curled chicory", "code": "50582904" },
      { "name": "Non GMO ione limnos chicory", "code": "50582905" },
      { "name": "Non GMO riccia pancalieri chicory", "code": "50582906" },
      { "name": "Non GMO salad king chicory", "code": "50582907" },
      { "name": "Non GMO sanda chicory", "code": "50582908" },
      { "name": "Non GMO scarola verde chicory", "code": "50582909" },
      { "name": "Non GMO tres fine maraichere chicory", "code": "50582910" },
      {
        "name": "Non GMO wallone freisee weschelkopf chicory",
        "code": "50582911"
      },
      { "name": "Non GMO chinese cabbages", "code": "50583000" },
      { "name": "Non GMO bok choy", "code": "50583001" },
      { "name": "Non GMO chinese flat-headed cabbage", "code": "50583002" },
      { "name": "Non GMO chinese flowering cabbage", "code": "50583003" },
      { "name": "Non GMO choy sum", "code": "50583004" },
      { "name": "Non GMO dwarf bok choy", "code": "50583005" },
      { "name": "Non GMO fengshan bok choy", "code": "50583006" },
      { "name": "Non GMO jade pagoda bok choy", "code": "50583007" },
      { "name": "Non GMO kasumi bok choy", "code": "50583008" },
      { "name": "Non GMO nerva bok choy", "code": "50583009" },
      { "name": "Non GMO rosette bok choy", "code": "50583010" },
      { "name": "Non GMO ruffles bok choy", "code": "50583011" },
      { "name": "Non GMO santo serrated leaved", "code": "50583012" },
      { "name": "Non GMO shanghai d bok choy", "code": "50583013" },
      { "name": "Non GMO shantung", "code": "50583014" },
      { "name": "Non GMO tip top cabbage", "code": "50583015" },
      { "name": "Non GMO yau choy sum", "code": "50583016" },
      { "name": "Non GMO chives", "code": "50583100" },
      { "name": "Non GMO chinese chives", "code": "50583101" },
      { "name": "Non GMO common Chives", "code": "50583102" },
      { "name": "Non GMO cresses", "code": "50583200" },
      { "name": "Non GMO land cress", "code": "50583201" },
      { "name": "Non GMO nasturtium", "code": "50583202" },
      { "name": "Non GMO watercress", "code": "50583203" },
      { "name": "Non GMO wintercress", "code": "50583204" },
      { "name": "Non GMO cucumbers", "code": "50583300" },
      { "name": "Non GMO arena cucumbers", "code": "50583301" },
      { "name": "Non GMO armenian cucumbers", "code": "50583302" },
      { "name": "Non GMO athene cucumbers", "code": "50583303" },
      {
        "name": "Non GMO bianco lungo di parigi cucumbers",
        "code": "50583304"
      },
      { "name": "Non GMO burpless tasty green cucumbers", "code": "50583305" },
      { "name": "Non GMO chicago pickling cucumbers", "code": "50583306" },
      { "name": "Non GMO crystal apple cucumbers", "code": "50583307" },
      { "name": "Non GMO crystal lemon cucumbers", "code": "50583308" },
      { "name": "Non GMO danimas cucumbers", "code": "50583309" },
      { "name": "Non GMO gherkin cucumbers", "code": "50583310" },
      { "name": "Non GMO hokus cucumbers", "code": "50583311" },
      { "name": "Non GMO japanese cucumbers", "code": "50583312" },
      { "name": "Non GMO karela cucumbers", "code": "50583313" },
      { "name": "Non GMO korila cucumbers", "code": "50583314" },
      { "name": "Non GMO long green improved cucumbers", "code": "50583315" },
      { "name": "Non GMO marketmore cucumbers", "code": "50583316" },
      { "name": "Non GMO midget cucumbers", "code": "50583317" },
      { "name": "Non GMO national pickling cucumbers", "code": "50583318" },
      { "name": "Non GMO persian cucumbers", "code": "50583319" },
      { "name": "Non GMO telegraph cucumbers", "code": "50583320" },
      { "name": "Non GMO telegraph improved cucumbers", "code": "50583321" },
      {
        "name": "Non GMO vert de massy cornichon cucumbers",
        "code": "50583322"
      },
      { "name": "Non GMO yamato cucumbers", "code": "50583323" },
      { "name": "Non GMO eggplants", "code": "50583400" },
      { "name": "Non GMO bambino eggplants", "code": "50583401" },
      { "name": "Non GMO black beauty eggplants", "code": "50583402" },
      { "name": "Non GMO black enorma eggplants", "code": "50583403" },
      { "name": "Non GMO chinese eggplants", "code": "50583404" },
      { "name": "Non GMO easter egg eggplants", "code": "50583405" },
      { "name": "Non GMO filipino eggplants", "code": "50583406" },
      { "name": "Non GMO florida market eggplants", "code": "50583407" },
      { "name": "Non GMO indian eggplants", "code": "50583408" },
      { "name": "Non GMO italian eggplants", "code": "50583409" },
      { "name": "Non GMO japanese eggplants", "code": "50583410" },
      { "name": "Non GMO long purple eggplants", "code": "50583411" },
      { "name": "Non GMO long striped eggplants", "code": "50583412" },
      { "name": "Non GMO moneymaker eggplants", "code": "50583413" },
      { "name": "Non GMO ova eggplants", "code": "50583414" },
      { "name": "Non GMO pea eggplants", "code": "50583415" },
      { "name": "Non GMO short tom eggplants", "code": "50583416" },
      { "name": "Non GMO sicilian eggplants", "code": "50583417" },
      { "name": "Non GMO thai eggplants", "code": "50583418" },
      { "name": "Non GMO violette di firenze eggplants", "code": "50583419" },
      { "name": "Non GMO white eggplants", "code": "50583420" },
      { "name": "Non GMO endives", "code": "50583500" },
      { "name": "Non GMO brussels witloof endives", "code": "50583501" },
      { "name": "Non GMO castelfranco endives", "code": "50583502" },
      { "name": "Non GMO catalogna di galatina endives", "code": "50583503" },
      { "name": "Non GMO chioggia endives", "code": "50583504" },
      { "name": "Non GMO grumolo verde endives", "code": "50583505" },
      { "name": "Non GMO large rooted magdeburg endives", "code": "50583506" },
      {
        "name": "Non GMO palla rossa zorzi precoce endives",
        "code": "50583507"
      },
      { "name": "Non GMO radice amare endives", "code": "50583508" },
      { "name": "Non GMO rossa di treviso endives", "code": "50583509" },
      { "name": "Non GMO rossa di verona endives", "code": "50583510" },
      { "name": "Non GMO soncino endives", "code": "50583511" },
      { "name": "Non GMO sugarhat endives", "code": "50583512" },
      { "name": "Non GMO verona endives", "code": "50583513" },
      { "name": "Non GMO witloof zoom endives", "code": "50583514" },
      { "name": "Non GMO fennels", "code": "50583600" },
      { "name": "Non GMO cantino fennel", "code": "50583601" },
      { "name": "Non GMO fino fennel", "code": "50583602" },
      { "name": "Non GMO herald fennel", "code": "50583603" },
      { "name": "Non GMO perfection fennel", "code": "50583604" },
      { "name": "Non GMO sirio fennel", "code": "50583605" },
      { "name": "Non GMO sweet florence fennel", "code": "50583606" },
      { "name": "Non GMO tardo fennel", "code": "50583607" },
      { "name": "Non GMO garlics", "code": "50583700" },
      { "name": "Non GMO california late garlic", "code": "50583701" },
      { "name": "Non GMO chinese garlic stems", "code": "50583702" },
      { "name": "Non GMO garlic chives", "code": "50583703" },
      { "name": "Non GMO germidor garlic", "code": "50583704" },
      { "name": "Non GMO long keeper garlic", "code": "50583705" },
      { "name": "Non GMO ramson garlic", "code": "50583706" },
      { "name": "Non GMO rocambole garlic", "code": "50583707" },
      { "name": "Non GMO rose de lautrec garlic", "code": "50583708" },
      { "name": "Non GMO solent wight garlic", "code": "50583709" },
      { "name": "Non GMO spanish morado garlic", "code": "50583710" },
      { "name": "Non GMO venetian/italian garlic", "code": "50583711" },
      { "name": "Non GMO gourds", "code": "50583800" },
      { "name": "Non GMO angled loofah", "code": "50583801" },
      { "name": "Non GMO bitter gourd", "code": "50583802" },
      { "name": "Non GMO bottle gourd", "code": "50583803" },
      { "name": "Non GMO calabash gourds", "code": "50583804" },
      { "name": "Non GMO fuzzy/hairy melon", "code": "50583805" },
      { "name": "Non GMO musky gourd", "code": "50583806" },
      { "name": "Non GMO smooth loofah", "code": "50583807" },
      { "name": "Non GMO snake gourd", "code": "50583808" },
      { "name": "Non GMO spiny bitter gourd", "code": "50583809" },
      { "name": "Non GMO tinda gourds", "code": "50583810" },
      { "name": "Non GMO tindoori gourds", "code": "50583811" },
      { "name": "Non GMO green peas", "code": "50583900" },
      { "name": "Non GMO china peas", "code": "50583901" },
      { "name": "Non GMO english peas", "code": "50583902" },
      { "name": "Non GMO garden peas", "code": "50583903" },
      { "name": "Non GMO snow peas", "code": "50583904" },
      { "name": "Non GMO sugar snap peas", "code": "50583905" },
      { "name": "Non GMO herbs", "code": "50584000" },
      { "name": "Non GMO basil", "code": "50584001" },
      { "name": "Non GMO bay leaves", "code": "50584002" },
      { "name": "Non GMO borage", "code": "50584003" },
      { "name": "Non GMO caraway", "code": "50584004" },
      { "name": "Non GMO chervil", "code": "50584005" },
      { "name": "Non GMO cilantro", "code": "50584006" },
      { "name": "Non GMO cipolinos", "code": "50584007" },
      { "name": "Non GMO curry leaves", "code": "50584008" },
      { "name": "Non GMO dill", "code": "50584009" },
      { "name": "Non GMO epazote", "code": "50584010" },
      { "name": "Non GMO fenugreek", "code": "50584011" },
      { "name": "Non GMO lemon grass", "code": "50584012" },
      { "name": "Non GMO marjoram", "code": "50584013" },
      { "name": "Non GMO mint", "code": "50584014" },
      { "name": "Non GMO oregano", "code": "50584015" },
      { "name": "Non GMO papalo", "code": "50584016" },
      { "name": "Non GMO pepicha", "code": "50584017" },
      { "name": "Non GMO perilla", "code": "50584018" },
      { "name": "Non GMO recao", "code": "50584019" },
      { "name": "Non GMO rosemary", "code": "50584020" },
      { "name": "Non GMO sage", "code": "50584021" },
      { "name": "Non GMO salsify", "code": "50584022" },
      { "name": "Non GMO savory", "code": "50584023" },
      { "name": "Non GMO tarragon", "code": "50584024" },
      { "name": "Non GMO thyme", "code": "50584025" },
      { "name": "Non GMO tumeric", "code": "50584026" },
      { "name": "Non GMO verdulaga", "code": "50584027" },
      { "name": "Non GMO kale", "code": "50584100" },
      { "name": "Non GMO curly kale", "code": "50584101" },
      { "name": "Non GMO collard greens", "code": "50584102" },
      { "name": "Non GMO kohlrabi", "code": "50584200" },
      { "name": "Non GMO azur star kohlrabi", "code": "50584201" },
      { "name": "Non GMO green vienna kohlrabi", "code": "50584202" },
      { "name": "Non GMO lanro kohlrabi", "code": "50584203" },
      { "name": "Non GMO purple vienna kohlrabi", "code": "50584204" },
      { "name": "Non GMO rowel trero kohlrabi", "code": "50584205" },
      { "name": "Non GMO white vienna kohlrabi", "code": "50584206" },
      { "name": "Non GMO leeks", "code": "50584300" },
      { "name": "Non GMO autumn giant-cobra leeks", "code": "50584301" },
      { "name": "Non GMO autumn mammoth 2 leeks", "code": "50584302" },
      { "name": "Non GMO bleu de solaise leeks", "code": "50584303" },
      { "name": "Non GMO cortina leeks", "code": "50584304" },
      { "name": "Non GMO prelina leeks", "code": "50584305" },
      { "name": "Non GMO wild leek ramp", "code": "50584306" },
      { "name": "Non GMO lentils", "code": "50584400" },
      { "name": "Non GMO beluga lentils", "code": "50584401" },
      { "name": "Non GMO french green lentils", "code": "50584402" },
      { "name": "Non GMO green lentils", "code": "50584403" },
      { "name": "Non GMO petite crimson lentils", "code": "50584404" },
      { "name": "Non GMO spanish pardina lentils", "code": "50584405" },
      { "name": "Non GMO split red lentils", "code": "50584406" },
      { "name": "Non GMO split yellow lentils", "code": "50584407" },
      { "name": "Non GMO tarahumara pinks lentils", "code": "50584408" },
      { "name": "Non GMO lettuces", "code": "50584500" },
      { "name": "Non GMO bibb lettuce", "code": "50584501" },
      { "name": "Non GMO boston lettuce", "code": "50584502" },
      { "name": "Non GMO frisee lettuce", "code": "50584503" },
      { "name": "Non GMO lolla rossa lettuce", "code": "50584504" },
      { "name": "Non GMO mesculin mix lettuce", "code": "50584505" },
      { "name": "Non GMO mizuna lettuce", "code": "50584506" },
      { "name": "Non GMO red leaf lettuce", "code": "50584507" },
      { "name": "Non GMO red oak leaf lettuce", "code": "50584508" },
      { "name": "Non GMO ruby romaine lettuce", "code": "50584509" },
      { "name": "Non GMO baby red romaine lettuce", "code": "50584510" },
      { "name": "Non GMO butterhead lettuces", "code": "50584511" },
      { "name": "Non GMO chinese lettuces", "code": "50584512" },
      { "name": "Non GMO crisphead lettuces", "code": "50584513" },
      { "name": "Non GMO green leaf lettuce", "code": "50584514" },
      { "name": "Non GMO iceberg lettuce", "code": "50584515" },
      { "name": "Non GMO lamb\u2019s lettuces", "code": "50584516" },
      { "name": "Non GMO looseleaf lettuces", "code": "50584517" },
      { "name": "Non GMO mache lettuce", "code": "50584518" },
      { "name": "Non GMO red boston lettuce", "code": "50584519" },
      { "name": "Non GMO red headed lettuces", "code": "50584520" },
      { "name": "Non GMO romaine lettuces", "code": "50584521" },
      { "name": "Non GMO russian red mustard lettuce", "code": "50584522" },
      { "name": "Non GMO tatsoi lettuce", "code": "50584523" },
      { "name": "Non GMO malanga", "code": "50584600" },
      { "name": "Non GMO blanca malanga", "code": "50584601" },
      { "name": "Non GMO coco malanga", "code": "50584602" },
      { "name": "Non GMO eddoes malanga", "code": "50584603" },
      { "name": "Non GMO islena malanga", "code": "50584604" },
      { "name": "Non GMO lila malanga", "code": "50584605" },
      { "name": "Non GMO amarilla malanga", "code": "50584606" },
      { "name": "Non GMO mushrooms", "code": "50584700" },
      { "name": "Non GMO black trumpet mushrooms", "code": "50584701" },
      { "name": "Non GMO brown mushrooms", "code": "50584702" },
      { "name": "Non GMO champinion mushrooms", "code": "50584703" },
      { "name": "Non GMO chanterelle mushrooms", "code": "50584704" },
      { "name": "Non GMO cremini mushrooms", "code": "50584705" },
      { "name": "Non GMO enoki mushrooms", "code": "50584706" },
      { "name": "Non GMO hedge hog mushrooms", "code": "50584707" },
      { "name": "Non GMO hen of the woods mushrooms", "code": "50584708" },
      { "name": "Non GMO lobster mushrooms", "code": "50584709" },
      { "name": "Non GMO morels mushrooms", "code": "50584710" },
      { "name": "Non GMO oyster mushrooms", "code": "50584711" },
      { "name": "Non GMO pleurotus mushrooms", "code": "50584712" },
      { "name": "Non GMO pompom mushrooms", "code": "50584713" },
      { "name": "Non GMO porcieni mushrooms", "code": "50584714" },
      { "name": "Non GMO portobella mushrooms", "code": "50584715" },
      { "name": "Non GMO shiitake mushrooms", "code": "50584716" },
      { "name": "Non GMO shimeji mushrooms", "code": "50584717" },
      { "name": "Non GMO st george's mushrooms", "code": "50584718" },
      { "name": "Non GMO white mushrooms", "code": "50584719" },
      { "name": "Non GMO white trumpet mushrooms", "code": "50584720" },
      { "name": "Non GMO woodear mushrooms", "code": "50584721" },
      { "name": "Non GMO mustards", "code": "50584800" },
      { "name": "Non GMO bamboo mustard", "code": "50584801" },
      { "name": "Non GMO garlic mustard", "code": "50584802" },
      { "name": "Non GMO giantleafed mustard", "code": "50584803" },
      { "name": "Non GMO red in snow mustard", "code": "50584804" },
      { "name": "Non GMO southern mustard", "code": "50584805" },
      { "name": "Non GMO wrapped heart mustard", "code": "50584806" },
      { "name": "Non GMO nightshades", "code": "50584900" },
      { "name": "Non GMO chinese lantern", "code": "50584901" },
      { "name": "Non GMO garden huckleberry", "code": "50584902" },
      { "name": "Non GMO naranjilla", "code": "50584903" },
      { "name": "Non GMO tomatillo", "code": "50584904" },
      { "name": "Non GMO okras", "code": "50585000" },
      { "name": "Non GMO artist okra", "code": "50585001" },
      { "name": "Non GMO burgundy okra", "code": "50585002" },
      { "name": "Non GMO clemson spineless okra", "code": "50585003" },
      { "name": "Non GMO dwarf green long pod okra", "code": "50585004" },
      { "name": "Non GMO mammoth spineless long pod okra", "code": "50585005" },
      { "name": "Non GMO red velvet okra", "code": "50585006" },
      { "name": "Non GMO star of david heirloom okra", "code": "50585007" },
      { "name": "Non GMO onions", "code": "50585100" },
      { "name": "Non GMO albion onions", "code": "50585101" },
      { "name": "Non GMO alisa craig onions", "code": "50585102" },
      { "name": "Non GMO boiling onions", "code": "50585103" },
      { "name": "Non GMO buffalo onions", "code": "50585104" },
      { "name": "Non GMO bulb onions", "code": "50585105" },
      { "name": "Non GMO creaming onions", "code": "50585106" },
      { "name": "Non GMO express yellow o-x onions", "code": "50585107" },
      { "name": "Non GMO kelsae onions", "code": "50585108" },
      {
        "name": "Non GMO marshalls giant fen globe onions",
        "code": "50585109"
      },
      { "name": "Non GMO pearl onions", "code": "50585110" },
      { "name": "Non GMO red baron onions", "code": "50585111" },
      { "name": "Non GMO red onions", "code": "50585112" },
      { "name": "Non GMO rijnsberger onions", "code": "50585113" },
      {
        "name": "Non GMO senshyu semi-globe yellow onions",
        "code": "50585114"
      },
      { "name": "Non GMO sturon onions", "code": "50585115" },
      { "name": "Non GMO stuttgarter giant onions", "code": "50585116" },
      { "name": "Non GMO sweet onions", "code": "50585117" },
      { "name": "Non GMO torpedo or red italian onions", "code": "50585118" },
      { "name": "Non GMO red storage onions", "code": "50585119" },
      { "name": "Non GMO white storage onions", "code": "50585120" },
      { "name": "Non GMO yellow storage onions", "code": "50585121" },
      { "name": "Non GMO peanuts", "code": "50585200" },
      { "name": "Non GMO bambarra groundnut peanuts", "code": "50585201" },
      { "name": "Non GMO florunner peanuts", "code": "50585202" },
      {
        "name": "Non GMO hausa/kersting's ground nut peanuts",
        "code": "50585203"
      },
      { "name": "Non GMO spanish peanuts", "code": "50585204" },
      { "name": "Non GMO valencia peanuts", "code": "50585205" },
      { "name": "Non GMO virginia peanuts", "code": "50585206" },
      { "name": "Non GMO peas", "code": "50585300" },
      { "name": "Non GMO purple hull peas", "code": "50585301" },
      { "name": "Non GMO pinkeye peas", "code": "50585302" },
      { "name": "Non GMO crowder peas", "code": "50585303" },
      { "name": "Non GMO white acre peas", "code": "50585304" },
      { "name": "Non GMO blackeyed peas", "code": "50585305" },
      { "name": "Non GMO zipper cream peas", "code": "50585306" },
      { "name": "Non GMO peppers", "code": "50585400" },
      { "name": "Non GMO ajies peppers", "code": "50585401" },
      { "name": "Non GMO arbol peppers", "code": "50585402" },
      { "name": "Non GMO cheese peppers", "code": "50585403" },
      { "name": "Non GMO chilaca peppers", "code": "50585404" },
      { "name": "Non GMO cubanelles peppers", "code": "50585405" },
      { "name": "Non GMO fresno peppers", "code": "50585406" },
      { "name": "Non GMO kapia peppers", "code": "50585407" },
      { "name": "Non GMO korean peppers", "code": "50585408" },
      { "name": "Non GMO manzano peppers", "code": "50585409" },
      { "name": "Non GMO melrose peppers", "code": "50585410" },
      { "name": "Non GMO yellow chile peppers", "code": "50585411" },
      { "name": "Non GMO aji dulces peppers", "code": "50585412" },
      { "name": "Non GMO anaheim peppers", "code": "50585413" },
      { "name": "Non GMO ancho peppers", "code": "50585414" },
      { "name": "Non GMO bell peppers", "code": "50585415" },
      { "name": "Non GMO cascabel peppers", "code": "50585416" },
      { "name": "Non GMO cayenne peppers", "code": "50585417" },
      { "name": "Non GMO cherry hots peppers", "code": "50585418" },
      { "name": "Non GMO chiltecpin peppers", "code": "50585419" },
      { "name": "Non GMO finger hot peppers", "code": "50585420" },
      { "name": "Non GMO guajillo peppers", "code": "50585421" },
      { "name": "Non GMO guerro peppers", "code": "50585422" },
      { "name": "Non GMO habanero peppers", "code": "50585423" },
      { "name": "Non GMO hungarian wax peppers", "code": "50585424" },
      { "name": "Non GMO jalape\u00f1o peppers", "code": "50585425" },
      { "name": "Non GMO long hot peppers", "code": "50585426" },
      { "name": "Non GMO mirasol peppers", "code": "50585427" },
      { "name": "Non GMO pasilla peppers", "code": "50585428" },
      { "name": "Non GMO peperoncini peppers", "code": "50585429" },
      { "name": "Non GMO pequin peppers", "code": "50585430" },
      { "name": "Non GMO pimiento peppers", "code": "50585431" },
      { "name": "Non GMO poblano peppers", "code": "50585432" },
      { "name": "Non GMO scotch bonnet peppers", "code": "50585433" },
      { "name": "Non GMO serrano peppers", "code": "50585434" },
      { "name": "Non GMO tabasco peppers", "code": "50585435" },
      { "name": "Non GMO tai peppers", "code": "50585436" },
      { "name": "Non GMO tepin peppers", "code": "50585437" },
      { "name": "Non GMO potatoes", "code": "50585500" },
      { "name": "Non GMO long white potatoes", "code": "50585501" },
      { "name": "Non GMO round white potatoes", "code": "50585502" },
      { "name": "Non GMO round red potatoes", "code": "50585503" },
      { "name": "Non GMO russet potatoes", "code": "50585504" },
      { "name": "Non GMO purple potatoes", "code": "50585505" },
      { "name": "Non GMO yellow potatoes", "code": "50585506" },
      { "name": "Non GMO new potatoes", "code": "50585507" },
      { "name": "Non GMO specialty potatoes", "code": "50585508" },
      { "name": "Non GMO rutabagas", "code": "50585600" },
      { "name": "Non GMO acme rutabagas", "code": "50585601" },
      { "name": "Non GMO angela rutabagas", "code": "50585602" },
      { "name": "Non GMO best of all rutabagas", "code": "50585603" },
      { "name": "Non GMO marian rutabagas", "code": "50585604" },
      { "name": "Non GMO sea vegetables", "code": "50585700" },
      { "name": "Non GMO agar-agar", "code": "50585701" },
      { "name": "Non GMO arame", "code": "50585702" },
      { "name": "Non GMO dulse", "code": "50585703" },
      { "name": "Non GMO haricot vert de mer", "code": "50585704" },
      { "name": "Non GMO hijiki", "code": "50585705" },
      { "name": "Non GMO irish moss", "code": "50585706" },
      { "name": "Non GMO kelp", "code": "50585707" },
      { "name": "Non GMO laver", "code": "50585708" },
      { "name": "Non GMO nori", "code": "50585709" },
      { "name": "Non GMO red algae", "code": "50585710" },
      { "name": "Non GMO sea kale", "code": "50585711" },
      { "name": "Non GMO sea lettuce", "code": "50585712" },
      { "name": "Non GMO seaweeds", "code": "50585713" },
      { "name": "Non GMO spirulina", "code": "50585714" },
      { "name": "Non GMO susabi nori", "code": "50585715" },
      { "name": "Non GMO wakame", "code": "50585716" },
      { "name": "Non GMO shallots", "code": "50585800" },
      { "name": "Non GMO atlantic shallots", "code": "50585801" },
      { "name": "Non GMO creation shallots", "code": "50585802" },
      { "name": "Non GMO drittler white nest shallots", "code": "50585803" },
      { "name": "Non GMO giant yellow improved shallots", "code": "50585804" },
      { "name": "Non GMO golden gourmet shallots", "code": "50585805" },
      { "name": "Non GMO grise de bagnolet shallots", "code": "50585806" },
      { "name": "Non GMO hative de niort shallots", "code": "50585807" },
      { "name": "Non GMO pikant shallots", "code": "50585808" },
      { "name": "Non GMO red potato onions", "code": "50585809" },
      { "name": "Non GMO sante shallots", "code": "50585810" },
      { "name": "Non GMO topper shallots", "code": "50585811" },
      { "name": "Non GMO sorrels", "code": "50585900" },
      { "name": "Non GMO dock sorrel", "code": "50585901" },
      { "name": "Non GMO garden sorrel", "code": "50585902" },
      { "name": "Non GMO sheep sorrel", "code": "50585903" },
      { "name": "Non GMO wood sorrel", "code": "50585904" },
      { "name": "Non GMO spinaches", "code": "50586000" },
      { "name": "Non GMO america spinach", "code": "50586001" },
      { "name": "Non GMO bloomsdale spinach", "code": "50586002" },
      { "name": "Non GMO giant winter spinach", "code": "50586003" },
      { "name": "Non GMO horenso spinach", "code": "50586004" },
      { "name": "Non GMO lamb's quarters spinach", "code": "50586005" },
      { "name": "Non GMO malabar spinach", "code": "50586006" },
      { "name": "Non GMO medania spinach", "code": "50586007" },
      { "name": "Non GMO orach spinach", "code": "50586008" },
      { "name": "Non GMO savoy spinach", "code": "50586009" },
      { "name": "Non GMO sigmaleaf spinach", "code": "50586010" },
      { "name": "Non GMO space spinach", "code": "50586011" },
      { "name": "Non GMO trinidad spinach", "code": "50586012" },
      { "name": "Non GMO wild spinach", "code": "50586013" },
      { "name": "Non GMO new zealand spinach", "code": "50586014" },
      { "name": "Non GMO iceplant spinach", "code": "50586015" },
      {
        "name": "Non GMO summer squashes and summer pumpkins",
        "code": "50586100"
      },
      { "name": "Non GMO boston marrow squash", "code": "50586101" },
      { "name": "Non GMO butternut squash", "code": "50586102" },
      { "name": "Non GMO costata romanesca squash", "code": "50586103" },
      { "name": "Non GMO crookneck squash", "code": "50586104" },
      { "name": "Non GMO cucuzza squash", "code": "50586105" },
      { "name": "Non GMO delicata squash", "code": "50586106" },
      { "name": "Non GMO delicious squash", "code": "50586107" },
      {
        "name": "Non GMO early golden summer crookneck squash",
        "code": "50586108"
      },
      {
        "name": "Non GMO early prolific straight neck squash",
        "code": "50586109"
      },
      { "name": "Non GMO gold squash", "code": "50586110" },
      { "name": "Non GMO jack be little squash", "code": "50586111" },
      { "name": "Non GMO kentucky field squash", "code": "50586112" },
      { "name": "Non GMO marrow squash", "code": "50586113" },
      { "name": "Non GMO middle eastern squash", "code": "50586114" },
      { "name": "Non GMO miniature squash", "code": "50586115" },
      { "name": "Non GMO orangetti squash", "code": "50586116" },
      { "name": "Non GMO pattypan squash", "code": "50586117" },
      { "name": "Non GMO rondini squash", "code": "50586118" },
      { "name": "Non GMO round squash", "code": "50586119" },
      { "name": "Non GMO spaghetti squash", "code": "50586120" },
      { "name": "Non GMO stripetti squash", "code": "50586121" },
      { "name": "Non GMO sugar loaf squash", "code": "50586122" },
      { "name": "Non GMO sweet dumpling squash", "code": "50586123" },
      { "name": "Non GMO triple treat squash", "code": "50586124" },
      { "name": "Non GMO waltham butternut squash", "code": "50586125" },
      { "name": "Non GMO yellow bush scallop squash", "code": "50586126" },
      { "name": "Non GMO yellow straightneck squash", "code": "50586127" },
      { "name": "Non GMO zephyr squash", "code": "50586128" },
      { "name": "Non GMO zucchini squash", "code": "50586129" },
      { "name": "Non GMO sweet potatoes", "code": "50586200" },
      { "name": "Non GMO beauregard sweet potatoes", "code": "50586201" },
      { "name": "Non GMO centennial sweet potatoes", "code": "50586202" },
      { "name": "Non GMO diane sweet potatoes", "code": "50586203" },
      { "name": "Non GMO garnet sweet potatoes", "code": "50586204" },
      { "name": "Non GMO georgia red sweet potatoes", "code": "50586205" },
      { "name": "Non GMO goldensweet sweet potatoes", "code": "50586206" },
      { "name": "Non GMO hanna sweet potatoes", "code": "50586207" },
      { "name": "Non GMO japanese sweet potatoes", "code": "50586208" },
      { "name": "Non GMO jersey sweet potatoes", "code": "50586209" },
      { "name": "Non GMO jewel sweet potatoes", "code": "50586210" },
      { "name": "Non GMO maryland red sweet potatoes", "code": "50586211" },
      { "name": "Non GMO nemagold sweet potatoes", "code": "50586212" },
      { "name": "Non GMO o'henry sweet potatoes", "code": "50586213" },
      { "name": "Non GMO okinawan sweet potatoes", "code": "50586214" },
      { "name": "Non GMO orange sweet potatoes", "code": "50586215" },
      { "name": "Non GMO oriental sweet potatoes", "code": "50586216" },
      { "name": "Non GMO red jersey sweet potatoes", "code": "50586217" },
      { "name": "Non GMO red mar sweet potatoes", "code": "50586218" },
      { "name": "Non GMO redglow sweet potatoes", "code": "50586219" },
      { "name": "Non GMO yellow jersey sweet potatoes", "code": "50586220" },
      { "name": "Non GMO tomatoes", "code": "50586300" },
      { "name": "Non GMO ailsa craig tomatoes", "code": "50586301" },
      { "name": "Non GMO alicante tomatoes", "code": "50586302" },
      { "name": "Non GMO black plum tomatoes", "code": "50586303" },
      { "name": "Non GMO brandywine tomatoes", "code": "50586304" },
      { "name": "Non GMO cherry belle tomatoes", "code": "50586305" },
      { "name": "Non GMO cherry tomatoes", "code": "50586306" },
      { "name": "Non GMO delicious tomatoes", "code": "50586307" },
      { "name": "Non GMO dombito tomatoes", "code": "50586308" },
      { "name": "Non GMO gardener's delight tomatoes", "code": "50586309" },
      { "name": "Non GMO grape tomatoes", "code": "50586310" },
      { "name": "Non GMO green tomatoes", "code": "50586311" },
      { "name": "Non GMO marmande super tomatoes", "code": "50586312" },
      {
        "name": "Non GMO marvel striped traditional tomatoes",
        "code": "50586313"
      },
      { "name": "Non GMO minibel tomatoes", "code": "50586314" },
      { "name": "Non GMO oaxacan pink tomatoes", "code": "50586315" },
      { "name": "Non GMO red alert tomatoes", "code": "50586316" },
      { "name": "Non GMO roma vf tomatoes", "code": "50586317" },
      { "name": "Non GMO san marzano tomatoes", "code": "50586318" },
      { "name": "Non GMO shirley tomatoes", "code": "50586319" },
      { "name": "Non GMO siberia tomatoes", "code": "50586320" },
      { "name": "Non GMO super beefsteak tomatoes", "code": "50586321" },
      { "name": "Non GMO tigerella tomatoes", "code": "50586322" },
      { "name": "Non GMO tiny tim tomatoes", "code": "50586323" },
      { "name": "Non GMO tumbler tomatoes", "code": "50586324" },
      { "name": "Non GMO yellow cocktail tomatoes", "code": "50586325" },
      { "name": "Non GMO yellow pear-shaped tomatoes", "code": "50586326" },
      { "name": "Non GMO yellow perfection tomatoes", "code": "50586327" },
      { "name": "Non GMO turnip greens", "code": "50586400" },
      { "name": "Non GMO green globe turnips", "code": "50586401" },
      { "name": "Non GMO golden ball turnips", "code": "50586402" },
      { "name": "Non GMO manchester market turnips", "code": "50586403" },
      { "name": "Non GMO purple top milan turnips", "code": "50586404" },
      { "name": "Non GMO purple top white turnips", "code": "50586405" },
      { "name": "Non GMO snowball turnips", "code": "50586406" },
      { "name": "Non GMO tokyo turnip", "code": "50586407" },
      { "name": "Non GMO tokyo cross turnips", "code": "50586408" },
      {
        "name": "Non GMO winter squashes and winter pumpkins",
        "code": "50586500"
      },
      { "name": "Non GMO acorn squash", "code": "50586501" },
      { "name": "Non GMO atlantic giant squash", "code": "50586502" },
      { "name": "Non GMO banana pink squash", "code": "50586503" },
      { "name": "Non GMO big max squash", "code": "50586504" },
      { "name": "Non GMO calabaza squash", "code": "50586505" },
      { "name": "Non GMO carnival squash", "code": "50586506" },
      { "name": "Non GMO cheese pumpkin", "code": "50586507" },
      { "name": "Non GMO crown prince squash", "code": "50586508" },
      { "name": "Non GMO curcibita squash", "code": "50586509" },
      { "name": "Non GMO cushaw squash", "code": "50586510" },
      { "name": "Non GMO giant pumpkin squash", "code": "50586511" },
      { "name": "Non GMO hubbard squash", "code": "50586512" },
      { "name": "Non GMO jarrahdale squash", "code": "50586513" },
      { "name": "Non GMO kabocha squash", "code": "50586514" },
      { "name": "Non GMO queensland blue squash", "code": "50586515" },
      { "name": "Non GMO rouge vif d'etampes squash", "code": "50586516" },
      { "name": "Non GMO turk's turban squash", "code": "50586517" },
      { "name": "Non GMO valenciano squash", "code": "50586518" },
      { "name": "Non GMO warted hubbard squash", "code": "50586519" },
      { "name": "Non GMO whangaparoa crown pumpkin", "code": "50586520" },
      { "name": "Non GMO yams", "code": "50586600" },
      { "name": "Non GMO african bitter yams", "code": "50586601" },
      { "name": "Non GMO asiatic bitter yams", "code": "50586602" },
      { "name": "Non GMO chinese yams", "code": "50586603" },
      { "name": "Non GMO globe yams", "code": "50586604" },
      { "name": "Non GMO greater yams", "code": "50586605" },
      { "name": "Non GMO japanese yams", "code": "50586606" },
      { "name": "Non GMO lesser yams", "code": "50586607" },
      { "name": "Non GMO potato yams", "code": "50586608" },
      { "name": "Non GMO white guinea yams", "code": "50586609" },
      { "name": "Non GMO yellow guinea yams", "code": "50586610" },
      { "name": "Non GMO corn", "code": "50586700" },
      { "name": "Non GMO aloha corn", "code": "50586701" },
      { "name": "Non GMO alpine corn", "code": "50586702" },
      { "name": "Non GMO ambrosia corn", "code": "50586703" },
      { "name": "Non GMO argent corn", "code": "50586704" },
      { "name": "Non GMO aspen corn", "code": "50586705" },
      { "name": "Non GMO avalanche corn", "code": "50586706" },
      { "name": "Non GMO biqueen corn", "code": "50586707" },
      { "name": "Non GMO bodacious corn", "code": "50586708" },
      { "name": "Non GMO butter and sugar corn", "code": "50586709" },
      { "name": "Non GMO calico belle corn", "code": "50586710" },
      { "name": "Non GMO camelot corn", "code": "50586711" },
      {
        "name": "Non GMO challengercrisp \u2018n sweet corn",
        "code": "50586712"
      },
      { "name": "Non GMO champ corn", "code": "50586713" },
      { "name": "Non GMO cotton candy corn", "code": "50586714" },
      { "name": "Non GMO d\u2019artagnan corn", "code": "50586715" },
      { "name": "Non GMO dazzle corn", "code": "50586716" },
      { "name": "Non GMO diamonds and gold corn", "code": "50586717" },
      { "name": "Non GMO divinity corn", "code": "50586718" },
      { "name": "Non GMO double delight corn", "code": "50586719" },
      { "name": "Non GMO double gem corn", "code": "50586720" },
      { "name": "Non GMO earlivee corn", "code": "50586721" },
      { "name": "Non GMO early xtra sweet corn", "code": "50586722" },
      { "name": "Non GMO excel corn", "code": "50586723" },
      { "name": "Non GMO golden cross bantam corn", "code": "50586724" },
      { "name": "Non GMO honey and cream corn", "code": "50586725" },
      { "name": "Non GMO honey \u2018n pearl corn", "code": "50586726" },
      { "name": "Non GMO how sweet it is corn", "code": "50586727" },
      { "name": "Non GMO hudson corn", "code": "50586728" },
      { "name": "Non GMO illini gold corn", "code": "50586729" },
      { "name": "Non GMO illini xtra sweet corn", "code": "50586730" },
      { "name": "Non GMO incredible corn", "code": "50586731" },
      { "name": "Non GMO iochief corn", "code": "50586732" },
      { "name": "Non GMO jubilee corn", "code": "50586733" },
      { "name": "Non GMO jubilee supersweet corn", "code": "50586734" },
      { "name": "Non GMO kandy korn corn", "code": "50586735" },
      { "name": "Non GMO kiss \u2018n tell corn", "code": "50586736" },
      { "name": "Non GMO lancelot corn", "code": "50586737" },
      { "name": "Non GMO maple sweet corn", "code": "50586738" },
      { "name": "Non GMO medley corn", "code": "50586739" },
      { "name": "Non GMO merlin corn", "code": "50586740" },
      { "name": "Non GMO miracle corn", "code": "50586741" },
      { "name": "Non GMO nk-199 corn", "code": "50586742" },
      { "name": "Non GMO peaches and cream corn", "code": "50586743" },
      { "name": "Non GMO pearl white corn", "code": "50586744" },
      { "name": "Non GMO pegasus corn", "code": "50586745" },
      { "name": "Non GMO phenomenal corn", "code": "50586746" },
      { "name": "Non GMO platinum lady corn", "code": "50586747" },
      { "name": "Non GMO precocious corn", "code": "50586748" },
      { "name": "Non GMO pristine corn", "code": "50586749" },
      { "name": "Non GMO quickie corn", "code": "50586750" },
      { "name": "Non GMO radiance corn", "code": "50586751" },
      { "name": "Non GMO seneca brave corn", "code": "50586752" },
      { "name": "Non GMO seneca dawn corn", "code": "50586753" },
      { "name": "Non GMO seneca horizon corn", "code": "50586754" },
      { "name": "Non GMO seneca starshine corn", "code": "50586755" },
      { "name": "Non GMO seneca white knight corn", "code": "50586756" },
      { "name": "Non GMO showcase corn", "code": "50586757" },
      { "name": "Non GMO silver queen corn", "code": "50586758" },
      { "name": "Non GMO snowbelle corn", "code": "50586759" },
      { "name": "Non GMO spring snow corn", "code": "50586760" },
      { "name": "Non GMO spring treat corn", "code": "50586761" },
      { "name": "Non GMO sugar and gold corn", "code": "50586762" },
      { "name": "Non GMO sugar buns corn", "code": "50586763" },
      { "name": "Non GMO sugar snow corn", "code": "50586764" },
      { "name": "Non GMO sundance corn", "code": "50586765" },
      { "name": "Non GMO telstar corn", "code": "50586766" },
      { "name": "Non GMO terminator corn", "code": "50586767" },
      { "name": "Non GMO treasure corn", "code": "50586768" },
      { "name": "Non GMO tuxedo corn", "code": "50586769" },
      { "name": "Nominant Non GMO vegetables", "code": "50586800" },
      { "name": "Non GMO alfalfa", "code": "50586801" },
      { "name": "Non GMO aloe leaves", "code": "50586802" },
      { "name": "Non GMO apio", "code": "50586803" },
      { "name": "Non GMO arrow root", "code": "50586804" },
      { "name": "Non GMO arrowhead", "code": "50586805" },
      { "name": "Non GMO arugula", "code": "50586806" },
      { "name": "Non GMO arum", "code": "50586807" },
      { "name": "Non GMO bamboo shoots", "code": "50586808" },
      { "name": "Non GMO banana leaves", "code": "50586809" },
      { "name": "Non GMO batatas", "code": "50586810" },
      { "name": "Non GMO bean sprouts", "code": "50586811" },
      { "name": "Non GMO beet tops", "code": "50586812" },
      { "name": "Non GMO bittermelon", "code": "50586813" },
      { "name": "Non GMO caperberries", "code": "50586814" },
      { "name": "Non GMO carob", "code": "50586815" },
      { "name": "Non GMO cha-om", "code": "50586816" },
      { "name": "Non GMO chaoyotes", "code": "50586817" },
      { "name": "Non GMO chickpeas", "code": "50586818" },
      { "name": "Non GMO chrysanthemum greens", "code": "50586819" },
      { "name": "Non GMO dandelion greens", "code": "50586820" },
      { "name": "Non GMO dandelions", "code": "50586821" },
      { "name": "Non GMO dasheen", "code": "50586822" },
      { "name": "Non GMO dau mue or pea tips", "code": "50586823" },
      { "name": "Non GMO diakon", "code": "50586824" },
      { "name": "Non GMO donqua", "code": "50586825" },
      { "name": "Non GMO fiddlehead ferns", "code": "50586826" },
      { "name": "Non GMO gai choy", "code": "50586827" },
      { "name": "Non GMO gailon", "code": "50586828" },
      { "name": "Non GMO galanga", "code": "50586829" },
      { "name": "Non GMO ginger root", "code": "50586830" },
      { "name": "Non GMO gobo", "code": "50586831" },
      { "name": "Non GMO hop sprouts", "code": "50586832" },
      { "name": "Non GMO horseradish", "code": "50586833" },
      { "name": "Non GMO jicama", "code": "50586834" },
      { "name": "Non GMO kudzu", "code": "50586835" },
      { "name": "Non GMO lily bulb", "code": "50586836" },
      { "name": "Non GMO linkok", "code": "50586837" },
      { "name": "Non GMO lo bok", "code": "50586838" },
      { "name": "Non GMO long beans", "code": "50586839" },
      { "name": "Non GMO lotus root", "code": "50586840" },
      { "name": "Non GMO maguey leaves", "code": "50586841" },
      { "name": "Non GMO mallows", "code": "50586842" },
      { "name": "Non GMO mamey sapote", "code": "50586843" },
      { "name": "Non GMO moap", "code": "50586844" },
      { "name": "Non GMO moo", "code": "50586845" },
      { "name": "Non GMO moqua", "code": "50586846" },
      { "name": "Non GMO opos", "code": "50586847" },
      { "name": "Non GMO palm hearts", "code": "50586848" },
      { "name": "Non GMO paprika", "code": "50586849" },
      { "name": "Non GMO purslane", "code": "50586850" },
      { "name": "Non GMO raddichios", "code": "50586851" },
      { "name": "Non GMO sinquas", "code": "50586852" },
      { "name": "Non GMO soybeans", "code": "50586853" },
      { "name": "Non GMO spoonwart", "code": "50586854" },
      { "name": "Non GMO taro", "code": "50586855" },
      { "name": "Non GMO taro leaf", "code": "50586856" },
      { "name": "Non GMO taro shoot", "code": "50586857" },
      { "name": "Non GMO tassle grape-hyacinth", "code": "50586858" },
      { "name": "Non GMO tendergreen", "code": "50586859" },
      { "name": "Non GMO tepeguaje", "code": "50586860" },
      { "name": "Non GMO tindora", "code": "50586861" },
      { "name": "Non GMO tree onion", "code": "50586862" },
      { "name": "Non GMO udo", "code": "50586863" },
      { "name": "Non GMO water chestnuts", "code": "50586864" },
      { "name": "Non GMO water spinach", "code": "50586865" },
      { "name": "Non GMO yampi", "code": "50586866" },
      { "name": "Non GMO yautia", "code": "50586867" },
      { "name": "Non GMO yu choy", "code": "50586868" },
      { "name": "Non GMO yuca", "code": "50586869" },
      { "name": "Non GMO sugar peas", "code": "50586900" },
      { "name": "Non GMO bikini peas", "code": "50586901" },
      { "name": "Non GMO cavalier peas", "code": "50586902" },
      { "name": "Dried Non GMO vegetables", "code": "50590000" },
      { "name": "Dried Non GMO artichokes", "code": "50591500" },
      { "name": "Dried Non GMO brittany artichokes", "code": "50591501" },
      { "name": "Dried Non GMO catanese artichokes", "code": "50591502" },
      { "name": "Dried Non GMO french artichokes", "code": "50591503" },
      { "name": "Dried Non GMO green globe artichokes", "code": "50591504" },
      {
        "name": "Dried Non GMO gros camus de bretagne artichokes",
        "code": "50591505"
      },
      { "name": "Dried Non GMO midi artichokes", "code": "50591506" },
      { "name": "Dried Non GMO purple globe artichokes", "code": "50591507" },
      {
        "name": "Dried Non GMO purple sicilian artichokes",
        "code": "50591508"
      },
      { "name": "Dried Non GMO romanesco artichokes", "code": "50591509" },
      { "name": "Dried Non GMO spinoso sardo artichokes", "code": "50591510" },
      { "name": "Dried Non GMO vert de laon artichokes", "code": "50591511" },
      {
        "name": "Dried Non GMO violetta di chioggia artichokes",
        "code": "50591512"
      },
      {
        "name": "Dried Non GMO violetto di toscana artichokes",
        "code": "50591513"
      },
      { "name": "Dried Non GMO asparagus", "code": "50591600" },
      {
        "name": "Dried Non GMO connover's colossal asparagus",
        "code": "50591601"
      },
      { "name": "Dried Non GMO franklin asparagus", "code": "50591602" },
      { "name": "Dried Non GMO giant mammoth asparagus", "code": "50591603" },
      { "name": "Dried Non GMO lucullus asparagus", "code": "50591604" },
      {
        "name": "Dried Non GMO martha washington asparagus",
        "code": "50591605"
      },
      { "name": "Dried Non GMO avocados", "code": "50591700" },
      { "name": "Dried Non GMO ajax b-7 avocados", "code": "50591701" },
      { "name": "Dried Non GMO arue avocados", "code": "50591702" },
      { "name": "Dried Non GMO bacon avocados", "code": "50591703" },
      { "name": "Dried Non GMO benik avocados", "code": "50591704" },
      { "name": "Dried Non GMO bernecker avocados", "code": "50591705" },
      { "name": "Dried Non GMO beta avocados", "code": "50591706" },
      { "name": "Dried Non GMO biondo avocados", "code": "50591707" },
      { "name": "Dried Non GMO black prince avocados", "code": "50591708" },
      { "name": "Dried Non GMO blair avocados", "code": "50591709" },
      { "name": "Dried Non GMO blair booth avocados", "code": "50591710" },
      { "name": "Dried Non GMO booth 1 avocados", "code": "50591711" },
      { "name": "Dried Non GMO booth 3 avocados", "code": "50591712" },
      { "name": "Dried Non GMO booth 5 avocados", "code": "50591713" },
      { "name": "Dried Non GMO booth 7 avocados", "code": "50591714" },
      { "name": "Dried Non GMO booth 8 avocados", "code": "50591715" },
      { "name": "Dried Non GMO brooks 1978 avocados", "code": "50591716" },
      { "name": "Dried Non GMO brookslate avocados", "code": "50591717" },
      { "name": "Dried Non GMO california haas avocados", "code": "50591718" },
      { "name": "Dried Non GMO catalina avocados", "code": "50591719" },
      { "name": "Dried Non GMO chica avocados", "code": "50591720" },
      { "name": "Dried Non GMO choquette avocados", "code": "50591721" },
      { "name": "Dried Non GMO christina avocados", "code": "50591722" },
      { "name": "Dried Non GMO collinson avocados", "code": "50591723" },
      { "name": "Dried Non GMO donnie avocados", "code": "50591724" },
      {
        "name": "Dried Non GMO dr dupuis number 2 avocados",
        "code": "50591725"
      },
      { "name": "Dried Non GMO dr dupuis avocados", "code": "50591726" },
      { "name": "Dried Non GMO ettinger avocados", "code": "50591727" },
      { "name": "Dried Non GMO fuchs avocados", "code": "50591728" },
      { "name": "Dried Non GMO fuchs gwen avocados", "code": "50591729" },
      { "name": "Dried Non GMO fuerte avocados", "code": "50591730" },
      { "name": "Dried Non GMO gorham avocados", "code": "50591731" },
      { "name": "Dried Non GMO gossman avocados", "code": "50591732" },
      {
        "name": "Dried Non GMO guatemalan seedling avocados",
        "code": "50591733"
      },
      { "name": "Dried Non GMO hall avocados", "code": "50591734" },
      { "name": "Dried Non GMO hardee avocados", "code": "50591735" },
      { "name": "Dried Non GMO haas avocados", "code": "50591736" },
      { "name": "Dried Non GMO herman avocados", "code": "50591737" },
      { "name": "Dried Non GMO hickson avocados", "code": "50591738" },
      { "name": "Dried Non GMO k-5 avocados", "code": "50591739" },
      { "name": "Dried Non GMO k-9 avocados", "code": "50591740" },
      { "name": "Dried Non GMO lamb haas avocados", "code": "50591741" },
      { "name": "Dried Non GMO leona avocados", "code": "50591742" },
      { "name": "Dried Non GMO leona linda avocados", "code": "50591743" },
      { "name": "Dried Non GMO lisa p avocados", "code": "50591744" },
      { "name": "Dried Non GMO lisa loretta avocados", "code": "50591745" },
      { "name": "Dried Non GMO loretta avocados", "code": "50591746" },
      { "name": "Dried Non GMO lula avocados", "code": "50591747" },
      { "name": "Dried Non GMO lula macarthur avocados", "code": "50591748" },
      { "name": "Dried Non GMO marcus avocados", "code": "50591749" },
      { "name": "Dried Non GMO melendez avocados", "code": "50591750" },
      { "name": "Dried Non GMO meya p avocados", "code": "50591751" },
      { "name": "Dried Non GMO miguel p avocados", "code": "50591752" },
      { "name": "Dried Non GMO monroe avocados", "code": "50591753" },
      { "name": "Dried Non GMO murrieta green avocados", "code": "50591754" },
      { "name": "Dried Non GMO nabal avocados", "code": "50591755" },
      { "name": "Dried Non GMO nadir avocados", "code": "50591756" },
      { "name": "Dried Non GMO nesbitt avocados", "code": "50591757" },
      { "name": "Dried Non GMO peterson avocados", "code": "50591758" },
      { "name": "Dried Non GMO pinelli avocados", "code": "50591759" },
      { "name": "Dried Non GMO pinkerton avocados", "code": "50591760" },
      { "name": "Dried Non GMO pollock avocados", "code": "50591761" },
      { "name": "Dried Non GMO puebla avocados", "code": "50591762" },
      { "name": "Dried Non GMO reed avocados", "code": "50591763" },
      { "name": "Dried Non GMO rue avocados", "code": "50591764" },
      { "name": "Dried Non GMO ruehle avocados", "code": "50591765" },
      { "name": "Dried Non GMO ryan avocados", "code": "50591766" },
      { "name": "Dried Non GMO semil 34 avocados", "code": "50591767" },
      { "name": "Dried Non GMO semil 43 avocados", "code": "50591768" },
      { "name": "Dried Non GMO simmonds avocados", "code": "50591769" },
      { "name": "Dried Non GMO simpson avocados", "code": "50591770" },
      { "name": "Dried Non GMO taylor avocados", "code": "50591771" },
      { "name": "Dried Non GMO tonnage avocados", "code": "50591772" },
      { "name": "Dried Non GMO tower avocados", "code": "50591773" },
      { "name": "Dried Non GMO tower li avocados", "code": "50591774" },
      { "name": "Dried Non GMO trapp avocados", "code": "50591775" },
      {
        "name": "Dried Non GMO west indian seedling avocados",
        "code": "50591776"
      },
      { "name": "Dried Non GMO wagner avocados", "code": "50591777" },
      { "name": "Dried Non GMO waldin avocados", "code": "50591778" },
      { "name": "Dried Non GMO wurtz avocados", "code": "50591779" },
      { "name": "Dried Non GMO zio p avocados", "code": "50591780" },
      { "name": "Dried Non GMO ziu avocados", "code": "50591781" },
      { "name": "Dried Non GMO zutano avocados", "code": "50591782" },
      { "name": "Dried Non GMO beans", "code": "50591800" },
      { "name": "Dried Non GMO anasazi or aztec beans", "code": "50591801" },
      { "name": "Dried Non GMO appaloosa beans", "code": "50591802" },
      { "name": "Dried Non GMO azuki beans", "code": "50591803" },
      { "name": "Dried Non GMO barlotti beans", "code": "50591804" },
      { "name": "Dried Non GMO black appaloosa beans", "code": "50591805" },
      { "name": "Dried Non GMO black beans", "code": "50591806" },
      { "name": "Dried Non GMO black gram beans", "code": "50591807" },
      { "name": "Dried Non GMO black shackamaxon beans", "code": "50591808" },
      { "name": "Dried Non GMO blackeyed beans", "code": "50591809" },
      { "name": "Dried Non GMO bobby beans", "code": "50591810" },
      { "name": "Dried Non GMO bolita beans", "code": "50591811" },
      { "name": "Dried Non GMO brown lazy wife beans", "code": "50591812" },
      { "name": "Dried Non GMO calypso beans", "code": "50591813" },
      { "name": "Dried Non GMO cannellini beans", "code": "50591814" },
      { "name": "Dried Non GMO castor beans", "code": "50591815" },
      { "name": "Dried Non GMO china yellow beans", "code": "50591816" },
      { "name": "Dried Non GMO dragon tongue beans", "code": "50591817" },
      { "name": "Dried Non GMO european soldier beans", "code": "50591818" },
      { "name": "Dried Non GMO fava beans", "code": "50591819" },
      { "name": "Dried Non GMO flageolet beans", "code": "50591820" },
      {
        "name": "Dried Non GMO french horticultural beans",
        "code": "50591821"
      },
      { "name": "Dried Non GMO french navy beans", "code": "50591822" },
      { "name": "Dried Non GMO giant white coco beans", "code": "50591823" },
      { "name": "Dried Non GMO green beans", "code": "50591824" },
      { "name": "Dried Non GMO green romano beans", "code": "50591825" },
      { "name": "Dried Non GMO guar gum beans", "code": "50591826" },
      { "name": "Dried Non GMO haricot beans", "code": "50591827" },
      { "name": "Dried Non GMO hyacinth beans", "code": "50591828" },
      { "name": "Dried Non GMO italian type beans", "code": "50591829" },
      { "name": "Dried Non GMO jackson wonder beans", "code": "50591830" },
      { "name": "Dried Non GMO jacob's cattle beans", "code": "50591831" },
      { "name": "Dried Non GMO kentucky wonder beans", "code": "50591832" },
      { "name": "Dried Non GMO kidney beans", "code": "50591833" },
      { "name": "Dried Non GMO lima beans", "code": "50591834" },
      { "name": "Dried Non GMO madeira/madera beans", "code": "50591835" },
      { "name": "Dried Non GMO marrow beans", "code": "50591836" },
      { "name": "Dried Non GMO mat beans", "code": "50591837" },
      {
        "name": "Dried Non GMO monstoller wild goose beans",
        "code": "50591838"
      },
      { "name": "Dried Non GMO mortgage lifter beans", "code": "50591839" },
      { "name": "Dried Non GMO moth beans", "code": "50591840" },
      { "name": "Dried Non GMO mung beans", "code": "50591841" },
      { "name": "Dried Non GMO munsi wolf bean", "code": "50591842" },
      { "name": "Dried Non GMO nuna beans", "code": "50591843" },
      { "name": "Dried Non GMO pinto beans", "code": "50591844" },
      { "name": "Dried Non GMO pole beans", "code": "50591845" },
      { "name": "Dried Non GMO runner beans", "code": "50591846" },
      { "name": "Dried Non GMO string beans", "code": "50591847" },
      { "name": "Dried Non GMO tamarind beans", "code": "50591848" },
      { "name": "Dried Non GMO tonka beans", "code": "50591849" },
      { "name": "Dried Non GMO wax beans", "code": "50591850" },
      { "name": "Dried Non GMO winged beans", "code": "50591851" },
      { "name": "Dried Non GMO yard long beans", "code": "50591852" },
      { "name": "Dried Non GMO beets", "code": "50591900" },
      { "name": "Dried Non GMO action beets", "code": "50591901" },
      { "name": "Dried Non GMO albina vereduna beets", "code": "50591902" },
      {
        "name": "Dried Non GMO barbabietola di chioggia beets",
        "code": "50591903"
      },
      { "name": "Dried Non GMO boltardy beets", "code": "50591904" },
      { "name": "Dried Non GMO bonel beets", "code": "50591905" },
      { "name": "Dried Non GMO burpees golden beets", "code": "50591906" },
      {
        "name": "Dried Non GMO cheltenham green top beets",
        "code": "50591907"
      },
      { "name": "Dried Non GMO cheltenham mono beets", "code": "50591908" },
      { "name": "Dried Non GMO chioggia beets", "code": "50591909" },
      { "name": "Dried Non GMO cylindra beets", "code": "50591910" },
      { "name": "Dried Non GMO d'egypte beets", "code": "50591911" },
      { "name": "Dried Non GMO detroit 2 dark red beets", "code": "50591912" },
      {
        "name": "Dried Non GMO detroit 2 little ball beets",
        "code": "50591913"
      },
      { "name": "Dried Non GMO egyptian flat beets", "code": "50591914" },
      {
        "name": "Dried Non GMO egyptian turnip rooted beets",
        "code": "50591915"
      },
      { "name": "Dried Non GMO formanova beets", "code": "50591916" },
      { "name": "Dried Non GMO forono beets", "code": "50591917" },
      { "name": "Dried Non GMO monaco beets", "code": "50591918" },
      { "name": "Dried Non GMO monogram beets", "code": "50591919" },
      { "name": "Dried Non GMO pronto beets", "code": "50591920" },
      { "name": "Dried Non GMO regalia beets", "code": "50591921" },
      { "name": "Dried Non GMO sugar beets", "code": "50591922" },
      { "name": "Dried Non GMO broccoli", "code": "50592000" },
      { "name": "Dried Non GMO broccolini", "code": "50592001" },
      { "name": "Dried Non GMO broccoli romanesco", "code": "50592002" },
      { "name": "Dried Non GMO broccoli raab", "code": "50592003" },
      { "name": "Dried Non GMO chinese broccoli", "code": "50592004" },
      { "name": "Dried Non GMO brussel sprouts", "code": "50592100" },
      { "name": "Dried Non GMO citadel brussel sprouts", "code": "50592101" },
      { "name": "Dried Non GMO falstaff brussel sprouts", "code": "50592102" },
      { "name": "Dried Non GMO oliver brussel sprouts", "code": "50592103" },
      { "name": "Dried Non GMO peer gynt brussel sprouts", "code": "50592104" },
      { "name": "Dried Non GMO rampart brussel sprouts", "code": "50592105" },
      { "name": "Dried Non GMO rubine brussel sprouts", "code": "50592106" },
      { "name": "Dried Non GMO widgeon brussel sprouts", "code": "50592107" },
      { "name": "Dried Non GMO bunching onions", "code": "50592200" },
      {
        "name": "Dried Non GMO beltsville bunching onions",
        "code": "50592201"
      },
      { "name": "Dried Non GMO feast bunching onions", "code": "50592202" },
      { "name": "Dried Non GMO ishikura bunching onions", "code": "50592203" },
      {
        "name": "Dried Non GMO kyoto market bunching onions",
        "code": "50592204"
      },
      { "name": "Dried Non GMO red beard bunching onions", "code": "50592205" },
      { "name": "Dried Non GMO redmate bunching onions", "code": "50592206" },
      {
        "name": "Dried Non GMO santa claus bunching onions",
        "code": "50592207"
      },
      { "name": "Dried Non GMO tokyo bunching onions", "code": "50592208" },
      {
        "name": "Dried Non GMO white lisbon bunching onions",
        "code": "50592209"
      },
      {
        "name": "Dried Non GMO winter white bunching onions",
        "code": "50592210"
      },
      {
        "name": "Dried Non GMO winter-over bunching onions",
        "code": "50592211"
      },
      { "name": "Dried Non GMO cabbages", "code": "50592300" },
      { "name": "Dried Non GMO black cabbages", "code": "50592301" },
      { "name": "Dried Non GMO savoy cabbages", "code": "50592302" },
      { "name": "Dried Non GMO skunk cabbages", "code": "50592303" },
      { "name": "Dried Non GMO white cabbages", "code": "50592304" },
      { "name": "Dried Non GMO cardoons", "code": "50592400" },
      { "name": "Dried Non GMO lunghi cardoons", "code": "50592401" },
      { "name": "Dried Non GMO gobbi cardoons", "code": "50592402" },
      { "name": "Dried Non GMO carrots", "code": "50592500" },
      { "name": "Dried Non GMO amsterdam carrots", "code": "50592501" },
      { "name": "Dried Non GMO autumn king carrots", "code": "50592502" },
      { "name": "Dried Non GMO berlicum carrots", "code": "50592503" },
      { "name": "Dried Non GMO chantenay carrots", "code": "50592504" },
      { "name": "Dried Non GMO nantes carrots", "code": "50592505" },
      { "name": "Dried Non GMO paris market carrots", "code": "50592506" },
      { "name": "Dried Non GMO cauliflowers", "code": "50592600" },
      {
        "name": "Dried Non GMO all the year round cauliflowers",
        "code": "50592601"
      },
      { "name": "Dried Non GMO alverda cauliflowers", "code": "50592602" },
      {
        "name": "Dried Non GMO autumn giant 3 cauliflowers",
        "code": "50592603"
      },
      { "name": "Dried Non GMO dok elgon cauliflowers", "code": "50592604" },
      {
        "name": "Dried Non GMO early snowball cauliflowers",
        "code": "50592605"
      },
      { "name": "Dried Non GMO limelight cauliflowers", "code": "50592606" },
      { "name": "Dried Non GMO minaret cauliflowers", "code": "50592607" },
      {
        "name": "Dried Non GMO orange bouquet cauliflowers",
        "code": "50592608"
      },
      { "name": "Dried Non GMO purple cape cauliflowers", "code": "50592609" },
      { "name": "Dried Non GMO snowball cauliflowers", "code": "50592610" },
      {
        "name": "Dried Non GMO walcheren winter 3 cauliflowers",
        "code": "50592611"
      },
      { "name": "Dried Non GMO white rock cauliflowers", "code": "50592612" },
      { "name": "Dried Non GMO celery", "code": "50592700" },
      { "name": "Dried Non GMO celebrity celery", "code": "50592701" },
      { "name": "Dried Non GMO celeriac", "code": "50592702" },
      { "name": "Dried Non GMO chinese celery", "code": "50592703" },
      { "name": "Dried Non GMO french dinant celery", "code": "50592704" },
      { "name": "Dried Non GMO giant pink celery", "code": "50592705" },
      { "name": "Dried Non GMO giant red celery", "code": "50592706" },
      { "name": "Dried Non GMO giant white celery", "code": "50592707" },
      {
        "name": "Dried Non GMO golden self-blanching celery",
        "code": "50592708"
      },
      { "name": "Dried Non GMO greensleeves celery", "code": "50592709" },
      { "name": "Dried Non GMO hopkins fenlander celery", "code": "50592710" },
      { "name": "Dried Non GMO ivory tower celery", "code": "50592711" },
      {
        "name": "Dried Non GMO lathom self-blanching celery",
        "code": "50592712"
      },
      { "name": "Dried Non GMO soup celery d'amsterdam", "code": "50592713" },
      { "name": "Dried Non GMO standard bearer celery", "code": "50592714" },
      { "name": "Dried Non GMO tall utah triumph celery", "code": "50592715" },
      { "name": "Dried Non GMO chards", "code": "50592800" },
      { "name": "Dried Non GMO bright lights chard", "code": "50592801" },
      { "name": "Dried Non GMO fordhook giant chard", "code": "50592802" },
      { "name": "Dried Non GMO lucullus chard", "code": "50592803" },
      { "name": "Dried Non GMO perpetual spinach chard", "code": "50592804" },
      { "name": "Dried Non GMO rhubarb chard", "code": "50592805" },
      { "name": "Dried Non GMO swiss chard", "code": "50592806" },
      { "name": "Dried Non GMO vulcan chard", "code": "50592807" },
      { "name": "Dried Non GMO white king chard", "code": "50592808" },
      { "name": "Dried Non GMO chicories", "code": "50592900" },
      {
        "name": "Dried Non GMO broad leaved batavian chicory",
        "code": "50592901"
      },
      {
        "name": "Dried Non GMO en cornet de bordeaux chicory",
        "code": "50592902"
      },
      {
        "name": "Dried Non GMO green curled ruffee chicory",
        "code": "50592903"
      },
      { "name": "Dried Non GMO green curled chicory", "code": "50592904" },
      { "name": "Dried Non GMO ione limnos chicory", "code": "50592905" },
      { "name": "Dried Non GMO riccia pancalieri chicory", "code": "50592906" },
      { "name": "Dried Non GMO salad king chicory", "code": "50592907" },
      { "name": "Dried Non GMO sanda chicory", "code": "50592908" },
      { "name": "Dried Non GMO scarola verde chicory", "code": "50592909" },
      {
        "name": "Dried Non GMO tres fine maraichere chicory",
        "code": "50592910"
      },
      {
        "name": "Dried Non GMO wallone freisee weschelkopf chicory",
        "code": "50592911"
      },
      { "name": "Dried Non GMO chinese cabbages", "code": "50593000" },
      { "name": "Dried Non GMO bok choy", "code": "50593001" },
      {
        "name": "Dried Non GMO chinese flat-headed cabbage",
        "code": "50593002"
      },
      { "name": "Dried Non GMO chinese flowering cabbage", "code": "50593003" },
      { "name": "Dried Non GMO choy sum", "code": "50593004" },
      { "name": "Dried Non GMO dwarf bok choy", "code": "50593005" },
      { "name": "Dried Non GMO fengshan bok choy", "code": "50593006" },
      { "name": "Dried Non GMO jade pagoda bok choy", "code": "50593007" },
      { "name": "Dried Non GMO kasumi bok choy", "code": "50593008" },
      { "name": "Dried Non GMO nerva bok choy", "code": "50593009" },
      { "name": "Dried Non GMO rosette bok choy", "code": "50593010" },
      { "name": "Dried Non GMO ruffles bok choy", "code": "50593011" },
      {
        "name": "Dried Non GMO santo serrated leaved cabbage",
        "code": "50593012"
      },
      { "name": "Dried Non GMO shanghai d bok choy", "code": "50593013" },
      { "name": "Dried Non GMO shantung cabbage", "code": "50593014" },
      { "name": "Dried Non GMO tip top cabbage", "code": "50593015" },
      { "name": "Dried Non GMO yau choy sum", "code": "50593016" },
      { "name": "Dried Non GMO chives", "code": "50593100" },
      { "name": "Dried Non GMO chinese chives", "code": "50593101" },
      { "name": "Non GMO Dried common chives", "code": "50593102" },
      { "name": "Dried Non GMO corn", "code": "50593200" },
      { "name": "Dried Non GMO aloha corn", "code": "50593201" },
      { "name": "Dried Non GMO alpine corn", "code": "50593202" },
      { "name": "Dried Non GMO ambrosia corn", "code": "50593203" },
      { "name": "Dried Non GMO argent corn", "code": "50593204" },
      { "name": "Dried Non GMO aspen corn", "code": "50593205" },
      { "name": "Dried Non GMO avalanche corn", "code": "50593206" },
      { "name": "Dried Non GMO biqueen corn", "code": "50593207" },
      { "name": "Dried Non GMO bodacious corn", "code": "50593208" },
      { "name": "Dried Non GMO butter and sugar corn", "code": "50593209" },
      { "name": "Dried Non GMO calico belle corn", "code": "50593210" },
      { "name": "Dried Non GMO camelot corn", "code": "50593211" },
      {
        "name": "Dried Non GMO challenger crisp \u2018n sweet corn",
        "code": "50593212"
      },
      { "name": "Dried Non GMO champ corn", "code": "50593213" },
      { "name": "Dried Non GMO cotton candy corn", "code": "50593214" },
      { "name": "Dried Non GMO d\u2019artagnan corn", "code": "50593215" },
      { "name": "Dried Non GMO dazzle corn", "code": "50593216" },
      { "name": "Dried Non GMO diamonds and gold corn", "code": "50593217" },
      { "name": "Dried Non GMO divinity corn", "code": "50593218" },
      { "name": "Dried Non GMO double delight corn", "code": "50593219" },
      { "name": "Dried Non GMO double gem corn", "code": "50593220" },
      { "name": "Dried Non GMO earlivee corn", "code": "50593221" },
      { "name": "Dried Non GMO early xtra sweet corn", "code": "50593222" },
      { "name": "Dried Non GMO excel corn", "code": "50593223" },
      { "name": "Dried Non GMO golden cross bantam corn", "code": "50593224" },
      { "name": "Dried Non GMO honey and cream corn", "code": "50593225" },
      { "name": "Dried Non GMO honey \u2018n pearl corn", "code": "50593226" },
      { "name": "Dried Non GMO how sweet it is corn", "code": "50593227" },
      { "name": "Dried Non GMO hudson corn", "code": "50593228" },
      { "name": "Dried Non GMO illini gold corn", "code": "50593229" },
      { "name": "Dried Non GMO illini xtra sweet corn", "code": "50593230" },
      { "name": "Dried Non GMO incredible corn", "code": "50593231" },
      { "name": "Dried Non GMO iochief corn", "code": "50593232" },
      { "name": "Dried Non GMO jubilee corn", "code": "50593233" },
      { "name": "Dried Non GMO jubilee supersweet corn", "code": "50593234" },
      { "name": "Dried Non GMO kandy korn corn", "code": "50593235" },
      { "name": "Dried Non GMO kiss \u2018n tell corn", "code": "50593236" },
      { "name": "Dried Non GMO lancelot corn", "code": "50593237" },
      { "name": "Dried Non GMO maple sweet corn", "code": "50593238" },
      { "name": "Dried Non GMO medley corn", "code": "50593239" },
      { "name": "Dried Non GMO merlin corn", "code": "50593240" },
      { "name": "Dried Non GMO miracle corn", "code": "50593241" },
      { "name": "Dried Non GMO nk-199 corn", "code": "50593242" },
      { "name": "Dried Non GMO peaches and cream corn", "code": "50593243" },
      { "name": "Dried Non GMO pearl white corn", "code": "50593244" },
      { "name": "Dried Non GMO pegasus corn", "code": "50593245" },
      { "name": "Dried Non GMO phenomenal corn", "code": "50593246" },
      { "name": "Dried Non GMO platinum lady corn", "code": "50593247" },
      { "name": "Dried Non GMO precocious corn", "code": "50593248" },
      { "name": "Dried Non GMO pristine corn", "code": "50593249" },
      { "name": "Dried Non GMO quickie corn", "code": "50593250" },
      { "name": "Dried Non GMO radiance corn", "code": "50593251" },
      { "name": "Dried Non GMO seneca brave corn", "code": "50593252" },
      { "name": "Dried Non GMO seneca dawn corn", "code": "50593253" },
      { "name": "Dried Non GMO seneca horizon corn", "code": "50593254" },
      { "name": "Dried Non GMO seneca starshine corn", "code": "50593255" },
      { "name": "Dried Non GMO seneca white knight corn", "code": "50593256" },
      { "name": "Dried Non GMO showcase corn", "code": "50593257" },
      { "name": "Dried Non GMO silver queen corn", "code": "50593258" },
      { "name": "Dried Non GMO snowbelle corn", "code": "50593259" },
      { "name": "Dried Non GMO spring snow corn", "code": "50593260" },
      { "name": "Dried Non GMO spring treat corn", "code": "50593261" },
      { "name": "Dried Non GMO sugar and gold corn", "code": "50593262" },
      { "name": "Dried Non GMO sugar buns corn", "code": "50593263" },
      { "name": "Dried Non GMO sugar snow corn", "code": "50593264" },
      { "name": "Dried Non GMO sundance corn", "code": "50593265" },
      { "name": "Dried Non GMO telstar corn", "code": "50593266" },
      { "name": "Dried Non GMO terminator corn", "code": "50593267" },
      { "name": "Dried Non GMO treasure corn", "code": "50593268" },
      { "name": "Dried Non GMO tuxedo corn", "code": "50593269" },
      { "name": "Dried Non GMO cresses", "code": "50593300" },
      { "name": "Dried Non GMO land cress", "code": "50593301" },
      { "name": "Dried Non GMO nasturtium", "code": "50593302" },
      { "name": "Dried Non GMO watercress", "code": "50593303" },
      { "name": "Dried Non GMO wintercress", "code": "50593304" },
      { "name": "Dried Non GMO cucumbers", "code": "50593400" },
      { "name": "Dried Non GMO arena cucumbers", "code": "50593401" },
      { "name": "Dried Non GMO armenian cucumbers", "code": "50593402" },
      { "name": "Dried Non GMO athene cucumbers", "code": "50593403" },
      {
        "name": "Dried Non GMO bianco lungo di parigi cucumbers",
        "code": "50593404"
      },
      {
        "name": "Dried Non GMO burpless tasty green cucumbers",
        "code": "50593405"
      },
      {
        "name": "Dried Non GMO chicago pickling cucumbers",
        "code": "50593406"
      },
      { "name": "Dried Non GMO crystal apple cucumbers", "code": "50593407" },
      { "name": "Dried Non GMO crystal lemon cucumbers", "code": "50593408" },
      { "name": "Dried Non GMO danimas cucumbers", "code": "50593409" },
      { "name": "Dried Non GMO gherkin cucumbers", "code": "50593410" },
      { "name": "Dried Non GMO hokus cucumbers", "code": "50593411" },
      { "name": "Dried Non GMO japanese cucumbers", "code": "50593412" },
      { "name": "Dried Non GMO karela cucumbers", "code": "50593413" },
      { "name": "Dried Non GMO korila cucumbers", "code": "50593414" },
      {
        "name": "Dried Non GMO long green improved cucumbers",
        "code": "50593415"
      },
      { "name": "Dried Non GMO marketmore cucumbers", "code": "50593416" },
      { "name": "Dried Non GMO midget cucumbers", "code": "50593417" },
      {
        "name": "Dried Non GMO national pickling cucumbers",
        "code": "50593418"
      },
      { "name": "Dried Non GMO persian cucumbers", "code": "50593419" },
      { "name": "Dried Non GMO telegraph cucumbers", "code": "50593420" },
      {
        "name": "Dried Non GMO telegraph improved cucumbers",
        "code": "50593421"
      },
      {
        "name": "Dried Non GMO vert de massy cornichon cucumbers",
        "code": "50593422"
      },
      { "name": "Dried Non GMO yamato cucumbers", "code": "50593423" },
      { "name": "Dried Non GMO eggplants", "code": "50593500" },
      { "name": "Dried Non GMO bambino eggplants", "code": "50593501" },
      { "name": "Dried Non GMO black beauty eggplants", "code": "50593502" },
      { "name": "Dried Non GMO black enorma eggplants", "code": "50593503" },
      { "name": "Dried Non GMO chinese eggplants", "code": "50593504" },
      { "name": "Dried Non GMO easter egg eggplants", "code": "50593505" },
      { "name": "Dried Non GMO filipino eggplants", "code": "50593506" },
      { "name": "Dried Non GMO florida market eggplants", "code": "50593507" },
      { "name": "Dried Non GMO indian eggplants", "code": "50593508" },
      { "name": "Dried Non GMO italian eggplants", "code": "50593509" },
      { "name": "Dried Non GMO japanese eggplants", "code": "50593510" },
      { "name": "Dried Non GMO long purple eggplants", "code": "50593511" },
      { "name": "Dried Non GMO long striped eggplants", "code": "50593512" },
      { "name": "Dried Non GMO moneymaker eggplants", "code": "50593513" },
      { "name": "Dried Non GMO ova eggplants", "code": "50593514" },
      { "name": "Dried Non GMO pea eggplants", "code": "50593515" },
      { "name": "Dried Non GMO short tom eggplants", "code": "50593516" },
      { "name": "Dried Non GMO sicilian eggplants", "code": "50593517" },
      { "name": "Dried Non GMO thai eggplants", "code": "50593518" },
      {
        "name": "Dried Non GMO violette di firenze eggplants",
        "code": "50593519"
      },
      { "name": "Dried Non GMO white eggplants", "code": "50593520" },
      { "name": "Dried Non GMO endives", "code": "50593600" },
      { "name": "Dried Non GMO brussels witloof endives", "code": "50593601" },
      { "name": "Dried Non GMO castelfranco endives", "code": "50593602" },
      {
        "name": "Dried Non GMO catalogna di galatina endives",
        "code": "50593603"
      },
      { "name": "Dried Non GMO chioggia endives", "code": "50593604" },
      { "name": "Dried Non GMO grumolo verde endives", "code": "50593605" },
      {
        "name": "Dried Non GMO large rooted magdeburg endives",
        "code": "50593606"
      },
      {
        "name": "Dried Non GMO palla rossa zorzi precoce endives",
        "code": "50593607"
      },
      { "name": "Dried Non GMO radice amare endives", "code": "50593608" },
      { "name": "Dried Non GMO rossa di treviso endives", "code": "50593609" },
      { "name": "Dried Non GMO rossa di verona endives", "code": "50593610" },
      { "name": "Dried Non GMO soncino endives", "code": "50593611" },
      { "name": "Dried Non GMO sugarhat endives", "code": "50593612" },
      { "name": "Dried Non GMO verona endives", "code": "50593613" },
      { "name": "Dried Non GMO witloof zoom endives", "code": "50593614" },
      { "name": "Dried Non GMO fennels", "code": "50593700" },
      { "name": "Dried Non GMO cantino fennel", "code": "50593701" },
      { "name": "Dried Non GMO fino fennel", "code": "50593702" },
      { "name": "Dried Non GMO herald fennel", "code": "50593703" },
      { "name": "Dried Non GMO perfection fennel", "code": "50593704" },
      { "name": "Dried Non GMO sirio fennel", "code": "50593705" },
      { "name": "Dried Non GMO sweet florence fennel", "code": "50593706" },
      { "name": "Dried Non GMO tardo fennel", "code": "50593707" },
      { "name": "Dried Non GMO garlics", "code": "50593800" },
      { "name": "Dried Non GMO california late garlic", "code": "50593801" },
      { "name": "Dried Non GMO chinese garlic stems", "code": "50593802" },
      { "name": "Dried Non GMO garlic chives", "code": "50593803" },
      { "name": "Dried Non GMO germidor garlic", "code": "50593804" },
      { "name": "Dried Non GMO long keeper garlic", "code": "50593805" },
      { "name": "Dried Non GMO ramson garlic", "code": "50593806" },
      { "name": "Dried Non GMO rocambole garlic", "code": "50593807" },
      { "name": "Dried Non GMO rose de lautrec garlic", "code": "50593808" },
      { "name": "Dried Non GMO solent wight garlic", "code": "50593809" },
      { "name": "Dried Non GMO spanish morado garlic", "code": "50593810" },
      { "name": "Dried Non GMO venetian/italian garlic", "code": "50593811" },
      { "name": "Dried Non GMO gourds", "code": "50593900" },
      { "name": "Dried Non GMO angled loofah", "code": "50593901" },
      { "name": "Dried Non GMO bitter gourd", "code": "50593902" },
      { "name": "Dried Non GMO bottle gourd", "code": "50593903" },
      { "name": "Dried Non GMO calabash gourds", "code": "50593904" },
      { "name": "Dried Non GMO fuzzy/hairy melon", "code": "50593905" },
      { "name": "Dried Non GMO musky gourd", "code": "50593906" },
      { "name": "Dried Non GMO smooth loofah", "code": "50593907" },
      { "name": "Dried Non GMO snake gourd", "code": "50593908" },
      { "name": "Dried Non GMO spiny bitter gourd", "code": "50593909" },
      { "name": "Dried Non GMO tinda gourds", "code": "50593910" },
      { "name": "Dried Non GMO tindoori gourds", "code": "50593911" },
      { "name": "Dried Non GMO green peas", "code": "50594000" },
      { "name": "Dried Non GMO china peas", "code": "50594001" },
      { "name": "Dried Non GMO english peas", "code": "50594002" },
      { "name": "Dried Non GMO garden peas", "code": "50594003" },
      { "name": "Dried Non GMO snow peas", "code": "50594004" },
      { "name": "Dried Non GMO sugar snap peas", "code": "50594005" },
      { "name": "Dried Non GMO herbs", "code": "50594100" },
      { "name": "Dried Non GMO basil", "code": "50594101" },
      { "name": "Dried Non GMO bay leaves", "code": "50594102" },
      { "name": "Dried Non GMO borage", "code": "50594103" },
      { "name": "Dried Non GMO caraway", "code": "50594104" },
      { "name": "Dried Non GMO chervil", "code": "50594105" },
      { "name": "Dried Non GMO cilantro", "code": "50594106" },
      { "name": "Dried Non GMO cipolinos", "code": "50594107" },
      { "name": "Dried Non GMO curry leaves", "code": "50594108" },
      { "name": "Dried Non GMO dill", "code": "50594109" },
      { "name": "Dried Non GMO epazote", "code": "50594110" },
      { "name": "Dried Non GMO fenugreek", "code": "50594111" },
      { "name": "Dried Non GMO lemon grass", "code": "50594112" },
      { "name": "Dried Non GMO marjoram", "code": "50594113" },
      { "name": "Dried Non GMO mint", "code": "50594114" },
      { "name": "Dried Non GMO oregano", "code": "50594115" },
      { "name": "Dried Non GMO papalo", "code": "50594116" },
      { "name": "Dried Non GMO pepicha", "code": "50594117" },
      { "name": "Dried Non GMO perilla", "code": "50594118" },
      { "name": "Dried Non GMO recao", "code": "50594119" },
      { "name": "Dried Non GMO rosemary", "code": "50594120" },
      { "name": "Dried Non GMO sage", "code": "50594121" },
      { "name": "Dried Non GMO salsify", "code": "50594122" },
      { "name": "Dried Non GMO savory", "code": "50594123" },
      { "name": "Dried Non GMO tarragon", "code": "50594124" },
      { "name": "Dried Non GMO thyme", "code": "50594125" },
      { "name": "Dried Non GMO tumeric", "code": "50594126" },
      { "name": "Dried Non GMO verdulaga", "code": "50594127" },
      { "name": "Dried Non GMO kale", "code": "50594200" },
      { "name": "Dried Non GMO curly kale", "code": "50594201" },
      { "name": "Dried Non GMO collard greens", "code": "50594202" },
      { "name": "Dried Non GMO kohlrabi", "code": "50594300" },
      { "name": "Dried Non GMO azur star kohlrabi", "code": "50594301" },
      { "name": "Dried Non GMO green vienna kohlrabi", "code": "50594302" },
      { "name": "Dried Non GMO lanro kohlrabi", "code": "50594303" },
      { "name": "Dried Non GMO purple vienna kohlrabi", "code": "50594304" },
      { "name": "Dried Non GMO rowel trero kohlrabi", "code": "50594305" },
      { "name": "Dried Non GMO white vienna kohlrabi", "code": "50594306" },
      { "name": "Dried Non GMO leeks", "code": "50594400" },
      { "name": "Dried Non GMO autumn giant-cobra leeks", "code": "50594401" },
      { "name": "Dried Non GMO autumn mammoth 2 leeks", "code": "50594402" },
      { "name": "Dried Non GMO bleu de solaise leeks", "code": "50594403" },
      { "name": "Dried Non GMO cortina leeks", "code": "50594404" },
      { "name": "Dried Non GMO prelina leeks", "code": "50594405" },
      { "name": "Dried Non GMO wild leek ramp", "code": "50594406" },
      { "name": "Dried Non GMO lentils", "code": "50594500" },
      { "name": "Dried Non GMO beluga lentils", "code": "50594501" },
      { "name": "Dried Non GMO french green lentils", "code": "50594502" },
      { "name": "Dried Non GMO green lentils", "code": "50594503" },
      { "name": "Dried Non GMO petite crimson lentils", "code": "50594504" },
      { "name": "Dried Non GMO spanish pardina lentils", "code": "50594505" },
      { "name": "Dried Non GMO split red lentils", "code": "50594506" },
      { "name": "Dried Non GMO split yellow lentils", "code": "50594507" },
      { "name": "Dried Non GMO tarahumara pinks lentils", "code": "50594508" },
      { "name": "Dried Non GMO lettuces", "code": "50594600" },
      { "name": "Dried Non GMO bibb lettuce", "code": "50594601" },
      { "name": "Dried Non GMO boston lettuce", "code": "50594602" },
      { "name": "Dried Non GMO frisee lettuce", "code": "50594603" },
      { "name": "Dried Non GMO lolla rossa lettuce", "code": "50594604" },
      { "name": "Dried Non GMO mesculin mix lettuce", "code": "50594605" },
      { "name": "Dried Non GMO mizuna lettuce", "code": "50594606" },
      { "name": "Dried Non GMO red leaf lettuce", "code": "50594607" },
      { "name": "Dried Non GMO red oak leaf lettuce", "code": "50594608" },
      { "name": "Dried Non GMO ruby romaine lettuce", "code": "50594609" },
      { "name": "Dried Non GMO baby red romaine lettuce", "code": "50594610" },
      { "name": "Dried Non GMO butterhead lettuces", "code": "50594611" },
      { "name": "Dried Non GMO chinese lettuces", "code": "50594612" },
      { "name": "Dried Non GMO crisphead lettuces", "code": "50594613" },
      { "name": "Dried Non GMO green leaf lettuce", "code": "50594614" },
      { "name": "Dried Non GMO iceberg lettuce", "code": "50594615" },
      { "name": "Dried Non GMO lamb\u2019s lettuces", "code": "50594616" },
      { "name": "Dried Non GMO looseleaf lettuces", "code": "50594617" },
      { "name": "Dried Non GMO mache lettuce", "code": "50594618" },
      { "name": "Dried Non GMO red boston lettuce", "code": "50594619" },
      { "name": "Dried Non GMO red headed lettuces", "code": "50594620" },
      { "name": "Dried Non GMO romaine lettuces", "code": "50594621" },
      {
        "name": "Dried Non GMO russian red mustard lettuce",
        "code": "50594622"
      },
      { "name": "Dried Non GMO tatsoi lettuce", "code": "50594623" },
      { "name": "Dried Non GMO malanga", "code": "50594700" },
      { "name": "Dried Non GMO amarilla malanga", "code": "50594701" },
      { "name": "Dried Non GMO blanca malanga", "code": "50594702" },
      { "name": "Dried Non GMO coco malanga", "code": "50594703" },
      { "name": "Dried Non GMO eddoes malanga", "code": "50594704" },
      { "name": "Dried Non GMO islena malanga", "code": "50594705" },
      { "name": "Dried Non GMO lila malanga", "code": "50594706" },
      { "name": "Dried Non GMO mushrooms", "code": "50594800" },
      { "name": "Dried Non GMO black trumpet mushrooms", "code": "50594801" },
      { "name": "Dried Non GMO brown mushrooms", "code": "50594802" },
      { "name": "Dried Non GMO champinion mushrooms", "code": "50594803" },
      { "name": "Dried Non GMO chanterelle mushrooms", "code": "50594804" },
      { "name": "Dried Non GMO cremini mushrooms", "code": "50594805" },
      { "name": "Dried Non GMO enoki mushrooms", "code": "50594806" },
      { "name": "Dried Non GMO hedge hog mushrooms", "code": "50594807" },
      {
        "name": "Dried Non GMO hen of the woods mushrooms",
        "code": "50594808"
      },
      { "name": "Dried Non GMO lobster mushrooms", "code": "50594809" },
      { "name": "Dried Non GMO morels mushrooms", "code": "50594810" },
      { "name": "Dried Non GMO oyster mushrooms", "code": "50594811" },
      { "name": "Dried Non GMO pleurotus mushrooms", "code": "50594812" },
      { "name": "Dried Non GMO pompom mushrooms", "code": "50594813" },
      { "name": "Dried Non GMO porcieni mushrooms", "code": "50594814" },
      { "name": "Dried Non GMO portobella mushrooms", "code": "50594815" },
      { "name": "Dried Non GMO shiitake mushrooms", "code": "50594816" },
      { "name": "Dried Non GMO shimeji mushrooms", "code": "50594817" },
      { "name": "Dried Non GMO st george's mushrooms", "code": "50594818" },
      { "name": "Dried Non GMO white mushrooms", "code": "50594819" },
      { "name": "Dried Non GMO white trumpet mushrooms", "code": "50594820" },
      { "name": "Dried Non GMO woodear mushrooms", "code": "50594821" },
      { "name": "Dried Non GMO mustards", "code": "50594900" },
      { "name": "Dried Non GMO bamboo mustard", "code": "50594901" },
      { "name": "Dried Non GMO garlic mustard", "code": "50594902" },
      { "name": "Dried Non GMO giantleafed mustard", "code": "50594903" },
      { "name": "Dried Non GMO red in snow mustard", "code": "50594904" },
      { "name": "Dried Non GMO southern mustard", "code": "50594905" },
      { "name": "Dried Non GMO wrapped heart mustard", "code": "50594906" },
      { "name": "Dried Non GMO nightshades", "code": "50595000" },
      { "name": "Dried Non GMO chinese lantern", "code": "50595001" },
      { "name": "Dried Non GMO garden huckleberry", "code": "50595002" },
      { "name": "Dried Non GMO naranjilla", "code": "50595003" },
      { "name": "Dried Non GMO tomatillo", "code": "50595004" },
      { "name": "Dried Non GMO okras", "code": "50595100" },
      { "name": "Dried Non GMO artist okra", "code": "50595101" },
      { "name": "Dried Non GMO burgundy okra", "code": "50595102" },
      { "name": "Dried Non GMO clemson spineless okra", "code": "50595103" },
      { "name": "Dried Non GMO dwarf green long pod okra", "code": "50595104" },
      {
        "name": "Dried Non GMO mammoth spineless long pod okra",
        "code": "50595105"
      },
      { "name": "Dried Non GMO red velvet okra", "code": "50595106" },
      {
        "name": "Dried Non GMO star of david heirloom okra",
        "code": "50595107"
      },
      { "name": "Dried Non GMO onions", "code": "50595200" },
      { "name": "Dried Non GMO albion onions", "code": "50595201" },
      { "name": "Dried Non GMO alisa craig onions", "code": "50595202" },
      { "name": "Dried Non GMO boiling onions", "code": "50595203" },
      { "name": "Dried Non GMO buffalo onions", "code": "50595204" },
      { "name": "Dried Non GMO bulb onions", "code": "50595205" },
      { "name": "Dried Non GMO creaming onions", "code": "50595206" },
      { "name": "Dried Non GMO express yellow o-x onions", "code": "50595207" },
      { "name": "Dried Non GMO kelsae onions", "code": "50595208" },
      {
        "name": "Dried Non GMO marshalls giant fen globe onions",
        "code": "50595209"
      },
      { "name": "Dried Non GMO pearl onions", "code": "50595210" },
      { "name": "Dried Non GMO red baron onions", "code": "50595211" },
      { "name": "Dried Non GMO red onions", "code": "50595212" },
      { "name": "Dried Non GMO rijnsberger onions", "code": "50595213" },
      {
        "name": "Dried Non GMO senshyu semi-globe yellow onions",
        "code": "50595214"
      },
      { "name": "Dried Non GMO sturon onions", "code": "50595215" },
      { "name": "Dried Non GMO stuttgarter giant onions", "code": "50595216" },
      { "name": "Dried Non GMO sweet onions", "code": "50595217" },
      {
        "name": "Dried Non GMO torpedo or red italian onions",
        "code": "50595218"
      },
      { "name": "Dried Non GMO red storage onions", "code": "50595219" },
      { "name": "Dried Non GMO white storage onions", "code": "50595220" },
      { "name": "Dried Non GMO yellow storage onions", "code": "50595221" },
      { "name": "Dried Non GMO peanuts", "code": "50595300" },
      {
        "name": "Dried Non GMO bambarra groundnut peanuts",
        "code": "50595301"
      },
      { "name": "Dried Non GMO florunner peanuts", "code": "50595302" },
      {
        "name": "Dried Non GMO hausa/kersting's ground nut peanuts",
        "code": "50595303"
      },
      { "name": "Dried Non GMO spanish peanuts", "code": "50595304" },
      { "name": "Dried Non GMO valencia peanuts", "code": "50595305" },
      { "name": "Dried Non GMO virginia peanuts", "code": "50595306" },
      { "name": "Dried Non GMO peas", "code": "50595400" },
      { "name": "Dried Non GMO purple hull peas", "code": "50595401" },
      { "name": "Dried Non GMO pinkeye peas", "code": "50595402" },
      { "name": "Dried Non GMO crowder peas", "code": "50595403" },
      { "name": "Dried Non GMO white acre peas", "code": "50595404" },
      { "name": "Dried Non GMO blackeyed peas", "code": "50595405" },
      { "name": "Dried Non GMO zipper cream peas", "code": "50595406" },
      { "name": "Dried Non GMO peppers", "code": "50595500" },
      { "name": "Dried Non GMO ajies peppers", "code": "50595501" },
      { "name": "Dried Non GMO arbol peppers", "code": "50595502" },
      { "name": "Dried Non GMO cheese peppers", "code": "50595503" },
      { "name": "Dried Non GMO chilaca peppers", "code": "50595504" },
      { "name": "Dried Non GMO cubanelles peppers", "code": "50595505" },
      { "name": "Dried Non GMO fresno peppers", "code": "50595506" },
      { "name": "Dried Non GMO kapia peppers", "code": "50595507" },
      { "name": "Dried Non GMO korean peppers", "code": "50595508" },
      { "name": "Dried Non GMO manzano peppers", "code": "50595509" },
      { "name": "Dried Non GMO melrose peppers", "code": "50595510" },
      { "name": "Dried Non GMO yellow chile peppers", "code": "50595511" },
      { "name": "Dried Non GMO aji dulces peppers", "code": "50595512" },
      { "name": "Dried Non GMO anaheim peppers", "code": "50595513" },
      { "name": "Dried Non GMO ancho peppers", "code": "50595514" },
      { "name": "Dried Non GMO bell peppers", "code": "50595515" },
      { "name": "Dried Non GMO cascabel peppers", "code": "50595516" },
      { "name": "Dried Non GMO cayenne peppers", "code": "50595517" },
      { "name": "Dried Non GMO cherry hots peppers", "code": "50595518" },
      { "name": "Dried Non GMO chiltecpin peppers", "code": "50595519" },
      { "name": "Dried Non GMO finger hot peppers", "code": "50595520" },
      { "name": "Dried Non GMO guajillo peppers", "code": "50595521" },
      { "name": "Dried Non GMO guerro peppers", "code": "50595522" },
      { "name": "Dried Non GMO habanero peppers", "code": "50595523" },
      { "name": "Dried Non GMO hungarian wax peppers", "code": "50595524" },
      { "name": "Dried Non GMO jalapeno peppers", "code": "50595525" },
      { "name": "Dried Non GMO long hot peppers", "code": "50595526" },
      { "name": "Dried Non GMO mirasol peppers", "code": "50595527" },
      { "name": "Dried Non GMO pasilla peppers", "code": "50595528" },
      { "name": "Dried Non GMO peperoncini peppers", "code": "50595529" },
      { "name": "Dried Non GMO pequin peppers", "code": "50595530" },
      { "name": "Dried Non GMO pimiento peppers", "code": "50595531" },
      { "name": "Dried Non GMO poblano peppers", "code": "50595532" },
      { "name": "Dried Non GMO scotch bonnet peppers", "code": "50595533" },
      { "name": "Dried Non GMO serrano peppers", "code": "50595534" },
      { "name": "Dried Non GMO tabasco peppers", "code": "50595535" },
      { "name": "Dried Non GMO tai peppers", "code": "50595536" },
      { "name": "Dried Non GMO tepin peppers", "code": "50595537" },
      { "name": "Dried Non GMO potatoes", "code": "50595600" },
      { "name": "Dried Non GMO long white potatoes", "code": "50595601" },
      { "name": "Dried Non GMO round white potatoes", "code": "50595602" },
      { "name": "Dried Non GMO round red potatoes", "code": "50595603" },
      { "name": "Dried Non GMO russet potatoes", "code": "50595604" },
      { "name": "Dried Non GMO purple potatoes", "code": "50595605" },
      { "name": "Dried Non GMO yellow potatoes", "code": "50595606" },
      { "name": "Dried Non GMO new potatoes", "code": "50595607" },
      { "name": "Dried Non GMO specialty potatoes", "code": "50595608" },
      { "name": "Dried Non GMO rutabagas", "code": "50595700" },
      { "name": "Dried Non GMO acme rutabagas", "code": "50595701" },
      { "name": "Dried Non GMO angela rutabagas", "code": "50595702" },
      { "name": "Dried Non GMO best of all rutabagas", "code": "50595703" },
      { "name": "Dried Non GMO marian rutabagas", "code": "50595704" },
      { "name": "Dried Non GMO sea vegetables", "code": "50595800" },
      { "name": "Dried Non GMO agar-agar", "code": "50595801" },
      { "name": "Dried Non GMO arame", "code": "50595802" },
      { "name": "Dried Non GMO dulse", "code": "50595803" },
      { "name": "Dried Non GMO haricot vert de mer", "code": "50595804" },
      { "name": "Dried Non GMO hijiki", "code": "50595805" },
      { "name": "Dried Non GMO irish moss", "code": "50595806" },
      { "name": "Dried Non GMO kelp", "code": "50595807" },
      { "name": "Dried Non GMO laver", "code": "50595808" },
      { "name": "Dried Non GMO nori", "code": "50595809" },
      { "name": "Dried Non GMO red algae", "code": "50595810" },
      { "name": "Dried Non GMO sea kale", "code": "50595811" },
      { "name": "Dried Non GMO sea lettuce", "code": "50595812" },
      { "name": "Dried Non GMO seaweeds", "code": "50595813" },
      { "name": "Dried Non GMO spirulina", "code": "50595814" },
      { "name": "Dried Non GMO susabi nori", "code": "50595815" },
      { "name": "Dried Non GMO wakame", "code": "50595816" },
      { "name": "Dried Non GMO shallots", "code": "50595900" },
      { "name": "Dried Non GMO atlantic shallots", "code": "50595901" },
      { "name": "Dried Non GMO creation shallots", "code": "50595902" },
      {
        "name": "Dried Non GMO drittler white nest shallots",
        "code": "50595903"
      },
      {
        "name": "Dried Non GMO giant yellow improved shallots",
        "code": "50595904"
      },
      { "name": "Dried Non GMO golden gourmet shallots", "code": "50595905" },
      {
        "name": "Dried Non GMO grise de bagnolet shallots",
        "code": "50595906"
      },
      { "name": "Dried Non GMO hative de niort shallots", "code": "50595907" },
      { "name": "Dried Non GMO pikant shallots", "code": "50595908" },
      { "name": "Dried Non GMO red potato onions", "code": "50595909" },
      { "name": "Dried Non GMO sante shallots", "code": "50595910" },
      { "name": "Dried Non GMO topper shallots", "code": "50595911" },
      { "name": "Dried Non GMO sorrels", "code": "50596000" },
      { "name": "Dried Non GMO dock sorrel", "code": "50596001" },
      { "name": "Dried Non GMO garden sorrel", "code": "50596002" },
      { "name": "Dried Non GMO sheep sorrel", "code": "50596003" },
      { "name": "Dried Non GMO wood sorrel", "code": "50596004" },
      { "name": "Dried Non GMO spinaches", "code": "50596100" },
      { "name": "Dried Non GMO america spinach", "code": "50596101" },
      { "name": "Dried Non GMO bloomsdale spinach", "code": "50596102" },
      { "name": "Dried Non GMO giant winter spinach", "code": "50596103" },
      { "name": "Dried Non GMO horenso spinach", "code": "50596104" },
      { "name": "Dried Non GMO iceplant spinach", "code": "50596105" },
      { "name": "Dried Non GMO lamb's quarters spinach", "code": "50596106" },
      { "name": "Dried Non GMO malabar spinach", "code": "50596107" },
      { "name": "Dried Non GMO medania spinach", "code": "50596108" },
      { "name": "Dried Non GMO new zealand spinach", "code": "50596109" },
      { "name": "Dried Non GMO orach spinach", "code": "50596110" },
      { "name": "Dried Non GMO savoy spinach", "code": "50596111" },
      { "name": "Dried Non GMO sigmaleaf spinach", "code": "50596112" },
      { "name": "Dried Non GMO space spinach", "code": "50596113" },
      { "name": "Dried Non GMO trinidad spinach", "code": "50596114" },
      { "name": "Dried Non GMO water spinach", "code": "50596115" },
      { "name": "Dried Non GMO wild spinach", "code": "50596116" },
      {
        "name": "Dried Non GMO summer squashes and summer pumpkins",
        "code": "50596200"
      },
      { "name": "Dried Non GMO boston marrow squash", "code": "50596201" },
      { "name": "Dried Non GMO butternut squash", "code": "50596202" },
      { "name": "Dried Non GMO costata romanesca squash", "code": "50596203" },
      { "name": "Dried Non GMO crookneck squash", "code": "50596204" },
      { "name": "Dried Non GMO cucuzza squash", "code": "50596205" },
      { "name": "Dried Non GMO delicata squash", "code": "50596206" },
      { "name": "Dried Non GMO delicious squash", "code": "50596207" },
      {
        "name": "Dried Non GMO early golden summer crookneck squash",
        "code": "50596208"
      },
      {
        "name": "Dried Non GMO early prolific straight neck squash",
        "code": "50596209"
      },
      { "name": "Dried Non GMO gold squash", "code": "50596210" },
      { "name": "Dried Non GMO jack be little squash", "code": "50596211" },
      { "name": "Dried Non GMO kentucky field squash", "code": "50596212" },
      { "name": "Dried Non GMO marrow squash", "code": "50596213" },
      { "name": "Dried Non GMO middle eastern squash", "code": "50596214" },
      { "name": "Dried Non GMO miniature squash", "code": "50596215" },
      { "name": "Dried Non GMO orangetti squash", "code": "50596216" },
      { "name": "Dried Non GMO pattypan squash", "code": "50596217" },
      { "name": "Dried Non GMO rondini squash", "code": "50596218" },
      { "name": "Dried Non GMO round squash", "code": "50596219" },
      { "name": "Dried Non GMO spaghetti squash", "code": "50596220" },
      { "name": "Dried Non GMO stripetti squash", "code": "50596221" },
      { "name": "Dried Non GMO sugar loaf squash", "code": "50596222" },
      { "name": "Dried Non GMO sweet dumpling squash", "code": "50596223" },
      { "name": "Dried Non GMO triple treat squash", "code": "50596224" },
      { "name": "Dried Non GMO waltham butternut squash", "code": "50596225" },
      {
        "name": "Dried Non GMO yellow bush scallop squash",
        "code": "50596226"
      },
      {
        "name": "Dried Non GMO yellow straightneck squash",
        "code": "50596227"
      },
      { "name": "Dried Non GMO zephyr squash", "code": "50596228" },
      { "name": "Dried Non GMO zucchini squash", "code": "50596229" },
      { "name": "Dried Non GMO sweet potatoes", "code": "50596300" },
      { "name": "Dried Non GMO beauregard sweet potatoes", "code": "50596301" },
      { "name": "Dried Non GMO centennial sweet potatoes", "code": "50596302" },
      { "name": "Dried Non GMO diane sweet potatoes", "code": "50596303" },
      { "name": "Dried Non GMO garnet sweet potatoes", "code": "50596304" },
      {
        "name": "Dried Non GMO georgia red sweet potatoes",
        "code": "50596305"
      },
      {
        "name": "Dried Non GMO goldensweet sweet potatoes",
        "code": "50596306"
      },
      { "name": "Dried Non GMO hanna sweet potatoes", "code": "50596307" },
      { "name": "Dried Non GMO japanese sweet potatoes", "code": "50596308" },
      { "name": "Dried Non GMO jersey sweet potatoes", "code": "50596309" },
      { "name": "Dried Non GMO jewel sweet potatoes", "code": "50596310" },
      {
        "name": "Dried Non GMO maryland red sweet potatoes",
        "code": "50596311"
      },
      { "name": "Dried Non GMO nemagold sweet potatoes", "code": "50596312" },
      { "name": "Dried Non GMO o'henry sweet potatoes", "code": "50596313" },
      { "name": "Dried Non GMO okinawan sweet potatoes", "code": "50596314" },
      { "name": "Dried Non GMO orange sweet potatoes", "code": "50596315" },
      { "name": "Dried Non GMO oriental sweet potatoes", "code": "50596316" },
      { "name": "Dried Non GMO red jersey sweet potatoes", "code": "50596317" },
      { "name": "Dried Non GMO red mar sweet potatoes", "code": "50596318" },
      { "name": "Dried Non GMO redglow sweet potatoes", "code": "50596319" },
      {
        "name": "Dried Non GMO yellow jersey sweet potatoes",
        "code": "50596320"
      },
      { "name": "Dried Non GMO tomatoes", "code": "50596400" },
      { "name": "Dried Non GMO ailsa craig tomatoes", "code": "50596401" },
      { "name": "Dried Non GMO alicante tomatoes", "code": "50596402" },
      { "name": "Dried Non GMO black plum tomatoes", "code": "50596403" },
      { "name": "Dried Non GMO brandywine tomatoes", "code": "50596404" },
      { "name": "Dried Non GMO cherry belle tomatoes", "code": "50596405" },
      { "name": "Dried Non GMO cherry tomatoes", "code": "50596406" },
      { "name": "Dried Non GMO delicious tomatoes", "code": "50596407" },
      { "name": "Dried Non GMO dombito tomatoes", "code": "50596408" },
      {
        "name": "Dried Non GMO gardener's delight tomatoes",
        "code": "50596409"
      },
      { "name": "Dried Non GMO grape tomatoes", "code": "50596410" },
      { "name": "Dried Non GMO green tomatoes", "code": "50596411" },
      { "name": "Dried Non GMO marmande super tomatoes", "code": "50596412" },
      {
        "name": "Dried Non GMO marvel striped traditional tomatoes",
        "code": "50596413"
      },
      { "name": "Dried Non GMO minibel tomatoes", "code": "50596414" },
      { "name": "Dried Non GMO oaxacan pink tomatoes", "code": "50596415" },
      { "name": "Dried Non GMO red alert tomatoes", "code": "50596416" },
      { "name": "Dried Non GMO roma vf tomatoes", "code": "50596417" },
      { "name": "Dried Non GMO san marzano tomatoes", "code": "50596418" },
      { "name": "Dried Non GMO shirley tomatoes", "code": "50596419" },
      { "name": "Dried Non GMO siberia tomato tomatoes", "code": "50596420" },
      { "name": "Dried Non GMO super beefsteak tomatoes", "code": "50596421" },
      { "name": "Dried Non GMO tigerella tomatoes", "code": "50596422" },
      { "name": "Dried Non GMO tiny tim tomatoes", "code": "50596423" },
      { "name": "Dried Non GMO tumbler tomatoes", "code": "50596424" },
      { "name": "Dried Non GMO yellow cocktail tomatoes", "code": "50596425" },
      {
        "name": "Dried Non GMO yellow pear-shaped tomatoes",
        "code": "50596426"
      },
      {
        "name": "Dried Non GMO yellow perfection tomatoes",
        "code": "50596427"
      },
      { "name": "Dried Non GMO turnip greens", "code": "50596500" },
      { "name": "Dried Non GMO green globe turnips", "code": "50596501" },
      { "name": "Dried Non GMO golden ball turnips", "code": "50596502" },
      { "name": "Dried Non GMO manchester market turnips", "code": "50596503" },
      { "name": "Dried Non GMO purple top milan turnips", "code": "50596504" },
      { "name": "Dried Non GMO purple top white turnips", "code": "50596505" },
      { "name": "Dried Non GMO snowball turnips", "code": "50596506" },
      { "name": "Dried Non GMO tokyo turnip", "code": "50596507" },
      { "name": "Dried Non GMO tokyo cross turnips", "code": "50596508" },
      {
        "name": "Dried Non GMO winter squashes and winter pumpkins",
        "code": "50596600"
      },
      { "name": "Dried Non GMO acorn squash", "code": "50596601" },
      { "name": "Dried Non GMO atlantic giant squash", "code": "50596602" },
      { "name": "Dried Non GMO banana pink squash", "code": "50596603" },
      { "name": "Dried Non GMO big max squash", "code": "50596604" },
      { "name": "Dried Non GMO calabaza squash", "code": "50596605" },
      { "name": "Dried Non GMO carnival squash", "code": "50596606" },
      { "name": "Dried Non GMO cheese pumpkin", "code": "50596607" },
      { "name": "Dried Non GMO crown prince squash", "code": "50596608" },
      { "name": "Dried Non GMO curcibita squash", "code": "50596609" },
      { "name": "Dried Non GMO cushaw squash", "code": "50596610" },
      { "name": "Dried Non GMO giant pumpkin squash", "code": "50596611" },
      { "name": "Dried Non GMO hubbard squash", "code": "50596612" },
      { "name": "Dried Non GMO jarrahdale squash", "code": "50596613" },
      { "name": "Dried Non GMO kabocha squash", "code": "50596614" },
      { "name": "Dried Non GMO queensland blue squash", "code": "50596615" },
      {
        "name": "Dried Non GMO rouge vif d'etampes squash",
        "code": "50596616"
      },
      { "name": "Dried Non GMO turks turban squash", "code": "50596617" },
      { "name": "Dried Non GMO valenciano squash", "code": "50596618" },
      { "name": "Dried Non GMO warted hubbard squash", "code": "50596619" },
      { "name": "Dried Non GMO whangaparoa crown pumpkin", "code": "50596620" },
      { "name": "Dried Non GMO yams", "code": "50596700" },
      { "name": "Dried Non GMO african bitter yams", "code": "50596701" },
      { "name": "Dried Non GMO asiatic bitter yams", "code": "50596702" },
      { "name": "Dried Non GMO chinese yams", "code": "50596703" },
      { "name": "Dried Non GMO globe yams", "code": "50596704" },
      { "name": "Dried Non GMO greater yams", "code": "50596705" },
      { "name": "Dried Non GMO japanese yams", "code": "50596706" },
      { "name": "Dried Non GMO lesser yams", "code": "50596707" },
      { "name": "Dried Non GMO potato yams", "code": "50596708" },
      { "name": "Dried Non GMO white guinea yams", "code": "50596709" },
      { "name": "Dried Non GMO yellow guinea yams", "code": "50596710" },
      { "name": "Dried Non GMO nominant vegetables", "code": "50596800" },
      { "name": "Dried Non GMO alfalfa", "code": "50596801" },
      { "name": "Dried Non GMO aloe leaves", "code": "50596802" },
      { "name": "Dried Non GMO apio", "code": "50596803" },
      { "name": "Dried Non GMO arrow root", "code": "50596804" },
      { "name": "Dried Non GMO arrowhead", "code": "50596805" },
      { "name": "Dried Non GMO arugula", "code": "50596806" },
      { "name": "Dried Non GMO arum", "code": "50596807" },
      { "name": "Dried Non GMO bamboo shoots", "code": "50596808" },
      { "name": "Dried Non GMO banana leaves", "code": "50596809" },
      { "name": "Dried Non GMO batatas", "code": "50596810" },
      { "name": "Dried Non GMO bean sprouts", "code": "50596811" },
      { "name": "Dried Non GMO beet tops", "code": "50596812" },
      { "name": "Dried Non GMO bittermelon", "code": "50596813" },
      { "name": "Dried Non GMO caperberries", "code": "50596814" },
      { "name": "Dried Non GMO carob", "code": "50596815" },
      { "name": "Dried Non GMO cha-om", "code": "50596816" },
      { "name": "Dried Non GMO chaoyotes", "code": "50596817" },
      { "name": "Dried Non GMO chickpeas", "code": "50596818" },
      { "name": "Dried Non GMO chrysanthemum greens", "code": "50596819" },
      { "name": "Dried Non GMO dandelion greens", "code": "50596820" },
      { "name": "Dried Non GMO dandelions", "code": "50596821" },
      { "name": "Dried Non GMO dasheen", "code": "50596822" },
      { "name": "Dried Non GMO dau mue or pea tips", "code": "50596823" },
      { "name": "Dried Non GMO diakon", "code": "50596824" },
      { "name": "Dried Non GMO donqua", "code": "50596825" },
      { "name": "Dried Non GMO fiddlehead ferns", "code": "50596826" },
      { "name": "Dried Non GMO gai choy", "code": "50596827" },
      { "name": "Dried Non GMO gailon", "code": "50596828" },
      { "name": "Dried Non GMO galanga", "code": "50596829" },
      { "name": "Dried Non GMO ginger root", "code": "50596830" },
      { "name": "Dried Non GMO gobo", "code": "50596831" },
      { "name": "Dried Non GMO hop sprouts", "code": "50596832" },
      { "name": "Dried Non GMO horseradish", "code": "50596833" },
      { "name": "Dried Non GMO jicama", "code": "50596834" },
      { "name": "Dried Non GMO kudzu", "code": "50596835" },
      { "name": "Dried Non GMO lily bulb", "code": "50596836" },
      { "name": "Dried Non GMO linkok", "code": "50596837" },
      { "name": "Dried Non GMO lo bok", "code": "50596838" },
      { "name": "Dried Non GMO long beans", "code": "50596839" },
      { "name": "Dried Non GMO lotus root", "code": "50596840" },
      { "name": "Dried Non GMO maguey leaves", "code": "50596841" },
      { "name": "Dried Non GMO mallows", "code": "50596842" },
      { "name": "Dried Non GMO mamey sapote", "code": "50596843" },
      { "name": "Dried Non GMO moap", "code": "50596844" },
      { "name": "Dried Non GMO moo", "code": "50596845" },
      { "name": "Dried Non GMO moqua", "code": "50596846" },
      { "name": "Dried Non GMO opos", "code": "50596847" },
      { "name": "Dried Non GMO palm hearts", "code": "50596848" },
      { "name": "Dried Non GMO paprika", "code": "50596849" },
      { "name": "Dried Non GMO purslane", "code": "50596850" },
      { "name": "Dried Non GMO raddichios", "code": "50596851" },
      { "name": "Dried Non GMO sinquas", "code": "50596852" },
      { "name": "Dried Non GMO soybeans", "code": "50596853" },
      { "name": "Dried Non GMO spoonwart", "code": "50596854" },
      { "name": "Dried Non GMO tassle grape-hyacinth", "code": "50596855" },
      { "name": "Dried Non GMO taro", "code": "50596856" },
      { "name": "Dried Non GMO taro leaf", "code": "50596857" },
      { "name": "Dried Non GMO taro shoot", "code": "50596858" },
      { "name": "Dried Non GMO tepeguaje", "code": "50596859" },
      { "name": "Dried Non GMO tendergreen", "code": "50596860" },
      { "name": "Dried Non GMO tindora", "code": "50596861" },
      { "name": "Dried Non GMO tree onion", "code": "50596862" },
      { "name": "Dried Non GMO udo", "code": "50596863" },
      { "name": "Dried Non GMO water chestnuts", "code": "50596864" },
      { "name": "Dried Non GMO yampi", "code": "50596865" },
      { "name": "Dried Non GMO yautia", "code": "50596866" },
      { "name": "Dried Non GMO yu choy", "code": "50596867" },
      { "name": "Dried Non GMO yuca", "code": "50596868" },
      { "name": "Dried Non GMO sugar peas", "code": "50596900" },
      { "name": "Dried Non GMO bikini peas", "code": "50596901" },
      { "name": "Dried Non GMO cavalier peas", "code": "50596902" },
      { "name": "Dried Non GMO daisy peas", "code": "50596903" },
      { "name": "Dried Non GMO darfon peas", "code": "50596904" },
      { "name": "Dried Non GMO early onward peas", "code": "50596905" },
      { "name": "Dried Non GMO feltham first peas", "code": "50596906" },
      { "name": "Dried Non GMO hurst green shaft peas", "code": "50596907" },
      { "name": "Dried Non GMO oregon sugar pod peas", "code": "50596908" },
      { "name": "Dried Non GMO prince albert peas", "code": "50596909" },
      { "name": "Dried Non GMO reuzensuiker peas", "code": "50596910" },
      { "name": "Frozen Non GMO vegetables", "code": "50600000" },
      { "name": "Frozen Non GMO artichokes", "code": "50601500" },
      { "name": "Frozen Non GMO brittany artichokes", "code": "50601501" },
      { "name": "Frozen Non GMO catanese artichokes", "code": "50601502" },
      { "name": "Frozen Non GMO french artichokes", "code": "50601503" },
      { "name": "Frozen Non GMO green globe artichokes", "code": "50601504" },
      {
        "name": "Frozen Non GMO gros camus de bretagne artichokes",
        "code": "50601505"
      },
      { "name": "Frozen Non GMO midi artichokes", "code": "50601506" },
      { "name": "Frozen Non GMO purple globe artichokes", "code": "50601507" },
      {
        "name": "Frozen Non GMO purple sicilian artichokes",
        "code": "50601508"
      },
      { "name": "Frozen Non GMO romanesco artichokes", "code": "50601509" },
      { "name": "Frozen Non GMO spinoso sardo artichokes", "code": "50601510" },
      { "name": "Frozen Non GMO vert de laon artichokes", "code": "50601511" },
      {
        "name": "Frozen Non GMO violetta di chioggia artichokes",
        "code": "50601512"
      },
      {
        "name": "Frozen Non GMO violetto di toscana artichokes",
        "code": "50601513"
      },
      { "name": "Frozen Non GMO asparagus", "code": "50601600" },
      {
        "name": "Frozen Non GMO connover's colossal asparagus",
        "code": "50601601"
      },
      { "name": "Frozen Non GMO franklin asparagus", "code": "50601602" },
      { "name": "Frozen Non GMO giant mammoth asparagus", "code": "50601603" },
      { "name": "Frozen Non GMO lucullus asparagus", "code": "50601604" },
      {
        "name": "Frozen Non GMO martha washington asparagus",
        "code": "50601605"
      },
      { "name": "Frozen Non GMO avocados", "code": "50601700" },
      { "name": "Frozen Non GMO ajax b-7 avocados", "code": "50601701" },
      { "name": "Frozen Non GMO arue avocados", "code": "50601702" },
      { "name": "Frozen Non GMO bacon avocados", "code": "50601703" },
      { "name": "Frozen Non GMO benik avocados", "code": "50601704" },
      { "name": "Frozen Non GMO bernecker avocados", "code": "50601705" },
      { "name": "Frozen Non GMO beta avocados", "code": "50601706" },
      { "name": "Frozen Non GMO biondo avocados", "code": "50601707" },
      { "name": "Frozen Non GMO black prince avocados", "code": "50601708" },
      { "name": "Frozen Non GMO blair avocados", "code": "50601709" },
      { "name": "Frozen Non GMO blair booth avocados", "code": "50601710" },
      { "name": "Frozen Non GMO booth 1 avocados", "code": "50601711" },
      { "name": "Frozen Non GMO booth 3 avocados", "code": "50601712" },
      { "name": "Frozen Non GMO booth 5 avocados", "code": "50601713" },
      { "name": "Frozen Non GMO booth 7 avocados", "code": "50601714" },
      { "name": "Frozen Non GMO booth 8 avocados", "code": "50601715" },
      { "name": "Frozen Non GMO brooks 1978 avocados", "code": "50601716" },
      { "name": "Frozen Non GMO brookslate avocados", "code": "50601717" },
      { "name": "Frozen Non GMO california haas avocados", "code": "50601718" },
      { "name": "Frozen Non GMO catalina avocados", "code": "50601719" },
      { "name": "Frozen Non GMO chica avocados", "code": "50601720" },
      { "name": "Frozen Non GMO choquette avocados", "code": "50601721" },
      { "name": "Frozen Non GMO christina avocados", "code": "50601722" },
      { "name": "Frozen Non GMO collinson avocados", "code": "50601723" },
      { "name": "Frozen Non GMO donnie avocados", "code": "50601724" },
      {
        "name": "Frozen Non GMO dr dupuis number 2 avocados",
        "code": "50601725"
      },
      { "name": "Frozen Non GMO dr dupuis avocados", "code": "50601726" },
      { "name": "Frozen Non GMO ettinger avocados", "code": "50601727" },
      { "name": "Frozen Non GMO fuchs avocados", "code": "50601728" },
      { "name": "Frozen Non GMO fuchs gwen avocados", "code": "50601729" },
      { "name": "Frozen Non GMO fuerte avocados", "code": "50601730" },
      { "name": "Frozen Non GMO gorham avocados", "code": "50601731" },
      { "name": "Frozen Non GMO gossman avocados", "code": "50601732" },
      {
        "name": "Frozen Non GMO guatemalan seedling avocados",
        "code": "50601733"
      },
      { "name": "Frozen Non GMO hall avocados", "code": "50601734" },
      { "name": "Frozen Non GMO hardee avocados", "code": "50601735" },
      { "name": "Frozen Non GMO haas avocados", "code": "50601736" },
      { "name": "Frozen Non GMO herman avocados", "code": "50601737" },
      { "name": "Frozen Non GMO hickson avocados", "code": "50601738" },
      { "name": "Frozen Non GMO k-5 avocados", "code": "50601739" },
      { "name": "Frozen Non GMO k-9 avocados", "code": "50601740" },
      { "name": "Frozen Non GMO lamb haas avocados", "code": "50601741" },
      { "name": "Frozen Non GMO leona avocados", "code": "50601742" },
      { "name": "Frozen Non GMO leona linda avocados", "code": "50601743" },
      { "name": "Frozen Non GMO lisa p avocados", "code": "50601744" },
      { "name": "Frozen Non GMO lisa loretta avocados", "code": "50601745" },
      { "name": "Frozen Non GMO loretta avocados", "code": "50601746" },
      { "name": "Frozen Non GMO lula avocados", "code": "50601747" },
      { "name": "Frozen Non GMO lula macarthur avocados", "code": "50601748" },
      { "name": "Frozen Non GMO marcus avocados", "code": "50601749" },
      { "name": "Frozen Non GMO melendez avocados", "code": "50601750" },
      { "name": "Frozen Non GMO meya p avocados", "code": "50601751" },
      { "name": "Frozen Non GMO miguel p avocados", "code": "50601752" },
      { "name": "Frozen Non GMO monroe avocados", "code": "50601753" },
      { "name": "Frozen Non GMO murrieta green avocados", "code": "50601754" },
      { "name": "Frozen Non GMO nabal avocados", "code": "50601755" },
      { "name": "Frozen Non GMO nadir avocados", "code": "50601756" },
      { "name": "Frozen Non GMO nesbitt avocados", "code": "50601757" },
      { "name": "Frozen Non GMO peterson avocados", "code": "50601758" },
      { "name": "Frozen Non GMO pinelli avocados", "code": "50601759" },
      { "name": "Frozen Non GMO pinkerton avocados", "code": "50601760" },
      { "name": "Frozen Non GMO pollock avocados", "code": "50601761" },
      { "name": "Frozen Non GMO puebla avocados", "code": "50601762" },
      { "name": "Frozen Non GMO reed avocados", "code": "50601763" },
      { "name": "Frozen Non GMO rue avocados", "code": "50601764" },
      { "name": "Frozen Non GMO ruehle avocados", "code": "50601765" },
      { "name": "Frozen Non GMO ryan avocados", "code": "50601766" },
      { "name": "Frozen Non GMO semil 34 avocados", "code": "50601767" },
      { "name": "Frozen Non GMO semil 43 avocados", "code": "50601768" },
      { "name": "Frozen Non GMO simmonds avocados", "code": "50601769" },
      { "name": "Frozen Non GMO simpson avocados", "code": "50601770" },
      { "name": "Frozen Non GMO taylor avocados", "code": "50601771" },
      { "name": "Frozen Non GMO tonnage avocados", "code": "50601772" },
      { "name": "Frozen Non GMO tower avocados", "code": "50601773" },
      { "name": "Frozen Non GMO tower li avocados", "code": "50601774" },
      { "name": "Frozen Non GMO trapp avocados", "code": "50601775" },
      {
        "name": "Frozen Non GMO west indian seedling avocados",
        "code": "50601776"
      },
      { "name": "Frozen Non GMO wagner avocados", "code": "50601777" },
      { "name": "Frozen Non GMO waldin avocados", "code": "50601778" },
      { "name": "Frozen Non GMO wurtz avocados", "code": "50601779" },
      { "name": "Frozen Non GMO zio p avocados", "code": "50601780" },
      { "name": "Frozen Non GMO ziu avocados", "code": "50601781" },
      { "name": "Frozen Non GMO zutano avocados", "code": "50601782" },
      { "name": "Frozen Non GMO beans", "code": "50601800" },
      { "name": "Frozen Non GMO anasazi or aztec beans", "code": "50601801" },
      { "name": "Frozen Non GMO appaloosa beans", "code": "50601802" },
      { "name": "Frozen Non GMO azuki beans", "code": "50601803" },
      { "name": "Frozen Non GMO barlotti beans", "code": "50601804" },
      { "name": "Frozen Non GMO black appaloosa beans", "code": "50601805" },
      { "name": "Frozen Non GMO black beans", "code": "50601806" },
      { "name": "Frozen Non GMO black gram beans", "code": "50601807" },
      { "name": "Frozen Non GMO black shackamaxon beans", "code": "50601808" },
      { "name": "Frozen Non GMO blackeyed beans", "code": "50601809" },
      { "name": "Frozen Non GMO bobby beans", "code": "50601810" },
      { "name": "Frozen Non GMO bolita beans", "code": "50601811" },
      { "name": "Frozen Non GMO brown lazy wife beans", "code": "50601812" },
      { "name": "Frozen Non GMO calypso beans", "code": "50601813" },
      { "name": "Frozen Non GMO cannellini beans", "code": "50601814" },
      { "name": "Frozen Non GMO castor beans", "code": "50601815" },
      { "name": "Frozen Non GMO china yellow beans", "code": "50601816" },
      { "name": "Frozen Non GMO dragon tongue beans", "code": "50601817" },
      { "name": "Frozen Non GMO european soldier beans", "code": "50601818" },
      { "name": "Frozen Non GMO fava beans", "code": "50601819" },
      { "name": "Frozen Non GMO flageolet beans", "code": "50601820" },
      {
        "name": "Frozen Non GMO french horticultural beans",
        "code": "50601821"
      },
      { "name": "Frozen Non GMO french navy beans", "code": "50601822" },
      { "name": "Frozen Non GMO giant white coco beans", "code": "50601823" },
      { "name": "Frozen Non GMO green beans", "code": "50601824" },
      { "name": "Frozen Non GMO green romano beans", "code": "50601825" },
      { "name": "Frozen Non GMO guar gum beans", "code": "50601826" },
      { "name": "Frozen Non GMO haricot beans", "code": "50601827" },
      { "name": "Frozen Non GMO hyacinth beans", "code": "50601828" },
      { "name": "Frozen Non GMO italian type beans", "code": "50601829" },
      { "name": "Frozen Non GMO jackson wonder beans", "code": "50601830" },
      { "name": "Frozen Non GMO jacob's cattle beans", "code": "50601831" },
      { "name": "Frozen Non GMO kentucky wonder beans", "code": "50601832" },
      { "name": "Frozen Non GMO kidney beans", "code": "50601833" },
      { "name": "Frozen Non GMO lima beans", "code": "50601834" },
      { "name": "Frozen Non GMO madeira/madera beans", "code": "50601835" },
      { "name": "Frozen Non GMO marrow beans", "code": "50601836" },
      { "name": "Frozen Non GMO mat beans", "code": "50601837" },
      {
        "name": "Frozen Non GMO monstoller wild goose beans",
        "code": "50601838"
      },
      { "name": "Frozen Non GMO mortgage lifter beans", "code": "50601839" },
      { "name": "Frozen Non GMO moth beans", "code": "50601840" },
      { "name": "Frozen Non GMO mung beans", "code": "50601841" },
      { "name": "Frozen Non GMO munsi wolf bean", "code": "50601842" },
      { "name": "Frozen Non GMO nuna beans", "code": "50601843" },
      { "name": "Frozen Non GMO pinto beans", "code": "50601844" },
      { "name": "Frozen Non GMO pole beans", "code": "50601845" },
      { "name": "Frozen Non GMO runner beans", "code": "50601846" },
      { "name": "Frozen Non GMO string beans", "code": "50601847" },
      { "name": "Frozen Non GMO tamarind beans", "code": "50601848" },
      { "name": "Frozen Non GMO tonka beans", "code": "50601849" },
      { "name": "Frozen Non GMO wax beans", "code": "50601850" },
      { "name": "Frozen Non GMO winged beans", "code": "50601851" },
      { "name": "Frozen Non GMO yard long beans", "code": "50601852" },
      { "name": "Frozen Non GMO beets", "code": "50601900" },
      { "name": "Frozen Non GMO action beets", "code": "50601901" },
      { "name": "Frozen Non GMO albina vereduna beets", "code": "50601902" },
      {
        "name": "Frozen Non GMO barbabietola di chioggia beets",
        "code": "50601903"
      },
      { "name": "Frozen Non GMO boltardy beets", "code": "50601904" },
      { "name": "Frozen Non GMO bonel beets", "code": "50601905" },
      { "name": "Frozen Non GMO burpees golden beets", "code": "50601906" },
      {
        "name": "Frozen Non GMO cheltenham green top beets",
        "code": "50601907"
      },
      { "name": "Frozen Non GMO cheltenham mono beets", "code": "50601908" },
      { "name": "Frozen Non GMO chioggia beets", "code": "50601909" },
      { "name": "Frozen Non GMO cylindra beets", "code": "50601910" },
      { "name": "Frozen Non GMO d'egypte beets", "code": "50601911" },
      { "name": "Frozen Non GMO detroit 2 dark red beets", "code": "50601912" },
      {
        "name": "Frozen Non GMO detroit 2 little ball beets",
        "code": "50601913"
      },
      { "name": "Frozen Non GMO egyptian flat beets", "code": "50601914" },
      {
        "name": "Frozen Non GMO egyptian turnip rooted beets",
        "code": "50601915"
      },
      { "name": "Frozen Non GMO formanova beets", "code": "50601916" },
      { "name": "Frozen Non GMO forono beets", "code": "50601917" },
      { "name": "Frozen Non GMO monaco beets", "code": "50601918" },
      { "name": "Frozen Non GMO monogram beets", "code": "50601919" },
      { "name": "Frozen Non GMO pronto beets", "code": "50601920" },
      { "name": "Frozen Non GMO regalia beets", "code": "50601921" },
      { "name": "Frozen Non GMO sugar beets", "code": "50601922" },
      { "name": "Frozen Non GMO broccoli", "code": "50602000" },
      { "name": "Frozen Non GMO broccolini", "code": "50602001" },
      { "name": "Frozen Non GMO broccoli romanesco", "code": "50602002" },
      { "name": "Frozen Non GMO broccoli raab", "code": "50602003" },
      { "name": "Frozen Non GMO chinese broccoli", "code": "50602004" },
      { "name": "Frozen Non GMO brussel sprouts", "code": "50602100" },
      { "name": "Frozen Non GMO citadel brussel sprouts", "code": "50602101" },
      { "name": "Frozen Non GMO falstaff brussel sprouts", "code": "50602102" },
      { "name": "Frozen Non GMO oliver brussel sprouts", "code": "50602103" },
      {
        "name": "Frozen Non GMO peer gynt brussel sprouts",
        "code": "50602104"
      },
      { "name": "Frozen Non GMO rampart brussel sprouts", "code": "50602105" },
      { "name": "Frozen Non GMO rubine brussel sprouts", "code": "50602106" },
      { "name": "Frozen Non GMO widgeon brussel sprouts", "code": "50602107" },
      { "name": "Frozen Non GMO bunching onions", "code": "50602200" },
      {
        "name": "Frozen Non GMO beltsville bunching onions",
        "code": "50602201"
      },
      { "name": "Frozen Non GMO feast bunching onions", "code": "50602202" },
      { "name": "Frozen Non GMO ishikura bunching onions", "code": "50602203" },
      {
        "name": "Frozen Non GMO kyoto market bunching onions",
        "code": "50602204"
      },
      {
        "name": "Frozen Non GMO red beard bunching onions",
        "code": "50602205"
      },
      { "name": "Frozen Non GMO redmate bunching onions", "code": "50602206" },
      {
        "name": "Frozen Non GMO santa claus bunching onions",
        "code": "50602207"
      },
      { "name": "Frozen Non GMO tokyo bunching onions", "code": "50602208" },
      {
        "name": "Frozen Non GMO white lisbon bunching onions",
        "code": "50602209"
      },
      {
        "name": "Frozen Non GMO winter white bunching onions",
        "code": "50602210"
      },
      {
        "name": "Frozen Non GMO winter-over bunching onions",
        "code": "50602211"
      },
      { "name": "Frozen Non GMO cabbages", "code": "50602300" },
      { "name": "Frozen Non GMO black cabbages", "code": "50602301" },
      { "name": "Frozen Non GMO savoy cabbages", "code": "50602302" },
      { "name": "Frozen Non GMO skunk cabbages", "code": "50602303" },
      { "name": "Frozen Non GMO white cabbages", "code": "50602304" },
      { "name": "Frozen Non GMO cardoons", "code": "50602400" },
      { "name": "Frozen Non GMO lunghi cardoons", "code": "50602401" },
      { "name": "Frozen Non GMO gobbi cardoons", "code": "50602402" },
      { "name": "Frozen Non GMO carrots", "code": "50602500" },
      { "name": "Frozen Non GMO amsterdam carrots", "code": "50602501" },
      { "name": "Frozen Non GMO autumn king carrots", "code": "50602502" },
      { "name": "Frozen Non GMO berlicum carrots", "code": "50602503" },
      { "name": "Frozen Non GMO chantenay carrots", "code": "50602504" },
      { "name": "Frozen Non GMO nantes carrots", "code": "50602505" },
      { "name": "Frozen Non GMO paris market carrots", "code": "50602506" },
      { "name": "Frozen Non GMO cauliflowers", "code": "50602600" },
      {
        "name": "Frozen Non GMO all the year round cauliflowers",
        "code": "50602601"
      },
      { "name": "Frozen Non GMO alverda cauliflowers", "code": "50602602" },
      {
        "name": "Frozen Non GMO autumn giant 3 cauliflowers",
        "code": "50602603"
      },
      { "name": "Frozen Non GMO dok elgon cauliflowers", "code": "50602604" },
      {
        "name": "Frozen Non GMO early snowball cauliflowers",
        "code": "50602605"
      },
      { "name": "Frozen Non GMO limelight cauliflowers", "code": "50602606" },
      { "name": "Frozen Non GMO minaret cauliflowers", "code": "50602607" },
      {
        "name": "Frozen Non GMO orange bouquet cauliflowers",
        "code": "50602608"
      },
      { "name": "Frozen Non GMO purple cape cauliflowers", "code": "50602609" },
      { "name": "Frozen Non GMO snowball cauliflowers", "code": "50602610" },
      {
        "name": "Frozen Non GMO walcheren winter 3 cauliflowers",
        "code": "50602611"
      },
      { "name": "Frozen Non GMO white rock cauliflowers", "code": "50602612" },
      { "name": "Frozen Non GMO celery", "code": "50602700" },
      { "name": "Frozen Non GMO celebrity celery", "code": "50602701" },
      { "name": "Frozen Non GMO celeriac", "code": "50602702" },
      { "name": "Frozen Non GMO chinese celery", "code": "50602703" },
      { "name": "Frozen Non GMO french dinant celery", "code": "50602704" },
      { "name": "Frozen Non GMO giant pink celery", "code": "50602705" },
      { "name": "Frozen Non GMO giant red celery", "code": "50602706" },
      { "name": "Frozen Non GMO giant white celery", "code": "50602707" },
      {
        "name": "Frozen Non GMO golden self-blanching celery",
        "code": "50602708"
      },
      { "name": "Frozen Non GMO greensleeves celery", "code": "50602709" },
      { "name": "Frozen Non GMO hopkins fenlander celery", "code": "50602710" },
      { "name": "Frozen Non GMO ivory tower celery", "code": "50602711" },
      {
        "name": "Frozen Non GMO lathom self-blanching celery",
        "code": "50602712"
      },
      { "name": "Frozen Non GMO soup celery d'amsterdam", "code": "50602713" },
      { "name": "Frozen Non GMO standard bearer celery", "code": "50602714" },
      { "name": "Frozen Non GMO tall utah triumph celery", "code": "50602715" },
      { "name": "Frozen Non GMO chards", "code": "50602800" },
      { "name": "Frozen Non GMO bright lights chard", "code": "50602801" },
      { "name": "Frozen Non GMO fordhook giant chard", "code": "50602802" },
      { "name": "Frozen Non GMO lucullus chard", "code": "50602803" },
      { "name": "Frozen Non GMO perpetual spinach chard", "code": "50602804" },
      { "name": "Frozen Non GMO rhubarb chard", "code": "50602805" },
      { "name": "Frozen Non GMO swiss chard", "code": "50602806" },
      { "name": "Frozen Non GMO vulcan chard", "code": "50602807" },
      { "name": "Frozen Non GMO white king chard", "code": "50602808" },
      { "name": "Frozen Non GMO chicories", "code": "50602900" },
      {
        "name": "Frozen Non GMO broad leaved batavian chicory",
        "code": "50602901"
      },
      {
        "name": "Frozen Non GMO en cornet de bordeaux chicory",
        "code": "50602902"
      },
      {
        "name": "Frozen Non GMO green curled ruffee chicory",
        "code": "50602903"
      },
      { "name": "Frozen Non GMO green curled chicory", "code": "50602904" },
      { "name": "Frozen Non GMO ione limnos chicory", "code": "50602905" },
      {
        "name": "Frozen Non GMO riccia pancalieri chicory",
        "code": "50602906"
      },
      { "name": "Frozen Non GMO salad king chicory", "code": "50602907" },
      { "name": "Frozen Non GMO sanda chicory", "code": "50602908" },
      { "name": "Frozen Non GMO scarola verde chicory", "code": "50602909" },
      {
        "name": "Frozen Non GMO tres fine maraichere chicory",
        "code": "50602910"
      },
      {
        "name": "Frozen Non GMO wallone freisee weschelkopf chicory",
        "code": "50602911"
      },
      { "name": "Frozen Non GMO chinese cabbages", "code": "50603000" },
      { "name": "Frozen Non GMO bok choy", "code": "50603001" },
      {
        "name": "Frozen Non GMO chinese flat-headed cabbage",
        "code": "50603002"
      },
      {
        "name": "Frozen Non GMO chinese flowering cabbage",
        "code": "50603003"
      },
      { "name": "Frozen Non GMO choy sum", "code": "50603004" },
      { "name": "Frozen Non GMO dwarf bok choy", "code": "50603005" },
      { "name": "Frozen Non GMO fengshan bok choy", "code": "50603006" },
      { "name": "Frozen Non GMO jade pagoda bok choy", "code": "50603007" },
      { "name": "Frozen Non GMO kasumi bok choy", "code": "50603008" },
      { "name": "Frozen Non GMO nerva bok choy", "code": "50603009" },
      { "name": "Frozen Non GMO rosette bok choy", "code": "50603010" },
      { "name": "Frozen Non GMO ruffles bok choy", "code": "50603011" },
      {
        "name": "Frozen Non GMO santo serrated leaved cabbage",
        "code": "50603012"
      },
      { "name": "Frozen Non GMO shanghai d bok choy", "code": "50603013" },
      { "name": "Frozen Non GMO shantung cabbage", "code": "50603014" },
      { "name": "Frozen Non GMO tip top cabbage", "code": "50603015" },
      { "name": "Frozen Non GMO yau choy sum", "code": "50603016" },
      { "name": "Frozen Non GMO chives", "code": "50603100" },
      { "name": "Frozen Non GMO chinese chives", "code": "50603101" },
      { "name": "Non GMO Frozen common chives", "code": "50603102" },
      { "name": "Frozen Non GMO corn", "code": "50603200" },
      { "name": "Frozen Non GMO aloha corn", "code": "50603201" },
      { "name": "Frozen Non GMO alpine corn", "code": "50603202" },
      { "name": "Frozen Non GMO ambrosia corn", "code": "50603203" },
      { "name": "Frozen Non GMO argent corn", "code": "50603204" },
      { "name": "Frozen Non GMO aspen corn", "code": "50603205" },
      { "name": "Frozen Non GMO avalanche corn", "code": "50603206" },
      { "name": "Frozen Non GMO biqueen corn", "code": "50603207" },
      { "name": "Frozen Non GMO bodacious corn", "code": "50603208" },
      { "name": "Frozen Non GMO butter and sugar corn", "code": "50603209" },
      { "name": "Frozen Non GMO calico belle corn", "code": "50603210" },
      { "name": "Frozen Non GMO camelot corn", "code": "50603211" },
      {
        "name": "Frozen Non GMO challenger crisp \u2018n sweet corn",
        "code": "50603212"
      },
      { "name": "Frozen Non GMO champ corn", "code": "50603213" },
      { "name": "Frozen Non GMO cotton candy corn", "code": "50603214" },
      { "name": "Frozen Non GMO d\u2019artagnan corn", "code": "50603215" },
      { "name": "Frozen Non GMO dazzle corn", "code": "50603216" },
      { "name": "Frozen Non GMO diamonds and gold corn", "code": "50603217" },
      { "name": "Frozen Non GMO divinity corn", "code": "50603218" },
      { "name": "Frozen Non GMO double delight corn", "code": "50603219" },
      { "name": "Frozen Non GMO double gem corn", "code": "50603220" },
      { "name": "Frozen Non GMO earlivee corn", "code": "50603221" },
      { "name": "Frozen Non GMO early xtra sweet corn", "code": "50603222" },
      { "name": "Frozen Non GMO excel corn", "code": "50603223" },
      { "name": "Frozen Non GMO golden cross bantam corn", "code": "50603224" },
      { "name": "Frozen Non GMO honey and cream corn", "code": "50603225" },
      { "name": "Frozen Non GMO honey \u2018n pearl corn", "code": "50603226" },
      { "name": "Frozen Non GMO how sweet it is corn", "code": "50603227" },
      { "name": "Frozen Non GMO hudson corn", "code": "50603228" },
      { "name": "Frozen Non GMO illini gold corn", "code": "50603229" },
      { "name": "Frozen Non GMO illini xtra sweet corn", "code": "50603230" },
      { "name": "Frozen Non GMO incredible corn", "code": "50603231" },
      { "name": "Frozen Non GMO iochief corn", "code": "50603232" },
      { "name": "Frozen Non GMO jubilee corn", "code": "50603233" },
      { "name": "Frozen Non GMO jubilee supersweet corn", "code": "50603234" },
      { "name": "Frozen Non GMO kandy korn corn", "code": "50603235" },
      { "name": "Frozen Non GMO kiss \u2018n tell corn", "code": "50603236" },
      { "name": "Frozen Non GMO lancelot corn", "code": "50603237" },
      { "name": "Frozen Non GMO maple sweet corn", "code": "50603238" },
      { "name": "Frozen Non GMO medley corn", "code": "50603239" },
      { "name": "Frozen Non GMO merlin corn", "code": "50603240" },
      { "name": "Frozen Non GMO miracle corn", "code": "50603241" },
      { "name": "Frozen Non GMO nk-199 corn", "code": "50603242" },
      { "name": "Frozen Non GMO peaches and cream corn", "code": "50603243" },
      { "name": "Frozen Non GMO pearl white corn", "code": "50603244" },
      { "name": "Frozen Non GMO pegasus corn", "code": "50603245" },
      { "name": "Frozen Non GMO phenomenal corn", "code": "50603246" },
      { "name": "Frozen Non GMO platinum lady corn", "code": "50603247" },
      { "name": "Frozen Non GMO precocious corn", "code": "50603248" },
      { "name": "Frozen Non GMO pristine corn", "code": "50603249" },
      { "name": "Frozen Non GMO quickie corn", "code": "50603250" },
      { "name": "Frozen Non GMO radiance corn", "code": "50603251" },
      { "name": "Frozen Non GMO seneca brave corn", "code": "50603252" },
      { "name": "Frozen Non GMO seneca dawn corn", "code": "50603253" },
      { "name": "Frozen Non GMO seneca horizon corn", "code": "50603254" },
      { "name": "Frozen Non GMO seneca starshine corn", "code": "50603255" },
      { "name": "Frozen Non GMO seneca white knight corn", "code": "50603256" },
      { "name": "Frozen Non GMO showcase corn", "code": "50603257" },
      { "name": "Frozen Non GMO silver queen corn", "code": "50603258" },
      { "name": "Frozen Non GMO snowbelle corn", "code": "50603259" },
      { "name": "Frozen Non GMO spring snow corn", "code": "50603260" },
      { "name": "Frozen Non GMO spring treat corn", "code": "50603261" },
      { "name": "Frozen Non GMO sugar and gold corn", "code": "50603262" },
      { "name": "Frozen Non GMO sugar buns corn", "code": "50603263" },
      { "name": "Frozen Non GMO sugar snow corn", "code": "50603264" },
      { "name": "Frozen Non GMO sundance corn", "code": "50603265" },
      { "name": "Frozen Non GMO telstar corn", "code": "50603266" },
      { "name": "Frozen Non GMO terminator corn", "code": "50603267" },
      { "name": "Frozen Non GMO treasure corn", "code": "50603268" },
      { "name": "Frozen Non GMO tuxedo corn", "code": "50603269" },
      { "name": "Frozen Non GMO cresses", "code": "50603300" },
      { "name": "Frozen Non GMO land cress", "code": "50603301" },
      { "name": "Frozen Non GMO nasturtium", "code": "50603302" },
      { "name": "Frozen Non GMO watercress", "code": "50603303" },
      { "name": "Frozen Non GMO wintercress", "code": "50603304" },
      { "name": "Frozen Non GMO cucumbers", "code": "50603400" },
      { "name": "Frozen Non GMO arena cucumbers", "code": "50603401" },
      { "name": "Frozen Non GMO armenian cucumbers", "code": "50603402" },
      { "name": "Frozen Non GMO athene cucumbers", "code": "50603403" },
      {
        "name": "Frozen Non GMO bianco lungo di parigi cucumbers",
        "code": "50603404"
      },
      {
        "name": "Frozen Non GMO burpless tasty green cucumbers",
        "code": "50603405"
      },
      {
        "name": "Frozen Non GMO chicago pickling cucumbers",
        "code": "50603406"
      },
      { "name": "Frozen Non GMO crystal apple cucumbers", "code": "50603407" },
      { "name": "Frozen Non GMO crystal lemon cucumbers", "code": "50603408" },
      { "name": "Frozen Non GMO danimas cucumbers", "code": "50603409" },
      { "name": "Frozen Non GMO gherkin cucumbers", "code": "50603410" },
      { "name": "Frozen Non GMO hokus cucumbers", "code": "50603411" },
      { "name": "Frozen Non GMO japanese cucumbers", "code": "50603412" },
      { "name": "Frozen Non GMO karela cucumbers", "code": "50603413" },
      { "name": "Frozen Non GMO korila cucumbers", "code": "50603414" },
      {
        "name": "Frozen Non GMO long green improved cucumbers",
        "code": "50603415"
      },
      { "name": "Frozen Non GMO marketmore cucumbers", "code": "50603416" },
      { "name": "Frozen Non GMO midget cucumbers", "code": "50603417" },
      {
        "name": "Frozen Non GMO national pickling cucumbers",
        "code": "50603418"
      },
      { "name": "Frozen Non GMO persian cucumbers", "code": "50603419" },
      { "name": "Frozen Non GMO telegraph cucumbers", "code": "50603420" },
      {
        "name": "Frozen Non GMO telegraph improved cucumbers",
        "code": "50603421"
      },
      {
        "name": "Frozen Non GMO vert de massy cornichon cucumbers",
        "code": "50603422"
      },
      { "name": "Frozen Non GMO yamato cucumbers", "code": "50603423" },
      { "name": "Frozen Non GMO eggplants", "code": "50603500" },
      { "name": "Frozen Non GMO bambino eggplants", "code": "50603501" },
      { "name": "Frozen Non GMO black beauty eggplants", "code": "50603502" },
      { "name": "Frozen Non GMO black enorma eggplants", "code": "50603503" },
      { "name": "Frozen Non GMO chinese eggplants", "code": "50603504" },
      { "name": "Frozen Non GMO easter egg eggplants", "code": "50603505" },
      { "name": "Frozen Non GMO filipino eggplants", "code": "50603506" },
      { "name": "Frozen Non GMO florida market eggplants", "code": "50603507" },
      { "name": "Frozen Non GMO indian eggplants", "code": "50603508" },
      { "name": "Frozen Non GMO italian eggplants", "code": "50603509" },
      { "name": "Frozen Non GMO japanese eggplants", "code": "50603510" },
      { "name": "Frozen Non GMO long purple eggplants", "code": "50603511" },
      { "name": "Frozen Non GMO long striped eggplants", "code": "50603512" },
      { "name": "Frozen Non GMO moneymaker eggplants", "code": "50603513" },
      { "name": "Frozen Non GMO ova eggplants", "code": "50603514" },
      { "name": "Frozen Non GMO pea eggplants", "code": "50603515" },
      { "name": "Frozen Non GMO short tom eggplants", "code": "50603516" },
      { "name": "Frozen Non GMO sicilian eggplants", "code": "50603517" },
      { "name": "Frozen Non GMO thai eggplants", "code": "50603518" },
      {
        "name": "Frozen Non GMO violette di firenze eggplants",
        "code": "50603519"
      },
      { "name": "Frozen Non GMO white eggplants", "code": "50603520" },
      { "name": "Frozen Non GMO endives", "code": "50603600" },
      { "name": "Frozen Non GMO brussels witloof endives", "code": "50603601" },
      { "name": "Frozen Non GMO castelfranco endives", "code": "50603602" },
      {
        "name": "Frozen Non GMO catalogna di galatina endives",
        "code": "50603603"
      },
      { "name": "Frozen Non GMO chioggia endives", "code": "50603604" },
      { "name": "Frozen Non GMO grumolo verde endives", "code": "50603605" },
      {
        "name": "Frozen Non GMO large rooted magdeburg endives",
        "code": "50603606"
      },
      {
        "name": "Frozen Non GMO palla rossa zorzi precoce endives",
        "code": "50603607"
      },
      { "name": "Frozen Non GMO radice amare endives", "code": "50603608" },
      { "name": "Frozen Non GMO rossa di treviso endives", "code": "50603609" },
      { "name": "Frozen Non GMO rossa di verona endives", "code": "50603610" },
      { "name": "Frozen Non GMO soncino endives", "code": "50603611" },
      { "name": "Frozen Non GMO sugarhat endives", "code": "50603612" },
      { "name": "Frozen Non GMO verona endives", "code": "50603613" },
      { "name": "Frozen Non GMO witloof zoom endives", "code": "50603614" },
      { "name": "Frozen Non GMO fennels", "code": "50603700" },
      { "name": "Frozen Non GMO cantino fennel", "code": "50603701" },
      { "name": "Frozen Non GMO fino fennel", "code": "50603702" },
      { "name": "Frozen Non GMO herald fennel", "code": "50603703" },
      { "name": "Frozen Non GMO perfection fennel", "code": "50603704" },
      { "name": "Frozen Non GMO sirio fennel", "code": "50603705" },
      { "name": "Frozen Non GMO sweet florence fennel", "code": "50603706" },
      { "name": "Frozen Non GMO tardo fennel", "code": "50603707" },
      { "name": "Frozen Non GMO garlics", "code": "50603800" },
      { "name": "Frozen Non GMO california late garlic", "code": "50603801" },
      { "name": "Frozen Non GMO chinese garlic stems", "code": "50603802" },
      { "name": "Frozen Non GMO garlic chives", "code": "50603803" },
      { "name": "Frozen Non GMO germidor garlic", "code": "50603804" },
      { "name": "Frozen Non GMO long keeper garlic", "code": "50603805" },
      { "name": "Frozen Non GMO ramson garlic", "code": "50603806" },
      { "name": "Frozen Non GMO rocambole garlic", "code": "50603807" },
      { "name": "Frozen Non GMO rose de lautrec garlic", "code": "50603808" },
      { "name": "Frozen Non GMO solent wight garlic", "code": "50603809" },
      { "name": "Frozen Non GMO spanish morado garlic", "code": "50603810" },
      { "name": "Frozen Non GMO venetian/italian garlic", "code": "50603811" },
      { "name": "Frozen Non GMO gourds", "code": "50603900" },
      { "name": "Frozen Non GMO angled loofah", "code": "50603901" },
      { "name": "Frozen Non GMO bitter gourd", "code": "50603902" },
      { "name": "Frozen Non GMO bottle gourd", "code": "50603903" },
      { "name": "Frozen Non GMO calabash gourds", "code": "50603904" },
      { "name": "Frozen Non GMO fuzzy/hairy melon", "code": "50603905" },
      { "name": "Frozen Non GMO musky gourd", "code": "50603906" },
      { "name": "Frozen Non GMO smooth loofah", "code": "50603907" },
      { "name": "Frozen Non GMO snake gourd", "code": "50603908" },
      { "name": "Frozen Non GMO spiny bitter gourd", "code": "50603909" },
      { "name": "Frozen Non GMO tinda gourds", "code": "50603910" },
      { "name": "Frozen Non GMO tindoori gourds", "code": "50603911" },
      { "name": "Frozen Non GMO green peas", "code": "50604000" },
      { "name": "Frozen Non GMO china peas", "code": "50604001" },
      { "name": "Frozen Non GMO english peas", "code": "50604002" },
      { "name": "Frozen Non GMO garden peas", "code": "50604003" },
      { "name": "Frozen Non GMO snow peas", "code": "50604004" },
      { "name": "Frozen Non GMO sugar snap peas", "code": "50604005" },
      { "name": "Frozen Non GMO herbs", "code": "50604100" },
      { "name": "Frozen Non GMO basil", "code": "50604101" },
      { "name": "Frozen Non GMO bay leaves", "code": "50604102" },
      { "name": "Frozen Non GMO borage", "code": "50604103" },
      { "name": "Frozen Non GMO caraway", "code": "50604104" },
      { "name": "Frozen Non GMO chervil", "code": "50604105" },
      { "name": "Frozen Non GMO cilantro", "code": "50604106" },
      { "name": "Frozen Non GMO cipolinos", "code": "50604107" },
      { "name": "Frozen Non GMO curry leaves", "code": "50604108" },
      { "name": "Frozen Non GMO dill", "code": "50604109" },
      { "name": "Frozen Non GMO epazote", "code": "50604110" },
      { "name": "Frozen Non GMO fenugreek", "code": "50604111" },
      { "name": "Frozen Non GMO lemon grass", "code": "50604112" },
      { "name": "Frozen Non GMO marjoram", "code": "50604113" },
      { "name": "Frozen Non GMO mint", "code": "50604114" },
      { "name": "Frozen Non GMO oregano", "code": "50604115" },
      { "name": "Frozen Non GMO papalo", "code": "50604116" },
      { "name": "Frozen Non GMO pepicha", "code": "50604117" },
      { "name": "Frozen Non GMO perilla", "code": "50604118" },
      { "name": "Frozen Non GMO recao", "code": "50604119" },
      { "name": "Frozen Non GMO rosemary", "code": "50604120" },
      { "name": "Frozen Non GMO sage", "code": "50604121" },
      { "name": "Frozen Non GMO salsify", "code": "50604122" },
      { "name": "Frozen Non GMO savory", "code": "50604123" },
      { "name": "Frozen Non GMO tarragon", "code": "50604124" },
      { "name": "Frozen Non GMO thyme", "code": "50604125" },
      { "name": "Frozen Non GMO tumeric", "code": "50604126" },
      { "name": "Frozen Non GMO verdulaga", "code": "50604127" },
      { "name": "Frozen Non GMO kale", "code": "50604200" },
      { "name": "Frozen Non GMO curly kale", "code": "50604201" },
      { "name": "Frozen Non GMO collard greens", "code": "50604202" },
      { "name": "Frozen Non GMO kohlrabi", "code": "50604300" },
      { "name": "Frozen Non GMO azur star kohlrabi", "code": "50604301" },
      { "name": "Frozen Non GMO green vienna kohlrabi", "code": "50604302" },
      { "name": "Frozen Non GMO lanro kohlrabi", "code": "50604303" },
      { "name": "Frozen Non GMO purple vienna kohlrabi", "code": "50604304" },
      { "name": "Frozen Non GMO rowel trero kohlrabi", "code": "50604305" },
      { "name": "Frozen Non GMO white vienna kohlrabi", "code": "50604306" },
      { "name": "Frozen Non GMO leeks", "code": "50604400" },
      { "name": "Frozen Non GMO autumn giant-cobra leeks", "code": "50604401" },
      { "name": "Frozen Non GMO autumn mammoth 2 leeks", "code": "50604402" },
      { "name": "Frozen Non GMO bleu de solaise leeks", "code": "50604403" },
      { "name": "Frozen Non GMO cortina leeks", "code": "50604404" },
      { "name": "Frozen Non GMO prelina leeks", "code": "50604405" },
      { "name": "Frozen Non GMO wild leek ramp", "code": "50604406" },
      { "name": "Frozen Non GMO lentils", "code": "50604500" },
      { "name": "Frozen Non GMO beluga lentils", "code": "50604501" },
      { "name": "Frozen Non GMO french green lentils", "code": "50604502" },
      { "name": "Frozen Non GMO green lentils", "code": "50604503" },
      { "name": "Frozen Non GMO petite crimson lentils", "code": "50604504" },
      { "name": "Frozen Non GMO spanish pardina lentils", "code": "50604505" },
      { "name": "Frozen Non GMO split red lentils", "code": "50604506" },
      { "name": "Frozen Non GMO split yellow lentils", "code": "50604507" },
      { "name": "Frozen Non GMO tarahumara pinks lentils", "code": "50604508" },
      { "name": "Frozen Non GMO lettuces", "code": "50604600" },
      { "name": "Frozen Non GMO bibb lettuce", "code": "50604601" },
      { "name": "Frozen Non GMO boston lettuce", "code": "50604602" },
      { "name": "Frozen Non GMO frisee lettuce", "code": "50604603" },
      { "name": "Frozen Non GMO lolla rossa lettuce", "code": "50604604" },
      { "name": "Frozen Non GMO mesculin mix lettuce", "code": "50604605" },
      { "name": "Frozen Non GMO mizuna lettuce", "code": "50604606" },
      { "name": "Frozen Non GMO red leaf lettuce", "code": "50604607" },
      { "name": "Frozen Non GMO red oak leaf lettuce", "code": "50604608" },
      { "name": "Frozen Non GMO ruby romaine lettuce", "code": "50604609" },
      { "name": "Frozen Non GMO baby red romaine lettuce", "code": "50604610" },
      { "name": "Frozen Non GMO butterhead lettuces", "code": "50604611" },
      { "name": "Frozen Non GMO chinese lettuces", "code": "50604612" },
      { "name": "Frozen Non GMO crisphead lettuces", "code": "50604613" },
      { "name": "Frozen Non GMO green leaf lettuce", "code": "50604614" },
      { "name": "Frozen Non GMO iceberg lettuce", "code": "50604615" },
      { "name": "Frozen Non GMO lamb\u2019s lettuces", "code": "50604616" },
      { "name": "Frozen Non GMO looseleaf lettuces", "code": "50604617" },
      { "name": "Frozen Non GMO mache lettuce", "code": "50604618" },
      { "name": "Frozen Non GMO red boston lettuce", "code": "50604619" },
      { "name": "Frozen Non GMO red headed lettuces", "code": "50604620" },
      { "name": "Frozen Non GMO romaine lettuces", "code": "50604621" },
      {
        "name": "Frozen Non GMO russian red mustard lettuce",
        "code": "50604622"
      },
      { "name": "Frozen Non GMO tatsoi lettuce", "code": "50604623" },
      { "name": "Frozen Non GMO malanga", "code": "50604700" },
      { "name": "Frozen Non GMO amarilla malanga", "code": "50604701" },
      { "name": "Frozen Non GMO blanca malanga", "code": "50604702" },
      { "name": "Frozen Non GMO coco malanga", "code": "50604703" },
      { "name": "Frozen Non GMO eddoes malanga", "code": "50604704" },
      { "name": "Frozen Non GMO islena malanga", "code": "50604705" },
      { "name": "Frozen Non GMO lila malanga", "code": "50604706" },
      { "name": "Frozen Non GMO mushrooms", "code": "50604800" },
      { "name": "Frozen Non GMO black trumpet mushrooms", "code": "50604801" },
      { "name": "Frozen Non GMO brown mushrooms", "code": "50604802" },
      { "name": "Frozen Non GMO champinion mushrooms", "code": "50604803" },
      { "name": "Frozen Non GMO chanterelle mushrooms", "code": "50604804" },
      { "name": "Frozen Non GMO cremini mushrooms", "code": "50604805" },
      { "name": "Frozen Non GMO enoki mushrooms", "code": "50604806" },
      { "name": "Frozen Non GMO hedge hog mushrooms", "code": "50604807" },
      {
        "name": "Frozen Non GMO hen of the woods mushrooms",
        "code": "50604808"
      },
      { "name": "Frozen Non GMO lobster mushrooms", "code": "50604809" },
      { "name": "Frozen Non GMO morels mushrooms", "code": "50604810" },
      { "name": "Frozen Non GMO oyster mushrooms", "code": "50604811" },
      { "name": "Frozen Non GMO pleurotus mushrooms", "code": "50604812" },
      { "name": "Frozen Non GMO pompom mushrooms", "code": "50604813" },
      { "name": "Frozen Non GMO porcieni mushrooms", "code": "50604814" },
      { "name": "Frozen Non GMO portobella mushrooms", "code": "50604815" },
      { "name": "Frozen Non GMO shiitake mushrooms", "code": "50604816" },
      { "name": "Frozen Non GMO shimeji mushrooms", "code": "50604817" },
      { "name": "Frozen Non GMO st george's mushrooms", "code": "50604818" },
      { "name": "Frozen Non GMO white mushrooms", "code": "50604819" },
      { "name": "Frozen Non GMO white trumpet mushrooms", "code": "50604820" },
      { "name": "Frozen Non GMO woodear mushrooms", "code": "50604821" },
      { "name": "Frozen Non GMO mustards", "code": "50604900" },
      { "name": "Frozen Non GMO bamboo mustard", "code": "50604901" },
      { "name": "Frozen Non GMO garlic mustard", "code": "50604902" },
      { "name": "Frozen Non GMO giantleafed mustard", "code": "50604903" },
      { "name": "Frozen Non GMO red in snow mustard", "code": "50604904" },
      { "name": "Frozen Non GMO southern mustard", "code": "50604905" },
      { "name": "Frozen Non GMO wrapped heart mustard", "code": "50604906" },
      { "name": "Frozen Non GMO nightshades", "code": "50605000" },
      { "name": "Frozen Non GMO chinese lantern", "code": "50605001" },
      { "name": "Frozen Non GMO garden huckleberry", "code": "50605002" },
      { "name": "Frozen Non GMO naranjilla", "code": "50605003" },
      { "name": "Frozen Non GMO tomatillo", "code": "50605004" },
      { "name": "Frozen Non GMO okras", "code": "50605100" },
      { "name": "Frozen Non GMO artist okra", "code": "50605101" },
      { "name": "Frozen Non GMO burgundy okra", "code": "50605102" },
      { "name": "Frozen Non GMO clemson spineless okra", "code": "50605103" },
      {
        "name": "Frozen Non GMO dwarf green long pod okra",
        "code": "50605104"
      },
      {
        "name": "Frozen Non GMO mammoth spineless long pod okra",
        "code": "50605105"
      },
      { "name": "Frozen Non GMO red velvet okra", "code": "50605106" },
      {
        "name": "Frozen Non GMO star of david heirloom okra",
        "code": "50605107"
      },
      { "name": "Frozen Non GMO onions", "code": "50605200" },
      { "name": "Frozen Non GMO albion onions", "code": "50605201" },
      { "name": "Frozen Non GMO alisa craig onions", "code": "50605202" },
      { "name": "Frozen Non GMO boiling onions", "code": "50605203" },
      { "name": "Frozen Non GMO buffalo onions", "code": "50605204" },
      { "name": "Frozen Non GMO bulb onions", "code": "50605205" },
      { "name": "Frozen Non GMO creaming onions", "code": "50605206" },
      {
        "name": "Frozen Non GMO express yellow o-x onions",
        "code": "50605207"
      },
      { "name": "Frozen Non GMO kelsae onions", "code": "50605208" },
      {
        "name": "Frozen Non GMO marshalls giant fen globe onions",
        "code": "50605209"
      },
      { "name": "Frozen Non GMO pearl onions", "code": "50605210" },
      { "name": "Frozen Non GMO red baron onions", "code": "50605211" },
      { "name": "Frozen Non GMO red onions", "code": "50605212" },
      { "name": "Frozen Non GMO rijnsberger onions", "code": "50605213" },
      {
        "name": "Frozen Non GMO senshyu semi-globe yellow onions",
        "code": "50605214"
      },
      { "name": "Frozen Non GMO sturon onions", "code": "50605215" },
      { "name": "Frozen Non GMO stuttgarter giant onions", "code": "50605216" },
      { "name": "Frozen Non GMO sweet onions", "code": "50605217" },
      {
        "name": "Frozen Non GMO torpedo or red italian onions",
        "code": "50605218"
      },
      { "name": "Frozen Non GMO red storage onions", "code": "50605219" },
      { "name": "Frozen Non GMO white storage onions", "code": "50605220" },
      { "name": "Frozen Non GMO yellow storage onions", "code": "50605221" },
      { "name": "Frozen Non GMO peanuts", "code": "50605300" },
      {
        "name": "Frozen Non GMO bambarra groundnut peanuts",
        "code": "50605301"
      },
      { "name": "Frozen Non GMO florunner peanuts", "code": "50605302" },
      {
        "name": "Frozen Non GMO hausa/kersting's ground nut peanuts",
        "code": "50605303"
      },
      { "name": "Frozen Non GMO spanish peanuts", "code": "50605304" },
      { "name": "Frozen Non GMO valencia peanuts", "code": "50605305" },
      { "name": "Frozen Non GMO virginia peanuts", "code": "50605306" },
      { "name": "Frozen Non GMO peas", "code": "50605400" },
      { "name": "Frozen Non GMO purple hull peas", "code": "50605401" },
      { "name": "Frozen Non GMO pinkeye peas", "code": "50605402" },
      { "name": "Frozen Non GMO crowder peas", "code": "50605403" },
      { "name": "Frozen Non GMO white acre peas", "code": "50605404" },
      { "name": "Frozen Non GMO blackeyed peas", "code": "50605405" },
      { "name": "Frozen Non GMO zipper cream peas", "code": "50605406" },
      { "name": "Frozen Non GMO peppers", "code": "50605500" },
      { "name": "Frozen Non GMO ajies peppers", "code": "50605501" },
      { "name": "Frozen Non GMO arbol peppers", "code": "50605502" },
      { "name": "Frozen Non GMO cheese peppers", "code": "50605503" },
      { "name": "Frozen Non GMO chilaca peppers", "code": "50605504" },
      { "name": "Frozen Non GMO cubanelles peppers", "code": "50605505" },
      { "name": "Frozen Non GMO fresno peppers", "code": "50605506" },
      { "name": "Frozen Non GMO kapia peppers", "code": "50605507" },
      { "name": "Frozen Non GMO korean peppers", "code": "50605508" },
      { "name": "Frozen Non GMO manzano peppers", "code": "50605509" },
      { "name": "Frozen Non GMO melrose peppers", "code": "50605510" },
      { "name": "Frozen Non GMO yellow chile peppers", "code": "50605511" },
      { "name": "Frozen Non GMO aji dulces peppers", "code": "50605512" },
      { "name": "Frozen Non GMO anaheim peppers", "code": "50605513" },
      { "name": "Frozen Non GMO ancho peppers", "code": "50605514" },
      { "name": "Frozen Non GMO bell peppers", "code": "50605515" },
      { "name": "Frozen Non GMO cascabel peppers", "code": "50605516" },
      { "name": "Frozen Non GMO cayenne peppers", "code": "50605517" },
      { "name": "Frozen Non GMO cherry hots peppers", "code": "50605518" },
      { "name": "Frozen Non GMO chiltecpin peppers", "code": "50605519" },
      { "name": "Frozen Non GMO finger hot peppers", "code": "50605520" },
      { "name": "Frozen Non GMO guajillo peppers", "code": "50605521" },
      { "name": "Frozen Non GMO guerro peppers", "code": "50605522" },
      { "name": "Frozen Non GMO habanero peppers", "code": "50605523" },
      { "name": "Frozen Non GMO hungarian wax peppers", "code": "50605524" },
      { "name": "Frozen Non GMO jalapeno peppers", "code": "50605525" },
      { "name": "Frozen Non GMO long hot peppers", "code": "50605526" },
      { "name": "Frozen Non GMO mirasol peppers", "code": "50605527" },
      { "name": "Frozen Non GMO pasilla peppers", "code": "50605528" },
      { "name": "Frozen Non GMO peperoncini peppers", "code": "50605529" },
      { "name": "Frozen Non GMO pequin peppers", "code": "50605530" },
      { "name": "Frozen Non GMO pimiento peppers", "code": "50605531" },
      { "name": "Frozen Non GMO poblano peppers", "code": "50605532" },
      { "name": "Frozen Non GMO scotch bonnet peppers", "code": "50605533" },
      { "name": "Frozen Non GMO serrano peppers", "code": "50605534" },
      { "name": "Frozen Non GMO tabasco peppers", "code": "50605535" },
      { "name": "Frozen Non GMO tai peppers", "code": "50605536" },
      { "name": "Frozen Non GMO tepin peppers", "code": "50605537" },
      { "name": "Frozen Non GMO potatoes", "code": "50605600" },
      { "name": "Frozen Non GMO long white potatoes", "code": "50605601" },
      { "name": "Frozen Non GMO round white potatoes", "code": "50605602" },
      { "name": "Frozen Non GMO round red potatoes", "code": "50605603" },
      { "name": "Frozen Non GMO russet potatoes", "code": "50605604" },
      { "name": "Frozen Non GMO purple potatoes", "code": "50605605" },
      { "name": "Frozen Non GMO yellow potatoes", "code": "50605606" },
      { "name": "Frozen Non GMO new potatoes", "code": "50605607" },
      { "name": "Frozen Non GMO specialty potatoes", "code": "50605608" },
      { "name": "Frozen Non GMO rutabagas", "code": "50605700" },
      { "name": "Frozen Non GMO acme rutabagas", "code": "50605701" },
      { "name": "Frozen Non GMO angela rutabagas", "code": "50605702" },
      { "name": "Frozen Non GMO best of all rutabagas", "code": "50605703" },
      { "name": "Frozen Non GMO marian rutabagas", "code": "50605704" },
      { "name": "Frozen Non GMO sea vegetables", "code": "50605800" },
      { "name": "Frozen Non GMO agar-agar", "code": "50605801" },
      { "name": "Frozen Non GMO arame", "code": "50605802" },
      { "name": "Frozen Non GMO dulse", "code": "50605803" },
      { "name": "Frozen Non GMO haricot vert de mer", "code": "50605804" },
      { "name": "Frozen Non GMO hijiki", "code": "50605805" },
      { "name": "Frozen Non GMO irish moss", "code": "50605806" },
      { "name": "Frozen Non GMO kelp", "code": "50605807" },
      { "name": "Frozen Non GMO laver", "code": "50605808" },
      { "name": "Frozen Non GMO nori", "code": "50605809" },
      { "name": "Frozen Non GMO red algae", "code": "50605810" },
      { "name": "Frozen Non GMO sea kale", "code": "50605811" },
      { "name": "Frozen Non GMO sea lettuce", "code": "50605812" },
      { "name": "Frozen Non GMO seaweeds", "code": "50605813" },
      { "name": "Frozen Non GMO spirulina", "code": "50605814" },
      { "name": "Frozen Non GMO susabi nori", "code": "50605815" },
      { "name": "Frozen Non GMO wakame", "code": "50605816" },
      { "name": "Frozen Non GMO shallots", "code": "50605900" },
      { "name": "Frozen Non GMO atlantic shallots", "code": "50605901" },
      { "name": "Frozen Non GMO creation shallots", "code": "50605902" },
      {
        "name": "Frozen Non GMO drittler white nest shallots",
        "code": "50605903"
      },
      {
        "name": "Frozen Non GMO giant yellow improved shallots",
        "code": "50605904"
      },
      { "name": "Frozen Non GMO golden gourmet shallots", "code": "50605905" },
      {
        "name": "Frozen Non GMO grise de bagnolet shallots",
        "code": "50605906"
      },
      { "name": "Frozen Non GMO hative de niort shallots", "code": "50605907" },
      { "name": "Frozen Non GMO pikant shallots", "code": "50605908" },
      { "name": "Frozen Non GMO red potato onions", "code": "50605909" },
      { "name": "Frozen Non GMO sante shallots", "code": "50605910" },
      { "name": "Frozen Non GMO topper shallots", "code": "50605911" },
      { "name": "Frozen Non GMO sorrels", "code": "50606000" },
      { "name": "Frozen Non GMO dock sorrel", "code": "50606001" },
      { "name": "Frozen Non GMO garden sorrel", "code": "50606002" },
      { "name": "Frozen Non GMO sheep sorrel", "code": "50606003" },
      { "name": "Frozen Non GMO wood sorrel", "code": "50606004" },
      { "name": "Frozen Non GMO spinaches", "code": "50606100" },
      { "name": "Frozen Non GMO america spinach", "code": "50606101" },
      { "name": "Frozen Non GMO bloomsdale spinach", "code": "50606102" },
      { "name": "Frozen Non GMO giant winter spinach", "code": "50606103" },
      { "name": "Frozen Non GMO horenso spinach", "code": "50606104" },
      { "name": "Frozen Non GMO iceplant spinach", "code": "50606105" },
      { "name": "Frozen Non GMO lamb's quarters spinach", "code": "50606106" },
      { "name": "Frozen Non GMO malabar spinach", "code": "50606107" },
      { "name": "Frozen Non GMO medania spinach", "code": "50606108" },
      { "name": "Frozen Non GMO new zealand spinach", "code": "50606109" },
      { "name": "Frozen Non GMO orach spinach", "code": "50606110" },
      { "name": "Frozen Non GMO savoy spinach", "code": "50606111" },
      { "name": "Frozen Non GMO sigmaleaf spinach", "code": "50606112" },
      { "name": "Frozen Non GMO space spinach", "code": "50606113" },
      { "name": "Frozen Non GMO trinidad spinach", "code": "50606114" },
      { "name": "Frozen Non GMO water spinach", "code": "50606115" },
      { "name": "Frozen Non GMO wild spinach", "code": "50606116" },
      {
        "name": "Frozen Non GMO summer squashes and summer pumpkins",
        "code": "50606200"
      },
      { "name": "Frozen Non GMO boston marrow squash", "code": "50606201" },
      { "name": "Frozen Non GMO butternut squash", "code": "50606202" },
      { "name": "Frozen Non GMO costata romanesca squash", "code": "50606203" },
      { "name": "Frozen Non GMO crookneck squash", "code": "50606204" },
      { "name": "Frozen Non GMO cucuzza squash", "code": "50606205" },
      { "name": "Frozen Non GMO delicata squash", "code": "50606206" },
      { "name": "Frozen Non GMO delicious squash", "code": "50606207" },
      {
        "name": "Frozen Non GMO early golden summer crookneck squash",
        "code": "50606208"
      },
      {
        "name": "Frozen Non GMO early prolific straight neck squash",
        "code": "50606209"
      },
      { "name": "Frozen Non GMO gold squash", "code": "50606210" },
      { "name": "Frozen Non GMO jack be little squash", "code": "50606211" },
      { "name": "Frozen Non GMO kentucky field squash", "code": "50606212" },
      { "name": "Frozen Non GMO marrow squash", "code": "50606213" },
      { "name": "Frozen Non GMO middle eastern squash", "code": "50606214" },
      { "name": "Frozen Non GMO miniature squash", "code": "50606215" },
      { "name": "Frozen Non GMO orangetti squash", "code": "50606216" },
      { "name": "Frozen Non GMO pattypan squash", "code": "50606217" },
      { "name": "Frozen Non GMO rondini squash", "code": "50606218" },
      { "name": "Frozen Non GMO round squash", "code": "50606219" },
      { "name": "Frozen Non GMO spaghetti squash", "code": "50606220" },
      { "name": "Frozen Non GMO stripetti squash", "code": "50606221" },
      { "name": "Frozen Non GMO sugar loaf squash", "code": "50606222" },
      { "name": "Frozen Non GMO sweet dumpling squash", "code": "50606223" },
      { "name": "Frozen Non GMO triple treat squash", "code": "50606224" },
      { "name": "Frozen Non GMO waltham butternut squash", "code": "50606225" },
      {
        "name": "Frozen Non GMO yellow bush scallop squash",
        "code": "50606226"
      },
      {
        "name": "Frozen Non GMO yellow straightneck squash",
        "code": "50606227"
      },
      { "name": "Frozen Non GMO zephyr squash", "code": "50606228" },
      { "name": "Frozen Non GMO zucchini squash", "code": "50606229" },
      { "name": "Frozen Non GMO sweet potatoes", "code": "50606300" },
      {
        "name": "Frozen Non GMO beauregard sweet potatoes",
        "code": "50606301"
      },
      {
        "name": "Frozen Non GMO centennial sweet potatoes",
        "code": "50606302"
      },
      { "name": "Frozen Non GMO diane sweet potatoes", "code": "50606303" },
      { "name": "Frozen Non GMO garnet sweet potatoes", "code": "50606304" },
      {
        "name": "Frozen Non GMO georgia red sweet potatoes",
        "code": "50606305"
      },
      {
        "name": "Frozen Non GMO goldensweet sweet potatoes",
        "code": "50606306"
      },
      { "name": "Frozen Non GMO hanna sweet potatoes", "code": "50606307" },
      { "name": "Frozen Non GMO japanese sweet potatoes", "code": "50606308" },
      { "name": "Frozen Non GMO jersey sweet potatoes", "code": "50606309" },
      { "name": "Frozen Non GMO jewel sweet potatoes", "code": "50606310" },
      {
        "name": "Frozen Non GMO maryland red sweet potatoes",
        "code": "50606311"
      },
      { "name": "Frozen Non GMO nemagold sweet potatoes", "code": "50606312" },
      { "name": "Frozen Non GMO o'henry sweet potatoes", "code": "50606313" },
      { "name": "Frozen Non GMO okinawan sweet potatoes", "code": "50606314" },
      { "name": "Frozen Non GMO orange sweet potatoes", "code": "50606315" },
      { "name": "Frozen Non GMO oriental sweet potatoes", "code": "50606316" },
      {
        "name": "Frozen Non GMO red jersey sweet potatoes",
        "code": "50606317"
      },
      { "name": "Frozen Non GMO red mar sweet potatoes", "code": "50606318" },
      { "name": "Frozen Non GMO redglow sweet potatoes", "code": "50606319" },
      {
        "name": "Frozen Non GMO yellow jersey sweet potatoes",
        "code": "50606320"
      },
      { "name": "Frozen Non GMO tomatoes", "code": "50606400" },
      { "name": "Frozen Non GMO ailsa craig tomatoes", "code": "50606401" },
      { "name": "Frozen Non GMO alicante tomatoes", "code": "50606402" },
      { "name": "Frozen Non GMO black plum tomatoes", "code": "50606403" },
      { "name": "Frozen Non GMO brandywine tomatoes", "code": "50606404" },
      { "name": "Frozen Non GMO cherry belle tomatoes", "code": "50606405" },
      { "name": "Frozen Non GMO cherry tomatoes", "code": "50606406" },
      { "name": "Frozen Non GMO delicious tomatoes", "code": "50606407" },
      { "name": "Frozen Non GMO dombito tomatoes", "code": "50606408" },
      {
        "name": "Frozen Non GMO gardener's delight tomatoes",
        "code": "50606409"
      },
      { "name": "Frozen Non GMO grape tomatoes", "code": "50606410" },
      { "name": "Frozen Non GMO green tomatoes", "code": "50606411" },
      { "name": "Frozen Non GMO marmande super tomatoes", "code": "50606412" },
      {
        "name": "Frozen Non GMO marvel striped traditional tomatoes",
        "code": "50606413"
      },
      { "name": "Frozen Non GMO minibel tomatoes", "code": "50606414" },
      { "name": "Frozen Non GMO oaxacan pink tomatoes", "code": "50606415" },
      { "name": "Frozen Non GMO red alert tomatoes", "code": "50606416" },
      { "name": "Frozen Non GMO roma vf tomatoes", "code": "50606417" },
      { "name": "Frozen Non GMO san marzano tomatoes", "code": "50606418" },
      { "name": "Frozen Non GMO shirley tomatoes", "code": "50606419" },
      { "name": "Frozen Non GMO siberia tomato tomatoes", "code": "50606420" },
      { "name": "Frozen Non GMO super beefsteak tomatoes", "code": "50606421" },
      { "name": "Frozen Non GMO tigerella tomatoes", "code": "50606422" },
      { "name": "Frozen Non GMO tiny tim tomatoes", "code": "50606423" },
      { "name": "Frozen Non GMO tumbler tomatoes", "code": "50606424" },
      { "name": "Frozen Non GMO yellow cocktail tomatoes", "code": "50606425" },
      {
        "name": "Frozen Non GMO yellow pear-shaped tomatoes",
        "code": "50606426"
      },
      {
        "name": "Frozen Non GMO yellow perfection tomatoes",
        "code": "50606427"
      },
      { "name": "Frozen Non GMO turnip greens", "code": "50606500" },
      { "name": "Frozen Non GMO green globe turnips", "code": "50606501" },
      { "name": "Frozen Non GMO golden ball turnips", "code": "50606502" },
      {
        "name": "Frozen Non GMO manchester market turnips",
        "code": "50606503"
      },
      { "name": "Frozen Non GMO purple top milan turnips", "code": "50606504" },
      { "name": "Frozen Non GMO purple top white turnips", "code": "50606505" },
      { "name": "Frozen Non GMO snowball turnips", "code": "50606506" },
      { "name": "Frozen Non GMO tokyo turnip", "code": "50606507" },
      { "name": "Frozen Non GMO tokyo cross turnips", "code": "50606508" },
      {
        "name": "Frozen Non GMO winter squashes and winter pumpkins",
        "code": "50606600"
      },
      { "name": "Frozen Non GMO acorn squash", "code": "50606601" },
      { "name": "Frozen Non GMO atlantic giant squash", "code": "50606602" },
      { "name": "Frozen Non GMO banana pink squash", "code": "50606603" },
      { "name": "Frozen Non GMO big max squash", "code": "50606604" },
      { "name": "Frozen Non GMO calabaza squash", "code": "50606605" },
      { "name": "Frozen Non GMO carnival squash", "code": "50606606" },
      { "name": "Frozen Non GMO cheese pumpkin", "code": "50606607" },
      { "name": "Frozen Non GMO crown prince squash", "code": "50606608" },
      { "name": "Frozen Non GMO curcibita squash", "code": "50606609" },
      { "name": "Frozen Non GMO cushaw squash", "code": "50606610" },
      { "name": "Frozen Non GMO giant pumpkin squash", "code": "50606611" },
      { "name": "Frozen Non GMO hubbard squash", "code": "50606612" },
      { "name": "Frozen Non GMO jarrahdale squash", "code": "50606613" },
      { "name": "Frozen Non GMO kabocha squash", "code": "50606614" },
      { "name": "Frozen Non GMO queensland blue squash", "code": "50606615" },
      {
        "name": "Frozen Non GMO rouge vif d'etampes squash",
        "code": "50606616"
      },
      { "name": "Frozen Non GMO turks turban squash", "code": "50606617" },
      { "name": "Frozen Non GMO valenciano squash", "code": "50606618" },
      { "name": "Frozen Non GMO warted hubbard squash", "code": "50606619" },
      {
        "name": "Frozen Non GMO whangaparoa crown pumpkin",
        "code": "50606620"
      },
      { "name": "Frozen Non GMO yams", "code": "50606700" },
      { "name": "Frozen Non GMO african bitter yams", "code": "50606701" },
      { "name": "Frozen Non GMO asiatic bitter yams", "code": "50606702" },
      { "name": "Frozen Non GMO chinese yams", "code": "50606703" },
      { "name": "Frozen Non GMO globe yams", "code": "50606704" },
      { "name": "Frozen Non GMO greater yams", "code": "50606705" },
      { "name": "Frozen Non GMO japanese yams", "code": "50606706" },
      { "name": "Frozen Non GMO lesser yams", "code": "50606707" },
      { "name": "Frozen Non GMO potato yams", "code": "50606708" },
      { "name": "Frozen Non GMO white guinea yams", "code": "50606709" },
      { "name": "Frozen Non GMO yellow guinea yams", "code": "50606710" },
      { "name": "Frozen Non GMO nominant vegetables", "code": "50606800" },
      { "name": "Frozen Non GMO alfalfa", "code": "50606801" },
      { "name": "Frozen Non GMO aloe leaves", "code": "50606802" },
      { "name": "Frozen Non GMO apio", "code": "50606803" },
      { "name": "Frozen Non GMO arrow root", "code": "50606804" },
      { "name": "Frozen Non GMO arrowhead", "code": "50606805" },
      { "name": "Frozen Non GMO arugula", "code": "50606806" },
      { "name": "Frozen Non GMO arum", "code": "50606807" },
      { "name": "Frozen Non GMO bamboo shoots", "code": "50606808" },
      { "name": "Frozen Non GMO banana leaves", "code": "50606809" },
      { "name": "Frozen Non GMO batatas", "code": "50606810" },
      { "name": "Frozen Non GMO bean sprouts", "code": "50606811" },
      { "name": "Frozen Non GMO beet tops", "code": "50606812" },
      { "name": "Frozen Non GMO bittermelon", "code": "50606813" },
      { "name": "Frozen Non GMO caperberries", "code": "50606814" },
      { "name": "Frozen Non GMO carob", "code": "50606815" },
      { "name": "Frozen Non GMO cha-om", "code": "50606816" },
      { "name": "Frozen Non GMO chaoyotes", "code": "50606817" },
      { "name": "Frozen Non GMO chickpeas", "code": "50606818" },
      { "name": "Frozen Non GMO chrysanthemum greens", "code": "50606819" },
      { "name": "Frozen Non GMO dandelion greens", "code": "50606820" },
      { "name": "Frozen Non GMO dandelions", "code": "50606821" },
      { "name": "Frozen Non GMO dasheen", "code": "50606822" },
      { "name": "Frozen Non GMO dau mue or pea tips", "code": "50606823" },
      { "name": "Frozen Non GMO diakon", "code": "50606824" },
      { "name": "Frozen Non GMO donqua", "code": "50606825" },
      { "name": "Frozen Non GMO fiddlehead ferns", "code": "50606826" },
      { "name": "Frozen Non GMO gai choy", "code": "50606827" },
      { "name": "Frozen Non GMO gailon", "code": "50606828" },
      { "name": "Frozen Non GMO galanga", "code": "50606829" },
      { "name": "Frozen Non GMO ginger root", "code": "50606830" },
      { "name": "Frozen Non GMO gobo", "code": "50606831" },
      { "name": "Frozen Non GMO hop sprouts", "code": "50606832" },
      { "name": "Frozen Non GMO horseradish", "code": "50606833" },
      { "name": "Frozen Non GMO jicama", "code": "50606834" },
      { "name": "Frozen Non GMO kudzu", "code": "50606835" },
      { "name": "Frozen Non GMO lily bulb", "code": "50606836" },
      { "name": "Frozen Non GMO linkok", "code": "50606837" },
      { "name": "Frozen Non GMO lo bok", "code": "50606838" },
      { "name": "Frozen Non GMO long beans", "code": "50606839" },
      { "name": "Frozen Non GMO lotus root", "code": "50606840" },
      { "name": "Frozen Non GMO maguey leaves", "code": "50606841" },
      { "name": "Frozen Non GMO mallows", "code": "50606842" },
      { "name": "Frozen Non GMO mamey sapote", "code": "50606843" },
      { "name": "Frozen Non GMO moap", "code": "50606844" },
      { "name": "Frozen Non GMO moo", "code": "50606845" },
      { "name": "Frozen Non GMO moqua", "code": "50606846" },
      { "name": "Frozen Non GMO opos", "code": "50606847" },
      { "name": "Frozen Non GMO palm hearts", "code": "50606848" },
      { "name": "Frozen Non GMO paprika", "code": "50606849" },
      { "name": "Frozen Non GMO purslane", "code": "50606850" },
      { "name": "Frozen Non GMO raddichios", "code": "50606851" },
      { "name": "Frozen Non GMO sinquas", "code": "50606852" },
      { "name": "Frozen Non GMO soybeans", "code": "50606853" },
      { "name": "Frozen Non GMO spoonwart", "code": "50606854" },
      { "name": "Frozen Non GMO tassle grape-hyacinth", "code": "50606855" },
      { "name": "Frozen Non GMO taro", "code": "50606856" },
      { "name": "Frozen Non GMO taro leaf", "code": "50606857" },
      { "name": "Frozen Non GMO taro shoot", "code": "50606858" },
      { "name": "Frozen Non GMO tepeguaje", "code": "50606859" },
      { "name": "Frozen Non GMO tendergreen", "code": "50606860" },
      { "name": "Frozen Non GMO tindora", "code": "50606861" },
      { "name": "Frozen Non GMO tree onion", "code": "50606862" },
      { "name": "Frozen Non GMO udo", "code": "50606863" },
      { "name": "Frozen Non GMO water chestnuts", "code": "50606864" },
      { "name": "Frozen Non GMO yampi", "code": "50606865" },
      { "name": "Frozen Non GMO yautia", "code": "50606866" },
      { "name": "Frozen Non GMO yu choy", "code": "50606867" },
      { "name": "Frozen Non GMO yuca", "code": "50606868" },
      { "name": "Frozen Non GMO sugar peas", "code": "50606900" },
      { "name": "Frozen Non GMO bikini peas", "code": "50606901" },
      { "name": "Frozen Non GMO cavalier peas", "code": "50606902" },
      { "name": "Frozen Non GMO daisy peas", "code": "50606903" },
      { "name": "Frozen Non GMO darfon peas", "code": "50606904" },
      { "name": "Frozen Non GMO early onward peas", "code": "50606905" },
      { "name": "Frozen Non GMO feltham first peas", "code": "50606906" },
      { "name": "Frozen Non GMO hurst green shaft peas", "code": "50606907" },
      { "name": "Frozen Non GMO oregon sugar pod peas", "code": "50606908" },
      { "name": "Frozen Non GMO prince albert peas", "code": "50606909" },
      { "name": "Frozen Non GMO reuzensuiker peas", "code": "50606910" },
      { "name": "Canned or jarred Non GMO vegetables", "code": "50610000" },
      { "name": "Canned or jarred Non GMO artichokes", "code": "50611500" },
      {
        "name": "Canned or jarred Non GMO brittany artichokes",
        "code": "50611501"
      },
      {
        "name": "Canned or jarred Non GMO catanese artichokes",
        "code": "50611502"
      },
      {
        "name": "Canned or jarred Non GMO french artichokes",
        "code": "50611503"
      },
      {
        "name": "Canned or jarred Non GMO green globe artichokes",
        "code": "50611504"
      },
      {
        "name": "Canned or jarred Non GMO gros camus de bretagne artichokes",
        "code": "50611505"
      },
      {
        "name": "Canned or jarred Non GMO midi artichokes",
        "code": "50611506"
      },
      {
        "name": "Canned or jarred Non GMO purple globe artichokes",
        "code": "50611507"
      },
      {
        "name": "Canned or jarred Non GMO purple sicilian artichokes",
        "code": "50611508"
      },
      {
        "name": "Canned or jarred Non GMO romanesco artichokes",
        "code": "50611509"
      },
      {
        "name": "Canned or jarred Non GMO spinoso sardo artichokes",
        "code": "50611510"
      },
      {
        "name": "Canned or jarred Non GMO vert de laon artichokes",
        "code": "50611511"
      },
      {
        "name": "Canned or jarred Non GMO violetta di chioggia artichokes",
        "code": "50611512"
      },
      {
        "name": "Canned or jarred Non GMO violetto di toscana artichokes",
        "code": "50611513"
      },
      { "name": "Canned or jarred Non GMO asparagus", "code": "50611600" },
      {
        "name": "Canned or jarred Non GMO connover's colossal asparagus",
        "code": "50611601"
      },
      {
        "name": "Canned or jarred Non GMO franklin asparagus",
        "code": "50611602"
      },
      {
        "name": "Canned or jarred Non GMO giant mammoth asparagus",
        "code": "50611603"
      },
      {
        "name": "Canned or jarred Non GMO lucullus asparagus",
        "code": "50611604"
      },
      {
        "name": "Canned or jarred Non GMO martha washington asparagus",
        "code": "50611605"
      },
      { "name": "Canned or jarred Non GMO avocados", "code": "50611700" },
      {
        "name": "Canned or jarred Non GMO ajax b-7 avocados",
        "code": "50611701"
      },
      { "name": "Canned or jarred Non GMO arue avocados", "code": "50611702" },
      { "name": "Canned or jarred Non GMO bacon avocados", "code": "50611703" },
      { "name": "Canned or jarred Non GMO benik avocados", "code": "50611704" },
      {
        "name": "Canned or jarred Non GMO bernecker avocados",
        "code": "50611705"
      },
      { "name": "Canned or jarred Non GMO beta avocados", "code": "50611706" },
      {
        "name": "Canned or jarred Non GMO biondo avocados",
        "code": "50611707"
      },
      {
        "name": "Canned or jarred Non GMO black prince avocados",
        "code": "50611708"
      },
      { "name": "Canned or jarred Non GMO blair avocados", "code": "50611709" },
      {
        "name": "Canned or jarred Non GMO blair booth avocados",
        "code": "50611710"
      },
      {
        "name": "Canned or jarred Non GMO booth 1 avocados",
        "code": "50611711"
      },
      {
        "name": "Canned or jarred Non GMO booth 3 avocados",
        "code": "50611712"
      },
      {
        "name": "Canned or jarred Non GMO booth 5 avocados",
        "code": "50611713"
      },
      {
        "name": "Canned or jarred Non GMO booth 7 avocados",
        "code": "50611714"
      },
      {
        "name": "Canned or jarred Non GMO booth 8 avocados",
        "code": "50611715"
      },
      {
        "name": "Canned or jarred Non GMO brooks 1978 avocados",
        "code": "50611716"
      },
      {
        "name": "Canned or jarred Non GMO brookslate avocados",
        "code": "50611717"
      },
      {
        "name": "Canned or jarred Non GMO california haas avocados",
        "code": "50611718"
      },
      {
        "name": "Canned or jarred Non GMO catalina avocados",
        "code": "50611719"
      },
      { "name": "Canned or jarred Non GMO chica avocados", "code": "50611720" },
      {
        "name": "Canned or jarred Non GMO choquette avocados",
        "code": "50611721"
      },
      {
        "name": "Canned or jarred Non GMO christina avocados",
        "code": "50611722"
      },
      {
        "name": "Canned or jarred Non GMO collinson avocados",
        "code": "50611723"
      },
      {
        "name": "Canned or jarred Non GMO donnie avocados",
        "code": "50611724"
      },
      {
        "name": "Canned or jarred Non GMO dr dupuis number 2 avocados",
        "code": "50611725"
      },
      {
        "name": "Canned or jarred Non GMO dr dupuis avocados",
        "code": "50611726"
      },
      {
        "name": "Canned or jarred Non GMO ettinger avocados",
        "code": "50611727"
      },
      { "name": "Canned or jarred Non GMO fuchs avocados", "code": "50611728" },
      {
        "name": "Canned or jarred Non GMO fuchs gwen avocados",
        "code": "50611729"
      },
      {
        "name": "Canned or jarred Non GMO fuerte avocados",
        "code": "50611730"
      },
      {
        "name": "Canned or jarred Non GMO gorham avocados",
        "code": "50611731"
      },
      {
        "name": "Canned or jarred Non GMO gossman avocados",
        "code": "50611732"
      },
      {
        "name": "Canned or jarred Non GMO guatemalan seedling avocados",
        "code": "50611733"
      },
      { "name": "Canned or jarred Non GMO hall avocados", "code": "50611734" },
      {
        "name": "Canned or jarred Non GMO hardee avocados",
        "code": "50611735"
      },
      { "name": "Canned or jarred Non GMO haas avocados", "code": "50611736" },
      {
        "name": "Canned or jarred Non GMO herman avocados",
        "code": "50611737"
      },
      {
        "name": "Canned or jarred Non GMO hickson avocados",
        "code": "50611738"
      },
      { "name": "Canned or jarred Non GMO k-5 avocados", "code": "50611739" },
      { "name": "Canned or jarred Non GMO k-9 avocados", "code": "50611740" },
      {
        "name": "Canned or jarred Non GMO lamb haas avocados",
        "code": "50611741"
      },
      { "name": "Canned or jarred Non GMO leona avocados", "code": "50611742" },
      {
        "name": "Canned or jarred Non GMO leona linda avocados",
        "code": "50611743"
      },
      {
        "name": "Canned or jarred Non GMO lisa p avocados",
        "code": "50611744"
      },
      {
        "name": "Canned or jarred Non GMO lisa loretta avocados",
        "code": "50611745"
      },
      {
        "name": "Canned or jarred Non GMO loretta avocados",
        "code": "50611746"
      },
      { "name": "Canned or jarred Non GMO lula avocados", "code": "50611747" },
      {
        "name": "Canned or jarred Non GMO lula macarthur avocados",
        "code": "50611748"
      },
      {
        "name": "Canned or jarred Non GMO marcus avocados",
        "code": "50611749"
      },
      {
        "name": "Canned or jarred Non GMO melendez avocados",
        "code": "50611750"
      },
      {
        "name": "Canned or jarred Non GMO meya p avocados",
        "code": "50611751"
      },
      {
        "name": "Canned or jarred Non GMO miguel p avocados",
        "code": "50611752"
      },
      {
        "name": "Canned or jarred Non GMO monroe avocados",
        "code": "50611753"
      },
      {
        "name": "Canned or jarred Non GMO murrieta green avocados",
        "code": "50611754"
      },
      { "name": "Canned or jarred Non GMO nabal avocados", "code": "50611755" },
      { "name": "Canned or jarred Non GMO nadir avocados", "code": "50611756" },
      {
        "name": "Canned or jarred Non GMO nesbitt avocados",
        "code": "50611757"
      },
      {
        "name": "Canned or jarred Non GMO peterson avocados",
        "code": "50611758"
      },
      {
        "name": "Canned or jarred Non GMO pinelli avocados",
        "code": "50611759"
      },
      {
        "name": "Canned or jarred Non GMO pinkerton avocados",
        "code": "50611760"
      },
      {
        "name": "Canned or jarred Non GMO pollock avocados",
        "code": "50611761"
      },
      {
        "name": "Canned or jarred Non GMO puebla avocados",
        "code": "50611762"
      },
      { "name": "Canned or jarred Non GMO reed avocados", "code": "50611763" },
      { "name": "Canned or jarred Non GMO rue avocados", "code": "50611764" },
      {
        "name": "Canned or jarred Non GMO ruehle avocados",
        "code": "50611765"
      },
      { "name": "Canned or jarred Non GMO ryan avocados", "code": "50611766" },
      {
        "name": "Canned or jarred Non GMO semil 34 avocados",
        "code": "50611767"
      },
      {
        "name": "Canned or jarred Non GMO semil 43 avocados",
        "code": "50611768"
      },
      {
        "name": "Canned or jarred Non GMO simmonds avocados",
        "code": "50611769"
      },
      {
        "name": "Canned or jarred Non GMO simpson avocados",
        "code": "50611770"
      },
      {
        "name": "Canned or jarred Non GMO taylor avocados",
        "code": "50611771"
      },
      {
        "name": "Canned or jarred Non GMO tonnage avocados",
        "code": "50611772"
      },
      { "name": "Canned or jarred Non GMO tower avocados", "code": "50611773" },
      {
        "name": "Canned or jarred Non GMO tower li avocados",
        "code": "50611774"
      },
      { "name": "Canned or jarred Non GMO trapp avocados", "code": "50611775" },
      {
        "name": "Canned or jarred Non GMO west indian seedling avocados",
        "code": "50611776"
      },
      {
        "name": "Canned or jarred Non GMO wagner avocados",
        "code": "50611777"
      },
      {
        "name": "Canned or jarred Non GMO waldin avocados",
        "code": "50611778"
      },
      { "name": "Canned or jarred Non GMO wurtz avocados", "code": "50611779" },
      { "name": "Canned or jarred Non GMO zio p avocados", "code": "50611780" },
      { "name": "Canned or jarred Non GMO ziu avocados", "code": "50611781" },
      {
        "name": "Canned or jarred Non GMO zutano avocados",
        "code": "50611782"
      },
      { "name": "Canned or jarred Non GMO beans", "code": "50611800" },
      {
        "name": "Canned or jarred Non GMO anasazi or aztec beans",
        "code": "50611801"
      },
      {
        "name": "Canned or jarred Non GMO appaloosa beans",
        "code": "50611802"
      },
      { "name": "Canned or jarred Non GMO azuki beans", "code": "50611803" },
      { "name": "Canned or jarred Non GMO barlotti beans", "code": "50611804" },
      {
        "name": "Canned or jarred Non GMO black appaloosa beans",
        "code": "50611805"
      },
      { "name": "Canned or jarred Non GMO black beans", "code": "50611806" },
      {
        "name": "Canned or jarred Non GMO black gram beans",
        "code": "50611807"
      },
      {
        "name": "Canned or jarred Non GMO black shackamaxon beans",
        "code": "50611808"
      },
      {
        "name": "Canned or jarred Non GMO blackeyed beans",
        "code": "50611809"
      },
      { "name": "Canned or jarred Non GMO bobby beans", "code": "50611810" },
      { "name": "Canned or jarred Non GMO bolita beans", "code": "50611811" },
      {
        "name": "Canned or jarred Non GMO brown lazy wife beans",
        "code": "50611812"
      },
      { "name": "Canned or jarred Non GMO calypso beans", "code": "50611813" },
      {
        "name": "Canned or jarred Non GMO cannellini beans",
        "code": "50611814"
      },
      { "name": "Canned or jarred Non GMO castor beans", "code": "50611815" },
      {
        "name": "Canned or jarred Non GMO china yellow beans",
        "code": "50611816"
      },
      {
        "name": "Canned or jarred Non GMO dragon tongue beans",
        "code": "50611817"
      },
      {
        "name": "Canned or jarred Non GMO european soldier beans",
        "code": "50611818"
      },
      { "name": "Canned or jarred Non GMO fava beans", "code": "50611819" },
      {
        "name": "Canned or jarred Non GMO flageolet beans",
        "code": "50611820"
      },
      {
        "name": "Canned or jarred Non GMO french horticultural beans",
        "code": "50611821"
      },
      {
        "name": "Canned or jarred Non GMO french navy beans",
        "code": "50611822"
      },
      {
        "name": "Canned or jarred Non GMO giant white coco beans",
        "code": "50611823"
      },
      { "name": "Canned or jarred Non GMO green beans", "code": "50611824" },
      {
        "name": "Canned or jarred Non GMO green romano beans",
        "code": "50611825"
      },
      { "name": "Canned or jarred Non GMO guar gum beans", "code": "50611826" },
      { "name": "Canned or jarred Non GMO haricot beans", "code": "50611827" },
      { "name": "Canned or jarred Non GMO hyacinth beans", "code": "50611828" },
      {
        "name": "Canned or jarred Non GMO italian type beans",
        "code": "50611829"
      },
      {
        "name": "Canned or jarred Non GMO jackson wonder beans",
        "code": "50611830"
      },
      {
        "name": "Canned or jarred Non GMO jacob's cattle beans",
        "code": "50611831"
      },
      {
        "name": "Canned or jarred Non GMO kentucky wonder beans",
        "code": "50611832"
      },
      { "name": "Canned or jarred Non GMO kidney beans", "code": "50611833" },
      { "name": "Canned or jarred Non GMO lima beans", "code": "50611834" },
      {
        "name": "Canned or jarred Non GMO madeira/madera beans",
        "code": "50611835"
      },
      { "name": "Canned or jarred Non GMO marrow beans", "code": "50611836" },
      { "name": "Canned or jarred Non GMO mat beans", "code": "50611837" },
      {
        "name": "Canned or jarred Non GMO monstoller wild goose beans",
        "code": "50611838"
      },
      {
        "name": "Canned or jarred Non GMO mortgage lifter beans",
        "code": "50611839"
      },
      { "name": "Canned or jarred Non GMO moth beans", "code": "50611840" },
      { "name": "Canned or jarred Non GMO mung beans", "code": "50611841" },
      {
        "name": "Canned or jarred Non GMO munsi wolf bean",
        "code": "50611842"
      },
      { "name": "Canned or jarred Non GMO nuna beans", "code": "50611843" },
      { "name": "Canned or jarred Non GMO pinto beans", "code": "50611844" },
      { "name": "Canned or jarred Non GMO pole beans", "code": "50611845" },
      { "name": "Canned or jarred Non GMO runner beans", "code": "50611846" },
      { "name": "Canned or jarred Non GMO string beans", "code": "50611847" },
      { "name": "Canned or jarred Non GMO tamarind beans", "code": "50611848" },
      { "name": "Canned or jarred Non GMO tonka beans", "code": "50611849" },
      { "name": "Canned or jarred Non GMO wax beans", "code": "50611850" },
      { "name": "Canned or jarred Non GMO winged beans", "code": "50611851" },
      {
        "name": "Canned or jarred Non GMO yard long beans",
        "code": "50611852"
      },
      { "name": "Canned or jarred Non GMO beets", "code": "50611900" },
      { "name": "Canned or jarred Non GMO action beets", "code": "50611901" },
      {
        "name": "Canned or jarred Non GMO albina vereduna beets",
        "code": "50611902"
      },
      {
        "name": "Canned or jarred Non GMO barbabietola di chioggia beets",
        "code": "50611903"
      },
      { "name": "Canned or jarred Non GMO boltardy beets", "code": "50611904" },
      { "name": "Canned or jarred Non GMO bonel beets", "code": "50611905" },
      {
        "name": "Canned or jarred Non GMO burpees golden beets",
        "code": "50611906"
      },
      {
        "name": "Canned or jarred Non GMO cheltenham green top beets",
        "code": "50611907"
      },
      {
        "name": "Canned or jarred Non GMO cheltenham mono beets",
        "code": "50611908"
      },
      { "name": "Canned or jarred Non GMO chioggia beets", "code": "50611909" },
      { "name": "Canned or jarred Non GMO cylindra beets", "code": "50611910" },
      { "name": "Canned or jarred Non GMO d'egypte beets", "code": "50611911" },
      {
        "name": "Canned or jarred Non GMO detroit 2 dark red beets",
        "code": "50611912"
      },
      {
        "name": "Canned or jarred Non GMO detroit 2 little ball beets",
        "code": "50611913"
      },
      {
        "name": "Canned or jarred Non GMO egyptian flat beets",
        "code": "50611914"
      },
      {
        "name": "Canned or jarred Non GMO egyptian turnip rooted beets",
        "code": "50611915"
      },
      {
        "name": "Canned or jarred Non GMO formanova beets",
        "code": "50611916"
      },
      { "name": "Canned or jarred Non GMO forono beets", "code": "50611917" },
      { "name": "Canned or jarred Non GMO monaco beets", "code": "50611918" },
      { "name": "Canned or jarred Non GMO monogram beets", "code": "50611919" },
      { "name": "Canned or jarred Non GMO pronto beets", "code": "50611920" },
      { "name": "Canned or jarred Non GMO regalia beets", "code": "50611921" },
      { "name": "Canned or jarred Non GMO sugar beets", "code": "50611922" },
      { "name": "Canned or jarred Non GMO broccoli", "code": "50612000" },
      { "name": "Canned or jarred Non GMO broccolini", "code": "50612001" },
      {
        "name": "Canned or jarred Non GMO broccoli romanesco",
        "code": "50612002"
      },
      { "name": "Canned or jarred Non GMO broccoli raab", "code": "50612003" },
      {
        "name": "Canned or jarred Non GMO chinese broccoli",
        "code": "50612004"
      },
      {
        "name": "Canned or jarred Non GMO brussel sprouts",
        "code": "50612100"
      },
      {
        "name": "Canned or jarred Non GMO citadel brussel sprouts",
        "code": "50612101"
      },
      {
        "name": "Canned or jarred Non GMO falstaff brussel sprouts",
        "code": "50612102"
      },
      {
        "name": "Canned or jarred Non GMO oliver brussel sprouts",
        "code": "50612103"
      },
      {
        "name": "Canned or jarred Non GMO peer gynt brussel sprouts",
        "code": "50612104"
      },
      {
        "name": "Canned or jarred Non GMO rampart brussel sprouts",
        "code": "50612105"
      },
      {
        "name": "Canned or jarred Non GMO rubine brussel sprouts",
        "code": "50612106"
      },
      {
        "name": "Canned or jarred Non GMO widgeon brussel sprouts",
        "code": "50612107"
      },
      {
        "name": "Canned or jarred Non GMO bunching onions",
        "code": "50612200"
      },
      {
        "name": "Canned or jarred Non GMO beltsville bunching onions",
        "code": "50612201"
      },
      {
        "name": "Canned or jarred Non GMO feast bunching onions",
        "code": "50612202"
      },
      {
        "name": "Canned or jarred Non GMO ishikura bunching onions",
        "code": "50612203"
      },
      {
        "name": "Canned or jarred Non GMO kyoto market bunching onions",
        "code": "50612204"
      },
      {
        "name": "Canned or jarred Non GMO red beard bunching onions",
        "code": "50612205"
      },
      {
        "name": "Canned or jarred Non GMO redmate bunching onions",
        "code": "50612206"
      },
      {
        "name": "Canned or jarred Non GMO santa claus bunching onions",
        "code": "50612207"
      },
      {
        "name": "Canned or jarred Non GMO tokyo bunching onions",
        "code": "50612208"
      },
      {
        "name": "Canned or jarred Non GMO white lisbon bunching onions",
        "code": "50612209"
      },
      {
        "name": "Canned or jarred Non GMO winter white bunching onions",
        "code": "50612210"
      },
      {
        "name": "Canned or jarred Non GMO winter-over bunching onions",
        "code": "50612211"
      },
      { "name": "Canned or jarred Non GMO cabbages", "code": "50612300" },
      { "name": "Canned or jarred Non GMO black cabbages", "code": "50612301" },
      { "name": "Canned or jarred Non GMO savoy cabbages", "code": "50612302" },
      { "name": "Canned or jarred Non GMO skunk cabbages", "code": "50612303" },
      { "name": "Canned or jarred Non GMO white cabbages", "code": "50612304" },
      { "name": "Canned or jarred Non GMO cardoons", "code": "50612400" },
      {
        "name": "Canned or jarred Non GMO lunghi cardoons",
        "code": "50612401"
      },
      { "name": "Canned or jarred Non GMO gobbi cardoons", "code": "50612402" },
      { "name": "Canned or jarred Non GMO carrots", "code": "50612500" },
      {
        "name": "Canned or jarred Non GMO amsterdam carrots",
        "code": "50612501"
      },
      {
        "name": "Canned or jarred Non GMO autumn king carrots",
        "code": "50612502"
      },
      {
        "name": "Canned or jarred Non GMO berlicum carrots",
        "code": "50612503"
      },
      {
        "name": "Canned or jarred Non GMO chantenay carrots",
        "code": "50612504"
      },
      { "name": "Canned or jarred Non GMO nantes carrots", "code": "50612505" },
      {
        "name": "Canned or jarred Non GMO paris market carrots",
        "code": "50612506"
      },
      { "name": "Canned or jarred Non GMO cauliflowers", "code": "50612600" },
      {
        "name": "Canned or jarred Non GMO all the year round cauliflowers",
        "code": "50612601"
      },
      {
        "name": "Canned or jarred Non GMO alverda cauliflowers",
        "code": "50612602"
      },
      {
        "name": "Canned or jarred Non GMO autumn giant 3 cauliflowers",
        "code": "50612603"
      },
      {
        "name": "Canned or jarred Non GMO dok elgon cauliflowers",
        "code": "50612604"
      },
      {
        "name": "Canned or jarred Non GMO early snowball cauliflowers",
        "code": "50612605"
      },
      {
        "name": "Canned or jarred Non GMO limelight cauliflowers",
        "code": "50612606"
      },
      {
        "name": "Canned or jarred Non GMO minaret cauliflowers",
        "code": "50612607"
      },
      {
        "name": "Canned or jarred Non GMO orange bouquet cauliflowers",
        "code": "50612608"
      },
      {
        "name": "Canned or jarred Non GMO purple cape cauliflowers",
        "code": "50612609"
      },
      {
        "name": "Canned or jarred Non GMO snowball cauliflowers",
        "code": "50612610"
      },
      {
        "name": "Canned or jarred Non GMO walcheren winter 3 cauliflowers",
        "code": "50612611"
      },
      {
        "name": "Canned or jarred Non GMO white rock cauliflowers",
        "code": "50612612"
      },
      { "name": "Canned or jarred Non GMO celery", "code": "50612700" },
      {
        "name": "Canned or jarred Non GMO celebrity celery",
        "code": "50612701"
      },
      { "name": "Canned or jarred Non GMO celeriac", "code": "50612702" },
      { "name": "Canned or jarred Non GMO chinese celery", "code": "50612703" },
      {
        "name": "Canned or jarred Non GMO french dinant celery",
        "code": "50612704"
      },
      {
        "name": "Canned or jarred Non GMO giant pink celery",
        "code": "50612705"
      },
      {
        "name": "Canned or jarred Non GMO giant red celery",
        "code": "50612706"
      },
      {
        "name": "Canned or jarred Non GMO giant white celery",
        "code": "50612707"
      },
      {
        "name": "Canned or jarred Non GMO golden self-blanching celery",
        "code": "50612708"
      },
      {
        "name": "Canned or jarred Non GMO greensleeves celery",
        "code": "50612709"
      },
      {
        "name": "Canned or jarred Non GMO hopkins fenlander celery",
        "code": "50612710"
      },
      {
        "name": "Canned or jarred Non GMO ivory tower celery",
        "code": "50612711"
      },
      {
        "name": "Canned or jarred Non GMO lathom self-blanching celery",
        "code": "50612712"
      },
      {
        "name": "Canned or jarred Non GMO soup celery d'amsterdam",
        "code": "50612713"
      },
      {
        "name": "Canned or jarred Non GMO standard bearer celery",
        "code": "50612714"
      },
      {
        "name": "Canned or jarred Non GMO tall utah triumph celery",
        "code": "50612715"
      },
      { "name": "Canned or jarred Non GMO chards", "code": "50612800" },
      {
        "name": "Canned or jarred Non GMO bright lights chard",
        "code": "50612801"
      },
      {
        "name": "Canned or jarred Non GMO fordhook giant chard",
        "code": "50612802"
      },
      { "name": "Canned or jarred Non GMO lucullus chard", "code": "50612803" },
      {
        "name": "Canned or jarred Non GMO perpetual spinach chard",
        "code": "50612804"
      },
      { "name": "Canned or jarred Non GMO rhubarb chard", "code": "50612805" },
      { "name": "Canned or jarred Non GMO swiss chard", "code": "50612806" },
      { "name": "Canned or jarred Non GMO vulcan chard", "code": "50612807" },
      {
        "name": "Canned or jarred Non GMO white king chard",
        "code": "50612808"
      },
      { "name": "Canned or jarred Non GMO chicories", "code": "50612900" },
      {
        "name": "Canned or jarred Non GMO broad leaved batavian chicory",
        "code": "50612901"
      },
      {
        "name": "Canned or jarred Non GMO en cornet de bordeaux chicory",
        "code": "50612902"
      },
      {
        "name": "Canned or jarred Non GMO green curled ruffee chicory",
        "code": "50612903"
      },
      {
        "name": "Canned or jarred Non GMO green curled chicory",
        "code": "50612904"
      },
      {
        "name": "Canned or jarred Non GMO ione limnos chicory",
        "code": "50612905"
      },
      {
        "name": "Canned or jarred Non GMO riccia pancalieri chicory",
        "code": "50612906"
      },
      {
        "name": "Canned or jarred Non GMO salad king chicory",
        "code": "50612907"
      },
      { "name": "Canned or jarred Non GMO sanda chicory", "code": "50612908" },
      {
        "name": "Canned or jarred Non GMO scarola verde chicory",
        "code": "50612909"
      },
      {
        "name": "Canned or jarred Non GMO tres fine maraichere chicory",
        "code": "50612910"
      },
      {
        "name": "Canned or jarred Non GMO wallone freisee weschelkopf chicory",
        "code": "50612911"
      },
      {
        "name": "Canned or jarred Non GMO chinese cabbages",
        "code": "50613000"
      },
      { "name": "Canned or jarred Non GMO bok choy", "code": "50613001" },
      {
        "name": "Canned or jarred Non GMO chinese flat-headed cabbage",
        "code": "50613002"
      },
      {
        "name": "Canned or jarred Non GMO chinese flowering cabbage",
        "code": "50613003"
      },
      { "name": "Canned or jarred Non GMO choy sum", "code": "50613004" },
      { "name": "Canned or jarred Non GMO dwarf bok choy", "code": "50613005" },
      {
        "name": "Canned or jarred Non GMO fengshan bok choy",
        "code": "50613006"
      },
      {
        "name": "Canned or jarred Non GMO jade pagoda bok choy",
        "code": "50613007"
      },
      {
        "name": "Canned or jarred Non GMO kasumi bok choy",
        "code": "50613008"
      },
      { "name": "Canned or jarred Non GMO nerva bok choy", "code": "50613009" },
      {
        "name": "Canned or jarred Non GMO rosette bok choy",
        "code": "50613010"
      },
      {
        "name": "Canned or jarred Non GMO ruffles bok choy",
        "code": "50613011"
      },
      {
        "name": "Canned or jarred Non GMO santo serrated leaved cabbage",
        "code": "50613012"
      },
      {
        "name": "Canned or jarred Non GMO shanghai d bok choy",
        "code": "50613013"
      },
      {
        "name": "Canned or jarred Non GMO shantung cabbage",
        "code": "50613014"
      },
      {
        "name": "Canned or jarred Non GMO tip top cabbage",
        "code": "50613015"
      },
      { "name": "Canned or jarred Non GMO yau choy sum", "code": "50613016" },
      { "name": "Canned or jarred Non GMO chives", "code": "50613100" },
      { "name": "Canned or jarred Non GMO chinese chives", "code": "50613101" },
      { "name": "Non GMO Canned or jarred common chives", "code": "50613102" },
      { "name": "Canned or jarred Non GMO corn", "code": "50613200" },
      { "name": "Canned or jarred Non GMO alpine corn", "code": "50613201" },
      { "name": "Canned or jarred Non GMO ambrosia corn", "code": "50613202" },
      { "name": "Canned or jarred Non GMO argent corn", "code": "50613203" },
      { "name": "Canned or jarred Non GMO aspen corn", "code": "50613204" },
      { "name": "Canned or jarred Non GMO avalanche corn", "code": "50613205" },
      { "name": "Canned or jarred Non GMO biqueen corn", "code": "50613206" },
      { "name": "Canned or jarred Non GMO bodacious corn", "code": "50613207" },
      {
        "name": "Canned or jarred Non GMO butter and sugar corn",
        "code": "50613208"
      },
      {
        "name": "Canned or jarred Non GMO calico belle corn",
        "code": "50613209"
      },
      { "name": "Canned or jarred Non GMO camelot corn", "code": "50613210" },
      {
        "name": "Canned or jarred Non GMO challenger crisp \u2018n sweet corn",
        "code": "50613211"
      },
      { "name": "Canned or jarred Non GMO champ corn", "code": "50613212" },
      {
        "name": "Canned or jarred Non GMO cotton candy corn",
        "code": "50613213"
      },
      {
        "name": "Canned or jarred Non GMO d\u2019artagnan corn",
        "code": "50613214"
      },
      { "name": "Canned or jarred Non GMO dazzle corn", "code": "50613215" },
      {
        "name": "Canned or jarred Non GMO diamonds and gold corn",
        "code": "50613216"
      },
      { "name": "Canned or jarred Non GMO divinity corn", "code": "50613217" },
      {
        "name": "Canned or jarred Non GMO double delight corn",
        "code": "50613218"
      },
      {
        "name": "Canned or jarred Non GMO double gem corn",
        "code": "50613219"
      },
      { "name": "Canned or jarred Non GMO earlivee corn", "code": "50613220" },
      {
        "name": "Canned or jarred Non GMO early xtra sweet corn",
        "code": "50613221"
      },
      { "name": "Canned or jarred Non GMO excel corn", "code": "50613222" },
      {
        "name": "Canned or jarred Non GMO golden cross bantam corn",
        "code": "50613223"
      },
      {
        "name": "Canned or jarred Non GMO honey and cream corn",
        "code": "50613224"
      },
      {
        "name": "Canned or jarred Non GMO honey \u2018n pearl corn",
        "code": "50613225"
      },
      {
        "name": "Canned or jarred Non GMO how sweet it is corn",
        "code": "50613226"
      },
      { "name": "Canned or jarred Non GMO hudson corn", "code": "50613227" },
      {
        "name": "Canned or jarred Non GMO illini gold corn",
        "code": "50613228"
      },
      {
        "name": "Canned or jarred Non GMO illini xtra sweet corn",
        "code": "50613229"
      },
      {
        "name": "Canned or jarred Non GMO incredible corn",
        "code": "50613230"
      },
      { "name": "Canned or jarred Non GMO iochief corn", "code": "50613231" },
      { "name": "Canned or jarred Non GMO jubilee corn", "code": "50613232" },
      {
        "name": "Canned or jarred Non GMO jubilee supersweet corn",
        "code": "50613233"
      },
      {
        "name": "Canned or jarred Non GMO kandy korn corn",
        "code": "50613234"
      },
      {
        "name": "Canned or jarred Non GMO kiss \u2018n tell corn",
        "code": "50613235"
      },
      { "name": "Canned or jarred Non GMO lancelot corn", "code": "50613236" },
      {
        "name": "Canned or jarred Non GMO maple sweet corn",
        "code": "50613237"
      },
      { "name": "Canned or jarred Non GMO medley corn", "code": "50613238" },
      { "name": "Canned or jarred Non GMO merlin corn", "code": "50613239" },
      { "name": "Canned or jarred Non GMO miracle corn", "code": "50613240" },
      { "name": "Canned or jarred Non GMO nk-199 corn", "code": "50613241" },
      {
        "name": "Canned or jarred Non GMO peaches and cream corn",
        "code": "50613242"
      },
      {
        "name": "Canned or jarred Non GMO pearl white corn",
        "code": "50613243"
      },
      { "name": "Canned or jarred Non GMO pegasus corn", "code": "50613244" },
      {
        "name": "Canned or jarred Non GMO phenomenal corn",
        "code": "50613245"
      },
      {
        "name": "Canned or jarred Non GMO platinum lady corn",
        "code": "50613246"
      },
      {
        "name": "Canned or jarred Non GMO precocious corn",
        "code": "50613247"
      },
      { "name": "Canned or jarred Non GMO pristine corn", "code": "50613248" },
      { "name": "Canned or jarred Non GMO quickie corn", "code": "50613249" },
      { "name": "Canned or jarred Non GMO radiance corn", "code": "50613250" },
      {
        "name": "Canned or jarred Non GMO seneca brave corn",
        "code": "50613251"
      },
      {
        "name": "Canned or jarred Non GMO seneca dawn corn",
        "code": "50613252"
      },
      {
        "name": "Canned or jarred Non GMO seneca horizon corn",
        "code": "50613253"
      },
      {
        "name": "Canned or jarred Non GMO seneca starshine corn",
        "code": "50613254"
      },
      {
        "name": "Canned or jarred Non GMO seneca white knight corn",
        "code": "50613255"
      },
      { "name": "Canned or jarred Non GMO showcase corn", "code": "50613256" },
      {
        "name": "Canned or jarred Non GMO silver queen corn",
        "code": "50613257"
      },
      { "name": "Canned or jarred Non GMO snowbelle corn", "code": "50613258" },
      {
        "name": "Canned or jarred Non GMO spring snow corn",
        "code": "50613259"
      },
      {
        "name": "Canned or jarred Non GMO spring treat corn",
        "code": "50613260"
      },
      {
        "name": "Canned or jarred Non GMO sugar and gold corn",
        "code": "50613261"
      },
      {
        "name": "Canned or jarred Non GMO sugar buns corn",
        "code": "50613262"
      },
      {
        "name": "Canned or jarred Non GMO sugar snow corn",
        "code": "50613263"
      },
      { "name": "Canned or jarred Non GMO sundance corn", "code": "50613264" },
      { "name": "Canned or jarred Non GMO telstar corn", "code": "50613265" },
      {
        "name": "Canned or jarred Non GMO terminator corn",
        "code": "50613266"
      },
      { "name": "Canned or jarred Non GMO treasure corn", "code": "50613267" },
      { "name": "Canned or jarred Non GMO tuxedo corn", "code": "50613268" },
      { "name": "Canned or jarred Non GMO aloha corn", "code": "50613269" },
      { "name": "Canned or jarred Non GMO cresses", "code": "50613300" },
      { "name": "Canned or jarred Non GMO land cress", "code": "50613301" },
      { "name": "Canned or jarred Non GMO nasturtium", "code": "50613302" },
      { "name": "Canned or jarred Non GMO watercress", "code": "50613303" },
      { "name": "Canned or jarred Non GMO wintercress", "code": "50613304" },
      { "name": "Canned or jarred Non GMO cucumbers", "code": "50613400" },
      {
        "name": "Canned or jarred Non GMO arena cucumbers",
        "code": "50613401"
      },
      {
        "name": "Canned or jarred Non GMO armenian cucumbers",
        "code": "50613402"
      },
      {
        "name": "Canned or jarred Non GMO athene cucumbers",
        "code": "50613403"
      },
      {
        "name": "Canned or jarred Non GMO bianco lungo di parigi cucumbers",
        "code": "50613404"
      },
      {
        "name": "Canned or jarred Non GMO burpless tasty green cucumbers",
        "code": "50613405"
      },
      {
        "name": "Canned or jarred Non GMO chicago pickling cucumbers",
        "code": "50613406"
      },
      {
        "name": "Canned or jarred Non GMO crystal apple cucumbers",
        "code": "50613407"
      },
      {
        "name": "Canned or jarred Non GMO crystal lemon cucumbers",
        "code": "50613408"
      },
      {
        "name": "Canned or jarred Non GMO danimas cucumbers",
        "code": "50613409"
      },
      {
        "name": "Canned or jarred Non GMO gherkin cucumbers",
        "code": "50613410"
      },
      {
        "name": "Canned or jarred Non GMO hokus cucumbers",
        "code": "50613411"
      },
      {
        "name": "Canned or jarred Non GMO japanese cucumbers",
        "code": "50613412"
      },
      {
        "name": "Canned or jarred Non GMO karela cucumbers",
        "code": "50613413"
      },
      {
        "name": "Canned or jarred Non GMO korila cucumbers",
        "code": "50613414"
      },
      {
        "name": "Canned or jarred Non GMO long green improved cucumbers",
        "code": "50613415"
      },
      {
        "name": "Canned or jarred Non GMO marketmore cucumbers",
        "code": "50613416"
      },
      {
        "name": "Canned or jarred Non GMO midget cucumbers",
        "code": "50613417"
      },
      {
        "name": "Canned or jarred Non GMO national pickling cucumbers",
        "code": "50613418"
      },
      {
        "name": "Canned or jarred Non GMO persian cucumbers",
        "code": "50613419"
      },
      {
        "name": "Canned or jarred Non GMO telegraph cucumbers",
        "code": "50613420"
      },
      {
        "name": "Canned or jarred Non GMO telegraph improved cucumbers",
        "code": "50613421"
      },
      {
        "name": "Canned or jarred Non GMO vert de massy cornichon cucumbers",
        "code": "50613422"
      },
      {
        "name": "Canned or jarred Non GMO yamato cucumbers",
        "code": "50613423"
      },
      { "name": "Canned or jarred Non GMO eggplants", "code": "50613500" },
      {
        "name": "Canned or jarred Non GMO bambino eggplants",
        "code": "50613501"
      },
      {
        "name": "Canned or jarred Non GMO black beauty eggplants",
        "code": "50613502"
      },
      {
        "name": "Canned or jarred Non GMO black enorma eggplants",
        "code": "50613503"
      },
      {
        "name": "Canned or jarred Non GMO chinese eggplants",
        "code": "50613504"
      },
      {
        "name": "Canned or jarred Non GMO easter egg eggplants",
        "code": "50613505"
      },
      {
        "name": "Canned or jarred Non GMO filipino eggplants",
        "code": "50613506"
      },
      {
        "name": "Canned or jarred Non GMO florida market eggplants",
        "code": "50613507"
      },
      {
        "name": "Canned or jarred Non GMO indian eggplants",
        "code": "50613508"
      },
      {
        "name": "Canned or jarred Non GMO italian eggplants",
        "code": "50613509"
      },
      {
        "name": "Canned or jarred Non GMO japanese eggplants",
        "code": "50613510"
      },
      {
        "name": "Canned or jarred Non GMO long purple eggplants",
        "code": "50613511"
      },
      {
        "name": "Canned or jarred Non GMO long striped eggplants",
        "code": "50613512"
      },
      {
        "name": "Canned or jarred Non GMO moneymaker eggplants",
        "code": "50613513"
      },
      { "name": "Canned or jarred Non GMO ova eggplants", "code": "50613514" },
      { "name": "Canned or jarred Non GMO pea eggplants", "code": "50613515" },
      {
        "name": "Canned or jarred Non GMO short tom eggplants",
        "code": "50613516"
      },
      {
        "name": "Canned or jarred Non GMO sicilian eggplants",
        "code": "50613517"
      },
      { "name": "Canned or jarred Non GMO thai eggplants", "code": "50613518" },
      {
        "name": "Canned or jarred Non GMO violette di firenze eggplants",
        "code": "50613519"
      },
      {
        "name": "Canned or jarred Non GMO white eggplants",
        "code": "50613520"
      },
      { "name": "Canned or jarred Non GMO endives", "code": "50613600" },
      {
        "name": "Canned or jarred Non GMO brussels witloof endives",
        "code": "50613601"
      },
      {
        "name": "Canned or jarred Non GMO castelfranco endives",
        "code": "50613602"
      },
      {
        "name": "Canned or jarred Non GMO catalogna di galatina endives",
        "code": "50613603"
      },
      {
        "name": "Canned or jarred Non GMO chioggia endives",
        "code": "50613604"
      },
      {
        "name": "Canned or jarred Non GMO grumolo verde endives",
        "code": "50613605"
      },
      {
        "name": "Canned or jarred Non GMO large rooted magdeburg endives",
        "code": "50613606"
      },
      {
        "name": "Canned or jarred Non GMO palla rossa zorzi precoce endives",
        "code": "50613607"
      },
      {
        "name": "Canned or jarred Non GMO radice amare endives",
        "code": "50613608"
      },
      {
        "name": "Canned or jarred Non GMO rossa di treviso endives",
        "code": "50613609"
      },
      {
        "name": "Canned or jarred Non GMO rossa di verona endives",
        "code": "50613610"
      },
      {
        "name": "Canned or jarred Non GMO soncino endives",
        "code": "50613611"
      },
      {
        "name": "Canned or jarred Non GMO sugarhat endives",
        "code": "50613612"
      },
      { "name": "Canned or jarred Non GMO verona endives", "code": "50613613" },
      {
        "name": "Canned or jarred Non GMO witloof zoom endives",
        "code": "50613614"
      },
      { "name": "Canned or jarred Non GMO fennels", "code": "50613700" },
      { "name": "Canned or jarred Non GMO cantino fennel", "code": "50613701" },
      { "name": "Canned or jarred Non GMO fino fennel", "code": "50613702" },
      { "name": "Canned or jarred Non GMO herald fennel", "code": "50613703" },
      {
        "name": "Canned or jarred Non GMO perfection fennel",
        "code": "50613704"
      },
      { "name": "Canned or jarred Non GMO sirio fennel", "code": "50613705" },
      {
        "name": "Canned or jarred Non GMO sweet florence fennel",
        "code": "50613706"
      },
      { "name": "Canned or jarred Non GMO tardo fennel", "code": "50613707" },
      { "name": "Canned or jarred Non GMO garlics", "code": "50613800" },
      {
        "name": "Canned or jarred Non GMO california late garlic",
        "code": "50613801"
      },
      {
        "name": "Canned or jarred Non GMO chinese garlic stems",
        "code": "50613802"
      },
      { "name": "Canned or jarred Non GMO garlic chives", "code": "50613803" },
      {
        "name": "Canned or jarred Non GMO germidor garlic",
        "code": "50613804"
      },
      {
        "name": "Canned or jarred Non GMO long keeper garlic",
        "code": "50613805"
      },
      { "name": "Canned or jarred Non GMO ramson garlic", "code": "50613806" },
      {
        "name": "Canned or jarred Non GMO rocambole garlic",
        "code": "50613807"
      },
      {
        "name": "Canned or jarred Non GMO rose de lautrec garlic",
        "code": "50613808"
      },
      {
        "name": "Canned or jarred Non GMO solent wight garlic",
        "code": "50613809"
      },
      {
        "name": "Canned or jarred Non GMO spanish morado garlic",
        "code": "50613810"
      },
      {
        "name": "Canned or jarred Non GMO venetian/italian garlic",
        "code": "50613811"
      },
      { "name": "Canned or jarred Non GMO gourds", "code": "50613900" },
      { "name": "Canned or jarred Non GMO angled loofah", "code": "50613901" },
      { "name": "Canned or jarred Non GMO bitter gourd", "code": "50613902" },
      { "name": "Canned or jarred Non GMO bottle gourd", "code": "50613903" },
      {
        "name": "Canned or jarred Non GMO calabash gourds",
        "code": "50613904"
      },
      {
        "name": "Canned or jarred Non GMO fuzzy/hairy melon",
        "code": "50613905"
      },
      { "name": "Canned or jarred Non GMO musky gourd", "code": "50613906" },
      { "name": "Canned or jarred Non GMO smooth loofah", "code": "50613907" },
      { "name": "Canned or jarred Non GMO snake gourd", "code": "50613908" },
      {
        "name": "Canned or jarred Non GMO spiny bitter gourd",
        "code": "50613909"
      },
      { "name": "Canned or jarred Non GMO tinda gourds", "code": "50613910" },
      {
        "name": "Canned or jarred Non GMO tindoori gourds",
        "code": "50613911"
      },
      { "name": "Canned or jarred Non GMO green peas", "code": "50614000" },
      { "name": "Canned or jarred Non GMO china peas", "code": "50614001" },
      { "name": "Canned or jarred Non GMO english peas", "code": "50614002" },
      { "name": "Canned or jarred Non GMO garden peas", "code": "50614003" },
      { "name": "Canned or jarred Non GMO snow peas", "code": "50614004" },
      {
        "name": "Canned or jarred Non GMO sugar snap peas",
        "code": "50614005"
      },
      { "name": "Canned or jarred Non GMO herbs", "code": "50614100" },
      { "name": "Canned or jarred Non GMO basil", "code": "50614101" },
      { "name": "Canned or jarred Non GMO bay leaves", "code": "50614102" },
      { "name": "Canned or jarred Non GMO borage", "code": "50614103" },
      { "name": "Canned or jarred Non GMO caraway", "code": "50614104" },
      { "name": "Canned or jarred Non GMO chervil", "code": "50614105" },
      { "name": "Canned or jarred Non GMO cilantro", "code": "50614106" },
      { "name": "Canned or jarred Non GMO cipolinos", "code": "50614107" },
      { "name": "Canned or jarred Non GMO curry leaves", "code": "50614108" },
      { "name": "Canned or jarred Non GMO dill", "code": "50614109" },
      { "name": "Canned or jarred Non GMO epazote", "code": "50614110" },
      { "name": "Canned or jarred Non GMO fenugreek", "code": "50614111" },
      { "name": "Canned or jarred Non GMO lemon grass", "code": "50614112" },
      { "name": "Canned or jarred Non GMO marjoram", "code": "50614113" },
      { "name": "Canned or jarred Non GMO mint", "code": "50614114" },
      { "name": "Canned or jarred Non GMO oregano", "code": "50614115" },
      { "name": "Canned or jarred Non GMO papalo", "code": "50614116" },
      { "name": "Canned or jarred Non GMO pepicha", "code": "50614117" },
      { "name": "Canned or jarred Non GMO perilla", "code": "50614118" },
      { "name": "Canned or jarred Non GMO recao", "code": "50614119" },
      { "name": "Canned or jarred Non GMO rosemary", "code": "50614120" },
      { "name": "Canned or jarred Non GMO sage", "code": "50614121" },
      { "name": "Canned or jarred Non GMO salsify", "code": "50614122" },
      { "name": "Canned or jarred Non GMO savory", "code": "50614123" },
      { "name": "Canned or jarred Non GMO tarragon", "code": "50614124" },
      { "name": "Canned or jarred Non GMO thyme", "code": "50614125" },
      { "name": "Canned or jarred Non GMO tumeric", "code": "50614126" },
      { "name": "Canned or jarred Non GMO verdulaga", "code": "50614127" },
      { "name": "Canned or jarred Non GMO kale", "code": "50614200" },
      { "name": "Canned or jarred Non GMO curly kale", "code": "50614201" },
      { "name": "Canned or jarred Non GMO collard greens", "code": "50614202" },
      { "name": "Canned or jarred Non GMO kohlrabi", "code": "50614300" },
      {
        "name": "Canned or jarred Non GMO azur star kohlrabi",
        "code": "50614301"
      },
      {
        "name": "Canned or jarred Non GMO green vienna kohlrabi",
        "code": "50614302"
      },
      { "name": "Canned or jarred Non GMO lanro kohlrabi", "code": "50614303" },
      {
        "name": "Canned or jarred Non GMO purple vienna kohlrabi",
        "code": "50614304"
      },
      {
        "name": "Canned or jarred Non GMO rowel trero kohlrabi",
        "code": "50614305"
      },
      {
        "name": "Canned or jarred Non GMO white vienna kohlrabi",
        "code": "50614306"
      },
      { "name": "Canned or jarred Non GMO leeks", "code": "50614400" },
      {
        "name": "Canned or jarred Non GMO autumn giant-cobra leeks",
        "code": "50614401"
      },
      {
        "name": "Canned or jarred Non GMO autumn mammoth 2 leeks",
        "code": "50614402"
      },
      {
        "name": "Canned or jarred Non GMO bleu de solaise leeks",
        "code": "50614403"
      },
      { "name": "Canned or jarred Non GMO cortina leeks", "code": "50614404" },
      { "name": "Canned or jarred Non GMO prelina leeks", "code": "50614405" },
      { "name": "Canned or jarred Non GMO wild leek ramp", "code": "50614406" },
      { "name": "Canned or jarred Non GMO lentils", "code": "50614500" },
      { "name": "Canned or jarred Non GMO beluga lentils", "code": "50614501" },
      {
        "name": "Canned or jarred Non GMO french green lentils",
        "code": "50614502"
      },
      { "name": "Canned or jarred Non GMO green lentils", "code": "50614503" },
      {
        "name": "Canned or jarred Non GMO petite crimson lentils",
        "code": "50614504"
      },
      {
        "name": "Canned or jarred Non GMO spanish pardina lentils",
        "code": "50614505"
      },
      {
        "name": "Canned or jarred Non GMO split red lentils",
        "code": "50614506"
      },
      {
        "name": "Canned or jarred Non GMO split yellow lentils",
        "code": "50614507"
      },
      {
        "name": "Canned or jarred Non GMO tarahumara pinks lentils",
        "code": "50614508"
      },
      { "name": "Canned or jarred Non GMO lettuces", "code": "50614600" },
      { "name": "Canned or jarred Non GMO bibb lettuce", "code": "50614601" },
      { "name": "Canned or jarred Non GMO boston lettuce", "code": "50614602" },
      { "name": "Canned or jarred Non GMO frisee lettuce", "code": "50614603" },
      {
        "name": "Canned or jarred Non GMO lolla rossa lettuce",
        "code": "50614604"
      },
      {
        "name": "Canned or jarred Non GMO mesculin mix lettuce",
        "code": "50614605"
      },
      { "name": "Canned or jarred Non GMO mizuna lettuce", "code": "50614606" },
      {
        "name": "Canned or jarred Non GMO red leaf lettuce",
        "code": "50614607"
      },
      {
        "name": "Canned or jarred Non GMO red oak leaf lettuce",
        "code": "50614608"
      },
      {
        "name": "Canned or jarred Non GMO ruby romaine lettuce",
        "code": "50614609"
      },
      {
        "name": "Canned or jarred Non GMO baby red romaine lettuce",
        "code": "50614610"
      },
      {
        "name": "Canned or jarred Non GMO butterhead lettuces",
        "code": "50614611"
      },
      {
        "name": "Canned or jarred Non GMO chinese lettuces",
        "code": "50614612"
      },
      {
        "name": "Canned or jarred Non GMO crisphead lettuces",
        "code": "50614613"
      },
      {
        "name": "Canned or jarred Non GMO green leaf lettuce",
        "code": "50614614"
      },
      {
        "name": "Canned or jarred Non GMO iceberg lettuce",
        "code": "50614615"
      },
      {
        "name": "Canned or jarred Non GMO lamb\u2019s lettuces",
        "code": "50614616"
      },
      {
        "name": "Canned or jarred Non GMO looseleaf lettuces",
        "code": "50614617"
      },
      { "name": "Canned or jarred Non GMO mache lettuce", "code": "50614618" },
      {
        "name": "Canned or jarred Non GMO red boston lettuce",
        "code": "50614619"
      },
      {
        "name": "Canned or jarred Non GMO red headed lettuces",
        "code": "50614620"
      },
      {
        "name": "Canned or jarred Non GMO romaine lettuces",
        "code": "50614621"
      },
      {
        "name": "Canned or jarred Non GMO russian red mustard lettuce",
        "code": "50614622"
      },
      { "name": "Canned or jarred Non GMO tatsoi lettuce", "code": "50614623" },
      { "name": "Canned or jarred Non GMO malanga", "code": "50614700" },
      {
        "name": "Canned or jarred Non GMO amarilla malanga",
        "code": "50614701"
      },
      { "name": "Canned or jarred Non GMO blanca malanga", "code": "50614702" },
      { "name": "Canned or jarred Non GMO coco malanga", "code": "50614703" },
      { "name": "Canned or jarred Non GMO eddoes malanga", "code": "50614704" },
      { "name": "Canned or jarred Non GMO islena malanga", "code": "50614705" },
      { "name": "Canned or jarred Non GMO lila malanga", "code": "50614706" },
      { "name": "Canned or jarred Non GMO mushrooms", "code": "50614800" },
      {
        "name": "Canned or jarred Non GMO black trumpet mushrooms",
        "code": "50614801"
      },
      {
        "name": "Canned or jarred Non GMO brown mushrooms",
        "code": "50614802"
      },
      {
        "name": "Canned or jarred Non GMO champinion mushrooms",
        "code": "50614803"
      },
      {
        "name": "Canned or jarred Non GMO chanterelle mushrooms",
        "code": "50614804"
      },
      {
        "name": "Canned or jarred Non GMO cremini mushrooms",
        "code": "50614805"
      },
      {
        "name": "Canned or jarred Non GMO enoki mushrooms",
        "code": "50614806"
      },
      {
        "name": "Canned or jarred Non GMO hedge hog mushrooms",
        "code": "50614807"
      },
      {
        "name": "Canned or jarred Non GMO hen of the woods mushrooms",
        "code": "50614808"
      },
      {
        "name": "Canned or jarred Non GMO lobster mushrooms",
        "code": "50614809"
      },
      {
        "name": "Canned or jarred Non GMO morels mushrooms",
        "code": "50614810"
      },
      {
        "name": "Canned or jarred Non GMO oyster mushrooms",
        "code": "50614811"
      },
      {
        "name": "Canned or jarred Non GMO pleurotus mushrooms",
        "code": "50614812"
      },
      {
        "name": "Canned or jarred Non GMO pompom mushrooms",
        "code": "50614813"
      },
      {
        "name": "Canned or jarred Non GMO porcieni mushrooms",
        "code": "50614814"
      },
      {
        "name": "Canned or jarred Non GMO portobella mushrooms",
        "code": "50614815"
      },
      {
        "name": "Canned or jarred Non GMO shiitake mushrooms",
        "code": "50614816"
      },
      {
        "name": "Canned or jarred Non GMO shimeji mushrooms",
        "code": "50614817"
      },
      {
        "name": "Canned or jarred Non GMO st george's mushrooms",
        "code": "50614818"
      },
      {
        "name": "Canned or jarred Non GMO white mushrooms",
        "code": "50614819"
      },
      {
        "name": "Canned or jarred Non GMO white trumpet mushrooms",
        "code": "50614820"
      },
      {
        "name": "Canned or jarred Non GMO woodear mushrooms",
        "code": "50614821"
      },
      { "name": "Canned or jarred Non GMO mustards", "code": "50614900" },
      { "name": "Canned or jarred Non GMO bamboo mustard", "code": "50614901" },
      { "name": "Canned or jarred Non GMO garlic mustard", "code": "50614902" },
      {
        "name": "Canned or jarred Non GMO giantleafed mustard",
        "code": "50614903"
      },
      {
        "name": "Canned or jarred Non GMO red in snow mustard",
        "code": "50614904"
      },
      {
        "name": "Canned or jarred Non GMO southern mustard",
        "code": "50614905"
      },
      {
        "name": "Canned or jarred Non GMO wrapped heart mustard",
        "code": "50614906"
      },
      { "name": "Canned or jarred Non GMO nightshades", "code": "50615000" },
      {
        "name": "Canned or jarred Non GMO chinese lantern",
        "code": "50615001"
      },
      {
        "name": "Canned or jarred Non GMO garden huckleberry",
        "code": "50615002"
      },
      { "name": "Canned or jarred Non GMO naranjilla", "code": "50615003" },
      { "name": "Canned or jarred Non GMO tomatillo", "code": "50615004" },
      { "name": "Canned or jarred Non GMO okras", "code": "50615100" },
      { "name": "Canned or jarred Non GMO artist okra", "code": "50615101" },
      { "name": "Canned or jarred Non GMO burgundy okra", "code": "50615102" },
      {
        "name": "Canned or jarred Non GMO clemson spineless okra",
        "code": "50615103"
      },
      {
        "name": "Canned or jarred Non GMO dwarf green long pod okra",
        "code": "50615104"
      },
      {
        "name": "Canned or jarred Non GMO mammoth spineless long pod okra",
        "code": "50615105"
      },
      {
        "name": "Canned or jarred Non GMO red velvet okra",
        "code": "50615106"
      },
      {
        "name": "Canned or jarred Non GMO star of david heirloom okra",
        "code": "50615107"
      },
      { "name": "Canned or jarred Non GMO onions", "code": "50615200" },
      { "name": "Canned or jarred Non GMO albion onions", "code": "50615201" },
      {
        "name": "Canned or jarred Non GMO alisa craig onions",
        "code": "50615202"
      },
      { "name": "Canned or jarred Non GMO boiling onions", "code": "50615203" },
      { "name": "Canned or jarred Non GMO buffalo onions", "code": "50615204" },
      { "name": "Canned or jarred Non GMO bulb onions", "code": "50615205" },
      {
        "name": "Canned or jarred Non GMO creaming onions",
        "code": "50615206"
      },
      {
        "name": "Canned or jarred Non GMO express yellow o-x onions",
        "code": "50615207"
      },
      { "name": "Canned or jarred Non GMO kelsae onions", "code": "50615208" },
      {
        "name": "Canned or jarred Non GMO marshalls giant fen globe onions",
        "code": "50615209"
      },
      { "name": "Canned or jarred Non GMO pearl onions", "code": "50615210" },
      {
        "name": "Canned or jarred Non GMO red baron onions",
        "code": "50615211"
      },
      { "name": "Canned or jarred Non GMO red onions", "code": "50615212" },
      {
        "name": "Canned or jarred Non GMO rijnsberger onions",
        "code": "50615213"
      },
      {
        "name": "Canned or jarred Non GMO senshyu semi-globe yellow onions",
        "code": "50615214"
      },
      { "name": "Canned or jarred Non GMO sturon onions", "code": "50615215" },
      {
        "name": "Canned or jarred Non GMO stuttgarter giant onions",
        "code": "50615216"
      },
      { "name": "Canned or jarred Non GMO sweet onions", "code": "50615217" },
      {
        "name": "Canned or jarred Non GMO torpedo or red italian onions",
        "code": "50615218"
      },
      {
        "name": "Canned or jarred Non GMO red storage onions",
        "code": "50615219"
      },
      {
        "name": "Canned or jarred Non GMO white storage onions",
        "code": "50615220"
      },
      {
        "name": "Canned or jarred Non GMO yellow storage onions",
        "code": "50615221"
      },
      { "name": "Canned or jarred Non GMO peanuts", "code": "50615300" },
      {
        "name": "Canned or jarred Non GMO bambarra groundnut peanuts",
        "code": "50615301"
      },
      {
        "name": "Canned or jarred Non GMO florunner peanuts",
        "code": "50615302"
      },
      {
        "name": "Canned or jarred Non GMO hausa/kersting's ground nut peanuts",
        "code": "50615303"
      },
      {
        "name": "Canned or jarred Non GMO spanish peanuts",
        "code": "50615304"
      },
      {
        "name": "Canned or jarred Non GMO valencia peanuts",
        "code": "50615305"
      },
      {
        "name": "Canned or jarred Non GMO virginia peanuts",
        "code": "50615306"
      },
      { "name": "Canned or jarred Non GMO peas", "code": "50615400" },
      {
        "name": "Canned or jarred Non GMO purple hull peas",
        "code": "50615401"
      },
      { "name": "Canned or jarred Non GMO pinkeye peas", "code": "50615402" },
      { "name": "Canned or jarred Non GMO crowder peas", "code": "50615403" },
      {
        "name": "Canned or jarred Non GMO white acre peas",
        "code": "50615404"
      },
      { "name": "Canned or jarred Non GMO blackeyed peas", "code": "50615405" },
      {
        "name": "Canned or jarred Non GMO zipper cream peas",
        "code": "50615406"
      },
      { "name": "Canned or jarred Non GMO peppers", "code": "50615500" },
      { "name": "Canned or jarred Non GMO ajies peppers", "code": "50615501" },
      { "name": "Canned or jarred Non GMO arbol peppers", "code": "50615502" },
      { "name": "Canned or jarred Non GMO cheese peppers", "code": "50615503" },
      {
        "name": "Canned or jarred Non GMO chilaca peppers",
        "code": "50615504"
      },
      {
        "name": "Canned or jarred Non GMO cubanelles peppers",
        "code": "50615505"
      },
      { "name": "Canned or jarred Non GMO fresno peppers", "code": "50615506" },
      { "name": "Canned or jarred Non GMO kapia peppers", "code": "50615507" },
      { "name": "Canned or jarred Non GMO korean peppers", "code": "50615508" },
      {
        "name": "Canned or jarred Non GMO manzano peppers",
        "code": "50615509"
      },
      {
        "name": "Canned or jarred Non GMO melrose peppers",
        "code": "50615510"
      },
      {
        "name": "Canned or jarred Non GMO yellow chile peppers",
        "code": "50615511"
      },
      {
        "name": "Canned or jarred Non GMO aji dulces peppers",
        "code": "50615512"
      },
      {
        "name": "Canned or jarred Non GMO anaheim peppers",
        "code": "50615513"
      },
      { "name": "Canned or jarred Non GMO ancho peppers", "code": "50615514" },
      { "name": "Canned or jarred Non GMO bell peppers", "code": "50615515" },
      {
        "name": "Canned or jarred Non GMO cascabel peppers",
        "code": "50615516"
      },
      {
        "name": "Canned or jarred Non GMO cayenne peppers",
        "code": "50615517"
      },
      {
        "name": "Canned or jarred Non GMO cherry hots peppers",
        "code": "50615518"
      },
      {
        "name": "Canned or jarred Non GMO chiltecpin peppers",
        "code": "50615519"
      },
      {
        "name": "Canned or jarred Non GMO finger hot peppers",
        "code": "50615520"
      },
      {
        "name": "Canned or jarred Non GMO guajillo peppers",
        "code": "50615521"
      },
      { "name": "Canned or jarred Non GMO guerro peppers", "code": "50615522" },
      {
        "name": "Canned or jarred Non GMO habanero peppers",
        "code": "50615523"
      },
      {
        "name": "Canned or jarred Non GMO hungarian wax peppers",
        "code": "50615524"
      },
      {
        "name": "Canned or jarred Non GMO jalapeno peppers",
        "code": "50615525"
      },
      {
        "name": "Canned or jarred Non GMO long hot peppers",
        "code": "50615526"
      },
      {
        "name": "Canned or jarred Non GMO mirasol peppers",
        "code": "50615527"
      },
      {
        "name": "Canned or jarred Non GMO pasilla peppers",
        "code": "50615528"
      },
      {
        "name": "Canned or jarred Non GMO peperoncini peppers",
        "code": "50615529"
      },
      { "name": "Canned or jarred Non GMO pequin peppers", "code": "50615530" },
      {
        "name": "Canned or jarred Non GMO pimiento peppers",
        "code": "50615531"
      },
      {
        "name": "Canned or jarred Non GMO poblano peppers",
        "code": "50615532"
      },
      {
        "name": "Canned or jarred Non GMO scotch bonnet peppers",
        "code": "50615533"
      },
      {
        "name": "Canned or jarred Non GMO serrano peppers",
        "code": "50615534"
      },
      {
        "name": "Canned or jarred Non GMO tabasco peppers",
        "code": "50615535"
      },
      { "name": "Canned or jarred Non GMO tai peppers", "code": "50615536" },
      { "name": "Canned or jarred Non GMO tepin peppers", "code": "50615537" },
      { "name": "Canned or jarred Non GMO potatoes", "code": "50615600" },
      {
        "name": "Canned or jarred Non GMO long white potatoes",
        "code": "50615601"
      },
      {
        "name": "Canned or jarred Non GMO round white potatoes",
        "code": "50615602"
      },
      {
        "name": "Canned or jarred Non GMO round red potatoes",
        "code": "50615603"
      },
      {
        "name": "Canned or jarred Non GMO russet potatoes",
        "code": "50615604"
      },
      {
        "name": "Canned or jarred Non GMO purple potatoes",
        "code": "50615605"
      },
      {
        "name": "Canned or jarred Non GMO yellow potatoes",
        "code": "50615606"
      },
      { "name": "Canned or jarred Non GMO new potatoes", "code": "50615607" },
      {
        "name": "Canned or jarred Non GMO specialty potatoes",
        "code": "50615608"
      },
      { "name": "Canned or jarred Non GMO rutabagas", "code": "50615700" },
      { "name": "Canned or jarred Non GMO acme rutabagas", "code": "50615701" },
      {
        "name": "Canned or jarred Non GMO angela rutabagas",
        "code": "50615702"
      },
      {
        "name": "Canned or jarred Non GMO best of all rutabagas",
        "code": "50615703"
      },
      {
        "name": "Canned or jarred Non GMO marian rutabagas",
        "code": "50615704"
      },
      { "name": "Canned or jarred Non GMO sea vegetables", "code": "50615800" },
      { "name": "Canned or jarred Non GMO agar-agar", "code": "50615801" },
      { "name": "Canned or jarred Non GMO arame", "code": "50615802" },
      { "name": "Canned or jarred Non GMO dulse", "code": "50615803" },
      {
        "name": "Canned or jarred Non GMO haricot vert de mer",
        "code": "50615804"
      },
      { "name": "Canned or jarred Non GMO hijiki", "code": "50615805" },
      { "name": "Canned or jarred Non GMO irish moss", "code": "50615806" },
      { "name": "Canned or jarred Non GMO kelp", "code": "50615807" },
      { "name": "Canned or jarred Non GMO laver", "code": "50615808" },
      { "name": "Canned or jarred Non GMO nori", "code": "50615809" },
      { "name": "Canned or jarred Non GMO red algae", "code": "50615810" },
      { "name": "Canned or jarred Non GMO sea kale", "code": "50615811" },
      { "name": "Canned or jarred Non GMO sea lettuce", "code": "50615812" },
      { "name": "Canned or jarred Non GMO seaweeds", "code": "50615813" },
      { "name": "Canned or jarred Non GMO spirulina", "code": "50615814" },
      { "name": "Canned or jarred Non GMO susabi nori", "code": "50615815" },
      { "name": "Canned or jarred Non GMO wakame", "code": "50615816" },
      { "name": "Canned or jarred Non GMO shallots", "code": "50615900" },
      {
        "name": "Canned or jarred Non GMO atlantic shallots",
        "code": "50615901"
      },
      {
        "name": "Canned or jarred Non GMO creation shallots",
        "code": "50615902"
      },
      {
        "name": "Canned or jarred Non GMO drittler white nest shallots",
        "code": "50615903"
      },
      {
        "name": "Canned or jarred Non GMO giant yellow improved shallots",
        "code": "50615904"
      },
      {
        "name": "Canned or jarred Non GMO golden gourmet shallots",
        "code": "50615905"
      },
      {
        "name": "Canned or jarred Non GMO grise de bagnolet shallots",
        "code": "50615906"
      },
      {
        "name": "Canned or jarred Non GMO hative de niort shallots",
        "code": "50615907"
      },
      {
        "name": "Canned or jarred Non GMO pikant shallots",
        "code": "50615908"
      },
      {
        "name": "Canned or jarred Non GMO red potato onions",
        "code": "50615909"
      },
      { "name": "Canned or jarred Non GMO sante shallots", "code": "50615910" },
      {
        "name": "Canned or jarred Non GMO topper shallots",
        "code": "50615911"
      },
      { "name": "Canned or jarred Non GMO sorrels", "code": "50616000" },
      { "name": "Canned or jarred Non GMO dock sorrel", "code": "50616001" },
      { "name": "Canned or jarred Non GMO garden sorrel", "code": "50616002" },
      { "name": "Canned or jarred Non GMO sheep sorrel", "code": "50616003" },
      { "name": "Canned or jarred Non GMO wood sorrel", "code": "50616004" },
      { "name": "Canned or jarred Non GMO spinaches", "code": "50616100" },
      {
        "name": "Canned or jarred Non GMO america spinach",
        "code": "50616101"
      },
      {
        "name": "Canned or jarred Non GMO bloomsdale spinach",
        "code": "50616102"
      },
      {
        "name": "Canned or jarred Non GMO giant winter spinach",
        "code": "50616103"
      },
      {
        "name": "Canned or jarred Non GMO horenso spinach",
        "code": "50616104"
      },
      {
        "name": "Canned or jarred Non GMO iceplant spinach",
        "code": "50616105"
      },
      {
        "name": "Canned or jarred Non GMO lamb's quarters spinach",
        "code": "50616106"
      },
      {
        "name": "Canned or jarred Non GMO malabar spinach",
        "code": "50616107"
      },
      {
        "name": "Canned or jarred Non GMO medania spinach",
        "code": "50616108"
      },
      {
        "name": "Canned or jarred Non GMO new zealand spinach",
        "code": "50616109"
      },
      { "name": "Canned or jarred Non GMO orach spinach", "code": "50616110" },
      { "name": "Canned or jarred Non GMO savoy spinach", "code": "50616111" },
      {
        "name": "Canned or jarred Non GMO sigmaleaf spinach",
        "code": "50616112"
      },
      { "name": "Canned or jarred Non GMO space spinach", "code": "50616113" },
      {
        "name": "Canned or jarred Non GMO trinidad spinach",
        "code": "50616114"
      },
      { "name": "Canned or jarred Non GMO water spinach", "code": "50616115" },
      { "name": "Canned or jarred Non GMO wild spinach", "code": "50616116" },
      {
        "name": "Canned or jarred Non GMO summer squashes and summer pumpkins",
        "code": "50616200"
      },
      {
        "name": "Canned or jarred Non GMO boston marrow squash",
        "code": "50616201"
      },
      {
        "name": "Canned or jarred Non GMO butternut squash",
        "code": "50616202"
      },
      {
        "name": "Canned or jarred Non GMO costata romanesca squash",
        "code": "50616203"
      },
      {
        "name": "Canned or jarred Non GMO crookneck squash",
        "code": "50616204"
      },
      { "name": "Canned or jarred Non GMO cucuzza squash", "code": "50616205" },
      {
        "name": "Canned or jarred Non GMO delicata squash",
        "code": "50616206"
      },
      {
        "name": "Canned or jarred Non GMO delicious squash",
        "code": "50616207"
      },
      {
        "name": "Canned or jarred Non GMO early golden summer crookneck squash",
        "code": "50616208"
      },
      {
        "name": "Canned or jarred Non GMO early prolific straight neck squash",
        "code": "50616209"
      },
      { "name": "Canned or jarred Non GMO gold squash", "code": "50616210" },
      {
        "name": "Canned or jarred Non GMO jack be little squash",
        "code": "50616211"
      },
      {
        "name": "Canned or jarred Non GMO kentucky field squash",
        "code": "50616212"
      },
      { "name": "Canned or jarred Non GMO marrow squash", "code": "50616213" },
      {
        "name": "Canned or jarred Non GMO middle eastern squash",
        "code": "50616214"
      },
      {
        "name": "Canned or jarred Non GMO miniature squash",
        "code": "50616215"
      },
      {
        "name": "Canned or jarred Non GMO orangetti squash",
        "code": "50616216"
      },
      {
        "name": "Canned or jarred Non GMO pattypan squash",
        "code": "50616217"
      },
      { "name": "Canned or jarred Non GMO rondini squash", "code": "50616218" },
      { "name": "Canned or jarred Non GMO round squash", "code": "50616219" },
      {
        "name": "Canned or jarred Non GMO spaghetti squash",
        "code": "50616220"
      },
      {
        "name": "Canned or jarred Non GMO stripetti squash",
        "code": "50616221"
      },
      {
        "name": "Canned or jarred Non GMO sugar loaf squash",
        "code": "50616222"
      },
      {
        "name": "Canned or jarred Non GMO sweet dumpling squash",
        "code": "50616223"
      },
      {
        "name": "Canned or jarred Non GMO triple treat squash",
        "code": "50616224"
      },
      {
        "name": "Canned or jarred Non GMO waltham butternut squash",
        "code": "50616225"
      },
      {
        "name": "Canned or jarred Non GMO yellow bush scallop squash",
        "code": "50616226"
      },
      {
        "name": "Canned or jarred Non GMO yellow straightneck squash",
        "code": "50616227"
      },
      { "name": "Canned or jarred Non GMO zephyr squash", "code": "50616228" },
      {
        "name": "Canned or jarred Non GMO zucchini squash",
        "code": "50616229"
      },
      { "name": "Canned or jarred Non GMO sweet potatoes", "code": "50616300" },
      {
        "name": "Canned or jarred Non GMO beauregard sweet potatoes",
        "code": "50616301"
      },
      {
        "name": "Canned or jarred Non GMO centennial sweet potatoes",
        "code": "50616302"
      },
      {
        "name": "Canned or jarred Non GMO diane sweet potatoes",
        "code": "50616303"
      },
      {
        "name": "Canned or jarred Non GMO garnet sweet potatoes",
        "code": "50616304"
      },
      {
        "name": "Canned or jarred Non GMO georgia red sweet potatoes",
        "code": "50616305"
      },
      {
        "name": "Canned or jarred Non GMO goldensweet sweet potatoes",
        "code": "50616306"
      },
      {
        "name": "Canned or jarred Non GMO hanna sweet potatoes",
        "code": "50616307"
      },
      {
        "name": "Canned or jarred Non GMO japanese sweet potatoes",
        "code": "50616308"
      },
      {
        "name": "Canned or jarred Non GMO jersey sweet potatoes",
        "code": "50616309"
      },
      {
        "name": "Canned or jarred Non GMO jewel sweet potatoes",
        "code": "50616310"
      },
      {
        "name": "Canned or jarred Non GMO maryland red sweet potatoes",
        "code": "50616311"
      },
      {
        "name": "Canned or jarred Non GMO nemagold sweet potatoes",
        "code": "50616312"
      },
      {
        "name": "Canned or jarred Non GMO o'henry sweet potatoes",
        "code": "50616313"
      },
      {
        "name": "Canned or jarred Non GMO okinawan sweet potatoes",
        "code": "50616314"
      },
      {
        "name": "Canned or jarred Non GMO orange sweet potatoes",
        "code": "50616315"
      },
      {
        "name": "Canned or jarred Non GMO oriental sweet potatoes",
        "code": "50616316"
      },
      {
        "name": "Canned or jarred Non GMO red jersey sweet potatoes",
        "code": "50616317"
      },
      {
        "name": "Canned or jarred Non GMO red mar sweet potatoes",
        "code": "50616318"
      },
      {
        "name": "Canned or jarred Non GMO redglow sweet potatoes",
        "code": "50616319"
      },
      {
        "name": "Canned or jarred Non GMO yellow jersey sweet potatoes",
        "code": "50616320"
      },
      { "name": "Canned or jarred Non GMO tomatoes", "code": "50616400" },
      {
        "name": "Canned or jarred Non GMO ailsa craig tomatoes",
        "code": "50616401"
      },
      {
        "name": "Canned or jarred Non GMO alicante tomatoes",
        "code": "50616402"
      },
      {
        "name": "Canned or jarred Non GMO black plum tomatoes",
        "code": "50616403"
      },
      {
        "name": "Canned or jarred Non GMO brandywine tomatoes",
        "code": "50616404"
      },
      {
        "name": "Canned or jarred Non GMO cherry belle tomatoes",
        "code": "50616405"
      },
      {
        "name": "Canned or jarred Non GMO cherry tomatoes",
        "code": "50616406"
      },
      {
        "name": "Canned or jarred Non GMO delicious tomatoes",
        "code": "50616407"
      },
      {
        "name": "Canned or jarred Non GMO dombito tomatoes",
        "code": "50616408"
      },
      {
        "name": "Canned or jarred Non GMO gardener's delight tomatoes",
        "code": "50616409"
      },
      { "name": "Canned or jarred Non GMO grape tomatoes", "code": "50616410" },
      { "name": "Canned or jarred Non GMO green tomatoes", "code": "50616411" },
      {
        "name": "Canned or jarred Non GMO marmande super tomatoes",
        "code": "50616412"
      },
      {
        "name": "Canned or jarred Non GMO marvel striped traditional tomatoes",
        "code": "50616413"
      },
      {
        "name": "Canned or jarred Non GMO minibel tomatoes",
        "code": "50616414"
      },
      {
        "name": "Canned or jarred Non GMO oaxacan pink tomatoes",
        "code": "50616415"
      },
      {
        "name": "Canned or jarred Non GMO red alert tomatoes",
        "code": "50616416"
      },
      {
        "name": "Canned or jarred Non GMO roma vf tomatoes",
        "code": "50616417"
      },
      {
        "name": "Canned or jarred Non GMO san marzano tomatoes",
        "code": "50616418"
      },
      {
        "name": "Canned or jarred Non GMO shirley tomatoes",
        "code": "50616419"
      },
      {
        "name": "Canned or jarred Non GMO siberia tomato tomatoes",
        "code": "50616420"
      },
      {
        "name": "Canned or jarred Non GMO super beefsteak tomatoes",
        "code": "50616421"
      },
      {
        "name": "Canned or jarred Non GMO tigerella tomatoes",
        "code": "50616422"
      },
      {
        "name": "Canned or jarred Non GMO tiny tim tomatoes",
        "code": "50616423"
      },
      {
        "name": "Canned or jarred Non GMO tumbler tomatoes",
        "code": "50616424"
      },
      {
        "name": "Canned or jarred Non GMO yellow cocktail tomatoes",
        "code": "50616425"
      },
      {
        "name": "Canned or jarred Non GMO yellow pear-shaped tomatoes",
        "code": "50616426"
      },
      {
        "name": "Canned or jarred Non GMO yellow perfection tomatoes",
        "code": "50616427"
      },
      { "name": "Canned or jarred Non GMO turnip greens", "code": "50616500" },
      {
        "name": "Canned or jarred Non GMO green globe turnips",
        "code": "50616501"
      },
      {
        "name": "Canned or jarred Non GMO golden ball turnips",
        "code": "50616502"
      },
      {
        "name": "Canned or jarred Non GMO manchester market turnips",
        "code": "50616503"
      },
      {
        "name": "Canned or jarred Non GMO purple top milan turnips",
        "code": "50616504"
      },
      {
        "name": "Canned or jarred Non GMO purple top white turnips",
        "code": "50616505"
      },
      {
        "name": "Canned or jarred Non GMO snowball turnips",
        "code": "50616506"
      },
      { "name": "Canned or jarred Non GMO tokyo turnip", "code": "50616507" },
      {
        "name": "Canned or jarred Non GMO tokyo cross turnips",
        "code": "50616508"
      },
      {
        "name": "Canned or jarred Non GMO winter squashes and winter pumpkins",
        "code": "50616600"
      },
      { "name": "Canned or jarred Non GMO acorn squash", "code": "50616601" },
      {
        "name": "Canned or jarred Non GMO atlantic giant squash",
        "code": "50616602"
      },
      {
        "name": "Canned or jarred Non GMO banana pink squash",
        "code": "50616603"
      },
      { "name": "Canned or jarred Non GMO big max squash", "code": "50616604" },
      {
        "name": "Canned or jarred Non GMO calabaza squash",
        "code": "50616605"
      },
      {
        "name": "Canned or jarred Non GMO carnival squash",
        "code": "50616606"
      },
      { "name": "Canned or jarred Non GMO cheese pumpkin", "code": "50616607" },
      {
        "name": "Canned or jarred Non GMO crown prince squash",
        "code": "50616608"
      },
      {
        "name": "Canned or jarred Non GMO curcibita squash",
        "code": "50616609"
      },
      { "name": "Canned or jarred Non GMO cushaw squash", "code": "50616610" },
      {
        "name": "Canned or jarred Non GMO giant pumpkin squash",
        "code": "50616611"
      },
      { "name": "Canned or jarred Non GMO hubbard squash", "code": "50616612" },
      {
        "name": "Canned or jarred Non GMO jarrahdale squash",
        "code": "50616613"
      },
      { "name": "Canned or jarred Non GMO kabocha squash", "code": "50616614" },
      {
        "name": "Canned or jarred Non GMO queensland blue squash",
        "code": "50616615"
      },
      {
        "name": "Canned or jarred Non GMO rouge vif d'etampes squash",
        "code": "50616616"
      },
      {
        "name": "Canned or jarred Non GMO turks turban squash",
        "code": "50616617"
      },
      {
        "name": "Canned or jarred Non GMO valenciano squash",
        "code": "50616618"
      },
      {
        "name": "Canned or jarred Non GMO warted hubbard squash",
        "code": "50616619"
      },
      {
        "name": "Canned or jarred Non GMO whangaparoa crown pumpkin",
        "code": "50616620"
      },
      { "name": "Canned or jarred Non GMO yams", "code": "50616700" },
      {
        "name": "Canned or jarred Non GMO african bitter yams",
        "code": "50616701"
      },
      {
        "name": "Canned or jarred Non GMO asiatic bitter yams",
        "code": "50616702"
      },
      { "name": "Canned or jarred Non GMO chinese yams", "code": "50616703" },
      { "name": "Canned or jarred Non GMO globe yams", "code": "50616704" },
      { "name": "Canned or jarred Non GMO greater yams", "code": "50616705" },
      { "name": "Canned or jarred Non GMO japanese yams", "code": "50616706" },
      { "name": "Canned or jarred Non GMO lesser yams", "code": "50616707" },
      { "name": "Canned or jarred Non GMO potato yams", "code": "50616708" },
      {
        "name": "Canned or jarred Non GMO white guinea yams",
        "code": "50616709"
      },
      {
        "name": "Canned or jarred Non GMO yellow guinea yams",
        "code": "50616710"
      },
      {
        "name": "Canned or jarred Non GMO nominant vegetables",
        "code": "50616800"
      },
      { "name": "Canned or jarred Non GMO alfalfa", "code": "50616801" },
      { "name": "Canned or jarred Non GMO aloe leaves", "code": "50616802" },
      { "name": "Canned or jarred Non GMO apio", "code": "50616803" },
      { "name": "Canned or jarred Non GMO arrow root", "code": "50616804" },
      { "name": "Canned or jarred Non GMO arrowhead", "code": "50616805" },
      { "name": "Canned or jarred Non GMO arugula", "code": "50616806" },
      { "name": "Canned or jarred Non GMO arum", "code": "50616807" },
      { "name": "Canned or jarred Non GMO bamboo shoots", "code": "50616808" },
      { "name": "Canned or jarred Non GMO banana leaves", "code": "50616809" },
      { "name": "Canned or jarred Non GMO batatas", "code": "50616810" },
      { "name": "Canned or jarred Non GMO bean sprouts", "code": "50616811" },
      { "name": "Canned or jarred Non GMO beet tops", "code": "50616812" },
      { "name": "Canned or jarred Non GMO bittermelon", "code": "50616813" },
      { "name": "Canned or jarred Non GMO caperberries", "code": "50616814" },
      { "name": "Canned or jarred Non GMO carob", "code": "50616815" },
      { "name": "Canned or jarred Non GMO cha-om", "code": "50616816" },
      { "name": "Canned or jarred Non GMO chaoyotes", "code": "50616817" },
      { "name": "Canned or jarred Non GMO chickpeas", "code": "50616818" },
      {
        "name": "Canned or jarred Non GMO chrysanthemum greens",
        "code": "50616819"
      },
      {
        "name": "Canned or jarred Non GMO dandelion greens",
        "code": "50616820"
      },
      { "name": "Canned or jarred Non GMO dandelions", "code": "50616821" },
      { "name": "Canned or jarred Non GMO dasheen", "code": "50616822" },
      {
        "name": "Canned or jarred Non GMO dau mue or pea tips",
        "code": "50616823"
      },
      { "name": "Canned or jarred Non GMO diakon", "code": "50616824" },
      { "name": "Canned or jarred Non GMO donqua", "code": "50616825" },
      {
        "name": "Canned or jarred Non GMO fiddlehead ferns",
        "code": "50616826"
      },
      { "name": "Canned or jarred Non GMO gai choy", "code": "50616827" },
      { "name": "Canned or jarred Non GMO gailon", "code": "50616828" },
      { "name": "Canned or jarred Non GMO galanga", "code": "50616829" },
      { "name": "Canned or jarred Non GMO ginger root", "code": "50616830" },
      { "name": "Canned or jarred Non GMO gobo", "code": "50616831" },
      { "name": "Canned or jarred Non GMO hop sprouts", "code": "50616832" },
      { "name": "Canned or jarred Non GMO horseradish", "code": "50616833" },
      { "name": "Canned or jarred Non GMO jicama", "code": "50616834" },
      { "name": "Canned or jarred Non GMO kudzu", "code": "50616835" },
      { "name": "Canned or jarred Non GMO lily bulb", "code": "50616836" },
      { "name": "Canned or jarred Non GMO linkok", "code": "50616837" },
      { "name": "Canned or jarred Non GMO lo bok", "code": "50616838" },
      { "name": "Canned or jarred Non GMO long beans", "code": "50616839" },
      { "name": "Canned or jarred Non GMO lotus root", "code": "50616840" },
      { "name": "Canned or jarred Non GMO maguey leaves", "code": "50616841" },
      { "name": "Canned or jarred Non GMO mallows", "code": "50616842" },
      { "name": "Canned or jarred Non GMO mamey sapote", "code": "50616843" },
      { "name": "Canned or jarred Non GMO moap", "code": "50616844" },
      { "name": "Canned or jarred Non GMO moo", "code": "50616845" },
      { "name": "Canned or jarred Non GMO moqua", "code": "50616846" },
      { "name": "Canned or jarred Non GMO opos", "code": "50616847" },
      { "name": "Canned or jarred Non GMO palm hearts", "code": "50616848" },
      { "name": "Canned or jarred Non GMO paprika", "code": "50616849" },
      { "name": "Canned or jarred Non GMO purslane", "code": "50616850" },
      { "name": "Canned or jarred Non GMO raddichios", "code": "50616851" },
      { "name": "Canned or jarred Non GMO sinquas", "code": "50616852" },
      { "name": "Canned or jarred Non GMO soybeans", "code": "50616853" },
      { "name": "Canned or jarred Non GMO spoonwart", "code": "50616854" },
      {
        "name": "Canned or jarred Non GMO tassle grape-hyacinth",
        "code": "50616855"
      },
      { "name": "Canned or jarred Non GMO taro", "code": "50616856" },
      { "name": "Canned or jarred Non GMO taro leaf", "code": "50616857" },
      { "name": "Canned or jarred Non GMO taro shoot", "code": "50616858" },
      { "name": "Canned or jarred Non GMO tepeguaje", "code": "50616859" },
      { "name": "Canned or jarred Non GMO tendergreen", "code": "50616860" },
      { "name": "Canned or jarred Non GMO tindora", "code": "50616861" },
      { "name": "Canned or jarred Non GMO tree onion", "code": "50616862" },
      { "name": "Canned or jarred Non GMO udo", "code": "50616863" },
      {
        "name": "Canned or jarred Non GMO water chestnuts",
        "code": "50616864"
      },
      { "name": "Canned or jarred Non GMO yampi", "code": "50616865" },
      { "name": "Canned or jarred Non GMO yautia", "code": "50616866" },
      { "name": "Canned or jarred Non GMO yu choy", "code": "50616867" },
      { "name": "Canned or jarred Non GMO yuca", "code": "50616868" },
      { "name": "Canned or jarred Non GMO sugar peas", "code": "50616900" },
      { "name": "Canned or jarred Non GMO bikini peas", "code": "50616901" },
      { "name": "Canned or jarred Non GMO cavalier peas", "code": "50616902" },
      { "name": "Canned or jarred Non GMO daisy peas", "code": "50616903" },
      { "name": "Canned or jarred Non GMO darfon peas", "code": "50616904" },
      {
        "name": "Canned or jarred Non GMO early onward peas",
        "code": "50616905"
      },
      {
        "name": "Canned or jarred Non GMO feltham first peas",
        "code": "50616906"
      },
      {
        "name": "Canned or jarred Non GMO hurst green shaft peas",
        "code": "50616907"
      },
      {
        "name": "Canned or jarred Non GMO oregon sugar pod peas",
        "code": "50616908"
      },
      {
        "name": "Canned or jarred Non GMO prince albert peas",
        "code": "50616909"
      },
      {
        "name": "Canned or jarred Non GMO reuzensuiker peas",
        "code": "50616910"
      },
      { "name": "Non GMO fresh vegetable purees", "code": "50620000" },
      { "name": "Non GMO artichoke purees", "code": "50621500" },
      { "name": "Non GMO brittany artichoke purees", "code": "50621501" },
      { "name": "Non GMO catanese artichoke purees", "code": "50621502" },
      { "name": "Non GMO french artichoke purees", "code": "50621503" },
      { "name": "Non GMO green globe artichoke purees", "code": "50621504" },
      {
        "name": "Non GMO gros camus de bretagne artichoke purees",
        "code": "50621505"
      },
      { "name": "Non GMO midi artichoke purees", "code": "50621506" },
      { "name": "Non GMO purple globe artichoke purees", "code": "50621507" },
      {
        "name": "Non GMO purple sicilian artichoke purees",
        "code": "50621508"
      },
      { "name": "Non GMO romanesco artichoke purees", "code": "50621509" },
      { "name": "Non GMO spinoso sardo artichoke purees", "code": "50621510" },
      { "name": "Non GMO vert de laon artichoke purees", "code": "50621511" },
      {
        "name": "Non GMO violetta di chioggia artichoke purees",
        "code": "50621512"
      },
      {
        "name": "Non GMO violetto di toscana artichoke purees",
        "code": "50621513"
      },
      { "name": "Non GMO asparagus purees", "code": "50621600" },
      {
        "name": "Non GMO connover's colossal asparagus purees",
        "code": "50621601"
      },
      { "name": "Non GMO franklin asparagus purees", "code": "50621602" },
      { "name": "Non GMO giant mammoth asparagus purees", "code": "50621603" },
      { "name": "Non GMO lucullus asparagus purees", "code": "50621604" },
      {
        "name": "Non GMO martha washington asparagus purees",
        "code": "50621605"
      },
      { "name": "Non GMO avocado purees", "code": "50621700" },
      { "name": "Non GMO ajax b-7 avocado purees", "code": "50621701" },
      { "name": "Non GMO arue avocado purees", "code": "50621702" },
      { "name": "Non GMO bacon avocado purees", "code": "50621703" },
      { "name": "Non GMO benik avocado purees", "code": "50621704" },
      { "name": "Non GMO bernecker avocado purees", "code": "50621705" },
      { "name": "Non GMO beta avocado purees", "code": "50621706" },
      { "name": "Non GMO biondo avocado purees", "code": "50621707" },
      { "name": "Non GMO black prince avocado purees", "code": "50621708" },
      { "name": "Non GMO blair avocado purees", "code": "50621709" },
      { "name": "Non GMO blair booth avocado purees", "code": "50621710" },
      { "name": "Non GMO booth 1 avocado purees", "code": "50621711" },
      { "name": "Non GMO booth 3 avocado purees", "code": "50621712" },
      { "name": "Non GMO booth 5 avocado purees", "code": "50621713" },
      { "name": "Non GMO booth 7 avocado purees", "code": "50621714" },
      { "name": "Non GMO booth 8 avocado purees", "code": "50621715" },
      { "name": "Non GMO brooks 1978 avocado purees", "code": "50621716" },
      { "name": "Non GMO brookslate avocado purees", "code": "50621717" },
      { "name": "Non GMO california haas avocado purees", "code": "50621718" },
      { "name": "Non GMO catalina avocado purees", "code": "50621719" },
      { "name": "Non GMO chica avocado purees", "code": "50621720" },
      { "name": "Non GMO choquette avocado purees", "code": "50621721" },
      { "name": "Non GMO christina avocado purees", "code": "50621722" },
      { "name": "Non GMO collinson avocado purees", "code": "50621723" },
      { "name": "Non GMO donnie avocado purees", "code": "50621724" },
      {
        "name": "Non GMO dr dupuis number 2 avocado purees",
        "code": "50621725"
      },
      { "name": "Non GMO dr dupuis avocado purees", "code": "50621726" },
      { "name": "Non GMO ettinger avocado purees", "code": "50621727" },
      { "name": "Non GMO fuchs avocado purees", "code": "50621728" },
      { "name": "Non GMO fuchs gwen avocado purees", "code": "50621729" },
      { "name": "Non GMO fuerte avocado purees", "code": "50621730" },
      { "name": "Non GMO gorham avocado purees", "code": "50621731" },
      { "name": "Non GMO gossman avocado purees", "code": "50621732" },
      {
        "name": "Non GMO guatemalan seedling avocado purees",
        "code": "50621733"
      },
      { "name": "Non GMO hall avocado purees", "code": "50621734" },
      { "name": "Non GMO hardee avocado purees", "code": "50621735" },
      { "name": "Non GMO haas avocado purees", "code": "50621736" },
      { "name": "Non GMO herman avocado purees", "code": "50621737" },
      { "name": "Non GMO hickson avocado purees", "code": "50621738" },
      { "name": "Non GMO k-5 avocado purees", "code": "50621739" },
      { "name": "Non GMO k-9 avocado purees", "code": "50621740" },
      { "name": "Non GMO lamb haas avocado purees", "code": "50621741" },
      { "name": "Non GMO leona avocado purees", "code": "50621742" },
      { "name": "Non GMO leona linda avocado purees", "code": "50621743" },
      { "name": "Non GMO lisa p avocado purees", "code": "50621744" },
      { "name": "Non GMO lisa loretta avocado purees", "code": "50621745" },
      { "name": "Non GMO loretta avocado purees", "code": "50621746" },
      { "name": "Non GMO lula avocado purees", "code": "50621747" },
      { "name": "Non GMO lula macarthur avocado purees", "code": "50621748" },
      { "name": "Non GMO marcus avocado purees", "code": "50621749" },
      { "name": "Non GMO melendez avocado purees", "code": "50621750" },
      { "name": "Non GMO meya p avocado purees", "code": "50621751" },
      { "name": "Non GMO miguel p avocado purees", "code": "50621752" },
      { "name": "Non GMO monroe avocado purees", "code": "50621753" },
      { "name": "Non GMO murrieta green avocado purees", "code": "50621754" },
      { "name": "Non GMO nabal avocado purees", "code": "50621755" },
      { "name": "Non GMO nadir avocado purees", "code": "50621756" },
      { "name": "Non GMO nesbitt avocado purees", "code": "50621757" },
      { "name": "Non GMO peterson avocado purees", "code": "50621758" },
      { "name": "Non GMO pinelli avocado purees", "code": "50621759" },
      { "name": "Non GMO pinkerton avocado purees", "code": "50621760" },
      { "name": "Non GMO pollock avocado purees", "code": "50621761" },
      { "name": "Non GMO puebla avocado purees", "code": "50621762" },
      { "name": "Non GMO reed avocado purees", "code": "50621763" },
      { "name": "Non GMO rue avocado purees", "code": "50621764" },
      { "name": "Non GMO ruehle avocado purees", "code": "50621765" },
      { "name": "Non GMO ryan avocado purees", "code": "50621766" },
      { "name": "Non GMO semil 34 avocado purees", "code": "50621767" },
      { "name": "Non GMO semil 43 avocado purees", "code": "50621768" },
      { "name": "Non GMO simmonds avocado purees", "code": "50621769" },
      { "name": "Non GMO simpson avocado purees", "code": "50621770" },
      { "name": "Non GMO taylor avocado purees", "code": "50621771" },
      { "name": "Non GMO tonnage avocado purees", "code": "50621772" },
      { "name": "Non GMO tower avocado purees", "code": "50621773" },
      { "name": "Non GMO tower li avocado purees", "code": "50621774" },
      { "name": "Non GMO trapp avocado purees", "code": "50621775" },
      {
        "name": "Non GMO west indian seedling avocado purees",
        "code": "50621776"
      },
      { "name": "Non GMO wagner avocado purees", "code": "50621777" },
      { "name": "Non GMO waldin avocado purees", "code": "50621778" },
      { "name": "Non GMO wurtz avocado purees", "code": "50621779" },
      { "name": "Non GMO zio p avocado purees", "code": "50621780" },
      { "name": "Non GMO ziu avocado purees", "code": "50621781" },
      { "name": "Non GMO zutano avocado purees", "code": "50621782" },
      { "name": "Non GMO bean purees", "code": "50621800" },
      { "name": "Non GMO anasazi or aztec bean purees", "code": "50621801" },
      { "name": "Non GMO appaloosa bean purees", "code": "50621802" },
      { "name": "Non GMO azuki bean purees", "code": "50621803" },
      { "name": "Non GMO barlotti bean purees", "code": "50621804" },
      { "name": "Non GMO black appaloosa bean purees", "code": "50621805" },
      { "name": "Non GMO black bean purees", "code": "50621806" },
      { "name": "Non GMO black gram bean purees", "code": "50621807" },
      { "name": "Non GMO black shackamaxon bean purees", "code": "50621808" },
      { "name": "Non GMO blackeyed bean purees", "code": "50621809" },
      { "name": "Non GMO bobby bean purees", "code": "50621810" },
      { "name": "Non GMO bolita bean purees", "code": "50621811" },
      { "name": "Non GMO brown lazy wife bean purees", "code": "50621812" },
      { "name": "Non GMO calypso bean purees", "code": "50621813" },
      { "name": "Non GMO cannellini bean purees", "code": "50621814" },
      { "name": "Non GMO castor bean purees", "code": "50621815" },
      { "name": "Non GMO china yellow bean purees", "code": "50621816" },
      { "name": "Non GMO dragon tongue bean purees", "code": "50621817" },
      { "name": "Non GMO european soldier bean purees", "code": "50621818" },
      { "name": "Non GMO fava or broad bean purees", "code": "50621819" },
      { "name": "Non GMO flageolet bean purees", "code": "50621820" },
      {
        "name": "Non GMO french horticultural bean purees",
        "code": "50621821"
      },
      { "name": "Non GMO french navy bean purees", "code": "50621822" },
      { "name": "Non GMO giant white coco bean purees", "code": "50621823" },
      { "name": "Non GMO green bean purees", "code": "50621824" },
      { "name": "Non GMO green romano bean purees", "code": "50621825" },
      { "name": "Non GMO guar gum bean purees", "code": "50621826" },
      { "name": "Non GMO haricot bean purees", "code": "50621827" },
      { "name": "Non GMO hyacinth bean purees", "code": "50621828" },
      { "name": "Non GMO italian type bean purees", "code": "50621829" },
      { "name": "Non GMO jackson wonder bean purees", "code": "50621830" },
      { "name": "Non GMO jacob's cattle bean purees", "code": "50621831" },
      { "name": "Non GMO kentucky wonder bean purees", "code": "50621832" },
      { "name": "Non GMO kidney bean purees", "code": "50621833" },
      { "name": "Non GMO lima bean purees", "code": "50621834" },
      { "name": "Non GMO madeira madera bean purees", "code": "50621835" },
      { "name": "Non GMO marrow bean purees", "code": "50621836" },
      { "name": "Non GMO mat bean purees", "code": "50621837" },
      {
        "name": "Non GMO monstoller wild goose bean purees",
        "code": "50621838"
      },
      { "name": "Non GMO mortgage lifter bean purees", "code": "50621839" },
      { "name": "Non GMO moth bean purees", "code": "50621840" },
      { "name": "Non GMO mung bean purees", "code": "50621841" },
      { "name": "Non GMO munsi wolf bea purees", "code": "50621842" },
      { "name": "Non GMO nuna bean purees", "code": "50621843" },
      { "name": "Non GMO pinto bean purees", "code": "50621844" },
      { "name": "Non GMO pole bean purees", "code": "50621845" },
      { "name": "Non GMO runner bean purees", "code": "50621846" },
      { "name": "Non GMO string bean purees", "code": "50621847" },
      { "name": "Non GMO tamarind bean purees", "code": "50621848" },
      { "name": "Non GMO tonka bean purees", "code": "50621849" },
      { "name": "Non GMO wax bean purees", "code": "50621850" },
      { "name": "Non GMO winged bean purees", "code": "50621851" },
      { "name": "Non GMO yard long bean purees", "code": "50621852" },
      { "name": "Non GMO beet purees", "code": "50621900" },
      { "name": "Non GMO action beet purees", "code": "50621901" },
      { "name": "Non GMO albina vereduna beet purees", "code": "50621902" },
      {
        "name": "Non GMO barbabietola di chioggia beet purees",
        "code": "50621903"
      },
      { "name": "Non GMO boltardy beet purees", "code": "50621904" },
      { "name": "Non GMO bonel beet purees", "code": "50621905" },
      { "name": "Non GMO burpees golden beet purees", "code": "50621906" },
      {
        "name": "Non GMO cheltenham green top beet purees",
        "code": "50621907"
      },
      { "name": "Non GMO cheltenham mono beet purees", "code": "50621908" },
      { "name": "Non GMO chioggia beet purees", "code": "50621909" },
      { "name": "Non GMO cylindra beet purees", "code": "50621910" },
      { "name": "Non GMO d'egypte beet purees", "code": "50621911" },
      { "name": "Non GMO detroit 2 dark red beet purees", "code": "50621912" },
      {
        "name": "Non GMO detroit 2 little ball beet purees",
        "code": "50621913"
      },
      { "name": "Non GMO egyptian flat beet purees", "code": "50621914" },
      {
        "name": "Non GMO egyptian turnip rooted beet purees",
        "code": "50621915"
      },
      { "name": "Non GMO formanova beet purees", "code": "50621916" },
      { "name": "Non GMO forono beet purees", "code": "50621917" },
      { "name": "Non GMO monaco beet purees", "code": "50621918" },
      { "name": "Non GMO monogram beet purees", "code": "50621919" },
      { "name": "Non GMO pronto beet purees", "code": "50621920" },
      { "name": "Non GMO regalia beet purees", "code": "50621921" },
      { "name": "Non GMO sugar beet purees", "code": "50621922" },
      { "name": "Non GMO broccoli purees", "code": "50622000" },
      { "name": "Non GMO broccolini purees", "code": "50622001" },
      { "name": "Non GMO broccoli romanesco purees", "code": "50622002" },
      { "name": "Non GMO broccoli raab purees", "code": "50622003" },
      { "name": "Non GMO brussel sprout purees", "code": "50622100" },
      { "name": "Non GMO oliver brussel sprout purees", "code": "50622101" },
      { "name": "Non GMO peer gynt brussel sprout purees", "code": "50622102" },
      { "name": "Non GMO rampart brussel sprout purees", "code": "50622103" },
      { "name": "Non GMO rubine brussel sprout purees", "code": "50622104" },
      { "name": "Non GMO widgeon brussel sprout purees", "code": "50622105" },
      { "name": "Non GMO bunching onion purees", "code": "50622200" },
      {
        "name": "Non GMO beltsville bunching onion purees",
        "code": "50622201"
      },
      { "name": "Non GMO feast bunching onion purees", "code": "50622202" },
      { "name": "Non GMO ishikura bunching onion purees", "code": "50622203" },
      {
        "name": "Non GMO kyoto market bunching onion purees",
        "code": "50622204"
      },
      { "name": "Non GMO red beard bunching onion purees", "code": "50622205" },
      { "name": "Non GMO redmate bunching onion purees", "code": "50622206" },
      {
        "name": "Non GMO santa claus bunching onion purees",
        "code": "50622207"
      },
      { "name": "Non GMO tokyo bunching onion purees", "code": "50622208" },
      {
        "name": "Non GMO white lisbon bunching onion purees",
        "code": "50622209"
      },
      {
        "name": "Non GMO winter white bunching onion purees",
        "code": "50622210"
      },
      {
        "name": "Non GMO winter-over bunching onion purees",
        "code": "50622211"
      },
      { "name": "Non GMO cabbage purees", "code": "50622300" },
      { "name": "Non GMO black cabbage purees", "code": "50622301" },
      { "name": "Non GMO savoy cabbage purees", "code": "50622302" },
      { "name": "Non GMO skunk cabbage purees", "code": "50622303" },
      { "name": "Non GMO white cabbage purees", "code": "50622304" },
      { "name": "Non GMO cardoon purees", "code": "50622400" },
      { "name": "Non GMO lunghi cardoon purees", "code": "50622401" },
      { "name": "Non GMO gobbi cardoon purees", "code": "50622402" },
      { "name": "Non GMO carrot purees", "code": "50622500" },
      { "name": "Non GMO amsterdam carrot purees", "code": "50622501" },
      { "name": "Non GMO autumn king carrot purees", "code": "50622502" },
      { "name": "Non GMO berlicum carrot purees", "code": "50622503" },
      { "name": "Non GMO chantenay carrot purees", "code": "50622504" },
      { "name": "Non GMO nantes carrot purees", "code": "50622505" },
      { "name": "Non GMO paris market carrot purees", "code": "50622506" },
      { "name": "Non GMO cauliflower purees", "code": "50622600" },
      {
        "name": "Non GMO all the year round cauliflower purees",
        "code": "50622601"
      },
      { "name": "Non GMO alverda cauliflower purees", "code": "50622602" },
      {
        "name": "Non GMO autumn giant 3 cauliflower purees",
        "code": "50622603"
      },
      { "name": "Non GMO dok elgon cauliflower purees", "code": "50622604" },
      {
        "name": "Non GMO early snowball cauliflower purees",
        "code": "50622605"
      },
      { "name": "Non GMO limelight cauliflower purees", "code": "50622606" },
      { "name": "Non GMO minaret cauliflower purees", "code": "50622607" },
      {
        "name": "Non GMO orange bouquet cauliflower purees",
        "code": "50622608"
      },
      { "name": "Non GMO purple cape cauliflower purees", "code": "50622609" },
      { "name": "Non GMO snowball cauliflower purees", "code": "50622610" },
      {
        "name": "Non GMO walcheren winter 3 cauliflower purees",
        "code": "50622611"
      },
      { "name": "Non GMO white rock cauliflower purees", "code": "50622612" },
      { "name": "Non GMO celery purees", "code": "50622700" },
      { "name": "Non GMO celebrity celery purees", "code": "50622701" },
      { "name": "Non GMO celeriac purees", "code": "50622702" },
      { "name": "Non GMO chinese celery purees", "code": "50622703" },
      { "name": "Non GMO french dinant celery purees", "code": "50622704" },
      { "name": "Non GMO giant pink celery purees", "code": "50622705" },
      { "name": "Non GMO giant red celery purees", "code": "50622706" },
      { "name": "Non GMO giant white celery purees", "code": "50622707" },
      {
        "name": "Non GMO golden self-blanching celery purees",
        "code": "50622708"
      },
      { "name": "Non GMO greensleeves celery purees", "code": "50622709" },
      { "name": "Non GMO hopkins fenlander celery purees", "code": "50622710" },
      { "name": "Non GMO ivory tower celery purees", "code": "50622711" },
      {
        "name": "Non GMO lathom self-blanching celery purees",
        "code": "50622712"
      },
      { "name": "Non GMO soup celery d'amsterdam purees", "code": "50622713" },
      { "name": "Non GMO standard bearer celery purees", "code": "50622714" },
      { "name": "Non GMO tall utah triumph celery purees", "code": "50622715" },
      { "name": "Non GMO chard purees", "code": "50622800" },
      { "name": "Non GMO fordhook giant chard purees", "code": "50622801" },
      { "name": "Non GMO lucullus chard purees", "code": "50622802" },
      { "name": "Non GMO perpetual spinach chard purees", "code": "50622803" },
      { "name": "Non GMO rhubarb chard purees", "code": "50622804" },
      { "name": "Non GMO swiss chard purees", "code": "50622805" },
      { "name": "Non GMO vulcan chard purees", "code": "50622806" },
      { "name": "Non GMO white king chard purees", "code": "50622807" },
      { "name": "Non GMO chicory purees", "code": "50622900" },
      {
        "name": "Non GMO broad leaved batavian chicory purees",
        "code": "50622901"
      },
      {
        "name": "Non GMO en cornet de bordeaux chicory purees",
        "code": "50622902"
      },
      {
        "name": "Non GMO green curled ruffee chicory purees",
        "code": "50622903"
      },
      { "name": "Non GMO green curled chicory purees", "code": "50622904" },
      { "name": "Non GMO ione limnos chicory purees", "code": "50622905" },
      {
        "name": "Non GMO riccia pancalieri chicory purees",
        "code": "50622906"
      },
      { "name": "Non GMO salad king chicory purees", "code": "50622907" },
      { "name": "Non GMO sanda chicory purees", "code": "50622908" },
      { "name": "Non GMO scarola verde chicory purees", "code": "50622909" },
      {
        "name": "Non GMO tres fine maraichere chicory purees",
        "code": "50622910"
      },
      {
        "name": "Non GMO wallone freisee weschelkopf chicory purees",
        "code": "50622911"
      },
      { "name": "Non GMO chinese cabbage purees", "code": "50623000" },
      { "name": "Non GMO bok choy purees", "code": "50623001" },
      {
        "name": "Non GMO chinese flat-headed cabbage purees",
        "code": "50623002"
      },
      {
        "name": "Non GMO chinese flowering cabbage purees",
        "code": "50623003"
      },
      { "name": "Non GMO choy sum purees", "code": "50623004" },
      { "name": "Non GMO dwarf bok choy purees", "code": "50623005" },
      { "name": "Non GMO fengshan bok choy purees", "code": "50623006" },
      { "name": "Non GMO jade pagoda bok choy purees", "code": "50623007" },
      { "name": "Non GMO kasumi bok choy purees", "code": "50623008" },
      { "name": "Non GMO nerva bok choy purees", "code": "50623009" },
      { "name": "Non GMO rosette bok choy purees", "code": "50623010" },
      { "name": "Non GMO ruffles bok choy purees", "code": "50623011" },
      { "name": "Non GMO santo serrated leaf purees", "code": "50623012" },
      { "name": "Non GMO shanghai d bok choy purees", "code": "50623013" },
      { "name": "Non GMO shantung purees", "code": "50623014" },
      { "name": "Non GMO tip top cabbage purees", "code": "50623015" },
      { "name": "Non GMO yau choy sum purees", "code": "50623016" },
      { "name": "Non GMO chive purees", "code": "50623100" },
      { "name": "Non GMO chinese chive purees", "code": "50623101" },
      { "name": "Non GMO common chive purees", "code": "50623102" },
      { "name": "Non GMO cress purees", "code": "50623200" },
      { "name": "Non GMO land cress purees", "code": "50623201" },
      { "name": "Non GMO Nasturtium purees", "code": "50623202" },
      { "name": "Non GMO watercress purees", "code": "50623203" },
      { "name": "Non GMO wintercress purees", "code": "50623204" },
      { "name": "Non GMO cucumber purees", "code": "50623300" },
      { "name": "Non GMO arena cucumber purees", "code": "50623301" },
      { "name": "Non GMO armenian cucumber purees", "code": "50623302" },
      { "name": "Non GMO athene cucumber purees", "code": "50623303" },
      {
        "name": "Non GMO bianco lungo di parigi cucumber purees",
        "code": "50623304"
      },
      {
        "name": "Non GMO burpless tasty green cucumber purees",
        "code": "50623305"
      },
      {
        "name": "Non GMO chicago pickling cucumber purees",
        "code": "50623306"
      },
      { "name": "Non GMO crystal apple cucumber purees", "code": "50623307" },
      { "name": "Non GMO crystal lemon cucumber purees", "code": "50623308" },
      { "name": "Non GMO danimas cucumber purees", "code": "50623309" },
      { "name": "Non GMO gherkin cucumber purees", "code": "50623310" },
      { "name": "Non GMO hokus cucumber purees", "code": "50623311" },
      { "name": "Non GMO japanese cucumber purees", "code": "50623312" },
      { "name": "Non GMO karela cucumber purees", "code": "50623313" },
      { "name": "Non GMO korila cucumber purees", "code": "50623314" },
      {
        "name": "Non GMO long green improved cucumber purees",
        "code": "50623315"
      },
      { "name": "Non GMO marketmore cucumber purees", "code": "50623316" },
      { "name": "Non GMO midget cucumber purees", "code": "50623317" },
      {
        "name": "Non GMO national pickling cucumber purees",
        "code": "50623318"
      },
      { "name": "Non GMO persian cucumber purees", "code": "50623319" },
      { "name": "Non GMO telegraph cucumber purees", "code": "50623320" },
      {
        "name": "Non GMO telegraph improved cucumber purees",
        "code": "50623321"
      },
      {
        "name": "Non GMO vert de massy cornichon cucumber purees",
        "code": "50623322"
      },
      { "name": "Non GMO yamato cucumber purees", "code": "50623323" },
      { "name": "Non GMO eggplant purees", "code": "50623400" },
      { "name": "Non GMO bambino eggplant purees", "code": "50623401" },
      { "name": "Non GMO black beauty eggplant purees", "code": "50623402" },
      { "name": "Non GMO black enorma eggplant purees", "code": "50623403" },
      { "name": "Non GMO chinese eggplant purees", "code": "50623404" },
      { "name": "Non GMO easter egg eggplant purees", "code": "50623405" },
      { "name": "Non GMO filipino eggplant purees", "code": "50623406" },
      { "name": "Non GMO florida market eggplant purees", "code": "50623407" },
      { "name": "Non GMO indian eggplant purees", "code": "50623408" },
      { "name": "Non GMO italian eggplant purees", "code": "50623409" },
      { "name": "Non GMO japanese eggplant purees", "code": "50623410" },
      { "name": "Non GMO long purple eggplant purees", "code": "50623411" },
      { "name": "Non GMO long striped eggplant purees", "code": "50623412" },
      { "name": "Non GMO moneymaker eggplant purees", "code": "50623413" },
      { "name": "Non GMO ova eggplant purees", "code": "50623414" },
      { "name": "Non GMO pea eggplant purees", "code": "50623415" },
      { "name": "Non GMO short tom eggplant purees", "code": "50623416" },
      { "name": "Non GMO sicilian eggplant purees", "code": "50623417" },
      { "name": "Non GMO thai eggplant purees", "code": "50623418" },
      {
        "name": "Non GMO violette di firenze eggplant purees",
        "code": "50623419"
      },
      { "name": "Non GMO white eggplant purees", "code": "50623420" },
      { "name": "Non GMO endive purees", "code": "50623500" },
      { "name": "Non GMO brussels witloof endive purees", "code": "50623501" },
      { "name": "Non GMO castelfranco endive purees", "code": "50623502" },
      {
        "name": "Non GMO catalogna di galatina endive purees",
        "code": "50623503"
      },
      { "name": "Non GMO chioggia endive purees", "code": "50623504" },
      { "name": "Non GMO grumolo verde endive purees", "code": "50623505" },
      {
        "name": "Non GMO large rooted magdeburg endive purees",
        "code": "50623506"
      },
      {
        "name": "Non GMO palla rossa zorzi precoce endive purees",
        "code": "50623507"
      },
      { "name": "Non GMO radice amare endive purees", "code": "50623508" },
      { "name": "Non GMO rossa di treviso endive purees", "code": "50623509" },
      { "name": "Non GMO rossa di verona endive purees", "code": "50623510" },
      { "name": "Non GMO soncino endive purees", "code": "50623511" },
      { "name": "Non GMO sugarhat endive purees", "code": "50623512" },
      { "name": "Non GMO verona endive purees", "code": "50623513" },
      { "name": "Non GMO witloof zoom endive purees", "code": "50623514" },
      { "name": "Non GMO fennel purees", "code": "50623600" },
      { "name": "Non GMO cantino fennel purees", "code": "50623601" },
      { "name": "Non GMO fino fennel purees", "code": "50623602" },
      { "name": "Non GMO herald fennel purees", "code": "50623603" },
      { "name": "Non GMO perfection fennel purees", "code": "50623604" },
      { "name": "Non GMO sirio fennel purees", "code": "50623605" },
      { "name": "Non GMO sweet florence fennel purees", "code": "50623606" },
      { "name": "Non GMO tardo fennel purees", "code": "50623607" },
      { "name": "Non GMO garlic purees", "code": "50623700" },
      { "name": "Non GMO california late garlic purees", "code": "50623701" },
      { "name": "Non GMO chinese garlic stem purees", "code": "50623702" },
      { "name": "Non GMO garlic chive purees", "code": "50623703" },
      { "name": "Non GMO germidor garlic purees", "code": "50623704" },
      { "name": "Non GMO long keeper garlic purees", "code": "50623705" },
      { "name": "Non GMO ramson garlic purees", "code": "50623706" },
      { "name": "Non GMO rocambole garlic purees", "code": "50623707" },
      { "name": "Non GMO rose de lautrec garlic purees", "code": "50623708" },
      { "name": "Non GMO solent wight garlic purees", "code": "50623709" },
      { "name": "Non GMO spanish morado garlic purees", "code": "50623710" },
      { "name": "Non GMO venetian italian garlic purees", "code": "50623711" },
      { "name": "Non GMO gourd purees", "code": "50623800" },
      { "name": "Non GMO angled loofah purees", "code": "50623801" },
      { "name": "Non GMO bitter gourd purees", "code": "50623802" },
      { "name": "Non GMO bottle gourd purees", "code": "50623803" },
      { "name": "Non GMO calabash gourd purees", "code": "50623804" },
      { "name": "Non GMO fuzzy hairy melon purees", "code": "50623805" },
      { "name": "Non GMO musky gourd purees", "code": "50623806" },
      { "name": "Non GMO smooth loofah purees", "code": "50623807" },
      { "name": "Non GMO snake gourd purees", "code": "50623808" },
      { "name": "Non GMO spiny bitter gourd purees", "code": "50623809" },
      { "name": "Non GMO tinda gourd purees", "code": "50623810" },
      { "name": "Non GMO tindoori gourd purees", "code": "50623811" },
      { "name": "Non GMO green pea purees", "code": "50623900" },
      { "name": "Non GMO china pea purees", "code": "50623901" },
      { "name": "Non GMO english pea purees", "code": "50623902" },
      { "name": "Non GMO garden pea purees", "code": "50623903" },
      { "name": "Non GMO snow pea purees", "code": "50623904" },
      { "name": "Non GMO sugar snap pea purees", "code": "50623905" },
      { "name": "Non GMO herb purees", "code": "50624000" },
      { "name": "Non GMO basil purees", "code": "50624001" },
      { "name": "Non GMO bay leaf purees", "code": "50624002" },
      { "name": "Non GMO broage purees", "code": "50624003" },
      { "name": "Non GMO caraway purees", "code": "50624004" },
      { "name": "Non GMO chervil purees", "code": "50624005" },
      { "name": "Non GMO cilantro purees", "code": "50624006" },
      { "name": "Non GMO cipolino purees", "code": "50624007" },
      { "name": "Non GMO curry leaf purees", "code": "50624008" },
      { "name": "Non GMO dill purees", "code": "50624009" },
      { "name": "Non GMO epazote purees", "code": "50624010" },
      { "name": "Non GMO fenugreek purees", "code": "50624011" },
      { "name": "Non GMO lemon gras purees", "code": "50624012" },
      { "name": "Non GMO marjoram purees", "code": "50624013" },
      { "name": "Non GMO mint purees", "code": "50624014" },
      { "name": "Non GMO oregano purees", "code": "50624015" },
      { "name": "Non GMO papalo purees", "code": "50624016" },
      { "name": "Non GMO pepicha purees", "code": "50624017" },
      { "name": "Non GMO perilla purees", "code": "50624018" },
      { "name": "Non GMO recao purees", "code": "50624019" },
      { "name": "Non GMO rosemary purees", "code": "50624020" },
      { "name": "Non GMO sage purees", "code": "50624021" },
      { "name": "Non GMO salsify purees", "code": "50624022" },
      { "name": "Non GMO savory purees", "code": "50624023" },
      { "name": "Non GMO tarragon purees", "code": "50624024" },
      { "name": "Non GMO thyme purees", "code": "50624025" },
      { "name": "Non GMO tumeric purees", "code": "50624026" },
      { "name": "Non GMO verdulaga purees", "code": "50624027" },
      { "name": "Non GMO kale purees", "code": "50624100" },
      { "name": "Non GMO curly kale purees", "code": "50624101" },
      { "name": "Non GMO collard green purees", "code": "50624102" },
      { "name": "Non GMO kohlrabi purees", "code": "50624200" },
      { "name": "Non GMO azur star kohlrabi purees", "code": "50624201" },
      { "name": "Non GMO green vienna kohlrabi purees", "code": "50624202" },
      { "name": "Non GMO lanro kohlrabi purees", "code": "50624203" },
      { "name": "Non GMO purple vienna kohlrabi purees", "code": "50624204" },
      { "name": "Non GMO rowel trero kohlrabi purees", "code": "50624205" },
      { "name": "Non GMO white vienna kohlrabi purees", "code": "50624206" },
      { "name": "Non GMO leek purees", "code": "50624300" },
      { "name": "Non GMO autumn giant-cobra leek purees", "code": "50624301" },
      { "name": "Non GMO autumn mammoth 2 leek purees", "code": "50624302" },
      { "name": "Non GMO bleu de solaise leek purees", "code": "50624303" },
      { "name": "Non GMO cortina leek purees", "code": "50624304" },
      { "name": "Non GMO prelina leek purees", "code": "50624305" },
      { "name": "Non GMO wild leek ramp purees", "code": "50624306" },
      { "name": "Non GMO lentil purees", "code": "50624400" },
      { "name": "Non GMO beluga lentil purees", "code": "50624401" },
      { "name": "Non GMO french green lentil purees", "code": "50624402" },
      { "name": "Non GMO green lentil purees", "code": "50624403" },
      { "name": "Non GMO petite crimson lentil purees", "code": "50624404" },
      { "name": "Non GMO spanish pardina lentil purees", "code": "50624405" },
      { "name": "Non GMO split red lentil purees", "code": "50624406" },
      { "name": "Non GMO split yellow lentil purees", "code": "50624407" },
      { "name": "Non GMO tarahumara pinks lentil purees", "code": "50624408" },
      { "name": "Non GMO lettuce purees", "code": "50624500" },
      { "name": "Non GMO bibb lettuce purees", "code": "50624501" },
      { "name": "Non GMO boston lettuce purees", "code": "50624502" },
      { "name": "Non GMO frisee lettuce purees", "code": "50624503" },
      { "name": "Non GMO lolla rossa lettuce purees", "code": "50624504" },
      { "name": "Non GMO mesculin mix lettuce purees", "code": "50624505" },
      { "name": "Non GMO mizuna lettuce purees", "code": "50624506" },
      { "name": "Non GMO red leaf lettuce purees", "code": "50624507" },
      { "name": "Non GMO red oak leaf lettuce purees", "code": "50624508" },
      { "name": "Non GMO ruby romaine lettuce purees", "code": "50624509" },
      { "name": "Non GMO baby red romaine lettuce purees", "code": "50624510" },
      { "name": "Non GMO butterhead lettuce purees", "code": "50624511" },
      { "name": "Non GMO chinese lettuce purees", "code": "50624512" },
      { "name": "Non GMO crisphead lettuce purees", "code": "50624513" },
      { "name": "Non GMO green leaf lettuce purees", "code": "50624514" },
      { "name": "Non GMO iceberg lettuce purees", "code": "50624515" },
      { "name": "Non GMO lamb\u2019s lettuce purees", "code": "50624516" },
      { "name": "Non GMO looseleaf lettuce purees", "code": "50624517" },
      { "name": "Non GMO mache lettuce purees", "code": "50624518" },
      { "name": "Non GMO red boston lettuce purees", "code": "50624519" },
      { "name": "Non GMO red headed lettuce purees", "code": "50624520" },
      { "name": "Non GMO romaine lettuce purees", "code": "50624521" },
      {
        "name": "Non GMO russian red mustard lettuce purees",
        "code": "50624522"
      },
      { "name": "Non GMO tatsoi lettuce purees", "code": "50624523" },
      { "name": "Non GMO malanga purees", "code": "50624600" },
      { "name": "Non GMO blanca malanga purees", "code": "50624601" },
      { "name": "Non GMO coco malanga purees", "code": "50624602" },
      { "name": "Non GMO eddoes malanga purees", "code": "50624603" },
      { "name": "Non GMO islena malanga purees", "code": "50624604" },
      { "name": "Non GMO lila malanga purees", "code": "50624605" },
      { "name": "Non GMO amarilla malanga purees", "code": "50624606" },
      { "name": "Non GMO mushroom purees", "code": "50624700" },
      { "name": "Non GMO black trumpet mushroom purees", "code": "50624701" },
      { "name": "Non GMO brown mushroom purees", "code": "50624702" },
      { "name": "Non GMO champinion mushroom purees", "code": "50624703" },
      { "name": "Non GMO chanterelle mushroom purees", "code": "50624704" },
      { "name": "Non GMO cremini mushroom purees", "code": "50624705" },
      { "name": "Non GMO enoki mushroom purees", "code": "50624706" },
      { "name": "Non GMO hedge hog mushroom purees", "code": "50624707" },
      {
        "name": "Non GMO hen of the woods mushroom purees",
        "code": "50624708"
      },
      { "name": "Non GMO lobster mushroom purees", "code": "50624709" },
      { "name": "Non GMO morels mushroom purees", "code": "50624710" },
      { "name": "Non GMO oyster mushroom purees", "code": "50624711" },
      { "name": "Non GMO pleurotus mushroom purees", "code": "50624712" },
      { "name": "Non GMO pompom mushroom purees", "code": "50624713" },
      { "name": "Non GMO porcieni mushroom purees", "code": "50624714" },
      { "name": "Non GMO portobella mushroom purees", "code": "50624715" },
      { "name": "Non GMO shiitake mushroom purees", "code": "50624716" },
      { "name": "Non GMO shimeji mushroom purees", "code": "50624717" },
      { "name": "Non GMO st george's mushroom purees", "code": "50624718" },
      { "name": "Non GMO white mushroom purees", "code": "50624719" },
      { "name": "Non GMO white trumpet mushroom purees", "code": "50624720" },
      { "name": "Non GMO woodear mushroom purees", "code": "50624721" },
      { "name": "Non GMO mustard purees", "code": "50624800" },
      { "name": "Non GMO bamboo mustard purees", "code": "50624801" },
      { "name": "Non GMO garlic mustard purees", "code": "50624802" },
      { "name": "Non GMO giantleafed mustard purees", "code": "50624803" },
      { "name": "Non GMO red in snow mustard purees", "code": "50624804" },
      { "name": "Non GMO southern mustard purees", "code": "50624805" },
      { "name": "Non GMO wrapped heart mustard purees", "code": "50624806" },
      { "name": "Non GMO nightshade purees", "code": "50624900" },
      { "name": "Non GMO chinese lantern purees", "code": "50624901" },
      { "name": "Non GMO garden huckleberry purees", "code": "50624902" },
      { "name": "Non GMO naranjilla purees", "code": "50624903" },
      { "name": "Non GMO tomatillo purees", "code": "50624904" },
      { "name": "Non GMO okra purees", "code": "50625000" },
      { "name": "Non GMO artist okra purees", "code": "50625001" },
      { "name": "Non GMO burgundy okra purees", "code": "50625002" },
      { "name": "Non GMO clemson spineless okra purees", "code": "50625003" },
      {
        "name": "Non GMO dwarf green long pod okra purees",
        "code": "50625004"
      },
      {
        "name": "Non GMO mammoth spineless long pod okra purees",
        "code": "50625005"
      },
      { "name": "Non GMO red velvet okra purees", "code": "50625006" },
      {
        "name": "Non GMO star of david heirloom okra purees",
        "code": "50625007"
      },
      { "name": "Non GMO onion purees", "code": "50625100" },
      { "name": "Non GMO albion onion purees", "code": "50625101" },
      { "name": "Non GMO alisa craig onion purees", "code": "50625102" },
      { "name": "Non GMO boiling onion purees", "code": "50625103" },
      { "name": "Non GMO buffalo onion purees", "code": "50625104" },
      { "name": "Non GMO bulb onion purees", "code": "50625105" },
      { "name": "Non GMO creaming onion purees", "code": "50625106" },
      { "name": "Non GMO express yellow o-x onion purees", "code": "50625107" },
      { "name": "Non GMO kelsae onion purees", "code": "50625108" },
      {
        "name": "Non GMO marshalls giant fen globe onion purees",
        "code": "50625109"
      },
      { "name": "Non GMO pearl onion purees", "code": "50625110" },
      { "name": "Non GMO red baron onion purees", "code": "50625111" },
      { "name": "Non GMO red onion purees", "code": "50625112" },
      { "name": "Non GMO rijnsberger onion purees", "code": "50625113" },
      {
        "name": "Non GMO senshyu semi-globe yellow onion purees",
        "code": "50625114"
      },
      { "name": "Non GMO sturon onion purees", "code": "50625115" },
      { "name": "Non GMO stuttgarter giant onion purees", "code": "50625116" },
      { "name": "Non GMO sweet onion purees", "code": "50625117" },
      {
        "name": "Non GMO torpedo or red italian onion purees",
        "code": "50625118"
      },
      { "name": "Non GMO red storage onion purees", "code": "50625119" },
      { "name": "Non GMO white storage onion purees", "code": "50625120" },
      { "name": "Non GMO yellow storage onion purees", "code": "50625121" },
      { "name": "Non GMO peanut purees", "code": "50625200" },
      {
        "name": "Non GMO bambarra groundnut peanut purees",
        "code": "50625201"
      },
      { "name": "Non GMO florunner peanut purees", "code": "50625202" },
      {
        "name": "Non GMO hausa kersting's ground nut peanut purees",
        "code": "50625203"
      },
      { "name": "Non GMO spanish peanut purees", "code": "50625204" },
      { "name": "Non GMO valencia peanut purees", "code": "50625205" },
      { "name": "Non GMO virginia peanut purees", "code": "50625206" },
      { "name": "Non GMO pea purees", "code": "50625300" },
      { "name": "Non GMO purple hull pea purees", "code": "50625301" },
      { "name": "Non GMO pinkeye pea purees", "code": "50625302" },
      { "name": "Non GMO crowder pea purees", "code": "50625303" },
      { "name": "Non GMO white acre pea purees", "code": "50625304" },
      { "name": "Non GMO blackeyed pea purees", "code": "50625305" },
      { "name": "Non GMO zipper cream pea purees", "code": "50625306" },
      { "name": "Non GMO pepper purees", "code": "50625400" },
      { "name": "Non GMO ajies pepper purees", "code": "50625401" },
      { "name": "Non GMO arbol pepper purees", "code": "50625402" },
      { "name": "Non GMO cheese pepper purees", "code": "50625403" },
      { "name": "Non GMO chilaca pepper purees", "code": "50625404" },
      { "name": "Non GMO cubanelles pepper purees", "code": "50625405" },
      { "name": "Non GMO fresno pepper purees", "code": "50625406" },
      { "name": "Non GMO kapia pepper purees", "code": "50625407" },
      { "name": "Non GMO korean pepper purees", "code": "50625408" },
      { "name": "Non GMO manzano pepper purees", "code": "50625409" },
      { "name": "Non GMO melrose pepper purees", "code": "50625410" },
      { "name": "Non GMO yellow chile pepper purees", "code": "50625411" },
      { "name": "Non GMO aji dulces pepper purees", "code": "50625412" },
      { "name": "Non GMO anaheim pepper purees", "code": "50625413" },
      { "name": "Non GMO ancho pepper purees", "code": "50625414" },
      { "name": "Non GMO bell pepper purees", "code": "50625415" },
      { "name": "Non GMO cascabel pepper purees", "code": "50625416" },
      { "name": "Non GMO cayenne pepper purees", "code": "50625417" },
      { "name": "Non GMO cherry hots pepper purees", "code": "50625418" },
      { "name": "Non GMO chiltecpin pepper purees", "code": "50625419" },
      { "name": "Non GMO finger hot pepper purees", "code": "50625420" },
      { "name": "Non GMO guajillo pepper purees", "code": "50625421" },
      { "name": "Non GMO guerro pepper purees", "code": "50625422" },
      { "name": "Non GMO habanero pepper purees", "code": "50625423" },
      { "name": "Non GMO hungarian wax pepper purees", "code": "50625424" },
      { "name": "Non GMO jalape\u00f1o pepper purees", "code": "50625425" },
      { "name": "Non GMO long hot pepper purees", "code": "50625426" },
      { "name": "Non GMO mirasol pepper purees", "code": "50625427" },
      { "name": "Non GMO pasilla pepper purees", "code": "50625428" },
      { "name": "Non GMO peperoncini pepper purees", "code": "50625429" },
      { "name": "Non GMO pequin pepper purees", "code": "50625430" },
      { "name": "Non GMO pimiento pepper purees", "code": "50625431" },
      { "name": "Non GMO poblano pepper purees", "code": "50625432" },
      { "name": "Non GMO scotch bonnet pepper purees", "code": "50625433" },
      { "name": "Non GMO serrano pepper purees", "code": "50625434" },
      { "name": "Non GMO tabasco pepper purees", "code": "50625435" },
      { "name": "Non GMO tai pepper purees", "code": "50625436" },
      { "name": "Non GMO tepin pepper purees", "code": "50625437" },
      { "name": "Non GMO potato purees", "code": "50625500" },
      { "name": "Non GMO long white potato purees", "code": "50625501" },
      { "name": "Non GMO round white potato purees", "code": "50625502" },
      { "name": "Non GMO round red potato purees", "code": "50625503" },
      { "name": "Non GMO russet potato purees", "code": "50625504" },
      { "name": "Non GMO purple potato purees", "code": "50625505" },
      { "name": "Non GMO yellow potato purees", "code": "50625506" },
      { "name": "Non GMO new potato purees", "code": "50625507" },
      { "name": "Non GMO specialty potato purees", "code": "50625508" },
      { "name": "Non GMO rutabaga purees", "code": "50625600" },
      { "name": "Non GMO acme rutabaga purees", "code": "50625601" },
      { "name": "Non GMO angela rutabaga purees", "code": "50625602" },
      { "name": "Non GMO best of all rutabaga purees", "code": "50625603" },
      { "name": "Non GMO marian rutabaga purees", "code": "50625604" },
      { "name": "Non GMO sea vegetable purees", "code": "50625700" },
      { "name": "Non GMO agar-agar purees", "code": "50625701" },
      { "name": "Non GMO arame purees", "code": "50625702" },
      { "name": "Non GMO dulse purees", "code": "50625703" },
      { "name": "Non GMO haricot vert de mer purees", "code": "50625704" },
      { "name": "Non GMO hijiki purees", "code": "50625705" },
      { "name": "Non GMO irish moss purees", "code": "50625706" },
      { "name": "Non GMO kelp purees", "code": "50625707" },
      { "name": "Non GMO laver purees", "code": "50625708" },
      { "name": "Non GMO nori purees", "code": "50625709" },
      { "name": "Non GMO red alga purees", "code": "50625710" },
      { "name": "Non GMO sea kale purees", "code": "50625711" },
      { "name": "Non GMO sea lettuce purees", "code": "50625712" },
      { "name": "Non GMO seaweed purees", "code": "50625713" },
      { "name": "Non GMO spirulina purees", "code": "50625714" },
      { "name": "Non GMO susabi nori purees", "code": "50625715" },
      { "name": "Non GMO wakame purees", "code": "50625716" },
      { "name": "Non GMO shallot purees", "code": "50625800" },
      { "name": "Non GMO atlantic shallot purees", "code": "50625801" },
      { "name": "Non GMO creation shallot purees", "code": "50625802" },
      {
        "name": "Non GMO drittler white nest shallot purees",
        "code": "50625803"
      },
      {
        "name": "Non GMO giant yellow improved shallot purees",
        "code": "50625804"
      },
      { "name": "Non GMO golden gourmet shallot purees", "code": "50625805" },
      {
        "name": "Non GMO grise de bagnolet shallot purees",
        "code": "50625806"
      },
      { "name": "Non GMO hative de niort shallot purees", "code": "50625807" },
      { "name": "Non GMO pikant shallot purees", "code": "50625808" },
      { "name": "Non GMO red potato onion purees", "code": "50625809" },
      { "name": "Non GMO sante shallot purees", "code": "50625810" },
      { "name": "Non GMO topper shallot purees", "code": "50625811" },
      { "name": "Non GMO sorrel purees", "code": "50625900" },
      { "name": "Non GMO dock sorrel purees", "code": "50625901" },
      { "name": "Non GMO garden sorrel purees", "code": "50625902" },
      { "name": "Non GMO sheep sorrel purees", "code": "50625903" },
      { "name": "Non GMO wood sorrel purees", "code": "50625904" },
      { "name": "Non GMO spinach purees", "code": "50626000" },
      { "name": "Non GMO america spinach purees", "code": "50626001" },
      { "name": "Non GMO bloomsdale spinach purees", "code": "50626002" },
      { "name": "Non GMO giant winter spinach purees", "code": "50626003" },
      { "name": "Non GMO horenso spinach purees", "code": "50626004" },
      { "name": "Non GMO lamb's quarters spinach purees", "code": "50626005" },
      { "name": "Non GMO malabar spinach purees", "code": "50626006" },
      { "name": "Non GMO medania spinach purees", "code": "50626007" },
      { "name": "Non GMO orach spinach purees", "code": "50626008" },
      { "name": "Non GMO savoy spinach purees", "code": "50626009" },
      { "name": "Non GMO sigmaleaf spinach purees", "code": "50626010" },
      { "name": "Non GMO space spinach purees", "code": "50626011" },
      { "name": "Non GMO trinidad spinach purees", "code": "50626012" },
      { "name": "Non GMO wild spinach purees", "code": "50626013" },
      { "name": "Non GMO new zealand spinach purees", "code": "50626014" },
      { "name": "Non GMO iceplant spinach purees", "code": "50626015" },
      {
        "name": "Non GMO summer squash and summer pumpkin purees",
        "code": "50626100"
      },
      { "name": "Non GMO boston marrow squash purees", "code": "50626101" },
      { "name": "Non GMO butternut squash purees", "code": "50626102" },
      { "name": "Non GMO costata romanesca squash purees", "code": "50626103" },
      { "name": "Non GMO crookneck squash purees", "code": "50626104" },
      { "name": "Non GMO cucuzza squash purees", "code": "50626105" },
      { "name": "Non GMO delicata squash purees", "code": "50626106" },
      { "name": "Non GMO delicious squash purees", "code": "50626107" },
      {
        "name": "Non GMO early golden summer crookneck squash purees",
        "code": "50626108"
      },
      {
        "name": "Non GMO early prolific straight neck squash purees",
        "code": "50626109"
      },
      { "name": "Non GMO gold squash purees", "code": "50626110" },
      { "name": "Non GMO jack be little squash purees", "code": "50626111" },
      { "name": "Non GMO kentucky field squash purees", "code": "50626112" },
      { "name": "Non GMO marrow squash purees", "code": "50626113" },
      { "name": "Non GMO middle eastern squash purees", "code": "50626114" },
      { "name": "Non GMO miniature squash purees", "code": "50626115" },
      { "name": "Non GMO orangetti squash purees", "code": "50626116" },
      { "name": "Non GMO pattypan squash purees", "code": "50626117" },
      { "name": "Non GMO rondini squash purees", "code": "50626118" },
      { "name": "Non GMO round squash purees", "code": "50626119" },
      { "name": "Non GMO spaghetti squash purees", "code": "50626120" },
      { "name": "Non GMO stripetti squash purees", "code": "50626121" },
      { "name": "Non GMO sugar loaf squash purees", "code": "50626122" },
      { "name": "Non GMO sweet dumpling squash purees", "code": "50626123" },
      { "name": "Non GMO triple treat squash purees", "code": "50626124" },
      { "name": "Non GMO waltham butternut squash purees", "code": "50626125" },
      {
        "name": "Non GMO yellow bush scallop squash purees",
        "code": "50626126"
      },
      {
        "name": "Non GMO yellow straightneck squash purees",
        "code": "50626127"
      },
      { "name": "Non GMO zephyr squash purees", "code": "50626128" },
      { "name": "Non GMO zucchini squash purees", "code": "50626129" },
      { "name": "Non GMO sweet potato purees", "code": "50626200" },
      { "name": "Non GMO beauregard sweet potato purees", "code": "50626201" },
      { "name": "Non GMO centennial sweet potato purees", "code": "50626202" },
      { "name": "Non GMO diane sweet potato purees", "code": "50626203" },
      { "name": "Non GMO garnet sweet potato purees", "code": "50626204" },
      { "name": "Non GMO georgia red sweet potato purees", "code": "50626205" },
      { "name": "Non GMO goldensweet sweet potato purees", "code": "50626206" },
      { "name": "Non GMO hanna sweet potato purees", "code": "50626207" },
      { "name": "Non GMO japanese sweet potato purees", "code": "50626208" },
      { "name": "Non GMO jersey sweet potato purees", "code": "50626209" },
      { "name": "Non GMO jewel sweet potato purees", "code": "50626210" },
      {
        "name": "Non GMO maryland red sweet potato purees",
        "code": "50626211"
      },
      { "name": "Non GMO nemagold sweet potato purees", "code": "50626212" },
      { "name": "Non GMO o'henry sweet potato purees", "code": "50626213" },
      { "name": "Non GMO okinawan sweet potato purees", "code": "50626214" },
      { "name": "Non GMO orange sweet potato purees", "code": "50626215" },
      { "name": "Non GMO oriental sweet potato purees", "code": "50626216" },
      { "name": "Non GMO red jersey sweet potato purees", "code": "50626217" },
      { "name": "Non GMO red mar sweet potato purees", "code": "50626218" },
      { "name": "Non GMO redglow sweet potato purees", "code": "50626219" },
      {
        "name": "Non GMO yellow jersey sweet potato purees",
        "code": "50626220"
      },
      { "name": "Non GMO tomato purees", "code": "50626300" },
      { "name": "Non GMO ailsa craig tomato purees", "code": "50626301" },
      { "name": "Non GMO alicante tomato purees", "code": "50626302" },
      { "name": "Non GMO black plum tomato purees", "code": "50626303" },
      { "name": "Non GMO brandywine tomato purees", "code": "50626304" },
      { "name": "Non GMO cherry belle tomato purees", "code": "50626305" },
      { "name": "Non GMO cherry tomato purees", "code": "50626306" },
      { "name": "Non GMO delicious tomato purees", "code": "50626307" },
      { "name": "Non GMO dombito tomato purees", "code": "50626308" },
      {
        "name": "Non GMO gardener's delight tomato purees",
        "code": "50626309"
      },
      { "name": "Non GMO grape tomato purees", "code": "50626310" },
      { "name": "Non GMO green tomato purees", "code": "50626311" },
      { "name": "Non GMO marmande super tomato purees", "code": "50626312" },
      {
        "name": "Non GMO marvel striped traditional tomato purees",
        "code": "50626313"
      },
      { "name": "Non GMO minibel tomato purees", "code": "50626314" },
      { "name": "Non GMO oaxacan pink tomato purees", "code": "50626315" },
      { "name": "Non GMO red alert tomato purees", "code": "50626316" },
      { "name": "Non GMO roma vf tomato purees", "code": "50626317" },
      { "name": "Non GMO san marzano tomato purees", "code": "50626318" },
      { "name": "Non GMO shirley tomato purees", "code": "50626319" },
      { "name": "Non GMO siberia tomato purees", "code": "50626320" },
      { "name": "Non GMO super beefsteak tomato purees", "code": "50626321" },
      { "name": "Non GMO tigerella tomato purees", "code": "50626322" },
      { "name": "Non GMO tiny tim tomato purees", "code": "50626323" },
      { "name": "Non GMO tumbler tomato purees", "code": "50626324" },
      { "name": "Non GMO yellow cocktail tomato purees", "code": "50626325" },
      {
        "name": "Non GMO yellow pear-shaped tomato purees",
        "code": "50626326"
      },
      { "name": "Non GMO yellow perfection tomato purees", "code": "50626327" },
      { "name": "Non GMO turnip green purees", "code": "50626400" },
      { "name": "Non GMO green globe turnip purees", "code": "50626401" },
      { "name": "Non GMO golden ball turnip purees", "code": "50626402" },
      { "name": "Non GMO manchester market turnip purees", "code": "50626403" },
      { "name": "Non GMO purple top milan turnip purees", "code": "50626404" },
      { "name": "Non GMO purple top white turnip purees", "code": "50626405" },
      { "name": "Non GMO snowball turnip purees", "code": "50626406" },
      { "name": "Non GMO tokyo turnip purees", "code": "50626407" },
      { "name": "Non GMO tokyo cross turnip purees", "code": "50626408" },
      {
        "name": "Non GMO winter squash and winter pumpkin purees",
        "code": "50626500"
      },
      { "name": "Non GMO acorn squash purees", "code": "50626501" },
      { "name": "Non GMO atlantic giant squash purees", "code": "50626502" },
      { "name": "Non GMO banana pink squash purees", "code": "50626503" },
      { "name": "Non GMO big max squash purees", "code": "50626504" },
      { "name": "Non GMO calabaza squash purees", "code": "50626505" },
      { "name": "Non GMO carnival squash purees", "code": "50626506" },
      { "name": "Non GMO cheese pumpkin purees", "code": "50626507" },
      { "name": "Non GMO crown prince squash purees", "code": "50626508" },
      { "name": "Non GMO curcibita squash purees", "code": "50626509" },
      { "name": "Non GMO cushaw squash purees", "code": "50626510" },
      { "name": "Non GMO giant pumpkin squash purees", "code": "50626511" },
      { "name": "Non GMO hubbard squash purees", "code": "50626512" },
      { "name": "Non GMO jarrahdale squash purees", "code": "50626513" },
      { "name": "Non GMO kabocha squash purees", "code": "50626514" },
      { "name": "Non GMO queensland blue squash purees", "code": "50626515" },
      {
        "name": "Non GMO rouge vif d'etampes squash purees",
        "code": "50626516"
      },
      { "name": "Non GMO turk's turban squash purees", "code": "50626517" },
      { "name": "Non GMO valenciano squash purees", "code": "50626518" },
      { "name": "Non GMO warted hubbard squash purees", "code": "50626519" },
      {
        "name": "Non GMO whangaparoa crown pumpkin purees",
        "code": "50626520"
      },
      { "name": "Non GMO yam purees", "code": "50626600" },
      { "name": "Non GMO african bitter yam purees", "code": "50626601" },
      { "name": "Non GMO asiatic bitter yam purees", "code": "50626602" },
      { "name": "Non GMO chinese yam purees", "code": "50626603" },
      { "name": "Non GMO globe yam purees", "code": "50626604" },
      { "name": "Non GMO greater yam purees", "code": "50626605" },
      { "name": "Non GMO japanese yam purees", "code": "50626606" },
      { "name": "Non GMO lesser yam purees", "code": "50626607" },
      { "name": "Non GMO potato yam purees", "code": "50626608" },
      { "name": "Non GMO white guinea yam purees", "code": "50626609" },
      { "name": "Non GMO yellow guinea yam purees", "code": "50626610" },
      { "name": "Non GMO corn purees", "code": "50626700" },
      { "name": "Non GMO aloha corn purees", "code": "50626701" },
      { "name": "Non GMO alpine corn purees", "code": "50626702" },
      { "name": "Non GMO ambrosia corn purees", "code": "50626703" },
      { "name": "Non GMO argent corn purees", "code": "50626704" },
      { "name": "Non GMO aspen corn purees", "code": "50626705" },
      { "name": "Non GMO avalanche corn purees", "code": "50626706" },
      { "name": "Non GMO biqueen corn purees", "code": "50626707" },
      { "name": "Non GMO bodacious corn purees", "code": "50626708" },
      { "name": "Non GMO butter and sugar corn purees", "code": "50626709" },
      { "name": "Non GMO calico belle corn purees", "code": "50626710" },
      { "name": "Non GMO camelot corn purees", "code": "50626711" },
      {
        "name": "Non GMO challengercrisp \u2018n sweet corn purees",
        "code": "50626712"
      },
      { "name": "Non GMO champ corn purees", "code": "50626713" },
      { "name": "Non GMO cotton candy corn purees", "code": "50626714" },
      { "name": "Non GMO d\u2019artagnan corn purees", "code": "50626715" },
      { "name": "Non GMO dazzle corn purees", "code": "50626716" },
      { "name": "Non GMO diamond and gold corn purees", "code": "50626717" },
      { "name": "Non GMO divinity corn purees", "code": "50626718" },
      { "name": "Non GMO double delight corn purees", "code": "50626719" },
      { "name": "Non GMO double gem corn purees", "code": "50626720" },
      { "name": "Non GMO earlivee corn purees", "code": "50626721" },
      { "name": "Non GMO early xtra sweet corn purees", "code": "50626722" },
      { "name": "Non GMO excel corn purees", "code": "50626723" },
      { "name": "Non GMO golden cross bantam corn purees", "code": "50626724" },
      { "name": "Non GMO honey and cream corn purees", "code": "50626725" },
      { "name": "Non GMO honey \u2018n pearl corn purees", "code": "50626726" },
      { "name": "Non GMO how sweet it is corn purees", "code": "50626727" },
      { "name": "Non GMO hudson corn purees", "code": "50626728" },
      { "name": "Non GMO illini gold corn purees", "code": "50626729" },
      { "name": "Non GMO illini xtra sweet corn purees", "code": "50626730" },
      { "name": "Non GMO incredible corn purees", "code": "50626731" },
      { "name": "Non GMO iochief corn purees", "code": "50626732" },
      { "name": "Non GMO jubilee corn purees", "code": "50626733" },
      { "name": "Non GMO jubilee supersweet corn purees", "code": "50626734" },
      { "name": "Non GMO kandy korn corn purees", "code": "50626735" },
      { "name": "Non GMO kiss \u2018n tell corn purees", "code": "50626736" },
      { "name": "Non GMO lancelot corn purees", "code": "50626737" },
      { "name": "Non GMO maple sweet corn purees", "code": "50626738" },
      { "name": "Non GMO medley corn purees", "code": "50626739" },
      { "name": "Non GMO merlin corn purees", "code": "50626740" },
      { "name": "Non GMO miracle corn purees", "code": "50626741" },
      { "name": "Non GMO nk-199 corn purees", "code": "50626742" },
      { "name": "Non GMO peaches and cream corn purees", "code": "50626743" },
      { "name": "Non GMO pearl white corn purees", "code": "50626744" },
      { "name": "Non GMO pegasus corn purees", "code": "50626745" },
      { "name": "Non GMO phenomenal corn purees", "code": "50626746" },
      { "name": "Non GMO platinum lady corn purees", "code": "50626747" },
      { "name": "Non GMO precocious corn purees", "code": "50626748" },
      { "name": "Non GMO pristine corn purees", "code": "50626749" },
      { "name": "Non GMO quickie corn purees", "code": "50626750" },
      { "name": "Non GMO radiance corn purees", "code": "50626751" },
      { "name": "Non GMO seneca brave corn purees", "code": "50626752" },
      { "name": "Non GMO seneca dawn corn purees", "code": "50626753" },
      { "name": "Non GMO seneca horizon corn purees", "code": "50626754" },
      { "name": "Non GMO seneca starshine corn purees", "code": "50626755" },
      { "name": "Non GMO seneca white knight corn purees", "code": "50626756" },
      { "name": "Non GMO showcase corn purees", "code": "50626757" },
      { "name": "Non GMO silver queen corn purees", "code": "50626758" },
      { "name": "Non GMO snowbelle corn purees", "code": "50626759" },
      { "name": "Non GMO spring snow corn purees", "code": "50626760" },
      { "name": "Non GMO spring treat corn purees", "code": "50626761" },
      { "name": "Non GMO sugar and gold corn purees", "code": "50626762" },
      { "name": "Non GMO sugar buns corn purees", "code": "50626763" },
      { "name": "Non GMO sugar snow corn purees", "code": "50626764" },
      { "name": "Non GMO sundance corn purees", "code": "50626765" },
      { "name": "Non GMO telstar corn purees", "code": "50626766" },
      { "name": "Non GMO terminator corn purees", "code": "50626767" },
      { "name": "Non GMO treasure corn purees", "code": "50626768" },
      { "name": "Non GMO tuxedo corn purees", "code": "50626769" },
      { "name": "Non GMO nominant vegetable purees", "code": "50626800" },
      { "name": "Non GMO alfalfa purees", "code": "50626801" },
      { "name": "Non GMO aloe leaf purees", "code": "50626802" },
      { "name": "Non GMO apio purees", "code": "50626803" },
      { "name": "Non GMO arrow root purees", "code": "50626804" },
      { "name": "Non GMO arrowhead purees", "code": "50626805" },
      { "name": "Non GMO arugula purees", "code": "50626806" },
      { "name": "Non GMO arum purees", "code": "50626807" },
      { "name": "Non GMO bamboo shoot purees", "code": "50626808" },
      { "name": "Non GMO banana leaf purees", "code": "50626809" },
      { "name": "Non GMO batata purees", "code": "50626810" },
      { "name": "Non GMO bean sprout purees", "code": "50626811" },
      { "name": "Non GMO beet top purees", "code": "50626812" },
      { "name": "Non GMO bittermelon purees", "code": "50626813" },
      { "name": "Non GMO caperberry purees", "code": "50626814" },
      { "name": "Non GMO carob purees", "code": "50626815" },
      { "name": "Non GMO cha-om purees", "code": "50626816" },
      { "name": "Non GMO chaoyote purees", "code": "50626817" },
      { "name": "Non GMO chickpea purees", "code": "50626818" },
      { "name": "Non GMO chrysanthemum green purees", "code": "50626819" },
      { "name": "Non GMO dandelion green purees", "code": "50626820" },
      { "name": "Non GMO dandelion purees", "code": "50626821" },
      { "name": "Non GMO dasheen purees", "code": "50626822" },
      { "name": "Non GMO dau mue or pea tip purees", "code": "50626823" },
      { "name": "Non GMO diakon purees", "code": "50626824" },
      { "name": "Non GMO donqua purees", "code": "50626825" },
      { "name": "Non GMO fiddlehead fern purees", "code": "50626826" },
      { "name": "Non GMO gai choy purees", "code": "50626827" },
      { "name": "Non GMO gailon purees", "code": "50626828" },
      { "name": "Non GMO galang purees", "code": "50626829" },
      { "name": "Non GMO ginger root purees", "code": "50626830" },
      { "name": "Non GMO gobo purees", "code": "50626831" },
      { "name": "Non GMO hop sprout purees", "code": "50626832" },
      { "name": "Non GMO horseradish purees", "code": "50626833" },
      { "name": "Non GMO jicama purees", "code": "50626834" },
      { "name": "Non GMO kudzu purees", "code": "50626835" },
      { "name": "Non GMO lily bul purees", "code": "50626836" },
      { "name": "Non GMO linkok purees", "code": "50626837" },
      { "name": "Non GMO lo bok purees", "code": "50626838" },
      { "name": "Non GMO long bean purees", "code": "50626839" },
      { "name": "Non GMO lotus root purees", "code": "50626840" },
      { "name": "Non GMO maguey leaf purees", "code": "50626841" },
      { "name": "Non GMO mallow purees", "code": "50626842" },
      { "name": "Non GMO mamey sapot purees", "code": "50626843" },
      { "name": "Non Gmoo moap purees", "code": "50626844" },
      { "name": "Non Gmoo moo purees", "code": "50626845" },
      { "name": "Non GMO moqua purees", "code": "50626846" },
      { "name": "Non GMO opo purees", "code": "50626847" },
      { "name": "Non GMO palm heart purees", "code": "50626848" },
      { "name": "Non GMO paprika purees", "code": "50626849" },
      { "name": "Non GMO purslanepurees", "code": "50626850" },
      { "name": "Non GMO raddichio purees", "code": "50626851" },
      { "name": "Non GMO sinquas purees", "code": "50626852" },
      { "name": "Non GMO soybean purees", "code": "50626853" },
      { "name": "Non GMO spoonwart purees", "code": "50626854" },
      { "name": "Non GMO taro purees", "code": "50626855" },
      { "name": "Non GMO taro lea purees", "code": "50626856" },
      { "name": "Non GMO taro shoot purees", "code": "50626857" },
      { "name": "Non GMO tassle grape-hyacint purees", "code": "50626858" },
      { "name": "Non GMO tendergreen purees", "code": "50626859" },
      { "name": "Non GMO tepeguaje purees", "code": "50626860" },
      { "name": "Non GMO tindora purees", "code": "50626861" },
      { "name": "Non GMO tree onion purees", "code": "50626862" },
      { "name": "Non GMO udo purees", "code": "50626863" },
      { "name": "Non GMO water chestnut purees", "code": "50626864" },
      { "name": "Non GMO water spinach purees", "code": "50626865" },
      { "name": "Non GMO yamp purees", "code": "50626866" },
      { "name": "Non GMO yautia purees", "code": "50626867" },
      { "name": "Non GMO yu choy purees", "code": "50626868" },
      { "name": "Non GMO yuca purees", "code": "50626869" },
      { "name": "Non GMO sugar pea purees", "code": "50626900" },
      { "name": "Non GMO bikini pea purees", "code": "50626901" },
      { "name": "Non GMO cavalier pea purees", "code": "50626902" },
      { "name": "Non GMO daisy pea purees", "code": "50626903" },
      { "name": "Non GMO darfon pea purees", "code": "50626904" },
      { "name": "Non GMO early onward pea purees", "code": "50626905" },
      { "name": "Non GMO feltham first pea purees", "code": "50626906" },
      { "name": "Non GMO hurst green shaft pea purees", "code": "50626907" },
      { "name": "Non GMO oregon sugar pod pea purees", "code": "50626908" },
      { "name": "Non GMO prince albert pea purees", "code": "50626909" },
      { "name": "Non GMO reuzensuiker pea purees", "code": "50626910" },
      { "name": "Non GMO chinese broccoli purees", "code": "50626911" },
      { "name": "Non GMO citadel brussel sprout purees", "code": "50626912" },
      { "name": "Non GMO falstaff brussel sprout purees", "code": "50626913" },
      { "name": "Non GMO daisy peas", "code": "50626914" },
      { "name": "Non GMO darfon peas", "code": "50626915" },
      { "name": "Non GMO early onward peas", "code": "50626916" },
      { "name": "Non GMO feltham first peas", "code": "50626917" },
      { "name": "Non GMO hurst green shaft peas", "code": "50626918" },
      { "name": "Non GMO oregon sugar pod peas", "code": "50626919" },
      { "name": "Non GMO prince albert peas", "code": "50626920" },
      { "name": "Non GMO reuzensuiker peas", "code": "50626921" }
    ]
  },
  {
    "name": "Drugs and Pharmaceutical Products",
    "code": "51000000",
    "subcategories": [
      {
        "name": "Amebicides and trichomonacides and antiprotozoals",
        "code": "51100000"
      },
      { "name": "Amebicides and trichomonacides", "code": "51101600" },
      { "name": "Eflornithine", "code": "51101602" },
      { "name": "Meglumine antimonate", "code": "51101604" },
      { "name": "Calcium oxide", "code": "51101607" },
      { "name": "Polynoxylin", "code": "51101612" },
      { "name": "Pentamidine", "code": "51101613" },
      { "name": "Diloxanide furoate", "code": "51101614" },
      { "name": "Melarsoprol", "code": "51101616" },
      { "name": "Taurolidine", "code": "51101618" },
      { "name": "Iodoquinol", "code": "51101620" },
      { "name": "Trimetrexate", "code": "51101629" },
      { "name": "Propamidine", "code": "51101630" },
      { "name": "Amprolium", "code": "51101633" },
      { "name": "Antienite", "code": "51101634" },
      { "name": "Arsthinol", "code": "51101635" },
      { "name": "Azanidazole", "code": "51101636" },
      { "name": "Benznidazole", "code": "51101637" },
      { "name": "Bialamicol", "code": "51101638" },
      { "name": "Clamoxyquin", "code": "51101639" },
      { "name": "Broxyquinoline", "code": "51101640" },
      { "name": "Buparvaquone", "code": "51101641" },
      { "name": "Calcium trisodium pentetate", "code": "51101642" },
      { "name": "Carbarsone", "code": "51101643" },
      { "name": "Carnidazole", "code": "51101644" },
      { "name": "Chiniofon", "code": "51101645" },
      { "name": "Chlorbetamide", "code": "51101646" },
      { "name": "Clamoxyquine", "code": "51101647" },
      { "name": "Clefamide", "code": "51101648" },
      { "name": "Clopidol", "code": "51101649" },
      { "name": "Dehydroemetine", "code": "51101650" },
      { "name": "Diclazuril", "code": "51101651" },
      { "name": "Difetarsone", "code": "51101652" },
      { "name": "Fumagillin", "code": "51101653" },
      { "name": "Dinitolmide", "code": "51101654" },
      { "name": "Emetine", "code": "51101655" },
      { "name": "Emodepside", "code": "51101656" },
      { "name": "Etofamide", "code": "51101657" },
      { "name": "Ferric cacodylate", "code": "51101658" },
      { "name": "Fexinidazole", "code": "51101659" },
      { "name": "Forminitrazole", "code": "51101660" },
      { "name": "Glycobiarsol", "code": "51101661" },
      { "name": "Halofuginone", "code": "51101662" },
      { "name": "Imidocarb", "code": "51101663" },
      { "name": "Ipronidazole", "code": "51101664" },
      { "name": "Melarsomine", "code": "51101665" },
      { "name": "Moxnidazole", "code": "51101666" },
      { "name": "Mepacrine or quinacrine", "code": "51101667" },
      { "name": "Miltefosine", "code": "51101668" },
      { "name": "Monensin", "code": "51101669" },
      { "name": "Moxidectin", "code": "51101670" },
      { "name": "Phanquinone or phanquone", "code": "51101671" },
      { "name": "Nemadectin", "code": "51101672" },
      { "name": "Nifurtimox", "code": "51101673" },
      { "name": "Nitarsone", "code": "51101674" },
      { "name": "Ontianil", "code": "51101675" },
      { "name": "Parvaquone", "code": "51101676" },
      { "name": "Sodium stibogluconate", "code": "51101677" },
      { "name": "Ponazuril", "code": "51101678" },
      { "name": "Pretamazium iodide", "code": "51101679" },
      { "name": "Quinfamide", "code": "51101680" },
      { "name": "Robenidine", "code": "51101681" },
      { "name": "Satranidazole", "code": "51101682" },
      { "name": "Semduramicin", "code": "51101683" },
      { "name": "Spirazine or spirotriazine", "code": "51101684" },
      { "name": "Sulazuril", "code": "51101685" },
      { "name": "Sulfaclozine or sulfachlorpyridazine", "code": "51101686" },
      { "name": "Sulnidazole", "code": "51101687" },
      { "name": "Suramin", "code": "51101688" },
      { "name": "Symetine", "code": "51101689" },
      { "name": "Teclozan", "code": "51101690" },
      { "name": "Tenonitrozole", "code": "51101691" },
      { "name": "Tivanidazole", "code": "51101692" },
      { "name": "Toltrazuril", "code": "51101693" },
      { "name": "Tosulur", "code": "51101694" },
      { "name": "Troquidazole", "code": "51101695" },
      {
        "name": "Aminosalicylate sodium or sodium aminosalicylate",
        "code": "51101696"
      },
      { "name": "Bidimazium iodide", "code": "51101697" },
      { "name": "Acetarsol or acetarsone", "code": "51101698" },
      { "name": "Pentamidine isethionate", "code": "51101699" },
      { "name": "Antiprotozoals", "code": "51101900" },
      { "name": "Mefloquine", "code": "51101902" },
      { "name": "Primaquine", "code": "51101903" },
      { "name": "Quinine", "code": "51101904" },
      { "name": "Chloroquine", "code": "51101905" },
      { "name": "Proguanil", "code": "51101906" },
      { "name": "Pyrimethamine", "code": "51101907" },
      { "name": "Artemether", "code": "51101908" },
      { "name": "Halofantrine", "code": "51101911" },
      { "name": "Hydroxychloroquine", "code": "51101912" },
      { "name": "Amodiaquine", "code": "51101913" },
      { "name": "Artesunate", "code": "51101914" },
      { "name": "Benflumetol or lumefantrine", "code": "51101915" },
      { "name": "Amopyroquine", "code": "51101916" },
      { "name": "Amquinate", "code": "51101917" },
      { "name": "Artemotil or beta-arteether", "code": "51101918" },
      { "name": "Arteflene", "code": "51101919" },
      { "name": "Artemisinin", "code": "51101920" },
      { "name": "Chlorproguanil", "code": "51101921" },
      { "name": "Artenimol", "code": "51101922" },
      { "name": "Atovaquone", "code": "51101923" },
      { "name": "Bulaquine", "code": "51101924" },
      { "name": "Chininum muriaticum", "code": "51101925" },
      { "name": "Chloroguanide", "code": "51101926" },
      { "name": "Cloquinate", "code": "51101927" },
      { "name": "Cycloguanil", "code": "51101928" },
      { "name": "Docetaxel", "code": "51101929" },
      { "name": "Enpiroline", "code": "51101930" },
      { "name": "Quinocide", "code": "51101931" },
      { "name": "Menoctone", "code": "51101932" },
      { "name": "Mepacrine", "code": "51101933" },
      { "name": "Moxipraquine", "code": "51101934" },
      { "name": "Pamaquine", "code": "51101935" },
      { "name": "Sitamaquine", "code": "51101936" },
      { "name": "Tafenoquine", "code": "51101937" },
      { "name": "Tebuquine", "code": "51101938" },
      { "name": "Acediasulfone", "code": "51101939" },
      { "name": "Cycloguanil embonate", "code": "51101940" },
      { "name": "Combination anti-infectives", "code": "51102800" },
      {
        "name": "Allantoin/aminacrine/dienestrol/sulfanilamide",
        "code": "51102801"
      },
      { "name": "Acetic acid/aluminum acetate", "code": "51102802" },
      {
        "name": "Acetic acid/benzalkonium chloride/chloroxylenol/pramoxine",
        "code": "51102803"
      },
      {
        "name": "Acetic acid/benzalkonuim chloride/chloroxylenol/glycerin",
        "code": "51102804"
      },
      {
        "name": "Acetic acid/benzethonium chloride/propylene glycol",
        "code": "51102805"
      },
      {
        "name": "Acetic acid/benzethonium/propylene glycol",
        "code": "51102806"
      },
      { "name": "Acetic acid/desonide", "code": "51102807" },
      { "name": "Acetic acid/hydrocortisone", "code": "51102808" },
      {
        "name": "Acetic/benzalkonium/parachlorometoxylenol/pramoxine/propylene",
        "code": "51102809"
      },
      {
        "name": "Alkyl/aminacrine/benzoxiquine/cupric/edetate/sodium",
        "code": "51102810"
      },
      {
        "name": "Bacitracin/hydrocortisone/neomycin/polymyxin b",
        "code": "51102811"
      },
      { "name": "Allantoin/aminacrine/sulfanilamide", "code": "51102812" },
      { "name": "Allantoin/aminacrine/sulfisoxazole", "code": "51102813" },
      { "name": "Aloe/hydrocortisone/iodoquinol", "code": "51102814" },
      { "name": "Aluminum acetate/benzethonium chloride", "code": "51102815" },
      {
        "name": "Aminacrine/docusate/edetate sodium/nonoxynol",
        "code": "51102816"
      },
      { "name": "Amphotericin b/tetracycline", "code": "51102817" },
      {
        "name": "Bacitracin/diperodon/neomycin/polymyxin b",
        "code": "51102818"
      },
      { "name": "Betamethasone/clotrimazole", "code": "51102819" },
      { "name": "Bacitracin/neomycin/polymyxin", "code": "51102820" },
      { "name": "Bacitracin/neomycin/polymyxin b", "code": "51102821" },
      { "name": "Bacitracin/polymyxin b", "code": "51102822" },
      { "name": "Bacitracin/polymyxin b/pramoxine", "code": "51102823" },
      {
        "name": "Benzalkonium chloride/chloroxylenol/hydrocortisone",
        "code": "51102824"
      },
      { "name": "Benzalkonium/oxyquinoline", "code": "51102825" },
      { "name": "Ciprofloxacin/dextrose", "code": "51102826" },
      {
        "name": "Brilliant green/gentian violet/proflavine",
        "code": "51102827"
      },
      {
        "name": "Cetylpyridinium chloride/chloroxylenol/hydrocortisone/triacetin",
        "code": "51102828"
      },
      {
        "name": "Cetylpyridinium chloride/dyclonine hydrochloride",
        "code": "51102829"
      },
      { "name": "Cetylpyridinium/chloroxylenol/triacetin", "code": "51102830" },
      { "name": "Chloramphenicol/hydrocortisone", "code": "51102831" },
      {
        "name": "Chloramphenicol/hydrocortisone/polymyxin b",
        "code": "51102832"
      },
      { "name": "Chlorcyclizine/hydrocortisone", "code": "51102833" },
      {
        "name": "Chloroxylenol/isopropyl myristate/undecylenic acid",
        "code": "51102834"
      },
      { "name": "Ciprofloxacin/dexamethasone", "code": "51102835" },
      { "name": "Dexamethasone/tobramycin", "code": "51102836" },
      { "name": "Ciprofloxacin/hydrocortisone", "code": "51102837" },
      { "name": "Clioquinol/hydrocortisone", "code": "51102838" },
      { "name": "Clioquinol/hydrocortisone/pramoxine", "code": "51102839" },
      { "name": "Colistin/hydrocortisone/neomycin", "code": "51102840" },
      {
        "name": "Colistin/hydrocortisone/neomycin/thonzonium",
        "code": "51102841"
      },
      { "name": "Dalfopristin/quinupristin", "code": "51102842" },
      { "name": "Dexamethasone/neomycin", "code": "51102843" },
      { "name": "Dexamethasone/neomycin/polymyxin b", "code": "51102844" },
      { "name": "Hydrocortisone/neomycin", "code": "51102845" },
      { "name": "Erythromycin/sulfisoxazole", "code": "51102846" },
      { "name": "Fluocinolone/neomycin", "code": "51102847" },
      { "name": "Fluorometholone/sulfacetamide", "code": "51102848" },
      { "name": "Flurandrenolide/neomycin", "code": "51102849" },
      { "name": "Gentamicin/prednisolone", "code": "51102850" },
      {
        "name": "Gramicidin/neomycin/nystatin/triamcinolone",
        "code": "51102851"
      },
      { "name": "Gramicidin/neomycin/polymyxin b", "code": "51102852" },
      { "name": "Hexachlorophene/hydrocortisone/menthol", "code": "51102853" },
      { "name": "Hydrocortisone/iodoquinol", "code": "51102854" },
      { "name": "Metronidazole/sodium chloride", "code": "51102855" },
      { "name": "Hydrocortisone/neomycin/polymyxin b", "code": "51102856" },
      { "name": "Hydrocortisone/oxytetracycline", "code": "51102857" },
      { "name": "Hydrocortisone/polymyxin b", "code": "51102858" },
      {
        "name": "Isopropyl alcohol/salicylic acid/sodium thiosulfate",
        "code": "51102859"
      },
      { "name": "Loteprednol/tobramycin", "code": "51102860" },
      { "name": "Methylprednisolone/neomycin", "code": "51102861" },
      { "name": "Oxytetracycline/polymyxin b", "code": "51102862" },
      { "name": "Neomycin/polymyxin b", "code": "51102863" },
      { "name": "Neomycin/polymyxin b/prednisolone", "code": "51102864" },
      { "name": "Neomycin/polymyxin/pramoxine", "code": "51102865" },
      { "name": "Neomycin/prednisolone", "code": "51102866" },
      { "name": "Nystatin/oxytetracycline", "code": "51102867" },
      { "name": "Nystatin/triamcinolone", "code": "51102868" },
      {
        "name": "Oxytetracycline/phenazopyridine/sulfamethizole",
        "code": "51102869"
      },
      { "name": "Polymyxin b/trimethoprim", "code": "51102871" },
      { "name": "Prednisolone/sulfacetamide", "code": "51102872" },
      {
        "name": "Sulfabenzamide/sulfacetamide/sulfathiazole",
        "code": "51102873"
      },
      {
        "name": "Sulfabenzamide/sulfacetamide/sulfathiazole/urea",
        "code": "51102874"
      },
      { "name": "Combination antimalarials", "code": "51102900" },
      { "name": "Artemether/lumefantrine", "code": "51102901" },
      { "name": "Atovaquone/proguanil", "code": "51102902" },
      { "name": "Chloroquine/primaquine", "code": "51102903" },
      { "name": "Pyrimethamine/sulfadoxine", "code": "51102904" },
      { "name": "Combination pediculicides", "code": "51103000" },
      { "name": "Petroleum/piperonyl butoxide/pyrethrins", "code": "51103001" },
      { "name": "Piperonyl butoxide/pyrethrins", "code": "51103002" },
      {
        "name": "Piperonyl butoxide/pyrethrins/petroleum distillates",
        "code": "51103003"
      },
      { "name": "Antineoplastic agents", "code": "51110000" },
      { "name": "Antimetabolites", "code": "51111600" },
      { "name": "Cytarabine", "code": "51111602" },
      { "name": "Floxuridine", "code": "51111603" },
      { "name": "Hydroxycarbamide or hydroxyurea", "code": "51111606" },
      { "name": "Methotrexate", "code": "51111610" },
      { "name": "Teniposide", "code": "51111611" },
      { "name": "Lenograstim", "code": "51111613" },
      { "name": "Glutathione", "code": "51111615" },
      { "name": "Gemcitabine", "code": "51111617" },
      { "name": "Brequinar", "code": "51111619" },
      { "name": "Acadesine", "code": "51111620" },
      { "name": "Acivicin", "code": "51111621" },
      { "name": "Adenine", "code": "51111622" },
      { "name": "Amonafide", "code": "51111623" },
      { "name": "Ancitabine", "code": "51111624" },
      { "name": "Azacitidine", "code": "51111625" },
      { "name": "Bexarotene", "code": "51111626" },
      { "name": "Doxifluridine", "code": "51111627" },
      { "name": "Carmofur", "code": "51111628" },
      { "name": "Clofarabine", "code": "51111629" },
      { "name": "Decitabine", "code": "51111630" },
      { "name": "Dezaguanine", "code": "51111631" },
      { "name": "Diflomotecan", "code": "51111632" },
      { "name": "Edatrexate", "code": "51111633" },
      { "name": "Emitefur", "code": "51111634" },
      { "name": "Eniluracil", "code": "51111635" },
      { "name": "Enocitabine", "code": "51111636" },
      { "name": "Evandamine", "code": "51111637" },
      { "name": "Exatecan", "code": "51111638" },
      { "name": "Fazarabine", "code": "51111639" },
      { "name": "Masoprocol", "code": "51111640" },
      { "name": "Galocitabine", "code": "51111641" },
      { "name": "Irinotecan", "code": "51111642" },
      { "name": "Ketotrexate", "code": "51111643" },
      { "name": "Lonidamine", "code": "51111644" },
      { "name": "Pralatrexate", "code": "51111645" },
      { "name": "Nelarabine", "code": "51111646" },
      { "name": "Nolatrexed", "code": "51111647" },
      { "name": "Oteracil", "code": "51111648" },
      { "name": "Peldesine", "code": "51111649" },
      { "name": "Pemetrexed", "code": "51111650" },
      { "name": "Pirazofurin or pyrazofurin", "code": "51111651" },
      { "name": "Piritrexim", "code": "51111652" },
      { "name": "Tocladesine", "code": "51111653" },
      { "name": "Raltitrexed", "code": "51111654" },
      { "name": "Rubitecan", "code": "51111655" },
      { "name": "Tegafur", "code": "51111656" },
      { "name": "Tezacitabine", "code": "51111657" },
      { "name": "Tiazofurin or tiazofurine", "code": "51111658" },
      { "name": "Topotecan", "code": "51111659" },
      { "name": "Triciribine", "code": "51111660" },
      { "name": "Troxacitabine", "code": "51111661" },
      { "name": "Uracil", "code": "51111662" },
      { "name": "Valopicitabine", "code": "51111663" },
      { "name": "Aminopterin", "code": "51111664" },
      { "name": "Thioguanine or tioguanine", "code": "51111665" },
      { "name": "Antineoplastic antibiotics", "code": "51111700" },
      { "name": "Daunorubicin citrate", "code": "51111703" },
      { "name": "Mitomycin", "code": "51111704" },
      { "name": "Deoxycoformycin or pentostatin", "code": "51111707" },
      { "name": "Rituximab", "code": "51111716" },
      { "name": "Trastuzumab", "code": "51111717" },
      { "name": "Anthramycin or antramycin", "code": "51111724" },
      { "name": "Acodazole", "code": "51111725" },
      { "name": "Ambazone", "code": "51111726" },
      { "name": "Amsacrine", "code": "51111727" },
      { "name": "Cabazitaxel", "code": "51111728" },
      { "name": "Arsenic trioxide", "code": "51111729" },
      { "name": "Azotomycin", "code": "51111730" },
      { "name": "Bevacizumab", "code": "51111731" },
      { "name": "Bisantrene", "code": "51111732" },
      { "name": "Bleomycin", "code": "51111733" },
      { "name": "Butantrone", "code": "51111734" },
      { "name": "Cemadotin", "code": "51111735" },
      { "name": "Cetuximab", "code": "51111736" },
      { "name": "Crisnatol", "code": "51111737" },
      { "name": "Datelliptium", "code": "51111738" },
      { "name": "Etoposide", "code": "51111739" },
      { "name": "Daunorubicin hydrochloride", "code": "51111740" },
      { "name": "Demecolcine", "code": "51111741" },
      { "name": "Eribulin", "code": "51111742" },
      { "name": "Medorubicin", "code": "51111743" },
      { "name": "Galarubicin", "code": "51111744" },
      { "name": "Gemtuzumab", "code": "51111745" },
      { "name": "Ipilimumab", "code": "51111746" },
      { "name": "Ixabepilone", "code": "51111747" },
      { "name": "Ladirubicin", "code": "51111748" },
      { "name": "Leurubicin", "code": "51111749" },
      { "name": "Losoxantrone", "code": "51111750" },
      { "name": "Ortetamine", "code": "51111751" },
      { "name": "Menogaril", "code": "51111752" },
      { "name": "Mitoguazone", "code": "51111753" },
      { "name": "Mitonafide", "code": "51111754" },
      { "name": "Nogalamycin", "code": "51111755" },
      { "name": "Ofatumumab", "code": "51111756" },
      { "name": "Teloxantrone", "code": "51111757" },
      { "name": "Panitumumab", "code": "51111758" },
      { "name": "Piroxantrone", "code": "51111759" },
      { "name": "Pixantrone", "code": "51111760" },
      { "name": "Ranibizumab", "code": "51111761" },
      { "name": "Romidepsin", "code": "51111762" },
      { "name": "Soblidotin", "code": "51111763" },
      { "name": "Vinepidine", "code": "51111764" },
      { "name": "Trabectedin", "code": "51111765" },
      { "name": "Vinblastine", "code": "51111766" },
      { "name": "Vincristine", "code": "51111767" },
      { "name": "Vindesine", "code": "51111768" },
      { "name": "Vinflunine", "code": "51111769" },
      { "name": "Vinfosiltine", "code": "51111770" },
      { "name": "Vinleucinol", "code": "51111771" },
      { "name": "Vinorelbine", "code": "51111772" },
      { "name": "Vinzolidine", "code": "51111773" },
      { "name": "Vorinostat", "code": "51111774" },
      { "name": "Bizelesin", "code": "51111775" },
      { "name": "Podofilox", "code": "51111776" },
      { "name": "Salinomycin", "code": "51111777" },
      { "name": "Hormones and antihormones", "code": "51111800" },
      { "name": "Bicalutamide", "code": "51111802" },
      { "name": "Estramustine", "code": "51111803" },
      { "name": "Goserelin", "code": "51111805" },
      { "name": "Leuprolide", "code": "51111807" },
      { "name": "Testolactone", "code": "51111810" },
      { "name": "Triptorelin", "code": "51111815" },
      { "name": "Letrozole", "code": "51111820" },
      { "name": "Exemestane", "code": "51111824" },
      { "name": "Cyproterone", "code": "51111828" },
      { "name": "Abarelix", "code": "51111829" },
      { "name": "Abiraterone", "code": "51111830" },
      { "name": "Acolbifene", "code": "51111831" },
      { "name": "Aminoglutethimide", "code": "51111832" },
      { "name": "Axitinib", "code": "51111833" },
      { "name": "Bazedoxifene", "code": "51111834" },
      { "name": "Benorterone", "code": "51111835" },
      { "name": "Bifluranol", "code": "51111837" },
      { "name": "Bortezomib", "code": "51111838" },
      { "name": "Calusterone", "code": "51111839" },
      { "name": "Cetrorelix", "code": "51111840" },
      { "name": "Cioteronel", "code": "51111841" },
      { "name": "Degarelix", "code": "51111842" },
      { "name": "Deslorelin", "code": "51111843" },
      { "name": "Fadrozole", "code": "51111844" },
      { "name": "Dromostanolone or drostanolone", "code": "51111845" },
      { "name": "Epitiostanol", "code": "51111846" },
      { "name": "Erbulozole", "code": "51111847" },
      { "name": "Formestane", "code": "51111848" },
      { "name": "Fosfestrol", "code": "51111849" },
      { "name": "Gonadorelin", "code": "51111850" },
      { "name": "Histrelin", "code": "51111851" },
      { "name": "Inocoterone", "code": "51111853" },
      { "name": "Nafarelin", "code": "51111854" },
      { "name": "Lanreotide", "code": "51111855" },
      { "name": "Lapatinib", "code": "51111856" },
      { "name": "Lasofoxifene", "code": "51111857" },
      { "name": "Liarozole", "code": "51111858" },
      { "name": "Mecasermin", "code": "51111859" },
      { "name": "Mitotane", "code": "51111860" },
      { "name": "Terfluranol", "code": "51111861" },
      { "name": "Nilotinib", "code": "51111862" },
      { "name": "Octreotide", "code": "51111863" },
      { "name": "Sulprostone", "code": "51111866" },
      { "name": "Zindoxifene", "code": "51111867" },
      { "name": "Terguride", "code": "51111868" },
      { "name": "Trilostane", "code": "51111869" },
      { "name": "Vapreotide", "code": "51111870" },
      { "name": "Vorozole", "code": "51111871" },
      { "name": "Zanoterone", "code": "51111872" },
      { "name": "Tamoxifen", "code": "51111873" },
      { "name": "Tamoxifen citrate", "code": "51111874" },
      { "name": "Anastrozole", "code": "51111875" },
      { "name": "Natural antineoplastic products", "code": "51111900" },
      { "name": "Asparaginase", "code": "51111901" },
      { "name": "Paclitaxel", "code": "51111904" },
      { "name": "Porfimer sodium", "code": "51111905" },
      { "name": "Pegaspargase", "code": "51111906" },
      { "name": "Sodium phenylbutyrate", "code": "51111907" },
      { "name": "Aminolevulinic acid", "code": "51111908" },
      { "name": "Methyl aminolevulinate", "code": "51111909" },
      { "name": "Paclitaxel poliglumex", "code": "51111910" },
      { "name": "Porfimer", "code": "51111911" },
      { "name": "Temoporfin", "code": "51111912" },
      { "name": "Paclitaxel ceribate", "code": "51111913" },
      { "name": "Tyrosine kinase inhibitors", "code": "51112000" },
      { "name": "Capecitabine", "code": "51112001" },
      { "name": "Dasatinib", "code": "51112002" },
      { "name": "Erlotinib", "code": "51112003" },
      { "name": "Gefitinib", "code": "51112004" },
      { "name": "Imatinib", "code": "51112005" },
      { "name": "Pazopanib", "code": "51112006" },
      { "name": "Semaxanib", "code": "51112007" },
      { "name": "Sorafenib", "code": "51112008" },
      { "name": "Sunitinib", "code": "51112009" },
      { "name": "Vandetanib", "code": "51112010" },
      { "name": "Vatalanib", "code": "51112011" },
      { "name": "Afatinib", "code": "51112012" },
      { "name": "Dasatinib hydrate", "code": "51112013" },
      { "name": "Erlotinib hydrochloride", "code": "51112014" },
      { "name": "Imatinib mesiate or mesylate", "code": "51112015" },
      { "name": "Sorafenib tosylate", "code": "51112016" },
      { "name": "Sunitinib malate", "code": "51112017" },
      { "name": "Vatalanib succinate", "code": "51112018" },
      {
        "name": "Antineoplastic agents, antineoplastic agent immunotoxins and antineoplastic keratinocyte growth factors",
        "code": "51112100"
      },
      { "name": "Denileukin diftitox", "code": "51112101" },
      { "name": "Palifermin", "code": "51112102" },
      { "name": "Alitretinoin", "code": "51112103" },
      { "name": "Etoglucid", "code": "51112104" },
      { "name": "Combination antineoplastics", "code": "51112200" },
      { "name": "Bleomycin/sodium chloride", "code": "51112201" },
      { "name": "Cyclophosphamide/mannitol", "code": "51112202" },
      { "name": "Doxorubicin/sodium chloride", "code": "51112203" },
      { "name": "Ifosfamide/mesna", "code": "51112204" },
      { "name": "Methotrexate/sodium chloride", "code": "51112205" },
      { "name": "Sodium chloride/vinblastine", "code": "51112206" },
      { "name": "Alkylating agent alkanesulfonic acids", "code": "51112300" },
      { "name": "Busulfan", "code": "51112301" },
      { "name": "Improsulfan", "code": "51112302" },
      { "name": "Improsulfan hydrochloride", "code": "51112303" },
      { "name": "Improsulfan tosilate", "code": "51112304" },
      { "name": "Mannosulfan", "code": "51112305" },
      { "name": "Treosulfan", "code": "51112306" },
      { "name": "Alkylating agent amides", "code": "51112400" },
      { "name": "Amifostine monohydrate", "code": "51112401" },
      { "name": "Dacarbazine", "code": "51112402" },
      { "name": "Dacarbazine citrate", "code": "51112403" },
      { "name": "Procarbazine", "code": "51112404" },
      { "name": "Procarbazine hydrochloride", "code": "51112405" },
      {
        "name": "Alkylating agent nitrogen mustard compounds",
        "code": "51112500"
      },
      { "name": "Ambamustine", "code": "51112501" },
      { "name": "Atrimustine", "code": "51112502" },
      { "name": "Bendamustine", "code": "51112503" },
      { "name": "Bofumustine", "code": "51112504" },
      { "name": "Chlorambucil", "code": "51112505" },
      { "name": "Chlormethine hydrochloride", "code": "51112506" },
      { "name": "Cyclophosphamide", "code": "51112507" },
      { "name": "Bendamustine hydrochloride", "code": "51112508" },
      { "name": "Chlormethine or mechlorethamine", "code": "51112509" },
      { "name": "Ifosfamide", "code": "51112510" },
      { "name": "Mafosfamide", "code": "51112511" },
      { "name": "Mannomustine", "code": "51112512" },
      { "name": "Melphalan", "code": "51112513" },
      { "name": "Mafosfamide lysine", "code": "51112514" },
      { "name": "Mannomustine dihydrochloride", "code": "51112515" },
      { "name": "Melphalan flufenamide", "code": "51112516" },
      { "name": "Mannomustine hydrochloride", "code": "51112517" },
      { "name": "Melphalan hydrochloride", "code": "51112518" },
      { "name": "Metamelfalan", "code": "51112519" },
      { "name": "Perfosfamide", "code": "51112520" },
      { "name": "Prednimustine", "code": "51112521" },
      { "name": "Sarcolysin", "code": "51112522" },
      { "name": "Sufosfamide", "code": "51112523" },
      { "name": "Trichlormethine", "code": "51112524" },
      { "name": "Trofosfamide", "code": "51112525" },
      { "name": "Uracil mustard or uramustine", "code": "51112526" },
      { "name": "Sarcolysin hydrochloride", "code": "51112527" },
      { "name": "Spiromustine", "code": "51112528" },
      { "name": "Alkylating agent nitrosourea compounds", "code": "51112600" },
      { "name": "Carmustine", "code": "51112601" },
      { "name": "Ditiomustine", "code": "51112602" },
      { "name": "Ecomustine", "code": "51112603" },
      { "name": "Fotemustine", "code": "51112604" },
      { "name": "Galamustine", "code": "51112605" },
      { "name": "Lomustine", "code": "51112606" },
      { "name": "Nimustine", "code": "51112607" },
      { "name": "Cyclophosphamide anhydrous", "code": "51112608" },
      { "name": "Neptamustine or pentamustine", "code": "51112609" },
      { "name": "Nimustine hydrochloride", "code": "51112610" },
      { "name": "Ranimustine", "code": "51112611" },
      { "name": "Semustine", "code": "51112612" },
      { "name": "Tauromustine", "code": "51112613" },
      {
        "name": "Alkylating agent organoplatinum compounds",
        "code": "51112700"
      },
      { "name": "Carboplatin", "code": "51112701" },
      { "name": "Cisplatin", "code": "51112702" },
      { "name": "Enloplatin", "code": "51112703" },
      { "name": "Nedaplatin", "code": "51112704" },
      { "name": "Oxaliplatin", "code": "51112705" },
      { "name": "Satraplatin", "code": "51112706" },
      { "name": "Zeniplatin", "code": "51112707" },
      { "name": "Picoplatin", "code": "51112708" },
      { "name": "Alkylating agent stilbenes", "code": "51112800" },
      { "name": "Droloxifene", "code": "51112801" },
      { "name": "Idoxifene", "code": "51112802" },
      { "name": "Ospemifene", "code": "51112803" },
      { "name": "Raloxifene", "code": "51112804" },
      { "name": "Toremifene", "code": "51112805" },
      { "name": "Alkylating agent triazines", "code": "51112900" },
      { "name": "Amifostine", "code": "51112901" },
      { "name": "Temozolomide", "code": "51112902" },
      { "name": "Alkylating agent amines", "code": "51113000" },
      { "name": "Altretamine", "code": "51113001" },
      { "name": "Altretamine hydrochloride", "code": "51113002" },
      { "name": "Alkylating agent alcohols", "code": "51113100" },
      { "name": "Defosfamide", "code": "51113101" },
      { "name": "Mitobronitol", "code": "51113102" },
      { "name": "Mitolactol", "code": "51113103" },
      { "name": "Ritrosulfan", "code": "51113104" },
      { "name": "Alkylating agent aziridines", "code": "51113200" },
      { "name": "Azatepa", "code": "51113201" },
      { "name": "Benzodepa", "code": "51113202" },
      { "name": "Diaziquone", "code": "51113203" },
      { "name": "Meturedepa", "code": "51113204" },
      { "name": "Pumitepa", "code": "51113205" },
      { "name": "Thiotepa", "code": "51113206" },
      { "name": "Tretamine or triethylenemelamine", "code": "51113207" },
      { "name": "Triaziquone", "code": "51113208" },
      { "name": "Alkylating agent carbamates", "code": "51113300" },
      { "name": "Carboquone", "code": "51113301" },
      { "name": "Enpromate", "code": "51113302" },
      { "name": "Uredepa", "code": "51113303" },
      { "name": "Alkylating agent carboxylic acids", "code": "51113400" },
      { "name": "Aceglatone", "code": "51113401" },
      { "name": "Azelaic acid", "code": "51113402" },
      { "name": "Alkylating agent piperazines", "code": "51113500" },
      { "name": "Pipobroman", "code": "51113501" },
      { "name": "Piposulfan", "code": "51113502" },
      { "name": "Alkylating agent cytokines", "code": "51113600" },
      { "name": "Irofulven", "code": "51113601" },
      { "name": "Oprelvekin", "code": "51113602" },
      {
        "name": "Antiarrythmics and antianginals and cardioplegics and drugs for heart failure",
        "code": "51120000"
      },
      { "name": "Antiarrythmic agents", "code": "51121500" },
      { "name": "Moricizine", "code": "51121518" },
      { "name": "Bucromarone", "code": "51121536" },
      { "name": "Nadolol", "code": "51121566" },
      { "name": "Propafenone hydrochloride", "code": "51121583" },
      { "name": "Antianginal drugs", "code": "51121600" },
      {
        "name": "Pentaerythritol tetranitrate or pentaerithrityl tetranitrate",
        "code": "51121604"
      },
      { "name": "Isoxsuprine", "code": "51121616" },
      { "name": "Ranolazine", "code": "51121617" },
      { "name": "Drugs used for congestive heart failure", "code": "51121900" },
      { "name": "Nesiritide", "code": "51121907" },
      { "name": "Powdered or prepared digitalis leaf", "code": "51121908" },
      { "name": "Lanatoside c", "code": "51121910" },
      { "name": "Gitoformate or pentaformylgitoxin", "code": "51121911" },
      { "name": "Acetyldigitoxin", "code": "51121912" },
      { "name": "Bemarinone", "code": "51121914" },
      { "name": "Bufogenin or resibufogenin", "code": "51121915" },
      { "name": "Carsatrin", "code": "51121916" },
      { "name": "Deslanoside", "code": "51121917" },
      { "name": "Draflazine", "code": "51121918" },
      { "name": "Etomoxir", "code": "51121919" },
      { "name": "Sulmazole", "code": "51121920" },
      { "name": "G-strophanthin or ouabain", "code": "51121921" },
      { "name": "Meribendan", "code": "51121922" },
      { "name": "Nepicastat", "code": "51121923" },
      { "name": "Proscillaridin", "code": "51121924" },
      { "name": "Ramnodigin", "code": "51121925" },
      { "name": "Simendan", "code": "51121926" },
      { "name": "Cardioplegic agents", "code": "51122300" },
      { "name": "Cardioplegic solution", "code": "51122301" },
      { "name": "Combination antianginals", "code": "51122400" },
      {
        "name": "Hydroxyzine/pentaerythritol tetranitrate",
        "code": "51122401"
      },
      { "name": "Mannitol hexanitrate/phenobarbital", "code": "51122402" },
      {
        "name": "Meprobamate/pentaerythritol tetranitrate",
        "code": "51122403"
      },
      { "name": "Niacin/pentaerythritol tetranitrate", "code": "51122404" },
      {
        "name": "Pentaerythritol tetranitrate/phenobarbital",
        "code": "51122405"
      },
      { "name": "Phenobarbital/sodium nitrite", "code": "51122406" },
      { "name": "Combination antiarrhythmics", "code": "51122500" },
      { "name": "Bretylium/dextrose", "code": "51122501" },
      { "name": "Combination antilipemic agents", "code": "51122600" },
      { "name": "Aspirin/pravastatin", "code": "51122601" },
      { "name": "Cholestyramine/sorbitol", "code": "51122602" },
      { "name": "Ezetimibe/simvastatin", "code": "51122603" },
      { "name": "Lovastatin/niacin", "code": "51122604" },
      { "name": "Niacin/simvastatin", "code": "51122605" },
      { "name": "Combination cardiovascular agents", "code": "51122700" },
      {
        "name": "Calcium chloride/dextrose/magnesium chloride/sodium chloride",
        "code": "51122701"
      },
      { "name": "Potassium chloride/sodium bicarbonate", "code": "51122702" },
      { "name": "Antiarrythmic alcohols", "code": "51122800" },
      { "name": "Almokalant", "code": "51122801" },
      { "name": "Stirocainide", "code": "51122802" },
      { "name": "Itrocainide", "code": "51122803" },
      { "name": "Zocainone", "code": "51122804" },
      { "name": "Drobuline", "code": "51122805" },
      { "name": "Guafecainol", "code": "51122806" },
      { "name": "Sotalol", "code": "51122807" },
      { "name": "Antiarrythmic alkaline earth metals", "code": "51122900" },
      { "name": "Magnesium gluconate", "code": "51122901" },
      { "name": "Antiarrythmic alkaloids", "code": "51123000" },
      { "name": "Ajmaline", "code": "51123001" },
      { "name": "Prajmaline", "code": "51123002" },
      { "name": "Sparteine", "code": "51123003" },
      { "name": "Emilium tosilate", "code": "51123004" },
      { "name": "Hydroquinidine", "code": "51123005" },
      { "name": "Lorajmine", "code": "51123006" },
      { "name": "Antiarrythmic amides", "code": "51123100" },
      { "name": "Acecainide", "code": "51123101" },
      { "name": "Acecainide hydrochloride", "code": "51123102" },
      { "name": "Droxicainide", "code": "51123103" },
      { "name": "Actisomide", "code": "51123104" },
      { "name": "Droxicainide hydrochloride", "code": "51123105" },
      { "name": "Encainide", "code": "51123106" },
      { "name": "Dofetilide", "code": "51123107" },
      { "name": "Encainide hydrochloride", "code": "51123108" },
      { "name": "Epicainide", "code": "51123109" },
      { "name": "Ibutilide", "code": "51123110" },
      { "name": "Ibutilide fumarate", "code": "51123111" },
      { "name": "Modecainide", "code": "51123112" },
      { "name": "Tocainide", "code": "51123113" },
      { "name": "Procainamide", "code": "51123114" },
      { "name": "Pincainide", "code": "51123115" },
      { "name": "Procainamide hydrochloride", "code": "51123116" },
      { "name": "Pilsicainide", "code": "51123117" },
      { "name": "Pilsicainide hydrochloride", "code": "51123118" },
      { "name": "Tocainide hydrochloride", "code": "51123119" },
      { "name": "Transcainide", "code": "51123120" },
      { "name": "Antiarrythmic amines", "code": "51123200" },
      { "name": "Aprindine", "code": "51123201" },
      { "name": "Butobendine", "code": "51123202" },
      { "name": "Aprindine hydrochloride", "code": "51123203" },
      { "name": "Butoprozine", "code": "51123204" },
      { "name": "Pentisomide", "code": "51123205" },
      { "name": "Mexiletine hydrochloride", "code": "51123206" },
      { "name": "Mexiletine", "code": "51123207" },
      { "name": "Antiarrythmic azocines", "code": "51123300" },
      { "name": "Asocainol", "code": "51123301" },
      { "name": "Asocainol hydrochloride", "code": "51123302" },
      { "name": "Antiarrythmic azoles", "code": "51123400" },
      { "name": "Cibenzoline", "code": "51123401" },
      { "name": "Dazolicine", "code": "51123402" },
      { "name": "Antiarrythmic benzazepines", "code": "51123500" },
      { "name": "Ivabradine", "code": "51123501" },
      { "name": "Zatebradine", "code": "51123502" },
      { "name": "Antiarrythmic benzofurans", "code": "51123600" },
      { "name": "Amiodarone", "code": "51123601" },
      { "name": "Dronedarone", "code": "51123602" },
      { "name": "Antiarrythmic cyclic hydrocarbons", "code": "51123700" },
      { "name": "Ambasilide", "code": "51123701" },
      { "name": "Tedisamil", "code": "51123702" },
      { "name": "Tedisamil sesquifumarate", "code": "51123703" },
      { "name": "Bisaramil", "code": "51123704" },
      { "name": "Indecainide", "code": "51123705" },
      { "name": "Bunaftine", "code": "51123706" },
      { "name": "Bunaftine citrate", "code": "51123707" },
      { "name": "Bunaftine hydrochloride", "code": "51123708" },
      { "name": "Berlafenone", "code": "51123709" },
      { "name": "Antiarrythmic guanidines", "code": "51123800" },
      { "name": "Meobentine sulfate", "code": "51123801" },
      { "name": "Meobentine", "code": "51123802" },
      { "name": "Antiarrythmic indoles", "code": "51123900" },
      { "name": "Alprafenone", "code": "51123901" },
      { "name": "Eproxindine", "code": "51123902" },
      { "name": "Rilozarone", "code": "51123903" },
      { "name": "Diprafenone", "code": "51123904" },
      { "name": "Ubisindine", "code": "51123905" },
      { "name": "Propafenone", "code": "51123906" },
      { "name": "Antiarrythmic piperidines", "code": "51124000" },
      { "name": "Benrixate", "code": "51124001" },
      { "name": "Disobutamide", "code": "51124002" },
      { "name": "Flecainide", "code": "51124003" },
      { "name": "Pirmenol", "code": "51124004" },
      { "name": "Flecainide acetate", "code": "51124005" },
      { "name": "Pirmenol hydrochloride", "code": "51124006" },
      { "name": "Flecainide monoacetate", "code": "51124007" },
      { "name": "Lorcainide", "code": "51124008" },
      { "name": "Lorcainide hydrochloride", "code": "51124009" },
      { "name": "Antiarrythmic purines", "code": "51124100" },
      { "name": "Adenosine", "code": "51124101" },
      { "name": "Adenosine diphosphate", "code": "51124102" },
      { "name": "Adenosine phosphate", "code": "51124103" },
      { "name": "Adenosine triphosphate", "code": "51124104" },
      { "name": "Antiarrythmic pyridines", "code": "51124200" },
      { "name": "Barucainide", "code": "51124201" },
      { "name": "Disopyramide", "code": "51124202" },
      { "name": "Pyrinoline", "code": "51124203" },
      { "name": "Antiarrythmic quinolines", "code": "51124300" },
      { "name": "Quinacainol", "code": "51124301" },
      { "name": "Quinidine", "code": "51124302" },
      { "name": "Quinidine bisulfate", "code": "51124303" },
      { "name": "Quinidine gluconate", "code": "51124304" },
      { "name": "Quinidine hydrochloride", "code": "51124305" },
      { "name": "Quinidine sulfate", "code": "51124306" },
      {
        "name": "Antiarrythmic cardiac glycosides or cardenolides",
        "code": "51124400"
      },
      { "name": "Acetyldigoxin", "code": "51124401" },
      { "name": "Cymarin", "code": "51124402" },
      { "name": "Digitoxin", "code": "51124403" },
      { "name": "Digoxin", "code": "51124404" },
      { "name": "Metildigoxin", "code": "51124405" },
      { "name": "Antiarrythmic sulfur compounds", "code": "51124500" },
      { "name": "Moracizine or moricizine", "code": "51124501" },
      { "name": "Clamikalant", "code": "51124502" },
      { "name": "Glimepiride", "code": "51124503" },
      { "name": "Hematolic drugs", "code": "51130000" },
      { "name": "Antianemic drugs", "code": "51131500" },
      { "name": "Magnesium pidolate", "code": "51131504" },
      { "name": "Erythropoietin", "code": "51131506" },
      { "name": "Darbepoetin alfa", "code": "51131507" },
      { "name": "Epoetin alfa", "code": "51131508" },
      { "name": "Ferric ammonium citrate", "code": "51131509" },
      { "name": "Heme iron polypeptide", "code": "51131510" },
      { "name": "Carbonyl iron", "code": "51131514" },
      { "name": "Hematin or hemin", "code": "51131515" },
      { "name": "Sodium ferric gluconate", "code": "51131519" },
      { "name": "Plerixafor", "code": "51131520" },
      { "name": "Sodium feredetate", "code": "51131521" },
      { "name": "Iron polysaccharide", "code": "51131522" },
      { "name": "Anticoagulants", "code": "51131600" },
      { "name": "Heparin", "code": "51131602" },
      { "name": "Warfarin", "code": "51131604" },
      { "name": "Sodium citrate", "code": "51131605" },
      { "name": "Sodium apolate", "code": "51131606" },
      { "name": "Enoxaparin", "code": "51131607" },
      { "name": "Lepirudin", "code": "51131608" },
      { "name": "Desirudin", "code": "51131609" },
      { "name": "Ardeparin", "code": "51131610" },
      { "name": "Dalteparin", "code": "51131611" },
      { "name": "Dicoumarol or dicumarol", "code": "51131613" },
      { "name": "Anisindione", "code": "51131614" },
      { "name": "Fondaparinux", "code": "51131615" },
      {
        "name": "Anticoagulant citrate phosphate dextrose solution",
        "code": "51131617"
      },
      { "name": "Beraprost", "code": "51131618" },
      { "name": "Acenocoumarol", "code": "51131619" },
      { "name": "Anagrelide hydrochloride", "code": "51131620" },
      { "name": "Apixaban", "code": "51131621" },
      { "name": "Aprosulate", "code": "51131622" },
      { "name": "Beciparcil", "code": "51131623" },
      { "name": "Dabigatran etexilate mesilate", "code": "51131624" },
      { "name": "Bivalirudin", "code": "51131625" },
      { "name": "Camonagrel", "code": "51131626" },
      { "name": "Cangrelor", "code": "51131627" },
      { "name": "Citric acid", "code": "51131628" },
      { "name": "Clopidogrel", "code": "51131629" },
      { "name": "Clorindione", "code": "51131630" },
      { "name": "Dabigatran", "code": "51131631" },
      { "name": "Diarbarone", "code": "51131632" },
      { "name": "Diphacinone or diphenadione", "code": "51131633" },
      { "name": "Ditazol or ditazole", "code": "51131634" },
      { "name": "Ecraprost", "code": "51131635" },
      { "name": "Efegatran", "code": "51131636" },
      { "name": "Etabenzarone", "code": "51131637" },
      { "name": "Ethyl biscoumacetate", "code": "51131638" },
      { "name": "Fradafiban", "code": "51131639" },
      { "name": "Gabexate", "code": "51131640" },
      { "name": "Nadroparin", "code": "51131641" },
      { "name": "Iloprost", "code": "51131642" },
      { "name": "Indobufen", "code": "51131643" },
      { "name": "Melagatran", "code": "51131644" },
      { "name": "Moxicoumone", "code": "51131645" },
      { "name": "Napsagatran", "code": "51131646" },
      { "name": "Phenindione", "code": "51131647" },
      { "name": "Phenprocoumon", "code": "51131648" },
      { "name": "Prasugrel", "code": "51131649" },
      { "name": "Rivaroxaban", "code": "51131650" },
      { "name": "Sulmarin", "code": "51131651" },
      { "name": "Sulotroban", "code": "51131652" },
      { "name": "Ticlopidine", "code": "51131653" },
      { "name": "Tioclomarol", "code": "51131654" },
      { "name": "Tulopafant", "code": "51131655" },
      { "name": "Vapiprost", "code": "51131656" },
      { "name": "Ximelagatran", "code": "51131657" },
      { "name": "Xylocoumarol", "code": "51131658" },
      { "name": "Ancrod", "code": "51131659" },
      { "name": "Danaparoid", "code": "51131660" },
      { "name": "Drotrecogin alfa", "code": "51131661" },
      { "name": "Parnaparin", "code": "51131662" },
      { "name": "Dabigatran etexilate", "code": "51131663" },
      {
        "name": "Thrombolytic drugs and platelet aggregation inhibitors",
        "code": "51131700"
      },
      { "name": "Abciximab", "code": "51131701" },
      { "name": "Streptokinase", "code": "51131703" },
      { "name": "Urokinase", "code": "51131704" },
      { "name": "Dipyridamole", "code": "51131705" },
      { "name": "Argatroban", "code": "51131706" },
      { "name": "Eptifibatide", "code": "51131710" },
      { "name": "Tenecteplase", "code": "51131711" },
      { "name": "Anistreplase", "code": "51131714" },
      { "name": "Reteplase", "code": "51131716" },
      { "name": "Dacopafant", "code": "51131717" },
      { "name": "Anagrelide", "code": "51131718" },
      { "name": "Apafant", "code": "51131719" },
      { "name": "Benzarone", "code": "51131720" },
      { "name": "Bepafant", "code": "51131721" },
      { "name": "Carafiban", "code": "51131722" },
      { "name": "Cloricromen", "code": "51131723" },
      { "name": "Midazogrel", "code": "51131724" },
      { "name": "Epoprostenol or prostacyclin", "code": "51131725" },
      { "name": "Gantofiban", "code": "51131726" },
      { "name": "Ifetroban", "code": "51131727" },
      { "name": "Iliparcil", "code": "51131728" },
      { "name": "Lamifiban", "code": "51131729" },
      { "name": "Lotrafiban", "code": "51131730" },
      { "name": "Setipafant", "code": "51131731" },
      { "name": "Modipafant", "code": "51131732" },
      { "name": "Naroparcil", "code": "51131733" },
      { "name": "Nicogrelate", "code": "51131734" },
      { "name": "Nupafant", "code": "51131735" },
      { "name": "Ozagrel", "code": "51131736" },
      { "name": "Picotamide", "code": "51131737" },
      { "name": "Rocepafant", "code": "51131738" },
      { "name": "Taprostene", "code": "51131739" },
      { "name": "Ticagrelor", "code": "51131740" },
      { "name": "Trifenagrel", "code": "51131741" },
      { "name": "Triflusal", "code": "51131742" },
      { "name": "Anagrelide hydrochloride monohydrate", "code": "51131743" },
      { "name": "Argatroban anhydrous", "code": "51131744" },
      { "name": "Argatroban monohydrate", "code": "51131745" },
      { "name": "Cloricromen hydrochloride", "code": "51131746" },
      { "name": "Epoprostenol sodium", "code": "51131747" },
      { "name": "Ifetroban sodium", "code": "51131748" },
      { "name": "Lamifiban hydrochloride", "code": "51131749" },
      { "name": "Lotrafiban hydrochloride", "code": "51131750" },
      { "name": "Ozagrel hydrochloride", "code": "51131751" },
      { "name": "Ozagrel hydrochloride hydrate", "code": "51131752" },
      { "name": "Ozagrel sodium", "code": "51131753" },
      { "name": "Taprostene sodium", "code": "51131754" },
      {
        "name": "Coagulants and systemic hemostatic agents",
        "code": "51131800"
      },
      { "name": "Fibrinogen", "code": "51131801" },
      { "name": "Antihemophilic factor or globulin", "code": "51131802" },
      { "name": "Thrombin", "code": "51131803" },
      { "name": "Etamsylate or ethamsylate", "code": "51131805" },
      { "name": "Ferric subsulfate", "code": "51131806" },
      { "name": "Aminocaproic acid", "code": "51131808" },
      { "name": "Calcium dobesilate", "code": "51131809" },
      { "name": "Tranexamic acid", "code": "51131811" },
      { "name": "Romiplostim", "code": "51131812" },
      { "name": "Aminomethylbenzoic acid", "code": "51131813" },
      { "name": "Aprotinin", "code": "51131814" },
      { "name": "Camostat", "code": "51131815" },
      { "name": "Carbazochrome", "code": "51131816" },
      { "name": "Eltrombopag", "code": "51131817" },
      { "name": "Batroxobin", "code": "51131818" },
      { "name": "Coagulation factor ix", "code": "51131819" },
      { "name": "Coagulation factor vii", "code": "51131820" },
      { "name": "Coagulation factor viii", "code": "51131821" },
      { "name": "Coagulation factor xiv or protein c", "code": "51131822" },
      { "name": "Moroctocog alfa", "code": "51131823" },
      { "name": "Camostat mesilate or mesylate", "code": "51131824" },
      { "name": "Carbazochrome salicylate", "code": "51131825" },
      { "name": "Carbazochrome sodium sulfonate", "code": "51131826" },
      { "name": "Eltrombopag olamine", "code": "51131827" },
      {
        "name": "Blood plasma substitutes and extenders and expanders",
        "code": "51131900"
      },
      { "name": "Poligeline or polygeline", "code": "51131901" },
      { "name": "Human blood plasma", "code": "51131904" },
      { "name": "Pentastarch", "code": "51131906" },
      { "name": "Human plasma protein fraction", "code": "51131907" },
      { "name": "Hetastarch", "code": "51131908" },
      { "name": "Albumin human", "code": "51131909" },
      { "name": "Dextran", "code": "51131911" },
      { "name": "Hemorrheologic agents", "code": "51132000" },
      { "name": "Pentoxifylline", "code": "51132001" },
      { "name": "Combination hematolic drugs", "code": "51132100" },
      { "name": "Heparin/sodium chloride", "code": "51132101" },
      { "name": "Aspirin/dipyridamole", "code": "51132102" },
      { "name": "Combination non-opioid analgesics", "code": "51132200" },
      { "name": "Acetaminophen/caffeine", "code": "51132201" },
      {
        "name": "Acetaminophen/aluminum hydroxide/aspirin/caffeine/magnesium hydr",
        "code": "51132202"
      },
      {
        "name": "Acetaminophen/aluminum hydroxide/aspirin/salicylamide",
        "code": "51132203"
      },
      { "name": "Acetaminophen/aspirin", "code": "51132204" },
      { "name": "Acetaminophen/aspirin/caffeine", "code": "51132205" },
      {
        "name": "Acetaminophen/aspirin/caffeine/calcium gluconate",
        "code": "51132206"
      },
      {
        "name": "Acetaminophen/aspirin/caffeine/salicylamide",
        "code": "51132207"
      },
      { "name": "Acetaminophen/aspirin/calcium carbonate", "code": "51132208" },
      { "name": "Acetaminophen/butalbital", "code": "51132209" },
      { "name": "Acetaminophen/butalbital/caffeine", "code": "51132210" },
      { "name": "Acetaminophen/pamabrom/pyrilamine", "code": "51132211" },
      { "name": "Acetaminophen/caffeine/hyoscyamus", "code": "51132212" },
      {
        "name": "Acetaminophen/caffeine/phenyltoloxamine/salicylamide",
        "code": "51132213"
      },
      { "name": "Acetaminophen/caffeine/pyrilamine", "code": "51132214" },
      { "name": "Acetaminophen/caffeine/salicylamide", "code": "51132215" },
      { "name": "Acetaminophen/calcium carbonate", "code": "51132216" },
      {
        "name": "Acetaminophen/calcium carbonate/magnesium carbonate/magnesium ox",
        "code": "51132217"
      },
      { "name": "Acetaminophen/cinnamedrine/pamabrom", "code": "51132218" },
      { "name": "Acetaminophen/pamabrom", "code": "51132219" },
      { "name": "Acetaminophen/pamabrom/pyridoxine", "code": "51132220" },
      { "name": "Aspirin/caffeine", "code": "51132221" },
      { "name": "Acetaminophen/phenobarbital", "code": "51132222" },
      { "name": "Acetaminophen/pyrilamine maleate", "code": "51132223" },
      { "name": "Acetaminophen/salicylamide", "code": "51132224" },
      { "name": "Acetaminophen/tramadol", "code": "51132225" },
      {
        "name": "Aluminum hydroxide/aspirin/calcium carbonate/magnesium hydroxide",
        "code": "51132226"
      },
      {
        "name": "Aluminum hydroxide/aspirin/magnesium hydroxide",
        "code": "51132227"
      },
      { "name": "Aspirin/butalbital", "code": "51132228" },
      { "name": "Aspirin/butalbital/caffeine", "code": "51132229" },
      { "name": "Aspirin/butalbital/caffeine/phenacetin", "code": "51132230" },
      { "name": "Aspirin/meprobamate", "code": "51132231" },
      { "name": "Aspirin/caffeine/cinnamedrine", "code": "51132232" },
      { "name": "Aspirin/caffeine/gelsemium/hyoscyamus", "code": "51132233" },
      { "name": "Aspirin/caffeine/salicylamide", "code": "51132234" },
      {
        "name": "Aspirin/calcium carbonate/magnesium carbonate/magnesium oxide",
        "code": "51132235"
      },
      { "name": "Aspirin/dihydroxyaluminum aminoacetate", "code": "51132236" },
      {
        "name": "Aspirin/dihydroxyaluminum aminoacetate/magnesium carbonate",
        "code": "51132237"
      },
      { "name": "Aspirin/ethoheptazine/meprobamate", "code": "51132238" },
      { "name": "Aspirin/magnesium carbonate", "code": "51132239" },
      { "name": "Aspirin/magnesium oxide", "code": "51132240" },
      {
        "name": "Caffeine/phenacetin/salicylamide/styramate",
        "code": "51132241"
      },
      { "name": "Calcium/ergocalciferol/salicylamide", "code": "51132242" },
      { "name": "Choline salicylate/magnesium salicylate", "code": "51132243" },
      { "name": "Combination volume expanders", "code": "51132300" },
      { "name": "Dextran 40/sodium chloride", "code": "51132301" },
      { "name": "Dextran 70/dextrose", "code": "51132302" },
      { "name": "Dextran 70/sodium chloride", "code": "51132303" },
      { "name": "Dextran 75/sodium chloride", "code": "51132304" },
      { "name": "Hetastarch/electrolytes", "code": "51132305" },
      { "name": "Hetastarch/sodium chloride", "code": "51132306" },
      { "name": "Central nervous system drugs", "code": "51140000" },
      { "name": "Anticonvulsants", "code": "51141500" },
      { "name": "Acetazolamide", "code": "51141501" },
      { "name": "Felbamate", "code": "51141503" },
      { "name": "Lamotrigine", "code": "51141504" },
      { "name": "Phenytoin", "code": "51141507" },
      { "name": "Ethosuximide", "code": "51141508" },
      { "name": "Vigabatrin", "code": "51141509" },
      { "name": "Carbamazepine", "code": "51141513" },
      { "name": "Zonisamide", "code": "51141514" },
      { "name": "Ethotoin", "code": "51141515" },
      { "name": "Fosphenytoin", "code": "51141516" },
      { "name": "Levetiracetam", "code": "51141518" },
      { "name": "Mephenytoin", "code": "51141519" },
      { "name": "Mephobarbital or methylphenobarbital", "code": "51141520" },
      { "name": "Mesuximide or methsuximide", "code": "51141521" },
      { "name": "Oxcarbazepine", "code": "51141522" },
      { "name": "Paramethadione", "code": "51141523" },
      { "name": "Phenacemide", "code": "51141524" },
      { "name": "Primidone", "code": "51141526" },
      { "name": "Topiramate", "code": "51141528" },
      { "name": "Trimethadione", "code": "51141529" },
      { "name": "Valproic acid", "code": "51141531" },
      { "name": "Divalproex", "code": "51141533" },
      { "name": "Cabergoline", "code": "51141535" },
      { "name": "Donepezil", "code": "51141538" },
      { "name": "Decimemide", "code": "51141544" },
      { "name": "Albutoin", "code": "51141545" },
      { "name": "Ameltolide", "code": "51141546" },
      { "name": "Aminobutyric acid", "code": "51141547" },
      { "name": "Atolide", "code": "51141548" },
      { "name": "Beclamide", "code": "51141549" },
      { "name": "Carisbamate", "code": "51141550" },
      { "name": "Ciprazafone", "code": "51141551" },
      { "name": "Ethylphenacemide or pheneturide", "code": "51141552" },
      { "name": "Denzimol", "code": "51141553" },
      { "name": "Dinazafone", "code": "51141554" },
      { "name": "Dulozafone", "code": "51141555" },
      { "name": "Eslicarbazepine", "code": "51141556" },
      { "name": "Eterobarb", "code": "51141557" },
      { "name": "Ethadione", "code": "51141558" },
      { "name": "Loreclezole", "code": "51141559" },
      { "name": "Etiracetam", "code": "51141560" },
      { "name": "Ezogabine or retigabine", "code": "51141561" },
      { "name": "Ilepcimide", "code": "51141562" },
      { "name": "Lacosamide", "code": "51141563" },
      { "name": "Nonapyrimine", "code": "51141564" },
      { "name": "Losigamone", "code": "51141565" },
      { "name": "Epsom salt or magnesium sulfate", "code": "51141566" },
      { "name": "Milacemide", "code": "51141567" },
      { "name": "Morsuximide", "code": "51141568" },
      { "name": "Nafimidone", "code": "51141569" },
      { "name": "Riluzole", "code": "51141570" },
      { "name": "Phensuximide", "code": "51141571" },
      { "name": "Pivagabine", "code": "51141572" },
      { "name": "Potassium bromide", "code": "51141573" },
      { "name": "Progabide", "code": "51141574" },
      { "name": "Ralitoline", "code": "51141575" },
      { "name": "Valproate pivoxil", "code": "51141576" },
      { "name": "Ropizine", "code": "51141577" },
      { "name": "Rufinamide", "code": "51141578" },
      { "name": "Stiripentol", "code": "51141579" },
      { "name": "Sultiame", "code": "51141580" },
      { "name": "Taltrimide", "code": "51141581" },
      { "name": "Tiagabine", "code": "51141582" },
      { "name": "Tolgabide", "code": "51141583" },
      { "name": "Valpromide", "code": "51141584" },
      { "name": "Vinpocetine", "code": "51141585" },
      { "name": "Zoniclezole", "code": "51141586" },
      { "name": "Acetazolamide sodium", "code": "51141587" },
      { "name": "Cabergoline diphosphate", "code": "51141588" },
      { "name": "Carbamazepine dihydrate", "code": "51141589" },
      { "name": "Donepezil hydrochloride", "code": "51141590" },
      { "name": "Donepezil oxalate", "code": "51141591" },
      { "name": "Eslicarbazepine acetate", "code": "51141592" },
      { "name": "Fosphenytoin sodium", "code": "51141593" },
      { "name": "Milacemide hydrochloride", "code": "51141594" },
      { "name": "Nafimidone hydrochloride", "code": "51141595" },
      { "name": "Phenytoin sodium", "code": "51141596" },
      { "name": "Tiagabine hydrochloride", "code": "51141597" },
      { "name": "Zoniclezole hydrochloride", "code": "51141598" },
      {
        "name": "Non narcotic analgesics and antipyretics",
        "code": "51142000"
      },
      { "name": "Methyl butetisalicylate", "code": "51142005" },
      { "name": "Metamizol or metamizole", "code": "51142009" },
      { "name": "Sodium thiosalicylate", "code": "51142014" },
      { "name": "Dehydrated alcohol injection", "code": "51142018" },
      { "name": "Filantor or filenadol", "code": "51142019" },
      { "name": "Acetaminosalol", "code": "51142020" },
      { "name": "Bicifadine", "code": "51142021" },
      { "name": "Dibupyrone", "code": "51142022" },
      { "name": "Dixyrazine", "code": "51142023" },
      { "name": "Dronabinol", "code": "51142024" },
      { "name": "Ethoheptazine", "code": "51142025" },
      { "name": "Flucarbril", "code": "51142026" },
      { "name": "Flupirtine", "code": "51142027" },
      { "name": "Phenacetin or phenacetinum", "code": "51142028" },
      { "name": "Phenicarbazide", "code": "51142029" },
      { "name": "Phenyl salicylate or salol", "code": "51142030" },
      { "name": "Pidolacetamol", "code": "51142031" },
      { "name": "Prednazate", "code": "51142032" },
      { "name": "Propacetamol", "code": "51142033" },
      { "name": "Xylazine", "code": "51142034" },
      { "name": "Ziconotide", "code": "51142035" },
      { "name": "Nicoboxil", "code": "51142036" },
      { "name": "Bucetin", "code": "51142037" },
      { "name": "Bicifadine hydrochloride", "code": "51142038" },
      { "name": "Ethoheptazine citrate", "code": "51142039" },
      { "name": "Ethoheptazine hydrochloride", "code": "51142040" },
      { "name": "Flupirtine gluconate", "code": "51142041" },
      { "name": "Flupirtine maleate", "code": "51142042" },
      { "name": "Propacetamol hydrochloride", "code": "51142043" },
      { "name": "Xylazine hydrochloride", "code": "51142044" },
      { "name": "Ziconotide acetate", "code": "51142045" },
      { "name": "Narcotic antagonists", "code": "51142300" },
      { "name": "Nalmefene", "code": "51142301" },
      { "name": "Naloxone", "code": "51142302" },
      { "name": "Naltrexone", "code": "51142303" },
      { "name": "Levallorphan", "code": "51142307" },
      { "name": "Nalorphine", "code": "51142308" },
      { "name": "Levallorphan tartrate", "code": "51142309" },
      { "name": "Nalmefene hydrochloride", "code": "51142310" },
      { "name": "Nalmefene hydrochloride dihydrate", "code": "51142311" },
      { "name": "Nalorphine bromide", "code": "51142312" },
      { "name": "Nalorphine hydrobromide", "code": "51142313" },
      { "name": "Nalorphine hydrochloride", "code": "51142314" },
      { "name": "Naloxone hydrochloride", "code": "51142315" },
      { "name": "Naloxone hydrochloride dihydrate", "code": "51142316" },
      { "name": "Naltrexone hydrochloride", "code": "51142317" },
      { "name": "Naltrexone methobromide", "code": "51142318" },
      {
        "name": "Drugs used for vascular and migraine headaches",
        "code": "51142400"
      },
      { "name": "Methysergide", "code": "51142401" },
      { "name": "Dihydroergotamine", "code": "51142404" },
      {
        "name": "Combination acetaminophen acetylsalicylic acid",
        "code": "51142405"
      },
      { "name": "Frovatriptan", "code": "51142408" },
      { "name": "Rizatriptan", "code": "51142411" },
      { "name": "Eletriptan", "code": "51142412" },
      { "name": "Zolmitriptan", "code": "51142413" },
      { "name": "Sumatriptan", "code": "51142414" },
      { "name": "Combination acetaminophen butalbital", "code": "51142415" },
      { "name": "Iprazochrome", "code": "51142416" },
      { "name": "Almotriptan", "code": "51142417" },
      { "name": "Donitriptan", "code": "51142418" },
      { "name": "Dotarizine", "code": "51142419" },
      { "name": "Ergotamine", "code": "51142420" },
      { "name": "Naratriptan", "code": "51142421" },
      { "name": "Oxetorone", "code": "51142422" },
      { "name": "Almotriptan malate", "code": "51142423" },
      { "name": "Dihydroergotamine mesilate or mesylate", "code": "51142424" },
      { "name": "Dihydroergotamine methanesulfonate", "code": "51142425" },
      { "name": "Donitriptan hydrochloride", "code": "51142426" },
      { "name": "Eletriptan hydrobromide", "code": "51142427" },
      { "name": "Ergotamine tartrate", "code": "51142428" },
      { "name": "Frovatriptan succinate", "code": "51142429" },
      { "name": "Frovatriptan succinate hydrate", "code": "51142430" },
      { "name": "Methysergide maleate", "code": "51142431" },
      { "name": "Naratriptan hydrochloride", "code": "51142432" },
      { "name": "Rizatriptan benzoate", "code": "51142433" },
      { "name": "Rizatriptan sulfate", "code": "51142434" },
      { "name": "Sumatriptan succinate", "code": "51142435" },
      { "name": "Antiparkinson drugs", "code": "51142500" },
      { "name": "Bromocriptine", "code": "51142501" },
      { "name": "Carbidopa", "code": "51142502" },
      { "name": "Levodopa", "code": "51142503" },
      { "name": "Selegiline", "code": "51142504" },
      { "name": "Pergolide", "code": "51142507" },
      { "name": "Pramipexole", "code": "51142508" },
      { "name": "Ropinirole", "code": "51142509" },
      { "name": "Entacapone", "code": "51142511" },
      { "name": "Tropacine", "code": "51142512" },
      { "name": "Rasagiline", "code": "51142513" },
      { "name": "Adrogolide", "code": "51142515" },
      { "name": "Tolcapone", "code": "51142516" },
      { "name": "Amantadine", "code": "51142517" },
      { "name": "Apomorphine or apomorphinum", "code": "51142518" },
      { "name": "Brasofensine", "code": "51142519" },
      { "name": "Budipine", "code": "51142520" },
      { "name": "Ciladopa", "code": "51142521" },
      { "name": "Dihydroergocryptine", "code": "51142522" },
      { "name": "Diprobutine", "code": "51142523" },
      { "name": "Doreptide", "code": "51142524" },
      { "name": "Droxidopa", "code": "51142525" },
      { "name": "Ethopropazine or profenamine", "code": "51142526" },
      { "name": "Etilevodopa", "code": "51142527" },
      { "name": "Ifenprodil", "code": "51142528" },
      { "name": "Lazabemide", "code": "51142529" },
      { "name": "Lisuride", "code": "51142530" },
      { "name": "Mazaticol", "code": "51142531" },
      { "name": "Melevodopa", "code": "51142532" },
      { "name": "Memantine", "code": "51142533" },
      { "name": "Mofegiline", "code": "51142534" },
      { "name": "Phenglutarimide", "code": "51142535" },
      { "name": "Piribedil", "code": "51142536" },
      { "name": "Piroheptine", "code": "51142537" },
      { "name": "Rotigotine", "code": "51142538" },
      { "name": "Adrogolide hydrochloride", "code": "51142539" },
      { "name": "Amantadine hydrochloride", "code": "51142540" },
      { "name": "Amantadine sulfate", "code": "51142541" },
      { "name": "Apomorphine diacetate", "code": "51142542" },
      { "name": "Apomorphine hydrochloride", "code": "51142543" },
      { "name": "Apomorphine hydrochloride anhydrous", "code": "51142544" },
      { "name": "Brasofensine maleate", "code": "51142545" },
      { "name": "Bromocriptine mesilate or mesylate", "code": "51142546" },
      { "name": "Bromocriptine methanesulfonate", "code": "51142547" },
      { "name": "Budipine hydrochloride", "code": "51142548" },
      { "name": "Carbidopa anhydrous", "code": "51142549" },
      { "name": "Ciladopa hydrochloride", "code": "51142550" },
      { "name": "Ifenprodil tartrate", "code": "51142551" },
      { "name": "Lazabemide hydrochloride", "code": "51142552" },
      { "name": "Lazabemide monohydrochloride", "code": "51142553" },
      { "name": "Lisuride maleate", "code": "51142554" },
      { "name": "Mazaticol hydrochloride", "code": "51142555" },
      { "name": "Melevodopa hydrochloride", "code": "51142556" },
      { "name": "Memantine hydrochloride", "code": "51142557" },
      { "name": "Mofegiline hydrochloride", "code": "51142558" },
      { "name": "Pergolide mesilate or mesylate", "code": "51142559" },
      { "name": "Phenglutarimide hydrochloride", "code": "51142560" },
      { "name": "Piribedil mesilate or mesylate", "code": "51142561" },
      { "name": "Piribedil monomethanesulfonate", "code": "51142562" },
      { "name": "Piroheptine hydrochloride", "code": "51142563" },
      { "name": "Pramipexole dihydrochloride", "code": "51142564" },
      { "name": "Pramipexole dihydrochloride monohydrate", "code": "51142565" },
      { "name": "Pramipexole hydrochloride hydrate", "code": "51142566" },
      { "name": "Profenamine hydrochloride", "code": "51142567" },
      { "name": "Rasagiline mesilate or mesylate", "code": "51142568" },
      { "name": "Rasagiline methanesulfonate", "code": "51142569" },
      { "name": "Ropinirole hydrochloride", "code": "51142570" },
      { "name": "Rotigotine hydrochloride", "code": "51142571" },
      { "name": "Selegiline hydrochloride", "code": "51142572" },
      { "name": "Stimulants and anorexiants", "code": "51142600" },
      {
        "name": "Amfepramone or dexfenfluramine or diethylcathinone or diethylpropion",
        "code": "51142611"
      },
      { "name": "Flurothyl", "code": "51142642" },
      {
        "name": "Fenethylline or fenetylline or phenethylline",
        "code": "51142643"
      },
      { "name": "Orlistat", "code": "51142650" },
      { "name": "Oxiracetam", "code": "51142658" },
      { "name": "Nootropics", "code": "51143000" },
      { "name": "Imuracetam", "code": "51143001" },
      { "name": "Aceglutamide", "code": "51143002" },
      { "name": "Aloracetam", "code": "51143003" },
      { "name": "Amifampridine", "code": "51143004" },
      { "name": "Brivaracetam", "code": "51143005" },
      { "name": "Choline alfoscerate", "code": "51143006" },
      { "name": "Coluracetam", "code": "51143007" },
      { "name": "Dimiracetam", "code": "51143008" },
      { "name": "Dalfampridine or fampridine", "code": "51143009" },
      { "name": "Fasoracetam", "code": "51143010" },
      { "name": "Vinconate", "code": "51143011" },
      { "name": "Leteprinim", "code": "51143012" },
      { "name": "Centrophenoxine or meclofenoxate", "code": "51143013" },
      { "name": "Molracetam", "code": "51143014" },
      { "name": "Nebracetam", "code": "51143015" },
      { "name": "Nefiracetam", "code": "51143016" },
      { "name": "Nicoracetam", "code": "51143017" },
      { "name": "Rolziracetam", "code": "51143018" },
      { "name": "Sulbutiamine", "code": "51143019" },
      { "name": "Aceglutamide aluminium", "code": "51143020" },
      { "name": "Aceglutamide aluminum", "code": "51143021" },
      { "name": "Leteprinim potassium", "code": "51143022" },
      { "name": "Vinconate hydrochloride", "code": "51143023" },
      { "name": "Salicylates", "code": "51143100" },
      { "name": "Salicylic acid", "code": "51143101" },
      { "name": "Aloxiprin or aluminium acetylsalicylate", "code": "51143102" },
      { "name": "Acetylsalicylic acid or Aspirin", "code": "51143103" },
      { "name": "Bismuth subsalicylate", "code": "51143104" },
      { "name": "Diflunisal", "code": "51143105" },
      { "name": "Salicin", "code": "51143107" },
      { "name": "Salicylamide", "code": "51143108" },
      { "name": "Sodium salicylate", "code": "51143109" },
      { "name": "Triethanolamine salicylate", "code": "51143110" },
      { "name": "Salicylamide sodium", "code": "51143111" },
      { "name": "Anti abuse drugs", "code": "51143200" },
      { "name": "Disulfiram", "code": "51143201" },
      { "name": "Nicotine", "code": "51143202" },
      { "name": "Varenicline", "code": "51143203" },
      { "name": "Acamprosate", "code": "51143204" },
      { "name": "Altinicline", "code": "51143205" },
      { "name": "Calcium carbimide", "code": "51143206" },
      { "name": "Lobeline", "code": "51143207" },
      { "name": "Silver acetate", "code": "51143208" },
      { "name": "Acamprosate calcium", "code": "51143209" },
      { "name": "Altinicline maleate", "code": "51143210" },
      { "name": "Lobeline hydrochloride", "code": "51143211" },
      { "name": "Lobeline sulfate", "code": "51143212" },
      { "name": "Lobeline sulphate", "code": "51143213" },
      { "name": "Nicotine bitartrate", "code": "51143214" },
      { "name": "Nicotine dihydrochloride", "code": "51143215" },
      { "name": "Nicotine hydrochloride", "code": "51143216" },
      { "name": "Nicotine hydrogen tartrate", "code": "51143217" },
      { "name": "Nicotine polacrilex", "code": "51143218" },
      { "name": "Nicotine salicylate", "code": "51143219" },
      { "name": "Nicotine sulfate", "code": "51143220" },
      { "name": "Nicotine tartrate", "code": "51143221" },
      { "name": "Varenicline tartrate", "code": "51143222" },
      { "name": "Combination anticonvulsants", "code": "51143300" },
      { "name": "Phenobarbital/phenytoin", "code": "51143301" },
      { "name": "Combination antimigraine agents", "code": "51143400" },
      { "name": "Acetaminophen/caffeine/isometheptene", "code": "51143401" },
      {
        "name": "Acetaminophen/dichloralphenazone/isometheptene",
        "code": "51143402"
      },
      {
        "name": "Belladonna/caffeine/ergotamine/pentobarbital",
        "code": "51143403"
      },
      {
        "name": "Belladonna/caffeine/ergotamine/phenobarbital",
        "code": "51143404"
      },
      { "name": "Belladonna/ergotamine/phenobarbital", "code": "51143405" },
      { "name": "Caffeine/ergotamine", "code": "51143406" },
      { "name": "Ergotamine/hyoscyamine/phenobarbital", "code": "51143407" },
      { "name": "Naproxen/sumatriptan", "code": "51143408" },
      { "name": "Combination antiparkinson agents", "code": "51143500" },
      { "name": "Carbidopa/entacapone/levodopa", "code": "51143501" },
      { "name": "Carbidopa/levodopa", "code": "51143502" },
      { "name": "Combination antivertigo agents", "code": "51143600" },
      { "name": "Dimenhydrinate/niacin", "code": "51143601" },
      { "name": "Niacin/pheniramine", "code": "51143602" },
      { "name": "Combination CNS medications", "code": "51143700" },
      { "name": "Liver/phenobarbital", "code": "51143701" },
      { "name": "Acetaminophen/diphenhydramine", "code": "51143702" },
      { "name": "Amitriptyline/chlordiazepoxide", "code": "51143703" },
      { "name": "Amitriptyline/perphenazine", "code": "51143704" },
      { "name": "Benactyzine/meprobamate", "code": "51143705" },
      {
        "name": "Calcium gluconate/niacinamide/phenobarbital",
        "code": "51143706"
      },
      {
        "name": "Camphor/hyoscyamus/passion flower/phenobarbital/valerian",
        "code": "51143707"
      },
      { "name": "Dextromethorphan/quinidine", "code": "51143708" },
      { "name": "Diphenhydramine/ibuprofen", "code": "51143709" },
      { "name": "Fluoxetine/olanzapine", "code": "51143710" },
      { "name": "Niacin/niacinamide/phenobarbital", "code": "51143711" },
      { "name": "Niacin/pentylenetetrazol", "code": "51143712" },
      { "name": "Combination CNS stimulants", "code": "51143800" },
      { "name": "Amphetamine/dextroamphetamine", "code": "51143801" },
      { "name": "Caffeine/sodium benzoate", "code": "51143802" },
      { "name": "Stimulant or anorexiant alcohols", "code": "51143900" },
      { "name": "Cathine hydrochloride", "code": "51143901" },
      { "name": "Cathine or norpseudoephedrine", "code": "51143902" },
      { "name": "Deanol", "code": "51143903" },
      { "name": "Deanol aceglumate", "code": "51143904" },
      { "name": "Hexacyclonate", "code": "51143905" },
      { "name": "Indanorex", "code": "51143906" },
      { "name": "Mazindol", "code": "51143907" },
      {
        "name": "Pyridoxine disulfide or pyrithioxine or pyritinol",
        "code": "51143908"
      },
      { "name": "Stimulant or anorexiant amines", "code": "51144000" },
      { "name": "Acridorex", "code": "51144001" },
      { "name": "Alfetamine", "code": "51144002" },
      { "name": "Azalanstat", "code": "51144003" },
      { "name": "Azalanstat dihydrochloride", "code": "51144004" },
      { "name": "Clominorex", "code": "51144005" },
      { "name": "Dexmethylphenidate", "code": "51144006" },
      { "name": "Dexmethylphenidate hydrochloride", "code": "51144007" },
      { "name": "Fencamfamin hydrochloride", "code": "51144008" },
      { "name": "Fencamfamin or fencamfamine", "code": "51144009" },
      { "name": "Fenfluramine", "code": "51144010" },
      { "name": "Fenfluramine hydrochloride", "code": "51144011" },
      { "name": "Fenisorex", "code": "51144012" },
      { "name": "Fluminorex", "code": "51144013" },
      { "name": "Methylphenidate", "code": "51144014" },
      { "name": "Methylphenidate hydrochloride", "code": "51144015" },
      { "name": "Morforex", "code": "51144016" },
      { "name": "Tiflorex", "code": "51144017" },
      { "name": "Stimulant or anorexiant amphetamines", "code": "51144100" },
      { "name": "Amfecloral or amphecloral", "code": "51144101" },
      { "name": "Amfepentorex", "code": "51144102" },
      { "name": "Amfetamine or amphetamine", "code": "51144103" },
      { "name": "Amphetamine adipate", "code": "51144104" },
      { "name": "Amfepramone hydrochloride", "code": "51144105" },
      { "name": "Amfetaminil or amphetaminil", "code": "51144106" },
      { "name": "Amphetamine aspartate", "code": "51144107" },
      { "name": "Amphetamine hydrochloride", "code": "51144108" },
      { "name": "Amphetamine phosphate", "code": "51144109" },
      { "name": "Amphetamine sulfate", "code": "51144110" },
      { "name": "Benzphetamine", "code": "51144111" },
      { "name": "Chlorphentermine", "code": "51144112" },
      { "name": "Clobenzorex", "code": "51144113" },
      { "name": "Clortermine", "code": "51144114" },
      { "name": "Dextroamphetamine", "code": "51144115" },
      {
        "name": "Dimethylamphetamine or dimetamfetamine or n,n-dimethylamphetamine",
        "code": "51144116"
      },
      {
        "name": "Etilamfetamine or ethylamphetamine or n-ethylamphetamine",
        "code": "51144117"
      },
      { "name": "Amphetamine tartrate", "code": "51144118" },
      { "name": "Etolorex", "code": "51144119" },
      { "name": "Fenproporex", "code": "51144120" },
      { "name": "Formetorex", "code": "51144121" },
      { "name": "Furfenorex", "code": "51144122" },
      { "name": "Levamfetamine", "code": "51144123" },
      { "name": "Lisdexamfetamine", "code": "51144124" },
      { "name": "Mefenorex", "code": "51144125" },
      { "name": "Levamfetamine succinate", "code": "51144126" },
      { "name": "Lisdexamfetamine dimesylate", "code": "51144127" },
      { "name": "Metamfetamine or methamphetamine", "code": "51144128" },
      { "name": "Lisdexamfetamine mesilate or mesylate", "code": "51144129" },
      { "name": "Phentermine", "code": "51144130" },
      { "name": "Stimulant or anorexiant azoles", "code": "51144200" },
      { "name": "Aminorex", "code": "51144201" },
      { "name": "Fenozolone", "code": "51144202" },
      { "name": "Nizofenone", "code": "51144203" },
      { "name": "Pemoline", "code": "51144204" },
      { "name": "Stimulant or anorexiant morpholines", "code": "51144300" },
      { "name": "Phendimetrazine", "code": "51144301" },
      { "name": "Phendimetrazine bitartrate", "code": "51144302" },
      { "name": "Phendimetrazine tartrate", "code": "51144303" },
      { "name": "Phenmetrazine", "code": "51144304" },
      { "name": "Stimulant or anorexiant piperazines", "code": "51144400" },
      { "name": "Fipexide", "code": "51144401" },
      { "name": "Pipradrol", "code": "51144402" },
      { "name": "Rimonabant", "code": "51144403" },
      { "name": "Stimulant or anorexiant pyrrolidines", "code": "51144500" },
      { "name": "Pramiracetam hydrochloride", "code": "51144501" },
      { "name": "Pramiracetam sulfate", "code": "51144502" },
      { "name": "Prolintane hydrochloride", "code": "51144503" },
      { "name": "Pyrovalerone hydrochloride", "code": "51144504" },
      { "name": "Stimulant or anorexiant alkaloids", "code": "51144600" },
      { "name": "Caffeine or trimethylxanthine", "code": "51144601" },
      { "name": "Diethylpropion hydrochloride", "code": "51144602" },
      { "name": "Propentofylline", "code": "51144603" },
      { "name": "Stimulant or anorexiant amides", "code": "51144700" },
      { "name": "Flucetorex", "code": "51144701" },
      { "name": "Nikethamide", "code": "51144702" },
      {
        "name": "Stimulant or anorexiant benzene derivatives",
        "code": "51144800"
      },
      { "name": "Etamivan or ethamivan", "code": "51144801" },
      {
        "name": "Stimulant or anorexiant carboxylic acids",
        "code": "51144900"
      },
      { "name": "Fenbutrazate", "code": "51144901" },
      { "name": "Stimulant or anorexiant indoles", "code": "51145000" },
      { "name": "Linopirdine", "code": "51145001" },
      { "name": "Stimulant or anorexiant oxadiazoles", "code": "51145100" },
      { "name": "Mesocarb", "code": "51145101" },
      { "name": "Stimulant or anorexiant piperidines", "code": "51145200" },
      { "name": "Difemetorex", "code": "51145201" },
      { "name": "Levofacetoperane or levophacetoperane", "code": "51145202" },
      { "name": "Stimulant or anorexiant pyrrolidones", "code": "51145300" },
      { "name": "Aniracetam", "code": "51145301" },
      { "name": "Pramiracetam", "code": "51145302" },
      { "name": "Prolintane", "code": "51145303" },
      { "name": "Pyrovalerone", "code": "51145304" },
      { "name": "Picilorex", "code": "51145305" },
      { "name": "Autonomic nervous system drugs", "code": "51150000" },
      {
        "name": "Cholinergic drugs and cholinesterase inhibitors",
        "code": "51151500"
      },
      { "name": "Bethanechol", "code": "51151501" },
      { "name": "Pilocarpine", "code": "51151504" },
      { "name": "Tropatepine hydrochlorate", "code": "51151505" },
      { "name": "Esterase inhibitor", "code": "51151506" },
      { "name": "Ambenonium", "code": "51151507" },
      { "name": "Galantamine", "code": "51151510" },
      { "name": "Neostigmine", "code": "51151512" },
      { "name": "Pyridostigmine", "code": "51151514" },
      { "name": "Tacrine", "code": "51151516" },
      { "name": "Cevimeline", "code": "51151517" },
      { "name": "Acetylcholine", "code": "51151519" },
      { "name": "Aclatonium napadisilate", "code": "51151520" },
      { "name": "Benzpyrinium", "code": "51151521" },
      { "name": "Hexafluorenium or hexafluronium", "code": "51151522" },
      { "name": "Distigmine", "code": "51151523" },
      {
        "name": "Diisopropyl fluorophosphate or dyflos or fluostigmine or isoflurophate",
        "code": "51151524"
      },
      { "name": "Furtrethonium iodide", "code": "51151525" },
      { "name": "Zanapezil", "code": "51151526" },
      { "name": "Metrifonate or trichlorfon", "code": "51151527" },
      { "name": "Quilostigmine", "code": "51151528" },
      { "name": "Rivastigmine", "code": "51151529" },
      { "name": "Telenzepine", "code": "51151530" },
      { "name": "Zifrosilone", "code": "51151531" },
      { "name": "Aceclidine", "code": "51151532" },
      { "name": "Aceclidine hydrochloride", "code": "51151533" },
      { "name": "Aceclidine salicylate", "code": "51151534" },
      { "name": "Acetylcholine chloride", "code": "51151535" },
      { "name": "Ambenonium chloride", "code": "51151536" },
      { "name": "Benzpyrinium bromide", "code": "51151537" },
      { "name": "Cevimeline hydrochloride", "code": "51151538" },
      { "name": "Cevimeline hydrochloride hemihydrate", "code": "51151539" },
      { "name": "Distigmine bromide", "code": "51151540" },
      { "name": "Galantamine hydrobromide", "code": "51151541" },
      { "name": "Hexafluronium bromide", "code": "51151542" },
      { "name": "Neostigmine bromide", "code": "51151543" },
      { "name": "Pilocarpine hydrochloride", "code": "51151544" },
      { "name": "Pilocarpine monohydrochloride", "code": "51151545" },
      { "name": "Pilocarpine mononitrate", "code": "51151546" },
      { "name": "Pilocarpine nitrate", "code": "51151547" },
      { "name": "Pyridostigmine bromide", "code": "51151548" },
      { "name": "Rivastigmine hydrogen tartrate", "code": "51151549" },
      { "name": "Rivastigmine hydrogentartrate", "code": "51151550" },
      { "name": "Tacrine hydrochloride", "code": "51151551" },
      { "name": "Cholinergic blocking agents", "code": "51151600" },
      { "name": "Benztropine or benzatropine", "code": "51151602" },
      { "name": "Procyclidine", "code": "51151603" },
      { "name": "Trihexyphenidyl", "code": "51151604" },
      { "name": "Tropicamide", "code": "51151606" },
      { "name": "Clidinium", "code": "51151607" },
      { "name": "Dicyclomine or dicycloverine", "code": "51151608" },
      { "name": "Propantheline", "code": "51151609" },
      { "name": "Glycopyrrolate", "code": "51151611" },
      { "name": "Hyoscyamine sulfate", "code": "51151612" },
      { "name": "Homatropine hydrobromide", "code": "51151614" },
      { "name": "Atropine", "code": "51151616" },
      { "name": "Biperiden", "code": "51151617" },
      { "name": "Ambutonium", "code": "51151618" },
      { "name": "Anisotropine", "code": "51151619" },
      { "name": "Benactyzine", "code": "51151620" },
      { "name": "Benzilone or benzilonium", "code": "51151621" },
      { "name": "Bevonium", "code": "51151622" },
      { "name": "Bornaprine", "code": "51151623" },
      { "name": "Butinoline", "code": "51151624" },
      { "name": "Butylscopolamine", "code": "51151625" },
      { "name": "Camylofin", "code": "51151626" },
      { "name": "Chlorbenzoxamine", "code": "51151627" },
      { "name": "Cimetropium", "code": "51151628" },
      { "name": "Cycrimine", "code": "51151629" },
      { "name": "Deptropine or dibenzheptropine", "code": "51151630" },
      { "name": "Dexetimide", "code": "51151631" },
      { "name": "Difemerine", "code": "51151632" },
      { "name": "Dihexyverine", "code": "51151633" },
      { "name": "Diphemanil", "code": "51151634" },
      { "name": "Gallamine", "code": "51151635" },
      { "name": "Edrophonium chloride", "code": "51151636" },
      { "name": "Elantrine", "code": "51151637" },
      { "name": "Emepronium", "code": "51151638" },
      {
        "name": "Ethybenztropin or ethybenztropine or tropethydrylin",
        "code": "51151639"
      },
      { "name": "Fenpiverinium", "code": "51151640" },
      { "name": "Fentonium", "code": "51151641" },
      { "name": "Flavoxate", "code": "51151642" },
      { "name": "Hexocyclium", "code": "51151643" },
      { "name": "Homatropine", "code": "51151644" },
      { "name": "Isopropamide", "code": "51151645" },
      { "name": "Methylatropine", "code": "51151646" },
      { "name": "Leiopyrrole", "code": "51151647" },
      { "name": "Mebeverine", "code": "51151648" },
      { "name": "Mepenzolate", "code": "51151649" },
      { "name": "Methantheline", "code": "51151650" },
      { "name": "Methixene or metixene", "code": "51151651" },
      { "name": "Methscopolamine or methylscopolamine", "code": "51151652" },
      { "name": "Prifinium", "code": "51151653" },
      { "name": "Obidoxime", "code": "51151654" },
      { "name": "Otenzepad", "code": "51151655" },
      { "name": "Otilonium", "code": "51151656" },
      { "name": "Oxybutynin", "code": "51151657" },
      { "name": "Oxyphenonium", "code": "51151658" },
      { "name": "Pipenzolate", "code": "51151659" },
      { "name": "Piperidolate", "code": "51151660" },
      { "name": "Solifenacin", "code": "51151661" },
      { "name": "Tiemonium", "code": "51151662" },
      { "name": "Tifenamil", "code": "51151663" },
      { "name": "Timepidium", "code": "51151664" },
      { "name": "Zamifenacin", "code": "51151665" },
      { "name": "Tolterodine", "code": "51151666" },
      { "name": "Tridihexethyl", "code": "51151667" },
      { "name": "Trimebutine", "code": "51151668" },
      { "name": "Trospium chloride", "code": "51151669" },
      { "name": "Tuamine or tuaminoheptane", "code": "51151670" },
      { "name": "Vamicamide", "code": "51151671" },
      {
        "name": "Centrally acting skeletal muscle relaxants",
        "code": "51151900"
      },
      { "name": "Chlorzoxazone", "code": "51151902" },
      { "name": "Methocarbamol", "code": "51151904" },
      { "name": "Carisoprodol", "code": "51151905" },
      { "name": "Thiocolchicoside", "code": "51151912" },
      { "name": "Phenprobamate", "code": "51151921" },
      { "name": "Styramate", "code": "51151922" },
      { "name": "Neuromuscular blocking agents", "code": "51152000" },
      { "name": "Atracurium", "code": "51152001" },
      { "name": "Rocuronium", "code": "51152003" },
      { "name": "Vecuronium", "code": "51152004" },
      { "name": "Botulinum toxin", "code": "51152005" },
      { "name": "Cisatracurium", "code": "51152006" },
      { "name": "Doxacurium", "code": "51152007" },
      { "name": "Dimethyltubocurarine or metocurine", "code": "51152008" },
      { "name": "Pancuronium", "code": "51152009" },
      { "name": "Rapacuronium", "code": "51152010" },
      { "name": "Tubocurarine", "code": "51152011" },
      { "name": "Pipecuronium", "code": "51152012" },
      { "name": "Cinflumide", "code": "51152013" },
      { "name": "Abobotulinumtoxina", "code": "51152014" },
      { "name": "Afloqualone", "code": "51152015" },
      { "name": "Alcuronium", "code": "51152016" },
      { "name": "Baclofen", "code": "51152017" },
      { "name": "Butamisole", "code": "51152018" },
      { "name": "Butamisole hydrochloride", "code": "51152019" },
      { "name": "Tubocurarine or d-tubocurarine or DTC", "code": "51152020" },
      { "name": "Clodanolene", "code": "51152021" },
      { "name": "Cyclobenzaprine", "code": "51152022" },
      { "name": "Dantrolene", "code": "51152023" },
      { "name": "Dantrolene sodium", "code": "51152024" },
      { "name": "Decamethonium", "code": "51152025" },
      { "name": "Denpidazone", "code": "51152026" },
      { "name": "Idrocilamide", "code": "51152027" },
      { "name": "Dotefonium", "code": "51152028" },
      { "name": "Fazadinium bromide", "code": "51152029" },
      { "name": "Fenyramidol or phenyramidol", "code": "51152030" },
      { "name": "Fenyripol", "code": "51152031" },
      { "name": "Hexcarbacholine", "code": "51152032" },
      { "name": "Inaperisone", "code": "51152033" },
      { "name": "Lanperisone", "code": "51152034" },
      { "name": "Mephenesin", "code": "51152035" },
      { "name": "Mephenesin nicotinate", "code": "51152036" },
      { "name": "Mephenoxalone", "code": "51152037" },
      { "name": "Mivacurium chyloride", "code": "51152038" },
      { "name": "Nelezaprine", "code": "51152039" },
      { "name": "Stercuronium", "code": "51152040" },
      { "name": "Orphenadrine", "code": "51152041" },
      { "name": "Oxydipentonium", "code": "51152042" },
      { "name": "Promoxolane", "code": "51152043" },
      { "name": "Silperisone", "code": "51152044" },
      {
        "name": "Succinylcholine or suxamethonium or suxamethonium chloride",
        "code": "51152046"
      },
      { "name": "Suxethonium", "code": "51152047" },
      { "name": "Tolperisone", "code": "51152048" },
      { "name": "Alcuronium chloride", "code": "51152049" },
      { "name": "Baclofen hydrochloride", "code": "51152050" },
      { "name": "Cisatracurium besilate", "code": "51152051" },
      { "name": "Cyclobenzaprine hydrochloride", "code": "51152052" },
      { "name": "Dantrolene sodium anhydrous", "code": "51152053" },
      { "name": "Decamethonium bromide", "code": "51152054" },
      { "name": "Dotefonium bromide", "code": "51152055" },
      { "name": "Doxacurium chloride", "code": "51152056" },
      { "name": "Fenyramidol hydrochloride", "code": "51152057" },
      { "name": "Fenyripol hydrochloride", "code": "51152058" },
      { "name": "Hexcarbacholine bromide", "code": "51152059" },
      { "name": "Inaperisone hydrochloride", "code": "51152060" },
      { "name": "Lanperisone hydrochloride", "code": "51152061" },
      { "name": "Mephenesin carbamate", "code": "51152062" },
      { "name": "Nelezaprine maleate", "code": "51152063" },
      { "name": "Orphenadrine citrate", "code": "51152064" },
      { "name": "Orphenadrine hydrochloride", "code": "51152065" },
      { "name": "Oxydipentonium chloride", "code": "51152066" },
      { "name": "Pancuronium bromide", "code": "51152067" },
      { "name": "Pipecuronium bromide", "code": "51152068" },
      { "name": "Rapacuronium bromide", "code": "51152069" },
      { "name": "Rocuronium bromide", "code": "51152070" },
      { "name": "Stercuronium iodide", "code": "51152071" },
      { "name": "Suxethonium chloride", "code": "51152072" },
      { "name": "Tolperisone hydrochloride", "code": "51152073" },
      { "name": "Tubocurarine chloride", "code": "51152074" },
      { "name": "Vecuronium bromide", "code": "51152075" },
      {
        "name": "Combination antimuscarinic and antipasmodics",
        "code": "51152100"
      },
      { "name": "Chlordiazepoxide/methscopolamine", "code": "51152101" },
      { "name": "Atropine/hyoscyamine/phenobarbital", "code": "51152102" },
      {
        "name": "Atropine/hyoscyamine/phenobarbital/scopolamine",
        "code": "51152103"
      },
      {
        "name": "Atropine/hyoscyamine/scopolamine/simethicone",
        "code": "51152104"
      },
      { "name": "Atropine/phenobarbital", "code": "51152105" },
      { "name": "Atropine/phenobarbital/scopolamine", "code": "51152106" },
      { "name": "Belladonna/butabarbital", "code": "51152107" },
      { "name": "Belladonna/charcoal", "code": "51152108" },
      { "name": "Belladonna/phenobarbital", "code": "51152109" },
      { "name": "Chlordiazepoxide/clidinium", "code": "51152110" },
      { "name": "Dicyclomine/phenobarbital", "code": "51152111" },
      { "name": "Hydroxyzine/oxyphencyclimine", "code": "51152112" },
      {
        "name": "Hyoscyamine/passion flower/phenobarbital/scopolamine",
        "code": "51152113"
      },
      { "name": "Hyoscyamine/phenobarbital", "code": "51152114" },
      {
        "name": "Hyoscyamus/passion flower/phenobarbital/valerian",
        "code": "51152115"
      },
      { "name": "Hyoscyamus/phenobarbital", "code": "51152116" },
      { "name": "Meprobamate/tridihexethyl", "code": "51152117" },
      {
        "name": "Combination neuromuscular blocking agents",
        "code": "51152200"
      },
      { "name": "Dextrose/mivacurium chloride", "code": "51152201" },
      {
        "name": "Combination parasympathomimetics (cholinergics)",
        "code": "51152300"
      },
      { "name": "Atropine/edrophonium", "code": "51152301" },
      { "name": "Metoclopramide/sodium chloride", "code": "51152302" },
      { "name": "Combination skeletal muscle relaxants", "code": "51152400" },
      { "name": "Acetaminophen/chlorzoxazone", "code": "51152401" },
      { "name": "Aspirin/caffeine/orphenadrine", "code": "51152402" },
      { "name": "Aspirin/carisoprodol", "code": "51152403" },
      { "name": "Aspirin/methocarbamol", "code": "51152404" },
      { "name": "Drugs affecting the respiratory tract", "code": "51160000" },
      { "name": "Antiasthmatic drugs", "code": "51161500" },
      { "name": "Diprophylline or dyphylline", "code": "51161513" },
      { "name": "Montelukast", "code": "51161515" },
      { "name": "Zileuton", "code": "51161517" },
      { "name": "Pranlukast", "code": "51161518" },
      { "name": "Carmoterol", "code": "51161519" },
      { "name": "Cilomilast", "code": "51161521" },
      { "name": "Tiotropium", "code": "51161524" },
      { "name": "Dazoquinast", "code": "51161526" },
      { "name": "Ablukast", "code": "51161527" },
      { "name": "Acitazanolast", "code": "51161528" },
      { "name": "Acreozast", "code": "51161529" },
      { "name": "Amipizone", "code": "51161530" },
      { "name": "Arofylline", "code": "51161531" },
      { "name": "Binizolast", "code": "51161532" },
      { "name": "Isbufylline", "code": "51161533" },
      { "name": "Denbufylline", "code": "51161534" },
      { "name": "Dimabefylline", "code": "51161535" },
      { "name": "Doxofylline", "code": "51161536" },
      { "name": "Enofelast", "code": "51161537" },
      { "name": "Eprozinol", "code": "51161538" },
      { "name": "Etamiphyllin or etamiphylline", "code": "51161539" },
      { "name": "Flufylline", "code": "51161540" },
      { "name": "Fluprofylline", "code": "51161541" },
      { "name": "Furafylline", "code": "51161542" },
      { "name": "Oxarbazole", "code": "51161543" },
      { "name": "Lomifylline", "code": "51161544" },
      { "name": "Melquinast", "code": "51161545" },
      { "name": "Midaxifylline", "code": "51161546" },
      { "name": "Motapizone", "code": "51161547" },
      { "name": "Nepadutant", "code": "51161548" },
      { "name": "Nestifylline", "code": "51161549" },
      { "name": "Ontazolast", "code": "51161550" },
      { "name": "Perbufylline", "code": "51161551" },
      { "name": "Pirolate", "code": "51161552" },
      { "name": "Proxyphylline", "code": "51161553" },
      { "name": "Ritolukast", "code": "51161554" },
      { "name": "Seratrodast", "code": "51161555" },
      { "name": "Tomelukast", "code": "51161556" },
      { "name": "Zardaverine", "code": "51161557" },
      { "name": "Nivimedone", "code": "51161558" },
      { "name": "Cromitrile", "code": "51161559" },
      { "name": "Nisbuterol", "code": "51161560" },
      { "name": "Pirquinozol", "code": "51161561" },
      {
        "name": "Norethindrone and ethinyl estradiol combination",
        "code": "51161562"
      },
      { "name": "Zafirlukast", "code": "51161563" },
      { "name": "Ablukast sodium", "code": "51161564" },
      { "name": "Acitazanolast hydrate", "code": "51161565" },
      { "name": "Cromitrile sodium", "code": "51161566" },
      { "name": "Eprozinol dihydrochloride", "code": "51161567" },
      { "name": "Eprozinol hydrochloride", "code": "51161568" },
      { "name": "Montelukast sodium", "code": "51161569" },
      { "name": "Nisbuterol mesilate or mesylate", "code": "51161570" },
      { "name": "Nivimedone sodium", "code": "51161571" },
      { "name": "Nivimedone sodium anhydrous", "code": "51161572" },
      { "name": "Pranlukast hydrate", "code": "51161573" },
      { "name": "Tiotropium bromide", "code": "51161574" },
      {
        "name": "Drugs used for respiratory tract disorders",
        "code": "51161700"
      },
      { "name": "Beractant", "code": "51161702" },
      { "name": "Colfosceril", "code": "51161704" },
      { "name": "Ipratropium bromide", "code": "51161705" },
      { "name": "Desoxyribonuclease or dornase alfa", "code": "51161708" },
      { "name": "Poractant alfa", "code": "51161709" },
      { "name": "Calfactant", "code": "51161710" },
      { "name": "Mepixanox", "code": "51161711" },
      { "name": "Almitrine", "code": "51161712" },
      { "name": "Amiphenazole", "code": "51161713" },
      { "name": "Bemegride", "code": "51161714" },
      { "name": "Dimefline", "code": "51161715" },
      { "name": "Doxapram", "code": "51161716" },
      { "name": "Fenspiride", "code": "51161717" },
      { "name": "Pentetrazol", "code": "51161718" },
      { "name": "Prethcamide", "code": "51161719" },
      { "name": "Roflumilast", "code": "51161720" },
      { "name": "Sivelestat", "code": "51161721" },
      { "name": "Almitrine bismesylate", "code": "51161722" },
      { "name": "Almitrine dimesilate", "code": "51161723" },
      { "name": "Almitrine mesylate", "code": "51161724" },
      { "name": "Amiphenazole hydrochloride", "code": "51161725" },
      { "name": "Dimefline hydrochloride", "code": "51161726" },
      { "name": "Doxapram hydrochloride", "code": "51161727" },
      { "name": "Doxapram hydrochloride anhydrous", "code": "51161728" },
      { "name": "Doxapram hydrochloride hydrate", "code": "51161729" },
      { "name": "Fenspiride hydrochloride", "code": "51161730" },
      { "name": "Cough and cold and antiallergy products", "code": "51161800" },
      {
        "name": "Combination chlorpheniramine acetaminophen",
        "code": "51161812"
      },
      { "name": "Althaea", "code": "51161823" },
      { "name": "Tetrahydrozoline", "code": "51161883" },
      { "name": "Combination antiasthma agents", "code": "51162000" },
      { "name": "Amobarbital/ephedrine", "code": "51162001" },
      { "name": "Albuterol/ipratropium", "code": "51162002" },
      {
        "name": "Aminophylline/ammonium chloride/diphenhydramine",
        "code": "51162003"
      },
      { "name": "Aminophylline/ephedrine", "code": "51162004" },
      {
        "name": "Aminophylline/ephedrine/guaifenesin/phenobarbital",
        "code": "51162005"
      },
      { "name": "Aminophylline/ephedrine/phenobarbital", "code": "51162006" },
      {
        "name": "Aminophylline/ephedrine/phenobarbital/potassium iodide",
        "code": "51162007"
      },
      { "name": "Aminophylline/guaifenesin", "code": "51162008" },
      { "name": "Aminophylline/phenobarbital", "code": "51162009" },
      { "name": "Aminophylline/potassium iodide", "code": "51162010" },
      {
        "name": "Ephedrine/guaifenesin/phenobarbital/theophylline",
        "code": "51162011"
      },
      { "name": "Budesonide/formoterol", "code": "51162012" },
      {
        "name": "Butabarbital/ephedrine/guaifenesin/theophylline",
        "code": "51162013"
      },
      { "name": "Butabarbital/epinephrine/theophylline", "code": "51162014" },
      {
        "name": "Butabarbital/guaifenesin/pseudoephedrine/theophylline",
        "code": "51162015"
      },
      { "name": "Calcium iodide/isoproterenol", "code": "51162016" },
      {
        "name": "Chlorpheniramine/ephedrine/guaifenesin/phenobarbital/theophylline",
        "code": "51162017"
      },
      {
        "name": "Dextromethorphan/dyphylline/pseudoephedrine",
        "code": "51162018"
      },
      {
        "name": "Dyphylline/ephedrine/guaifenesin/phenobarbital",
        "code": "51162019"
      },
      { "name": "Dyphylline/guaifenesin", "code": "51162020" },
      { "name": "Formoterol/mometasone", "code": "51162021" },
      { "name": "Ephedrine/guaifenesin/theophylline", "code": "51162022" },
      { "name": "Ephedrine/hydroxyzine/theophylline", "code": "51162023" },
      {
        "name": "Ephedrine/isoproterenol/phenobarbital/potassium/theophylline",
        "code": "51162024"
      },
      { "name": "Ephedrine/phenobarbital", "code": "51162025" },
      {
        "name": "Ephedrine/phenobarbital/potassium iodide/theophylline",
        "code": "51162026"
      },
      { "name": "Ephedrine/phenobarbital/theophylline", "code": "51162027" },
      { "name": "Ephedrine/secobarbital", "code": "51162028" },
      { "name": "Ephedrine/theophylline", "code": "51162029" },
      { "name": "Fluticasone/salmeterol", "code": "51162030" },
      { "name": "Glycerol, iodinated/theophylline", "code": "51162031" },
      { "name": "Guaifenesin/oxtriphylline", "code": "51162032" },
      { "name": "Guaifenesin/sodium citrate/theophylline", "code": "51162033" },
      { "name": "Guaifenesin/theophylline", "code": "51162034" },
      {
        "name": "Phenobarbital/potassium iodide/sodium bicarbonate/theobromine",
        "code": "51162035"
      },
      { "name": "Potassium iodide/theophylline", "code": "51162036" },
      { "name": "Combination antituberculars", "code": "51162100" },
      { "name": "Isoniazid/pyrazinamide/rifampin", "code": "51162101" },
      { "name": "Isoniazid/pyridoxine", "code": "51162102" },
      { "name": "Isoniazid/rifampin", "code": "51162103" },
      {
        "name": "Combination bronchodilators and respiratory agents",
        "code": "51162200"
      },
      { "name": "Ephedrine/sodium chloride", "code": "51162201" },
      {
        "name": "Acetaminophen/caffeine/phenylpropanolamine/pyrilamine",
        "code": "51162202"
      },
      {
        "name": "Acetaminophen/diphenhydramine/pseudoephedrine",
        "code": "51162203"
      },
      { "name": "Aminophylline/sodium chloride", "code": "51162204" },
      { "name": "Camphor/eucalyptus oil/menthol", "code": "51162205" },
      {
        "name": "Camphor/eucalyptus oil/menthol/turpentine spirits",
        "code": "51162206"
      },
      {
        "name": "Cetyl alcohol/colfosceril palmitate/tyloxapol",
        "code": "51162207"
      },
      { "name": "Chlorpheniramine/methscopolamine", "code": "51162208" },
      { "name": "Dextrose/theophylline", "code": "51162209" },
      { "name": "Isoproterenol/phenylephrine", "code": "51162210" },
      { "name": "Combination cold remedies", "code": "51162300" },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylephrine/pyrilamine",
        "code": "51162301"
      },
      {
        "name": "Acetaminophen/aluminum acetate/chlorpheniramine/phenylpropanolam",
        "code": "51162302"
      },
      {
        "name": "Acetaminophen/aspirin/phenylpropanolamine",
        "code": "51162303"
      },
      {
        "name": "Acetaminophen/brompheniramine/pseudoephedrine",
        "code": "51162304"
      },
      {
        "name": "Acetaminophen/caffeine/chlorpheniramine/phenylephrine/pyrilamine",
        "code": "51162305"
      },
      {
        "name": "Acetaminophen/caffeine/chlorpheniramine/phenylpropanolamine",
        "code": "51162306"
      },
      {
        "name": "Acetaminophen/caffeine/guaifenesin/phenylephrine",
        "code": "51162307"
      },
      {
        "name": "Acetaminophen/caffeine/phenylpropanolamine/salicylamide",
        "code": "51162308"
      },
      { "name": "Acetaminophen/chlorpheniramine", "code": "51162309" },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylephrine",
        "code": "51162310"
      },
      { "name": "Acetaminophen/phenylephrine", "code": "51162311" },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylephrine/salicylamide",
        "code": "51162312"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylpropanolamine",
        "code": "51162313"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylpropanolamine/phenyltoloxamine",
        "code": "51162314"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/phenylpropanolamine/salicylamide",
        "code": "51162315"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/pseudoephedrine",
        "code": "51162316"
      },
      {
        "name": "Acetaminophen/dexbrompheniramine/pseudoephedrine",
        "code": "51162317"
      },
      {
        "name": "Acetaminophen/dextromethorphan/guaifenesin",
        "code": "51162318"
      },
      {
        "name": "Acetaminophen/diphenhydramine/phenylephrine",
        "code": "51162319"
      },
      {
        "name": "Ammonium/antimony/chlorpheniramine/potassium guaiacolsulfonate",
        "code": "51162320"
      },
      { "name": "Acetaminophen/phenylpropanolamine", "code": "51162321" },
      {
        "name": "Acetaminophen/phenylpropanolamine/phenyltoloxamine",
        "code": "51162322"
      },
      { "name": "Acetaminophen/phenyltoloxamine", "code": "51162323" },
      {
        "name": "Acetaminophen/phenyltoloxamine/salicylamide",
        "code": "51162324"
      },
      { "name": "Acetaminophen/pseudoephedrine", "code": "51162325" },
      {
        "name": "Acetaminophen/pseudoephedrine/triprolidine",
        "code": "51162326"
      },
      { "name": "Ammonium chloride/diphenhydramine", "code": "51162327" },
      {
        "name": "Ammonium chloride/diphenhydramine/menthol/sodium citrate",
        "code": "51162328"
      },
      {
        "name": "Ammonium chloride/glycyrrhiza/hyoscyamus/phenobarbital/terpin hydrate",
        "code": "51162329"
      },
      {
        "name": "Atropine/chlorpheniramine/hyoscyamine/phenylephrine/scopolamine",
        "code": "51162330"
      },
      { "name": "Aspirin/caffeine/chlorpheniramine", "code": "51162331" },
      {
        "name": "Aspirin/chlorpheniramine/phenylpropanolamine/sodium acetylsalicylate",
        "code": "51162332"
      },
      { "name": "Aspirin/phenyltoloxamine", "code": "51162333" },
      { "name": "Aspirin/phenypropanolamine", "code": "51162334" },
      { "name": "Aspirin/promethazine/pseudoephedrine", "code": "51162335" },
      { "name": "Aspirin/pseudoephedrine", "code": "51162336" },
      {
        "name": "Atropine/brompheniramine/phenyltoloxamine/pseudoephedrine",
        "code": "51162337"
      },
      { "name": "Atropine/chlorpheniramine", "code": "51162338" },
      { "name": "Atropine/chlorpheniramine/ephedrine", "code": "51162339" },
      {
        "name": "Chlorpheniramine/methscopolamine/phenylephrine",
        "code": "51162340"
      },
      {
        "name": "Atropine/chlorpheniramine/phenylephrine/phenyltoloxamine",
        "code": "51162341"
      },
      {
        "name": "Atropine/chlorpheniramine/phenylpropanolamine",
        "code": "51162342"
      },
      {
        "name": "Belladonna/chlorpheniramine/ephedrine/phenobarbital",
        "code": "51162343"
      },
      {
        "name": "Belladonna/chlorpheniramine/phenylephrine/phenylpropanolamine",
        "code": "51162344"
      },
      {
        "name": "Belladonna/chlorpheniramine/phenylephrine/pyrilamine",
        "code": "51162345"
      },
      {
        "name": "Benzocaine/phenylephrine/phenylpropanolamine",
        "code": "51162346"
      },
      {
        "name": "Caffeine/pheniramine/phenylephrine/sodium citrate/sodium salicylate",
        "code": "51162347"
      },
      {
        "name": "Calcium lactate/guaifenesin/hyoscyamus/phenobarbital/terpin hydrate",
        "code": "51162348"
      },
      {
        "name": "Caramiphen/chlorpheniramine/isopropamide/phenylpropanolamine",
        "code": "51162349"
      },
      {
        "name": "Potassium guaiacolsulfonate/promethazine",
        "code": "51162350"
      },
      {
        "name": "Chlorpheniramine/methscopolamine/pseudoephedrine",
        "code": "51162351"
      },
      {
        "name": "Diphenhydramine/guaifenesin/menthol/sodium citrate",
        "code": "51162352"
      },
      { "name": "Ibuprofen/pseudoephedrine", "code": "51162353" },
      { "name": "Magnesium salicylate/phenyltoloxamine", "code": "51162354" },
      { "name": "Methscopolamine/pseudoephedrine", "code": "51162355" },
      { "name": "Naproxen/pseudoephedrine", "code": "51162356" },
      {
        "name": "Combination decongestants, with analgesics, antitussives, or expectorants",
        "code": "51162400"
      },
      {
        "name": "Dextromethorphan/guaifenesin/phenylephrine",
        "code": "51162401"
      },
      {
        "name": "Acetaminophen/dextromethorphan/guaifenesin/phenylephrine",
        "code": "51162402"
      },
      {
        "name": "Acetaminophen/dextromethorphan/guaifenesin/phenylpropanolamine",
        "code": "51162403"
      },
      {
        "name": "Acetaminophen/dextromethorphan/guaifenesin/pseudoephedrine",
        "code": "51162404"
      },
      {
        "name": "Acetaminophen/dextromethorphan/phenylpropanolamine",
        "code": "51162405"
      },
      {
        "name": "Acetaminophen/dextromethorphan/pseudoephedrine",
        "code": "51162406"
      },
      { "name": "Caramiphen/phenylpropanolamine", "code": "51162407" },
      { "name": "Dextromethorphan/guaifenesin", "code": "51162408" },
      {
        "name": "Dextromethorphan/guaifenesin/phenylpropanolamine",
        "code": "51162409"
      },
      { "name": "Dextromethorphan/phenylephrine", "code": "51162410" },
      { "name": "Dextromethorphan/phenylpropanolamine", "code": "51162411" },
      { "name": "Dextromethorphan/pseudoephedrine", "code": "51162412" },
      { "name": "Ephedrine/guaifenesin", "code": "51162413" },
      { "name": "Ephedrine/potassium iodide", "code": "51162414" },
      { "name": "Guaifenesin/phenylephrine", "code": "51162415" },
      {
        "name": "Guaifenesin/phenylephrine/phenylpropanolamine",
        "code": "51162416"
      },
      { "name": "Guaifenesin/phenylpropanolamine", "code": "51162417" },
      { "name": "Guaifenesin/pseudoephedrine", "code": "51162418" },
      {
        "name": "Phenylephrine/phenylpropanolamine/pseudoephedrine",
        "code": "51162419"
      },
      {
        "name": "Combination non-opioid-containing antitussives/expectorants",
        "code": "51162500"
      },
      { "name": "Acetaminophen/dextromethorphan", "code": "51162501" },
      {
        "name": "Ammonium chloride/antimony potassium tartrate/ipecac",
        "code": "51162502"
      },
      {
        "name": "Ammonium chloride/antimony/guaiacolsulfonate/potassium tartrate",
        "code": "51162503"
      },
      { "name": "Ammonium chloride/ipecac", "code": "51162504" },
      { "name": "Ammonium chloride/ipecac/tolu balsam", "code": "51162505" },
      {
        "name": "Ammonium chloride/potassium guaiacolsulfonate/terpin hydrate",
        "code": "51162506"
      },
      { "name": "Benzocaine/dextromethorphan", "code": "51162507" },
      { "name": "Calcium creosotate/iodine", "code": "51162508" },
      { "name": "Carbetapentane/guaifenesin", "code": "51162509" },
      { "name": "Dextromethorphan/glycerol, iodinated", "code": "51162510" },
      { "name": "Dextromethorphan/terpin hydrate", "code": "51162511" },
      {
        "name": "Antitussives and associated cough remedies",
        "code": "51162600"
      },
      { "name": "Alloclamide", "code": "51162601" },
      { "name": "Bibenzonium", "code": "51162602" },
      { "name": "Benzonatate", "code": "51162603" },
      { "name": "Benproperine", "code": "51162604" },
      { "name": "Benproperine embonate", "code": "51162605" },
      { "name": "Benproperine pamoate", "code": "51162606" },
      { "name": "Amicibone", "code": "51162607" },
      { "name": "Benproperine phosphate", "code": "51162608" },
      { "name": "Benproperine trihydrogen phosphate", "code": "51162609" },
      { "name": "Bibenzonium bromide", "code": "51162610" },
      { "name": "Butamirate", "code": "51162611" },
      { "name": "Carbetapentane", "code": "51162612" },
      { "name": "Butamirate citrate", "code": "51162613" },
      { "name": "Caramiphen", "code": "51162614" },
      { "name": "Clobutinol", "code": "51162615" },
      { "name": "Chlophedianol or clofedanol", "code": "51162616" },
      { "name": "Cloperastine", "code": "51162617" },
      { "name": "Cloperastine fendizoate", "code": "51162618" },
      { "name": "Cyclexanone", "code": "51162619" },
      { "name": "Dimethoxanate", "code": "51162620" },
      { "name": "Dipropizine or dropropizine", "code": "51162621" },
      { "name": "Fedrilate", "code": "51162622" },
      { "name": "Dibunate", "code": "51162623" },
      { "name": "Dimemorfan", "code": "51162624" },
      { "name": "Droxypropine", "code": "51162625" },
      { "name": "Dextromethorphan", "code": "51162626" },
      { "name": "Dimemorfan phosphate", "code": "51162627" },
      { "name": "Fedrilate maleate", "code": "51162628" },
      { "name": "Levopropoxyphene", "code": "51162629" },
      { "name": "Meprotixol", "code": "51162630" },
      {
        "name": "Levopropoxyphene napsilate or levopropoxyphene napsylate",
        "code": "51162631"
      },
      { "name": "Isoaminile", "code": "51162632" },
      { "name": "Levodropropizine", "code": "51162633" },
      { "name": "Isoaminile citrate", "code": "51162634" },
      { "name": "Isoaminile cyclamate", "code": "51162635" },
      { "name": "Guaiacolsulfonate or sulfoguaiacolum", "code": "51162636" },
      { "name": "Morclofone", "code": "51162637" },
      { "name": "Narcotinum or noscapine", "code": "51162638" },
      { "name": "Nepinalone", "code": "51162639" },
      { "name": "Nonivamide", "code": "51162640" },
      { "name": "Oxeladin", "code": "51162641" },
      { "name": "Oxeladin citrate", "code": "51162642" },
      { "name": "Nicocodine", "code": "51162643" },
      { "name": "Nicodicodine", "code": "51162644" },
      { "name": "Normethadone", "code": "51162645" },
      { "name": "Pentoxyverine", "code": "51162646" },
      { "name": "Pentoxyverine citrate", "code": "51162647" },
      { "name": "Picoperine", "code": "51162648" },
      { "name": "Viminol", "code": "51162649" },
      { "name": "Xyloxemine", "code": "51162650" },
      { "name": "Pipazetate or pipazethate", "code": "51162651" },
      { "name": "Sulfogaiacol", "code": "51162652" },
      { "name": "Tipepidine", "code": "51162653" },
      { "name": "Prenoxdiazine", "code": "51162654" },
      { "name": "Zipeprol", "code": "51162655" },
      {
        "name": "Decongestants, expectorants, and mucolytics",
        "code": "51162700"
      },
      { "name": "Acetylcysteine", "code": "51162701" },
      { "name": "Ambroxol", "code": "51162702" },
      { "name": "Amidephrine", "code": "51162703" },
      { "name": "Bromhexine", "code": "51162704" },
      { "name": "Brovanexine", "code": "51162705" },
      { "name": "Carbocisteine", "code": "51162706" },
      { "name": "Cartasteine", "code": "51162707" },
      { "name": "Domiodol", "code": "51162708" },
      { "name": "Cistinexine", "code": "51162709" },
      { "name": "Eprazinone", "code": "51162710" },
      { "name": "Erdosteine", "code": "51162711" },
      { "name": "Fudosteine", "code": "51162712" },
      { "name": "Guaiacol", "code": "51162713" },
      { "name": "Guaiacol acetate", "code": "51162714" },
      { "name": "Guaiacol benzoate", "code": "51162715" },
      { "name": "Guaiacol butyrate", "code": "51162716" },
      { "name": "Guaiacol carbonate", "code": "51162717" },
      { "name": "Guaiacol nicotinate", "code": "51162718" },
      { "name": "Guaiacol phenylacetate", "code": "51162719" },
      { "name": "Letosteine", "code": "51162720" },
      { "name": "Mecysteine", "code": "51162721" },
      { "name": "Moguisteine", "code": "51162722" },
      { "name": "Guaiacol phosphate", "code": "51162723" },
      { "name": "Isalsteine", "code": "51162724" },
      { "name": "Guaiacol propionate", "code": "51162725" },
      { "name": "Guaietolin", "code": "51162726" },
      { "name": "Guaifenesin", "code": "51162727" },
      { "name": "Naphazoline", "code": "51162728" },
      { "name": "Neltenexine", "code": "51162729" },
      { "name": "Nesosteine", "code": "51162730" },
      { "name": "Oxedrine", "code": "51162731" },
      { "name": "Oxymetazoline", "code": "51162732" },
      { "name": "Prenisteine", "code": "51162733" },
      { "name": "Pseudoephedrine", "code": "51162734" },
      { "name": "Naphazoline nitrate", "code": "51162735" },
      { "name": "Pseudoephedrine sulfate", "code": "51162736" },
      { "name": "Pseudoephedrine tannate", "code": "51162737" },
      { "name": "Sobrerol", "code": "51162738" },
      { "name": "Stepronin", "code": "51162739" },
      { "name": "Tasuldine", "code": "51162740" },
      { "name": "Taurosteine", "code": "51162741" },
      { "name": "Telmesteine", "code": "51162742" },
      { "name": "Terpin hydrate", "code": "51162743" },
      { "name": "Tetrahydrozoline or tetryzoline", "code": "51162744" },
      { "name": "Tiopronin", "code": "51162745" },
      { "name": "Tyloxapol", "code": "51162746" },
      { "name": "Xylometazoline", "code": "51162747" },
      {
        "name": "Drugs affecting the gastrointestinal system",
        "code": "51170000"
      },
      { "name": "Antacids and antiflatulents", "code": "51171500" },
      { "name": "Calcium carbonate", "code": "51171501" },
      { "name": "Magaldrate", "code": "51171502" },
      { "name": "Simethicone", "code": "51171505" },
      { "name": "Hydrotalcite", "code": "51171507" },
      { "name": "Magnesium carbonate", "code": "51171508" },
      { "name": "Magnesium trisilicate", "code": "51171510" },
      { "name": "Algeldrate or aluminium hydroxide", "code": "51171511" },
      { "name": "Dihydroxyaluminum sodium carbonate", "code": "51171513" },
      { "name": "Bismuth subnitrate", "code": "51171514" },
      { "name": "Aluminum carbonate", "code": "51171515" },
      { "name": "Almagate", "code": "51171516" },
      { "name": "Almasilate", "code": "51171517" },
      {
        "name": "Aluminium glycinate or dihydroxyaluminum aminoacetate",
        "code": "51171518"
      },
      { "name": "Attapulgite", "code": "51171519" },
      {
        "name": "Bismuth aluminate or aluminum bismuth oxide",
        "code": "51171520"
      },
      { "name": "Calcarea silicata", "code": "51171521" },
      { "name": "Calcium lactate", "code": "51171522" },
      { "name": "Carbaldrate", "code": "51171523" },
      {
        "name": "Dihydroxyaluminum aminoacetate or aluminium glycinate",
        "code": "51171524"
      },
      { "name": "Magnesium oxide", "code": "51171525" },
      { "name": "Troxipide", "code": "51171526" },
      { "name": "Betaine", "code": "51171527" },
      { "name": "Betaine anhydrous", "code": "51171528" },
      { "name": "Betaine hydrate", "code": "51171529" },
      { "name": "Betaine hydrochloride", "code": "51171530" },
      { "name": "Laxatives", "code": "51171600" },
      { "name": "Docusate calcium", "code": "51171602" },
      { "name": "Docusate potassium", "code": "51171603" },
      { "name": "Lactulose", "code": "51171605" },
      { "name": "Psyllium hydrophilic muciloid", "code": "51171607" },
      { "name": "Glycerine", "code": "51171608" },
      { "name": "Senna glycosides or sennosides", "code": "51171610" },
      { "name": "Aloin", "code": "51171613" },
      { "name": "Bisacodyl", "code": "51171614" },
      {
        "name": "Calcium polycarbophil or polycarbophil calcium",
        "code": "51171615"
      },
      { "name": "Casanthranol", "code": "51171616" },
      { "name": "Castor oil", "code": "51171617" },
      { "name": "Sodium phosphate", "code": "51171622" },
      { "name": "Phenolphthalein", "code": "51171623" },
      { "name": "Potassium sodium tartrate", "code": "51171627" },
      { "name": "Potassium phosphate", "code": "51171628" },
      { "name": "Sodium sulfate", "code": "51171629" },
      { "name": "Macrogol or polyethylene glycol", "code": "51171631" },
      { "name": "Lubiprostone", "code": "51171633" },
      { "name": "Alvimopan", "code": "51171634" },
      { "name": "Bisoxatin", "code": "51171635" },
      { "name": "Danthron or dantron", "code": "51171636" },
      { "name": "Lactitol", "code": "51171637" },
      { "name": "Methylnaltrexone", "code": "51171638" },
      { "name": "Oxyphenisatin or oxyphenisatine", "code": "51171639" },
      { "name": "Picosulfate", "code": "51171640" },
      { "name": "Polyethylene glycol", "code": "51171641" },
      { "name": "Prucalopride", "code": "51171642" },
      { "name": "Methylcellulose", "code": "51171643" },
      { "name": "Sulisatin", "code": "51171644" },
      { "name": "Polyethylene glycol 400", "code": "51171645" },
      { "name": "Alvimopan anhydrous", "code": "51171646" },
      { "name": "Bisacodyl tannex", "code": "51171647" },
      { "name": "Bisoxatin acetate", "code": "51171648" },
      { "name": "Lactitol dihydrate", "code": "51171649" },
      { "name": "Lactitol monohydrate", "code": "51171650" },
      { "name": "Phenolphthalein glucuronide", "code": "51171651" },
      { "name": "Prucalopride hydrochloride", "code": "51171652" },
      { "name": "Prucalopride succinate", "code": "51171653" },
      { "name": "Sulisatin sodium", "code": "51171654" },
      { "name": "Antidiarrheals", "code": "51171700" },
      { "name": "Difenoxin", "code": "51171701" },
      { "name": "Loperamide", "code": "51171702" },
      { "name": "Paregoric", "code": "51171703" },
      { "name": "Nifuroxazide", "code": "51171704" },
      { "name": "Diphenoxylate", "code": "51171708" },
      { "name": "Saccharomyces boulardii", "code": "51171709" },
      { "name": "Alosetron", "code": "51171710" },
      { "name": "Purified pectin with acidophilus", "code": "51171712" },
      { "name": "Bismuth subcarbonate", "code": "51171713" },
      { "name": "Racecadotril", "code": "51171714" },
      { "name": "Bismuth subgallate", "code": "51171715" },
      { "name": "Lidamidine", "code": "51171716" },
      { "name": "Mebiquine", "code": "51171717" },
      { "name": "Rolgamidine", "code": "51171718" },
      { "name": "Zaldaride", "code": "51171719" },
      { "name": "Alosetron hydrochloride", "code": "51171720" },
      { "name": "Difenoxin hydrochloride", "code": "51171721" },
      { "name": "Diphenoxylate hydrochloride", "code": "51171722" },
      { "name": "Lidamidine hydrochloride", "code": "51171723" },
      { "name": "Loperamide hydrochloride", "code": "51171724" },
      { "name": "Loperamide oxide monohydrate", "code": "51171725" },
      { "name": "Zaldaride maleate", "code": "51171726" },
      {
        "name": "Antiemetics and antinauseants and antivertigo agents",
        "code": "51171800"
      },
      { "name": "Trimethobenzamide", "code": "51171805" },
      { "name": "Metoclopramide", "code": "51171806" },
      { "name": "Cinnarizine", "code": "51171807" },
      { "name": "Difenidol or diphenidol", "code": "51171808" },
      { "name": "Cyclizine", "code": "51171809" },
      {
        "name": "Combinational fructose dextrose and phosphoric acid",
        "code": "51171811"
      },
      { "name": "Prochlorperazine", "code": "51171812" },
      { "name": "Metopimazine", "code": "51171813" },
      { "name": "Dolasetron", "code": "51171814" },
      { "name": "Ondansetron", "code": "51171816" },
      { "name": "Thiethylperazine", "code": "51171819" },
      { "name": "Dimenhydrinate", "code": "51171820" },
      { "name": "Palonosetron", "code": "51171822" },
      { "name": "Granisetron", "code": "51171823" },
      { "name": "Bemesetron", "code": "51171824" },
      { "name": "Acotiamide", "code": "51171825" },
      { "name": "Alepride", "code": "51171826" },
      { "name": "Alizapride", "code": "51171827" },
      { "name": "Azasetron", "code": "51171828" },
      { "name": "Azasetron hydrochloride", "code": "51171829" },
      { "name": "Batanopride", "code": "51171830" },
      { "name": "Cipropride", "code": "51171831" },
      { "name": "Benzquinamide", "code": "51171832" },
      { "name": "Bromopride", "code": "51171833" },
      {
        "name": "Cannabinol or nabiximols or tetrahydrocannabinol",
        "code": "51171834"
      },
      { "name": "Casopitant", "code": "51171835" },
      { "name": "Cilansetron", "code": "51171836" },
      { "name": "Flumeridone", "code": "51171837" },
      { "name": "Fosaprepitant or ivemend", "code": "51171838" },
      { "name": "Hydroxyzine", "code": "51171839" },
      {
        "name": "Hyoscine or levo-duboisine or scopolamine",
        "code": "51171840"
      },
      { "name": "Iprozilamine", "code": "51171841" },
      { "name": "Denipride", "code": "51171842" },
      { "name": "Maropitant", "code": "51171843" },
      { "name": "Nabilone", "code": "51171844" },
      { "name": "Nantradol", "code": "51171845" },
      { "name": "Nonabine", "code": "51171846" },
      { "name": "Pancopride", "code": "51171847" },
      { "name": "Pipamazine", "code": "51171848" },
      { "name": "Levonantradol", "code": "51171849" },
      { "name": "Prochlorperazine mesilate", "code": "51171850" },
      { "name": "Ricasetron", "code": "51171851" },
      { "name": "Tinisulpride", "code": "51171852" },
      { "name": "Tropisetron", "code": "51171853" },
      { "name": "Ramosetron", "code": "51171854" },
      { "name": "Clebopride", "code": "51171855" },
      { "name": "Acetylleucine", "code": "51171856" },
      { "name": "Fludorex", "code": "51171857" },
      {
        "name": "Antiulcer and related gastrointestinal GI drugs",
        "code": "51171900"
      },
      { "name": "Cimetidine", "code": "51171901" },
      { "name": "Famotidine", "code": "51171902" },
      { "name": "Nizatidine", "code": "51171903" },
      { "name": "Cisapride", "code": "51171905" },
      { "name": "Lansoprazole", "code": "51171906" },
      { "name": "Esomeprazole or omeprazole", "code": "51171909" },
      { "name": "Pancreatin", "code": "51171910" },
      { "name": "Sucralfate", "code": "51171911" },
      { "name": "Pantoprazole", "code": "51171915" },
      { "name": "Rabeprazole sodium", "code": "51171916" },
      { "name": "Ranitidine", "code": "51171917" },
      { "name": "Tegaserod", "code": "51171922" },
      { "name": "Rebamipide", "code": "51171925" },
      { "name": "Sevelamer", "code": "51171926" },
      { "name": "Acetoxolone", "code": "51171927" },
      { "name": "Bismuth subcitrate", "code": "51171928" },
      { "name": "Bismuth tripotassium dicitrate", "code": "51171929" },
      { "name": "Candoxatril or candoxatrilat", "code": "51171930" },
      { "name": "Cetraxate", "code": "51171931" },
      { "name": "Cinitapride", "code": "51171932" },
      { "name": "Colloidal bismuth subcitrate", "code": "51171933" },
      { "name": "Deboxamet", "code": "51171934" },
      { "name": "Dexlansoprazole", "code": "51171935" },
      { "name": "Disuprazole", "code": "51171936" },
      { "name": "Ebrotidine", "code": "51171937" },
      { "name": "Enprostil", "code": "51171938" },
      { "name": "Esaprazole", "code": "51171939" },
      { "name": "Fenoctimine", "code": "51171940" },
      { "name": "Metiamide", "code": "51171941" },
      { "name": "Gefarnate", "code": "51171942" },
      { "name": "Glaziovine", "code": "51171943" },
      { "name": "Irsogladine", "code": "51171944" },
      { "name": "Isotiquimide", "code": "51171945" },
      { "name": "Lafutidine", "code": "51171946" },
      { "name": "Lamtidine", "code": "51171947" },
      { "name": "Lozilurea", "code": "51171948" },
      { "name": "Pirenzepine", "code": "51171949" },
      { "name": "Molfarnate", "code": "51171950" },
      { "name": "Niperotidine", "code": "51171951" },
      { "name": "Nolinium", "code": "51171952" },
      { "name": "Pibutidine", "code": "51171953" },
      { "name": "Pifarnine", "code": "51171954" },
      { "name": "Roxatidine", "code": "51171955" },
      { "name": "Plaunotol", "code": "51171956" },
      { "name": "Proglumide", "code": "51171957" },
      { "name": "Propinetidine", "code": "51171958" },
      { "name": "Proxazole", "code": "51171959" },
      { "name": "Ramixotidine", "code": "51171960" },
      { "name": "Rotraxate", "code": "51171961" },
      { "name": "Ursodeoxycholic acid or ursodiol", "code": "51171962" },
      { "name": "Spizofurone", "code": "51171963" },
      { "name": "Sufotidine", "code": "51171964" },
      { "name": "Benatoprazole or tenatoprazole", "code": "51171965" },
      { "name": "Geranylgeranylacetone or teprenone", "code": "51171966" },
      { "name": "Timoprazole", "code": "51171967" },
      { "name": "Tiquinamide", "code": "51171968" },
      { "name": "Tuvatidine", "code": "51171969" },
      { "name": "Zolenzepine", "code": "51171970" },
      { "name": "Zolimidine", "code": "51171971" },
      { "name": "Alglucerase", "code": "51171972" },
      { "name": "Alglucosidase alfa", "code": "51171973" },
      { "name": "Imiglucerase", "code": "51171974" },
      { "name": "Miglustat", "code": "51171975" },
      { "name": "Ornithine oxoglurate", "code": "51171976" },
      { "name": "Pancrelipase", "code": "51171977" },
      { "name": "Pinaverium", "code": "51171978" },
      { "name": "Sacrosidase", "code": "51171979" },
      { "name": "Velaglucerase alfa", "code": "51171980" },
      { "name": "Carbenoxolone", "code": "51171981" },
      { "name": "Ranitidine bismuth citrate", "code": "51171982" },
      { "name": "Drugs used for gallbladder disease", "code": "51172000" },
      {
        "name": "Chenodiol or chenodeoxycholic acid or chenodeoxycholic acid sodium salt",
        "code": "51172001"
      },
      { "name": "Dimethyl sulfoxide", "code": "51172004" },
      { "name": "Fencibutirol", "code": "51172005" },
      { "name": "Alibendol", "code": "51172006" },
      { "name": "Azintamide", "code": "51172007" },
      { "name": "Cholic acid", "code": "51172008" },
      { "name": "Clanobutin", "code": "51172009" },
      { "name": "Cyclobutyrol", "code": "51172010" },
      { "name": "Cynarine", "code": "51172011" },
      { "name": "Deoxycholic acid", "code": "51172012" },
      { "name": "Febuprol", "code": "51172013" },
      { "name": "Fenipentol", "code": "51172014" },
      { "name": "Florantyrone", "code": "51172015" },
      { "name": "Hymecromone", "code": "51172016" },
      { "name": "Oxazorone", "code": "51172017" },
      { "name": "Piprozolin or piprozoline", "code": "51172018" },
      { "name": "Cyclobutyrol sodium", "code": "51172019" },
      { "name": "Antispasmodics", "code": "51172100" },
      { "name": "Pitofenone", "code": "51172103" },
      { "name": "Phloroglucinol", "code": "51172105" },
      { "name": "Viquidil", "code": "51172108" },
      { "name": "Prozapine", "code": "51172110" },
      { "name": "Alverine", "code": "51172111" },
      { "name": "Adiphenine", "code": "51172112" },
      { "name": "Ambucetamide", "code": "51172113" },
      { "name": "Amifloverine", "code": "51172114" },
      { "name": "Amikhelline", "code": "51172115" },
      { "name": "Aminopromazine", "code": "51172116" },
      { "name": "Aseripide", "code": "51172117" },
      { "name": "Bietamiverine", "code": "51172118" },
      { "name": "Butaverine", "code": "51172119" },
      { "name": "Ciclactate", "code": "51172120" },
      { "name": "Darifenacin", "code": "51172121" },
      { "name": "Denaverine", "code": "51172122" },
      { "name": "Dexsecoverine", "code": "51172123" },
      { "name": "Difenoximide", "code": "51172124" },
      { "name": "Drofenine", "code": "51172125" },
      { "name": "Drotaverine", "code": "51172126" },
      { "name": "Elziverine", "code": "51172127" },
      { "name": "Eperisone", "code": "51172128" },
      { "name": "Fenoverine", "code": "51172129" },
      { "name": "Fenpipramide", "code": "51172130" },
      { "name": "Fenpiprane", "code": "51172131" },
      { "name": "Moxaverine", "code": "51172132" },
      { "name": "Fesoterodine", "code": "51172133" },
      { "name": "Flopropione", "code": "51172134" },
      { "name": "Heptaverine", "code": "51172135" },
      { "name": "Meladrazine", "code": "51172136" },
      { "name": "Metaxalone", "code": "51172137" },
      { "name": "Methylchromone or tricromyl", "code": "51172138" },
      { "name": "Milverine", "code": "51172139" },
      { "name": "Mofloverine", "code": "51172140" },
      { "name": "Pramiverin or pramiverine", "code": "51172141" },
      {
        "name": "Ciclonium or cyclonium or oxapium iodide",
        "code": "51172142"
      },
      { "name": "Oxyphencyclimine", "code": "51172143" },
      { "name": "Pargeverine", "code": "51172144" },
      { "name": "Pimetremide", "code": "51172145" },
      { "name": "Treptilamine", "code": "51172146" },
      { "name": "Propiverine", "code": "51172147" },
      { "name": "Rociverine", "code": "51172148" },
      { "name": "Stilonium", "code": "51172149" },
      { "name": "Talnetant", "code": "51172150" },
      { "name": "Tiropramide", "code": "51172151" },
      { "name": "Trepibutone", "code": "51172152" },
      { "name": "Trospium", "code": "51172153" },
      { "name": "Vetrabutine", "code": "51172154" },
      { "name": "Anethole trithione or anetholetrithione", "code": "51172155" },
      { "name": "Adiphenine hydrochloride", "code": "51172156" },
      { "name": "Adiphenine methyl bromide", "code": "51172157" },
      { "name": "Alverine citrate", "code": "51172158" },
      { "name": "Alverine hydrochloride", "code": "51172159" },
      { "name": "Alverine tartrate", "code": "51172160" },
      { "name": "Ambucetamide hydrochloride", "code": "51172161" },
      { "name": "Bietamiverine dihydrochloride", "code": "51172162" },
      { "name": "Bietamiverine hydrochloride", "code": "51172163" },
      { "name": "Ciclonium bromide", "code": "51172164" },
      { "name": "Darifenacin hydrobromide", "code": "51172165" },
      { "name": "Denaverine hydrochloride", "code": "51172166" },
      { "name": "Dexsecoverine hydrochloride", "code": "51172167" },
      { "name": "Difenoximide hydrochloride", "code": "51172168" },
      { "name": "Drofenine hydrochloride", "code": "51172169" },
      { "name": "Drotaverine hydrochloride", "code": "51172170" },
      { "name": "Eperisone hydrochloride", "code": "51172171" },
      { "name": "Fenpipramide hydrochloride", "code": "51172172" },
      { "name": "Fenpiprane hydrochloride", "code": "51172173" },
      { "name": "Fesoterodine fumarate", "code": "51172174" },
      { "name": "Meladrazine tartrate", "code": "51172175" },
      { "name": "Moxaverine hydrochloride", "code": "51172176" },
      { "name": "Oxyphencyclimine hydrochloride", "code": "51172177" },
      { "name": "Pargeverine hydrochloride", "code": "51172178" },
      { "name": "Pitofenone hydrochloride", "code": "51172179" },
      { "name": "Propiverine hydrochloride", "code": "51172180" },
      { "name": "Stilonium iodide", "code": "51172181" },
      { "name": "Tiropramide hydrochloride", "code": "51172182" },
      { "name": "Vetrabutine hydrochloride", "code": "51172183" },
      { "name": "Viquidil hydrochloride", "code": "51172184" },
      { "name": "Drugs that treat interstitial cystitis", "code": "51172200" },
      { "name": "Pentosan polysulfate sodium", "code": "51172201" },
      { "name": "Butaphosphan", "code": "51172202" },
      { "name": "Antidotes and emetics", "code": "51172300" },
      { "name": "Deferoxamine mesylate", "code": "51172301" },
      { "name": "Digoxin Immune Fab", "code": "51172302" },
      { "name": "Dimercaprol", "code": "51172303" },
      { "name": "Edetate disodium", "code": "51172304" },
      { "name": "Protamine sulfate", "code": "51172305" },
      { "name": "Sodium polystyrene sulfonate", "code": "51172306" },
      { "name": "Trientine", "code": "51172307" },
      {
        "name": "Methylene blue or methylthioninium chloride",
        "code": "51172308"
      },
      { "name": "Antivenins", "code": "51172309" },
      { "name": "Folinic acid or leucovorin", "code": "51172310" },
      {
        "name": "Activated carbon or activated charcoal for medical use",
        "code": "51172311"
      },
      { "name": "Edetate calcium disodium", "code": "51172312" },
      { "name": "Fomepizole", "code": "51172313" },
      { "name": "Pralidoxime", "code": "51172314" },
      {
        "name": "Sodium thiosulfate or thiosulfate or thiosulphate",
        "code": "51172315"
      },
      { "name": "Dimercaptosuccinic acid or succimer", "code": "51172316" },
      { "name": "Copper sulfate", "code": "51172317" },
      { "name": "Carglumic acid", "code": "51172318" },
      { "name": "Cysteamine or mercaptamine", "code": "51172319" },
      { "name": "Deferasirox", "code": "51172320" },
      { "name": "Deferiprone", "code": "51172321" },
      { "name": "Dicobalt edetate", "code": "51172322" },
      { "name": "Glucarpidase", "code": "51172323" },
      { "name": "Mesna", "code": "51172324" },
      { "name": "Pentetic acid", "code": "51172325" },
      { "name": "Sodium edetate", "code": "51172326" },
      { "name": "Sodium lactate", "code": "51172327" },
      { "name": "Sugammadex", "code": "51172328" },
      { "name": "Trientine dihydrochloride", "code": "51172329" },
      { "name": "Exopeptidase g2", "code": "51172330" },
      { "name": "Ethylenediaminetetraacetic acid (EDTA)", "code": "51172331" },
      { "name": "Acetohydroxamic acid", "code": "51172332" },
      { "name": "Sugammadex sodium", "code": "51172333" },
      { "name": "Trientine hydrochloride", "code": "51172334" },
      { "name": "Gadopenamide", "code": "51172335" },
      {
        "name": "Combination antacid, antidiarrheal, anti-flatulence, digestive, and gastric preparations",
        "code": "51172400"
      },
      {
        "name": "Aluminum hydroxide/magnesium hydroxide/magnesium trisilicate",
        "code": "51172401"
      },
      { "name": "Acidophilus/pectin", "code": "51172402" },
      {
        "name": "Alginic acid/aluminum hydroxide/calcium stearate/magnesium trisilicate",
        "code": "51172403"
      },
      {
        "name": "Alginic acid/aluminum hydroxide/magnesium trisilicate/sodium bicarbonate",
        "code": "51172404"
      },
      {
        "name": "Alginic acid/calcium carbonate/magnesium trisilicate/sodium bica",
        "code": "51172405"
      },
      {
        "name": "Alginic acid/carboxymethylcellulose sodium/sodium bicarbonate",
        "code": "51172406"
      },
      {
        "name": "Aluminum hydroxide/calcium carbonate/magnesium hydroxide",
        "code": "51172407"
      },
      { "name": "Aluminum hydroxide/magnesium carbonate", "code": "51172408" },
      {
        "name": "Aluminum hydroxide/magnesium carbonate/sodium alginate",
        "code": "51172409"
      },
      { "name": "Aluminum hydroxide/magnesium hydroxide", "code": "51172410" },
      { "name": "Amylase/papain", "code": "51172411" },
      {
        "name": "Aluminum hydroxide/magnesium hydroxide/simethicone",
        "code": "51172412"
      },
      {
        "name": "Aluminum hydroxide/magnesium trisilicate",
        "code": "51172413"
      },
      {
        "name": "Amylase/atropine/cellulase/hyoscyamine/lipase/phenobarbital/protease",
        "code": "51172414"
      },
      { "name": "Amylase/atropine/lipase/protease", "code": "51172415" },
      {
        "name": "Amylase/bile salts/dehydrocholic acid/lipase/pepsin/proteolytic",
        "code": "51172416"
      },
      {
        "name": "Amylase/cellulase/homatropine/phenobarbital/protease",
        "code": "51172417"
      },
      {
        "name": "Amylase/cellulase/hyoscyamine/lipase/phenyltoloxamine/protease",
        "code": "51172418"
      },
      {
        "name": "Amylase/dehydrocholic acid/desoxycholic acid/protease",
        "code": "51172419"
      },
      {
        "name": "Amylase/dehydrocholic/desoxycholic/homatropine/phenobarbital/protease",
        "code": "51172420"
      },
      { "name": "Belladonna/kaolin/opium/pectin", "code": "51172421" },
      { "name": "Asafetida/magnesia", "code": "51172422" },
      { "name": "Aspirin/citric acid/sodium bicarbonate", "code": "51172423" },
      {
        "name": "Atropine/butabarbital/hyoscyamine/ox bile/pepsin/scopolamine",
        "code": "51172424"
      },
      { "name": "Atropine/difenoxin", "code": "51172425" },
      { "name": "Atropine/diphenoxylate", "code": "51172426" },
      {
        "name": "Atropine/hyoscyamine/kaolin/pectin/scopolamine",
        "code": "51172427"
      },
      { "name": "Atropine/kaolin/phenobarbital", "code": "51172428" },
      {
        "name": "Belladonna/bile salts/pancreatin/pepsin/phenobarbital",
        "code": "51172429"
      },
      {
        "name": "Belladonna/dehydrocholic acid/lipase/ox bile/pancreatin/pepsin",
        "code": "51172430"
      },
      { "name": "Bismuth subcarbonate/kaolin/pectin", "code": "51172431" },
      { "name": "Belladonna/kaolin/pectin", "code": "51172432" },
      {
        "name": "Belladonna/kaolin/pectin/sodium benzoate",
        "code": "51172433"
      },
      { "name": "Belladonna/kaolin/phenobarbital", "code": "51172434" },
      {
        "name": "Benzocaine/bismuth subnitrate/cerium oxalate",
        "code": "51172435"
      },
      {
        "name": "Benzocaine/calcium carbonate/magnesium carbonate/phenobarbital",
        "code": "51172436"
      },
      { "name": "Betaine/protease", "code": "51172437" },
      {
        "name": "Bile salts/dehydrocholic acid/pancreatin/pepsin",
        "code": "51172438"
      },
      { "name": "Bismuth hydroxide/kaolin/opium/pectin", "code": "51172439" },
      {
        "name": "Bismuth subcarbonate/kaolin/opium/pectin",
        "code": "51172440"
      },
      {
        "name": "Calcium carbonate/famotidine/magnesium hydroxide",
        "code": "51172441"
      },
      { "name": "Bismuth subcarbonate/morphine", "code": "51172442" },
      {
        "name": "Bismuth subgallate/kaolin/opium/pectin/zinc phenosulfonate",
        "code": "51172443"
      },
      {
        "name": "Bismuth subnitrate/calcium carbonate/magnesium carbonate",
        "code": "51172444"
      },
      { "name": "Bismuth subsalicylate/calcium carbonate", "code": "51172445" },
      {
        "name": "Bismuth subsalicylate/codeine/kaolin/pectin",
        "code": "51172446"
      },
      {
        "name": "Bismuth subsalicylate/kaolin/opium/pectin",
        "code": "51172447"
      },
      {
        "name": "Bismuth subsalicylate/magnesium silicate/salicylic acid/sodium s",
        "code": "51172448"
      },
      { "name": "Bismuth/calcium/opium/pectin/zinc", "code": "51172449" },
      {
        "name": "Bismuth/kaolin/opium/pectin/potassium/salol/zinc",
        "code": "51172450"
      },
      { "name": "Calcium carbonate/pectin", "code": "51172451" },
      {
        "name": "Calcium carbonate/folic acid/magnesium carbonate",
        "code": "51172452"
      },
      { "name": "Calcium carbonate/glycine", "code": "51172453" },
      { "name": "Calcium carbonate/magnesium carbonate", "code": "51172454" },
      {
        "name": "Calcium carbonate/magnesium carbonate/magnesium oxide",
        "code": "51172455"
      },
      {
        "name": "Calcium carbonate/magnesium carbonate/sodium alginate",
        "code": "51172456"
      },
      { "name": "Calcium carbonate/magnesium hydroxide", "code": "51172457" },
      {
        "name": "Calcium carbonate/magnesium hydroxide/phenobarbital",
        "code": "51172458"
      },
      {
        "name": "Calcium carbonate/magnesium hydroxide/simethicone",
        "code": "51172459"
      },
      {
        "name": "Calcium carbonate/nux vomica/phenobarbital",
        "code": "51172460"
      },
      {
        "name": "Homatropine/hyoscyamine/pancreatin/pepsin/phenobarbital/scopolam",
        "code": "51172461"
      },
      {
        "name": "Homatropine/hyoscyamine/pancreatin/pepsin/phenobarbital/scopolamine",
        "code": "51172462"
      },
      { "name": "Calcium carbonate/simethicone", "code": "51172463" },
      { "name": "Choline/dexpanthenol", "code": "51172464" },
      {
        "name": "Citric acid/simethicone/sodium bicarbonate",
        "code": "51172465"
      },
      { "name": "Dehydrocholic acid/homatropine", "code": "51172466" },
      {
        "name": "Dehydrocholic acid/homatropine/phenobarbital",
        "code": "51172467"
      },
      { "name": "Dehydrocholic acid/pancreatin/pepsin", "code": "51172468" },
      { "name": "Pancreatin/pyridoxine", "code": "51172469" },
      { "name": "Homatropine/opium/pectin", "code": "51172470" },
      { "name": "Isopropamide/prochlorperazine", "code": "51172471" },
      { "name": "Kaolin/paregoric/pectin", "code": "51172472" },
      { "name": "Kaolin/pectin/zinc phenolsulfonate", "code": "51172473" },
      { "name": "Lansoprazole/naproxen", "code": "51172474" },
      { "name": "Loperamide/simethicone", "code": "51172475" },
      { "name": "Magaldrate/simethicone", "code": "51172476" },
      {
        "name": "Nux vomica/pancreatin/papain/pepsin/phenobarbital/sodium bicarbonate",
        "code": "51172477"
      },
      { "name": "Omeprazole/sodium bicarbonate", "code": "51172478" },
      {
        "name": "Potassium bicarbonate/sodium bicarbonate",
        "code": "51172479"
      },
      { "name": "Sodium bicarbonate/sodium citrate", "code": "51172480" },
      { "name": "Combination poison antidotes", "code": "51172500" },
      {
        "name": "Amyl nitrite/sodium nitrite/sodium thiosulfate",
        "code": "51172501"
      },
      { "name": "Atropine/pralidoxime", "code": "51172502" },
      { "name": "Charcoal/ipecac", "code": "51172503" },
      { "name": "Charcoal/sorbitol", "code": "51172504" },
      { "name": "Chlorpheniramine/epinephrine", "code": "51172505" },
      {
        "name": "Sodium chloride/sodium sulfite/sodium thiosulfate",
        "code": "51172506"
      },
      { "name": "Combination antiemetics", "code": "51172600" },
      { "name": "Dextrose/levulose/phosphoric acid", "code": "51172601" },
      { "name": "Fructose/glucose/phosphoric acid", "code": "51172602" },
      { "name": "Combination antispasmodics", "code": "51172700" },
      {
        "name": "Hyoscyamine/methenamine/methylene blue/phenyl salicylate/sodium salicylate",
        "code": "51172701"
      },
      {
        "name": "Atropine/benzoic acid/hyoscyamine/methenamine/phenyl salicylate",
        "code": "51172702"
      },
      {
        "name": "Atropine/benzoic/gelsemium/hyoscyamine/methenamine/methylene/phenobarbital",
        "code": "51172703"
      },
      {
        "name": "Atropine/benzoic/hyoscyamine/methenamine/methylene/phenyl salicylate",
        "code": "51172704"
      },
      {
        "name": "Atropine/hyoscyamine/phenazopyridine/scopolamine",
        "code": "51172705"
      },
      {
        "name": "Belladonna/benzoic/gelsemium/methenamine/phenazopyridine/phenyl salicylate",
        "code": "51172706"
      },
      { "name": "Belladonna/methenamine/salicylamide", "code": "51172707" },
      {
        "name": "Butabarbital/hyoscyamine/phenazopyridine",
        "code": "51172708"
      },
      {
        "name": "Hyoscyamine/methamine/methylene blue/phenyl salicylate/sodium bicarbonate",
        "code": "51172709"
      },
      { "name": "Hyoscyamine/methenamine", "code": "51172710" },
      {
        "name": "Hyoscyamine/methenamine/methylene/phenyl salicyl/sodium phoshate",
        "code": "51172711"
      },
      {
        "name": "Hyoscyamus/phenazopyridine/sulfamethizole",
        "code": "51172712"
      },
      {
        "name": "Methenamine/na biphospha/phenyl salicylate/methelene/hyoscyamine",
        "code": "51172713"
      },
      {
        "name": "Combination cariostatics, dental or mouthwashes or oral agents",
        "code": "51172800"
      },
      {
        "name": "Benzalkonium chloride/benzocaine/zinc chloride",
        "code": "51172801"
      },
      {
        "name": "Alcohol/benzoic acid/eucalyptol/methyl salicylate/thymol",
        "code": "51172802"
      },
      {
        "name": "Alcohol/cetylpyridinium chloride/domiphen bromide",
        "code": "51172803"
      },
      {
        "name": "Alcohol/eucalyptol/menthol/methyl salicylate/thymol",
        "code": "51172804"
      },
      { "name": "Alcohol/fluoride", "code": "51172805" },
      { "name": "Alcohol/gum benzoin", "code": "51172806" },
      {
        "name": "Aluminum chloride/hydroxyquinoline sulfate/tetracaine",
        "code": "51172807"
      },
      { "name": "Amyl metacresol/benzocaine/myrrh/phenol", "code": "51172808" },
      { "name": "Benzocaine/chlorobutanol", "code": "51172809" },
      {
        "name": "Benzocaine/chlorobutanol/eugenol/peruvian balsam",
        "code": "51172810"
      },
      {
        "name": "Benzocaine/cinnamon oil/clove oil/eucalyptus oil/phenol",
        "code": "51172811"
      },
      { "name": "Benzocaine/clove oil", "code": "51172812" },
      { "name": "Benzocaine/phenol", "code": "51172813" },
      { "name": "Benzocaine/phenol/povidone iodine", "code": "51172814" },
      { "name": "Calcium carbonate/calcium phosphate", "code": "51172815" },
      { "name": "Camphor/menthol/phenol", "code": "51172816" },
      { "name": "Camphor/parachlorophenol", "code": "51172817" },
      { "name": "Glycerin/lemon", "code": "51172818" },
      { "name": "Carbamide peroxide/glycerol", "code": "51172819" },
      {
        "name": "Cetylpyridinium chloride/chlorophyl copper complex",
        "code": "51172820"
      },
      { "name": "Cresol/formaldehyde", "code": "51172821" },
      { "name": "Epinephrine/zinc chloride", "code": "51172822" },
      { "name": "Epinephrine/zinc phenolsulfonate", "code": "51172823" },
      { "name": "Eucalyptol/menthol/thymol", "code": "51172824" },
      { "name": "Fluoride/phosphoric acid", "code": "51172825" },
      { "name": "Glycerin/kaolin/sodium fluoride", "code": "51172826" },
      { "name": "Potassium nitrate/sodium fluoride", "code": "51172827" },
      {
        "name": "Glycerin/phenol/sodium bicarbonate/sodium borate",
        "code": "51172828"
      },
      {
        "name": "Hydrofluoric acid/phosphoric acid/sodium fluoride",
        "code": "51172829"
      },
      { "name": "Hydrofluoric acid/sodium fluoride", "code": "51172830" },
      {
        "name": "Hydrofluoric acid/sodium fluoride/stannous fluoride",
        "code": "51172831"
      },
      { "name": "Hydrogen peroxide/povidone iodine", "code": "51172832" },
      { "name": "Hydrogen peroxide/sodium bicarbonate", "code": "51172833" },
      {
        "name": "Iodine/potassium iodide/zinc phenolsulfonate",
        "code": "51172834"
      },
      {
        "name": "Paraformaldehyde/titanium dioxide/zinc oxide",
        "code": "51172835"
      },
      { "name": "Phosphoric acid/sodium fluoride", "code": "51172836" },
      { "name": "Sodium biphosphate/sodium fluoride", "code": "51172837" },
      { "name": "Sodium fluoride/sodium phosphate", "code": "51172838" },
      { "name": "Combination genito-urinary agents", "code": "51172900" },
      {
        "name": "Acetic acid/glycerin/oxyquinoline/ricinoleic acid",
        "code": "51172901"
      },
      { "name": "Acetic acid/oxyquinoline", "code": "51172902" },
      {
        "name": "Acetic acid/oxyquinoline/ricinoleic acid",
        "code": "51172903"
      },
      {
        "name": "Cystine/inositol/methionine/sodium propionate/urea",
        "code": "51172904"
      },
      { "name": "Dutasteride/tamsulosin", "code": "51172905" },
      {
        "name": "Eucalyptus oil/menthol/methyl salicylate/phenol/sodium chloride",
        "code": "51172906"
      },
      {
        "name": "Lactic acid/methyl salicylate/octoxynol 9/sodium bicarbonate",
        "code": "51172907"
      },
      { "name": "Lactic acid/octoxynol 9/sodium lactate", "code": "51172908" },
      { "name": "Combination hemorrhoidal preparations", "code": "51173000" },
      {
        "name": "Benzocaine/bismuth subgallate/phenylmecuric nitrate/zinc oxide",
        "code": "51173001"
      },
      { "name": "Benzocaine/camphor/ephedrine/zinc oxide", "code": "51173002" },
      {
        "name": "Benzyl benzoate/bismuth/calcium/coconut oil/vegetable oil/zinc oxide",
        "code": "51173003"
      },
      {
        "name": "Benzyl benzoate/bismuth/peruvian balsam/zinc oxide",
        "code": "51173004"
      },
      {
        "name": "Bismuth subgallate/boric acid/zinc oxide",
        "code": "51173005"
      },
      { "name": "Camphor/ephedrine/zinc oxide", "code": "51173006" },
      { "name": "Hemorrhoidal/hydrocortisone", "code": "51173007" },
      { "name": "Pramoxine/zinc oxide", "code": "51173008" },
      { "name": "Combination histamine antagonists", "code": "51173100" },
      { "name": "Cimetidine/dextrose", "code": "51173101" },
      { "name": "Cimetidine/sodium chloride", "code": "51173102" },
      { "name": "Ranitidine/sodium chloride", "code": "51173103" },
      { "name": "Combination laxatives", "code": "51173200" },
      {
        "name": "Bisacodyl/magnesium citrate/phenolphthalein",
        "code": "51173201"
      },
      { "name": "Aloe/cascara sagrada", "code": "51173202" },
      {
        "name": "Asafetida/capsicum/cascara sagrada/ginger/nux vomica",
        "code": "51173203"
      },
      {
        "name": "Bagenema/bisacodyl/castile soap/castor oil",
        "code": "51173204"
      },
      { "name": "Benzocaine/docusate", "code": "51173205" },
      { "name": "Bisacodyl/castor oil", "code": "51173206" },
      {
        "name": "Bisacodyl/docusate/senna concentrate/senna extract/sucrose",
        "code": "51173207"
      },
      { "name": "Bisacodyl/electrolytes/peg-3350", "code": "51173208" },
      { "name": "Bisacodyl/magnesium citrate", "code": "51173209" },
      {
        "name": "Bisacodyl/magnesium citrate/magnesium sulfate/senna/sucrose",
        "code": "51173210"
      },
      {
        "name": "Carboxymethylcellulose/docusate/phenolphthalein",
        "code": "51173211"
      },
      { "name": "Bisacodyl/phospho soda", "code": "51173212" },
      { "name": "Bisacodyl/phospho-soda", "code": "51173213" },
      { "name": "Bisacodyl/tannic acid", "code": "51173214" },
      { "name": "Bryonia/hydrastis/phenolphthalein", "code": "51173215" },
      { "name": "Calcium pantothenate/danthron", "code": "51173216" },
      { "name": "Calcium pantothenate/danthron/docusate", "code": "51173217" },
      {
        "name": "Capsicum/cascara sagrada/ginger/nux vomica",
        "code": "51173218"
      },
      {
        "name": "Carboxymethylcellulose/casanthranol/docusate",
        "code": "51173219"
      },
      { "name": "Carboxymethylcellulose/docusate", "code": "51173220" },
      { "name": "Magnesium hydroxide/mineral oil", "code": "51173221" },
      { "name": "Casanthranol/docusate", "code": "51173222" },
      { "name": "Cascara/mineral oil", "code": "51173223" },
      { "name": "Danthron/docusate", "code": "51173224" },
      { "name": "Dehydrocholic acid/docusate", "code": "51173225" },
      {
        "name": "Dehydrocholic acid/docusate/phenolphthalein",
        "code": "51173226"
      },
      { "name": "Docusate/phenolphthalein", "code": "51173227" },
      { "name": "Docusate/sennosides", "code": "51173228" },
      { "name": "Galactose/lactose/lactulose", "code": "51173229" },
      {
        "name": "Magnesium citrate/phenolphthalein/potassium bitartrate/sodium bicarbonate",
        "code": "51173230"
      },
      {
        "name": "Magnesium sulfate/potassium sulfate/sodium sulfate",
        "code": "51173231"
      },
      { "name": "Mineral oil/phenolphthalein", "code": "51173232" },
      { "name": "Potassium bitartrate/sodium bicarbonate", "code": "51173233" },
      { "name": "Combination rectal, local other", "code": "51173300" },
      { "name": "Alcloxa/pramoxine/witch hazel", "code": "51173301" },
      { "name": "Hormones and hormone antagonists", "code": "51180000" },
      {
        "name": "Antidiabetic agents and hyperglycemic agents",
        "code": "51181500"
      },
      { "name": "Epalrestat", "code": "51181526" },
      { "name": "Fidarestat", "code": "51181538" },
      { "name": "Gliamilide", "code": "51181539" },
      { "name": "Glisamuride", "code": "51181546" },
      { "name": "Imirestat", "code": "51181548" },
      { "name": "Minalrestat", "code": "51181553" },
      { "name": "Ponalrestat", "code": "51181565" },
      { "name": "Zenarestat", "code": "51181576" },
      { "name": "Zopolrestat", "code": "51181577" },
      { "name": "Thyroid and antithyroid drugs", "code": "51181600" },
      { "name": "Liothyronine", "code": "51181602" },
      { "name": "Liotrix", "code": "51181603" },
      {
        "name": "Thyroid-stimulating hormone or thyrotropin or thyrotropin alfa",
        "code": "51181604"
      },
      { "name": "Methimazole", "code": "51181605" },
      { "name": "Propylthiouracil", "code": "51181606" },
      { "name": "Potassium iodide", "code": "51181607" },
      {
        "name": "Levothyroxine or thyroxine or triiodothyronine",
        "code": "51181608"
      },
      { "name": "Montirelin", "code": "51181610" },
      { "name": "Azetirelin", "code": "51181611" },
      { "name": "Benzylthiouracil", "code": "51181612" },
      { "name": "Calcitonin", "code": "51181613" },
      { "name": "Carbimazole", "code": "51181614" },
      { "name": "Dibromotyrosine", "code": "51181615" },
      { "name": "Diiodotyrosine", "code": "51181616" },
      { "name": "Methylthiouracil", "code": "51181617" },
      { "name": "Potassium perchlorate", "code": "51181618" },
      { "name": "Thiouracil", "code": "51181619" },
      { "name": "Thyromedan or tyromedan", "code": "51181620" },
      {
        "name": "Thyrotropin-releasing hormone (trh) or thyrotropin-releasing factor (trf) or thyroliberin or protirelin",
        "code": "51181621"
      },
      { "name": "Tiratricol", "code": "51181622" },
      { "name": "Elcatonin", "code": "51181623" },
      { "name": "Calcitonin human", "code": "51181624" },
      { "name": "Calcitonin salmon", "code": "51181625" },
      { "name": "Liothyronine hydrochloride", "code": "51181626" },
      { "name": "Liothyronine sodium", "code": "51181627" },
      { "name": "Protirelin tartrate", "code": "51181628" },
      {
        "name": "Gonadotropic hormones and ovarian stimulants and inhibitors",
        "code": "51181900"
      },
      {
        "name": "Choriogonadotropin alfa or chorionic gonadotropin",
        "code": "51181901"
      },
      { "name": "Menotropin", "code": "51181905" },
      { "name": "Urofollitropin", "code": "51181906" },
      { "name": "Follitropin", "code": "51181912" },
      { "name": "Lynestrenol or lynoestrenol", "code": "51181914" },
      { "name": "Epimestrol", "code": "51181915" },
      { "name": "Acefluranol", "code": "51181916" },
      { "name": "Buserelin", "code": "51181917" },
      { "name": "Clometerone or clometherone", "code": "51181918" },
      { "name": "Clomifene or clomiphene", "code": "51181919" },
      { "name": "Cyclofenil", "code": "51181920" },
      { "name": "Enclomifene", "code": "51181921" },
      { "name": "Ganirelix", "code": "51181922" },
      { "name": "Nitromifene", "code": "51181923" },
      { "name": "Panomifene", "code": "51181924" },
      { "name": "Zuclomifene or zuclomiphene", "code": "51181925" },
      { "name": "Buserelin acetate", "code": "51181926" },
      { "name": "Clomifene citrate", "code": "51181927" },
      { "name": "Cyclofenil diphenol", "code": "51181928" },
      { "name": "Ganirelix acetate", "code": "51181929" },
      { "name": "Nitromifene citrate", "code": "51181930" },
      { "name": "Androgens and androgen inhibitors", "code": "51182000" },
      { "name": "Finasteride", "code": "51182001" },
      { "name": "Testosterone", "code": "51182002" },
      { "name": "Trenbolone", "code": "51182006" },
      { "name": "Methandriol", "code": "51182007" },
      { "name": "Fluoxymesterone", "code": "51182008" },
      { "name": "Methyltestosterone", "code": "51182009" },
      { "name": "Nandrolone", "code": "51182010" },
      {
        "name": "Androstenolone or dehydroepiandrosterone or DHEA or prasterone",
        "code": "51182012"
      },
      { "name": "Stanozolol", "code": "51182013" },
      { "name": "Dutasteride", "code": "51182014" },
      { "name": "Cloxotestosterone", "code": "51182015" },
      { "name": "Alfatradiol", "code": "51182016" },
      {
        "name": "Androstanolone or dht or dihydrotestosterone",
        "code": "51182017"
      },
      { "name": "Androstenediol", "code": "51182018" },
      { "name": "Androstenedione", "code": "51182019" },
      { "name": "Bolandiol", "code": "51182020" },
      { "name": "Bolasterone", "code": "51182021" },
      { "name": "Clostebol", "code": "51182022" },
      { "name": "Mebolazine", "code": "51182023" },
      { "name": "Danazol", "code": "51182024" },
      { "name": "Enestebol", "code": "51182025" },
      { "name": "Ethylestrenol", "code": "51182026" },
      { "name": "Flutamide", "code": "51182027" },
      { "name": "Formebolone", "code": "51182028" },
      { "name": "Furazabol", "code": "51182029" },
      { "name": "Norboletone or norbolethone", "code": "51182030" },
      { "name": "Mesterolone", "code": "51182031" },
      {
        "name": "Metandienone or methandrostenolone or methandienone",
        "code": "51182032"
      },
      { "name": "Metenolone", "code": "51182033" },
      { "name": "Mibolerone", "code": "51182034" },
      { "name": "Nilutamide", "code": "51182035" },
      { "name": "Oxymetholone", "code": "51182036" },
      { "name": "Norclostebol", "code": "51182037" },
      { "name": "Norethandrolone", "code": "51182038" },
      { "name": "Normethandrone", "code": "51182039" },
      { "name": "Oxabolone", "code": "51182040" },
      { "name": "Oxandrolone", "code": "51182041" },
      { "name": "Oxymesterone", "code": "51182042" },
      { "name": "Propetandrol", "code": "51182043" },
      { "name": "Quinbolone", "code": "51182044" },
      { "name": "Rosterolone", "code": "51182045" },
      { "name": "Roxibolone", "code": "51182046" },
      { "name": "Tibolone", "code": "51182047" },
      { "name": "Bolandiol dipropionate", "code": "51182048" },
      { "name": "Clostebol propionate", "code": "51182049" },
      { "name": "Metenolone acetate", "code": "51182050" },
      { "name": "Metenolone enantate", "code": "51182051" },
      { "name": "Methandriol diacetate", "code": "51182052" },
      { "name": "Methandriol dipropionate", "code": "51182053" },
      { "name": "Nandrolone benzoate", "code": "51182054" },
      { "name": "Nandrolone cyclohexanecarboxylate", "code": "51182055" },
      { "name": "Nandrolone cyclohexylpropionate", "code": "51182056" },
      { "name": "Nandrolone cyclotate", "code": "51182057" },
      { "name": "Nandrolone decanoate", "code": "51182058" },
      { "name": "Nandrolone furylpropionate", "code": "51182059" },
      { "name": "Nandrolone phenpropionate", "code": "51182060" },
      { "name": "Nandrolone propionate", "code": "51182061" },
      { "name": "Nandrolone undecylate", "code": "51182062" },
      { "name": "Oxabolone cipionate", "code": "51182063" },
      { "name": "Prasterone sodium sulfate", "code": "51182064" },
      { "name": "Prasterone sulfate", "code": "51182065" },
      { "name": "Testosterone benzoate", "code": "51182066" },
      { "name": "Testosterone cipionate", "code": "51182067" },
      { "name": "Testosterone cyclopentanepropionate", "code": "51182068" },
      { "name": "Testosterone cypionate", "code": "51182069" },
      { "name": "Testosterone enantate", "code": "51182070" },
      { "name": "Testosterone enanthate", "code": "51182071" },
      { "name": "Testosterone heptanoate", "code": "51182072" },
      { "name": "Testosterone hexahydrobenzoate", "code": "51182073" },
      { "name": "Testosterone phenylacetate", "code": "51182074" },
      { "name": "Testosterone propionate", "code": "51182075" },
      { "name": "Testosterone undecanoate", "code": "51182076" },
      { "name": "Testosterone undecylate", "code": "51182077" },
      { "name": "Trenbolone acetate", "code": "51182078" },
      { "name": "Trenbolone cyclohexylmethylcarbonate", "code": "51182079" },
      { "name": "Posterior pituitary hormones", "code": "51182100" },
      { "name": "Desmopressin", "code": "51182101" },
      { "name": "Argipressin or vasopressin", "code": "51182102" },
      { "name": "Conivaptan", "code": "51182103" },
      { "name": "Felypressin", "code": "51182104" },
      { "name": "Lypressin", "code": "51182105" },
      { "name": "Terlipressin", "code": "51182106" },
      { "name": "Conivaptan hydrochloride", "code": "51182107" },
      { "name": "Desmopressin acetate", "code": "51182108" },
      { "name": "Desmopressin acetate anhydrous", "code": "51182109" },
      { "name": "Desmopressin acetate trihydrate", "code": "51182110" },
      { "name": "Drugs for inducing labor", "code": "51182200" },
      { "name": "Dinoprostone", "code": "51182201" },
      { "name": "Methylergometrine or methylergonovine", "code": "51182202" },
      { "name": "Oxytocin", "code": "51182203" },
      { "name": "Ergometrine or ergonovine", "code": "51182204" },
      { "name": "Doxaprost", "code": "51182205" },
      { "name": "Atosiban", "code": "51182206" },
      { "name": "Carbetocin", "code": "51182207" },
      { "name": "Carboprost", "code": "51182208" },
      { "name": "Deaminooxytocin or demoxytocin", "code": "51182209" },
      { "name": "Dinoprost", "code": "51182210" },
      { "name": "Epostane", "code": "51182211" },
      { "name": "Mepitiostane", "code": "51182212" },
      { "name": "Meteneprost", "code": "51182213" },
      { "name": "Misoprostol", "code": "51182214" },
      { "name": "Atosiban acetate", "code": "51182215" },
      { "name": "Carboprost methyl", "code": "51182216" },
      { "name": "Carboprost methyl ester", "code": "51182217" },
      { "name": "Carboprost trometamol", "code": "51182218" },
      { "name": "Carboprost tromethamine", "code": "51182219" },
      { "name": "Dinoprost trometamol", "code": "51182220" },
      { "name": "Dinoprost tromethamine", "code": "51182221" },
      { "name": "Ergometrine maleate", "code": "51182222" },
      { "name": "Oxytocin citrate", "code": "51182223" },
      { "name": "Growth hormones and their inhibitors", "code": "51182300" },
      { "name": "Somatrem", "code": "51182301" },
      {
        "name": "Human growth hormone or somatotropin or somatropin",
        "code": "51182302"
      },
      { "name": "Somatostatin", "code": "51182303" },
      { "name": "Egrifta or tesamorelin", "code": "51182305" },
      { "name": "Tesamorelin acetate", "code": "51182306" },
      { "name": "Calcium regulators and calcium salts", "code": "51182400" },
      { "name": "Calcium chloride", "code": "51182401" },
      { "name": "Dibase calcium phosphate", "code": "51182405" },
      { "name": "Alendronic acid or alendronate sodium", "code": "51182406" },
      { "name": "Cellulose sodium phosphate", "code": "51182408" },
      { "name": "Etidronate", "code": "51182410" },
      { "name": "Tribasic calcium phosphates", "code": "51182412" },
      {
        "name": "Calcium glycerophosphate or calcium glycero phosphate",
        "code": "51182413"
      },
      { "name": "Zoledronic acid", "code": "51182415" },
      { "name": "Pamidronate disodium or pamidronic acid", "code": "51182416" },
      { "name": "Risedronate sodium or risedronic acid", "code": "51182417" },
      { "name": "Calcium acetate", "code": "51182418" },
      { "name": "Ibandronate or ibandronic acid", "code": "51182423" },
      { "name": "Butedronic acid", "code": "51182425" },
      { "name": "Cinacalcet", "code": "51182426" },
      { "name": "Clodronate or clodronic acid", "code": "51182427" },
      { "name": "Denosumab", "code": "51182428" },
      { "name": "Strontium ranelate", "code": "51182429" },
      { "name": "Etidronic acid", "code": "51182430" },
      { "name": "Ipriflavone", "code": "51182431" },
      { "name": "Minodronic acid", "code": "51182432" },
      { "name": "Neridronic acid", "code": "51182433" },
      { "name": "Olpadronic acid", "code": "51182434" },
      { "name": "Teriparatide", "code": "51182435" },
      { "name": "Tiludronic acid", "code": "51182436" },
      { "name": "Cinacalcet hydrochloride", "code": "51182437" },
      { "name": "Teriparatide acetate", "code": "51182438" },
      { "name": "Teriparatide recombinant human", "code": "51182439" },
      {
        "name": "Combination antithyroid agents and supplements",
        "code": "51182500"
      },
      { "name": "Levothyroxine/liothyronine", "code": "51182501" },
      {
        "name": "Combination hormones/synthetics/modifiers",
        "code": "51182600"
      },
      { "name": "Diethylstilbestrol/methyltestosterone", "code": "51182601" },
      { "name": "Combination oral hypoglycemic agents", "code": "51182700" },
      { "name": "Glimepiride/pioglitazone", "code": "51182701" },
      { "name": "Glimepiride/rosiglitazone", "code": "51182702" },
      { "name": "Glipizide/metformin", "code": "51182703" },
      { "name": "Glyburide/metformin", "code": "51182704" },
      { "name": "Metformin/pioglitazone", "code": "51182705" },
      { "name": "Metformin/repaglinide", "code": "51182706" },
      { "name": "Metformin/rosiglitazone", "code": "51182707" },
      { "name": "Metformin/saxagliptin", "code": "51182708" },
      { "name": "Metformin/sitagliptin", "code": "51182709" },
      { "name": "Combination oxytocics", "code": "51182800" },
      { "name": "Dextrose/oxytocin", "code": "51182801" },
      { "name": "Combination phosphorus", "code": "51182900" },
      {
        "name": "Calcium glycerophosphate/phosphoric acid/sodium glycerophosphate",
        "code": "51182901"
      },
      { "name": "Combination anterior pituitary", "code": "51183000" },
      { "name": "Corticotropin/zinc hydroxide", "code": "51183001" },
      { "name": "Antidiabetic and hyperglycemic amides", "code": "51183100" },
      { "name": "Alrestatin", "code": "51183101" },
      { "name": "Alrestatin sodium", "code": "51183102" },
      { "name": "Benfluorex", "code": "51183103" },
      { "name": "Benfluorex hydrochloride", "code": "51183104" },
      { "name": "Gliflumide", "code": "51183105" },
      { "name": "Glycodiazine or glymidine", "code": "51183106" },
      { "name": "Glymidine sodium", "code": "51183107" },
      { "name": "Glypinamide", "code": "51183108" },
      { "name": "Glyprothiazol", "code": "51183109" },
      { "name": "Ingliforib", "code": "51183110" },
      { "name": "Meglitinide", "code": "51183111" },
      { "name": "Thiohexamide", "code": "51183112" },
      { "name": "Tolpyrramide", "code": "51183113" },
      { "name": "Antidiabetic and hyperglycemic amines", "code": "51183200" },
      { "name": "Alogliptin", "code": "51183201" },
      { "name": "Alogliptin benzoate", "code": "51183202" },
      { "name": "Isaglidole", "code": "51183203" },
      { "name": "Tolrestat", "code": "51183204" },
      {
        "name": "Antidiabetic and hyperglycemic dipeptidyl peptidase-4 inhibitors",
        "code": "51183300"
      },
      { "name": "Linagliptin", "code": "51183301" },
      { "name": "Saxagliptin", "code": "51183302" },
      { "name": "Sitagliptin", "code": "51183303" },
      { "name": "Vildagliptin", "code": "51183304" },
      { "name": "Saxagliptin anhydrous", "code": "51183305" },
      { "name": "Sitagliptin phosphate", "code": "51183306" },
      { "name": "Saxagliptin hydrochloride", "code": "51183307" },
      { "name": "Sitagliptin phosphate hydrate", "code": "51183308" },
      { "name": "Saxagliptin monohydrate", "code": "51183309" },
      { "name": "Antidiabetic and hyperglycemic glinides", "code": "51183400" },
      { "name": "Aminoguanidine or pimagedine", "code": "51183401" },
      { "name": "Mitiglinide", "code": "51183402" },
      { "name": "Nateglinide", "code": "51183403" },
      { "name": "Repaglinide", "code": "51183404" },
      { "name": "Mitiglinide calcium", "code": "51183405" },
      { "name": "Mitiglinide calcium dihydrate", "code": "51183406" },
      { "name": "Mitiglinide calcium hydrate", "code": "51183407" },
      {
        "name": "Antidiabetic and hyperglycemic guanidines",
        "code": "51183500"
      },
      { "name": "Benfosformin", "code": "51183501" },
      { "name": "Buformin", "code": "51183502" },
      { "name": "Etoformin", "code": "51183503" },
      { "name": "Linogliride", "code": "51183504" },
      { "name": "Metformin", "code": "51183505" },
      { "name": "Benfosformin anhydrous", "code": "51183506" },
      { "name": "Buformin hydrochloride", "code": "51183507" },
      { "name": "Metformin embonate", "code": "51183508" },
      { "name": "Metformin glycinate", "code": "51183509" },
      { "name": "Metformin hydrochloride", "code": "51183510" },
      { "name": "Phenformin", "code": "51183511" },
      { "name": "Metformin orotate", "code": "51183512" },
      { "name": "Phenformin hydrochloride", "code": "51183513" },
      {
        "name": "Antidiabetic and hyperglycemic peptide hormones",
        "code": "51183600"
      },
      { "name": "Exenatide", "code": "51183601" },
      { "name": "Glucagon", "code": "51183602" },
      { "name": "Insulin", "code": "51183603" },
      { "name": "Exenatide synthetic", "code": "51183604" },
      { "name": "Glucagon hydrochloride", "code": "51183605" },
      { "name": "Insulin argine", "code": "51183606" },
      { "name": "Insulin aspart", "code": "51183607" },
      { "name": "Insulin beef", "code": "51183608" },
      { "name": "Insulin detemir", "code": "51183609" },
      { "name": "Insulin glargine", "code": "51183610" },
      { "name": "Liraglutide", "code": "51183611" },
      { "name": "Pancreatic extract", "code": "51183612" },
      { "name": "Insulin glulisine", "code": "51183613" },
      { "name": "Insulin human", "code": "51183614" },
      { "name": "Insulin lispro", "code": "51183615" },
      { "name": "Insulin peglispro", "code": "51183616" },
      { "name": "Insulin porcine", "code": "51183617" },
      { "name": "Insulin pork", "code": "51183618" },
      { "name": "Pramlintide", "code": "51183619" },
      { "name": "Pramlintide acetate", "code": "51183620" },
      {
        "name": "Antidiabetic and hyperglycemic thiazolidinediones",
        "code": "51183700"
      },
      { "name": "Ciglitazone", "code": "51183701" },
      { "name": "Netoglitazone", "code": "51183702" },
      { "name": "Pioglitazone", "code": "51183703" },
      { "name": "Rivoglitazone", "code": "51183704" },
      { "name": "Rosiglitazone", "code": "51183705" },
      { "name": "Pioglitazone hydrochloride", "code": "51183706" },
      { "name": "Rivoglitazone hydrochloride", "code": "51183707" },
      { "name": "Rosiglitazone maleate", "code": "51183708" },
      { "name": "Troglitazone", "code": "51183709" },
      {
        "name": "Antidiabetic and hyperglycemic alpha-glucosidase inhibitors",
        "code": "51183800"
      },
      { "name": "Acarbose", "code": "51183801" },
      { "name": "Miglitol", "code": "51183802" },
      { "name": "Voglibose", "code": "51183803" },
      {
        "name": "Antidiabetic and hyperglycemic peroxisome proliferator-activated receptors",
        "code": "51183900"
      },
      { "name": "Farglitazar", "code": "51183901" },
      { "name": "Ragaglitazar", "code": "51183902" },
      { "name": "Tesaglitazar", "code": "51183903" },
      {
        "name": "Antidiabetic and hyperglycemic sulfonylureas",
        "code": "51184000"
      },
      { "name": "Acetohexamide", "code": "51184001" },
      { "name": "Carbutamide", "code": "51184002" },
      { "name": "Chlorpropamide", "code": "51184003" },
      { "name": "Glibenclamide or glyburide", "code": "51184004" },
      { "name": "Glibornuride", "code": "51184005" },
      { "name": "Glicaramide", "code": "51184006" },
      { "name": "Gliclazide", "code": "51184007" },
      { "name": "Glipalamide", "code": "51184008" },
      { "name": "Glipizide", "code": "51184009" },
      { "name": "Gliquidone", "code": "51184010" },
      { "name": "Glisolamide", "code": "51184011" },
      { "name": "Glisoxepid or glisoxepide", "code": "51184012" },
      { "name": "Glybuthiazol", "code": "51184013" },
      { "name": "Metahexamide", "code": "51184014" },
      { "name": "Tolazamide", "code": "51184015" },
      { "name": "Tolbutamide", "code": "51184016" },
      { "name": "Agents affecting water and electrolytes", "code": "51190000" },
      { "name": "Diuretics", "code": "51191500" },
      { "name": "Chlorothiazide", "code": "51191501" },
      { "name": "Chlortalidone or chlorthalidone", "code": "51191502" },
      { "name": "Metolazone", "code": "51191503" },
      { "name": "Bumetanide", "code": "51191504" },
      { "name": "Ethacrynate sodium", "code": "51191505" },
      { "name": "Amiloride", "code": "51191506" },
      { "name": "Spironolactone", "code": "51191507" },
      { "name": "Triamterene", "code": "51191508" },
      { "name": "Mannitol", "code": "51191509" },
      {
        "name": "Canrenoate potassium or canrenone or potassium canrenoate",
        "code": "51191511"
      },
      { "name": "Hydrochlorothiazide", "code": "51191515" },
      { "name": "Hydroflumethiazide", "code": "51191516" },
      { "name": "Isosorbide", "code": "51191517" },
      { "name": "Methyclothiazide", "code": "51191518" },
      { "name": "Pamabrom", "code": "51191519" },
      { "name": "Polythiazide", "code": "51191520" },
      { "name": "Urea osmotic diuretic", "code": "51191523" },
      { "name": "Amisometradine", "code": "51191524" },
      { "name": "Aditeren", "code": "51191526" },
      { "name": "Alipamide", "code": "51191527" },
      { "name": "Althiazide or altizide", "code": "51191528" },
      { "name": "Amanozine", "code": "51191529" },
      { "name": "Aminometradine", "code": "51191530" },
      { "name": "Azolimine", "code": "51191531" },
      { "name": "Bemitradine", "code": "51191532" },
      { "name": "Benzthiazide", "code": "51191533" },
      { "name": "Besulpamide", "code": "51191534" },
      { "name": "Brocrinat", "code": "51191535" },
      { "name": "Chlormerodrin", "code": "51191536" },
      { "name": "Dehydrocholic acid", "code": "51191537" },
      { "name": "Cicletanine", "code": "51191538" },
      { "name": "Clazolimine", "code": "51191539" },
      { "name": "Clofenamide", "code": "51191540" },
      { "name": "Clopamide", "code": "51191541" },
      { "name": "Clorexolone", "code": "51191542" },
      { "name": "Cyclopenthiazide", "code": "51191543" },
      { "name": "Cyclothiazide", "code": "51191544" },
      { "name": "Epithiazide or epitizide", "code": "51191545" },
      { "name": "Etacrynic acid or ethacrynic acid", "code": "51191546" },
      { "name": "Ethiazide", "code": "51191547" },
      { "name": "Fenquizone", "code": "51191548" },
      { "name": "Frusemide or furosemide", "code": "51191549" },
      { "name": "Furterene", "code": "51191550" },
      { "name": "Mebutizide", "code": "51191551" },
      { "name": "Hydroxindasate", "code": "51191552" },
      { "name": "Indapamide", "code": "51191553" },
      { "name": "Isosorbide dinitrate", "code": "51191554" },
      { "name": "Isosorbide mononitrate", "code": "51191555" },
      { "name": "Lemidosul", "code": "51191556" },
      { "name": "Prorenoate", "code": "51191557" },
      { "name": "Mefruside", "code": "51191558" },
      { "name": "Mersalyl", "code": "51191559" },
      { "name": "Methazolamide", "code": "51191560" },
      { "name": "Meticrane", "code": "51191561" },
      { "name": "Triflocin", "code": "51191562" },
      { "name": "Quincarbate", "code": "51191563" },
      { "name": "Quinethazone", "code": "51191564" },
      { "name": "Sitalidone", "code": "51191565" },
      { "name": "Sodium acetate", "code": "51191566" },
      { "name": "Sulosemide", "code": "51191567" },
      { "name": "Theobromide or theobromine or xantheose", "code": "51191568" },
      { "name": "Tizolemide", "code": "51191569" },
      { "name": "Xipamide", "code": "51191570" },
      { "name": "Zidapamide", "code": "51191571" },
      { "name": "Amiloride hydrochloride", "code": "51191572" },
      { "name": "Amiloride hydrochloride anhydrous", "code": "51191573" },
      { "name": "Chlorothiazide sodium", "code": "51191574" },
      { "name": "Cicletanine hydrochloride", "code": "51191575" },
      { "name": "Furosemide sodium", "code": "51191576" },
      { "name": "Furosemide xantinol", "code": "51191577" },
      { "name": "Mannitol myleran", "code": "51191578" },
      { "name": "Mersalyl sodium", "code": "51191579" },
      { "name": "Prorenoate potassium", "code": "51191580" },
      { "name": "Theobromine magnesium oleate", "code": "51191581" },
      { "name": "Theobromine sodium salicylate", "code": "51191582" },
      { "name": "Triflocin sodium", "code": "51191583" },
      { "name": "Electrolytes", "code": "51191600" },
      { "name": "Sodium chloride electrolytes", "code": "51191602" },
      {
        "name": "Total parenteral alimentation or total parenteral nutrition TPN",
        "code": "51191603"
      },
      { "name": "Lactated ringers solution", "code": "51191604" },
      { "name": "Sterile water for injection", "code": "51191605" },
      { "name": "Cupric chloride", "code": "51191606" },
      { "name": "Alkalinizing agents", "code": "51191700" },
      { "name": "Trometamol or tromethamine", "code": "51191701" },
      { "name": "Multiple electrolyte solutions", "code": "51191704" },
      {
        "name": "Citric acid and potassium citrate combination",
        "code": "51191705"
      },
      {
        "name": "Citric acid and sodium citrate combination",
        "code": "51191706"
      },
      {
        "name": "Trometamol or tromethamine hydrochloride",
        "code": "51191707"
      },
      { "name": "Potassium salts", "code": "51191800" },
      { "name": "Potassium chloride", "code": "51191802" },
      { "name": "Potassium acetate", "code": "51191804" },
      { "name": "Nutritional therapy products", "code": "51191900" },
      { "name": "Phospholipids", "code": "51191904" },
      { "name": "Thiamine", "code": "51191909" },
      { "name": "Idebenone", "code": "51191912" },
      { "name": "Acitretin", "code": "51191913" },
      {
        "name": "Calcium glucoheptonate or calcium gluceptate",
        "code": "51191914"
      },
      { "name": "Calcium gluconate", "code": "51191915" },
      { "name": "Dihydrotachysterol", "code": "51191916" },
      { "name": "Ferrous sulfate", "code": "51191917" },
      { "name": "Folic acid", "code": "51191918" },
      { "name": "Iron sucrose", "code": "51191919" },
      { "name": "Niacinamide or nicotinamide", "code": "51191920" },
      { "name": "Nicorandil", "code": "51191921" },
      { "name": "Tocofersolan", "code": "51191922" },
      { "name": "Tretinoin", "code": "51191923" },
      { "name": "Ademetionine", "code": "51191924" },
      { "name": "Fluoride", "code": "51191925" },
      { "name": "Hydroxydecyl ubiquinone or idebenone", "code": "51191926" },
      { "name": "Acetylcarnitine", "code": "51191928" },
      { "name": "Niacinamide hydrochloride", "code": "51191930" },
      { "name": "Thiamine bromide", "code": "51191932" },
      { "name": "Thiamine chloride hydrochloride", "code": "51191934" },
      { "name": "Thiamine disulfide", "code": "51191936" },
      { "name": "Thiamine disulfide nitrate", "code": "51191938" },
      { "name": "Thiamine disulfide phosphate", "code": "51191940" },
      { "name": "Thiamine hydrochloride", "code": "51191942" },
      { "name": "Thiamine monochloride", "code": "51191944" },
      { "name": "Thiamine mononitrate", "code": "51191946" },
      { "name": "Thiamine monophosphate chloride", "code": "51191948" },
      { "name": "Thiamine monophosphate disulfide", "code": "51191950" },
      { "name": "Thiamine nitrate", "code": "51191952" },
      { "name": "Thiamine propyl disulfide", "code": "51191954" },
      { "name": "Thiamine pyrophosphate chloride", "code": "51191956" },
      {
        "name": "Thiamine tetrahydrofurfuryl disulfide hydrochloride",
        "code": "51191958"
      },
      { "name": "Thiamine triphosphate", "code": "51191960" },
      { "name": "Thiamine triphosphorate", "code": "51191962" },
      { "name": "Tretinoin tocoferil", "code": "51191964" },
      { "name": "Antigout drugs", "code": "51192000" },
      { "name": "Allopurinol", "code": "51192001" },
      { "name": "Colchicine", "code": "51192002" },
      { "name": "Probenecid", "code": "51192003" },
      { "name": "Sulfinpyrazone", "code": "51192004" },
      { "name": "Benzbromarone", "code": "51192005" },
      { "name": "Neocinchophen", "code": "51192006" },
      { "name": "Amflutizole", "code": "51192007" },
      { "name": "Febuxostat", "code": "51192008" },
      { "name": "Halofenate", "code": "51192009" },
      { "name": "Irtemazole", "code": "51192010" },
      { "name": "Isobromindione", "code": "51192011" },
      { "name": "Nicoxamat", "code": "51192012" },
      { "name": "Oxipurinol or oxypurinol", "code": "51192013" },
      { "name": "Rasburicase", "code": "51192014" },
      { "name": "Seclazone", "code": "51192015" },
      { "name": "Thiopurinol or tisopurine", "code": "51192016" },
      { "name": "Pegloticase", "code": "51192017" },
      { "name": "Allopurinol riboside", "code": "51192018" },
      { "name": "Allopurinol sodium", "code": "51192019" },
      { "name": "Probenecid sodium", "code": "51192020" },
      { "name": "Combination antigout agents", "code": "51192100" },
      { "name": "Colchicine/probenecid", "code": "51192101" },
      { "name": "Combination diuretics", "code": "51192200" },
      { "name": "Amiloride/hydrochlorothiazide", "code": "51192201" },
      { "name": "Bendroflumethiazide/potassium chloride", "code": "51192202" },
      { "name": "Hydrochlorothiazide/spironolactone", "code": "51192203" },
      { "name": "Hydrochlorothiazide/triamterene", "code": "51192204" },
      { "name": "Combination electrolytes/minerals", "code": "51192300" },
      {
        "name": "Chromic chloride/cupric sulfate/manganese sulfate/zinc sulfate",
        "code": "51192301"
      },
      {
        "name": "Acetate/calcium/chloride/gluconate/magnesium/potassium/sodium",
        "code": "51192302"
      },
      {
        "name": "Acetate/calcium/chloride/gluconate/magnesium/potassium/sodium/sugar",
        "code": "51192303"
      },
      {
        "name": "Ammonium chloride/potassium citrate/potassium gluconate",
        "code": "51192304"
      },
      { "name": "Calcium carbonate/sodium fluoride", "code": "51192305" },
      {
        "name": "Calcium/chloride/gluconate/magnesium/potassium/sodium/sulfate",
        "code": "51192306"
      },
      {
        "name": "Chromic chloride/cupric chloride/manganese chloride/selenium/zinc chloride",
        "code": "51192307"
      },
      {
        "name": "Chromic chloride/cupric chloride/manganese chloride/zinc chloride",
        "code": "51192308"
      },
      {
        "name": "Chromic chloride/cupric chloride/manganese sulfate/selenium/zinc",
        "code": "51192309"
      },
      {
        "name": "Chromic chloride/cupric sulfate/manganese sulfate/selenium/zinc",
        "code": "51192310"
      },
      {
        "name": "Chromic sulfate/cupric sulfate/manganese sulfate/selenium/zinc",
        "code": "51192311"
      },
      {
        "name": "Chromic sulfate/cupric sulfate/manganese sulfate/zinc sulfate",
        "code": "51192312"
      },
      { "name": "Potassium chloride/sodium chloride", "code": "51192313" },
      {
        "name": "Potassium chloride/sodium chloride/tromethamine",
        "code": "51192314"
      },
      {
        "name": "Combination nutritional therapy products",
        "code": "51192400"
      },
      {
        "name": "Ascorbic acid/cyanocobalamin/ferrous gluconate/intrinsic factor",
        "code": "51192401"
      },
      { "name": "Alanine/glutamic acid/glycine/thiamine", "code": "51192402" },
      {
        "name": "Aluminum hydroxide/ferrous sulfate/magnesium hydroxide",
        "code": "51192403"
      },
      { "name": "Ascorbic acid/bioflavonoids", "code": "51192404" },
      { "name": "Ascorbic acid/bioflavonoids/calcium", "code": "51192405" },
      {
        "name": "Ascorbic acid/citrus bioflavonoids/hesperidin complex/rutin",
        "code": "51192406"
      },
      {
        "name": "Ascorbic acid/cyanocobalamin/ferrous fumarate",
        "code": "51192407"
      },
      {
        "name": "Ascorbic acid/cyanocobalamin/ferrous fumarate/folic acid/intrins",
        "code": "51192408"
      },
      {
        "name": "Ascorbic acid/cyanocobalamin/ferrous gluconate/folic acid/gold",
        "code": "51192409"
      },
      { "name": "Ascorbic acid/niacin", "code": "51192410" },
      {
        "name": "Ascorbic acid/cyanocobalamin/folic acid/iron",
        "code": "51192411"
      },
      { "name": "Ascorbic acid/docusate/ferrous fumarate", "code": "51192412" },
      { "name": "Ascorbic acid/ferrous fumarate", "code": "51192413" },
      {
        "name": "Ascorbic acid/ferrous fumarate/folic acid",
        "code": "51192414"
      },
      { "name": "Ascorbic acid/ferrous sulfate", "code": "51192415" },
      {
        "name": "Ascorbic acid/ferrous sulfate/folic acid",
        "code": "51192416"
      },
      { "name": "Ascorbic acid/folic acid/iron", "code": "51192417" },
      { "name": "Ascorbic acid/hesperidin", "code": "51192418" },
      { "name": "Ascorbic acid/iron", "code": "51192419" },
      { "name": "Calcium citrate/glutamic acid", "code": "51192420" },
      { "name": "Ascorbic acid/rose hips", "code": "51192421" },
      { "name": "Ascorbic acid/zinc", "code": "51192422" },
      { "name": "Beta carotene/vitamin a", "code": "51192423" },
      { "name": "Calciuim lactate/thiamine", "code": "51192424" },
      {
        "name": "Calcium carbonate/calcium gluconate/calciucm lactate/vitamin d",
        "code": "51192425"
      },
      {
        "name": "Calcium carbonate/ferrous fumarate/vitamin d",
        "code": "51192426"
      },
      {
        "name": "Calcium carbonate/magnesium carbonate/vitamin d",
        "code": "51192427"
      },
      { "name": "Calcium carbonate/minerals/vitamin d", "code": "51192428" },
      { "name": "Calcium carbonate/vitamin d", "code": "51192429" },
      { "name": "Cyanocobalamin/intrinsic factor", "code": "51192430" },
      { "name": "Calcium citrate/vitamin a/vitamin d", "code": "51192431" },
      {
        "name": "Calcium glycerophosphate/calcium levulinate",
        "code": "51192432"
      },
      { "name": "Cholecalciferol/fish oil", "code": "51192433" },
      {
        "name": "Citric acid/potassium citrate/sodium citrate",
        "code": "51192434"
      },
      { "name": "Cyanocobalamin/folic acid", "code": "51192435" },
      { "name": "Cyanocobalamin/folic acid/iron", "code": "51192436" },
      { "name": "Cyanocobalamin/folic acid/liver", "code": "51192437" },
      { "name": "Cyanocobalamin/folic acid/pyridoxine", "code": "51192438" },
      { "name": "Glycine/niacin", "code": "51192439" },
      { "name": "Cyanocobalamin/iron/liver", "code": "51192440" },
      { "name": "Cyanocobalamin/thiamine", "code": "51192441" },
      { "name": "Ferrous fumarate/folic acid", "code": "51192442" },
      { "name": "Ferrous sulfate/folic acid", "code": "51192443" },
      {
        "name": "Fluoride/iron/vitamin a/vitamin c/vitamin d",
        "code": "51192444"
      },
      { "name": "Folic acid/iron", "code": "51192445" },
      { "name": "Folic acid/iron/multivitamins", "code": "51192446" },
      { "name": "Niacin/rice bran", "code": "51192447" },
      { "name": "Glycine/niacin/pyridoxine", "code": "51192448" },
      { "name": "Iron/multivitamins/procaine", "code": "51192449" },
      { "name": "Iron/multivitamins/sorbitol", "code": "51192450" },
      { "name": "Magnesium chloride/sodium chloride", "code": "51192451" },
      { "name": "Magnesium oxide/pyridoxine", "code": "51192452" },
      { "name": "Minerals/multivitamins/testosterone", "code": "51192453" },
      { "name": "Multivitamins/testosterone", "code": "51192454" },
      {
        "name": "Niacin/pantothenic acid/pyridoxine/riboflavin/thiamine",
        "code": "51192455"
      },
      { "name": "Niacin/riboflavin/thiamine", "code": "51192456" },
      { "name": "Pyridoxine/thiamine", "code": "51192457" },
      { "name": "Combination prostaglandins", "code": "51192500" },
      { "name": "Carboprost/tromethamine", "code": "51192501" },
      { "name": "Combination protective agents", "code": "51192600" },
      { "name": "Amifostine/mannitol", "code": "51192601" },
      { "name": "Immunomodulating drugs", "code": "51200000" },
      { "name": "Immunosuppressants", "code": "51201500" },
      {
        "name": "Antilymphocyte or lymphocyte immune globulin",
        "code": "51201516"
      },
      { "name": "Guanidine", "code": "51201517" },
      { "name": "Anakinra", "code": "51201523" },
      { "name": "Efalizumab", "code": "51201539" },
      { "name": "Glucosamine hcl", "code": "51201544" },
      { "name": "Gusperimus", "code": "51201545" },
      { "name": "Isatoribine", "code": "51201547" },
      {
        "name": "Sodium aurothiosulfate or sodium aurotiosulfate",
        "code": "51201564"
      },
      { "name": "Atlizumab or tocilizumab", "code": "51201569" },
      { "name": "Vaccines and antigens and toxoids", "code": "51201600" },
      { "name": "Anthrax antigen", "code": "51201601" },
      { "name": "Brucella antigen", "code": "51201602" },
      { "name": "Diphteria vaccine", "code": "51201604" },
      { "name": "Encephalitis virus vaccine", "code": "51201605" },
      { "name": "Hemophilus influenzae vaccine", "code": "51201606" },
      { "name": "Hepatitis B virus vaccine", "code": "51201607" },
      { "name": "Influenza virus vaccine", "code": "51201608" },
      { "name": "Measles virus vaccine", "code": "51201609" },
      { "name": "Meningococcal vaccine", "code": "51201610" },
      { "name": "Mumps virus vaccine", "code": "51201612" },
      { "name": "Parotitis vaccine", "code": "51201613" },
      { "name": "Pertussis vaccine", "code": "51201614" },
      { "name": "Pneumococcal vaccine", "code": "51201615" },
      { "name": "Poliovirus vaccine", "code": "51201616" },
      { "name": "Rabies vaccine", "code": "51201617" },
      { "name": "Rota virus vaccine", "code": "51201618" },
      { "name": "Rubella virus vaccine", "code": "51201619" },
      { "name": "Smallpox vaccine", "code": "51201620" },
      { "name": "Tetanus vaccine", "code": "51201621" },
      { "name": "Typhoid vaccine", "code": "51201623" },
      { "name": "Varicella virus vaccine", "code": "51201624" },
      { "name": "Yellow fever vaccine", "code": "51201625" },
      { "name": "Hepatitis A virus vaccine", "code": "51201626" },
      { "name": "Haemophilus B vaccine", "code": "51201627" },
      {
        "name": "Measles and mumps and rubella virus vaccine",
        "code": "51201628"
      },
      { "name": "Diphtheria and tetanus toxoids absorbed", "code": "51201629" },
      {
        "name": "Diptheria and tetanus toxoids and acellular pertussis vaccine",
        "code": "51201631"
      },
      {
        "name": "Diptheria and tetanus and wholecell pertussis vaccine",
        "code": "51201632"
      },
      {
        "name": "Haemophilus influenzae b with diptheria and tetanus and acellular pertussis",
        "code": "51201633"
      },
      {
        "name": "Haemophilus influenzae B with diptheria and tetanus and wholecell pertussis conjugated vaccine",
        "code": "51201634"
      },
      { "name": "Lyme disease vaccine", "code": "51201636" },
      { "name": "Plague vaccine", "code": "51201638" },
      { "name": "Staphylococcus vaccine", "code": "51201639" },
      { "name": "Measles and rubella virus vaccine", "code": "51201646" },
      {
        "name": "Bacillus calmette\u2013guerin or BCG vaccine",
        "code": "51201647"
      },
      { "name": "Rubella and mumps virus vaccine", "code": "51201648" },
      { "name": "Human papilloma virus vaccine", "code": "51201649" },
      { "name": "Poultry vaccines", "code": "51201700" },
      { "name": "E Coli vaccines", "code": "51201702" },
      { "name": "Gumboro vaccines", "code": "51201703" },
      { "name": "Avian infectious bronchitis vaccines", "code": "51201704" },
      { "name": "Newcastle virus vaccine", "code": "51201705" },
      { "name": "Immunostimulating agents", "code": "51201800" },
      { "name": "Bacterial immunoglobulins", "code": "51201801" },
      { "name": "Filgrastim", "code": "51201802" },
      { "name": "Pegfilgrastim", "code": "51201803" },
      { "name": "Rho D immunoglobulins", "code": "51201805" },
      { "name": "Gamma IGG or immunoglobulin", "code": "51201806" },
      { "name": "Levamisole or tetramisole", "code": "51201808" },
      { "name": "Galsulfase", "code": "51201810" },
      { "name": "Thalidomide", "code": "51201811" },
      { "name": "Mifamurtide", "code": "51201812" },
      { "name": "Betazole", "code": "51201813" },
      { "name": "Bropirimine", "code": "51201814" },
      { "name": "Histamine", "code": "51201815" },
      { "name": "Impromidine", "code": "51201816" },
      { "name": "Pidotimod", "code": "51201817" },
      { "name": "Roquinimex", "code": "51201818" },
      { "name": "Sargramostim", "code": "51201819" },
      { "name": "Thymopentin", "code": "51201820" },
      { "name": "Tiprotimod", "code": "51201821" },
      { "name": "Ancestim", "code": "51201822" },
      { "name": "Molgramostim", "code": "51201823" },
      { "name": "Betazole dihydrochloride", "code": "51201824" },
      { "name": "Betazole hydrochloride", "code": "51201825" },
      { "name": "Impromidine hydrochloride", "code": "51201826" },
      { "name": "Levamisole hydrochloride", "code": "51201827" },
      { "name": "Levamisole phosphate", "code": "51201828" },
      { "name": "Tetramisole hydrochloride", "code": "51201829" },
      { "name": "Multiple sclerosis MS agents", "code": "51201900" },
      { "name": "Glatiramer acetate", "code": "51201901" },
      {
        "name": "Immunomodulating drugs based on tyrosine Inhibitors and kinase Inhibitors",
        "code": "51202000"
      },
      { "name": "Temsirolimus", "code": "51202001" },
      {
        "name": "Hyaluronan or hyaluronate or hyaluronic acid",
        "code": "51202002"
      },
      { "name": "Polystyrene sulfonate", "code": "51202003" },
      { "name": "Tolvaptan", "code": "51202004" },
      { "name": "Resiquimod", "code": "51202005" },
      { "name": "Combination toxoids and vaccines", "code": "51202100" },
      {
        "name": "Diphtheria toxoid/pertussis vaccine/tetanus toxoid",
        "code": "51202101"
      },
      { "name": "Immunosupressant amides", "code": "51202200" },
      { "name": "Laflunimus", "code": "51202201" },
      { "name": "Laquinimod", "code": "51202202" },
      { "name": "Methotrexate sodium", "code": "51202203" },
      { "name": "Sulfasalazine", "code": "51202204" },
      { "name": "Tabilautide", "code": "51202205" },
      { "name": "Dexrazoxane", "code": "51202206" },
      { "name": "Dexrazoxane hydrochloride", "code": "51202207" },
      { "name": "Immunosupressant amino acids", "code": "51202300" },
      { "name": "Afelimomab", "code": "51202301" },
      { "name": "Briakinumab", "code": "51202302" },
      { "name": "Penicillamine", "code": "51202303" },
      { "name": "Penicillamine disulfide", "code": "51202304" },
      { "name": "Penicillamine hydrochloride", "code": "51202305" },
      { "name": "Etanercept", "code": "51202306" },
      { "name": "Immunosupressant antibodies", "code": "51202400" },
      { "name": "Adalimumab", "code": "51202401" },
      { "name": "Basiliximab", "code": "51202402" },
      { "name": "Belimumab", "code": "51202403" },
      { "name": "Canakinumab", "code": "51202404" },
      { "name": "Certolizumab", "code": "51202405" },
      { "name": "Certolizumab pegol", "code": "51202406" },
      { "name": "Dacliximab or daclizumab", "code": "51202407" },
      { "name": "Eculizumab", "code": "51202408" },
      { "name": "Infliximab", "code": "51202409" },
      { "name": "Muromonab cd3", "code": "51202410" },
      { "name": "Natalizumab", "code": "51202411" },
      { "name": "Omalizumab", "code": "51202412" },
      { "name": "Secukinumab", "code": "51202413" },
      { "name": "Ustekinumab", "code": "51202414" },
      { "name": "Immunosupressant azoles", "code": "51202500" },
      { "name": "Leflunomide", "code": "51202501" },
      { "name": "Mizoribine", "code": "51202502" },
      { "name": "Procodazole", "code": "51202503" },
      { "name": "Procodazole ethyl ester", "code": "51202504" },
      { "name": "Procodazole sodium", "code": "51202505" },
      { "name": "Immunosupressant imides", "code": "51202600" },
      { "name": "Ascomycin", "code": "51202601" },
      { "name": "Anisperimus", "code": "51202602" },
      { "name": "Everolimus", "code": "51202603" },
      { "name": "Immunosupressant immunoglobulins", "code": "51202700" },
      { "name": "Anti-thymocyte globulin", "code": "51202701" },
      { "name": "Immunosupressant macrolides", "code": "51202800" },
      { "name": "Pimecrolimus", "code": "51202801" },
      { "name": "Ridaforolimus", "code": "51202802" },
      { "name": "Sirolimus", "code": "51202803" },
      { "name": "Tacrolimus", "code": "51202804" },
      { "name": "Tacrolimus anhydrous", "code": "51202805" },
      { "name": "Umirolimus", "code": "51202806" },
      { "name": "Zotarolimus", "code": "51202807" },
      {
        "name": "Immunosupressant nucleic acids, nucleotides, and nucleosides",
        "code": "51202900"
      },
      { "name": "Abetimus", "code": "51202901" },
      { "name": "Defibrotide", "code": "51202902" },
      { "name": "Immunosupressant ortho-aminobenzoates", "code": "51203000" },
      { "name": "Lobenzarit", "code": "51203001" },
      { "name": "Lobenzarit disodium", "code": "51203002" },
      { "name": "Lobenzarit sodium", "code": "51203003" },
      { "name": "Immunosupressant peptides", "code": "51203100" },
      { "name": "Ciclosporin or cyclosporine", "code": "51203101" },
      { "name": "Ecallantide", "code": "51203102" },
      { "name": "Thymocartin", "code": "51203103" },
      { "name": "Voclosporin", "code": "51203104" },
      { "name": "Immunosupressant phenols", "code": "51203200" },
      { "name": "Mycophenolate mofetil", "code": "51203201" },
      { "name": "Mycophenolate mofetil hydrochloride", "code": "51203202" },
      { "name": "Mycophenolate or mycophenolic acid", "code": "51203203" },
      { "name": "Tazofelone", "code": "51203204" },
      { "name": "Immunosupressant phthalimides", "code": "51203300" },
      { "name": "Lenalidomide", "code": "51203301" },
      { "name": "Fingolimod", "code": "51203302" },
      { "name": "Pomalidomide", "code": "51203303" },
      { "name": "Immunosupressant purines", "code": "51203400" },
      { "name": "Azathioprine", "code": "51203401" },
      { "name": "Cladribine", "code": "51203402" },
      { "name": "Mercaptopurine", "code": "51203403" },
      { "name": "Mercaptopurine anhydrous", "code": "51203404" },
      { "name": "Mercaptopurine ribonucleoside", "code": "51203405" },
      { "name": "Immunosupressant aziridines", "code": "51203500" },
      { "name": "Ciamexon", "code": "51203501" },
      { "name": "Immunosupressant carboxylic acids", "code": "51203600" },
      { "name": "Bucillamine", "code": "51203601" },
      { "name": "Loxanast", "code": "51203602" },
      { "name": "Napirimus", "code": "51203603" },
      { "name": "Oxycinchophen", "code": "51203604" },
      { "name": "Teriflunomide", "code": "51203605" },
      { "name": "Tresperimus", "code": "51203606" },
      { "name": "Immunosupressant heavy metals", "code": "51203700" },
      { "name": "Gallium nitrate", "code": "51203701" },
      { "name": "Immunosupressant immunoconjugates", "code": "51203800" },
      { "name": "Abatacept", "code": "51203801" },
      { "name": "Belatacept", "code": "51203802" },
      { "name": "Immunosupressant organogold compounds", "code": "51203900" },
      { "name": "Auranofin", "code": "51203901" },
      { "name": "Aurotioprol", "code": "51203902" },
      { "name": "Sodium aurothiomalate", "code": "51203903" },
      { "name": "Immunosupressant pyridines", "code": "51204000" },
      { "name": "Pirfenidone", "code": "51204001" },
      {
        "name": "Immunosupressant recombinant fusion proteins",
        "code": "51204100"
      },
      { "name": "Alefacept", "code": "51204101" },
      { "name": "Rilonacept", "code": "51204102" },
      {
        "name": "Veterinary vaccines and virology products",
        "code": "51204200"
      },
      {
        "name": "Veterinary foot and mouth disease (FMD) vaccines",
        "code": "51204201"
      },
      { "name": "Veterinary anthrax vaccines", "code": "51204202" },
      { "name": "Veterinary brucella vaccines", "code": "51204203" },
      {
        "name": "Contagious bovine pleuropneumonia CBPP vaccines",
        "code": "51204204"
      },
      {
        "name": "Ovine rinderpest or peste des petit ruminants vaccines",
        "code": "51204205"
      },
      {
        "name": "Drugs affecting the ears, eye, nose and skin",
        "code": "51240000"
      },
      { "name": "Aural preparations", "code": "51241000" },
      { "name": "Dioctyl sodium sulphosuccinate", "code": "51241001" },
      { "name": "Triethanolamine polypeptide oleate", "code": "51241002" },
      { "name": "Ophthalmic agents", "code": "51241100" },
      { "name": "Bimatoprost", "code": "51241103" },
      { "name": "Brinzolamide", "code": "51241104" },
      { "name": "Carbachol", "code": "51241105" },
      { "name": "Demecarium", "code": "51241106" },
      { "name": "Dorzolamide", "code": "51241107" },
      { "name": "Echothiophate or ecothiopate", "code": "51241108" },
      { "name": "Latanoprost", "code": "51241110" },
      { "name": "Physostigmine", "code": "51241113" },
      { "name": "Unoprostone", "code": "51241117" },
      { "name": "Dichlorphenamide or diclofenamide", "code": "51241118" },
      { "name": "Artificial tears", "code": "51241120" },
      { "name": "Pegaptanib", "code": "51241122" },
      { "name": "Amlexanox", "code": "51241123" },
      { "name": "Pirnabin or pirnabine", "code": "51241124" },
      { "name": "Tafluprost", "code": "51241125" },
      { "name": "Brimonidine", "code": "51241126" },
      { "name": "Verteporfin", "code": "51241127" },
      { "name": "Hypromellose", "code": "51241128" },
      { "name": "Carboxymethylcellulose sodium", "code": "51241129" },
      { "name": "Brimonidine tartrate", "code": "51241131" },
      { "name": "Demecarium bromide", "code": "51241132" },
      { "name": "Dorzolamide hydrochloride", "code": "51241133" },
      { "name": "Pegaptanib sodium", "code": "51241134" },
      { "name": "Unoprostone isopropyl", "code": "51241135" },
      { "name": "Unoprostone isopropyl ester", "code": "51241136" },
      { "name": "Dermatologic agents", "code": "51241200" },
      { "name": "Calamine", "code": "51241205" },
      { "name": "Chloroxine", "code": "51241206" },
      { "name": "Topical coal tar preparations", "code": "51241207" },
      { "name": "Hydroquinone", "code": "51241209" },
      {
        "name": "Pine tar or topical pine tar preparation",
        "code": "51241213"
      },
      { "name": "Podophyllum resin", "code": "51241215" },
      { "name": "Pyrithione zinc", "code": "51241216" },
      { "name": "Selenium disulfide", "code": "51241218" },
      { "name": "Tazarotene", "code": "51241219" },
      { "name": "Crystallized trypsin", "code": "51241221" },
      { "name": "Dimethicone", "code": "51241222" },
      { "name": "Calcipotriene or calcipotriol", "code": "51241223" },
      { "name": "Anthralin or dithranol", "code": "51241224" },
      { "name": "Camphor or topical camphor preparations", "code": "51241225" },
      { "name": "Topical urea preparations", "code": "51241226" },
      { "name": "Topical turpentine oil preparations", "code": "51241227" },
      { "name": "Lactic acid", "code": "51241228" },
      { "name": "Saliva substitute solution", "code": "51241229" },
      { "name": "Isotretinoin", "code": "51241232" },
      { "name": "Calcitriol", "code": "51241235" },
      {
        "name": "Ammonium bituminosulfonate or ichthyol or ichthammol",
        "code": "51241236"
      },
      { "name": "Benzoyl peroxide", "code": "51241237" },
      { "name": "Bithionol", "code": "51241239" },
      { "name": "Calcipotriene hydrate", "code": "51241240" },
      { "name": "Mequinol", "code": "51241241" },
      { "name": "Crotamiton", "code": "51241242" },
      { "name": "Dimethyl fumarate", "code": "51241243" },
      { "name": "Etretinate", "code": "51241244" },
      { "name": "Lithium succinate", "code": "51241245" },
      { "name": "Tacalcitol", "code": "51241246" },
      { "name": "Mesulfen or mesulphen", "code": "51241247" },
      { "name": "Methoxsalen or xanthotoxin", "code": "51241248" },
      { "name": "Monobenzone", "code": "51241249" },
      { "name": "Motretinide", "code": "51241250" },
      { "name": "Panthenol", "code": "51241251" },
      { "name": "Pidobenzone", "code": "51241252" },
      { "name": "Tioxolone", "code": "51241253" },
      {
        "name": "Trimethylpsoralen or trioxsalen or trioxysalen or trisoralen",
        "code": "51241254"
      },
      {
        "name": "Undecenoic acid or undecylenic acid or zinc undecylenate",
        "code": "51241255"
      },
      { "name": "Becaplermin", "code": "51241256" },
      { "name": "Dextranomer", "code": "51241257" },
      {
        "name": "4-aminobenzoic acid or Aminobenzoic acid or PABA or para-aminobenzoic acid",
        "code": "51241258"
      },
      { "name": "Isotretinoin anisatil", "code": "51241259" },
      { "name": "Tacalcitol monohydrate", "code": "51241260" },
      { "name": "Astringents", "code": "51241300" },
      { "name": "Aluminum acetate", "code": "51241301" },
      { "name": "Hamamelis or witch hazel", "code": "51241302" },
      { "name": "Ammonium alum", "code": "51241304" },
      { "name": "Tannic acid", "code": "51241305" },
      { "name": "Alcloxa", "code": "51241306" },
      { "name": "Allantoin", "code": "51241307" },
      { "name": "Allantoin acetyl methionine", "code": "51241308" },
      { "name": "Allantoin ascorbate", "code": "51241309" },
      { "name": "Allantoin biotin", "code": "51241310" },
      { "name": "Allantoin paba", "code": "51241311" },
      { "name": "Combination antiacne agents", "code": "51241400" },
      { "name": "Benzalkonium chloride/polyoxyethylene", "code": "51241401" },
      {
        "name": "Acetone/alcohol/benzalkonium chloride/salicylic acid",
        "code": "51241402"
      },
      {
        "name": "Acetone/isopropyl alcohol/polysorbate 80",
        "code": "51241403"
      },
      { "name": "Adapalene/benzoyl peroxide", "code": "51241404" },
      { "name": "Alcohol/citric acid/salicylic acid", "code": "51241405" },
      { "name": "Alcohol/glycolic acid", "code": "51241406" },
      { "name": "Alcohol/resorcinol", "code": "51241407" },
      { "name": "Alcohol/salicylic acid", "code": "51241408" },
      { "name": "Alcohol/sulfur/zinc oxide/zinc sulfate", "code": "51241409" },
      { "name": "Attapulgite/salicylic acid/sulfur", "code": "51241410" },
      { "name": "Resorcinol/sulfur", "code": "51241411" },
      { "name": "Benzoyl peroxide/clindamycin", "code": "51241412" },
      { "name": "Benzoyl peroxide/erythromycin", "code": "51241413" },
      { "name": "Benzoyl peroxide/hydrocortisone", "code": "51241414" },
      { "name": "Benzoyl peroxide/sulfur", "code": "51241415" },
      { "name": "Calamine/resorcinol/sulfur", "code": "51241416" },
      { "name": "Chloroxylenol/resorcinol/sulfur", "code": "51241417" },
      { "name": "Clindamycin phosphate/tretinoin", "code": "51241418" },
      { "name": "Copper/folic/niacinamide/zinc", "code": "51241419" },
      {
        "name": "Combination antiglaucoma, ophthalmic, and eye-related agents and preparations",
        "code": "51241500"
      },
      { "name": "Boric acid/sodium borate", "code": "51241501" },
      { "name": "Antazoline/naphazoline", "code": "51241502" },
      { "name": "Antipyrine/phenylephrine", "code": "51241503" },
      { "name": "Atropine/prednisolone", "code": "51241504" },
      {
        "name": "Balanced salt solution/bicarbonate/dextrose/glutathione",
        "code": "51241505"
      },
      { "name": "Benoxinate/fluorescein", "code": "51241506" },
      { "name": "Benzalkonium chloride/tyloxapol", "code": "51241507" },
      {
        "name": "Benzalkonium/calcium chloride/hydrochloric acid/magnesium chloride",
        "code": "51241508"
      },
      {
        "name": "Boric acid/glycerin/sodium borate/tetrahydrozoline",
        "code": "51241509"
      },
      {
        "name": "Boric acid/potassium chloride/sodium carbonate",
        "code": "51241510"
      },
      { "name": "Dextran 70/hypromellose", "code": "51241511" },
      {
        "name": "Boric acid/sodium borate/sodium chloride",
        "code": "51241512"
      },
      { "name": "Brimonidine/timolol", "code": "51241513" },
      { "name": "Carboxymethylcellulose/glycerin", "code": "51241514" },
      { "name": "Carboxymethylcellulose/hypromellose", "code": "51241515" },
      { "name": "Chlorobutanol/edta/polyvinyl alcohol", "code": "51241516" },
      { "name": "Cyclopentolate/phenylephrine", "code": "51241517" },
      {
        "name": "Dextran 70/edta/methylcellulose/sodium chloride",
        "code": "51241518"
      },
      {
        "name": "Dextran 70/hydroxypropyl methylcellulose",
        "code": "51241519"
      },
      { "name": "Glycerin/propylene glycol", "code": "51241520" },
      { "name": "Dorzolamide/timolol", "code": "51241522" },
      {
        "name": "Edetate disodium/sodium chloride/sodium phosphate",
        "code": "51241523"
      },
      { "name": "Edta/povidone", "code": "51241524" },
      { "name": "Epinephrine/pilocarpine", "code": "51241525" },
      { "name": "Fluorescein/proparacaine", "code": "51241526" },
      { "name": "Glucose/lanolin/parabens/petrolatum", "code": "51241527" },
      {
        "name": "Glycerin/hypromellose/polyethylene glycol",
        "code": "51241528"
      },
      { "name": "Glycerin/naphazoline", "code": "51241529" },
      { "name": "Naphazoline/pheniramine", "code": "51241530" },
      { "name": "Hydroxyamphetamine/tropicamide", "code": "51241531" },
      { "name": "Hydroxyethyl cellulose/povidone", "code": "51241532" },
      { "name": "Hydroxyethyl cellulose/thimerosal", "code": "51241533" },
      { "name": "Hypromellose/naphazoline", "code": "51241534" },
      { "name": "Lanolin/mineral oil/petrolatum", "code": "51241535" },
      { "name": "Mineral oil/petrolatum", "code": "51241536" },
      { "name": "Phenylephrine/sulfacetamide", "code": "51241537" },
      { "name": "Naphazoline/zinc sulfate", "code": "51241538" },
      { "name": "Pheniramine/phenylephrine", "code": "51241539" },
      {
        "name": "Phenylephrine/prednisolone/sulfacetamide",
        "code": "51241540"
      },
      { "name": "Phenylephrine/scopolamine", "code": "51241542" },
      {
        "name": "Sodium biphosphate/sodium chloride/sodium phosphate",
        "code": "51241543"
      },
      { "name": "Phenylephrine/zinc sulfate", "code": "51241544" },
      { "name": "Physostigmine/pilocarpine", "code": "51241545" },
      {
        "name": "Polyethylene glycol 400/propylene glycol",
        "code": "51241546"
      },
      { "name": "Polyethylene glycol/polyvinyl alcohol", "code": "51241547" },
      { "name": "Polysorbate/glycerin", "code": "51241548" },
      { "name": "Polyvinyl alcohol/povidone", "code": "51241549" },
      {
        "name": "Polyvinyl alcohol/povidone/sodium chloride",
        "code": "51241550"
      },
      { "name": "Polyvinyl alcohol/sodium chloride", "code": "51241551" },
      {
        "name": "Potassium chloride/sodium chloride/sodium hydroxide/sodium phosphate",
        "code": "51241552"
      },
      { "name": "Sodium chloride/sodium phosphate", "code": "51241553" },
      { "name": "Tetrahydrozoline/zinc sulfate", "code": "51241554" },
      { "name": "Combination antipsoriatics", "code": "51241600" },
      { "name": "Coal tar/menthol/salicylic acid", "code": "51241601" },
      {
        "name": "Alcohol/benzocaine/coal tar/salicylic acid",
        "code": "51241602"
      },
      { "name": "Allantoin/coal tar", "code": "51241603" },
      {
        "name": "Ammoniated mercury/coal tar/methenamine sulfosalicylate",
        "code": "51241604"
      },
      { "name": "Betamethasone/calcipotriene", "code": "51241605" },
      { "name": "Clioquinol/coal tar/hydrocortisone", "code": "51241606" },
      { "name": "Coal tar/lactic acid/salicylic acid", "code": "51241607" },
      { "name": "Coal tar/lanolin/mineral oil", "code": "51241608" },
      {
        "name": "Coal tar/lauramide dea/sodium lauryl sulfate",
        "code": "51241609"
      },
      { "name": "Coal tar/menthol", "code": "51241610" },
      { "name": "Coal tar/polysorbate 80", "code": "51241611" },
      { "name": "Coal tar/salicylic acid", "code": "51241612" },
      { "name": "Coal tar/salicylic acid/sulfur", "code": "51241613" },
      { "name": "Coal tar/salicylic acid/zinc oxide", "code": "51241614" },
      { "name": "Coal tar/zinc oxide", "code": "51241615" },
      { "name": "Combination contact lens solutions", "code": "51241700" },
      {
        "name": "Edta/sodium chloride/sodium lauryl sulfate/sodium phosphate",
        "code": "51241701"
      },
      {
        "name": "Benzalkonium chloride/edetate/polyvinyl alcohol",
        "code": "51241702"
      },
      {
        "name": "Benzalkonium chloride/edetate/sodium hydroxide",
        "code": "51241703"
      },
      { "name": "Benzalkonium chloride/edta", "code": "51241704" },
      {
        "name": "Benzalkonium chloride/hydroxypropyl methylcellulose/polyvinyl alcohol",
        "code": "51241705"
      },
      { "name": "Boric acid/edta/hydroxyethyl cellulose", "code": "51241706" },
      { "name": "Boric acid/sodium chloride", "code": "51241707" },
      { "name": "Edta/polyquarternium-1/sodium chloride", "code": "51241708" },
      { "name": "Edta/thimerosal", "code": "51241709" },
      {
        "name": "Hydroxyethyl cellulose/polyvinyl alcohol/tyloxapol",
        "code": "51241710"
      },
      { "name": "Polyvinyl alcohol/thimerosal", "code": "51241711" },
      { "name": "Sodium chloride/sorbic acid", "code": "51241712" },
      { "name": "Combination dermatologicals", "code": "51241800" },
      { "name": "Aluminum sulfate/calcium acetate", "code": "51241801" },
      {
        "name": "Allantoin/benzalkonium chloride/benzyl alcohol/diperodon/eucalyptol",
        "code": "51241802"
      },
      { "name": "Allantoin/camphor/phenol", "code": "51241803" },
      {
        "name": "Alum/camphor/eucalyptus/menthol/salicylic/talc/thymol",
        "code": "51241804"
      },
      {
        "name": "Aluminum acetate/boric acid/eucalyptol/ichthammol/phenol/zinc oxide",
        "code": "51241805"
      },
      { "name": "Aluminum acetate/camphor/menthol/phenol", "code": "51241806" },
      { "name": "Aluminum acetate/zinc oxide", "code": "51241807" },
      {
        "name": "Aluminum chlorohydrate/chloroxylenol/menthol/zinc undecylenate",
        "code": "51241808"
      },
      {
        "name": "Aluminum chlorohydrex/formaldehyde/menthol/zinc undecylenate",
        "code": "51241809"
      },
      {
        "name": "Aluminum potassium sulfate/chlorobutanol/tannic acid",
        "code": "51241810"
      },
      {
        "name": "Benzalkonium/camphor/diperodon/ichthammol/juniper/phenol/thymol",
        "code": "51241811"
      },
      {
        "name": "Aluminum/bentone/isobutane/isopropyl/menthol/undecylenic acid",
        "code": "51241812"
      },
      { "name": "Bacitracin/lidocaine/neomycin/polymyxin", "code": "51241813" },
      { "name": "Bacitracin/lidocaine/polymyxin b", "code": "51241814" },
      { "name": "Balsam peru/castor oil/trypsin", "code": "51241815" },
      { "name": "Balsam peru/trypsin", "code": "51241816" },
      {
        "name": "Bentonite magma/calamine/calcium hydroxide/glycerin/zinc oxide",
        "code": "51241817"
      },
      { "name": "Bentonite/potassium alum/talc", "code": "51241818" },
      { "name": "Benzoin/camphor/eucalyptus oil/thymol", "code": "51241819" },
      {
        "name": "Benzethonium/calamine/eucalyptol/zinc oxide",
        "code": "51241820"
      },
      {
        "name": "Benzocaine/calamine/diphenhydramine/menthol",
        "code": "51241821"
      },
      {
        "name": "Benzocaine/camphor/ichthammol/phenol/sulfur/tar",
        "code": "51241822"
      },
      { "name": "Benzocaine/camphor/menthol", "code": "51241823" },
      { "name": "Benzocaine/chloroxylenol/zinc oxide", "code": "51241824" },
      {
        "name": "Benzocaine/ephedrine/hydrocortisone/ichthammol/oxyquinoline/zinc",
        "code": "51241825"
      },
      { "name": "Benzocaine/ichthammol", "code": "51241826" },
      { "name": "Benzocaine/methyl salicylate", "code": "51241827" },
      { "name": "Benzoin compound/isopropyl alcohol", "code": "51241828" },
      { "name": "Calamine/lanolin/menthol/zinc oxide", "code": "51241829" },
      { "name": "Benzoin/camphor/tolu balsam", "code": "51241830" },
      { "name": "Benzoxiquine/ichthammol", "code": "51241831" },
      {
        "name": "Benzyl alcohol/camphor/isopropyl alcohol/menthol",
        "code": "51241832"
      },
      {
        "name": "Boric acid/vitamin a/vitamin d/zinc oxide",
        "code": "51241833"
      },
      { "name": "Boric acid/zinc oxide", "code": "51241834" },
      { "name": "Calamine/camphor/diphenhydramine", "code": "51241835" },
      { "name": "Calamine/camphor/pramoxine", "code": "51241836" },
      { "name": "Calamine/diphenhydramine", "code": "51241837" },
      { "name": "Calamine/glycerin/phenol/zinc oxide", "code": "51241838" },
      { "name": "Camphor/phenol", "code": "51241839" },
      { "name": "Calamine/phenol/zinc oxide", "code": "51241840" },
      { "name": "Calamine/pramoxine", "code": "51241841" },
      {
        "name": "Calcium caseinate/cod liver oil/lanolin/lanolin alcohol/methylbe",
        "code": "51241842"
      },
      {
        "name": "Camphor/diperodon/menthol/phenyltoloxamine/pyrilamine",
        "code": "51241843"
      },
      { "name": "Camphor/eucalyptus oil/menthol/phenol", "code": "51241844" },
      { "name": "Camphor/glycerin/mineral oil/phenol", "code": "51241845" },
      {
        "name": "Camphor/isopropanol/menthol/propylene glycol",
        "code": "51241846"
      },
      { "name": "Cod liver oil/talc/zinc oxide", "code": "51241847" },
      { "name": "Castor oil/dimethicone/nitrocellulose", "code": "51241848" },
      { "name": "Castor oil/peruvian balsam/trypsin", "code": "51241849" },
      { "name": "Cellulose/talc", "code": "51241850" },
      { "name": "Chloral hydrate/salicylic acid", "code": "51241851" },
      {
        "name": "Chloramphenicol/desoxyribonuclease/fibrinolysin",
        "code": "51241852"
      },
      {
        "name": "Chlorhexidine gluconate/glycerin/hydroxyethyl cellulose",
        "code": "51241853"
      },
      {
        "name": "Chlorohexidine gluconate/glycerin/hydroxyethyl cellulose",
        "code": "51241854"
      },
      { "name": "Chlorophyll/papain/urea", "code": "51241855" },
      {
        "name": "Chlorophyllin copper complex/sodium chloride",
        "code": "51241856"
      },
      { "name": "Gelatin/glycerin/karaya gum/zinc oxide", "code": "51241857" },
      { "name": "Dimethicone/menthol", "code": "51241858" },
      { "name": "Dimethicone/zinc oxide", "code": "51241859" },
      {
        "name": "Diperodon/vitamin a/vitamin d/zinc oxide",
        "code": "51241860"
      },
      { "name": "Diphenhydramine/hydrocortisone", "code": "51241861" },
      { "name": "Diphenhydramine/phenol", "code": "51241862" },
      { "name": "Diphenhydramine/tripelennamine", "code": "51241863" },
      { "name": "Diphenhydramine/zinc acetate", "code": "51241864" },
      { "name": "Diphenhydramine/zinc oxide", "code": "51241865" },
      { "name": "Fluocinolone/hydroquinone/tretinoin", "code": "51241866" },
      { "name": "Lanolin/mineral oil", "code": "51241867" },
      { "name": "Glycerin/mineral oil/phenol", "code": "51241868" },
      {
        "name": "Glycerin/petrolatum/propylparaben/sodium lauryl sulfate",
        "code": "51241869"
      },
      { "name": "Glycerin/petrolatum/zinc oxide", "code": "51241870" },
      { "name": "Glycerin/silicone oil/triethanolamine", "code": "51241871" },
      { "name": "Glycerin/witch hazel", "code": "51241872" },
      { "name": "Hamamelis water/lanolin/petrolatum", "code": "51241873" },
      {
        "name": "Iodine/phenol/potassium iodide/trichloroacetic acid",
        "code": "51241874"
      },
      { "name": "Ketoconazole/zinc pyrithione", "code": "51241875" },
      {
        "name": "Mineral oil/mineral wax/petrolatum/wool wax alcohol",
        "code": "51241876"
      },
      { "name": "Lidocaine/neomycin/polymyxin", "code": "51241877" },
      { "name": "Menthol/phenol", "code": "51241878" },
      {
        "name": "Menthol/sodium bicarbonate/sodium borate/sodium chloride/thymol",
        "code": "51241879"
      },
      { "name": "Menthol/sulfur", "code": "51241880" },
      { "name": "Menthol/zinc", "code": "51241881" },
      { "name": "Menthol/zinc oxide", "code": "51241882" },
      { "name": "Papain/urea", "code": "51241883" },
      { "name": "Vitamin a/vitamin d/zinc oxide", "code": "51241885" },
      { "name": "Combination emollients", "code": "51241900" },
      {
        "name": "Cholesterol/petrolatum/stearyl alcohol/wax",
        "code": "51241901"
      },
      { "name": "Aloe/vitamin a/vitamin e", "code": "51241902" },
      {
        "name": "Beeswax/mineral oil/rose oil/rose soluble/sodium borate/spermace",
        "code": "51241903"
      },
      {
        "name": "Benzophenone-3/lanolin/mineral oil/polyethylene glycol-4",
        "code": "51241904"
      },
      {
        "name": "Bismuth subnitrate/castor oil/ceresin/lanolin/peru balsam",
        "code": "51241905"
      },
      { "name": "Castor oil/silicone", "code": "51241906" },
      {
        "name": "Cetearyl alcohol/glycerin/mineral oil/petrolatum/propylene glycol",
        "code": "51241907"
      },
      {
        "name": "Cetyl alcohol/propylene glycol/stearyl alcohol/wax",
        "code": "51241908"
      },
      {
        "name": "Cetyl esters wax/mineral oil/sodium borate/white wax",
        "code": "51241909"
      },
      { "name": "Cholecalciferol/fish liver oil", "code": "51241910" },
      { "name": "Collagen/elastin/glycerin", "code": "51241911" },
      { "name": "Glycerin/lanolin/mineral oil/petrolatum", "code": "51241912" },
      { "name": "Glycerin/mineral oil/petrolatum", "code": "51241913" },
      { "name": "Glycerin/mineral oil/urea", "code": "51241914" },
      { "name": "Glycerin/rose water", "code": "51241915" },
      { "name": "Hydroxyquinoline/lanolin/petrolatum", "code": "51241916" },
      { "name": "Lactic acid/vitamin e", "code": "51241917" },
      { "name": "Lanolin/mineral oil/octoxynol 3", "code": "51241918" },
      { "name": "Lanolin/mineral oil/oxybenzone", "code": "51241919" },
      { "name": "Lanolin/mineral oil/parabens", "code": "51241920" },
      {
        "name": "Lanolin/mineral oil/petrolatum/triethanolamine",
        "code": "51241921"
      },
      { "name": "Menthol/mineral oil/vitamin a", "code": "51241922" },
      { "name": "Methyl paraben/urea", "code": "51241923" },
      { "name": "Vitamin a/vitamin d", "code": "51241924" },
      {
        "name": "Mineral oil/nonoxynol/oleth-2/ppg-15 stearyl ether",
        "code": "51241925"
      },
      { "name": "Mineral oil/oatmeal, colloidal", "code": "51241926" },
      { "name": "Oil/water", "code": "51241927" },
      { "name": "Panthenol/vitamin a/vitamin d/vitamin e", "code": "51241928" },
      { "name": "Vitamin a/vitamin d/vitamin e", "code": "51241929" },
      { "name": "Combination keratolytics/caustics", "code": "51242000" },
      { "name": "Phenol/resorcinol", "code": "51242001" },
      { "name": "Acetic acid/salicylic acid", "code": "51242002" },
      { "name": "Alcohol/sulfur", "code": "51242003" },
      { "name": "Benzoin/podophyllin", "code": "51242004" },
      { "name": "Cantharidin/podophyllin/salicylic acid", "code": "51242005" },
      {
        "name": "Castor oil/collodion/podophyllin/salicylic acid",
        "code": "51242006"
      },
      {
        "name": "Collodion, flexible/lactic acid/salicylic acid",
        "code": "51242007"
      },
      { "name": "Lactic acid/phenol/salicylic acid", "code": "51242008" },
      { "name": "Lactic acid/salicylic acid", "code": "51242009" },
      { "name": "Lactic acid/urea", "code": "51242010" },
      { "name": "Salicylic acid/sodium thiosulfate", "code": "51242011" },
      { "name": "Salicylic acid/sulfur", "code": "51242012" },
      { "name": "Sulfacetamide/sulfur", "code": "51242013" },
      {
        "name": "Combination nasal and throat preparations",
        "code": "51242100"
      },
      {
        "name": "Anethole/capsicum/glycyrrhiza/peppermint oil",
        "code": "51242101"
      },
      { "name": "Glycerin/sodium chloride", "code": "51242102" },
      { "name": "Naphazoline/phenylephrine/pyrilamine", "code": "51242103" },
      { "name": "Phenylephrine/pyrilamine", "code": "51242104" },
      { "name": "Combination otic agents", "code": "51242200" },
      {
        "name": "Acetic acid/antipyrine/benzocaine/glycerin",
        "code": "51242201"
      },
      {
        "name": "Acetic/benzalkonium/chloroxylenol/hydrocortisone/pramoxine",
        "code": "51242202"
      },
      { "name": "Antipyrine/benzocaine/phenylephrine", "code": "51242203" },
      {
        "name": "Antipyrine/hydrocortisone/neomycin/polymyxin b",
        "code": "51242204"
      },
      {
        "name": "Chloroxylenol/domiphen/hydrocortisone/pramoxine",
        "code": "51242205"
      },
      { "name": "Chloroxylenol/hydrocortisone/pramoxine", "code": "51242206" },
      { "name": "Chloroxylenol/pramoxine", "code": "51242207" },
      { "name": "Glycerin/isopropyl alcohol", "code": "51242208" },
      { "name": "Combination sun protectants or screens", "code": "51242300" },
      { "name": "Oxybenzone/padimate o", "code": "51242301" },
      { "name": "Avobenzone/padimate-o", "code": "51242302" },
      { "name": "Cinoxate/methyl anthranilate", "code": "51242303" },
      { "name": "Cinoxate/zinc oxide", "code": "51242304" },
      {
        "name": "Dioxybenzone/ethyl dihydroxypropyl paba/hydroquinone",
        "code": "51242305"
      },
      { "name": "Hydroquinone/padimate o", "code": "51242306" },
      { "name": "Methyl anthranilate/titanium dioxide", "code": "51242307" },
      {
        "name": "Octyl methoxycinnamate/octyl salicylate/oxybenzone",
        "code": "51242308"
      },
      {
        "name": "Octyl methoxycinnamate/octyl salicylate/oxybenzone/titanium diox",
        "code": "51242309"
      },
      {
        "name": "Octyl methoxycinnamate/oxybenzone/titanium dioxide",
        "code": "51242310"
      },
      {
        "name": "Oxybenzone/padimate o/para-aminobenzoic acid/sd alcohol 40",
        "code": "51242311"
      },
      { "name": "Oxybenzone/padimate o/sd alcohol 40", "code": "51242312" },
      { "name": "Oxybenzone/para-aminobenzoic acid", "code": "51242313" },
      { "name": "Oxybenzone/petrolatum", "code": "51242314" },
      { "name": "Padimate o/petrolatum", "code": "51242315" },
      { "name": "Titanium dioxide/zinc oxide", "code": "51242316" },
      { "name": "Adrenergic blocking agents", "code": "51260000" },
      { "name": "Adrenergic blocking agent amides", "code": "51261500" },
      { "name": "Ancarolol", "code": "51261501" },
      { "name": "Cetamolol", "code": "51261502" },
      { "name": "Indoramin", "code": "51261503" },
      { "name": "Cetamolol hydrochloride", "code": "51261504" },
      { "name": "Indoramin hydrochloride", "code": "51261505" },
      { "name": "Adrenergic blocking agent amines", "code": "51261600" },
      { "name": "Phenoxybenzamine", "code": "51261601" },
      { "name": "Butaxamine or butoxamine", "code": "51261602" },
      { "name": "Phenoxybenzamine hydrochloride", "code": "51261603" },
      {
        "name": "Adrenergic blocking agent amino alcohols and aminoquinolines and benzopyrans and bradykinin/analogs and derivatives",
        "code": "51261700"
      },
      { "name": "Arnolol", "code": "51261701" },
      { "name": "Abanoquil", "code": "51261702" },
      { "name": "Nebivolol", "code": "51261703" },
      { "name": "Icatibant", "code": "51261704" },
      { "name": "Icatibant acetate", "code": "51261705" },
      { "name": "Nebivolol hydrochloride", "code": "51261706" },
      { "name": "Adrenergic blocking agent benzofurans", "code": "51261800" },
      { "name": "Afurolol", "code": "51261801" },
      { "name": "Efaroxan", "code": "51261802" },
      {
        "name": "Adrenergic blocking agent butyrophenones and carbazoles and dibenzazepines and dimethylamines",
        "code": "51262000"
      },
      { "name": "Aceperone", "code": "51262001" },
      { "name": "Carvedilol", "code": "51262002" },
      { "name": "Azapetine", "code": "51262003" },
      { "name": "Moxisylyte", "code": "51262004" },
      { "name": "Carvedilol hydrochloride", "code": "51262005" },
      { "name": "Carvedilol phosphate", "code": "51262006" },
      { "name": "Moxisylyte hydrochloride", "code": "51262007" },
      { "name": "Adrenergic blocking agent indoles", "code": "51262100" },
      { "name": "Atiprosin", "code": "51262101" },
      { "name": "Silodosin", "code": "51262102" },
      { "name": "Tinazoline", "code": "51262103" },
      { "name": "Atiprosin maleate", "code": "51262104" },
      { "name": "Adrenergic blocking agent dioxanes", "code": "51262200" },
      { "name": "Piperoxan", "code": "51262201" },
      { "name": "Proroxan", "code": "51262202" },
      { "name": "Spiroxatrine", "code": "51262203" },
      { "name": "Piperoxan hydrochloride", "code": "51262204" },
      { "name": "Proroxan hydrochloride", "code": "51262205" },
      {
        "name": "Adrenergic blocking agent ergolines and heterocyclic compounds, 3-ring and morpholines and pyridazines",
        "code": "51262300"
      },
      { "name": "Epicriptine", "code": "51262301" },
      { "name": "Fiduxosin", "code": "51262302" },
      { "name": "Viloxazine", "code": "51262303" },
      { "name": "Pirepolol", "code": "51262304" },
      { "name": "Ridazolol", "code": "51262305" },
      { "name": "Fiduxosin hydrochloride", "code": "51262306" },
      { "name": "Viloxazine hydrochloride", "code": "51262307" },
      { "name": "Adrenergic blocking agent ethanolamines", "code": "51262400" },
      { "name": "Procaterol", "code": "51262401" },
      { "name": "Pronetalol or pronethalol", "code": "51262402" },
      { "name": "Amosulalol", "code": "51262403" },
      { "name": "Labetalol", "code": "51262404" },
      { "name": "Reproterol", "code": "51262406" },
      { "name": "Terbutaline", "code": "51262407" },
      { "name": "Amosulalol hydrochloride", "code": "51262408" },
      { "name": "Amosulalol monohydrochloride", "code": "51262409" },
      { "name": "Labetalol hydrochloride", "code": "51262410" },
      { "name": "Procaterol hydrochloride", "code": "51262411" },
      { "name": "Procaterol hydrochloride hemihydrate", "code": "51262412" },
      { "name": "Reproterol hydrochloride", "code": "51262413" },
      { "name": "Terbutaline sulfate", "code": "51262414" },
      { "name": "Adrenergic blocking agent guanidines", "code": "51262500" },
      { "name": "Betanidine or bethanidine", "code": "51262501" },
      { "name": "Guanazodine", "code": "51262502" },
      { "name": "Guanoxan", "code": "51262503" },
      { "name": "Betanidine sulfate", "code": "51262504" },
      { "name": "Guanazodine sulfate", "code": "51262505" },
      { "name": "Guanazodine sulfate monohydrate", "code": "51262506" },
      { "name": "Guanoxan sulfate", "code": "51262507" },
      { "name": "Adrenergic blocking agent imidazoles", "code": "51262600" },
      { "name": "Atipamezole", "code": "51262601" },
      { "name": "Imiloxan", "code": "51262602" },
      { "name": "Napamezole", "code": "51262603" },
      { "name": "Phentolamine", "code": "51262604" },
      { "name": "Atipamezole hydrochloride", "code": "51262605" },
      { "name": "Imiloxan hydrochloride", "code": "51262606" },
      { "name": "Napamezole hydrochloride", "code": "51262607" },
      { "name": "Phentolamine hydrochloride", "code": "51262608" },
      { "name": "Phentolamine mesilate or mesylate", "code": "51262609" },
      { "name": "Adrenergic blocking agent imidazolines", "code": "51262700" },
      { "name": "Tolazoline", "code": "51262701" },
      { "name": "Tymazoline", "code": "51262702" },
      { "name": "Tolazoline hydrochloride", "code": "51262703" },
      {
        "name": "Adrenergic blocking agent phenoxypropanolamines",
        "code": "51262900"
      },
      { "name": "Pindolol", "code": "51262901" },
      { "name": "Flusoxolol", "code": "51262902" },
      { "name": "Levomoprolol", "code": "51262903" },
      { "name": "Mepindolol", "code": "51262904" },
      { "name": "Prenalterol", "code": "51262905" },
      { "name": "Procinolol", "code": "51262906" },
      { "name": "Propranolol", "code": "51262907" },
      { "name": "Bopindolol", "code": "51262908" },
      { "name": "Bopindolol fumarate", "code": "51262909" },
      { "name": "Bopindolol hydrogen malonate", "code": "51262910" },
      { "name": "Bopindolol malonate", "code": "51262911" },
      { "name": "Mepindolol sulfate", "code": "51262912" },
      { "name": "Prenalterol hydrochloride", "code": "51262913" },
      { "name": "Propranolol hydrochloride", "code": "51262914" },
      { "name": "Adrenergic blocking agent piperazines", "code": "51263000" },
      { "name": "Dapiprazole", "code": "51263001" },
      { "name": "Prazosin", "code": "51263002" },
      { "name": "Trimazosin", "code": "51263003" },
      { "name": "Urapidil", "code": "51263004" },
      { "name": "Zolertine", "code": "51263005" },
      { "name": "Terazosin", "code": "51263006" },
      { "name": "Dapiprazole hydrochloride", "code": "51263007" },
      { "name": "Dapiprazole monohydrochloride", "code": "51263008" },
      { "name": "Prazosin hydrochloride", "code": "51263009" },
      { "name": "Terazosin hydrochloride", "code": "51263010" },
      { "name": "Terazosin hydrochloride anhydrous", "code": "51263011" },
      { "name": "Terazosin hydrochloride dihydrate", "code": "51263012" },
      { "name": "Terazosin monohydrochloride dihydrate", "code": "51263013" },
      { "name": "Trimazosin hydrochloride", "code": "51263014" },
      { "name": "Trimazosin hydrochloride anhydrous", "code": "51263015" },
      { "name": "Urapidil fumarate", "code": "51263016" },
      { "name": "Zolertine hydrochloride", "code": "51263017" },
      {
        "name": "Adrenergic blocking agent propanolamines",
        "code": "51263100"
      },
      { "name": "Atenolol", "code": "51263101" },
      { "name": "Arotinolol", "code": "51263102" },
      { "name": "Adimolol", "code": "51263103" },
      { "name": "Alprenolol", "code": "51263104" },
      { "name": "Bisoprolol", "code": "51263105" },
      { "name": "Carteolol", "code": "51263106" },
      { "name": "Befunolol", "code": "51263107" },
      { "name": "Betaxolol or levobetaxolol", "code": "51263108" },
      { "name": "Bevantolol", "code": "51263109" },
      { "name": "Idropranolol", "code": "51263110" },
      { "name": "Bornaprolol", "code": "51263111" },
      { "name": "Bucindolol", "code": "51263112" },
      { "name": "Bucumolol", "code": "51263113" },
      { "name": "Bunitrolol", "code": "51263114" },
      { "name": "Bupranolol", "code": "51263115" },
      { "name": "Butofilolol", "code": "51263116" },
      { "name": "Carazolol", "code": "51263117" },
      { "name": "Nicainoprol", "code": "51263118" },
      { "name": "Cicloprolol", "code": "51263119" },
      { "name": "Cloranolol", "code": "51263120" },
      { "name": "Epanolol", "code": "51263121" },
      { "name": "Esmolol", "code": "51263122" },
      { "name": "Primidolol", "code": "51263123" },
      { "name": "Indopanolol", "code": "51263124" },
      { "name": "Levobunolol", "code": "51263125" },
      { "name": "Metipranolol", "code": "51263126" },
      { "name": "Talinolol", "code": "51263127" },
      { "name": "Nipradilol", "code": "51263128" },
      { "name": "Oxprenolol", "code": "51263129" },
      { "name": "Pacrinolol", "code": "51263130" },
      { "name": "Pafenolol", "code": "51263131" },
      { "name": "Pargolol", "code": "51263132" },
      { "name": "Penbutolol", "code": "51263133" },
      { "name": "Practolol", "code": "51263134" },
      { "name": "Tertatolol", "code": "51263135" },
      { "name": "Timolol", "code": "51263136" },
      { "name": "Xipranolol", "code": "51263137" },
      { "name": "Acebutolol", "code": "51263138" },
      { "name": "Metoprolol", "code": "51263139" },
      { "name": "Celiprolol", "code": "51263140" },
      { "name": "Esatenolol or s-atenolol", "code": "51263141" },
      { "name": "Flestolol", "code": "51263142" },
      { "name": "Acebutolol hydrochloride", "code": "51263143" },
      { "name": "Alprenolol benzoate", "code": "51263144" },
      { "name": "Alprenolol hydrochloride", "code": "51263145" },
      { "name": "Arotinolol hydrochloride", "code": "51263146" },
      { "name": "Atenolol hydrochloride", "code": "51263147" },
      { "name": "Befunolol hydrochloride", "code": "51263148" },
      { "name": "Betaxolol hydrochloride", "code": "51263149" },
      { "name": "Bevantolol hydrochloride", "code": "51263150" },
      { "name": "Bisoprolol fumarate", "code": "51263151" },
      { "name": "Bisoprolol hemifumarate", "code": "51263152" },
      { "name": "Bisoprolol hydrochloride", "code": "51263153" },
      { "name": "Bucindolol hydrochloride", "code": "51263154" },
      { "name": "Bucumolol hydrochloride", "code": "51263155" },
      { "name": "Bunitrolol hydrochloride", "code": "51263156" },
      { "name": "Bupranolol hydrochloride", "code": "51263157" },
      { "name": "Carteolol hydrochloride", "code": "51263158" },
      { "name": "Celiprolol hydrochloride", "code": "51263159" },
      { "name": "Cicloprolol hydrochloride", "code": "51263160" },
      { "name": "Cloranolol hydrochloride", "code": "51263161" },
      { "name": "Esmolol hydrochloride", "code": "51263162" },
      { "name": "Flestolol sulfate", "code": "51263163" },
      { "name": "Levobetaxolol hydrochloride", "code": "51263164" },
      { "name": "Levobunolol hydrochloride", "code": "51263165" },
      { "name": "Metipranolol hydrochloride", "code": "51263166" },
      { "name": "Metoprolol fumarate", "code": "51263167" },
      { "name": "Metoprolol succinate", "code": "51263168" },
      { "name": "Metoprolol tartrate", "code": "51263169" },
      { "name": "Oxprenolol hydrochloride", "code": "51263170" },
      { "name": "Penbutolol sulfate", "code": "51263171" },
      { "name": "Tertatolol hydrochloride", "code": "51263172" },
      { "name": "Timolol anhydrous", "code": "51263173" },
      { "name": "Timolol hemihydrate", "code": "51263174" },
      { "name": "Timolol maleate", "code": "51263175" },
      { "name": "Adrenergic blocking agents, synthesized", "code": "51263300" },
      { "name": "Nafetolol", "code": "51263301" },
      { "name": "Butamoxane", "code": "51263302" },
      { "name": "Butidrine", "code": "51263303" },
      { "name": "Butocrolol", "code": "51263304" },
      { "name": "Carpindolol", "code": "51263305" },
      { "name": "Dioxadilol", "code": "51263306" },
      { "name": "Ecastolol", "code": "51263307" },
      { "name": "Ericolol", "code": "51263308" },
      { "name": "Idralfidine", "code": "51263309" },
      { "name": "Penirolol", "code": "51263310" },
      { "name": "Quinazosin", "code": "51263311" },
      { "name": "Tolboxane", "code": "51263312" },
      { "name": "Tribendilol", "code": "51263313" },
      { "name": "Trigevolol", "code": "51263314" },
      { "name": "Trigevolol mesilate or mesylate", "code": "51263315" },
      {
        "name": "Adrenergic blocking agent pyrimidine nucleosides and quaternary ammonium compounds and secologanin tryptamine alkaloids and spiro compounds and sulfonamides",
        "code": "51263400"
      },
      { "name": "Yohimbine", "code": "51263401" },
      { "name": "Tamsulosin", "code": "51263402" },
      { "name": "Spirendolol", "code": "51263403" },
      { "name": "Bretylium", "code": "51263404" },
      { "name": "Bretylium tosilate", "code": "51263405" },
      { "name": "Tamsulosin hydrochloride", "code": "51263406" },
      { "name": "Yohimbine hydrochloride", "code": "51263407" },
      {
        "name": "Adrenergic blocking agent phenethylamines and quinolines",
        "code": "51263500"
      },
      { "name": "Hexoprenaline", "code": "51263501" },
      { "name": "Brefonalol", "code": "51263502" },
      { "name": "Hexoprenaline dihydrochloride", "code": "51263503" },
      { "name": "Hexoprenaline sulfate", "code": "51263504" },
      { "name": "Adrenergic blocking agent quinazolines", "code": "51263600" },
      { "name": "Alfuzosin", "code": "51263601" },
      { "name": "Doxazosin", "code": "51263602" },
      { "name": "Alfuzosin hydrochloride", "code": "51263603" },
      { "name": "Doxazosin mesilate or mesylate", "code": "51263604" },
      { "name": "Adrenergic blocking agent isoquinolines", "code": "51263700" },
      { "name": "Adaprolol", "code": "51263701" },
      { "name": "Debrisoquin sulfate", "code": "51263702" },
      { "name": "Tilisolol", "code": "51263703" },
      { "name": "Adaprolol maleate", "code": "51263704" },
      { "name": "Tilisolol hydrochloride", "code": "51263705" },
      {
        "name": "Anaesthetic drugs and related adjuncts and analeptics",
        "code": "51270000"
      },
      { "name": "Anaesthetic alcohols", "code": "51271500" },
      { "name": "Chlorobutanol", "code": "51271501" },
      { "name": "Ethyl chloride", "code": "51271502" },
      { "name": "Hyaluronidase", "code": "51271503" },
      { "name": "Salicyl alcohol", "code": "51271504" },
      { "name": "Chlorobutanol hemihydrate", "code": "51271505" },
      { "name": "Anaesthetic amides", "code": "51271600" },
      { "name": "Cinchocaine or dibucaine", "code": "51271601" },
      { "name": "Bumecaine", "code": "51271602" },
      { "name": "Bupivacaine", "code": "51271603" },
      { "name": "Butanilicaine", "code": "51271604" },
      { "name": "Capsaicin", "code": "51271605" },
      { "name": "Carcainium chloride", "code": "51271606" },
      { "name": "Prilocaine", "code": "51271607" },
      { "name": "Clibucaine", "code": "51271608" },
      { "name": "Clodacaine", "code": "51271609" },
      { "name": "Etidocaine", "code": "51271610" },
      { "name": "Fexicaine", "code": "51271611" },
      { "name": "Levobupivacaine", "code": "51271612" },
      { "name": "Lidocaine", "code": "51271613" },
      { "name": "Octacaine", "code": "51271614" },
      { "name": "Oxetacaine or oxethazaine", "code": "51271615" },
      { "name": "Pyrrocaine", "code": "51271616" },
      { "name": "Rodocaine", "code": "51271617" },
      { "name": "Ropivacaine", "code": "51271618" },
      { "name": "Zucapsaicin", "code": "51271619" },
      { "name": "Eutectic mixture of local anesthetics", "code": "51271620" },
      { "name": "Bumecaine hydrochloride", "code": "51271621" },
      { "name": "Bupivacaine hydrochloride", "code": "51271622" },
      { "name": "Bupivacaine hydrochloride monohydrate", "code": "51271623" },
      { "name": "Butanilicaine hydrochloride", "code": "51271624" },
      { "name": "Butanilicaine phosphate", "code": "51271625" },
      { "name": "Cinchocaine hydrochloride", "code": "51271626" },
      { "name": "Etidocaine hydrochloride", "code": "51271627" },
      { "name": "Lidocaine benzyl benzoate", "code": "51271628" },
      { "name": "Lidocaine hydrochloride", "code": "51271629" },
      { "name": "Lidocaine hydrochloride anhydrous", "code": "51271630" },
      { "name": "Lidocaine hydrochloride monohydrate", "code": "51271631" },
      { "name": "Octacaine hydrochloride", "code": "51271632" },
      { "name": "Prilocaine hydrochloride", "code": "51271633" },
      { "name": "Pyrrocaine hydrochloride", "code": "51271634" },
      { "name": "Ropivacaine hydrochloride", "code": "51271635" },
      { "name": "Ropivacaine hydrochloride monohydrate", "code": "51271636" },
      {
        "name": "Anaesthetic amidines and benzofurans and butyrates and cinnamates",
        "code": "51271700"
      },
      { "name": "Phenacaine", "code": "51271701" },
      { "name": "Sodium oxybate", "code": "51271702" },
      { "name": "Amolanone", "code": "51271703" },
      { "name": "Eugenol", "code": "51271704" },
      { "name": "Amolanone hydrochloride", "code": "51271705" },
      { "name": "Phenacaine hydrochloride", "code": "51271706" },
      { "name": "Phenacaine hydrochloride anhydrous", "code": "51271707" },
      { "name": "Anaesthetic amines", "code": "51271800" },
      { "name": "Aptocaine", "code": "51271801" },
      { "name": "Hexylcaine", "code": "51271802" },
      { "name": "Propanidid", "code": "51271803" },
      { "name": "Quatacaine", "code": "51271804" },
      { "name": "Rolicyclidine", "code": "51271805" },
      { "name": "Eticyclidine", "code": "51271806" },
      { "name": "Hexylcaine hydrochloride", "code": "51271807" },
      { "name": "Anaesthetic aminobenzoates", "code": "51271900" },
      { "name": "Butamben", "code": "51271901" },
      { "name": "Ambucaine", "code": "51271902" },
      { "name": "Benoxinate or oxybuprocaine", "code": "51271904" },
      { "name": "Benzocaine", "code": "51271905" },
      { "name": "Butacaine", "code": "51271906" },
      { "name": "Butacaine sulfate", "code": "51271907" },
      { "name": "Chloroprocaine", "code": "51271908" },
      { "name": "Isobutamben", "code": "51271909" },
      { "name": "Leucinocaine", "code": "51271910" },
      { "name": "Piridocaine", "code": "51271911" },
      { "name": "Procaine", "code": "51271912" },
      { "name": "Proparacaine or proxymetacaine", "code": "51271913" },
      { "name": "Propoxycaine", "code": "51271914" },
      { "name": "Benzocaine hydrochloride", "code": "51271915" },
      { "name": "Chloroprocaine hydrochloride", "code": "51271916" },
      { "name": "Chloroprocaine penicillin o", "code": "51271917" },
      { "name": "Leucinocaine mesilate or mesylate", "code": "51271918" },
      { "name": "Oxybuprocaine hydrochloride", "code": "51271919" },
      { "name": "Piridocaine hydrochloride", "code": "51271920" },
      { "name": "Procaine borate", "code": "51271921" },
      { "name": "Procaine hydrochloride", "code": "51271922" },
      { "name": "Procaine pyroglutamate", "code": "51271923" },
      { "name": "Propoxycaine hydrochloride", "code": "51271924" },
      { "name": "Proxymetacaine hydrochloride", "code": "51271925" },
      { "name": "Anaesthetic barbiturates", "code": "51272000" },
      { "name": "Methohexital or methohexitone", "code": "51272001" },
      { "name": "Thialbarbital", "code": "51272002" },
      { "name": "Thiamylal", "code": "51272003" },
      {
        "name": "Sodium thiopental or thiopental or thiopentone",
        "code": "51272004"
      },
      { "name": "Methohexital sodium", "code": "51272005" },
      { "name": "Thiopental sodium", "code": "51272006" },
      { "name": "Anaesthetic benzoates", "code": "51272100" },
      { "name": "Parethoxycaine", "code": "51272101" },
      { "name": "Amylocaine", "code": "51272102" },
      { "name": "Betoxycaine", "code": "51272103" },
      { "name": "Butethamine", "code": "51272104" },
      { "name": "Hydroxytetracaine", "code": "51272105" },
      { "name": "Meprylcaine", "code": "51272106" },
      { "name": "Metabutethamine", "code": "51272107" },
      { "name": "Paridocaine", "code": "51272108" },
      { "name": "Pribecaine", "code": "51272109" },
      { "name": "Tolycaine", "code": "51272110" },
      { "name": "Amethocaine or tetracaine", "code": "51272111" },
      { "name": "Betoxycaine hydrochloride", "code": "51272112" },
      { "name": "Meprylcaine hydrochloride", "code": "51272113" },
      { "name": "Parethoxycaine hydrochloride", "code": "51272114" },
      { "name": "Tetracaine hydrochloride", "code": "51272115" },
      { "name": "Tolycaine hydrochloride", "code": "51272116" },
      { "name": "Anaesthetic cycloparaffins", "code": "51272200" },
      { "name": "Aliflurane", "code": "51272201" },
      { "name": "Cyclopropane", "code": "51272202" },
      { "name": "Esketamine", "code": "51272203" },
      { "name": "Esketamine or ketamine or (s)-ketamine", "code": "51272204" },
      { "name": "Tiletamine", "code": "51272205" },
      { "name": "Esketamine hydrochloride", "code": "51272206" },
      { "name": "Ketamine hydrochloride", "code": "51272207" },
      { "name": "Tiletamine hydrochloride", "code": "51272208" },
      {
        "name": "Anaesthetic dioxolanes and gases and imidazoles and isoquinolines",
        "code": "51272300"
      },
      { "name": "Etoxadrol", "code": "51272301" },
      { "name": "Etomidate", "code": "51272302" },
      { "name": "Nitrous oxide", "code": "51272303" },
      { "name": "Etomidate hydrochloride", "code": "51272304" },
      { "name": "Etomidate sulfate", "code": "51272305" },
      { "name": "Etomidate sulphate", "code": "51272306" },
      { "name": "Etoxadrol hydrochloride", "code": "51272307" },
      { "name": "Anaesthetic ethers", "code": "51272400" },
      { "name": "Desflurane", "code": "51272401" },
      { "name": "Enflurane", "code": "51272402" },
      { "name": "Diethyl ether or ether", "code": "51272403" },
      { "name": "Fluroxene", "code": "51272404" },
      { "name": "Fomocaine", "code": "51272405" },
      { "name": "Isoflurane", "code": "51272406" },
      { "name": "Methoxyflurane", "code": "51272407" },
      { "name": "Sevoflurane", "code": "51272408" },
      { "name": "Anaesthetic hydrocarbons, halogenated", "code": "51272500" },
      { "name": "Chloroform", "code": "51272501" },
      {
        "name": "Cryofluorane or dichlorotetrafluoroethane",
        "code": "51272502"
      },
      { "name": "Halothane", "code": "51272503" },
      { "name": "Trichloroethylene", "code": "51272504" },
      { "name": "Anaesthetic isonipecotic acids", "code": "51272600" },
      { "name": "Anileridine", "code": "51272601" },
      { "name": "Phenoperidine", "code": "51272602" },
      { "name": "Anileridine hydrochloride", "code": "51272603" },
      { "name": "Anileridine phosphate", "code": "51272604" },
      { "name": "Phenoperidine hydrochloride", "code": "51272605" },
      {
        "name": "Anaesthetic methionine/analogs and derivatives and morpholines and piperazines and propiophenones",
        "code": "51272700"
      },
      { "name": "Methionine", "code": "51272701" },
      { "name": "Pramocaine or pramoxin or pramoxine", "code": "51272702" },
      { "name": "Antrafenine", "code": "51272703" },
      { "name": "Dyclonine", "code": "51272704" },
      { "name": "Dyclonine hydrochloride", "code": "51272705" },
      { "name": "Methionine enkephalin", "code": "51272706" },
      { "name": "Methionine methyl ester", "code": "51272707" },
      { "name": "Methionine sulfoxide", "code": "51272708" },
      { "name": "Pramocaine hydrochloride", "code": "51272709" },
      { "name": "Anaesthetic phenols", "code": "51272900" },
      { "name": "Propofol", "code": "51272901" },
      { "name": "Fospropofol", "code": "51272902" },
      { "name": "Fospropofol sodium", "code": "51272903" },
      { "name": "Anaesthetic piperidines", "code": "51273000" },
      { "name": "Alfentanil", "code": "51273001" },
      { "name": "Cyclomethycaine", "code": "51273002" },
      { "name": "Dexivacaine", "code": "51273003" },
      { "name": "Levoxadrol", "code": "51273004" },
      { "name": "Diperodon", "code": "51273005" },
      { "name": "Mepivacaine", "code": "51273006" },
      { "name": "Phencyclidine", "code": "51273007" },
      { "name": "Piperocaine", "code": "51273008" },
      { "name": "Remifentanil", "code": "51273009" },
      { "name": "Sufentanil", "code": "51273010" },
      { "name": "Vadocaine", "code": "51273011" },
      { "name": "Alfentanil hydrochloride", "code": "51273012" },
      { "name": "Alfentanil hydrochloride anhydrous", "code": "51273013" },
      { "name": "Alfentanil hydrochloride monohydrate", "code": "51273014" },
      { "name": "Cyclomethycaine hydrochloride", "code": "51273015" },
      { "name": "Cyclomethycaine sulfate", "code": "51273016" },
      { "name": "Diperodon anhydrous", "code": "51273017" },
      { "name": "Diperodon hydrochloride", "code": "51273018" },
      { "name": "Levoxadrol hydrochloride", "code": "51273019" },
      { "name": "Mepivacaine hydrochloride", "code": "51273020" },
      { "name": "Phencyclidine hydrochloride", "code": "51273021" },
      { "name": "Piperocaine hydrochloride", "code": "51273022" },
      { "name": "Remifentanil hydrochloride", "code": "51273023" },
      { "name": "Sufentanil citrate", "code": "51273024" },
      { "name": "Anaesthetic pregnanes", "code": "51273100" },
      { "name": "Alfaxalone", "code": "51273101" },
      { "name": "Eltanolone", "code": "51273102" },
      { "name": "Minaxolone", "code": "51273103" },
      { "name": "Hydroxydione", "code": "51273104" },
      { "name": "Hydroxydione sodium succinate", "code": "51273105" },
      { "name": "Anaesthetics, synthesized", "code": "51273300" },
      { "name": "Cinoctramide", "code": "51273301" },
      { "name": "Euprocin", "code": "51273302" },
      { "name": "Pinolcaine", "code": "51273303" },
      { "name": "Biphenamine or xenysalate", "code": "51273304" },
      { "name": "Euprocin hydrochloride", "code": "51273305" },
      {
        "name": "Anaesthetic quinolines and terpenes and thiophenes and tropanes and tropanes",
        "code": "51273600"
      },
      { "name": "Bucricaine", "code": "51273601" },
      { "name": "Vinyl ether", "code": "51273602" },
      { "name": "Articaine or carticaine", "code": "51273603" },
      { "name": "Myrtecaine", "code": "51273604" },
      { "name": "Cocaine", "code": "51273605" },
      { "name": "Articaine hydrochloride", "code": "51273606" },
      { "name": "Cocaine hydrochloride", "code": "51273607" },
      { "name": "Cocaine methiodide", "code": "51273608" },
      { "name": "Cocaine muriate", "code": "51273609" },
      { "name": "Combination analgesics", "code": "51273800" },
      { "name": "Benzocaine/menthol", "code": "51273801" },
      { "name": "Alcohol/arnica", "code": "51273802" },
      {
        "name": "Alcohol/camphor/capsicum oleoresin/methyl salicylate",
        "code": "51273803"
      },
      { "name": "Alcohol/menthol/thymol", "code": "51273804" },
      { "name": "Antipyrine/benzocaine", "code": "51273805" },
      { "name": "Antipyrine/benzocaine/glycerin", "code": "51273806" },
      { "name": "Benzalkonium chloride/benzocaine", "code": "51273807" },
      {
        "name": "Benzethonium/benzocaine/glycerin/polyethylene glycol",
        "code": "51273808"
      },
      {
        "name": "Benzethonium/chloroxylenol/menthol/thymol/zinc stearate",
        "code": "51273809"
      },
      {
        "name": "Camphor/menthol/methyl nicotinate/methyl salicylate",
        "code": "51273810"
      },
      {
        "name": "Camphor/capsicum oleoresin/methyl salicylate/pine oil/turpentine",
        "code": "51273811"
      },
      {
        "name": "Camphor/chloroxylenol/eucalyptus/menthol/methyl salicylate",
        "code": "51273812"
      },
      {
        "name": "Camphor/eucalyptus oil/menthol/methyl salicylate",
        "code": "51273813"
      },
      {
        "name": "Camphor/eucalyptus/menthol/methyl salicylate/turpentine",
        "code": "51273814"
      },
      { "name": "Camphor/menthol", "code": "51273815" },
      { "name": "Guaiacol/menthol/methyl salicylate", "code": "51273816" },
      { "name": "Camphor/menthol/methyl salicylate", "code": "51273817" },
      {
        "name": "Camphor/menthol/methyl salicylate/peppermint oil",
        "code": "51273818"
      },
      {
        "name": "Camphor/menthol/methyl salicylate/tartrazine",
        "code": "51273819"
      },
      {
        "name": "Chloroxylenol/iodine/menthol/potassium iodide/thymol",
        "code": "51273820"
      },
      {
        "name": "Dipropylene glycol salicylate/histamine/methyl nicotinate",
        "code": "51273821"
      },
      { "name": "Eucalyptus oil/menthol", "code": "51273822" },
      {
        "name": "Eucalyptus oil/methyl salicylate/peppermint oil/salicylic acid",
        "code": "51273823"
      },
      {
        "name": "Eucalyptus/menthol/methyl salicylate/triethanolamine",
        "code": "51273824"
      },
      { "name": "Guaiacol/methyl salicylate", "code": "51273825" },
      {
        "name": "Menthol/methyl nicotinate/methyl salicylate",
        "code": "51273826"
      },
      { "name": "Menthol/methyl salicylate", "code": "51273827" },
      { "name": "Methacholine/methyl salicylate", "code": "51273828" },
      { "name": "Methyl salicylate/trolamine", "code": "51273829" },
      { "name": "Antipyrine and benzocaine solution", "code": "51273830" },
      { "name": "Combination anesthetics and adjuncts", "code": "51273900" },
      { "name": "Benzocaine/calamine/zinc oxide", "code": "51273901" },
      { "name": "Aloe/benzocaine/lanolin/menthol", "code": "51273902" },
      {
        "name": "Aloh/diphenhydramine/lidocaine/magnesium/simethicone",
        "code": "51273903"
      },
      { "name": "Articaine/epinephrine", "code": "51273904" },
      { "name": "Atropine/morphine", "code": "51273905" },
      { "name": "Atropine/neostigmine", "code": "51273906" },
      {
        "name": "Benzalkonium chloride/benzocaine/dibucaine/tetracaine",
        "code": "51273907"
      },
      { "name": "Benzethonium chloride/benzocaine", "code": "51273908" },
      { "name": "Benzocaine/boric acid", "code": "51273909" },
      { "name": "Benzocaine/butamben/tetracaine", "code": "51273910" },
      { "name": "Benzocaine/orabase", "code": "51273911" },
      { "name": "Benzocaine/cetalkonium chloride", "code": "51273912" },
      { "name": "Benzocaine/cetylpyridinium chloride", "code": "51273913" },
      {
        "name": "Benzocaine/cetylpyridinium chloride/terpin hydrate",
        "code": "51273914"
      },
      {
        "name": "Benzocaine/chlorobutanol/ethanol/menthol/tannic acid",
        "code": "51273915"
      },
      { "name": "Benzocaine/isopropyl alcohol/menthol", "code": "51273916" },
      { "name": "Benzocaine/licorice/menthol", "code": "51273917" },
      { "name": "Cetylpyridinium/menthol", "code": "51273918" },
      { "name": "Benzocaine/phenol/vitamin e/zinc oxide", "code": "51273919" },
      { "name": "Benzocaine/resorcinol", "code": "51273920" },
      { "name": "Bupivacaine/dextrose", "code": "51273921" },
      {
        "name": "Bupivacaine/dextrose/epinephrine/lidocaine",
        "code": "51273922"
      },
      { "name": "Bupivacaine/epinephrine", "code": "51273923" },
      { "name": "Bupivacaine/epinephrine/lidocaine", "code": "51273924" },
      {
        "name": "Bupivacaine/epinephrine/lidocaine/sodium chloride",
        "code": "51273925"
      },
      { "name": "Ephedrine/lidocaine/sodium chloride", "code": "51273926" },
      { "name": "Dextrose/epinephrine/lidocaine", "code": "51273927" },
      { "name": "Dextrose/epinephrine/tetracaine", "code": "51273928" },
      { "name": "Dextrose/lidocaine", "code": "51273929" },
      { "name": "Dextrose/lidocaine/tetracaine", "code": "51273930" },
      { "name": "Dextrose/tetracaine", "code": "51273931" },
      { "name": "Dibucaine/diperodon", "code": "51273932" },
      {
        "name": "Dichlorodifluoromethane/trichloroflouromethane",
        "code": "51273933"
      },
      {
        "name": "Dichlorotetrafluoroethane/ethyl chloride",
        "code": "51273934"
      },
      { "name": "Lidocaine/prilocaine", "code": "51273935" },
      { "name": "Epinephrine/etidocaine", "code": "51273936" },
      { "name": "Epinephrine/lidocaine", "code": "51273937" },
      { "name": "Epinephrine/lidocaine/povidone", "code": "51273938" },
      {
        "name": "Epinephrine/lidocaine/povidone/sodium chloride",
        "code": "51273939"
      },
      { "name": "Epinephrine/lidocaine/sodium chloride", "code": "51273940" },
      { "name": "Epinephrine/prilocaine", "code": "51273941" },
      { "name": "Levonordefrin/mepivacaine", "code": "51273942" },
      { "name": "Lidocaine/povidone iodine", "code": "51273943" },
      { "name": "Lidocaine/sodium chloride", "code": "51273944" },
      { "name": "Lidocaine/tetracaine", "code": "51273945" },
      { "name": "Menthol/petrolatum/pramoxine", "code": "51273946" },
      { "name": "Menthol/pramoxine", "code": "51273947" },
      { "name": "Sodium chloride/thiopental", "code": "51273948" },
      { "name": "Thiopental/water", "code": "51273949" },
      { "name": "Antibacterials", "code": "51280000" },
      { "name": "Antitubercular drugs", "code": "51281500" },
      { "name": "Ethambutol", "code": "51281501" },
      { "name": "Isoniazid", "code": "51281502" },
      { "name": "Amithiozone or thiacetazone", "code": "51281503" },
      { "name": "Pyrazinamide", "code": "51281504" },
      { "name": "Protionamide", "code": "51281505" },
      { "name": "Aminosalicylic acid", "code": "51281506" },
      { "name": "Ethionamide", "code": "51281507" },
      {
        "name": "Morinamide or morphazinamide or morinamid",
        "code": "51281508"
      },
      { "name": "Terizidone", "code": "51281509" },
      { "name": "Tiocarlide", "code": "51281510" },
      { "name": "Ethambutol dihydrochloride", "code": "51281511" },
      { "name": "Verazide", "code": "51281512" },
      { "name": "Ethambutol hydrochloride", "code": "51281513" },
      { "name": "Isoniazid calcium pyruvate", "code": "51281514" },
      { "name": "Isoniazid methanesulfonate", "code": "51281515" },
      { "name": "Morinamide hydrochloride", "code": "51281516" },
      { "name": "Fenamisal", "code": "51281517" },
      { "name": "Aminoglycosides", "code": "51281600" },
      { "name": "Bluensomycin", "code": "51281601" },
      { "name": "Spectinomycin", "code": "51281602" },
      { "name": "Apramycin", "code": "51281603" },
      { "name": "Arbekacin", "code": "51281604" },
      { "name": "Bekanamycin", "code": "51281605" },
      { "name": "Betamicin", "code": "51281606" },
      { "name": "Gentamicin", "code": "51281607" },
      { "name": "Butikacin", "code": "51281608" },
      { "name": "Daptomycin", "code": "51281609" },
      { "name": "Dibekacin", "code": "51281610" },
      { "name": "Dihydrostreptomycin", "code": "51281611" },
      { "name": "Elsamicin a or elsamitrucin", "code": "51281612" },
      { "name": "Framycetin", "code": "51281613" },
      { "name": "Netilmicin", "code": "51281614" },
      { "name": "Isepamicin", "code": "51281615" },
      { "name": "Kanamycin", "code": "51281616" },
      { "name": "Micronomicin", "code": "51281617" },
      { "name": "Neomycin", "code": "51281618" },
      { "name": "Paromomycin", "code": "51281620" },
      { "name": "Propikacin", "code": "51281621" },
      { "name": "Ribostamycin", "code": "51281622" },
      { "name": "Sisomicin", "code": "51281623" },
      { "name": "Streptomycin", "code": "51281624" },
      { "name": "Telavancin", "code": "51281625" },
      { "name": "Tobramycin", "code": "51281626" },
      { "name": "Amikacin", "code": "51281627" },
      { "name": "Capreomycin", "code": "51281628" },
      { "name": "Streptoduocin", "code": "51281629" },
      { "name": "Astromicin", "code": "51281630" },
      { "name": "Viomycin", "code": "51281631" },
      { "name": "Amikacin sulfate", "code": "51281632" },
      { "name": "Apramycin sulfate", "code": "51281633" },
      { "name": "Apramycin sulphate", "code": "51281634" },
      { "name": "Arbekacin sulfate", "code": "51281635" },
      { "name": "Astromicin sulfate", "code": "51281636" },
      { "name": "Bekanamycin sulfate", "code": "51281637" },
      { "name": "Betamicin sulfate", "code": "51281638" },
      { "name": "Capreomycin ia", "code": "51281639" },
      { "name": "Capreomycin ib", "code": "51281640" },
      { "name": "Capreomycin iia", "code": "51281641" },
      { "name": "Capreomycin iib", "code": "51281642" },
      { "name": "Capreomycin sulfate", "code": "51281643" },
      { "name": "Capreomycin sulphate", "code": "51281644" },
      { "name": "Dibekacin sulfate", "code": "51281645" },
      { "name": "Dibekacin sulphate", "code": "51281646" },
      { "name": "Framycetin sulfate", "code": "51281647" },
      { "name": "Framycetin sulphate", "code": "51281648" },
      { "name": "Gentamicin a", "code": "51281649" },
      { "name": "Gentamicin c1a", "code": "51281650" },
      { "name": "Gentamicin sulfate", "code": "51281651" },
      { "name": "Isepamicin sulfate", "code": "51281652" },
      { "name": "Kanamycin a", "code": "51281653" },
      { "name": "Kanamycin a sulfate", "code": "51281654" },
      { "name": "Micronomicin sulfate", "code": "51281655" },
      { "name": "Neomycin b sulphate", "code": "51281656" },
      { "name": "Neomycin c", "code": "51281657" },
      { "name": "Neomycin e", "code": "51281658" },
      { "name": "Neomycin sulfate", "code": "51281659" },
      { "name": "Neomycin sulphate", "code": "51281660" },
      { "name": "Netilmicin sulfate", "code": "51281661" },
      { "name": "Paromomycin i", "code": "51281662" },
      { "name": "Paromomycin sulfate", "code": "51281663" },
      { "name": "Ribostamycin sulfate", "code": "51281664" },
      { "name": "Sisomicin sulfate", "code": "51281665" },
      { "name": "Spectinomycin dihydrochloride", "code": "51281666" },
      {
        "name": "Spectinomycin dihydrochloride pentahydrate",
        "code": "51281667"
      },
      { "name": "Spectinomycin hydrochloride", "code": "51281668" },
      { "name": "Spectinomycin hydrochloride anhydrous", "code": "51281669" },
      { "name": "Spectinomycin sulfate", "code": "51281670" },
      { "name": "Spectinomycin sulfate tetrahydrate", "code": "51281671" },
      { "name": "Spectinomycin sulphate", "code": "51281672" },
      { "name": "Streptomycin b", "code": "51281673" },
      { "name": "Streptomycin hydrochloride", "code": "51281674" },
      { "name": "Streptomycin sulfate", "code": "51281675" },
      { "name": "Telavancin hydrochloride", "code": "51281676" },
      { "name": "Tobramycin sulfate", "code": "51281677" },
      { "name": "Tobramycin sulphate", "code": "51281678" },
      { "name": "Viomycin hydrochloride", "code": "51281679" },
      { "name": "Viomycin pantothenate sulfate", "code": "51281680" },
      { "name": "Viomycin sulfate", "code": "51281681" },
      { "name": "Viomycin trihydrochloride", "code": "51281682" },
      { "name": "Porfiromycin", "code": "51281683" },
      { "name": "Aminopenicillins", "code": "51281700" },
      { "name": "Lenampicillin", "code": "51281701" },
      { "name": "Amoxicillin", "code": "51281702" },
      { "name": "Ampicillin", "code": "51281703" },
      { "name": "Aspoxicillin", "code": "51281704" },
      { "name": "Bacampicillin", "code": "51281705" },
      {
        "name": "Ciclacillin or cyclacillin or gloximonam",
        "code": "51281706"
      },
      { "name": "Sarmoxicillin", "code": "51281707" },
      { "name": "Sultamicillin", "code": "51281708" },
      { "name": "Talampicillin", "code": "51281709" },
      { "name": "Amoxicillin anhydrous", "code": "51281710" },
      { "name": "Amoxicillin hydrate", "code": "51281711" },
      { "name": "Amoxicillin sodium", "code": "51281712" },
      { "name": "Amoxicillin trihydrate", "code": "51281713" },
      { "name": "Ampicillin anhydrous", "code": "51281714" },
      { "name": "Ampicillin benzathine", "code": "51281715" },
      { "name": "Ampicillin sodium", "code": "51281716" },
      { "name": "Ampicillin trihydrate", "code": "51281717" },
      { "name": "Bacampicillin hydrochloride", "code": "51281718" },
      { "name": "Lenampicillin hydrochloride", "code": "51281719" },
      { "name": "Talampicillin hydrochloride", "code": "51281720" },
      { "name": "Anthracyclines", "code": "51281800" },
      { "name": "Idarubicin", "code": "51281801" },
      { "name": "Aclarubicin or aclacinomycin A", "code": "51281802" },
      { "name": "Epirubicin", "code": "51281803" },
      { "name": "Carubicin", "code": "51281804" },
      { "name": "Daunorubicin", "code": "51281805" },
      { "name": "Doxorubicin", "code": "51281806" },
      { "name": "Amrubicin", "code": "51281807" },
      { "name": "Esorubicin", "code": "51281808" },
      { "name": "Mitoxantrone", "code": "51281809" },
      { "name": "Valrubicin", "code": "51281810" },
      { "name": "Detorubicin", "code": "51281811" },
      { "name": "Zorubicin", "code": "51281812" },
      { "name": "Aclarubicin hydrochloride", "code": "51281813" },
      { "name": "Amrubicin hydrochloride", "code": "51281814" },
      { "name": "Carubicin hydrochloride", "code": "51281815" },
      { "name": "Daunorubicin aglycone", "code": "51281816" },
      { "name": "Doxorubicin aglycone", "code": "51281817" },
      { "name": "Doxorubicin hydrochloride", "code": "51281818" },
      { "name": "Epirubicin hydrochloride", "code": "51281819" },
      { "name": "Esorubicin hydrochloride", "code": "51281820" },
      { "name": "Idarubicin hydrochloride", "code": "51281821" },
      { "name": "Mitoxantrone dihydrochloride", "code": "51281822" },
      { "name": "Mitoxantrone hydrochloride", "code": "51281823" },
      { "name": "Zorubicin hydrochloride", "code": "51281824" },
      { "name": "Antineoplastics", "code": "51281900" },
      { "name": "Geranylhydroquinone or geroquinol", "code": "51281901" },
      { "name": "Rifabutin", "code": "51281902" },
      { "name": "Nemorubicin", "code": "51281903" },
      { "name": "Olivomycin", "code": "51281904" },
      { "name": "Plicamycin", "code": "51281905" },
      { "name": "Puromycin", "code": "51281906" },
      { "name": "Rufocromomycin or streptonigrin", "code": "51281907" },
      { "name": "Streptozocin", "code": "51281908" },
      { "name": "Antramycin or anthramycin", "code": "51281909" },
      { "name": "Nemorubicin hydrochloride", "code": "51281910" },
      { "name": "Olivomycin b", "code": "51281911" },
      { "name": "Puromycin aminonucleoside", "code": "51281912" },
      {
        "name": "Actinomycines and bacitracins and chloramphenicols and cyclic peptide antibacterials and glycylcyclines",
        "code": "51282000"
      },
      { "name": "Dactinomycin", "code": "51282001" },
      { "name": "Bacitracin", "code": "51282002" },
      { "name": "Tigecycline", "code": "51282003" },
      { "name": "Chloramphenicol or chloramphenicolum", "code": "51282004" },
      { "name": "Tyrothricin", "code": "51282005" },
      { "name": "Bacitracin a", "code": "51282006" },
      { "name": "Bacitracin methylenedisalicylate", "code": "51282007" },
      { "name": "Bacitracin zinc", "code": "51282008" },
      { "name": "Chloramphenicol palmitate", "code": "51282009" },
      { "name": "Chloramphenicol sodium succinate", "code": "51282010" },
      { "name": "Chloramphenicol stearate", "code": "51282011" },
      { "name": "Chloramphenicol succinate", "code": "51282012" },
      { "name": "Beta-lactams", "code": "51282100" },
      { "name": "Clavulanic acid", "code": "51282101" },
      { "name": "Amantocillin", "code": "51282102" },
      { "name": "Azidocillin", "code": "51282103" },
      { "name": "Carumonam", "code": "51282104" },
      { "name": "Latamoxef or moxalactam", "code": "51282105" },
      { "name": "Clometocillin", "code": "51282106" },
      { "name": "Epicillin", "code": "51282107" },
      { "name": "Faropenem", "code": "51282108" },
      { "name": "Faropenem sodium", "code": "51282109" },
      { "name": "Floxacillin or flucloxacillin", "code": "51282110" },
      { "name": "Penamecillin", "code": "51282111" },
      { "name": "Isopropicillin", "code": "51282112" },
      { "name": "Propicillin", "code": "51282113" },
      { "name": "Metampicillin", "code": "51282114" },
      { "name": "Oximonam", "code": "51282115" },
      { "name": "Pivampicillin", "code": "51282116" },
      { "name": "Sulbenicillin", "code": "51282117" },
      { "name": "Ciclacillin or gloximonam", "code": "51282118" },
      { "name": "Hetacillin", "code": "51282119" },
      { "name": "Azidocillin potassium", "code": "51282120" },
      { "name": "Sulbactam", "code": "51282121" },
      { "name": "Azidocillin sodium", "code": "51282122" },
      { "name": "Sulopenem", "code": "51282123" },
      { "name": "Carumonam sodium", "code": "51282124" },
      { "name": "Tameticillin", "code": "51282125" },
      { "name": "Clometocillin potassium", "code": "51282126" },
      { "name": "Tazobactam", "code": "51282127" },
      { "name": "Faropenem daloxate", "code": "51282128" },
      { "name": "Faropenem medoxomil", "code": "51282129" },
      { "name": "Flucloxacillin sodium", "code": "51282130" },
      { "name": "Hetacillin potassium", "code": "51282131" },
      { "name": "Metampicillin sodium", "code": "51282132" },
      { "name": "Moxalactam disodium", "code": "51282133" },
      { "name": "Oximonam sodium", "code": "51282134" },
      { "name": "Pivampicillin hydrochloride", "code": "51282135" },
      { "name": "Pivampicillin pamoate", "code": "51282136" },
      { "name": "Propicillin potassium", "code": "51282137" },
      { "name": "Sulbenicillin disodium", "code": "51282138" },
      { "name": "Sulbenicillin sodium", "code": "51282139" },
      { "name": "Furbucillin", "code": "51282140" },
      { "name": "Cephamycins", "code": "51282200" },
      { "name": "Cefotetan", "code": "51282201" },
      { "name": "Cefoxitin", "code": "51282202" },
      { "name": "Flomoxef", "code": "51282203" },
      { "name": "Cefmetazole", "code": "51282204" },
      { "name": "Cefmetazole sodium", "code": "51282205" },
      { "name": "Cefotetan disodium", "code": "51282206" },
      { "name": "Cefotetan sodium", "code": "51282207" },
      { "name": "Cefoxitin sodium", "code": "51282208" },
      { "name": "Flomoxef sodium", "code": "51282209" },
      { "name": "Erythromycins", "code": "51282300" },
      { "name": "Azithromycin", "code": "51282301" },
      { "name": "Clarithromycin", "code": "51282302" },
      { "name": "Dirithromycin", "code": "51282303" },
      { "name": "Erythromycin", "code": "51282304" },
      { "name": "Erythromycin ethylcarbonate", "code": "51282305" },
      { "name": "Flurithromycin", "code": "51282306" },
      { "name": "Lexithromycin", "code": "51282307" },
      { "name": "Roxithromycin", "code": "51282308" },
      { "name": "Azithromycin anhydrous", "code": "51282309" },
      { "name": "Azithromycin dihydrate", "code": "51282310" },
      { "name": "Clarithromycin lactobionate", "code": "51282311" },
      { "name": "Erythromycin a", "code": "51282312" },
      { "name": "Erythromycin aspartate", "code": "51282313" },
      { "name": "Erythromycin e", "code": "51282314" },
      { "name": "Erythromycin enol ether", "code": "51282315" },
      { "name": "Erythromycin estolate", "code": "51282316" },
      { "name": "Erythromycin ethyl succinate", "code": "51282317" },
      { "name": "Erythromycin ethylsuccinate", "code": "51282318" },
      { "name": "Erythromycin f", "code": "51282319" },
      { "name": "Erythromycin gluceptate", "code": "51282320" },
      { "name": "Erythromycin glucoheptonate", "code": "51282321" },
      { "name": "Erythromycin lactobionate", "code": "51282322" },
      { "name": "Erythromycin oxime", "code": "51282323" },
      { "name": "Erythromycin phosphate", "code": "51282324" },
      { "name": "Erythromycin propionate", "code": "51282325" },
      { "name": "Erythromycin salnacedin", "code": "51282326" },
      { "name": "Erythromycin stearate", "code": "51282327" },
      { "name": "Erythromycin succinate", "code": "51282328" },
      { "name": "Erythromycin thiocyanate", "code": "51282329" },
      { "name": "Extended-spectrum penicillins", "code": "51282400" },
      { "name": "Amdinocillin or mecillinam", "code": "51282401" },
      { "name": "Pivmecillinam or amdinocillin pivoxil", "code": "51282402" },
      { "name": "Azlocillin", "code": "51282403" },
      { "name": "Carbenicillin", "code": "51282404" },
      { "name": "Carindacillin", "code": "51282405" },
      { "name": "Fuzlocillin", "code": "51282406" },
      { "name": "Mezlocillin", "code": "51282407" },
      { "name": "Mezlocillin sodium", "code": "51282408" },
      { "name": "Piperacillin", "code": "51282409" },
      { "name": "Temocillin", "code": "51282410" },
      { "name": "Ticarcillin", "code": "51282411" },
      { "name": "Azlocillin sodium", "code": "51282412" },
      { "name": "Carbenicillin disodium", "code": "51282413" },
      { "name": "Carbenicillin indanyl", "code": "51282414" },
      { "name": "Carbenicillin indanyl sodium", "code": "51282415" },
      { "name": "Carbenicillin phenyl", "code": "51282416" },
      { "name": "Carbenicillin phenyl sodium", "code": "51282417" },
      { "name": "Carbenicillin sodium", "code": "51282418" },
      { "name": "Carindacillin sodium", "code": "51282419" },
      { "name": "Mezlocillin sodium monohydrate", "code": "51282420" },
      { "name": "Piperacillin anhydrous", "code": "51282421" },
      { "name": "Piperacillin hydrate", "code": "51282422" },
      { "name": "Piperacillin sodium", "code": "51282423" },
      { "name": "Pivmecillinam hydrochloride", "code": "51282424" },
      { "name": "Temocillin sodium", "code": "51282425" },
      { "name": "Ticarcillin cresyl sodium", "code": "51282426" },
      { "name": "Ticarcillin disodium", "code": "51282427" },
      { "name": "Ticarcillin sodium", "code": "51282428" },
      { "name": "First generation cephalosporins", "code": "51282500" },
      { "name": "Cefacetrile or cephacetrile", "code": "51282501" },
      { "name": "Cefadroxil", "code": "51282502" },
      { "name": "Cefalexin or cephalexin", "code": "51282503" },
      { "name": "Cefaloglycin or cephaloglycin", "code": "51282504" },
      { "name": "Cefaloridine or cephaloridine", "code": "51282505" },
      { "name": "Cefalotin or cephalothin", "code": "51282506" },
      { "name": "Cefroxadine", "code": "51282507" },
      { "name": "Cefatrizine", "code": "51282508" },
      { "name": "Cefazaflur", "code": "51282509" },
      { "name": "Cefazedone", "code": "51282510" },
      { "name": "Cefazolin or cefazoline or cephazolin", "code": "51282511" },
      { "name": "Ceftezole", "code": "51282513" },
      { "name": "Cephalexin", "code": "51282514" },
      { "name": "Cefapirin or cephapirin", "code": "51282516" },
      { "name": "Cefradine or cephradine", "code": "51282517" },
      { "name": "Cefmepidium", "code": "51282518" },
      { "name": "Cefacetrile sodium", "code": "51282519" },
      { "name": "Cefadroxil hemihydrate", "code": "51282520" },
      { "name": "Cefadroxil monohydrate", "code": "51282521" },
      { "name": "Cefalexin hydrochloride", "code": "51282522" },
      { "name": "Cefalexin monohydrate", "code": "51282523" },
      { "name": "Cefalexin sodium", "code": "51282524" },
      { "name": "Cefalotin sodium", "code": "51282525" },
      { "name": "Cefapirin sodium", "code": "51282526" },
      { "name": "Cefatrizine compd with propylene glycol", "code": "51282527" },
      { "name": "Cefatrizine propylene glycol", "code": "51282528" },
      { "name": "Cefazaflur sodium", "code": "51282529" },
      { "name": "Cefazedone sodium", "code": "51282530" },
      { "name": "Cefazolin benzathine", "code": "51282531" },
      { "name": "Cefazolin sodium", "code": "51282532" },
      { "name": "Cefmepidium chloride", "code": "51282533" },
      { "name": "Ceftezole sodium", "code": "51282534" },
      { "name": "Fourth generation cephalosporins", "code": "51282600" },
      { "name": "Cefpirome", "code": "51282601" },
      { "name": "Cefepime", "code": "51282602" },
      { "name": "Cefluprenam", "code": "51282603" },
      { "name": "Cefoselis", "code": "51282604" },
      { "name": "Cefozopran", "code": "51282605" },
      { "name": "Cefquinome", "code": "51282606" },
      { "name": "Cefovecin", "code": "51282607" },
      { "name": "Cefepime dihydrochloride", "code": "51282608" },
      { "name": "Cefepime dihydrochloride monohydrate", "code": "51282609" },
      { "name": "Cefepime hydrochloride", "code": "51282610" },
      { "name": "Cefoselis sulfate", "code": "51282611" },
      { "name": "Cefovecin sodium", "code": "51282612" },
      { "name": "Cefozopran hydrochloride", "code": "51282613" },
      { "name": "Cefpirome sulfate", "code": "51282614" },
      { "name": "Cefquinome sulfate", "code": "51282615" },
      { "name": "Glycopeptides", "code": "51282700" },
      { "name": "Teicoplanin", "code": "51282701" },
      { "name": "Vancomycin", "code": "51282702" },
      { "name": "Vancomycin hydrochloride", "code": "51282703" },
      { "name": "Dalbavancin", "code": "51282704" },
      { "name": "Vancomycin monohydrochloride", "code": "51282705" },
      { "name": "Imidazoles", "code": "51282800" },
      { "name": "Tinidazole", "code": "51282801" },
      { "name": "Coumamycin", "code": "51282802" },
      { "name": "Ornidazole", "code": "51282803" },
      { "name": "Secnidazole", "code": "51282804" },
      { "name": "Propenidazole", "code": "51282805" },
      { "name": "Dimetridazole", "code": "51282806" },
      { "name": "Telithromycin", "code": "51282807" },
      { "name": "Metronidazole", "code": "51282808" },
      { "name": "Nimorazole", "code": "51282809" },
      { "name": "Metronidazole benzoate", "code": "51282810" },
      { "name": "Metronidazole hydrochloride", "code": "51282811" },
      { "name": "Metronidazole phosphate", "code": "51282812" },
      { "name": "Quinolone antibacterials", "code": "51282900" },
      { "name": "Cinoxacin", "code": "51282901" },
      { "name": "Alatrofloxacin", "code": "51282902" },
      { "name": "Amifloxacin", "code": "51282903" },
      { "name": "Balofloxacin", "code": "51282904" },
      { "name": "Besifloxacin", "code": "51282905" },
      { "name": "Besifloxacin hydrochloride", "code": "51282906" },
      { "name": "Binfloxacin", "code": "51282907" },
      { "name": "Cadrofloxacin", "code": "51282908" },
      { "name": "Ciprofloxacin", "code": "51282909" },
      { "name": "Clinafloxacin", "code": "51282910" },
      { "name": "Danofloxacin", "code": "51282911" },
      { "name": "Dequalinium", "code": "51282912" },
      { "name": "Difloxacin", "code": "51282913" },
      { "name": "Gemifloxacin", "code": "51282914" },
      { "name": "Ecenofloxacin", "code": "51282915" },
      { "name": "Enrofloxacin", "code": "51282916" },
      { "name": "Fandofloxacin", "code": "51282917" },
      { "name": "Fleroxacin", "code": "51282918" },
      { "name": "Flumequine", "code": "51282919" },
      { "name": "Garenoxacin", "code": "51282920" },
      { "name": "Gatifloxacin", "code": "51282921" },
      { "name": "Merafloxacin", "code": "51282922" },
      { "name": "Grepafloxacin", "code": "51282923" },
      { "name": "Ibafloxacin", "code": "51282924" },
      { "name": "Irloxacin", "code": "51282925" },
      { "name": "Levofloxacin", "code": "51282926" },
      { "name": "Lomefloxacin", "code": "51282927" },
      { "name": "Marbofloxacin", "code": "51282928" },
      { "name": "Pefloxacin", "code": "51282929" },
      { "name": "Miloxacin", "code": "51282930" },
      { "name": "Moxifloxacin", "code": "51282931" },
      { "name": "Norfloxacin", "code": "51282932" },
      { "name": "Olamufloxacin", "code": "51282933" },
      { "name": "Orbifloxacin", "code": "51282934" },
      { "name": "Oxolinic acid", "code": "51282935" },
      { "name": "Pazufloxacin", "code": "51282936" },
      { "name": "Sitafloxacin", "code": "51282937" },
      { "name": "Pipemidic acid", "code": "51282938" },
      { "name": "Piromidic acid", "code": "51282939" },
      { "name": "Premafloxacin", "code": "51282940" },
      { "name": "Prulifloxacin", "code": "51282941" },
      { "name": "Rosoxacin", "code": "51282942" },
      { "name": "Rufloxacin", "code": "51282943" },
      { "name": "Sparfloxacin", "code": "51282944" },
      { "name": "Temafloxacin", "code": "51282945" },
      { "name": "Temafloxacin hydrochloride", "code": "51282946" },
      { "name": "Tioxacin", "code": "51282947" },
      { "name": "Tosufloxacin", "code": "51282948" },
      { "name": "Trovafloxacin", "code": "51282949" },
      { "name": "Sarafloxacin", "code": "51282950" },
      { "name": "Nalidixic acid", "code": "51282951" },
      { "name": "Decoquinate", "code": "51282952" },
      { "name": "Enoxacin", "code": "51282953" },
      { "name": "Enoxacin sesquihydrate", "code": "51282954" },
      { "name": "Esafloxacin", "code": "51282955" },
      { "name": "Ofloxacin", "code": "51282956" },
      { "name": "Ofloxacin hydrochloride", "code": "51282957" },
      { "name": "Lincosamides", "code": "51283000" },
      { "name": "Pirlimycin", "code": "51283001" },
      { "name": "Clindamycin", "code": "51283002" },
      { "name": "Clindamycin hydrochloride", "code": "51283003" },
      { "name": "Clindamycin palmitate", "code": "51283004" },
      { "name": "Clindamycin palmitate hydrochloride", "code": "51283005" },
      { "name": "Clindamycin phosphate", "code": "51283006" },
      { "name": "Pirlimycin hydrochloride", "code": "51283007" },
      { "name": "Lipopeptides", "code": "51283100" },
      { "name": "Anidulafungin", "code": "51283101" },
      { "name": "Caspofungin", "code": "51283102" },
      { "name": "Cilofungin", "code": "51283103" },
      { "name": "Colistimethate", "code": "51283104" },
      { "name": "Micafungin", "code": "51283105" },
      { "name": "Polymyxin b", "code": "51283106" },
      { "name": "Caspofungin acetate", "code": "51283107" },
      { "name": "Colistimethate sodium", "code": "51283108" },
      { "name": "Micafungin sodium", "code": "51283109" },
      { "name": "Clofazimine", "code": "51283110" },
      { "name": "Cuprimyxin", "code": "51283111" },
      { "name": "Gramicidin", "code": "51283112" },
      { "name": "Macrolides", "code": "51283200" },
      { "name": "Filipin", "code": "51283201" },
      { "name": "Filipin iii", "code": "51283202" },
      { "name": "Josamycin", "code": "51283203" },
      { "name": "Tylosin", "code": "51283204" },
      { "name": "Oleandomycin", "code": "51283205" },
      { "name": "Rokitamycin", "code": "51283206" },
      { "name": "Sedecamycin", "code": "51283207" },
      { "name": "Spiramycin", "code": "51283208" },
      { "name": "Tilmicosin", "code": "51283209" },
      { "name": "Troleandomycin", "code": "51283210" },
      { "name": "Fidaxomicin", "code": "51283211" },
      { "name": "Mepartricin", "code": "51283212" },
      { "name": "Mirosamicin", "code": "51283213" },
      { "name": "Josamycin propionate", "code": "51283214" },
      { "name": "Relomycin", "code": "51283215" },
      { "name": "Triacetyloleandomycin or troleandomycin", "code": "51283216" },
      { "name": "Josamycin tartrate", "code": "51283217" },
      { "name": "Mepartricin a", "code": "51283218" },
      { "name": "Mepartricin b", "code": "51283219" },
      { "name": "Oleandomycin phosphate", "code": "51283220" },
      { "name": "Spiramycin adipate", "code": "51283221" },
      { "name": "Spiramycin i", "code": "51283222" },
      { "name": "Tilmicosin phosphate", "code": "51283223" },
      { "name": "Tylosin phosphate", "code": "51283224" },
      { "name": "Tylosin tartrate", "code": "51283225" },
      { "name": "Oxazolidinones", "code": "51283300" },
      { "name": "Cycloserine", "code": "51283301" },
      { "name": "Eperezolid", "code": "51283302" },
      { "name": "Linezolid", "code": "51283303" },
      { "name": "Cycloserine tartrate", "code": "51283304" },
      { "name": "Levofuraltadone", "code": "51283305" },
      { "name": "Levofuraltadone hydrochloride", "code": "51283306" },
      { "name": "Penicillins", "code": "51283400" },
      { "name": "Penicillin o", "code": "51283401" },
      { "name": "Adicillin or penicillin n", "code": "51283402" },
      { "name": "Apalcillin", "code": "51283403" },
      { "name": "Carfecillin", "code": "51283404" },
      { "name": "Benzylpenicillin or penicillin g", "code": "51283405" },
      { "name": "Penicillin V or phenoxymethylpenicillin", "code": "51283406" },
      { "name": "Pheneticillin or phenethicillin", "code": "51283407" },
      { "name": "Prazocillin", "code": "51283408" },
      {
        "name": "Procaine benzylpenicillin or procaine penicillin g",
        "code": "51283409"
      },
      { "name": "Sarpicillin", "code": "51283410" },
      { "name": "Piroxicillin", "code": "51283411" },
      { "name": "Apalcillin sodium", "code": "51283412" },
      { "name": "Rotamicillin", "code": "51283413" },
      { "name": "Benzylpenicillin potassium", "code": "51283414" },
      { "name": "Tobicillin", "code": "51283415" },
      { "name": "Benzylpenicillin sodium", "code": "51283416" },
      { "name": "Carfecillin sodium", "code": "51283417" },
      { "name": "Pheneticillin potassium", "code": "51283418" },
      { "name": "Phenoxymethylpenicillin calcium", "code": "51283419" },
      { "name": "Phenoxymethylpenicillin potassium", "code": "51283420" },
      { "name": "Fenbenicillin", "code": "51283421" },
      { "name": "Fumoxicillin", "code": "51283422" },
      { "name": "Oxetacillin", "code": "51283423" },
      { "name": "Penicillinase-resistant penicillins", "code": "51283500" },
      { "name": "Cloxacillin", "code": "51283501" },
      { "name": "Dicloxacillin", "code": "51283502" },
      { "name": "Methicillin or meticillin", "code": "51283503" },
      { "name": "Nafcillin", "code": "51283504" },
      { "name": "Oxacillin", "code": "51283505" },
      { "name": "Cloxacillin benzathine", "code": "51283506" },
      { "name": "Cloxacillin sodium", "code": "51283507" },
      { "name": "Cloxacillin sodium anhydrous", "code": "51283508" },
      { "name": "Dicloxacillin sodium", "code": "51283509" },
      { "name": "Dicloxacillin sodium anhydrous", "code": "51283510" },
      { "name": "Dicloxacillin sodium monohydrate", "code": "51283511" },
      { "name": "Meticillin sodium", "code": "51283512" },
      { "name": "Nafcillin sodium", "code": "51283513" },
      { "name": "Nafcillin sodium anhydrous", "code": "51283514" },
      { "name": "Nafcillin sodium monohydrate", "code": "51283515" },
      { "name": "Oxacillin sodium", "code": "51283516" },
      { "name": "Oxacillin sodium anhydrous", "code": "51283517" },
      { "name": "Rifamycin antibacterials", "code": "51283600" },
      { "name": "Rifampicinn or rifampin", "code": "51283601" },
      { "name": "Rifamycin", "code": "51283602" },
      { "name": "Rifapentine", "code": "51283603" },
      { "name": "Rifaximin", "code": "51283604" },
      { "name": "Rifametane", "code": "51283605" },
      { "name": "Rifamycin amp", "code": "51283606" },
      { "name": "Rifamycin b", "code": "51283607" },
      { "name": "Rifamycin b diethylamide", "code": "51283608" },
      { "name": "Rifamycin o", "code": "51283609" },
      { "name": "Rifamycin s", "code": "51283610" },
      { "name": "Rifamycin sodium", "code": "51283611" },
      { "name": "Rifamycin sv", "code": "51283612" },
      { "name": "Second generation cephalosporins", "code": "51283700" },
      { "name": "Cefaclor", "code": "51283701" },
      { "name": "Cefamandole", "code": "51283702" },
      { "name": "Cefbuperazone", "code": "51283703" },
      { "name": "Cefetrizole", "code": "51283704" },
      { "name": "Cefminox", "code": "51283705" },
      { "name": "Cefonicid", "code": "51283706" },
      { "name": "Ceforanide", "code": "51283707" },
      { "name": "Cefotiam", "code": "51283708" },
      { "name": "Cefprozil", "code": "51283709" },
      { "name": "Cefuzonam", "code": "51283710" },
      { "name": "Loracarbef", "code": "51283711" },
      { "name": "Cefuroxime", "code": "51283712" },
      { "name": "Cefaclor anhydrous", "code": "51283713" },
      { "name": "Cefamandole nafate", "code": "51283714" },
      { "name": "Cefamandole sodium", "code": "51283715" },
      { "name": "Cefbuperazone sodium", "code": "51283716" },
      { "name": "Cefminox sodium", "code": "51283717" },
      { "name": "Cefonicid monosodium", "code": "51283718" },
      { "name": "Cefonicid sodium", "code": "51283719" },
      { "name": "Cefotiam dihydrochloride", "code": "51283720" },
      { "name": "Cefotiam hexetil hydrochloride", "code": "51283721" },
      { "name": "Cefotiam hydrochloride", "code": "51283722" },
      { "name": "Cefprozil anhydrous", "code": "51283723" },
      { "name": "Cefuroxime axetil", "code": "51283724" },
      { "name": "Cefuroxime pivoxetil", "code": "51283725" },
      { "name": "Cefuroxime sodium", "code": "51283726" },
      { "name": "Cefuzonam sodium", "code": "51283727" },
      { "name": "Loracarbef monohydrate", "code": "51283728" },
      { "name": "Streptogramins", "code": "51283800" },
      { "name": "Dalfopristin", "code": "51283801" },
      { "name": "Pristinamycin or pristinamycine", "code": "51283802" },
      { "name": "Quinupristin", "code": "51283803" },
      { "name": "Volpristin", "code": "51283804" },
      { "name": "Sulfonamide antibacterials", "code": "51283900" },
      { "name": "Sulfacetamide", "code": "51283901" },
      { "name": "Mafenide", "code": "51283902" },
      { "name": "Phthalylsulfathiazole", "code": "51283903" },
      { "name": "Silvadene or silver sulfadiazine", "code": "51283904" },
      { "name": "Succinylsulfathiazole", "code": "51283905" },
      { "name": "Sulfabenzamide", "code": "51283906" },
      { "name": "Sulfacitine or sulfacytine", "code": "51283907" },
      { "name": "Sulfadiazine", "code": "51283908" },
      { "name": "Sulfadimethoxine", "code": "51283909" },
      {
        "name": "Sulfadimethazine or sulfadimidine or sulfamidine",
        "code": "51283910"
      },
      { "name": "Sulfadoxine or sulphadoxine", "code": "51283911" },
      {
        "name": "Sulfafurazole or sulfisoxazole or sulphafurazole",
        "code": "51283912"
      },
      {
        "name": "Sulfametoxydiazine or sulfamethoxydiazine sulfameter",
        "code": "51283913"
      },
      { "name": "Sulfaguanidine", "code": "51283914" },
      { "name": "Sulfalene or sulfamethopyrazine", "code": "51283915" },
      { "name": "Sulfamazone", "code": "51283916" },
      { "name": "Sulfamerazine", "code": "51283917" },
      { "name": "Sulfamethizole", "code": "51283918" },
      { "name": "Sulfamethoxazole", "code": "51283919" },
      { "name": "Sulfamethoxypyridazine", "code": "51283920" },
      { "name": "Sulfametomidine or sulfamethomidine", "code": "51283921" },
      { "name": "Sulfathiourea", "code": "51283922" },
      { "name": "Sulfametrole", "code": "51283923" },
      { "name": "Sulfamoxole", "code": "51283924" },
      { "name": "Sulfaperin or sulfaperine", "code": "51283925" },
      { "name": "Sulfaphenazole", "code": "51283926" },
      { "name": "Sulfapyridine", "code": "51283927" },
      { "name": "Sulfaquinoxaline", "code": "51283928" },
      { "name": "Sulfathiazole", "code": "51283929" },
      { "name": "Sulfatolamide", "code": "51283930" },
      {
        "name": "Sulfaisodimidine or sulfamethin or sulfisomidine or sulphasomidine",
        "code": "51283931"
      },
      { "name": "Sulfanilamide or sulphanilamide", "code": "51283932" },
      { "name": "Aldesulfone sodium or sulfoxone", "code": "51283933" },
      { "name": "Sulfatrozole", "code": "51283934" },
      { "name": "Sulfadicramide", "code": "51283935" },
      { "name": "Sulfafenazol", "code": "51283936" },
      { "name": "Mafenide acetate", "code": "51283937" },
      { "name": "Salazosulfathiazole", "code": "51283938" },
      { "name": "Mafenide hydrochloride", "code": "51283939" },
      { "name": "Sulfachlorpyridazine or sulfaclozine", "code": "51283940" },
      { "name": "Sulfacetamide sodium", "code": "51283941" },
      { "name": "Sulfachrysoidine", "code": "51283942" },
      { "name": "Sulfacetamide sodium anhydrous", "code": "51283943" },
      { "name": "Sulfaclomide", "code": "51283944" },
      { "name": "Sulfadiazine silver", "code": "51283945" },
      { "name": "Sulfamonomethoxine", "code": "51283946" },
      { "name": "Sulfadiazine sodium", "code": "51283947" },
      { "name": "Sulfanitran", "code": "51283948" },
      { "name": "Sulfamerazine sodium", "code": "51283949" },
      { "name": "Sulfaproxyline", "code": "51283950" },
      { "name": "Sulfamethoxazole sodium", "code": "51283951" },
      { "name": "Sulfarsphenamine", "code": "51283952" },
      { "name": "Sulfaquinoxaline sodium", "code": "51283953" },
      { "name": "Vanyldisulfamide", "code": "51283954" },
      { "name": "Sulfathiazole sodium", "code": "51283955" },
      { "name": "Sulfathiazole sodium monohydrate", "code": "51283956" },
      { "name": "Sulfathiazole zinc", "code": "51283957" },
      { "name": "Sulfisomidine sodium", "code": "51283958" },
      { "name": "Benzylsulfamide", "code": "51283959" },
      { "name": "Ditolamide", "code": "51283960" },
      { "name": "Glucosulfamide", "code": "51283961" },
      { "name": "Mesulfamide", "code": "51283962" },
      { "name": "Phthalylsulfamethizole", "code": "51283963" },
      { "name": "Tetracyclines", "code": "51284000" },
      { "name": "Apicycline", "code": "51284001" },
      { "name": "Chlortetracycline", "code": "51284002" },
      { "name": "Clomocycline", "code": "51284003" },
      { "name": "Demeclocycline", "code": "51284004" },
      { "name": "Demecycline", "code": "51284005" },
      { "name": "Doxycycline", "code": "51284006" },
      { "name": "Etamocycline", "code": "51284007" },
      { "name": "Guamecycline", "code": "51284008" },
      { "name": "Lymecycline", "code": "51284009" },
      { "name": "Meclocycline", "code": "51284010" },
      { "name": "Metacycline or methacycline", "code": "51284011" },
      { "name": "Minocycline", "code": "51284012" },
      { "name": "Nitrocycline", "code": "51284013" },
      { "name": "Oxytetracycline", "code": "51284014" },
      { "name": "Pipacycline", "code": "51284015" },
      { "name": "Rolitetracycline", "code": "51284016" },
      { "name": "Tetracycline", "code": "51284017" },
      { "name": "Chlortetracycline bisulfate", "code": "51284018" },
      { "name": "Chlortetracycline calcium", "code": "51284019" },
      { "name": "Chlortetracycline hydrochloride", "code": "51284020" },
      { "name": "Clomocycline sodium", "code": "51284021" },
      { "name": "Demeclocycline calcium", "code": "51284022" },
      { "name": "Demeclocycline hydrochloride", "code": "51284023" },
      { "name": "Doxycycline anhydrous", "code": "51284024" },
      { "name": "Doxycycline calcium", "code": "51284025" },
      { "name": "Doxycycline fosfatex", "code": "51284026" },
      { "name": "Doxycycline hyclate", "code": "51284027" },
      { "name": "Doxycycline hydrochloride", "code": "51284028" },
      {
        "name": "Doxycycline hydrochloride hemiethanolate hemihydrate",
        "code": "51284029"
      },
      { "name": "Doxycycline monohydrate", "code": "51284030" },
      { "name": "Doxycycline phosphate", "code": "51284031" },
      { "name": "Guamecycline dihydrochloride", "code": "51284032" },
      { "name": "Meclocycline sulfosalicylate", "code": "51284033" },
      { "name": "Methacycline hydrochloride", "code": "51284034" },
      { "name": "Minocycline hydrochloride", "code": "51284035" },
      { "name": "Oxytetracycline anhydrous", "code": "51284036" },
      { "name": "Oxytetracycline calcium", "code": "51284037" },
      { "name": "Oxytetracycline dihydrate", "code": "51284038" },
      { "name": "Oxytetracycline hydrochloride", "code": "51284039" },
      { "name": "Rolitetracycline nitrate", "code": "51284040" },
      { "name": "Rolitetracycline nitrate anhydrous", "code": "51284041" },
      { "name": "Tetracycline guaiacolsulfonate", "code": "51284042" },
      { "name": "Tetracycline hydrochloride", "code": "51284043" },
      { "name": "Tetracycline metaphosphate", "code": "51284044" },
      { "name": "Tetracycline phosphate", "code": "51284045" },
      { "name": "Tetracycline phosphate complex", "code": "51284046" },
      { "name": "Tetracycline trihydrate", "code": "51284047" },
      { "name": "Third generation cephalosporins", "code": "51284100" },
      { "name": "Cefcapene", "code": "51284101" },
      { "name": "Cefdaloxime", "code": "51284102" },
      { "name": "Cefdinir", "code": "51284103" },
      { "name": "Cefditoren", "code": "51284104" },
      { "name": "Cefetamet", "code": "51284105" },
      { "name": "Cefixime", "code": "51284106" },
      { "name": "Cefmenoxime", "code": "51284107" },
      { "name": "Ceftazidime", "code": "51284108" },
      { "name": "Cefotaxime", "code": "51284109" },
      { "name": "Cefpimizole", "code": "51284110" },
      { "name": "Cefpiramide", "code": "51284111" },
      { "name": "Cefpodoxime", "code": "51284112" },
      { "name": "Cefsulodin", "code": "51284113" },
      { "name": "Cefteram", "code": "51284114" },
      { "name": "Ceftibuten", "code": "51284115" },
      { "name": "Ceftiofur", "code": "51284116" },
      { "name": "Ceftiolene", "code": "51284117" },
      { "name": "Ceftioxide", "code": "51284118" },
      { "name": "Ceftriaxone", "code": "51284119" },
      { "name": "Ceftizoxime", "code": "51284120" },
      { "name": "Cefoperazone", "code": "51284121" },
      { "name": "Cefodizime", "code": "51284122" },
      { "name": "Cefcapene pivoxil", "code": "51284123" },
      { "name": "Cefcapene pivoxil hydrochloride", "code": "51284124" },
      { "name": "Cefditoren pivaloyloxymethyl ester", "code": "51284125" },
      { "name": "Cefditoren pivoxil", "code": "51284126" },
      { "name": "Cefetamet pivaloyloxymethyl ester", "code": "51284127" },
      { "name": "Cefetamet pivoxil", "code": "51284128" },
      { "name": "Cefetamet pivoxil hydrochloride", "code": "51284129" },
      { "name": "Cefixime anhydrous", "code": "51284130" },
      { "name": "Cefixime trihydrate", "code": "51284131" },
      { "name": "Cefmenoxime hydrochloride", "code": "51284132" },
      { "name": "Cefodizime disodium", "code": "51284133" },
      { "name": "Cefodizime sodium", "code": "51284134" },
      { "name": "Cefoperazone sodium", "code": "51284135" },
      { "name": "Cefotaxime sodium", "code": "51284136" },
      { "name": "Cefpimizole sodium", "code": "51284137" },
      { "name": "Cefpiramide sodium", "code": "51284138" },
      { "name": "Cefpodoxime proxetil", "code": "51284139" },
      { "name": "Cefsulodin sodium", "code": "51284140" },
      { "name": "Ceftazidime pentahydrate", "code": "51284141" },
      { "name": "Ceftazidime sodium", "code": "51284142" },
      { "name": "Cefteram pivaloyloxymethyl ester", "code": "51284143" },
      { "name": "Cefteram pivoxil", "code": "51284144" },
      { "name": "Ceftibuten dihydrate", "code": "51284145" },
      { "name": "Ceftiofur hydrochloride", "code": "51284146" },
      { "name": "Ceftiofur sodium", "code": "51284147" },
      { "name": "Ceftizoxime alapivoxil", "code": "51284148" },
      { "name": "Ceftizoxime sodium", "code": "51284149" },
      { "name": "Ceftriaxone sodium", "code": "51284150" },
      { "name": "Amphenicols", "code": "51284300" },
      { "name": "Azidamfenicol", "code": "51284301" },
      { "name": "Florfenicol", "code": "51284302" },
      { "name": "Thiamphenicol", "code": "51284303" },
      { "name": "Racefenicol or racephenicol", "code": "51284304" },
      {
        "name": "Thiamphenicol aminoacetate hydrochloride",
        "code": "51284305"
      },
      { "name": "Thiamphenicol glycinate", "code": "51284306" },
      { "name": "Thiamphenicol glycinate hydrochloride", "code": "51284307" },
      { "name": "Fifth generation cephalosporins", "code": "51284400" },
      { "name": "Ceftaroline fosamil", "code": "51284401" },
      { "name": "Ceftobiprole", "code": "51284402" },
      { "name": "Ceftobiprole medocaril", "code": "51284403" },
      {
        "name": "Lincomycins and monobactam antibacterials and polymyxin antibacterials and polysaccharide antibacterials",
        "code": "51284500"
      },
      { "name": "Lincomycin", "code": "51284501" },
      { "name": "Aztreonam", "code": "51284502" },
      { "name": "Colistin", "code": "51284503" },
      { "name": "Bambermycin", "code": "51284504" },
      { "name": "Aztreonam lysine", "code": "51284505" },
      { "name": "Colistin sulfate", "code": "51284506" },
      { "name": "Lincomycin hydrochloride", "code": "51284507" },
      { "name": "Lincomycin hydrochloride anhydrous", "code": "51284508" },
      { "name": "Lincomycin hydrochloride monohydrate", "code": "51284509" },
      { "name": "Carbapenems", "code": "51284600" },
      { "name": "Biapenem", "code": "51284601" },
      { "name": "Doripenem", "code": "51284602" },
      { "name": "Ertapenem", "code": "51284603" },
      { "name": "Imipenem", "code": "51284604" },
      { "name": "Meropenem", "code": "51284605" },
      { "name": "Panipenem", "code": "51284606" },
      { "name": "Doripenem hydrate", "code": "51284607" },
      { "name": "Doripenem monohydrate", "code": "51284608" },
      { "name": "Ertapenem disodium", "code": "51284609" },
      { "name": "Ertapenem sodium", "code": "51284610" },
      { "name": "Imipenem anhydrous", "code": "51284611" },
      { "name": "Meropenem anhydrous", "code": "51284612" },
      { "name": "Meropenem trihydrate", "code": "51284613" },
      { "name": "Pleuromutilin antibacterials", "code": "51284700" },
      { "name": "Xibornol", "code": "51284701" },
      { "name": "Cloxiqine or cloxyquin", "code": "51284703" },
      { "name": "Midecamycin", "code": "51284704" },
      { "name": "Penoctonium", "code": "51284705" },
      { "name": "Retapamulin", "code": "51284706" },
      { "name": "Distamycin a or stallimycin", "code": "51284708" },
      { "name": "Tiamulin", "code": "51284709" },
      { "name": "Valnemulin", "code": "51284710" },
      { "name": "Midecamycin acetate", "code": "51284711" },
      { "name": "Penoctonium bromide", "code": "51284712" },
      { "name": "Stallimycin hydrochloride", "code": "51284713" },
      { "name": "Sulfadiasulfone sodium", "code": "51284714" },
      { "name": "Tiamulin fumarate", "code": "51284715" },
      { "name": "Tiamulin hydrogen fumarate", "code": "51284716" },
      { "name": "Azamulin", "code": "51284717" },
      { "name": "Combination antibacterials", "code": "51284800" },
      { "name": "Benzalkonium/lidocaine", "code": "51284801" },
      {
        "name": "Benzethonium chloride/corn starch/kaolin/zinc oxide",
        "code": "51284802"
      },
      {
        "name": "Camphor/corn starch/eucalyptus/kaolin/triclosan/zinc oxide",
        "code": "51284803"
      },
      {
        "name": "Chlorhexidine gluconate/ispropyl alcohol",
        "code": "51284804"
      },
      { "name": "Ichthammol/lanolin/petrolatum", "code": "51284805" },
      { "name": "Iodine/potassium iodide", "code": "51284806" },
      { "name": "Iodine/sodium iodide", "code": "51284807" },
      { "name": "Combination antimicrobials", "code": "51284900" },
      { "name": "Sulfamethizole/trimethoprim", "code": "51284901" },
      { "name": "Aztreonam/dextrose", "code": "51284902" },
      { "name": "Cilastatin/imipenem", "code": "51284903" },
      { "name": "Methenamine/potassium phosphate", "code": "51284904" },
      { "name": "Methenamine/sodium biphosphate", "code": "51284905" },
      { "name": "Methinamine/sodium salicylate", "code": "51284906" },
      { "name": "Phenazopyridine/sulfamethizole", "code": "51284907" },
      { "name": "Phenazopyridine/sulfamethoxazole", "code": "51284908" },
      { "name": "Phenazopyridine/sulfisoxazole", "code": "51284909" },
      {
        "name": "Sulfadiazine/sulfamerazine/sulfamethazine",
        "code": "51284910"
      },
      { "name": "Sulfamethoxazole/trimethoprim", "code": "51284911" },
      {
        "name": "Combination extended spectrum penicillins",
        "code": "51285000"
      },
      { "name": "Clavulanate/ticarcillin", "code": "51285001" },
      { "name": "Dextrose/ticarcillin", "code": "51285002" },
      { "name": "Piperacillin/tazobactam", "code": "51285003" },
      { "name": "Combination H. pylori agents", "code": "51285100" },
      { "name": "Amoxicillin/clarithromycin/lansoprazole", "code": "51285101" },
      {
        "name": "Bismuth subsalicylate/metronidazole/tetracycline",
        "code": "51285102"
      },
      { "name": "Bismuth/metronidazole/tetracycline", "code": "51285103" },
      {
        "name": "Dihydrofolate reductase inhibitor antibacterials",
        "code": "51285200"
      },
      { "name": "Baquiloprim", "code": "51285201" },
      { "name": "Epiroprim", "code": "51285202" },
      { "name": "Trimethoprim", "code": "51285203" },
      { "name": "Trimethoprim hydrochloride", "code": "51285204" },
      { "name": "Trimethoprim pamoate", "code": "51285205" },
      { "name": "Trimethoprim sulfate", "code": "51285206" },
      { "name": "Antibacterial nitrofurans", "code": "51285300" },
      { "name": "Ranbezolid", "code": "51285301" },
      { "name": "Furazolidone", "code": "51285302" },
      { "name": "Nifurquinazol", "code": "51285303" },
      { "name": "Nifurtoinol", "code": "51285304" },
      { "name": "Nifurzide", "code": "51285305" },
      { "name": "Furalazine", "code": "51285306" },
      { "name": "Nitrofurantoin", "code": "51285307" },
      { "name": "Furaltadone", "code": "51285308" },
      { "name": "Furaltadone hydrochloride", "code": "51285309" },
      { "name": "Nifuradene", "code": "51285310" },
      { "name": "Nifuralide", "code": "51285311" },
      { "name": "Nifurethazone", "code": "51285312" },
      { "name": "Nifurimide", "code": "51285313" },
      { "name": "Nifurizone", "code": "51285314" },
      { "name": "Nifurmazole", "code": "51285315" },
      { "name": "Nifuroxime", "code": "51285316" },
      { "name": "Nifurpirinol", "code": "51285317" },
      { "name": "Nifurvidine", "code": "51285318" },
      { "name": "A-generational cephalosporins", "code": "51285400" },
      { "name": "Cefaloram", "code": "51285401" },
      { "name": "Cefedrolor", "code": "51285402" },
      { "name": "Cefivitril", "code": "51285403" },
      { "name": "Cefoxazole", "code": "51285404" },
      { "name": "Cefrotil", "code": "51285405" },
      { "name": "Cefuracetime", "code": "51285406" },
      { "name": "Cefcanel", "code": "51285407" },
      { "name": "Cefmatilen", "code": "51285408" },
      { "name": "Cefsumide", "code": "51285409" },
      { "name": "Antibacterials, synthesized", "code": "51285500" },
      { "name": "Clazuril", "code": "51285501" },
      { "name": "Clofoctol", "code": "51285502" },
      { "name": "Ditophal", "code": "51285503" },
      { "name": "Fusidate or fusidic acid", "code": "51285504" },
      { "name": "Oxibetaine", "code": "51285505" },
      { "name": "Cefempidone", "code": "51285506" },
      { "name": "Heliomycin", "code": "51285507" },
      { "name": "Antibacterial alcohols", "code": "51285600" },
      { "name": "Azacosterol", "code": "51285601" },
      { "name": "Azacosterol hydrochloride", "code": "51285602" },
      { "name": "Delmopinol", "code": "51285603" },
      { "name": "Delmopinol hydrochloride", "code": "51285604" },
      { "name": "Lasalocid", "code": "51285605" },
      { "name": "Lasalocid a", "code": "51285606" },
      { "name": "Lasalocid sodium", "code": "51285607" },
      { "name": "Antibacterial amides", "code": "51285700" },
      { "name": "Betamipron", "code": "51285701" },
      { "name": "Tolfamide", "code": "51285702" },
      { "name": "Antibacterial amidines", "code": "51285800" },
      { "name": "Aconiazide", "code": "51285801" },
      { "name": "Hexamidine", "code": "51285802" },
      { "name": "Hexamidine diisethionate", "code": "51285803" },
      { "name": "Hexamine mandelate", "code": "51285804" },
      { "name": "Hexamine or methenamine", "code": "51285805" },
      { "name": "Opiniazide", "code": "51285806" },
      { "name": "Antibacterial amines", "code": "51285900" },
      { "name": "Benurestat", "code": "51285901" },
      { "name": "Cilastatin", "code": "51285902" },
      { "name": "Cilastatin sodium", "code": "51285903" },
      { "name": "Dectaflur", "code": "51285904" },
      { "name": "Etisomicin", "code": "51285905" },
      {
        "name": "Hexamethylenetetramine or methenamine hippurate",
        "code": "51285906"
      },
      { "name": "Methenamine mandelate", "code": "51285907" },
      { "name": "Pirtenidine", "code": "51285908" },
      { "name": "Antibacterial arsenicals", "code": "51286000" },
      { "name": "Dichlorophenarsine", "code": "51286001" },
      { "name": "Antibacterial azoles", "code": "51286100" },
      { "name": "Enoxamast", "code": "51286101" },
      { "name": "Hexedine", "code": "51286102" },
      {
        "name": "Antibacterial organophosphorus compounds",
        "code": "51286200"
      },
      { "name": "Fosfomycin", "code": "51286201" },
      { "name": "Fosfomycin calcium", "code": "51286202" },
      { "name": "Fosfomycin calcium monohydrate", "code": "51286203" },
      { "name": "Fosfomycin disodium", "code": "51286204" },
      { "name": "Fosfomycin sodium", "code": "51286205" },
      { "name": "Fosfomycin trometamol", "code": "51286206" },
      { "name": "Fosfomycin tromethamine", "code": "51286207" },
      { "name": "Fosmidomycin", "code": "51286208" },
      { "name": "Antibacterial piperazines", "code": "51286300" },
      { "name": "Picloxydine", "code": "51286301" },
      { "name": "Picloxydine dihydrochloride", "code": "51286302" },
      { "name": "Antibacterial piperidines", "code": "51286400" },
      { "name": "Octapinol", "code": "51286401" },
      { "name": "Pecocycline", "code": "51286402" },
      { "name": "Antibacterial pyrans", "code": "51286500" },
      { "name": "Mupirocin", "code": "51286501" },
      { "name": "Mupirocin calcium", "code": "51286502" },
      { "name": "Narasin", "code": "51286503" },
      { "name": "Novobiocin", "code": "51286504" },
      { "name": "Novobiocin calcium", "code": "51286505" },
      { "name": "Antibacterial pyridines", "code": "51286600" },
      { "name": "Efrotomycin", "code": "51286601" },
      { "name": "Ftivazide", "code": "51286602" },
      { "name": "Antibacterial pyrimidines", "code": "51286700" },
      { "name": "Aditoprim", "code": "51286701" },
      { "name": "Brodimoprim", "code": "51286702" },
      { "name": "Iclaprim", "code": "51286703" },
      { "name": "Metioprim", "code": "51286704" },
      { "name": "Ormetoprim", "code": "51286705" },
      { "name": "Talmetoprim", "code": "51286706" },
      { "name": "Tetroxoprim", "code": "51286707" },
      { "name": "Vaneprim", "code": "51286708" },
      { "name": "Quinoline antibacterials", "code": "51286800" },
      { "name": "Chlorquinaldol", "code": "51286801" },
      { "name": "Iclaprim mesilate or mesylate", "code": "51286802" },
      { "name": "Nitroxoline", "code": "51286803" },
      { "name": "Proquinolate", "code": "51286804" },
      { "name": "Antibacterial quinoxalines", "code": "51286900" },
      { "name": "Carbadox", "code": "51286901" },
      { "name": "Cinoquidox", "code": "51286902" },
      { "name": "Drazidox", "code": "51286903" },
      { "name": "Levcycloserine", "code": "51286904" },
      { "name": "Mequidox", "code": "51286905" },
      { "name": "Olaquindox", "code": "51286906" },
      { "name": "Antibacterial sulfones", "code": "51287000" },
      { "name": "Acedapsone", "code": "51287001" },
      { "name": "Acetosulfone or sulfadiasulfone", "code": "51287002" },
      { "name": "Chaulmosulfone", "code": "51287003" },
      { "name": "Dapsone", "code": "51287004" },
      { "name": "Diathymosulfone", "code": "51287005" },
      { "name": "Glucosulfone", "code": "51287006" },
      { "name": "Glucosulfone sodium", "code": "51287007" },
      { "name": "Solasulfone", "code": "51287008" },
      { "name": "Antibacterial ureas", "code": "51287009" },
      { "name": "Dicloralurea", "code": "51287010" },
      { "name": "Etocarlide", "code": "51287011" },
      { "name": "Antidepressants", "code": "51290000" },
      { "name": "Antidepressant azoles", "code": "51291500" },
      { "name": "Feprosidnine", "code": "51291501" },
      { "name": "Almoxatone", "code": "51291502" },
      { "name": "Befloxatone", "code": "51291503" },
      { "name": "Cimoxatone", "code": "51291504" },
      { "name": "Cyclazodone", "code": "51291505" },
      { "name": "Deximafen", "code": "51291506" },
      { "name": "Ebalzotan", "code": "51291507" },
      { "name": "Efetozole", "code": "51291508" },
      { "name": "Fenmetozole", "code": "51291509" },
      { "name": "Fezolamine", "code": "51291510" },
      { "name": "Isocarboxazid", "code": "51291511" },
      { "name": "Nefazodone", "code": "51291512" },
      { "name": "Toloxatone", "code": "51291513" },
      { "name": "Fenmetozole hydrochloride", "code": "51291514" },
      { "name": "Fezolamine fumarate", "code": "51291515" },
      { "name": "Nefazodone hydrochloride", "code": "51291516" },
      { "name": "Antidepressant acridines", "code": "51291600" },
      { "name": "Dimetacrine", "code": "51291601" },
      { "name": "Monometacrine", "code": "51291602" },
      { "name": "Dimetacrine bitartrate", "code": "51291603" },
      { "name": "Antidepressant alcohols", "code": "51291700" },
      { "name": "Venlafaxine", "code": "51291701" },
      { "name": "Cericlamine", "code": "51291702" },
      { "name": "Clemeprol", "code": "51291703" },
      { "name": "Desvenlafaxine", "code": "51291704" },
      { "name": "Fenpentadiol", "code": "51291705" },
      { "name": "Flerobuterol", "code": "51291706" },
      { "name": "Isamoltan", "code": "51291707" },
      { "name": "Setazindol", "code": "51291708" },
      { "name": "Bipenamol", "code": "51291709" },
      { "name": "Bipenamol hydrochloride", "code": "51291710" },
      { "name": "Desvenlafaxine succinate", "code": "51291711" },
      { "name": "Venlafaxine hydrochloride", "code": "51291712" },
      { "name": "Antidepressant amides", "code": "51291800" },
      { "name": "Agomelatine", "code": "51291801" },
      { "name": "Eclanamine", "code": "51291802" },
      { "name": "Moclobemide", "code": "51291803" },
      { "name": "Nitrafudam", "code": "51291804" },
      { "name": "Eclanamine maleate", "code": "51291805" },
      { "name": "Nitrafudam hydrochloride", "code": "51291806" },
      {
        "name": "Antidepressant amidines and amino acids and aminoacridines and angiosperms",
        "code": "51291900"
      },
      { "name": "Napactadine", "code": "51291901" },
      { "name": "Alaproclate", "code": "51291902" },
      { "name": "Nitracrine", "code": "51291903" },
      { "name": "Hypericum or saint johns wart", "code": "51291904" },
      { "name": "Napactadine hydrochloride", "code": "51291905" },
      { "name": "Nitracrine dihydrochloride", "code": "51291906" },
      { "name": "Antidepressant amines", "code": "51292000" },
      { "name": "Adrafinil", "code": "51292001" },
      { "name": "Amiflamine", "code": "51292002" },
      { "name": "Ansoxetine", "code": "51292003" },
      { "name": "Citalopram", "code": "51292004" },
      { "name": "Clorgiline", "code": "51292005" },
      { "name": "Dapoxetine", "code": "51292006" },
      { "name": "Melitracen", "code": "51292007" },
      { "name": "Diisopromine or disoprominum", "code": "51292008" },
      { "name": "Escitalopram", "code": "51292009" },
      { "name": "Flunamine", "code": "51292010" },
      { "name": "Fluoxetine", "code": "51292011" },
      { "name": "Fluoxetine hydrochloride", "code": "51292012" },
      { "name": "Fluvoxamine", "code": "51292013" },
      { "name": "Medifoxamine", "code": "51292014" },
      { "name": "Nisoxetine", "code": "51292015" },
      { "name": "Sertraline", "code": "51292016" },
      { "name": "Talopram", "code": "51292017" },
      { "name": "Tranylcypromine", "code": "51292018" },
      { "name": "Citalopram hydrobromide", "code": "51292019" },
      { "name": "Citalopram hydrochloride", "code": "51292020" },
      { "name": "Dapoxetine hydrochloride", "code": "51292021" },
      { "name": "Diisopromine hydrochloride", "code": "51292022" },
      { "name": "Escitalopram oxalate", "code": "51292023" },
      { "name": "Fluvoxamine maleate", "code": "51292024" },
      { "name": "Medifoxamine fumarate", "code": "51292025" },
      { "name": "Melitracen hydrochloride", "code": "51292026" },
      { "name": "Sertraline hydrochloride", "code": "51292027" },
      { "name": "Talopram hydrochloride", "code": "51292028" },
      { "name": "Tranylcypromine sulfate", "code": "51292029" },
      { "name": "Tranylcypromine sulphate", "code": "51292030" },
      { "name": "Antidepressant anthracenes", "code": "51292100" },
      { "name": "Danitracen", "code": "51292101" },
      { "name": "Levoprotiline", "code": "51292102" },
      { "name": "Litracen", "code": "51292103" },
      { "name": "Maprotiline", "code": "51292104" },
      { "name": "Levoprotiline hydrochloride", "code": "51292105" },
      { "name": "Maprotiline hydrochloride", "code": "51292106" },
      { "name": "Maprotiline mesilate or mesylate", "code": "51292107" },
      { "name": "Antidepressant benzamides", "code": "51292200" },
      { "name": "Etacepride", "code": "51292201" },
      { "name": "Flubepride", "code": "51292202" },
      { "name": "Irolapride", "code": "51292203" },
      { "name": "Itopride", "code": "51292204" },
      { "name": "Mosapride", "code": "51292205" },
      { "name": "Sevopramide", "code": "51292206" },
      { "name": "Itopride hydrochloride", "code": "51292207" },
      { "name": "Mosapride citrate", "code": "51292208" },
      {
        "name": "Antidepressant benzazepines and benzoates and benzothiazoles and benzylamines",
        "code": "51292300"
      },
      { "name": "Mosapramine", "code": "51292301" },
      { "name": "Dembrexine", "code": "51292302" },
      { "name": "Tropanserin", "code": "51292303" },
      { "name": "Etrabamine", "code": "51292304" },
      { "name": "Mosapramine dihydrochloride", "code": "51292305" },
      { "name": "Tropanserin hydrochloride", "code": "51292306" },
      { "name": "Antidepressant benzene derivatives", "code": "51292400" },
      { "name": "Bifemelane", "code": "51292401" },
      { "name": "Fengabine", "code": "51292402" },
      { "name": "Bifemelane hydrochloride", "code": "51292403" },
      { "name": "Antidepressant benzimidazoles", "code": "51292500" },
      { "name": "Flibanserin", "code": "51292501" },
      { "name": "Itasetron", "code": "51292502" },
      { "name": "Antidepressant benzodiazepines", "code": "51292600" },
      { "name": "Clorazepate", "code": "51292601" },
      { "name": "Zomebazam", "code": "51292602" },
      { "name": "Aptazapine", "code": "51292603" },
      { "name": "Ftormetazine", "code": "51292604" },
      { "name": "Aptazapine maleate", "code": "51292605" },
      { "name": "Clorazepate dipotassium", "code": "51292606" },
      { "name": "Clorazepate monopotassium", "code": "51292607" },
      {
        "name": "Antidepressant bicyclo compounds and carbamates and carbolines and cinnamates",
        "code": "51292700"
      },
      { "name": "Cilobamine", "code": "51292701" },
      { "name": "Encyprate", "code": "51292702" },
      { "name": "Metralindole", "code": "51292703" },
      { "name": "Cinanserin", "code": "51292704" },
      { "name": "Cilobamine mesilate or mesylate", "code": "51292705" },
      { "name": "Cinanserin hydrochloride", "code": "51292706" },
      { "name": "Metralindole hydrochloride", "code": "51292707" },
      {
        "name": "Antidepressant cycloparaffins and dibenzoxazepines and dibenzoxepins and ergolines",
        "code": "51292800"
      },
      { "name": "Milnacipran", "code": "51292801" },
      { "name": "Amoxapine", "code": "51292802" },
      { "name": "Doxepin", "code": "51292803" },
      { "name": "Lysergide", "code": "51292804" },
      { "name": "Doxepin hydrochloride", "code": "51292805" },
      { "name": "Milnacipran hydrochloride", "code": "51292806" },
      { "name": "Antidepressant dibenzazepines", "code": "51292900" },
      { "name": "Cianopramine", "code": "51292901" },
      { "name": "Clomipramine", "code": "51292902" },
      { "name": "Depramine", "code": "51292903" },
      { "name": "Desipramine", "code": "51292904" },
      { "name": "Desipramine hydrochloride", "code": "51292905" },
      { "name": "Homopipramol", "code": "51292906" },
      { "name": "Imipramine or melipramine or prazepine", "code": "51292907" },
      { "name": "Mirtazapine", "code": "51292908" },
      { "name": "Lofepramine", "code": "51292909" },
      { "name": "Maroxepin", "code": "51292910" },
      { "name": "Metapramine", "code": "51292911" },
      { "name": "Mianserin", "code": "51292912" },
      { "name": "Opipramol", "code": "51292913" },
      { "name": "Tiracizine", "code": "51292914" },
      { "name": "Trimipramine", "code": "51292915" },
      { "name": "Dibenzepin", "code": "51292916" },
      { "name": "Clomipramine hydrochloride", "code": "51292917" },
      { "name": "Desipramine trifluoroacetate", "code": "51292918" },
      { "name": "Dibenzepin hydrochloride", "code": "51292919" },
      { "name": "Imipramine embonate", "code": "51292920" },
      { "name": "Imipramine hydrochloride", "code": "51292921" },
      { "name": "Imipramine pamoate", "code": "51292922" },
      { "name": "Lofepramine hydrochloride", "code": "51292923" },
      { "name": "Mianserin hydrochloride", "code": "51292924" },
      { "name": "Mirtazapine hemihydrate", "code": "51292925" },
      { "name": "Opipramol dihydrochloride", "code": "51292926" },
      { "name": "Opipramol hydrochloride", "code": "51292927" },
      { "name": "Tiracizine hydrochloride", "code": "51292928" },
      { "name": "Trimipramine maleate", "code": "51292929" },
      { "name": "Antidepressant dibenzocycloheptenes", "code": "51293000" },
      { "name": "Nortriptyline", "code": "51293001" },
      { "name": "Amitriptyline", "code": "51293002" },
      { "name": "Amitriptylinoxide", "code": "51293003" },
      { "name": "Benzaprinoxide", "code": "51293004" },
      { "name": "Butriptyline", "code": "51293005" },
      { "name": "Cotriptyline", "code": "51293006" },
      { "name": "Noxiptiline", "code": "51293007" },
      { "name": "Protriptyline", "code": "51293008" },
      { "name": "Amineptine", "code": "51293009" },
      { "name": "Amineptine hydrochloride", "code": "51293010" },
      { "name": "Amineptine sodium", "code": "51293011" },
      { "name": "Amitriptyline hydrochloride", "code": "51293012" },
      { "name": "Amitriptyline pamoate", "code": "51293013" },
      { "name": "Butriptyline hydrochloride", "code": "51293014" },
      { "name": "Nortriptyline hydrochloride", "code": "51293015" },
      { "name": "Noxiptiline hydrochloride", "code": "51293016" },
      { "name": "Protriptyline hydrochloride", "code": "51293017" },
      { "name": "Antidepressant glycolates", "code": "51293100" },
      { "name": "Adafenoxate", "code": "51293101" },
      { "name": "Iproclozide", "code": "51293102" },
      { "name": "Antidepressant hydrazines", "code": "51293200" },
      { "name": "Iproniazid or iproniazide", "code": "51293201" },
      { "name": "Phenelzine", "code": "51293202" },
      { "name": "Pheniprazine", "code": "51293203" },
      { "name": "Phenelzine sulfate", "code": "51293204" },
      { "name": "Phenelzine sulphate", "code": "51293205" },
      { "name": "Pheniprazine hydrochloride", "code": "51293206" },
      {
        "name": "Antidepressant imidazoles and isonicotinic acids and ketones and orphenadrine/analogs and derivatives and phenanthrenes",
        "code": "51293400"
      },
      { "name": "Cirazoline", "code": "51293401" },
      { "name": "Bupropion", "code": "51293402" },
      { "name": "Nialamide", "code": "51293403" },
      { "name": "Tofenacin", "code": "51293404" },
      { "name": "Deprodone", "code": "51293405" },
      { "name": "Deprodone propionate", "code": "51293406" },
      { "name": "Tofenacin hydrochloride", "code": "51293407" },
      { "name": "Antidepressant indenes", "code": "51293500" },
      { "name": "Pirandamine", "code": "51293501" },
      { "name": "Indatraline", "code": "51293502" },
      { "name": "Pirandamine hydrochloride", "code": "51293503" },
      { "name": "Antidepressant indoles", "code": "51293600" },
      { "name": "Roxindole", "code": "51293601" },
      { "name": "Amedalin", "code": "51293602" },
      { "name": "Amedalin hydrochloride", "code": "51293603" },
      { "name": "Azepindole", "code": "51293604" },
      { "name": "Binedaline", "code": "51293605" },
      { "name": "Ciclazindol", "code": "51293606" },
      { "name": "Ciclindole or cyclindole", "code": "51293607" },
      { "name": "Iprindole", "code": "51293608" },
      { "name": "Pirlindole", "code": "51293609" },
      { "name": "Tiflucarbine", "code": "51293610" },
      { "name": "Tipindole", "code": "51293611" },
      { "name": "Binedaline hydrochloride", "code": "51293612" },
      { "name": "Iprindole hydrochloride", "code": "51293613" },
      { "name": "Pirlindole hydrochloride", "code": "51293614" },
      { "name": "Roxindole mesilate", "code": "51293615" },
      { "name": "Antidepressant isoquinolines", "code": "51293700" },
      { "name": "Diclofensine", "code": "51293701" },
      { "name": "Nomifensine", "code": "51293702" },
      { "name": "Nomifensine maleate", "code": "51293703" },
      { "name": "Antidepressant pyrazines", "code": "51293800" },
      { "name": "Pirolazamide", "code": "51293801" },
      { "name": "Modaline", "code": "51293802" },
      { "name": "Modaline sulfate", "code": "51293803" },
      { "name": "Antidepressant methylamines", "code": "51293900" },
      { "name": "Oxabrexine", "code": "51293901" },
      { "name": "Cinfenine", "code": "51293902" },
      { "name": "Antidepressant morpholines", "code": "51294000" },
      { "name": "Aprepitant", "code": "51294001" },
      { "name": "Eprobemide", "code": "51294002" },
      { "name": "Antidepressant naphthalenes", "code": "51294100" },
      { "name": "Dexnafenodone", "code": "51294101" },
      { "name": "Nafenodone", "code": "51294102" },
      { "name": "Antidepressant oxazines", "code": "51294200" },
      { "name": "Bazinaprine", "code": "51294201" },
      { "name": "Caroxazone", "code": "51294202" },
      { "name": "Fenmetramide", "code": "51294203" },
      { "name": "Indeloxazine", "code": "51294204" },
      { "name": "Oxaflozane", "code": "51294205" },
      { "name": "Paraxazone", "code": "51294206" },
      { "name": "Reboxetine", "code": "51294207" },
      { "name": "Teniloxazine", "code": "51294208" },
      { "name": "Indeloxazine hydrochloride", "code": "51294209" },
      { "name": "Oxaflozane hydrochloride", "code": "51294210" },
      { "name": "Reboxetine mesilate or mesylate", "code": "51294211" },
      {
        "name": "Antidepressant phenothiazines and pregnanes and pyrrolidines and pyrrolidinones",
        "code": "51294300"
      },
      { "name": "Quisultazine", "code": "51294301" },
      { "name": "Losindole", "code": "51294302" },
      { "name": "Cotinine", "code": "51294303" },
      { "name": "Ganaxolone", "code": "51294304" },
      { "name": "Cotinine fumarate", "code": "51294305" },
      { "name": "Antidepressant piperazines", "code": "51294400" },
      { "name": "Mezilamine", "code": "51294401" },
      { "name": "Adatanserin", "code": "51294402" },
      { "name": "Aripiprazole", "code": "51294403" },
      { "name": "Befuraline", "code": "51294404" },
      { "name": "Delfaprazine", "code": "51294405" },
      { "name": "Etoperidone", "code": "51294406" },
      { "name": "Metoxepin", "code": "51294407" },
      { "name": "Oxypertine", "code": "51294408" },
      { "name": "Piberaline", "code": "51294409" },
      { "name": "Tebatizole", "code": "51294410" },
      { "name": "Trazitiline", "code": "51294411" },
      { "name": "Trazodone", "code": "51294412" },
      { "name": "Vanoxerine", "code": "51294413" },
      { "name": "Vilazodone", "code": "51294414" },
      { "name": "Adatanserin hydrochloride", "code": "51294415" },
      { "name": "Aripiprazole cavoxil", "code": "51294416" },
      { "name": "Aripiprazole lauroxil", "code": "51294417" },
      { "name": "Befuraline hydrochloride", "code": "51294418" },
      { "name": "Etoperidone hydrochloride", "code": "51294419" },
      { "name": "Oxypertine hydrochloride", "code": "51294420" },
      { "name": "Trazodone hydrochloride", "code": "51294421" },
      { "name": "Vilazodone hydrochloride", "code": "51294422" },
      { "name": "Antidepressant piperidines", "code": "51294500" },
      { "name": "Ivoqualine", "code": "51294501" },
      { "name": "Altanserin", "code": "51294502" },
      { "name": "Brofaromine", "code": "51294503" },
      { "name": "Enefexine", "code": "51294504" },
      { "name": "Femoxetine", "code": "51294505" },
      { "name": "Hepzidine", "code": "51294506" },
      { "name": "Ifoxetine", "code": "51294507" },
      { "name": "Litoxetine", "code": "51294508" },
      { "name": "Metioxate", "code": "51294509" },
      { "name": "Omiloxetine", "code": "51294510" },
      { "name": "Panuramine", "code": "51294511" },
      { "name": "Paroxetine", "code": "51294512" },
      { "name": "Paroxetine hydrochloride", "code": "51294513" },
      { "name": "Seganserin", "code": "51294514" },
      { "name": "Azaloxan", "code": "51294515" },
      { "name": "Altanserin tartrate", "code": "51294516" },
      { "name": "Azaloxan fumarate", "code": "51294517" },
      { "name": "Femoxetine hydrochloride", "code": "51294518" },
      { "name": "Paroxetine acetate", "code": "51294519" },
      { "name": "Paroxetine hydrochloride hemihydrate", "code": "51294520" },
      { "name": "Paroxetine hydrochloride hydrate", "code": "51294521" },
      { "name": "Paroxetine maleate", "code": "51294522" },
      { "name": "Paroxetine mesilate or mesylate", "code": "51294523" },
      { "name": "Antidepressant pyridazines", "code": "51294600" },
      { "name": "Minaprine", "code": "51294601" },
      { "name": "Pipofezine", "code": "51294602" },
      { "name": "Minaprine hydrochloride", "code": "51294603" },
      { "name": "Antidepressant pyridines", "code": "51294700" },
      { "name": "Dicarbine", "code": "51294701" },
      { "name": "Nomelidine", "code": "51294702" },
      { "name": "Pirisudanol or pyrisuccideanol", "code": "51294703" },
      { "name": "Antidepressant pyrimidines", "code": "51294800" },
      { "name": "Gepirone", "code": "51294801" },
      { "name": "Rofelodine", "code": "51294802" },
      { "name": "Gepirone hydrochloride", "code": "51294803" },
      { "name": "Antidepressant sulfur compounds", "code": "51294900" },
      { "name": "Tianeptine", "code": "51294901" },
      { "name": "Aceprometazine", "code": "51294902" },
      { "name": "Dosulepin", "code": "51294903" },
      { "name": "Ftorpropazine", "code": "51294904" },
      { "name": "Nuclotixene", "code": "51294905" },
      { "name": "Pizotifen or pizotyline", "code": "51294906" },
      { "name": "Tienopramine", "code": "51294907" },
      { "name": "Aceprometazine maleate", "code": "51294908" },
      { "name": "Dosulepin hydrochloride", "code": "51294909" },
      { "name": "Tianeptine sodium", "code": "51294910" },
      { "name": "Antidepressant thiazoles", "code": "51295000" },
      { "name": "Atibeprone", "code": "51295001" },
      { "name": "Dexamisole", "code": "51295002" },
      { "name": "Antidepressants, synthesized", "code": "51295100" },
      { "name": "Beloxepin", "code": "51295101" },
      { "name": "Clodazon", "code": "51295102" },
      { "name": "Dazadrol", "code": "51295103" },
      { "name": "Fantridone", "code": "51295104" },
      { "name": "Clodazon hydrochloride", "code": "51295105" },
      { "name": "Clodazon hydrochloride anhydrous", "code": "51295106" },
      { "name": "Dazadrol maleate", "code": "51295107" },
      { "name": "Fantridone hydrochloride", "code": "51295108" },
      {
        "name": "Antidepressant spiro compounds and thiophenes and triazines",
        "code": "51295300"
      },
      { "name": "Alnespirone", "code": "51295301" },
      { "name": "Eprovafen", "code": "51295302" },
      { "name": "Trazium esilate", "code": "51295303" },
      { "name": "Antifungal drugs", "code": "51300000" },
      { "name": "Antifungal alcohols", "code": "51301600" },
      { "name": "Chlorphenesin", "code": "51301601" },
      {
        "name": "Piroctone olamine or octopirox or piroctone ethanolamine",
        "code": "51301602"
      },
      { "name": "Chlorphenesin carbamate", "code": "51301603" },
      { "name": "Antifungal amides", "code": "51301700" },
      { "name": "Buclosamide", "code": "51301701" },
      { "name": "Exalamide", "code": "51301702" },
      { "name": "Antifungal amines", "code": "51301800" },
      { "name": "Butenafine", "code": "51301801" },
      { "name": "Butenafine hydrochloride", "code": "51301802" },
      { "name": "Naftifine", "code": "51301803" },
      { "name": "Naftifine hydrochloride", "code": "51301804" },
      {
        "name": "Antifungal aminoacridines and azoles and benzimidazoles and benzofurans",
        "code": "51301900"
      },
      { "name": "Acrisorcin", "code": "51301901" },
      { "name": "Saperconazole", "code": "51301902" },
      { "name": "Chlormidazole", "code": "51301903" },
      { "name": "Griseofulvin", "code": "51301904" },
      { "name": "Chlormidazole hydrochloride", "code": "51301905" },
      { "name": "Antifungal carboxylic acids", "code": "51302000" },
      { "name": "Caprylic acid or Octanoic acid", "code": "51302001" },
      { "name": "Sodium caprylate", "code": "51302002" },
      { "name": "Sulfiram", "code": "51302003" },
      { "name": "Tolnaftate", "code": "51302004" },
      { "name": "Tolciclate", "code": "51302005" },
      { "name": "Antifungal peptides", "code": "51302100" },
      { "name": "Oxifungin", "code": "51302101" },
      { "name": "Triafungin", "code": "51302102" },
      { "name": "Oxifungin hydrochloride", "code": "51302103" },
      { "name": "Antifungal imidazoles", "code": "51302300" },
      { "name": "Croconazole hydrochloride", "code": "51302301" },
      { "name": "Becliconazole", "code": "51302302" },
      { "name": "Bifonazole", "code": "51302303" },
      { "name": "Brolaconazole", "code": "51302304" },
      { "name": "Chlordantoin or clodantoin", "code": "51302305" },
      { "name": "Climbazole", "code": "51302306" },
      { "name": "Croconazole", "code": "51302307" },
      { "name": "Clotrimazole", "code": "51302308" },
      { "name": "Isoconazole", "code": "51302309" },
      { "name": "Democonazole", "code": "51302310" },
      { "name": "Doconazole", "code": "51302311" },
      { "name": "Eberconazole", "code": "51302312" },
      { "name": "Econazole", "code": "51302313" },
      { "name": "Enilconazole", "code": "51302314" },
      { "name": "Fenticonazole", "code": "51302315" },
      { "name": "Flutrimazole", "code": "51302316" },
      { "name": "Sertaconazole", "code": "51302317" },
      { "name": "Ketoconazole", "code": "51302318" },
      { "name": "Lanoconazole", "code": "51302319" },
      { "name": "Miconazole", "code": "51302320" },
      { "name": "Neticonazole", "code": "51302321" },
      { "name": "Omoconazole", "code": "51302322" },
      { "name": "Oxiconazole", "code": "51302323" },
      { "name": "Sulconazole", "code": "51302324" },
      { "name": "Tioconazole", "code": "51302325" },
      { "name": "Valconazole", "code": "51302326" },
      { "name": "Zoficonazole", "code": "51302327" },
      { "name": "Butoconazole", "code": "51302328" },
      { "name": "Abunidazole", "code": "51302329" },
      { "name": "Butoconazole nitrate", "code": "51302330" },
      { "name": "Econazole nitrate", "code": "51302331" },
      { "name": "Enilconazole for veterinary use", "code": "51302332" },
      { "name": "Enilconazole sulfate", "code": "51302333" },
      { "name": "Fenticonazole mononitrate", "code": "51302334" },
      { "name": "Fenticonazole nitrate", "code": "51302335" },
      { "name": "Isoconazole nitrate", "code": "51302336" },
      { "name": "Miconazole nitrate", "code": "51302337" },
      { "name": "Neticonazole hydrochloride", "code": "51302338" },
      { "name": "Omoconazole nitrate", "code": "51302339" },
      { "name": "Oxiconazole nitrate", "code": "51302340" },
      { "name": "Sertaconazole nitrate", "code": "51302341" },
      { "name": "Sulconazole nitrate", "code": "51302342" },
      { "name": "Antifungal macrolides", "code": "51302400" },
      { "name": "Amphotericin b", "code": "51302401" },
      { "name": "Candicidin", "code": "51302402" },
      { "name": "Natamycin", "code": "51302403" },
      { "name": "Nystatin", "code": "51302404" },
      { "name": "Candicidin d", "code": "51302405" },
      { "name": "Antifungal naphthalenes", "code": "51302500" },
      { "name": "Liranaftate", "code": "51302501" },
      { "name": "Terbinafine", "code": "51302502" },
      { "name": "Terbinafine hydrochloride", "code": "51302503" },
      {
        "name": "Antifungal organophosphorus compounds and oxazines and phenyl ethers and pyrans",
        "code": "51302600"
      },
      { "name": "Fosfluconazole", "code": "51302601" },
      { "name": "Amorolfine", "code": "51302602" },
      { "name": "Haloprogin", "code": "51302603" },
      { "name": "Ambruticin", "code": "51302604" },
      { "name": "Amorolfine hydrochloride", "code": "51302605" },
      { "name": "Antifungal phenols", "code": "51302700" },
      { "name": "Chlorocresol", "code": "51302701" },
      { "name": "Cresol", "code": "51302702" },
      { "name": "Antifungal pyridines", "code": "51302900" },
      { "name": "Ciclopirox", "code": "51302901" },
      { "name": "Rilopirox", "code": "51302902" },
      { "name": "Ciclopirox olamine", "code": "51302903" },
      { "name": "Antifungal pyrimidines", "code": "51303000" },
      { "name": "Flucytosine", "code": "51303001" },
      { "name": "Voriconazole", "code": "51303002" },
      {
        "name": "Antifungal pyrroles and pyrrolidinones and quinazolines and terpenes",
        "code": "51303100"
      },
      { "name": "Pyrrolnitrin", "code": "51303101" },
      { "name": "Pecilocin or variotin", "code": "51303102" },
      { "name": "Albaconazole", "code": "51303103" },
      { "name": "Thymol iodide", "code": "51303104" },
      { "name": "Antifungals, synthesized", "code": "51303200" },
      { "name": "Basifungin", "code": "51303201" },
      { "name": "Clofenoxyde", "code": "51303202" },
      { "name": "Lidimycin or lydimycin", "code": "51303203" },
      { "name": "Nifurmerone", "code": "51303204" },
      { "name": "Tolindate", "code": "51303205" },
      { "name": "Bensuldazic acid", "code": "51303206" },
      { "name": "Antifungal thiazoles", "code": "51303300" },
      { "name": "Diamthazole or dimazole", "code": "51303301" },
      { "name": "Fezatione", "code": "51303302" },
      { "name": "Ravuconazole", "code": "51303303" },
      { "name": "Ticlatone", "code": "51303304" },
      { "name": "Antifungal triazoles", "code": "51303400" },
      { "name": "Alteconazole", "code": "51303401" },
      { "name": "Fluconazole", "code": "51303402" },
      { "name": "Itraconazole", "code": "51303403" },
      { "name": "Posaconazole", "code": "51303404" },
      { "name": "Flucloronide", "code": "51303406" },
      { "name": "Terconazole", "code": "51303407" },
      { "name": "Combination antifungals", "code": "51303600" },
      {
        "name": "Benzoic acid/boric acid/zinc oxide/zinc stearate",
        "code": "51303601"
      },
      {
        "name": "Acetone/basic fuchsin/boric acid/resorcinol",
        "code": "51303602"
      },
      { "name": "Acetone/boric acid/resorcinol", "code": "51303603" },
      { "name": "Alcohol, isopropyl/undecylenic acid", "code": "51303604" },
      {
        "name": "Alcohol/benzocaine/choroxylenol/resorcinol/salicylic acid",
        "code": "51303605"
      },
      {
        "name": "Alcohol/benzoic acid/boric acid/zinc oxide/zinc stearate",
        "code": "51303606"
      },
      {
        "name": "Ammonia/benzoic/propionic/salicylic/sodium/sodium/undecylenic acid",
        "code": "51303607"
      },
      {
        "name": "Benzalkonium/cetylpyridinium/chloroxylenol/sodium/triacetin",
        "code": "51303608"
      },
      {
        "name": "Benzalkonium/cetylpyridinium/chloroxylenol/triacetin",
        "code": "51303609"
      },
      {
        "name": "Benzocaine/chloroxylenol/isopropyl/resorcinol/salicylic acid",
        "code": "51303610"
      },
      { "name": "Benzoic acid/salicylic acid", "code": "51303611" },
      { "name": "Calcium undecylenate/zinc undecylenate", "code": "51303612" },
      { "name": "Chloroxylenol/undecylenic acid", "code": "51303613" },
      { "name": "Diphenhydramine/lidocaine/nystatin", "code": "51303614" },
      { "name": "Miconazole/zinc", "code": "51303615" },
      { "name": "Undecylenic acid/zinc undecylenate", "code": "51303616" },
      {
        "name": "Combination odor control products, ostomy",
        "code": "51303700"
      },
      { "name": "Chlorophyll/thymol", "code": "51303701" },
      { "name": "Antihistamines or H1 blockers", "code": "51310000" },
      {
        "name": "Antihistamine or H1 blocker 4-quinolones and acetates and aminopyridines and azepines",
        "code": "51311600"
      },
      { "name": "Nedocromil", "code": "51311601" },
      { "name": "Efletirizine", "code": "51311602" },
      { "name": "Mepyramine or pyrilamine", "code": "51311603" },
      { "name": "Setastine", "code": "51311604" },
      { "name": "Efletirizine dihydrochloride", "code": "51311605" },
      { "name": "Mepyramine hydrochloride", "code": "51311606" },
      { "name": "Mepyramine maleate", "code": "51311607" },
      { "name": "Mepyramine tannate", "code": "51311608" },
      { "name": "Nedocromil calcium", "code": "51311609" },
      { "name": "Nedocromil sodium", "code": "51311610" },
      { "name": "Setastine hydrochloride", "code": "51311611" },
      {
        "name": "Antihistamine or H1 blocker benzhydryl compounds",
        "code": "51311700"
      },
      { "name": "Meclizine or meclozine", "code": "51311701" },
      { "name": "Phenyltoloxamine", "code": "51311702" },
      { "name": "Quifenadine", "code": "51311703" },
      { "name": "Phenyltoloxamine citrate", "code": "51311704" },
      { "name": "Phenyltoloxamine dihydrogen citrate", "code": "51311705" },
      { "name": "Phenyltoloxamine hydrochloride", "code": "51311706" },
      {
        "name": "Antihistamine or H1 blocker benzimidazoles",
        "code": "51311800"
      },
      { "name": "Astemizole", "code": "51311801" },
      { "name": "Bilastine", "code": "51311802" },
      { "name": "Clemizole", "code": "51311803" },
      { "name": "Emedastine", "code": "51311804" },
      { "name": "Mapinastine", "code": "51311805" },
      { "name": "Mizolastine", "code": "51311806" },
      { "name": "Clemizole hydrochloride", "code": "51311807" },
      { "name": "Clemizole sulfate", "code": "51311808" },
      { "name": "Clemizole sulphate", "code": "51311809" },
      { "name": "Clemizole undecylate", "code": "51311810" },
      { "name": "Emedastine difumarate", "code": "51311811" },
      { "name": "Emedastine fumarate", "code": "51311812" },
      { "name": "Antihistamine or H1 blocker benzopyrans", "code": "51311900" },
      { "name": "Cromoglicic acid or cromolyn", "code": "51311901" },
      { "name": "Probicromil calcium", "code": "51311902" },
      {
        "name": "Antihistamine or H1 blocker butyrophenones",
        "code": "51312000"
      },
      { "name": "Carebastine", "code": "51312001" },
      { "name": "Ebastine", "code": "51312002" },
      {
        "name": "Antihistamine or H1 blocker cyclic s-oxides and dibenzazepines and dibenzoxepins and glyoxylates",
        "code": "51312100"
      },
      { "name": "Oxomemazine", "code": "51312101" },
      { "name": "Epinastine", "code": "51312102" },
      { "name": "Olopatadine", "code": "51312103" },
      { "name": "Lodoxamide", "code": "51312104" },
      { "name": "Epinastine hydrochloride", "code": "51312105" },
      { "name": "Lodoxamide ethyl", "code": "51312106" },
      { "name": "Lodoxamide tromethamine", "code": "51312107" },
      { "name": "Olopatadine hydrochloride", "code": "51312108" },
      { "name": "Oxomemazine hydrochloride", "code": "51312109" },
      {
        "name": "Antihistamine or H1 blocker dibenzocycloheptenes",
        "code": "51312200"
      },
      { "name": "Azatadine", "code": "51312201" },
      { "name": "Cyproheptadine", "code": "51312202" },
      { "name": "Desloratadine", "code": "51312203" },
      { "name": "Loratadine", "code": "51312204" },
      { "name": "Rupatadine", "code": "51312205" },
      { "name": "Azatadine dimaleate", "code": "51312206" },
      { "name": "Azatadine maleate", "code": "51312207" },
      { "name": "Cyproheptadine hydrochloride", "code": "51312208" },
      {
        "name": "Cyproheptadine hydrochloride sesquihydrate",
        "code": "51312209"
      },
      { "name": "Rupatadine fumarate", "code": "51312210" },
      { "name": "Antihistamine or H1 blocker ethylamines", "code": "51312300" },
      { "name": "Bromazine or bromodiphenhydramine", "code": "51312301" },
      { "name": "Bufenadrine", "code": "51312302" },
      { "name": "Chlorphenoxamine", "code": "51312303" },
      { "name": "Diphenhydramine", "code": "51312304" },
      { "name": "Embramine", "code": "51312305" },
      { "name": "Bromazine hydrochloride", "code": "51312306" },
      { "name": "Chlorphenoxamine hydrochloride", "code": "51312307" },
      { "name": "Diphenhydramine citrate", "code": "51312308" },
      { "name": "Diphenhydramine hydrochloride", "code": "51312309" },
      { "name": "Diphenhydramine laurylsulfate", "code": "51312310" },
      { "name": "Diphenhydramine methiodide", "code": "51312311" },
      { "name": "Diphenhydramine methylbromide", "code": "51312312" },
      { "name": "Diphenhydramine salicylate", "code": "51312313" },
      { "name": "Diphenhydramine tannate", "code": "51312314" },
      { "name": "Embramine hydrochloride", "code": "51312315" },
      {
        "name": "Antihistamine or H1 blocker ethylenediamines",
        "code": "51312400"
      },
      { "name": "Chloropyramine", "code": "51312401" },
      { "name": "Methapyrilene", "code": "51312402" },
      { "name": "Tripelennamine", "code": "51312403" },
      { "name": "Chlorothen", "code": "51312404" },
      { "name": "Chloropyramine hydrochloride", "code": "51312405" },
      { "name": "Methapyrilene fumarate", "code": "51312406" },
      { "name": "Methapyrilene hydrochloride", "code": "51312407" },
      { "name": "Tripelennamine citrate", "code": "51312408" },
      { "name": "Tripelennamine hydrochloride", "code": "51312409" },
      {
        "name": "Antihistamine or H1 blocker guanidines and indenes and lactams and peptides and quinazolines",
        "code": "51312500"
      },
      { "name": "Arpromidine", "code": "51312501" },
      { "name": "Clobenzepam", "code": "51312502" },
      { "name": "Dimethindene or dimetindene", "code": "51312503" },
      { "name": "Tiacrilast", "code": "51312504" },
      { "name": "Spaglumic acid", "code": "51312505" },
      { "name": "Clobenzepam hydrochloride", "code": "51312506" },
      { "name": "Dimetindene maleate", "code": "51312507" },
      { "name": "Tiacrilast sodium", "code": "51312508" },
      { "name": "Antihistamine or H1 blocker imidazoles", "code": "51312600" },
      { "name": "Noberastine", "code": "51312601" },
      { "name": "Alcaftadine", "code": "51312602" },
      { "name": "Antazoline", "code": "51312603" },
      { "name": "Etintidine", "code": "51312604" },
      { "name": "Mifentidine", "code": "51312605" },
      { "name": "Nemazoline", "code": "51312606" },
      { "name": "Antazoline hydrochloride", "code": "51312607" },
      { "name": "Antazoline mesilate or mesylate", "code": "51312608" },
      { "name": "Antazoline phosphate", "code": "51312609" },
      { "name": "Antazoline sulfate", "code": "51312610" },
      { "name": "Etintidine hydrochloride", "code": "51312611" },
      { "name": "Nemazoline hydrochloride", "code": "51312612" },
      {
        "name": "Antihistamine or H1 blocker pyrrolidines",
        "code": "51312700"
      },
      { "name": "Clemastine", "code": "51312701" },
      { "name": "Histapyrrodine", "code": "51312702" },
      { "name": "Pyrrobutamine", "code": "51312703" },
      { "name": "Clemastine fumarate", "code": "51312704" },
      { "name": "Clemastine hydrogen fumarate", "code": "51312705" },
      { "name": "Histapyrrodine hydrochloride", "code": "51312706" },
      {
        "name": "Antihistamine or H1 blocker phenothiazines",
        "code": "51312800"
      },
      { "name": "Dacemazine", "code": "51312801" },
      { "name": "Dimetotiazine", "code": "51312802" },
      { "name": "Fenethazine", "code": "51312803" },
      { "name": "Mequitazine", "code": "51312804" },
      { "name": "Methdilazine", "code": "51312805" },
      { "name": "Methiomeprazine", "code": "51312806" },
      { "name": "Alimemazine or trimeprazine", "code": "51312807" },
      { "name": "Mequitamium iodide", "code": "51312808" },
      { "name": "Alimemazine tartrate", "code": "51312809" },
      { "name": "Dimetotiazine mesilate", "code": "51312810" },
      { "name": "Fenethazine hydrochloride", "code": "51312811" },
      { "name": "Methdilazine hydrochloride", "code": "51312812" },
      { "name": "Methiomeprazine hydrochloride", "code": "51312813" },
      {
        "name": "Antihistamine or H1 blocker phenanthrolines",
        "code": "51312900"
      },
      { "name": "Bufrolin", "code": "51312901" },
      { "name": "Etoloxamine", "code": "51312902" },
      {
        "name": "Antihistamine or H1 blocker phthalazines",
        "code": "51313000"
      },
      { "name": "Azelastine", "code": "51313001" },
      { "name": "Talastine", "code": "51313002" },
      { "name": "Azelastine hydrochloride", "code": "51313003" },
      { "name": "Talastine hydrochloride", "code": "51313004" },
      { "name": "Antihistamine or H1 blocker piperazines", "code": "51313100" },
      { "name": "Cetirizine", "code": "51313101" },
      { "name": "Chlorcyclizine", "code": "51313102" },
      { "name": "Clocinizine", "code": "51313103" },
      { "name": "Homochlorcyclizine", "code": "51313104" },
      { "name": "Hydroxyzine embonate", "code": "51313105" },
      { "name": "Levocetirizine", "code": "51313106" },
      { "name": "Oxatomide", "code": "51313107" },
      { "name": "Buclizine", "code": "51313108" },
      { "name": "Buclizine dihydrochloride", "code": "51313109" },
      { "name": "Buclizine hydrochloride", "code": "51313110" },
      { "name": "Cetirizine dihydrochloride", "code": "51313111" },
      { "name": "Cetirizine hydrochloride", "code": "51313112" },
      { "name": "Chlorcyclizine dihydrochloride", "code": "51313113" },
      { "name": "Chlorcyclizine hydrochloride", "code": "51313114" },
      { "name": "Chlorcyclizine monohydrochloride", "code": "51313115" },
      { "name": "Chlorcyclizine pamoate", "code": "51313116" },
      { "name": "Clocinizine hydrochloride", "code": "51313117" },
      { "name": "Homochlorcyclizine dihydrochloride", "code": "51313118" },
      { "name": "Homochlorcyclizine hydrochloride", "code": "51313119" },
      { "name": "Levocetirizine dihydrochloride", "code": "51313120" },
      { "name": "Antihistamine or H1 blocker piperidines", "code": "51313200" },
      { "name": "Piprinhydrinate", "code": "51313201" },
      { "name": "Bamipine", "code": "51313202" },
      { "name": "Bepotastine", "code": "51313203" },
      { "name": "Diphenylpyraline", "code": "51313204" },
      { "name": "Levocabastine", "code": "51313205" },
      { "name": "Pimethixene", "code": "51313206" },
      { "name": "Terfenadine", "code": "51313207" },
      { "name": "Tenaldin or thenaldine or thenalidine", "code": "51313208" },
      { "name": "Pirdonium", "code": "51313209" },
      { "name": "Cycliramine", "code": "51313210" },
      { "name": "Bamipine dihydrochloride", "code": "51313211" },
      { "name": "Bamipine hydrochloride", "code": "51313212" },
      { "name": "Bamipine lactate", "code": "51313213" },
      { "name": "Bamipine salicylate", "code": "51313214" },
      { "name": "Bepotastine besilate", "code": "51313215" },
      { "name": "Cycliramine maleate", "code": "51313216" },
      { "name": "Diphenylpyraline hydrochloride", "code": "51313217" },
      { "name": "Levocabastine hydrochloride", "code": "51313218" },
      { "name": "Pimethixene maleate", "code": "51313219" },
      { "name": "Pirdonium bromide", "code": "51313220" },
      {
        "name": "Antihistamine or H1 blocker propylamines",
        "code": "51313300"
      },
      { "name": "Promethazine", "code": "51313301" },
      { "name": "Thiazinam or thiazinamium", "code": "51313302" },
      { "name": "Tolpropamine", "code": "51313303" },
      { "name": "Promethazine hydrobromide", "code": "51313304" },
      { "name": "Promethazine hydrochloride", "code": "51313305" },
      { "name": "Promethazine sulfoxide", "code": "51313306" },
      { "name": "Promethazine teoclate", "code": "51313307" },
      { "name": "Thiazinamium metilsulfate", "code": "51313308" },
      { "name": "Tolpropamine hydrochloride", "code": "51313309" },
      { "name": "Antihistamine or H1 blocker pyridines", "code": "51313400" },
      {
        "name": "Chlorphenamine or chlorpheniramine or dexchlorpheniramine",
        "code": "51313401"
      },
      { "name": "Acrivastine", "code": "51313402" },
      { "name": "Betahistine", "code": "51313403" },
      { "name": "Brompheniramine", "code": "51313404" },
      { "name": "Carbinoxamine or rotoxamine", "code": "51313405" },
      { "name": "Mebhydrolin or mebhydroline", "code": "51313406" },
      { "name": "Dexbrompheniramine", "code": "51313407" },
      { "name": "Doxylamine", "code": "51313409" },
      { "name": "Triprolidine", "code": "51313410" },
      { "name": "Pemirolast", "code": "51313411" },
      { "name": "Phenindamine", "code": "51313412" },
      { "name": "Pheniramine", "code": "51313413" },
      { "name": "Thenyldiamine", "code": "51313414" },
      { "name": "Betahistine dihydrochloride", "code": "51313415" },
      { "name": "Betahistine hydrochloride", "code": "51313416" },
      { "name": "Betahistine mesilate", "code": "51313417" },
      { "name": "Brompheniramine maleate", "code": "51313418" },
      { "name": "Brompheniramine tannate", "code": "51313419" },
      { "name": "Carbinoxamine maleate", "code": "51313420" },
      { "name": "Chlorphenamine maleate", "code": "51313421" },
      { "name": "Chlorphenamine tannate", "code": "51313422" },
      { "name": "Chlorpheniramine hydrochloride", "code": "51313423" },
      { "name": "Chlorpheniramine maleate", "code": "51313424" },
      { "name": "Chlorpheniramine tannate", "code": "51313425" },
      { "name": "Dexbrompheniramine maleate", "code": "51313426" },
      { "name": "Dexbrompheniramine tannate", "code": "51313427" },
      { "name": "Dexchlorpheniramine maleate", "code": "51313428" },
      { "name": "Doxylamine chlorotheophyllinate", "code": "51313429" },
      { "name": "Doxylamine hydrogen succinate", "code": "51313430" },
      { "name": "Doxylamine succinate", "code": "51313431" },
      { "name": "Pemirolast potassium", "code": "51313432" },
      { "name": "Phenindamine hydrogen tartrate", "code": "51313433" },
      { "name": "Phenindamine tartrate", "code": "51313434" },
      { "name": "Pheniramine aminosalicylate", "code": "51313435" },
      { "name": "Pheniramine hydrochloride", "code": "51313436" },
      { "name": "Pheniramine hydrogen maleate", "code": "51313437" },
      { "name": "Pheniramine maleate", "code": "51313438" },
      { "name": "Thenyldiamine hydrochloride", "code": "51313439" },
      { "name": "Triprolidine hydrochloride", "code": "51313440" },
      { "name": "Triprolidine hydrochloride anhydrous", "code": "51313441" },
      { "name": "Triprolidine hydrochloride monohydrate", "code": "51313442" },
      {
        "name": "Antihistamine or H1 blockers, synthesized",
        "code": "51313500"
      },
      { "name": "Isocromil", "code": "51313501" },
      { "name": "Barmastine", "code": "51313502" },
      { "name": "Bepiastine", "code": "51313503" },
      { "name": "Dimetholizine", "code": "51313504" },
      { "name": "Dorastine", "code": "51313505" },
      { "name": "Eclazolast", "code": "51313506" },
      { "name": "Elbanizine", "code": "51313507" },
      { "name": "Etolotifen", "code": "51313508" },
      { "name": "Flotrenizine", "code": "51313509" },
      { "name": "Texacromil", "code": "51313510" },
      { "name": "Trenizine", "code": "51313511" },
      { "name": "Dimetholizine phosphate", "code": "51313512" },
      { "name": "Dorastine hydrochloride", "code": "51313513" },
      { "name": "Antihistamine or H1 blocker quinolines", "code": "51313600" },
      { "name": "Minocromil", "code": "51313601" },
      { "name": "Repirinast", "code": "51313602" },
      {
        "name": "Antihistamine or H1 blocker quinolizines and sulfonamides and thiazines and thiazoles and thiophenes",
        "code": "51313700"
      },
      { "name": "Quinotolast", "code": "51313701" },
      { "name": "Bosentan", "code": "51313702" },
      { "name": "Isothipendyl", "code": "51313703" },
      { "name": "Ketotifen", "code": "51313704" },
      { "name": "Zolamine", "code": "51313705" },
      { "name": "Bosentan hydrate", "code": "51313706" },
      { "name": "Bosentan monohydrate", "code": "51313707" },
      { "name": "Isothipendyl hydrochloride", "code": "51313708" },
      { "name": "Ketotifen fumarate", "code": "51313709" },
      { "name": "Zolamine hydrochloride", "code": "51313710" },
      {
        "name": "Antihistamine or H1 blocker terfenadine/analogs and derivatives",
        "code": "51313800"
      },
      { "name": "Fexofenadine", "code": "51313801" },
      { "name": "Fexofenadine hydrochloride", "code": "51313802" },
      { "name": "Antihistamine or H1 blocker tetrazoles", "code": "51313900" },
      { "name": "Eflumast", "code": "51313901" },
      { "name": "Tazanolast", "code": "51313902" },
      { "name": "Antihistamine or H1 blocker pyrimidines", "code": "51314100" },
      { "name": "Donetidine", "code": "51314101" },
      { "name": "Temelastine", "code": "51314102" },
      { "name": "Thonzylamine", "code": "51314103" },
      { "name": "Thonzylamine hydrochloride", "code": "51314104" },
      { "name": "Antihistamine or H1 blocker tropanes", "code": "51314200" },
      { "name": "Hypostamine or tritoqualine", "code": "51314201" },
      { "name": "Flutropium", "code": "51314202" },
      { "name": "Flutropium bromide", "code": "51314203" },
      {
        "name": "Combination antihistamines, with analgesics, antitussives, decongestants, or expectorants",
        "code": "51314300"
      },
      {
        "name": "Acetaminophen/dextromethorphan/phenylephrine/pyrilamine",
        "code": "51314301"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/dextromethorphan",
        "code": "51314302"
      },
      {
        "name": "Carbinoxamine/gauifenesin/pseudoephedrine",
        "code": "51314303"
      },
      {
        "name": "Carbinoxamine/methscopolamine/pseudoephedrine",
        "code": "51314304"
      },
      { "name": "Carbinoxamine/pseudoephedrine", "code": "51314305" },
      { "name": "Cetirizine/pseudoephedrine", "code": "51314306" },
      {
        "name": "Chlorpheniramine/citric acid/guaifenesin/phenylpropanolamine/sodium citrate",
        "code": "51314307"
      },
      { "name": "Chlorpheniramine/dextromethorphan", "code": "51314308" },
      {
        "name": "Chlorpheniramine/dextromethorphan/glycerol, iodinated",
        "code": "51314309"
      },
      {
        "name": "Chlorpheniramine/dextromethorphan/guaifenesin/phenylephrine",
        "code": "51314310"
      },
      {
        "name": "Brompheniramine/guaifenesin/menthol/phenylephrine/phenylpropanolamine",
        "code": "51314311"
      },
      { "name": "Acrivastine/pseudoephedrine", "code": "51314312" },
      {
        "name": "Ammonium chloride/chlorpheniramine/dextromethorphan/phenylephrine",
        "code": "51314313"
      },
      {
        "name": "Ascorbic acid/chlorpheniramine/phenylpropanolamine/pyrilamine",
        "code": "51314314"
      },
      {
        "name": "Aspirin/chlorpheniramine/dextromethorphan/phenylephrine",
        "code": "51314315"
      },
      { "name": "Azatadine/pseudoephedrine", "code": "51314316" },
      {
        "name": "Belladonna/chlorpheniramine/pheniramine/phenylpropanolamine",
        "code": "51314317"
      },
      {
        "name": "Bromphenhiramine/dextromethorphan/pseudoephedrine",
        "code": "51314318"
      },
      {
        "name": "Brompheniramine/dextromethorphan/phenylpropanolamine",
        "code": "51314319"
      },
      {
        "name": "Brompheniramine/dextromethorphan/pseudoephedrine",
        "code": "51314320"
      },
      { "name": "Carbetapentane/phenylephrine/pyrilamine", "code": "51314321" },
      {
        "name": "Brompheniramine/guaifenesin/phenylephrine/phenylpropanolamine",
        "code": "51314322"
      },
      {
        "name": "Brompheniramine/guaifenesin/pseudoephedrine",
        "code": "51314323"
      },
      {
        "name": "Brompheniramine/phenylephrine/phenylpropanolamine",
        "code": "51314324"
      },
      {
        "name": "Brompheniramine/phenylephrine/phenyltoloxamine",
        "code": "51314325"
      },
      { "name": "Brompheniramine/phenylpropanolamine", "code": "51314326" },
      { "name": "Brompheniramine/pseudoephedrine", "code": "51314327" },
      { "name": "Carbetapentane/chlorpheniramine", "code": "51314328" },
      {
        "name": "Carbetapentane/chlorpheniramine/ephedrine/phenylephrine",
        "code": "51314329"
      },
      {
        "name": "Carbetapentane/phenylephrine/phenylpropanolamine/potassium",
        "code": "51314330"
      },
      {
        "name": "Carbinoxamine/dextromethorphan/pseudoephedrine",
        "code": "51314331"
      },
      {
        "name": "Chlorpheniramine/guaifenesin/phenylpropanolamine",
        "code": "51314332"
      },
      {
        "name": "Chlorpheniramine/dextromethorphan/phenylephrine",
        "code": "51314333"
      },
      {
        "name": "Chlorpheniramine/dextromethorphan/phenylephrine/phenylpropanolamine",
        "code": "51314334"
      },
      {
        "name": "Chlorpheniramine/dextromethorphan/phenylpropanolamine",
        "code": "51314335"
      },
      {
        "name": "Chlorpheniramine/dextromethorphan/pseudoephedrine",
        "code": "51314336"
      },
      { "name": "Chlorpheniramine/ephedrine", "code": "51314337" },
      {
        "name": "Chlorpheniramine/ephedrine/guaifenesin/hydriodic acid",
        "code": "51314338"
      },
      {
        "name": "Chlorpheniramine/guaifenesin/phenylephrine",
        "code": "51314339"
      },
      {
        "name": "Chlorpheniramine/guaifenesin/phenylephrine/phenylpropanolamine",
        "code": "51314340"
      },
      {
        "name": "Chlorpheniramine/guaifenesin/phenylephrine/phenylpropanolamine/p",
        "code": "51314341"
      },
      {
        "name": "Chlorpheniramine/phenylpropanolamine/pseudoephedrine",
        "code": "51314342"
      },
      {
        "name": "Chlorpheniramine/guaifenesin/pseudoephedrine",
        "code": "51314343"
      },
      { "name": "Chlorpheniramine/phenylephrine", "code": "51314345" },
      {
        "name": "Chlorpheniramine/phenylephrine/phenylpropanolamine",
        "code": "51314346"
      },
      {
        "name": "Chlorpheniramine/phenylephrine/phenylpropanolamine/phenyltoloxamine",
        "code": "51314347"
      },
      {
        "name": "Chlorpheniramine/phenylephrine/phenylpropanolamine/pyrilamine",
        "code": "51314348"
      },
      {
        "name": "Chlorpheniramine/phenylephrine/phenyltoloxamine",
        "code": "51314349"
      },
      {
        "name": "Chlorpheniramine/phenylephrine/pyrilamine",
        "code": "51314350"
      },
      { "name": "Chlorpheniramine/phenylpropanolamine", "code": "51314351" },
      { "name": "Dexbrompheniramine/pseudoephedrine", "code": "51314352" },
      {
        "name": "Chlorpheniramine/phenylpropanolamine/pyrilamine",
        "code": "51314353"
      },
      { "name": "Chlorpheniramine/pseudoephedrine", "code": "51314354" },
      {
        "name": "Citric/dextromethorphan/ipecac/potassium/promethazine/sodium citrate",
        "code": "51314355"
      },
      {
        "name": "Citric/ipecac/phenylephrine/potassium/promethazine/sodium citrate",
        "code": "51314356"
      },
      {
        "name": "Citric/ipecac/potassium/promethazine/sodium citrate",
        "code": "51314357"
      },
      { "name": "Clemastine/phenylpropanolamine", "code": "51314358" },
      { "name": "Desloratadine/pseudoephedrine", "code": "51314359" },
      { "name": "Diphenhydramine/phenylephrine", "code": "51314360" },
      {
        "name": "Dexchlorpheniramine/dextromethorphan/pseudoephedrine",
        "code": "51314361"
      },
      {
        "name": "Dexchlorpheniramine/guaifenesin/pseudoephedrine",
        "code": "51314362"
      },
      { "name": "Dextromethorphan/doxylamine/guaifenesin", "code": "51314363" },
      {
        "name": "Dextromethorphan/guaifenesin/pseudoephedrine",
        "code": "51314364"
      },
      {
        "name": "Dextromethorphan/ipecac/potassium guaiacolsulfonate/promethazine",
        "code": "51314365"
      },
      {
        "name": "Dextromethorphan/phenylephrine/pyrilamine",
        "code": "51314366"
      },
      {
        "name": "Dextromethorphan/potassium guaiacolsulfonate/promethazine",
        "code": "51314367"
      },
      { "name": "Dextromethorphan/promethazine", "code": "51314368" },
      {
        "name": "Pheniramine/phenylpropanolamine/pyrilamine",
        "code": "51314369"
      },
      { "name": "Diphenhydramine/pseudoephedrine", "code": "51314370" },
      { "name": "Fexofenadine/pseudoephedrine", "code": "51314371" },
      {
        "name": "Ipecac/phenylephrine/potassium guaiacolsulfonate/promethazine",
        "code": "51314372"
      },
      { "name": "Loratadine/pseudoephedrine", "code": "51314373" },
      { "name": "Menthol/pheniramine/phenylephrine", "code": "51314374" },
      {
        "name": "Pheniramine/phenylpropanolamine/phenyltoloxamine/pyrilamine",
        "code": "51314375"
      },
      { "name": "Pheniramine/phenyltoloxamine/pyrilamine", "code": "51314376" },
      {
        "name": "Phenylephrine/potassium guaiacolsulfonate/promethazine",
        "code": "51314377"
      },
      { "name": "Phenylephrine/promethazine", "code": "51314378" },
      { "name": "Promethazine/pseudoephedrine", "code": "51314379" },
      { "name": "Pseudoephedrine/terfenadine", "code": "51314380" },
      { "name": "Pseudoephedrine/triprolidine", "code": "51314381" },
      {
        "name": "Chlorpheniramine/dextromethorphan/guaifenesin/phenylephrine/sodium salicylate",
        "code": "51314382"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/dextromethorphan/phenylephrine",
        "code": "51314383"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/dextromethorphan/phenylpropanolamine",
        "code": "51314384"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/dextromethorphan/pseudoephedrine",
        "code": "51314385"
      },
      {
        "name": "Acetaminophen/dextromethorphan/diphenhydramine/pseudoephedrine",
        "code": "51314386"
      },
      {
        "name": "Acetaminophen/dextromethorphan/doxylamine",
        "code": "51314387"
      },
      {
        "name": "Acetaminophen/dextromethorphan/doxylamine/ephedrine",
        "code": "51314388"
      },
      {
        "name": "Acetaminophen/dextromethorphan/doxylamine/pseudoephedrine",
        "code": "51314389"
      },
      {
        "name": "Acetaminophen/dextromethorphan/phenylephrine",
        "code": "51314390"
      },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic agents",
        "code": "51320000"
      },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic benzene derivatives",
        "code": "51321500"
      },
      { "name": "Eniclobrate", "code": "51321501" },
      { "name": "Probucol", "code": "51321502" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic alcohols",
        "code": "51321600"
      },
      {
        "name": "Ethanolamine oleate or ethanolammonium oleate or monoethanolamine oleate",
        "code": "51321601"
      },
      { "name": "Inositol", "code": "51321602" },
      { "name": "Lifibrol", "code": "51321603" },
      { "name": "Polidocanol", "code": "51321604" },
      { "name": "Inositol hexanicotinate", "code": "51321605" },
      { "name": "Inositol hexanitrate", "code": "51321606" },
      { "name": "Inositol niacinate", "code": "51321607" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic fatty acids",
        "code": "51321700"
      },
      { "name": "Atorvastatin", "code": "51321701" },
      { "name": "Eicosapentaenoic acid", "code": "51321702" },
      { "name": "Fluvastatin", "code": "51321703" },
      { "name": "Melinamide", "code": "51321704" },
      { "name": "Phosphatidylcholine", "code": "51321705" },
      { "name": "Atorvastatin calcium", "code": "51321706" },
      { "name": "Atorvastatin calcium hydrate", "code": "51321707" },
      { "name": "Atorvastatin calcium trihydrate", "code": "51321708" },
      { "name": "Atorvastatin lactone", "code": "51321709" },
      { "name": "Atorvastatin magnesium", "code": "51321710" },
      { "name": "Atorvastatin magnesium trihydrate", "code": "51321711" },
      { "name": "Atorvastatin sodium", "code": "51321712" },
      { "name": "Atorvastatin strontium", "code": "51321713" },
      { "name": "Fluvastatin sodium", "code": "51321714" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic fibric acids",
        "code": "51321800"
      },
      { "name": "Ponfibrate", "code": "51321801" },
      { "name": "Aluminium clofibrate", "code": "51321802" },
      { "name": "Biclofibrate", "code": "51321803" },
      { "name": "Ciprofibrate", "code": "51321804" },
      { "name": "Clofibrate", "code": "51321805" },
      { "name": "Clofibride", "code": "51321806" },
      { "name": "Etofibrate", "code": "51321807" },
      { "name": "Fenofibrate", "code": "51321808" },
      { "name": "Gemfibrozil", "code": "51321809" },
      { "name": "Pirifibrate", "code": "51321810" },
      { "name": "Ronifibrate", "code": "51321811" },
      { "name": "Salafibrate", "code": "51321812" },
      { "name": "Serfibrate", "code": "51321813" },
      { "name": "Simfibrate", "code": "51321814" },
      { "name": "Timofibrate", "code": "51321815" },
      { "name": "Cetaben", "code": "51321816" },
      { "name": "Cetaben sodium", "code": "51321817" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic amides",
        "code": "51321900"
      },
      { "name": "Bezafibrate", "code": "51321901" },
      { "name": "Eflucimibe", "code": "51321902" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic amines",
        "code": "51322000"
      },
      { "name": "Colesevelam", "code": "51322001" },
      { "name": "Beloxamide", "code": "51322003" },
      { "name": "Colestipol", "code": "51322004" },
      { "name": "Xinomiline", "code": "51322005" },
      { "name": "Colesevelam hydrochloride", "code": "51322006" },
      { "name": "Colestipol hydrochloride", "code": "51322007" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic amino acids",
        "code": "51322100"
      },
      { "name": "Crilvastatin", "code": "51322101" },
      { "name": "D,l-methionine or racemethionine", "code": "51322102" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic azetidines and cyclohexanes and fluorobenzenes and furans",
        "code": "51322200"
      },
      { "name": "Ezetimibe", "code": "51322201" },
      { "name": "Dalvastatin", "code": "51322202" },
      { "name": "Rosuvastatin", "code": "51322203" },
      { "name": "Acifran", "code": "51322204" },
      { "name": "Rosuvastatin calcium", "code": "51322205" },
      { "name": "Rosuvastatin zinc", "code": "51322206" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic macromolecular substances",
        "code": "51322300"
      },
      { "name": "Cholestyramine or colestyramine", "code": "51322301" },
      { "name": "Chondroitin", "code": "51322302" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic carboxylic acids",
        "code": "51322400"
      },
      { "name": "Avasimibe", "code": "51322401" },
      { "name": "Benzmalecene", "code": "51322402" },
      { "name": "Dirlotapide", "code": "51322403" },
      { "name": "Meglutol", "code": "51322404" },
      { "name": "Terbufibrol", "code": "51322405" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic glycosaminoglycans and hormones and indoles and isoquinolines",
        "code": "51322500"
      },
      { "name": "Dextrothyroxine", "code": "51322501" },
      { "name": "Treloxinate", "code": "51322502" },
      { "name": "Implitapide", "code": "51322503" },
      { "name": "Draquinolol", "code": "51322504" },
      { "name": "Dextrothyroxine sodium", "code": "51322505" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic sulfur compounds",
        "code": "51322600"
      },
      { "name": "Citiolone", "code": "51322601" },
      { "name": "Pantethine", "code": "51322602" },
      { "name": "Tazasubrate", "code": "51322603" },
      { "name": "Tizoprolic acid", "code": "51322604" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic naphthalenes",
        "code": "51322700"
      },
      { "name": "Mevastatin", "code": "51322701" },
      { "name": "Lovastatin", "code": "51322702" },
      { "name": "Pravastatin", "code": "51322703" },
      { "name": "Simvastatin", "code": "51322704" },
      { "name": "Pravastatin sodium", "code": "51322705" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic nicotinic acids",
        "code": "51322800"
      },
      { "name": "Glunicate", "code": "51322801" },
      { "name": "Niceritrol", "code": "51322802" },
      { "name": "Picafibrate", "code": "51322803" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic phenoxyacetates and pyrrolidines and quinolines",
        "code": "51322900"
      },
      { "name": "Clinofibrate", "code": "51322901" },
      { "name": "Boxidine", "code": "51322902" },
      { "name": "Pitavastatin", "code": "51322903" },
      { "name": "Boxidine hydrochloride", "code": "51322904" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic quaternary ammonium compounds",
        "code": "51323000"
      },
      { "name": "Carnitine", "code": "51323001" },
      { "name": "Levocarnitine", "code": "51323002" },
      { "name": "Carnitine chloride", "code": "51323003" },
      { "name": "Levocarnitine acetyl", "code": "51323004" },
      { "name": "Levocarnitine hydrochloride", "code": "51323005" },
      { "name": "Levocarnitine propionate", "code": "51323006" },
      { "name": "Levocarnitine propionate hydrochloride", "code": "51323007" },
      { "name": "Levocarnitine tartrate", "code": "51323008" },
      {
        "name": "Antihyperlipidemics and hypocholesterolemics, synthesized",
        "code": "51323100"
      },
      { "name": "Anisacril", "code": "51323101" },
      { "name": "Axitirome", "code": "51323102" },
      { "name": "Bervastatin", "code": "51323103" },
      { "name": "Colestolone", "code": "51323104" },
      { "name": "Disogluside", "code": "51323105" },
      { "name": "Fosmenic acid", "code": "51323106" },
      { "name": "Lodazecar", "code": "51323107" },
      { "name": "Xenthiorate", "code": "51323108" },
      { "name": "Xenyhexenic acid", "code": "51323109" },
      {
        "name": "Antihyperlipidemic and hypocholesterolemic aromatic heterocyclics",
        "code": "51323200"
      },
      { "name": "Cerivastatin", "code": "51323201" },
      { "name": "Nicanartine", "code": "51323202" },
      { "name": "Pirinixic acid", "code": "51323203" },
      { "name": "Pirinixil", "code": "51323204" },
      { "name": "Cerivastatin sodium", "code": "51323205" },
      { "name": "Antipsychotics", "code": "51330000" },
      { "name": "Antipsychotic benzene derivatives", "code": "51331500" },
      { "name": "Armodafinil or modafinil", "code": "51331501" },
      { "name": "Modafinil or armodafinil", "code": "51331502" },
      {
        "name": "Antipsychotic alcohols and amines and amino acids and benzopyrans and cyclic s-oxides",
        "code": "51331600"
      },
      { "name": "Oxyfenamate or hydroxyphenamate", "code": "51331601" },
      { "name": "Lometraline", "code": "51331602" },
      { "name": "Pentiapine", "code": "51331603" },
      { "name": "Abaperidone", "code": "51331604" },
      { "name": "Fananserin", "code": "51331605" },
      { "name": "Lometraline hydrochloride", "code": "51331606" },
      { "name": "Pentiapine maleate", "code": "51331607" },
      { "name": "Antipsychotic amides", "code": "51331700" },
      { "name": "Amisulpride", "code": "51331701" },
      { "name": "Picobenzide", "code": "51331702" },
      { "name": "Remoxipride", "code": "51331703" },
      { "name": "Sultopride", "code": "51331704" },
      { "name": "Piracetam", "code": "51331705" },
      { "name": "Remoxipride hydrochloride", "code": "51331706" },
      { "name": "Sultopride hydrochloride", "code": "51331707" },
      { "name": "Antipsychotic anions", "code": "51331900" },
      { "name": "Lithium carbonate", "code": "51331901" },
      { "name": "Lithium citrate", "code": "51331902" },
      { "name": "Antipsychotic azoles", "code": "51332000" },
      { "name": "Iloperidone", "code": "51332001" },
      { "name": "Lintopride", "code": "51332002" },
      { "name": "Paliperidone", "code": "51332003" },
      { "name": "Sertindole", "code": "51332004" },
      { "name": "Zetidoline", "code": "51332005" },
      { "name": "Paliperidone palmitate", "code": "51332006" },
      { "name": "Antipsychotic benzamides", "code": "51332100" },
      { "name": "Dobupride", "code": "51332101" },
      { "name": "Nemonapride", "code": "51332102" },
      { "name": "Levosulpiride or sulpiride", "code": "51332103" },
      { "name": "Veralipride", "code": "51332104" },
      { "name": "Antipsychotic benzazepines", "code": "51332200" },
      { "name": "Berupipam", "code": "51332201" },
      { "name": "Carpipramine", "code": "51332202" },
      { "name": "Timelotem", "code": "51332203" },
      { "name": "Carpipramine dihydrochloride", "code": "51332204" },
      {
        "name": "Carpipramine dihydrochloride monohydrate",
        "code": "51332205"
      },
      { "name": "Carpipramine hydrochloride", "code": "51332206" },
      { "name": "Carpipramine maleate", "code": "51332207" },
      { "name": "Antipsychotic benzimidazoles", "code": "51332300" },
      { "name": "Axamozide", "code": "51332301" },
      { "name": "Milenperone", "code": "51332302" },
      { "name": "Neflumozide", "code": "51332303" },
      { "name": "Oxiperomide", "code": "51332304" },
      { "name": "Pimozide", "code": "51332305" },
      { "name": "Neflumozide hydrochloride", "code": "51332306" },
      { "name": "Antipsychotic carbamates", "code": "51332400" },
      { "name": "Pentabamate", "code": "51332401" },
      { "name": "Prifuroline", "code": "51332402" },
      { "name": "Antipsychotic butyrophenones", "code": "51332500" },
      { "name": "Haloperidol", "code": "51332501" },
      { "name": "Azabuperone", "code": "51332502" },
      { "name": "Azaperone", "code": "51332503" },
      { "name": "Benperidol", "code": "51332504" },
      { "name": "Bromperidol", "code": "51332505" },
      { "name": "Carperone", "code": "51332506" },
      { "name": "Cloroperone", "code": "51332507" },
      { "name": "Droperidol", "code": "51332508" },
      { "name": "Fluanisone", "code": "51332509" },
      { "name": "Lenperone", "code": "51332510" },
      { "name": "Melperone", "code": "51332511" },
      { "name": "Moperone", "code": "51332512" },
      { "name": "Pipamperone", "code": "51332513" },
      { "name": "Trifluperidol", "code": "51332514" },
      { "name": "Azaperone for veterinary use", "code": "51332515" },
      { "name": "Bromperidol decanoate", "code": "51332516" },
      { "name": "Cloroperone hydrochloride", "code": "51332517" },
      { "name": "Haloperidol decanoate", "code": "51332518" },
      { "name": "Haloperidol intensol", "code": "51332519" },
      { "name": "Haloperidol lactate", "code": "51332520" },
      { "name": "Lenperone hydrochloride", "code": "51332521" },
      { "name": "Melperone hydrochloride", "code": "51332522" },
      { "name": "Moperone chlorohydrate", "code": "51332523" },
      { "name": "Moperone hydrochloride", "code": "51332524" },
      { "name": "Pipamperone dihydrochloride", "code": "51332525" },
      { "name": "Pipamperone hydrochloride", "code": "51332526" },
      { "name": "Trifluperidol hydrochloride", "code": "51332527" },
      { "name": "Antipsychotic dibenzazepines", "code": "51332700" },
      { "name": "Batelapine", "code": "51332701" },
      { "name": "Clocapramine", "code": "51332702" },
      { "name": "Clozapine", "code": "51332703" },
      { "name": "Fluperlapine", "code": "51332704" },
      { "name": "Batelapine maleate", "code": "51332705" },
      { "name": "Clocapramine hydrochloride", "code": "51332706" },
      { "name": "Antipsychotic phenothiazines", "code": "51332800" },
      { "name": "Chlorproethazine", "code": "51332801" },
      { "name": "Acepromazine", "code": "51332802" },
      { "name": "Acetophenazine", "code": "51332803" },
      { "name": "Butaperazine", "code": "51332804" },
      { "name": "Butaperazine maleate", "code": "51332805" },
      { "name": "Carfenazine or carphenazine", "code": "51332806" },
      { "name": "Chlorpromazine", "code": "51332807" },
      { "name": "Ciclofenazine or cyclophenazine", "code": "51332808" },
      { "name": "Clospirazine or spiclomazine", "code": "51332809" },
      { "name": "Cyamemazine", "code": "51332810" },
      { "name": "Duoperone", "code": "51332811" },
      { "name": "Fluphenazine", "code": "51332812" },
      { "name": "Oxaflumazine", "code": "51332813" },
      { "name": "Levomepromazine or methotrimeprazine", "code": "51332814" },
      { "name": "Mesoridazine", "code": "51332815" },
      { "name": "Metofenazate", "code": "51332816" },
      { "name": "Promazine", "code": "51332817" },
      { "name": "Oxyridazine", "code": "51332818" },
      { "name": "Mepazine or pecazine", "code": "51332819" },
      { "name": "Perazine", "code": "51332820" },
      { "name": "Periciazine", "code": "51332821" },
      { "name": "Perphenazine", "code": "51332822" },
      { "name": "Phenothiazine", "code": "51332823" },
      { "name": "Piperacetazine", "code": "51332824" },
      { "name": "Pipotiazine", "code": "51332825" },
      { "name": "Propiomazine", "code": "51332826" },
      { "name": "Thiopropazate", "code": "51332827" },
      { "name": "Thioproperazine", "code": "51332828" },
      { "name": "Thioproperazine mesilate", "code": "51332829" },
      { "name": "Thioridazine", "code": "51332830" },
      { "name": "Trifluoperazine", "code": "51332831" },
      { "name": "Triflupromazine", "code": "51332832" },
      { "name": "Acepromazine maleate", "code": "51332833" },
      { "name": "Acetophenazine dimaleate", "code": "51332834" },
      { "name": "Acetophenazine maleate", "code": "51332835" },
      { "name": "Butaperazine dimaleate", "code": "51332836" },
      { "name": "Carfenazine maleate", "code": "51332837" },
      { "name": "Chlorproethazine hydrochloride", "code": "51332838" },
      { "name": "Chlorpromazine hibenzate", "code": "51332839" },
      { "name": "Chlorpromazine hydrochloride", "code": "51332840" },
      { "name": "Chlorpromazine maleate", "code": "51332841" },
      { "name": "Chlorpromazine pamoate", "code": "51332842" },
      { "name": "Chlorpromazine phenolphthalinate", "code": "51332843" },
      { "name": "Chlorpromazine sulfone", "code": "51332844" },
      { "name": "Chlorpromazine sulphoxide", "code": "51332845" },
      { "name": "Cyamemazine tartrate", "code": "51332846" },
      { "name": "Duoperone fumarate", "code": "51332847" },
      { "name": "Fluphenazine decanoate", "code": "51332848" },
      { "name": "Fluphenazine dihydrochloride", "code": "51332849" },
      { "name": "Fluphenazine enantate", "code": "51332850" },
      { "name": "Fluphenazine enanthate", "code": "51332851" },
      { "name": "Fluphenazine hydrochloride", "code": "51332852" },
      { "name": "Fluphenazine maleate", "code": "51332853" },
      { "name": "Fluphenazine sulfoxide", "code": "51332854" },
      { "name": "Levomepromazine hydrochloride", "code": "51332855" },
      { "name": "Levomepromazine maleate", "code": "51332856" },
      { "name": "Mesoridazine benzenesulfonate", "code": "51332857" },
      { "name": "Mesoridazine besilate", "code": "51332858" },
      { "name": "Mesoridazine besylate", "code": "51332859" },
      { "name": "Metofenazate difumarate", "code": "51332860" },
      { "name": "Pecazine hydrochloride", "code": "51332861" },
      { "name": "Perphenazine decanoate", "code": "51332862" },
      { "name": "Perphenazine enantate", "code": "51332863" },
      { "name": "Perphenazine enanthate", "code": "51332864" },
      { "name": "Perphenazine hydrochloride", "code": "51332865" },
      { "name": "Perphenazine maleate", "code": "51332866" },
      { "name": "Perphenazine sulfoxide", "code": "51332867" },
      { "name": "Pipotiazine palmitate", "code": "51332868" },
      { "name": "Pipotiazine undecylenate", "code": "51332869" },
      { "name": "Promazine hydrochloride", "code": "51332870" },
      { "name": "Promazine phosphate", "code": "51332871" },
      { "name": "Propiomazine hydrochloride", "code": "51332872" },
      { "name": "Propiomazine maleate", "code": "51332873" },
      { "name": "Thiopropazate dihydrochloride", "code": "51332874" },
      { "name": "Thioproperazine dimesilate", "code": "51332875" },
      { "name": "Thioproperazine mesylate", "code": "51332876" },
      { "name": "Thioridazine hydrochloride", "code": "51332877" },
      { "name": "Trifluoperazine dihydrochloride", "code": "51332878" },
      { "name": "Trifluoperazine dimaleate", "code": "51332879" },
      { "name": "Trifluoperazine hydrochloride", "code": "51332880" },
      { "name": "Trifluoperazine maleate", "code": "51332881" },
      { "name": "Triflupromazine hydrochloride", "code": "51332882" },
      { "name": "Triflupromazine maleate", "code": "51332883" },
      { "name": "Antipsychotic ergot alkaloids", "code": "51332900" },
      { "name": "Bromerguride", "code": "51332901" },
      { "name": "Nicergoline", "code": "51332902" },
      { "name": "Proterguride", "code": "51332903" },
      { "name": "Nicergoline tartrate", "code": "51332904" },
      { "name": "Antipsychotic indoles", "code": "51333000" },
      { "name": "Flucindole", "code": "51333001" },
      { "name": "Milipertine", "code": "51333002" },
      { "name": "Molindone", "code": "51333003" },
      { "name": "Tepirindole", "code": "51333004" },
      { "name": "Tienocarbine", "code": "51333005" },
      { "name": "Molindone hydrochloride", "code": "51333006" },
      {
        "name": "Antipsychotic isoquinolines and nicotinic acids and oxazoles and pyridines and pyrrolidines",
        "code": "51333100"
      },
      { "name": "Azaquinzole", "code": "51333101" },
      { "name": "Etazolate", "code": "51333102" },
      { "name": "Panamesine", "code": "51333103" },
      { "name": "Carvotroline", "code": "51333104" },
      { "name": "Asenapine", "code": "51333105" },
      { "name": "Asenapine maleate", "code": "51333106" },
      { "name": "Carvotroline hydrochloride", "code": "51333107" },
      { "name": "Etazolate hydrochloride", "code": "51333108" },
      {
        "name": "Antipsychotic nitrogen mustard compounds",
        "code": "51333200"
      },
      { "name": "Aldesleukin", "code": "51333201" },
      { "name": "Imiquimod", "code": "51333202" },
      { "name": "Antipsychotic piperazines", "code": "51333400" },
      { "name": "Alpertine", "code": "51333401" },
      { "name": "Batoprazine", "code": "51333402" },
      { "name": "Blonanserin", "code": "51333403" },
      { "name": "Eltoprazine", "code": "51333404" },
      { "name": "Fenaperone", "code": "51333405" },
      { "name": "Lorpiprazole", "code": "51333406" },
      { "name": "Mafoprazine", "code": "51333407" },
      { "name": "Tefludazine", "code": "51333408" },
      { "name": "Tolpiprazole", "code": "51333409" },
      { "name": "Ziprasidone", "code": "51333410" },
      { "name": "Fluprazine", "code": "51333411" },
      { "name": "Eltoprazine hydrochloride", "code": "51333412" },
      { "name": "Ziprasidone hydrochloride", "code": "51333413" },
      { "name": "Ziprasidone hydrochloride hydrate", "code": "51333414" },
      { "name": "Ziprasidone hydrochloride monohydrate", "code": "51333415" },
      { "name": "Ziprasidone mesilate or mesylate", "code": "51333416" },
      { "name": "Antipsychotic piperidines", "code": "51333500" },
      { "name": "Clopimozide", "code": "51333501" },
      { "name": "Domperidone", "code": "51333502" },
      { "name": "Halopemide", "code": "51333503" },
      { "name": "Ocaperidone", "code": "51333504" },
      { "name": "Penfluridol", "code": "51333505" },
      { "name": "Pridinol", "code": "51333506" },
      { "name": "Clopipazan", "code": "51333507" },
      { "name": "Preclamol", "code": "51333508" },
      { "name": "Clopipazan mesilate or mesylate", "code": "51333509" },
      { "name": "Domperidone maleate", "code": "51333510" },
      { "name": "Preclamol hydrochloride", "code": "51333511" },
      { "name": "Pridinol hydrochloride", "code": "51333512" },
      { "name": "Pridinol mesilate", "code": "51333513" },
      { "name": "Pridinol methanesulfonate", "code": "51333514" },
      { "name": "Antipsychotic pyrimidines", "code": "51333600" },
      { "name": "Risperidone", "code": "51333601" },
      { "name": "Setoperone", "code": "51333602" },
      {
        "name": "Antipsychotic quaternary ammonium compounds and quinazolines and quinolizines and quinoxalines and thiazines",
        "code": "51333700"
      },
      { "name": "Citicoline", "code": "51333701" },
      { "name": "Licostinel", "code": "51333702" },
      { "name": "Belaperidone", "code": "51333703" },
      { "name": "Tetrabenazine", "code": "51333704" },
      { "name": "Prothipendyl", "code": "51333705" },
      { "name": "Citicoline sodium", "code": "51333706" },
      { "name": "Prothipendyl hydrochloride", "code": "51333707" },
      { "name": "Antipsychotic quinolines", "code": "51333900" },
      { "name": "Quinagolide", "code": "51333901" },
      { "name": "Terbequinil", "code": "51333902" },
      { "name": "Quinagolide hydrochloride", "code": "51333903" },
      { "name": "Antipsychotic spiro compounds", "code": "51334000" },
      { "name": "Fluspirilene", "code": "51334001" },
      { "name": "Spiramide", "code": "51334002" },
      { "name": "Spirilene", "code": "51334003" },
      { "name": "Tiospirone", "code": "51334004" },
      { "name": "Tiospirone hydrochloride", "code": "51334005" },
      { "name": "Antipsychotic thiazoles", "code": "51334100" },
      { "name": "Isosulpride", "code": "51334101" },
      { "name": "Perospirone", "code": "51334102" },
      { "name": "Lurasidone", "code": "51334103" },
      { "name": "Lurasidone hydrochloride", "code": "51334104" },
      { "name": "Perospirone hydrochloride", "code": "51334105" },
      { "name": "Perospirone hydrochloride hydrate", "code": "51334106" },
      { "name": "Antipsychotic thiepins", "code": "51334200" },
      { "name": "Clorotepine", "code": "51334201" },
      { "name": "Clothiapine or clotiapine", "code": "51334202" },
      { "name": "Metiapine", "code": "51334203" },
      { "name": "Quetiapine", "code": "51334204" },
      { "name": "Zotepine", "code": "51334205" },
      { "name": "Clorotepine maleate", "code": "51334206" },
      { "name": "Quetiapine fumarate", "code": "51334207" },
      { "name": "Quetiapine hemifumarate", "code": "51334208" },
      { "name": "Antipsychotic thioxanthenes", "code": "51334300" },
      { "name": "Chlorprothixene", "code": "51334301" },
      { "name": "Clopenthixol", "code": "51334302" },
      { "name": "Flupentixol", "code": "51334303" },
      { "name": "Prothixene", "code": "51334304" },
      { "name": "Thiothixene or tiotixene", "code": "51334305" },
      { "name": "Zuclopenthixol", "code": "51334306" },
      { "name": "Chlorprothixene acetate", "code": "51334307" },
      { "name": "Chlorprothixene citrate", "code": "51334308" },
      { "name": "Chlorprothixene hydrochloride", "code": "51334309" },
      { "name": "Clopenthixol decanoate", "code": "51334310" },
      { "name": "Clopenthixol dihydrochloride", "code": "51334311" },
      { "name": "Clopenthixol hydrochloride", "code": "51334312" },
      { "name": "Flupentixol dihydrochloride", "code": "51334313" },
      { "name": "Flupentixol hydrochloride", "code": "51334314" },
      { "name": "Zuclopenthixol acetate", "code": "51334315" },
      { "name": "Zuclopenthixol hydrochloride", "code": "51334316" },
      { "name": "Antipsychotics, synthesized", "code": "51334400" },
      { "name": "Mindoperone", "code": "51334401" },
      { "name": "Biriperone", "code": "51334402" },
      { "name": "Cinuperone", "code": "51334403" },
      { "name": "Citatepine", "code": "51334404" },
      { "name": "Cloxypendyl", "code": "51334405" },
      { "name": "Diclometide", "code": "51334406" },
      { "name": "Fenimide", "code": "51334407" },
      { "name": "Fluspiperone", "code": "51334408" },
      { "name": "Fosenazide", "code": "51334409" },
      { "name": "Lusaperidone", "code": "51334410" },
      { "name": "Nonaperone", "code": "51334411" },
      { "name": "Pinoxepin", "code": "51334412" },
      { "name": "Prideperone", "code": "51334413" },
      { "name": "Propyperone", "code": "51334414" },
      { "name": "Rilapine", "code": "51334415" },
      { "name": "Roxoperone", "code": "51334416" },
      { "name": "Tenilapine", "code": "51334417" },
      { "name": "Timirdine", "code": "51334418" },
      { "name": "Zoloperone", "code": "51334419" },
      { "name": "Cloxypendyl dihydrochloride", "code": "51334420" },
      { "name": "Pinoxepin hydrochloride", "code": "51334421" },
      {
        "name": "Antipsychotic cyclohexanes and dibenzocycloheptenes and dibenzoxazepines and indole alkaloids",
        "code": "51334500"
      },
      { "name": "Cyprodenate", "code": "51334501" },
      { "name": "Butaclamol", "code": "51334502" },
      { "name": "Loxapine", "code": "51334503" },
      { "name": "Psilocybin", "code": "51334504" },
      { "name": "Butaclamol hydrochloride", "code": "51334505" },
      { "name": "Cyprodenate maleate", "code": "51334506" },
      { "name": "Loxapine hydrochloride", "code": "51334507" },
      { "name": "Loxapine succinate", "code": "51334508" },
      { "name": "Combination lithium salts", "code": "51334600" },
      { "name": "Lithium carbonate/sodium chloride", "code": "51334601" },
      { "name": "Antiviral drugs", "code": "51340000" },
      { "name": "Antiviral carboxylic acids", "code": "51341500" },
      { "name": "Amprenavir", "code": "51341501" },
      { "name": "Fosamprenavir", "code": "51341502" },
      { "name": "Foscarnet", "code": "51341503" },
      { "name": "Fosfonet", "code": "51341504" },
      { "name": "Fosamprenavir calcium", "code": "51341505" },
      { "name": "Fosamprenavir sodium", "code": "51341506" },
      { "name": "Antiviral adamantanes", "code": "51341600" },
      { "name": "Somantadine", "code": "51341601" },
      { "name": "Tromantadine", "code": "51341602" },
      { "name": "Rimantadine", "code": "51341603" },
      { "name": "Rimantadine hydrochloride", "code": "51341604" },
      { "name": "Somantadine hydrochloride", "code": "51341605" },
      { "name": "Tromantadine hydrochloride", "code": "51341606" },
      { "name": "Antiviral aldehydes", "code": "51341700" },
      { "name": "Kethoxal or ketoxal", "code": "51341701" },
      { "name": "Xenygloxal", "code": "51341702" },
      { "name": "Antiviral amides", "code": "51341800" },
      { "name": "Darunavir", "code": "51341801" },
      { "name": "Oseltamivir", "code": "51341802" },
      { "name": "Darunavir ethanolate", "code": "51341803" },
      { "name": "Oseltamivir carboxylate", "code": "51341804" },
      { "name": "Oseltamivir phosphate", "code": "51341805" },
      {
        "name": "Antiviral amidines and amines and benzoxazines and cyclohexanes",
        "code": "51341900"
      },
      { "name": "Zanamivir", "code": "51341901" },
      { "name": "Zinviroxime", "code": "51341902" },
      { "name": "Efavirenz", "code": "51341903" },
      { "name": "Maraviroc", "code": "51341904" },
      { "name": "Antiviral azoles", "code": "51342000" },
      { "name": "Capravirine", "code": "51342001" },
      { "name": "Disoxaril", "code": "51342002" },
      { "name": "Pleconaril", "code": "51342003" },
      { "name": "Antiviral benzimidazoles", "code": "51342100" },
      { "name": "Enviradene", "code": "51342101" },
      { "name": "Enviroxime", "code": "51342102" },
      { "name": "Maribavir", "code": "51342103" },
      { "name": "Antiviral guanines", "code": "51342300" },
      { "name": "Valaciclovir or valacyclovir", "code": "51342301" },
      { "name": "Buciclovir", "code": "51342302" },
      { "name": "Desciclovir", "code": "51342303" },
      { "name": "Entecavir", "code": "51342304" },
      { "name": "Ganciclovir", "code": "51342305" },
      { "name": "Lobucavir", "code": "51342306" },
      { "name": "Penciclovir", "code": "51342307" },
      { "name": "Valganciclovir", "code": "51342308" },
      { "name": "Aciclovir or acyclovir", "code": "51342309" },
      { "name": "Valacyclovir hydrochloride", "code": "51342310" },
      { "name": "Aciclovir sodium", "code": "51342311" },
      { "name": "Entecavir hydrate", "code": "51342312" },
      { "name": "Ganciclovir sodium", "code": "51342313" },
      { "name": "Penciclovir sodium", "code": "51342314" },
      { "name": "Valaciclovir hydrochloride", "code": "51342315" },
      { "name": "Valganciclovir hydrochloride", "code": "51342316" },
      { "name": "Antiviral isoquinolines", "code": "51342400" },
      { "name": "Nelfinavir", "code": "51342401" },
      { "name": "Saquinavir", "code": "51342402" },
      { "name": "Nelfinavir mesilate or mesylate", "code": "51342403" },
      { "name": "Saquinavir mesilate or mesylate", "code": "51342404" },
      { "name": "Antiviral nucleosides", "code": "51342500" },
      { "name": "Abacavir", "code": "51342501" },
      { "name": "Alovudine", "code": "51342502" },
      { "name": "Ribavirin", "code": "51342503" },
      { "name": "Telbivudine", "code": "51342504" },
      { "name": "Abacavir succinate", "code": "51342505" },
      { "name": "Abacavir sulfate", "code": "51342506" },
      { "name": "Antiviral sulfur compounds", "code": "51342600" },
      { "name": "Amidapsone", "code": "51342601" },
      { "name": "Fomivirsen", "code": "51342602" },
      { "name": "Fomivirsen sodium", "code": "51342603" },
      { "name": "Antiviral peptides", "code": "51342700" },
      { "name": "Atazanavir", "code": "51342701" },
      { "name": "Enfuvirtide", "code": "51342702" },
      { "name": "Telaprevir", "code": "51342703" },
      { "name": "Boceprevir", "code": "51342704" },
      { "name": "Interferon alfa-2a", "code": "51342705" },
      { "name": "Interferon alfa-2b", "code": "51342706" },
      { "name": "Interferon alfacon-1", "code": "51342707" },
      { "name": "Interferon alfa-n1", "code": "51342708" },
      { "name": "Interferon beta-1a", "code": "51342709" },
      { "name": "Interferon beta-1b", "code": "51342710" },
      { "name": "Interferon gamma-1b", "code": "51342711" },
      { "name": "Peginterferon alfa-2a", "code": "51342712" },
      { "name": "Atazanavir sulfate", "code": "51342713" },
      { "name": "Atazanavir sulphate", "code": "51342714" },
      { "name": "Antiviral piperazines", "code": "51342800" },
      { "name": "Aranotin", "code": "51342801" },
      { "name": "Atevirdine", "code": "51342802" },
      { "name": "Delavirdine", "code": "51342803" },
      { "name": "Atevirdine mesilate or mesylate", "code": "51342804" },
      { "name": "Delavirdine mesilate or mesylate", "code": "51342805" },
      { "name": "Antiviral purines", "code": "51342900" },
      { "name": "Didanosine", "code": "51342901" },
      { "name": "Famciclovir", "code": "51342902" },
      { "name": "Inosine pranobex", "code": "51342903" },
      { "name": "Vidarabine", "code": "51342904" },
      { "name": "Vidarabine anhydrous", "code": "51342905" },
      { "name": "Vidarabine phosphate", "code": "51342906" },
      { "name": "Vidarabine sodium phosphate", "code": "51342907" },
      { "name": "Antiviral pyridines", "code": "51343000" },
      { "name": "Indinavir", "code": "51343001" },
      { "name": "Nevirapine", "code": "51343002" },
      { "name": "Tipranavir", "code": "51343003" },
      { "name": "Stavudine or stavidine", "code": "51343004" },
      { "name": "Stavudine and lamivudine and nevirapine", "code": "51343005" },
      { "name": "Indinavir sulfate", "code": "51343006" },
      { "name": "Indinavir sulphate", "code": "51343007" },
      { "name": "Nevirapine hemihydrate", "code": "51343008" },
      { "name": "Antiviral pyrimidines", "code": "51343100" },
      { "name": "Idoxuridine", "code": "51343101" },
      { "name": "Brivudine", "code": "51343102" },
      { "name": "Clevudine", "code": "51343103" },
      { "name": "Emivirine", "code": "51343104" },
      { "name": "Emtricitabine", "code": "51343105" },
      { "name": "Epervudine", "code": "51343106" },
      { "name": "Fiacitabine", "code": "51343107" },
      { "name": "Fialuridine", "code": "51343108" },
      { "name": "Ibacitabine", "code": "51343109" },
      { "name": "Lamivudine", "code": "51343110" },
      { "name": "Lopinavir", "code": "51343111" },
      { "name": "Navuridine", "code": "51343112" },
      { "name": "Sorivudine", "code": "51343113" },
      { "name": "Zalcitabine", "code": "51343115" },
      { "name": "Azidothymidine or zidovudine", "code": "51343116" },
      { "name": "Idoxuridine monohydrate", "code": "51343117" },
      { "name": "Lamivudine sulfoxide", "code": "51343118" },
      {
        "name": "Antiviral pyrrolidinones and quinolines and thiazoles",
        "code": "51343200"
      },
      { "name": "Raltegravir", "code": "51343201" },
      { "name": "Palinavir", "code": "51343202" },
      { "name": "Ritonavir", "code": "51343203" },
      { "name": "Antivirals, synthesized", "code": "51343300" },
      { "name": "Xenazoic acid", "code": "51343301" },
      { "name": "Edoxudine", "code": "51343302" },
      { "name": "Memotine", "code": "51343303" },
      { "name": "Mozenavir", "code": "51343304" },
      { "name": "Omaciclovir", "code": "51343305" },
      { "name": "Opaviraline", "code": "51343306" },
      { "name": "Rociclovir", "code": "51343307" },
      { "name": "Telinavir", "code": "51343308" },
      { "name": "Viroxime", "code": "51343309" },
      { "name": "Memotine hydrochloride", "code": "51343310" },
      {
        "name": "Antiviral cyclopentanes and dioxolanes and fluorenes and indolizines",
        "code": "51343400"
      },
      { "name": "Peramivir", "code": "51343401" },
      { "name": "Amdoxovir", "code": "51343402" },
      { "name": "Tilorone", "code": "51343403" },
      { "name": "Celgosivir", "code": "51343404" },
      { "name": "Celgosivir hydrochloride", "code": "51343405" },
      { "name": "Tilorone hydrochloride", "code": "51343406" },
      { "name": "Antiviral organophosphorus compounds", "code": "51343500" },
      { "name": "Adefovir", "code": "51343501" },
      { "name": "Cidofovir", "code": "51343502" },
      { "name": "Tenofovir", "code": "51343503" },
      { "name": "Adefovir dipivoxil", "code": "51343504" },
      { "name": "Adefovir pivoxil", "code": "51343505" },
      { "name": "Cidofovir anhydrous", "code": "51343506" },
      { "name": "Cidofovir sodium", "code": "51343507" },
      { "name": "Tenofovir alafenamide", "code": "51343508" },
      { "name": "Tenofovir alafenamide fumarate", "code": "51343509" },
      { "name": "Tenofovir df", "code": "51343510" },
      { "name": "Tenofovir disoproxil", "code": "51343511" },
      { "name": "Tenofovir disoproxil fumarate", "code": "51343512" },
      { "name": "Antiviral thiosemicarbazones", "code": "51343600" },
      { "name": "Citenazone", "code": "51343601" },
      { "name": "Methisazone or metisazone", "code": "51343602" },
      {
        "name": "Antiviral ketones and morpholines and nitriles and pyridazines",
        "code": "51343700"
      },
      { "name": "Tanomastat", "code": "51343701" },
      { "name": "Moroxydine", "code": "51343702" },
      { "name": "Rilpivirine", "code": "51343703" },
      { "name": "Etravirine", "code": "51343704" },
      { "name": "Rilpivirine hydrochloride", "code": "51343705" },
      { "name": "Combination antivirals", "code": "51343800" },
      { "name": "Abacavir/lamivudine", "code": "51343801" },
      { "name": "Abacavir/lamivudine/zidovudine", "code": "51343802" },
      { "name": "Efavirenz/emtricitabine/tenofovir", "code": "51343803" },
      { "name": "Emtricitabine/tenofovir", "code": "51343804" },
      { "name": "Interferon alfa-2b/ribavirin", "code": "51343805" },
      { "name": "Lamivudine/zidovudine", "code": "51343806" },
      { "name": "Peginterferon/ribavirin", "code": "51343807" },
      {
        "name": "Estrogens and progestins and internal contraceptives",
        "code": "51350000"
      },
      {
        "name": "Estrogen, progestin, or internal contraceptive pregnanes",
        "code": "51351500"
      },
      { "name": "Chlormadinone", "code": "51351501" },
      { "name": "Cismadinone", "code": "51351502" },
      { "name": "Dimethisterone", "code": "51351503" },
      { "name": "Edogestrone", "code": "51351504" },
      { "name": "Ethisterone", "code": "51351505" },
      { "name": "Haloprogesterone", "code": "51351506" },
      { "name": "Hydroxyprogesterone caproate", "code": "51351507" },
      { "name": "Medrogestone", "code": "51351508" },
      { "name": "Medroxyprogesterone", "code": "51351509" },
      { "name": "Megestrol", "code": "51351510" },
      { "name": "Megestrol acetate", "code": "51351511" },
      { "name": "Nomegestrol", "code": "51351512" },
      { "name": "Pregnenolone", "code": "51351513" },
      { "name": "Progesterone", "code": "51351514" },
      { "name": "Proligestone", "code": "51351515" },
      { "name": "Anagestone", "code": "51351516" },
      { "name": "Hydroxyprogesterone", "code": "51351517" },
      { "name": "Alfasone or algestone or alphasone", "code": "51351518" },
      { "name": "Algestone acetonide", "code": "51351519" },
      { "name": "Algestone acetophenide", "code": "51351520" },
      { "name": "Anagestone acetate", "code": "51351521" },
      { "name": "Chlormadinone acetate", "code": "51351522" },
      { "name": "Hydroxyprogesterone acetate", "code": "51351523" },
      { "name": "Medroxyprogesterone caproate", "code": "51351524" },
      { "name": "Nomegestrol acetate", "code": "51351525" },
      { "name": "Pregnenolone methyl ether", "code": "51351526" },
      { "name": "Pregnenolone succinate", "code": "51351527" },
      { "name": "Pregnenolone sulfate", "code": "51351528" },
      { "name": "Progesterone caproate", "code": "51351529" },
      {
        "name": "Estrogen, progestin, or internal contraceptive estrenes",
        "code": "51351600"
      },
      { "name": "16-epiestriol", "code": "51351601" },
      { "name": "Aglepristone", "code": "51351602" },
      { "name": "Allylestrenol", "code": "51351603" },
      { "name": "Altrenogest", "code": "51351604" },
      { "name": "Estradiol or oestradiol", "code": "51351605" },
      { "name": "Dienogest", "code": "51351606" },
      { "name": "Dydrogesterone", "code": "51351607" },
      { "name": "Estriol", "code": "51351608" },
      { "name": "Fulvestrant", "code": "51351609" },
      { "name": "Estrone", "code": "51351610" },
      { "name": "Estropipate", "code": "51351611" },
      { "name": "Medroxyprogesterone acetate", "code": "51351612" },
      { "name": "Mifepristone", "code": "51351613" },
      { "name": "Nilestriol or nylestriol", "code": "51351614" },
      { "name": "Quinestrol", "code": "51351615" },
      { "name": "Promestriene", "code": "51351616" },
      { "name": "Methylestrenolone", "code": "51351617" },
      { "name": "Ormeloxifene or centchroman", "code": "51351618" },
      { "name": "Estradiol acetate", "code": "51351619" },
      { "name": "Estradiol benzoate", "code": "51351620" },
      { "name": "Estradiol cipionate", "code": "51351621" },
      { "name": "Estradiol cypionate", "code": "51351622" },
      { "name": "Estradiol dipropionate", "code": "51351623" },
      { "name": "Estradiol hemihydrate", "code": "51351624" },
      { "name": "Estradiol monopalmitate", "code": "51351625" },
      { "name": "Estradiol undecylate", "code": "51351626" },
      { "name": "Estradiol valerate", "code": "51351627" },
      { "name": "Estriol succinate", "code": "51351628" },
      { "name": "Estrone acetate", "code": "51351629" },
      { "name": "Estrone benzoate", "code": "51351630" },
      { "name": "Estrone glucuronide", "code": "51351631" },
      { "name": "Estrone hydrogen sulfate", "code": "51351632" },
      { "name": "Estrone sodium sulfate", "code": "51351633" },
      { "name": "Estrone sodium sulphate", "code": "51351634" },
      {
        "name": "Estrogen, progestin, or internal contraceptive androstanes and cyclohexanecarboxylic acids and naphthalenes",
        "code": "51351700"
      },
      { "name": "Drospirenone", "code": "51351701" },
      { "name": "Fenestrel", "code": "51351702" },
      { "name": "Methallenestril", "code": "51351703" },
      { "name": "Arzoxifene", "code": "51351704" },
      { "name": "Trioxifene", "code": "51351705" },
      { "name": "Arzoxifene hydrochloride", "code": "51351706" },
      { "name": "Trioxifene mesylate", "code": "51351707" },
      {
        "name": "Estrogen, progestin, or internal contraceptive gonadal hormones",
        "code": "51351800"
      },
      { "name": "Norvinisterone", "code": "51351801" },
      { "name": "Polyestradiol phosphate", "code": "51351802" },
      { "name": "Esterified estrogen", "code": "51351803" },
      { "name": "Estrogens conjugated", "code": "51351804" },
      {
        "name": "Estrogen, progestin, or internal contraceptive norpregnanes",
        "code": "51351900"
      },
      { "name": "Ethynerone", "code": "51351901" },
      { "name": "Ethynodiol diacetate", "code": "51351902" },
      { "name": "Etynodiol", "code": "51351903" },
      { "name": "Gestrinone", "code": "51351904" },
      { "name": "Mestranol", "code": "51351905" },
      { "name": "Moxestrol", "code": "51351906" },
      { "name": "Norgesterone", "code": "51351907" },
      { "name": "Norgestrienone", "code": "51351908" },
      { "name": "Promegestone", "code": "51351909" },
      { "name": "Quinestradol", "code": "51351910" },
      { "name": "Ulipristal", "code": "51351911" },
      { "name": "Ulipristal acetate", "code": "51351912" },
      { "name": "Quingestanol", "code": "51351913" },
      { "name": "Etynodiol diacetate", "code": "51351914" },
      { "name": "Quingestanol acetate", "code": "51351915" },
      {
        "name": "Estrogen, progestin, or internal contraceptive norpregnenes",
        "code": "51352000"
      },
      { "name": "Demegestone", "code": "51352001" },
      { "name": "Gestodene", "code": "51352002" },
      { "name": "Gestonorone", "code": "51352003" },
      { "name": "Desogestrel", "code": "51352004" },
      { "name": "Etonogestrel", "code": "51352005" },
      { "name": "Levonorgestrel", "code": "51352006" },
      { "name": "Norelgestromin", "code": "51352007" },
      { "name": "Norethindrone or norethisterone", "code": "51352008" },
      { "name": "Norethynodrel or noretynodrel", "code": "51352009" },
      { "name": "Norgestimate", "code": "51352010" },
      { "name": "Norgestrel", "code": "51352011" },
      { "name": "Ethinyl estradiol or ethinylestradiol", "code": "51352012" },
      { "name": "Gestonorone caproate", "code": "51352013" },
      {
        "name": "Estrogen, progestin, or internal contraceptive phenols",
        "code": "51352100"
      },
      { "name": "Dienestrol", "code": "51352101" },
      { "name": "Taleranol", "code": "51352102" },
      { "name": "Zearalenone or zeranol", "code": "51352103" },
      { "name": "Dienestrol diacetate", "code": "51352104" },
      {
        "name": "Estrogen, progestin, or internal contraceptive stilbenes",
        "code": "51352200"
      },
      { "name": "Benzestrol", "code": "51352201" },
      { "name": "Broparestrol", "code": "51352202" },
      { "name": "Chlorotrianisene", "code": "51352203" },
      { "name": "Diethylstilbestrol", "code": "51352204" },
      { "name": "Hexestrol", "code": "51352205" },
      { "name": "Diethylstilbestrol dipalmitate", "code": "51352206" },
      { "name": "Diethylstilbestrol diphosphate", "code": "51352207" },
      { "name": "Diethylstilbestrol dipropionate", "code": "51352208" },
      { "name": "Hexestrol diacetate", "code": "51352209" },
      { "name": "Hexestrol diphosphate", "code": "51352210" },
      { "name": "Hexestrol diphosphate sodium", "code": "51352211" },
      { "name": "Hexestrol dipropionate", "code": "51352212" },
      { "name": "Hexestrol monomethyl ether", "code": "51352213" },
      {
        "name": "Estrogens, progestins, or internal contraceptives, synthesized",
        "code": "51352300"
      },
      { "name": "Almestrone", "code": "51352301" },
      { "name": "Cloxestradiol", "code": "51352302" },
      { "name": "Dimepregnen", "code": "51352303" },
      { "name": "Estrofurate", "code": "51352304" },
      { "name": "Methestrol", "code": "51352305" },
      { "name": "Orestrate", "code": "51352306" },
      { "name": "Pentafluranol", "code": "51352307" },
      { "name": "Pipendoxifene", "code": "51352308" },
      { "name": "Toripristone", "code": "51352309" },
      { "name": "Estrazinol", "code": "51352310" },
      { "name": "Estrazinol hydrobromide", "code": "51352311" },
      {
        "name": "Combination contraceptives and estrogens",
        "code": "51352400"
      },
      {
        "name": "Ethinyl estradiol/ferrous fumarate/norethindrone",
        "code": "51352401"
      },
      { "name": "Chlordiazepoxide/estrogens", "code": "51352402" },
      { "name": "Dienogest/estradiol", "code": "51352403" },
      { "name": "Drospirenone/estradiol", "code": "51352404" },
      { "name": "Drospirenone/ethinyl estradiol", "code": "51352405" },
      {
        "name": "Drospirenone/ethinyl estradiol/levomefolate",
        "code": "51352406"
      },
      { "name": "Estradiol/levonorgestrel", "code": "51352407" },
      { "name": "Estrogens/estrone", "code": "51352408" },
      { "name": "Estrogens/meprobamate", "code": "51352409" },
      { "name": "Ethinyl estradiol/etonogestrel", "code": "51352410" },
      { "name": "Mestranol/norethynodrel", "code": "51352411" },
      {
        "name": "Ethinyl estradiol/ferrous fumarate/norgestrel",
        "code": "51352412"
      },
      { "name": "Ethinyl estradiol/levonorgestrel", "code": "51352413" },
      { "name": "Ethinyl estradiol/norelgestromin", "code": "51352414" },
      { "name": "Ethinyl estradiol/norethindrone", "code": "51352415" },
      { "name": "Ethinyl estradiol/norgestimate", "code": "51352416" },
      { "name": "Ethinyl estradiol/norgestrel", "code": "51352417" },
      { "name": "Ethynodiol/mestranol", "code": "51352418" },
      { "name": "Mestranol/norethindrone", "code": "51352419" },
      {
        "name": "Etonogestrel and ethinyl estradiol combination",
        "code": "51352420"
      },
      {
        "name": "Ethinyl estradiol and ethynodiol diacetate combination",
        "code": "51352421"
      },
      { "name": "Desogestrel and ethinyl estradiol", "code": "51352422" },
      { "name": "Estradiol and norethisterone acetate", "code": "51352423" },
      { "name": "Ethinyl estradiol/fluoxymesterone", "code": "51352424" },
      { "name": "Estradiol/hydroxyprogesterone", "code": "51352425" },
      { "name": "Estradiol/norethindrone", "code": "51352426" },
      { "name": "Estradiol/norgestimate", "code": "51352427" },
      { "name": "Estradiol/testosterone", "code": "51352428" },
      { "name": "Estrogens/methyltestosterone", "code": "51352429" },
      { "name": "Estrone/progesterone", "code": "51352430" },
      { "name": "Estrone/testosterone", "code": "51352431" },
      {
        "name": "Cyanocobalamin/estrogens/methamphetamine/methyltestosterone/thiamine",
        "code": "51352432"
      },
      {
        "name": "Estrogens/iron/methamphetamine/methyltestosterone/multivitamins",
        "code": "51352433"
      },
      {
        "name": "Ethinyl estradiol/methyltestosterone/multivitamins",
        "code": "51352434"
      },
      { "name": "Hypnotics", "code": "51360000" },
      { "name": "Hypnotic alcohols", "code": "51361500" },
      { "name": "Chloral hydrate", "code": "51361501" },
      { "name": "Cloretate or clorethate", "code": "51361502" },
      { "name": "Dichloralphenazone", "code": "51361503" },
      { "name": "Ethchlorvynol", "code": "51361504" },
      { "name": "Meparfynol or methylpentynol", "code": "51361505" },
      { "name": "Acetylglycinamide chloral hydrate", "code": "51361506" },
      { "name": "Chloralodol or chlorhexadol", "code": "51361507" },
      {
        "name": "Hypnotic aldehydes and amino acids and carbamates and imidazoles",
        "code": "51361600"
      },
      { "name": "Paraldehyde", "code": "51361601" },
      { "name": "Ethinamate", "code": "51361602" },
      { "name": "Tryptophan", "code": "51361603" },
      { "name": "Romifidine", "code": "51361604" },
      { "name": "Hypnotic amides", "code": "51361700" },
      { "name": "Butoctamide", "code": "51361701" },
      { "name": "Ibrotamide", "code": "51361702" },
      { "name": "Valnoctamide", "code": "51361703" },
      { "name": "Zaleplon", "code": "51361704" },
      { "name": "Butoctamide semisuccinate", "code": "51361705" },
      { "name": "Butoctamide succinate", "code": "51361706" },
      { "name": "Hypnotic azoles", "code": "51361800" },
      { "name": "Dexmedetomidine", "code": "51361801" },
      { "name": "Fenadiazole", "code": "51361802" },
      { "name": "Fenobam", "code": "51361803" },
      { "name": "Fepitrizol", "code": "51361804" },
      { "name": "Metomidate", "code": "51361805" },
      { "name": "Midaflur", "code": "51361806" },
      { "name": "Taniplon", "code": "51361807" },
      { "name": "Clidafidine", "code": "51361808" },
      { "name": "Dexmedetomidine hydrochloride", "code": "51361809" },
      { "name": "Metomidate hydrochloride", "code": "51361810" },
      { "name": "Hypnotic barbiturates", "code": "51361900" },
      { "name": "Butabarbital", "code": "51361901" },
      { "name": "Allobarbital", "code": "51361902" },
      { "name": "Amobarbital or amylobarbitone", "code": "51361903" },
      { "name": "Aprobarbital", "code": "51361904" },
      { "name": "Barbexaclone", "code": "51361905" },
      { "name": "Benzobarbital", "code": "51361906" },
      { "name": "Brallobarbital", "code": "51361907" },
      { "name": "Hexobarbital or hexobarbitone", "code": "51361908" },
      { "name": "Butalbital", "code": "51361909" },
      { "name": "Butobarbital or butobarbitone", "code": "51361910" },
      { "name": "Carbubarb", "code": "51361911" },
      {
        "name": "Cyclobarbital or cyclobarbitol or cyclobarbiton",
        "code": "51361912"
      },
      { "name": "Difebarbamate", "code": "51361913" },
      {
        "name": "Heptabarb or heptabarbital or heptabarbitone",
        "code": "51361914"
      },
      { "name": "Metharbital", "code": "51361915" },
      { "name": "Methitural", "code": "51361916" },
      { "name": "Pentobarbital or pentobarbitone", "code": "51361917" },
      { "name": "Phenobarbital", "code": "51361918" },
      { "name": "Proxibarbal or proxibarbital", "code": "51361919" },
      { "name": "Secobarbital", "code": "51361920" },
      { "name": "Talbutal", "code": "51361921" },
      { "name": "Thiobutabarbital", "code": "51361922" },
      { "name": "Thiotetrabarbital", "code": "51361923" },
      { "name": "Vinbarbital", "code": "51361924" },
      { "name": "Butylvinyl or vinylbital", "code": "51361925" },
      { "name": "Barbital or barbitone", "code": "51361926" },
      { "name": "Probarbital", "code": "51361927" },
      { "name": "Amobarbital sodium", "code": "51361928" },
      { "name": "Aprobarbital sodium", "code": "51361929" },
      { "name": "Barbital sodium", "code": "51361930" },
      { "name": "Brallobarbital calcium", "code": "51361931" },
      { "name": "Butabarbital sodium", "code": "51361932" },
      { "name": "Butalbital sodium", "code": "51361933" },
      { "name": "Hexobarbital sodium", "code": "51361934" },
      { "name": "Methitural sodium", "code": "51361935" },
      { "name": "Pentobarbital sodium", "code": "51361936" },
      { "name": "Phenobarbital diethylamine", "code": "51361937" },
      { "name": "Phenobarbital sodium", "code": "51361938" },
      { "name": "Probarbital sodium", "code": "51361939" },
      { "name": "Secobarbital sodium", "code": "51361940" },
      { "name": "Vinbarbital sodium", "code": "51361941" },
      { "name": "Hypnotic benzodiazepines", "code": "51362000" },
      { "name": "Cyprazepam", "code": "51362001" },
      { "name": "Estazolam", "code": "51362002" },
      { "name": "Flurazepam", "code": "51362003" },
      { "name": "Zolazepam", "code": "51362004" },
      { "name": "Flurazepam dihydrochloride", "code": "51362005" },
      { "name": "Flurazepam hydrochloride", "code": "51362006" },
      { "name": "Flurazepam monohydrochloride", "code": "51362007" },
      { "name": "Zolazepam hydrochloride", "code": "51362008" },
      { "name": "Hypnotic bromides", "code": "51362100" },
      { "name": "Bromamide", "code": "51362101" },
      { "name": "Bromisoval or bromvalerylurea", "code": "51362102" },
      { "name": "Sodium bromide", "code": "51362103" },
      { "name": "Hypnotic carboxylic acids", "code": "51362200" },
      { "name": "Aceburic acid", "code": "51362201" },
      { "name": "Carbocloral", "code": "51362202" },
      { "name": "Carfimate", "code": "51362203" },
      { "name": "Hexapropymate", "code": "51362204" },
      {
        "name": "Hypnotic pyridazines and pyridines and pyridones and secologanin tryptamine alkaloids",
        "code": "51362300"
      },
      { "name": "Cetohexazine", "code": "51362301" },
      { "name": "Zolpidem", "code": "51362302" },
      { "name": "Metoserpate", "code": "51362303" },
      { "name": "Pyrithyldione", "code": "51362304" },
      { "name": "Metoserpate hydrochloride", "code": "51362305" },
      { "name": "Zolpidem hemitartrate", "code": "51362306" },
      { "name": "Zolpidem tartrate", "code": "51362307" },
      {
        "name": "Hypnotic indenes and nicotinic acids and organophosphorus compounds and phenothiazines",
        "code": "51362400"
      },
      { "name": "Ramelteon", "code": "51362401" },
      { "name": "Niaprazine", "code": "51362402" },
      { "name": "Triclofos", "code": "51362403" },
      { "name": "Homofenazine", "code": "51362404" },
      { "name": "Homofenazine dihydrochloride", "code": "51362405" },
      { "name": "Homofenazine hydrochloride", "code": "51362406" },
      { "name": "Triclofos sodium", "code": "51362407" },
      { "name": "Hypnotic piperazines", "code": "51362500" },
      { "name": "Amperozide", "code": "51362501" },
      { "name": "Cloperidone", "code": "51362502" },
      { "name": "Eszopiclone", "code": "51362503" },
      { "name": "Etodroxizine", "code": "51362504" },
      { "name": "Iminophenimide", "code": "51362505" },
      { "name": "Zopiclone", "code": "51362506" },
      { "name": "Amperozide hydrochloride", "code": "51362507" },
      { "name": "Cloperidone hydrochloride", "code": "51362508" },
      { "name": "Etodroxizine dimaleate", "code": "51362509" },
      { "name": "Etodroxizine maleate", "code": "51362510" },
      { "name": "Hypnotic acetates", "code": "51362600" },
      { "name": "Gabapentin", "code": "51362601" },
      { "name": "Gabapentin enacarbil", "code": "51362602" },
      { "name": "Acebrochol", "code": "51362603" },
      { "name": "Hypnotic pyrrolidines", "code": "51362700" },
      { "name": "Lirequinil", "code": "51362701" },
      { "name": "Mefeclorazine", "code": "51362702" },
      { "name": "Hypnotic quinazolines", "code": "51362800" },
      { "name": "Methaqualone", "code": "51362801" },
      { "name": "Etaqualone", "code": "51362802" },
      { "name": "Methaqualone hydrochloride", "code": "51362803" },
      {
        "name": "Hypnotic thiazoles and thioxanthenes and urea analogs",
        "code": "51363000"
      },
      { "name": "Clomethiazole", "code": "51363001" },
      { "name": "Tixadil", "code": "51363002" },
      { "name": "Carbromal", "code": "51363003" },
      { "name": "Clomethiazole edisylate", "code": "51363004" },
      { "name": "Hypnotics, synthesized", "code": "51363100" },
      { "name": "Alonimid", "code": "51363101" },
      { "name": "Amiperone", "code": "51363102" },
      { "name": "Amphenidone", "code": "51363103" },
      { "name": "Ectylurea", "code": "51363104" },
      { "name": "Hypnotic valerates", "code": "51363200" },
      { "name": "Ethypicone", "code": "51363201" },
      { "name": "Apronal or apronalide", "code": "51363202" },
      { "name": "Hypnotic piperidines", "code": "51363300" },
      { "name": "Glutethimide", "code": "51363301" },
      { "name": "Methyprylon", "code": "51363302" },
      { "name": "Tameridone", "code": "51363303" },
      { "name": "Rogletimide", "code": "51363304" },
      { "name": "Hypnotic ureas", "code": "51363400" },
      { "name": "Acecarbromal", "code": "51363401" },
      { "name": "Capuride", "code": "51363402" },
      { "name": "Combination hypnotics or sedatives", "code": "51363500" },
      { "name": "Amobarbital/secobarbital", "code": "51363501" },
      { "name": "Aprobarbital/butabarbital/phenobarbital", "code": "51363502" },
      {
        "name": "Barbital/hyoscyamine/hyoscyamus/passion/scopolamine/valerian",
        "code": "51363503"
      },
      { "name": "Butabarbital/phenobarbital/secobarbital", "code": "51363504" },
      {
        "name": "Hyoscyamus/passion flower/phenobarbital/thiamine/valerian",
        "code": "51363505"
      },
      { "name": "Hypnotic oximes", "code": "51363600" },
      { "name": "Benolizime", "code": "51363601" },
      { "name": "Violuric acid", "code": "51363602" },
      { "name": "Controlled substance analgesics", "code": "51370000" },
      {
        "name": "Controlled substance analgesic butanones and cyclohexanecarboxylic acids and phenethylamines and pyridines and tetrahydronaphthalenes",
        "code": "51371500"
      },
      { "name": "Dezocine", "code": "51371501" },
      { "name": "Tilidine", "code": "51371502" },
      { "name": "Desmethylmoramide", "code": "51371503" },
      { "name": "Doxpicomine", "code": "51371504" },
      { "name": "Lefetamine", "code": "51371505" },
      { "name": "Doxpicomine hydrochloride", "code": "51371506" },
      { "name": "Lefetamine hydrochloride", "code": "51371507" },
      { "name": "Tilidine fumarate", "code": "51371508" },
      { "name": "Tilidine hydrochloride", "code": "51371509" },
      { "name": "Tilidine phosphate", "code": "51371510" },
      { "name": "Controlled substance analgesic alcohols", "code": "51371600" },
      { "name": "Tramadol", "code": "51371601" },
      { "name": "Betametadol or betamethadol", "code": "51371602" },
      { "name": "Alfametadol or alphamethadol", "code": "51371603" },
      { "name": "Tramadol hydrochloride", "code": "51371604" },
      {
        "name": "Controlled substance analgesic indole alkaloids",
        "code": "51371700"
      },
      { "name": "Narceine", "code": "51371701" },
      { "name": "Opium", "code": "51371702" },
      { "name": "Narceine hydrochloride", "code": "51371703" },
      {
        "name": "Controlled substance analgesic isonipecotic acids",
        "code": "51371800"
      },
      { "name": "Promedol or trimeperidine", "code": "51371801" },
      { "name": "Ketobemidone", "code": "51371802" },
      { "name": "Meperidine or pethidine", "code": "51371803" },
      { "name": "Piritramide", "code": "51371804" },
      { "name": "Ketobemidone hydrochloride", "code": "51371805" },
      { "name": "Pethidine hydrochloride", "code": "51371806" },
      { "name": "Trimeperidine hydrochloride", "code": "51371807" },
      {
        "name": "Controlled substance analgesic methadones",
        "code": "51371900"
      },
      { "name": "Acetylmethadol or methadyl acetate", "code": "51371901" },
      { "name": "Betacetylmethadol", "code": "51371902" },
      {
        "name": "Dimepheptanol or methadol or racemethadol",
        "code": "51371903"
      },
      { "name": "Dipipanone", "code": "51371904" },
      { "name": "Isomethadone", "code": "51371905" },
      {
        "name": "Levacetylmethadol or levomethadyl acetate",
        "code": "51371906"
      },
      { "name": "Methadone", "code": "51371907" },
      { "name": "Acetylmethadol hydrochloride", "code": "51371908" },
      { "name": "Dipipanone hydrochloride", "code": "51371909" },
      { "name": "Methadone hydrochloride", "code": "51371910" },
      {
        "name": "Controlled substance analgesic morphinans",
        "code": "51372000"
      },
      { "name": "Desomorphine", "code": "51372001" },
      { "name": "Alletorphine", "code": "51372002" },
      { "name": "Buprenorphine", "code": "51372003" },
      { "name": "Butorphanol", "code": "51372004" },
      { "name": "Cyprenorphine", "code": "51372005" },
      { "name": "Methyldesorphine", "code": "51372006" },
      { "name": "Etorphine", "code": "51372007" },
      { "name": "Homprenorphine", "code": "51372008" },
      { "name": "Hydromorphinol", "code": "51372009" },
      { "name": "Ketorfanol", "code": "51372010" },
      { "name": "Levophenacylmorphan", "code": "51372011" },
      { "name": "Levorphanol", "code": "51372012" },
      { "name": "Thebacon", "code": "51372013" },
      { "name": "Metopon", "code": "51372014" },
      { "name": "Morphine", "code": "51372015" },
      { "name": "Nalbuphine", "code": "51372016" },
      { "name": "Pholcodine", "code": "51372017" },
      { "name": "Nalfurafine", "code": "51372018" },
      { "name": "Levomethorphan", "code": "51372019" },
      { "name": "Acetorphine", "code": "51372020" },
      { "name": "Acetorphine hydrochloride", "code": "51372021" },
      { "name": "Buprenorphine hydrochloride", "code": "51372022" },
      { "name": "Butorphanol tartrate", "code": "51372023" },
      { "name": "Cyprenorphine hydrochloride", "code": "51372024" },
      { "name": "Etorphine hydrochloride", "code": "51372025" },
      { "name": "Levomethorphan hydrobromide", "code": "51372026" },
      { "name": "Levorphanol tartrate", "code": "51372027" },
      { "name": "Levorphanol tartrate anhydrous", "code": "51372028" },
      { "name": "Metopon hydrochloride", "code": "51372029" },
      { "name": "Morphine dinicotinate hydrochloride", "code": "51372030" },
      { "name": "Morphine hydrochloride", "code": "51372031" },
      { "name": "Morphine hydrochloride trihydrate", "code": "51372032" },
      { "name": "Morphine methylbromide", "code": "51372033" },
      { "name": "Morphine monohydrate", "code": "51372034" },
      { "name": "Morphine oxide", "code": "51372035" },
      { "name": "Morphine sulfate", "code": "51372036" },
      { "name": "Morphine sulfate anhydrous", "code": "51372037" },
      { "name": "Morphine sulfate pentahydrate", "code": "51372038" },
      { "name": "Morphine tartrate", "code": "51372039" },
      { "name": "Nalbuphine hydrochloride", "code": "51372040" },
      { "name": "Nalfurafine hydrochloride", "code": "51372041" },
      { "name": "Thebacon hydrochloride", "code": "51372042" },
      {
        "name": "Controlled substance analgesic morphine derivatives",
        "code": "51372100"
      },
      { "name": "Acetyldihydrocodeine", "code": "51372101" },
      { "name": "3-methylmorphine or codeine", "code": "51372102" },
      {
        "name": "Diacetylmorphine or diamorphine or heroin or morphine diacetate",
        "code": "51372103"
      },
      { "name": "Dihydrocodeine", "code": "51372104" },
      { "name": "Ethylmorphine", "code": "51372105" },
      { "name": "Hydrocodone or dihydrocodeinone", "code": "51372106" },
      { "name": "Hydromorphone", "code": "51372107" },
      { "name": "Oxycodone", "code": "51372108" },
      { "name": "Oxymorphone", "code": "51372109" },
      { "name": "Nicomorphine", "code": "51372110" },
      { "name": "Acetyldihydrocodeine hydrochloride", "code": "51372111" },
      { "name": "Codeine hydrobromide", "code": "51372112" },
      { "name": "Codeine hydrochloride", "code": "51372113" },
      { "name": "Codeine hydrochloride dihydrate", "code": "51372114" },
      { "name": "Codeine phosphate hemihydrate", "code": "51372115" },
      { "name": "Codeine sulfate", "code": "51372116" },
      { "name": "Dihydrocodeine bitartrate", "code": "51372117" },
      { "name": "Dihydrocodeine phosphate", "code": "51372118" },
      { "name": "Dihydrocodeine tartrate", "code": "51372119" },
      { "name": "Dihydrocodeine thiocyanate", "code": "51372120" },
      { "name": "Ethylmorphine hydrochloride", "code": "51372121" },
      { "name": "Hydrocodone bitartrate", "code": "51372122" },
      { "name": "Hydrocodone hydrochloride", "code": "51372123" },
      { "name": "Hydrocodone tannate", "code": "51372124" },
      { "name": "Hydromorphone hydrochloride", "code": "51372125" },
      { "name": "Nicomorphine hydrochloride", "code": "51372126" },
      { "name": "Oxycodone hydrochloride", "code": "51372127" },
      { "name": "Oxycodone terephthalate", "code": "51372128" },
      { "name": "Oxymorphone hydrochloride", "code": "51372129" },
      {
        "name": "Controlled substance analgesic morpholines",
        "code": "51372200"
      },
      { "name": "Dextromoramide", "code": "51372201" },
      { "name": "Dextropropoxyphene or propoxyphene", "code": "51372202" },
      { "name": "Levomoramide", "code": "51372203" },
      { "name": "Phenadoxone", "code": "51372204" },
      { "name": "Racemoramide", "code": "51372205" },
      { "name": "Dextromoramide bitartrate", "code": "51372206" },
      { "name": "Dextromoramide tartrate", "code": "51372207" },
      { "name": "Dextropropoxyphene hydrochloride", "code": "51372208" },
      { "name": "Dextropropoxyphene napsilate", "code": "51372209" },
      {
        "name": "Controlled substance analgesic piperidines",
        "code": "51372300"
      },
      { "name": "Meprodine", "code": "51372301" },
      { "name": "Alphaprodine", "code": "51372302" },
      { "name": "Prodine", "code": "51372303" },
      { "name": "Carperidine", "code": "51372304" },
      { "name": "Fentanyl", "code": "51372305" },
      { "name": "Hydroxypethidine", "code": "51372306" },
      { "name": "Loperamide oxide", "code": "51372307" },
      { "name": "Ocfentanil", "code": "51372308" },
      { "name": "Carbamethidine or oxpheneridine", "code": "51372309" },
      { "name": "Pheneridine", "code": "51372310" },
      { "name": "Picenadol", "code": "51372311" },
      { "name": "Properidine", "code": "51372312" },
      { "name": "Piminodine", "code": "51372313" },
      { "name": "Mirfentanil", "code": "51372314" },
      { "name": "Carfentanil", "code": "51372315" },
      { "name": "Phenampromide", "code": "51372316" },
      { "name": "Norpipanone", "code": "51372317" },
      { "name": "Allylprodine", "code": "51372318" },
      { "name": "Alphaprodine hydrochloride", "code": "51372319" },
      { "name": "Carfentanil citrate", "code": "51372320" },
      { "name": "Fentanyl citrate", "code": "51372321" },
      { "name": "Fentanyl hydrochloride", "code": "51372322" },
      { "name": "Mirfentanil hydrochloride", "code": "51372323" },
      { "name": "Ocfentanil hydrochloride", "code": "51372324" },
      { "name": "Picenadol hydrochloride", "code": "51372325" },
      { "name": "Piminodine esylate", "code": "51372326" },
      {
        "name": "Controlled substance analgesic propionates",
        "code": "51372400"
      },
      { "name": "Proheptazine", "code": "51372402" },
      {
        "name": "Controlled substance analgesics, synthesized",
        "code": "51372500"
      },
      { "name": "Metheptazine", "code": "51372501" },
      {
        "name": "Controlled substance analgesic amides and amines and benzofurans and benzylamines",
        "code": "51372600"
      },
      { "name": "Diampromide", "code": "51372601" },
      { "name": "Enadoline", "code": "51372602" },
      { "name": "Ciramadol", "code": "51372603" },
      { "name": "Dimenoxadol", "code": "51372604" },
      { "name": "Ciramadol hydrochloride", "code": "51372605" },
      { "name": "Enadoline hydrochloride", "code": "51372606" },
      { "name": "Controlled substance analgesic azepines", "code": "51372700" },
      { "name": "Meptazinol", "code": "51372701" },
      { "name": "Metethoheptazine", "code": "51372702" },
      { "name": "Meptazinol hydrochloride", "code": "51372703" },
      { "name": "Controlled substanceanalgesic azocines", "code": "51372800" },
      { "name": "Ketazocine", "code": "51372801" },
      { "name": "Quadazocine", "code": "51372802" },
      { "name": "Nefopam", "code": "51372803" },
      { "name": "Eptazocine", "code": "51372804" },
      { "name": "Eptazocine hydrobromide", "code": "51372805" },
      { "name": "Nefopam hydrochloride", "code": "51372806" },
      { "name": "Quadazocine mesylate", "code": "51372807" },
      {
        "name": "Controlled substance analgesic benzimidazoles",
        "code": "51372900"
      },
      { "name": "Bezitramide", "code": "51372901" },
      { "name": "Clonitazene", "code": "51372902" },
      { "name": "Etonitazene", "code": "51372903" },
      { "name": "Etonitazene hydrochloride", "code": "51372904" },
      {
        "name": "Controlled substance analgesic benzomorphans",
        "code": "51373000"
      },
      { "name": "Anazocine", "code": "51373001" },
      { "name": "Bremazocine", "code": "51373002" },
      { "name": "Pentazocine", "code": "51373003" },
      { "name": "Phenazocine", "code": "51373004" },
      { "name": "Moxazocine", "code": "51373005" },
      { "name": "Pentazocine hydrochloride", "code": "51373006" },
      { "name": "Pentazocine lactate", "code": "51373007" },
      { "name": "Phenazocine hydrobromide", "code": "51373008" },
      {
        "name": "Controlled substance analgesic thiophenes",
        "code": "51373100"
      },
      { "name": "Diethylthiambutene", "code": "51373101" },
      { "name": "Ethylmethylthiambutene", "code": "51373102" },
      { "name": "Diethylthiambutene hydrochloride", "code": "51373103" },
      { "name": "Controlled substance analgesic phenols", "code": "51373200" },
      { "name": "Dioxaphetyl", "code": "51373201" },
      { "name": "Tapentadol", "code": "51373202" },
      { "name": "Dioxaphetyl butyrate", "code": "51373203" },
      { "name": "Tapentadol hydrochloride", "code": "51373204" },
      { "name": "Combination opioid analgesics", "code": "51373300" },
      {
        "name": "Acetaminophen/dihydrocodeine/salicylamide",
        "code": "51373301"
      },
      {
        "name": "Acetaminophen/aspirin/caffeine/codeine/salicylamide",
        "code": "51373302"
      },
      {
        "name": "Acetaminophen/aspirin/caffeine/hydrocodone",
        "code": "51373303"
      },
      { "name": "Acetaminophen/aspirin/codeine", "code": "51373304" },
      {
        "name": "Acetaminophen/butalbital/caffeine/codeine",
        "code": "51373305"
      },
      {
        "name": "Acetaminophen/butalbital/caffeine/hydrocodone",
        "code": "51373306"
      },
      { "name": "Acetaminophen/butalbital/codeine", "code": "51373307" },
      {
        "name": "Acetaminophen/caffeine/codeine/salicylamide",
        "code": "51373308"
      },
      { "name": "Acetaminophen/caffeine/dihydrocodeine", "code": "51373309" },
      { "name": "Acetaminophen/codeine", "code": "51373310" },
      { "name": "Aspirin/caffeine/dihydrocodeine", "code": "51373311" },
      { "name": "Acetaminophen/hydrocodone", "code": "51373312" },
      { "name": "Acetaminophen/meperidine", "code": "51373313" },
      { "name": "Acetaminophen/oxycodone", "code": "51373314" },
      { "name": "Acetaminophen/pentazocine", "code": "51373315" },
      { "name": "Acetaminophen/propoxyphene", "code": "51373316" },
      {
        "name": "Aluminum hydroxide/aspirin/codeine/magnesium hydroxide",
        "code": "51373317"
      },
      {
        "name": "Aspirin/atropine/caffeine/camphor/ipecac/opium/phenacetin",
        "code": "51373318"
      },
      { "name": "Aspirin/butalbital/caffeine/codeine", "code": "51373319" },
      { "name": "Aspirin/caffeine/codeine", "code": "51373320" },
      { "name": "Aspirin/propoxyphene", "code": "51373321" },
      {
        "name": "Aspirin/caffeine/dihydrocodeine/promethazine",
        "code": "51373322"
      },
      { "name": "Aspirin/caffeine/hydrocodone", "code": "51373323" },
      { "name": "Aspirin/caffeine/ipecac/opium", "code": "51373324" },
      { "name": "Aspirin/caffeine/propoxyphene", "code": "51373325" },
      { "name": "Aspirin/carisoprodol/codeine", "code": "51373326" },
      { "name": "Aspirin/codeine", "code": "51373327" },
      { "name": "Aspirin/hydrocodone", "code": "51373328" },
      { "name": "Aspirin/oxycodone", "code": "51373329" },
      { "name": "Aspirin/pentazocine", "code": "51373330" },
      { "name": "Naloxone/pentazocine", "code": "51373331" },
      { "name": "Atropine/meperidine", "code": "51373332" },
      { "name": "Belladonna/opium", "code": "51373333" },
      { "name": "Bupivacaine/fentanyl", "code": "51373334" },
      { "name": "Bupivacaine/hydromorphone", "code": "51373335" },
      { "name": "Buprenorphine/naloxone", "code": "51373336" },
      { "name": "Droperidol/fentanyl", "code": "51373337" },
      { "name": "Hydrocodone/ibuprofen", "code": "51373338" },
      { "name": "Meperidine/promethazine", "code": "51373339" },
      { "name": "Morphine/naltrexone", "code": "51373340" },
      {
        "name": "Combination opioid-containing antitussives and expectorants",
        "code": "51373400"
      },
      {
        "name": "Ammonium/bromodiphenhydramine/codeine/menthol/potassium",
        "code": "51373401"
      },
      {
        "name": "Acetaminophen/caffeine/chlorpheniramine/hydrocodone/phenylephrine",
        "code": "51373402"
      },
      {
        "name": "Acetaminophen/chlorpheniramine/codeine/phenylephrine",
        "code": "51373403"
      },
      {
        "name": "Acetaminophen/codeine/guaifenesin/phenylpropanolamine",
        "code": "51373404"
      },
      {
        "name": "Ammonium chloride/chlorpheniramine/codeine/phenylephrine",
        "code": "51373405"
      },
      {
        "name": "Ammonium chloride/chlorpheniramine/codeine/phenylephrine/potassium",
        "code": "51373406"
      },
      {
        "name": "Ammonium/antimony/cherry/codeine/pine/potassium",
        "code": "51373407"
      },
      {
        "name": "Ammonium/antimony/chlorpheniramine/codeine/potassium guaiacolsulfonate",
        "code": "51373408"
      },
      {
        "name": "Ammonium/antimony/codeine/phenylephrine/potassium/pyrilamine",
        "code": "51373409"
      },
      {
        "name": "Ammonium/bromodiphenhydramine/codeine/diphenhydramine/potassium",
        "code": "51373410"
      },
      { "name": "Chlorpheniramine/codeine", "code": "51373411" },
      {
        "name": "Ascorbic acid/hydrocodone/pheniramine/potassium citrate/pyrilamine",
        "code": "51373412"
      },
      { "name": "Bromodiphenhydramine/codeine", "code": "51373413" },
      {
        "name": "Brompheniramine/codeine/guaifenesin/menthol/phenylephrine/phenylpropanolamine",
        "code": "51373414"
      },
      {
        "name": "Brompheniramine/codeine/guaifenesin/phenylephrine/phenylpropanol",
        "code": "51373415"
      },
      { "name": "Brompheniramine/codeine/phenylephrine", "code": "51373416" },
      {
        "name": "Brompheniramine/codeine/phenylpropanolamine",
        "code": "51373417"
      },
      {
        "name": "Caffeine/codeine/menthol/pheniramine/phenylephrine/sodium/sodium",
        "code": "51373418"
      },
      { "name": "Calcium iodide/codeine", "code": "51373419" },
      {
        "name": "Carbetapentane/chlorpheniramine/citric/codeine/guaifenesin/sodium salicylate",
        "code": "51373420"
      },
      {
        "name": "Chlorpheniramine/hydrocodone/nh4/phenindamine/phenylephrine/pyrilamine",
        "code": "51373421"
      },
      {
        "name": "Chlorpheniramine/codeine/glycerol, iodinated",
        "code": "51373422"
      },
      { "name": "Chlorpheniramine/codeine/phenylephrine", "code": "51373423" },
      {
        "name": "Chlorpheniramine/codeine/phenylephrine/phenylpropanolamine",
        "code": "51373424"
      },
      {
        "name": "Chlorpheniramine/codeine/phenylephrine/potassium iodide",
        "code": "51373425"
      },
      {
        "name": "Chlorpheniramine/codeine/phenylpropanolamine",
        "code": "51373426"
      },
      {
        "name": "Chlorpheniramine/codeine/pseudoephedrine",
        "code": "51373427"
      },
      {
        "name": "Chlorpheniramine/dihydrocodeine/phenylephrine/phenylpropanolamine",
        "code": "51373428"
      },
      { "name": "Chlorpheniramine/hydrocodone", "code": "51373429" },
      {
        "name": "Chlorpheniramine/hydrocodone/menthol/pseudoephedrine",
        "code": "51373430"
      },
      { "name": "Codeine/guaifenesin/phenylephrine", "code": "51373431" },
      {
        "name": "Chlorpheniramine/hydrocodone/phenindamine/phenylephrine/pyrilamine",
        "code": "51373432"
      },
      {
        "name": "Chlorpheniramine/hydrocodone/phenylephrine",
        "code": "51373433"
      },
      {
        "name": "Chlorpheniramine/hydrocodone/pseudoephedrine",
        "code": "51373434"
      },
      {
        "name": "Citric/codeine/ipecac/potassium/promethazine/sodium",
        "code": "51373435"
      },
      {
        "name": "Citric/codeine/ipecac/potassium/promethazine/sodium citrate",
        "code": "51373436"
      },
      { "name": "Codeine/ephedrine/guaifenesin", "code": "51373437" },
      { "name": "Codeine/glycerol, iodinated", "code": "51373438" },
      { "name": "Codeine/guaifenesin", "code": "51373439" },
      { "name": "Codeine/guaifenesin/pheniramine", "code": "51373440" },
      { "name": "Codeine/phenylephrine/promethazine", "code": "51373441" },
      { "name": "Codeine/guaifenesin/phenylpropanolamine", "code": "51373442" },
      { "name": "Codeine/guaifenesin/pseudoephedrine", "code": "51373443" },
      {
        "name": "Codeine/guaifenesin/pseudoephedrine/triprolidine",
        "code": "51373444"
      },
      {
        "name": "Codeine/ipecac/phenylephrine/potassium/promethazine",
        "code": "51373445"
      },
      {
        "name": "Codeine/ipecac/potassium guaiacolsulfonate/promethazine",
        "code": "51373446"
      },
      {
        "name": "Codeine/menthol/pheniramine/phenylephrine",
        "code": "51373447"
      },
      { "name": "Codeine/papaverine", "code": "51373448" },
      {
        "name": "Codeine/phenylephrine/phenylpropanolamine",
        "code": "51373449"
      },
      {
        "name": "Codeine/phenylephrine/potassium guaiacolsulfonate/promethazine",
        "code": "51373450"
      },
      { "name": "Codeine/terpin hydrate", "code": "51373451" },
      { "name": "Codeine/phenylephrine/pseudoephedrine", "code": "51373452" },
      { "name": "Codeine/phenylephrine/pyrilamine", "code": "51373453" },
      {
        "name": "Codeine/phenylpropanolamine/promethazine",
        "code": "51373454"
      },
      { "name": "Codeine/potassium citrate", "code": "51373455" },
      {
        "name": "Codeine/potassium guaiacolsulfonate/promethazine",
        "code": "51373456"
      },
      { "name": "Codeine/promethazine", "code": "51373457" },
      { "name": "Codeine/pseudoephedrine", "code": "51373458" },
      { "name": "Codeine/pseudoephedrine/triprolidine", "code": "51373459" },
      { "name": "Codeine/pyrilamine", "code": "51373460" },
      {
        "name": "Hydrocodone/carbinoxamine/pseudoephedrine",
        "code": "51373461"
      },
      {
        "name": "Dihydrocodeine/guaifenesin/pheniramine/phenylephrine/phenylpropanolamine",
        "code": "51373462"
      },
      { "name": "Guaifenesin/hydrocodone", "code": "51373463" },
      { "name": "Guaifenesin/hydrocodone/phenindamine", "code": "51373464" },
      {
        "name": "Guaifenesin/hydrocodone/pheniramine/phenylpropanolamine/pyrilamine",
        "code": "51373465"
      },
      { "name": "Guaifenesin/hydrocodone/phenylephrine", "code": "51373466" },
      {
        "name": "Guaifenesin/hydrocodone/phenylpropanolamine/salicylamide",
        "code": "51373467"
      },
      { "name": "Guaifenesin/hydrocodone/pseudoephedrine", "code": "51373468" },
      { "name": "Guaifenesin/hydromorphone", "code": "51373469" },
      { "name": "Homatropine/hydrocodone", "code": "51373470" },
      {
        "name": "Hydrocodone/pheniramine/phenylephrine/phenylpropanolamine/pyrilamine",
        "code": "51373471"
      },
      { "name": "Hydrocodone/phenylephrine", "code": "51373472" },
      { "name": "Hydrocodone/phenylephrine/pyrilamine", "code": "51373473" },
      { "name": "Hydrocodone/phenylpropanolamine", "code": "51373474" },
      { "name": "Hydrocodone/phenyltoloxamine", "code": "51373475" },
      { "name": "Hydrocodone/potassium guaiacolsulfonate", "code": "51373476" },
      { "name": "Hydrocodone/pseudoephedrine", "code": "51373477" },
      {
        "name": "Morphine/potassium citrate/potassium guaiacolsulfonate",
        "code": "51373478"
      },
      {
        "name": "Nonsteroidal anti inflammatory drugs NSAIDs",
        "code": "51380000"
      },
      {
        "name": "Nonsteroidal anti inflammatory benzoates",
        "code": "51381500"
      },
      { "name": "Colfenamate", "code": "51381501" },
      { "name": "Etofenamate", "code": "51381502" },
      { "name": "Floctafenine", "code": "51381503" },
      { "name": "Mefenamic acid", "code": "51381504" },
      { "name": "Mesalamine or mesalazine", "code": "51381505" },
      { "name": "Morniflumate", "code": "51381506" },
      { "name": "Talosalate", "code": "51381507" },
      { "name": "Tolmetin", "code": "51381508" },
      { "name": "Triflumidate", "code": "51381509" },
      { "name": "Flufenamic acid", "code": "51381510" },
      { "name": "Glafenine", "code": "51381511" },
      { "name": "Meclofenamic acid", "code": "51381512" },
      { "name": "Glafenine hydrochloride", "code": "51381513" },
      { "name": "Tolmetin glycine amide", "code": "51381514" },
      { "name": "Tolmetin sodium", "code": "51381515" },
      { "name": "Tolmetin sodium dihydrate", "code": "51381516" },
      {
        "name": "Nonsteroidal anti inflammatory acetamides",
        "code": "51381600"
      },
      { "name": "Difenpiramide", "code": "51381601" },
      { "name": "Parcetasal", "code": "51381602" },
      {
        "name": "Nonsteroidal anti inflammatory acetanilides",
        "code": "51381700"
      },
      { "name": "Acetaminophen or paracetamol", "code": "51381701" },
      { "name": "Benorilate", "code": "51381702" },
      { "name": "Acetaminophen glucuronide", "code": "51381703" },
      { "name": "Acetaminophen mercapturate", "code": "51381704" },
      { "name": "Acetaminophen sulfate", "code": "51381705" },
      { "name": "Nonsteroidal anti inflammatory acetates", "code": "51381800" },
      { "name": "Fentiazac", "code": "51381801" },
      { "name": "Oxepinac", "code": "51381802" },
      { "name": "Talmetacin", "code": "51381803" },
      { "name": "Nonsteroidal anti inflammatory azoles", "code": "51381900" },
      { "name": "Ebselen", "code": "51381901" },
      { "name": "Fenpipalone", "code": "51381902" },
      { "name": "Mofezolac", "code": "51381903" },
      { "name": "Oxolamine", "code": "51381904" },
      { "name": "Parecoxib", "code": "51381905" },
      { "name": "Valdecoxib", "code": "51381906" },
      { "name": "Oxolamine citrate", "code": "51381907" },
      { "name": "Oxolamine hydrochloride", "code": "51381908" },
      { "name": "Oxolamine phosphate", "code": "51381909" },
      { "name": "Parecoxib sodium", "code": "51381910" },
      {
        "name": "Nonsteroidal anti inflammatory benzeneacetamides and benzodiazepines and benzoxazoles and butanones",
        "code": "51382000"
      },
      { "name": "Bufexamac", "code": "51382001" },
      { "name": "Lufuradom", "code": "51382002" },
      { "name": "Flunoxaprofen", "code": "51382003" },
      { "name": "Nabumetone", "code": "51382004" },
      {
        "name": "Nonsteroidal anti inflammatory carbazoles",
        "code": "51382100"
      },
      { "name": "Araprofen", "code": "51382101" },
      { "name": "Carprofen", "code": "51382102" },
      { "name": "Carprofen for veterinary use", "code": "51382103" },
      {
        "name": "Nonsteroidal anti inflammatory benzofurans",
        "code": "51382200"
      },
      { "name": "Clofurac", "code": "51382201" },
      { "name": "Talniflumate", "code": "51382202" },
      {
        "name": "Nonsteroidal anti inflammatory benzoic acids",
        "code": "51382300"
      },
      { "name": "Dipyrocetyl", "code": "51382301" },
      { "name": "Enfenamic acid", "code": "51382302" },
      { "name": "Feclobuzone", "code": "51382303" },
      { "name": "Niflumic acid", "code": "51382304" },
      { "name": "Tolfenamic acid", "code": "51382305" },
      {
        "name": "Nonsteroidal anti inflammatory benzophenones",
        "code": "51382400"
      },
      { "name": "Bromfenac", "code": "51382401" },
      { "name": "Diflumidone", "code": "51382402" },
      { "name": "Bromfenac sodium", "code": "51382403" },
      { "name": "Bromfenac sodium hydrate", "code": "51382404" },
      { "name": "Bromfenac sodium sesquihydrate", "code": "51382405" },
      { "name": "Diflumidone sodium", "code": "51382406" },
      {
        "name": "Nonsteroidal anti inflammatory butylated hydroxytoluene/analogs and derivatives and butyric acids and glycosides and guanidines",
        "code": "51382500"
      },
      { "name": "Prifelone", "code": "51382501" },
      { "name": "Namoxyrate", "code": "51382502" },
      { "name": "Tribenoside", "code": "51382503" },
      { "name": "Timegadine", "code": "51382504" },
      {
        "name": "Nonsteroidal anti inflammatory carbamates",
        "code": "51382600"
      },
      { "name": "Carbasalate calcium", "code": "51382601" },
      { "name": "Lotifazole", "code": "51382602" },
      { "name": "Tybamate", "code": "51382603" },
      { "name": "Nonsteroidal anti inflammatory indenes", "code": "51382700" },
      { "name": "Oxindanac", "code": "51382701" },
      { "name": "Sulindac", "code": "51382702" },
      { "name": "Sulindac sodium", "code": "51382703" },
      { "name": "Sulindac sulfide", "code": "51382704" },
      { "name": "Sulindac sulfone", "code": "51382705" },
      {
        "name": "Nonsteroidal anti inflammatory glycolates",
        "code": "51382800"
      },
      { "name": "Clofexamide", "code": "51382801" },
      { "name": "Clofezone", "code": "51382802" },
      { "name": "Clofezone anhydrous", "code": "51382803" },
      { "name": "Clofezone dihydrate", "code": "51382804" },
      {
        "name": "Nonsteroidal anti inflammatory hexosamines and hydroxamic acids and isoquinolines and lactones",
        "code": "51382900"
      },
      { "name": "Glucosamine", "code": "51382901" },
      { "name": "Ibuproxam", "code": "51382902" },
      { "name": "Tesimide", "code": "51382903" },
      { "name": "Rofecoxib", "code": "51382904" },
      { "name": "Glucosamine hydrochloride", "code": "51382905" },
      { "name": "Glucosamine sulfate", "code": "51382906" },
      {
        "name": "Nonsteroidal anti inflammatory imidazoles",
        "code": "51383000"
      },
      { "name": "Fenflumizol or fenflumizole", "code": "51383001" },
      { "name": "Nimazone", "code": "51383002" },
      { "name": "Tomoxiprole", "code": "51383003" },
      {
        "name": "Nonsteroidal anti inflammatory dibenzoxepins",
        "code": "51383100"
      },
      { "name": "Bermoprofen", "code": "51383101" },
      { "name": "Oxaceprol", "code": "51383102" },
      { "name": "Quinupramine", "code": "51383103" },
      { "name": "Nonsteroidal anti inflammatory indans", "code": "51383200" },
      { "name": "Clidanac", "code": "51383201" },
      { "name": "Flosulide", "code": "51383202" },
      {
        "name": "Nonsteroidal anti inflammatory phenylacetates",
        "code": "51383300"
      },
      { "name": "Lumiracoxib", "code": "51383301" },
      { "name": "Aceclofenac", "code": "51383302" },
      { "name": "Amfenac", "code": "51383303" },
      { "name": "Diclofenac", "code": "51383304" },
      { "name": "Felbinac", "code": "51383305" },
      { "name": "Fenclorac", "code": "51383306" },
      { "name": "Nepafenac", "code": "51383307" },
      { "name": "Robenacoxib", "code": "51383308" },
      { "name": "Alclofenac", "code": "51383309" },
      { "name": "Amfenac sodium", "code": "51383310" },
      { "name": "Diclofenac diethylamine", "code": "51383311" },
      { "name": "Diclofenac epolamine", "code": "51383312" },
      { "name": "Diclofenac hydroxyethylpyrrolidine", "code": "51383313" },
      { "name": "Diclofenac potassium", "code": "51383314" },
      { "name": "Diclofenac sodium", "code": "51383315" },
      { "name": "Felbinac ethyl", "code": "51383316" },
      { "name": "Felbinac ethyl ester", "code": "51383317" },
      {
        "name": "Nonsteroidal anti inflammatory indoleacetic acids",
        "code": "51383400"
      },
      { "name": "Acemetacin", "code": "51383401" },
      { "name": "Etodolac", "code": "51383402" },
      { "name": "Zidometacin", "code": "51383403" },
      { "name": "Proglumetacin", "code": "51383404" },
      { "name": "Proglumetacin dimaleate", "code": "51383405" },
      { "name": "Proglumetacin maleate", "code": "51383406" },
      { "name": "Nonsteroidal anti inflammatory indoles", "code": "51383500" },
      { "name": "Tenidap", "code": "51383501" },
      { "name": "Delmetacin", "code": "51383502" },
      { "name": "Glucametacin", "code": "51383503" },
      { "name": "Ketorolac", "code": "51383504" },
      { "name": "Oxametacin or oxamethacin", "code": "51383505" },
      { "name": "Pravadoline", "code": "51383506" },
      { "name": "Indometacin or indomethacin", "code": "51383507" },
      { "name": "Indomethacin sodium", "code": "51383508" },
      { "name": "Ketorolac trometamol", "code": "51383509" },
      { "name": "Ketorolac tromethamine", "code": "51383510" },
      { "name": "Pravadoline maleate", "code": "51383511" },
      { "name": "Tenidap sodium", "code": "51383512" },
      {
        "name": "Nonsteroidal anti inflammatory malonates and organogold compounds and piperazines and propanols",
        "code": "51383700"
      },
      { "name": "Bumadizone", "code": "51383701" },
      { "name": "Aurothioglucose", "code": "51383702" },
      { "name": "Lobuprofen", "code": "51383703" },
      { "name": "Naproxol", "code": "51383704" },
      { "name": "Bumadizone calcium", "code": "51383705" },
      { "name": "Lobuprofen hydrochloride", "code": "51383706" },
      {
        "name": "Nonsteroidal anti inflammatory naphthalenes",
        "code": "51383800"
      },
      { "name": "Adapalene", "code": "51383801" },
      { "name": "Naproxcinod", "code": "51383802" },
      {
        "name": "Nonsteroidal anti inflammatory nicotinic acids",
        "code": "51383900"
      },
      { "name": "Clonixeril", "code": "51383901" },
      { "name": "Clonixin", "code": "51383902" },
      { "name": "Flunixin", "code": "51383903" },
      { "name": "Nifenazone", "code": "51383904" },
      { "name": "Lysine clonixinate", "code": "51383905" },
      { "name": "Flunixin meglumine", "code": "51383906" },
      { "name": "Nonsteroidal anti inflammatory oxazines", "code": "51384000" },
      { "name": "Carsalam", "code": "51384001" },
      { "name": "Chlorthenoxazine", "code": "51384002" },
      { "name": "Morazone", "code": "51384003" },
      { "name": "Morazone hydrochloride", "code": "51384004" },
      {
        "name": "Nonsteroidal anti inflammatory phenylbutyrates",
        "code": "51384100"
      },
      { "name": "Fenbufen", "code": "51384101" },
      { "name": "Metbufen", "code": "51384102" },
      { "name": "Butibufen", "code": "51384103" },
      {
        "name": "Nonsteroidal anti inflammatory phenylpropionates",
        "code": "51384200"
      },
      { "name": "Fenoprofen", "code": "51384201" },
      { "name": "Loxoprofen", "code": "51384202" },
      { "name": "Miroprofen", "code": "51384203" },
      { "name": "Piketoprofen", "code": "51384204" },
      { "name": "Fenoprofen calcium", "code": "51384205" },
      { "name": "Fenoprofen calcium anhydrous", "code": "51384206" },
      { "name": "Fenoprofen sodium", "code": "51384207" },
      { "name": "Loxoprofen sodium", "code": "51384208" },
      { "name": "Loxoprofen sodium dihydrate", "code": "51384209" },
      { "name": "Piketoprofen hydrochloride", "code": "51384210" },
      {
        "name": "Nonsteroidal anti inflammatory piperidines",
        "code": "51384300"
      },
      { "name": "Benzpiperylon", "code": "51384301" },
      { "name": "Broperamole", "code": "51384302" },
      { "name": "Flazalone", "code": "51384303" },
      {
        "name": "Nonsteroidal anti inflammatory propionates",
        "code": "51384500"
      },
      { "name": "Alminoprofen", "code": "51384501" },
      { "name": "Bakeprofen", "code": "51384502" },
      { "name": "Benoxaprofen", "code": "51384503" },
      { "name": "Carpofen", "code": "51384504" },
      { "name": "Cinaproxen", "code": "51384505" },
      { "name": "Dexketoprofen", "code": "51384506" },
      { "name": "Fluprofen", "code": "51384507" },
      { "name": "Flurbiprofen", "code": "51384508" },
      { "name": "Ibuprofen", "code": "51384509" },
      { "name": "Indoprofen", "code": "51384510" },
      { "name": "Ketoprofen", "code": "51384511" },
      { "name": "Losmiprofen", "code": "51384512" },
      { "name": "Mexoprofen", "code": "51384513" },
      { "name": "Naproxen", "code": "51384514" },
      { "name": "Oxaprozin", "code": "51384515" },
      { "name": "Pranoprofen", "code": "51384516" },
      { "name": "Suprofen", "code": "51384517" },
      { "name": "Tiaprofenic acid", "code": "51384518" },
      { "name": "Vedaprofen", "code": "51384519" },
      { "name": "Zaltoprofen", "code": "51384520" },
      { "name": "Dexibuprofen lysine", "code": "51384521" },
      { "name": "Dexketoprofen trometamol", "code": "51384522" },
      { "name": "Flurbiprofen axetil", "code": "51384523" },
      { "name": "Flurbiprofen sodium", "code": "51384524" },
      { "name": "Ibuprofen aluminum", "code": "51384525" },
      { "name": "Ibuprofen lysinate", "code": "51384526" },
      { "name": "Ibuprofen piconol", "code": "51384527" },
      { "name": "Ibuprofen sodium", "code": "51384528" },
      { "name": "Ketoprofen lysine", "code": "51384529" },
      { "name": "Ketoprofen sodium", "code": "51384530" },
      { "name": "Naproxen etemesil", "code": "51384531" },
      { "name": "Naproxen piperazine", "code": "51384532" },
      { "name": "Naproxen sodium", "code": "51384533" },
      {
        "name": "Nonsteroidal anti inflammatory pyrazoles",
        "code": "51384600"
      },
      { "name": "Dipyrone or metamizol or metamizole", "code": "51384601" },
      { "name": "Aminophenazone or aminopyrine", "code": "51384602" },
      {
        "name": "Antipyrine or phenazone or phenazon or analgesine",
        "code": "51384603"
      },
      { "name": "Bendazac or bendazolic acid", "code": "51384604" },
      { "name": "Benzydamine", "code": "51384605" },
      { "name": "Bisfenazone", "code": "51384606" },
      { "name": "Celecoxib", "code": "51384607" },
      { "name": "Difenamizole", "code": "51384608" },
      { "name": "Oxyphenbutazone", "code": "51384609" },
      { "name": "Edaravone or norphenazone", "code": "51384610" },
      { "name": "Feprazone", "code": "51384611" },
      { "name": "Kebuzone or ketophenylbutazone", "code": "51384612" },
      { "name": "Lonazolac", "code": "51384613" },
      { "name": "Mofebutazone", "code": "51384614" },
      { "name": "Niprofazone", "code": "51384615" },
      { "name": "Pirazolac", "code": "51384616" },
      { "name": "Propyphenazone", "code": "51384617" },
      { "name": "Suxibuzone", "code": "51384618" },
      { "name": "Trifezolac", "code": "51384619" },
      { "name": "Phenylbutazone", "code": "51384620" },
      { "name": "Aminophenazone cyclamate", "code": "51384621" },
      { "name": "Praxadine", "code": "51384622" },
      { "name": "Bendazac lysine dihydrate", "code": "51384623" },
      { "name": "Bendazac sodium", "code": "51384624" },
      { "name": "Benzydamine hydrochloride", "code": "51384625" },
      { "name": "Lonazolac calcium", "code": "51384626" },
      { "name": "Metamizole sodium", "code": "51384627" },
      { "name": "Mofebutazone sodium", "code": "51384628" },
      { "name": "Oxyphenbutazone anhydrous", "code": "51384629" },
      { "name": "Oxyphenbutazone piperazine", "code": "51384630" },
      { "name": "Phenazone salicylate", "code": "51384631" },
      { "name": "Phenylbutazone calcium", "code": "51384632" },
      { "name": "Phenylbutazone sodium", "code": "51384633" },
      {
        "name": "Nonsteroidal anti inflammatory pyridazines",
        "code": "51384700"
      },
      { "name": "Cinnopentazone or cintazone", "code": "51384701" },
      { "name": "Emorfazone", "code": "51384702" },
      { "name": "Isamfazone", "code": "51384703" },
      {
        "name": "Nonsteroidal anti inflammatory pyridines",
        "code": "51384800"
      },
      { "name": "Droxicam", "code": "51384801" },
      { "name": "Etoricoxib", "code": "51384802" },
      {
        "name": "Nonsteroidal anti inflammatory pyrimidinones and pyruvates and quinazolinones and sulfones",
        "code": "51384900"
      },
      { "name": "Firocoxib", "code": "51384901" },
      { "name": "Rimazolium", "code": "51384902" },
      { "name": "Ruvazone", "code": "51384903" },
      { "name": "Quillifoline", "code": "51384904" },
      { "name": "Nonsteroidal anti inflammatory pyrroles", "code": "51385000" },
      { "name": "Anirolac", "code": "51385001" },
      { "name": "Isoprazone", "code": "51385002" },
      { "name": "Zomepirac", "code": "51385003" },
      { "name": "Zomepirac sodium", "code": "51385004" },
      { "name": "Zomepirac sodium anhydrous", "code": "51385005" },
      {
        "name": "Nonsteroidal anti inflammatory pyrrolidines",
        "code": "51385100"
      },
      { "name": "Amixetrine", "code": "51385101" },
      { "name": "Felipyrine", "code": "51385102" },
      { "name": "Amixetrine hydrochloride", "code": "51385103" },
      {
        "name": "Nonsteroidal anti inflammatory quinazolines",
        "code": "51385200"
      },
      { "name": "Fluquazone", "code": "51385201" },
      { "name": "Proquazone", "code": "51385202" },
      {
        "name": "Nonsteroidal anti inflammatory quinazolinones",
        "code": "51385300"
      },
      { "name": "Ciproquazone", "code": "51385301" },
      {
        "name": "Nonsteroidal anti inflammatory quinolines",
        "code": "51385400"
      },
      { "name": "Cinchophen", "code": "51385401" },
      { "name": "Diclonixin", "code": "51385402" },
      { "name": "Cintazone or cinnopentazone", "code": "51385403" },
      { "name": "Cinchophen lithium", "code": "51385404" },
      { "name": "Cinchophen piperazine", "code": "51385405" },
      {
        "name": "Nonsteroidal anti inflammatory salicylamides",
        "code": "51385500"
      },
      { "name": "Ethenzamide", "code": "51385501" },
      { "name": "Salacetamide", "code": "51385502" },
      {
        "name": "Nonsteroidal anti inflammatory salicylates",
        "code": "51385600"
      },
      { "name": "Imidazole salicylate", "code": "51385601" },
      { "name": "Aspirin", "code": "51385602" },
      { "name": "Choline salicylate", "code": "51385603" },
      { "name": "Detanosal", "code": "51385604" },
      { "name": "Fendosal", "code": "51385605" },
      { "name": "Guacetisal", "code": "51385606" },
      { "name": "Magnesium salicylate", "code": "51385607" },
      { "name": "Methyl salicylate", "code": "51385608" },
      { "name": "Potassium salicylate", "code": "51385609" },
      { "name": "Salicylsalicylic acid or salsalate", "code": "51385610" },
      { "name": "Sulfosalicylic acid", "code": "51385611" },
      { "name": "Diethylamine salicylate", "code": "51385612" },
      { "name": "Aspirin aluminium", "code": "51385613" },
      { "name": "Aspirin calcium", "code": "51385614" },
      { "name": "Aspirin glycine calcium", "code": "51385615" },
      { "name": "Aspirin magnesium", "code": "51385616" },
      { "name": "Aspirin methyl ester", "code": "51385617" },
      {
        "name": "Nonsteroidal anti inflammatory sulfonamides",
        "code": "51385700"
      },
      { "name": "Deracoxib", "code": "51385701" },
      { "name": "Nimesulide", "code": "51385702" },
      { "name": "Tilmacoxib", "code": "51385703" },
      {
        "name": "Nonsteroidal anti inflammatory thiazines",
        "code": "51385800"
      },
      { "name": "Ampiroxicam", "code": "51385801" },
      { "name": "Isoxicam", "code": "51385802" },
      { "name": "Lornoxicam", "code": "51385803" },
      { "name": "Meloxicam", "code": "51385804" },
      { "name": "Piroxicam", "code": "51385805" },
      { "name": "Tenoxicam", "code": "51385806" },
      { "name": "Piroxicam cinnamate", "code": "51385807" },
      { "name": "Piroxicam olamine", "code": "51385808" },
      { "name": "Piroxicam pivalic ester", "code": "51385809" },
      {
        "name": "Nonsteroidal anti inflammatory thiazolidines and thiophenes and triazines",
        "code": "51385900"
      },
      { "name": "Darbufelone", "code": "51385901" },
      { "name": "Tianafac", "code": "51385902" },
      { "name": "Apazone or azapropazone", "code": "51385903" },
      { "name": "Darbufelone mesilate or mesylate", "code": "51385904" },
      {
        "name": "Nonsteroidal anti inflammatorys, synthesized",
        "code": "51386000"
      },
      { "name": "Isoprofen", "code": "51386001" },
      { "name": "Bifeprofen", "code": "51386002" },
      { "name": "Bufezolac", "code": "51386003" },
      { "name": "Butanixin", "code": "51386004" },
      { "name": "Cliprofen", "code": "51386005" },
      { "name": "Drinidene", "code": "51386006" },
      { "name": "Duometacin", "code": "51386007" },
      { "name": "Fenclozic acid", "code": "51386008" },
      { "name": "Furcloprofen", "code": "51386009" },
      { "name": "Lexofenac", "code": "51386010" },
      { "name": "Mabuprofen", "code": "51386011" },
      { "name": "Metamfazone", "code": "51386012" },
      { "name": "Pimetacin", "code": "51386013" },
      { "name": "Sulprosal", "code": "51386014" },
      { "name": "Tazeprofen", "code": "51386015" },
      { "name": "Tenosiprol", "code": "51386016" },
      { "name": "Zoliprofen", "code": "51386017" },
      {
        "name": "Nonsteroidal anti inflammatory sulindacs",
        "code": "51386100"
      },
      { "name": "Exisulind", "code": "51386101" },
      { "name": "Paranyline or renytoline", "code": "51386102" },
      {
        "name": "Nonsteroidal anti inflammatory aminocaproic acids and aminosalicylic acids and aniline compounds and anthraquinones",
        "code": "51386200"
      },
      { "name": "Acexamic acid", "code": "51386201" },
      { "name": "Olsalazine", "code": "51386202" },
      { "name": "Eltenac", "code": "51386203" },
      { "name": "Diacerein", "code": "51386204" },
      { "name": "Olsalazine sodium", "code": "51386205" },
      {
        "name": "Nonsteroidal anti inflammatory benzoxazines",
        "code": "51386300"
      },
      { "name": "Meseclazone", "code": "51386301" },
      { "name": "Pirprofen", "code": "51386302" },
      {
        "name": "Nonsteroidal anti inflammatory phenylhydrazines",
        "code": "51386400"
      },
      { "name": "Balsalazide", "code": "51386401" },
      { "name": "Tepoxalin", "code": "51386402" },
      { "name": "Balsalazide disodium", "code": "51386403" },
      { "name": "Balsalazide sodium", "code": "51386404" },
      { "name": "Combination amino acids/proteins", "code": "51386500" },
      { "name": "Glycerin/lysine", "code": "51386501" },
      { "name": "Isoleucine/leucine/valine", "code": "51386502" },
      { "name": "Combination anti-inflammatory agents", "code": "51386600" },
      { "name": "Alcohol/hydrocortisone", "code": "51386601" },
      { "name": "Aloe/hydrocortisone", "code": "51386602" },
      { "name": "Aloe/hydrocortisone/pramoxine", "code": "51386603" },
      { "name": "Dibucaine/hydrocortisone", "code": "51386604" },
      { "name": "Diperodon/hydrocortisone/zinc oxide", "code": "51386605" },
      { "name": "Fluocinolone/pyrithione", "code": "51386606" },
      {
        "name": "Hydrocortisone/isopropyl alcohol/resorcinol/sulfur",
        "code": "51386607"
      },
      {
        "name": "Hydrocortisone/isopropyl alcohol/salicylic acid/sodium thiosulfate",
        "code": "51386608"
      },
      { "name": "Hydrocortisone/lidocaine", "code": "51386609" },
      { "name": "Hydrocortisone/pramoxine", "code": "51386610" },
      { "name": "Hydrocortisone/salicylic acid/sulfur", "code": "51386611" },
      { "name": "Hydrocortisone/sulfur/zinc oxide", "code": "51386612" },
      { "name": "Hydrocortisone/urea", "code": "51386613" },
      { "name": "Sympathomimetic or adrenergic drugs", "code": "51390000" },
      { "name": "Sympathomimetic or adrenergic alcohols", "code": "51391500" },
      { "name": "Imoxiterol", "code": "51391501" },
      { "name": "Isoxaprolol", "code": "51391502" },
      { "name": "Naminterol", "code": "51391503" },
      { "name": "Sympathomimetic or adrenergic amines", "code": "51391600" },
      { "name": "Octodrine", "code": "51391601" },
      { "name": "Aminophylline", "code": "51391602" },
      { "name": "Amrinone or inamrinone", "code": "51391603" },
      { "name": "Isometheptene", "code": "51391604" },
      { "name": "Milrinone", "code": "51391605" },
      { "name": "Prenylamine", "code": "51391606" },
      { "name": "Propylhexedrine", "code": "51391607" },
      { "name": "Ractopamine", "code": "51391608" },
      { "name": "Hydroxyamphetamine", "code": "51391609" },
      { "name": "Aminophylline dihydrate", "code": "51391610" },
      { "name": "Aminophylline hydrate", "code": "51391611" },
      { "name": "Amrinone lactate", "code": "51391612" },
      { "name": "Isometheptene bitartrate", "code": "51391613" },
      { "name": "Isometheptene hydrochloride", "code": "51391614" },
      { "name": "Isometheptene mucate", "code": "51391615" },
      { "name": "Milrinone lactate", "code": "51391616" },
      { "name": "Prenylamine lactate", "code": "51391617" },
      { "name": "Propylhexedrine hydrochloride", "code": "51391618" },
      { "name": "Ractopamine hydrochloride", "code": "51391619" },
      {
        "name": "Sympathomimetic or adrenergic amino alcohols",
        "code": "51391700"
      },
      { "name": "Butopamine", "code": "51391701" },
      { "name": "Adrenalone", "code": "51391702" },
      { "name": "Albuterol or salbutamol", "code": "51391703" },
      { "name": "Alifedrine", "code": "51391704" },
      { "name": "Bambuterol", "code": "51391705" },
      { "name": "Bitolterol", "code": "51391706" },
      { "name": "Etilefrine", "code": "51391707" },
      { "name": "Denopamine", "code": "51391708" },
      { "name": "Carbuterol", "code": "51391709" },
      { "name": "Cimaterol", "code": "51391710" },
      { "name": "Cinnamedrine", "code": "51391711" },
      { "name": "Clenbuterol", "code": "51391712" },
      { "name": "Clorprenaline", "code": "51391713" },
      { "name": "Dimetofrine", "code": "51391714" },
      { "name": "Dipivefrin or dipivefrine", "code": "51391715" },
      { "name": "Divabuterol", "code": "51391716" },
      { "name": "Ephedrine", "code": "51391717" },
      { "name": "Isoetarine or isoetharine", "code": "51391718" },
      { "name": "Etafedrine", "code": "51391719" },
      { "name": "Butanefrine or ethylnorepinephrine", "code": "51391720" },
      { "name": "Fenoterol", "code": "51391721" },
      {
        "name": "Levarterenol or noradrenaline or norepinephrine",
        "code": "51391722"
      },
      { "name": "Levalbuterol or levosalbutamol", "code": "51391723" },
      { "name": "Levisoprenaline", "code": "51391724" },
      { "name": "Mabuterol", "code": "51391725" },
      { "name": "Orciprenaline or metaproterenol", "code": "51391726" },
      { "name": "Methoxamine", "code": "51391727" },
      { "name": "Metiprenaline", "code": "51391728" },
      { "name": "Midodrine", "code": "51391729" },
      { "name": "Pirbuterol", "code": "51391730" },
      { "name": "Norfenefrine", "code": "51391731" },
      { "name": "Octopamine", "code": "51391732" },
      { "name": "Oxilofrine", "code": "51391733" },
      { "name": "Phenylephrine", "code": "51391734" },
      { "name": "Theodrenaline", "code": "51391735" },
      { "name": "Racephedrine", "code": "51391736" },
      { "name": "Ritodrine", "code": "51391737" },
      { "name": "Salmefamol", "code": "51391738" },
      { "name": "Salmeterol", "code": "51391739" },
      { "name": "Tazolol", "code": "51391740" },
      { "name": "Tobuterol", "code": "51391741" },
      { "name": "Isoprenaline", "code": "51391742" },
      { "name": "Adrenalin or adrenaline or epinephrine", "code": "51391743" },
      { "name": "Colterol", "code": "51391744" },
      { "name": "Isoetharine mesylate", "code": "51391745" },
      { "name": "Racepinefrine or racepinephrine", "code": "51391746" },
      { "name": "Arformoterol", "code": "51391747" },
      { "name": "Eformoterol or formoterol", "code": "51391749" },
      {
        "name": "Sympathomimetic or adrenergic amphetamines",
        "code": "51391800"
      },
      { "name": "Levmetamfetamine or levomethamphetamine", "code": "51391801" },
      { "name": "Gepefrine", "code": "51391802" },
      { "name": "Mephentermine", "code": "51391803" },
      { "name": "Methedrone or methoxyphedrine", "code": "51391804" },
      { "name": "Methoxyphenamine", "code": "51391805" },
      { "name": "Pholedrine", "code": "51391806" },
      { "name": "Hydroxyamphetamine hydrobromide", "code": "51391807" },
      { "name": "Gepefrine tartrate", "code": "51391808" },
      { "name": "Mephentermine sulfate", "code": "51391809" },
      { "name": "Mephentermine sulphate", "code": "51391810" },
      { "name": "Methoxyphenamine hydrochloride", "code": "51391811" },
      { "name": "Pholedrine sulfate", "code": "51391812" },
      { "name": "Pholedrine sulphate", "code": "51391813" },
      { "name": "Sympathomimetic or adrenergic azepines", "code": "51391900" },
      { "name": "Dilazep", "code": "51391901" },
      { "name": "Azepexole", "code": "51391902" },
      { "name": "Azepexole dihydrochloride", "code": "51391903" },
      { "name": "Dilazep dihydrochloride", "code": "51391904" },
      {
        "name": "Sympathomimetic or adrenergic benzazepines and benzimidazoles and benzonitriles and benzyl alcohols",
        "code": "51392000"
      },
      { "name": "Fenoldopam", "code": "51392001" },
      { "name": "Adibendan", "code": "51392002" },
      { "name": "Cinamolol", "code": "51392003" },
      { "name": "Sulfonterol", "code": "51392004" },
      { "name": "Fenoldopam mesilate or mesylate", "code": "51392005" },
      {
        "name": "Sympathomimetic or adrenergic imidazoles",
        "code": "51392200"
      },
      { "name": "Alinidine", "code": "51392201" },
      { "name": "Apraclonidine or iopidine", "code": "51392202" },
      { "name": "Clonidine", "code": "51392203" },
      { "name": "Coumazoline", "code": "51392204" },
      { "name": "Detomidine", "code": "51392205" },
      { "name": "Metizoline", "code": "51392206" },
      { "name": "Indanazoline", "code": "51392207" },
      { "name": "Indanidine", "code": "51392208" },
      {
        "name": "Dexlofexidine or levlofexidine or lofexidine",
        "code": "51392209"
      },
      { "name": "Medetomidine", "code": "51392210" },
      { "name": "Mivazerol or piroximone", "code": "51392211" },
      { "name": "Piroximone", "code": "51392212" },
      { "name": "Tefazoline", "code": "51392213" },
      { "name": "Tizanidine", "code": "51392214" },
      { "name": "Tolonidine", "code": "51392215" },
      { "name": "Tramazoline", "code": "51392216" },
      { "name": "Fenoxazoline", "code": "51392217" },
      { "name": "Apraclonidine hydrochloride", "code": "51392218" },
      { "name": "Clonidine hydrochloride", "code": "51392219" },
      { "name": "Detomidine hydrochloride", "code": "51392220" },
      {
        "name": "Detomidine hydrochloride for veterinary use",
        "code": "51392221"
      },
      { "name": "Fenoxazoline hydrochloride", "code": "51392222" },
      { "name": "Indanazoline hydrochloride", "code": "51392223" },
      { "name": "Lofexidine hydrochloride", "code": "51392224" },
      { "name": "Medetomidine hydrochloride", "code": "51392225" },
      { "name": "Metizoline hydrochloride", "code": "51392226" },
      { "name": "Tizanidine hydrochloride", "code": "51392227" },
      { "name": "Tolonidine nitrate", "code": "51392228" },
      { "name": "Tramazoline hydrochloride", "code": "51392229" },
      { "name": "Tramazoline hydrochloride monohydrate", "code": "51392230" },
      {
        "name": "Sympathomimetic or adrenergic isoquinolines and isoxazoles and naphthyridines and phenoxypropanolamines",
        "code": "51392300"
      },
      { "name": "Debrisoquine", "code": "51392301" },
      { "name": "Broxaterol", "code": "51392302" },
      { "name": "Medorinone", "code": "51392303" },
      { "name": "Xamoterol", "code": "51392304" },
      { "name": "Debrisoquine sulfate", "code": "51392305" },
      { "name": "Xamoterol fumarate", "code": "51392306" },
      { "name": "Xamoterol hemifumarate", "code": "51392307" },
      {
        "name": "Sympathomimetic or adrenergic catecholamines",
        "code": "51392400"
      },
      { "name": "Arbutamine", "code": "51392401" },
      { "name": "Dobutamine", "code": "51392402" },
      { "name": "Dopamine", "code": "51392403" },
      { "name": "Dopexamine", "code": "51392404" },
      { "name": "Ibopamine", "code": "51392405" },
      { "name": "Levonordefrin", "code": "51392406" },
      { "name": "Arbutamine hydrochloride", "code": "51392407" },
      { "name": "Dobutamine hydrochloride", "code": "51392408" },
      { "name": "Dobutamine lactobionate", "code": "51392409" },
      { "name": "Dobutamine tartrate", "code": "51392410" },
      { "name": "Dopamine dimethyl ether", "code": "51392411" },
      { "name": "Dopamine hydrochloride", "code": "51392412" },
      { "name": "Dopamine lutine", "code": "51392413" },
      { "name": "Dopexamine dihydrochloride", "code": "51392414" },
      { "name": "Dopexamine hydrochloride", "code": "51392415" },
      { "name": "Ibopamine hydrochloride", "code": "51392416" },
      {
        "name": "Sympathomimetic or adrenergic benzylisoquinolines and carbamates and catechols and cyclopentanes",
        "code": "51392500"
      },
      { "name": "Rimiterol", "code": "51392501" },
      { "name": "Tretoquinol", "code": "51392502" },
      { "name": "Cyclopentamine", "code": "51392503" },
      { "name": "Carbazeran", "code": "51392504" },
      { "name": "Cyclopentamine hydrochloride", "code": "51392505" },
      { "name": "Rimiterol hbr", "code": "51392506" },
      { "name": "Rimiterol hydrobromide", "code": "51392507" },
      { "name": "Tretoquinol hydrochloride", "code": "51392508" },
      {
        "name": "Sympathomimetic or adrenergic phenylpropanolamines",
        "code": "51392600"
      },
      { "name": "Cafedrine", "code": "51392601" },
      { "name": "Metaraminol", "code": "51392602" },
      { "name": "Phenylpropanolamine", "code": "51392603" },
      { "name": "Cafedrine hydrochloride", "code": "51392604" },
      { "name": "Metaraminol bitartrate", "code": "51392605" },
      { "name": "Metaraminol hydrochloride", "code": "51392606" },
      { "name": "Metaraminol tartrate", "code": "51392607" },
      { "name": "Phenylpropanolamine bitartrate", "code": "51392608" },
      { "name": "Phenylpropanolamine hydrochloride", "code": "51392609" },
      { "name": "Phenylpropanolamine maleate", "code": "51392610" },
      { "name": "Phenylpropanolamine sulfate", "code": "51392611" },
      { "name": "Phenylpropanolamine sulphate", "code": "51392612" },
      {
        "name": "Sympathomimetic or adrenergic piperazines",
        "code": "51392700"
      },
      { "name": "Saterinone", "code": "51392701" },
      { "name": "Acefylline piperazine", "code": "51392702" },
      {
        "name": "Sympathomimetic or adrenergic pyridazines",
        "code": "51392800"
      },
      { "name": "Bemoradan", "code": "51392801" },
      { "name": "Amezinium metilsulfate", "code": "51392802" },
      { "name": "Prinoxodan", "code": "51392803" },
      { "name": "Zindotrine", "code": "51392804" },
      { "name": "Imazodan", "code": "51392805" },
      { "name": "Imazodan hydrochloride", "code": "51392806" },
      { "name": "Sympathomimetic or adrenergic pyridines", "code": "51392900" },
      { "name": "Picumeterol", "code": "51392901" },
      { "name": "Azanator", "code": "51392902" },
      { "name": "Azanator maleate", "code": "51392903" },
      { "name": "Picumeterol fumarate", "code": "51392904" },
      {
        "name": "Sympathomimetic or adrenergic quinazolines",
        "code": "51393000"
      },
      { "name": "Quazinone", "code": "51393001" },
      { "name": "Quazodine", "code": "51393002" },
      { "name": "Piquizil", "code": "51393003" },
      { "name": "Piquizil hydrochloride", "code": "51393004" },
      {
        "name": "Sympathomimetic or adrenergic quinolines",
        "code": "51393100"
      },
      { "name": "Aganodine", "code": "51393101" },
      { "name": "Guanabenz", "code": "51393102" },
      { "name": "Guanoxabenz", "code": "51393103" },
      { "name": "Vesnarinone", "code": "51393104" },
      { "name": "Guanabenz acetate", "code": "51393105" },
      { "name": "Guanabenz monoacetate", "code": "51393106" },
      { "name": "Guanoxabenz hydrochloride", "code": "51393107" },
      {
        "name": "Sympathomimetic or adrenergic tartrates and thiophenes and trimethylsilyl compounds and",
        "code": "51393200"
      },
      { "name": "Tiamenidine", "code": "51393201" },
      { "name": "Zilpaterol", "code": "51393202" },
      { "name": "Meluadrine", "code": "51393203" },
      { "name": "Meluadrine tartrate", "code": "51393204" },
      { "name": "Tiamenidine hydrochloride", "code": "51393205" },
      {
        "name": "Sympathomimetic or adrenergic drugs, synthesized",
        "code": "51393300"
      },
      { "name": "Nardeterol", "code": "51393301" },
      { "name": "Benafentrine", "code": "51393302" },
      { "name": "Clonazoline", "code": "51393303" },
      { "name": "Dalbraminol", "code": "51393304" },
      { "name": "Dexlofexidine", "code": "51393305" },
      { "name": "Difeterol", "code": "51393306" },
      { "name": "Etanterol", "code": "51393307" },
      { "name": "Laprafylline", "code": "51393308" },
      { "name": "Lodiperone", "code": "51393309" },
      { "name": "Mexafylline", "code": "51393310" },
      { "name": "Neraminol", "code": "51393311" },
      { "name": "Norbudrine", "code": "51393312" },
      { "name": "Pivenfrine", "code": "51393313" },
      { "name": "Spirofylline", "code": "51393314" },
      { "name": "Sulukast", "code": "51393315" },
      { "name": "Triclofylline", "code": "51393316" },
      { "name": "Visnafylline", "code": "51393317" },
      { "name": "Suloxifen", "code": "51393318" },
      { "name": "Difeterol hydrochloride", "code": "51393319" },
      { "name": "Suloxifen oxalate", "code": "51393320" },
      { "name": "Sympathomimetic or adrenergic xanthines", "code": "51393400" },
      { "name": "Ambuphylline or bufylline", "code": "51393401" },
      { "name": "Bamifylline", "code": "51393402" },
      { "name": "Etofylline", "code": "51393403" },
      { "name": "Choline theophyllinate or oxtriphylline", "code": "51393404" },
      { "name": "Theophylline", "code": "51393405" },
      { "name": "Verofylline", "code": "51393406" },
      { "name": "Tazifylline", "code": "51393407" },
      { "name": "Acefylline", "code": "51393408" },
      { "name": "Xanoxic acid", "code": "51393409" },
      { "name": "Acefylline clofibrol", "code": "51393410" },
      { "name": "Bamifylline hydrochloride", "code": "51393411" },
      { "name": "Tazifylline hydrochloride", "code": "51393412" },
      {
        "name": "Sympathomimetic or adrenergic dibenzoxepins and dioxolanes and glycosides and guanidines",
        "code": "51393600"
      },
      { "name": "Doxaminol", "code": "51393601" },
      { "name": "Protokylol", "code": "51393602" },
      { "name": "Bucladesine", "code": "51393603" },
      { "name": "Indacaterol", "code": "51393604" },
      { "name": "Bucladesine sodium", "code": "51393605" },
      { "name": "Indacaterol maleate", "code": "51393606" },
      { "name": "Protokylol hydrochloride", "code": "51393607" },
      {
        "name": "Sympathomimetic or adrenergic pyrimidines",
        "code": "51393700"
      },
      { "name": "Oxitropium", "code": "51393701" },
      { "name": "Pelrinone", "code": "51393702" },
      { "name": "Oxitropium bromide", "code": "51393703" },
      { "name": "Pelrinone hydrochloride", "code": "51393704" },
      {
        "name": "Combination sympathomimetics (adrenergics)",
        "code": "51393800"
      },
      { "name": "Dextrose/dopamine", "code": "51393801" },
      { "name": "Isoproterenol/sodium chloride", "code": "51393802" },
      {
        "name": "Tranquilizers and antimanic and antianxiety drugs",
        "code": "51400000"
      },
      {
        "name": "Tranquilizers and antimanic and antianxiety benzodiazepines",
        "code": "51401500"
      },
      { "name": "Bromazepam", "code": "51401501" },
      { "name": "Climazolam", "code": "51401502" },
      { "name": "Chlordiazepoxide", "code": "51401503" },
      { "name": "Cinolazepam", "code": "51401504" },
      { "name": "Alprazolam", "code": "51401505" },
      { "name": "Doxefazepam", "code": "51401506" },
      { "name": "Bretazenil", "code": "51401507" },
      { "name": "Lormetazepam", "code": "51401508" },
      { "name": "Meclonazepam", "code": "51401509" },
      { "name": "Medazepam", "code": "51401510" },
      { "name": "Ethyl carfluzepate", "code": "51401511" },
      { "name": "Menitrazepam", "code": "51401512" },
      { "name": "Nitrazepam", "code": "51401513" },
      { "name": "Prazepam", "code": "51401514" },
      { "name": "Clobazam", "code": "51401515" },
      { "name": "Clonazepam", "code": "51401516" },
      { "name": "Cloxazolam", "code": "51401517" },
      { "name": "Diazepam", "code": "51401518" },
      { "name": "Ethyl loflazepate", "code": "51401519" },
      { "name": "Elfazepam", "code": "51401520" },
      { "name": "Halazepam", "code": "51401521" },
      { "name": "Ethyl dirazepate", "code": "51401522" },
      { "name": "Etizolam", "code": "51401523" },
      { "name": "Fludiazepam", "code": "51401524" },
      { "name": "Flumazenil", "code": "51401525" },
      { "name": "Flunitrazepam", "code": "51401526" },
      { "name": "Ketazolam", "code": "51401527" },
      { "name": "Flutazolam", "code": "51401528" },
      { "name": "Flutoprazepam", "code": "51401529" },
      { "name": "Girisopam", "code": "51401530" },
      { "name": "Nordazepam", "code": "51401531" },
      { "name": "Iclazepam", "code": "51401532" },
      { "name": "Lorazepam", "code": "51401533" },
      { "name": "Nortetrazepam", "code": "51401535" },
      { "name": "Metaclazepam", "code": "51401536" },
      { "name": "Mexazolam", "code": "51401537" },
      { "name": "Midazolam", "code": "51401538" },
      { "name": "Motrazepam", "code": "51401539" },
      { "name": "Nimetazepam", "code": "51401540" },
      { "name": "Sarmazenil", "code": "51401541" },
      { "name": "Oxazepam", "code": "51401542" },
      { "name": "Pinazepam", "code": "51401543" },
      { "name": "Pivoxazepam", "code": "51401544" },
      { "name": "Proflazepam", "code": "51401545" },
      { "name": "Quazepam", "code": "51401546" },
      { "name": "Razobazam", "code": "51401547" },
      { "name": "Reclazepam", "code": "51401548" },
      { "name": "Haloxazolam", "code": "51401549" },
      { "name": "Triazolam", "code": "51401550" },
      { "name": "Sulazepam", "code": "51401551" },
      { "name": "Talampanel", "code": "51401552" },
      { "name": "Temazepam", "code": "51401553" },
      { "name": "Tetrazepam", "code": "51401554" },
      { "name": "Tofisopam", "code": "51401555" },
      { "name": "Tolufazepam", "code": "51401556" },
      { "name": "Adinazolam", "code": "51401557" },
      { "name": "Loprazolam", "code": "51401558" },
      { "name": "Camazepam", "code": "51401559" },
      { "name": "Adinazolam mesilate or mesylate", "code": "51401560" },
      { "name": "Chlordiazepoxide hydrochloride", "code": "51401561" },
      { "name": "Chlordiazepoxide lactam", "code": "51401562" },
      { "name": "Diazepam hydrochloride", "code": "51401563" },
      { "name": "Loprazolam methanesulfonate", "code": "51401564" },
      { "name": "Loprazolammesilate or mesylate", "code": "51401565" },
      { "name": "Lorazepam glucuronide", "code": "51401566" },
      { "name": "Medazepam hydrochloride", "code": "51401567" },
      { "name": "Metaclazepam hydrochloride", "code": "51401568" },
      { "name": "Midazolam hydrochloride", "code": "51401569" },
      { "name": "Midazolam maleate", "code": "51401570" },
      {
        "name": "Tranquilizers and antimanic and antianxiety amides",
        "code": "51401600"
      },
      { "name": "Bentazepam", "code": "51401601" },
      { "name": "Brotizolam", "code": "51401602" },
      { "name": "Tiapride", "code": "51401603" },
      { "name": "Tiapride hydrochloride", "code": "51401604" },
      {
        "name": "Tranquilizers and antimanic and antianxiety amines and anthracenes and barbiturates and benzazepines and dipeptides",
        "code": "51401700"
      },
      { "name": "Captodiame or captodiamine", "code": "51401701" },
      { "name": "Benzoctamine", "code": "51401702" },
      { "name": "Avizafone", "code": "51401703" },
      { "name": "Febarbamate", "code": "51401704" },
      { "name": "Olanzapine", "code": "51401705" },
      { "name": "Benzoctamine hydrochloride", "code": "51401706" },
      { "name": "Captodiame hydrochloride", "code": "51401707" },
      { "name": "Olanzapine embonate", "code": "51401708" },
      { "name": "Olanzapine hydrochloride", "code": "51401709" },
      { "name": "Olanzapine pamoate", "code": "51401710" },
      {
        "name": "Tranquilizers and antimanic and antianxiety azepines",
        "code": "51401800"
      },
      { "name": "Ciclotizolam", "code": "51401801" },
      { "name": "Clotiazepam", "code": "51401802" },
      { "name": "Premazepam", "code": "51401803" },
      {
        "name": "Tranquilizers and antimanic and antianxiety azoles",
        "code": "51401900"
      },
      { "name": "Rilmazafone", "code": "51401901" },
      { "name": "Alpidem", "code": "51401902" },
      { "name": "Divaplon", "code": "51401903" },
      { "name": "Fasiplon", "code": "51401904" },
      { "name": "Panadiplon", "code": "51401905" },
      { "name": "Rilmazafone hydrochloride", "code": "51401906" },
      {
        "name": "Tranquilizers and antimanic and antianxiety carbamates",
        "code": "51402000"
      },
      { "name": "Emylcamate", "code": "51402001" },
      { "name": "Mebutamate", "code": "51402002" },
      { "name": "Meprobamate", "code": "51402003" },
      { "name": "Procymate", "code": "51402004" },
      {
        "name": "Tranquilizers and antimanic and antianxiety thiazines",
        "code": "51402100"
      },
      { "name": "Chlormezanone", "code": "51402101" },
      { "name": "Dichlormezanone", "code": "51402102" },
      {
        "name": "Tranquilizers and antimanic and antianxiety naphthyridines and oxazines and quinolines and spiro compounds and sulfur compounds",
        "code": "51402200"
      },
      { "name": "Pagoclone", "code": "51402201" },
      { "name": "Pipequaline", "code": "51402202" },
      { "name": "Buspirone", "code": "51402203" },
      { "name": "Duloxetine", "code": "51402204" },
      { "name": "Buspirone hydrochloride", "code": "51402205" },
      { "name": "Duloxetine hydrochloride", "code": "51402206" },
      {
        "name": "Tranquilizers and antimanic and antianxiety piperazines",
        "code": "51402300"
      },
      { "name": "Mepiprazole", "code": "51402301" },
      { "name": "Enciprazine", "code": "51402302" },
      { "name": "Enpiprazole", "code": "51402303" },
      { "name": "Revospirone", "code": "51402304" },
      { "name": "Metanopirone or tandospirone", "code": "51402305" },
      { "name": "Enciprazine dihydrochloride", "code": "51402306" },
      { "name": "Enciprazine hydrochloride", "code": "51402307" },
      { "name": "Enpiprazole hydrochloride", "code": "51402308" },
      { "name": "Mepiprazole dihydrochloride", "code": "51402309" },
      { "name": "Tandospirone citrate", "code": "51402310" },
      {
        "name": "Tranquilizers and antimanic and antianxiety piperidines",
        "code": "51402400"
      },
      { "name": "Azacyclonol", "code": "51402401" },
      { "name": "Prazitone", "code": "51402402" },
      { "name": "Azacyclonol hydrochloride", "code": "51402403" },
      { "name": "Prazitone hydrochloride", "code": "51402404" },
      {
        "name": "Tranquilizers and antimanic and antianxiety pyridines",
        "code": "51402500"
      },
      { "name": "Abecarnil", "code": "51402501" },
      { "name": "Gedocarnil", "code": "51402502" },
      {
        "name": "Tranquilizers and antimanic and antianxiety pyrimidines",
        "code": "51402600"
      },
      { "name": "Ipsapirone", "code": "51402601" },
      { "name": "Ocinaplon", "code": "51402602" },
      { "name": "Ipsapirone hydrochloride", "code": "51402603" },
      { "name": "Vasodilators", "code": "51410000" },
      { "name": "Vasodilator carboxylic acids", "code": "51411500" },
      { "name": "Burodiline", "code": "51411501" },
      { "name": "Cyclandelate", "code": "51411502" },
      { "name": "Fenetradil", "code": "51411503" },
      { "name": "Hexobendine", "code": "51411504" },
      { "name": "Stevaladil", "code": "51411505" },
      { "name": "Vasodilator alcohols", "code": "51411600" },
      { "name": "Suloctidil", "code": "51411601" },
      { "name": "Bamethan", "code": "51411602" },
      { "name": "Buphenine or nylidrin", "code": "51411603" },
      { "name": "Dilevalol", "code": "51411604" },
      {
        "name": "Glyceryl trinitrate or glyceryltrinitrate or nitroglycerin",
        "code": "51411605"
      },
      { "name": "Oxyfedrine", "code": "51411606" },
      { "name": "Propatylnitrate", "code": "51411607" },
      { "name": "Tenitramine", "code": "51411608" },
      { "name": "Teopranitol", "code": "51411609" },
      { "name": "Tipropidil", "code": "51411610" },
      { "name": "Bamethan sulfate", "code": "51411611" },
      { "name": "Bamethan sulphate", "code": "51411612" },
      { "name": "Buphenine hydrochloride", "code": "51411613" },
      { "name": "Dilevalol hydrochloride", "code": "51411614" },
      { "name": "Oxyfedrine hydrochloride", "code": "51411615" },
      { "name": "Tipropidil hydrochloride", "code": "51411616" },
      { "name": "Vasodilator alkaloids", "code": "51411700" },
      { "name": "Pimefylline", "code": "51411701" },
      { "name": "Brovincamine", "code": "51411702" },
      { "name": "Ciclosidomine", "code": "51411703" },
      { "name": "Ethaverine", "code": "51411704" },
      { "name": "Pentifylline", "code": "51411705" },
      { "name": "Vinburnine", "code": "51411706" },
      { "name": "Papaverine or papaverinum", "code": "51411707" },
      { "name": "Brovincamine fumarate", "code": "51411708" },
      { "name": "Ethaverine hydrochloride", "code": "51411709" },
      { "name": "Papaverine hydrochloride", "code": "51411710" },
      { "name": "Papaverine nitrite", "code": "51411711" },
      { "name": "Papaverine sulfate", "code": "51411712" },
      { "name": "Papaverine sulphate", "code": "51411713" },
      { "name": "Pimefylline nicotinate", "code": "51411714" },
      {
        "name": "Vasodilator amides and azepines and ergot alkaloids and ethers",
        "code": "51411800"
      },
      { "name": "Acoxatrine", "code": "51411801" },
      { "name": "Cetiedil", "code": "51411802" },
      { "name": "Fenoxedil", "code": "51411803" },
      {
        "name": "Co-dergocrine mesilate or dihydroergotoxine mesylate or ergoloid mesylate",
        "code": "51411804"
      },
      { "name": "Cetiedil citrate", "code": "51411805" },
      { "name": "Fenoxedil hydrochloride", "code": "51411806" },
      { "name": "Vasodilator amines", "code": "51411900" },
      { "name": "Aminoxytriphene or amotriphene", "code": "51411901" },
      { "name": "Droprenilamine", "code": "51411902" },
      { "name": "Emopamil", "code": "51411903" },
      { "name": "Fenalcomine", "code": "51411904" },
      { "name": "Fantofarone", "code": "51411905" },
      { "name": "Droprenilamine hydrochloride", "code": "51411906" },
      { "name": "Vasodilator azoles", "code": "51412000" },
      { "name": "Molsidomine", "code": "51412001" },
      { "name": "Butalamine", "code": "51412002" },
      { "name": "Butalamine hydrochloride", "code": "51412003" },
      { "name": "Cilostazol", "code": "51412004" },
      { "name": "Enoximone", "code": "51412005" },
      { "name": "Imolamine", "code": "51412006" },
      { "name": "Ipramidil", "code": "51412007" },
      { "name": "Linsidomine", "code": "51412009" },
      { "name": "Mefenidil", "code": "51412010" },
      { "name": "Nanterinone", "code": "51412011" },
      { "name": "Trapidil", "code": "51412012" },
      { "name": "Imolamine hydrochloride", "code": "51412013" },
      { "name": "Linsidomine hydrochloride", "code": "51412014" },
      { "name": "Mefenidil fumarate", "code": "51412015" },
      { "name": "Nanterinone mesylate", "code": "51412016" },
      { "name": "Vasodilator benzimidazoles", "code": "51412100" },
      { "name": "Bendazol", "code": "51412101" },
      { "name": "Buterizine", "code": "51412102" },
      { "name": "Bendazol hydrochloride", "code": "51412103" },
      { "name": "Vasodilator benzofurans", "code": "51412300" },
      { "name": "Benziodarone", "code": "51412301" },
      { "name": "Clobenfurol or cloridarol", "code": "51412302" },
      { "name": "Vasodilator benzopyrans", "code": "51412400" },
      { "name": "Carbocromen or chromonar", "code": "51412401" },
      { "name": "Efloxate", "code": "51412402" },
      { "name": "Visnadine", "code": "51412403" },
      { "name": "Carbocromen hydrochloride", "code": "51412404" },
      { "name": "Vasodilator phenothiazines", "code": "51412500" },
      { "name": "Azaclorzine", "code": "51412501" },
      { "name": "Chloracizine or chloracyzine", "code": "51412502" },
      { "name": "Azaclorzine hydrochloride", "code": "51412503" },
      {
        "name": "Vasodilator ferricyanides and hydrazines and isoquinolines and morpholines and phenols",
        "code": "51412600"
      },
      { "name": "Nitroprusside", "code": "51412601" },
      { "name": "Todralazine", "code": "51412602" },
      { "name": "Dioxyline", "code": "51412603" },
      { "name": "Ledazerol", "code": "51412604" },
      { "name": "Floredil", "code": "51412605" },
      { "name": "Todralazine hydrochloride", "code": "51412606" },
      { "name": "Vasodilator furans", "code": "51412700" },
      { "name": "Nafronyl or naftidrofuryl", "code": "51412701" },
      { "name": "Alteplase", "code": "51412702" },
      { "name": "Naftidrofuryl hydrogen oxalate", "code": "51412703" },
      { "name": "Naftidrofuryl oxalate", "code": "51412704" },
      { "name": "Vasodilator ketones", "code": "51412800" },
      { "name": "Etafenone", "code": "51412801" },
      { "name": "Perfomedil", "code": "51412802" },
      { "name": "Etafenone hydrochloride", "code": "51412803" },
      { "name": "Vasodilator nicotinic acids", "code": "51412900" },
      { "name": "Micinicate", "code": "51412901" },
      { "name": "Nicofuranose", "code": "51412902" },
      { "name": "Ciclonicate", "code": "51412903" },
      { "name": "Vasodilator nitrates", "code": "51413000" },
      { "name": "Aminoethyl nitrate", "code": "51413001" },
      { "name": "Clonitrate", "code": "51413002" },
      { "name": "Eritrityl tetranitrate", "code": "51413003" },
      { "name": "Itramin tosilate or itramin tosylate", "code": "51413004" },
      { "name": "Mannitol hexanitrate", "code": "51413005" },
      {
        "name": "Vasodilator quinazolines and quinolines and quinolones and sulfonamides and triazines",
        "code": "51413100"
      },
      { "name": "Cilostamide", "code": "51413101" },
      { "name": "Buquineran", "code": "51413102" },
      { "name": "Flosequinan", "code": "51413103" },
      { "name": "Razinodil", "code": "51413104" },
      { "name": "Fasudil", "code": "51413105" },
      { "name": "Fasudil hydrochloride", "code": "51413106" },
      { "name": "Fasudil hydrochloride hydrate", "code": "51413107" },
      { "name": "Vasodilator piperazines", "code": "51413200" },
      { "name": "Cinepazic acid", "code": "51413201" },
      { "name": "Cinepazide", "code": "51413202" },
      { "name": "Trimetazidine", "code": "51413203" },
      { "name": "Sildenafil", "code": "51413204" },
      { "name": "Vardenafil", "code": "51413205" },
      { "name": "Cinepazide maleate", "code": "51413206" },
      { "name": "Sildenafil citrate", "code": "51413207" },
      { "name": "Trimetazidine dihydrochloride", "code": "51413208" },
      { "name": "Trimetazidine hydrochloride", "code": "51413209" },
      { "name": "Vardenafil dihydrochloride", "code": "51413210" },
      { "name": "Vardenafil hydrochloride", "code": "51413211" },
      { "name": "Vardenafil hydrochloride hydrate", "code": "51413212" },
      { "name": "Vasodilator prostaglandins", "code": "51413300" },
      { "name": "Alprostadil", "code": "51413301" },
      { "name": "Gemeprost", "code": "51413302" },
      { "name": "Alprostadil alfadex", "code": "51413303" },
      { "name": "Vasodilator pyridazines", "code": "51413400" },
      { "name": "Endralazine", "code": "51413401" },
      { "name": "Pildralazine", "code": "51413402" },
      { "name": "Dihydroergocristine", "code": "51413403" },
      { "name": "Endralazine mesilate or mesylate", "code": "51413404" },
      { "name": "Vasodilator pyridines", "code": "51413500" },
      { "name": "Gapicomine", "code": "51413501" },
      { "name": "Ibudilast", "code": "51413502" },
      { "name": "Nicotinyl alcohol or pyridylcarbinol", "code": "51413503" },
      { "name": "Nimodipine", "code": "51413504" },
      { "name": "Nitrendipine", "code": "51413505" },
      { "name": "Tadalafil", "code": "51413506" },
      { "name": "Vasodilator pyrimidines", "code": "51413600" },
      { "name": "Cinepazet", "code": "51413601" },
      { "name": "Bumepidil", "code": "51413602" },
      { "name": "Carprazidil", "code": "51413603" },
      { "name": "Cinepazet maleate", "code": "51413604" },
      { "name": "Vasodilator pyrrolidines", "code": "51413700" },
      { "name": "Buflomedil", "code": "51413701" },
      { "name": "Fronepidil", "code": "51413702" },
      { "name": "Buflomedil hydrochloride", "code": "51413703" },
      { "name": "Vasodilator thiazoles", "code": "51413800" },
      { "name": "Podilfen", "code": "51413801" },
      { "name": "Vasodilators, synthesized", "code": "51413900" },
      { "name": "Pitenodil", "code": "51413901" },
      { "name": "Altapizone", "code": "51413902" },
      { "name": "Cinepaxadil", "code": "51413903" },
      { "name": "Dramedilol", "code": "51413904" },
      { "name": "Flosatidil", "code": "51413905" },
      { "name": "Iproxamine", "code": "51413906" },
      { "name": "Manozodil", "code": "51413907" },
      { "name": "Nesapidil", "code": "51413908" },
      { "name": "Parodilol", "code": "51413909" },
      { "name": "Piraxelate", "code": "51413910" },
      { "name": "Vincantril", "code": "51413911" },
      { "name": "Vinpoline", "code": "51413912" },
      { "name": "Iproxamine hydrochloride", "code": "51413913" },
      { "name": "Vasodilator piperidines", "code": "51414000" },
      { "name": "Hexadiline", "code": "51414001" },
      { "name": "Mindodilol", "code": "51414002" },
      { "name": "Vasodilator purines", "code": "51414100" },
      { "name": "Regadenoson", "code": "51414101" },
      { "name": "Etofylline nicotinate", "code": "51414102" },
      { "name": "Corticosteroids", "code": "51420000" },
      { "name": "Corticosteroid androstadienes", "code": "51421500" },
      { "name": "Fluticasone", "code": "51421501" },
      { "name": "Timobesone", "code": "51421502" },
      { "name": "Loteprednol", "code": "51421503" },
      { "name": "Fluticasone furoate", "code": "51421504" },
      { "name": "Fluticasone propionate", "code": "51421505" },
      { "name": "Loteprednol etabonate", "code": "51421506" },
      { "name": "Timobesone acetate", "code": "51421507" },
      { "name": "Corticosteroid betamethasones", "code": "51421600" },
      { "name": "Clobetasol", "code": "51421601" },
      { "name": "Clobetasone", "code": "51421602" },
      { "name": "Diflorasone", "code": "51421603" },
      { "name": "Halobetasol or ulobetasol", "code": "51421604" },
      { "name": "Clobetasol propionate", "code": "51421605" },
      { "name": "Clobetasone butyrate", "code": "51421606" },
      { "name": "Diflorasone diacetate", "code": "51421607" },
      { "name": "Ulobetasol propionate", "code": "51421608" },
      {
        "name": "Corticosteroid corticotropin-releasing hormones",
        "code": "51421700"
      },
      { "name": "Corticorelin ovine triflutate", "code": "51421701" },
      { "name": "Corticorelin", "code": "51421702" },
      { "name": "Corticosteroid glucocorticoids", "code": "51421800" },
      { "name": "Rofleponide", "code": "51421801" },
      { "name": "Budesonide", "code": "51421802" },
      {
        "name": "Corticosteriod naphthoquinones and pregnanes and pregnenes",
        "code": "51421900"
      },
      { "name": "Naflocort", "code": "51421901" },
      { "name": "Locicortolone", "code": "51421902" },
      { "name": "Cortisone", "code": "51421903" },
      { "name": "Cortisone acetate", "code": "51421904" },
      { "name": "Cortisone aceticum", "code": "51421905" },
      { "name": "Locicortolone dicibate", "code": "51421906" },
      { "name": "Naflocort anhydrous", "code": "51421907" },
      { "name": "Corticosteroid peptide hormones", "code": "51422000" },
      { "name": "Cosyntropin or tetracosactide", "code": "51422001" },
      { "name": "Sermorelin", "code": "51422002" },
      { "name": "Sermorelin acetate", "code": "51422003" },
      { "name": "Corticosteroid pregnadienediols", "code": "51422100" },
      { "name": "Prednisone", "code": "51422101" },
      { "name": "Chloroprednisone", "code": "51422102" },
      { "name": "Clocortolone", "code": "51422103" },
      { "name": "Diflucortolone", "code": "51422104" },
      { "name": "Fluocortin", "code": "51422105" },
      { "name": "Fluocortolone", "code": "51422106" },
      { "name": "Meprednisone", "code": "51422107" },
      { "name": "Mometasone", "code": "51422108" },
      { "name": "Tralonide", "code": "51422109" },
      { "name": "Chloroprednisone acetate", "code": "51422110" },
      { "name": "Clocortolone acetate", "code": "51422111" },
      { "name": "Clocortolone pivalate", "code": "51422112" },
      { "name": "Diflucortolone pivalate", "code": "51422113" },
      { "name": "Diflucortolone valerate", "code": "51422114" },
      { "name": "Fluocortin butyl", "code": "51422115" },
      { "name": "Fluocortin butyl ester", "code": "51422116" },
      { "name": "Fluocortolone acetate", "code": "51422117" },
      { "name": "Fluocortolone caproate", "code": "51422118" },
      { "name": "Meprednisone disodium phosphate", "code": "51422119" },
      { "name": "Mometasone furoate", "code": "51422120" },
      { "name": "Mometasone furoate hydrate", "code": "51422121" },
      { "name": "Mometasone furoate monohydrate", "code": "51422122" },
      { "name": "Prednisone intensol", "code": "51422123" },
      { "name": "Corticosteroid pregnadienes", "code": "51422200" },
      { "name": "Fluocinonide", "code": "51422201" },
      { "name": "Fluorometholone", "code": "51422202" },
      { "name": "Rimexolone", "code": "51422203" },
      { "name": "Triamcinolone", "code": "51422204" },
      { "name": "Flunarizine", "code": "51422205" },
      { "name": "Fluocinolone acetonide", "code": "51422206" },
      { "name": "Flunarizine dihydrochloride", "code": "51422207" },
      { "name": "Flunarizine hydrochloride", "code": "51422208" },
      { "name": "Fluorometholone acetate", "code": "51422209" },
      { "name": "Triamcinolone acetonide", "code": "51422210" },
      { "name": "Triamcinolone acetonide hemisuccinate", "code": "51422211" },
      {
        "name": "Triamcinolone acetonide sodium phosphate",
        "code": "51422212"
      },
      { "name": "Triamcinolone acetonide valerate", "code": "51422213" },
      { "name": "Triamcinolone benetonide", "code": "51422214" },
      { "name": "Triamcinolone diacetate", "code": "51422215" },
      { "name": "Triamcinolone hexacetonide", "code": "51422216" },
      { "name": "Corticosteroid pregnadienetriols", "code": "51422300" },
      { "name": "Alclometasone", "code": "51422301" },
      { "name": "Beclometasone or Beclomethasone", "code": "51422302" },
      { "name": "Betamethasone", "code": "51422303" },
      { "name": "Desonide", "code": "51422304" },
      { "name": "Desoximetasone", "code": "51422305" },
      { "name": "Dexamethasone", "code": "51422306" },
      { "name": "Fluprednidene", "code": "51422307" },
      { "name": "Dichlorisone", "code": "51422308" },
      { "name": "Difluprednate", "code": "51422309" },
      { "name": "Fluprednisolone", "code": "51422310" },
      { "name": "Mazipredone", "code": "51422311" },
      { "name": "Methylprednisolone", "code": "51422312" },
      { "name": "Paramethasone", "code": "51422313" },
      { "name": "Prednicarbate", "code": "51422314" },
      { "name": "Prednisolone", "code": "51422315" },
      { "name": "Prednylidene", "code": "51422316" },
      { "name": "Fluclorolone", "code": "51422317" },
      { "name": "Melengestrol", "code": "51422318" },
      { "name": "Fluperolone", "code": "51422319" },
      { "name": "Halometasone", "code": "51422320" },
      { "name": "Alclometasone dipropionate", "code": "51422321" },
      { "name": "Betamethasone acetate", "code": "51422322" },
      { "name": "Betamethasone acibutate", "code": "51422323" },
      { "name": "Betamethasone benzoate", "code": "51422324" },
      { "name": "Betamethasone butyrate propionate", "code": "51422325" },
      { "name": "Betamethasone dihydrogen phosphate", "code": "51422326" },
      { "name": "Betamethasone dipropionate", "code": "51422327" },
      { "name": "Betamethasone phosphate", "code": "51422328" },
      { "name": "Betamethasone sodium phosphate", "code": "51422329" },
      { "name": "Betamethasone succinate", "code": "51422330" },
      { "name": "Betamethasone valerate", "code": "51422331" },
      { "name": "Dexamethasone acefurate", "code": "51422332" },
      { "name": "Dexamethasone acetate", "code": "51422333" },
      { "name": "Dexamethasone acetate anhydrous", "code": "51422334" },
      { "name": "Dexamethasone beloxil", "code": "51422335" },
      { "name": "Dexamethasone dipropionate", "code": "51422336" },
      { "name": "Dexamethasone isonicotinate", "code": "51422337" },
      { "name": "Dexamethasone metasulfobenzoate sodium", "code": "51422338" },
      { "name": "Dexamethasone palmitate", "code": "51422339" },
      { "name": "Dexamethasone phosphate", "code": "51422340" },
      { "name": "Dexamethasone pivalate", "code": "51422341" },
      { "name": "Dexamethasone sodium phosphate", "code": "51422342" },
      { "name": "Dexamethasone tebutate", "code": "51422343" },
      { "name": "Dexamethasone trimethyl acetate", "code": "51422344" },
      { "name": "Dexamethasone valerate", "code": "51422345" },
      { "name": "Dichlorisone acetate", "code": "51422346" },
      { "name": "Fluclorolone acetonide", "code": "51422347" },
      { "name": "Fluperolone acetate", "code": "51422348" },
      { "name": "Fluprednisolone valerate", "code": "51422349" },
      { "name": "Halometasone monohydrate", "code": "51422350" },
      { "name": "Mazipredone hydrochloride", "code": "51422351" },
      { "name": "Melengestrol acetate", "code": "51422352" },
      { "name": "Methylprednisolone aceponate", "code": "51422353" },
      { "name": "Methylprednisolone acetate", "code": "51422354" },
      { "name": "Methylprednisolone sodium phosphate", "code": "51422355" },
      { "name": "Methylprednisolone sodium succinate", "code": "51422356" },
      { "name": "Methylprednisolone suleptanate", "code": "51422357" },
      { "name": "Paramethasone acetate", "code": "51422358" },
      { "name": "Paramethasone disodium phosphate", "code": "51422359" },
      { "name": "Prednisolone acetate", "code": "51422360" },
      { "name": "Prednisolone hemisuccinate", "code": "51422361" },
      { "name": "Prednisolone phosphate", "code": "51422362" },
      { "name": "Prednisolone sodium metasulfobenzoate", "code": "51422363" },
      { "name": "Prednisolone sodium phosphate", "code": "51422364" },
      { "name": "Prednisolone sodium succinate", "code": "51422365" },
      { "name": "Prednisolone steaglate", "code": "51422366" },
      { "name": "Prednisolone succinate", "code": "51422367" },
      { "name": "Prednisolone tebutate", "code": "51422368" },
      { "name": "Prednisolone valerate acetate", "code": "51422369" },
      { "name": "Corticosteroid pregnenediones", "code": "51422400" },
      {
        "name": "11-deoxycortisol or cortexolone or cortodoxone",
        "code": "51422401"
      },
      { "name": "Aldosterone", "code": "51422402" },
      { "name": "Anecortave", "code": "51422403" },
      { "name": "Butixocort", "code": "51422404" },
      { "name": "Ciclesonide", "code": "51422405" },
      { "name": "Cloprednol", "code": "51422406" },
      { "name": "Cortivazol", "code": "51422407" },
      { "name": "Deflazacort", "code": "51422408" },
      {
        "name": "Deoxycorticosterone or desoxycorticosterone or desoxycortone",
        "code": "51422409"
      },
      { "name": "Fludrocortisone", "code": "51422410" },
      { "name": "Fludroxycortide or flurandrenolide", "code": "51422411" },
      { "name": "Flumedroxone", "code": "51422412" },
      { "name": "Flunisolide", "code": "51422413" },
      { "name": "Halcinonide", "code": "51422414" },
      { "name": "Hydrocortisone", "code": "51422415" },
      { "name": "Medrysone", "code": "51422416" },
      { "name": "Hydrocortisone butyrate", "code": "51422417" },
      { "name": "Tixocortol", "code": "51422418" },
      { "name": "Desoxycorticosterone or desoxycortone", "code": "51422419" },
      { "name": "Desoxycortone pivalate", "code": "51422420" },
      { "name": "Fludrocortisone acetate", "code": "51422421" },
      { "name": "Fludrocortisone hemisuccinate", "code": "51422422" },
      { "name": "Flunisolide acetate", "code": "51422423" },
      { "name": "Hydrocortisone aceponate", "code": "51422424" },
      { "name": "Hydrocortisone acetate", "code": "51422425" },
      { "name": "Hydrocortisone buteprate", "code": "51422426" },
      { "name": "Hydrocortisone butyrate propionate", "code": "51422427" },
      { "name": "Hydrocortisone caproate", "code": "51422428" },
      { "name": "Hydrocortisone cypionate", "code": "51422429" },
      { "name": "Hydrocortisone hemisuccinate anhydrous", "code": "51422430" },
      { "name": "Hydrocortisone phosphate", "code": "51422431" },
      { "name": "Hydrocortisone probutate", "code": "51422432" },
      { "name": "Hydrocortisone sodium phosphate", "code": "51422433" },
      { "name": "Hydrocortisone sodium succinate", "code": "51422434" },
      { "name": "Hydrocortisone tebutate", "code": "51422435" },
      { "name": "Hydrocortisone valerate", "code": "51422436" },
      { "name": "Tixocortol pivalate", "code": "51422437" },
      { "name": "Corticosteroids, synthesized", "code": "51422500" },
      { "name": "Isoprednidene", "code": "51422501" },
      { "name": "Acrocinonide", "code": "51422502" },
      { "name": "Amcinafide", "code": "51422503" },
      { "name": "Amcinonide", "code": "51422504" },
      { "name": "Amebucort", "code": "51422505" },
      { "name": "Cicortonide", "code": "51422506" },
      { "name": "Cortisuzol", "code": "51422507" },
      { "name": "Halocortolone", "code": "51422508" },
      { "name": "Hydrocortamate", "code": "51422509" },
      { "name": "Oxisopred", "code": "51422510" },
      { "name": "Triclonide", "code": "51422511" },
      { "name": "Hydrocortamate hydrochloride", "code": "51422512" },
      { "name": "Combination glucocorticoids", "code": "51422600" },
      { "name": "Dexamethasone/lidocaine", "code": "51422601" },
      { "name": "Antihypertensive drugs", "code": "51430000" },
      {
        "name": "Antihypertensive acrylates and amides and angiotensins and aromatic amino acids",
        "code": "51431500"
      },
      { "name": "Eprosartan", "code": "51431501" },
      { "name": "Aliskiren", "code": "51431502" },
      { "name": "Tirofiban", "code": "51431503" },
      { "name": "Arfalasin", "code": "51431504" },
      { "name": "Aliskiren fumarate", "code": "51431505" },
      { "name": "Eprosartan mesilate or mesylate", "code": "51431506" },
      { "name": "Tirofiban hydrochloride", "code": "51431507" },
      { "name": "Tirofiban hydrochloride anhydrous", "code": "51431508" },
      { "name": "Tirofiban hydrochloride monohydrate", "code": "51431509" },
      { "name": "Antihypertensive amines", "code": "51431600" },
      { "name": "Anipamil", "code": "51431601" },
      { "name": "Atomoxetine", "code": "51431602" },
      { "name": "Devapamil", "code": "51431603" },
      { "name": "Fendiline", "code": "51431604" },
      { "name": "Gallopamil", "code": "51431605" },
      { "name": "Metirosine or metyrosine", "code": "51431606" },
      { "name": "Pargyline", "code": "51431607" },
      { "name": "Terodiline", "code": "51431608" },
      { "name": "Verapamil", "code": "51431609" },
      { "name": "Fendiline hydrochloride", "code": "51431610" },
      { "name": "Gallopamil hydrochloride", "code": "51431611" },
      { "name": "Pargyline hydrochloride", "code": "51431612" },
      { "name": "Terodiline hydrochloride", "code": "51431613" },
      { "name": "Verapamil hydrochloride", "code": "51431614" },
      { "name": "Antihypertensive benzazepines", "code": "51431700" },
      { "name": "Benazepril", "code": "51431701" },
      { "name": "Benazeprilat", "code": "51431702" },
      { "name": "Libenzapril", "code": "51431703" },
      { "name": "Diltiazem", "code": "51431704" },
      { "name": "Clentiazem", "code": "51431705" },
      { "name": "Benazepril hydrochloride", "code": "51431706" },
      { "name": "Clentiazem maleate", "code": "51431707" },
      { "name": "Diltiazem hydrochloride", "code": "51431708" },
      { "name": "Diltiazem malate", "code": "51431709" },
      {
        "name": "Antihypertensive benzene derivatives and benzimidazoles and benzofurans and catecholamines",
        "code": "51431800"
      },
      { "name": "Irbesartan", "code": "51431801" },
      { "name": "Diclofurime", "code": "51431802" },
      { "name": "Methyldopa", "code": "51431803" },
      { "name": "Azilsartan", "code": "51431804" },
      { "name": "Irbesartan hydrochloride", "code": "51431805" },
      { "name": "Methyldopa anhydrous", "code": "51431806" },
      { "name": "Methyldopa sesquihydrate", "code": "51431807" },
      { "name": "Antihypertensive alcohols", "code": "51431900" },
      { "name": "Candesartan", "code": "51431901" },
      { "name": "Telmisartan", "code": "51431902" },
      { "name": "Exaprolol", "code": "51431903" },
      { "name": "Candesartan cilexetil", "code": "51431904" },
      { "name": "Exaprolol hydrochloride", "code": "51431905" },
      {
        "name": "Antihypertensive cycloparaffins and indans and isoxazoles and pregnanes",
        "code": "51432000"
      },
      { "name": "Sitaxentan", "code": "51432001" },
      { "name": "Bencyclane", "code": "51432002" },
      { "name": "Delapril", "code": "51432003" },
      { "name": "Guggulsterone", "code": "51432004" },
      { "name": "Bencyclane fumarate", "code": "51432005" },
      { "name": "Delapril hydrochloride", "code": "51432006" },
      { "name": "Sitaxentan sodium", "code": "51432007" },
      { "name": "Antihypertensive guanidines", "code": "51432100" },
      { "name": "Guabenxan", "code": "51432101" },
      { "name": "Guanadrel", "code": "51432102" },
      { "name": "Guanclofine", "code": "51432103" },
      { "name": "Guanethidine", "code": "51432104" },
      { "name": "Guanfacine", "code": "51432105" },
      { "name": "Guanoclor", "code": "51432106" },
      { "name": "Guanoxyfen", "code": "51432107" },
      { "name": "Pinacidil", "code": "51432108" },
      { "name": "Guanadrel sulfate", "code": "51432109" },
      { "name": "Guanethidine monosulfate", "code": "51432110" },
      { "name": "Guanethidine sulfate", "code": "51432111" },
      { "name": "Guanfacine hydrochloride", "code": "51432112" },
      { "name": "Guanoclor sulfate", "code": "51432113" },
      { "name": "Antihypertensive hydralazines", "code": "51432200" },
      { "name": "Budralazine", "code": "51432201" },
      { "name": "Dihydralazine", "code": "51432202" },
      { "name": "Dihydralazine hydrochloride", "code": "51432203" },
      { "name": "Dihydralazine mesilate", "code": "51432204" },
      { "name": "Dihydralazine sulfate", "code": "51432205" },
      { "name": "Dihydralazine sulphate", "code": "51432206" },
      { "name": "Dihydralazine tartrate", "code": "51432207" },
      { "name": "Antihypertensive imidazoles", "code": "51432300" },
      { "name": "Ciprokiren", "code": "51432301" },
      { "name": "Imidapril", "code": "51432302" },
      { "name": "Moxonidine", "code": "51432303" },
      { "name": "Imidaprilat", "code": "51432304" },
      { "name": "Losartan", "code": "51432305" },
      { "name": "Olmesartan", "code": "51432306" },
      { "name": "Pratosartan", "code": "51432307" },
      { "name": "Remikiren", "code": "51432308" },
      {
        "name": "Trimetaphan camsilate or trimethaphan camsylate",
        "code": "51432309"
      },
      { "name": "Trimetaphan or trimethaphan", "code": "51432310" },
      { "name": "Imidapril hydrochloride", "code": "51432311" },
      { "name": "Imidapril monohydrochloride", "code": "51432312" },
      { "name": "Losartan potassium", "code": "51432313" },
      { "name": "Moxonidine hydrochloride", "code": "51432314" },
      { "name": "Olmesartan medoxomil", "code": "51432315" },
      { "name": "Antihypertensive imino acids", "code": "51432400" },
      { "name": "Captopril", "code": "51432401" },
      { "name": "Moveltipril", "code": "51432402" },
      { "name": "Zofenopril", "code": "51432403" },
      { "name": "Zofenoprilat", "code": "51432404" },
      { "name": "Captopril disulfide", "code": "51432405" },
      { "name": "Moveltipril calcium", "code": "51432406" },
      { "name": "Zofenopril calcium", "code": "51432407" },
      { "name": "Zofenoprilat arginine", "code": "51432408" },
      { "name": "Antihypertensive indoles", "code": "51432500" },
      { "name": "Mecarbinate", "code": "51432501" },
      { "name": "Pentopril", "code": "51432502" },
      { "name": "Perindopril", "code": "51432503" },
      { "name": "Perindoprilat", "code": "51432504" },
      { "name": "Trandolapril", "code": "51432505" },
      { "name": "Trandolaprilat", "code": "51432506" },
      { "name": "Vintoperol", "code": "51432507" },
      { "name": "Methoserpidine", "code": "51432508" },
      { "name": "Oxprenoate", "code": "51432509" },
      { "name": "Perindopril arginine", "code": "51432510" },
      { "name": "Perindopril erbumine", "code": "51432511" },
      { "name": "Antihypertensive piperazines", "code": "51432600" },
      { "name": "Lidoflazine", "code": "51432601" },
      { "name": "Terciprazine", "code": "51432602" },
      { "name": "Zankiren", "code": "51432603" },
      { "name": "Monatepil", "code": "51432604" },
      { "name": "Naftopidil", "code": "51432605" },
      { "name": "Eplerenone", "code": "51432606" },
      { "name": "Monatepil maleate", "code": "51432607" },
      { "name": "Naftopidil dihydrochloride", "code": "51432608" },
      { "name": "Zankiren hydrochloride", "code": "51432609" },
      { "name": "Antihypertensive bicyclo compounds", "code": "51432700" },
      { "name": "Nicaraven", "code": "51432701" },
      { "name": "Zabicipril", "code": "51432702" },
      { "name": "Zabiciprilat", "code": "51432703" },
      { "name": "Flordipine", "code": "51432704" },
      { "name": "Ramipril", "code": "51432705" },
      { "name": "Ramiprilat", "code": "51432706" },
      { "name": "Antihypertensive oligopeptides", "code": "51432800" },
      { "name": "Saralasin", "code": "51432801" },
      { "name": "Ditekiren", "code": "51432802" },
      { "name": "Enalapril", "code": "51432803" },
      { "name": "Enalaprilat", "code": "51432804" },
      { "name": "Lisinopril", "code": "51432805" },
      { "name": "Enalapril maleate", "code": "51432806" },
      { "name": "Enalaprilat anhydrous", "code": "51432807" },
      { "name": "Enalaprilat dihydrate", "code": "51432808" },
      { "name": "Lisinopril anhydrous", "code": "51432809" },
      { "name": "Lisinopril dihydrate", "code": "51432810" },
      { "name": "Saralasin acetate", "code": "51432811" },
      {
        "name": "Antihypertensive organophosphorus compounds",
        "code": "51432900"
      },
      { "name": "Belfosdil", "code": "51432901" },
      { "name": "Fosinopril", "code": "51432902" },
      { "name": "Fosinoprilat", "code": "51432903" },
      { "name": "Fosinopril sodium", "code": "51432904" },
      { "name": "Antihypertensive oxazoles", "code": "51433000" },
      { "name": "Olmidine", "code": "51433001" },
      { "name": "Rilmenidine", "code": "51433002" },
      { "name": "Rilmenidine dihydrogen phosphate", "code": "51433003" },
      { "name": "Rilmenidine phosphate", "code": "51433004" },
      { "name": "Antihypertensive peptides", "code": "51433100" },
      { "name": "Enalkiren", "code": "51433101" },
      { "name": "Spirapril", "code": "51433102" },
      { "name": "Angiotensinamide", "code": "51433103" },
      { "name": "Spirapril hydrochloride", "code": "51433104" },
      { "name": "Antihypertensive phenylpropionates", "code": "51433200" },
      { "name": "Ambrisentan", "code": "51433201" },
      { "name": "Darusentan", "code": "51433202" },
      { "name": "Antihypertensive phthalazines", "code": "51433300" },
      { "name": "Cromakalim or levcromakalim", "code": "51433301" },
      { "name": "Flavodilol", "code": "51433302" },
      { "name": "Hydralazine", "code": "51433303" },
      { "name": "Flavodilol maleate", "code": "51433304" },
      { "name": "Hydralazine hydrochloride", "code": "51433305" },
      { "name": "Antihypertensive nicotinic acids", "code": "51433400" },
      { "name": "Diproteverine", "code": "51433401" },
      { "name": "Moexipril", "code": "51433402" },
      { "name": "Quinapril", "code": "51433403" },
      { "name": "Quinaprilat", "code": "51433404" },
      { "name": "Falipamil", "code": "51433405" },
      { "name": "Diproteverine hydrochloride", "code": "51433406" },
      { "name": "Moexipril hydrochloride", "code": "51433407" },
      { "name": "Quinapril hydrochloride", "code": "51433408" },
      {
        "name": "Antihypertensive quinolines and quinuclidines and tetrahydronaphthalenes and tropanes and veratrum alkaloids",
        "code": "51433500"
      },
      { "name": "Quinuclium bromide", "code": "51433501" },
      { "name": "Amiquinsin", "code": "51433502" },
      { "name": "Mibefradil", "code": "51433503" },
      { "name": "Phenactropinium", "code": "51433504" },
      { "name": "Cryptenamine", "code": "51433505" },
      { "name": "Amiquinsin hydrochloride", "code": "51433506" },
      { "name": "Mibefradil dihydrochloride", "code": "51433507" },
      { "name": "Mibefradil hydrochloride", "code": "51433508" },
      { "name": "Phenactropinium chloride", "code": "51433509" },
      { "name": "Antihypertensive piperidines", "code": "51433600" },
      { "name": "Ketanserin", "code": "51433601" },
      { "name": "Minoxidil", "code": "51433602" },
      { "name": "Pempidine", "code": "51433603" },
      { "name": "Perhexiline", "code": "51433604" },
      { "name": "Ketanserin tartrate", "code": "51433605" },
      { "name": "Minoxidil sulfate ester", "code": "51433606" },
      { "name": "Pempidine tartrate", "code": "51433607" },
      { "name": "Perhexiline maleate", "code": "51433608" },
      {
        "name": "Antihypertensive prostaglandins and pyrazoles and pyrimidines and quinazolines",
        "code": "51433700"
      },
      { "name": "Treprostinil", "code": "51433701" },
      { "name": "Muzolimine", "code": "51433702" },
      { "name": "Tasosartan", "code": "51433703" },
      { "name": "Pelanserin", "code": "51433704" },
      { "name": "Pelanserin hydrochloride", "code": "51433705" },
      { "name": "Treprostinil sodium", "code": "51433706" },
      { "name": "Antihypertensive pyridazines", "code": "51433800" },
      { "name": "Cadralazine", "code": "51433801" },
      { "name": "Cilazapril", "code": "51433802" },
      { "name": "Cilazaprilat", "code": "51433803" },
      { "name": "Levosimendan", "code": "51433804" },
      { "name": "Mopidralazine", "code": "51433805" },
      { "name": "Cilazapril anhydrous", "code": "51433806" },
      { "name": "Cilazapril monohydrate", "code": "51433807" },
      { "name": "Antihypertensive pyridines", "code": "51433900" },
      { "name": "Efonidipine", "code": "51433901" },
      { "name": "Amlodipine", "code": "51433902" },
      { "name": "Nifedipine", "code": "51433903" },
      { "name": "Niguldipine", "code": "51433904" },
      { "name": "Nilvadipine", "code": "51433905" },
      { "name": "Aprikalim", "code": "51433906" },
      { "name": "Nisoldipine", "code": "51433907" },
      { "name": "Barnidipine", "code": "51433908" },
      { "name": "Omapatrilat", "code": "51433909" },
      { "name": "Palonidipine", "code": "51433910" },
      { "name": "Teludipine", "code": "51433911" },
      { "name": "Cilnidipine", "code": "51433912" },
      { "name": "Clevidipine", "code": "51433913" },
      { "name": "Elgodipine", "code": "51433914" },
      { "name": "Felodipine", "code": "51433915" },
      { "name": "Forasartan", "code": "51433916" },
      { "name": "Isradipine", "code": "51433917" },
      { "name": "Tiadenol", "code": "51433918" },
      { "name": "Lercanidipine", "code": "51433919" },
      { "name": "Manidipine", "code": "51433920" },
      { "name": "Nicardipine", "code": "51433921" },
      { "name": "Benidipine", "code": "51433922" },
      { "name": "Lacidipine", "code": "51433923" },
      { "name": "Amlodipine besilate", "code": "51433924" },
      { "name": "Amlodipine maleate", "code": "51433925" },
      { "name": "Amlodipine mesilate", "code": "51433926" },
      { "name": "Amlodipine mesilate hydrate", "code": "51433927" },
      { "name": "Amlodipine mesylate hydrate", "code": "51433928" },
      { "name": "Barnidipine hydrochloride", "code": "51433929" },
      { "name": "Benidipine hydrochloride", "code": "51433930" },
      { "name": "Clevidipine butyrate", "code": "51433931" },
      { "name": "Efonidipine hydrochloride ethanolate", "code": "51433932" },
      { "name": "Elgodipine hydrochloride", "code": "51433933" },
      { "name": "Lercanidipine hydrochloride", "code": "51433934" },
      { "name": "Manidipine dihydrochloride", "code": "51433935" },
      { "name": "Manidipine hydrochloride", "code": "51433936" },
      { "name": "Nicardipine hydrochloride", "code": "51433937" },
      { "name": "Nifedipine hydrochloride", "code": "51433938" },
      { "name": "Teludipine hydrochloride", "code": "51433939" },
      { "name": "Antihypertensive pyrrolidines", "code": "51434000" },
      { "name": "Bepridil", "code": "51434001" },
      { "name": "Enalpril", "code": "51434002" },
      { "name": "Pentolinium or pentolonium", "code": "51434003" },
      { "name": "Rilmakalim", "code": "51434004" },
      { "name": "Vernakalant", "code": "51434005" },
      { "name": "Bepridil hydrochloride", "code": "51434006" },
      { "name": "Bepridil hydrochloride monohydrate", "code": "51434007" },
      { "name": "Pentolonium tartrate", "code": "51434008" },
      {
        "name": "Antihypertensive quaternary ammonium compounds",
        "code": "51434100"
      },
      { "name": "Azamethonium", "code": "51434101" },
      { "name": "Chlorisondamine", "code": "51434102" },
      { "name": "Hexamethonium", "code": "51434103" },
      { "name": "Pentamethonium", "code": "51434104" },
      { "name": "Azamethonium bromide", "code": "51434105" },
      { "name": "Chlorisondamine chloride", "code": "51434106" },
      { "name": "Hexamethonium bromide", "code": "51434107" },
      { "name": "Pentamethonium bromide", "code": "51434108" },
      {
        "name": "Antihypertensive secologanin tryptamine alkaloids",
        "code": "51434200"
      },
      { "name": "Bietaserpine", "code": "51434201" },
      { "name": "Deserpidine", "code": "51434202" },
      { "name": "Mefeserpine", "code": "51434203" },
      { "name": "Rescinnamine", "code": "51434204" },
      { "name": "Reserpine", "code": "51434205" },
      { "name": "Vincamine", "code": "51434206" },
      { "name": "Vincamine hydrochloride", "code": "51434207" },
      { "name": "Antihypertensive sulfonamides", "code": "51434300" },
      { "name": "Piretanide", "code": "51434301" },
      { "name": "Diazoxide", "code": "51434302" },
      { "name": "Bendroflumethiazide", "code": "51434303" },
      { "name": "Piretanide sodium", "code": "51434304" },
      { "name": "Antihypertensive terpenes", "code": "51434400" },
      { "name": "Mecamylamine", "code": "51434401" },
      { "name": "Tinabinol", "code": "51434402" },
      { "name": "Mecamylamine hydrochloride", "code": "51434403" },
      { "name": "Antihypertensive tetrazoles", "code": "51434500" },
      { "name": "Valsartan", "code": "51434502" },
      { "name": "Antihypertensive thiazoles", "code": "51434600" },
      { "name": "Etozolin", "code": "51434601" },
      { "name": "Rentiapril", "code": "51434602" },
      { "name": "Semotiadil", "code": "51434603" },
      { "name": "Utibapril", "code": "51434604" },
      { "name": "Semotiadil fumarate", "code": "51434605" },
      { "name": "Antihypertensive thiepins", "code": "51434700" },
      { "name": "Temocapril", "code": "51434701" },
      { "name": "Temocaprilat", "code": "51434702" },
      { "name": "Temocapril hydrochloride", "code": "51434703" },
      { "name": "Antihypertensive drugs, synthesized", "code": "51434800" },
      { "name": "Neldazosin", "code": "51434801" },
      { "name": "Benclonidine", "code": "51434802" },
      { "name": "Ciheptolane", "code": "51434803" },
      { "name": "Cronidipine", "code": "51434804" },
      { "name": "Dagapamil", "code": "51434805" },
      { "name": "Elisartan", "code": "51434806" },
      { "name": "Fenperate", "code": "51434807" },
      { "name": "Iprotiazem", "code": "51434808" },
      { "name": "Leptacline", "code": "51434809" },
      { "name": "Mazokalim", "code": "51434810" },
      { "name": "Utibaprilat", "code": "51434811" },
      { "name": "Orbutopril", "code": "51434812" },
      { "name": "Peraquinsin", "code": "51434813" },
      { "name": "Piclonidine", "code": "51434814" },
      { "name": "Pivopril", "code": "51434815" },
      { "name": "Rescimetol", "code": "51434816" },
      { "name": "Sagandipine", "code": "51434817" },
      { "name": "Sornidipine", "code": "51434818" },
      { "name": "Tefenperate", "code": "51434819" },
      { "name": "Tenosal", "code": "51434820" },
      { "name": "Zolasartan", "code": "51434821" },
      { "name": "Combination antihypertensives", "code": "51434900" },
      { "name": "Amlodipine/valsartan", "code": "51434901" },
      {
        "name": "Aliskiren/amlodipine/hydrochlorothiazide",
        "code": "51434902"
      },
      { "name": "Aliskiren/hydrochlorothiazide", "code": "51434903" },
      { "name": "Aliskiren/valsartan", "code": "51434904" },
      { "name": "Alskiren/amlodipine", "code": "51434905" },
      { "name": "Amlodipine/benazepril", "code": "51434906" },
      {
        "name": "Amlodipine/hydrochlorothiazide/olmesartan",
        "code": "51434907"
      },
      {
        "name": "Amlodipine/hydrochlorothiazide/valsartan",
        "code": "51434908"
      },
      { "name": "Amlodipine/olmesartan", "code": "51434909" },
      { "name": "Amlodipine/telmisartan", "code": "51434910" },
      { "name": "Chlorothiazide/reserpine", "code": "51434911" },
      { "name": "Atenolol/chlorthalidone", "code": "51434912" },
      { "name": "Benazepril/hydrochlorothiazide", "code": "51434913" },
      { "name": "Bendroflumethiazide/nadolol", "code": "51434914" },
      {
        "name": "Bendroflumethiazide/potassium chloride/rauwolfia serpentina",
        "code": "51434915"
      },
      {
        "name": "Bendroflumethiazide/rauwolfia serpentina",
        "code": "51434916"
      },
      { "name": "Bisoprolol/hydrochlorothiazide", "code": "51434917" },
      { "name": "Candesartan/hydrochlorothiazide", "code": "51434918" },
      { "name": "Captopril/hydrochlorothiazide", "code": "51434919" },
      { "name": "Chlorothiazide/methyldopa", "code": "51434920" },
      {
        "name": "Flumethiazide/potassium chloride/rauwolfia serpentina",
        "code": "51434921"
      },
      { "name": "Chlorthalidone/clonidine", "code": "51434922" },
      { "name": "Chlorthalidone/reserpine", "code": "51434923" },
      { "name": "Cryptenamine/methyclothiazide", "code": "51434924" },
      { "name": "Deserpidine/hydrochlorothiazide", "code": "51434925" },
      { "name": "Deserpidine/methyclothiazide", "code": "51434926" },
      { "name": "Diltiazem/enalapril", "code": "51434927" },
      { "name": "Enalapril/felodipine", "code": "51434928" },
      { "name": "Enalapril/hydrochlorothiazide", "code": "51434929" },
      { "name": "Eprosartan/hydrochlorothiazide", "code": "51434930" },
      { "name": "Hydrochlorothiazide/losartan", "code": "51434931" },
      { "name": "Fosinopril/hydrochlorothiazide", "code": "51434932" },
      { "name": "Guanethidine/hydrochlorothiazide", "code": "51434933" },
      { "name": "Hydralazine/hydrochlorothiazide", "code": "51434934" },
      {
        "name": "Hydralazine/hydrochlorothiazide/reserpine",
        "code": "51434935"
      },
      { "name": "Hydralazine/isosorbide", "code": "51434936" },
      { "name": "Hydralazine/reserpine", "code": "51434937" },
      { "name": "Hydrochlorothiazide/irbesartan", "code": "51434938" },
      { "name": "Hydrochlorothiazide/labetalol", "code": "51434939" },
      { "name": "Hydrochlorothiazide/lisinopril", "code": "51434940" },
      { "name": "Hydrochlorothiazide/valsartan", "code": "51434941" },
      { "name": "Hydrochlorothiazide/methyldopa", "code": "51434942" },
      { "name": "Hydrochlorothiazide/metoprolol", "code": "51434943" },
      { "name": "Hydrochlorothiazide/moexipril", "code": "51434944" },
      { "name": "Hydrochlorothiazide/olmesartan", "code": "51434945" },
      { "name": "Hydrochlorothiazide/propranolol", "code": "51434946" },
      { "name": "Hydrochlorothiazide/quinapril", "code": "51434947" },
      { "name": "Hydrochlorothiazide/reserpine", "code": "51434948" },
      { "name": "Hydrochlorothiazide/telmisartan", "code": "51434949" },
      { "name": "Hydrochlorothiazide/timolol", "code": "51434950" },
      { "name": "Hydroflumethiazide/reserpine", "code": "51434951" },
      { "name": "Methyclothiazide/pargyline", "code": "51434952" },
      { "name": "Methyclothiazide/reserpine", "code": "51434953" },
      { "name": "Polythiazide/prazosin", "code": "51434954" },
      { "name": "Polythiazide/reserpine", "code": "51434955" },
      { "name": "Quinethazone/reserpine", "code": "51434956" },
      { "name": "Reserpine/trichlormethiazide", "code": "51434957" },
      { "name": "Combination calcium channel blockers", "code": "51435000" },
      { "name": "Amlodipine/atorvastatin", "code": "51435001" },
      { "name": "Sodium chloride/verapamil", "code": "51435002" },
      { "name": "Radiopharmaceuticals and contrast media", "code": "51440000" },
      { "name": "Medical imaging contrast media", "code": "51441500" },
      { "name": "Iocarmic acid", "code": "51441520" },
      { "name": "Iodoxamic acid", "code": "51441523" },
      { "name": "Sodium ioxaglate", "code": "51441549" },
      { "name": "Technetium tc-99m or 99mtc", "code": "51441562" },
      { "name": "Barium sulfate", "code": "51441577" },
      { "name": "Loversol", "code": "51441579" },
      {
        "name": "Diagnostic agents and radiopharmaceuticals",
        "code": "51441600"
      },
      {
        "name": "Aminohippurate sodium or Aminohippuric acid",
        "code": "51441601"
      },
      { "name": "Fluorescein", "code": "51441603" },
      { "name": "Methacholine", "code": "51441605" },
      { "name": "Metyrapone", "code": "51441606" },
      { "name": "Radiopharmaceutical preparation kit", "code": "51441608" },
      { "name": "Etanidazole", "code": "51441611" },
      { "name": "Ibritumomab tiuxetan 90y", "code": "51441612" },
      { "name": "Iodine-125-fibrinogen", "code": "51441614" },
      { "name": "Alsactide", "code": "51441617" },
      {
        "name": "Combination contrast media, imaging agents, and radiopharmaceuticals",
        "code": "51441700"
      },
      { "name": "Medronate disodium/stannous chloride", "code": "51441701" },
      { "name": "Albumin/stannous chloride", "code": "51441702" },
      {
        "name": "Ascorbic acid/methylene diphosphonic acid/stannous chloride",
        "code": "51441703"
      },
      { "name": "Barium sulfate/methylcellulose", "code": "51441704" },
      { "name": "Diatrizoate/iodine", "code": "51441705" },
      { "name": "Diatrizoate/iodipamide", "code": "51441706" },
      { "name": "Diatrizoate/lidocaine", "code": "51441707" },
      { "name": "Disofenin/stannous chloride", "code": "51441708" },
      { "name": "Gluceptate/stannous chloride", "code": "51441709" },
      { "name": "Gluceptate/stannous chloride/tin", "code": "51441710" },
      {
        "name": "Methylene diphosphonic acid/stannous chloride",
        "code": "51441711"
      },
      {
        "name": "Pentetate calcium trisodium/stannous chloride",
        "code": "51441712"
      },
      { "name": "Pentetate pentasodium/stannous chloride", "code": "51441713" },
      { "name": "Sodium hydroxymethane/stannous chloride", "code": "51441714" },
      { "name": "Stannous chloride/succimer", "code": "51441715" },
      { "name": "Combination diagnostic agents", "code": "51441800" },
      { "name": "Cosyntropin/mannitol", "code": "51441801" },
      { "name": "Sodium bicarbonate/tartaric acid", "code": "51441802" },
      {
        "name": "Benzoic acid-based medical imaging agents, media, and tracers",
        "code": "51441900"
      },
      { "name": "Acetrizoic acid", "code": "51441901" },
      {
        "name": "Amidotrizoic acid or diatrizoate or diatrizoic acid",
        "code": "51441902"
      },
      { "name": "Diazoate", "code": "51441903" },
      { "name": "Methiodal", "code": "51441904" },
      { "name": "Methiodal sodium", "code": "51441905" },
      { "name": "Metrizamide", "code": "51441906" },
      { "name": "Metrizoic acid", "code": "51441907" },
      {
        "name": "Fluorine-based medical imaging agents, media, and tracers",
        "code": "51442000"
      },
      { "name": "Fludarabine", "code": "51442001" },
      {
        "name": "Fludeoxyglucose (18f) or fludeoxyglucose f-18",
        "code": "51442002"
      },
      { "name": "Fluorodopa", "code": "51442003" },
      { "name": "Fluorouracil", "code": "51442004" },
      { "name": "Sodium fluoride", "code": "51442005" },
      { "name": "Sulfur hexafluoride", "code": "51442006" },
      {
        "name": "TFT or trifluorothymidine or trifluridine",
        "code": "51442007"
      },
      {
        "name": "Fluorocarbon-based medical imaging agents, media, and tracers",
        "code": "51442100"
      },
      { "name": "Perflenapent or perfluoropentane", "code": "51442101" },
      { "name": "Perflubron or perfluorooctyl bromide", "code": "51442102" },
      {
        "name": "Gadolinium-based medical imaging agents, media, and tracers",
        "code": "51442200"
      },
      { "name": "Gadobenic acid", "code": "51442201" },
      { "name": "Gadobutrol", "code": "51442202" },
      { "name": "Gadodiamide", "code": "51442203" },
      { "name": "Gadodiamide hydrate", "code": "51442204" },
      { "name": "Gadofosveset", "code": "51442205" },
      { "name": "Gadofosveset trisodium", "code": "51442206" },
      { "name": "Gadolinium", "code": "51442207" },
      { "name": "Gadopentetic acid", "code": "51442208" },
      { "name": "Gadoteric acid", "code": "51442209" },
      { "name": "Gadoteridol", "code": "51442210" },
      { "name": "Gadoversetamide", "code": "51442211" },
      { "name": "Gadoxetic acid", "code": "51442212" },
      {
        "name": "Indium-based medical imaging agents and media",
        "code": "51442300"
      },
      { "name": "Indium 111 capromab pendetide", "code": "51442301" },
      { "name": "Indium in 111 oxyquinoline", "code": "51442302" },
      {
        "name": "Iodine-based radiopharmaceuticals, imaging agents, media, and tracers",
        "code": "51442400"
      },
      { "name": "Adipiodone or iodipamide", "code": "51442402" },
      { "name": "Calcium iopodate", "code": "51442403" },
      { "name": "Diodone or iodopyracet", "code": "51442404" },
      {
        "name": "Ethiodized oil or ethiodol or or lipiodol",
        "code": "51442405"
      },
      { "name": "Iobenguane", "code": "51442406" },
      { "name": "Iobenguane sulfate", "code": "51442407" },
      { "name": "Iobenzamic acid", "code": "51442408" },
      { "name": "Iobitridol", "code": "51442409" },
      {
        "name": "Iocarmate or iocarmic acid or iocarmate meglumine",
        "code": "51442410"
      },
      { "name": "Iocetamic acid", "code": "51442411" },
      { "name": "Iodamide or iodamide meglumine", "code": "51442412" },
      {
        "name": "Iodinated i-125 human serum albumin or jeanatope",
        "code": "51442413"
      },
      { "name": "Iodixanol", "code": "51442414" },
      {
        "name": "Iodoxamate or iodoxamate meglumine or iodoxamic acid",
        "code": "51442415"
      },
      { "name": "Iofendylate or iophendylate", "code": "51442416" },
      { "name": "Iofetamine i-123", "code": "51442417" },
      { "name": "Ioflupane 123I", "code": "51442418" },
      { "name": "Ioglicic acid", "code": "51442419" },
      { "name": "Ioglycamic acid", "code": "51442420" },
      { "name": "Iohexol", "code": "51442421" },
      { "name": "Iomeprol", "code": "51442422" },
      { "name": "Iopamidol", "code": "51442423" },
      { "name": "Iopanoic acid", "code": "51442424" },
      { "name": "Iopentol", "code": "51442425" },
      { "name": "Iophenoic acid or ioprocemic acid", "code": "51442426" },
      { "name": "Iopromide", "code": "51442427" },
      { "name": "Iopydol", "code": "51442428" },
      {
        "name": "Iotalamic acid or iotalamate or sodium iotalamate",
        "code": "51442429"
      },
      { "name": "Iotrolan", "code": "51442430" },
      { "name": "Iotroxic acid", "code": "51442431" },
      {
        "name": "Ioxaglate or ioxaglate meglumine or ioxaglate sodium or sodium ioxaglate",
        "code": "51442432"
      },
      { "name": "Ioxaglic acid", "code": "51442433" },
      {
        "name": "Ioxitalamic acid or ioxithalamic acid or ioxithalamate",
        "code": "51442434"
      },
      { "name": "Propyliodone", "code": "51442435" },
      { "name": "Rose bengal sodium I 131", "code": "51442436" },
      { "name": "Sodium iodide i-123", "code": "51442437" },
      { "name": "Sodium iodide i-131", "code": "51442438" },
      { "name": "Sodium iopodate", "code": "51442439" },
      { "name": "Sodium tyropanoate or tyropanoic acid", "code": "51442440" },
      {
        "name": "Manganese-based radiopharmaceuticals, imaging agents, media, and tracers",
        "code": "51442500"
      },
      { "name": "Mangafodipir", "code": "51442501" },
      {
        "name": "Sodium-based radiopharmaceuticals, imaging agents, media, and tracers",
        "code": "51442600"
      },
      {
        "name": "Indigo carmine or indigotindisulfonate sodium",
        "code": "51442601"
      },
      { "name": "Phenolsulfonphthalein", "code": "51442602" },
      { "name": "Sulfobromophthalein sodium", "code": "51442603" },
      {
        "name": "Technetium-based radiopharmaceuticals, imaging agents, media, and tracers",
        "code": "51442700"
      },
      {
        "name": "Technetium Tc-99m apcitide or technetium 99mtc apcitide",
        "code": "51442701"
      },
      {
        "name": "Technetium Tc-99m arcitumomab or technetium 99mtc arcitumomab",
        "code": "51442702"
      },
      {
        "name": "Technetium Tc-99m bicisate or technetium 99mtc bicisate",
        "code": "51442703"
      },
      {
        "name": "Technetium Tc-99m disofenin or technetium 99mtc disofenin",
        "code": "51442704"
      },
      {
        "name": "Technetium Tc-99m fanolesomab or technetium 99mtc fanolesomab",
        "code": "51442705"
      },
      {
        "name": "Technetium Tc-99m furifosmin or technetium 99mtc furifosmin",
        "code": "51442706"
      },
      {
        "name": "Technetium Tc-99m gluceptate or technetium 99mtc gluceptate",
        "code": "51442707"
      },
      {
        "name": "Technetium Tc-99m mebrofenin or technetium 99mtc mebrofenin",
        "code": "51442708"
      },
      {
        "name": "Technetium Tc-99m medronate or technetium 99mtc medronate",
        "code": "51442709"
      },
      {
        "name": "Technetium Tc-99m mertiatide or technetium 99mtc mertiatide",
        "code": "51442710"
      },
      {
        "name": "Technetium Tc-99m nofetumomab merpentan or technetium 99mtc nofetumomab merpentan",
        "code": "51442711"
      },
      {
        "name": "Technetium Tc-99m pentetate or technetium 99mtc pentetate",
        "code": "51442712"
      },
      {
        "name": "Technetium Tc-99m pertechnetate or technetium 99mtc pertechnetate",
        "code": "51442713"
      },
      {
        "name": "Technetium Tc-99m pintumomab or technetium 99mtc pintumomab",
        "code": "51442714"
      },
      {
        "name": "Technetium Tc-99m pyrophosphate or technetium 99mtc pyrophosphate",
        "code": "51442715"
      },
      {
        "name": "Technetium Tc-99m sestamibi or technetium 99mtc sestamibi",
        "code": "51442716"
      },
      {
        "name": "Technetium Tc-99m sodium pertechnetate or technetium 99mtc sodium pertechnetate",
        "code": "51442717"
      },
      {
        "name": "Technetium Tc-99m succimer or technetium 99mtc succimer",
        "code": "51442718"
      },
      {
        "name": "Technetium Tc-99m sulfur colloid or technetium 99mtc sulfur colloid",
        "code": "51442719"
      },
      {
        "name": "Technetium Tc-99m teboroxime or technetium 99mtc teboroxime",
        "code": "51442720"
      },
      {
        "name": "Technetium Tc-99m tetrofosmin or technetium 99mtc tetrofosmin",
        "code": "51442721"
      },
      {
        "name": "Technetium Tc-99m tilmanocept or technetium 99mtc tilmanocept",
        "code": "51442722"
      },
      { "name": "Anthelmintics", "code": "51450000" },
      { "name": "Anthelmintic chlorophenols", "code": "51451500" },
      { "name": "Dichlorophen", "code": "51451501" },
      { "name": "Nitroclofene", "code": "51451502" },
      { "name": "Anthelmintic benzimidazoles", "code": "51451600" },
      { "name": "Cambendazole", "code": "51451601" },
      { "name": "Ciclobendazole or cyclobendazole", "code": "51451602" },
      { "name": "Fenbendazole", "code": "51451603" },
      { "name": "Oxfendazole", "code": "51451604" },
      { "name": "Oxibendazole", "code": "51451605" },
      { "name": "Triclabendazole", "code": "51451606" },
      { "name": "Dribendazole", "code": "51451608" },
      { "name": "Etibendazole", "code": "51451609" },
      { "name": "Lobendazole", "code": "51451610" },
      { "name": "Subendazole", "code": "51451611" },
      { "name": "Fenbendazole for veterinary use", "code": "51451612" },
      { "name": "Oxfendazole sulfone", "code": "51451613" },
      {
        "name": "Anthelmintic phenothiazines and piperidines and pyrrolidines and quaternary ammonium compounds and thiophenes",
        "code": "51451700"
      },
      { "name": "Bephenium", "code": "51451701" },
      { "name": "Kainic acid", "code": "51451702" },
      { "name": "Pyrathiazine", "code": "51451703" },
      { "name": "Thenium closylate", "code": "51451704" },
      { "name": "Ticarbodine", "code": "51451705" },
      { "name": "Bephenium hydroxynaphthoate", "code": "51451706" },
      { "name": "Anthelmintic from other constituents", "code": "51451800" },
      { "name": "Albendazole", "code": "51451801" },
      { "name": "Imcarbofos", "code": "51451802" },
      { "name": "Nitrodan", "code": "51451803" },
      { "name": "Zilantel", "code": "51451804" },
      { "name": "Albendazole oxide", "code": "51451805" },
      { "name": "Albendazole sulfone", "code": "51451806" },
      { "name": "Albendazole sulfoxide", "code": "51451807" },
      { "name": "Anthelmintic amides", "code": "51451900" },
      { "name": "Bromoxanide", "code": "51451901" },
      { "name": "Flurantel", "code": "51451902" },
      { "name": "Piperamide", "code": "51451903" },
      { "name": "Salantel", "code": "51451904" },
      { "name": "Clorsulon", "code": "51451905" },
      { "name": "Closantel", "code": "51451906" },
      { "name": "Niclosamide", "code": "51451907" },
      { "name": "Oxyclozanide or zanil", "code": "51451908" },
      { "name": "Resorantel", "code": "51451909" },
      { "name": "Niclosamide monohydrate", "code": "51451910" },
      { "name": "Piperamide maleate", "code": "51451911" },
      {
        "name": "Anthelmintic amidines and amines and arsenicals and azoles",
        "code": "51452000"
      },
      { "name": "Amidantel", "code": "51452001" },
      { "name": "Arsenamide or thiacetarsamide", "code": "51452002" },
      { "name": "Bunamidine", "code": "51452003" },
      { "name": "Nitramisole", "code": "51452004" },
      { "name": "Bunamidine hydrochloride", "code": "51452005" },
      { "name": "Nitramisole hydrochloride", "code": "51452006" },
      { "name": "Thiacetarsamide sodium", "code": "51452007" },
      { "name": "Anthelmintic benzene derivatives", "code": "51452100" },
      { "name": "Hexylresorcinol", "code": "51452101" },
      { "name": "Nitroxinil or nitroxynil", "code": "51452102" },
      { "name": "Anthelmintic benzoates", "code": "51452200" },
      { "name": "Methylparaben", "code": "51452201" },
      { "name": "Benzyl benzoate", "code": "51452202" },
      { "name": "Ftalofyne or phthalofyne", "code": "51452203" },
      {
        "name": "Anthelmintic benzodioxoles and butyrophenones and copper compounds and cyclohexanes and naphthalenes",
        "code": "51452300"
      },
      { "name": "Pyrantel", "code": "51452301" },
      { "name": "Piperonyl", "code": "51452302" },
      { "name": "Desaspidin", "code": "51452303" },
      { "name": "Nitisinone", "code": "51452304" },
      { "name": "Copper napthanate or copper naphthenate", "code": "51452305" },
      { "name": "Pyrantel embonate", "code": "51452306" },
      { "name": "Pyrantel pamoate", "code": "51452307" },
      { "name": "Pyrantel tartrate", "code": "51452308" },
      { "name": "Anthelmintic carbamates", "code": "51452400" },
      { "name": "Mebendazole", "code": "51452401" },
      { "name": "Diethylcarbamazine", "code": "51452402" },
      { "name": "Flubendazole", "code": "51452403" },
      { "name": "Diphenan", "code": "51452404" },
      { "name": "Diethylcarbamazine citrate", "code": "51452405" },
      { "name": "Anthelmintic chlorinated hydrocarbons", "code": "51452500" },
      { "name": "Tetrachloroethylene", "code": "51452501" },
      { "name": "Lindane", "code": "51452502" },
      { "name": "Anthelmintic guanidines", "code": "51452600" },
      { "name": "Febantel", "code": "51452601" },
      { "name": "Netobimin", "code": "51452602" },
      { "name": "Febantel for veterinary use", "code": "51452603" },
      { "name": "Anthelmintic isoquinolines", "code": "51452700" },
      { "name": "Praziquantel", "code": "51452701" },
      { "name": "Epsiprantel", "code": "51452702" },
      { "name": "Anthelmintic macrolides", "code": "51452800" },
      { "name": "Ivermectin", "code": "51452801" },
      { "name": "Doramectin", "code": "51452802" },
      { "name": "Selamectin", "code": "51452803" },
      { "name": "Eprinomectin", "code": "51452804" },
      { "name": "Anthelmintic organophosphorus compounds", "code": "51452900" },
      { "name": "Malathion topical antiparasitic", "code": "51452901" },
      { "name": "Haloxon", "code": "51452902" },
      { "name": "Naftalofos", "code": "51452903" },
      { "name": "Uredofos", "code": "51452904" },
      { "name": "Butonate", "code": "51452905" },
      { "name": "Dichlorvos", "code": "51452906" },
      { "name": "Anthelmintic piperazines", "code": "51453000" },
      { "name": "Piperazine", "code": "51453001" },
      { "name": "Amocarzine", "code": "51453002" },
      { "name": "Pexantel", "code": "51453003" },
      { "name": "Piperazine calcium edetate", "code": "51453004" },
      { "name": "Piperazine citrate", "code": "51453005" },
      { "name": "Piperazine citrate anhydrous", "code": "51453006" },
      { "name": "Piperazine dihydrobromide", "code": "51453007" },
      { "name": "Piperazine dihydrochloride", "code": "51453008" },
      { "name": "Piperazine edetate calcium", "code": "51453009" },
      { "name": "Piperazine estrone sulfate", "code": "51453010" },
      { "name": "Piperazine hexahydrate", "code": "51453011" },
      { "name": "Piperazine hydrate", "code": "51453012" },
      { "name": "Piperazine hydrobromide", "code": "51453013" },
      { "name": "Piperazine hydrochloride", "code": "51453014" },
      { "name": "Piperazine phosphate", "code": "51453015" },
      { "name": "Piperazine phosphate anhydrous", "code": "51453016" },
      { "name": "Piperazine sebacate", "code": "51453017" },
      { "name": "Piperazine sulfate", "code": "51453018" },
      { "name": "Piperazine sulphate", "code": "51453019" },
      { "name": "Piperazine tartrate", "code": "51453020" },
      { "name": "Anthelmintic pyrimidines", "code": "51453100" },
      { "name": "Morantel", "code": "51453101" },
      { "name": "Oxantel", "code": "51453102" },
      { "name": "Morantel citrate", "code": "51453103" },
      { "name": "Morantel pamoate", "code": "51453104" },
      { "name": "Morantel tartrate", "code": "51453105" },
      { "name": "Oxantel embonate", "code": "51453106" },
      { "name": "Oxantel pamoate", "code": "51453107" },
      { "name": "Anthelmintic quinolines", "code": "51453200" },
      { "name": "Oxamniquine", "code": "51453201" },
      { "name": "Pyrvinium", "code": "51453202" },
      { "name": "Furodazole", "code": "51453203" },
      { "name": "Phthalylsulfacetamide", "code": "51453204" },
      { "name": "Pyrvinium chloride", "code": "51453205" },
      { "name": "Tilbroquinol", "code": "51453206" },
      { "name": "Anthelmintic sulfides", "code": "51453300" },
      { "name": "Dixanthogen", "code": "51453301" },
      { "name": "Anthelmintic terpenes", "code": "51453400" },
      { "name": "Permethrin topical antiparasitic", "code": "51453401" },
      { "name": "Niridazole", "code": "51453402" },
      { "name": "Santonin", "code": "51453403" },
      { "name": "Flumethrin", "code": "51453404" },
      { "name": "Decamethrin or deltamethrin", "code": "51453405" },
      { "name": "Anthelmintic thiazoles", "code": "51453500" },
      { "name": "Thiabendazole or tiabendazole", "code": "51453501" },
      { "name": "Nitazoxanide", "code": "51453502" },
      { "name": "Anthelmintic thiocyanates", "code": "51453600" },
      { "name": "Isobornyl thiocyanoacetate", "code": "51453601" },
      { "name": "Nitroscanate", "code": "51453602" },
      { "name": "Anthelmintic thioxanthenes", "code": "51453700" },
      { "name": "Hycanthone", "code": "51453701" },
      { "name": "Becanthone", "code": "51453702" },
      { "name": "Hycanthone mesilate or mesylate", "code": "51453703" },
      { "name": "Hycanthone methanesulfonate", "code": "51453704" },
      { "name": "Herbal drugs", "code": "51460000" },
      { "name": "Medicinal herbs", "code": "51461500" },
      { "name": "Centella asiatica extract", "code": "51461501" },
      { "name": "Ipecacuanha extract", "code": "51461502" },
      { "name": "Aesculus", "code": "51461503" },
      { "name": "Aconitum napelus", "code": "51461504" },
      { "name": "Valerian", "code": "51461505" },
      { "name": "Garlic", "code": "51461506" },
      { "name": "Chelidonium majus", "code": "51461507" },
      { "name": "Soapwort", "code": "51461508" },
      { "name": "Primula", "code": "51461509" },
      { "name": "Pimpinella", "code": "51461510" },
      { "name": "Coriander", "code": "51461511" },
      { "name": "Hedera helix", "code": "51461512" },
      { "name": "Gumplant", "code": "51461513" },
      { "name": "Boneset", "code": "51461514" },
      { "name": "Coccus cacti", "code": "51461515" },
      { "name": "Catnip", "code": "51461516" },
      { "name": "Ginseng", "code": "51461517" },
      { "name": "Asafetida", "code": "51461518" },
      { "name": "Bilberry", "code": "51461519" },
      { "name": "Arnica", "code": "51461520" },
      { "name": "Balsam peru", "code": "51461521" },
      { "name": "Eyebright", "code": "51461522" },
      { "name": "Ginkgo biloba", "code": "51461523" },
      { "name": "Hydrastis", "code": "51461524" },
      { "name": "Gotu kola", "code": "51461525" },
      { "name": "Saw palmetto", "code": "51461526" },
      { "name": "Pygeum africanum", "code": "51461527" },
      { "name": "Angelica root", "code": "51461528" },
      { "name": "Milk thistle", "code": "51461529" },
      { "name": "Black cohosh", "code": "51461530" },
      { "name": "Cinnamomum", "code": "51461531" },
      { "name": "Abies balsamea resin", "code": "51461532" },
      { "name": "Absinthium", "code": "51461533" },
      { "name": "Aralia", "code": "51461534" },
      { "name": "Commiphora myrrha resin", "code": "51461535" },
      { "name": "Eupatorium", "code": "51461536" },
      { "name": "Gelsemium", "code": "51461537" },
      { "name": "Kava", "code": "51461538" },
      { "name": "Passion flower extract", "code": "51461539" },
      { "name": "Ephedra", "code": "51461540" },
      { "name": "Cascara sagrada", "code": "51461541" },
      { "name": "Combination herbs/alternative therapies", "code": "51461600" },
      { "name": "Calcium/iodine", "code": "51461601" },
      { "name": "Chondroitin/glucosamine", "code": "51461602" },
      { "name": "Chondroitin/glucosamine/hyaluronate", "code": "51461603" },
      {
        "name": "Chondroitin/glucosamine/methylsulfonylmethane",
        "code": "51461604"
      },
      { "name": "Chromium/cinnamon", "code": "51461605" },
      { "name": "Antiseptics", "code": "51470000" },
      {
        "name": "Antiseptic alkenes and amidines and amines and ammonia-based compounds",
        "code": "51471500"
      },
      {
        "name": "Ammoniated mercury or mercuric amidochloride",
        "code": "51471501"
      },
      { "name": "Povidone", "code": "51471502" },
      { "name": "Dibromopropamidine or dibrompropamidine", "code": "51471503" },
      {
        "name": "Crystal violet or gentian violet or methylrosaniline",
        "code": "51471504"
      },
      { "name": "Povidone iodine", "code": "51471505" },
      { "name": "Antiseptic acetates", "code": "51471600" },
      { "name": "Laurolinium acetate", "code": "51471601" },
      { "name": "Acetic acid", "code": "51471602" },
      { "name": "Antiseptic acids", "code": "51471700" },
      { "name": "Mandelic acid", "code": "51471701" },
      { "name": "Octafonium or phenoctide", "code": "51471702" },
      { "name": "Boric acid", "code": "51471703" },
      { "name": "Octafonium chloride", "code": "51471704" },
      { "name": "Antiseptic acridines", "code": "51471800" },
      { "name": "Acriflavine or acriflavinium", "code": "51471801" },
      { "name": "Aminacrine or aminoacridine", "code": "51471802" },
      { "name": "Ethacridine lactate", "code": "51471803" },
      { "name": "Euflavine", "code": "51471804" },
      { "name": "Proflavine", "code": "51471805" },
      { "name": "Aminoacridine hydrochloride", "code": "51471806" },
      { "name": "Proflavine dihydrochloride", "code": "51471807" },
      { "name": "Proflavine hemisulfate or hemisulphate", "code": "51471808" },
      { "name": "Proflavine hydrochloride", "code": "51471809" },
      { "name": "Antiseptic alcohols", "code": "51471900" },
      { "name": "Isopropanol or isopropyl alcohol", "code": "51471901" },
      { "name": "Menthol", "code": "51471902" },
      { "name": "Dichlorobenzyl alcohol", "code": "51471903" },
      { "name": "Propanol", "code": "51471904" },
      { "name": "Antiseptic aldehydes", "code": "51472000" },
      { "name": "Glutaral", "code": "51472001" },
      { "name": "Glutaraldehyde", "code": "51472002" },
      { "name": "Antiseptic amides", "code": "51472100" },
      { "name": "Bensalan", "code": "51472101" },
      { "name": "Triclocarban", "code": "51472102" },
      {
        "name": "Chloramine-t or n-chloro tosylamide or tosylchloramide",
        "code": "51472103"
      },
      { "name": "Chlorazodin or chloroazodin", "code": "51472104" },
      { "name": "Tosylchloramide sodium", "code": "51472105" },
      {
        "name": "Antiseptic borates and chlorine-based compounds and heavy metals and herbal preparations",
        "code": "51472300"
      },
      { "name": "Camphora", "code": "51472301" },
      { "name": "Sodium perborate", "code": "51472302" },
      { "name": "Zinc chloride", "code": "51472303" },
      { "name": "Meralein sodium", "code": "51472304" },
      { "name": "Antiseptic benzoates", "code": "51472400" },
      { "name": "Halazone", "code": "51472401" },
      { "name": "Anthranilic acid", "code": "51472402" },
      { "name": "Antiseptic chlorobenzenes", "code": "51472500" },
      { "name": "Hexachlorophene", "code": "51472502" },
      { "name": "Antiseptic cresols", "code": "51472600" },
      { "name": "Amylmetacresol", "code": "51472601" },
      { "name": "Nitromersol", "code": "51472602" },
      { "name": "Antiseptic fluorine compounds", "code": "51472700" },
      { "name": "Olaflur", "code": "51472702" },
      { "name": "Antiseptic guanidines", "code": "51472800" },
      { "name": "Polihexanide", "code": "51472801" },
      { "name": "Chlorhexidine gluconate", "code": "51472802" },
      { "name": "Chlorhexidine", "code": "51472803" },
      { "name": "Chlorhexidine acetate", "code": "51472804" },
      { "name": "Chlorhexidine digluconate", "code": "51472805" },
      { "name": "Chlorhexidine dihydrochloride", "code": "51472806" },
      { "name": "Chlorhexidine hydrochloride", "code": "51472807" },
      { "name": "Chlorhexidine phosphanilate", "code": "51472808" },
      { "name": "Antiseptic halogens", "code": "51472900" },
      { "name": "Iodine", "code": "51472901" },
      { "name": "Iodoform", "code": "51472902" },
      {
        "name": "Antiseptic quaternary ammonium compounds",
        "code": "51473000"
      },
      { "name": "Benzalkonium chloride", "code": "51473001" },
      { "name": "Benzoxonium chloride", "code": "51473002" },
      { "name": "Cetrimonium", "code": "51473003" },
      { "name": "Benzethonium", "code": "51473004" },
      { "name": "Benzododecinium", "code": "51473005" },
      { "name": "Didecyldimethylammonium chloride", "code": "51473006" },
      { "name": "Domiphen", "code": "51473007" },
      { "name": "Lauralkonium", "code": "51473008" },
      { "name": "Pirralkonium", "code": "51473009" },
      { "name": "Disiquonium chloride", "code": "51473010" },
      { "name": "Tibezonium", "code": "51473011" },
      { "name": "Cetalkonium chloride", "code": "51473012" },
      { "name": "Oxyquinoline", "code": "51473013" },
      { "name": "Dodeclonium", "code": "51473014" },
      { "name": "Acetone or alcohol based antiseptics", "code": "51473016" },
      { "name": "Benzododecinium chloride", "code": "51473018" },
      { "name": "Cetrimonium bromide", "code": "51473020" },
      { "name": "Dodeclonium bromide", "code": "51473022" },
      { "name": "Lauralkonium chloride", "code": "51473024" },
      { "name": "Pirralkonium bromide", "code": "51473026" },
      { "name": "Tibezonium iodide", "code": "51473028" },
      {
        "name": "Antiseptic imidazoles and ketones and lipids and nitrofurans",
        "code": "51473100"
      },
      { "name": "Fludazonium", "code": "51473101" },
      { "name": "Benzoin", "code": "51473102" },
      { "name": "Isopropyl myristate", "code": "51473103" },
      { "name": "Nitrofurazone", "code": "51473104" },
      { "name": "Fludazonium chloride", "code": "51473105" },
      { "name": "Antiseptic organometallic compounds", "code": "51473200" },
      { "name": "Phenylmercuric nitrate", "code": "51473201" },
      { "name": "Thimerosal or thiomersal", "code": "51473202" },
      { "name": "Merbromin or mercurochrome", "code": "51473203" },
      { "name": "Antiseptic phenols", "code": "51473300" },
      { "name": "Bibrocathol", "code": "51473301" },
      { "name": "Chloroxylenol", "code": "51473302" },
      { "name": "Chlorothymol", "code": "51473303" },
      { "name": "Biclotymol", "code": "51473304" },
      { "name": "Bismuth tribromophenate", "code": "51473305" },
      { "name": "Resorcin or resorcinol", "code": "51473306" },
      { "name": "Phenol or phenolate sodium", "code": "51473307" },
      { "name": "Resorcinol acetate", "code": "51473308" },
      { "name": "Resorcinol monoacetate", "code": "51473309" },
      { "name": "Antiseptic pyridines", "code": "51473400" },
      { "name": "Cetylpyridinium", "code": "51473401" },
      { "name": "Octenidine", "code": "51473402" },
      { "name": "Octenidine dihydrochloride", "code": "51473403" },
      { "name": "Octenidine saccharin", "code": "51473404" },
      {
        "name": "Antiseptic pyrimidines and silvers and ureas and waters",
        "code": "51473500"
      },
      { "name": "Hexetidine", "code": "51473501" },
      { "name": "Silver nitrate", "code": "51473502" },
      { "name": "Carbamide peroxide or hydrogen peroxide", "code": "51473503" },
      { "name": "Sterile water for irrigation", "code": "51473504" },
      { "name": "Antiseptic quinolines", "code": "51473600" },
      { "name": "Benzoxiquine", "code": "51473601" },
      { "name": "Clioquinol", "code": "51473602" },
      { "name": "8-hydroxyquinoline", "code": "51473603" },
      { "name": "Antiseptic sulfur compounds", "code": "51473700" },
      { "name": "Noxythiolin or noxytiolin", "code": "51473701" },
      { "name": "Zinc phenolsulfonate", "code": "51473702" },
      { "name": "Ritiometan", "code": "51473703" },
      { "name": "Antiseptic terpenes", "code": "51473800" },
      { "name": "Carvacrol or cymophenol", "code": "51473801" },
      { "name": "Eucalyptol or eucalyptus oil", "code": "51473802" },
      {
        "name": "Combination soaps/shampoos/soap-free cleansers",
        "code": "51473900"
      },
      { "name": "Acetone/alcohol", "code": "51473901" },
      { "name": "Benzethonium/triclosan", "code": "51473902" },
      {
        "name": "Boric acid/lauramide dea/propylene glycol/tea-dodecylbenzenesulfonate",
        "code": "51473903"
      },
      {
        "name": "Butylparaben/cetyl alcohol/methylparaben/propylene glycol/propylparaben",
        "code": "51473904"
      },
      { "name": "Citric acid/sodium laureth sulfate", "code": "51473905" },
      {
        "name": "Edta/phosphoric acid/propylene glycol/sodium lauryl sulfate",
        "code": "51473906"
      },
      { "name": "Lauramide dea/sodium lauryl sulfate", "code": "51473907" },
      { "name": "Menthol/selenium", "code": "51473908" },
      { "name": "Salicylic acid/zinc pyrithione", "code": "51473909" },
      {
        "name": "Sodium lauryl sulfate/sorbic acid/standapol",
        "code": "51473910"
      },
      {
        "name": "Combination antiseptics, ceruminolytics, and disinfectants",
        "code": "51474000"
      },
      {
        "name": "Formaldehyde/isopropyl alcohol/sodium nitrite",
        "code": "51474001"
      },
      { "name": "Carbamide peroxide/glycerin", "code": "51474002" }
    ]
  },
  {
    "name": "Domestic Appliances and Supplies and Consumer Electronic Products",
    "code": "52000000",
    "subcategories": [
      { "name": "Floor coverings", "code": "52100000" },
      { "name": "Rugs and mats", "code": "52101500" },
      { "name": "Oriental rugs", "code": "52101501" },
      { "name": "Area rugs", "code": "52101502" },
      { "name": "Wool rugs", "code": "52101503" },
      { "name": "Cotton rugs", "code": "52101504" },
      { "name": "Synthetic rugs", "code": "52101505" },
      { "name": "Braided rugs", "code": "52101506" },
      { "name": "Bath mats", "code": "52101507" },
      { "name": "Door mats", "code": "52101508" },
      { "name": "Decorative mats", "code": "52101509" },
      { "name": "Anti fatigue mats", "code": "52101510" },
      { "name": "Rubber or vinyl mats", "code": "52101511" },
      { "name": "Chair mat", "code": "52101512" },
      { "name": "Carpet protector", "code": "52101513" },
      { "name": "Fiberglass mat", "code": "52101514" },
      { "name": "Floor cushion", "code": "52101515" },
      { "name": "Straw mat rug", "code": "52101516" },
      {
        "name": "Bedclothes and table and kitchen linen and towels",
        "code": "52120000"
      },
      { "name": "Bedclothes", "code": "52121500" },
      { "name": "Quilts", "code": "52121501" },
      { "name": "Comforters", "code": "52121502" },
      { "name": "Comforter covers", "code": "52121503" },
      { "name": "Mattress covers", "code": "52121504" },
      { "name": "Pillows", "code": "52121505" },
      { "name": "Mattress pads", "code": "52121506" },
      { "name": "Featherbeds", "code": "52121507" },
      { "name": "Blankets", "code": "52121508" },
      { "name": "Sheets", "code": "52121509" },
      { "name": "Dust ruffles", "code": "52121510" },
      { "name": "Duvets", "code": "52121511" },
      { "name": "Pillow cases", "code": "52121512" },
      { "name": "Bed spreads", "code": "52121513" },
      { "name": "Chair cover", "code": "52121514" },
      { "name": "Duvet cover", "code": "52121515" },
      { "name": "Table and kitchen linen and accessories", "code": "52121600" },
      { "name": "Dish towels", "code": "52121601" },
      { "name": "Napkins", "code": "52121602" },
      { "name": "Table runners", "code": "52121603" },
      { "name": "Table cloths", "code": "52121604" },
      { "name": "Domestic oven mits or pot holders", "code": "52121605" },
      { "name": "Place mats", "code": "52121606" },
      { "name": "Table skirts", "code": "52121607" },
      { "name": "Clips for table skirts", "code": "52121608" },
      { "name": "Towels", "code": "52121700" },
      { "name": "Bath towels", "code": "52121701" },
      { "name": "Beach towels", "code": "52121702" },
      { "name": "Wash cloths", "code": "52121703" },
      { "name": "Hand towels", "code": "52121704" },
      { "name": "Face towel", "code": "52121705" },
      { "name": "Window treatments", "code": "52130000" },
      { "name": "Curtains and draperies", "code": "52131500" },
      { "name": "Curtains", "code": "52131501" },
      { "name": "Draperies", "code": "52131503" },
      { "name": "Blinds and shades", "code": "52131600" },
      { "name": "Venetian blinds", "code": "52131601" },
      { "name": "Roll up shades", "code": "52131602" },
      { "name": "Interior shutters", "code": "52131603" },
      { "name": "Vertical blinds", "code": "52131604" },
      {
        "name": "Window treatment accessories and hardware",
        "code": "52131700"
      },
      { "name": "Valances", "code": "52131701" },
      { "name": "Curtain rods", "code": "52131702" },
      { "name": "Rod finials", "code": "52131703" },
      { "name": "Curtain rings or clips", "code": "52131704" },
      { "name": "Metallic reel for curtain", "code": "52131705" },
      { "name": "Domestic appliances", "code": "52140000" },
      { "name": "Domestic kitchen appliances", "code": "52141500" },
      { "name": "Domestic refrigerators", "code": "52141501" },
      { "name": "Domestic microwave ovens", "code": "52141502" },
      { "name": "Domestic garbage disposals", "code": "52141503" },
      { "name": "Domestic ranges", "code": "52141504" },
      { "name": "Domestic dish washers", "code": "52141505" },
      { "name": "Domestic freezers", "code": "52141506" },
      { "name": "Domestic upright freezers", "code": "52141507" },
      { "name": "Domestic chest freezers", "code": "52141508" },
      {
        "name": "Domestic combination refrigerator freezers",
        "code": "52141509"
      },
      { "name": "Domestic portable air conditioners", "code": "52141510" },
      { "name": "Domestic juicers", "code": "52141511" },
      { "name": "Domestic waffle irons", "code": "52141512" },
      { "name": "Domestic electric can openers", "code": "52141513" },
      { "name": "Domestic food processors", "code": "52141514" },
      { "name": "Domestic trash compactors", "code": "52141515" },
      { "name": "Domestic deep fryers", "code": "52141516" },
      { "name": "Domestic popcorn poppers", "code": "52141517" },
      { "name": "Domestic bread making machines", "code": "52141518" },
      { "name": "Domestic convectional ovens", "code": "52141519" },
      { "name": "Domestic mixers", "code": "52141520" },
      { "name": "Domestic toaster ovens", "code": "52141521" },
      { "name": "Domestic toasters", "code": "52141522" },
      { "name": "Domestic electrical kettles", "code": "52141523" },
      { "name": "Domestic blenders", "code": "52141524" },
      { "name": "Domestic hot plates", "code": "52141525" },
      { "name": "Domestic coffee makers", "code": "52141526" },
      { "name": "Domestic electric knives", "code": "52141527" },
      { "name": "Domestic electric woks", "code": "52141528" },
      { "name": "Domestic coffee grinders", "code": "52141529" },
      { "name": "Parts of dishwashing machines", "code": "52141530" },
      { "name": "Domestic food choppers", "code": "52141531" },
      { "name": "Domestic electric skillets", "code": "52141532" },
      { "name": "Domestic electric griddles", "code": "52141533" },
      { "name": "Domestic electric sandwich maker", "code": "52141534" },
      { "name": "Domestic indoor electric grills", "code": "52141535" },
      { "name": "Domestic pizelle or cookie maker", "code": "52141536" },
      { "name": "Domestic crock pot", "code": "52141537" },
      { "name": "Domestic use food warmers", "code": "52141538" },
      { "name": "Domestic tea makers", "code": "52141539" },
      { "name": "Vacuum Bag Sealer", "code": "52141540" },
      { "name": "Domestic baby bottle sterilizer", "code": "52141541" },
      { "name": "Domestic meat grinder", "code": "52141542" },
      { "name": "Domestic egg boiler", "code": "52141543" },
      { "name": "Domestic multi cooker", "code": "52141544" },
      { "name": "Domestic cooktop", "code": "52141545" },
      { "name": "Domestic kitchen hood", "code": "52141546" },
      {
        "name": "Domestic pepper and salt grinder or mill",
        "code": "52141547"
      },
      { "name": "Domestic pierrade", "code": "52141548" },
      { "name": "Domestic raclette", "code": "52141549" },
      { "name": "Domestic yogurt maker", "code": "52141550" },
      { "name": "Domestic steam oven", "code": "52141551" },
      { "name": "Domestic rotisserie oven", "code": "52141552" },
      { "name": "Domestic electric rice cooker", "code": "52141553" },
      { "name": "Domestic kimchi refrigerator", "code": "52141554" },
      { "name": "Domestic bread slicer", "code": "52141555" },
      { "name": "Domestic food dehydrator", "code": "52141556" },
      { "name": "Domestic ice cream maker", "code": "52141557" },
      { "name": "Domestic food slicer", "code": "52141558" },
      { "name": "Domestic soup maker", "code": "52141559" },
      { "name": "Domestic cake maker", "code": "52141560" },
      { "name": "Domestic tagine", "code": "52141561" },
      { "name": "Domestic chocolate fountain", "code": "52141562" },
      { "name": "Domestic panini press", "code": "52141563" },
      {
        "name": "Domestic laundry appliances and supplies",
        "code": "52141600"
      },
      { "name": "Domestic clothes washers", "code": "52141601" },
      { "name": "Domestic tumble dryers", "code": "52141602" },
      { "name": "Domestic clothing irons", "code": "52141603" },
      { "name": "Footwear dryers", "code": "52141604" },
      { "name": "Laundry hampers", "code": "52141605" },
      { "name": "Laundry baskets", "code": "52141606" },
      { "name": "Fabric wrinkle removing compounds", "code": "52141607" },
      { "name": "Garment steamer", "code": "52141608" },
      { "name": "Domestic stain removing device", "code": "52141609" },
      { "name": "Domestic built in ironing center", "code": "52141610" },
      { "name": "Washboard", "code": "52141611" },
      { "name": "Laundry hydroextractor", "code": "52141612" },
      { "name": "Domestic clothes boiler", "code": "52141613" },
      { "name": "Domestic bath appliances", "code": "52141700" },
      { "name": "Domestic electric toothbrushes", "code": "52141701" },
      { "name": "Domestic hair dryers", "code": "52141703" },
      { "name": "Domestic electric razors", "code": "52141704" },
      { "name": "Parts of shavers or hair removers", "code": "52141705" },
      { "name": "Nail dryers", "code": "52141706" },
      { "name": "Towel dryer", "code": "52141707" },
      { "name": "Hair or curling iron", "code": "52141708" },
      { "name": "Body dryer", "code": "52141709" },
      { "name": "Other domestic household appliances", "code": "52141800" },
      { "name": "Domestic sewing machines", "code": "52141801" },
      { "name": "Domestic space heaters", "code": "52141802" },
      { "name": "Domestic electric blankets", "code": "52141803" },
      { "name": "Domestic electric fan", "code": "52141804" },
      { "name": "Folding screen", "code": "52141805" },
      { "name": "Fish basin or tank", "code": "52141806" },
      { "name": "Folding fan", "code": "52141807" },
      {
        "name": "Domestic kitchenware and kitchen supplies",
        "code": "52150000"
      },
      { "name": "Domestic disposable kitchenware", "code": "52151500" },
      { "name": "Domestic disposable cookware", "code": "52151501" },
      { "name": "Domestic disposable dishes", "code": "52151502" },
      { "name": "Domestic disposable flatware", "code": "52151503" },
      {
        "name": "Domestic disposable cups or glasses or lids",
        "code": "52151504"
      },
      { "name": "Domestic disposable stirrers", "code": "52151505" },
      { "name": "Domestic disposable food containers", "code": "52151506" },
      { "name": "Domestic disposable drinking straws", "code": "52151507" },
      { "name": "Domestic kitchen tools and utensils", "code": "52151600" },
      { "name": "Domestic rolling pins", "code": "52151601" },
      { "name": "Domestic mixing bowls", "code": "52151602" },
      { "name": "Domestic graters", "code": "52151603" },
      { "name": "Domestic strainers or colanders", "code": "52151604" },
      { "name": "Domestic can or bottle openers", "code": "52151605" },
      { "name": "Domestic cutting boards", "code": "52151606" },
      { "name": "Domestic measuring cups", "code": "52151607" },
      { "name": "Basters or basting brushes", "code": "52151608" },
      { "name": "Vegetable peeler", "code": "52151609" },
      { "name": "Cookie cutters", "code": "52151610" },
      { "name": "Domestic kitchen tongs", "code": "52151611" },
      { "name": "Domestic kitchen wire whips", "code": "52151612" },
      { "name": "Domestic food scrapers", "code": "52151613" },
      { "name": "Domestic cooling racks", "code": "52151614" },
      { "name": "Domestic pizza cutters", "code": "52151615" },
      { "name": "Domestic kitchen spatulas", "code": "52151616" },
      { "name": "Domestic wooden spoons", "code": "52151617" },
      { "name": "Domestic wooden oven paddle", "code": "52151618" },
      { "name": "Domestic pastry blender", "code": "52151619" },
      { "name": "Domestic sifter", "code": "52151620" },
      { "name": "Domestic cookie stampers", "code": "52151621" },
      { "name": "Domestic pastry decorating gun", "code": "52151622" },
      { "name": "Domestic cookie press", "code": "52151623" },
      { "name": "Domestic knife sharpeners", "code": "52151624" },
      { "name": "Domestic biscuit cutters", "code": "52151625" },
      { "name": "Domestic mandolin", "code": "52151626" },
      { "name": "Domestic garlic press", "code": "52151627" },
      { "name": "Domestic egg slicer", "code": "52151628" },
      { "name": "Domestic egg separator", "code": "52151629" },
      { "name": "Domestic cheese slicer", "code": "52151630" },
      { "name": "Domestic food mill", "code": "52151631" },
      { "name": "Domestic kitchen funnels", "code": "52151632" },
      { "name": "Domestic garnishing tools", "code": "52151633" },
      { "name": "Domestic apple corer", "code": "52151634" },
      { "name": "Domestic melon or butter baller", "code": "52151635" },
      { "name": "Domestic food scoops", "code": "52151636" },
      { "name": "Domestic pumpkin carver", "code": "52151637" },
      { "name": "Domestic vegetable brush", "code": "52151638" },
      { "name": "Domestic egg beater", "code": "52151639" },
      { "name": "Domestic pasta drying rack", "code": "52151640" },
      { "name": "Domestic whipped cream maker", "code": "52151641" },
      { "name": "Domestic dough press", "code": "52151642" },
      { "name": "Domestic ravioli maker", "code": "52151643" },
      { "name": "Domestic mist or trigger sprayers", "code": "52151644" },
      { "name": "Domestic pastry brush", "code": "52151645" },
      { "name": "Domestic kitchen or diet scales", "code": "52151646" },
      { "name": "Domestic kitchen timers", "code": "52151647" },
      { "name": "Domestic kitchen or food thermometers", "code": "52151648" },
      { "name": "Domestic bread slicing guide", "code": "52151649" },
      { "name": "Domestic drain boards", "code": "52151650" },
      { "name": "Domestic measuring spoons", "code": "52151651" },
      { "name": "Lemon squeezers", "code": "52151652" },
      { "name": "Oyster knives", "code": "52151653" },
      { "name": "Potato mashers", "code": "52151654" },
      { "name": "Salad spinners", "code": "52151655" },
      { "name": "Avocado slicer", "code": "52151656" },
      { "name": "Culinary torch", "code": "52151657" },
      { "name": "Dough knife", "code": "52151658" },
      { "name": "Manual meat tenderizer", "code": "52151659" },
      { "name": "Spaghetti spoon", "code": "52151660" },
      { "name": "Bottle splash guard", "code": "52151661" },
      { "name": "Mesh dipper", "code": "52151662" },
      { "name": "Food grinding mortar", "code": "52151663" },
      { "name": "Domestic olive pit remover", "code": "52151664" },
      { "name": "Domestic skimmer", "code": "52151665" },
      { "name": "Domestic ladle", "code": "52151666" },
      { "name": "Domestic ice mould", "code": "52151667" },
      { "name": "Domestic kitchen tool set", "code": "52151668" },
      { "name": "Domestic flatware and cutlery", "code": "52151700" },
      { "name": "Domestic serving utensils", "code": "52151701" },
      { "name": "Domestic knives", "code": "52151702" },
      { "name": "Domestic forks", "code": "52151703" },
      { "name": "Domestic spoons", "code": "52151704" },
      { "name": "Spoon rests", "code": "52151705" },
      { "name": "Chopsticks", "code": "52151706" },
      { "name": "Domestic cutlery set", "code": "52151707" },
      { "name": "Butter spreaders", "code": "52151708" },
      { "name": "Flatware set", "code": "52151709" },
      { "name": "Domestic cookware", "code": "52151800" },
      { "name": "Domestic dutch ovens", "code": "52151801" },
      { "name": "Domestic frying pans", "code": "52151802" },
      { "name": "Domestic saucepans", "code": "52151803" },
      { "name": "Domestic kettles", "code": "52151804" },
      { "name": "Domestic woks", "code": "52151805" },
      { "name": "Domestic steamers", "code": "52151806" },
      { "name": "Domestic stock pots", "code": "52151807" },
      { "name": "Domestic pressure cookers", "code": "52151808" },
      { "name": "Domestic saute pans", "code": "52151809" },
      { "name": "Domestic chafing dishes", "code": "52151810" },
      { "name": "Domestic griddles", "code": "52151811" },
      { "name": "Domestic double boilers", "code": "52151812" },
      { "name": "Domestic splatter shield", "code": "52151813" },
      { "name": "Domestic casserole dish or pan", "code": "52151814" },
      { "name": "Domestic ramekin", "code": "52151815" },
      { "name": "Domestic bakeware", "code": "52151900" },
      { "name": "Domestic muffin pans", "code": "52151901" },
      { "name": "Domestic baking pans", "code": "52151902" },
      { "name": "Domestic cake or pie pans", "code": "52151903" },
      { "name": "Domestic roasting pans", "code": "52151904" },
      { "name": "Domestic baking sheets", "code": "52151905" },
      { "name": "Domestic broiling pans", "code": "52151906" },
      { "name": "Domestic baking molds", "code": "52151907" },
      { "name": "Domestic pizza pans", "code": "52151908" },
      { "name": "Domestic tortilla bakers", "code": "52151909" },
      {
        "name": "Domestic dishes and servingware and storage containers",
        "code": "52152000"
      },
      { "name": "Domestic pitchers", "code": "52152001" },
      { "name": "Domestic food storage containers", "code": "52152002" },
      { "name": "Domestic punch bowls", "code": "52152003" },
      { "name": "Domestic plates", "code": "52152004" },
      { "name": "Domestic saucers", "code": "52152005" },
      { "name": "Domestic trays or platters", "code": "52152006" },
      { "name": "Domestic serving bowls", "code": "52152007" },
      { "name": "Domestic tea or coffee pots", "code": "52152008" },
      { "name": "Domestic soup or salad bowls", "code": "52152009" },
      { "name": "Domestic vacuum flasks", "code": "52152010" },
      { "name": "Inners for vacuum flasks", "code": "52152011" },
      { "name": "Ice trays", "code": "52152012" },
      { "name": "Spice or salt or pepper shakers", "code": "52152013" },
      { "name": "Carafe sets", "code": "52152014" },
      { "name": "Domestic cake plate with dome", "code": "52152015" },
      { "name": "Domestic dinner set", "code": "52152016" },
      { "name": "Wooden rice chest", "code": "52152017" },
      { "name": "Lunch box", "code": "52152018" },
      { "name": "Domestic cutlery or utensil box", "code": "52152019" },
      { "name": "Domestic drink ware", "code": "52152100" },
      { "name": "Domestic coffee or tea cups", "code": "52152101" },
      { "name": "Domestic drinking glasses", "code": "52152102" },
      { "name": "Domestic mugs", "code": "52152103" },
      { "name": "Domestic stemware", "code": "52152104" },
      { "name": "Shot glass", "code": "52152105" },
      { "name": "Domestic mug tree", "code": "52152106" },
      {
        "name": "Dishwashing and dish storage accessories",
        "code": "52152200"
      },
      { "name": "Shelf liner", "code": "52152201" },
      { "name": "Dish drainer", "code": "52152202" },
      { "name": "Soap dispensing brush", "code": "52152203" },
      { "name": "Food preparation table", "code": "52152204" },
      { "name": "Domestic kitchen supplies", "code": "52152300" },
      { "name": "Domestic oven cleaner", "code": "52152301" },
      { "name": "Consumer electronics", "code": "52160000" },
      { "name": "Audio and visual equipment", "code": "52161500" },
      { "name": "Cassette players or recorders", "code": "52161502" },
      { "name": "Televisions", "code": "52161505" },
      { "name": "Clock radios", "code": "52161507" },
      { "name": "Laser disc players", "code": "52161508" },
      { "name": "Portable stereo systems", "code": "52161509" },
      { "name": "Home high fidelity audio system", "code": "52161510" },
      { "name": "Radios", "code": "52161511" },
      { "name": "Loudspeakers", "code": "52161512" },
      {
        "name": "Combination television VHS and DVD recorder",
        "code": "52161513"
      },
      { "name": "Headphones", "code": "52161514" },
      { "name": "Compact disk players or recorders", "code": "52161515" },
      { "name": "Digital video disk players or recorders", "code": "52161516" },
      { "name": "Equalizers", "code": "52161517" },
      { "name": "Global positioning system GPS receiver", "code": "52161518" },
      { "name": "Microphones", "code": "52161520" },
      { "name": "Multimedia receivers", "code": "52161521" },
      { "name": "Radio frequency scanners", "code": "52161522" },
      {
        "name": "Radio frequency transmitters or receivers",
        "code": "52161523"
      },
      { "name": "Radio receivers", "code": "52161524" },
      { "name": "Remote control", "code": "52161525" },
      { "name": "Satellite receivers", "code": "52161526" },
      { "name": "Subwoofers", "code": "52161527" },
      { "name": "Video cassette players or recorders", "code": "52161529" },
      { "name": "Radio phonographs", "code": "52161531" },
      { "name": "Karaoke systems", "code": "52161532" },
      { "name": "Megaphones", "code": "52161533" },
      { "name": "Integrated circut IC chip recorder", "code": "52161534" },
      { "name": "Digital voice recorders", "code": "52161535" },
      { "name": "Minidisc players or recorders", "code": "52161536" },
      { "name": "Magnetic storage media erasers", "code": "52161537" },
      { "name": "Video tape rewinders", "code": "52161538" },
      {
        "name": "Combination digital video disc DVD video cassette disc VCD compact disc CD player",
        "code": "52161539"
      },
      { "name": "Video switchers", "code": "52161540" },
      { "name": "Audio switchers", "code": "52161541" },
      { "name": "Plasma screens", "code": "52161542" },
      { "name": "MP3 players or recorders", "code": "52161543" },
      { "name": "Video or audio cassette erasers", "code": "52161544" },
      { "name": "Digital video recorders", "code": "52161545" },
      { "name": "Television tuners", "code": "52161546" },
      { "name": "Audio amplifier", "code": "52161547" },
      { "name": "Audio turntable", "code": "52161548" },
      { "name": "Digital picture frame", "code": "52161549" },
      { "name": "Personal video recorder PVR", "code": "52161550" },
      {
        "name": "Wireless microphone and instrument amplification system",
        "code": "52161551"
      },
      { "name": "Light receiver", "code": "52161552" },
      { "name": "Blu ray video disc player or recorder", "code": "52161553" },
      { "name": "Earphone", "code": "52161554" },
      { "name": "Portable video multimedia combined set", "code": "52161555" },
      { "name": "Video distributer", "code": "52161556" },
      { "name": "Fixed computer gaming console", "code": "52161557" },
      { "name": "Portable computer gaming console", "code": "52161558" },
      {
        "name": "Three dimensional 3D glasses for use with 3D televisions and 3D graphics equipped computers",
        "code": "52161559"
      },
      { "name": "Home cinema system", "code": "52161560" },
      { "name": "Mp4 player or recorder", "code": "52161561" },
      { "name": "Language translation device", "code": "52161562" },
      { "name": "Digital radio", "code": "52161563" },
      { "name": "Audio visual equipment accessories", "code": "52161600" },
      { "name": "Cassette storage", "code": "52161601" },
      { "name": "Audio or video head cleaners", "code": "52161602" },
      { "name": "Compact video cassette adapter", "code": "52161603" },
      { "name": "Headphone jack adapters", "code": "52161604" },
      { "name": "Portable media player accessories", "code": "52161605" },
      { "name": "Speaker enclosure", "code": "52161606" },
      { "name": "Vinyl record storage device", "code": "52161607" },
      { "name": "SCART splitter box", "code": "52161608" },
      { "name": "Audio turntable cartridge and pickup", "code": "52161609" },
      { "name": "Loud speaker stand", "code": "52161610" },
      { "name": "Microphone stand", "code": "52161611" },
      { "name": "Domestic wall treatments", "code": "52170000" },
      { "name": "Bathroom wall treatments", "code": "52171000" },
      { "name": "Hanging toiletry organizer or shelf", "code": "52171001" },
      { "name": "Bathroom or toilet mirror", "code": "52171002" }
    ]
  },
  {
    "name": "Apparel and Luggage and Personal Care Products",
    "code": "53000000",
    "subcategories": [
      { "name": "Clothing", "code": "53100000" },
      { "name": "Slacks and trousers and shorts", "code": "53101500" },
      { "name": "Boys slacks or trousers or shorts", "code": "53101501" },
      { "name": "Mens slacks or trousers or shorts", "code": "53101502" },
      { "name": "Girls slacks or trousers or shorts", "code": "53101503" },
      { "name": "Womens slacks or trousers or shorts", "code": "53101504" },
      { "name": "Infants slacks or trousers or shorts", "code": "53101505" },
      { "name": "Shirts and blouses", "code": "53101600" },
      { "name": "Boys shirts", "code": "53101601" },
      { "name": "Mens shirts", "code": "53101602" },
      { "name": "Girls shirts or blouses", "code": "53101603" },
      { "name": "Womens shirts or blouses", "code": "53101604" },
      { "name": "Infants shirts or blouses", "code": "53101605" },
      { "name": "Sweaters", "code": "53101700" },
      { "name": "Boys sweaters", "code": "53101701" },
      { "name": "Mens sweaters", "code": "53101702" },
      { "name": "Girls sweaters", "code": "53101703" },
      { "name": "Womens sweaters", "code": "53101704" },
      { "name": "Infants sweaters", "code": "53101705" },
      { "name": "Coats and jackets", "code": "53101800" },
      { "name": "Boys coats or jackets", "code": "53101801" },
      { "name": "Mens coats or jackets", "code": "53101802" },
      { "name": "Girls coats or jackets", "code": "53101803" },
      { "name": "Womens coats or jackets", "code": "53101804" },
      { "name": "Infants coats or jackets", "code": "53101805" },
      { "name": "Poncho", "code": "53101806" },
      { "name": "Suits", "code": "53101900" },
      { "name": "Boys suits", "code": "53101901" },
      { "name": "Mens suits", "code": "53101902" },
      { "name": "Girls suits", "code": "53101903" },
      { "name": "Womens suits", "code": "53101904" },
      { "name": "Infants suits", "code": "53101905" },
      {
        "name": "Dresses and skirts and saris and kimonos",
        "code": "53102000"
      },
      {
        "name": "Girls dresses or skirts or saris or kimonos",
        "code": "53102001"
      },
      {
        "name": "Womens dresses or skirts or saris or kimonos",
        "code": "53102002"
      },
      {
        "name": "Infants dresses or skirts or saris or kimonos",
        "code": "53102003"
      },
      { "name": "Overalls and coveralls", "code": "53102100" },
      { "name": "Boys overalls or coveralls", "code": "53102101" },
      { "name": "Mens overalls or coveralls", "code": "53102102" },
      { "name": "Girls overalls or coveralls", "code": "53102103" },
      { "name": "Womens overalls or coveralls", "code": "53102104" },
      { "name": "Infants overalls or coveralls", "code": "53102105" },
      { "name": "Folkloric clothing", "code": "53102200" },
      { "name": "Boys folkloric clothing", "code": "53102201" },
      { "name": "Mens folkloric clothing", "code": "53102202" },
      { "name": "Girls folkloric clothing", "code": "53102203" },
      { "name": "Womens folkloric clothing", "code": "53102204" },
      { "name": "Infants folkloric clothing", "code": "53102205" },
      { "name": "Undergarments", "code": "53102300" },
      { "name": "Undershirts", "code": "53102301" },
      { "name": "Slips", "code": "53102302" },
      { "name": "Underpants", "code": "53102303" },
      { "name": "Brassieres", "code": "53102304" },
      { "name": "Infant diapers", "code": "53102305" },
      { "name": "Adult diapers", "code": "53102306" },
      { "name": "Body shaping garments", "code": "53102307" },
      { "name": "Diaper liners", "code": "53102308" },
      { "name": "Breast form", "code": "53102309" },
      { "name": "Breast form cover", "code": "53102310" },
      { "name": "Hosiery", "code": "53102400" },
      { "name": "Stockings", "code": "53102401" },
      { "name": "Socks", "code": "53102402" },
      { "name": "Panty hose", "code": "53102403" },
      { "name": "Tights", "code": "53102404" },
      { "name": "Clothing accessories", "code": "53102500" },
      { "name": "Belts or suspenders", "code": "53102501" },
      { "name": "Ties or scarves or mufflers", "code": "53102502" },
      { "name": "Hats", "code": "53102503" },
      { "name": "Gloves or mittens", "code": "53102504" },
      { "name": "Umbrellas", "code": "53102505" },
      { "name": "Sweat bands", "code": "53102506" },
      { "name": "Clothing hangers", "code": "53102507" },
      { "name": "Armbands", "code": "53102508" },
      { "name": "Garters", "code": "53102509" },
      { "name": "Tassles", "code": "53102510" },
      { "name": "Bandannas", "code": "53102511" },
      { "name": "Handkerchiefs", "code": "53102512" },
      { "name": "Headbands", "code": "53102513" },
      { "name": "Pocket protectors", "code": "53102514" },
      { "name": "Button covers", "code": "53102515" },
      { "name": "Caps", "code": "53102516" },
      { "name": "Tie holders", "code": "53102517" },
      { "name": "Chevrons", "code": "53102518" },
      { "name": "Gorgets", "code": "53102519" },
      { "name": "Shoulder boards or epaulettes", "code": "53102520" },
      { "name": "Bib", "code": "53102521" },
      { "name": "Nightwear", "code": "53102600" },
      { "name": "Boys pajamas or nightshirts or robes", "code": "53102601" },
      { "name": "Mens pajamas or nightshirts or robes", "code": "53102602" },
      { "name": "Girls pajamas or nightshirts or robes", "code": "53102603" },
      { "name": "Womens pajamas or nightshirts or robes", "code": "53102604" },
      { "name": "Infants pajamas or nightshirts or robes", "code": "53102605" },
      { "name": "Bath robes", "code": "53102606" },
      { "name": "Uniforms", "code": "53102700" },
      { "name": "Military uniforms", "code": "53102701" },
      { "name": "Customs uniforms", "code": "53102702" },
      { "name": "Police uniforms", "code": "53102703" },
      {
        "name": "Institutional food preparation or service attire",
        "code": "53102704"
      },
      { "name": "School uniforms", "code": "53102705" },
      { "name": "Security uniforms", "code": "53102706" },
      { "name": "Doctors coat", "code": "53102707" },
      { "name": "Nurses uniforms", "code": "53102708" },
      { "name": "Ambulance officers uniforms", "code": "53102709" },
      { "name": "Corporate uniforms", "code": "53102710" },
      { "name": "Salon smocks", "code": "53102711" },
      { "name": "Paramedic uniforms", "code": "53102712" },
      { "name": "Ushers uniforms", "code": "53102713" },
      { "name": "Judicial robe", "code": "53102714" },
      { "name": "Prison officer uniform", "code": "53102715" },
      { "name": "Prisoner uniform", "code": "53102716" },
      { "name": "Sport uniform", "code": "53102717" },
      { "name": "Swimwear", "code": "53102800" },
      { "name": "Mens swimwear", "code": "53102801" },
      { "name": "Womens swimwear", "code": "53102802" },
      { "name": "Boys swimwear", "code": "53102803" },
      { "name": "Girls swimwear", "code": "53102804" },
      { "name": "Infants swimwear", "code": "53102805" },
      { "name": "Athletic wear", "code": "53102900" },
      { "name": "Womens athletic wear", "code": "53102901" },
      { "name": "Mens athletic wear", "code": "53102902" },
      { "name": "Boys athletic wear", "code": "53102903" },
      { "name": "Girls athletic wear", "code": "53102904" },
      { "name": "Tshirts", "code": "53103000" },
      { "name": "Mens tshirts", "code": "53103001" },
      { "name": "Tshirt for expectant mothers", "code": "53103002" },
      { "name": "Ladies tshirt", "code": "53103003" },
      { "name": "Waistcoats", "code": "53103100" },
      { "name": "Mens waistcoats", "code": "53103101" },
      { "name": "Ladies vest", "code": "53103102" },
      { "name": "Disposable clothes", "code": "53103200" },
      { "name": "Disposable work coat", "code": "53103201" },
      { "name": "Footwear", "code": "53110000" },
      { "name": "Boots", "code": "53111500" },
      { "name": "Mens boots", "code": "53111501" },
      { "name": "Womens boots", "code": "53111502" },
      { "name": "Boys boots", "code": "53111503" },
      { "name": "Girls boots", "code": "53111504" },
      { "name": "Infants boots", "code": "53111505" },
      { "name": "Shoes", "code": "53111600" },
      { "name": "Mens shoes", "code": "53111601" },
      { "name": "Womens shoes", "code": "53111602" },
      { "name": "Boys shoes", "code": "53111603" },
      { "name": "Girls shoes", "code": "53111604" },
      { "name": "Infants shoes", "code": "53111605" },
      { "name": "Slippers", "code": "53111700" },
      { "name": "Mens slippers", "code": "53111701" },
      { "name": "Womens slippers", "code": "53111702" },
      { "name": "Boys slippers", "code": "53111703" },
      { "name": "Girls slippers", "code": "53111704" },
      { "name": "Infants slippers", "code": "53111705" },
      { "name": "Sandals", "code": "53111800" },
      { "name": "Mens sandals", "code": "53111801" },
      { "name": "Womens sandals", "code": "53111802" },
      { "name": "Boys sandals", "code": "53111803" },
      { "name": "Girls sandals", "code": "53111804" },
      { "name": "Infants sandals", "code": "53111805" },
      { "name": "Athletic footwear", "code": "53111900" },
      { "name": "Mens athletic footwear", "code": "53111901" },
      { "name": "Womens athletic footwear", "code": "53111902" },
      { "name": "Boys athletic footwear", "code": "53111903" },
      { "name": "Girls athletic footwear", "code": "53111904" },
      { "name": "Infants athletic footwear", "code": "53111905" },
      { "name": "Shoe accessories", "code": "53112000" },
      { "name": "Shoehorns", "code": "53112001" },
      { "name": "Shoelaces", "code": "53112002" },
      { "name": "Heel pads", "code": "53112003" },
      { "name": "Shoe stretcher", "code": "53112004" },
      { "name": "Foot measuring device", "code": "53112005" },
      { "name": "Shoe heelpiece machine", "code": "53112006" },
      { "name": "Overshoes", "code": "53112100" },
      { "name": "Mens overshoes", "code": "53112101" },
      { "name": "Womens overshoes", "code": "53112102" },
      { "name": "Boys overshoes", "code": "53112103" },
      { "name": "Girls overshoes", "code": "53112104" },
      { "name": "Infants overshoes", "code": "53112105" },
      {
        "name": "Luggage and handbags and packs and cases",
        "code": "53120000"
      },
      { "name": "Luggage", "code": "53121500" },
      { "name": "Garment bags", "code": "53121501" },
      { "name": "Luggage sets", "code": "53121502" },
      { "name": "Individual luggage pieces", "code": "53121503" },
      { "name": "Purses and handbags and bags", "code": "53121600" },
      { "name": "Handbags or purses", "code": "53121601" },
      { "name": "Duffel bags", "code": "53121602" },
      { "name": "Backpacks", "code": "53121603" },
      { "name": "Coin purses", "code": "53121605" },
      { "name": "Lipstick cases", "code": "53121606" },
      { "name": "Cigar case", "code": "53121607" },
      { "name": "Shopping bags", "code": "53121608" },
      { "name": "Business cases", "code": "53121700" },
      { "name": "Briefcases", "code": "53121701" },
      { "name": "Attaches", "code": "53121702" },
      { "name": "Portfolios", "code": "53121704" },
      { "name": "Equipment cases", "code": "53121705" },
      { "name": "Computer bags", "code": "53121706" },
      { "name": "Travel kits and accessories", "code": "53121800" },
      { "name": "Travel kits", "code": "53121801" },
      { "name": "Travel carts", "code": "53121802" },
      { "name": "Garment brushes", "code": "53121803" },
      { "name": "Makeup or manicure cases", "code": "53121804" },
      { "name": "Personal care products", "code": "53130000" },
      { "name": "Dental", "code": "53131500" },
      { "name": "Mouthwash", "code": "53131501" },
      { "name": "Toothpaste", "code": "53131502" },
      { "name": "Toothbrushes", "code": "53131503" },
      { "name": "Dental floss", "code": "53131504" },
      { "name": "Infant soother or pacifier or dummy", "code": "53131505" },
      { "name": "Dental kits", "code": "53131506" },
      { "name": "Toothpicks", "code": "53131507" },
      { "name": "Denture cleaning tablets", "code": "53131508" },
      { "name": "Mouth fresheners", "code": "53131509" },
      { "name": "Denture adhesive", "code": "53131510" },
      { "name": "Bath and body", "code": "53131600" },
      { "name": "Shower caps", "code": "53131601" },
      { "name": "Hair care supplies", "code": "53131602" },
      { "name": "Razors", "code": "53131603" },
      { "name": "Hair combs or brushes", "code": "53131604" },
      { "name": "Vanity kits", "code": "53131605" },
      { "name": "Deodorants", "code": "53131606" },
      { "name": "Hand or body lotion or oil", "code": "53131607" },
      { "name": "Soaps", "code": "53131608" },
      { "name": "Sun protection products", "code": "53131609" },
      { "name": "Eye care supplies", "code": "53131610" },
      { "name": "Shaving creams", "code": "53131611" },
      { "name": "Bath gels", "code": "53131612" },
      { "name": "Facial care products", "code": "53131613" },
      { "name": "Foot care products", "code": "53131614" },
      { "name": "Feminine hygiene products", "code": "53131615" },
      { "name": "Para pharmaceutical creams or lotions", "code": "53131616" },
      { "name": "Manicure implements", "code": "53131617" },
      { "name": "Pedicure implements", "code": "53131618" },
      { "name": "Cosmetics", "code": "53131619" },
      { "name": "Perfumes or colognes or fragrances", "code": "53131620" },
      { "name": "Nail clippers", "code": "53131621" },
      { "name": "Condoms", "code": "53131622" },
      { "name": "Hair removal or depilatory products", "code": "53131623" },
      { "name": "Disposable personal wipes", "code": "53131624" },
      { "name": "Hair or beard nets", "code": "53131625" },
      { "name": "Hand sanitizer", "code": "53131626" },
      { "name": "Hand cleaner", "code": "53131627" },
      { "name": "Shampoos", "code": "53131628" },
      { "name": "Makeup kits", "code": "53131629" },
      { "name": "Lip balm", "code": "53131630" },
      { "name": "Tattoos", "code": "53131631" },
      { "name": "Hot rollers", "code": "53131632" },
      { "name": "Barrettes", "code": "53131633" },
      { "name": "Chemical protection products", "code": "53131634" },
      { "name": "Shaving brushes", "code": "53131635" },
      { "name": "Rose water", "code": "53131636" },
      { "name": "Nursing pads", "code": "53131637" },
      { "name": "Nail polish", "code": "53131638" },
      { "name": "Urinary incontinence pad", "code": "53131639" },
      { "name": "Nail polish remover", "code": "53131640" },
      { "name": "Petroleum jelly", "code": "53131641" },
      {
        "name": "Barber and salon hair cutting gown or cape",
        "code": "53131642"
      },
      { "name": "Electric hair clipper", "code": "53131643" },
      { "name": "Aromatherapy essential oil", "code": "53131644" },
      { "name": "Ear piercing instrument or gun", "code": "53131645" },
      { "name": "Blackhead remover", "code": "53131646" },
      { "name": "Infant hygiene and personal care set", "code": "53131647" },
      { "name": "Insect repellant", "code": "53131648" },
      { "name": "Bath and body powder", "code": "53131649" },
      { "name": "Facial sauna", "code": "53131650" },
      { "name": "Personal hygiene kit", "code": "53131651" },
      { "name": "Therapeutic massage equipment", "code": "53131700" },
      { "name": "Hand held massaging unit", "code": "53131701" },
      { "name": "Massage machine", "code": "53131702" },
      {
        "name": "Tattoo equipment and accessories and materials",
        "code": "53131800"
      },
      { "name": "Tattoo ink", "code": "53131801" },
      { "name": "Tattoo needle", "code": "53131802" },
      { "name": "Sewing supplies and accessories", "code": "53140000" },
      { "name": "Sewing fasteners", "code": "53141500" },
      { "name": "Straight pins", "code": "53141501" },
      { "name": "Safety pins", "code": "53141502" },
      { "name": "Zippers", "code": "53141503" },
      { "name": "Buckles", "code": "53141504" },
      { "name": "Buttons", "code": "53141505" },
      { "name": "Snaps", "code": "53141506" },
      { "name": "Clasps", "code": "53141507" },
      { "name": "Garment rivet", "code": "53141508" },
      { "name": "Miscellaneous sewing supplies", "code": "53141600" },
      { "name": "Pin cushions", "code": "53141601" },
      { "name": "Sewing kits", "code": "53141602" },
      { "name": "Thimbles", "code": "53141603" },
      { "name": "Sewing patterns", "code": "53141604" },
      { "name": "Sewing needles", "code": "53141605" },
      { "name": "Bobbins or bobbin holders", "code": "53141606" },
      { "name": "Seam gauge", "code": "53141607" },
      { "name": "Bodkin", "code": "53141608" },
      { "name": "Loop turner", "code": "53141609" },
      { "name": "Fabric or tailors chalk holders", "code": "53141610" },
      {
        "name": "Fabric markers or fabric pencils or fabric chalk",
        "code": "53141611"
      },
      { "name": "Serrated pattern tracing wheel", "code": "53141612" },
      { "name": "Tapestry needles", "code": "53141614" },
      { "name": "Weaving needles", "code": "53141615" },
      { "name": "Looper looms", "code": "53141616" },
      { "name": "Cotton jersey loops", "code": "53141617" },
      { "name": "Needlepoint canvas", "code": "53141618" },
      { "name": "Magnet wands", "code": "53141619" },
      { "name": "Seam ripper", "code": "53141620" },
      { "name": "Needle threader", "code": "53141621" },
      { "name": "Dressmakers ruler", "code": "53141622" },
      { "name": "Liquid thread lock or reinforcer", "code": "53141623" },
      { "name": "Cross stitch fabric or needles", "code": "53141624" },
      { "name": "Cross stitch designs", "code": "53141625" },
      { "name": "Embroidery hoops", "code": "53141626" },
      { "name": "Crochet hooks", "code": "53141627" },
      { "name": "Quilters basting tools", "code": "53141628" },
      { "name": "Quilters pins", "code": "53141629" },
      { "name": "Pattern cutting mats or boards", "code": "53141630" },
      { "name": "Pattern notcher", "code": "53141631" }
    ]
  },
  {
    "name": "Timepieces and Jewelry and Gemstone Products",
    "code": "54000000",
    "subcategories": [
      { "name": "Jewelry", "code": "54100000" },
      { "name": "Fine jewelry", "code": "54101500" },
      { "name": "Gold or silver or platinum chains", "code": "54101501" },
      { "name": "Fine jewelry necklaces", "code": "54101502" },
      { "name": "Fine jewelry rings", "code": "54101503" },
      { "name": "Fine jewelry earrings", "code": "54101504" },
      { "name": "Fine body jewelry", "code": "54101505" },
      { "name": "Fine jewelry bracelets", "code": "54101506" },
      { "name": "Tiaras", "code": "54101507" },
      { "name": "Fine jewelry ring guards", "code": "54101508" },
      { "name": "Fine jewelry cuff links", "code": "54101509" },
      { "name": "Fine jewelry earring clasps", "code": "54101510" },
      { "name": "Fine jewelry pendants", "code": "54101511" },
      { "name": "Fine jewelry pendant bales", "code": "54101512" },
      { "name": "Tiepin", "code": "54101513" },
      { "name": "Fine jewelry set", "code": "54101514" },
      { "name": "Imitation jewelry", "code": "54101600" },
      { "name": "Bracelets", "code": "54101601" },
      { "name": "Necklaces", "code": "54101602" },
      { "name": "Rings", "code": "54101603" },
      { "name": "Earrings", "code": "54101604" },
      { "name": "Body jewelry", "code": "54101605" },
      { "name": "Jewelry making tools and supplies", "code": "54101700" },
      { "name": "Pickling compounds", "code": "54101701" },
      { "name": "Dapping punches", "code": "54101702" },
      { "name": "Wire mills", "code": "54101703" },
      { "name": "Jewelry mandrels", "code": "54101704" },
      { "name": "Ring sizers", "code": "54101705" },
      {
        "name": "Low temperature malleable friendly plastic materials or accessories",
        "code": "54101706"
      },
      { "name": "Timepieces", "code": "54110000" },
      { "name": "Watches", "code": "54111500" },
      { "name": "Wrist watches", "code": "54111501" },
      { "name": "Pocket watches", "code": "54111502" },
      { "name": "Stop watch", "code": "54111503" },
      { "name": "Marine chronometer", "code": "54111504" },
      { "name": "Diver watch", "code": "54111505" },
      { "name": "Clocks", "code": "54111600" },
      { "name": "Wall clocks", "code": "54111601" },
      { "name": "Mantel or table clocks", "code": "54111602" },
      { "name": "Free standing clocks", "code": "54111603" },
      { "name": "Hourglasses", "code": "54111604" },
      { "name": "Electronic master clock", "code": "54111605" },
      { "name": "Electronic slave clock", "code": "54111606" },
      { "name": "Watch or clock parts or accessories", "code": "54111700" },
      { "name": "Watch or clock dials", "code": "54111701" },
      { "name": "Watch or clock crystals", "code": "54111702" },
      { "name": "Watch or clock plates or bridges", "code": "54111703" },
      {
        "name": "Watch straps or bands or bracelets or fobs",
        "code": "54111704"
      },
      { "name": "Watch or clock cases", "code": "54111705" },
      { "name": "Watch or clock holders", "code": "54111706" },
      { "name": "Watch or clock case openers", "code": "54111707" },
      { "name": "Watch or clock pendulum", "code": "54111708" },
      { "name": "Watch or clock repair kits", "code": "54111709" },
      { "name": "Watch or clock movement", "code": "54111710" },
      { "name": "Gemstones", "code": "54120000" },
      { "name": "Precious stones", "code": "54121500" },
      { "name": "Diamond gemstones", "code": "54121501" },
      { "name": "Emerald gemstones", "code": "54121502" },
      { "name": "Ruby gemstones", "code": "54121503" },
      { "name": "Sapphire gemstones", "code": "54121504" },
      { "name": "Semi precious stones", "code": "54121600" },
      { "name": "Garnet gemstones", "code": "54121601" },
      { "name": "Jade gemstones", "code": "54121602" },
      { "name": "Opal gemstones", "code": "54121603" },
      { "name": "Pearls", "code": "54121700" },
      { "name": "Cultured pearls", "code": "54121701" },
      { "name": "Natural pearls", "code": "54121702" },
      {
        "name": "Industrial precious and semi precious stones",
        "code": "54121800"
      },
      { "name": "Industrial diamonds", "code": "54121801" },
      { "name": "Industrial garnets", "code": "54121802" }
    ]
  },
  {
    "name": "Published Products",
    "code": "55000000",
    "subcategories": [
      { "name": "Printed media", "code": "55100000" },
      { "name": "Printed publications", "code": "55101500" },
      { "name": "Charts or maps or atlases", "code": "55101501" },
      { "name": "Directories", "code": "55101502" },
      { "name": "Catalogs", "code": "55101503" },
      { "name": "Newspapers", "code": "55101504" },
      { "name": "Comic books", "code": "55101505" },
      { "name": "Magazines", "code": "55101506" },
      {
        "name": "Picture or drawing or coloring books for children",
        "code": "55101507"
      },
      { "name": "Educational or vocational textbooks", "code": "55101509" },
      { "name": "Leisure reading books", "code": "55101510" },
      { "name": "Trading cards", "code": "55101513" },
      { "name": "Sheet music", "code": "55101514" },
      { "name": "Promotional material or annual reports", "code": "55101515" },
      { "name": "Operation or instruction manuals", "code": "55101516" },
      { "name": "Clearance or dimensional drawings", "code": "55101517" },
      { "name": "Technical diagrams or drawings", "code": "55101518" },
      { "name": "Periodicals", "code": "55101519" },
      { "name": "Instruction sheets or booklets", "code": "55101520" },
      { "name": "Owner or user manuals", "code": "55101521" },
      { "name": "Terrestrial or celestial globes", "code": "55101522" },
      { "name": "Exercise books", "code": "55101523" },
      { "name": "Reference books", "code": "55101524" },
      { "name": "Encyclopedias", "code": "55101525" },
      { "name": "Dictionaries", "code": "55101526" },
      { "name": "Song Books", "code": "55101527" },
      { "name": "Religious books", "code": "55101528" },
      { "name": "Bankbook or passbook", "code": "55101529" },
      { "name": "Code book", "code": "55101530" },
      { "name": "Law book", "code": "55101531" },
      { "name": "Graduation album or yearbook", "code": "55101532" },
      { "name": "Electronic reference material", "code": "55110000" },
      { "name": "Electronic publications and music", "code": "55111500" },
      { "name": "Electronic directories", "code": "55111501" },
      { "name": "Electronic dictionaries", "code": "55111502" },
      { "name": "Electronic encyclopedias", "code": "55111503" },
      { "name": "Electronic catalogs", "code": "55111504" },
      { "name": "Books on tape or compact disc", "code": "55111505" },
      { "name": "Electronic magazines", "code": "55111506" },
      { "name": "Electronic newspapers", "code": "55111507" },
      { "name": "Electronic charts or maps or atlases", "code": "55111508" },
      { "name": "Background music", "code": "55111509" },
      { "name": "Motion pictures on celluloid", "code": "55111510" },
      { "name": "Motion pictures on video tape", "code": "55111511" },
      { "name": "Music on tape or compact disc", "code": "55111512" },
      {
        "name": "Electronic educational or vocational texts",
        "code": "55111513"
      },
      {
        "name": "Motion pictures on digital video disk DVD",
        "code": "55111514"
      },
      { "name": "Educational material audio tape", "code": "55111515" },
      {
        "name": "Internet based television or motion picture program",
        "code": "55111516"
      },
      { "name": "Electronic software reference material", "code": "55111600" },
      {
        "name": "Electronic software documentation or user manuals",
        "code": "55111601"
      },
      { "name": "Signage and accessories", "code": "55120000" },
      { "name": "Tags", "code": "55121500" },
      { "name": "Luggage tags", "code": "55121501" },
      { "name": "Security tags", "code": "55121502" },
      { "name": "Identification tags", "code": "55121503" },
      { "name": "Key tags", "code": "55121504" },
      { "name": "Tag holders or accessories", "code": "55121505" },
      { "name": "Price tags", "code": "55121506" },
      { "name": "Tea or coffee bag tag", "code": "55121507" },
      { "name": "Ladder tag", "code": "55121508" },
      { "name": "Scaffolding tag", "code": "55121509" },
      { "name": "Inspection tag", "code": "55121510" },
      { "name": "Safety tag", "code": "55121511" },
      { "name": "Valve tag", "code": "55121512" },
      { "name": "Lockout tag", "code": "55121513" },
      { "name": "Labels", "code": "55121600" },
      { "name": "Label removing kits", "code": "55121601" },
      { "name": "Clothing labels", "code": "55121602" },
      { "name": "Canning or bottling labels", "code": "55121604" },
      { "name": "Addressing or mailing labels", "code": "55121605" },
      { "name": "Self adhesive labels", "code": "55121606" },
      { "name": "Decals", "code": "55121607" },
      { "name": "Bar code labels", "code": "55121608" },
      { "name": "Packaging labels", "code": "55121609" },
      { "name": "Consecutively numbered labels", "code": "55121610" },
      { "name": "Label making tapes", "code": "55121611" },
      { "name": "Printer labels", "code": "55121612" },
      { "name": "Color coding labels", "code": "55121613" },
      { "name": "Removable labels", "code": "55121614" },
      { "name": "Adhesive dots or arrows", "code": "55121615" },
      { "name": "Self adhesive flags", "code": "55121616" },
      { "name": "Label protectors", "code": "55121617" },
      { "name": "Label holders", "code": "55121618" },
      { "name": "Non adhesive labels", "code": "55121619" },
      { "name": "Multipurpose labels", "code": "55121620" },
      { "name": "Notary seals", "code": "55121621" },
      { "name": "Cryogenic label", "code": "55121622" },
      { "name": "Overlaminate label", "code": "55121623" },
      { "name": "Tamper indicating label", "code": "55121624" },
      { "name": "Signage", "code": "55121700" },
      { "name": "Metallic nameplates", "code": "55121701" },
      { "name": "Non metallic nameplates", "code": "55121702" },
      { "name": "Illuminated signs", "code": "55121703" },
      { "name": "Safety signs", "code": "55121704" },
      { "name": "Self adhesive signs", "code": "55121705" },
      { "name": "Banners", "code": "55121706" },
      { "name": "Magnetic signs", "code": "55121707" },
      { "name": "Neon signs", "code": "55121708" },
      { "name": "Traffic signs", "code": "55121710" },
      { "name": "Directional signs", "code": "55121712" },
      { "name": "Point of purchase signs", "code": "55121713" },
      { "name": "Pennants", "code": "55121714" },
      { "name": "Flags or accessories", "code": "55121715" },
      { "name": "Wooden signs", "code": "55121716" },
      { "name": "Marker plates", "code": "55121717" },
      { "name": "Informational signs", "code": "55121718" },
      { "name": "Signalling components", "code": "55121719" },
      { "name": "Emblems", "code": "55121720" },
      { "name": "Signage characters", "code": "55121721" },
      { "name": "Signage kits", "code": "55121725" },
      { "name": "Identification panels", "code": "55121726" },
      { "name": "Signage covers", "code": "55121728" },
      { "name": "Tokens", "code": "55121729" },
      { "name": "Casualty signs", "code": "55121730" },
      { "name": "Identification markers", "code": "55121731" },
      { "name": "Size dividers", "code": "55121732" },
      { "name": "Sign blank", "code": "55121733" },
      { "name": "Sign sheeting material", "code": "55121734" },
      { "name": "Temporary sign", "code": "55121735" },
      { "name": "Workplace safety sign or poster", "code": "55121736" },
      { "name": "Pipe identification marker", "code": "55121737" },
      { "name": "Identification documents", "code": "55121800" },
      { "name": "Car tax discs", "code": "55121801" },
      {
        "name": "Identification cards or bands or similar products",
        "code": "55121802"
      },
      { "name": "Passports", "code": "55121803" },
      { "name": "Badges or badge holders", "code": "55121804" },
      {
        "name": "Personnel identification band kits or accessories",
        "code": "55121806"
      },
      {
        "name": "Identification product holders or accessories",
        "code": "55121807"
      },
      { "name": "Identification badge clip", "code": "55121808" },
      { "name": "Signage equipment", "code": "55121900" },
      { "name": "Advertising pillars", "code": "55121901" },
      { "name": "Barker stands or stalls", "code": "55121902" },
      { "name": "Moving message signs", "code": "55121903" },
      { "name": "Billboards", "code": "55121904" },
      { "name": "Flagpoles or parts or accessories", "code": "55121905" },
      { "name": "Flag stands", "code": "55121906" },
      { "name": "Title boards", "code": "55121907" },
      { "name": "Sign holders or stands", "code": "55121908" },
      { "name": "Electrical labels", "code": "55122000" },
      { "name": "Voice and data identification label", "code": "55122001" },
      {
        "name": "Circuit board and component identification label",
        "code": "55122002"
      },
      { "name": "Arc flash label", "code": "55122003" }
    ]
  },
  {
    "name": "Furniture and Furnishings",
    "code": "56000000",
    "subcategories": [
      { "name": "Accommodation furniture", "code": "56100000" },
      { "name": "Furniture", "code": "56101500" },
      { "name": "Stands", "code": "56101501" },
      { "name": "Sofas", "code": "56101502" },
      { "name": "Coat racks", "code": "56101503" },
      { "name": "Chairs", "code": "56101504" },
      { "name": "Entertainment centers", "code": "56101505" },
      { "name": "Futons", "code": "56101506" },
      { "name": "Bookcases", "code": "56101507" },
      { "name": "Mattresses or sleep sets", "code": "56101508" },
      { "name": "Dressers or armoires", "code": "56101509" },
      { "name": "Partitions", "code": "56101510" },
      { "name": "Cots or accessories", "code": "56101513" },
      { "name": "Foot stools", "code": "56101514" },
      { "name": "Beds", "code": "56101515" },
      { "name": "Chests", "code": "56101516" },
      { "name": "Wall racks", "code": "56101518" },
      { "name": "Tables", "code": "56101519" },
      { "name": "Lockers", "code": "56101520" },
      { "name": "Bed headboard or footboard", "code": "56101521" },
      { "name": "Arm chair", "code": "56101522" },
      { "name": "Umbrella holders or stands", "code": "56101523" },
      { "name": "Ironing boards", "code": "56101524" },
      { "name": "Ironing board covers", "code": "56101525" },
      { "name": "Fridge bar", "code": "56101526" },
      { "name": "Household type linen driers", "code": "56101527" },
      { "name": "Artificial plants", "code": "56101528" },
      { "name": "Magazine racks", "code": "56101529" },
      { "name": "Storage cabinets", "code": "56101530" },
      { "name": "Shoe racks", "code": "56101531" },
      { "name": "Furniture set", "code": "56101532" },
      { "name": "Armrests", "code": "56101533" },
      { "name": "Furniture carts", "code": "56101535" },
      { "name": "Instrument tripods", "code": "56101536" },
      { "name": "Dressing tables", "code": "56101537" },
      { "name": "Dining servers or buffets", "code": "56101538" },
      { "name": "Bedframes or parts or accessories", "code": "56101539" },
      { "name": "Apparel costumers", "code": "56101540" },
      { "name": "Mattress ventilators", "code": "56101541" },
      { "name": "Folding chairs", "code": "56101542" },
      { "name": "Dining table", "code": "56101543" },
      { "name": "Furniture mirror", "code": "56101544" },
      { "name": "Decorative mirror", "code": "56101545" },
      { "name": "Test bench for furniture", "code": "56101546" },
      { "name": "Outdoor furniture", "code": "56101600" },
      { "name": "Outdoor umbrellas", "code": "56101601" },
      { "name": "Outdoor chairs", "code": "56101602" },
      { "name": "Outdoor tables or picnic tables", "code": "56101603" },
      { "name": "Outdoor swings", "code": "56101604" },
      { "name": "Outdoor benches", "code": "56101605" },
      { "name": "Flower stands", "code": "56101606" },
      { "name": "Outside clothes dryer", "code": "56101607" },
      { "name": "Bicycle racks", "code": "56101608" },
      { "name": "Clay flowerpot", "code": "56101609" },
      { "name": "Flowerpot support", "code": "56101610" },
      { "name": "Office furniture", "code": "56101700" },
      { "name": "Credenzas", "code": "56101701" },
      { "name": "Filing cabinets or accesories", "code": "56101702" },
      { "name": "Desks", "code": "56101703" },
      { "name": "Table base", "code": "56101704" },
      { "name": "Showcases", "code": "56101705" },
      { "name": "Conferencing tables", "code": "56101706" },
      { "name": "Drafting tables", "code": "56101707" },
      { "name": "Mobile files", "code": "56101708" },
      { "name": "Projector stands or carts", "code": "56101710" },
      { "name": "Modular furniture connectors", "code": "56101711" },
      { "name": "Pedestals", "code": "56101712" },
      { "name": "Desk returns", "code": "56101713" },
      { "name": "Data binder racks", "code": "56101714" },
      { "name": "Mail sorters or organizers", "code": "56101715" },
      { "name": "Desktop organizer hutches", "code": "56101716" },
      { "name": "Table risers", "code": "56101717" },
      { "name": "Newspaper rack", "code": "56101718" },
      { "name": "Side table", "code": "56101719" },
      {
        "name": "Baby and toddler furniture and accessories",
        "code": "56101800"
      },
      { "name": "Carriages or perambulators or strollers", "code": "56101803" },
      { "name": "Cribs or playpens or accessories", "code": "56101804" },
      { "name": "Car seats", "code": "56101805" },
      { "name": "High chairs or accessories", "code": "56101806" },
      { "name": "Bouncer seats or jumpers", "code": "56101807" },
      { "name": "Swings or jumpers or accessories", "code": "56101808" },
      { "name": "Potty seats", "code": "56101809" },
      { "name": "Baby baths or tubs", "code": "56101810" },
      { "name": "Bassinets or cradles", "code": "56101811" },
      { "name": "Changing tables or accessories", "code": "56101812" },
      { "name": "Bath seats", "code": "56101813" },
      { "name": "General furniture parts and accessories", "code": "56101900" },
      { "name": "Furniture tops or work surfaces", "code": "56101901" },
      { "name": "Furniture moving discs", "code": "56101902" },
      { "name": "Furniture glides or cups or pads", "code": "56101903" },
      {
        "name": "Furniture bases or legs or leg extensions",
        "code": "56101904"
      },
      { "name": "Panel assemblies or sections", "code": "56101905" },
      { "name": "Table extension leafs", "code": "56101906" },
      { "name": "Slip covers", "code": "56101907" },
      { "name": "Commercial and industrial furniture", "code": "56110000" },
      { "name": "Workstations and office packages", "code": "56111500" },
      { "name": "Modular reception office packages", "code": "56111501" },
      {
        "name": "Casegood or non modular executive office packages",
        "code": "56111502"
      },
      { "name": "Modular executive office packages", "code": "56111503" },
      {
        "name": "Casegood or non modular managerial office packages",
        "code": "56111504"
      },
      { "name": "Modular managerial office packages", "code": "56111505" },
      {
        "name": "Casegood or non modular staff office packages",
        "code": "56111506"
      },
      { "name": "Modular staff office packages", "code": "56111507" },
      {
        "name": "Casegood or non modular technical office packages",
        "code": "56111508"
      },
      { "name": "Modular technical office packages", "code": "56111509" },
      {
        "name": "Casegood or non modular clerical office packages",
        "code": "56111510"
      },
      { "name": "Modular clerical office packages", "code": "56111511" },
      {
        "name": "Casegood or non modular reception office packages",
        "code": "56111512"
      },
      { "name": "Conference or non modular room packages", "code": "56111513" },
      { "name": "Modular counter office packages", "code": "56111514" },
      { "name": "Panel systems", "code": "56111600" },
      { "name": "Screens for panel systems", "code": "56111601" },
      { "name": "Storage for panel systems", "code": "56111602" },
      { "name": "Organization for panel systems", "code": "56111603" },
      { "name": "Work surfaces for panel systems", "code": "56111604" },
      {
        "name": "Lighting or power or data components for panel systems",
        "code": "56111605"
      },
      { "name": "Parts or accessories panel systems", "code": "56111606" },
      { "name": "Casegood and non modular systems", "code": "56111700" },
      { "name": "Casegood or non modular desk", "code": "56111701" },
      { "name": "Casegood or non modular credenza", "code": "56111702" },
      { "name": "Casegood or non modular storage", "code": "56111703" },
      { "name": "Casegood or non modular organization", "code": "56111704" },
      {
        "name": "Casegood or non modular lighting or power or data components",
        "code": "56111705"
      },
      {
        "name": "Casegood or non modular parts or accessories",
        "code": "56111706"
      },
      { "name": "Casegood or non modular shelf", "code": "56111707" },
      { "name": "Freestanding furniture", "code": "56111800" },
      {
        "name": "Freestanding lighting or power or data components",
        "code": "56111801"
      },
      { "name": "Freestanding tables", "code": "56111802" },
      { "name": "Freestanding storage", "code": "56111803" },
      { "name": "Freestanding organization", "code": "56111804" },
      { "name": "Freestanding parts or accessories", "code": "56111805" },
      { "name": "Swiveling barber chair", "code": "56111806" },
      { "name": "Industrial furniture", "code": "56111900" },
      {
        "name": "Industrial lighting or power or data components",
        "code": "56111901"
      },
      { "name": "Industrial work surfaces", "code": "56111902" },
      { "name": "Industrial storage units", "code": "56111903" },
      { "name": "Industrial organization", "code": "56111904" },
      { "name": "Industrial parts or accessories", "code": "56111905" },
      {
        "name": "Industrial cabinets or drawers or shelving",
        "code": "56111906"
      },
      { "name": "Industrial tool carts", "code": "56111907" },
      { "name": "Seismic isolation platform", "code": "56111908" },
      { "name": "Computer support furniture", "code": "56112000" },
      {
        "name": "Computer support lighting or power or data components",
        "code": "56112001"
      },
      { "name": "Computer support work surfaces", "code": "56112002" },
      { "name": "Computer support storage accessories", "code": "56112003" },
      { "name": "Computer support organization", "code": "56112004" },
      { "name": "Computer support parts or accessories", "code": "56112005" },
      { "name": "Seating", "code": "56112100" },
      {
        "name": "Auditorium or stadium or special use seating",
        "code": "56112101"
      },
      { "name": "Task seating", "code": "56112102" },
      { "name": "Guest seating", "code": "56112103" },
      { "name": "Executive seating", "code": "56112104" },
      { "name": "Lounge seating", "code": "56112105" },
      { "name": "Stool seating", "code": "56112106" },
      { "name": "Seating parts or accessories", "code": "56112107" },
      { "name": "Combination chair with desk", "code": "56112108" },
      { "name": "Benches", "code": "56112109" },
      { "name": "Musician seating", "code": "56112110" },
      { "name": "Booster seats", "code": "56112111" },
      { "name": "Desking systems", "code": "56112200" },
      { "name": "Desk based screens", "code": "56112201" },
      { "name": "Desk storage components", "code": "56112202" },
      { "name": "Desking organizational components", "code": "56112203" },
      { "name": "Worksurfaces", "code": "56112204" },
      { "name": "Lighting, power or data components", "code": "56112205" },
      {
        "name": "Desking systems related parts and accessories",
        "code": "56112206"
      },
      {
        "name": "Auditorium or stadium or special use seating parts and accessories",
        "code": "56112300"
      },
      { "name": "Back rest", "code": "56112301" },
      { "name": "Footboard seating fixture", "code": "56112302" },
      { "name": "Seat pivot", "code": "56112303" },
      { "name": "Chair seat", "code": "56112304" },
      {
        "name": "Classroom and instructional and institutional furniture and fixtures",
        "code": "56120000"
      },
      { "name": "Library furnishings", "code": "56121000" },
      { "name": "Book carts or book trucks", "code": "56121001" },
      {
        "name": "Circulation or librarian desks or components",
        "code": "56121002"
      },
      { "name": "Book returns", "code": "56121003" },
      { "name": "Card catalog units", "code": "56121004" },
      { "name": "Dictionary stands", "code": "56121005" },
      { "name": "Upholstered benches", "code": "56121006" },
      { "name": "Public access tables", "code": "56121007" },
      { "name": "Book browser units", "code": "56121008" },
      { "name": "Sloped reading tables", "code": "56121009" },
      { "name": "Book kiosks", "code": "56121010" },
      {
        "name": "Library compact disc or audio cassette displayers",
        "code": "56121011"
      },
      { "name": "Rotary island stands", "code": "56121012" },
      { "name": "Hang up bag racks or bags", "code": "56121014" },
      { "name": "Mobile book rack", "code": "56121015" },
      { "name": "Art classroom furnishings", "code": "56121100" },
      { "name": "Art horse", "code": "56121101" },
      { "name": "Art student bench", "code": "56121102" },
      { "name": "First aid room furnishings", "code": "56121200" },
      { "name": "First aid couches", "code": "56121201" },
      { "name": "General educational facility fixtures", "code": "56121300" },
      { "name": "Standing risers", "code": "56121301" },
      { "name": "Table or chair movers or caddys", "code": "56121302" },
      { "name": "Rubber brush floor matting", "code": "56121303" },
      { "name": "Planning tables", "code": "56121304" },
      { "name": "Cafeteria and lunchroom furnishings", "code": "56121400" },
      { "name": "Mobile bench tables", "code": "56121401" },
      { "name": "Mobile stool tables", "code": "56121402" },
      { "name": "Mobile tables", "code": "56121403" },
      { "name": "General classroom furnishings", "code": "56121500" },
      { "name": "Activity tables", "code": "56121501" },
      { "name": "Classroom chairs", "code": "56121502" },
      { "name": "Classroom benches", "code": "56121503" },
      { "name": "Classroom stools", "code": "56121504" },
      { "name": "Classroom tables", "code": "56121505" },
      { "name": "Student desks", "code": "56121506" },
      { "name": "Study carrels", "code": "56121507" },
      { "name": "Student computer desks", "code": "56121508" },
      { "name": "Student computer tables", "code": "56121509" },
      { "name": "Teacher desk", "code": "56121510" },
      {
        "name": "Creative play and rest time furnishings for daycare and early childhood facilities",
        "code": "56121600"
      },
      { "name": "Toddler or child size living room sets", "code": "56121601" },
      { "name": "Toddler or child size sofas", "code": "56121602" },
      { "name": "Toddler or child size easy chairs", "code": "56121603" },
      { "name": "Toddler or child size bean bag chairs", "code": "56121604" },
      { "name": "Low rise room dividers or play panels", "code": "56121605" },
      { "name": "Childs rest mats", "code": "56121606" },
      { "name": "Childs rest mat racks or holders", "code": "56121607" },
      { "name": "Childrens cots", "code": "56121608" },
      { "name": "Childrens cot carriers", "code": "56121609" },
      { "name": "Childrens cot activity systems", "code": "56121610" },
      { "name": "Infant\u00a0table", "code": "56121611" },
      {
        "name": "Book and general storage units for classrooms",
        "code": "56121700"
      },
      { "name": "General storage units", "code": "56121701" },
      { "name": "Book storage units", "code": "56121702" },
      { "name": "Cubbie units", "code": "56121703" },
      { "name": "Institutional Storage Cabinets", "code": "56121704" },
      {
        "name": "Vocational classroom furnishings and fixtures",
        "code": "56121800"
      },
      {
        "name": "Technical education tool storage cabinets or cabinets with tools",
        "code": "56121801"
      },
      {
        "name": "General shop tool storage cabinets or cabinets with tools",
        "code": "56121802"
      },
      {
        "name": "Woodworking tool storage cabinets or cabinets with tools",
        "code": "56121803"
      },
      { "name": "Instructors technical desk", "code": "56121804" },
      { "name": "Flat files", "code": "56121805" },
      { "name": "Demonstration furnishings", "code": "56121900" },
      { "name": "Sewing machine demonstrator tables", "code": "56121901" },
      { "name": "Display stand", "code": "56121902" },
      { "name": "Relic preservation storage cabinet", "code": "56121903" },
      { "name": "Laboratory furniture", "code": "56122000" },
      { "name": "Laboratory benches", "code": "56122001" },
      { "name": "Laboratory storage units or accessories", "code": "56122002" },
      { "name": "Laboratory workstations", "code": "56122003" },
      { "name": "Sink base units", "code": "56122004" },
      { "name": "Merchandising furniture and accessories", "code": "56130000" },
      { "name": "Mannequins and forms", "code": "56131500" },
      { "name": "Bust forms", "code": "56131501" },
      { "name": "Head forms", "code": "56131502" },
      { "name": "Neck forms", "code": "56131503" },
      { "name": "Full body form or mannequin", "code": "56131504" },
      {
        "name": "Merchandiser free standing display and accessories",
        "code": "56131600"
      },
      { "name": "Floor stands", "code": "56131601" },
      { "name": "Free standing display poles", "code": "56131602" },
      { "name": "Sales counters", "code": "56131603" },
      { "name": "Paint color center component", "code": "56131604" },
      { "name": "Paint chain board", "code": "56131605" },
      { "name": "Paint color fan deck", "code": "56131606" },
      { "name": "Color inspiration selection", "code": "56131607" },
      {
        "name": "Merchandiser installation hardware, shelving systems and accessories",
        "code": "56131700"
      },
      { "name": "Hang rails", "code": "56131701" },
      { "name": "Merchandise baskets", "code": "56131702" },
      { "name": "Waterfalls or straight arm face outs", "code": "56131703" },
      { "name": "Decorative adornments", "code": "56140000" },
      { "name": "Ceramic adornments", "code": "56141500" },
      { "name": "Ceramic bowl", "code": "56141501" },
      { "name": "Ceramic plate or platter", "code": "56141502" },
      { "name": "Ceramic vase", "code": "56141503" },
      { "name": "Ceramic statuette or figurine", "code": "56141504" },
      { "name": "Glass adornments", "code": "56141600" },
      { "name": "Glass bowl", "code": "56141601" },
      { "name": "Glass vase", "code": "56141602" },
      { "name": "Glass plate or platter", "code": "56141603" },
      { "name": "Glass statuette or figurine", "code": "56141604" },
      { "name": "Metal adornments", "code": "56141700" },
      { "name": "Metal bowl", "code": "56141701" },
      { "name": "Metal chest or box", "code": "56141702" },
      { "name": "Metal figurine or statuette", "code": "56141703" },
      { "name": "Wooden adornments", "code": "56141800" },
      { "name": "Wooden bowl", "code": "56141801" },
      { "name": "Wooden chest or box", "code": "56141802" },
      { "name": "Wooden figurine or statuette", "code": "56141803" }
    ]
  },
  {
    "name": "Musical Instruments and Games and Toys and Arts and Crafts and Educational Equipment and Materials and Accessories and Supplies",
    "code": "60000000",
    "subcategories": [
      {
        "name": "Developmental and professional teaching aids and materials and accessories and supplies",
        "code": "60100000"
      },
      { "name": "Math kits", "code": "60101000" },
      { "name": "Addition math kits", "code": "60101001" },
      { "name": "Division math kits", "code": "60101002" },
      { "name": "Fraction math kits", "code": "60101003" },
      { "name": "Middle school math kits", "code": "60101004" },
      { "name": "Early childhood math kits", "code": "60101005" },
      { "name": "Measurement math kits", "code": "60101006" },
      { "name": "Multiplication math kits", "code": "60101007" },
      { "name": "Elementary math kits", "code": "60101008" },
      { "name": "Subtraction math kits", "code": "60101009" },
      { "name": "High school math kits", "code": "60101010" },
      { "name": "Electronic learning aids", "code": "60101100" },
      { "name": "Electronic card readers", "code": "60101101" },
      {
        "name": "Curriculum based electronic learning aids",
        "code": "60101102"
      },
      { "name": "Electronic globes", "code": "60101103" },
      { "name": "Electronic quiz machines", "code": "60101104" },
      { "name": "Educational incentives", "code": "60101200" },
      { "name": "Bible based chart stickers", "code": "60101201" },
      { "name": "Bible based incentive charts", "code": "60101202" },
      { "name": "Incentive chart stickers", "code": "60101203" },
      { "name": "Incentive charts", "code": "60101204" },
      { "name": "Incentive punchcards", "code": "60101205" },
      { "name": "Educational stickers and supplies", "code": "60101300" },
      { "name": "Bible based stickers", "code": "60101301" },
      { "name": "Giant stickers", "code": "60101302" },
      { "name": "Photo stickers", "code": "60101304" },
      { "name": "Reward stickers", "code": "60101305" },
      { "name": "Scented stickers", "code": "60101306" },
      { "name": "Shape stickers", "code": "60101307" },
      { "name": "Sparkle stickers", "code": "60101308" },
      { "name": "Reward star stickers", "code": "60101309" },
      { "name": "Sticker assortments", "code": "60101310" },
      { "name": "Sticker books", "code": "60101311" },
      { "name": "Sticker boxes", "code": "60101312" },
      { "name": "Tattoo stickers", "code": "60101313" },
      { "name": "Addition flash cards", "code": "60101314" },
      { "name": "All facts flash cards", "code": "60101315" },
      { "name": "Blank flash cards", "code": "60101316" },
      { "name": "Division flash cards", "code": "60101317" },
      { "name": "Electronic flash cards", "code": "60101318" },
      { "name": "Equivalent flash cards", "code": "60101319" },
      { "name": "Fraction flash cards", "code": "60101320" },
      { "name": "Greater than or less than flash cards", "code": "60101321" },
      { "name": "Multiplication flash cards", "code": "60101322" },
      { "name": "Subtraction flash cards", "code": "60101323" },
      { "name": "Alphabet flash cards", "code": "60101324" },
      { "name": "Word building flash cards", "code": "60101325" },
      { "name": "Phonics flash cards", "code": "60101326" },
      { "name": "Handwriting or writing flash cards", "code": "60101327" },
      { "name": "Number flash cards", "code": "60101328" },
      { "name": "Money flash cards", "code": "60101329" },
      { "name": "Time flash cards", "code": "60101330" },
      { "name": "State flash cards", "code": "60101331" },
      { "name": "Classroom awards", "code": "60101400" },
      { "name": "Badges", "code": "60101401" },
      { "name": "Award buttons", "code": "60101402" },
      { "name": "Celebration crowns", "code": "60101403" },
      { "name": "Reward jewelry", "code": "60101404" },
      { "name": "Classroom ribbons or rosettes", "code": "60101405" },
      { "name": "Educational certificates or diplomas", "code": "60101600" },
      { "name": "Bible based certificates", "code": "60101601" },
      { "name": "Blank certificates", "code": "60101602" },
      { "name": "Certificate frames", "code": "60101603" },
      { "name": "Certificate holders", "code": "60101604" },
      { "name": "Certificate ribbons", "code": "60101605" },
      { "name": "Diplomas", "code": "60101606" },
      { "name": "Foreign language certificates", "code": "60101607" },
      { "name": "General praise certificates", "code": "60101608" },
      { "name": "Grade specific certificates", "code": "60101609" },
      { "name": "Subject specific certificates", "code": "60101610" },
      { "name": "Teacher resource materials", "code": "60101700" },
      { "name": "Assessment resource books", "code": "60101701" },
      { "name": "Calendars or cut outs", "code": "60101702" },
      { "name": "Character education resource materials", "code": "60101703" },
      { "name": "Classroom activity books", "code": "60101704" },
      { "name": "Critical thinking resource materials", "code": "60101705" },
      { "name": "Cross curriculum guides", "code": "60101706" },
      { "name": "Curriculum guides", "code": "60101707" },
      { "name": "Fabric charts", "code": "60101708" },
      { "name": "Flannel board aids", "code": "60101709" },
      { "name": "Educator gifts", "code": "60101710" },
      { "name": "Grading stamps", "code": "60101711" },
      { "name": "Hall passes", "code": "60101712" },
      { "name": "Home education resource materials", "code": "60101713" },
      { "name": "Homework assignment resources", "code": "60101714" },
      { "name": "Idea books", "code": "60101715" },
      { "name": "Magnetic board aids", "code": "60101716" },
      { "name": "Name plates or tags", "code": "60101717" },
      { "name": "Teacher plan books", "code": "60101718" },
      { "name": "Pocket charts", "code": "60101719" },
      { "name": "Teacher communication postcards", "code": "60101720" },
      { "name": "Professional teacher resource books", "code": "60101721" },
      { "name": "Teacher classroom grading books", "code": "60101722" },
      { "name": "Classroom student seating charts", "code": "60101723" },
      { "name": "Substitute teacher folders or forms", "code": "60101724" },
      { "name": "Technology activity or resource books", "code": "60101725" },
      { "name": "Technology reference guides", "code": "60101726" },
      { "name": "Test taking resource materials", "code": "60101727" },
      { "name": "Theme unit resource materials", "code": "60101728" },
      { "name": "Middle or junior school teaching kits", "code": "60101729" },
      { "name": "Laboratory manuals", "code": "60101730" },
      { "name": "Sentence strips or rolls", "code": "60101731" },
      { "name": "Pointing sticks", "code": "60101732" },
      { "name": "School supply set or kit", "code": "60101733" },
      { "name": "Flip chart", "code": "60101734" },
      { "name": "Church school educational resources", "code": "60101800" },
      { "name": "Bible reference guides", "code": "60101801" },
      { "name": "Bible based dramas", "code": "60101802" },
      { "name": "Bible based resource or activity books", "code": "60101803" },
      { "name": "Sunday school activity resource books", "code": "60101804" },
      { "name": "Vacation bible school resources", "code": "60101805" },
      { "name": "Holy emblems or symbols", "code": "60101806" },
      { "name": "Prayer beads", "code": "60101807" },
      { "name": "Prayer wheels", "code": "60101808" },
      { "name": "Religious product kits or supplies", "code": "60101809" },
      { "name": "Patens", "code": "60101810" },
      { "name": "Vestments", "code": "60101811" },
      { "name": "Alphabet skills materials and resources", "code": "60101900" },
      { "name": "Alphabet activity books", "code": "60101901" },
      { "name": "Alphabet cubes", "code": "60101902" },
      { "name": "Alphabet desk tapes", "code": "60101903" },
      { "name": "Alphabet kits", "code": "60101904" },
      { "name": "Alphabet letter tiles", "code": "60101905" },
      { "name": "Alphabet poster cards", "code": "60101906" },
      { "name": "Alphabet reference guides", "code": "60101907" },
      { "name": "Alphabet resource books", "code": "60101908" },
      { "name": "Alphabet stamps", "code": "60101909" },
      { "name": "Alphabet wall cards", "code": "60101910" },
      { "name": "Tactile alphabets", "code": "60101911" },
      {
        "name": "Word building resource materials and accessories",
        "code": "60102000"
      },
      { "name": "Speech mirror", "code": "60102001" },
      { "name": "Spelling resource materials", "code": "60102002" },
      { "name": "Word building activity books", "code": "60102003" },
      { "name": "Word building kits", "code": "60102004" },
      { "name": "Word building resource books", "code": "60102005" },
      { "name": "Word building tiles", "code": "60102006" },
      { "name": "Word walls", "code": "60102007" },
      { "name": "Language arts resource materials", "code": "60102100" },
      { "name": "Adjective resource books", "code": "60102101" },
      { "name": "Adverb resource books", "code": "60102102" },
      { "name": "Grammar resource books", "code": "60102103" },
      { "name": "Noun resource books", "code": "60102104" },
      { "name": "Punctuation resource books", "code": "60102105" },
      { "name": "Verb resource books", "code": "60102106" },
      {
        "name": "Phonics resource materials and accessories",
        "code": "60102200"
      },
      { "name": "Phonics activity books", "code": "60102201" },
      { "name": "Phonics drill cards", "code": "60102202" },
      { "name": "Phonics kit", "code": "60102203" },
      { "name": "Phonics picture cards", "code": "60102204" },
      { "name": "Phonics resource books", "code": "60102205" },
      { "name": "Phonics tiles", "code": "60102206" },
      { "name": "Reading books and resources", "code": "60102300" },
      { "name": "Reading activity books", "code": "60102301" },
      { "name": "Beginning reading books", "code": "60102302" },
      { "name": "Bible based childrens literature books", "code": "60102303" },
      { "name": "Childrens literature books", "code": "60102304" },
      { "name": "Critical reading skills", "code": "60102305" },
      { "name": "Flannel boards", "code": "60102306" },
      { "name": "Poetry resource books", "code": "60102307" },
      { "name": "Reading comprehension materials", "code": "60102308" },
      { "name": "Reading development materials or kits", "code": "60102309" },
      { "name": "Reading resource books", "code": "60102310" },
      { "name": "Reading thematic units", "code": "60102311" },
      { "name": "Vocabulary activity or resource books", "code": "60102312" },
      {
        "name": "Early learning math and counting resources and accessories",
        "code": "60102400"
      },
      { "name": "Abacus or counting frames", "code": "60102401" },
      {
        "name": "Activity or resource books for working with early math manipulatives",
        "code": "60102402"
      },
      {
        "name": "Activity cards for working with early math manipulatives",
        "code": "60102403"
      },
      {
        "name": "Beads or bead activity sets for early math",
        "code": "60102404"
      },
      {
        "name": "Counters or counter activity sets for early math",
        "code": "60102405"
      },
      {
        "name": "Counting or sorting trays or bowls for early math",
        "code": "60102406"
      },
      { "name": "Laces or lacing sets for early math", "code": "60102407" },
      {
        "name": "Linking manipulatives or linking activity sets for early math",
        "code": "60102408"
      },
      { "name": "Math game chips or tokens", "code": "60102409" },
      { "name": "Number cards", "code": "60102410" },
      { "name": "Number forms models or accessories", "code": "60102411" },
      { "name": "Pegboards for early math", "code": "60102412" },
      { "name": "Pegs for early math", "code": "60102413" },
      {
        "name": "Sorting manipulatives or sorting activity sets for early math",
        "code": "60102414"
      },
      {
        "name": "Basic math operations and numeration resources and materials",
        "code": "60102500"
      },
      { "name": "Addition activity or resource books", "code": "60102501" },
      { "name": "Basic operations models", "code": "60102502" },
      { "name": "Basic operations reference guides", "code": "60102503" },
      { "name": "Division activity or resource books", "code": "60102504" },
      {
        "name": "Multiplication activity or resource books",
        "code": "60102505"
      },
      { "name": "Subtraction activity or resource books", "code": "60102506" },
      { "name": "Number desk tapes", "code": "60102507" },
      { "name": "Hundreds charts or boards", "code": "60102508" },
      { "name": "Hundreds number tiles", "code": "60102509" },
      { "name": "Number kits", "code": "60102510" },
      { "name": "Number lines", "code": "60102511" },
      { "name": "Numeration activity or resource books", "code": "60102512" },
      { "name": "Dominoes", "code": "60102513" },
      {
        "name": "Probability or chance or data or problem solving teacher resource materials",
        "code": "60102600"
      },
      { "name": "Two sided counters or tokens", "code": "60102601" },
      { "name": "Spinners", "code": "60102602" },
      { "name": "Dice sets", "code": "60102603" },
      { "name": "Probability activity or resource books", "code": "60102604" },
      { "name": "Logic activity or resource books", "code": "60102605" },
      { "name": "Attribute blocks", "code": "60102606" },
      { "name": "Attribute blocks activity cards", "code": "60102607" },
      { "name": "Attribute activity or resource books", "code": "60102608" },
      { "name": "Graphing mat", "code": "60102609" },
      { "name": "Graphing activity or resource books", "code": "60102610" },
      { "name": "Logic games", "code": "60102611" },
      { "name": "Attribute sets or kits", "code": "60102612" },
      {
        "name": "Problem solving activity or resource books",
        "code": "60102613"
      },
      { "name": "Problem solving activity cards", "code": "60102614" },
      {
        "name": "Patterning or matching or spatial perception or logical thinking teacher resource materials",
        "code": "60102700"
      },
      {
        "name": "Pattern blocks or parquetry blocks activity or resource books",
        "code": "60102701"
      },
      { "name": "Pattern blocks", "code": "60102702" },
      { "name": "Pattern block activity or pattern cards", "code": "60102703" },
      { "name": "Pattern block games or activity sets", "code": "60102704" },
      { "name": "Pattern block stickers", "code": "60102705" },
      { "name": "Pattern blocks mirror", "code": "60102706" },
      { "name": "Pattern blocks charts or posters", "code": "60102707" },
      { "name": "Parquetry blocks", "code": "60102708" },
      {
        "name": "Parquetry blocks activity or pattern cards",
        "code": "60102709"
      },
      { "name": "Parquetry blocks activity sets", "code": "60102710" },
      { "name": "Tangrams activity or resource books", "code": "60102711" },
      { "name": "Tangram activity or pattern cards", "code": "60102712" },
      { "name": "Tangram puzzle activity sets", "code": "60102713" },
      { "name": "Tangrams puzzles", "code": "60102714" },
      { "name": "Pentominoes activity and resource books", "code": "60102715" },
      { "name": "Pentominoes", "code": "60102717" },
      { "name": "Pentominoes activity sets", "code": "60102718" },
      {
        "name": "Base ten and place value teacher resource materials",
        "code": "60102800"
      },
      { "name": "Base ten blocks", "code": "60102801" },
      {
        "name": "Base ten or place value activity or resource books",
        "code": "60102802"
      },
      { "name": "Base ten or place value activity cards", "code": "60102803" },
      { "name": "Base ten rubber stamps", "code": "60102804" },
      { "name": "Place value grids", "code": "60102805" },
      { "name": "Place value games or activity sets", "code": "60102806" },
      { "name": "Place value models or accessories", "code": "60102807" },
      {
        "name": "Money and time teacher resource materials",
        "code": "60102900"
      },
      { "name": "Money activity or resource books", "code": "60102901" },
      { "name": "Bulk bills for the classroom", "code": "60102902" },
      { "name": "Bulk coins for the classroom", "code": "60102903" },
      { "name": "Coin cubes or dice", "code": "60102904" },
      { "name": "Magnetic money", "code": "60102905" },
      { "name": "Overhead bills", "code": "60102906" },
      { "name": "Overhead coins", "code": "60102907" },
      { "name": "Money puzzles", "code": "60102908" },
      { "name": "Money rubber stamps", "code": "60102909" },
      { "name": "Coin bank", "code": "60102910" },
      { "name": "Money games or kits", "code": "60102911" },
      { "name": "Play cash registers", "code": "60102912" },
      { "name": "Money reference guides", "code": "60102913" },
      { "name": "Time activity or resource books", "code": "60102914" },
      { "name": "Time rubber stamps", "code": "60102915" },
      { "name": "Time kits", "code": "60102916" },
      { "name": "Time reference guides", "code": "60102917" },
      {
        "name": "Fractions and pentominoes and decimals teaching aids",
        "code": "60103000"
      },
      { "name": "Fraction circles or squares", "code": "60103001" },
      { "name": "Decimal squares", "code": "60103002" },
      { "name": "Fraction activity books", "code": "60103003" },
      { "name": "Fraction bars", "code": "60103004" },
      { "name": "Fraction charts", "code": "60103005" },
      { "name": "Fraction dice", "code": "60103006" },
      { "name": "Fraction games", "code": "60103007" },
      { "name": "Fraction kits", "code": "60103008" },
      { "name": "Fraction tiles", "code": "60103009" },
      { "name": "Fractions discs", "code": "60103010" },
      { "name": "Pentominoes activity book", "code": "60103012" },
      { "name": "Pentominoes pattern cards", "code": "60103013" },
      { "name": "Geometry teacher resource materials", "code": "60103100" },
      { "name": "Geometry activity or resource books", "code": "60103101" },
      { "name": "Geometry charts or posters", "code": "60103102" },
      { "name": "Geoboards", "code": "60103103" },
      { "name": "Geometric construction sets", "code": "60103104" },
      { "name": "Double sided geoboards", "code": "60103105" },
      { "name": "Geoboard games or activity kits", "code": "60103106" },
      { "name": "Geoboard rubber bands", "code": "60103107" },
      { "name": "Geoboards activity cards", "code": "60103108" },
      {
        "name": "Geometric chalkboard drawing instruments",
        "code": "60103109"
      },
      { "name": "Geometry reference guides", "code": "60103110" },
      { "name": "GeoMirror", "code": "60103111" },
      { "name": "Geometric solids models", "code": "60103112" },
      { "name": "Algebra teacher resource materials", "code": "60103200" },
      { "name": "Algebra activity or resource books", "code": "60103201" },
      { "name": "Centimeter cubes", "code": "60103202" },
      { "name": "Algebra or pre algebra reference guides", "code": "60103203" },
      { "name": "Algebra models or accessories", "code": "60103204" },
      {
        "name": "Precalculus and calculus teacher resource materials",
        "code": "60103300"
      },
      { "name": "Calculus activity or resources books", "code": "60103301" },
      {
        "name": "Precalculus or calculus reference guides",
        "code": "60103302"
      },
      { "name": "Precalculus activity or resource books", "code": "60103303" },
      {
        "name": "Geography and map skills resources and accessories",
        "code": "60103400"
      },
      { "name": "Geography charts or posters", "code": "60103401" },
      { "name": "Continents activity or resource books", "code": "60103402" },
      { "name": "Electronic social studies learning aids", "code": "60103403" },
      { "name": "Geography reference guides", "code": "60103404" },
      { "name": "Map racks", "code": "60103405" },
      { "name": "Map stencil kits", "code": "60103406" },
      { "name": "Portable wall maps", "code": "60103407" },
      { "name": "Geography resource or activity books", "code": "60103408" },
      { "name": "Map measurers", "code": "60103409" },
      { "name": "Map teaching aids or materials", "code": "60103410" },
      { "name": "Political science", "code": "60103500" },
      { "name": "Economics activity or resource books", "code": "60103501" },
      { "name": "Government activity or resource books", "code": "60103502" },
      { "name": "Government reference guides", "code": "60103503" },
      { "name": "State theme units", "code": "60103504" },
      { "name": "Multicultural activities and resources", "code": "60103600" },
      { "name": "Ancient civilizations resources", "code": "60103601" },
      {
        "name": "Customs or rituals or traditions resources",
        "code": "60103602"
      },
      { "name": "Ethnic diversity resources", "code": "60103603" },
      { "name": "Genealogy resources", "code": "60103604" },
      { "name": "Multicultural holidays resources", "code": "60103605" },
      { "name": "Multicultural theme units", "code": "60103606" },
      { "name": "Foreign languages resources", "code": "60103700" },
      { "name": "Resources for learning to speak Spanish", "code": "60103701" },
      { "name": "Resources for learning to speak French", "code": "60103702" },
      { "name": "Resources for learning to speak German", "code": "60103703" },
      { "name": "Resources for learning to speak English", "code": "60103704" },
      { "name": "Resources for learning to speak Latin", "code": "60103705" },
      { "name": "Resources for learning to speak Italian", "code": "60103706" },
      { "name": "History teaching resources", "code": "60103800" },
      { "name": "African history resource books", "code": "60103801" },
      { "name": "History charts or posters", "code": "60103802" },
      { "name": "European history resource books", "code": "60103803" },
      { "name": "Historical maps", "code": "60103804" },
      { "name": "History theme units", "code": "60103805" },
      { "name": "History photo cards", "code": "60103806" },
      { "name": "History resource books", "code": "60103807" },
      { "name": "Womans history resources", "code": "60103808" },
      { "name": "World history resources", "code": "60103809" },
      {
        "name": "Living organisms and preserved specimens and related materials",
        "code": "60103900"
      },
      { "name": "Amphibia models", "code": "60103903" },
      { "name": "Fungi cultures", "code": "60103904" },
      { "name": "Culture kits or supplies", "code": "60103905" },
      { "name": "Protozoan cultures", "code": "60103906" },
      { "name": "Aquaria plants", "code": "60103907" },
      { "name": "Terrarium plants", "code": "60103908" },
      { "name": "Live invertebrates", "code": "60103909" },
      { "name": "Live vertebrates", "code": "60103911" },
      { "name": "Dissection kits or supplies", "code": "60103915" },
      { "name": "Preserved embryos", "code": "60103916" },
      { "name": "Biospheres", "code": "60103918" },
      { "name": "Biology experiment kits or supplies", "code": "60103919" },
      { "name": "Staining kits or supplies", "code": "60103920" },
      { "name": "Preserved life cycle specimens", "code": "60103921" },
      { "name": "Biology reference guides", "code": "60103922" },
      { "name": "Skeleton or bone or shell specimen", "code": "60103923" },
      { "name": "Biology resource or activity books", "code": "60103924" },
      { "name": "Biology study or activity kits", "code": "60103925" },
      { "name": "Biology charts or posters", "code": "60103926" },
      { "name": "Preserved plant body or organ specimens", "code": "60103927" },
      { "name": "Biology activity or photo cards", "code": "60103928" },
      { "name": "Plant life cycle specimens", "code": "60103929" },
      { "name": "Combination organism specimens", "code": "60103930" },
      {
        "name": "Animal body or body part or organ specimens",
        "code": "60103931"
      },
      { "name": "Ecosystem displays", "code": "60103932" },
      {
        "name": "Human body or body part or organ specimens",
        "code": "60103933"
      },
      { "name": "Tissue cultures", "code": "60103934" },
      { "name": "Anatomy charts or sets", "code": "60103936" },
      {
        "name": "Biotechnology and bio chemistry and genetics and microbiology and related materials",
        "code": "60104000"
      },
      { "name": "Deoxyribonucleic acid DNA models", "code": "60104001" },
      {
        "name": "Deoxyribonucleic acid DNA experiment kits",
        "code": "60104002"
      },
      { "name": "Genetics books", "code": "60104003" },
      { "name": "Genetics kits", "code": "60104004" },
      { "name": "Bacteria teaching kits", "code": "60104005" },
      { "name": "Bacteria testing supplies", "code": "60104006" },
      { "name": "Enzymology kits or supplies", "code": "60104007" },
      { "name": "Protein testing kits or supplies", "code": "60104008" },
      { "name": "Body systems and related materials", "code": "60104100" },
      { "name": "Human body or body part or organ models", "code": "60104101" },
      { "name": "Cell models", "code": "60104102" },
      { "name": "Cell teaching kits", "code": "60104103" },
      { "name": "Body systems teaching kits", "code": "60104104" },
      { "name": "Body systems teaching aids", "code": "60104105" },
      { "name": "Plant body or body part or organ models", "code": "60104106" },
      {
        "name": "Animal body or body part or organ models",
        "code": "60104107"
      },
      {
        "name": "Water testing and conservation and ecology",
        "code": "60104200"
      },
      { "name": "Water testing chemicals", "code": "60104201" },
      { "name": "Water testing and sampling kits", "code": "60104202" },
      { "name": "Water models", "code": "60104203" },
      { "name": "Water ecology supplies", "code": "60104204" },
      { "name": "Astronomy", "code": "60104300" },
      { "name": "Astronomy models", "code": "60104301" },
      { "name": "Astronomy charts", "code": "60104302" },
      { "name": "Astronomy study kits", "code": "60104303" },
      { "name": "Sun compass", "code": "60104304" },
      { "name": "Solar simulator", "code": "60104305" },
      { "name": "Planetarium", "code": "60104306" },
      { "name": "Planetary model", "code": "60104307" },
      { "name": "Celestial globe", "code": "60104308" },
      { "name": "Manual star chart", "code": "60104309" },
      { "name": "Geology and earth science", "code": "60104400" },
      { "name": "Rock specimen sets", "code": "60104401" },
      { "name": "Rock specimens", "code": "60104402" },
      { "name": "Fossils", "code": "60104403" },
      { "name": "Landform models", "code": "60104404" },
      { "name": "Fossil models", "code": "60104405" },
      { "name": "Geology tools or field", "code": "60104406" },
      { "name": "Stream tables", "code": "60104407" },
      { "name": "Geology study kits", "code": "60104408" },
      { "name": "Cloud apparatus", "code": "60104409" },
      { "name": "Moon observation apparatus", "code": "60104410" },
      { "name": "Weather simulator", "code": "60104411" },
      { "name": "Soil accumulation apparatus", "code": "60104412" },
      { "name": "Coriolis force apparatus", "code": "60104413" },
      { "name": "Terrestrial globe", "code": "60104414" },
      { "name": "Mineral model", "code": "60104415" },
      { "name": "Rotating cylinder", "code": "60104416" },
      {
        "name": "Chemistry and electrochemistry and microchemistry",
        "code": "60104500"
      },
      { "name": "Periodic table posters or boards", "code": "60104501" },
      { "name": "Consumer analysis kits", "code": "60104502" },
      { "name": "Chemistry class kits", "code": "60104503" },
      { "name": "Chemistry demonstration kits", "code": "60104504" },
      { "name": "Atomic models", "code": "60104505" },
      { "name": "Molecular models", "code": "60104506" },
      { "name": "Electrochemical demonstration tools", "code": "60104507" },
      { "name": "Electrochemical kits", "code": "60104508" },
      { "name": "Fuel cells", "code": "60104509" },
      { "name": "Microchemistry tools", "code": "60104511" },
      { "name": "Milikans oil drop apparatus", "code": "60104512" },
      { "name": "Mechanical physics materials", "code": "60104600" },
      { "name": "Force tables", "code": "60104601" },
      { "name": "Gravity models or model sets", "code": "60104602" },
      { "name": "Inclined planes", "code": "60104604" },
      { "name": "Friction apparatus", "code": "60104605" },
      { "name": "Physic cars", "code": "60104606" },
      { "name": "Pendulum apparatus", "code": "60104607" },
      { "name": "Torque apparatus", "code": "60104608" },
      { "name": "Projectile apparatus", "code": "60104609" },
      { "name": "Air tables", "code": "60104610" },
      { "name": "Air apparatus", "code": "60104611" },
      { "name": "Light or photo apparatus", "code": "60104612" },
      { "name": "Magdeburg hemisphere", "code": "60104613" },
      { "name": "Newtons ring apparatus", "code": "60104614" },
      { "name": "Air track", "code": "60104615" },
      { "name": "Centripetal force apparatus", "code": "60104616" },
      { "name": "Tensile modulus apparatus", "code": "60104617" },
      { "name": "Newtonian demonstrator", "code": "60104618" },
      { "name": "Recording timer", "code": "60104619" },
      { "name": "Physics lesson kit", "code": "60104620" },
      { "name": "Energy and power physics materials", "code": "60104700" },
      { "name": "Solar collecting devices", "code": "60104701" },
      { "name": "Solar kits", "code": "60104702" },
      { "name": "Energy demonstration kits", "code": "60104703" },
      { "name": "Energy class kits", "code": "60104704" },
      { "name": "Matter demonstration kits", "code": "60104705" },
      { "name": "Matter class kits", "code": "60104706" },
      { "name": "Monometers", "code": "60104707" },
      { "name": "Gas diffusion apparatus", "code": "60104708" },
      { "name": "Heat conduction apparatus", "code": "60104709" },
      {
        "name": "Thermal radiation and convection apparatus",
        "code": "60104710"
      },
      { "name": "Hookes law apparatus", "code": "60104711" },
      { "name": "Frank hertz apparatus", "code": "60104712" },
      { "name": "Liquid expansion apparatus", "code": "60104713" },
      { "name": "Boyles law apparatus", "code": "60104714" },
      { "name": "Pascals principle apparatus", "code": "60104715" },
      { "name": "Archimedes principle apparatus", "code": "60104716" },
      { "name": "Bernoullis theorem apparatus", "code": "60104717" },
      { "name": "Centrifugal pump apparatus", "code": "60104718" },
      { "name": "Ventury tube apparatus", "code": "60104719" },
      { "name": "Reynolds number measuring apparatus", "code": "60104720" },
      { "name": "Inertia moment apparatus", "code": "60104721" },
      { "name": "Gravitational force apparatus", "code": "60104722" },
      { "name": "Torsion balance", "code": "60104723" },
      { "name": "Refrigeration system analyzer", "code": "60104724" },
      { "name": "Torricellis law apparatus", "code": "60104726" },
      { "name": "Wave and sound physics materials", "code": "60104800" },
      { "name": "Wave generators", "code": "60104801" },
      { "name": "Wave tanks", "code": "60104802" },
      { "name": "Wave springs", "code": "60104803" },
      { "name": "Wave demonstration sets", "code": "60104804" },
      { "name": "Tuning forks", "code": "60104805" },
      { "name": "Doppler demonstrators", "code": "60104806" },
      { "name": "Resonance apparatus", "code": "60104807" },
      { "name": "Sound meters", "code": "60104808" },
      { "name": "Wave apparatus", "code": "60104809" },
      { "name": "Spectroscopes", "code": "60104810" },
      { "name": "Spectrum charts", "code": "60104811" },
      { "name": "Light demonstration kits", "code": "60104812" },
      { "name": "Color charts or samples", "code": "60104813" },
      { "name": "Radiometer", "code": "60104814" },
      { "name": "Reflection or refraction apparatus", "code": "60104815" },
      { "name": "Optics sets or kits", "code": "60104816" },
      { "name": "Ripple tank apparatus", "code": "60104817" },
      { "name": "Planck constant apparatus", "code": "60104818" },
      { "name": "Balmer lamp", "code": "60104819" },
      { "name": "Kundt apparatus", "code": "60104820" },
      { "name": "Photoelectric effect apparatus", "code": "60104821" },
      { "name": "Elementary optical bench set", "code": "60104822" },
      { "name": "Simple harmonic oscillator", "code": "60104823" },
      { "name": "Wave optics apparatus", "code": "60104824" },
      { "name": "Holography apparatus", "code": "60104825" },
      { "name": "Optical magic mirror", "code": "60104826" },
      { "name": "Focal distance measuring equipment", "code": "60104827" },
      { "name": "Electrical physics materials", "code": "60104900" },
      { "name": "Van degraff generators", "code": "60104901" },
      { "name": "Electrostatic apparatus", "code": "60104902" },
      { "name": "Electrostatic kits", "code": "60104903" },
      { "name": "Electricity kits", "code": "60104904" },
      { "name": "Electricity demonstration boards", "code": "60104905" },
      { "name": "Battery kits", "code": "60104906" },
      { "name": "Hand held generators", "code": "60104907" },
      { "name": "Electromagnetic apparatus", "code": "60104908" },
      { "name": "Magnetism apparatus", "code": "60104909" },
      { "name": "Electromagnets", "code": "60104910" },
      { "name": "Electric bells or accessories", "code": "60104911" },
      { "name": "Electric lead wires or cables", "code": "60104912" },
      { "name": "Ohms law apparatus", "code": "60104913" },
      { "name": "Discharge tube", "code": "60104914" },
      { "name": "Transformer experiment apparatus", "code": "60104915" },
      { "name": "Thermoelectric effect apparatus", "code": "60104916" },
      { "name": "Electric equipotential apparatus", "code": "60104917" },
      { "name": "Photoelectric apparatus", "code": "60104918" },
      { "name": "Leyden jar", "code": "60104919" },
      { "name": "Cloud chamber", "code": "60104920" },
      { "name": "Zeeman effect apparatus", "code": "60104921" },
      { "name": "Coulombs law apparatus", "code": "60104922" },
      { "name": "Discharger", "code": "60104923" },
      { "name": "Faradays law apparatus", "code": "60104924" },
      { "name": "Flemings law apparatus", "code": "60104925" },
      { "name": "Hall effect apparatus", "code": "60104926" },
      { "name": "Armature test system", "code": "60104927" },
      { "name": "Crookes tube", "code": "60104928" },
      { "name": "Rotating magnetic field generator", "code": "60104929" },
      { "name": "Fluorescent light demonstration device", "code": "60104930" },
      { "name": "Nuclear physics materials", "code": "60105000" },
      { "name": "Radioactivity sets", "code": "60105001" },
      { "name": "Geiger counters", "code": "60105002" },
      { "name": "Electron apparatus", "code": "60105003" },
      { "name": "Radiation warning signs", "code": "60105004" },
      { "name": "Nuclear physics transparencies", "code": "60105005" },
      { "name": "Nuclear physics charts", "code": "60105006" },
      {
        "name": "Rocketry and flight materials and supplies",
        "code": "60105100"
      },
      { "name": "Rocket sets", "code": "60105101" },
      { "name": "Launching apparatus", "code": "60105102" },
      { "name": "Altitude measuring devices", "code": "60105103" },
      { "name": "Airplane kits", "code": "60105104" },
      {
        "name": "Life skills resources instructional materials",
        "code": "60105200"
      },
      {
        "name": "Building listening skills instructional materials",
        "code": "60105201"
      },
      { "name": "Study skills instructional materials", "code": "60105202" },
      {
        "name": "Test preparation instructional materials",
        "code": "60105203"
      },
      {
        "name": "Career education instructional materials",
        "code": "60105300"
      },
      {
        "name": "Career education or planning or decision making skills instructional materials",
        "code": "60105301"
      },
      {
        "name": "Basic job skills instructional materials",
        "code": "60105302"
      },
      {
        "name": "Job search skills instructional materials",
        "code": "60105303"
      },
      {
        "name": "Time management skills instructional materials",
        "code": "60105304"
      },
      {
        "name": "Interview skills instructional materials",
        "code": "60105305"
      },
      { "name": "Resume skills instructional materials", "code": "60105306" },
      {
        "name": "Work ethics or attitude training instructional materials",
        "code": "60105307"
      },
      {
        "name": "Team building skills instructional materials",
        "code": "60105308"
      },
      {
        "name": "Business etiquette instructional materials",
        "code": "60105309"
      },
      {
        "name": "Consumer economics and money management and independent living instructional materials",
        "code": "60105400"
      },
      {
        "name": "Personal finance or money management education instructional materials",
        "code": "60105401"
      },
      {
        "name": "Shopping or consumer skills instructional materials",
        "code": "60105402"
      },
      {
        "name": "Independent living instructional materials",
        "code": "60105403"
      },
      {
        "name": "Understanding consumer credit or loans instructional materials",
        "code": "60105404"
      },
      {
        "name": "Insurance coverage or insurance comparison instructional materials",
        "code": "60105405"
      },
      { "name": "Home buying instructional materials", "code": "60105406" },
      {
        "name": "Apartment rental instructional materials",
        "code": "60105407"
      },
      { "name": "Car buying educational aids", "code": "60105408" },
      {
        "name": "Brand marketing or advertising instructional materials",
        "code": "60105409"
      },
      {
        "name": "Relationship building or family life skills instructional materials",
        "code": "60105410"
      },
      {
        "name": "Developing self concept and self esteem instructional materials",
        "code": "60105411"
      },
      {
        "name": "Violence avoidance education or violence prevention instructional materials",
        "code": "60105412"
      },
      {
        "name": "Anger resolution training instructional materials",
        "code": "60105413"
      },
      {
        "name": "Teaching patience skills instructional materials",
        "code": "60105414"
      },
      {
        "name": "Tolerance training instructional materials",
        "code": "60105415"
      },
      { "name": "Personal safety instructional materials", "code": "60105416" },
      {
        "name": "Personal conflict resolution instructional materials",
        "code": "60105417"
      },
      { "name": "Practical teen advice guides", "code": "60105418" },
      {
        "name": "Developing social skills instructional materials",
        "code": "60105419"
      },
      {
        "name": "Manners or etiquette or courtesy instructional aids",
        "code": "60105420"
      },
      {
        "name": "Understanding or dealing with cultural diversity instructional materials",
        "code": "60105421"
      },
      {
        "name": "How to read body language instructional materials",
        "code": "60105422"
      },
      {
        "name": "Developing resiliency instructional materials",
        "code": "60105423"
      },
      {
        "name": "Understanding community service instructional materials",
        "code": "60105424"
      },
      {
        "name": "Developing refusal skills instructional materials",
        "code": "60105425"
      },
      {
        "name": "Responsibility or Ddecision making skills instructional materials",
        "code": "60105426"
      },
      {
        "name": "Understanding teens legal rights instructional materials",
        "code": "60105427"
      },
      {
        "name": "Repercussions of dropping out of school instructional materials",
        "code": "60105428"
      },
      { "name": "Race relations videos", "code": "60105429" },
      {
        "name": "Home and interior design instructional materials",
        "code": "60105500"
      },
      { "name": "Feng shui instructional materials", "code": "60105501" },
      {
        "name": "Instructional materials for using color or paint for home decoration",
        "code": "60105502"
      },
      {
        "name": "Instructional materials for home planning or design",
        "code": "60105503"
      },
      {
        "name": "Landscaping design instructional materials",
        "code": "60105504"
      },
      {
        "name": "Instructional materials for home decorating or furnishing",
        "code": "60105505"
      },
      {
        "name": "Health education and nutrition and food preparation instructional materials",
        "code": "60105600"
      },
      {
        "name": "Dietary guidelines or balanced diets educational resources",
        "code": "60105601"
      },
      {
        "name": "Nutritional curriculum menu planning skills instructional materials",
        "code": "60105602"
      },
      {
        "name": "Understanding nutrition labeling instructional materials",
        "code": "60105603"
      },
      { "name": "Food shopping instructional materials", "code": "60105604" },
      {
        "name": "Healthy food choices demonstration units",
        "code": "60105605"
      },
      {
        "name": "Understanding the effects of dietary fat instructional materials",
        "code": "60105606"
      },
      {
        "name": "Understanding vegetarianism instructional materials",
        "code": "60105607"
      },
      { "name": "Recipe books or cook books", "code": "60105608" },
      {
        "name": "Eating disorders education instructional materials",
        "code": "60105609"
      },
      {
        "name": "Weight control or exercise instructional materials",
        "code": "60105610"
      },
      {
        "name": "Kitchen measurements of solids or liquids instructional aids",
        "code": "60105611"
      },
      {
        "name": "Kitchen equivalants or kitchen math instructional aids",
        "code": "60105612"
      },
      { "name": "Kitchen utensils instructional aids", "code": "60105613" },
      {
        "name": "Kitchen safety or sanitation instructional materials",
        "code": "60105614"
      },
      { "name": "Food safety instructional materials", "code": "60105615" },
      {
        "name": "Food science activities instructional materials",
        "code": "60105616"
      },
      {
        "name": "Cooking skills instruction instructional materials",
        "code": "60105617"
      },
      {
        "name": "Table manners or eating etiquette instructional materials",
        "code": "60105618"
      },
      { "name": "Table setting instructional materials", "code": "60105619" },
      {
        "name": "Food service training instructional materials",
        "code": "60105620"
      },
      {
        "name": "Drug or tobacco or alcohol abuse education instructional materials",
        "code": "60105621"
      },
      { "name": "Smoking Simulators", "code": "60105622" },
      {
        "name": "Understanding addiction or addiction avoidance instructional materials",
        "code": "60105623"
      },
      {
        "name": "Teen depression symptoms instructional materials",
        "code": "60105624"
      },
      {
        "name": "Teen suicide avoidance training instructional materials",
        "code": "60105625"
      },
      {
        "name": "Coping with stress instructional materials",
        "code": "60105626"
      },
      { "name": "Memory books and supplies", "code": "60105700" },
      { "name": "Memory books", "code": "60105701" },
      { "name": "Memory book posts or extenders", "code": "60105702" },
      { "name": "Memory book paper", "code": "60105703" },
      { "name": "Acid free glue sticks", "code": "60105704" },
      { "name": "Acid free tape", "code": "60105705" },
      {
        "name": "Clothing and textile design instructional materials",
        "code": "60105800"
      },
      { "name": "Sewing skills instructional materials", "code": "60105801" },
      { "name": "Sewing project materials", "code": "60105802" },
      {
        "name": "Understanding clothing construction or workmanship instructional materials",
        "code": "60105803"
      },
      {
        "name": "Clothing design or fashion instructional materials",
        "code": "60105804"
      },
      {
        "name": "Personal color analysis instructional materials",
        "code": "60105805"
      },
      {
        "name": "Fashion merchandising or retail fundamentals instructional materials",
        "code": "60105806"
      },
      {
        "name": "Science of fabrics or fibers instructional materials",
        "code": "60105807"
      },
      {
        "name": "Clothing care or maintenance or laundering instructional materials",
        "code": "60105808"
      },
      {
        "name": "Materials for teaching the art of design color on fabric",
        "code": "60105809"
      },
      {
        "name": "Fabric dyes or paints instructional materials",
        "code": "60105810"
      },
      {
        "name": "Quilting projects instructional materials",
        "code": "60105811"
      },
      {
        "name": "Dating and sex and teen pregnancy and parenting skills and child development instructional materials",
        "code": "60105900"
      },
      {
        "name": "Sex education or sexually transmitted disease instructional materials",
        "code": "60105901"
      },
      {
        "name": "Prenatal nutrition resources or fetal abuse instructional materials",
        "code": "60105902"
      },
      {
        "name": "Parenting skills instructional materials",
        "code": "60105903"
      },
      {
        "name": "Child development instructional materials",
        "code": "60105904"
      },
      {
        "name": "Understanding date rape or dating skills or harassment instructional materials",
        "code": "60105905"
      },
      {
        "name": "Childbirth education instructional materials",
        "code": "60105906"
      },
      {
        "name": "Pregnancy from conception through birth instructional materials",
        "code": "60105907"
      },
      {
        "name": "Understanding the risks of birth defects instructional materials",
        "code": "60105908"
      },
      { "name": "Pregnancy simulators", "code": "60105909" },
      { "name": "Infant simulators and accessories", "code": "60105910" },
      {
        "name": "Infant care training instructional materials",
        "code": "60105911"
      },
      {
        "name": "Understanding physical or emotional child abuse instructional materials",
        "code": "60105912"
      },
      {
        "name": "Discipline skill education instructional materials for parents",
        "code": "60105913"
      },
      {
        "name": "Home safety or childproofing instructional materials",
        "code": "60105914"
      },
      {
        "name": "Cardio pulmonary resusitation or basic life support instructional materials",
        "code": "60105915"
      },
      {
        "name": "Understanding childhood illnesses instructional materials",
        "code": "60105916"
      },
      {
        "name": "Understanding attention deficit hyperactivity disorder instructional materials",
        "code": "60105917"
      },
      { "name": "Child caregiver instructional materials", "code": "60105918" },
      { "name": "Babysitting instructional materials", "code": "60105919" },
      {
        "name": "Curriculum guides and projects and activities and lessons",
        "code": "60106000"
      },
      {
        "name": "Middle school curriculum resource or idea books",
        "code": "60106001"
      },
      {
        "name": "High School curriculum resource or idea books",
        "code": "60106002"
      },
      {
        "name": "Home economics independent study projects",
        "code": "60106003"
      },
      {
        "name": "Home economics projects or activities resources or guides",
        "code": "60106004"
      },
      { "name": "Vocational teaching aids and materials", "code": "60106100" },
      { "name": "Automotive teaching aids or materials", "code": "60106101" },
      { "name": "Construction teaching aids or materials", "code": "60106102" },
      {
        "name": "Drafting or design teaching aids or materials",
        "code": "60106103"
      },
      {
        "name": "Electronics or electricity teaching aids or materials",
        "code": "60106104"
      },
      {
        "name": "Graphic arts or photography teaching aids or materials",
        "code": "60106105"
      },
      { "name": "Horticulture teaching aids or materials", "code": "60106106" },
      {
        "name": "Manufacturing teaching aids or materials",
        "code": "60106107"
      },
      {
        "name": "Safety or hazardous teaching aids or materials",
        "code": "60106108"
      },
      { "name": "Welding teaching aids or materials", "code": "60106109" },
      { "name": "Technology teaching aids and materials", "code": "60106200" },
      { "name": "Agriculture teaching aids or materials", "code": "60106201" },
      {
        "name": "Biotechnology teaching aids or materials",
        "code": "60106202"
      },
      {
        "name": "Communications teaching aids or materials",
        "code": "60106203"
      },
      {
        "name": "Computer science teaching aids or materials",
        "code": "60106204"
      },
      {
        "name": "Energy or power teaching aids or materials",
        "code": "60106205"
      },
      {
        "name": "Environmental teaching aids or materials",
        "code": "60106206"
      },
      { "name": "Materials teaching aids or materials", "code": "60106207" },
      { "name": "Medical teaching aids or materials", "code": "60106208" },
      {
        "name": "Transportation teaching aids or materials",
        "code": "60106209"
      },
      {
        "name": "Weapon system teaching aids or materials",
        "code": "60106210"
      },
      {
        "name": "Engine or engine parts teaching aids or materials",
        "code": "60106211"
      },
      {
        "name": "Navigational instrument teaching aids or materials",
        "code": "60106212"
      },
      {
        "name": "Fluid mechanics or machines teaching aids or materials",
        "code": "60106213"
      },
      { "name": "Robotics teaching aids or materials", "code": "60106214" },
      {
        "name": "Cooling systems teaching aids or materials",
        "code": "60106215"
      },
      {
        "name": "Forensic science teaching equipment and supplies",
        "code": "60106300"
      },
      { "name": "Forensic science kits", "code": "60106301" },
      {
        "name": "Forensic science teaching aids or materials",
        "code": "60106302"
      },
      {
        "name": "Electronics teaching supplies and equipment",
        "code": "60106400"
      },
      { "name": "Electronics kits", "code": "60106401" },
      { "name": "Electronics teaching supplies", "code": "60106402" },
      { "name": "Religious supplies", "code": "60106500" },
      { "name": "Buddhist statue", "code": "60106501" },
      { "name": "Holy mother and son statue", "code": "60106502" },
      { "name": "Ecclesiastical cross", "code": "60106503" },
      { "name": "Tableware for memorial service", "code": "60106504" },
      { "name": "Incense burner", "code": "60106505" },
      { "name": "Harubang", "code": "60106506" },
      { "name": "Altar kit", "code": "60106507" },
      {
        "name": "Aptitude and vocational testing materials",
        "code": "60106600"
      },
      { "name": "Block assembly test", "code": "60106601" },
      { "name": "Form perception test", "code": "60106602" },
      { "name": "Speed anticipation reaction test", "code": "60106603" },
      { "name": "Vocational evaluation set", "code": "60106604" },
      { "name": "Mental reaction test", "code": "60106605" },
      { "name": "Aim test", "code": "60106606" },
      { "name": "Reaction movement test", "code": "60106607" },
      { "name": "Two arm coordination test", "code": "60106608" },
      { "name": "Discrimination tool", "code": "60106609" },
      { "name": "Assembly testing tool", "code": "60106610" },
      { "name": "Packaging testing tool", "code": "60106611" },
      { "name": "Eye arm coordination test", "code": "60106612" },
      { "name": "Classroom decoratives and supplies", "code": "60110000" },
      { "name": "Classroom Charts or classroom posters", "code": "60111000" },
      { "name": "Chart packs", "code": "60111001" },
      { "name": "Classroom charts", "code": "60111002" },
      { "name": "Classroom posters or sets", "code": "60111003" },
      { "name": "Do it yourself posters", "code": "60111004" },
      { "name": "Chart holders or accessories", "code": "60111005" },
      { "name": "Bulletin board sets", "code": "60111100" },
      { "name": "Big bulletin board sets", "code": "60111101" },
      { "name": "Calendar bulletin board sets", "code": "60111102" },
      { "name": "Early childhood bulletin board sets", "code": "60111103" },
      { "name": "Language bulletin board sets", "code": "60111104" },
      { "name": "Math bulletin board sets", "code": "60111105" },
      { "name": "Multipurpose bulletin board sets", "code": "60111106" },
      { "name": "Science bulletin board sets", "code": "60111107" },
      { "name": "Seasonal bulletin board sets", "code": "60111108" },
      { "name": "Social studies bulletin board sets", "code": "60111109" },
      { "name": "Bulletin board borders and trimmers", "code": "60111200" },
      { "name": "Classroom banners", "code": "60111201" },
      { "name": "Border packs", "code": "60111202" },
      { "name": "Classroom headers", "code": "60111203" },
      { "name": "Corrugated borders or trimmers", "code": "60111204" },
      {
        "name": "Die cut shaped scalloped borders or trimmers",
        "code": "60111205"
      },
      { "name": "Sparkle borders or trimmers", "code": "60111206" },
      { "name": "Straight borders or trimmers", "code": "60111207" },
      { "name": "Border or Trimmer storage", "code": "60111208" },
      { "name": "Decorative letters and numbers", "code": "60111300" },
      { "name": "Lettered or numbered blocks", "code": "60111301" },
      { "name": "Casual letters or numbers", "code": "60111302" },
      { "name": "Italic letters or numbers", "code": "60111303" },
      { "name": "Self adhesive letters or numbers", "code": "60111304" },
      { "name": "Sparkle letters or numbers", "code": "60111305" },
      { "name": "Tracing letters or numbers", "code": "60111306" },
      {
        "name": "Specialty decoratives for the classroom and decorative accessories",
        "code": "60111400"
      },
      { "name": "Classroom decorative kits", "code": "60111401" },
      { "name": "Door decorations", "code": "60111402" },
      { "name": "Mobiles", "code": "60111403" },
      { "name": "Two sided decorations", "code": "60111404" },
      { "name": "Window cling decorations", "code": "60111405" },
      { "name": "Decorative Storage Systems", "code": "60111407" },
      { "name": "Decorative tapes or twists", "code": "60111408" },
      { "name": "Decorative buttons", "code": "60111409" },
      { "name": "Decorative shapes or strings", "code": "60111410" },
      { "name": "Decorative sprays", "code": "60111411" },
      {
        "name": "Arts and crafts equipment and accessories and supplies",
        "code": "60120000"
      },
      { "name": "Art", "code": "60121000" },
      { "name": "Paintings", "code": "60121001" },
      { "name": "Sculptures", "code": "60121002" },
      { "name": "Statuary", "code": "60121003" },
      { "name": "Portraits", "code": "60121004" },
      { "name": "Drawings", "code": "60121005" },
      { "name": "Pictures", "code": "60121006" },
      { "name": "Lithographs", "code": "60121007" },
      { "name": "Posters", "code": "60121008" },
      { "name": "Decorative pot", "code": "60121009" },
      { "name": "Scrolls", "code": "60121010" },
      { "name": "Photographs", "code": "60121011" },
      { "name": "Decorative stickers", "code": "60121012" },
      { "name": "Wall artistic decoration", "code": "60121013" },
      { "name": "Decorative dried fruit", "code": "60121014" },
      { "name": "Adhesive decorative vinyl", "code": "60121015" },
      { "name": "Public artwork", "code": "60121016" },
      {
        "name": "Canvases and films and boards and artists papers",
        "code": "60121100"
      },
      { "name": "Sulphite drawing paper", "code": "60121101" },
      { "name": "Groundwood drawing paper", "code": "60121102" },
      { "name": "Tracing or vellum drawing paper", "code": "60121103" },
      { "name": "Bond drawing paper", "code": "60121104" },
      { "name": "Charcoal or pastel drawing paper", "code": "60121105" },
      { "name": "Bristol drawing paper", "code": "60121106" },
      { "name": "Watercolor paper sheets", "code": "60121107" },
      { "name": "Watercolor paper pads", "code": "60121108" },
      { "name": "Watercolor paper blocks", "code": "60121109" },
      { "name": "Finger paint paper", "code": "60121110" },
      { "name": "Sulphite construction paper", "code": "60121111" },
      { "name": "Groundwood construction paper", "code": "60121112" },
      { "name": "Foil construction paper", "code": "60121113" },
      { "name": "Origami craft papers", "code": "60121114" },
      { "name": "Paper or plastic Confetti", "code": "60121115" },
      { "name": "Crepe paper for crafts", "code": "60121116" },
      { "name": "Craft tissue paper", "code": "60121117" },
      { "name": "Corrugated craft paper", "code": "60121118" },
      { "name": "Pattern printed craft paper", "code": "60121119" },
      { "name": "Self adhesive craft paper", "code": "60121120" },
      { "name": "Glow in the dark paper", "code": "60121121" },
      { "name": "Hand made paper", "code": "60121123" },
      { "name": "Kraft paper", "code": "60121124" },
      { "name": "Canvas panels", "code": "60121125" },
      { "name": "Prestretched canvas", "code": "60121126" },
      { "name": "Primed canvas", "code": "60121127" },
      { "name": "Unprimed canvas", "code": "60121128" },
      { "name": "Masonite panels", "code": "60121129" },
      { "name": "Canvas like paper pads", "code": "60121130" },
      { "name": "Japanese printmaking paper", "code": "60121131" },
      {
        "name": "Lithography or intaglio printmaking paper",
        "code": "60121132"
      },
      { "name": "Blockprinting printmaking paper", "code": "60121133" },
      { "name": "Foil paper", "code": "60121134" },
      { "name": "Acetate or vinyl or polyester films", "code": "60121135" },
      { "name": "Cellophane films", "code": "60121136" },
      { "name": "Acrylic sheets", "code": "60121137" },
      { "name": "Illustration boards", "code": "60121138" },
      { "name": "Mat boards", "code": "60121139" },
      { "name": "Mounting board", "code": "60121140" },
      { "name": "Foam core mounting board", "code": "60121141" },
      { "name": "Tagboard or railroad board", "code": "60121142" },
      { "name": "Display board", "code": "60121143" },
      { "name": "Scratch art papers", "code": "60121144" },
      { "name": "Scratch art boards", "code": "60121145" },
      { "name": "Scratch art accessories", "code": "60121146" },
      { "name": "Gloss paper", "code": "60121147" },
      { "name": "Color paperboard", "code": "60121148" },
      { "name": "Plant press paper", "code": "60121149" },
      { "name": "Sugar paper", "code": "60121150" },
      {
        "name": "Drawing or sketching boards or accessories",
        "code": "60121151"
      },
      { "name": "Writing slates", "code": "60121152" },
      { "name": "Transfer sheets", "code": "60121153" },
      { "name": "Leathercloth paper", "code": "60121154" },
      { "name": "Metallic card", "code": "60121155" },
      { "name": "Holographic card", "code": "60121156" },
      { "name": "Embossed card", "code": "60121157" },
      { "name": "Fluorescent card", "code": "60121158" },
      {
        "name": "Classroom and fine art paint and mediums and applicators and accessories",
        "code": "60121200"
      },
      { "name": "Traditional liquid tempera paint", "code": "60121201" },
      { "name": "Contemporary liquid tempera paint", "code": "60121202" },
      { "name": "Powdered tempera paint", "code": "60121203" },
      { "name": "Washable tempera paint", "code": "60121204" },
      { "name": "Tempera cakes", "code": "60121205" },
      { "name": "Liquid face or body paint", "code": "60121206" },
      { "name": "Cake face or body paint", "code": "60121207" },
      { "name": "Marker face or body paint", "code": "60121208" },
      { "name": "Temporary tattoo paint", "code": "60121209" },
      { "name": "Washable finger paint", "code": "60121210" },
      { "name": "School style acrylic paint", "code": "60121211" },
      { "name": "Acrylic airbrush paint", "code": "60121212" },
      {
        "name": "Synthetic heat treated oil paint or mediums",
        "code": "60121213"
      },
      { "name": "Water soluble oil paint or mediums", "code": "60121214" },
      {
        "name": "Low viscosity removable glass or ceramic paint",
        "code": "60121215"
      },
      {
        "name": "Low viscosity permanent glass or ceramic paint",
        "code": "60121216"
      },
      {
        "name": "High viscosity Gel removable glass or ceramic paint",
        "code": "60121217"
      },
      {
        "name": "High viscosity Gel permanent glass or ceramic paint",
        "code": "60121218"
      },
      { "name": "Oven baked glass or ceramic paint", "code": "60121219" },
      {
        "name": "Marker delivery system glass or ceramic paint",
        "code": "60121220"
      },
      { "name": "Pan watercolor paint", "code": "60121221" },
      { "name": "Tube watercolor paint", "code": "60121222" },
      { "name": "Liquid watercolor paint", "code": "60121223" },
      { "name": "Liquid watercolor frisket paint", "code": "60121224" },
      { "name": "Watercolor painting mediums", "code": "60121225" },
      { "name": "Watercolor brushes", "code": "60121226" },
      { "name": "Oriental brushes", "code": "60121227" },
      { "name": "Utility brushes", "code": "60121228" },
      { "name": "Specialty brushes", "code": "60121229" },
      { "name": "Easel brushes", "code": "60121230" },
      { "name": "Palette knives", "code": "60121231" },
      { "name": "Brayers for hand printing", "code": "60121232" },
      { "name": "Sponge stamps", "code": "60121233" },
      { "name": "Scrapers for paint application", "code": "60121234" },
      { "name": "Pipettes for paint or dye mixing", "code": "60121235" },
      {
        "name": "Combs or tools for paint or ink application",
        "code": "60121236"
      },
      { "name": "Palettes for paint or ink mixing", "code": "60121237" },
      { "name": "Paint pots for paint storage or mixing", "code": "60121238" },
      { "name": "Paint cups or bottles", "code": "60121239" },
      { "name": "Brush or tool cleaners", "code": "60121241" },
      { "name": "Paint aprons", "code": "60121242" },
      { "name": "Artists Smocks", "code": "60121243" },
      { "name": "Stretcher strips", "code": "60121244" },
      { "name": "Canvas stretchers", "code": "60121245" },
      { "name": "Metal easels", "code": "60121246" },
      { "name": "Wood easels", "code": "60121247" },
      { "name": "Table top easels", "code": "60121248" },
      { "name": "Presentation easels", "code": "60121249" },
      { "name": "Drawing portfolios", "code": "60121250" },
      { "name": "Gouache paint", "code": "60121251" },
      { "name": "Paint pans", "code": "60121252" },
      { "name": "Art airbrushes", "code": "60121253" },
      { "name": "Art and craft cutting products", "code": "60121300" },
      { "name": "Guillotine paper trimmers", "code": "60121301" },
      { "name": "Mat cutter", "code": "60121302" },
      { "name": "Mat knives", "code": "60121303" },
      { "name": "Artist knives", "code": "60121304" },
      { "name": "Rotary paper or fabric cutter", "code": "60121305" },
      { "name": "Circle or oval paper cutters", "code": "60121306" },
      { "name": "Picture framing", "code": "60121400" },
      { "name": "Preassembled wood picture frames", "code": "60121401" },
      { "name": "Wood section picture frames", "code": "60121402" },
      { "name": "Preassembled metal picture frames", "code": "60121403" },
      { "name": "Metal section picture frames", "code": "60121404" },
      { "name": "Adjustable picture frames", "code": "60121405" },
      { "name": "Plastic picture frames", "code": "60121406" },
      { "name": "Clear box picture frame", "code": "60121407" },
      {
        "name": "Point drivers or accessories for picture frames",
        "code": "60121408"
      },
      { "name": "Mitre box", "code": "60121409" },
      { "name": "Picture Hanging devices", "code": "60121410" },
      { "name": "Acrylic panels for picture frames", "code": "60121411" },
      { "name": "Glass panels for picture frames", "code": "60121412" },
      { "name": "Photo or picture albums or organizers", "code": "60121413" },
      { "name": "Magnetic mounts for frames", "code": "60121414" },
      { "name": "Frame kits", "code": "60121415" },
      {
        "name": "Drawing tools and supplies and accessories",
        "code": "60121500"
      },
      { "name": "Water based markers", "code": "60121501" },
      { "name": "Solvent based markers", "code": "60121502" },
      { "name": "Washable markers", "code": "60121503" },
      { "name": "Calligraphy markers", "code": "60121504" },
      { "name": "Fabric markers", "code": "60121505" },
      { "name": "Metallic markers", "code": "60121506" },
      { "name": "Tempera or chalk window markers", "code": "60121507" },
      { "name": "Paint markers", "code": "60121508" },
      { "name": "Wax based crayons", "code": "60121509" },
      { "name": "Soy based crayons", "code": "60121510" },
      { "name": "Specialty crayons", "code": "60121511" },
      { "name": "Watercolor crayons", "code": "60121512" },
      { "name": "Dry pastel", "code": "60121513" },
      { "name": "Chalk pastel", "code": "60121514" },
      { "name": "Oil based pastel", "code": "60121515" },
      { "name": "Compressed charcoal", "code": "60121516" },
      { "name": "Vine charcoal", "code": "60121517" },
      { "name": "Graphite pencils", "code": "60121518" },
      { "name": "Wax based colored drawing pencils", "code": "60121519" },
      { "name": "Charcoal pencils", "code": "60121520" },
      { "name": "Watercolor pencils", "code": "60121521" },
      { "name": "Waterbased pens", "code": "60121522" },
      { "name": "Permanent pens", "code": "60121523" },
      { "name": "Gel pens", "code": "60121524" },
      { "name": "Technical pens", "code": "60121525" },
      { "name": "Calligraphy pens", "code": "60121526" },
      { "name": "Pink pencil erasers", "code": "60121531" },
      { "name": "Kneaded erasers", "code": "60121532" },
      { "name": "Vinyl erasers", "code": "60121533" },
      { "name": "Plastic erasers", "code": "60121534" },
      { "name": "Gum erasers", "code": "60121535" },
      { "name": "Crayon remover", "code": "60121536" },
      { "name": "Dip pens or their accessories", "code": "60121537" },
      { "name": "Calligraphy kits", "code": "60121538" },
      { "name": "Drawing fixatives", "code": "60121539" },
      { "name": "Drawing cloths", "code": "60121540" },
      { "name": "Studio aids", "code": "60121600" },
      { "name": "Wood mannequins", "code": "60121601" },
      { "name": "Clear acrylic mirrors or panels", "code": "60121602" },
      { "name": "Plastic Rubbing plates", "code": "60121603" },
      { "name": "Studio aid accessories", "code": "60121604" },
      { "name": "Anatomical models", "code": "60121605" },
      { "name": "Background screens", "code": "60121606" },
      { "name": "Printmaking supplies and accessories", "code": "60121700" },
      { "name": "Rubber stamping stamps", "code": "60121701" },
      { "name": "Rubber stamping stamp pads", "code": "60121702" },
      { "name": "Rubber stamping accessories", "code": "60121703" },
      { "name": "Linoleum for block printing", "code": "60121704" },
      { "name": "Wood blocks for printing", "code": "60121705" },
      { "name": "Synthetic blocks for printing", "code": "60121706" },
      { "name": "Block printing accessories", "code": "60121707" },
      { "name": "Intaglio or lithography plates", "code": "60121708" },
      { "name": "Intaglio or lithography blankets", "code": "60121709" },
      { "name": "Intaglio or lithography wipes", "code": "60121710" },
      { "name": "Intaglio or lithography hot plates", "code": "60121711" },
      {
        "name": "Intaglio or lithography printing presses",
        "code": "60121712"
      },
      { "name": "Printing barens and brayers", "code": "60121713" },
      { "name": "Intaglio etching or engraving tools", "code": "60121714" },
      { "name": "Silkscreen screens or printing stations", "code": "60121715" },
      { "name": "Silkscreen accessories", "code": "60121716" },
      { "name": "Etching needles", "code": "60121717" },
      { "name": "Printing ink extenders", "code": "60121718" },
      { "name": "Printing and drawing inks", "code": "60121800" },
      { "name": "Water based poster inks", "code": "60121801" },
      { "name": "Water based acrylic inks", "code": "60121802" },
      { "name": "Oil based silkscreen inks", "code": "60121803" },
      { "name": "Water based Textile inks", "code": "60121804" },
      { "name": "Oil based textile inks", "code": "60121805" },
      { "name": "Printmaking sublimation inks", "code": "60121806" },
      { "name": "Oil based intaglio or lithography inks", "code": "60121807" },
      { "name": "Oil based monoprint inks", "code": "60121808" },
      { "name": "Water based monoprint inks", "code": "60121809" },
      { "name": "Water based drawing inks", "code": "60121810" },
      { "name": "Solvent based drawing inks", "code": "60121811" },
      { "name": "Calligraphy drawing inks", "code": "60121812" },
      { "name": "Silkscreen inks", "code": "60121813" },
      { "name": "Lithographic varnishes", "code": "60121814" },
      {
        "name": "Art fabric and fabric decoration materials and supplies",
        "code": "60121900"
      },
      { "name": "Muslin", "code": "60121901" },
      { "name": "Felt", "code": "60121902" },
      { "name": "Craft fur", "code": "60121903" },
      { "name": "Cotton blends", "code": "60121904" },
      { "name": "Canvas imprintables", "code": "60121905" },
      { "name": "Pre sensitized imprintables", "code": "60121906" },
      { "name": "Cotton imprintables", "code": "60121907" },
      { "name": "Blended imprintables", "code": "60121908" },
      { "name": "Batik waxes", "code": "60121909" },
      { "name": "Batik accessories", "code": "60121910" },
      { "name": "Batik fabric", "code": "60121911" },
      { "name": "Art stumps", "code": "60121912" },
      {
        "name": "Sewing and stitchery and weaving equipment and accessories",
        "code": "60122000"
      },
      { "name": "Weaving accessories", "code": "60122002" },
      { "name": "Hand sewing needles", "code": "60122003" },
      { "name": "String art kits", "code": "60122004" },
      { "name": "Hand looms", "code": "60122005" },
      { "name": "Table looms", "code": "60122006" },
      { "name": "Floor looms", "code": "60122007" },
      { "name": "Rexlace", "code": "60122008" },
      { "name": "Lacing or stringing accessories", "code": "60122009" },
      { "name": "Candlemaking", "code": "60122100" },
      { "name": "Candlemaking wicks", "code": "60122101" },
      { "name": "Candlemaking forms", "code": "60122102" },
      { "name": "Candlemaking accessories", "code": "60122103" },
      { "name": "Wood crafts", "code": "60122200" },
      { "name": "Wood craft materials", "code": "60122201" },
      { "name": "Finishing materials", "code": "60122202" },
      { "name": "Wood burning tools", "code": "60122203" },
      { "name": "Carving tools", "code": "60122204" },
      { "name": "Basketry making supplies", "code": "60122300" },
      { "name": "Basketry reed", "code": "60122301" },
      { "name": "Basketry project kits", "code": "60122302" },
      { "name": "Stained glass making supplies", "code": "60122400" },
      { "name": "Stained Glass fragments", "code": "60122401" },
      { "name": "Stained glass tools or accessories", "code": "60122402" },
      { "name": "Paper crafts and hand made papermaking", "code": "60122500" },
      { "name": "Paper shaping tools", "code": "60122501" },
      { "name": "Paper picture frames", "code": "60122502" },
      { "name": "Paper plates or trays", "code": "60122503" },
      { "name": "Paper filters", "code": "60122504" },
      { "name": "Paper Doilies", "code": "60122506" },
      { "name": "Deckles or molds for hand made paper", "code": "60122507" },
      {
        "name": "Couch sheets or felts for hand made paper",
        "code": "60122508"
      },
      {
        "name": "Pulp or raw materials for hand made paper",
        "code": "60122509"
      },
      { "name": "Card making kit", "code": "60122510" },
      { "name": "Mosaics", "code": "60122600" },
      { "name": "Mosaic tiles", "code": "60122601" },
      { "name": "Mosaic molds", "code": "60122602" },
      { "name": "Mosaic tools", "code": "60122603" },
      { "name": "Mosaic accessories", "code": "60122604" },
      { "name": "Enameling paints and accessories", "code": "60122700" },
      { "name": "Enameling paints or mediums", "code": "60122701" },
      { "name": "Copper shapes", "code": "60122702" },
      { "name": "Enameling accessories", "code": "60122703" },
      { "name": "Enameling Kilns", "code": "60122704" },
      { "name": "Maskmaking", "code": "60122800" },
      { "name": "Maskmaking forms", "code": "60122801" },
      { "name": "Beads or beading accessories", "code": "60122900" },
      { "name": "Seed beads", "code": "60122901" },
      { "name": "Pony beads", "code": "60122902" },
      { "name": "Wood beads", "code": "60122903" },
      { "name": "Straw beads", "code": "60122904" },
      { "name": "Ceramic beads", "code": "60122905" },
      { "name": "Glass beads", "code": "60122906" },
      { "name": "Assorted or decorative beads", "code": "60122907" },
      { "name": "Bead accessories", "code": "60122908" },
      { "name": "Plastic beads", "code": "60122909" },
      { "name": "Foam crafts", "code": "60123000" },
      { "name": "Styrofoam shapes", "code": "60123001" },
      { "name": "Tools for foam crafts", "code": "60123002" },
      { "name": "Chenille stem crafts", "code": "60123100" },
      { "name": "Jumbo chenille stems", "code": "60123101" },
      { "name": "Cotton chenille stems", "code": "60123102" },
      { "name": "Bumps chenille stems", "code": "60123103" },
      { "name": "Ribbon making materials", "code": "60123200" },
      { "name": "Paper ribbons", "code": "60123201" },
      { "name": "Silk ribbons", "code": "60123202" },
      { "name": "Synthetic ribbons", "code": "60123203" },
      { "name": "Decorative ribbons", "code": "60123204" },
      { "name": "Craft poms", "code": "60123300" },
      { "name": "Acrylic craft poms", "code": "60123301" },
      { "name": "Glitter craft poms", "code": "60123302" },
      { "name": "Craft pom beads", "code": "60123303" },
      { "name": "Wiggle eyes materials", "code": "60123400" },
      { "name": "Non self adhesive wiggle eyes", "code": "60123401" },
      { "name": "Self adhesive wiggle eyes", "code": "60123402" },
      { "name": "Decorative wiggle eyes", "code": "60123403" },
      { "name": "Leather craft materials", "code": "60123500" },
      { "name": "Leather or leather lacing materials", "code": "60123501" },
      { "name": "Leather accessories", "code": "60123502" },
      { "name": "Glitter", "code": "60123600" },
      { "name": "Glitter glue", "code": "60123601" },
      { "name": "Glitter dots", "code": "60123602" },
      { "name": "Glitter jewels", "code": "60123603" },
      { "name": "Plastic glitter", "code": "60123604" },
      { "name": "Irridescent glitter", "code": "60123605" },
      { "name": "Metallic glitter", "code": "60123606" },
      { "name": "Macrame craft materials and accessories", "code": "60123700" },
      { "name": "Macrame cord", "code": "60123701" },
      { "name": "Macrame beads", "code": "60123702" },
      { "name": "Macrame accessories", "code": "60123703" },
      { "name": "Marbling supplies and accessories", "code": "60123800" },
      { "name": "Marbling inks", "code": "60123801" },
      { "name": "Marbling accessories", "code": "60123802" },
      { "name": "Sequins and trims", "code": "60123900" },
      { "name": "Decorative sequins or trims", "code": "60123901" },
      { "name": "Cork craft supplies and accessories", "code": "60124000" },
      { "name": "Cork sheets", "code": "60124001" },
      { "name": "Cork stoppers or accessories", "code": "60124002" },
      {
        "name": "Multicultural project materials and accessories",
        "code": "60124100"
      },
      { "name": "Multicultural painting products", "code": "60124101" },
      { "name": "Multicultural crafts products", "code": "60124102" },
      { "name": "Cross curricular projects", "code": "60124200" },
      { "name": "Mylar sculptures", "code": "60124201" },
      {
        "name": "Clay and modeling compounds and ceramics equipment and accessories",
        "code": "60124300"
      },
      { "name": "Moist kiln fired clay", "code": "60124301" },
      { "name": "Dry kiln fired clay", "code": "60124302" },
      { "name": "Kiln furniture", "code": "60124303" },
      { "name": "Kilns for firing ceramics", "code": "60124304" },
      { "name": "Kiln accessories for firing ceramics", "code": "60124305" },
      { "name": "Potters wheels for hand made ceramics", "code": "60124306" },
      { "name": "Extruders for modeling materials", "code": "60124307" },
      { "name": "Cones for firing kilns", "code": "60124308" },
      { "name": "Pottery batts", "code": "60124309" },
      { "name": "Decorating wheels for pottery", "code": "60124310" },
      { "name": "Clay or modeling tools", "code": "60124311" },
      { "name": "Fired ceramic tiles", "code": "60124312" },
      { "name": "Clay storage containers", "code": "60124313" },
      {
        "name": "Plasticized non hardening modeling compounds",
        "code": "60124314"
      },
      {
        "name": "Oilbased nonhardening modeling compounds",
        "code": "60124315"
      },
      { "name": "Air dry clay or modeling compounds", "code": "60124316" },
      { "name": "Modeling dough", "code": "60124317" },
      { "name": "Papier mache", "code": "60124318" },
      { "name": "Specialty modeling compounds", "code": "60124319" },
      { "name": "Plaster compounds", "code": "60124320" },
      {
        "name": "Oven hardening clay or modeling compounds",
        "code": "60124321"
      },
      { "name": "Plastic modeling compounds", "code": "60124322" },
      {
        "name": "Casts or molds for shaping modeling compounds",
        "code": "60124323"
      },
      { "name": "Clay modeling kits", "code": "60124324" },
      { "name": "Paper clay", "code": "60124325" },
      { "name": "Art metals", "code": "60124400" },
      { "name": "Copper tooling foil", "code": "60124401" },
      { "name": "Aluminum tooling foil", "code": "60124402" },
      { "name": "Aluminum wire", "code": "60124403" },
      { "name": "Brass tooling foil", "code": "60124404" },
      { "name": "Silver sheets or plates", "code": "60124406" },
      { "name": "Silver wire", "code": "60124407" },
      { "name": "Pewter pellets or shots", "code": "60124408" },
      { "name": "Pewter ingots", "code": "60124409" },
      { "name": "Nu gold sheets plates", "code": "60124410" },
      { "name": "Nu gold wire", "code": "60124411" },
      { "name": "Galvanized stovepipe wire", "code": "60124412" },
      { "name": "Sculpture supplies and accessories", "code": "60124500" },
      { "name": "Plaster wrap", "code": "60124501" },
      { "name": "Casting resins", "code": "60124502" },
      { "name": "Sculpture accessories", "code": "60124503" },
      { "name": "Puzzle racks", "code": "60124504" },
      { "name": "Bubbles or accessories", "code": "60124505" },
      {
        "name": "Plastic sand or water tools or molds or toys",
        "code": "60124506"
      },
      { "name": "Play sand", "code": "60124507" },
      {
        "name": "Sand or water tables or activity centers",
        "code": "60124508"
      },
      { "name": "Vehicle sets", "code": "60124509" },
      { "name": "Waterway sets", "code": "60124510" },
      { "name": "Play tools or play tool kits", "code": "60124511" },
      { "name": "Scooter boards", "code": "60124512" },
      { "name": "Beanbags", "code": "60124513" },
      { "name": "Tactile toys", "code": "60124514" },
      { "name": "Cognitive toys", "code": "60124515" },
      {
        "name": "Musical Instruments and parts and accessories",
        "code": "60130000"
      },
      { "name": "Keyboard instruments", "code": "60131000" },
      { "name": "Pianos", "code": "60131001" },
      { "name": "Accordions", "code": "60131002" },
      { "name": "Musical organs", "code": "60131003" },
      { "name": "Celestas", "code": "60131004" },
      { "name": "Melodion", "code": "60131005" },
      { "name": "Synthesizer", "code": "60131006" },
      { "name": "Melodica", "code": "60131007" },
      { "name": "Brass instruments", "code": "60131100" },
      { "name": "Trumpets", "code": "60131101" },
      { "name": "Trombones", "code": "60131102" },
      { "name": "Sousaphones", "code": "60131103" },
      { "name": "Saxophones", "code": "60131104" },
      { "name": "Whistle", "code": "60131105" },
      { "name": "Bugles", "code": "60131106" },
      { "name": "Saxhorns", "code": "60131107" },
      { "name": "French horns", "code": "60131108" },
      { "name": "Mellophones", "code": "60131109" },
      { "name": "Alto horns", "code": "60131110" },
      { "name": "Baritone horns", "code": "60131111" },
      { "name": "Flugel horns", "code": "60131112" },
      { "name": "Nabal", "code": "60131113" },
      { "name": "Tuba", "code": "60131114" },
      { "name": "Euphonium", "code": "60131115" },
      { "name": "Tubaphone", "code": "60131116" },
      { "name": "Woodwind instruments", "code": "60131200" },
      { "name": "Clarinets", "code": "60131201" },
      { "name": "Oboes", "code": "60131202" },
      { "name": "Musical flutes", "code": "60131203" },
      { "name": "Piccoloes", "code": "60131204" },
      { "name": "Musical cornets", "code": "60131205" },
      { "name": "Bagpipes", "code": "60131206" },
      { "name": "Harmonicas", "code": "60131207" },
      { "name": "Kazoos", "code": "60131208" },
      { "name": "English horns", "code": "60131209" },
      { "name": "Ocarinas", "code": "60131210" },
      { "name": "Daegeum", "code": "60131211" },
      { "name": "Danso", "code": "60131212" },
      { "name": "Toongso", "code": "60131213" },
      { "name": "Taepyeongso", "code": "60131214" },
      { "name": "Jeok", "code": "60131215" },
      { "name": "Dangjeok", "code": "60131216" },
      { "name": "Dangpirie", "code": "60131217" },
      { "name": "Sepiri", "code": "60131218" },
      { "name": "So", "code": "60131219" },
      { "name": "Yak", "code": "60131220" },
      { "name": "Junggeum", "code": "60131221" },
      { "name": "Ji", "code": "60131222" },
      { "name": "Sogeum", "code": "60131223" },
      { "name": "Hyangpiri", "code": "60131224" },
      { "name": "Saenghwang", "code": "60131225" },
      { "name": "Hun", "code": "60131226" },
      { "name": "Nagak", "code": "60131227" },
      { "name": "Bassoon", "code": "60131228" },
      { "name": "Musette", "code": "60131229" },
      { "name": "Contra bassoon", "code": "60131230" },
      { "name": "Sarrusophone", "code": "60131231" },
      { "name": "Siren horn", "code": "60131232" },
      { "name": "Recorder", "code": "60131233" },
      { "name": "Clave", "code": "60131234" },
      { "name": "Quena", "code": "60131235" },
      { "name": "Quenacho", "code": "60131236" },
      { "name": "Pan pipe", "code": "60131237" },
      { "name": "String instruments", "code": "60131300" },
      { "name": "Harpsichords", "code": "60131301" },
      { "name": "Clavichords", "code": "60131302" },
      { "name": "Guitars", "code": "60131303" },
      { "name": "Violins", "code": "60131304" },
      { "name": "Harps", "code": "60131305" },
      { "name": "Banjoes", "code": "60131306" },
      { "name": "Mandolins", "code": "60131307" },
      { "name": "Violoncellos", "code": "60131308" },
      { "name": "Basses", "code": "60131309" },
      { "name": "Gayageum", "code": "60131310" },
      { "name": "Geomungo", "code": "60131311" },
      { "name": "Ajaeng", "code": "60131312" },
      { "name": "Geum", "code": "60131313" },
      { "name": "Dangbipa", "code": "60131314" },
      { "name": "Daejaeng", "code": "60131315" },
      { "name": "Seul", "code": "60131316" },
      { "name": "Wagonghu", "code": "60131317" },
      { "name": "Hyangbipa", "code": "60131318" },
      { "name": "Haegeum", "code": "60131319" },
      { "name": "Sogonghu", "code": "60131320" },
      { "name": "Sugonghu", "code": "60131321" },
      { "name": "Yangkum", "code": "60131322" },
      { "name": "Wolgeum", "code": "60131323" },
      { "name": "Ukelele", "code": "60131324" },
      { "name": "Viola", "code": "60131325" },
      { "name": "Morin khuur national instrument", "code": "60131326" },
      { "name": "Yochin", "code": "60131327" },
      { "name": "Yatga", "code": "60131328" },
      { "name": "Shanz", "code": "60131329" },
      { "name": "Zither", "code": "60131330" },
      { "name": "Charango", "code": "60131331" },
      { "name": "Bandurria", "code": "60131332" },
      { "name": "Angel", "code": "60131333" },
      { "name": "Percussion instruments", "code": "60131400" },
      { "name": "Cymbals", "code": "60131401" },
      { "name": "Bells", "code": "60131402" },
      { "name": "Tambourines", "code": "60131403" },
      { "name": "Castanets", "code": "60131404" },
      { "name": "Drums", "code": "60131405" },
      { "name": "Xylophones", "code": "60131406" },
      { "name": "Vibraphones", "code": "60131407" },
      { "name": "Jing", "code": "60131408" },
      { "name": "Janggu", "code": "60131409" },
      { "name": "Kkwaengwari", "code": "60131410" },
      { "name": "Buk", "code": "60131411" },
      { "name": "Galgo", "code": "60131412" },
      { "name": "Geongo", "code": "60131413" },
      { "name": "Gyobanggo", "code": "60131414" },
      { "name": "Banghyang", "code": "60131415" },
      { "name": "Sakko", "code": "60131416" },
      { "name": "Sogo", "code": "60131417" },
      { "name": "Yonggo", "code": "60131418" },
      { "name": "Ulla", "code": "60131419" },
      { "name": "Eunggo", "code": "60131420" },
      { "name": "Jabara", "code": "60131421" },
      { "name": "Jeolgo", "code": "60131422" },
      { "name": "Jwago", "code": "60131423" },
      { "name": "Jingo", "code": "60131424" },
      { "name": "Teukkyeong", "code": "60131425" },
      { "name": "Teukjong", "code": "60131426" },
      { "name": "Pyeongyeong", "code": "60131427" },
      { "name": "Pyeonjong", "code": "60131428" },
      { "name": "Eo", "code": "60131429" },
      { "name": "Nogo", "code": "60131430" },
      { "name": "Nodo", "code": "60131431" },
      { "name": "Noego", "code": "60131432" },
      { "name": "Noedo", "code": "60131433" },
      { "name": "Bak", "code": "60131434" },
      { "name": "Bu", "code": "60131435" },
      { "name": "Yeonggo", "code": "60131436" },
      { "name": "Yeongdo", "code": "60131437" },
      { "name": "Junggo", "code": "60131438" },
      { "name": "Chuk", "code": "60131439" },
      { "name": "Mugo", "code": "60131440" },
      { "name": "Glockenspiel", "code": "60131441" },
      { "name": "Maracas", "code": "60131442" },
      { "name": "Cabasas", "code": "60131443" },
      { "name": "Timpanies", "code": "60131444" },
      { "name": "Bongoes", "code": "60131445" },
      { "name": "Congas", "code": "60131446" },
      { "name": "Bell lyrases", "code": "60131447" },
      { "name": "Marimbas", "code": "60131448" },
      { "name": "Instrumental triangle", "code": "60131449" },
      { "name": "Maracases", "code": "60131450" },
      { "name": "Guiro", "code": "60131451" },
      { "name": "Musical shaker", "code": "60131452" },
      { "name": "Wood gong", "code": "60131453" },
      { "name": "Marching bells", "code": "60131454" },
      { "name": "Bronze or brass gong", "code": "60131455" },
      { "name": "Tom tom", "code": "60131456" },
      { "name": "Timbal", "code": "60131457" },
      { "name": "Rainstick", "code": "60131458" },
      { "name": "Tinya", "code": "60131459" },
      { "name": "Ton ton", "code": "60131460" },
      { "name": "Woodbox", "code": "60131461" },
      { "name": "Vibraslap", "code": "60131462" },
      { "name": "Ronroco", "code": "60131463" },
      { "name": "Rattle, matraca", "code": "60131464" },
      { "name": "Rattle, cascabel", "code": "60131465" },
      { "name": "Donkey jawbone", "code": "60131466" },
      { "name": "Cajon", "code": "60131467" },
      {
        "name": "Musical instrument parts and accessories",
        "code": "60131500"
      },
      { "name": "Metronomes", "code": "60131501" },
      { "name": "Reeds", "code": "60131502" },
      { "name": "Instrument strings or picks", "code": "60131503" },
      { "name": "Tuning pins", "code": "60131504" },
      {
        "name": "Musical instrument stands or sheet holders",
        "code": "60131505"
      },
      { "name": "Accessories for stringed instruments", "code": "60131506" },
      { "name": "Percussion instrument accessory", "code": "60131507" },
      { "name": "Music boxes or mechanisms", "code": "60131508" },
      { "name": "Mouthpieces", "code": "60131509" },
      {
        "name": "Musical instrument pouches or cases or accessories",
        "code": "60131510"
      },
      { "name": "Mutes", "code": "60131511" },
      { "name": "Tuning bars", "code": "60131512" },
      { "name": "Conductors batons", "code": "60131513" },
      { "name": "Piccolo pads", "code": "60131514" },
      { "name": "Musical instrument effects unit", "code": "60131515" },
      { "name": "Electronic instrument tuner", "code": "60131516" },
      {
        "name": "Musical instrument case or protective carry bag",
        "code": "60131517"
      },
      { "name": "Musical instrument strap or harness", "code": "60131518" },
      { "name": "Musical instrument stand", "code": "60131519" },
      { "name": "Drum stick", "code": "60131520" },
      { "name": "Musical instrument sets", "code": "60131600" },
      { "name": "Rhythm band sets", "code": "60131601" },
      { "name": "Alternative sounds musical instruments", "code": "60131700" },
      { "name": "Boomwhackers", "code": "60131701" },
      { "name": "Disco taps", "code": "60131702" },
      { "name": "Horses hoofs", "code": "60131703" },
      { "name": "Music and dance accessories", "code": "60131800" },
      { "name": "Dancing scarves", "code": "60131801" },
      { "name": "Rhythm sticks or lummi sticks", "code": "60131802" },
      { "name": "Rhythm wands or hoops", "code": "60131803" },
      { "name": "Toys and games", "code": "60140000" },
      { "name": "Toys", "code": "60141000" },
      { "name": "Toy balloons or balls", "code": "60141001" },
      { "name": "Dolls", "code": "60141002" },
      { "name": "Doll houses", "code": "60141003" },
      { "name": "Stuffed animals or puppets", "code": "60141004" },
      { "name": "Playhouses", "code": "60141005" },
      { "name": "Building blocks", "code": "60141006" },
      { "name": "Riding toys", "code": "60141007" },
      { "name": "Pull toys", "code": "60141008" },
      { "name": "Childrens science kits", "code": "60141009" },
      { "name": "Toy vehicles", "code": "60141010" },
      { "name": "Toy trains", "code": "60141011" },
      { "name": "Inflatable toys", "code": "60141012" },
      { "name": "Doll parts or accessories", "code": "60141013" },
      { "name": "Yo yos", "code": "60141014" },
      { "name": "Kites", "code": "60141015" },
      { "name": "Pogs", "code": "60141016" },
      { "name": "Kaleidoscopes", "code": "60141017" },
      { "name": "Pom poms", "code": "60141018" },
      { "name": "Pinatas", "code": "60141019" },
      { "name": "Boomerangs", "code": "60141020" },
      { "name": "Flying discs", "code": "60141021" },
      { "name": "Toy pails", "code": "60141022" },
      { "name": "Bath toys", "code": "60141023" },
      { "name": "Rattles", "code": "60141024" },
      { "name": "Toy weapons", "code": "60141025" },
      { "name": "Tops", "code": "60141026" },
      { "name": "Games", "code": "60141100" },
      { "name": "Educational games", "code": "60141101" },
      { "name": "Board games", "code": "60141102" },
      { "name": "Playing cards", "code": "60141103" },
      { "name": "Video games", "code": "60141104" },
      { "name": "Puzzles", "code": "60141105" },
      { "name": "Dice", "code": "60141106" },
      { "name": "Bingo", "code": "60141107" },
      { "name": "Classic games", "code": "60141108" },
      { "name": "Collaborative games", "code": "60141109" },
      { "name": "Strategy games", "code": "60141110" },
      { "name": "Game accessories", "code": "60141111" },
      { "name": "Game books", "code": "60141112" },
      { "name": "Lotto games", "code": "60141113" },
      { "name": "Memory games", "code": "60141114" },
      { "name": "Game kits", "code": "60141115" },
      { "name": "Baduk or go game and accessories", "code": "60141116" },
      { "name": "Yut or four stick game and accessories", "code": "60141117" },
      { "name": "Janggi game and accessories", "code": "60141118" },
      { "name": "Active play equipment and accessories", "code": "60141200" },
      { "name": "Balance or gross motor equipment", "code": "60141201" },
      { "name": "Ball pools or accessories", "code": "60141202" },
      { "name": "Play houses or huts", "code": "60141203" },
      { "name": "Trikes or wagons", "code": "60141204" },
      { "name": "Soft play centers", "code": "60141205" },
      { "name": "Childrens blocks and building systems", "code": "60141300" },
      { "name": "Construction sets", "code": "60141302" },
      { "name": "Playmats", "code": "60141303" },
      { "name": "Railplay systems or accessories", "code": "60141304" },
      { "name": "Unit blocks", "code": "60141305" },
      { "name": "Play vehicles", "code": "60141306" },
      { "name": "Play animals", "code": "60141307" },
      { "name": "Dramatic play equipment and accessories", "code": "60141400" },
      { "name": "Costumes or accessories", "code": "60141401" },
      { "name": "Dress up Centers", "code": "60141402" },
      { "name": "Housekeeping units or accessories", "code": "60141403" },
      { "name": "Play food dishes or accessories", "code": "60141404" },
      { "name": "Pretend play kits or supplies", "code": "60141405" }
    ]
  },
  {
    "name": "Financial Instruments, Products, Contracts and Agreements",
    "code": "64000000",
    "subcategories": [
      { "name": "Bank offered products ", "code": "64100000" },
      { "name": "Time deposit accounts", "code": "64101500" },
      { "name": "Certificate of deposit CD", "code": "64101501" },
      { "name": "Simple savings account", "code": "64101502" },
      { "name": "Demand deposit accounts", "code": "64101600" },
      { "name": "Interest bearing checking account", "code": "64101601" },
      { "name": "Money market account", "code": "64101602" },
      { "name": "Simple checking account", "code": "64101603" },
      {
        "name": "Electronic fund transfer and payment products",
        "code": "64101700"
      },
      { "name": "Bill paying application", "code": "64101701" },
      { "name": "Currency exchange application", "code": "64101702" },
      { "name": "Money transfer application", "code": "64101703" },
      { "name": "Over draft protection", "code": "64101704" },
      { "name": "Card based revolving credit products", "code": "64101800" },
      { "name": "Secured credit card", "code": "64101801" },
      { "name": "Unsecured credit card", "code": "64101802" },
      { "name": "Account based credit products", "code": "64101900" },
      { "name": "Automotive loan", "code": "64101901" },
      { "name": "Equipment loan", "code": "64101902" },
      { "name": "Forfaiting loan", "code": "64101903" },
      { "name": "Home equity line of credit", "code": "64101904" },
      { "name": "Home equity loan", "code": "64101905" },
      { "name": "Inventory loan", "code": "64101906" },
      { "name": "Mortgage loan", "code": "64101907" },
      { "name": "Receivable loan", "code": "64101908" },
      { "name": "Unsecured loan", "code": "64101909" },
      { "name": "Unsecured revolving line of credit", "code": "64101910" },
      { "name": "Lock box", "code": "64101911" },
      { "name": "Safe deposit box", "code": "64101912" },
      { "name": "Investment accounts", "code": "64102000" },
      { "name": "Education savings account", "code": "64102001" },
      { "name": "Health savings account ", "code": "64102002" },
      {
        "name": "Individual retirement account IRA, corporate",
        "code": "64102003"
      },
      {
        "name": "Individual retirement account IRA, personal",
        "code": "64102004"
      },
      {
        "name": "Individual retirement account IRA, public sector",
        "code": "64102005"
      },
      { "name": "Simplified employee pension SEP account", "code": "64102006" },
      { "name": "Basic investment account", "code": "64102007" },
      { "name": "Managed investment account", "code": "64102008" },
      { "name": "Securities", "code": "64110000" },
      { "name": "Equity based securities", "code": "64111500" },
      { "name": "Common or ordinary stock ", "code": "64111501" },
      { "name": "Preferred stock", "code": "64111502" },
      { "name": "Preference stock", "code": "64111503" },
      { "name": "Convertible stock", "code": "64111504" },
      { "name": "Convertible preferred stock", "code": "64111505" },
      { "name": "Convertible preference stock", "code": "64111506" },
      { "name": "Unit trust unit", "code": "64111507" },
      { "name": "Debt based securities ", "code": "64111600" },
      { "name": "Bond", "code": "64111601" },
      { "name": "Convertible bond", "code": "64111602" },
      { "name": "Bond with warrant attached", "code": "64111603" },
      { "name": "Medium- term note", "code": "64111604" },
      { "name": "Money market instrument", "code": "64111605" },
      { "name": "Asset-backed security", "code": "64111606" },
      { "name": "Mortgage-backed security", "code": "64111607" },
      { "name": "Subordinated debt or debenture", "code": "64111608" },
      { "name": "Derivative securities", "code": "64111700" },
      { "name": "Forward contract, financial", "code": "64111701" },
      { "name": "Forward contract, commodity", "code": "64111702" },
      { "name": "Futures contract, financial", "code": "64111703" },
      { "name": "Futures contract, commodity", "code": "64111704" },
      { "name": "Carbon credit permit or certificate", "code": "64111705" },
      { "name": "Certified emission reduction CER unit", "code": "64111706" },
      { "name": "Renewable energy credit REC", "code": "64111707" },
      { "name": "Spot price contract", "code": "64111708" },
      { "name": "Swap contract", "code": "64111709" },
      { "name": "Call option contract", "code": "64111710" },
      { "name": "Put option contract", "code": "64111711" },
      { "name": "Rainbow option contract", "code": "64111712" },
      { "name": "Entitlements or rights", "code": "64111800" },
      { "name": "Allotment or bonus right", "code": "64111801" },
      { "name": "Subscription right", "code": "64111802" },
      { "name": "Purchase right", "code": "64111803" },
      { "name": "Warrant", "code": "64111804" },
      { "name": "Structured financial products", "code": "64111900" },
      {
        "name": "Principal protected structured financial product",
        "code": "64111901"
      },
      {
        "name": "Principal unprotected structured financial product",
        "code": "64111902"
      },
      { "name": "Exchange traded fund ETF", "code": "64111903" },
      { "name": "Single structured product", "code": "64111904" },
      { "name": "Over the rainbow structured product", "code": "64111905" },
      { "name": "Variable annuity", "code": "64111906" },
      { "name": "Insurable interest contracts", "code": "64120000" },
      { "name": "Property insurance contracts", "code": "64121500" },
      { "name": "Aircraft insurance policy", "code": "64121501" },
      { "name": "Boiler and machinery policy", "code": "64121502" },
      { "name": "Commercial automotive insurance policy", "code": "64121503" },
      { "name": "Commercial multiple peril policy", "code": "64121504" },
      { "name": "Farm owners multiple peril policy", "code": "64121505" },
      { "name": "Flood insurance policy", "code": "64121506" },
      {
        "name": "Fraternal benefit society insurance policy",
        "code": "64121507"
      },
      { "name": "Homeowners insurance policy", "code": "64121508" },
      { "name": "Inland marine insurance policy", "code": "64121509" },
      { "name": "Personal automobile insurance policy", "code": "64121510" },
      { "name": "Ocean marine insurance policy", "code": "64121511" },
      { "name": "Surety policy", "code": "64121512" },
      { "name": "Title insurance policy", "code": "64121513" },
      { "name": "Warranty policy", "code": "64121514" },
      { "name": "Life insurance contracts", "code": "64122000" },
      { "name": "Credit life insurance policy", "code": "64122001" },
      { "name": "Fraternal life insurance policy", "code": "64122002" },
      { "name": "Group life insurance policy", "code": "64122003" },
      { "name": "Term life insurance policy", "code": "64122004" },
      { "name": "Whole life insurance policy", "code": "64122005" },
      { "name": "Fixed annuity policy", "code": "64122006" },
      { "name": "Modified guaranteed annuity policy", "code": "64122007" },
      { "name": "General agreements and contracts", "code": "64130000" },
      { "name": "Agreements", "code": "64131500" },
      { "name": "Basic agreement", "code": "64131501" },
      { "name": "Basic ordering agreement", "code": "64131502" },
      { "name": "Contracts", "code": "64131600" },
      { "name": "Fixed price contract", "code": "64131601" },
      { "name": "Cost reimbursement contract", "code": "64131602" },
      { "name": "Incentive contract", "code": "64131603" },
      { "name": "Indefinite delivery contract", "code": "64131604" },
      { "name": "Time and materials contract", "code": "64131605" },
      { "name": "Labor hour contract", "code": "64131606" },
      { "name": "Letter contract", "code": "64131607" },
      { "name": "Governmental property right conferrals", "code": "64140000" },
      {
        "name": "International intellectual property protection products",
        "code": "64141500"
      },
      { "name": "Patent cooperation treaty patent", "code": "64141501" },
      {
        "name": "International trademark system trademark",
        "code": "64141502"
      },
      {
        "name": "International servicemark system servicemark",
        "code": "64141503"
      },
      { "name": "International copyright", "code": "64141504" },
      {
        "name": "National intellectual property protection products",
        "code": "64141600"
      },
      { "name": "Nation based patent", "code": "64141601" },
      { "name": "Nation based trademark", "code": "64141602" },
      { "name": "Nation based servicemark", "code": "64141603" },
      { "name": "Nation based copyright", "code": "64141604" }
    ]
  },
  {
    "name": "Farming and Fishing and Forestry and Wildlife Contracting Services",
    "code": "70000000",
    "subcategories": [
      { "name": "Fisheries and aquaculture", "code": "70100000" },
      { "name": "Fisheries operations", "code": "70101500" },
      { "name": "Commercial fishing operations", "code": "70101501" },
      { "name": "Fishing port services", "code": "70101502" },
      { "name": "Fishing onshore facilities", "code": "70101503" },
      { "name": "Deep sea fishing operations", "code": "70101504" },
      { "name": "Sonar fishing", "code": "70101505" },
      { "name": "Whaling", "code": "70101506" },
      { "name": "Trawling", "code": "70101507" },
      { "name": "Line fishing", "code": "70101508" },
      { "name": "Seine operations", "code": "70101509" },
      { "name": "Fishing nets", "code": "70101510" },
      { "name": "Fisheries oversight", "code": "70101600" },
      {
        "name": "Fishery information or documentation services",
        "code": "70101601"
      },
      {
        "name": "Fishery research or experimentation services",
        "code": "70101602"
      },
      { "name": "Fishery data collection or distribution", "code": "70101603" },
      { "name": "Fishery commercial management", "code": "70101604" },
      { "name": "Fishing fleet management", "code": "70101605" },
      { "name": "Fishing cooperatives", "code": "70101606" },
      {
        "name": "Fishery resources protection or conservation",
        "code": "70101607"
      },
      { "name": "Fishery industry and technology", "code": "70101700" },
      { "name": "Fishing technology services", "code": "70101701" },
      { "name": "Fishery by products production services", "code": "70101702" },
      { "name": "Fish production", "code": "70101703" },
      { "name": "Fish storage", "code": "70101704" },
      { "name": "Fisheries resources", "code": "70101800" },
      { "name": "Inland water fishery resources", "code": "70101801" },
      { "name": "Fish pond resources", "code": "70101802" },
      { "name": "Fish hatcheries", "code": "70101803" },
      { "name": "Fish ranches", "code": "70101804" },
      { "name": "Fishery by product resources", "code": "70101805" },
      { "name": "Fisheries resource evaluation", "code": "70101806" },
      { "name": "Aquaculture", "code": "70101900" },
      { "name": "Mariculture", "code": "70101901" },
      { "name": "Ostreiculture", "code": "70101902" },
      { "name": "Shellfish culture", "code": "70101903" },
      { "name": "Shrimp farming", "code": "70101904" },
      { "name": "Fish farming", "code": "70101905" },
      { "name": "Horticulture", "code": "70110000" },
      { "name": "Plants and ornamental trees", "code": "70111500" },
      {
        "name": "Planting services or ornamental plant or bush or tree",
        "code": "70111501"
      },
      {
        "name": "Pruning services or ornamental plant or bush",
        "code": "70111502"
      },
      { "name": "Tree trimming services", "code": "70111503" },
      { "name": "Bracing services", "code": "70111504" },
      { "name": "Tree surgery services", "code": "70111505" },
      { "name": "Arborist services", "code": "70111506" },
      {
        "name": "Removal services or ornamental plant or bush or tree",
        "code": "70111507"
      },
      {
        "name": "Plants or ornamental tree spraying services",
        "code": "70111508"
      },
      { "name": "Flowering plants", "code": "70111600" },
      { "name": "Planting services", "code": "70111601" },
      { "name": "Nursery services", "code": "70111602" },
      { "name": "Floriculture services", "code": "70111603" },
      { "name": "Parks and gardens and orchards", "code": "70111700" },
      {
        "name": "Orchard management or maintenance services",
        "code": "70111701"
      },
      {
        "name": "Vineyard management or maintenance services",
        "code": "70111702"
      },
      { "name": "Garden planting or maintenance services", "code": "70111703" },
      { "name": "Horticultural counseling services", "code": "70111704" },
      { "name": "Cemetery upkeep services", "code": "70111705" },
      { "name": "Lawn care services", "code": "70111706" },
      { "name": "Highway lawn maintenance services", "code": "70111707" },
      { "name": "Mulching services", "code": "70111708" },
      { "name": "Seeding services", "code": "70111709" },
      { "name": "Mowing services", "code": "70111710" },
      { "name": "Sprigging services", "code": "70111711" },
      { "name": "Parks or gardens spraying services", "code": "70111712" },
      {
        "name": "Parks management or maintenance services",
        "code": "70111713"
      },
      { "name": "Livestock services", "code": "70120000" },
      { "name": "Dairying", "code": "70121500" },
      { "name": "Dairy herd management", "code": "70121501" },
      { "name": "Dairy industry development", "code": "70121502" },
      { "name": "Dairy technology", "code": "70121503" },
      { "name": "Dairy laboratories services", "code": "70121504" },
      { "name": "Dairy farm in house processing", "code": "70121505" },
      { "name": "Livestock industry", "code": "70121600" },
      { "name": "Livestock breeding", "code": "70121601" },
      { "name": "Livestock genetics services", "code": "70121602" },
      { "name": "Sericulture", "code": "70121603" },
      { "name": "Animal husbandry", "code": "70121604" },
      { "name": "Farm rearing systems", "code": "70121605" },
      { "name": "Poultry production services", "code": "70121606" },
      { "name": "Small animal breeding services", "code": "70121607" },
      { "name": "Bovine production services", "code": "70121608" },
      { "name": "Apiculture", "code": "70121610" },
      { "name": "Livestock management", "code": "70121700" },
      { "name": "Livestock selection", "code": "70121701" },
      { "name": "Livestock showing services", "code": "70121702" },
      { "name": "Livestock slaughtering services", "code": "70121703" },
      { "name": "Herd management", "code": "70121704" },
      { "name": "Livestock breeding or grooming services", "code": "70121705" },
      {
        "name": "Livestock identification and recording service",
        "code": "70121706"
      },
      { "name": "Pets industry", "code": "70121800" },
      { "name": "Pet breeding services", "code": "70121801" },
      { "name": "Pet grooming services", "code": "70121802" },
      { "name": "Kennels services", "code": "70121803" },
      { "name": "Pasture and range services", "code": "70121900" },
      { "name": "Pasture improvement", "code": "70121901" },
      { "name": "Range management", "code": "70121902" },
      { "name": "Range research", "code": "70121903" },
      { "name": "Animal health", "code": "70122000" },
      { "name": "Animal nutrition", "code": "70122001" },
      { "name": "Animal disease control", "code": "70122002" },
      { "name": "Animal trypanosomiasis", "code": "70122003" },
      { "name": "Foot or mouth control services", "code": "70122004" },
      {
        "name": "Animal health preventive medication services",
        "code": "70122005"
      },
      { "name": "Animal vaccination services", "code": "70122006" },
      { "name": "Veterinary administration", "code": "70122007" },
      { "name": "Veterinary laboratory technology", "code": "70122008" },
      { "name": "Animal hospital services", "code": "70122009" },
      { "name": "Animal health information services", "code": "70122010" },
      { "name": "Animal control and welfare services", "code": "70123000" },
      {
        "name": "Dog control, containment and welfare service",
        "code": "70123001"
      },
      {
        "name": "Land and soil preparation and management and protection",
        "code": "70130000"
      },
      { "name": "Land and soil protection", "code": "70131500" },
      {
        "name": "Desertification assessment or control services",
        "code": "70131501"
      },
      {
        "name": "Soil conservation or protection services",
        "code": "70131502"
      },
      { "name": "Erosion control services", "code": "70131503" },
      { "name": "Sand dune fixation", "code": "70131504" },
      { "name": "Soil conditioning", "code": "70131505" },
      { "name": "Soil improvement", "code": "70131506" },
      { "name": "Land and soil preparation", "code": "70131600" },
      { "name": "Fertilizer application services", "code": "70131601" },
      { "name": "Lime spreading services", "code": "70131602" },
      { "name": "Plowing services", "code": "70131603" },
      { "name": "Seed bed preparation services", "code": "70131604" },
      { "name": "Soil chemical treatment services", "code": "70131605" },
      { "name": "Land and soil management", "code": "70131700" },
      { "name": "Land use planning", "code": "70131701" },
      { "name": "Land evaluation", "code": "70131702" },
      { "name": "Land reclamation", "code": "70131703" },
      {
        "name": "Agro ecological zones assessment or planning",
        "code": "70131704"
      },
      { "name": "Soil classification", "code": "70131705" },
      { "name": "Watershed management", "code": "70131706" },
      { "name": "Soil fertility analysis", "code": "70131707" },
      { "name": "Pedology", "code": "70131708" },
      {
        "name": "Crop production and management and protection",
        "code": "70140000"
      },
      { "name": "Crop production", "code": "70141500" },
      { "name": "Seed production services", "code": "70141501" },
      { "name": "Hydroponics", "code": "70141502" },
      { "name": "Grass or fodder production", "code": "70141503" },
      { "name": "Aromatic plants production", "code": "70141504" },
      { "name": "Beverage crops production", "code": "70141505" },
      { "name": "Cocoa production", "code": "70141506" },
      { "name": "Sugarbeet or sugarcane production", "code": "70141507" },
      { "name": "Nut production", "code": "70141508" },
      { "name": "Essential oil crops production", "code": "70141509" },
      { "name": "Fiber crops production", "code": "70141510" },
      { "name": "Fruit production", "code": "70141511" },
      { "name": "Grain or legume production", "code": "70141512" },
      { "name": "Insecticidal plants production", "code": "70141513" },
      { "name": "Medicinal plants production", "code": "70141514" },
      { "name": "Roots or tubers production", "code": "70141515" },
      { "name": "Cereals production", "code": "70141516" },
      { "name": "Rubber plant production", "code": "70141517" },
      { "name": "Spice crops production", "code": "70141518" },
      { "name": "Tobacco crop production", "code": "70141519" },
      { "name": "Vegetable production", "code": "70141520" },
      { "name": "Crop protection", "code": "70141600" },
      { "name": "Crop spraying services", "code": "70141601" },
      { "name": "Biological control services", "code": "70141602" },
      { "name": "Weed control services", "code": "70141603" },
      { "name": "Herbicide services", "code": "70141604" },
      { "name": "Integrated pest management services", "code": "70141605" },
      { "name": "Plant pathology", "code": "70141606" },
      { "name": "Locust control", "code": "70141607" },
      { "name": "Aerial crop survey", "code": "70141608" },
      { "name": "Crop management", "code": "70141700" },
      { "name": "Greenhouse services", "code": "70141701" },
      { "name": "Fertilizer services", "code": "70141702" },
      { "name": "Crop administration", "code": "70141703" },
      { "name": "Crop substitution", "code": "70141704" },
      { "name": "Extension services", "code": "70141705" },
      { "name": "Crop specialization", "code": "70141706" },
      { "name": "Cultivation farming system management", "code": "70141707" },
      {
        "name": "Crop rotation or diversification counseling services",
        "code": "70141708"
      },
      { "name": "Plant taxonomy services", "code": "70141709" },
      { "name": "Field crop entomology", "code": "70141710" },
      { "name": "Crop planting and cultivation", "code": "70141800" },
      { "name": "Orchard tree or vine planting services", "code": "70141801" },
      { "name": "Sprout or twig services", "code": "70141802" },
      { "name": "Crop cultivating services", "code": "70141803" },
      { "name": "Crop planting services", "code": "70141804" },
      { "name": "Crop harvesting", "code": "70141900" },
      { "name": "Cash grains harvesting services", "code": "70141901" },
      { "name": "Fruit or tree nuts harvesting services", "code": "70141902" },
      { "name": "Field crop harvesting services", "code": "70141903" },
      { "name": "Seed harvesting services", "code": "70141904" },
      { "name": "Post harvesting crop processing", "code": "70142000" },
      { "name": "Silos related services", "code": "70142001" },
      { "name": "Grain dryers services", "code": "70142002" },
      { "name": "Crop processing services", "code": "70142003" },
      { "name": "Field crops market preparation services", "code": "70142004" },
      {
        "name": "Cash grain crops market preparation services",
        "code": "70142005"
      },
      {
        "name": "Vegetable crops market preparation services",
        "code": "70142006"
      },
      { "name": "Fruit crops market preparation services", "code": "70142007" },
      {
        "name": "Tree nut crops market preparation services",
        "code": "70142008"
      },
      { "name": "Ginning services", "code": "70142009" },
      { "name": "Crop cleaning services", "code": "70142010" },
      { "name": "Cooling or refrigeration services", "code": "70142011" },
      { "name": "Forestry", "code": "70150000" },
      { "name": "Forestry management", "code": "70151500" },
      { "name": "Forest resources management services", "code": "70151501" },
      { "name": "Forest pest control", "code": "70151502" },
      {
        "name": "Forest organizations or associations or cooperatives",
        "code": "70151503"
      },
      { "name": "Forest administration services", "code": "70151504" },
      { "name": "Forest inventory", "code": "70151505" },
      { "name": "Forest monitoring or evaluation", "code": "70151506" },
      { "name": "Afforestation services", "code": "70151507" },
      { "name": "Forestry extension services", "code": "70151508" },
      { "name": "Forest nursery management", "code": "70151509" },
      { "name": "Forest sectoral planning", "code": "70151510" },
      { "name": "Forestry industry", "code": "70151600" },
      { "name": "Nonwood production services", "code": "70151601" },
      { "name": "Essential oils production", "code": "70151602" },
      { "name": "Timber production services", "code": "70151603" },
      { "name": "Dyes production", "code": "70151604" },
      { "name": "Edible forestry production", "code": "70151605" },
      { "name": "Wood testing services", "code": "70151606" },
      { "name": "Forestry harvesting", "code": "70151700" },
      { "name": "Logging or felling", "code": "70151701" },
      { "name": "Forest harvesting mountainous areas", "code": "70151702" },
      {
        "name": "Forest harvesting specialized operations",
        "code": "70151703"
      },
      { "name": "Plantation harvesting", "code": "70151704" },
      { "name": "Swamp or mangrove forest harvesting", "code": "70151705" },
      { "name": "Temperate forest harvesting", "code": "70151706" },
      { "name": "Tropical high forests harvesting", "code": "70151707" },
      { "name": "Forestry conservation services", "code": "70151800" },
      {
        "name": "Conservation of forest genetic resources",
        "code": "70151801"
      },
      { "name": "Forest protection services", "code": "70151802" },
      { "name": "Forest arid land rehabilitation", "code": "70151803" },
      { "name": "Forest wind breaks or shelter belts", "code": "70151804" },
      {
        "name": "Forest reserves or parks conservation services",
        "code": "70151805"
      },
      { "name": "Forestry watershed management", "code": "70151806" },
      { "name": "Forestry torrent control", "code": "70151807" },
      { "name": "Forestry resources", "code": "70151900" },
      { "name": "Forest resources development", "code": "70151901" },
      { "name": "Agroforestry resources", "code": "70151902" },
      { "name": "Fuel wood resources", "code": "70151903" },
      { "name": "Silviculture", "code": "70151904" },
      { "name": "Arid rainfed plantation resources", "code": "70151905" },
      { "name": "Broad leafed plantation resources", "code": "70151906" },
      { "name": "Conifers plantations", "code": "70151907" },
      { "name": "Tropical rain forest plantations", "code": "70151909" },
      { "name": "Mangrove swamps resources", "code": "70151910" },
      { "name": "Wildlife and flora", "code": "70160000" },
      { "name": "Fauna", "code": "70161500" },
      { "name": "Fauna protection", "code": "70161501" },
      { "name": "Flora", "code": "70161600" },
      { "name": "Flora protection", "code": "70161601" },
      { "name": "Ecosystems", "code": "70161700" },
      { "name": "Ecodevelopment services", "code": "70161701" },
      { "name": "Marine ecosystem management services", "code": "70161702" },
      {
        "name": "Terrestrial ecosystem management services",
        "code": "70161703"
      },
      { "name": "Ecosystems protection services", "code": "70161704" },
      {
        "name": "Bush and forest ecology and conservation service",
        "code": "70161705"
      },
      {
        "name": "Coastal ecology and conservation service",
        "code": "70161706"
      },
      {
        "name": "Conservation and management of animal or bird sanctuaries or pest free environments",
        "code": "70161707"
      },
      {
        "name": "Conservation and management of freshwater ponds and lakes and rivers and streams",
        "code": "70161708"
      },
      {
        "name": "Riparian margins ecology and conservation service",
        "code": "70161709"
      },
      {
        "name": "Wetland ecology and conservation service",
        "code": "70161710"
      },
      {
        "name": "Mangrove swamp ecology and conservation service",
        "code": "70161711"
      },
      {
        "name": "Water resources development and oversight",
        "code": "70170000"
      },
      { "name": "Water resource development service", "code": "70171500" },
      { "name": "Water quality assessment services", "code": "70171501" },
      { "name": "Water resources planning services", "code": "70171502" },
      { "name": "Ground or surface water surveying", "code": "70171503" },
      { "name": "Water resources mapping services", "code": "70171504" },
      { "name": "River basin development", "code": "70171505" },
      {
        "name": "Ground or surface water modeling services",
        "code": "70171506"
      },
      { "name": "Surface water development", "code": "70171507" },
      { "name": "Water quality management services", "code": "70171600" },
      { "name": "Water quality management", "code": "70171601" },
      { "name": "Water testing services", "code": "70171602" },
      { "name": "Floodplain management", "code": "70171603" },
      { "name": "Water conservation advisory services", "code": "70171604" },
      { "name": "Water rights advisory services", "code": "70171605" },
      { "name": "Water resource recovery services", "code": "70171606" },
      { "name": "Water pricing services", "code": "70171607" },
      {
        "name": "Irrigation system maintenance and management services",
        "code": "70171700"
      },
      {
        "name": "Canal maintenance or management services",
        "code": "70171701"
      },
      { "name": "Dam maintenance or management services", "code": "70171702" },
      {
        "name": "Reservoirs maintenance or management services",
        "code": "70171703"
      },
      {
        "name": "Pumping station maintenance or management services",
        "code": "70171704"
      },
      {
        "name": "Pipelines maintenance or management services",
        "code": "70171705"
      },
      {
        "name": "Dike or embankment maintenance or management services",
        "code": "70171706"
      },
      {
        "name": "Water well maintenance or management services",
        "code": "70171707"
      },
      { "name": "Irrigation advisory services", "code": "70171708" },
      { "name": "Irrigation systems management services", "code": "70171709" },
      { "name": "Irrigation system construction service", "code": "70171710" },
      { "name": "Drainage services", "code": "70171800" },
      { "name": "Land drainage services", "code": "70171801" },
      { "name": "Storm water drainage", "code": "70171802" },
      { "name": "Flood protection or control services", "code": "70171803" }
    ]
  },
  {
    "name": "Mining and oil and gas services",
    "code": "71000000",
    "subcategories": [
      { "name": "Mining services", "code": "71100000" },
      { "name": "Mine exploration", "code": "71101500" },
      { "name": "Mine development", "code": "71101501" },
      { "name": "Test boring or core drilling", "code": "71101502" },
      {
        "name": "Mine drilling blasting and construction services",
        "code": "71101600"
      },
      { "name": "Shaft sinking", "code": "71101601" },
      { "name": "Mine blasting services", "code": "71101602" },
      { "name": "Mine bore hole drilling service", "code": "71101603" },
      { "name": "Slope shaft construction service", "code": "71101604" },
      { "name": "Mine horizontal drilling service", "code": "71101605" },
      { "name": "Underground seal installation service", "code": "71101606" },
      {
        "name": "Underground roofing construction service",
        "code": "71101607"
      },
      { "name": "Bunker construction service", "code": "71101608" },
      {
        "name": "Underground ventilation construction service",
        "code": "71101609"
      },
      { "name": "Extraction", "code": "71101700" },
      { "name": "Shaft mining services", "code": "71101701" },
      { "name": "Open pit mining services", "code": "71101702" },
      { "name": "Strip mining services", "code": "71101703" },
      { "name": "In situ leaching ISL services", "code": "71101704" },
      { "name": "Pumping or draining", "code": "71101705" },
      { "name": "Overburden removal", "code": "71101706" },
      {
        "name": "Impoundment or storage of water services",
        "code": "71101707"
      },
      { "name": "Soil flushing services", "code": "71101708" },
      { "name": "Mine filling services", "code": "71101709" },
      {
        "name": "Mine machinery rental or leasing service",
        "code": "71101710"
      },
      { "name": "Oil and gas exploration services", "code": "71110000" },
      { "name": "Cased hole well logging services", "code": "71112000" },
      {
        "name": "Cased hole formation sampling testing services",
        "code": "71112001"
      },
      { "name": "Casing thickness measurement services", "code": "71112002" },
      { "name": "Chemical cutters services", "code": "71112003" },
      { "name": "Electromagnetic pipe recovery services", "code": "71112004" },
      { "name": "Well flow measurement services", "code": "71112005" },
      { "name": "Well fluid density measurement services", "code": "71112006" },
      {
        "name": "Well fluid temperature measurement services",
        "code": "71112007"
      },
      { "name": "Free point tool services", "code": "71112008" },
      { "name": "Gamma ray services", "code": "71112009" },
      { "name": "Jet cutter services", "code": "71112010" },
      { "name": "Measurement while perforating services", "code": "71112011" },
      { "name": "Neutron porosity services", "code": "71112012" },
      { "name": "Nuclear spectroscopy services", "code": "71112013" },
      { "name": "Well packer services", "code": "71112014" },
      {
        "name": "Permanent magnets pipe recovery services",
        "code": "71112015"
      },
      {
        "name": "Well pressure measurement control services",
        "code": "71112017"
      },
      {
        "name": "Production logging borehole fluid measurement services",
        "code": "71112018"
      },
      {
        "name": "Production logging density measurement services",
        "code": "71112019"
      },
      {
        "name": "Production logging downhole video services",
        "code": "71112020"
      },
      {
        "name": "Production logging flow measurement services",
        "code": "71112021"
      },
      {
        "name": "Production logging pressure measurement services",
        "code": "71112022"
      },
      { "name": "Other production logging services", "code": "71112023" },
      {
        "name": "Production logging temperature measurement services",
        "code": "71112024"
      },
      { "name": "Severing colliding services", "code": "71112025" },
      { "name": "Well logging acoustic services", "code": "71112026" },
      { "name": "Stuck point tool services", "code": "71112027" },
      { "name": "Thermal decay well logging services", "code": "71112028" },
      {
        "name": "Well tubular corrosion evaluation services",
        "code": "71112029"
      },
      { "name": "Well performance services", "code": "71112030" },
      { "name": "Well tubular services", "code": "71112031" },
      { "name": "Open hole well logging services", "code": "71112100" },
      { "name": "Digital acoustic logging services", "code": "71112101" },
      { "name": "Borehole geometry logging services", "code": "71112102" },
      {
        "name": "Density lithology nuclear logging services",
        "code": "71112103"
      },
      { "name": "Dipole acoustic logging services", "code": "71112105" },
      { "name": "Directional logging services", "code": "71112106" },
      { "name": "Directional survey services", "code": "71112107" },
      {
        "name": "Electromagnetic propagation logging services",
        "code": "71112108"
      },
      { "name": "Formation testing sampling services", "code": "71112109" },
      {
        "name": "Fracture identification logging services",
        "code": "71112110"
      },
      { "name": "Gamma ray logging services", "code": "71112111" },
      { "name": "Gamma ray spectroscopy logging services", "code": "71112112" },
      { "name": "Geochemical nuclear logging services", "code": "71112113" },
      { "name": "Well imaging services", "code": "71112114" },
      { "name": "Magnetic resonance logging services", "code": "71112115" },
      { "name": "Microresistivity logging services", "code": "71112116" },
      { "name": "Neutron porosity logging services", "code": "71112117" },
      { "name": "Resistivity logging services", "code": "71112119" },
      { "name": "Slim access acoustic logging services", "code": "71112120" },
      {
        "name": "Acoustic cement bond ratio logging services",
        "code": "71112121"
      },
      { "name": "Well acoustic imaging services", "code": "71112122" },
      {
        "name": "Formation dip direction and angle logging services",
        "code": "71112123"
      },
      { "name": "Other logging services", "code": "71112200" },
      { "name": "Logging while drilling services", "code": "71112202" },
      { "name": "Logging during fishing services", "code": "71112203" },
      { "name": "Logging while perforating services", "code": "71112204" },
      { "name": "Drill pipe conveyed well services", "code": "71112205" },
      { "name": "General well logging services", "code": "71112206" },
      { "name": "Seismic services", "code": "71112300" },
      {
        "name": "2d/ 3d/ 4d land seismic acquisition services",
        "code": "71112301"
      },
      {
        "name": "2d/ 3d/ 4d marine seismic acquisition services",
        "code": "71112302"
      },
      {
        "name": "2d/ 3d/ 4d seismic data processing services",
        "code": "71112303"
      },
      { "name": "2d / 3d/ 4d seismic data interpretation", "code": "71112322" },
      { "name": "Well velocity survey services", "code": "71112323" },
      { "name": "Other seismic services", "code": "71112324" },
      {
        "name": "Borehole seismic land acquisition services",
        "code": "71112325"
      },
      {
        "name": "Borehole seismic marine acquisition services",
        "code": "71112326"
      },
      { "name": "Borehole seismic processing services", "code": "71112327" },
      {
        "name": "Borehole seismic monitoring acquisition services",
        "code": "71112328"
      },
      {
        "name": "Borehole seismic while drilling acquisition services",
        "code": "71112329"
      },
      {
        "name": "Simultaneous borehole and surface seismic acquisition services",
        "code": "71112330"
      },
      { "name": "Well drilling and construction services", "code": "71120000" },
      { "name": "Cement pumping services", "code": "71121000" },
      { "name": "Oilfield casing hardware services", "code": "71121001" },
      { "name": "Oilfield cement lab testing services", "code": "71121002" },
      { "name": "Oilwell lost circulation services", "code": "71121008" },
      { "name": "Oilfield mud removal services", "code": "71121009" },
      { "name": "Plug cement services", "code": "71121010" },
      { "name": "Well site pressure pumping services", "code": "71121011" },
      { "name": "Oilfield pressure testing services", "code": "71121012" },
      { "name": "Squeeze well cementing services", "code": "71121016" },
      { "name": "Well water control services", "code": "71121017" },
      { "name": "Well cement evaluation services", "code": "71121018" },
      { "name": "Casing cement services", "code": "71121024" },
      { "name": "Other cementing services", "code": "71121025" },
      { "name": "Coiled tubing services", "code": "71121100" },
      {
        "name": "Acidizing through coiled tubing services",
        "code": "71121101"
      },
      {
        "name": "Cable installation through coiled tubing services",
        "code": "71121102"
      },
      {
        "name": "Cementing through coiled tubing services",
        "code": "71121103"
      },
      {
        "name": "Chemical cutting through coiled tubing services",
        "code": "71121104"
      },
      {
        "name": "Clean out through coiled tubing services",
        "code": "71121105"
      },
      {
        "name": "Completion through coiled tubing services",
        "code": "71121106"
      },
      {
        "name": "Conveying inflatables through coiled tubing services",
        "code": "71121107"
      },
      { "name": "Coring through coiled tubing services", "code": "71121108" },
      { "name": "Extended reach well services", "code": "71121109" },
      { "name": "Fishing through coiled tubing services", "code": "71121110" },
      {
        "name": "Fracturing through coiled tubing services",
        "code": "71121111"
      },
      { "name": "High pressure coiled tubing services", "code": "71121112" },
      { "name": "Horizontal isolation oilfield services", "code": "71121113" },
      { "name": "Logging with coiled tubing services", "code": "71121114" },
      { "name": "Milling through coiled tubing services", "code": "71121115" },
      {
        "name": "Nitrogen related applications through coiled tubing",
        "code": "71121116"
      },
      {
        "name": "Perforating through coiled tubing services",
        "code": "71121117"
      },
      { "name": "Pipeline or flow line laying services", "code": "71121118" },
      { "name": "Pipeline intervention services", "code": "71121119" },
      {
        "name": "Sand control through coiled tubing services",
        "code": "71121120"
      },
      { "name": "Abrasive tubular cleaning services", "code": "71121121" },
      {
        "name": "Well kill through coiled tubing services",
        "code": "71121122"
      },
      { "name": "Casing exit with coiled tubing services", "code": "71121123" },
      { "name": "Coring services", "code": "71121200" },
      { "name": "Conventional coring services", "code": "71121201" },
      { "name": "Core isolation services", "code": "71121202" },
      { "name": "Core preservation services", "code": "71121203" },
      { "name": "Horizontal coring services", "code": "71121204" },
      { "name": "Oriented coring services", "code": "71121205" },
      { "name": "Core analysis service", "code": "71121206" },
      { "name": "Sponge coring services", "code": "71121207" },
      { "name": "Wireline retrievable coring services", "code": "71121208" },
      { "name": "Anti jamming system services", "code": "71121209" },
      { "name": "Motor coring services", "code": "71121210" },
      { "name": "Full closure systems services", "code": "71121211" },
      { "name": "Downhole drilling tool services", "code": "71121300" },
      {
        "name": "Downhole drilling vibration control services",
        "code": "71121301"
      },
      {
        "name": "Downhole drilling borehole enlargement services",
        "code": "71121302"
      },
      {
        "name": "Downhole drilling reaming and gauge protection services",
        "code": "71121303"
      },
      {
        "name": "Downhole drilling torque or drag reduction services",
        "code": "71121304"
      },
      {
        "name": "Downhole drilling stuck pipe alleviation services",
        "code": "71121305"
      },
      { "name": "Downhole drilling tool repair services", "code": "71121307" },
      {
        "name": "Downhole drilling stabilization services",
        "code": "71121309"
      },
      { "name": "Blow out preventer BOP rental", "code": "71121310" },
      { "name": "Oilfield drilling bit services", "code": "71121400" },
      { "name": "Oilfield drilling bit design services", "code": "71121401" },
      {
        "name": "Oilfield drilling bit hydraulic optimization services",
        "code": "71121402"
      },
      { "name": "Oilfield drilling bit planning services", "code": "71121403" },
      {
        "name": "Oilfield drilling bit recording services",
        "code": "71121404"
      },
      { "name": "Oilfield drilling bit repair services", "code": "71121405" },
      { "name": "Oilfield drilling bit footage contracts", "code": "71121406" },
      {
        "name": "Well site drilling optimization assistance service",
        "code": "71121407"
      },
      {
        "name": "Drill bit hydraulic lifting system services",
        "code": "71121408"
      },
      { "name": "Drilling measurement services", "code": "71121500" },
      {
        "name": "Acoustic measurement when drilling services",
        "code": "71121501"
      },
      {
        "name": "Acoustic telemetry when drilling services",
        "code": "71121502"
      },
      {
        "name": "Caliper measurement when drilling services",
        "code": "71121503"
      },
      {
        "name": "Density measurement when drilling services",
        "code": "71121504"
      },
      { "name": "Oilfield drilling performance services", "code": "71121505" },
      {
        "name": "Electromagnetic telemetry when drilling services",
        "code": "71121506"
      },
      {
        "name": "Formation dip measurement when drilling services",
        "code": "71121507"
      },
      {
        "name": "Formation pressure measurement when drilling services",
        "code": "71121508"
      },
      { "name": "Geologically steered wells", "code": "71121509" },
      { "name": "Geostopping services", "code": "71121510" },
      { "name": "Mud logging services", "code": "71121511" },
      { "name": "Nuclear magnetic resonance services", "code": "71121512" },
      {
        "name": "Porosity measurement when drilling services",
        "code": "71121513"
      },
      {
        "name": "Resistivity measurement when drilling services",
        "code": "71121514"
      },
      { "name": "Well surveying management services", "code": "71121515" },
      { "name": "Surveying wireline services", "code": "71121516" },
      { "name": "Oil well drilling services", "code": "71121600" },
      { "name": "Well casing crew service", "code": "71121601" },
      { "name": "Well casing planning services", "code": "71121602" },
      { "name": "Well casing milling services", "code": "71121603" },
      { "name": "Coiled tubing drilling services", "code": "71121604" },
      { "name": "Well completion planning services", "code": "71121605" },
      {
        "name": "Conventional well directional drilling services",
        "code": "71121606"
      },
      { "name": "Well directional drilling services", "code": "71121607" },
      {
        "name": "Well drilling contract development services",
        "code": "71121608"
      },
      { "name": "Well drilling engineering services", "code": "71121610" },
      { "name": "Well drilling mud or fluid services", "code": "71121611" },
      {
        "name": "Well drilling of water intake well services",
        "code": "71121612"
      },
      { "name": "Well drilling rig monitor services", "code": "71121613" },
      { "name": "Well drilling solids control", "code": "71121614" },
      {
        "name": "Well drilling stabilizing or hole opening services",
        "code": "71121615"
      },
      { "name": "Well drilling supervision", "code": "71121616" },
      { "name": "Well drilling control services", "code": "71121617" },
      { "name": "Well drilling other general services", "code": "71121618" },
      {
        "name": "Extended reach directional well drilling services",
        "code": "71121619"
      },
      {
        "name": "Multilateral directional well drilling services",
        "code": "71121620"
      },
      {
        "name": "Oil country tubular modification or testing services",
        "code": "71121621"
      },
      { "name": "Well drilling pickup or laydown service", "code": "71121622" },
      { "name": "Well drilling pipe storage", "code": "71121623" },
      { "name": "Well drilling pipe threading", "code": "71121624" },
      { "name": "Rat hole well drilling service", "code": "71121625" },
      {
        "name": "Short radius directional well drilling services",
        "code": "71121626"
      },
      {
        "name": "Steerable underreaming while well drilling services",
        "code": "71121627"
      },
      {
        "name": "Ultra short radius directional well drilling services",
        "code": "71121628"
      },
      { "name": "Underbalanced well drilling services", "code": "71121629" },
      { "name": "Well planning services", "code": "71121630" },
      { "name": "Well redrilling or reworking services", "code": "71121631" },
      { "name": "Well drilling deviation control", "code": "71121632" },
      { "name": "Oilfield gravel packing services", "code": "71121633" },
      { "name": "Oilfield hull systems services", "code": "71121634" },
      { "name": "Rig well pulling crew services", "code": "71121635" },
      { "name": "Wellhead equipment services", "code": "71121636" },
      { "name": "Sidetracking with whipstock services", "code": "71121637" },
      { "name": "Well drilling pipe cleaning service", "code": "71121638" },
      {
        "name": "Downhole drilling tubular rental service",
        "code": "71121639"
      },
      { "name": "Tubular running service", "code": "71121640" },
      {
        "name": "Rotary steerable directional drilling service",
        "code": "71121641"
      },
      { "name": "Oilfield fishing services", "code": "71121700" },
      {
        "name": "Oilfield fishing service design services",
        "code": "71121701"
      },
      {
        "name": "Oilfield fishing service economic evaluation services",
        "code": "71121702"
      },
      {
        "name": "Oilfield fishing service operation services",
        "code": "71121703"
      },
      { "name": "Oilfield junk recovery services", "code": "71121704" },
      { "name": "Oilfield pipe recovery service", "code": "71121705" },
      { "name": "Oilfield fishing or drilling services", "code": "71121706" },
      { "name": "Artificial lift services", "code": "71121800" },
      { "name": "Plunger lift services", "code": "71121805" },
      { "name": "Reciprocating rod lift services", "code": "71121806" },
      { "name": "Hydraulic lifting services", "code": "71121807" },
      { "name": "Progressive cavity lift CVX services", "code": "71121808" },
      {
        "name": "Well site optimization and automation services",
        "code": "71121809"
      },
      {
        "name": "Electronic submersible pump lifting services",
        "code": "71121810"
      },
      { "name": "Other lift systems", "code": "71121811" },
      { "name": "Gas lift Services", "code": "71121812" },
      { "name": "Downhole fluid services", "code": "71121900" },
      { "name": "Downhole fluid evaluation services", "code": "71121901" },
      { "name": "Downhole fluid laboratory services", "code": "71121902" },
      { "name": "Downhole fluid sampling services", "code": "71121903" },
      { "name": "Downhole fluid engineering services", "code": "71121904" },
      { "name": "Fluid waste management service", "code": "71121905" },
      { "name": "Well perforating services", "code": "71122000" },
      { "name": "Coiled tubing well perforating services", "code": "71122001" },
      { "name": "Slickline well perforating services", "code": "71122002" },
      {
        "name": "Through tubing well perforation services",
        "code": "71122003"
      },
      {
        "name": "Tubing conveyed well perforating services",
        "code": "71122004"
      },
      { "name": "Wireline well perforating services", "code": "71122005" },
      { "name": "Well tubing puncher services", "code": "71122006" },
      { "name": "Oilfield sand control services", "code": "71122100" },
      { "name": "Acid sand control pumping services", "code": "71122101" },
      { "name": "Sand control blending services", "code": "71122102" },
      { "name": "Cleaning fluid sand control services", "code": "71122103" },
      { "name": "Completion fluid sand control services", "code": "71122104" },
      { "name": "Well consolidation services", "code": "71122105" },
      { "name": "Fracturing fluid sand control services", "code": "71122107" },
      {
        "name": "Fracturing pre frac design testing services",
        "code": "71122108"
      },
      {
        "name": "General /miscellaneous sand control services",
        "code": "71122109"
      },
      {
        "name": "Gravel carrier fluid sand control services",
        "code": "71122110"
      },
      { "name": "Sand control lab testing services", "code": "71122111" },
      { "name": "Multizone sand control services", "code": "71122112" },
      { "name": "Sand control monitoring services", "code": "71122113" },
      {
        "name": "Sand control temporary isolation services",
        "code": "71122114"
      },
      { "name": "Well tool redressing services", "code": "71122115" },
      {
        "name": "Non fracturing sand control pumping services",
        "code": "71122116"
      },
      { "name": "Slickline services", "code": "71122200" },
      { "name": "Slickline fishing services", "code": "71122201" },
      { "name": "Slickline lifting services", "code": "71122202" },
      { "name": "Slickline manipulation services", "code": "71122203" },
      { "name": "Slickline completion services", "code": "71122204" },
      { "name": "Slickline logging services", "code": "71122205" },
      { "name": "Slickline mechanical services", "code": "71122206" },
      { "name": "Slickline pipe recovery services", "code": "71122207" },
      { "name": "Subsea well services", "code": "71122300" },
      { "name": "Subsea well diving services", "code": "71122301" },
      {
        "name": "Subsea well remote operation vehicle rov services",
        "code": "71122302"
      },
      {
        "name": "Subsea well fixturing or test equipment services",
        "code": "71122303"
      },
      {
        "name": "Subsea well intervention or completion services",
        "code": "71122304"
      },
      { "name": "Subsea well safety test tree services", "code": "71122305" },
      {
        "name": "Subsea well equipment maintenance services",
        "code": "71122306"
      },
      {
        "name": "Subsea dredging trenching and excavation services",
        "code": "71122307"
      },
      {
        "name": "Subsea pipe laying and cable laying services",
        "code": "71122308"
      },
      {
        "name": "Subsea well production equipment monitoring and inspection service",
        "code": "71122309"
      },
      {
        "name": "Subsea well project management and engineering service",
        "code": "71122310"
      },
      {
        "name": "Subsea well project front end engineering design service FEED",
        "code": "71122311"
      },
      {
        "name": "Subsea equipment installation and intervention tool service",
        "code": "71122312"
      },
      { "name": "Well testing services", "code": "71122400" },
      { "name": "Drill stem testing services", "code": "71122407" },
      { "name": "Periodic well testing services", "code": "71122408" },
      { "name": "Surface well testing services", "code": "71122409" },
      {
        "name": "Underbalanced testing while drilling services",
        "code": "71122410"
      },
      { "name": "Water or gas control services", "code": "71122500" },
      { "name": "Water or gas control design services", "code": "71122501" },
      {
        "name": "Water or gas control evaluation services",
        "code": "71122502"
      },
      { "name": "Water or gas control isolation services", "code": "71122503" },
      { "name": "Water or gas control testing services", "code": "71122504" },
      { "name": "Water or gas testing services", "code": "71122505" },
      { "name": "Flare services", "code": "71122506" },
      { "name": "Well completion services", "code": "71122600" },
      { "name": "Cased hole well completion services", "code": "71122601" },
      { "name": "Well completion fluids services", "code": "71122602" },
      { "name": "Gas lift well completion services", "code": "71122605" },
      { "name": "Intelligent well completion services", "code": "71122606" },
      { "name": "Multilateral well completion services", "code": "71122608" },
      { "name": "Sand control well completion services", "code": "71122610" },
      { "name": "Liner running services", "code": "71122614" },
      { "name": "Open hole completion services", "code": "71122615" },
      {
        "name": "Expandable pipe/screen well completion services",
        "code": "71122616"
      },
      { "name": "Well maintenance services", "code": "71122700" },
      { "name": "Oil well platform maintenance services", "code": "71122701" },
      {
        "name": "Oilfield pumping unit maintenance services",
        "code": "71122702"
      },
      { "name": "Oilfield rod pump repair services", "code": "71122703" },
      { "name": "Oilfield snubbing service", "code": "71122704" },
      {
        "name": "Oilfield sucker rod maintenance services",
        "code": "71122705"
      },
      { "name": "Well swabbing services", "code": "71122706" },
      { "name": "Oilfield tubing anchor services", "code": "71122707" },
      { "name": "Oilfield pump mechanic services", "code": "71122708" },
      { "name": "Downhole tool maintenance services", "code": "71122709" },
      { "name": "Oilfield tubular maintenance services", "code": "71122710" },
      { "name": "Well workover service", "code": "71122711" },
      { "name": "Well workover lift boat service", "code": "71122712" },
      { "name": "Well monitoring services", "code": "71122800" },
      { "name": "Downhole recording services", "code": "71122801" },
      { "name": "Well flow monitoring services", "code": "71122802" },
      { "name": "Oil or gas well monitoring services", "code": "71122803" },
      { "name": "Well site phase monitoring services", "code": "71122804" },
      { "name": "Well site pump monitoring services", "code": "71122805" },
      {
        "name": "Well site surface data acquisition services",
        "code": "71122806"
      },
      { "name": "Well site surface readout services", "code": "71122807" },
      { "name": "Well site surface recording services", "code": "71122808" },
      { "name": "Subsurface well testing services", "code": "71122810" },
      { "name": "Oilfield rigs", "code": "71122900" },
      { "name": "Deepwater oilfield rig services", "code": "71122901" },
      { "name": "Jackup oilfield rig services", "code": "71122902" },
      { "name": "Barge oilfield rig services", "code": "71122903" },
      { "name": "Land oilfield rig services", "code": "71122904" },
      { "name": "Platform oilfield rig services", "code": "71122905" },
      { "name": "Integrated services", "code": "71123000" },
      {
        "name": "Management and provision of all facilities engineering modification and maintenance services for a site or platform",
        "code": "71123001"
      },
      {
        "name": "Management and operation of all facilities, engineering, modification and maintenance services for site or platform",
        "code": "71123002"
      },
      {
        "name": "Hydrocarbon reservoir development and production services",
        "code": "71123003"
      },
      { "name": "Well management / construction services", "code": "71123004" },
      {
        "name": "Management and provision of all catering, cleaning, office and security services at location or platform",
        "code": "71123005"
      },
      { "name": "Field development", "code": "71123006" },
      {
        "name": "Integrated field rehabilitation services",
        "code": "71123007"
      },
      {
        "name": "Oil and gas extraction and production enhancement services",
        "code": "71130000"
      },
      { "name": "Well fracturing services", "code": "71131000" },
      {
        "name": "Acid based fluid well fracturing services",
        "code": "71131001"
      },
      {
        "name": "Emulsion based fluid well fracturing services",
        "code": "71131002"
      },
      {
        "name": "Foam based fluid well fracturing services",
        "code": "71131003"
      },
      { "name": "Oil well fracturing services", "code": "71131004" },
      { "name": "Oil well scale control services", "code": "71131005" },
      { "name": "Well fracture testing services", "code": "71131006" },
      {
        "name": "Well fracturing downhole evaluation services",
        "code": "71131007"
      },
      { "name": "Well fracturing height control services", "code": "71131008" },
      { "name": "Well fracturing monitoring services", "code": "71131009" },
      { "name": "Well fracturing service design services", "code": "71131010" },
      {
        "name": "Well fracturing service evaluation services",
        "code": "71131011"
      },
      {
        "name": "Well fracturing surface evaluation services",
        "code": "71131012"
      },
      {
        "name": "Well fracturing treatment quality control services",
        "code": "71131013"
      },
      {
        "name": "Oil based fluid well fracturing services",
        "code": "71131014"
      },
      { "name": "Other well fracturing services", "code": "71131015" },
      {
        "name": "Well fracturing stress management services",
        "code": "71131016"
      },
      {
        "name": "Water based linear fluid well fracturing services",
        "code": "71131018"
      },
      {
        "name": "Water based cross linked fluid well fracturing services",
        "code": "71131019"
      },
      { "name": "Matrix stimulation services", "code": "71131100" },
      { "name": "Acid based matrix stimulation services", "code": "71131101" },
      {
        "name": "Formation sealer matrix stimulation services",
        "code": "71131102"
      },
      { "name": "Matrix organic cleanup services", "code": "71131103" },
      { "name": "Matrix organic inhibition services", "code": "71131104" },
      { "name": "Matrix scale cleanup services", "code": "71131105" },
      { "name": "Matrix scale inhibition services", "code": "71131106" },
      { "name": "Matrix treatment design services", "code": "71131107" },
      { "name": "Matrix treatment diversion services", "code": "71131108" },
      { "name": "Matrix treatment evaluation services", "code": "71131109" },
      {
        "name": "Matrix treatment quality control services",
        "code": "71131110"
      },
      {
        "name": "Non acid based matrix stimulation services",
        "code": "71131111"
      },
      { "name": "Oilfield nitrogen services", "code": "71131200" },
      { "name": "Nitrogen well services", "code": "71131201" },
      { "name": "Other oilfield pumping services", "code": "71131300" },
      { "name": "Bottom intake oilfield pumping services", "code": "71131301" },
      {
        "name": "Cable deployed oilfield pumping services",
        "code": "71131302"
      },
      { "name": "Co2 oilfield pumping services", "code": "71131303" },
      {
        "name": "Coiled tubing deployed oilfield pumping services",
        "code": "71131304"
      },
      { "name": "Downhole pumping services", "code": "71131305" },
      { "name": "Dual completion well services", "code": "71131306" },
      { "name": "Horizontal pumping well services", "code": "71131307" },
      {
        "name": "Oilfield pumping installation pull or operation services",
        "code": "71131308"
      },
      { "name": "Well profile modification services", "code": "71131309" },
      { "name": "Oilfield spooling services", "code": "71131310" },
      { "name": "Progressive cavity pump services", "code": "71131311" },
      { "name": "Electronic submersible pump services", "code": "71131312" },
      { "name": "Conventional pumping services", "code": "71131313" },
      { "name": "Well production services", "code": "71131400" },
      { "name": "Oilfield flare system services", "code": "71131401" },
      { "name": "Oilfield hot oil or water service", "code": "71131402" },
      { "name": "Oilfield steaming services", "code": "71131403" },
      { "name": "Sulfide scavenging services", "code": "71131404" },
      {
        "name": "Flow line hydrate formation control services",
        "code": "71131405"
      },
      { "name": "Iron sulfide deposit removal services", "code": "71131406" },
      { "name": "Squeeze modeling services", "code": "71131407" },
      { "name": "Hydrate formation modeling services", "code": "71131408" },
      { "name": "Chemical treatment monitoring services", "code": "71131409" },
      { "name": "Pipeline cleaning services", "code": "71131410" },
      { "name": "Pipeline flow enhancement services", "code": "71131411" },
      {
        "name": "Well restoration and enhancement services",
        "code": "71131412"
      },
      { "name": "Corrosion inhibiting services", "code": "71131413" },
      { "name": "Scale inhibiting services", "code": "71131414" },
      { "name": "Pipeline flow assurance services", "code": "71131415" },
      { "name": "Impurity removal services", "code": "71131416" },
      { "name": "Organic fouling control services", "code": "71131417" },
      { "name": "Bacterial and fungus control services", "code": "71131418" },
      { "name": "Gas gathering service", "code": "71131419" },
      { "name": "Well flowback filtration service", "code": "71131420" },
      {
        "name": "Oil and gas restoration and reclamation services",
        "code": "71140000"
      },
      { "name": "Emergency well control services", "code": "71141000" },
      { "name": "Well pressure control services", "code": "71141001" },
      { "name": "Well capping services", "code": "71141002" },
      { "name": "Well fire fighting services", "code": "71141003" },
      { "name": "Wild well control services", "code": "71141004" },
      { "name": "Dead well workover", "code": "71141005" },
      { "name": "Well freeze operations", "code": "71141006" },
      { "name": "Relief well design and implementation", "code": "71141007" },
      { "name": "Well control engineering services", "code": "71141008" },
      { "name": "Well plugging and abandonment services", "code": "71141100" },
      { "name": "Well abandonment services", "code": "71141101" },
      { "name": "Well plugging services", "code": "71141102" },
      {
        "name": "Well abandonment platform removal services",
        "code": "71141103"
      },
      {
        "name": "Well plugging and abandonment tubular cutting services",
        "code": "71141104"
      },
      { "name": "Well site restoration", "code": "71141200" },
      { "name": "Well cleaning or swabbing services", "code": "71141201" },
      { "name": "Well site restoration services", "code": "71141202" },
      {
        "name": "Oil and gas data management and processing services",
        "code": "71150000"
      },
      {
        "name": "Oilfield information management and communications services",
        "code": "71151000"
      },
      {
        "name": "Oilfield data and message transmission services",
        "code": "71151001"
      },
      { "name": "Oilfield graphics transmission services", "code": "71151002" },
      {
        "name": "Oilfield real time well data monitoring services",
        "code": "71151003"
      },
      {
        "name": "Oilfield satellite well data transmission services",
        "code": "71151004"
      },
      {
        "name": "Oilfield well data transmission services",
        "code": "71151005"
      },
      {
        "name": "Computer based simulation training program services",
        "code": "71151007"
      },
      { "name": "Oilfield data management services", "code": "71151100" },
      { "name": "Oilfield asset data management services", "code": "71151101" },
      { "name": "Oilfield data mining services", "code": "71151102" },
      { "name": "Oilfield log data management services", "code": "71151103" },
      {
        "name": "Oilfield mapping data management services",
        "code": "71151104"
      },
      { "name": "Seismic data management services", "code": "71151105" },
      { "name": "Data storage and backup", "code": "71151106" },
      {
        "name": "Oilfield economic and risk model services",
        "code": "71151200"
      },
      { "name": "Oilfield budgeting services", "code": "71151201" },
      { "name": "Oilfield capital planning services", "code": "71151202" },
      { "name": "Oilfield decision tree services", "code": "71151203" },
      { "name": "Oilfield interpretation services", "code": "71151300" },
      { "name": "Oilfield bubble map services", "code": "71151301" },
      { "name": "Oilfield case studies", "code": "71151302" },
      { "name": "Oilfield decline analysis", "code": "71151303" },
      { "name": "Oilfield field studies", "code": "71151304" },
      {
        "name": "Oilfield fracturing interpretation services",
        "code": "71151305"
      },
      { "name": "Geology services", "code": "71151306" },
      { "name": "Geophysics services", "code": "71151307" },
      { "name": "Gravel pack interpretation services", "code": "71151308" },
      { "name": "Oilfield grid mapping services", "code": "71151309" },
      {
        "name": "Oilfield mapping or visualization services",
        "code": "71151310"
      },
      { "name": "Petrophysics services", "code": "71151311" },
      { "name": "Rock mechanics services", "code": "71151315" },
      { "name": "Reservoir services", "code": "71151316" },
      { "name": "General formation evaluation", "code": "71151317" },
      { "name": "Acoustic waveform processing services", "code": "71151318" },
      { "name": "Geomechanical services processing", "code": "71151319" },
      {
        "name": "Nuclear magnetic resonance processing and interpretation services",
        "code": "71151320"
      },
      {
        "name": "Resistivity processing and interpretation services",
        "code": "71151321"
      },
      { "name": "Dip angle and direction processing", "code": "71151322" },
      { "name": "Borehole image processing", "code": "71151323" },
      { "name": "Logging while drilling processing", "code": "71151324" },
      {
        "name": "Pulsed neutron processing and interpretation services",
        "code": "71151325"
      },
      {
        "name": "General cased hole and production log processing",
        "code": "71151326"
      },
      {
        "name": "Casing inspection analysis services processing",
        "code": "71151327"
      },
      { "name": "Well service engineering", "code": "71151400" },
      { "name": "Well cementing job design services", "code": "71151401" },
      { "name": "Coiled tubing job design services", "code": "71151402" },
      { "name": "Well drilling job design services", "code": "71151403" },
      { "name": "Well fracturing job design services", "code": "71151404" },
      { "name": "Matrix stimulation job design services", "code": "71151405" },
      { "name": "Well sand control job design services", "code": "71151406" },
      {
        "name": "Oil and gas well project management services",
        "code": "71160000"
      },
      { "name": "Oilfield modeling services", "code": "71161000" },
      { "name": "Oilfield completion models", "code": "71161001" },
      { "name": "Oilfield drilling models", "code": "71161002" },
      { "name": "Oilfield economic models", "code": "71161003" },
      { "name": "Oilfield field development models", "code": "71161004" },
      { "name": "Oilfield production models", "code": "71161005" },
      { "name": "Oilfield risk management services", "code": "71161006" },
      { "name": "Geological or geophysical models", "code": "71161007" },
      { "name": "Reservoir models", "code": "71161008" },
      {
        "name": "Oilfield production engineering management",
        "code": "71161100"
      },
      { "name": "Oilfield artificial lift services", "code": "71161101" },
      { "name": "Enhanced oil recovery services", "code": "71161102" },
      { "name": "Well injection services", "code": "71161103" },
      {
        "name": "Oilfield production system analysis services",
        "code": "71161104"
      },
      { "name": "Well perforation services", "code": "71161105" },
      { "name": "Oilfield production chemistry services", "code": "71161106" },
      { "name": "Oilfield production monitoring services", "code": "71161107" },
      { "name": "Well stimulation services", "code": "71161109" },
      { "name": "Underground gas storage services", "code": "71161110" },
      { "name": "Oilfield water management services", "code": "71161111" },
      {
        "name": "Oilfield production operations management",
        "code": "71161200"
      },
      { "name": "Oilfield hot oiling service", "code": "71161201" },
      { "name": "Oilfield lease operations", "code": "71161202" },
      { "name": "Oilfield logging services", "code": "71161203" },
      { "name": "Oilfield paraffin cutting service", "code": "71161204" },
      { "name": "Oilfield planning services", "code": "71161205" },
      { "name": "Oilfield processing services", "code": "71161206" },
      { "name": "Oilfield project management services", "code": "71161300" },
      { "name": "Oilfield audits or inspection services", "code": "71161301" },
      {
        "name": "Oilfield emergency response planning services",
        "code": "71161302"
      },
      { "name": "Oilfield field development services", "code": "71161303" },
      {
        "name": "Oilfield performance monitoring services",
        "code": "71161304"
      },
      { "name": "Oilfield reporting services", "code": "71161305" },
      {
        "name": "Oilfield succession plan or handover services",
        "code": "71161306"
      },
      { "name": "Oilfield training matrix services", "code": "71161307" },
      {
        "name": "Well site logistics or procurement services",
        "code": "71161308"
      },
      { "name": "Well construction management services", "code": "71161400" },
      { "name": "Well completion engineering services", "code": "71161402" },
      { "name": "Deadman anchor services", "code": "71161403" },
      { "name": "Well site pit lining services", "code": "71161405" },
      { "name": "Well pulling unit services", "code": "71161407" },
      { "name": "Shorebased services", "code": "71161408" },
      { "name": "Test pit lining services", "code": "71161409" },
      { "name": "Well site vacuum truck services", "code": "71161410" },
      {
        "name": "Underbalanced applications engineering services",
        "code": "71161411"
      },
      {
        "name": "Well fabrication or construction services",
        "code": "71161413"
      },
      { "name": "Well site operations services", "code": "71161500" },
      {
        "name": "Well site inspection or equipment testing services",
        "code": "71161503"
      },
      { "name": "Other oilfield support services", "code": "71161600" },
      { "name": "Oilfield drafting services", "code": "71161601" },
      { "name": "Oilfield equipment brokerage services", "code": "71161602" },
      {
        "name": "Oilfield research and development services",
        "code": "71161603"
      },
      { "name": "Oilfield certification services", "code": "71161604" },
      { "name": "Oilfield consultancy services", "code": "71161605" },
      { "name": "Oil and gas technology licensing service", "code": "71161606" }
    ]
  },
  {
    "name": "Building and Facility Construction and Maintenance Services",
    "code": "72000000",
    "subcategories": [
      {
        "name": "Building and facility maintenance and repair services",
        "code": "72100000"
      },
      {
        "name": "Building maintenance and repair services",
        "code": "72101500"
      },
      { "name": "Handyman services", "code": "72101501" },
      {
        "name": "Disaster proofing or contingency services",
        "code": "72101504"
      },
      { "name": "Locksmith services", "code": "72101505" },
      { "name": "Elevator maintenance services", "code": "72101506" },
      { "name": "Building maintenance service", "code": "72101507" },
      { "name": "Floor cleaning services", "code": "72101508" },
      {
        "name": "Fire protection system and equipment maintenance or repair service",
        "code": "72101509"
      },
      { "name": "Plumbing system maintenance or repair", "code": "72101510" },
      {
        "name": "Air conditioning installation or maintenance or repair service",
        "code": "72101511"
      },
      { "name": "Hoist construction service", "code": "72101512" },
      { "name": "Offsite construction service", "code": "72101513" },
      { "name": "Refuse area construction service", "code": "72101514" },
      { "name": "Subsidence service work", "code": "72101515" },
      {
        "name": "Fire extinguisher inspection maintenance and repair service",
        "code": "72101516"
      },
      {
        "name": "Portable generator maintenance and or repair service",
        "code": "72101517"
      },
      { "name": "Portable generator rental service", "code": "72101518" },
      { "name": "Gas fitting installation service", "code": "72101519" },
      { "name": "Roof framing service", "code": "72101520" },
      { "name": "Building framing service", "code": "72101521" },
      { "name": "Pest control", "code": "72102100" },
      { "name": "Bird proofing services", "code": "72102101" },
      { "name": "Termite control services", "code": "72102102" },
      { "name": "Extermination or fumigation services", "code": "72102103" },
      { "name": "Structural pest control", "code": "72102104" },
      { "name": "Animal trapping", "code": "72102105" },
      { "name": "Rodent control", "code": "72102106" },
      {
        "name": "Facility maintenance and repair services",
        "code": "72102900"
      },
      { "name": "Landscaping services", "code": "72102902" },
      { "name": "Snow removal services", "code": "72102903" },
      { "name": "Exterior grounds maintenance", "code": "72102905" },
      { "name": "Landscape architecture service", "code": "72102906" },
      {
        "name": "Conveyance systems installation and repair",
        "code": "72103100"
      },
      { "name": "Material conveyance system installation", "code": "72103101" },
      { "name": "Material conveyance system repair", "code": "72103102" },
      { "name": "Aboveground conveyor service", "code": "72103103" },
      { "name": "Underground conveyor service", "code": "72103104" },
      {
        "name": "Infrastructure maintenance and repair services",
        "code": "72103300"
      },
      {
        "name": "Parking lot or road maintenance or repairs or services",
        "code": "72103301"
      },
      {
        "name": "Telecom equipment maintenance or support",
        "code": "72103302"
      },
      { "name": "Parking lot or road sweeping services", "code": "72103304" },
      { "name": "Drain laying service", "code": "72103305" },
      {
        "name": "Residential building construction services",
        "code": "72110000"
      },
      {
        "name": "Single family dwelling construction services",
        "code": "72111000"
      },
      {
        "name": "Single family home remodeling addition and repair service",
        "code": "72111001"
      },
      {
        "name": "Single family home general remodeling service",
        "code": "72111002"
      },
      { "name": "On site mobile home repair service", "code": "72111003" },
      {
        "name": "Patio and deck construction and repair service",
        "code": "72111004"
      },
      {
        "name": "Single family home fire damage repair service",
        "code": "72111005"
      },
      {
        "name": "Single family home new construction service",
        "code": "72111006"
      },
      {
        "name": "Single family prefabricated home erection service",
        "code": "72111007"
      },
      {
        "name": "Single family new town home or garden home construction service",
        "code": "72111008"
      },
      {
        "name": "Multiple unit dwelling construction services",
        "code": "72111100"
      },
      {
        "name": "New apartment building construction service",
        "code": "72111101"
      },
      { "name": "New cooperative construction service", "code": "72111102" },
      { "name": "New condominium construction service", "code": "72111103" },
      { "name": "New dormitory construction service", "code": "72111104" },
      { "name": "New hotel or motel construction service", "code": "72111105" },
      { "name": "Apartment remodeling service", "code": "72111106" },
      {
        "name": "Cooperative apartment remodeling service",
        "code": "72111107"
      },
      { "name": "Condominium remodeling service", "code": "72111108" },
      { "name": "Dormitory remodeling service", "code": "72111109" },
      { "name": "Hotel or motel remodeling service", "code": "72111110" },
      {
        "name": "General residential construction contractor service",
        "code": "72111111"
      },
      {
        "name": "Nonresidential building construction services",
        "code": "72120000"
      },
      {
        "name": "New industrial building and warehouse construction services",
        "code": "72121000"
      },
      {
        "name": "Dry cleaning plant construction and remodeling service",
        "code": "72121001"
      },
      {
        "name": "Food product manufacturing or packing plant construction and remodeling service",
        "code": "72121002"
      },
      {
        "name": "Grain elevator construction and remodeling service",
        "code": "72121003"
      },
      {
        "name": "Paper or pulp mill construction and remodeling service",
        "code": "72121004"
      },
      {
        "name": "Pharmaceutical manufacturing plant construction and remodeling service",
        "code": "72121005"
      },
      {
        "name": "Prefabricated industrial building erection and remodeling service",
        "code": "72121006"
      },
      {
        "name": "Truck and automobile assembly plant constructionand remodeling service",
        "code": "72121007"
      },
      {
        "name": "Warehouse construction and remodeling service",
        "code": "72121008"
      },
      {
        "name": "Commercial and office building construction services",
        "code": "72121100"
      },
      {
        "name": "Commercial and office building new construction service",
        "code": "72121101"
      },
      {
        "name": "Commercial and office building prefabricated erection service",
        "code": "72121102"
      },
      {
        "name": "Commercial and office building renovation and repair service",
        "code": "72121103"
      },
      { "name": "Restaurant construction service", "code": "72121104" },
      {
        "name": "Shopping center and mall construction service",
        "code": "72121105"
      },
      {
        "name": "Agricultural building construction services",
        "code": "72121200"
      },
      { "name": "Farm building construction service", "code": "72121201" },
      { "name": "Greenhouse construction service", "code": "72121202" },
      {
        "name": "Silo and agricultural service building construction service",
        "code": "72121203"
      },
      {
        "name": "Automotive garage and service station construction services",
        "code": "72121300"
      },
      { "name": "Automotive garage construction service", "code": "72121301" },
      {
        "name": "Automotive service station construction service",
        "code": "72121302"
      },
      {
        "name": "Specialized public building construction services",
        "code": "72121400"
      },
      { "name": "Bank building construction service", "code": "72121401" },
      { "name": "Fire station construction service", "code": "72121402" },
      { "name": "Hospital construction service", "code": "72121403" },
      { "name": "Post office construction service", "code": "72121404" },
      { "name": "Religious building construction service", "code": "72121405" },
      { "name": "School building construction service", "code": "72121406" },
      { "name": "Mausoleum construction service", "code": "72121407" },
      { "name": "Stadium construction service", "code": "72121408" },
      { "name": "Public library construction", "code": "72121409" },
      {
        "name": "Airport terminal and hanger construction",
        "code": "72121410"
      },
      { "name": "Industrial plant construction services", "code": "72121500" },
      { "name": "Chemical plant construction service", "code": "72121501" },
      {
        "name": "Mine loading and discharging station construction service",
        "code": "72121502"
      },
      { "name": "Oil refinery construction service", "code": "72121503" },
      {
        "name": "Waste disposal plant construction service",
        "code": "72121504"
      },
      {
        "name": "Waste water and sewage treatment plant construction service",
        "code": "72121505"
      },
      { "name": "Power plant construction service", "code": "72121506" },
      { "name": "Tank construction and servicing", "code": "72121507" },
      { "name": "Preparation plant construction service", "code": "72121508" },
      { "name": "Underground Electrical Services", "code": "72121509" },
      {
        "name": "Floating oil and gas production storage and offloading system construction service",
        "code": "72121510"
      },
      {
        "name": "Offshore oil and gas production facility hookup and commissioning service",
        "code": "72121511"
      },
      {
        "name": "Offshore oil and gas production facility equipment installation and integration service",
        "code": "72121512"
      },
      {
        "name": "Oil and gas plant modular fabrication service",
        "code": "72121513"
      },
      {
        "name": "Oil and gas production platform and topside fabrication service",
        "code": "72121514"
      },
      {
        "name": "Floating oil and gas production system hull fabrication service",
        "code": "72121515"
      },
      {
        "name": "Fixed oil and gas offshore production facilities fabrication service",
        "code": "72121516"
      },
      {
        "name": "Liquid natural gas LNG plant construction service",
        "code": "72121517"
      },
      { "name": "Heavy construction services", "code": "72140000" },
      { "name": "Highway and road construction services", "code": "72141000" },
      {
        "name": "Highway and road new construction service",
        "code": "72141001"
      },
      {
        "name": "Highway and road sign or guardrail construction and repair service",
        "code": "72141002"
      },
      { "name": "Highway and road maintenance service", "code": "72141003" },
      { "name": "Highway reflector installation service", "code": "72141004" },
      {
        "name": "Infrastructure building and surfacing and paving services",
        "code": "72141100"
      },
      { "name": "Airport runway construction service", "code": "72141101" },
      { "name": "Land grading service", "code": "72141102" },
      { "name": "Highway and road paving service", "code": "72141103" },
      { "name": "Highway and road resurfacing service", "code": "72141104" },
      {
        "name": "Sidewalk construction and repair service",
        "code": "72141105"
      },
      {
        "name": "Gravel or dirt road construction service",
        "code": "72141106"
      },
      { "name": "Bridge construction and repair service", "code": "72141107" },
      { "name": "Tunnel construction and repair service", "code": "72141108" },
      { "name": "Viaduct construction and repair service", "code": "72141109" },
      {
        "name": "Oil and gas branch line construction service",
        "code": "72141110"
      },
      { "name": "Gas main construction service", "code": "72141111" },
      {
        "name": "Natural gas compressor station construction service",
        "code": "72141112"
      },
      {
        "name": "Oil and gas pipeline construction service",
        "code": "72141113"
      },
      { "name": "Pipeline wrapping service", "code": "72141114" },
      { "name": "Electrical cable laying service", "code": "72141115" },
      { "name": "Television cable laying service", "code": "72141116" },
      {
        "name": "Telephone and communication cable laying service",
        "code": "72141117"
      },
      {
        "name": "Telecommunication transmission tower construction service",
        "code": "72141118"
      },
      { "name": "Aqueduct construction service", "code": "72141119" },
      { "name": "Sewer line construction service", "code": "72141120" },
      { "name": "Water main construction service", "code": "72141121" },
      {
        "name": "Electric power line construction service",
        "code": "72141122"
      },
      { "name": "Manhole construction service", "code": "72141123" },
      { "name": "Pipe laying service", "code": "72141124" },
      { "name": "Pumping station construction service", "code": "72141125" },
      {
        "name": "Underground utilities construction service",
        "code": "72141126"
      },
      {
        "name": "Seal coating of roads, highways and parking lots",
        "code": "72141127"
      },
      {
        "name": "Public square construction and or remodelling service",
        "code": "72141128"
      },
      {
        "name": "Park and garden construction and or remodelling service",
        "code": "72141129"
      },
      {
        "name": "Ornamental fountain construction and or remodelling service",
        "code": "72141130"
      },
      { "name": "Marine construction services", "code": "72141200" },
      { "name": "Caisson drilling service", "code": "72141201" },
      { "name": "Canal construction service", "code": "72141202" },
      { "name": "Dam construction service", "code": "72141203" },
      { "name": "Dock construction service", "code": "72141204" },
      { "name": "Drainage system construction service", "code": "72141205" },
      { "name": "Dredging service", "code": "72141206" },
      { "name": "Harbor construction service", "code": "72141207" },
      { "name": "Levee construction service", "code": "72141209" },
      { "name": "Marina construction service", "code": "72141210" },
      { "name": "Pier construction service", "code": "72141211" },
      { "name": "Pond construction service", "code": "72141212" },
      { "name": "Waterway construction service", "code": "72141213" },
      { "name": "Sheet pile driving service", "code": "72141214" },
      { "name": "Underwater construction service", "code": "72141215" },
      { "name": "Offshore construction vessel service", "code": "72141216" },
      {
        "name": "Detention facility construction and repair services",
        "code": "72141400"
      },
      { "name": "Detention facility construction service", "code": "72141401" },
      {
        "name": "Detention facility remodeling and repair",
        "code": "72141402"
      },
      { "name": "Land preparation services", "code": "72141500" },
      {
        "name": "Land pre-construction assessment service",
        "code": "72141502"
      },
      { "name": "Rock removal service", "code": "72141503" },
      { "name": "Timber removal service", "code": "72141504" },
      { "name": "Earthmoving service", "code": "72141505" },
      { "name": "Pile driving service", "code": "72141507" },
      {
        "name": "Blasting service except building demolition",
        "code": "72141508"
      },
      { "name": "Trenching service", "code": "72141509" },
      { "name": "Demolition services", "code": "72141510" },
      { "name": "Digging services", "code": "72141511" },
      {
        "name": "Mass transit system construction services",
        "code": "72141600"
      },
      { "name": "Light rail construction service", "code": "72141601" },
      { "name": "Right of way cutting service", "code": "72141602" },
      {
        "name": "Railroad and railway roadbed construction service",
        "code": "72141603"
      },
      { "name": "Subway construction service", "code": "72141604" },
      { "name": "Railway track laying service", "code": "72141605" },
      {
        "name": "Construction machinery and equipment rental or leasing services",
        "code": "72141700"
      },
      {
        "name": "Construction machinery rental or leasing service",
        "code": "72141701"
      },
      {
        "name": "Construction equipment rental or leasing service",
        "code": "72141702"
      },
      {
        "name": "Specialized trade construction and maintenance services",
        "code": "72150000"
      },
      {
        "name": "Boiler and furnace construction and maintenance services",
        "code": "72151000"
      },
      { "name": "Boiler maintenance service", "code": "72151001" },
      { "name": "Boiler installation and setup service", "code": "72151002" },
      {
        "name": "Heating system maintenance and repair service",
        "code": "72151003"
      },
      {
        "name": "Hydronics heating system maintenance and repair service",
        "code": "72151004"
      },
      { "name": "Boiler pressure controller installation", "code": "72151005" },
      {
        "name": "Boiler pressure controller maintenance or repair or operation",
        "code": "72151006"
      },
      { "name": "Plumbing construction services", "code": "72151100" },
      { "name": "Septic system construction service", "code": "72151101" },
      {
        "name": "Fire sprinkler system installation service",
        "code": "72151102"
      },
      {
        "name": "Irrigation sprinkler system installation service",
        "code": "72151103"
      },
      {
        "name": "Heating and cooling and air conditioning HVAC construction and maintenance services",
        "code": "72151200"
      },
      { "name": "HVAC mechanical construction service", "code": "72151201" },
      {
        "name": "HVAC process piping construction service",
        "code": "72151202"
      },
      { "name": "HVAC solar energy construction service", "code": "72151203" },
      {
        "name": "HVAC ventilation and duct work construction service",
        "code": "72151204"
      },
      { "name": "HVAC refrigeration construction service", "code": "72151205" },
      {
        "name": "HVAC heating system construction service",
        "code": "72151206"
      },
      {
        "name": "Heating and cooling and air conditioning HVAC installation and maintenance service",
        "code": "72151207"
      },
      { "name": "Painting and paper hanging services", "code": "72151300" },
      { "name": "Residential painting service", "code": "72151301" },
      { "name": "Commercial painting service", "code": "72151302" },
      { "name": "Industrial painting service", "code": "72151303" },
      { "name": "Aircraft painting service", "code": "72151304" },
      { "name": "Bridge painting service", "code": "72151305" },
      { "name": "Pavement marking service", "code": "72151306" },
      { "name": "Ship painting service", "code": "72151307" },
      { "name": "Paper hanging service", "code": "72151308" },
      { "name": "Wall covering construction services", "code": "72151400" },
      {
        "name": "Commercial wall covering construction service",
        "code": "72151401"
      },
      {
        "name": "Residential wall covering construction service",
        "code": "72151402"
      },
      { "name": "Electrical system services", "code": "72151500" },
      { "name": "Lighting installation services", "code": "72151501" },
      {
        "name": "Electric power system construction service",
        "code": "72151502"
      },
      { "name": "Cogeneration plant construction service", "code": "72151503" },
      { "name": "Computer power conditioning service", "code": "72151504" },
      {
        "name": "Standby or emergency power installation service",
        "code": "72151505"
      },
      {
        "name": "Switchgear and related devices installation service",
        "code": "72151506"
      },
      {
        "name": "Electronic controls installation service",
        "code": "72151507"
      },
      {
        "name": "Computerized controls installation service",
        "code": "72151508"
      },
      {
        "name": "Energy management controls installation service",
        "code": "72151509"
      },
      {
        "name": "Environmental system control installation service",
        "code": "72151510"
      },
      {
        "name": "Lighting system maintenance or repair service",
        "code": "72151511"
      },
      {
        "name": "Standby or emergency power maintenance service",
        "code": "72151514"
      },
      { "name": "Electrical inspection service", "code": "72151515" },
      {
        "name": "Specialized communication system services",
        "code": "72151600"
      },
      { "name": "Cable television installation service", "code": "72151601" },
      { "name": "Fiber optic cable installation service", "code": "72151602" },
      {
        "name": "Specialized sound equipment installation service",
        "code": "72151603"
      },
      {
        "name": "Telephone and telephone equipment installation service",
        "code": "72151604"
      },
      { "name": "Voice and data and video wiring service", "code": "72151605" },
      {
        "name": "Underground engineering for communication equipment",
        "code": "72151606"
      },
      {
        "name": "Overground engineering for communication equipment",
        "code": "72151607"
      },
      {
        "name": "Satellite system maintenance or repair service",
        "code": "72151608"
      },
      { "name": "Satellite system hub support service", "code": "72151609" },
      {
        "name": "Safety and security system installation services",
        "code": "72151700"
      },
      {
        "name": "Access control system installation service",
        "code": "72151701"
      },
      {
        "name": "Closed circuit television system installation service",
        "code": "72151702"
      },
      {
        "name": "Fire detection and burglar alarm systems installation service",
        "code": "72151703"
      },
      {
        "name": "Safety instrumented system installation and maintenance service",
        "code": "72151704"
      },
      {
        "name": "Machine installation and maintenance and repair services",
        "code": "72151800"
      },
      {
        "name": "Banking machine installation and maintenance",
        "code": "72151801"
      },
      {
        "name": "Machinery component refurbishing and repair service",
        "code": "72151802"
      },
      {
        "name": "Vending machine installation and maintenance service",
        "code": "72151803"
      },
      { "name": "Masonry and stonework services", "code": "72151900" },
      { "name": "Foundation building service", "code": "72151901" },
      { "name": "Bricklaying service", "code": "72151903" },
      {
        "name": "Chimney construction and maintenance service",
        "code": "72151904"
      },
      { "name": "Concrete block masonry service", "code": "72151905" },
      { "name": "Drain tile installation service", "code": "72151906" },
      { "name": "Exterior marble masonry service", "code": "72151907" },
      {
        "name": "Refractory or acid brick masonry service",
        "code": "72151908"
      },
      { "name": "Stone masonry service", "code": "72151909" },
      {
        "name": "Mortar tuckpointing or restoration service",
        "code": "72151910"
      },
      { "name": "Unit paver installation service", "code": "72151911" },
      { "name": "Plastering and drywall services", "code": "72152000" },
      { "name": "Drywall installation and repair service", "code": "72152001" },
      { "name": "Fresco installation and repair service", "code": "72152002" },
      { "name": "Mantel installation and repair service", "code": "72152003" },
      { "name": "Plain or ornamental plastering service", "code": "72152004" },
      { "name": "Stucco installation and repair service", "code": "72152005" },
      { "name": "Acoustical and insulation services", "code": "72152100" },
      { "name": "Acoustical and ceiling work service", "code": "72152101" },
      {
        "name": "Exterior insulation and finishing service",
        "code": "72152102"
      },
      { "name": "Building insulation service", "code": "72152103" },
      {
        "name": "Solar reflecting insulation film service",
        "code": "72152104"
      },
      {
        "name": "Terrazzo tile and marble and mosaic services",
        "code": "72152200"
      },
      { "name": "Interior marble installation service", "code": "72152201" },
      { "name": "Mosaic creation and repair service", "code": "72152202" },
      {
        "name": "Terrazzo installation and repair service",
        "code": "72152203"
      },
      { "name": "Ceramic tile installation service", "code": "72152204" },
      { "name": "Carpentry services", "code": "72152300" },
      { "name": "Rough carpentry service", "code": "72152301" },
      { "name": "Finish carpentry service", "code": "72152302" },
      {
        "name": "Cabinet building and installation service",
        "code": "72152303"
      },
      {
        "name": "Window and door installation and erection services",
        "code": "72152400"
      },
      {
        "name": "Garage door installation or erection service",
        "code": "72152401"
      },
      {
        "name": "Prefabricated window and door installation service",
        "code": "72152402"
      },
      { "name": "Entryway and exit framing service", "code": "72152403" },
      { "name": "Metal stud installation service", "code": "72152404" },
      { "name": "Store fixture installation service", "code": "72152405" },
      { "name": "Floor laying services", "code": "72152500" },
      {
        "name": "Access flooring system installation service",
        "code": "72152501"
      },
      { "name": "Asphalt tile installation service", "code": "72152502" },
      { "name": "Carpet laying service", "code": "72152503" },
      { "name": "Ceramic floor tile installation service", "code": "72152504" },
      { "name": "Linoleum installation service", "code": "72152505" },
      { "name": "Resilient floor laying service", "code": "72152506" },
      {
        "name": "Vinyl floor tile and sheet installation service",
        "code": "72152507"
      },
      {
        "name": "Wood floor installation and refinishing service",
        "code": "72152508"
      },
      { "name": "Floor leveling service", "code": "72152509" },
      {
        "name": "Roofing and siding and sheet metal services",
        "code": "72152600"
      },
      { "name": "Roofing service", "code": "72152601" },
      { "name": "Gutter and downspout service", "code": "72152602" },
      { "name": "Skylight installation service", "code": "72152603" },
      { "name": "Architectural sheet metal service", "code": "72152604" },
      { "name": "Ceiling erection and repair service", "code": "72152605" },
      { "name": "Siding installation and repair service", "code": "72152606" },
      { "name": "Chute installation service", "code": "72152607" },
      {
        "name": "Concrete installation and repair services",
        "code": "72152700"
      },
      { "name": "Exterior concrete stucco service", "code": "72152701" },
      { "name": "Grouting service", "code": "72152702" },
      { "name": "Gunite installation service", "code": "72152703" },
      { "name": "Curb construction service", "code": "72152704" },
      { "name": "Sidewalk or ramp construction service", "code": "72152705" },
      { "name": "Driveway construction service", "code": "72152706" },
      { "name": "Retaining wall construction service", "code": "72152707" },
      { "name": "Parking lot construction service", "code": "72152708" },
      { "name": "Concrete pumping service", "code": "72152709" },
      {
        "name": "Foundation and footing construction service",
        "code": "72152710"
      },
      { "name": "Concrete patio construction service", "code": "72152711" },
      { "name": "Water well drilling services", "code": "72152800" },
      { "name": "Domestic water well drilling service", "code": "72152801" },
      { "name": "Geothermal water well drilling service", "code": "72152802" },
      { "name": "Structural steel erection services", "code": "72152900" },
      {
        "name": "Metal building front installation service",
        "code": "72152901"
      },
      { "name": "Concrete reinforcement placing service", "code": "72152902" },
      { "name": "Elevator front installation service", "code": "72152903" },
      {
        "name": "Exterior metal wall system installation service",
        "code": "72152904"
      },
      { "name": "Structural iron work service", "code": "72152905" },
      { "name": "Metal lath and furring service", "code": "72152906" },
      {
        "name": "Precast concrete structural framing panel placing service",
        "code": "72152907"
      },
      { "name": "Smoke stack installation service", "code": "72152908" },
      { "name": "Metal storage tank erection service", "code": "72152909" },
      { "name": "Glass and glazing services", "code": "72153000" },
      {
        "name": "Ballistic resistant glazing installation service",
        "code": "72153001"
      },
      { "name": "Glazing installation and repair service", "code": "72153002" },
      {
        "name": "Athletic and recreational facility construction services",
        "code": "72153100"
      },
      { "name": "Bowling alley installation service", "code": "72153101" },
      { "name": "Indoor sport court construction service", "code": "72153102" },
      {
        "name": "Playground construction and equipment installation service",
        "code": "72153103"
      },
      {
        "name": "Spa or hot tub construction and installation service",
        "code": "72153104"
      },
      { "name": "Swimming pool construction service", "code": "72153105" },
      { "name": "Athletic field construction service", "code": "72153106" },
      { "name": "Golf course construction service", "code": "72153107" },
      { "name": "Tennis court construction service", "code": "72153108" },
      {
        "name": "Coating and caulking and weather water and fireproofing services",
        "code": "72153200"
      },
      {
        "name": "Plastic coating of concrete structures service",
        "code": "72153201"
      },
      { "name": "Metal structure coating service", "code": "72153202" },
      { "name": "Corrosion control service", "code": "72153203" },
      { "name": "Damp proofing service", "code": "72153204" },
      { "name": "Caulking service", "code": "72153205" },
      { "name": "Building fireproofing service", "code": "72153206" },
      { "name": "Glazing of concrete surfaces service", "code": "72153207" },
      { "name": "Insulation of pipes and boilers service", "code": "72153208" },
      { "name": "Waterproofing service", "code": "72153209" },
      {
        "name": "Service station equipment installation and maintenance services",
        "code": "72153300"
      },
      { "name": "Gasoline pump installation service", "code": "72153301" },
      { "name": "Diesel pump installation service", "code": "72153302" },
      {
        "name": "Service station equipment maintenance and or repair service",
        "code": "72153303"
      },
      { "name": "Rigging and scaffolding services", "code": "72153400" },
      { "name": "Rigging services", "code": "72153401" },
      { "name": "Scaffolding services", "code": "72153402" },
      { "name": "Structural exterior cleaning services", "code": "72153500" },
      { "name": "Building exterior cleaning service", "code": "72153501" },
      { "name": "Building exterior sandblasting service", "code": "72153502" },
      {
        "name": "Building exterior steam cleaning service",
        "code": "72153503"
      },
      { "name": "High pressure water blasting", "code": "72153504" },
      { "name": "Construction site clean up service", "code": "72153505" },
      {
        "name": "New building post construction cleanup service",
        "code": "72153506"
      },
      { "name": "Construction site haul away service", "code": "72153507" },
      {
        "name": "Interior finishing and furnishing and remodeling services",
        "code": "72153600"
      },
      { "name": "Bathtub refinishing and repair service", "code": "72153601" },
      {
        "name": "Closet organizer installation and repair service",
        "code": "72153602"
      },
      { "name": "Counter top installation service", "code": "72153603" },
      { "name": "Drapery track installation service", "code": "72153604" },
      { "name": "Kitchen and bathroom remodeling service", "code": "72153605" },
      { "name": "Office furniture installation service", "code": "72153606" },
      { "name": "Plastic wall tile installation service", "code": "72153607" },
      { "name": "Window treatment installation service", "code": "72153608" },
      {
        "name": "Domestic or commercial appliance installation service",
        "code": "72153609"
      },
      {
        "name": "Prefabricated fireplace installation service",
        "code": "72153610"
      },
      { "name": "Kitchen cabinet installation service", "code": "72153611" },
      { "name": "Interior design or decorating", "code": "72153612" },
      {
        "name": "Office furniture lease and maintenance service",
        "code": "72153613"
      },
      {
        "name": "Parking facility construction and equipment installation and maintenance and repair services",
        "code": "72153700"
      },
      { "name": "Parking facility equipment installation", "code": "72153701" },
      { "name": "Parking lot maintenance", "code": "72153702" },
      { "name": "Building site preparation services", "code": "72153900" },
      { "name": "Shoring and underpinning work", "code": "72153901" },
      { "name": "Mobile home site set up and tie down", "code": "72153902" },
      { "name": "Specialty building and trades services", "code": "72154000" },
      { "name": "Antenna installation service", "code": "72154001" },
      { "name": "Artificial turf installation service", "code": "72154002" },
      { "name": "Awning installation service", "code": "72154003" },
      { "name": "Building mover service", "code": "72154004" },
      { "name": "Cable splicingservice", "code": "72154005" },
      { "name": "Core drilling and cutting service", "code": "72154006" },
      { "name": "Dewatering service", "code": "72154007" },
      { "name": "Diamond drilling and sawing service", "code": "72154008" },
      {
        "name": "Industrial dock and dock equipment installation service",
        "code": "72154009"
      },
      {
        "name": "Elevator installation maintenance and repair service",
        "code": "72154010"
      },
      { "name": "Epoxy application service", "code": "72154011" },
      {
        "name": "Concrete forms erection and dismantling service",
        "code": "72154012"
      },
      { "name": "Fence construction service", "code": "72154013" },
      {
        "name": "Fiberglass installation and maintenance service",
        "code": "72154014"
      },
      { "name": "Fire escape installation service", "code": "72154015" },
      { "name": "Parade float construction service", "code": "72154016" },
      {
        "name": "Food service equipment installation service",
        "code": "72154017"
      },
      { "name": "Fountain installation service", "code": "72154018" },
      { "name": "Gas leak detection service", "code": "72154019" },
      { "name": "Medical gas system installation service", "code": "72154020" },
      { "name": "Glass tinting service", "code": "72154021" },
      {
        "name": "Hydraulic equipment installation and maintenance service",
        "code": "72154022"
      },
      { "name": "Lightning conductor erection service", "code": "72154023" },
      { "name": "Ornamental metal and iron work service", "code": "72154024" },
      {
        "name": "Petroleum storage tank pumping and draining service",
        "code": "72154025"
      },
      { "name": "Hydrogen cell refueling service", "code": "72154026" },
      { "name": "Posthole digging service", "code": "72154027" },
      {
        "name": "Sign installation and maintenance service",
        "code": "72154028"
      },
      {
        "name": "Steeple jack installation and repair service",
        "code": "72154029"
      },
      {
        "name": "Tower bell installation and repair service",
        "code": "72154030"
      },
      { "name": "On site welding service", "code": "72154031" },
      {
        "name": "Window and door and screening installation and repair service",
        "code": "72154032"
      },
      {
        "name": "Theatrical prop or set or scenery construction erection and repair service",
        "code": "72154033"
      },
      {
        "name": "Underground petroleum storage tank installation service",
        "code": "72154034"
      },
      { "name": "Nuclear power refueling service", "code": "72154035" },
      {
        "name": "Central vacuum cleaning system installation service",
        "code": "72154036"
      },
      { "name": "Safe or vault installation service", "code": "72154037" },
      {
        "name": "Airwave shielding installation for computer room service",
        "code": "72154038"
      },
      { "name": "Building board up service", "code": "72154039" },
      {
        "name": "Underground protective lining installation service",
        "code": "72154040"
      },
      { "name": "Target systems installation service", "code": "72154041" },
      { "name": "Flag pole erection service", "code": "72154042" },
      {
        "name": "Industrial and commercial sprayingservice",
        "code": "72154043"
      },
      { "name": "Water hewning or use service", "code": "72154044" },
      { "name": "Ground water level reduction service", "code": "72154045" },
      { "name": "Escalator installation service", "code": "72154046" },
      { "name": "Lift table service", "code": "72154047" },
      { "name": "Balcony and external walkway service", "code": "72154048" },
      { "name": "Sun screen service", "code": "72154049" },
      {
        "name": "Shopping cart maintenance or repair service",
        "code": "72154050"
      },
      { "name": "Grave relocation service", "code": "72154051" },
      {
        "name": "Post disaster renovation and repair service",
        "code": "72154052"
      },
      { "name": "Broadcasting station repair service", "code": "72154053" },
      { "name": "Catalyst loading or removal service", "code": "72154054" },
      { "name": "Tank cleaning service", "code": "72154055" },
      { "name": "Tank maintenance or repair service", "code": "72154056" },
      { "name": "Storage tank rental service", "code": "72154057" },
      { "name": "Tank and line testing service", "code": "72154058" },
      {
        "name": "Leak detection sealing and repair service",
        "code": "72154059"
      },
      { "name": "Degassing service", "code": "72154060" },
      { "name": "Air filtration service", "code": "72154061" },
      { "name": "Hot tapping service", "code": "72154062" },
      { "name": "Stopple or line stopping service", "code": "72154063" },
      {
        "name": "Portable lighting equipment rental service",
        "code": "72154064"
      },
      {
        "name": "Electrical copying equipment maintenance",
        "code": "72154065"
      },
      { "name": "General office equipment maintenance", "code": "72154066" },
      {
        "name": "Temporary theatrical stage and platform rental and installation",
        "code": "72154067"
      },
      { "name": "Graffiti removal and treatment service", "code": "72154068" },
      { "name": "Fencing and railing service", "code": "72154069" },
      {
        "name": "Distribution and conditioning system equipment maintenance and repair services",
        "code": "72154100"
      },
      {
        "name": "Air compressor rental and maintenance service",
        "code": "72154101"
      },
      {
        "name": "Heat exchanger maintenance or repair service",
        "code": "72154102"
      },
      {
        "name": "Rotary pump maintenance or repair service",
        "code": "72154103"
      },
      { "name": "Gearbox maintenance and repair service", "code": "72154104" },
      {
        "name": "Control valve maintenance and repair service",
        "code": "72154105"
      },
      {
        "name": "Reciprocating compressor rental and maintenance service",
        "code": "72154106"
      },
      {
        "name": "Centrifugal compressor rental and maintenance service",
        "code": "72154107"
      },
      {
        "name": "Reciprocating pump maintenance or repair service",
        "code": "72154108"
      },
      {
        "name": "Centrifugal pump maintenance or repair service",
        "code": "72154109"
      },
      {
        "name": "Fin fan cooling tower maintenance and repair service",
        "code": "72154110"
      },
      {
        "name": "Instrumentation installation maintenance and repair services",
        "code": "72154200"
      },
      {
        "name": "Instrument or meter maintenance and repair service",
        "code": "72154201"
      },
      {
        "name": "Motive and electrical power generation equipment maintenance and repair services",
        "code": "72154300"
      },
      {
        "name": "Turbine equipment maintenance or repair service",
        "code": "72154301"
      },
      {
        "name": "Motor installation and maintenance service",
        "code": "72154302"
      },
      { "name": "Steam turbine maintenance service", "code": "72154303" },
      {
        "name": "Pipefitting fabrication and maintenance services",
        "code": "72154400"
      },
      { "name": "Pipefitting fabrication service", "code": "72154401" },
      {
        "name": "Pipefitting maintenance or repair service",
        "code": "72154402"
      },
      {
        "name": "Heavy equipment installation and maintenance services",
        "code": "72154500"
      },
      {
        "name": "Heavy equipment maintenance and repair service",
        "code": "72154501"
      },
      { "name": "Crane maintenance and repair service", "code": "72154502" },
      { "name": "Crane rental service", "code": "72154503" },
      {
        "name": "Animal habitat and enclosure construction and maintenance services",
        "code": "72154600"
      },
      {
        "name": "Construction of zoo habitat and enclosure for birds and flying species",
        "code": "72154601"
      },
      {
        "name": "Construction of zoo habitat and enclosure for insects and invertebrates",
        "code": "72154602"
      },
      {
        "name": "Construction of zoo habitat and enclosure for land mammals and primates",
        "code": "72154603"
      },
      {
        "name": "Construction of zoo habitat and enclosure for aquatic and amphibious species",
        "code": "72154604"
      },
      {
        "name": "Construction of zoo habitat and enclosure for reptiles",
        "code": "72154605"
      }
    ]
  },
  {
    "name": "Industrial Production and Manufacturing Services",
    "code": "73000000",
    "subcategories": [
      { "name": "Plastic and chemical industries", "code": "73100000" },
      { "name": "Petrochemical and plastic production", "code": "73101500" },
      { "name": "Petroleum refining services", "code": "73101501" },
      { "name": "Natural gas production services", "code": "73101502" },
      { "name": "Oils or greases production services", "code": "73101503" },
      { "name": "Coal production services", "code": "73101504" },
      {
        "name": "Plastics or resins or fibers manufacturing services",
        "code": "73101505"
      },
      { "name": "Chemicals and fertilizers production", "code": "73101600" },
      { "name": "Inorganic chemicals production services", "code": "73101601" },
      {
        "name": "Soda ash or chlorine or caustic soda production services",
        "code": "73101602"
      },
      { "name": "Inorganic acids production services", "code": "73101603" },
      { "name": "Organic chemical production services", "code": "73101604" },
      {
        "name": "Acetylene or derivatives production services",
        "code": "73101605"
      },
      {
        "name": "Ethylene or derivatives production services",
        "code": "73101606"
      },
      {
        "name": "Ethanol or methanol or derivatives production services",
        "code": "73101607"
      },
      { "name": "Fertilizers production services", "code": "73101608" },
      { "name": "Potash mining or processing services", "code": "73101609" },
      { "name": "Pesticide production services", "code": "73101610" },
      {
        "name": "Paints or varnishes or lacquers production services",
        "code": "73101611"
      },
      {
        "name": "Soap or cleaning preparations or perfumes or cosmetics production services",
        "code": "73101612"
      },
      {
        "name": "Solvents or glycols or detergents production services",
        "code": "73101613"
      },
      { "name": "Ferments or enzymes services", "code": "73101614" },
      { "name": "Pharmaceutical production", "code": "73101700" },
      { "name": "Drugs or medicine production services", "code": "73101701" },
      {
        "name": "Vaccines or sera or antibiotics production services",
        "code": "73101702"
      },
      { "name": "Parapharmaceutical production services", "code": "73101703" },
      {
        "name": "Biochemical and biotechnology production",
        "code": "73101800"
      },
      { "name": "Biomass production services", "code": "73101801" },
      { "name": "Bioprotein production services", "code": "73101802" },
      { "name": "Rubber production", "code": "73101900" },
      { "name": "Rubber milling services", "code": "73101901" },
      {
        "name": "Rubber tires or tubes production services",
        "code": "73101902"
      },
      {
        "name": "Rubber or plastic footwear production services",
        "code": "73101903"
      },
      { "name": "Wood and paper industries", "code": "73110000" },
      { "name": "Wood processing", "code": "73111500" },
      { "name": "Sawmilling services", "code": "73111501" },
      { "name": "Veneer production services", "code": "73111502" },
      { "name": "Wood base panels manufacturing services", "code": "73111503" },
      {
        "name": "Wooden containers manufacturing services",
        "code": "73111504"
      },
      { "name": "Furniture manufacturing services", "code": "73111505" },
      { "name": "Cork products manufacturing services", "code": "73111506" },
      { "name": "Cane or wickerware processing services", "code": "73111507" },
      { "name": "Pulp and paper processing", "code": "73111600" },
      { "name": "Pulp production services", "code": "73111601" },
      {
        "name": "Paper or paper board production services",
        "code": "73111602"
      },
      {
        "name": "Hardboard or fiberboard production services",
        "code": "73111603"
      },
      { "name": "Paper production or recycling services", "code": "73111604" },
      { "name": "Metal and mineral industries", "code": "73120000" },
      {
        "name": "Metal smelting and refining and forming processes",
        "code": "73121500"
      },
      { "name": "Ferrous alloy production services", "code": "73121501" },
      {
        "name": "Basic metal combination processes services",
        "code": "73121502"
      },
      {
        "name": "Refractors services for iron or steel production",
        "code": "73121503"
      },
      { "name": "Iron or steel making services", "code": "73121504" },
      { "name": "Iron or steel forging services", "code": "73121505" },
      {
        "name": "Pre finishing iron or steel processes services",
        "code": "73121506"
      },
      { "name": "Finishing metal processing services", "code": "73121507" },
      { "name": "Smelting metal services", "code": "73121508" },
      { "name": "Refining metal services", "code": "73121509" },
      { "name": "Metal finishing", "code": "73121600" },
      { "name": "Metal cutting services", "code": "73121601" },
      { "name": "Blacksmith services", "code": "73121602" },
      { "name": "Metal heating services", "code": "73121603" },
      { "name": "Metal forging services", "code": "73121606" },
      { "name": "Metal drawing services", "code": "73121607" },
      { "name": "Metal extruding services", "code": "73121608" },
      { "name": "Horseshoeing services", "code": "73121610" },
      { "name": "Tinsmithing services", "code": "73121611" },
      { "name": "Rebabbiting services", "code": "73121612" },
      { "name": "Metal casting services", "code": "73121613" },
      { "name": "Non destructive testing service", "code": "73121614" },
      {
        "name": "Non metallic mineral products industry services",
        "code": "73121800"
      },
      {
        "name": "Pottery or china or earthenware manufacturing services",
        "code": "73121801"
      },
      {
        "name": "Glass or glass products manufacturing services",
        "code": "73121802"
      },
      {
        "name": "Structural clay products manufacturing services",
        "code": "73121803"
      },
      {
        "name": "Cement or lime or plaster manufacturing services",
        "code": "73121804"
      },
      {
        "name": "Concrete or aggregates or stone products manufacturing services",
        "code": "73121805"
      },
      { "name": "Abrasives manufacturing services", "code": "73121806" },
      {
        "name": "Asbestos products manufacturing services",
        "code": "73121807"
      },
      { "name": "Food and beverage industries", "code": "73130000" },
      { "name": "Beverage processing", "code": "73131500" },
      { "name": "Spirits distilling or blending services", "code": "73131501" },
      { "name": "Wine processing services", "code": "73131502" },
      { "name": "Brewery processing services", "code": "73131503" },
      {
        "name": "Non alcoholic fruit based beverage processing services",
        "code": "73131504"
      },
      { "name": "Water beverages processing services", "code": "73131505" },
      { "name": "Infused beverages processing services", "code": "73131506" },
      { "name": "Coffee processing services", "code": "73131507" },
      { "name": "Tea processing services", "code": "73131508" },
      { "name": "Meat and poultry and seafood processing", "code": "73131600" },
      {
        "name": "Meat products or by products processing services",
        "code": "73131601"
      },
      {
        "name": "Fish or fish products processing services",
        "code": "73131602"
      },
      { "name": "Poultry processing services", "code": "73131603" },
      { "name": "Meat hygiene or inspection services", "code": "73131604" },
      {
        "name": "Meat plant operation or management services",
        "code": "73131605"
      },
      { "name": "Slaughter houses services", "code": "73131606" },
      { "name": "Butcher services", "code": "73131607" },
      { "name": "Cold storage services", "code": "73131608" },
      { "name": "Fruits and vegetables processing", "code": "73131700" },
      { "name": "Fruit or vegetable cleaning services", "code": "73131701" },
      { "name": "Fruit or vegetable spraying services", "code": "73131702" },
      { "name": "Fruit or vegetable packing services", "code": "73131703" },
      { "name": "Dairy and eggs processing", "code": "73131800" },
      { "name": "Milk processing services", "code": "73131801" },
      { "name": "Egg processing services", "code": "73131802" },
      { "name": "Cheese processing services", "code": "73131803" },
      { "name": "Butter or cream processing services", "code": "73131804" },
      {
        "name": "Grains and sugar and oils and fat processing",
        "code": "73131900"
      },
      { "name": "Cereal products processing services", "code": "73131902" },
      {
        "name": "Sugar or sugar products processing services",
        "code": "73131903"
      },
      {
        "name": "Vegetable oils or fats processing services",
        "code": "73131904"
      },
      { "name": "Spice processing services", "code": "73131905" },
      { "name": "Bakery products processing services", "code": "73131906" },
      {
        "name": "Fibers and textiles and fabric industries",
        "code": "73140000"
      },
      { "name": "Fiber production", "code": "73141500" },
      {
        "name": "Rayon or acetate fiber manufacturing services",
        "code": "73141501"
      },
      { "name": "Glass fiber manufacturing services", "code": "73141502" },
      { "name": "Silk fiber manufacturing services", "code": "73141503" },
      { "name": "Cotton fiber manufacturing services", "code": "73141504" },
      { "name": "Wool fiber manufacturing services", "code": "73141505" },
      { "name": "Polyester fiber manufacturing services", "code": "73141506" },
      { "name": "Polyamide fiber manufacturing services", "code": "73141507" },
      { "name": "Acrylic fiber manufacturing services", "code": "73141508" },
      { "name": "Thread and yarn processing", "code": "73141600" },
      { "name": "Thread processing services", "code": "73141601" },
      { "name": "Yarn processing services", "code": "73141602" },
      { "name": "Fabrics and leather production", "code": "73141700" },
      {
        "name": "Broad woven fabrics manufacturing services",
        "code": "73141701"
      },
      {
        "name": "Narrow woven fabrics manufacturing services",
        "code": "73141702"
      },
      { "name": "Knitwear manufacturing services", "code": "73141703" },
      { "name": "Carpet or rug manufacturing services", "code": "73141704" },
      {
        "name": "Cordage or rope or twine manufacturing services",
        "code": "73141705"
      },
      {
        "name": "Dyeing or printing or finishing services",
        "code": "73141706"
      },
      {
        "name": "Woven suits or coats or overcoats manufacturing services",
        "code": "73141707"
      },
      {
        "name": "Woven outerwear clothing manufacturing services",
        "code": "73141708"
      },
      { "name": "Fur dressing or dyeing services", "code": "73141709" },
      { "name": "Leather footwear manufacturing services", "code": "73141710" },
      {
        "name": "Leather luggage or handbags manufacturing services",
        "code": "73141711"
      },
      {
        "name": "Leather tanning or finishing manufacturing services",
        "code": "73141712"
      },
      { "name": "Nonwoven fabric services", "code": "73141713" },
      { "name": "Braided yarn or fabric services", "code": "73141714" },
      { "name": "Industrial sewing services", "code": "73141715" },
      { "name": "Manufacturing support services", "code": "73150000" },
      { "name": "Assembly services", "code": "73151500" },
      { "name": "Assembly line work", "code": "73151501" },
      { "name": "Joint sealing services", "code": "73151502" },
      {
        "name": "Original design and manufacturing service",
        "code": "73151503"
      },
      { "name": "Electronics manufacturing service", "code": "73151504" },
      { "name": "Sequenced delivery service", "code": "73151505" },
      { "name": "Final or sub-assembly service", "code": "73151506" },
      { "name": "Packaging services", "code": "73151600" },
      { "name": "Canning plants services", "code": "73151601" },
      {
        "name": "Packaging of agricultural by products services",
        "code": "73151602"
      },
      { "name": "Packaging of non food products services", "code": "73151603" },
      { "name": "Packaging of pharmaceuticals services", "code": "73151604" },
      {
        "name": "Point of purchase display packaging services",
        "code": "73151605"
      },
      { "name": "Manual hand packaging services", "code": "73151606" },
      { "name": "Machine assisted packaging services", "code": "73151607" },
      { "name": "Kitting pack service", "code": "73151608" },
      { "name": "Material treatment", "code": "73151700" },
      {
        "name": "Water proofing material treatment services",
        "code": "73151701"
      },
      {
        "name": "Fire protection material treatment services",
        "code": "73151702"
      },
      {
        "name": "Anticorrosion material treatment services",
        "code": "73151703"
      },
      { "name": "Aluminum anodizing service", "code": "73151704" },
      {
        "name": "Anodizing material treatment surfacing service",
        "code": "73151705"
      },
      { "name": "Converting services", "code": "73151800" },
      { "name": "Sheeting services", "code": "73151801" },
      { "name": "Slitting services", "code": "73151802" },
      { "name": "Die cutting services", "code": "73151803" },
      { "name": "Folding services", "code": "73151804" },
      { "name": "Laminating services", "code": "73151805" },
      { "name": "Industrial printing services", "code": "73151900" },
      {
        "name": "Flexographic industrial printing services",
        "code": "73151901"
      },
      {
        "name": "Rotogravure industrial printing services",
        "code": "73151902"
      },
      { "name": "Screen industrial printing services", "code": "73151903" },
      { "name": "Offset industrial printing services", "code": "73151904" },
      { "name": "Digital industrial printing services", "code": "73151905" },
      {
        "name": "Thermal transfer industrial printing services",
        "code": "73151906"
      },
      {
        "name": "Compact disk CD duplication and printing services",
        "code": "73151907"
      },
      { "name": "Filling Services", "code": "73152000" },
      { "name": "Liquid filling services", "code": "73152001" },
      { "name": "Aerosol filling services", "code": "73152002" },
      { "name": "Paste filling services", "code": "73152003" },
      { "name": "Powder filling services", "code": "73152004" },
      {
        "name": "Manufacturing equipment maintenance and repair services",
        "code": "73152100"
      },
      {
        "name": "Manufacturing equipment maintenance services",
        "code": "73152101"
      },
      { "name": "Manufacturing equipment repair services", "code": "73152102" },
      {
        "name": "Engineering equipment maintenance services",
        "code": "73152103"
      },
      {
        "name": "Packaging equipment maintenance and repair service",
        "code": "73152104"
      },
      { "name": "Test equipment refurbishment", "code": "73152105" },
      {
        "name": "Baking and heating equipment maintenance and repair service",
        "code": "73152106"
      },
      {
        "name": "Machining equipment rebuild and refurbishment service",
        "code": "73152107"
      },
      {
        "name": "Electrical equipment maintenance and repair service",
        "code": "73152108"
      },
      {
        "name": "Industrial weight scale maintenance and rental service",
        "code": "73152109"
      },
      {
        "name": "Valve or valve part testing maintenance or repair service",
        "code": "73152112"
      },
      {
        "name": "Machinery and transport equipment manufacture",
        "code": "73160000"
      },
      { "name": "Manufacture of machinery", "code": "73161500" },
      {
        "name": "Engine or turbine manufacturing services",
        "code": "73161501"
      },
      {
        "name": "Agricultural machinery or equipment manufacturing services",
        "code": "73161502"
      },
      {
        "name": "Machine tools or metal or wood working manufacturing services",
        "code": "73161503"
      },
      {
        "name": "Special industrial plants or machinery manufacturing services",
        "code": "73161504"
      },
      {
        "name": "Construction machinery or equipment manufacturing services",
        "code": "73161505"
      },
      {
        "name": "Mining machinery or equipment manufacturing services",
        "code": "73161506"
      },
      {
        "name": "Food products machinery or equipment manufacturing services",
        "code": "73161507"
      },
      {
        "name": "Paper printing machinery or equipment manufacture services",
        "code": "73161508"
      },
      {
        "name": "Metallurgical machinery or equipment manufacture services",
        "code": "73161509"
      },
      {
        "name": "Chemical or pharmaceutical machinery or equipment manufacture services",
        "code": "73161510"
      },
      {
        "name": "Cement plant machinery or equipment manufacture services",
        "code": "73161511"
      },
      {
        "name": "Textile machinery or equipment manufacture services",
        "code": "73161512"
      },
      {
        "name": "Power plant boilers manufacture services",
        "code": "73161513"
      },
      { "name": "Furnaces or ovens manufacture services", "code": "73161514" },
      {
        "name": "Office machinery or equipment manufacture services",
        "code": "73161515"
      },
      {
        "name": "Lifting or hoisting or conveying equipment manufacture services",
        "code": "73161516"
      },
      {
        "name": "Air conditioning or ventilating or refrigeration equipment manufacture services",
        "code": "73161517"
      },
      {
        "name": "Domestic appliances or machines except electrical manufacture services",
        "code": "73161518"
      },
      {
        "name": "Pumps or compressors manufacture services",
        "code": "73161519"
      },
      { "name": "Manufacture of transport equipment", "code": "73161600" },
      { "name": "Fishing ship or boat building services", "code": "73161601" },
      {
        "name": "Railroad rolling stock manufacture services",
        "code": "73161602"
      },
      { "name": "Locomotive manufacture services", "code": "73161603" },
      { "name": "Motor vehicles manufacture services", "code": "73161604" },
      {
        "name": "Motor vehicles parts or accessories manufacture services",
        "code": "73161605"
      },
      {
        "name": "Motorcycle or bicycle manufacture services",
        "code": "73161606"
      },
      { "name": "Air or spacecraft manufacture services", "code": "73161607" },
      {
        "name": "Manufacture of electrical goods and precision instruments",
        "code": "73170000"
      },
      { "name": "Manufacture of electrical goods", "code": "73171500" },
      {
        "name": "Power generation or transmission or distribution equipment manufacture services",
        "code": "73171501"
      },
      {
        "name": "Dry or storage battery manufacture services",
        "code": "73171502"
      },
      { "name": "Electrical tools manufacture services", "code": "73171503" },
      {
        "name": "Measuring or testing instruments manufacture services",
        "code": "73171504"
      },
      {
        "name": "Radio or television manufacture services",
        "code": "73171505"
      },
      {
        "name": "Communication equipment manufacture services",
        "code": "73171506"
      },
      {
        "name": "Electrical household appliances manufacture services",
        "code": "73171507"
      },
      {
        "name": "Insulated wire or cable manufacture services",
        "code": "73171508"
      },
      {
        "name": "Electrical accessories or supplies manufacture services",
        "code": "73171510"
      },
      {
        "name": "Electronic equipment manufacture services",
        "code": "73171511"
      },
      {
        "name": "Electronic computers or data processing equipment manufacture services",
        "code": "73171512"
      },
      { "name": "Epitaxial services", "code": "73171513" },
      { "name": "Manufacture of precision instruments", "code": "73171600" },
      {
        "name": "Scientific instruments or measuring equipment manufacture services",
        "code": "73171601"
      },
      {
        "name": "Medical or dental equipment manufacture services",
        "code": "73171602"
      },
      {
        "name": "Photographic or optical equipment manufacture services",
        "code": "73171603"
      },
      { "name": "Watches or clocks manufacture services", "code": "73171604" },
      {
        "name": "Laboratory equipment manufacture services",
        "code": "73171605"
      },
      { "name": "Test equipment upgrade services", "code": "73171606" },
      { "name": "Machining and processing services", "code": "73180000" },
      { "name": "Machining services", "code": "73181000" },
      { "name": "Turning services", "code": "73181001" },
      {
        "name": "Electro discharge machining EDM services",
        "code": "73181002"
      },
      { "name": "Electro chemical machining ECM services", "code": "73181003" },
      { "name": "Chem milling services", "code": "73181004" },
      { "name": "Punching services", "code": "73181005" },
      { "name": "Stamping services", "code": "73181006" },
      { "name": "Boring services", "code": "73181007" },
      { "name": "Drilling services", "code": "73181008" },
      { "name": "Tapping services", "code": "73181009" },
      { "name": "Laser services", "code": "73181010" },
      { "name": "Bending services", "code": "73181011" },
      { "name": "Grinding services", "code": "73181012" },
      { "name": "Shot blasting services", "code": "73181013" },
      { "name": "Polishing services", "code": "73181014" },
      { "name": "Flame cutting services", "code": "73181015" },
      { "name": "Laser cutting services", "code": "73181016" },
      { "name": "Plasma cutting services", "code": "73181017" },
      { "name": "Water jet cutting services", "code": "73181018" },
      { "name": "Rolling Services", "code": "73181019" },
      { "name": "Surface treatment Services", "code": "73181020" },
      { "name": "Milling services", "code": "73181021" },
      { "name": "Spraying services", "code": "73181022" },
      { "name": "Sharpening service", "code": "73181023" },
      { "name": "Coating services", "code": "73181100" },
      { "name": "Electro coating services", "code": "73181101" },
      { "name": "Dipping services", "code": "73181102" },
      { "name": "Wrapping services", "code": "73181103" },
      { "name": "Painting services", "code": "73181104" },
      { "name": "Web coating services", "code": "73181105" },
      { "name": "Plating services", "code": "73181106" },
      { "name": "Zinc alloy barrel plating service", "code": "73181107" },
      { "name": "Tin plating service", "code": "73181108" },
      { "name": "Nickel plating service", "code": "73181109" },
      { "name": "Electroless nickel plating service", "code": "73181110" },
      { "name": "Precious metals plating service", "code": "73181111" },
      { "name": "Teflon coating service", "code": "73181112" },
      { "name": "Zinc aluminum organic coating service", "code": "73181113" },
      { "name": "Barrel electro coating service", "code": "73181114" },
      { "name": "Nickel chrome plating service", "code": "73181115" },
      { "name": "Thin dense chrome coating service", "code": "73181116" },
      { "name": "Wet spray coating service", "code": "73181117" },
      { "name": "Rack electro coating service", "code": "73181118" },
      { "name": "Powdercoat coating service", "code": "73181119" },
      { "name": "Zinc barrel plating service", "code": "73181120" },
      { "name": "Zinc alloy rack plating service", "code": "73181121" },
      { "name": "Zinc with top coat rack coating service", "code": "73181122" },
      {
        "name": "Zinc with top coat barrel coating service",
        "code": "73181123"
      },
      { "name": "Phoscoating service", "code": "73181124" },
      { "name": "Diamond like coating service", "code": "73181125" },
      { "name": "Forming services", "code": "73181200" },
      { "name": "Draw forming services", "code": "73181201" },
      { "name": "Hydro forming services", "code": "73181202" },
      { "name": "Roll forming services", "code": "73181203" },
      { "name": "Stretch forming services", "code": "73181204" },
      { "name": "Spin forming services", "code": "73181205" },
      { "name": "Explosive forming services", "code": "73181206" },
      { "name": "Heat treatment services", "code": "73181300" },
      { "name": "Quench or temper services", "code": "73181301" },
      { "name": "Annealing services", "code": "73181302" },
      { "name": "Normalizing services", "code": "73181303" },
      { "name": "Aging or stabilizing services", "code": "73181304" },
      { "name": "Braze heat treat service", "code": "73181305" },
      { "name": "Quench and temper heat treat service", "code": "73181306" },
      { "name": "Precipitation harden heat treat service", "code": "73181307" },
      { "name": "Carbonitride heat treat service", "code": "73181308" },
      { "name": "Nitride heat treat service", "code": "73181309" },
      {
        "name": "Vacuum quench and temper heat treat service",
        "code": "73181310"
      },
      { "name": "Vacuum anneal heat treat service", "code": "73181311" },
      { "name": "Carburizing heat treat service", "code": "73181312" },
      { "name": "Induction heat treat service", "code": "73181313" },
      { "name": "Austemper heat treat service", "code": "73181314" },
      {
        "name": "Welding and brazing and soldering services",
        "code": "73181900"
      },
      { "name": "Arc welding services", "code": "73181901" },
      { "name": "Metal inert gas MIG welding services", "code": "73181902" },
      { "name": "Tungsten inert gas TIG welding services", "code": "73181903" },
      { "name": "Laser welding services", "code": "73181904" },
      { "name": "Spot welding services", "code": "73181905" },
      { "name": "Projection welding services", "code": "73181906" },
      { "name": "Brazing services", "code": "73181907" },
      { "name": "Soldering services", "code": "73181908" }
    ]
  },
  {
    "name": "Industrial Cleaning Services",
    "code": "76000000",
    "subcategories": [
      { "name": "Decontamination services", "code": "76100000" },
      { "name": "Disinfection", "code": "76101500" },
      { "name": "Washroom sanitation services", "code": "76101501" },
      { "name": "Rest room cleaning services", "code": "76101502" },
      { "name": "Disinfection or deodorizing services", "code": "76101503" },
      { "name": "Hazardous material decontamination", "code": "76101600" },
      { "name": "Radioactive decontamination services", "code": "76101601" },
      { "name": "Asbestos removal or encapsulation", "code": "76101602" },
      {
        "name": "Lead based paint abatement or encapsulation",
        "code": "76101603"
      },
      { "name": "Mold abatement service", "code": "76101604" },
      {
        "name": "Carbon based filtration and decontamination service",
        "code": "76101605"
      },
      {
        "name": "Chemical based equipment cleaning service",
        "code": "76101606"
      },
      { "name": "Cleaning and janitorial services", "code": "76110000" },
      {
        "name": "General building and office cleaning and maintenance services",
        "code": "76111500"
      },
      { "name": "Building cleaning services", "code": "76111501" },
      { "name": "Lighting maintenance services", "code": "76111503" },
      {
        "name": "Window or window blind cleaning services",
        "code": "76111504"
      },
      { "name": "Fabric and furniture cleaning services", "code": "76111505" },
      { "name": "Interior plant landscaping services", "code": "76111506" },
      {
        "name": "Cleaning services for parks and outdoor public venues",
        "code": "76111507"
      },
      { "name": "Building component cleaning services", "code": "76111600" },
      {
        "name": "Acoustical tile or ceiling cleaning services",
        "code": "76111601"
      },
      { "name": "Air duct cleaning", "code": "76111602" },
      { "name": "Chimney cleaning", "code": "76111603" },
      { "name": "Floor waxing or carpet cleaning", "code": "76111604" },
      { "name": "Exhaust hood or fan clearing", "code": "76111605" },
      { "name": "Transport vehicle cleaning", "code": "76111800" },
      { "name": "Car or boat detailing", "code": "76111801" },
      { "name": "Refuse disposal and treatment", "code": "76120000" },
      { "name": "Refuse collection and disposal", "code": "76121500" },
      {
        "name": "Garbage collection or destruction or processing or disposal",
        "code": "76121501"
      },
      {
        "name": "Liquid waste collection or processing or disposal",
        "code": "76121502"
      },
      { "name": "Street cleaning services", "code": "76121503" },
      { "name": "Tire collection and disposal", "code": "76121504" },
      { "name": "Inorganic waste collection and disposal", "code": "76121505" },
      {
        "name": "Abandoned vehicle recovery and disposal service",
        "code": "76121506"
      },
      {
        "name": "Post event refuse collection and clean up service",
        "code": "76121507"
      },
      { "name": "Nonhazardous waste disposal", "code": "76121600" },
      { "name": "Garbage dump", "code": "76121601" },
      { "name": "Sanitary landfill operations", "code": "76121602" },
      { "name": "Sludge disposal", "code": "76121603" },
      { "name": "Dead animal disposal services", "code": "76121604" },
      { "name": "Liquid waste treatment", "code": "76121700" },
      { "name": "Sewage treatment services", "code": "76121701" },
      { "name": "Chemical treatment services", "code": "76121702" },
      { "name": "Hazardous waste disposal", "code": "76121900" },
      { "name": "Medical waste disposal", "code": "76121901" },
      { "name": "Acid waste collection or disposal", "code": "76121902" },
      { "name": "Chemical detoxification", "code": "76121903" },
      { "name": "Hazardous waste water disposal", "code": "76121904" },
      {
        "name": "Inorganic hazardous waste collection and disposal",
        "code": "76121905"
      },
      { "name": "Landfill services", "code": "76122000" },
      {
        "name": "Landfill for non hazardous generic waste",
        "code": "76122001"
      },
      {
        "name": "Landfill for non hazardous special waste",
        "code": "76122002"
      },
      { "name": "Landfill for hazardous waste", "code": "76122003" },
      {
        "name": "Landfill for low risk hazardous or universal waste",
        "code": "76122004"
      },
      { "name": "Waste to fuel blending services", "code": "76122100" },
      {
        "name": "Fuel blending for non hazardous generic waste",
        "code": "76122101"
      },
      {
        "name": "Fuel blending for non hazardous special waste",
        "code": "76122102"
      },
      { "name": "Fuel blending for hazardous waste", "code": "76122103" },
      { "name": "Waste incineration services", "code": "76122200" },
      {
        "name": "Incineration for non hazardous generic waste",
        "code": "76122201"
      },
      {
        "name": "Incineration for non hazardous special waste",
        "code": "76122202"
      },
      { "name": "Incineration for hazardous waste", "code": "76122203" },
      { "name": "Recycling services", "code": "76122300" },
      { "name": "Recycling of solvents", "code": "76122301" },
      { "name": "Recycling of used oil", "code": "76122302" },
      { "name": "Recycling of cleaning rags", "code": "76122303" },
      { "name": "Recycling of hazardous waste", "code": "76122304" },
      { "name": "Recycling of computer based products", "code": "76122305" },
      { "name": "Recycling of paper", "code": "76122306" },
      { "name": "Recycling of corrugated cardboard", "code": "76122307" },
      { "name": "Recycling of wood", "code": "76122308" },
      { "name": "Recycling of plastic", "code": "76122309" },
      { "name": "Recycling of metal", "code": "76122310" },
      { "name": "Recycling of glass", "code": "76122311" },
      { "name": "Recycling of styrofoam", "code": "76122312" },
      { "name": "Recycling of vinyl", "code": "76122313" },
      { "name": "Recycling of fluorescent lamps", "code": "76122314" },
      { "name": "Recycling of lamp ballasts", "code": "76122315" },
      { "name": "Refuse disposal and treatment fees", "code": "76122400" },
      { "name": "Demurrage fee", "code": "76122401" },
      { "name": "Equipment usage fee", "code": "76122402" },
      { "name": "Fuel recovery fee", "code": "76122403" },
      { "name": "Hazardous waste fee", "code": "76122404" },
      { "name": "Labor fee", "code": "76122405" },
      { "name": "Personal protective equipment fee", "code": "76122406" },
      { "name": "Refuse transportation fee", "code": "76122407" },
      { "name": "State county local waste or recycle fee", "code": "76122408" },
      { "name": "Toxic and hazardous waste cleanup", "code": "76130000" },
      { "name": "Nuclear waste treatment", "code": "76131500" },
      { "name": "Radioactive waste material treatment", "code": "76131501" },
      { "name": "Radioactive containment services", "code": "76131502" },
      { "name": "Toxic spill cleanup", "code": "76131600" },
      { "name": "Toxic spill containment", "code": "76131601" },
      { "name": "Toxic substances spill cleanup", "code": "76131602" },
      { "name": "Oil spill cleanup", "code": "76131700" },
      {
        "name": "Oil residue disposal or control services",
        "code": "76131701"
      },
      { "name": "Oil spill treatment services", "code": "76131702" }
    ]
  },
  {
    "name": "Environmental Services",
    "code": "77000000",
    "subcategories": [
      { "name": "Environmental management", "code": "77100000" },
      { "name": "Environmental impact assessment", "code": "77101500" },
      { "name": "Risk or hazard assessment", "code": "77101501" },
      { "name": "Environmental standards", "code": "77101502" },
      { "name": "Environmental indicators analysis", "code": "77101503" },
      {
        "name": "Environmental Impact Assessment EIA services",
        "code": "77101504"
      },
      { "name": "Environmental monitoring", "code": "77101505" },
      {
        "name": "Development consent or approval processing service",
        "code": "77101506"
      },
      { "name": "Environmental planning", "code": "77101600" },
      {
        "name": "Urban environmental development planning",
        "code": "77101601"
      },
      { "name": "Forest conservation strategy planning", "code": "77101602" },
      { "name": "Marine conservation strategy planning", "code": "77101603" },
      {
        "name": "Natural resources management or conservation strategy planning services",
        "code": "77101604"
      },
      {
        "name": "Environmental institution building or planning",
        "code": "77101605"
      },
      { "name": "Environmental advisory services", "code": "77101700" },
      {
        "name": "Environmental sciences advisory services",
        "code": "77101701"
      },
      {
        "name": "Environmental chemistry advisory services",
        "code": "77101702"
      },
      { "name": "Environmental ethics advisory services", "code": "77101703" },
      {
        "name": "Environmental technology advisory services",
        "code": "77101704"
      },
      {
        "name": "Environmental economics advisory services",
        "code": "77101705"
      },
      { "name": "Environmental law advisory services", "code": "77101706" },
      { "name": "Ecology advisory services", "code": "77101707" },
      { "name": "Environmental auditing", "code": "77101800" },
      { "name": "Environmental information systems", "code": "77101801" },
      {
        "name": "Corporate environmental auditing services",
        "code": "77101802"
      },
      {
        "name": "Sectoral environmental auditing services",
        "code": "77101803"
      },
      {
        "name": "Activity specific environmental auditing services",
        "code": "77101804"
      },
      { "name": "Environmental quality control services", "code": "77101805" },
      { "name": "Environmental security control services", "code": "77101806" },
      { "name": "Pollution investigation services", "code": "77101900" },
      { "name": "Industrial site investigation", "code": "77101901" },
      { "name": "Industrial waste site investigation", "code": "77101902" },
      { "name": "Gasworks site investigation", "code": "77101903" },
      {
        "name": "Chemical works or oil refinery waste site investigation",
        "code": "77101904"
      },
      { "name": "Wood treatment plant site investigation", "code": "77101905" },
      {
        "name": "Oil depot or terminal site investigation",
        "code": "77101906"
      },
      { "name": "Dry cleaning plants site investigation", "code": "77101907" },
      { "name": "Foundry site investigation", "code": "77101908" },
      { "name": "Recycling plant site investigation", "code": "77101909" },
      {
        "name": "Food processing plant site investigation",
        "code": "77101910"
      },
      { "name": "Environmental reporting services", "code": "77102000" },
      { "name": "Legal compliance certification service", "code": "77102001" },
      { "name": "Emission reporting compliance service", "code": "77102002" },
      {
        "name": "Safety compliance or accident reporting service",
        "code": "77102003"
      },
      {
        "name": "Waste generation or disposal reporting service",
        "code": "77102004"
      },
      { "name": "Environmental protection", "code": "77110000" },
      { "name": "Environmental safety services", "code": "77111500" },
      { "name": "Landscape protection services", "code": "77111501" },
      { "name": "Ozone protection services", "code": "77111502" },
      {
        "name": "Food or feed contamination protection services",
        "code": "77111503"
      },
      { "name": "Genetic resources protection services", "code": "77111504" },
      { "name": "Toxic substances protection services", "code": "77111505" },
      { "name": "Radiation protection services", "code": "77111506" },
      { "name": "Endangered species protection services", "code": "77111507" },
      {
        "name": "Natural risks or hazards protection services",
        "code": "77111508"
      },
      { "name": "Environmental rehabilitation", "code": "77111600" },
      { "name": "Industrial site rehabilitation", "code": "77111601" },
      { "name": "Environmental decontamination services", "code": "77111602" },
      { "name": "Land rehabilitation services", "code": "77111603" },
      {
        "name": "Pollution tracking and monitoring and rehabilitation",
        "code": "77120000"
      },
      { "name": "Air pollution", "code": "77121500" },
      { "name": "Air quality management", "code": "77121501" },
      {
        "name": "Transboundary air pollution management or control services",
        "code": "77121502"
      },
      { "name": "Air pollution protection services", "code": "77121503" },
      {
        "name": "Air pollution monitoring or measurement services",
        "code": "77121504"
      },
      { "name": "Toxic gas detection services", "code": "77121505" },
      { "name": "Methane monitoring", "code": "77121506" },
      { "name": "Carbon dioxide monitoring services", "code": "77121507" },
      { "name": "Airborne particle monitoring", "code": "77121508" },
      { "name": "Ozone depletion monitoring services", "code": "77121509" },
      { "name": "Soil pollution", "code": "77121600" },
      { "name": "Soil pollution protection services", "code": "77121601" },
      { "name": "Polluted soil removal services", "code": "77121602" },
      {
        "name": "Polluted soil treatment or rehabilitation",
        "code": "77121603"
      },
      { "name": "Soil pollution advisory services", "code": "77121604" },
      { "name": "Soil pollution mapping", "code": "77121605" },
      {
        "name": "Soil pollution measurement or monitoring",
        "code": "77121606"
      },
      { "name": "Organic fertilizer pollution assessment", "code": "77121607" },
      { "name": "Pesticides pollution assessment", "code": "77121608" },
      { "name": "Nitrates pollution assessment", "code": "77121609" },
      { "name": "Phosphates pollution assessment", "code": "77121610" },
      { "name": "Water pollution", "code": "77121700" },
      {
        "name": "Surface water pollution monitoring or control services",
        "code": "77121701"
      },
      {
        "name": "Surface water pollution rehabilitation services",
        "code": "77121702"
      },
      {
        "name": "Surface water pollution protection services",
        "code": "77121703"
      },
      { "name": "Surface water treatment services", "code": "77121704" },
      {
        "name": "Surface water pollution drainage services",
        "code": "77121705"
      },
      {
        "name": "Transboundary water pollution management or control services",
        "code": "77121706"
      },
      {
        "name": "Groundwater pollution monitoring or control services",
        "code": "77121707"
      },
      { "name": "Groundwater pollution drainage services", "code": "77121708" },
      {
        "name": "Groundwater pollution treatment or rehabilitation",
        "code": "77121709"
      },
      {
        "name": "Pollutants tracking and monitoring and rehabilitation services",
        "code": "77130000"
      },
      { "name": "Oil pollution", "code": "77131500" },
      { "name": "Oil spillage monitoring services", "code": "77131501" },
      { "name": "Oil spillage control services", "code": "77131502" },
      { "name": "Oil spillage rehabilitation services", "code": "77131503" },
      { "name": "Noise pollution", "code": "77131600" },
      { "name": "Noise control services", "code": "77131601" },
      { "name": "Noise pollution protection services", "code": "77131602" },
      { "name": "Noise pollution monitoring services", "code": "77131603" },
      { "name": "Noise pollution advisory services", "code": "77131604" },
      { "name": "Toxic substances pollution", "code": "77131700" },
      { "name": "Toxic substances monitoring services", "code": "77131701" },
      { "name": "Toxic substances rehabilitation services", "code": "77131702" }
    ]
  },
  {
    "name": "Transportation and Storage and Mail Services",
    "code": "78000000",
    "subcategories": [
      { "name": "Mail and cargo transport", "code": "78100000" },
      { "name": "Air cargo transport", "code": "78101500" },
      { "name": "Domestic air cargo transport", "code": "78101501" },
      { "name": "International air cargo transport", "code": "78101502" },
      { "name": "Armored air transport", "code": "78101503" },
      { "name": "Air transport of letters and parcels", "code": "78101504" },
      {
        "name": "Air transport of livestock or live animals",
        "code": "78101505"
      },
      { "name": "Rail cargo transport", "code": "78101600" },
      { "name": "Boxcar transport services", "code": "78101601" },
      { "name": "Bulk cargo rail transport services", "code": "78101602" },
      { "name": "Livestock rail transport", "code": "78101603" },
      { "name": "Vehicle transport services", "code": "78101604" },
      { "name": "Rail tankcar leasing service", "code": "78101605" },
      {
        "name": "Rail boxcar or cargo car leasing service",
        "code": "78101606"
      },
      { "name": "Railway pushing or towing service", "code": "78101607" },
      {
        "name": "Railway transport services of letters and parcels",
        "code": "78101608"
      },
      { "name": "Railway transport by tanker car", "code": "78101609" },
      { "name": "Railway transport by refrigerator car", "code": "78101610" },
      { "name": "Marine cargo transport", "code": "78101700" },
      { "name": "Domestic vessel transport services", "code": "78101701" },
      { "name": "International vessel transport services", "code": "78101702" },
      { "name": "Domestic barge transport services", "code": "78101703" },
      { "name": "International barge transport services", "code": "78101704" },
      { "name": "Armored marine transport", "code": "78101705" },
      {
        "name": "Oil and gas offshore platform supply vessel service",
        "code": "78101706"
      },
      { "name": "Inland water transport by tankers", "code": "78101707" },
      {
        "name": "Inland water transport by refrigerator vessels",
        "code": "78101708"
      },
      {
        "name": "Coastal and transoceanic transport by tankers",
        "code": "78101709"
      },
      {
        "name": "Coastal and transoceanic water transport by refrigerator vessels",
        "code": "78101710"
      },
      {
        "name": "Marine transport of livestock or live animals",
        "code": "78101711"
      },
      { "name": "Road cargo transport", "code": "78101800" },
      { "name": "Local area trucking services", "code": "78101801" },
      { "name": "Regional or national trucking services", "code": "78101802" },
      { "name": "Vehicle carrier services", "code": "78101803" },
      { "name": "Relocation services", "code": "78101804" },
      { "name": "Tanker truck and trailer rental service", "code": "78101805" },
      { "name": "International trucking service", "code": "78101806" },
      { "name": "Petroleum or chemical trucking service", "code": "78101807" },
      { "name": "Road transport of dry bulk", "code": "78101808" },
      { "name": "Road transport of letters and parcels", "code": "78101809" },
      {
        "name": "Road transport of livestock or live animals",
        "code": "78101810"
      },
      { "name": "Intermodal cargo transport", "code": "78101900" },
      { "name": "Air to ocean transportation", "code": "78101901" },
      { "name": "Ocean to rail transportation", "code": "78101902" },
      { "name": "Ocean to truck transportation", "code": "78101903" },
      { "name": "Air to truck transport", "code": "78101904" },
      { "name": "Rail truck transportation", "code": "78101905" },
      { "name": "Spacecraft cargo transport", "code": "78102000" },
      { "name": "Satellite launch services", "code": "78102001" },
      { "name": "Experimental payload services", "code": "78102002" },
      { "name": "Pipeline services", "code": "78102100" },
      { "name": "Petroleum products transport", "code": "78102101" },
      { "name": "Water transport", "code": "78102102" },
      { "name": "Pipeline inline inspection service", "code": "78102103" },
      {
        "name": "Postal and small parcel and courier services",
        "code": "78102200"
      },
      { "name": "National postal delivery services", "code": "78102201" },
      { "name": "Post office box services", "code": "78102202" },
      {
        "name": "Mailing or mail pick up or delivery services",
        "code": "78102203"
      },
      {
        "name": "Letter or small parcel worldwide delivery services",
        "code": "78102204"
      },
      {
        "name": "Letter or small parcel local delivery services",
        "code": "78102205"
      },
      { "name": "Bicycle or scooter messenger services", "code": "78102206" },
      { "name": "Passenger transport", "code": "78110000" },
      { "name": "Passenger air transportation", "code": "78111500" },
      { "name": "Helicopter services", "code": "78111501" },
      { "name": "Commercial airplane travel", "code": "78111502" },
      { "name": "Chartered airplane travel", "code": "78111503" },
      { "name": "Sightseeing service by air", "code": "78111504" },
      { "name": "Passenger railway transportation", "code": "78111600" },
      {
        "name": "Light rail vehicle transport LRV services",
        "code": "78111601"
      },
      { "name": "Subway transport", "code": "78111602" },
      {
        "name": "Continental or inter continental rail services",
        "code": "78111603"
      },
      { "name": "Sightseeing service by rail", "code": "78111604" },
      { "name": "Passenger marine transportation", "code": "78111700" },
      { "name": "Water taxis", "code": "78111701" },
      { "name": "Overnight ship cruises", "code": "78111702" },
      { "name": "Sightseeing boat excursions", "code": "78111703" },
      { "name": "Marine craft rental or leasing service", "code": "78111704" },
      {
        "name": "Oil and gas offshore platform personnel transportation service",
        "code": "78111705"
      },
      {
        "name": "Coastal and transoceanic water transport of passengers by ferry",
        "code": "78111706"
      },
      {
        "name": "Inland water transport of passengers by ferry",
        "code": "78111707"
      },
      { "name": "Passenger road transportation", "code": "78111800" },
      { "name": "Scheduled bus services", "code": "78111802" },
      { "name": "Chartered bus services", "code": "78111803" },
      { "name": "Taxicab services", "code": "78111804" },
      { "name": "Parking fees", "code": "78111807" },
      { "name": "Vehicle rental", "code": "78111808" },
      {
        "name": "Vehicle leasing of sedans or coupes or station wagons",
        "code": "78111809"
      },
      { "name": "Limousine or town car service", "code": "78111810" },
      {
        "name": "Vehicle leasing of light trucks and sport utility vehicles",
        "code": "78111811"
      },
      {
        "name": "Vehicle leasing of passenger vans or minivans",
        "code": "78111812"
      },
      {
        "name": "Sightseeing service by land, except rail",
        "code": "78111813"
      },
      {
        "name": "Road transport of passengers by man-or animal-drawn vehicle",
        "code": "78111814"
      },
      { "name": "Space transportation", "code": "78111900" },
      { "name": "Experimental or educational missions", "code": "78111901" },
      { "name": "Intermodal passenger transport services", "code": "78112000" },
      {
        "name": "Mixed mode urban and suburban transportation for passengers",
        "code": "78112001"
      },
      { "name": "Material packing and handling", "code": "78120000" },
      { "name": "Packing", "code": "78121500" },
      { "name": "Containerization of goods", "code": "78121501" },
      { "name": "Crating services", "code": "78121502" },
      { "name": "Material handling services", "code": "78121600" },
      { "name": "Freight loading or unloading", "code": "78121601" },
      { "name": "Weighing services", "code": "78121602" },
      { "name": "Freight fee", "code": "78121603" },
      { "name": "Forklift rental or leasing service", "code": "78121604" },
      { "name": "Storage", "code": "78130000" },
      { "name": "Farm products warehousing", "code": "78131500" },
      { "name": "Silo services", "code": "78131501" },
      { "name": "Grain elevator services", "code": "78131502" },
      { "name": "General goods storage", "code": "78131600" },
      { "name": "Palletized cargo storage", "code": "78131601" },
      { "name": "File archive storage", "code": "78131602" },
      { "name": "Furniture storage", "code": "78131603" },
      { "name": "Bulk storage", "code": "78131700" },
      { "name": "In ground storage services", "code": "78131701" },
      { "name": "Above ground storage or tankage service", "code": "78131702" },
      { "name": "Specialized warehousing and storage", "code": "78131800" },
      { "name": "Refrigerated storage", "code": "78131801" },
      { "name": "Customs bonded storage services", "code": "78131802" },
      { "name": "Hazardous materials storage", "code": "78131803" },
      { "name": "Document storage services", "code": "78131804" },
      { "name": "Storage of automatic teller machines", "code": "78131805" },
      { "name": "Self storage or mini storage service", "code": "78131806" },
      { "name": "Critical spare part storage service", "code": "78131807" },
      { "name": "Transport services", "code": "78140000" },
      { "name": "Transport arranging services", "code": "78141500" },
      { "name": "Freight forwarders services", "code": "78141501" },
      { "name": "Customs brokerage services", "code": "78141502" },
      {
        "name": "Transportation industry tariff comparison or freight audit services",
        "code": "78141503"
      },
      {
        "name": "Supplier or vendor managed freight and rebilling",
        "code": "78141504"
      },
      {
        "name": "Towing service for commercial and private vehicles",
        "code": "78141505"
      },
      { "name": "Inspection", "code": "78141600" },
      { "name": "Packing inspection services", "code": "78141601" },
      { "name": "Cargo survey services", "code": "78141602" },
      { "name": "Pest control inspections", "code": "78141603" },
      { "name": "Navigational services", "code": "78141700" },
      { "name": "Tugboat services", "code": "78141701" },
      { "name": "Drawbridge operations", "code": "78141702" },
      {
        "name": "Marine navigational or communication services",
        "code": "78141703"
      },
      { "name": "Dead man anchor service", "code": "78141704" },
      { "name": "Terminal services", "code": "78141800" },
      { "name": "Stevedoring services", "code": "78141801" },
      { "name": "Vessel docking services", "code": "78141802" },
      { "name": "Vessel stores services", "code": "78141803" },
      { "name": "Loading terminal facility management", "code": "78141804" },
      {
        "name": "Aerodrome or airport or aviation facility operations service",
        "code": "78141805"
      },
      {
        "name": "Oil and gas offshore support shore base service",
        "code": "78141806"
      },
      { "name": "Air traffic control service", "code": "78141807" },
      { "name": "Transport container rental services", "code": "78141900" },
      { "name": "Storage basket rental service", "code": "78141901" },
      { "name": "Tote rental", "code": "78141902" },
      {
        "name": "Intermodal cargo container rental service",
        "code": "78141903"
      },
      {
        "name": "Transport conveyance rental or lease services",
        "code": "78142000"
      },
      {
        "name": "Rental of freight aircraft with operator",
        "code": "78142001"
      },
      {
        "name": "Rental of freight vessel for inland water transport with operator",
        "code": "78142002"
      },
      {
        "name": "Rental of freight vessel for coastal and transoceanic water transport with operator",
        "code": "78142003"
      },
      { "name": "Rental of truck with operator", "code": "78142004" },
      {
        "name": "Transportation repair or maintenance services",
        "code": "78180000"
      },
      { "name": "Vehicle maintenance and repair services", "code": "78181500" },
      { "name": "Vehicle body repair or painting service", "code": "78181501" },
      { "name": "Transmission repair", "code": "78181502" },
      {
        "name": "Oil or transmission fluid change service",
        "code": "78181503"
      },
      { "name": "Landing gear repair", "code": "78181504" },
      { "name": "Vehicle inspection service", "code": "78181505" },
      { "name": "Vehicle glass replacement service", "code": "78181506" },
      {
        "name": "Automotive and light truck maintenance and repair",
        "code": "78181507"
      },
      { "name": "Heavy truck maintenance and repair", "code": "78181508" },
      {
        "name": "Rail car inspection and maintenance service",
        "code": "78181509"
      },
      {
        "name": "Vehicle lighting system maintenance service",
        "code": "78181510"
      },
      {
        "name": "Vehicle license plate installation service",
        "code": "78181511"
      },
      { "name": "Panel and paint services", "code": "78181600" },
      { "name": "Panelbeating service", "code": "78181601" },
      {
        "name": "Transport fueling and vehicle storage and support services",
        "code": "78181700"
      },
      { "name": "Vehicle fueling service", "code": "78181701" },
      { "name": "Transportation storage service", "code": "78181702" },
      { "name": "Vehicle parking service", "code": "78181703" },
      {
        "name": "Aircraft maintenance and repair services",
        "code": "78181800"
      },
      { "name": "Aircraft fixed wing maintenance service", "code": "78181801" },
      {
        "name": "Aircraft rotary wing maintenance service",
        "code": "78181802"
      },
      {
        "name": "Navigational equipment maintenance and repair services",
        "code": "78181900"
      },
      {
        "name": "Maintenance or repair of navigation equipment",
        "code": "78181901"
      },
      {
        "name": "Water transport vessel maintenance and repair services",
        "code": "78182000"
      },
      {
        "name": "Inland water vessel salvage and refloating service",
        "code": "78182001"
      },
      {
        "name": "Coastal and transoceanic water vessel salvage and refloating service",
        "code": "78182002"
      }
    ]
  },
  {
    "name": "Management and Business Professionals and Administrative Services",
    "code": "80000000",
    "subcategories": [
      { "name": "Management advisory services", "code": "80100000" },
      {
        "name": "Business and corporate management consultation services",
        "code": "80101500"
      },
      {
        "name": "New business start up consultation services",
        "code": "80101501"
      },
      { "name": "Corporate mergers consultation services", "code": "80101502" },
      {
        "name": "Corporate divestiture consultation services",
        "code": "80101503"
      },
      {
        "name": "Strategic planning consultation services",
        "code": "80101504"
      },
      {
        "name": "Corporate objectives or policy development",
        "code": "80101505"
      },
      { "name": "Organizational structure consultation", "code": "80101506" },
      {
        "name": "Information technology consultation services",
        "code": "80101507"
      },
      {
        "name": "Business intelligence consulting services",
        "code": "80101508"
      },
      {
        "name": "Government affairs and community relations consultation service",
        "code": "80101509"
      },
      { "name": "Risk management consultation service", "code": "80101510" },
      { "name": "Human resources consulting service", "code": "80101511" },
      { "name": "Actuarial consulting services", "code": "80101512" },
      {
        "name": "Process and procedures management consultation service",
        "code": "80101513"
      },
      { "name": "Project management", "code": "80101600" },
      {
        "name": "Feasibility studies or screening of project ideas",
        "code": "80101601"
      },
      {
        "name": "Regional or location studies for projects",
        "code": "80101602"
      },
      {
        "name": "Economic or financial evaluation of projects",
        "code": "80101603"
      },
      { "name": "Project administration or planning", "code": "80101604" },
      { "name": "Temporary drafting service", "code": "80101605" },
      { "name": "Project monitoring and evaluation", "code": "80101606" },
      { "name": "Project impact assessment", "code": "80101607" },
      { "name": "Industrial management", "code": "80101700" },
      { "name": "Factory management services", "code": "80101701" },
      {
        "name": "Productivity or efficiency studies or implementation",
        "code": "80101702"
      },
      { "name": "Specification standardization services", "code": "80101703" },
      {
        "name": "Supply chain analysis or re engineering services",
        "code": "80101704"
      },
      { "name": "Co operative or consortium services", "code": "80101705" },
      { "name": "Professional procurement services", "code": "80101706" },
      { "name": "Lobbying services", "code": "80101707" },
      { "name": "Chemical management service", "code": "80101708" },
      { "name": "Human resources services", "code": "80110000" },
      { "name": "Human resource development", "code": "80111500" },
      { "name": "Management development", "code": "80111501" },
      { "name": "Compensation or benefits planning", "code": "80111502" },
      { "name": "Labor or union relations", "code": "80111503" },
      { "name": "Labor training or development", "code": "80111504" },
      { "name": "Human resources productivity audits", "code": "80111505" },
      { "name": "Personnel relocation", "code": "80111506" },
      { "name": "Outplacement services", "code": "80111507" },
      { "name": "Service recognition programs", "code": "80111508" },
      { "name": "Job evaluation service", "code": "80111509" },
      {
        "name": "Job description development and writing service",
        "code": "80111510"
      },
      { "name": "Labor training impact assessment", "code": "80111511" },
      { "name": "Temporary personnel services", "code": "80111600" },
      {
        "name": "Temporary clerical or administrative assistance",
        "code": "80111601"
      },
      { "name": "Temporary marketing staff needs", "code": "80111602" },
      { "name": "Temporary production staffing needs", "code": "80111603" },
      { "name": "Temporary technician staffing needs", "code": "80111604" },
      { "name": "Temporary financial staffing needs", "code": "80111605" },
      { "name": "Temporary medical staffing needs", "code": "80111606" },
      { "name": "Temporary legal staffing needs", "code": "80111607" },
      {
        "name": "Temporary information technology software developers",
        "code": "80111608"
      },
      {
        "name": "Temporary information technology systems or database administrators",
        "code": "80111609"
      },
      {
        "name": "Temporary information technology networking specialists",
        "code": "80111610"
      },
      { "name": "Temporary warehouse staff", "code": "80111611" },
      { "name": "Temporary drivers", "code": "80111612" },
      { "name": "Temporary manual labor", "code": "80111613" },
      { "name": "Temporary engineering services", "code": "80111614" },
      { "name": "Temporary machinist personnel", "code": "80111615" },
      { "name": "Temporary customer service personnel", "code": "80111616" },
      { "name": "Temporary architectural services", "code": "80111617" },
      { "name": "Temporary construction services", "code": "80111618" },
      { "name": "Temporary creative services", "code": "80111619" },
      { "name": "Temporary human resources services", "code": "80111620" },
      {
        "name": "Temporary research and development services",
        "code": "80111621"
      },
      {
        "name": "Temporary safety health environmental services",
        "code": "80111622"
      },
      {
        "name": "Temporary sourcing and logistics services",
        "code": "80111623"
      },
      { "name": "Temporary Travel Staffing", "code": "80111624" },
      { "name": "Temporary manual labor underground", "code": "80111625" },
      { "name": "Personnel recruitment", "code": "80111700" },
      { "name": "Staff recruiting services", "code": "80111701" },
      { "name": "Reference or background check services", "code": "80111702" },
      {
        "name": "Resume or curriculum vitae screening services",
        "code": "80111703"
      },
      { "name": "Permanent marketing staff needs", "code": "80111704" },
      { "name": "Permanent machinist personnel", "code": "80111705" },
      {
        "name": "Permanent clerical or administrative assistance",
        "code": "80111706"
      },
      { "name": "Permanent technical staffing needs", "code": "80111707" },
      { "name": "Permanent financial staffing needs", "code": "80111708" },
      { "name": "Permanent medical staff needs", "code": "80111709" },
      { "name": "Permanent legal staffing needs", "code": "80111710" },
      {
        "name": "Permanent information technology software developers",
        "code": "80111711"
      },
      {
        "name": "Permanent information technology networking specialists",
        "code": "80111712"
      },
      {
        "name": "Permanent information technology systems or database administrators",
        "code": "80111713"
      },
      { "name": "Permanent drivers", "code": "80111714" },
      { "name": "Permanent professional staff", "code": "80111715" },
      {
        "name": "Permanent information technology staffing needs",
        "code": "80111716"
      },
      { "name": "Employee physical screening service", "code": "80111717" },
      {
        "name": "Employee skill testing and assessment service",
        "code": "80111718"
      },
      { "name": "Employee psychometric testing service", "code": "80111719" },
      { "name": "Legal services", "code": "80120000" },
      { "name": "Criminal law services", "code": "80121500" },
      { "name": "Juvenile justice law services", "code": "80121501" },
      { "name": "Appellate procedure services", "code": "80121502" },
      { "name": "Defense or criminal law services", "code": "80121503" },
      { "name": "Business law services", "code": "80121600" },
      {
        "name": "Government antitrust or regulations law services",
        "code": "80121601"
      },
      { "name": "Bankruptcy law services", "code": "80121602" },
      { "name": "Partnership law", "code": "80121603" },
      { "name": "Patent or trademark or copyright law", "code": "80121604" },
      { "name": "Liquidation law services", "code": "80121605" },
      { "name": "Real estate law", "code": "80121606" },
      { "name": "Taxation law", "code": "80121607" },
      { "name": "Mergers or acquisitions law", "code": "80121608" },
      { "name": "Legal Research Services", "code": "80121609" },
      { "name": "Debt collection law services", "code": "80121610" },
      { "name": "Healthcare claim law services", "code": "80121611" },
      { "name": "Civil liability services", "code": "80121700" },
      { "name": "Malpractice or negligence law services", "code": "80121701" },
      { "name": "Personal injury law services", "code": "80121702" },
      { "name": "Property law services", "code": "80121703" },
      { "name": "Contract law services", "code": "80121704" },
      { "name": "Employee benefits law services", "code": "80121705" },
      { "name": "Employment law services", "code": "80121706" },
      { "name": "Labor disputes law services", "code": "80121707" },
      { "name": "Insurance law service", "code": "80121708" },
      { "name": "Family law services", "code": "80121800" },
      { "name": "Divorce law services", "code": "80121801" },
      { "name": "Adoption law services", "code": "80121802" },
      { "name": "Immigration or naturalization law", "code": "80121803" },
      { "name": "Guardianship or custody law services", "code": "80121804" },
      {
        "name": "Compensated legal participation services",
        "code": "80121900"
      },
      { "name": "Jury member service", "code": "80121901" },
      { "name": "Witness service", "code": "80121902" },
      { "name": "Expert witness service", "code": "80121903" },
      { "name": "Process server service", "code": "80121904" },
      { "name": "Legal review and inquiry services", "code": "80122000" },
      {
        "name": "Independent dispute mediation or arbitration service",
        "code": "80122001"
      },
      {
        "name": "Independent commission or board of inquiry service",
        "code": "80122002"
      },
      { "name": "Administrative law services", "code": "80122100" },
      { "name": "Administrative law consultation service", "code": "80122101" },
      { "name": "Municipality law consultation service", "code": "80122102" },
      { "name": "Computing law consultation service", "code": "80122103" },
      { "name": "Constitutional law services", "code": "80122200" },
      { "name": "Constitutional law consultation service", "code": "80122201" },
      {
        "name": "Constitutional law public sector defense consultation service",
        "code": "80122202"
      },
      { "name": "International law services", "code": "80122300" },
      { "name": "International law consultation service", "code": "80122301" },
      { "name": "Real estate services", "code": "80130000" },
      {
        "name": "Lease and rental of property or building",
        "code": "80131500"
      },
      { "name": "Residential rental", "code": "80131501" },
      {
        "name": "Commercial or industrial facility rental",
        "code": "80131502"
      },
      { "name": "Land leases", "code": "80131503" },
      { "name": "Offshore temporary housing service", "code": "80131504" },
      {
        "name": "Portable or modular office rental service",
        "code": "80131505"
      },
      { "name": "Portable toilet rental service", "code": "80131506" },
      { "name": "Sale of property and building", "code": "80131600" },
      { "name": "Real estate brokers or agents", "code": "80131601" },
      { "name": "Real estate auction", "code": "80131602" },
      { "name": "Sale of residential land", "code": "80131603" },
      { "name": "Sale of commercial or industrial land", "code": "80131604" },
      { "name": "Sale of commercial building", "code": "80131605" },
      { "name": "Escrow and title services", "code": "80131700" },
      { "name": "Title reconveyance services", "code": "80131701" },
      { "name": "Title search services", "code": "80131702" },
      { "name": "Escrow account services", "code": "80131703" },
      { "name": "Real estate management services", "code": "80131800" },
      { "name": "Property management", "code": "80131801" },
      {
        "name": "Real estate appraisal and valuation service",
        "code": "80131802"
      },
      { "name": "Real estate listing services", "code": "80131803" },
      { "name": "Marketing and distribution", "code": "80140000" },
      { "name": "Market research", "code": "80141500" },
      { "name": "Marketing analysis", "code": "80141501" },
      {
        "name": "Distributive or service trade statistics",
        "code": "80141502"
      },
      { "name": "Commodity price forecasting", "code": "80141503" },
      { "name": "Preparation of commodity market surveys", "code": "80141504" },
      { "name": "Marketing plans", "code": "80141505" },
      { "name": "Internet based market research", "code": "80141506" },
      {
        "name": "Consumer based research or clinics or focus groups",
        "code": "80141507"
      },
      {
        "name": "Syndicated or proprietary forecast studies",
        "code": "80141508"
      },
      {
        "name": "Market intelligence or competitive analysis",
        "code": "80141509"
      },
      { "name": "Market research telephone surveys", "code": "80141510" },
      { "name": "Market research paper surveys", "code": "80141511" },
      { "name": "Market research on location surveys", "code": "80141512" },
      { "name": "Market research one on one interviews", "code": "80141513" },
      { "name": "Market research mail surveys", "code": "80141514" },
      { "name": "Sales and business promotion activities", "code": "80141600" },
      { "name": "Sales promotion services", "code": "80141601" },
      { "name": "Telemarketing", "code": "80141603" },
      { "name": "Branding of product naming services", "code": "80141604" },
      { "name": "Promotional merchandise", "code": "80141605" },
      { "name": "Direct marketing fulfillment", "code": "80141606" },
      { "name": "Events management", "code": "80141607" },
      { "name": "Sponsorship of event or celebrity", "code": "80141609" },
      { "name": "Close outs", "code": "80141610" },
      {
        "name": "Product or gift personalization services",
        "code": "80141611"
      },
      { "name": "Sales or marketing programs", "code": "80141612" },
      { "name": "After sales programs", "code": "80141613" },
      { "name": "Demo or rental or used vehicle", "code": "80141615" },
      {
        "name": "Point of sale materials not including printed materials",
        "code": "80141616"
      },
      {
        "name": "In dealership strategic initiatives training",
        "code": "80141617"
      },
      {
        "name": "Sales marketing agencies including print",
        "code": "80141618"
      },
      {
        "name": "Customer relationship center CRC management services",
        "code": "80141619"
      },
      {
        "name": "In dealership strategic initiatives support",
        "code": "80141620"
      },
      { "name": "Motorsport", "code": "80141621" },
      { "name": "Letter shop services", "code": "80141622" },
      { "name": "Merchandising service", "code": "80141623" },
      { "name": "Recognition program management service", "code": "80141624" },
      { "name": "Incentive program management service", "code": "80141625" },
      { "name": "Promotional program management service", "code": "80141626" },
      {
        "name": "Cooperative or shared advertising management service",
        "code": "80141627"
      },
      { "name": "Commissioned distributor service", "code": "80141628" },
      { "name": "Rebate management service", "code": "80141629" },
      { "name": "Direct marketing print service", "code": "80141630" },
      { "name": "Distribution", "code": "80141700" },
      { "name": "Direct sales services", "code": "80141701" },
      { "name": "Wholesale distribution services", "code": "80141702" },
      { "name": "Retail distribution services", "code": "80141703" },
      { "name": "Franchise operations", "code": "80141704" },
      { "name": "Auction services", "code": "80141705" },
      { "name": "Product brokerage service", "code": "80141706" },
      { "name": "Mailing services", "code": "80141800" },
      { "name": "Mailing list compilation services", "code": "80141801" },
      { "name": "Mailing list management service", "code": "80141802" },
      { "name": "Addressing service", "code": "80141803" },
      { "name": "Trade shows and exhibits", "code": "80141900" },
      { "name": "Auto shows or other exhibits", "code": "80141901" },
      { "name": "Meetings events", "code": "80141902" },
      { "name": "Talent or entertainment", "code": "80141903" },
      { "name": "Trade policy and services", "code": "80150000" },
      { "name": "Trade facilitation", "code": "80151500" },
      {
        "name": "Commodity policy or projections services",
        "code": "80151501"
      },
      { "name": "Trade expansion", "code": "80151502" },
      { "name": "Trade information services", "code": "80151503" },
      { "name": "Trade promotion services", "code": "80151504" },
      { "name": "Multinational marketing enterprises", "code": "80151505" },
      { "name": "International trade services", "code": "80151600" },
      { "name": "Export development", "code": "80151601" },
      { "name": "Import planning", "code": "80151602" },
      { "name": "Export projections", "code": "80151603" },
      { "name": "Import procurement services", "code": "80151604" },
      { "name": "Customs consulting service", "code": "80151605" },
      { "name": "Business administration services", "code": "80160000" },
      { "name": "Management support services", "code": "80161500" },
      {
        "name": "Office administration or secretarial services",
        "code": "80161501"
      },
      { "name": "Meeting planning services", "code": "80161502" },
      { "name": "Keyboard entry services", "code": "80161503" },
      { "name": "Clerical services", "code": "80161504" },
      { "name": "Fleet management services", "code": "80161505" },
      { "name": "Data archiving services", "code": "80161506" },
      { "name": "Audio visual services", "code": "80161507" },
      { "name": "Document destruction services", "code": "80161508" },
      { "name": "Business facilities oversight", "code": "80161600" },
      { "name": "Property management services", "code": "80161601" },
      { "name": "Receiving or inventorying services", "code": "80161602" },
      {
        "name": "Furniture project administration or management",
        "code": "80161603"
      },
      { "name": "Asset recovery service", "code": "80161700" },
      { "name": "Byproduct disposal or sale service", "code": "80161701" },
      { "name": "Capital asset disposal or sale service", "code": "80161702" },
      {
        "name": "Excess or obsolete non capital material disposal or sale service",
        "code": "80161703"
      },
      {
        "name": "Office equipment rental or leasing services",
        "code": "80161800"
      },
      { "name": "Photocopier rental or leasing service", "code": "80161801" },
      {
        "name": "Public relations and professional communications services",
        "code": "80170000"
      },
      {
        "name": "Situation and stakeholder analysis and communications planning services",
        "code": "80171500"
      },
      {
        "name": "Polling and survey and public opinion monitoring and analysis",
        "code": "80171501"
      },
      {
        "name": "Focus group and public feedback meeting facilitation and analysis",
        "code": "80171502"
      },
      {
        "name": "Public relations situation and issues and risk analysis",
        "code": "80171503"
      },
      { "name": "Stakeholder analysis", "code": "80171504" },
      { "name": "Communication planning", "code": "80171505" },
      {
        "name": "Publicity and marketing support services",
        "code": "80171600"
      },
      {
        "name": "Trade and tourism familiarization service",
        "code": "80171601"
      },
      {
        "name": "Online and social media publicity service",
        "code": "80171602"
      },
      {
        "name": "Publicity and marketing advisory service",
        "code": "80171603"
      },
      { "name": "Public information campaign service", "code": "80171604" },
      { "name": "Press release and media kit service", "code": "80171605" },
      {
        "name": "Reputation and brand management services",
        "code": "80171700"
      },
      { "name": "Reputation management service", "code": "80171701" },
      { "name": "Brand promotion and management service", "code": "80171702" },
      { "name": "Media relations services", "code": "80171800" },
      { "name": "Media monitoring service", "code": "80171801" },
      { "name": "Media relations and advisory service", "code": "80171802" },
      { "name": "Media training and coaching service", "code": "80171803" },
      {
        "name": "Stakeholder management and relations services",
        "code": "80171900"
      },
      {
        "name": "Volunteer relations and management and engagement",
        "code": "80171901"
      },
      {
        "name": "Investor and shareholder relations consultation and engagement",
        "code": "80171902"
      },
      {
        "name": "Internal stakeholder relations consultation and engagement",
        "code": "80171903"
      },
      {
        "name": "Cultural and ethnic group relations consultation and engagement",
        "code": "80171904"
      },
      {
        "name": "Indigenous peoples relations consultation and engagement",
        "code": "80171905"
      },
      {
        "name": "Government relations consultation and engagement",
        "code": "80171906"
      },
      {
        "name": "Community relations consultation and engagement",
        "code": "80171907"
      },
      {
        "name": "Not for profit organization relations consultation and engagement",
        "code": "80171908"
      },
      {
        "name": "Business and utility provider relations consultation and engagement",
        "code": "80171909"
      },
      { "name": "Professional communication services", "code": "80172000" },
      { "name": "Internal communication service", "code": "80172001" },
      { "name": "Project based communications service", "code": "80172002" },
      { "name": "Public affairs service", "code": "80172003" },
      { "name": "Issues and crisis management services", "code": "80172100" },
      { "name": "Crisis management and recovery service", "code": "80172101" },
      { "name": "Crisis planning and avoidance service", "code": "80172102" },
      {
        "name": "Change management communication and advisory service",
        "code": "80172103"
      },
      {
        "name": "Issues management and mitigation advisory service",
        "code": "80172104"
      }
    ]
  },
  {
    "name": "Engineering and Research and Technology Based Services",
    "code": "81000000",
    "subcategories": [
      { "name": "Professional engineering services", "code": "81100000" },
      { "name": "Civil engineering", "code": "81101500" },
      { "name": "Well engineering", "code": "81101501" },
      { "name": "Technical drawing", "code": "81101502" },
      { "name": "Harbor or water ports engineering", "code": "81101503" },
      { "name": "Structural engineering", "code": "81101505" },
      { "name": "Naval architecture", "code": "81101506" },
      { "name": "Dam engineering", "code": "81101507" },
      { "name": "Architectural engineering", "code": "81101508" },
      { "name": "Airport engineering", "code": "81101509" },
      { "name": "Highway engineering", "code": "81101510" },
      { "name": "Railway engineering", "code": "81101511" },
      {
        "name": "Geographic information system GIS services",
        "code": "81101512"
      },
      { "name": "Building construction management", "code": "81101513" },
      { "name": "Geotechnical or geoseismic engineering", "code": "81101514" },
      {
        "name": "Plant or facility infrastructure engineering",
        "code": "81101515"
      },
      { "name": "Energy or utility consulting service", "code": "81101516" },
      {
        "name": "Landscape architecture and design service",
        "code": "81101517"
      },
      { "name": "Lighting engineering service", "code": "81101518" },
      { "name": "Subdivison planning service", "code": "81101519" },
      { "name": "Hydrology assessment service", "code": "81101520" },
      { "name": "Hydrogeology assessment service", "code": "81101521" },
      { "name": "Earthworks engineering", "code": "81101522" },
      { "name": "Urban design and engineering service", "code": "81101523" },
      { "name": "City development planning service", "code": "81101524" },
      { "name": "Sediment control engineering", "code": "81101525" },
      { "name": "Quantity surveying service", "code": "81101526" },
      { "name": "Wastewater engineering", "code": "81101527" },
      { "name": "Stormwater engineering", "code": "81101528" },
      { "name": "Acoustic engineering", "code": "81101529" },
      { "name": "Fisheries engineering", "code": "81101530" },
      { "name": "Mechanical engineering", "code": "81101600" },
      { "name": "Mechanical drawing", "code": "81101601" },
      { "name": "Mechanical product enclosures design", "code": "81101602" },
      { "name": "Machine tool design", "code": "81101603" },
      { "name": "Power transmission design", "code": "81101604" },
      { "name": "Electromechanical services", "code": "81101605" },
      { "name": "Marine engineering", "code": "81101606" },
      { "name": "Textile engineering service", "code": "81101607" },
      { "name": "Electrical and electronic engineering", "code": "81101700" },
      { "name": "Electrical engineering services", "code": "81101701" },
      { "name": "Electronic circuit design", "code": "81101702" },
      { "name": "Engineering testing services", "code": "81101703" },
      { "name": "Laboratory equipment maintenance", "code": "81101706" },
      { "name": "Printing equipment maintenance", "code": "81101707" },
      { "name": "Wafer reclaiming service", "code": "81101710" },
      {
        "name": "Electronic component manufacturing service",
        "code": "81101711"
      },
      {
        "name": "Electronic measurement and recording instrument engineering and design service",
        "code": "81101713"
      },
      { "name": "Chemical engineering", "code": "81101800" },
      { "name": "Plastics engineering", "code": "81101801" },
      { "name": "Chemical process engineering service", "code": "81101802" },
      { "name": "Oil and gas engineering", "code": "81101900" },
      { "name": "Production engineering for oil or gas", "code": "81101902" },
      { "name": "Mining engineering", "code": "81102000" },
      { "name": "Hydraulic mining", "code": "81102001" },
      { "name": "Ocean engineering", "code": "81102100" },
      { "name": "Coastal engineering", "code": "81102101" },
      { "name": "Transportation engineering", "code": "81102200" },
      { "name": "Traffic engineering", "code": "81102201" },
      { "name": "Urban transport network", "code": "81102202" },
      { "name": "Inland waterways", "code": "81102203" },
      { "name": "Aeronautical engineering", "code": "81102300" },
      { "name": "Avionics design", "code": "81102301" },
      { "name": "Space engineering service", "code": "81102302" },
      {
        "name": "Electrical power transmission engineering",
        "code": "81102400"
      },
      { "name": "High voltage overhead line construction", "code": "81102401" },
      {
        "name": "High voltage overhead line maintenance and repair",
        "code": "81102402"
      },
      { "name": "Permitting services", "code": "81102500" },
      { "name": "Mine permitting service", "code": "81102501" },
      {
        "name": "Building consent and permit engineering peer review service",
        "code": "81102502"
      },
      {
        "name": "Building consent processing and support service",
        "code": "81102503"
      },
      { "name": "Sampling services", "code": "81102600" },
      { "name": "Coal sampling service", "code": "81102601" },
      {
        "name": "Instrumented control systems design and engineering services",
        "code": "81102700"
      },
      {
        "name": "Fire and gas monitoring and control system engineering service",
        "code": "81102701"
      },
      {
        "name": "Process control system design and engineering service",
        "code": "81102702"
      },
      { "name": "Minefield and demining services", "code": "81102800" },
      {
        "name": "Demining environmental impact assessment EIA service",
        "code": "81102801"
      },
      { "name": "Demining feasibility study FS service", "code": "81102802" },
      {
        "name": "General mine action assessment GMAA service",
        "code": "81102803"
      },
      {
        "name": "Demining non technical survey NTS service",
        "code": "81102804"
      },
      {
        "name": "Demining operational analysis (OA) or operational research service",
        "code": "81102805"
      },
      { "name": "Post mine clearance assessment service", "code": "81102806" },
      {
        "name": "Demining risk analysis or assessment or evaluation service",
        "code": "81102807"
      },
      { "name": "Demining technical survey", "code": "81102808" },
      { "name": "Mine landmine impact survey LIS", "code": "81102809" },
      { "name": "Computer services", "code": "81110000" },
      { "name": "Software or hardware engineering", "code": "81111500" },
      { "name": "Mainframe software applications design", "code": "81111501" },
      { "name": "Personal computer PC application design", "code": "81111502" },
      { "name": "Systems integration design", "code": "81111503" },
      { "name": "Application programming services", "code": "81111504" },
      { "name": "Operating system programming services", "code": "81111505" },
      { "name": "Client or server programming services", "code": "81111506" },
      {
        "name": "ERP or database applications programming services",
        "code": "81111507"
      },
      { "name": "Application implementation services", "code": "81111508" },
      {
        "name": "Internet or intranet client application development services",
        "code": "81111509"
      },
      {
        "name": "Internet or intranet server application development services",
        "code": "81111510"
      },
      {
        "name": "System or application programming management service",
        "code": "81111511"
      },
      { "name": "Computer graphics service", "code": "81111512" },
      { "name": "Computer programmers", "code": "81111600" },
      { "name": "Programming for Visual Basic", "code": "81111601" },
      { "name": "Programming for Java", "code": "81111602" },
      { "name": "Programming for HTML", "code": "81111603" },
      { "name": "Programming for ALGOL", "code": "81111604" },
      { "name": "Programming for Assembler", "code": "81111605" },
      { "name": "Programming for Basic", "code": "81111606" },
      { "name": "Programming for C or C++", "code": "81111607" },
      { "name": "Programming for COBOL", "code": "81111608" },
      { "name": "Programming for FORTRAN", "code": "81111609" },
      { "name": "Programming for Pascal", "code": "81111610" },
      { "name": "Programming for PL/1", "code": "81111611" },
      { "name": "Programming or Proprietary Languages", "code": "81111612" },
      { "name": "Programming for Perl", "code": "81111613" },
      { "name": "Management information systems MIS", "code": "81111700" },
      { "name": "Wide area network communications design", "code": "81111701" },
      {
        "name": "Local area network communications design",
        "code": "81111702"
      },
      { "name": "Electronic data interchange EDI design", "code": "81111703" },
      { "name": "Database design", "code": "81111704" },
      { "name": "Systems architecture", "code": "81111705" },
      { "name": "Network planning services", "code": "81111706" },
      { "name": "Systems planning services", "code": "81111707" },
      { "name": "Telecommunications planning services", "code": "81111708" },
      {
        "name": "Demining geographical or geospatial information system GIS",
        "code": "81111709"
      },
      {
        "name": "Information management system for mine action IMSMA",
        "code": "81111710"
      },
      {
        "name": "System and system component administration services",
        "code": "81111800"
      },
      {
        "name": "Computer or network or internet security",
        "code": "81111801"
      },
      { "name": "Mainframe administration services", "code": "81111802" },
      {
        "name": "Local area network LAN maintenance or support",
        "code": "81111803"
      },
      {
        "name": "Wide area network WAN maintenance or support",
        "code": "81111804"
      },
      {
        "name": "Proprietary or licensed systems maintenance or support",
        "code": "81111805"
      },
      { "name": "Database analysis service", "code": "81111806" },
      { "name": "System analysis service", "code": "81111808" },
      { "name": "System installation service", "code": "81111809" },
      { "name": "Software coding service", "code": "81111810" },
      { "name": "Technical support or help desk services", "code": "81111811" },
      {
        "name": "Computer hardware maintenance support service",
        "code": "81111812"
      },
      { "name": "Co location service", "code": "81111814" },
      { "name": "Third party warranty service", "code": "81111818" },
      { "name": "Quality assurance services", "code": "81111819" },
      { "name": "System usability services", "code": "81111820" },
      { "name": "Information retrieval systems", "code": "81111900" },
      { "name": "Database information retrieval", "code": "81111901" },
      {
        "name": "Online database information retrieval service",
        "code": "81111902"
      },
      { "name": "Data services", "code": "81112000" },
      { "name": "Online data processing service", "code": "81112001" },
      { "name": "Data processing or preparation services", "code": "81112002" },
      { "name": "Data center services", "code": "81112003" },
      { "name": "Disaster recovery services", "code": "81112004" },
      { "name": "Document scanning service", "code": "81112005" },
      { "name": "Data storage service", "code": "81112006" },
      {
        "name": "Content or data standardization services",
        "code": "81112007"
      },
      { "name": "Cd rom mastering services", "code": "81112008" },
      { "name": "Content or data classification services", "code": "81112009" },
      { "name": "Data conversion service", "code": "81112010" },
      { "name": "Internet services", "code": "81112100" },
      { "name": "Internet service providers ISP", "code": "81112101" },
      { "name": "Electronic mail service provider", "code": "81112102" },
      { "name": "World wide web WWW site design services", "code": "81112103" },
      { "name": "Web search engine providers", "code": "81112104" },
      {
        "name": "World wide web WWW site operation host services",
        "code": "81112105"
      },
      { "name": "Application service providers", "code": "81112106" },
      { "name": "Internet domain names", "code": "81112107" },
      { "name": "Software maintenance and support", "code": "81112200" },
      { "name": "Maintenance or support fees", "code": "81112201" },
      { "name": "Software patches or upgrades", "code": "81112202" },
      { "name": "Firmware patching or upgrade service", "code": "81112203" },
      { "name": "Operating system software maintenance", "code": "81112204" },
      {
        "name": "Database management system software maintenance",
        "code": "81112205"
      },
      {
        "name": "Information retrieval or search software maintenance",
        "code": "81112206"
      },
      { "name": "Video conferencing software maintenance", "code": "81112207" },
      {
        "name": "Security and protection software maintenance",
        "code": "81112208"
      },
      { "name": "Development software maintenance", "code": "81112209" },
      { "name": "System management software maintenance", "code": "81112210" },
      {
        "name": "Enterprise resource planning software maintenance",
        "code": "81112211"
      },
      {
        "name": "Customer relationship management software maintenance",
        "code": "81112212"
      },
      { "name": "Accounting software maintenance", "code": "81112213" },
      {
        "name": "Content authoring and editing software maintenance",
        "code": "81112214"
      },
      { "name": "Content management software maintenance", "code": "81112215" },
      {
        "name": "Educational or reference software maintenance",
        "code": "81112216"
      },
      { "name": "Industry specific software maintenance", "code": "81112217" },
      {
        "name": "Network application software maintenance",
        "code": "81112218"
      },
      {
        "name": "Computer game or entertainment software maintenance",
        "code": "81112219"
      },
      { "name": "Server software maintenance", "code": "81112220" },
      {
        "name": "Point of sale software maintenance service",
        "code": "81112221"
      },
      {
        "name": "Facility operation and maintenance management software maintenance",
        "code": "81112222"
      },
      {
        "name": "Computer hardware maintenance and support",
        "code": "81112300"
      },
      { "name": "Disk storage system maintenance", "code": "81112301" },
      { "name": "Nearline or backup system maintenance", "code": "81112302" },
      { "name": "Mainframe computer maintenance", "code": "81112303" },
      { "name": "UNIX server maintenance", "code": "81112304" },
      { "name": "X86 server maintenance", "code": "81112305" },
      {
        "name": "Printer, scanner and multifunctional equipment maintenance",
        "code": "81112306"
      },
      {
        "name": "PC or workstation or notebook maintenance",
        "code": "81112307"
      },
      {
        "name": "Point of sale hardware maintenance and support service",
        "code": "81112308"
      },
      {
        "name": "Point of sale hardware installation or implementation service",
        "code": "81112309"
      },
      { "name": "Computer cabinet maintenance", "code": "81112310" },
      {
        "name": "Computer hardware rental or leasing services",
        "code": "81112400"
      },
      { "name": "Computer hardware rental", "code": "81112401" },
      {
        "name": "Computer software licensing rental or leasing service",
        "code": "81112500"
      },
      { "name": "Computer software licensing service", "code": "81112501" },
      {
        "name": "Computer software rental or leasing service",
        "code": "81112502"
      },
      { "name": "Economics", "code": "81120000" },
      { "name": "Economic analysis", "code": "81121500" },
      { "name": "Macro economic analysis", "code": "81121501" },
      { "name": "Micro economic analysis", "code": "81121502" },
      { "name": "Econometrics", "code": "81121503" },
      { "name": "Economic forecasts", "code": "81121504" },
      { "name": "Economic development consultancy", "code": "81121505" },
      { "name": "Monetary systems and issues", "code": "81121600" },
      { "name": "Monetary policy", "code": "81121601" },
      { "name": "Monetary systems", "code": "81121602" },
      { "name": "Monetary analysis", "code": "81121603" },
      { "name": "Monetary liquidity", "code": "81121604" },
      { "name": "Precious metals reserves", "code": "81121605" },
      { "name": "Foreign exchange control", "code": "81121606" },
      { "name": "Currency exchange markets", "code": "81121607" },
      { "name": "Statistics", "code": "81130000" },
      { "name": "Methodology and analysis", "code": "81131500" },
      { "name": "Factor analysis", "code": "81131501" },
      { "name": "Multivariate analysis", "code": "81131502" },
      { "name": "Regression analysis", "code": "81131503" },
      { "name": "Sampling surveys", "code": "81131504" },
      { "name": "Time series analysis", "code": "81131505" },
      { "name": "Manufacturing technologies", "code": "81140000" },
      { "name": "Quality control", "code": "81141500" },
      { "name": "Materials testing", "code": "81141501" },
      { "name": "Materials synthesis", "code": "81141502" },
      { "name": "Materials or product inspection", "code": "81141503" },
      { "name": "Equipment test calibration or repair", "code": "81141504" },
      { "name": "Production standards development", "code": "81141505" },
      { "name": "Product testing", "code": "81141506" },
      { "name": "Supply chain management", "code": "81141600" },
      { "name": "Logistics", "code": "81141601" },
      { "name": "Transit analysis", "code": "81141602" },
      { "name": "Transport finance or economics", "code": "81141603" },
      { "name": "Transport facilitation", "code": "81141604" },
      { "name": "Transport infrastructure", "code": "81141605" },
      { "name": "Transport planning", "code": "81141606" },
      { "name": "Production planning and control", "code": "81141700" },
      { "name": "Production planning", "code": "81141701" },
      { "name": "Production control", "code": "81141702" },
      { "name": "Production scheduling", "code": "81141703" },
      {
        "name": "Production statistics collection or analysis services",
        "code": "81141704"
      },
      { "name": "Facilities management", "code": "81141800" },
      { "name": "Safety or risk analysis", "code": "81141801" },
      { "name": "Industrial hygiene or ventilation", "code": "81141802" },
      { "name": "Acoustics or noise control", "code": "81141803" },
      { "name": "Equipment inspection service", "code": "81141804" },
      { "name": "Building inspection service", "code": "81141805" },
      { "name": "Power line inspection service", "code": "81141806" },
      { "name": "Plumbing or sewer inspection service", "code": "81141807" },
      {
        "name": "Manufacturing technology research and development services",
        "code": "81141900"
      },
      {
        "name": "Product research and development service",
        "code": "81141901"
      },
      {
        "name": "Application or technology research and development service",
        "code": "81141902"
      },
      { "name": "Earth science services", "code": "81150000" },
      { "name": "Meteorology", "code": "81151500" },
      { "name": "Climatology", "code": "81151501" },
      { "name": "Meteorological services", "code": "81151502" },
      { "name": "Hydrometeorology", "code": "81151503" },
      { "name": "Cartography", "code": "81151600" },
      { "name": "Mapping", "code": "81151601" },
      { "name": "Map production", "code": "81151602" },
      { "name": "Photogrammetry", "code": "81151603" },
      { "name": "Land surveying", "code": "81151604" },
      { "name": "Geology", "code": "81151700" },
      { "name": "Photogeology", "code": "81151701" },
      { "name": "Stratigraphic geology", "code": "81151702" },
      { "name": "Geological surveys", "code": "81151703" },
      { "name": "Geological exploration", "code": "81151704" },
      { "name": "Archaeological services", "code": "81151705" },
      { "name": "Oceanography and hydrology", "code": "81151800" },
      { "name": "Oceanographic survey", "code": "81151801" },
      { "name": "Estuarine oceanography", "code": "81151802" },
      { "name": "Physical oceanography", "code": "81151803" },
      { "name": "Hydrological surveys", "code": "81151804" },
      { "name": "Bathymetric surveys", "code": "81151805" },
      { "name": "Underwater exploration", "code": "81151806" },
      { "name": "Geophysics", "code": "81151900" },
      { "name": "Geophysical surveys", "code": "81151901" },
      { "name": "Geophysical exploration", "code": "81151902" },
      { "name": "Geophysical photo interpretation", "code": "81151903" },
      { "name": "Aero magnetic geophysics", "code": "81151904" },
      { "name": "Information Technology Service Delivery", "code": "81160000" },
      { "name": "Access management services", "code": "81161500" },
      {
        "name": "Software application administration service",
        "code": "81161501"
      },
      { "name": "Network Account Administration Service", "code": "81161502" },
      { "name": "Network folder administration service", "code": "81161503" },
      { "name": "Electronic mail and messaging services", "code": "81161600" },
      {
        "name": "Instant Messaging Administration Service",
        "code": "81161601"
      },
      { "name": "Telecommunication Services", "code": "81161700" },
      { "name": "Fax Administration Service", "code": "81161701" },
      { "name": "Fax Support Service", "code": "81161702" },
      { "name": "Mobile Telephone Administration Service", "code": "81161703" },
      { "name": "Mobile Telephone Support Service", "code": "81161704" },
      { "name": "Pager Administration Service", "code": "81161705" },
      { "name": "Pager Support Service", "code": "81161706" },
      { "name": "Telephone Administration Service", "code": "81161707" },
      { "name": "Telephone Support Service", "code": "81161708" },
      { "name": "Voice Mail Administration Service", "code": "81161709" },
      { "name": "Voice Mail Support Service", "code": "81161710" },
      { "name": "Videoconferencing service", "code": "81161711" },
      { "name": "Network voice service", "code": "81161712" },
      {
        "name": "Data voice or multimedia network equipment or platform rental or leasing services",
        "code": "81161800"
      },
      {
        "name": "Data communication equipment or platform rental or leasing service",
        "code": "81161801"
      },
      { "name": "Biological science services", "code": "81170000" },
      { "name": "Marine biology services", "code": "81171500" },
      { "name": "Marine biology consultation service", "code": "81171501" },
      { "name": "Ecological science services", "code": "81171600" },
      { "name": "Aquatic ecology service", "code": "81171601" },
      { "name": "Botanical science services", "code": "81171700" },
      { "name": "Horticultural science service", "code": "81171701" },
      { "name": "Arboricultural science service", "code": "81171702" },
      { "name": "Agricultural science services", "code": "81171800" },
      {
        "name": "Agricultural science consultation service",
        "code": "81171801"
      },
      { "name": "Aerobiological science services", "code": "81171900" },
      { "name": "Air quality science service", "code": "81171901" }
    ]
  },
  {
    "name": "Editorial and Design and Graphic and Fine Art Services",
    "code": "82000000",
    "subcategories": [
      { "name": "Advertising", "code": "82100000" },
      { "name": "Print advertising", "code": "82101500" },
      { "name": "Billboard advertising", "code": "82101501" },
      { "name": "Poster advertising", "code": "82101502" },
      { "name": "Magazine advertising", "code": "82101503" },
      { "name": "Newspaper advertising", "code": "82101504" },
      { "name": "Handbill or coupon advertising", "code": "82101505" },
      { "name": "Transit advertising services", "code": "82101506" },
      {
        "name": "Shopping news or advertising or distribution service",
        "code": "82101507"
      },
      {
        "name": "Trade or service directory or yellow page advertising",
        "code": "82101508"
      },
      { "name": "Broadcast advertising", "code": "82101600" },
      { "name": "Radio advertising", "code": "82101601" },
      { "name": "Television advertising", "code": "82101602" },
      { "name": "Internet advertising", "code": "82101603" },
      { "name": "Cinema advertising", "code": "82101604" },
      {
        "name": "Television commercials production service",
        "code": "82101605"
      },
      { "name": "Radio commercial production service", "code": "82101606" },
      { "name": "Aerial advertising", "code": "82101700" },
      { "name": "Banner advertising services", "code": "82101701" },
      { "name": "Skywriting advertising services", "code": "82101702" },
      { "name": "Advertising agency services", "code": "82101800" },
      { "name": "Advertising campaign services", "code": "82101801" },
      { "name": "Advertising production service", "code": "82101802" },
      { "name": "Media placement and fulfillment", "code": "82101900" },
      { "name": "Radio placement", "code": "82101901" },
      { "name": "Television placement", "code": "82101902" },
      { "name": "Internet placement", "code": "82101903" },
      { "name": "Cinema placement", "code": "82101904" },
      { "name": "Print placement", "code": "82101905" },
      { "name": "Writing and translations", "code": "82110000" },
      { "name": "Technical writing", "code": "82111500" },
      { "name": "Instruction writing services", "code": "82111501" },
      { "name": "Manual writing services", "code": "82111502" },
      { "name": "Academic or scientific article writing", "code": "82111503" },
      { "name": "Non technical writing", "code": "82111600" },
      { "name": "Letter writing services", "code": "82111601" },
      { "name": "Resume writing services", "code": "82111602" },
      { "name": "Court reporting services", "code": "82111603" },
      { "name": "Transcribing services", "code": "82111604" },
      { "name": "Creative writing", "code": "82111700" },
      { "name": "Article writers services", "code": "82111701" },
      { "name": "Book authors services", "code": "82111702" },
      { "name": "Poetry authors services", "code": "82111703" },
      { "name": "Copywriting", "code": "82111704" },
      { "name": "Speech writing", "code": "82111705" },
      { "name": "Editorial and support services", "code": "82111800" },
      { "name": "Editing services", "code": "82111801" },
      { "name": "Fact checking services", "code": "82111802" },
      { "name": "Proofreading services", "code": "82111803" },
      { "name": "Written translation services", "code": "82111804" },
      { "name": "News and publicity services", "code": "82111900" },
      { "name": "Press release services", "code": "82111901" },
      { "name": "Special interest newsletter services", "code": "82111902" },
      { "name": "News agency wire services", "code": "82111903" },
      {
        "name": "Newspaper or advertising material delivery services",
        "code": "82111904"
      },
      {
        "name": "In person language interpretation services",
        "code": "82112000"
      },
      {
        "name": "In person afghan or pashto or pushto interpretation service",
        "code": "82112001"
      },
      {
        "name": "In person albanian interpretation service",
        "code": "82112002"
      },
      {
        "name": "In person amharic interpretation service",
        "code": "82112003"
      },
      { "name": "In person arabic interpretation service", "code": "82112004" },
      {
        "name": "In person armenian interpretation service",
        "code": "82112005"
      },
      {
        "name": "In person bangledesh interpretation service",
        "code": "82112006"
      },
      {
        "name": "In person belarussian interpretation service",
        "code": "82112007"
      },
      {
        "name": "In person bengali interpretation service",
        "code": "82112008"
      },
      {
        "name": "In person bosnian interpretation service",
        "code": "82112009"
      },
      {
        "name": "In person bulgarian interpretation service",
        "code": "82112010"
      },
      {
        "name": "In person cambodian interpretation service",
        "code": "82112011"
      },
      {
        "name": "In person chinese interpretation service",
        "code": "82112012"
      },
      { "name": "In person creole interpretation service", "code": "82112013" },
      {
        "name": "In person croatian interpretation service",
        "code": "82112014"
      },
      { "name": "In person czech interpretation service", "code": "82112015" },
      { "name": "In person danish interpretation service", "code": "82112016" },
      { "name": "In person dinka interpretation service", "code": "82112017" },
      { "name": "In person dutch interpretation service", "code": "82112018" },
      {
        "name": "In person egyptian interpretation service",
        "code": "82112019"
      },
      { "name": "In person fanti interpretation service", "code": "82112020" },
      {
        "name": "In person fanti or persian interpretation service",
        "code": "82112021"
      },
      { "name": "In person french interpretation service", "code": "82112022" },
      { "name": "In person german interpretation service", "code": "82112023" },
      { "name": "In person greek interpretation service", "code": "82112024" },
      {
        "name": "In person gugarati interpretation service",
        "code": "82112025"
      },
      { "name": "In person hebrew interpretation service", "code": "82112026" },
      { "name": "In person hindi interpretation service", "code": "82112027" },
      { "name": "In person hmong interpretation service", "code": "82112028" },
      {
        "name": "In person american indian interpretation service",
        "code": "82112029"
      },
      {
        "name": "In person indonesian interpretation service",
        "code": "82112030"
      },
      {
        "name": "In person italian interpretation service",
        "code": "82112031"
      },
      {
        "name": "In person jamaican interpretation service",
        "code": "82112032"
      },
      {
        "name": "In person japanese interpretation service",
        "code": "82112033"
      },
      {
        "name": "In person kirghiz interpretation service",
        "code": "82112034"
      },
      { "name": "In person korean interpretation service", "code": "82112035" },
      {
        "name": "In person kurdish interpretation service",
        "code": "82112036"
      },
      {
        "name": "In person lithuanian interpretation service",
        "code": "82112037"
      },
      {
        "name": "In person malayalam interpretation service",
        "code": "82112038"
      },
      {
        "name": "In person mandingo interpretation service",
        "code": "82112039"
      },
      {
        "name": "In person native american interpretation service",
        "code": "82112040"
      },
      {
        "name": "In person pakistani interpretation service",
        "code": "82112041"
      },
      {
        "name": "In person eastern panjabi interpretation service",
        "code": "82112042"
      },
      {
        "name": "In person western panjabi interpretation service",
        "code": "82112043"
      },
      { "name": "In person polish interpretation service", "code": "82112044" },
      {
        "name": "In person portuguese interpretation service",
        "code": "82112045"
      },
      {
        "name": "In person romanian interpretation service",
        "code": "82112046"
      },
      { "name": "In person romany interpretation service", "code": "82112047" },
      {
        "name": "In person russian interpretation service",
        "code": "82112048"
      },
      {
        "name": "In person rwandan interpretation service",
        "code": "82112049"
      },
      { "name": "In person samoan interpretation service", "code": "82112050" },
      {
        "name": "In person serbian interpretation service",
        "code": "82112051"
      },
      {
        "name": "In person serbo croatian interpretation service",
        "code": "82112052"
      },
      {
        "name": "In person slovenian interpretation service",
        "code": "82112053"
      },
      { "name": "In person somali interpretation service", "code": "82112054" },
      {
        "name": "In person spanish interpretation service",
        "code": "82112055"
      },
      {
        "name": "In person swahili interpretation service",
        "code": "82112056"
      },
      {
        "name": "In person swedish interpretation service",
        "code": "82112057"
      },
      {
        "name": "In person taiwanese interpretation service",
        "code": "82112058"
      },
      { "name": "In person thai interpretation service", "code": "82112059" },
      {
        "name": "In person tibetan interpretation service",
        "code": "82112060"
      },
      {
        "name": "In person turkish interpretation service",
        "code": "82112061"
      },
      {
        "name": "In person ukranian interpretation service",
        "code": "82112062"
      },
      {
        "name": "In person vietnamese interpretation service",
        "code": "82112063"
      },
      {
        "name": "In person yiddish interpretation service",
        "code": "82112064"
      },
      {
        "name": "In person yugoslavian interpretation service",
        "code": "82112065"
      },
      {
        "name": "In person hungarian interpretation service",
        "code": "82112066"
      },
      {
        "name": "In person sign language interpretation service",
        "code": "82112067"
      },
      { "name": "Reproduction services", "code": "82120000" },
      { "name": "Printing", "code": "82121500" },
      {
        "name": "Planning or layout of graphic production",
        "code": "82121501"
      },
      { "name": "Typesetting", "code": "82121502" },
      { "name": "Digital printing", "code": "82121503" },
      { "name": "Letterpress or screen printing", "code": "82121504" },
      { "name": "Promotional or advertising printing", "code": "82121505" },
      { "name": "Publication printing", "code": "82121506" },
      { "name": "Stationery or business form printing", "code": "82121507" },
      {
        "name": "Wrap or tag or label or seal or bag printing",
        "code": "82121508"
      },
      {
        "name": "Security or financial instruments printing",
        "code": "82121509"
      },
      { "name": "Textile printing", "code": "82121510" },
      {
        "name": "Technical manual or instruction sheet printing",
        "code": "82121511"
      },
      { "name": "Embossing", "code": "82121512" },
      { "name": "Engraving", "code": "82121600" },
      { "name": "Currency engraving", "code": "82121601" },
      { "name": "Engraved roll printing", "code": "82121602" },
      { "name": "Metal plate engraving", "code": "82121603" },
      { "name": "Photocopying", "code": "82121700" },
      {
        "name": "Black and white copy or collating services",
        "code": "82121701"
      },
      { "name": "Color copy or collating services", "code": "82121702" },
      { "name": "Publishing", "code": "82121800" },
      { "name": "Textbook or research publishing", "code": "82121801" },
      { "name": "Author funded publishing services", "code": "82121802" },
      { "name": "Bookbinding", "code": "82121900" },
      { "name": "Thread stitch bookbinding", "code": "82121901" },
      { "name": "Spiral binding", "code": "82121902" },
      { "name": "Glued binding", "code": "82121903" },
      { "name": "Comb or clamp type binding", "code": "82121904" },
      { "name": "Binding restoration or repair", "code": "82121905" },
      {
        "name": "Bronzing or gilding or edging or deckling",
        "code": "82121906"
      },
      { "name": "Velo binding services", "code": "82121907" },
      { "name": "Case making services", "code": "82121908" },
      { "name": "Photographic services", "code": "82130000" },
      { "name": "Film processing services", "code": "82131500" },
      { "name": "Still film processing or reproduction", "code": "82131501" },
      {
        "name": "Motion picture film processing or reproduction",
        "code": "82131502"
      },
      { "name": "Microfiche services", "code": "82131503" },
      { "name": "Color separation", "code": "82131504" },
      { "name": "Film post production service", "code": "82131505" },
      { "name": "Photographers and cinematographers", "code": "82131600" },
      { "name": "Aerial photography services", "code": "82131601" },
      { "name": "Motion picture cinematography", "code": "82131602" },
      { "name": "Video production services", "code": "82131603" },
      {
        "name": "Studio photography services or still photographs",
        "code": "82131604"
      },
      { "name": "Graphic design", "code": "82140000" },
      { "name": "Art design services", "code": "82141500" },
      { "name": "Layout or graphics editing services", "code": "82141501" },
      { "name": "Art design or graphics", "code": "82141502" },
      { "name": "Photocomposition", "code": "82141503" },
      { "name": "Chart or graph design services", "code": "82141504" },
      { "name": "Computer generated design services", "code": "82141505" },
      { "name": "Package design services", "code": "82141506" },
      { "name": "Silkscreen design services", "code": "82141507" },
      { "name": "Graphic display services", "code": "82141600" },
      {
        "name": "Photographic or picture mounting or framing services",
        "code": "82141601"
      },
      { "name": "Article display arrangement", "code": "82141602" },
      { "name": "Professional artists and performers", "code": "82150000" },
      { "name": "Visual art services", "code": "82151500" },
      { "name": "Painters services", "code": "82151501" },
      { "name": "Lithographers services", "code": "82151502" },
      { "name": "Cartoonists services", "code": "82151503" },
      { "name": "Sculptors services", "code": "82151504" },
      { "name": "Ceramics makers services", "code": "82151505" },
      { "name": "Glass blowers services", "code": "82151506" },
      {
        "name": "Textile spinners or loomers or weavers services",
        "code": "82151507"
      },
      { "name": "Taxidermy services", "code": "82151508" },
      {
        "name": "Public or outdoor artwork or decorative fixture installation and maintenance",
        "code": "82151509"
      },
      {
        "name": "Art installation and picture hanging service",
        "code": "82151510"
      },
      {
        "name": "Technical service for art gallery and museum exhibitions and collections",
        "code": "82151511"
      },
      {
        "name": "Curatorial service for art gallery and museum exhibitions and collections",
        "code": "82151512"
      },
      { "name": "Circus Performers", "code": "82151600" },
      { "name": "Animal trainers services", "code": "82151601" },
      { "name": "Acrobats services", "code": "82151602" },
      { "name": "Magicians services", "code": "82151603" },
      { "name": "Clowns services", "code": "82151604" },
      { "name": "Performing arts professionals", "code": "82151700" },
      { "name": "Acting services", "code": "82151701" },
      { "name": "Comedians services", "code": "82151702" },
      { "name": "Dancers services", "code": "82151703" },
      { "name": "Musicians services", "code": "82151704" },
      { "name": "Vocalists services", "code": "82151705" },
      { "name": "Choreographic services", "code": "82151706" },
      {
        "name": "Film and theater production support services",
        "code": "82160000"
      },
      {
        "name": "Theatrical set design, property and costume services",
        "code": "82161500"
      },
      { "name": "Theatrical prop construction service", "code": "82161501" },
      { "name": "Theatrical prop rental service", "code": "82161502" },
      { "name": "Theatrical costume rental service", "code": "82161503" },
      { "name": "Set design service", "code": "82161504" },
      { "name": "Theatrical make-up artist service", "code": "82161505" }
    ]
  },
  {
    "name": "Public Utilities and Public Sector Related Services",
    "code": "83000000",
    "subcategories": [
      { "name": "Utilities", "code": "83100000" },
      { "name": "Water and sewer utilities", "code": "83101500" },
      { "name": "Supply of water", "code": "83101501" },
      { "name": "Water resource management", "code": "83101502" },
      { "name": "Water quality control management", "code": "83101503" },
      { "name": "Water distribution management", "code": "83101504" },
      { "name": "Water policy advisory services", "code": "83101505" },
      { "name": "Water treatment services", "code": "83101506" },
      { "name": "Desalination services", "code": "83101507" },
      { "name": "Town water", "code": "83101508" },
      { "name": "Service water", "code": "83101509" },
      { "name": "Chilled water", "code": "83101510" },
      { "name": "Oil and gas utilities", "code": "83101600" },
      { "name": "Supply of natural gas", "code": "83101601" },
      { "name": "Supply of fuel oil", "code": "83101602" },
      { "name": "Oil pipeline services", "code": "83101603" },
      { "name": "Gas pipeline services", "code": "83101604" },
      { "name": "Gas facility charge", "code": "83101605" },
      { "name": "Electric utilities", "code": "83101800" },
      { "name": "Supply of single phase electricity", "code": "83101801" },
      { "name": "Supply of two phase electricity", "code": "83101802" },
      { "name": "Supply of three phase electricity", "code": "83101803" },
      { "name": "Electric power transmission services", "code": "83101804" },
      { "name": "Industrial electric power distribution", "code": "83101805" },
      { "name": "Rural electrical power distribution", "code": "83101806" },
      { "name": "Municipal electric power distribution", "code": "83101807" },
      { "name": "Power quality monitoring", "code": "83101808" },
      { "name": "Energy conservation", "code": "83101900" },
      { "name": "Energy conservation programs", "code": "83101901" },
      { "name": "Energy use reduction measures", "code": "83101902" },
      { "name": "District heating", "code": "83101903" },
      { "name": "Telecommunications media services", "code": "83110000" },
      {
        "name": "Local and long distance telephone communications",
        "code": "83111500"
      },
      { "name": "Local telephone service", "code": "83111501" },
      { "name": "Long distance telephone services", "code": "83111502" },
      { "name": "Pay phone provider services", "code": "83111503" },
      { "name": "Pre paid phone card services", "code": "83111504" },
      { "name": "Directory assistance services", "code": "83111505" },
      { "name": "Conference calling services", "code": "83111506" },
      { "name": "Call centre bureau services", "code": "83111507" },
      { "name": "Toll free inbound telephone service", "code": "83111508" },
      { "name": "Interactive voice response service", "code": "83111510" },
      { "name": "Frame relay telecommunications service", "code": "83111511" },
      { "name": "Mobile communications services", "code": "83111600" },
      {
        "name": "Telecommunication signal enhancement network services",
        "code": "83111601"
      },
      {
        "name": "Satellite or earth communication systems services",
        "code": "83111602"
      },
      { "name": "Cellular telephone services", "code": "83111603" },
      { "name": "Paging services", "code": "83111604" },
      { "name": "Spacesegment leasing", "code": "83111605" },
      { "name": "Facsimile and telegraph services", "code": "83111700" },
      { "name": "Facsimile transmission services", "code": "83111701" },
      { "name": "Telegraph transmission services", "code": "83111702" },
      { "name": "Telex transmission services", "code": "83111703" },
      { "name": "Television services", "code": "83111800" },
      { "name": "Cable television services", "code": "83111801" },
      { "name": "Closed circuit television services", "code": "83111802" },
      {
        "name": "Television antenna construction or rental services",
        "code": "83111803"
      },
      {
        "name": "Television broadcasting station management",
        "code": "83111804"
      },
      { "name": "Radio services", "code": "83111900" },
      { "name": "Radio broadcasting station management", "code": "83111901" },
      { "name": "Amateur radio networks or services", "code": "83111902" },
      { "name": "Small scale radio systems", "code": "83111903" },
      { "name": "Radio studio or equipment services", "code": "83111904" },
      {
        "name": "International bilateral services and international private leased lines",
        "code": "83111905"
      },
      { "name": "Enhanced telecommunications services", "code": "83112200" },
      {
        "name": "ATM asynchronous transfer mode managed network services",
        "code": "83112201"
      },
      {
        "name": "Frame relay public managed network services",
        "code": "83112202"
      },
      {
        "name": "VPN virtual private network managed network services",
        "code": "83112203"
      },
      { "name": "X75 managed network services", "code": "83112204" },
      { "name": "X25 managed network services", "code": "83112205" },
      {
        "name": "Directional radio capacity disaster recovery management service",
        "code": "83112206"
      },
      { "name": "Fiber telecommunications services", "code": "83112300" },
      { "name": "Dark fiber", "code": "83112301" },
      {
        "name": "Dense wavelength division multiplexing DWDM",
        "code": "83112302"
      },
      { "name": "Wave division multiplexing WDM", "code": "83112303" },
      { "name": "Ocx optical carrier service", "code": "83112304" },
      {
        "name": "Switched dial up and leased dedicated line circuit telecommunications services",
        "code": "83112400"
      },
      {
        "name": "High speed circuit switched dial up services",
        "code": "83112401"
      },
      {
        "name": "ISDN integrated services digital network services",
        "code": "83112402"
      },
      {
        "name": "Point to point digital telecommunications circuit",
        "code": "83112403"
      },
      {
        "name": "Multi point analog telecommunications circuit",
        "code": "83112404"
      },
      {
        "name": "Point to point analog telecommunications circuit",
        "code": "83112405"
      },
      { "name": "DSL digital subscriber line", "code": "83112406" },
      { "name": "Backbone capacities", "code": "83112500" },
      {
        "name": "Submarine cable capacities and submarine cable PoP to PoP capacities",
        "code": "83112501"
      },
      { "name": "Terrestrial backbone capacities", "code": "83112502" },
      {
        "name": "Rights of way for transit for half circuit systems, DDPs and admin lease",
        "code": "83112503"
      },
      {
        "name": "Indefeasible rights of use IRU for submarine cable or terrestrial cable systems",
        "code": "83112504"
      },
      { "name": "Crossconnection functionality", "code": "83112505" },
      {
        "name": "Directional radio backbone capacity technical infrastructure service",
        "code": "83112506"
      },
      { "name": "Customer access", "code": "83112600" },
      { "name": "Local loop capacities", "code": "83112601" },
      { "name": "Domestic leased lines", "code": "83112602" },
      { "name": "International access lines", "code": "83112603" },
      { "name": "Dial access services", "code": "83112604" },
      { "name": "External international lines", "code": "83112605" },
      {
        "name": "Directional radio capacity customer access service",
        "code": "83112606"
      },
      { "name": "Information services", "code": "83120000" },
      { "name": "Libraries", "code": "83121500" },
      { "name": "General municipal public use libraries", "code": "83121501" },
      { "name": "College or university libraries", "code": "83121502" },
      { "name": "Privately owned libraries", "code": "83121503" },
      {
        "name": "National government or military post libraries",
        "code": "83121504"
      },
      { "name": "Information centers", "code": "83121600" },
      { "name": "Chambers of Commerce", "code": "83121601" },
      { "name": "Tourism board services", "code": "83121602" },
      {
        "name": "Computerized information retrieval systems",
        "code": "83121603"
      },
      {
        "name": "Online database information retrieval systems",
        "code": "83121604"
      },
      {
        "name": "Remote database information retrieval services",
        "code": "83121605"
      },
      { "name": "Skip tracing", "code": "83121606" },
      { "name": "Mass communication services", "code": "83121700" },
      { "name": "Television related services", "code": "83121701" },
      { "name": "Radio related services", "code": "83121702" },
      { "name": "Internet related services", "code": "83121703" },
      { "name": "Citizen warning systems", "code": "83121704" }
    ]
  },
  {
    "name": "Financial and Insurance Services",
    "code": "84000000",
    "subcategories": [
      { "name": "Development finance", "code": "84100000" },
      { "name": "Development assistance", "code": "84101500" },
      { "name": "Financial assistance", "code": "84101501" },
      { "name": "Savings mobilization programs", "code": "84101502" },
      { "name": "Guarantee agreements", "code": "84101503" },
      { "name": "Aid financing", "code": "84101600" },
      { "name": "Co financing", "code": "84101601" },
      { "name": "Bi lateral or multi lateral aid", "code": "84101602" },
      { "name": "Non governmental aid", "code": "84101603" },
      { "name": "Government aid", "code": "84101604" },
      { "name": "Debt management", "code": "84101700" },
      { "name": "Debt negotiation", "code": "84101701" },
      { "name": "Debt reorganization", "code": "84101702" },
      { "name": "Debt servicing", "code": "84101703" },
      { "name": "Debt collection services", "code": "84101704" },
      { "name": "Repossession services", "code": "84101705" },
      { "name": "Accounting and bookkeeping services", "code": "84110000" },
      { "name": "Accounting services", "code": "84111500" },
      { "name": "Cost accounting service", "code": "84111501" },
      { "name": "Financial accounting service", "code": "84111502" },
      { "name": "Tax accounting service", "code": "84111503" },
      { "name": "Bookkeeping services", "code": "84111504" },
      { "name": "Payroll accounting services", "code": "84111505" },
      { "name": "Billing services", "code": "84111506" },
      { "name": "Inventory accounting service", "code": "84111507" },
      {
        "name": "Export administration and accounting service",
        "code": "84111508"
      },
      { "name": "Audit services", "code": "84111600" },
      { "name": "Year end audits", "code": "84111601" },
      { "name": "Quarterly reviews", "code": "84111602" },
      { "name": "Internal audits", "code": "84111603" },
      { "name": "Corporate finance", "code": "84111700" },
      { "name": "Treasury services", "code": "84111701" },
      { "name": "Investor relations services or programs", "code": "84111702" },
      { "name": "Budget preparation or review services", "code": "84111703" },
      { "name": "Taxation issues and preparation", "code": "84111800" },
      { "name": "Tax preparers", "code": "84111801" },
      { "name": "Tax advisory services", "code": "84111802" },
      { "name": "Banking and investment", "code": "84120000" },
      { "name": "Banking institutions", "code": "84121500" },
      { "name": "Privately owned banks", "code": "84121501" },
      { "name": "Publicly owned banks", "code": "84121502" },
      { "name": "Credit unions", "code": "84121503" },
      { "name": "Development finance institutions", "code": "84121504" },
      {
        "name": "Funds transfer and clearance and exchange services",
        "code": "84121600"
      },
      { "name": "Funds clearance services", "code": "84121601" },
      { "name": "Letter of credit services", "code": "84121602" },
      { "name": "Currency exchange services", "code": "84121603" },
      { "name": "Spot exchange transaction services", "code": "84121604" },
      { "name": "Currency conversion service", "code": "84121605" },
      { "name": "Remittance processing services", "code": "84121606" },
      { "name": "Operating lease finance service", "code": "84121607" },
      { "name": "Investment advice", "code": "84121700" },
      { "name": "Investment advisers", "code": "84121701" },
      { "name": "Investment policy", "code": "84121702" },
      { "name": "Investment analysis", "code": "84121703" },
      { "name": "Investment agreements", "code": "84121704" },
      { "name": "Market data", "code": "84121705" },
      { "name": "Financial asset management service", "code": "84121706" },
      {
        "name": "Securities and commodities markets services",
        "code": "84121800"
      },
      { "name": "Stock market trading services", "code": "84121801" },
      { "name": "Commodities or futures market services", "code": "84121802" },
      { "name": "Government bonds", "code": "84121803" },
      { "name": "Privately issued bonds", "code": "84121804" },
      { "name": "Precious metals market services", "code": "84121805" },
      { "name": "Securities Custodial Services", "code": "84121806" },
      { "name": "Mortgage banking", "code": "84121900" },
      { "name": "Housing finance", "code": "84121901" },
      { "name": "Re financing services", "code": "84121902" },
      { "name": "Commercial mortgage finance", "code": "84121903" },
      { "name": "Cash vault services", "code": "84122000" },
      { "name": "Deposit verification services", "code": "84122001" },
      { "name": "Insurance and retirement services", "code": "84130000" },
      {
        "name": "Insurance services for structures and property and possessions",
        "code": "84131500"
      },
      { "name": "Building or building contents insurance", "code": "84131501" },
      { "name": "Homeowners or renters insurance", "code": "84131502" },
      { "name": "Car or truck insurance", "code": "84131503" },
      { "name": "Cargo insurance", "code": "84131504" },
      { "name": "Marine insurance", "code": "84131505" },
      { "name": "Reinsurance services", "code": "84131506" },
      { "name": "Business interruption insurance", "code": "84131507" },
      { "name": "Cash in transit insurance", "code": "84131508" },
      { "name": "Comprehensive projects insurance", "code": "84131509" },
      { "name": "Contractors all risks insurance", "code": "84131510" },
      { "name": "Deterioration of stocks insurance", "code": "84131511" },
      { "name": "Electronic equipment insurance", "code": "84131512" },
      { "name": "Erection all risks insurance", "code": "84131513" },
      { "name": "Fidelity guarantee insurance", "code": "84131514" },
      { "name": "Jewelers block insurance", "code": "84131515" },
      { "name": "Professional indemnity insurance", "code": "84131516" },
      { "name": "Travel insurance", "code": "84131517" },
      { "name": "Bicycle insurance", "code": "84131518" },
      { "name": "Fire insurance", "code": "84131519" },
      { "name": "Life and health and accident insurance", "code": "84131600" },
      { "name": "Life insurance", "code": "84131601" },
      { "name": "Health or hospitalization insurance", "code": "84131602" },
      { "name": "Accidental injury insurance", "code": "84131603" },
      { "name": "Disability insurance", "code": "84131604" },
      { "name": "Workmens insurance", "code": "84131605" },
      { "name": "Unemployment insurance", "code": "84131606" },
      { "name": "Liability insurance", "code": "84131607" },
      { "name": "Medical Claims Review and Management", "code": "84131608" },
      { "name": "Employee assistance programs", "code": "84131609" },
      { "name": "Flexible spending accounts FSA", "code": "84131610" },
      { "name": "Medical malpractice insurance", "code": "84131611" },
      { "name": "Pension funds", "code": "84131700" },
      { "name": "Employer administered pension funds", "code": "84131701" },
      {
        "name": "Union or guild administered pension funds",
        "code": "84131702"
      },
      { "name": "Retirement funds", "code": "84131800" },
      {
        "name": "Self directed or employer sponsored retirement funds",
        "code": "84131801"
      },
      {
        "name": "Self directed or self initiated retirement plans",
        "code": "84131802"
      },
      { "name": "Credit agencies", "code": "84140000" },
      { "name": "Governmental credit agencies", "code": "84141500" },
      { "name": "Farm credit services", "code": "84141501" },
      { "name": "Small business loan agencies", "code": "84141502" },
      { "name": "Minority owned business programs", "code": "84141503" },
      { "name": "Personal credit agencies", "code": "84141600" },
      {
        "name": "Consumer credit gathering or reporting services",
        "code": "84141601"
      },
      { "name": "Credit card service providers", "code": "84141602" },
      { "name": "Business credit agencies", "code": "84141700" },
      {
        "name": "Business credit gathering or reporting services",
        "code": "84141701"
      },
      { "name": "Value added network VAN services", "code": "84141702" }
    ]
  },
  {
    "name": "Healthcare Services",
    "code": "85000000",
    "subcategories": [
      { "name": "Comprehensive health services", "code": "85100000" },
      { "name": "Healthcare centers", "code": "85101500" },
      { "name": "Emergency or surgical hospital services", "code": "85101501" },
      { "name": "Private specialized clinic services", "code": "85101502" },
      { "name": "Medical office services", "code": "85101503" },
      { "name": "Psychiatric hospital services", "code": "85101504" },
      { "name": "Respiratory hospital services", "code": "85101505" },
      { "name": "Substance abuse hospital services", "code": "85101506" },
      { "name": "Urgent care centers", "code": "85101507" },
      { "name": "Mobile healthcare centers or services", "code": "85101508" },
      {
        "name": "Gynecological or obstetrical hospital services",
        "code": "85101509"
      },
      { "name": "Healthcare provider support persons", "code": "85101600" },
      { "name": "Nursing services", "code": "85101601" },
      {
        "name": "Midwifery or child birth preparation services",
        "code": "85101602"
      },
      {
        "name": "Personal care services in specialized institutions",
        "code": "85101603"
      },
      {
        "name": "Physicians personnel assistance services",
        "code": "85101604"
      },
      { "name": "Home health assistants", "code": "85101605" },
      { "name": "Health administration services", "code": "85101700" },
      { "name": "Health policy", "code": "85101701" },
      { "name": "Health legislation or regulations", "code": "85101702" },
      { "name": "Health service planning", "code": "85101703" },
      { "name": "Health economics", "code": "85101704" },
      { "name": "Public health administration", "code": "85101705" },
      { "name": "Traditional healthcare services", "code": "85101706" },
      { "name": "Health systems evaluation services", "code": "85101707" },
      { "name": "Disease prevention and control", "code": "85110000" },
      {
        "name": "Contagious disease prevention and control",
        "code": "85111500"
      },
      { "name": "Aids prevention or control services", "code": "85111501" },
      {
        "name": "Parasitic disease prevention or control services",
        "code": "85111502"
      },
      {
        "name": "Fungal diseases prevention or control services",
        "code": "85111503"
      },
      {
        "name": "Tuberculosis prevention or control services",
        "code": "85111504"
      },
      { "name": "Leprosy prevention or control services", "code": "85111505" },
      {
        "name": "Bacterial disease prevention or control services",
        "code": "85111506"
      },
      {
        "name": "Sexually transmitted diseases prevention or control services",
        "code": "85111507"
      },
      {
        "name": "Viral diseases prevention or control services",
        "code": "85111508"
      },
      {
        "name": "Zoonotic diseases prevention or control services",
        "code": "85111509"
      },
      { "name": "Vaccination services", "code": "85111510" },
      { "name": "Quarantine services", "code": "85111511" },
      { "name": "Immunization services", "code": "85111512" },
      { "name": "Disinsectization services", "code": "85111513" },
      {
        "name": "Epidemics prevention or control services",
        "code": "85111514"
      },
      {
        "name": "Non contagious disease prevention and control",
        "code": "85111600"
      },
      {
        "name": "Bone diseases prevention or control services",
        "code": "85111601"
      },
      {
        "name": "Cancer or leukemia prevention or control services",
        "code": "85111602"
      },
      {
        "name": "Endocrine diseases prevention or control services",
        "code": "85111603"
      },
      {
        "name": "Heart diseases prevention or control services",
        "code": "85111604"
      },
      {
        "name": "Immunologic prevention or control services",
        "code": "85111605"
      },
      {
        "name": "Allergies prevention or control services",
        "code": "85111606"
      },
      {
        "name": "Neurological disorders prevention or control services",
        "code": "85111607"
      },
      {
        "name": "Nutritional diseases prevention or control services",
        "code": "85111608"
      },
      {
        "name": "Radiation sickness prevention or control services",
        "code": "85111609"
      },
      {
        "name": "Digestive system diseases prevention or control services",
        "code": "85111610"
      },
      {
        "name": "Eye disease prevention or control services",
        "code": "85111611"
      },
      {
        "name": "Respiratory diseases prevention or control services",
        "code": "85111612"
      },
      {
        "name": "Tropical diseases prevention or control services",
        "code": "85111613"
      },
      {
        "name": "Childhood diseases prevention or control services",
        "code": "85111614"
      },
      {
        "name": "Diarrheal diseases prevention or control services",
        "code": "85111615"
      },
      {
        "name": "Alcoholism prevention or control services",
        "code": "85111616"
      },
      {
        "name": "Drug addiction prevention or control services",
        "code": "85111617"
      },
      { "name": "Disease vectors management and control", "code": "85111700" },
      { "name": "Ticks management or control services", "code": "85111701" },
      {
        "name": "Tsetse flies management or control services",
        "code": "85111702"
      },
      { "name": "Bacteria management or control services", "code": "85111703" },
      { "name": "Mosquito management or control services", "code": "85111704" },
      { "name": "Medical practice", "code": "85120000" },
      { "name": "Primary care practitioners services", "code": "85121500" },
      {
        "name": "Primary care physicians home visits services",
        "code": "85121501"
      },
      {
        "name": "Primary care physicians consultation services",
        "code": "85121502"
      },
      {
        "name": "Primary care physicians control services",
        "code": "85121503"
      },
      {
        "name": "Primary care physicians emergency medical services",
        "code": "85121504"
      },
      { "name": "Medical doctors specialist services", "code": "85121600" },
      { "name": "Gynecologic or obstetric services", "code": "85121601" },
      { "name": "Nephrology services", "code": "85121602" },
      { "name": "Cardiology services", "code": "85121603" },
      { "name": "Pulomonary specialists services", "code": "85121604" },
      { "name": "Gastroenterologists services", "code": "85121605" },
      { "name": "Geriatric services", "code": "85121606" },
      { "name": "Psychiatrist services", "code": "85121607" },
      { "name": "Psychologists services", "code": "85121608" },
      { "name": "Surgery services", "code": "85121609" },
      { "name": "Ophthalmologists services", "code": "85121610" },
      { "name": "Dermatology services", "code": "85121611" },
      { "name": "Orthopedics services", "code": "85121612" },
      { "name": "Pediatric services", "code": "85121613" },
      { "name": "Nervous system specialist services", "code": "85121614" },
      { "name": "Oncology service", "code": "85121615" },
      {
        "name": "Anesthesiology and resuscitation service",
        "code": "85121616"
      },
      {
        "name": "Healthcare providers specialists services",
        "code": "85121700"
      },
      { "name": "Psychotherapists services", "code": "85121701" },
      { "name": "Optometrists services", "code": "85121702" },
      { "name": "Podiatrists services", "code": "85121703" },
      { "name": "Speech specialists services", "code": "85121704" },
      { "name": "Acupuncturists services", "code": "85121705" },
      { "name": "Chiropractors services", "code": "85121706" },
      { "name": "Medical laboratories", "code": "85121800" },
      { "name": "Blood analysis laboratory services", "code": "85121801" },
      { "name": "Bacteriological laboratory services", "code": "85121802" },
      { "name": "Biological laboratory services", "code": "85121803" },
      { "name": "Pathological laboratory services", "code": "85121804" },
      { "name": "Urinalysis laboratory services", "code": "85121805" },
      { "name": "Neurological laboratory services", "code": "85121806" },
      { "name": "Ultrasound laboratory services", "code": "85121807" },
      { "name": "X ray laboratory services", "code": "85121808" },
      {
        "name": "Blood or sperm or transplant organ banks services",
        "code": "85121809"
      },
      { "name": "Drug or alcohol screening", "code": "85121810" },
      { "name": "Laboratory equipment rental service", "code": "85121811" },
      { "name": "Pharmacists", "code": "85121900" },
      { "name": "Pharmaceutical preparation services", "code": "85121901" },
      { "name": "Commercial pharmaceutical services", "code": "85121902" },
      { "name": "Dental services", "code": "85122000" },
      { "name": "Dentists services", "code": "85122001" },
      { "name": "Dental hygienists services", "code": "85122002" },
      { "name": "Dentist support staff services", "code": "85122003" },
      { "name": "Oral surgeons services", "code": "85122004" },
      { "name": "Orthodontic services", "code": "85122005" },
      { "name": "Rehabilitation services", "code": "85122100" },
      { "name": "Physical therapy services", "code": "85122101" },
      { "name": "Occupational therapy services", "code": "85122102" },
      {
        "name": "Rehabilitation services for substance abuse",
        "code": "85122103"
      },
      { "name": "Athletic rehabilitation services", "code": "85122104" },
      { "name": "Eating disorders services", "code": "85122105" },
      { "name": "Brain or spinal cord injury services", "code": "85122106" },
      {
        "name": "Blind or vision impaired rehabilitation services",
        "code": "85122107"
      },
      { "name": "Speech or language therapy", "code": "85122108" },
      {
        "name": "Rehabilitation services for people with chronic disabilities",
        "code": "85122109"
      },
      {
        "name": "Individual health screening and assessment services",
        "code": "85122200"
      },
      { "name": "Individual health assessment", "code": "85122201" },
      {
        "name": "Medical science research and experimentation",
        "code": "85130000"
      },
      { "name": "Experimental medicine services", "code": "85131500" },
      { "name": "Organ transplant services", "code": "85131501" },
      { "name": "Clinical human drug trials", "code": "85131502" },
      { "name": "Animal experimentation", "code": "85131503" },
      { "name": "Human experimentation", "code": "85131504" },
      { "name": "Space experimentation", "code": "85131505" },
      { "name": "Medical ethics", "code": "85131600" },
      { "name": "Euthanasia issues", "code": "85131601" },
      { "name": "Medical code of conduct", "code": "85131602" },
      { "name": "Medical societies", "code": "85131603" },
      { "name": "International drug monitoring services", "code": "85131604" },
      { "name": "Medical science and research", "code": "85131700" },
      { "name": "Pharmaceutical research services", "code": "85131701" },
      { "name": "Bacteriology research services", "code": "85131702" },
      { "name": "Biomedical research services", "code": "85131703" },
      { "name": "Cardiology research services", "code": "85131704" },
      { "name": "Anatomy research services", "code": "85131705" },
      { "name": "Pathology research services", "code": "85131706" },
      { "name": "Embryology research services", "code": "85131707" },
      { "name": "Epidemiology research services", "code": "85131708" },
      { "name": "Genetics research services", "code": "85131709" },
      { "name": "Immunology research services", "code": "85131710" },
      { "name": "Physiology research services", "code": "85131711" },
      { "name": "Toxicology research services", "code": "85131712" },
      { "name": "Neurology research services", "code": "85131713" },
      { "name": "Alternative and holistic medicine", "code": "85140000" },
      { "name": "Faith healers", "code": "85141500" },
      { "name": "Witch doctors or voodoo services", "code": "85141501" },
      { "name": "Faith healers services", "code": "85141502" },
      { "name": "Shamans", "code": "85141503" },
      { "name": "Energy work", "code": "85141504" },
      { "name": "Herbal treatments", "code": "85141600" },
      { "name": "Herbal medicine or herbalists services", "code": "85141601" },
      { "name": "Algae or sea weed medical cures", "code": "85141602" },
      { "name": "Hot springs cure services", "code": "85141603" },
      { "name": "Homeopathic practice", "code": "85141700" },
      { "name": "Initial diagnostic assessment", "code": "85141701" },
      { "name": "Remedy consultations", "code": "85141702" },
      { "name": "Food and nutrition services", "code": "85150000" },
      { "name": "Food technology", "code": "85151500" },
      { "name": "Food hygiene control services", "code": "85151501" },
      { "name": "Food contamination control services", "code": "85151502" },
      {
        "name": "Food preservation management or control services",
        "code": "85151503"
      },
      {
        "name": "Food preparation counseling or control services",
        "code": "85151504"
      },
      { "name": "Food research services", "code": "85151505" },
      { "name": "Studies on foods or food habits", "code": "85151506" },
      {
        "name": "Food additive or quality standards services",
        "code": "85151507"
      },
      { "name": "Food analysis services", "code": "85151508" },
      { "name": "Food legislation services", "code": "85151509" },
      { "name": "Nutrition issues", "code": "85151600" },
      { "name": "Nutrition programming services", "code": "85151601" },
      { "name": "Breast or bottle feeding policy", "code": "85151602" },
      { "name": "Nutritional rehabilitation services", "code": "85151603" },
      { "name": "Nutrition project evaluation", "code": "85151604" },
      {
        "name": "Food or nutrition development strategies",
        "code": "85151605"
      },
      { "name": "Nutrient deficiency control programs", "code": "85151606" },
      { "name": "Diet control or programs", "code": "85151607" },
      { "name": "Food policy planning and aid", "code": "85151700" },
      { "name": "Food standards", "code": "85151701" },
      {
        "name": "Food aid global information or early warning systems services",
        "code": "85151702"
      },
      {
        "name": "Assessment of emergency food requirements",
        "code": "85151703"
      },
      {
        "name": "National food intervention policy or programs",
        "code": "85151704"
      },
      {
        "name": "Evaluation of food aid nutritional impact",
        "code": "85151705"
      },
      {
        "name": "Medical Surgical Equipment Maintenance Refurbishment and Repair Services",
        "code": "85160000"
      },
      { "name": "Medical or surgical equipment repair", "code": "85161500" },
      {
        "name": "Medical capital equipment maintenance or repair",
        "code": "85161501"
      },
      {
        "name": "Medical minor equipment maintenance or repair",
        "code": "85161502"
      },
      {
        "name": "Medical or surgical instrument maintenance or repair",
        "code": "85161503"
      },
      {
        "name": "Medical or surgical equipment service agreement",
        "code": "85161504"
      },
      {
        "name": "Medical or surgical equipment or implant rental and shipping fee",
        "code": "85161505"
      },
      { "name": "Death and dying support services", "code": "85170000" },
      { "name": "Funeral and associated services", "code": "85171500" },
      { "name": "Grave digging", "code": "85171501" },
      { "name": "Hospice care", "code": "85171600" },
      { "name": "Hospice administration service", "code": "85171601" },
      { "name": "Thanatology services", "code": "85171700" },
      { "name": "Necropsy service", "code": "85171701" }
    ]
  },
  {
    "name": "Education and Training Services",
    "code": "86000000",
    "subcategories": [
      { "name": "Vocational training", "code": "86100000" },
      {
        "name": "Agriculture and forestry and other natural resources training services",
        "code": "86101500"
      },
      { "name": "Agro industry vocational training", "code": "86101501" },
      { "name": "Dairy industry vocational training", "code": "86101502" },
      { "name": "Meat industry vocational training", "code": "86101503" },
      {
        "name": "Agriculture vocational training services",
        "code": "86101504"
      },
      {
        "name": "Rural youth or farmers vocational training services",
        "code": "86101505"
      },
      { "name": "Forestry vocational training services", "code": "86101506" },
      { "name": "Fishery vocational training services", "code": "86101507" },
      {
        "name": "Environmental vocational training services",
        "code": "86101508"
      },
      {
        "name": "Natural resources vocational training services",
        "code": "86101509"
      },
      { "name": "Scientific vocational training services", "code": "86101600" },
      { "name": "Computer vocational training services", "code": "86101601" },
      {
        "name": "Energy related vocational training services",
        "code": "86101602"
      },
      { "name": "Chemistry vocational training services", "code": "86101603" },
      { "name": "Biology vocational training services", "code": "86101604" },
      { "name": "Medical vocational training services", "code": "86101605" },
      {
        "name": "Electronics vocational training services",
        "code": "86101606"
      },
      {
        "name": "Telecommunications vocational training services",
        "code": "86101607"
      },
      { "name": "Hydraulics vocational training services", "code": "86101608" },
      { "name": "Industrial vocational training services", "code": "86101609" },
      {
        "name": "Engineering vocational training services",
        "code": "86101610"
      },
      {
        "name": "Non scientific vocational training services",
        "code": "86101700"
      },
      {
        "name": "Communications vocational training services",
        "code": "86101701"
      },
      { "name": "Tourism related training", "code": "86101702" },
      { "name": "Library or documentation training", "code": "86101703" },
      { "name": "Procurement or supply chain training", "code": "86101704" },
      { "name": "Clerical training", "code": "86101705" },
      {
        "name": "Health assistance vocational training services",
        "code": "86101706"
      },
      {
        "name": "Personal care vocational training services",
        "code": "86101707"
      },
      { "name": "Literacy services", "code": "86101708" },
      { "name": "Safety training services", "code": "86101709" },
      { "name": "Teacher training services", "code": "86101710" },
      { "name": "Fire fighting training services", "code": "86101711" },
      { "name": "Handcrafts vocational training services", "code": "86101712" },
      { "name": "Law vocational training services", "code": "86101713" },
      {
        "name": "Law enforcement vocational training services",
        "code": "86101714"
      },
      {
        "name": "Road or rail transportation vocational training services",
        "code": "86101715"
      },
      { "name": "Shipping vocational training services", "code": "86101716" },
      { "name": "Marketing professional training service", "code": "86101717" },
      {
        "name": "In service training and manpower development",
        "code": "86101800"
      },
      {
        "name": "Bank or finance sector manpower development",
        "code": "86101801"
      },
      {
        "name": "Re training or refreshing training services",
        "code": "86101802"
      },
      { "name": "Vocational rehabilitation services", "code": "86101803" },
      { "name": "Commercial sector manpower development", "code": "86101804" },
      { "name": "Industrial sector manpower development", "code": "86101805" },
      { "name": "Health sector manpower development", "code": "86101806" },
      { "name": "Management sector manpower development", "code": "86101807" },
      {
        "name": "Public sector manpower development services",
        "code": "86101808"
      },
      {
        "name": "Merchant marine vocational training services",
        "code": "86101809"
      },
      { "name": "Personnel skills training", "code": "86101810" },
      { "name": "Alternative educational systems", "code": "86110000" },
      { "name": "Distance learning services", "code": "86111500" },
      { "name": "Distance learning guidance services", "code": "86111501" },
      { "name": "Distance teaching services", "code": "86111502" },
      { "name": "Certificated distance learning services", "code": "86111503" },
      {
        "name": "Non certificated distance learning services",
        "code": "86111504"
      },
      { "name": "Distance learning assessment services", "code": "86111505" },
      { "name": "Adult education", "code": "86111600" },
      { "name": "Evening courses", "code": "86111601" },
      { "name": "Part time adult education services", "code": "86111602" },
      { "name": "Parent education", "code": "86111603" },
      { "name": "Employee education", "code": "86111604" },
      { "name": "Language schooling", "code": "86111700" },
      {
        "name": "Conversational foreign language instruction",
        "code": "86111701"
      },
      { "name": "Foreign language immersion instruction", "code": "86111702" },
      { "name": "Educational exchanges", "code": "86111800" },
      {
        "name": "Educational exchanges between universities",
        "code": "86111801"
      },
      { "name": "Educational exchanges between schools", "code": "86111802" },
      { "name": "Educational institutions", "code": "86120000" },
      { "name": "Elementary and secondary schools", "code": "86121500" },
      { "name": "Pre school educational services", "code": "86121501" },
      {
        "name": "Religious elementary or secondary schools",
        "code": "86121502"
      },
      { "name": "Private elementary or secondary schools", "code": "86121503" },
      { "name": "Public elementary or secondary schools", "code": "86121504" },
      { "name": "Junior colleges", "code": "86121600" },
      { "name": "Community colleges", "code": "86121601" },
      { "name": "Technical institutes", "code": "86121602" },
      { "name": "University and colleges", "code": "86121700" },
      { "name": "Undergraduate programs", "code": "86121701" },
      { "name": "Postgraduate programs", "code": "86121702" },
      { "name": "Professional schools", "code": "86121800" },
      { "name": "Theological seminaries", "code": "86121802" },
      { "name": "Technical professional schools", "code": "86121803" },
      { "name": "Non technical professional schools", "code": "86121804" },
      { "name": "Specialized educational services", "code": "86130000" },
      { "name": "Fine arts", "code": "86131500" },
      { "name": "Theater studies", "code": "86131501" },
      { "name": "Painting", "code": "86131502" },
      { "name": "Sculpture", "code": "86131503" },
      { "name": "Media studies", "code": "86131504" },
      { "name": "Music and drama", "code": "86131600" },
      { "name": "Music schools", "code": "86131601" },
      { "name": "Dance education", "code": "86131602" },
      { "name": "Drama studies", "code": "86131603" },
      { "name": "Driving and flying and sailing", "code": "86131700" },
      { "name": "Vehicle driving schools services", "code": "86131701" },
      { "name": "Flight school services", "code": "86131702" },
      { "name": "Yachting or boating school services", "code": "86131703" },
      { "name": "Military education", "code": "86131800" },
      { "name": "Service academies", "code": "86131801" },
      { "name": "Pilot schools", "code": "86131802" },
      { "name": "Military police training", "code": "86131803" },
      { "name": "War college", "code": "86131804" },
      { "name": "Schools for people with disabilities", "code": "86131900" },
      {
        "name": "Primary schooling services for people with disabilities",
        "code": "86131901"
      },
      {
        "name": "Secondary schooling services for people with disabilities",
        "code": "86131902"
      },
      {
        "name": "Specialized schools for people with disabilities",
        "code": "86131903"
      },
      {
        "name": "Specialized rehabilitation services for people with disabilities",
        "code": "86131904"
      },
      {
        "name": "Management education and training services",
        "code": "86132000"
      },
      { "name": "Executive coaching service", "code": "86132001" },
      {
        "name": "Training planning, facilitation and delivery services",
        "code": "86132100"
      },
      { "name": "Training facilitation service", "code": "86132101" },
      {
        "name": "Training planning and development consultancy service",
        "code": "86132102"
      },
      { "name": "Educational support services", "code": "86132200" },
      { "name": "Training workshop service", "code": "86132201" },
      { "name": "Field trip service", "code": "86132202" },
      { "name": "Educational facilities", "code": "86140000" },
      { "name": "Educational guidance services", "code": "86141500" },
      { "name": "Educational advisory services", "code": "86141501" },
      {
        "name": "Universities cooperation guidance services",
        "code": "86141502"
      },
      { "name": "Study abroad advisory services", "code": "86141503" },
      { "name": "Tuition reimbursement programs", "code": "86141504" },
      { "name": "Students organizations", "code": "86141600" },
      { "name": "Students hobby clubs", "code": "86141601" },
      { "name": "Students unions", "code": "86141602" },
      { "name": "Students travelling organizations", "code": "86141603" },
      { "name": "Educational technology", "code": "86141700" },
      { "name": "Language laboratories", "code": "86141701" },
      { "name": "Audio visual technology", "code": "86141702" },
      { "name": "Computer programmed instruction", "code": "86141703" },
      { "name": "Library or documentation services", "code": "86141704" }
    ]
  },
  {
    "name": "Travel and Food and Lodging and Entertainment Services",
    "code": "90000000",
    "subcategories": [
      { "name": "Restaurants and catering", "code": "90100000" },
      { "name": "Eating and drinking establishments", "code": "90101500" },
      { "name": "Restaurants", "code": "90101501" },
      { "name": "Bars", "code": "90101502" },
      { "name": "Fast food establishments", "code": "90101503" },
      { "name": "On street food vendors", "code": "90101504" },
      { "name": "Banquet and catering services", "code": "90101600" },
      { "name": "Banquet facilities", "code": "90101601" },
      { "name": "Party tent services", "code": "90101602" },
      { "name": "Catering services", "code": "90101603" },
      {
        "name": "Construction or work site catering services",
        "code": "90101604"
      },
      {
        "name": "Food service for transportation operators",
        "code": "90101605"
      },
      { "name": "Cafeteria services", "code": "90101700" },
      { "name": "On site cafeteria management", "code": "90101701" },
      { "name": "Carryout and takeaway services", "code": "90101800" },
      { "name": "Professionally prepared carryout meals", "code": "90101801" },
      { "name": "Delivered meals services", "code": "90101802" },
      {
        "name": "Food and beverage equipment rental or leasing and maintenance services",
        "code": "90101900"
      },
      {
        "name": "Coffee machine and grinder rental and maintenance service",
        "code": "90101901"
      },
      {
        "name": "Coffee or hot drink vending machine rental and maintenance service",
        "code": "90101902"
      },
      { "name": "Stove rental service", "code": "90101903" },
      { "name": "Microwave oven rental service", "code": "90101904" },
      { "name": "Blender rental service", "code": "90101905" },
      { "name": "Confetti machine rental service", "code": "90101906" },
      { "name": "Tavola rental service", "code": "90101907" },
      { "name": "Propane gas balloon rental service", "code": "90101908" },
      {
        "name": "Hotels and lodging and meeting facilities",
        "code": "90110000"
      },
      { "name": "Hotels and motels and inns", "code": "90111500" },
      { "name": "Hotels", "code": "90111501" },
      { "name": "Lodges or resorts", "code": "90111502" },
      { "name": "Bed and breakfast inns", "code": "90111503" },
      { "name": "Cottage rental services", "code": "90111504" },
      { "name": "Meeting facilities", "code": "90111600" },
      { "name": "Conference centers", "code": "90111601" },
      { "name": "Videoconferencing facilities", "code": "90111602" },
      { "name": "Meeting or banquet rooms", "code": "90111603" },
      { "name": "Marquees", "code": "90111604" },
      { "name": "Camping and wilderness facilities", "code": "90111700" },
      { "name": "Campsites", "code": "90111701" },
      { "name": "Government owned parks", "code": "90111702" },
      {
        "name": "Recreational vehicle campsite facilities",
        "code": "90111703"
      },
      { "name": "Hotel rooms", "code": "90111800" },
      { "name": "Single room", "code": "90111801" },
      { "name": "Double room", "code": "90111802" },
      { "name": "Suite", "code": "90111803" },
      { "name": "Specialized accommodation services", "code": "90111900" },
      {
        "name": "Worker worksite room or unit accommodation service",
        "code": "90111901"
      },
      {
        "name": "Student residential room or unit accommodation service",
        "code": "90111902"
      },
      { "name": "Travel facilitation", "code": "90120000" },
      { "name": "Travel agents", "code": "90121500" },
      { "name": "Tour arrangement services", "code": "90121501" },
      { "name": "Travel agencies", "code": "90121502" },
      { "name": "Chartering services", "code": "90121503" },
      { "name": "Travel document assistance", "code": "90121600" },
      { "name": "Passport services", "code": "90121601" },
      { "name": "Visa or auxiliary document services", "code": "90121602" },
      { "name": "Passenger ticket verification service", "code": "90121603" },
      { "name": "Guides and interpreters", "code": "90121700" },
      { "name": "Area or tour guides", "code": "90121701" },
      { "name": "Interpreters", "code": "90121702" },
      { "name": "Emergency travel assistance services", "code": "90121800" },
      { "name": "Emergency travel agent assistance", "code": "90121801" },
      { "name": "Performing arts", "code": "90130000" },
      { "name": "Live performances", "code": "90131500" },
      { "name": "Theatrical performances or plays", "code": "90131501" },
      { "name": "Dance performances", "code": "90131502" },
      { "name": "Opera", "code": "90131503" },
      { "name": "Concerts", "code": "90131504" },
      { "name": "Taped or motion picture performances", "code": "90131600" },
      { "name": "Motion pictures", "code": "90131601" },
      { "name": "Videotaped entertainment", "code": "90131602" },
      { "name": "Cinema distribution service", "code": "90131603" },
      { "name": "Commercial sports", "code": "90140000" },
      { "name": "Professional sporting events", "code": "90141500" },
      { "name": "League play", "code": "90141501" },
      { "name": "Competitive events", "code": "90141502" },
      { "name": "Exhibitions", "code": "90141503" },
      { "name": "Sports event promotion and sponsorship", "code": "90141600" },
      {
        "name": "Company sponsored professional sports events",
        "code": "90141601"
      },
      { "name": "Company sponsored amateur sports events", "code": "90141602" },
      { "name": "Sporting event promotion services", "code": "90141603" },
      { "name": "Amateur and recreational sports", "code": "90141700" },
      { "name": "Youth competitive sports leagues", "code": "90141701" },
      { "name": "Adult sports leagues", "code": "90141702" },
      { "name": "Youth sports", "code": "90141703" },
      { "name": "Entertainment services", "code": "90150000" },
      { "name": "Tourist attractions", "code": "90151500" },
      { "name": "Museums", "code": "90151501" },
      { "name": "Historical or cultural sites", "code": "90151502" },
      { "name": "Zoological gardens", "code": "90151503" },
      { "name": "Travelling shows", "code": "90151600" },
      { "name": "Circuses", "code": "90151601" },
      { "name": "Touring companies", "code": "90151602" },
      { "name": "Art exhibitions", "code": "90151603" },
      { "name": "Amusement parks", "code": "90151700" },
      { "name": "Theme parks", "code": "90151701" },
      { "name": "Water parks", "code": "90151702" },
      { "name": "Miniature golf courses", "code": "90151703" },
      { "name": "Carnivals and fairs", "code": "90151800" },
      { "name": "Travelling carnivals", "code": "90151801" },
      {
        "name": "Fair organization or management services",
        "code": "90151802"
      },
      { "name": "Fair stands creation or construction", "code": "90151803" },
      { "name": "Gambling and betting establishments", "code": "90151900" },
      { "name": "Casinos", "code": "90151901" },
      { "name": "Card clubs", "code": "90151902" },
      { "name": "Racetracks", "code": "90151903" },
      { "name": "Nightclubs and dance halls", "code": "90152000" },
      { "name": "Nightclubs", "code": "90152001" },
      { "name": "Dance halls", "code": "90152002" },
      { "name": "Concierge services", "code": "90152100" },
      { "name": "Personal assistance services", "code": "90152101" }
    ]
  },
  {
    "name": "Personal and Domestic Services",
    "code": "91000000",
    "subcategories": [
      { "name": "Personal appearance", "code": "91100000" },
      {
        "name": "Spa and workout and rejuvenation facilities",
        "code": "91101500"
      },
      { "name": "Health or fitness clubs", "code": "91101501" },
      { "name": "Spas", "code": "91101502" },
      { "name": "Massage services", "code": "91101503" },
      { "name": "Aerobics or exercise classes", "code": "91101504" },
      { "name": "Turkish or steam or ritual baths", "code": "91101505" },
      { "name": "Face and body care and adornment", "code": "91101600" },
      { "name": "Facial or body treatments", "code": "91101601" },
      { "name": "Make up consultation", "code": "91101602" },
      { "name": "Tattoo services", "code": "91101603" },
      { "name": "Body piercing services", "code": "91101604" },
      { "name": "Electrolysis", "code": "91101605" },
      { "name": "Make up application service", "code": "91101606" },
      { "name": "Hair care", "code": "91101700" },
      { "name": "Hair cutting or color services", "code": "91101701" },
      { "name": "Hair weaving or replacement services", "code": "91101702" },
      { "name": "Clothing rental", "code": "91101800" },
      { "name": "Tuxedo or formalwear rental", "code": "91101801" },
      { "name": "Evening or bridal gown or dress rental", "code": "91101802" },
      { "name": "Costume rental", "code": "91101803" },
      { "name": "Fashion consultants", "code": "91101900" },
      { "name": "Color consultant", "code": "91101901" },
      { "name": "Fashion stylist", "code": "91101902" },
      { "name": "Wardrobe consultant", "code": "91101903" },
      { "name": "Tailoring service", "code": "91101904" },
      { "name": "Domestic and personal assistance", "code": "91110000" },
      { "name": "Laundering services", "code": "91111500" },
      { "name": "Uniform rental", "code": "91111501" },
      { "name": "Laundry services", "code": "91111502" },
      { "name": "Dry cleaning", "code": "91111503" },
      { "name": "Coin operated self service laundries", "code": "91111504" },
      { "name": "Household assistance and care", "code": "91111600" },
      { "name": "Housekeeping services", "code": "91111601" },
      { "name": "Yard or pool care services", "code": "91111602" },
      { "name": "Cooking or food preparation services", "code": "91111603" },
      { "name": "Consumer buying and bartering services", "code": "91111700" },
      { "name": "Used clothing consignment services", "code": "91111701" },
      { "name": "Barter clubs or consortiums", "code": "91111702" },
      { "name": "Wardrobe buying services", "code": "91111703" },
      { "name": "Personal item care and storage", "code": "91111800" },
      { "name": "Valet services", "code": "91111801" },
      { "name": "Check room concessions", "code": "91111802" },
      { "name": "Locker rental", "code": "91111803" },
      { "name": "Fur storage", "code": "91111804" },
      { "name": "Personal care services", "code": "91111900" },
      { "name": "Infant or child daycare services", "code": "91111901" },
      { "name": "Nanny or babysitting services", "code": "91111902" },
      { "name": "Elderly daycare services", "code": "91111903" },
      { "name": "Assisted living services", "code": "91111904" }
    ]
  },
  {
    "name": "National Defense and Public Order and Security and Safety Services",
    "code": "92000000",
    "subcategories": [
      { "name": "Public order and safety", "code": "92100000" },
      { "name": "Police services", "code": "92101500" },
      { "name": "Policing services", "code": "92101501" },
      {
        "name": "Special weapons and tactics SWAT or riot teams",
        "code": "92101502"
      },
      { "name": "Community outreach programs", "code": "92101503" },
      { "name": "Crime deterrence programs", "code": "92101504" },
      {
        "name": "Graffiti and vandalism deterrence support service",
        "code": "92101505"
      },
      { "name": "Fire services", "code": "92101600" },
      {
        "name": "Municipal or national firefighting services",
        "code": "92101601"
      },
      { "name": "Volunteer fire department services", "code": "92101602" },
      { "name": "Fire prevention services", "code": "92101603" },
      {
        "name": "Forest or wilderness firefighting services",
        "code": "92101604"
      },
      { "name": "Jail and prison system", "code": "92101700" },
      { "name": "Jail or prison or penitentiary services", "code": "92101701" },
      { "name": "Youth camps or facilities services", "code": "92101702" },
      { "name": "Half way house services", "code": "92101703" },
      { "name": "Mentally impaired criminal facilities", "code": "92101704" },
      { "name": "Court system", "code": "92101800" },
      { "name": "Sheriffs services", "code": "92101801" },
      { "name": "Plea bargain agreements", "code": "92101802" },
      { "name": "Civil case court expenses", "code": "92101803" },
      { "name": "Criminal case fees or fines", "code": "92101804" },
      { "name": "Appeals process or judicial review", "code": "92101805" },
      { "name": "Rescue services", "code": "92101900" },
      { "name": "Search and rescue teams", "code": "92101901" },
      { "name": "Ambulance services", "code": "92101902" },
      { "name": "Life saving helicopter services", "code": "92101903" },
      { "name": "Lifeguard services for pool or beach", "code": "92101904" },
      { "name": "Military services and national defense", "code": "92110000" },
      {
        "name": "Maintenance of international peace and security",
        "code": "92111500"
      },
      {
        "name": "Dispute mediation or conciliation or negotiation or settlement",
        "code": "92111501"
      },
      { "name": "Peace keeping operations", "code": "92111502" },
      {
        "name": "Cease fire agreements or truce supervision",
        "code": "92111503"
      },
      { "name": "Fact finding missions", "code": "92111504" },
      { "name": "War prevention strategies", "code": "92111505" },
      { "name": "Counterterrorism", "code": "92111506" },
      { "name": "Embargoes", "code": "92111507" },
      { "name": "Disarmament", "code": "92111600" },
      { "name": "Arms limitations", "code": "92111601" },
      { "name": "Conventional arms disarmament", "code": "92111602" },
      { "name": "Nuclear freezes or disarmament", "code": "92111603" },
      { "name": "Weapons destruction", "code": "92111604" },
      { "name": "Disarmament negotiations or agreements", "code": "92111605" },
      { "name": "Mutual or balanced force reductions", "code": "92111606" },
      { "name": "Battle area clearance BAC service", "code": "92111607" },
      { "name": "Explosive ordnance disposal EOD service", "code": "92111608" },
      {
        "name": "Mine action center MAC or mine action coordination center MACC service",
        "code": "92111609"
      },
      { "name": "Post mine clearance inspection service", "code": "92111610" },
      { "name": "Demining post design service PDS", "code": "92111611" },
      {
        "name": "Demining preliminary development PD service",
        "code": "92111612"
      },
      {
        "name": "Mine victim or survivor assistance service",
        "code": "92111613"
      },
      {
        "name": "Physical security and stockpile management PSSM service",
        "code": "92111614"
      },
      { "name": "Military science and research", "code": "92111700" },
      { "name": "Military history", "code": "92111701" },
      { "name": "Conventional weapons usage", "code": "92111702" },
      { "name": "Chemical weapons usage", "code": "92111703" },
      { "name": "Guerilla warfare", "code": "92111704" },
      { "name": "Military strategy", "code": "92111705" },
      { "name": "Aerial maneuvers", "code": "92111706" },
      { "name": "Naval or submarine maneuvers", "code": "92111707" },
      { "name": "Land maneuvers", "code": "92111708" },
      { "name": "Military personnel and discipline", "code": "92111800" },
      { "name": "Civil defense", "code": "92111801" },
      { "name": "Compulsory military services", "code": "92111802" },
      { "name": "Voluntary military services", "code": "92111803" },
      { "name": "Military reservists", "code": "92111804" },
      { "name": "Guerrillas", "code": "92111805" },
      { "name": "Mercenaries", "code": "92111806" },
      { "name": "Veterans", "code": "92111807" },
      { "name": "Military courts", "code": "92111808" },
      { "name": "Military offenses", "code": "92111809" },
      { "name": "Military personnel", "code": "92111810" },
      { "name": "Military policy", "code": "92111900" },
      { "name": "National security", "code": "92111901" },
      { "name": "Defense contracts", "code": "92111902" },
      { "name": "Non first use policy", "code": "92111903" },
      { "name": "Arms race", "code": "92111904" },
      { "name": "Military relations", "code": "92111905" },
      { "name": "Military zones", "code": "92112000" },
      { "name": "Demilitarized zones", "code": "92112001" },
      { "name": "Nuclear or chemical weapon free zones", "code": "92112002" },
      { "name": "Peace zones", "code": "92112003" },
      { "name": "No fly zones", "code": "92112004" },
      { "name": "Minefield zone", "code": "92112005" },
      { "name": "Nuclear warfare", "code": "92112100" },
      { "name": "Nuclear safeguards", "code": "92112101" },
      { "name": "Nuclear weapon tests", "code": "92112102" },
      { "name": "Nuclear non proliferation", "code": "92112103" },
      { "name": "Military tactics", "code": "92112200" },
      { "name": "Weapons deployment", "code": "92112201" },
      { "name": "Military reconnaissance", "code": "92112202" },
      { "name": "Arms transfers", "code": "92112203" },
      { "name": "Military assistance", "code": "92112204" },
      { "name": "Troop withdrawals", "code": "92112205" },
      { "name": "Rapid deployment forces", "code": "92112206" },
      { "name": "Environmental warfare", "code": "92112207" },
      { "name": "Military bases", "code": "92112300" },
      { "name": "Domestic military bases", "code": "92112301" },
      { "name": "Foreign military bases", "code": "92112302" },
      { "name": "Naval bases", "code": "92112303" },
      { "name": "Armed conflicts and incidents", "code": "92112400" },
      { "name": "Border incidents", "code": "92112401" },
      { "name": "Limited war", "code": "92112402" },
      { "name": "Nuclear war", "code": "92112403" },
      { "name": "Space based war", "code": "92112404" },
      { "name": "Response to terrorist attacks", "code": "92112405" },
      { "name": "Security and personal safety", "code": "92120000" },
      { "name": "Guard services", "code": "92121500" },
      { "name": "Burglary protection services", "code": "92121502" },
      { "name": "Guard dog rental", "code": "92121503" },
      { "name": "Security guard services", "code": "92121504" },
      { "name": "Detective services", "code": "92121600" },
      { "name": "Detective agencies", "code": "92121601" },
      { "name": "Fingerprint services", "code": "92121602" },
      { "name": "Lie detection services", "code": "92121603" },
      { "name": "Private investigation services", "code": "92121604" },
      { "name": "Security systems services", "code": "92121700" },
      {
        "name": "Surveillance or alarm maintenance or monitoring",
        "code": "92121701"
      },
      { "name": "Fire alarm maintenance or monitoring", "code": "92121702" },
      { "name": "Store or business anti theft services", "code": "92121703" },
      {
        "name": "Confinement surveillance systems maintenance or monitoring",
        "code": "92121704"
      },
      {
        "name": "Armored car services and money transport",
        "code": "92121800"
      },
      { "name": "Armored car service", "code": "92121801" },
      { "name": "Money transport service", "code": "92121802" },
      { "name": "Money storage service", "code": "92121803" },
      { "name": "Marine security services", "code": "92121900" },
      { "name": "Security boat service", "code": "92121901" }
    ]
  },
  {
    "name": "Politics and Civic Affairs Services",
    "code": "93000000",
    "subcategories": [
      { "name": "Political systems and institutions", "code": "93100000" },
      { "name": "Political bodies", "code": "93101500" },
      {
        "name": "Political parties representation services",
        "code": "93101501"
      },
      { "name": "Political parties fund raising services", "code": "93101502" },
      {
        "name": "Political parties public appearance services",
        "code": "93101503"
      },
      { "name": "Political legislature services", "code": "93101504" },
      { "name": "Political judicial power or services", "code": "93101505" },
      { "name": "Political executive power or services", "code": "93101506" },
      { "name": "Political officials", "code": "93101600" },
      { "name": "Cabinet officers services", "code": "93101601" },
      { "name": "Governors services", "code": "93101602" },
      { "name": "Heads of states services", "code": "93101603" },
      { "name": "Presidential services", "code": "93101604" },
      { "name": "Prime ministers services", "code": "93101605" },
      { "name": "Monarch services", "code": "93101606" },
      { "name": "Statesmen services", "code": "93101607" },
      { "name": "Parliament members services", "code": "93101608" },
      { "name": "Legislative bodies and practice", "code": "93101700" },
      { "name": "National council services", "code": "93101701" },
      { "name": "Corporate states", "code": "93101702" },
      { "name": "Bill drafting services", "code": "93101703" },
      { "name": "Legislative hearings services", "code": "93101704" },
      { "name": "Intelligence services", "code": "93101705" },
      { "name": "Legislators services", "code": "93101706" },
      { "name": "Parliamentary practice services", "code": "93101707" },
      { "name": "Socio political conditions", "code": "93110000" },
      { "name": "Political movements", "code": "93111500" },
      { "name": "Extremist movements", "code": "93111501" },
      { "name": "Peace movements", "code": "93111502" },
      { "name": "Protest movements", "code": "93111503" },
      { "name": "Underground movements", "code": "93111504" },
      { "name": "Student movements", "code": "93111505" },
      { "name": "Peasant movements", "code": "93111506" },
      { "name": "Opposition movements", "code": "93111507" },
      {
        "name": "Political representation and participation",
        "code": "93111600"
      },
      { "name": "Political representation", "code": "93111601" },
      { "name": "Political participation", "code": "93111602" },
      { "name": "Proportional representation", "code": "93111603" },
      { "name": "Vote catcher services", "code": "93111604" },
      {
        "name": "Voter registration or counting or analysis or scrutiny services",
        "code": "93111605"
      },
      {
        "name": "Pressure groups representation or participation services",
        "code": "93111606"
      },
      { "name": "Election analysis services", "code": "93111607" },
      { "name": "Election organization services", "code": "93111608" },
      { "name": "International relations", "code": "93120000" },
      { "name": "Diplomacy", "code": "93121500" },
      { "name": "Diplomatic services", "code": "93121501" },
      { "name": "Consular services", "code": "93121502" },
      { "name": "Diplomats security services", "code": "93121503" },
      {
        "name": "Diplomatic privileges or immunities services",
        "code": "93121504"
      },
      { "name": "State immunities services", "code": "93121505" },
      { "name": "Embassies or Ambassadors services", "code": "93121506" },
      { "name": "State visits organization services", "code": "93121507" },
      { "name": "International law prescription services", "code": "93121508" },
      {
        "name": "International law promotion or recognition services",
        "code": "93121509"
      },
      { "name": "International relations and cooperation", "code": "93121600" },
      { "name": "Multilateral cooperation services", "code": "93121601" },
      { "name": "Military cooperation services", "code": "93121602" },
      { "name": "Political cooperation services", "code": "93121603" },
      {
        "name": "International economic cooperation services",
        "code": "93121604"
      },
      { "name": "North south cooperation services", "code": "93121605" },
      { "name": "East west cooperation services", "code": "93121606" },
      { "name": "International cooperation services", "code": "93121607" },
      { "name": "Non governmental liaison services", "code": "93121608" },
      { "name": "Non aligned countries cooperation", "code": "93121609" },
      { "name": "Aligned countries cooperation", "code": "93121610" },
      { "name": "Political crimes cooperation services", "code": "93121611" },
      { "name": "Peace treaties cooperation", "code": "93121612" },
      {
        "name": "Treaty signature or accessions or rectification services",
        "code": "93121613"
      },
      {
        "name": "International watercourse cooperation services",
        "code": "93121614"
      },
      {
        "name": "Territorial claims or negotiations third party services",
        "code": "93121615"
      },
      { "name": "International organizations", "code": "93121700" },
      { "name": "System of organizations services", "code": "93121701" },
      { "name": "Security council services", "code": "93121702" },
      { "name": "Economic or social council services", "code": "93121703" },
      { "name": "Secretariat services", "code": "93121704" },
      { "name": "Trustship council services", "code": "93121705" },
      { "name": "General assembly services", "code": "93121706" },
      { "name": "International court of justice services", "code": "93121707" },
      {
        "name": "International political organizations services",
        "code": "93121708"
      },
      {
        "name": "International charity organizations services",
        "code": "93121709"
      },
      {
        "name": "International human relief organizations services",
        "code": "93121710"
      },
      {
        "name": "International health organizations services",
        "code": "93121711"
      },
      { "name": "Humanitarian aid and relief", "code": "93130000" },
      { "name": "Refugee programs", "code": "93131500" },
      { "name": "Protection of human rights services", "code": "93131501" },
      { "name": "Promotion of human rights services", "code": "93131502" },
      {
        "name": "Human rights education or information dissemination services",
        "code": "93131503"
      },
      { "name": "Refugee emergency assistance services", "code": "93131504" },
      { "name": "Refugee camps services", "code": "93131505" },
      {
        "name": "Refugee resettlements or repatriation services",
        "code": "93131506"
      },
      { "name": "Displaced persons assistance services", "code": "93131507" },
      {
        "name": "Food and nutrition policy planning and programs",
        "code": "93131600"
      },
      { "name": "Hunger eradication programs", "code": "93131601" },
      { "name": "Emergency food supply services", "code": "93131602" },
      { "name": "World food program services", "code": "93131603" },
      {
        "name": "Food or agriculture organization services",
        "code": "93131604"
      },
      { "name": "Common fund for commodities services", "code": "93131605" },
      {
        "name": "International fund for agricultural development services",
        "code": "93131606"
      },
      { "name": "Food distribution services", "code": "93131607" },
      { "name": "Food supply services", "code": "93131608" },
      { "name": "Food aid policies or programs", "code": "93131609" },
      { "name": "Food planning services", "code": "93131610" },
      { "name": "Food security services", "code": "93131611" },
      { "name": "Food reserves management", "code": "93131612" },
      {
        "name": "Food shortage or surplus management or control services",
        "code": "93131613"
      },
      { "name": "Health programs", "code": "93131700" },
      { "name": "Anti tobacco campaigns", "code": "93131701" },
      { "name": "Sanitation programs", "code": "93131702" },
      { "name": "Research programs", "code": "93131703" },
      { "name": "Disease prevention or control services", "code": "93131704" },
      {
        "name": "Drug abuse prevention or control programs",
        "code": "93131705"
      },
      { "name": "Disaster preparedness and relief", "code": "93131800" },
      { "name": "Disaster warning services", "code": "93131801" },
      { "name": "Disaster preparedness response services", "code": "93131802" },
      { "name": "Emergency housing services", "code": "93131803" },
      { "name": "Community and social services", "code": "93140000" },
      { "name": "Social development and services", "code": "93141500" },
      { "name": "Social policy services", "code": "93141501" },
      { "name": "Social security legislation services", "code": "93141502" },
      { "name": "Social planning services", "code": "93141503" },
      { "name": "Foster home care services or orphanage", "code": "93141504" },
      { "name": "Adoption services", "code": "93141505" },
      { "name": "Social welfare services", "code": "93141506" },
      { "name": "Social work administration services", "code": "93141507" },
      { "name": "Voluntary service management", "code": "93141508" },
      {
        "name": "Social problems analysis or management services",
        "code": "93141509"
      },
      {
        "name": "Social structure studies or related services",
        "code": "93141510"
      },
      {
        "name": "Social groups studies or related services",
        "code": "93141511"
      },
      {
        "name": "Youth movements or organizations services",
        "code": "93141512"
      },
      { "name": "Social justice or legislation services", "code": "93141513" },
      { "name": "Socio cultural services", "code": "93141514" },
      { "name": "Immigrant settlement support service", "code": "93141515" },
      { "name": "Population", "code": "93141600" },
      { "name": "Population census services", "code": "93141601" },
      { "name": "Population sample surveys services", "code": "93141602" },
      { "name": "Birth reporting or control services", "code": "93141603" },
      { "name": "Population control services", "code": "93141604" },
      {
        "name": "Population trends or projections services",
        "code": "93141605"
      },
      { "name": "Birth statistics services", "code": "93141606" },
      {
        "name": "Marriage research or statistics services",
        "code": "93141607"
      },
      {
        "name": "Population distribution or analysis services",
        "code": "93141608"
      },
      {
        "name": "Population composition analysis services",
        "code": "93141609"
      },
      { "name": "Demographic studies", "code": "93141610" },
      { "name": "Immigration analysis or services", "code": "93141611" },
      { "name": "Family planning programs or services", "code": "93141612" },
      { "name": "Human reproduction analysis", "code": "93141613" },
      { "name": "Culture", "code": "93141700" },
      { "name": "Cultural events organizations", "code": "93141701" },
      { "name": "Culture promotional services", "code": "93141702" },
      { "name": "Art related services", "code": "93141703" },
      { "name": "Song writing services", "code": "93141704" },
      { "name": "Literary writing services", "code": "93141705" },
      { "name": "Minorities protection services", "code": "93141706" },
      {
        "name": "Cultural heritage preservation or promotion services",
        "code": "93141707"
      },
      { "name": "Museum services", "code": "93141708" },
      { "name": "Cultural policy services", "code": "93141709" },
      { "name": "Archaic or indigenous language services", "code": "93141710" },
      {
        "name": "Traditional handcrafts promotion services",
        "code": "93141711"
      },
      {
        "name": "Protection of intellectual or cultural property services",
        "code": "93141712"
      },
      {
        "name": "Historic sites or monuments protection services",
        "code": "93141713"
      },
      { "name": "Mythology", "code": "93141714" },
      { "name": "Anthropological research service", "code": "93141715" },
      { "name": "Paleontologic study service", "code": "93141716" },
      { "name": "Employment", "code": "93141800" },
      {
        "name": "Employment promotion or planning services",
        "code": "93141801"
      },
      { "name": "Recruitment services", "code": "93141802" },
      { "name": "International labor standards services", "code": "93141803" },
      {
        "name": "International labor registration services",
        "code": "93141804"
      },
      { "name": "Unemployment services", "code": "93141805" },
      {
        "name": "Employment statistics or forecasting services",
        "code": "93141806"
      },
      { "name": "Work time arrangements", "code": "93141807" },
      { "name": "Occupational health or safety services", "code": "93141808" },
      { "name": "Career development services", "code": "93141810" },
      { "name": "Promotional services", "code": "93141811" },
      { "name": "Labor inspection services", "code": "93141812" },
      { "name": "Work council services", "code": "93141813" },
      { "name": "International labor services", "code": "93141814" },
      { "name": "Rural development", "code": "93141900" },
      {
        "name": "Agricultural commercial banking services",
        "code": "93141901"
      },
      { "name": "Rural investment services", "code": "93141902" },
      {
        "name": "Agricultural institutions organization or management services",
        "code": "93141903"
      },
      {
        "name": "Agricultural or rural cooperatives services",
        "code": "93141904"
      },
      { "name": "Agricultural research services", "code": "93141905" },
      {
        "name": "Farmers or peasants organizations services",
        "code": "93141906"
      },
      {
        "name": "Womens services in agricultural production or rural development",
        "code": "93141907"
      },
      {
        "name": "Agrarian reform or land settlement services",
        "code": "93141908"
      },
      { "name": "Land administration services", "code": "93141909" },
      { "name": "Island development services", "code": "93141910" },
      { "name": "Urban development", "code": "93142000" },
      { "name": "Urban development planning services", "code": "93142001" },
      { "name": "Urban land administration services", "code": "93142002" },
      { "name": "Urban investment programming services", "code": "93142003" },
      { "name": "Slum redevelopment services", "code": "93142004" },
      { "name": "Urban lighting services", "code": "93142005" },
      {
        "name": "Urban development control or regulations services",
        "code": "93142006"
      },
      {
        "name": "Urban building standards or regulations services",
        "code": "93142007"
      },
      { "name": "Urban community services", "code": "93142008" },
      {
        "name": "Urban project or program administration or management services",
        "code": "93142009"
      },
      { "name": "Regional development", "code": "93142100" },
      { "name": "Regional development planning services", "code": "93142101" },
      { "name": "Economic cooperation services", "code": "93142102" },
      { "name": "Technical cooperation services", "code": "93142103" },
      { "name": "Sectoral planning services", "code": "93142104" },
      {
        "name": "Public administration and finance services",
        "code": "93150000"
      },
      { "name": "Public administration", "code": "93151500" },
      {
        "name": "Public enterprises management or financial services",
        "code": "93151501"
      },
      {
        "name": "Public enterprises information or control systems services",
        "code": "93151502"
      },
      { "name": "Privatization programs", "code": "93151503" },
      { "name": "Administrative reform services", "code": "93151504" },
      { "name": "Administrative agencies services", "code": "93151505" },
      {
        "name": "Administrative economic council services",
        "code": "93151506"
      },
      { "name": "Administrative procedures or services", "code": "93151507" },
      { "name": "Government departments services", "code": "93151508" },
      { "name": "Government information services", "code": "93151509" },
      {
        "name": "Administrative fees or tax collection services",
        "code": "93151510"
      },
      {
        "name": "Administrative acts ratification or implementation services",
        "code": "93151511"
      },
      { "name": "Public institutions services", "code": "93151512" },
      {
        "name": "Multinational public corporations services",
        "code": "93151513"
      },
      { "name": "Ombudsman services", "code": "93151514" },
      { "name": "National planning services", "code": "93151515" },
      { "name": "Building permit", "code": "93151516" },
      { "name": "License or registration fee", "code": "93151517" },
      { "name": "Property title fee", "code": "93151518" },
      { "name": "Censorship service", "code": "93151519" },
      { "name": "Public finance", "code": "93151600" },
      { "name": "Program budgeting services", "code": "93151601" },
      { "name": "Government budgeting services", "code": "93151602" },
      { "name": "Budget or public investment management", "code": "93151603" },
      {
        "name": "Military expenditures budgeting services",
        "code": "93151604"
      },
      { "name": "Government finance services", "code": "93151605" },
      { "name": "Government accounting services", "code": "93151606" },
      { "name": "Government auditing services", "code": "93151607" },
      { "name": "Government or central bank services", "code": "93151608" },
      { "name": "Lotteries services", "code": "93151609" },
      { "name": "Tax collection services", "code": "93151610" },
      { "name": "Subsidies", "code": "93151611" },
      { "name": "Currency", "code": "93151700" },
      { "name": "Currencies or coinage", "code": "93151701" },
      { "name": "National bank notes", "code": "93151702" },
      { "name": "Taxation", "code": "93160000" },
      { "name": "Income tax", "code": "93161500" },
      { "name": "National income tax", "code": "93161501" },
      { "name": "Municipal income tax", "code": "93161502" },
      { "name": "Capital gains tax", "code": "93161503" },
      { "name": "Excess profits tax", "code": "93161504" },
      { "name": "Taxes other than income tax", "code": "93161600" },
      { "name": "Property tax", "code": "93161601" },
      { "name": "Land tax", "code": "93161602" },
      { "name": "Value added tax VAT", "code": "93161603" },
      { "name": "Payroll tax", "code": "93161604" },
      { "name": "Sales tax", "code": "93161605" },
      { "name": "Social security tax", "code": "93161606" },
      { "name": "Inheritance or transfer tax", "code": "93161607" },
      { "name": "Custom tax or duty", "code": "93161608" },
      { "name": "Tariff or duty", "code": "93161609" },
      { "name": "Tax administration", "code": "93161700" },
      { "name": "Tax collation", "code": "93161701" },
      { "name": "Tax incentives", "code": "93161702" },
      { "name": "Tax systems", "code": "93161703" },
      { "name": "Tax revenue administration", "code": "93161704" },
      { "name": "Taxation issues", "code": "93161800" },
      { "name": "Tax reform", "code": "93161801" },
      { "name": "Taxation policy", "code": "93161802" },
      { "name": "Tax research", "code": "93161803" },
      { "name": "Investment tax credit", "code": "93161804" },
      { "name": "Tax deductions", "code": "93161805" },
      { "name": "Tax evasion", "code": "93161806" },
      { "name": "Tax shelters or havens", "code": "93161807" },
      { "name": "Tax returns", "code": "93161808" },
      { "name": "Trade policy and regulation", "code": "93170000" },
      { "name": "Trade policy", "code": "93171500" },
      { "name": "Trade agreements", "code": "93171501" },
      { "name": "Trade negotiations", "code": "93171502" },
      {
        "name": "Formulation of national commodity policies",
        "code": "93171503"
      },
      { "name": "Development of small scale industries", "code": "93171504" },
      { "name": "International trade", "code": "93171600" },
      { "name": "International commodity agreements", "code": "93171601" },
      { "name": "Export policy", "code": "93171602" },
      { "name": "Global trade policies or procedures", "code": "93171603" },
      { "name": "Bilateral trade agreements", "code": "93171604" },
      { "name": "Customs administration and compliance", "code": "93171700" },
      { "name": "Customs conventions", "code": "93171701" },
      { "name": "Customs formalities", "code": "93171702" },
      { "name": "Customs offences", "code": "93171703" },
      { "name": "Trade analysis", "code": "93171800" },
      { "name": "Trade projections", "code": "93171801" },
      { "name": "Balance of trade projections", "code": "93171802" },
      { "name": "Trade statistics", "code": "93171803" }
    ]
  },
  {
    "name": "Organizations and Clubs",
    "code": "94000000",
    "subcategories": [
      { "name": "Work related organizations", "code": "94100000" },
      { "name": "Business associations", "code": "94101500" },
      { "name": "Agricultural industry associations", "code": "94101501" },
      { "name": "Regulatory associations", "code": "94101502" },
      { "name": "Sectoral business associations", "code": "94101503" },
      { "name": "International business associations", "code": "94101504" },
      { "name": "Employers associations", "code": "94101505" },
      { "name": "Professional associations", "code": "94101600" },
      { "name": "Dental associations", "code": "94101601" },
      { "name": "Medical health associations", "code": "94101602" },
      { "name": "Nursing associations", "code": "94101603" },
      { "name": "Accounting associations", "code": "94101604" },
      { "name": "Architect associations", "code": "94101605" },
      { "name": "Bar associations", "code": "94101606" },
      { "name": "Educational or teacher associations", "code": "94101607" },
      { "name": "Engineering associations", "code": "94101608" },
      { "name": "Scientific associations", "code": "94101609" },
      { "name": "Professional standards review boards", "code": "94101610" },
      { "name": "Staff associations", "code": "94101700" },
      { "name": "Staff hobby clubs", "code": "94101701" },
      { "name": "Staff sports associations", "code": "94101702" },
      { "name": "Women staff associations", "code": "94101703" },
      { "name": "Pensionnist staff associations", "code": "94101704" },
      { "name": "Management staff associations", "code": "94101705" },
      { "name": "Trade unions", "code": "94101800" },
      { "name": "Labor or general workers trade unions", "code": "94101801" },
      { "name": "Trade union activists services", "code": "94101802" },
      { "name": "Trade union information services", "code": "94101803" },
      { "name": "Transport Unions", "code": "94101804" },
      { "name": "Teachers unions", "code": "94101805" },
      { "name": "Medical personnel unions", "code": "94101806" },
      { "name": "Employers unions", "code": "94101807" },
      { "name": "Civil servants unions", "code": "94101808" },
      { "name": "Personal assistance service unions", "code": "94101809" },
      { "name": "Aviation unions", "code": "94101810" },
      { "name": "Religious organizations", "code": "94110000" },
      { "name": "Religious residences", "code": "94111700" },
      { "name": "Private religious home residences", "code": "94111701" },
      { "name": "Community religious home residences", "code": "94111702" },
      { "name": "Religious retreat residences", "code": "94111703" },
      { "name": "Temporary religious home residences", "code": "94111704" },
      { "name": "Pilgrimage organizations and services", "code": "94111800" },
      {
        "name": "Mecca pilgrimage organizations or services",
        "code": "94111801"
      },
      {
        "name": "Vatican pilgrimage organizations or services",
        "code": "94111802"
      },
      { "name": "Pilgrimage travel assistance services", "code": "94111803" },
      { "name": "Pilgrimage tour operators services", "code": "94111804" },
      { "name": "Missionary services", "code": "94111900" },
      { "name": "Religious orders services", "code": "94111901" },
      { "name": "Evangelical missionary services", "code": "94111902" },
      { "name": "Educational missionary services", "code": "94111903" },
      { "name": "Denominational services", "code": "94112000" },
      { "name": "Hinduism services", "code": "94112001" },
      { "name": "Buddhism services", "code": "94112002" },
      { "name": "Christian services", "code": "94112003" },
      { "name": "Islam services", "code": "94112004" },
      { "name": "Judaism services", "code": "94112005" },
      { "name": "Clubs", "code": "94120000" },
      { "name": "Sport clubs", "code": "94121500" },
      { "name": "Ice sports clubs", "code": "94121501" },
      { "name": "Boating or swimming sport clubs", "code": "94121502" },
      { "name": "Gun or hunting sport clubs", "code": "94121503" },
      { "name": "Outdoor field sport clubs", "code": "94121504" },
      { "name": "Indoor or outdoor court sport clubs", "code": "94121505" },
      { "name": "Winter sport clubs", "code": "94121506" },
      { "name": "Beach or water sport clubs", "code": "94121507" },
      { "name": "Cycling sport clubs", "code": "94121508" },
      { "name": "Mountaineering sport clubs", "code": "94121509" },
      { "name": "Racing sport clubs", "code": "94121510" },
      { "name": "Flying sport clubs", "code": "94121511" },
      {
        "name": "Professional or semiprofessional sports clubs",
        "code": "94121512"
      },
      { "name": "Stadium event operator services", "code": "94121513" },
      {
        "name": "Sport club managers or promoters services",
        "code": "94121514"
      },
      { "name": "Hobby clubs", "code": "94121600" },
      { "name": "Playing card hobby clubs", "code": "94121601" },
      { "name": "Handcrafts clubs", "code": "94121602" },
      { "name": "Poetry or literature hobby clubs", "code": "94121603" },
      { "name": "Cooking hobby clubs", "code": "94121604" },
      { "name": "Gardening hobby clubs", "code": "94121605" },
      { "name": "Collector hobby club", "code": "94121606" },
      { "name": "Scouting club", "code": "94121607" },
      { "name": "Amateurs clubs", "code": "94121700" },
      { "name": "Amateur drama clubs or services", "code": "94121701" },
      { "name": "Amateur music clubs or services", "code": "94121702" },
      { "name": "Amateur dance clubs or services", "code": "94121703" },
      { "name": "Amateur fine arts clubs or services", "code": "94121704" },
      { "name": "Social clubs", "code": "94121800" },
      { "name": "Youth clubs", "code": "94121801" },
      { "name": "Senior citizens clubs", "code": "94121802" },
      { "name": "Social gathering clubs", "code": "94121803" },
      {
        "name": "Social clubs for people with disabilities",
        "code": "94121804"
      },
      { "name": "War veterans social clubs", "code": "94121805" },
      {
        "name": "Civic organizations and associations and movements",
        "code": "94130000"
      },
      { "name": "Non governmental organizations", "code": "94131500" },
      { "name": "Environmental non governmental services", "code": "94131501" },
      {
        "name": "Emergency relief non governmental services",
        "code": "94131502"
      },
      {
        "name": "Technical assistance non governmental services",
        "code": "94131503"
      },
      {
        "name": "Development aid non governmental services",
        "code": "94131504"
      },
      { "name": "Charity organizations", "code": "94131600" },
      { "name": "Charity organizations shelter services", "code": "94131601" },
      { "name": "Food relief services", "code": "94131602" },
      { "name": "Legal assistance services", "code": "94131603" },
      { "name": "Resource mobilization services", "code": "94131604" },
      { "name": "International aid assistance services", "code": "94131605" },
      { "name": "Orphanage or adoption services", "code": "94131606" },
      { "name": "Elderly assistance organizations", "code": "94131607" },
      { "name": "Prisoner assistance organizations", "code": "94131608" },
      { "name": "Green associations", "code": "94131700" },
      { "name": "Radical green associations", "code": "94131701" },
      { "name": "Ecofeminists associations", "code": "94131702" },
      { "name": "Ecological political organizations", "code": "94131703" },
      { "name": "Green activists movements or services", "code": "94131704" },
      { "name": "Movements", "code": "94131800" },
      {
        "name": "Gay or lesbian or bisexual or transgender movements",
        "code": "94131801"
      },
      { "name": "Anti racism movements", "code": "94131802" },
      { "name": "Women liberation movements", "code": "94131803" },
      { "name": "Fraternal associations", "code": "94131804" },
      {
        "name": "Ethnic minorities cultural preservation services",
        "code": "94131805"
      },
      { "name": "Animal protection associations", "code": "94131900" },
      { "name": "Animal liberation movements", "code": "94131901" },
      {
        "name": "Endangered species protection associations",
        "code": "94131902"
      },
      {
        "name": "Threatened animals protection associations",
        "code": "94131903"
      },
      {
        "name": "Human rights advocacy and defense associations",
        "code": "94132000"
      },
      { "name": "Children rights defense services", "code": "94132001" },
      { "name": "Prisoners defense services", "code": "94132002" },
      {
        "name": "Physical or mental torture defense associations",
        "code": "94132003"
      },
      { "name": "Freedom of speech defense associations", "code": "94132004" }
    ]
  },
  {
    "name": "Land and Buildings and Structures and Thoroughfares",
    "code": "95000000",
    "subcategories": [
      { "name": "Land parcels", "code": "95100000" },
      { "name": "Residential land parcel", "code": "95101500" },
      { "name": "Single family home parcel", "code": "95101501" },
      { "name": "Apartment building parcel", "code": "95101502" },
      { "name": "Cooperative apartment parcel", "code": "95101503" },
      { "name": "Condominium parcel", "code": "95101504" },
      { "name": "Dormitory parcel", "code": "95101505" },
      { "name": "Housing subdivision parcel", "code": "95101506" },
      { "name": "Hotel parcel", "code": "95101507" },
      { "name": "Motel parcel", "code": "95101508" },
      { "name": "Mobile home parcel", "code": "95101509" },
      { "name": "Mobile home park", "code": "95101510" },
      { "name": "Commercial land parcels", "code": "95101600" },
      { "name": "Business park", "code": "95101601" },
      { "name": "Office campus", "code": "95101602" },
      { "name": "Strip mall site", "code": "95101603" },
      { "name": "Industrial land parcels", "code": "95101700" },
      { "name": "Airpark", "code": "95101701" },
      { "name": "Assembly plant site", "code": "95101702" },
      { "name": "Factory site", "code": "95101703" },
      { "name": "Manufacturing plant site", "code": "95101704" },
      { "name": "Marina site", "code": "95101705" },
      { "name": "Packing plant site", "code": "95101706" },
      { "name": "Telecommunication site", "code": "95101707" },
      { "name": "Utility site", "code": "95101708" },
      { "name": "Governmental land parcels", "code": "95101800" },
      { "name": "Army base", "code": "95101801" },
      { "name": "Naval base", "code": "95101802" },
      { "name": "Air force base", "code": "95101803" },
      { "name": "Marine base", "code": "95101804" },
      { "name": "National or state park", "code": "95101805" },
      { "name": "National guard base", "code": "95101806" },
      { "name": "Maintenance yard", "code": "95101807" },
      { "name": "Agricultural land", "code": "95101900" },
      { "name": "Farm", "code": "95101901" },
      { "name": "Ranch", "code": "95101902" },
      { "name": "Orchard", "code": "95101903" },
      { "name": "Thoroughfares", "code": "95110000" },
      { "name": "Limited traffic thoroughfares", "code": "95111500" },
      { "name": "Easement", "code": "95111501" },
      { "name": "Right of way", "code": "95111502" },
      { "name": "Access roads", "code": "95111503" },
      { "name": "Highway ramp or slip road", "code": "95111504" },
      { "name": "Open traffic thoroughfares", "code": "95111600" },
      { "name": "Street or avenue or road or boulevard", "code": "95111601" },
      {
        "name": "Intrastate highway or freeway or turnpike",
        "code": "95111602"
      },
      {
        "name": "Interstate highway or freeway or turnpike",
        "code": "95111603"
      },
      { "name": "Railway line", "code": "95111604" },
      { "name": "Subway line", "code": "95111605" },
      { "name": "Light rail line", "code": "95111606" },
      { "name": "Street car or trolley line", "code": "95111607" },
      { "name": "Bike path", "code": "95111608" },
      { "name": "Crossroad", "code": "95111609" },
      { "name": "Ring road", "code": "95111610" },
      { "name": "Trunk road", "code": "95111611" },
      { "name": "Secondary road", "code": "95111612" },
      { "name": "Dual carriageway", "code": "95111613" },
      { "name": "Single carriageway", "code": "95111614" },
      { "name": "Road junction", "code": "95111615" },
      { "name": "Pedestrian way", "code": "95111616" },
      { "name": "Footpath", "code": "95111617" },
      { "name": "Permanent buildings and structures", "code": "95120000" },
      {
        "name": "Commercial and entertainment buildings and structures",
        "code": "95121500"
      },
      { "name": "Shopping mall", "code": "95121501" },
      { "name": "Parking structure", "code": "95121502" },
      { "name": "Cafeteria", "code": "95121503" },
      { "name": "Shop building", "code": "95121504" },
      { "name": "Shopping center", "code": "95121505" },
      { "name": "Theater", "code": "95121506" },
      { "name": "Market", "code": "95121507" },
      { "name": "Childrens play area or playground", "code": "95121508" },
      { "name": "Zoo", "code": "95121509" },
      { "name": "Garden", "code": "95121510" },
      { "name": "Park", "code": "95121511" },
      { "name": "Waterside leisure facility", "code": "95121512" },
      { "name": "Bank", "code": "95121513" },
      { "name": "Ticket office", "code": "95121514" },
      { "name": "Artificial water fall", "code": "95121515" },
      { "name": "Artificial rock and rockwall", "code": "95121516" },
      { "name": "Crematorium", "code": "95121517" },
      { "name": "Transport buildings and structures", "code": "95121600" },
      { "name": "Steel bridge", "code": "95121601" },
      { "name": "Dry dock", "code": "95121602" },
      { "name": "Bus station", "code": "95121603" },
      { "name": "Bus garage", "code": "95121604" },
      { "name": "Automotive repair or servicing building", "code": "95121605" },
      { "name": "Railway station", "code": "95121606" },
      { "name": "Railway depot", "code": "95121607" },
      { "name": "Underground railway station", "code": "95121608" },
      { "name": "Tramway depot", "code": "95121609" },
      { "name": "Tramway platform", "code": "95121610" },
      { "name": "Airport building", "code": "95121611" },
      { "name": "Airport control tower", "code": "95121612" },
      { "name": "Airfield", "code": "95121613" },
      { "name": "Runway", "code": "95121614" },
      { "name": "Quay or wharf", "code": "95121615" },
      { "name": "Pier", "code": "95121616" },
      { "name": "Dock", "code": "95121617" },
      { "name": "Marina", "code": "95121618" },
      { "name": "Yacht harbor", "code": "95121619" },
      { "name": "Breakwater", "code": "95121620" },
      { "name": "Seawall", "code": "95121621" },
      { "name": "Ferry terminal building", "code": "95121622" },
      { "name": "Ro ro terminal", "code": "95121623" },
      { "name": "Lighthouse", "code": "95121624" },
      { "name": "Road bridge", "code": "95121625" },
      { "name": "Railway bridge", "code": "95121626" },
      { "name": "Footbridge", "code": "95121627" },
      { "name": "Road viaduct", "code": "95121628" },
      { "name": "Railway viaduct", "code": "95121629" },
      { "name": "Underpass", "code": "95121630" },
      { "name": "Overpass", "code": "95121631" },
      { "name": "Subway", "code": "95121632" },
      { "name": "Culvert", "code": "95121633" },
      { "name": "Road tunnel", "code": "95121634" },
      { "name": "Railway tunnel", "code": "95121635" },
      { "name": "Pedestrian tunnel", "code": "95121636" },
      { "name": "Canal tunnel", "code": "95121637" },
      { "name": "Pedestrian overpass", "code": "95121638" },
      { "name": "Pipeline carrying bridge", "code": "95121639" },
      { "name": "Pipeline", "code": "95121640" },
      { "name": "Pumping station", "code": "95121641" },
      { "name": "Sewage pumping station", "code": "95121642" },
      { "name": "Sewage outfall", "code": "95121643" },
      { "name": "Parking lot", "code": "95121644" },
      { "name": "Motorway service area", "code": "95121645" },
      { "name": "Bicycle parking rack", "code": "95121646" },
      { "name": "Elevated roadway or highway", "code": "95121647" },
      { "name": "Public buildings and structures", "code": "95121700" },
      { "name": "Post office", "code": "95121701" },
      { "name": "Police station", "code": "95121702" },
      { "name": "Court building", "code": "95121703" },
      { "name": "Prison building", "code": "95121704" },
      { "name": "Fire station", "code": "95121705" },
      { "name": "Ambulance station", "code": "95121706" },
      { "name": "Mountain rescue building", "code": "95121707" },
      { "name": "Lifeboat station", "code": "95121708" },
      { "name": "Coastguard building", "code": "95121709" },
      { "name": "Rescue service station", "code": "95121710" },
      { "name": "Civic center", "code": "95121711" },
      { "name": "Art gallery", "code": "95121712" },
      { "name": "Prehistoric monument", "code": "95121713" },
      {
        "name": "Public changing room and shower facility",
        "code": "95121714"
      },
      { "name": "Public toilet facility", "code": "95121715" },
      { "name": "Utility buildings and structures", "code": "95121800" },
      { "name": "Radar station", "code": "95121801" },
      { "name": "Substation", "code": "95121802" },
      { "name": "Nuclear power station", "code": "95121803" },
      { "name": "Water tower", "code": "95121804" },
      { "name": "Well", "code": "95121805" },
      { "name": "Mobile telephone base station", "code": "95121806" },
      { "name": "Dam", "code": "95121807" },
      { "name": "Oil or gas platform", "code": "95121808" },
      { "name": "Movable weir", "code": "95121809" },
      { "name": "Irrigation and flood control waterworks", "code": "95121810" },
      {
        "name": "Aquaduct or other water supply conduit, except pipeline",
        "code": "95121811"
      },
      { "name": "Floating structure", "code": "95121812" },
      {
        "name": "Educational and research buildings and structures",
        "code": "95121900"
      },
      { "name": "Polytechnic school", "code": "95121901" },
      { "name": "Vocational college", "code": "95121902" },
      { "name": "Lecture theater", "code": "95121903" },
      { "name": "Library", "code": "95121904" },
      { "name": "Language laboratory", "code": "95121905" },
      { "name": "Laboratory building", "code": "95121906" },
      { "name": "Meteorological station", "code": "95121907" },
      { "name": "Research or testing facility", "code": "95121908" },
      { "name": "Elementary school", "code": "95121909" },
      { "name": "Junior high or middle school", "code": "95121910" },
      { "name": "High school", "code": "95121911" },
      { "name": "University", "code": "95121913" },
      { "name": "Hospital buildings and structures", "code": "95122000" },
      { "name": "Clinic", "code": "95122001" },
      { "name": "Nursing home", "code": "95122002" },
      { "name": "Operating room or theater", "code": "95122003" },
      { "name": "Intensive care unit", "code": "95122004" },
      { "name": "Diagnostic screening room", "code": "95122005" },
      { "name": "Fluoroscopy room", "code": "95122006" },
      { "name": "Pathology room or laboratory", "code": "95122007" },
      { "name": "Catheter room", "code": "95122008" },
      { "name": "Accommodation buildings and structures", "code": "95122100" },
      { "name": "Residential home", "code": "95122101" },
      { "name": "Flat or apartment", "code": "95122102" },
      { "name": "Childrens home", "code": "95122103" },
      { "name": "Daycare center", "code": "95122104" },
      { "name": "Retirement home", "code": "95122105" },
      { "name": "Hostel", "code": "95122106" },
      {
        "name": "Sports and health buildings and structures",
        "code": "95122300"
      },
      { "name": "Stadium", "code": "95122301" },
      { "name": "Sports ground", "code": "95122302" },
      { "name": "Running track", "code": "95122303" },
      { "name": "Sports hall", "code": "95122304" },
      { "name": "Spa", "code": "95122305" },
      { "name": "Gymnasium", "code": "95122306" },
      { "name": "Swimming pool", "code": "95122307" },
      { "name": "Water sports facility", "code": "95122308" },
      { "name": "Industrial buildings and structures", "code": "95122400" },
      { "name": "Workshop", "code": "95122401" },
      { "name": "Cold storage installation", "code": "95122402" },
      { "name": "Warehouse store", "code": "95122403" },
      {
        "name": "Agricultural and farming and fishing buildings and structures",
        "code": "95122500"
      },
      { "name": "Barn", "code": "95122501" },
      { "name": "Cowshed", "code": "95122502" },
      { "name": "Irrigation channel", "code": "95122503" },
      { "name": "Artificial reef", "code": "95122504" },
      { "name": "Marine transfer station", "code": "95122505" },
      { "name": "Religious buildings and structures", "code": "95122600" },
      { "name": "Church", "code": "95122601" },
      { "name": "Hindu temple", "code": "95122602" },
      { "name": "Mosque", "code": "95122603" },
      { "name": "Synagogue", "code": "95122604" },
      { "name": "Sikh temple", "code": "95122605" },
      { "name": "Defense buildings and structures", "code": "95122700" },
      { "name": "Military bunker", "code": "95122701" },
      { "name": "Barracks", "code": "95122702" },
      { "name": "Military mess or mess hall", "code": "95122703" },
      { "name": "Portable buildings and structures", "code": "95130000" },
      { "name": "Grandstands and bleachers", "code": "95131500" },
      { "name": "Foldable grandstand", "code": "95131501" },
      { "name": "Mobile grandstand", "code": "95131502" },
      { "name": "Portable grandstand", "code": "95131503" },
      {
        "name": "Portable commercial and industrial buildings and structures",
        "code": "95131600"
      },
      { "name": "Portable box office", "code": "95131601" },
      { "name": "Portable toilet", "code": "95131602" },
      { "name": "Site office", "code": "95131603" },
      { "name": "Portable kitchen unit", "code": "95131604" },
      { "name": "Shipping container house", "code": "95131605" },
      { "name": "Portable sales booth", "code": "95131606" },
      { "name": "Tents and membrane structures", "code": "95131700" },
      { "name": "Framed textile structure", "code": "95131701" },
      { "name": "Pole tent or tension tent", "code": "95131702" },
      { "name": "Prefabricated buildings and structures", "code": "95140000" },
      {
        "name": "Prefabricated farm buildings and structures",
        "code": "95141500"
      },
      { "name": "Silo", "code": "95141501" },
      { "name": "Greenhouse", "code": "95141502" },
      {
        "name": "Prefabricated residential buildings and structures",
        "code": "95141600"
      },
      { "name": "House", "code": "95141601" },
      { "name": "Mobile home", "code": "95141602" },
      { "name": "Cabin", "code": "95141603" },
      { "name": "Garage", "code": "95141604" },
      { "name": "Gazebo", "code": "95141605" },
      { "name": "Home kitchen", "code": "95141606" },
      {
        "name": "Prefabricated commercial and industrial buildings and structures",
        "code": "95141700"
      },
      { "name": "In plant office", "code": "95141701" },
      { "name": "Spray booth", "code": "95141702" },
      { "name": "Storage shed", "code": "95141703" },
      { "name": "Clean room", "code": "95141704" },
      { "name": "Guardhouse", "code": "95141705" },
      { "name": "Warehouse", "code": "95141706" },
      { "name": "Auditorium", "code": "95141707" },
      { "name": "Office kitchen", "code": "95141708" },
      { "name": "Conservatory", "code": "95141709" },
      { "name": "Phone box or phone booth", "code": "95141710" },
      { "name": "Tollbooth", "code": "95141711" },
      {
        "name": "Prefabricated emergency relief buildings and structures",
        "code": "95141800"
      },
      { "name": "Shelter", "code": "95141801" },
      { "name": "Emergency tent or hall", "code": "95141802" },
      { "name": "Container unit", "code": "95141803" },
      {
        "name": "Prefabricated medical buildings and structures",
        "code": "95141900"
      },
      { "name": "Medical unit", "code": "95141901" },
      { "name": "Laboratory unit", "code": "95141902" },
      { "name": "Dental unit", "code": "95141903" },
      { "name": "Surgical units", "code": "95141904" }
    ]
  }
]
