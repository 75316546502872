import { useMutate } from '@cotiss/common'
import {
  TenderResponsePopulatedModel,
  TenderResponsePriceScoreConsensusStatus,
  TenderResponseReviewStatus,
  TenderResponseStatus,
} from '@cotiss/tender-response'

type CreateTenderResponseBody = {
  tenderId: string
  supplierOrganisationId?: string
}

type UpdateTenderResponseBody = {
  status?: TenderResponseStatus
  nonPriceAttachments?: string[]
  priceAttachments?: string[]
  nonPriceAttachmentsClarification?: string[]
  priceAttachmentsClarification?: string[]
  reviewStatus?: TenderResponseReviewStatus
  reviewJustification?: string
  priceScore?: number
  priceScoreConsensusStatus?: TenderResponsePriceScoreConsensusStatus
  responseUnlocked?: boolean
}

type ExportTenderResponseBody = {
  tenderId: string
}

type ExportTenderResponseModel = {
  documentId?: string
  isGenerating: boolean
}

export const useMutateTenderResponse = () => {
  const { mutate } = useMutate()

  return {
    createTenderResponse: async (body: CreateTenderResponseBody) => {
      return await mutate<TenderResponsePopulatedModel>({
        route: `/v2/tender-response`,
        body,
        invalidate: ['/v2/procurements', '/v2/procurement-response', '/v2/reports/evaluations-by-tender-response'],
      })
    },
    updateTenderResponse: async (id: string, body: UpdateTenderResponseBody) => {
      return await mutate({
        route: `/v2/tender-response/${id}`,
        body,
        invalidate: ['/v2/tender-response', '/v2/tenders', '/v2/procurement-response', '/v2/reports/evaluations-by-tender-response'],
      })
    },
    deleteTenderResponse: async (id: string) => {
      return await mutate({
        route: `/v2/tender-response/${id}`,
        method: 'DELETE',
        invalidate: ['/v2/tender-response', '/v2/tenders', '/v2/procurement-response', '/v2/reports/evaluations-by-tender-response'],
      })
    },
    exportTenderResponse: async (body: ExportTenderResponseBody) => {
      return await mutate<ExportTenderResponseModel>({ route: `/v2/tender-response/export`, body, invalidate: ['/v2/tenders'] })
    },
  }
}
