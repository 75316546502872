import React, { FormEvent, memo, useEffect, useState } from 'react'
import { useGetLoggedInUser, useMutateUser } from '@cotiss/user'
import { Button, Form, Label, Select_DEPRECATED, Text, sentryService, useToast } from '@cotiss/common'
import { PREFERENCES_EMAIL_NOTIFICATION_PERIOD_TYPE_OPTIONS, PreferencesEmailNotificationPeriod } from '@cotiss/preferences'

type FormData = {
  notificationPeriod?: PreferencesEmailNotificationPeriod
}

export const SettingsPreferencesFormNotificationPeriod = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { updateUserMe } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState<FormData>({ notificationPeriod: user?.notificationPeriod })

  useEffect(() => {
    if (user) {
      setFormData({ notificationPeriod: user.notificationPeriod })
    }
  }, [user])

  const handleReset = () => {
    setFormData({ notificationPeriod: user?.notificationPeriod })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateUserMe(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>How often would you like to get notified of listings that match your profile?</Label>
        <Select_DEPRECATED<PreferencesEmailNotificationPeriod>
          className="mb-4"
          value={formData.notificationPeriod}
          options={PREFERENCES_EMAIL_NOTIFICATION_PERIOD_TYPE_OPTIONS}
          onChange={(notificationPeriod) => setFormData({ ...formData, notificationPeriod })}
          isDisabled={isSaving}
          placeholder
          isRequired
        />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">How often would you like to get notified of listings that match your profile?</Text>
        <Text>{user?.notificationPeriod || '–'}</Text>
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
        Update
      </Button>
    </div>
  )
})
