import { map } from 'lodash'
import React, { memo, useState } from 'react'
import { DocumentLink } from '@cotiss/document'
import { OrganisationCertificationModel, OrganisationModel } from '@cotiss/organisation'
import { Button, Dropdown, DropdownContent, ExternalLink, Icon, Text, useCallout } from '@cotiss/common'
import { SupplierAddUpdateCertificationModal, SupplierDeleteCertificationConfirmModal } from '@cotiss/supplier'

type Props = {
  className?: string
  organisation: OrganisationModel
  certification: OrganisationCertificationModel
  isEditable?: boolean
}

export const SupplierViewCertificationItem = memo(({ className, organisation, certification, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className={className}>
      <div className="relative flex items-center justify-between">
        <Text className="font-medium mr-2">{certification.title}</Text>
        {isEditable && (
          <>
            <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
              <Icon icon="dots" variant="light" size={20} />
            </Button>
            <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<SupplierAddUpdateCertificationModal organisation={organisation} certification={certification} />)}
              >
                Edit
              </DropdownContent>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<SupplierDeleteCertificationConfirmModal organisation={organisation} certification={certification} />)}
              >
                Delete
              </DropdownContent>
            </Dropdown>
          </>
        )}
      </div>
      <Text variant="light">{certification.description}</Text>
      {certification.link && (
        <ExternalLink className="mt-1" href={certification.link} size="sm">
          {certification.link}
        </ExternalLink>
      )}
      {Boolean(certification.attachments.length) && (
        <>
          <Text className="font-medium mt-2" size="sm">
            Documents
          </Text>
          {map(certification.attachments, (attachment) => (
            <DocumentLink key={attachment._id} className="mt-2" document={attachment} />
          ))}
        </>
      )}
    </div>
  )
})
