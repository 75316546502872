import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { GqlEvaluationUserViewInput, GqlEvaluationUserViewQuery } from '@gql'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationUserView = async (input: GqlEvaluationUserViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      query EvaluationUserView($input: EvaluationUserViewInput!) {
        evaluationUserView(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserView
}
