import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom'
import React, { createElement, ComponentType, memo, useEffect, useState } from 'react'
import { useAuth } from '@cotiss/auth'
import { useGetLoggedInUser, useGetUserRoles } from '@cotiss/user'
import { routerService, sentryService, featureService, useAsyncEffect, localStorageService, analyticsService } from '@cotiss/common'

type Props = RouteProps & {
  component: ComponentType<any>
}

export const AppRoute = memo(({ component, ...rest }: Props) => {
  const location = useLocation()
  const { user } = useGetLoggedInUser()
  const { roles } = useGetUserRoles()
  const { push, replace } = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticating, isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (!isAuthenticated) {
      if (location.pathname !== '/') {
        localStorageService.setItem('auth-redirect-route', `${location.pathname}${location.search}`)
      }

      return push(routerService.getHref('/login'))
    }

    // If we make it this far, then we know that the user is authenticated. We can remove the redirect route from local storage.
    localStorageService.removeItem('auth-redirect-route')
  }, [isAuthenticating])

  useAsyncEffect(async () => {
    if (!user) {
      return
    }

    sentryService.setUser(user)
    await Promise.all([featureService.identify(user), analyticsService.identify(user)])
  }, [user])

  useEffect(() => {
    if (!roles.length) {
      return
    }
    // Only go through redirection logic if the user navigates to the root path. Otherwise the user is navigating to a specific page.
    // TODO: Ensure that the user has permission to view the respective page. This needs to be done on a per-page basis.
    if (location.pathname !== '/') {
      setIsLoading(false)
      return
    }

    replace(routerService.getHref('/settings'))
    setIsLoading(false)
  }, [roles])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading || !user) {
          return null
        }

        return createElement(component, props)
      }}
    />
  )
})
