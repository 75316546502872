import React, { memo } from 'react'
import { Button, Page, Section, Text } from '@cotiss/common'

export const MaintenancePage = memo(() => (
  <Page>
    <Section isCentered>
      <Text className="text-center text-2xl font-semibold">Under Maintenance</Text>

      <Text className="text-center mt-8">Cotiss is currently under maintenance.</Text>

      <Text className="text-center mt-8">
        Come back later to see if we&apos;re back up and running, or email{' '}
        <Button onClick={() => window.open('mailto:support@cotiss.sport', '_blank')} state="text">
          support@cotiss.com
        </Button>{' '}
        for more information.
      </Text>
    </Section>
  </Page>
))
