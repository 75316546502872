import classNames from 'classnames'
import React, { ReactNode, HTMLProps, memo } from 'react'

type Props = HTMLProps<HTMLTableElement> & {
  children: ReactNode
  className?: string
  isSeparate?: boolean
}

export const Table_DEPRECATED = memo(({ className, children, isSeparate, ...rest }: Props) => {
  const classes = classNames(className, 'table-fixed w-full', {
    'border-collapse': !isSeparate,
    'border-separate border-spacing-y-3': isSeparate,
  })

  return (
    <table {...rest} className={classes}>
      {children}
    </table>
  )
})
