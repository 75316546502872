import classNames from 'classnames'
import React, { FormEvent, ReactNode, memo, useEffect, useRef, useState } from 'react'
import { DrawerContent, DrawerFooter, DrawerHeader, Form } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
  header: ReactNode
  footer?: ReactNode
  onSubmit?: () => void | Promise<unknown>
  hasPadding?: boolean
  isNarrow?: boolean
}

export const Drawer = memo(({ className, children, header, footer, onSubmit, hasPadding = true, isNarrow }: Props) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const classes = classNames(className, 'flex flex-col min-h-screen max-h-screen')

  useEffect(() => {
    setPaddingTop(headerRef?.current?.getBoundingClientRect().height || 0)
    setPaddingBottom(footerRef?.current?.getBoundingClientRect().height || 0)
  }, [footerRef])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onSubmit && (await onSubmit())
  }

  const renderContent = () => (
    <>
      <DrawerHeader ref={headerRef} isNarrow={isNarrow}>
        {header}
      </DrawerHeader>
      <DrawerContent hasPadding={hasPadding}>{children}</DrawerContent>
      {footer && (
        <DrawerFooter ref={footerRef} isNarrow={isNarrow}>
          {footer}
        </DrawerFooter>
      )}
    </>
  )

  return onSubmit ? (
    <Form className={classes} onSubmit={handleSubmit} style={{ paddingTop, paddingBottom }}>
      {renderContent()}
    </Form>
  ) : (
    <div className={classes} style={{ paddingTop, paddingBottom }}>
      {renderContent()}
    </div>
  )
})
