import { TenderRatingScale } from '@cotiss/tender'
import { SelectOption_DEPRECATED } from '@cotiss/common'

export const EVALUATION_QUERY_KEYS = ['/v2/evaluations'] as const
export const EVALUATION_ROUTES = [
  '/evaluation',
  '/evaluation/view/:id',
  '/evaluation/chair/:tenderId/:step?',
  '/evaluation/tender/:tenderId',
] as const

export const EVALUATION_RATING_SCALE_OPTIONS_MAP: Record<TenderRatingScale, SelectOption_DEPRECATED[]> = {
  zeroToFive: [
    { value: '', label: '--' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ],
  zeroToTen: [
    { value: '', label: '--' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
  ],
}

export const EVALUATION_CHAIR_STEPS = ['individual-evaluation', 'consensus-scoring', 'price-scoring'] as const
export type EvaluationChairStep = (typeof EVALUATION_CHAIR_STEPS)[number]
