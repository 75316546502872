import { uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateBeginEvaluationEvent,
  mutateCreateEvaluationEvent,
  mutateDuplicateEvaluationEvent,
  mutateUpdateEvaluationEvent,
  queryEvaluationEventResultsByCriteriaCsv,
  queryEvaluationEventResultsByEnvelopeCsv,
  queryEvaluationEventRawResultsCsv,
  queryEvaluationEventResultsBySubCriteriaCsv,
  queryEvaluationEventList,
  queryEvaluationEventView,
} from '@cotiss/evaluation-event'
import {
  GqlBeginEvaluationEventInput,
  GqlCreateEvaluationEventInput,
  GqlDuplicateEvaluationEventInput,
  GqlEvaluationEventFieldsFragment,
  GqlEvaluationEventListInput,
  GqlEvaluationEventViewInput,
  GqlUpdateEvaluationEventInput,
} from '@gql'

const evaluationEventVar = makeVar<GqlEvaluationEventFieldsFragment | null>(null)
const evaluationEventsVar = makeVar<GqlEvaluationEventFieldsFragment[]>([])

export const useEvaluationEvent = () => {
  const evaluationEvent = useReactiveVar(evaluationEventVar)
  const evaluationEvents = useReactiveVar(evaluationEventsVar)

  return {
    evaluationEvent,
    evaluationEvents,
    setEvaluationEvent: evaluationEventVar,
    setEvaluationEvents: evaluationEventsVar,
    queryEvaluationEventList: async (input: GqlEvaluationEventListInput) => {
      const { items: evaluationEvents, pagination } = await queryEvaluationEventList(input)

      evaluationEventsVar(evaluationEvents)

      return { evaluationEvents, pagination }
    },
    queryEvaluationEventView: async (input: GqlEvaluationEventViewInput) => {
      const evaluationEvent = await queryEvaluationEventView(input)

      evaluationEventVar(evaluationEvent)
      evaluationEventsVar(uniqBy([evaluationEvent, ...evaluationEvents], 'id'))

      return evaluationEvent
    },
    queryEvaluationEventResultsByEnvelopeCsv: async (input: GqlEvaluationEventViewInput) => {
      return await queryEvaluationEventResultsByEnvelopeCsv(input)
    },
    queryEvaluationEventResultsByCriteriaCsv: async (input: GqlEvaluationEventViewInput) => {
      return await queryEvaluationEventResultsByCriteriaCsv(input)
    },
    queryEvaluationEventResultsBySubCriteriaCsv: async (input: GqlEvaluationEventViewInput) => {
      return await queryEvaluationEventResultsBySubCriteriaCsv(input)
    },
    queryEvaluationEventRawResultsCsv: async (input: GqlEvaluationEventViewInput) => {
      return await queryEvaluationEventRawResultsCsv(input)
    },
    mutateCreateEvaluationEvent: async (input: GqlCreateEvaluationEventInput) => {
      const createdEvaluationEvent = await mutateCreateEvaluationEvent(input)

      evaluationEventsVar(uniqBy([createdEvaluationEvent, ...evaluationEvents], 'id'))

      return createdEvaluationEvent
    },
    mutateUpdateEvaluationEvent: async (input: GqlUpdateEvaluationEventInput) => {
      const updatedEvaluationEvent = await mutateUpdateEvaluationEvent(input)

      evaluationEventVar(updatedEvaluationEvent)
      evaluationEventsVar(uniqBy([updatedEvaluationEvent, ...evaluationEvents], 'id'))

      return updatedEvaluationEvent
    },
    mutateDuplicateEvaluationEvent: async (input: GqlDuplicateEvaluationEventInput) => {
      const duplicatedEvaluationEvent = await mutateDuplicateEvaluationEvent(input)

      evaluationEventsVar(uniqBy([duplicatedEvaluationEvent, ...evaluationEvents], 'id'))

      return duplicatedEvaluationEvent
    },
    mutateBeginEvaluationEvent: async (input: GqlBeginEvaluationEventInput) => {
      const updatedEvaluationEvent = await mutateBeginEvaluationEvent(input)

      evaluationEventVar(updatedEvaluationEvent)
      evaluationEventsVar(uniqBy([updatedEvaluationEvent, ...evaluationEvents], 'id'))

      return updatedEvaluationEvent
    },
  }
}

export const clearReactiveEvaluationEvent = async () => {
  evaluationEventVar(null)
  evaluationEventsVar([])
}
