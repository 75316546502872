import { filter } from 'lodash'
import classNames from 'classnames'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { contractService, useGetContractShell, useMutateContractShell, UpdateContractPriceDurationBulkBody } from '@cotiss/contract'
import { useCallout, Form, ModalHeader, ModalContent, ModalFooter, Icon, Text, Input, Label, sentryService, useToast, TextArea } from '@cotiss/common'

type FormData = {
  id: string
  length: number
  value: number
  variation: number
  index: number
  description: string
}

type Props = {
  milestoneId?: string
  contractShellId: string
}

const DESCRIPTION_MAX_LENGTH = 100

export const ContractWizardMilestoneModal = memo(({ milestoneId, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { updateContractMilestoneBulk } = useMutateContractShell()

  const { milestone, contract, isVariation } = useMemo(() => {
    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const milestone = milestoneId ? contract?.milestones.find((milestone) => milestone._id === milestoneId) : null
    return { milestone, contract, isVariation: Boolean(contract?.variationTypes.length) }
  }, [])

  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>(
    (() => {
      return {
        id: milestone?._id || crypto.randomUUID(),
        length: milestone?.length || 0,
        value: milestone?.value || 0,
        variation: milestone?.variation || 0,
        index: milestone?.index || 0,
        description: milestone?.description || '',
      }
    })()
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contract) {
      // TODO: something
      return
    }

    const existingMilestones = contract?.milestones.map((milestone) => {
      return {
        ...milestone,
        variation: milestone.variation || 0,
      }
    })

    const parsedMilestones: UpdateContractPriceDurationBulkBody['items'] = []

    // If we're adding a new milestone
    if (!existingMilestones?.find((pd) => pd._id === formData.id)) {
      parsedMilestones.push(...existingMilestones, {
        ...formData,
        index: existingMilestones.length,
        exercised: 0,
      })
    }

    const existingPriceDuration = existingMilestones?.find((rate) => rate._id === formData.id)

    // If we're updating an existing milestone
    if (existingPriceDuration) {
      parsedMilestones.push(...filter(existingMilestones, (pd) => pd._id !== formData.id), {
        ...formData,
        index: existingPriceDuration.index,
        exercised: existingPriceDuration.exercised ?? 0,
      })
    }

    try {
      setIsSaving(true)
      await updateContractMilestoneBulk(contractShellId, contract._id, { items: parsedMilestones })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${milestone ? 'Edit milestone' : 'Add new milestone'}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Length (months)</Label>
        <div className="relative flex items-center">
          <Icon className="absolute ml-4" icon="clock" variant="light" />
          <Input
            className="pl-10"
            value={formData.length || ''}
            placeholder="--"
            maxLength={5}
            onChange={({ target }) =>
              setFormData({
                ...formData,
                length: parseInt(target.value) || 0,
              })
            }
            isDisabled={isSaving}
          />
        </div>

        <div className="mt-6">
          <Label>{isVariation ? 'Initial value' : 'Value'}</Label>
          <div className={classNames('relative flex items-center rounded', { 'bg-primary-100': isVariation })}>
            <Icon className="absolute ml-4" icon="currency-dollar-circle" variant="light" />
            <Input
              value={formData.value ? formData.value.toLocaleString() : ''}
              placeholder="--"
              onChange={({ target }) => setFormData({ ...formData, value: Number(target.value.replaceAll(',', '')) || 0 })}
              isDisabled={isSaving || isVariation}
              className="pl-10"
            />
          </div>
          {isVariation && (
            <Text className=" mt-1 font-medium" size="sm" variant="light">
              Initial value cannot be edited
            </Text>
          )}
          {isVariation && (
            <div className="mt-4">
              <Label>Variation</Label>
              <div className="relative flex items-center">
                <Icon className="absolute ml-4" icon="currency-dollar-circle" variant="light" />
                <Input
                  className="pl-10"
                  value={formData.variation.toLocaleString()}
                  onChange={({ target }) => setFormData({ ...formData, variation: parseInt(target.value.replaceAll(',', '')) || 0 })}
                  isDisabled={isSaving}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-6 mb-2">
          <Label className="flex items-baseline">
            Description <Text size="sm" variant="light" className="ml-1">{`(${formData.description.length}/${DESCRIPTION_MAX_LENGTH})`}</Text>
          </Label>
          <TextArea
            value={formData.description}
            rows={2}
            maxLength={DESCRIPTION_MAX_LENGTH}
            onChange={({ target }) => setFormData({ ...formData, description: target.value })}
            isDisabled={isSaving}
          />
          <Text className="mt-1" size="sm" variant="light">
            Max 100 characters
          </Text>
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
