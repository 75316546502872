import React, { memo } from 'react'
import { SupplierViewDrawer } from '@cotiss/supplier'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { Badge, Td_DEPRECATED, Tr_DEPRECATED, Text, Button, Icon, useCallout } from '@cotiss/common'

type Props = {
  tenderResponseReport: TenderResponseReportModel
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const TenderResponseReportConsensusListItem = memo(({ tenderResponseReport, onView }: Props) => {
  const { openNarrowDrawer } = useCallout()
  const { supplier } = tenderResponseReport.procurementResponse
  const total = tenderResponseReport.consensusUnscoredQuestions + tenderResponseReport.consensusScoredQuestions
  const isComplete = total === tenderResponseReport.consensusScoredQuestions

  return (
    <Tr_DEPRECATED variant="white">
      <Td_DEPRECATED>
        <Button
          onClick={() =>
            openNarrowDrawer(
              <SupplierViewDrawer
                organisationId={supplier._id}
                procurementResponseId={tenderResponseReport.procurementResponse._id}
                tenderResponseId={tenderResponseReport._id}
              />
            )
          }
          state="text"
          variant="link"
          isTruncated
        >
          {supplier.name}
        </Button>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Badge variant={isComplete ? 'success' : 'neutral'} state="translucent">
          {isComplete ? 'Complete' : `${tenderResponseReport.consensusScoredQuestions}/${total}`}
        </Badge>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Text className="text-blue-500" variant="none">
          {tenderResponseReport.avgScore.toFixed(2)}
        </Text>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Text className="font-semibold bg-secondary-300 rounded px-2 py-0.5" variant="secondary" isInline>
          {tenderResponseReport.groupScore.toFixed(2)}
        </Text>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Button onClick={() => onView && onView(tenderResponseReport)} state="translucent" variant="secondary" size="sm" isDisabled={!onView}>
          View
          <Icon icon="arrow-right" size={20} />
        </Button>
      </Td_DEPRECATED>
    </Tr_DEPRECATED>
  )
})
