import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardMetricCycleDocumentListInput, GqlPerformanceScorecardMetricCycleDocumentListQuery } from '@gql'

export const queryPerformanceScorecardMetricCycleDocumentList = async (input: GqlPerformanceScorecardMetricCycleDocumentListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT}

      query PerformanceScorecardMetricCycleDocumentList($input: PerformanceScorecardMetricCycleDocumentListInput!) {
        performanceScorecardMetricCycleDocumentList(input: $input) {
          items {
            ...PerformanceScorecardMetricCycleDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleDocumentList
}
