import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationUserAccessControlsInput,
  GqlUpdateEvaluationUserAccessControlsMutation,
  GqlUpdateEvaluationUserAccessControlsMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationUserAccessControls = async (input: GqlUpdateEvaluationUserAccessControlsInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationUserAccessControlsMutation, GqlUpdateEvaluationUserAccessControlsMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation UpdateEvaluationUserAccessControls($input: UpdateEvaluationUserAccessControlsInput!) {
        updateEvaluationUserAccessControls(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationUserAccessControls as GqlEvaluationUserFieldsFragment
}
