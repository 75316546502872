import { ConfirmModal } from '@cotiss/common'
import React, { memo } from 'react'

type Props = {
  onSubmit: () => void | Promise<unknown>
}

export const ForumRemoveProtectionQuestionConfirmModal = memo(({ onSubmit }: Props) => {
  return (
    <ConfirmModal
      heading="Remove protection"
      description="Removing the protection on this question will make it publicly accessible to anyone with access to this listing. Are you sure this question does not contain any sensitive information?"
      onSubmit={onSubmit}
    />
  )
})
