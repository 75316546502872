import { map } from 'lodash'
import classNames from 'classnames'
import React, { memo } from 'react'
import { Text } from '@cotiss/common'
import { useListTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria'

type Props = {
  className?: string
  tenderId: string
}

export const TenderMandatoryCriteriaTable = memo(({ className, tenderId }: Props) => {
  const classes = classNames(className, 'table-auto w-full text-left')
  const { tenderMandatoryCriteria } = useListTenderMandatoryCriteria({ tenderId })

  return (
    <table className={classes}>
      <thead>
        <tr className="bg-secondary-50">
          <th>
            <Text className="font-semibold text-center uppercase mr-2" size="xs" variant="light">
              #
            </Text>
          </th>
          <th>
            <Text className="font-semibold uppercase" size="xs" variant="light">
              Criteria
            </Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {map(tenderMandatoryCriteria, ({ _id, description }, index) => (
          <tr key={_id} className="border-t border-gray-100 align-top">
            <td className="px-2 py-2">
              <Text className="text-center whitespace-pre-wrap mr-2" size="sm">
                {index + 1}
              </Text>
            </td>
            <td className="py-2">
              <Text className="whitespace-pre-wrap" size="sm">
                {description}
              </Text>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
})
