import React, { FormEvent, memo, useState } from 'react'
import { GqlApprovalTemplateGroupFieldsFragment } from '@gql'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, Text, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'

type FormData = { name: string; description: string }

type FieldName = keyof FormData

type Props = {
  label: string
  field: FieldName
  approvalTemplateGroup: GqlApprovalTemplateGroupFieldsFragment
}

const DESCRIPTION_NAME_MAX_LENGTH = 200

export const SettingsModulesApprovalTemplateGroupUpdateModal = memo(({ label, field, approvalTemplateGroup }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateUpdateApprovalTemplateGroup } = useApprovalTemplateGroup()
  const [formData, setFormData] = useState<FormData>({
    name: approvalTemplateGroup.name,
    description: approvalTemplateGroup.description ?? '',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setIsSaving(true)
      await mutateUpdateApprovalTemplateGroup({
        approvalTemplateGroupId: approvalTemplateGroup.id,
        name: formData.name,
        description: formData.description,
      })
      setIsSaving(false)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`Edit ${label.toLowerCase()}`} isDisabled={isSaving} />
      <ModalContent>
        <Label className="flex items-baseline">
          {label}
          <Text size="sm" variant="light" className="ml-1">{`(${formData.description.length}/${DESCRIPTION_NAME_MAX_LENGTH})`}</Text>
        </Label>
        {field === 'name' && (
          <Input value={formData.name} onChange={({ target }) => setFormData({ ...formData, name: target.value })} isDisabled={isSaving} />
        )}
        {field === 'description' && (
          <>
            <TextArea
              isDisabled={isSaving}
              maxLength={DESCRIPTION_NAME_MAX_LENGTH}
              onChange={({ target }) => setFormData({ ...formData, description: target.value })}
              rows={4}
              value={formData.description}
            />
            <Text className="mt-1" size="sm" variant="light">
              Max {DESCRIPTION_NAME_MAX_LENGTH} characters
            </Text>
          </>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
