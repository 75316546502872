import classNames from 'classnames'
import React, { memo, useMemo, useState } from 'react'
import { filter, find, groupBy, keyBy, map, sortBy } from 'lodash'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationScoreFieldsFragment, GqlPagination } from '@gql'
import {
  Icon,
  TableColumn,
  Table,
  TableRowCta,
  Text,
  sentryService,
  useAsyncEffect,
  useToast,
  utilService,
  TableHeader,
  Tooltip,
  useCallout,
} from '@cotiss/common'
import {
  evaluationEventService,
  useEvaluationEvent,
  useEvaluationEnvelope,
  useEvaluationCriteria,
  useEvaluationScore,
  useEvaluation,
  EvaluationEventEvaluationCriteriaBreakdownStatusBadge,
  useEvaluationUser,
  EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP,
} from '@cotiss/evaluation-event'

type Props = {
  parentEvaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment
  onCtaClick: (evaluationCriteria: GqlEvaluationCriteriaFieldsFragment) => void
}

export const EvaluationEventCriteriaScoreList = memo(({ parentEvaluationCriteriaItem, onCtaClick }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const { queryEvaluationScoreList } = useEvaluationScore()
  const { evaluation, evaluationOverview } = useEvaluation()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [evaluationScores, setEvaluationScores] = useState<GqlEvaluationScoreFieldsFragment[]>([])
  const [evaluationCriteria, setEvaluationCriteria] = useState<GqlEvaluationCriteriaFieldsFragment[]>([])

  const refreshScores = async (evaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
    if (!evaluationEvent || !evaluation) {
      return
    }

    const { evaluationScores } = await queryEvaluationScoreList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        evaluationId: evaluation.id,
        evaluationCriteriaIds: map(evaluationCriteria, 'id'),
      },
    })
    setEvaluationScores(evaluationScores)
  }

  useAsyncEffect(async () => {
    // If we don't have this data available, then something has gone wrong.
    if (!evaluationEnvelope || !evaluationEvent || !evaluation) {
      return
    }

    try {
      const { evaluationCriteria, pagination } = await queryEvaluationCriteriaList({
        filter: {
          evaluationEnvelopeId: evaluationEnvelope.id,
          evaluationEventId: evaluationEvent.id,
          parentEvaluationCriteriaId: parentEvaluationCriteriaItem?.id || null,
        },
        pagination: { page: currentPage, pageSize: 100 },
      })

      await refreshScores(evaluationCriteria)
      setEvaluationCriteria(evaluationCriteria)
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      // TODO: How should we handle this error?
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [currentPage])

  const { parentColumns, parentCriteria, parentCriteriaBreakdown, leafColumns, leafCriteria } = useMemo(() => {
    const criteriaByIsScored = groupBy(evaluationCriteria, 'isScored')
    const parentCriteria = sortBy(criteriaByIsScored['false'], 'index') || []
    const leafCriteria = sortBy(criteriaByIsScored['true'], 'index') || []
    const parentWeightById = evaluationEventService.getWeightById({ items: parentCriteria })
    const leafWeightById = evaluationEventService.getWeightById({ items: leafCriteria })
    const parentWeightTotal = evaluationEventService.getTotalWeight({ weightById: parentWeightById })
    const leafWeightTotal = evaluationEventService.getTotalWeight({ weightById: leafWeightById })
    const parentWeightPercentageById = evaluationEventService.getWeightedPercentageById({
      weightById: parentWeightById,
      totalWeight: parentWeightTotal,
    })
    const leafWeightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById: leafWeightById, totalWeight: leafWeightTotal })
    const parentCriteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, { evaluationCriteriaId: parentEvaluationCriteriaItem?.id })

    const accessControlCriteriaScoreMap = keyBy(
      filter(evaluationUserInSession?.accessControls, { resourceType: 'criteria', access: 'score' }),
      'resourceId'
    )

    // All the leaf nodes are disabled if the user does not have scoring access to the parent.
    const isAllLeafDisabled = Boolean(parentEvaluationCriteriaItem && !accessControlCriteriaScoreMap[parentEvaluationCriteriaItem.id])

    const parentColumns: TableColumn[] = [
      {
        heading: 'Criteria',
        rows: map(parentCriteria, (evaluationCriteriaItem) => {
          const isDisabled = !accessControlCriteriaScoreMap[evaluationCriteriaItem.id]

          return {
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">{evaluationCriteriaItem.index}.</Text>
                <Text className="line-clamp-3">{evaluationCriteriaItem.content}</Text>
              </div>
            ),
            cta: !isDisabled ? (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      Score <Icon className="ml-1" icon="arrow-right" />
                    </>
                  ),
                  onClick: () => onCtaClick(evaluationCriteriaItem),
                }}
              />
            ) : (
              <Tooltip tooltipClassName="text-center" tooltip="You are not required to score this criteria.">
                <Icon icon="lock" variant="light" />
              </Tooltip>
            ),
          }
        }),
      },
      {
        heading: 'Status',
        thClassName: 'w-48',
        rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => {
          const isDisabled = !accessControlCriteriaScoreMap[evaluationCriteriaId]
          const criteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, { evaluationCriteriaId })

          return {
            content: () => (
              <div className="flex items-center">
                {!isDisabled && (
                  <>
                    <EvaluationEventEvaluationCriteriaBreakdownStatusBadge criteriaBreakdown={criteriaBreakdown} />
                    {criteriaBreakdown && (
                      <Text className="shrink-0 ml-2" variant="light" size="sm">
                        ({criteriaBreakdown.completedScoredSubCriteriaCount} of {criteriaBreakdown.scoredSubCriteriaCount})
                      </Text>
                    )}
                  </>
                )}
                {isDisabled && <Text>--</Text>}
              </div>
            ),
          }
        }),
      },
      {
        heading: 'Weight',
        thClassName: 'text-right w-28',
        rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => ({
          content: () => (
            <Text className="text-right">{utilService.formatAsPercentage(Number(parentWeightPercentageById[evaluationCriteriaId]) * 100)}</Text>
          ),
        })),
      },
      {
        heading: 'Weighted score',
        thClassName: 'text-right w-36',
        rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => {
          const criteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, { evaluationCriteriaId })

          return {
            content: () =>
              criteriaBreakdown?.completedScoredSubCriteriaCount ? (
                <Text className="text-right" variant="secondary">
                  {utilService.formatAsPercentage(criteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)}
                </Text>
              ) : (
                <Text className="text-right">--</Text>
              ),
          }
        }),
      },
    ]

    const leafColumns: TableColumn[] = [
      {
        heading: 'Criteria',
        rows: map(leafCriteria, (evaluationCriteriaItem) => {
          const isDisabled = parentEvaluationCriteriaItem ? isAllLeafDisabled : !accessControlCriteriaScoreMap[evaluationCriteriaItem.id]
          return {
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">
                  {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}.` : ''}
                  {evaluationCriteriaItem.index}.
                </Text>
                <Text className="line-clamp-3">{evaluationCriteriaItem.content}</Text>
              </div>
            ),
            cta: !isDisabled ? (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      Score <Icon className="ml-1" icon="arrow-right" />
                    </>
                  ),
                  onClick: () => onCtaClick(evaluationCriteriaItem),
                }}
              />
            ) : (
              <Tooltip tooltipClassName="text-center" tooltip="You are not required to score this criteria.">
                <Icon icon="lock" variant="light" />
              </Tooltip>
            ),
          }
        }),
      },
      {
        heading: 'Weight',
        thClassName: 'text-right w-28',
        rows: map(leafCriteria, ({ id: evaluationCriteriaId }) => ({
          tdClassName: 'text-right',
          content: () => (
            <Text className="truncate" font="jakarta">
              {utilService.formatAsPercentage(Number(leafWeightPercentageById[evaluationCriteriaId]) * 100)}
            </Text>
          ),
        })),
      },
      {
        heading: 'Score',
        thClassName: 'w-40',
        rows: map(leafCriteria, (evaluationCriteriaItem) => {
          const evaluationScore = find(evaluationScores, { evaluationCriteriaId: evaluationCriteriaItem.id })

          return {
            content: () => {
              const isDisabled = parentEvaluationCriteriaItem ? isAllLeafDisabled : !accessControlCriteriaScoreMap[evaluationCriteriaItem.id]
              return isDisabled ? (
                <Text>--</Text>
              ) : (
                <Text>
                  {evaluationScore ? evaluationScore.value : '--'}{' '}
                  {evaluationCriteriaItem.ratingScale === 'percentage'
                    ? '%'
                    : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[evaluationCriteriaItem.ratingScale]}`}
                </Text>
              )
            },
          }
        }),
      },
      {
        heading: 'Comment',
        thClassName: 'w-48',
        rows: map(leafCriteria, (evaluationCriteriaItem) => {
          const evaluationScore = find(evaluationScores, { evaluationCriteriaId: evaluationCriteriaItem.id })
          return {
            content: () => <Text className="line-clamp-3">{evaluationScore?.comment || '--'}</Text>,
          }
        }),
      },
    ]

    return { parentColumns, parentCriteria, parentCriteriaBreakdown, leafColumns, leafCriteria }
  }, [parentEvaluationCriteriaItem, evaluationCriteria, evaluationScores, evaluationEnvelope, evaluation])

  return (
    <>
      {Boolean(parentCriteria.length) && (
        <>
          <TableHeader>
            <Text className="font-semibold" font="jakarta">
              Criteria with sub-criteria
            </Text>
            <Text className="mt-1" variant="light" size="sm">
              Hover over each criteria and select to score each sub-criteria
            </Text>
          </TableHeader>
          <Table columns={parentColumns} isLoading={isLoading} />
        </>
      )}
      {Boolean(leafCriteria.length) && (
        <>
          <TableHeader className={classNames('flex items-center justify-between', { 'mt-6': parentCriteria.length })}>
            <div>
              <Text className="font-semibold" font="jakarta">
                {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}. ${parentEvaluationCriteriaItem.content}` : 'Criteria only'}
              </Text>
              <Text className="mt-1" variant="light" size="sm">
                Add a score for each sub-criteria and add a justification comment
              </Text>
            </div>
            {parentCriteriaBreakdown && (
              <div>
                <Text size="sm" variant="light">
                  Weighted score
                </Text>
                <Text className="text-right" variant="secondary">
                  {parentCriteriaBreakdown.completedScoredSubCriteriaCount
                    ? utilService.formatAsPercentage(parentCriteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)
                    : '--'}
                </Text>
              </div>
            )}
          </TableHeader>
          <Table className="w-full" columns={leafColumns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
        </>
      )}
    </>
  )
})
