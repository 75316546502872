import React, { useMemo } from 'react'
import { Text, NoDataPlaceholder, ScrollableTable, ScrollableTableColumn, Badge, datetimeService, BaseVariant } from '@cotiss/common'
import { AttachmentSignatureStatus, ContractDocumentAttachmentSignature, useGetContractShell } from '@cotiss/contract'
import { find, lowerCase, map, upperFirst } from 'lodash'

type Props = {
  contractShellId: string
  contractId: string
  isLoading?: boolean
}
// TODO: if we need to this anywhere else, break into separate Badge component
const getSignatureVariant = (status: AttachmentSignatureStatus): BaseVariant => {
  switch (status) {
    case 'PENDING':
    case 'SENT':
      return 'info'
    case 'PENDING_APPROVAL':
      return 'neutral'
    case 'SIGNED':
      return 'success'
    case 'DECLINED':
    case 'ERROR':
      return 'danger'
    default:
      return 'warning'
  }
}

export const ContractSummarySignaturesTab = ({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const signatures: ContractDocumentAttachmentSignature[] = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)
    const attachments = find(contract?.documentShells, { type: 'CONTRACT' })?.attachments || []
    return attachments.length ? attachments[0].signatures : []
  }, [contractShell, contractId])

  if (!isLoading && !signatures.length) {
    return <NoDataPlaceholder illustration="dot-list" label="No signees added" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Signer name',
      rows: map(signatures, ({ name }) => ({
        content: () => (
          <Text className="font-medium truncate" font="jakarta" title={name}>
            {name}
          </Text>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Email',
      rows: map(signatures, ({ email }) => ({
        content: () => <Text>{email}</Text>,
      })),
    },
    {
      heading: 'Type',
      rows: map(signatures, ({ signatureType }) => ({
        content: () => (
          <Badge shape="square" state="translucent" variant={signatureType === 'EXTERNAL' ? 'info' : 'secondary'}>
            {upperFirst(lowerCase(signatureType))}
          </Badge>
        ),
      })),
    },
    {
      heading: 'Date sent',
      rows: map(signatures, ({ sentAt }) => ({
        content: () => <Text>{sentAt ? datetimeService.format(sentAt, 'do MMM yyyy') : '--'}</Text>,
      })),
    },
    {
      heading: 'Status',
      rows: map(signatures, ({ status }) => ({
        content: () => (
          <Badge state="dot" variant={getSignatureVariant(status)}>
            {upperFirst(lowerCase(status))}
          </Badge>
        ),
      })),
    },
    {
      heading: 'Date signed',
      rows: map(signatures, ({ signedAt }) => ({
        content: () => <Text>{signedAt ? datetimeService.format(signedAt, 'do MMM yyyy') : '--'}</Text>,
      })),
    },
  ]

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
}
