import { Filter_DEPRECATED, utilService } from '@cotiss/common'
import { isArray } from 'lodash'

class FilterService {
  getFiltersFromUrl = (): Filter_DEPRECATED[] => {
    try {
      const { filters } = utilService.getUrlSearchParams({ params: ['filters'] })

      if (!filters) {
        return []
      }

      const parsedFilters = JSON.parse(filters)

      // If the parsed filters are not in the correct format, then we will return the default filters.
      return isArray(parsedFilters) ? parsedFilters : []
    } catch (error: any) {
      // This will throw if there are no filters in the URL, or the filters are in the incorrect format.
      return []
    }
  }
}

export const filterService_DEPRECATED = new FilterService()
