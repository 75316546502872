const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB'] as const

class DocumentService {
  formatSize = (fileSize: number) => {
    let size = Math.abs(fileSize)

    if (!size) {
      return '0 bytes'
    }

    let quotient = Math.floor(Math.log10(size) / 3)
    quotient = quotient < FILE_SIZE_UNITS.length ? quotient : FILE_SIZE_UNITS.length - 1

    size /= 1024 ** quotient

    return `${Number(size.toFixed(0))} ${FILE_SIZE_UNITS[quotient]}`
  }
}

export const documentService = new DocumentService()
