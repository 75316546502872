import { filter, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { TENDER_TYPE_NAME_MAP } from '@cotiss/tender'
import { approvalService, useListApproval } from '@cotiss/approval'
import { TaskStatusBadge, TaskListApprovalCta } from '@cotiss/task'
import { datetimeService, NoDataPlaceholder, paginationService, ScrollableTable, ScrollableTableColumn, Text } from '@cotiss/common'

export const ApprovalTaskTab = memo(() => {
  const { user, isLoading: isLoggedInUserLoading, isError: isLoggedInUserError } = useGetLoggedInUser()
  const { approvals, isLoading: isApprovalsLoading, isError: isApprovalsError } = useListApproval({ userId: user?._id, invalidated: true })
  const [currentPage, setCurrentPage] = useState(1)

  const { processedApprovals, pagination } = useMemo(() => {
    const _approvals = map(approvals, (approval) => ({ type: 'approval', item: approval } as const))
    const { items: preProcessedApprovals, pagination } = paginationService.paginate(_approvals, { currentPage })
    const processedApprovals = filter(preProcessedApprovals, ({ item }) => item.status !== 'revoked')
    return { processedApprovals, pagination }
  }, [approvals, currentPage])

  if (isLoggedInUserError || isApprovalsError) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  if (!(isLoggedInUserLoading || isApprovalsLoading) && !processedApprovals.length) {
    return <NoDataPlaceholder label="No approvals" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Tender',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text className="truncate" font="jakarta">
            {item.tender.title || TENDER_TYPE_NAME_MAP[item.tender.tenderType]}
          </Text>
        ),
        cta: <TaskListApprovalCta approval={item} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => <TaskStatusBadge status={item.status} />,
      })),
    },
    {
      heading: 'Task Type',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text size="sm" variant="light">
            {approvalService.getDisplayApprovalType(item)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Procurement',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text className="truncate max-w-xs" size="sm">
            {item.procurement.title}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date Created',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(item.createdAt, 'd MMMM yyyy h:mm aaa')}
          </Text>
        ),
      })),
    },
  ]

  return (
    <ScrollableTable
      fixedColumns={fixedColumns}
      columns={columns}
      pagination={pagination}
      onPageChange={setCurrentPage}
      isLoading={isLoggedInUserLoading || isApprovalsLoading}
    />
  )
})
