import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationEnvelopeDocumentListInput, GqlEvaluationEnvelopeDocumentListQuery } from '@gql'

export const queryEvaluationEnvelopeDocumentList = async (input: GqlEvaluationEnvelopeDocumentListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT}

      query EvaluationEnvelopeDocumentList($input: EvaluationEnvelopeDocumentListInput!) {
        evaluationEnvelopeDocumentList(input: $input) {
          items {
            ...EvaluationEnvelopeDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeDocumentList
}
