import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlEvaluationEventResultsByEnvelopeCsvQuery, GqlEvaluationEventViewInput } from '@gql'

export const queryEvaluationEventResultsByEnvelopeCsv = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventResultsByEnvelopeCsvQuery>({
    query: gql`
      query EvaluationEventResultsByEnvelopeCsv($input: EvaluationEventViewInput!) {
        evaluationEventResultsByEnvelopeCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.evaluationEventResultsByEnvelopeCsv
}
