import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common'
import { ProjectModel } from '@cotiss/project'

type CreateProjectBody = {
  title: string
  summary?: RemirrorJSON
  targetStartDate?: string
  targetEndDate?: string
}

type UpdateProjectBody = {
  title?: string
  summary?: RemirrorJSON
  targetStartDate?: string
  targetEndDate?: string
}

export const useMutateProject = () => {
  const { mutate } = useMutate()

  return {
    createProject: async (body: CreateProjectBody) => {
      return await mutate<ProjectModel>({ route: '/v2/projects', body })
    },
    updateProject: async (id: string, body: UpdateProjectBody) => {
      return await mutate<ProjectModel>({ route: `/v2/projects/${id}`, body, invalidate: ['/v2/projects'] })
    },
  }
}
