import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT = gql`
  fragment PerformanceScorecardDocumentFields on PerformanceScorecardDocument {
    id
    performanceScorecardId
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
