import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationEventInput,
  GqlCreateEvaluationEventMutation,
  GqlCreateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationEvent = async (input: GqlCreateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEventMutation, GqlCreateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation CreateEvaluationEvent($input: CreateEvaluationEventInput!) {
        createEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEvent as GqlEvaluationEventFieldsFragment
}
