import { useFetch } from '@cotiss/common'
import { ProjectModel } from '@cotiss/project'

export const useGetProject = (id?: string) => {
  const { data: project, ...rest } = useFetch<ProjectModel>('/v2/projects', {
    route: `/v2/projects/${id}`,
    isEnabled: Boolean(id),
  })

  return { project, ...rest }
}
