import { Button, Checkbox, Field, Form, Hr, Text, sentryService, useToast } from '@cotiss/common'
import React, { FormEvent, memo, useState } from 'react'
import { useMutateConflictOfInterest, ConflictOfInterestModel } from '@cotiss/conflict-of-interest'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
  onSuccess: () => void
}

type FormData = {
  termsAgreement: boolean
}

export const ConflictOfInterestViewConfirmationTab = memo(({ conflictOfInterest, onSuccess }: Props) => {
  const [formData, setFormData] = useState<FormData>({
    termsAgreement: false,
  })
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { updateConflictOfInterest } = useMutateConflictOfInterest()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateConflictOfInterest(conflictOfInterest._id, { step: 'declaration-form', dateSigned: new Date() })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
    onSuccess()
  }

  return (
    <Form className="bg-white rounded-lg shadow-lg w-full px-8 py-6 max-w-5xl" onSubmit={handleSubmit}>
      <Text variant="heading" size="h4" font="jakarta">
        {conflictOfInterest.procurement.title}
      </Text>
      <Hr className="my-4" />
      {/* TODO: Pending conflict template */}
      <Field label="Declaration">
        <Text>
          I agree that my duties and responsibilities under this declaration are a continuing, perpetual obligation and survives any expiry,
          performance or termination of this declaration. If any information arises after this declaration that may cause me to be in breach of my
          duties and responsibilities I shall thereafter inform the Organisation. I also confirm that the above details are correct to the best of my
          knowledge, and I make this declaration in good faith and commit to the implementation of any agreed management action.
        </Text>
      </Field>
      <Field label="I have read and understand this declaration and agreed to be bound by the terms herein." className="mt-4">
        <Checkbox
          id="cotiss-terms-and-conditions"
          className="mr-1"
          isChecked={formData.termsAgreement}
          onChange={() => setFormData({ ...formData, termsAgreement: !formData.termsAgreement })}
        />
      </Field>
      <Hr className="my-4" />
      <div className="flex items-center justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!formData.termsAgreement}>
          Agree & sign
        </Button>
      </div>
    </Form>
  )
})
