import { useFetch } from '@cotiss/common'

type Props = {
  q?: string
}

export const useListPreferredSupplierTag = ({ q }: Props = {}) => {
  const { data, ...rest } = useFetch<{ data: string[] }>('/v2/preferred-suppliers/tags', {
    params: { q },
  })

  return { preferredSupplierTags: data?.data || [], ...rest }
}
