import { map } from 'lodash'
import React, { memo } from 'react'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { ShortListDueDiligenceListItem, ShortListPopulatedModel } from '@cotiss/short-list'
import { Table_DEPRECATED, Tbody_DEPRECATED, Th_DEPRECATED, Thead_DEPRECATED } from '@cotiss/common'

type Props = {
  className?: string
  shortLists: ShortListPopulatedModel[]
  tenderResponseReports: TenderResponseReportModel[]
  onView?: (shortList: ShortListPopulatedModel) => void
  isEditable?: boolean
  isScoreVisible?: boolean
}

export const ShortListDueDiligenceList = memo(({ className, shortLists, tenderResponseReports, onView, isEditable, isScoreVisible }: Props) => (
  <Table_DEPRECATED className={className} isSeparate>
    <Thead_DEPRECATED>
      {isScoreVisible && <Th_DEPRECATED className="w-16">Score</Th_DEPRECATED>}
      <Th_DEPRECATED>Supplier name</Th_DEPRECATED>
      <Th_DEPRECATED className="w-24">Status</Th_DEPRECATED>
      <Th_DEPRECATED className="w-56"></Th_DEPRECATED>
    </Thead_DEPRECATED>
    <Tbody_DEPRECATED>
      {map(shortLists, (shortList) => (
        <ShortListDueDiligenceListItem
          key={shortList._id}
          shortList={shortList}
          tenderResponseReports={tenderResponseReports}
          onView={onView}
          isEditable={isEditable}
          isScoreVisible={isScoreVisible}
        />
      ))}
    </Tbody_DEPRECATED>
  </Table_DEPRECATED>
))
