import React, { memo, useState } from 'react'
import { filter, includes, map } from 'lodash'
import { DocumentList } from '@cotiss/document'
import { TenderRequestDocumentUploadModal, useGetTender, useMutateTender } from '@cotiss/tender'
import { Banner, Card, CardFormHeader, Hr, NoDataPlaceholder, Text, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestDocumentsTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { tender, isLoading } = useGetTender(tenderId)

  const handleBulkRemove = async (documentIdsToRemove: string[]) => {
    try {
      setIsSaving(true)
      const attachments = map(
        filter(tender?.attachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateTender(tenderId, { attachments })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Card className="max-w-5xl">
      <CardFormHeader heading="Support documents" hasSeparator={false} />
      <Text className="mb-4" variant="light">
        Upload relevant documents that provide further details about the request
      </Text>
      <Hr className="my-4" />
      <Banner className="mb-4" icon="visible" iconVariant="secondary" variant="light">
        <div>
          <Text className="font-semibold" variant="heading">
            The below information will be displayed on the live listing
          </Text>
          <Text size="sm">External or invited parties will have access to this information.</Text>
        </div>
      </Banner>
      {!isLoading && !tender?.attachments.length && (
        <NoDataPlaceholder
          label="You have not uploaded any documents yet"
          ctaLabel="+ Upload documents"
          onCtaClick={isEditable ? () => openModal(<TenderRequestDocumentUploadModal tenderId={tenderId} />) : undefined}
        />
      )}
      {(isLoading || Boolean(tender?.attachments.length)) && (
        <DocumentList
          documents={tender?.attachments}
          onUpload={isEditable ? () => openModal(<TenderRequestDocumentUploadModal tenderId={tenderId} />) : undefined}
          onBulkRemove={isEditable ? handleBulkRemove : undefined}
          isDisabled={isSaving}
          isLoading={isLoading}
        />
      )}
    </Card>
  )
})
