import {
  Breadcrumb,
  BreadcrumbModel,
  Button,
  Dropdown,
  DropdownContent,
  Header,
  Icon,
  Skeleton,
  StepButtonVariant,
  TRANSITION_DURATION,
  Text,
  routerService,
  useCallout,
} from '@cotiss/common'
import { useGetProcurement } from '@cotiss/procurement'
import { useGetProject } from '@cotiss/project'
import { TENDER_TYPE_NAME_MAP, TenderStatus, TenderTypeBadge, TenderUpdateEndDateModal, useGetTender } from '@cotiss/tender'
import { TenderFlowStep, TenderFlowStepButton, tenderFlowService } from '@cotiss/tender-flow'
import { TenderUpdateCreateUpdateModal } from '@cotiss/tender-update'
import { motion } from 'framer-motion'
import { includes, map } from 'lodash'
import React, { memo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export const TenderFlowHeader = memo(() => {
  const { openModal } = useCallout()
  const { replace, push } = useHistory()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(tender?.procurement)
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const canPreview = includes(
    ['active', 'review', 'evaluate', 'consensus', 'price', 'selection', 'selection-approval', 'report', 'cancelled', 'unsuccessful', 'complete'],
    tender?.status
  )
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)
  const tenderFlowSteps = tenderFlowService.getTenderFlowSteps(tender)
  const stepUnlockedIndex = tender ? tenderFlowService.getUnlockedRequestStepIndex(tender) : 0
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Procurements',
      href: routerService.getHref('/project/list/:tab?'),
    },
    {
      label: 'Overview',
      href: routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId: tender?.procurement ?? '' }),
    },
    {
      label: 'View',
    },
  ]

  const handleStepChange = (step: TenderFlowStep | 'settings') => {
    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step }))
  }

  const getVariant = (...statuses: TenderStatus[]): StepButtonVariant => {
    const results = map(statuses, (status) => {
      if (status === tender?.status) {
        return 'default'
      }

      if (tender?.validation.finishedStages.includes(status)) {
        return 'complete'
      }

      return 'locked'
    })

    if (includes(results, 'default')) {
      return 'default'
    }

    if (includes(results, 'complete')) {
      return 'complete'
    }

    return 'locked'
  }

  const getVariantFromStep = (step: TenderFlowStep): StepButtonVariant => {
    if (!tender) {
      return 'locked'
    }

    switch (step) {
      case 'active':
        // 'active' step can show either 'planned-approval' or 'active' screens
        return getVariant('planned-approval', 'active')
      case 'shortlist':
      case 'evaluate':
        // 'evaluate' step in the front end combines 'evaluate', 'consensus' and 'price' status' on tender
        return getVariant('evaluate', 'consensus', 'price')
      case 'report':
        // 'report' step can show either 'selection-approval' or 'report' screens
        return getVariant('selection-approval', 'report')
      default:
        return getVariant(step)
    }
  }

  const handleOnPostTenderUpdateClick = () => {
    push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, tab: 'forum', step: 'active' }))
    openModal(<TenderUpdateCreateUpdateModal tenderId={tenderId} />)
  }

  return (
    <>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb breadcrumbs={breadcrumbs} />
              {isProcurementLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isProcurementLoading && (
                <div className="flex items-center">
                  {tender && (
                    <>
                      <TenderTypeBadge className="mr-2" tenderType={tender.tenderType} />
                      <Text className="font-medium" size="h7" variant="heading" font="jakarta">
                        {tender.title || TENDER_TYPE_NAME_MAP[tender.tenderType]}
                      </Text>
                    </>
                  )}
                </div>
              )}
              {isProjectLoading && <Skeleton className="h-3.5 w-24 mt-1" variant="gray" />}
              {!isProjectLoading && (
                <Text className="mt-0.5" size="sm" variant="light">
                  {procurement?.title} | {project?.title}
                </Text>
              )}
            </div>
          </div>
          <div className="flex items-center">
            {tender?.status === 'active' && tender && (
              <div className="relative ml-2">
                <Button className="ml-2" onClick={() => setIsDropdownOpen(true)} state="translucent" variant="secondary" size="sm">
                  Actions <Icon className="ml-1" icon="chevron-down" />
                </Button>
                <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  <DropdownContent className="flex items-center" onClick={handleOnPostTenderUpdateClick}>
                    Post update
                  </DropdownContent>
                  <DropdownContent
                    className="flex items-center"
                    onClick={() => openModal(<TenderUpdateEndDateModal tender={tender} field="tenderPeriod" />)}
                  >
                    Extend close date
                  </DropdownContent>
                  <DropdownContent
                    className="flex items-center"
                    onClick={() => openModal(<TenderUpdateEndDateModal tender={tender} field="forumCloseDate" />)}
                  >
                    Extend forum close date
                  </DropdownContent>
                </Dropdown>
              </div>
            )}
            {canPreview && tender && (
              <Button
                className="ml-2"
                onClick={() =>
                  window.open(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId: tender.procurement }))
                }
                state="translucent"
                variant="secondary"
                size="sm"
              >
                Preview listing
              </Button>
            )}

            <Button className="ml-2" onClick={() => handleStepChange('settings')} state="ghost" variant="link" size="sm">
              <Icon className="mr-1" icon="settings" size={16} />
              Settings
            </Button>
          </div>
        </div>
      </Header>
      <div className="sticky top-0 flex items-center bg-white border-t border-gray-100 shadow-sm overflow-x-auto z-10">
        {map(tenderFlowSteps, (step) => (
          <TenderFlowStepButton key={step} step={step} tender={tender} variant={getVariantFromStep(step)} onStepChange={handleStepChange} />
        ))}
        <motion.div
          className="absolute left-0 bottom-0 bg-gradient-to-r from-secondary-500 to-secondary-400 h-1"
          animate={{ width: `${(stepUnlockedIndex / tenderFlowSteps.length) * ((isOldEvaluationsEnabled ? 150 : 250) * tenderFlowSteps.length)}px` }}
          transition={{ duration: TRANSITION_DURATION.xl }}
          initial={false}
        />
      </div>
    </>
  )
})
