import { filter, forEach, map, some } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { userService } from '@cotiss/user'
import { GqlEvaluationUserFieldsFragment } from '@gql'
import { Badge, Button, ConfirmModal, Table, TableColumn, TableRowCta, Text, sentryService, useAsyncEffect, useCallout } from '@cotiss/common'
import {
  EvaluationEventPanelMemberAddModal,
  EvaluationEventPanelMemberUpdateModal,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationUser,
} from '@cotiss/evaluation-event'

type Props = {
  isEditable?: boolean
}

export const EvaluationEventPanelList = memo(({ isEditable }: Props) => {
  const { openModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationUsers, queryEvaluationUserList, mutateDeleteEvaluationUser, mutateDeleteEvaluationUserAccessControls } = useEvaluationUser()

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      await queryEvaluationUserList({ filter: { evaluationEventId: evaluationEvent.id } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }

    setIsLoading(false)
  }, [])

  const handleDeleteEvaluationUser = (evaluationUser: GqlEvaluationUserFieldsFragment) => {
    track('evaluation_event_wizard_panel_delete_panel_submit')

    if (evaluationUser.role === 'owner') {
      mutateDeleteEvaluationUserAccessControls({ evaluationUserId: evaluationUser.id })
    } else {
      mutateDeleteEvaluationUser({ evaluationUserId: evaluationUser.id })
    }
  }

  const { columns } = useMemo(() => {
    const evaluationUsersWithAccessControl = filter(evaluationUsers, ({ accessControls }) => Boolean(accessControls.length))

    const columns: TableColumn[] = [
      {
        heading: 'Panel member',
        thClassName: 'w-1/3',
        rows: map(evaluationUsersWithAccessControl, (evaluationUser) => ({
          content: () => <Text isInline>{userService.getFullName(evaluationUser.user)}</Text>,
          cta: isEditable && (
            <TableRowCta
              actions={[
                {
                  label: 'Edit',
                  onClick: () => openModal(<EvaluationEventPanelMemberUpdateModal evaluationUser={evaluationUser} />),
                },
                {
                  label: 'Delete',
                  onClick: () => {
                    openModal(
                      <ConfirmModal
                        heading="Delete panel member"
                        description="Are you sure you want to delete this panel member?"
                        onSubmit={() => handleDeleteEvaluationUser(evaluationUser)}
                      />
                    )
                  },
                },
              ]}
            />
          ),
        })),
      },
    ]

    forEach(evaluationEnvelopes, ({ id: envelopeId, name, order }) => {
      columns.push({
        heading: (
          <div className="normal-case">
            <Text size="sm" variant="light">
              Envelope {order}.
            </Text>
            <Text className="font-semibold">{name}</Text>
          </div>
        ),
        isThWrapped: true,
        rows: map(evaluationUsersWithAccessControl, ({ accessControls }) => {
          const isEvaluator = some(accessControls, { resourceType: 'envelope', resourceId: envelopeId, access: 'evaluate' })
          const isModerator = some(accessControls, { resourceType: 'envelope', resourceId: envelopeId, access: 'moderate' })

          return {
            content: () => (
              <>
                {!isEvaluator && !isModerator && <Text>--</Text>}
                {isModerator && (
                  <Badge className="mr-1" state="outline" variant="warning">
                    Moderate
                  </Badge>
                )}
                {isEvaluator && (
                  <Badge state="outline" variant="secondary">
                    Evaluate
                  </Badge>
                )}
              </>
            ),
          }
        }),
      })
    })

    return { columns }
  }, [evaluationEvent, evaluationUsers])

  return (
    <Table
      columns={columns}
      emptyCta={
        isEditable && (
          <Button size="sm" variant="secondary" state="text" onClick={() => openModal(<EvaluationEventPanelMemberAddModal />)}>
            + Add panel members
          </Button>
        )
      }
      isLoading={isLoading}
    />
  )
})
