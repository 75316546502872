import React, { memo } from 'react'
import { userService } from '@cotiss/user'
import { DocumentList } from '@cotiss/document'
import { useGetContractShell } from '@cotiss/contract'
import { CorrespondenceModel, CorrespondenceUpdateDeleteCta } from '@cotiss/correspondence'
import { Badge, Button, Drawer, Hr, Icon, RichText, Skeleton, Text, datetimeService, useCallout } from '@cotiss/common'

type Props = {
  contractShellId: string
  correspondence: CorrespondenceModel
}

export const ContractViewCorrespondenceDrawer = memo(({ contractShellId, correspondence }: Props) => {
  const { closeNarrowDrawer } = useCallout()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-between bg-white border border-b-grey-200 py-4 px-6">
          <div className="flex items-center">
            <Button onClick={closeNarrowDrawer} state="ghost" shape="square">
              <Icon icon="cross" />
            </Button>
          </div>
        </div>
        <div className="py-6 px-8 w-full">
          <Skeleton className="h-8 w-32" />
          <Skeleton className="h-4 w-52 mt-1" />
          <Skeleton className="h-4 w-24 rounded-full mt-4" />
          <Hr className="my-4" />
          <Skeleton className="h-32 w-full" />
        </div>
      </>
    )
  }

  const renderHeader = () => (
    <CorrespondenceUpdateDeleteCta
      className="ml-auto"
      contractShellId={contractShellId}
      correspondence={correspondence}
      onDeleteSuccess={closeNarrowDrawer}
    />
  )

  return (
    <Drawer header={renderHeader()} isNarrow>
      <Text size="lg" className="font-semibold" font="jakarta">
        {correspondence.title}
      </Text>
      <Text size="sm" variant="light" className="mt-1">
        {`${userService.getFullName(correspondence.createdBy)} • ${datetimeService.format(correspondence.createdAt, 'd MMMM yyyy h:mm aaa')}`}
      </Text>
      <Badge className="mt-4" variant="secondary" state="translucent">
        {contractShell?.title}
      </Badge>
      <Hr className="my-4" />
      {!correspondence.description && <Text className="italic text-center mt-12">No description entered</Text>}
      {correspondence.description && <RichText value={correspondence.description} />}
      <Hr className="my-4" />
      <DocumentList documents={correspondence.attachments} />
    </Drawer>
  )
})
