import { useFetch } from '@cotiss/common'
import { RecommendationModel } from '@cotiss/recommendation'

export const useGetRecommendation = (tenderId?: string) => {
  const { data: recommendation, ...rest } = useFetch<RecommendationModel>('/v2/recommendations', {
    route: `/v2/recommendations/${tenderId}`,
    isEnabled: Boolean(tenderId),
  })

  return { recommendation, ...rest }
}
