import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { accountService } from '@cotiss/account'
import { BuyerUpdateProfileModal } from '@cotiss/buyer'
import { useGetOrganisation } from '@cotiss/organisation'
import { Label, ExternalLink, Icon, Text, Button, useCallout, Card } from '@cotiss/common'

type Props = {
  isEditable?: boolean
}

export const BuyerViewDetails = memo(({ isEditable }: Props) => {
  const { openModal } = useCallout()
  const { id } = useParams<{ id: string }>()
  const { organisation } = useGetOrganisation(id)
  const hasContact = Boolean(organisation?.contactPhone || organisation?.contactEmail)
  const hasExternalLinks = Boolean(organisation?.website || organisation?.linkedin || organisation?.otherLink)

  return (
    <div className="w-1/3">
      <Card className="relative mb-4">
        {isEditable && (
          <Button
            className="absolute top-4 right-4"
            onClick={() => openModal(<BuyerUpdateProfileModal />)}
            variant="secondary"
            state="translucent"
            size="sm"
          >
            Update
          </Button>
        )}
        <div>
          <Label>About</Label>
          <Text>{organisation?.buyerDescription || '–'}</Text>
        </div>
      </Card>

      <Card className="relative">
        <Label>Contact Us</Label>
        {!hasContact && <>–</>}
        {hasContact && (
          <>
            {organisation?.contactPhone && (
              <Text className="flex items-center">
                <Icon className="mr-1" icon="phone" size={16} variant="link" />
                {organisation?.contactPhone || '–'}
              </Text>
            )}
            {organisation?.contactEmail && (
              <Text className="flex items-center">
                <Icon className="mr-1" icon="mail" size={16} variant="link" />
                {organisation?.contactEmail || '–'}
              </Text>
            )}
          </>
        )}

        <Label className="mt-4">Address</Label>
        <Text>{accountService.formatLocation(organisation?.account?.location)}</Text>

        {hasExternalLinks && (
          <>
            <Label className="mt-4">External Links</Label>
            {Boolean(organisation?.website) && (
              <ExternalLink href={organisation?.website} variant="dark" size="sm">
                <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                  <Icon icon="globe" variant="link" size={12} />
                </div>
                {organisation?.website}
              </ExternalLink>
            )}
            {Boolean(organisation?.linkedin) && (
              <ExternalLink className="mt-2" href={organisation?.linkedin} variant="dark" size="sm">
                <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                  <Icon icon="linkedin" variant="link" size={12} />
                </div>
                {organisation?.linkedin}
              </ExternalLink>
            )}
            {Boolean(organisation?.otherLink) && (
              <ExternalLink className="mt-2" href={organisation?.otherLink} variant="dark" size="sm">
                <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                  <Icon icon="external-link" variant="link" size={12} />
                </div>
                {organisation?.otherLink}
              </ExternalLink>
            )}
          </>
        )}
      </Card>
    </div>
  )
})
