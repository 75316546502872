import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { TenderResponsePopulatedModel, useMutateTenderResponse } from '@cotiss/tender-response'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useAnalytics, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
}

export const TenderResponseNonPriceDocumentUploadModal = memo(({ tenderResponse }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const [nonPriceAttachments, setNonPriceAttachments] = useState<DocumentModel[]>([])
  const { track } = useAnalytics()

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    track('procurement_response_event_upload_document_submit')
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, {
        nonPriceAttachments: [...map(nonPriceAttachments, ({ _id }) => _id), ...map(tenderResponse.nonPriceAttachments, ({ _id }) => _id)],
      }),
        closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload non-price forms" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={nonPriceAttachments} onChange={setNonPriceAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
