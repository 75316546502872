import { useAnalytics } from '@cotiss/common'
import { usePerformanceScorecardUser } from '@cotiss/performance'
import { PerformanceAnalyticsEvent } from '@cotiss/performance/performance.events'

export const usePerformanceScorecardAnalytics = () => {
  const { track } = useAnalytics()
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()

  return {
    track: async (event: PerformanceAnalyticsEvent, additionalProperties: Record<string, any> = {}) => {
      const properties = { ...additionalProperties }

      if (performanceScorecardUserInSession) {
        properties.evaluationUserId = performanceScorecardUserInSession.id
        properties.evaluationEventRoles = JSON.stringify(performanceScorecardUserInSession.roles)
      }

      return await track(event, properties)
    },
  }
}
