import { useMutate } from '@cotiss/common'
import { MetafieldEntityType, MetafieldFieldType, MetafieldModel } from '@cotiss/metafield'

type CreateMetafieldBody = {
  entityType: MetafieldEntityType
  fieldType: MetafieldFieldType
  fieldLabel?: string
  fieldValues?: string[]
  fieldDescription?: string
  isRequired?: boolean
  isArchived: boolean
}

type UpdateMetafieldBody = {
  fieldType?: MetafieldFieldType
  fieldLabel?: string
  fieldValues?: string[]
  fieldDescription?: string
  isRequired?: boolean
  isArchived?: boolean
}

export const useMutateMetafield = () => {
  const { mutate } = useMutate()

  return {
    createMetafield: async (body: CreateMetafieldBody) => {
      return await mutate<MetafieldModel>({ route: '/v2/metafields', body, invalidate: ['/v2/metafields'] })
    },
    updateMetafield: async ({ id, body, propagateUpdates = false }: { id: string; body: UpdateMetafieldBody; propagateUpdates?: boolean }) => {
      return await mutate<MetafieldModel>({
        route: `/v2/metafields/${id}`,
        params: { propagateUpdates },
        method: 'PUT',
        body,
        invalidate: ['/v2/metafields'],
      })
    },
  }
}
