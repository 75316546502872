import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeletePerformanceScorecardMetricUserInput,
  GqlDeletePerformanceScorecardMetricUserMutation,
  GqlDeletePerformanceScorecardMetricUserMutationVariables,
} from '@gql'

export const mutateDeletePerformanceScorecardMetricUser = async (input: GqlDeletePerformanceScorecardMetricUserInput) => {
  const { data } = await apolloService.mutate<
    GqlDeletePerformanceScorecardMetricUserMutation,
    GqlDeletePerformanceScorecardMetricUserMutationVariables
  >({
    mutation: gql`
      mutation DeletePerformanceScorecardMetricUser($input: DeletePerformanceScorecardMetricUserInput!) {
        deletePerformanceScorecardMetricUser(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePerformanceScorecardMetricUser as boolean
}
