import { find, forEach, map, some } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useListMetafield } from '@cotiss/metafield'
import { UserAvatarGroup, useGetLoggedInUser } from '@cotiss/user'
import { metafieldValueService, useListMetafieldValue } from '@cotiss/metafield-value'
import {
  ContractCreateDrawer,
  ContractListItemCta,
  ContractShellFilterPopulatedModel,
  ContractStatusBadge,
  ContractVariationStatusBadge,
  FilterContractShellSortKey,
  contractService,
  useMutateContractShell,
} from '@cotiss/contract'
import {
  Button,
  FilterDrawer_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  Filter_DEPRECATED,
  Icon,
  NoDataPlaceholder,
  PaginationModel,
  ScrollableTable,
  ScrollableTableColumn,
  Text,
  Tooltip,
  datetimeService,
  filterService_DEPRECATED,
  sentryService,
  useCallout,
  useFeature,
  useSortTable,
  useToast,
  utilService,
} from '@cotiss/common'

type Props = {
  isArchived?: boolean
}

export const ContractList = memo(({ isArchived }: Props) => {
  const { replace } = useHistory()
  const { openToast } = useToast()
  const { pathname } = useLocation()
  const { user } = useGetLoggedInUser()
  const [currentPage, setCurrentPage] = useState(1)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const [isDownloading, setIsDownloading] = useState(false)
  const [pagination, setPagination] = useState<PaginationModel>()
  const { filterContractShell, exportCsv } = useMutateContractShell()
  const [isLoadingContractShells, setIsLoadingContractShells] = useState(false)
  const [filterFields, setFilterFields] = useState<FilterFieldOptions_DEPRECATED>({})
  const [contractShells, setContractShell] = useState<ContractShellFilterPopulatedModel[]>([])
  const isContractManagementListViewMetafieldsEnabled = useFeature('contract-management-list-view-metafields')
  const [filters, setFilters] = useState<Array<Filter_DEPRECATED>>(filterService_DEPRECATED.getFiltersFromUrl())
  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues

  useEffect(() => {
    const queryParams = utilService.generateUrlSearchParams({ filters: JSON.stringify(filters) })

    replace(`${pathname}${filters.length ? queryParams : ''}`)
  }, [filters])

  const processedFilters = useMemo(() => {
    return [
      ...filters,
      {
        field: 'archived',
        operation: isArchived ? 'IS_TRUE' : 'IS_FALSE',
        value: true,
      },
    ] as Filter_DEPRECATED[]
  }, [filters, isArchived])

  const refreshContractShells = async () => {
    try {
      setIsLoadingContractShells(true)

      const { contractShells, pagination, meta } = await filterContractShell({
        filters: processedFilters,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setContractShell(contractShells)
      setPagination(pagination)

      // Remove archived filter from the UI; the user cannot override it so they needn't see it in the UI
      Object.hasOwn(meta, 'archived') && delete meta.archived

      setFilterFields(meta)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoadingContractShells(false)
  }

  useEffect(() => {
    refreshContractShells()
  }, [processedFilters, currentPage, sortKey, sortDirection])

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({ filters: processedFilters, timeZone: datetimeService.getLocalTimeZone() })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `contract_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const handleSetFilters = (filters: Filter_DEPRECATED[]) => {
    setCurrentPage(1)
    setFilters(filters)
  }

  const renderButtons = () => (
    <div className="flex items-center justify-between">
      <Button
        size="xs"
        variant="secondary"
        state="outline"
        className="mb-4"
        isDisabled={isLoading}
        onClick={() => openNarrowDrawer(<FilterDrawer_DEPRECATED filters={filters} filterFields={filterFields} setFilters={handleSetFilters} />)}
      >
        + Filters ({filters.length})
      </Button>
      <Button size="xs" variant="secondary" className="mb-4" isDisabled={isLoading} isLoading={isDownloading} onClick={handleDownloadCsv}>
        <Icon icon="download-01" className="mr-2" /> Download CSV
      </Button>
    </div>
  )

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(contractShells, (contractShell) => ({
          content: () => (
            <Text className="truncate" title={contractShell.title} font="jakarta">
              {contractShell.title}
            </Text>
          ),
          cta: (
            <>
              {/* For initial contracts that are in drafting, only the person who created the contract can view */}
              {!contractShell.contracts.variationTypes.length &&
              contractShell.contracts.status == 'DRAFTING' &&
              !some(contractShell.contracts.metadata.owners, (owner) => owner._id === user?._id) ? (
                <Tooltip className="hover:cursor-default" tooltip="This contract is locked as it is still in draft">
                  <Icon icon="lock" variant="light" />
                </Tooltip>
              ) : (
                <ContractListItemCta contractShell={contractShell} onSuccess={refreshContractShells} />
              )}
            </>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Owners',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <UserAvatarGroup users={contracts.metadata.owners} />,
        })),
      },
      {
        heading: 'Variation status',
        rows: map(contractShells, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Total Exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          contractShells,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          contractShells,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Next expiry',
        rows: map(contractShells, ({ contracts: { priceDurations } }) => ({
          content: () => {
            const nextExpiry = contractService.getNextExpirationDate(priceDurations)
            return (
              <Text size="sm" variant="light">
                {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
              </Text>
            )
          },
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(contractShells, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(contractShells, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract id',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.externalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isContractManagementListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(contractShells, ({ contracts: { _id: contractId } }) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return { fixedColumns, columns }
  }, [contractShells, user, metafields, metafieldValues, isContractManagementListViewMetafieldsEnabled])

  if (!isLoading && !contractShells.length) {
    return (
      <div>
        {renderButtons()}
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label={
              filters.length
                ? 'No contracts have been found'
                : "You haven't created any contracts yet. Once created, you can track your contracts here"
            }
            ctaSize="xs"
            ctaLabel="+ Create contract"
            onCtaClick={() => openDrawer(<ContractCreateDrawer />)}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderButtons()}
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </div>
  )
})
