import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_USER_FRAGMENT } from '@cotiss/approval-template-user'
import {
  GqlApprovalTemplateUserFieldsFragment,
  GqlCreateApprovalTemplateUsersInput,
  GqlCreateApprovalTemplateUsersMutation,
  GqlCreateApprovalTemplateUsersMutationVariables,
} from '@gql'

export const mutateCreateApprovalTemplateUsers = async (input: GqlCreateApprovalTemplateUsersInput) => {
  const { data } = await apolloService.mutate<GqlCreateApprovalTemplateUsersMutation, GqlCreateApprovalTemplateUsersMutationVariables>({
    mutation: gql`
      ${APPROVAL_TEMPLATE_USER_FRAGMENT}

      mutation CreateApprovalTemplateUsers($input: CreateApprovalTemplateUsersInput!) {
        createApprovalTemplateUsers(input: $input) {
          ...ApprovalTemplateUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createApprovalTemplateUsers as GqlApprovalTemplateUserFieldsFragment[]
}
