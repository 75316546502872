import React, { useState, memo } from 'react'
import { ContractReminderAddModal, ContractReminderModel, useMutateContractShell } from '@cotiss/contract'
import { DropdownContent, Dropdown, Button, Icon, useCallout, sentryService, useToast } from '@cotiss/common'

type Props = {
  reminder: ContractReminderModel
  contractShellId: string
}

export const ContractReminderListItemCta = memo(({ reminder, contractShellId }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { deleteContractReminder, updateContractReminder } = useMutateContractShell()

  const onToggleMarkAsComplete = async () => {
    try {
      setIsSaving(true)
      await updateContractReminder(reminder._id, contractShellId, { complete: reminder.complete ? false : true })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() => openModal(<ContractReminderAddModal contractShellId={contractShellId} reminder={reminder} />)}
        >
          Edit
        </DropdownContent>
        <DropdownContent className="flex items-center" onClick={async () => deleteContractReminder(reminder._id, contractShellId)}>
          Archive
        </DropdownContent>
      </Dropdown>
      {!reminder.archived && (
        <Button onClick={() => onToggleMarkAsComplete()} state="outline" variant="secondary" size="xs" isLoading={isSaving}>
          {reminder.complete ? 'Incomplete' : 'Complete'}
        </Button>
      )}
    </div>
  )
})
