import { map } from 'lodash'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { CardFormHeader, Card } from '@cotiss/common'
import { useListMetafieldValue } from '@cotiss/metafield-value'
import { MetafieldForm, useListMetafield } from '@cotiss/metafield'

export const ProcurementOverviewBackgroundMetafieldsTab = memo(() => {
  const { procurementId } = useParams<{ procurementId: string }>()
  const { metafields } = useListMetafield({ entityType: 'PROCUREMENT_PLAN' })
  const { metafieldValues } = useListMetafieldValue({ resourceId: procurementId })

  return (
    <>
      <Card className="max-w-5xl">
        <CardFormHeader heading="Custom fields" />

        {map(metafields, (metafield, index) => (
          <div key={metafield._id}>
            <MetafieldForm
              className={index ? 'mt-8' : ''}
              resourceId={procurementId}
              metafield={metafield}
              metafieldValues={metafieldValues}
              isEditable
            />
          </div>
        ))}
      </Card>
    </>
  )
})
