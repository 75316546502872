import { useFetch } from '@cotiss/common'
import { OrganisationModel } from '@cotiss/organisation'

export const useGetOrganisation = (id?: string) => {
  const { data: organisation, ...rest } = useFetch<OrganisationModel>('/v2/organisations', {
    route: `/v2/organisations/${id}`,
    isEnabled: Boolean(id),
  })

  return { organisation, ...rest }
}
