import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationUserInput,
  GqlCreateEvaluationUserMutation,
  GqlCreateEvaluationUserMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationUser = async (input: GqlCreateEvaluationUserInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationUserMutation, GqlCreateEvaluationUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation CreateEvaluationUser($input: CreateEvaluationUserInput!) {
        createEvaluationUser(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationUser as GqlEvaluationUserFieldsFragment
}
