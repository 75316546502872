import { useFetch } from '@cotiss/common'
import { OrganisationClaimedStatus, OrganisationCountryCodeCombined } from '@cotiss/organisation'

export type OrganisationSearchResponse = {
  name?: string
  businessNumber?: string
  address?: string
  status: OrganisationClaimedStatus
  internalId?: string
  externalId?: string
}

export type PreferredSupplierSearchMethod = 'name' | 'businessNumber'

type UseListOrganisationsSearchParams =
  | {
      type: 'name'
      name?: string
      countryCode?: OrganisationCountryCodeCombined
      isEnabled?: boolean
    }
  | {
      type: 'businessNumber'
      businessNumber?: string
      countryCode?: OrganisationCountryCodeCombined
      isEnabled?: boolean
    }

export const useListOrganisationSearch = ({ isEnabled = true, ...params }: UseListOrganisationsSearchParams) => {
  const { data: organisations = [], ...rest } = useFetch<OrganisationSearchResponse[]>('/v2/organisations/search', {
    route: `/v2/organisations/search`,
    params: params,
    isEnabled:
      isEnabled && (params.type === 'name' ? Boolean(params.name && params.countryCode) : Boolean(params.businessNumber && params.countryCode)),
    willRetry: false,
  })

  return { organisations, ...rest }
}
