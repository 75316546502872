import { useFetch } from '@cotiss/common'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response'

export const useGetTenderResponse = (id?: string) => {
  const { data: tenderResponse, ...rest } = useFetch<TenderResponsePopulatedModel>('/v2/tender-response', {
    route: `/v2/tender-response/${id}`,
    isEnabled: Boolean(id),
  })

  return { tenderResponse, ...rest }
}
