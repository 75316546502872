import { gql } from '@apollo/client'

export const APPROVAL_TEMPLATE_GROUP_FRAGMENT = gql`
  fragment ApprovalTemplateGroupFields on ApprovalTemplateGroup {
    id
    name
    description
    approvalTemplates {
      ...ApprovalTemplateFields
    }
  }
`
