import { filter, find, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { GqlEvaluationUserRole } from '@gql'
import { UserSelect, useListUser } from '@cotiss/user'
import { useEvaluationEvent, useEvaluationEventAnalytics, useEvaluationUser } from '@cotiss/evaluation-event'
import { Form, Label, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast, Button, Text, routerService } from '@cotiss/common'

type FormData = {
  userId: string
  role: GqlEvaluationUserRole
}

export const EvaluationEventUserAddModal = memo(() => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const { closeModal, closeFullModal } = useCallout()
  const [formData, setFormData] = useState<FormData>({ userId: '', role: 'owner' })
  const { users, isLoading } = useListUser({ permissions: ['PROCUREMENT_MANAGER'] })
  const { evaluationUsers, mutateCreateEvaluationUser, mutateUpdateEvaluationUser } = useEvaluationUser()

  // We only want to show users that are not already owners (I.e. don't have a role.)
  const userOptions = useMemo(
    () => filter(users, ({ _id }) => !some(evaluationUsers, ({ userId, role }) => _id === userId && role)),
    [evaluationUsers, users]
  )

  useEffect(() => {
    track('evaluation_event_wizard_panel_add_owner_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.userId || !evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_panel_add_owner_submit')

    try {
      setIsSaving(true)

      const existingEvaluationUser = find(evaluationUsers, { userId: formData.userId })

      if (existingEvaluationUser) {
        await mutateUpdateEvaluationUser({ evaluationUserId: existingEvaluationUser.id, role: 'owner' })
      } else {
        await mutateCreateEvaluationUser({ evaluationEventId: evaluationEvent.id, userId: formData.userId, role: 'owner', accessControls: [] })
      }
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleClickOpenSettings = () => {
    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
    closeModal()
    closeFullModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading="Add owner"
        supplementary="Evaluation owners have access to edit the event itself. They can also upload and edit supplier submissions."
      />
      <ModalContent>
        <Label className="mb-0">User</Label>
        <Text className="mb-1" size="sm" variant="light" isInline>
          Users must be added to your organisation in your
          <Button className="ml-1" state="text" variant="link" size="sm" onClick={handleClickOpenSettings} isDisabled={isSaving}>
            Settings.
          </Button>
        </Text>
        <UserSelect
          value={formData.userId}
          options={userOptions}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving || isLoading}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
