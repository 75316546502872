import { Badge, Button, Card, CardHeader, Table, TableColumn, TableHeader, TableRow, Text, useCallout } from '@cotiss/common'
import { EvaluationEventPanelUpdateFullModal, useEvaluationEnvelope, useEvaluationEvent, useEvaluationUser } from '@cotiss/evaluation-event'
import { userService } from '@cotiss/user'
import classNames from 'classnames'
import { compact, filter, find, map, some } from 'lodash'
import React, { memo, useMemo } from 'react'

export const EvaluationEventViewTeamPanelTab = memo(() => {
  const { openFullModal } = useCallout()
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()

  const panelData = useMemo(() => {
    if (!evaluationUserInSession) {
      return []
    }

    const panelData = map(evaluationEnvelopes, (evaluationEnvelope) => {
      const accessControl = { resourceType: 'envelope', resourceId: evaluationEnvelope.id }
      const moderateEvaluationUser = find(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'moderate' }))
      const evaluateEvaluationUsers = filter(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'evaluate' }))

      const userRows: TableRow[] = map(evaluateEvaluationUsers, (evaluationUser) => ({
        content: () => (
          <div className="flex items-center truncate">
            <Text className="truncate">{userService.getFullName(evaluationUser.user)}</Text>
            {evaluationUserInSession?.id === evaluationUser.id && (
              <Text className="ml-1" variant="light">
                (you)
              </Text>
            )}
          </div>
        ),
      }))

      const accessRows: TableRow[] = map(evaluateEvaluationUsers, () => ({
        content: () => (
          <Badge state="outline" variant="secondary">
            Evaluate
          </Badge>
        ),
      }))

      if (moderateEvaluationUser) {
        userRows.push({
          content: () => (
            <div className="flex items-center truncate">
              <Text className="truncate">{userService.getFullName(moderateEvaluationUser.user)}</Text>
              {evaluationUserInSession?.id === moderateEvaluationUser.id && (
                <Text className="ml-1" variant="light">
                  (you)
                </Text>
              )}
            </div>
          ),
        })

        accessRows.push({
          content: () => (
            <Badge state="outline" variant="warning">
              Moderate
            </Badge>
          ),
        })
      }

      const columns: TableColumn[] = [
        {
          heading: 'users',
          rows: userRows,
        },
        {
          heading: 'tasks',
          rows: accessRows,
        },
      ]

      return { evaluationEnvelope, evaluationUsers: evaluateEvaluationUsers, columns }
    })

    return compact(panelData)
  }, [evaluationUsers, evaluationEnvelopes, evaluationUserInSession])

  return (
    <Card>
      <CardHeader>
        <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
          Panel
        </Text>
      </CardHeader>
      {map(panelData, ({ evaluationEnvelope, columns }, index) => (
        <div key={evaluationEnvelope.id} className={classNames({ 'mt-8': index })}>
          <TableHeader className="relative flex items-center justify-between">
            <div>
              <Text size="sm" variant="light">
                Envelope {evaluationEnvelope.order}
              </Text>
              <Text className="font-medium" size="lg">
                {evaluationEnvelope.name}
              </Text>
            </div>
            {evaluationEvent?.status === 'active' && evaluationEnvelope.status !== 'complete' && evaluationUserInSession?.role === 'owner' && (
              <Button
                variant="secondary-dark"
                state="translucent"
                size="sm"
                onClick={() => openFullModal(<EvaluationEventPanelUpdateFullModal evaluationEnvelope={evaluationEnvelope} />)}
              >
                Edit
              </Button>
            )}
          </TableHeader>
          <Table columns={columns} />
        </div>
      ))}
    </Card>
  )
})
