import { useEffect, useState } from 'react'
import { forEach, includes, map } from 'lodash'
import { TenderValidationModel } from '@cotiss/tender'

// Planning step
const PLANNING_SUMMARY_TASKS = ['title', 'summary', 'tender.tenderType', 'enableConflictOfInterest'] as const
const PLANNING_EVALUATION_TASKS = ['tender.evaluationPanelType', 'accessControls.tender:chair', 'accessControls.tender:evaluator'] as const
const PLANNING_METHODOLOGY_TASKS = [
  'tenderCriteria',
  'tenderCriteria.weight',
  'tenderCriteria.name',
  'tender.ratingScale',
  'tender.evaluationMethodology',
  'tender.isTwoEnvelope',
  'tender.isPriceWeighted',
  'tender.criteriaWeightingsVisible',
] as const
const PLANNING_APPROVAL_TASKS = [
  'accessControls.tender:approval:go-to-market',
  'accessControls.tender:approval:planning',
  'accessControls.tender:approval:recommendation',
] as const

// Planned step
const PLANNED_DOCUMENT_TASKS = ['attachments'] as const
const PLANNED_QUESTION_TASKS = ['tenderCriteriaQuestion'] as const
const PLANNED_NON_PRICE_DOCUMENT_TASKS = ['nonPriceAttachments'] as const
const PLANNED_DETAILS_TASKS = [
  'title',
  'tenderPeriod.endDate',
  'briefDescription',
  'gracePeriod',
  'description',
  'visibility',
  'tender.regions',
  'tender.categories',
  'tender.canViewResponsesBeforeClose',
  'forumCloseDate.endDate',
] as const
const PLANNED_PRICE_DOCUMENT_TASKS = [
  'tender.priceTableEnabled',
  'currency',
  'npv',
  'priceTableEnableBuyerItems',
  'tenderPriceItem',
  'priceTableAllowAdditionalItems',
  'priceTableAllowQuantityChanges',
] as const

export const useTenderValidationSummary = (validation?: TenderValidationModel) => {
  const [planningSummaryTasks, setPlanningSummaryTasks] = useState<(typeof PLANNING_SUMMARY_TASKS)[number][]>([])
  const [planningEvaluationTasks, setPlanningEvaluationTasks] = useState<(typeof PLANNING_EVALUATION_TASKS)[number][]>([])
  const [planningMethodologyTasks, setPlanningMethodologyTasks] = useState<(typeof PLANNING_METHODOLOGY_TASKS)[number][]>([])
  const [planningApprovalTasks, setPlanningApprovalTasks] = useState<(typeof PLANNING_APPROVAL_TASKS)[number][]>([])

  const [plannedDetailsTasks, setPlannedDetailsTasks] = useState<(typeof PLANNED_DETAILS_TASKS)[number][]>([])
  const [plannedDocumentTasks, setPlannedDocumentTasks] = useState<(typeof PLANNED_DOCUMENT_TASKS)[number][]>([])
  const [plannedQuestionTasks, setPlannedQuestionsTasks] = useState<(typeof PLANNED_QUESTION_TASKS)[number][]>([])
  const [plannedPriceDocumentTasks, setPlannedPriceDocumentTasks] = useState<(typeof PLANNED_PRICE_DOCUMENT_TASKS)[number][]>([])
  const [plannedNonPriceDocumentTasks, setPlannedNonPriceDocumentTasks] = useState<(typeof PLANNED_NON_PRICE_DOCUMENT_TASKS)[number][]>([])

  useEffect(() => {
    if (!validation) {
      return
    }

    setPlanningSummaryTasks([])
    setPlanningEvaluationTasks([])
    setPlanningMethodologyTasks([])
    setPlanningApprovalTasks([])

    setPlannedQuestionsTasks([])
    setPlannedDetailsTasks([])
    setPlannedDocumentTasks([])
    setPlannedPriceDocumentTasks([])
    setPlannedNonPriceDocumentTasks([])

    const validationErrorNames = map(validation.validationErrors, ({ name }) => name)

    if (validation.currentStage === 'planning') {
      forEach(PLANNING_SUMMARY_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlanningSummaryTasks((tasks) => [...tasks, task])
        }
      })
      forEach(PLANNING_EVALUATION_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlanningEvaluationTasks((tasks) => [...tasks, task])
        }
      })
      forEach(PLANNING_METHODOLOGY_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlanningMethodologyTasks((tasks) => [...tasks, task])
        }
      })
      forEach(PLANNING_APPROVAL_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlanningApprovalTasks((tasks) => [...tasks, task])
        }
      })
      forEach(PLANNED_DETAILS_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlannedDetailsTasks((tasks) => [...tasks, task])
        }
      })

      forEach(PLANNED_DOCUMENT_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlannedDocumentTasks((tasks) => [...tasks, task])
        }
      })

      forEach(PLANNED_QUESTION_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlannedQuestionsTasks((tasks) => [...tasks, task])
        }
      })

      forEach(PLANNED_PRICE_DOCUMENT_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlannedPriceDocumentTasks((tasks) => [...tasks, task])
        }
      })

      forEach(PLANNED_NON_PRICE_DOCUMENT_TASKS, (task) => {
        if (includes(validationErrorNames, task)) {
          setPlannedNonPriceDocumentTasks((tasks) => [...tasks, task])
        }
      })
    }
  }, [validation])

  return {
    planningSummaryTasks,
    planningEvaluationTasks,
    planningMethodologyTasks,
    planningApprovalTasks,
    plannedDetailsTasks,
    plannedDocumentTasks,
    plannedQuestionTasks,
    plannedPriceDocumentTasks,
    plannedNonPriceDocumentTasks,
  }
}
