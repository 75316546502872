import React, { memo } from 'react'
import { find, groupBy, map, sortBy } from 'lodash'
import { useDownloadReport } from '@cotiss/report'
import { useListPriceItemResponse } from '@cotiss/price-item-response'
import { EvaluationListItemCta, EvaluationModel, useListEvaluation } from '@cotiss/evaluation'
import { RATING_SCALE_VALUE_MAP, TenderEvaluationPanelType, useGetTender } from '@cotiss/tender'
import { Button, Icon, ScrollableTable, ScrollableTableColumn, Text, datetimeService, utilService } from '@cotiss/common'
import {
  TenderResponsePriceScoreConsensusStatusBadge,
  TenderResponseReportModel,
  useListTenderResponse,
  useListTenderResponseReport,
} from '@cotiss/tender-response'

type BaseProps = {
  className?: string
  tenderId: string
  isScoringEnabled?: boolean
}

type SelfEvaluationProps = BaseProps & {
  evaluationType: Extract<TenderEvaluationPanelType, 'self'>
  onView?: (evaluation: EvaluationModel) => void
}

type PanelEvaluationProps = BaseProps & {
  evaluationType: Extract<TenderEvaluationPanelType, 'panel'>
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

type Props = SelfEvaluationProps | PanelEvaluationProps

export const EvaluationListPrice = memo(({ className, evaluationType, isScoringEnabled, onView, tenderId }: Props) => {
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { downloadPricingTableReport, isDownloading } = useDownloadReport(tenderId)
  const { evaluations, isLoading: isEvaluationsLoading } = useListEvaluation({ tenderId })
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId })
  const { priceItemResponses, isLoading: isLoadingPriceItemResponses } = useListPriceItemResponse({ tenderId })
  const { tenderResponseReports, isLoading: isTenderResponseReportsLoading } = useListTenderResponseReport(
    // Disable query for self-evaluation
    evaluationType === 'panel' ? tenderId : undefined
  )

  const isLoading =
    isTenderLoading ||
    isTenderResponsesLoading ||
    isLoadingPriceItemResponses ||
    isEvaluationsLoading ||
    (evaluationType === 'panel' ? isTenderResponseReportsLoading : false)

  const sortedPriceItemResponses = sortBy(priceItemResponses, ['priceItem.index', 'tenderResponse', 'index'])

  const groupedPriceItemResponses = map(
    Object.values(groupBy(sortedPriceItemResponses, (item) => (item.priceItem ? item.priceItem._id : item._id))),
    (priceItemResponses) => {
      const isBuyerItem = Boolean(priceItemResponses[0].priceItem)

      if (isBuyerItem) {
        return {
          category: priceItemResponses[0].priceItem?.category,
          description: priceItemResponses[0].priceItem?.description,
          unit: priceItemResponses[0].priceItem?.unit,
          items: priceItemResponses,
        }
      }

      return {
        category: priceItemResponses[0].category,
        description: priceItemResponses[0].description,
        unit: priceItemResponses[0].unit,
        items: priceItemResponses,
      }
    }
  )

  const maxRatingScaleScore = tender?.ratingScale ? RATING_SCALE_VALUE_MAP[tender.ratingScale].max : 10

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Category',
      rows: [
        {
          tdClassName: 'h-20',
          content: () => (
            <Text className="truncate uppercase" variant="light" size="xs">
              Description
            </Text>
          ),
        },
        {
          content: () => (
            <Text className="truncate uppercase" variant="light" size="xs">
              Unit
            </Text>
          ),
        },
        ...map(tenderResponses, (tenderResponse) => {
          const evaluation = find(evaluations, { tenderResponse: tenderResponse._id })
          const tenderResponseReport = find(tenderResponseReports, { _id: tenderResponse._id })

          const onViewCTA = () => {
            if (!onView) return

            if (evaluationType === 'panel' && tenderResponseReport) {
              return onView(tenderResponseReport)
            }

            if (evaluationType === 'self' && evaluation) {
              return onView(evaluation)
            }
          }

          return {
            tdClassName: 'h-[8.3125rem]',
            content: () => (
              <div className="truncate" title={tenderResponse.procurementResponse.supplier.name}>
                <Text className="truncate font-semibold mb-2" font="jakarta">
                  {tenderResponse.procurementResponse.supplier.name}
                </Text>
                <Text variant="light" size="sm" className="truncate mb-1">
                  Total Price:{' '}
                  {tenderResponse.totalPrice?.amount ? (
                    <span className="text-gray-700">
                      {utilService.formatAsCurrency(tenderResponse.totalPrice?.amount, tenderResponse.totalPrice?.currency)}
                    </span>
                  ) : (
                    '-'
                  )}
                </Text>
                {tender?.isPriceWeighted && (
                  <Text variant="light" size="sm" className="truncate mb-1">
                    Price score:{' '}
                    {tenderResponse.priceScore ? (
                      <span className="text-gray-700">{`${tenderResponse.priceScore.toFixed(2)} / ${maxRatingScaleScore}`}</span>
                    ) : (
                      '-'
                    )}
                  </Text>
                )}
                {evaluationType === 'panel' && (
                  <Text variant="light" size="sm" className="truncate mb-1">
                    Total score:{' '}
                    {tenderResponseReport?.totalScore ? (
                      <span className="text-gray-700">{`${tenderResponseReport?.totalScore.toFixed(2)} / ${maxRatingScaleScore}`}</span>
                    ) : (
                      '-'
                    )}
                  </Text>
                )}
                {tenderResponse.priceScoreConsensusStatus && (
                  <TenderResponsePriceScoreConsensusStatusBadge status={tenderResponse.priceScoreConsensusStatus} />
                )}
              </div>
            ),
            cta: (
              <EvaluationListItemCta
                onView={onViewCTA}
                organisationId={tenderResponse.procurementResponse.supplier._id}
                tenderResponse={tenderResponse}
                isScoringEnabled={isScoringEnabled}
              />
            ),
          }
        }),
      ],
    },
  ]

  const columns: ScrollableTableColumn[] = [
    ...map(groupedPriceItemResponses, (groupedPriceItemResponse) => {
      const { category, description, unit, items } = groupedPriceItemResponse

      return {
        heading: (
          <Text variant="light" className="truncate w-36" size="xs" title={category}>
            {category}
          </Text>
        ),
        rows: [
          {
            tdClassName: 'h-20',
            content: () => (
              <Text size="xs" title={description} className="w-40 whitespace-pre-wrap line-clamp-3">
                {description}
              </Text>
            ),
          },
          {
            content: () => <Text size="sm">{unit}</Text>,
          },
          ...map(tenderResponses, (tenderResponse) => {
            const priceItemResponse = items.find((item) => item.tenderResponse === tenderResponse._id)

            const quantity = priceItemResponse?.quantity ?? priceItemResponse?.priceItem?.quantity
            const unitPrice = priceItemResponse?.unitPrice
            const itemTotal = quantity !== undefined && unitPrice !== undefined ? quantity * unitPrice : null

            return {
              tdClassName: 'h-[8.3125rem]',
              content: () => (
                <>
                  {tenderResponse._id === priceItemResponse?.tenderResponse && (
                    <>
                      <Text size="sm">{itemTotal !== null ? utilService.formatAsCurrency(itemTotal, tender?.currency) : '-'}</Text>
                      <Text size="sm" variant="light">{`(${quantity} * ${
                        unitPrice !== undefined ? utilService.formatAsCurrency(unitPrice, tender?.currency) : 'N/A'
                      })`}</Text>
                      {tender?.priceTableRequirePaymentDate && (
                        <Text size="sm" variant="light" className="mt-2">
                          {priceItemResponse?.paymentDate
                            ? `Payment date: ${datetimeService.format(priceItemResponse.paymentDate, 'do MMM yyyy')}`
                            : '-'}
                        </Text>
                      )}
                    </>
                  )}
                  {tenderResponse._id !== priceItemResponse?.tenderResponse && (
                    <Text size="sm" variant="light">
                      -
                    </Text>
                  )}
                </>
              ),
            }
          }),
        ],
      }
    }),
  ]

  return (
    <div>
      <div className="flex items-center justify-between bg-white p-4 border border-b-0 border-gray-200">
        <Text className="truncate font-semibold" font="jakarta">
          Price submissions table
        </Text>
        <Button onClick={downloadPricingTableReport} size="sm" variant="secondary" state="translucent" isDisabled={isDownloading || isLoading}>
          <Icon className="mr-1" icon="download-01" />
          Export raw
        </Button>
      </div>
      <ScrollableTable className={className} fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
    </div>
  )
})
