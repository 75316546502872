import { map } from 'lodash'
import React, { memo } from 'react'
import { EvaluationEventScoringAccessList, useEvaluationEnvelope } from '@cotiss/evaluation-event'

export const EvaluationEventSummaryScoring = memo(() => {
  const { evaluationEnvelopes } = useEvaluationEnvelope()

  return (
    <>
      {map(evaluationEnvelopes, (evaluationEnvelope) => (
        <EvaluationEventScoringAccessList key={evaluationEnvelope.id} className="mt-4" evaluationEnvelope={evaluationEnvelope} />
      ))}
    </>
  )
})
