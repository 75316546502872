import React, { FormEvent, memo, useState } from 'react'
import { TenderResponsePopulatedModel, useMutateTenderResponse } from '@cotiss/tender-response'
import { Form, Label, ModalContent, ModalFooter, ModalHeader, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
}

export const TenderResponseReviewExcludeModal = memo(({ tenderResponse }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const [reviewJustification, setReviewJustification] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, { reviewStatus: 'excluded', reviewJustification })
      closeModal()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Exclude response" isDisabled={isSaving} />
      <ModalContent>
        <Label>Provide a reason for this exclusion</Label>
        <TextArea
          value={reviewJustification}
          onChange={({ target }) => setReviewJustification(target.value)}
          placeholder="Give your reason here"
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
