import { gql } from '@apollo/client'
import { PAGINATION_FRAGMENT, apolloService } from '@cotiss/common'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationEnvelopeListInput, GqlEvaluationEnvelopeListQuery } from '@gql'

export const queryEvaluationEnvelopeList = async (input: GqlEvaluationEnvelopeListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_ENVELOPE_FRAGMENT}

      query EvaluationEnvelopeList($input: EvaluationEnvelopeListInput!) {
        evaluationEnvelopeList(input: $input) {
          items {
            ...EvaluationEnvelopeFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeList
}
