import React, { memo, useEffect, useState } from 'react'
import { useAuth } from '@cotiss/auth'
import { useHistory, useParams } from 'react-router-dom'
import { COLOUR, Page, Spinner, Text, localStorageService, routerService, sentryService } from '@cotiss/common'

export const MasqueradePage = memo(() => {
  const { push } = useHistory()
  const [error, setError] = useState('')
  const { masquerade, logout } = useAuth()
  const { token } = useParams<{ token: string }>()

  useEffect(() => {
    logout()

    handleMasquerade(token)
  }, [])

  const handleMasquerade = async (token: string) => {
    try {
      await masquerade(token)
      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setError(error.message)
    }
  }

  return (
    <Page className="flex items-center justify-center">
      <div>
        <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        {Boolean(error) && (
          <Text className="text-sm my-4" variant="danger">
            {error}
          </Text>
        )}
      </div>
    </Page>
  )
})
