import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@cotiss/common'
import {
  ProcurementResponseListPage,
  ProcurementResponseViewPage,
  ProcurementResponseViewProcurementResponseTeamPage,
} from '@cotiss/procurement-response'

export const ProcurementResponseLayout = memo(() => (
  <Switch>
    <Route path="/procurement-response/list" exact>
      <ProcurementResponseListPage />
    </Route>
    <Route path="/procurement-response/view/:procurementResponseId/team" exact>
      <ProcurementResponseViewProcurementResponseTeamPage />
    </Route>
    <Route path="/procurement-response/view/:procurementResponseId/:tab?/:nestedTab?" exact>
      <ProcurementResponseViewPage />
    </Route>
    <Redirect from="/procurement-response" to="/procurement-response/list" exact />
  </Switch>
))
