import { every, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useApprovalTemplate } from '@cotiss/approval-template'
import { GqlApprovalTemplateFieldsFragment, GqlApprovalTemplateModuleType } from '@gql'
import { ApprovalTemplateGroupApprovalTemplateListItem, useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { Banner, Button, Drawer, EditableField, Hr, Icon, Text, sentryService, useAsyncEffect, useCallout, useToast } from '@cotiss/common'
import { SettingsModulesApprovalTemplateCreateUpdateModal, SettingsModulesApprovalTemplateGroupUpdateModal } from '@cotiss/settings'

type Props = {
  approvalTemplateGroupId: string
  module: GqlApprovalTemplateModuleType
  isNew?: boolean
  isEditable?: boolean
}

export const SettingsModulesApprovalTemplateGroupUpdateDrawer = memo(({ approvalTemplateGroupId, module, isNew, isEditable }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const { queryApprovalTemplateList } = useApprovalTemplate()
  const { approvalTemplateGroup, queryApprovalTemplateGroupView } = useApprovalTemplateGroup()
  const [approvalTemplates, setApprovalTemplates] = useState<GqlApprovalTemplateFieldsFragment[]>([])

  const isAllOptionalBannerVisible = useMemo(() => {
    return Boolean(approvalTemplates.length && every(approvalTemplates, 'isOptional'))
  }, [approvalTemplates])

  const handleRefresh = async () => {
    try {
      // TODO: We need to think about pagination here.
      const [{ approvalTemplates }] = await Promise.all([
        queryApprovalTemplateList({ filter: { approvalTemplateGroupId } }),
        queryApprovalTemplateGroupView({ approvalTemplateGroupId }),
      ])

      setApprovalTemplates(approvalTemplates)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  useAsyncEffect(async () => {
    setIsLoading(true)
    await handleRefresh()
    setIsLoading(false)
  }, [approvalTemplateGroupId])

  const renderEmptyApprovalTemplateListPlaceholder = () => (
    <div className="mt-6 p-6 group flex items-center justify-between bg-gray-50 border border-gray-200 rounded-lg cursor-default w-full">
      <Button
        onClick={() =>
          openModal(
            <SettingsModulesApprovalTemplateCreateUpdateModal
              approvalTemplateGroupId={approvalTemplateGroupId}
              module={module}
              onUpdate={handleRefresh}
            />
          )
        }
        state="text"
        variant="secondary"
        isDisabled={isLoading || !isEditable}
      >
        + Add approval type
      </Button>
    </div>
  )

  const renderHeader = () => (
    <Text className="font-medium mr-2" size="h5" font="jakarta">
      {isNew ? 'Add' : 'Edit'} approval sequence
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <EditableField
        label="Name"
        textValue={approvalTemplateGroup?.name}
        onClick={() =>
          approvalTemplateGroup &&
          openModal(<SettingsModulesApprovalTemplateGroupUpdateModal label="Name" field="name" approvalTemplateGroup={approvalTemplateGroup} />)
        }
        isDisabled={isLoading || !isEditable}
      />
      <EditableField
        label="Description"
        textValue={approvalTemplateGroup?.description}
        onClick={() =>
          approvalTemplateGroup
            ? openModal(
                <SettingsModulesApprovalTemplateGroupUpdateModal
                  label="Description"
                  field="description"
                  approvalTemplateGroup={approvalTemplateGroup}
                />
              )
            : undefined
        }
        isDisabled={isLoading || !isEditable}
      />
      <Hr className="mt-8 mb-4" />
      <div className="flex items-center justify-between">
        <div>
          <Text className="font-medium">Approval sequence</Text>
          <Text variant="light" size="sm">
            Input a number to adjust the sequence of approvals
          </Text>
        </div>
        <Button
          size="xs"
          variant="tertiary"
          onClick={() =>
            openModal(
              <SettingsModulesApprovalTemplateCreateUpdateModal
                approvalTemplateGroupId={approvalTemplateGroupId}
                module={module}
                onUpdate={handleRefresh}
              />
            )
          }
          isDisabled={isLoading || !isEditable}
        >
          <Icon icon="plus" /> Add approval type
        </Button>
      </div>
      {isAllOptionalBannerVisible && (
        <Banner icon="alert-circle" className="my-4" variant="danger">
          <div>
            <Text className="font-medium" variant="heading">
              All approval steps are optional
            </Text>
            <Text size="sm">Contract owners will be required to select at least one approver when sending for approval.</Text>
          </div>
        </Banner>
      )}
      {!approvalTemplates.length && renderEmptyApprovalTemplateListPlaceholder()}
      {Boolean(approvalTemplates.length) && (
        <div className="mt-6">
          <AnimatePresence mode="sync">
            {map(approvalTemplates, (approvalTemplate) => (
              <div key={approvalTemplate.id} className="flex items-center justify-between w-full">
                <motion.div className="w-full" animate={{ x: 0, opacity: 1 }} exit={{ x: '-20px', opacity: 0 }} transition={{ type: 'tween' }} layout>
                  <ApprovalTemplateGroupApprovalTemplateListItem
                    approvalTemplate={approvalTemplate}
                    module={module}
                    onUpdate={handleRefresh}
                    isEditable={isEditable}
                  />
                </motion.div>
              </div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </Drawer>
  )
})
