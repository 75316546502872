import React, { memo, useEffect } from 'react'
import { NoDataPlaceholder } from '@cotiss/common'
import { usePlanEventAnalytics } from '@cotiss/plan-event'

export const PlanEventViewDetailsTab = memo(() => {
  const { track } = usePlanEventAnalytics()

  useEffect(() => {
    track('plan_event_view_details_tab_view')
  }, [])

  return (
    <>
      <NoDataPlaceholder label="You have not published any plans yet. Once you do, it will display here." />
    </>
  )
})
