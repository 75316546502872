import React from 'react'
import { TransitionContainer, Text } from '@cotiss/common'
import { AccountEmployeeNumberRange } from '@cotiss/account'
import { SupplierOnboardingRadioButton } from '@cotiss/supplier'

type Props = {
  onChange: (teamSize: AccountEmployeeNumberRange) => void
  teamSize: AccountEmployeeNumberRange
  isSaving?: boolean
}

export const SupplierOnboardingStepTeamSize = ({ onChange, teamSize, isSaving }: Props) => (
  <TransitionContainer transition="right">
    <Text size="h4">How many people are in your team?</Text>
    <div className="flex items-center mt-6">
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="supplier-team-size"
        value="1-50"
        onChange={onChange}
        isSelected={teamSize === '1-50'}
        isDisabled={isSaving}
        isRequired
      >
        1-50
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="supplier-team-size"
        value="50-100"
        onChange={onChange}
        isSelected={teamSize === '50-100'}
        isDisabled={isSaving}
        isRequired
      >
        50-100
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="supplier-team-size"
        value="100-300"
        onChange={onChange}
        isSelected={teamSize === '100-300'}
        isDisabled={isSaving}
        isRequired
      >
        100-300
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="supplier-team-size"
        value="300+"
        onChange={onChange}
        isSelected={teamSize === '300+'}
        isDisabled={isSaving}
        isRequired
      >
        300+
      </SupplierOnboardingRadioButton>
    </div>
  </TransitionContainer>
)
