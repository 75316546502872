import React from 'react'
import { TransitionContainer, Text } from '@cotiss/common'
import { SupplierOnboardingRadioButton } from '@cotiss/supplier'
import { PreferencesEmailNotificationPeriod } from '@cotiss/preferences'

type Props = {
  onChange: (emailNotificationPeriod: PreferencesEmailNotificationPeriod) => void
  emailNotificationPeriod: PreferencesEmailNotificationPeriod
  isSaving?: boolean
}

export const SupplierOnboardingStepTenderNotifications = ({ onChange, emailNotificationPeriod, isSaving }: Props) => (
  <TransitionContainer transition="right">
    <Text size="h4">How often do you want to get notified about new tenders?</Text>
    <div className="flex items-center mt-6">
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="email-notification-period"
        value="hourly"
        onChange={onChange}
        isSelected={emailNotificationPeriod === 'hourly'}
        isDisabled={isSaving}
        isRequired
      >
        Hourly
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="email-notification-period"
        value="daily"
        onChange={onChange}
        isSelected={emailNotificationPeriod === 'daily'}
        isDisabled={isSaving}
        isRequired
      >
        Daily
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="email-notification-period"
        value="weekly"
        onChange={onChange}
        isSelected={emailNotificationPeriod === 'weekly'}
        isDisabled={isSaving}
        isRequired
      >
        Weekly
      </SupplierOnboardingRadioButton>
      <SupplierOnboardingRadioButton
        className="mr-4"
        name="email-notification-period"
        value="never"
        onChange={onChange}
        isSelected={emailNotificationPeriod === 'never'}
        isDisabled={isSaving}
        isRequired
      >
        Never
      </SupplierOnboardingRadioButton>
    </div>
  </TransitionContainer>
)
