import { gql } from '@apollo/client'

export const EVALUATION_SCORE_FRAGMENT = gql`
  fragment EvaluationScoreFields on EvaluationScore {
    id
    evaluationId
    evaluationCriteriaId
    value
    comment
    moderationValue
    moderationComment
    ratingScale
    createdAt
    updatedAt
    deletedAt
  }
`
