import classNames from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import { Button, Dropdown, DropdownContent, Icon, Text, datetimeService } from '@cotiss/common'
import { DocumentModel, useGetDocument } from '@cotiss/document'

type Props = {
  className?: string
  document: DocumentModel
  onClick?: (document: DocumentModel) => void
  onRemove?: (id: string) => void
  isActive?: boolean
}

export const DocumentButton = memo(({ className, document, onClick, onRemove, isActive }: Props) => {
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const classes = classNames(className, 'flex items-stretch justify-between rounded-lg w-full', {
    'bg-primary-50': !isActive,
    'bg-white shadow-lg': isActive,
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  return (
    <div className={classes}>
      <Button className="flex items-center truncate p-3" onClick={() => onClick && onClick(document)} state="raw">
        <div className="inline-flex items-center justify-center shrink-0 bg-gray-200 rounded w-6 h-6 mr-2">
          <Icon icon="file-04" variant="link" />
        </div>
        <div className="truncate">
          <Text className="truncate mr-1" size="sm" variant="link">
            {document.fileName}
          </Text>
          <Text size="sm" variant="light">
            {datetimeService.format(document.createdAt, 'do MMM yyyy')}
          </Text>
        </div>
      </Button>
      {onRemove ? (
        <div className="relative inline-flex items-center justify-center shrink-0 w-10 hover:bg-primary-50">
          <Button className="inline-flex " onClick={() => setIsDropdownOpen(true)} state="raw">
            <Icon icon="dots" variant="light" size={20} />
          </Button>
          <Dropdown className="top-8 left-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
            <DropdownContent className="flex items-center" onClick={() => setDocumentIdToDownload(document._id)}>
              Download
            </DropdownContent>
            <DropdownContent className="flex items-center" onClick={() => onRemove(document._id)}>
              Delete
            </DropdownContent>
          </Dropdown>
        </div>
      ) : (
        <Button
          className="inline-flex items-center justify-center shrink-0 w-10 hover:bg-primary-50"
          onClick={() => setDocumentIdToDownload(document._id)}
          state="raw"
        >
          <Icon icon="download" variant="light" size={20} />
        </Button>
      )}
    </div>
  )
})
