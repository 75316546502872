import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT = gql`
  fragment EvaluationEnvelopeOverviewCriteriaBreakdownFields on EvaluationEnvelopeOverviewCriteriaBreakdown {
    evaluationCriteriaId
    evaluationSubmissionId
    rawScoreSummary {
      totalScore
      averageScore
      medianScore
      averagePercentageScore
      averageWeightedPercentageScore
      averageEnvelopeWeightedPercentageScore
      averageOverallWeightedPercentageScore
    }
    moderatedScoreSummary {
      totalScore
      averageScore
      medianScore
      averagePercentageScore
      averageWeightedPercentageScore
      averageEnvelopeWeightedPercentageScore
      averageOverallWeightedPercentageScore
    }
  }
`
