import { gql } from '@apollo/client'

export const EVALUATION_USER_FRAGMENT = gql`
  fragment EvaluationUserFields on EvaluationUser {
    id
    evaluationEventId
    userId
    role
    user {
      ...UserFields
    }
    accessControls {
      ...EvaluationAccessControlFields
    }
    createdAt
    updatedAt
    deletedAt
  }
`
