import { filter, find, flatMap, forEach, groupBy, keyBy, map, some } from 'lodash'
import React, { FormEvent, Fragment, memo, useEffect, useMemo, useState } from 'react'
import { GqlEvaluationUserFieldsFragment } from '@gql'
import { UserLineItem, useGetLoggedInUser } from '@cotiss/user'
import {
  Form,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  sentryService,
  useCallout,
  useToast,
  Select_DEPRECATED,
  SelectOption_DEPRECATED,
} from '@cotiss/common'
import {
  EvaluationEventPanelAccessOption,
  evaluationEventService,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationUser,
} from '@cotiss/evaluation-event'

type FormData = {
  access: Record<string, EvaluationEventPanelAccessOption>
}

type Props = {
  evaluationUser: GqlEvaluationUserFieldsFragment
}

export const EvaluationEventPanelMemberUpdateModal = memo(({ evaluationUser }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const [formData, setFormData] = useState<FormData>({ access: {} })
  const { evaluationUsers, mutateUpdateEvaluationUserAccessControls } = useEvaluationUser()
  const { accessOptionsByEnvelopeId, criteriaByEnvelopeId } = useMemo(() => {
    const allAccessControls = flatMap(evaluationUsers, ({ accessControls }) => accessControls)

    const accessOptionsByEnvelopeId = keyBy(
      map(evaluationEnvelopes, ({ id: envelopeId }) => {
        const envelopeAccessControls = filter(allAccessControls, { resourceType: 'envelope', resourceId: envelopeId })
        const usersEnvelopeAccessControls = filter(envelopeAccessControls, { evaluationUserId: evaluationUser.id })
        const options: SelectOption_DEPRECATED<EvaluationEventPanelAccessOption>[] = [
          {
            value: 'no-access',
            label: 'No access',
          },
          {
            value: 'evaluate',
            label: 'Evaluate',
          },
        ]

        if (find(usersEnvelopeAccessControls, { access: 'moderate' }) || !find(envelopeAccessControls, { access: 'moderate' })) {
          options.push({ value: 'moderate', label: 'Moderate' })
          options.push({ value: 'both', label: 'Moderate and evaluate' })
        }

        return { envelopeId, options }
      }),
      'envelopeId'
    )

    const criteriaByEnvelopeId = groupBy(evaluationCriteria, 'evaluationEnvelopeId')

    return { accessOptionsByEnvelopeId, criteriaByEnvelopeId }
  }, [evaluationEvent, evaluationUsers, user])

  useEffect(() => {
    const access: Record<string, EvaluationEventPanelAccessOption> = {}

    forEach(evaluationEnvelopes, ({ id: envelopeId }) => {
      const envelopeAccesses = filter(evaluationUser.accessControls, { resourceType: 'envelope', resourceId: envelopeId })
      const hasEvaluateAccess = some(envelopeAccesses, { access: 'evaluate' })
      const hasModerateAccess = some(envelopeAccesses, { access: 'moderate' })

      if (hasEvaluateAccess && hasModerateAccess) {
        access[envelopeId] = 'both'
      } else if (hasEvaluateAccess) {
        access[envelopeId] = 'evaluate'
      } else if (hasModerateAccess) {
        access[envelopeId] = 'moderate'
      } else {
        access[envelopeId] = 'no-access'
      }
    })

    setFormData({ access })
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const accessControls = evaluationEventService.getAccessControlInput({ accessByEvaluationEnvelopeId: formData.access, criteriaByEnvelopeId })
      // NOTE: This will delete any existing access controls the user has, before adding the new ones. So as a side-effect, any existing evaluations,
      // scores, etc will be deleted. Right now, this mutation is only called in the setup wizard, before any evaluations or scores are created. But
      // if we ever introduce the ability to update the access controls after an evaluation event has started, then we will need to change this
      // mutation.
      await mutateUpdateEvaluationUserAccessControls({ evaluationUserId: evaluationUser.id, accessControls })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update panel member" />
      <ModalContent isScrollable>
        <UserLineItem {...evaluationUser.user} />
        {map(evaluationEnvelopes, ({ id, name, order }) => (
          <Fragment key={id}>
            <Label className="mt-6">
              Envelope {order} &quot;{name}&quot; access (optional)
            </Label>
            <Select_DEPRECATED
              value={formData.access[id]}
              options={accessOptionsByEnvelopeId[id].options}
              onChange={(access) => setFormData({ ...formData, access: { ...formData.access, [id]: access } })}
              isDisabled={isSaving}
              placeholder
            />
          </Fragment>
        ))}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
