import { gql } from '@apollo/client'

export const EVALUATION_FRAGMENT = gql`
  fragment EvaluationFields on Evaluation {
    id
    evaluationEventId
    evaluationUserId
    evaluationEnvelopeId
    evaluationSubmissionId
    comment
    status
    createdAt
    updatedAt
    deletedAt
  }
`
