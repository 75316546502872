import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardUserViewInput, GqlPerformanceScorecardUserViewQuery } from '@gql'

export const queryPerformanceScorecardUserView = async (input: GqlPerformanceScorecardUserViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserView($input: PerformanceScorecardUserViewInput!) {
        performanceScorecardUserView(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserView
}
