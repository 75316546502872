import { useParams } from 'react-router-dom'
import { find, forEach, map, sortBy } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { AppErrorPage } from '@cotiss/app'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment } from '@gql'
import {
  PageContent,
  Card,
  CardHeader,
  Text,
  sentryService,
  useAsyncEffect,
  utilService,
  Icon,
  Banner,
  Button,
  useToast,
  routerService,
  Spinner,
  COLOUR,
} from '@cotiss/common'
import {
  evaluationEventService,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  EvaluationEventModerationParentCriteriaItem,
  EvaluationEventModerationCriteriaReview,
  useEvaluationEnvelopeSubmission,
} from '@cotiss/evaluation-event'

type HandleSelectParam = {
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  activeEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export const EvaluationEventModerateEnvelopeSubmissionResultsTab = memo(() => {
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [isExpandedMap, setIsExpandedMap] = useState<Record<string, boolean>>({})
  const [parentEvaluationCriteria, setParentEvaluationCriteria] = useState<GqlEvaluationCriteriaFieldsFragment[]>([])
  const { evaluationEnvelope, evaluationEnvelopeOverview, queryEvaluationEnvelopeOverviewView } = useEvaluationEnvelope()
  const [parentEvaluationCriteriaItem, setParentEvaluationCriteriaItem] = useState<GqlEvaluationCriteriaFieldsFragment | null>(null)
  const [activeEvaluationCriteriaItem, setActiveEvaluationCriteriaItem] = useState<GqlEvaluationCriteriaFieldsFragment | null>(null)
  const { mutateCreateEvaluationEnvelopeSubmission, mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId } =
    useEvaluationEnvelopeSubmission()
  const { evaluationEventId, evaluationEnvelopeId, evaluationSubmissionId } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationSubmissionId: string
  }>()

  const { submissionBreakdown, parentCriteriaWeightPercentageById } = useMemo(() => {
    const submissionBreakdown = find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId })
    const weightById = evaluationEventService.getWeightById({ items: parentEvaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const parentCriteriaWeightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    return { submissionBreakdown, parentCriteriaWeightPercentageById }
  }, [parentEvaluationCriteria, evaluationEnvelopeOverview])

  const getIsExpandedMap = (parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
    const isExpandedMap: Record<string, boolean> = {}

    forEach(parentEvaluationCriteria, ({ id, isScored }) => {
      // If the criteria is scored, then we know that it does not have sub-criteria.
      if (!isScored) {
        isExpandedMap[id] = false
      }
    })

    return isExpandedMap
  }

  useAsyncEffect(async () => {
    try {
      // TODO: We may have to consider how to paginate this list.
      const { evaluationCriteria } = await queryEvaluationCriteriaList({
        filter: {
          evaluationEventId,
          evaluationEnvelopeId,
          parentEvaluationCriteriaId: null,
        },
      })

      if (!evaluationCriteria.length) {
        sentryService.captureException({ exception: 'No evaluation criteria.' })
        setIsError(true)
        return
      }

      const sortedParentCriteria = sortBy(evaluationCriteria, 'index')
      setParentEvaluationCriteria(sortedParentCriteria)

      const isExpandedMap = getIsExpandedMap(sortedParentCriteria)

      if (sortedParentCriteria[0].isScored) {
        setParentEvaluationCriteriaItem(sortedParentCriteria[0])
        setActiveEvaluationCriteriaItem(sortedParentCriteria[0])
      } else {
        isExpandedMap[sortedParentCriteria[0].id] = true
      }

      setIsExpandedMap(isExpandedMap)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }
  }, [])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  const handleToggleExpand = (parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => {
    if (isExpandedMap[parentEvaluationCriteriaItem.id]) {
      setIsExpandedMap({ ...isExpandedMap, [parentEvaluationCriteriaItem.id]: false })
      return
    }

    setIsExpandedMap({
      ...getIsExpandedMap(parentEvaluationCriteria),
      [parentEvaluationCriteriaItem.id]: true,
    })
  }

  const handleSelect = ({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem }: HandleSelectParam) => {
    if (parentEvaluationCriteriaItem.isScored) {
      setIsExpandedMap(getIsExpandedMap(parentEvaluationCriteria))
    }

    setParentEvaluationCriteriaItem(parentEvaluationCriteriaItem)
    setActiveEvaluationCriteriaItem(activeEvaluationCriteriaItem)
  }

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId({ evaluationEnvelopeId, evaluationSubmissionId })
      await queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const handleMarkAsReviewed = async () => {
    try {
      setIsSaving(true)
      await mutateCreateEvaluationEnvelopeSubmission({ evaluationEnvelopeId, evaluationSubmissionId, status: 'reviewed' })
      await queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const renderWeightedTotalDelta = (submissionBreakdown?: GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment) => {
    if (
      !submissionBreakdown?.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore ||
      !submissionBreakdown?.rawScoreSummary.averageEnvelopeWeightedPercentageScore ||
      submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore ===
        submissionBreakdown.rawScoreSummary.averageEnvelopeWeightedPercentageScore
    ) {
      return null
    }

    const delta =
      submissionBreakdown?.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore -
      submissionBreakdown?.rawScoreSummary.averageEnvelopeWeightedPercentageScore

    return (
      <Text className="shrink-0 ml-1" variant={delta > 0 ? 'success' : 'danger'}>
        <Icon icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
        {utilService.formatAsPercentage(delta * 100)}
      </Text>
    )
  }

  return (
    <PageContent>
      {/* TODO: Add skeleton loader */}
      <Banner
        className="mb-4"
        variant={submissionBreakdown?.status === 'reviewed' ? 'success' : 'secondary'}
        icon={submissionBreakdown?.status === 'reviewed' ? 'clipboard-check' : 'check-circle'}
      >
        <div className="mr-4">
          <Text className="font-semibold" variant="heading">
            Submission review
          </Text>
          <Text size="sm" variant="light">
            Once the scores for each criteria and sub-criteria have been reviewed, mark the submission as reviewed.
          </Text>
        </div>
        {submissionBreakdown && !submissionBreakdown.status && (
          <Button onClick={handleMarkAsReviewed} size="sm" variant="secondary" isLoading={isSaving}>
            Mark as reviewed
          </Button>
        )}
        {submissionBreakdown?.status === 'reviewed' && (
          <div className="flex items-center">
            <div className="flex items-center bg-white rounded p-2 mr-6">
              <Icon className="mr-2" icon="check-circle" variant="success" />
              <Text className="whitespace-nowrap mr-2">Marked as reviewed</Text>
              {!isSaving && evaluationEnvelope?.status === 'moderate' && (
                <Button onClick={handleEdit} state="text" variant="secondary">
                  <Icon icon="edit" />
                </Button>
              )}
              {isSaving && <Spinner colour={COLOUR.green[400]} />}
            </div>
            <Button
              href={routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
                evaluationEventId,
                evaluationEnvelopeId,
              })}
              state="text"
              variant="secondary"
              isLink
            >
              Back to list
            </Button>
          </div>
        )}
      </Banner>

      <Card>
        <CardHeader>
          <div>
            <Text font="jakarta" variant="heading" size="h7">
              Evaluation results
            </Text>
            <Text variant="light">
              As a group, review the scores for each question within each criteria. You have the option to adjust an evaluator&apos;s score.
            </Text>
          </div>
          <div className="whitespace-nowrap text-right ml-4">
            <Text size="sm" variant="light">
              Weighted total
            </Text>
            <Text className="flex items-center justify-end">
              {submissionBreakdown !== undefined
                ? utilService.formatAsPercentage(submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore * 100)
                : '--'}
              {renderWeightedTotalDelta(submissionBreakdown)}
            </Text>
          </div>
        </CardHeader>
        <div className="flex justify-between">
          <div className="shrink-0 border-r border-gray-200 w-1/3 pr-4">
            {map(parentEvaluationCriteria, (parentEvaluationCriteriaItem) => (
              <EvaluationEventModerationParentCriteriaItem
                key={parentEvaluationCriteriaItem.id}
                className="mb-3"
                parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                activeEvaluationCriteriaItem={activeEvaluationCriteriaItem}
                parentCriteriaWeightPercentageById={parentCriteriaWeightPercentageById}
                onSelect={handleSelect}
                onToggleExpand={handleToggleExpand}
                isExpanded={isExpandedMap[parentEvaluationCriteriaItem.id]}
              />
            ))}
          </div>
          <div className="w-full pl-4">
            {(!parentEvaluationCriteriaItem || !activeEvaluationCriteriaItem) && <Text>Please select a criteria to moderate.</Text>}
            {parentEvaluationCriteriaItem && activeEvaluationCriteriaItem && (
              <EvaluationEventModerationCriteriaReview
                parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                activeEvaluationCriteriaItem={activeEvaluationCriteriaItem}
              />
            )}
          </div>
        </div>
      </Card>
    </PageContent>
  )
})
