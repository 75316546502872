import { GqlCreateEvaluationAccessControlsInput, GqlDeleteEvaluationAccessControlsInput } from '@gql'
import { mutateCreateEvaluationAccessControls, mutateDeleteEvaluationAccessControls } from '@cotiss/evaluation-event'

export const useEvaluationAccessControl = () => {
  return {
    mutateCreateEvaluationAccessControls: async (input: GqlCreateEvaluationAccessControlsInput) => {
      await mutateCreateEvaluationAccessControls(input)
    },
    mutateDeleteEvaluationAccessControls: async (input: GqlDeleteEvaluationAccessControlsInput) => {
      await mutateDeleteEvaluationAccessControls(input)
    },
  }
}
