import classNames from 'classnames'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { every, filter, find, forEach, map, some, sortBy } from 'lodash'
import {
  EvaluationEventCriteriaCreateUpdateModal,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'
import {
  Button,
  Card,
  CardHeader,
  ConfirmModal,
  Table,
  TableColumn,
  TableHeader,
  TableRowCta,
  Text,
  sentryService,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardCriteriaStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEnvelopes, queryEvaluationEnvelopeList } = useEvaluationEnvelope()
  const { evaluationCriteria, queryEvaluationCriteriaList, mutateDeleteEvaluationCriteria } = useEvaluationCriteria()

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_criteria_view')

    try {
      await queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
      await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [])

  const handleClickContinue = () => {
    if (!every(evaluationEnvelopes, ({ id: evaluationEnvelopeId }) => some(evaluationCriteria, { evaluationEnvelopeId }))) {
      setErrorText('Every envelope must have at least one criteria.')
      return
    }

    onNext()
  }

  const handleDelete = async (evaluationCriteriaId: string) => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_criteria_delete_submit')

    await mutateDeleteEvaluationCriteria({ evaluationCriteriaId })
    await queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
  }

  const tableColumnsByEnvelopeId = useMemo(() => {
    const tableColumnsByEnvelopeId: Record<string, TableColumn[]> = {}

    forEach(sortBy(evaluationEnvelopes, 'order'), ({ id: evaluationEnvelopeId }) => {
      const parentEvaluationCriteria = sortBy(filter(evaluationCriteria, { evaluationEnvelopeId, parentEvaluationCriteriaId: null }), 'index')

      const tableColumns: TableColumn[] = [
        {
          heading: 'Criteria',
          rows: map(parentEvaluationCriteria, (evaluationCriteriaItem) => ({
            content: () => (
              <Text>
                {evaluationCriteriaItem.index}. {evaluationCriteriaItem.content}
              </Text>
            ),
            cta: (
              <TableRowCta
                actions={[
                  {
                    label: 'Edit',
                    onClick: () =>
                      openModal(
                        <EvaluationEventCriteriaCreateUpdateModal
                          evaluationEnvelopeId={evaluationEnvelopeId}
                          evaluationCriteriaItem={evaluationCriteriaItem}
                        />
                      ),
                  },
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete criteria"
                          description="Are you sure you want to delete this criteria?"
                          onSubmit={() => handleDelete(evaluationCriteriaItem.id)}
                        />
                      ),
                  },
                ]}
              />
            ),
          })),
        },
      ]

      tableColumnsByEnvelopeId[evaluationEnvelopeId] = tableColumns
    })

    return tableColumnsByEnvelopeId
  }, [evaluationCriteria, evaluationEnvelopes])

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <div>
          <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
            Build your evaluation criteria
          </Text>
          <Text size="sm" variant="light">
            Every envelope must have at least 1 criteria
          </Text>
        </div>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading}>
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm" isDisabled={isLoading}>
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      {map(tableColumnsByEnvelopeId, (tableColumns, envelopeId) => {
        const envelope = find(evaluationEnvelopes, { id: envelopeId })

        if (!envelope) {
          return null
        }

        return (
          <div key={envelopeId} className={classNames({ 'mt-6': envelope.order > 1 })}>
            <TableHeader className="flex justify-between items-center">
              <div>
                <Text className="font-semibold" font="jakarta">
                  <Text className="mr-1" variant="light" isInline>
                    Envelope {envelope.order}:
                  </Text>
                  {envelope.name}
                </Text>
                <Text className="mt-1" size="sm" variant="light">
                  Add the criteria you will use to evaluate the aspects of the tender.
                </Text>
              </div>
              <Button
                className="ml-2"
                variant="secondary-dark"
                state="translucent"
                size="xs"
                onClick={() => openModal(<EvaluationEventCriteriaCreateUpdateModal evaluationEnvelopeId={envelopeId} />)}
              >
                + Add criteria
              </Button>
            </TableHeader>
            <Table
              isLoading={isLoading}
              columns={tableColumns}
              emptyCta={
                <Button
                  variant="secondary"
                  state="text"
                  size="sm"
                  onClick={() => openModal(<EvaluationEventCriteriaCreateUpdateModal evaluationEnvelopeId={envelopeId} />)}
                >
                  + Add criteria
                </Button>
              }
            />
          </div>
        )
      })}
    </Card>
  )
})
