import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { TaskListChairEvaluationCta } from '@cotiss/task'
import { useListChairEvaluation } from '@cotiss/chair-evaluation'
import { NoDataPlaceholder, ScrollableTable, ScrollableTableColumn, Text, datetimeService, paginationService } from '@cotiss/common'

export const ChairEvaluationTaskTab = memo(() => {
  const { user, isLoading: isLoggedInUserLoading, isError: isLoggedInUserError } = useGetLoggedInUser()
  const { chairEvaluations, isLoading: isEvaluationsLoading, isError: isEvaluationsError } = useListChairEvaluation({ userId: user?._id })
  const [currentPage, setCurrentPage] = useState(1)

  const { processedChairEvaluations, pagination } = useMemo(() => {
    const _chairEvaluations = map(chairEvaluations, (approval) => ({ type: 'approval', item: approval } as const))

    const { items: processedChairEvaluations, pagination } = paginationService.paginate(_chairEvaluations, { currentPage })

    return { processedChairEvaluations, pagination }
  }, [chairEvaluations, currentPage])

  if (isLoggedInUserError || isEvaluationsError) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  if (!(isLoggedInUserLoading || isEvaluationsLoading) && !processedChairEvaluations.length) {
    return <NoDataPlaceholder label="No evaluations" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Tender',
      rows: map(processedChairEvaluations, ({ item }) => ({
        content: () => (
          <Text className="truncate" font="jakarta" title={item.tender.title}>
            {item.tender.title}
          </Text>
        ),
        cta: <TaskListChairEvaluationCta tenderId={item.tender._id} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Procurement',
      rows: map(processedChairEvaluations, ({ item }) => ({
        content: () => <Text size="sm">{item.tender.procurement.title}</Text>,
      })),
    },
    {
      heading: 'Date Created',
      rows: map(processedChairEvaluations, ({ item }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(item.createdAt, 'd MMMM yyyy h:mm aaa')}
          </Text>
        ),
      })),
    },
  ]

  return (
    <ScrollableTable
      fixedColumns={fixedColumns}
      columns={columns}
      pagination={pagination}
      onPageChange={setCurrentPage}
      isLoading={isLoggedInUserLoading || isEvaluationsLoading}
    />
  )
})
