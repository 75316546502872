import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_SCORE_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationScoreInput,
  GqlUpdateEvaluationScoreMutation,
  GqlUpdateEvaluationScoreMutationVariables,
  GqlEvaluationScoreFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationScore = async (input: GqlUpdateEvaluationScoreInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationScoreMutation, GqlUpdateEvaluationScoreMutationVariables>({
    mutation: gql`
      ${EVALUATION_SCORE_FRAGMENT}

      mutation UpdateEvaluationScore($input: UpdateEvaluationScoreInput!) {
        updateEvaluationScore(input: $input) {
          ...EvaluationScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationScore as GqlEvaluationScoreFieldsFragment
}
