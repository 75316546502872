// NOTE: This is part of a DEPRECATED and UNUSED flow.

import { filter, map } from 'lodash'
import React, { memo, useState } from 'react'
import { Icon, Button, Dropdown, DropdownContent, useCallout, ConfirmModal, Text } from '@cotiss/common'
import { PriceItemAddUpdateModal, PriceItemModel, useListPriceItem, useMutatePriceItem } from '@cotiss/price-item'

type Props = {
  tenderId: string
  priceItem: PriceItemModel
}

export const TenderRequestPriceTableItemCta = memo(({ tenderId, priceItem }: Props) => {
  const { openModal } = useCallout()
  const { upsertPriceItemBulk } = useMutatePriceItem()
  const { priceItems } = useListPriceItem({ tenderId })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <>
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent onClick={() => openModal(<PriceItemAddUpdateModal tenderId={tenderId} priceItem={priceItem} />)}>
          <Text className="text-left">Edit item</Text>
        </DropdownContent>
        <DropdownContent
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Remove price item"
                description="Are you sure you want to remove this price item?"
                onSubmit={async () => {
                  const priceItemsToSave = filter(priceItems, ({ _id }) => _id !== priceItem._id)

                  await upsertPriceItemBulk({
                    tenderId,
                    items: map(priceItemsToSave, ({ _id, category, description, unit, quantity }, index) => ({
                      priceItemId: _id,
                      body: { description, category, unit, quantity, index },
                    })),
                  })
                }}
              />
            )
          }
        >
          <Text className="text-left">Remove item</Text>
        </DropdownContent>
      </Dropdown>
    </>
  )
})
