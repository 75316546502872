import classNames from 'classnames'
import React, { memo } from 'react'
import { Skeleton } from '@cotiss/common'

type Props = {
  className?: string
}

export const ForumQuestionSkeleton = memo(({ className }: Props) => (
  <Skeleton className={classNames(className, 'bg-gray-200 p-4')} variant="none">
    <Skeleton className="bg-primary-200 h-1.5 w-1/6" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-1/2 mt-3" variant="none" />
    <Skeleton className="bg-primary-200 h-2 w-1/3 mt-1" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-full mt-3" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-full mt-1" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-2/3 mt-1" variant="none" />
  </Skeleton>
))
