import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { TaskListPage } from '@cotiss/task'
import { Redirect, Route } from '@cotiss/common'

export const TaskLayout = memo(() => (
  <Switch>
    <Route path="/task/list/:tab?" exact>
      <TaskListPage />
    </Route>
    <Redirect from="/task" to="/task/list" exact />
  </Switch>
))
