import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT = gql`
  fragment EvaluationEnvelopeOverviewFields on EvaluationEnvelopeOverview {
    evaluationEnvelopeId
    nextStatus
    previousStatus
    hasModerationPhase
    criteriaBreakdown {
      ...EvaluationEnvelopeOverviewCriteriaBreakdownFields
    }
    submissionBreakdown {
      ...EvaluationEnvelopeOverviewSubmissionBreakdownFields
    }
  }
`
