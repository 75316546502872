/* eslint no-console: 0 */

class LoggerService {
  debug = (message?: any) => {
    if (!message) {
      return
    }

    console.debug(message)
  }

  error = (error?: any) => {
    if (!error) {
      return
    }

    console.error(error)
  }
}

export const loggerService = new LoggerService()
