import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'
import {
  GqlUpdatePerformanceScorecardMetricInput,
  GqlUpdatePerformanceScorecardMetricMutation,
  GqlUpdatePerformanceScorecardMetricMutationVariables,
  GqlPerformanceScorecardMetricFieldsFragment,
} from '@gql'

export const mutateUpdatePerformanceScorecardMetric = async (input: GqlUpdatePerformanceScorecardMetricInput) => {
  const { data } = await apolloService.mutate<GqlUpdatePerformanceScorecardMetricMutation, GqlUpdatePerformanceScorecardMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}

      mutation UpdatePerformanceScorecardMetric($input: UpdatePerformanceScorecardMetricInput!) {
        updatePerformanceScorecardMetric(input: $input) {
          ...PerformanceScorecardMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceScorecardMetric as GqlPerformanceScorecardMetricFieldsFragment
}
