import React, { memo, useEffect } from 'react'
import { useAuth } from '@cotiss/auth'
import { Button, CotissSquares, EXTERNAL_LINK, Page, Text, routerService } from '@cotiss/common'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import backgroundSquaresPng from '@assets/images/background-squares.png'

export const SignUpPage = memo(() => {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [])

  return (
    <Page>
      <img className="object-cover w-full h-full" src={backgroundSquaresPng} alt="Background squares" />
      <div className="absolute top-1/3 w-full">
        <div className="max-w-5xl mx-auto px-16">
          <Text className="font-medium" size="h2">
            Which product are you looking for?
          </Text>
          <Text className="mt-2" variant="light" isInline>
            Please select an option. Already have an account?
          </Text>

          <Button className="text-sm ml-2" state="text" variant="link" href={routerService.getHref('/login')} isLink>
            Log in
          </Button>
          <div className="flex items-stretch justify-center mt-6">
            <div className="bg-primary-50 shadow-lg rounded-md p-8 mr-8">
              <div className="flex items-center">
                <CotissSquares className="mr-4" variant="secondary" />
                <CotissLogoDarkSvg className="h-5 mr-2" />
                <Text size="h3">for buyers</Text>
              </div>
              <Text className="mt-4" variant="light">
                The world&apos;s easiest to use, end-to-end procurement software for small to medium procurement teams. We instil guided, best
                practice processes into organisations through an easy to implement, integrated, single solution.
              </Text>
              <Button className="mt-4" onClick={() => window.open(EXTERNAL_LINK.bookDemo, '_blank')} size="sm" variant="secondary">
                Book a demo
              </Button>
            </div>
            <div className="bg-primary-50 shadow-lg rounded-md p-8">
              <div className="flex items-center">
                <CotissSquares className="mr-4" variant="info" />
                <CotissLogoDarkSvg className="h-5 mr-2" />
                <Text size="h3">for suppliers</Text>
              </div>
              <Text className="mt-4" variant="light">
                Cotiss levels the playing field and empowers suppliers to succeed in the tender market. Cotiss simplifies the tendering process,
                making it more accessible for suppliers to find and win new business opportunities across a variety of sectors.
              </Text>
              <Button className="mt-4" href={routerService.getHref('/sign-up/supplier')} size="sm" variant="secondary" isLink>
                Sign up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
})
