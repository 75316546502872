import { gql } from '@apollo/client'

export const PLAN_EVENT_FRAGMENT = gql`
  fragment PlanEventFields on PlanEvent {
    id
    name
    organisationId
    organisation {
      ...OrganisationFields
    }
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
