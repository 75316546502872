import React, { memo } from 'react'
import { SignUpUserFormData } from '@cotiss/auth'
import {
  Button,
  Checkbox,
  EXTERNAL_LINK,
  ExternalLink,
  FormHiddenInput,
  Input,
  Label,
  RegionMultiSelect,
  Text,
  TransitionContainer,
  TransitionType,
} from '@cotiss/common'

type Props = {
  formData: SignUpUserFormData
  transition: TransitionType
  setFormData: (formData: SignUpUserFormData) => void
  isDisabled?: boolean
}

export const SignUpUserStepConfirmation = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>What regions do you operate in?</Label>
    <RegionMultiSelect value={formData.regions} onChange={(regions) => setFormData({ ...formData, regions })} />
    <Label className="mt-6">Choose password</Label>
    <Input
      value={formData.password}
      placeholder="Enter your password"
      onChange={({ target }) => setFormData({ ...formData, password: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Confirm password</Label>
    <Input
      value={formData.passwordConfirm}
      placeholder="Confirm your password"
      onChange={({ target }) => setFormData({ ...formData, passwordConfirm: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <label className="flex items-center cursor-pointer mt-4" htmlFor="cotiss-terms-and-conditions">
      <Checkbox
        id="cotiss-terms-and-conditions"
        className="mr-1"
        isChecked={formData.termsAgreement}
        onChange={() => setFormData({ ...formData, termsAgreement: !formData.termsAgreement })}
      />
      <Text>
        I agree to the{' '}
        <ExternalLink href={EXTERNAL_LINK.termsAndConditions} isInline>
          Terms and Conditions
        </ExternalLink>
      </Text>
    </label>
    <Button
      className="mt-6 w-full"
      type="submit"
      isDisabled={isDisabled || !formData.password || formData.password !== formData.passwordConfirm || !formData.termsAgreement}
    >
      Join Now
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.firstname} name="First name" />
    <FormHiddenInput value={formData.surname} name="Last name" />
    <FormHiddenInput value={formData.phone} name="Phone number" />
  </TransitionContainer>
))
