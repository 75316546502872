import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'
import { ContractDocumentShellAttachmentStatus } from '@cotiss/contract'

const getVariant = (status: ContractDocumentShellAttachmentStatus): BaseVariant => {
  switch (status) {
    case 'PENDING':
    case 'PENDING_APPROVAL':
      return 'warning'
    case 'APPROVED':
    case 'SIGNED':
      return 'success'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ContractDocumentShellAttachmentStatus
  size?: BadgeSize
}

export const ContractDocumentShellAttachmentStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="dot">
    {upperFirst(lowerCase(status))}
  </Badge>
))
