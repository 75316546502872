import React, { useEffect, useRef } from 'react'
import { Text, TextArea, TransitionContainer } from '@cotiss/common'

type Props = {
  summary: string
  onChange: (summary: string) => void
  isSaving?: boolean
}

export const SupplierOnboardingStepBusinessSummary = ({ summary, onChange, isSaving }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  return (
    <TransitionContainer transition="right">
      <Text size="h4">Have a go at writing a short summary about your business.</Text>
      <Text variant="light">250 words maximum</Text>

      <div className="flex items-stretch justify-between mt-6">
        <div className="bg-gray-300 w-px mr-4" />
        <div className="bg-gray-50 rounded-lg w-full p-4">
          <TextArea
            className="bg-gray-50 outline-none shadow-none border-none"
            value={summary}
            rows={4}
            onChange={({ target }) => onChange(target.value)}
            ref={textareaRef}
            isDisabled={isSaving}
          />
        </div>
      </div>
    </TransitionContainer>
  )
}
