import { filter, find, forEach, map, sortBy } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { documentService, useGetDocument } from '@cotiss/document'
import {
  EvaluationEventSubmissionDocumentUploadModal,
  EvaluationEventSubmissionSetupCompleteBadge,
  EvaluationEventSubmissionUpdateModal,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationSubmission,
  useEvaluationSubmissionDocument,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Button,
  ConfirmModal,
  Drawer,
  EditableField,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  TableRowCta,
  Text,
  datetimeService,
  sentryService,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

export const EvaluationEventSubmissionUpdateDrawer = memo(() => {
  const { openToast } = useToast()
  const { openModal, closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationSubmission, queryEvaluationSubmissionView } = useEvaluationSubmission()
  const { evaluationSubmissionDocuments, queryEvaluationSubmissionDocumentList, mutateDeleteEvaluationSubmissionDocument } =
    useEvaluationSubmissionDocument()

  useAsyncEffect(async () => {
    // If there is no submission, something has gone wrong.
    if (!evaluationSubmission || !evaluationEvent) {
      sentryService.captureException({ exception: 'Failed to open EvaluationEventSubmissionUpdateDrawer due to missing data.' })
      closeDrawer()
      return
    }

    track('evaluation_event_view_submissions_drawer_view')

    try {
      setIsLoading(true)
      await queryEvaluationSubmissionDocumentList({
        filter: {
          evaluationEventId: evaluationEvent.id,
          evaluationSubmissionId: evaluationSubmission.id,
        },
      })
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [evaluationSubmission])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const handleDocumentDelete = async (evaluationSubmissionDocumentId: string) => {
    if (!evaluationSubmission) {
      return
    }

    track('evaluation_event_view_submissions_drawer_document_delete_submit')

    await mutateDeleteEvaluationSubmissionDocument({ evaluationSubmissionDocumentId })

    // Refreshing submission in order to update the setup complete badge.
    await queryEvaluationSubmissionView({ evaluationSubmissionId: evaluationSubmission.id })
  }

  const { tableColumnsByEnvelopeId } = useMemo(() => {
    const tableColumnsByEnvelopeId: Record<string, { fixedColumns: ScrollableTableColumn[]; columns: ScrollableTableColumn[] }> = {}

    forEach(sortBy(evaluationEnvelopes, 'order'), ({ id: evaluationEnvelopeId }) => {
      const submissionDocuments = filter(evaluationSubmissionDocuments, { evaluationEnvelopeId })

      const fixedColumns: ScrollableTableColumn[] = [
        {
          heading: 'Document name',
          rows: map(submissionDocuments, ({ id, fileName, documentId }) => ({
            content: () => (
              <div>
                <Button
                  className="text-sm underline cursor-pointer inline-block align-middle w-full"
                  onClick={() => setDocumentIdToDownload(documentId)}
                  state="raw"
                >
                  <Text className="truncate">{fileName}</Text>
                </Button>
              </div>
            ),
            cta: evaluationUserInSession?.role === 'owner' && (
              <TableRowCta
                actions={[
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete document"
                          description="Are you sure you want to delete this document?"
                          onSubmit={() => handleDocumentDelete(id)}
                        />
                      ),
                  },
                ]}
              />
            ),
          })),
        },
      ]

      const columns: ScrollableTableColumn[] = [
        {
          heading: 'Envelope',
          rows: map(submissionDocuments, ({ envelope }) => ({
            content: () => (
              <Text className="truncate" font="jakarta">
                {envelope.name}
              </Text>
            ),
          })),
        },
        {
          heading: 'Date created',
          rows: map(submissionDocuments, ({ createdAt }) => ({
            content: () => (
              <Text size="sm" variant="light">
                {datetimeService.format(createdAt, 'do MMM yyyy')}
              </Text>
            ),
          })),
        },
        {
          heading: 'Size',
          rows: map(submissionDocuments, ({ fileSize }) => ({
            content: () => (
              <Text size="sm" variant="light">
                {documentService.formatSize(fileSize)}
              </Text>
            ),
          })),
        },
      ]

      tableColumnsByEnvelopeId[evaluationEnvelopeId] = { fixedColumns, columns }
    })

    return { tableColumnsByEnvelopeId }
  }, [evaluationSubmission, evaluationSubmissionDocuments])

  const renderHeader = () => (
    <div>
      <div className="flex items-center">
        <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
          {evaluationSubmission?.name}
        </Text>
        <EvaluationEventSubmissionSetupCompleteBadge className="ml-2" isSetupComplete={evaluationSubmission?.isSetupComplete || false} />
      </div>
      {evaluationSubmission?.organisation?.name && evaluationSubmission.name !== evaluationSubmission.organisation.name && (
        <Text size="sm" variant="light">
          {evaluationSubmission?.organisation?.name}
        </Text>
      )}
    </div>
  )

  return (
    <Drawer header={renderHeader()}>
      <EditableField
        label="Name"
        textValue={evaluationSubmission?.name || '--'}
        onClick={() => openModal(<EvaluationEventSubmissionUpdateModal />)}
        isDisabled={isLoading}
      />
      {map(tableColumnsByEnvelopeId, ({ fixedColumns, columns }, envelopeId) => {
        const envelope = find(evaluationEnvelopes, { id: envelopeId })

        if (!envelope) {
          return null
        }

        return (
          <div key={envelopeId} className="mt-6">
            <TableHeader className="flex justify-between items-center">
              <div>
                <Text size="sm" variant="light">
                  Envelope {envelope.order}
                </Text>
                <Text className="font-semibold" font="jakarta">
                  {envelope.name}
                </Text>
              </div>
              {evaluationUserInSession?.role === 'owner' && (
                <Button
                  size="xs"
                  state="translucent"
                  variant="secondary-dark"
                  onClick={() => openModal(<EvaluationEventSubmissionDocumentUploadModal evaluationEnvelopeId={envelopeId} />)}
                  isDisabled={isLoading}
                >
                  + Upload documents
                </Button>
              )}
            </TableHeader>
            <ScrollableTable
              fixedColumns={fixedColumns}
              columns={columns}
              emptyCta={
                evaluationUserInSession?.role === 'owner' && (
                  <Button
                    state="text"
                    variant="secondary"
                    size="sm"
                    onClick={() => openModal(<EvaluationEventSubmissionDocumentUploadModal evaluationEnvelopeId={envelopeId} />)}
                    isDisabled={isLoading}
                  >
                    + Upload documents
                  </Button>
                )
              }
              isLoading={isLoading}
            />
          </div>
        )
      })}
    </Drawer>
  )
})
