import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardMetricCycleScoreViewInput, GqlPerformanceScorecardMetricCycleScoreViewQuery } from '@gql'

export const queryPerformanceScorecardMetricCycleScoreView = async (input: GqlPerformanceScorecardMetricCycleScoreViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleScoreViewQuery>({
    query: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT}

      query PerformanceScorecardMetricCycleScoreView($input: PerformanceScorecardMetricCycleScoreViewInput!) {
        performanceScorecardMetricCycleScoreView(input: $input) {
          ...PerformanceScorecardMetricCycleScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleScoreView
}
