import { BreadcrumbModel, Page, PageContent, TransitionContainer, routerService, useTransition } from '@cotiss/common'
import { ProcurementSettings, useGetProcurement } from '@cotiss/procurement'
import { useGetTender } from '@cotiss/tender'
import {
  TenderFlowActiveStep,
  TenderFlowEvaluateStep,
  TenderFlowHeader,
  TenderFlowReportStep,
  TenderFlowRequestStep,
  TenderFlowReviewStep,
  TenderFlowSelectionStep,
  TenderFlowShortlistStep,
  TenderFlowStep,
  tenderFlowService,
} from '@cotiss/tender-flow'
import { AnimatePresence } from 'framer-motion'
import { indexOf, upperFirst } from 'lodash'
import React, { memo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export const TenderFlowPage = memo(() => {
  const { replace } = useHistory()
  const { tenderId, step: procurementRequestStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab: string }>()
  const { tender } = useGetTender(tenderId)
  const { procurement } = useGetProcurement(tender?.procurement)
  const activeStep = tenderFlowService.getActiveStep(tender)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)
  const procurementRequestStepsWithSettings = [...tenderFlowService.getTenderFlowSteps(tender), 'settings']

  const { step, transition, onTransition } = useTransition({ initialStep: indexOf(procurementRequestStepsWithSettings, procurementRequestStep) + 1 })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: upperFirst(activeStep),
      onClick: () => replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: activeStep })),
      isLoading: !procurement,
    },
  ]

  useEffect(() => {
    if (!procurementRequestStep) {
      replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'planning' }))
    }
  }, [])

  useEffect(() => {
    if (procurementRequestStep === 'planning' && tab === undefined) {
      replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: procurementRequestStep, tab: 'details' }))
    }
  }, [procurementRequestStep])

  useEffect(() => {
    const newStep = indexOf(procurementRequestStepsWithSettings, procurementRequestStep) + 1

    onTransition({ step: newStep || 1, transition: newStep > step ? 'right' : 'left' })
  }, [procurementRequestStep])

  return (
    <Page>
      <TenderFlowHeader />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <TenderFlowRequestStep />}
            {step === 2 && <TenderFlowActiveStep />}
            {step === 3 && <TenderFlowReviewStep />}
            {step === 4 && !isOldEvaluationsEnabled && <TenderFlowShortlistStep />}
            {step === 4 && isOldEvaluationsEnabled && <TenderFlowEvaluateStep />}
            {step === 5 && isOldEvaluationsEnabled && <TenderFlowSelectionStep />}
            {step === 6 && isOldEvaluationsEnabled && <TenderFlowReportStep />}
            {step === procurementRequestStepsWithSettings.length && (
              <ProcurementSettings procurementId={tender?.procurement} breadcrumbs={breadcrumbs} />
            )}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
