import React, { HTMLProps, ChangeEvent, forwardRef } from 'react'
import cn from 'classnames'

export type TextAreaProps = Omit<HTMLProps<HTMLTextAreaElement>, 'onChange' | 'required' | 'disabled'> & {
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => any
  isDisabled?: boolean
  isError?: boolean
  isRequired?: boolean
  resize?: 'none' | 'vertical'
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { className, onChange, rows, isError, isDisabled, isRequired, resize = 'none', ...rest } = props
  const classes = cn(
    className,
    'block bg-white border text-primary-500 placeholder-gray-400 rounded w-full px-3 py-2 focus:shadow-none focus:outline-none',
    {
      'border-gray-300 focus:border-secondary-600': !isError,
      'opacity-50': isDisabled,
      'resize-none': resize === 'none',
      'resize-y': resize === 'vertical',
    }
  )

  return <textarea {...rest} className={classes} onChange={onChange} rows={rows} disabled={isDisabled} required={isRequired} ref={ref} />
})
