import classNames from 'classnames'
import React, { memo } from 'react'
import { upperFirst, lowerCase } from 'lodash'
import { Badge, BadgeSize, BaseVariant, Icon } from '@cotiss/common'
import { GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationEnvelopeStatus } from '@gql'

const getVariant = (status?: GqlEvaluationEnvelopeStatus): BaseVariant => {
  switch (status) {
    case 'evaluate':
      return 'secondary'
    case 'moderate':
      return 'warning'
    case 'complete':
      return 'success'
    case 'locked':
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  evaluationEnvelope?: GqlEvaluationEnvelopeFieldsFragment
  size?: BadgeSize
}

export const EvaluationEventEnvelopeStatusBadge = memo(({ className, evaluationEnvelope, size = 'sm' }: Props) => {
  return (
    <Badge className={classNames(className, 'flex items-center')} size={size} variant={getVariant(evaluationEnvelope?.status)} state="translucent">
      {evaluationEnvelope?.status === 'locked' && <Icon className="mr-1" icon="lock" size={12} />}
      {upperFirst(lowerCase(evaluationEnvelope?.status)) || 'Locked'}
    </Badge>
  )
})
