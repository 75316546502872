import classNames from 'classnames'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { filter, find, forEach, groupBy, includes, map, reverse, sortBy, uniq } from 'lodash'
import { PreferredSupplierOrganisationModel, PreferredSupplierPopulatedModel, useListPreferredSupplier } from '@cotiss/preferred-supplier'
import { TenderInvitationInviteStep, TenderInvitationModel, useListTenderInvitation, useMutateTenderInvitation } from '@cotiss/tender-invitation'
import {
  Button,
  Drawer,
  Icon,
  Input,
  NoDataPlaceholder,
  Pill,
  Table,
  TableColumn,
  Text,
  TransitionContainer,
  paginationService,
  sentryService,
  useCallout,
  useSortTable,
  useToast,
  useTransition,
  utilService,
} from '@cotiss/common'

type PreferredSupplierListSortKey = 'name'

/**
 * Old preferred suppliers just have an organisation name until the preferred suppliers signs up,
 * then they will have a supplierOrganisation
 * New preferred suppliers will always have an supplierOrganisation as it's now created when the preferred supplier is created
 * If there is a supplierOrganisation we want to use the details from that instead of the organisationName
 * This type allows us to quickly get the correct details for the preferred supplier by just checking the type instead of
 * having to check both organisationName and supplierOrganisation every time
 */
type LabelledPreferredSupplier =
  | (Omit<PreferredSupplierPopulatedModel, 'organisationName'> & {
      type: 'pending'
      organisationName: string
      tenderInvitation?: TenderInvitationModel
    })
  | (Omit<PreferredSupplierPopulatedModel, 'supplierOrganisation'> & {
      type: 'notPending'
      supplierOrganisation: PreferredSupplierOrganisationModel
      tenderInvitation?: TenderInvitationModel
    })

type ProcessedSelectedUser = {
  _id: string
  preferredSupplierId: string
  email: string
  type: 'contact' | 'user'
}

type Props = {
  isEditable?: boolean
  isNewContact?: boolean
  preferredSupplierId?: string
  tenderId: string
}

export const TenderInvitationDrawer = memo(({ isEditable, isNewContact, preferredSupplierId, tenderId }: Props) => {
  // Utils
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { step, transition, isTransitioning, onTransition } = useTransition({ initialStep: preferredSupplierId ? 2 : 1 })
  const { sortKey, sortDirection, onSort } = useSortTable<PreferredSupplierListSortKey>({ initialKey: 'name' })

  // State
  const [selectedPreferredSupplierId, setSelectedPreferredSupplierId] = useState<string | undefined>(preferredSupplierId)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [q, setQ] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  // Queries and mutations
  const { sendTenderInvitationBulk } = useMutateTenderInvitation()
  const { preferredSuppliers, isLoading: isPreferredSuppliersLoading } = useListPreferredSupplier()
  const { tenderInvitations, isLoading: isTenderInvitationsLoading } = useListTenderInvitation({ tenderId })

  // Constants
  const isLoading = isPreferredSuppliersLoading || isTenderInvitationsLoading
  const isDisabled = isTransitioning || !isEditable || isSaving
  const showSideBarList = !isNewContact && !preferredSupplierId

  const selectedTenderInvitationId = useMemo(() => {
    return find(tenderInvitations, (invite) => invite.preferredSupplier._id === selectedPreferredSupplierId)?._id
  }, [tenderInvitations, selectedPreferredSupplierId])

  const { processedSelectedUsers, groupedSelectedUsers } = useMemo(() => {
    const processedSelectedUsers: ProcessedSelectedUser[] = []

    forEach(preferredSuppliers, (preferredSupplier) => {
      const { _id: preferredSupplierId, organisationName, contacts, supplierOrganisation } = preferredSupplier

      const orgName = supplierOrganisation?.name || organisationName

      if (!orgName || !selectedUsers.length) {
        return processedSelectedUsers
      }

      forEach(selectedUsers, (_id) => {
        const contact = find(contacts, { _id })
        const accountUser = find(supplierOrganisation?.account?.accountUser, { _id })

        if (contact?.email) {
          processedSelectedUsers.push({ _id, preferredSupplierId, email: contact.email, type: 'contact' })
        } else if (accountUser?.email) {
          processedSelectedUsers.push({ _id, preferredSupplierId, email: accountUser.email, type: 'user' })
        }
      })
    })

    const groupedSelectedUsers = groupBy(processedSelectedUsers, 'preferredSupplierId')

    return { processedSelectedUsers, groupedSelectedUsers }
  }, [preferredSuppliers, selectedUsers])

  const { processedPreferredSuppliers, pagination } = useMemo(() => {
    const filteredSuppliers = filter(preferredSuppliers, (preferredSupplier) => {
      if (q) {
        const qLower = q.toLowerCase()

        if (!preferredSupplier.supplierOrganisation && preferredSupplier.organisationName?.toLowerCase().includes(qLower)) {
          return true
        }

        if (preferredSupplier.supplierOrganisation && preferredSupplier.supplierOrganisation.name?.toLowerCase().includes(qLower)) {
          return true
        }

        if (preferredSupplier.contacts?.some((contact) => contact.email.toLowerCase().includes(qLower))) {
          return true
        }

        if (preferredSupplier.supplierOrganisation?.account?.accountUser?.some((user) => user.email.toLowerCase().includes(qLower))) {
          return true
        }

        if (preferredSupplier.tags?.some((tag) => tag.toLowerCase().includes(qLower))) {
          return true
        }

        return false
      }

      return true
    })

    const labelledSuppliers = filter<LabelledPreferredSupplier>(
      map(filteredSuppliers, (preferredSupplier) => {
        const tenderInvitation = find(tenderInvitations, (invite) => invite.preferredSupplier._id === preferredSupplier._id)

        if (preferredSupplier.supplierOrganisation) {
          return {
            ...preferredSupplier,
            tenderInvitation: tenderInvitation,
            supplierOrganisation: preferredSupplier.supplierOrganisation,
            type: 'notPending',
          }
        }

        return {
          ...preferredSupplier,
          tenderInvitation: tenderInvitation,
          organisationName: preferredSupplier.organisationName ?? '',
          type: 'pending',
        }
      }),
      // Filter out preferred suppliers that don't have a name or organisation name
      // This shouldn't happen but is a safety net as both fields are optional
      (preferredSupplier) => Boolean(preferredSupplier.supplierOrganisation || preferredSupplier.organisationName)
    )

    const result = sortBy(labelledSuppliers, (labelledSupplier) => {
      if (sortKey === 'name') {
        return labelledSupplier.type === 'pending' ? labelledSupplier.organisationName : labelledSupplier.supplierOrganisation.name
      }

      return labelledSupplier
    })

    const sortedResult = sortDirection === 'asc' ? result : reverse(result)
    const { items: processedPreferredSuppliers, pagination } = paginationService.paginate(sortedResult, { currentPage, pageSize: 10 })

    return { processedPreferredSuppliers, pagination }
  }, [preferredSuppliers, q, tenderInvitations, sortKey, sortDirection, currentPage])

  const columns: TableColumn[] = useMemo(
    () => [
      {
        heading: 'Name',
        onSort: () => onSort('name'),
        rows: map(processedPreferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <div className="flex-1 flex items-center justify-between truncate">
              <Text
                className="truncate"
                font="jakarta"
                title={preferredSupplier.type === 'pending' ? preferredSupplier.organisationName : preferredSupplier.supplierOrganisation.name}
                variant={selectedPreferredSupplierId === preferredSupplier._id ? 'secondary' : 'primary'}
              >
                {preferredSupplier.type === 'pending' ? preferredSupplier.organisationName : preferredSupplier.supplierOrganisation.name}
              </Text>
              {groupedSelectedUsers[preferredSupplier._id] && <Pill>{groupedSelectedUsers[preferredSupplier._id].length}</Pill>}
            </div>
          ),
          cta: (
            <Button onClick={() => handleSelectPreferredSupplier(preferredSupplier._id)} state="outline" variant="secondary" size="xs">
              View
            </Button>
          ),
        })),
      },
    ],
    [processedPreferredSuppliers, onSort, tenderId, isEditable]
  )

  // Effects
  useEffect(() => {
    if (preferredSupplierId) {
      setSelectedPreferredSupplierId(preferredSupplierId)
    }
  }, [preferredSupplierId])

  // Functions
  const handleSubmit = async () => {
    if (step === 1) {
      return
    }

    if (!selectedPreferredSupplierId) {
      return
    }

    try {
      setIsSaving(true)
      const items = map(groupedSelectedUsers, (selectedUsers, preferredSupplierId) => ({
        preferredSupplierId: preferredSupplierId,
        contacts: map(filter(selectedUsers, { type: 'contact' }), '_id'),
        users: map(filter(selectedUsers, { type: 'user' }), '_id'),
      }))

      await sendTenderInvitationBulk({ tenderId, items })
      setIsSaving(false)
      setSelectedUsers([])
      closeDrawer()
      openToast(`${utilService.pluralize(processedSelectedUsers.length, 'Invitation')} sent successfully`, 'success')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleSelectPreferredSupplier = (preferredSupplierId: string) => {
    setSelectedPreferredSupplierId(preferredSupplierId)
    onTransition({ step: 2 })
  }

  const handleAddUsers = (idsToAdd: string[]) => {
    setSelectedUsers(uniq([...selectedUsers, ...idsToAdd]))
  }

  const handleRemoveUsers = (idsToRemove: string[]) => {
    setSelectedUsers(filter(selectedUsers, (id) => !includes(idsToRemove, id)))
  }

  const renderSideBarList = () => {
    return (
      <div className="flex flex-col w-1/3">
        <div className="relative">
          <Icon className="absolute left-4 top-1/2 -translate-y-1/2" icon="search" variant="light" size={20} />
          <Input
            className="pl-12 border-x border-gray-200 rounded-none"
            value={q}
            onChange={({ target }) => setQ(target.value)}
            placeholder="Search contacts or emails"
            state="ghost"
            isDisabled={isLoading}
          />
        </div>
        <Table isLoading={isLoading} columns={columns} pagination={pagination} onPageChange={setCurrentPage} />
      </div>
    )
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
      Invite contact
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button className="mr-4" type="submit" variant="secondary" isLoading={isSaving} isDisabled={isDisabled || processedSelectedUsers.length === 0}>
        <Icon icon="send-01" className="mr-1" /> Send
      </Button>
      {showSideBarList && (
        <>
          <Pill className="mr-1.5">{selectedUsers.length}</Pill>
          <Text className="font-medium" size="sm" variant="secondary">
            Selected
          </Text>
        </>
      )}
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} hasPadding={false}>
      <div className="flex flex-1">
        {showSideBarList && renderSideBarList()}
        <div
          className={classNames('flex', {
            'w-full': !showSideBarList,
            'w-2/3': showSideBarList,
          })}
        >
          <AnimatePresence mode="wait" initial={false}>
            <TransitionContainer className="flex flex-1" transition={transition}>
              {step === 1 && (
                <div className="flex flex-1 items-center justify-center bg-gray-200">
                  <NoDataPlaceholder
                    variant="gray"
                    orientation="vertical"
                    label="Select a contact from the list and choose which users you would like to send the invitation to"
                  />
                </div>
              )}
              {step === 2 && selectedPreferredSupplierId && (
                <TenderInvitationInviteStep
                  tenderInvitationId={selectedTenderInvitationId}
                  preferredSupplierId={selectedPreferredSupplierId}
                  selectedUsers={map(groupedSelectedUsers[selectedPreferredSupplierId], '_id')}
                  onAddUsers={handleAddUsers}
                  onRemoveUsers={handleRemoveUsers}
                  isNewContact={isNewContact}
                  isDisabled={isDisabled}
                />
              )}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </div>
    </Drawer>
  )
})
