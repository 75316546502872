import { BaseVariant } from '@cotiss/common'
import { TenderResponseStatus } from '@cotiss/tender-response'

class TenderResponseService {
  getStatusBaseVariant = (type: TenderResponseStatus): BaseVariant => {
    switch (type) {
      case 'watching':
        return 'info'
      case 'drafting':
        return 'warning'
      case 'submitted':
        return 'success'
      case 'withdrawn':
        return 'danger'
      case 'invited':
        return 'secondary'
      default:
        return 'neutral'
    }
  }
}

export const tenderResponseService = new TenderResponseService()
