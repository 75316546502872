export * from './settings-conflict-of-interest-template.tab'
export * from './settings-general.tab'
export * from './settings-modules-contact.tab'
export * from './settings-modules-contract-approvals.tab'
export * from './settings-modules-contract.tab'
export * from './settings-modules-metafields.tab'
export * from './settings-modules-performance.tab'
export * from './settings-modules-procurement.tab'
export * from './settings-modules.tab'
export * from './settings-organisation-account-members.tab'
export * from './settings-organisation-display.tab'
export * from './settings-organisation.tab'
export * from './settings-preferences.tab'
export * from './settings-subscription.tab'
