import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
  hasPadding?: boolean
}

export const DrawerContent = memo(({ className, children, hasPadding = true }: Props) => {
  const classes = classNames(className, 'grow overflow-y-auto', {
    'p-6': hasPadding,
  })

  return <div className={classes}>{children}</div>
})
