import { GqlCreateEvaluationEnvelopeSubmissionInput, GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput } from '@gql'
import {
  mutateCreateEvaluationEnvelopeSubmission,
  mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId,
} from '@cotiss/evaluation-event'

export const useEvaluationEnvelopeSubmission = () => {
  return {
    mutateCreateEvaluationEnvelopeSubmission: async (input: GqlCreateEvaluationEnvelopeSubmissionInput) => {
      const createdEvaluationEnvelopeSubmission = await mutateCreateEvaluationEnvelopeSubmission(input)

      return createdEvaluationEnvelopeSubmission
    },
    mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId: async (
      input: GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput
    ) => {
      await mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId(input)
    },
  }
}
