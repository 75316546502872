import { map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import React, { FormEvent, memo, useState } from 'react'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { OrganisationBuyerResourceModel, OrganisationModel, useMutateOrganisation } from '@cotiss/organisation'
import {
  Form,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  RichTextEditor,
  richTextService,
  sentryService,
  useCallout,
  useToast,
} from '@cotiss/common'

type FormData = {
  title: string
  description: RemirrorJSON
  link: string
  attachments: DocumentModel[]
}

type Props = {
  organisation: OrganisationModel
  resource?: OrganisationBuyerResourceModel
}

export const BuyerAddUpdateResourceModal = memo(({ organisation, resource }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createOrganisationResource, updateOrganisationResource } = useMutateOrganisation()
  const [formData, setFormData] = useState<FormData>({
    title: resource?.title || '',
    description: resource?.description || richTextService.getEmptyRemirrorJson(),
    link: resource?.link || '',
    attachments: resource?.attachments || [],
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.description) {
      return
    }

    try {
      setIsSaving(true)
      const { attachments, ...rest } = formData

      const formDataToPost = {
        attachments: map(attachments, ({ _id }) => _id),
        ...rest,
      }

      if (resource) {
        await updateOrganisationResource(organisation._id, resource._id, formDataToPost)
      } else {
        await createOrganisationResource(organisation._id, formDataToPost)
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${resource ? 'Update' : 'Add'} resource`} isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Enter job title"
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-4">Description</Label>
        <RichTextEditor value={formData.description} onChange={(description) => setFormData({ ...formData, description })} isRequired />

        <Label className="mt-4">External link (optional)</Label>
        <Input
          value={formData.link}
          onChange={({ target }) => setFormData({ ...formData, link: target.value })}
          placeholder="Add an external link if relevant"
          isDisabled={isSaving}
        />

        <Label className="mt-4">Attachments</Label>
        <DocumentUpload documents={formData.attachments} onChange={(attachments) => setFormData({ ...formData, attachments })} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
