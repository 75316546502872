import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { userService } from '@cotiss/user'
import { usePlanEventAnalytics, usePlanUser } from '@cotiss/plan-event'
import { Avatar, ErrorPanel, Table, TableColumn, TableHeader, Text, sentryService, useAsyncEffect } from '@cotiss/common'

export const PlanEventViewTeamTab = memo(() => {
  const { track } = usePlanEventAnalytics()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { planUsers, queryPlanUserList } = usePlanUser()
  const { planEventId } = useParams<{ planEventId: string }>()
  const [pagination, setPagination] = useState<GqlPagination>()

  useEffect(() => {
    track('plan_event_view_team_tab_view')
  }, [])

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryPlanUserList({ filter: { planEventId }, pagination: { page: currentPage, pageSize: 20 } })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Name',
        rows: map(planUsers, (planUser) => ({
          content: () => (
            <div className="flex items-center truncate">
              <Avatar>{userService.getInitials(planUser.user)}</Avatar>
              <Text className="font-medium truncate" font="jakarta" title={userService.getFullName(planUser.user)}>
                {userService.getFullName(planUser.user)}
              </Text>
            </div>
          ),
        })),
      },
      {
        heading: 'Email',
        rows: map(planUsers, (planUser) => ({
          content: () => <Text>{planUser.user.email}</Text>,
        })),
      },
    ]

    return { columns }
  }, [planUsers])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" font="jakarta" size="lg">
          Team
          {pagination && (
            <Text className="ml-1" variant="light" font="jakarta" size="lg" isInline>
              ({pagination.totalCount})
            </Text>
          )}
        </Text>
      </TableHeader>
      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
