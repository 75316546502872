import { useRouteMatch } from 'react-router-dom'
import React, { MouseEvent as ReactMouseEvent, memo, useMemo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { Button, Icon, routerService, useToast, sentryService, RouterPath } from '@cotiss/common'
import { ConflictOfInterestModel, conflictOfInterestService, useMutateConflictOfInterest } from '@cotiss/conflict-of-interest'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
}

export const ConflictOfInterestListItemCta = memo(({ conflictOfInterest }: Props) => {
  const { path } = useRouteMatch()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const [isResending, setIsResending] = useState(false)
  const { resendConflictOfInterest } = useMutateConflictOfInterest()
  const isMyConflict = user?._id == conflictOfInterest.recipient._id
  const conflictOfInterestOutcome = conflictOfInterestService.getConflictOutcome(conflictOfInterest)

  const handleResend = async (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    try {
      setIsResending(true)
      await resendConflictOfInterest(conflictOfInterest._id)
      openToast('The conflict of interest request has been resent.')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsResending(false)
  }

  const { href } = useMemo(() => {
    let href
    if ((path as RouterPath) === '/conflict-of-interest/me/list') {
      href = routerService.getHref('/conflict-of-interest/me/view/:conflictOfInterestId', { conflictOfInterestId: conflictOfInterest._id })
    } else if ((path as RouterPath) === '/conflict-of-interest/list') {
      href = routerService.getHref('/conflict-of-interest/view/:conflictOfInterestId', { conflictOfInterestId: conflictOfInterest._id })
    } else if ((path as RouterPath) === '/procurement/overview/:procurementId/:tab?/:nestedTab?') {
      href = routerService.getHref('/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId', {
        procurementId: conflictOfInterest.procurement._id,
        conflictOfInterestId: conflictOfInterest._id,
      })
    }
    return { href }
  }, [conflictOfInterest._id, path])

  const getCta = () => {
    switch (conflictOfInterestService.getConflictOutcome(conflictOfInterest)) {
      case 'excluded':
      case 'included':
      case 'managed':
        return 'Review'
      case 'pending':
        return isMyConflict ? 'View' : 'Resolve'
      case '--':
        return 'Complete'
    }
  }

  return (
    <>
      {conflictOfInterestOutcome === '--' && !isMyConflict && (
        <Button onClick={handleResend} state="outline" variant="secondary" size="xs" isLoading={isResending}>
          Resend link
        </Button>
      )}
      {((conflictOfInterestOutcome === '--' && isMyConflict) || conflictOfInterestOutcome !== '--') && (
        <Button href={href} state="outline" variant="secondary" size="xs" isLink>
          {getCta()}
          <Icon className="ml-1" icon="arrow-right" size={20} />
        </Button>
      )}
    </>
  )
})
