import React, { memo } from 'react'
import { Banner, Card, Text } from '@cotiss/common'
import { SupplierViewGeneralFormAbout, SupplierViewGeneralFormRegions, SupplierViewGeneralFormUnspscCategories } from '@cotiss/supplier'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewGeneralTab = memo(({ organisationId, isEditable }: Props) => (
  <>
    {!isEditable && (
      <Banner className="mb-4" icon="info-circle" iconVariant="secondary" variant="neutral">
        <div>
          <Text className="font-semibold" variant="heading">
            This tab is for the contact to complete
          </Text>
          <Text size="sm">Contact profiles will be updated by the organisation</Text>
        </div>
      </Banner>
    )}
    <Card>
      <SupplierViewGeneralFormAbout organisationId={organisationId} isEditable={isEditable} />
    </Card>
    <Card className="mt-8">
      <SupplierViewGeneralFormUnspscCategories organisationId={organisationId} isEditable={isEditable} />
    </Card>
    <Card className="mt-8">
      <SupplierViewGeneralFormRegions organisationId={organisationId} isEditable={isEditable} />
    </Card>
  </>
))
