import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceMetricViewInput, GqlPerformanceMetricViewQuery } from '@gql'

export const queryPerformanceMetricView = async (input: GqlPerformanceMetricViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceMetricViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      query PerformanceMetricView($input: PerformanceMetricViewInput!) {
        performanceMetricView(input: $input) {
          ...PerformanceMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceMetricView
}
