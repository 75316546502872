import { useFetch, PaginatedModel } from '@cotiss/common'
import { TenderInvitationModel } from '@cotiss/tender-invitation'

type Props = {
  tenderId?: string
  procurementId?: string
  limit?: number
  offset?: number
}

export const useListTenderInvitation = ({ tenderId, procurementId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderInvitationModel>>('/v2/tender-invitations', {
    route: `/v2/tender-invitations`,
    params: { tenderId, procurementId, offset, limit },
    isEnabled: Boolean(tenderId || procurementId),
  })

  return { tenderInvitations: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
