import React, { memo } from 'react'
import { SignUpSupplierFormData } from '@cotiss/auth'
import {
  Button,
  Checkbox,
  EXTERNAL_LINK,
  ExternalLink,
  FormHiddenInput,
  Input,
  Label,
  Text,
  TransitionContainer,
  TransitionType,
} from '@cotiss/common'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
  isInvitation?: boolean
}

export const SignUpSupplierStepPassword = memo(({ formData, transition, setFormData, isDisabled, isInvitation }: Props) => (
  <TransitionContainer transition={transition}>
    <Label className="mt-6">Choose password</Label>
    <Input
      value={formData.password}
      placeholder="Enter your password"
      onChange={({ target }) => setFormData({ ...formData, password: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Confirm password</Label>
    <Input
      value={formData.passwordConfirm}
      placeholder="Confirm your password"
      onChange={({ target }) => setFormData({ ...formData, passwordConfirm: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <label className="flex items-center cursor-pointer mt-4" htmlFor="cotiss-terms-and-conditions">
      <Checkbox
        id="cotiss-terms-and-conditions"
        className="mr-1"
        isChecked={formData.termsAgreement}
        onChange={() => setFormData({ ...formData, termsAgreement: !formData.termsAgreement })}
      />
      <Text>
        I agree to the{' '}
        <ExternalLink href={EXTERNAL_LINK.termsAndConditions} isInline>
          Terms and Conditions
        </ExternalLink>
      </Text>
    </label>
    <Button className="mt-6 w-full" type="submit" isLoading={isDisabled}>
      Join now{isInvitation && ' to accept invitation'}
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.firstname} name="First name" />
    <FormHiddenInput value={formData.surname} name="Last name" />
    <FormHiddenInput value={formData.phone} name="Phone number" />
    <FormHiddenInput value={formData.companyName} name="Company name" />
    <FormHiddenInput value={formData.industry} name="Industry" />
  </TransitionContainer>
))
