import { gql } from '@apollo/client'

export const APPROVAL_TEMPLATE_FRAGMENT = gql`
  fragment ApprovalTemplateFields on ApprovalTemplate {
    id
    approvalTemplateUsers {
      id
      user {
        ...UserFields
      }
    }
    name
    order
    isOptional
    approvalTemplateGroupId
  }
`
