import { gql } from '@apollo/client'

export const CONTACT_FRAGMENT = gql`
  fragment ContactFields on Contact {
    id
    externalId
    organisationId
    name
    logoUrl
    claimedStatus
    createdAt
    updatedAt
    deletedAt
  }
`
