import { find } from 'lodash'
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { Button, EditableField, Field, Icon, Text, useCallout } from '@cotiss/common'
import { MetafieldValueModel, metafieldValueService } from '@cotiss/metafield-value'
import { MetafieldFormEditModal, MetafieldModel } from '@cotiss/metafield'

type Props = {
  className?: string
  resourceId: string
  metafield: MetafieldModel
  metafieldValues: MetafieldValueModel[]
  isEditable?: boolean
}

export const MetafieldForm = memo(({ className, resourceId, metafield, metafieldValues, isEditable }: Props) => {
  const { openModal } = useCallout()
  const classes = classNames(className, 'flex items-center')
  const metafieldValue = useMemo(() => find(metafieldValues, { metafield: metafield._id }), [metafield, metafieldValues])

  const renderContent = () => {
    const contentClasses = classNames({
      truncate: metafield.fieldType === 'SHORT_STRING',
      'whitespace-pre-wrap': metafield.fieldType === 'LONG_STRING',
    })

    const value = metafieldValueService.renderFieldValue({ metafield, metafieldValue, isEditable })

    if (!value) {
      return null
    }

    if (metafield.fieldType === 'HYPERLINK') {
      return (
        <Button href={value} variant="secondary" state="text" isExternalLink isTruncated>
          {value}
        </Button>
      )
    }

    return <div className={contentClasses}>{value}</div>
  }

  if (isEditable) {
    // The hyperlink has a unique layout, as we don't want to nest the hyperlink within a button. So in the case of an editable hyperlink field, we
    // arrange the value and edit buttons next to each other.
    if (metafield.fieldType === 'HYPERLINK') {
      return (
        <Field className={classes} label={metafield.fieldLabel} isFullWidth>
          <div className="flex items-center justify-between pl-3 pr-1">
            <Text className="truncate">{renderContent()}</Text>
            <Button
              className="flex items-center justify-center ml-2"
              onClick={() => openModal(<MetafieldFormEditModal resourceId={resourceId} metafield={metafield} metafieldValue={metafieldValue} />)}
              shape="square"
              size="sm"
              variant="neutral"
              state="ghost"
            >
              <Icon icon="edit" />
            </Button>
          </div>
        </Field>
      )
    }

    return (
      <EditableField
        className={classes}
        label={metafield.fieldLabel}
        onClick={() => openModal(<MetafieldFormEditModal resourceId={resourceId} metafield={metafield} metafieldValue={metafieldValue} />)}
        isTruncated={metafield.fieldType === 'SHORT_STRING'}
      >
        {renderContent()}
      </EditableField>
    )
  }

  return (
    <Field className={classes} label={metafield.fieldLabel}>
      <Text>{renderContent()}</Text>
    </Field>
  )
})
