import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@cotiss/common'
import { PreferredSupplierListPage, PreferredSupplierBuyerListPage } from '@cotiss/preferred-supplier'

export const PreferredSupplierLayout = memo(() => (
  <Switch>
    <Route path="/preferred-supplier/buyer/list" exact>
      <PreferredSupplierBuyerListPage />
    </Route>
    <Route path="/preferred-supplier/list/:tab?" exact>
      <PreferredSupplierListPage />
    </Route>
    <Redirect from="/preferred-supplier" to="/preferred-supplier/list/:tab?" exact />
  </Switch>
))
