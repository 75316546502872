import classNames from 'classnames'
import React, { HTMLProps, memo, useEffect, useRef } from 'react'
import { Icon } from '@cotiss/common'

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'required' | 'disabled' | 'checked'> & {
  onChange?: () => void
  id?: string
  className?: string
  isChecked?: boolean
  isIndeterminate?: boolean
  isDisabled?: boolean
}

export const Checkbox = memo(({ id, className, onChange, isChecked, isIndeterminate, isDisabled }: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const classes = classNames(className, 'relative inline-block shrink-0 h-4 w-4', {
    'pointer-events-none': isDisabled,
  })
  const checkboxClasses = classNames('absolute top-0 left-0 shrink-0 border border-gray-300 appearance-none rounded shadow-sm h-4 w-4', {
    'pointer-events-none': isDisabled,
    'bg-gray-200': isDisabled && !isChecked,
    'border-gray-500 bg-gray-500': isChecked && isDisabled,
    'bg-center text-white cursor-pointer hover:border-gray-400 checked:bg-secondary-500 checked:border-secondary-500 indeterminate:bg-secondary-500 indeterminate:border-secondary-500':
      !isDisabled,
  })

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = Boolean(isIndeterminate)
    }
  }, [isIndeterminate])

  return (
    <div className={classes}>
      <input
        ref={ref}
        id={id}
        className={checkboxClasses}
        type="checkbox"
        onChange={onChange}
        checked={isChecked}
        aria-checked={isIndeterminate ? 'mixed' : isChecked}
        disabled={isDisabled}
      />

      {isChecked && (
        <Icon
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer z-1"
          icon="check"
          variant="white"
          onClick={(event) => {
            event.preventDefault()
            if (onChange) {
              onChange()
            }
          }}
        />
      )}
      {isIndeterminate && (
        <div
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white cursor-pointer w-2 h-px z-1"
          onClick={(event) => {
            event.preventDefault()
            if (onChange) {
              onChange()
            }
          }}
        />
      )}
    </div>
  )
})
