import React from 'react'
import { Card, CardHeader, Hr, Text } from '@cotiss/common'
import { SettingsOrganisationFormLogo, SettingsOrganisationFormName } from '@cotiss/settings/components'

export const SettingsOrganisationDisplayTab = () => {
  return (
    <Card>
      <CardHeader>
        <Text className="font-medium" variant="heading" size="h5" font="jakarta">
          Display
        </Text>
      </CardHeader>

      <SettingsOrganisationFormLogo />

      <Hr className="my-4" />
      <SettingsOrganisationFormName />
    </Card>
  )
}
