import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlDeleteApprovalTemplateGroupInput, GqlDeleteApprovalTemplateGroupMutation, GqlDeleteApprovalTemplateGroupMutationVariables } from '@gql'

export const mutateDeleteApprovalTemplateGroup = async (input: GqlDeleteApprovalTemplateGroupInput) => {
  const { data } = await apolloService.mutate<GqlDeleteApprovalTemplateGroupMutation, GqlDeleteApprovalTemplateGroupMutationVariables>({
    mutation: gql`
      mutation DeleteApprovalTemplateGroup($input: DeleteApprovalTemplateGroupInput!) {
        deleteApprovalTemplateGroup(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteApprovalTemplateGroup as boolean
}
