import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreateApprovalTemplateGroup,
  mutateDeleteApprovalTemplateGroup,
  mutateUpdateApprovalTemplateGroup,
  queryApprovalTemplateGroupList,
  queryApprovalTemplateGroupView,
} from '@cotiss/approval-template-group'
import {
  GqlApprovalTemplateGroupFieldsFragment,
  GqlApprovalTemplateGroupListInput,
  GqlApprovalTemplateGroupViewInput,
  GqlCreateApprovalTemplateGroupInput,
  GqlDeleteApprovalTemplateGroupInput,
  GqlUpdateApprovalTemplateGroupInput,
} from '@gql'
import { filter, map, uniqBy } from 'lodash'

const approvalTemplateGroupVar = makeVar<GqlApprovalTemplateGroupFieldsFragment | null>(null)
const approvalTemplateGroupsVar = makeVar<GqlApprovalTemplateGroupFieldsFragment[]>([])

export const useApprovalTemplateGroup = () => {
  const approvalTemplateGroup = useReactiveVar(approvalTemplateGroupVar)
  const approvalTemplateGroups = useReactiveVar(approvalTemplateGroupsVar)

  return {
    approvalTemplateGroup,
    approvalTemplateGroups,
    setApprovalTemplateGroup: approvalTemplateGroupVar,
    setApprovalTemplateGroups: approvalTemplateGroupsVar,
    queryApprovalTemplateGroupList: async (input: GqlApprovalTemplateGroupListInput) => {
      const { items: approvalTemplateGroups, pagination } = await queryApprovalTemplateGroupList(input)

      approvalTemplateGroupsVar(approvalTemplateGroups)

      return { approvalTemplateGroups, pagination }
    },
    queryApprovalTemplateGroupView: async (input: GqlApprovalTemplateGroupViewInput) => {
      const approvalTemplateGroup = await queryApprovalTemplateGroupView(input)

      approvalTemplateGroupVar(approvalTemplateGroup)

      approvalTemplateGroupsVar(
        map(approvalTemplateGroups, (existingApprovalTemplateGroup) =>
          approvalTemplateGroup.id === existingApprovalTemplateGroup.id ? approvalTemplateGroup : existingApprovalTemplateGroup
        )
      )

      return approvalTemplateGroup
    },
    mutateCreateApprovalTemplateGroup: async (input: GqlCreateApprovalTemplateGroupInput) => {
      const createdApprovalTemplateGroup = await mutateCreateApprovalTemplateGroup(input)

      approvalTemplateGroupVar(createdApprovalTemplateGroup)
      approvalTemplateGroupsVar(uniqBy([createdApprovalTemplateGroup, ...approvalTemplateGroups], 'id'))

      return createdApprovalTemplateGroup
    },
    mutateUpdateApprovalTemplateGroup: async (input: GqlUpdateApprovalTemplateGroupInput) => {
      const updatedApprovalTemplateGroup = await mutateUpdateApprovalTemplateGroup(input)

      approvalTemplateGroupVar(updatedApprovalTemplateGroup)

      approvalTemplateGroupsVar(
        map(approvalTemplateGroups, (existingApprovalTemplateGroup) =>
          existingApprovalTemplateGroup.id === updatedApprovalTemplateGroup.id ? updatedApprovalTemplateGroup : existingApprovalTemplateGroup
        )
      )

      return updatedApprovalTemplateGroup
    },
    mutateDeleteApprovalTemplateGroup: async (input: GqlDeleteApprovalTemplateGroupInput) => {
      await mutateDeleteApprovalTemplateGroup(input)

      approvalTemplateGroupsVar(filter(approvalTemplateGroups, (approvalTemplateGroup) => approvalTemplateGroup.id !== input.approvalTemplateGroupId))
    },
  }
}

export const clearReactiveApprovalTemplateGroup = async () => {
  approvalTemplateGroupVar(null)
  approvalTemplateGroupsVar([])
}
