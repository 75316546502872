import {
  Button,
  Form,
  Icon,
  IconType,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioCard,
  Text,
  sentryService,
  useCallout,
  useToast,
  utilService,
} from '@cotiss/common'
import { useEvaluationEvent, useEvaluationEventAnalytics } from '@cotiss/evaluation-event'
import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'

type EvaluationEventResultExportType = 'envelope' | 'criteria' | 'subCriteria' | 'raw'

type RadioCardOptions = {
  title: string
  exportType: EvaluationEventResultExportType
  description: string
  icon: IconType
}

const RADIO_CARD_OPTIONS: RadioCardOptions[] = [
  {
    title: 'Results by envelope',
    exportType: 'envelope',
    description: 'Download final weighted scores by envelope.',
    icon: 'folder',
  },
  {
    title: 'Results by criteria',
    exportType: 'criteria',
    description: 'Download final weighted scores split by evaluation criteria.',
    icon: 'file-06',
  },
  {
    title: 'Results by sub-criteria',
    exportType: 'subCriteria',
    description: 'Download final weighted scores split by criteria and sub-criteria (lowest level).',
    icon: 'list',
  },
  {
    title: 'Raw data',
    exportType: 'raw',
    description: 'Download all raw scoring data, including individual scores, moderation and comments.',
    icon: 'data',
  },
]

export const EvaluationEventResultExportModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const {
    evaluationEvent,
    queryEvaluationEventRawResultsCsv,
    queryEvaluationEventResultsByCriteriaCsv,
    queryEvaluationEventResultsByEnvelopeCsv,
    queryEvaluationEventResultsBySubCriteriaCsv,
  } = useEvaluationEvent()
  const [isSaving, setIsSaving] = useState(false)
  const [selectedExportType, setSelectedExportType] = useState<EvaluationEventResultExportType>('envelope')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEvent) {
      return
    }

    try {
      setIsSaving(true)
      track('evaluation_event_view_results_tab_download_csv_submit', {
        selectedExportType,
      })

      const { id: evaluationEventId, name } = evaluationEvent

      if (selectedExportType === 'envelope') {
        const csv = await queryEvaluationEventResultsByEnvelopeCsv({ evaluationEventId })
        utilService.downloadCsv({ csv, filename: `${name}_results_by_envelope.csv` })
      } else if (selectedExportType === 'criteria') {
        const csv = await queryEvaluationEventResultsByCriteriaCsv({ evaluationEventId })
        utilService.downloadCsv({ csv, filename: `${name}_results_by_criteria.csv` })
      } else if (selectedExportType === 'subCriteria') {
        const csv = await queryEvaluationEventResultsBySubCriteriaCsv({ evaluationEventId })
        utilService.downloadCsv({ csv, filename: `${name}_results_by_sub_criteria.csv` })
      } else if (selectedExportType === 'raw') {
        const csv = await queryEvaluationEventRawResultsCsv({ evaluationEventId })
        utilService.downloadCsv({ csv, filename: `${name}_raw_results.csv` })
      }

      setIsSaving(false)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[550px] max-w-[550px]" onSubmit={handleSubmit}>
      <ModalHeader heading="How would you like to format this download?" supplementary="Select one option." />
      <ModalContent>
        <div className="grid grid-cols-2 grid-rows-2 gap-4">
          {map(RADIO_CARD_OPTIONS, ({ title, exportType, description, icon }) => (
            <RadioCard
              key={exportType}
              className="border border-gray-100 p-3.5"
              name="evaluation-event-export-method"
              isSelected={selectedExportType === exportType}
              onChange={() => setSelectedExportType(exportType)}
            >
              <div className="flex justify-between mb-3">
                <div className="flex items-center justify-center rounded bg-secondary-100 h-7 w-7">
                  <Icon icon={icon} variant="secondary" />
                </div>
                <Radio className="ml-2" isChecked={selectedExportType === exportType} />
              </div>
              <Text className="font-semibold" font="jakarta">
                {title}
              </Text>
              <Text className="mt-1" size="sm" variant="light">
                {description}
              </Text>
            </RadioCard>
          ))}
        </div>
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving}>
          Download
        </Button>
      </ModalFooter>
    </Form>
  )
})
