import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreateEvaluationUser,
  mutateDeleteEvaluationUser,
  mutateDeleteEvaluationUserAccessControls,
  mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId,
  mutateReplaceEvaluationUserInEvaluationEnvelope,
  mutateUpdateEvaluationUser,
  mutateUpdateEvaluationUserAccessControls,
  queryEvaluationUserEnvelopeList,
  queryEvaluationUserInSessionView,
  queryEvaluationUserList,
  queryEvaluationUserView,
} from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationUserInput,
  GqlDeleteEvaluationUserAccessControlsInput,
  GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdInput,
  GqlDeleteEvaluationUserInput,
  GqlEvaluationUserEnvelopeFieldsFragment,
  GqlEvaluationUserEnvelopeListInput,
  GqlEvaluationUserFieldsFragment,
  GqlEvaluationUserInSessionViewInput,
  GqlEvaluationUserListInput,
  GqlEvaluationUserViewInput,
  GqlReplaceEvaluationUserInEvaluationEnvelopeInput,
  GqlUpdateEvaluationUserAccessControlsInput,
  GqlUpdateEvaluationUserInput,
} from '@gql'

const evaluationUserVar = makeVar<GqlEvaluationUserFieldsFragment | null>(null)
const evaluationUserInSessionVar = makeVar<GqlEvaluationUserFieldsFragment | null>(null)
const evaluationUsersVar = makeVar<GqlEvaluationUserFieldsFragment[]>([])
const evaluationUserEnvelopesVar = makeVar<GqlEvaluationUserEnvelopeFieldsFragment[]>([])

export const useEvaluationUser = () => {
  const evaluationUser = useReactiveVar(evaluationUserVar)
  const evaluationUserInSession = useReactiveVar(evaluationUserInSessionVar)
  const evaluationUsers = useReactiveVar(evaluationUsersVar)
  const evaluationUserEnvelopes = useReactiveVar(evaluationUserEnvelopesVar)

  return {
    evaluationUser,
    evaluationUserInSession,
    evaluationUsers,
    evaluationUserEnvelopes,
    setEvaluationUser: evaluationUserVar,
    setEvaluationUserInSession: evaluationUserInSessionVar,
    setEvaluationUsers: evaluationUsersVar,
    queryEvaluationUserList: async (input: GqlEvaluationUserListInput) => {
      const { items, pagination } = await queryEvaluationUserList(input)

      evaluationUsersVar(items)

      return { items, pagination }
    },
    queryEvaluationUserEnvelopeList: async (input: GqlEvaluationUserEnvelopeListInput) => {
      const { items, pagination } = await queryEvaluationUserEnvelopeList(input)

      evaluationUserEnvelopesVar(items)

      return { items, pagination }
    },
    queryEvaluationUserView: async (input: GqlEvaluationUserViewInput) => {
      const evaluationUser = await queryEvaluationUserView(input)

      evaluationUserVar(evaluationUser)
      evaluationUsersVar(uniqBy([evaluationUser, ...evaluationUsers], 'id'))

      return evaluationUser
    },
    queryEvaluationUserInSessionView: async (input: GqlEvaluationUserInSessionViewInput) => {
      const evaluationUserInSession = await queryEvaluationUserInSessionView(input)

      evaluationUserInSessionVar(evaluationUserInSession)
      evaluationUsersVar(uniqBy([evaluationUserInSession, ...evaluationUsers], 'id'))

      return evaluationUserInSession
    },
    mutateCreateEvaluationUser: async (input: GqlCreateEvaluationUserInput) => {
      const createdEvaluationUser = await mutateCreateEvaluationUser(input)

      evaluationUserVar(createdEvaluationUser)
      evaluationUsersVar(uniqBy([createdEvaluationUser, ...evaluationUsers], 'id'))

      return createdEvaluationUser
    },
    mutateUpdateEvaluationUser: async (input: GqlUpdateEvaluationUserInput) => {
      const updatedEvaluationUser = await mutateUpdateEvaluationUser(input)

      evaluationUsersVar(uniqBy([updatedEvaluationUser, ...evaluationUsers], 'id'))

      return updatedEvaluationUser
    },
    mutateUpdateEvaluationUserAccessControls: async (input: GqlUpdateEvaluationUserAccessControlsInput) => {
      // NOTE: This will delete any existing access controls the user has, before adding the new ones. So as a side-effect, any existing evaluations,
      // scores, etc will be deleted. Right now, this mutation is only called in the setup wizard, before any evaluations or scores are created. But
      // if we ever introduce the ability to update the access controls after an evaluation event has started, then we will need to change this
      // mutation.
      const updatedEvaluationUser = await mutateUpdateEvaluationUserAccessControls(input)

      evaluationUserVar(updatedEvaluationUser)
      evaluationUsersVar(uniqBy([updatedEvaluationUser, ...evaluationUsers], 'id'))

      return updatedEvaluationUser
    },
    mutateReplaceEvaluationUserInEvaluationEnvelope: async (input: GqlReplaceEvaluationUserInEvaluationEnvelopeInput) => {
      const updatedEvaluationUser = await mutateReplaceEvaluationUserInEvaluationEnvelope(input)

      return updatedEvaluationUser
    },
    mutateDeleteEvaluationUser: async (input: GqlDeleteEvaluationUserInput) => {
      await mutateDeleteEvaluationUser(input)

      evaluationUsersVar(filter(evaluationUsers, ({ id }) => id !== input.evaluationUserId))
    },
    mutateDeleteEvaluationUserAccessControls: async (input: GqlDeleteEvaluationUserAccessControlsInput) => {
      const updatedEvaluationUser = await mutateDeleteEvaluationUserAccessControls(input)

      evaluationUserVar(updatedEvaluationUser)
      evaluationUsersVar(uniqBy([updatedEvaluationUser, ...evaluationUsers], 'id'))

      return updatedEvaluationUser
    },
    mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId: async (
      input: GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdInput
    ) => {
      const updatedEvaluationUser = await mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId(input)

      evaluationUserVar(updatedEvaluationUser)
      evaluationUsersVar(uniqBy([updatedEvaluationUser, ...evaluationUsers], 'id'))

      return updatedEvaluationUser
    },
  }
}

export const clearReactiveEvaluationUser = async () => {
  evaluationUserVar(null)
  evaluationUserInSessionVar(null)
  evaluationUsersVar([])
  evaluationUserEnvelopesVar([])
}
