import React, { memo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutateTenderInvitation } from '@cotiss/tender-invitation'
import { COLOUR, Icon, Page, Section, Spinner, Text, sentryService, useAsyncEffect, useToast, utilService } from '@cotiss/common'

export const TenderInvitationPage = memo(() => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const { validateTenderInvitation } = useMutateTenderInvitation()
  const searchParams = utilService.getUrlSearchParams({ search, params: ['invitation-token', 'invitation-email'] })

  useAsyncEffect(async () => {
    try {
      const token = searchParams['invitation-token']

      if (!token) {
        setIsError(true)
        return
      }

      const { redirectUrl } = await validateTenderInvitation({ token })
      openToast('Invitation has been accepted')
      push(redirectUrl)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsError(true)
    }
  }, [])

  const renderContent = () => {
    if (isError) {
      return (
        <Section className="text-center" isCentered>
          <Icon className="mx-auto mb-2" icon="cross" variant="danger" />
          <Text className="text-center font-semibold" size="h5" font="jakarta">
            Failed to verify invitation
          </Text>

          <Text className="text-center mt-2" variant="light">
            Please get in touch with the organisation to get a new invitation
          </Text>
        </Section>
      )
    }

    return (
      <Section isCentered>
        <Spinner className="mx-auto mb-2" colour={COLOUR.primary['500']} />
        <Text className="text-center font-semibold" size="h5" font="jakarta">
          Verifying invitation
        </Text>

        <Text className="text-center mt-2" variant="light">
          Please do not refresh the page
        </Text>
      </Section>
    )
  }

  return <Page className="flex items-center">{renderContent()}</Page>
})
