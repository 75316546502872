import { filter, find, findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ContractSummaryFullModal,
  ContractViewContractTab,
  ContractViewGeneralTab,
  ContractViewRemindersTab,
  ContractWizardFullModal,
  useGetContractShell,
  contractService,
  ContractRequestVariationModal,
  ContractStatus,
  ContractUploadSignedDocumentModal,
  ContractViewCessationTab,
  useMutateContractShell,
  ContractViewAssociatedTab,
  ContractViewScheduledRatesTab,
  ContractViewCorrespondenceTab,
  ContractRejectedFeedbackModal,
  ContractViewOwnersTab,
} from '@cotiss/contract'
import {
  Banner,
  BaseVariant,
  Breadcrumb,
  Button,
  ConfirmModal,
  Header,
  Icon,
  IconType,
  Page,
  PageContent,
  Section,
  Skeleton,
  Text,
  TransitionContainer,
  VerticalTabModel,
  VerticalTabs,
  routerService,
  sentryService,
  useFeature,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'

type ContractViewTab = 'general' | 'contract' | 'reminders' | 'cessation' | 'associated-contracts' | 'scheduled-rates' | 'correspondence' | 'owners'

type BannerConfigItem = {
  variant: BaseVariant
  icon: IconType
  label: string
  supplementary: string
}

// REJECTED is not a supported contract status so we will create a hybrid status here
const CONTRACT_VIEW_BANNER_CONFIG_MAP: Record<ContractStatus | 'REJECTED' | 'PENDING_SIGNATURES_E_SIGN', BannerConfigItem> = {
  DRAFTING: {
    variant: 'warning',
    icon: 'edit',
    label: 'Continue with draft',
    supplementary: 'Continue editing contract shell and submit changes for approval.',
  },
  REJECTED: { variant: 'danger', icon: 'cross', label: 'Changes rejected', supplementary: 'Resend for approval once feedback has been actioned' },
  PENDING_APPROVAL: {
    variant: 'neutral',
    icon: 'clock',
    label: 'Pending approval',
    supplementary: 'Contract is locked until latest updates have been approved.',
  },
  PENDING_SIGNATURES: {
    variant: 'success',
    icon: 'check-circle',
    label: 'Contract variation has been approved!',
    supplementary: 'Upload signed copy of the contract to see the variation display in your contract shell.',
  },
  PENDING_SIGNATURES_E_SIGN: {
    variant: 'neutral',
    icon: 'clock',
    label: 'Pending e-signature',
    supplementary: 'Contract is locked until latest updates have been signed.',
  },
  PUBLISHED: {
    variant: 'secondary',
    icon: 'file-check-02',
    label: 'This is your Contract Shell',
    supplementary: 'Seek approval for contract changes or collect e-signatures by submitting a request to a contract approver.',
  },
  CEASED: {
    variant: 'secondary',
    icon: 'cross',
    label: 'This contract has been ceased',
    supplementary: 'This contract can be viewed but can no longer be edited',
  },
  // Note the following statuses aren't displayed in the banner yet:
  UNPUBLISHED: { variant: 'neutral', icon: 'clock', label: '', supplementary: '' },
  ARCHIVED: { variant: 'neutral', icon: 'clock', label: '', supplementary: '' },
}

export const ContractViewPage = memo(() => {
  const { replace, push } = useHistory()
  const { openToast } = useToast()
  const { openFullModal, openModal, closeFullModal } = useCallout()
  const { contractShellId, tab: urlTab } = useParams<{ contractShellId: string; tab?: ContractViewTab }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { step, transition, onTransition } = useTransition()
  const { deleteContract } = useMutateContractShell()

  // The base contract is the contract that is either the latest approved contract in the shell, or
  // the contract that will be the first (but not yet) approved contract in the shell.
  // The current variation is the newest unapproved variation of the base contract
  const { baseContract, currentContractVariation } = useMemo(() => {
    const baseContract = contractShell && contractService.getContract(contractShell)
    const currentContractVariation =
      contractShell &&
      baseContract?.status === 'PUBLISHED' &&
      contractService.getContract(contractShell, ['PENDING_APPROVAL', 'PENDING_SIGNATURES', 'DRAFTING'])

    return { baseContract, currentContractVariation }
  }, [contractShell])

  const tabs = useMemo(() => {
    const tabs: (VerticalTabModel<ContractViewTab> & { isHidden?: boolean })[] = [
      { id: 'general', label: 'General' },
      { id: 'scheduled-rates', label: 'Schedule of rates' },
      { id: 'associated-contracts', label: 'Associations' },
      { id: 'contract', label: 'Contract' },
      { id: 'owners', label: 'Owners' },
      { id: 'correspondence', label: 'Notes' },
      { id: 'reminders', label: 'Reminders' },
      { id: 'cessation', label: 'Cessation', isHidden: !baseContract || !baseContract?.cessationDate },
    ]
    return filter(tabs, (tab) => !tab.isHidden)
  }, [baseContract?.cessationDate])

  const activeTabId = useMemo(() => urlTab || tabs[step - 1].id, [step, tabs])

  const isEsignEnabled = useFeature('contract-management-e-sign')

  const isUsingEsign = useMemo(
    () => isEsignEnabled && currentContractVariation && currentContractVariation?.documentShells.find((ds) => ds.signatureType === 'ESIGN_DOCUSIGN'),
    [isEsignEnabled, currentContractVariation]
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Contracts',
        href: routerService.getHref('/contract/list'),
      },
      {
        label: `${contractShell?.title || ''}${baseContract?.metadata?.internalReference ? ` (${baseContract?.metadata?.internalReference})` : ''}`,
        isLoading: !contractShell,
      },
    ]
  }, [contractShell])

  useEffect(() => {
    if (!contractShell) {
      return
    }

    if (!baseContract) {
      sentryService.captureException({ exception: 'No valid contract to open.', extras: { contractShellId: contractShell._id } })
      openToast(`Couldn't open contract`, 'danger')
      return
    }

    if (baseContract.status === 'PUBLISHED' || baseContract.status === 'CEASED') {
      closeFullModal()
      return
    }

    if (baseContract.status === 'DRAFTING') {
      openFullModal(<ContractWizardFullModal contractShellId={contractShell._id} onClose={() => push(routerService.getHref('/contract/list'))} />)
      return
    }

    openFullModal(
      <ContractSummaryFullModal
        contractShellId={contractShell._id}
        contractId={baseContract._id}
        onClose={() => push(routerService.getHref('/contract/list'))}
        breadcrumbs={[
          {
            label: 'Contracts',
            onClick: () => {
              push(routerService.getHref('/contract/list'))
              closeFullModal()
            },
          },
          { label: 'New contract request' },
        ]}
      />
    )
  }, [baseContract?.status])

  useEffect(() => {
    if (!urlTab) {
      replace(routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId, tab: tabs[0].id }))
    }

    const newStep = findIndex(tabs, ({ id }) => id === urlTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [urlTab])

  const contractShellStatus = useMemo(() => {
    if (!currentContractVariation) {
      return baseContract?.status || 'UNPUBLISHED' // This shouldn't ever happen or something else has gone wrong
    }
    if (contractService.getAreChangesRequested(currentContractVariation?.approvals)) {
      return 'REJECTED'
    }
    if (currentContractVariation.status === 'PENDING_SIGNATURES' && isUsingEsign) {
      return 'PENDING_SIGNATURES_E_SIGN'
    }
    return currentContractVariation.status
  }, [currentContractVariation, baseContract?.status])

  if (isLoading) {
    return (
      <Page>
        <Header className="flex items-center justify-between">
          <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
            Contract repository
          </Text>
        </Header>
        <div className="w-full h-full p-8">
          <Skeleton className="h-20 w-full" />
          <div className="flex w-full h-full">
            <div className="mt-8">
              <Skeleton className="h-8 w-36" />
              <Skeleton className="h-8 w-36 mt-4" />
              <Skeleton className="h-8 w-36 mt-4" />
            </div>
            <div className="w-full h-full pt-8 pl-8">
              <Skeleton className="h-full w-full" />
            </div>
          </div>
        </div>
      </Page>
    )
  }

  if (!baseContract || !contractShell) {
    return (
      <Page>
        <Header className="flex items-center justify-between">
          <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
            Contract repository
          </Text>
        </Header>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Page>
    )
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <div>
          <Breadcrumb className="" breadcrumbs={breadcrumbs} />
          <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
            Contract repository
          </Text>
        </div>
      </Header>
      <PageContent>
        <Section>
          <Banner
            variant={CONTRACT_VIEW_BANNER_CONFIG_MAP[contractShellStatus].variant}
            icon={CONTRACT_VIEW_BANNER_CONFIG_MAP[contractShellStatus].icon}
            className="mb-8"
          >
            <div>
              <Text className="font-semibold" variant="heading">
                {CONTRACT_VIEW_BANNER_CONFIG_MAP[contractShellStatus].label}
              </Text>
              <Text size="sm" variant="light">
                {CONTRACT_VIEW_BANNER_CONFIG_MAP[contractShellStatus].supplementary}
              </Text>
            </div>

            <div className="flex items-center">
              {!currentContractVariation && baseContract.status !== 'CEASED' && (
                <Button
                  size="sm"
                  onClick={() =>
                    openModal(
                      <ContractRequestVariationModal
                        contractId={baseContract._id}
                        contractShellId={contractShellId}
                        onSuccess={() => openFullModal(<ContractWizardFullModal contractShellId={contractShell._id} />)}
                      />
                    )
                  }
                  variant="secondary"
                >
                  Request variation
                </Button>
              )}
              {currentContractVariation && contractShellStatus === 'DRAFTING' && (
                <div>
                  <Button
                    className="mr-6"
                    state="text"
                    variant="secondary"
                    onClick={() =>
                      openModal(
                        <ConfirmModal
                          heading="Discard draft"
                          description="Are you sure you want to discard this variation? This cannot be undone."
                          onSubmit={async () => await deleteContract(contractShellId, currentContractVariation._id)}
                        />
                      )
                    }
                  >
                    Discard draft
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => openFullModal(<ContractWizardFullModal contractShellId={contractShell._id} />)}
                    variant="secondary"
                  >
                    {find(currentContractVariation.approvals, { status: 'REJECTED' }) ? 'Edit Variation Request' : 'Continue draft'}
                  </Button>
                </div>
              )}
              {currentContractVariation && contractShellStatus === 'REJECTED' && (
                <>
                  <Button
                    className="mr-2"
                    onClick={() =>
                      openModal(
                        <ConfirmModal
                          heading="Are you sure you want to discard this variation?"
                          description="You will no longer have access to this variation once discarded."
                          onSubmit={async () => await deleteContract(contractShellId, currentContractVariation._id)}
                        />
                      )
                    }
                    variant="secondary"
                    isLink
                    state="text"
                  >
                    Discard this variation
                  </Button>
                  <Button
                    className="mr-2"
                    onClick={() => openModal(<ContractRejectedFeedbackModal contract={currentContractVariation} />)}
                    variant="tertiary"
                    size="sm"
                  >
                    View approval feedback
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => openFullModal(<ContractWizardFullModal contractShellId={contractShell._id} />)}
                    variant="secondary"
                  >
                    Edit variation request
                  </Button>
                </>
              )}

              {currentContractVariation &&
                (contractShellStatus === 'PENDING_APPROVAL' ||
                  contractShellStatus === 'PENDING_SIGNATURES_E_SIGN' ||
                  contractShellStatus === 'PENDING_SIGNATURES') && (
                  <Button
                    size="sm"
                    onClick={() =>
                      openFullModal(
                        <ContractSummaryFullModal
                          contractShellId={contractShell._id}
                          contractId={currentContractVariation._id}
                          breadcrumbs={[
                            {
                              label: 'Contracts',
                              onClick: () => {
                                push(routerService.getHref('/contract/list'))
                                closeFullModal()
                              },
                            },
                            {
                              label: `${contractShell?.title || ''}${
                                baseContract?.metadata?.internalReference ? ` (${baseContract?.metadata?.internalReference})` : ''
                              }`,
                              onClick: closeFullModal,
                            },
                            { label: 'Variation request' },
                          ]}
                        />
                      )
                    }
                    variant="secondary"
                    state={contractShellStatus === 'PENDING_SIGNATURES' ? 'outline' : 'filled'}
                  >
                    View variation request
                  </Button>
                )}
              {currentContractVariation && contractShellStatus === 'PENDING_SIGNATURES' && !isUsingEsign && (
                <Button
                  size="sm"
                  className="ml-4"
                  variant="secondary"
                  onClick={() =>
                    openModal(<ContractUploadSignedDocumentModal contractShellId={contractShellId} contractId={currentContractVariation?._id} />)
                  }
                >
                  <Icon icon="upload" className="mr-2" />
                  Upload signed contract
                </Button>
              )}
            </div>
          </Banner>

          <div className="flex items-start justify-between">
            <VerticalTabs<ContractViewTab>
              className="sticky top-24 mr-8"
              tab={activeTabId}
              tabs={tabs}
              onChange={({ id: tab }) => push(routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId, tab }))}
            />
            <div className="w-full min-w-0">
              <AnimatePresence initial={false} mode="wait">
                <TransitionContainer key={step} className="w-full" transition={transition}>
                  {activeTabId === 'general' && <ContractViewGeneralTab />}
                  {activeTabId === 'contract' && <ContractViewContractTab />}
                  {activeTabId === 'owners' && <ContractViewOwnersTab />}
                  {activeTabId === 'reminders' && <ContractViewRemindersTab />}
                  {activeTabId === 'correspondence' && <ContractViewCorrespondenceTab />}
                  {activeTabId === 'associated-contracts' && <ContractViewAssociatedTab />}
                  {activeTabId === 'scheduled-rates' && <ContractViewScheduledRatesTab />}
                  {activeTabId === 'cessation' && <ContractViewCessationTab />}
                </TransitionContainer>
              </AnimatePresence>
            </div>
          </div>
        </Section>
      </PageContent>
    </Page>
  )
})
