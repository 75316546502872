import { OcdsCurrencyCode } from '@cotiss/common'
import { forEach, groupBy, isArray, sortBy, startsWith } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

type GetUrlSearchParamsParam = {
  search?: string
  params?: string[]
}

type DownloadCsvParam = {
  csv: string
  filename: string
}

class UtilService {
  generateUid = () => {
    return uuidv4()
  }

  asyncForEach = async <T, R = unknown>(collection: T[], callback: (item: T, index: number, collection: T[]) => Promise<R>) => {
    for (let index = 0; index < collection.length; index += 1) {
      await callback(collection[index], index, collection)
    }
  }

  getUrlSearchParams = ({ search = '', params = [] }: GetUrlSearchParamsParam = {}) => {
    const searchParamsMap: Partial<{ [key in (typeof params)[number]]: string }> = {}
    const urlSearchParams = new URLSearchParams(search || window.location.search)

    forEach(params, (value) => {
      const param = urlSearchParams.get(value)

      if (param) {
        searchParamsMap[value] = param
      }
    })

    return searchParamsMap
  }

  generateUrlSearchParams = (params?: Record<string, any>) => {
    if (!params) {
      return ''
    }

    const urlSearchParams = new URLSearchParams()

    forEach(params, (value, key) => {
      if (value !== undefined) {
        if (isArray(value)) {
          forEach(value, (valueItem) => {
            urlSearchParams.append(key, valueItem.toString())
          })
        } else {
          urlSearchParams.append(key, value.toString())
        }
      }
    })

    const urlSearchParamsStr = urlSearchParams.toString()

    return urlSearchParamsStr && `?${urlSearchParamsStr}`
  }

  getExternalHref = (href?: string) => {
    if (!href) {
      return ''
    }

    if (startsWith(href, 'mailto:')) {
      return href
    }

    if (!/^https?:\/\//i.test(href)) {
      return 'https://' + href
    }

    return href
  }

  getMedian = (values: number[]) => {
    if (!values.length) {
      return 0
    }

    const sortedValues = sortBy(values)

    const midpoint = Math.floor(sortedValues.length / 2)

    return Number(sortedValues.length % 2 === 1 ? sortedValues[midpoint] : (sortedValues[midpoint - 1] + sortedValues[midpoint]) / 2) || 0
  }

  getLocale = () => {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
  }

  formatAsPercentage = (value: number, fractionDigits = 2) => {
    if (!value) {
      return `${Number('0').toFixed(fractionDigits)}%`
    }

    return new Intl.NumberFormat(this.getLocale(), {
      style: 'percent',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value / 100)
  }

  formatAsCurrency = (value: number, currency: OcdsCurrencyCode = 'NZD') => {
    return new Intl.NumberFormat(this.getLocale(), { style: 'currency', currency, minimumFractionDigits: 2 }).format(value)
  }

  formatAsNumber = (value: number, fractionDigits?: number) => {
    return new Intl.NumberFormat(this.getLocale(), { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(value)
  }

  pluralize = (count: number, noun: string, suffix = 's') => {
    return `${noun}${count !== 1 ? suffix : ''}`
  }

  pluralizeCount = (count: number, noun: string, suffix = 's') => {
    return `${count} ${this.pluralize(count, noun, suffix)}`
  }

  normaliseSequenceByAttribute = <T, K extends keyof T>(items: T[], key: K) => {
    const normalisedItems: T[] = []
    const groupedItems = sortBy(groupBy(items, key), (items, index) => index)

    forEach(groupedItems, (items, index) => {
      forEach(items, (item) => {
        normalisedItems.push({ ...item, [key]: Number(index) + 1 })
      })
    })

    return normalisedItems
  }

  downloadCsv = ({ csv, filename }: DownloadCsvParam) => {
    const objectUrl = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
    const link = document.createElement('a')

    link.setAttribute('href', objectUrl)
    link.setAttribute('download', filename)
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(objectUrl)
  }
}

export const utilService = new UtilService()
