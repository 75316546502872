import { ORGANISATION_REGISTRY_NUMBER_TYPE } from '@cotiss/organisation'
import { OrganisationClaimedStatus, OrganisationCountryCodeCombined } from '@cotiss/organisation/organisation.models'

class OrganisationService {
  getClaimedStatusText(claimedStatus: OrganisationClaimedStatus) {
    switch (claimedStatus) {
      case 'CLAIMED':
        return 'Claimed'
      case 'PENDING_CLAIMED':
        return 'Pending claimed'
      case 'UNCLAIMED':
        return 'Unclaimed'
      default:
        return '--'
    }
  }

  getRegistryNumberType(countryCode?: OrganisationCountryCodeCombined): string {
    if (!countryCode) {
      return 'Company number'
    }

    return ORGANISATION_REGISTRY_NUMBER_TYPE[countryCode] || 'Company number'
  }
}

export const organisationService = new OrganisationService()
