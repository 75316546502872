import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
}

export const PageContent = memo(({ className, children }: Props) => {
  const classes = classNames(className, 'px-10 py-6')

  return <div className={classes}>{children}</div>
})
