import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group'
import { GqlApprovalTemplateGroupViewInput, GqlApprovalTemplateGroupViewQuery } from '@gql'

export const queryApprovalTemplateGroupView = async (input: GqlApprovalTemplateGroupViewInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateGroupViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      query ApprovalTemplateGroupView($input: ApprovalTemplateGroupViewInput!) {
        approvalTemplateGroupView(input: $input) {
          ...ApprovalTemplateGroupFields
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateGroupView
}
