import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { ShortListPopulatedModel, useMutateShortList } from '@cotiss/short-list'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  shortList: ShortListPopulatedModel
}

export const ShortListDueDiligenceUploadModal = memo(({ shortList }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateShortList } = useMutateShortList()
  const [isSaving, setIsSaving] = useState(false)
  const [dueDiligence, setDueDiligence] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateShortList(shortList._id, {
        dueDilligence: [...map(dueDiligence, ({ _id }) => _id), ...map(shortList.dueDilligence, ({ _id }) => _id)],
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload due diligence documents" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={dueDiligence} onChange={setDueDiligence} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
