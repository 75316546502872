import { filter, find, orderBy } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { Form, Label, ModalContent, ModalFooter, ModalHeader, Text, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'
import { useEvaluationCriteria, useEvaluationEnvelope, useEvaluationEvent, useEvaluationEventAnalytics } from '@cotiss/evaluation-event'

type FormData = {
  content: string
  evaluationEnvelopeId: string
}

type Props = {
  evaluationEnvelopeId: string
  evaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment
}

const CRITERIA_NAME_MAX_LENGTH = 200

export const EvaluationEventCriteriaCreateUpdateModal = memo(({ evaluationEnvelopeId, evaluationCriteriaItem }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { evaluationCriteria, mutateCreateEvaluationCriteria, mutateUpdateEvaluationCriteria } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    content: evaluationCriteriaItem?.content || '',
    evaluationEnvelopeId: evaluationCriteriaItem?.evaluationEnvelopeId || evaluationEnvelopeId,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEvent) {
      return
    }

    try {
      setIsSaving(true)

      if (evaluationCriteriaItem) {
        track('evaluation_event_wizard_criteria_update_submit')
        await mutateUpdateEvaluationCriteria({ evaluationCriteriaId: evaluationCriteriaItem.id, ...formData })
      }

      if (!evaluationCriteriaItem) {
        const envelope = find(evaluationEnvelopes, { id: evaluationEnvelopeId })
        // Get the next index of the criteria within the envelope.
        const parentCriteria = filter(evaluationCriteria, { evaluationEnvelopeId: formData.evaluationEnvelopeId, parentEvaluationCriteriaId: null })
        const index = (orderBy(parentCriteria, 'index', 'desc')[0]?.index || 0) + 1

        track('evaluation_event_wizard_criteria_create_submit')
        await mutateCreateEvaluationCriteria({ ...formData, weight: 1, ratingScale: envelope?.defaultRatingScale, index, isScored: true })
      }

      // We need to refresh the evaluation event as updating the envelopes may update the steps of the wizard (E.g. if there is sub criteria.)
      await queryEvaluationEventView({ evaluationEventId: evaluationEvent.id })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${evaluationCriteriaItem ? 'Edit' : 'Add'} criteria`} />
      <ModalContent isScrollable>
        <Label className="flex items-baseline">
          Criteria name
          <Text size="sm" variant="light" className="ml-1">{`(${formData.content.length}/${CRITERIA_NAME_MAX_LENGTH})`}</Text>
        </Label>
        <TextArea
          value={formData.content}
          onChange={({ target }) => setFormData({ ...formData, content: target.value })}
          maxLength={CRITERIA_NAME_MAX_LENGTH}
          rows={5}
          isDisabled={isSaving}
          isRequired
          autoFocus
        />
        <Text className="mt-1" size="sm" variant="light">
          Max {CRITERIA_NAME_MAX_LENGTH} characters
        </Text>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
