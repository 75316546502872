import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { UserLineItem, useGetLoggedInUser } from '@cotiss/user'
import { Button, ConfirmModal, sentryService, useCallout, useToast } from '@cotiss/common'
import { ApprovalModel, ApprovalRejectConfirmModal, ApprovalStatusBadge, useMutateApproval } from '@cotiss/approval'

type Props = {
  className?: string
  approval: ApprovalModel
}

export const ApprovalApprover = memo(({ className, approval }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateApproval, resendApproval } = useMutateApproval()
  const classes = classNames(className, 'relative flex items-center justify-between')
  const isUserApproval = approval.assigned._id === user?._id

  const handleResendApproval = async () => {
    try {
      setIsSaving(true)
      await resendApproval(approval._id)
      openToast(
        <>
          The approval has been sent to <strong>{approval.assigned.email}</strong>
        </>
      )
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className={classes}>
      <div className="flex items-center">
        <UserLineItem {...approval.assigned} hasAvatar={false} />
      </div>
      {approval.status === 'incomplete' &&
        (isUserApproval ? (
          <>
            <Button
              className="mr-2"
              onClick={() => openModal(<ApprovalRejectConfirmModal approval={approval} />)}
              state="translucent"
              variant="secondary"
              size="sm"
            >
              Reject
            </Button>
            <Button
              size="sm"
              onClick={() =>
                openModal(
                  <ConfirmModal
                    heading={approval.approvalType === 'go-to-market' ? 'Approve go to market' : 'Approve recommendation report'}
                    description="Are you sure you want to approve this request?"
                    onSubmit={() => updateApproval(approval._id, { status: 'approved' })}
                  />
                )
              }
            >
              Approve
            </Button>
          </>
        ) : (
          <Button onClick={handleResendApproval} state="text" variant="link" size="sm" isLoading={isSaving}>
            Resend approval
          </Button>
        ))}
      {approval.status !== 'incomplete' && <ApprovalStatusBadge status={approval.status} />}
    </div>
  )
})
