import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'
import { Button } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
  onClick?: () => void
  isActive?: boolean
  isDisabled?: boolean
}

export const PaginationButton = memo(({ className, children, onClick, isActive, isDisabled }: Props) => {
  const classes = classNames(className, 'inline-flex items-center justify-center shrink-0 text-sm rounded w-8 h-8', {
    'bg-gray-200': isActive,
    'text-light': !isActive,
    'hover:bg-gray-200': !isDisabled,
    'pointer-events-none opacity-50': isDisabled || !onClick,
  })

  return (
    <Button className={classes} onClick={onClick} state="raw">
      {children}
    </Button>
  )
})
