import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { useMutateTenderInvitation } from '@cotiss/tender-invitation'
import { Button, Text, sentryService, useToast } from '@cotiss/common'

type Props = {
  className?: string
  _id: string
  email?: string
  preferredSupplierId: string
  type: 'user' | 'contact'
  isEditable?: boolean
}

export const TenderInvitationLineItem = memo(({ className, preferredSupplierId, _id, email, type, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { resendTenderInvitation } = useMutateTenderInvitation()
  const classes = classNames(className, 'flex items-center justify-between border-t border-gray-200 p-4')

  if (!email) {
    return null
  }

  const handleResendInvite = async () => {
    try {
      setIsSaving(true)
      await resendTenderInvitation(preferredSupplierId, { [type]: _id })
      openToast(
        <>
          The invitation has been resent to <strong>{email}</strong>
        </>,
        'danger'
      )
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className={classes}>
      <Text>{email}</Text>
      {isEditable && (
        <Button onClick={handleResendInvite} state="text" variant="link" isLoading={isSaving}>
          Resend invite
        </Button>
      )}
    </div>
  )
})
