import classNames from 'classnames'
import { find, indexOf, map } from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ForwardedRef, forwardRef, useState } from 'react'
import { Badge, Icon, TRANSITION_DURATION, TabModel, TabsVariant, Text } from '@cotiss/common'

type Props<T> = TabModel<T> & {
  className?: string
  variant?: TabsVariant
  activeTab: TabModel<T>
  setActiveTab: (activeTab: TabModel<T>) => void
  tabs: TabModel<T>[]
  isFull?: boolean
}

function TabButtonInner<T>(props: Props<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const { className, id, label, count, variant, activeTab, setActiveTab, tabs, isHidden, isFull, isDisabled } = props
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id)
  const [isDisabledTooltipOpen, setIsDisabledTooltipOpen] = useState(false)

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`)
  }

  const isActive = activeTab.id === id
  let classes = classNames(className, 'flex items-center justify-center text-sm whitespace-nowrap focus:outline-none', {
    hidden: isHidden,
    'mr-1': !isFull && variant !== 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'mr-5': !isFull && variant === 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'text-secondary-500 is-active': isActive,
    'text-gray-900': isDisabled,
  })

  if (variant === 'underline') {
    classes = classNames(classes, 'py-2.5 border-b-1 border-transparent', {
      'text-opacity-90': isActive,
      'text-opacity-40': !isActive,
    })
  }

  if (variant !== 'underline') {
    classes = classNames(classes, 'text-opacity-90 rounded-full h-7 px-3 z-1 hover:text-secondary-500')
  }

  return (
    <button className={classes} onClick={() => setActiveTab(tab)} type="button" disabled={Boolean(isDisabled)} ref={ref}>
      {/* Wrapping the content in a div, so we can get the exact width of the content without having to hard code the padding offset. */}
      <div className="relative" onMouseEnter={() => setIsDisabledTooltipOpen(true)} onMouseLeave={() => setIsDisabledTooltipOpen(false)}>
        <div className="flex items-center">
          {isDisabled && typeof isDisabled === 'string' && <Icon className="mr-1" icon="lock" />}
          {label}
          {(count || count === 0) && (
            <Badge className="ml-1" state="translucent" variant="secondary" size="sm">
              {count}
            </Badge>
          )}
        </div>

        {/* Tooltip */}
        <AnimatePresence>
          {isDisabledTooltipOpen && typeof isDisabled === 'string' && (
            <motion.div
              className="absolute bottom-[calc(100%+10px)] full left-1/2 bg-white shadow rounded w-[140px] px-2 py-1 z-10"
              hidden={!isDisabledTooltipOpen}
              initial={{ y: 5, x: '-50%', opacity: 0 }}
              animate={{ y: 0, x: '-50%', opacity: 1 }}
              transition={{ duration: TRANSITION_DURATION.md }}
              exit={{ y: 5, x: '-50%', opacity: 0 }}
            >
              <Text className="whitespace-pre-wrap" size="sm">
                {isDisabled}
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </button>
  )
}

export const TabButton = forwardRef(TabButtonInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TabButtonInner>
