import { map } from 'lodash'
import classNames from 'classnames'
import React, { Fragment, memo } from 'react'
import { Button, Icon, Skeleton, Text } from '@cotiss/common'

export type BreadcrumbModel = {
  label: string
  href?: string
  onClick?: () => void
  isLoading?: boolean
}

type Props = {
  className?: string
  breadcrumbs?: BreadcrumbModel[]
  onBack?: () => void
  backHref?: string
  isDisabled?: boolean
}

export const Breadcrumb = memo(({ className, breadcrumbs, backHref, onBack, isDisabled }: Props) => {
  const classes = classNames(className, 'flex items-center')

  if (!breadcrumbs || !breadcrumbs.length) {
    return null
  }

  const handleClick = (onClick?: () => void) => {
    onClick && onClick()
  }

  const handleBack = () => {
    onBack && onBack()
  }

  const renderBreadcrumb = (breadcrumb: BreadcrumbModel) => {
    if (breadcrumb.isLoading) {
      return <Skeleton className="h-2 w-32 mt-1.5" variant="gray" />
    }

    if (breadcrumb.href || breadcrumb.onClick) {
      return (
        <Button className="mr-2" state="raw" variant="link" href={breadcrumb.href} onClick={() => handleClick(breadcrumb.onClick)} isLink>
          <Text className="font-medium truncate max-w-[150px]" size="sm" variant="link" title={breadcrumb.label}>
            {breadcrumb.label}
          </Text>
        </Button>
      )
    }

    return (
      <Text className="font-medium truncate max-w-[150px] mr-2" size="sm" variant="light" title={breadcrumb.label}>
        {breadcrumb.label}
      </Text>
    )
  }

  return (
    <div className={classes}>
      {(onBack || backHref) && (
        <Button
          className="mr-2"
          onClick={() => handleBack()}
          href={backHref}
          state="translucent"
          variant="secondary"
          size="xs"
          isDisabled={isDisabled}
          isLink
        >
          <Icon icon="arrow-left" />
        </Button>
      )}
      <div className="flex items-center text-sm">
        {map(breadcrumbs, (breadcrumb, index) => (
          <Fragment key={index}>
            {renderBreadcrumb(breadcrumb)}
            {index !== breadcrumbs.length - 1 && <Text className="mr-2 -mt-0.5">/</Text>}
          </Fragment>
        ))}
      </div>
    </div>
  )
})
