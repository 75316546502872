import { Badge, Banner, Card, CardFormHeader, Field, NoDataPlaceholder, RichText, Skeleton, Text, utilService } from '@cotiss/common'
import { DocumentList } from '@cotiss/document'
import { MetafieldForm, useListMetafield } from '@cotiss/metafield'
import { useListMetafieldValue } from '@cotiss/metafield-value'
import { useGetProcurement } from '@cotiss/procurement'
import { useGetTender } from '@cotiss/tender'
import { map } from 'lodash'
import React, { memo } from 'react'

type Props = {
  tenderId: string
}

export const TenderPlanOverviewTab = memo(({ tenderId }: Props) => {
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const procurementId = tender?.procurement
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(procurementId)
  const { metafields } = useListMetafield({ entityType: 'PROCUREMENT_PLAN' })
  const { metafieldValues } = useListMetafieldValue({ resourceId: procurementId })
  const isLoading = isTenderLoading || isProcurementLoading

  return (
    <Card className="max-w-5xl">
      <CardFormHeader heading="Overview" />
      <Banner className="mb-4" icon="invisible" iconVariant="secondary" variant="light">
        <div>
          <Text className="font-semibold" variant="heading">
            The below information is for internal reference only
          </Text>
          <Text size="sm">External or invited parties will not have access to this information.</Text>
        </div>
      </Banner>
      <Field label="Title">
        {isLoading && <Skeleton className="h-4 w-1/3" />}
        {!isLoading && <Text className="font-medium">{procurement?.title}</Text>}
      </Field>
      <Field className="mt-8" label="Reference">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && <Text>{procurement?.internalIdentifier}</Text>}
      </Field>
      <Field className="mt-8" label="Internal reference">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.manualIdentifier && <Text>--</Text>}
            {procurement?.manualIdentifier && <Text>{procurement.manualIdentifier}</Text>}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Internal summary">
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
        {!isLoading && (
          <>
            {!procurement?.summary && <Text>--</Text>}
            {procurement?.summary && <RichText value={procurement.summary} />}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Regions">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.regions?.length && <Text>--</Text>}
            {Boolean(procurement?.regions?.length) && <Text>{procurement?.regions.join(', ')}</Text>}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Categories">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {Boolean(procurement?.topLevelClassification.length) &&
              map(procurement?.topLevelClassification, (classification) => (
                <Badge key={classification._id} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                  {classification.description}
                </Badge>
              ))}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Currency">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.totalBudget && <Text>--</Text>}
            {procurement?.totalBudget && <Text>{procurement.totalBudget.currency}</Text>}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Opex budget">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.opexBudget && <Text>--</Text>}
            {procurement?.opexBudget && <Text>{utilService.formatAsCurrency(procurement.opexBudget.amount, procurement.opexBudget.currency)}</Text>}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Capex budget">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.capexBudget && <Text>--</Text>}
            {procurement?.capexBudget && (
              <Text>{utilService.formatAsCurrency(procurement.capexBudget.amount, procurement.capexBudget.currency)}</Text>
            )}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Total budget">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {!procurement?.totalBudget && <Text>--</Text>}
            {procurement?.totalBudget && (
              <Text>{utilService.formatAsCurrency(procurement.totalBudget.amount, procurement.totalBudget.currency)}</Text>
            )}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Documents">
        {!isLoading && (
          <>
            {isLoading || (procurement?.attachments.length && <DocumentList documents={procurement.attachments} isLoading={isLoading} />)}
            {!isLoading && !procurement?.attachments.length && <NoDataPlaceholder label="No documents uploaded" />}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Market analysis">
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}

        {!isLoading && (
          <>
            {!procurement?.marketAnalysis && <Text>--</Text>}
            {procurement?.marketAnalysis && <RichText value={procurement.marketAnalysis} />}
          </>
        )}
      </Field>

      {procurementId &&
        map(metafields, (metafield) => (
          <div key={metafield._id} className="mt-8">
            <MetafieldForm resourceId={procurementId} metafield={metafield} metafieldValues={metafieldValues} />
          </div>
        ))}
    </Card>
  )
})
