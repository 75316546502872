import { sortBy, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreateEvaluationEnvelopeInput,
  GqlDeleteEvaluationEnvelopeInput,
  GqlEvaluationEnvelopeOverviewFieldsFragment,
  GqlEvaluationEnvelopeFieldsFragment,
  GqlEvaluationEnvelopeViewInput,
  GqlProgressEvaluationEnvelopeInput,
  GqlUpdateEvaluationEnvelopeInput,
  GqlUpdateEvaluationEnvelopeOrdersInput,
  GqlEvaluationEnvelopeListInput,
} from '@gql'
import {
  mutateCreateEvaluationEnvelope,
  mutateDeleteEvaluationEnvelope,
  mutateProgressEvaluationEnvelope,
  mutateUpdateEvaluationEnvelope,
  mutateUpdateEvaluationEnvelopeOrders,
  queryEvaluationEnvelopeDownloadCsv,
  queryEvaluationEnvelopeList,
  queryEvaluationEnvelopeOverviewList,
  queryEvaluationEnvelopeOverviewView,
  queryEvaluationEnvelopeView,
} from '@cotiss/evaluation-event'

const evaluationEnvelopeVar = makeVar<GqlEvaluationEnvelopeFieldsFragment | null>(null)
const evaluationEnvelopesVar = makeVar<GqlEvaluationEnvelopeFieldsFragment[]>([])
const evaluationEnvelopeOverviewVar = makeVar<GqlEvaluationEnvelopeOverviewFieldsFragment | null>(null)
const evaluationEnvelopeOverviewsVar = makeVar<GqlEvaluationEnvelopeOverviewFieldsFragment[]>([])

export const useEvaluationEnvelope = () => {
  const evaluationEnvelope = useReactiveVar(evaluationEnvelopeVar)
  const evaluationEnvelopes = useReactiveVar(evaluationEnvelopesVar)
  const evaluationEnvelopeOverview = useReactiveVar(evaluationEnvelopeOverviewVar)
  const evaluationEnvelopeOverviews = useReactiveVar(evaluationEnvelopeOverviewsVar)

  return {
    evaluationEnvelope,
    evaluationEnvelopes,
    evaluationEnvelopeOverview,
    evaluationEnvelopeOverviews,
    setEvaluationEnvelope: evaluationEnvelopeVar,
    setEvaluationEnvelopeOverview: evaluationEnvelopeOverviewVar,
    setEvaluationEnvelopeOverviews: evaluationEnvelopeOverviewsVar,
    queryEvaluationEnvelopeList: async (input: GqlEvaluationEnvelopeListInput) => {
      const { items: evaluationEnvelopes, pagination } = await queryEvaluationEnvelopeList(input)

      evaluationEnvelopesVar(sortBy(evaluationEnvelopes, 'order'))

      return { evaluationEnvelopes, pagination }
    },
    queryEvaluationEnvelopeView: async (input: GqlEvaluationEnvelopeViewInput) => {
      const evaluationEnvelope = await queryEvaluationEnvelopeView(input)

      evaluationEnvelopeVar(evaluationEnvelope)
      evaluationEnvelopesVar(sortBy(uniqBy([evaluationEnvelope, ...evaluationEnvelopes], 'id'), 'order'))

      return evaluationEnvelope
    },
    queryEvaluationEnvelopeDownloadCsv: async (input: GqlEvaluationEnvelopeViewInput) => {
      return await queryEvaluationEnvelopeDownloadCsv(input)
    },
    queryEvaluationEnvelopeOverviewList: async (input: GqlEvaluationEnvelopeListInput) => {
      const { items: evaluationEnvelopeOverviews, pagination } = await queryEvaluationEnvelopeOverviewList(input)

      evaluationEnvelopeOverviewsVar(evaluationEnvelopeOverviews)

      return { evaluationEnvelopeOverviews, pagination }
    },
    queryEvaluationEnvelopeOverviewView: async (input: GqlEvaluationEnvelopeViewInput) => {
      const evaluationEnvelopeOverview = await queryEvaluationEnvelopeOverviewView(input)

      evaluationEnvelopeOverviewVar(evaluationEnvelopeOverview)

      return evaluationEnvelopeOverview
    },
    mutateCreateEvaluationEnvelope: async (input: GqlCreateEvaluationEnvelopeInput) => {
      const createdEvaluationEnvelope = await mutateCreateEvaluationEnvelope(input)

      evaluationEnvelopeVar(createdEvaluationEnvelope)

      return createdEvaluationEnvelope
    },
    mutateUpdateEvaluationEnvelope: async (input: GqlUpdateEvaluationEnvelopeInput) => {
      const updatedEvaluationEnvelope = await mutateUpdateEvaluationEnvelope(input)

      evaluationEnvelopeVar(updatedEvaluationEnvelope)
      evaluationEnvelopesVar(sortBy(uniqBy([updatedEvaluationEnvelope, ...evaluationEnvelopes], 'id'), 'order'))

      return updatedEvaluationEnvelope
    },
    mutateUpdateEvaluationEnvelopeOrders: async (input: GqlUpdateEvaluationEnvelopeOrdersInput) => {
      const updatedEvaluationEnvelopes = await mutateUpdateEvaluationEnvelopeOrders(input)

      return updatedEvaluationEnvelopes
    },
    mutateProgressEvaluationEnvelope: async (input: GqlProgressEvaluationEnvelopeInput) => {
      const progressedEvaluationEnvelope = await mutateProgressEvaluationEnvelope(input)

      evaluationEnvelopeVar(progressedEvaluationEnvelope)
      evaluationEnvelopesVar(sortBy(uniqBy([progressedEvaluationEnvelope, ...evaluationEnvelopes], 'id'), 'order'))

      return progressedEvaluationEnvelope
    },
    mutateDeleteEvaluationEnvelope: async (input: GqlDeleteEvaluationEnvelopeInput) => {
      await mutateDeleteEvaluationEnvelope(input)
    },
  }
}

export const clearReactiveEvaluationEnvelope = async () => {
  evaluationEnvelopeVar(null)
  evaluationEnvelopesVar([])
  evaluationEnvelopeOverviewVar(null)
  evaluationEnvelopeOverviewsVar([])
}
