import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { GqlEvaluationUserInSessionViewInput, GqlEvaluationUserInSessionViewQuery } from '@gql'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationUserInSessionView = async (input: GqlEvaluationUserInSessionViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserInSessionViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      query EvaluationUserInSessionView($input: EvaluationUserInSessionViewInput!) {
        evaluationUserInSessionView(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserInSessionView
}
