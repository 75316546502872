import { ForumQuestionModel } from '@cotiss/forum'
import { useFetch, PaginatedModel } from '@cotiss/common'

type Props = {
  tenderId?: string
  limit?: number
  offset?: number
}

export const useListForumQuestion = ({ tenderId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ForumQuestionModel>>('/v2/forum-questions', {
    route: `/v2/forum-questions`,
    params: { tenderId, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { forumQuestions: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
