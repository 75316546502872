import { memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetDocument } from '@cotiss/document'

export const DocumentViewPage = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { document } = useGetDocument(id)

  useEffect(() => {
    if (document) {
      window.location.replace(document.downloadUrl)
    }
  }, [document])

  return null
})
