import { upperCase } from 'lodash'
import classNames from 'classnames'
import React, { memo } from 'react'
import { userService } from '@cotiss/user'
import { Avatar, Text } from '@cotiss/common'

type Props = {
  className?: string
  firstName?: string
  lastName?: string
  email?: string
  hasAvatar?: boolean

  // These are to support our old user model.
  firstname?: string
  surname?: string
}

export const UserLineItem = memo(({ className, firstname, firstName, surname, lastName, email, hasAvatar = true }: Props) => {
  const classes = classNames(className, 'flex items-center justify-between')

  const getInitials = () => {
    if (firstName && lastName) {
      return userService.getInitials({ firstName, lastName })
    } else if (firstname && surname) {
      return userService.getInitials({ firstname, surname })
    } else if (email) {
      return upperCase(email.charAt(0))
    } else {
      return '?'
    }
  }

  const getFullName = () => {
    if (firstName && lastName) {
      return userService.getFullName({ firstName, lastName })
    } else if (firstname && surname) {
      return userService.getFullName({ firstname, surname })
    }
  }

  const fullName = getFullName()

  return (
    <div className={classes}>
      <div className="flex items-center">
        {hasAvatar && <Avatar className="mr-2">{getInitials()}</Avatar>}
        <div className="mr-2">
          {fullName && <Text className="font-medium">{fullName}</Text>}
          <Text size="sm" variant="light">
            {email}
          </Text>
        </div>
      </div>
    </div>
  )
})
