import React, { FormEvent, memo, useState } from 'react'
import { TenderPopulatedModel } from '@cotiss/tender'
import { useMutateTenderQuestion } from '@cotiss/tender-question'
import { EVALUATION_RATING_SCALE_OPTIONS_MAP, EvaluationModel, useMutateEvaluation } from '@cotiss/evaluation'
import {
  AccordionContent,
  Button,
  Form,
  Dropdown,
  DropdownContent,
  Icon,
  Label,
  Select_DEPRECATED,
  Text,
  TextArea,
  sentryService,
  useToast,
} from '@cotiss/common'

export type EvaluationCriteriaScoreFormDataItem = {
  criterionId: string
  questionId: string
  tenderQuestionScoreId: string
  question: string
  score: string
  commentary: string
}

type Props = {
  evaluation: EvaluationModel
  tender: TenderPopulatedModel
  evaluationCriteriaScore: EvaluationCriteriaScoreFormDataItem
  isEditable?: boolean
  isLast?: boolean
}

export const EvaluationCriteriaAccordionItem = memo(({ tender, evaluation, evaluationCriteriaScore, isEditable, isLast }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateEvaluation } = useMutateEvaluation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCommentaryOpen, setIsCommentaryOpen] = useState(false)
  const { updateTenderQuestionScore } = useMutateTenderQuestion()
  const [commentary, setCommentary] = useState(evaluationCriteriaScore.commentary || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTenderQuestionScore(evaluationCriteriaScore.tenderQuestionScoreId, { commentary })
      setIsCommentaryOpen(false)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleScoreChange = async (score: string) => {
    try {
      setIsSaving(true)

      if (evaluation.status === 'notStarted') {
        await updateEvaluation(evaluation._id, { status: 'inProgress' })
      }

      await updateTenderQuestionScore(evaluationCriteriaScore.tenderQuestionScoreId, { score: score === '' ? null : Number(score) })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleCommentary = (isCommentaryOpen: boolean) => {
    setIsCommentaryOpen(isCommentaryOpen)
    setCommentary(evaluationCriteriaScore.commentary)
  }

  return (
    <AccordionContent className="ml-6" isLast={isLast}>
      <div className="w-full">
        <div className="flex items-center justify-between">
          <Text className="whitespace-pre-wrap mr-2" size="sm">
            {evaluationCriteriaScore.question}
          </Text>
          <div className="flex items-center space-x-2 self-start">
            <Select_DEPRECATED
              value={evaluationCriteriaScore.score}
              options={(tender.ratingScale && EVALUATION_RATING_SCALE_OPTIONS_MAP[tender.ratingScale]) || []}
              onChange={handleScoreChange}
              size="sm"
              isDisabled={!isEditable || isSaving}
            />

            {isEditable && (
              <Button onClick={() => handleToggleCommentary(true)} state="ghost" shape="square" size="sm" isDisabled={isSaving}>
                <Icon icon="comment" variant="light" />
              </Button>
            )}
          </div>
        </div>
        {(isCommentaryOpen || evaluationCriteriaScore.commentary) && (
          <div className="mt-2">
            {isCommentaryOpen && (
              <Form onSubmit={handleSubmit}>
                <Label>Comment</Label>
                <div className="border-l-2 border-secondary-500 pl-2 ml-2">
                  <TextArea
                    value={commentary}
                    onChange={({ target }) => setCommentary(target.value)}
                    placeholder="Add comments..."
                    isDisabled={isSaving}
                    isRequired
                  />
                </div>
                <Button
                  className="ml-5"
                  type="submit"
                  state="text"
                  variant={!commentary ? 'light' : 'link'}
                  isDisabled={!commentary}
                  isLoading={isSaving}
                >
                  Save
                </Button>
                <Button className="ml-2" onClick={() => handleToggleCommentary(false)} state="text" variant="link" isDisabled={isSaving}>
                  Cancel
                </Button>
              </Form>
            )}
            {!isCommentaryOpen && (
              <div className="relative flex items-start justify-between border-l-2 border-secondary-500 pl-2 ml-2">
                <Text className="whitespace-pre-wrap mr-4" variant="light" size="sm">
                  {evaluationCriteriaScore.commentary}
                </Text>
                {isEditable && (
                  <Button
                    className="absolute top-0 right-0"
                    onClick={() => setIsDropdownOpen(true)}
                    shape="square"
                    state="ghost"
                    isDisabled={isSaving}
                    size="xs"
                  >
                    <Icon icon="dots" variant="light" size={20} />
                  </Button>
                )}
                <Dropdown className="top-4 right-2" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  <DropdownContent className="flex items-center" onClick={() => handleToggleCommentary(true)}>
                    Edit
                  </DropdownContent>
                </Dropdown>
              </div>
            )}
          </div>
        )}
      </div>
    </AccordionContent>
  )
})
