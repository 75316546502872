import React, { memo } from 'react'
import { ProcurementViewTenderTabs, useGetProcurement } from '@cotiss/procurement'
import { useGetOrganisation } from '@cotiss/organisation'
import { TENDER_TYPE_NAME_MAP, TenderHistoryModal, useGetTender } from '@cotiss/tender'
import { Badge, Button, Field, RichText, Skeleton, Text, datetimeService, useCallout } from '@cotiss/common'
import { map } from 'lodash'

type Props = {
  tenderId?: string
  onTabChange: (tab: ProcurementViewTenderTabs) => void
  isDisabled?: boolean
}

export const ProcurementViewTenderDetailsTab = memo(({ tenderId, onTabChange, isDisabled }: Props) => {
  const { openModal } = useCallout()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(tender?.procurement)
  const { organisation, isLoading: isOrganisationLoading } = useGetOrganisation(procurement?.procuringEntity)
  const isLoading = isProcurementLoading || isOrganisationLoading || isTenderLoading

  return (
    <>
      <Field label="Title">
        {isLoading && <Skeleton className="h-4 w-1/3" />}
        {!isLoading && (
          <Text className="font-medium" size="lg">
            {tender?.title}
          </Text>
        )}
      </Field>
      <Field className="mt-8" label="Regions">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && <Text>{tender?.regions.join(', ')}</Text>}
      </Field>
      <Field className="mt-8" label="Categories">
        {isLoading && <Skeleton className="h-3 w-20" />}
        {!isLoading && (
          <>
            {map(tender?.categories, (classification) => (
              <Badge key={classification._id} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                {classification.description}
              </Badge>
            ))}
          </>
        )}
      </Field>
      <Field className="mt-8" label="Published date">
        {isLoading && <Skeleton className="h-3 w-28" />}
        {!isLoading && (
          <Text>{tender?.tenderPeriod?.startDate && datetimeService.format(tender.tenderPeriod.startDate, 'd MMMM yyyy h:mm aaa')}</Text>
        )}
      </Field>
      <Field
        className="mt-8"
        label="Close date"
        supplementary={
          <Button
            variant="secondary"
            state="text"
            size="sm"
            onClick={() => openModal(<TenderHistoryModal tenderId={tenderId} field="tenderPeriod" />)}
          >
            View history
          </Button>
        }
      >
        {isLoading && <Skeleton className="h-3 w-28" />}
        {!isLoading && <Text>{tender?.tenderPeriod?.endDate && datetimeService.format(tender.tenderPeriod.endDate, 'd MMMM yyyy h:mm aaa')}</Text>}
      </Field>
      <Field
        className="mt-8"
        label="Forum close date"
        supplementary={
          <Button
            variant="secondary"
            state="text"
            size="sm"
            onClick={() => openModal(<TenderHistoryModal tenderId={tenderId} field="forumCloseDate" />)}
          >
            View history
          </Button>
        }
      >
        {isLoading && <Skeleton className="h-3 w-28" />}
        {!isLoading && (
          <Text>{tender?.forumCloseDate?.endDate && datetimeService.format(tender.forumCloseDate.endDate, 'd MMMM yyyy h:mm aaa')}</Text>
        )}
      </Field>
      <Field className="mt-8" label="Organisation">
        {isLoading && <Skeleton className="h-3 w-2/3" />}
        {!isLoading && <Text>{organisation?.name}</Text>}
      </Field>
      <Field className="mt-8" label="Brief description">
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
        {!isLoading && <Text className="whitespace-pre-wrap">{tender?.briefDescription}</Text>}
      </Field>
      <Field className="mt-8" label="Summary">
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
        {!isLoading && (
          <Text>
            <RichText value={tender?.description} />
          </Text>
        )}
      </Field>
      <Field className="mt-8" label="Tender type">
        {isLoading && <Skeleton className="h-3 w-28" />}
        {!isLoading && <Text>{tender?.tenderType && TENDER_TYPE_NAME_MAP[tender.tenderType]}</Text>}
      </Field>
      <Field className="mt-8" label="Documents">
        <Button onClick={() => onTabChange('documents')} state="text" variant="link" isDisabled={isDisabled}>
          View and download documents
        </Button>
      </Field>
    </>
  )
})
