import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeleteEvaluationAccessControlsInput,
  GqlDeleteEvaluationAccessControlsMutation,
  GqlDeleteEvaluationAccessControlsMutationVariables,
} from '@gql'

export const mutateDeleteEvaluationAccessControls = async (input: GqlDeleteEvaluationAccessControlsInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationAccessControlsMutation, GqlDeleteEvaluationAccessControlsMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationAccessControls($input: DeleteEvaluationAccessControlsInput!) {
        deleteEvaluationAccessControls(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationAccessControls as boolean
}
