import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { ApprovalViewRequestPage, ApprovalViewPage } from '@cotiss/approval'

export const ApprovalLayout = memo(() => (
  <Switch>
    <Route path="/approval/view/:id" exact>
      <ApprovalViewPage />
    </Route>
    <Route path="/approval/view/:id/request/:tab?" exact>
      <ApprovalViewRequestPage />
    </Route>
  </Switch>
))
