import classNames from 'classnames'
import { filter, flatMap, map, some } from 'lodash'
import React, { ReactNode, memo, useMemo, useState } from 'react'
import { TableThAction, Pagination, PaginationModel, Skeleton, TableTd, TableTh } from '@cotiss/common'

export type TableRow = {
  content?: () => ReactNode
  cta?: ReactNode
  tdClassName?: string
  variant?: TableVariant
  colSpan?: number
  maxWidth?: number
  hasHover?: boolean
}

export type TableColumn = {
  heading?: ReactNode
  thClassName?: string
  rows: TableRow[]
  actions?: TableThAction[]
  onSort?: () => void
  colSpan?: number
  isThWrapped?: boolean
  isTruncated?: boolean
}

export type TableState = 'default' | 'split' | 'ghost'
export type TableVariant = 'primary' | 'white'

type Props = {
  className?: string
  state?: TableState
  variant?: TableVariant
  columns: TableColumn[]
  emptyCta?: ReactNode
  pagination?: PaginationModel
  onPageChange?: (page: number) => void
  isLoading?: boolean
}

export const Table = memo(({ className, state = 'default', variant = 'white', columns, emptyCta, pagination, onPageChange, isLoading }: Props) => {
  const classes = classNames(className, 'bg-white rounded-lg')
  const tableClasses = classNames('shrink-0 table-fixed bg-white w-full', {
    'border-separate border-spacing-y-2': state === 'split',
  })
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number | null>(null)

  // If there are no CTAs then the table will not have a row hover state.
  const hasCta = useMemo(() => some(flatMap(columns, 'rows'), 'cta'), [columns])

  const getRows = (columns: TableColumn[]) => {
    const rows: TableRow[][] = []

    if (isLoading) {
      for (let i = 0; i < 5; i++) {
        const cells: TableRow[] = []

        for (let j = 0; j < columns.length; j++) {
          cells.push({ hasHover: false, content: () => <Skeleton className="w-34 h-2" variant="gray" /> })
        }

        rows.push(cells)
      }

      return rows
    }

    // If the table is empty, and there is a CTA, we need to render a single row where the first column renders the CTA, and the rest are empty.
    if (emptyCta && !columns[0]?.rows.length) {
      const cells: TableRow[] = []

      for (let i = 0; i < columns.length; i++) {
        cells.push({ content: () => (!i ? emptyCta : <></>) })
      }

      rows.push(cells)
    }

    for (let i = 0; i < columns[0]?.rows.length; i++) {
      const cells: TableRow[] = []

      for (let j = 0; j < columns.length; j++) {
        cells.push({ variant, ...columns[j].rows[i] })
      }

      rows.push(cells)
    }

    return rows
  }

  return (
    <div className={classes}>
      <table className={tableClasses} onMouseLeave={() => setHighlightedRowIndex(null)}>
        <thead onMouseEnter={() => setHighlightedRowIndex(null)}>
          <tr>
            {/* We need to filter out any columns with no heading. This should only happen when a `colSpan` has been passed. */}
            {map(
              filter(columns, (column) => Boolean(column.heading)),
              (column, index) => (
                <TableTh
                  key={index}
                  className={column.thClassName}
                  state={state}
                  onSort={column.onSort}
                  actions={column.actions}
                  colSpan={column.colSpan}
                  isWrapped={column.isThWrapped}
                >
                  {column.heading}
                </TableTh>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {map(getRows(columns), (rows, rowIndex) => (
            <tr key={rowIndex} onMouseEnter={() => setHighlightedRowIndex(rowIndex)}>
              {map(
                filter(rows, (row) => Boolean(row.content)),
                (cell, columnIndex) => {
                  const column = columns[columnIndex]
                  const isHovered = highlightedRowIndex === rowIndex
                  const isHighlighted = (cell.hasHover === undefined ? true : cell.hasHover) && isHovered && hasCta
                  const ctaClasses = classNames('absolute right-0 top-0 flex items-center justify-between border-l border-gray-200 h-full px-3', {
                    'bg-white': variant === 'white',
                    'bg-primary-50': variant === 'primary',
                  })

                  return (
                    <TableTd
                      key={columnIndex}
                      className={cell.tdClassName}
                      state={state}
                      variant={cell.variant}
                      colSpan={cell.colSpan}
                      isHighlighted={isHighlighted}
                      isWrapped={!column.isTruncated}
                    >
                      {cell.content && cell.content()}
                      {cell.cta && isHovered && <div className={ctaClasses}>{cell.cta}</div>}
                    </TableTd>
                  )
                }
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {Boolean(pagination?.pageCount && pagination.pageCount > 1 && onPageChange) && (
        <div className="p-4">{pagination && onPageChange && <Pagination pagination={pagination} onPageChange={onPageChange} />}</div>
      )}
    </div>
  )
})
