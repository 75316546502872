import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { ConflictOfInterestViewPage } from '@cotiss/conflict-of-interest'
import { ProcurementOverviewPage, ProcurementViewPage } from '@cotiss/procurement'

export const ProcurementLayout = memo(() => (
  <Switch>
    <Route path="/procurement/view/:procurementId/:tab?/:nestedTab?" exact>
      <ProcurementViewPage />
    </Route>
    <Route path="/procurement/overview/:procurementId/:tab?/:nestedTab?" exact>
      <ProcurementOverviewPage />
    </Route>
    <Route path="/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
  </Switch>
))
