import { PaginatedModel, useFetch } from '@cotiss/common'
import { PreferredSupplierBuyerPopulatedModel } from '@cotiss/preferred-supplier'

type Props = {
  limit?: number
  offset?: number
}

export const useListPreferredSupplierBuyer = ({ limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<PreferredSupplierBuyerPopulatedModel>>('/v2/preferred-suppliers/buyer', {
    route: `/v2/preferred-suppliers/buyer`,
    params: { limit, offset },
  })

  return { preferredSupplierBuyers: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
