import { map } from 'lodash'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useCallout } from '@cotiss/common'
import { useGetProcurement } from '@cotiss/procurement'
import { TenderFlowRequestDraftCard } from '@cotiss/tender-flow'
import { TenderCreateModal, TenderSummaryCard, TenderSummaryCardSkeleton } from '@cotiss/tender'

export const ProcurementOverviewAllRequestsTab = memo(() => {
  const { openModal } = useCallout()
  const { procurementId } = useParams<{ procurementId: string }>()
  const { procurement, isLoading } = useGetProcurement(procurementId)

  return (
    <div className="flex flex-wrap">
      {isLoading && <TenderSummaryCardSkeleton />}
      {map(procurement?.tenders, (tender) => tender.tenderType && <TenderSummaryCard key={tender._id} className="mr-4 mb-4" tender={tender} />)}
      {procurement && (
        <TenderFlowRequestDraftCard className="mr-4 mb-4" onCreateRequest={() => openModal(<TenderCreateModal procurementId={procurementId} />)} />
      )}
    </div>
  )
})
