import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import { GqlApprovalTemplateViewInput, GqlApprovalTemplateViewQuery } from '@gql'

export const queryApprovalTemplateView = async (input: GqlApprovalTemplateViewInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      query ApprovalTemplateView($input: ApprovalTemplateViewInput!) {
        approvalTemplateView(input: $input) {
          ...ApprovalTemplateFields
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateView
}
