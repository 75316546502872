import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardMetricUserListInput, GqlPerformanceScorecardMetricUserListQuery } from '@gql'

export const queryPerformanceScorecardMetricUserList = async (input: GqlPerformanceScorecardMetricUserListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricUserListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      query PerformanceScorecardMetricUserList($input: PerformanceScorecardMetricUserListInput!) {
        performanceScorecardMetricUserList(input: $input) {
          items {
            ...PerformanceScorecardMetricUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricUserList
}
