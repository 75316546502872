import React, { memo } from 'react'
import { Card, Hr } from '@cotiss/common'
import {
  SettingsGeneralFormEmail,
  SettingsGeneralFormName,
  SettingsGeneralFormPassword,
  SettingsGeneralFormPhone,
  SettingsGeneralTwoFactorForm,
} from '@cotiss/settings'

export const SettingsGeneralTab = memo(() => (
  <Card>
    <SettingsGeneralFormEmail />
    <Hr className="my-4" />
    <SettingsGeneralFormName />
    <Hr className="my-4" />
    <SettingsGeneralFormPhone />
    <Hr className="my-4" />
    <SettingsGeneralFormPassword />
    <Hr className="my-4" />
    <SettingsGeneralTwoFactorForm />
  </Card>
))
