import classNames from 'classnames'
import { every, filter, find, map } from 'lodash'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { TabModel, TabButton, TabsActiveIndicator } from '@cotiss/common'

export type TabsVariant = 'square' | 'rounded' | 'underline'

type Props<T> = {
  className?: string
  onChange?: (activeTab: TabModel<T>) => void
  tab?: T | null
  tabs: TabModel<T>[]
  variant?: TabsVariant
  isDisabled?: boolean | string
}

export function Tabs<T>({ className, tab, tabs, onChange, variant = 'square', isDisabled }: Props<T>) {
  const tabButtonRefs = useRef<HTMLButtonElement[]>([])
  const initialTabById = tab && find(tabs, ({ id }) => Boolean(id && id === tab))
  const [tabsToManage, setTabs] = useState<TabModel<T>[]>(tabs)
  const [activeTab, setActiveTab] = useState<TabModel<T>>(initialTabById || tabs[0])
  const classes = classNames(className, 'relative inline-flex items-center', {
    'rounded-md h-8 p-0.5': variant === 'square',
    'rounded-full p-0.5': variant === 'rounded',
  })

  const visibleTabs = useMemo(() => filter(tabsToManage, ({ isHidden }) => !isHidden), [tabsToManage])

  useEffect(() => {
    setTabs(tabs)
    tabButtonRefs.current = []
  }, [tabs])

  useEffect(() => {
    const activeTab = tab && find(tabs, ({ id }) => Boolean(id && id === tab))

    activeTab && setActiveTab(activeTab)
  }, [tab])

  const handleSetActiveTab = (activeTab: TabModel<T>) => {
    setActiveTab(activeTab)

    if (onChange) {
      onChange(activeTab)
    }
  }

  if (!activeTab || !every(tabs, ({ id: idToCheck }) => find(tabsToManage, { id: idToCheck }))) {
    return null
  }

  return (
    <div className={classes}>
      <TabsActiveIndicator<T> tabButtonRefs={tabButtonRefs} variant={variant} activeTab={activeTab} tabs={tabsToManage} />
      {map(visibleTabs, (tab, index) => (
        <TabButton<T>
          key={index}
          variant={variant}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          tabs={tabsToManage}
          isDisabled={isDisabled}
          ref={(ref) => ref && tabButtonRefs.current.push(ref)}
          {...tab}
        />
      ))}
    </div>
  )
}
