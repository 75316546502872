import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Button, Icon, Label, Text, Tooltip } from '@cotiss/common'

type Props = {
  className?: string
  children?: ReactNode
  label: ReactNode
  supplementary?: ReactNode
  // Adding null to the type for simplicity when working with nullable fields from graphql
  textValue?: string | null
  onClick?: () => void
  isTruncated?: boolean
  isDisabled?: boolean
}

export const EditableField = memo(({ className, children, label, supplementary, textValue, onClick, isTruncated, isDisabled }: Props) => {
  const hasValue = Boolean(children || textValue)
  const classes = classNames(className, 'max-w-5xl flex shrink-0 items-start justify-between')
  const readOnlyTextClasses = classNames('w-full max-w-md', { truncate: isTruncated })
  const editableTextClasses = classNames('w-full max-w-md', {
    'group-hover:text-secondary-500': hasValue,
    'whitespace-pre-wrap': !isTruncated,
    truncate: isTruncated,
  })

  const renderClickHandlerContent = () => {
    if (!onClick || isDisabled) {
      return null
    }

    if (hasValue) {
      return (
        <div className="flex justify-between items-start w-full">
          <Button state="text" onClick={onClick} className="rounded-md group p-3 w-full hover:bg-primary-50">
            <div className="w-full flex items-start justify-between">
              {textValue && (
                <Text className={editableTextClasses} title={textValue}>
                  {textValue}
                </Text>
              )}
              {children && !textValue && <div className={editableTextClasses}>{children}</div>}
              <Icon className="ml-2" icon="edit" />
            </div>
          </Button>
        </div>
      )
    }

    return (
      <Button className="w-full mt-3" state="text" onClick={onClick}>
        <Text variant="secondary" className="flex items-center max-w-md">
          <Icon icon="plus" className="mr-1 stroke-2" size={12} />
          Add {label}
        </Text>
      </Button>
    )
  }

  return (
    <div className={classes}>
      <div className="shrink-0 w-1/3 mr-8">
        <Label className="mt-3">{label}</Label>
        {supplementary && (
          <Text className="mt-1" variant="light" size="sm">
            {supplementary}
          </Text>
        )}
      </div>
      <div className="w-2/3 min-w-0">
        {(isDisabled || !onClick) && (
          <div className="flex justify-between items-center p-3 w-full">
            {!textValue && !children && (
              <Text className={readOnlyTextClasses} variant="light">
                --
              </Text>
            )}
            {textValue && !children && (
              <Text className={readOnlyTextClasses} variant="light" title={textValue}>
                {textValue}
              </Text>
            )}
            {!textValue && children && <div className={readOnlyTextClasses}>{children}</div>}
            <Tooltip tooltip="This field cannot be changed" className="flex items-center">
              <Icon className="text-light" icon="lock" />
            </Tooltip>
          </div>
        )}
        {renderClickHandlerContent()}
      </div>
    </div>
  )
})
