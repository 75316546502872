import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PLAN_USER_FRAGMENT } from '@cotiss/plan-event'
import { GqlPlanUserInSessionViewInput, GqlPlanUserInSessionViewQuery } from '@gql'

export const queryPlanUserInSessionView = async (input: GqlPlanUserInSessionViewInput) => {
  const { data } = await apolloService.query<GqlPlanUserInSessionViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PLAN_USER_FRAGMENT}

      query PlanUserInSessionView($input: PlanUserInSessionViewInput!) {
        planUserInSessionView(input: $input) {
          ...PlanUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.planUserInSessionView
}
