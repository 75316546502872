import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BaseVariant, Text } from '@cotiss/common'
import { ContractVariationStatus } from '@cotiss/contract'

const getVariant = (status: ContractVariationStatus): BaseVariant => {
  switch (status) {
    case 'DRAFTING':
      return 'warning'
    case 'PENDING_APPROVAL':
    case 'PENDING_SIGNATURES':
      return 'info'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ContractVariationStatus
  size?: BadgeSize
}

export const ContractVariationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => {
  if (status === 'ACTIVE' || status === 'NONE') {
    return <Text>--</Text>
  }
  return (
    <Badge className={className} size={size} variant={getVariant(status)} state="dot">
      {upperFirst(lowerCase(status))}
    </Badge>
  )
})
