import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreateEvaluationEnvelopeDocument,
  mutateCreateEvaluationEnvelopeDocuments,
  mutateDeleteEvaluationEnvelopeDocument,
  queryEvaluationEnvelopeDocumentList,
} from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationEnvelopeDocumentInput,
  GqlCreateEvaluationEnvelopeDocumentsInput,
  GqlDeleteEvaluationEnvelopeDocumentInput,
  GqlEvaluationEnvelopeDocumentFieldsFragment,
  GqlEvaluationEnvelopeDocumentListInput,
} from '@gql'

const evaluationEnvelopeDocumentVar = makeVar<GqlEvaluationEnvelopeDocumentFieldsFragment | null>(null)
const evaluationEnvelopeDocumentsVar = makeVar<GqlEvaluationEnvelopeDocumentFieldsFragment[]>([])

export const useEvaluationEnvelopeDocument = () => {
  const evaluationEnvelopeDocument = useReactiveVar(evaluationEnvelopeDocumentVar)
  const evaluationEnvelopeDocuments = useReactiveVar(evaluationEnvelopeDocumentsVar)

  return {
    evaluationEnvelopeDocument,
    evaluationEnvelopeDocuments,
    setEvaluationEnvelopeDocument: evaluationEnvelopeDocumentVar,
    setEvaluationEnvelopeDocuments: evaluationEnvelopeDocumentsVar,
    queryEvaluationEnvelopeDocumentList: async (input: GqlEvaluationEnvelopeDocumentListInput) => {
      const { items: evaluationEnvelopeDocuments, pagination } = await queryEvaluationEnvelopeDocumentList(input)

      evaluationEnvelopeDocumentsVar(evaluationEnvelopeDocuments)

      return { evaluationEnvelopeDocuments, pagination }
    },
    mutateCreateEvaluationEnvelopeDocument: async (input: GqlCreateEvaluationEnvelopeDocumentInput) => {
      const createdEvaluationEnvelopeDocument = await mutateCreateEvaluationEnvelopeDocument(input)

      evaluationEnvelopeDocumentsVar(uniqBy([createdEvaluationEnvelopeDocument, ...evaluationEnvelopeDocuments], 'id'))

      return createdEvaluationEnvelopeDocument
    },
    mutateCreateEvaluationEnvelopeDocuments: async (input: GqlCreateEvaluationEnvelopeDocumentsInput) => {
      const createdEvaluationEnvelopeDocuments = await mutateCreateEvaluationEnvelopeDocuments(input)

      evaluationEnvelopeDocumentsVar(uniqBy([...createdEvaluationEnvelopeDocuments, ...evaluationEnvelopeDocuments], 'id'))

      return createdEvaluationEnvelopeDocuments
    },
    mutateDeleteEvaluationEnvelopeDocument: async (input: GqlDeleteEvaluationEnvelopeDocumentInput) => {
      await mutateDeleteEvaluationEnvelopeDocument(input)

      evaluationEnvelopeDocumentsVar(filter(evaluationEnvelopeDocuments, ({ id }) => id !== input.evaluationEnvelopeDocumentId))
    },
  }
}

export const clearReactiveEvaluationEnvelopeDocument = async () => {
  evaluationEnvelopeDocumentVar(null)
  evaluationEnvelopeDocumentsVar([])
}
