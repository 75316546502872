import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

export const Header = memo(({ className, children }: Props) => {
  const classes = classNames(className, 'sticky top-0 bg-white shadow-sm px-10 py-4 z-20')

  return <header className={classes}>{children}</header>
})
