import { PaginatedModel, useFetch } from '@cotiss/common'
import { ChairEvaluationModel } from '@cotiss/chair-evaluation'

type Props = {
  limit?: number
  offset?: number
  userId?: string
  tenderId?: string
}

export const useListChairEvaluation = (props: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ChairEvaluationModel>>('/v2/chair-evaluations', {
    route: `/v2/chair-evaluations`,
    params: { offset: 0, limit: -1, ...props },
    isEnabled: Boolean(props.userId || props.tenderId),
  })

  return { chairEvaluations: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
