import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationSubmissionInput,
  GqlCreateEvaluationSubmissionMutation,
  GqlCreateEvaluationSubmissionMutationVariables,
  GqlEvaluationSubmissionFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationSubmission = async (input: GqlCreateEvaluationSubmissionInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationSubmissionMutation, GqlCreateEvaluationSubmissionMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_FRAGMENT}

      mutation CreateEvaluationSubmission($input: CreateEvaluationSubmissionInput!) {
        createEvaluationSubmission(input: $input) {
          ...EvaluationSubmissionFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationSubmission as GqlEvaluationSubmissionFieldsFragment
}
