import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlProgressEvaluationEnvelopeInput,
  GqlProgressEvaluationEnvelopeMutation,
  GqlProgressEvaluationEnvelopeMutationVariables,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'

export const mutateProgressEvaluationEnvelope = async (input: GqlProgressEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<GqlProgressEvaluationEnvelopeMutation, GqlProgressEvaluationEnvelopeMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      mutation ProgressEvaluationEnvelope($input: ProgressEvaluationEnvelopeInput!) {
        progressEvaluationEnvelope(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data?.progressEvaluationEnvelope as GqlEvaluationEnvelopeFieldsFragment
}
