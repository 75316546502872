import React, { memo } from 'react'
import { upperFirst, lowerCase } from 'lodash'
import { GqlEvaluationStatus } from '@gql'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'

const getVariant = (status?: GqlEvaluationStatus): BaseVariant => {
  switch (status) {
    case 'inProgress':
      return 'warning'
    case 'complete':
      return 'success'
    case 'abstained':
      return 'info'
    case 'notStarted':
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status?: GqlEvaluationStatus
  size?: BadgeSize
}

export const EvaluationEventEvaluationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status)) || 'Not started'}
  </Badge>
))
