import { gql } from '@apollo/client'

export const EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT = gql`
  fragment EvaluationSubmissionDocumentFields on EvaluationSubmissionDocument {
    id
    evaluationSubmissionId
    evaluationEnvelopeId
    envelope {
      id
      name
    }
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
