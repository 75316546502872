import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@cotiss/common'
import { PlanEventListPage, PlanEventViewPage } from '@cotiss/plan-event'

export const PlanEventLayout = memo(() => {
  return (
    <Switch>
      <Route path="/plan-event/list/:tab?" exact>
        <PlanEventListPage />
      </Route>
      <Route path="/plan-event/view/:planEventId/:tab?/:nestedTab?" exact>
        <PlanEventViewPage />
      </Route>
      <Redirect from="/plan-event" to="/plan-event/list/:tab?" exact />
    </Switch>
  )
})
