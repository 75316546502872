import React, { memo } from 'react'
import { Text, TableHeader } from '@cotiss/common'
import { EvaluationEventPanelList } from '@cotiss/evaluation-event'

export const EvaluationEventSummaryPanel = memo(() => (
  <>
    <TableHeader>
      <Text className="font-semibold truncate" variant="heading" font="jakarta">
        Your evaluation panel
      </Text>
    </TableHeader>
    <EvaluationEventPanelList />
  </>
))
