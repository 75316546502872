import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlDeleteApprovalTemplateUsersInput, GqlDeleteApprovalTemplateUsersMutation, GqlDeleteApprovalTemplateUsersMutationVariables } from '@gql'

export const mutateDeleteApprovalTemplateUsers = async (input: GqlDeleteApprovalTemplateUsersInput) => {
  const { data } = await apolloService.mutate<GqlDeleteApprovalTemplateUsersMutation, GqlDeleteApprovalTemplateUsersMutationVariables>({
    mutation: gql`
      mutation DeleteApprovalTemplateUsers($input: DeleteApprovalTemplateUsersInput!) {
        deleteApprovalTemplateUsers(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteApprovalTemplateUsers as boolean
}
