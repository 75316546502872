import React, { ReactNode, useState, MouseEvent as ReactMouseEvent } from 'react'
import { Button, Dropdown, DropdownContent } from '@cotiss/common'

type Props = {
  dropdownContent: ReactNode
  isDisabled?: boolean
  children: ReactNode
}

export const DropdownButton = ({ dropdownContent, isDisabled, children }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()

    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div className="relative">
      <Button isDisabled={isDisabled} state="text" onClick={handleClick}>
        {children}
      </Button>
      <Dropdown className="absolute" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent>{dropdownContent}</DropdownContent>
      </Dropdown>
    </div>
  )
}
