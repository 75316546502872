import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlDuplicateEvaluationEventInput,
  GqlDuplicateEvaluationEventMutation,
  GqlDuplicateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'

export const mutateDuplicateEvaluationEvent = async (input: GqlDuplicateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlDuplicateEvaluationEventMutation, GqlDuplicateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation DuplicateEvaluationEvent($input: DuplicateEvaluationEventInput!) {
        duplicateEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.duplicateEvaluationEvent as GqlEvaluationEventFieldsFragment
}
