import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetTender } from '@cotiss/tender'
import { EvaluationListPrice } from '@cotiss/evaluation'
import { TenderResponseReportModel, TenderResponseReportPriceList } from '@cotiss/tender-response'

type Props = {
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const EvaluationPriceTab = memo(({ onView }: Props) => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)

  return (
    <>
      {tender?.priceTableEnabled ? (
        <EvaluationListPrice evaluationType="panel" onView={onView} tenderId={tenderId} />
      ) : (
        <TenderResponseReportPriceList tenderId={tenderId} onView={onView} />
      )}
    </>
  )
})
