import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState, BaseVariant } from '@cotiss/common'
import { ContractReminderModel } from '@cotiss/contract'

const getVariant = (reminder: ContractReminderModel): { variant: BaseVariant; text: string } => {
  if (reminder.complete) return { variant: 'success', text: 'Complete' }
  return { variant: 'neutral', text: 'Incomplete' }
}

type Props = {
  className?: string
  reminder: ContractReminderModel
  size?: BadgeSize
  state?: BadgeState
}

export const ContractReminderStatusBadge = memo(({ className, reminder, size = 'sm', state = 'translucent' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(reminder).variant} state={state}>
    {upperFirst(lowerCase(getVariant(reminder).text))}
  </Badge>
))
