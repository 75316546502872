import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { useGetTenderResponse, useMutateTenderResponse } from '@cotiss/tender-response'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderResponseId: string
}

export const TenderResponseNonPriceClarificationUploadModal = memo(({ tenderResponseId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { tenderResponse, isLoading } = useGetTenderResponse(tenderResponseId)
  const [nonPriceAttachmentsClarification, setNonPriceAttachmentsClarification] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!tenderResponse) {
      return null
    }

    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, {
        nonPriceAttachmentsClarification: [
          ...map(nonPriceAttachmentsClarification, ({ _id }) => _id),
          ...map(tenderResponse.nonPriceAttachmentsClarification, ({ _id }) => _id),
        ],
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload non-price clarification forms" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={nonPriceAttachmentsClarification} onChange={setNonPriceAttachmentsClarification} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={isLoading} isForm />
    </Form>
  )
})
