import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT = gql`
  fragment EvaluationEnvelopeOverviewSubmissionBreakdownFields on EvaluationEnvelopeOverviewSubmissionBreakdown {
    evaluationSubmissionId
    rawScoreSummary {
      averagePercentageScore
      averageWeightedPercentageScore
      averageEnvelopeWeightedPercentageScore
      averageOverallWeightedPercentageScore
    }
    moderatedScoreSummary {
      averagePercentageScore
      averageWeightedPercentageScore
      averageEnvelopeWeightedPercentageScore
      averageOverallWeightedPercentageScore
    }
    status
  }
`
