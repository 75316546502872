import { gql } from '@apollo/client'
import { PAGINATION_FRAGMENT, apolloService } from '@cotiss/common'
import { GqlEvaluationEnvelopeListInput, GqlEvaluationEnvelopeOverviewListQuery } from '@gql'
import {
  EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT,
  EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT,
  EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT,
} from '@cotiss/evaluation-event'

export const queryEvaluationEnvelopeOverviewList = async (input: GqlEvaluationEnvelopeListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeOverviewListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT}

      query EvaluationEnvelopeOverviewList($input: EvaluationEnvelopeListInput!) {
        evaluationEnvelopeOverviewList(input: $input) {
          items {
            ...EvaluationEnvelopeOverviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeOverviewList
}
