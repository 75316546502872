import { SsoConfigurationModel } from '@cotiss/auth/auth.models'
import { useFetch } from '@cotiss/common'

export const useGetSsoConfiguration = () => {
  const { data: configurations, ...rest } = useFetch<SsoConfigurationModel[]>('/v3/sso', {
    route: `/v3/sso/saml/configuration`,
  })

  return { configurations, ...rest }
}
