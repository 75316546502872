import React, { FormEvent, memo, useState } from 'react'
import { useGetSupplierProfile, useMutateSupplier } from '@cotiss/supplier'
import { Button, Form, Label, Skeleton, Text, TextArea, sentryService, useToast } from '@cotiss/common'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewGeneralFormAbout = memo(({ organisationId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateSupplierProfile } = useMutateSupplier()
  const { supplierProfile, isLoading } = useGetSupplierProfile(organisationId)
  const [formData, setFormData] = useState({ description: supplierProfile?.description || '' })

  const handleReset = () => {
    setFormData({ description: supplierProfile?.description || '' })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateSupplierProfile(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>About</Label>
        <TextArea
          className="mb-4"
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Cotiss is a simple procurement solution connecting buyers and suppliers..."
          isDisabled={isSaving}
          isRequired
        />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-start justify-between">
      <div className="w-full">
        <Label>About</Label>
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
        {!isLoading && <Text>{supplierProfile?.description || '--'}</Text>}
      </div>

      {isEditable && (
        <Button className="ml-4" onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm" isDisabled={isSaving}>
          Update
        </Button>
      )}
    </div>
  )
})
