[
  {
    "label": "Afghanistan",
    "options": [
      {
        "label": "Badghis",
        "value": "AF-BDG"
      },
      {
        "label": "Baghlan",
        "value": "AF-BGL"
      },
      {
        "label": "Balkh",
        "value": "AF-BAL"
      },
      {
        "label": "Bamyan",
        "value": "AF-BAM"
      },
      {
        "label": "Daykundi",
        "value": "AF-DAY"
      },
      {
        "label": "Farah",
        "value": "AF-FRA"
      },
      {
        "label": "Faryab",
        "value": "AF-FYB"
      },
      {
        "label": "Ghazni",
        "value": "AF-GHA"
      },
      {
        "label": "Ghor",
        "value": "AF-GHO"
      },
      {
        "label": "Helmand",
        "value": "AF-HEL"
      },
      {
        "label": "Herat",
        "value": "AF-HER"
      },
      {
        "label": "Jowzjan",
        "value": "AF-JOW"
      },
      {
        "label": "Kabul",
        "value": "AF-KAB"
      },
      {
        "label": "Kandahar",
        "value": "AF-KAN"
      },
      {
        "label": "Khost",
        "value": "AF-KHO"
      },
      {
        "label": "Kunduz",
        "value": "AF-KDZ"
      },
      {
        "label": "Laghman",
        "value": "AF-LAG"
      },
      {
        "label": "Logar",
        "value": "AF-LOG"
      },
      {
        "label": "Nangarhar",
        "value": "AF-NAN"
      },
      {
        "label": "Nimroz",
        "value": "AF-NIM"
      },
      {
        "label": "Paktika",
        "value": "AF-PKA"
      },
      {
        "label": "Paktiya",
        "value": "AF-PIA"
      },
      {
        "label": "Parwan",
        "value": "AF-PAR"
      },
      {
        "label": "Samangan",
        "value": "AF-SAM"
      },
      {
        "label": "Sar-e Pul",
        "value": "AF-SAR"
      },
      {
        "label": "Takhar",
        "value": "AF-TAK"
      },
      {
        "label": "Uruzgan",
        "value": "AF-URU"
      }
    ],
    "value": "AF"
  },
  {
    "label": "Åland Islands",
    "value": "AX"
  },
  {
    "label": "Albania",
    "options": [
      {
        "label": "Berat",
        "value": "AL-01"
      },
      {
        "label": "Diber",
        "value": "AL-09"
      },
      {
        "label": "Durres",
        "value": "AL-02"
      },
      {
        "label": "Elbasan",
        "value": "AL-03"
      },
      {
        "label": "Fier",
        "value": "AL-04"
      },
      {
        "label": "Gjirokaster",
        "value": "AL-05"
      },
      {
        "label": "Korce",
        "value": "AL-06"
      },
      {
        "label": "Kukes",
        "value": "AL-07"
      },
      {
        "label": "Lezhe",
        "value": "AL-08"
      },
      {
        "label": "Shkoder",
        "value": "AL-10"
      },
      {
        "label": "Tirane",
        "value": "AL-11"
      },
      {
        "label": "Vlore",
        "value": "AL-12"
      }
    ],
    "value": "AL"
  },
  {
    "label": "Algeria",
    "options": [
      {
        "label": "Adrar",
        "value": "DZ-01"
      },
      {
        "label": "Ain Defla",
        "value": "DZ-44"
      },
      {
        "label": "Ain Temouchent",
        "value": "DZ-46"
      },
      {
        "label": "Alger",
        "value": "DZ-16"
      },
      {
        "label": "Annaba",
        "value": "DZ-23"
      },
      {
        "label": "Batna",
        "value": "DZ-05"
      },
      {
        "label": "Bechar",
        "value": "DZ-08"
      },
      {
        "label": "Bejaia",
        "value": "DZ-06"
      },
      {
        "label": "Biskra",
        "value": "DZ-07"
      },
      {
        "label": "Blida",
        "value": "DZ-09"
      },
      {
        "label": "Bordj Bou Arreridj",
        "value": "DZ-34"
      },
      {
        "label": "Bouira",
        "value": "DZ-10"
      },
      {
        "label": "Boumerdes",
        "value": "DZ-35"
      },
      {
        "label": "Chlef",
        "value": "DZ-02"
      },
      {
        "label": "Constantine",
        "value": "DZ-25"
      },
      {
        "label": "Djanet",
        "value": "DZ-56"
      },
      {
        "label": "Djelfa",
        "value": "DZ-17"
      },
      {
        "label": "El Bayadh",
        "value": "DZ-32"
      },
      {
        "label": "El Meghaier",
        "value": "DZ-57"
      },
      {
        "label": "El Oued",
        "value": "DZ-39"
      },
      {
        "label": "El Tarf",
        "value": "DZ-36"
      },
      {
        "label": "Ghardaia",
        "value": "DZ-47"
      },
      {
        "label": "Guelma",
        "value": "DZ-24"
      },
      {
        "label": "Illizi",
        "value": "DZ-33"
      },
      {
        "label": "In Salah",
        "value": "DZ-53"
      },
      {
        "label": "Jijel",
        "value": "DZ-18"
      },
      {
        "label": "Khenchela",
        "value": "DZ-40"
      },
      {
        "label": "Laghouat",
        "value": "DZ-03"
      },
      {
        "label": "M'sila",
        "value": "DZ-28"
      },
      {
        "label": "Mascara",
        "value": "DZ-29"
      },
      {
        "label": "Medea",
        "value": "DZ-26"
      },
      {
        "label": "Mila",
        "value": "DZ-43"
      },
      {
        "label": "Mostaganem",
        "value": "DZ-27"
      },
      {
        "label": "Naama",
        "value": "DZ-45"
      },
      {
        "label": "Oran",
        "value": "DZ-31"
      },
      {
        "label": "Ouargla",
        "value": "DZ-30"
      },
      {
        "label": "Ouled Djellal",
        "value": "DZ-51"
      },
      {
        "label": "Oum el Bouaghi",
        "value": "DZ-04"
      },
      {
        "label": "Relizane",
        "value": "DZ-48"
      },
      {
        "label": "Saida",
        "value": "DZ-20"
      },
      {
        "label": "Setif",
        "value": "DZ-19"
      },
      {
        "label": "Sidi Bel Abbes",
        "value": "DZ-22"
      },
      {
        "label": "Skikda",
        "value": "DZ-21"
      },
      {
        "label": "Souk Ahras",
        "value": "DZ-41"
      },
      {
        "label": "Tamanrasset",
        "value": "DZ-11"
      },
      {
        "label": "Tebessa",
        "value": "DZ-12"
      },
      {
        "label": "Tiaret",
        "value": "DZ-14"
      },
      {
        "label": "Timimoun",
        "value": "DZ-49"
      },
      {
        "label": "Tindouf",
        "value": "DZ-37"
      },
      {
        "label": "Tipaza",
        "value": "DZ-42"
      },
      {
        "label": "Tissemsilt",
        "value": "DZ-38"
      },
      {
        "label": "Tizi Ouzou",
        "value": "DZ-15"
      },
      {
        "label": "Tlemcen",
        "value": "DZ-13"
      },
      {
        "label": "Touggourt",
        "value": "DZ-55"
      }
    ],
    "value": "DZ"
  },
  {
    "label": "American Samoa",
    "value": "AS"
  },
  {
    "label": "Andorra",
    "options": [
      {
        "label": "Andorra la Vella",
        "value": "AD-07"
      },
      {
        "label": "Canillo",
        "value": "AD-02"
      },
      {
        "label": "Encamp",
        "value": "AD-03"
      },
      {
        "label": "Escaldes-Engordany",
        "value": "AD-08"
      },
      {
        "label": "La Massana",
        "value": "AD-04"
      },
      {
        "label": "Ordino",
        "value": "AD-05"
      },
      {
        "label": "Sant Julia de Loria",
        "value": "AD-06"
      }
    ],
    "value": "AD"
  },
  {
    "label": "Angola",
    "options": [
      {
        "label": "Bengo",
        "value": "AO-BGO"
      },
      {
        "label": "Benguela",
        "value": "AO-BGU"
      },
      {
        "label": "Bie",
        "value": "AO-BIE"
      },
      {
        "label": "Cabinda",
        "value": "AO-CAB"
      },
      {
        "label": "Cuando Cubango",
        "value": "AO-CCU"
      },
      {
        "label": "Cuanza-Norte",
        "value": "AO-CNO"
      },
      {
        "label": "Cuanza-Sul",
        "value": "AO-CUS"
      },
      {
        "label": "Huambo",
        "value": "AO-HUA"
      },
      {
        "label": "Huila",
        "value": "AO-HUI"
      },
      {
        "label": "Luanda",
        "value": "AO-LUA"
      },
      {
        "label": "Lunda-Norte",
        "value": "AO-LNO"
      },
      {
        "label": "Lunda-Sul",
        "value": "AO-LSU"
      },
      {
        "label": "Malange",
        "value": "AO-MAL"
      },
      {
        "label": "Moxico",
        "value": "AO-MOX"
      },
      {
        "label": "Namibe",
        "value": "AO-NAM"
      },
      {
        "label": "Uige",
        "value": "AO-UIG"
      },
      {
        "label": "Zaire",
        "value": "AO-ZAI"
      }
    ],
    "value": "AO"
  },
  {
    "label": "Anguilla",
    "value": "AI"
  },
  {
    "label": "Antarctica",
    "value": "AQ"
  },
  {
    "label": "Antigua and Barbuda",
    "options": [
      {
        "label": "Barbuda",
        "value": "AG-10"
      },
      {
        "label": "Saint George",
        "value": "AG-03"
      },
      {
        "label": "Saint John",
        "value": "AG-04"
      },
      {
        "label": "Saint Mary",
        "value": "AG-05"
      },
      {
        "label": "Saint Paul",
        "value": "AG-06"
      },
      {
        "label": "Saint Peter",
        "value": "AG-07"
      },
      {
        "label": "Saint Philip",
        "value": "AG-08"
      }
    ],
    "value": "AG"
  },
  {
    "label": "Argentina",
    "options": [
      {
        "label": "Buenos Aires",
        "value": "AR-B"
      },
      {
        "label": "Catamarca",
        "value": "AR-K"
      },
      {
        "label": "Chaco",
        "value": "AR-H"
      },
      {
        "label": "Chubut",
        "value": "AR-U"
      },
      {
        "label": "Ciudad Autonoma de Buenos Aires",
        "value": "AR-C"
      },
      {
        "label": "Cordoba",
        "value": "AR-X"
      },
      {
        "label": "Corrientes",
        "value": "AR-W"
      },
      {
        "label": "Entre Rios",
        "value": "AR-E"
      },
      {
        "label": "Formosa",
        "value": "AR-P"
      },
      {
        "label": "Jujuy",
        "value": "AR-Y"
      },
      {
        "label": "La Pampa",
        "value": "AR-L"
      },
      {
        "label": "La Rioja",
        "value": "AR-F"
      },
      {
        "label": "Mendoza",
        "value": "AR-M"
      },
      {
        "label": "Misiones",
        "value": "AR-N"
      },
      {
        "label": "Neuquen",
        "value": "AR-Q"
      },
      {
        "label": "Rio Negro",
        "value": "AR-R"
      },
      {
        "label": "Salta",
        "value": "AR-A"
      },
      {
        "label": "San Juan",
        "value": "AR-J"
      },
      {
        "label": "San Luis",
        "value": "AR-D"
      },
      {
        "label": "Santa Cruz",
        "value": "AR-Z"
      },
      {
        "label": "Santa Fe",
        "value": "AR-S"
      },
      {
        "label": "Santiago del Estero",
        "value": "AR-G"
      },
      {
        "label": "Tierra del Fuego",
        "value": "AR-V"
      },
      {
        "label": "Tucuman",
        "value": "AR-T"
      }
    ],
    "value": "AR"
  },
  {
    "label": "Armenia",
    "options": [
      {
        "label": "Aragacotn",
        "value": "AM-AG"
      },
      {
        "label": "Ararat",
        "value": "AM-AR"
      },
      {
        "label": "Armavir",
        "value": "AM-AV"
      },
      {
        "label": "Erevan",
        "value": "AM-ER"
      },
      {
        "label": "Gegark'unik'",
        "value": "AM-GR"
      },
      {
        "label": "Kotayk'",
        "value": "AM-KT"
      },
      {
        "label": "Lori",
        "value": "AM-LO"
      },
      {
        "label": "Sirak",
        "value": "AM-SH"
      },
      {
        "label": "Syunik'",
        "value": "AM-SU"
      },
      {
        "label": "Tavus",
        "value": "AM-TV"
      },
      {
        "label": "Vayoc Jor",
        "value": "AM-VD"
      }
    ],
    "value": "AM"
  },
  {
    "label": "Aruba",
    "value": "AW"
  },
  {
    "label": "Australia",
    "options": [
      {
        "label": "Australian Capital Territory",
        "value": "AU-ACT"
      },
      {
        "label": "New South Wales",
        "value": "AU-NSW"
      },
      {
        "label": "Northern Territory",
        "value": "AU-NT"
      },
      {
        "label": "Queensland",
        "value": "AU-QLD"
      },
      {
        "label": "South Australia",
        "value": "AU-SA"
      },
      {
        "label": "Tasmania",
        "value": "AU-TAS"
      },
      {
        "label": "Victoria",
        "value": "AU-VIC"
      },
      {
        "label": "Western Australia",
        "value": "AU-WA"
      }
    ],
    "value": "AU"
  },
  {
    "label": "Austria",
    "options": [
      {
        "label": "Burgenland",
        "value": "AT-1"
      },
      {
        "label": "Karnten",
        "value": "AT-2"
      },
      {
        "label": "Niederosterreich",
        "value": "AT-3"
      },
      {
        "label": "Oberosterreich",
        "value": "AT-4"
      },
      {
        "label": "Salzburg",
        "value": "AT-5"
      },
      {
        "label": "Steiermark",
        "value": "AT-6"
      },
      {
        "label": "Tirol",
        "value": "AT-7"
      },
      {
        "label": "Vorarlberg",
        "value": "AT-8"
      },
      {
        "label": "Wien",
        "value": "AT-9"
      }
    ],
    "value": "AT"
  },
  {
    "label": "Azerbaijan",
    "options": [
      {
        "label": "Abseron",
        "value": "AZ-ABS"
      },
      {
        "label": "Agcabadi",
        "value": "AZ-AGC"
      },
      {
        "label": "Agdas",
        "value": "AZ-AGS"
      },
      {
        "label": "Agstafa",
        "value": "AZ-AGA"
      },
      {
        "label": "Agsu",
        "value": "AZ-AGU"
      },
      {
        "label": "Astara",
        "value": "AZ-AST"
      },
      {
        "label": "Baki",
        "value": "AZ-BA"
      },
      {
        "label": "Balakan",
        "value": "AZ-BAL"
      },
      {
        "label": "Barda",
        "value": "AZ-BAR"
      },
      {
        "label": "Beylaqan",
        "value": "AZ-BEY"
      },
      {
        "label": "Calilabad",
        "value": "AZ-CAL"
      },
      {
        "label": "Gadabay",
        "value": "AZ-GAD"
      },
      {
        "label": "Ganca",
        "value": "AZ-GA"
      },
      {
        "label": "Goranboy",
        "value": "AZ-GOR"
      },
      {
        "label": "Goycay",
        "value": "AZ-GOY"
      },
      {
        "label": "Goygol",
        "value": "AZ-GYG"
      },
      {
        "label": "Imisli",
        "value": "AZ-IMI"
      },
      {
        "label": "Ismayilli",
        "value": "AZ-ISM"
      },
      {
        "label": "Kurdamir",
        "value": "AZ-KUR"
      },
      {
        "label": "Lankaran",
        "value": "AZ-LA"
      },
      {
        "label": "Masalli",
        "value": "AZ-MAS"
      },
      {
        "label": "Mingacevir",
        "value": "AZ-MI"
      },
      {
        "label": "Naxcivan",
        "value": "AZ-NX"
      },
      {
        "label": "Neftcala",
        "value": "AZ-NEF"
      },
      {
        "label": "Oguz",
        "value": "AZ-OGU"
      },
      {
        "label": "Qabala",
        "value": "AZ-QAB"
      },
      {
        "label": "Qazax",
        "value": "AZ-QAZ"
      },
      {
        "label": "Quba",
        "value": "AZ-QBA"
      },
      {
        "label": "Qusar",
        "value": "AZ-QUS"
      },
      {
        "label": "Saatli",
        "value": "AZ-SAT"
      },
      {
        "label": "Sabirabad",
        "value": "AZ-SAB"
      },
      {
        "label": "Saki",
        "value": "AZ-SAK"
      },
      {
        "label": "Salyan",
        "value": "AZ-SAL"
      },
      {
        "label": "Samaxi",
        "value": "AZ-SMI"
      },
      {
        "label": "Samkir",
        "value": "AZ-SKR"
      },
      {
        "label": "Samux",
        "value": "AZ-SMX"
      },
      {
        "label": "Sirvan",
        "value": "AZ-SR"
      },
      {
        "label": "Siyazan",
        "value": "AZ-SIY"
      },
      {
        "label": "Sumqayit",
        "value": "AZ-SM"
      },
      {
        "label": "Tartar",
        "value": "AZ-TAR"
      },
      {
        "label": "Tovuz",
        "value": "AZ-TOV"
      },
      {
        "label": "Ucar",
        "value": "AZ-UCA"
      },
      {
        "label": "Xacmaz",
        "value": "AZ-XAC"
      },
      {
        "label": "Xizi",
        "value": "AZ-XIZ"
      },
      {
        "label": "Yardimli",
        "value": "AZ-YAR"
      },
      {
        "label": "Yevlax",
        "value": "AZ-YEV"
      },
      {
        "label": "Zaqatala",
        "value": "AZ-ZAQ"
      },
      {
        "label": "Zardab",
        "value": "AZ-ZAR"
      }
    ],
    "value": "AZ"
  },
  {
    "label": "Bahamas",
    "options": [
      {
        "label": "Black Point",
        "value": "BS-BP"
      },
      {
        "label": "Central Abaco",
        "value": "BS-CO"
      },
      {
        "label": "City of Freeport",
        "value": "BS-FP"
      },
      {
        "label": "East Grand Bahama",
        "value": "BS-EG"
      },
      {
        "label": "Harbour Island",
        "value": "BS-HI"
      },
      {
        "label": "Long Island",
        "value": "BS-LI"
      },
      {
        "label": "Moore's Island",
        "value": "BS-MI"
      },
      {
        "label": "New Providence",
        "value": "BS-NP"
      },
      {
        "label": "North Andros",
        "value": "BS-NS"
      },
      {
        "label": "North Eleuthera",
        "value": "BS-NE"
      },
      {
        "label": "San Salvador",
        "value": "BS-SS"
      },
      {
        "label": "South Eleuthera",
        "value": "BS-SE"
      },
      {
        "label": "West Grand Bahama",
        "value": "BS-WG"
      }
    ],
    "value": "BS"
  },
  {
    "label": "Bahrain",
    "options": [
      {
        "label": "Al 'Asimah",
        "value": "BH-13"
      },
      {
        "label": "Al Janubiyah",
        "value": "BH-14"
      },
      {
        "label": "Al Muharraq",
        "value": "BH-15"
      },
      {
        "label": "Ash Shamaliyah",
        "value": "BH-17"
      }
    ],
    "value": "BH"
  },
  {
    "label": "Bangladesh",
    "options": [
      {
        "label": "Barishal",
        "value": "BD-A"
      },
      {
        "label": "Chattogram",
        "value": "BD-B"
      },
      {
        "label": "Dhaka",
        "value": "BD-C"
      },
      {
        "label": "Khulna",
        "value": "BD-D"
      },
      {
        "label": "Rajshahi",
        "value": "BD-E"
      },
      {
        "label": "Rangpur",
        "value": "BD-F"
      },
      {
        "label": "Sylhet",
        "value": "BD-G"
      }
    ],
    "value": "BD"
  },
  {
    "label": "Barbados",
    "options": [
      {
        "label": "Christ Church",
        "value": "BB-01"
      },
      {
        "label": "Saint Andrew",
        "value": "BB-02"
      },
      {
        "label": "Saint George",
        "value": "BB-03"
      },
      {
        "label": "Saint James",
        "value": "BB-04"
      },
      {
        "label": "Saint John",
        "value": "BB-05"
      },
      {
        "label": "Saint Joseph",
        "value": "BB-06"
      },
      {
        "label": "Saint Lucy",
        "value": "BB-07"
      },
      {
        "label": "Saint Michael",
        "value": "BB-08"
      },
      {
        "label": "Saint Peter",
        "value": "BB-09"
      },
      {
        "label": "Saint Philip",
        "value": "BB-10"
      },
      {
        "label": "Saint Thomas",
        "value": "BB-11"
      }
    ],
    "value": "BB"
  },
  {
    "label": "Belarus",
    "options": [
      {
        "label": "Brestskaya voblasts'",
        "value": "BY-BR"
      },
      {
        "label": "Homyel'skaya voblasts'",
        "value": "BY-HO"
      },
      {
        "label": "Horad Minsk",
        "value": "BY-HM"
      },
      {
        "label": "Hrodzyenskaya voblasts'",
        "value": "BY-HR"
      },
      {
        "label": "Mahilyowskaya voblasts'",
        "value": "BY-MA"
      },
      {
        "label": "Minskaya voblasts'",
        "value": "BY-MI"
      },
      {
        "label": "Vitsyebskaya voblasts'",
        "value": "BY-VI"
      }
    ],
    "value": "BY"
  },
  {
    "label": "Belgium",
    "options": [
      {
        "label": "Antwerpen",
        "value": "BE-VAN"
      },
      {
        "label": "Brabant wallon",
        "value": "BE-WBR"
      },
      {
        "label": "Brussels Hoofdstedelijk Gewest",
        "value": "BE-BRU"
      },
      {
        "label": "Hainaut",
        "value": "BE-WHT"
      },
      {
        "label": "Liege",
        "value": "BE-WLG"
      },
      {
        "label": "Limburg",
        "value": "BE-VLI"
      },
      {
        "label": "Luxembourg",
        "value": "BE-WLX"
      },
      {
        "label": "Namur",
        "value": "BE-WNA"
      },
      {
        "label": "Oost-Vlaanderen",
        "value": "BE-VOV"
      },
      {
        "label": "Vlaams-Brabant",
        "value": "BE-VBR"
      },
      {
        "label": "West-Vlaanderen",
        "value": "BE-VWV"
      }
    ],
    "value": "BE"
  },
  {
    "label": "Belize",
    "options": [
      {
        "label": "Belize",
        "value": "BZ-BZ"
      },
      {
        "label": "Cayo",
        "value": "BZ-CY"
      },
      {
        "label": "Corozal",
        "value": "BZ-CZL"
      },
      {
        "label": "Orange Walk",
        "value": "BZ-OW"
      },
      {
        "label": "Stann Creek",
        "value": "BZ-SC"
      },
      {
        "label": "Toledo",
        "value": "BZ-TOL"
      }
    ],
    "value": "BZ"
  },
  {
    "label": "Benin",
    "options": [
      {
        "label": "Atacora",
        "value": "BJ-AK"
      },
      {
        "label": "Atlantique",
        "value": "BJ-AQ"
      },
      {
        "label": "Borgou",
        "value": "BJ-BO"
      },
      {
        "label": "Collines",
        "value": "BJ-CO"
      },
      {
        "label": "Littoral",
        "value": "BJ-LI"
      },
      {
        "label": "Oueme",
        "value": "BJ-OU"
      },
      {
        "label": "Plateau",
        "value": "BJ-PL"
      },
      {
        "label": "Zou",
        "value": "BJ-ZO"
      }
    ],
    "value": "BJ"
  },
  {
    "label": "Bermuda",
    "value": "BM"
  },
  {
    "label": "Bhutan",
    "options": [
      {
        "label": "Bumthang",
        "value": "BT-33"
      },
      {
        "label": "Chhukha",
        "value": "BT-12"
      },
      {
        "label": "Dagana",
        "value": "BT-22"
      },
      {
        "label": "Gasa",
        "value": "BT-GA"
      },
      {
        "label": "Lhuentse",
        "value": "BT-44"
      },
      {
        "label": "Monggar",
        "value": "BT-42"
      },
      {
        "label": "Paro",
        "value": "BT-11"
      },
      {
        "label": "Pema Gatshel",
        "value": "BT-43"
      },
      {
        "label": "Punakha",
        "value": "BT-23"
      },
      {
        "label": "Samdrup Jongkhar",
        "value": "BT-45"
      },
      {
        "label": "Samtse",
        "value": "BT-14"
      },
      {
        "label": "Sarpang",
        "value": "BT-31"
      },
      {
        "label": "Thimphu",
        "value": "BT-15"
      },
      {
        "label": "Trashigang",
        "value": "BT-41"
      },
      {
        "label": "Trongsa",
        "value": "BT-32"
      },
      {
        "label": "Tsirang",
        "value": "BT-21"
      },
      {
        "label": "Wangdue Phodrang",
        "value": "BT-24"
      }
    ],
    "value": "BT"
  },
  {
    "label": "Bolivia, Plurinational State of",
    "options": [
      {
        "label": "Chuquisaca",
        "value": "BO-H"
      },
      {
        "label": "Cochabamba",
        "value": "BO-C"
      },
      {
        "label": "El Beni",
        "value": "BO-B"
      },
      {
        "label": "La Paz",
        "value": "BO-L"
      },
      {
        "label": "Oruro",
        "value": "BO-O"
      },
      {
        "label": "Pando",
        "value": "BO-N"
      },
      {
        "label": "Potosi",
        "value": "BO-P"
      },
      {
        "label": "Santa Cruz",
        "value": "BO-S"
      },
      {
        "label": "Tarija",
        "value": "BO-T"
      }
    ],
    "value": "BO"
  },
  {
    "label": "Bonaire, Sint Eustatius and Saba",
    "options": [
      {
        "label": "Bonaire",
        "value": "BQ-BO"
      },
      {
        "label": "Saba",
        "value": "BQ-SA"
      },
      {
        "label": "Sint Eustatius",
        "value": "BQ-SE"
      }
    ],
    "value": "BQ"
  },
  {
    "label": "Bosnia and Herzegovina",
    "options": [
      {
        "label": "Brcko distrikt",
        "value": "BA-BRC"
      },
      {
        "label": "Federacija Bosne i Hercegovine",
        "value": "BA-BIH"
      },
      {
        "label": "Republika Srpska",
        "value": "BA-SRP"
      }
    ],
    "value": "BA"
  },
  {
    "label": "Botswana",
    "options": [
      {
        "label": "Central",
        "value": "BW-CE"
      },
      {
        "label": "Chobe",
        "value": "BW-CH"
      },
      {
        "label": "Ghanzi",
        "value": "BW-GH"
      },
      {
        "label": "Kgalagadi",
        "value": "BW-KG"
      },
      {
        "label": "Kgatleng",
        "value": "BW-KL"
      },
      {
        "label": "Kweneng",
        "value": "BW-KW"
      },
      {
        "label": "North East",
        "value": "BW-NE"
      },
      {
        "label": "North West",
        "value": "BW-NW"
      },
      {
        "label": "South East",
        "value": "BW-SE"
      },
      {
        "label": "Southern",
        "value": "BW-SO"
      }
    ],
    "value": "BW"
  },
  {
    "label": "Bouvet Island",
    "value": "BV"
  },
  {
    "label": "Brazil",
    "options": [
      {
        "label": "Acre",
        "value": "BR-AC"
      },
      {
        "label": "Alagoas",
        "value": "BR-AL"
      },
      {
        "label": "Amapa",
        "value": "BR-AP"
      },
      {
        "label": "Amazonas",
        "value": "BR-AM"
      },
      {
        "label": "Bahia",
        "value": "BR-BA"
      },
      {
        "label": "Ceara",
        "value": "BR-CE"
      },
      {
        "label": "Distrito Federal",
        "value": "BR-DF"
      },
      {
        "label": "Espirito Santo",
        "value": "BR-ES"
      },
      {
        "label": "Goias",
        "value": "BR-GO"
      },
      {
        "label": "Maranhao",
        "value": "BR-MA"
      },
      {
        "label": "Mato Grosso",
        "value": "BR-MT"
      },
      {
        "label": "Mato Grosso do Sul",
        "value": "BR-MS"
      },
      {
        "label": "Minas Gerais",
        "value": "BR-MG"
      },
      {
        "label": "Para",
        "value": "BR-PA"
      },
      {
        "label": "Paraiba",
        "value": "BR-PB"
      },
      {
        "label": "Parana",
        "value": "BR-PR"
      },
      {
        "label": "Pernambuco",
        "value": "BR-PE"
      },
      {
        "label": "Piaui",
        "value": "BR-PI"
      },
      {
        "label": "Rio de Janeiro",
        "value": "BR-RJ"
      },
      {
        "label": "Rio Grande do Norte",
        "value": "BR-RN"
      },
      {
        "label": "Rio Grande do Sul",
        "value": "BR-RS"
      },
      {
        "label": "Rondonia",
        "value": "BR-RO"
      },
      {
        "label": "Roraima",
        "value": "BR-RR"
      },
      {
        "label": "Santa Catarina",
        "value": "BR-SC"
      },
      {
        "label": "Sao Paulo",
        "value": "BR-SP"
      },
      {
        "label": "Sergipe",
        "value": "BR-SE"
      },
      {
        "label": "Tocantins",
        "value": "BR-TO"
      }
    ],
    "value": "BR"
  },
  {
    "label": "British Indian Ocean Territory",
    "value": "IO"
  },
  {
    "label": "Brunei Darussalam",
    "options": [
      {
        "label": "Belait",
        "value": "BN-BE"
      },
      {
        "label": "Brunei-Muara",
        "value": "BN-BM"
      },
      {
        "label": "Temburong",
        "value": "BN-TE"
      },
      {
        "label": "Tutong",
        "value": "BN-TU"
      }
    ],
    "value": "BN"
  },
  {
    "label": "Bulgaria",
    "options": [
      {
        "label": "Blagoevgrad",
        "value": "BG-01"
      },
      {
        "label": "Burgas",
        "value": "BG-02"
      },
      {
        "label": "Dobrich",
        "value": "BG-08"
      },
      {
        "label": "Gabrovo",
        "value": "BG-07"
      },
      {
        "label": "Haskovo",
        "value": "BG-26"
      },
      {
        "label": "Kardzhali",
        "value": "BG-09"
      },
      {
        "label": "Kyustendil",
        "value": "BG-10"
      },
      {
        "label": "Lovech",
        "value": "BG-11"
      },
      {
        "label": "Montana",
        "value": "BG-12"
      },
      {
        "label": "Pazardzhik",
        "value": "BG-13"
      },
      {
        "label": "Pernik",
        "value": "BG-14"
      },
      {
        "label": "Pleven",
        "value": "BG-15"
      },
      {
        "label": "Plovdiv",
        "value": "BG-16"
      },
      {
        "label": "Razgrad",
        "value": "BG-17"
      },
      {
        "label": "Ruse",
        "value": "BG-18"
      },
      {
        "label": "Shumen",
        "value": "BG-27"
      },
      {
        "label": "Silistra",
        "value": "BG-19"
      },
      {
        "label": "Sliven",
        "value": "BG-20"
      },
      {
        "label": "Smolyan",
        "value": "BG-21"
      },
      {
        "label": "Sofia",
        "value": "BG-23"
      },
      {
        "label": "Sofia (stolitsa)",
        "value": "BG-22"
      },
      {
        "label": "Stara Zagora",
        "value": "BG-24"
      },
      {
        "label": "Targovishte",
        "value": "BG-25"
      },
      {
        "label": "Varna",
        "value": "BG-03"
      },
      {
        "label": "Veliko Tarnovo",
        "value": "BG-04"
      },
      {
        "label": "Vidin",
        "value": "BG-05"
      },
      {
        "label": "Vratsa",
        "value": "BG-06"
      },
      {
        "label": "Yambol",
        "value": "BG-28"
      }
    ],
    "value": "BG"
  },
  {
    "label": "Burkina Faso",
    "options": [
      {
        "label": "Bale",
        "value": "BF-BAL"
      },
      {
        "label": "Bam",
        "value": "BF-BAM"
      },
      {
        "label": "Banwa",
        "value": "BF-BAN"
      },
      {
        "label": "Bazega",
        "value": "BF-BAZ"
      },
      {
        "label": "Bougouriba",
        "value": "BF-BGR"
      },
      {
        "label": "Boulgou",
        "value": "BF-BLG"
      },
      {
        "label": "Boulkiemde",
        "value": "BF-BLK"
      },
      {
        "label": "Comoe",
        "value": "BF-COM"
      },
      {
        "label": "Ganzourgou",
        "value": "BF-GAN"
      },
      {
        "label": "Gnagna",
        "value": "BF-GNA"
      },
      {
        "label": "Gourma",
        "value": "BF-GOU"
      },
      {
        "label": "Houet",
        "value": "BF-HOU"
      },
      {
        "label": "Kadiogo",
        "value": "BF-KAD"
      },
      {
        "label": "Kenedougou",
        "value": "BF-KEN"
      },
      {
        "label": "Kompienga",
        "value": "BF-KMP"
      },
      {
        "label": "Kossi",
        "value": "BF-KOS"
      },
      {
        "label": "Kouritenga",
        "value": "BF-KOT"
      },
      {
        "label": "Kourweogo",
        "value": "BF-KOW"
      },
      {
        "label": "Leraba",
        "value": "BF-LER"
      },
      {
        "label": "Loroum",
        "value": "BF-LOR"
      },
      {
        "label": "Mouhoun",
        "value": "BF-MOU"
      },
      {
        "label": "Nahouri",
        "value": "BF-NAO"
      },
      {
        "label": "Namentenga",
        "value": "BF-NAM"
      },
      {
        "label": "Nayala",
        "value": "BF-NAY"
      },
      {
        "label": "Oubritenga",
        "value": "BF-OUB"
      },
      {
        "label": "Oudalan",
        "value": "BF-OUD"
      },
      {
        "label": "Passore",
        "value": "BF-PAS"
      },
      {
        "label": "Sanmatenga",
        "value": "BF-SMT"
      },
      {
        "label": "Seno",
        "value": "BF-SEN"
      },
      {
        "label": "Sissili",
        "value": "BF-SIS"
      },
      {
        "label": "Soum",
        "value": "BF-SOM"
      },
      {
        "label": "Sourou",
        "value": "BF-SOR"
      },
      {
        "label": "Tapoa",
        "value": "BF-TAP"
      },
      {
        "label": "Tuy",
        "value": "BF-TUI"
      },
      {
        "label": "Yatenga",
        "value": "BF-YAT"
      },
      {
        "label": "Ziro",
        "value": "BF-ZIR"
      },
      {
        "label": "Zondoma",
        "value": "BF-ZON"
      },
      {
        "label": "Zoundweogo",
        "value": "BF-ZOU"
      }
    ],
    "value": "BF"
  },
  {
    "label": "Burundi",
    "options": [
      {
        "label": "Bujumbura Mairie",
        "value": "BI-BM"
      },
      {
        "label": "Bururi",
        "value": "BI-BR"
      },
      {
        "label": "Cibitoke",
        "value": "BI-CI"
      },
      {
        "label": "Gitega",
        "value": "BI-GI"
      },
      {
        "label": "Kirundo",
        "value": "BI-KI"
      },
      {
        "label": "Mwaro",
        "value": "BI-MW"
      },
      {
        "label": "Ngozi",
        "value": "BI-NG"
      },
      {
        "label": "Rumonge",
        "value": "BI-RM"
      },
      {
        "label": "Rutana",
        "value": "BI-RT"
      },
      {
        "label": "Ruyigi",
        "value": "BI-RY"
      }
    ],
    "value": "BI"
  },
  {
    "label": "Cabo Verde",
    "options": [
      {
        "label": "Boa Vista",
        "value": "CV-BV"
      },
      {
        "label": "Porto Novo",
        "value": "CV-PN"
      },
      {
        "label": "Praia",
        "value": "CV-PR"
      },
      {
        "label": "Ribeira Grande",
        "value": "CV-RG"
      },
      {
        "label": "Ribeira Grande de Santiago",
        "value": "CV-RS"
      },
      {
        "label": "Sal",
        "value": "CV-SL"
      },
      {
        "label": "Sao Filipe",
        "value": "CV-SF"
      },
      {
        "label": "Sao Lourenco dos Orgaos",
        "value": "CV-SO"
      },
      {
        "label": "Sao Salvador do Mundo",
        "value": "CV-SS"
      },
      {
        "label": "Sao Vicente",
        "value": "CV-SV"
      },
      {
        "label": "Tarrafal",
        "value": "CV-TA"
      }
    ],
    "value": "CV"
  },
  {
    "label": "Cambodia",
    "options": [
      {
        "label": "Baat Dambang",
        "value": "KH-2"
      },
      {
        "label": "Banteay Mean Choay",
        "value": "KH-1"
      },
      {
        "label": "Kaeb",
        "value": "KH-23"
      },
      {
        "label": "Kampong Chaam",
        "value": "KH-3"
      },
      {
        "label": "Kampong Chhnang",
        "value": "KH-4"
      },
      {
        "label": "Kampong Spueu",
        "value": "KH-5"
      },
      {
        "label": "Kampong Thum",
        "value": "KH-6"
      },
      {
        "label": "Kampot",
        "value": "KH-7"
      },
      {
        "label": "Kandaal",
        "value": "KH-8"
      },
      {
        "label": "Kaoh Kong",
        "value": "KH-9"
      },
      {
        "label": "Kracheh",
        "value": "KH-10"
      },
      {
        "label": "Mondol Kiri",
        "value": "KH-11"
      },
      {
        "label": "Otdar Mean Chey",
        "value": "KH-22"
      },
      {
        "label": "Pailin",
        "value": "KH-24"
      },
      {
        "label": "Phnom Penh",
        "value": "KH-12"
      },
      {
        "label": "Pousaat",
        "value": "KH-15"
      },
      {
        "label": "Preah Sihanouk",
        "value": "KH-18"
      },
      {
        "label": "Preah Vihear",
        "value": "KH-13"
      },
      {
        "label": "Prey Veaeng",
        "value": "KH-14"
      },
      {
        "label": "Rotanak Kiri",
        "value": "KH-16"
      },
      {
        "label": "Siem Reab",
        "value": "KH-17"
      },
      {
        "label": "Stueng Traeng",
        "value": "KH-19"
      },
      {
        "label": "Svaay Rieng",
        "value": "KH-20"
      },
      {
        "label": "Taakaev",
        "value": "KH-21"
      }
    ],
    "value": "KH"
  },
  {
    "label": "Cameroon",
    "options": [
      {
        "label": "Adamaoua",
        "value": "CM-AD"
      },
      {
        "label": "Centre",
        "value": "CM-CE"
      },
      {
        "label": "Est",
        "value": "CM-ES"
      },
      {
        "label": "Extreme-Nord",
        "value": "CM-EN"
      },
      {
        "label": "Littoral",
        "value": "CM-LT"
      },
      {
        "label": "Nord",
        "value": "CM-NO"
      },
      {
        "label": "Nord-Ouest",
        "value": "CM-NW"
      },
      {
        "label": "Ouest",
        "value": "CM-OU"
      },
      {
        "label": "Sud",
        "value": "CM-SU"
      },
      {
        "label": "Sud-Ouest",
        "value": "CM-SW"
      }
    ],
    "value": "CM"
  },
  {
    "label": "Canada",
    "options": [
      {
        "label": "Alberta",
        "value": "CA-AB"
      },
      {
        "label": "British Columbia",
        "value": "CA-BC"
      },
      {
        "label": "Manitoba",
        "value": "CA-MB"
      },
      {
        "label": "New Brunswick",
        "value": "CA-NB"
      },
      {
        "label": "Newfoundland and Labrador",
        "value": "CA-NL"
      },
      {
        "label": "Northwest Territories",
        "value": "CA-NT"
      },
      {
        "label": "Nova Scotia",
        "value": "CA-NS"
      },
      {
        "label": "Nunavut",
        "value": "CA-NU"
      },
      {
        "label": "Ontario",
        "value": "CA-ON"
      },
      {
        "label": "Prince Edward Island",
        "value": "CA-PE"
      },
      {
        "label": "Quebec",
        "value": "CA-QC"
      },
      {
        "label": "Saskatchewan",
        "value": "CA-SK"
      },
      {
        "label": "Yukon",
        "value": "CA-YT"
      }
    ],
    "value": "CA"
  },
  {
    "label": "Cayman Islands",
    "value": "KY"
  },
  {
    "label": "Central African Republic",
    "options": [
      {
        "label": "Bamingui-Bangoran",
        "value": "CF-BB"
      },
      {
        "label": "Bangui",
        "value": "CF-BGF"
      },
      {
        "label": "Gribingui",
        "value": "CF-KB"
      },
      {
        "label": "Haut-Mbomou",
        "value": "CF-HM"
      },
      {
        "label": "Haute-Kotto",
        "value": "CF-HK"
      },
      {
        "label": "Kemo-Gribingui",
        "value": "CF-KG"
      },
      {
        "label": "Lobaye",
        "value": "CF-LB"
      },
      {
        "label": "Mambere-Kadei",
        "value": "CF-HS"
      },
      {
        "label": "Nana-Mambere",
        "value": "CF-NM"
      },
      {
        "label": "Ouaka",
        "value": "CF-UK"
      },
      {
        "label": "Ouham",
        "value": "CF-AC"
      },
      {
        "label": "Ouham-Pende",
        "value": "CF-OP"
      },
      {
        "label": "Vakaga",
        "value": "CF-VK"
      }
    ],
    "value": "CF"
  },
  {
    "label": "Chad",
    "options": [
      {
        "label": "Bahr el Ghazal",
        "value": "TD-BG"
      },
      {
        "label": "Chari-Baguirmi",
        "value": "TD-CB"
      },
      {
        "label": "Guera",
        "value": "TD-GR"
      },
      {
        "label": "Lac",
        "value": "TD-LC"
      },
      {
        "label": "Sila",
        "value": "TD-SI"
      },
      {
        "label": "Ville de Ndjamena",
        "value": "TD-ND"
      }
    ],
    "value": "TD"
  },
  {
    "label": "Chile",
    "options": [
      {
        "label": "Aisen del General Carlos Ibanez del Campo",
        "value": "CL-AI"
      },
      {
        "label": "Antofagasta",
        "value": "CL-AN"
      },
      {
        "label": "Arica y Parinacota",
        "value": "CL-AP"
      },
      {
        "label": "Atacama",
        "value": "CL-AT"
      },
      {
        "label": "Biobio",
        "value": "CL-BI"
      },
      {
        "label": "Coquimbo",
        "value": "CL-CO"
      },
      {
        "label": "La Araucania",
        "value": "CL-AR"
      },
      {
        "label": "Libertador General Bernardo O'Higgins",
        "value": "CL-LI"
      },
      {
        "label": "Los Lagos",
        "value": "CL-LL"
      },
      {
        "label": "Los Rios",
        "value": "CL-LR"
      },
      {
        "label": "Magallanes",
        "value": "CL-MA"
      },
      {
        "label": "Maule",
        "value": "CL-ML"
      },
      {
        "label": "Nuble",
        "value": "CL-NB"
      },
      {
        "label": "Region Metropolitana de Santiago",
        "value": "CL-RM"
      },
      {
        "label": "Tarapaca",
        "value": "CL-TA"
      },
      {
        "label": "Valparaiso",
        "value": "CL-VS"
      }
    ],
    "value": "CL"
  },
  {
    "label": "China",
    "options": [
      {
        "label": "Anhui",
        "value": "CN-AH"
      },
      {
        "label": "Beijing",
        "value": "CN-BJ"
      },
      {
        "label": "Chongqing",
        "value": "CN-CQ"
      },
      {
        "label": "Fujian",
        "value": "CN-FJ"
      },
      {
        "label": "Gansu",
        "value": "CN-GS"
      },
      {
        "label": "Guangdong",
        "value": "CN-GD"
      },
      {
        "label": "Guangxi Zhuangzu",
        "value": "CN-GX"
      },
      {
        "label": "Guizhou",
        "value": "CN-GZ"
      },
      {
        "label": "Hainan",
        "value": "CN-HI"
      },
      {
        "label": "Hebei",
        "value": "CN-HE"
      },
      {
        "label": "Heilongjiang",
        "value": "CN-HL"
      },
      {
        "label": "Henan",
        "value": "CN-HA"
      },
      {
        "label": "Hubei",
        "value": "CN-HB"
      },
      {
        "label": "Hunan",
        "value": "CN-HN"
      },
      {
        "label": "Jiangsu",
        "value": "CN-JS"
      },
      {
        "label": "Jiangxi",
        "value": "CN-JX"
      },
      {
        "label": "Jilin",
        "value": "CN-JL"
      },
      {
        "label": "Liaoning",
        "value": "CN-LN"
      },
      {
        "label": "Nei Mongol",
        "value": "CN-NM"
      },
      {
        "label": "Ningxia Huizu",
        "value": "CN-NX"
      },
      {
        "label": "Qinghai",
        "value": "CN-QH"
      },
      {
        "label": "Shaanxi",
        "value": "CN-SN"
      },
      {
        "label": "Shandong",
        "value": "CN-SD"
      },
      {
        "label": "Shanghai",
        "value": "CN-SH"
      },
      {
        "label": "Shanxi",
        "value": "CN-SX"
      },
      {
        "label": "Sichuan",
        "value": "CN-SC"
      },
      {
        "label": "Tianjin",
        "value": "CN-TJ"
      },
      {
        "label": "Xinjiang Uygur",
        "value": "CN-XJ"
      },
      {
        "label": "Xizang",
        "value": "CN-XZ"
      },
      {
        "label": "Yunnan",
        "value": "CN-YN"
      },
      {
        "label": "Zhejiang",
        "value": "CN-ZJ"
      }
    ],
    "value": "CN"
  },
  {
    "label": "Christmas Island",
    "value": "CX"
  },
  {
    "label": "Cocos (Keeling) Islands",
    "value": "CC"
  },
  {
    "label": "Colombia",
    "options": [
      {
        "label": "Amazonas",
        "value": "CO-AMA"
      },
      {
        "label": "Antioquia",
        "value": "CO-ANT"
      },
      {
        "label": "Arauca",
        "value": "CO-ARA"
      },
      {
        "label": "Atlantico",
        "value": "CO-ATL"
      },
      {
        "label": "Bolivar",
        "value": "CO-BOL"
      },
      {
        "label": "Boyaca",
        "value": "CO-BOY"
      },
      {
        "label": "Caldas",
        "value": "CO-CAL"
      },
      {
        "label": "Caqueta",
        "value": "CO-CAQ"
      },
      {
        "label": "Casanare",
        "value": "CO-CAS"
      },
      {
        "label": "Cauca",
        "value": "CO-CAU"
      },
      {
        "label": "Cesar",
        "value": "CO-CES"
      },
      {
        "label": "Choco",
        "value": "CO-CHO"
      },
      {
        "label": "Cordoba",
        "value": "CO-COR"
      },
      {
        "label": "Cundinamarca",
        "value": "CO-CUN"
      },
      {
        "label": "Distrito Capital de Bogota",
        "value": "CO-DC"
      },
      {
        "label": "Guainia",
        "value": "CO-GUA"
      },
      {
        "label": "Guaviare",
        "value": "CO-GUV"
      },
      {
        "label": "Huila",
        "value": "CO-HUI"
      },
      {
        "label": "La Guajira",
        "value": "CO-LAG"
      },
      {
        "label": "Magdalena",
        "value": "CO-MAG"
      },
      {
        "label": "Meta",
        "value": "CO-MET"
      },
      {
        "label": "Narino",
        "value": "CO-NAR"
      },
      {
        "label": "Norte de Santander",
        "value": "CO-NSA"
      },
      {
        "label": "Putumayo",
        "value": "CO-PUT"
      },
      {
        "label": "Quindio",
        "value": "CO-QUI"
      },
      {
        "label": "Risaralda",
        "value": "CO-RIS"
      },
      {
        "label": "San Andres, Providencia y Santa Catalina",
        "value": "CO-SAP"
      },
      {
        "label": "Santander",
        "value": "CO-SAN"
      },
      {
        "label": "Sucre",
        "value": "CO-SUC"
      },
      {
        "label": "Tolima",
        "value": "CO-TOL"
      },
      {
        "label": "Valle del Cauca",
        "value": "CO-VAC"
      },
      {
        "label": "Vichada",
        "value": "CO-VID"
      }
    ],
    "value": "CO"
  },
  {
    "label": "Comoros",
    "options": [
      {
        "label": "Grande Comore",
        "value": "KM-G"
      },
      {
        "label": "Moheli",
        "value": "KM-M"
      }
    ],
    "value": "KM"
  },
  {
    "label": "Congo",
    "options": [
      {
        "label": "Bouenza",
        "value": "CG-11"
      },
      {
        "label": "Brazzaville",
        "value": "CG-BZV"
      },
      {
        "label": "Cuvette",
        "value": "CG-8"
      },
      {
        "label": "Likouala",
        "value": "CG-7"
      },
      {
        "label": "Niari",
        "value": "CG-9"
      },
      {
        "label": "Pointe-Noire",
        "value": "CG-16"
      },
      {
        "label": "Sangha",
        "value": "CG-13"
      }
    ],
    "value": "CG"
  },
  {
    "label": "Congo, Democratic Republic of the",
    "options": [
      {
        "label": "Equateur",
        "value": "CD-EQ"
      },
      {
        "label": "Haut-Katanga",
        "value": "CD-HK"
      },
      {
        "label": "Ituri",
        "value": "CD-IT"
      },
      {
        "label": "Kasai",
        "value": "CD-KS"
      },
      {
        "label": "Kasai Central",
        "value": "CD-KC"
      },
      {
        "label": "Kasai Oriental",
        "value": "CD-KE"
      },
      {
        "label": "Kinshasa",
        "value": "CD-KN"
      },
      {
        "label": "Kongo Central",
        "value": "CD-BC"
      },
      {
        "label": "Lomami",
        "value": "CD-LO"
      },
      {
        "label": "Lualaba",
        "value": "CD-LU"
      },
      {
        "label": "Maniema",
        "value": "CD-MA"
      },
      {
        "label": "Nord-Kivu",
        "value": "CD-NK"
      },
      {
        "label": "Nord-Ubangi",
        "value": "CD-NU"
      },
      {
        "label": "Sankuru",
        "value": "CD-SA"
      },
      {
        "label": "Sud-Kivu",
        "value": "CD-SK"
      },
      {
        "label": "Tanganyika",
        "value": "CD-TA"
      },
      {
        "label": "Tshopo",
        "value": "CD-TO"
      }
    ],
    "value": "CD"
  },
  {
    "label": "Cook Islands",
    "value": "CK"
  },
  {
    "label": "Costa Rica",
    "options": [
      {
        "label": "Alajuela",
        "value": "CR-A"
      },
      {
        "label": "Cartago",
        "value": "CR-C"
      },
      {
        "label": "Guanacaste",
        "value": "CR-G"
      },
      {
        "label": "Heredia",
        "value": "CR-H"
      },
      {
        "label": "Limon",
        "value": "CR-L"
      },
      {
        "label": "Puntarenas",
        "value": "CR-P"
      },
      {
        "label": "San Jose",
        "value": "CR-SJ"
      }
    ],
    "value": "CR"
  },
  {
    "label": "Côte d'Ivoire",
    "options": [
      {
        "label": "Abidjan",
        "value": "CI-AB"
      },
      {
        "label": "Bas-Sassandra",
        "value": "CI-BS"
      },
      {
        "label": "Comoe",
        "value": "CI-CM"
      },
      {
        "label": "Denguele",
        "value": "CI-DN"
      },
      {
        "label": "Goh-Djiboua",
        "value": "CI-GD"
      },
      {
        "label": "Lacs",
        "value": "CI-LC"
      },
      {
        "label": "Lagunes",
        "value": "CI-LG"
      },
      {
        "label": "Montagnes",
        "value": "CI-MG"
      },
      {
        "label": "Sassandra-Marahoue",
        "value": "CI-SM"
      },
      {
        "label": "Savanes",
        "value": "CI-SV"
      },
      {
        "label": "Vallee du Bandama",
        "value": "CI-VB"
      },
      {
        "label": "Woroba",
        "value": "CI-WR"
      },
      {
        "label": "Yamoussoukro",
        "value": "CI-YM"
      },
      {
        "label": "Zanzan",
        "value": "CI-ZZ"
      }
    ],
    "value": "CI"
  },
  {
    "label": "Croatia",
    "options": [
      {
        "label": "Bjelovarsko-bilogorska zupanija",
        "value": "HR-07"
      },
      {
        "label": "Brodsko-posavska zupanija",
        "value": "HR-12"
      },
      {
        "label": "Dubrovacko-neretvanska zupanija",
        "value": "HR-19"
      },
      {
        "label": "Grad Zagreb",
        "value": "HR-21"
      },
      {
        "label": "Istarska zupanija",
        "value": "HR-18"
      },
      {
        "label": "Karlovacka zupanija",
        "value": "HR-04"
      },
      {
        "label": "Koprivnicko-krizevacka zupanija",
        "value": "HR-06"
      },
      {
        "label": "Krapinsko-zagorska zupanija",
        "value": "HR-02"
      },
      {
        "label": "Licko-senjska zupanija",
        "value": "HR-09"
      },
      {
        "label": "Medimurska zupanija",
        "value": "HR-20"
      },
      {
        "label": "Osjecko-baranjska zupanija",
        "value": "HR-14"
      },
      {
        "label": "Pozesko-slavonska zupanija",
        "value": "HR-11"
      },
      {
        "label": "Primorsko-goranska zupanija",
        "value": "HR-08"
      },
      {
        "label": "Sibensko-kninska zupanija",
        "value": "HR-15"
      },
      {
        "label": "Sisacko-moslavacka zupanija",
        "value": "HR-03"
      },
      {
        "label": "Splitsko-dalmatinska zupanija",
        "value": "HR-17"
      },
      {
        "label": "Varazdinska zupanija",
        "value": "HR-05"
      },
      {
        "label": "Viroviticko-podravska zupanija",
        "value": "HR-10"
      },
      {
        "label": "Vukovarsko-srijemska zupanija",
        "value": "HR-16"
      },
      {
        "label": "Zadarska zupanija",
        "value": "HR-13"
      },
      {
        "label": "Zagrebacka zupanija",
        "value": "HR-01"
      }
    ],
    "value": "HR"
  },
  {
    "label": "Cuba",
    "options": [
      {
        "label": "Artemisa",
        "value": "CU-15"
      },
      {
        "label": "Camaguey",
        "value": "CU-09"
      },
      {
        "label": "Ciego de Avila",
        "value": "CU-08"
      },
      {
        "label": "Cienfuegos",
        "value": "CU-06"
      },
      {
        "label": "Granma",
        "value": "CU-12"
      },
      {
        "label": "Guantanamo",
        "value": "CU-14"
      },
      {
        "label": "Holguin",
        "value": "CU-11"
      },
      {
        "label": "Isla de la Juventud",
        "value": "CU-99"
      },
      {
        "label": "La Habana",
        "value": "CU-03"
      },
      {
        "label": "Las Tunas",
        "value": "CU-10"
      },
      {
        "label": "Matanzas",
        "value": "CU-04"
      },
      {
        "label": "Mayabeque",
        "value": "CU-16"
      },
      {
        "label": "Pinar del Rio",
        "value": "CU-01"
      },
      {
        "label": "Sancti Spiritus",
        "value": "CU-07"
      },
      {
        "label": "Santiago de Cuba",
        "value": "CU-13"
      },
      {
        "label": "Villa Clara",
        "value": "CU-05"
      }
    ],
    "value": "CU"
  },
  {
    "label": "Curaçao",
    "value": "CW"
  },
  {
    "label": "Cyprus",
    "options": [
      {
        "label": "Ammochostos",
        "value": "CY-04"
      },
      {
        "label": "Keryneia",
        "value": "CY-06"
      },
      {
        "label": "Larnaka",
        "value": "CY-03"
      },
      {
        "label": "Lefkosia",
        "value": "CY-01"
      },
      {
        "label": "Lemesos",
        "value": "CY-02"
      },
      {
        "label": "Pafos",
        "value": "CY-05"
      }
    ],
    "value": "CY"
  },
  {
    "label": "Czechia",
    "options": [
      {
        "label": "Jihocesky kraj",
        "value": "CZ-31"
      },
      {
        "label": "Jihomoravsky kraj",
        "value": "CZ-64"
      },
      {
        "label": "Karlovarsky kraj",
        "value": "CZ-41"
      },
      {
        "label": "Kraj Vysocina",
        "value": "CZ-63"
      },
      {
        "label": "Kralovehradecky kraj",
        "value": "CZ-52"
      },
      {
        "label": "Liberecky kraj",
        "value": "CZ-51"
      },
      {
        "label": "Moravskoslezsky kraj",
        "value": "CZ-80"
      },
      {
        "label": "Olomoucky kraj",
        "value": "CZ-71"
      },
      {
        "label": "Pardubicky kraj",
        "value": "CZ-53"
      },
      {
        "label": "Plzensky kraj",
        "value": "CZ-32"
      },
      {
        "label": "Praha, Hlavni mesto",
        "value": "CZ-10"
      },
      {
        "label": "Stredocesky kraj",
        "value": "CZ-20"
      },
      {
        "label": "Ustecky kraj",
        "value": "CZ-42"
      },
      {
        "label": "Zlinsky kraj",
        "value": "CZ-72"
      }
    ],
    "value": "CZ"
  },
  {
    "label": "Denmark",
    "options": [
      {
        "label": "Hovedstaden",
        "value": "DK-84"
      },
      {
        "label": "Midtjylland",
        "value": "DK-82"
      },
      {
        "label": "Nordjylland",
        "value": "DK-81"
      },
      {
        "label": "Sjaelland",
        "value": "DK-85"
      },
      {
        "label": "Syddanmark",
        "value": "DK-83"
      }
    ],
    "value": "DK"
  },
  {
    "label": "Djibouti",
    "options": [
      {
        "label": "Arta",
        "value": "DJ-AR"
      },
      {
        "label": "Dikhil",
        "value": "DJ-DI"
      },
      {
        "label": "Djibouti",
        "value": "DJ-DJ"
      }
    ],
    "value": "DJ"
  },
  {
    "label": "Dominica",
    "options": [
      {
        "label": "Saint Andrew",
        "value": "DM-02"
      },
      {
        "label": "Saint George",
        "value": "DM-04"
      },
      {
        "label": "Saint John",
        "value": "DM-05"
      },
      {
        "label": "Saint Joseph",
        "value": "DM-06"
      },
      {
        "label": "Saint Luke",
        "value": "DM-07"
      },
      {
        "label": "Saint Patrick",
        "value": "DM-09"
      },
      {
        "label": "Saint Paul",
        "value": "DM-10"
      }
    ],
    "value": "DM"
  },
  {
    "label": "Dominican Republic",
    "options": [
      {
        "label": "Azua",
        "value": "DO-02"
      },
      {
        "label": "Baoruco",
        "value": "DO-03"
      },
      {
        "label": "Barahona",
        "value": "DO-04"
      },
      {
        "label": "Dajabon",
        "value": "DO-05"
      },
      {
        "label": "Distrito Nacional (Santo Domingo)",
        "value": "DO-01"
      },
      {
        "label": "Duarte",
        "value": "DO-06"
      },
      {
        "label": "El Seibo",
        "value": "DO-08"
      },
      {
        "label": "Elias Pina",
        "value": "DO-07"
      },
      {
        "label": "Espaillat",
        "value": "DO-09"
      },
      {
        "label": "Hato Mayor",
        "value": "DO-30"
      },
      {
        "label": "Hermanas Mirabal",
        "value": "DO-19"
      },
      {
        "label": "Independencia",
        "value": "DO-10"
      },
      {
        "label": "La Altagracia",
        "value": "DO-11"
      },
      {
        "label": "La Romana",
        "value": "DO-12"
      },
      {
        "label": "La Vega",
        "value": "DO-13"
      },
      {
        "label": "Maria Trinidad Sanchez",
        "value": "DO-14"
      },
      {
        "label": "Monsenor Nouel",
        "value": "DO-28"
      },
      {
        "label": "Monte Cristi",
        "value": "DO-15"
      },
      {
        "label": "Monte Plata",
        "value": "DO-29"
      },
      {
        "label": "Peravia",
        "value": "DO-17"
      },
      {
        "label": "Puerto Plata",
        "value": "DO-18"
      },
      {
        "label": "Samana",
        "value": "DO-20"
      },
      {
        "label": "San Cristobal",
        "value": "DO-21"
      },
      {
        "label": "San Jose de Ocoa",
        "value": "DO-31"
      },
      {
        "label": "San Juan",
        "value": "DO-22"
      },
      {
        "label": "San Pedro de Macoris",
        "value": "DO-23"
      },
      {
        "label": "Sanchez Ramirez",
        "value": "DO-24"
      },
      {
        "label": "Santiago",
        "value": "DO-25"
      },
      {
        "label": "Santiago Rodriguez",
        "value": "DO-26"
      },
      {
        "label": "Valverde",
        "value": "DO-27"
      }
    ],
    "value": "DO"
  },
  {
    "label": "Ecuador",
    "options": [
      {
        "label": "Azuay",
        "value": "EC-A"
      },
      {
        "label": "Bolivar",
        "value": "EC-B"
      },
      {
        "label": "Canar",
        "value": "EC-F"
      },
      {
        "label": "Carchi",
        "value": "EC-C"
      },
      {
        "label": "Chimborazo",
        "value": "EC-H"
      },
      {
        "label": "Cotopaxi",
        "value": "EC-X"
      },
      {
        "label": "El Oro",
        "value": "EC-O"
      },
      {
        "label": "Esmeraldas",
        "value": "EC-E"
      },
      {
        "label": "Galapagos",
        "value": "EC-W"
      },
      {
        "label": "Guayas",
        "value": "EC-G"
      },
      {
        "label": "Imbabura",
        "value": "EC-I"
      },
      {
        "label": "Loja",
        "value": "EC-L"
      },
      {
        "label": "Los Rios",
        "value": "EC-R"
      },
      {
        "label": "Manabi",
        "value": "EC-M"
      },
      {
        "label": "Morona Santiago",
        "value": "EC-S"
      },
      {
        "label": "Napo",
        "value": "EC-N"
      },
      {
        "label": "Orellana",
        "value": "EC-D"
      },
      {
        "label": "Pastaza",
        "value": "EC-Y"
      },
      {
        "label": "Pichincha",
        "value": "EC-P"
      },
      {
        "label": "Santa Elena",
        "value": "EC-SE"
      },
      {
        "label": "Santo Domingo de los Tsachilas",
        "value": "EC-SD"
      },
      {
        "label": "Sucumbios",
        "value": "EC-U"
      },
      {
        "label": "Tungurahua",
        "value": "EC-T"
      },
      {
        "label": "Zamora Chinchipe",
        "value": "EC-Z"
      }
    ],
    "value": "EC"
  },
  {
    "label": "Egypt",
    "options": [
      {
        "label": "Ad Daqahliyah",
        "value": "EG-DK"
      },
      {
        "label": "Al Bahr al Ahmar",
        "value": "EG-BA"
      },
      {
        "label": "Al Buhayrah",
        "value": "EG-BH"
      },
      {
        "label": "Al Fayyum",
        "value": "EG-FYM"
      },
      {
        "label": "Al Gharbiyah",
        "value": "EG-GH"
      },
      {
        "label": "Al Iskandariyah",
        "value": "EG-ALX"
      },
      {
        "label": "Al Isma'iliyah",
        "value": "EG-IS"
      },
      {
        "label": "Al Jizah",
        "value": "EG-GZ"
      },
      {
        "label": "Al Minufiyah",
        "value": "EG-MNF"
      },
      {
        "label": "Al Minya",
        "value": "EG-MN"
      },
      {
        "label": "Al Qahirah",
        "value": "EG-C"
      },
      {
        "label": "Al Qalyubiyah",
        "value": "EG-KB"
      },
      {
        "label": "Al Uqsur",
        "value": "EG-LX"
      },
      {
        "label": "Al Wadi al Jadid",
        "value": "EG-WAD"
      },
      {
        "label": "As Suways",
        "value": "EG-SUZ"
      },
      {
        "label": "Ash Sharqiyah",
        "value": "EG-SHR"
      },
      {
        "label": "Aswan",
        "value": "EG-ASN"
      },
      {
        "label": "Asyut",
        "value": "EG-AST"
      },
      {
        "label": "Bani Suwayf",
        "value": "EG-BNS"
      },
      {
        "label": "Bur Sa'id",
        "value": "EG-PTS"
      },
      {
        "label": "Dumyat",
        "value": "EG-DT"
      },
      {
        "label": "Janub Sina'",
        "value": "EG-JS"
      },
      {
        "label": "Kafr ash Shaykh",
        "value": "EG-KFS"
      },
      {
        "label": "Matruh",
        "value": "EG-MT"
      },
      {
        "label": "Qina",
        "value": "EG-KN"
      },
      {
        "label": "Shamal Sina'",
        "value": "EG-SIN"
      },
      {
        "label": "Suhaj",
        "value": "EG-SHG"
      }
    ],
    "value": "EG"
  },
  {
    "label": "El Salvador",
    "options": [
      {
        "label": "Ahuachapan",
        "value": "SV-AH"
      },
      {
        "label": "Cabanas",
        "value": "SV-CA"
      },
      {
        "label": "Chalatenango",
        "value": "SV-CH"
      },
      {
        "label": "Cuscatlan",
        "value": "SV-CU"
      },
      {
        "label": "La Libertad",
        "value": "SV-LI"
      },
      {
        "label": "La Paz",
        "value": "SV-PA"
      },
      {
        "label": "La Union",
        "value": "SV-UN"
      },
      {
        "label": "Morazan",
        "value": "SV-MO"
      },
      {
        "label": "San Miguel",
        "value": "SV-SM"
      },
      {
        "label": "San Salvador",
        "value": "SV-SS"
      },
      {
        "label": "San Vicente",
        "value": "SV-SV"
      },
      {
        "label": "Santa Ana",
        "value": "SV-SA"
      },
      {
        "label": "Sonsonate",
        "value": "SV-SO"
      },
      {
        "label": "Usulutan",
        "value": "SV-US"
      }
    ],
    "value": "SV"
  },
  {
    "label": "Equatorial Guinea",
    "options": [
      {
        "label": "Annobon",
        "value": "GQ-AN"
      },
      {
        "label": "Bioko Norte",
        "value": "GQ-BN"
      },
      {
        "label": "Centro Sur",
        "value": "GQ-CS"
      },
      {
        "label": "Kie-Ntem",
        "value": "GQ-KN"
      },
      {
        "label": "Litoral",
        "value": "GQ-LI"
      },
      {
        "label": "Wele-Nzas",
        "value": "GQ-WN"
      }
    ],
    "value": "GQ"
  },
  {
    "label": "Eritrea",
    "options": [
      {
        "label": "Al Awsat",
        "value": "ER-MA"
      },
      {
        "label": "Qash-Barkah",
        "value": "ER-GB"
      }
    ],
    "value": "ER"
  },
  {
    "label": "Estonia",
    "options": [
      {
        "label": "Harjumaa",
        "value": "EE-37"
      },
      {
        "label": "Hiiumaa",
        "value": "EE-39"
      },
      {
        "label": "Ida-Virumaa",
        "value": "EE-45"
      },
      {
        "label": "Jarvamaa",
        "value": "EE-52"
      },
      {
        "label": "Jogevamaa",
        "value": "EE-50"
      },
      {
        "label": "Laane-Virumaa",
        "value": "EE-60"
      },
      {
        "label": "Laanemaa",
        "value": "EE-56"
      },
      {
        "label": "Parnumaa",
        "value": "EE-68"
      },
      {
        "label": "Polvamaa",
        "value": "EE-64"
      },
      {
        "label": "Raplamaa",
        "value": "EE-71"
      },
      {
        "label": "Saaremaa",
        "value": "EE-74"
      },
      {
        "label": "Tartumaa",
        "value": "EE-79"
      },
      {
        "label": "Valgamaa",
        "value": "EE-81"
      },
      {
        "label": "Viljandimaa",
        "value": "EE-84"
      },
      {
        "label": "Vorumaa",
        "value": "EE-87"
      }
    ],
    "value": "EE"
  },
  {
    "label": "Eswatini",
    "options": [
      {
        "label": "Hhohho",
        "value": "SZ-HH"
      },
      {
        "label": "Lubombo",
        "value": "SZ-LU"
      },
      {
        "label": "Manzini",
        "value": "SZ-MA"
      }
    ],
    "value": "SZ"
  },
  {
    "label": "Ethiopia",
    "options": [
      {
        "label": "Addis Ababa",
        "value": "ET-AA"
      },
      {
        "label": "Afar",
        "value": "ET-AF"
      },
      {
        "label": "Amara",
        "value": "ET-AM"
      },
      {
        "label": "Benshangul-Gumaz",
        "value": "ET-BE"
      },
      {
        "label": "Dire Dawa",
        "value": "ET-DD"
      },
      {
        "label": "Harari People",
        "value": "ET-HA"
      },
      {
        "label": "Oromia",
        "value": "ET-OR"
      },
      {
        "label": "Somali",
        "value": "ET-SO"
      },
      {
        "label": "Southern Nations, Nationalities and Peoples",
        "value": "ET-SN"
      },
      {
        "label": "Tigrai",
        "value": "ET-TI"
      }
    ],
    "value": "ET"
  },
  {
    "label": "Falkland Islands (Malvinas)",
    "value": "FK"
  },
  {
    "label": "Faroe Islands",
    "value": "FO"
  },
  {
    "label": "Fiji",
    "options": [
      {
        "label": "Central",
        "value": "FJ-C"
      },
      {
        "label": "Eastern",
        "value": "FJ-E"
      },
      {
        "label": "Northern",
        "value": "FJ-N"
      },
      {
        "label": "Rotuma",
        "value": "FJ-R"
      },
      {
        "label": "Western",
        "value": "FJ-W"
      }
    ],
    "value": "FJ"
  },
  {
    "label": "Finland",
    "options": [
      {
        "label": "Etela-Karjala",
        "value": "FI-02"
      },
      {
        "label": "Etela-Pohjanmaa",
        "value": "FI-03"
      },
      {
        "label": "Etela-Savo",
        "value": "FI-04"
      },
      {
        "label": "Kainuu",
        "value": "FI-05"
      },
      {
        "label": "Kanta-Hame",
        "value": "FI-06"
      },
      {
        "label": "Keski-Pohjanmaa",
        "value": "FI-07"
      },
      {
        "label": "Keski-Suomi",
        "value": "FI-08"
      },
      {
        "label": "Kymenlaakso",
        "value": "FI-09"
      },
      {
        "label": "Lappi",
        "value": "FI-10"
      },
      {
        "label": "Paijat-Hame",
        "value": "FI-16"
      },
      {
        "label": "Pirkanmaa",
        "value": "FI-11"
      },
      {
        "label": "Pohjanmaa",
        "value": "FI-12"
      },
      {
        "label": "Pohjois-Karjala",
        "value": "FI-13"
      },
      {
        "label": "Pohjois-Pohjanmaa",
        "value": "FI-14"
      },
      {
        "label": "Pohjois-Savo",
        "value": "FI-15"
      },
      {
        "label": "Satakunta",
        "value": "FI-17"
      },
      {
        "label": "Uusimaa",
        "value": "FI-18"
      },
      {
        "label": "Varsinais-Suomi",
        "value": "FI-19"
      }
    ],
    "value": "FI"
  },
  {
    "label": "France",
    "options": [
      {
        "label": "Auvergne-Rhone-Alpes",
        "value": "FR-ARA"
      },
      {
        "label": "Bourgogne-Franche-Comte",
        "value": "FR-BFC"
      },
      {
        "label": "Bretagne",
        "value": "FR-BRE"
      },
      {
        "label": "Centre-Val de Loire",
        "value": "FR-CVL"
      },
      {
        "label": "Corse",
        "value": "FR-20R"
      },
      {
        "label": "Grand-Est",
        "value": "FR-GES"
      },
      {
        "label": "Hauts-de-France",
        "value": "FR-HDF"
      },
      {
        "label": "Ile-de-France",
        "value": "FR-IDF"
      },
      {
        "label": "Normandie",
        "value": "FR-NOR"
      },
      {
        "label": "Nouvelle-Aquitaine",
        "value": "FR-NAQ"
      },
      {
        "label": "Occitanie",
        "value": "FR-OCC"
      },
      {
        "label": "Pays-de-la-Loire",
        "value": "FR-PDL"
      },
      {
        "label": "Provence-Alpes-Cote-d'Azur",
        "value": "FR-PAC"
      }
    ],
    "value": "FR"
  },
  {
    "label": "French Guiana",
    "value": "GF"
  },
  {
    "label": "French Polynesia",
    "value": "PF"
  },
  {
    "label": "French Southern Territories",
    "value": "TF"
  },
  {
    "label": "Gabon",
    "options": [
      {
        "label": "Estuaire",
        "value": "GA-1"
      },
      {
        "label": "Haut-Ogooue",
        "value": "GA-2"
      },
      {
        "label": "Moyen-Ogooue",
        "value": "GA-3"
      },
      {
        "label": "Ngounie",
        "value": "GA-4"
      },
      {
        "label": "Nyanga",
        "value": "GA-5"
      },
      {
        "label": "Ogooue-Lolo",
        "value": "GA-7"
      },
      {
        "label": "Ogooue-Maritime",
        "value": "GA-8"
      },
      {
        "label": "Woleu-Ntem",
        "value": "GA-9"
      }
    ],
    "value": "GA"
  },
  {
    "label": "Gambia",
    "options": [
      {
        "label": "Banjul",
        "value": "GM-B"
      },
      {
        "label": "Central River",
        "value": "GM-M"
      },
      {
        "label": "Lower River",
        "value": "GM-L"
      },
      {
        "label": "North Bank",
        "value": "GM-N"
      },
      {
        "label": "Upper River",
        "value": "GM-U"
      },
      {
        "label": "Western",
        "value": "GM-W"
      }
    ],
    "value": "GM"
  },
  {
    "label": "Georgia",
    "options": [
      {
        "label": "Abkhazia",
        "value": "GE-AB"
      },
      {
        "label": "Ajaria",
        "value": "GE-AJ"
      },
      {
        "label": "Guria",
        "value": "GE-GU"
      },
      {
        "label": "Imereti",
        "value": "GE-IM"
      },
      {
        "label": "K'akheti",
        "value": "GE-KA"
      },
      {
        "label": "Kvemo Kartli",
        "value": "GE-KK"
      },
      {
        "label": "Mtskheta-Mtianeti",
        "value": "GE-MM"
      },
      {
        "label": "Rach'a-Lechkhumi-Kvemo Svaneti",
        "value": "GE-RL"
      },
      {
        "label": "Samegrelo-Zemo Svaneti",
        "value": "GE-SZ"
      },
      {
        "label": "Samtskhe-Javakheti",
        "value": "GE-SJ"
      },
      {
        "label": "Shida Kartli",
        "value": "GE-SK"
      },
      {
        "label": "Tbilisi",
        "value": "GE-TB"
      }
    ],
    "value": "GE"
  },
  {
    "label": "Germany",
    "options": [
      {
        "label": "Baden-Wurttemberg",
        "value": "DE-BW"
      },
      {
        "label": "Bayern",
        "value": "DE-BY"
      },
      {
        "label": "Berlin",
        "value": "DE-BE"
      },
      {
        "label": "Brandenburg",
        "value": "DE-BB"
      },
      {
        "label": "Bremen",
        "value": "DE-HB"
      },
      {
        "label": "Hamburg",
        "value": "DE-HH"
      },
      {
        "label": "Hessen",
        "value": "DE-HE"
      },
      {
        "label": "Mecklenburg-Vorpommern",
        "value": "DE-MV"
      },
      {
        "label": "Niedersachsen",
        "value": "DE-NI"
      },
      {
        "label": "Nordrhein-Westfalen",
        "value": "DE-NW"
      },
      {
        "label": "Rheinland-Pfalz",
        "value": "DE-RP"
      },
      {
        "label": "Saarland",
        "value": "DE-SL"
      },
      {
        "label": "Sachsen",
        "value": "DE-SN"
      },
      {
        "label": "Sachsen-Anhalt",
        "value": "DE-ST"
      },
      {
        "label": "Schleswig-Holstein",
        "value": "DE-SH"
      },
      {
        "label": "Thuringen",
        "value": "DE-TH"
      }
    ],
    "value": "DE"
  },
  {
    "label": "Ghana",
    "options": [
      {
        "label": "Ahafo",
        "value": "GH-AF"
      },
      {
        "label": "Ashanti",
        "value": "GH-AH"
      },
      {
        "label": "Bono",
        "value": "GH-BO"
      },
      {
        "label": "Bono East",
        "value": "GH-BE"
      },
      {
        "label": "Central",
        "value": "GH-CP"
      },
      {
        "label": "Eastern",
        "value": "GH-EP"
      },
      {
        "label": "Greater Accra",
        "value": "GH-AA"
      },
      {
        "label": "Northern",
        "value": "GH-NP"
      },
      {
        "label": "Upper East",
        "value": "GH-UE"
      },
      {
        "label": "Upper West",
        "value": "GH-UW"
      },
      {
        "label": "Volta",
        "value": "GH-TV"
      },
      {
        "label": "Western",
        "value": "GH-WP"
      }
    ],
    "value": "GH"
  },
  {
    "label": "Gibraltar",
    "value": "GI"
  },
  {
    "label": "Greece",
    "options": [
      {
        "label": "Anatoliki Makedonia kai Thraki",
        "value": "GR-A"
      },
      {
        "label": "Attiki",
        "value": "GR-I"
      },
      {
        "label": "Dytiki Ellada",
        "value": "GR-G"
      },
      {
        "label": "Dytiki Makedonia",
        "value": "GR-C"
      },
      {
        "label": "Ionia Nisia",
        "value": "GR-F"
      },
      {
        "label": "Ipeiros",
        "value": "GR-D"
      },
      {
        "label": "Kentriki Makedonia",
        "value": "GR-B"
      },
      {
        "label": "Kriti",
        "value": "GR-M"
      },
      {
        "label": "Notio Aigaio",
        "value": "GR-L"
      },
      {
        "label": "Peloponnisos",
        "value": "GR-J"
      },
      {
        "label": "Sterea Ellada",
        "value": "GR-H"
      },
      {
        "label": "Thessalia",
        "value": "GR-E"
      },
      {
        "label": "Voreio Aigaio",
        "value": "GR-K"
      }
    ],
    "value": "GR"
  },
  {
    "label": "Greenland",
    "options": [
      {
        "label": "Avannaata Kommunia",
        "value": "GL-AV"
      },
      {
        "label": "Kommune Kujalleq",
        "value": "GL-KU"
      },
      {
        "label": "Kommune Qeqertalik",
        "value": "GL-QT"
      },
      {
        "label": "Kommuneqarfik Sermersooq",
        "value": "GL-SM"
      },
      {
        "label": "Qeqqata Kommunia",
        "value": "GL-QE"
      }
    ],
    "value": "GL"
  },
  {
    "label": "Grenada",
    "options": [
      {
        "label": "Saint Andrew",
        "value": "GD-01"
      },
      {
        "label": "Saint David",
        "value": "GD-02"
      },
      {
        "label": "Saint George",
        "value": "GD-03"
      },
      {
        "label": "Saint John",
        "value": "GD-04"
      },
      {
        "label": "Saint Mark",
        "value": "GD-05"
      },
      {
        "label": "Saint Patrick",
        "value": "GD-06"
      },
      {
        "label": "Southern Grenadine Islands",
        "value": "GD-10"
      }
    ],
    "value": "GD"
  },
  {
    "label": "Guadeloupe",
    "value": "GP"
  },
  {
    "label": "Guam",
    "value": "GU"
  },
  {
    "label": "Guatemala",
    "options": [
      {
        "label": "Alta Verapaz",
        "value": "GT-16"
      },
      {
        "label": "Baja Verapaz",
        "value": "GT-15"
      },
      {
        "label": "Chimaltenango",
        "value": "GT-04"
      },
      {
        "label": "Chiquimula",
        "value": "GT-20"
      },
      {
        "label": "El Progreso",
        "value": "GT-02"
      },
      {
        "label": "Escuintla",
        "value": "GT-05"
      },
      {
        "label": "Guatemala",
        "value": "GT-01"
      },
      {
        "label": "Huehuetenango",
        "value": "GT-13"
      },
      {
        "label": "Izabal",
        "value": "GT-18"
      },
      {
        "label": "Jalapa",
        "value": "GT-21"
      },
      {
        "label": "Jutiapa",
        "value": "GT-22"
      },
      {
        "label": "Peten",
        "value": "GT-17"
      },
      {
        "label": "Quetzaltenango",
        "value": "GT-09"
      },
      {
        "label": "Quiche",
        "value": "GT-14"
      },
      {
        "label": "Retalhuleu",
        "value": "GT-11"
      },
      {
        "label": "Sacatepequez",
        "value": "GT-03"
      },
      {
        "label": "San Marcos",
        "value": "GT-12"
      },
      {
        "label": "Santa Rosa",
        "value": "GT-06"
      },
      {
        "label": "Solola",
        "value": "GT-07"
      },
      {
        "label": "Suchitepequez",
        "value": "GT-10"
      },
      {
        "label": "Totonicapan",
        "value": "GT-08"
      },
      {
        "label": "Zacapa",
        "value": "GT-19"
      }
    ],
    "value": "GT"
  },
  {
    "label": "Guernsey",
    "value": "GG"
  },
  {
    "label": "Guinea",
    "options": [
      {
        "label": "Beyla",
        "value": "GN-BE"
      },
      {
        "label": "Boffa",
        "value": "GN-BF"
      },
      {
        "label": "Boke",
        "value": "GN-BK"
      },
      {
        "label": "Conakry",
        "value": "GN-C"
      },
      {
        "label": "Dabola",
        "value": "GN-DB"
      },
      {
        "label": "Dinguiraye",
        "value": "GN-DI"
      },
      {
        "label": "Dubreka",
        "value": "GN-DU"
      },
      {
        "label": "Fria",
        "value": "GN-FR"
      },
      {
        "label": "Kankan",
        "value": "GN-KA"
      },
      {
        "label": "Kouroussa",
        "value": "GN-KO"
      },
      {
        "label": "Labe",
        "value": "GN-LA"
      },
      {
        "label": "Mamou",
        "value": "GN-MM"
      },
      {
        "label": "Siguiri",
        "value": "GN-SI"
      }
    ],
    "value": "GN"
  },
  {
    "label": "Guinea-Bissau",
    "options": [
      {
        "label": "Bafata",
        "value": "GW-BA"
      },
      {
        "label": "Bissau",
        "value": "GW-BS"
      },
      {
        "label": "Cacheu",
        "value": "GW-CA"
      },
      {
        "label": "Gabu",
        "value": "GW-GA"
      },
      {
        "label": "Oio",
        "value": "GW-OI"
      },
      {
        "label": "Quinara",
        "value": "GW-QU"
      }
    ],
    "value": "GW"
  },
  {
    "label": "Guyana",
    "options": [
      {
        "label": "Barima-Waini",
        "value": "GY-BA"
      },
      {
        "label": "Cuyuni-Mazaruni",
        "value": "GY-CU"
      },
      {
        "label": "Demerara-Mahaica",
        "value": "GY-DE"
      },
      {
        "label": "East Berbice-Corentyne",
        "value": "GY-EB"
      },
      {
        "label": "Essequibo Islands-West Demerara",
        "value": "GY-ES"
      },
      {
        "label": "Mahaica-Berbice",
        "value": "GY-MA"
      },
      {
        "label": "Pomeroon-Supenaam",
        "value": "GY-PM"
      },
      {
        "label": "Potaro-Siparuni",
        "value": "GY-PT"
      },
      {
        "label": "Upper Demerara-Berbice",
        "value": "GY-UD"
      }
    ],
    "value": "GY"
  },
  {
    "label": "Haiti",
    "options": [
      {
        "label": "Artibonite",
        "value": "HT-AR"
      },
      {
        "label": "Centre",
        "value": "HT-CE"
      },
      {
        "label": "Grande'Anse",
        "value": "HT-GA"
      },
      {
        "label": "Nord",
        "value": "HT-ND"
      },
      {
        "label": "Nord-Est",
        "value": "HT-NE"
      },
      {
        "label": "Nord-Ouest",
        "value": "HT-NO"
      },
      {
        "label": "Ouest",
        "value": "HT-OU"
      },
      {
        "label": "Sud",
        "value": "HT-SD"
      },
      {
        "label": "Sud-Est",
        "value": "HT-SE"
      }
    ],
    "value": "HT"
  },
  {
    "label": "Heard Island and McDonald Islands",
    "value": "HM"
  },
  {
    "label": "Holy See",
    "value": "VA"
  },
  {
    "label": "Honduras",
    "options": [
      {
        "label": "Atlantida",
        "value": "HN-AT"
      },
      {
        "label": "Choluteca",
        "value": "HN-CH"
      },
      {
        "label": "Colon",
        "value": "HN-CL"
      },
      {
        "label": "Comayagua",
        "value": "HN-CM"
      },
      {
        "label": "Copan",
        "value": "HN-CP"
      },
      {
        "label": "Cortes",
        "value": "HN-CR"
      },
      {
        "label": "El Paraiso",
        "value": "HN-EP"
      },
      {
        "label": "Francisco Morazan",
        "value": "HN-FM"
      },
      {
        "label": "Intibuca",
        "value": "HN-IN"
      },
      {
        "label": "Islas de la Bahia",
        "value": "HN-IB"
      },
      {
        "label": "La Paz",
        "value": "HN-LP"
      },
      {
        "label": "Lempira",
        "value": "HN-LE"
      },
      {
        "label": "Ocotepeque",
        "value": "HN-OC"
      },
      {
        "label": "Olancho",
        "value": "HN-OL"
      },
      {
        "label": "Santa Barbara",
        "value": "HN-SB"
      },
      {
        "label": "Valle",
        "value": "HN-VA"
      },
      {
        "label": "Yoro",
        "value": "HN-YO"
      }
    ],
    "value": "HN"
  },
  {
    "label": "Hong Kong",
    "value": "HK"
  },
  {
    "label": "Hungary",
    "options": [
      {
        "label": "Bacs-Kiskun",
        "value": "HU-BK"
      },
      {
        "label": "Baranya",
        "value": "HU-BA"
      },
      {
        "label": "Bekes",
        "value": "HU-BE"
      },
      {
        "label": "Borsod-Abauj-Zemplen",
        "value": "HU-BZ"
      },
      {
        "label": "Budapest",
        "value": "HU-BU"
      },
      {
        "label": "Csongrad-Csanad",
        "value": "HU-CS"
      },
      {
        "label": "Fejer",
        "value": "HU-FE"
      },
      {
        "label": "Gyor-Moson-Sopron",
        "value": "HU-GS"
      },
      {
        "label": "Hajdu-Bihar",
        "value": "HU-HB"
      },
      {
        "label": "Heves",
        "value": "HU-HE"
      },
      {
        "label": "Jasz-Nagykun-Szolnok",
        "value": "HU-JN"
      },
      {
        "label": "Komarom-Esztergom",
        "value": "HU-KE"
      },
      {
        "label": "Nograd",
        "value": "HU-NO"
      },
      {
        "label": "Pest",
        "value": "HU-PE"
      },
      {
        "label": "Somogy",
        "value": "HU-SO"
      },
      {
        "label": "Szabolcs-Szatmar-Bereg",
        "value": "HU-SZ"
      },
      {
        "label": "Tolna",
        "value": "HU-TO"
      },
      {
        "label": "Vas",
        "value": "HU-VA"
      },
      {
        "label": "Veszprem",
        "value": "HU-VE"
      },
      {
        "label": "Zala",
        "value": "HU-ZA"
      }
    ],
    "value": "HU"
  },
  {
    "label": "Iceland",
    "options": [
      {
        "label": "Austurland",
        "value": "IS-7"
      },
      {
        "label": "Hofudborgarsvaedi",
        "value": "IS-1"
      },
      {
        "label": "Nordurland eystra",
        "value": "IS-6"
      },
      {
        "label": "Nordurland vestra",
        "value": "IS-5"
      },
      {
        "label": "Sudurland",
        "value": "IS-8"
      },
      {
        "label": "Sudurnes",
        "value": "IS-2"
      },
      {
        "label": "Vestfirdir",
        "value": "IS-4"
      },
      {
        "label": "Vesturland",
        "value": "IS-3"
      }
    ],
    "value": "IS"
  },
  {
    "label": "India",
    "options": [
      {
        "label": "Andaman and Nicobar Islands",
        "value": "IN-AN"
      },
      {
        "label": "Andhra Pradesh",
        "value": "IN-AP"
      },
      {
        "label": "Arunachal Pradesh",
        "value": "IN-AR"
      },
      {
        "label": "Assam",
        "value": "IN-AS"
      },
      {
        "label": "Bihar",
        "value": "IN-BR"
      },
      {
        "label": "Chandigarh",
        "value": "IN-CH"
      },
      {
        "label": "Chhattisgarh",
        "value": "IN-CG"
      },
      {
        "label": "Dadra and Nagar Haveli and Daman and Diu",
        "value": "IN-DH"
      },
      {
        "label": "Delhi",
        "value": "IN-DL"
      },
      {
        "label": "Goa",
        "value": "IN-GA"
      },
      {
        "label": "Gujarat",
        "value": "IN-GJ"
      },
      {
        "label": "Haryana",
        "value": "IN-HR"
      },
      {
        "label": "Himachal Pradesh",
        "value": "IN-HP"
      },
      {
        "label": "Jammu and Kashmir",
        "value": "IN-JK"
      },
      {
        "label": "Jharkhand",
        "value": "IN-JH"
      },
      {
        "label": "Karnataka",
        "value": "IN-KA"
      },
      {
        "label": "Kerala",
        "value": "IN-KL"
      },
      {
        "label": "Lakshadweep",
        "value": "IN-LD"
      },
      {
        "label": "Madhya Pradesh",
        "value": "IN-MP"
      },
      {
        "label": "Maharashtra",
        "value": "IN-MH"
      },
      {
        "label": "Manipur",
        "value": "IN-MN"
      },
      {
        "label": "Meghalaya",
        "value": "IN-ML"
      },
      {
        "label": "Mizoram",
        "value": "IN-MZ"
      },
      {
        "label": "Nagaland",
        "value": "IN-NL"
      },
      {
        "label": "Odisha",
        "value": "IN-OD"
      },
      {
        "label": "Puducherry",
        "value": "IN-PY"
      },
      {
        "label": "Punjab",
        "value": "IN-PB"
      },
      {
        "label": "Rajasthan",
        "value": "IN-RJ"
      },
      {
        "label": "Sikkim",
        "value": "IN-SK"
      },
      {
        "label": "Tamil Nadu",
        "value": "IN-TN"
      },
      {
        "label": "Telangana",
        "value": "IN-TS"
      },
      {
        "label": "Tripura",
        "value": "IN-TR"
      },
      {
        "label": "Uttar Pradesh",
        "value": "IN-UP"
      },
      {
        "label": "Uttarakhand",
        "value": "IN-UK"
      },
      {
        "label": "West Bengal",
        "value": "IN-WB"
      }
    ],
    "value": "IN"
  },
  {
    "label": "Indonesia",
    "options": [
      {
        "label": "Aceh",
        "value": "ID-AC"
      },
      {
        "label": "Bali",
        "value": "ID-BA"
      },
      {
        "label": "Banten",
        "value": "ID-BT"
      },
      {
        "label": "Bengkulu",
        "value": "ID-BE"
      },
      {
        "label": "Gorontalo",
        "value": "ID-GO"
      },
      {
        "label": "Jakarta Raya",
        "value": "ID-JK"
      },
      {
        "label": "Jambi",
        "value": "ID-JA"
      },
      {
        "label": "Jawa Barat",
        "value": "ID-JB"
      },
      {
        "label": "Jawa Tengah",
        "value": "ID-JT"
      },
      {
        "label": "Jawa Timur",
        "value": "ID-JI"
      },
      {
        "label": "Kalimantan Barat",
        "value": "ID-KB"
      },
      {
        "label": "Kalimantan Selatan",
        "value": "ID-KS"
      },
      {
        "label": "Kalimantan Tengah",
        "value": "ID-KT"
      },
      {
        "label": "Kalimantan Timur",
        "value": "ID-KI"
      },
      {
        "label": "Kalimantan Utara",
        "value": "ID-KU"
      },
      {
        "label": "Kepulauan Bangka Belitung",
        "value": "ID-BB"
      },
      {
        "label": "Kepulauan Riau",
        "value": "ID-KR"
      },
      {
        "label": "Lampung",
        "value": "ID-LA"
      },
      {
        "label": "Maluku",
        "value": "ID-ML"
      },
      {
        "label": "Maluku Utara",
        "value": "ID-MU"
      },
      {
        "label": "Nusa Tenggara Barat",
        "value": "ID-NB"
      },
      {
        "label": "Nusa Tenggara Timur",
        "value": "ID-NT"
      },
      {
        "label": "Papua",
        "value": "ID-PP"
      },
      {
        "label": "Papua Barat",
        "value": "ID-PB"
      },
      {
        "label": "Papua Barat Daya",
        "value": "ID-PD"
      },
      {
        "label": "Papua Pengunungan",
        "value": "ID-PE"
      },
      {
        "label": "Papua Selatan",
        "value": "ID-PS"
      },
      {
        "label": "Papua Tengah",
        "value": "ID-PT"
      },
      {
        "label": "Riau",
        "value": "ID-RI"
      },
      {
        "label": "Sulawesi Barat",
        "value": "ID-SR"
      },
      {
        "label": "Sulawesi Selatan",
        "value": "ID-SN"
      },
      {
        "label": "Sulawesi Tengah",
        "value": "ID-ST"
      },
      {
        "label": "Sulawesi Tenggara",
        "value": "ID-SG"
      },
      {
        "label": "Sulawesi Utara",
        "value": "ID-SA"
      },
      {
        "label": "Sumatera Barat",
        "value": "ID-SB"
      },
      {
        "label": "Sumatera Selatan",
        "value": "ID-SS"
      },
      {
        "label": "Sumatera Utara",
        "value": "ID-SU"
      },
      {
        "label": "Yogyakarta",
        "value": "ID-YO"
      }
    ],
    "value": "ID"
  },
  {
    "label": "Iran, Islamic Republic of",
    "options": [
      {
        "label": "Alborz",
        "value": "IR-30"
      },
      {
        "label": "Ardabil",
        "value": "IR-24"
      },
      {
        "label": "Azarbayjan-e Gharbi",
        "value": "IR-04"
      },
      {
        "label": "Azarbayjan-e Sharqi",
        "value": "IR-03"
      },
      {
        "label": "Bushehr",
        "value": "IR-18"
      },
      {
        "label": "Chahar Mahal va Bakhtiari",
        "value": "IR-14"
      },
      {
        "label": "Esfahan",
        "value": "IR-10"
      },
      {
        "label": "Fars",
        "value": "IR-07"
      },
      {
        "label": "Gilan",
        "value": "IR-01"
      },
      {
        "label": "Golestan",
        "value": "IR-27"
      },
      {
        "label": "Hamadan",
        "value": "IR-13"
      },
      {
        "label": "Hormozgan",
        "value": "IR-22"
      },
      {
        "label": "Ilam",
        "value": "IR-16"
      },
      {
        "label": "Kerman",
        "value": "IR-08"
      },
      {
        "label": "Kermanshah",
        "value": "IR-05"
      },
      {
        "label": "Khorasan-e Jonubi",
        "value": "IR-29"
      },
      {
        "label": "Khorasan-e Razavi",
        "value": "IR-09"
      },
      {
        "label": "Khorasan-e Shomali",
        "value": "IR-28"
      },
      {
        "label": "Khuzestan",
        "value": "IR-06"
      },
      {
        "label": "Kohgiluyeh va Bowyer Ahmad",
        "value": "IR-17"
      },
      {
        "label": "Kordestan",
        "value": "IR-12"
      },
      {
        "label": "Lorestan",
        "value": "IR-15"
      },
      {
        "label": "Markazi",
        "value": "IR-00"
      },
      {
        "label": "Mazandaran",
        "value": "IR-02"
      },
      {
        "label": "Qazvin",
        "value": "IR-26"
      },
      {
        "label": "Qom",
        "value": "IR-25"
      },
      {
        "label": "Semnan",
        "value": "IR-20"
      },
      {
        "label": "Sistan va Baluchestan",
        "value": "IR-11"
      },
      {
        "label": "Tehran",
        "value": "IR-23"
      },
      {
        "label": "Yazd",
        "value": "IR-21"
      },
      {
        "label": "Zanjan",
        "value": "IR-19"
      }
    ],
    "value": "IR"
  },
  {
    "label": "Iraq",
    "options": [
      {
        "label": "Al Anbar",
        "value": "IQ-AN"
      },
      {
        "label": "Al Basrah",
        "value": "IQ-BA"
      },
      {
        "label": "Al Muthanna",
        "value": "IQ-MU"
      },
      {
        "label": "Al Qadisiyah",
        "value": "IQ-QA"
      },
      {
        "label": "An Najaf",
        "value": "IQ-NA"
      },
      {
        "label": "Arbil",
        "value": "IQ-AR"
      },
      {
        "label": "As Sulaymaniyah",
        "value": "IQ-SU"
      },
      {
        "label": "Babil",
        "value": "IQ-BB"
      },
      {
        "label": "Baghdad",
        "value": "IQ-BG"
      },
      {
        "label": "Dahuk",
        "value": "IQ-DA"
      },
      {
        "label": "Dhi Qar",
        "value": "IQ-DQ"
      },
      {
        "label": "Diyala",
        "value": "IQ-DI"
      },
      {
        "label": "Karbala'",
        "value": "IQ-KA"
      },
      {
        "label": "Kirkuk",
        "value": "IQ-KI"
      },
      {
        "label": "Maysan",
        "value": "IQ-MA"
      },
      {
        "label": "Ninawa",
        "value": "IQ-NI"
      },
      {
        "label": "Salah ad Din",
        "value": "IQ-SD"
      },
      {
        "label": "Wasit",
        "value": "IQ-WA"
      }
    ],
    "value": "IQ"
  },
  {
    "label": "Ireland",
    "options": [
      {
        "label": "Carlow",
        "value": "IE-CW"
      },
      {
        "label": "Cavan",
        "value": "IE-CN"
      },
      {
        "label": "Clare",
        "value": "IE-CE"
      },
      {
        "label": "Cork",
        "value": "IE-CO"
      },
      {
        "label": "Donegal",
        "value": "IE-DL"
      },
      {
        "label": "Dublin",
        "value": "IE-D"
      },
      {
        "label": "Galway",
        "value": "IE-G"
      },
      {
        "label": "Kerry",
        "value": "IE-KY"
      },
      {
        "label": "Kildare",
        "value": "IE-KE"
      },
      {
        "label": "Kilkenny",
        "value": "IE-KK"
      },
      {
        "label": "Laois",
        "value": "IE-LS"
      },
      {
        "label": "Leitrim",
        "value": "IE-LM"
      },
      {
        "label": "Limerick",
        "value": "IE-LK"
      },
      {
        "label": "Longford",
        "value": "IE-LD"
      },
      {
        "label": "Louth",
        "value": "IE-LH"
      },
      {
        "label": "Mayo",
        "value": "IE-MO"
      },
      {
        "label": "Meath",
        "value": "IE-MH"
      },
      {
        "label": "Monaghan",
        "value": "IE-MN"
      },
      {
        "label": "Offaly",
        "value": "IE-OY"
      },
      {
        "label": "Roscommon",
        "value": "IE-RN"
      },
      {
        "label": "Sligo",
        "value": "IE-SO"
      },
      {
        "label": "Tipperary",
        "value": "IE-TA"
      },
      {
        "label": "Waterford",
        "value": "IE-WD"
      },
      {
        "label": "Westmeath",
        "value": "IE-WH"
      },
      {
        "label": "Wexford",
        "value": "IE-WX"
      },
      {
        "label": "Wicklow",
        "value": "IE-WW"
      }
    ],
    "value": "IE"
  },
  {
    "label": "Isle of Man",
    "value": "IM"
  },
  {
    "label": "Israel",
    "options": [
      {
        "label": "HaDarom",
        "value": "IL-D"
      },
      {
        "label": "HaMerkaz",
        "value": "IL-M"
      },
      {
        "label": "HaTsafon",
        "value": "IL-Z"
      },
      {
        "label": "Hefa",
        "value": "IL-HA"
      },
      {
        "label": "Tel Aviv",
        "value": "IL-TA"
      },
      {
        "label": "Yerushalayim",
        "value": "IL-JM"
      }
    ],
    "value": "IL"
  },
  {
    "label": "Italy",
    "options": [
      {
        "label": "Abruzzo",
        "value": "IT-65"
      },
      {
        "label": "Basilicata",
        "value": "IT-77"
      },
      {
        "label": "Calabria",
        "value": "IT-78"
      },
      {
        "label": "Campania",
        "value": "IT-72"
      },
      {
        "label": "Emilia-Romagna",
        "value": "IT-45"
      },
      {
        "label": "Friuli-Venezia Giulia",
        "value": "IT-36"
      },
      {
        "label": "Lazio",
        "value": "IT-62"
      },
      {
        "label": "Liguria",
        "value": "IT-42"
      },
      {
        "label": "Lombardia",
        "value": "IT-25"
      },
      {
        "label": "Marche",
        "value": "IT-57"
      },
      {
        "label": "Molise",
        "value": "IT-67"
      },
      {
        "label": "Piemonte",
        "value": "IT-21"
      },
      {
        "label": "Puglia",
        "value": "IT-75"
      },
      {
        "label": "Sardegna",
        "value": "IT-88"
      },
      {
        "label": "Sicilia",
        "value": "IT-82"
      },
      {
        "label": "Toscana",
        "value": "IT-52"
      },
      {
        "label": "Trentino-Alto Adige",
        "value": "IT-32"
      },
      {
        "label": "Umbria",
        "value": "IT-55"
      },
      {
        "label": "Valle d'Aosta",
        "value": "IT-23"
      },
      {
        "label": "Veneto",
        "value": "IT-34"
      }
    ],
    "value": "IT"
  },
  {
    "label": "Jamaica",
    "options": [
      {
        "label": "Clarendon",
        "value": "JM-13"
      },
      {
        "label": "Hanover",
        "value": "JM-09"
      },
      {
        "label": "Kingston",
        "value": "JM-01"
      },
      {
        "label": "Manchester",
        "value": "JM-12"
      },
      {
        "label": "Portland",
        "value": "JM-04"
      },
      {
        "label": "Saint Andrew",
        "value": "JM-02"
      },
      {
        "label": "Saint Ann",
        "value": "JM-06"
      },
      {
        "label": "Saint Catherine",
        "value": "JM-14"
      },
      {
        "label": "Saint Elizabeth",
        "value": "JM-11"
      },
      {
        "label": "Saint James",
        "value": "JM-08"
      },
      {
        "label": "Saint Mary",
        "value": "JM-05"
      },
      {
        "label": "Saint Thomas",
        "value": "JM-03"
      },
      {
        "label": "Trelawny",
        "value": "JM-07"
      },
      {
        "label": "Westmoreland",
        "value": "JM-10"
      }
    ],
    "value": "JM"
  },
  {
    "label": "Japan",
    "options": [
      {
        "label": "Aichi",
        "value": "JP-23"
      },
      {
        "label": "Akita",
        "value": "JP-05"
      },
      {
        "label": "Aomori",
        "value": "JP-02"
      },
      {
        "label": "Chiba",
        "value": "JP-12"
      },
      {
        "label": "Ehime",
        "value": "JP-38"
      },
      {
        "label": "Fukui",
        "value": "JP-18"
      },
      {
        "label": "Fukuoka",
        "value": "JP-40"
      },
      {
        "label": "Fukushima",
        "value": "JP-07"
      },
      {
        "label": "Gifu",
        "value": "JP-21"
      },
      {
        "label": "Gunma",
        "value": "JP-10"
      },
      {
        "label": "Hiroshima",
        "value": "JP-34"
      },
      {
        "label": "Hokkaido",
        "value": "JP-01"
      },
      {
        "label": "Hyogo",
        "value": "JP-28"
      },
      {
        "label": "Ibaraki",
        "value": "JP-08"
      },
      {
        "label": "Ishikawa",
        "value": "JP-17"
      },
      {
        "label": "Iwate",
        "value": "JP-03"
      },
      {
        "label": "Kagawa",
        "value": "JP-37"
      },
      {
        "label": "Kagoshima",
        "value": "JP-46"
      },
      {
        "label": "Kanagawa",
        "value": "JP-14"
      },
      {
        "label": "Kochi",
        "value": "JP-39"
      },
      {
        "label": "Kumamoto",
        "value": "JP-43"
      },
      {
        "label": "Kyoto",
        "value": "JP-26"
      },
      {
        "label": "Mie",
        "value": "JP-24"
      },
      {
        "label": "Miyagi",
        "value": "JP-04"
      },
      {
        "label": "Miyazaki",
        "value": "JP-45"
      },
      {
        "label": "Nagano",
        "value": "JP-20"
      },
      {
        "label": "Nagasaki",
        "value": "JP-42"
      },
      {
        "label": "Nara",
        "value": "JP-29"
      },
      {
        "label": "Niigata",
        "value": "JP-15"
      },
      {
        "label": "Oita",
        "value": "JP-44"
      },
      {
        "label": "Okayama",
        "value": "JP-33"
      },
      {
        "label": "Okinawa",
        "value": "JP-47"
      },
      {
        "label": "Osaka",
        "value": "JP-27"
      },
      {
        "label": "Saga",
        "value": "JP-41"
      },
      {
        "label": "Saitama",
        "value": "JP-11"
      },
      {
        "label": "Shiga",
        "value": "JP-25"
      },
      {
        "label": "Shimane",
        "value": "JP-32"
      },
      {
        "label": "Shizuoka",
        "value": "JP-22"
      },
      {
        "label": "Tochigi",
        "value": "JP-09"
      },
      {
        "label": "Tokushima",
        "value": "JP-36"
      },
      {
        "label": "Tokyo",
        "value": "JP-13"
      },
      {
        "label": "Tottori",
        "value": "JP-31"
      },
      {
        "label": "Toyama",
        "value": "JP-16"
      },
      {
        "label": "Wakayama",
        "value": "JP-30"
      },
      {
        "label": "Yamagata",
        "value": "JP-06"
      },
      {
        "label": "Yamaguchi",
        "value": "JP-35"
      },
      {
        "label": "Yamanashi",
        "value": "JP-19"
      }
    ],
    "value": "JP"
  },
  {
    "label": "Jersey",
    "value": "JE"
  },
  {
    "label": "Jordan",
    "options": [
      {
        "label": "'Ajlun",
        "value": "JO-AJ"
      },
      {
        "label": "Al 'Aqabah",
        "value": "JO-AQ"
      },
      {
        "label": "Al 'Asimah",
        "value": "JO-AM"
      },
      {
        "label": "Al Balqa'",
        "value": "JO-BA"
      },
      {
        "label": "Al Karak",
        "value": "JO-KA"
      },
      {
        "label": "Al Mafraq",
        "value": "JO-MA"
      },
      {
        "label": "At Tafilah",
        "value": "JO-AT"
      },
      {
        "label": "Az Zarqa'",
        "value": "JO-AZ"
      },
      {
        "label": "Irbid",
        "value": "JO-IR"
      },
      {
        "label": "Jarash",
        "value": "JO-JA"
      },
      {
        "label": "Ma'an",
        "value": "JO-MN"
      },
      {
        "label": "Madaba",
        "value": "JO-MD"
      }
    ],
    "value": "JO"
  },
  {
    "label": "Kazakhstan",
    "options": [
      {
        "label": "Abay oblysy",
        "value": "KZ-10"
      },
      {
        "label": "Almaty",
        "value": "KZ-75"
      },
      {
        "label": "Almaty oblysy",
        "value": "KZ-19"
      },
      {
        "label": "Aqmola oblysy",
        "value": "KZ-11"
      },
      {
        "label": "Aqtobe oblysy",
        "value": "KZ-15"
      },
      {
        "label": "Astana",
        "value": "KZ-71"
      },
      {
        "label": "Atyrau oblysy",
        "value": "KZ-23"
      },
      {
        "label": "Batys Qazaqstan oblysy",
        "value": "KZ-27"
      },
      {
        "label": "Mangghystau oblysy",
        "value": "KZ-47"
      },
      {
        "label": "Pavlodar oblysy",
        "value": "KZ-55"
      },
      {
        "label": "Qaraghandy oblysy",
        "value": "KZ-35"
      },
      {
        "label": "Qostanay oblysy",
        "value": "KZ-39"
      },
      {
        "label": "Qyzylorda oblysy",
        "value": "KZ-43"
      },
      {
        "label": "Shyghys Qazaqstan oblysy",
        "value": "KZ-63"
      },
      {
        "label": "Shymkent",
        "value": "KZ-79"
      },
      {
        "label": "Soltustik Qazaqstan oblysy",
        "value": "KZ-59"
      },
      {
        "label": "Turkistan oblysy",
        "value": "KZ-61"
      },
      {
        "label": "Ulytau oblysy",
        "value": "KZ-62"
      },
      {
        "label": "Zhambyl oblysy",
        "value": "KZ-31"
      },
      {
        "label": "Zhetisu oblysy",
        "value": "KZ-33"
      }
    ],
    "value": "KZ"
  },
  {
    "label": "Kenya",
    "options": [
      {
        "label": "Baringo",
        "value": "KE-01"
      },
      {
        "label": "Bomet",
        "value": "KE-02"
      },
      {
        "label": "Bungoma",
        "value": "KE-03"
      },
      {
        "label": "Busia",
        "value": "KE-04"
      },
      {
        "label": "Elgeyo/Marakwet",
        "value": "KE-05"
      },
      {
        "label": "Embu",
        "value": "KE-06"
      },
      {
        "label": "Garissa",
        "value": "KE-07"
      },
      {
        "label": "Homa Bay",
        "value": "KE-08"
      },
      {
        "label": "Isiolo",
        "value": "KE-09"
      },
      {
        "label": "Kajiado",
        "value": "KE-10"
      },
      {
        "label": "Kakamega",
        "value": "KE-11"
      },
      {
        "label": "Kericho",
        "value": "KE-12"
      },
      {
        "label": "Kiambu",
        "value": "KE-13"
      },
      {
        "label": "Kilifi",
        "value": "KE-14"
      },
      {
        "label": "Kirinyaga",
        "value": "KE-15"
      },
      {
        "label": "Kisii",
        "value": "KE-16"
      },
      {
        "label": "Kisumu",
        "value": "KE-17"
      },
      {
        "label": "Kitui",
        "value": "KE-18"
      },
      {
        "label": "Kwale",
        "value": "KE-19"
      },
      {
        "label": "Laikipia",
        "value": "KE-20"
      },
      {
        "label": "Lamu",
        "value": "KE-21"
      },
      {
        "label": "Machakos",
        "value": "KE-22"
      },
      {
        "label": "Makueni",
        "value": "KE-23"
      },
      {
        "label": "Mandera",
        "value": "KE-24"
      },
      {
        "label": "Marsabit",
        "value": "KE-25"
      },
      {
        "label": "Meru",
        "value": "KE-26"
      },
      {
        "label": "Migori",
        "value": "KE-27"
      },
      {
        "label": "Mombasa",
        "value": "KE-28"
      },
      {
        "label": "Murang'a",
        "value": "KE-29"
      },
      {
        "label": "Nairobi City",
        "value": "KE-30"
      },
      {
        "label": "Nakuru",
        "value": "KE-31"
      },
      {
        "label": "Nandi",
        "value": "KE-32"
      },
      {
        "label": "Narok",
        "value": "KE-33"
      },
      {
        "label": "Nyamira",
        "value": "KE-34"
      },
      {
        "label": "Nyandarua",
        "value": "KE-35"
      },
      {
        "label": "Nyeri",
        "value": "KE-36"
      },
      {
        "label": "Samburu",
        "value": "KE-37"
      },
      {
        "label": "Siaya",
        "value": "KE-38"
      },
      {
        "label": "Taita/Taveta",
        "value": "KE-39"
      },
      {
        "label": "Tana River",
        "value": "KE-40"
      },
      {
        "label": "Tharaka-Nithi",
        "value": "KE-41"
      },
      {
        "label": "Trans Nzoia",
        "value": "KE-42"
      },
      {
        "label": "Turkana",
        "value": "KE-43"
      },
      {
        "label": "Uasin Gishu",
        "value": "KE-44"
      },
      {
        "label": "Vihiga",
        "value": "KE-45"
      },
      {
        "label": "Wajir",
        "value": "KE-46"
      },
      {
        "label": "West Pokot",
        "value": "KE-47"
      }
    ],
    "value": "KE"
  },
  {
    "label": "Kiribati",
    "options": [
      {
        "label": "Gilbert Islands",
        "value": "KI-G"
      },
      {
        "label": "Line Islands",
        "value": "KI-L"
      }
    ],
    "value": "KI"
  },
  {
    "label": "Korea, Democratic People's Republic of",
    "options": [
      {
        "label": "P'yongyang",
        "value": "KP-01"
      }
    ],
    "value": "KP"
  },
  {
    "label": "Korea, Republic of",
    "options": [
      {
        "label": "Busan-gwangyeoksi",
        "value": "KR-26"
      },
      {
        "label": "Chungcheongbuk-do",
        "value": "KR-43"
      },
      {
        "label": "Chungcheongnam-do",
        "value": "KR-44"
      },
      {
        "label": "Daegu-gwangyeoksi",
        "value": "KR-27"
      },
      {
        "label": "Daejeon-gwangyeoksi",
        "value": "KR-30"
      },
      {
        "label": "Gangwon-teukbyeoljachido",
        "value": "KR-42"
      },
      {
        "label": "Gwangju-gwangyeoksi",
        "value": "KR-29"
      },
      {
        "label": "Gyeonggi-do",
        "value": "KR-41"
      },
      {
        "label": "Gyeongsangbuk-do",
        "value": "KR-47"
      },
      {
        "label": "Gyeongsangnam-do",
        "value": "KR-48"
      },
      {
        "label": "Incheon-gwangyeoksi",
        "value": "KR-28"
      },
      {
        "label": "Jeju-teukbyeoljachido",
        "value": "KR-49"
      },
      {
        "label": "Jeollabuk-do",
        "value": "KR-45"
      },
      {
        "label": "Jeollanam-do",
        "value": "KR-46"
      },
      {
        "label": "Sejong",
        "value": "KR-50"
      },
      {
        "label": "Seoul-teukbyeolsi",
        "value": "KR-11"
      },
      {
        "label": "Ulsan-gwangyeoksi",
        "value": "KR-31"
      }
    ],
    "value": "KR"
  },
  {
    "label": "Kuwait",
    "options": [
      {
        "label": "Al 'Asimah",
        "value": "KW-KU"
      },
      {
        "label": "Al Ahmadi",
        "value": "KW-AH"
      },
      {
        "label": "Al Farwaniyah",
        "value": "KW-FA"
      },
      {
        "label": "Al Jahra'",
        "value": "KW-JA"
      },
      {
        "label": "Hawalli",
        "value": "KW-HA"
      },
      {
        "label": "Mubarak al Kabir",
        "value": "KW-MU"
      }
    ],
    "value": "KW"
  },
  {
    "label": "Kyrgyzstan",
    "options": [
      {
        "label": "Batken",
        "value": "KG-B"
      },
      {
        "label": "Bishkek Shaary",
        "value": "KG-GB"
      },
      {
        "label": "Chuy",
        "value": "KG-C"
      },
      {
        "label": "Jalal-Abad",
        "value": "KG-J"
      },
      {
        "label": "Naryn",
        "value": "KG-N"
      },
      {
        "label": "Osh Shaary",
        "value": "KG-GO"
      },
      {
        "label": "Talas",
        "value": "KG-T"
      },
      {
        "label": "Ysyk-Kol",
        "value": "KG-Y"
      }
    ],
    "value": "KG"
  },
  {
    "label": "Lao People's Democratic Republic",
    "options": [
      {
        "label": "Attapu",
        "value": "LA-AT"
      },
      {
        "label": "Bokeo",
        "value": "LA-BK"
      },
      {
        "label": "Bolikhamxai",
        "value": "LA-BL"
      },
      {
        "label": "Champasak",
        "value": "LA-CH"
      },
      {
        "label": "Khammouan",
        "value": "LA-KH"
      },
      {
        "label": "Louang Namtha",
        "value": "LA-LM"
      },
      {
        "label": "Louangphabang",
        "value": "LA-LP"
      },
      {
        "label": "Oudomxai",
        "value": "LA-OU"
      },
      {
        "label": "Phongsali",
        "value": "LA-PH"
      },
      {
        "label": "Savannakhet",
        "value": "LA-SV"
      },
      {
        "label": "Viangchan",
        "value": "LA-VI"
      },
      {
        "label": "Xaignabouli",
        "value": "LA-XA"
      },
      {
        "label": "Xekong",
        "value": "LA-XE"
      },
      {
        "label": "Xiangkhouang",
        "value": "LA-XI"
      }
    ],
    "value": "LA"
  },
  {
    "label": "Latvia",
    "options": [
      {
        "label": "Adazu novads",
        "value": "LV-011"
      },
      {
        "label": "Aizkraukles novads",
        "value": "LV-002"
      },
      {
        "label": "Aluksnes novads",
        "value": "LV-007"
      },
      {
        "label": "Augsdaugavas novads",
        "value": "LV-111"
      },
      {
        "label": "Balvu novads",
        "value": "LV-015"
      },
      {
        "label": "Bauskas novads",
        "value": "LV-016"
      },
      {
        "label": "Cesu novads",
        "value": "LV-022"
      },
      {
        "label": "Daugavpils",
        "value": "LV-DGV"
      },
      {
        "label": "Dienvidkurzemes novads",
        "value": "LV-112"
      },
      {
        "label": "Dobeles novads",
        "value": "LV-026"
      },
      {
        "label": "Gulbenes novads",
        "value": "LV-033"
      },
      {
        "label": "Jekabpils novads",
        "value": "LV-042"
      },
      {
        "label": "Jelgava",
        "value": "LV-JEL"
      },
      {
        "label": "Jelgavas novads",
        "value": "LV-041"
      },
      {
        "label": "Jurmala",
        "value": "LV-JUR"
      },
      {
        "label": "Kekavas novads",
        "value": "LV-052"
      },
      {
        "label": "Kraslavas novads",
        "value": "LV-047"
      },
      {
        "label": "Kuldigas novads",
        "value": "LV-050"
      },
      {
        "label": "Liepaja",
        "value": "LV-LPX"
      },
      {
        "label": "Limbazu novads",
        "value": "LV-054"
      },
      {
        "label": "Livanu novads",
        "value": "LV-056"
      },
      {
        "label": "Ludzas novads",
        "value": "LV-058"
      },
      {
        "label": "Madonas novads",
        "value": "LV-059"
      },
      {
        "label": "Marupes novads",
        "value": "LV-062"
      },
      {
        "label": "Ogres novads",
        "value": "LV-067"
      },
      {
        "label": "Olaines novads",
        "value": "LV-068"
      },
      {
        "label": "Preilu novads",
        "value": "LV-073"
      },
      {
        "label": "Rezeknes novads",
        "value": "LV-077"
      },
      {
        "label": "Riga",
        "value": "LV-RIX"
      },
      {
        "label": "Ropazu novads",
        "value": "LV-080"
      },
      {
        "label": "Salaspils novads",
        "value": "LV-087"
      },
      {
        "label": "Saldus novads",
        "value": "LV-088"
      },
      {
        "label": "Saulkrastu novads",
        "value": "LV-089"
      },
      {
        "label": "Siguldas novads",
        "value": "LV-091"
      },
      {
        "label": "Smiltenes novads",
        "value": "LV-094"
      },
      {
        "label": "Talsu novads",
        "value": "LV-097"
      },
      {
        "label": "Tukuma novads",
        "value": "LV-099"
      },
      {
        "label": "Valkas novads",
        "value": "LV-101"
      },
      {
        "label": "Valmieras novads",
        "value": "LV-113"
      },
      {
        "label": "Varaklanu novads",
        "value": "LV-102"
      },
      {
        "label": "Ventspils novads",
        "value": "LV-106"
      }
    ],
    "value": "LV"
  },
  {
    "label": "Lebanon",
    "options": [
      {
        "label": "Aakkar",
        "value": "LB-AK"
      },
      {
        "label": "Baalbek-Hermel",
        "value": "LB-BH"
      },
      {
        "label": "Beqaa",
        "value": "LB-BI"
      },
      {
        "label": "Beyrouth",
        "value": "LB-BA"
      },
      {
        "label": "Liban-Nord",
        "value": "LB-AS"
      },
      {
        "label": "Liban-Sud",
        "value": "LB-JA"
      },
      {
        "label": "Mont-Liban",
        "value": "LB-JL"
      },
      {
        "label": "Nabatiye",
        "value": "LB-NA"
      }
    ],
    "value": "LB"
  },
  {
    "label": "Lesotho",
    "options": [
      {
        "label": "Berea",
        "value": "LS-D"
      },
      {
        "label": "Botha-Bothe",
        "value": "LS-B"
      },
      {
        "label": "Leribe",
        "value": "LS-C"
      },
      {
        "label": "Mafeteng",
        "value": "LS-E"
      },
      {
        "label": "Maseru",
        "value": "LS-A"
      },
      {
        "label": "Mohale's Hoek",
        "value": "LS-F"
      },
      {
        "label": "Mokhotlong",
        "value": "LS-J"
      },
      {
        "label": "Qacha's Nek",
        "value": "LS-H"
      },
      {
        "label": "Quthing",
        "value": "LS-G"
      },
      {
        "label": "Thaba-Tseka",
        "value": "LS-K"
      }
    ],
    "value": "LS"
  },
  {
    "label": "Liberia",
    "options": [
      {
        "label": "Bomi",
        "value": "LR-BM"
      },
      {
        "label": "Bong",
        "value": "LR-BG"
      },
      {
        "label": "Grand Cape Mount",
        "value": "LR-CM"
      },
      {
        "label": "Grand Gedeh",
        "value": "LR-GG"
      },
      {
        "label": "Margibi",
        "value": "LR-MG"
      },
      {
        "label": "Montserrado",
        "value": "LR-MO"
      },
      {
        "label": "Nimba",
        "value": "LR-NI"
      },
      {
        "label": "River Cess",
        "value": "LR-RI"
      },
      {
        "label": "River Gee",
        "value": "LR-RG"
      },
      {
        "label": "Sinoe",
        "value": "LR-SI"
      }
    ],
    "value": "LR"
  },
  {
    "label": "Libya",
    "options": [
      {
        "label": "Al Butnan",
        "value": "LY-BU"
      },
      {
        "label": "Al Jabal al Akhdar",
        "value": "LY-JA"
      },
      {
        "label": "Al Jabal al Gharbi",
        "value": "LY-JG"
      },
      {
        "label": "Al Jafarah",
        "value": "LY-JI"
      },
      {
        "label": "Al Jufrah",
        "value": "LY-JU"
      },
      {
        "label": "Al Kufrah",
        "value": "LY-KF"
      },
      {
        "label": "Al Marj",
        "value": "LY-MJ"
      },
      {
        "label": "Al Marqab",
        "value": "LY-MB"
      },
      {
        "label": "Al Wahat",
        "value": "LY-WA"
      },
      {
        "label": "An Nuqat al Khams",
        "value": "LY-NQ"
      },
      {
        "label": "Az Zawiyah",
        "value": "LY-ZA"
      },
      {
        "label": "Banghazi",
        "value": "LY-BA"
      },
      {
        "label": "Darnah",
        "value": "LY-DR"
      },
      {
        "label": "Misratah",
        "value": "LY-MI"
      },
      {
        "label": "Murzuq",
        "value": "LY-MQ"
      },
      {
        "label": "Nalut",
        "value": "LY-NL"
      },
      {
        "label": "Sabha",
        "value": "LY-SB"
      },
      {
        "label": "Surt",
        "value": "LY-SR"
      },
      {
        "label": "Tarabulus",
        "value": "LY-TB"
      },
      {
        "label": "Wadi al Hayat",
        "value": "LY-WD"
      },
      {
        "label": "Wadi ash Shati'",
        "value": "LY-WS"
      }
    ],
    "value": "LY"
  },
  {
    "label": "Liechtenstein",
    "options": [
      {
        "label": "Balzers",
        "value": "LI-01"
      },
      {
        "label": "Eschen",
        "value": "LI-02"
      },
      {
        "label": "Gamprin",
        "value": "LI-03"
      },
      {
        "label": "Mauren",
        "value": "LI-04"
      },
      {
        "label": "Ruggell",
        "value": "LI-06"
      },
      {
        "label": "Schaan",
        "value": "LI-07"
      },
      {
        "label": "Triesen",
        "value": "LI-09"
      },
      {
        "label": "Triesenberg",
        "value": "LI-10"
      },
      {
        "label": "Vaduz",
        "value": "LI-11"
      }
    ],
    "value": "LI"
  },
  {
    "label": "Lithuania",
    "options": [
      {
        "label": "Alytaus apskritis",
        "value": "LT-AL"
      },
      {
        "label": "Kauno apskritis",
        "value": "LT-KU"
      },
      {
        "label": "Klaipedos apskritis",
        "value": "LT-KL"
      },
      {
        "label": "Marijampoles apskritis",
        "value": "LT-MR"
      },
      {
        "label": "Panevezio apskritis",
        "value": "LT-PN"
      },
      {
        "label": "Siauliu apskritis",
        "value": "LT-SA"
      },
      {
        "label": "Taurages apskritis",
        "value": "LT-TA"
      },
      {
        "label": "Telsiu apskritis",
        "value": "LT-TE"
      },
      {
        "label": "Utenos apskritis",
        "value": "LT-UT"
      },
      {
        "label": "Vilniaus apskritis",
        "value": "LT-VL"
      }
    ],
    "value": "LT"
  },
  {
    "label": "Luxembourg",
    "options": [
      {
        "label": "Capellen",
        "value": "LU-CA"
      },
      {
        "label": "Clervaux",
        "value": "LU-CL"
      },
      {
        "label": "Diekirch",
        "value": "LU-DI"
      },
      {
        "label": "Echternach",
        "value": "LU-EC"
      },
      {
        "label": "Esch-sur-Alzette",
        "value": "LU-ES"
      },
      {
        "label": "Grevenmacher",
        "value": "LU-GR"
      },
      {
        "label": "Luxembourg",
        "value": "LU-LU"
      },
      {
        "label": "Mersch",
        "value": "LU-ME"
      },
      {
        "label": "Redange",
        "value": "LU-RD"
      },
      {
        "label": "Remich",
        "value": "LU-RM"
      },
      {
        "label": "Vianden",
        "value": "LU-VD"
      },
      {
        "label": "Wiltz",
        "value": "LU-WI"
      }
    ],
    "value": "LU"
  },
  {
    "label": "Macao",
    "value": "MO"
  },
  {
    "label": "Madagascar",
    "options": [
      {
        "label": "Antananarivo",
        "value": "MG-T"
      },
      {
        "label": "Antsiranana",
        "value": "MG-D"
      },
      {
        "label": "Fianarantsoa",
        "value": "MG-F"
      },
      {
        "label": "Mahajanga",
        "value": "MG-M"
      },
      {
        "label": "Toamasina",
        "value": "MG-A"
      },
      {
        "label": "Toliara",
        "value": "MG-U"
      }
    ],
    "value": "MG"
  },
  {
    "label": "Malawi",
    "options": [
      {
        "label": "Balaka",
        "value": "MW-BA"
      },
      {
        "label": "Blantyre",
        "value": "MW-BL"
      },
      {
        "label": "Chikwawa",
        "value": "MW-CK"
      },
      {
        "label": "Chiradzulu",
        "value": "MW-CR"
      },
      {
        "label": "Dedza",
        "value": "MW-DE"
      },
      {
        "label": "Dowa",
        "value": "MW-DO"
      },
      {
        "label": "Karonga",
        "value": "MW-KR"
      },
      {
        "label": "Lilongwe",
        "value": "MW-LI"
      },
      {
        "label": "Machinga",
        "value": "MW-MH"
      },
      {
        "label": "Mangochi",
        "value": "MW-MG"
      },
      {
        "label": "Mwanza",
        "value": "MW-MW"
      },
      {
        "label": "Mzimba",
        "value": "MW-MZ"
      },
      {
        "label": "Neno",
        "value": "MW-NE"
      },
      {
        "label": "Nkhotakota",
        "value": "MW-NK"
      },
      {
        "label": "Ntchisi",
        "value": "MW-NI"
      },
      {
        "label": "Salima",
        "value": "MW-SA"
      },
      {
        "label": "Thyolo",
        "value": "MW-TH"
      },
      {
        "label": "Zomba",
        "value": "MW-ZO"
      }
    ],
    "value": "MW"
  },
  {
    "label": "Malaysia",
    "options": [
      {
        "label": "Johor",
        "value": "MY-01"
      },
      {
        "label": "Kedah",
        "value": "MY-02"
      },
      {
        "label": "Kelantan",
        "value": "MY-03"
      },
      {
        "label": "Melaka",
        "value": "MY-04"
      },
      {
        "label": "Negeri Sembilan",
        "value": "MY-05"
      },
      {
        "label": "Pahang",
        "value": "MY-06"
      },
      {
        "label": "Perak",
        "value": "MY-08"
      },
      {
        "label": "Perlis",
        "value": "MY-09"
      },
      {
        "label": "Pulau Pinang",
        "value": "MY-07"
      },
      {
        "label": "Sabah",
        "value": "MY-12"
      },
      {
        "label": "Sarawak",
        "value": "MY-13"
      },
      {
        "label": "Selangor",
        "value": "MY-10"
      },
      {
        "label": "Terengganu",
        "value": "MY-11"
      },
      {
        "label": "Wilayah Persekutuan Kuala Lumpur",
        "value": "MY-14"
      },
      {
        "label": "Wilayah Persekutuan Labuan",
        "value": "MY-15"
      },
      {
        "label": "Wilayah Persekutuan Putrajaya",
        "value": "MY-16"
      }
    ],
    "value": "MY"
  },
  {
    "label": "Maldives",
    "options": [
      {
        "label": "Addu City",
        "value": "MV-01"
      },
      {
        "label": "Faadhippolhu",
        "value": "MV-03"
      },
      {
        "label": "Felidhu Atoll",
        "value": "MV-04"
      },
      {
        "label": "Fuvammulah",
        "value": "MV-29"
      },
      {
        "label": "Hahdhunmathi",
        "value": "MV-05"
      },
      {
        "label": "Male",
        "value": "MV-MLE"
      },
      {
        "label": "Mulaku Atoll",
        "value": "MV-12"
      },
      {
        "label": "North Ari Atoll",
        "value": "MV-02"
      },
      {
        "label": "North Huvadhu Atoll",
        "value": "MV-27"
      },
      {
        "label": "North Maalhosmadulu",
        "value": "MV-13"
      },
      {
        "label": "North Miladhunmadulu",
        "value": "MV-24"
      },
      {
        "label": "North Nilandhe Atoll",
        "value": "MV-14"
      },
      {
        "label": "North Thiladhunmathi",
        "value": "MV-07"
      },
      {
        "label": "South Ari Atoll",
        "value": "MV-00"
      },
      {
        "label": "South Huvadhu Atoll",
        "value": "MV-28"
      },
      {
        "label": "South Maalhosmadulu",
        "value": "MV-20"
      },
      {
        "label": "South Miladhunmadulu",
        "value": "MV-25"
      },
      {
        "label": "South Nilandhe Atoll",
        "value": "MV-17"
      },
      {
        "label": "South Thiladhunmathi",
        "value": "MV-23"
      }
    ],
    "value": "MV"
  },
  {
    "label": "Mali",
    "options": [
      {
        "label": "Bamako",
        "value": "ML-BKO"
      },
      {
        "label": "Gao",
        "value": "ML-7"
      },
      {
        "label": "Kayes",
        "value": "ML-1"
      },
      {
        "label": "Kidal",
        "value": "ML-8"
      },
      {
        "label": "Koulikoro",
        "value": "ML-2"
      },
      {
        "label": "Mopti",
        "value": "ML-5"
      },
      {
        "label": "Segou",
        "value": "ML-4"
      },
      {
        "label": "Sikasso",
        "value": "ML-3"
      },
      {
        "label": "Tombouctou",
        "value": "ML-6"
      }
    ],
    "value": "ML"
  },
  {
    "label": "Malta",
    "options": [
      {
        "label": "Attard",
        "value": "MT-01"
      },
      {
        "label": "Balzan",
        "value": "MT-02"
      },
      {
        "label": "Birgu",
        "value": "MT-03"
      },
      {
        "label": "Birkirkara",
        "value": "MT-04"
      },
      {
        "label": "Birzebbuga",
        "value": "MT-05"
      },
      {
        "label": "Bormla",
        "value": "MT-06"
      },
      {
        "label": "Dingli",
        "value": "MT-07"
      },
      {
        "label": "Fgura",
        "value": "MT-08"
      },
      {
        "label": "Floriana",
        "value": "MT-09"
      },
      {
        "label": "Fontana",
        "value": "MT-10"
      },
      {
        "label": "Ghajnsielem",
        "value": "MT-13"
      },
      {
        "label": "Gharb",
        "value": "MT-14"
      },
      {
        "label": "Gharghur",
        "value": "MT-15"
      },
      {
        "label": "Ghasri",
        "value": "MT-16"
      },
      {
        "label": "Ghaxaq",
        "value": "MT-17"
      },
      {
        "label": "Gudja",
        "value": "MT-11"
      },
      {
        "label": "Gzira",
        "value": "MT-12"
      },
      {
        "label": "Hamrun",
        "value": "MT-18"
      },
      {
        "label": "Iklin",
        "value": "MT-19"
      },
      {
        "label": "Isla",
        "value": "MT-20"
      },
      {
        "label": "Kalkara",
        "value": "MT-21"
      },
      {
        "label": "Kirkop",
        "value": "MT-23"
      },
      {
        "label": "Lija",
        "value": "MT-24"
      },
      {
        "label": "Luqa",
        "value": "MT-25"
      },
      {
        "label": "Marsa",
        "value": "MT-26"
      },
      {
        "label": "Marsaskala",
        "value": "MT-27"
      },
      {
        "label": "Marsaxlokk",
        "value": "MT-28"
      },
      {
        "label": "Mdina",
        "value": "MT-29"
      },
      {
        "label": "Mellieha",
        "value": "MT-30"
      },
      {
        "label": "Mgarr",
        "value": "MT-31"
      },
      {
        "label": "Mosta",
        "value": "MT-32"
      },
      {
        "label": "Mqabba",
        "value": "MT-33"
      },
      {
        "label": "Msida",
        "value": "MT-34"
      },
      {
        "label": "Mtarfa",
        "value": "MT-35"
      },
      {
        "label": "Munxar",
        "value": "MT-36"
      },
      {
        "label": "Nadur",
        "value": "MT-37"
      },
      {
        "label": "Naxxar",
        "value": "MT-38"
      },
      {
        "label": "Paola",
        "value": "MT-39"
      },
      {
        "label": "Pembroke",
        "value": "MT-40"
      },
      {
        "label": "Pieta",
        "value": "MT-41"
      },
      {
        "label": "Qala",
        "value": "MT-42"
      },
      {
        "label": "Qormi",
        "value": "MT-43"
      },
      {
        "label": "Qrendi",
        "value": "MT-44"
      },
      {
        "label": "Rabat Gozo",
        "value": "MT-45"
      },
      {
        "label": "Rabat Malta",
        "value": "MT-46"
      },
      {
        "label": "Safi",
        "value": "MT-47"
      },
      {
        "label": "Saint John",
        "value": "MT-49"
      },
      {
        "label": "Saint Julian's",
        "value": "MT-48"
      },
      {
        "label": "Saint Lawrence",
        "value": "MT-50"
      },
      {
        "label": "Saint Lucia's",
        "value": "MT-53"
      },
      {
        "label": "Saint Paul's Bay",
        "value": "MT-51"
      },
      {
        "label": "Sannat",
        "value": "MT-52"
      },
      {
        "label": "Santa Venera",
        "value": "MT-54"
      },
      {
        "label": "Siggiewi",
        "value": "MT-55"
      },
      {
        "label": "Sliema",
        "value": "MT-56"
      },
      {
        "label": "Swieqi",
        "value": "MT-57"
      },
      {
        "label": "Ta' Xbiex",
        "value": "MT-58"
      },
      {
        "label": "Tarxien",
        "value": "MT-59"
      },
      {
        "label": "Valletta",
        "value": "MT-60"
      },
      {
        "label": "Xaghra",
        "value": "MT-61"
      },
      {
        "label": "Xewkija",
        "value": "MT-62"
      },
      {
        "label": "Xghajra",
        "value": "MT-63"
      },
      {
        "label": "Zabbar",
        "value": "MT-64"
      },
      {
        "label": "Zebbug Gozo",
        "value": "MT-65"
      },
      {
        "label": "Zejtun",
        "value": "MT-67"
      },
      {
        "label": "Zurrieq",
        "value": "MT-68"
      }
    ],
    "value": "MT"
  },
  {
    "label": "Marshall Islands",
    "options": [
      {
        "label": "Kwajalein",
        "value": "MH-KWA"
      },
      {
        "label": "Majuro",
        "value": "MH-MAJ"
      }
    ],
    "value": "MH"
  },
  {
    "label": "Martinique",
    "value": "MQ"
  },
  {
    "label": "Mauritania",
    "options": [
      {
        "label": "Adrar",
        "value": "MR-07"
      },
      {
        "label": "Assaba",
        "value": "MR-03"
      },
      {
        "label": "Brakna",
        "value": "MR-05"
      },
      {
        "label": "Dakhlet Nouadhibou",
        "value": "MR-08"
      },
      {
        "label": "Gorgol",
        "value": "MR-04"
      },
      {
        "label": "Guidimaka",
        "value": "MR-10"
      },
      {
        "label": "Hodh el Gharbi",
        "value": "MR-02"
      },
      {
        "label": "Inchiri",
        "value": "MR-12"
      },
      {
        "label": "Nouakchott Ouest",
        "value": "MR-13"
      },
      {
        "label": "Tagant",
        "value": "MR-09"
      },
      {
        "label": "Tiris Zemmour",
        "value": "MR-11"
      },
      {
        "label": "Trarza",
        "value": "MR-06"
      }
    ],
    "value": "MR"
  },
  {
    "label": "Mauritius",
    "options": [
      {
        "label": "Black River",
        "value": "MU-BL"
      },
      {
        "label": "Flacq",
        "value": "MU-FL"
      },
      {
        "label": "Grand Port",
        "value": "MU-GP"
      },
      {
        "label": "Moka",
        "value": "MU-MO"
      },
      {
        "label": "Pamplemousses",
        "value": "MU-PA"
      },
      {
        "label": "Plaines Wilhems",
        "value": "MU-PW"
      },
      {
        "label": "Port Louis",
        "value": "MU-PL"
      },
      {
        "label": "Riviere du Rempart",
        "value": "MU-RR"
      },
      {
        "label": "Rodrigues Islands",
        "value": "MU-RO"
      },
      {
        "label": "Savanne",
        "value": "MU-SA"
      }
    ],
    "value": "MU"
  },
  {
    "label": "Mayotte",
    "value": "YT"
  },
  {
    "label": "Mexico",
    "options": [
      {
        "label": "Aguascalientes",
        "value": "MX-AGU"
      },
      {
        "label": "Baja California",
        "value": "MX-BCN"
      },
      {
        "label": "Baja California Sur",
        "value": "MX-BCS"
      },
      {
        "label": "Campeche",
        "value": "MX-CAM"
      },
      {
        "label": "Chiapas",
        "value": "MX-CHP"
      },
      {
        "label": "Chihuahua",
        "value": "MX-CHH"
      },
      {
        "label": "Ciudad de Mexico",
        "value": "MX-CMX"
      },
      {
        "label": "Coahuila de Zaragoza",
        "value": "MX-COA"
      },
      {
        "label": "Colima",
        "value": "MX-COL"
      },
      {
        "label": "Durango",
        "value": "MX-DUR"
      },
      {
        "label": "Guanajuato",
        "value": "MX-GUA"
      },
      {
        "label": "Guerrero",
        "value": "MX-GRO"
      },
      {
        "label": "Hidalgo",
        "value": "MX-HID"
      },
      {
        "label": "Jalisco",
        "value": "MX-JAL"
      },
      {
        "label": "Mexico",
        "value": "MX-MEX"
      },
      {
        "label": "Michoacan de Ocampo",
        "value": "MX-MIC"
      },
      {
        "label": "Morelos",
        "value": "MX-MOR"
      },
      {
        "label": "Nayarit",
        "value": "MX-NAY"
      },
      {
        "label": "Nuevo Leon",
        "value": "MX-NLE"
      },
      {
        "label": "Oaxaca",
        "value": "MX-OAX"
      },
      {
        "label": "Puebla",
        "value": "MX-PUE"
      },
      {
        "label": "Queretaro",
        "value": "MX-QUE"
      },
      {
        "label": "Quintana Roo",
        "value": "MX-ROO"
      },
      {
        "label": "San Luis Potosi",
        "value": "MX-SLP"
      },
      {
        "label": "Sinaloa",
        "value": "MX-SIN"
      },
      {
        "label": "Sonora",
        "value": "MX-SON"
      },
      {
        "label": "Tabasco",
        "value": "MX-TAB"
      },
      {
        "label": "Tamaulipas",
        "value": "MX-TAM"
      },
      {
        "label": "Tlaxcala",
        "value": "MX-TLA"
      },
      {
        "label": "Veracruz de Ignacio de la Llave",
        "value": "MX-VER"
      },
      {
        "label": "Yucatan",
        "value": "MX-YUC"
      },
      {
        "label": "Zacatecas",
        "value": "MX-ZAC"
      }
    ],
    "value": "MX"
  },
  {
    "label": "Micronesia, Federated States of",
    "options": [
      {
        "label": "Chuuk",
        "value": "FM-TRK"
      },
      {
        "label": "Kosrae",
        "value": "FM-KSA"
      },
      {
        "label": "Pohnpei",
        "value": "FM-PNI"
      },
      {
        "label": "Yap",
        "value": "FM-YAP"
      }
    ],
    "value": "FM"
  },
  {
    "label": "Moldova, Republic of",
    "options": [
      {
        "label": "Anenii Noi",
        "value": "MD-AN"
      },
      {
        "label": "Balti",
        "value": "MD-BA"
      },
      {
        "label": "Basarabeasca",
        "value": "MD-BS"
      },
      {
        "label": "Bender",
        "value": "MD-BD"
      },
      {
        "label": "Briceni",
        "value": "MD-BR"
      },
      {
        "label": "Cahul",
        "value": "MD-CA"
      },
      {
        "label": "Calarasi",
        "value": "MD-CL"
      },
      {
        "label": "Cantemir",
        "value": "MD-CT"
      },
      {
        "label": "Causeni",
        "value": "MD-CS"
      },
      {
        "label": "Chisinau",
        "value": "MD-CU"
      },
      {
        "label": "Cimislia",
        "value": "MD-CM"
      },
      {
        "label": "Criuleni",
        "value": "MD-CR"
      },
      {
        "label": "Donduseni",
        "value": "MD-DO"
      },
      {
        "label": "Drochia",
        "value": "MD-DR"
      },
      {
        "label": "Dubasari",
        "value": "MD-DU"
      },
      {
        "label": "Edinet",
        "value": "MD-ED"
      },
      {
        "label": "Falesti",
        "value": "MD-FA"
      },
      {
        "label": "Floresti",
        "value": "MD-FL"
      },
      {
        "label": "Gagauzia, Unitatea teritoriala autonoma",
        "value": "MD-GA"
      },
      {
        "label": "Glodeni",
        "value": "MD-GL"
      },
      {
        "label": "Hincesti",
        "value": "MD-HI"
      },
      {
        "label": "Ialoveni",
        "value": "MD-IA"
      },
      {
        "label": "Leova",
        "value": "MD-LE"
      },
      {
        "label": "Nisporeni",
        "value": "MD-NI"
      },
      {
        "label": "Ocnita",
        "value": "MD-OC"
      },
      {
        "label": "Orhei",
        "value": "MD-OR"
      },
      {
        "label": "Rezina",
        "value": "MD-RE"
      },
      {
        "label": "Riscani",
        "value": "MD-RI"
      },
      {
        "label": "Singerei",
        "value": "MD-SI"
      },
      {
        "label": "Soldanesti",
        "value": "MD-SD"
      },
      {
        "label": "Soroca",
        "value": "MD-SO"
      },
      {
        "label": "Stefan Voda",
        "value": "MD-SV"
      },
      {
        "label": "Stinga Nistrului, unitatea teritoriala din",
        "value": "MD-SN"
      },
      {
        "label": "Straseni",
        "value": "MD-ST"
      },
      {
        "label": "Taraclia",
        "value": "MD-TA"
      },
      {
        "label": "Telenesti",
        "value": "MD-TE"
      },
      {
        "label": "Ungheni",
        "value": "MD-UN"
      }
    ],
    "value": "MD"
  },
  {
    "label": "Monaco",
    "options": [
      {
        "label": "Fontvieille",
        "value": "MC-FO"
      },
      {
        "label": "La Condamine",
        "value": "MC-CO"
      },
      {
        "label": "Monaco-Ville",
        "value": "MC-MO"
      },
      {
        "label": "Moneghetti",
        "value": "MC-MG"
      },
      {
        "label": "Monte-Carlo",
        "value": "MC-MC"
      },
      {
        "label": "Saint-Roman",
        "value": "MC-SR"
      }
    ],
    "value": "MC"
  },
  {
    "label": "Mongolia",
    "options": [
      {
        "label": "Bayan-Olgiy",
        "value": "MN-071"
      },
      {
        "label": "Bayanhongor",
        "value": "MN-069"
      },
      {
        "label": "Darhan uul",
        "value": "MN-037"
      },
      {
        "label": "Dornod",
        "value": "MN-061"
      },
      {
        "label": "Dundgovi",
        "value": "MN-059"
      },
      {
        "label": "Dzavhan",
        "value": "MN-057"
      },
      {
        "label": "Govi-Altay",
        "value": "MN-065"
      },
      {
        "label": "Hovd",
        "value": "MN-043"
      },
      {
        "label": "Hovsgol",
        "value": "MN-041"
      },
      {
        "label": "Omnogovi",
        "value": "MN-053"
      },
      {
        "label": "Orhon",
        "value": "MN-035"
      },
      {
        "label": "Ovorhangay",
        "value": "MN-055"
      },
      {
        "label": "Selenge",
        "value": "MN-049"
      },
      {
        "label": "Tov",
        "value": "MN-047"
      },
      {
        "label": "Ulaanbaatar",
        "value": "MN-1"
      }
    ],
    "value": "MN"
  },
  {
    "label": "Montenegro",
    "options": [
      {
        "label": "Bar",
        "value": "ME-02"
      },
      {
        "label": "Berane",
        "value": "ME-03"
      },
      {
        "label": "Bijelo Polje",
        "value": "ME-04"
      },
      {
        "label": "Budva",
        "value": "ME-05"
      },
      {
        "label": "Cetinje",
        "value": "ME-06"
      },
      {
        "label": "Danilovgrad",
        "value": "ME-07"
      },
      {
        "label": "Herceg-Novi",
        "value": "ME-08"
      },
      {
        "label": "Kolasin",
        "value": "ME-09"
      },
      {
        "label": "Kotor",
        "value": "ME-10"
      },
      {
        "label": "Niksic",
        "value": "ME-12"
      },
      {
        "label": "Plav",
        "value": "ME-13"
      },
      {
        "label": "Pljevlja",
        "value": "ME-14"
      },
      {
        "label": "Pluzine",
        "value": "ME-15"
      },
      {
        "label": "Podgorica",
        "value": "ME-16"
      },
      {
        "label": "Rozaje",
        "value": "ME-17"
      },
      {
        "label": "Tivat",
        "value": "ME-19"
      },
      {
        "label": "Tuzi",
        "value": "ME-24"
      },
      {
        "label": "Ulcinj",
        "value": "ME-20"
      },
      {
        "label": "Zabljak",
        "value": "ME-21"
      },
      {
        "label": "Zeta",
        "value": "ME-25"
      }
    ],
    "value": "ME"
  },
  {
    "label": "Montserrat",
    "value": "MS"
  },
  {
    "label": "Morocco",
    "options": [
      {
        "label": "Beni-Mellal-Khenifra",
        "value": "MA-05"
      },
      {
        "label": "Casablanca-Settat",
        "value": "MA-06"
      },
      {
        "label": "Draa-Tafilalet",
        "value": "MA-08"
      },
      {
        "label": "Fes- Meknes",
        "value": "MA-03"
      },
      {
        "label": "Guelmim-Oued Noun (EH-partial)",
        "value": "MA-10"
      },
      {
        "label": "L'Oriental",
        "value": "MA-02"
      },
      {
        "label": "Laayoune-Sakia El Hamra (EH-partial)",
        "value": "MA-11"
      },
      {
        "label": "Marrakech-Safi",
        "value": "MA-07"
      },
      {
        "label": "Rabat-Sale-Kenitra",
        "value": "MA-04"
      },
      {
        "label": "Souss-Massa",
        "value": "MA-09"
      },
      {
        "label": "Tanger-Tetouan-Al Hoceima",
        "value": "MA-01"
      }
    ],
    "value": "MA"
  },
  {
    "label": "Mozambique",
    "options": [
      {
        "label": "Cabo Delgado",
        "value": "MZ-P"
      },
      {
        "label": "Gaza",
        "value": "MZ-G"
      },
      {
        "label": "Inhambane",
        "value": "MZ-I"
      },
      {
        "label": "Manica",
        "value": "MZ-B"
      },
      {
        "label": "Maputo",
        "value": "MZ-L"
      },
      {
        "label": "Nampula",
        "value": "MZ-N"
      },
      {
        "label": "Niassa",
        "value": "MZ-A"
      },
      {
        "label": "Sofala",
        "value": "MZ-S"
      },
      {
        "label": "Tete",
        "value": "MZ-T"
      },
      {
        "label": "Zambezia",
        "value": "MZ-Q"
      }
    ],
    "value": "MZ"
  },
  {
    "label": "Myanmar",
    "options": [
      {
        "label": "Ayeyarwady",
        "value": "MM-07"
      },
      {
        "label": "Bago",
        "value": "MM-02"
      },
      {
        "label": "Chin",
        "value": "MM-14"
      },
      {
        "label": "Kachin",
        "value": "MM-11"
      },
      {
        "label": "Kayah",
        "value": "MM-12"
      },
      {
        "label": "Kayin",
        "value": "MM-13"
      },
      {
        "label": "Magway",
        "value": "MM-03"
      },
      {
        "label": "Mandalay",
        "value": "MM-04"
      },
      {
        "label": "Mon",
        "value": "MM-15"
      },
      {
        "label": "Nay Pyi Taw",
        "value": "MM-18"
      },
      {
        "label": "Rakhine",
        "value": "MM-16"
      },
      {
        "label": "Sagaing",
        "value": "MM-01"
      },
      {
        "label": "Shan",
        "value": "MM-17"
      },
      {
        "label": "Tanintharyi",
        "value": "MM-05"
      },
      {
        "label": "Yangon",
        "value": "MM-06"
      }
    ],
    "value": "MM"
  },
  {
    "label": "Namibia",
    "options": [
      {
        "label": "Erongo",
        "value": "NA-ER"
      },
      {
        "label": "Hardap",
        "value": "NA-HA"
      },
      {
        "label": "Karas",
        "value": "NA-KA"
      },
      {
        "label": "Kavango East",
        "value": "NA-KE"
      },
      {
        "label": "Kavango West",
        "value": "NA-KW"
      },
      {
        "label": "Khomas",
        "value": "NA-KH"
      },
      {
        "label": "Kunene",
        "value": "NA-KU"
      },
      {
        "label": "Ohangwena",
        "value": "NA-OW"
      },
      {
        "label": "Omaheke",
        "value": "NA-OH"
      },
      {
        "label": "Omusati",
        "value": "NA-OS"
      },
      {
        "label": "Oshana",
        "value": "NA-ON"
      },
      {
        "label": "Oshikoto",
        "value": "NA-OT"
      },
      {
        "label": "Otjozondjupa",
        "value": "NA-OD"
      },
      {
        "label": "Zambezi",
        "value": "NA-CA"
      }
    ],
    "value": "NA"
  },
  {
    "label": "Nauru",
    "options": [
      {
        "label": "Aiwo",
        "value": "NR-01"
      },
      {
        "label": "Anetan",
        "value": "NR-03"
      },
      {
        "label": "Yaren",
        "value": "NR-14"
      }
    ],
    "value": "NR"
  },
  {
    "label": "Nepal",
    "options": [
      {
        "label": "Bagmati",
        "value": "NP-P3"
      },
      {
        "label": "Gandaki",
        "value": "NP-P4"
      },
      {
        "label": "Karnali",
        "value": "NP-P6"
      },
      {
        "label": "Koshi",
        "value": "NP-P1"
      },
      {
        "label": "Lumbini",
        "value": "NP-P5"
      },
      {
        "label": "Madhesh",
        "value": "NP-P2"
      },
      {
        "label": "Sudurpashchim",
        "value": "NP-P7"
      }
    ],
    "value": "NP"
  },
  {
    "label": "Netherlands, Kingdom of the",
    "options": [
      {
        "label": "Drenthe",
        "value": "NL-DR"
      },
      {
        "label": "Flevoland",
        "value": "NL-FL"
      },
      {
        "label": "Fryslan",
        "value": "NL-FR"
      },
      {
        "label": "Gelderland",
        "value": "NL-GE"
      },
      {
        "label": "Groningen",
        "value": "NL-GR"
      },
      {
        "label": "Limburg",
        "value": "NL-LI"
      },
      {
        "label": "Noord-Brabant",
        "value": "NL-NB"
      },
      {
        "label": "Noord-Holland",
        "value": "NL-NH"
      },
      {
        "label": "Overijssel",
        "value": "NL-OV"
      },
      {
        "label": "Utrecht",
        "value": "NL-UT"
      },
      {
        "label": "Zeeland",
        "value": "NL-ZE"
      },
      {
        "label": "Zuid-Holland",
        "value": "NL-ZH"
      }
    ],
    "value": "NL"
  },
  {
    "label": "New Caledonia",
    "value": "NC"
  },
  {
    "label": "New Zealand",
    "options": [
      {
        "label": "Auckland",
        "value": "NZ-AUK"
      },
      {
        "label": "Bay of Plenty",
        "value": "NZ-BOP"
      },
      {
        "label": "Canterbury",
        "value": "NZ-CAN"
      },
      {
        "label": "Chatham Islands Territory",
        "value": "NZ-CIT"
      },
      {
        "label": "Gisborne",
        "value": "NZ-GIS"
      },
      {
        "label": "Greater Wellington",
        "value": "NZ-WGN"
      },
      {
        "label": "Hawke's Bay",
        "value": "NZ-HKB"
      },
      {
        "label": "Manawatu-Whanganui",
        "value": "NZ-MWT"
      },
      {
        "label": "Marlborough",
        "value": "NZ-MBH"
      },
      {
        "label": "Nelson",
        "value": "NZ-NSN"
      },
      {
        "label": "Northland",
        "value": "NZ-NTL"
      },
      {
        "label": "Otago",
        "value": "NZ-OTA"
      },
      {
        "label": "Southland",
        "value": "NZ-STL"
      },
      {
        "label": "Taranaki",
        "value": "NZ-TKI"
      },
      {
        "label": "Tasman",
        "value": "NZ-TAS"
      },
      {
        "label": "Waikato",
        "value": "NZ-WKO"
      },
      {
        "label": "West Coast",
        "value": "NZ-WTC"
      }
    ],
    "value": "NZ"
  },
  {
    "label": "Nicaragua",
    "options": [
      {
        "label": "Boaco",
        "value": "NI-BO"
      },
      {
        "label": "Carazo",
        "value": "NI-CA"
      },
      {
        "label": "Chinandega",
        "value": "NI-CI"
      },
      {
        "label": "Chontales",
        "value": "NI-CO"
      },
      {
        "label": "Costa Caribe Norte",
        "value": "NI-AN"
      },
      {
        "label": "Costa Caribe Sur",
        "value": "NI-AS"
      },
      {
        "label": "Esteli",
        "value": "NI-ES"
      },
      {
        "label": "Granada",
        "value": "NI-GR"
      },
      {
        "label": "Jinotega",
        "value": "NI-JI"
      },
      {
        "label": "Leon",
        "value": "NI-LE"
      },
      {
        "label": "Madriz",
        "value": "NI-MD"
      },
      {
        "label": "Managua",
        "value": "NI-MN"
      },
      {
        "label": "Masaya",
        "value": "NI-MS"
      },
      {
        "label": "Matagalpa",
        "value": "NI-MT"
      },
      {
        "label": "Nueva Segovia",
        "value": "NI-NS"
      },
      {
        "label": "Rio San Juan",
        "value": "NI-SJ"
      },
      {
        "label": "Rivas",
        "value": "NI-RI"
      }
    ],
    "value": "NI"
  },
  {
    "label": "Niger",
    "options": [
      {
        "label": "Agadez",
        "value": "NE-1"
      },
      {
        "label": "Diffa",
        "value": "NE-2"
      },
      {
        "label": "Dosso",
        "value": "NE-3"
      },
      {
        "label": "Maradi",
        "value": "NE-4"
      },
      {
        "label": "Niamey",
        "value": "NE-8"
      },
      {
        "label": "Tahoua",
        "value": "NE-5"
      },
      {
        "label": "Tillaberi",
        "value": "NE-6"
      },
      {
        "label": "Zinder",
        "value": "NE-7"
      }
    ],
    "value": "NE"
  },
  {
    "label": "Nigeria",
    "options": [
      {
        "label": "Abia",
        "value": "NG-AB"
      },
      {
        "label": "Abuja Federal Capital Territory",
        "value": "NG-FC"
      },
      {
        "label": "Adamawa",
        "value": "NG-AD"
      },
      {
        "label": "Akwa Ibom",
        "value": "NG-AK"
      },
      {
        "label": "Anambra",
        "value": "NG-AN"
      },
      {
        "label": "Bauchi",
        "value": "NG-BA"
      },
      {
        "label": "Bayelsa",
        "value": "NG-BY"
      },
      {
        "label": "Benue",
        "value": "NG-BE"
      },
      {
        "label": "Borno",
        "value": "NG-BO"
      },
      {
        "label": "Cross River",
        "value": "NG-CR"
      },
      {
        "label": "Delta",
        "value": "NG-DE"
      },
      {
        "label": "Edo",
        "value": "NG-ED"
      },
      {
        "label": "Ekiti",
        "value": "NG-EK"
      },
      {
        "label": "Enugu",
        "value": "NG-EN"
      },
      {
        "label": "Gombe",
        "value": "NG-GO"
      },
      {
        "label": "Imo",
        "value": "NG-IM"
      },
      {
        "label": "Jigawa",
        "value": "NG-JI"
      },
      {
        "label": "Kaduna",
        "value": "NG-KD"
      },
      {
        "label": "Kano",
        "value": "NG-KN"
      },
      {
        "label": "Katsina",
        "value": "NG-KT"
      },
      {
        "label": "Kebbi",
        "value": "NG-KE"
      },
      {
        "label": "Kogi",
        "value": "NG-KO"
      },
      {
        "label": "Kwara",
        "value": "NG-KW"
      },
      {
        "label": "Lagos",
        "value": "NG-LA"
      },
      {
        "label": "Nasarawa",
        "value": "NG-NA"
      },
      {
        "label": "Niger",
        "value": "NG-NI"
      },
      {
        "label": "Ogun",
        "value": "NG-OG"
      },
      {
        "label": "Ondo",
        "value": "NG-ON"
      },
      {
        "label": "Osun",
        "value": "NG-OS"
      },
      {
        "label": "Oyo",
        "value": "NG-OY"
      },
      {
        "label": "Plateau",
        "value": "NG-PL"
      },
      {
        "label": "Rivers",
        "value": "NG-RI"
      },
      {
        "label": "Sokoto",
        "value": "NG-SO"
      },
      {
        "label": "Taraba",
        "value": "NG-TA"
      },
      {
        "label": "Yobe",
        "value": "NG-YO"
      },
      {
        "label": "Zamfara",
        "value": "NG-ZA"
      }
    ],
    "value": "NG"
  },
  {
    "label": "Niue",
    "value": "NU"
  },
  {
    "label": "Norfolk Island",
    "value": "NF"
  },
  {
    "label": "North Macedonia",
    "options": [
      {
        "label": "Berovo",
        "value": "MK-201"
      },
      {
        "label": "Bitola",
        "value": "MK-501"
      },
      {
        "label": "Bogdanci",
        "value": "MK-401"
      },
      {
        "label": "Bogovinje",
        "value": "MK-601"
      },
      {
        "label": "Bosilovo",
        "value": "MK-402"
      },
      {
        "label": "Brvenica",
        "value": "MK-602"
      },
      {
        "label": "Butel",
        "value": "MK-803"
      },
      {
        "label": "Caska",
        "value": "MK-109"
      },
      {
        "label": "Centar",
        "value": "MK-814"
      },
      {
        "label": "Centar Zupa",
        "value": "MK-313"
      },
      {
        "label": "Cucer Sandevo",
        "value": "MK-816"
      },
      {
        "label": "Debar",
        "value": "MK-303"
      },
      {
        "label": "Delcevo",
        "value": "MK-203"
      },
      {
        "label": "Demir Hisar",
        "value": "MK-502"
      },
      {
        "label": "Dojran",
        "value": "MK-406"
      },
      {
        "label": "Dolneni",
        "value": "MK-503"
      },
      {
        "label": "Gazi Baba",
        "value": "MK-804"
      },
      {
        "label": "Gevgelija",
        "value": "MK-405"
      },
      {
        "label": "Gostivar",
        "value": "MK-604"
      },
      {
        "label": "Ilinden",
        "value": "MK-807"
      },
      {
        "label": "Jegunovce",
        "value": "MK-606"
      },
      {
        "label": "Karbinci",
        "value": "MK-205"
      },
      {
        "label": "Kavadarci",
        "value": "MK-104"
      },
      {
        "label": "Kicevo",
        "value": "MK-307"
      },
      {
        "label": "Kisela Voda",
        "value": "MK-809"
      },
      {
        "label": "Kocani",
        "value": "MK-206"
      },
      {
        "label": "Konce",
        "value": "MK-407"
      },
      {
        "label": "Kratovo",
        "value": "MK-701"
      },
      {
        "label": "Kriva Palanka",
        "value": "MK-702"
      },
      {
        "label": "Krivogastani",
        "value": "MK-504"
      },
      {
        "label": "Kumanovo",
        "value": "MK-703"
      },
      {
        "label": "Lipkovo",
        "value": "MK-704"
      },
      {
        "label": "Lozovo",
        "value": "MK-105"
      },
      {
        "label": "Makedonska Kamenica",
        "value": "MK-207"
      },
      {
        "label": "Makedonski Brod",
        "value": "MK-308"
      },
      {
        "label": "Mavrovo i Rostusa",
        "value": "MK-607"
      },
      {
        "label": "Mogila",
        "value": "MK-506"
      },
      {
        "label": "Negotino",
        "value": "MK-106"
      },
      {
        "label": "Novaci",
        "value": "MK-507"
      },
      {
        "label": "Novo Selo",
        "value": "MK-408"
      },
      {
        "label": "Ohrid",
        "value": "MK-310"
      },
      {
        "label": "Petrovec",
        "value": "MK-810"
      },
      {
        "label": "Plasnica",
        "value": "MK-311"
      },
      {
        "label": "Prilep",
        "value": "MK-508"
      },
      {
        "label": "Probistip",
        "value": "MK-209"
      },
      {
        "label": "Radovis",
        "value": "MK-409"
      },
      {
        "label": "Rankovce",
        "value": "MK-705"
      },
      {
        "label": "Resen",
        "value": "MK-509"
      },
      {
        "label": "Rosoman",
        "value": "MK-107"
      },
      {
        "label": "Saraj",
        "value": "MK-811"
      },
      {
        "label": "Sopiste",
        "value": "MK-812"
      },
      {
        "label": "Stip",
        "value": "MK-211"
      },
      {
        "label": "Struga",
        "value": "MK-312"
      },
      {
        "label": "Strumica",
        "value": "MK-410"
      },
      {
        "label": "Studenicani",
        "value": "MK-813"
      },
      {
        "label": "Sveti Nikole",
        "value": "MK-108"
      },
      {
        "label": "Tearce",
        "value": "MK-608"
      },
      {
        "label": "Tetovo",
        "value": "MK-609"
      },
      {
        "label": "Valandovo",
        "value": "MK-403"
      },
      {
        "label": "Vasilevo",
        "value": "MK-404"
      },
      {
        "label": "Veles",
        "value": "MK-101"
      },
      {
        "label": "Vevcani",
        "value": "MK-301"
      },
      {
        "label": "Vinica",
        "value": "MK-202"
      },
      {
        "label": "Vrapciste",
        "value": "MK-603"
      },
      {
        "label": "Zelenikovo",
        "value": "MK-806"
      },
      {
        "label": "Zelino",
        "value": "MK-605"
      },
      {
        "label": "Zrnovci",
        "value": "MK-204"
      }
    ],
    "value": "MK"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "MP"
  },
  {
    "label": "Norway",
    "options": [
      {
        "label": "Agder",
        "value": "NO-42"
      },
      {
        "label": "Innlandet",
        "value": "NO-34"
      },
      {
        "label": "More og Romsdal",
        "value": "NO-15"
      },
      {
        "label": "Nordland",
        "value": "NO-18"
      },
      {
        "label": "Oslo",
        "value": "NO-03"
      },
      {
        "label": "Rogaland",
        "value": "NO-11"
      },
      {
        "label": "Troms og Finnmark",
        "value": "NO-54"
      },
      {
        "label": "Trondelag",
        "value": "NO-50"
      },
      {
        "label": "Vestfold og Telemark",
        "value": "NO-38"
      },
      {
        "label": "Vestland",
        "value": "NO-46"
      },
      {
        "label": "Viken",
        "value": "NO-30"
      }
    ],
    "value": "NO"
  },
  {
    "label": "Oman",
    "options": [
      {
        "label": "Ad Dakhiliyah",
        "value": "OM-DA"
      },
      {
        "label": "Al Buraymi",
        "value": "OM-BU"
      },
      {
        "label": "Al Wusta",
        "value": "OM-WU"
      },
      {
        "label": "Az Zahirah",
        "value": "OM-ZA"
      },
      {
        "label": "Janub al Batinah",
        "value": "OM-BJ"
      },
      {
        "label": "Janub ash Sharqiyah",
        "value": "OM-SJ"
      },
      {
        "label": "Masqat",
        "value": "OM-MA"
      },
      {
        "label": "Musandam",
        "value": "OM-MU"
      },
      {
        "label": "Shamal al Batinah",
        "value": "OM-BS"
      },
      {
        "label": "Shamal ash Sharqiyah",
        "value": "OM-SS"
      },
      {
        "label": "Zufar",
        "value": "OM-ZU"
      }
    ],
    "value": "OM"
  },
  {
    "label": "Pakistan",
    "options": [
      {
        "label": "Azad Jammu and Kashmir",
        "value": "PK-JK"
      },
      {
        "label": "Balochistan",
        "value": "PK-BA"
      },
      {
        "label": "Gilgit-Baltistan",
        "value": "PK-GB"
      },
      {
        "label": "Islamabad",
        "value": "PK-IS"
      },
      {
        "label": "Khyber Pakhtunkhwa",
        "value": "PK-KP"
      },
      {
        "label": "Punjab",
        "value": "PK-PB"
      },
      {
        "label": "Sindh",
        "value": "PK-SD"
      }
    ],
    "value": "PK"
  },
  {
    "label": "Palau",
    "options": [
      {
        "label": "Airai",
        "value": "PW-004"
      },
      {
        "label": "Koror",
        "value": "PW-150"
      },
      {
        "label": "Melekeok",
        "value": "PW-212"
      },
      {
        "label": "Ngaraard",
        "value": "PW-214"
      },
      {
        "label": "Ngardmau",
        "value": "PW-222"
      },
      {
        "label": "Ngatpang",
        "value": "PW-224"
      },
      {
        "label": "Sonsorol",
        "value": "PW-370"
      }
    ],
    "value": "PW"
  },
  {
    "label": "Palestine, State of",
    "options": [
      {
        "label": "Bethlehem",
        "value": "PS-BTH"
      },
      {
        "label": "Deir El Balah",
        "value": "PS-DEB"
      },
      {
        "label": "Gaza",
        "value": "PS-GZA"
      },
      {
        "label": "Hebron",
        "value": "PS-HBN"
      },
      {
        "label": "Jenin",
        "value": "PS-JEN"
      },
      {
        "label": "Jericho and Al Aghwar",
        "value": "PS-JRH"
      },
      {
        "label": "Jerusalem",
        "value": "PS-JEM"
      },
      {
        "label": "Khan Yunis",
        "value": "PS-KYS"
      },
      {
        "label": "Nablus",
        "value": "PS-NBS"
      },
      {
        "label": "Qalqilya",
        "value": "PS-QQA"
      },
      {
        "label": "Rafah",
        "value": "PS-RFH"
      },
      {
        "label": "Ramallah",
        "value": "PS-RBH"
      },
      {
        "label": "Salfit",
        "value": "PS-SLT"
      },
      {
        "label": "Tubas",
        "value": "PS-TBS"
      },
      {
        "label": "Tulkarm",
        "value": "PS-TKM"
      }
    ],
    "value": "PS"
  },
  {
    "label": "Panama",
    "options": [
      {
        "label": "Bocas del Toro",
        "value": "PA-1"
      },
      {
        "label": "Chiriqui",
        "value": "PA-4"
      },
      {
        "label": "Cocle",
        "value": "PA-2"
      },
      {
        "label": "Colon",
        "value": "PA-3"
      },
      {
        "label": "Darien",
        "value": "PA-5"
      },
      {
        "label": "Herrera",
        "value": "PA-6"
      },
      {
        "label": "Los Santos",
        "value": "PA-7"
      },
      {
        "label": "Ngobe-Bugle",
        "value": "PA-NB"
      },
      {
        "label": "Panama",
        "value": "PA-8"
      },
      {
        "label": "Veraguas",
        "value": "PA-9"
      }
    ],
    "value": "PA"
  },
  {
    "label": "Papua New Guinea",
    "options": [
      {
        "label": "Bougainville",
        "value": "PG-NSB"
      },
      {
        "label": "Central",
        "value": "PG-CPM"
      },
      {
        "label": "East New Britain",
        "value": "PG-EBR"
      },
      {
        "label": "Eastern Highlands",
        "value": "PG-EHG"
      },
      {
        "label": "Enga",
        "value": "PG-EPW"
      },
      {
        "label": "Madang",
        "value": "PG-MPM"
      },
      {
        "label": "Manus",
        "value": "PG-MRL"
      },
      {
        "label": "Milne Bay",
        "value": "PG-MBA"
      },
      {
        "label": "Morobe",
        "value": "PG-MPL"
      },
      {
        "label": "National Capital District (Port Moresby)",
        "value": "PG-NCD"
      },
      {
        "label": "New Ireland",
        "value": "PG-NIK"
      },
      {
        "label": "Northern",
        "value": "PG-NPP"
      },
      {
        "label": "Southern Highlands",
        "value": "PG-SHM"
      },
      {
        "label": "West New Britain",
        "value": "PG-WBK"
      },
      {
        "label": "West Sepik",
        "value": "PG-SAN"
      },
      {
        "label": "Western",
        "value": "PG-WPD"
      },
      {
        "label": "Western Highlands",
        "value": "PG-WHM"
      }
    ],
    "value": "PG"
  },
  {
    "label": "Paraguay",
    "options": [
      {
        "label": "Alto Parana",
        "value": "PY-10"
      },
      {
        "label": "Amambay",
        "value": "PY-13"
      },
      {
        "label": "Asuncion",
        "value": "PY-ASU"
      },
      {
        "label": "Boqueron",
        "value": "PY-19"
      },
      {
        "label": "Caaguazu",
        "value": "PY-5"
      },
      {
        "label": "Caazapa",
        "value": "PY-6"
      },
      {
        "label": "Canindeyu",
        "value": "PY-14"
      },
      {
        "label": "Central",
        "value": "PY-11"
      },
      {
        "label": "Concepcion",
        "value": "PY-1"
      },
      {
        "label": "Cordillera",
        "value": "PY-3"
      },
      {
        "label": "Guaira",
        "value": "PY-4"
      },
      {
        "label": "Itapua",
        "value": "PY-7"
      },
      {
        "label": "Misiones",
        "value": "PY-8"
      },
      {
        "label": "Neembucu",
        "value": "PY-12"
      },
      {
        "label": "Paraguari",
        "value": "PY-9"
      },
      {
        "label": "Presidente Hayes",
        "value": "PY-15"
      },
      {
        "label": "San Pedro",
        "value": "PY-2"
      }
    ],
    "value": "PY"
  },
  {
    "label": "Peru",
    "options": [
      {
        "label": "Amazonas",
        "value": "PE-AMA"
      },
      {
        "label": "Ancash",
        "value": "PE-ANC"
      },
      {
        "label": "Apurimac",
        "value": "PE-APU"
      },
      {
        "label": "Arequipa",
        "value": "PE-ARE"
      },
      {
        "label": "Ayacucho",
        "value": "PE-AYA"
      },
      {
        "label": "Cajamarca",
        "value": "PE-CAJ"
      },
      {
        "label": "Cusco",
        "value": "PE-CUS"
      },
      {
        "label": "El Callao",
        "value": "PE-CAL"
      },
      {
        "label": "Huancavelica",
        "value": "PE-HUV"
      },
      {
        "label": "Huanuco",
        "value": "PE-HUC"
      },
      {
        "label": "Ica",
        "value": "PE-ICA"
      },
      {
        "label": "Junin",
        "value": "PE-JUN"
      },
      {
        "label": "La Libertad",
        "value": "PE-LAL"
      },
      {
        "label": "Lambayeque",
        "value": "PE-LAM"
      },
      {
        "label": "Lima",
        "value": "PE-LIM"
      },
      {
        "label": "Loreto",
        "value": "PE-LOR"
      },
      {
        "label": "Madre de Dios",
        "value": "PE-MDD"
      },
      {
        "label": "Moquegua",
        "value": "PE-MOQ"
      },
      {
        "label": "Pasco",
        "value": "PE-PAS"
      },
      {
        "label": "Piura",
        "value": "PE-PIU"
      },
      {
        "label": "Puno",
        "value": "PE-PUN"
      },
      {
        "label": "San Martin",
        "value": "PE-SAM"
      },
      {
        "label": "Tacna",
        "value": "PE-TAC"
      },
      {
        "label": "Tumbes",
        "value": "PE-TUM"
      },
      {
        "label": "Ucayali",
        "value": "PE-UCA"
      }
    ],
    "value": "PE"
  },
  {
    "label": "Philippines",
    "options": [
      {
        "label": "Abra",
        "value": "PH-ABR"
      },
      {
        "label": "Agusan del Norte",
        "value": "PH-AGN"
      },
      {
        "label": "Agusan del Sur",
        "value": "PH-AGS"
      },
      {
        "label": "Aklan",
        "value": "PH-AKL"
      },
      {
        "label": "Albay",
        "value": "PH-ALB"
      },
      {
        "label": "Antique",
        "value": "PH-ANT"
      },
      {
        "label": "Apayao",
        "value": "PH-APA"
      },
      {
        "label": "Aurora",
        "value": "PH-AUR"
      },
      {
        "label": "Basilan",
        "value": "PH-BAS"
      },
      {
        "label": "Bataan",
        "value": "PH-BAN"
      },
      {
        "label": "Batanes",
        "value": "PH-BTN"
      },
      {
        "label": "Batangas",
        "value": "PH-BTG"
      },
      {
        "label": "Benguet",
        "value": "PH-BEN"
      },
      {
        "label": "Biliran",
        "value": "PH-BIL"
      },
      {
        "label": "Bohol",
        "value": "PH-BOH"
      },
      {
        "label": "Bukidnon",
        "value": "PH-BUK"
      },
      {
        "label": "Bulacan",
        "value": "PH-BUL"
      },
      {
        "label": "Cagayan",
        "value": "PH-CAG"
      },
      {
        "label": "Camarines Norte",
        "value": "PH-CAN"
      },
      {
        "label": "Camarines Sur",
        "value": "PH-CAS"
      },
      {
        "label": "Camiguin",
        "value": "PH-CAM"
      },
      {
        "label": "Capiz",
        "value": "PH-CAP"
      },
      {
        "label": "Catanduanes",
        "value": "PH-CAT"
      },
      {
        "label": "Cavite",
        "value": "PH-CAV"
      },
      {
        "label": "Cebu",
        "value": "PH-CEB"
      },
      {
        "label": "Cotabato",
        "value": "PH-NCO"
      },
      {
        "label": "Davao de Oro",
        "value": "PH-COM"
      },
      {
        "label": "Davao del Norte",
        "value": "PH-DAV"
      },
      {
        "label": "Davao del Sur",
        "value": "PH-DAS"
      },
      {
        "label": "Davao Oriental",
        "value": "PH-DAO"
      },
      {
        "label": "Dinagat Islands",
        "value": "PH-DIN"
      },
      {
        "label": "Eastern Samar",
        "value": "PH-EAS"
      },
      {
        "label": "Guimaras",
        "value": "PH-GUI"
      },
      {
        "label": "Ifugao",
        "value": "PH-IFU"
      },
      {
        "label": "Ilocos Norte",
        "value": "PH-ILN"
      },
      {
        "label": "Ilocos Sur",
        "value": "PH-ILS"
      },
      {
        "label": "Iloilo",
        "value": "PH-ILI"
      },
      {
        "label": "Isabela",
        "value": "PH-ISA"
      },
      {
        "label": "Kalinga",
        "value": "PH-KAL"
      },
      {
        "label": "La Union",
        "value": "PH-LUN"
      },
      {
        "label": "Laguna",
        "value": "PH-LAG"
      },
      {
        "label": "Lanao del Norte",
        "value": "PH-LAN"
      },
      {
        "label": "Lanao del Sur",
        "value": "PH-LAS"
      },
      {
        "label": "Leyte",
        "value": "PH-LEY"
      },
      {
        "label": "Maguindanao",
        "value": "PH-MAG"
      },
      {
        "label": "Marinduque",
        "value": "PH-MAD"
      },
      {
        "label": "Masbate",
        "value": "PH-MAS"
      },
      {
        "label": "Mindoro Occidental",
        "value": "PH-MDC"
      },
      {
        "label": "Mindoro Oriental",
        "value": "PH-MDR"
      },
      {
        "label": "Misamis Occidental",
        "value": "PH-MSC"
      },
      {
        "label": "Misamis Oriental",
        "value": "PH-MSR"
      },
      {
        "label": "Mountain Province",
        "value": "PH-MOU"
      },
      {
        "label": "National Capital Region",
        "value": "PH-00"
      },
      {
        "label": "Negros Occidental",
        "value": "PH-NEC"
      },
      {
        "label": "Negros Oriental",
        "value": "PH-NER"
      },
      {
        "label": "Northern Samar",
        "value": "PH-NSA"
      },
      {
        "label": "Nueva Ecija",
        "value": "PH-NUE"
      },
      {
        "label": "Nueva Vizcaya",
        "value": "PH-NUV"
      },
      {
        "label": "Palawan",
        "value": "PH-PLW"
      },
      {
        "label": "Pampanga",
        "value": "PH-PAM"
      },
      {
        "label": "Pangasinan",
        "value": "PH-PAN"
      },
      {
        "label": "Quezon",
        "value": "PH-QUE"
      },
      {
        "label": "Quirino",
        "value": "PH-QUI"
      },
      {
        "label": "Rizal",
        "value": "PH-RIZ"
      },
      {
        "label": "Romblon",
        "value": "PH-ROM"
      },
      {
        "label": "Samar",
        "value": "PH-WSA"
      },
      {
        "label": "Sarangani",
        "value": "PH-SAR"
      },
      {
        "label": "Siquijor",
        "value": "PH-SIG"
      },
      {
        "label": "Sorsogon",
        "value": "PH-SOR"
      },
      {
        "label": "South Cotabato",
        "value": "PH-SCO"
      },
      {
        "label": "Southern Leyte",
        "value": "PH-SLE"
      },
      {
        "label": "Sultan Kudarat",
        "value": "PH-SUK"
      },
      {
        "label": "Sulu",
        "value": "PH-SLU"
      },
      {
        "label": "Surigao del Norte",
        "value": "PH-SUN"
      },
      {
        "label": "Surigao del Sur",
        "value": "PH-SUR"
      },
      {
        "label": "Tarlac",
        "value": "PH-TAR"
      },
      {
        "label": "Tawi-Tawi",
        "value": "PH-TAW"
      },
      {
        "label": "Zambales",
        "value": "PH-ZMB"
      },
      {
        "label": "Zamboanga del Norte",
        "value": "PH-ZAN"
      },
      {
        "label": "Zamboanga del Sur",
        "value": "PH-ZAS"
      },
      {
        "label": "Zamboanga Sibugay",
        "value": "PH-ZSI"
      }
    ],
    "value": "PH"
  },
  {
    "label": "Pitcairn",
    "value": "PN"
  },
  {
    "label": "Poland",
    "options": [
      {
        "label": "Dolnoslaskie",
        "value": "PL-02"
      },
      {
        "label": "Kujawsko-Pomorskie",
        "value": "PL-04"
      },
      {
        "label": "Lodzkie",
        "value": "PL-10"
      },
      {
        "label": "Lubelskie",
        "value": "PL-06"
      },
      {
        "label": "Lubuskie",
        "value": "PL-08"
      },
      {
        "label": "Malopolskie",
        "value": "PL-12"
      },
      {
        "label": "Mazowieckie",
        "value": "PL-14"
      },
      {
        "label": "Opolskie",
        "value": "PL-16"
      },
      {
        "label": "Podkarpackie",
        "value": "PL-18"
      },
      {
        "label": "Podlaskie",
        "value": "PL-20"
      },
      {
        "label": "Pomorskie",
        "value": "PL-22"
      },
      {
        "label": "Slaskie",
        "value": "PL-24"
      },
      {
        "label": "Swietokrzyskie",
        "value": "PL-26"
      },
      {
        "label": "Warminsko-Mazurskie",
        "value": "PL-28"
      },
      {
        "label": "Wielkopolskie",
        "value": "PL-30"
      },
      {
        "label": "Zachodniopomorskie",
        "value": "PL-32"
      }
    ],
    "value": "PL"
  },
  {
    "label": "Portugal",
    "options": [
      {
        "label": "Aveiro",
        "value": "PT-01"
      },
      {
        "label": "Beja",
        "value": "PT-02"
      },
      {
        "label": "Braga",
        "value": "PT-03"
      },
      {
        "label": "Braganca",
        "value": "PT-04"
      },
      {
        "label": "Castelo Branco",
        "value": "PT-05"
      },
      {
        "label": "Coimbra",
        "value": "PT-06"
      },
      {
        "label": "Evora",
        "value": "PT-07"
      },
      {
        "label": "Faro",
        "value": "PT-08"
      },
      {
        "label": "Guarda",
        "value": "PT-09"
      },
      {
        "label": "Leiria",
        "value": "PT-10"
      },
      {
        "label": "Lisboa",
        "value": "PT-11"
      },
      {
        "label": "Portalegre",
        "value": "PT-12"
      },
      {
        "label": "Porto",
        "value": "PT-13"
      },
      {
        "label": "Regiao Autonoma da Madeira",
        "value": "PT-30"
      },
      {
        "label": "Regiao Autonoma dos Acores",
        "value": "PT-20"
      },
      {
        "label": "Santarem",
        "value": "PT-14"
      },
      {
        "label": "Setubal",
        "value": "PT-15"
      },
      {
        "label": "Viana do Castelo",
        "value": "PT-16"
      },
      {
        "label": "Vila Real",
        "value": "PT-17"
      },
      {
        "label": "Viseu",
        "value": "PT-18"
      }
    ],
    "value": "PT"
  },
  {
    "label": "Puerto Rico",
    "value": "PR"
  },
  {
    "label": "Qatar",
    "options": [
      {
        "label": "Ad Dawhah",
        "value": "QA-DA"
      },
      {
        "label": "Al Khawr wa adh Dhakhirah",
        "value": "QA-KH"
      },
      {
        "label": "Al Wakrah",
        "value": "QA-WA"
      },
      {
        "label": "Ar Rayyan",
        "value": "QA-RA"
      },
      {
        "label": "Ash Shamal",
        "value": "QA-MS"
      },
      {
        "label": "Az Za'ayin",
        "value": "QA-ZA"
      },
      {
        "label": "Umm Salal",
        "value": "QA-US"
      }
    ],
    "value": "QA"
  },
  {
    "label": "Réunion",
    "value": "RE"
  },
  {
    "label": "Romania",
    "options": [
      {
        "label": "Alba",
        "value": "RO-AB"
      },
      {
        "label": "Arad",
        "value": "RO-AR"
      },
      {
        "label": "Arges",
        "value": "RO-AG"
      },
      {
        "label": "Bacau",
        "value": "RO-BC"
      },
      {
        "label": "Bihor",
        "value": "RO-BH"
      },
      {
        "label": "Bistrita-Nasaud",
        "value": "RO-BN"
      },
      {
        "label": "Botosani",
        "value": "RO-BT"
      },
      {
        "label": "Braila",
        "value": "RO-BR"
      },
      {
        "label": "Brasov",
        "value": "RO-BV"
      },
      {
        "label": "Bucuresti",
        "value": "RO-B"
      },
      {
        "label": "Buzau",
        "value": "RO-BZ"
      },
      {
        "label": "Calarasi",
        "value": "RO-CL"
      },
      {
        "label": "Caras-Severin",
        "value": "RO-CS"
      },
      {
        "label": "Cluj",
        "value": "RO-CJ"
      },
      {
        "label": "Constanta",
        "value": "RO-CT"
      },
      {
        "label": "Covasna",
        "value": "RO-CV"
      },
      {
        "label": "Dambovita",
        "value": "RO-DB"
      },
      {
        "label": "Dolj",
        "value": "RO-DJ"
      },
      {
        "label": "Galati",
        "value": "RO-GL"
      },
      {
        "label": "Giurgiu",
        "value": "RO-GR"
      },
      {
        "label": "Gorj",
        "value": "RO-GJ"
      },
      {
        "label": "Harghita",
        "value": "RO-HR"
      },
      {
        "label": "Hunedoara",
        "value": "RO-HD"
      },
      {
        "label": "Ialomita",
        "value": "RO-IL"
      },
      {
        "label": "Iasi",
        "value": "RO-IS"
      },
      {
        "label": "Ilfov",
        "value": "RO-IF"
      },
      {
        "label": "Maramures",
        "value": "RO-MM"
      },
      {
        "label": "Mehedinti",
        "value": "RO-MH"
      },
      {
        "label": "Mures",
        "value": "RO-MS"
      },
      {
        "label": "Neamt",
        "value": "RO-NT"
      },
      {
        "label": "Olt",
        "value": "RO-OT"
      },
      {
        "label": "Prahova",
        "value": "RO-PH"
      },
      {
        "label": "Salaj",
        "value": "RO-SJ"
      },
      {
        "label": "Satu Mare",
        "value": "RO-SM"
      },
      {
        "label": "Sibiu",
        "value": "RO-SB"
      },
      {
        "label": "Suceava",
        "value": "RO-SV"
      },
      {
        "label": "Teleorman",
        "value": "RO-TR"
      },
      {
        "label": "Timis",
        "value": "RO-TM"
      },
      {
        "label": "Tulcea",
        "value": "RO-TL"
      },
      {
        "label": "Valcea",
        "value": "RO-VL"
      },
      {
        "label": "Vaslui",
        "value": "RO-VS"
      },
      {
        "label": "Vrancea",
        "value": "RO-VN"
      }
    ],
    "value": "RO"
  },
  {
    "label": "Russian Federation",
    "options": [
      {
        "label": "Adygeya, Respublika",
        "value": "RU-AD"
      },
      {
        "label": "Altay, Respublika",
        "value": "RU-AL"
      },
      {
        "label": "Altayskiy kray",
        "value": "RU-ALT"
      },
      {
        "label": "Amurskaya oblast'",
        "value": "RU-AMU"
      },
      {
        "label": "Arkhangel'skaya oblast'",
        "value": "RU-ARK"
      },
      {
        "label": "Astrakhanskaya oblast'",
        "value": "RU-AST"
      },
      {
        "label": "Bashkortostan, Respublika",
        "value": "RU-BA"
      },
      {
        "label": "Belgorodskaya oblast'",
        "value": "RU-BEL"
      },
      {
        "label": "Bryanskaya oblast'",
        "value": "RU-BRY"
      },
      {
        "label": "Buryatiya, Respublika",
        "value": "RU-BU"
      },
      {
        "label": "Chechenskaya Respublika",
        "value": "RU-CE"
      },
      {
        "label": "Chelyabinskaya oblast'",
        "value": "RU-CHE"
      },
      {
        "label": "Chukotskiy avtonomnyy okrug",
        "value": "RU-CHU"
      },
      {
        "label": "Chuvashskaya Respublika",
        "value": "RU-CU"
      },
      {
        "label": "Dagestan, Respublika",
        "value": "RU-DA"
      },
      {
        "label": "Ingushetiya, Respublika",
        "value": "RU-IN"
      },
      {
        "label": "Irkutskaya oblast'",
        "value": "RU-IRK"
      },
      {
        "label": "Ivanovskaya oblast'",
        "value": "RU-IVA"
      },
      {
        "label": "Kabardino-Balkarskaya Respublika",
        "value": "RU-KB"
      },
      {
        "label": "Kaliningradskaya oblast'",
        "value": "RU-KGD"
      },
      {
        "label": "Kalmykiya, Respublika",
        "value": "RU-KL"
      },
      {
        "label": "Kaluzhskaya oblast'",
        "value": "RU-KLU"
      },
      {
        "label": "Kamchatskiy kray",
        "value": "RU-KAM"
      },
      {
        "label": "Karachayevo-Cherkesskaya Respublika",
        "value": "RU-KC"
      },
      {
        "label": "Kareliya, Respublika",
        "value": "RU-KR"
      },
      {
        "label": "Kemerovskaya oblast'",
        "value": "RU-KEM"
      },
      {
        "label": "Khabarovskiy kray",
        "value": "RU-KHA"
      },
      {
        "label": "Khakasiya, Respublika",
        "value": "RU-KK"
      },
      {
        "label": "Khanty-Mansiyskiy avtonomnyy okrug",
        "value": "RU-KHM"
      },
      {
        "label": "Kirovskaya oblast'",
        "value": "RU-KIR"
      },
      {
        "label": "Komi, Respublika",
        "value": "RU-KO"
      },
      {
        "label": "Kostromskaya oblast'",
        "value": "RU-KOS"
      },
      {
        "label": "Krasnodarskiy kray",
        "value": "RU-KDA"
      },
      {
        "label": "Krasnoyarskiy kray",
        "value": "RU-KYA"
      },
      {
        "label": "Kurganskaya oblast'",
        "value": "RU-KGN"
      },
      {
        "label": "Kurskaya oblast'",
        "value": "RU-KRS"
      },
      {
        "label": "Leningradskaya oblast'",
        "value": "RU-LEN"
      },
      {
        "label": "Lipetskaya oblast'",
        "value": "RU-LIP"
      },
      {
        "label": "Magadanskaya oblast'",
        "value": "RU-MAG"
      },
      {
        "label": "Mariy El, Respublika",
        "value": "RU-ME"
      },
      {
        "label": "Mordoviya, Respublika",
        "value": "RU-MO"
      },
      {
        "label": "Moskovskaya oblast'",
        "value": "RU-MOS"
      },
      {
        "label": "Moskva",
        "value": "RU-MOW"
      },
      {
        "label": "Murmanskaya oblast'",
        "value": "RU-MUR"
      },
      {
        "label": "Nenetskiy avtonomnyy okrug",
        "value": "RU-NEN"
      },
      {
        "label": "Nizhegorodskaya oblast'",
        "value": "RU-NIZ"
      },
      {
        "label": "Novgorodskaya oblast'",
        "value": "RU-NGR"
      },
      {
        "label": "Novosibirskaya oblast'",
        "value": "RU-NVS"
      },
      {
        "label": "Omskaya oblast'",
        "value": "RU-OMS"
      },
      {
        "label": "Orenburgskaya oblast'",
        "value": "RU-ORE"
      },
      {
        "label": "Orlovskaya oblast'",
        "value": "RU-ORL"
      },
      {
        "label": "Penzenskaya oblast'",
        "value": "RU-PNZ"
      },
      {
        "label": "Permskiy kray",
        "value": "RU-PER"
      },
      {
        "label": "Primorskiy kray",
        "value": "RU-PRI"
      },
      {
        "label": "Pskovskaya oblast'",
        "value": "RU-PSK"
      },
      {
        "label": "Rostovskaya oblast'",
        "value": "RU-ROS"
      },
      {
        "label": "Ryazanskaya oblast'",
        "value": "RU-RYA"
      },
      {
        "label": "Saha, Respublika",
        "value": "RU-SA"
      },
      {
        "label": "Sakhalinskaya oblast'",
        "value": "RU-SAK"
      },
      {
        "label": "Samarskaya oblast'",
        "value": "RU-SAM"
      },
      {
        "label": "Sankt-Peterburg",
        "value": "RU-SPE"
      },
      {
        "label": "Saratovskaya oblast'",
        "value": "RU-SAR"
      },
      {
        "label": "Severnaya Osetiya, Respublika",
        "value": "RU-SE"
      },
      {
        "label": "Smolenskaya oblast'",
        "value": "RU-SMO"
      },
      {
        "label": "Stavropol'skiy kray",
        "value": "RU-STA"
      },
      {
        "label": "Sverdlovskaya oblast'",
        "value": "RU-SVE"
      },
      {
        "label": "Tambovskaya oblast'",
        "value": "RU-TAM"
      },
      {
        "label": "Tatarstan, Respublika",
        "value": "RU-TA"
      },
      {
        "label": "Tomskaya oblast'",
        "value": "RU-TOM"
      },
      {
        "label": "Tul'skaya oblast'",
        "value": "RU-TUL"
      },
      {
        "label": "Tverskaya oblast'",
        "value": "RU-TVE"
      },
      {
        "label": "Tyumenskaya oblast'",
        "value": "RU-TYU"
      },
      {
        "label": "Tyva, Respublika",
        "value": "RU-TY"
      },
      {
        "label": "Udmurtskaya Respublika",
        "value": "RU-UD"
      },
      {
        "label": "Ul'yanovskaya oblast'",
        "value": "RU-ULY"
      },
      {
        "label": "Vladimirskaya oblast'",
        "value": "RU-VLA"
      },
      {
        "label": "Volgogradskaya oblast'",
        "value": "RU-VGG"
      },
      {
        "label": "Vologodskaya oblast'",
        "value": "RU-VLG"
      },
      {
        "label": "Voronezhskaya oblast'",
        "value": "RU-VOR"
      },
      {
        "label": "Yamalo-Nenetskiy avtonomnyy okrug",
        "value": "RU-YAN"
      },
      {
        "label": "Yaroslavskaya oblast'",
        "value": "RU-YAR"
      },
      {
        "label": "Yevreyskaya avtonomnaya oblast'",
        "value": "RU-YEV"
      },
      {
        "label": "Zabaykal'skiy kray",
        "value": "RU-ZAB"
      }
    ],
    "value": "RU"
  },
  {
    "label": "Rwanda",
    "options": [
      {
        "label": "Est",
        "value": "RW-02"
      },
      {
        "label": "Nord",
        "value": "RW-03"
      },
      {
        "label": "Ouest",
        "value": "RW-04"
      },
      {
        "label": "Sud",
        "value": "RW-05"
      },
      {
        "label": "Ville de Kigali",
        "value": "RW-01"
      }
    ],
    "value": "RW"
  },
  {
    "label": "Saint Barthélemy",
    "value": "BL"
  },
  {
    "label": "Saint Helena, Ascension and Tristan da Cunha",
    "options": [
      {
        "label": "Saint Helena",
        "value": "SH-HL"
      }
    ],
    "value": "SH"
  },
  {
    "label": "Saint Kitts and Nevis",
    "options": [
      {
        "label": "Christ Church Nichola Town",
        "value": "KN-01"
      },
      {
        "label": "Saint Anne Sandy Point",
        "value": "KN-02"
      },
      {
        "label": "Saint George Basseterre",
        "value": "KN-03"
      },
      {
        "label": "Saint James Windward",
        "value": "KN-05"
      },
      {
        "label": "Saint John Capisterre",
        "value": "KN-06"
      },
      {
        "label": "Saint John Figtree",
        "value": "KN-07"
      },
      {
        "label": "Saint Mary Cayon",
        "value": "KN-08"
      },
      {
        "label": "Saint Paul Capisterre",
        "value": "KN-09"
      },
      {
        "label": "Saint Paul Charlestown",
        "value": "KN-10"
      },
      {
        "label": "Saint Peter Basseterre",
        "value": "KN-11"
      },
      {
        "label": "Saint Thomas Lowland",
        "value": "KN-12"
      },
      {
        "label": "Saint Thomas Middle Island",
        "value": "KN-13"
      },
      {
        "label": "Trinity Palmetto Point",
        "value": "KN-15"
      }
    ],
    "value": "KN"
  },
  {
    "label": "Saint Lucia",
    "options": [
      {
        "label": "Anse la Raye",
        "value": "LC-01"
      },
      {
        "label": "Castries",
        "value": "LC-02"
      },
      {
        "label": "Choiseul",
        "value": "LC-03"
      },
      {
        "label": "Dennery",
        "value": "LC-05"
      },
      {
        "label": "Gros Islet",
        "value": "LC-06"
      },
      {
        "label": "Laborie",
        "value": "LC-07"
      },
      {
        "label": "Micoud",
        "value": "LC-08"
      },
      {
        "label": "Soufriere",
        "value": "LC-10"
      },
      {
        "label": "Vieux Fort",
        "value": "LC-11"
      }
    ],
    "value": "LC"
  },
  {
    "label": "Saint Martin (French part)",
    "value": "MF"
  },
  {
    "label": "Saint Pierre and Miquelon",
    "value": "PM"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "options": [
      {
        "label": "Charlotte",
        "value": "VC-01"
      },
      {
        "label": "Grenadines",
        "value": "VC-06"
      },
      {
        "label": "Saint George",
        "value": "VC-04"
      },
      {
        "label": "Saint Patrick",
        "value": "VC-05"
      }
    ],
    "value": "VC"
  },
  {
    "label": "Samoa",
    "options": [
      {
        "label": "Atua",
        "value": "WS-AT"
      },
      {
        "label": "Fa'asaleleaga",
        "value": "WS-FA"
      },
      {
        "label": "Tuamasaga",
        "value": "WS-TU"
      }
    ],
    "value": "WS"
  },
  {
    "label": "San Marino",
    "options": [
      {
        "label": "Chiesanuova",
        "value": "SM-02"
      },
      {
        "label": "Citta di San Marino",
        "value": "SM-07"
      },
      {
        "label": "Faetano",
        "value": "SM-04"
      },
      {
        "label": "Serravalle",
        "value": "SM-09"
      }
    ],
    "value": "SM"
  },
  {
    "label": "Sao Tome and Principe",
    "options": [
      {
        "label": "Agua Grande",
        "value": "ST-01"
      },
      {
        "label": "Principe",
        "value": "ST-P"
      }
    ],
    "value": "ST"
  },
  {
    "label": "Saudi Arabia",
    "options": [
      {
        "label": "'Asir",
        "value": "SA-14"
      },
      {
        "label": "Al Bahah",
        "value": "SA-11"
      },
      {
        "label": "Al Hudud ash Shamaliyah",
        "value": "SA-08"
      },
      {
        "label": "Al Jawf",
        "value": "SA-12"
      },
      {
        "label": "Al Madinah al Munawwarah",
        "value": "SA-03"
      },
      {
        "label": "Al Qasim",
        "value": "SA-05"
      },
      {
        "label": "Ar Riyad",
        "value": "SA-01"
      },
      {
        "label": "Ash Sharqiyah",
        "value": "SA-04"
      },
      {
        "label": "Ha'il",
        "value": "SA-06"
      },
      {
        "label": "Jazan",
        "value": "SA-09"
      },
      {
        "label": "Makkah al Mukarramah",
        "value": "SA-02"
      },
      {
        "label": "Najran",
        "value": "SA-10"
      },
      {
        "label": "Tabuk",
        "value": "SA-07"
      }
    ],
    "value": "SA"
  },
  {
    "label": "Senegal",
    "options": [
      {
        "label": "Dakar",
        "value": "SN-DK"
      },
      {
        "label": "Diourbel",
        "value": "SN-DB"
      },
      {
        "label": "Fatick",
        "value": "SN-FK"
      },
      {
        "label": "Kaffrine",
        "value": "SN-KA"
      },
      {
        "label": "Kaolack",
        "value": "SN-KL"
      },
      {
        "label": "Kedougou",
        "value": "SN-KE"
      },
      {
        "label": "Kolda",
        "value": "SN-KD"
      },
      {
        "label": "Louga",
        "value": "SN-LG"
      },
      {
        "label": "Matam",
        "value": "SN-MT"
      },
      {
        "label": "Saint-Louis",
        "value": "SN-SL"
      },
      {
        "label": "Tambacounda",
        "value": "SN-TC"
      },
      {
        "label": "Thies",
        "value": "SN-TH"
      },
      {
        "label": "Ziguinchor",
        "value": "SN-ZG"
      }
    ],
    "value": "SN"
  },
  {
    "label": "Serbia",
    "options": [
      {
        "label": "Beograd",
        "value": "RS-00"
      },
      {
        "label": "Borski okrug",
        "value": "RS-14"
      },
      {
        "label": "Branicevski okrug",
        "value": "RS-11"
      },
      {
        "label": "Jablanicki okrug",
        "value": "RS-23"
      },
      {
        "label": "Juznobacki okrug",
        "value": "RS-06"
      },
      {
        "label": "Juznobanatski okrug",
        "value": "RS-04"
      },
      {
        "label": "Kolubarski okrug",
        "value": "RS-09"
      },
      {
        "label": "Kosovsko-Mitrovacki okrug",
        "value": "RS-28"
      },
      {
        "label": "Macvanski okrug",
        "value": "RS-08"
      },
      {
        "label": "Moravicki okrug",
        "value": "RS-17"
      },
      {
        "label": "Nisavski okrug",
        "value": "RS-20"
      },
      {
        "label": "Pcinjski okrug",
        "value": "RS-24"
      },
      {
        "label": "Pecki okrug",
        "value": "RS-26"
      },
      {
        "label": "Pirotski okrug",
        "value": "RS-22"
      },
      {
        "label": "Podunavski okrug",
        "value": "RS-10"
      },
      {
        "label": "Pomoravski okrug",
        "value": "RS-13"
      },
      {
        "label": "Prizrenski okrug",
        "value": "RS-27"
      },
      {
        "label": "Rasinski okrug",
        "value": "RS-19"
      },
      {
        "label": "Raski okrug",
        "value": "RS-18"
      },
      {
        "label": "Severnobacki okrug",
        "value": "RS-01"
      },
      {
        "label": "Severnobanatski okrug",
        "value": "RS-03"
      },
      {
        "label": "Srednjebanatski okrug",
        "value": "RS-02"
      },
      {
        "label": "Sremski okrug",
        "value": "RS-07"
      },
      {
        "label": "Sumadijski okrug",
        "value": "RS-12"
      },
      {
        "label": "Toplicki okrug",
        "value": "RS-21"
      },
      {
        "label": "Zajecarski okrug",
        "value": "RS-15"
      },
      {
        "label": "Zapadnobacki okrug",
        "value": "RS-05"
      },
      {
        "label": "Zlatiborski okrug",
        "value": "RS-16"
      }
    ],
    "value": "RS"
  },
  {
    "label": "Seychelles",
    "options": [
      {
        "label": "Anse aux Pins",
        "value": "SC-01"
      },
      {
        "label": "Anse Boileau",
        "value": "SC-02"
      },
      {
        "label": "Anse Royale",
        "value": "SC-05"
      },
      {
        "label": "Baie Lazare",
        "value": "SC-06"
      },
      {
        "label": "Baie Sainte Anne",
        "value": "SC-07"
      },
      {
        "label": "Beau Vallon",
        "value": "SC-08"
      },
      {
        "label": "Bel Ombre",
        "value": "SC-10"
      },
      {
        "label": "Cascade",
        "value": "SC-11"
      },
      {
        "label": "English River",
        "value": "SC-16"
      },
      {
        "label": "Grand Anse Mahe",
        "value": "SC-13"
      },
      {
        "label": "Grand Anse Praslin",
        "value": "SC-14"
      },
      {
        "label": "La Digue",
        "value": "SC-15"
      },
      {
        "label": "Pointe Larue",
        "value": "SC-20"
      },
      {
        "label": "Takamaka",
        "value": "SC-23"
      }
    ],
    "value": "SC"
  },
  {
    "label": "Sierra Leone",
    "options": [
      {
        "label": "Eastern",
        "value": "SL-E"
      },
      {
        "label": "North Western",
        "value": "SL-NW"
      },
      {
        "label": "Northern",
        "value": "SL-N"
      },
      {
        "label": "Southern",
        "value": "SL-S"
      },
      {
        "label": "Western Area",
        "value": "SL-W"
      }
    ],
    "value": "SL"
  },
  {
    "label": "Singapore",
    "value": "SG"
  },
  {
    "label": "Sint Maarten (Dutch part)",
    "value": "SX"
  },
  {
    "label": "Slovakia",
    "options": [
      {
        "label": "Banskobystricky kraj",
        "value": "SK-BC"
      },
      {
        "label": "Bratislavsky kraj",
        "value": "SK-BL"
      },
      {
        "label": "Kosicky kraj",
        "value": "SK-KI"
      },
      {
        "label": "Nitriansky kraj",
        "value": "SK-NI"
      },
      {
        "label": "Presovsky kraj",
        "value": "SK-PV"
      },
      {
        "label": "Trenciansky kraj",
        "value": "SK-TC"
      },
      {
        "label": "Trnavsky kraj",
        "value": "SK-TA"
      },
      {
        "label": "Zilinsky kraj",
        "value": "SK-ZI"
      }
    ],
    "value": "SK"
  },
  {
    "label": "Slovenia",
    "options": [
      {
        "label": "Ajdovscina",
        "value": "SI-001"
      },
      {
        "label": "Ankaran",
        "value": "SI-213"
      },
      {
        "label": "Apace",
        "value": "SI-195"
      },
      {
        "label": "Beltinci",
        "value": "SI-002"
      },
      {
        "label": "Benedikt",
        "value": "SI-148"
      },
      {
        "label": "Bistrica ob Sotli",
        "value": "SI-149"
      },
      {
        "label": "Bled",
        "value": "SI-003"
      },
      {
        "label": "Bloke",
        "value": "SI-150"
      },
      {
        "label": "Bohinj",
        "value": "SI-004"
      },
      {
        "label": "Borovnica",
        "value": "SI-005"
      },
      {
        "label": "Bovec",
        "value": "SI-006"
      },
      {
        "label": "Braslovce",
        "value": "SI-151"
      },
      {
        "label": "Brda",
        "value": "SI-007"
      },
      {
        "label": "Brezice",
        "value": "SI-009"
      },
      {
        "label": "Brezovica",
        "value": "SI-008"
      },
      {
        "label": "Cankova",
        "value": "SI-152"
      },
      {
        "label": "Celje",
        "value": "SI-011"
      },
      {
        "label": "Cerklje na Gorenjskem",
        "value": "SI-012"
      },
      {
        "label": "Cerknica",
        "value": "SI-013"
      },
      {
        "label": "Cerkno",
        "value": "SI-014"
      },
      {
        "label": "Cirkulane",
        "value": "SI-196"
      },
      {
        "label": "Crensovci",
        "value": "SI-015"
      },
      {
        "label": "Crnomelj",
        "value": "SI-017"
      },
      {
        "label": "Destrnik",
        "value": "SI-018"
      },
      {
        "label": "Divaca",
        "value": "SI-019"
      },
      {
        "label": "Dobje",
        "value": "SI-154"
      },
      {
        "label": "Dobrepolje",
        "value": "SI-020"
      },
      {
        "label": "Dobrna",
        "value": "SI-155"
      },
      {
        "label": "Dobrova-Polhov Gradec",
        "value": "SI-021"
      },
      {
        "label": "Dobrovnik",
        "value": "SI-156"
      },
      {
        "label": "Domzale",
        "value": "SI-023"
      },
      {
        "label": "Dornava",
        "value": "SI-024"
      },
      {
        "label": "Dravograd",
        "value": "SI-025"
      },
      {
        "label": "Duplek",
        "value": "SI-026"
      },
      {
        "label": "Gorje",
        "value": "SI-207"
      },
      {
        "label": "Gornja Radgona",
        "value": "SI-029"
      },
      {
        "label": "Gornji Petrovci",
        "value": "SI-031"
      },
      {
        "label": "Grad",
        "value": "SI-158"
      },
      {
        "label": "Grosuplje",
        "value": "SI-032"
      },
      {
        "label": "Hajdina",
        "value": "SI-159"
      },
      {
        "label": "Hoce-Slivnica",
        "value": "SI-160"
      },
      {
        "label": "Hodos",
        "value": "SI-161"
      },
      {
        "label": "Horjul",
        "value": "SI-162"
      },
      {
        "label": "Hrastnik",
        "value": "SI-034"
      },
      {
        "label": "Hrpelje-Kozina",
        "value": "SI-035"
      },
      {
        "label": "Idrija",
        "value": "SI-036"
      },
      {
        "label": "Ig",
        "value": "SI-037"
      },
      {
        "label": "Ilirska Bistrica",
        "value": "SI-038"
      },
      {
        "label": "Ivancna Gorica",
        "value": "SI-039"
      },
      {
        "label": "Izola",
        "value": "SI-040"
      },
      {
        "label": "Jesenice",
        "value": "SI-041"
      },
      {
        "label": "Jursinci",
        "value": "SI-042"
      },
      {
        "label": "Kamnik",
        "value": "SI-043"
      },
      {
        "label": "Kanal",
        "value": "SI-044"
      },
      {
        "label": "Kidricevo",
        "value": "SI-045"
      },
      {
        "label": "Kobarid",
        "value": "SI-046"
      },
      {
        "label": "Kobilje",
        "value": "SI-047"
      },
      {
        "label": "Kocevje",
        "value": "SI-048"
      },
      {
        "label": "Komen",
        "value": "SI-049"
      },
      {
        "label": "Komenda",
        "value": "SI-164"
      },
      {
        "label": "Koper",
        "value": "SI-050"
      },
      {
        "label": "Kosanjevica na Krki",
        "value": "SI-197"
      },
      {
        "label": "Kostel",
        "value": "SI-165"
      },
      {
        "label": "Kranj",
        "value": "SI-052"
      },
      {
        "label": "Kranjska Gora",
        "value": "SI-053"
      },
      {
        "label": "Krizevci",
        "value": "SI-166"
      },
      {
        "label": "Krsko",
        "value": "SI-054"
      },
      {
        "label": "Kungota",
        "value": "SI-055"
      },
      {
        "label": "Kuzma",
        "value": "SI-056"
      },
      {
        "label": "Lasko",
        "value": "SI-057"
      },
      {
        "label": "Lenart",
        "value": "SI-058"
      },
      {
        "label": "Lendava",
        "value": "SI-059"
      },
      {
        "label": "Litija",
        "value": "SI-060"
      },
      {
        "label": "Ljubljana",
        "value": "SI-061"
      },
      {
        "label": "Ljutomer",
        "value": "SI-063"
      },
      {
        "label": "Log-Dragomer",
        "value": "SI-208"
      },
      {
        "label": "Logatec",
        "value": "SI-064"
      },
      {
        "label": "Loska dolina",
        "value": "SI-065"
      },
      {
        "label": "Loski Potok",
        "value": "SI-066"
      },
      {
        "label": "Lovrenc na Pohorju",
        "value": "SI-167"
      },
      {
        "label": "Luce",
        "value": "SI-067"
      },
      {
        "label": "Lukovica",
        "value": "SI-068"
      },
      {
        "label": "Majsperk",
        "value": "SI-069"
      },
      {
        "label": "Makole",
        "value": "SI-198"
      },
      {
        "label": "Maribor",
        "value": "SI-070"
      },
      {
        "label": "Markovci",
        "value": "SI-168"
      },
      {
        "label": "Medvode",
        "value": "SI-071"
      },
      {
        "label": "Menges",
        "value": "SI-072"
      },
      {
        "label": "Metlika",
        "value": "SI-073"
      },
      {
        "label": "Mezica",
        "value": "SI-074"
      },
      {
        "label": "Miklavz na Dravskem polju",
        "value": "SI-169"
      },
      {
        "label": "Miren-Kostanjevica",
        "value": "SI-075"
      },
      {
        "label": "Mirna",
        "value": "SI-212"
      },
      {
        "label": "Mirna Pec",
        "value": "SI-170"
      },
      {
        "label": "Mislinja",
        "value": "SI-076"
      },
      {
        "label": "Mokronog-Trebelno",
        "value": "SI-199"
      },
      {
        "label": "Moravce",
        "value": "SI-077"
      },
      {
        "label": "Mozirje",
        "value": "SI-079"
      },
      {
        "label": "Murska Sobota",
        "value": "SI-080"
      },
      {
        "label": "Muta",
        "value": "SI-081"
      },
      {
        "label": "Naklo",
        "value": "SI-082"
      },
      {
        "label": "Nazarje",
        "value": "SI-083"
      },
      {
        "label": "Nova Gorica",
        "value": "SI-084"
      },
      {
        "label": "Novo Mesto",
        "value": "SI-085"
      },
      {
        "label": "Odranci",
        "value": "SI-086"
      },
      {
        "label": "Oplotnica",
        "value": "SI-171"
      },
      {
        "label": "Ormoz",
        "value": "SI-087"
      },
      {
        "label": "Piran",
        "value": "SI-090"
      },
      {
        "label": "Pivka",
        "value": "SI-091"
      },
      {
        "label": "Podcetrtek",
        "value": "SI-092"
      },
      {
        "label": "Podlehnik",
        "value": "SI-172"
      },
      {
        "label": "Poljcane",
        "value": "SI-200"
      },
      {
        "label": "Polzela",
        "value": "SI-173"
      },
      {
        "label": "Postojna",
        "value": "SI-094"
      },
      {
        "label": "Prebold",
        "value": "SI-174"
      },
      {
        "label": "Preddvor",
        "value": "SI-095"
      },
      {
        "label": "Prevalje",
        "value": "SI-175"
      },
      {
        "label": "Ptuj",
        "value": "SI-096"
      },
      {
        "label": "Puconci",
        "value": "SI-097"
      },
      {
        "label": "Race-Fram",
        "value": "SI-098"
      },
      {
        "label": "Radece",
        "value": "SI-099"
      },
      {
        "label": "Radenci",
        "value": "SI-100"
      },
      {
        "label": "Radlje ob Dravi",
        "value": "SI-101"
      },
      {
        "label": "Radovljica",
        "value": "SI-102"
      },
      {
        "label": "Ravne na Koroskem",
        "value": "SI-103"
      },
      {
        "label": "Razkrizje",
        "value": "SI-176"
      },
      {
        "label": "Recica ob Savinji",
        "value": "SI-209"
      },
      {
        "label": "Rence-Vogrsko",
        "value": "SI-201"
      },
      {
        "label": "Ribnica",
        "value": "SI-104"
      },
      {
        "label": "Rogaska Slatina",
        "value": "SI-106"
      },
      {
        "label": "Rogasovci",
        "value": "SI-105"
      },
      {
        "label": "Ruse",
        "value": "SI-108"
      },
      {
        "label": "Salovci",
        "value": "SI-033"
      },
      {
        "label": "Semic",
        "value": "SI-109"
      },
      {
        "label": "Sempeter-Vrtojba",
        "value": "SI-183"
      },
      {
        "label": "Sencur",
        "value": "SI-117"
      },
      {
        "label": "Sentilj",
        "value": "SI-118"
      },
      {
        "label": "Sentjernej",
        "value": "SI-119"
      },
      {
        "label": "Sentjur",
        "value": "SI-120"
      },
      {
        "label": "Sentrupert",
        "value": "SI-211"
      },
      {
        "label": "Sevnica",
        "value": "SI-110"
      },
      {
        "label": "Sezana",
        "value": "SI-111"
      },
      {
        "label": "Skocjan",
        "value": "SI-121"
      },
      {
        "label": "Skofja Loka",
        "value": "SI-122"
      },
      {
        "label": "Skofljica",
        "value": "SI-123"
      },
      {
        "label": "Slovenj Gradec",
        "value": "SI-112"
      },
      {
        "label": "Slovenska Bistrica",
        "value": "SI-113"
      },
      {
        "label": "Slovenske Konjice",
        "value": "SI-114"
      },
      {
        "label": "Smarje pri Jelsah",
        "value": "SI-124"
      },
      {
        "label": "Smarjeske Toplice",
        "value": "SI-206"
      },
      {
        "label": "Smartno ob Paki",
        "value": "SI-125"
      },
      {
        "label": "Smartno pri Litiji",
        "value": "SI-194"
      },
      {
        "label": "Sodrazica",
        "value": "SI-179"
      },
      {
        "label": "Solcava",
        "value": "SI-180"
      },
      {
        "label": "Sostanj",
        "value": "SI-126"
      },
      {
        "label": "Starse",
        "value": "SI-115"
      },
      {
        "label": "Store",
        "value": "SI-127"
      },
      {
        "label": "Straza",
        "value": "SI-203"
      },
      {
        "label": "Sveta Trojica v Slovenskih goricah",
        "value": "SI-204"
      },
      {
        "label": "Sveti Andraz v Slovenskih Goricah",
        "value": "SI-182"
      },
      {
        "label": "Sveti Jurij ob Scavnici",
        "value": "SI-116"
      },
      {
        "label": "Sveti Jurij v Slovenskih goricah",
        "value": "SI-210"
      },
      {
        "label": "Sveti Tomaz",
        "value": "SI-205"
      },
      {
        "label": "Tabor",
        "value": "SI-184"
      },
      {
        "label": "Tisina",
        "value": "SI-010"
      },
      {
        "label": "Tolmin",
        "value": "SI-128"
      },
      {
        "label": "Trbovlje",
        "value": "SI-129"
      },
      {
        "label": "Trebnje",
        "value": "SI-130"
      },
      {
        "label": "Trnovska Vas",
        "value": "SI-185"
      },
      {
        "label": "Trzic",
        "value": "SI-131"
      },
      {
        "label": "Trzin",
        "value": "SI-186"
      },
      {
        "label": "Turnisce",
        "value": "SI-132"
      },
      {
        "label": "Velenje",
        "value": "SI-133"
      },
      {
        "label": "Velika Polana",
        "value": "SI-187"
      },
      {
        "label": "Velike Lasce",
        "value": "SI-134"
      },
      {
        "label": "Verzej",
        "value": "SI-188"
      },
      {
        "label": "Videm",
        "value": "SI-135"
      },
      {
        "label": "Vipava",
        "value": "SI-136"
      },
      {
        "label": "Vitanje",
        "value": "SI-137"
      },
      {
        "label": "Vodice",
        "value": "SI-138"
      },
      {
        "label": "Vojnik",
        "value": "SI-139"
      },
      {
        "label": "Vransko",
        "value": "SI-189"
      },
      {
        "label": "Vrhnika",
        "value": "SI-140"
      },
      {
        "label": "Vuzenica",
        "value": "SI-141"
      },
      {
        "label": "Zagorje ob Savi",
        "value": "SI-142"
      },
      {
        "label": "Zalec",
        "value": "SI-190"
      },
      {
        "label": "Zavrc",
        "value": "SI-143"
      },
      {
        "label": "Zelezniki",
        "value": "SI-146"
      },
      {
        "label": "Zetale",
        "value": "SI-191"
      },
      {
        "label": "Ziri",
        "value": "SI-147"
      },
      {
        "label": "Zrece",
        "value": "SI-144"
      },
      {
        "label": "Zuzemberk",
        "value": "SI-193"
      }
    ],
    "value": "SI"
  },
  {
    "label": "Solomon Islands",
    "options": [
      {
        "label": "Guadalcanal",
        "value": "SB-GU"
      },
      {
        "label": "Makira-Ulawa",
        "value": "SB-MK"
      },
      {
        "label": "Western",
        "value": "SB-WE"
      }
    ],
    "value": "SB"
  },
  {
    "label": "Somalia",
    "options": [
      {
        "label": "Awdal",
        "value": "SO-AW"
      },
      {
        "label": "Banaadir",
        "value": "SO-BN"
      },
      {
        "label": "Bari",
        "value": "SO-BR"
      },
      {
        "label": "Bay",
        "value": "SO-BY"
      },
      {
        "label": "Galguduud",
        "value": "SO-GA"
      },
      {
        "label": "Hiiraan",
        "value": "SO-HI"
      },
      {
        "label": "Jubbada Hoose",
        "value": "SO-JH"
      },
      {
        "label": "Mudug",
        "value": "SO-MU"
      },
      {
        "label": "Nugaal",
        "value": "SO-NU"
      },
      {
        "label": "Sanaag",
        "value": "SO-SA"
      },
      {
        "label": "Sool",
        "value": "SO-SO"
      },
      {
        "label": "Togdheer",
        "value": "SO-TO"
      },
      {
        "label": "Woqooyi Galbeed",
        "value": "SO-WO"
      }
    ],
    "value": "SO"
  },
  {
    "label": "South Africa",
    "options": [
      {
        "label": "Eastern Cape",
        "value": "ZA-EC"
      },
      {
        "label": "Free State",
        "value": "ZA-FS"
      },
      {
        "label": "Gauteng",
        "value": "ZA-GP"
      },
      {
        "label": "Kwazulu-Natal",
        "value": "ZA-KZN"
      },
      {
        "label": "Limpopo",
        "value": "ZA-LP"
      },
      {
        "label": "Mpumalanga",
        "value": "ZA-MP"
      },
      {
        "label": "North-West",
        "value": "ZA-NW"
      },
      {
        "label": "Northern Cape",
        "value": "ZA-NC"
      },
      {
        "label": "Western Cape",
        "value": "ZA-WC"
      }
    ],
    "value": "ZA"
  },
  {
    "label": "South Georgia and the South Sandwich Islands",
    "value": "GS"
  },
  {
    "label": "South Sudan",
    "options": [
      {
        "label": "Central Equatoria",
        "value": "SS-EC"
      },
      {
        "label": "Eastern Equatoria",
        "value": "SS-EE"
      },
      {
        "label": "Jonglei",
        "value": "SS-JG"
      },
      {
        "label": "Northern Bahr el Ghazal",
        "value": "SS-BN"
      },
      {
        "label": "Unity",
        "value": "SS-UY"
      },
      {
        "label": "Upper Nile",
        "value": "SS-NU"
      },
      {
        "label": "Warrap",
        "value": "SS-WR"
      },
      {
        "label": "Western Bahr el Ghazal",
        "value": "SS-BW"
      },
      {
        "label": "Western Equatoria",
        "value": "SS-EW"
      }
    ],
    "value": "SS"
  },
  {
    "label": "Spain",
    "options": [
      {
        "label": "Andalucia",
        "value": "ES-AN"
      },
      {
        "label": "Aragon",
        "value": "ES-AR"
      },
      {
        "label": "Asturias, Principado de",
        "value": "ES-AS"
      },
      {
        "label": "Canarias",
        "value": "ES-CN"
      },
      {
        "label": "Cantabria",
        "value": "ES-CB"
      },
      {
        "label": "Castilla y Leon",
        "value": "ES-CL"
      },
      {
        "label": "Castilla-La Mancha",
        "value": "ES-CM"
      },
      {
        "label": "Catalunya",
        "value": "ES-CT"
      },
      {
        "label": "Ceuta",
        "value": "ES-CE"
      },
      {
        "label": "Extremadura",
        "value": "ES-EX"
      },
      {
        "label": "Galicia",
        "value": "ES-GA"
      },
      {
        "label": "Illes Balears",
        "value": "ES-IB"
      },
      {
        "label": "La Rioja",
        "value": "ES-RI"
      },
      {
        "label": "Madrid, Comunidad de",
        "value": "ES-MD"
      },
      {
        "label": "Melilla",
        "value": "ES-ML"
      },
      {
        "label": "Murcia, Region de",
        "value": "ES-MC"
      },
      {
        "label": "Navarra, Comunidad Foral de",
        "value": "ES-NC"
      },
      {
        "label": "Pais Vasco",
        "value": "ES-PV"
      },
      {
        "label": "Valenciana, Comunidad",
        "value": "ES-VC"
      }
    ],
    "value": "ES"
  },
  {
    "label": "Sri Lanka",
    "options": [
      {
        "label": "Central Province",
        "value": "LK-2"
      },
      {
        "label": "Eastern Province",
        "value": "LK-5"
      },
      {
        "label": "North Central Province",
        "value": "LK-7"
      },
      {
        "label": "North Western Province",
        "value": "LK-6"
      },
      {
        "label": "Northern Province",
        "value": "LK-4"
      },
      {
        "label": "Sabaragamuwa Province",
        "value": "LK-9"
      },
      {
        "label": "Southern Province",
        "value": "LK-3"
      },
      {
        "label": "Uva Province",
        "value": "LK-8"
      },
      {
        "label": "Western Province",
        "value": "LK-1"
      }
    ],
    "value": "LK"
  },
  {
    "label": "Sudan",
    "options": [
      {
        "label": "Blue Nile",
        "value": "SD-NB"
      },
      {
        "label": "Central Darfur",
        "value": "SD-DC"
      },
      {
        "label": "Gedaref",
        "value": "SD-GD"
      },
      {
        "label": "Gezira",
        "value": "SD-GZ"
      },
      {
        "label": "Kassala",
        "value": "SD-KA"
      },
      {
        "label": "Khartoum",
        "value": "SD-KH"
      },
      {
        "label": "North Darfur",
        "value": "SD-DN"
      },
      {
        "label": "North Kordofan",
        "value": "SD-KN"
      },
      {
        "label": "Northern",
        "value": "SD-NO"
      },
      {
        "label": "Red Sea",
        "value": "SD-RS"
      },
      {
        "label": "River Nile",
        "value": "SD-NR"
      },
      {
        "label": "Sennar",
        "value": "SD-SI"
      },
      {
        "label": "South Darfur",
        "value": "SD-DS"
      },
      {
        "label": "South Kordofan",
        "value": "SD-KS"
      },
      {
        "label": "West Darfur",
        "value": "SD-DW"
      },
      {
        "label": "West Kordofan",
        "value": "SD-GK"
      },
      {
        "label": "White Nile",
        "value": "SD-NW"
      }
    ],
    "value": "SD"
  },
  {
    "label": "Suriname",
    "options": [
      {
        "label": "Brokopondo",
        "value": "SR-BR"
      },
      {
        "label": "Commewijne",
        "value": "SR-CM"
      },
      {
        "label": "Coronie",
        "value": "SR-CR"
      },
      {
        "label": "Nickerie",
        "value": "SR-NI"
      },
      {
        "label": "Para",
        "value": "SR-PR"
      },
      {
        "label": "Paramaribo",
        "value": "SR-PM"
      },
      {
        "label": "Saramacca",
        "value": "SR-SA"
      },
      {
        "label": "Sipaliwini",
        "value": "SR-SI"
      },
      {
        "label": "Wanica",
        "value": "SR-WA"
      }
    ],
    "value": "SR"
  },
  {
    "label": "Svalbard and Jan Mayen",
    "value": "SJ"
  },
  {
    "label": "Sweden",
    "options": [
      {
        "label": "Blekinge lan",
        "value": "SE-K"
      },
      {
        "label": "Dalarnas lan",
        "value": "SE-W"
      },
      {
        "label": "Gavleborgs lan",
        "value": "SE-X"
      },
      {
        "label": "Gotlands lan",
        "value": "SE-I"
      },
      {
        "label": "Hallands lan",
        "value": "SE-N"
      },
      {
        "label": "Jamtlands lan",
        "value": "SE-Z"
      },
      {
        "label": "Jonkopings lan",
        "value": "SE-F"
      },
      {
        "label": "Kalmar lan",
        "value": "SE-H"
      },
      {
        "label": "Kronobergs lan",
        "value": "SE-G"
      },
      {
        "label": "Norrbottens lan",
        "value": "SE-BD"
      },
      {
        "label": "Orebro lan",
        "value": "SE-T"
      },
      {
        "label": "Ostergotlands lan",
        "value": "SE-E"
      },
      {
        "label": "Skane lan",
        "value": "SE-M"
      },
      {
        "label": "Sodermanlands lan",
        "value": "SE-D"
      },
      {
        "label": "Stockholms lan",
        "value": "SE-AB"
      },
      {
        "label": "Uppsala lan",
        "value": "SE-C"
      },
      {
        "label": "Varmlands lan",
        "value": "SE-S"
      },
      {
        "label": "Vasterbottens lan",
        "value": "SE-AC"
      },
      {
        "label": "Vasternorrlands lan",
        "value": "SE-Y"
      },
      {
        "label": "Vastmanlands lan",
        "value": "SE-U"
      },
      {
        "label": "Vastra Gotalands lan",
        "value": "SE-O"
      }
    ],
    "value": "SE"
  },
  {
    "label": "Switzerland",
    "options": [
      {
        "label": "Aargau",
        "value": "CH-AG"
      },
      {
        "label": "Appenzell Ausserrhoden",
        "value": "CH-AR"
      },
      {
        "label": "Appenzell Innerrhoden",
        "value": "CH-AI"
      },
      {
        "label": "Basel-Landschaft",
        "value": "CH-BL"
      },
      {
        "label": "Basel-Stadt",
        "value": "CH-BS"
      },
      {
        "label": "Bern",
        "value": "CH-BE"
      },
      {
        "label": "Fribourg",
        "value": "CH-FR"
      },
      {
        "label": "Geneve",
        "value": "CH-GE"
      },
      {
        "label": "Glarus",
        "value": "CH-GL"
      },
      {
        "label": "Graubunden",
        "value": "CH-GR"
      },
      {
        "label": "Jura",
        "value": "CH-JU"
      },
      {
        "label": "Luzern",
        "value": "CH-LU"
      },
      {
        "label": "Neuchatel",
        "value": "CH-NE"
      },
      {
        "label": "Nidwalden",
        "value": "CH-NW"
      },
      {
        "label": "Obwalden",
        "value": "CH-OW"
      },
      {
        "label": "Sankt Gallen",
        "value": "CH-SG"
      },
      {
        "label": "Schaffhausen",
        "value": "CH-SH"
      },
      {
        "label": "Schwyz",
        "value": "CH-SZ"
      },
      {
        "label": "Solothurn",
        "value": "CH-SO"
      },
      {
        "label": "Thurgau",
        "value": "CH-TG"
      },
      {
        "label": "Ticino",
        "value": "CH-TI"
      },
      {
        "label": "Uri",
        "value": "CH-UR"
      },
      {
        "label": "Valais",
        "value": "CH-VS"
      },
      {
        "label": "Vaud",
        "value": "CH-VD"
      },
      {
        "label": "Zug",
        "value": "CH-ZG"
      },
      {
        "label": "Zurich",
        "value": "CH-ZH"
      }
    ],
    "value": "CH"
  },
  {
    "label": "Syrian Arab Republic",
    "options": [
      {
        "label": "Al Hasakah",
        "value": "SY-HA"
      },
      {
        "label": "Al Ladhiqiyah",
        "value": "SY-LA"
      },
      {
        "label": "Al Qunaytirah",
        "value": "SY-QU"
      },
      {
        "label": "Ar Raqqah",
        "value": "SY-RA"
      },
      {
        "label": "As Suwayda'",
        "value": "SY-SU"
      },
      {
        "label": "Dar'a",
        "value": "SY-DR"
      },
      {
        "label": "Dayr az Zawr",
        "value": "SY-DY"
      },
      {
        "label": "Dimashq",
        "value": "SY-DI"
      },
      {
        "label": "Halab",
        "value": "SY-HL"
      },
      {
        "label": "Hamah",
        "value": "SY-HM"
      },
      {
        "label": "Hims",
        "value": "SY-HI"
      },
      {
        "label": "Idlib",
        "value": "SY-ID"
      },
      {
        "label": "Rif Dimashq",
        "value": "SY-RD"
      },
      {
        "label": "Tartus",
        "value": "SY-TA"
      }
    ],
    "value": "SY"
  },
  {
    "label": "Taiwan, Province of China",
    "options": [
      {
        "label": "Changhua",
        "value": "TW-CHA"
      },
      {
        "label": "Chiayi",
        "value": "TW-CYQ"
      },
      {
        "label": "Hsinchu",
        "value": "TW-HSQ"
      },
      {
        "label": "Hualien",
        "value": "TW-HUA"
      },
      {
        "label": "Kaohsiung",
        "value": "TW-KHH"
      },
      {
        "label": "Keelung",
        "value": "TW-KEE"
      },
      {
        "label": "Kinmen",
        "value": "TW-KIN"
      },
      {
        "label": "Lienchiang",
        "value": "TW-LIE"
      },
      {
        "label": "Miaoli",
        "value": "TW-MIA"
      },
      {
        "label": "Nantou",
        "value": "TW-NAN"
      },
      {
        "label": "New Taipei",
        "value": "TW-NWT"
      },
      {
        "label": "Penghu",
        "value": "TW-PEN"
      },
      {
        "label": "Pingtung",
        "value": "TW-PIF"
      },
      {
        "label": "Taichung",
        "value": "TW-TXG"
      },
      {
        "label": "Tainan",
        "value": "TW-TNN"
      },
      {
        "label": "Taipei",
        "value": "TW-TPE"
      },
      {
        "label": "Taitung",
        "value": "TW-TTT"
      },
      {
        "label": "Taoyuan",
        "value": "TW-TAO"
      },
      {
        "label": "Yilan",
        "value": "TW-ILA"
      },
      {
        "label": "Yunlin",
        "value": "TW-YUN"
      }
    ],
    "value": "TW"
  },
  {
    "label": "Tajikistan",
    "options": [
      {
        "label": "Dushanbe",
        "value": "TJ-DU"
      },
      {
        "label": "Khatlon",
        "value": "TJ-KT"
      },
      {
        "label": "Kuhistoni Badakhshon",
        "value": "TJ-GB"
      },
      {
        "label": "Nohiyahoi Tobei Jumhuri",
        "value": "TJ-RA"
      },
      {
        "label": "Sughd",
        "value": "TJ-SU"
      }
    ],
    "value": "TJ"
  },
  {
    "label": "Tanzania, United Republic of",
    "options": [
      {
        "label": "Arusha",
        "value": "TZ-01"
      },
      {
        "label": "Dar es Salaam",
        "value": "TZ-02"
      },
      {
        "label": "Dodoma",
        "value": "TZ-03"
      },
      {
        "label": "Geita",
        "value": "TZ-27"
      },
      {
        "label": "Iringa",
        "value": "TZ-04"
      },
      {
        "label": "Kagera",
        "value": "TZ-05"
      },
      {
        "label": "Kaskazini Pemba",
        "value": "TZ-06"
      },
      {
        "label": "Kaskazini Unguja",
        "value": "TZ-07"
      },
      {
        "label": "Katavi",
        "value": "TZ-28"
      },
      {
        "label": "Kigoma",
        "value": "TZ-08"
      },
      {
        "label": "Kilimanjaro",
        "value": "TZ-09"
      },
      {
        "label": "Kusini Pemba",
        "value": "TZ-10"
      },
      {
        "label": "Kusini Unguja",
        "value": "TZ-11"
      },
      {
        "label": "Lindi",
        "value": "TZ-12"
      },
      {
        "label": "Manyara",
        "value": "TZ-26"
      },
      {
        "label": "Mara",
        "value": "TZ-13"
      },
      {
        "label": "Mbeya",
        "value": "TZ-14"
      },
      {
        "label": "Mjini Magharibi",
        "value": "TZ-15"
      },
      {
        "label": "Morogoro",
        "value": "TZ-16"
      },
      {
        "label": "Mtwara",
        "value": "TZ-17"
      },
      {
        "label": "Mwanza",
        "value": "TZ-18"
      },
      {
        "label": "Njombe",
        "value": "TZ-29"
      },
      {
        "label": "Pwani",
        "value": "TZ-19"
      },
      {
        "label": "Rukwa",
        "value": "TZ-20"
      },
      {
        "label": "Ruvuma",
        "value": "TZ-21"
      },
      {
        "label": "Shinyanga",
        "value": "TZ-22"
      },
      {
        "label": "Simiyu",
        "value": "TZ-30"
      },
      {
        "label": "Singida",
        "value": "TZ-23"
      },
      {
        "label": "Songwe",
        "value": "TZ-31"
      },
      {
        "label": "Tabora",
        "value": "TZ-24"
      },
      {
        "label": "Tanga",
        "value": "TZ-25"
      }
    ],
    "value": "TZ"
  },
  {
    "label": "Thailand",
    "options": [
      {
        "label": "Amnat Charoen",
        "value": "TH-37"
      },
      {
        "label": "Ang Thong",
        "value": "TH-15"
      },
      {
        "label": "Bueng Kan",
        "value": "TH-38"
      },
      {
        "label": "Buri Ram",
        "value": "TH-31"
      },
      {
        "label": "Chachoengsao",
        "value": "TH-24"
      },
      {
        "label": "Chai Nat",
        "value": "TH-18"
      },
      {
        "label": "Chaiyaphum",
        "value": "TH-36"
      },
      {
        "label": "Chanthaburi",
        "value": "TH-22"
      },
      {
        "label": "Chiang Mai",
        "value": "TH-50"
      },
      {
        "label": "Chiang Rai",
        "value": "TH-57"
      },
      {
        "label": "Chon Buri",
        "value": "TH-20"
      },
      {
        "label": "Chumphon",
        "value": "TH-86"
      },
      {
        "label": "Kalasin",
        "value": "TH-46"
      },
      {
        "label": "Kamphaeng Phet",
        "value": "TH-62"
      },
      {
        "label": "Kanchanaburi",
        "value": "TH-71"
      },
      {
        "label": "Khon Kaen",
        "value": "TH-40"
      },
      {
        "label": "Krabi",
        "value": "TH-81"
      },
      {
        "label": "Krung Thep Maha Nakhon",
        "value": "TH-10"
      },
      {
        "label": "Lampang",
        "value": "TH-52"
      },
      {
        "label": "Lamphun",
        "value": "TH-51"
      },
      {
        "label": "Loei",
        "value": "TH-42"
      },
      {
        "label": "Lop Buri",
        "value": "TH-16"
      },
      {
        "label": "Mae Hong Son",
        "value": "TH-58"
      },
      {
        "label": "Maha Sarakham",
        "value": "TH-44"
      },
      {
        "label": "Mukdahan",
        "value": "TH-49"
      },
      {
        "label": "Nakhon Nayok",
        "value": "TH-26"
      },
      {
        "label": "Nakhon Pathom",
        "value": "TH-73"
      },
      {
        "label": "Nakhon Phanom",
        "value": "TH-48"
      },
      {
        "label": "Nakhon Ratchasima",
        "value": "TH-30"
      },
      {
        "label": "Nakhon Sawan",
        "value": "TH-60"
      },
      {
        "label": "Nakhon Si Thammarat",
        "value": "TH-80"
      },
      {
        "label": "Nan",
        "value": "TH-55"
      },
      {
        "label": "Narathiwat",
        "value": "TH-96"
      },
      {
        "label": "Nong Bua Lam Phu",
        "value": "TH-39"
      },
      {
        "label": "Nong Khai",
        "value": "TH-43"
      },
      {
        "label": "Nonthaburi",
        "value": "TH-12"
      },
      {
        "label": "Pathum Thani",
        "value": "TH-13"
      },
      {
        "label": "Pattani",
        "value": "TH-94"
      },
      {
        "label": "Phangnga",
        "value": "TH-82"
      },
      {
        "label": "Phatthalung",
        "value": "TH-93"
      },
      {
        "label": "Phayao",
        "value": "TH-56"
      },
      {
        "label": "Phetchabun",
        "value": "TH-67"
      },
      {
        "label": "Phetchaburi",
        "value": "TH-76"
      },
      {
        "label": "Phichit",
        "value": "TH-66"
      },
      {
        "label": "Phitsanulok",
        "value": "TH-65"
      },
      {
        "label": "Phra Nakhon Si Ayutthaya",
        "value": "TH-14"
      },
      {
        "label": "Phrae",
        "value": "TH-54"
      },
      {
        "label": "Phuket",
        "value": "TH-83"
      },
      {
        "label": "Prachin Buri",
        "value": "TH-25"
      },
      {
        "label": "Prachuap Khiri Khan",
        "value": "TH-77"
      },
      {
        "label": "Ranong",
        "value": "TH-85"
      },
      {
        "label": "Ratchaburi",
        "value": "TH-70"
      },
      {
        "label": "Rayong",
        "value": "TH-21"
      },
      {
        "label": "Roi Et",
        "value": "TH-45"
      },
      {
        "label": "Sa Kaeo",
        "value": "TH-27"
      },
      {
        "label": "Sakon Nakhon",
        "value": "TH-47"
      },
      {
        "label": "Samut Prakan",
        "value": "TH-11"
      },
      {
        "label": "Samut Sakhon",
        "value": "TH-74"
      },
      {
        "label": "Samut Songkhram",
        "value": "TH-75"
      },
      {
        "label": "Saraburi",
        "value": "TH-19"
      },
      {
        "label": "Satun",
        "value": "TH-91"
      },
      {
        "label": "Si Sa Ket",
        "value": "TH-33"
      },
      {
        "label": "Sing Buri",
        "value": "TH-17"
      },
      {
        "label": "Songkhla",
        "value": "TH-90"
      },
      {
        "label": "Sukhothai",
        "value": "TH-64"
      },
      {
        "label": "Suphan Buri",
        "value": "TH-72"
      },
      {
        "label": "Surat Thani",
        "value": "TH-84"
      },
      {
        "label": "Surin",
        "value": "TH-32"
      },
      {
        "label": "Tak",
        "value": "TH-63"
      },
      {
        "label": "Trang",
        "value": "TH-92"
      },
      {
        "label": "Trat",
        "value": "TH-23"
      },
      {
        "label": "Ubon Ratchathani",
        "value": "TH-34"
      },
      {
        "label": "Udon Thani",
        "value": "TH-41"
      },
      {
        "label": "Uthai Thani",
        "value": "TH-61"
      },
      {
        "label": "Uttaradit",
        "value": "TH-53"
      },
      {
        "label": "Yala",
        "value": "TH-95"
      },
      {
        "label": "Yasothon",
        "value": "TH-35"
      }
    ],
    "value": "TH"
  },
  {
    "label": "Timor-Leste",
    "options": [
      {
        "label": "Ainaro",
        "value": "TL-AN"
      },
      {
        "label": "Bobonaro",
        "value": "TL-BO"
      },
      {
        "label": "Dili",
        "value": "TL-DI"
      },
      {
        "label": "Ermera",
        "value": "TL-ER"
      },
      {
        "label": "Liquica",
        "value": "TL-LI"
      },
      {
        "label": "Manatuto",
        "value": "TL-MT"
      },
      {
        "label": "Viqueque",
        "value": "TL-VI"
      }
    ],
    "value": "TL"
  },
  {
    "label": "Togo",
    "options": [
      {
        "label": "Centrale",
        "value": "TG-C"
      },
      {
        "label": "Kara",
        "value": "TG-K"
      },
      {
        "label": "Maritime",
        "value": "TG-M"
      },
      {
        "label": "Plateaux",
        "value": "TG-P"
      },
      {
        "label": "Savanes",
        "value": "TG-S"
      }
    ],
    "value": "TG"
  },
  {
    "label": "Tokelau",
    "value": "TK"
  },
  {
    "label": "Tonga",
    "options": [
      {
        "label": "'Eua",
        "value": "TO-01"
      },
      {
        "label": "Ha'apai",
        "value": "TO-02"
      },
      {
        "label": "Niuas",
        "value": "TO-03"
      },
      {
        "label": "Tongatapu",
        "value": "TO-04"
      },
      {
        "label": "Vava'u",
        "value": "TO-05"
      }
    ],
    "value": "TO"
  },
  {
    "label": "Trinidad and Tobago",
    "options": [
      {
        "label": "Arima",
        "value": "TT-ARI"
      },
      {
        "label": "Chaguanas",
        "value": "TT-CHA"
      },
      {
        "label": "Couva-Tabaquite-Talparo",
        "value": "TT-CTT"
      },
      {
        "label": "Diego Martin",
        "value": "TT-DMN"
      },
      {
        "label": "Mayaro-Rio Claro",
        "value": "TT-MRC"
      },
      {
        "label": "Penal-Debe",
        "value": "TT-PED"
      },
      {
        "label": "Point Fortin",
        "value": "TT-PTF"
      },
      {
        "label": "Port of Spain",
        "value": "TT-POS"
      },
      {
        "label": "Princes Town",
        "value": "TT-PRT"
      },
      {
        "label": "San Fernando",
        "value": "TT-SFO"
      },
      {
        "label": "San Juan-Laventille",
        "value": "TT-SJL"
      },
      {
        "label": "Sangre Grande",
        "value": "TT-SGE"
      },
      {
        "label": "Siparia",
        "value": "TT-SIP"
      },
      {
        "label": "Tobago",
        "value": "TT-TOB"
      },
      {
        "label": "Tunapuna-Piarco",
        "value": "TT-TUP"
      }
    ],
    "value": "TT"
  },
  {
    "label": "Tunisia",
    "options": [
      {
        "label": "Beja",
        "value": "TN-31"
      },
      {
        "label": "Ben Arous",
        "value": "TN-13"
      },
      {
        "label": "Bizerte",
        "value": "TN-23"
      },
      {
        "label": "Gabes",
        "value": "TN-81"
      },
      {
        "label": "Gafsa",
        "value": "TN-71"
      },
      {
        "label": "Jendouba",
        "value": "TN-32"
      },
      {
        "label": "Kairouan",
        "value": "TN-41"
      },
      {
        "label": "Kasserine",
        "value": "TN-42"
      },
      {
        "label": "Kebili",
        "value": "TN-73"
      },
      {
        "label": "L'Ariana",
        "value": "TN-12"
      },
      {
        "label": "La Manouba",
        "value": "TN-14"
      },
      {
        "label": "Le Kef",
        "value": "TN-33"
      },
      {
        "label": "Mahdia",
        "value": "TN-53"
      },
      {
        "label": "Medenine",
        "value": "TN-82"
      },
      {
        "label": "Monastir",
        "value": "TN-52"
      },
      {
        "label": "Nabeul",
        "value": "TN-21"
      },
      {
        "label": "Sfax",
        "value": "TN-61"
      },
      {
        "label": "Sidi Bouzid",
        "value": "TN-43"
      },
      {
        "label": "Siliana",
        "value": "TN-34"
      },
      {
        "label": "Sousse",
        "value": "TN-51"
      },
      {
        "label": "Tataouine",
        "value": "TN-83"
      },
      {
        "label": "Tozeur",
        "value": "TN-72"
      },
      {
        "label": "Tunis",
        "value": "TN-11"
      },
      {
        "label": "Zaghouan",
        "value": "TN-22"
      }
    ],
    "value": "TN"
  },
  {
    "label": "Türkiye",
    "options": [
      {
        "label": "Adana",
        "value": "TR-01"
      },
      {
        "label": "Adiyaman",
        "value": "TR-02"
      },
      {
        "label": "Afyonkarahisar",
        "value": "TR-03"
      },
      {
        "label": "Agri",
        "value": "TR-04"
      },
      {
        "label": "Aksaray",
        "value": "TR-68"
      },
      {
        "label": "Amasya",
        "value": "TR-05"
      },
      {
        "label": "Ankara",
        "value": "TR-06"
      },
      {
        "label": "Antalya",
        "value": "TR-07"
      },
      {
        "label": "Ardahan",
        "value": "TR-75"
      },
      {
        "label": "Artvin",
        "value": "TR-08"
      },
      {
        "label": "Aydin",
        "value": "TR-09"
      },
      {
        "label": "Balikesir",
        "value": "TR-10"
      },
      {
        "label": "Bartin",
        "value": "TR-74"
      },
      {
        "label": "Batman",
        "value": "TR-72"
      },
      {
        "label": "Bayburt",
        "value": "TR-69"
      },
      {
        "label": "Bilecik",
        "value": "TR-11"
      },
      {
        "label": "Bingol",
        "value": "TR-12"
      },
      {
        "label": "Bitlis",
        "value": "TR-13"
      },
      {
        "label": "Bolu",
        "value": "TR-14"
      },
      {
        "label": "Burdur",
        "value": "TR-15"
      },
      {
        "label": "Bursa",
        "value": "TR-16"
      },
      {
        "label": "Canakkale",
        "value": "TR-17"
      },
      {
        "label": "Cankiri",
        "value": "TR-18"
      },
      {
        "label": "Corum",
        "value": "TR-19"
      },
      {
        "label": "Denizli",
        "value": "TR-20"
      },
      {
        "label": "Diyarbakir",
        "value": "TR-21"
      },
      {
        "label": "Duzce",
        "value": "TR-81"
      },
      {
        "label": "Edirne",
        "value": "TR-22"
      },
      {
        "label": "Elazig",
        "value": "TR-23"
      },
      {
        "label": "Erzincan",
        "value": "TR-24"
      },
      {
        "label": "Erzurum",
        "value": "TR-25"
      },
      {
        "label": "Eskisehir",
        "value": "TR-26"
      },
      {
        "label": "Gaziantep",
        "value": "TR-27"
      },
      {
        "label": "Giresun",
        "value": "TR-28"
      },
      {
        "label": "Gumushane",
        "value": "TR-29"
      },
      {
        "label": "Hakkari",
        "value": "TR-30"
      },
      {
        "label": "Hatay",
        "value": "TR-31"
      },
      {
        "label": "Igdir",
        "value": "TR-76"
      },
      {
        "label": "Isparta",
        "value": "TR-32"
      },
      {
        "label": "Istanbul",
        "value": "TR-34"
      },
      {
        "label": "Izmir",
        "value": "TR-35"
      },
      {
        "label": "Kahramanmaras",
        "value": "TR-46"
      },
      {
        "label": "Karabuk",
        "value": "TR-78"
      },
      {
        "label": "Karaman",
        "value": "TR-70"
      },
      {
        "label": "Kars",
        "value": "TR-36"
      },
      {
        "label": "Kastamonu",
        "value": "TR-37"
      },
      {
        "label": "Kayseri",
        "value": "TR-38"
      },
      {
        "label": "Kilis",
        "value": "TR-79"
      },
      {
        "label": "Kirikkale",
        "value": "TR-71"
      },
      {
        "label": "Kirklareli",
        "value": "TR-39"
      },
      {
        "label": "Kirsehir",
        "value": "TR-40"
      },
      {
        "label": "Kocaeli",
        "value": "TR-41"
      },
      {
        "label": "Konya",
        "value": "TR-42"
      },
      {
        "label": "Kutahya",
        "value": "TR-43"
      },
      {
        "label": "Malatya",
        "value": "TR-44"
      },
      {
        "label": "Manisa",
        "value": "TR-45"
      },
      {
        "label": "Mardin",
        "value": "TR-47"
      },
      {
        "label": "Mersin",
        "value": "TR-33"
      },
      {
        "label": "Mugla",
        "value": "TR-48"
      },
      {
        "label": "Mus",
        "value": "TR-49"
      },
      {
        "label": "Nevsehir",
        "value": "TR-50"
      },
      {
        "label": "Nigde",
        "value": "TR-51"
      },
      {
        "label": "Ordu",
        "value": "TR-52"
      },
      {
        "label": "Osmaniye",
        "value": "TR-80"
      },
      {
        "label": "Rize",
        "value": "TR-53"
      },
      {
        "label": "Sakarya",
        "value": "TR-54"
      },
      {
        "label": "Samsun",
        "value": "TR-55"
      },
      {
        "label": "Sanliurfa",
        "value": "TR-63"
      },
      {
        "label": "Siirt",
        "value": "TR-56"
      },
      {
        "label": "Sinop",
        "value": "TR-57"
      },
      {
        "label": "Sirnak",
        "value": "TR-73"
      },
      {
        "label": "Sivas",
        "value": "TR-58"
      },
      {
        "label": "Tekirdag",
        "value": "TR-59"
      },
      {
        "label": "Tokat",
        "value": "TR-60"
      },
      {
        "label": "Trabzon",
        "value": "TR-61"
      },
      {
        "label": "Tunceli",
        "value": "TR-62"
      },
      {
        "label": "Usak",
        "value": "TR-64"
      },
      {
        "label": "Van",
        "value": "TR-65"
      },
      {
        "label": "Yalova",
        "value": "TR-77"
      },
      {
        "label": "Yozgat",
        "value": "TR-66"
      },
      {
        "label": "Zonguldak",
        "value": "TR-67"
      }
    ],
    "value": "TR"
  },
  {
    "label": "Turkmenistan",
    "options": [
      {
        "label": "Ahal",
        "value": "TM-A"
      },
      {
        "label": "Balkan",
        "value": "TM-B"
      },
      {
        "label": "Dasoguz",
        "value": "TM-D"
      },
      {
        "label": "Lebap",
        "value": "TM-L"
      },
      {
        "label": "Mary",
        "value": "TM-M"
      }
    ],
    "value": "TM"
  },
  {
    "label": "Turks and Caicos Islands",
    "value": "TC"
  },
  {
    "label": "Tuvalu",
    "options": [
      {
        "label": "Funafuti",
        "value": "TV-FUN"
      }
    ],
    "value": "TV"
  },
  {
    "label": "Uganda",
    "options": [
      {
        "label": "Abim",
        "value": "UG-314"
      },
      {
        "label": "Adjumani",
        "value": "UG-301"
      },
      {
        "label": "Agago",
        "value": "UG-322"
      },
      {
        "label": "Alebtong",
        "value": "UG-323"
      },
      {
        "label": "Apac",
        "value": "UG-302"
      },
      {
        "label": "Arua",
        "value": "UG-303"
      },
      {
        "label": "Bududa",
        "value": "UG-218"
      },
      {
        "label": "Bugiri",
        "value": "UG-201"
      },
      {
        "label": "Buhweju",
        "value": "UG-420"
      },
      {
        "label": "Buikwe",
        "value": "UG-117"
      },
      {
        "label": "Bukedea",
        "value": "UG-219"
      },
      {
        "label": "Bukomansibi",
        "value": "UG-118"
      },
      {
        "label": "Bulambuli",
        "value": "UG-225"
      },
      {
        "label": "Buliisa",
        "value": "UG-416"
      },
      {
        "label": "Bundibugyo",
        "value": "UG-401"
      },
      {
        "label": "Bushenyi",
        "value": "UG-402"
      },
      {
        "label": "Busia",
        "value": "UG-202"
      },
      {
        "label": "Buvuma",
        "value": "UG-120"
      },
      {
        "label": "Buyende",
        "value": "UG-226"
      },
      {
        "label": "Gomba",
        "value": "UG-121"
      },
      {
        "label": "Gulu",
        "value": "UG-304"
      },
      {
        "label": "Hoima",
        "value": "UG-403"
      },
      {
        "label": "Ibanda",
        "value": "UG-417"
      },
      {
        "label": "Iganga",
        "value": "UG-203"
      },
      {
        "label": "Isingiro",
        "value": "UG-418"
      },
      {
        "label": "Jinja",
        "value": "UG-204"
      },
      {
        "label": "Kaabong",
        "value": "UG-318"
      },
      {
        "label": "Kabale",
        "value": "UG-404"
      },
      {
        "label": "Kabarole",
        "value": "UG-405"
      },
      {
        "label": "Kaberamaido",
        "value": "UG-213"
      },
      {
        "label": "Kalangala",
        "value": "UG-101"
      },
      {
        "label": "Kaliro",
        "value": "UG-222"
      },
      {
        "label": "Kalungu",
        "value": "UG-122"
      },
      {
        "label": "Kampala",
        "value": "UG-102"
      },
      {
        "label": "Kamuli",
        "value": "UG-205"
      },
      {
        "label": "Kamwenge",
        "value": "UG-413"
      },
      {
        "label": "Kanungu",
        "value": "UG-414"
      },
      {
        "label": "Kapchorwa",
        "value": "UG-206"
      },
      {
        "label": "Kasese",
        "value": "UG-406"
      },
      {
        "label": "Katakwi",
        "value": "UG-207"
      },
      {
        "label": "Kayunga",
        "value": "UG-112"
      },
      {
        "label": "Kibaale",
        "value": "UG-407"
      },
      {
        "label": "Kiboga",
        "value": "UG-103"
      },
      {
        "label": "Kibuku",
        "value": "UG-227"
      },
      {
        "label": "Kiruhura",
        "value": "UG-419"
      },
      {
        "label": "Kiryandongo",
        "value": "UG-421"
      },
      {
        "label": "Kisoro",
        "value": "UG-408"
      },
      {
        "label": "Kitgum",
        "value": "UG-305"
      },
      {
        "label": "Koboko",
        "value": "UG-319"
      },
      {
        "label": "Kole",
        "value": "UG-325"
      },
      {
        "label": "Kumi",
        "value": "UG-208"
      },
      {
        "label": "Kween",
        "value": "UG-228"
      },
      {
        "label": "Kyankwanzi",
        "value": "UG-123"
      },
      {
        "label": "Kyegegwa",
        "value": "UG-422"
      },
      {
        "label": "Kyenjojo",
        "value": "UG-415"
      },
      {
        "label": "Lamwo",
        "value": "UG-326"
      },
      {
        "label": "Lira",
        "value": "UG-307"
      },
      {
        "label": "Luuka",
        "value": "UG-229"
      },
      {
        "label": "Luwero",
        "value": "UG-104"
      },
      {
        "label": "Lwengo",
        "value": "UG-124"
      },
      {
        "label": "Lyantonde",
        "value": "UG-114"
      },
      {
        "label": "Manafwa",
        "value": "UG-223"
      },
      {
        "label": "Maracha",
        "value": "UG-320"
      },
      {
        "label": "Masaka",
        "value": "UG-105"
      },
      {
        "label": "Masindi",
        "value": "UG-409"
      },
      {
        "label": "Mayuge",
        "value": "UG-214"
      },
      {
        "label": "Mbale",
        "value": "UG-209"
      },
      {
        "label": "Mbarara",
        "value": "UG-410"
      },
      {
        "label": "Mitooma",
        "value": "UG-423"
      },
      {
        "label": "Mityana",
        "value": "UG-115"
      },
      {
        "label": "Moroto",
        "value": "UG-308"
      },
      {
        "label": "Moyo",
        "value": "UG-309"
      },
      {
        "label": "Mpigi",
        "value": "UG-106"
      },
      {
        "label": "Mubende",
        "value": "UG-107"
      },
      {
        "label": "Mukono",
        "value": "UG-108"
      },
      {
        "label": "Nakapiripirit",
        "value": "UG-311"
      },
      {
        "label": "Nakaseke",
        "value": "UG-116"
      },
      {
        "label": "Nakasongola",
        "value": "UG-109"
      },
      {
        "label": "Namayingo",
        "value": "UG-230"
      },
      {
        "label": "Napak",
        "value": "UG-327"
      },
      {
        "label": "Nebbi",
        "value": "UG-310"
      },
      {
        "label": "Ntoroko",
        "value": "UG-424"
      },
      {
        "label": "Ntungamo",
        "value": "UG-411"
      },
      {
        "label": "Nwoya",
        "value": "UG-328"
      },
      {
        "label": "Oyam",
        "value": "UG-321"
      },
      {
        "label": "Pader",
        "value": "UG-312"
      },
      {
        "label": "Rakai",
        "value": "UG-110"
      },
      {
        "label": "Rubirizi",
        "value": "UG-425"
      },
      {
        "label": "Rukungiri",
        "value": "UG-412"
      },
      {
        "label": "Sembabule",
        "value": "UG-111"
      },
      {
        "label": "Sheema",
        "value": "UG-426"
      },
      {
        "label": "Sironko",
        "value": "UG-215"
      },
      {
        "label": "Soroti",
        "value": "UG-211"
      },
      {
        "label": "Tororo",
        "value": "UG-212"
      },
      {
        "label": "Wakiso",
        "value": "UG-113"
      },
      {
        "label": "Yumbe",
        "value": "UG-313"
      },
      {
        "label": "Zombo",
        "value": "UG-330"
      }
    ],
    "value": "UG"
  },
  {
    "label": "Ukraine",
    "options": [
      {
        "label": "Avtonomna Respublika Krym",
        "value": "UA-43"
      },
      {
        "label": "Cherkaska oblast",
        "value": "UA-71"
      },
      {
        "label": "Chernihivska oblast",
        "value": "UA-74"
      },
      {
        "label": "Chernivetska oblast",
        "value": "UA-77"
      },
      {
        "label": "Dnipropetrovska oblast",
        "value": "UA-12"
      },
      {
        "label": "Donetska oblast",
        "value": "UA-14"
      },
      {
        "label": "Ivano-Frankivska oblast",
        "value": "UA-26"
      },
      {
        "label": "Kharkivska oblast",
        "value": "UA-63"
      },
      {
        "label": "Khersonska oblast",
        "value": "UA-65"
      },
      {
        "label": "Khmelnytska oblast",
        "value": "UA-68"
      },
      {
        "label": "Kirovohradska oblast",
        "value": "UA-35"
      },
      {
        "label": "Kyiv",
        "value": "UA-30"
      },
      {
        "label": "Kyivska oblast",
        "value": "UA-32"
      },
      {
        "label": "Luhanska oblast",
        "value": "UA-09"
      },
      {
        "label": "Lvivska oblast",
        "value": "UA-46"
      },
      {
        "label": "Mykolaivska oblast",
        "value": "UA-48"
      },
      {
        "label": "Odeska oblast",
        "value": "UA-51"
      },
      {
        "label": "Poltavska oblast",
        "value": "UA-53"
      },
      {
        "label": "Rivnenska oblast",
        "value": "UA-56"
      },
      {
        "label": "Sevastopol",
        "value": "UA-40"
      },
      {
        "label": "Sumska oblast",
        "value": "UA-59"
      },
      {
        "label": "Ternopilska oblast",
        "value": "UA-61"
      },
      {
        "label": "Vinnytska oblast",
        "value": "UA-05"
      },
      {
        "label": "Volynska oblast",
        "value": "UA-07"
      },
      {
        "label": "Zakarpatska oblast",
        "value": "UA-21"
      },
      {
        "label": "Zaporizka oblast",
        "value": "UA-23"
      },
      {
        "label": "Zhytomyrska oblast",
        "value": "UA-18"
      }
    ],
    "value": "UA"
  },
  {
    "label": "United Arab Emirates",
    "options": [
      {
        "label": "'Ajman",
        "value": "AE-AJ"
      },
      {
        "label": "Abu Zaby",
        "value": "AE-AZ"
      },
      {
        "label": "Al Fujayrah",
        "value": "AE-FU"
      },
      {
        "label": "Ash Shariqah",
        "value": "AE-SH"
      },
      {
        "label": "Dubayy",
        "value": "AE-DU"
      },
      {
        "label": "Ra's al Khaymah",
        "value": "AE-RK"
      },
      {
        "label": "Umm al Qaywayn",
        "value": "AE-UQ"
      }
    ],
    "value": "AE"
  },
  {
    "label": "United Kingdom of Great Britain and Northern Ireland",
    "options": [
      {
        "label": "England",
        "value": "GB-ENG"
      },
      {
        "label": "Northern Ireland",
        "value": "GB-NIR"
      },
      {
        "label": "Scotland",
        "value": "GB-SCT"
      },
      {
        "label": "Wales",
        "value": "GB-WLS"
      }
    ],
    "value": "GB"
  },
  {
    "label": "United States Minor Outlying Islands",
    "options": [
      {
        "label": "Palmyra Atoll",
        "value": "UM-95"
      }
    ],
    "value": "UM"
  },
  {
    "label": "United States of America",
    "options": [
      {
        "label": "Alabama",
        "value": "US-AL"
      },
      {
        "label": "Alaska",
        "value": "US-AK"
      },
      {
        "label": "Arizona",
        "value": "US-AZ"
      },
      {
        "label": "Arkansas",
        "value": "US-AR"
      },
      {
        "label": "California",
        "value": "US-CA"
      },
      {
        "label": "Colorado",
        "value": "US-CO"
      },
      {
        "label": "Connecticut",
        "value": "US-CT"
      },
      {
        "label": "Delaware",
        "value": "US-DE"
      },
      {
        "label": "District of Columbia",
        "value": "US-DC"
      },
      {
        "label": "Florida",
        "value": "US-FL"
      },
      {
        "label": "Georgia",
        "value": "US-GA"
      },
      {
        "label": "Hawaii",
        "value": "US-HI"
      },
      {
        "label": "Idaho",
        "value": "US-ID"
      },
      {
        "label": "Illinois",
        "value": "US-IL"
      },
      {
        "label": "Indiana",
        "value": "US-IN"
      },
      {
        "label": "Iowa",
        "value": "US-IA"
      },
      {
        "label": "Kansas",
        "value": "US-KS"
      },
      {
        "label": "Kentucky",
        "value": "US-KY"
      },
      {
        "label": "Louisiana",
        "value": "US-LA"
      },
      {
        "label": "Maine",
        "value": "US-ME"
      },
      {
        "label": "Maryland",
        "value": "US-MD"
      },
      {
        "label": "Massachusetts",
        "value": "US-MA"
      },
      {
        "label": "Michigan",
        "value": "US-MI"
      },
      {
        "label": "Minnesota",
        "value": "US-MN"
      },
      {
        "label": "Mississippi",
        "value": "US-MS"
      },
      {
        "label": "Missouri",
        "value": "US-MO"
      },
      {
        "label": "Montana",
        "value": "US-MT"
      },
      {
        "label": "Nebraska",
        "value": "US-NE"
      },
      {
        "label": "Nevada",
        "value": "US-NV"
      },
      {
        "label": "New Hampshire",
        "value": "US-NH"
      },
      {
        "label": "New Jersey",
        "value": "US-NJ"
      },
      {
        "label": "New Mexico",
        "value": "US-NM"
      },
      {
        "label": "New York",
        "value": "US-NY"
      },
      {
        "label": "North Carolina",
        "value": "US-NC"
      },
      {
        "label": "North Dakota",
        "value": "US-ND"
      },
      {
        "label": "Ohio",
        "value": "US-OH"
      },
      {
        "label": "Oklahoma",
        "value": "US-OK"
      },
      {
        "label": "Oregon",
        "value": "US-OR"
      },
      {
        "label": "Pennsylvania",
        "value": "US-PA"
      },
      {
        "label": "Rhode Island",
        "value": "US-RI"
      },
      {
        "label": "South Carolina",
        "value": "US-SC"
      },
      {
        "label": "South Dakota",
        "value": "US-SD"
      },
      {
        "label": "Tennessee",
        "value": "US-TN"
      },
      {
        "label": "Texas",
        "value": "US-TX"
      },
      {
        "label": "Utah",
        "value": "US-UT"
      },
      {
        "label": "Vermont",
        "value": "US-VT"
      },
      {
        "label": "Virginia",
        "value": "US-VA"
      },
      {
        "label": "Washington",
        "value": "US-WA"
      },
      {
        "label": "West Virginia",
        "value": "US-WV"
      },
      {
        "label": "Wisconsin",
        "value": "US-WI"
      },
      {
        "label": "Wyoming",
        "value": "US-WY"
      }
    ],
    "value": "US"
  },
  {
    "label": "Uruguay",
    "options": [
      {
        "label": "Artigas",
        "value": "UY-AR"
      },
      {
        "label": "Canelones",
        "value": "UY-CA"
      },
      {
        "label": "Cerro Largo",
        "value": "UY-CL"
      },
      {
        "label": "Colonia",
        "value": "UY-CO"
      },
      {
        "label": "Durazno",
        "value": "UY-DU"
      },
      {
        "label": "Flores",
        "value": "UY-FS"
      },
      {
        "label": "Florida",
        "value": "UY-FD"
      },
      {
        "label": "Lavalleja",
        "value": "UY-LA"
      },
      {
        "label": "Maldonado",
        "value": "UY-MA"
      },
      {
        "label": "Montevideo",
        "value": "UY-MO"
      },
      {
        "label": "Paysandu",
        "value": "UY-PA"
      },
      {
        "label": "Rio Negro",
        "value": "UY-RN"
      },
      {
        "label": "Rivera",
        "value": "UY-RV"
      },
      {
        "label": "Rocha",
        "value": "UY-RO"
      },
      {
        "label": "Salto",
        "value": "UY-SA"
      },
      {
        "label": "San Jose",
        "value": "UY-SJ"
      },
      {
        "label": "Soriano",
        "value": "UY-SO"
      },
      {
        "label": "Tacuarembo",
        "value": "UY-TA"
      },
      {
        "label": "Treinta y Tres",
        "value": "UY-TT"
      }
    ],
    "value": "UY"
  },
  {
    "label": "Uzbekistan",
    "options": [
      {
        "label": "Andijon",
        "value": "UZ-AN"
      },
      {
        "label": "Buxoro",
        "value": "UZ-BU"
      },
      {
        "label": "Farg'ona",
        "value": "UZ-FA"
      },
      {
        "label": "Jizzax",
        "value": "UZ-JI"
      },
      {
        "label": "Namangan",
        "value": "UZ-NG"
      },
      {
        "label": "Navoiy",
        "value": "UZ-NW"
      },
      {
        "label": "Qashqadaryo",
        "value": "UZ-QA"
      },
      {
        "label": "Qoraqalpog'iston Respublikasi",
        "value": "UZ-QR"
      },
      {
        "label": "Samarqand",
        "value": "UZ-SA"
      },
      {
        "label": "Sirdaryo",
        "value": "UZ-SI"
      },
      {
        "label": "Surxondaryo",
        "value": "UZ-SU"
      },
      {
        "label": "Toshkent",
        "value": "UZ-TK"
      },
      {
        "label": "Xorazm",
        "value": "UZ-XO"
      }
    ],
    "value": "UZ"
  },
  {
    "label": "Vanuatu",
    "options": [
      {
        "label": "Sanma",
        "value": "VU-SAM"
      },
      {
        "label": "Shefa",
        "value": "VU-SEE"
      },
      {
        "label": "Tafea",
        "value": "VU-TAE"
      }
    ],
    "value": "VU"
  },
  {
    "label": "Venezuela, Bolivarian Republic of",
    "options": [
      {
        "label": "Amazonas",
        "value": "VE-Z"
      },
      {
        "label": "Anzoategui",
        "value": "VE-B"
      },
      {
        "label": "Apure",
        "value": "VE-C"
      },
      {
        "label": "Aragua",
        "value": "VE-D"
      },
      {
        "label": "Barinas",
        "value": "VE-E"
      },
      {
        "label": "Bolivar",
        "value": "VE-F"
      },
      {
        "label": "Carabobo",
        "value": "VE-G"
      },
      {
        "label": "Cojedes",
        "value": "VE-H"
      },
      {
        "label": "Delta Amacuro",
        "value": "VE-Y"
      },
      {
        "label": "Dependencias Federales",
        "value": "VE-W"
      },
      {
        "label": "Distrito Capital",
        "value": "VE-A"
      },
      {
        "label": "Falcon",
        "value": "VE-I"
      },
      {
        "label": "Guarico",
        "value": "VE-J"
      },
      {
        "label": "La Guaira",
        "value": "VE-X"
      },
      {
        "label": "Lara",
        "value": "VE-K"
      },
      {
        "label": "Merida",
        "value": "VE-L"
      },
      {
        "label": "Miranda",
        "value": "VE-M"
      },
      {
        "label": "Monagas",
        "value": "VE-N"
      },
      {
        "label": "Nueva Esparta",
        "value": "VE-O"
      },
      {
        "label": "Portuguesa",
        "value": "VE-P"
      },
      {
        "label": "Sucre",
        "value": "VE-R"
      },
      {
        "label": "Tachira",
        "value": "VE-S"
      },
      {
        "label": "Trujillo",
        "value": "VE-T"
      },
      {
        "label": "Yaracuy",
        "value": "VE-U"
      },
      {
        "label": "Zulia",
        "value": "VE-V"
      }
    ],
    "value": "VE"
  },
  {
    "label": "Viet Nam",
    "options": [
      {
        "label": "An Giang",
        "value": "VN-44"
      },
      {
        "label": "Ba Ria - Vung Tau",
        "value": "VN-43"
      },
      {
        "label": "Bac Giang",
        "value": "VN-54"
      },
      {
        "label": "Bac Kan",
        "value": "VN-53"
      },
      {
        "label": "Bac Lieu",
        "value": "VN-55"
      },
      {
        "label": "Bac Ninh",
        "value": "VN-56"
      },
      {
        "label": "Ben Tre",
        "value": "VN-50"
      },
      {
        "label": "Binh Dinh",
        "value": "VN-31"
      },
      {
        "label": "Binh Duong",
        "value": "VN-57"
      },
      {
        "label": "Binh Phuoc",
        "value": "VN-58"
      },
      {
        "label": "Binh Thuan",
        "value": "VN-40"
      },
      {
        "label": "Ca Mau",
        "value": "VN-59"
      },
      {
        "label": "Can Tho",
        "value": "VN-CT"
      },
      {
        "label": "Cao Bang",
        "value": "VN-04"
      },
      {
        "label": "Da Nang",
        "value": "VN-DN"
      },
      {
        "label": "Dak Lak",
        "value": "VN-33"
      },
      {
        "label": "Dak Nong",
        "value": "VN-72"
      },
      {
        "label": "Dien Bien",
        "value": "VN-71"
      },
      {
        "label": "Dong Nai",
        "value": "VN-39"
      },
      {
        "label": "Dong Thap",
        "value": "VN-45"
      },
      {
        "label": "Gia Lai",
        "value": "VN-30"
      },
      {
        "label": "Ha Giang",
        "value": "VN-03"
      },
      {
        "label": "Ha Nam",
        "value": "VN-63"
      },
      {
        "label": "Ha Noi",
        "value": "VN-HN"
      },
      {
        "label": "Ha Tinh",
        "value": "VN-23"
      },
      {
        "label": "Hai Duong",
        "value": "VN-61"
      },
      {
        "label": "Hai Phong",
        "value": "VN-HP"
      },
      {
        "label": "Hau Giang",
        "value": "VN-73"
      },
      {
        "label": "Ho Chi Minh",
        "value": "VN-SG"
      },
      {
        "label": "Hoa Binh",
        "value": "VN-14"
      },
      {
        "label": "Hung Yen",
        "value": "VN-66"
      },
      {
        "label": "Khanh Hoa",
        "value": "VN-34"
      },
      {
        "label": "Kien Giang",
        "value": "VN-47"
      },
      {
        "label": "Kon Tum",
        "value": "VN-28"
      },
      {
        "label": "Lai Chau",
        "value": "VN-01"
      },
      {
        "label": "Lam Dong",
        "value": "VN-35"
      },
      {
        "label": "Lang Son",
        "value": "VN-09"
      },
      {
        "label": "Lao Cai",
        "value": "VN-02"
      },
      {
        "label": "Long An",
        "value": "VN-41"
      },
      {
        "label": "Nam Dinh",
        "value": "VN-67"
      },
      {
        "label": "Nghe An",
        "value": "VN-22"
      },
      {
        "label": "Ninh Binh",
        "value": "VN-18"
      },
      {
        "label": "Ninh Thuan",
        "value": "VN-36"
      },
      {
        "label": "Phu Tho",
        "value": "VN-68"
      },
      {
        "label": "Phu Yen",
        "value": "VN-32"
      },
      {
        "label": "Quang Binh",
        "value": "VN-24"
      },
      {
        "label": "Quang Nam",
        "value": "VN-27"
      },
      {
        "label": "Quang Ngai",
        "value": "VN-29"
      },
      {
        "label": "Quang Ninh",
        "value": "VN-13"
      },
      {
        "label": "Quang Tri",
        "value": "VN-25"
      },
      {
        "label": "Soc Trang",
        "value": "VN-52"
      },
      {
        "label": "Son La",
        "value": "VN-05"
      },
      {
        "label": "Tay Ninh",
        "value": "VN-37"
      },
      {
        "label": "Thai Binh",
        "value": "VN-20"
      },
      {
        "label": "Thai Nguyen",
        "value": "VN-69"
      },
      {
        "label": "Thanh Hoa",
        "value": "VN-21"
      },
      {
        "label": "Thua Thien-Hue",
        "value": "VN-26"
      },
      {
        "label": "Tien Giang",
        "value": "VN-46"
      },
      {
        "label": "Tra Vinh",
        "value": "VN-51"
      },
      {
        "label": "Tuyen Quang",
        "value": "VN-07"
      },
      {
        "label": "Vinh Long",
        "value": "VN-49"
      },
      {
        "label": "Vinh Phuc",
        "value": "VN-70"
      },
      {
        "label": "Yen Bai",
        "value": "VN-06"
      }
    ],
    "value": "VN"
  },
  {
    "label": "Virgin Islands (British)",
    "value": "VG"
  },
  {
    "label": "Virgin Islands (U.S.)",
    "value": "VI"
  },
  {
    "label": "Wallis and Futuna",
    "options": [
      {
        "label": "Sigave",
        "value": "WF-SG"
      },
      {
        "label": "Uvea",
        "value": "WF-UV"
      }
    ],
    "value": "WF"
  },
  {
    "label": "Western Sahara",
    "value": "EH"
  },
  {
    "label": "Yemen",
    "options": [
      {
        "label": "'Adan",
        "value": "YE-AD"
      },
      {
        "label": "'Amran",
        "value": "YE-AM"
      },
      {
        "label": "Ad Dali'",
        "value": "YE-DA"
      },
      {
        "label": "Al Bayda'",
        "value": "YE-BA"
      },
      {
        "label": "Al Hudaydah",
        "value": "YE-HU"
      },
      {
        "label": "Al Jawf",
        "value": "YE-JA"
      },
      {
        "label": "Al Mahrah",
        "value": "YE-MR"
      },
      {
        "label": "Al Mahwit",
        "value": "YE-MW"
      },
      {
        "label": "Amanat al 'Asimah",
        "value": "YE-SA"
      },
      {
        "label": "Dhamar",
        "value": "YE-DH"
      },
      {
        "label": "Hadramawt",
        "value": "YE-HD"
      },
      {
        "label": "Ibb",
        "value": "YE-IB"
      },
      {
        "label": "Lahij",
        "value": "YE-LA"
      },
      {
        "label": "Ma'rib",
        "value": "YE-MA"
      },
      {
        "label": "Sa'dah",
        "value": "YE-SD"
      },
      {
        "label": "San'a'",
        "value": "YE-SN"
      },
      {
        "label": "Shabwah",
        "value": "YE-SH"
      },
      {
        "label": "Ta'izz",
        "value": "YE-TA"
      }
    ],
    "value": "YE"
  },
  {
    "label": "Zambia",
    "options": [
      {
        "label": "Central",
        "value": "ZM-02"
      },
      {
        "label": "Copperbelt",
        "value": "ZM-08"
      },
      {
        "label": "Eastern",
        "value": "ZM-03"
      },
      {
        "label": "Luapula",
        "value": "ZM-04"
      },
      {
        "label": "Lusaka",
        "value": "ZM-09"
      },
      {
        "label": "Muchinga",
        "value": "ZM-10"
      },
      {
        "label": "North-Western",
        "value": "ZM-06"
      },
      {
        "label": "Northern",
        "value": "ZM-05"
      },
      {
        "label": "Southern",
        "value": "ZM-07"
      },
      {
        "label": "Western",
        "value": "ZM-01"
      }
    ],
    "value": "ZM"
  },
  {
    "label": "Zimbabwe",
    "options": [
      {
        "label": "Bulawayo",
        "value": "ZW-BU"
      },
      {
        "label": "Harare",
        "value": "ZW-HA"
      },
      {
        "label": "Manicaland",
        "value": "ZW-MA"
      },
      {
        "label": "Mashonaland Central",
        "value": "ZW-MC"
      },
      {
        "label": "Mashonaland East",
        "value": "ZW-ME"
      },
      {
        "label": "Mashonaland West",
        "value": "ZW-MW"
      },
      {
        "label": "Masvingo",
        "value": "ZW-MV"
      },
      {
        "label": "Matabeleland North",
        "value": "ZW-MN"
      },
      {
        "label": "Matabeleland South",
        "value": "ZW-MS"
      },
      {
        "label": "Midlands",
        "value": "ZW-MI"
      }
    ],
    "value": "ZW"
  }
]