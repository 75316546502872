import classNames from 'classnames'
import React, { memo } from 'react'
import { Icon } from '@cotiss/common'

type Props = {
  className?: string
  tasksRemaining: number
  size?: 'sm' | 'md'
  state?: 'filled' | 'translucent' | 'outline'
  isRounded?: boolean
}

export const TasksRemainingChip = memo(({ className, tasksRemaining, state = 'translucent', size = 'md', isRounded }: Props) => {
  const classes = classNames(className, 'flex items-center justify-center shrink-0 border', {
    'h-5 w-5': size === 'sm',
    'h-7 px-2': size === 'md',
    'bg-green-200 border-transparent': !tasksRemaining,
    'bg-secondary-200 border-secondary-200 text-secondary-500 text-xs': state === 'translucent' && tasksRemaining,
    'bg-secondary-500 border-secondary-500 text-white text-xs': state === 'filled' && tasksRemaining,
    'rounded-full': isRounded,
    'rounded-sm': !isRounded,

    // TODO: This is a temporary solution to get a disabled step looking correct for the VerticalTabs component. We should revisit this
    'bg-primary-50 border-secondary-400 text-secondary-400 text-xs': state === 'outline' && tasksRemaining,
  })

  return <div className={classes}>{tasksRemaining || <Icon className="text-green-500" icon="check" size={size === 'md' ? 16 : 12} />}</div>
})
