import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlEvaluationEventRawResultsCsvQuery, GqlEvaluationEventViewInput } from '@gql'

export const queryEvaluationEventRawResultsCsv = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventRawResultsCsvQuery>({
    query: gql`
      query EvaluationEventRawResultsCsv($input: EvaluationEventViewInput!) {
        evaluationEventRawResultsCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.evaluationEventRawResultsCsv
}
