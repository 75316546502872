import { useFetch, PaginatedModel } from '@cotiss/common'
import { EvaluationModel, EvaluationType } from '@cotiss/evaluation'

type Props = {
  limit?: number
  offset?: number
  userId?: string
  tenderId?: string
  tenderResponseId?: string
  type?: EvaluationType
}

export const useListEvaluation = (props: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<EvaluationModel>>('/v2/evaluations', {
    route: `/v2/evaluations`,
    params: { ...props, offset: 0, limit: -1 },
    isEnabled: Boolean(props.userId || props.tenderId || props.tenderResponseId),
  })

  return { evaluations: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
