import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import {
  GqlApprovalTemplateFieldsFragment,
  GqlCreateApprovalTemplateInput,
  GqlCreateApprovalTemplateMutation,
  GqlCreateApprovalTemplateMutationVariables,
} from '@gql'

export const mutateCreateApprovalTemplate = async (input: GqlCreateApprovalTemplateInput) => {
  const { data } = await apolloService.mutate<GqlCreateApprovalTemplateMutation, GqlCreateApprovalTemplateMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      mutation CreateApprovalTemplate($input: CreateApprovalTemplateInput!) {
        createApprovalTemplate(input: $input) {
          ...ApprovalTemplateFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createApprovalTemplate as GqlApprovalTemplateFieldsFragment
}
