import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlPerformanceMetricListInput, GqlPerformanceMetricListQuery } from '@gql'

export const queryPerformanceMetricList = async (input: GqlPerformanceMetricListInput) => {
  const { data } = await apolloService.query<GqlPerformanceMetricListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      query PerformanceMetricList($input: PerformanceMetricListInput!) {
        performanceMetricList(input: $input) {
          items {
            ...PerformanceMetricFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceMetricList
}
