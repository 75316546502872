import React, { memo, useMemo } from 'react'
import { UserLineItem } from '@cotiss/user'
import {
  Icon,
  Table_DEPRECATED,
  Tbody_DEPRECATED,
  Td_DEPRECATED,
  Th_DEPRECATED,
  Thead_DEPRECATED,
  Tr_DEPRECATED,
  Text,
  datetimeService,
} from '@cotiss/common'

export const ContractSummaryTermsMetadataTab = memo(() => {
  const timestamp = useMemo(() => new Date(), [])

  return (
    <Table_DEPRECATED>
      <Thead_DEPRECATED variant="white">
        <Th_DEPRECATED>Activity</Th_DEPRECATED>
        <Th_DEPRECATED>Actioned by</Th_DEPRECATED>
        <Th_DEPRECATED className="w-60">Timestamp</Th_DEPRECATED>
      </Thead_DEPRECATED>
      <Tbody_DEPRECATED>
        <Tr_DEPRECATED>
          <Td_DEPRECATED>
            <div className="flex justify-between items-center">
              <Icon icon="star-06" className="flex-shrink mr-4" size={12} />
              <div className="flex-grow">
                <Text size="sm">Internal Reference Added</Text>
                <Text size="sm" variant="light">
                  Internal Reference: 76843
                </Text>
              </div>
            </div>
          </Td_DEPRECATED>
          <Td_DEPRECATED>
            <UserLineItem email="local-buyer1-admin1@cotiss.com" />
          </Td_DEPRECATED>
          <Td_DEPRECATED>
            <Text variant="light" size="sm">
              {datetimeService.format(timestamp, 'd MMMM yyyy h:mm aaa')}
            </Text>
          </Td_DEPRECATED>
        </Tr_DEPRECATED>
      </Tbody_DEPRECATED>
    </Table_DEPRECATED>
  )
})
