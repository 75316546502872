import classNames from 'classnames'
import React, { memo } from 'react'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'
import { GqlEvaluationOverviewCriteriaBreakdownFieldsFragment } from '@gql'

const getVariant = (criteriaBreakdown?: GqlEvaluationOverviewCriteriaBreakdownFieldsFragment): BaseVariant => {
  if (!criteriaBreakdown || !criteriaBreakdown.completedScoredSubCriteriaCount) {
    return 'neutral'
  }

  if (criteriaBreakdown.scoredSubCriteriaCount === criteriaBreakdown.completedScoredSubCriteriaCount) {
    return 'success'
  }

  return 'warning'
}

const getCopy = (criteriaBreakdown?: GqlEvaluationOverviewCriteriaBreakdownFieldsFragment) => {
  if (!criteriaBreakdown || !criteriaBreakdown.completedScoredSubCriteriaCount) {
    return 'Not started'
  }

  if (criteriaBreakdown.scoredSubCriteriaCount === criteriaBreakdown.completedScoredSubCriteriaCount) {
    return 'Complete'
  }

  return 'In progress'
}

type Props = {
  className?: string
  criteriaBreakdown?: GqlEvaluationOverviewCriteriaBreakdownFieldsFragment
  size?: BadgeSize
}

export const EvaluationEventEvaluationCriteriaBreakdownStatusBadge = memo(({ className, criteriaBreakdown, size = 'sm' }: Props) => (
  <Badge className={classNames(className, 'shrink-0')} size={size} variant={getVariant(criteriaBreakdown)} state="translucent">
    {getCopy(criteriaBreakdown)}
  </Badge>
))
