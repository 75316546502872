import React, { FormEvent, memo, useState } from 'react'
import { useEvaluationEventAnalytics, useEvaluationSubmission } from '@cotiss/evaluation-event'
import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

export const EvaluationEventSubmissionUpdateModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationSubmission, mutateUpdateEvaluationSubmission } = useEvaluationSubmission()
  const [name, setName] = useState(evaluationSubmission?.name || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationSubmission) {
      return
    }

    track('evaluation_event_view_submissions_drawer_update_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationSubmission({ evaluationSubmissionId: evaluationSubmission.id, name })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update submission" isDisabled={isSaving} />
      <ModalContent>
        <Label>Submission name</Label>
        <Input value={name} onChange={({ target }) => setName(target.value)} placeholder="Enter submission name" isDisabled={isSaving} isRequired />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
