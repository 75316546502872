import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT } from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardMetricCycleScoreInput,
  GqlCreatePerformanceScorecardMetricCycleScoreMutation,
  GqlCreatePerformanceScorecardMetricCycleScoreMutationVariables,
  GqlPerformanceScorecardMetricCycleScoreFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceScorecardMetricCycleScore = async (input: GqlCreatePerformanceScorecardMetricCycleScoreInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricCycleScoreMutation,
    GqlCreatePerformanceScorecardMetricCycleScoreMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT}

      mutation CreatePerformanceScorecardMetricCycleScore($input: CreatePerformanceScorecardMetricCycleScoreInput!) {
        createPerformanceScorecardMetricCycleScore(input: $input) {
          ...PerformanceScorecardMetricCycleScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricCycleScore as GqlPerformanceScorecardMetricCycleScoreFieldsFragment
}
