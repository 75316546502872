import ReactSelect, { MultiValueRemoveProps, components } from 'react-select'
import React, { useMemo } from 'react'
import { filter, includes, map } from 'lodash'
import { Icon, SelectClearIndicator, SelectDropdownIndicator, SelectOption, getSelectClassNamesConfig } from '@cotiss/common'

type Props<T extends string> = {
  className?: string
  values?: T[]
  options: SelectOption<T>[]
  onChange?: (value: T[]) => void
  placeholder?: string
  isRequired?: boolean
  isError?: boolean
  isDisabled?: boolean
}

export function MultiSelect<T extends string>(props: Props<T>) {
  const { className, values, options, onChange, placeholder = '- Select an option -', isRequired, isDisabled } = props
  const processedValues = useMemo(() => filter(options, (option) => includes(values, option.value)), [values, options])

  return (
    <ReactSelect
      className={className}
      value={processedValues}
      classNames={{ ...getSelectClassNamesConfig(props), ...getMultiSelectClassNamesConfig() }}
      components={{ ClearIndicator: SelectClearIndicator, DropdownIndicator: SelectDropdownIndicator, MultiValueRemove: MultiSelectValueRemove }}
      options={options as any[]}
      onChange={(values: any) => onChange && onChange(map(values, 'value'))}
      placeholder={placeholder}
      required={isRequired}
      isClearable={!isRequired}
      isDisabled={isDisabled}
      isMulti
    />
  )
}

const getMultiSelectClassNamesConfig = () => ({
  multiValue: () => '!bg-secondary-200 !rounded-full !px-1',
  multiValueLabel: () => '!font-inter !text-secondary-500 !text-sm',
  multiValueRemove: () => '!bg-transparent',
})

export function MultiSelectValueRemove<T>(props: MultiValueRemoveProps<T>) {
  return (
    <components.MultiValueRemove {...props}>
      <Icon icon="cross" variant="secondary" size={12} />
    </components.MultiValueRemove>
  )
}
