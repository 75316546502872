import { useState } from 'react'
import { useGetTender } from '@cotiss/tender'
import { useMutateReport } from '@cotiss/report'
import { useToast, sentryService } from '@cotiss/common'

export const useDownloadReport = (tenderId: string) => {
  const { openToast } = useToast()
  const { tender } = useGetTender(tenderId)
  const [isDownloading, setIsDownloading] = useState(false)
  const { exportFinalScoringReport, exportPricingTableReport } = useMutateReport()

  const handleExport = (type: 'pricing-table' | 'non-price') => async () => {
    try {
      setIsDownloading(true)
      const { report } = type === 'pricing-table' ? await exportPricingTableReport({ tenderId }) : await exportFinalScoringReport({ tenderId })

      if (!report.data?.length) {
        setIsDownloading(false)
      }

      const byteArray = new Uint8Array(report.data)
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${tender?.title.toLocaleLowerCase()}_scoring_report_export.xlsx`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  return {
    downloadNonPriceReport: handleExport('non-price'),
    downloadPricingTableReport: handleExport('pricing-table'),
    isDownloading,
  }
}
