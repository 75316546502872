import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeletePerformanceScorecardUserInput,
  GqlDeletePerformanceScorecardUserMutation,
  GqlDeletePerformanceScorecardUserMutationVariables,
} from '@gql'

export const mutateDeletePerformanceScorecardUser = async (input: GqlDeletePerformanceScorecardUserInput) => {
  const { data } = await apolloService.mutate<GqlDeletePerformanceScorecardUserMutation, GqlDeletePerformanceScorecardUserMutationVariables>({
    mutation: gql`
      mutation DeletePerformanceScorecardUser($input: DeletePerformanceScorecardUserInput!) {
        deletePerformanceScorecardUser(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePerformanceScorecardUser as boolean
}
