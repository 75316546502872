import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { compact, every, filter, find, groupBy, map, some } from 'lodash'
import { userService } from '@cotiss/user'
import { GqlApprovalTemplateFieldsFragment } from '@gql'
import { useApprovalTemplate } from '@cotiss/approval-template'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { contractService, useGetContractShell, useMutateContractShell } from '@cotiss/contract'
import {
  Banner,
  Button,
  Card,
  CardHeader,
  Field,
  Form,
  Hr,
  Icon,
  Select_DEPRECATED,
  Skeleton,
  Text,
  TextArea,
  routerService,
  sentryService,
  useAnalytics,
  useAsyncEffect,
  useCallout,
  useToast,
  utilService,
} from '@cotiss/common'

type ApproversToSubmit = {
  approvalTemplateId?: string
  userId: string
  role: string
  order: number | null
}

type HandleApproversToSubmitChangeParam = {
  userId: string
  approvalTemplate: GqlApprovalTemplateFieldsFragment
  index: number
}

type Props = {
  contractShellId: string
  onNext: () => void
  onBack: () => void
}

export const ContractWizardApproversStep = memo(({ contractShellId, onNext, onBack }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const { closeFullModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [approvalComment, setApprovalComment] = useState('')
  const { queryApprovalTemplateList } = useApprovalTemplate()
  const { contractShell } = useGetContractShell(contractShellId)
  const { updateContractApproverSequence } = useMutateContractShell()
  const [isApprovalTemplatesLoading, setIsApprovalTemplatesLoading] = useState(false)
  const [selectedApprovalTemplateGroupId, setSelectedApprovalTemplateGroupId] = useState('')
  const { approvalTemplateGroups, queryApprovalTemplateGroupList } = useApprovalTemplateGroup()
  const [isApprovalTemplateGroupsLoading, setIsApprovalTemplateGroupsLoading] = useState(false)
  const [approvalTemplates, setApprovalTemplates] = useState<GqlApprovalTemplateFieldsFragment[]>([])
  const [approversToSubmit, setApproversToSubmit] = useState<Array<ApproversToSubmit | undefined>>([])

  const { contract, approval } = useMemo(() => {
    const contract = find(contractShell?.contracts, { status: 'DRAFTING' })

    return { contract, approval: contractService.getApproval(contract?.approvals, ['DRAFTING']) }
  }, [contractShell])

  const approvalTemplateGroupOptions = useMemo(() => {
    return map(approvalTemplateGroups, ({ id, name }) => ({ value: id, label: name }))
  }, [approvalTemplateGroups])

  useAsyncEffect(async () => {
    track('contract_wizard_approvers_view')

    try {
      const { approvalTemplateGroups } = await queryApprovalTemplateGroupList({ filter: { module: 'contract' } })

      const existingApprovalTemplateGroup =
        approval?.approvalTemplateGroupId && find(approvalTemplateGroups, { id: approval.approvalTemplateGroupId })
      setSelectedApprovalTemplateGroupId(existingApprovalTemplateGroup ? existingApprovalTemplateGroup.id : '')
      setApprovalComment(approval?.comment || '')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsApprovalTemplateGroupsLoading(false)
  }, [])

  useAsyncEffect(async () => {
    if (!selectedApprovalTemplateGroupId) {
      return
    }

    try {
      setErrorMessage('')
      setIsApprovalTemplatesLoading(true)

      const { approvalTemplates } = await queryApprovalTemplateList({ filter: { approvalTemplateGroupId: selectedApprovalTemplateGroupId } })
      // Normalising the order numbers for templates. For example the order approval sequence could provide 1,4,4,2,3,3,3,5,16. But we want to convert
      // it to normalised: 1,2,3,3,3,4,4,5,6.
      const normalisedApprovalTemplates = utilService.normaliseSequenceByAttribute(approvalTemplates, 'order')
      setApprovalTemplates(normalisedApprovalTemplates)

      const approversToSubmit = map(normalisedApprovalTemplates, (approvalTemplate) => {
        const { id: approvalTemplateId, name: role, order, approvalTemplateUsers, isOptional } = approvalTemplate

        // If the are already approvers assigned to the contract, attempt to preload the user based on the approvalTemplateId referenced.
        if (selectedApprovalTemplateGroupId === approval?.approvalTemplateGroupId) {
          const approver = find(approval.approvers, { approvalTemplateId })

          if (approver && some(approvalTemplateUsers, ({ user }) => user.id === approver.assigned._id)) {
            return { approvalTemplateId, userId: approver.assigned._id, order, role }
          }
        }

        if (!approvalTemplateUsers[0]) {
          return
        }

        return !isOptional ? { userId: approvalTemplateUsers[0].user.id, order, role } : undefined
      })

      setApproversToSubmit(approversToSubmit)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsApprovalTemplatesLoading(false)
  }, [selectedApprovalTemplateGroupId])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const selectedTemplateGroupOption = find(approvalTemplateGroupOptions, { value: selectedApprovalTemplateGroupId })

    // The UI should prevent this.
    if (!contract || !approval || !selectedTemplateGroupOption) {
      setErrorMessage('Something went wrong trying to save your changes. Please try again.')
      return
    }

    const isEveryUserValid = every(approvalTemplates, ({ isOptional }, index) => (isOptional ? true : Boolean(approversToSubmit[index])))
    if (!isEveryUserValid) {
      setErrorMessage('Every required approval must have a user selected.')
      return
    }

    const approvers = filter(compact(approversToSubmit), 'userId')
    if (!approvers.length) {
      setErrorMessage('You must have at least one approver selected.')
      return
    }

    if (some(groupBy(approvers, 'userId'), (approvers) => approvers.length > 1)) {
      setErrorMessage('You have the same user selected twice. Each approval must have a different user selected.')
      return
    }

    track('contract_wizard_approvers_update_submit')

    try {
      setIsSaving(true)
      await updateContractApproverSequence({
        contractShellId,
        contractId: contract._id,
        approvalId: approval._id,
        body: {
          approvalTemplateGroupId: selectedApprovalTemplateGroupId,
          approvalTemplateGroupName: selectedTemplateGroupOption.label,
          comment: approvalComment,
          approvers,
        },
      })
      setIsSaving(false)
      onNext()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleClickOpenSettings = () => {
    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'modules', nestedTab: 'contract' }))
    closeFullModal()
  }

  const handleApproversToSubmitChange = ({ userId, approvalTemplate, index: indexToUpdate }: HandleApproversToSubmitChangeParam) => {
    track('contract_wizard_approvers_sequence_approver_change')
    setErrorMessage('')

    const { id: approvalTemplateId, name: role, order } = approvalTemplate
    setApproversToSubmit(
      map(approversToSubmit, (approverToSubmit, index) => (index === indexToUpdate ? { approvalTemplateId, userId, role, order } : approverToSubmit))
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
              Approvers
            </Text>
          </div>
          <div className="flex flex-col items-end ml-4">
            <div>
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isSaving}>
                Back
              </Button>
              <Button
                type="submit"
                variant="secondary"
                size="sm"
                isDisabled={isSaving || isApprovalTemplatesLoading || isApprovalTemplateGroupsLoading}
              >
                Continue
              </Button>
            </div>
            {errorMessage && (
              <Text className="mt-1" size="sm" variant="danger">
                {errorMessage}
              </Text>
            )}
          </div>
        </CardHeader>
        <div className="p-6">
          <Field
            label="Requester comment"
            supplementary="Type any notes or comments that you would like the approvers to read while they complete their approval"
          >
            <TextArea className="h-36" value={approvalComment} onChange={({ target }) => setApprovalComment(target.value)} />
          </Field>
          <Banner variant="light" className="flex mt-6">
            <div className="bg-secondary-100 h-6 w-6 rounded-full mr-4 flex items-center justify-center p-1">
              <Icon icon="users-01" className="text-secondary-500" />
            </div>
            <div>
              <Text className="font-medium">Creating approval sequences</Text>
              <Text className="mt-1" size="sm">
                Creating approval sequences and assigning users to approval roles must be done in your account{' '}
                <Button state="text" variant="link" size="sm" onClick={handleClickOpenSettings}>
                  Settings
                </Button>
                . Make sure that the user you are adding has the &quot;Contract Management&quot; and &quot;Approver&quot; permissions
              </Text>
            </div>
          </Banner>
          <Field
            className="mt-6"
            label="Select approvers sequence"
            supplementary="Select users to review and approve the contract and associated conditions and metadata."
          >
            {isApprovalTemplateGroupsLoading && <Skeleton className="w-full h-12" />}
            {!isApprovalTemplateGroupsLoading && (
              <Select_DEPRECATED
                placeholder="Please select"
                isRequired
                value={selectedApprovalTemplateGroupId}
                options={approvalTemplateGroupOptions}
                onChange={setSelectedApprovalTemplateGroupId}
              />
            )}
          </Field>
          {selectedApprovalTemplateGroupId && (
            <>
              <Hr className="my-6" />
              {isApprovalTemplatesLoading && (
                <div className="max-w-4xl">
                  <Skeleton className="w-full h-12" />
                  <Skeleton className="w-full h-12 mt-6" />
                  <Skeleton className="w-full h-12 mt-6" />
                </div>
              )}
              {!isApprovalTemplatesLoading &&
                map(approvalTemplates, (approvalTemplate, index) => {
                  const { id, order, name, approvalTemplateUsers, isOptional } = approvalTemplate
                  return (
                    <Field key={id} className="mt-4" label={`${order}. Select ${name} approver`} supplementary={isOptional && '(optional)'}>
                      {!approvalTemplateUsers.length && (
                        <Text>
                          There are no users in this template. Add them in your account{' '}
                          <Button state="text" variant="link" onClick={handleClickOpenSettings}>
                            Settings
                          </Button>
                          .
                        </Text>
                      )}
                      {Boolean(approvalTemplateUsers.length) && (
                        <Select_DEPRECATED
                          value={approversToSubmit[index]?.userId}
                          options={compact([
                            isOptional ? { value: '', label: '- Select an option -' } : undefined,
                            ...map(approvalTemplateUsers, ({ user }) => ({ value: user.id, label: userService.getFullName(user) })),
                          ])}
                          onChange={(userId) => handleApproversToSubmitChange({ userId, approvalTemplate, index })}
                          isRequired={!isOptional}
                        />
                      )}
                    </Field>
                  )
                })}
            </>
          )}
        </div>
      </Card>
    </Form>
  )
})
