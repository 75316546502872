import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance'
import {
  GqlCreatePerformanceMetricInput,
  GqlCreatePerformanceMetricMutation,
  GqlCreatePerformanceMetricMutationVariables,
  GqlPerformanceMetricFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceMetric = async (input: GqlCreatePerformanceMetricInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceMetricMutation, GqlCreatePerformanceMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      mutation CreatePerformanceMetric($input: CreatePerformanceMetricInput!) {
        createPerformanceMetric(input: $input) {
          ...PerformanceMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceMetric as GqlPerformanceMetricFieldsFragment
}
