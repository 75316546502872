export const SUPPLIER_ROUTES = [
  '/supplier',
  '/supplier/view/:id/:tab?',
  '/supplier/invitation',

  // ! This is a legacy route that we want to support incase there are any old invitation emails hanging around.
  '/supplier-invitation',
] as const

export const SUPPLIER_QUERY_KEYS = ['/v2/supplier-profiles', '/v2/supplier-profiles-onboarding'] as const
