import { useParams } from 'react-router-dom'
import React, { memo, useState } from 'react'
import { filter, includes, map } from 'lodash'
import { DocumentList } from '@cotiss/document'
import { Card, CardHeader, NoDataPlaceholder, Text, sentryService, useCallout, useToast } from '@cotiss/common'
import { useGetProcurement, ProcurementOverviewBackgroundDocumentUploadModal, useMutateProcurement } from '@cotiss/procurement'

export const ProcurementOverviewBackgroundAttachmentsTab = memo(() => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { procurementId } = useParams<{ procurementId: string }>()
  const [isSaving, setIsSaving] = useState(false)
  const { updateProcurement } = useMutateProcurement()
  const { procurement, isLoading } = useGetProcurement(procurementId)
  const isEditable = procurement !== undefined // TODO: Figure out when this should be editable

  const handleBulkRemoveAttachments = async (documentIdsToRemove: string[]) => {
    if (!procurement) {
      return
    }

    try {
      setIsSaving(true)
      const attachments = filter(procurement.attachments, ({ _id }) => !includes(documentIdsToRemove, _id))
      await updateProcurement(procurement._id, { attachments: map(attachments, ({ _id }) => _id) })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Card className="relative">
      <CardHeader>
        <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
          Attachments
        </Text>
      </CardHeader>
      {!isLoading && !procurement?.attachments.length && (
        <NoDataPlaceholder
          label="No documents uploaded"
          ctaLabel="+ Upload"
          onCtaClick={isEditable ? () => openModal(<ProcurementOverviewBackgroundDocumentUploadModal procurement={procurement} />) : undefined}
        />
      )}
      {Boolean(isLoading || procurement?.attachments.length) && (
        <DocumentList
          documents={procurement?.attachments || []}
          onUpload={isEditable ? () => openModal(<ProcurementOverviewBackgroundDocumentUploadModal procurement={procurement} />) : undefined}
          onBulkRemove={isEditable ? handleBulkRemoveAttachments : undefined}
          isDisabled={isSaving}
          isLoading={isLoading}
        />
      )}
    </Card>
  )
})
