import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  tenderId: string
}

export const TenderRequestDocumentUploadModal = memo(({ tenderId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { tender, isLoading } = useGetTender(tenderId)
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTender(tenderId, { attachments: [...map(attachments, ({ _id }) => _id), ...map(tender?.attachments, ({ _id }) => _id)] })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload specification documents" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={isLoading} isForm />
    </Form>
  )
})
