import { findIndex } from 'lodash'
import React, { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import folderDocumentsPng from '@assets/images/folder-documents.png'
import { useListTenderMandatoryCriteria, useListTenderMandatoryCriteriaScore } from '@cotiss/tender-mandatory-criteria'
import {
  Button,
  Card,
  CardHeader,
  Icon,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  sentryService,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'
import {
  TenderResponseReviewExcludeModal,
  TenderResponseReviewForms,
  TenderResponseReviewIncludeConfirmModal,
  TenderResponseReviewQuestionnaireTab,
  TenderResponseReviewStatusBadge,
  useGetTenderResponse,
  useMutateTenderResponse,
} from '@cotiss/tender-response'

export type TenderResponseTab = 'response-forms' | 'questionnaire'
const TENDER_RESPONSE_TABS: TabModel<TenderResponseTab>[] = [
  { id: 'response-forms', label: 'Response forms' },
  { id: 'questionnaire', label: 'Questionnaire' },
]

type Props = {
  tenderResponseId: string
  onBack: () => void
  isEditable?: boolean
}

export const TenderResponseReview = memo(({ tenderResponseId, onBack, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { step, transition, onTransition } = useTransition()
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const { tenderMandatoryCriteriaScores } = useListTenderMandatoryCriteriaScore({ tenderResponseId })
  const { tenderMandatoryCriteria } = useListTenderMandatoryCriteria({ tenderId: tenderResponse?.tender._id })
  const isDisabled = tenderMandatoryCriteria.length !== tenderMandatoryCriteriaScores.length && Boolean(tenderMandatoryCriteria.length)

  if (!tenderResponse) {
    return null
  }

  const handleUnlock = async () => {
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponseId, { responseUnlocked: true })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleTabChange = (tab: TenderResponseTab) => {
    const newStep = findIndex(TENDER_RESPONSE_TABS, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const renderContent = () => {
    if (!tenderResponse.responseUnlocked) {
      return (
        <div className="flex items-start justify-center max-w-[600px] mx-auto mt-12">
          <div>
            <Text className="font-semibold" size="h5" font="jakarta">
              Submission is ready for review
            </Text>
            <Text className="mt-2" variant="light">
              Unlock submission to review price and non-price documents
            </Text>

            <Button className="mt-2" onClick={handleUnlock} size="sm" isLoading={isSaving}>
              Unlock submission
            </Button>
          </div>
          <img className="ml-4 -mt-8" src={folderDocumentsPng} alt="Folder documents" />
        </div>
      )
    }

    return (
      <>
        <Tabs<TenderResponseTab>
          className="border-b border-gray-300 w-full mt-2 mb-4"
          tabs={TENDER_RESPONSE_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <TenderResponseReviewForms tenderResponseId={tenderResponseId} isEditable={isEditable} />}
            {step === 2 && <TenderResponseReviewQuestionnaireTab tenderResponseId={tenderResponseId} isEditable={isEditable} />}
          </TransitionContainer>
        </AnimatePresence>
      </>
    )
  }

  return (
    <>
      {tenderResponse.reviewStatus === 'excluded' && (
        <div className="bg-white border border-pink-400 rounded-lg p-4 mb-4">
          <Text className="font-semibold uppercase" size="sm">
            Response excluded
          </Text>
          <Text className="mt-6 whitespace-pre-wrap" variant="light">
            {tenderResponse.reviewJustification || '–'}
          </Text>
        </div>
      )}
      <Card>
        <CardHeader>
          <div className="flex items-center mr-4">
            <Button className="mr-2" onClick={onBack} state="ghost" variant="link" size="sm">
              <Icon icon="arrow-left" size={20} />
              Back
            </Button>
            <Text className="font-semibold" font="jakarta">
              {tenderResponse.procurementResponse.supplier.name}
            </Text>
            <TenderResponseReviewStatusBadge className="ml-3" status={tenderResponse.reviewStatus} />
          </div>
          {isEditable && (
            <div className="flex items-center">
              <Button
                onClick={() => openModal(<TenderResponseReviewExcludeModal tenderResponse={tenderResponse} />)}
                state="ghost"
                variant="link"
                size="sm"
                isDisabled={isDisabled || tenderResponse.reviewStatus === 'excluded' || !tenderResponse.responseUnlocked}
              >
                Exclude
              </Button>
              <Button
                className="ml-2"
                onClick={() => openModal(<TenderResponseReviewIncludeConfirmModal tenderResponse={tenderResponse} />)}
                size="sm"
                isDisabled={isDisabled || tenderResponse.reviewStatus === 'included' || !tenderResponse.responseUnlocked}
              >
                Include
              </Button>
            </div>
          )}
        </CardHeader>
        {renderContent()}
      </Card>
    </>
  )
})
