import { gql } from '@apollo/client'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlPlanEventListInput, GqlPlanEventListQuery } from '@gql'

export const queryPlanEventList = async (input: GqlPlanEventListInput) => {
  const { data } = await apolloService.query<GqlPlanEventListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      query PlanEventList($input: PlanEventListInput!) {
        planEventList(input: $input) {
          items {
            ...PlanEventFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.planEventList
}
