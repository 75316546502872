import classNames from 'classnames'
import React, { memo } from 'react'
import { RemirrorJSON } from 'remirror'
import { renderToString } from 'react-dom/server'
import { BoldExtension, HeadingExtension, ItalicExtension, UnderlineExtension } from 'remirror/extensions'
import {
  BasicFormattingButtonGroup,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  useRemirror,
  Remirror,
  Toolbar,
  OnChangeJSON,
  RemirrorRenderer,
} from '@remirror/react'
import { richTextService } from '@cotiss/common'

type Props = {
  className?: string
  value?: RemirrorJSON
  onChange: (value: RemirrorJSON) => void
  isRequired?: boolean
  isDisabled?: boolean
}

export const RichTextEditor = memo(({ className, value, onChange, isRequired, isDisabled }: Props) => {
  const { manager, state } = useRemirror({
    extensions: () => [new HeadingExtension({}), new BoldExtension({}), new ItalicExtension(), new UnderlineExtension()],
    content: value ? renderToString(<RemirrorRenderer json={value} />) : '',
    selection: 'end',
    stringHandler: 'html',
  })
  const classes = classNames(className, 'relative remirror-theme', {
    'opacity-50 pointer-events-none': isDisabled,
  })
  const toolbarClasses = classNames({
    'pointer-events-none': isDisabled,
  })

  return (
    <div className={classes}>
      {isRequired && richTextService.isEmpty(value) && (
        <input className="absolute top-1/2 left-1/2 w-px h-px pointer-events-none" type="text" required />
      )}
      <Remirror manager={manager} initialContent={state} autoRender="end" editable={!isDisabled}>
        {!isDisabled && (
          <Toolbar className={toolbarClasses}>
            <HistoryButtonGroup />
            <HeadingLevelButtonGroup />
            <BasicFormattingButtonGroup />
          </Toolbar>
        )}
        <OnChangeJSON onChange={onChange} />
      </Remirror>
    </div>
  )
})
