import { gql } from '@apollo/client'

export const EVALUATION_OVERVIEW_FRAGMENT = gql`
  fragment EvaluationOverviewFields on EvaluationOverview {
    evaluationId
    scoredCriteriaCount
    completedScoredCriteriaCount
    totalPercentageScore
    totalWeightedPercentageScore
    totalEnvelopeWeightedPercentageScore
    totalOverallWeightedPercentageScore
    totalModeratedPercentageScore
    totalModeratedWeightedPercentageScore
    totalModeratedEnvelopeWeightedPercentageScore
    totalModeratedOverallWeightedPercentageScore
    criteriaBreakdown {
      ...EvaluationOverviewCriteriaBreakdownFields
    }
  }
`
