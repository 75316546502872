import qrcode from 'qrcode'
import { Authenticator } from '@otplib/core'
import { useEffect, useRef, useState } from 'react'
import { authService } from '@cotiss/auth'

export const useOtpAuthenticatorHook = () => {
  const [image, setImage] = useState('')
  const [secret, setSecret] = useState('')
  const otpAuthenticatorRef = useRef<Authenticator>()

  useEffect(() => {
    if (typeof window !== 'undefined' && !otpAuthenticatorRef.current) {
      otpAuthenticatorRef.current = authService.getOtpAuthenticator()
    }
  }, [])

  const getImage = async (userId: string) => {
    if (!otpAuthenticatorRef.current) {
      otpAuthenticatorRef.current = authService.getOtpAuthenticator()
    }

    const secret = otpAuthenticatorRef.current.generateSecret()
    setSecret(secret)
    const otpUri = otpAuthenticatorRef.current.keyuri(userId, 'Cotiss', secret)
    const imageUrl = await qrcode.toDataURL(otpUri)
    setImage(imageUrl)
  }

  return {
    getImage,
    image,
    secret,
  }
}
