import { envService } from '@cotiss/common'

class ClarityService {
  init = () => {
    if (envService.isProduction()) {
      const head = document.querySelector('head')
      const script = document.createElement('script')

      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "l7jxijj9w1");
        `

      if (!head) {
        return
      }

      head.appendChild(script)
    }
  }

  // Set custom-id using email
  identify = (email: string) => {
    if (!window.clarity) {
      return
    }
    window.clarity('identify', email)
  }
}

export const clarityService = new ClarityService()
