import React, { FormEvent, memo, useState } from 'react'
import { GqlApprovalTemplateModuleType } from '@gql'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { SettingsModulesApprovalTemplateGroupUpdateDrawer } from '@cotiss/settings'
import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  module: GqlApprovalTemplateModuleType
}

export const SettingsModulesApprovalTemplateGroupCreateModal = memo(({ module }: Props) => {
  const { openToast } = useToast()
  const { openDrawer, closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateCreateApprovalTemplateGroup } = useApprovalTemplateGroup()
  const [name, setName] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const approvalTemplateGroup = await mutateCreateApprovalTemplateGroup({ name })

      openDrawer(
        <SettingsModulesApprovalTemplateGroupUpdateDrawer approvalTemplateGroupId={approvalTemplateGroup.id} module={module} isEditable isNew />
      )

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add sequence" isDisabled={isSaving} />
      <ModalContent>
        <Label>Name</Label>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Enter name"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
