import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlDeletePlanUserInput, GqlDeletePlanUserMutation, GqlDeletePlanUserMutationVariables } from '@gql'

export const mutateDeletePlanUser = async (input: GqlDeletePlanUserInput) => {
  const { data } = await apolloService.mutate<GqlDeletePlanUserMutation, GqlDeletePlanUserMutationVariables>({
    mutation: gql`
      mutation DeletePlanUser($input: DeletePlanUserInput!) {
        deletePlanUser(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePlanUser as boolean
}
