import { useMutate } from '@cotiss/common'
import { CorrespondenceModel } from '@cotiss/correspondence'

export type CreateContractCorrespondenceBody = Pick<CorrespondenceModel, 'contractShellId' | 'title' | 'date' | 'description'> & {
  attachments: string[]
}

export type UpdateContractCorrespondenceBody = Pick<CorrespondenceModel, 'title' | 'date' | 'description'> & { attachments: string[] }

export const useMutateCorrespondence = () => {
  const { mutate } = useMutate()

  return {
    createCorrespondence: async (body: CreateContractCorrespondenceBody) => {
      return await mutate<CorrespondenceModel>({
        route: `/v2/correspondence/`,
        body,
        invalidate: ['/v2/correspondence'],
      })
    },
    updateCorrespondence: async (correspondenceId: string, body: UpdateContractCorrespondenceBody) => {
      return await mutate<CorrespondenceModel>({
        route: `/v2/correspondence/${correspondenceId}`,
        method: 'PUT',
        body,
        invalidate: ['/v2/correspondence'],
      })
    },
    deleteCorrespondence: async (correspondenceId: string) => {
      return await mutate<CorrespondenceModel>({
        route: `/v2/correspondence/${correspondenceId}`,
        method: 'DELETE',
        invalidate: ['/v2/correspondence'],
      })
    },
  }
}
