import { findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  FilterPreferredSupplierSortKey,
  PreferredSupplierAddDrawer,
  PreferredSupplierList,
  PreferredSupplierPopulatedModel,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier'
import { useUserAccess } from '@cotiss/user'
import {
  Banner,
  Button,
  FilterDrawer_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  Filter_DEPRECATED,
  FourOhThreePage,
  Header,
  Icon,
  Page,
  PageContent,
  PaginationModel,
  TabModel,
  Tabs,
  Text,
  Tooltip,
  TransitionContainer,
  datetimeService,
  filterService_DEPRECATED,
  localStorageService,
  routerService,
  sentryService,
  useAnalytics,
  useCallout,
  useSortTable,
  useToast,
  useTransition,
  utilService,
} from '@cotiss/common'

export type ContactListTab = 'archived' | 'active'

const CONTACT_LIST_TABS: TabModel<ContactListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const PreferredSupplierListPage = memo(() => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { pathname } = useLocation()
  const { replace, push } = useHistory()
  const { permissions } = useUserAccess()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const { tab } = useParams<{ tab?: ContactListTab }>()
  const [isDownloading, setIsDownloading] = useState(false)
  const [pagination, setPagination] = useState<PaginationModel>()
  const { filterPreferredSupplier, exportCsv } = useMutatePreferredSupplier()
  const [filterFields, setFilterFields] = useState<FilterFieldOptions_DEPRECATED>({})
  const [preferredSuppliers, setPreferredSupplier] = useState<PreferredSupplierPopulatedModel[]>([])
  const [filters, setFilters] = useState<Array<Filter_DEPRECATED>>(filterService_DEPRECATED.getFiltersFromUrl())
  const { sortKey, sortDirection, onSort } = useSortTable<FilterPreferredSupplierSortKey>({ initialKey: 'organisationName' })
  const [isBannerHidden, setIsBannerHidden] = useState(Boolean(localStorageService.getItem('preferred-supplier-list-banner-hidden')))
  const { step, transition, onTransition } = useTransition({ initialStep: tab ? findIndex(CONTACT_LIST_TABS, ({ id }) => id === tab) + 1 : 0 })

  useEffect(() => {
    track('preferred_supplier_list_view')

    if (!tab) {
      replace(routerService.getHref('/preferred-supplier/list/:tab?', { tab: 'active' }))
    }
  }, [])

  useEffect(() => {
    const queryParams = utilService.generateUrlSearchParams({ filters: JSON.stringify(filters) })

    replace(`${pathname}${filters.length ? queryParams : ''}`)
  }, [filters])

  const processedFilters = useMemo(() => {
    return [
      ...filters,
      // step 2 is the archived tab
      {
        field: 'archived',
        operation: step === 2 ? 'IS_TRUE' : 'IS_FALSE',
        value: true,
      },
    ] as Filter_DEPRECATED[]
  }, [filters, step])

  const refreshPreferredSuppliers = async () => {
    try {
      setIsLoading(true)

      const { preferredSuppliers, meta, pagination } = await filterPreferredSupplier({
        filters: processedFilters,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setPreferredSupplier(preferredSuppliers)
      setPagination(pagination)

      // Remove archived filter from the UI; the user cannot override it so they needn't see it in the UI
      Object.hasOwn(meta, 'archived') && delete meta.archived

      setFilterFields(meta)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    refreshPreferredSuppliers()
  }, [processedFilters, currentPage, sortKey, sortDirection])

  if (!permissions.isBuyer) {
    return <FourOhThreePage />
  }

  const handleHideBanner = () => {
    localStorageService.setItem('preferred-supplier-list-banner-hidden', 'true')
    setIsBannerHidden(true)
  }

  const handleTabChange = (_tab: ContactListTab) => {
    if (tab === 'active') {
      track('preferred_supplier_list_active_view')
    }

    if (tab === 'archived') {
      track('preferred_supplier_list_archived_view')
    }

    const newStep = findIndex(CONTACT_LIST_TABS, ({ id }) => id === _tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    push(routerService.getHref('/preferred-supplier/list/:tab?', { tab: _tab }))
  }

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({ filters: processedFilters, timeZone: datetimeService.getLocalTimeZone() })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `contact_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const handleSetFilters = (filters: Filter_DEPRECATED[]) => {
    setCurrentPage(1)
    setFilters(filters)
  }

  const renderBanner = () => {
    if (isBannerHidden) return null
    return (
      <Banner className="relative flex items-center mb-8" variant="secondary">
        <div className="flex items-center justify-center bg-white rounded-md w-9 h-9 mr-4">
          <Icon icon="bullet-list" variant="secondary" size={20} />
        </div>
        <div>
          <Text className="font-semibold" variant="heading">
            View your list of contacts
          </Text>
          <Text size="sm">Contacts can be added directly to the contact list or through a procurement invitation</Text>
        </div>
        <Button className="absolute top-0 right-0" onClick={handleHideBanner} state="ghost">
          <Icon icon="cross" size={16} />
        </Button>
      </Banner>
    )
  }

  const renderButtons = () => {
    return (
      <div className="flex items-center justify-between">
        <Button
          size="xs"
          variant="secondary"
          state="outline"
          className="mb-4"
          isDisabled={isLoading}
          onClick={() => openNarrowDrawer(<FilterDrawer_DEPRECATED filters={filters} filterFields={filterFields} setFilters={handleSetFilters} />)}
        >
          + Filters ({filters.length})
        </Button>
        <Tooltip tooltip="Download a CSV of contacts including applied filters" width={200}>
          <Button size="xs" variant="secondary" className="mb-4" isDisabled={isLoading} isLoading={isDownloading} onClick={handleDownloadCsv}>
            <Icon icon="download-01" className="mr-2" /> Download CSV
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          Contacts
        </Text>
        <Button onClick={() => openDrawer(<PreferredSupplierAddDrawer onClose={refreshPreferredSuppliers} />)} size="sm">
          + Add contact
        </Button>
      </Header>
      <PageContent>
        {renderBanner()}
        <Tabs<ContactListTab>
          className="border-b border-gray-300 w-full mb-8"
          tab={tab}
          tabs={CONTACT_LIST_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          {renderButtons()}
          <TransitionContainer key={step} transition={transition}>
            <PreferredSupplierList
              isLoading={isLoading}
              preferredSuppliers={preferredSuppliers}
              refreshPreferredSuppliers={refreshPreferredSuppliers}
              isFiltered={Boolean(processedFilters.length)}
              pagination={pagination}
              onSort={onSort}
              onPageChange={setCurrentPage}
            />
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
