import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { userService } from '@cotiss/user'
import { TenderUpdatePopulatedModel, TenderUpdateViewModel, useListTenderUpdateView } from '@cotiss/tender-update'
import { TenderResponseInviteModal, TenderResponsePopulatedModel, useListTenderResponse } from '@cotiss/tender-response'
import {
  Badge,
  Drawer,
  Icon,
  NoDataPlaceholder,
  ScrollableTable,
  ScrollableTableColumn,
  TableRowCta,
  Text,
  datetimeService,
  paginationService,
  routerService,
  sortService,
  useCallout,
  useSortTable,
} from '@cotiss/common'

type TenderResponseWithUpdateView = TenderResponsePopulatedModel & { tenderUpdateView?: TenderUpdateViewModel }

type SortKey = 'organisationName' | 'status' | 'dateViewed'

type Props = {
  tenderId: string
  tenderUpdate: TenderUpdatePopulatedModel
  isEditable?: boolean
}

export const TenderUpdateViewDrawer = memo(({ tenderId, tenderUpdate, isEditable }: Props) => {
  const { openModal, closeDrawer } = useCallout()
  const {
    tenderUpdateViews,
    count: tenderUpdateViewsCount,
    isLoading: isTenderUpdateViewsLoading,
  } = useListTenderUpdateView({ tenderUpdateId: tenderUpdate._id })
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId })
  const isLoading = isTenderUpdateViewsLoading || isTenderResponsesLoading

  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'status' })
  const { processedTenderResponses, pagination } = useMemo(() => {
    const tenderResponsesWithViews: TenderResponseWithUpdateView[] = tenderResponses.map((tenderResponse) => {
      const tenderUpdateView = tenderUpdateViews.find((view) => view.organisation === tenderResponse.procurementResponse.supplier._id)

      return { ...tenderResponse, tenderUpdateView }
    })

    const result = tenderResponsesWithViews.sort((a, b) => {
      if (sortKey === 'organisationName') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'status') {
        return sortService.sortNumber(a.tenderUpdateView ? 1 : 0, b.tenderUpdateView ? 1 : 0)
      } else if (sortKey === 'dateViewed') {
        if (a.tenderUpdateView && b.tenderUpdateView) {
          return sortService.sortDate(a.tenderUpdateView.createdAt, b.tenderUpdateView.createdAt)
        }
        return a.tenderUpdateView ? -1 : 1
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedTenderResponses, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedTenderResponses, pagination }
  }, [tenderResponses, tenderUpdateViews, sortKey, sortDirection, currentPage])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Name',
      onSort: () => onSort('organisationName'),
      rows: map(processedTenderResponses, (tenderResponse) => ({
        content: () => (
          <Text className="truncate" font="jakarta">
            {tenderResponse.procurementResponse.supplier.name}
          </Text>
        ),
        cta: (
          <TableRowCta
            cta={{
              label: (
                <>
                  View <Icon className="ml-1" icon="arrow-right" />
                </>
              ),
              href: routerService.getHref('/supplier/view/:id/:tab?', { id: tenderResponse.procurementResponse.supplier._id }),
              isOpenNewTab: true,
            }}
          />
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      onSort: () => onSort('status'),
      rows: map(processedTenderResponses, (tenderResponse) => ({
        content: () =>
          tenderResponse.tenderUpdateView ? (
            <Badge variant="success" state="dot">
              Viewed
            </Badge>
          ) : (
            <Badge variant="danger" state="dot">
              Not viewed
            </Badge>
          ),
      })),
    },
    {
      heading: 'Viewed by',
      rows: map(processedTenderResponses, (tenderResponse) => ({
        content: () => (
          <Text variant="light" size="sm">
            {tenderResponse.tenderUpdateView ? userService.getFullName(tenderResponse.tenderUpdateView.createdBy) : '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date viewed',
      onSort: () => onSort('dateViewed'),
      rows: map(processedTenderResponses, (tenderResponse) => ({
        content: () => (
          <Text variant="light" size="sm">
            {tenderResponse.tenderUpdateView ? datetimeService.format(tenderResponse.tenderUpdateView.createdAt, 'do MMM yyyy') : '--'}
          </Text>
        ),
      })),
    },
  ]

  const renderHeader = () => (
    <Text className="font-semibold truncate" size="h5" variant="heading" font="jakarta">
      <Text className="font-semibold" size="h5" variant="light" font="jakarta" isInline>
        Views ({tenderUpdateViewsCount}):{' '}
      </Text>
      {tenderUpdate.title}
    </Text>
  )

  const renderContent = () => {
    if (!isLoading && !processedTenderResponses.length) {
      return (
        <div className="flex items-center justify-center h-full w-full">
          <NoDataPlaceholder
            variant="white"
            label="There are currently no contacts active on this tender"
            ctaLabel="+ Invite contacts"
            onCtaClick={() => {
              openModal(<TenderResponseInviteModal tenderId={tenderId} isEditable={isEditable} />)
              closeDrawer()
            }}
            isDisabled={!isEditable}
          />
        </div>
      )
    }

    return (
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    )
  }

  return <Drawer header={renderHeader()}>{renderContent()}</Drawer>
})
