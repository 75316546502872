import React, { useState } from 'react'
import { Select_DEPRECATED, SelectProps_DEPRECATED, sentryService, useToast } from '@cotiss/common'

type Props<T> = Omit<SelectProps_DEPRECATED<T>, 'onSubmit' | 'onChange'> & {
  onSubmit: (value: T) => Promise<void>
}

export function AsyncSelect<T extends string>({ onSubmit, value, isDisabled, ...rest }: Props<T>) {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)

  const handleChange = async (value: T) => {
    try {
      setIsSaving(true)
      await onSubmit(value)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return <Select_DEPRECATED<T> {...rest} value={value} onChange={handleChange} isDisabled={isDisabled || isSaving} />
}
