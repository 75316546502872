import { map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { AppErrorPage } from '@cotiss/app'
import { DocumentViewer, documentService, useGetDocument } from '@cotiss/document'
import {
  EvaluationEventEvaluationCommentDrawer,
  EvaluationEventEvaluationStatusBadge,
  EvaluationEventScoreDrawer,
  useEvaluation,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationSubmission,
  useEvaluationSubmissionDocument,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Breadcrumb,
  BreadcrumbModel,
  Button,
  FourOhFourPage,
  Header,
  Page,
  PageContent,
  Skeleton,
  Table,
  TableColumn,
  TableRowCta,
  Text,
  Tooltip,
  routerService,
  sentryService,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

export const EvaluationEventEvaluateViewPage = memo(() => {
  const { replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const [documentIdToPreview, setDocumentIdToPreview] = useState('')
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { document: documentToPreview } = useGetDocument(documentIdToPreview)
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationEnvelope, queryEvaluationEnvelopeView } = useEvaluationEnvelope()
  const { evaluation, queryEvaluationView, queryEvaluationOverviewView } = useEvaluation()
  const { evaluationSubmission, queryEvaluationSubmissionView } = useEvaluationSubmission()
  const { evaluationEventId, evaluationId } = useParams<{ evaluationEventId: string; evaluationId: string }>()
  const { evaluationSubmissionDocuments, queryEvaluationSubmissionDocumentList } = useEvaluationSubmissionDocument()
  const { evaluationUser, evaluationUserInSession, queryEvaluationUserView, queryEvaluationUserInSessionView } = useEvaluationUser()

  const backHref = routerService.getHref(
    '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
    {
      evaluationEventId: evaluationEvent?.id || '',
      evaluationEnvelopeId: evaluationEnvelope?.id || '',
      evaluationUserId: evaluationUser?.id || '',
    }
  )

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: routerService.getHref('/evaluation-event/list/:tab?'),
    },
    {
      label: evaluationEvent?.name || '',
      href: routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId }),
      isLoading,
    },
    {
      label: evaluationEnvelope ? `Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}` : '',
      href: backHref,
      isLoading,
    },
    {
      label: evaluationSubmission?.name || '',
      isLoading,
    },
  ]

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  useAsyncEffect(async () => {
    try {
      const [evaluationEvent, evaluationUserInSession] = await Promise.all([
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
      ])

      if (evaluationEvent?.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        replace(backHref)
        return
      }

      const [evaluation] = await Promise.all([queryEvaluationView({ evaluationId }), queryEvaluationOverviewView({ evaluationId })])

      const [{ evaluationSubmissionDocuments }] = await Promise.all([
        queryEvaluationSubmissionDocumentList({
          filter: {
            evaluationEventId,
            evaluationSubmissionId: evaluation.evaluationSubmissionId,
            evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          },
        }),
        queryEvaluationEnvelopeView({ evaluationEnvelopeId: evaluation.evaluationEnvelopeId }),
        queryEvaluationUserView({ evaluationUserId: evaluation.evaluationUserId }),
        queryEvaluationSubmissionView({ evaluationSubmissionId: evaluation.evaluationSubmissionId }),
      ])
      setDocumentIdToPreview(evaluationSubmissionDocuments[0]?.documentId || '')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Document',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileName }) => ({
          content: () => (
            <Text title={fileName} className="truncate" variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>
              {fileName}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{ label: 'Preview', onClick: () => setDocumentIdToPreview(documentId) }}
              actions={[{ label: 'Download', onClick: () => setDocumentIdToDownload(documentId) }]}
            />
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileSize }) => ({
          content: () => <Text variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>{documentService.formatSize(fileSize)}</Text>,
        })),
      },
    ]

    return { columns }
  }, [evaluationSubmissionDocuments, documentIdToPreview])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationSubmission && (
                <div className="flex items-center truncate">
                  <Text className="font-medium" size="h7" variant="light" font="jakarta">
                    Evaluate
                  </Text>
                  <Text className="font-medium truncate ml-1" size="h7" variant="heading" font="jakarta">
                    {evaluationSubmission.name || evaluationSubmission.organisation?.name}
                  </Text>
                  {evaluation && <EvaluationEventEvaluationStatusBadge className="ml-2" status={evaluation.status} />}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center ml-4">
            {evaluationUserInSession && evaluation && evaluationUserInSession.id === evaluation.evaluationUserId && (
              <Tooltip
                tooltip="Notes cannot be edited once moderation has begun."
                position="bottom"
                isEnabled={evaluationEnvelope?.status !== 'evaluate'}
              >
                <Button
                  className="mr-2"
                  onClick={() => openNarrowDrawer(<EvaluationEventEvaluationCommentDrawer evaluation={evaluation} />)}
                  size="sm"
                  variant="secondary"
                  state="translucent"
                  isDisabled={evaluationEnvelope?.status !== 'evaluate'}
                >
                  Add note
                </Button>
              </Tooltip>
            )}
            <Button onClick={() => openDrawer(<EvaluationEventScoreDrawer />, { willCloseOnClickOutside: true })} size="sm" variant="secondary">
              {evaluationEnvelope?.status === 'evaluate' ? 'Score criteria' : 'View scores'}
            </Button>
          </div>
        </div>
      </Header>

      <div className="flex justify-between shrink-0 h-[calc(100%-84px)] w-full">
        <PageContent className="w-full">
          <div className="bg-gray-100 rounded-lg w-full p-6 aspect-[1/1.414]">
            {documentToPreview && (
              <DocumentViewer
                document={documentToPreview}
                isDownloadable
                onClickDownload={() => track('evaluation_event_evaluate_view_document_download_view')}
                onClickOpenInNewTab={() => track('evaluation_event_evaluate_view_document_new_tab_view')}
              />
            )}
          </div>
        </PageContent>
        <div className="shrink-0 bg-white overflow-y-scroll w-[400px] min-h-full">
          <Table columns={columns} isLoading={isLoading} />
        </div>
      </div>
    </Page>
  )
})
