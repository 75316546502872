import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, useCallout } from '@cotiss/common'
import React, { FormEvent, memo, useState } from 'react'

type FormData = {
  label: string
}

type Props = {
  value?: string
  onSubmit: (value: string) => void
}

export const SettingsModulesMetafieldCreateUpdateModal = memo(({ value, onSubmit }: Props) => {
  const { closeModal } = useCallout()
  const [formData, setFormData] = useState<FormData>({
    label: value ?? '',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(formData.label)
    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={value ? 'Edit dropdown option' : 'Add dropdown option'} />
      <ModalContent>
        <Label>Option label</Label>
        <Input
          value={formData.label}
          onChange={({ target }) => setFormData({ ...formData, label: target.value })}
          placeholder="Enter option label"
          maxLength={100}
          isRequired
          autoFocus
        />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
