import { useHistory } from 'react-router-dom'
import { filter, flatMap, groupBy, keyBy, map, some } from 'lodash'
import React, { FormEvent, Fragment, memo, useMemo, useState } from 'react'
import { UserSelect, useListUser } from '@cotiss/user'
import {
  evaluationEventService,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Form,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  sentryService,
  useCallout,
  useToast,
  Button,
  Text,
  routerService,
  Select_DEPRECATED,
  SelectOption_DEPRECATED,
} from '@cotiss/common'

export type EvaluationEventPanelAccessOption = 'no-access' | 'moderate' | 'evaluate' | 'both'

type FormData = {
  userId: string
  access: Record<string, EvaluationEventPanelAccessOption>
}

export const EvaluationEventPanelMemberAddModal = memo(() => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { closeModal, closeFullModal } = useCallout()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { users, isLoading } = useListUser({ permissions: ['EVALUATOR'] })
  const { evaluationUsers, mutateCreateEvaluationUser } = useEvaluationUser()
  const [formData, setFormData] = useState<FormData>({ userId: '', access: {} })
  const { userOptions, accessOptionsByEnvelopeId, criteriaByEnvelopeId } = useMemo(() => {
    const allAccessControls = flatMap(evaluationUsers, ({ accessControls }) => accessControls)

    const accessOptionsByEnvelopeId = keyBy(
      map(evaluationEnvelopes, ({ id: envelopeId }) => {
        const envelopeAccessControls = filter(allAccessControls, { resourceType: 'envelope', resourceId: envelopeId })
        const options: SelectOption_DEPRECATED<EvaluationEventPanelAccessOption>[] = [
          {
            value: 'no-access',
            label: 'No access',
          },
          {
            value: 'evaluate',
            label: 'Evaluate',
          },
        ]

        if (!some(envelopeAccessControls, { access: 'moderate' })) {
          options.push({ value: 'moderate', label: 'Moderate' })
          options.push({ value: 'both', label: 'Moderate and evaluate' })
        }

        return { envelopeId, options }
      }),
      'envelopeId'
    )

    // Filter out any users that are already added to the evaluation event with access controls. I.e. are already panel members.
    const userOptions = filter(users, ({ _id }) => !some(evaluationUsers, ({ userId, accessControls }) => _id === userId && accessControls.length))

    const criteriaByEnvelopeId = groupBy(evaluationCriteria, 'evaluationEnvelopeId')

    return { userOptions, accessOptionsByEnvelopeId, criteriaByEnvelopeId }
  }, [evaluationEvent, evaluationUsers, users])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.userId || !evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_panel_add_panel_submit')

    try {
      setIsSaving(true)

      const accessControls = evaluationEventService.getAccessControlInput({ accessByEvaluationEnvelopeId: formData.access, criteriaByEnvelopeId })

      await mutateCreateEvaluationUser({ evaluationEventId: evaluationEvent.id, userId: formData.userId, accessControls })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleClickOpenSettings = () => {
    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
    closeModal()
    closeFullModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add panel members" />
      <ModalContent>
        <Text className="font-semibold">Select panel member</Text>
        <Text className="mt-1" size="sm" variant="light">
          Select from the list of organisation users. New users can be added to your organisation in your
          <Button className="ml-1" state="text" variant="link" size="sm" onClick={handleClickOpenSettings} isDisabled={isSaving}>
            Settings.
          </Button>
        </Text>
        <Label className="mt-4">User</Label>
        <UserSelect
          value={formData.userId}
          options={userOptions}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving || isLoading}
          isRequired
        />

        <Text className="font-semibold mt-6">Assign access</Text>
        <Text className="mt-1" size="sm" variant="light">
          Select the type of access this user has to each envelope. Panel members can have multiple access types. There can only be one moderator
          maximum per envelope.
        </Text>
        {map(evaluationEnvelopes, ({ id, name, order }) => (
          <Fragment key={id}>
            <Label className="mt-4">
              Envelope {order}:{' '}
              <Text variant="light" isInline>
                {name}
              </Text>
            </Label>
            <Select_DEPRECATED<EvaluationEventPanelAccessOption>
              value={formData.access[id]}
              options={accessOptionsByEnvelopeId[id]?.options || []}
              onChange={(access) => setFormData({ ...formData, access: { ...formData.access, [id]: access } })}
              isDisabled={isSaving}
              isRequired
            />
          </Fragment>
        ))}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
