import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationEventDocumentsInput,
  GqlCreateEvaluationEventDocumentsMutation,
  GqlCreateEvaluationEventDocumentsMutationVariables,
  GqlEvaluationEventDocumentFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationEventDocuments = async (input: GqlCreateEvaluationEventDocumentsInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEventDocumentsMutation, GqlCreateEvaluationEventDocumentsMutationVariables>({
    mutation: gql`
      ${EVALUATION_EVENT_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationEventDocuments($input: CreateEvaluationEventDocumentsInput!) {
        createEvaluationEventDocuments(input: $input) {
          ...EvaluationEventDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEventDocuments as GqlEvaluationEventDocumentFieldsFragment[]
}
