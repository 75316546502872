import React, { useState, useCallback, memo, ReactNode, useContext, useMemo, createContext } from 'react'
import { ToastModel, ToastNotification, ToastVariant } from '@cotiss/common'

export type ToastContext = {
  toast: ToastModel | null
  openToast(content: ReactNode, variant?: ToastVariant): void
  closeToast(): void
}

const ToastContext = createContext<ToastContext | null>(null)

// Auto dismiss toasts after 3 seconds.
const TOAST_DISMISS_TIMEOUT = 3000

type Props = {
  children: ReactNode
}

// @TODO handle multiple toasts
export const ToastProvider = memo(({ children }: Props) => {
  const [toast, setToast] = useState<ToastModel | null>(null)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const openToast = useCallback(
    (children: ReactNode, variant: ToastVariant = 'success') => {
      clearTimeout(timer as NodeJS.Timeout)

      setToast({ children, variant })

      if (variant === 'success') {
        const timer = setTimeout(() => {
          setToast(null)
        }, TOAST_DISMISS_TIMEOUT)

        setTimer(timer)
      }
    },
    [timer]
  )

  const closeToast = useCallback(() => {
    clearTimeout(timer as NodeJS.Timeout)
    setToast(null)
  }, [timer])

  const memoizedContextValue = useMemo(
    () => ({
      openToast,
      toast,
      closeToast,
    }),
    [toast]
  )

  return (
    <ToastContext.Provider value={memoizedContextValue}>
      {children}
      <ToastNotification />
    </ToastContext.Provider>
  )
})

export const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('This component must be used within a <ToastProvider> component.')
  }

  return context
}
