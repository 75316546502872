import React, { FormEvent, memo, useState } from 'react'
import { Button, Form, Icon, Label, RegionMultiSelect, Text, useCallout } from '@cotiss/common'

type Props = {
  regions: string[]
  onChange: (regions: string[]) => void
}

export const SupplierOnboardingRegionsModal = memo(({ regions, onChange }: Props) => {
  const { closeModal } = useCallout()
  const [stateRegions, setStateRegions] = useState<string[]>(regions)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onChange(stateRegions)
    closeModal()
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square">
        <Icon icon="cross" />
      </Button>
      <div className="py-6 px-10">
        <Text className="font-semibold" size="h5">
          Select Regions
        </Text>

        <Label>Regions</Label>
        <RegionMultiSelect value={stateRegions} onChange={setStateRegions} />
        <div className="flex justify-end mt-4">
          <Button type="submit" variant="secondary">
            Confirm Selection
          </Button>
        </div>
      </div>
    </Form>
  )
})
