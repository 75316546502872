import { gql } from '@apollo/client'

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFields on Pagination {
    currentPage
    itemCount
    pageSize
    pageCount
    totalCount
  }
`
