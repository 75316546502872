import { filter, findIndex } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  Breadcrumb,
  BreadcrumbModel,
  Button,
  Header,
  PageContent,
  Section,
  StepModel,
  Steps,
  Text,
  TransitionContainer,
  TransitionType,
  routerService,
  useAnalytics,
  useCallout,
} from '@cotiss/common'
import {
  ContractWizardMetadataStep,
  ContractWizardContractStep,
  ContractWizardSignersStep,
  ContractWizardApproversStep,
  ContractWizardSummaryStep,
  useGetContractShell,
  ContractApprovalStatusBadge,
  ContractRejectedFeedbackModal,
  contractService,
  ContractWizardExerciseStep,
  ContractWizardCessationStep,
  ContractWizardScheduleRatesStep,
  ContractApprovalStatus,
  ContractWizardOverviewStep,
} from '@cotiss/contract'
import { useHistory } from 'react-router-dom'

type Step =
  | 'cessation'
  | 'metadata'
  | 'price'
  | 'contract'
  | 'signers'
  | 'approvers'
  | 'summary'
  | 'exercise'
  | 'schedule-rates'
  | 'milestones'
  | 'overview'

type Props = {
  contractShellId: string
  onClose?: () => void
}

export const ContractWizardFullModal = memo(({ contractShellId, onClose }: Props) => {
  const [stepIndex, setStepIndex] = useState(0)
  const { openModal, closeFullModal } = useCallout()
  const { contractShell } = useGetContractShell(contractShellId)
  const [transition, setTransition] = useState<TransitionType>('right')
  const { push } = useHistory()
  const { track } = useAnalytics()

  useEffect(() => {
    track('contract_wizard_view')
  }, [])

  const { contract, steps, approval, areChangesRequested } = useMemo(() => {
    const contract = contractShell && contractService.getContract(contractShell, ['DRAFTING'])
    const approval = contractService.getApproval(contract?.approvals)
    const variationTypes = contract?.variationTypes
    // TODO: remove this when 'changes requested' status introduced
    const areChangesRequested = contractService.getAreChangesRequested(contract?.approvals)

    // Control the steps the user sees in the step nav
    const steps: (StepModel<Step> & { isHidden?: boolean })[] = [
      { id: 'metadata', label: 'Terms & metadata', isHidden: Boolean(variationTypes?.length && !variationTypes.includes('metadata_terms')) },
      {
        id: 'schedule-rates',
        label: 'Schedule of rates',
        isHidden: Boolean(variationTypes?.length && !variationTypes.includes('scheduled_rates')),
      },
      {
        id: 'overview',
        label: 'Contract overview',
        isHidden: Boolean(variationTypes?.length && !variationTypes.includes('price_duration') && !variationTypes.includes('milestone')),
      },
      { id: 'exercise', label: 'Exercise period', isHidden: Boolean(variationTypes?.length && !variationTypes.includes('contract_renewal')) },
      { id: 'cessation', label: 'Cessation of contract', isHidden: !variationTypes?.includes('cessation') },
      { id: 'contract', label: 'Contract' },
      { id: 'signers', label: 'Signers', isHidden: !contract?.documentShells.length },
      { id: 'approvers', label: 'Approvers' },
      { id: 'summary', label: 'Summary' },
    ]

    return { contract, steps: filter(steps, (step) => !step.isHidden), approval, areChangesRequested }
  }, [contractShell?.contracts])

  const handleClose = () => {
    track('contract_wizard_exit')
    closeFullModal()
    onClose && onClose()
  }

  // TODO: eventually move these to a prop so we can open this modal from different pages
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Contracts',
      onClick: () => {
        push(routerService.getHref('/contract/list'))
        handleClose()
      },
    },
    ...(contract?.variationTypes.length
      ? [
          {
            label: `${contractShell?.title || ''}${contract?.metadata?.internalReference ? ` (${contract?.metadata?.internalReference})` : ''}`,
            onClick: handleClose,
          },
        ]
      : []),
    {
      label: contract?.variationTypes.length
        ? 'Variation request'
        : `Create contract${contract?.metadata?.internalReference ? ` (${contract?.metadata?.internalReference})` : ''}`,
    },
  ]

  const handleStepChange = (newStep: StepModel<Step>) => {
    const oldStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id)
    const newStepIndex = findIndex(steps, ({ id }) => id === newStep.id)

    setTransition(newStepIndex > oldStepIndex ? 'right' : 'left')
    setTimeout(() => setStepIndex(newStepIndex), 0)
  }

  const handleNext = () => {
    const newStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id) + 1

    steps[newStepIndex] && handleStepChange(steps[newStepIndex])
  }

  const handleBack = () => {
    const newStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id) - 1

    newStepIndex >= 0 && handleStepChange(steps[newStepIndex])
  }

  const props = {
    onBack: handleBack,
    onNext: handleNext,
    contractShellId,
  }

  // This will only ever happen momentarily, usually just before the modal closes
  if (!steps[stepIndex]?.id) {
    return null
  }

  return (
    <div className="bg-primary-50 w-full h-full min-h-screen overflow-scroll">
      <Header>
        <Section isCentered>
          <div className="flex items-center justify-between">
            <div className="w-full">
              <Breadcrumb breadcrumbs={breadcrumbs} />
              <div className="flex items-center">
                <Text className="font-semibold mr-2" size="h4" variant="heading" font="jakarta">
                  {Boolean(contract?.variationTypes.length) && 'Variation request'}
                  {!contract?.variationTypes.length && (areChangesRequested ? 'Contract request rejected' : 'Create contract')}
                </Text>
                {(approval?.status || areChangesRequested) && (
                  <ContractApprovalStatusBadge
                    status={areChangesRequested ? 'REJECTED' : (approval?.status as ContractApprovalStatus)}
                    state="outline"
                  />
                )}
              </div>
            </div>
            {areChangesRequested && contract && (
              <Button className="ml-2" onClick={() => openModal(<ContractRejectedFeedbackModal contract={contract} />)} variant="tertiary" size="sm">
                View approval feedback
              </Button>
            )}

            <Button className="ml-2" onClick={handleClose} state="ghost" variant="link" size="sm">
              {areChangesRequested ? 'Exit' : 'Save and exit'}
            </Button>
          </div>
        </Section>
      </Header>
      <PageContent>
        <Section isCentered>
          <Steps<Step> className="mb-8" steps={steps} step={steps[stepIndex]} onChange={handleStepChange} />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={steps[stepIndex].id} transition={transition}>
              {steps[stepIndex].id === 'metadata' && <ContractWizardMetadataStep {...props} onBack={stepIndex === 0 ? null : props.onBack} />}
              {steps[stepIndex].id === 'schedule-rates' && (
                <ContractWizardScheduleRatesStep {...props} onBack={stepIndex === 0 ? null : props.onBack} />
              )}
              {steps[stepIndex].id === 'cessation' && <ContractWizardCessationStep {...props} onBack={stepIndex === 0 ? null : props.onBack} />}
              {steps[stepIndex].id === 'overview' && <ContractWizardOverviewStep {...props} onBack={stepIndex === 0 ? null : props.onBack} />}
              {steps[stepIndex].id === 'exercise' && <ContractWizardExerciseStep {...props} onBack={stepIndex === 0 ? null : props.onBack} />}
              {steps[stepIndex].id === 'contract' && <ContractWizardContractStep {...props} />}
              {steps[stepIndex].id === 'signers' && <ContractWizardSignersStep {...props} />}
              {steps[stepIndex].id === 'approvers' && <ContractWizardApproversStep {...props} />}
              {steps[stepIndex].id === 'summary' && <ContractWizardSummaryStep {...props} onNext={closeFullModal} />}
            </TransitionContainer>
          </AnimatePresence>
        </Section>
      </PageContent>
    </div>
  )
})
