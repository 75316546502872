import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlImportEvaluationSubmissionsFromLegacyTenderInput,
  GqlImportEvaluationSubmissionsFromLegacyTenderMutation,
  GqlImportEvaluationSubmissionsFromLegacyTenderMutationVariables,
} from '@gql'

export const mutateImportEvaluationSubmissionsFromLegacyTender = async (input: GqlImportEvaluationSubmissionsFromLegacyTenderInput) => {
  const { data } = await apolloService.mutate<
    GqlImportEvaluationSubmissionsFromLegacyTenderMutation,
    GqlImportEvaluationSubmissionsFromLegacyTenderMutationVariables
  >({
    mutation: gql`
      mutation ImportEvaluationSubmissionsFromLegacyTender($input: ImportEvaluationSubmissionsFromLegacyTenderInput!) {
        importEvaluationSubmissionsFromLegacyTender(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.importEvaluationSubmissionsFromLegacyTender as boolean
}
