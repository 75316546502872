export const AUTH_ROUTES = [
  '/forgot-password',
  '/join-account/:email',
  '/login',
  '/sso-login/:token',
  '/masquerade/:token',
  '/logout',
  '/reset-password/:token',
  '/sign-up',
  '/sign-up/buyer',
  '/sign-up/supplier',
  '/sign-up/user/:invitationCode',
  '/sso-configuration',
  '/sso-error',

  // ! This is a legacy route that we want to support incase there are any old invitation emails hanging around.
  '/user-signup',
] as const

export const AUTH_QUERY_KEYS = ['/v2/auth/domain', '/v3/sso', '/v3/auth'] as const
