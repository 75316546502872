import { isAfter, parseISO } from 'date-fns'
import { datetimeService, OcdsCurrencyCode, utilService } from '@cotiss/common'
import { GqlPerformanceScorecardMetricCycleFieldsFragment, GqlPerformanceMetricFieldsFragment } from '@gql'

type FormatPerformanceValue = {
  value: number
  performanceMetric: GqlPerformanceMetricFieldsFragment
}

class PerformanceService {
  getPerformanceMetricCycleStatus = ({ dateTo, status }: GqlPerformanceScorecardMetricCycleFieldsFragment) => {
    if (status === 'complete') {
      return 'complete'
    }

    if (isAfter(new Date(), parseISO(dateTo))) {
      return 'overdue'
    }

    return 'active'
  }

  getPerformanceMetricCycleLabel = ({ dateFrom, dateTo }: GqlPerformanceScorecardMetricCycleFieldsFragment) => {
    return `${datetimeService.format(dateFrom, 'do MMM yyyy')} – ${datetimeService.format(dateTo, 'do MMM yyyy')}`
  }

  formatPerformanceValue = ({ value, performanceMetric }: FormatPerformanceValue) => {
    if (performanceMetric.metricUnit === 'percentage') {
      return utilService.formatAsPercentage(value)
    }

    if (performanceMetric.metricUnit === 'currency') {
      return utilService.formatAsCurrency(value, performanceMetric.metricUnitType as OcdsCurrencyCode)
    }

    return utilService.formatAsNumber(value)
  }
}

export const performanceService = new PerformanceService()
