import { useFetch } from '@cotiss/common'
import { ContractShellPopulatedModel } from '@cotiss/contract'

export const useGetContractShell = (id: string) => {
  const { data: contractShell, ...rest } = useFetch<ContractShellPopulatedModel>('/v2/contract-shells', {
    route: `/v2/contract-shells/${id}`,
    isEnabled: Boolean(id),
  })

  return { contractShell, ...rest }
}
