import { slice } from 'lodash'

type PaginateParam = {
  currentPage?: number
  pageSize?: number
}

type PaginateResult<T> = {
  items: T[]
  pagination: {
    currentPage: number
    pageSize: number
    pageCount: number
    itemCount: number
    totalCount: number
  }
}

class PaginationService {
  paginate<T>(entities: T[], { currentPage = 1, pageSize = 20 }: PaginateParam = {}): PaginateResult<T> {
    const offset = (currentPage - 1) * pageSize
    const items = slice(entities, offset, offset + pageSize)
    const totalCount = entities.length

    return {
      items,
      pagination: {
        currentPage,
        pageSize,
        pageCount: Math.ceil(totalCount / pageSize),
        itemCount: items.length,
        totalCount,
      },
    }
  }

  convertPageSizeToOffset({ currentPage, pageSize }: Required<PaginateParam>) {
    return {
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
    }
  }
}

export const paginationService = new PaginationService()
