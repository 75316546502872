import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlDeleteEvaluationUserAccessControlsInput,
  GqlDeleteEvaluationUserAccessControlsMutation,
  GqlDeleteEvaluationUserAccessControlsMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'

export const mutateDeleteEvaluationUserAccessControls = async (input: GqlDeleteEvaluationUserAccessControlsInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationUserAccessControlsMutation, GqlDeleteEvaluationUserAccessControlsMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation DeleteEvaluationUserAccessControls($input: DeleteEvaluationUserAccessControlsInput!) {
        deleteEvaluationUserAccessControls(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationUserAccessControls as GqlEvaluationUserFieldsFragment
}
