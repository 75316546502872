import { findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useState } from 'react'
import { AppErrorPage } from '@cotiss/app'
import {
  EvaluationEventEnvelopeSubmissionDocumentsTab,
  EvaluationEventEnvelopeSubmissionResourcesTab,
  EvaluationEventModerateEnvelopeSubmissionResultsTab,
  EvaluationEventModerationEvaluatorCommentsDrawer,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationSubmission,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Breadcrumb,
  BreadcrumbModel,
  FourOhFourPage,
  Header,
  Page,
  Skeleton,
  routerService,
  sentryService,
  Text,
  useAsyncEffect,
  TransitionContainer,
  TabModel,
  useTransition,
  Tabs,
  Button,
  useCallout,
} from '@cotiss/common'

export type EvaluationEventModerateEnvelopeSubmissionTab = 'results' | 'documents' | 'resources'
export const EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_TABS: TabModel<EvaluationEventModerateEnvelopeSubmissionTab>[] = [
  { id: 'results', label: 'Results' },
  { id: 'documents', label: 'Documents' },
  { id: 'resources', label: 'Resources' },
]

export const EvaluationEventModerateEnvelopeSubmissionPage = memo(() => {
  const { push, replace } = useHistory()
  const { openNarrowDrawer } = useCallout()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { evaluationSubmission, queryEvaluationSubmissionView } = useEvaluationSubmission()
  const { queryEvaluationUserList, queryEvaluationUserInSessionView } = useEvaluationUser()
  const { evaluationEnvelope, queryEvaluationEnvelopeView, queryEvaluationEnvelopeOverviewView } = useEvaluationEnvelope()
  const { evaluationEventId, evaluationEnvelopeId, evaluationSubmissionId, tab } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationSubmissionId: string
    tab?: EvaluationEventModerateEnvelopeSubmissionTab
  }>()
  const { step, transition, onTransition } = useTransition({
    initialStep: findIndex(EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_TABS, ({ id }) => id === tab) + 1,
  })

  const backHref = routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
    evaluationEventId,
    evaluationEnvelopeId,
  })

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: routerService.getHref('/evaluation-event/list/:tab?'),
    },
    {
      label: evaluationEvent?.name || '',
      href: routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId }),
      isLoading,
    },
    {
      label: evaluationEnvelope ? `Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}` : '',
      href: backHref,
      isLoading,
    },
    {
      label: evaluationSubmission?.name || '',
      isLoading,
    },
  ]

  useEffect(() => {
    if (!tab) {
      replace(
        routerService.getHref(
          '/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?',
          {
            evaluationEventId,
            evaluationEnvelopeId,
            evaluationSubmissionId,
            tab: EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_TABS[0].id,
          }
        )
      )
      return
    }

    const newStep = findIndex(EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  useAsyncEffect(async () => {
    try {
      const [evaluationEvent, evaluationUserInSession] = await Promise.all([
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
        queryEvaluationUserList({ filter: { evaluationEventId } }),
        queryEvaluationEnvelopeView({ evaluationEnvelopeId }),
        queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId }),
        queryEvaluationSubmissionView({ evaluationSubmissionId }),
      ])

      if (evaluationEvent?.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        replace(backHref)
        return
      }
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }
  }, [])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start truncate">
            <div className="truncate">
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationEnvelope && evaluationSubmission && (
                <div className="flex items-center truncate">
                  <Text className="font-medium" size="h7" variant="heading" font="jakarta">
                    Envelope {evaluationEnvelope.order}. {evaluationEnvelope.name} (Moderation):
                  </Text>
                  <Text className="font-medium truncate ml-1" size="h7" variant="light" font="jakarta">
                    {evaluationSubmission.organisation?.name || evaluationSubmission.name}
                  </Text>
                </div>
              )}
            </div>
          </div>
          <Button
            onClick={() => openNarrowDrawer(<EvaluationEventModerationEvaluatorCommentsDrawer />, { willCloseOnClickOutside: true })}
            state="translucent"
            variant="secondary-dark"
            size="sm"
          >
            View evaluator notes
          </Button>
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm overflow-x-auto px-10 z-10">
        <Tabs<EvaluationEventModerateEnvelopeSubmissionTab>
          tab={tab}
          tabs={EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_TABS}
          onChange={({ id: tab }) =>
            push(
              routerService.getHref(
                '/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?',
                {
                  evaluationEventId,
                  evaluationEnvelopeId,
                  evaluationSubmissionId,
                  tab,
                }
              )
            )
          }
          variant="underline"
        />
      </div>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="h-[calc(100%-149px)]" transition={transition}>
          {step === 1 && <EvaluationEventModerateEnvelopeSubmissionResultsTab />}
          {step === 2 && <EvaluationEventEnvelopeSubmissionDocumentsTab />}
          {step === 3 && <EvaluationEventEnvelopeSubmissionResourcesTab />}
        </TransitionContainer>
      </AnimatePresence>
    </Page>
  )
})
