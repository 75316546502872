import { filter, includes } from 'lodash'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { UserModel, UserPermission, USER_PERMISSION_MAP, useGetLoggedInUser, useMutateUser, UserPermissionCard } from '@cotiss/user'
import {
  Form,
  Checkbox,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  NoDataPlaceholder,
  Text,
  sentryService,
  useCallout,
  useToast,
  useFeature,
} from '@cotiss/common'

type FormData = {
  email: string
  permissions: UserPermission[]
}

type Props = {
  user?: UserModel
}

export const UserAddUpdateModal = memo(({ user }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { user: loggedInUser } = useGetLoggedInUser()
  const { createPendingUsers, updateUserPermissions } = useMutateUser()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const [formData, setFormData] = useState<FormData>({
    email: user?.email || '',
    permissions: user?.permissions || [],
  })

  const userPermissionOptions = useMemo(() => {
    let userPermissionOptions: UserPermission[] = ['ADMIN']

    if (!loggedInUser) {
      return userPermissionOptions
    }

    if (loggedInUser.account.productBuyer) {
      userPermissionOptions = [...userPermissionOptions, 'APPROVER', 'CONTRACT_MANAGER', 'EVALUATOR', 'LEGAL', 'PROCUREMENT_MANAGER']

      if (isPerformanceScorecardEnabled) {
        userPermissionOptions = [...userPermissionOptions, 'PERFORMANCE_MANAGER']
      }
    }

    if (loggedInUser.account.productSupplier) {
      userPermissionOptions = [...userPermissionOptions, 'BID_MANAGER']
    }

    return userPermissionOptions
  }, [loggedInUser])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const permissions = formData.permissions

      if (user) {
        await updateUserPermissions(user._id, { permissions })
      } else {
        await createPendingUsers([{ ...formData, permissions }])
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const options = userPermissionOptions?.map((permission) => ({
    label: USER_PERMISSION_MAP[permission],
    value: permission,
    disabled: loggedInUser?._id === user?._id && permission === 'ADMIN',
  }))

  const isEditing = Boolean(user)

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader heading={`${user ? 'Update' : 'Add'} user ${user ? 'roles and permissions' : ''}`} isDisabled={isSaving} />
      <ModalContent>
        <div className="grid grid-cols-12 gap-x-8 ">
          <div className="col-span-5">
            <div className="flex flex-col space-y-4">
              <div>
                <Label>Enter email address</Label>
                <Input
                  value={formData?.email}
                  onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                  maxLength={100}
                  isDisabled={isEditing}
                  placeholder="Enter email"
                  isRequired
                  type="email"
                />
                {!isEditing && (
                  <Text className="mt-2" size="sm" variant="light">
                    They will be emailed an invitation.
                  </Text>
                )}
              </div>
              <div>
                <Text className="font-semibold" size="h5">
                  Assign roles
                </Text>
                <Text className="mt-2" size="sm" variant="light">
                  Select one or more roles for this team member.
                </Text>
              </div>
              {/* iterate through options with line items can contain a checkbox for selecting user permissions */}
              <ul className="space-y-2">
                {options?.map((option, subIdx) => (
                  <li key={subIdx}>
                    <label className="flex items-center cursor-pointer bg-secondary-50 w-full rounded p-2">
                      <Checkbox
                        className="shrink-0 mr-2"
                        onChange={() =>
                          setFormData({
                            ...formData,
                            permissions: includes(formData.permissions, option.value)
                              ? filter(formData.permissions, (permission) => permission !== option.value)
                              : [...formData.permissions, option.value],
                          })
                        }
                        isChecked={formData.permissions.includes(option.value)}
                        isDisabled={option.disabled}
                      />
                      <Text size="sm" font="jakarta">
                        {option.label}
                      </Text>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-span-7">
            <Text className="font-semibold" size="h5">
              Role description
            </Text>
            <Text size="sm" variant="light">
              The team member will receive the combined set of permissions provided by these roles.
            </Text>
            {formData.permissions && formData.permissions.length > 0 ? (
              <div className="space-y-2 mt-2">
                {formData.permissions.map((permission, idx) => (
                  <UserPermissionCard key={idx} permission={permission} />
                ))}
              </div>
            ) : (
              <NoDataPlaceholder className="mt-4" label="Role descriptions will appear here on selection." />
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={formData.permissions?.length === 0} isForm />
    </Form>
  )
})
