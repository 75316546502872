import React, { memo } from 'react'
import { ConfirmModal, Icon, TableRowCta, routerService, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  PreferredSupplierAddTagsModal,
  PreferredSupplierAddUserModal,
  PreferredSupplierContactModel,
  PreferredSupplierPopulatedModel,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier'
import { compact } from 'lodash'

type Props = {
  preferredSupplier: PreferredSupplierPopulatedModel
  onSubmitAction: () => Promise<void>
}

export const PreferredSupplierListCta = memo(({ preferredSupplier, onSubmitAction }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { updatePreferredSupplier } = useMutatePreferredSupplier()

  const handleArchiveSubmit = async (preferredSupplier: PreferredSupplierPopulatedModel, archiveStatus: boolean) => {
    try {
      await updatePreferredSupplier(preferredSupplier._id, {
        isArchived: archiveStatus,
      })
      await onSubmitAction()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    openToast(`Contact ${archiveStatus ? 'archived' : 'unarchived'}`)
  }

  const handleAddUserModalSubmit = (preferredSupplier: PreferredSupplierPopulatedModel) => async (contact: PreferredSupplierContactModel) => {
    try {
      await updatePreferredSupplier(preferredSupplier._id, {
        contacts: [contact],
      })
      await onSubmitAction()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    if (contact.sendInvitationEmail) {
      openToast('Email invitation sent')
    }
  }

  return (
    <TableRowCta
      cta={{
        label: (
          <>
            View <Icon className="ml-1" icon="arrow-right" />
          </>
        ),
        href:
          !preferredSupplier.isArchived && preferredSupplier.supplierOrganisation
            ? routerService.getHref('/supplier/view/:id/:tab?', { id: preferredSupplier.supplierOrganisation._id })
            : undefined,
      }}
      actions={compact([
        !preferredSupplier.isArchived
          ? {
              label: 'Add tags',
              onClick: () => openModal(<PreferredSupplierAddTagsModal preferredSuppliers={[preferredSupplier]} onClose={onSubmitAction} />),
            }
          : undefined,
        !preferredSupplier.isArchived
          ? {
              label: 'Add user',
              onClick: () => openModal(<PreferredSupplierAddUserModal onSubmit={handleAddUserModalSubmit(preferredSupplier)} />),
            }
          : undefined,
        {
          label: preferredSupplier.isArchived ? 'Unarchive' : 'Archive',
          onClick: () => {
            openModal(
              <ConfirmModal
                heading={preferredSupplier.isArchived ? 'Unarchive Contact' : 'Archive Contact'}
                description={
                  preferredSupplier.isArchived ? 'Confirm you want to unarchive this contact?' : 'Are you sure you want to archive this contact?'
                }
                onSubmit={() => handleArchiveSubmit(preferredSupplier, !preferredSupplier.isArchived)}
              />
            )
          },
        },
      ])}
    />
  )
})
