import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreatePlanEventDocument,
  mutateCreatePlanEventDocuments,
  mutateDeletePlanEventDocument,
  queryPlanEventDocumentList,
} from '@cotiss/plan-event'
import {
  GqlCreatePlanEventDocumentInput,
  GqlCreatePlanEventDocumentsInput,
  GqlDeletePlanEventDocumentInput,
  GqlPlanEventDocumentFieldsFragment,
  GqlPlanEventDocumentListInput,
} from '@gql'

const planEventDocumentVar = makeVar<GqlPlanEventDocumentFieldsFragment | null>(null)
const planEventDocumentsVar = makeVar<GqlPlanEventDocumentFieldsFragment[]>([])

export const usePlanEventDocument = () => {
  const planEventDocument = useReactiveVar(planEventDocumentVar)
  const planEventDocuments = useReactiveVar(planEventDocumentsVar)

  return {
    planEventDocument,
    planEventDocuments,
    setPlanEventDocument: planEventDocumentVar,
    setPlanEventDocuments: planEventDocumentsVar,
    queryPlanEventDocumentList: async (input: GqlPlanEventDocumentListInput) => {
      const { items: planEventDocuments, pagination } = await queryPlanEventDocumentList(input)

      planEventDocumentsVar(planEventDocuments)

      return { planEventDocuments, pagination }
    },
    mutateCreatePlanEventDocument: async (input: GqlCreatePlanEventDocumentInput) => {
      const createdPlanEventDocument = await mutateCreatePlanEventDocument(input)

      planEventDocumentsVar(uniqBy([createdPlanEventDocument, ...planEventDocuments], 'id'))

      return createdPlanEventDocument
    },
    mutateCreatePlanEventDocuments: async (input: GqlCreatePlanEventDocumentsInput) => {
      const createdPlanEventDocuments = await mutateCreatePlanEventDocuments(input)

      planEventDocumentsVar(uniqBy([...createdPlanEventDocuments, ...planEventDocuments], 'id'))

      return createdPlanEventDocuments
    },
    mutateDeletePlanEventDocument: async (input: GqlDeletePlanEventDocumentInput) => {
      await mutateDeletePlanEventDocument(input)

      planEventDocumentsVar(filter(planEventDocuments, ({ id }) => id !== input.planEventDocumentId))
    },
  }
}

export const clearReactivePlanEventDocument = async () => {
  planEventDocumentVar(null)
  planEventDocumentsVar([])
}
