import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreateEvaluationSubmission,
  mutateDeleteEvaluationSubmission,
  mutateImportEvaluationSubmissionsFromLegacyTender,
  mutateUpdateEvaluationSubmission,
  queryEvaluationSubmissionList,
  queryEvaluationSubmissionView,
} from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationSubmissionInput,
  GqlDeleteEvaluationSubmissionInput,
  GqlEvaluationSubmissionFieldsFragment,
  GqlEvaluationSubmissionListInput,
  GqlEvaluationSubmissionViewInput,
  GqlImportEvaluationSubmissionsFromLegacyTenderInput,
  GqlUpdateEvaluationSubmissionInput,
} from '@gql'

const evaluationSubmissionVar = makeVar<GqlEvaluationSubmissionFieldsFragment | null>(null)
const evaluationSubmissionsVar = makeVar<GqlEvaluationSubmissionFieldsFragment[]>([])

export const useEvaluationSubmission = () => {
  const evaluationSubmission = useReactiveVar(evaluationSubmissionVar)
  const evaluationSubmissions = useReactiveVar(evaluationSubmissionsVar)

  return {
    evaluationSubmission,
    evaluationSubmissions,
    setEvaluationSubmission: evaluationSubmissionVar,
    setEvaluationSubmissions: evaluationSubmissionsVar,
    queryEvaluationSubmissionList: async (input: GqlEvaluationSubmissionListInput) => {
      const { items: evaluationSubmissions, pagination } = await queryEvaluationSubmissionList(input)

      evaluationSubmissionsVar(evaluationSubmissions)

      return { evaluationSubmissions, pagination }
    },
    queryEvaluationSubmissionView: async (input: GqlEvaluationSubmissionViewInput) => {
      const evaluationSubmission = await queryEvaluationSubmissionView(input)

      evaluationSubmissionVar(evaluationSubmission)
      evaluationSubmissionsVar(uniqBy([evaluationSubmission, ...evaluationSubmissions], 'id'))

      return evaluationSubmission
    },
    mutateCreateEvaluationSubmission: async (input: GqlCreateEvaluationSubmissionInput) => {
      const createdEvaluationSubmission = await mutateCreateEvaluationSubmission(input)

      evaluationSubmissionsVar(uniqBy([createdEvaluationSubmission, ...evaluationSubmissions], 'id'))

      return createdEvaluationSubmission
    },
    mutateImportEvaluationSubmissionsFromLegacyTender: async (input: GqlImportEvaluationSubmissionsFromLegacyTenderInput) => {
      await mutateImportEvaluationSubmissionsFromLegacyTender(input)
    },
    mutateUpdateEvaluationSubmission: async (input: GqlUpdateEvaluationSubmissionInput) => {
      const updatedEvaluationSubmission = await mutateUpdateEvaluationSubmission(input)

      evaluationSubmissionVar(updatedEvaluationSubmission)
      evaluationSubmissionsVar(uniqBy([updatedEvaluationSubmission, ...evaluationSubmissions], 'id'))

      return updatedEvaluationSubmission
    },
    mutateDeleteEvaluationSubmission: async (input: GqlDeleteEvaluationSubmissionInput) => {
      await mutateDeleteEvaluationSubmission(input)

      evaluationSubmissionsVar(filter(evaluationSubmissions, ({ id }) => id !== input.evaluationSubmissionId))
    },
  }
}

export const clearReactiveEvaluationSubmission = async () => {
  evaluationSubmissionVar(null)
  evaluationSubmissionsVar([])
}
