import { gql } from '@apollo/client'

export const EVALUATION_USER_ENVELOPE_FRAGMENT = gql`
  fragment EvaluationUserEnvelopeFields on EvaluationUserEnvelope {
    evaluationUserId
    evaluationEnvelopeId
    completedEvaluationCount
    status
  }
`
