import React, { memo } from 'react'
import { TabModel, Tabs, TransitionContainer, useTransition } from '@cotiss/common'
import { DocumentModel, DocumentViewer } from '@cotiss/document'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'

type Props = {
  approvedDocument: DocumentModel
  signedDocument?: DocumentModel
}

type ContractSignedDocumentViewerTab = 'approved-contract' | 'signed-contract'
const CONTRACT_SIGNED_DOCUMENT_TABS: TabModel<ContractSignedDocumentViewerTab>[] = [
  { id: 'signed-contract', label: 'Signed contract' },
  { id: 'approved-contract', label: 'Approved contract' },
]

export const ContractSignedDocumentViewer = memo(({ approvedDocument, signedDocument }: Props) => {
  const { step, transition, onTransition } = useTransition()

  const handleTabChange = (tab: ContractSignedDocumentViewerTab) => {
    const newStep = findIndex(CONTRACT_SIGNED_DOCUMENT_TABS, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <>
      {!signedDocument && (
        <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 aspect-[1/1.414] mt-6">
          <DocumentViewer document={approvedDocument} isDownloadable />
        </div>
      )}
      {signedDocument && (
        <>
          <Tabs<ContractSignedDocumentViewerTab>
            className="w-full mt-6"
            tabs={CONTRACT_SIGNED_DOCUMENT_TABS}
            onChange={({ id }) => handleTabChange(id)}
            variant="square"
          />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && (
                <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 aspect-[1/1.414] mt-6">
                  <DocumentViewer document={signedDocument} isDownloadable />
                </div>
              )}
              {step === 2 && (
                <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 aspect-[1/1.414] mt-6">
                  <DocumentViewer document={approvedDocument} isDownloadable />
                </div>
              )}
            </TransitionContainer>
          </AnimatePresence>
        </>
      )}
    </>
  )
})
