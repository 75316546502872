import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Card, CardHeader, NoDataPlaceholder, Skeleton, Text, useCallout } from '@cotiss/common'
import {
  ContractScheduledRatesHistoryModal,
  ContractStatusBadge,
  ContractSummaryScheduleOfRates,
  contractService,
  useGetContractShell,
} from '@cotiss/contract'

export const ContractViewScheduledRatesTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()
  const { contract, showHistory } = useMemo(() => {
    const contract = contractShell ? contractService.getContract(contractShell) : null
    const showHistory = (contractShell?.contracts.filter(({ variationTypes }) => variationTypes?.includes('scheduled_rates')) || []).length > 1
    return { contract, showHistory }
  }, [])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5" font="jakarta">
              Schedule of rates
            </Text>
          </div>
        </CardHeader>
        <Skeleton className="h-8 w-full" />
      </Card>
    )
  }

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5" font="jakarta">
            Schedule of rates
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>

      {showHistory && (
        <div className="flex justify-end w-full mb-4">
          <Button
            size="sm"
            variant="secondary"
            state="text"
            onClick={() => openModal(<ContractScheduledRatesHistoryModal contractShellId={contractShellId} />)}
          >
            View history
          </Button>
        </div>
      )}
      <ContractSummaryScheduleOfRates variant="inline" showTableHeader={false} contractId={contract?._id} contractShellId={contractShellId} />
    </Card>
  )
})
