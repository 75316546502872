import React, { memo, useEffect, useState } from 'react'
import { Button, ConfirmModal, Dropdown, DropdownContent, Icon, Text, useCallout } from '@cotiss/common'
import { EvaluationEventScoreCommentCreateUpdateModal, useEvaluationScore } from '@cotiss/evaluation-event'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationFieldsFragment, GqlEvaluationScoreFieldsFragment } from '@gql'

type Props = {
  evaluationCriteria: GqlEvaluationCriteriaFieldsFragment
  evaluation?: GqlEvaluationFieldsFragment | null
  evaluationScore?: GqlEvaluationScoreFieldsFragment
  onChange: () => void
  isModeration?: boolean
  isEditable?: boolean
}

export const EvaluationEventScoreComment = memo(({ evaluationCriteria, evaluation, evaluationScore, onChange, isModeration, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isSaving] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { mutateUpdateEvaluationScore } = useEvaluationScore()
  const [comment, setComment] = useState(isModeration ? evaluationScore?.moderationComment || '' : evaluationScore?.comment || '')

  useEffect(() => {
    setComment(isModeration ? evaluationScore?.moderationComment || '' : evaluationScore?.comment || '')
  }, [evaluationScore, isModeration])

  const handleDeleteComment = async () => {
    if (!evaluationScore) {
      return
    }

    if (isModeration) {
      await mutateUpdateEvaluationScore({ evaluationScoreId: evaluationScore?.id, moderationComment: null })
    } else {
      await mutateUpdateEvaluationScore({ evaluationScoreId: evaluationScore?.id, comment: null })
    }
    setComment('')
    onChange()
  }

  return (
    <>
      {comment && (
        <div className="relative flex">
          <Text className="w-full mr-2" size="sm">
            {comment}
          </Text>
          {isEditable && evaluationScore && (
            <>
              <Button onClick={() => setIsDropdownOpen(true)} state="text">
                <Icon icon="dots" variant="light" size={20} />
              </Button>
              <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                <DropdownContent
                  onClick={() =>
                    openModal(
                      <EvaluationEventScoreCommentCreateUpdateModal
                        evaluationCriteria={evaluationCriteria}
                        evaluation={evaluation}
                        evaluationScore={evaluationScore}
                        onChange={onChange}
                        isModeration={isModeration}
                      />
                    )
                  }
                >
                  Edit
                </DropdownContent>
                <DropdownContent
                  onClick={() =>
                    openModal(
                      <ConfirmModal
                        heading="Delete comment"
                        description="Are you sure you want to delete this comment?"
                        onSubmit={() => handleDeleteComment()}
                      />
                    )
                  }
                >
                  Delete
                </DropdownContent>
              </Dropdown>
            </>
          )}
        </div>
      )}
      {!comment && (
        <Button
          onClick={() =>
            openModal(
              <EvaluationEventScoreCommentCreateUpdateModal
                evaluationCriteria={evaluationCriteria}
                evaluation={evaluation}
                evaluationScore={evaluationScore}
                onChange={onChange}
                isModeration={isModeration}
              />
            )
          }
          state="text"
          size="sm"
          variant="link"
          isDisabled={!isEditable || isSaving}
        >
          + Add comment
        </Button>
      )}
    </>
  )
})
