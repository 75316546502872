class NewrelicService {
  _isInitialised = false

  init = () => {
    if (!this._isInitialised) {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = 'newrelic.js'
      head.appendChild(script)

      this._isInitialised = true
    }
  }
}

export const newrelicService = new NewrelicService()
