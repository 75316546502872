import { useFetch } from '@cotiss/common'
import { EvaluationModel } from '@cotiss/evaluation'

export const useGetEvaluation = (id?: string) => {
  const { data: evaluation, ...rest } = useFetch<EvaluationModel>('/v2/evaluations', {
    route: `/v2/evaluations/${id}`,
    isEnabled: Boolean(id),
  })

  return { evaluation, ...rest }
}
