import { filter, map } from 'lodash'
import React, { memo, useMemo } from 'react'
import { contractService, useGetContractShell } from '@cotiss/contract'
import { Text, NoDataPlaceholder, Skeleton, ScrollableTable, ScrollableTableColumn, datetimeService, utilService } from '@cotiss/common'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummaryExercisedTab = memo(({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const newExercisedPeriods = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)

    // The exercised price durations from the current contract/variation (in DRAFTING)
    const currentExercisedPriceDurations = filter(contract?.priceDurations, (pd) => Boolean(pd.exercised))

    // The ids of the exercised price durations from the last approved contract/variation
    const latestApprovedContract = contractShell ? contractService.getContract(contractShell, ['PUBLISHED']) : null
    const approvedExercisedPriceDurationReferenceIds = map(
      filter(latestApprovedContract?.priceDurations, (pd) => Boolean(pd.exercised)),
      (pd) => pd.referenceId
    )

    const newExercisedPeriods = filter(currentExercisedPriceDurations, (pd) => !approvedExercisedPriceDurationReferenceIds.includes(pd.referenceId))

    return contractService.parseContractPriceDurations(newExercisedPeriods)
  }, [contractShell, contractId])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Term',
      rows: map(newExercisedPeriods, ({ label }) => ({
        content: () => (
          <Text className="font-semibold truncate" font="jakarta" variant="light">
            {label}
          </Text>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Dates',
      rows: map(newExercisedPeriods, ({ startDate, endDate }) => ({
        content: () => (
          <Text variant="light">
            {startDate && endDate ? `${datetimeService.format(startDate, 'do MMM yyyy')} - ${datetimeService.format(endDate, 'do MMM yyyy')}` : '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Duration',
      rows: map(newExercisedPeriods, ({ length }) => ({
        content: () => <Text variant="light">{`${length} months`}</Text>,
      })),
    },
    {
      heading: 'Total',
      rows: map(newExercisedPeriods, ({ value, variation }) => ({
        content: () => <Text variant="light">{utilService.formatAsCurrency(value + variation)}</Text>,
      })),
    },
  ]

  if (isLoading) {
    return (
      <div className="p-6">
        <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
      </div>
    )
  }

  if (!newExercisedPeriods.length) {
    return <NoDataPlaceholder label="No new periods exercised" />
  }

  return <ScrollableTable fixedColumnsWidth={350} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
})
