import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common'

type UpdateOrganisationBody = {
  name?: string
  website?: string
  linkedin?: string
  description?: string
  buyerDescription?: string
  regions?: string[]
  link?: string
  contactEmail?: string
  contactPhone?: string
}

type CreateUpdateOrganisationJobBody = {
  title: string
  description: string
  startDate: Date
  endDate: Date
  link?: string
  attachments: string[]
}

type CreateUpdateOrganisationCertificationBody = {
  title: string
  type: string
  description: string
  link?: string
  attachments: string[]
}

type CreateUpdateOrganisationResourceBody = {
  title: string
  description: RemirrorJSON
  link?: string
  attachments: string[]
}

export const useMutateOrganisation = () => {
  const { mutate } = useMutate()

  return {
    updateOrganisation: async (id: string, body: UpdateOrganisationBody) => {
      await mutate({ route: `/v2/organisations/${id}`, body, invalidate: ['/v2/organisations', '/v2/users/me', '/v2/supplier-profiles'] })
    },
    uploadOrganisationLogo: async (id: string, image: File) => {
      const body = new FormData()
      body.append('image', image)

      await mutate({
        route: `/v2/organisations/${id}/logo`,
        body,
        type: 'form-data',
        invalidate: ['/v2/organisations', '/v2/users/me', '/v2/supplier-profiles'],
      })
    },
    createOrganisationJob: async (id: string, body: CreateUpdateOrganisationJobBody) => {
      await mutate({ route: `/v2/organisations/${id}/jobs`, body, invalidate: ['/v2/organisations'] })
    },
    updateOrganisationJob: async (id: string, jobId: string, body: CreateUpdateOrganisationJobBody) => {
      await mutate({ route: `/v2/organisations/${id}/jobs/${jobId}`, body, invalidate: ['/v2/organisations'] })
    },
    deleteOrganisationJob: async (id: string, jobId: string) => {
      await mutate({ route: `/v2/organisations/${id}/jobs/${jobId}`, method: 'DELETE', invalidate: ['/v2/organisations'] })
    },
    createOrganisationCertification: async (id: string, body: CreateUpdateOrganisationCertificationBody) => {
      await mutate({ route: `/v2/organisations/${id}/certification`, body, invalidate: ['/v2/organisations'] })
    },
    updateOrganisationCertification: async (id: string, certificationId: string, body: CreateUpdateOrganisationCertificationBody) => {
      await mutate({ route: `/v2/organisations/${id}/certification/${certificationId}`, body, invalidate: ['/v2/organisations'] })
    },
    deleteOrganisationCertification: async (id: string, certificationId: string) => {
      await mutate({ route: `/v2/organisations/${id}/certification/${certificationId}`, method: 'DELETE', invalidate: ['/v2/organisations'] })
    },
    createOrganisationResource: async (id: string, body: CreateUpdateOrganisationResourceBody) => {
      await mutate({ route: `/v2/organisations/${id}/resources`, body, invalidate: ['/v2/organisations'] })
    },
    updateOrganisationResource: async (id: string, resourceId: string, body: CreateUpdateOrganisationResourceBody) => {
      await mutate({ route: `/v2/organisations/${id}/resources/${resourceId}`, body, invalidate: ['/v2/organisations'] })
    },
    deleteOrganisationResource: async (id: string, resourceId: string) => {
      await mutate({ route: `/v2/organisations/${id}/resources/${resourceId}`, method: 'DELETE', invalidate: ['/v2/organisations'] })
    },
    generatePreferredSupplierInvitationToken: async (id: string) => {
      return await mutate({
        route: `/v2/organisations/${id}/generate-invitation-token`,
        invalidate: ['/v2/organisations'],
      })
    },
  }
}
