import { uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateUpdatePerformanceScorecardMetricCycle,
  queryPerformanceScorecardMetricCycleList,
  queryPerformanceScorecardMetricCycleView,
} from '@cotiss/performance'
import {
  GqlPerformanceScorecardMetricCycleFieldsFragment,
  GqlPerformanceScorecardMetricCycleListInput,
  GqlPerformanceScorecardMetricCycleViewInput,
  GqlUpdatePerformanceScorecardMetricCycleInput,
} from '@gql'

const performanceScorecardMetricCycleVar = makeVar<GqlPerformanceScorecardMetricCycleFieldsFragment | null>(null)
const performanceScorecardMetricCyclesVar = makeVar<GqlPerformanceScorecardMetricCycleFieldsFragment[]>([])

export const usePerformanceScorecardMetricCycle = () => {
  const performanceScorecardMetricCycle = useReactiveVar(performanceScorecardMetricCycleVar)
  const performanceScorecardMetricCycles = useReactiveVar(performanceScorecardMetricCyclesVar)

  return {
    performanceScorecardMetricCycle,
    performanceScorecardMetricCycles,
    setPerformanceScorecardMetricCycles: performanceScorecardMetricCyclesVar,
    queryPerformanceScorecardMetricCycleList: async (input: GqlPerformanceScorecardMetricCycleListInput) => {
      const { items, pagination } = await queryPerformanceScorecardMetricCycleList(input)

      performanceScorecardMetricCyclesVar(items)

      return { items, pagination }
    },
    queryPerformanceScorecardMetricCycleView: async (input: GqlPerformanceScorecardMetricCycleViewInput) => {
      const performanceScorecardMetricCycle = await queryPerformanceScorecardMetricCycleView(input)

      performanceScorecardMetricCycleVar(performanceScorecardMetricCycle)

      return performanceScorecardMetricCycle
    },

    mutateUpdatePerformanceScorecardMetricCycle: async (input: GqlUpdatePerformanceScorecardMetricCycleInput) => {
      const updatedPerformanceScorecardMetricCycle = await mutateUpdatePerformanceScorecardMetricCycle(input)

      performanceScorecardMetricCycleVar(updatedPerformanceScorecardMetricCycle)
      performanceScorecardMetricCyclesVar(uniqBy([updatedPerformanceScorecardMetricCycle, ...performanceScorecardMetricCycles], 'id'))

      return updatedPerformanceScorecardMetricCycle
    },
  }
}

export const clearReactivePerformanceScorecardMetricCycle = async () => {
  performanceScorecardMetricCycleVar(null)
  performanceScorecardMetricCyclesVar([])
}
