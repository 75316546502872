import { COLOUR, utilService } from '@cotiss/common'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation'
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'
import { forEach, map, max, mean } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)
ChartJS.register(annotationPlugin)
ChartJS.defaults.font.family = 'Inter, sans-serif'

type DataItem = {
  label: string
  weightedScore: number
  unweightedScore: number
}

type Props = {
  className?: string
  tenderResponseReports: TenderResponseReportModel[]
  type: 'overall' | 'non-price'
}

export const TenderResponseReportResultGraph = memo(({ className, tenderResponseReports, type }: Props) => {
  const [totals, setTotals] = useState<number[]>([])
  const [datasets, setDatasets] = useState<any[]>([])
  const { averageValue, medianConfig } = useMemo(() => {
    const averageValue = mean(totals).toFixed(2)
    const medianValue = utilService.getMedian(totals).toFixed(2)
    let medianConfig: AnnotationOptions | undefined

    if (medianValue !== averageValue) {
      medianConfig = {
        type: 'line',
        borderColor: COLOUR.orange[500],
        borderDash: [6, 6],
        borderDashOffset: 0,
        borderWidth: 3,
        label: {
          display: true,
          content: () => `Median: ${medianValue}`,
          position: 'start',
          backgroundColor: COLOUR.orange[500],
          color: COLOUR.white,
        },
        scaleID: 'y',
        value: () => medianValue,
      }
    }

    return { averageValue, medianConfig }
  }, [totals])

  useEffect(() => {
    if (type === 'overall') {
      setDatasets([
        {
          label: 'Price',
          data: map(tenderResponseReports, ({ procurementResponse, priceScore: unweightedScore = 0, weightedPriceScore }) => ({
            label: procurementResponse.supplier.name,
            weightedScore: weightedPriceScore,
            unweightedScore,
          })),
          backgroundColor: COLOUR.secondary[500],
        },
        {
          label: 'Non-price',
          data: map(tenderResponseReports, ({ procurementResponse, groupScore: unweightedScore = 0, weightedGroupScore }) => ({
            label: procurementResponse.supplier.name,
            weightedScore: weightedGroupScore,
            unweightedScore,
          })),
          backgroundColor: COLOUR.secondary[400],
        },
      ])

      setTotals(map(tenderResponseReports, ({ totalScore }) => totalScore))
    }

    if (type === 'non-price') {
      const tenderCriteriaMap: { [key: string]: { label: string; data: DataItem[] } } = {}

      forEach(tenderResponseReports, ({ tenderCriteria, procurementResponse }) => {
        forEach(tenderCriteria, ({ _id, name, groupScore, weightedCriterionWeight }) => {
          if (!tenderCriteriaMap[_id]) {
            tenderCriteriaMap[_id] = { label: name, data: [] }
          }

          tenderCriteriaMap[_id].data.push({
            label: procurementResponse.supplier.name,
            weightedScore: groupScore * weightedCriterionWeight,
            unweightedScore: groupScore,
          })
        })
      })

      const data = Object.values(tenderCriteriaMap)
      setDatasets(
        map(data, ({ label, data }, index) => ({
          label,
          data,
          backgroundColor: index % 2 ? COLOUR.secondary[500] : COLOUR.secondary[400],
        }))
      )
      setTotals(map(tenderResponseReports, ({ groupScore }) => groupScore))
    }
  }, [tenderResponseReports, type])

  return (
    <Bar
      className={className}
      data={{ datasets }}
      options={{
        plugins: {
          legend: { position: 'bottom' },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            formatter: (_: number, context: Context) => {
              return context.datasetIndex === context.chart.data.datasets.length - 1 ? totals[context.dataIndex].toFixed(2) : ''
            },
          },
          annotation: {
            annotations: {
              average: {
                type: 'line',
                borderColor: COLOUR.blue[600],
                borderDash: [6, 6],
                borderDashOffset: 0,
                borderWidth: 3,
                label: {
                  display: true,
                  content: () => `Average: ${averageValue}`,
                  position: 'end',
                  backgroundColor: COLOUR.blue[600],
                  color: COLOUR.white,
                },
                scaleID: 'y',
                value: () => averageValue,
              },
              median: medianConfig,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.dataset.label} (weighted): ${context.parsed.y.toFixed(2)}`,
              afterLabel: (context) => {
                return `${context.dataset.label} (unweighted): ${(context.raw as DataItem).unweightedScore.toFixed(2)}`
              },
            },
          },
        },
        responsive: true,
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'weightedScore',
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              callback(value) {
                if (typeof value === 'number') {
                  const label = this.getLabelForValue(value)

                  return label.length > 10 ? `${label.slice(0, 10)}...` : label
                }
              },
            },
          },
          y: {
            stacked: true,
            min: 0,
            max: Math.max(...totals) > 5 ? 12 : 6,
            ticks: {
              precision: 0,
              callback(tickValue: string | number) {
                if (typeof tickValue !== 'number') {
                  return tickValue
                }

                return (max(totals) || 0) > 5 ? (tickValue > 10 ? '' : tickValue) : tickValue > 5 ? '' : tickValue
              },
            },
          },
        },
      }}
    />
  )
})
