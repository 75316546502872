import { map } from 'lodash'
import React, { memo, useMemo } from 'react'
import { GqlEvaluationCriteriaRatingScale } from '@gql'
import { Table, TableColumn, TableHeader, Text } from '@cotiss/common'
import {
  EVALUATION_EVENT_RATING_SCALE_NAME_MAP,
  EVALUATION_EVENT_RATING_SCALE_ZERO_TO_FIVE_DEFINITIONS,
  EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_DEFINITIONS,
  EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_STAGGERED_DEFINITIONS,
  EVALUATION_EVENT_RATING_SCALE_ZERO_TO_SEVEN_DEFINITIONS,
  EVALUATION_EVENT_RATING_SCALE_ZERO_TO_TEN_DEFINITIONS,
} from '@cotiss/evaluation-event'

type DefinitionItem = {
  score: number | string
  heading: string
  definition: string
}

type Props = {
  className?: string
  ratingScale: GqlEvaluationCriteriaRatingScale
}

const getDefinitions = (ratingScale: GqlEvaluationCriteriaRatingScale) => {
  switch (ratingScale) {
    case 'zeroToFiveWithDefinitions':
      return EVALUATION_EVENT_RATING_SCALE_ZERO_TO_FIVE_DEFINITIONS as DefinitionItem[]
    case 'zeroToTenWithDefinitions':
      return EVALUATION_EVENT_RATING_SCALE_ZERO_TO_TEN_DEFINITIONS as DefinitionItem[]
    case 'zeroToSevenWithDefinitions':
      return EVALUATION_EVENT_RATING_SCALE_ZERO_TO_SEVEN_DEFINITIONS as DefinitionItem[]
    case 'zeroToOneHundredWithDefinitions':
      return EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_DEFINITIONS as DefinitionItem[]
    case 'zeroToOneHundredWithStaggeredDefinitions':
      return EVALUATION_EVENT_RATING_SCALE_ZERO_TO_ONE_HUNDRED_STAGGERED_DEFINITIONS as DefinitionItem[]
    default:
      return []
  }
}

export const EvaluationEventRatingScaleDefinitionList = memo(({ className, ratingScale }: Props) => {
  const definitions = getDefinitions(ratingScale)

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: '#',
        thClassName: 'w-16',
        rows: [
          ...map(definitions, ({ score }) => ({
            tdClassName: 'align-top',
            content: () => <Text className="whitespace-pre-wrap">{score}</Text>,
          })),
        ],
      },
      {
        heading: 'Heading',
        thClassName: 'w-32',
        rows: [
          ...map(definitions, ({ heading }) => ({
            tdClassName: 'align-top',
            content: () => <Text>{heading}</Text>,
          })),
        ],
      },
      {
        heading: 'Definition',
        rows: [
          ...map(definitions, ({ definition }) => ({
            tdClassName: 'align-top',
            content: () => <Text size="sm">{definition}</Text>,
          })),
        ],
      },
    ]

    return { columns }
  }, [ratingScale])

  if (!definitions.length) {
    return null
  }

  return (
    <div className={className}>
      <TableHeader>
        <div className="flex items-center">
          <Text className="mr-1" variant="light">
            Preview rating scale
          </Text>
          <Text>{EVALUATION_EVENT_RATING_SCALE_NAME_MAP[ratingScale]}</Text>
        </div>
        <Text className="mt-1" variant="light" size="sm">
          Below are the default definitions assigned to this rating scale
        </Text>
      </TableHeader>
      <Table columns={columns} />
    </div>
  )
})
