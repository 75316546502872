import { filter, map } from 'lodash'
import React, { useMemo, memo } from 'react'
import { Button, Icon, Text, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  AssociatedContractShellDetails,
  ContractAssociationCard,
  ContractLinkDrawer,
  ContractShellHierarchy,
  useGetContractShell,
  useMutateContractShell,
} from '@cotiss/contract'

type Props = {
  contractShellId: string
  isEditable?: boolean
}

export const ContractAssociations = memo(({ contractShellId, isEditable }: Props) => {
  const { contractShell } = useGetContractShell(contractShellId)
  const { openToast } = useToast()
  const { openDrawer } = useCallout()
  const { associateContractShell } = useMutateContractShell()

  const associatedContractShells: AssociatedContractShellDetails[] = useMemo(() => {
    return contractShell
      ? [
          ...map(contractShell.siblingsContractShells, (shell) => ({ ...shell, type: 'sibling' as ContractShellHierarchy })),
          ...map(contractShell.parentContractShells, (shell) => ({ ...shell, type: 'parent' as ContractShellHierarchy })),
          ...map(contractShell.childrenContractShells, (shell) => ({ ...shell, type: 'child' as ContractShellHierarchy })),
        ]
      : []
  }, [contractShell])

  const handleRemoveAssociatedContractShell = async (contract: AssociatedContractShellDetails) => {
    if (!contractShell) {
      openToast('Something went wrong. Please re-load the page and try again.', 'danger')
      sentryService.captureException({ exception: 'Tried to update associated contracts but found no contract shell', extras: { contractShellId } })
      return
    }

    await associateContractShell(contractShellId, {
      ...(contract.type === 'parent' && {
        parentContractShells: [
          ...map(
            filter(contractShell.parentContractShells, ({ _id }) => _id !== contract._id),
            ({ _id }) => _id
          ),
        ],
      }),
      ...(contract.type === 'sibling' && {
        siblingsContractShells: [
          ...map(
            filter(contractShell.siblingsContractShells, ({ _id }) => _id !== contract._id),
            ({ _id }) => _id
          ),
        ],
      }),
      ...(contract.type === 'child' && {
        childrenContractShells: [
          ...map(
            filter(contractShell.childrenContractShells, ({ _id }) => _id !== contract._id),
            ({ _id }) => _id
          ),
        ],
      }),
    })
  }

  return (
    <div className="py-6 flex flex-wrap gap-6">
      {isEditable && (
        <Button
          variant="none"
          className="w-48 h-48 border border-dashed hover:border-secondary-400 rounded-lg"
          onClick={() => openDrawer(<ContractLinkDrawer contractShellId={contractShellId} />)}
        >
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center h-11 w-11 bg-secondary-100 rounded-full">
              <Icon icon="file-attachment-05" className="text-secondary-500" size={24} />
            </div>
            <Text variant="secondary" className="text-center font-medium mt-2">
              Link associated contract
            </Text>
          </div>
        </Button>
      )}
      {map(associatedContractShells, (contractShell) => (
        <ContractAssociationCard
          key={`${contractShell._id}-${contractShell.type}`}
          associatedContractShellDetails={contractShell}
          onRemoveAssociatedContractShell={handleRemoveAssociatedContractShell}
          isEditable={isEditable}
        />
      ))}
    </div>
  )
})
