import { compact, filter, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { UserAvatarGroup } from '@cotiss/user'
import { organisationService } from '@cotiss/organisation'
import { PerformanceScorecardCreateFormData } from '@cotiss/performance'
import { FilterPreferredSupplierSortKey, PreferredSupplierPopulatedModel, useMutatePreferredSupplier } from '@cotiss/preferred-supplier'
import {
  Text,
  Radio,
  useSortTable,
  Filter_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  useCallout,
  PaginationModel,
  Badge,
  Tooltip,
  ScrollableTableColumn,
  Icon,
  datetimeService,
  sentryService,
  useToast,
  TableHeader,
  Button,
  ScrollableTable,
  FilterDrawer_DEPRECATED,
  sortService,
  Breadcrumb,
  BreadcrumbModel,
  Banner,
} from '@cotiss/common'

type Props = {
  formData: PerformanceScorecardCreateFormData
  setFormData: (formData: PerformanceScorecardCreateFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardCreateContactStep = memo(({ formData, setFormData, onBack, isDisabled }: Props) => {
  const { openToast } = useToast()
  const { openNarrowDrawer } = useCallout()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<Filter_DEPRECATED[]>([])
  const [pagination, setPagination] = useState<PaginationModel>()
  const { filterPreferredSupplier } = useMutatePreferredSupplier()
  const [filterFields, setFilterFields] = useState<FilterFieldOptions_DEPRECATED>({})
  const [preferredSuppliers, setPreferredSupplier] = useState<PreferredSupplierPopulatedModel[]>([])
  const { sortKey, sortDirection, onSort } = useSortTable<FilterPreferredSupplierSortKey>({ initialKey: 'organisationName' })

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: formData.name,
      onClick: onBack,
    },
    {
      label: 'Select vendor',
    },
  ]

  const processedFilters = useMemo(() => {
    return [
      ...filters,
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
    ] as Filter_DEPRECATED[]
  }, [filters])

  const { fixedColumns, columns } = useMemo(() => {
    const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
      if (!preferredSupplier.tags?.length) {
        return <Text size="sm">--</Text>
      }

      const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

      return (
        <>
          <Badge className="mr-1" state="outline" variant="secondary">
            {firstTag}
          </Badge>
          {Boolean(rest.length) && (
            <Tooltip tooltip={rest.join(', ')}>
              <Text className="font-medium" variant="link" size="sm">
                +{rest.length}
              </Text>
            </Tooltip>
          )}
        </>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(preferredSuppliers, ({ _id, supplierOrganisation }) => ({
          content: () => (
            <Radio
              value={_id}
              name="performance-scorecard-contact"
              onChange={() => setFormData({ ...formData, contactId: _id, supplierId: supplierOrganisation?._id || '' })}
              isChecked={_id === formData.contactId}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={preferredSupplier.supplierOrganisation?.name || '--'}>
              {preferredSupplier.supplierOrganisation?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the vendors associated with this account has created a Cotiss account'
                    : 'None of the vendors associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [preferredSuppliers, formData, isDisabled])

  const refreshPreferredSuppliers = async () => {
    try {
      setIsLoading(true)

      const { preferredSuppliers, meta, pagination } = await filterPreferredSupplier({
        filters: processedFilters,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setPreferredSupplier(preferredSuppliers)
      setPagination(pagination)

      // Remove archived filter from the UI; the user cannot override it so they needn't see it in the UI
      Object.hasOwn(meta, 'archived') && delete meta.archived

      setFilterFields(meta)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoading(false)
  }

  const handleSetFilters = (filters: Filter_DEPRECATED[]) => {
    setCurrentPage(1)
    setFilters(filters)
  }

  useEffect(() => {
    refreshPreferredSuppliers()
  }, [processedFilters, currentPage, sortKey, sortDirection])

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Select a vendor
          </Text>
          <Text size="sm">Select an option below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of {formData.withContract ? '3' : '2'}
        </Badge>
      </Banner>
      <TableHeader className="flex items-center justify-between mt-8">
        <Text className="font-medium" size="lg">
          Select vendor
        </Text>
        <Button
          size="xs"
          variant="secondary"
          state="outline"
          isDisabled={isLoading}
          onClick={() => openNarrowDrawer(<FilterDrawer_DEPRECATED filters={filters} filterFields={filterFields} setFilters={handleSetFilters} />)}
        >
          + Filters ({filters.length})
        </Button>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
