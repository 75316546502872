import { useHistory } from 'react-router-dom'
import { filter, groupBy, some } from 'lodash'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { UserSelect, useListUser } from '@cotiss/user'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Form, Label, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast, Button, Text, routerService } from '@cotiss/common'
import {
  evaluationEventService,
  useEvaluationCriteria,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationUser,
} from '@cotiss/evaluation-event'

type FormData = {
  userId: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventEvaluatorAddModal = memo(({ evaluationEnvelope }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { closeModal, closeFullModal } = useCallout()
  const { evaluationCriteria } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({ userId: '' })
  const { users, isLoading } = useListUser({ permissions: ['EVALUATOR'] })
  const { evaluationUsers, mutateCreateEvaluationUser } = useEvaluationUser()
  const { userOptions, criteriaByEnvelopeId } = useMemo(() => {
    // Filter out any users that are already added to the evaluation event with access control linked to the envelope.
    const userOptions = filter(users, ({ _id }) => {
      return !some(evaluationUsers, ({ userId, accessControls }) => {
        return _id === userId && some(accessControls, { resourceType: 'envelope', access: 'evaluate', resourceId: evaluationEnvelope.id })
      })
    })

    const criteriaByEnvelopeId = groupBy(evaluationCriteria, 'evaluationEnvelopeId')

    return { userOptions, criteriaByEnvelopeId }
  }, [evaluationUsers, users])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.userId || !evaluationEvent) {
      return
    }

    track('evaluation_event_evaluator_add_submit')

    try {
      setIsSaving(true)

      const accessControls = evaluationEventService.getAccessControlInput({
        accessByEvaluationEnvelopeId: { [evaluationEnvelope.id]: 'evaluate' },
        criteriaByEnvelopeId,
      })
      await mutateCreateEvaluationUser({ evaluationEventId: evaluationEvent.id, userId: formData.userId, accessControls })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleClickOpenSettings = () => {
    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
    closeModal()
    closeFullModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add panel members" />
      <ModalContent>
        <Text className="font-semibold">Select panel member</Text>
        <Text className="mt-1" size="sm" variant="light">
          Select from the list of organisation users. New users can be added to your organisation in your
          <Button className="ml-1" state="text" variant="link" size="sm" onClick={handleClickOpenSettings} isDisabled={isSaving}>
            Settings.
          </Button>
        </Text>
        <Label className="mt-4">User</Label>
        <UserSelect
          value={formData.userId}
          options={userOptions}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving || isLoading}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
