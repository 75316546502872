// NOTE: This is part of a DEPRECATED and UNUSED flow.

import { forEach, map, uniq } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { PriceItemModel, UpsertPriceItemBulkBodyItem, useListPriceItem, useMutatePriceItem } from '@cotiss/price-item'
import {
  Form,
  Input,
  Label,
  METRIC_UNIT_OPTIONS,
  MetricUnit,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select_DEPRECATED,
  sentryService,
  TextArea,
  useCallout,
  useToast,
} from '@cotiss/common'

type FormData = {
  category: string
  description: string
  unit?: MetricUnit
  quantity: string
}

type Props = {
  tenderId: string
  priceItem?: PriceItemModel
}

export const PriceItemAddUpdateModal = memo(({ tenderId, priceItem }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { upsertPriceItemBulk } = useMutatePriceItem()
  const { priceItems } = useListPriceItem({ tenderId })
  const [formData, setFormData] = useState<FormData>({
    category: priceItem?.category || '',
    description: priceItem?.description || '',
    quantity: priceItem?.quantity ? priceItem.quantity.toString() : '',
    unit: priceItem?.unit,
  })
  const categoryOptions = useMemo(
    () => map(uniq(map(priceItems, ({ category }) => category)), (category) => ({ value: category, label: category })),
    [priceItems]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      const items: UpsertPriceItemBulkBodyItem[] = []

      forEach(priceItems, ({ _id, description, category, unit, quantity }, index) => {
        if (priceItem?._id === _id) {
          items.push({
            priceItemId: _id,
            body: {
              ...formData,
              unit: formData.unit || 'number',
              quantity: Number(formData.quantity),
              index,
            },
          })
        } else {
          items.push({
            priceItemId: _id,
            body: { description, category, unit, quantity, index },
          })
        }
      })

      if (!priceItem) {
        items.push({
          body: {
            ...formData,
            unit: formData.unit || 'number',
            quantity: Number(formData.quantity),
            index: priceItems.length,
          },
        })
      }

      await upsertPriceItemBulk({ tenderId, items })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add price item" isDisabled={isSaving} />
      <ModalContent>
        <Label>Category</Label>
        <CreatableSelect
          value={formData.category ? { value: formData.category, label: formData.category } : undefined}
          options={categoryOptions}
          onChange={(category) => setFormData({ ...formData, category: category?.value || '' })}
          components={{ IndicatorSeparator: null }}
          noOptionsMessage={() => 'Type to create category...'}
          placeholder="Add category"
          isDisabled={isSaving}
          isMulti={false}
          required
          isClearable
        />
        <Label className="mt-4">Description</Label>
        <TextArea
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Description"
          isDisabled={isSaving}
          isRequired
        />
        <div className="flex items-start justify-between mt-4">
          <div className="mr-4">
            <Label>Quantity</Label>
            <Input
              value={formData.quantity}
              onChange={({ target }) => setFormData({ ...formData, quantity: target.value })}
              placeholder="Quantity"
              type="number"
              isDisabled={isSaving}
              isRequired
            />
          </div>
          <div>
            <Label>Unit</Label>
            <Select_DEPRECATED
              value={formData.unit}
              options={METRIC_UNIT_OPTIONS}
              onChange={(unit) => setFormData({ ...formData, unit })}
              isDisabled={isSaving}
              placeholder
              isRequired
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
