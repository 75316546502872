import { gql } from '@apollo/client'

export const EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT = gql`
  fragment EvaluationOverviewCriteriaBreakdownFields on EvaluationOverviewCriteriaBreakdown {
    evaluationCriteriaId
    rawScoreSummary {
      totalScore
      averageScore
      medianScore
      percentageScore
      weightedPercentageScore
      envelopeWeightedPercentageScore
      overallWeightedPercentageScore
    }
    moderatedScoreSummary {
      totalScore
      averageScore
      medianScore
      percentageScore
      weightedPercentageScore
      envelopeWeightedPercentageScore
      overallWeightedPercentageScore
    }
    scoredSubCriteriaCount
    completedScoredSubCriteriaCount
    isScored
  }
`
