import { useFetch } from '@cotiss/common'
import { OrganisationModel } from '@cotiss/organisation'

export const useGetPublicOrganisation = (id?: string) => {
  const { data: publicOrganisation, ...rest } = useFetch<OrganisationModel>('/v2/public/organisations', {
    route: `/v2/public/organisations/${id}`,
    isEnabled: Boolean(id),
    isPublicApi: true,
  })

  return { publicOrganisation, ...rest }
}
