import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance'
import {
  GqlUpdatePerformanceMetricInput,
  GqlUpdatePerformanceMetricMutation,
  GqlUpdatePerformanceMetricMutationVariables,
  GqlPerformanceMetricFieldsFragment,
} from '@gql'

export const mutateUpdatePerformanceMetric = async (input: GqlUpdatePerformanceMetricInput) => {
  const { data } = await apolloService.mutate<GqlUpdatePerformanceMetricMutation, GqlUpdatePerformanceMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      mutation UpdatePerformanceMetric($input: UpdatePerformanceMetricInput!) {
        updatePerformanceMetric(input: $input) {
          ...PerformanceMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceMetric as GqlPerformanceMetricFieldsFragment
}
