import classNames from 'classnames'
import { filter, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { useListNotification, useMutateNotification } from '@cotiss/notification'
import { Button, Icon, Dropdown, DropdownContent, Text, Badge, sentryService, useToast } from '@cotiss/common'

export const AppSideNavNotifications = memo(() => {
  const { openToast } = useToast()
  const { notifications } = useListNotification()
  const [isSaving, setIsSaving] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { markAllNotificationsAsRead } = useMutateNotification()
  const count = useMemo(() => filter(notifications, ({ seen }) => !seen).length || 0, [notifications])

  const handleMarkAllAsRead = async () => {
    try {
      setIsSaving(true)
      await markAllNotificationsAsRead()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="relative">
      <Button className="relative" onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
        <Icon icon="bell-01" size={20} />
        {Boolean(count) && <div className="absolute bg-pink-500 rounded-full top-[9px] right-[11px] h-2 w-2" />}
      </Button>
      <Dropdown className="left-[calc(100%+8px)] origin-top-left" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent hasPadding={false}>
          <div className="flex items-center bg-white border-b border-gray-100 rounded-t-md py-2.5 px-4">
            <Text variant="light">Activity</Text>
            {Boolean(count) && <Badge className="ml-2">{count} new</Badge>}
          </div>
          <div className="max-h-96 overflow-y-auto py-2 px-4">
            {!notifications.length && <Text className="text-center p-2">✨ No notifications ✨</Text>}
            {Boolean(notifications.length) && (
              <>
                {map(notifications, ({ message, createdAt, seen, action }, index) => (
                  <a
                    href={action}
                    key={index}
                    title={message}
                    className="relative flex items-center whitespace-normal cursor-pointer rounded-lg px-4 py-2 w-64 hover:bg-gray-100"
                  >
                    <div className={classNames('shrink-0 rounded-full h-2 w-2 mr-3', { 'bg-secondary-500': !seen, 'bg-gray-300': seen })} />

                    <div>
                      <Text className="line-clamp-3">{message}</Text>
                      <Text variant="light" size="sm">
                        {formatDistanceToNowStrict(parseISO(createdAt), { addSuffix: true })}
                      </Text>
                    </div>
                  </a>
                ))}
              </>
            )}
          </div>
          <div className="relative flex items-center bg-white border-t border-gray-100 rounded-b-md py-2.5 px-4">
            <Button onClick={handleMarkAllAsRead} state="text" variant={count ? 'link' : 'light'} isLoading={isSaving} isDisabled={!count}>
              Mark all as read
            </Button>
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  )
})
