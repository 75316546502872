import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'

type Props = {
  children: ReactNode
  className?: string
  hasPadding?: boolean
}

export const Card = memo(({ className, hasPadding = true, children }: Props) => {
  const classes = classNames(className, 'bg-white rounded-lg shadow-lg', { 'px-8 py-6': hasPadding })

  return <div className={classes}>{children}</div>
})
