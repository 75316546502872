export const EXTERNAL_LINK = {
  marketingHome: 'https://www.cotiss.com',
  bookDemo: 'https://www.cotiss.com/book-a-demo',
  termsAndConditions: 'https://www.cotiss.com/terms-conditions',
  privacyPolicy: 'https://www.cotiss.com/privacy-policy',
  authenticatorAppApple: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  authenticatorAppAndroid: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  authenticatorAppMicrosoft: 'https://www.microsoft.com/en-us/security/mobile-authenticator-app',
  unspsc: 'https://www.unspsc.org/',

  // Hosted Templates
  tenderRequestPricingTableImportTemplate:
    'https://cotiss-document-storage-production.s3.ap-southeast-2.amazonaws.com/templates/tender-request-pricing-table-import-template.xlsx',
}
