import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlEvaluationListInput, GqlEvaluationOverviewListQuery } from '@gql'
import { EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT, EVALUATION_OVERVIEW_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationOverviewList = async (input: GqlEvaluationListInput) => {
  const { data } = await apolloService.query<GqlEvaluationOverviewListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_OVERVIEW_FRAGMENT}
      ${EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}

      query EvaluationOverviewList($input: EvaluationListInput!) {
        evaluationOverviewList(input: $input) {
          items {
            ...EvaluationOverviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationOverviewList
}
