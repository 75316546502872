import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationEventInput,
  GqlUpdateEvaluationEventMutation,
  GqlUpdateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationEvent = async (input: GqlUpdateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationEventMutation, GqlUpdateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation UpdateEvaluationEvent($input: UpdateEvaluationEventInput!) {
        updateEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationEvent as GqlEvaluationEventFieldsFragment
}
