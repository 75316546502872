import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Text, TextSize, TextVariant, utilService } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
  href?: string
  variant?: TextVariant
  size?: TextSize
  isInline?: boolean
}

export const ExternalLink = memo(({ className, children, href, variant = 'link', size, isInline }: Props) => {
  const classes = classNames(className, {
    block: !isInline,
  })

  return (
    <a className={classes} href={utilService.getExternalHref(href)} target="_blank" rel="noopener noreferrer">
      <Text variant={variant} size={size} isInline={isInline}>
        {children}
      </Text>
    </a>
  )
})
