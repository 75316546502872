import { map } from 'lodash'
import React, { memo, useState } from 'react'
import { ShortListPopulatedModel, ShortListStatusBadge, useMutateShortList } from '@cotiss/short-list'
import { Td_DEPRECATED, Tr_DEPRECATED, Text, Select_DEPRECATED, SelectOption_DEPRECATED, useToast, sentryService } from '@cotiss/common'

type Props = {
  shortList: ShortListPopulatedModel
  shortListCount: number
  isEditable?: boolean
}

export const RecommendationShortListItem = memo(({ shortList, shortListCount, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateShortList } = useMutateShortList()
  const rankOptions: SelectOption_DEPRECATED[] = map(Array(shortListCount + 1), (_, index) => {
    return index ? { label: index.toString(), value: index.toString() } : { label: '-', value: '0' }
  })

  const handleRankChange = async (rank: string) => {
    try {
      setIsSaving(true)

      await updateShortList(shortList._id, { rank: Number(rank) })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Tr_DEPRECATED variant="white">
      <Td_DEPRECATED>
        <Text className="truncate">{shortList.tenderResponse.procurementResponse.supplier.name}</Text>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <ShortListStatusBadge status={shortList.status} />
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Select_DEPRECATED
          value={shortList.rank.toString()}
          options={rankOptions}
          onChange={handleRankChange}
          size="sm"
          isDisabled={!isEditable || isSaving}
        />
      </Td_DEPRECATED>
    </Tr_DEPRECATED>
  )
})
