export * from './procurement-overview-all-activity.tab'
export * from './procurement-overview-all-requests.tab'
export * from './procurement-overview-background-attachments.tab'
export * from './procurement-overview-background-general.tab'
export * from './procurement-overview-background-market-analysis.tab'
export * from './procurement-overview-background-metafields.tab'
export * from './procurement-overview-background.tab'
export * from './procurement-overview-conflict-of-interest.tab'
export * from './procurement-view-tender-details.tab'
export * from './procurement-view-tender-documents.tab'
export * from './procurement-view-tender-forum.tab'
export * from './procurement-view-tender-response.tab'
export * from './procurement-view-tender-updates.tab'
export * from './procurement-view-tender.tab'
