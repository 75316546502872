import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useState } from 'react'
import { useEvaluationEvent } from '@cotiss/evaluation-event'
import {
  Form,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  routerService,
  sentryService,
  useAnalytics,
  useCallout,
  useToast,
} from '@cotiss/common'

export const EvaluationEventCreateModal = memo(() => {
  const { push } = useHistory()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [name, setName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { mutateCreateEvaluationEvent } = useEvaluationEvent()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('evaluation_event_list_create_submit')

    try {
      setIsSaving(true)
      const evaluationEvent = await mutateCreateEvaluationEvent({ name: name })

      push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId: evaluationEvent.id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Create evaluation event" isDisabled={isSaving} />
      <ModalContent>
        <Label>Evaluation event title</Label>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Enter a unique title for this evaluation event"
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
