import { gql } from '@apollo/client'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationSubmissionListInput, GqlEvaluationSubmissionListQuery } from '@gql'

export const queryEvaluationSubmissionList = async (input: GqlEvaluationSubmissionListInput) => {
  const { data } = await apolloService.query<GqlEvaluationSubmissionListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_FRAGMENT}

      query EvaluationSubmissionList($input: EvaluationSubmissionListInput!) {
        evaluationSubmissionList(input: $input) {
          items {
            ...EvaluationSubmissionFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationSubmissionList
}
