import React, { memo, useMemo, useState } from 'react'
import { find } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { EvaluationListTable, useListEvaluation, EvaluationAllIndividualList } from '@cotiss/evaluation'
import {
  Banner,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  Hr,
  Text,
  TransitionContainer,
  TransitionType,
  routerService,
  useCallout,
} from '@cotiss/common'
import { AnimatePresence } from 'framer-motion'

export const EvaluationChairIndividualStep = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { progressTender } = useMutateTender()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const { evaluations } = useListEvaluation({ tenderId })
  const [transition, setTransition] = useState<TransitionType>('right')
  const [activeEntity, setActiveEntity] = useState<string | null>(null)

  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    if (activeEntity) {
      return [
        {
          label: 'Individual evaluations',
          onClick: () => {
            handleSetActiveEvaluation(null)
          },
        },
      ]
    }
    return []
  }, [activeEntity])

  if (!tender) return null

  const handleProgressProcurement = async () => {
    await progressTender(tender._id)
    push(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: 'consensus-scoring' }))
  }

  const handleSetActiveEvaluation = (userId: string | null) => {
    setTransition(userId ? 'right' : 'left')

    setTimeout(() => {
      setActiveEntity(userId || null)
    }, 0)
  }

  const renderBanner = () => {
    if (tender.validation.validationErrors.length) {
      return (
        <Banner className="mb-6" icon="clock" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Waiting for evaluation panel
            </Text>
            <Text size="sm">
              Consensus scoring can begin once all individual evaluations have been marked as completed or abstained with at least one completed
            </Text>
          </div>
        </Banner>
      )
    }

    return (
      <Banner className="mb-6" icon="check-circle" variant="success">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Individual evaluation has been completed!
          </Text>
          <Text size="sm">Proceed to Consensus scoring to review these results</Text>
        </div>
        <Button
          className="shrink-0"
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Proceed to consensus scoring?"
                description="This action cannot be undone."
                onSubmit={handleProgressProcurement}
              />
            )
          }
          size="sm"
          isDisabled={Boolean(
            find(evaluations, ({ status }) => status !== 'completed' && status !== 'abstained') || tender.validation.validationErrors.length
          )}
        >
          Proceed to consensus scoring
        </Button>
      </Banner>
    )
  }

  return (
    <>
      {tender.status === 'evaluate' && renderBanner()}
      <Text className="font-semibold" size="h7" font="jakarta">
        Individual Evaluations
      </Text>
      <Hr className="my-4" />
      <AnimatePresence mode="wait" initial={false}>
        {!activeEntity && (
          <TransitionContainer key={1} transition={transition}>
            <EvaluationAllIndividualList tenderId={tenderId} onView={handleSetActiveEvaluation} />
          </TransitionContainer>
        )}
        {activeEntity && (
          <TransitionContainer key={2} transition={transition}>
            <EvaluationListTable tenderId={tenderId} userId={activeEntity} breadcrumbs={breadcrumbs} />
          </TransitionContainer>
        )}
      </AnimatePresence>
    </>
  )
})
