import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { useUserAccess } from '@cotiss/user'
import { FourOhFourPage, Route, Redirect } from '@cotiss/common'
import {
  PerformanceScorecardListPage,
  PerformanceScorecardMetricCycleViewPage,
  PerformanceScorecardMetricViewPage,
  PerformanceScorecardViewPage,
} from '@cotiss/performance'

export const PerformanceLayout = memo(() => {
  const { permissions } = useUserAccess()

  if (!permissions.hasEvaluationAccess) {
    return <FourOhFourPage />
  }

  return (
    <Switch>
      <Route path="/performance/scorecard/list/:tab?" exact>
        <PerformanceScorecardListPage />
      </Route>
      <Route
        path="/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId/cycle/:performanceScorecardMetricCycleId"
        exact
      >
        <PerformanceScorecardMetricCycleViewPage />
      </Route>
      <Route path="/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId" exact>
        <PerformanceScorecardMetricViewPage />
      </Route>
      <Route path="/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?" exact>
        <PerformanceScorecardViewPage />
      </Route>
      <Redirect from="/performance" to="/performance/scorecard" exact />
      <Redirect from="/performance/scorecard" to="/performance/scorecard/list/:tab?" exact />
    </Switch>
  )
})
