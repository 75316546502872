import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { useUserAccess } from '@cotiss/user'
import { FourOhFourPage, Redirect, Route } from '@cotiss/common'
import {
  EvaluationEventEvaluateEnvelopeUserPage,
  EvaluationEventEvaluateViewPage,
  EvaluationEventListPage,
  EvaluationEventModerateEnvelopePage,
  EvaluationEventModerateEnvelopeSubmissionPage,
  EvaluationEventViewPage,
} from '@cotiss/evaluation-event'

export const EvaluationEventLayout = memo(() => {
  const { permissions } = useUserAccess()

  if (!permissions.hasEvaluationAccess) {
    return <FourOhFourPage />
  }

  return (
    <Switch>
      <Route path="/evaluation-event/list/:tab?" exact>
        <EvaluationEventListPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?" exact>
        <EvaluationEventEvaluateEnvelopeUserPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId" exact>
        <EvaluationEventEvaluateViewPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId" exact>
        <EvaluationEventModerateEnvelopePage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?" exact>
        <EvaluationEventModerateEnvelopeSubmissionPage />
      </Route>
      {/* This route should be last, just incase there is a clash in pattern. */}
      <Route path="/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?" exact>
        <EvaluationEventViewPage />
      </Route>
      <Redirect from="/evaluation-event" to="/evaluation-event/list/:tab?" exact />
    </Switch>
  )
})
