import { useHistory } from 'react-router-dom'
import React, { memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { ContractApproverAction, ContractModel, useMutateContractShell } from '@cotiss/contract'
import { ConfirmModal, Label, Text, TextArea, routerService, useCallout } from '@cotiss/common'
import { find } from 'lodash'

type Props = {
  contract: ContractModel | undefined
  action: ContractApproverAction
  approvalId: string | undefined
}

export const ContractApprovalModal = memo(({ contract, action, approvalId }: Props) => {
  const { push } = useHistory()
  const { closeModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { actionContractApprover } = useMutateContractShell()

  const handleSubmit = async () => {
    const approval = find(contract?.approvals, { _id: approvalId })
    const approver = find(approval?.approvers, ({ assigned }) => assigned._id === user?._id)

    if (!contract || !approval || !approver) {
      return
    }

    setIsSaving(true)
    await actionContractApprover({
      contractShellId: contract.contractShell,
      contractId: contract?._id,
      approvalId: approval._id,
      approverId: approver?._id,
      action,
      body: { comment },
    })

    setIsSaving(false)

    closeModal()
    push(
      routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?', {
        contractShellId: contract.contractShell,
        contractId: contract._id,
        approvalId: approval._id,
      })
    )
    setIsSaving(false)
  }

  // TODO: implement 'request changes' flow once supported by BE
  return (
    <ConfirmModal
      heading={`${action === 'request-changes' ? 'Reject' : 'Accept'} approval`}
      description={
        <>
          {action === 'request-changes' && (
            <>
              <Text>
                Please add a reason for rejecting. <span className="font-semibold">This action is final and cannot be undone.</span>
              </Text>
              <Label className="mt-4">Reason</Label>
              <TextArea
                className="mt-1"
                value={comment}
                rows={4}
                onChange={({ target }) => setComment(target.value)}
                isDisabled={isSaving}
                isRequired
              />
            </>
          )}
          {action === 'approved' && (
            <>
              <Text>
                Please confirm you would like to approve this contract.{' '}
                <span className="font-semibold">This action is final and cannot be undone.</span>
              </Text>
              <Label className="mt-4">Comment (optional)</Label>
              <TextArea className="mt-1" value={comment} rows={4} onChange={({ target }) => setComment(target.value)} isDisabled={isSaving} />
            </>
          )}
        </>
      }
      onSubmit={handleSubmit}
    />
  )
})
