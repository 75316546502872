import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Text } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
}

export const Label = memo(({ className, children }: Props) => {
  const classes = classNames(className, 'font-medium text-sm text-left mb-1')

  return (
    <Text className={classes} variant="heading">
      {children}
    </Text>
  )
})
