import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useState } from 'react'
import { usePlanEvent } from '@cotiss/plan-event'
import {
  Form,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  routerService,
  sentryService,
  useAnalytics,
  useCallout,
  useToast,
} from '@cotiss/common'

export const PlanEventCreateModal = memo(() => {
  const { push } = useHistory()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [name, setName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { mutateCreatePlanEvent } = usePlanEvent()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('plan_event_list_create_submit')

    try {
      setIsSaving(true)
      const planEvent = await mutateCreatePlanEvent({ name: name })

      push(routerService.getHref('/plan-event/view/:planEventId/:tab?/:nestedTab?', { planEventId: planEvent.id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Create plan" isDisabled={isSaving} />
      <ModalContent>
        <Label>Plan title</Label>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Enter a unique title for this plan"
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
