import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PLAN_USER_FRAGMENT } from '@cotiss/plan-event'
import { GqlCreatePlanUserInput, GqlCreatePlanUserMutation, GqlCreatePlanUserMutationVariables, GqlPlanUserFieldsFragment } from '@gql'

export const mutateCreatePlanUser = async (input: GqlCreatePlanUserInput) => {
  const { data } = await apolloService.mutate<GqlCreatePlanUserMutation, GqlCreatePlanUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${PLAN_USER_FRAGMENT}

      mutation CreatePlanUser($input: CreatePlanUserInput!) {
        createPlanUser(input: $input) {
          ...PlanUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPlanUser as GqlPlanUserFieldsFragment
}
