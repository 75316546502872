import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlEvaluationViewInput, GqlEvaluationOverviewViewQuery } from '@gql'
import { EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT, EVALUATION_OVERVIEW_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationOverviewView = async (input: GqlEvaluationViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationOverviewViewQuery>({
    query: gql`
      ${EVALUATION_OVERVIEW_FRAGMENT}
      ${EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}

      query EvaluationOverviewView($input: EvaluationViewInput!) {
        evaluationOverviewView(input: $input) {
          ...EvaluationOverviewFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationOverviewView
}
