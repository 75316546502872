import React, { memo } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { OrganisationLogo } from '@cotiss/organisation'
import { Button, Icon, Text, useCallout } from '@cotiss/common'
import { SettingsOrganisationUploadLogoModal } from '@cotiss/settings'

export const SettingsOrganisationFormLogo = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openModal } = useCallout()

  return (
    <div className="flex items-start justify-between">
      <div>
        <Text className="font-medium" variant="heading">
          Organisation logo
        </Text>
        <Text className="mt-1" variant="light">
          This will be shown on all posted listings.
        </Text>
        <OrganisationLogo className="mt-2" organisation={user?.account?.organisation} />
      </div>
      <Button onClick={() => openModal(<SettingsOrganisationUploadLogoModal />)} state="translucent" variant="secondary" size="sm">
        <Icon className="mr-2" icon="upload" size={20} />
        Upload image
      </Button>
    </div>
  )
})
