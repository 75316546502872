import { includes, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { useGetLoggedInUser, UserAvatarGroup } from '@cotiss/user'
import { PerformanceScorecardCreateDrawer, PerformanceScorecardStatusBadge, usePerformanceScorecard } from '@cotiss/performance'
import {
  ConfirmModal,
  ErrorPanel,
  Icon,
  NoDataPlaceholder,
  routerService,
  sentryService,
  Table,
  TableColumn,
  TableRowCta,
  Text,
  useAnalytics,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

type Props = {
  isArchived?: boolean
}

export const PerformanceScorecardList = memo(({ isArchived = false }: Props) => {
  const { track } = useAnalytics()
  const { user } = useGetLoggedInUser()
  const [isError, setIsError] = useState(false)
  const { openDrawer, openModal } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const canCreatePerformanceScorecard = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])
  const { performanceScorecards, setPerformanceScorecard, queryPerformanceScorecardList, mutateUpdatePerformanceScorecard } =
    usePerformanceScorecard()

  const handleQueryPerformanceScorecardList = async () => {
    try {
      setPerformanceScorecard(null)

      const { pagination } = await queryPerformanceScorecardList({
        filter: {
          isArchived,
          isUserContext: true,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    track(isArchived ? 'performance_scorecard_list_archived_view' : 'performance_scorecard_list_active_view')
  }, [isArchived])

  useAsyncEffect(async () => {
    handleQueryPerformanceScorecardList()
  }, [currentPage])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  const handleArchive = async (performanceScorecardId: string, isArchived: boolean) => {
    track(isArchived ? 'performance_scorecard_list_archive_submit' : 'performance_scorecard_list_unarchive_submit')

    await mutateUpdatePerformanceScorecard({ performanceScorecardId, isArchived })
    await handleQueryPerformanceScorecardList()
  }

  if (!performanceScorecards.length && !isLoading) {
    if (isArchived) {
      return <NoDataPlaceholder label="You have not been added to any archived scorecards." />
    }

    return (
      <NoDataPlaceholder
        label="You have not been added to any scorecards yet."
        ctaLabel={canCreatePerformanceScorecard ? '+ New scorecard' : undefined}
        onCtaClick={canCreatePerformanceScorecard ? () => openDrawer(<PerformanceScorecardCreateDrawer />) : undefined}
      />
    )
  }

  const columns: TableColumn[] = [
    {
      heading: 'Scorecard',
      rows: map(performanceScorecards, (performanceScorecard) => ({
        content: () => (
          <Text className="font-medium truncate" font="jakarta" title={performanceScorecard.name}>
            {performanceScorecard.name}
          </Text>
        ),
        cta: (
          <TableRowCta
            cta={
              !isArchived
                ? {
                    href: routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
                      performanceScorecardId: performanceScorecard.id,
                    }),
                    label: (
                      <>
                        View <Icon className="ml-1" icon="arrow-right" />
                      </>
                    ),
                  }
                : undefined
            }
            actions={
              canCreatePerformanceScorecard
                ? [
                    {
                      onClick: () =>
                        openModal(
                          <ConfirmModal
                            heading={performanceScorecard.isArchived ? 'Unarchive' : 'Archive'}
                            description={`Are you sure you want to ${performanceScorecard.isArchived ? 'unarchive' : 'archive'} this scorecard?`}
                            onSubmit={() => handleArchive(performanceScorecard.id, !performanceScorecard.isArchived)}
                          />
                        ),
                      label: performanceScorecard.isArchived ? 'Unarchive' : 'Archive',
                    },
                  ]
                : undefined
            }
          />
        ),
      })),
    },
    {
      heading: 'Vendor',
      rows: map(performanceScorecards, ({ contact }) => ({
        content: () => <Text>{contact.name}</Text>,
      })),
    },
    {
      heading: 'Contract',
      rows: map(performanceScorecards, ({ contractShell }) => ({
        content: () => <Text>{contractShell?.title || '--'}</Text>,
      })),
    },
    {
      heading: 'Team',
      rows: map(performanceScorecards, ({ performanceScorecardUsers }) => ({
        content: () => <UserAvatarGroup users={map(performanceScorecardUsers, ({ user }) => user)} />,
      })),
    },
    {
      heading: 'Status',
      rows: map(performanceScorecards, ({ status }) => ({
        content: () => <PerformanceScorecardStatusBadge status={status} size="sm" />,
      })),
    },
  ]

  return <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
