import { useMutate } from '@cotiss/common'
import { ReportFinalScoreExportModel } from '@cotiss/report'

type ExportFinalScoringReportBody = {
  tenderId: string
}

export const useMutateReport = () => {
  const { mutate } = useMutate()

  return {
    exportFinalScoringReport: async (body: ExportFinalScoringReportBody) => {
      return await mutate<ReportFinalScoreExportModel>({ route: `/v2/reports/final-score-report/export`, body })
    },
    exportPricingTableReport: async (body: ExportFinalScoringReportBody) => {
      return await mutate<ReportFinalScoreExportModel>({ route: `/v2/reports/pricing-table-report/export`, body })
    },
  }
}
