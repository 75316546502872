import { includes } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationCriteriaRatingScale, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Button, Drawer, Hr, Label, Select_DEPRECATED, Text, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  EVALUATION_EVENT_RATING_SCALE_OPTIONS,
  EvaluationEventRatingScaleDefinitionList,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
  useEvaluationCriteria,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'

type FormData = {
  ratingScale: GqlEvaluationCriteriaRatingScale
  supplementary: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  isEditable?: boolean
}

export const EvaluationEventCriteriaRatingScaleDrawer = memo(({ evaluationEnvelope, evaluationCriteriaItem, isEditable }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateUpdateEvaluationCriteria } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    ratingScale: evaluationCriteriaItem.ratingScale || evaluationEnvelope.defaultRatingScale,
    supplementary: evaluationCriteriaItem.supplementary || '',
  })

  useEffect(() => {
    setFormData({
      ratingScale: evaluationCriteriaItem.ratingScale || evaluationEnvelope.defaultRatingScale,
      supplementary: evaluationCriteriaItem.supplementary || '',
    })
  }, [evaluationCriteriaItem, evaluationEnvelope])

  useEffect(() => {
    track('evaluation_event_wizard_criteria_edit_rating_scale_view')
  }, [])

  const handleSubmit = async () => {
    track('evaluation_event_wizard_criteria_edit_rating_scale_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationCriteria({ evaluationCriteriaId: evaluationCriteriaItem.id, ...formData })
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      Edit rating scale
    </Text>
  )

  const renderFooter = () => {
    if (!isEditable) {
      return null
    }

    return (
      <>
        <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
          Confirm
        </Button>
        <Button onClick={() => closeDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <Label>
        Scoring requirements{' '}
        <Text isInline variant="light">
          (optional)
        </Text>{' '}
      </Label>
      <Text variant="light" size="sm">
        This is where you can add custom rating scale definitions or any additional scoring information.
      </Text>
      <TextArea
        className="mt-2"
        value={formData.supplementary}
        onChange={({ target }) => setFormData({ ...formData, supplementary: target.value })}
        rows={5}
        isDisabled={isSaving || !isEditable}
      />
      <Hr className="mt-4" />
      <div className="flex justify-between mt-8">
        <Label className="flex-1">Rating scale</Label>
        <Select_DEPRECATED<GqlEvaluationCriteriaRatingScale>
          className="flex-1"
          value={formData.ratingScale}
          options={EVALUATION_EVENT_RATING_SCALE_OPTIONS}
          onChange={(ratingScale) => setFormData({ ...formData, ratingScale })}
          isDisabled={isSaving || !isEditable}
          placeholder
          isRequired
          isFull={false}
        />
      </div>
      {includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, formData.ratingScale) && (
        <EvaluationEventRatingScaleDefinitionList className="mt-4" ratingScale={formData.ratingScale} />
      )}
    </Drawer>
  )
})
