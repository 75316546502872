import { ProjectModel } from '@cotiss/project'
import { PaginatedModel, useFetch } from '@cotiss/common'

type Props = {
  limit?: number
  offset?: number
}

export const useListProject = ({ limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ProjectModel>>('/v2/projects', {
    params: { limit, offset },
  })

  return { projects: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
