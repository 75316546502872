import { useFetch } from '@cotiss/common'
import { ProcurementPopulatedModel } from '@cotiss/procurement'

export const useGetProcurement = (id?: string) => {
  const { data: procurement, ...rest } = useFetch<ProcurementPopulatedModel>('/v2/procurements', {
    route: `/v2/procurements/${id}`,
    isEnabled: Boolean(id),
  })

  return { procurement, ...rest }
}
