import { ConfirmModal, DatetimeInput, Form, Label, ModalContent, ModalFooter, ModalHeader, useCallout } from '@cotiss/common'
import { TenderFieldWithHistory, TenderModel, TenderPopulatedModel, useMutateTender } from '@cotiss/tender'
import { parseISO } from 'date-fns'
import React, { FormEvent, memo, useState } from 'react'

type Props = {
  tender: TenderPopulatedModel | TenderModel
  field: TenderFieldWithHistory
}

export const TenderUpdateEndDateModal = memo(({ tender, field }: Props) => {
  const { openModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [endDate, setEndDate] = useState<Date | null>(tender[field]?.endDate ? parseISO(tender[field]?.endDate as string) : null)

  const handleUpdateTenderEndDate = async () => {
    if (!endDate) {
      return
    }

    await updateTender(tender._id, { [field]: { endDate: endDate.toISOString() } })
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    openModal(
      <ConfirmModal
        heading={`Are you sure you want to edit the ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date?`}
        description={`Vendors will be able to view a history log of all changes to the ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date`}
        onSubmit={handleUpdateTenderEndDate}
      />
    )
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`Extend ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date`} />
      <ModalContent>
        <Label>New close date</Label>
        <DatetimeInput value={endDate} onChange={(endDate) => setEndDate(endDate)} isFuture isRequired />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
