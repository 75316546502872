// NOTE: This is part of a DEPRECATED and UNUSED flow.

import React, { memo } from 'react'
import { Hr, Text } from '@cotiss/common'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria'
import { TenderQuestionReportModel, TenderQuestionScoreGraph, useListTenderQuestionScore } from '@cotiss/tender-question'

type Props = {
  tenderQuestionId: string
  tenderResponseId: string
  tenderCriterionReport: TenderCriterionReportModel
  tenderQuestionReport: TenderQuestionReportModel
}

export const TenderQuestionReportQuestion = memo(({ tenderQuestionId, tenderResponseId, tenderCriterionReport, tenderQuestionReport }: Props) => {
  const { tenderQuestionScores } = useListTenderQuestionScore({ tenderQuestionId, tenderResponseId, evaluationStatus: 'completed' })

  return (
    <>
      <Text className="uppercase" variant="light" size="sm">
        Criteria {tenderCriterionReport.index + 1}.
      </Text>
      <Text className="font-semibold mt-1">{tenderCriterionReport.name}</Text>
      <Hr className="my-4" />
      <Text className="uppercase" variant="light" size="sm">
        Question {tenderCriterionReport.index + 1}.{tenderQuestionReport.index + 1}.
      </Text>
      <Text className="whitespace-pre-wrap mt-1" size="sm">
        {tenderQuestionReport.question}
      </Text>
      <TenderQuestionScoreGraph tenderQuestionScores={tenderQuestionScores} />
    </>
  )
})
