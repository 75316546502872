import React, { useState, useEffect, FormEvent, memo } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { useMutateAuth, useOtpAuthenticatorHook } from '@cotiss/auth'
import { Input, Form, useToast, sentryService, Label, ExternalLink, EXTERNAL_LINK, Button, Text } from '@cotiss/common'

type FormData = {
  secret: string
  token: string
}

type Props = {
  onClose: () => void
}

export const SettingsTwoFactorRegisterForm = memo(({ onClose }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { registerTwoFactor } = useMutateAuth()
  const [isSaving, setIsSaving] = useState(false)
  const { getImage, image, secret } = useOtpAuthenticatorHook()
  const [formData, setFormData] = useState<FormData>({ secret: '', token: '' })

  useEffect(() => {
    const userId = user?._id as string
    getImage(userId)
  }, [open])

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await registerTwoFactor({ ...formData, secret })
      setIsSaving(false)
      onClose()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="space-y-2" onSubmit={onSubmit}>
      <Label>Configure Two Factor Authentication</Label>
      <Text>An Authenticator app is a mobile application which adds an additional layer of security if your email and password are compromised.</Text>
      <br />
      <Text>
        We recommend using one of the following applications for your authenticator app.
        <ul className="list-disc">
          <li className="ml-6">
            Google Authenticator –{' '}
            <ExternalLink className=" inline-block" href={EXTERNAL_LINK.authenticatorAppApple}>
              Apple
            </ExternalLink>
            ,{' '}
            <ExternalLink className=" inline-block" href={EXTERNAL_LINK.authenticatorAppAndroid}>
              Android
            </ExternalLink>
          </li>
          <li className="ml-6">
            <ExternalLink href={EXTERNAL_LINK.authenticatorAppMicrosoft}>Microsoft Authenticator</ExternalLink>
          </li>
        </ul>
      </Text>
      {image && <img src={image} alt="" />}
      <Text>or enter the following code manually:</Text>
      <Text>
        {secret.match(/.{1,4}/g)?.map((part, index) => (
          <span key={index + part} className="font-mono mr-3">
            {part}
          </span>
        ))}
      </Text>
      <Label>Verification code</Label>
      <Input placeholder="Enter your code" isRequired onChange={({ target }) => setFormData({ ...formData, token: target.value })} />
      <div className="flex items-center justify-end">
        <Button className="mr-2" onClick={() => close()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
          Save
        </Button>
      </div>
    </Form>
  )
})
