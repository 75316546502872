import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeleteEvaluationEnvelopeDocumentInput,
  GqlDeleteEvaluationEnvelopeDocumentMutation,
  GqlDeleteEvaluationEnvelopeDocumentMutationVariables,
} from '@gql'

export const mutateDeleteEvaluationEnvelopeDocument = async (input: GqlDeleteEvaluationEnvelopeDocumentInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationEnvelopeDocumentMutation, GqlDeleteEvaluationEnvelopeDocumentMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationEnvelopeDocument($input: DeleteEvaluationEnvelopeDocumentInput!) {
        deleteEvaluationEnvelopeDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationEnvelopeDocument as boolean
}
