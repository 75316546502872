/* eslint-disable react/forbid-elements */
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import React, { HTMLProps, ReactNode, forwardRef } from 'react'
import { FormHiddenInput } from '@cotiss/common'
import { useGetLoggedInUser } from '@cotiss/user'

type HtmlFormAttributes = HTMLProps<HTMLFormElement> & {
  'hs-do-not-collect'?: 'true'
}

type Props = HTMLProps<HTMLFormElement> & {
  children: ReactNode
  isHubSpot?: boolean
}

export const Form = forwardRef(({ className, children, isHubSpot, ...rest }: Props, ref: React.ForwardedRef<HTMLFormElement>) => {
  const { pathname } = useLocation()
  const { user } = useGetLoggedInUser()
  const attrs: HtmlFormAttributes = { ...rest }
  const classes = classNames(className, { relative: isHubSpot })

  if (!isHubSpot) {
    attrs['hs-do-not-collect'] = 'true'
  }

  const renderHiddenHubSpotFields = () => (
    <>
      {user?.email && <FormHiddenInput value={user.email} type="email" />}
      {user?.firstname && <FormHiddenInput value={user.firstname} name="First name" />}
      {user?.surname && <FormHiddenInput value={user.surname} name="Last name" />}
      {user?.phone && <FormHiddenInput value={user.phone} name="Phone number" />}
      {user?.account?.organisation && <FormHiddenInput value={user.account.organisation.name} name="Company name" />}
    </>
  )

  return (
    <form className={classes} name={pathname} ref={ref} {...attrs}>
      {isHubSpot && user && renderHiddenHubSpotFields()}
      {children}
    </form>
  )
})
