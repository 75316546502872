import { forEach, map } from 'lodash'
import React, { memo, useMemo } from 'react'
import { TenderResponsePriceTableItemCta, useGetTenderResponse } from '@cotiss/tender-response'
import {
  Button,
  Icon,
  ScrollableTable,
  ScrollableTableColumn,
  ScrollableTableRowContentParam,
  Text,
  datetimeService,
  useCallout,
  utilService,
} from '@cotiss/common'
import { PriceItemResponseAddUpdateModal, priceItemResponseService, useListPriceItemResponse } from '@cotiss/price-item-response'
import { useGetTender } from '@cotiss/tender/resources'
import { tenderService } from '@cotiss/tender'

type Props = {
  className?: string
  tenderResponseId: string
  isNpvVisible?: boolean
  isEditable?: boolean
}

// ! The values of the table can either come from the `PriceItemResponse.priceItem.{...}`, or directly off the `PriceItemResponse.{...}`. This is
// ! because the buyer sets their price items on the price item, and if the supplier has permission to add their own line items via
// ! `priceTableAllowAdditionalItems` being set on the tender, they create their own line items, but their properties are set directly on
// ! `PriceItemResponse`. This is not an intuitive model, and we should consider refining this in the future.

// ! Note the above only applies to the properties that exist on the PriceItemModel. So `paymentDate` and `unitPrice` are not included, and these are
// ! only set by the supplier on the PriceItemResponse.

export const TenderResponsePriceTable = memo(({ className, tenderResponseId, isNpvVisible, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { tenderResponse, isLoading: isTenderResponseLoading } = useGetTenderResponse(tenderResponseId)
  const { tender } = useGetTender(tenderResponse?.tender._id)
  const { priceItemResponses, isLoading: isPriceItemResponsesLoading } = useListPriceItemResponse({ tenderResponseId })
  const isLoading = isTenderResponseLoading || isPriceItemResponsesLoading
  const isTenderClosed = Boolean(tender && tenderService.isTenderClosed(tender))

  // ! If priceTableEnableBuyerItems is true, then the buyer can decide if the supplier is allowed to add additional items or not (This is via the
  // ! priceTableAllowAdditionalItems property on the tender). However, if it is false, then the supplier automatically has permission to add
  // ! additional items. This is because there needs to be at least one source of price items if price table is enabled for the tender. Either
  // ! exclusively from the buyer, or both the supplier and buyer, or exclusively from the supplier.
  const canAddRows = isEditable && (tenderResponse?.tender.priceTableAllowAdditionalItems || !tenderResponse?.tender.priceTableEnableBuyerItems)

  const { totalPrice, totalNpv } = useMemo(() => {
    let totalPrice = 0

    forEach(priceItemResponses, ({ priceItem, quantity, unitPrice }) => {
      // ! We have to prioritise the quantity off the price item response, as this is set by the supplier. If the supplier hasn't set a value, we can
      // ! fall back to the buyer's value that are set on the nested price item.
      const quantityToUse = quantity ?? priceItem?.quantity

      if (quantityToUse && unitPrice) {
        totalPrice += quantityToUse * unitPrice
      }
    })

    const totalNpv = tenderResponse?.tender.npv ? priceItemResponseService.getTotalNpv({ npv: tenderResponse.tender.npv, priceItemResponses }) : 0

    return { totalPrice, totalNpv }
  }, [tenderResponse, priceItemResponses])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Category',
      rows: [
        ...(priceItemResponses.length
          ? map(priceItemResponses, (priceItemResponse) => ({
              content: ({ isHighlighted }: ScrollableTableRowContentParam) => (
                <div className="truncate w-full">
                  <div className="flex items-center justify-between">
                    <Text size="sm" className="truncate">
                      {priceItemResponse.priceItem?.category || priceItemResponse.category}
                    </Text>
                    {(!isHighlighted || isTenderClosed) && tender && !priceItemResponseService.isComplete({ priceItemResponse, tender }) && (
                      <Icon icon="annotation-alert" className="text-alert-error-400" />
                    )}
                  </div>
                </div>
              ),
              cta: isEditable && <TenderResponsePriceTableItemCta tenderResponseId={tenderResponseId} priceItemResponse={priceItemResponse} />,
            }))
          : [
              {
                content: () => (
                  <>
                    {canAddRows && (
                      <Button
                        onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} />)}
                        state="text"
                        variant="secondary"
                      >
                        + Add row
                      </Button>
                    )}
                  </>
                ),
              },
            ]),
      ],
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Description',
      rows: map(priceItemResponses, ({ priceItem, description }) => ({
        content: () => <Text size="sm">{description || priceItem?.description}</Text>,
      })),
    },
    {
      heading: 'Quantity',
      rows: map(priceItemResponses, ({ priceItem, quantity }) => {
        const delta = priceItem?.quantity !== undefined && quantity !== undefined ? quantity - priceItem.quantity : 0
        const isNegative = delta < 0

        return {
          // ! We have to prioritise the quantity off the price item response, as this is set by the supplier. If the supplier hasn't set a value, we
          // ! can fall back to the buyer's value that are set on the nested price item.
          content: () => (
            <>
              <Text size="sm" isInline>
                {quantity ?? priceItem?.quantity}
              </Text>
              {Boolean(delta) && (
                <Text className="ml-1" size="sm" variant={isNegative ? 'danger' : 'success'} isInline>
                  {`${isNegative ? '-' : '+'}${Math.abs(delta)}`}
                </Text>
              )}
            </>
          ),
        }
      }),
    },
    {
      heading: 'Unit',
      rows: map(priceItemResponses, ({ priceItem, unit }) => ({
        // ! We have to prioritise the unit off the price item response, as this is set by the supplier. If the supplier hasn't set a value, we can
        // ! fall back to the buyer's value that are set on the nested price item.
        content: () => <Text size="sm">{unit ?? priceItem?.unit}</Text>,
      })),
    },

    {
      heading: 'Unit price',
      rows: map(priceItemResponses, ({ unitPrice }) => ({
        content: () => (
          <Text size="sm">{unitPrice !== undefined ? utilService.formatAsCurrency(unitPrice, tenderResponse?.tender.currency) : '--'}</Text>
        ),
      })),
    },
    {
      heading: 'Total',
      rows: map(priceItemResponses, ({ priceItem, quantity, unitPrice }) => {
        // ! We have to prioritise the quantity off the price item response, as this is set by the supplier. If the supplier hasn't set a value, we
        // ! can fall back to the buyer's value that are set on the nested price item.
        const quantityToUse = quantity ?? priceItem?.quantity

        return {
          content: () => (
            <Text size="sm">
              {unitPrice !== undefined && quantityToUse !== undefined
                ? utilService.formatAsCurrency(quantityToUse * unitPrice, tenderResponse?.tender.currency)
                : '--'}
            </Text>
          ),
        }
      }),
    },
  ]

  if (tenderResponse?.tender.priceTableRequirePaymentDate) {
    columns.push({
      heading: 'Payment date',
      rows: map(priceItemResponses, ({ paymentDate }) => ({
        content: () => <Text size="sm">{paymentDate ? datetimeService.format(paymentDate, 'do MMM yyyy') : '--'}</Text>,
      })),
    })
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between bg-primary-100 border-x border-t border-primary-200 rounded-t-lg px-4 py-3 mt-4">
        <div className="mr-4">
          <Text className="font-semibold" variant="heading" font="jakarta">
            Line items
          </Text>
        </div>
        {canAddRows && (
          <div className="flex items-center">
            <Button onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} />)} variant="secondary" size="sm">
              + Add row
            </Button>
          </div>
        )}
      </div>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />

      {tenderResponse && (
        <div className="bg-secondary-100 flex flex-col items-end px-6 py-3 mt-6 rounded">
          <div className="flex whitespace-nowrap items-center">
            <Text className="font-medium mr-3" size="sm">
              Total price:{' '}
            </Text>
            <div className="flex items-center bg-white rounded pl-2 py-2 pr-14">
              <Text className="font-medium" variant="secondary">
                {totalPrice ? utilService.formatAsCurrency(totalPrice, tenderResponse.tender.currency) : '--'}
              </Text>
            </div>
          </div>
          {isNpvVisible && (
            <div className="flex whitespace-nowrap items-center mt-3">
              <Text className="font-medium mr-3" size="sm">
                Net present value (NPV):{' '}
              </Text>
              <div className="flex items-center bg-white rounded pl-2 py-2 pr-14">
                <Text className="font-medium 2" variant="secondary">
                  {totalNpv ? utilService.formatAsCurrency(totalNpv, tenderResponse.tender.currency) : '--'}
                </Text>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
})
