import { gql } from '@apollo/client'

export const EVALUATION_EVENT_DOCUMENT_FRAGMENT = gql`
  fragment EvaluationEventDocumentFields on EvaluationEventDocument {
    id
    evaluationEventId
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
