import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreateEvaluationSubmissionDocument,
  mutateCreateEvaluationSubmissionDocuments,
  mutateDeleteEvaluationSubmissionDocument,
  queryEvaluationSubmissionDocumentList,
} from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationSubmissionDocumentInput,
  GqlCreateEvaluationSubmissionDocumentsInput,
  GqlDeleteEvaluationSubmissionDocumentInput,
  GqlEvaluationSubmissionDocumentFieldsFragment,
  GqlEvaluationSubmissionDocumentListInput,
} from '@gql'

const evaluationSubmissionDocumentVar = makeVar<GqlEvaluationSubmissionDocumentFieldsFragment | null>(null)
const evaluationSubmissionDocumentsVar = makeVar<GqlEvaluationSubmissionDocumentFieldsFragment[]>([])

export const useEvaluationSubmissionDocument = () => {
  const evaluationSubmissionDocument = useReactiveVar(evaluationSubmissionDocumentVar)
  const evaluationSubmissionDocuments = useReactiveVar(evaluationSubmissionDocumentsVar)

  return {
    evaluationSubmissionDocument,
    evaluationSubmissionDocuments,
    setEvaluationSubmissionDocument: evaluationSubmissionDocumentVar,
    setEvaluationSubmissionDocuments: evaluationSubmissionDocumentsVar,
    queryEvaluationSubmissionDocumentList: async (input: GqlEvaluationSubmissionDocumentListInput) => {
      const { items: evaluationSubmissionDocuments, pagination } = await queryEvaluationSubmissionDocumentList(input)

      evaluationSubmissionDocumentsVar(evaluationSubmissionDocuments)

      return { evaluationSubmissionDocuments, pagination }
    },
    mutateCreateEvaluationSubmissionDocument: async (input: GqlCreateEvaluationSubmissionDocumentInput) => {
      const createdEvaluationSubmissionDocument = await mutateCreateEvaluationSubmissionDocument(input)

      evaluationSubmissionDocumentsVar(uniqBy([createdEvaluationSubmissionDocument, ...evaluationSubmissionDocuments], 'id'))

      return createdEvaluationSubmissionDocument
    },
    mutateCreateEvaluationSubmissionDocuments: async (input: GqlCreateEvaluationSubmissionDocumentsInput) => {
      const createdEvaluationSubmissionDocuments = await mutateCreateEvaluationSubmissionDocuments(input)

      evaluationSubmissionDocumentsVar(uniqBy([...createdEvaluationSubmissionDocuments, ...evaluationSubmissionDocuments], 'id'))

      return createdEvaluationSubmissionDocuments
    },
    mutateDeleteEvaluationSubmissionDocument: async (input: GqlDeleteEvaluationSubmissionDocumentInput) => {
      await mutateDeleteEvaluationSubmissionDocument(input)

      evaluationSubmissionDocumentsVar(filter(evaluationSubmissionDocuments, ({ id }) => id !== input.evaluationSubmissionDocumentId))
    },
  }
}

export const clearReactiveEvaluationSubmissionDocument = async () => {
  evaluationSubmissionDocumentVar(null)
  evaluationSubmissionDocumentsVar([])
}
