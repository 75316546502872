import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { ApprovalStatus } from '@cotiss/approval'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'

const getVariant = (status: ApprovalStatus): BaseVariant => {
  switch (status) {
    case 'incomplete':
      return 'warning'
    case 'approved':
      return 'success'
    case 'rejected':
      return 'danger'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ApprovalStatus
  size?: BadgeSize
}

export const ApprovalStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status))}
  </Badge>
))
