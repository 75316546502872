import { gql } from '@apollo/client'

export const EVALUATION_CRITERIA_FRAGMENT = gql`
  fragment EvaluationCriteriaFields on EvaluationCriteria {
    id
    evaluationEnvelopeId
    parentEvaluationCriteriaId
    content
    weight
    ratingScale
    index
    supplementary
    isScored
    createdAt
    updatedAt
    deletedAt
  }
`
