import { upperFirst } from 'lodash'
import React, { memo } from 'react'
import { Badge, BadgeSize, BaseVariant, Text } from '@cotiss/common'
import { ConflictOfInterestStatus } from '@cotiss/conflict-of-interest'

const getVariant = (outcome: ConflictOfInterestStatus): BaseVariant => {
  switch (outcome) {
    case 'conflict':
    default:
      return 'danger'
    case 'no-conflict':
      return 'success'
  }
}

type Props = {
  className?: string
  status: ConflictOfInterestStatus
  size?: BadgeSize
}

// TODO: Change to dot type when implemented
export const ConflictOfInterestStatusBadge = memo(({ className, status, size = 'sm' }: Props) =>
  status === '--' ? (
    <Text variant="light">--</Text>
  ) : (
    <Badge className={className} size={size} variant={getVariant(status)} state="dot">
      {upperFirst(status === 'conflict' ? status : 'no conflict')}
    </Badge>
  )
)
