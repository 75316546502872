import { useFetch } from '@cotiss/common'
import { DocumentModel, DownloadDocumentInfo } from '@cotiss/document'

export const useGetDocument = (id?: string, type?: DownloadDocumentInfo['type']) => {
  const { data: document, ...rest } = useFetch<DocumentModel>('/v2/documents', {
    route: `/v2/documents/${id}`,
    params: { contentDisposition: type }, // 'inline' will attempt to review in another tab. 'attachment' will automatically download without preview
    isEnabled: Boolean(id),
  })

  return { document, ...rest }
}
