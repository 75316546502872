import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT = gql`
  fragment PerformanceScorecardMetricCycleDocumentFields on PerformanceScorecardMetricCycleDocument {
    id
    performanceScorecardMetricCycleId
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
