import React, { FormEvent, memo, useState } from 'react'
import { UserModel, useMutateUser, userService } from '@cotiss/user'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  user: UserModel
}

export const UserDeleteConfirmModal = memo(({ user }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { deleteUser } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await deleteUser(user._id)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="cross" />
        </Button>

        <Text className="font-semibold" size="h5" font="jakarta">
          Delete user
        </Text>

        <Text className="mt-4">
          Are you sure you would like to remove <strong>&quot;{userService.getFullName(user)}&quot;</strong>?
        </Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Deactivate user
      </ModalConfirmationButton>
    </Form>
  )
})
