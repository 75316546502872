import { Button, Card, CardFormHeader, Field, Form, Hr, RichText, RichTextEditor, richTextService, sentryService, useToast } from '@cotiss/common'
import { useGetProcurement, useMutateProcurement } from '@cotiss/procurement'
import React, { FormEvent, memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RemirrorJSON } from 'remirror'

type FormData = {
  marketAnalysis: RemirrorJSON
}

export const ProcurementOverviewBackgroundMarketAnalysisTab = memo(() => {
  const { openToast } = useToast()
  const { procurementId } = useParams<{ procurementId: string }>()
  const { procurement } = useGetProcurement(procurementId)
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateProcurement } = useMutateProcurement()
  const isEditable = true // TODO: Figure out when this should be editable
  const [formData, setFormData] = useState<FormData>({
    marketAnalysis: procurement?.marketAnalysis || richTextService.getEmptyRemirrorJson(),
  })

  useEffect(() => {
    if (!procurement) {
      return
    }

    setFormData({ marketAnalysis: procurement.marketAnalysis || richTextService.getEmptyRemirrorJson() })
  }, [procurement])

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({ marketAnalysis: procurement?.marketAnalysis || richTextService.getEmptyRemirrorJson() })

    setIsEditing(isEditing)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateProcurement(procurementId, formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderContent = () => {
    if (isEditing) {
      return (
        <Field label="Market analysis">
          <RichTextEditor
            value={formData.marketAnalysis}
            onChange={(marketAnalysis) => setFormData({ ...formData, marketAnalysis })}
            isDisabled={isSaving}
            isRequired
          />
        </Field>
      )
    }

    return (
      <Field label="Market analysis">
        {!procurement?.marketAnalysis && isEditable && renderToggleLink('+ Add market analysis')}
        {procurement?.marketAnalysis && <RichText value={procurement.marketAnalysis} />}
      </Field>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader heading="Market analysis" onToggleEdit={handleToggleEdit} isEditable={isEditable} isEditing={isEditing} isSaving={isSaving} />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
