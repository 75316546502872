var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-columns/src/columns-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  isElementDomNode,
  joinStyles,
  NodeExtension,
  omitExtraAttributes
} from "@remirror/core";
import { ExtensionColumnsMessages as Messages } from "@remirror/messages";
var toggleColumnsOptions = {
  icon: ({ attrs }) => ({ name: "layoutColumnLine", sup: attrs == null ? void 0 : attrs.count }),
  label: ({ t, attrs }) => t(Messages.LABEL, { count: attrs == null ? void 0 : attrs.count }),
  description: ({ t, attrs }) => t(Messages.DESCRIPTION, { count: attrs == null ? void 0 : attrs.count })
};
var DEFAULT_COLUMN_ATTRIBUTES = {
  count: 2,
  fill: "auto",
  gap: "inherit",
  ruleColor: "inherit",
  ruleStyle: "none",
  ruleWidth: "inherit",
  width: "inherit"
};
var COLUMN_DATA_ATTRIBUTE = "data-column-type";
var ColumnsExtension = class extends NodeExtension {
  get name() {
    return "columns";
  }
  createTags() {
    return [ExtensionTag.Block];
  }
  createNodeSpec(extra, override) {
    return {
      ...override,
      content: "block+",
      attrs: {
        ...extra.defaults(),
        count: { default: this.options.defaults.count },
        fill: { default: this.options.defaults.fill },
        gap: { default: this.options.defaults.gap },
        ruleColor: { default: this.options.defaults.ruleColor },
        ruleStyle: { default: this.options.defaults.ruleStyle },
        ruleWidth: { default: this.options.defaults.ruleWidth },
        width: { default: this.options.defaults.width }
      },
      parseDOM: [
        {
          tag: `div[${COLUMN_DATA_ATTRIBUTE}]`,
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return false;
            }
            const {
              columnCount,
              columnFill,
              columnGap,
              columnRuleColor,
              columnRuleStyle,
              columnRuleWidth,
              columnWidth
            } = node.style;
            return {
              ...extra.parse(node),
              count: columnCount,
              fill: columnFill,
              gap: columnGap,
              ruleColor: columnRuleColor,
              ruleStyle: columnRuleStyle,
              ruleWidth: columnRuleWidth,
              width: columnWidth
            };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => {
        const { count, fill, gap, ruleColor, ruleStyle, ruleWidth, width, ...other } = omitExtraAttributes(node.attrs, extra);
        const { style: currentStyle = "", ...rest } = extra.dom(node);
        const style = joinStyles(
          {
            columnCount: count,
            columnFill: fill,
            columnGap: gap,
            columnRuleColor: ruleColor,
            columnRuleStyle: ruleStyle,
            columnRuleWidth: ruleWidth,
            columnWidth: width
          },
          currentStyle
        );
        const attributes = { ...rest, ...other, style, [COLUMN_DATA_ATTRIBUTE]: "true" };
        return ["div", attributes, 0];
      }
    };
  }
  /**
   * Add a column span attribute to all block nodes within the editor.
   */
  createSchemaAttributes() {
    const columnSpan = {
      default: null,
      parseDOM: (node) => node.getAttribute("column-span") ?? "none",
      toDOM: (attrs) => {
        return attrs.columnSpan ? ["column-span", attrs.columnSpan === "all" ? "all" : "none"] : null;
      }
    };
    return [
      {
        identifiers: {
          tags: [ExtensionTag.Block],
          type: "node",
          excludeNames: ["columns"]
        },
        attributes: { columnSpan }
      }
    ];
  }
  toggleColumns(attrs = {}, options = {}) {
    return this.store.commands.toggleWrappingNode.original(this.type, attrs, options.selection);
  }
};
__decorateClass([
  command(toggleColumnsOptions)
], ColumnsExtension.prototype, "toggleColumns", 1);
ColumnsExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaults: DEFAULT_COLUMN_ATTRIBUTES
    },
    staticKeys: ["defaults"]
  })
], ColumnsExtension);
export {
  ColumnsExtension,
  DEFAULT_COLUMN_ATTRIBUTES,
  toggleColumnsOptions
};
