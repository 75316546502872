import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { Select_DEPRECATED } from '@cotiss/common'
import { MetafieldValueModel } from '@cotiss/metafield-value'
import { MetafieldArrayStringModel } from '@cotiss/metafield'

type Props = {
  className?: string
  metafield: MetafieldArrayStringModel
  metafieldValue?: MetafieldValueModel
  onChange: (value: any) => void
}

export const MetafieldFormArrayString = memo(({ className, metafield, metafieldValue, onChange }: Props) => {
  const [formData, setFormData] = useState(metafieldValue?.fieldValue?.value || '')
  const options = useMemo(() => map(metafield.fieldValues, (option) => ({ label: option, value: option })), [metafield])

  const handleChange = (value: string) => {
    setFormData(value)
    onChange({ value })
  }

  return (
    <Select_DEPRECATED
      className={className}
      options={options}
      value={formData}
      onChange={(value) => handleChange(value)}
      placeholder={metafield.validation?.placeholder || metafield.fieldLabel}
      isRequired={metafield.isRequired}
    />
  )
})
