import { GqlCreateEvaluationScoreInput, GqlEvaluationScoreListInput, GqlUpdateEvaluationScoreInput } from '@gql'
import { mutateCreateEvaluationScore, mutateUpdateEvaluationScore, queryEvaluationScoreList } from '@cotiss/evaluation-event'

export const useEvaluationScore = () => {
  return {
    queryEvaluationScoreList: async (input: GqlEvaluationScoreListInput) => {
      const { items: evaluationScores, pagination } = await queryEvaluationScoreList(input)

      return { evaluationScores, pagination }
    },
    mutateCreateEvaluationScore: async (input: GqlCreateEvaluationScoreInput) => {
      return await mutateCreateEvaluationScore(input)
    },
    mutateUpdateEvaluationScore: async (input: GqlUpdateEvaluationScoreInput) => {
      return await mutateUpdateEvaluationScore(input)
    },
  }
}
