import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreatePerformanceScorecardDocument,
  mutateCreatePerformanceScorecardDocuments,
  mutateDeletePerformanceScorecardDocument,
  queryPerformanceScorecardDocumentList,
} from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardDocumentInput,
  GqlCreatePerformanceScorecardDocumentsInput,
  GqlDeletePerformanceScorecardDocumentInput,
  GqlPerformanceScorecardDocumentFieldsFragment,
  GqlPerformanceScorecardDocumentListInput,
} from '@gql'

const performanceScorecardDocumentVar = makeVar<GqlPerformanceScorecardDocumentFieldsFragment | null>(null)
const performanceScorecardDocumentsVar = makeVar<GqlPerformanceScorecardDocumentFieldsFragment[]>([])

export const usePerformanceScorecardDocument = () => {
  const performanceScorecardDocument = useReactiveVar(performanceScorecardDocumentVar)
  const performanceScorecardDocuments = useReactiveVar(performanceScorecardDocumentsVar)

  return {
    performanceScorecardDocument,
    performanceScorecardDocuments,
    setPerformanceScorecardDocument: performanceScorecardDocumentVar,
    setPerformanceScorecardDocuments: performanceScorecardDocumentsVar,
    queryPerformanceScorecardDocumentList: async (input: GqlPerformanceScorecardDocumentListInput) => {
      const { items: performanceScorecardDocuments, pagination } = await queryPerformanceScorecardDocumentList(input)

      performanceScorecardDocumentsVar(performanceScorecardDocuments)

      return { performanceScorecardDocuments, pagination }
    },
    mutateCreatePerformanceScorecardDocument: async (input: GqlCreatePerformanceScorecardDocumentInput) => {
      const createdPerformanceScorecardDocument = await mutateCreatePerformanceScorecardDocument(input)

      performanceScorecardDocumentsVar(uniqBy([createdPerformanceScorecardDocument, ...performanceScorecardDocuments], 'id'))

      return createdPerformanceScorecardDocument
    },
    mutateCreatePerformanceScorecardDocuments: async (input: GqlCreatePerformanceScorecardDocumentsInput) => {
      const createdPerformanceScorecardDocuments = await mutateCreatePerformanceScorecardDocuments(input)

      performanceScorecardDocumentsVar(uniqBy([...createdPerformanceScorecardDocuments, ...performanceScorecardDocuments], 'id'))

      return createdPerformanceScorecardDocuments
    },
    mutateDeletePerformanceScorecardDocument: async (input: GqlDeletePerformanceScorecardDocumentInput) => {
      await mutateDeletePerformanceScorecardDocument(input)

      performanceScorecardDocumentsVar(filter(performanceScorecardDocuments, ({ id }) => id !== input.performanceScorecardDocumentId))
    },
  }
}

export const clearReactivePerformanceScorecardDocument = async () => {
  performanceScorecardDocumentVar(null)
  performanceScorecardDocumentsVar([])
}
