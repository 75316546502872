import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState, BaseVariant } from '@cotiss/common'
import { ContractApproverStatus } from '@cotiss/contract'

const getVariant = (status: ContractApproverStatus): BaseVariant => {
  switch (status) {
    case 'NOT_SUBMITTED':
      return 'neutral'
    case 'PENDING_APPROVAL':
      return 'info'
    case 'APPROVED':
      return 'success'
    case 'REQUESTED_CHANGES':
      return 'danger'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ContractApproverStatus
  size?: BadgeSize
  state?: BadgeState
}

export const ContractApproverStatusBadge = memo(({ className, status, size = 'sm', state = 'translucent' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state={state}>
    {upperFirst(lowerCase(status))}
  </Badge>
))
