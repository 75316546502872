import { JwtDataModel } from '@cotiss/auth'
import { Authenticator, AuthenticatorOptions } from '@otplib/core'
import { keyDecoder, keyEncoder } from '@otplib/plugin-base32-enc-dec'
import { createDigest, createRandomBytes } from '@otplib/plugin-crypto-js'

class AuthService {
  getOtpAuthenticator = () => {
    return new Authenticator<AuthenticatorOptions>({
      createDigest,
      createRandomBytes,
      keyDecoder,
      keyEncoder,
    })
  }

  isTokenValid = (token: string) => {
    const decodedToken = this.decodeToken(token)

    return decodedToken ? decodedToken.exp > Math.floor(Date.now() / 1000) : false
  }

  decodeToken = (token = '') => {
    const tokenParts = token.split('.')
    const base64Payload = tokenParts[1]

    if (!base64Payload) {
      return
    }

    const payloadBuffer = Buffer.from(base64Payload, 'base64')
    return JSON.parse(payloadBuffer.toString()) as JwtDataModel
  }

  isPasswordValid = (password: string) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/
    return passwordRegex.test(password)
  }
}

export const authService = new AuthService()
