import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardUserInSessionViewInput, GqlPerformanceScorecardUserInSessionViewQuery } from '@gql'

export const queryPerformanceScorecardUserInSessionView = async (input: GqlPerformanceScorecardUserInSessionViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserInSessionViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserInSessionView($input: PerformanceScorecardUserInSessionViewInput!) {
        performanceScorecardUserInSessionView(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserInSessionView
}
