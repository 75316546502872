import classNames from 'classnames'
import React, { memo } from 'react'

type Props = {
  className?: string
  variant: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'neutral'
  size?: 'sm' | 'md'
}

export const CotissSquares = memo(({ className, variant, size = 'md' }: Props) => {
  const classes = classNames(className, 'relative', {
    'w-5 h-5': size === 'sm',
    'w-7 h-7': size === 'md',
  })
  const backSquareClasses = classNames('absolute rounded opacity-80', {
    'bg-blue-400': variant === 'info',
    'bg-secondary-400': variant === 'secondary',
    'top-0 left-1 w-4 h-4': size === 'sm',
    'top-0 left-1.5 w-6 h-6': size === 'md',
  })
  const frontSquareClasses = classNames('absolute rounded opacity-80', {
    'bg-blue-700': variant === 'info',
    'bg-secondary-600': variant === 'secondary',
    'top-1 left-0 w-4 h-4': size === 'sm',
    'top-1.5 left-0 w-6 h-6': size === 'md',
  })

  return (
    <div className={classes}>
      <div className={backSquareClasses} />
      <div className={frontSquareClasses} />
    </div>
  )
})
