import { PaginatedModel, useFetch } from '@cotiss/common'
import { ContractingEntityModel } from '@cotiss/contract'

export const useListContractingEntities = () => {
  const { data, ...rest } = useFetch<PaginatedModel<ContractingEntityModel>>('/v2/contract-shells', {
    route: `/v2/contract-shells/contracting-entities`,
    params: { includeOwnOrganisation: true },
  })

  return { contractingEntities: data?.items, ...rest }
}
