import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Form,
  Skeleton,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  routerService,
  sentryService,
  useAnalytics,
  useFeature,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'
import { filter, find, findIndex, includes, some } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import {
  useGetContractShell,
  useMutateContractShell,
  ContractSummaryPriceDurationTab,
  ContractSummaryContractTab,
  ContractApproversTable,
  ContractStepCardSkeletonLoading,
  ContractSummaryExercisedTab,
  ContractSummaryCessationDetails,
  ContractSummaryTermsAndMetadataTab,
  ContractSummaryScheduleOfRates,
  ContractSummarySignaturesTab,
  ContractSummaryMilestoneTab,
  ContractSummarySupportingDocumentsTab,
  ContractAssociations,
  contractService,
  ContractConfirmSubmitModal,
} from '@cotiss/contract'
import { useHistory } from 'react-router-dom'

type Props = {
  onNext: () => void
  onBack: () => void
  contractShellId: string
}

type ContractSummaryTab =
  | 'price-duration'
  | 'contract'
  | 'approvals'
  | 'cessation'
  | 'exercising'
  | 'terms-metadata'
  | 'schedule-of-rates'
  | 'signatures'
  | 'milestones'
  | 'supporting-docs'
  | 'associations'

export const ContractWizardSummaryStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { actionContractApproval } = useMutateContractShell()
  const { openToast } = useToast()
  const { openModal, closeFullModal } = useCallout()
  const { step, transition, onTransition } = useTransition()
  const [isSaving, setIsSaving] = useState(false)
  const { push } = useHistory()
  const { track } = useAnalytics()

  const { contract, canSubmitContract, approval } = useMemo(() => {
    const contract = find(contractShell?.contracts, { status: 'DRAFTING' })
    const approval = contractService.getApproval(contract?.approvals, ['DRAFTING'])
    return {
      contract,
      canSubmitContract: Boolean(approval?.approvers.length),
      approval,
    }
  }, [contractShell])

  const isEsignEnabled = useFeature('contract-management-e-sign')

  useEffect(() => {
    track('contract_wizard_summary_view')
  }, [])

  const tabs = useMemo(() => {
    const tabs: TabModel<ContractSummaryTab & { isHidden?: boolean }>[] = [
      {
        id: 'terms-metadata',
        label: 'Terms and metadata',
        isHidden: Boolean(contract?.variationTypes.length) && !includes(contract?.variationTypes, 'metadata_terms'),
      },
      { id: 'supporting-docs', label: 'Supporting documents', isHidden: !find(contract?.documentShells, { type: 'SUPPORTING' }) },
      {
        id: 'associations',
        label: 'Associations',
        isHidden:
          Boolean(contract?.variationTypes.length) ||
          !some([
            contractShell?.childrenContractShells.length,
            contractShell?.parentContractShells.length,
            contractShell?.siblingsContractShells.length,
          ]),
      },
      {
        id: 'exercising',
        label: 'Exercising period',
        isHidden: !includes(contract?.variationTypes, 'contract_renewal'),
      },
      {
        id: 'schedule-of-rates',
        label: 'Schedule of rates',
        isHidden: Boolean(contract?.variationTypes.length) && !includes(contract?.variationTypes, 'scheduled_rates'),
      },
      { id: 'cessation', label: 'Cessation of contract', isHidden: !includes(contract?.variationTypes, 'cessation') },
      { id: 'price-duration', label: 'Price and duration', isHidden: contractShell?.type === 'MILESTONE' },
      { id: 'milestones', label: 'Milestones', isHidden: contractShell?.type !== 'MILESTONE' },
      { id: 'contract', label: 'Contract', isHidden: !find(contract?.documentShells, { type: 'CONTRACT' }) },
      {
        id: 'signatures',
        label: 'Signatures',
        isHidden: !isEsignEnabled || !find(contract?.documentShells, (ds) => ds.signatureType === 'ESIGN_DOCUSIGN'),
      },
      { id: 'approvals', label: 'Approvals' },
    ]
    return filter(tabs, (tab) => !tab.isHidden)
  }, [contract?.cessationDate])

  const activeTabId = useMemo(() => tabs[step - 1].id, [step, tabs])

  const handleClickSubmit = async () => {
    if (!contract || !approval) {
      return
    }

    track('contract_wizard_summary_submit')

    try {
      setIsSaving(true)
      await actionContractApproval({ contractShellId, contractId: contract?._id, approvalId: approval?._id, action: 'submit' })
      setIsSaving(false)
      closeFullModal()
      onNext()
      // If this is an original contract, take user back to list page
      if (!contract.variationTypes.length) {
        push(routerService.getHref('/contract/list'))
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleTabChange = (tab: ContractSummaryTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="p-6">
          <Skeleton className="bg-primary-200 h-10 w-4/5 mr-20" />
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell || !contract) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  return (
    <Form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        openModal(
          <ContractConfirmSubmitModal
            contractShellId={contractShellId}
            contractId={contract._id}
            approvalId={approval?._id}
            onSubmit={handleClickSubmit}
          />
        )
      }}
    >
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              Contract title
            </Text>
            <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
              Summary
            </Text>
          </div>
          <div className="flex flex-col items-end ml-4">
            <div>
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isSaving}>
                Back
              </Button>
              <Button type="submit" variant="secondary" size="sm" isDisabled={isSaving || !canSubmitContract}>
                Confirm and send
              </Button>
            </div>
            {!canSubmitContract && (
              <Text className="mt-1" size="sm" variant="danger">
                To submit this contract, you must add at least one approver
              </Text>
            )}
          </div>
        </CardHeader>
        <div className="mt-6">
          <Tabs<ContractSummaryTab>
            className="border-b border-gray-300 w-full mb-4"
            tab={activeTabId}
            tabs={tabs}
            onChange={({ id }) => handleTabChange(id)}
            variant="underline"
          />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {activeTabId === 'terms-metadata' && (
                <ContractSummaryTermsAndMetadataTab contractShellId={contractShellId} contractId={contract?._id} />
              )}
              {activeTabId === 'supporting-docs' && (
                <ContractSummarySupportingDocumentsTab contractId={contract._id} contractShellId={contractShellId} />
              )}
              {activeTabId === 'associations' && <ContractAssociations contractShellId={contractShellId} />}
              {activeTabId === 'exercising' && <ContractSummaryExercisedTab contractShellId={contractShellId} contractId={contract?._id} />}
              {activeTabId === 'schedule-of-rates' && <ContractSummaryScheduleOfRates contractShellId={contractShellId} contractId={contract?._id} />}
              {activeTabId === 'cessation' && <ContractSummaryCessationDetails contractShellId={contractShellId} contractId={contract?._id} />}
              {activeTabId === 'price-duration' && (
                <ContractSummaryPriceDurationTab
                  contractShellId={contractShellId}
                  contractId={contract?._id}
                  showComparison={includes(contract.variationTypes, 'price_duration')}
                />
              )}
              {activeTabId === 'milestones' && (
                <ContractSummaryMilestoneTab
                  contractShellId={contractShellId}
                  contractId={contract?._id}
                  showComparison={includes(contract.variationTypes, 'milestone')}
                />
              )}
              {activeTabId === 'contract' && <ContractSummaryContractTab contractShellId={contractShellId} contractId={contract?._id} />}
              {activeTabId === 'signatures' && <ContractSummarySignaturesTab contractShellId={contractShellId} contractId={contract?._id} />}
              {activeTabId === 'approvals' && (
                <ContractApproversTable contractShellId={contractShellId} contractId={contract?._id} approvalId={approval?._id} isCommentVisible />
              )}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </Card>
    </Form>
  )
})
