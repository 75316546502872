import { gql } from '@apollo/client'

export const EVALUATION_SUBMISSION_FRAGMENT = gql`
  fragment EvaluationSubmissionFields on EvaluationSubmission {
    id
    evaluationEventId
    organisationId
    organisation {
      ...OrganisationFields
    }
    name
    overallPercentageScore
    rank
    isSetupComplete
    createdAt
    updatedAt
    deletedAt
  }
`
