import { uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreatePerformanceScorecard,
  mutatePublishPerformanceScorecard,
  mutateUpdatePerformanceScorecard,
  queryPerformanceScorecardList,
  queryPerformanceScorecardView,
} from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardInput,
  GqlPerformanceScorecardFieldsFragment,
  GqlPerformanceScorecardListInput,
  GqlPerformanceScorecardViewInput,
  GqlPublishPerformanceScorecardInput,
  GqlUpdatePerformanceScorecardInput,
} from '@gql'

const performanceScorecardVar = makeVar<GqlPerformanceScorecardFieldsFragment | null>(null)
const performanceScorecardsVar = makeVar<GqlPerformanceScorecardFieldsFragment[]>([])

export const usePerformanceScorecard = () => {
  const performanceScorecard = useReactiveVar(performanceScorecardVar)
  const performanceScorecards = useReactiveVar(performanceScorecardsVar)

  return {
    performanceScorecard,
    performanceScorecards,
    setPerformanceScorecard: performanceScorecardVar,
    setPerformanceScorecards: performanceScorecardsVar,
    queryPerformanceScorecardList: async (input: GqlPerformanceScorecardListInput) => {
      const { items: performanceScorecards, pagination } = await queryPerformanceScorecardList(input)

      performanceScorecardsVar(performanceScorecards)

      return { performanceScorecards, pagination }
    },
    queryPerformanceScorecardView: async (input: GqlPerformanceScorecardViewInput) => {
      const performanceScorecard = await queryPerformanceScorecardView(input)

      performanceScorecardVar(performanceScorecard)
      performanceScorecardsVar(uniqBy([performanceScorecard, ...performanceScorecards], 'id'))

      return performanceScorecard
    },
    mutateCreatePerformanceScorecard: async (input: GqlCreatePerformanceScorecardInput) => {
      const createdPerformanceScorecard = await mutateCreatePerformanceScorecard(input)

      performanceScorecardsVar(uniqBy([createdPerformanceScorecard, ...performanceScorecards], 'id'))

      return createdPerformanceScorecard
    },
    mutateUpdatePerformanceScorecard: async (input: GqlUpdatePerformanceScorecardInput) => {
      const updatedPerformanceScorecard = await mutateUpdatePerformanceScorecard(input)

      performanceScorecardVar(updatedPerformanceScorecard)
      performanceScorecardsVar(uniqBy([updatedPerformanceScorecard, ...performanceScorecards], 'id'))

      return updatedPerformanceScorecard
    },
    mutatePublishPerformanceScorecard: async (input: GqlPublishPerformanceScorecardInput) => {
      const publishedPerformanceScorecard = await mutatePublishPerformanceScorecard(input)

      performanceScorecardsVar(uniqBy([publishedPerformanceScorecard, ...performanceScorecards], 'id'))

      return publishedPerformanceScorecard
    },
  }
}

export const clearReactivePerformanceScorecard = async () => {
  performanceScorecardVar(null)
  performanceScorecardsVar([])
}
