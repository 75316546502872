import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_USER_FRAGMENT = gql`
  fragment PerformanceScorecardUserFields on PerformanceScorecardUser {
    id
    performanceScorecardId
    userId
    roles
    user {
      ...UserFields
    }
    createdAt
    updatedAt
    deletedAt
  }
`
