import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationEventViewInput, GqlEvaluationEventViewQuery } from '@gql'

export const queryEvaluationEventView = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventViewQuery>({
    query: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      query EvaluationEventView($input: EvaluationEventViewInput!) {
        evaluationEventView(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEventView
}
