import { useFetch } from '@cotiss/common'
import { AccessControlModel } from '@cotiss/access-control'

type Props = {
  resourceId?: string
  userId?: string
}

export const useListAccessControl = ({ resourceId, userId }: Props = {}) => {
  const { data: accessControls = [], ...rest } = useFetch<AccessControlModel[]>('/v2/access-control', {
    route: `/v2/access-control/${resourceId}`,
    params: { userId },
    isEnabled: Boolean(resourceId),
  })

  return { accessControls, ...rest }
}
