import { useMutate } from '@cotiss/common'
import { ShortListStatus } from '@cotiss/short-list'

type UpdateShortListBody = {
  rank?: number
  dueDilligence?: string[]
  status?: ShortListStatus
}

export const useMutateShortList = () => {
  const { mutate } = useMutate()

  return {
    updateShortList: async (id: string, body: UpdateShortListBody) => {
      await mutate({
        route: `/v2/short-lists/${id}`,
        body,
        invalidate: ['/v2/short-lists', '/v2/reports/evaluations-by-tender-response', '/v2/tenders'],
      })
    },
  }
}
