import { format, isAfter, isBefore, isSameDay, parseISO } from 'date-fns'

export type DatetimeFormat = 'do MMM yyyy' | 'MMM yyyy' | 'd MMMM yyyy h:mm aaa'

type IsDateBetweenParam = {
  dateFrom: Date
  dateTo: Date
}

class DatetimeService {
  parse = (datetime: Date | string) => {
    return typeof datetime === 'string' ? parseISO(datetime) : datetime
  }

  format = (datetime: Date | string, formatStr: DatetimeFormat) => {
    return format(this.parse(datetime), formatStr)
  }

  getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  isDateBetween = (date: Date, { dateFrom, dateTo }: IsDateBetweenParam) => {
    return isSameDay(date, dateFrom) || isSameDay(date, dateFrom) || (isAfter(date, dateFrom) && isBefore(date, dateTo))
  }
}

export const datetimeService = new DatetimeService()
