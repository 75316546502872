import { useMutate } from '@cotiss/common'
import { ConflictOfInterestModel } from '@cotiss/conflict-of-interest'

type UpdateConflictOfInterestBody = Partial<
  Pick<ConflictOfInterestModel, 'dateSigned' | 'resolution' | 'resolutionReason' | 'conflictDeclared' | 'step'> & { conflictAttachments: string[] }
>

type CreateConflictOfInterestBody = { procurementId: string; userId?: string }

export const useMutateConflictOfInterest = () => {
  const { mutate } = useMutate()

  return {
    updateConflictOfInterest: async (id: string, body: UpdateConflictOfInterestBody) => {
      return await mutate({ route: `/v2/conflict-of-interest/${id}`, body, invalidate: ['/v2/conflict-of-interest'] })
    },
    resendConflictOfInterest: async (id: string) => {
      return await mutate({ route: `/v2/conflict-of-interest/${id}/resend` })
    },
    createConflictOfInterest: async (body: CreateConflictOfInterestBody) => {
      return await mutate<ConflictOfInterestModel>({ route: `/v2/conflict-of-interest`, body, invalidate: ['/v2/conflict-of-interest'] })
    },
  }
}
