import { Target, Transition } from 'framer-motion'
import { TRANSITION_DURATION, TransitionType } from '@cotiss/common'

class TransitionService {
  getTransitionConfig = (transitionType?: TransitionType, offset = 0) => {
    const initial: Target = { opacity: 0, scaleX: 1, scaleY: 1 }
    const animate: Target = { opacity: 1, x: 0, y: 0 }
    const exit: Target = { opacity: 0, scaleX: 1, scaleY: 1 }
    const transition: Transition = { duration: transitionType ? TRANSITION_DURATION.sm : 0 }

    if (transitionType) {
      if (transitionType === 'left') {
        initial.x = offset - 30
        exit.x = offset + 30
      } else if (transitionType === 'right') {
        initial.x = offset + 30
        exit.x = offset - 30
      } else if (transitionType === 'top') {
        initial.y = offset - 10
        exit.y = offset + 10
      } else if (transitionType === 'bottom') {
        initial.y = offset + 10
        exit.y = offset - 10
      }
    }

    return { initial, exit, animate, transition }
  }
}

export const transitionService = new TransitionService()
