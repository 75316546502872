import { gql } from '@apollo/client'

export const PLAN_USER_FRAGMENT = gql`
  fragment PlanUserFields on PlanUser {
    id
    planEventId
    userId
    user {
      ...UserFields
    }
    role
    createdAt
    updatedAt
    deletedAt
  }
`
