import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { TenderInvitationDrawer } from '@cotiss/tender-invitation'
import { PreferredSupplierAddDrawer } from '@cotiss/preferred-supplier'
import { TenderResponseInvitationLinkModal } from '@cotiss/tender-response'
import { Button, Form, Icon, IconType, ModalContent, ModalFooter, ModalHeader, Radio, RadioCard, Text, useCallout } from '@cotiss/common'

type InviteMethod = 'new' | 'existing' | 'link'

type InviteRadioCardOptions = {
  title: string
  value: InviteMethod
  description: string
  icon: IconType
}

const INVITE_RADIO_CARD_OPTIONS: InviteRadioCardOptions[] = [
  {
    title: 'Invite from contact list',
    value: 'existing',
    description: 'Select users from your contacts list. These are users that have already been invited to the Cotiss platform.',
    icon: 'list',
  },
  {
    title: 'Invite new contact',
    value: 'new',
    description: 'Invite a new contact to this tender and to the Cotiss platform. You must create this contact yourself.',
    icon: 'user-plus-01',
  },
  {
    title: 'Copy invitation link',
    value: 'link',
    description: 'Get a shareable link for Cotiss users. Users will need to create or log in to a Cotiss account to respond.',
    icon: 'link-03',
  },
]

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderResponseInviteModal = memo(({ tenderId, isEditable }: Props) => {
  const { openDrawer } = useCallout()
  const { closeModal, openModal } = useCallout()
  const [inviteMethod, setInviteMethod] = useState<InviteMethod>('existing')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    switch (inviteMethod) {
      case 'new':
        openDrawer(<PreferredSupplierAddDrawer tenderId={tenderId} isEditable isTenderInvitation />)
        closeModal()
        break
      case 'existing':
        openDrawer(<TenderInvitationDrawer tenderId={tenderId} isEditable={isEditable} />)
        closeModal()
        break
      case 'link':
        openModal(<TenderResponseInvitationLinkModal tenderId={tenderId} isEditable={isEditable} />)
        break

      default:
        break
    }
  }

  return (
    <Form className="relative min-w-[750px] max-w-[750px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Select where you would like to invite users from?" supplementary="You can only select one invite method at a time" />
      <ModalContent>
        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          {map(INVITE_RADIO_CARD_OPTIONS, ({ title, value, description, icon }) => (
            <RadioCard
              key={value}
              className="border border-gray-100 p-3.5"
              name="evaluation-event-envelopes"
              isSelected={inviteMethod === value}
              onChange={() => setInviteMethod(value)}
            >
              <div className="flex justify-between mb-3">
                <div className="flex items-center justify-center rounded bg-secondary-100 h-7 w-7">
                  <Icon icon={icon} variant="secondary" />
                </div>
                <Radio className="ml-2" isChecked={inviteMethod === value} />
              </div>
              <Text className="font-semibold" font="jakarta">
                {title}
              </Text>
              <Text className="mt-1" size="sm" variant="light">
                {description}
              </Text>
            </RadioCard>
          ))}
        </div>
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button type="submit" variant="secondary">
          Continue <Icon icon="arrow-right" className="ml-2" />
        </Button>
      </ModalFooter>
    </Form>
  )
})
