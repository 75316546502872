import { MultiSelectOption, MultiSelectGroupedOption } from '@cotiss/common'

export const COUNTRY_REGION_OPTIONS: MultiSelectOption[] = [
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Cook Islands', value: 'Cook Islands' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Vanuatu', value: 'Vanuatu' },
]

export const AUSTRALIAN_REGION_OPTIONS: MultiSelectOption[] = [
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Northern Territory', value: 'NT' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
]

export const NEW_ZEALAND_REGION_OPTIONS: MultiSelectOption[] = [
  { label: 'Auckland Region', value: 'Auckland' },
  { label: 'Bay of Plenty Region', value: 'Bay of Plenty' },
  { label: 'Canterbury Region', value: 'Canterbury' },
  { label: 'Chatham Islands', value: 'Chatham Islands' },
  { label: 'Gisborne District', value: 'Gisborne' },
  { label: "Hawke's Bay Region", value: "Hawke's Bay" },
  { label: 'Manawatu-Wanganui Region', value: 'Manawatu-Wanganui' },
  { label: 'Marlborough Region', value: 'Marlborough' },
  { label: 'Nelson Region', value: 'Nelson' },
  { label: 'Northland Region', value: 'Northland' },
  { label: 'Otago Region', value: 'Otago' },
  { label: 'Waikato Region', value: 'Waikato' },
  { label: 'Wellington Region', value: 'Wellington' },
  { label: 'West Coast Region', value: 'West Coast' },
  { label: 'Southland Region', value: 'Southland' },
  { label: 'Taranaki Region', value: 'Taranaki' },
  { label: 'Tasman District', value: 'Tasman' },
]

export const REGION_OPTIONS: MultiSelectOption[] = [...COUNTRY_REGION_OPTIONS, ...AUSTRALIAN_REGION_OPTIONS, ...NEW_ZEALAND_REGION_OPTIONS]

export const GROUPED_REGION_OPTIONS: MultiSelectGroupedOption[] = [
  {
    label: 'Countries',
    options: COUNTRY_REGION_OPTIONS,
  },
  {
    label: 'Australia',
    options: AUSTRALIAN_REGION_OPTIONS,
  },
  {
    label: 'New Zealand',
    options: NEW_ZEALAND_REGION_OPTIONS,
  },
]
