import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PLAN_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/plan-event'
import { GqlPlanEventDocumentListInput, GqlPlanEventDocumentListQuery } from '@gql'

export const queryPlanEventDocumentList = async (input: GqlPlanEventDocumentListInput) => {
  const { data } = await apolloService.query<GqlPlanEventDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PLAN_EVENT_DOCUMENT_FRAGMENT}

      query PlanEventDocumentList($input: PlanEventDocumentListInput!) {
        planEventDocumentList(input: $input) {
          items {
            ...PlanEventDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.planEventDocumentList
}
