import { upperFirst } from 'lodash'
import React, { memo } from 'react'
import { ShortListStatus } from '@cotiss/short-list'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'

const getVariant = (status: ShortListStatus): BaseVariant => {
  switch (status) {
    case 'pending':
      return 'warning'
    case 'excluded':
      return 'info'
    case 'passed':
      return 'success'
    case 'failed':
      return 'danger'

    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: ShortListStatus
  size?: BadgeSize
}

export const ShortListStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(status)}
  </Badge>
))
