import classNames from 'classnames'
import React, { forwardRef, ReactNode } from 'react'
import { Button, Icon, useCallout } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
  isNarrow?: boolean
}

export const DrawerHeader = forwardRef<HTMLDivElement, Props>(({ className, children, isNarrow }, ref) => {
  const { closeDrawer, closeNarrowDrawer } = useCallout()
  const classes = classNames(className, 'fixed top-0 flex items-center justify-between bg-white shadow-sm w-full py-4 px-6 z-20')

  return (
    <div className={classes} style={{ maxWidth: isNarrow ? 460 : 900 }} ref={ref}>
      {children}
      <Button
        className="ml-2"
        onClick={() => (isNarrow ? closeNarrowDrawer() : closeDrawer())}
        state="translucent"
        variant="secondary"
        shape="square"
        size="sm"
        isRounded
      >
        <Icon icon="cross" size={20} />
      </Button>
    </div>
  )
})
