import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PLAN_USER_FRAGMENT } from '@cotiss/plan-event'
import { GqlPlanUserViewInput, GqlPlanUserViewQuery } from '@gql'

export const queryPlanUserView = async (input: GqlPlanUserViewInput) => {
  const { data } = await apolloService.query<GqlPlanUserViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PLAN_USER_FRAGMENT}

      query PlanUserView($input: PlanUserViewInput!) {
        planUserView(input: $input) {
          ...PlanUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.planUserView
}
