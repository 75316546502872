import { useMutate } from '@cotiss/common'
import { GroupEvaluationStatus } from '@cotiss/group-evaluation'

type UpdateGroupEvaluationBulkUpdate = {
  tenderQuestionId: string
  tenderResponseId: string
  status?: GroupEvaluationStatus
}

type UpdateGroupEvaluationBulkBody = {
  tenderId: string
  updates: UpdateGroupEvaluationBulkUpdate[]
}

export const useMutateGroupEvaluation = () => {
  const { mutate } = useMutate()

  return {
    updateGroupEvaluationBulk: async (body: UpdateGroupEvaluationBulkBody) => {
      return await mutate({
        route: '/v2/group-evaluations/bulk-update',
        body,
        invalidate: ['/v2/tenders', '/v2/group-evaluations', '/v2/reports/evaluations-by-tender-response'],
      })
    },
  }
}
