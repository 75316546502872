import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@cotiss/common'
import { ContractListPage, ContractViewPage, ContractApprovalPage, ContractApprovalViewPage } from '@cotiss/contract'

export const ContractLayout = memo(() => (
  <Switch>
    <Route path="/contract/list" exact>
      <ContractListPage />
    </Route>
    <Route path="/contract/list/:tab?" exact>
      <ContractListPage />
    </Route>
    <Route path="/contract/view/:contractShellId/:tab?" exact>
      <ContractViewPage />
    </Route>
    <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?" exact>
      <ContractApprovalPage />
    </Route>
    <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?" exact>
      <ContractApprovalViewPage />
    </Route>
    <Redirect from="/contract" to="/contract/list" />
  </Switch>
))
