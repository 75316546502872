import { filter, map } from 'lodash'
import React, { memo, useState } from 'react'
import { useGetTenderResponse } from '@cotiss/tender-response'
import { Icon, Button, Dropdown, DropdownContent, useCallout, ConfirmModal, Text } from '@cotiss/common'
import {
  PriceItemResponseAddUpdateModal,
  PriceItemResponsePopulatedModel,
  priceItemResponseService,
  useListPriceItemResponse,
  useMutatePriceItemResponse,
} from '@cotiss/price-item-response'
import { useGetTender } from '@cotiss/tender/resources'

type Props = {
  tenderResponseId: string
  priceItemResponse: PriceItemResponsePopulatedModel
}

export const TenderResponsePriceTableItemCta = memo(({ tenderResponseId, priceItemResponse }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const { tender } = useGetTender(tenderResponse?.tender._id)
  const { upsertPriceItemResponseBulk } = useMutatePriceItemResponse()
  const { priceItemResponses } = useListPriceItemResponse({ tenderResponseId })

  if (!tender) {
    return null
  }

  return (
    <div className="flex items-center">
      {(!priceItemResponse.priceItem || priceItemResponseService.isComplete({ priceItemResponse, tender })) && (
        <>
          <Button
            className="mr-2"
            onClick={() => setIsDropdownOpen(true)}
            shape="square"
            state="translucent"
            variant="secondary-dark"
            size="xs"
            isRounded
          >
            <Icon icon="dots" variant="secondary" />
          </Button>
          <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
            {
              // ! If there is a price item attached to the price item response, then we know it was created as a result of a price item being added to the
              // ! tender by the buyer. Then the supplier should not be able to remove this item.
            }
            <DropdownContent
              onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} priceItemResponse={priceItemResponse} />)}
            >
              <Text className="text-left">Edit item</Text>
            </DropdownContent>
            {!priceItemResponse.priceItem && (
              <DropdownContent
                isDisabled={Boolean(priceItemResponse?.priceItem)}
                onClick={() =>
                  openModal(
                    <ConfirmModal
                      heading="Remove price item"
                      description="Are you sure you want to remove this price item?"
                      onSubmit={async () => {
                        if (!tenderResponse) {
                          return
                        }

                        const priceItemResponsesToSave = filter(priceItemResponses, ({ _id }) => _id !== priceItemResponse._id)

                        await upsertPriceItemResponseBulk({
                          tenderResponseId,
                          tenderId: tenderResponse.tender._id,
                          items: map(priceItemResponsesToSave, ({ _id, category, description, unit, quantity, paymentDate, unitPrice }, index) => ({
                            priceItemResponseId: _id,
                            description,
                            category,
                            unit,
                            quantity,
                            index,
                            paymentDate,
                            unitPrice,
                          })),
                        })
                      }}
                    />
                  )
                }
              >
                <Text className="text-left">Remove item</Text>
              </DropdownContent>
            )}
          </Dropdown>
        </>
      )}
      {!priceItemResponseService.isComplete({ priceItemResponse, tender }) && (
        <Button
          state="outline"
          variant="secondary"
          size="xs"
          onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} priceItemResponse={priceItemResponse} />)}
        >
          Complete
        </Button>
      )}
    </div>
  )
})
