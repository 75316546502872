import { UserPermission } from '@cotiss/user'

export type AuthType = 'public' | 'masquerade'

export type JwtDataModel = {
  _id: string
  sub?: string
  scope: string[]
  roles: string[]
  accountId: string
  organisationId: string
  authType?: AuthType
  iat: number
  exp: number
}

export type SsoConfigurationModel = {
  id: string
  userId: string
  organisationId: string
  providerName: 'okta'
  defaultUserPermission: UserPermission
  entityId: string
  url: string
  metadataUrl: string
  assertionConsumerServiceUrl: string
  x509Certificate: string
  enabled: false
  createdAt: string
  updatedAt: string
}

export type SsoProviderName = 'okta' | 'microsoft' | 'forgerock'

export const PROVIDER_NAME_MAPPING: Record<SsoProviderName, string> = {
  microsoft: 'Microsoft',
  okta: 'Okta',
  forgerock: 'ForgeRock',
}

export type SsoEnabledModel = {
  enabled: boolean
  redirectUrl?: string
}
