import { filter } from 'lodash'
import React, { memo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApprovalFeedbackModal, useListApproval } from '@cotiss/approval'
import { TenderRequest, TenderRequestTab, useGetTender, useMutateTender } from '@cotiss/tender'
import { TenderFlowSendForApprovalConfirmModal, TenderFlowStep } from '@cotiss/tender-flow'
import { Button, RemainingTasksButton, Banner, Text, routerService, sentryService, useCallout, useToast, ConfirmModal } from '@cotiss/common'

export const TenderFlowRequestStep = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { replace } = useHistory()
  const { tenderId, step } = useParams<{ tenderId: string; step: TenderFlowStep }>()
  const { tender } = useGetTender(tenderId)
  const { regressTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { approvals } = useListApproval({ tenderId })
  const rejectedApprovals = filter(approvals, { approvalType: 'go-to-market', status: 'rejected' })
  const isEditable = tender?.status === 'planning'

  const handleTabChange = (tab: TenderRequestTab) => {
    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, tab, step }))
  }

  const handleEditProcurement = async () => {
    try {
      setIsSaving(true)
      await regressTender(tenderId)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleRevokeContract = async () => {
    openModal(
      <ConfirmModal
        heading="Are you sure you would like to revoke this request?"
        description={
          <>
            <Text className="mb-6 text-left" size="sm" variant="light">
              This will allow you to edit the listing and revoke current approvals. Once updated, it can then be re-sent for approval.
            </Text>
          </>
        }
        onSubmit={async () => {
          await regressTender(tenderId)
        }}
      />
    )
  }

  return (
    <>
      {isEditable && (
        <Banner className="mb-6" icon="file-plus-02" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Create your request
            </Text>
            <Text size="sm">Once all tasks are completed you can progress to the next step</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            {Boolean(rejectedApprovals.length) && (
              <Button
                onClick={() => openModal(<ApprovalFeedbackModal rejectedApprovals={rejectedApprovals} />)}
                state="text"
                className="mr-2"
                variant="link"
                isDisabled={isSaving}
              >
                View feedback
              </Button>
            )}
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            <Button
              className="ml-2"
              onClick={() => openModal(<TenderFlowSendForApprovalConfirmModal tenderId={tender._id} />)}
              size="sm"
              isDisabled={Boolean(tender.validation.validationErrors.length)}
            >
              Get approval
            </Button>
          </div>
        </Banner>
      )}
      {tender?.status === 'planned-approval' && (
        <>
          {rejectedApprovals.length ? (
            <Banner className="mb-6" icon="clock" variant="danger">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Approval rejected
                </Text>
                <Text size="sm">Your approval has been rejected.</Text>
              </div>
              <div className="flex items-center justify-center shrink-0">
                <Button
                  onClick={() => openModal(<ApprovalFeedbackModal rejectedApprovals={rejectedApprovals} />)}
                  state="text"
                  variant="link"
                  isDisabled={isSaving}
                >
                  View feedback
                </Button>
                <Button className="ml-4" onClick={handleEditProcurement} size="sm" isLoading={isSaving}>
                  Edit request
                </Button>
              </div>
            </Banner>
          ) : (
            <Banner className="mb-6" icon="clock" variant="primary">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Waiting for approval
                </Text>
                <Text size="sm">Once approved you will be able to publish the request to the live market.</Text>
              </div>
              <Button type="submit" variant="secondary" state="text" onClick={handleRevokeContract}>
                Revoke request
              </Button>
            </Banner>
          )}
        </>
      )}

      <TenderRequest tenderId={tenderId} onTabChange={handleTabChange} isEditable={isEditable} />
    </>
  )
})
