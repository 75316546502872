import classNames from 'classnames'
import { filter, findIndex } from 'lodash'
import React, { useState, useEffect, MutableRefObject } from 'react'
import { TabModel, TabsVariant } from '@cotiss/common'

type Props<T> = {
  tabButtonRefs: MutableRefObject<HTMLButtonElement[]>
  variant: TabsVariant
  activeTab: TabModel<T>
  tabs: TabModel<T>[]
}

export function TabsActiveIndicator<T>({ tabButtonRefs, variant, activeTab, tabs }: Props<T>) {
  const [style, setStyle] = useState<{ left: string; width: string }>()
  const classes = classNames('absolute w-full', {
    hidden: activeTab.isHidden,
    'bottom-0 h-0.5': variant === 'underline',
    'top-0.5 bottom-0.5': variant === 'square' || variant === 'rounded',
  })
  const contentClasses = classNames('absolute h-full duration-300 ease-in-out ', {
    'bg-secondary-500': variant === 'underline',
    'bg-secondary-100 rounded-md': variant === 'square',
    'bg-secondary-100 rounded-full': variant === 'rounded',
  })

  useEffect(() => {
    if (activeTab.isHidden) {
      return
    }

    const visibleTabs = filter(tabs, ({ isHidden }) => !isHidden)
    const activeIndex = findIndex(visibleTabs, ({ id }) => id === activeTab.id)
    const activeTabButtonRef = tabButtonRefs.current[activeIndex]

    if (!activeTabButtonRef) {
      return
    }

    let left = 0

    for (let i = 0; i < activeIndex; i += 1) {
      const tabButtonWidth = tabButtonRefs.current[i]?.getBoundingClientRect().width || 0

      left += tabButtonWidth

      if (variant === 'underline' && visibleTabs.length > 1) {
        // If this is the underline variant, adding 20px to left to account for the margin left on the tab buttons.
        // Only do this though if we are displaying more than one tab
        left += 20
      } else if (variant === 'square' || variant === 'rounded') {
        // Adding 8px to account for the margin right on the tab buttons,
        left += 4
      }
    }

    let width = 0
    if (variant === 'underline') {
      // With the underline variant, we want the width of the border bottom to be the same as the text. So we use the bounding client rectangle of the
      // first child.
      width = activeTabButtonRef.children[0].getBoundingClientRect().width || 0
    } else if (variant === 'square' || variant === 'rounded') {
      width = activeTabButtonRef.getBoundingClientRect().width || 0
    }

    setStyle({ left: `${left}px`, width: `${width}px` })
  }, [tabButtonRefs, activeTab, tabs])

  if (!style) {
    return null
  }

  return (
    <div className={classes}>
      <div className={contentClasses} style={style} />
    </div>
  )
}
