import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlPerformanceScorecardMetricListInput, GqlPerformanceScorecardMetricListQuery } from '@gql'
import { PERFORMANCE_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'

export const queryPerformanceScorecardMetricList = async (input: GqlPerformanceScorecardMetricListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}

      query PerformanceScorecardMetricList($input: PerformanceScorecardMetricListInput!) {
        performanceScorecardMetricList(input: $input) {
          items {
            ...PerformanceScorecardMetricFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricList
}
