import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreateEvaluationCriteriaInput,
  GqlDeleteEvaluationCriteriaInput,
  GqlEvaluationCriteriaFieldsFragment,
  GqlEvaluationCriteriaListInput,
  GqlUpdateEvaluationCriteriaInput,
} from '@gql'
import {
  mutateCreateEvaluationCriteria,
  mutateDeleteEvaluationCriteria,
  mutateUpdateEvaluationCriteria,
  queryEvaluationCriteriaList,
} from '@cotiss/evaluation-event'

const evaluationCriteriaVar = makeVar<GqlEvaluationCriteriaFieldsFragment[]>([])

export const useEvaluationCriteria = () => {
  const evaluationCriteria = useReactiveVar(evaluationCriteriaVar)

  return {
    evaluationCriteria,
    setEvaluationCriteria: evaluationCriteriaVar,
    queryEvaluationCriteriaList: async (input: GqlEvaluationCriteriaListInput) => {
      const { items: evaluationCriteria, pagination } = await queryEvaluationCriteriaList(input)

      evaluationCriteriaVar(evaluationCriteria)

      return { evaluationCriteria, pagination }
    },
    mutateCreateEvaluationCriteria: async (input: GqlCreateEvaluationCriteriaInput) => {
      const createdEvaluationCriteria = await mutateCreateEvaluationCriteria(input)

      evaluationCriteriaVar(uniqBy([createdEvaluationCriteria, ...evaluationCriteria], 'id'))

      return createdEvaluationCriteria
    },
    mutateUpdateEvaluationCriteria: async (input: GqlUpdateEvaluationCriteriaInput) => {
      const updatedEvaluationCriteria = await mutateUpdateEvaluationCriteria(input)

      evaluationCriteriaVar(uniqBy([updatedEvaluationCriteria, ...evaluationCriteria], 'id'))

      return updatedEvaluationCriteria
    },
    mutateDeleteEvaluationCriteria: async (input: GqlDeleteEvaluationCriteriaInput) => {
      await mutateDeleteEvaluationCriteria(input)

      evaluationCriteriaVar(filter(evaluationCriteria, ({ id }) => id !== input.evaluationCriteriaId))
    },
  }
}

export const clearReactiveEvaluationCriteria = async () => {
  evaluationCriteriaVar([])
}
