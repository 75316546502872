import { Button, Icon, LazyImg } from '@cotiss/common'
import classNames from 'classnames'
import React, { memo } from 'react'

type Props = {
  className?: string
  organisation?: { logo?: string }
  onUpdate?: () => void
  size?: 'sm' | 'lg'
}

export const OrganisationLogo = memo(({ className, organisation, onUpdate, size = 'lg' }: Props) => {
  const classes = classNames(className, 'inline-block shrink-0', {
    relative: onUpdate,
  })

  const imgClasses = classNames('object-contain object-center border border-gray-300 rounded-lg', {
    'w-20 h-20': size === 'lg',
    'w-10 h-10': size === 'sm',
  })

  const src = organisation?.logo
    ? `https://tender-logos.s3-ap-southeast-2.amazonaws.com/logos/${organisation?.logo}`
    : 'https://tender-logos.s3-ap-southeast-2.amazonaws.com/logos/full/CotissLogoBigGreen.png'

  return (
    <div className={classes}>
      <LazyImg className={imgClasses} src={src} alt="Organisation logo" />
      {onUpdate && (
        <Button className="absolute -bottom-1 -right-1" onClick={onUpdate} variant="secondary" shape="square" size="xs" isRounded>
          <Icon icon="edit" size={14} />
        </Button>
      )}
    </div>
  )
})
