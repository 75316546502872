import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ContractReminderAddModal, ContractReminderList, ContractStatusBadge, contractService, useGetContractShell } from '@cotiss/contract'
import { Button, COLOUR, Card, CardHeader, NoDataPlaceholder, Skeleton, Spinner, Text, useCallout } from '@cotiss/common'

export const ContractViewRemindersTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5" font="jakarta">
              Reminders
            </Text>
          </div>
        </CardHeader>
        <div className="flex items-center h-36">
          <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        </div>
      </Card>
    )
  }

  if (!contract || !(contract.status === 'PUBLISHED' || contract.status === 'CEASED')) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div>
            {isLoading && <Skeleton className="h-2 w-1/3 mb-1" />}
            {!isLoading && (
              <Text className="mb-1" size="sm" variant="light">
                {contractShell?.title || ''}
              </Text>
            )}
            <Text className="font-semibold" size="h5" font="jakarta">
              Reminders
            </Text>
          </div>
        </div>
        <div>
          <ContractStatusBadge className="mr-4" status={contract.status} />
          <Button variant="secondary" size="sm" onClick={() => openModal(<ContractReminderAddModal contractShellId={contractShellId} />)}>
            + Add reminder
          </Button>
        </div>
      </CardHeader>
      <ContractReminderList contractShellId={contractShellId} isDisabled={contract.status === 'CEASED'} />
    </Card>
  )
})
