import classNames from 'classnames'
import React, { memo } from 'react'
import { Badge, Section, Text } from '@cotiss/common'
import FiveHundredComputerSvg from '@assets/svg/500-computer.svg'

type Props = {
  className?: string
}

export const ErrorPanel = memo(({ className }: Props) => (
  <Section className={classNames(className, 'text-center')} isCentered>
    <FiveHundredComputerSvg className="mx-auto" />
    <Badge state="translucent" variant="secondary" shape="square">
      500 Error
    </Badge>
    <Text className="font-semibold text-center mt-6" size="h2" font="jakarta" variant="heading">
      Oops, something went wrong
    </Text>
    <Text className="mt-2" variant="light">
      We apologise and are fixing the problem, please try again later
    </Text>
  </Section>
))
