import { AnimatePresence } from 'framer-motion'
import { filter, findIndex, includes } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { TenderFlowStep } from '@cotiss/tender-flow'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { useListTenderResponse } from '@cotiss/tender-response'
import { EvaluationList, EvaluationListPrice, EvaluationListTable, EvaluationModel, EvaluationView, useListEvaluation } from '@cotiss/evaluation'
import {
  Banner,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  RemainingTasksButton,
  Text,
  TransitionContainer,
  TransitionType,
  VerticalTabModel,
  VerticalTabs,
  routerService,
  useCallout,
  useTransition,
} from '@cotiss/common'

export type TenderFlowEvaluateStepSelfTab = 'non-price' | 'price'

export const TenderFlowEvaluateStepSelf = memo(() => {
  const { push, replace } = useHistory()
  const { progressTender } = useMutateTender()
  const { openModal, closeDrawer } = useCallout()
  const [activeEvaluationId, setActiveEvaluationId] = useState('')
  const [nestedTransition, setNestedTransition] = useState<TransitionType>('right')
  const { tenderId, step: tenderFlowStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab?: TenderFlowEvaluateStepSelfTab }>()
  const { tender } = useGetTender(tenderId)
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId })
  const { evaluations, isLoading: isEvaluationsLoading } = useListEvaluation({ tenderId })
  const { tabs, tabToSet, isEvaluateComplete } = useMemo(() => {
    const isEvaluateComplete = tender && includes(tender.validation.finishedStages || [], 'evaluate')
    const tabToSet = includes(['non-price', 'price'], tab) ? tab : !isEvaluateComplete ? 'non-price' : 'price'
    const evaluationsToComplete = filter(evaluations, ({ status }) => status !== 'completed')
    const tenderResponsesToComplete = filter(tenderResponses, ({ priceScoreConsensusStatus }) => priceScoreConsensusStatus !== 'completed')
    const tabs: VerticalTabModel<TenderFlowEvaluateStepSelfTab>[] = [
      { id: 'non-price', label: 'Non-price', tasksRemaining: evaluationsToComplete.length, isDisabled: isEvaluationsLoading },
      {
        id: 'price',
        label: 'Price',
        tasksRemaining: tenderResponsesToComplete.length,
        isLocked: tender?.isTwoEnvelope && !isEvaluateComplete,
        isDisabled: isTenderResponsesLoading,
      },
    ]

    return { tabs, tabToSet, isEvaluateComplete }
  }, [evaluations, tenderResponses, isEvaluationsLoading, isTenderResponsesLoading])
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tabToSet) + 1 })
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: step === 1 ? 'Non-price' : 'Price',
        onClick: () => {
          closeDrawer()
          handleViewEvaluation(null, 'left')
        },
      },
    ]
  }, [step])

  useEffect(() => {
    const tabToSet = includes(['non-price', 'price'], tab) ? tab : !isEvaluateComplete ? 'non-price' : 'price'

    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: tenderFlowStep, tab: tabToSet }))
  }, [])

  const handleTabChange = (tab: TenderFlowEvaluateStepSelfTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: tenderFlowStep, tab }))
    onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
  }

  const handleViewEvaluation = (evaluation: EvaluationModel | null, transition: TransitionType = 'right') => {
    setNestedTransition(transition)
    setTimeout(() => {
      setActiveEvaluationId(evaluation?._id || '')
    }, 0)
  }

  const handleCompleteTwoEnvelopeNonPrice = () => {
    openModal(
      <ConfirmModal
        heading="Complete non-price evaluation"
        description="This action cannot be undone."
        onSubmit={async () => {
          await progressTender(tenderId)
          handleTabChange('price')
        }}
      />
    )
  }

  const handleCompleteTwoEnvelopePrice = () => {
    openModal(
      <ConfirmModal
        heading="Complete price evaluation"
        description="This action cannot be undone."
        onSubmit={async () => {
          await progressTender(tenderId)
          push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'selection' }))
        }}
      />
    )
  }

  const handleCompleteOneEnvelopeEvaluation = () => {
    openModal(
      <ConfirmModal
        heading="Complete evaluation"
        description="This action cannot be undone."
        onSubmit={async () => {
          await progressTender(tenderId)
          push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'selection' }))
        }}
      />
    )
  }

  const renderInitial = () => (
    <>
      {(tender?.status === 'evaluate' || tender?.status === 'price') && (
        <Banner className="mb-6" icon="bar-chart-10" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Evaluate & score the supplier responses listed below
            </Text>
            <Text size="sm">
              First evaluate the responses to non-price criteria for each supplier. Once complete you can proceed to scoring price.
            </Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            {tender.isTwoEnvelope && (
              <>
                {tender.status === 'evaluate' && (
                  <Button
                    className="ml-2"
                    onClick={handleCompleteTwoEnvelopeNonPrice}
                    size="sm"
                    isDisabled={Boolean(tender.validation.validationErrors.length)}
                  >
                    Complete non-price
                  </Button>
                )}
                {tender.status === 'price' && (
                  <Button
                    className="ml-2"
                    onClick={handleCompleteTwoEnvelopePrice}
                    size="sm"
                    isDisabled={Boolean(tender.validation.validationErrors.length)}
                  >
                    Complete price
                  </Button>
                )}
              </>
            )}
            {!tender.isTwoEnvelope && tender.status === 'evaluate' && (
              <Button
                className="ml-2"
                onClick={handleCompleteOneEnvelopeEvaluation}
                size="sm"
                isDisabled={Boolean(tender.validation.validationErrors.length)}
              >
                Complete evaluation
              </Button>
            )}
          </div>
        </Banner>
      )}
      <div className="flex items-start justify-between">
        <div className="sticky top-28 mr-12">
          <Text className="uppercase mb-2" variant="light" size="xs">
            Evaluation path
          </Text>

          <VerticalTabs<TenderFlowEvaluateStepSelfTab> tab={tab} tabs={tabs} onChange={({ id }) => handleTabChange(id)} />
        </div>
        <div className="w-full min-w-0">
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && <EvaluationListTable tenderId={tenderId} onView={handleViewEvaluation} isSelfEvaluation />}
              {step === 2 &&
                (tender?.priceTableEnabled ? (
                  <EvaluationListPrice
                    tenderId={tenderId}
                    onView={handleViewEvaluation}
                    evaluationType="self"
                    isScoringEnabled={
                      tender.isPriceWeighted && (tender.isTwoEnvelope ? tender.status === 'price' : includes(['evaluate', 'price'], tender.status))
                    }
                  />
                ) : (
                  <EvaluationList tenderId={tenderId} onView={handleViewEvaluation} />
                ))}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </div>
    </>
  )

  return (
    <AnimatePresence mode="wait" initial={false}>
      {!activeEvaluationId && (
        <TransitionContainer key={1} transition={nestedTransition}>
          {renderInitial()}
        </TransitionContainer>
      )}
      {activeEvaluationId && (
        <TransitionContainer key={2} transition={nestedTransition}>
          <EvaluationView evaluationId={activeEvaluationId} breadcrumbs={breadcrumbs} variant={step === 1 ? 'non-price' : 'price'} isEditable />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
