import { useState } from 'react'

type Props<T> = {
  initialKey: T
  initialSortDirection?: 'asc' | 'desc'
}

export const useSortTable = <T extends string>({ initialKey, initialSortDirection = 'asc' }: Props<T>) => {
  const [sortKey, setSortKey] = useState<T>(initialKey)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(initialSortDirection)

  const onSort = (newSortKey: T) => {
    if (newSortKey === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(newSortKey)
      setSortDirection('asc')
    }
  }

  return { onSort, sortKey, sortDirection }
}
