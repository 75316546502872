import classNames from 'classnames'
import ReactSelect from 'react-select'
import React, { memo, useMemo } from 'react'
import { filter, find, forEach, map } from 'lodash'
import { UserLineItem, userService } from '@cotiss/user'
import { Button, Icon, MultiSelectOption } from '@cotiss/common'

type User = {
  _id: string
  firstname: string
  surname: string
  email: string
}

type Props = {
  className?: string
  value: string[]
  options: User[]
  onChange: (value: string[]) => void
  isRequired?: boolean
  isDisabled?: boolean
}

// TODO: Replace this with the MultiSelect component
// TODO: This logic is duplicated in the `UserSelect` component.

export const UserMultiSelect = memo(({ className, value, options, onChange, isRequired, isDisabled }: Props) => {
  const { userValue, userOptions } = useMemo(() => {
    const userValue: MultiSelectOption[] = []
    const userOptions = map(options, (user) => ({ value: user._id, label: userService.getFullName(user) }))

    forEach(value, (userId) => {
      const user = find(options, { _id: userId })

      if (user) {
        userValue.push({ value: user._id, label: userService.getFullName(user) })
      }
    })

    return { userValue, userOptions }
  }, [value, options])

  const handleChange = (userValue: MultiSelectOption[]) => {
    onChange(map(userValue, ({ value }) => value))
  }

  return (
    <div className={className}>
      <ReactSelect
        value={userValue}
        options={userOptions}
        onChange={(userValue) => handleChange(userValue as MultiSelectOption[])}
        controlShouldRenderValue={false}
        isClearable={false}
        isDisabled={isDisabled}
        backspaceRemovesValue={false}
        components={{ IndicatorSeparator: null }}
        required={isRequired}
        isMulti
      />
      {map(value, (id, index) => {
        const user = find(options, { _id: id })

        if (!user) {
          return null
        }

        return (
          <div key={id} className="flex items-center justify-between mt-8">
            <UserLineItem {...user} className={classNames({ 'mt-4': index })} />
            <Button onClick={() => onChange(filter(value, (idToCompare) => id !== idToCompare))} state="ghost" shape="square" size="sm">
              <Icon icon="cross" size={20} />
            </Button>
          </div>
        )
      })}
    </div>
  )
})
