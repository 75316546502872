import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import {
  GqlApprovalTemplateFieldsFragment,
  GqlUpdateApprovalTemplateOrdersInput,
  GqlUpdateApprovalTemplateOrdersMutation,
  GqlUpdateApprovalTemplateOrdersMutationVariables,
} from '@gql'

export const mutateUpdateApprovalTemplateOrders = async (input: GqlUpdateApprovalTemplateOrdersInput) => {
  const { data } = await apolloService.mutate<GqlUpdateApprovalTemplateOrdersMutation, GqlUpdateApprovalTemplateOrdersMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      mutation UpdateApprovalTemplateOrders($input: UpdateApprovalTemplateOrdersInput!) {
        updateApprovalTemplateOrders(input: $input) {
          ...ApprovalTemplateFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateApprovalTemplateOrders as GqlApprovalTemplateFieldsFragment[]
}
