import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeletePerformanceScorecardMetricCycleDocumentInput,
  GqlDeletePerformanceScorecardMetricCycleDocumentMutation,
  GqlDeletePerformanceScorecardMetricCycleDocumentMutationVariables,
} from '@gql'

export const mutateDeletePerformanceScorecardMetricCycleDocument = async (input: GqlDeletePerformanceScorecardMetricCycleDocumentInput) => {
  const { data } = await apolloService.mutate<
    GqlDeletePerformanceScorecardMetricCycleDocumentMutation,
    GqlDeletePerformanceScorecardMetricCycleDocumentMutationVariables
  >({
    mutation: gql`
      mutation DeletePerformanceScorecardMetricCycleDocument($input: DeletePerformanceScorecardMetricCycleDocumentInput!) {
        deletePerformanceScorecardMetricCycleDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePerformanceScorecardMetricCycleDocument as boolean
}
