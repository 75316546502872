import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { Hr, Text } from '@cotiss/common'
import { TenderResponseReportConsensusList, TenderResponseReportModel } from '@cotiss/tender-response'

type Props = {
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const EvaluationConsensusTab = memo(({ onView }: Props) => {
  const { tenderId } = useParams<{ tenderId: string }>()

  return (
    <>
      <Text className="font-semibold" size="h7" font="jakarta">
        Consensus scoring
      </Text>
      <Text className="mt-1" variant="light" size="sm">
        The evaluation panel collectively scores the overall score per question.
      </Text>
      <Hr className="my-4" />
      <TenderResponseReportConsensusList tenderId={tenderId} onView={onView} />
    </>
  )
})
