import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT } from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardDocumentsInput,
  GqlCreatePerformanceScorecardDocumentsMutation,
  GqlCreatePerformanceScorecardDocumentsMutationVariables,
  GqlPerformanceScorecardDocumentFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceScorecardDocuments = async (input: GqlCreatePerformanceScorecardDocumentsInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardDocumentsMutation,
    GqlCreatePerformanceScorecardDocumentsMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT}

      mutation CreatePerformanceScorecardDocuments($input: CreatePerformanceScorecardDocumentsInput!) {
        createPerformanceScorecardDocuments(input: $input) {
          ...PerformanceScorecardDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardDocuments as GqlPerformanceScorecardDocumentFieldsFragment[]
}
