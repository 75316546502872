import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlBeginEvaluationEventInput,
  GqlBeginEvaluationEventMutation,
  GqlBeginEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'

export const mutateBeginEvaluationEvent = async (input: GqlBeginEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlBeginEvaluationEventMutation, GqlBeginEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation BeginEvaluationEvent($input: BeginEvaluationEventInput!) {
        beginEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.beginEvaluationEvent as GqlEvaluationEventFieldsFragment
}
