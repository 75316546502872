import { useEffect } from 'react'

export const useAsyncEffect = (mountCallback: () => Promise<void>, dependencies: any[] = [], unmountCallback?: () => void) => {
  useEffect(() => {
    ;(async () => {
      await mountCallback()
    })()

    return () => {
      if (unmountCallback) {
        unmountCallback()
      }
    }
  }, dependencies)
}
