import React, { useState, FormEvent, memo } from 'react'
import { useMutateAuth } from '@cotiss/auth'
import { Form, Input, useToast, sentryService, Label, Button } from '@cotiss/common'

type FormData = {
  password: string
}

type Props = {
  onClose: () => void
}

export const SettingsTwoFactorUnregisterForm = memo(({ onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { unregisterTwoFactor } = useMutateAuth()
  const [formData, setFormData] = useState<FormData>({ password: '' })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await unregisterTwoFactor(formData)
      setIsSaving(false)
      onClose()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="space-y-2" onSubmit={handleSubmit}>
      <Label>Disable Two Factor Authentication</Label>
      <Input
        className="mb-4"
        value={formData?.password}
        onChange={({ target }) => setFormData({ ...formData, password: target.value })}
        type="password"
        placeholder="Enter password"
        isRequired
      />
      <div className="flex items-center justify-end">
        <Button className="mr-2" onClick={onClose} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
          Save
        </Button>
      </div>
    </Form>
  )
})
