// NOTE: This is part of a DEPRECATED and UNUSED flow.

import { filter, forEach, groupBy, map } from 'lodash'
import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { tenderService, useGetTender } from '@cotiss/tender'
import { useListTenderCriteria } from '@cotiss/tender-criteria'
import { Button, Card, CardFormHeader, Form, Hr, sentryService, useToast, Text, Banner } from '@cotiss/common'
import { TenderQuestionAccordion, TenderQuestionFormData, useListTenderQuestion, useMutateTenderQuestion } from '@cotiss/tender-question'

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestQuestionsTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateTenderQuestionBulk } = useMutateTenderQuestion()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { tenderCriteria, isLoading: isTenderCriteriaLoading } = useListTenderCriteria({ tenderId })
  const { tenderQuestions, isLoading: isTenderQuestionsLoading } = useListTenderQuestion({ tenderId })
  const isLoading = isTenderLoading || isTenderCriteriaLoading || isTenderQuestionsLoading
  const [formData, setFormData] = useState<Record<string, TenderQuestionFormData[]>>({})
  const processedCriteria = useMemo(() => {
    const nonPriceCriteriaWithQuestions = map(tenderCriteria, ({ _id, weight }) => ({
      weight,
      questions: map(formData[_id] || [], ({ weight }) => ({ weight })),
    }))

    return tenderService.processCriteria({
      nonPriceCriteriaWithQuestions,
      nonPriceWeight: tender?.envelope.weight,
      priceWeight: tender?.priceEnvelope.weight,
    })
  }, [tenderCriteria, tender, formData])

  useEffect(() => {
    if (tenderCriteria && tenderQuestions) {
      processAndSetFormData()
    }
  }, [tenderCriteria, tenderQuestions])

  const processAndSetFormData = () => {
    const formData: Record<string, TenderQuestionFormData[]> = {}
    const formDataMap = groupBy(
      map(tenderQuestions, ({ _id, criterion, question, weight }) => ({
        criterionId: criterion,
        questionId: _id,
        weight: weight?.toString() || '',
        question,
      })),
      'criterionId'
    )

    forEach(tenderCriteria, ({ _id }) => {
      formData[_id] = formDataMap[_id] || []
    })

    setFormData(formData)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await Promise.all(
        map(formData, (formDataItems, criterionId) => {
          return updateTenderQuestionBulk({
            tenderId,
            criterionId,
            updates: map(formDataItems, ({ weight, ...rest }, index) => ({ ...rest, weight: Number(weight), index })),
          })
        })
      )
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    processAndSetFormData()
    setIsEditing(isEditing)
  }

  const handleQuestionAdd = (criterionId: string) => {
    setIsEditing(true)
    setFormData({ ...formData, [criterionId]: [...formData[criterionId], { criterionId, question: '', weight: '' }] })
  }

  const handleQuestionUpdate = (indexToUpdate: number, formDataItemToUpdate: TenderQuestionFormData) => {
    const formDataItems = map(formData[formDataItemToUpdate.criterionId], (formDataItem, index) => {
      return index === indexToUpdate ? formDataItemToUpdate : formDataItem
    })

    setFormData({ ...formData, [formDataItemToUpdate.criterionId]: formDataItems })
  }

  const handleQuestionRemove = (indexToRemove: number, criterionId: string) => {
    setFormData({ ...formData, [criterionId]: filter(formData[criterionId], (_, index) => index !== indexToRemove) })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader
          heading="Sub-criteria"
          onToggleEdit={handleToggleEdit}
          isEditable={isEditable}
          isEditing={isEditing}
          isSaving={isSaving}
          isLoading={isLoading}
        />
        <Banner className="my-6" variant="neutral">
          <Text className="font-medium mb-1"> Sub-criteria for assessing responses during evaluation.</Text>
          <Text size="sm">The sub-criteria are not visible to suppliers and will be assessed during evaluation using the rating scale selected.</Text>
        </Banner>
        {map(tenderCriteria, (tenderCriterion, index) => (
          <TenderQuestionAccordion
            key={tenderCriterion._id}
            className={index ? 'mt-8' : ''}
            tenderCriterion={tenderCriterion}
            tenderQuestions={formData[tenderCriterion._id] || []}
            processedNonPriceCriteria={processedCriteria.nonPriceCriteriaWithQuestions.criteria[index]}
            onQuestionAdd={handleQuestionAdd}
            onQuestionUpdate={handleQuestionUpdate}
            onQuestionRemove={handleQuestionRemove}
            isEditing={isEditing}
            isDisabled={isSaving || !isEditable}
          />
        ))}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
