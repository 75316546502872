import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import { GqlApprovalTemplateListInput, GqlApprovalTemplateListQuery } from '@gql'

export const queryApprovalTemplateList = async (input: GqlApprovalTemplateListInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      query ApprovalTemplateList($input: ApprovalTemplateListInput!) {
        approvalTemplateList(input: $input) {
          items {
            ...ApprovalTemplateFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateList
}
