import { useMutate } from '@cotiss/common'
import { AccessControlResourceName, AccessControlRole } from '@cotiss/access-control'

export type CreateAccessControlBulkBody = {
  users: {
    userId: string
    roles: AccessControlRole[]
  }[]
}

type UpdateAccessControlBody = {
  roles: AccessControlRole[]
  userId: string
}

export type RemoveAccessControlBulkBody = {
  accessControls: {
    accessControlId: string
    role: AccessControlRole
  }[]
}

export const useMutateAccessControl = () => {
  const { mutate } = useMutate()

  return {
    createAccessControlBulk: async (resourceId: string, resourceName: AccessControlResourceName, body: CreateAccessControlBulkBody) => {
      return await mutate({
        route: `/v2/access-control/${resourceId}/${resourceName}/create-bulk`,
        body,
        invalidate: ['/v2/access-control', '/v2/procurements', '/v2/tenders'],
      })
    },
    updateAccessControl: async (resourceId: string, body: UpdateAccessControlBody) => {
      return await mutate({
        route: `/v2/access-control/${resourceId}`,
        body,
        invalidate: ['/v2/access-control', '/v2/procurements', '/v2/tenders'],
      })
    },
    removeAccessControlBulk: async (resourceId: string, resourceName: AccessControlResourceName, body: RemoveAccessControlBulkBody) => {
      return await mutate({
        route: `/v2/access-control/${resourceId}/${resourceName}/remove-bulk`,
        body,
        invalidate: ['/v2/access-control', '/v2/procurements', '/v2/tenders'],
      })
    },
  }
}
