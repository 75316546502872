import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'
import { Hr } from '@cotiss/common'

type Props = {
  className?: string
  children: ReactNode
  hasSeparator?: boolean
}

export const CardHeader = memo(({ className, children, hasSeparator = true }: Props) => {
  const classes = classNames(className, 'flex items-center justify-between')

  return (
    <>
      <div className={classes}>{children}</div>
      {hasSeparator && <Hr className="my-4" />}
    </>
  )
})
