import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Field,
  Form,
  Hr,
  Radio,
  RadioCard,
  Skeleton,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  Text,
  sentryService,
  useFeature,
  useCallout,
  useToast,
  useAnalytics,
} from '@cotiss/common'
import {
  ContractDocumentShellSignatureType,
  ContractSignersEditDeleteCta,
  ContractStepCardSkeletonLoading,
  useGetContractShell,
  useMutateContractShell,
  ContractSignatureModal,
} from '@cotiss/contract'
import { find, first, lowerCase, map, orderBy, upperFirst } from 'lodash'

type Props = {
  onNext: () => void
  onBack: () => void
  contractShellId: string
}

export const ContractWizardSignersStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateContractDocumentShell, updateAttachmentSignaturesBulk } = useMutateContractShell()
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { track } = useAnalytics()

  const { signatures, documentShell } = useMemo(() => {
    const documentShell = find(find(contractShell?.contracts, { status: 'DRAFTING' })?.documentShells, { type: 'CONTRACT' })
    const signatures = documentShell?.attachments.length ? documentShell?.attachments[0].signatures : []
    return { signatures: orderBy(signatures, 'name', 'desc'), documentShell }
  }, [contractShell])

  const [errorMessage, setErrorMessage] = useState('')
  const [signatureType, setSignatureType] = useState<ContractDocumentShellSignatureType>(documentShell ? documentShell.signatureType : 'NONE')
  const [isSaving, setIsSaving] = useState(false)

  const isESignEnabled = useFeature('contract-management-e-sign')

  useEffect(() => {
    track('contract_wizard_signers_view')
  }, [])

  useEffect(() => {
    setErrorMessage('')
  }, [signatures])

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="flex items-between p-6">
          <Skeleton className="bg-primary-200 h-6 w-96 mr-20" />
          <div className="w-full">
            <Skeleton className="bg-primary-200 h-6 w-1/2 mb-4" />
            <Skeleton className="bg-primary-200 h-6 w-1/2" />
          </div>
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // TODO: dynamic wizard steps - this page shouldn't show if there's no document shell
    // Will implement dynamic wizard steps in v2/v3
    if (!documentShell) {
      onNext()
      return
    }

    const contract = find(contractShell.contracts, (contract) => contract.status === 'DRAFTING')
    const attachment = first(documentShell?.attachments) // TODO: there shouldn't be more than one attachment, but do we handle that just in case?

    if (!contract || !attachment) {
      return
    }

    if (signatureType === 'ESIGN_DOCUSIGN' && !signatures.length) {
      setErrorMessage('Please add at least one signee to continue')
      return
    }

    track('contract_wizard_signers_update_submit')

    try {
      setIsSaving(true)
      await updateContractDocumentShell(contractShell._id, contract._id, documentShell._id, { signatureType })

      // If we are not using docusign, but have previously added signatures, clear them out
      if (signatureType !== 'ESIGN_DOCUSIGN' && signatures.length) {
        await updateAttachmentSignaturesBulk({
          contractShellId: contractShell._id,
          contractId: contract._id,
          documentShellId: documentShell._id,
          attachmentId: attachment?._id,
          body: { items: [] },
        })
      }
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    onNext()
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Name',
      rows: map(signatures, (signature) => ({
        content: () => (
          <Text className="font-medium truncate" font="jakarta" title={signature.name}>
            {signature.name}
          </Text>
        ),
        cta: <ContractSignersEditDeleteCta signature={signature} contractShellId={contractShellId} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Email',
      rows: map(signatures, ({ email }) => ({
        content: () => <Text>{email}</Text>,
      })),
    },
    {
      heading: 'Type',
      rows: map(signatures, ({ signatureType }) => ({
        content: () => (
          <Badge shape="square" state="translucent" variant={signatureType === 'EXTERNAL' ? 'info' : 'secondary'}>
            {upperFirst(lowerCase(signatureType))}
          </Badge>
        ),
      })),
    },
  ]

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5" font="jakarta">
              Signers
            </Text>
          </div>
          <div className="flex flex-col items-end ml-4">
            <div className="flex">
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isSaving}>
                Back
              </Button>
              <Button type="submit" variant="secondary" size="sm" isDisabled={isSaving}>
                Continue
              </Button>
            </div>
            <Text variant="danger" className="mt-1" size="sm">
              {errorMessage}
            </Text>
          </div>
        </CardHeader>
        <div className="p-6">
          {/* TODO: remove when dynamic wizard steps have been implemented */}
          {!documentShell && <Text className="text-center">No contract to sign</Text>}
          {documentShell && (
            <Field label="Select the relevant signature setting" isFullWidth>
              <div className="grid grid-cols-3 grid-rows-1 gap-4">
                <RadioCard
                  className="border border-gray-100 p-3.5"
                  name="contract-signature-setting"
                  isSelected={signatureType === 'NONE'}
                  onChange={() => setSignatureType('NONE')}
                  isDisabled={isSaving}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium" font="jakarta">
                      This contract has already been signed
                    </Text>
                    <Radio className="ml-2" isChecked={signatureType === 'NONE'} />
                  </div>
                </RadioCard>
                <RadioCard
                  className="border border-gray-100 p-3.5"
                  name="contract-signature-setting"
                  isSelected={signatureType === 'EXTERNAL'}
                  onChange={() => setSignatureType('EXTERNAL')}
                  isDisabled={isSaving}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium" font="jakarta">
                      This contract will be signed externally and uploaded
                    </Text>
                    <Radio className="ml-2" isChecked={signatureType === 'EXTERNAL'} />
                  </div>
                  <Text className="mt-1" size="sm" variant="light">
                    {isESignEnabled
                      ? 'If you wish to send the contract via email, mail or any method except Cotiss E-Sign. You will be required to upload the signed contract separately'
                      : 'If you wish to send the contract via email or mail. You will be required to upload the signed contract separately'}
                  </Text>
                </RadioCard>
                {isESignEnabled && (
                  <RadioCard
                    className="border border-gray-100 p-3.5"
                    name="contract-signature-setting"
                    isSelected={signatureType === 'ESIGN_DOCUSIGN'}
                    onChange={() => setSignatureType('ESIGN_DOCUSIGN')}
                    isDisabled={isSaving}
                  >
                    <div className="flex justify-between">
                      <Text className="font-medium" font="jakarta">
                        This contract will be signed using e-signature
                      </Text>
                      <Radio className="ml-2" isChecked={signatureType === 'ESIGN_DOCUSIGN'} />
                    </div>
                    <Text className="mt-1" size="sm" variant="light">
                      You will be able to list a set of emails for sending via the Cotiss E-Sign functionality
                    </Text>
                  </RadioCard>
                )}
              </div>
            </Field>
          )}
          {isESignEnabled && signatureType === 'ESIGN_DOCUSIGN' && (
            <>
              <Hr className="my-8" />
              <TableHeader className="flex items-center justify-between">
                <div>
                  <Text className="font-semibold" variant="heading" font="jakarta">
                    E-signature invitations
                  </Text>
                  <Text className="mt-1" size="sm" variant="light">
                    Add internal and external signatories that you would like to sign this contract via e-signature. At least one must be added. The
                    signatures are sent in two stages, with external signatories first and then the internal signatories second.
                  </Text>
                </div>
                <Button
                  variant="secondary"
                  size="xs"
                  onClick={() => openModal(<ContractSignatureModal contractShellId={contractShellId} existingSignatures={signatures} />)}
                >
                  + Add row {/* TODO: Replace this with descriptive CTA label */}
                </Button>
              </TableHeader>
              <ScrollableTable
                fixedColumns={fixedColumns}
                columns={columns}
                emptyCta={
                  <Button
                    state="text"
                    variant="secondary"
                    onClick={() => openModal(<ContractSignatureModal contractShellId={contractShellId} existingSignatures={signatures} />)}
                  >
                    + Add row {/* TODO: Replace this with descriptive CTA label */}
                  </Button>
                }
              />
            </>
          )}
        </div>
      </Card>
    </Form>
  )
})
