import { useFetch, PaginatedModel } from '@cotiss/common'
import { ContractShellPopulatedModel } from '@cotiss/contract'

type Props = {
  limit?: number
  offset?: number
  filter?: 'approvals'
  procurementId?: string
}

export const useListContractShell = ({ offset = 0, limit = -1, ...params }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ContractShellPopulatedModel>>('/v2/contract-shells', {
    route: '/v2/contract-shells',
    params: { ...params, offset, limit },
  })

  return { contractShells: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
