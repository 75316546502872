import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'
import { useEvaluationEnvelope, useEvaluationEvent, useEvaluationEventAnalytics } from '@cotiss/evaluation-event'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import React, { FormEvent, memo, useState } from 'react'

type FormData = {
  name: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventEnvelopeUpdateModal = memo(({ evaluationEnvelope }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { queryEvaluationEnvelopeList, mutateUpdateEvaluationEnvelope } = useEvaluationEnvelope()
  const [formData, setFormData] = useState<FormData>({
    name: evaluationEnvelope.name,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelopes_update_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelope.id, ...formData, isScored: true })

      // We need to refresh the evaluation event as updating the envelopes may update the steps of the wizard (E.g. if there is a price table.)
      await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update envelope" isDisabled={isSaving} />
      <ModalContent className="max-h-[500px]" isScrollable>
        <Label>Envelope name</Label>
        <Input
          value={formData.name}
          onChange={({ target }) => setFormData({ ...formData, name: target.value })}
          placeholder="Type what will be evaluated in this envelope"
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
