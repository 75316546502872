// NOTE: This is part of a DEPRECATED and UNUSED flow.

import React, { memo } from 'react'
import { AvatarSkeleton, Skeleton } from '@cotiss/common'

export const TenderQuestionReportScoreItemSkeleton = memo(() => (
  <tr>
    <td className="py-2" colSpan={3}>
      <div className="flex items-center w-full">
        <AvatarSkeleton className="mr-1" />
        <Skeleton className="h-3 w-1/2" />
      </div>
      <div className="border-l-2 border-gray-200 pl-2 ml-2">
        <Skeleton className="h-2 w-2/3 mt-3" />
        <Skeleton className="h-2 w-2/3 mt-1" />
        <Skeleton className="h-2 w-1/2 mt-1" />
      </div>
    </td>
  </tr>
))
