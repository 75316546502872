import { gql } from '@apollo/client'

export const EVALUATION_EVENT_FRAGMENT = gql`
  fragment EvaluationEventFields on EvaluationEvent {
    id
    name
    organisationId
    organisation {
      ...OrganisationFields
    }
    methodology
    status
    isParallel
    isSetupComplete
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
