import { ContactModel, Filter_DEPRECATED, FilterFieldOptions_DEPRECATED, PaginatedModel, paginationService, useMutate } from '@cotiss/common'
import { OrganisationCountryCodeCombined, OrganisationEntityType } from '@cotiss/organisation'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier'
import { isArray, map } from 'lodash'

export type PreferredSupplierContactModel = Pick<ContactModel, 'email' | 'firstName' | 'lastName' | 'phoneNumber'> & {
  sendInvitationEmail: boolean
}

/**
 * Need to supply either `name`, `entityType`, `countryCode` `businessNumber` and `kyckrId` or `supplierOrganisation`
 */
type CreatePreferredSupplierBody = {
  name?: string
  entityType?: OrganisationEntityType
  countryCode?: OrganisationCountryCodeCombined
  businessNumber?: string
  kyckrId?: string
  supplierOrganisation?: string
  contacts?: PreferredSupplierContactModel[]
}

type UpdatePreferredSupplierBody = {
  isArchived?: boolean
  contacts?: (PreferredSupplierContactModel & { _id?: string })[]
}

type UpdatePreferredSupplierBulkBody = {
  preferredSupplierIds: string[]
  updates?: {
    tags?: string[]
  }
}

type ValidatePreferredSupplierInvitationResponse = {
  organisationId: string
}

type ResendPreferredSupplierInvitationBody = {
  contactId: string
}

type ExportPreferredSupplierCsvBody = {
  filters?: Filter_DEPRECATED[]
  timeZone?: string
}

export type FilterPreferredSupplierSortKey = 'organisationName' | 'createdAt'

type FilterPreferredSupplierParam = {
  currentPage: number
  pageSize: number
  sort?: FilterPreferredSupplierSortKey
  order: 'asc' | 'desc'
  filters?: Filter_DEPRECATED[]
}

export const useMutatePreferredSupplier = () => {
  const { mutate } = useMutate()

  return {
    createPreferredSupplier: async (body: CreatePreferredSupplierBody) => {
      return await mutate<PreferredSupplierPopulatedModel>({ route: '/v2/preferred-suppliers', body, invalidate: ['/v2/preferred-suppliers'] })
    },
    updatePreferredSupplier: async (id: string, body: UpdatePreferredSupplierBody) => {
      return await mutate<PreferredSupplierPopulatedModel>({ route: `/v2/preferred-suppliers/${id}`, body, invalidate: ['/v2/preferred-suppliers'] })
    },
    updatePreferredSupplierBulk: async (body: UpdatePreferredSupplierBulkBody) => {
      return await mutate({ route: '/v2/preferred-suppliers/updateBulk', body, invalidate: ['/v2/preferred-suppliers'] })
    },
    resendPreferredSupplierInvitation: async (id: string, body: ResendPreferredSupplierInvitationBody) => {
      return await mutate({ route: `/v2/preferred-suppliers/${id}/resend`, body, invalidate: ['/v2/preferred-suppliers'] })
    },
    validatePreferredSupplierInvitation: async (token: string) => {
      return await mutate<ValidatePreferredSupplierInvitationResponse>({ route: `/v2/preferred-suppliers/validate`, body: { token } })
    },
    filterPreferredSupplier: async ({ currentPage = 1, pageSize = 20, sort, order, filters }: FilterPreferredSupplierParam) => {
      // If the filter value is an array, map it to get the value of what is being filtered
      const processedFilters = map(filters, (filter) => {
        const { field, operation, value } = filter
        const finalValue = isArray(value) ? map(value, ({ value }) => value) : value
        return { field, operation, value: finalValue }
      })

      const { limit, offset } = paginationService.convertPageSizeToOffset({ currentPage, pageSize })

      const { items, count, meta } = await mutate<PaginatedModel<PreferredSupplierPopulatedModel, FilterFieldOptions_DEPRECATED>>({
        method: 'POST',
        route: '/v2/preferred-suppliers/filter',
        params: { limit, offset, sort, order: order === 'asc' ? 1 : -1 },
        body: { filters: processedFilters },
      })

      return {
        preferredSuppliers: items,
        pagination: { currentPage, pageSize, pageCount: Math.ceil(count / pageSize), itemCount: items.length, totalCount: count },
        meta,
      }
    },
    exportCsv: async (body: ExportPreferredSupplierCsvBody) => {
      // If the filter value is an array, map it to get the value of what is being filtered x2 lol
      const finalFilter = body.filters?.map((filter) => {
        const { field, operation, value } = filter
        const finalValue = isArray(value) ? map(value, ({ value }) => value) : value
        return { field, operation, value: finalValue }
      })

      return await mutate<{ csv: string }>({
        method: 'POST',
        route: '/v2/preferred-suppliers/export-csv',
        body: { ...body, filters: finalFilter },
      })
    },
  }
}
