// NOTE: This is part of a DEPRECATED and UNUSED flow.

import React, { memo } from 'react'
import { TenderRequestPriceTable } from '@cotiss/tender'

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestPricingTableTab = memo(({ tenderId, isEditable }: Props) => {
  return (
    <div className="max-w-5xl">
      <TenderRequestPriceTable tenderId={tenderId} isEditable={isEditable} />
    </div>
  )
})
