import React, { memo, useMemo } from 'react'
import { DocumentViewer } from '@cotiss/document'
import { useGetContractShell } from '@cotiss/contract'
import { Input, NoDataPlaceholder, Skeleton } from '@cotiss/common'
import { find } from 'lodash'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummaryContractTab = memo(({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading: isContractShellLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => find(contractShell?.contracts, (contract) => contract._id === contractId), [contractShell, contractId])

  const { document, documentShellTitle } = useMemo(() => {
    // Currently we only support one document shell of type CONTRACT
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })
    return { document: documentShell?.attachments[0].document, documentShellTitle: documentShell?.name }
  }, [contract])

  if (isContractShellLoading) {
    return <Skeleton className="h-64" />
  }

  return (
    <div>
      {!document && <NoDataPlaceholder label="No contract uploaded" />}
      {document && (
        <>
          <div className="w-1/3">
            <Input value={documentShellTitle} isDisabled />
          </div>
          <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 aspect-[1/1.414] mt-6">
            <DocumentViewer document={document} isDownloadable />
          </div>
        </>
      )}
    </div>
  )
})
