import { includes } from 'lodash'
import { useParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useMemo, useState } from 'react'
import { EvaluationListPrice } from '@cotiss/evaluation'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { TenderResponsePriceScore, TenderResponseReportModel, TenderResponseReportPriceList } from '@cotiss/tender-response'
import {
  Banner,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  RemainingTasksButton,
  Text,
  TransitionContainer,
  TransitionType,
  useCallout,
} from '@cotiss/common'

export const EvaluationChairPriceStep = memo(() => {
  const { openModal } = useCallout()
  const { progressTender } = useMutateTender()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const [transition, setTransition] = useState<TransitionType>('right')
  const [activeTenderResponseReport, setActiveTenderResponseReport] = useState<TenderResponseReportModel | null>(null)
  const isEditable = tender?.isTwoEnvelope ? tender.status === 'price' : includes(['consensus', 'price'], tender?.status)
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Price scoring',
        onClick: () => handleSetActiveTenderResponseReport(null),
      },
    ]
  }, [])

  const handleSetActiveTenderResponseReport = (tenderResponseReport: TenderResponseReportModel | null) => {
    setTransition(tenderResponseReport ? 'right' : 'left')

    setTimeout(() => {
      setActiveTenderResponseReport(tenderResponseReport || null)
    }, 0)
  }

  if (!tender) return null

  return (
    <>
      {tender.status === 'price' && (
        <Banner className="mb-6" icon="dollar" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Complete price scoring
            </Text>
            <Text size="sm">Review price documents and score accordingly</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            <Button
              className="ml-2"
              onClick={() =>
                openModal(
                  <ConfirmModal
                    heading="Finalise price scores?"
                    description="This action cannot be undone."
                    onSubmit={() => progressTender(tender._id)}
                  />
                )
              }
              size="sm"
              isDisabled={Boolean(tender.validation.validationErrors.length)}
            >
              Complete scoring
            </Button>
          </div>
        </Banner>
      )}
      {includes(tender.validation.finishedStages, 'price') && (
        <Banner className="mb-6" icon="check" variant="success">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Evaluation completed
            </Text>
            <Text size="sm">Individual scoring, consensus scoring and price scoring have been completed</Text>
          </div>
        </Banner>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {!activeTenderResponseReport && (
          <TransitionContainer key={1} transition={transition}>
            {tender.priceTableEnabled ? (
              <EvaluationListPrice
                tenderId={tenderId}
                onView={handleSetActiveTenderResponseReport}
                evaluationType="panel"
                isScoringEnabled={
                  tender.isPriceWeighted && (tender.isTwoEnvelope ? tender.status === 'price' : includes(['consensus', 'price'], tender.status))
                }
              />
            ) : (
              <TenderResponseReportPriceList tenderId={tenderId} onView={handleSetActiveTenderResponseReport} />
            )}
          </TransitionContainer>
        )}
        {activeTenderResponseReport && (
          <TransitionContainer key={2} transition={transition}>
            <TenderResponsePriceScore tenderResponseId={activeTenderResponseReport._id} isEditable={isEditable} breadcrumbs={breadcrumbs} />
          </TransitionContainer>
        )}
      </AnimatePresence>
    </>
  )
})
