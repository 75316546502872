import classNames from 'classnames'
import React, { memo } from 'react'
import { Skeleton } from '@cotiss/common'
import { TENDER_FLOW_STEPS } from '@cotiss/tender-flow'

type Props = {
  className?: string
}

export const TenderSummaryCardSkeleton = memo(({ className }: Props) => {
  const classes = classNames(className, 'shrink-0 bg-white rounded-lg shadow-lg w-60 p-4')

  return (
    <div className={classes}>
      <div className="flex items-center justify-between">
        <Skeleton className="h-5 w-10 mr-2" />
        <Skeleton className="h-4 w-20" isRounded />
      </div>
      <Skeleton className="h-4 w-24 mt-4" />

      <Skeleton className="h-3 w-full mt-2" />
      <Skeleton className="h-3 w-full mt-1" />
      <Skeleton className="h-3 w-12 mt-1" />

      <Skeleton className="h-5 w-20 mt-4" isRounded />
      <div className="flex space-x-1.5 mt-4">
        {Array.from({ length: TENDER_FLOW_STEPS.length }, (_, i) => (
          <div key={i} className="bg-primary-100 rounded-full h-1 w-8" />
        ))}
      </div>

      <Skeleton className="h-8 w-full mt-4" />
    </div>
  )
})
