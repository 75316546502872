import React, { memo } from 'react'
import { Badge, Page, Section, Text } from '@cotiss/common'
import FourOhFourFolderSvg from '@assets/svg/404-folder.svg'

export const FourOhFourPage = memo(() => (
  <Page className="flex items-center justify-center">
    <Section className="text-center" isCentered>
      <FourOhFourFolderSvg className="mx-auto" />
      <Badge state="translucent" variant="secondary" shape="square">
        404 Error
      </Badge>
      <Text className="font-semibold text-center mt-6" size="h2" font="jakarta" variant="heading">
        Sorry, there&apos;s nothing here
      </Text>
      <Text className="mt-2" variant="light">
        The page you are looking for is not available.
      </Text>
    </Section>
  </Page>
))
