import { ProcessedUnspscSubcategory } from '@cotiss/supplier'
import { compact, forEach, map } from 'lodash'
import FULL_UNSPSC_DATA from './data/full-unspsc-data.json'

class SupplierService {
  _unspscCategoryMap: Record<string, ProcessedUnspscSubcategory> | null = null

  getUnspscCategoryMap = () => {
    if (this._unspscCategoryMap) {
      return this._unspscCategoryMap
    }

    const unspscCategoryMap: Record<string, ProcessedUnspscSubcategory> = {}

    forEach(FULL_UNSPSC_DATA as ProcessedUnspscSubcategory[], (value) => {
      unspscCategoryMap[value.code] = value
    })

    this._unspscCategoryMap = unspscCategoryMap

    return unspscCategoryMap
  }

  processCategoryCodes = (categoryCodes: string[]) => {
    const unspscCategoryMap = this.getUnspscCategoryMap()

    return compact(map(categoryCodes, (categoryCode) => unspscCategoryMap[categoryCode]))
  }
}

export const supplierService = new SupplierService()
