import { parseISO } from 'date-fns'

class SortService {
  sortString = (a: string, b: string) => {
    return a.localeCompare(b)
  }

  sortNumber = (a?: number, b?: number) => {
    return (a || 0) - (b || 0)
  }

  sortDate = (a: Date | string, b: Date | string) => {
    return (typeof a === 'string' ? parseISO(a) : a).getTime() - (typeof b === 'string' ? parseISO(b) : b).getTime()
  }
}

export const sortService = new SortService()
