import { AppErrorPage, AppLayout, AppRoute } from '@cotiss/app'
import {
  ForgotPasswordPage,
  JoinAccountPage,
  LoginPage,
  LogoutPage,
  MasqueradePage,
  ResetPasswordPage,
  SsoLoginPage,
  SignUpBuyerPage,
  SignUpPage,
  SignUpSupplierPage,
  SignUpUserPage,
  SsoErrorPage,
} from '@cotiss/auth'
import { StagingBanner, Route, envService, useAnalytics } from '@cotiss/common'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'

export const App = Sentry.withProfiler(() => {
  const history = useHistory()
  const { init: initAnalytics } = useAnalytics()
  const classes = classNames('relative', {
    'h-full': !envService.isStaging(),
    'top-[68px] h-[calc(100%-68px)]': envService.isStaging(),
  })

  useEffect(() => {
    initAnalytics()
    history.listen(() => {
      window.scroll(0, 0)
    })
  }, [])

  return (
    <Sentry.ErrorBoundary>
      {envService.isStaging() && <StagingBanner />}
      <div className={classes}>
        <Switch>
          {/* ! This is a legacy route that we want to support incase there are any old invitation emails hanging around. */}
          <Route path="/user-signup" exact>
            <SignUpUserPage />
          </Route>

          <Route path="/error" exact>
            <AppErrorPage />
          </Route>
          <Route path="/sign-up" exact>
            <SignUpPage />
          </Route>
          <Route path="/sign-up/supplier" exact>
            <SignUpSupplierPage />
          </Route>
          <Route path="/sign-up/buyer" exact>
            <SignUpBuyerPage />
          </Route>
          <Route path="/sign-up/user/:invitationCode" exact>
            <SignUpUserPage />
          </Route>
          <Route path="/join-account/:email" exact>
            <JoinAccountPage />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password/:token" exact>
            <ResetPasswordPage />
          </Route>
          <Route path="/masquerade/:token" exact>
            <MasqueradePage />
          </Route>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/sso-login/:token" exact>
            <SsoLoginPage />
          </Route>
          <Route path="/sso-error" exact>
            <SsoErrorPage />
          </Route>
          <Route path="/logout" exact>
            <LogoutPage />
          </Route>
          <AppRoute path="/" component={AppLayout} />
        </Switch>
      </div>
    </Sentry.ErrorBoundary>
  )
})
