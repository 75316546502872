import { gql } from '@apollo/client'

export const CONTRACT_SHELL_FRAGMENT = gql`
  fragment ContractShellFields on ContractShell {
    id
    externalId
    title
    status
    description
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
