import { gql } from '@apollo/client'
import { GqlEvaluationScoreListInput, GqlEvaluationScoreListQuery } from '@gql'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_SCORE_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationScoreList = async (input: GqlEvaluationScoreListInput) => {
  const { data } = await apolloService.query<GqlEvaluationScoreListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_SCORE_FRAGMENT}

      query EvaluationScoreList($input: EvaluationScoreListInput!) {
        evaluationScoreList(input: $input) {
          items {
            ...EvaluationScoreFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationScoreList
}
