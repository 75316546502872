import { Field, Skeleton, datetimeService, Text } from '@cotiss/common'
import { useGetContractShell } from '@cotiss/contract'
import React, { useMemo } from 'react'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummaryCessationDetails = ({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading: isLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => contractShell?.contracts.find((contract) => contract._id === contractId), [contractShell, contractId])

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-6 mb-6" />
        <Skeleton className="h-6" />
      </div>
    )
  }

  return (
    <div className="my-6">
      <Field label="Cessation date" className="my-6">
        <Text>
          {contract?.cessationDate ? datetimeService.format(new Date(contract?.cessationDate), 'd MMMM yyyy h:mm aaa') : 'No date provided'}
        </Text>
      </Field>
      <Field label="Reason for cessation">
        <Text>{contract?.cessationReason || 'No reason provided'}</Text>
      </Field>
    </div>
  )
}
