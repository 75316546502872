import { useFetch, PaginatedModel } from '@cotiss/common'
import { TenderUpdatePopulatedModel } from '@cotiss/tender-update'

type Meta = {
  unviewedUpdatesIds: string[]
  unviewedUpdatesCount: number
}

type Props = {
  tenderId?: string
  limit?: number
  offset?: number
}

export const useListTenderUpdate = ({ tenderId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderUpdatePopulatedModel, Meta>>('/v2/tender-updates', {
    route: `/v2/tender-updates`,
    params: { tenderId, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { tenderUpdates: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
