import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { SettingsPage } from '@cotiss/settings'

export const SettingsLayout = memo(() => (
  <Switch>
    <Route path="/settings/:tab?/:nestedTab?/:subNestedTab?" exact>
      <SettingsPage />
    </Route>
  </Switch>
))
