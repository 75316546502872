import { useMutate } from '@cotiss/common'

type CreateMetafieldValueBody = {
  metafieldId: string
  resourceId: string
  fieldValue: any
}

type UpdateMetafieldValueBody = {
  fieldValue: any
}

export const useMutateMetafieldValue = () => {
  const { mutate } = useMutate()

  return {
    createMetafieldValue: async (body: CreateMetafieldValueBody) => {
      await mutate({ route: '/v2/metafield-values', body })
    },
    updateMetafieldValue: async (id: string, body: UpdateMetafieldValueBody) => {
      await mutate({ route: `/v2/metafield-values/${id}`, method: 'PUT', body, invalidate: ['/v2/metafield-values'] })
    },
    deleteMetafieldValue: async (id: string) => {
      await mutate({ route: `/v2/metafield-values/${id}`, method: 'DELETE', invalidate: ['/v2/metafield-values'] })
    },
  }
}
