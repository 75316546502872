import { UserMeModel, userService } from '@cotiss/user'
import { AuthAnalyticsEvent } from '@cotiss/auth/auth.events'
import { ForumAnalyticsEvent } from '@cotiss/forum/forum.events'
import { clarityService } from '@cotiss/common/services/clarity.service'
import { ContractAnalyticsEvent } from '@cotiss/contract/contract.events'
import { PlanEventAnalyticsEvent } from '@cotiss/plan-event/plan-event.events'
import { PerformanceAnalyticsEvent } from '@cotiss/performance/performance.events'
import { ProcurementAnalyticsEvent } from '@cotiss/procurement/procurement.events'
import { EvaluationEventAnalyticsEvent } from '@cotiss/evaluation-event/evaluation-event.events'
import { PreferredSupplierAnalyticsEvent } from '@cotiss/preferred-supplier/preferred-supplier.events'
import { ProcurementResponseAnalyticsEvent } from '@cotiss/procurement-response/procurement-response.events'
import { TIMEZONE_CONFIG_BY_TIMEZONE, COUNTRY_BY_COUNTRY_CODE, apiService, utilService, datetimeService } from '@cotiss/common'

export type AnalyticsEvent =
  | AuthAnalyticsEvent
  | ContractAnalyticsEvent
  | EvaluationEventAnalyticsEvent
  | ForumAnalyticsEvent
  | PerformanceAnalyticsEvent
  | PlanEventAnalyticsEvent
  | PreferredSupplierAnalyticsEvent
  | ProcurementAnalyticsEvent
  | ProcurementResponseAnalyticsEvent

type CachedTrackEvent = {
  event: AnalyticsEvent
  additionalProperties?: Record<string, any>
}

class AnalyticsService {
  private _context = {
    ip: '',
    user_agent: navigator.userAgent,
    timezone: '',
    location: { country: '' },
    os: { name: '' },
    screen: { width: 0, height: 0 },
  }
  private _name = ''
  private _email = ''
  private _userId = ''
  private _anonymousId = ''
  private _isIdentified = false
  private _isInitialised = false
  private _cachedTrackEvents: CachedTrackEvent[] = []

  init = async () => {
    let ip = ''
    try {
      const response = await fetch('https://api.ipify.org/?format=json')
      const data: { ip: string } = await response.json()
      ip = data.ip || ''
    } catch {
      // Swallow this error.
    }

    const timezone = datetimeService.getLocalTimeZone()
    const timezoneConfig = TIMEZONE_CONFIG_BY_TIMEZONE[timezone]
    const countryCode = timezoneConfig?.c ? timezoneConfig.c[0] || '' : ''
    const country = countryCode ? COUNTRY_BY_COUNTRY_CODE[countryCode] : ''

    this._anonymousId = utilService.generateUid()
    this._context.ip = ip
    this._context.timezone = timezone
    this._context.location = { country: country }
    this._context.screen.height = window.innerHeight
    this._context.screen.width = window.innerWidth

    const userAgent = navigator.userAgent || navigator.vendor
    if (/windows phone/i.test(userAgent)) {
      this._context.os.name = 'Windows Phone'
    } else if (/android/i.test(userAgent)) {
      this._context.os.name = 'Android'
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      this._context.os.name = 'iOS'
    } else {
      this._context.os.name = navigator.platform || 'unknown'
    }

    clarityService.init()

    this._isInitialised = true
  }

  post = async (path: string, config: Record<string, any>) => {
    try {
      await apiService.post(path, config)
    } catch (error: any) {
      // We are just swallowing these errors, as we don't need them to be tracked by Sentry.
    }
  }

  identify = async ({ _id, email, firstname, surname }: UserMeModel, force?: boolean) => {
    if (!this._isInitialised || (this._isIdentified && !force)) {
      return
    }

    this._userId = _id
    this._email = email
    this._name = userService.getFullName({ firstname, surname })

    await this.post('/analytics/identify', {
      ...this.getBaseConfig(),
      traits: { name: this._name, email: this._email },
    })

    await this.post('/analytics/alias', {
      properties: this.getBaseProperties(),
      context: this._context,
      previousId: this._anonymousId,
      userId: this._userId,
    })

    clarityService.identify(this._email)

    this._isIdentified = true

    // Fire any cached track events once we've identified.
    await utilService.asyncForEach(this._cachedTrackEvents, async ({ event, additionalProperties }) => {
      await this.track(event, additionalProperties)
    })
  }

  track = async (event: AnalyticsEvent, additionalProperties?: Record<string, any>) => {
    if (!this._isIdentified) {
      // Cache the track event, so we can fire it right after the identify event has completed.
      this._cachedTrackEvents.push({ event, additionalProperties })
      return
    }

    await this.post('/analytics/track', { ...this.getBaseConfig(additionalProperties), event })
  }

  getBaseConfig = (additionalProperties?: Record<string, any>) => {
    const config: Record<string, any> = {
      anonymousId: this._anonymousId,
      properties: this.getBaseProperties(additionalProperties),
      context: this._context,
    }

    if (this._userId) {
      config.userId = this._userId
    }

    return config
  }

  getBaseProperties = (additionalProperties?: Record<string, any>) => {
    const properties: Record<string, any> = {
      ...additionalProperties,
      path: location.pathname,
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url: location.href,
    }

    return properties
  }
}

export const analyticsService = new AnalyticsService()
