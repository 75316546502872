import { gql } from '@apollo/client'

export const PERFORMANCE_METRIC_FRAGMENT = gql`
  fragment PerformanceMetricFields on PerformanceMetric {
    id
    organisationId
    organisation {
      ...OrganisationFields
    }
    name
    description
    methodology
    group
    metricUnit
    metricUnitType
    createdByUserId
    createdByUser {
      ...UserFields
    }
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
