var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/remirror__extension-epic-mode/src/epic-mode-effects.ts
import { randomInt } from "@remirror/core";
var COLORS = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#bcbd22",
  "#17becf"
];
var VIBRANT_COLORS = [
  "#0078ff",
  "#bd00ff",
  "#ff9a00",
  "#01ff1f",
  "#e3ff00",
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#bcbd22",
  "#17becf"
];
var PARTICLE_NUM_RANGE = { min: 5, max: 10 };
var PARTICLE_GRAVITY = 0.08;
var PARTICLE_ALPHA_FADEOUT = 0.96;
var PARTICLE_VELOCITY_RANGE = {
  x: [-1, 1],
  y: [-3.5, -1.5]
};
var defaultEffect = {
  createParticle({ x, y, color }) {
    return {
      x,
      y: y + 10,
      alpha: 1,
      color,
      size: randomInt(2, 4),
      vx: PARTICLE_VELOCITY_RANGE.x[0] + Math.random() * (PARTICLE_VELOCITY_RANGE.x[1] - PARTICLE_VELOCITY_RANGE.x[0]),
      vy: PARTICLE_VELOCITY_RANGE.y[0] + Math.random() * (PARTICLE_VELOCITY_RANGE.y[1] - PARTICLE_VELOCITY_RANGE.y[0])
    };
  },
  updateParticle({ particle, ctx }) {
    particle.vy += PARTICLE_GRAVITY;
    particle.x += particle.vx;
    particle.y += particle.vy;
    particle.alpha *= PARTICLE_ALPHA_FADEOUT;
    ctx.fillStyle = `rgba(${particle.color[0]},${particle.color[1]},${particle.color[2]},${particle.alpha})`;
    ctx.fillRect(
      Math.round(particle.x - 1),
      Math.round(particle.y - 1),
      particle.size,
      particle.size
    );
  }
};
var DEFAULT_SPAWNING_DRAG = 0.92;
var createSpawningTheta = () => randomInt(0, 360) * Math.PI / 180;
var spawningEffect = {
  createParticle({ x, y, color }) {
    return {
      x,
      y: y + 10,
      alpha: 1,
      color,
      size: randomInt(2, 8),
      drag: DEFAULT_SPAWNING_DRAG,
      vx: randomInt(-3, 3),
      vy: randomInt(-3, 3),
      wander: 0.15,
      theta: createSpawningTheta()
    };
  },
  updateParticle({ particle, ctx }) {
    particle.x += particle.vx;
    particle.y += particle.vy;
    particle.vx *= particle.drag ?? DEFAULT_SPAWNING_DRAG;
    particle.vy *= particle.drag ?? DEFAULT_SPAWNING_DRAG;
    particle.theta = (particle.theta ?? createSpawningTheta()) + randomInt(-0.5, 0.5);
    particle.vx += Math.sin(particle.theta) * 0.1;
    particle.vy += Math.cos(particle.theta) * 0.1;
    particle.size *= 0.96;
    ctx.fillStyle = `rgba(${particle.color[0]},${particle.color[1]},${particle.color[2]},${particle.alpha})`;
    ctx.beginPath();
    ctx.arc(Math.round(particle.x - 1), Math.round(particle.y - 1), particle.size, 0, 2 * Math.PI);
    ctx.fill();
  }
};
var heartEffect = {
  createParticle({ x, y, color }) {
    return {
      x: x + 20,
      y: y - 10,
      alpha: 1,
      color,
      size: randomInt(5, 12),
      drag: 0.92,
      vx: randomInt(-3, 5),
      vy: randomInt(-3, 5),
      wander: 0.15,
      theta: randomInt(0, 360) * Math.PI / 180
    };
  },
  updateParticle({ particle, ctx }) {
    particle.x += particle.vx / 2;
    particle.y += particle.vy / 2;
    particle.alpha *= 0.985;
    const baseLen = particle.size;
    ctx.save();
    ctx.translate(particle.x, particle.y);
    ctx.rotate(3.95);
    ctx.beginPath();
    ctx.moveTo(-baseLen, 0);
    ctx.arc(0, 0, baseLen, 0, Math.PI, false);
    ctx.lineTo(baseLen, 0);
    ctx.arc(baseLen, -baseLen, baseLen, Math.PI * 90 / 180, Math.PI * 270 / 180, true);
    ctx.lineTo(baseLen, -baseLen * 2);
    ctx.lineTo(-baseLen, -baseLen * 2);
    ctx.lineTo(-baseLen, 0);
    const [r, g, b] = particle.color;
    ctx.fillStyle = `rgba(${r},${g},${b},${particle.alpha})`;
    ctx.fill();
    ctx.closePath();
    ctx.restore();
  }
};

// packages/remirror__extension-epic-mode/src/epic-mode-extension.ts
import {
  assertGet,
  extension,
  PlainExtension,
  randomInt as randomInt2,
  throttle
} from "@remirror/core";
var EpicModeExtension = class extends PlainExtension {
  get name() {
    return "epicMode";
  }
  createPlugin() {
    const pluginState = new EpicModePluginState(this);
    return {
      state: {
        init() {
          return pluginState;
        },
        apply(_tr, pluginState2) {
          return pluginState2;
        }
      },
      props: {
        handleKeyPress() {
          pluginState.shake();
          pluginState.spawnParticles();
          return false;
        }
      },
      view(view) {
        pluginState.init(view);
        return {
          destroy() {
            pluginState.destroy();
          }
        };
      }
    };
  }
};
EpicModeExtension = __decorateClass([
  extension({
    defaultOptions: {
      particleEffect: defaultEffect,
      getCanvasContainer: () => document.body,
      colors: VIBRANT_COLORS,
      particleRange: PARTICLE_NUM_RANGE,
      active: true,
      shakeTime: 0.3,
      shakeIntensity: 5
    }
  })
], EpicModeExtension);
function getRGBComponents(node) {
  const color = getComputedStyle(node).color;
  let match;
  if (color && (match = color.match(/(\d+), (\d+), (\d+)/))) {
    try {
      return match.slice(1);
    } catch {
      return [255, 255, 255];
    }
  } else {
    return [255, 255, 255];
  }
}
var _extension, _shakeTime, _shakeTimeMax, _lastTime, _particles;
var EpicModePluginState = class {
  constructor(extension2) {
    __privateAdd(this, _extension, void 0);
    __privateAdd(this, _shakeTime, 0);
    __privateAdd(this, _shakeTimeMax, 0);
    __privateAdd(this, _lastTime, 0);
    __privateAdd(this, _particles, []);
    this.shake = () => {
      if (this.options.active) {
        __privateSet(this, _shakeTime, __privateSet(this, _shakeTimeMax, this.options.shakeTime));
      }
    };
    this.spawnParticles = () => {
      const { selection } = this.view.state;
      const coords = this.view.coordsAtPos(selection.$anchor.pos);
      this.canvas.style.top = `${window.scrollY}px`;
      this.canvas.style.left = `${window.scrollX}px`;
      const node = document.elementFromPoint(coords.left - 5, coords.top + 5);
      if (!node) {
        return;
      }
      const numParticles = randomInt2(this.options.particleRange.min, this.options.particleRange.max);
      const textColor = getRGBComponents(node);
      for (let ii = 0; ii < numParticles; ii++) {
        const colorCode = assertGet(this.options.colors, ii % this.options.colors.length);
        const r = Number.parseInt(colorCode.slice(1, 3), 16);
        const g = Number.parseInt(colorCode.slice(3, 5), 16);
        const b = Number.parseInt(colorCode.slice(5, 7), 16);
        const color = [r, g, b];
        __privateGet(this, _particles)[ii] = this.options.particleEffect.createParticle({
          x: coords.left + 10,
          y: coords.top - 10,
          color,
          textColor,
          ctx: this.ctx,
          canvas: this.canvas
        });
      }
    };
    /**
     * Runs through the animation loop
     */
    this.loop = () => {
      if (!this.options.active) {
        return;
      }
      this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      const currentTime = Date.now();
      if (!__privateGet(this, _lastTime)) {
        __privateSet(this, _lastTime, currentTime);
      }
      const dt = (currentTime - __privateGet(this, _lastTime)) / 1e3;
      __privateSet(this, _lastTime, currentTime);
      if (__privateGet(this, _shakeTime) > 0) {
        __privateSet(this, _shakeTime, __privateGet(this, _shakeTime) - dt);
        const magnitude = __privateGet(this, _shakeTime) / __privateGet(this, _shakeTimeMax) * this.options.shakeIntensity;
        const shakeX = randomInt2(-magnitude, magnitude);
        const shakeY = randomInt2(-magnitude, magnitude);
        this.view.dom.style.transform = `translate(${shakeX}px,${shakeY}px)`;
      }
      this.drawParticles();
      requestAnimationFrame(this.loop);
    };
    __privateSet(this, _extension, extension2);
    this.shake = throttle(100, this.shake);
    this.spawnParticles = throttle(100, this.spawnParticles);
  }
  get options() {
    return __privateGet(this, _extension).options;
  }
  /**
   * Store a reference to the Prosemirror view and add the canvas to the DOM
   *
   * @param view
   */
  init(view) {
    this.view = view;
    this.container = this.options.getCanvasContainer();
    const canvas = document.createElement("canvas");
    this.canvas = canvas;
    canvas.id = "epic-mode-canvas";
    canvas.style.position = "absolute";
    canvas.style.top = "0";
    canvas.style.left = "0";
    canvas.style.zIndex = "1";
    canvas.style.pointerEvents = "none";
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("An error occurred while creating the canvas context");
    }
    this.ctx = ctx;
    this.container.append(this.canvas);
    this.loop();
    return this;
  }
  destroy() {
    try {
      this.canvas.remove();
      if (this.container.contains(this.canvas)) {
        this.canvas.remove();
      }
    } catch {
    }
  }
  drawParticles() {
    for (const particle of __privateGet(this, _particles)) {
      if (particle.alpha < 0.01 || particle.size <= 0.5) {
        continue;
      }
      this.options.particleEffect.updateParticle({
        particle,
        ctx: this.ctx,
        canvas: this.canvas
      });
    }
  }
};
_extension = new WeakMap();
_shakeTime = new WeakMap();
_shakeTimeMax = new WeakMap();
_lastTime = new WeakMap();
_particles = new WeakMap();
export {
  COLORS,
  EpicModeExtension,
  defaultEffect,
  heartEffect,
  spawningEffect
};
