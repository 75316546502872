import React, { memo, useMemo } from 'react'
import { filter, forEach, map, some, sortBy } from 'lodash'
import { userService } from '@cotiss/user'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Badge, Icon, ScrollableTable, ScrollableTableColumn, TableHeader, TableRowCta, Text, useCallout } from '@cotiss/common'
import { EvaluationEventScoringAccessUpdateModal, useEvaluationCriteria, useEvaluationUser } from '@cotiss/evaluation-event'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isEditable?: boolean
}

export const EvaluationEventScoringAccessList = memo(({ className, evaluationEnvelope, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationCriteria } = useEvaluationCriteria()

  const { fixedColumns, columns } = useMemo(() => {
    const evaluationUsersInEnvelope = filter(evaluationUsers, ({ accessControls }) => {
      return some(accessControls, { resourceType: 'envelope', resourceId: evaluationEnvelope.id, access: 'evaluate' })
    })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Panel member',
        rows: map(evaluationUsersInEnvelope, (evaluationUser) => ({
          content: () => (
            <Text className="truncate" font="jakarta">
              {userService.getFullName(evaluationUser.user)}
            </Text>
          ),
          cta: isEditable && (
            <TableRowCta
              cta={{
                label: 'Edit access',
                onClick: () =>
                  openModal(<EvaluationEventScoringAccessUpdateModal evaluationUser={evaluationUser} evaluationEnvelope={evaluationEnvelope} />),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = []

    const parentCriteria = filter(evaluationCriteria, { evaluationEnvelopeId: evaluationEnvelope.id, parentEvaluationCriteriaId: null })

    forEach(sortBy(parentCriteria, 'index'), ({ id: criteriaId, content, index }) => {
      columns.push({
        heading: (
          <Text className="normal-case">
            {index}. {content}
          </Text>
        ),
        rows: map(evaluationUsersInEnvelope, ({ accessControls }) => {
          const isScoring = some(accessControls, { resourceType: 'criteria', resourceId: criteriaId, access: 'score' })

          return {
            content: () => (
              <>
                {isScoring && (
                  <Badge className="mr-1" state="outline" variant="success">
                    <Icon className="mr-1" icon="check-circle" /> Scoring
                  </Badge>
                )}
                {!isScoring && (
                  <Badge className="mr-1" state="outline" variant="neutral">
                    <Icon className="mr-1" icon="visible" /> View only
                  </Badge>
                )}
              </>
            ),
          }
        }),
      })
    })

    return { fixedColumns, columns }
  }, [evaluationEnvelope, evaluationUsers, evaluationCriteria])

  return (
    <>
      <TableHeader className={className}>
        <Text size="sm" variant="light">
          Envelope {evaluationEnvelope.order}.
        </Text>
        <Text className="font-semibold" isInline>
          {evaluationEnvelope.name}
        </Text>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} />
    </>
  )
})
