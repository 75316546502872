import {
  Icon,
  ModalContent,
  ModalHeader,
  NoDataPlaceholder,
  ScrollableTable,
  ScrollableTableColumn,
  Skeleton,
  Text,
  datetimeService,
} from '@cotiss/common'
import { TenderFieldWithHistory, tenderService, useGetTender } from '@cotiss/tender'
import { userService } from '@cotiss/user'
import { map } from 'lodash'
import React, { memo, useMemo } from 'react'

type Props = {
  tenderId?: string
  field: TenderFieldWithHistory
}

export const TenderHistoryModal = memo(({ tenderId, field }: Props) => {
  const { tender, isLoading } = useGetTender(tenderId)

  const history = useMemo(() => (tender ? tenderService.getPeriodEndDateHistory({ tender, field }) : []), [tender])

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
          <Skeleton className="bg-primary-200 h-16 w-full" />
        </>
      )
    }

    if (!history.length) {
      return <NoDataPlaceholder label="No changes made" />
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Activity',
        rows: map(history, ({ type, value }) => ({
          content: () => (
            <div className="flex items-center">
              <Icon className="mr-4" variant="secondary" icon={type === 'added' ? 'circle-add' : 'refresh-02'} />
              <div className="w-full truncate">
                <Text>
                  {field === 'tenderPeriod' ? 'Tender' : 'Forum'} close date {type}
                </Text>
                <Text variant="light" size="sm" className="w-full truncate" title={value}>
                  {value ? datetimeService.format(value, 'd MMMM yyyy h:mm aaa') : '--'}
                </Text>
              </div>
            </div>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Actioned by',
        rows: map(history, ({ actionedBy }) => ({
          content: () => (
            <Text className="truncate w-48" title={userService.getFullName(actionedBy)}>
              {actionedBy ? userService.getFullName(actionedBy) : '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Timestamp',
        rows: map(history, ({ timestamp }) => ({
          content: () => <Text>{timestamp ? datetimeService.format(timestamp, 'd MMMM yyyy h:mm aaa') : '--'}</Text>,
        })),
      },
    ]

    return <ScrollableTable fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
  }

  return (
    <>
      <ModalHeader heading="View history" />
      <ModalContent>{renderContent()}</ModalContent>
    </>
  )
})
