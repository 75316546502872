import { useCallout } from '@cotiss/common'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

export const FullModalContainer = () => {
  const { fullModal } = useCallout()
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (fullModal) {
      document.body.style.overflow = 'hidden'
      window.scroll(0, 0)
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [fullModal])

  return (
    <AnimatePresence>
      {Boolean(fullModal) && (
        <motion.div
          className="fixed top-0 left-0 bottom-0 right-0 flex bg-white z-full-modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
          ref={modalRef}
        >
          {fullModal}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
