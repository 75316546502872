import { filter, forEach, groupBy } from 'lodash'
import { EvaluationEventPanelAccessOption } from '@cotiss/evaluation-event'
import {
  GqlCreateUpdateEvaluationUserAccessControlInput,
  GqlEvaluationCriteriaFieldsFragment,
  GqlEvaluationCriteriaRatingScale,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'

type GetWeightByIdParam = {
  items: { id: string; weight: number }[]
}

type GetTotalWeightParam = {
  weightById: Record<string, number>
}

type GetWeightedPercentageByIdParam = {
  weightById: Record<string, number>
  totalWeight: number
  parentWeightedPercentage?: number
}

type GetWeightedPercentagesByIdParam = {
  evaluationEnvelopes: GqlEvaluationEnvelopeFieldsFragment[]
  evaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]
}

type GetAccessControlInputParam = {
  accessByEvaluationEnvelopeId: Record<string, EvaluationEventPanelAccessOption>
  criteriaByEnvelopeId: Record<string, GqlEvaluationCriteriaFieldsFragment[]>
}

type CalculatePercentageScoreParam = {
  score?: number
  ratingScale: GqlEvaluationCriteriaRatingScale
}

class EvaluationEventService {
  getWeightById({ items }: GetWeightByIdParam) {
    const weightById: Record<string, number> = {}

    forEach(items, ({ id, weight }) => {
      weightById[id] = weight
    })

    return weightById
  }

  getTotalWeight({ weightById }: GetTotalWeightParam) {
    let totalWeight = 0

    forEach(weightById, (weight) => {
      totalWeight += weight || 0
    })

    return totalWeight
  }

  getWeightedPercentageById({ weightById, totalWeight, parentWeightedPercentage = 1 }: GetWeightedPercentageByIdParam) {
    const weightedPercentageById: Record<string, number> = {}

    forEach(weightById, (weight, id) => {
      weightedPercentageById[id] = (weight / totalWeight) * parentWeightedPercentage
    })

    return weightedPercentageById
  }

  // The logic for how these values are calculated can be found here: https://www.notion.so/cotiss/Evaluation-percentage-weighting-decisions-39384159c4dc4585afb7bd0e4aa36093
  getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria }: GetWeightedPercentagesByIdParam) {
    const envelopeWeightById = this.getWeightById({ items: evaluationEnvelopes })
    const totalEnvelopeWeight = this.getTotalWeight({ weightById: envelopeWeightById })
    let envelopeWeightedPercentageById: Record<string, number> = {}
    let weightedPercentageById = this.getWeightedPercentageById({ weightById: envelopeWeightById, totalWeight: totalEnvelopeWeight })
    let overallWeightedPercentageById: Record<string, number> = { ...weightedPercentageById }

    const parentCriteriaByEnvelope = groupBy(filter(evaluationCriteria, { parentEvaluationCriteriaId: null }), 'evaluationEnvelopeId')

    forEach(parentCriteriaByEnvelope, (parentCriteria, envelopeId) => {
      const envelopeWeightedPercentage = overallWeightedPercentageById[envelopeId]
      const parentCriteriaWeightById = this.getWeightById({ items: parentCriteria })
      const totalParentCriteriaWeight = this.getTotalWeight({ weightById: parentCriteriaWeightById })

      weightedPercentageById = {
        ...weightedPercentageById,
        ...this.getWeightedPercentageById({
          weightById: parentCriteriaWeightById,
          totalWeight: totalParentCriteriaWeight,
        }),
      }

      overallWeightedPercentageById = {
        ...overallWeightedPercentageById,
        ...this.getWeightedPercentageById({
          weightById: parentCriteriaWeightById,
          totalWeight: totalParentCriteriaWeight,
          parentWeightedPercentage: envelopeWeightedPercentage,
        }),
      }

      forEach(parentCriteria, (parentCriteriaItem) => {
        const parentCriteriaWeightedPercentage = weightedPercentageById[parentCriteriaItem.id]
        const subCriteria = filter(evaluationCriteria, { parentEvaluationCriteriaId: parentCriteriaItem.id })
        const subCriteriaWeightById = this.getWeightById({ items: subCriteria })
        const totalSubCriteriaWeight = this.getTotalWeight({ weightById: subCriteriaWeightById })

        weightedPercentageById = {
          ...weightedPercentageById,
          ...this.getWeightedPercentageById({
            weightById: subCriteriaWeightById,
            totalWeight: totalSubCriteriaWeight,
          }),
        }

        envelopeWeightedPercentageById = {
          ...envelopeWeightedPercentageById,
          ...this.getWeightedPercentageById({
            weightById: subCriteriaWeightById,
            totalWeight: totalSubCriteriaWeight,
            parentWeightedPercentage: parentCriteriaWeightedPercentage,
          }),
        }

        overallWeightedPercentageById = {
          ...overallWeightedPercentageById,
          ...this.getWeightedPercentageById({
            weightById: subCriteriaWeightById,
            totalWeight: totalSubCriteriaWeight,
            parentWeightedPercentage: envelopeWeightedPercentage * parentCriteriaWeightedPercentage,
          }),
        }
      })
    })

    return {
      weightedPercentageById,
      envelopeWeightedPercentageById,
      overallWeightedPercentageById,
    }
  }

  getAccessControlInput({ accessByEvaluationEnvelopeId, criteriaByEnvelopeId }: GetAccessControlInputParam) {
    const accessControls: GqlCreateUpdateEvaluationUserAccessControlInput[] = []

    forEach(accessByEvaluationEnvelopeId, (access, envelopeId) => {
      // Filter out sub criteria as access controls are only needed for parent criteria.
      const envelopeCriteria = filter(criteriaByEnvelopeId[envelopeId], { parentEvaluationCriteriaId: null })

      if (access === 'moderate') {
        accessControls.push({ resourceType: 'envelope', resourceId: envelopeId, access: 'moderate' })
      }

      if (access === 'evaluate') {
        accessControls.push({ resourceType: 'envelope', resourceId: envelopeId, access: 'evaluate' })
        forEach(envelopeCriteria, ({ id: criteriaId }) => {
          accessControls.push({ resourceType: 'criteria', resourceId: criteriaId, access: 'score' })
        })
      }

      if (access === 'both') {
        accessControls.push({ resourceType: 'envelope', resourceId: envelopeId, access: 'evaluate' })
        accessControls.push({ resourceType: 'envelope', resourceId: envelopeId, access: 'moderate' })
        forEach(envelopeCriteria, ({ id: criteriaId }) => {
          accessControls.push({ resourceType: 'criteria', resourceId: criteriaId, access: 'score' })
        })
      }
    })

    return accessControls
  }

  calculatePercentageScore({ score = 0, ratingScale }: CalculatePercentageScoreParam) {
    switch (ratingScale) {
      case 'zeroToFive':
        return score / 5
      case 'zeroToTen':
        return score / 10
      case 'percentage':
        return score / 100
      default:
        return score
    }
  }
}

export const evaluationEventService = new EvaluationEventService()
