import { generatePath } from 'react-router-dom'
import { ExtractRouteParams } from 'react-router'
import { AUTH_ROUTES } from '@cotiss/auth'
import { TASK_ROUTES } from '@cotiss/task'
import { BUYER_ROUTES } from '@cotiss/buyer'
import { PROJECT_ROUTES } from '@cotiss/project'
import { DOCUMENT_ROUTES } from '@cotiss/document'
import { SETTINGS_ROUTES } from '@cotiss/settings'
import { SUPPLIER_ROUTES } from '@cotiss/supplier'
import { APPROVAL_ROUTES } from '@cotiss/approval'
import { CONTRACT_ROUTES } from '@cotiss/contract'
import { EVALUATION_ROUTES } from '@cotiss/evaluation'
import { PLAN_EVENT_ROUTES } from '@cotiss/plan-event'
import { PROCUREMENT_ROUTES } from '@cotiss/procurement'
import { TENDER_FLOW_ROUTES } from '@cotiss/tender-flow'
import { PERFORMANCE_ROUTES } from '@cotiss/performance'
import { EVALUATION_EVENT_ROUTES } from '@cotiss/evaluation-event'
import { TENDER_INVITATION_ROUTES } from '@cotiss/tender-invitation'
import { PREFERRED_SUPPLIER_ROUTES } from '@cotiss/preferred-supplier'
import { CONFLICT_OF_INTEREST_ROUTES } from '@cotiss/conflict-of-interest'
import { PROCUREMENT_RESPONSE_ROUTES } from '@cotiss/procurement-response'

export type RouterPath = '/' | '/error' | (typeof ROUTES)[number]
export const ROUTES = [
  ...APPROVAL_ROUTES,
  ...AUTH_ROUTES,
  ...BUYER_ROUTES,
  ...CONFLICT_OF_INTEREST_ROUTES,
  ...CONTRACT_ROUTES,
  ...DOCUMENT_ROUTES,
  ...EVALUATION_EVENT_ROUTES,
  ...EVALUATION_ROUTES,
  ...PERFORMANCE_ROUTES,
  ...PLAN_EVENT_ROUTES,
  ...PREFERRED_SUPPLIER_ROUTES,
  ...PROCUREMENT_RESPONSE_ROUTES,
  ...PROCUREMENT_ROUTES,
  ...PROJECT_ROUTES,
  ...SETTINGS_ROUTES,
  ...SUPPLIER_ROUTES,
  ...TASK_ROUTES,
  ...TENDER_FLOW_ROUTES,
  ...TENDER_INVITATION_ROUTES,
] as const

class RouterService {
  getHref<S extends RouterPath>(path: S, params?: ExtractRouteParams<S>) {
    // Wrapping this in a try catch, because generatePath will throw an error if the params don't contain values to generate the path from. If this
    // error occurs, we will just default to the root route.
    try {
      return generatePath<S>(path, params)
    } catch {
      return '/'
    }
  }
}

export const routerService = new RouterService()
