import React, { memo, useState } from 'react'
import { SupplierViewDrawer } from '@cotiss/supplier'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { Button, Dropdown, DropdownContent, Icon, useCallout } from '@cotiss/common'

type Props = {
  tenderResponseReport: TenderResponseReportModel
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const TenderResponseReportPriceListCta = memo(({ tenderResponseReport, onView }: Props) => {
  const { openNarrowDrawer } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className="flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openNarrowDrawer(
              <SupplierViewDrawer
                organisationId={tenderResponseReport.procurementResponse.supplier._id}
                procurementResponseId={tenderResponseReport.procurementResponse._id}
                tenderResponseId={tenderResponseReport._id}
              />
            )
          }
        >
          View Supplier
        </DropdownContent>
      </Dropdown>
      <Button onClick={() => onView && onView(tenderResponseReport)} isDisabled={!onView} state="outline" variant="secondary" size="xs" isLink>
        View <Icon className="ml-1" icon="arrow-right" />
      </Button>
    </div>
  )
})
