import { map } from 'lodash'
import { OcdsCurrencyCode } from '@cotiss/common'

export const OCDS_CURRENCY_MAP: Record<OcdsCurrencyCode, string> = {
  NZD: 'NZD (New Zealand Dollar)',
  AUD: 'AUD (Australian Dollar)',
  USD: 'USD (United States Dollar)',
  GBP: 'GBP (British Pound)',
  EUR: 'EUR (Euro)',
}

export const OCDS_CURRENCY_OPTIONS = map(OCDS_CURRENCY_MAP, (value, key) => ({ value: key as OcdsCurrencyCode, label: value }))
