var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-whitespace/src/whitespace-extension.ts
import {
  command,
  extension,
  getChangedRanges,
  getDocRange,
  helper,
  isEmptyObject,
  isString,
  PlainExtension
} from "@remirror/core";
import { DecorationSet as DecorationSet2 } from "@remirror/pm/view";

// packages/remirror__extension-whitespace/src/whitespace-utils.ts
import {
  isNodeOfType,
  textBetween
} from "@remirror/core";
import { ExtensionWhitespaceMessages } from "@remirror/messages";
import { Decoration, DecorationSet } from "@remirror/pm/view";
function generateDecorations(props) {
  const { from, to, doc, decorators } = props;
  let { decorationSet = DecorationSet.empty } = props;
  for (const decorator of decorators) {
    decorationSet = decorator({ decorationSet, doc, from, to });
  }
  return decorationSet;
}
function createWidget(pos, key) {
  const span = document.createElement("span");
  span.classList.add("whitespace", `whitespace--${key}`);
  return Decoration.widget(pos, span, {
    marks: [],
    key
  });
}
function createNodeBuilder(builderOptions) {
  return (details) => {
    const { calculatePosition, key, predicate } = builderOptions;
    const { decorationSet, doc, from, to } = details;
    const added = [];
    const removed = [];
    doc.nodesBetween(from, to, (node, pos) => {
      if (predicate(node)) {
        const widgetPos = calculatePosition({ node, pos });
        added.push(createWidget(widgetPos, key));
        removed.push(...decorationSet.find(widgetPos, widgetPos, (spec) => spec.key === key));
      }
    });
    return decorationSet.remove(removed).add(doc, added);
  };
}
function createCharacterBuilder(builderOptions) {
  return (details) => {
    const { key, predicate } = builderOptions;
    const { decorationSet, doc, from, to } = details;
    const textRanges = textBetween({ from, to, doc });
    const decorations = [];
    for (const { pos, text } of textRanges) {
      for (const [index, char] of [...text].entries()) {
        if (!predicate(char)) {
          continue;
        }
        decorations.push(createWidget(pos + index, key));
      }
    }
    return decorationSet.add(doc, decorations);
  };
}
function createDefaultWhitespaceDecorators(settings) {
  const {
    breakNodes = ["hardBreak"],
    paragraphNodes = ["paragraph"],
    spaceCharacters = [" "]
  } = settings;
  return {
    // Characters for the hard break character.
    hardBreak: createNodeBuilder({
      calculatePosition: ({ pos }) => pos,
      key: "br",
      predicate: (node) => isNodeOfType({ node, types: breakNodes })
    }),
    // Create decorations for paragraphs
    paragraph: createNodeBuilder({
      key: "p",
      calculatePosition: ({ node, pos }) => pos + node.nodeSize - 1,
      predicate: (node) => isNodeOfType({ node, types: paragraphNodes })
    }),
    // Create character decorations for space characters
    space: createCharacterBuilder({
      key: "s",
      predicate: (char) => spaceCharacters.includes(char)
    })
  };
}
var toggleWhitespaceOptions = {
  icon: "paragraph",
  description: ({ t }) => t(ExtensionWhitespaceMessages.DESCRIPTION),
  label: ({ t }) => t(ExtensionWhitespaceMessages.LABEL)
};

// packages/remirror__extension-whitespace/src/whitespace-extension.ts
var WhitespaceExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    this.active = this.options.initialVisibility;
    /**
     * Set this to true to force updates to the decorationSet even if the editor
     * doc hasn't been changed. This is set to true when running commands.
     */
    this.forcedUpdate = false;
    /**
     * The white space decorations to be applied.
     */
    this.decorationSet = DecorationSet2.empty;
    /**
     * The decorator methods which are used to produce the whitespace characters
     * in for the provided ranges.
     */
    this.decorators = [];
  }
  get name() {
    return "whitespace";
  }
  // Setup the initial decorators.
  onCreate() {
    this.updateDecorators();
  }
  /**
   * Create the initial decoration state.
   */
  onInitState(state) {
    if (this.active) {
      this.decorationSet = this.createFullDecorationSet(state.doc);
    }
  }
  /**
   * Update the whitespace decorations for each state update.
   */
  onApplyState(props) {
    const { tr } = props;
    if (!tr.docChanged && !this.forcedUpdate) {
      return;
    }
    if (this.forcedUpdate) {
      this.forcedUpdate = false;
      this.decorationSet = this.active ? this.createFullDecorationSet(tr.doc) : DecorationSet2.empty;
      return;
    }
    const changedRanges = getChangedRanges(tr);
    this.decorationSet = this.decorationSet.map(tr.mapping, tr.doc);
    for (const { from, to } of changedRanges) {
      this.decorationSet = generateDecorations({
        from,
        to,
        doc: tr.doc,
        decorationSet: this.decorationSet,
        decorators: this.decorators
      });
    }
  }
  createDecorations() {
    return this.decorationSet;
  }
  /**
   * When the decorators are updated we should update trigger an update to the
   * editor state.
   */
  onSetOptions(props) {
    const { pickChanged } = props;
    const allUpdates = pickChanged([
      "breakNodes",
      "decorators",
      "paragraphNodes",
      "spaceCharacters"
    ]);
    if (isEmptyObject(allUpdates)) {
      return;
    }
    this.updateDecorators();
    this.store.commands.emptyUpdate(() => {
      this.forcedUpdate = true;
    });
  }
  /**
   * Generate the whitespace decorations for the full .
   */
  createFullDecorationSet(doc) {
    const { from, to } = getDocRange(doc);
    return generateDecorations({ from, to, doc, decorators: this.decorators });
  }
  /**
   * Create the decorators array.
   */
  updateDecorators() {
    const decorators = [];
    const { breakNodes, paragraphNodes, spaceCharacters } = this.options;
    const defaultDecorators = createDefaultWhitespaceDecorators({
      breakNodes,
      paragraphNodes,
      spaceCharacters
    });
    for (const decorator of this.options.decorators) {
      decorators.push(isString(decorator) ? defaultDecorators[decorator] : decorator);
    }
    this.decorators = decorators;
  }
  toggleWhitespace() {
    return (props) => {
      return this.store.commands.emptyUpdate.original(() => {
        this.forcedUpdate = true;
        this.active = !this.active;
      })(props);
    };
  }
  showWhitespace() {
    return (props) => {
      if (this.active) {
        return false;
      }
      return this.store.commands.emptyUpdate.original(() => {
        this.forcedUpdate = true;
        this.active = true;
      })(props);
    };
  }
  hideWhitespace() {
    return (props) => {
      if (!this.active) {
        return false;
      }
      return this.store.commands.emptyUpdate.original(() => {
        this.forcedUpdate = true;
        this.active = false;
      })(props);
    };
  }
  isWhitespaceVisible() {
    return this.active;
  }
};
__decorateClass([
  command(toggleWhitespaceOptions)
], WhitespaceExtension.prototype, "toggleWhitespace", 1);
__decorateClass([
  command()
], WhitespaceExtension.prototype, "showWhitespace", 1);
__decorateClass([
  command()
], WhitespaceExtension.prototype, "hideWhitespace", 1);
__decorateClass([
  helper()
], WhitespaceExtension.prototype, "isWhitespaceVisible", 1);
WhitespaceExtension = __decorateClass([
  extension({
    defaultOptions: {
      initialVisibility: false,
      breakNodes: ["hardBreak"],
      paragraphNodes: ["paragraph"],
      spaceCharacters: [" "],
      decorators: ["hardBreak", "paragraph", "space"]
    },
    staticKeys: ["initialVisibility"]
  })
], WhitespaceExtension);
export {
  WhitespaceExtension
};
