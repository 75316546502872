import classNames from 'classnames'
import React, { ChangeEvent, forwardRef, FocusEvent } from 'react'

export type InputProps = {
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  type?: 'email' | 'number' | 'password' | 'text'
  min?: number
  max?: number
  autoComplete?: 'current-password' | 'email' | 'username' | 'given-name' | 'family-name' | 'new-password' | 'off'
  size?: 'sm' | 'md' | 'lg'
  state?: 'default' | 'ghost'
  placeholder?: string
  name?: string
  minLength?: number
  maxLength?: number
  value?: string | number
  pattern?: string
  title?: string
  autoFocus?: boolean
  step?: string | number
  ariaLabel?: string
  defaultValue?: string | number
  isReadOnly?: boolean
  isFull?: boolean
  isError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    size = 'md',
    state = 'default',
    type = 'text',
    ariaLabel,
    isError,
    isRequired,
    isFull = true,
    isDisabled,
    isReadOnly,
    ...rest
  } = props
  let classes = classNames(
    className,
    'block bg-white text-primary-500 placeholder-gray-400 rounded-lg focus:shadow-none focus:outline-none focus:ring-2',
    {
      'cotiss-input--password': type === 'password',
      'text-sm px-2 py-0.5': size === 'sm',
      'h-11 px-3 py-2': size === 'md',
      'px-4 py-4': size === 'lg',
      'pointer-events-none opacity-50': isDisabled,
      'w-full': isFull,
    }
  )

  if (state === 'default') {
    classes = classNames(classes, 'border border-gray-300', {
      'focus:border-secondary-300 focus:ring-secondary-200': !isError,
      'border-red-400 focus:border-red-300 focus:ring-red-200': isError,
    })
  }

  return (
    <input
      {...rest}
      readOnly={isReadOnly}
      className={classes}
      type={type}
      ref={ref}
      disabled={isDisabled}
      required={isRequired}
      aria-label={ariaLabel}
    />
  )
})
