import { map } from 'lodash'
import React, { memo } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { BuyerProcurementItem } from '@cotiss/buyer'
import { useListPublicProcurement } from '@cotiss/procurement'
import { NoDataPlaceholder } from '@cotiss/common'

export const BuyerViewOpenListingsTab = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { publicProcurements } = useListPublicProcurement({ organisationId: id, open: true })

  return (
    <>
      {!publicProcurements.length && <NoDataPlaceholder label="No public listings" />}
      {Boolean(publicProcurements.length) && (
        <div>
          {map(publicProcurements, (publicProcurement, index) => (
            <BuyerProcurementItem
              key={publicProcurement.tender._id}
              className={classNames({ 'mt-4': index })}
              publicProcurement={publicProcurement}
            />
          ))}
        </div>
      )}
    </>
  )
})
