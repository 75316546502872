import React, { FormEvent, memo, useState } from 'react'
import { useMutateOrganisation } from '@cotiss/organisation'
import { useGetLoggedInUser, useUserAccess } from '@cotiss/user'
import { Button, Form, Input, Label, Text, sentryService, useToast } from '@cotiss/common'

export const SettingsOrganisationFormName = memo(() => {
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { user } = useGetLoggedInUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateOrganisation } = useMutateOrganisation()
  const [formData, setFormData] = useState({ name: user?.account.organisation?.name || '' })

  const handleReset = () => {
    setFormData({ name: user?.account.organisation?.name || '' })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!user?.account.organisation?._id) {
      return
    }

    try {
      setIsSaving(true)
      await updateOrganisation(user?.account.organisation?._id, formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>Organisation name</Label>
        <Input
          className="mb-4"
          value={formData.name}
          onChange={({ target }) => setFormData({ ...formData, name: target.value })}
          placeholder="Enter organisation name"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Organisation name</Text>
        <Text>{user?.account.organisation?.name || '–'}</Text>
      </div>

      {permissions.isAdmin && (
        <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
          Update
        </Button>
      )}
    </div>
  )
})
