import { useMutate, utilService } from '@cotiss/common'
import {
  AccountBuyerSubscription,
  AccountEmployeeNumberRange,
  AccountLocationModel,
  AccountSupplierSubscription,
  AccountTenderExperience,
} from '@cotiss/account'
import { OrganisationCountryCodeCombined, OrganisationEntityType } from '@cotiss/organisation'

export type CreateAccountBody = {
  name: string
  businessNumber?: string
  taxNumber?: string
  entityType?: OrganisationEntityType
  countryCode?: OrganisationCountryCodeCombined
  firstname: string
  surname: string
  email: string
  phone: string
  location?: {
    country?: string
    address?: string
    city?: string
    region?: string
    postcode?: string
  }
  regions?: string[]
  industry?: string
  employeeNoRange?: AccountEmployeeNumberRange
  experience?: AccountTenderExperience
  password: string
  productSupplier?: AccountSupplierSubscription
  productBuyer?: AccountBuyerSubscription
}

type CreateAccountResponse = {
  accessToken: string
  refreshToken: string
  customerId: string
}

type UpdateAccountBody = {
  location?: AccountLocationModel
  employeeNoRange?: AccountEmployeeNumberRange
}

export const useMutateAccount = () => {
  const { mutate } = useMutate()

  return {
    createAccount: async (body: CreateAccountBody, validateBusiness?: boolean) => {
      const queryRoute = `/v2/accounts${utilService.generateUrlSearchParams({ validateBusiness: Boolean(validateBusiness) })}`
      return await mutate<CreateAccountResponse>({ route: queryRoute, body, invalidate: ['/v2/users/me'] })
    },
    updateAccount: async (id: string, body: UpdateAccountBody) => {
      return await mutate({ route: `/v2/accounts/${id}`, body, invalidate: ['/v2/accounts', '/v2/users/me'] })
    },
    deleteAccount: async () => {
      return await mutate({ route: `/v2/accounts`, method: 'DELETE', invalidate: ['/v2/accounts', '/v2/users/me'] })
    },
  }
}
