import React, { memo } from 'react'
import { Badge, BadgeSize, BaseVariant } from '@cotiss/common'
import { TenderType, TENDER_TYPE_ABBREVIATION_MAP } from '@cotiss/tender'

const getVariant = (tenderType: TenderType): BaseVariant => {
  switch (tenderType) {
    case 'request_for_proposal':
      return 'warning'
    case 'request_for_quotation':
      return 'info'
    case 'invitation_to_qualify':
      return 'info'
    case 'expression_of_interest':
      return 'success'
    case 'request_for_information':
      return 'danger'
    case 'future_procurement_opportunity':
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  tenderType: TenderType
  size?: BadgeSize
}

export const TenderTypeBadge = memo(({ className, tenderType, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(tenderType)} state="translucent" shape="square">
    {TENDER_TYPE_ABBREVIATION_MAP[tenderType]}
  </Badge>
))
