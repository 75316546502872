import { FourOhThreePage } from '@cotiss/common'
import { useGetTender } from '@cotiss/tender'
import { TenderFlowEvaluateStepPanel, TenderFlowEvaluateStepSelf, tenderFlowService } from '@cotiss/tender-flow'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'

export const TenderFlowEvaluateStep = memo(() => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  return tender?.evaluationPanelType === 'self' ? <TenderFlowEvaluateStepSelf /> : <TenderFlowEvaluateStepPanel />
})
