import { EVALUATION_CHAIR_STEPS, EvaluationChairStep, EvaluationModel, EvaluationStatus } from '@cotiss/evaluation'
import { TenderPopulatedModel } from '@cotiss/tender'
import { every, indexOf } from 'lodash'

class EvaluationService {
  getActiveChairStep = (tender: TenderPopulatedModel): EvaluationChairStep => {
    switch (tender.status) {
      case 'consensus':
        return 'consensus-scoring'
      case 'price':
      case 'selection':
      case 'selection-approval':
      case 'report':
      case 'complete':
        return 'price-scoring'
      default:
        return 'individual-evaluation'
    }
  }

  getUnlockedChairStepIndex = (tender: TenderPopulatedModel) => {
    const activeStep = this.getActiveChairStep(tender)

    return indexOf(EVALUATION_CHAIR_STEPS, activeStep) + 1
  }

  getGroupedEvaluationStatus = (evaluations: EvaluationModel[]): EvaluationStatus => {
    if (every(evaluations, (evaluation) => evaluation.status === 'abstained')) {
      return 'abstained'
    } else if (every(evaluations, (evaluation) => evaluation.status === 'completed')) {
      return 'completed'
    } else {
      return 'inProgress'
    }
  }
}

export const evaluationService = new EvaluationService()
