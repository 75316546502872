var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-diff/src/diff-extension.ts
import {
  command,
  extension,
  hasTransactionChanged,
  helper,
  invariant,
  isDomNode,
  isEmptyArray as isEmptyArray2,
  isEqual,
  isNumber,
  isString,
  PlainExtension
} from "@remirror/core";
import { Mapping } from "@remirror/pm/transform";
import { Decoration, DecorationSet } from "@remirror/pm/view";

// packages/remirror__extension-diff/src/diff-utils.ts
import { assertGet, isEmptyArray } from "@remirror/core";
var Span = class {
  constructor(props) {
    const { from, to, commit } = props;
    this.from = from;
    this.to = to;
    this.commit = commit;
  }
};
var Commit = class {
  constructor(props) {
    const { message, time, steps, maps, hidden } = props;
    this.message = message;
    this.time = time;
    this.steps = steps;
    this.maps = maps;
    this.hidden = hidden;
  }
};
var TrackState = class {
  constructor(props) {
    const { blameMap, commits, uncommittedSteps, uncommittedMaps } = props;
    this.blameMap = blameMap;
    this.commits = commits;
    this.uncommittedSteps = uncommittedSteps;
    this.uncommittedMaps = uncommittedMaps;
  }
  /**
   * Apply a transform to this state.
   */
  applyTransform(transform) {
    const inverted = transform.steps.map(
      (step, index) => step.invert(assertGet(transform.docs, index))
    );
    const newBlame = updateBlameMap({ map: this.blameMap, transform, id: this.commits.length });
    return new TrackState({
      blameMap: newBlame,
      commits: this.commits,
      uncommittedSteps: [...this.uncommittedSteps, ...inverted],
      uncommittedMaps: [...this.uncommittedMaps, ...transform.mapping.maps]
    });
  }
  /**
   * When a transaction is marked as a commit, this is used to put any
   * uncommitted steps into a new commit.
   */
  applyCommit(message, time) {
    if (isEmptyArray(this.uncommittedSteps)) {
      return this;
    }
    const commit = new Commit({
      message,
      time,
      steps: this.uncommittedSteps,
      maps: this.uncommittedMaps
    });
    return new TrackState({
      blameMap: this.blameMap,
      commits: [...this.commits, commit],
      uncommittedSteps: [],
      uncommittedMaps: []
    });
  }
};
function updateBlameMap({ map, transform, id }) {
  const result = [];
  const mapping = transform.mapping;
  for (const span of map) {
    const from = mapping.map(span.from, 1);
    const to = mapping.map(span.to, -1);
    if (from < to) {
      result.push(new Span({ from, to, commit: span.commit }));
    }
  }
  for (const [index, map2] of mapping.maps.entries()) {
    const after = mapping.slice(index + 1);
    map2.forEach((_s, _e, start, end) => {
      insertIntoBlameMap({
        map: result,
        from: after.map(start, 1),
        to: after.map(end, -1),
        commit: id
      });
    });
  }
  return result;
}
function insertIntoBlameMap(props) {
  let { from, to, map, commit } = props;
  if (from >= to) {
    return;
  }
  let pos = 0;
  let next;
  for (; pos < map.length; pos++) {
    next = assertGet(map, pos);
    if (next.commit === commit) {
      if (next.to >= from) {
        break;
      }
      continue;
    }
    if (next.to <= from) {
      continue;
    }
    if (next.from < from) {
      const left = new Span({ from: next.from, to: from, commit: next.commit });
      if (next.to > to) {
        map.splice(pos++, 0, left);
      } else {
        map[pos++] = left;
      }
    }
    break;
  }
  while (next = map[pos]) {
    if (next.commit === commit) {
      if (next.from > to) {
        break;
      }
      from = Math.min(from, next.from);
      to = Math.max(to, next.to);
      map.splice(pos, 1);
      continue;
    }
    if (next.from >= to) {
      break;
    }
    if (next.to > to) {
      map[pos] = new Span({ from: to, to: next.to, commit: next.commit });
      break;
    }
    map.splice(pos, 1);
  }
  map.splice(pos, 0, new Span({ from, to, commit }));
}

// packages/remirror__extension-diff/src/diff-extension.ts
var DiffExtension = class extends PlainExtension {
  get name() {
    return "diff";
  }
  /**
   * Create the custom change tracking plugin.
   *
   * This has been adapted from the prosemirror website demo.
   * https://github.com/ProseMirror/website/blob/master/example/track/index.js
   */
  createPlugin() {
    return {
      state: {
        init: (_, state) => {
          return this.createInitialState(state);
        },
        apply: (tr, pluginState, _, state) => {
          const newState = this.applyStateUpdates(tr, pluginState, state);
          this.handleSelection(tr, newState);
          return newState;
        }
      },
      props: {
        decorations: (state) => {
          return this.getPluginState(state).decorations;
        },
        handleDOMEvents: {
          mouseover: (view, event) => {
            return this.handlerMouseOver(view, event);
          },
          mouseleave: (view, event) => {
            return this.handleMouseLeave(view, event);
          }
        }
      }
    };
  }
  highlightCommit(commit) {
    return (props) => {
      const { tr, dispatch } = props;
      if (isString(commit)) {
        commit = this.getIndexByName(commit);
      }
      if (!isNumber(commit)) {
        commit = this.getCommitId(commit);
      }
      if (dispatch) {
        dispatch(this.setMeta(tr, { add: commit }));
      }
      return true;
    };
  }
  removeHighlightedCommit(commit) {
    return (props) => {
      const { tr, dispatch } = props;
      if (isString(commit)) {
        commit = this.getIndexByName(commit);
      }
      if (!isNumber(commit)) {
        commit = this.getCommitId(commit);
      }
      if (dispatch) {
        dispatch(this.setMeta(tr, { clear: commit }));
      }
      return true;
    };
  }
  commitChange(message) {
    return (props) => {
      const { tr, dispatch } = props;
      if (dispatch) {
        dispatch(this.setMeta(tr, { message }));
      }
      return true;
    };
  }
  revertCommit(commit) {
    return (props) => {
      var _a;
      const { state, tr, dispatch } = props;
      if (!commit) {
        commit = this.getCommit("last");
      }
      const { tracked } = this.getPluginState(state);
      const index = tracked.commits.indexOf(commit);
      if (index === -1) {
        return false;
      }
      if (!isEmptyArray2(tracked.uncommittedSteps)) {
        return false;
      }
      if (!dispatch) {
        return true;
      }
      const commitMaps = [];
      for (const commit2 of tracked.commits.slice(index)) {
        commitMaps.push(...commit2.maps);
      }
      const remap = new Mapping(commitMaps);
      for (let index2 = commit.steps.length - 1; index2 >= 0; index2--) {
        const remapped = (_a = commit.steps[index2]) == null ? void 0 : _a.map(remap.slice(index2 + 1));
        if (!remapped) {
          continue;
        }
        const result = tr.maybeStep(remapped);
        if (result.doc) {
          remap.appendMap(remapped.getMap(), index2);
        }
      }
      if (tr.docChanged) {
        this.setMeta(tr, { message: this.options.revertMessage(commit.message) });
        dispatch(tr);
      }
      return true;
    };
  }
  getCommits() {
    return this.getPluginState().tracked.commits;
  }
  getIndexByName(name) {
    const length = this.getPluginState().tracked.commits.length;
    switch (name) {
      case "first":
        return 0;
      default:
        return length - 1;
    }
  }
  getCommit(id) {
    const commits = this.getPluginState().tracked.commits;
    const commit = isString(id) ? commits[this.getIndexByName(id)] : commits[id];
    invariant(commit, {});
    return commit;
  }
  getCommitId(commit) {
    const { tracked } = this.getPluginState();
    return tracked.commits.indexOf(commit);
  }
  /**
   * Get the meta data for this custom plugin.
   */
  getMeta(tr) {
    return tr.getMeta(this.pluginKey) ?? {};
  }
  /**
   * Set the meta data for the plugin.
   */
  setMeta(tr, meta) {
    tr.setMeta(this.pluginKey, { ...this.getMeta(tr), ...meta });
    return tr;
  }
  /**
   * Calls the selection handlers when the selection changes the number of
   * commit spans covered.
   */
  handleSelection(tr, pluginState) {
    if (!hasTransactionChanged(tr)) {
      return;
    }
    const { from, to } = tr.selection;
    const { blameMap, commits } = pluginState.tracked;
    const selections = [];
    for (const map of blameMap) {
      const selectionIncludesSpan = map.from <= from && map.to >= from || map.from <= to && map.to >= to;
      if (!selectionIncludesSpan || !isNumber(map.commit) || map.commit >= commits.length) {
        continue;
      }
      selections.push({ commit: this.getCommit(map.commit), from: map.from, to: map.to });
    }
    const selectionHasCommit = selections.length > 0;
    if (selectionHasCommit && !isEqual(selections, this.selections)) {
      this.options.onSelectCommits(selections, this.selections);
      this.selections = selections;
      return;
    }
    if (this.selections) {
      this.options.onDeselectCommits(this.selections);
      this.selections = void 0;
    }
  }
  /**
   * Transform the view and event into a commit and span.
   */
  getHandlerPropsFromEvent(view, event) {
    if (!isDomNode(event.target)) {
      return;
    }
    const pos = view.posAtDOM(event.target, 0);
    const { tracked } = this.getPluginState();
    const span = tracked.blameMap.find((map) => map.from <= pos && map.to >= pos);
    if (!span || !isNumber(span.commit)) {
      return;
    }
    return { commit: this.getCommit(span.commit), from: span.from, to: span.to };
  }
  /**
   * Capture the mouseover event and trigger the `onMouseOverCommit` handler
   * when it is captured.
   */
  handlerMouseOver(view, event) {
    const props = this.getHandlerPropsFromEvent(view, event);
    if (props) {
      this.hovered = props;
      this.options.onMouseOverCommit(props);
    }
    return false;
  }
  /**
   * Capture the mouseleave event and trigger the `onMouseLeaveCommit` handler.
   */
  handleMouseLeave(view, event) {
    if (!this.hovered) {
      return false;
    }
    const commit = this.getHandlerPropsFromEvent(view, event);
    if (commit) {
      this.hovered = void 0;
      this.options.onMouseLeaveCommit(commit);
    }
    return false;
  }
  /**
   * Create the initial plugin state for the custom plugin.
   */
  createInitialState(state) {
    return {
      tracked: new TrackState({
        blameMap: [new Span({ from: 0, to: state.doc.content.size, commit: void 0 })],
        commits: [],
        uncommittedMaps: [],
        uncommittedSteps: []
      }),
      decorations: DecorationSet.empty
    };
  }
  /**
   * Apply state updates in response to document changes.
   */
  applyStateUpdates(tr, pluginState, state) {
    return {
      ...this.updateTracked(tr, pluginState),
      ...this.updateHighlights(tr, pluginState, state)
    };
  }
  createDecorationSet(commits, pluginState, state) {
    const { tracked } = pluginState;
    const decorations = [];
    for (const { commit, from, to } of tracked.blameMap) {
      if (!isNumber(commit) || !commits.includes(commit)) {
        continue;
      }
      decorations.push(Decoration.inline(from, to, { class: this.options.blameMarkerClass }));
    }
    return DecorationSet.create(state.doc, decorations);
  }
  /**
   * Apply updates to the highlight decorations.
   */
  updateHighlights(tr, pluginState, state) {
    const { add, clear } = this.getMeta(tr);
    if (isNumber(add) && pluginState.commits && !pluginState.commits.includes(add)) {
      const commits = [...pluginState.commits, add];
      const decorations = this.createDecorationSet(commits, pluginState, state);
      return { decorations, commits };
    }
    if (isNumber(clear) && pluginState.commits && pluginState.commits.includes(clear)) {
      const commits = pluginState.commits.filter((commit) => commit !== clear);
      const decorations = this.createDecorationSet(commits, pluginState, state);
      return { decorations, commits };
    }
    if (tr.docChanged && !isEmptyArray2(pluginState.commits)) {
      return {
        decorations: pluginState.decorations.map(tr.mapping, tr.doc),
        commits: pluginState.commits
      };
    }
    return { decorations: pluginState.decorations, commits: pluginState.commits ?? [] };
  }
  /**
   * Apply updates for the commit tracker.
   *
   * Please note this isn't able to track marks and diffs. It can only
   * track changes to content.
   */
  updateTracked(tr, state) {
    let { tracked } = state;
    if (tr.docChanged) {
      tracked = tracked.applyTransform(tr);
    }
    const { message } = this.getMeta(tr);
    if (message) {
      tracked = tracked.applyCommit(message, tr.time);
    }
    return { tracked };
  }
};
__decorateClass([
  command()
], DiffExtension.prototype, "highlightCommit", 1);
__decorateClass([
  command()
], DiffExtension.prototype, "removeHighlightedCommit", 1);
__decorateClass([
  command()
], DiffExtension.prototype, "commitChange", 1);
__decorateClass([
  command()
], DiffExtension.prototype, "revertCommit", 1);
__decorateClass([
  helper()
], DiffExtension.prototype, "getCommits", 1);
__decorateClass([
  helper()
], DiffExtension.prototype, "getCommit", 1);
DiffExtension = __decorateClass([
  extension({
    defaultOptions: {
      blameMarkerClass: "blame-marker",
      revertMessage: (message) => `Revert: '${message}'`
    },
    staticKeys: ["blameMarkerClass"],
    handlerKeys: ["onMouseOverCommit", "onMouseLeaveCommit", "onSelectCommits", "onDeselectCommits"]
  })
], DiffExtension);
export {
  DiffExtension
};
