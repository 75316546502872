import { useFetch } from '@cotiss/common'
import { ProcurementResponsePopulatedModel } from '@cotiss/procurement-response'

export const useGetProcurementResponse = (id?: string) => {
  const { data: procurementResponse, ...rest } = useFetch<ProcurementResponsePopulatedModel>('/v2/procurement-response', {
    route: `/v2/procurement-response/${id}`,
    isEnabled: Boolean(id),
  })

  return { procurementResponse, ...rest }
}
