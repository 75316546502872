import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { CONTACT_FRAGMENT } from '@cotiss/contact'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_SCORECARD_FRAGMENT, PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance'
import {
  GqlPublishPerformanceScorecardInput,
  GqlPublishPerformanceScorecardMutation,
  GqlPublishPerformanceScorecardMutationVariables,
  GqlPerformanceScorecardFieldsFragment,
} from '@gql'

export const mutatePublishPerformanceScorecard = async (input: GqlPublishPerformanceScorecardInput) => {
  const { data } = await apolloService.mutate<GqlPublishPerformanceScorecardMutation, GqlPublishPerformanceScorecardMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${CONTACT_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      mutation PublishPerformanceScorecard($input: PublishPerformanceScorecardInput!) {
        publishPerformanceScorecard(input: $input) {
          ...PerformanceScorecardFields
        }
      }
    `,
    variables: { input },
  })

  return data?.publishPerformanceScorecard as GqlPerformanceScorecardFieldsFragment
}
