import { Banner, Checkbox, Form, ModalConfirmationButton, ModalContent, ModalHeader, Text, useCallout } from '@cotiss/common'
import { TenderResponseInvitationLinkModal } from '@cotiss/tender-response'
import React, { memo, useState } from 'react'

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderResponseInvitationLinkRegenerateConfirmationModal = memo(({ tenderId, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  return (
    <Form className="relative min-w-[450px] max-w-[450px]">
      <ModalHeader
        heading="Are you sure you want to regenerate this link?"
        onClose={() => {
          openModal(<TenderResponseInvitationLinkModal tenderId={tenderId} isEditable={isEditable} />)
        }}
      />
      <ModalContent>
        <Banner icon="alert-circle" variant="danger">
          <div>
            <Text className="font-medium">Warning</Text>
            <Text className="mt-1" size="sm">
              Users with access to the old link who have not already accepted the invitation will no longer be able to access the tender invitation.
            </Text>
          </div>
        </Banner>
        <label className="flex mt-6 cursor-pointer" htmlFor="tender-response-invitation-link-regenerate">
          <Checkbox
            id="tender-response-invitation-link-regenerate"
            className="mr-2 mt-1"
            isChecked={isAcknowledged}
            onChange={() => setIsAcknowledged(!isAcknowledged)}
          />

          <Text>I acknowledge that regenerating this link will invalidate access for the current link</Text>
        </label>
      </ModalContent>
      <ModalConfirmationButton
        onClick={() => openModal(<TenderResponseInvitationLinkModal tenderId={tenderId} isEditable={isEditable} willRegenerateLinkOnOpen />)}
        isDisabled={!isAcknowledged}
      >
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
