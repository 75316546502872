import { Field, RichText, Skeleton } from '@cotiss/common'
import React, { memo } from 'react'
import { ConflictOfInterestTermsBackground, useGetConflictOfInterest } from '@cotiss/conflict-of-interest'

type Props = {
  conflictOfInterestId?: string
}

export const ConflictOfInterestViewCompletedTerms = memo(({ conflictOfInterestId }: Props) => {
  const { conflictOfInterest, isLoading } = useGetConflictOfInterest(conflictOfInterestId)

  return (
    <>
      <Field className="mt-8" label="Background">
        <ConflictOfInterestTermsBackground conflictOfInterestId={conflictOfInterestId} />
      </Field>
      <Field className="mt-8" label="Terms">
        {!isLoading && <>{conflictOfInterest?.terms && <RichText value={conflictOfInterest.terms} />}</>}
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
      </Field>
    </>
  )
})
