import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'
import { useEvaluationEnvelope, useEvaluationEnvelopeDocument, useEvaluationUser } from '@cotiss/evaluation-event'

export const EvaluationEventEnvelopeDocumentUploadModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const { mutateCreateEvaluationEnvelopeDocuments } = useEvaluationEnvelopeDocument()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEnvelope || !evaluationUserInSession) {
      return
    }

    try {
      setIsSaving(true)
      await mutateCreateEvaluationEnvelopeDocuments({
        evaluationEnvelopeId: evaluationEnvelope.id,
        evaluationUserId: evaluationUserInSession.id,
        documents: map(documents, ({ _id, fileName, fileSize, mimeType }) => ({
          documentId: _id,
          fileName,
          fileSize,
          mimeType,
        })),
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload resources" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <DocumentUpload documents={documents} onChange={setDocuments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
