export const DOCUMENT_QUERY_KEYS = ['/v2/documents'] as const
export const DOCUMENT_ROUTES = ['/document', '/document/view/:id'] as const

export type SUPPORTED_DOCUMENT_ERROR_CODES = 'file-invalid-type' | 'too-many-files' | 'file-too-large'

export const DOCUMENT_ERROR_MESSAGES_MAP: Record<SUPPORTED_DOCUMENT_ERROR_CODES | string, string> = {
  'file-invalid-type':
    'Supported file types: BMP, GIF, JPEG, PNG, SVG, WebP, ZIP, TXT, RTF, PDF, DOC, DOCX, ODT, EPUB, PPT, PPTX, ODP, CSV, XLS, XLSX, ODS, MPP, CERT, MP4, WEBM, AVI, MKV.',
  'too-many-files': 'You can only upload one file at a time.',
  'file-too-large': 'File exceeds maximum allowed size.',
}

export const DOCUMENT_FILE_TYPE = {
  image: {
    'image/bmp': ['.bmp'] as string[],
    'image/gif': ['.gif'] as string[],
    'image/jpeg': ['.jpg', '.jpeg'] as string[],
    'image/png': ['.png'] as string[],
    'image/svg+xml': ['.svg'] as string[],
    'image/webp': ['.webp'] as string[],
  },
  archive: {
    'application/zip': ['.zip'] as string[],
    'application/x-zip-compressed': ['.zip'] as string[],
    'multipart/x-zip': ['.zip'] as string[],
  },
  document: {
    'text/plain': ['.txt'] as string[],
    'text/rtf': ['.rtf'] as string[],
    'text/cert': ['.cert'] as string[],
    'application/rtf': ['.rtf'] as string[],
    'application/pdf': ['.pdf'] as string[],
    'application/msword': ['.doc'] as string[],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] as string[],
    'application/vnd.oasis.opendocument.text': ['.odt'] as string[],
    'application/epub+zip': ['.epub'] as string[], // Ebook
    'application/vnd.ms-project': ['.mpp'] as string[],
    'application/msproj': ['.mpp'] as string[],
    'application/msproject': ['.mpp'] as string[],
    'application/x-msproject': ['.mpp'] as string[],
    'application/x-ms-project': ['.mpp'] as string[],
    'application/mpp': ['.mpp'] as string[],
    'application/vnd.ms-office': ['.mpp'] as string[],
  },
  presentation: {
    'application/vnd.ms-powerpoint': ['.ppt'] as string[],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'] as string[],
    'application/vnd.oasis.opendocument.presentation': ['.odp'] as string[],
  },
  spreadsheet: {
    'text/csv': ['.csv'] as string[],
    'application/vnd.ms-excel': ['.xls'] as string[],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] as string[],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'] as string[],
  },
  video: {
    'video/mp4': ['.mp4'] as string[],
    'video/webm': ['.webm'] as string[],
    'video/x-msvideo': ['.avi'] as string[],
    'video/x-matroska': ['.mkv'] as string[],
  },
} as const

export const DOCUMENT_ALL_FILE_TYPES = {
  ...DOCUMENT_FILE_TYPE.image,
  ...DOCUMENT_FILE_TYPE.archive,
  ...DOCUMENT_FILE_TYPE.document,
  ...DOCUMENT_FILE_TYPE.presentation,
  ...DOCUMENT_FILE_TYPE.spreadsheet,
  ...DOCUMENT_FILE_TYPE.video,
}

export type DocumentFileType = (typeof DOCUMENT_FILE_TYPE)[keyof typeof DOCUMENT_FILE_TYPE]
