import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { map, orderBy, toLower, upperFirst } from 'lodash'
import { useGetContractShell } from '@cotiss/contract'
import { Text, Skeleton, ScrollableTable, ScrollableTableColumn, utilService, Badge, TableHeader, NoDataPlaceholder } from '@cotiss/common'

type Props = {
  contractShellId: string
  contractId: string
  showTableHeader?: boolean
  // TODO: move this capability to the table component itself once we have standardized the consistent row heights
  variant?: 'inline' | 'normal'
}

export const ContractSummaryScheduleOfRates = memo(({ contractId, contractShellId, showTableHeader = true, variant }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { scheduledRates, contract } = useMemo(() => {
    if (!contractShell) {
      return { scheduledRates: [], contract: null, isVariation: false }
    }

    const contract = contractShell.contracts.find((contract) => contract._id === contractId)
    const isVariation = Boolean(contract?.variationTypes.length)

    return { scheduledRates: orderBy(contract?.scheduledRates, 'index'), contract, isVariation }
  }, [contractShell?.contracts, contractId])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Item',
      rows: map(scheduledRates, (rate) => ({
        content: () => (
          <Text className={classNames('whitespace-normal font-medium break-words w-full', { 'p-2': variant === 'inline' })} font="jakarta">
            {rate.title}
          </Text>
        ),
        tdClassName: 'w-60 align-top py-4',
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Tag',
      rows: map(scheduledRates, ({ tag }) => ({
        content: () => (
          <>
            {tag ? (
              <div className={classNames({ 'p-2': variant === 'inline' })}>
                <Badge variant="neutral" state="outline">
                  {upperFirst(toLower(tag))}
                </Badge>
              </div>
            ) : (
              '--'
            )}
          </>
        ),
        tdClassName: 'align-top py-4',
      })),
    },
    {
      heading: 'Unit price',
      rows: map(scheduledRates, ({ amount, unit }) => ({
        content: () => (
          <div className={classNames('flex items-center', { 'p-2': variant === 'inline' })}>
            <Text variant="secondary">{utilService.formatAsCurrency(amount, contract?.metadata.currency)}</Text>
            <Text variant="light">/{unit}</Text>
          </div>
        ),
        tdClassName: 'align-top mt-4 py-4',
      })),
    },
    {
      heading: 'Description',
      rows: map(scheduledRates, ({ description }) => ({
        content: () => <Text className={classNames('whitespace-pre-wrap', { 'p-2': variant === 'inline' })}>{description}</Text>,
        tdClassName: 'w-3/4 align-top py-4',
      })),
    },
  ]

  if (isLoading) {
    return (
      <div className="p-6">
        <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
      </div>
    )
  }

  if (!scheduledRates.length) {
    return <NoDataPlaceholder illustration="dot-list" label="No scheduled rates" />
  }

  return (
    <>
      {showTableHeader && (
        <TableHeader className="mt-4">
          <Text className="font-semibold">Schedule of rates table</Text>
          <Text variant="light" size="sm">
            Agreed rates charged for various goods or services by the counterparty
          </Text>
        </TableHeader>
      )}
      <ScrollableTable fixedColumnsWidth={240} fixedColumns={fixedColumns} columns={columns} />
    </>
  )
})
