import classNames from 'classnames'
import React, { CSSProperties, memo, useEffect, useState } from 'react'

type Props = {
  className?: string
  src?: string
  fallbackSrc?: string
  alt: string
  style?: CSSProperties
}

export const LazyImg = memo(({ className, src, fallbackSrc, alt, style }: Props) => {
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = classNames(className, {
    'bg-gray-50 animate-pulse': !isLoaded,
    'flex items-center justify-center': isError,
  })

  useEffect(() => {
    if (!src) {
      return
    }

    let preloadImg: HTMLImageElement | null = document.createElement('img')
    preloadImg.src = src

    preloadImg.addEventListener('load', () => {
      preloadImg = null
      setIsLoaded(true)
    })

    preloadImg.addEventListener('error', () => {
      setIsError(true)
      setIsLoaded(true)
    })
  }, [])

  if (isError) {
    if (fallbackSrc) {
      return <img className={classes} style={{ ...style }} src={fallbackSrc} alt={alt} />
    }

    return (
      <div className={classes} style={style}>
        {/* TODO: Show some failed connection icon? */}
      </div>
    )
  }

  return <img className={classes} style={{ ...style }} src={src} alt={alt} />
})
