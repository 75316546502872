import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { TenderFlowPage } from '@cotiss/tender-flow'

export const TenderFlowLayout = memo(() => (
  <Switch>
    <Route path="/tender-flow/:tenderId/:step?/:tab?" exact>
      <TenderFlowPage />
    </Route>
  </Switch>
))
