import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { GqlCreatePlanEventInput, GqlCreatePlanEventMutation, GqlCreatePlanEventMutationVariables, GqlPlanEventFieldsFragment } from '@gql'

export const mutateCreatePlanEvent = async (input: GqlCreatePlanEventInput) => {
  const { data } = await apolloService.mutate<GqlCreatePlanEventMutation, GqlCreatePlanEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      mutation CreatePlanEvent($input: CreatePlanEventInput!) {
        createPlanEvent(input: $input) {
          ...PlanEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPlanEvent as GqlPlanEventFieldsFragment
}
