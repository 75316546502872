import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationSubmissionDocumentListInput, GqlEvaluationSubmissionDocumentListQuery } from '@gql'

export const queryEvaluationSubmissionDocumentList = async (input: GqlEvaluationSubmissionDocumentListInput) => {
  const { data } = await apolloService.query<GqlEvaluationSubmissionDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT}

      query EvaluationSubmissionDocumentList($input: EvaluationSubmissionDocumentListInput!) {
        evaluationSubmissionDocumentList(input: $input) {
          items {
            ...EvaluationSubmissionDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationSubmissionDocumentList
}
