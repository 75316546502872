import { find } from 'lodash'
import React, { memo } from 'react'
import { Button, Icon, Td_DEPRECATED, Tr_DEPRECATED, Text } from '@cotiss/common'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { ShortListPopulatedModel, ShortListStatusBadge } from '@cotiss/short-list'

type Props = {
  shortList: ShortListPopulatedModel
  tenderResponseReports: TenderResponseReportModel[]
  onView?: (shortList: ShortListPopulatedModel) => void
  isEditable?: boolean
  isScoreVisible?: boolean
}

export const ShortListDueDiligenceListItem = memo(({ shortList, tenderResponseReports, onView, isEditable, isScoreVisible }: Props) => (
  <Tr_DEPRECATED variant="white">
    {isScoreVisible && (
      <Td_DEPRECATED>
        <Text>{find(tenderResponseReports, { _id: shortList.tenderResponse._id })?.totalScore?.toFixed(2) || '--'}</Text>
      </Td_DEPRECATED>
    )}
    <Td_DEPRECATED>
      <Text className="truncate">{shortList.tenderResponse.procurementResponse.supplier.name}</Text>
    </Td_DEPRECATED>
    <Td_DEPRECATED>
      <ShortListStatusBadge status={shortList.status} />
    </Td_DEPRECATED>
    <Td_DEPRECATED className="text-right">
      {onView && (
        <Button onClick={() => onView(shortList)} state="translucent" variant="secondary" size="sm">
          {isEditable ? 'Conduct due diligence' : 'View'}
          <Icon className="ml-1" icon="arrow-right" size={20} />
        </Button>
      )}
    </Td_DEPRECATED>
  </Tr_DEPRECATED>
))
