import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardMetricCycleViewInput, GqlPerformanceScorecardMetricCycleViewQuery } from '@gql'

export const queryPerformanceScorecardMetricCycleView = async (input: GqlPerformanceScorecardMetricCycleViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      query PerformanceScorecardMetricCycleView($input: PerformanceScorecardMetricCycleViewInput!) {
        performanceScorecardMetricCycleView(input: $input) {
          ...PerformanceScorecardMetricCycleFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleView
}
