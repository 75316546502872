import { gql } from '@apollo/client'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationEventListInput, GqlEvaluationEventListQuery } from '@gql'

export const queryEvaluationEventList = async (input: GqlEvaluationEventListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      query EvaluationEventList($input: EvaluationEventListInput!) {
        evaluationEventList(input: $input) {
          items {
            ...EvaluationEventFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEventList
}
