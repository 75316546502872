import { isEmpty } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { MetafieldValueModel, useMutateMetafieldValue } from '@cotiss/metafield-value'
import { Form, Label, ModalContent, ModalFooter, ModalHeader, Text, sentryService, useAnalytics, useCallout, useToast } from '@cotiss/common'
import {
  MetafieldFormArrayString,
  MetafieldFormDate,
  MetafieldFormDatetime,
  MetafieldFormHyperlink,
  MetafieldFormLongString,
  MetafieldFormMultiArrayString,
  MetafieldFormNumber,
  MetafieldFormShortString,
  MetafieldModel,
} from '@cotiss/metafield'

type Props = {
  resourceId: string
  metafield: MetafieldModel
  metafieldValue?: MetafieldValueModel
}

export const MetafieldFormEditModal = memo(({ resourceId, metafield, metafieldValue }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState(metafieldValue?.fieldValue || {})
  const { createMetafieldValue, updateMetafieldValue } = useMutateMetafieldValue()
  const { track } = useAnalytics()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // If there is no formData, then close the modal, so we don't store empty object in the database.
    if (isEmpty(formData)) {
      closeModal()
      return
    }

    track('contract_wizard_metadata_update_submit')

    setIsSaving(true)
    try {
      !metafieldValue && (await createMetafieldValue({ metafieldId: metafield._id, resourceId, fieldValue: formData }))
      metafieldValue && (await updateMetafieldValue(metafieldValue._id, { fieldValue: formData }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={metafield.fieldLabel} isDisabled={isSaving} />
      <ModalContent>
        <Label>{metafield.fieldLabel}</Label>
        {metafield.fieldType === 'SHORT_STRING' && (
          <MetafieldFormShortString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'HYPERLINK' && (
          <MetafieldFormHyperlink metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'LONG_STRING' && (
          <MetafieldFormLongString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'ARRAY_STRING' && (
          <MetafieldFormArrayString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'MULTI_ARRAY_STRING' && (
          <MetafieldFormMultiArrayString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'NUMBER' && <MetafieldFormNumber metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldType === 'DATE' && <MetafieldFormDate metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldType === 'DATETIME' && <MetafieldFormDatetime metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldDescription && (
          <Text className="mt-2" size="sm" variant="light">
            {metafield.fieldDescription}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
