import { useAnalytics } from '@cotiss/common'
import { usePlanUser } from '@cotiss/plan-event'
import { PlanEventAnalyticsEvent } from '@cotiss/plan-event/plan-event.events'

export const usePlanEventAnalytics = () => {
  const { track } = useAnalytics()
  const { planUserInSession } = usePlanUser()

  return {
    track: async (event: PlanEventAnalyticsEvent, additionalProperties: Record<string, any> = {}) => {
      const properties = { ...additionalProperties }

      if (planUserInSession) {
        properties.planUserId = planUserInSession.id
        properties.planEventRole = planUserInSession.role
      }

      return await track(event, properties)
    },
  }
}
