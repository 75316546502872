import React, { memo } from 'react'
import { SignUpSupplierFormData } from '@cotiss/auth'
import { Button, FormHiddenInput, Input, Label, TransitionContainer, TransitionType } from '@cotiss/common'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
}

export const SignUpSupplierStepUser = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>First name</Label>
    <Input
      value={formData.firstname}
      onChange={({ target }) => setFormData({ ...formData, firstname: target.value })}
      name="First name"
      placeholder="Enter your first name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Last name</Label>
    <Input
      className="mb-4"
      value={formData.surname}
      onChange={({ target }) => setFormData({ ...formData, surname: target.value })}
      name="Last name"
      placeholder="Enter your last name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Contact number (optional)</Label>
    <Input
      value={formData.phone}
      onChange={({ target }) => setFormData({ ...formData, phone: target.value })}
      name="Phone number"
      placeholder="Enter your contact number"
      maxLength={100}
      isDisabled={isDisabled}
    />
    <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
      Next
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.companyName} name="Company name" />
    <FormHiddenInput value={formData.industry} name="Industry" />
  </TransitionContainer>
))
