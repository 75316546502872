import { gql } from '@apollo/client'

export const EVALUATION_ACCESS_CONTROL_FRAGMENT = gql`
  fragment EvaluationAccessControlFields on EvaluationAccessControl {
    id
    evaluationUserId
    resourceType
    resourceId
    access
    createdAt
    updatedAt
    deletedAt
  }
`
