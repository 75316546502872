import { useGetLoggedInUser } from '@cotiss/user'
import { AnalyticsEvent, analyticsService } from '@cotiss/common'

export const useAnalytics = () => {
  const { user } = useGetLoggedInUser()

  return {
    identify: analyticsService.identify,
    init: analyticsService.init,
    track: async (event: AnalyticsEvent, additionalProperties: Record<string, any> = {}) => {
      const properties = { ...additionalProperties }

      if (user?.account?._id) {
        properties.accountId = user?.account?._id
      }

      if (user?.account?.organisation?._id) {
        properties.organisationId = user?.account?.organisation?._id
      }

      return await analyticsService.track(event, properties)
    },
  }
}
