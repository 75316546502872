import React, { memo } from 'react'
import { Button, Icon, routerService } from '@cotiss/common'

type Props = {
  tenderId: string
}

export const TaskListChairEvaluationCta = memo(({ tenderId }: Props) => {
  return (
    <Button href={routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId })} state="outline" variant="secondary" size="xs" isLink>
      View <Icon className="ml-1" icon="arrow-right" />
    </Button>
  )
})
