import { useFetch } from '@cotiss/common'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier'

export const useGetPreferredSupplier = (id?: string) => {
  const { data: preferredSupplier, ...rest } = useFetch<PreferredSupplierPopulatedModel>('/v2/preferred-suppliers', {
    route: `/v2/preferred-suppliers/${id}`,
    isEnabled: Boolean(id),
  })

  return { preferredSupplier, ...rest }
}
