import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { SupplierInvitationPage, SupplierViewPage } from '@cotiss/supplier'

export const SupplierLayout = memo(() => (
  <Switch>
    <Route path="/supplier/view/:id/:tab?" exact>
      <SupplierViewPage />
    </Route>
    <Route path="/supplier/invitation" exact>
      <SupplierInvitationPage />
    </Route>
  </Switch>
))
