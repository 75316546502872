import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_SUBMISSION_FRAGMENT = gql`
  fragment EvaluationEnvelopeSubmissionFields on EvaluationEnvelopeSubmission {
    id
    evaluationEnvelopeId
    evaluationSubmissionId
    status
    createdAt
    updatedAt
    deletedAt
  }
`
