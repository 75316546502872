import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationEnvelopeDocumentInput,
  GqlCreateEvaluationEnvelopeDocumentMutation,
  GqlCreateEvaluationEnvelopeDocumentMutationVariables,
  GqlEvaluationEnvelopeDocumentFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationEnvelopeDocument = async (input: GqlCreateEvaluationEnvelopeDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEnvelopeDocumentMutation, GqlCreateEvaluationEnvelopeDocumentMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationEnvelopeDocument($input: CreateEvaluationEnvelopeDocumentInput!) {
        createEvaluationEnvelopeDocument(input: $input) {
          ...EvaluationEnvelopeDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEnvelopeDocument as GqlEvaluationEnvelopeDocumentFieldsFragment
}
