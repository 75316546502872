import React, { memo } from 'react'
import { ContractModel } from '@cotiss/contract'
import { Button, Icon, routerService } from '@cotiss/common'

type Props = {
  contract: ContractModel
}

export const TaskListContractCta = memo(({ contract }: Props) => {
  return (
    <Button
      href={routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?', {
        contractShellId: contract?.contractShell,
        contractId: contract?._id || '',
      })}
      state="outline"
      variant="secondary"
      size="xs"
      isLink
    >
      View <Icon className="ml-1" icon="arrow-right" />
    </Button>
  )
})
