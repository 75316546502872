import { gql } from '@apollo/client'

export const PLAN_EVENT_DOCUMENT_FRAGMENT = gql`
  fragment PlanEventDocumentFields on PlanEventDocument {
    id
    planEventId
    documentId
    fileName
    fileSize
    mimeType
    createdAt
    updatedAt
    deletedAt
  }
`
