import { MetricUnit, useMutate } from '@cotiss/common'

export type UpsertPriceItemResponseBulkBodyItem = {
  priceItemResponseId?: string

  // ! This API structure is inconsistent to how we update the PriceItem, as the item properties to update are nested in a `body` property.
  unitPrice?: number
  paymentDate?: string
  index?: number
  description?: string
  category?: string
  unit?: MetricUnit
  quantity?: number
}

// TODO: Since there is a 1:1 mapping between the tender response and tender, we shouldn't need to pass both ids. However this most likely requires an
// TODO: API change.
type UpsertPriceItemResponseBulkBody = {
  tenderId: string
  tenderResponseId: string
  items: UpsertPriceItemResponseBulkBodyItem[]
}

export const useMutatePriceItemResponse = () => {
  const { mutate } = useMutate()

  return {
    upsertPriceItemResponseBulk: async (body: UpsertPriceItemResponseBulkBody) => {
      return await mutate({ route: '/v2/price-item-response/bulk', body: body, method: 'POST', invalidate: ['/v2/price-item-response'] })
    },
  }
}
