import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlEvaluationUserListInput, GqlEvaluationUserListQuery } from '@gql'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'

export const queryEvaluationUserList = async (input: GqlEvaluationUserListInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      query EvaluationUserList($input: EvaluationUserListInput!) {
        evaluationUserList(input: $input) {
          items {
            ...EvaluationUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserList
}
