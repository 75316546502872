import { useMutate } from '@cotiss/common'
import { ConflictOfInterestTemplateModel } from '@cotiss/conflict-of-interest-template'

type UpdateConflictOfInterestTemplateBody = Partial<Omit<ConflictOfInterestTemplateModel, 'organisation' | '_id'>>

export const useMutateConflictOfInterestTemplate = () => {
  const { mutate } = useMutate()

  return {
    updateConflictOfInterestTemplate: async (body: UpdateConflictOfInterestTemplateBody) => {
      return await mutate({ route: `/v2/conflict-of-interest-template`, body, invalidate: ['/v2/conflict-of-interest-template'] })
    },
  }
}
