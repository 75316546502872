import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { useGetTender } from '@cotiss/tender'
import { useGetLoggedInUser } from '@cotiss/user'
import { useGetProcurement } from '@cotiss/procurement'
import { Button, Dropdown, DropdownContent, Icon, Text, sentryService, useAnalytics, useCallout, useToast } from '@cotiss/common'
import { TenderUpdateCreateUpdateModal, TenderUpdatePopulatedModel, TenderUpdateViewDrawer, useMutateTenderUpdate } from '@cotiss/tender-update'

type Props = {
  tenderUpdate: TenderUpdatePopulatedModel
  isEditable?: boolean
  tenderResponseCount?: number
  isRead?: boolean
}

export const TenderUpdateButtons = memo(({ tenderUpdate, isEditable, tenderResponseCount, isRead }: Props) => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const { openModal, openDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { tender } = useGetTender(tenderUpdate.tender)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { procurement } = useGetProcurement(tender?.procurement)
  const { updateTenderUpdate, createTenderUpdateView } = useMutateTenderUpdate()

  const isOwnProcurement = user?.account.organisation?._id === procurement?.procuringEntity
  const isTenderUpdateViewsEnabled = tender?.isTenderUpdateViewsEnabled

  const handlePin = async (pinned: boolean) => {
    try {
      setIsSaving(true)
      setIsDropdownOpen(false)
      await updateTenderUpdate(tenderUpdate._id, { pinned })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleHidden = async (hidden: boolean) => {
    try {
      setIsSaving(true)
      setIsDropdownOpen(false)
      await updateTenderUpdate(tenderUpdate._id, { hidden })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleViewUpdate = async () => {
    try {
      setIsSaving(true)
      track('forum_update_view_create_submit')

      await createTenderUpdateView({ tenderUpdateId: tenderUpdate._id, tenderId: tenderUpdate.tender })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <div className="relative">
      {isEditable && isOwnProcurement && (
        <>
          <Button
            className={classNames('absolute -top-2', {
              'right-[104px]': isTenderUpdateViewsEnabled,
              'right-0': !isTenderUpdateViewsEnabled,
            })}
            onClick={() => setIsDropdownOpen(true)}
            shape="square"
            state="ghost"
            isDisabled={isSaving}
          >
            <Icon icon="dots" variant="light" size={20} />
          </Button>
          <Dropdown
            className={classNames('top-4', { 'right-[104px]': isTenderUpdateViewsEnabled, 'right-0': !isTenderUpdateViewsEnabled })}
            onClose={() => setIsDropdownOpen(false)}
            isOpen={isDropdownOpen}
          >
            <DropdownContent className="flex items-center" onClick={() => openModal(<TenderUpdateCreateUpdateModal tenderUpdate={tenderUpdate} />)}>
              Edit
            </DropdownContent>
            {tenderUpdate.pinned && (
              <DropdownContent className="flex items-center" onClick={() => handlePin(false)}>
                Unpin
              </DropdownContent>
            )}
            {!tenderUpdate.pinned && (
              <DropdownContent className="flex items-center" onClick={() => handlePin(true)}>
                Pin
              </DropdownContent>
            )}
            {tenderUpdate.hidden && (
              <DropdownContent className="flex items-center" onClick={() => handleHidden(false)}>
                Show
              </DropdownContent>
            )}
            {!tenderUpdate.hidden && (
              <DropdownContent className="flex items-center" onClick={() => handleHidden(true)}>
                Hide
              </DropdownContent>
            )}
          </Dropdown>
          {tender?.isTenderUpdateViewsEnabled && tenderResponseCount !== undefined && (
            <Button
              size="xs"
              className="absolute top-0 right-0"
              state="outline"
              variant="secondary"
              onClick={() =>
                openDrawer(<TenderUpdateViewDrawer tenderId={tenderUpdate.tender} tenderUpdate={tenderUpdate} isEditable={isEditable} />)
              }
            >
              <Icon icon="visible" size={14} className="mr-1" /> Views ({tenderUpdate.views.length}/{tenderResponseCount})
            </Button>
          )}
        </>
      )}
      {!isEditable && !isOwnProcurement && isTenderUpdateViewsEnabled && !isRead && (
        <Button size="xs" className="absolute top-0 right-0" onClick={handleViewUpdate} isLoading={isSaving}>
          <Icon icon="check-square-broken" size={14} className="mr-1" /> Mark as viewed
        </Button>
      )}
      {!isEditable && !isOwnProcurement && isTenderUpdateViewsEnabled && isRead && (
        <div className="absolute top-0 right-0 flex items-center">
          <Icon icon="check-circle" size={14} className="mr-1 text-green-500" />
          <Text>Marked as viewed</Text>
        </div>
      )}
    </div>
  )
})
