var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/remirror__extension-codemirror5/src/codemirror-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  isElementDomNode,
  NodeExtension,
  setBlockType
} from "@remirror/core";

// packages/remirror__extension-codemirror5/src/codemirror-node-view.ts
import { entries } from "@remirror/core";
import { exitCode } from "@remirror/pm/commands";
import { redo, undo } from "@remirror/pm/history";
import { Selection as Selection2, TextSelection } from "@remirror/pm/state";

// packages/remirror__extension-codemirror5/src/codemirror-ref.ts
import { object } from "@remirror/core";
var ref = object();
var codemirror_ref_default = ref;

// packages/remirror__extension-codemirror5/src/codemirror-utils.ts
import { findParentNodeOfType, isEqual } from "@remirror/core";
import { Selection } from "@remirror/pm/state";
function arrowHandler(dir) {
  return ({ dispatch, view, tr }) => {
    if (!view) {
      return false;
    }
    if (!(tr.selection.empty && view.endOfTextblock(dir))) {
      return false;
    }
    const side = dir === "left" || dir === "up" ? -1 : 1;
    const $head = tr.selection.$head;
    const nextPos = Selection.near(tr.doc.resolve(side > 0 ? $head.after() : $head.before()), side);
    if (nextPos.$head && nextPos.$head.parent.type.name === "codeMirror") {
      dispatch == null ? void 0 : dispatch(tr.setSelection(nextPos));
      return true;
    }
    return false;
  };
}
function updateNodeAttributes(type) {
  return (attributes) => ({ state: { tr, selection }, dispatch }) => {
    const parent = findParentNodeOfType({ types: type, selection });
    if (!parent || isEqual(attributes, parent.node.attrs)) {
      return false;
    }
    tr.setNodeMarkup(parent.pos, type, { ...parent.node.attrs, ...attributes });
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
function parseLanguageToMode(language) {
  if (language) {
    let mime;
    if (mime = codemirror_ref_default.CodeMirror.findModeByName(language)) {
      return mime.mode;
    } else if (mime = codemirror_ref_default.CodeMirror.findModeByExtension(language)) {
      return mime.mode;
    } else if (mime = codemirror_ref_default.CodeMirror.findModeByMIME(language)) {
      return mime.mode;
    }
  }
  return null;
}

// packages/remirror__extension-codemirror5/src/codemirror-node-view.ts
var _node, _cm, _schema, _view, _getPos, _incomingChanges, _updating;
var CodeMirrorNodeView = class {
  constructor(node, view, getPos, config) {
    /**
     * The ProseMirror Node represented by this instance of the
     * [[`CodeMirrorNodeView`]].
     */
    __privateAdd(this, _node, void 0);
    /**
     * The CodeMirror editor instance being controlled by this NodeView.
     */
    __privateAdd(this, _cm, void 0);
    /**
     * The schema for the current editor.
     */
    __privateAdd(this, _schema, void 0);
    /**
     * The `EditorView` being used.
     */
    __privateAdd(this, _view, void 0);
    /**
     * A method for retrieving the current position from the editor.
     */
    __privateAdd(this, _getPos, void 0);
    /**
     * A flag used to identify whether there are any changes that are incoming. It
     * is used along with `this.#updating` to prevent cursorActivity changes from
     * being forwarded to the ProseMirror editor.
     */
    __privateAdd(this, _incomingChanges, false);
    /**
     * This flag is used to avoid an update loop between the outer and inner
     * editor.
     *
     * - `true` when the CodeMirror editor is being updated right now.
     */
    __privateAdd(this, _updating, false);
    __privateSet(this, _node, node);
    __privateSet(this, _schema, node.type.schema);
    __privateSet(this, _view, view);
    __privateSet(this, _getPos, getPos);
    __privateSet(this, _cm, codemirror_ref_default.CodeMirror(null, {
      value: __privateGet(this, _node).textContent,
      extraKeys: this.codeMirrorKeymap(),
      ...config
    }));
    this.dom = __privateGet(this, _cm).getWrapperElement();
    this.setupCodeMirrorHandlers();
  }
  /**
   * Create the event listeners for managing updates from CodeMirror.
   */
  setupCodeMirrorHandlers() {
    setTimeout(() => __privateGet(this, _cm).refresh(), 20);
    __privateSet(this, _updating, false);
    __privateGet(this, _cm).on("beforeChange", () => __privateSet(this, _incomingChanges, true));
    __privateGet(this, _cm).on("cursorActivity", () => {
      if (!__privateGet(this, _updating) && !__privateGet(this, _incomingChanges)) {
        this.forwardSelection();
      }
    });
    __privateGet(this, _cm).on("changes", () => {
      if (!__privateGet(this, _updating)) {
        this.valueChanged();
        this.forwardSelection();
      }
      __privateSet(this, _incomingChanges, false);
    });
    __privateGet(this, _cm).on("focus", () => this.forwardSelection());
  }
  /**
   * When the code editor is focused, we can keep the selection of the outer
   * editor synchronized with the inner one, so that any commands executed on
   * the outer editor see an accurate selection.
   */
  forwardSelection() {
    if (!__privateGet(this, _cm).hasFocus()) {
      return;
    }
    const state = __privateGet(this, _view).state;
    const selection = this.asProseMirrorSelection(state.doc);
    if (!selection.eq(state.selection)) {
      __privateGet(this, _view).dispatch(state.tr.setSelection(selection));
    }
  }
  /**
   * This helper function translates from a CodeMirror selection to a
   * ProseMirror selection. Because CodeMirror uses a line/column based indexing
   * system, `indexFromPos` is used to convert to an actual character index.
   */
  asProseMirrorSelection(doc) {
    const offset = __privateGet(this, _getPos).call(this) + 1;
    const anchor = __privateGet(this, _cm).indexFromPos(__privateGet(this, _cm).getCursor("anchor")) + offset;
    const head = __privateGet(this, _cm).indexFromPos(__privateGet(this, _cm).getCursor("head")) + offset;
    return TextSelection.between(doc.resolve(anchor), doc.resolve(head));
  }
  /**
   * Selections are also synchronized the other way, from ProseMirror to
   * CodeMirror, using the view's `setSelection` method.
   */
  setSelection(anchor, head) {
    __privateGet(this, _cm).focus();
    __privateSet(this, _updating, true);
    __privateGet(this, _cm).setSelection(__privateGet(this, _cm).posFromIndex(anchor), __privateGet(this, _cm).posFromIndex(head));
    __privateSet(this, _updating, false);
  }
  /**
   * When the actual content of the code editor is changed, the event handler
   * registered in the node view's constructor calls this method. It'll compare
   * the code block node's current value to the value in the editor, and
   * dispatch a transaction if there is a difference.
   */
  valueChanged() {
    const change = computeTextChange(__privateGet(this, _node).textContent, __privateGet(this, _cm).getValue());
    if (!change) {
      return;
    }
    const start = __privateGet(this, _getPos).call(this) + 1;
    const tr = __privateGet(this, _view).state.tr.replaceWith(
      start + change.from,
      start + change.to,
      change.text ? __privateGet(this, _schema).text(change.text) : []
    );
    __privateGet(this, _view).dispatch(tr);
  }
  /**
   * A somewhat tricky aspect of nesting editor like this is handling cursor
   * motion across the edges of the inner editor. This node view will have to
   * take care of allowing the user to move the selection out of the code
   * editor. For that purpose, it binds the arrow keys to handlers that check if
   * further motion would ‘escape’ the editor, and if so, return the selection
   * and focus to the outer editor.
   *
   * The keymap also binds keys for undo and redo, which the outer editor will
   * handle, and for ctrl-enter, which, in ProseMirror's base keymap, creates a
   * new paragraph after a code block.
   */
  codeMirrorKeymap() {
    const view = __privateGet(this, _view);
    const mod = /Mac/.test(navigator.platform) ? "Cmd" : "Ctrl";
    return codemirror_ref_default.CodeMirror.normalizeKeyMap({
      Up: () => this.maybeEscape("line", -1),
      Left: () => this.maybeEscape("char", -1),
      Down: () => this.maybeEscape("line", 1),
      Right: () => this.maybeEscape("char", 1),
      "Ctrl-Enter": () => {
        if (exitCode(view.state, view.dispatch)) {
          view.focus();
        }
      },
      [`${mod}-Z`]: () => undo(view.state, view.dispatch),
      [`Shift-${mod}-Z`]: () => redo(view.state, view.dispatch),
      [`${mod}-Y`]: () => redo(view.state, view.dispatch)
    });
  }
  maybeEscape(unit, dir) {
    const pos = __privateGet(this, _cm).getCursor();
    if (__privateGet(this, _cm).somethingSelected() || pos.line !== (dir < 0 ? __privateGet(this, _cm).firstLine() : __privateGet(this, _cm).lastLine()) || unit === "char" && pos.ch !== (dir < 0 ? 0 : __privateGet(this, _cm).getLine(pos.line).length)) {
      return codemirror_ref_default.CodeMirror.Pass;
    }
    __privateGet(this, _view).focus();
    const targetPos = __privateGet(this, _getPos).call(this) + (dir < 0 ? 0 : __privateGet(this, _node).nodeSize);
    const selection = Selection2.near(__privateGet(this, _view).state.doc.resolve(targetPos), dir);
    __privateGet(this, _view).dispatch(__privateGet(this, _view).state.tr.setSelection(selection).scrollIntoView());
    __privateGet(this, _view).focus();
    return null;
  }
  /**
   * When an update comes in from the editor, for example because of an undo
   * action, we kind of have to do the inverse of what `valueChanged` did—check
   * for text changes, and if present, propagate them from the outer to the
   * inner editor.
   */
  update(node) {
    if (node.type !== __privateGet(this, _node).type) {
      return false;
    }
    const textChange = computeTextChange(__privateGet(this, _cm).getValue(), node.textContent);
    const attrsChange = computeAttrsChange(__privateGet(this, _node).attrs, node.attrs);
    __privateSet(this, _node, node);
    if (textChange || attrsChange) {
      __privateSet(this, _updating, true);
      if (textChange) {
        __privateGet(this, _cm).replaceRange(
          textChange.text,
          __privateGet(this, _cm).posFromIndex(textChange.from),
          __privateGet(this, _cm).posFromIndex(textChange.to)
        );
      }
      if (attrsChange) {
        for (const k of Object.keys(attrsChange)) {
          __privateGet(this, _cm).setOption(k, attrsChange[k]);
        }
      }
      __privateSet(this, _updating, false);
    }
    return true;
  }
  selectNode() {
    __privateGet(this, _cm).focus();
  }
  stopEvent() {
    return true;
  }
};
_node = new WeakMap();
_cm = new WeakMap();
_schema = new WeakMap();
_view = new WeakMap();
_getPos = new WeakMap();
_incomingChanges = new WeakMap();
_updating = new WeakMap();
function computeTextChange(previousText, currentText) {
  if (previousText === currentText) {
    return null;
  }
  let from = 0;
  let to = previousText.length;
  let currentTo = currentText.length;
  while (from < to && previousText.charCodeAt(from) === currentText.charCodeAt(from)) {
    ++from;
  }
  while (to > from && currentTo > from && previousText.charCodeAt(to - 1) === currentText.charCodeAt(currentTo - 1)) {
    to--;
    currentTo--;
  }
  return { from, to, text: currentText.slice(from, currentTo) };
}
function computeAttrsChange(oldAttrs, newAttrs) {
  let updated = false;
  const deltaConfig = {};
  const oldConfig = oldAttrs.codeMirrorConfig ?? {};
  const newConfig = newAttrs.codeMirrorConfig ?? {};
  for (const [key, value] of entries(oldConfig)) {
    if (value !== newConfig[key]) {
      deltaConfig[key] = newConfig[key];
      updated = true;
    }
  }
  if (oldAttrs.language !== newAttrs.language) {
    deltaConfig.mode = parseLanguageToMode(newAttrs.language) ?? void 0;
    updated = true;
  }
  if (updated) {
    return deltaConfig;
  }
  return null;
}

// packages/remirror__extension-codemirror5/src/codemirror-extension.ts
var CodeMirrorExtension = class extends NodeExtension {
  constructor() {
    super(...arguments);
    this.tags = [ExtensionTag.Block, ExtensionTag.Code];
  }
  get name() {
    return "codeMirror";
  }
  init() {
    if (this.options.CodeMirror) {
      codemirror_ref_default.CodeMirror = this.options.CodeMirror;
    }
  }
  createNodeSpec(extra, override) {
    return {
      group: "block",
      content: "text*",
      marks: "",
      defining: true,
      ...override,
      code: true,
      attrs: {
        ...extra.defaults(),
        codeMirrorConfig: { default: void 0 },
        language: { default: void 0 }
      },
      parseDOM: [
        {
          tag: "pre",
          getAttrs: (node) => isElementDomNode(node) ? extra.parse(node) : false
        },
        ...override.parseDOM ?? []
      ],
      toDOM() {
        return ["pre", ["code", 0]];
      },
      isolating: true
    };
  }
  createNodeViews() {
    return (node, view, getPos) => {
      const codeMirrorConfig = {
        ...this.options.defaultCodeMirrorConfig,
        ...node.attrs.codeMirrorConfig
      };
      if (node.attrs.language) {
        const mode = parseLanguageToMode(node.attrs.language);
        if (mode) {
          codeMirrorConfig.mode = mode;
        }
      }
      return new CodeMirrorNodeView(node, view, getPos, codeMirrorConfig);
    };
  }
  createKeymap() {
    return {
      ArrowLeft: arrowHandler("left"),
      ArrowRight: arrowHandler("right"),
      ArrowUp: arrowHandler("up"),
      ArrowDown: arrowHandler("down")
    };
  }
  createCodeMirror(attributes) {
    return setBlockType(this.type, attributes);
  }
  updateCodeMirror(attributes) {
    return updateNodeAttributes(this.type)(attributes);
  }
};
__decorateClass([
  command()
], CodeMirrorExtension.prototype, "createCodeMirror", 1);
__decorateClass([
  command()
], CodeMirrorExtension.prototype, "updateCodeMirror", 1);
CodeMirrorExtension = __decorateClass([
  extension({
    defaultOptions: {
      CodeMirror: codemirror_ref_default.CodeMirror,
      defaultCodeMirrorConfig: null
    },
    staticKeys: ["CodeMirror"]
  })
], CodeMirrorExtension);
export {
  CodeMirrorExtension
};
