import { useFetch } from '@cotiss/common'
import { ApprovalModel } from '@cotiss/approval'

export const useGetApproval = (id?: string) => {
  const { data: approval, ...rest } = useFetch<ApprovalModel>('/v2/approvals', {
    route: `/v2/approvals/${id}`,
    isEnabled: Boolean(id),
  })

  return { approval, ...rest }
}
