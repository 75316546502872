import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { ProcurementPopulatedModel, useMutateProcurement } from '@cotiss/procurement'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  procurement: ProcurementPopulatedModel
}

export const ProcurementOverviewBackgroundDocumentUploadModal = memo(({ procurement }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateProcurement } = useMutateProcurement()
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateProcurement(procurement._id, {
        attachments: [...map(attachments, ({ _id }) => _id), ...map(procurement.attachments, ({ _id }) => _id)],
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading="Upload internal documents"
        supplementary="These are read only documents not visible to suppliers."
        isDisabled={isSaving}
      />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
