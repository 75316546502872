import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import {
  GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdInput,
  GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdMutation,
  GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdMutationVariables,
} from '@gql'

export const mutateDeleteApprovalTemplatesByApprovalTemplateGroupId = async (input: GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdInput) => {
  const { data } = await apolloService.mutate<
    GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdMutation,
    GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdMutationVariables
  >({
    mutation: gql`
      mutation DeleteApprovalTemplatesByApprovalTemplateGroupId($input: DeleteApprovalTemplatesByApprovalTemplateGroupIdInput!) {
        deleteApprovalTemplatesByApprovalTemplateGroupId(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteApprovalTemplatesByApprovalTemplateGroupId as boolean
}
