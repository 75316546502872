import React, { memo, useState } from 'react'
import { ContractAddEditCorrespondenceDrawer } from '@cotiss/contract'
import { CorrespondenceModel, useMutateCorrespondence } from '@cotiss/correspondence'
import { Button, Dropdown, DropdownContent, ConfirmModal, Icon, sentryService, useToast, useCallout } from '@cotiss/common'

type Props = {
  className?: string
  contractShellId: string
  correspondence: CorrespondenceModel
  onDeleteSuccess?: () => void
}

export const CorrespondenceUpdateDeleteCta = memo(({ className, contractShellId, correspondence, onDeleteSuccess }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { openModal, openNarrowDrawer } = useCallout()
  const { deleteCorrespondence } = useMutateCorrespondence()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleDelete = async () => {
    try {
      setIsSaving(true)
      await deleteCorrespondence(correspondence._id)
      setIsSaving(false)
      if (!onDeleteSuccess) {
        return
      }
      onDeleteSuccess()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      <Button
        className={className}
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          isDisabled={isSaving}
          className="flex items-center"
          onClick={() => openNarrowDrawer(<ContractAddEditCorrespondenceDrawer contractShellId={contractShellId} correspondence={correspondence} />)}
        >
          Edit
        </DropdownContent>
        <DropdownContent
          isDisabled={isSaving}
          className="flex items-center"
          onClick={() =>
            openModal(<ConfirmModal heading="Confirm delete" description="Are you sure you want to delete this note?" onSubmit={handleDelete} />)
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </>
  )
})
