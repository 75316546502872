import { useFetch } from '@cotiss/common'
import { CorrespondenceModel } from '@cotiss/correspondence'

type Props = {
  correspondenceId?: string
}

export const useGetCorrespondence = ({ correspondenceId }: Props = {}) => {
  const { data: correspondence, ...rest } = useFetch<CorrespondenceModel>('/v2/correspondence', {
    route: `/v2/correspondence/${correspondenceId}`,
  })

  return { correspondence, ...rest }
}
