import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_SCORE_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlCreateEvaluationScoreInput,
  GqlCreateEvaluationScoreMutation,
  GqlCreateEvaluationScoreMutationVariables,
  GqlEvaluationScoreFieldsFragment,
} from '@gql'

export const mutateCreateEvaluationScore = async (input: GqlCreateEvaluationScoreInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationScoreMutation, GqlCreateEvaluationScoreMutationVariables>({
    mutation: gql`
      ${EVALUATION_SCORE_FRAGMENT}

      mutation CreateEvaluationScore($input: CreateEvaluationScoreInput!) {
        createEvaluationScore(input: $input) {
          ...EvaluationScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationScore as GqlEvaluationScoreFieldsFragment
}
