import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'
import { DocumentModel, DocumentUpload } from '@cotiss/document'
import { Form, ModalContent, ModalFooter, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'
import { useEvaluationEvent, useEvaluationEventAnalytics, useEvaluationSubmission, useEvaluationSubmissionDocument } from '@cotiss/evaluation-event'

type Props = {
  evaluationEnvelopeId: string
}

export const EvaluationEventSubmissionDocumentUploadModal = memo(({ evaluationEnvelopeId }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { mutateCreateEvaluationSubmissionDocuments } = useEvaluationSubmissionDocument()
  const { evaluationSubmission, queryEvaluationSubmissionView } = useEvaluationSubmission()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationSubmission || !evaluationEvent) {
      return
    }

    track('evaluation_event_view_submissions_drawer_document_create_submit')

    try {
      setIsSaving(true)
      await mutateCreateEvaluationSubmissionDocuments({
        evaluationSubmissionId: evaluationSubmission.id,
        documents: map(documents, ({ _id, fileName, fileSize, mimeType }) => ({
          documentId: _id,
          evaluationEnvelopeId,
          fileName,
          fileSize,
          mimeType,
        })),
      })
      // Refreshing event and submission in order to update the setup complete badge, and the tracking tab.
      await Promise.all([
        queryEvaluationSubmissionView({ evaluationSubmissionId: evaluationSubmission.id }),
        queryEvaluationEventView({ evaluationEventId: evaluationEvent.id }),
      ])
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload submission documents" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <DocumentUpload documents={documents} onChange={setDocuments} onIsUploadingChange={setIsSaving} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
