import React, { memo, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex } from 'lodash'
import { DocumentModel } from '@cotiss/document'
import { Drawer, TabModel, Tabs, Text, TransitionContainer, useFeature, useTransition } from '@cotiss/common'
import {
  ContractSignedDocumentViewer,
  ContractApproversTable,
  ContractSummarySignaturesTab,
  useGetContractShell,
  contractService,
} from '@cotiss/contract'

type Props = {
  approvedDocument: DocumentModel
  signedDocument?: DocumentModel
  contractId: string
  contractShellId: string
  title: string
}

type ContractDocumentDrawerTab = 'contract-preview' | 'approvals' | 'signatures'

export const ContractDocumentDrawer = memo(({ approvedDocument, signedDocument, contractId, contractShellId, title }: Props) => {
  const { step, transition, onTransition } = useTransition()
  const { contractShell } = useGetContractShell(contractShellId)
  const isEsignEnabled = useFeature('contract-management-e-sign')

  const { tabs, approval } = useMemo(() => {
    const contract = find(contractShell?.contracts, ({ _id }) => _id === contractId)
    const approval = contractService.getApproval(contract?.approvals, ['APPROVED'])
    const isUsingEsign = isEsignEnabled && contract && contract?.documentShells.find((ds) => ds.signatureType === 'ESIGN_DOCUSIGN')
    const tabs: TabModel<ContractDocumentDrawerTab>[] = [
      { id: 'contract-preview', label: 'Contract preview' },
      { id: 'approvals', label: 'Approvals', isHidden: !approval }, // TODO: approval count on label
      { id: 'signatures', label: 'Signatures', isHidden: !isUsingEsign }, // TODO: signature count on label
    ]

    return { tabs: filter(tabs, (tab) => !tab.isHidden), approval }
  }, [isEsignEnabled, contractShell, contractId])

  const activeTabId = useMemo(() => tabs[step - 1].id, [step, tabs])

  const handleTabChange = (tab: ContractDocumentDrawerTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
      {title}
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <Tabs<ContractDocumentDrawerTab>
        className="border-b border-gray-300 w-full"
        tabs={tabs}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {activeTabId === 'contract-preview' && <ContractSignedDocumentViewer approvedDocument={approvedDocument} signedDocument={signedDocument} />}
          {activeTabId === 'approvals' && (
            <ContractApproversTable contractId={contractId} contractShellId={contractShellId} approvalId={approval?._id} />
          )}
          {activeTabId === 'signatures' && <ContractSummarySignaturesTab contractId={contractId} contractShellId={contractShellId} />}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
