import { findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetLoggedInUser } from '@cotiss/user'
import { SettingsOrganisationUploadLogoModal } from '@cotiss/settings'
import { OrganisationLogo, useGetOrganisation } from '@cotiss/organisation'
import { Badge, Page, TabModel, Tabs, Text, TransitionContainer, routerService, useCallout, useTransition } from '@cotiss/common'
import { BuyerViewOpenListingsTab, BuyerViewClosedListingsTab, BuyerViewResourcesTab, BuyerViewSupportTab, BuyerViewDetails } from '@cotiss/buyer'

export type BuyerViewTab = 'open-listings' | 'closed-listings' | 'resources' | 'support'
const BUYER_VIEW_TABS: TabModel<BuyerViewTab>[] = [
  { id: 'open-listings', label: 'Active listings' },
  { id: 'closed-listings', label: 'Closed listings' },
  { id: 'resources', label: 'Resources' },
  { id: 'support', label: 'Support' },
]

export const BuyerViewPage = memo(() => {
  const { user } = useGetLoggedInUser()
  const { replace } = useHistory()
  const { openModal } = useCallout()
  const { id, tab } = useParams<{ id: string; tab?: BuyerViewTab }>()
  const { organisation } = useGetOrganisation(id)
  const isUserProfile = useMemo(() => user?.account.organisation?._id === id, [user, id])
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(BUYER_VIEW_TABS, ({ id }) => id === tab) + 1 })

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/buyer/view/:id/:tab?', { id, tab: 'open-listings' }))
    }
  }, [])

  const handleTabChange = (tab: BuyerViewTab) => {
    const newStep = findIndex(BUYER_VIEW_TABS, ({ id }) => id === tab) + 1

    replace(routerService.getHref('/buyer/view/:id/:tab?', { id, tab }))
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <Page>
      <div className="bg-primary-500 h-24 w-full" />
      <div className="relative -top-8 flex items-start justify-between px-10">
        <div className="w-2/3 mr-8">
          <OrganisationLogo
            organisation={organisation}
            onUpdate={isUserProfile ? () => openModal(<SettingsOrganisationUploadLogoModal />) : undefined}
          />

          <Text className="font-medium mt-4" size="h4">
            {organisation?.name || '–'}
            {isUserProfile && (
              <Badge className="ml-2" state="translucent" variant="secondary">
                Admin view
              </Badge>
            )}
          </Text>

          <Tabs<BuyerViewTab>
            className="border-b border-gray-300 w-full mt-2 mb-4"
            tab={tab}
            tabs={BUYER_VIEW_TABS}
            onChange={({ id }) => handleTabChange(id)}
            variant="underline"
          />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && <BuyerViewOpenListingsTab />}
              {step === 2 && <BuyerViewClosedListingsTab />}
              {step === 3 && <BuyerViewResourcesTab isEditable={isUserProfile} />}
              {step === 4 && <BuyerViewSupportTab />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
        <BuyerViewDetails isEditable={isUserProfile} />
      </div>
    </Page>
  )
})
