import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { ProcurementListPopulatedModel } from '@cotiss/procurement'
import { useMutateContractShell, ContractCreateInitialStep, ContractCreateProcurementStep } from '@cotiss/contract'
import {
  Button,
  Drawer,
  Icon,
  TransitionContainer,
  routerService,
  sentryService,
  Text,
  useAnalytics,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'

export type ContractCreateMethod = 'with-procurement' | 'without-procurement'

export const ContractCreateDrawer = memo(() => {
  const { push } = useHistory()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [title, setTitle] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { createContractShell } = useMutateContractShell()
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const [createMethod, setCreateMethod] = useState<ContractCreateMethod>('with-procurement')
  const [selectedProcurement, setSelectedProcurement] = useState<ProcurementListPopulatedModel>()

  const handleCreateContract = async () => {
    track('contract_list_create_submit')

    try {
      setIsSaving(true)
      const { _id: contractShellId } = await createContractShell({
        title,
        ...(selectedProcurement && { procurementId: selectedProcurement._id }),
      })

      closeDrawer()
      push(routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId }))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async () => {
    if (step === 1) {
      createMethod === 'without-procurement' ? handleCreateContract() : onTransition({ step: 2 })
    } else {
      handleCreateContract()
    }
  }

  const renderHeader = () => (
    <Text className="font-medium" size="h5" variant="heading" font="jakarta">
      Create contract
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence mode="wait" initial={false}>
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            {createMethod === 'with-procurement' ? (
              <>
                Continue <Icon icon="arrow-right" className="text-white ml-1" />
              </>
            ) : (
              'Confirm'
            )}
          </Button>
        )}
        {step === 2 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving}>
              Confirm
            </Button>
            <Text className="ml-2">{selectedProcurement ? '1' : '0'} selected</Text>
          </div>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <ContractCreateInitialStep
              title={title}
              createMethod={createMethod}
              onCreateMethodChange={setCreateMethod}
              onTitleChange={setTitle}
              isDisabled={isTransitioning || isSaving}
            />
          )}
          {step === 2 && (
            <ContractCreateProcurementStep
              title={title}
              selectedProcurement={selectedProcurement}
              onProcurementChange={setSelectedProcurement}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
              isDisabled={isTransitioning || isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
