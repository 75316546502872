import React, { FormEvent, memo, useState } from 'react'
import {
  Badge,
  Button,
  Form,
  Icon,
  Label,
  ModalConfirmationButton,
  ModalContent,
  Radio,
  RadioCard,
  Text,
  TextArea,
  sentryService,
  useCallout,
  useToast,
} from '@cotiss/common'
import { ConflictOfInterestModel, ConflictOfInterestResolution, useMutateConflictOfInterest } from '@cotiss/conflict-of-interest'

type ConfirmationSteps = 'select' | 'resolve'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
}

type FormData = {
  resolution: ConflictOfInterestResolution
  resolutionReason?: string
}

export const ConflictOfInterestResolutionModal = memo(({ conflictOfInterest }: Props) => {
  const [step, setStep] = useState<ConfirmationSteps>('select')
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    resolution: 'include',
  })

  const { updateConflictOfInterest } = useMutateConflictOfInterest()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (step === 'select') {
      setStep('resolve')
      return
    }

    if (step === 'resolve') {
      try {
        setIsSaving(true)
        await updateConflictOfInterest(conflictOfInterest._id, formData)
        closeModal()
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        openToast(error.message, 'danger')
        setIsSaving(false)
      }
    }
  }

  return (
    <Form className="relative min-w-[650px] max-w-[650px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="cross" />
        </Button>
        {/* TODO: We could add some nice transitions here using the onTransition hook */}
        {step === 'select' && (
          <>
            <Text className="font-semibold text-center" size="h5" font="jakarta">
              How would you like to resolve this conflict?
            </Text>
            <div className="flex justify-between mt-4">
              <RadioCard
                className="w-1/2 border border-gray-100 p-3.5 mr-2"
                value="include"
                name="evaluation-type"
                onChange={() => setFormData({ ...formData, resolution: 'include' })}
                isSelected={formData.resolution === 'include'}
                isDisabled={isSaving}
                isRequired
              >
                <div className="flex justify-between">
                  <Badge shape="square" variant="secondary" state="translucent">
                    <Icon icon="users-01" size={16} />
                  </Badge>
                  <Radio className="ml-2" isChecked={formData.resolution === 'include'} />
                </div>
                <Text className="font-medium mt-2">Include User</Text>
                <Text size="sm" variant="light">
                  Due to the nature of the conflict I acknowledge this user can be included without risking the integrity of the procurement.
                </Text>
              </RadioCard>
              <RadioCard
                className="w-1/2 border border-gray-100 p-3.5 mr-2"
                value="exclude"
                name="evaluation-type"
                onChange={() => setFormData({ ...formData, resolution: 'exclude' })}
                isSelected={formData.resolution === 'exclude'}
                isDisabled={isSaving}
                isRequired
              >
                <div className="flex justify-between">
                  <Badge shape="square" variant="secondary" state="translucent">
                    <Icon icon="users-01" size={16} />
                  </Badge>
                  <Radio className="ml-2" isChecked={formData.resolution === 'exclude'} />
                </div>
                <Text className="font-medium mt-2">Replace User</Text>
                <Text size="sm" variant="light">
                  Due to the nature of the conflict this person should be removed from the procurement, and I acknowledge that I must replace this
                  user.
                </Text>
              </RadioCard>
            </div>
          </>
        )}
        {step === 'resolve' && (
          <>
            <Text className="font-semibold text-center" size="h5" font="jakarta">
              Manage conflict
            </Text>
            <Label className="mt-4">Justification</Label>
            <TextArea
              value={formData?.resolutionReason}
              maxLength={500}
              placeholder="Provide additional information as to how this conflict can or cannot be managed."
              isDisabled={isSaving}
              rows={4}
              onChange={({ target }) => setFormData({ ...formData, resolutionReason: target.value })}
            />
          </>
        )}
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
