{
  "AF": {
    "label": "Afghanistan",
    "value": "AF"
  },
  "AF-BDG": {
    "label": "Badghis",
    "value": "AF-BDG",
    "parent": "AF"
  },
  "AF-BGL": {
    "label": "Baghlan",
    "value": "AF-BGL",
    "parent": "AF"
  },
  "AF-BAL": {
    "label": "Balkh",
    "value": "AF-BAL",
    "parent": "AF"
  },
  "AF-BAM": {
    "label": "Bamyan",
    "value": "AF-BAM",
    "parent": "AF"
  },
  "AF-DAY": {
    "label": "Daykundi",
    "value": "AF-DAY",
    "parent": "AF"
  },
  "AF-FRA": {
    "label": "Farah",
    "value": "AF-FRA",
    "parent": "AF"
  },
  "AF-FYB": {
    "label": "Faryab",
    "value": "AF-FYB",
    "parent": "AF"
  },
  "AF-GHA": {
    "label": "Ghazni",
    "value": "AF-GHA",
    "parent": "AF"
  },
  "AF-GHO": {
    "label": "Ghor",
    "value": "AF-GHO",
    "parent": "AF"
  },
  "AF-HEL": {
    "label": "Helmand",
    "value": "AF-HEL",
    "parent": "AF"
  },
  "AF-HER": {
    "label": "Herat",
    "value": "AF-HER",
    "parent": "AF"
  },
  "AF-JOW": {
    "label": "Jowzjan",
    "value": "AF-JOW",
    "parent": "AF"
  },
  "AF-KAB": {
    "label": "Kabul",
    "value": "AF-KAB",
    "parent": "AF"
  },
  "AF-KAN": {
    "label": "Kandahar",
    "value": "AF-KAN",
    "parent": "AF"
  },
  "AF-KHO": {
    "label": "Khost",
    "value": "AF-KHO",
    "parent": "AF"
  },
  "AF-KDZ": {
    "label": "Kunduz",
    "value": "AF-KDZ",
    "parent": "AF"
  },
  "AF-LAG": {
    "label": "Laghman",
    "value": "AF-LAG",
    "parent": "AF"
  },
  "AF-LOG": {
    "label": "Logar",
    "value": "AF-LOG",
    "parent": "AF"
  },
  "AF-NAN": {
    "label": "Nangarhar",
    "value": "AF-NAN",
    "parent": "AF"
  },
  "AF-NIM": {
    "label": "Nimroz",
    "value": "AF-NIM",
    "parent": "AF"
  },
  "AF-PKA": {
    "label": "Paktika",
    "value": "AF-PKA",
    "parent": "AF"
  },
  "AF-PIA": {
    "label": "Paktiya",
    "value": "AF-PIA",
    "parent": "AF"
  },
  "AF-PAR": {
    "label": "Parwan",
    "value": "AF-PAR",
    "parent": "AF"
  },
  "AF-SAM": {
    "label": "Samangan",
    "value": "AF-SAM",
    "parent": "AF"
  },
  "AF-SAR": {
    "label": "Sar-e Pul",
    "value": "AF-SAR",
    "parent": "AF"
  },
  "AF-TAK": {
    "label": "Takhar",
    "value": "AF-TAK",
    "parent": "AF"
  },
  "AF-URU": {
    "label": "Uruzgan",
    "value": "AF-URU",
    "parent": "AF"
  },
  "AX": {
    "label": "Åland Islands",
    "value": "AX"
  },
  "AL": {
    "label": "Albania",
    "value": "AL"
  },
  "AL-01": {
    "label": "Berat",
    "value": "AL-01",
    "parent": "AL"
  },
  "AL-09": {
    "label": "Diber",
    "value": "AL-09",
    "parent": "AL"
  },
  "AL-02": {
    "label": "Durres",
    "value": "AL-02",
    "parent": "AL"
  },
  "AL-03": {
    "label": "Elbasan",
    "value": "AL-03",
    "parent": "AL"
  },
  "AL-04": {
    "label": "Fier",
    "value": "AL-04",
    "parent": "AL"
  },
  "AL-05": {
    "label": "Gjirokaster",
    "value": "AL-05",
    "parent": "AL"
  },
  "AL-06": {
    "label": "Korce",
    "value": "AL-06",
    "parent": "AL"
  },
  "AL-07": {
    "label": "Kukes",
    "value": "AL-07",
    "parent": "AL"
  },
  "AL-08": {
    "label": "Lezhe",
    "value": "AL-08",
    "parent": "AL"
  },
  "AL-10": {
    "label": "Shkoder",
    "value": "AL-10",
    "parent": "AL"
  },
  "AL-11": {
    "label": "Tirane",
    "value": "AL-11",
    "parent": "AL"
  },
  "AL-12": {
    "label": "Vlore",
    "value": "AL-12",
    "parent": "AL"
  },
  "DZ": {
    "label": "Algeria",
    "value": "DZ"
  },
  "DZ-01": {
    "label": "Adrar",
    "value": "DZ-01",
    "parent": "DZ"
  },
  "DZ-44": {
    "label": "Ain Defla",
    "value": "DZ-44",
    "parent": "DZ"
  },
  "DZ-46": {
    "label": "Ain Temouchent",
    "value": "DZ-46",
    "parent": "DZ"
  },
  "DZ-16": {
    "label": "Alger",
    "value": "DZ-16",
    "parent": "DZ"
  },
  "DZ-23": {
    "label": "Annaba",
    "value": "DZ-23",
    "parent": "DZ"
  },
  "DZ-05": {
    "label": "Batna",
    "value": "DZ-05",
    "parent": "DZ"
  },
  "DZ-08": {
    "label": "Bechar",
    "value": "DZ-08",
    "parent": "DZ"
  },
  "DZ-06": {
    "label": "Bejaia",
    "value": "DZ-06",
    "parent": "DZ"
  },
  "DZ-07": {
    "label": "Biskra",
    "value": "DZ-07",
    "parent": "DZ"
  },
  "DZ-09": {
    "label": "Blida",
    "value": "DZ-09",
    "parent": "DZ"
  },
  "DZ-34": {
    "label": "Bordj Bou Arreridj",
    "value": "DZ-34",
    "parent": "DZ"
  },
  "DZ-10": {
    "label": "Bouira",
    "value": "DZ-10",
    "parent": "DZ"
  },
  "DZ-35": {
    "label": "Boumerdes",
    "value": "DZ-35",
    "parent": "DZ"
  },
  "DZ-02": {
    "label": "Chlef",
    "value": "DZ-02",
    "parent": "DZ"
  },
  "DZ-25": {
    "label": "Constantine",
    "value": "DZ-25",
    "parent": "DZ"
  },
  "DZ-56": {
    "label": "Djanet",
    "value": "DZ-56",
    "parent": "DZ"
  },
  "DZ-17": {
    "label": "Djelfa",
    "value": "DZ-17",
    "parent": "DZ"
  },
  "DZ-32": {
    "label": "El Bayadh",
    "value": "DZ-32",
    "parent": "DZ"
  },
  "DZ-57": {
    "label": "El Meghaier",
    "value": "DZ-57",
    "parent": "DZ"
  },
  "DZ-39": {
    "label": "El Oued",
    "value": "DZ-39",
    "parent": "DZ"
  },
  "DZ-36": {
    "label": "El Tarf",
    "value": "DZ-36",
    "parent": "DZ"
  },
  "DZ-47": {
    "label": "Ghardaia",
    "value": "DZ-47",
    "parent": "DZ"
  },
  "DZ-24": {
    "label": "Guelma",
    "value": "DZ-24",
    "parent": "DZ"
  },
  "DZ-33": {
    "label": "Illizi",
    "value": "DZ-33",
    "parent": "DZ"
  },
  "DZ-53": {
    "label": "In Salah",
    "value": "DZ-53",
    "parent": "DZ"
  },
  "DZ-18": {
    "label": "Jijel",
    "value": "DZ-18",
    "parent": "DZ"
  },
  "DZ-40": {
    "label": "Khenchela",
    "value": "DZ-40",
    "parent": "DZ"
  },
  "DZ-03": {
    "label": "Laghouat",
    "value": "DZ-03",
    "parent": "DZ"
  },
  "DZ-28": {
    "label": "M'sila",
    "value": "DZ-28",
    "parent": "DZ"
  },
  "DZ-29": {
    "label": "Mascara",
    "value": "DZ-29",
    "parent": "DZ"
  },
  "DZ-26": {
    "label": "Medea",
    "value": "DZ-26",
    "parent": "DZ"
  },
  "DZ-43": {
    "label": "Mila",
    "value": "DZ-43",
    "parent": "DZ"
  },
  "DZ-27": {
    "label": "Mostaganem",
    "value": "DZ-27",
    "parent": "DZ"
  },
  "DZ-45": {
    "label": "Naama",
    "value": "DZ-45",
    "parent": "DZ"
  },
  "DZ-31": {
    "label": "Oran",
    "value": "DZ-31",
    "parent": "DZ"
  },
  "DZ-30": {
    "label": "Ouargla",
    "value": "DZ-30",
    "parent": "DZ"
  },
  "DZ-51": {
    "label": "Ouled Djellal",
    "value": "DZ-51",
    "parent": "DZ"
  },
  "DZ-04": {
    "label": "Oum el Bouaghi",
    "value": "DZ-04",
    "parent": "DZ"
  },
  "DZ-48": {
    "label": "Relizane",
    "value": "DZ-48",
    "parent": "DZ"
  },
  "DZ-20": {
    "label": "Saida",
    "value": "DZ-20",
    "parent": "DZ"
  },
  "DZ-19": {
    "label": "Setif",
    "value": "DZ-19",
    "parent": "DZ"
  },
  "DZ-22": {
    "label": "Sidi Bel Abbes",
    "value": "DZ-22",
    "parent": "DZ"
  },
  "DZ-21": {
    "label": "Skikda",
    "value": "DZ-21",
    "parent": "DZ"
  },
  "DZ-41": {
    "label": "Souk Ahras",
    "value": "DZ-41",
    "parent": "DZ"
  },
  "DZ-11": {
    "label": "Tamanrasset",
    "value": "DZ-11",
    "parent": "DZ"
  },
  "DZ-12": {
    "label": "Tebessa",
    "value": "DZ-12",
    "parent": "DZ"
  },
  "DZ-14": {
    "label": "Tiaret",
    "value": "DZ-14",
    "parent": "DZ"
  },
  "DZ-49": {
    "label": "Timimoun",
    "value": "DZ-49",
    "parent": "DZ"
  },
  "DZ-37": {
    "label": "Tindouf",
    "value": "DZ-37",
    "parent": "DZ"
  },
  "DZ-42": {
    "label": "Tipaza",
    "value": "DZ-42",
    "parent": "DZ"
  },
  "DZ-38": {
    "label": "Tissemsilt",
    "value": "DZ-38",
    "parent": "DZ"
  },
  "DZ-15": {
    "label": "Tizi Ouzou",
    "value": "DZ-15",
    "parent": "DZ"
  },
  "DZ-13": {
    "label": "Tlemcen",
    "value": "DZ-13",
    "parent": "DZ"
  },
  "DZ-55": {
    "label": "Touggourt",
    "value": "DZ-55",
    "parent": "DZ"
  },
  "AS": {
    "label": "American Samoa",
    "value": "AS"
  },
  "AD": {
    "label": "Andorra",
    "value": "AD"
  },
  "AD-07": {
    "label": "Andorra la Vella",
    "value": "AD-07",
    "parent": "AD"
  },
  "AD-02": {
    "label": "Canillo",
    "value": "AD-02",
    "parent": "AD"
  },
  "AD-03": {
    "label": "Encamp",
    "value": "AD-03",
    "parent": "AD"
  },
  "AD-08": {
    "label": "Escaldes-Engordany",
    "value": "AD-08",
    "parent": "AD"
  },
  "AD-04": {
    "label": "La Massana",
    "value": "AD-04",
    "parent": "AD"
  },
  "AD-05": {
    "label": "Ordino",
    "value": "AD-05",
    "parent": "AD"
  },
  "AD-06": {
    "label": "Sant Julia de Loria",
    "value": "AD-06",
    "parent": "AD"
  },
  "AO": {
    "label": "Angola",
    "value": "AO"
  },
  "AO-BGO": {
    "label": "Bengo",
    "value": "AO-BGO",
    "parent": "AO"
  },
  "AO-BGU": {
    "label": "Benguela",
    "value": "AO-BGU",
    "parent": "AO"
  },
  "AO-BIE": {
    "label": "Bie",
    "value": "AO-BIE",
    "parent": "AO"
  },
  "AO-CAB": {
    "label": "Cabinda",
    "value": "AO-CAB",
    "parent": "AO"
  },
  "AO-CCU": {
    "label": "Cuando Cubango",
    "value": "AO-CCU",
    "parent": "AO"
  },
  "AO-CNO": {
    "label": "Cuanza-Norte",
    "value": "AO-CNO",
    "parent": "AO"
  },
  "AO-CUS": {
    "label": "Cuanza-Sul",
    "value": "AO-CUS",
    "parent": "AO"
  },
  "AO-HUA": {
    "label": "Huambo",
    "value": "AO-HUA",
    "parent": "AO"
  },
  "AO-HUI": {
    "label": "Huila",
    "value": "AO-HUI",
    "parent": "AO"
  },
  "AO-LUA": {
    "label": "Luanda",
    "value": "AO-LUA",
    "parent": "AO"
  },
  "AO-LNO": {
    "label": "Lunda-Norte",
    "value": "AO-LNO",
    "parent": "AO"
  },
  "AO-LSU": {
    "label": "Lunda-Sul",
    "value": "AO-LSU",
    "parent": "AO"
  },
  "AO-MAL": {
    "label": "Malange",
    "value": "AO-MAL",
    "parent": "AO"
  },
  "AO-MOX": {
    "label": "Moxico",
    "value": "AO-MOX",
    "parent": "AO"
  },
  "AO-NAM": {
    "label": "Namibe",
    "value": "AO-NAM",
    "parent": "AO"
  },
  "AO-UIG": {
    "label": "Uige",
    "value": "AO-UIG",
    "parent": "AO"
  },
  "AO-ZAI": {
    "label": "Zaire",
    "value": "AO-ZAI",
    "parent": "AO"
  },
  "AI": {
    "label": "Anguilla",
    "value": "AI"
  },
  "AQ": {
    "label": "Antarctica",
    "value": "AQ"
  },
  "AG": {
    "label": "Antigua and Barbuda",
    "value": "AG"
  },
  "AG-10": {
    "label": "Barbuda",
    "value": "AG-10",
    "parent": "AG"
  },
  "AG-03": {
    "label": "Saint George",
    "value": "AG-03",
    "parent": "AG"
  },
  "AG-04": {
    "label": "Saint John",
    "value": "AG-04",
    "parent": "AG"
  },
  "AG-05": {
    "label": "Saint Mary",
    "value": "AG-05",
    "parent": "AG"
  },
  "AG-06": {
    "label": "Saint Paul",
    "value": "AG-06",
    "parent": "AG"
  },
  "AG-07": {
    "label": "Saint Peter",
    "value": "AG-07",
    "parent": "AG"
  },
  "AG-08": {
    "label": "Saint Philip",
    "value": "AG-08",
    "parent": "AG"
  },
  "AR": {
    "label": "Argentina",
    "value": "AR"
  },
  "AR-B": {
    "label": "Buenos Aires",
    "value": "AR-B",
    "parent": "AR"
  },
  "AR-K": {
    "label": "Catamarca",
    "value": "AR-K",
    "parent": "AR"
  },
  "AR-H": {
    "label": "Chaco",
    "value": "AR-H",
    "parent": "AR"
  },
  "AR-U": {
    "label": "Chubut",
    "value": "AR-U",
    "parent": "AR"
  },
  "AR-C": {
    "label": "Ciudad Autonoma de Buenos Aires",
    "value": "AR-C",
    "parent": "AR"
  },
  "AR-X": {
    "label": "Cordoba",
    "value": "AR-X",
    "parent": "AR"
  },
  "AR-W": {
    "label": "Corrientes",
    "value": "AR-W",
    "parent": "AR"
  },
  "AR-E": {
    "label": "Entre Rios",
    "value": "AR-E",
    "parent": "AR"
  },
  "AR-P": {
    "label": "Formosa",
    "value": "AR-P",
    "parent": "AR"
  },
  "AR-Y": {
    "label": "Jujuy",
    "value": "AR-Y",
    "parent": "AR"
  },
  "AR-L": {
    "label": "La Pampa",
    "value": "AR-L",
    "parent": "AR"
  },
  "AR-F": {
    "label": "La Rioja",
    "value": "AR-F",
    "parent": "AR"
  },
  "AR-M": {
    "label": "Mendoza",
    "value": "AR-M",
    "parent": "AR"
  },
  "AR-N": {
    "label": "Misiones",
    "value": "AR-N",
    "parent": "AR"
  },
  "AR-Q": {
    "label": "Neuquen",
    "value": "AR-Q",
    "parent": "AR"
  },
  "AR-R": {
    "label": "Rio Negro",
    "value": "AR-R",
    "parent": "AR"
  },
  "AR-A": {
    "label": "Salta",
    "value": "AR-A",
    "parent": "AR"
  },
  "AR-J": {
    "label": "San Juan",
    "value": "AR-J",
    "parent": "AR"
  },
  "AR-D": {
    "label": "San Luis",
    "value": "AR-D",
    "parent": "AR"
  },
  "AR-Z": {
    "label": "Santa Cruz",
    "value": "AR-Z",
    "parent": "AR"
  },
  "AR-S": {
    "label": "Santa Fe",
    "value": "AR-S",
    "parent": "AR"
  },
  "AR-G": {
    "label": "Santiago del Estero",
    "value": "AR-G",
    "parent": "AR"
  },
  "AR-V": {
    "label": "Tierra del Fuego",
    "value": "AR-V",
    "parent": "AR"
  },
  "AR-T": {
    "label": "Tucuman",
    "value": "AR-T",
    "parent": "AR"
  },
  "AM": {
    "label": "Armenia",
    "value": "AM"
  },
  "AM-AG": {
    "label": "Aragacotn",
    "value": "AM-AG",
    "parent": "AM"
  },
  "AM-AR": {
    "label": "Ararat",
    "value": "AM-AR",
    "parent": "AM"
  },
  "AM-AV": {
    "label": "Armavir",
    "value": "AM-AV",
    "parent": "AM"
  },
  "AM-ER": {
    "label": "Erevan",
    "value": "AM-ER",
    "parent": "AM"
  },
  "AM-GR": {
    "label": "Gegark'unik'",
    "value": "AM-GR",
    "parent": "AM"
  },
  "AM-KT": {
    "label": "Kotayk'",
    "value": "AM-KT",
    "parent": "AM"
  },
  "AM-LO": {
    "label": "Lori",
    "value": "AM-LO",
    "parent": "AM"
  },
  "AM-SH": {
    "label": "Sirak",
    "value": "AM-SH",
    "parent": "AM"
  },
  "AM-SU": {
    "label": "Syunik'",
    "value": "AM-SU",
    "parent": "AM"
  },
  "AM-TV": {
    "label": "Tavus",
    "value": "AM-TV",
    "parent": "AM"
  },
  "AM-VD": {
    "label": "Vayoc Jor",
    "value": "AM-VD",
    "parent": "AM"
  },
  "AW": {
    "label": "Aruba",
    "value": "AW"
  },
  "AU": {
    "label": "Australia",
    "value": "AU"
  },
  "AU-ACT": {
    "label": "Australian Capital Territory",
    "value": "AU-ACT",
    "parent": "AU"
  },
  "AU-NSW": {
    "label": "New South Wales",
    "value": "AU-NSW",
    "parent": "AU"
  },
  "AU-NT": {
    "label": "Northern Territory",
    "value": "AU-NT",
    "parent": "AU"
  },
  "AU-QLD": {
    "label": "Queensland",
    "value": "AU-QLD",
    "parent": "AU"
  },
  "AU-SA": {
    "label": "South Australia",
    "value": "AU-SA",
    "parent": "AU"
  },
  "AU-TAS": {
    "label": "Tasmania",
    "value": "AU-TAS",
    "parent": "AU"
  },
  "AU-VIC": {
    "label": "Victoria",
    "value": "AU-VIC",
    "parent": "AU"
  },
  "AU-WA": {
    "label": "Western Australia",
    "value": "AU-WA",
    "parent": "AU"
  },
  "AT": {
    "label": "Austria",
    "value": "AT"
  },
  "AT-1": {
    "label": "Burgenland",
    "value": "AT-1",
    "parent": "AT"
  },
  "AT-2": {
    "label": "Karnten",
    "value": "AT-2",
    "parent": "AT"
  },
  "AT-3": {
    "label": "Niederosterreich",
    "value": "AT-3",
    "parent": "AT"
  },
  "AT-4": {
    "label": "Oberosterreich",
    "value": "AT-4",
    "parent": "AT"
  },
  "AT-5": {
    "label": "Salzburg",
    "value": "AT-5",
    "parent": "AT"
  },
  "AT-6": {
    "label": "Steiermark",
    "value": "AT-6",
    "parent": "AT"
  },
  "AT-7": {
    "label": "Tirol",
    "value": "AT-7",
    "parent": "AT"
  },
  "AT-8": {
    "label": "Vorarlberg",
    "value": "AT-8",
    "parent": "AT"
  },
  "AT-9": {
    "label": "Wien",
    "value": "AT-9",
    "parent": "AT"
  },
  "AZ": {
    "label": "Azerbaijan",
    "value": "AZ"
  },
  "AZ-ABS": {
    "label": "Abseron",
    "value": "AZ-ABS",
    "parent": "AZ"
  },
  "AZ-AGC": {
    "label": "Agcabadi",
    "value": "AZ-AGC",
    "parent": "AZ"
  },
  "AZ-AGS": {
    "label": "Agdas",
    "value": "AZ-AGS",
    "parent": "AZ"
  },
  "AZ-AGA": {
    "label": "Agstafa",
    "value": "AZ-AGA",
    "parent": "AZ"
  },
  "AZ-AGU": {
    "label": "Agsu",
    "value": "AZ-AGU",
    "parent": "AZ"
  },
  "AZ-AST": {
    "label": "Astara",
    "value": "AZ-AST",
    "parent": "AZ"
  },
  "AZ-BA": {
    "label": "Baki",
    "value": "AZ-BA",
    "parent": "AZ"
  },
  "AZ-BAL": {
    "label": "Balakan",
    "value": "AZ-BAL",
    "parent": "AZ"
  },
  "AZ-BAR": {
    "label": "Barda",
    "value": "AZ-BAR",
    "parent": "AZ"
  },
  "AZ-BEY": {
    "label": "Beylaqan",
    "value": "AZ-BEY",
    "parent": "AZ"
  },
  "AZ-CAL": {
    "label": "Calilabad",
    "value": "AZ-CAL",
    "parent": "AZ"
  },
  "AZ-GAD": {
    "label": "Gadabay",
    "value": "AZ-GAD",
    "parent": "AZ"
  },
  "AZ-GA": {
    "label": "Ganca",
    "value": "AZ-GA",
    "parent": "AZ"
  },
  "AZ-GOR": {
    "label": "Goranboy",
    "value": "AZ-GOR",
    "parent": "AZ"
  },
  "AZ-GOY": {
    "label": "Goycay",
    "value": "AZ-GOY",
    "parent": "AZ"
  },
  "AZ-GYG": {
    "label": "Goygol",
    "value": "AZ-GYG",
    "parent": "AZ"
  },
  "AZ-IMI": {
    "label": "Imisli",
    "value": "AZ-IMI",
    "parent": "AZ"
  },
  "AZ-ISM": {
    "label": "Ismayilli",
    "value": "AZ-ISM",
    "parent": "AZ"
  },
  "AZ-KUR": {
    "label": "Kurdamir",
    "value": "AZ-KUR",
    "parent": "AZ"
  },
  "AZ-LA": {
    "label": "Lankaran",
    "value": "AZ-LA",
    "parent": "AZ"
  },
  "AZ-MAS": {
    "label": "Masalli",
    "value": "AZ-MAS",
    "parent": "AZ"
  },
  "AZ-MI": {
    "label": "Mingacevir",
    "value": "AZ-MI",
    "parent": "AZ"
  },
  "AZ-NX": {
    "label": "Naxcivan",
    "value": "AZ-NX",
    "parent": "AZ"
  },
  "AZ-NEF": {
    "label": "Neftcala",
    "value": "AZ-NEF",
    "parent": "AZ"
  },
  "AZ-OGU": {
    "label": "Oguz",
    "value": "AZ-OGU",
    "parent": "AZ"
  },
  "AZ-QAB": {
    "label": "Qabala",
    "value": "AZ-QAB",
    "parent": "AZ"
  },
  "AZ-QAZ": {
    "label": "Qazax",
    "value": "AZ-QAZ",
    "parent": "AZ"
  },
  "AZ-QBA": {
    "label": "Quba",
    "value": "AZ-QBA",
    "parent": "AZ"
  },
  "AZ-QUS": {
    "label": "Qusar",
    "value": "AZ-QUS",
    "parent": "AZ"
  },
  "AZ-SAT": {
    "label": "Saatli",
    "value": "AZ-SAT",
    "parent": "AZ"
  },
  "AZ-SAB": {
    "label": "Sabirabad",
    "value": "AZ-SAB",
    "parent": "AZ"
  },
  "AZ-SAK": {
    "label": "Saki",
    "value": "AZ-SAK",
    "parent": "AZ"
  },
  "AZ-SAL": {
    "label": "Salyan",
    "value": "AZ-SAL",
    "parent": "AZ"
  },
  "AZ-SMI": {
    "label": "Samaxi",
    "value": "AZ-SMI",
    "parent": "AZ"
  },
  "AZ-SKR": {
    "label": "Samkir",
    "value": "AZ-SKR",
    "parent": "AZ"
  },
  "AZ-SMX": {
    "label": "Samux",
    "value": "AZ-SMX",
    "parent": "AZ"
  },
  "AZ-SR": {
    "label": "Sirvan",
    "value": "AZ-SR",
    "parent": "AZ"
  },
  "AZ-SIY": {
    "label": "Siyazan",
    "value": "AZ-SIY",
    "parent": "AZ"
  },
  "AZ-SM": {
    "label": "Sumqayit",
    "value": "AZ-SM",
    "parent": "AZ"
  },
  "AZ-TAR": {
    "label": "Tartar",
    "value": "AZ-TAR",
    "parent": "AZ"
  },
  "AZ-TOV": {
    "label": "Tovuz",
    "value": "AZ-TOV",
    "parent": "AZ"
  },
  "AZ-UCA": {
    "label": "Ucar",
    "value": "AZ-UCA",
    "parent": "AZ"
  },
  "AZ-XAC": {
    "label": "Xacmaz",
    "value": "AZ-XAC",
    "parent": "AZ"
  },
  "AZ-XIZ": {
    "label": "Xizi",
    "value": "AZ-XIZ",
    "parent": "AZ"
  },
  "AZ-YAR": {
    "label": "Yardimli",
    "value": "AZ-YAR",
    "parent": "AZ"
  },
  "AZ-YEV": {
    "label": "Yevlax",
    "value": "AZ-YEV",
    "parent": "AZ"
  },
  "AZ-ZAQ": {
    "label": "Zaqatala",
    "value": "AZ-ZAQ",
    "parent": "AZ"
  },
  "AZ-ZAR": {
    "label": "Zardab",
    "value": "AZ-ZAR",
    "parent": "AZ"
  },
  "BS": {
    "label": "Bahamas",
    "value": "BS"
  },
  "BS-BP": {
    "label": "Black Point",
    "value": "BS-BP",
    "parent": "BS"
  },
  "BS-CO": {
    "label": "Central Abaco",
    "value": "BS-CO",
    "parent": "BS"
  },
  "BS-FP": {
    "label": "City of Freeport",
    "value": "BS-FP",
    "parent": "BS"
  },
  "BS-EG": {
    "label": "East Grand Bahama",
    "value": "BS-EG",
    "parent": "BS"
  },
  "BS-HI": {
    "label": "Harbour Island",
    "value": "BS-HI",
    "parent": "BS"
  },
  "BS-LI": {
    "label": "Long Island",
    "value": "BS-LI",
    "parent": "BS"
  },
  "BS-MI": {
    "label": "Moore's Island",
    "value": "BS-MI",
    "parent": "BS"
  },
  "BS-NP": {
    "label": "New Providence",
    "value": "BS-NP",
    "parent": "BS"
  },
  "BS-NS": {
    "label": "North Andros",
    "value": "BS-NS",
    "parent": "BS"
  },
  "BS-NE": {
    "label": "North Eleuthera",
    "value": "BS-NE",
    "parent": "BS"
  },
  "BS-SS": {
    "label": "San Salvador",
    "value": "BS-SS",
    "parent": "BS"
  },
  "BS-SE": {
    "label": "South Eleuthera",
    "value": "BS-SE",
    "parent": "BS"
  },
  "BS-WG": {
    "label": "West Grand Bahama",
    "value": "BS-WG",
    "parent": "BS"
  },
  "BH": {
    "label": "Bahrain",
    "value": "BH"
  },
  "BH-13": {
    "label": "Al 'Asimah",
    "value": "BH-13",
    "parent": "BH"
  },
  "BH-14": {
    "label": "Al Janubiyah",
    "value": "BH-14",
    "parent": "BH"
  },
  "BH-15": {
    "label": "Al Muharraq",
    "value": "BH-15",
    "parent": "BH"
  },
  "BH-17": {
    "label": "Ash Shamaliyah",
    "value": "BH-17",
    "parent": "BH"
  },
  "BD": {
    "label": "Bangladesh",
    "value": "BD"
  },
  "BD-A": {
    "label": "Barishal",
    "value": "BD-A",
    "parent": "BD"
  },
  "BD-B": {
    "label": "Chattogram",
    "value": "BD-B",
    "parent": "BD"
  },
  "BD-C": {
    "label": "Dhaka",
    "value": "BD-C",
    "parent": "BD"
  },
  "BD-D": {
    "label": "Khulna",
    "value": "BD-D",
    "parent": "BD"
  },
  "BD-E": {
    "label": "Rajshahi",
    "value": "BD-E",
    "parent": "BD"
  },
  "BD-F": {
    "label": "Rangpur",
    "value": "BD-F",
    "parent": "BD"
  },
  "BD-G": {
    "label": "Sylhet",
    "value": "BD-G",
    "parent": "BD"
  },
  "BB": {
    "label": "Barbados",
    "value": "BB"
  },
  "BB-01": {
    "label": "Christ Church",
    "value": "BB-01",
    "parent": "BB"
  },
  "BB-02": {
    "label": "Saint Andrew",
    "value": "BB-02",
    "parent": "BB"
  },
  "BB-03": {
    "label": "Saint George",
    "value": "BB-03",
    "parent": "BB"
  },
  "BB-04": {
    "label": "Saint James",
    "value": "BB-04",
    "parent": "BB"
  },
  "BB-05": {
    "label": "Saint John",
    "value": "BB-05",
    "parent": "BB"
  },
  "BB-06": {
    "label": "Saint Joseph",
    "value": "BB-06",
    "parent": "BB"
  },
  "BB-07": {
    "label": "Saint Lucy",
    "value": "BB-07",
    "parent": "BB"
  },
  "BB-08": {
    "label": "Saint Michael",
    "value": "BB-08",
    "parent": "BB"
  },
  "BB-09": {
    "label": "Saint Peter",
    "value": "BB-09",
    "parent": "BB"
  },
  "BB-10": {
    "label": "Saint Philip",
    "value": "BB-10",
    "parent": "BB"
  },
  "BB-11": {
    "label": "Saint Thomas",
    "value": "BB-11",
    "parent": "BB"
  },
  "BY": {
    "label": "Belarus",
    "value": "BY"
  },
  "BY-BR": {
    "label": "Brestskaya voblasts'",
    "value": "BY-BR",
    "parent": "BY"
  },
  "BY-HO": {
    "label": "Homyel'skaya voblasts'",
    "value": "BY-HO",
    "parent": "BY"
  },
  "BY-HM": {
    "label": "Horad Minsk",
    "value": "BY-HM",
    "parent": "BY"
  },
  "BY-HR": {
    "label": "Hrodzyenskaya voblasts'",
    "value": "BY-HR",
    "parent": "BY"
  },
  "BY-MA": {
    "label": "Mahilyowskaya voblasts'",
    "value": "BY-MA",
    "parent": "BY"
  },
  "BY-MI": {
    "label": "Minskaya voblasts'",
    "value": "BY-MI",
    "parent": "BY"
  },
  "BY-VI": {
    "label": "Vitsyebskaya voblasts'",
    "value": "BY-VI",
    "parent": "BY"
  },
  "BE": {
    "label": "Belgium",
    "value": "BE"
  },
  "BE-VAN": {
    "label": "Antwerpen",
    "value": "BE-VAN",
    "parent": "BE"
  },
  "BE-WBR": {
    "label": "Brabant wallon",
    "value": "BE-WBR",
    "parent": "BE"
  },
  "BE-BRU": {
    "label": "Brussels Hoofdstedelijk Gewest",
    "value": "BE-BRU",
    "parent": "BE"
  },
  "BE-WHT": {
    "label": "Hainaut",
    "value": "BE-WHT",
    "parent": "BE"
  },
  "BE-WLG": {
    "label": "Liege",
    "value": "BE-WLG",
    "parent": "BE"
  },
  "BE-VLI": {
    "label": "Limburg",
    "value": "BE-VLI",
    "parent": "BE"
  },
  "BE-WLX": {
    "label": "Luxembourg",
    "value": "BE-WLX",
    "parent": "BE"
  },
  "BE-WNA": {
    "label": "Namur",
    "value": "BE-WNA",
    "parent": "BE"
  },
  "BE-VOV": {
    "label": "Oost-Vlaanderen",
    "value": "BE-VOV",
    "parent": "BE"
  },
  "BE-VBR": {
    "label": "Vlaams-Brabant",
    "value": "BE-VBR",
    "parent": "BE"
  },
  "BE-VWV": {
    "label": "West-Vlaanderen",
    "value": "BE-VWV",
    "parent": "BE"
  },
  "BZ": {
    "label": "Belize",
    "value": "BZ"
  },
  "BZ-BZ": {
    "label": "Belize",
    "value": "BZ-BZ",
    "parent": "BZ"
  },
  "BZ-CY": {
    "label": "Cayo",
    "value": "BZ-CY",
    "parent": "BZ"
  },
  "BZ-CZL": {
    "label": "Corozal",
    "value": "BZ-CZL",
    "parent": "BZ"
  },
  "BZ-OW": {
    "label": "Orange Walk",
    "value": "BZ-OW",
    "parent": "BZ"
  },
  "BZ-SC": {
    "label": "Stann Creek",
    "value": "BZ-SC",
    "parent": "BZ"
  },
  "BZ-TOL": {
    "label": "Toledo",
    "value": "BZ-TOL",
    "parent": "BZ"
  },
  "BJ": {
    "label": "Benin",
    "value": "BJ"
  },
  "BJ-AK": {
    "label": "Atacora",
    "value": "BJ-AK",
    "parent": "BJ"
  },
  "BJ-AQ": {
    "label": "Atlantique",
    "value": "BJ-AQ",
    "parent": "BJ"
  },
  "BJ-BO": {
    "label": "Borgou",
    "value": "BJ-BO",
    "parent": "BJ"
  },
  "BJ-CO": {
    "label": "Collines",
    "value": "BJ-CO",
    "parent": "BJ"
  },
  "BJ-LI": {
    "label": "Littoral",
    "value": "BJ-LI",
    "parent": "BJ"
  },
  "BJ-OU": {
    "label": "Oueme",
    "value": "BJ-OU",
    "parent": "BJ"
  },
  "BJ-PL": {
    "label": "Plateau",
    "value": "BJ-PL",
    "parent": "BJ"
  },
  "BJ-ZO": {
    "label": "Zou",
    "value": "BJ-ZO",
    "parent": "BJ"
  },
  "BM": {
    "label": "Bermuda",
    "value": "BM"
  },
  "BT": {
    "label": "Bhutan",
    "value": "BT"
  },
  "BT-33": {
    "label": "Bumthang",
    "value": "BT-33",
    "parent": "BT"
  },
  "BT-12": {
    "label": "Chhukha",
    "value": "BT-12",
    "parent": "BT"
  },
  "BT-22": {
    "label": "Dagana",
    "value": "BT-22",
    "parent": "BT"
  },
  "BT-GA": {
    "label": "Gasa",
    "value": "BT-GA",
    "parent": "BT"
  },
  "BT-44": {
    "label": "Lhuentse",
    "value": "BT-44",
    "parent": "BT"
  },
  "BT-42": {
    "label": "Monggar",
    "value": "BT-42",
    "parent": "BT"
  },
  "BT-11": {
    "label": "Paro",
    "value": "BT-11",
    "parent": "BT"
  },
  "BT-43": {
    "label": "Pema Gatshel",
    "value": "BT-43",
    "parent": "BT"
  },
  "BT-23": {
    "label": "Punakha",
    "value": "BT-23",
    "parent": "BT"
  },
  "BT-45": {
    "label": "Samdrup Jongkhar",
    "value": "BT-45",
    "parent": "BT"
  },
  "BT-14": {
    "label": "Samtse",
    "value": "BT-14",
    "parent": "BT"
  },
  "BT-31": {
    "label": "Sarpang",
    "value": "BT-31",
    "parent": "BT"
  },
  "BT-15": {
    "label": "Thimphu",
    "value": "BT-15",
    "parent": "BT"
  },
  "BT-41": {
    "label": "Trashigang",
    "value": "BT-41",
    "parent": "BT"
  },
  "BT-32": {
    "label": "Trongsa",
    "value": "BT-32",
    "parent": "BT"
  },
  "BT-21": {
    "label": "Tsirang",
    "value": "BT-21",
    "parent": "BT"
  },
  "BT-24": {
    "label": "Wangdue Phodrang",
    "value": "BT-24",
    "parent": "BT"
  },
  "BO": {
    "label": "Bolivia, Plurinational State of",
    "value": "BO"
  },
  "BO-H": {
    "label": "Chuquisaca",
    "value": "BO-H",
    "parent": "BO"
  },
  "BO-C": {
    "label": "Cochabamba",
    "value": "BO-C",
    "parent": "BO"
  },
  "BO-B": {
    "label": "El Beni",
    "value": "BO-B",
    "parent": "BO"
  },
  "BO-L": {
    "label": "La Paz",
    "value": "BO-L",
    "parent": "BO"
  },
  "BO-O": {
    "label": "Oruro",
    "value": "BO-O",
    "parent": "BO"
  },
  "BO-N": {
    "label": "Pando",
    "value": "BO-N",
    "parent": "BO"
  },
  "BO-P": {
    "label": "Potosi",
    "value": "BO-P",
    "parent": "BO"
  },
  "BO-S": {
    "label": "Santa Cruz",
    "value": "BO-S",
    "parent": "BO"
  },
  "BO-T": {
    "label": "Tarija",
    "value": "BO-T",
    "parent": "BO"
  },
  "BQ": {
    "label": "Bonaire, Sint Eustatius and Saba",
    "value": "BQ"
  },
  "BQ-BO": {
    "label": "Bonaire",
    "value": "BQ-BO",
    "parent": "BQ"
  },
  "BQ-SA": {
    "label": "Saba",
    "value": "BQ-SA",
    "parent": "BQ"
  },
  "BQ-SE": {
    "label": "Sint Eustatius",
    "value": "BQ-SE",
    "parent": "BQ"
  },
  "BA": {
    "label": "Bosnia and Herzegovina",
    "value": "BA"
  },
  "BA-BRC": {
    "label": "Brcko distrikt",
    "value": "BA-BRC",
    "parent": "BA"
  },
  "BA-BIH": {
    "label": "Federacija Bosne i Hercegovine",
    "value": "BA-BIH",
    "parent": "BA"
  },
  "BA-SRP": {
    "label": "Republika Srpska",
    "value": "BA-SRP",
    "parent": "BA"
  },
  "BW": {
    "label": "Botswana",
    "value": "BW"
  },
  "BW-CE": {
    "label": "Central",
    "value": "BW-CE",
    "parent": "BW"
  },
  "BW-CH": {
    "label": "Chobe",
    "value": "BW-CH",
    "parent": "BW"
  },
  "BW-GH": {
    "label": "Ghanzi",
    "value": "BW-GH",
    "parent": "BW"
  },
  "BW-KG": {
    "label": "Kgalagadi",
    "value": "BW-KG",
    "parent": "BW"
  },
  "BW-KL": {
    "label": "Kgatleng",
    "value": "BW-KL",
    "parent": "BW"
  },
  "BW-KW": {
    "label": "Kweneng",
    "value": "BW-KW",
    "parent": "BW"
  },
  "BW-NE": {
    "label": "North East",
    "value": "BW-NE",
    "parent": "BW"
  },
  "BW-NW": {
    "label": "North West",
    "value": "BW-NW",
    "parent": "BW"
  },
  "BW-SE": {
    "label": "South East",
    "value": "BW-SE",
    "parent": "BW"
  },
  "BW-SO": {
    "label": "Southern",
    "value": "BW-SO",
    "parent": "BW"
  },
  "BV": {
    "label": "Bouvet Island",
    "value": "BV"
  },
  "BR": {
    "label": "Brazil",
    "value": "BR"
  },
  "BR-AC": {
    "label": "Acre",
    "value": "BR-AC",
    "parent": "BR"
  },
  "BR-AL": {
    "label": "Alagoas",
    "value": "BR-AL",
    "parent": "BR"
  },
  "BR-AP": {
    "label": "Amapa",
    "value": "BR-AP",
    "parent": "BR"
  },
  "BR-AM": {
    "label": "Amazonas",
    "value": "BR-AM",
    "parent": "BR"
  },
  "BR-BA": {
    "label": "Bahia",
    "value": "BR-BA",
    "parent": "BR"
  },
  "BR-CE": {
    "label": "Ceara",
    "value": "BR-CE",
    "parent": "BR"
  },
  "BR-DF": {
    "label": "Distrito Federal",
    "value": "BR-DF",
    "parent": "BR"
  },
  "BR-ES": {
    "label": "Espirito Santo",
    "value": "BR-ES",
    "parent": "BR"
  },
  "BR-GO": {
    "label": "Goias",
    "value": "BR-GO",
    "parent": "BR"
  },
  "BR-MA": {
    "label": "Maranhao",
    "value": "BR-MA",
    "parent": "BR"
  },
  "BR-MT": {
    "label": "Mato Grosso",
    "value": "BR-MT",
    "parent": "BR"
  },
  "BR-MS": {
    "label": "Mato Grosso do Sul",
    "value": "BR-MS",
    "parent": "BR"
  },
  "BR-MG": {
    "label": "Minas Gerais",
    "value": "BR-MG",
    "parent": "BR"
  },
  "BR-PA": {
    "label": "Para",
    "value": "BR-PA",
    "parent": "BR"
  },
  "BR-PB": {
    "label": "Paraiba",
    "value": "BR-PB",
    "parent": "BR"
  },
  "BR-PR": {
    "label": "Parana",
    "value": "BR-PR",
    "parent": "BR"
  },
  "BR-PE": {
    "label": "Pernambuco",
    "value": "BR-PE",
    "parent": "BR"
  },
  "BR-PI": {
    "label": "Piaui",
    "value": "BR-PI",
    "parent": "BR"
  },
  "BR-RJ": {
    "label": "Rio de Janeiro",
    "value": "BR-RJ",
    "parent": "BR"
  },
  "BR-RN": {
    "label": "Rio Grande do Norte",
    "value": "BR-RN",
    "parent": "BR"
  },
  "BR-RS": {
    "label": "Rio Grande do Sul",
    "value": "BR-RS",
    "parent": "BR"
  },
  "BR-RO": {
    "label": "Rondonia",
    "value": "BR-RO",
    "parent": "BR"
  },
  "BR-RR": {
    "label": "Roraima",
    "value": "BR-RR",
    "parent": "BR"
  },
  "BR-SC": {
    "label": "Santa Catarina",
    "value": "BR-SC",
    "parent": "BR"
  },
  "BR-SP": {
    "label": "Sao Paulo",
    "value": "BR-SP",
    "parent": "BR"
  },
  "BR-SE": {
    "label": "Sergipe",
    "value": "BR-SE",
    "parent": "BR"
  },
  "BR-TO": {
    "label": "Tocantins",
    "value": "BR-TO",
    "parent": "BR"
  },
  "IO": {
    "label": "British Indian Ocean Territory",
    "value": "IO"
  },
  "BN": {
    "label": "Brunei Darussalam",
    "value": "BN"
  },
  "BN-BE": {
    "label": "Belait",
    "value": "BN-BE",
    "parent": "BN"
  },
  "BN-BM": {
    "label": "Brunei-Muara",
    "value": "BN-BM",
    "parent": "BN"
  },
  "BN-TE": {
    "label": "Temburong",
    "value": "BN-TE",
    "parent": "BN"
  },
  "BN-TU": {
    "label": "Tutong",
    "value": "BN-TU",
    "parent": "BN"
  },
  "BG": {
    "label": "Bulgaria",
    "value": "BG"
  },
  "BG-01": {
    "label": "Blagoevgrad",
    "value": "BG-01",
    "parent": "BG"
  },
  "BG-02": {
    "label": "Burgas",
    "value": "BG-02",
    "parent": "BG"
  },
  "BG-08": {
    "label": "Dobrich",
    "value": "BG-08",
    "parent": "BG"
  },
  "BG-07": {
    "label": "Gabrovo",
    "value": "BG-07",
    "parent": "BG"
  },
  "BG-26": {
    "label": "Haskovo",
    "value": "BG-26",
    "parent": "BG"
  },
  "BG-09": {
    "label": "Kardzhali",
    "value": "BG-09",
    "parent": "BG"
  },
  "BG-10": {
    "label": "Kyustendil",
    "value": "BG-10",
    "parent": "BG"
  },
  "BG-11": {
    "label": "Lovech",
    "value": "BG-11",
    "parent": "BG"
  },
  "BG-12": {
    "label": "Montana",
    "value": "BG-12",
    "parent": "BG"
  },
  "BG-13": {
    "label": "Pazardzhik",
    "value": "BG-13",
    "parent": "BG"
  },
  "BG-14": {
    "label": "Pernik",
    "value": "BG-14",
    "parent": "BG"
  },
  "BG-15": {
    "label": "Pleven",
    "value": "BG-15",
    "parent": "BG"
  },
  "BG-16": {
    "label": "Plovdiv",
    "value": "BG-16",
    "parent": "BG"
  },
  "BG-17": {
    "label": "Razgrad",
    "value": "BG-17",
    "parent": "BG"
  },
  "BG-18": {
    "label": "Ruse",
    "value": "BG-18",
    "parent": "BG"
  },
  "BG-27": {
    "label": "Shumen",
    "value": "BG-27",
    "parent": "BG"
  },
  "BG-19": {
    "label": "Silistra",
    "value": "BG-19",
    "parent": "BG"
  },
  "BG-20": {
    "label": "Sliven",
    "value": "BG-20",
    "parent": "BG"
  },
  "BG-21": {
    "label": "Smolyan",
    "value": "BG-21",
    "parent": "BG"
  },
  "BG-23": {
    "label": "Sofia",
    "value": "BG-23",
    "parent": "BG"
  },
  "BG-22": {
    "label": "Sofia (stolitsa)",
    "value": "BG-22",
    "parent": "BG"
  },
  "BG-24": {
    "label": "Stara Zagora",
    "value": "BG-24",
    "parent": "BG"
  },
  "BG-25": {
    "label": "Targovishte",
    "value": "BG-25",
    "parent": "BG"
  },
  "BG-03": {
    "label": "Varna",
    "value": "BG-03",
    "parent": "BG"
  },
  "BG-04": {
    "label": "Veliko Tarnovo",
    "value": "BG-04",
    "parent": "BG"
  },
  "BG-05": {
    "label": "Vidin",
    "value": "BG-05",
    "parent": "BG"
  },
  "BG-06": {
    "label": "Vratsa",
    "value": "BG-06",
    "parent": "BG"
  },
  "BG-28": {
    "label": "Yambol",
    "value": "BG-28",
    "parent": "BG"
  },
  "BF": {
    "label": "Burkina Faso",
    "value": "BF"
  },
  "BF-BAL": {
    "label": "Bale",
    "value": "BF-BAL",
    "parent": "BF"
  },
  "BF-BAM": {
    "label": "Bam",
    "value": "BF-BAM",
    "parent": "BF"
  },
  "BF-BAN": {
    "label": "Banwa",
    "value": "BF-BAN",
    "parent": "BF"
  },
  "BF-BAZ": {
    "label": "Bazega",
    "value": "BF-BAZ",
    "parent": "BF"
  },
  "BF-BGR": {
    "label": "Bougouriba",
    "value": "BF-BGR",
    "parent": "BF"
  },
  "BF-BLG": {
    "label": "Boulgou",
    "value": "BF-BLG",
    "parent": "BF"
  },
  "BF-BLK": {
    "label": "Boulkiemde",
    "value": "BF-BLK",
    "parent": "BF"
  },
  "BF-COM": {
    "label": "Comoe",
    "value": "BF-COM",
    "parent": "BF"
  },
  "BF-GAN": {
    "label": "Ganzourgou",
    "value": "BF-GAN",
    "parent": "BF"
  },
  "BF-GNA": {
    "label": "Gnagna",
    "value": "BF-GNA",
    "parent": "BF"
  },
  "BF-GOU": {
    "label": "Gourma",
    "value": "BF-GOU",
    "parent": "BF"
  },
  "BF-HOU": {
    "label": "Houet",
    "value": "BF-HOU",
    "parent": "BF"
  },
  "BF-KAD": {
    "label": "Kadiogo",
    "value": "BF-KAD",
    "parent": "BF"
  },
  "BF-KEN": {
    "label": "Kenedougou",
    "value": "BF-KEN",
    "parent": "BF"
  },
  "BF-KMP": {
    "label": "Kompienga",
    "value": "BF-KMP",
    "parent": "BF"
  },
  "BF-KOS": {
    "label": "Kossi",
    "value": "BF-KOS",
    "parent": "BF"
  },
  "BF-KOT": {
    "label": "Kouritenga",
    "value": "BF-KOT",
    "parent": "BF"
  },
  "BF-KOW": {
    "label": "Kourweogo",
    "value": "BF-KOW",
    "parent": "BF"
  },
  "BF-LER": {
    "label": "Leraba",
    "value": "BF-LER",
    "parent": "BF"
  },
  "BF-LOR": {
    "label": "Loroum",
    "value": "BF-LOR",
    "parent": "BF"
  },
  "BF-MOU": {
    "label": "Mouhoun",
    "value": "BF-MOU",
    "parent": "BF"
  },
  "BF-NAO": {
    "label": "Nahouri",
    "value": "BF-NAO",
    "parent": "BF"
  },
  "BF-NAM": {
    "label": "Namentenga",
    "value": "BF-NAM",
    "parent": "BF"
  },
  "BF-NAY": {
    "label": "Nayala",
    "value": "BF-NAY",
    "parent": "BF"
  },
  "BF-OUB": {
    "label": "Oubritenga",
    "value": "BF-OUB",
    "parent": "BF"
  },
  "BF-OUD": {
    "label": "Oudalan",
    "value": "BF-OUD",
    "parent": "BF"
  },
  "BF-PAS": {
    "label": "Passore",
    "value": "BF-PAS",
    "parent": "BF"
  },
  "BF-SMT": {
    "label": "Sanmatenga",
    "value": "BF-SMT",
    "parent": "BF"
  },
  "BF-SEN": {
    "label": "Seno",
    "value": "BF-SEN",
    "parent": "BF"
  },
  "BF-SIS": {
    "label": "Sissili",
    "value": "BF-SIS",
    "parent": "BF"
  },
  "BF-SOM": {
    "label": "Soum",
    "value": "BF-SOM",
    "parent": "BF"
  },
  "BF-SOR": {
    "label": "Sourou",
    "value": "BF-SOR",
    "parent": "BF"
  },
  "BF-TAP": {
    "label": "Tapoa",
    "value": "BF-TAP",
    "parent": "BF"
  },
  "BF-TUI": {
    "label": "Tuy",
    "value": "BF-TUI",
    "parent": "BF"
  },
  "BF-YAT": {
    "label": "Yatenga",
    "value": "BF-YAT",
    "parent": "BF"
  },
  "BF-ZIR": {
    "label": "Ziro",
    "value": "BF-ZIR",
    "parent": "BF"
  },
  "BF-ZON": {
    "label": "Zondoma",
    "value": "BF-ZON",
    "parent": "BF"
  },
  "BF-ZOU": {
    "label": "Zoundweogo",
    "value": "BF-ZOU",
    "parent": "BF"
  },
  "BI": {
    "label": "Burundi",
    "value": "BI"
  },
  "BI-BM": {
    "label": "Bujumbura Mairie",
    "value": "BI-BM",
    "parent": "BI"
  },
  "BI-BR": {
    "label": "Bururi",
    "value": "BI-BR",
    "parent": "BI"
  },
  "BI-CI": {
    "label": "Cibitoke",
    "value": "BI-CI",
    "parent": "BI"
  },
  "BI-GI": {
    "label": "Gitega",
    "value": "BI-GI",
    "parent": "BI"
  },
  "BI-KI": {
    "label": "Kirundo",
    "value": "BI-KI",
    "parent": "BI"
  },
  "BI-MW": {
    "label": "Mwaro",
    "value": "BI-MW",
    "parent": "BI"
  },
  "BI-NG": {
    "label": "Ngozi",
    "value": "BI-NG",
    "parent": "BI"
  },
  "BI-RM": {
    "label": "Rumonge",
    "value": "BI-RM",
    "parent": "BI"
  },
  "BI-RT": {
    "label": "Rutana",
    "value": "BI-RT",
    "parent": "BI"
  },
  "BI-RY": {
    "label": "Ruyigi",
    "value": "BI-RY",
    "parent": "BI"
  },
  "CV": {
    "label": "Cabo Verde",
    "value": "CV"
  },
  "CV-BV": {
    "label": "Boa Vista",
    "value": "CV-BV",
    "parent": "CV"
  },
  "CV-PN": {
    "label": "Porto Novo",
    "value": "CV-PN",
    "parent": "CV"
  },
  "CV-PR": {
    "label": "Praia",
    "value": "CV-PR",
    "parent": "CV"
  },
  "CV-RG": {
    "label": "Ribeira Grande",
    "value": "CV-RG",
    "parent": "CV"
  },
  "CV-RS": {
    "label": "Ribeira Grande de Santiago",
    "value": "CV-RS",
    "parent": "CV"
  },
  "CV-SL": {
    "label": "Sal",
    "value": "CV-SL",
    "parent": "CV"
  },
  "CV-SF": {
    "label": "Sao Filipe",
    "value": "CV-SF",
    "parent": "CV"
  },
  "CV-SO": {
    "label": "Sao Lourenco dos Orgaos",
    "value": "CV-SO",
    "parent": "CV"
  },
  "CV-SS": {
    "label": "Sao Salvador do Mundo",
    "value": "CV-SS",
    "parent": "CV"
  },
  "CV-SV": {
    "label": "Sao Vicente",
    "value": "CV-SV",
    "parent": "CV"
  },
  "CV-TA": {
    "label": "Tarrafal",
    "value": "CV-TA",
    "parent": "CV"
  },
  "KH": {
    "label": "Cambodia",
    "value": "KH"
  },
  "KH-2": {
    "label": "Baat Dambang",
    "value": "KH-2",
    "parent": "KH"
  },
  "KH-1": {
    "label": "Banteay Mean Choay",
    "value": "KH-1",
    "parent": "KH"
  },
  "KH-23": {
    "label": "Kaeb",
    "value": "KH-23",
    "parent": "KH"
  },
  "KH-3": {
    "label": "Kampong Chaam",
    "value": "KH-3",
    "parent": "KH"
  },
  "KH-4": {
    "label": "Kampong Chhnang",
    "value": "KH-4",
    "parent": "KH"
  },
  "KH-5": {
    "label": "Kampong Spueu",
    "value": "KH-5",
    "parent": "KH"
  },
  "KH-6": {
    "label": "Kampong Thum",
    "value": "KH-6",
    "parent": "KH"
  },
  "KH-7": {
    "label": "Kampot",
    "value": "KH-7",
    "parent": "KH"
  },
  "KH-8": {
    "label": "Kandaal",
    "value": "KH-8",
    "parent": "KH"
  },
  "KH-9": {
    "label": "Kaoh Kong",
    "value": "KH-9",
    "parent": "KH"
  },
  "KH-10": {
    "label": "Kracheh",
    "value": "KH-10",
    "parent": "KH"
  },
  "KH-11": {
    "label": "Mondol Kiri",
    "value": "KH-11",
    "parent": "KH"
  },
  "KH-22": {
    "label": "Otdar Mean Chey",
    "value": "KH-22",
    "parent": "KH"
  },
  "KH-24": {
    "label": "Pailin",
    "value": "KH-24",
    "parent": "KH"
  },
  "KH-12": {
    "label": "Phnom Penh",
    "value": "KH-12",
    "parent": "KH"
  },
  "KH-15": {
    "label": "Pousaat",
    "value": "KH-15",
    "parent": "KH"
  },
  "KH-18": {
    "label": "Preah Sihanouk",
    "value": "KH-18",
    "parent": "KH"
  },
  "KH-13": {
    "label": "Preah Vihear",
    "value": "KH-13",
    "parent": "KH"
  },
  "KH-14": {
    "label": "Prey Veaeng",
    "value": "KH-14",
    "parent": "KH"
  },
  "KH-16": {
    "label": "Rotanak Kiri",
    "value": "KH-16",
    "parent": "KH"
  },
  "KH-17": {
    "label": "Siem Reab",
    "value": "KH-17",
    "parent": "KH"
  },
  "KH-19": {
    "label": "Stueng Traeng",
    "value": "KH-19",
    "parent": "KH"
  },
  "KH-20": {
    "label": "Svaay Rieng",
    "value": "KH-20",
    "parent": "KH"
  },
  "KH-21": {
    "label": "Taakaev",
    "value": "KH-21",
    "parent": "KH"
  },
  "CM": {
    "label": "Cameroon",
    "value": "CM"
  },
  "CM-AD": {
    "label": "Adamaoua",
    "value": "CM-AD",
    "parent": "CM"
  },
  "CM-CE": {
    "label": "Centre",
    "value": "CM-CE",
    "parent": "CM"
  },
  "CM-ES": {
    "label": "Est",
    "value": "CM-ES",
    "parent": "CM"
  },
  "CM-EN": {
    "label": "Extreme-Nord",
    "value": "CM-EN",
    "parent": "CM"
  },
  "CM-LT": {
    "label": "Littoral",
    "value": "CM-LT",
    "parent": "CM"
  },
  "CM-NO": {
    "label": "Nord",
    "value": "CM-NO",
    "parent": "CM"
  },
  "CM-NW": {
    "label": "Nord-Ouest",
    "value": "CM-NW",
    "parent": "CM"
  },
  "CM-OU": {
    "label": "Ouest",
    "value": "CM-OU",
    "parent": "CM"
  },
  "CM-SU": {
    "label": "Sud",
    "value": "CM-SU",
    "parent": "CM"
  },
  "CM-SW": {
    "label": "Sud-Ouest",
    "value": "CM-SW",
    "parent": "CM"
  },
  "CA": {
    "label": "Canada",
    "value": "CA"
  },
  "CA-AB": {
    "label": "Alberta",
    "value": "CA-AB",
    "parent": "CA"
  },
  "CA-BC": {
    "label": "British Columbia",
    "value": "CA-BC",
    "parent": "CA"
  },
  "CA-MB": {
    "label": "Manitoba",
    "value": "CA-MB",
    "parent": "CA"
  },
  "CA-NB": {
    "label": "New Brunswick",
    "value": "CA-NB",
    "parent": "CA"
  },
  "CA-NL": {
    "label": "Newfoundland and Labrador",
    "value": "CA-NL",
    "parent": "CA"
  },
  "CA-NT": {
    "label": "Northwest Territories",
    "value": "CA-NT",
    "parent": "CA"
  },
  "CA-NS": {
    "label": "Nova Scotia",
    "value": "CA-NS",
    "parent": "CA"
  },
  "CA-NU": {
    "label": "Nunavut",
    "value": "CA-NU",
    "parent": "CA"
  },
  "CA-ON": {
    "label": "Ontario",
    "value": "CA-ON",
    "parent": "CA"
  },
  "CA-PE": {
    "label": "Prince Edward Island",
    "value": "CA-PE",
    "parent": "CA"
  },
  "CA-QC": {
    "label": "Quebec",
    "value": "CA-QC",
    "parent": "CA"
  },
  "CA-SK": {
    "label": "Saskatchewan",
    "value": "CA-SK",
    "parent": "CA"
  },
  "CA-YT": {
    "label": "Yukon",
    "value": "CA-YT",
    "parent": "CA"
  },
  "KY": {
    "label": "Cayman Islands",
    "value": "KY"
  },
  "CF": {
    "label": "Central African Republic",
    "value": "CF"
  },
  "CF-BB": {
    "label": "Bamingui-Bangoran",
    "value": "CF-BB",
    "parent": "CF"
  },
  "CF-BGF": {
    "label": "Bangui",
    "value": "CF-BGF",
    "parent": "CF"
  },
  "CF-KB": {
    "label": "Gribingui",
    "value": "CF-KB",
    "parent": "CF"
  },
  "CF-HM": {
    "label": "Haut-Mbomou",
    "value": "CF-HM",
    "parent": "CF"
  },
  "CF-HK": {
    "label": "Haute-Kotto",
    "value": "CF-HK",
    "parent": "CF"
  },
  "CF-KG": {
    "label": "Kemo-Gribingui",
    "value": "CF-KG",
    "parent": "CF"
  },
  "CF-LB": {
    "label": "Lobaye",
    "value": "CF-LB",
    "parent": "CF"
  },
  "CF-HS": {
    "label": "Mambere-Kadei",
    "value": "CF-HS",
    "parent": "CF"
  },
  "CF-NM": {
    "label": "Nana-Mambere",
    "value": "CF-NM",
    "parent": "CF"
  },
  "CF-UK": {
    "label": "Ouaka",
    "value": "CF-UK",
    "parent": "CF"
  },
  "CF-AC": {
    "label": "Ouham",
    "value": "CF-AC",
    "parent": "CF"
  },
  "CF-OP": {
    "label": "Ouham-Pende",
    "value": "CF-OP",
    "parent": "CF"
  },
  "CF-VK": {
    "label": "Vakaga",
    "value": "CF-VK",
    "parent": "CF"
  },
  "TD": {
    "label": "Chad",
    "value": "TD"
  },
  "TD-BG": {
    "label": "Bahr el Ghazal",
    "value": "TD-BG",
    "parent": "TD"
  },
  "TD-CB": {
    "label": "Chari-Baguirmi",
    "value": "TD-CB",
    "parent": "TD"
  },
  "TD-GR": {
    "label": "Guera",
    "value": "TD-GR",
    "parent": "TD"
  },
  "TD-LC": {
    "label": "Lac",
    "value": "TD-LC",
    "parent": "TD"
  },
  "TD-SI": {
    "label": "Sila",
    "value": "TD-SI",
    "parent": "TD"
  },
  "TD-ND": {
    "label": "Ville de Ndjamena",
    "value": "TD-ND",
    "parent": "TD"
  },
  "CL": {
    "label": "Chile",
    "value": "CL"
  },
  "CL-AI": {
    "label": "Aisen del General Carlos Ibanez del Campo",
    "value": "CL-AI",
    "parent": "CL"
  },
  "CL-AN": {
    "label": "Antofagasta",
    "value": "CL-AN",
    "parent": "CL"
  },
  "CL-AP": {
    "label": "Arica y Parinacota",
    "value": "CL-AP",
    "parent": "CL"
  },
  "CL-AT": {
    "label": "Atacama",
    "value": "CL-AT",
    "parent": "CL"
  },
  "CL-BI": {
    "label": "Biobio",
    "value": "CL-BI",
    "parent": "CL"
  },
  "CL-CO": {
    "label": "Coquimbo",
    "value": "CL-CO",
    "parent": "CL"
  },
  "CL-AR": {
    "label": "La Araucania",
    "value": "CL-AR",
    "parent": "CL"
  },
  "CL-LI": {
    "label": "Libertador General Bernardo O'Higgins",
    "value": "CL-LI",
    "parent": "CL"
  },
  "CL-LL": {
    "label": "Los Lagos",
    "value": "CL-LL",
    "parent": "CL"
  },
  "CL-LR": {
    "label": "Los Rios",
    "value": "CL-LR",
    "parent": "CL"
  },
  "CL-MA": {
    "label": "Magallanes",
    "value": "CL-MA",
    "parent": "CL"
  },
  "CL-ML": {
    "label": "Maule",
    "value": "CL-ML",
    "parent": "CL"
  },
  "CL-NB": {
    "label": "Nuble",
    "value": "CL-NB",
    "parent": "CL"
  },
  "CL-RM": {
    "label": "Region Metropolitana de Santiago",
    "value": "CL-RM",
    "parent": "CL"
  },
  "CL-TA": {
    "label": "Tarapaca",
    "value": "CL-TA",
    "parent": "CL"
  },
  "CL-VS": {
    "label": "Valparaiso",
    "value": "CL-VS",
    "parent": "CL"
  },
  "CN": {
    "label": "China",
    "value": "CN"
  },
  "CN-AH": {
    "label": "Anhui",
    "value": "CN-AH",
    "parent": "CN"
  },
  "CN-BJ": {
    "label": "Beijing",
    "value": "CN-BJ",
    "parent": "CN"
  },
  "CN-CQ": {
    "label": "Chongqing",
    "value": "CN-CQ",
    "parent": "CN"
  },
  "CN-FJ": {
    "label": "Fujian",
    "value": "CN-FJ",
    "parent": "CN"
  },
  "CN-GS": {
    "label": "Gansu",
    "value": "CN-GS",
    "parent": "CN"
  },
  "CN-GD": {
    "label": "Guangdong",
    "value": "CN-GD",
    "parent": "CN"
  },
  "CN-GX": {
    "label": "Guangxi Zhuangzu",
    "value": "CN-GX",
    "parent": "CN"
  },
  "CN-GZ": {
    "label": "Guizhou",
    "value": "CN-GZ",
    "parent": "CN"
  },
  "CN-HI": {
    "label": "Hainan",
    "value": "CN-HI",
    "parent": "CN"
  },
  "CN-HE": {
    "label": "Hebei",
    "value": "CN-HE",
    "parent": "CN"
  },
  "CN-HL": {
    "label": "Heilongjiang",
    "value": "CN-HL",
    "parent": "CN"
  },
  "CN-HA": {
    "label": "Henan",
    "value": "CN-HA",
    "parent": "CN"
  },
  "CN-HB": {
    "label": "Hubei",
    "value": "CN-HB",
    "parent": "CN"
  },
  "CN-HN": {
    "label": "Hunan",
    "value": "CN-HN",
    "parent": "CN"
  },
  "CN-JS": {
    "label": "Jiangsu",
    "value": "CN-JS",
    "parent": "CN"
  },
  "CN-JX": {
    "label": "Jiangxi",
    "value": "CN-JX",
    "parent": "CN"
  },
  "CN-JL": {
    "label": "Jilin",
    "value": "CN-JL",
    "parent": "CN"
  },
  "CN-LN": {
    "label": "Liaoning",
    "value": "CN-LN",
    "parent": "CN"
  },
  "CN-NM": {
    "label": "Nei Mongol",
    "value": "CN-NM",
    "parent": "CN"
  },
  "CN-NX": {
    "label": "Ningxia Huizu",
    "value": "CN-NX",
    "parent": "CN"
  },
  "CN-QH": {
    "label": "Qinghai",
    "value": "CN-QH",
    "parent": "CN"
  },
  "CN-SN": {
    "label": "Shaanxi",
    "value": "CN-SN",
    "parent": "CN"
  },
  "CN-SD": {
    "label": "Shandong",
    "value": "CN-SD",
    "parent": "CN"
  },
  "CN-SH": {
    "label": "Shanghai",
    "value": "CN-SH",
    "parent": "CN"
  },
  "CN-SX": {
    "label": "Shanxi",
    "value": "CN-SX",
    "parent": "CN"
  },
  "CN-SC": {
    "label": "Sichuan",
    "value": "CN-SC",
    "parent": "CN"
  },
  "CN-TJ": {
    "label": "Tianjin",
    "value": "CN-TJ",
    "parent": "CN"
  },
  "CN-XJ": {
    "label": "Xinjiang Uygur",
    "value": "CN-XJ",
    "parent": "CN"
  },
  "CN-XZ": {
    "label": "Xizang",
    "value": "CN-XZ",
    "parent": "CN"
  },
  "CN-YN": {
    "label": "Yunnan",
    "value": "CN-YN",
    "parent": "CN"
  },
  "CN-ZJ": {
    "label": "Zhejiang",
    "value": "CN-ZJ",
    "parent": "CN"
  },
  "CX": {
    "label": "Christmas Island",
    "value": "CX"
  },
  "CC": {
    "label": "Cocos (Keeling) Islands",
    "value": "CC"
  },
  "CO": {
    "label": "Colombia",
    "value": "CO"
  },
  "CO-AMA": {
    "label": "Amazonas",
    "value": "CO-AMA",
    "parent": "CO"
  },
  "CO-ANT": {
    "label": "Antioquia",
    "value": "CO-ANT",
    "parent": "CO"
  },
  "CO-ARA": {
    "label": "Arauca",
    "value": "CO-ARA",
    "parent": "CO"
  },
  "CO-ATL": {
    "label": "Atlantico",
    "value": "CO-ATL",
    "parent": "CO"
  },
  "CO-BOL": {
    "label": "Bolivar",
    "value": "CO-BOL",
    "parent": "CO"
  },
  "CO-BOY": {
    "label": "Boyaca",
    "value": "CO-BOY",
    "parent": "CO"
  },
  "CO-CAL": {
    "label": "Caldas",
    "value": "CO-CAL",
    "parent": "CO"
  },
  "CO-CAQ": {
    "label": "Caqueta",
    "value": "CO-CAQ",
    "parent": "CO"
  },
  "CO-CAS": {
    "label": "Casanare",
    "value": "CO-CAS",
    "parent": "CO"
  },
  "CO-CAU": {
    "label": "Cauca",
    "value": "CO-CAU",
    "parent": "CO"
  },
  "CO-CES": {
    "label": "Cesar",
    "value": "CO-CES",
    "parent": "CO"
  },
  "CO-CHO": {
    "label": "Choco",
    "value": "CO-CHO",
    "parent": "CO"
  },
  "CO-COR": {
    "label": "Cordoba",
    "value": "CO-COR",
    "parent": "CO"
  },
  "CO-CUN": {
    "label": "Cundinamarca",
    "value": "CO-CUN",
    "parent": "CO"
  },
  "CO-DC": {
    "label": "Distrito Capital de Bogota",
    "value": "CO-DC",
    "parent": "CO"
  },
  "CO-GUA": {
    "label": "Guainia",
    "value": "CO-GUA",
    "parent": "CO"
  },
  "CO-GUV": {
    "label": "Guaviare",
    "value": "CO-GUV",
    "parent": "CO"
  },
  "CO-HUI": {
    "label": "Huila",
    "value": "CO-HUI",
    "parent": "CO"
  },
  "CO-LAG": {
    "label": "La Guajira",
    "value": "CO-LAG",
    "parent": "CO"
  },
  "CO-MAG": {
    "label": "Magdalena",
    "value": "CO-MAG",
    "parent": "CO"
  },
  "CO-MET": {
    "label": "Meta",
    "value": "CO-MET",
    "parent": "CO"
  },
  "CO-NAR": {
    "label": "Narino",
    "value": "CO-NAR",
    "parent": "CO"
  },
  "CO-NSA": {
    "label": "Norte de Santander",
    "value": "CO-NSA",
    "parent": "CO"
  },
  "CO-PUT": {
    "label": "Putumayo",
    "value": "CO-PUT",
    "parent": "CO"
  },
  "CO-QUI": {
    "label": "Quindio",
    "value": "CO-QUI",
    "parent": "CO"
  },
  "CO-RIS": {
    "label": "Risaralda",
    "value": "CO-RIS",
    "parent": "CO"
  },
  "CO-SAP": {
    "label": "San Andres, Providencia y Santa Catalina",
    "value": "CO-SAP",
    "parent": "CO"
  },
  "CO-SAN": {
    "label": "Santander",
    "value": "CO-SAN",
    "parent": "CO"
  },
  "CO-SUC": {
    "label": "Sucre",
    "value": "CO-SUC",
    "parent": "CO"
  },
  "CO-TOL": {
    "label": "Tolima",
    "value": "CO-TOL",
    "parent": "CO"
  },
  "CO-VAC": {
    "label": "Valle del Cauca",
    "value": "CO-VAC",
    "parent": "CO"
  },
  "CO-VID": {
    "label": "Vichada",
    "value": "CO-VID",
    "parent": "CO"
  },
  "KM": {
    "label": "Comoros",
    "value": "KM"
  },
  "KM-G": {
    "label": "Grande Comore",
    "value": "KM-G",
    "parent": "KM"
  },
  "KM-M": {
    "label": "Moheli",
    "value": "KM-M",
    "parent": "KM"
  },
  "CG": {
    "label": "Congo",
    "value": "CG"
  },
  "CG-11": {
    "label": "Bouenza",
    "value": "CG-11",
    "parent": "CG"
  },
  "CG-BZV": {
    "label": "Brazzaville",
    "value": "CG-BZV",
    "parent": "CG"
  },
  "CG-8": {
    "label": "Cuvette",
    "value": "CG-8",
    "parent": "CG"
  },
  "CG-7": {
    "label": "Likouala",
    "value": "CG-7",
    "parent": "CG"
  },
  "CG-9": {
    "label": "Niari",
    "value": "CG-9",
    "parent": "CG"
  },
  "CG-16": {
    "label": "Pointe-Noire",
    "value": "CG-16",
    "parent": "CG"
  },
  "CG-13": {
    "label": "Sangha",
    "value": "CG-13",
    "parent": "CG"
  },
  "CD": {
    "label": "Congo, Democratic Republic of the",
    "value": "CD"
  },
  "CD-EQ": {
    "label": "Equateur",
    "value": "CD-EQ",
    "parent": "CD"
  },
  "CD-HK": {
    "label": "Haut-Katanga",
    "value": "CD-HK",
    "parent": "CD"
  },
  "CD-IT": {
    "label": "Ituri",
    "value": "CD-IT",
    "parent": "CD"
  },
  "CD-KS": {
    "label": "Kasai",
    "value": "CD-KS",
    "parent": "CD"
  },
  "CD-KC": {
    "label": "Kasai Central",
    "value": "CD-KC",
    "parent": "CD"
  },
  "CD-KE": {
    "label": "Kasai Oriental",
    "value": "CD-KE",
    "parent": "CD"
  },
  "CD-KN": {
    "label": "Kinshasa",
    "value": "CD-KN",
    "parent": "CD"
  },
  "CD-BC": {
    "label": "Kongo Central",
    "value": "CD-BC",
    "parent": "CD"
  },
  "CD-LO": {
    "label": "Lomami",
    "value": "CD-LO",
    "parent": "CD"
  },
  "CD-LU": {
    "label": "Lualaba",
    "value": "CD-LU",
    "parent": "CD"
  },
  "CD-MA": {
    "label": "Maniema",
    "value": "CD-MA",
    "parent": "CD"
  },
  "CD-NK": {
    "label": "Nord-Kivu",
    "value": "CD-NK",
    "parent": "CD"
  },
  "CD-NU": {
    "label": "Nord-Ubangi",
    "value": "CD-NU",
    "parent": "CD"
  },
  "CD-SA": {
    "label": "Sankuru",
    "value": "CD-SA",
    "parent": "CD"
  },
  "CD-SK": {
    "label": "Sud-Kivu",
    "value": "CD-SK",
    "parent": "CD"
  },
  "CD-TA": {
    "label": "Tanganyika",
    "value": "CD-TA",
    "parent": "CD"
  },
  "CD-TO": {
    "label": "Tshopo",
    "value": "CD-TO",
    "parent": "CD"
  },
  "CK": {
    "label": "Cook Islands",
    "value": "CK"
  },
  "CR": {
    "label": "Costa Rica",
    "value": "CR"
  },
  "CR-A": {
    "label": "Alajuela",
    "value": "CR-A",
    "parent": "CR"
  },
  "CR-C": {
    "label": "Cartago",
    "value": "CR-C",
    "parent": "CR"
  },
  "CR-G": {
    "label": "Guanacaste",
    "value": "CR-G",
    "parent": "CR"
  },
  "CR-H": {
    "label": "Heredia",
    "value": "CR-H",
    "parent": "CR"
  },
  "CR-L": {
    "label": "Limon",
    "value": "CR-L",
    "parent": "CR"
  },
  "CR-P": {
    "label": "Puntarenas",
    "value": "CR-P",
    "parent": "CR"
  },
  "CR-SJ": {
    "label": "San Jose",
    "value": "CR-SJ",
    "parent": "CR"
  },
  "CI": {
    "label": "Côte d'Ivoire",
    "value": "CI"
  },
  "CI-AB": {
    "label": "Abidjan",
    "value": "CI-AB",
    "parent": "CI"
  },
  "CI-BS": {
    "label": "Bas-Sassandra",
    "value": "CI-BS",
    "parent": "CI"
  },
  "CI-CM": {
    "label": "Comoe",
    "value": "CI-CM",
    "parent": "CI"
  },
  "CI-DN": {
    "label": "Denguele",
    "value": "CI-DN",
    "parent": "CI"
  },
  "CI-GD": {
    "label": "Goh-Djiboua",
    "value": "CI-GD",
    "parent": "CI"
  },
  "CI-LC": {
    "label": "Lacs",
    "value": "CI-LC",
    "parent": "CI"
  },
  "CI-LG": {
    "label": "Lagunes",
    "value": "CI-LG",
    "parent": "CI"
  },
  "CI-MG": {
    "label": "Montagnes",
    "value": "CI-MG",
    "parent": "CI"
  },
  "CI-SM": {
    "label": "Sassandra-Marahoue",
    "value": "CI-SM",
    "parent": "CI"
  },
  "CI-SV": {
    "label": "Savanes",
    "value": "CI-SV",
    "parent": "CI"
  },
  "CI-VB": {
    "label": "Vallee du Bandama",
    "value": "CI-VB",
    "parent": "CI"
  },
  "CI-WR": {
    "label": "Woroba",
    "value": "CI-WR",
    "parent": "CI"
  },
  "CI-YM": {
    "label": "Yamoussoukro",
    "value": "CI-YM",
    "parent": "CI"
  },
  "CI-ZZ": {
    "label": "Zanzan",
    "value": "CI-ZZ",
    "parent": "CI"
  },
  "HR": {
    "label": "Croatia",
    "value": "HR"
  },
  "HR-07": {
    "label": "Bjelovarsko-bilogorska zupanija",
    "value": "HR-07",
    "parent": "HR"
  },
  "HR-12": {
    "label": "Brodsko-posavska zupanija",
    "value": "HR-12",
    "parent": "HR"
  },
  "HR-19": {
    "label": "Dubrovacko-neretvanska zupanija",
    "value": "HR-19",
    "parent": "HR"
  },
  "HR-21": {
    "label": "Grad Zagreb",
    "value": "HR-21",
    "parent": "HR"
  },
  "HR-18": {
    "label": "Istarska zupanija",
    "value": "HR-18",
    "parent": "HR"
  },
  "HR-04": {
    "label": "Karlovacka zupanija",
    "value": "HR-04",
    "parent": "HR"
  },
  "HR-06": {
    "label": "Koprivnicko-krizevacka zupanija",
    "value": "HR-06",
    "parent": "HR"
  },
  "HR-02": {
    "label": "Krapinsko-zagorska zupanija",
    "value": "HR-02",
    "parent": "HR"
  },
  "HR-09": {
    "label": "Licko-senjska zupanija",
    "value": "HR-09",
    "parent": "HR"
  },
  "HR-20": {
    "label": "Medimurska zupanija",
    "value": "HR-20",
    "parent": "HR"
  },
  "HR-14": {
    "label": "Osjecko-baranjska zupanija",
    "value": "HR-14",
    "parent": "HR"
  },
  "HR-11": {
    "label": "Pozesko-slavonska zupanija",
    "value": "HR-11",
    "parent": "HR"
  },
  "HR-08": {
    "label": "Primorsko-goranska zupanija",
    "value": "HR-08",
    "parent": "HR"
  },
  "HR-15": {
    "label": "Sibensko-kninska zupanija",
    "value": "HR-15",
    "parent": "HR"
  },
  "HR-03": {
    "label": "Sisacko-moslavacka zupanija",
    "value": "HR-03",
    "parent": "HR"
  },
  "HR-17": {
    "label": "Splitsko-dalmatinska zupanija",
    "value": "HR-17",
    "parent": "HR"
  },
  "HR-05": {
    "label": "Varazdinska zupanija",
    "value": "HR-05",
    "parent": "HR"
  },
  "HR-10": {
    "label": "Viroviticko-podravska zupanija",
    "value": "HR-10",
    "parent": "HR"
  },
  "HR-16": {
    "label": "Vukovarsko-srijemska zupanija",
    "value": "HR-16",
    "parent": "HR"
  },
  "HR-13": {
    "label": "Zadarska zupanija",
    "value": "HR-13",
    "parent": "HR"
  },
  "HR-01": {
    "label": "Zagrebacka zupanija",
    "value": "HR-01",
    "parent": "HR"
  },
  "CU": {
    "label": "Cuba",
    "value": "CU"
  },
  "CU-15": {
    "label": "Artemisa",
    "value": "CU-15",
    "parent": "CU"
  },
  "CU-09": {
    "label": "Camaguey",
    "value": "CU-09",
    "parent": "CU"
  },
  "CU-08": {
    "label": "Ciego de Avila",
    "value": "CU-08",
    "parent": "CU"
  },
  "CU-06": {
    "label": "Cienfuegos",
    "value": "CU-06",
    "parent": "CU"
  },
  "CU-12": {
    "label": "Granma",
    "value": "CU-12",
    "parent": "CU"
  },
  "CU-14": {
    "label": "Guantanamo",
    "value": "CU-14",
    "parent": "CU"
  },
  "CU-11": {
    "label": "Holguin",
    "value": "CU-11",
    "parent": "CU"
  },
  "CU-99": {
    "label": "Isla de la Juventud",
    "value": "CU-99",
    "parent": "CU"
  },
  "CU-03": {
    "label": "La Habana",
    "value": "CU-03",
    "parent": "CU"
  },
  "CU-10": {
    "label": "Las Tunas",
    "value": "CU-10",
    "parent": "CU"
  },
  "CU-04": {
    "label": "Matanzas",
    "value": "CU-04",
    "parent": "CU"
  },
  "CU-16": {
    "label": "Mayabeque",
    "value": "CU-16",
    "parent": "CU"
  },
  "CU-01": {
    "label": "Pinar del Rio",
    "value": "CU-01",
    "parent": "CU"
  },
  "CU-07": {
    "label": "Sancti Spiritus",
    "value": "CU-07",
    "parent": "CU"
  },
  "CU-13": {
    "label": "Santiago de Cuba",
    "value": "CU-13",
    "parent": "CU"
  },
  "CU-05": {
    "label": "Villa Clara",
    "value": "CU-05",
    "parent": "CU"
  },
  "CW": {
    "label": "Curaçao",
    "value": "CW"
  },
  "CY": {
    "label": "Cyprus",
    "value": "CY"
  },
  "CY-04": {
    "label": "Ammochostos",
    "value": "CY-04",
    "parent": "CY"
  },
  "CY-06": {
    "label": "Keryneia",
    "value": "CY-06",
    "parent": "CY"
  },
  "CY-03": {
    "label": "Larnaka",
    "value": "CY-03",
    "parent": "CY"
  },
  "CY-01": {
    "label": "Lefkosia",
    "value": "CY-01",
    "parent": "CY"
  },
  "CY-02": {
    "label": "Lemesos",
    "value": "CY-02",
    "parent": "CY"
  },
  "CY-05": {
    "label": "Pafos",
    "value": "CY-05",
    "parent": "CY"
  },
  "CZ": {
    "label": "Czechia",
    "value": "CZ"
  },
  "CZ-31": {
    "label": "Jihocesky kraj",
    "value": "CZ-31",
    "parent": "CZ"
  },
  "CZ-64": {
    "label": "Jihomoravsky kraj",
    "value": "CZ-64",
    "parent": "CZ"
  },
  "CZ-41": {
    "label": "Karlovarsky kraj",
    "value": "CZ-41",
    "parent": "CZ"
  },
  "CZ-63": {
    "label": "Kraj Vysocina",
    "value": "CZ-63",
    "parent": "CZ"
  },
  "CZ-52": {
    "label": "Kralovehradecky kraj",
    "value": "CZ-52",
    "parent": "CZ"
  },
  "CZ-51": {
    "label": "Liberecky kraj",
    "value": "CZ-51",
    "parent": "CZ"
  },
  "CZ-80": {
    "label": "Moravskoslezsky kraj",
    "value": "CZ-80",
    "parent": "CZ"
  },
  "CZ-71": {
    "label": "Olomoucky kraj",
    "value": "CZ-71",
    "parent": "CZ"
  },
  "CZ-53": {
    "label": "Pardubicky kraj",
    "value": "CZ-53",
    "parent": "CZ"
  },
  "CZ-32": {
    "label": "Plzensky kraj",
    "value": "CZ-32",
    "parent": "CZ"
  },
  "CZ-10": {
    "label": "Praha, Hlavni mesto",
    "value": "CZ-10",
    "parent": "CZ"
  },
  "CZ-20": {
    "label": "Stredocesky kraj",
    "value": "CZ-20",
    "parent": "CZ"
  },
  "CZ-42": {
    "label": "Ustecky kraj",
    "value": "CZ-42",
    "parent": "CZ"
  },
  "CZ-72": {
    "label": "Zlinsky kraj",
    "value": "CZ-72",
    "parent": "CZ"
  },
  "DK": {
    "label": "Denmark",
    "value": "DK"
  },
  "DK-84": {
    "label": "Hovedstaden",
    "value": "DK-84",
    "parent": "DK"
  },
  "DK-82": {
    "label": "Midtjylland",
    "value": "DK-82",
    "parent": "DK"
  },
  "DK-81": {
    "label": "Nordjylland",
    "value": "DK-81",
    "parent": "DK"
  },
  "DK-85": {
    "label": "Sjaelland",
    "value": "DK-85",
    "parent": "DK"
  },
  "DK-83": {
    "label": "Syddanmark",
    "value": "DK-83",
    "parent": "DK"
  },
  "DJ": {
    "label": "Djibouti",
    "value": "DJ"
  },
  "DJ-AR": {
    "label": "Arta",
    "value": "DJ-AR",
    "parent": "DJ"
  },
  "DJ-DI": {
    "label": "Dikhil",
    "value": "DJ-DI",
    "parent": "DJ"
  },
  "DJ-DJ": {
    "label": "Djibouti",
    "value": "DJ-DJ",
    "parent": "DJ"
  },
  "DM": {
    "label": "Dominica",
    "value": "DM"
  },
  "DM-02": {
    "label": "Saint Andrew",
    "value": "DM-02",
    "parent": "DM"
  },
  "DM-04": {
    "label": "Saint George",
    "value": "DM-04",
    "parent": "DM"
  },
  "DM-05": {
    "label": "Saint John",
    "value": "DM-05",
    "parent": "DM"
  },
  "DM-06": {
    "label": "Saint Joseph",
    "value": "DM-06",
    "parent": "DM"
  },
  "DM-07": {
    "label": "Saint Luke",
    "value": "DM-07",
    "parent": "DM"
  },
  "DM-09": {
    "label": "Saint Patrick",
    "value": "DM-09",
    "parent": "DM"
  },
  "DM-10": {
    "label": "Saint Paul",
    "value": "DM-10",
    "parent": "DM"
  },
  "DO": {
    "label": "Dominican Republic",
    "value": "DO"
  },
  "DO-02": {
    "label": "Azua",
    "value": "DO-02",
    "parent": "DO"
  },
  "DO-03": {
    "label": "Baoruco",
    "value": "DO-03",
    "parent": "DO"
  },
  "DO-04": {
    "label": "Barahona",
    "value": "DO-04",
    "parent": "DO"
  },
  "DO-05": {
    "label": "Dajabon",
    "value": "DO-05",
    "parent": "DO"
  },
  "DO-01": {
    "label": "Distrito Nacional (Santo Domingo)",
    "value": "DO-01",
    "parent": "DO"
  },
  "DO-06": {
    "label": "Duarte",
    "value": "DO-06",
    "parent": "DO"
  },
  "DO-08": {
    "label": "El Seibo",
    "value": "DO-08",
    "parent": "DO"
  },
  "DO-07": {
    "label": "Elias Pina",
    "value": "DO-07",
    "parent": "DO"
  },
  "DO-09": {
    "label": "Espaillat",
    "value": "DO-09",
    "parent": "DO"
  },
  "DO-30": {
    "label": "Hato Mayor",
    "value": "DO-30",
    "parent": "DO"
  },
  "DO-19": {
    "label": "Hermanas Mirabal",
    "value": "DO-19",
    "parent": "DO"
  },
  "DO-10": {
    "label": "Independencia",
    "value": "DO-10",
    "parent": "DO"
  },
  "DO-11": {
    "label": "La Altagracia",
    "value": "DO-11",
    "parent": "DO"
  },
  "DO-12": {
    "label": "La Romana",
    "value": "DO-12",
    "parent": "DO"
  },
  "DO-13": {
    "label": "La Vega",
    "value": "DO-13",
    "parent": "DO"
  },
  "DO-14": {
    "label": "Maria Trinidad Sanchez",
    "value": "DO-14",
    "parent": "DO"
  },
  "DO-28": {
    "label": "Monsenor Nouel",
    "value": "DO-28",
    "parent": "DO"
  },
  "DO-15": {
    "label": "Monte Cristi",
    "value": "DO-15",
    "parent": "DO"
  },
  "DO-29": {
    "label": "Monte Plata",
    "value": "DO-29",
    "parent": "DO"
  },
  "DO-17": {
    "label": "Peravia",
    "value": "DO-17",
    "parent": "DO"
  },
  "DO-18": {
    "label": "Puerto Plata",
    "value": "DO-18",
    "parent": "DO"
  },
  "DO-20": {
    "label": "Samana",
    "value": "DO-20",
    "parent": "DO"
  },
  "DO-21": {
    "label": "San Cristobal",
    "value": "DO-21",
    "parent": "DO"
  },
  "DO-31": {
    "label": "San Jose de Ocoa",
    "value": "DO-31",
    "parent": "DO"
  },
  "DO-22": {
    "label": "San Juan",
    "value": "DO-22",
    "parent": "DO"
  },
  "DO-23": {
    "label": "San Pedro de Macoris",
    "value": "DO-23",
    "parent": "DO"
  },
  "DO-24": {
    "label": "Sanchez Ramirez",
    "value": "DO-24",
    "parent": "DO"
  },
  "DO-25": {
    "label": "Santiago",
    "value": "DO-25",
    "parent": "DO"
  },
  "DO-26": {
    "label": "Santiago Rodriguez",
    "value": "DO-26",
    "parent": "DO"
  },
  "DO-27": {
    "label": "Valverde",
    "value": "DO-27",
    "parent": "DO"
  },
  "EC": {
    "label": "Ecuador",
    "value": "EC"
  },
  "EC-A": {
    "label": "Azuay",
    "value": "EC-A",
    "parent": "EC"
  },
  "EC-B": {
    "label": "Bolivar",
    "value": "EC-B",
    "parent": "EC"
  },
  "EC-F": {
    "label": "Canar",
    "value": "EC-F",
    "parent": "EC"
  },
  "EC-C": {
    "label": "Carchi",
    "value": "EC-C",
    "parent": "EC"
  },
  "EC-H": {
    "label": "Chimborazo",
    "value": "EC-H",
    "parent": "EC"
  },
  "EC-X": {
    "label": "Cotopaxi",
    "value": "EC-X",
    "parent": "EC"
  },
  "EC-O": {
    "label": "El Oro",
    "value": "EC-O",
    "parent": "EC"
  },
  "EC-E": {
    "label": "Esmeraldas",
    "value": "EC-E",
    "parent": "EC"
  },
  "EC-W": {
    "label": "Galapagos",
    "value": "EC-W",
    "parent": "EC"
  },
  "EC-G": {
    "label": "Guayas",
    "value": "EC-G",
    "parent": "EC"
  },
  "EC-I": {
    "label": "Imbabura",
    "value": "EC-I",
    "parent": "EC"
  },
  "EC-L": {
    "label": "Loja",
    "value": "EC-L",
    "parent": "EC"
  },
  "EC-R": {
    "label": "Los Rios",
    "value": "EC-R",
    "parent": "EC"
  },
  "EC-M": {
    "label": "Manabi",
    "value": "EC-M",
    "parent": "EC"
  },
  "EC-S": {
    "label": "Morona Santiago",
    "value": "EC-S",
    "parent": "EC"
  },
  "EC-N": {
    "label": "Napo",
    "value": "EC-N",
    "parent": "EC"
  },
  "EC-D": {
    "label": "Orellana",
    "value": "EC-D",
    "parent": "EC"
  },
  "EC-Y": {
    "label": "Pastaza",
    "value": "EC-Y",
    "parent": "EC"
  },
  "EC-P": {
    "label": "Pichincha",
    "value": "EC-P",
    "parent": "EC"
  },
  "EC-SE": {
    "label": "Santa Elena",
    "value": "EC-SE",
    "parent": "EC"
  },
  "EC-SD": {
    "label": "Santo Domingo de los Tsachilas",
    "value": "EC-SD",
    "parent": "EC"
  },
  "EC-U": {
    "label": "Sucumbios",
    "value": "EC-U",
    "parent": "EC"
  },
  "EC-T": {
    "label": "Tungurahua",
    "value": "EC-T",
    "parent": "EC"
  },
  "EC-Z": {
    "label": "Zamora Chinchipe",
    "value": "EC-Z",
    "parent": "EC"
  },
  "EG": {
    "label": "Egypt",
    "value": "EG"
  },
  "EG-DK": {
    "label": "Ad Daqahliyah",
    "value": "EG-DK",
    "parent": "EG"
  },
  "EG-BA": {
    "label": "Al Bahr al Ahmar",
    "value": "EG-BA",
    "parent": "EG"
  },
  "EG-BH": {
    "label": "Al Buhayrah",
    "value": "EG-BH",
    "parent": "EG"
  },
  "EG-FYM": {
    "label": "Al Fayyum",
    "value": "EG-FYM",
    "parent": "EG"
  },
  "EG-GH": {
    "label": "Al Gharbiyah",
    "value": "EG-GH",
    "parent": "EG"
  },
  "EG-ALX": {
    "label": "Al Iskandariyah",
    "value": "EG-ALX",
    "parent": "EG"
  },
  "EG-IS": {
    "label": "Al Isma'iliyah",
    "value": "EG-IS",
    "parent": "EG"
  },
  "EG-GZ": {
    "label": "Al Jizah",
    "value": "EG-GZ",
    "parent": "EG"
  },
  "EG-MNF": {
    "label": "Al Minufiyah",
    "value": "EG-MNF",
    "parent": "EG"
  },
  "EG-MN": {
    "label": "Al Minya",
    "value": "EG-MN",
    "parent": "EG"
  },
  "EG-C": {
    "label": "Al Qahirah",
    "value": "EG-C",
    "parent": "EG"
  },
  "EG-KB": {
    "label": "Al Qalyubiyah",
    "value": "EG-KB",
    "parent": "EG"
  },
  "EG-LX": {
    "label": "Al Uqsur",
    "value": "EG-LX",
    "parent": "EG"
  },
  "EG-WAD": {
    "label": "Al Wadi al Jadid",
    "value": "EG-WAD",
    "parent": "EG"
  },
  "EG-SUZ": {
    "label": "As Suways",
    "value": "EG-SUZ",
    "parent": "EG"
  },
  "EG-SHR": {
    "label": "Ash Sharqiyah",
    "value": "EG-SHR",
    "parent": "EG"
  },
  "EG-ASN": {
    "label": "Aswan",
    "value": "EG-ASN",
    "parent": "EG"
  },
  "EG-AST": {
    "label": "Asyut",
    "value": "EG-AST",
    "parent": "EG"
  },
  "EG-BNS": {
    "label": "Bani Suwayf",
    "value": "EG-BNS",
    "parent": "EG"
  },
  "EG-PTS": {
    "label": "Bur Sa'id",
    "value": "EG-PTS",
    "parent": "EG"
  },
  "EG-DT": {
    "label": "Dumyat",
    "value": "EG-DT",
    "parent": "EG"
  },
  "EG-JS": {
    "label": "Janub Sina'",
    "value": "EG-JS",
    "parent": "EG"
  },
  "EG-KFS": {
    "label": "Kafr ash Shaykh",
    "value": "EG-KFS",
    "parent": "EG"
  },
  "EG-MT": {
    "label": "Matruh",
    "value": "EG-MT",
    "parent": "EG"
  },
  "EG-KN": {
    "label": "Qina",
    "value": "EG-KN",
    "parent": "EG"
  },
  "EG-SIN": {
    "label": "Shamal Sina'",
    "value": "EG-SIN",
    "parent": "EG"
  },
  "EG-SHG": {
    "label": "Suhaj",
    "value": "EG-SHG",
    "parent": "EG"
  },
  "SV": {
    "label": "El Salvador",
    "value": "SV"
  },
  "SV-AH": {
    "label": "Ahuachapan",
    "value": "SV-AH",
    "parent": "SV"
  },
  "SV-CA": {
    "label": "Cabanas",
    "value": "SV-CA",
    "parent": "SV"
  },
  "SV-CH": {
    "label": "Chalatenango",
    "value": "SV-CH",
    "parent": "SV"
  },
  "SV-CU": {
    "label": "Cuscatlan",
    "value": "SV-CU",
    "parent": "SV"
  },
  "SV-LI": {
    "label": "La Libertad",
    "value": "SV-LI",
    "parent": "SV"
  },
  "SV-PA": {
    "label": "La Paz",
    "value": "SV-PA",
    "parent": "SV"
  },
  "SV-UN": {
    "label": "La Union",
    "value": "SV-UN",
    "parent": "SV"
  },
  "SV-MO": {
    "label": "Morazan",
    "value": "SV-MO",
    "parent": "SV"
  },
  "SV-SM": {
    "label": "San Miguel",
    "value": "SV-SM",
    "parent": "SV"
  },
  "SV-SS": {
    "label": "San Salvador",
    "value": "SV-SS",
    "parent": "SV"
  },
  "SV-SV": {
    "label": "San Vicente",
    "value": "SV-SV",
    "parent": "SV"
  },
  "SV-SA": {
    "label": "Santa Ana",
    "value": "SV-SA",
    "parent": "SV"
  },
  "SV-SO": {
    "label": "Sonsonate",
    "value": "SV-SO",
    "parent": "SV"
  },
  "SV-US": {
    "label": "Usulutan",
    "value": "SV-US",
    "parent": "SV"
  },
  "GQ": {
    "label": "Equatorial Guinea",
    "value": "GQ"
  },
  "GQ-AN": {
    "label": "Annobon",
    "value": "GQ-AN",
    "parent": "GQ"
  },
  "GQ-BN": {
    "label": "Bioko Norte",
    "value": "GQ-BN",
    "parent": "GQ"
  },
  "GQ-CS": {
    "label": "Centro Sur",
    "value": "GQ-CS",
    "parent": "GQ"
  },
  "GQ-KN": {
    "label": "Kie-Ntem",
    "value": "GQ-KN",
    "parent": "GQ"
  },
  "GQ-LI": {
    "label": "Litoral",
    "value": "GQ-LI",
    "parent": "GQ"
  },
  "GQ-WN": {
    "label": "Wele-Nzas",
    "value": "GQ-WN",
    "parent": "GQ"
  },
  "ER": {
    "label": "Eritrea",
    "value": "ER"
  },
  "ER-MA": {
    "label": "Al Awsat",
    "value": "ER-MA",
    "parent": "ER"
  },
  "ER-GB": {
    "label": "Qash-Barkah",
    "value": "ER-GB",
    "parent": "ER"
  },
  "EE": {
    "label": "Estonia",
    "value": "EE"
  },
  "EE-37": {
    "label": "Harjumaa",
    "value": "EE-37",
    "parent": "EE"
  },
  "EE-39": {
    "label": "Hiiumaa",
    "value": "EE-39",
    "parent": "EE"
  },
  "EE-45": {
    "label": "Ida-Virumaa",
    "value": "EE-45",
    "parent": "EE"
  },
  "EE-52": {
    "label": "Jarvamaa",
    "value": "EE-52",
    "parent": "EE"
  },
  "EE-50": {
    "label": "Jogevamaa",
    "value": "EE-50",
    "parent": "EE"
  },
  "EE-60": {
    "label": "Laane-Virumaa",
    "value": "EE-60",
    "parent": "EE"
  },
  "EE-56": {
    "label": "Laanemaa",
    "value": "EE-56",
    "parent": "EE"
  },
  "EE-68": {
    "label": "Parnumaa",
    "value": "EE-68",
    "parent": "EE"
  },
  "EE-64": {
    "label": "Polvamaa",
    "value": "EE-64",
    "parent": "EE"
  },
  "EE-71": {
    "label": "Raplamaa",
    "value": "EE-71",
    "parent": "EE"
  },
  "EE-74": {
    "label": "Saaremaa",
    "value": "EE-74",
    "parent": "EE"
  },
  "EE-79": {
    "label": "Tartumaa",
    "value": "EE-79",
    "parent": "EE"
  },
  "EE-81": {
    "label": "Valgamaa",
    "value": "EE-81",
    "parent": "EE"
  },
  "EE-84": {
    "label": "Viljandimaa",
    "value": "EE-84",
    "parent": "EE"
  },
  "EE-87": {
    "label": "Vorumaa",
    "value": "EE-87",
    "parent": "EE"
  },
  "SZ": {
    "label": "Eswatini",
    "value": "SZ"
  },
  "SZ-HH": {
    "label": "Hhohho",
    "value": "SZ-HH",
    "parent": "SZ"
  },
  "SZ-LU": {
    "label": "Lubombo",
    "value": "SZ-LU",
    "parent": "SZ"
  },
  "SZ-MA": {
    "label": "Manzini",
    "value": "SZ-MA",
    "parent": "SZ"
  },
  "ET": {
    "label": "Ethiopia",
    "value": "ET"
  },
  "ET-AA": {
    "label": "Addis Ababa",
    "value": "ET-AA",
    "parent": "ET"
  },
  "ET-AF": {
    "label": "Afar",
    "value": "ET-AF",
    "parent": "ET"
  },
  "ET-AM": {
    "label": "Amara",
    "value": "ET-AM",
    "parent": "ET"
  },
  "ET-BE": {
    "label": "Benshangul-Gumaz",
    "value": "ET-BE",
    "parent": "ET"
  },
  "ET-DD": {
    "label": "Dire Dawa",
    "value": "ET-DD",
    "parent": "ET"
  },
  "ET-HA": {
    "label": "Harari People",
    "value": "ET-HA",
    "parent": "ET"
  },
  "ET-OR": {
    "label": "Oromia",
    "value": "ET-OR",
    "parent": "ET"
  },
  "ET-SO": {
    "label": "Somali",
    "value": "ET-SO",
    "parent": "ET"
  },
  "ET-SN": {
    "label": "Southern Nations, Nationalities and Peoples",
    "value": "ET-SN",
    "parent": "ET"
  },
  "ET-TI": {
    "label": "Tigrai",
    "value": "ET-TI",
    "parent": "ET"
  },
  "FK": {
    "label": "Falkland Islands (Malvinas)",
    "value": "FK"
  },
  "FO": {
    "label": "Faroe Islands",
    "value": "FO"
  },
  "FJ": {
    "label": "Fiji",
    "value": "FJ"
  },
  "FJ-C": {
    "label": "Central",
    "value": "FJ-C",
    "parent": "FJ"
  },
  "FJ-E": {
    "label": "Eastern",
    "value": "FJ-E",
    "parent": "FJ"
  },
  "FJ-N": {
    "label": "Northern",
    "value": "FJ-N",
    "parent": "FJ"
  },
  "FJ-R": {
    "label": "Rotuma",
    "value": "FJ-R",
    "parent": "FJ"
  },
  "FJ-W": {
    "label": "Western",
    "value": "FJ-W",
    "parent": "FJ"
  },
  "FI": {
    "label": "Finland",
    "value": "FI"
  },
  "FI-02": {
    "label": "Etela-Karjala",
    "value": "FI-02",
    "parent": "FI"
  },
  "FI-03": {
    "label": "Etela-Pohjanmaa",
    "value": "FI-03",
    "parent": "FI"
  },
  "FI-04": {
    "label": "Etela-Savo",
    "value": "FI-04",
    "parent": "FI"
  },
  "FI-05": {
    "label": "Kainuu",
    "value": "FI-05",
    "parent": "FI"
  },
  "FI-06": {
    "label": "Kanta-Hame",
    "value": "FI-06",
    "parent": "FI"
  },
  "FI-07": {
    "label": "Keski-Pohjanmaa",
    "value": "FI-07",
    "parent": "FI"
  },
  "FI-08": {
    "label": "Keski-Suomi",
    "value": "FI-08",
    "parent": "FI"
  },
  "FI-09": {
    "label": "Kymenlaakso",
    "value": "FI-09",
    "parent": "FI"
  },
  "FI-10": {
    "label": "Lappi",
    "value": "FI-10",
    "parent": "FI"
  },
  "FI-16": {
    "label": "Paijat-Hame",
    "value": "FI-16",
    "parent": "FI"
  },
  "FI-11": {
    "label": "Pirkanmaa",
    "value": "FI-11",
    "parent": "FI"
  },
  "FI-12": {
    "label": "Pohjanmaa",
    "value": "FI-12",
    "parent": "FI"
  },
  "FI-13": {
    "label": "Pohjois-Karjala",
    "value": "FI-13",
    "parent": "FI"
  },
  "FI-14": {
    "label": "Pohjois-Pohjanmaa",
    "value": "FI-14",
    "parent": "FI"
  },
  "FI-15": {
    "label": "Pohjois-Savo",
    "value": "FI-15",
    "parent": "FI"
  },
  "FI-17": {
    "label": "Satakunta",
    "value": "FI-17",
    "parent": "FI"
  },
  "FI-18": {
    "label": "Uusimaa",
    "value": "FI-18",
    "parent": "FI"
  },
  "FI-19": {
    "label": "Varsinais-Suomi",
    "value": "FI-19",
    "parent": "FI"
  },
  "FR": {
    "label": "France",
    "value": "FR"
  },
  "FR-ARA": {
    "label": "Auvergne-Rhone-Alpes",
    "value": "FR-ARA",
    "parent": "FR"
  },
  "FR-BFC": {
    "label": "Bourgogne-Franche-Comte",
    "value": "FR-BFC",
    "parent": "FR"
  },
  "FR-BRE": {
    "label": "Bretagne",
    "value": "FR-BRE",
    "parent": "FR"
  },
  "FR-CVL": {
    "label": "Centre-Val de Loire",
    "value": "FR-CVL",
    "parent": "FR"
  },
  "FR-20R": {
    "label": "Corse",
    "value": "FR-20R",
    "parent": "FR"
  },
  "FR-GES": {
    "label": "Grand-Est",
    "value": "FR-GES",
    "parent": "FR"
  },
  "FR-HDF": {
    "label": "Hauts-de-France",
    "value": "FR-HDF",
    "parent": "FR"
  },
  "FR-IDF": {
    "label": "Ile-de-France",
    "value": "FR-IDF",
    "parent": "FR"
  },
  "FR-NOR": {
    "label": "Normandie",
    "value": "FR-NOR",
    "parent": "FR"
  },
  "FR-NAQ": {
    "label": "Nouvelle-Aquitaine",
    "value": "FR-NAQ",
    "parent": "FR"
  },
  "FR-OCC": {
    "label": "Occitanie",
    "value": "FR-OCC",
    "parent": "FR"
  },
  "FR-PDL": {
    "label": "Pays-de-la-Loire",
    "value": "FR-PDL",
    "parent": "FR"
  },
  "FR-PAC": {
    "label": "Provence-Alpes-Cote-d'Azur",
    "value": "FR-PAC",
    "parent": "FR"
  },
  "GF": {
    "label": "French Guiana",
    "value": "GF"
  },
  "PF": {
    "label": "French Polynesia",
    "value": "PF"
  },
  "TF": {
    "label": "French Southern Territories",
    "value": "TF"
  },
  "GA": {
    "label": "Gabon",
    "value": "GA"
  },
  "GA-1": {
    "label": "Estuaire",
    "value": "GA-1",
    "parent": "GA"
  },
  "GA-2": {
    "label": "Haut-Ogooue",
    "value": "GA-2",
    "parent": "GA"
  },
  "GA-3": {
    "label": "Moyen-Ogooue",
    "value": "GA-3",
    "parent": "GA"
  },
  "GA-4": {
    "label": "Ngounie",
    "value": "GA-4",
    "parent": "GA"
  },
  "GA-5": {
    "label": "Nyanga",
    "value": "GA-5",
    "parent": "GA"
  },
  "GA-7": {
    "label": "Ogooue-Lolo",
    "value": "GA-7",
    "parent": "GA"
  },
  "GA-8": {
    "label": "Ogooue-Maritime",
    "value": "GA-8",
    "parent": "GA"
  },
  "GA-9": {
    "label": "Woleu-Ntem",
    "value": "GA-9",
    "parent": "GA"
  },
  "GM": {
    "label": "Gambia",
    "value": "GM"
  },
  "GM-B": {
    "label": "Banjul",
    "value": "GM-B",
    "parent": "GM"
  },
  "GM-M": {
    "label": "Central River",
    "value": "GM-M",
    "parent": "GM"
  },
  "GM-L": {
    "label": "Lower River",
    "value": "GM-L",
    "parent": "GM"
  },
  "GM-N": {
    "label": "North Bank",
    "value": "GM-N",
    "parent": "GM"
  },
  "GM-U": {
    "label": "Upper River",
    "value": "GM-U",
    "parent": "GM"
  },
  "GM-W": {
    "label": "Western",
    "value": "GM-W",
    "parent": "GM"
  },
  "GE": {
    "label": "Georgia",
    "value": "GE"
  },
  "GE-AB": {
    "label": "Abkhazia",
    "value": "GE-AB",
    "parent": "GE"
  },
  "GE-AJ": {
    "label": "Ajaria",
    "value": "GE-AJ",
    "parent": "GE"
  },
  "GE-GU": {
    "label": "Guria",
    "value": "GE-GU",
    "parent": "GE"
  },
  "GE-IM": {
    "label": "Imereti",
    "value": "GE-IM",
    "parent": "GE"
  },
  "GE-KA": {
    "label": "K'akheti",
    "value": "GE-KA",
    "parent": "GE"
  },
  "GE-KK": {
    "label": "Kvemo Kartli",
    "value": "GE-KK",
    "parent": "GE"
  },
  "GE-MM": {
    "label": "Mtskheta-Mtianeti",
    "value": "GE-MM",
    "parent": "GE"
  },
  "GE-RL": {
    "label": "Rach'a-Lechkhumi-Kvemo Svaneti",
    "value": "GE-RL",
    "parent": "GE"
  },
  "GE-SZ": {
    "label": "Samegrelo-Zemo Svaneti",
    "value": "GE-SZ",
    "parent": "GE"
  },
  "GE-SJ": {
    "label": "Samtskhe-Javakheti",
    "value": "GE-SJ",
    "parent": "GE"
  },
  "GE-SK": {
    "label": "Shida Kartli",
    "value": "GE-SK",
    "parent": "GE"
  },
  "GE-TB": {
    "label": "Tbilisi",
    "value": "GE-TB",
    "parent": "GE"
  },
  "DE": {
    "label": "Germany",
    "value": "DE"
  },
  "DE-BW": {
    "label": "Baden-Wurttemberg",
    "value": "DE-BW",
    "parent": "DE"
  },
  "DE-BY": {
    "label": "Bayern",
    "value": "DE-BY",
    "parent": "DE"
  },
  "DE-BE": {
    "label": "Berlin",
    "value": "DE-BE",
    "parent": "DE"
  },
  "DE-BB": {
    "label": "Brandenburg",
    "value": "DE-BB",
    "parent": "DE"
  },
  "DE-HB": {
    "label": "Bremen",
    "value": "DE-HB",
    "parent": "DE"
  },
  "DE-HH": {
    "label": "Hamburg",
    "value": "DE-HH",
    "parent": "DE"
  },
  "DE-HE": {
    "label": "Hessen",
    "value": "DE-HE",
    "parent": "DE"
  },
  "DE-MV": {
    "label": "Mecklenburg-Vorpommern",
    "value": "DE-MV",
    "parent": "DE"
  },
  "DE-NI": {
    "label": "Niedersachsen",
    "value": "DE-NI",
    "parent": "DE"
  },
  "DE-NW": {
    "label": "Nordrhein-Westfalen",
    "value": "DE-NW",
    "parent": "DE"
  },
  "DE-RP": {
    "label": "Rheinland-Pfalz",
    "value": "DE-RP",
    "parent": "DE"
  },
  "DE-SL": {
    "label": "Saarland",
    "value": "DE-SL",
    "parent": "DE"
  },
  "DE-SN": {
    "label": "Sachsen",
    "value": "DE-SN",
    "parent": "DE"
  },
  "DE-ST": {
    "label": "Sachsen-Anhalt",
    "value": "DE-ST",
    "parent": "DE"
  },
  "DE-SH": {
    "label": "Schleswig-Holstein",
    "value": "DE-SH",
    "parent": "DE"
  },
  "DE-TH": {
    "label": "Thuringen",
    "value": "DE-TH",
    "parent": "DE"
  },
  "GH": {
    "label": "Ghana",
    "value": "GH"
  },
  "GH-AF": {
    "label": "Ahafo",
    "value": "GH-AF",
    "parent": "GH"
  },
  "GH-AH": {
    "label": "Ashanti",
    "value": "GH-AH",
    "parent": "GH"
  },
  "GH-BO": {
    "label": "Bono",
    "value": "GH-BO",
    "parent": "GH"
  },
  "GH-BE": {
    "label": "Bono East",
    "value": "GH-BE",
    "parent": "GH"
  },
  "GH-CP": {
    "label": "Central",
    "value": "GH-CP",
    "parent": "GH"
  },
  "GH-EP": {
    "label": "Eastern",
    "value": "GH-EP",
    "parent": "GH"
  },
  "GH-AA": {
    "label": "Greater Accra",
    "value": "GH-AA",
    "parent": "GH"
  },
  "GH-NP": {
    "label": "Northern",
    "value": "GH-NP",
    "parent": "GH"
  },
  "GH-UE": {
    "label": "Upper East",
    "value": "GH-UE",
    "parent": "GH"
  },
  "GH-UW": {
    "label": "Upper West",
    "value": "GH-UW",
    "parent": "GH"
  },
  "GH-TV": {
    "label": "Volta",
    "value": "GH-TV",
    "parent": "GH"
  },
  "GH-WP": {
    "label": "Western",
    "value": "GH-WP",
    "parent": "GH"
  },
  "GI": {
    "label": "Gibraltar",
    "value": "GI"
  },
  "GR": {
    "label": "Greece",
    "value": "GR"
  },
  "GR-A": {
    "label": "Anatoliki Makedonia kai Thraki",
    "value": "GR-A",
    "parent": "GR"
  },
  "GR-I": {
    "label": "Attiki",
    "value": "GR-I",
    "parent": "GR"
  },
  "GR-G": {
    "label": "Dytiki Ellada",
    "value": "GR-G",
    "parent": "GR"
  },
  "GR-C": {
    "label": "Dytiki Makedonia",
    "value": "GR-C",
    "parent": "GR"
  },
  "GR-F": {
    "label": "Ionia Nisia",
    "value": "GR-F",
    "parent": "GR"
  },
  "GR-D": {
    "label": "Ipeiros",
    "value": "GR-D",
    "parent": "GR"
  },
  "GR-B": {
    "label": "Kentriki Makedonia",
    "value": "GR-B",
    "parent": "GR"
  },
  "GR-M": {
    "label": "Kriti",
    "value": "GR-M",
    "parent": "GR"
  },
  "GR-L": {
    "label": "Notio Aigaio",
    "value": "GR-L",
    "parent": "GR"
  },
  "GR-J": {
    "label": "Peloponnisos",
    "value": "GR-J",
    "parent": "GR"
  },
  "GR-H": {
    "label": "Sterea Ellada",
    "value": "GR-H",
    "parent": "GR"
  },
  "GR-E": {
    "label": "Thessalia",
    "value": "GR-E",
    "parent": "GR"
  },
  "GR-K": {
    "label": "Voreio Aigaio",
    "value": "GR-K",
    "parent": "GR"
  },
  "GL": {
    "label": "Greenland",
    "value": "GL"
  },
  "GL-AV": {
    "label": "Avannaata Kommunia",
    "value": "GL-AV",
    "parent": "GL"
  },
  "GL-KU": {
    "label": "Kommune Kujalleq",
    "value": "GL-KU",
    "parent": "GL"
  },
  "GL-QT": {
    "label": "Kommune Qeqertalik",
    "value": "GL-QT",
    "parent": "GL"
  },
  "GL-SM": {
    "label": "Kommuneqarfik Sermersooq",
    "value": "GL-SM",
    "parent": "GL"
  },
  "GL-QE": {
    "label": "Qeqqata Kommunia",
    "value": "GL-QE",
    "parent": "GL"
  },
  "GD": {
    "label": "Grenada",
    "value": "GD"
  },
  "GD-01": {
    "label": "Saint Andrew",
    "value": "GD-01",
    "parent": "GD"
  },
  "GD-02": {
    "label": "Saint David",
    "value": "GD-02",
    "parent": "GD"
  },
  "GD-03": {
    "label": "Saint George",
    "value": "GD-03",
    "parent": "GD"
  },
  "GD-04": {
    "label": "Saint John",
    "value": "GD-04",
    "parent": "GD"
  },
  "GD-05": {
    "label": "Saint Mark",
    "value": "GD-05",
    "parent": "GD"
  },
  "GD-06": {
    "label": "Saint Patrick",
    "value": "GD-06",
    "parent": "GD"
  },
  "GD-10": {
    "label": "Southern Grenadine Islands",
    "value": "GD-10",
    "parent": "GD"
  },
  "GP": {
    "label": "Guadeloupe",
    "value": "GP"
  },
  "GU": {
    "label": "Guam",
    "value": "GU"
  },
  "GT": {
    "label": "Guatemala",
    "value": "GT"
  },
  "GT-16": {
    "label": "Alta Verapaz",
    "value": "GT-16",
    "parent": "GT"
  },
  "GT-15": {
    "label": "Baja Verapaz",
    "value": "GT-15",
    "parent": "GT"
  },
  "GT-04": {
    "label": "Chimaltenango",
    "value": "GT-04",
    "parent": "GT"
  },
  "GT-20": {
    "label": "Chiquimula",
    "value": "GT-20",
    "parent": "GT"
  },
  "GT-02": {
    "label": "El Progreso",
    "value": "GT-02",
    "parent": "GT"
  },
  "GT-05": {
    "label": "Escuintla",
    "value": "GT-05",
    "parent": "GT"
  },
  "GT-01": {
    "label": "Guatemala",
    "value": "GT-01",
    "parent": "GT"
  },
  "GT-13": {
    "label": "Huehuetenango",
    "value": "GT-13",
    "parent": "GT"
  },
  "GT-18": {
    "label": "Izabal",
    "value": "GT-18",
    "parent": "GT"
  },
  "GT-21": {
    "label": "Jalapa",
    "value": "GT-21",
    "parent": "GT"
  },
  "GT-22": {
    "label": "Jutiapa",
    "value": "GT-22",
    "parent": "GT"
  },
  "GT-17": {
    "label": "Peten",
    "value": "GT-17",
    "parent": "GT"
  },
  "GT-09": {
    "label": "Quetzaltenango",
    "value": "GT-09",
    "parent": "GT"
  },
  "GT-14": {
    "label": "Quiche",
    "value": "GT-14",
    "parent": "GT"
  },
  "GT-11": {
    "label": "Retalhuleu",
    "value": "GT-11",
    "parent": "GT"
  },
  "GT-03": {
    "label": "Sacatepequez",
    "value": "GT-03",
    "parent": "GT"
  },
  "GT-12": {
    "label": "San Marcos",
    "value": "GT-12",
    "parent": "GT"
  },
  "GT-06": {
    "label": "Santa Rosa",
    "value": "GT-06",
    "parent": "GT"
  },
  "GT-07": {
    "label": "Solola",
    "value": "GT-07",
    "parent": "GT"
  },
  "GT-10": {
    "label": "Suchitepequez",
    "value": "GT-10",
    "parent": "GT"
  },
  "GT-08": {
    "label": "Totonicapan",
    "value": "GT-08",
    "parent": "GT"
  },
  "GT-19": {
    "label": "Zacapa",
    "value": "GT-19",
    "parent": "GT"
  },
  "GG": {
    "label": "Guernsey",
    "value": "GG"
  },
  "GN": {
    "label": "Guinea",
    "value": "GN"
  },
  "GN-BE": {
    "label": "Beyla",
    "value": "GN-BE",
    "parent": "GN"
  },
  "GN-BF": {
    "label": "Boffa",
    "value": "GN-BF",
    "parent": "GN"
  },
  "GN-BK": {
    "label": "Boke",
    "value": "GN-BK",
    "parent": "GN"
  },
  "GN-C": {
    "label": "Conakry",
    "value": "GN-C",
    "parent": "GN"
  },
  "GN-DB": {
    "label": "Dabola",
    "value": "GN-DB",
    "parent": "GN"
  },
  "GN-DI": {
    "label": "Dinguiraye",
    "value": "GN-DI",
    "parent": "GN"
  },
  "GN-DU": {
    "label": "Dubreka",
    "value": "GN-DU",
    "parent": "GN"
  },
  "GN-FR": {
    "label": "Fria",
    "value": "GN-FR",
    "parent": "GN"
  },
  "GN-KA": {
    "label": "Kankan",
    "value": "GN-KA",
    "parent": "GN"
  },
  "GN-KO": {
    "label": "Kouroussa",
    "value": "GN-KO",
    "parent": "GN"
  },
  "GN-LA": {
    "label": "Labe",
    "value": "GN-LA",
    "parent": "GN"
  },
  "GN-MM": {
    "label": "Mamou",
    "value": "GN-MM",
    "parent": "GN"
  },
  "GN-SI": {
    "label": "Siguiri",
    "value": "GN-SI",
    "parent": "GN"
  },
  "GW": {
    "label": "Guinea-Bissau",
    "value": "GW"
  },
  "GW-BA": {
    "label": "Bafata",
    "value": "GW-BA",
    "parent": "GW"
  },
  "GW-BS": {
    "label": "Bissau",
    "value": "GW-BS",
    "parent": "GW"
  },
  "GW-CA": {
    "label": "Cacheu",
    "value": "GW-CA",
    "parent": "GW"
  },
  "GW-GA": {
    "label": "Gabu",
    "value": "GW-GA",
    "parent": "GW"
  },
  "GW-OI": {
    "label": "Oio",
    "value": "GW-OI",
    "parent": "GW"
  },
  "GW-QU": {
    "label": "Quinara",
    "value": "GW-QU",
    "parent": "GW"
  },
  "GY": {
    "label": "Guyana",
    "value": "GY"
  },
  "GY-BA": {
    "label": "Barima-Waini",
    "value": "GY-BA",
    "parent": "GY"
  },
  "GY-CU": {
    "label": "Cuyuni-Mazaruni",
    "value": "GY-CU",
    "parent": "GY"
  },
  "GY-DE": {
    "label": "Demerara-Mahaica",
    "value": "GY-DE",
    "parent": "GY"
  },
  "GY-EB": {
    "label": "East Berbice-Corentyne",
    "value": "GY-EB",
    "parent": "GY"
  },
  "GY-ES": {
    "label": "Essequibo Islands-West Demerara",
    "value": "GY-ES",
    "parent": "GY"
  },
  "GY-MA": {
    "label": "Mahaica-Berbice",
    "value": "GY-MA",
    "parent": "GY"
  },
  "GY-PM": {
    "label": "Pomeroon-Supenaam",
    "value": "GY-PM",
    "parent": "GY"
  },
  "GY-PT": {
    "label": "Potaro-Siparuni",
    "value": "GY-PT",
    "parent": "GY"
  },
  "GY-UD": {
    "label": "Upper Demerara-Berbice",
    "value": "GY-UD",
    "parent": "GY"
  },
  "HT": {
    "label": "Haiti",
    "value": "HT"
  },
  "HT-AR": {
    "label": "Artibonite",
    "value": "HT-AR",
    "parent": "HT"
  },
  "HT-CE": {
    "label": "Centre",
    "value": "HT-CE",
    "parent": "HT"
  },
  "HT-GA": {
    "label": "Grande'Anse",
    "value": "HT-GA",
    "parent": "HT"
  },
  "HT-ND": {
    "label": "Nord",
    "value": "HT-ND",
    "parent": "HT"
  },
  "HT-NE": {
    "label": "Nord-Est",
    "value": "HT-NE",
    "parent": "HT"
  },
  "HT-NO": {
    "label": "Nord-Ouest",
    "value": "HT-NO",
    "parent": "HT"
  },
  "HT-OU": {
    "label": "Ouest",
    "value": "HT-OU",
    "parent": "HT"
  },
  "HT-SD": {
    "label": "Sud",
    "value": "HT-SD",
    "parent": "HT"
  },
  "HT-SE": {
    "label": "Sud-Est",
    "value": "HT-SE",
    "parent": "HT"
  },
  "HM": {
    "label": "Heard Island and McDonald Islands",
    "value": "HM"
  },
  "VA": {
    "label": "Holy See",
    "value": "VA"
  },
  "HN": {
    "label": "Honduras",
    "value": "HN"
  },
  "HN-AT": {
    "label": "Atlantida",
    "value": "HN-AT",
    "parent": "HN"
  },
  "HN-CH": {
    "label": "Choluteca",
    "value": "HN-CH",
    "parent": "HN"
  },
  "HN-CL": {
    "label": "Colon",
    "value": "HN-CL",
    "parent": "HN"
  },
  "HN-CM": {
    "label": "Comayagua",
    "value": "HN-CM",
    "parent": "HN"
  },
  "HN-CP": {
    "label": "Copan",
    "value": "HN-CP",
    "parent": "HN"
  },
  "HN-CR": {
    "label": "Cortes",
    "value": "HN-CR",
    "parent": "HN"
  },
  "HN-EP": {
    "label": "El Paraiso",
    "value": "HN-EP",
    "parent": "HN"
  },
  "HN-FM": {
    "label": "Francisco Morazan",
    "value": "HN-FM",
    "parent": "HN"
  },
  "HN-IN": {
    "label": "Intibuca",
    "value": "HN-IN",
    "parent": "HN"
  },
  "HN-IB": {
    "label": "Islas de la Bahia",
    "value": "HN-IB",
    "parent": "HN"
  },
  "HN-LP": {
    "label": "La Paz",
    "value": "HN-LP",
    "parent": "HN"
  },
  "HN-LE": {
    "label": "Lempira",
    "value": "HN-LE",
    "parent": "HN"
  },
  "HN-OC": {
    "label": "Ocotepeque",
    "value": "HN-OC",
    "parent": "HN"
  },
  "HN-OL": {
    "label": "Olancho",
    "value": "HN-OL",
    "parent": "HN"
  },
  "HN-SB": {
    "label": "Santa Barbara",
    "value": "HN-SB",
    "parent": "HN"
  },
  "HN-VA": {
    "label": "Valle",
    "value": "HN-VA",
    "parent": "HN"
  },
  "HN-YO": {
    "label": "Yoro",
    "value": "HN-YO",
    "parent": "HN"
  },
  "HK": {
    "label": "Hong Kong",
    "value": "HK"
  },
  "HU": {
    "label": "Hungary",
    "value": "HU"
  },
  "HU-BK": {
    "label": "Bacs-Kiskun",
    "value": "HU-BK",
    "parent": "HU"
  },
  "HU-BA": {
    "label": "Baranya",
    "value": "HU-BA",
    "parent": "HU"
  },
  "HU-BE": {
    "label": "Bekes",
    "value": "HU-BE",
    "parent": "HU"
  },
  "HU-BZ": {
    "label": "Borsod-Abauj-Zemplen",
    "value": "HU-BZ",
    "parent": "HU"
  },
  "HU-BU": {
    "label": "Budapest",
    "value": "HU-BU",
    "parent": "HU"
  },
  "HU-CS": {
    "label": "Csongrad-Csanad",
    "value": "HU-CS",
    "parent": "HU"
  },
  "HU-FE": {
    "label": "Fejer",
    "value": "HU-FE",
    "parent": "HU"
  },
  "HU-GS": {
    "label": "Gyor-Moson-Sopron",
    "value": "HU-GS",
    "parent": "HU"
  },
  "HU-HB": {
    "label": "Hajdu-Bihar",
    "value": "HU-HB",
    "parent": "HU"
  },
  "HU-HE": {
    "label": "Heves",
    "value": "HU-HE",
    "parent": "HU"
  },
  "HU-JN": {
    "label": "Jasz-Nagykun-Szolnok",
    "value": "HU-JN",
    "parent": "HU"
  },
  "HU-KE": {
    "label": "Komarom-Esztergom",
    "value": "HU-KE",
    "parent": "HU"
  },
  "HU-NO": {
    "label": "Nograd",
    "value": "HU-NO",
    "parent": "HU"
  },
  "HU-PE": {
    "label": "Pest",
    "value": "HU-PE",
    "parent": "HU"
  },
  "HU-SO": {
    "label": "Somogy",
    "value": "HU-SO",
    "parent": "HU"
  },
  "HU-SZ": {
    "label": "Szabolcs-Szatmar-Bereg",
    "value": "HU-SZ",
    "parent": "HU"
  },
  "HU-TO": {
    "label": "Tolna",
    "value": "HU-TO",
    "parent": "HU"
  },
  "HU-VA": {
    "label": "Vas",
    "value": "HU-VA",
    "parent": "HU"
  },
  "HU-VE": {
    "label": "Veszprem",
    "value": "HU-VE",
    "parent": "HU"
  },
  "HU-ZA": {
    "label": "Zala",
    "value": "HU-ZA",
    "parent": "HU"
  },
  "IS": {
    "label": "Iceland",
    "value": "IS"
  },
  "IS-7": {
    "label": "Austurland",
    "value": "IS-7",
    "parent": "IS"
  },
  "IS-1": {
    "label": "Hofudborgarsvaedi",
    "value": "IS-1",
    "parent": "IS"
  },
  "IS-6": {
    "label": "Nordurland eystra",
    "value": "IS-6",
    "parent": "IS"
  },
  "IS-5": {
    "label": "Nordurland vestra",
    "value": "IS-5",
    "parent": "IS"
  },
  "IS-8": {
    "label": "Sudurland",
    "value": "IS-8",
    "parent": "IS"
  },
  "IS-2": {
    "label": "Sudurnes",
    "value": "IS-2",
    "parent": "IS"
  },
  "IS-4": {
    "label": "Vestfirdir",
    "value": "IS-4",
    "parent": "IS"
  },
  "IS-3": {
    "label": "Vesturland",
    "value": "IS-3",
    "parent": "IS"
  },
  "IN": {
    "label": "India",
    "value": "IN"
  },
  "IN-AN": {
    "label": "Andaman and Nicobar Islands",
    "value": "IN-AN",
    "parent": "IN"
  },
  "IN-AP": {
    "label": "Andhra Pradesh",
    "value": "IN-AP",
    "parent": "IN"
  },
  "IN-AR": {
    "label": "Arunachal Pradesh",
    "value": "IN-AR",
    "parent": "IN"
  },
  "IN-AS": {
    "label": "Assam",
    "value": "IN-AS",
    "parent": "IN"
  },
  "IN-BR": {
    "label": "Bihar",
    "value": "IN-BR",
    "parent": "IN"
  },
  "IN-CH": {
    "label": "Chandigarh",
    "value": "IN-CH",
    "parent": "IN"
  },
  "IN-CG": {
    "label": "Chhattisgarh",
    "value": "IN-CG",
    "parent": "IN"
  },
  "IN-DH": {
    "label": "Dadra and Nagar Haveli and Daman and Diu",
    "value": "IN-DH",
    "parent": "IN"
  },
  "IN-DL": {
    "label": "Delhi",
    "value": "IN-DL",
    "parent": "IN"
  },
  "IN-GA": {
    "label": "Goa",
    "value": "IN-GA",
    "parent": "IN"
  },
  "IN-GJ": {
    "label": "Gujarat",
    "value": "IN-GJ",
    "parent": "IN"
  },
  "IN-HR": {
    "label": "Haryana",
    "value": "IN-HR",
    "parent": "IN"
  },
  "IN-HP": {
    "label": "Himachal Pradesh",
    "value": "IN-HP",
    "parent": "IN"
  },
  "IN-JK": {
    "label": "Jammu and Kashmir",
    "value": "IN-JK",
    "parent": "IN"
  },
  "IN-JH": {
    "label": "Jharkhand",
    "value": "IN-JH",
    "parent": "IN"
  },
  "IN-KA": {
    "label": "Karnataka",
    "value": "IN-KA",
    "parent": "IN"
  },
  "IN-KL": {
    "label": "Kerala",
    "value": "IN-KL",
    "parent": "IN"
  },
  "IN-LD": {
    "label": "Lakshadweep",
    "value": "IN-LD",
    "parent": "IN"
  },
  "IN-MP": {
    "label": "Madhya Pradesh",
    "value": "IN-MP",
    "parent": "IN"
  },
  "IN-MH": {
    "label": "Maharashtra",
    "value": "IN-MH",
    "parent": "IN"
  },
  "IN-MN": {
    "label": "Manipur",
    "value": "IN-MN",
    "parent": "IN"
  },
  "IN-ML": {
    "label": "Meghalaya",
    "value": "IN-ML",
    "parent": "IN"
  },
  "IN-MZ": {
    "label": "Mizoram",
    "value": "IN-MZ",
    "parent": "IN"
  },
  "IN-NL": {
    "label": "Nagaland",
    "value": "IN-NL",
    "parent": "IN"
  },
  "IN-OD": {
    "label": "Odisha",
    "value": "IN-OD",
    "parent": "IN"
  },
  "IN-PY": {
    "label": "Puducherry",
    "value": "IN-PY",
    "parent": "IN"
  },
  "IN-PB": {
    "label": "Punjab",
    "value": "IN-PB",
    "parent": "IN"
  },
  "IN-RJ": {
    "label": "Rajasthan",
    "value": "IN-RJ",
    "parent": "IN"
  },
  "IN-SK": {
    "label": "Sikkim",
    "value": "IN-SK",
    "parent": "IN"
  },
  "IN-TN": {
    "label": "Tamil Nadu",
    "value": "IN-TN",
    "parent": "IN"
  },
  "IN-TS": {
    "label": "Telangana",
    "value": "IN-TS",
    "parent": "IN"
  },
  "IN-TR": {
    "label": "Tripura",
    "value": "IN-TR",
    "parent": "IN"
  },
  "IN-UP": {
    "label": "Uttar Pradesh",
    "value": "IN-UP",
    "parent": "IN"
  },
  "IN-UK": {
    "label": "Uttarakhand",
    "value": "IN-UK",
    "parent": "IN"
  },
  "IN-WB": {
    "label": "West Bengal",
    "value": "IN-WB",
    "parent": "IN"
  },
  "ID": {
    "label": "Indonesia",
    "value": "ID"
  },
  "ID-AC": {
    "label": "Aceh",
    "value": "ID-AC",
    "parent": "ID"
  },
  "ID-BA": {
    "label": "Bali",
    "value": "ID-BA",
    "parent": "ID"
  },
  "ID-BT": {
    "label": "Banten",
    "value": "ID-BT",
    "parent": "ID"
  },
  "ID-BE": {
    "label": "Bengkulu",
    "value": "ID-BE",
    "parent": "ID"
  },
  "ID-GO": {
    "label": "Gorontalo",
    "value": "ID-GO",
    "parent": "ID"
  },
  "ID-JK": {
    "label": "Jakarta Raya",
    "value": "ID-JK",
    "parent": "ID"
  },
  "ID-JA": {
    "label": "Jambi",
    "value": "ID-JA",
    "parent": "ID"
  },
  "ID-JB": {
    "label": "Jawa Barat",
    "value": "ID-JB",
    "parent": "ID"
  },
  "ID-JT": {
    "label": "Jawa Tengah",
    "value": "ID-JT",
    "parent": "ID"
  },
  "ID-JI": {
    "label": "Jawa Timur",
    "value": "ID-JI",
    "parent": "ID"
  },
  "ID-KB": {
    "label": "Kalimantan Barat",
    "value": "ID-KB",
    "parent": "ID"
  },
  "ID-KS": {
    "label": "Kalimantan Selatan",
    "value": "ID-KS",
    "parent": "ID"
  },
  "ID-KT": {
    "label": "Kalimantan Tengah",
    "value": "ID-KT",
    "parent": "ID"
  },
  "ID-KI": {
    "label": "Kalimantan Timur",
    "value": "ID-KI",
    "parent": "ID"
  },
  "ID-KU": {
    "label": "Kalimantan Utara",
    "value": "ID-KU",
    "parent": "ID"
  },
  "ID-BB": {
    "label": "Kepulauan Bangka Belitung",
    "value": "ID-BB",
    "parent": "ID"
  },
  "ID-KR": {
    "label": "Kepulauan Riau",
    "value": "ID-KR",
    "parent": "ID"
  },
  "ID-LA": {
    "label": "Lampung",
    "value": "ID-LA",
    "parent": "ID"
  },
  "ID-ML": {
    "label": "Maluku",
    "value": "ID-ML",
    "parent": "ID"
  },
  "ID-MU": {
    "label": "Maluku Utara",
    "value": "ID-MU",
    "parent": "ID"
  },
  "ID-NB": {
    "label": "Nusa Tenggara Barat",
    "value": "ID-NB",
    "parent": "ID"
  },
  "ID-NT": {
    "label": "Nusa Tenggara Timur",
    "value": "ID-NT",
    "parent": "ID"
  },
  "ID-PP": {
    "label": "Papua",
    "value": "ID-PP",
    "parent": "ID"
  },
  "ID-PB": {
    "label": "Papua Barat",
    "value": "ID-PB",
    "parent": "ID"
  },
  "ID-PD": {
    "label": "Papua Barat Daya",
    "value": "ID-PD",
    "parent": "ID"
  },
  "ID-PE": {
    "label": "Papua Pengunungan",
    "value": "ID-PE",
    "parent": "ID"
  },
  "ID-PS": {
    "label": "Papua Selatan",
    "value": "ID-PS",
    "parent": "ID"
  },
  "ID-PT": {
    "label": "Papua Tengah",
    "value": "ID-PT",
    "parent": "ID"
  },
  "ID-RI": {
    "label": "Riau",
    "value": "ID-RI",
    "parent": "ID"
  },
  "ID-SR": {
    "label": "Sulawesi Barat",
    "value": "ID-SR",
    "parent": "ID"
  },
  "ID-SN": {
    "label": "Sulawesi Selatan",
    "value": "ID-SN",
    "parent": "ID"
  },
  "ID-ST": {
    "label": "Sulawesi Tengah",
    "value": "ID-ST",
    "parent": "ID"
  },
  "ID-SG": {
    "label": "Sulawesi Tenggara",
    "value": "ID-SG",
    "parent": "ID"
  },
  "ID-SA": {
    "label": "Sulawesi Utara",
    "value": "ID-SA",
    "parent": "ID"
  },
  "ID-SB": {
    "label": "Sumatera Barat",
    "value": "ID-SB",
    "parent": "ID"
  },
  "ID-SS": {
    "label": "Sumatera Selatan",
    "value": "ID-SS",
    "parent": "ID"
  },
  "ID-SU": {
    "label": "Sumatera Utara",
    "value": "ID-SU",
    "parent": "ID"
  },
  "ID-YO": {
    "label": "Yogyakarta",
    "value": "ID-YO",
    "parent": "ID"
  },
  "IR": {
    "label": "Iran, Islamic Republic of",
    "value": "IR"
  },
  "IR-30": {
    "label": "Alborz",
    "value": "IR-30",
    "parent": "IR"
  },
  "IR-24": {
    "label": "Ardabil",
    "value": "IR-24",
    "parent": "IR"
  },
  "IR-04": {
    "label": "Azarbayjan-e Gharbi",
    "value": "IR-04",
    "parent": "IR"
  },
  "IR-03": {
    "label": "Azarbayjan-e Sharqi",
    "value": "IR-03",
    "parent": "IR"
  },
  "IR-18": {
    "label": "Bushehr",
    "value": "IR-18",
    "parent": "IR"
  },
  "IR-14": {
    "label": "Chahar Mahal va Bakhtiari",
    "value": "IR-14",
    "parent": "IR"
  },
  "IR-10": {
    "label": "Esfahan",
    "value": "IR-10",
    "parent": "IR"
  },
  "IR-07": {
    "label": "Fars",
    "value": "IR-07",
    "parent": "IR"
  },
  "IR-01": {
    "label": "Gilan",
    "value": "IR-01",
    "parent": "IR"
  },
  "IR-27": {
    "label": "Golestan",
    "value": "IR-27",
    "parent": "IR"
  },
  "IR-13": {
    "label": "Hamadan",
    "value": "IR-13",
    "parent": "IR"
  },
  "IR-22": {
    "label": "Hormozgan",
    "value": "IR-22",
    "parent": "IR"
  },
  "IR-16": {
    "label": "Ilam",
    "value": "IR-16",
    "parent": "IR"
  },
  "IR-08": {
    "label": "Kerman",
    "value": "IR-08",
    "parent": "IR"
  },
  "IR-05": {
    "label": "Kermanshah",
    "value": "IR-05",
    "parent": "IR"
  },
  "IR-29": {
    "label": "Khorasan-e Jonubi",
    "value": "IR-29",
    "parent": "IR"
  },
  "IR-09": {
    "label": "Khorasan-e Razavi",
    "value": "IR-09",
    "parent": "IR"
  },
  "IR-28": {
    "label": "Khorasan-e Shomali",
    "value": "IR-28",
    "parent": "IR"
  },
  "IR-06": {
    "label": "Khuzestan",
    "value": "IR-06",
    "parent": "IR"
  },
  "IR-17": {
    "label": "Kohgiluyeh va Bowyer Ahmad",
    "value": "IR-17",
    "parent": "IR"
  },
  "IR-12": {
    "label": "Kordestan",
    "value": "IR-12",
    "parent": "IR"
  },
  "IR-15": {
    "label": "Lorestan",
    "value": "IR-15",
    "parent": "IR"
  },
  "IR-00": {
    "label": "Markazi",
    "value": "IR-00",
    "parent": "IR"
  },
  "IR-02": {
    "label": "Mazandaran",
    "value": "IR-02",
    "parent": "IR"
  },
  "IR-26": {
    "label": "Qazvin",
    "value": "IR-26",
    "parent": "IR"
  },
  "IR-25": {
    "label": "Qom",
    "value": "IR-25",
    "parent": "IR"
  },
  "IR-20": {
    "label": "Semnan",
    "value": "IR-20",
    "parent": "IR"
  },
  "IR-11": {
    "label": "Sistan va Baluchestan",
    "value": "IR-11",
    "parent": "IR"
  },
  "IR-23": {
    "label": "Tehran",
    "value": "IR-23",
    "parent": "IR"
  },
  "IR-21": {
    "label": "Yazd",
    "value": "IR-21",
    "parent": "IR"
  },
  "IR-19": {
    "label": "Zanjan",
    "value": "IR-19",
    "parent": "IR"
  },
  "IQ": {
    "label": "Iraq",
    "value": "IQ"
  },
  "IQ-AN": {
    "label": "Al Anbar",
    "value": "IQ-AN",
    "parent": "IQ"
  },
  "IQ-BA": {
    "label": "Al Basrah",
    "value": "IQ-BA",
    "parent": "IQ"
  },
  "IQ-MU": {
    "label": "Al Muthanna",
    "value": "IQ-MU",
    "parent": "IQ"
  },
  "IQ-QA": {
    "label": "Al Qadisiyah",
    "value": "IQ-QA",
    "parent": "IQ"
  },
  "IQ-NA": {
    "label": "An Najaf",
    "value": "IQ-NA",
    "parent": "IQ"
  },
  "IQ-AR": {
    "label": "Arbil",
    "value": "IQ-AR",
    "parent": "IQ"
  },
  "IQ-SU": {
    "label": "As Sulaymaniyah",
    "value": "IQ-SU",
    "parent": "IQ"
  },
  "IQ-BB": {
    "label": "Babil",
    "value": "IQ-BB",
    "parent": "IQ"
  },
  "IQ-BG": {
    "label": "Baghdad",
    "value": "IQ-BG",
    "parent": "IQ"
  },
  "IQ-DA": {
    "label": "Dahuk",
    "value": "IQ-DA",
    "parent": "IQ"
  },
  "IQ-DQ": {
    "label": "Dhi Qar",
    "value": "IQ-DQ",
    "parent": "IQ"
  },
  "IQ-DI": {
    "label": "Diyala",
    "value": "IQ-DI",
    "parent": "IQ"
  },
  "IQ-KA": {
    "label": "Karbala'",
    "value": "IQ-KA",
    "parent": "IQ"
  },
  "IQ-KI": {
    "label": "Kirkuk",
    "value": "IQ-KI",
    "parent": "IQ"
  },
  "IQ-MA": {
    "label": "Maysan",
    "value": "IQ-MA",
    "parent": "IQ"
  },
  "IQ-NI": {
    "label": "Ninawa",
    "value": "IQ-NI",
    "parent": "IQ"
  },
  "IQ-SD": {
    "label": "Salah ad Din",
    "value": "IQ-SD",
    "parent": "IQ"
  },
  "IQ-WA": {
    "label": "Wasit",
    "value": "IQ-WA",
    "parent": "IQ"
  },
  "IE": {
    "label": "Ireland",
    "value": "IE"
  },
  "IE-CW": {
    "label": "Carlow",
    "value": "IE-CW",
    "parent": "IE"
  },
  "IE-CN": {
    "label": "Cavan",
    "value": "IE-CN",
    "parent": "IE"
  },
  "IE-CE": {
    "label": "Clare",
    "value": "IE-CE",
    "parent": "IE"
  },
  "IE-CO": {
    "label": "Cork",
    "value": "IE-CO",
    "parent": "IE"
  },
  "IE-DL": {
    "label": "Donegal",
    "value": "IE-DL",
    "parent": "IE"
  },
  "IE-D": {
    "label": "Dublin",
    "value": "IE-D",
    "parent": "IE"
  },
  "IE-G": {
    "label": "Galway",
    "value": "IE-G",
    "parent": "IE"
  },
  "IE-KY": {
    "label": "Kerry",
    "value": "IE-KY",
    "parent": "IE"
  },
  "IE-KE": {
    "label": "Kildare",
    "value": "IE-KE",
    "parent": "IE"
  },
  "IE-KK": {
    "label": "Kilkenny",
    "value": "IE-KK",
    "parent": "IE"
  },
  "IE-LS": {
    "label": "Laois",
    "value": "IE-LS",
    "parent": "IE"
  },
  "IE-LM": {
    "label": "Leitrim",
    "value": "IE-LM",
    "parent": "IE"
  },
  "IE-LK": {
    "label": "Limerick",
    "value": "IE-LK",
    "parent": "IE"
  },
  "IE-LD": {
    "label": "Longford",
    "value": "IE-LD",
    "parent": "IE"
  },
  "IE-LH": {
    "label": "Louth",
    "value": "IE-LH",
    "parent": "IE"
  },
  "IE-MO": {
    "label": "Mayo",
    "value": "IE-MO",
    "parent": "IE"
  },
  "IE-MH": {
    "label": "Meath",
    "value": "IE-MH",
    "parent": "IE"
  },
  "IE-MN": {
    "label": "Monaghan",
    "value": "IE-MN",
    "parent": "IE"
  },
  "IE-OY": {
    "label": "Offaly",
    "value": "IE-OY",
    "parent": "IE"
  },
  "IE-RN": {
    "label": "Roscommon",
    "value": "IE-RN",
    "parent": "IE"
  },
  "IE-SO": {
    "label": "Sligo",
    "value": "IE-SO",
    "parent": "IE"
  },
  "IE-TA": {
    "label": "Tipperary",
    "value": "IE-TA",
    "parent": "IE"
  },
  "IE-WD": {
    "label": "Waterford",
    "value": "IE-WD",
    "parent": "IE"
  },
  "IE-WH": {
    "label": "Westmeath",
    "value": "IE-WH",
    "parent": "IE"
  },
  "IE-WX": {
    "label": "Wexford",
    "value": "IE-WX",
    "parent": "IE"
  },
  "IE-WW": {
    "label": "Wicklow",
    "value": "IE-WW",
    "parent": "IE"
  },
  "IM": {
    "label": "Isle of Man",
    "value": "IM"
  },
  "IL": {
    "label": "Israel",
    "value": "IL"
  },
  "IL-D": {
    "label": "HaDarom",
    "value": "IL-D",
    "parent": "IL"
  },
  "IL-M": {
    "label": "HaMerkaz",
    "value": "IL-M",
    "parent": "IL"
  },
  "IL-Z": {
    "label": "HaTsafon",
    "value": "IL-Z",
    "parent": "IL"
  },
  "IL-HA": {
    "label": "Hefa",
    "value": "IL-HA",
    "parent": "IL"
  },
  "IL-TA": {
    "label": "Tel Aviv",
    "value": "IL-TA",
    "parent": "IL"
  },
  "IL-JM": {
    "label": "Yerushalayim",
    "value": "IL-JM",
    "parent": "IL"
  },
  "IT": {
    "label": "Italy",
    "value": "IT"
  },
  "IT-65": {
    "label": "Abruzzo",
    "value": "IT-65",
    "parent": "IT"
  },
  "IT-77": {
    "label": "Basilicata",
    "value": "IT-77",
    "parent": "IT"
  },
  "IT-78": {
    "label": "Calabria",
    "value": "IT-78",
    "parent": "IT"
  },
  "IT-72": {
    "label": "Campania",
    "value": "IT-72",
    "parent": "IT"
  },
  "IT-45": {
    "label": "Emilia-Romagna",
    "value": "IT-45",
    "parent": "IT"
  },
  "IT-36": {
    "label": "Friuli-Venezia Giulia",
    "value": "IT-36",
    "parent": "IT"
  },
  "IT-62": {
    "label": "Lazio",
    "value": "IT-62",
    "parent": "IT"
  },
  "IT-42": {
    "label": "Liguria",
    "value": "IT-42",
    "parent": "IT"
  },
  "IT-25": {
    "label": "Lombardia",
    "value": "IT-25",
    "parent": "IT"
  },
  "IT-57": {
    "label": "Marche",
    "value": "IT-57",
    "parent": "IT"
  },
  "IT-67": {
    "label": "Molise",
    "value": "IT-67",
    "parent": "IT"
  },
  "IT-21": {
    "label": "Piemonte",
    "value": "IT-21",
    "parent": "IT"
  },
  "IT-75": {
    "label": "Puglia",
    "value": "IT-75",
    "parent": "IT"
  },
  "IT-88": {
    "label": "Sardegna",
    "value": "IT-88",
    "parent": "IT"
  },
  "IT-82": {
    "label": "Sicilia",
    "value": "IT-82",
    "parent": "IT"
  },
  "IT-52": {
    "label": "Toscana",
    "value": "IT-52",
    "parent": "IT"
  },
  "IT-32": {
    "label": "Trentino-Alto Adige",
    "value": "IT-32",
    "parent": "IT"
  },
  "IT-55": {
    "label": "Umbria",
    "value": "IT-55",
    "parent": "IT"
  },
  "IT-23": {
    "label": "Valle d'Aosta",
    "value": "IT-23",
    "parent": "IT"
  },
  "IT-34": {
    "label": "Veneto",
    "value": "IT-34",
    "parent": "IT"
  },
  "JM": {
    "label": "Jamaica",
    "value": "JM"
  },
  "JM-13": {
    "label": "Clarendon",
    "value": "JM-13",
    "parent": "JM"
  },
  "JM-09": {
    "label": "Hanover",
    "value": "JM-09",
    "parent": "JM"
  },
  "JM-01": {
    "label": "Kingston",
    "value": "JM-01",
    "parent": "JM"
  },
  "JM-12": {
    "label": "Manchester",
    "value": "JM-12",
    "parent": "JM"
  },
  "JM-04": {
    "label": "Portland",
    "value": "JM-04",
    "parent": "JM"
  },
  "JM-02": {
    "label": "Saint Andrew",
    "value": "JM-02",
    "parent": "JM"
  },
  "JM-06": {
    "label": "Saint Ann",
    "value": "JM-06",
    "parent": "JM"
  },
  "JM-14": {
    "label": "Saint Catherine",
    "value": "JM-14",
    "parent": "JM"
  },
  "JM-11": {
    "label": "Saint Elizabeth",
    "value": "JM-11",
    "parent": "JM"
  },
  "JM-08": {
    "label": "Saint James",
    "value": "JM-08",
    "parent": "JM"
  },
  "JM-05": {
    "label": "Saint Mary",
    "value": "JM-05",
    "parent": "JM"
  },
  "JM-03": {
    "label": "Saint Thomas",
    "value": "JM-03",
    "parent": "JM"
  },
  "JM-07": {
    "label": "Trelawny",
    "value": "JM-07",
    "parent": "JM"
  },
  "JM-10": {
    "label": "Westmoreland",
    "value": "JM-10",
    "parent": "JM"
  },
  "JP": {
    "label": "Japan",
    "value": "JP"
  },
  "JP-23": {
    "label": "Aichi",
    "value": "JP-23",
    "parent": "JP"
  },
  "JP-05": {
    "label": "Akita",
    "value": "JP-05",
    "parent": "JP"
  },
  "JP-02": {
    "label": "Aomori",
    "value": "JP-02",
    "parent": "JP"
  },
  "JP-12": {
    "label": "Chiba",
    "value": "JP-12",
    "parent": "JP"
  },
  "JP-38": {
    "label": "Ehime",
    "value": "JP-38",
    "parent": "JP"
  },
  "JP-18": {
    "label": "Fukui",
    "value": "JP-18",
    "parent": "JP"
  },
  "JP-40": {
    "label": "Fukuoka",
    "value": "JP-40",
    "parent": "JP"
  },
  "JP-07": {
    "label": "Fukushima",
    "value": "JP-07",
    "parent": "JP"
  },
  "JP-21": {
    "label": "Gifu",
    "value": "JP-21",
    "parent": "JP"
  },
  "JP-10": {
    "label": "Gunma",
    "value": "JP-10",
    "parent": "JP"
  },
  "JP-34": {
    "label": "Hiroshima",
    "value": "JP-34",
    "parent": "JP"
  },
  "JP-01": {
    "label": "Hokkaido",
    "value": "JP-01",
    "parent": "JP"
  },
  "JP-28": {
    "label": "Hyogo",
    "value": "JP-28",
    "parent": "JP"
  },
  "JP-08": {
    "label": "Ibaraki",
    "value": "JP-08",
    "parent": "JP"
  },
  "JP-17": {
    "label": "Ishikawa",
    "value": "JP-17",
    "parent": "JP"
  },
  "JP-03": {
    "label": "Iwate",
    "value": "JP-03",
    "parent": "JP"
  },
  "JP-37": {
    "label": "Kagawa",
    "value": "JP-37",
    "parent": "JP"
  },
  "JP-46": {
    "label": "Kagoshima",
    "value": "JP-46",
    "parent": "JP"
  },
  "JP-14": {
    "label": "Kanagawa",
    "value": "JP-14",
    "parent": "JP"
  },
  "JP-39": {
    "label": "Kochi",
    "value": "JP-39",
    "parent": "JP"
  },
  "JP-43": {
    "label": "Kumamoto",
    "value": "JP-43",
    "parent": "JP"
  },
  "JP-26": {
    "label": "Kyoto",
    "value": "JP-26",
    "parent": "JP"
  },
  "JP-24": {
    "label": "Mie",
    "value": "JP-24",
    "parent": "JP"
  },
  "JP-04": {
    "label": "Miyagi",
    "value": "JP-04",
    "parent": "JP"
  },
  "JP-45": {
    "label": "Miyazaki",
    "value": "JP-45",
    "parent": "JP"
  },
  "JP-20": {
    "label": "Nagano",
    "value": "JP-20",
    "parent": "JP"
  },
  "JP-42": {
    "label": "Nagasaki",
    "value": "JP-42",
    "parent": "JP"
  },
  "JP-29": {
    "label": "Nara",
    "value": "JP-29",
    "parent": "JP"
  },
  "JP-15": {
    "label": "Niigata",
    "value": "JP-15",
    "parent": "JP"
  },
  "JP-44": {
    "label": "Oita",
    "value": "JP-44",
    "parent": "JP"
  },
  "JP-33": {
    "label": "Okayama",
    "value": "JP-33",
    "parent": "JP"
  },
  "JP-47": {
    "label": "Okinawa",
    "value": "JP-47",
    "parent": "JP"
  },
  "JP-27": {
    "label": "Osaka",
    "value": "JP-27",
    "parent": "JP"
  },
  "JP-41": {
    "label": "Saga",
    "value": "JP-41",
    "parent": "JP"
  },
  "JP-11": {
    "label": "Saitama",
    "value": "JP-11",
    "parent": "JP"
  },
  "JP-25": {
    "label": "Shiga",
    "value": "JP-25",
    "parent": "JP"
  },
  "JP-32": {
    "label": "Shimane",
    "value": "JP-32",
    "parent": "JP"
  },
  "JP-22": {
    "label": "Shizuoka",
    "value": "JP-22",
    "parent": "JP"
  },
  "JP-09": {
    "label": "Tochigi",
    "value": "JP-09",
    "parent": "JP"
  },
  "JP-36": {
    "label": "Tokushima",
    "value": "JP-36",
    "parent": "JP"
  },
  "JP-13": {
    "label": "Tokyo",
    "value": "JP-13",
    "parent": "JP"
  },
  "JP-31": {
    "label": "Tottori",
    "value": "JP-31",
    "parent": "JP"
  },
  "JP-16": {
    "label": "Toyama",
    "value": "JP-16",
    "parent": "JP"
  },
  "JP-30": {
    "label": "Wakayama",
    "value": "JP-30",
    "parent": "JP"
  },
  "JP-06": {
    "label": "Yamagata",
    "value": "JP-06",
    "parent": "JP"
  },
  "JP-35": {
    "label": "Yamaguchi",
    "value": "JP-35",
    "parent": "JP"
  },
  "JP-19": {
    "label": "Yamanashi",
    "value": "JP-19",
    "parent": "JP"
  },
  "JE": {
    "label": "Jersey",
    "value": "JE"
  },
  "JO": {
    "label": "Jordan",
    "value": "JO"
  },
  "JO-AJ": {
    "label": "'Ajlun",
    "value": "JO-AJ",
    "parent": "JO"
  },
  "JO-AQ": {
    "label": "Al 'Aqabah",
    "value": "JO-AQ",
    "parent": "JO"
  },
  "JO-AM": {
    "label": "Al 'Asimah",
    "value": "JO-AM",
    "parent": "JO"
  },
  "JO-BA": {
    "label": "Al Balqa'",
    "value": "JO-BA",
    "parent": "JO"
  },
  "JO-KA": {
    "label": "Al Karak",
    "value": "JO-KA",
    "parent": "JO"
  },
  "JO-MA": {
    "label": "Al Mafraq",
    "value": "JO-MA",
    "parent": "JO"
  },
  "JO-AT": {
    "label": "At Tafilah",
    "value": "JO-AT",
    "parent": "JO"
  },
  "JO-AZ": {
    "label": "Az Zarqa'",
    "value": "JO-AZ",
    "parent": "JO"
  },
  "JO-IR": {
    "label": "Irbid",
    "value": "JO-IR",
    "parent": "JO"
  },
  "JO-JA": {
    "label": "Jarash",
    "value": "JO-JA",
    "parent": "JO"
  },
  "JO-MN": {
    "label": "Ma'an",
    "value": "JO-MN",
    "parent": "JO"
  },
  "JO-MD": {
    "label": "Madaba",
    "value": "JO-MD",
    "parent": "JO"
  },
  "KZ": {
    "label": "Kazakhstan",
    "value": "KZ"
  },
  "KZ-10": {
    "label": "Abay oblysy",
    "value": "KZ-10",
    "parent": "KZ"
  },
  "KZ-75": {
    "label": "Almaty",
    "value": "KZ-75",
    "parent": "KZ"
  },
  "KZ-19": {
    "label": "Almaty oblysy",
    "value": "KZ-19",
    "parent": "KZ"
  },
  "KZ-11": {
    "label": "Aqmola oblysy",
    "value": "KZ-11",
    "parent": "KZ"
  },
  "KZ-15": {
    "label": "Aqtobe oblysy",
    "value": "KZ-15",
    "parent": "KZ"
  },
  "KZ-71": {
    "label": "Astana",
    "value": "KZ-71",
    "parent": "KZ"
  },
  "KZ-23": {
    "label": "Atyrau oblysy",
    "value": "KZ-23",
    "parent": "KZ"
  },
  "KZ-27": {
    "label": "Batys Qazaqstan oblysy",
    "value": "KZ-27",
    "parent": "KZ"
  },
  "KZ-47": {
    "label": "Mangghystau oblysy",
    "value": "KZ-47",
    "parent": "KZ"
  },
  "KZ-55": {
    "label": "Pavlodar oblysy",
    "value": "KZ-55",
    "parent": "KZ"
  },
  "KZ-35": {
    "label": "Qaraghandy oblysy",
    "value": "KZ-35",
    "parent": "KZ"
  },
  "KZ-39": {
    "label": "Qostanay oblysy",
    "value": "KZ-39",
    "parent": "KZ"
  },
  "KZ-43": {
    "label": "Qyzylorda oblysy",
    "value": "KZ-43",
    "parent": "KZ"
  },
  "KZ-63": {
    "label": "Shyghys Qazaqstan oblysy",
    "value": "KZ-63",
    "parent": "KZ"
  },
  "KZ-79": {
    "label": "Shymkent",
    "value": "KZ-79",
    "parent": "KZ"
  },
  "KZ-59": {
    "label": "Soltustik Qazaqstan oblysy",
    "value": "KZ-59",
    "parent": "KZ"
  },
  "KZ-61": {
    "label": "Turkistan oblysy",
    "value": "KZ-61",
    "parent": "KZ"
  },
  "KZ-62": {
    "label": "Ulytau oblysy",
    "value": "KZ-62",
    "parent": "KZ"
  },
  "KZ-31": {
    "label": "Zhambyl oblysy",
    "value": "KZ-31",
    "parent": "KZ"
  },
  "KZ-33": {
    "label": "Zhetisu oblysy",
    "value": "KZ-33",
    "parent": "KZ"
  },
  "KE": {
    "label": "Kenya",
    "value": "KE"
  },
  "KE-01": {
    "label": "Baringo",
    "value": "KE-01",
    "parent": "KE"
  },
  "KE-02": {
    "label": "Bomet",
    "value": "KE-02",
    "parent": "KE"
  },
  "KE-03": {
    "label": "Bungoma",
    "value": "KE-03",
    "parent": "KE"
  },
  "KE-04": {
    "label": "Busia",
    "value": "KE-04",
    "parent": "KE"
  },
  "KE-05": {
    "label": "Elgeyo/Marakwet",
    "value": "KE-05",
    "parent": "KE"
  },
  "KE-06": {
    "label": "Embu",
    "value": "KE-06",
    "parent": "KE"
  },
  "KE-07": {
    "label": "Garissa",
    "value": "KE-07",
    "parent": "KE"
  },
  "KE-08": {
    "label": "Homa Bay",
    "value": "KE-08",
    "parent": "KE"
  },
  "KE-09": {
    "label": "Isiolo",
    "value": "KE-09",
    "parent": "KE"
  },
  "KE-10": {
    "label": "Kajiado",
    "value": "KE-10",
    "parent": "KE"
  },
  "KE-11": {
    "label": "Kakamega",
    "value": "KE-11",
    "parent": "KE"
  },
  "KE-12": {
    "label": "Kericho",
    "value": "KE-12",
    "parent": "KE"
  },
  "KE-13": {
    "label": "Kiambu",
    "value": "KE-13",
    "parent": "KE"
  },
  "KE-14": {
    "label": "Kilifi",
    "value": "KE-14",
    "parent": "KE"
  },
  "KE-15": {
    "label": "Kirinyaga",
    "value": "KE-15",
    "parent": "KE"
  },
  "KE-16": {
    "label": "Kisii",
    "value": "KE-16",
    "parent": "KE"
  },
  "KE-17": {
    "label": "Kisumu",
    "value": "KE-17",
    "parent": "KE"
  },
  "KE-18": {
    "label": "Kitui",
    "value": "KE-18",
    "parent": "KE"
  },
  "KE-19": {
    "label": "Kwale",
    "value": "KE-19",
    "parent": "KE"
  },
  "KE-20": {
    "label": "Laikipia",
    "value": "KE-20",
    "parent": "KE"
  },
  "KE-21": {
    "label": "Lamu",
    "value": "KE-21",
    "parent": "KE"
  },
  "KE-22": {
    "label": "Machakos",
    "value": "KE-22",
    "parent": "KE"
  },
  "KE-23": {
    "label": "Makueni",
    "value": "KE-23",
    "parent": "KE"
  },
  "KE-24": {
    "label": "Mandera",
    "value": "KE-24",
    "parent": "KE"
  },
  "KE-25": {
    "label": "Marsabit",
    "value": "KE-25",
    "parent": "KE"
  },
  "KE-26": {
    "label": "Meru",
    "value": "KE-26",
    "parent": "KE"
  },
  "KE-27": {
    "label": "Migori",
    "value": "KE-27",
    "parent": "KE"
  },
  "KE-28": {
    "label": "Mombasa",
    "value": "KE-28",
    "parent": "KE"
  },
  "KE-29": {
    "label": "Murang'a",
    "value": "KE-29",
    "parent": "KE"
  },
  "KE-30": {
    "label": "Nairobi City",
    "value": "KE-30",
    "parent": "KE"
  },
  "KE-31": {
    "label": "Nakuru",
    "value": "KE-31",
    "parent": "KE"
  },
  "KE-32": {
    "label": "Nandi",
    "value": "KE-32",
    "parent": "KE"
  },
  "KE-33": {
    "label": "Narok",
    "value": "KE-33",
    "parent": "KE"
  },
  "KE-34": {
    "label": "Nyamira",
    "value": "KE-34",
    "parent": "KE"
  },
  "KE-35": {
    "label": "Nyandarua",
    "value": "KE-35",
    "parent": "KE"
  },
  "KE-36": {
    "label": "Nyeri",
    "value": "KE-36",
    "parent": "KE"
  },
  "KE-37": {
    "label": "Samburu",
    "value": "KE-37",
    "parent": "KE"
  },
  "KE-38": {
    "label": "Siaya",
    "value": "KE-38",
    "parent": "KE"
  },
  "KE-39": {
    "label": "Taita/Taveta",
    "value": "KE-39",
    "parent": "KE"
  },
  "KE-40": {
    "label": "Tana River",
    "value": "KE-40",
    "parent": "KE"
  },
  "KE-41": {
    "label": "Tharaka-Nithi",
    "value": "KE-41",
    "parent": "KE"
  },
  "KE-42": {
    "label": "Trans Nzoia",
    "value": "KE-42",
    "parent": "KE"
  },
  "KE-43": {
    "label": "Turkana",
    "value": "KE-43",
    "parent": "KE"
  },
  "KE-44": {
    "label": "Uasin Gishu",
    "value": "KE-44",
    "parent": "KE"
  },
  "KE-45": {
    "label": "Vihiga",
    "value": "KE-45",
    "parent": "KE"
  },
  "KE-46": {
    "label": "Wajir",
    "value": "KE-46",
    "parent": "KE"
  },
  "KE-47": {
    "label": "West Pokot",
    "value": "KE-47",
    "parent": "KE"
  },
  "KI": {
    "label": "Kiribati",
    "value": "KI"
  },
  "KI-G": {
    "label": "Gilbert Islands",
    "value": "KI-G",
    "parent": "KI"
  },
  "KI-L": {
    "label": "Line Islands",
    "value": "KI-L",
    "parent": "KI"
  },
  "KP": {
    "label": "Korea, Democratic People's Republic of",
    "value": "KP"
  },
  "KP-01": {
    "label": "P'yongyang",
    "value": "KP-01",
    "parent": "KP"
  },
  "KR": {
    "label": "Korea, Republic of",
    "value": "KR"
  },
  "KR-26": {
    "label": "Busan-gwangyeoksi",
    "value": "KR-26",
    "parent": "KR"
  },
  "KR-43": {
    "label": "Chungcheongbuk-do",
    "value": "KR-43",
    "parent": "KR"
  },
  "KR-44": {
    "label": "Chungcheongnam-do",
    "value": "KR-44",
    "parent": "KR"
  },
  "KR-27": {
    "label": "Daegu-gwangyeoksi",
    "value": "KR-27",
    "parent": "KR"
  },
  "KR-30": {
    "label": "Daejeon-gwangyeoksi",
    "value": "KR-30",
    "parent": "KR"
  },
  "KR-42": {
    "label": "Gangwon-teukbyeoljachido",
    "value": "KR-42",
    "parent": "KR"
  },
  "KR-29": {
    "label": "Gwangju-gwangyeoksi",
    "value": "KR-29",
    "parent": "KR"
  },
  "KR-41": {
    "label": "Gyeonggi-do",
    "value": "KR-41",
    "parent": "KR"
  },
  "KR-47": {
    "label": "Gyeongsangbuk-do",
    "value": "KR-47",
    "parent": "KR"
  },
  "KR-48": {
    "label": "Gyeongsangnam-do",
    "value": "KR-48",
    "parent": "KR"
  },
  "KR-28": {
    "label": "Incheon-gwangyeoksi",
    "value": "KR-28",
    "parent": "KR"
  },
  "KR-49": {
    "label": "Jeju-teukbyeoljachido",
    "value": "KR-49",
    "parent": "KR"
  },
  "KR-45": {
    "label": "Jeollabuk-do",
    "value": "KR-45",
    "parent": "KR"
  },
  "KR-46": {
    "label": "Jeollanam-do",
    "value": "KR-46",
    "parent": "KR"
  },
  "KR-50": {
    "label": "Sejong",
    "value": "KR-50",
    "parent": "KR"
  },
  "KR-11": {
    "label": "Seoul-teukbyeolsi",
    "value": "KR-11",
    "parent": "KR"
  },
  "KR-31": {
    "label": "Ulsan-gwangyeoksi",
    "value": "KR-31",
    "parent": "KR"
  },
  "KW": {
    "label": "Kuwait",
    "value": "KW"
  },
  "KW-KU": {
    "label": "Al 'Asimah",
    "value": "KW-KU",
    "parent": "KW"
  },
  "KW-AH": {
    "label": "Al Ahmadi",
    "value": "KW-AH",
    "parent": "KW"
  },
  "KW-FA": {
    "label": "Al Farwaniyah",
    "value": "KW-FA",
    "parent": "KW"
  },
  "KW-JA": {
    "label": "Al Jahra'",
    "value": "KW-JA",
    "parent": "KW"
  },
  "KW-HA": {
    "label": "Hawalli",
    "value": "KW-HA",
    "parent": "KW"
  },
  "KW-MU": {
    "label": "Mubarak al Kabir",
    "value": "KW-MU",
    "parent": "KW"
  },
  "KG": {
    "label": "Kyrgyzstan",
    "value": "KG"
  },
  "KG-B": {
    "label": "Batken",
    "value": "KG-B",
    "parent": "KG"
  },
  "KG-GB": {
    "label": "Bishkek Shaary",
    "value": "KG-GB",
    "parent": "KG"
  },
  "KG-C": {
    "label": "Chuy",
    "value": "KG-C",
    "parent": "KG"
  },
  "KG-J": {
    "label": "Jalal-Abad",
    "value": "KG-J",
    "parent": "KG"
  },
  "KG-N": {
    "label": "Naryn",
    "value": "KG-N",
    "parent": "KG"
  },
  "KG-GO": {
    "label": "Osh Shaary",
    "value": "KG-GO",
    "parent": "KG"
  },
  "KG-T": {
    "label": "Talas",
    "value": "KG-T",
    "parent": "KG"
  },
  "KG-Y": {
    "label": "Ysyk-Kol",
    "value": "KG-Y",
    "parent": "KG"
  },
  "LA": {
    "label": "Lao People's Democratic Republic",
    "value": "LA"
  },
  "LA-AT": {
    "label": "Attapu",
    "value": "LA-AT",
    "parent": "LA"
  },
  "LA-BK": {
    "label": "Bokeo",
    "value": "LA-BK",
    "parent": "LA"
  },
  "LA-BL": {
    "label": "Bolikhamxai",
    "value": "LA-BL",
    "parent": "LA"
  },
  "LA-CH": {
    "label": "Champasak",
    "value": "LA-CH",
    "parent": "LA"
  },
  "LA-KH": {
    "label": "Khammouan",
    "value": "LA-KH",
    "parent": "LA"
  },
  "LA-LM": {
    "label": "Louang Namtha",
    "value": "LA-LM",
    "parent": "LA"
  },
  "LA-LP": {
    "label": "Louangphabang",
    "value": "LA-LP",
    "parent": "LA"
  },
  "LA-OU": {
    "label": "Oudomxai",
    "value": "LA-OU",
    "parent": "LA"
  },
  "LA-PH": {
    "label": "Phongsali",
    "value": "LA-PH",
    "parent": "LA"
  },
  "LA-SV": {
    "label": "Savannakhet",
    "value": "LA-SV",
    "parent": "LA"
  },
  "LA-VI": {
    "label": "Viangchan",
    "value": "LA-VI",
    "parent": "LA"
  },
  "LA-XA": {
    "label": "Xaignabouli",
    "value": "LA-XA",
    "parent": "LA"
  },
  "LA-XE": {
    "label": "Xekong",
    "value": "LA-XE",
    "parent": "LA"
  },
  "LA-XI": {
    "label": "Xiangkhouang",
    "value": "LA-XI",
    "parent": "LA"
  },
  "LV": {
    "label": "Latvia",
    "value": "LV"
  },
  "LV-011": {
    "label": "Adazu novads",
    "value": "LV-011",
    "parent": "LV"
  },
  "LV-002": {
    "label": "Aizkraukles novads",
    "value": "LV-002",
    "parent": "LV"
  },
  "LV-007": {
    "label": "Aluksnes novads",
    "value": "LV-007",
    "parent": "LV"
  },
  "LV-111": {
    "label": "Augsdaugavas novads",
    "value": "LV-111",
    "parent": "LV"
  },
  "LV-015": {
    "label": "Balvu novads",
    "value": "LV-015",
    "parent": "LV"
  },
  "LV-016": {
    "label": "Bauskas novads",
    "value": "LV-016",
    "parent": "LV"
  },
  "LV-022": {
    "label": "Cesu novads",
    "value": "LV-022",
    "parent": "LV"
  },
  "LV-DGV": {
    "label": "Daugavpils",
    "value": "LV-DGV",
    "parent": "LV"
  },
  "LV-112": {
    "label": "Dienvidkurzemes novads",
    "value": "LV-112",
    "parent": "LV"
  },
  "LV-026": {
    "label": "Dobeles novads",
    "value": "LV-026",
    "parent": "LV"
  },
  "LV-033": {
    "label": "Gulbenes novads",
    "value": "LV-033",
    "parent": "LV"
  },
  "LV-042": {
    "label": "Jekabpils novads",
    "value": "LV-042",
    "parent": "LV"
  },
  "LV-JEL": {
    "label": "Jelgava",
    "value": "LV-JEL",
    "parent": "LV"
  },
  "LV-041": {
    "label": "Jelgavas novads",
    "value": "LV-041",
    "parent": "LV"
  },
  "LV-JUR": {
    "label": "Jurmala",
    "value": "LV-JUR",
    "parent": "LV"
  },
  "LV-052": {
    "label": "Kekavas novads",
    "value": "LV-052",
    "parent": "LV"
  },
  "LV-047": {
    "label": "Kraslavas novads",
    "value": "LV-047",
    "parent": "LV"
  },
  "LV-050": {
    "label": "Kuldigas novads",
    "value": "LV-050",
    "parent": "LV"
  },
  "LV-LPX": {
    "label": "Liepaja",
    "value": "LV-LPX",
    "parent": "LV"
  },
  "LV-054": {
    "label": "Limbazu novads",
    "value": "LV-054",
    "parent": "LV"
  },
  "LV-056": {
    "label": "Livanu novads",
    "value": "LV-056",
    "parent": "LV"
  },
  "LV-058": {
    "label": "Ludzas novads",
    "value": "LV-058",
    "parent": "LV"
  },
  "LV-059": {
    "label": "Madonas novads",
    "value": "LV-059",
    "parent": "LV"
  },
  "LV-062": {
    "label": "Marupes novads",
    "value": "LV-062",
    "parent": "LV"
  },
  "LV-067": {
    "label": "Ogres novads",
    "value": "LV-067",
    "parent": "LV"
  },
  "LV-068": {
    "label": "Olaines novads",
    "value": "LV-068",
    "parent": "LV"
  },
  "LV-073": {
    "label": "Preilu novads",
    "value": "LV-073",
    "parent": "LV"
  },
  "LV-077": {
    "label": "Rezeknes novads",
    "value": "LV-077",
    "parent": "LV"
  },
  "LV-RIX": {
    "label": "Riga",
    "value": "LV-RIX",
    "parent": "LV"
  },
  "LV-080": {
    "label": "Ropazu novads",
    "value": "LV-080",
    "parent": "LV"
  },
  "LV-087": {
    "label": "Salaspils novads",
    "value": "LV-087",
    "parent": "LV"
  },
  "LV-088": {
    "label": "Saldus novads",
    "value": "LV-088",
    "parent": "LV"
  },
  "LV-089": {
    "label": "Saulkrastu novads",
    "value": "LV-089",
    "parent": "LV"
  },
  "LV-091": {
    "label": "Siguldas novads",
    "value": "LV-091",
    "parent": "LV"
  },
  "LV-094": {
    "label": "Smiltenes novads",
    "value": "LV-094",
    "parent": "LV"
  },
  "LV-097": {
    "label": "Talsu novads",
    "value": "LV-097",
    "parent": "LV"
  },
  "LV-099": {
    "label": "Tukuma novads",
    "value": "LV-099",
    "parent": "LV"
  },
  "LV-101": {
    "label": "Valkas novads",
    "value": "LV-101",
    "parent": "LV"
  },
  "LV-113": {
    "label": "Valmieras novads",
    "value": "LV-113",
    "parent": "LV"
  },
  "LV-102": {
    "label": "Varaklanu novads",
    "value": "LV-102",
    "parent": "LV"
  },
  "LV-106": {
    "label": "Ventspils novads",
    "value": "LV-106",
    "parent": "LV"
  },
  "LB": {
    "label": "Lebanon",
    "value": "LB"
  },
  "LB-AK": {
    "label": "Aakkar",
    "value": "LB-AK",
    "parent": "LB"
  },
  "LB-BH": {
    "label": "Baalbek-Hermel",
    "value": "LB-BH",
    "parent": "LB"
  },
  "LB-BI": {
    "label": "Beqaa",
    "value": "LB-BI",
    "parent": "LB"
  },
  "LB-BA": {
    "label": "Beyrouth",
    "value": "LB-BA",
    "parent": "LB"
  },
  "LB-AS": {
    "label": "Liban-Nord",
    "value": "LB-AS",
    "parent": "LB"
  },
  "LB-JA": {
    "label": "Liban-Sud",
    "value": "LB-JA",
    "parent": "LB"
  },
  "LB-JL": {
    "label": "Mont-Liban",
    "value": "LB-JL",
    "parent": "LB"
  },
  "LB-NA": {
    "label": "Nabatiye",
    "value": "LB-NA",
    "parent": "LB"
  },
  "LS": {
    "label": "Lesotho",
    "value": "LS"
  },
  "LS-D": {
    "label": "Berea",
    "value": "LS-D",
    "parent": "LS"
  },
  "LS-B": {
    "label": "Botha-Bothe",
    "value": "LS-B",
    "parent": "LS"
  },
  "LS-C": {
    "label": "Leribe",
    "value": "LS-C",
    "parent": "LS"
  },
  "LS-E": {
    "label": "Mafeteng",
    "value": "LS-E",
    "parent": "LS"
  },
  "LS-A": {
    "label": "Maseru",
    "value": "LS-A",
    "parent": "LS"
  },
  "LS-F": {
    "label": "Mohale's Hoek",
    "value": "LS-F",
    "parent": "LS"
  },
  "LS-J": {
    "label": "Mokhotlong",
    "value": "LS-J",
    "parent": "LS"
  },
  "LS-H": {
    "label": "Qacha's Nek",
    "value": "LS-H",
    "parent": "LS"
  },
  "LS-G": {
    "label": "Quthing",
    "value": "LS-G",
    "parent": "LS"
  },
  "LS-K": {
    "label": "Thaba-Tseka",
    "value": "LS-K",
    "parent": "LS"
  },
  "LR": {
    "label": "Liberia",
    "value": "LR"
  },
  "LR-BM": {
    "label": "Bomi",
    "value": "LR-BM",
    "parent": "LR"
  },
  "LR-BG": {
    "label": "Bong",
    "value": "LR-BG",
    "parent": "LR"
  },
  "LR-CM": {
    "label": "Grand Cape Mount",
    "value": "LR-CM",
    "parent": "LR"
  },
  "LR-GG": {
    "label": "Grand Gedeh",
    "value": "LR-GG",
    "parent": "LR"
  },
  "LR-MG": {
    "label": "Margibi",
    "value": "LR-MG",
    "parent": "LR"
  },
  "LR-MO": {
    "label": "Montserrado",
    "value": "LR-MO",
    "parent": "LR"
  },
  "LR-NI": {
    "label": "Nimba",
    "value": "LR-NI",
    "parent": "LR"
  },
  "LR-RI": {
    "label": "River Cess",
    "value": "LR-RI",
    "parent": "LR"
  },
  "LR-RG": {
    "label": "River Gee",
    "value": "LR-RG",
    "parent": "LR"
  },
  "LR-SI": {
    "label": "Sinoe",
    "value": "LR-SI",
    "parent": "LR"
  },
  "LY": {
    "label": "Libya",
    "value": "LY"
  },
  "LY-BU": {
    "label": "Al Butnan",
    "value": "LY-BU",
    "parent": "LY"
  },
  "LY-JA": {
    "label": "Al Jabal al Akhdar",
    "value": "LY-JA",
    "parent": "LY"
  },
  "LY-JG": {
    "label": "Al Jabal al Gharbi",
    "value": "LY-JG",
    "parent": "LY"
  },
  "LY-JI": {
    "label": "Al Jafarah",
    "value": "LY-JI",
    "parent": "LY"
  },
  "LY-JU": {
    "label": "Al Jufrah",
    "value": "LY-JU",
    "parent": "LY"
  },
  "LY-KF": {
    "label": "Al Kufrah",
    "value": "LY-KF",
    "parent": "LY"
  },
  "LY-MJ": {
    "label": "Al Marj",
    "value": "LY-MJ",
    "parent": "LY"
  },
  "LY-MB": {
    "label": "Al Marqab",
    "value": "LY-MB",
    "parent": "LY"
  },
  "LY-WA": {
    "label": "Al Wahat",
    "value": "LY-WA",
    "parent": "LY"
  },
  "LY-NQ": {
    "label": "An Nuqat al Khams",
    "value": "LY-NQ",
    "parent": "LY"
  },
  "LY-ZA": {
    "label": "Az Zawiyah",
    "value": "LY-ZA",
    "parent": "LY"
  },
  "LY-BA": {
    "label": "Banghazi",
    "value": "LY-BA",
    "parent": "LY"
  },
  "LY-DR": {
    "label": "Darnah",
    "value": "LY-DR",
    "parent": "LY"
  },
  "LY-MI": {
    "label": "Misratah",
    "value": "LY-MI",
    "parent": "LY"
  },
  "LY-MQ": {
    "label": "Murzuq",
    "value": "LY-MQ",
    "parent": "LY"
  },
  "LY-NL": {
    "label": "Nalut",
    "value": "LY-NL",
    "parent": "LY"
  },
  "LY-SB": {
    "label": "Sabha",
    "value": "LY-SB",
    "parent": "LY"
  },
  "LY-SR": {
    "label": "Surt",
    "value": "LY-SR",
    "parent": "LY"
  },
  "LY-TB": {
    "label": "Tarabulus",
    "value": "LY-TB",
    "parent": "LY"
  },
  "LY-WD": {
    "label": "Wadi al Hayat",
    "value": "LY-WD",
    "parent": "LY"
  },
  "LY-WS": {
    "label": "Wadi ash Shati'",
    "value": "LY-WS",
    "parent": "LY"
  },
  "LI": {
    "label": "Liechtenstein",
    "value": "LI"
  },
  "LI-01": {
    "label": "Balzers",
    "value": "LI-01",
    "parent": "LI"
  },
  "LI-02": {
    "label": "Eschen",
    "value": "LI-02",
    "parent": "LI"
  },
  "LI-03": {
    "label": "Gamprin",
    "value": "LI-03",
    "parent": "LI"
  },
  "LI-04": {
    "label": "Mauren",
    "value": "LI-04",
    "parent": "LI"
  },
  "LI-06": {
    "label": "Ruggell",
    "value": "LI-06",
    "parent": "LI"
  },
  "LI-07": {
    "label": "Schaan",
    "value": "LI-07",
    "parent": "LI"
  },
  "LI-09": {
    "label": "Triesen",
    "value": "LI-09",
    "parent": "LI"
  },
  "LI-10": {
    "label": "Triesenberg",
    "value": "LI-10",
    "parent": "LI"
  },
  "LI-11": {
    "label": "Vaduz",
    "value": "LI-11",
    "parent": "LI"
  },
  "LT": {
    "label": "Lithuania",
    "value": "LT"
  },
  "LT-AL": {
    "label": "Alytaus apskritis",
    "value": "LT-AL",
    "parent": "LT"
  },
  "LT-KU": {
    "label": "Kauno apskritis",
    "value": "LT-KU",
    "parent": "LT"
  },
  "LT-KL": {
    "label": "Klaipedos apskritis",
    "value": "LT-KL",
    "parent": "LT"
  },
  "LT-MR": {
    "label": "Marijampoles apskritis",
    "value": "LT-MR",
    "parent": "LT"
  },
  "LT-PN": {
    "label": "Panevezio apskritis",
    "value": "LT-PN",
    "parent": "LT"
  },
  "LT-SA": {
    "label": "Siauliu apskritis",
    "value": "LT-SA",
    "parent": "LT"
  },
  "LT-TA": {
    "label": "Taurages apskritis",
    "value": "LT-TA",
    "parent": "LT"
  },
  "LT-TE": {
    "label": "Telsiu apskritis",
    "value": "LT-TE",
    "parent": "LT"
  },
  "LT-UT": {
    "label": "Utenos apskritis",
    "value": "LT-UT",
    "parent": "LT"
  },
  "LT-VL": {
    "label": "Vilniaus apskritis",
    "value": "LT-VL",
    "parent": "LT"
  },
  "LU": {
    "label": "Luxembourg",
    "value": "LU"
  },
  "LU-CA": {
    "label": "Capellen",
    "value": "LU-CA",
    "parent": "LU"
  },
  "LU-CL": {
    "label": "Clervaux",
    "value": "LU-CL",
    "parent": "LU"
  },
  "LU-DI": {
    "label": "Diekirch",
    "value": "LU-DI",
    "parent": "LU"
  },
  "LU-EC": {
    "label": "Echternach",
    "value": "LU-EC",
    "parent": "LU"
  },
  "LU-ES": {
    "label": "Esch-sur-Alzette",
    "value": "LU-ES",
    "parent": "LU"
  },
  "LU-GR": {
    "label": "Grevenmacher",
    "value": "LU-GR",
    "parent": "LU"
  },
  "LU-LU": {
    "label": "Luxembourg",
    "value": "LU-LU",
    "parent": "LU"
  },
  "LU-ME": {
    "label": "Mersch",
    "value": "LU-ME",
    "parent": "LU"
  },
  "LU-RD": {
    "label": "Redange",
    "value": "LU-RD",
    "parent": "LU"
  },
  "LU-RM": {
    "label": "Remich",
    "value": "LU-RM",
    "parent": "LU"
  },
  "LU-VD": {
    "label": "Vianden",
    "value": "LU-VD",
    "parent": "LU"
  },
  "LU-WI": {
    "label": "Wiltz",
    "value": "LU-WI",
    "parent": "LU"
  },
  "MO": {
    "label": "Macao",
    "value": "MO"
  },
  "MG": {
    "label": "Madagascar",
    "value": "MG"
  },
  "MG-T": {
    "label": "Antananarivo",
    "value": "MG-T",
    "parent": "MG"
  },
  "MG-D": {
    "label": "Antsiranana",
    "value": "MG-D",
    "parent": "MG"
  },
  "MG-F": {
    "label": "Fianarantsoa",
    "value": "MG-F",
    "parent": "MG"
  },
  "MG-M": {
    "label": "Mahajanga",
    "value": "MG-M",
    "parent": "MG"
  },
  "MG-A": {
    "label": "Toamasina",
    "value": "MG-A",
    "parent": "MG"
  },
  "MG-U": {
    "label": "Toliara",
    "value": "MG-U",
    "parent": "MG"
  },
  "MW": {
    "label": "Malawi",
    "value": "MW"
  },
  "MW-BA": {
    "label": "Balaka",
    "value": "MW-BA",
    "parent": "MW"
  },
  "MW-BL": {
    "label": "Blantyre",
    "value": "MW-BL",
    "parent": "MW"
  },
  "MW-CK": {
    "label": "Chikwawa",
    "value": "MW-CK",
    "parent": "MW"
  },
  "MW-CR": {
    "label": "Chiradzulu",
    "value": "MW-CR",
    "parent": "MW"
  },
  "MW-DE": {
    "label": "Dedza",
    "value": "MW-DE",
    "parent": "MW"
  },
  "MW-DO": {
    "label": "Dowa",
    "value": "MW-DO",
    "parent": "MW"
  },
  "MW-KR": {
    "label": "Karonga",
    "value": "MW-KR",
    "parent": "MW"
  },
  "MW-LI": {
    "label": "Lilongwe",
    "value": "MW-LI",
    "parent": "MW"
  },
  "MW-MH": {
    "label": "Machinga",
    "value": "MW-MH",
    "parent": "MW"
  },
  "MW-MG": {
    "label": "Mangochi",
    "value": "MW-MG",
    "parent": "MW"
  },
  "MW-MW": {
    "label": "Mwanza",
    "value": "MW-MW",
    "parent": "MW"
  },
  "MW-MZ": {
    "label": "Mzimba",
    "value": "MW-MZ",
    "parent": "MW"
  },
  "MW-NE": {
    "label": "Neno",
    "value": "MW-NE",
    "parent": "MW"
  },
  "MW-NK": {
    "label": "Nkhotakota",
    "value": "MW-NK",
    "parent": "MW"
  },
  "MW-NI": {
    "label": "Ntchisi",
    "value": "MW-NI",
    "parent": "MW"
  },
  "MW-SA": {
    "label": "Salima",
    "value": "MW-SA",
    "parent": "MW"
  },
  "MW-TH": {
    "label": "Thyolo",
    "value": "MW-TH",
    "parent": "MW"
  },
  "MW-ZO": {
    "label": "Zomba",
    "value": "MW-ZO",
    "parent": "MW"
  },
  "MY": {
    "label": "Malaysia",
    "value": "MY"
  },
  "MY-01": {
    "label": "Johor",
    "value": "MY-01",
    "parent": "MY"
  },
  "MY-02": {
    "label": "Kedah",
    "value": "MY-02",
    "parent": "MY"
  },
  "MY-03": {
    "label": "Kelantan",
    "value": "MY-03",
    "parent": "MY"
  },
  "MY-04": {
    "label": "Melaka",
    "value": "MY-04",
    "parent": "MY"
  },
  "MY-05": {
    "label": "Negeri Sembilan",
    "value": "MY-05",
    "parent": "MY"
  },
  "MY-06": {
    "label": "Pahang",
    "value": "MY-06",
    "parent": "MY"
  },
  "MY-08": {
    "label": "Perak",
    "value": "MY-08",
    "parent": "MY"
  },
  "MY-09": {
    "label": "Perlis",
    "value": "MY-09",
    "parent": "MY"
  },
  "MY-07": {
    "label": "Pulau Pinang",
    "value": "MY-07",
    "parent": "MY"
  },
  "MY-12": {
    "label": "Sabah",
    "value": "MY-12",
    "parent": "MY"
  },
  "MY-13": {
    "label": "Sarawak",
    "value": "MY-13",
    "parent": "MY"
  },
  "MY-10": {
    "label": "Selangor",
    "value": "MY-10",
    "parent": "MY"
  },
  "MY-11": {
    "label": "Terengganu",
    "value": "MY-11",
    "parent": "MY"
  },
  "MY-14": {
    "label": "Wilayah Persekutuan Kuala Lumpur",
    "value": "MY-14",
    "parent": "MY"
  },
  "MY-15": {
    "label": "Wilayah Persekutuan Labuan",
    "value": "MY-15",
    "parent": "MY"
  },
  "MY-16": {
    "label": "Wilayah Persekutuan Putrajaya",
    "value": "MY-16",
    "parent": "MY"
  },
  "MV": {
    "label": "Maldives",
    "value": "MV"
  },
  "MV-01": {
    "label": "Addu City",
    "value": "MV-01",
    "parent": "MV"
  },
  "MV-03": {
    "label": "Faadhippolhu",
    "value": "MV-03",
    "parent": "MV"
  },
  "MV-04": {
    "label": "Felidhu Atoll",
    "value": "MV-04",
    "parent": "MV"
  },
  "MV-29": {
    "label": "Fuvammulah",
    "value": "MV-29",
    "parent": "MV"
  },
  "MV-05": {
    "label": "Hahdhunmathi",
    "value": "MV-05",
    "parent": "MV"
  },
  "MV-MLE": {
    "label": "Male",
    "value": "MV-MLE",
    "parent": "MV"
  },
  "MV-12": {
    "label": "Mulaku Atoll",
    "value": "MV-12",
    "parent": "MV"
  },
  "MV-02": {
    "label": "North Ari Atoll",
    "value": "MV-02",
    "parent": "MV"
  },
  "MV-27": {
    "label": "North Huvadhu Atoll",
    "value": "MV-27",
    "parent": "MV"
  },
  "MV-13": {
    "label": "North Maalhosmadulu",
    "value": "MV-13",
    "parent": "MV"
  },
  "MV-24": {
    "label": "North Miladhunmadulu",
    "value": "MV-24",
    "parent": "MV"
  },
  "MV-14": {
    "label": "North Nilandhe Atoll",
    "value": "MV-14",
    "parent": "MV"
  },
  "MV-07": {
    "label": "North Thiladhunmathi",
    "value": "MV-07",
    "parent": "MV"
  },
  "MV-00": {
    "label": "South Ari Atoll",
    "value": "MV-00",
    "parent": "MV"
  },
  "MV-28": {
    "label": "South Huvadhu Atoll",
    "value": "MV-28",
    "parent": "MV"
  },
  "MV-20": {
    "label": "South Maalhosmadulu",
    "value": "MV-20",
    "parent": "MV"
  },
  "MV-25": {
    "label": "South Miladhunmadulu",
    "value": "MV-25",
    "parent": "MV"
  },
  "MV-17": {
    "label": "South Nilandhe Atoll",
    "value": "MV-17",
    "parent": "MV"
  },
  "MV-23": {
    "label": "South Thiladhunmathi",
    "value": "MV-23",
    "parent": "MV"
  },
  "ML": {
    "label": "Mali",
    "value": "ML"
  },
  "ML-BKO": {
    "label": "Bamako",
    "value": "ML-BKO",
    "parent": "ML"
  },
  "ML-7": {
    "label": "Gao",
    "value": "ML-7",
    "parent": "ML"
  },
  "ML-1": {
    "label": "Kayes",
    "value": "ML-1",
    "parent": "ML"
  },
  "ML-8": {
    "label": "Kidal",
    "value": "ML-8",
    "parent": "ML"
  },
  "ML-2": {
    "label": "Koulikoro",
    "value": "ML-2",
    "parent": "ML"
  },
  "ML-5": {
    "label": "Mopti",
    "value": "ML-5",
    "parent": "ML"
  },
  "ML-4": {
    "label": "Segou",
    "value": "ML-4",
    "parent": "ML"
  },
  "ML-3": {
    "label": "Sikasso",
    "value": "ML-3",
    "parent": "ML"
  },
  "ML-6": {
    "label": "Tombouctou",
    "value": "ML-6",
    "parent": "ML"
  },
  "MT": {
    "label": "Malta",
    "value": "MT"
  },
  "MT-01": {
    "label": "Attard",
    "value": "MT-01",
    "parent": "MT"
  },
  "MT-02": {
    "label": "Balzan",
    "value": "MT-02",
    "parent": "MT"
  },
  "MT-03": {
    "label": "Birgu",
    "value": "MT-03",
    "parent": "MT"
  },
  "MT-04": {
    "label": "Birkirkara",
    "value": "MT-04",
    "parent": "MT"
  },
  "MT-05": {
    "label": "Birzebbuga",
    "value": "MT-05",
    "parent": "MT"
  },
  "MT-06": {
    "label": "Bormla",
    "value": "MT-06",
    "parent": "MT"
  },
  "MT-07": {
    "label": "Dingli",
    "value": "MT-07",
    "parent": "MT"
  },
  "MT-08": {
    "label": "Fgura",
    "value": "MT-08",
    "parent": "MT"
  },
  "MT-09": {
    "label": "Floriana",
    "value": "MT-09",
    "parent": "MT"
  },
  "MT-10": {
    "label": "Fontana",
    "value": "MT-10",
    "parent": "MT"
  },
  "MT-13": {
    "label": "Ghajnsielem",
    "value": "MT-13",
    "parent": "MT"
  },
  "MT-14": {
    "label": "Gharb",
    "value": "MT-14",
    "parent": "MT"
  },
  "MT-15": {
    "label": "Gharghur",
    "value": "MT-15",
    "parent": "MT"
  },
  "MT-16": {
    "label": "Ghasri",
    "value": "MT-16",
    "parent": "MT"
  },
  "MT-17": {
    "label": "Ghaxaq",
    "value": "MT-17",
    "parent": "MT"
  },
  "MT-11": {
    "label": "Gudja",
    "value": "MT-11",
    "parent": "MT"
  },
  "MT-12": {
    "label": "Gzira",
    "value": "MT-12",
    "parent": "MT"
  },
  "MT-18": {
    "label": "Hamrun",
    "value": "MT-18",
    "parent": "MT"
  },
  "MT-19": {
    "label": "Iklin",
    "value": "MT-19",
    "parent": "MT"
  },
  "MT-20": {
    "label": "Isla",
    "value": "MT-20",
    "parent": "MT"
  },
  "MT-21": {
    "label": "Kalkara",
    "value": "MT-21",
    "parent": "MT"
  },
  "MT-23": {
    "label": "Kirkop",
    "value": "MT-23",
    "parent": "MT"
  },
  "MT-24": {
    "label": "Lija",
    "value": "MT-24",
    "parent": "MT"
  },
  "MT-25": {
    "label": "Luqa",
    "value": "MT-25",
    "parent": "MT"
  },
  "MT-26": {
    "label": "Marsa",
    "value": "MT-26",
    "parent": "MT"
  },
  "MT-27": {
    "label": "Marsaskala",
    "value": "MT-27",
    "parent": "MT"
  },
  "MT-28": {
    "label": "Marsaxlokk",
    "value": "MT-28",
    "parent": "MT"
  },
  "MT-29": {
    "label": "Mdina",
    "value": "MT-29",
    "parent": "MT"
  },
  "MT-30": {
    "label": "Mellieha",
    "value": "MT-30",
    "parent": "MT"
  },
  "MT-31": {
    "label": "Mgarr",
    "value": "MT-31",
    "parent": "MT"
  },
  "MT-32": {
    "label": "Mosta",
    "value": "MT-32",
    "parent": "MT"
  },
  "MT-33": {
    "label": "Mqabba",
    "value": "MT-33",
    "parent": "MT"
  },
  "MT-34": {
    "label": "Msida",
    "value": "MT-34",
    "parent": "MT"
  },
  "MT-35": {
    "label": "Mtarfa",
    "value": "MT-35",
    "parent": "MT"
  },
  "MT-36": {
    "label": "Munxar",
    "value": "MT-36",
    "parent": "MT"
  },
  "MT-37": {
    "label": "Nadur",
    "value": "MT-37",
    "parent": "MT"
  },
  "MT-38": {
    "label": "Naxxar",
    "value": "MT-38",
    "parent": "MT"
  },
  "MT-39": {
    "label": "Paola",
    "value": "MT-39",
    "parent": "MT"
  },
  "MT-40": {
    "label": "Pembroke",
    "value": "MT-40",
    "parent": "MT"
  },
  "MT-41": {
    "label": "Pieta",
    "value": "MT-41",
    "parent": "MT"
  },
  "MT-42": {
    "label": "Qala",
    "value": "MT-42",
    "parent": "MT"
  },
  "MT-43": {
    "label": "Qormi",
    "value": "MT-43",
    "parent": "MT"
  },
  "MT-44": {
    "label": "Qrendi",
    "value": "MT-44",
    "parent": "MT"
  },
  "MT-45": {
    "label": "Rabat Gozo",
    "value": "MT-45",
    "parent": "MT"
  },
  "MT-46": {
    "label": "Rabat Malta",
    "value": "MT-46",
    "parent": "MT"
  },
  "MT-47": {
    "label": "Safi",
    "value": "MT-47",
    "parent": "MT"
  },
  "MT-49": {
    "label": "Saint John",
    "value": "MT-49",
    "parent": "MT"
  },
  "MT-48": {
    "label": "Saint Julian's",
    "value": "MT-48",
    "parent": "MT"
  },
  "MT-50": {
    "label": "Saint Lawrence",
    "value": "MT-50",
    "parent": "MT"
  },
  "MT-53": {
    "label": "Saint Lucia's",
    "value": "MT-53",
    "parent": "MT"
  },
  "MT-51": {
    "label": "Saint Paul's Bay",
    "value": "MT-51",
    "parent": "MT"
  },
  "MT-52": {
    "label": "Sannat",
    "value": "MT-52",
    "parent": "MT"
  },
  "MT-54": {
    "label": "Santa Venera",
    "value": "MT-54",
    "parent": "MT"
  },
  "MT-55": {
    "label": "Siggiewi",
    "value": "MT-55",
    "parent": "MT"
  },
  "MT-56": {
    "label": "Sliema",
    "value": "MT-56",
    "parent": "MT"
  },
  "MT-57": {
    "label": "Swieqi",
    "value": "MT-57",
    "parent": "MT"
  },
  "MT-58": {
    "label": "Ta' Xbiex",
    "value": "MT-58",
    "parent": "MT"
  },
  "MT-59": {
    "label": "Tarxien",
    "value": "MT-59",
    "parent": "MT"
  },
  "MT-60": {
    "label": "Valletta",
    "value": "MT-60",
    "parent": "MT"
  },
  "MT-61": {
    "label": "Xaghra",
    "value": "MT-61",
    "parent": "MT"
  },
  "MT-62": {
    "label": "Xewkija",
    "value": "MT-62",
    "parent": "MT"
  },
  "MT-63": {
    "label": "Xghajra",
    "value": "MT-63",
    "parent": "MT"
  },
  "MT-64": {
    "label": "Zabbar",
    "value": "MT-64",
    "parent": "MT"
  },
  "MT-65": {
    "label": "Zebbug Gozo",
    "value": "MT-65",
    "parent": "MT"
  },
  "MT-67": {
    "label": "Zejtun",
    "value": "MT-67",
    "parent": "MT"
  },
  "MT-68": {
    "label": "Zurrieq",
    "value": "MT-68",
    "parent": "MT"
  },
  "MH": {
    "label": "Marshall Islands",
    "value": "MH"
  },
  "MH-KWA": {
    "label": "Kwajalein",
    "value": "MH-KWA",
    "parent": "MH"
  },
  "MH-MAJ": {
    "label": "Majuro",
    "value": "MH-MAJ",
    "parent": "MH"
  },
  "MQ": {
    "label": "Martinique",
    "value": "MQ"
  },
  "MR": {
    "label": "Mauritania",
    "value": "MR"
  },
  "MR-07": {
    "label": "Adrar",
    "value": "MR-07",
    "parent": "MR"
  },
  "MR-03": {
    "label": "Assaba",
    "value": "MR-03",
    "parent": "MR"
  },
  "MR-05": {
    "label": "Brakna",
    "value": "MR-05",
    "parent": "MR"
  },
  "MR-08": {
    "label": "Dakhlet Nouadhibou",
    "value": "MR-08",
    "parent": "MR"
  },
  "MR-04": {
    "label": "Gorgol",
    "value": "MR-04",
    "parent": "MR"
  },
  "MR-10": {
    "label": "Guidimaka",
    "value": "MR-10",
    "parent": "MR"
  },
  "MR-02": {
    "label": "Hodh el Gharbi",
    "value": "MR-02",
    "parent": "MR"
  },
  "MR-12": {
    "label": "Inchiri",
    "value": "MR-12",
    "parent": "MR"
  },
  "MR-13": {
    "label": "Nouakchott Ouest",
    "value": "MR-13",
    "parent": "MR"
  },
  "MR-09": {
    "label": "Tagant",
    "value": "MR-09",
    "parent": "MR"
  },
  "MR-11": {
    "label": "Tiris Zemmour",
    "value": "MR-11",
    "parent": "MR"
  },
  "MR-06": {
    "label": "Trarza",
    "value": "MR-06",
    "parent": "MR"
  },
  "MU": {
    "label": "Mauritius",
    "value": "MU"
  },
  "MU-BL": {
    "label": "Black River",
    "value": "MU-BL",
    "parent": "MU"
  },
  "MU-FL": {
    "label": "Flacq",
    "value": "MU-FL",
    "parent": "MU"
  },
  "MU-GP": {
    "label": "Grand Port",
    "value": "MU-GP",
    "parent": "MU"
  },
  "MU-MO": {
    "label": "Moka",
    "value": "MU-MO",
    "parent": "MU"
  },
  "MU-PA": {
    "label": "Pamplemousses",
    "value": "MU-PA",
    "parent": "MU"
  },
  "MU-PW": {
    "label": "Plaines Wilhems",
    "value": "MU-PW",
    "parent": "MU"
  },
  "MU-PL": {
    "label": "Port Louis",
    "value": "MU-PL",
    "parent": "MU"
  },
  "MU-RR": {
    "label": "Riviere du Rempart",
    "value": "MU-RR",
    "parent": "MU"
  },
  "MU-RO": {
    "label": "Rodrigues Islands",
    "value": "MU-RO",
    "parent": "MU"
  },
  "MU-SA": {
    "label": "Savanne",
    "value": "MU-SA",
    "parent": "MU"
  },
  "YT": {
    "label": "Mayotte",
    "value": "YT"
  },
  "MX": {
    "label": "Mexico",
    "value": "MX"
  },
  "MX-AGU": {
    "label": "Aguascalientes",
    "value": "MX-AGU",
    "parent": "MX"
  },
  "MX-BCN": {
    "label": "Baja California",
    "value": "MX-BCN",
    "parent": "MX"
  },
  "MX-BCS": {
    "label": "Baja California Sur",
    "value": "MX-BCS",
    "parent": "MX"
  },
  "MX-CAM": {
    "label": "Campeche",
    "value": "MX-CAM",
    "parent": "MX"
  },
  "MX-CHP": {
    "label": "Chiapas",
    "value": "MX-CHP",
    "parent": "MX"
  },
  "MX-CHH": {
    "label": "Chihuahua",
    "value": "MX-CHH",
    "parent": "MX"
  },
  "MX-CMX": {
    "label": "Ciudad de Mexico",
    "value": "MX-CMX",
    "parent": "MX"
  },
  "MX-COA": {
    "label": "Coahuila de Zaragoza",
    "value": "MX-COA",
    "parent": "MX"
  },
  "MX-COL": {
    "label": "Colima",
    "value": "MX-COL",
    "parent": "MX"
  },
  "MX-DUR": {
    "label": "Durango",
    "value": "MX-DUR",
    "parent": "MX"
  },
  "MX-GUA": {
    "label": "Guanajuato",
    "value": "MX-GUA",
    "parent": "MX"
  },
  "MX-GRO": {
    "label": "Guerrero",
    "value": "MX-GRO",
    "parent": "MX"
  },
  "MX-HID": {
    "label": "Hidalgo",
    "value": "MX-HID",
    "parent": "MX"
  },
  "MX-JAL": {
    "label": "Jalisco",
    "value": "MX-JAL",
    "parent": "MX"
  },
  "MX-MEX": {
    "label": "Mexico",
    "value": "MX-MEX",
    "parent": "MX"
  },
  "MX-MIC": {
    "label": "Michoacan de Ocampo",
    "value": "MX-MIC",
    "parent": "MX"
  },
  "MX-MOR": {
    "label": "Morelos",
    "value": "MX-MOR",
    "parent": "MX"
  },
  "MX-NAY": {
    "label": "Nayarit",
    "value": "MX-NAY",
    "parent": "MX"
  },
  "MX-NLE": {
    "label": "Nuevo Leon",
    "value": "MX-NLE",
    "parent": "MX"
  },
  "MX-OAX": {
    "label": "Oaxaca",
    "value": "MX-OAX",
    "parent": "MX"
  },
  "MX-PUE": {
    "label": "Puebla",
    "value": "MX-PUE",
    "parent": "MX"
  },
  "MX-QUE": {
    "label": "Queretaro",
    "value": "MX-QUE",
    "parent": "MX"
  },
  "MX-ROO": {
    "label": "Quintana Roo",
    "value": "MX-ROO",
    "parent": "MX"
  },
  "MX-SLP": {
    "label": "San Luis Potosi",
    "value": "MX-SLP",
    "parent": "MX"
  },
  "MX-SIN": {
    "label": "Sinaloa",
    "value": "MX-SIN",
    "parent": "MX"
  },
  "MX-SON": {
    "label": "Sonora",
    "value": "MX-SON",
    "parent": "MX"
  },
  "MX-TAB": {
    "label": "Tabasco",
    "value": "MX-TAB",
    "parent": "MX"
  },
  "MX-TAM": {
    "label": "Tamaulipas",
    "value": "MX-TAM",
    "parent": "MX"
  },
  "MX-TLA": {
    "label": "Tlaxcala",
    "value": "MX-TLA",
    "parent": "MX"
  },
  "MX-VER": {
    "label": "Veracruz de Ignacio de la Llave",
    "value": "MX-VER",
    "parent": "MX"
  },
  "MX-YUC": {
    "label": "Yucatan",
    "value": "MX-YUC",
    "parent": "MX"
  },
  "MX-ZAC": {
    "label": "Zacatecas",
    "value": "MX-ZAC",
    "parent": "MX"
  },
  "FM": {
    "label": "Micronesia, Federated States of",
    "value": "FM"
  },
  "FM-TRK": {
    "label": "Chuuk",
    "value": "FM-TRK",
    "parent": "FM"
  },
  "FM-KSA": {
    "label": "Kosrae",
    "value": "FM-KSA",
    "parent": "FM"
  },
  "FM-PNI": {
    "label": "Pohnpei",
    "value": "FM-PNI",
    "parent": "FM"
  },
  "FM-YAP": {
    "label": "Yap",
    "value": "FM-YAP",
    "parent": "FM"
  },
  "MD": {
    "label": "Moldova, Republic of",
    "value": "MD"
  },
  "MD-AN": {
    "label": "Anenii Noi",
    "value": "MD-AN",
    "parent": "MD"
  },
  "MD-BA": {
    "label": "Balti",
    "value": "MD-BA",
    "parent": "MD"
  },
  "MD-BS": {
    "label": "Basarabeasca",
    "value": "MD-BS",
    "parent": "MD"
  },
  "MD-BD": {
    "label": "Bender",
    "value": "MD-BD",
    "parent": "MD"
  },
  "MD-BR": {
    "label": "Briceni",
    "value": "MD-BR",
    "parent": "MD"
  },
  "MD-CA": {
    "label": "Cahul",
    "value": "MD-CA",
    "parent": "MD"
  },
  "MD-CL": {
    "label": "Calarasi",
    "value": "MD-CL",
    "parent": "MD"
  },
  "MD-CT": {
    "label": "Cantemir",
    "value": "MD-CT",
    "parent": "MD"
  },
  "MD-CS": {
    "label": "Causeni",
    "value": "MD-CS",
    "parent": "MD"
  },
  "MD-CU": {
    "label": "Chisinau",
    "value": "MD-CU",
    "parent": "MD"
  },
  "MD-CM": {
    "label": "Cimislia",
    "value": "MD-CM",
    "parent": "MD"
  },
  "MD-CR": {
    "label": "Criuleni",
    "value": "MD-CR",
    "parent": "MD"
  },
  "MD-DO": {
    "label": "Donduseni",
    "value": "MD-DO",
    "parent": "MD"
  },
  "MD-DR": {
    "label": "Drochia",
    "value": "MD-DR",
    "parent": "MD"
  },
  "MD-DU": {
    "label": "Dubasari",
    "value": "MD-DU",
    "parent": "MD"
  },
  "MD-ED": {
    "label": "Edinet",
    "value": "MD-ED",
    "parent": "MD"
  },
  "MD-FA": {
    "label": "Falesti",
    "value": "MD-FA",
    "parent": "MD"
  },
  "MD-FL": {
    "label": "Floresti",
    "value": "MD-FL",
    "parent": "MD"
  },
  "MD-GA": {
    "label": "Gagauzia, Unitatea teritoriala autonoma",
    "value": "MD-GA",
    "parent": "MD"
  },
  "MD-GL": {
    "label": "Glodeni",
    "value": "MD-GL",
    "parent": "MD"
  },
  "MD-HI": {
    "label": "Hincesti",
    "value": "MD-HI",
    "parent": "MD"
  },
  "MD-IA": {
    "label": "Ialoveni",
    "value": "MD-IA",
    "parent": "MD"
  },
  "MD-LE": {
    "label": "Leova",
    "value": "MD-LE",
    "parent": "MD"
  },
  "MD-NI": {
    "label": "Nisporeni",
    "value": "MD-NI",
    "parent": "MD"
  },
  "MD-OC": {
    "label": "Ocnita",
    "value": "MD-OC",
    "parent": "MD"
  },
  "MD-OR": {
    "label": "Orhei",
    "value": "MD-OR",
    "parent": "MD"
  },
  "MD-RE": {
    "label": "Rezina",
    "value": "MD-RE",
    "parent": "MD"
  },
  "MD-RI": {
    "label": "Riscani",
    "value": "MD-RI",
    "parent": "MD"
  },
  "MD-SI": {
    "label": "Singerei",
    "value": "MD-SI",
    "parent": "MD"
  },
  "MD-SD": {
    "label": "Soldanesti",
    "value": "MD-SD",
    "parent": "MD"
  },
  "MD-SO": {
    "label": "Soroca",
    "value": "MD-SO",
    "parent": "MD"
  },
  "MD-SV": {
    "label": "Stefan Voda",
    "value": "MD-SV",
    "parent": "MD"
  },
  "MD-SN": {
    "label": "Stinga Nistrului, unitatea teritoriala din",
    "value": "MD-SN",
    "parent": "MD"
  },
  "MD-ST": {
    "label": "Straseni",
    "value": "MD-ST",
    "parent": "MD"
  },
  "MD-TA": {
    "label": "Taraclia",
    "value": "MD-TA",
    "parent": "MD"
  },
  "MD-TE": {
    "label": "Telenesti",
    "value": "MD-TE",
    "parent": "MD"
  },
  "MD-UN": {
    "label": "Ungheni",
    "value": "MD-UN",
    "parent": "MD"
  },
  "MC": {
    "label": "Monaco",
    "value": "MC"
  },
  "MC-FO": {
    "label": "Fontvieille",
    "value": "MC-FO",
    "parent": "MC"
  },
  "MC-CO": {
    "label": "La Condamine",
    "value": "MC-CO",
    "parent": "MC"
  },
  "MC-MO": {
    "label": "Monaco-Ville",
    "value": "MC-MO",
    "parent": "MC"
  },
  "MC-MG": {
    "label": "Moneghetti",
    "value": "MC-MG",
    "parent": "MC"
  },
  "MC-MC": {
    "label": "Monte-Carlo",
    "value": "MC-MC",
    "parent": "MC"
  },
  "MC-SR": {
    "label": "Saint-Roman",
    "value": "MC-SR",
    "parent": "MC"
  },
  "MN": {
    "label": "Mongolia",
    "value": "MN"
  },
  "MN-071": {
    "label": "Bayan-Olgiy",
    "value": "MN-071",
    "parent": "MN"
  },
  "MN-069": {
    "label": "Bayanhongor",
    "value": "MN-069",
    "parent": "MN"
  },
  "MN-037": {
    "label": "Darhan uul",
    "value": "MN-037",
    "parent": "MN"
  },
  "MN-061": {
    "label": "Dornod",
    "value": "MN-061",
    "parent": "MN"
  },
  "MN-059": {
    "label": "Dundgovi",
    "value": "MN-059",
    "parent": "MN"
  },
  "MN-057": {
    "label": "Dzavhan",
    "value": "MN-057",
    "parent": "MN"
  },
  "MN-065": {
    "label": "Govi-Altay",
    "value": "MN-065",
    "parent": "MN"
  },
  "MN-043": {
    "label": "Hovd",
    "value": "MN-043",
    "parent": "MN"
  },
  "MN-041": {
    "label": "Hovsgol",
    "value": "MN-041",
    "parent": "MN"
  },
  "MN-053": {
    "label": "Omnogovi",
    "value": "MN-053",
    "parent": "MN"
  },
  "MN-035": {
    "label": "Orhon",
    "value": "MN-035",
    "parent": "MN"
  },
  "MN-055": {
    "label": "Ovorhangay",
    "value": "MN-055",
    "parent": "MN"
  },
  "MN-049": {
    "label": "Selenge",
    "value": "MN-049",
    "parent": "MN"
  },
  "MN-047": {
    "label": "Tov",
    "value": "MN-047",
    "parent": "MN"
  },
  "MN-1": {
    "label": "Ulaanbaatar",
    "value": "MN-1",
    "parent": "MN"
  },
  "ME": {
    "label": "Montenegro",
    "value": "ME"
  },
  "ME-02": {
    "label": "Bar",
    "value": "ME-02",
    "parent": "ME"
  },
  "ME-03": {
    "label": "Berane",
    "value": "ME-03",
    "parent": "ME"
  },
  "ME-04": {
    "label": "Bijelo Polje",
    "value": "ME-04",
    "parent": "ME"
  },
  "ME-05": {
    "label": "Budva",
    "value": "ME-05",
    "parent": "ME"
  },
  "ME-06": {
    "label": "Cetinje",
    "value": "ME-06",
    "parent": "ME"
  },
  "ME-07": {
    "label": "Danilovgrad",
    "value": "ME-07",
    "parent": "ME"
  },
  "ME-08": {
    "label": "Herceg-Novi",
    "value": "ME-08",
    "parent": "ME"
  },
  "ME-09": {
    "label": "Kolasin",
    "value": "ME-09",
    "parent": "ME"
  },
  "ME-10": {
    "label": "Kotor",
    "value": "ME-10",
    "parent": "ME"
  },
  "ME-12": {
    "label": "Niksic",
    "value": "ME-12",
    "parent": "ME"
  },
  "ME-13": {
    "label": "Plav",
    "value": "ME-13",
    "parent": "ME"
  },
  "ME-14": {
    "label": "Pljevlja",
    "value": "ME-14",
    "parent": "ME"
  },
  "ME-15": {
    "label": "Pluzine",
    "value": "ME-15",
    "parent": "ME"
  },
  "ME-16": {
    "label": "Podgorica",
    "value": "ME-16",
    "parent": "ME"
  },
  "ME-17": {
    "label": "Rozaje",
    "value": "ME-17",
    "parent": "ME"
  },
  "ME-19": {
    "label": "Tivat",
    "value": "ME-19",
    "parent": "ME"
  },
  "ME-24": {
    "label": "Tuzi",
    "value": "ME-24",
    "parent": "ME"
  },
  "ME-20": {
    "label": "Ulcinj",
    "value": "ME-20",
    "parent": "ME"
  },
  "ME-21": {
    "label": "Zabljak",
    "value": "ME-21",
    "parent": "ME"
  },
  "ME-25": {
    "label": "Zeta",
    "value": "ME-25",
    "parent": "ME"
  },
  "MS": {
    "label": "Montserrat",
    "value": "MS"
  },
  "MA": {
    "label": "Morocco",
    "value": "MA"
  },
  "MA-05": {
    "label": "Beni-Mellal-Khenifra",
    "value": "MA-05",
    "parent": "MA"
  },
  "MA-06": {
    "label": "Casablanca-Settat",
    "value": "MA-06",
    "parent": "MA"
  },
  "MA-08": {
    "label": "Draa-Tafilalet",
    "value": "MA-08",
    "parent": "MA"
  },
  "MA-03": {
    "label": "Fes- Meknes",
    "value": "MA-03",
    "parent": "MA"
  },
  "MA-10": {
    "label": "Guelmim-Oued Noun (EH-partial)",
    "value": "MA-10",
    "parent": "MA"
  },
  "MA-02": {
    "label": "L'Oriental",
    "value": "MA-02",
    "parent": "MA"
  },
  "MA-11": {
    "label": "Laayoune-Sakia El Hamra (EH-partial)",
    "value": "MA-11",
    "parent": "MA"
  },
  "MA-07": {
    "label": "Marrakech-Safi",
    "value": "MA-07",
    "parent": "MA"
  },
  "MA-04": {
    "label": "Rabat-Sale-Kenitra",
    "value": "MA-04",
    "parent": "MA"
  },
  "MA-09": {
    "label": "Souss-Massa",
    "value": "MA-09",
    "parent": "MA"
  },
  "MA-01": {
    "label": "Tanger-Tetouan-Al Hoceima",
    "value": "MA-01",
    "parent": "MA"
  },
  "MZ": {
    "label": "Mozambique",
    "value": "MZ"
  },
  "MZ-P": {
    "label": "Cabo Delgado",
    "value": "MZ-P",
    "parent": "MZ"
  },
  "MZ-G": {
    "label": "Gaza",
    "value": "MZ-G",
    "parent": "MZ"
  },
  "MZ-I": {
    "label": "Inhambane",
    "value": "MZ-I",
    "parent": "MZ"
  },
  "MZ-B": {
    "label": "Manica",
    "value": "MZ-B",
    "parent": "MZ"
  },
  "MZ-L": {
    "label": "Maputo",
    "value": "MZ-L",
    "parent": "MZ"
  },
  "MZ-N": {
    "label": "Nampula",
    "value": "MZ-N",
    "parent": "MZ"
  },
  "MZ-A": {
    "label": "Niassa",
    "value": "MZ-A",
    "parent": "MZ"
  },
  "MZ-S": {
    "label": "Sofala",
    "value": "MZ-S",
    "parent": "MZ"
  },
  "MZ-T": {
    "label": "Tete",
    "value": "MZ-T",
    "parent": "MZ"
  },
  "MZ-Q": {
    "label": "Zambezia",
    "value": "MZ-Q",
    "parent": "MZ"
  },
  "MM": {
    "label": "Myanmar",
    "value": "MM"
  },
  "MM-07": {
    "label": "Ayeyarwady",
    "value": "MM-07",
    "parent": "MM"
  },
  "MM-02": {
    "label": "Bago",
    "value": "MM-02",
    "parent": "MM"
  },
  "MM-14": {
    "label": "Chin",
    "value": "MM-14",
    "parent": "MM"
  },
  "MM-11": {
    "label": "Kachin",
    "value": "MM-11",
    "parent": "MM"
  },
  "MM-12": {
    "label": "Kayah",
    "value": "MM-12",
    "parent": "MM"
  },
  "MM-13": {
    "label": "Kayin",
    "value": "MM-13",
    "parent": "MM"
  },
  "MM-03": {
    "label": "Magway",
    "value": "MM-03",
    "parent": "MM"
  },
  "MM-04": {
    "label": "Mandalay",
    "value": "MM-04",
    "parent": "MM"
  },
  "MM-15": {
    "label": "Mon",
    "value": "MM-15",
    "parent": "MM"
  },
  "MM-18": {
    "label": "Nay Pyi Taw",
    "value": "MM-18",
    "parent": "MM"
  },
  "MM-16": {
    "label": "Rakhine",
    "value": "MM-16",
    "parent": "MM"
  },
  "MM-01": {
    "label": "Sagaing",
    "value": "MM-01",
    "parent": "MM"
  },
  "MM-17": {
    "label": "Shan",
    "value": "MM-17",
    "parent": "MM"
  },
  "MM-05": {
    "label": "Tanintharyi",
    "value": "MM-05",
    "parent": "MM"
  },
  "MM-06": {
    "label": "Yangon",
    "value": "MM-06",
    "parent": "MM"
  },
  "NA": {
    "label": "Namibia",
    "value": "NA"
  },
  "NA-ER": {
    "label": "Erongo",
    "value": "NA-ER",
    "parent": "NA"
  },
  "NA-HA": {
    "label": "Hardap",
    "value": "NA-HA",
    "parent": "NA"
  },
  "NA-KA": {
    "label": "Karas",
    "value": "NA-KA",
    "parent": "NA"
  },
  "NA-KE": {
    "label": "Kavango East",
    "value": "NA-KE",
    "parent": "NA"
  },
  "NA-KW": {
    "label": "Kavango West",
    "value": "NA-KW",
    "parent": "NA"
  },
  "NA-KH": {
    "label": "Khomas",
    "value": "NA-KH",
    "parent": "NA"
  },
  "NA-KU": {
    "label": "Kunene",
    "value": "NA-KU",
    "parent": "NA"
  },
  "NA-OW": {
    "label": "Ohangwena",
    "value": "NA-OW",
    "parent": "NA"
  },
  "NA-OH": {
    "label": "Omaheke",
    "value": "NA-OH",
    "parent": "NA"
  },
  "NA-OS": {
    "label": "Omusati",
    "value": "NA-OS",
    "parent": "NA"
  },
  "NA-ON": {
    "label": "Oshana",
    "value": "NA-ON",
    "parent": "NA"
  },
  "NA-OT": {
    "label": "Oshikoto",
    "value": "NA-OT",
    "parent": "NA"
  },
  "NA-OD": {
    "label": "Otjozondjupa",
    "value": "NA-OD",
    "parent": "NA"
  },
  "NA-CA": {
    "label": "Zambezi",
    "value": "NA-CA",
    "parent": "NA"
  },
  "NR": {
    "label": "Nauru",
    "value": "NR"
  },
  "NR-01": {
    "label": "Aiwo",
    "value": "NR-01",
    "parent": "NR"
  },
  "NR-03": {
    "label": "Anetan",
    "value": "NR-03",
    "parent": "NR"
  },
  "NR-14": {
    "label": "Yaren",
    "value": "NR-14",
    "parent": "NR"
  },
  "NP": {
    "label": "Nepal",
    "value": "NP"
  },
  "NP-P3": {
    "label": "Bagmati",
    "value": "NP-P3",
    "parent": "NP"
  },
  "NP-P4": {
    "label": "Gandaki",
    "value": "NP-P4",
    "parent": "NP"
  },
  "NP-P6": {
    "label": "Karnali",
    "value": "NP-P6",
    "parent": "NP"
  },
  "NP-P1": {
    "label": "Koshi",
    "value": "NP-P1",
    "parent": "NP"
  },
  "NP-P5": {
    "label": "Lumbini",
    "value": "NP-P5",
    "parent": "NP"
  },
  "NP-P2": {
    "label": "Madhesh",
    "value": "NP-P2",
    "parent": "NP"
  },
  "NP-P7": {
    "label": "Sudurpashchim",
    "value": "NP-P7",
    "parent": "NP"
  },
  "NL": {
    "label": "Netherlands, Kingdom of the",
    "value": "NL"
  },
  "NL-DR": {
    "label": "Drenthe",
    "value": "NL-DR",
    "parent": "NL"
  },
  "NL-FL": {
    "label": "Flevoland",
    "value": "NL-FL",
    "parent": "NL"
  },
  "NL-FR": {
    "label": "Fryslan",
    "value": "NL-FR",
    "parent": "NL"
  },
  "NL-GE": {
    "label": "Gelderland",
    "value": "NL-GE",
    "parent": "NL"
  },
  "NL-GR": {
    "label": "Groningen",
    "value": "NL-GR",
    "parent": "NL"
  },
  "NL-LI": {
    "label": "Limburg",
    "value": "NL-LI",
    "parent": "NL"
  },
  "NL-NB": {
    "label": "Noord-Brabant",
    "value": "NL-NB",
    "parent": "NL"
  },
  "NL-NH": {
    "label": "Noord-Holland",
    "value": "NL-NH",
    "parent": "NL"
  },
  "NL-OV": {
    "label": "Overijssel",
    "value": "NL-OV",
    "parent": "NL"
  },
  "NL-UT": {
    "label": "Utrecht",
    "value": "NL-UT",
    "parent": "NL"
  },
  "NL-ZE": {
    "label": "Zeeland",
    "value": "NL-ZE",
    "parent": "NL"
  },
  "NL-ZH": {
    "label": "Zuid-Holland",
    "value": "NL-ZH",
    "parent": "NL"
  },
  "NC": {
    "label": "New Caledonia",
    "value": "NC"
  },
  "NZ": {
    "label": "New Zealand",
    "value": "NZ"
  },
  "NZ-AUK": {
    "label": "Auckland",
    "value": "NZ-AUK",
    "parent": "NZ"
  },
  "NZ-BOP": {
    "label": "Bay of Plenty",
    "value": "NZ-BOP",
    "parent": "NZ"
  },
  "NZ-CAN": {
    "label": "Canterbury",
    "value": "NZ-CAN",
    "parent": "NZ"
  },
  "NZ-CIT": {
    "label": "Chatham Islands Territory",
    "value": "NZ-CIT",
    "parent": "NZ"
  },
  "NZ-GIS": {
    "label": "Gisborne",
    "value": "NZ-GIS",
    "parent": "NZ"
  },
  "NZ-WGN": {
    "label": "Greater Wellington",
    "value": "NZ-WGN",
    "parent": "NZ"
  },
  "NZ-HKB": {
    "label": "Hawke's Bay",
    "value": "NZ-HKB",
    "parent": "NZ"
  },
  "NZ-MWT": {
    "label": "Manawatu-Whanganui",
    "value": "NZ-MWT",
    "parent": "NZ"
  },
  "NZ-MBH": {
    "label": "Marlborough",
    "value": "NZ-MBH",
    "parent": "NZ"
  },
  "NZ-NSN": {
    "label": "Nelson",
    "value": "NZ-NSN",
    "parent": "NZ"
  },
  "NZ-NTL": {
    "label": "Northland",
    "value": "NZ-NTL",
    "parent": "NZ"
  },
  "NZ-OTA": {
    "label": "Otago",
    "value": "NZ-OTA",
    "parent": "NZ"
  },
  "NZ-STL": {
    "label": "Southland",
    "value": "NZ-STL",
    "parent": "NZ"
  },
  "NZ-TKI": {
    "label": "Taranaki",
    "value": "NZ-TKI",
    "parent": "NZ"
  },
  "NZ-TAS": {
    "label": "Tasman",
    "value": "NZ-TAS",
    "parent": "NZ"
  },
  "NZ-WKO": {
    "label": "Waikato",
    "value": "NZ-WKO",
    "parent": "NZ"
  },
  "NZ-WTC": {
    "label": "West Coast",
    "value": "NZ-WTC",
    "parent": "NZ"
  },
  "NI": {
    "label": "Nicaragua",
    "value": "NI"
  },
  "NI-BO": {
    "label": "Boaco",
    "value": "NI-BO",
    "parent": "NI"
  },
  "NI-CA": {
    "label": "Carazo",
    "value": "NI-CA",
    "parent": "NI"
  },
  "NI-CI": {
    "label": "Chinandega",
    "value": "NI-CI",
    "parent": "NI"
  },
  "NI-CO": {
    "label": "Chontales",
    "value": "NI-CO",
    "parent": "NI"
  },
  "NI-AN": {
    "label": "Costa Caribe Norte",
    "value": "NI-AN",
    "parent": "NI"
  },
  "NI-AS": {
    "label": "Costa Caribe Sur",
    "value": "NI-AS",
    "parent": "NI"
  },
  "NI-ES": {
    "label": "Esteli",
    "value": "NI-ES",
    "parent": "NI"
  },
  "NI-GR": {
    "label": "Granada",
    "value": "NI-GR",
    "parent": "NI"
  },
  "NI-JI": {
    "label": "Jinotega",
    "value": "NI-JI",
    "parent": "NI"
  },
  "NI-LE": {
    "label": "Leon",
    "value": "NI-LE",
    "parent": "NI"
  },
  "NI-MD": {
    "label": "Madriz",
    "value": "NI-MD",
    "parent": "NI"
  },
  "NI-MN": {
    "label": "Managua",
    "value": "NI-MN",
    "parent": "NI"
  },
  "NI-MS": {
    "label": "Masaya",
    "value": "NI-MS",
    "parent": "NI"
  },
  "NI-MT": {
    "label": "Matagalpa",
    "value": "NI-MT",
    "parent": "NI"
  },
  "NI-NS": {
    "label": "Nueva Segovia",
    "value": "NI-NS",
    "parent": "NI"
  },
  "NI-SJ": {
    "label": "Rio San Juan",
    "value": "NI-SJ",
    "parent": "NI"
  },
  "NI-RI": {
    "label": "Rivas",
    "value": "NI-RI",
    "parent": "NI"
  },
  "NE": {
    "label": "Niger",
    "value": "NE"
  },
  "NE-1": {
    "label": "Agadez",
    "value": "NE-1",
    "parent": "NE"
  },
  "NE-2": {
    "label": "Diffa",
    "value": "NE-2",
    "parent": "NE"
  },
  "NE-3": {
    "label": "Dosso",
    "value": "NE-3",
    "parent": "NE"
  },
  "NE-4": {
    "label": "Maradi",
    "value": "NE-4",
    "parent": "NE"
  },
  "NE-8": {
    "label": "Niamey",
    "value": "NE-8",
    "parent": "NE"
  },
  "NE-5": {
    "label": "Tahoua",
    "value": "NE-5",
    "parent": "NE"
  },
  "NE-6": {
    "label": "Tillaberi",
    "value": "NE-6",
    "parent": "NE"
  },
  "NE-7": {
    "label": "Zinder",
    "value": "NE-7",
    "parent": "NE"
  },
  "NG": {
    "label": "Nigeria",
    "value": "NG"
  },
  "NG-AB": {
    "label": "Abia",
    "value": "NG-AB",
    "parent": "NG"
  },
  "NG-FC": {
    "label": "Abuja Federal Capital Territory",
    "value": "NG-FC",
    "parent": "NG"
  },
  "NG-AD": {
    "label": "Adamawa",
    "value": "NG-AD",
    "parent": "NG"
  },
  "NG-AK": {
    "label": "Akwa Ibom",
    "value": "NG-AK",
    "parent": "NG"
  },
  "NG-AN": {
    "label": "Anambra",
    "value": "NG-AN",
    "parent": "NG"
  },
  "NG-BA": {
    "label": "Bauchi",
    "value": "NG-BA",
    "parent": "NG"
  },
  "NG-BY": {
    "label": "Bayelsa",
    "value": "NG-BY",
    "parent": "NG"
  },
  "NG-BE": {
    "label": "Benue",
    "value": "NG-BE",
    "parent": "NG"
  },
  "NG-BO": {
    "label": "Borno",
    "value": "NG-BO",
    "parent": "NG"
  },
  "NG-CR": {
    "label": "Cross River",
    "value": "NG-CR",
    "parent": "NG"
  },
  "NG-DE": {
    "label": "Delta",
    "value": "NG-DE",
    "parent": "NG"
  },
  "NG-ED": {
    "label": "Edo",
    "value": "NG-ED",
    "parent": "NG"
  },
  "NG-EK": {
    "label": "Ekiti",
    "value": "NG-EK",
    "parent": "NG"
  },
  "NG-EN": {
    "label": "Enugu",
    "value": "NG-EN",
    "parent": "NG"
  },
  "NG-GO": {
    "label": "Gombe",
    "value": "NG-GO",
    "parent": "NG"
  },
  "NG-IM": {
    "label": "Imo",
    "value": "NG-IM",
    "parent": "NG"
  },
  "NG-JI": {
    "label": "Jigawa",
    "value": "NG-JI",
    "parent": "NG"
  },
  "NG-KD": {
    "label": "Kaduna",
    "value": "NG-KD",
    "parent": "NG"
  },
  "NG-KN": {
    "label": "Kano",
    "value": "NG-KN",
    "parent": "NG"
  },
  "NG-KT": {
    "label": "Katsina",
    "value": "NG-KT",
    "parent": "NG"
  },
  "NG-KE": {
    "label": "Kebbi",
    "value": "NG-KE",
    "parent": "NG"
  },
  "NG-KO": {
    "label": "Kogi",
    "value": "NG-KO",
    "parent": "NG"
  },
  "NG-KW": {
    "label": "Kwara",
    "value": "NG-KW",
    "parent": "NG"
  },
  "NG-LA": {
    "label": "Lagos",
    "value": "NG-LA",
    "parent": "NG"
  },
  "NG-NA": {
    "label": "Nasarawa",
    "value": "NG-NA",
    "parent": "NG"
  },
  "NG-NI": {
    "label": "Niger",
    "value": "NG-NI",
    "parent": "NG"
  },
  "NG-OG": {
    "label": "Ogun",
    "value": "NG-OG",
    "parent": "NG"
  },
  "NG-ON": {
    "label": "Ondo",
    "value": "NG-ON",
    "parent": "NG"
  },
  "NG-OS": {
    "label": "Osun",
    "value": "NG-OS",
    "parent": "NG"
  },
  "NG-OY": {
    "label": "Oyo",
    "value": "NG-OY",
    "parent": "NG"
  },
  "NG-PL": {
    "label": "Plateau",
    "value": "NG-PL",
    "parent": "NG"
  },
  "NG-RI": {
    "label": "Rivers",
    "value": "NG-RI",
    "parent": "NG"
  },
  "NG-SO": {
    "label": "Sokoto",
    "value": "NG-SO",
    "parent": "NG"
  },
  "NG-TA": {
    "label": "Taraba",
    "value": "NG-TA",
    "parent": "NG"
  },
  "NG-YO": {
    "label": "Yobe",
    "value": "NG-YO",
    "parent": "NG"
  },
  "NG-ZA": {
    "label": "Zamfara",
    "value": "NG-ZA",
    "parent": "NG"
  },
  "NU": {
    "label": "Niue",
    "value": "NU"
  },
  "NF": {
    "label": "Norfolk Island",
    "value": "NF"
  },
  "MK": {
    "label": "North Macedonia",
    "value": "MK"
  },
  "MK-201": {
    "label": "Berovo",
    "value": "MK-201",
    "parent": "MK"
  },
  "MK-501": {
    "label": "Bitola",
    "value": "MK-501",
    "parent": "MK"
  },
  "MK-401": {
    "label": "Bogdanci",
    "value": "MK-401",
    "parent": "MK"
  },
  "MK-601": {
    "label": "Bogovinje",
    "value": "MK-601",
    "parent": "MK"
  },
  "MK-402": {
    "label": "Bosilovo",
    "value": "MK-402",
    "parent": "MK"
  },
  "MK-602": {
    "label": "Brvenica",
    "value": "MK-602",
    "parent": "MK"
  },
  "MK-803": {
    "label": "Butel",
    "value": "MK-803",
    "parent": "MK"
  },
  "MK-109": {
    "label": "Caska",
    "value": "MK-109",
    "parent": "MK"
  },
  "MK-814": {
    "label": "Centar",
    "value": "MK-814",
    "parent": "MK"
  },
  "MK-313": {
    "label": "Centar Zupa",
    "value": "MK-313",
    "parent": "MK"
  },
  "MK-816": {
    "label": "Cucer Sandevo",
    "value": "MK-816",
    "parent": "MK"
  },
  "MK-303": {
    "label": "Debar",
    "value": "MK-303",
    "parent": "MK"
  },
  "MK-203": {
    "label": "Delcevo",
    "value": "MK-203",
    "parent": "MK"
  },
  "MK-502": {
    "label": "Demir Hisar",
    "value": "MK-502",
    "parent": "MK"
  },
  "MK-406": {
    "label": "Dojran",
    "value": "MK-406",
    "parent": "MK"
  },
  "MK-503": {
    "label": "Dolneni",
    "value": "MK-503",
    "parent": "MK"
  },
  "MK-804": {
    "label": "Gazi Baba",
    "value": "MK-804",
    "parent": "MK"
  },
  "MK-405": {
    "label": "Gevgelija",
    "value": "MK-405",
    "parent": "MK"
  },
  "MK-604": {
    "label": "Gostivar",
    "value": "MK-604",
    "parent": "MK"
  },
  "MK-807": {
    "label": "Ilinden",
    "value": "MK-807",
    "parent": "MK"
  },
  "MK-606": {
    "label": "Jegunovce",
    "value": "MK-606",
    "parent": "MK"
  },
  "MK-205": {
    "label": "Karbinci",
    "value": "MK-205",
    "parent": "MK"
  },
  "MK-104": {
    "label": "Kavadarci",
    "value": "MK-104",
    "parent": "MK"
  },
  "MK-307": {
    "label": "Kicevo",
    "value": "MK-307",
    "parent": "MK"
  },
  "MK-809": {
    "label": "Kisela Voda",
    "value": "MK-809",
    "parent": "MK"
  },
  "MK-206": {
    "label": "Kocani",
    "value": "MK-206",
    "parent": "MK"
  },
  "MK-407": {
    "label": "Konce",
    "value": "MK-407",
    "parent": "MK"
  },
  "MK-701": {
    "label": "Kratovo",
    "value": "MK-701",
    "parent": "MK"
  },
  "MK-702": {
    "label": "Kriva Palanka",
    "value": "MK-702",
    "parent": "MK"
  },
  "MK-504": {
    "label": "Krivogastani",
    "value": "MK-504",
    "parent": "MK"
  },
  "MK-703": {
    "label": "Kumanovo",
    "value": "MK-703",
    "parent": "MK"
  },
  "MK-704": {
    "label": "Lipkovo",
    "value": "MK-704",
    "parent": "MK"
  },
  "MK-105": {
    "label": "Lozovo",
    "value": "MK-105",
    "parent": "MK"
  },
  "MK-207": {
    "label": "Makedonska Kamenica",
    "value": "MK-207",
    "parent": "MK"
  },
  "MK-308": {
    "label": "Makedonski Brod",
    "value": "MK-308",
    "parent": "MK"
  },
  "MK-607": {
    "label": "Mavrovo i Rostusa",
    "value": "MK-607",
    "parent": "MK"
  },
  "MK-506": {
    "label": "Mogila",
    "value": "MK-506",
    "parent": "MK"
  },
  "MK-106": {
    "label": "Negotino",
    "value": "MK-106",
    "parent": "MK"
  },
  "MK-507": {
    "label": "Novaci",
    "value": "MK-507",
    "parent": "MK"
  },
  "MK-408": {
    "label": "Novo Selo",
    "value": "MK-408",
    "parent": "MK"
  },
  "MK-310": {
    "label": "Ohrid",
    "value": "MK-310",
    "parent": "MK"
  },
  "MK-810": {
    "label": "Petrovec",
    "value": "MK-810",
    "parent": "MK"
  },
  "MK-311": {
    "label": "Plasnica",
    "value": "MK-311",
    "parent": "MK"
  },
  "MK-508": {
    "label": "Prilep",
    "value": "MK-508",
    "parent": "MK"
  },
  "MK-209": {
    "label": "Probistip",
    "value": "MK-209",
    "parent": "MK"
  },
  "MK-409": {
    "label": "Radovis",
    "value": "MK-409",
    "parent": "MK"
  },
  "MK-705": {
    "label": "Rankovce",
    "value": "MK-705",
    "parent": "MK"
  },
  "MK-509": {
    "label": "Resen",
    "value": "MK-509",
    "parent": "MK"
  },
  "MK-107": {
    "label": "Rosoman",
    "value": "MK-107",
    "parent": "MK"
  },
  "MK-811": {
    "label": "Saraj",
    "value": "MK-811",
    "parent": "MK"
  },
  "MK-812": {
    "label": "Sopiste",
    "value": "MK-812",
    "parent": "MK"
  },
  "MK-211": {
    "label": "Stip",
    "value": "MK-211",
    "parent": "MK"
  },
  "MK-312": {
    "label": "Struga",
    "value": "MK-312",
    "parent": "MK"
  },
  "MK-410": {
    "label": "Strumica",
    "value": "MK-410",
    "parent": "MK"
  },
  "MK-813": {
    "label": "Studenicani",
    "value": "MK-813",
    "parent": "MK"
  },
  "MK-108": {
    "label": "Sveti Nikole",
    "value": "MK-108",
    "parent": "MK"
  },
  "MK-608": {
    "label": "Tearce",
    "value": "MK-608",
    "parent": "MK"
  },
  "MK-609": {
    "label": "Tetovo",
    "value": "MK-609",
    "parent": "MK"
  },
  "MK-403": {
    "label": "Valandovo",
    "value": "MK-403",
    "parent": "MK"
  },
  "MK-404": {
    "label": "Vasilevo",
    "value": "MK-404",
    "parent": "MK"
  },
  "MK-101": {
    "label": "Veles",
    "value": "MK-101",
    "parent": "MK"
  },
  "MK-301": {
    "label": "Vevcani",
    "value": "MK-301",
    "parent": "MK"
  },
  "MK-202": {
    "label": "Vinica",
    "value": "MK-202",
    "parent": "MK"
  },
  "MK-603": {
    "label": "Vrapciste",
    "value": "MK-603",
    "parent": "MK"
  },
  "MK-806": {
    "label": "Zelenikovo",
    "value": "MK-806",
    "parent": "MK"
  },
  "MK-605": {
    "label": "Zelino",
    "value": "MK-605",
    "parent": "MK"
  },
  "MK-204": {
    "label": "Zrnovci",
    "value": "MK-204",
    "parent": "MK"
  },
  "MP": {
    "label": "Northern Mariana Islands",
    "value": "MP"
  },
  "NO": {
    "label": "Norway",
    "value": "NO"
  },
  "NO-42": {
    "label": "Agder",
    "value": "NO-42",
    "parent": "NO"
  },
  "NO-34": {
    "label": "Innlandet",
    "value": "NO-34",
    "parent": "NO"
  },
  "NO-15": {
    "label": "More og Romsdal",
    "value": "NO-15",
    "parent": "NO"
  },
  "NO-18": {
    "label": "Nordland",
    "value": "NO-18",
    "parent": "NO"
  },
  "NO-03": {
    "label": "Oslo",
    "value": "NO-03",
    "parent": "NO"
  },
  "NO-11": {
    "label": "Rogaland",
    "value": "NO-11",
    "parent": "NO"
  },
  "NO-54": {
    "label": "Troms og Finnmark",
    "value": "NO-54",
    "parent": "NO"
  },
  "NO-50": {
    "label": "Trondelag",
    "value": "NO-50",
    "parent": "NO"
  },
  "NO-38": {
    "label": "Vestfold og Telemark",
    "value": "NO-38",
    "parent": "NO"
  },
  "NO-46": {
    "label": "Vestland",
    "value": "NO-46",
    "parent": "NO"
  },
  "NO-30": {
    "label": "Viken",
    "value": "NO-30",
    "parent": "NO"
  },
  "OM": {
    "label": "Oman",
    "value": "OM"
  },
  "OM-DA": {
    "label": "Ad Dakhiliyah",
    "value": "OM-DA",
    "parent": "OM"
  },
  "OM-BU": {
    "label": "Al Buraymi",
    "value": "OM-BU",
    "parent": "OM"
  },
  "OM-WU": {
    "label": "Al Wusta",
    "value": "OM-WU",
    "parent": "OM"
  },
  "OM-ZA": {
    "label": "Az Zahirah",
    "value": "OM-ZA",
    "parent": "OM"
  },
  "OM-BJ": {
    "label": "Janub al Batinah",
    "value": "OM-BJ",
    "parent": "OM"
  },
  "OM-SJ": {
    "label": "Janub ash Sharqiyah",
    "value": "OM-SJ",
    "parent": "OM"
  },
  "OM-MA": {
    "label": "Masqat",
    "value": "OM-MA",
    "parent": "OM"
  },
  "OM-MU": {
    "label": "Musandam",
    "value": "OM-MU",
    "parent": "OM"
  },
  "OM-BS": {
    "label": "Shamal al Batinah",
    "value": "OM-BS",
    "parent": "OM"
  },
  "OM-SS": {
    "label": "Shamal ash Sharqiyah",
    "value": "OM-SS",
    "parent": "OM"
  },
  "OM-ZU": {
    "label": "Zufar",
    "value": "OM-ZU",
    "parent": "OM"
  },
  "PK": {
    "label": "Pakistan",
    "value": "PK"
  },
  "PK-JK": {
    "label": "Azad Jammu and Kashmir",
    "value": "PK-JK",
    "parent": "PK"
  },
  "PK-BA": {
    "label": "Balochistan",
    "value": "PK-BA",
    "parent": "PK"
  },
  "PK-GB": {
    "label": "Gilgit-Baltistan",
    "value": "PK-GB",
    "parent": "PK"
  },
  "PK-IS": {
    "label": "Islamabad",
    "value": "PK-IS",
    "parent": "PK"
  },
  "PK-KP": {
    "label": "Khyber Pakhtunkhwa",
    "value": "PK-KP",
    "parent": "PK"
  },
  "PK-PB": {
    "label": "Punjab",
    "value": "PK-PB",
    "parent": "PK"
  },
  "PK-SD": {
    "label": "Sindh",
    "value": "PK-SD",
    "parent": "PK"
  },
  "PW": {
    "label": "Palau",
    "value": "PW"
  },
  "PW-004": {
    "label": "Airai",
    "value": "PW-004",
    "parent": "PW"
  },
  "PW-150": {
    "label": "Koror",
    "value": "PW-150",
    "parent": "PW"
  },
  "PW-212": {
    "label": "Melekeok",
    "value": "PW-212",
    "parent": "PW"
  },
  "PW-214": {
    "label": "Ngaraard",
    "value": "PW-214",
    "parent": "PW"
  },
  "PW-222": {
    "label": "Ngardmau",
    "value": "PW-222",
    "parent": "PW"
  },
  "PW-224": {
    "label": "Ngatpang",
    "value": "PW-224",
    "parent": "PW"
  },
  "PW-370": {
    "label": "Sonsorol",
    "value": "PW-370",
    "parent": "PW"
  },
  "PS": {
    "label": "Palestine, State of",
    "value": "PS"
  },
  "PS-BTH": {
    "label": "Bethlehem",
    "value": "PS-BTH",
    "parent": "PS"
  },
  "PS-DEB": {
    "label": "Deir El Balah",
    "value": "PS-DEB",
    "parent": "PS"
  },
  "PS-GZA": {
    "label": "Gaza",
    "value": "PS-GZA",
    "parent": "PS"
  },
  "PS-HBN": {
    "label": "Hebron",
    "value": "PS-HBN",
    "parent": "PS"
  },
  "PS-JEN": {
    "label": "Jenin",
    "value": "PS-JEN",
    "parent": "PS"
  },
  "PS-JRH": {
    "label": "Jericho and Al Aghwar",
    "value": "PS-JRH",
    "parent": "PS"
  },
  "PS-JEM": {
    "label": "Jerusalem",
    "value": "PS-JEM",
    "parent": "PS"
  },
  "PS-KYS": {
    "label": "Khan Yunis",
    "value": "PS-KYS",
    "parent": "PS"
  },
  "PS-NBS": {
    "label": "Nablus",
    "value": "PS-NBS",
    "parent": "PS"
  },
  "PS-QQA": {
    "label": "Qalqilya",
    "value": "PS-QQA",
    "parent": "PS"
  },
  "PS-RFH": {
    "label": "Rafah",
    "value": "PS-RFH",
    "parent": "PS"
  },
  "PS-RBH": {
    "label": "Ramallah",
    "value": "PS-RBH",
    "parent": "PS"
  },
  "PS-SLT": {
    "label": "Salfit",
    "value": "PS-SLT",
    "parent": "PS"
  },
  "PS-TBS": {
    "label": "Tubas",
    "value": "PS-TBS",
    "parent": "PS"
  },
  "PS-TKM": {
    "label": "Tulkarm",
    "value": "PS-TKM",
    "parent": "PS"
  },
  "PA": {
    "label": "Panama",
    "value": "PA"
  },
  "PA-1": {
    "label": "Bocas del Toro",
    "value": "PA-1",
    "parent": "PA"
  },
  "PA-4": {
    "label": "Chiriqui",
    "value": "PA-4",
    "parent": "PA"
  },
  "PA-2": {
    "label": "Cocle",
    "value": "PA-2",
    "parent": "PA"
  },
  "PA-3": {
    "label": "Colon",
    "value": "PA-3",
    "parent": "PA"
  },
  "PA-5": {
    "label": "Darien",
    "value": "PA-5",
    "parent": "PA"
  },
  "PA-6": {
    "label": "Herrera",
    "value": "PA-6",
    "parent": "PA"
  },
  "PA-7": {
    "label": "Los Santos",
    "value": "PA-7",
    "parent": "PA"
  },
  "PA-NB": {
    "label": "Ngobe-Bugle",
    "value": "PA-NB",
    "parent": "PA"
  },
  "PA-8": {
    "label": "Panama",
    "value": "PA-8",
    "parent": "PA"
  },
  "PA-9": {
    "label": "Veraguas",
    "value": "PA-9",
    "parent": "PA"
  },
  "PG": {
    "label": "Papua New Guinea",
    "value": "PG"
  },
  "PG-NSB": {
    "label": "Bougainville",
    "value": "PG-NSB",
    "parent": "PG"
  },
  "PG-CPM": {
    "label": "Central",
    "value": "PG-CPM",
    "parent": "PG"
  },
  "PG-EBR": {
    "label": "East New Britain",
    "value": "PG-EBR",
    "parent": "PG"
  },
  "PG-EHG": {
    "label": "Eastern Highlands",
    "value": "PG-EHG",
    "parent": "PG"
  },
  "PG-EPW": {
    "label": "Enga",
    "value": "PG-EPW",
    "parent": "PG"
  },
  "PG-MPM": {
    "label": "Madang",
    "value": "PG-MPM",
    "parent": "PG"
  },
  "PG-MRL": {
    "label": "Manus",
    "value": "PG-MRL",
    "parent": "PG"
  },
  "PG-MBA": {
    "label": "Milne Bay",
    "value": "PG-MBA",
    "parent": "PG"
  },
  "PG-MPL": {
    "label": "Morobe",
    "value": "PG-MPL",
    "parent": "PG"
  },
  "PG-NCD": {
    "label": "National Capital District (Port Moresby)",
    "value": "PG-NCD",
    "parent": "PG"
  },
  "PG-NIK": {
    "label": "New Ireland",
    "value": "PG-NIK",
    "parent": "PG"
  },
  "PG-NPP": {
    "label": "Northern",
    "value": "PG-NPP",
    "parent": "PG"
  },
  "PG-SHM": {
    "label": "Southern Highlands",
    "value": "PG-SHM",
    "parent": "PG"
  },
  "PG-WBK": {
    "label": "West New Britain",
    "value": "PG-WBK",
    "parent": "PG"
  },
  "PG-SAN": {
    "label": "West Sepik",
    "value": "PG-SAN",
    "parent": "PG"
  },
  "PG-WPD": {
    "label": "Western",
    "value": "PG-WPD",
    "parent": "PG"
  },
  "PG-WHM": {
    "label": "Western Highlands",
    "value": "PG-WHM",
    "parent": "PG"
  },
  "PY": {
    "label": "Paraguay",
    "value": "PY"
  },
  "PY-10": {
    "label": "Alto Parana",
    "value": "PY-10",
    "parent": "PY"
  },
  "PY-13": {
    "label": "Amambay",
    "value": "PY-13",
    "parent": "PY"
  },
  "PY-ASU": {
    "label": "Asuncion",
    "value": "PY-ASU",
    "parent": "PY"
  },
  "PY-19": {
    "label": "Boqueron",
    "value": "PY-19",
    "parent": "PY"
  },
  "PY-5": {
    "label": "Caaguazu",
    "value": "PY-5",
    "parent": "PY"
  },
  "PY-6": {
    "label": "Caazapa",
    "value": "PY-6",
    "parent": "PY"
  },
  "PY-14": {
    "label": "Canindeyu",
    "value": "PY-14",
    "parent": "PY"
  },
  "PY-11": {
    "label": "Central",
    "value": "PY-11",
    "parent": "PY"
  },
  "PY-1": {
    "label": "Concepcion",
    "value": "PY-1",
    "parent": "PY"
  },
  "PY-3": {
    "label": "Cordillera",
    "value": "PY-3",
    "parent": "PY"
  },
  "PY-4": {
    "label": "Guaira",
    "value": "PY-4",
    "parent": "PY"
  },
  "PY-7": {
    "label": "Itapua",
    "value": "PY-7",
    "parent": "PY"
  },
  "PY-8": {
    "label": "Misiones",
    "value": "PY-8",
    "parent": "PY"
  },
  "PY-12": {
    "label": "Neembucu",
    "value": "PY-12",
    "parent": "PY"
  },
  "PY-9": {
    "label": "Paraguari",
    "value": "PY-9",
    "parent": "PY"
  },
  "PY-15": {
    "label": "Presidente Hayes",
    "value": "PY-15",
    "parent": "PY"
  },
  "PY-2": {
    "label": "San Pedro",
    "value": "PY-2",
    "parent": "PY"
  },
  "PE": {
    "label": "Peru",
    "value": "PE"
  },
  "PE-AMA": {
    "label": "Amazonas",
    "value": "PE-AMA",
    "parent": "PE"
  },
  "PE-ANC": {
    "label": "Ancash",
    "value": "PE-ANC",
    "parent": "PE"
  },
  "PE-APU": {
    "label": "Apurimac",
    "value": "PE-APU",
    "parent": "PE"
  },
  "PE-ARE": {
    "label": "Arequipa",
    "value": "PE-ARE",
    "parent": "PE"
  },
  "PE-AYA": {
    "label": "Ayacucho",
    "value": "PE-AYA",
    "parent": "PE"
  },
  "PE-CAJ": {
    "label": "Cajamarca",
    "value": "PE-CAJ",
    "parent": "PE"
  },
  "PE-CUS": {
    "label": "Cusco",
    "value": "PE-CUS",
    "parent": "PE"
  },
  "PE-CAL": {
    "label": "El Callao",
    "value": "PE-CAL",
    "parent": "PE"
  },
  "PE-HUV": {
    "label": "Huancavelica",
    "value": "PE-HUV",
    "parent": "PE"
  },
  "PE-HUC": {
    "label": "Huanuco",
    "value": "PE-HUC",
    "parent": "PE"
  },
  "PE-ICA": {
    "label": "Ica",
    "value": "PE-ICA",
    "parent": "PE"
  },
  "PE-JUN": {
    "label": "Junin",
    "value": "PE-JUN",
    "parent": "PE"
  },
  "PE-LAL": {
    "label": "La Libertad",
    "value": "PE-LAL",
    "parent": "PE"
  },
  "PE-LAM": {
    "label": "Lambayeque",
    "value": "PE-LAM",
    "parent": "PE"
  },
  "PE-LIM": {
    "label": "Lima",
    "value": "PE-LIM",
    "parent": "PE"
  },
  "PE-LOR": {
    "label": "Loreto",
    "value": "PE-LOR",
    "parent": "PE"
  },
  "PE-MDD": {
    "label": "Madre de Dios",
    "value": "PE-MDD",
    "parent": "PE"
  },
  "PE-MOQ": {
    "label": "Moquegua",
    "value": "PE-MOQ",
    "parent": "PE"
  },
  "PE-PAS": {
    "label": "Pasco",
    "value": "PE-PAS",
    "parent": "PE"
  },
  "PE-PIU": {
    "label": "Piura",
    "value": "PE-PIU",
    "parent": "PE"
  },
  "PE-PUN": {
    "label": "Puno",
    "value": "PE-PUN",
    "parent": "PE"
  },
  "PE-SAM": {
    "label": "San Martin",
    "value": "PE-SAM",
    "parent": "PE"
  },
  "PE-TAC": {
    "label": "Tacna",
    "value": "PE-TAC",
    "parent": "PE"
  },
  "PE-TUM": {
    "label": "Tumbes",
    "value": "PE-TUM",
    "parent": "PE"
  },
  "PE-UCA": {
    "label": "Ucayali",
    "value": "PE-UCA",
    "parent": "PE"
  },
  "PH": {
    "label": "Philippines",
    "value": "PH"
  },
  "PH-ABR": {
    "label": "Abra",
    "value": "PH-ABR",
    "parent": "PH"
  },
  "PH-AGN": {
    "label": "Agusan del Norte",
    "value": "PH-AGN",
    "parent": "PH"
  },
  "PH-AGS": {
    "label": "Agusan del Sur",
    "value": "PH-AGS",
    "parent": "PH"
  },
  "PH-AKL": {
    "label": "Aklan",
    "value": "PH-AKL",
    "parent": "PH"
  },
  "PH-ALB": {
    "label": "Albay",
    "value": "PH-ALB",
    "parent": "PH"
  },
  "PH-ANT": {
    "label": "Antique",
    "value": "PH-ANT",
    "parent": "PH"
  },
  "PH-APA": {
    "label": "Apayao",
    "value": "PH-APA",
    "parent": "PH"
  },
  "PH-AUR": {
    "label": "Aurora",
    "value": "PH-AUR",
    "parent": "PH"
  },
  "PH-BAS": {
    "label": "Basilan",
    "value": "PH-BAS",
    "parent": "PH"
  },
  "PH-BAN": {
    "label": "Bataan",
    "value": "PH-BAN",
    "parent": "PH"
  },
  "PH-BTN": {
    "label": "Batanes",
    "value": "PH-BTN",
    "parent": "PH"
  },
  "PH-BTG": {
    "label": "Batangas",
    "value": "PH-BTG",
    "parent": "PH"
  },
  "PH-BEN": {
    "label": "Benguet",
    "value": "PH-BEN",
    "parent": "PH"
  },
  "PH-BIL": {
    "label": "Biliran",
    "value": "PH-BIL",
    "parent": "PH"
  },
  "PH-BOH": {
    "label": "Bohol",
    "value": "PH-BOH",
    "parent": "PH"
  },
  "PH-BUK": {
    "label": "Bukidnon",
    "value": "PH-BUK",
    "parent": "PH"
  },
  "PH-BUL": {
    "label": "Bulacan",
    "value": "PH-BUL",
    "parent": "PH"
  },
  "PH-CAG": {
    "label": "Cagayan",
    "value": "PH-CAG",
    "parent": "PH"
  },
  "PH-CAN": {
    "label": "Camarines Norte",
    "value": "PH-CAN",
    "parent": "PH"
  },
  "PH-CAS": {
    "label": "Camarines Sur",
    "value": "PH-CAS",
    "parent": "PH"
  },
  "PH-CAM": {
    "label": "Camiguin",
    "value": "PH-CAM",
    "parent": "PH"
  },
  "PH-CAP": {
    "label": "Capiz",
    "value": "PH-CAP",
    "parent": "PH"
  },
  "PH-CAT": {
    "label": "Catanduanes",
    "value": "PH-CAT",
    "parent": "PH"
  },
  "PH-CAV": {
    "label": "Cavite",
    "value": "PH-CAV",
    "parent": "PH"
  },
  "PH-CEB": {
    "label": "Cebu",
    "value": "PH-CEB",
    "parent": "PH"
  },
  "PH-NCO": {
    "label": "Cotabato",
    "value": "PH-NCO",
    "parent": "PH"
  },
  "PH-COM": {
    "label": "Davao de Oro",
    "value": "PH-COM",
    "parent": "PH"
  },
  "PH-DAV": {
    "label": "Davao del Norte",
    "value": "PH-DAV",
    "parent": "PH"
  },
  "PH-DAS": {
    "label": "Davao del Sur",
    "value": "PH-DAS",
    "parent": "PH"
  },
  "PH-DAO": {
    "label": "Davao Oriental",
    "value": "PH-DAO",
    "parent": "PH"
  },
  "PH-DIN": {
    "label": "Dinagat Islands",
    "value": "PH-DIN",
    "parent": "PH"
  },
  "PH-EAS": {
    "label": "Eastern Samar",
    "value": "PH-EAS",
    "parent": "PH"
  },
  "PH-GUI": {
    "label": "Guimaras",
    "value": "PH-GUI",
    "parent": "PH"
  },
  "PH-IFU": {
    "label": "Ifugao",
    "value": "PH-IFU",
    "parent": "PH"
  },
  "PH-ILN": {
    "label": "Ilocos Norte",
    "value": "PH-ILN",
    "parent": "PH"
  },
  "PH-ILS": {
    "label": "Ilocos Sur",
    "value": "PH-ILS",
    "parent": "PH"
  },
  "PH-ILI": {
    "label": "Iloilo",
    "value": "PH-ILI",
    "parent": "PH"
  },
  "PH-ISA": {
    "label": "Isabela",
    "value": "PH-ISA",
    "parent": "PH"
  },
  "PH-KAL": {
    "label": "Kalinga",
    "value": "PH-KAL",
    "parent": "PH"
  },
  "PH-LUN": {
    "label": "La Union",
    "value": "PH-LUN",
    "parent": "PH"
  },
  "PH-LAG": {
    "label": "Laguna",
    "value": "PH-LAG",
    "parent": "PH"
  },
  "PH-LAN": {
    "label": "Lanao del Norte",
    "value": "PH-LAN",
    "parent": "PH"
  },
  "PH-LAS": {
    "label": "Lanao del Sur",
    "value": "PH-LAS",
    "parent": "PH"
  },
  "PH-LEY": {
    "label": "Leyte",
    "value": "PH-LEY",
    "parent": "PH"
  },
  "PH-MAG": {
    "label": "Maguindanao",
    "value": "PH-MAG",
    "parent": "PH"
  },
  "PH-MAD": {
    "label": "Marinduque",
    "value": "PH-MAD",
    "parent": "PH"
  },
  "PH-MAS": {
    "label": "Masbate",
    "value": "PH-MAS",
    "parent": "PH"
  },
  "PH-MDC": {
    "label": "Mindoro Occidental",
    "value": "PH-MDC",
    "parent": "PH"
  },
  "PH-MDR": {
    "label": "Mindoro Oriental",
    "value": "PH-MDR",
    "parent": "PH"
  },
  "PH-MSC": {
    "label": "Misamis Occidental",
    "value": "PH-MSC",
    "parent": "PH"
  },
  "PH-MSR": {
    "label": "Misamis Oriental",
    "value": "PH-MSR",
    "parent": "PH"
  },
  "PH-MOU": {
    "label": "Mountain Province",
    "value": "PH-MOU",
    "parent": "PH"
  },
  "PH-00": {
    "label": "National Capital Region",
    "value": "PH-00",
    "parent": "PH"
  },
  "PH-NEC": {
    "label": "Negros Occidental",
    "value": "PH-NEC",
    "parent": "PH"
  },
  "PH-NER": {
    "label": "Negros Oriental",
    "value": "PH-NER",
    "parent": "PH"
  },
  "PH-NSA": {
    "label": "Northern Samar",
    "value": "PH-NSA",
    "parent": "PH"
  },
  "PH-NUE": {
    "label": "Nueva Ecija",
    "value": "PH-NUE",
    "parent": "PH"
  },
  "PH-NUV": {
    "label": "Nueva Vizcaya",
    "value": "PH-NUV",
    "parent": "PH"
  },
  "PH-PLW": {
    "label": "Palawan",
    "value": "PH-PLW",
    "parent": "PH"
  },
  "PH-PAM": {
    "label": "Pampanga",
    "value": "PH-PAM",
    "parent": "PH"
  },
  "PH-PAN": {
    "label": "Pangasinan",
    "value": "PH-PAN",
    "parent": "PH"
  },
  "PH-QUE": {
    "label": "Quezon",
    "value": "PH-QUE",
    "parent": "PH"
  },
  "PH-QUI": {
    "label": "Quirino",
    "value": "PH-QUI",
    "parent": "PH"
  },
  "PH-RIZ": {
    "label": "Rizal",
    "value": "PH-RIZ",
    "parent": "PH"
  },
  "PH-ROM": {
    "label": "Romblon",
    "value": "PH-ROM",
    "parent": "PH"
  },
  "PH-WSA": {
    "label": "Samar",
    "value": "PH-WSA",
    "parent": "PH"
  },
  "PH-SAR": {
    "label": "Sarangani",
    "value": "PH-SAR",
    "parent": "PH"
  },
  "PH-SIG": {
    "label": "Siquijor",
    "value": "PH-SIG",
    "parent": "PH"
  },
  "PH-SOR": {
    "label": "Sorsogon",
    "value": "PH-SOR",
    "parent": "PH"
  },
  "PH-SCO": {
    "label": "South Cotabato",
    "value": "PH-SCO",
    "parent": "PH"
  },
  "PH-SLE": {
    "label": "Southern Leyte",
    "value": "PH-SLE",
    "parent": "PH"
  },
  "PH-SUK": {
    "label": "Sultan Kudarat",
    "value": "PH-SUK",
    "parent": "PH"
  },
  "PH-SLU": {
    "label": "Sulu",
    "value": "PH-SLU",
    "parent": "PH"
  },
  "PH-SUN": {
    "label": "Surigao del Norte",
    "value": "PH-SUN",
    "parent": "PH"
  },
  "PH-SUR": {
    "label": "Surigao del Sur",
    "value": "PH-SUR",
    "parent": "PH"
  },
  "PH-TAR": {
    "label": "Tarlac",
    "value": "PH-TAR",
    "parent": "PH"
  },
  "PH-TAW": {
    "label": "Tawi-Tawi",
    "value": "PH-TAW",
    "parent": "PH"
  },
  "PH-ZMB": {
    "label": "Zambales",
    "value": "PH-ZMB",
    "parent": "PH"
  },
  "PH-ZAN": {
    "label": "Zamboanga del Norte",
    "value": "PH-ZAN",
    "parent": "PH"
  },
  "PH-ZAS": {
    "label": "Zamboanga del Sur",
    "value": "PH-ZAS",
    "parent": "PH"
  },
  "PH-ZSI": {
    "label": "Zamboanga Sibugay",
    "value": "PH-ZSI",
    "parent": "PH"
  },
  "PN": {
    "label": "Pitcairn",
    "value": "PN"
  },
  "PL": {
    "label": "Poland",
    "value": "PL"
  },
  "PL-02": {
    "label": "Dolnoslaskie",
    "value": "PL-02",
    "parent": "PL"
  },
  "PL-04": {
    "label": "Kujawsko-Pomorskie",
    "value": "PL-04",
    "parent": "PL"
  },
  "PL-10": {
    "label": "Lodzkie",
    "value": "PL-10",
    "parent": "PL"
  },
  "PL-06": {
    "label": "Lubelskie",
    "value": "PL-06",
    "parent": "PL"
  },
  "PL-08": {
    "label": "Lubuskie",
    "value": "PL-08",
    "parent": "PL"
  },
  "PL-12": {
    "label": "Malopolskie",
    "value": "PL-12",
    "parent": "PL"
  },
  "PL-14": {
    "label": "Mazowieckie",
    "value": "PL-14",
    "parent": "PL"
  },
  "PL-16": {
    "label": "Opolskie",
    "value": "PL-16",
    "parent": "PL"
  },
  "PL-18": {
    "label": "Podkarpackie",
    "value": "PL-18",
    "parent": "PL"
  },
  "PL-20": {
    "label": "Podlaskie",
    "value": "PL-20",
    "parent": "PL"
  },
  "PL-22": {
    "label": "Pomorskie",
    "value": "PL-22",
    "parent": "PL"
  },
  "PL-24": {
    "label": "Slaskie",
    "value": "PL-24",
    "parent": "PL"
  },
  "PL-26": {
    "label": "Swietokrzyskie",
    "value": "PL-26",
    "parent": "PL"
  },
  "PL-28": {
    "label": "Warminsko-Mazurskie",
    "value": "PL-28",
    "parent": "PL"
  },
  "PL-30": {
    "label": "Wielkopolskie",
    "value": "PL-30",
    "parent": "PL"
  },
  "PL-32": {
    "label": "Zachodniopomorskie",
    "value": "PL-32",
    "parent": "PL"
  },
  "PT": {
    "label": "Portugal",
    "value": "PT"
  },
  "PT-01": {
    "label": "Aveiro",
    "value": "PT-01",
    "parent": "PT"
  },
  "PT-02": {
    "label": "Beja",
    "value": "PT-02",
    "parent": "PT"
  },
  "PT-03": {
    "label": "Braga",
    "value": "PT-03",
    "parent": "PT"
  },
  "PT-04": {
    "label": "Braganca",
    "value": "PT-04",
    "parent": "PT"
  },
  "PT-05": {
    "label": "Castelo Branco",
    "value": "PT-05",
    "parent": "PT"
  },
  "PT-06": {
    "label": "Coimbra",
    "value": "PT-06",
    "parent": "PT"
  },
  "PT-07": {
    "label": "Evora",
    "value": "PT-07",
    "parent": "PT"
  },
  "PT-08": {
    "label": "Faro",
    "value": "PT-08",
    "parent": "PT"
  },
  "PT-09": {
    "label": "Guarda",
    "value": "PT-09",
    "parent": "PT"
  },
  "PT-10": {
    "label": "Leiria",
    "value": "PT-10",
    "parent": "PT"
  },
  "PT-11": {
    "label": "Lisboa",
    "value": "PT-11",
    "parent": "PT"
  },
  "PT-12": {
    "label": "Portalegre",
    "value": "PT-12",
    "parent": "PT"
  },
  "PT-13": {
    "label": "Porto",
    "value": "PT-13",
    "parent": "PT"
  },
  "PT-30": {
    "label": "Regiao Autonoma da Madeira",
    "value": "PT-30",
    "parent": "PT"
  },
  "PT-20": {
    "label": "Regiao Autonoma dos Acores",
    "value": "PT-20",
    "parent": "PT"
  },
  "PT-14": {
    "label": "Santarem",
    "value": "PT-14",
    "parent": "PT"
  },
  "PT-15": {
    "label": "Setubal",
    "value": "PT-15",
    "parent": "PT"
  },
  "PT-16": {
    "label": "Viana do Castelo",
    "value": "PT-16",
    "parent": "PT"
  },
  "PT-17": {
    "label": "Vila Real",
    "value": "PT-17",
    "parent": "PT"
  },
  "PT-18": {
    "label": "Viseu",
    "value": "PT-18",
    "parent": "PT"
  },
  "PR": {
    "label": "Puerto Rico",
    "value": "PR"
  },
  "QA": {
    "label": "Qatar",
    "value": "QA"
  },
  "QA-DA": {
    "label": "Ad Dawhah",
    "value": "QA-DA",
    "parent": "QA"
  },
  "QA-KH": {
    "label": "Al Khawr wa adh Dhakhirah",
    "value": "QA-KH",
    "parent": "QA"
  },
  "QA-WA": {
    "label": "Al Wakrah",
    "value": "QA-WA",
    "parent": "QA"
  },
  "QA-RA": {
    "label": "Ar Rayyan",
    "value": "QA-RA",
    "parent": "QA"
  },
  "QA-MS": {
    "label": "Ash Shamal",
    "value": "QA-MS",
    "parent": "QA"
  },
  "QA-ZA": {
    "label": "Az Za'ayin",
    "value": "QA-ZA",
    "parent": "QA"
  },
  "QA-US": {
    "label": "Umm Salal",
    "value": "QA-US",
    "parent": "QA"
  },
  "RE": {
    "label": "Réunion",
    "value": "RE"
  },
  "RO": {
    "label": "Romania",
    "value": "RO"
  },
  "RO-AB": {
    "label": "Alba",
    "value": "RO-AB",
    "parent": "RO"
  },
  "RO-AR": {
    "label": "Arad",
    "value": "RO-AR",
    "parent": "RO"
  },
  "RO-AG": {
    "label": "Arges",
    "value": "RO-AG",
    "parent": "RO"
  },
  "RO-BC": {
    "label": "Bacau",
    "value": "RO-BC",
    "parent": "RO"
  },
  "RO-BH": {
    "label": "Bihor",
    "value": "RO-BH",
    "parent": "RO"
  },
  "RO-BN": {
    "label": "Bistrita-Nasaud",
    "value": "RO-BN",
    "parent": "RO"
  },
  "RO-BT": {
    "label": "Botosani",
    "value": "RO-BT",
    "parent": "RO"
  },
  "RO-BR": {
    "label": "Braila",
    "value": "RO-BR",
    "parent": "RO"
  },
  "RO-BV": {
    "label": "Brasov",
    "value": "RO-BV",
    "parent": "RO"
  },
  "RO-B": {
    "label": "Bucuresti",
    "value": "RO-B",
    "parent": "RO"
  },
  "RO-BZ": {
    "label": "Buzau",
    "value": "RO-BZ",
    "parent": "RO"
  },
  "RO-CL": {
    "label": "Calarasi",
    "value": "RO-CL",
    "parent": "RO"
  },
  "RO-CS": {
    "label": "Caras-Severin",
    "value": "RO-CS",
    "parent": "RO"
  },
  "RO-CJ": {
    "label": "Cluj",
    "value": "RO-CJ",
    "parent": "RO"
  },
  "RO-CT": {
    "label": "Constanta",
    "value": "RO-CT",
    "parent": "RO"
  },
  "RO-CV": {
    "label": "Covasna",
    "value": "RO-CV",
    "parent": "RO"
  },
  "RO-DB": {
    "label": "Dambovita",
    "value": "RO-DB",
    "parent": "RO"
  },
  "RO-DJ": {
    "label": "Dolj",
    "value": "RO-DJ",
    "parent": "RO"
  },
  "RO-GL": {
    "label": "Galati",
    "value": "RO-GL",
    "parent": "RO"
  },
  "RO-GR": {
    "label": "Giurgiu",
    "value": "RO-GR",
    "parent": "RO"
  },
  "RO-GJ": {
    "label": "Gorj",
    "value": "RO-GJ",
    "parent": "RO"
  },
  "RO-HR": {
    "label": "Harghita",
    "value": "RO-HR",
    "parent": "RO"
  },
  "RO-HD": {
    "label": "Hunedoara",
    "value": "RO-HD",
    "parent": "RO"
  },
  "RO-IL": {
    "label": "Ialomita",
    "value": "RO-IL",
    "parent": "RO"
  },
  "RO-IS": {
    "label": "Iasi",
    "value": "RO-IS",
    "parent": "RO"
  },
  "RO-IF": {
    "label": "Ilfov",
    "value": "RO-IF",
    "parent": "RO"
  },
  "RO-MM": {
    "label": "Maramures",
    "value": "RO-MM",
    "parent": "RO"
  },
  "RO-MH": {
    "label": "Mehedinti",
    "value": "RO-MH",
    "parent": "RO"
  },
  "RO-MS": {
    "label": "Mures",
    "value": "RO-MS",
    "parent": "RO"
  },
  "RO-NT": {
    "label": "Neamt",
    "value": "RO-NT",
    "parent": "RO"
  },
  "RO-OT": {
    "label": "Olt",
    "value": "RO-OT",
    "parent": "RO"
  },
  "RO-PH": {
    "label": "Prahova",
    "value": "RO-PH",
    "parent": "RO"
  },
  "RO-SJ": {
    "label": "Salaj",
    "value": "RO-SJ",
    "parent": "RO"
  },
  "RO-SM": {
    "label": "Satu Mare",
    "value": "RO-SM",
    "parent": "RO"
  },
  "RO-SB": {
    "label": "Sibiu",
    "value": "RO-SB",
    "parent": "RO"
  },
  "RO-SV": {
    "label": "Suceava",
    "value": "RO-SV",
    "parent": "RO"
  },
  "RO-TR": {
    "label": "Teleorman",
    "value": "RO-TR",
    "parent": "RO"
  },
  "RO-TM": {
    "label": "Timis",
    "value": "RO-TM",
    "parent": "RO"
  },
  "RO-TL": {
    "label": "Tulcea",
    "value": "RO-TL",
    "parent": "RO"
  },
  "RO-VL": {
    "label": "Valcea",
    "value": "RO-VL",
    "parent": "RO"
  },
  "RO-VS": {
    "label": "Vaslui",
    "value": "RO-VS",
    "parent": "RO"
  },
  "RO-VN": {
    "label": "Vrancea",
    "value": "RO-VN",
    "parent": "RO"
  },
  "RU": {
    "label": "Russian Federation",
    "value": "RU"
  },
  "RU-AD": {
    "label": "Adygeya, Respublika",
    "value": "RU-AD",
    "parent": "RU"
  },
  "RU-AL": {
    "label": "Altay, Respublika",
    "value": "RU-AL",
    "parent": "RU"
  },
  "RU-ALT": {
    "label": "Altayskiy kray",
    "value": "RU-ALT",
    "parent": "RU"
  },
  "RU-AMU": {
    "label": "Amurskaya oblast'",
    "value": "RU-AMU",
    "parent": "RU"
  },
  "RU-ARK": {
    "label": "Arkhangel'skaya oblast'",
    "value": "RU-ARK",
    "parent": "RU"
  },
  "RU-AST": {
    "label": "Astrakhanskaya oblast'",
    "value": "RU-AST",
    "parent": "RU"
  },
  "RU-BA": {
    "label": "Bashkortostan, Respublika",
    "value": "RU-BA",
    "parent": "RU"
  },
  "RU-BEL": {
    "label": "Belgorodskaya oblast'",
    "value": "RU-BEL",
    "parent": "RU"
  },
  "RU-BRY": {
    "label": "Bryanskaya oblast'",
    "value": "RU-BRY",
    "parent": "RU"
  },
  "RU-BU": {
    "label": "Buryatiya, Respublika",
    "value": "RU-BU",
    "parent": "RU"
  },
  "RU-CE": {
    "label": "Chechenskaya Respublika",
    "value": "RU-CE",
    "parent": "RU"
  },
  "RU-CHE": {
    "label": "Chelyabinskaya oblast'",
    "value": "RU-CHE",
    "parent": "RU"
  },
  "RU-CHU": {
    "label": "Chukotskiy avtonomnyy okrug",
    "value": "RU-CHU",
    "parent": "RU"
  },
  "RU-CU": {
    "label": "Chuvashskaya Respublika",
    "value": "RU-CU",
    "parent": "RU"
  },
  "RU-DA": {
    "label": "Dagestan, Respublika",
    "value": "RU-DA",
    "parent": "RU"
  },
  "RU-IN": {
    "label": "Ingushetiya, Respublika",
    "value": "RU-IN",
    "parent": "RU"
  },
  "RU-IRK": {
    "label": "Irkutskaya oblast'",
    "value": "RU-IRK",
    "parent": "RU"
  },
  "RU-IVA": {
    "label": "Ivanovskaya oblast'",
    "value": "RU-IVA",
    "parent": "RU"
  },
  "RU-KB": {
    "label": "Kabardino-Balkarskaya Respublika",
    "value": "RU-KB",
    "parent": "RU"
  },
  "RU-KGD": {
    "label": "Kaliningradskaya oblast'",
    "value": "RU-KGD",
    "parent": "RU"
  },
  "RU-KL": {
    "label": "Kalmykiya, Respublika",
    "value": "RU-KL",
    "parent": "RU"
  },
  "RU-KLU": {
    "label": "Kaluzhskaya oblast'",
    "value": "RU-KLU",
    "parent": "RU"
  },
  "RU-KAM": {
    "label": "Kamchatskiy kray",
    "value": "RU-KAM",
    "parent": "RU"
  },
  "RU-KC": {
    "label": "Karachayevo-Cherkesskaya Respublika",
    "value": "RU-KC",
    "parent": "RU"
  },
  "RU-KR": {
    "label": "Kareliya, Respublika",
    "value": "RU-KR",
    "parent": "RU"
  },
  "RU-KEM": {
    "label": "Kemerovskaya oblast'",
    "value": "RU-KEM",
    "parent": "RU"
  },
  "RU-KHA": {
    "label": "Khabarovskiy kray",
    "value": "RU-KHA",
    "parent": "RU"
  },
  "RU-KK": {
    "label": "Khakasiya, Respublika",
    "value": "RU-KK",
    "parent": "RU"
  },
  "RU-KHM": {
    "label": "Khanty-Mansiyskiy avtonomnyy okrug",
    "value": "RU-KHM",
    "parent": "RU"
  },
  "RU-KIR": {
    "label": "Kirovskaya oblast'",
    "value": "RU-KIR",
    "parent": "RU"
  },
  "RU-KO": {
    "label": "Komi, Respublika",
    "value": "RU-KO",
    "parent": "RU"
  },
  "RU-KOS": {
    "label": "Kostromskaya oblast'",
    "value": "RU-KOS",
    "parent": "RU"
  },
  "RU-KDA": {
    "label": "Krasnodarskiy kray",
    "value": "RU-KDA",
    "parent": "RU"
  },
  "RU-KYA": {
    "label": "Krasnoyarskiy kray",
    "value": "RU-KYA",
    "parent": "RU"
  },
  "RU-KGN": {
    "label": "Kurganskaya oblast'",
    "value": "RU-KGN",
    "parent": "RU"
  },
  "RU-KRS": {
    "label": "Kurskaya oblast'",
    "value": "RU-KRS",
    "parent": "RU"
  },
  "RU-LEN": {
    "label": "Leningradskaya oblast'",
    "value": "RU-LEN",
    "parent": "RU"
  },
  "RU-LIP": {
    "label": "Lipetskaya oblast'",
    "value": "RU-LIP",
    "parent": "RU"
  },
  "RU-MAG": {
    "label": "Magadanskaya oblast'",
    "value": "RU-MAG",
    "parent": "RU"
  },
  "RU-ME": {
    "label": "Mariy El, Respublika",
    "value": "RU-ME",
    "parent": "RU"
  },
  "RU-MO": {
    "label": "Mordoviya, Respublika",
    "value": "RU-MO",
    "parent": "RU"
  },
  "RU-MOS": {
    "label": "Moskovskaya oblast'",
    "value": "RU-MOS",
    "parent": "RU"
  },
  "RU-MOW": {
    "label": "Moskva",
    "value": "RU-MOW",
    "parent": "RU"
  },
  "RU-MUR": {
    "label": "Murmanskaya oblast'",
    "value": "RU-MUR",
    "parent": "RU"
  },
  "RU-NEN": {
    "label": "Nenetskiy avtonomnyy okrug",
    "value": "RU-NEN",
    "parent": "RU"
  },
  "RU-NIZ": {
    "label": "Nizhegorodskaya oblast'",
    "value": "RU-NIZ",
    "parent": "RU"
  },
  "RU-NGR": {
    "label": "Novgorodskaya oblast'",
    "value": "RU-NGR",
    "parent": "RU"
  },
  "RU-NVS": {
    "label": "Novosibirskaya oblast'",
    "value": "RU-NVS",
    "parent": "RU"
  },
  "RU-OMS": {
    "label": "Omskaya oblast'",
    "value": "RU-OMS",
    "parent": "RU"
  },
  "RU-ORE": {
    "label": "Orenburgskaya oblast'",
    "value": "RU-ORE",
    "parent": "RU"
  },
  "RU-ORL": {
    "label": "Orlovskaya oblast'",
    "value": "RU-ORL",
    "parent": "RU"
  },
  "RU-PNZ": {
    "label": "Penzenskaya oblast'",
    "value": "RU-PNZ",
    "parent": "RU"
  },
  "RU-PER": {
    "label": "Permskiy kray",
    "value": "RU-PER",
    "parent": "RU"
  },
  "RU-PRI": {
    "label": "Primorskiy kray",
    "value": "RU-PRI",
    "parent": "RU"
  },
  "RU-PSK": {
    "label": "Pskovskaya oblast'",
    "value": "RU-PSK",
    "parent": "RU"
  },
  "RU-ROS": {
    "label": "Rostovskaya oblast'",
    "value": "RU-ROS",
    "parent": "RU"
  },
  "RU-RYA": {
    "label": "Ryazanskaya oblast'",
    "value": "RU-RYA",
    "parent": "RU"
  },
  "RU-SA": {
    "label": "Saha, Respublika",
    "value": "RU-SA",
    "parent": "RU"
  },
  "RU-SAK": {
    "label": "Sakhalinskaya oblast'",
    "value": "RU-SAK",
    "parent": "RU"
  },
  "RU-SAM": {
    "label": "Samarskaya oblast'",
    "value": "RU-SAM",
    "parent": "RU"
  },
  "RU-SPE": {
    "label": "Sankt-Peterburg",
    "value": "RU-SPE",
    "parent": "RU"
  },
  "RU-SAR": {
    "label": "Saratovskaya oblast'",
    "value": "RU-SAR",
    "parent": "RU"
  },
  "RU-SE": {
    "label": "Severnaya Osetiya, Respublika",
    "value": "RU-SE",
    "parent": "RU"
  },
  "RU-SMO": {
    "label": "Smolenskaya oblast'",
    "value": "RU-SMO",
    "parent": "RU"
  },
  "RU-STA": {
    "label": "Stavropol'skiy kray",
    "value": "RU-STA",
    "parent": "RU"
  },
  "RU-SVE": {
    "label": "Sverdlovskaya oblast'",
    "value": "RU-SVE",
    "parent": "RU"
  },
  "RU-TAM": {
    "label": "Tambovskaya oblast'",
    "value": "RU-TAM",
    "parent": "RU"
  },
  "RU-TA": {
    "label": "Tatarstan, Respublika",
    "value": "RU-TA",
    "parent": "RU"
  },
  "RU-TOM": {
    "label": "Tomskaya oblast'",
    "value": "RU-TOM",
    "parent": "RU"
  },
  "RU-TUL": {
    "label": "Tul'skaya oblast'",
    "value": "RU-TUL",
    "parent": "RU"
  },
  "RU-TVE": {
    "label": "Tverskaya oblast'",
    "value": "RU-TVE",
    "parent": "RU"
  },
  "RU-TYU": {
    "label": "Tyumenskaya oblast'",
    "value": "RU-TYU",
    "parent": "RU"
  },
  "RU-TY": {
    "label": "Tyva, Respublika",
    "value": "RU-TY",
    "parent": "RU"
  },
  "RU-UD": {
    "label": "Udmurtskaya Respublika",
    "value": "RU-UD",
    "parent": "RU"
  },
  "RU-ULY": {
    "label": "Ul'yanovskaya oblast'",
    "value": "RU-ULY",
    "parent": "RU"
  },
  "RU-VLA": {
    "label": "Vladimirskaya oblast'",
    "value": "RU-VLA",
    "parent": "RU"
  },
  "RU-VGG": {
    "label": "Volgogradskaya oblast'",
    "value": "RU-VGG",
    "parent": "RU"
  },
  "RU-VLG": {
    "label": "Vologodskaya oblast'",
    "value": "RU-VLG",
    "parent": "RU"
  },
  "RU-VOR": {
    "label": "Voronezhskaya oblast'",
    "value": "RU-VOR",
    "parent": "RU"
  },
  "RU-YAN": {
    "label": "Yamalo-Nenetskiy avtonomnyy okrug",
    "value": "RU-YAN",
    "parent": "RU"
  },
  "RU-YAR": {
    "label": "Yaroslavskaya oblast'",
    "value": "RU-YAR",
    "parent": "RU"
  },
  "RU-YEV": {
    "label": "Yevreyskaya avtonomnaya oblast'",
    "value": "RU-YEV",
    "parent": "RU"
  },
  "RU-ZAB": {
    "label": "Zabaykal'skiy kray",
    "value": "RU-ZAB",
    "parent": "RU"
  },
  "RW": {
    "label": "Rwanda",
    "value": "RW"
  },
  "RW-02": {
    "label": "Est",
    "value": "RW-02",
    "parent": "RW"
  },
  "RW-03": {
    "label": "Nord",
    "value": "RW-03",
    "parent": "RW"
  },
  "RW-04": {
    "label": "Ouest",
    "value": "RW-04",
    "parent": "RW"
  },
  "RW-05": {
    "label": "Sud",
    "value": "RW-05",
    "parent": "RW"
  },
  "RW-01": {
    "label": "Ville de Kigali",
    "value": "RW-01",
    "parent": "RW"
  },
  "BL": {
    "label": "Saint Barthélemy",
    "value": "BL"
  },
  "SH": {
    "label": "Saint Helena, Ascension and Tristan da Cunha",
    "value": "SH"
  },
  "SH-HL": {
    "label": "Saint Helena",
    "value": "SH-HL",
    "parent": "SH"
  },
  "KN": {
    "label": "Saint Kitts and Nevis",
    "value": "KN"
  },
  "KN-01": {
    "label": "Christ Church Nichola Town",
    "value": "KN-01",
    "parent": "KN"
  },
  "KN-02": {
    "label": "Saint Anne Sandy Point",
    "value": "KN-02",
    "parent": "KN"
  },
  "KN-03": {
    "label": "Saint George Basseterre",
    "value": "KN-03",
    "parent": "KN"
  },
  "KN-05": {
    "label": "Saint James Windward",
    "value": "KN-05",
    "parent": "KN"
  },
  "KN-06": {
    "label": "Saint John Capisterre",
    "value": "KN-06",
    "parent": "KN"
  },
  "KN-07": {
    "label": "Saint John Figtree",
    "value": "KN-07",
    "parent": "KN"
  },
  "KN-08": {
    "label": "Saint Mary Cayon",
    "value": "KN-08",
    "parent": "KN"
  },
  "KN-09": {
    "label": "Saint Paul Capisterre",
    "value": "KN-09",
    "parent": "KN"
  },
  "KN-10": {
    "label": "Saint Paul Charlestown",
    "value": "KN-10",
    "parent": "KN"
  },
  "KN-11": {
    "label": "Saint Peter Basseterre",
    "value": "KN-11",
    "parent": "KN"
  },
  "KN-12": {
    "label": "Saint Thomas Lowland",
    "value": "KN-12",
    "parent": "KN"
  },
  "KN-13": {
    "label": "Saint Thomas Middle Island",
    "value": "KN-13",
    "parent": "KN"
  },
  "KN-15": {
    "label": "Trinity Palmetto Point",
    "value": "KN-15",
    "parent": "KN"
  },
  "LC": {
    "label": "Saint Lucia",
    "value": "LC"
  },
  "LC-01": {
    "label": "Anse la Raye",
    "value": "LC-01",
    "parent": "LC"
  },
  "LC-02": {
    "label": "Castries",
    "value": "LC-02",
    "parent": "LC"
  },
  "LC-03": {
    "label": "Choiseul",
    "value": "LC-03",
    "parent": "LC"
  },
  "LC-05": {
    "label": "Dennery",
    "value": "LC-05",
    "parent": "LC"
  },
  "LC-06": {
    "label": "Gros Islet",
    "value": "LC-06",
    "parent": "LC"
  },
  "LC-07": {
    "label": "Laborie",
    "value": "LC-07",
    "parent": "LC"
  },
  "LC-08": {
    "label": "Micoud",
    "value": "LC-08",
    "parent": "LC"
  },
  "LC-10": {
    "label": "Soufriere",
    "value": "LC-10",
    "parent": "LC"
  },
  "LC-11": {
    "label": "Vieux Fort",
    "value": "LC-11",
    "parent": "LC"
  },
  "MF": {
    "label": "Saint Martin (French part)",
    "value": "MF"
  },
  "PM": {
    "label": "Saint Pierre and Miquelon",
    "value": "PM"
  },
  "VC": {
    "label": "Saint Vincent and the Grenadines",
    "value": "VC"
  },
  "VC-01": {
    "label": "Charlotte",
    "value": "VC-01",
    "parent": "VC"
  },
  "VC-06": {
    "label": "Grenadines",
    "value": "VC-06",
    "parent": "VC"
  },
  "VC-04": {
    "label": "Saint George",
    "value": "VC-04",
    "parent": "VC"
  },
  "VC-05": {
    "label": "Saint Patrick",
    "value": "VC-05",
    "parent": "VC"
  },
  "WS": {
    "label": "Samoa",
    "value": "WS"
  },
  "WS-AT": {
    "label": "Atua",
    "value": "WS-AT",
    "parent": "WS"
  },
  "WS-FA": {
    "label": "Fa'asaleleaga",
    "value": "WS-FA",
    "parent": "WS"
  },
  "WS-TU": {
    "label": "Tuamasaga",
    "value": "WS-TU",
    "parent": "WS"
  },
  "SM": {
    "label": "San Marino",
    "value": "SM"
  },
  "SM-02": {
    "label": "Chiesanuova",
    "value": "SM-02",
    "parent": "SM"
  },
  "SM-07": {
    "label": "Citta di San Marino",
    "value": "SM-07",
    "parent": "SM"
  },
  "SM-04": {
    "label": "Faetano",
    "value": "SM-04",
    "parent": "SM"
  },
  "SM-09": {
    "label": "Serravalle",
    "value": "SM-09",
    "parent": "SM"
  },
  "ST": {
    "label": "Sao Tome and Principe",
    "value": "ST"
  },
  "ST-01": {
    "label": "Agua Grande",
    "value": "ST-01",
    "parent": "ST"
  },
  "ST-P": {
    "label": "Principe",
    "value": "ST-P",
    "parent": "ST"
  },
  "SA": {
    "label": "Saudi Arabia",
    "value": "SA"
  },
  "SA-14": {
    "label": "'Asir",
    "value": "SA-14",
    "parent": "SA"
  },
  "SA-11": {
    "label": "Al Bahah",
    "value": "SA-11",
    "parent": "SA"
  },
  "SA-08": {
    "label": "Al Hudud ash Shamaliyah",
    "value": "SA-08",
    "parent": "SA"
  },
  "SA-12": {
    "label": "Al Jawf",
    "value": "SA-12",
    "parent": "SA"
  },
  "SA-03": {
    "label": "Al Madinah al Munawwarah",
    "value": "SA-03",
    "parent": "SA"
  },
  "SA-05": {
    "label": "Al Qasim",
    "value": "SA-05",
    "parent": "SA"
  },
  "SA-01": {
    "label": "Ar Riyad",
    "value": "SA-01",
    "parent": "SA"
  },
  "SA-04": {
    "label": "Ash Sharqiyah",
    "value": "SA-04",
    "parent": "SA"
  },
  "SA-06": {
    "label": "Ha'il",
    "value": "SA-06",
    "parent": "SA"
  },
  "SA-09": {
    "label": "Jazan",
    "value": "SA-09",
    "parent": "SA"
  },
  "SA-02": {
    "label": "Makkah al Mukarramah",
    "value": "SA-02",
    "parent": "SA"
  },
  "SA-10": {
    "label": "Najran",
    "value": "SA-10",
    "parent": "SA"
  },
  "SA-07": {
    "label": "Tabuk",
    "value": "SA-07",
    "parent": "SA"
  },
  "SN": {
    "label": "Senegal",
    "value": "SN"
  },
  "SN-DK": {
    "label": "Dakar",
    "value": "SN-DK",
    "parent": "SN"
  },
  "SN-DB": {
    "label": "Diourbel",
    "value": "SN-DB",
    "parent": "SN"
  },
  "SN-FK": {
    "label": "Fatick",
    "value": "SN-FK",
    "parent": "SN"
  },
  "SN-KA": {
    "label": "Kaffrine",
    "value": "SN-KA",
    "parent": "SN"
  },
  "SN-KL": {
    "label": "Kaolack",
    "value": "SN-KL",
    "parent": "SN"
  },
  "SN-KE": {
    "label": "Kedougou",
    "value": "SN-KE",
    "parent": "SN"
  },
  "SN-KD": {
    "label": "Kolda",
    "value": "SN-KD",
    "parent": "SN"
  },
  "SN-LG": {
    "label": "Louga",
    "value": "SN-LG",
    "parent": "SN"
  },
  "SN-MT": {
    "label": "Matam",
    "value": "SN-MT",
    "parent": "SN"
  },
  "SN-SL": {
    "label": "Saint-Louis",
    "value": "SN-SL",
    "parent": "SN"
  },
  "SN-TC": {
    "label": "Tambacounda",
    "value": "SN-TC",
    "parent": "SN"
  },
  "SN-TH": {
    "label": "Thies",
    "value": "SN-TH",
    "parent": "SN"
  },
  "SN-ZG": {
    "label": "Ziguinchor",
    "value": "SN-ZG",
    "parent": "SN"
  },
  "RS": {
    "label": "Serbia",
    "value": "RS"
  },
  "RS-00": {
    "label": "Beograd",
    "value": "RS-00",
    "parent": "RS"
  },
  "RS-14": {
    "label": "Borski okrug",
    "value": "RS-14",
    "parent": "RS"
  },
  "RS-11": {
    "label": "Branicevski okrug",
    "value": "RS-11",
    "parent": "RS"
  },
  "RS-23": {
    "label": "Jablanicki okrug",
    "value": "RS-23",
    "parent": "RS"
  },
  "RS-06": {
    "label": "Juznobacki okrug",
    "value": "RS-06",
    "parent": "RS"
  },
  "RS-04": {
    "label": "Juznobanatski okrug",
    "value": "RS-04",
    "parent": "RS"
  },
  "RS-09": {
    "label": "Kolubarski okrug",
    "value": "RS-09",
    "parent": "RS"
  },
  "RS-28": {
    "label": "Kosovsko-Mitrovacki okrug",
    "value": "RS-28",
    "parent": "RS"
  },
  "RS-08": {
    "label": "Macvanski okrug",
    "value": "RS-08",
    "parent": "RS"
  },
  "RS-17": {
    "label": "Moravicki okrug",
    "value": "RS-17",
    "parent": "RS"
  },
  "RS-20": {
    "label": "Nisavski okrug",
    "value": "RS-20",
    "parent": "RS"
  },
  "RS-24": {
    "label": "Pcinjski okrug",
    "value": "RS-24",
    "parent": "RS"
  },
  "RS-26": {
    "label": "Pecki okrug",
    "value": "RS-26",
    "parent": "RS"
  },
  "RS-22": {
    "label": "Pirotski okrug",
    "value": "RS-22",
    "parent": "RS"
  },
  "RS-10": {
    "label": "Podunavski okrug",
    "value": "RS-10",
    "parent": "RS"
  },
  "RS-13": {
    "label": "Pomoravski okrug",
    "value": "RS-13",
    "parent": "RS"
  },
  "RS-27": {
    "label": "Prizrenski okrug",
    "value": "RS-27",
    "parent": "RS"
  },
  "RS-19": {
    "label": "Rasinski okrug",
    "value": "RS-19",
    "parent": "RS"
  },
  "RS-18": {
    "label": "Raski okrug",
    "value": "RS-18",
    "parent": "RS"
  },
  "RS-01": {
    "label": "Severnobacki okrug",
    "value": "RS-01",
    "parent": "RS"
  },
  "RS-03": {
    "label": "Severnobanatski okrug",
    "value": "RS-03",
    "parent": "RS"
  },
  "RS-02": {
    "label": "Srednjebanatski okrug",
    "value": "RS-02",
    "parent": "RS"
  },
  "RS-07": {
    "label": "Sremski okrug",
    "value": "RS-07",
    "parent": "RS"
  },
  "RS-12": {
    "label": "Sumadijski okrug",
    "value": "RS-12",
    "parent": "RS"
  },
  "RS-21": {
    "label": "Toplicki okrug",
    "value": "RS-21",
    "parent": "RS"
  },
  "RS-15": {
    "label": "Zajecarski okrug",
    "value": "RS-15",
    "parent": "RS"
  },
  "RS-05": {
    "label": "Zapadnobacki okrug",
    "value": "RS-05",
    "parent": "RS"
  },
  "RS-16": {
    "label": "Zlatiborski okrug",
    "value": "RS-16",
    "parent": "RS"
  },
  "SC": {
    "label": "Seychelles",
    "value": "SC"
  },
  "SC-01": {
    "label": "Anse aux Pins",
    "value": "SC-01",
    "parent": "SC"
  },
  "SC-02": {
    "label": "Anse Boileau",
    "value": "SC-02",
    "parent": "SC"
  },
  "SC-05": {
    "label": "Anse Royale",
    "value": "SC-05",
    "parent": "SC"
  },
  "SC-06": {
    "label": "Baie Lazare",
    "value": "SC-06",
    "parent": "SC"
  },
  "SC-07": {
    "label": "Baie Sainte Anne",
    "value": "SC-07",
    "parent": "SC"
  },
  "SC-08": {
    "label": "Beau Vallon",
    "value": "SC-08",
    "parent": "SC"
  },
  "SC-10": {
    "label": "Bel Ombre",
    "value": "SC-10",
    "parent": "SC"
  },
  "SC-11": {
    "label": "Cascade",
    "value": "SC-11",
    "parent": "SC"
  },
  "SC-16": {
    "label": "English River",
    "value": "SC-16",
    "parent": "SC"
  },
  "SC-13": {
    "label": "Grand Anse Mahe",
    "value": "SC-13",
    "parent": "SC"
  },
  "SC-14": {
    "label": "Grand Anse Praslin",
    "value": "SC-14",
    "parent": "SC"
  },
  "SC-15": {
    "label": "La Digue",
    "value": "SC-15",
    "parent": "SC"
  },
  "SC-20": {
    "label": "Pointe Larue",
    "value": "SC-20",
    "parent": "SC"
  },
  "SC-23": {
    "label": "Takamaka",
    "value": "SC-23",
    "parent": "SC"
  },
  "SL": {
    "label": "Sierra Leone",
    "value": "SL"
  },
  "SL-E": {
    "label": "Eastern",
    "value": "SL-E",
    "parent": "SL"
  },
  "SL-NW": {
    "label": "North Western",
    "value": "SL-NW",
    "parent": "SL"
  },
  "SL-N": {
    "label": "Northern",
    "value": "SL-N",
    "parent": "SL"
  },
  "SL-S": {
    "label": "Southern",
    "value": "SL-S",
    "parent": "SL"
  },
  "SL-W": {
    "label": "Western Area",
    "value": "SL-W",
    "parent": "SL"
  },
  "SG": {
    "label": "Singapore",
    "value": "SG"
  },
  "SX": {
    "label": "Sint Maarten (Dutch part)",
    "value": "SX"
  },
  "SK": {
    "label": "Slovakia",
    "value": "SK"
  },
  "SK-BC": {
    "label": "Banskobystricky kraj",
    "value": "SK-BC",
    "parent": "SK"
  },
  "SK-BL": {
    "label": "Bratislavsky kraj",
    "value": "SK-BL",
    "parent": "SK"
  },
  "SK-KI": {
    "label": "Kosicky kraj",
    "value": "SK-KI",
    "parent": "SK"
  },
  "SK-NI": {
    "label": "Nitriansky kraj",
    "value": "SK-NI",
    "parent": "SK"
  },
  "SK-PV": {
    "label": "Presovsky kraj",
    "value": "SK-PV",
    "parent": "SK"
  },
  "SK-TC": {
    "label": "Trenciansky kraj",
    "value": "SK-TC",
    "parent": "SK"
  },
  "SK-TA": {
    "label": "Trnavsky kraj",
    "value": "SK-TA",
    "parent": "SK"
  },
  "SK-ZI": {
    "label": "Zilinsky kraj",
    "value": "SK-ZI",
    "parent": "SK"
  },
  "SI": {
    "label": "Slovenia",
    "value": "SI"
  },
  "SI-001": {
    "label": "Ajdovscina",
    "value": "SI-001",
    "parent": "SI"
  },
  "SI-213": {
    "label": "Ankaran",
    "value": "SI-213",
    "parent": "SI"
  },
  "SI-195": {
    "label": "Apace",
    "value": "SI-195",
    "parent": "SI"
  },
  "SI-002": {
    "label": "Beltinci",
    "value": "SI-002",
    "parent": "SI"
  },
  "SI-148": {
    "label": "Benedikt",
    "value": "SI-148",
    "parent": "SI"
  },
  "SI-149": {
    "label": "Bistrica ob Sotli",
    "value": "SI-149",
    "parent": "SI"
  },
  "SI-003": {
    "label": "Bled",
    "value": "SI-003",
    "parent": "SI"
  },
  "SI-150": {
    "label": "Bloke",
    "value": "SI-150",
    "parent": "SI"
  },
  "SI-004": {
    "label": "Bohinj",
    "value": "SI-004",
    "parent": "SI"
  },
  "SI-005": {
    "label": "Borovnica",
    "value": "SI-005",
    "parent": "SI"
  },
  "SI-006": {
    "label": "Bovec",
    "value": "SI-006",
    "parent": "SI"
  },
  "SI-151": {
    "label": "Braslovce",
    "value": "SI-151",
    "parent": "SI"
  },
  "SI-007": {
    "label": "Brda",
    "value": "SI-007",
    "parent": "SI"
  },
  "SI-009": {
    "label": "Brezice",
    "value": "SI-009",
    "parent": "SI"
  },
  "SI-008": {
    "label": "Brezovica",
    "value": "SI-008",
    "parent": "SI"
  },
  "SI-152": {
    "label": "Cankova",
    "value": "SI-152",
    "parent": "SI"
  },
  "SI-011": {
    "label": "Celje",
    "value": "SI-011",
    "parent": "SI"
  },
  "SI-012": {
    "label": "Cerklje na Gorenjskem",
    "value": "SI-012",
    "parent": "SI"
  },
  "SI-013": {
    "label": "Cerknica",
    "value": "SI-013",
    "parent": "SI"
  },
  "SI-014": {
    "label": "Cerkno",
    "value": "SI-014",
    "parent": "SI"
  },
  "SI-196": {
    "label": "Cirkulane",
    "value": "SI-196",
    "parent": "SI"
  },
  "SI-015": {
    "label": "Crensovci",
    "value": "SI-015",
    "parent": "SI"
  },
  "SI-017": {
    "label": "Crnomelj",
    "value": "SI-017",
    "parent": "SI"
  },
  "SI-018": {
    "label": "Destrnik",
    "value": "SI-018",
    "parent": "SI"
  },
  "SI-019": {
    "label": "Divaca",
    "value": "SI-019",
    "parent": "SI"
  },
  "SI-154": {
    "label": "Dobje",
    "value": "SI-154",
    "parent": "SI"
  },
  "SI-020": {
    "label": "Dobrepolje",
    "value": "SI-020",
    "parent": "SI"
  },
  "SI-155": {
    "label": "Dobrna",
    "value": "SI-155",
    "parent": "SI"
  },
  "SI-021": {
    "label": "Dobrova-Polhov Gradec",
    "value": "SI-021",
    "parent": "SI"
  },
  "SI-156": {
    "label": "Dobrovnik",
    "value": "SI-156",
    "parent": "SI"
  },
  "SI-023": {
    "label": "Domzale",
    "value": "SI-023",
    "parent": "SI"
  },
  "SI-024": {
    "label": "Dornava",
    "value": "SI-024",
    "parent": "SI"
  },
  "SI-025": {
    "label": "Dravograd",
    "value": "SI-025",
    "parent": "SI"
  },
  "SI-026": {
    "label": "Duplek",
    "value": "SI-026",
    "parent": "SI"
  },
  "SI-207": {
    "label": "Gorje",
    "value": "SI-207",
    "parent": "SI"
  },
  "SI-029": {
    "label": "Gornja Radgona",
    "value": "SI-029",
    "parent": "SI"
  },
  "SI-031": {
    "label": "Gornji Petrovci",
    "value": "SI-031",
    "parent": "SI"
  },
  "SI-158": {
    "label": "Grad",
    "value": "SI-158",
    "parent": "SI"
  },
  "SI-032": {
    "label": "Grosuplje",
    "value": "SI-032",
    "parent": "SI"
  },
  "SI-159": {
    "label": "Hajdina",
    "value": "SI-159",
    "parent": "SI"
  },
  "SI-160": {
    "label": "Hoce-Slivnica",
    "value": "SI-160",
    "parent": "SI"
  },
  "SI-161": {
    "label": "Hodos",
    "value": "SI-161",
    "parent": "SI"
  },
  "SI-162": {
    "label": "Horjul",
    "value": "SI-162",
    "parent": "SI"
  },
  "SI-034": {
    "label": "Hrastnik",
    "value": "SI-034",
    "parent": "SI"
  },
  "SI-035": {
    "label": "Hrpelje-Kozina",
    "value": "SI-035",
    "parent": "SI"
  },
  "SI-036": {
    "label": "Idrija",
    "value": "SI-036",
    "parent": "SI"
  },
  "SI-037": {
    "label": "Ig",
    "value": "SI-037",
    "parent": "SI"
  },
  "SI-038": {
    "label": "Ilirska Bistrica",
    "value": "SI-038",
    "parent": "SI"
  },
  "SI-039": {
    "label": "Ivancna Gorica",
    "value": "SI-039",
    "parent": "SI"
  },
  "SI-040": {
    "label": "Izola",
    "value": "SI-040",
    "parent": "SI"
  },
  "SI-041": {
    "label": "Jesenice",
    "value": "SI-041",
    "parent": "SI"
  },
  "SI-042": {
    "label": "Jursinci",
    "value": "SI-042",
    "parent": "SI"
  },
  "SI-043": {
    "label": "Kamnik",
    "value": "SI-043",
    "parent": "SI"
  },
  "SI-044": {
    "label": "Kanal",
    "value": "SI-044",
    "parent": "SI"
  },
  "SI-045": {
    "label": "Kidricevo",
    "value": "SI-045",
    "parent": "SI"
  },
  "SI-046": {
    "label": "Kobarid",
    "value": "SI-046",
    "parent": "SI"
  },
  "SI-047": {
    "label": "Kobilje",
    "value": "SI-047",
    "parent": "SI"
  },
  "SI-048": {
    "label": "Kocevje",
    "value": "SI-048",
    "parent": "SI"
  },
  "SI-049": {
    "label": "Komen",
    "value": "SI-049",
    "parent": "SI"
  },
  "SI-164": {
    "label": "Komenda",
    "value": "SI-164",
    "parent": "SI"
  },
  "SI-050": {
    "label": "Koper",
    "value": "SI-050",
    "parent": "SI"
  },
  "SI-197": {
    "label": "Kosanjevica na Krki",
    "value": "SI-197",
    "parent": "SI"
  },
  "SI-165": {
    "label": "Kostel",
    "value": "SI-165",
    "parent": "SI"
  },
  "SI-052": {
    "label": "Kranj",
    "value": "SI-052",
    "parent": "SI"
  },
  "SI-053": {
    "label": "Kranjska Gora",
    "value": "SI-053",
    "parent": "SI"
  },
  "SI-166": {
    "label": "Krizevci",
    "value": "SI-166",
    "parent": "SI"
  },
  "SI-054": {
    "label": "Krsko",
    "value": "SI-054",
    "parent": "SI"
  },
  "SI-055": {
    "label": "Kungota",
    "value": "SI-055",
    "parent": "SI"
  },
  "SI-056": {
    "label": "Kuzma",
    "value": "SI-056",
    "parent": "SI"
  },
  "SI-057": {
    "label": "Lasko",
    "value": "SI-057",
    "parent": "SI"
  },
  "SI-058": {
    "label": "Lenart",
    "value": "SI-058",
    "parent": "SI"
  },
  "SI-059": {
    "label": "Lendava",
    "value": "SI-059",
    "parent": "SI"
  },
  "SI-060": {
    "label": "Litija",
    "value": "SI-060",
    "parent": "SI"
  },
  "SI-061": {
    "label": "Ljubljana",
    "value": "SI-061",
    "parent": "SI"
  },
  "SI-063": {
    "label": "Ljutomer",
    "value": "SI-063",
    "parent": "SI"
  },
  "SI-208": {
    "label": "Log-Dragomer",
    "value": "SI-208",
    "parent": "SI"
  },
  "SI-064": {
    "label": "Logatec",
    "value": "SI-064",
    "parent": "SI"
  },
  "SI-065": {
    "label": "Loska dolina",
    "value": "SI-065",
    "parent": "SI"
  },
  "SI-066": {
    "label": "Loski Potok",
    "value": "SI-066",
    "parent": "SI"
  },
  "SI-167": {
    "label": "Lovrenc na Pohorju",
    "value": "SI-167",
    "parent": "SI"
  },
  "SI-067": {
    "label": "Luce",
    "value": "SI-067",
    "parent": "SI"
  },
  "SI-068": {
    "label": "Lukovica",
    "value": "SI-068",
    "parent": "SI"
  },
  "SI-069": {
    "label": "Majsperk",
    "value": "SI-069",
    "parent": "SI"
  },
  "SI-198": {
    "label": "Makole",
    "value": "SI-198",
    "parent": "SI"
  },
  "SI-070": {
    "label": "Maribor",
    "value": "SI-070",
    "parent": "SI"
  },
  "SI-168": {
    "label": "Markovci",
    "value": "SI-168",
    "parent": "SI"
  },
  "SI-071": {
    "label": "Medvode",
    "value": "SI-071",
    "parent": "SI"
  },
  "SI-072": {
    "label": "Menges",
    "value": "SI-072",
    "parent": "SI"
  },
  "SI-073": {
    "label": "Metlika",
    "value": "SI-073",
    "parent": "SI"
  },
  "SI-074": {
    "label": "Mezica",
    "value": "SI-074",
    "parent": "SI"
  },
  "SI-169": {
    "label": "Miklavz na Dravskem polju",
    "value": "SI-169",
    "parent": "SI"
  },
  "SI-075": {
    "label": "Miren-Kostanjevica",
    "value": "SI-075",
    "parent": "SI"
  },
  "SI-212": {
    "label": "Mirna",
    "value": "SI-212",
    "parent": "SI"
  },
  "SI-170": {
    "label": "Mirna Pec",
    "value": "SI-170",
    "parent": "SI"
  },
  "SI-076": {
    "label": "Mislinja",
    "value": "SI-076",
    "parent": "SI"
  },
  "SI-199": {
    "label": "Mokronog-Trebelno",
    "value": "SI-199",
    "parent": "SI"
  },
  "SI-077": {
    "label": "Moravce",
    "value": "SI-077",
    "parent": "SI"
  },
  "SI-079": {
    "label": "Mozirje",
    "value": "SI-079",
    "parent": "SI"
  },
  "SI-080": {
    "label": "Murska Sobota",
    "value": "SI-080",
    "parent": "SI"
  },
  "SI-081": {
    "label": "Muta",
    "value": "SI-081",
    "parent": "SI"
  },
  "SI-082": {
    "label": "Naklo",
    "value": "SI-082",
    "parent": "SI"
  },
  "SI-083": {
    "label": "Nazarje",
    "value": "SI-083",
    "parent": "SI"
  },
  "SI-084": {
    "label": "Nova Gorica",
    "value": "SI-084",
    "parent": "SI"
  },
  "SI-085": {
    "label": "Novo Mesto",
    "value": "SI-085",
    "parent": "SI"
  },
  "SI-086": {
    "label": "Odranci",
    "value": "SI-086",
    "parent": "SI"
  },
  "SI-171": {
    "label": "Oplotnica",
    "value": "SI-171",
    "parent": "SI"
  },
  "SI-087": {
    "label": "Ormoz",
    "value": "SI-087",
    "parent": "SI"
  },
  "SI-090": {
    "label": "Piran",
    "value": "SI-090",
    "parent": "SI"
  },
  "SI-091": {
    "label": "Pivka",
    "value": "SI-091",
    "parent": "SI"
  },
  "SI-092": {
    "label": "Podcetrtek",
    "value": "SI-092",
    "parent": "SI"
  },
  "SI-172": {
    "label": "Podlehnik",
    "value": "SI-172",
    "parent": "SI"
  },
  "SI-200": {
    "label": "Poljcane",
    "value": "SI-200",
    "parent": "SI"
  },
  "SI-173": {
    "label": "Polzela",
    "value": "SI-173",
    "parent": "SI"
  },
  "SI-094": {
    "label": "Postojna",
    "value": "SI-094",
    "parent": "SI"
  },
  "SI-174": {
    "label": "Prebold",
    "value": "SI-174",
    "parent": "SI"
  },
  "SI-095": {
    "label": "Preddvor",
    "value": "SI-095",
    "parent": "SI"
  },
  "SI-175": {
    "label": "Prevalje",
    "value": "SI-175",
    "parent": "SI"
  },
  "SI-096": {
    "label": "Ptuj",
    "value": "SI-096",
    "parent": "SI"
  },
  "SI-097": {
    "label": "Puconci",
    "value": "SI-097",
    "parent": "SI"
  },
  "SI-098": {
    "label": "Race-Fram",
    "value": "SI-098",
    "parent": "SI"
  },
  "SI-099": {
    "label": "Radece",
    "value": "SI-099",
    "parent": "SI"
  },
  "SI-100": {
    "label": "Radenci",
    "value": "SI-100",
    "parent": "SI"
  },
  "SI-101": {
    "label": "Radlje ob Dravi",
    "value": "SI-101",
    "parent": "SI"
  },
  "SI-102": {
    "label": "Radovljica",
    "value": "SI-102",
    "parent": "SI"
  },
  "SI-103": {
    "label": "Ravne na Koroskem",
    "value": "SI-103",
    "parent": "SI"
  },
  "SI-176": {
    "label": "Razkrizje",
    "value": "SI-176",
    "parent": "SI"
  },
  "SI-209": {
    "label": "Recica ob Savinji",
    "value": "SI-209",
    "parent": "SI"
  },
  "SI-201": {
    "label": "Rence-Vogrsko",
    "value": "SI-201",
    "parent": "SI"
  },
  "SI-104": {
    "label": "Ribnica",
    "value": "SI-104",
    "parent": "SI"
  },
  "SI-106": {
    "label": "Rogaska Slatina",
    "value": "SI-106",
    "parent": "SI"
  },
  "SI-105": {
    "label": "Rogasovci",
    "value": "SI-105",
    "parent": "SI"
  },
  "SI-108": {
    "label": "Ruse",
    "value": "SI-108",
    "parent": "SI"
  },
  "SI-033": {
    "label": "Salovci",
    "value": "SI-033",
    "parent": "SI"
  },
  "SI-109": {
    "label": "Semic",
    "value": "SI-109",
    "parent": "SI"
  },
  "SI-183": {
    "label": "Sempeter-Vrtojba",
    "value": "SI-183",
    "parent": "SI"
  },
  "SI-117": {
    "label": "Sencur",
    "value": "SI-117",
    "parent": "SI"
  },
  "SI-118": {
    "label": "Sentilj",
    "value": "SI-118",
    "parent": "SI"
  },
  "SI-119": {
    "label": "Sentjernej",
    "value": "SI-119",
    "parent": "SI"
  },
  "SI-120": {
    "label": "Sentjur",
    "value": "SI-120",
    "parent": "SI"
  },
  "SI-211": {
    "label": "Sentrupert",
    "value": "SI-211",
    "parent": "SI"
  },
  "SI-110": {
    "label": "Sevnica",
    "value": "SI-110",
    "parent": "SI"
  },
  "SI-111": {
    "label": "Sezana",
    "value": "SI-111",
    "parent": "SI"
  },
  "SI-121": {
    "label": "Skocjan",
    "value": "SI-121",
    "parent": "SI"
  },
  "SI-122": {
    "label": "Skofja Loka",
    "value": "SI-122",
    "parent": "SI"
  },
  "SI-123": {
    "label": "Skofljica",
    "value": "SI-123",
    "parent": "SI"
  },
  "SI-112": {
    "label": "Slovenj Gradec",
    "value": "SI-112",
    "parent": "SI"
  },
  "SI-113": {
    "label": "Slovenska Bistrica",
    "value": "SI-113",
    "parent": "SI"
  },
  "SI-114": {
    "label": "Slovenske Konjice",
    "value": "SI-114",
    "parent": "SI"
  },
  "SI-124": {
    "label": "Smarje pri Jelsah",
    "value": "SI-124",
    "parent": "SI"
  },
  "SI-206": {
    "label": "Smarjeske Toplice",
    "value": "SI-206",
    "parent": "SI"
  },
  "SI-125": {
    "label": "Smartno ob Paki",
    "value": "SI-125",
    "parent": "SI"
  },
  "SI-194": {
    "label": "Smartno pri Litiji",
    "value": "SI-194",
    "parent": "SI"
  },
  "SI-179": {
    "label": "Sodrazica",
    "value": "SI-179",
    "parent": "SI"
  },
  "SI-180": {
    "label": "Solcava",
    "value": "SI-180",
    "parent": "SI"
  },
  "SI-126": {
    "label": "Sostanj",
    "value": "SI-126",
    "parent": "SI"
  },
  "SI-115": {
    "label": "Starse",
    "value": "SI-115",
    "parent": "SI"
  },
  "SI-127": {
    "label": "Store",
    "value": "SI-127",
    "parent": "SI"
  },
  "SI-203": {
    "label": "Straza",
    "value": "SI-203",
    "parent": "SI"
  },
  "SI-204": {
    "label": "Sveta Trojica v Slovenskih goricah",
    "value": "SI-204",
    "parent": "SI"
  },
  "SI-182": {
    "label": "Sveti Andraz v Slovenskih Goricah",
    "value": "SI-182",
    "parent": "SI"
  },
  "SI-116": {
    "label": "Sveti Jurij ob Scavnici",
    "value": "SI-116",
    "parent": "SI"
  },
  "SI-210": {
    "label": "Sveti Jurij v Slovenskih goricah",
    "value": "SI-210",
    "parent": "SI"
  },
  "SI-205": {
    "label": "Sveti Tomaz",
    "value": "SI-205",
    "parent": "SI"
  },
  "SI-184": {
    "label": "Tabor",
    "value": "SI-184",
    "parent": "SI"
  },
  "SI-010": {
    "label": "Tisina",
    "value": "SI-010",
    "parent": "SI"
  },
  "SI-128": {
    "label": "Tolmin",
    "value": "SI-128",
    "parent": "SI"
  },
  "SI-129": {
    "label": "Trbovlje",
    "value": "SI-129",
    "parent": "SI"
  },
  "SI-130": {
    "label": "Trebnje",
    "value": "SI-130",
    "parent": "SI"
  },
  "SI-185": {
    "label": "Trnovska Vas",
    "value": "SI-185",
    "parent": "SI"
  },
  "SI-131": {
    "label": "Trzic",
    "value": "SI-131",
    "parent": "SI"
  },
  "SI-186": {
    "label": "Trzin",
    "value": "SI-186",
    "parent": "SI"
  },
  "SI-132": {
    "label": "Turnisce",
    "value": "SI-132",
    "parent": "SI"
  },
  "SI-133": {
    "label": "Velenje",
    "value": "SI-133",
    "parent": "SI"
  },
  "SI-187": {
    "label": "Velika Polana",
    "value": "SI-187",
    "parent": "SI"
  },
  "SI-134": {
    "label": "Velike Lasce",
    "value": "SI-134",
    "parent": "SI"
  },
  "SI-188": {
    "label": "Verzej",
    "value": "SI-188",
    "parent": "SI"
  },
  "SI-135": {
    "label": "Videm",
    "value": "SI-135",
    "parent": "SI"
  },
  "SI-136": {
    "label": "Vipava",
    "value": "SI-136",
    "parent": "SI"
  },
  "SI-137": {
    "label": "Vitanje",
    "value": "SI-137",
    "parent": "SI"
  },
  "SI-138": {
    "label": "Vodice",
    "value": "SI-138",
    "parent": "SI"
  },
  "SI-139": {
    "label": "Vojnik",
    "value": "SI-139",
    "parent": "SI"
  },
  "SI-189": {
    "label": "Vransko",
    "value": "SI-189",
    "parent": "SI"
  },
  "SI-140": {
    "label": "Vrhnika",
    "value": "SI-140",
    "parent": "SI"
  },
  "SI-141": {
    "label": "Vuzenica",
    "value": "SI-141",
    "parent": "SI"
  },
  "SI-142": {
    "label": "Zagorje ob Savi",
    "value": "SI-142",
    "parent": "SI"
  },
  "SI-190": {
    "label": "Zalec",
    "value": "SI-190",
    "parent": "SI"
  },
  "SI-143": {
    "label": "Zavrc",
    "value": "SI-143",
    "parent": "SI"
  },
  "SI-146": {
    "label": "Zelezniki",
    "value": "SI-146",
    "parent": "SI"
  },
  "SI-191": {
    "label": "Zetale",
    "value": "SI-191",
    "parent": "SI"
  },
  "SI-147": {
    "label": "Ziri",
    "value": "SI-147",
    "parent": "SI"
  },
  "SI-144": {
    "label": "Zrece",
    "value": "SI-144",
    "parent": "SI"
  },
  "SI-193": {
    "label": "Zuzemberk",
    "value": "SI-193",
    "parent": "SI"
  },
  "SB": {
    "label": "Solomon Islands",
    "value": "SB"
  },
  "SB-GU": {
    "label": "Guadalcanal",
    "value": "SB-GU",
    "parent": "SB"
  },
  "SB-MK": {
    "label": "Makira-Ulawa",
    "value": "SB-MK",
    "parent": "SB"
  },
  "SB-WE": {
    "label": "Western",
    "value": "SB-WE",
    "parent": "SB"
  },
  "SO": {
    "label": "Somalia",
    "value": "SO"
  },
  "SO-AW": {
    "label": "Awdal",
    "value": "SO-AW",
    "parent": "SO"
  },
  "SO-BN": {
    "label": "Banaadir",
    "value": "SO-BN",
    "parent": "SO"
  },
  "SO-BR": {
    "label": "Bari",
    "value": "SO-BR",
    "parent": "SO"
  },
  "SO-BY": {
    "label": "Bay",
    "value": "SO-BY",
    "parent": "SO"
  },
  "SO-GA": {
    "label": "Galguduud",
    "value": "SO-GA",
    "parent": "SO"
  },
  "SO-HI": {
    "label": "Hiiraan",
    "value": "SO-HI",
    "parent": "SO"
  },
  "SO-JH": {
    "label": "Jubbada Hoose",
    "value": "SO-JH",
    "parent": "SO"
  },
  "SO-MU": {
    "label": "Mudug",
    "value": "SO-MU",
    "parent": "SO"
  },
  "SO-NU": {
    "label": "Nugaal",
    "value": "SO-NU",
    "parent": "SO"
  },
  "SO-SA": {
    "label": "Sanaag",
    "value": "SO-SA",
    "parent": "SO"
  },
  "SO-SO": {
    "label": "Sool",
    "value": "SO-SO",
    "parent": "SO"
  },
  "SO-TO": {
    "label": "Togdheer",
    "value": "SO-TO",
    "parent": "SO"
  },
  "SO-WO": {
    "label": "Woqooyi Galbeed",
    "value": "SO-WO",
    "parent": "SO"
  },
  "ZA": {
    "label": "South Africa",
    "value": "ZA"
  },
  "ZA-EC": {
    "label": "Eastern Cape",
    "value": "ZA-EC",
    "parent": "ZA"
  },
  "ZA-FS": {
    "label": "Free State",
    "value": "ZA-FS",
    "parent": "ZA"
  },
  "ZA-GP": {
    "label": "Gauteng",
    "value": "ZA-GP",
    "parent": "ZA"
  },
  "ZA-KZN": {
    "label": "Kwazulu-Natal",
    "value": "ZA-KZN",
    "parent": "ZA"
  },
  "ZA-LP": {
    "label": "Limpopo",
    "value": "ZA-LP",
    "parent": "ZA"
  },
  "ZA-MP": {
    "label": "Mpumalanga",
    "value": "ZA-MP",
    "parent": "ZA"
  },
  "ZA-NW": {
    "label": "North-West",
    "value": "ZA-NW",
    "parent": "ZA"
  },
  "ZA-NC": {
    "label": "Northern Cape",
    "value": "ZA-NC",
    "parent": "ZA"
  },
  "ZA-WC": {
    "label": "Western Cape",
    "value": "ZA-WC",
    "parent": "ZA"
  },
  "GS": {
    "label": "South Georgia and the South Sandwich Islands",
    "value": "GS"
  },
  "SS": {
    "label": "South Sudan",
    "value": "SS"
  },
  "SS-EC": {
    "label": "Central Equatoria",
    "value": "SS-EC",
    "parent": "SS"
  },
  "SS-EE": {
    "label": "Eastern Equatoria",
    "value": "SS-EE",
    "parent": "SS"
  },
  "SS-JG": {
    "label": "Jonglei",
    "value": "SS-JG",
    "parent": "SS"
  },
  "SS-BN": {
    "label": "Northern Bahr el Ghazal",
    "value": "SS-BN",
    "parent": "SS"
  },
  "SS-UY": {
    "label": "Unity",
    "value": "SS-UY",
    "parent": "SS"
  },
  "SS-NU": {
    "label": "Upper Nile",
    "value": "SS-NU",
    "parent": "SS"
  },
  "SS-WR": {
    "label": "Warrap",
    "value": "SS-WR",
    "parent": "SS"
  },
  "SS-BW": {
    "label": "Western Bahr el Ghazal",
    "value": "SS-BW",
    "parent": "SS"
  },
  "SS-EW": {
    "label": "Western Equatoria",
    "value": "SS-EW",
    "parent": "SS"
  },
  "ES": {
    "label": "Spain",
    "value": "ES"
  },
  "ES-AN": {
    "label": "Andalucia",
    "value": "ES-AN",
    "parent": "ES"
  },
  "ES-AR": {
    "label": "Aragon",
    "value": "ES-AR",
    "parent": "ES"
  },
  "ES-AS": {
    "label": "Asturias, Principado de",
    "value": "ES-AS",
    "parent": "ES"
  },
  "ES-CN": {
    "label": "Canarias",
    "value": "ES-CN",
    "parent": "ES"
  },
  "ES-CB": {
    "label": "Cantabria",
    "value": "ES-CB",
    "parent": "ES"
  },
  "ES-CL": {
    "label": "Castilla y Leon",
    "value": "ES-CL",
    "parent": "ES"
  },
  "ES-CM": {
    "label": "Castilla-La Mancha",
    "value": "ES-CM",
    "parent": "ES"
  },
  "ES-CT": {
    "label": "Catalunya",
    "value": "ES-CT",
    "parent": "ES"
  },
  "ES-CE": {
    "label": "Ceuta",
    "value": "ES-CE",
    "parent": "ES"
  },
  "ES-EX": {
    "label": "Extremadura",
    "value": "ES-EX",
    "parent": "ES"
  },
  "ES-GA": {
    "label": "Galicia",
    "value": "ES-GA",
    "parent": "ES"
  },
  "ES-IB": {
    "label": "Illes Balears",
    "value": "ES-IB",
    "parent": "ES"
  },
  "ES-RI": {
    "label": "La Rioja",
    "value": "ES-RI",
    "parent": "ES"
  },
  "ES-MD": {
    "label": "Madrid, Comunidad de",
    "value": "ES-MD",
    "parent": "ES"
  },
  "ES-ML": {
    "label": "Melilla",
    "value": "ES-ML",
    "parent": "ES"
  },
  "ES-MC": {
    "label": "Murcia, Region de",
    "value": "ES-MC",
    "parent": "ES"
  },
  "ES-NC": {
    "label": "Navarra, Comunidad Foral de",
    "value": "ES-NC",
    "parent": "ES"
  },
  "ES-PV": {
    "label": "Pais Vasco",
    "value": "ES-PV",
    "parent": "ES"
  },
  "ES-VC": {
    "label": "Valenciana, Comunidad",
    "value": "ES-VC",
    "parent": "ES"
  },
  "LK": {
    "label": "Sri Lanka",
    "value": "LK"
  },
  "LK-2": {
    "label": "Central Province",
    "value": "LK-2",
    "parent": "LK"
  },
  "LK-5": {
    "label": "Eastern Province",
    "value": "LK-5",
    "parent": "LK"
  },
  "LK-7": {
    "label": "North Central Province",
    "value": "LK-7",
    "parent": "LK"
  },
  "LK-6": {
    "label": "North Western Province",
    "value": "LK-6",
    "parent": "LK"
  },
  "LK-4": {
    "label": "Northern Province",
    "value": "LK-4",
    "parent": "LK"
  },
  "LK-9": {
    "label": "Sabaragamuwa Province",
    "value": "LK-9",
    "parent": "LK"
  },
  "LK-3": {
    "label": "Southern Province",
    "value": "LK-3",
    "parent": "LK"
  },
  "LK-8": {
    "label": "Uva Province",
    "value": "LK-8",
    "parent": "LK"
  },
  "LK-1": {
    "label": "Western Province",
    "value": "LK-1",
    "parent": "LK"
  },
  "SD": {
    "label": "Sudan",
    "value": "SD"
  },
  "SD-NB": {
    "label": "Blue Nile",
    "value": "SD-NB",
    "parent": "SD"
  },
  "SD-DC": {
    "label": "Central Darfur",
    "value": "SD-DC",
    "parent": "SD"
  },
  "SD-GD": {
    "label": "Gedaref",
    "value": "SD-GD",
    "parent": "SD"
  },
  "SD-GZ": {
    "label": "Gezira",
    "value": "SD-GZ",
    "parent": "SD"
  },
  "SD-KA": {
    "label": "Kassala",
    "value": "SD-KA",
    "parent": "SD"
  },
  "SD-KH": {
    "label": "Khartoum",
    "value": "SD-KH",
    "parent": "SD"
  },
  "SD-DN": {
    "label": "North Darfur",
    "value": "SD-DN",
    "parent": "SD"
  },
  "SD-KN": {
    "label": "North Kordofan",
    "value": "SD-KN",
    "parent": "SD"
  },
  "SD-NO": {
    "label": "Northern",
    "value": "SD-NO",
    "parent": "SD"
  },
  "SD-RS": {
    "label": "Red Sea",
    "value": "SD-RS",
    "parent": "SD"
  },
  "SD-NR": {
    "label": "River Nile",
    "value": "SD-NR",
    "parent": "SD"
  },
  "SD-SI": {
    "label": "Sennar",
    "value": "SD-SI",
    "parent": "SD"
  },
  "SD-DS": {
    "label": "South Darfur",
    "value": "SD-DS",
    "parent": "SD"
  },
  "SD-KS": {
    "label": "South Kordofan",
    "value": "SD-KS",
    "parent": "SD"
  },
  "SD-DW": {
    "label": "West Darfur",
    "value": "SD-DW",
    "parent": "SD"
  },
  "SD-GK": {
    "label": "West Kordofan",
    "value": "SD-GK",
    "parent": "SD"
  },
  "SD-NW": {
    "label": "White Nile",
    "value": "SD-NW",
    "parent": "SD"
  },
  "SR": {
    "label": "Suriname",
    "value": "SR"
  },
  "SR-BR": {
    "label": "Brokopondo",
    "value": "SR-BR",
    "parent": "SR"
  },
  "SR-CM": {
    "label": "Commewijne",
    "value": "SR-CM",
    "parent": "SR"
  },
  "SR-CR": {
    "label": "Coronie",
    "value": "SR-CR",
    "parent": "SR"
  },
  "SR-NI": {
    "label": "Nickerie",
    "value": "SR-NI",
    "parent": "SR"
  },
  "SR-PR": {
    "label": "Para",
    "value": "SR-PR",
    "parent": "SR"
  },
  "SR-PM": {
    "label": "Paramaribo",
    "value": "SR-PM",
    "parent": "SR"
  },
  "SR-SA": {
    "label": "Saramacca",
    "value": "SR-SA",
    "parent": "SR"
  },
  "SR-SI": {
    "label": "Sipaliwini",
    "value": "SR-SI",
    "parent": "SR"
  },
  "SR-WA": {
    "label": "Wanica",
    "value": "SR-WA",
    "parent": "SR"
  },
  "SJ": {
    "label": "Svalbard and Jan Mayen",
    "value": "SJ"
  },
  "SE": {
    "label": "Sweden",
    "value": "SE"
  },
  "SE-K": {
    "label": "Blekinge lan",
    "value": "SE-K",
    "parent": "SE"
  },
  "SE-W": {
    "label": "Dalarnas lan",
    "value": "SE-W",
    "parent": "SE"
  },
  "SE-X": {
    "label": "Gavleborgs lan",
    "value": "SE-X",
    "parent": "SE"
  },
  "SE-I": {
    "label": "Gotlands lan",
    "value": "SE-I",
    "parent": "SE"
  },
  "SE-N": {
    "label": "Hallands lan",
    "value": "SE-N",
    "parent": "SE"
  },
  "SE-Z": {
    "label": "Jamtlands lan",
    "value": "SE-Z",
    "parent": "SE"
  },
  "SE-F": {
    "label": "Jonkopings lan",
    "value": "SE-F",
    "parent": "SE"
  },
  "SE-H": {
    "label": "Kalmar lan",
    "value": "SE-H",
    "parent": "SE"
  },
  "SE-G": {
    "label": "Kronobergs lan",
    "value": "SE-G",
    "parent": "SE"
  },
  "SE-BD": {
    "label": "Norrbottens lan",
    "value": "SE-BD",
    "parent": "SE"
  },
  "SE-T": {
    "label": "Orebro lan",
    "value": "SE-T",
    "parent": "SE"
  },
  "SE-E": {
    "label": "Ostergotlands lan",
    "value": "SE-E",
    "parent": "SE"
  },
  "SE-M": {
    "label": "Skane lan",
    "value": "SE-M",
    "parent": "SE"
  },
  "SE-D": {
    "label": "Sodermanlands lan",
    "value": "SE-D",
    "parent": "SE"
  },
  "SE-AB": {
    "label": "Stockholms lan",
    "value": "SE-AB",
    "parent": "SE"
  },
  "SE-C": {
    "label": "Uppsala lan",
    "value": "SE-C",
    "parent": "SE"
  },
  "SE-S": {
    "label": "Varmlands lan",
    "value": "SE-S",
    "parent": "SE"
  },
  "SE-AC": {
    "label": "Vasterbottens lan",
    "value": "SE-AC",
    "parent": "SE"
  },
  "SE-Y": {
    "label": "Vasternorrlands lan",
    "value": "SE-Y",
    "parent": "SE"
  },
  "SE-U": {
    "label": "Vastmanlands lan",
    "value": "SE-U",
    "parent": "SE"
  },
  "SE-O": {
    "label": "Vastra Gotalands lan",
    "value": "SE-O",
    "parent": "SE"
  },
  "CH": {
    "label": "Switzerland",
    "value": "CH"
  },
  "CH-AG": {
    "label": "Aargau",
    "value": "CH-AG",
    "parent": "CH"
  },
  "CH-AR": {
    "label": "Appenzell Ausserrhoden",
    "value": "CH-AR",
    "parent": "CH"
  },
  "CH-AI": {
    "label": "Appenzell Innerrhoden",
    "value": "CH-AI",
    "parent": "CH"
  },
  "CH-BL": {
    "label": "Basel-Landschaft",
    "value": "CH-BL",
    "parent": "CH"
  },
  "CH-BS": {
    "label": "Basel-Stadt",
    "value": "CH-BS",
    "parent": "CH"
  },
  "CH-BE": {
    "label": "Bern",
    "value": "CH-BE",
    "parent": "CH"
  },
  "CH-FR": {
    "label": "Fribourg",
    "value": "CH-FR",
    "parent": "CH"
  },
  "CH-GE": {
    "label": "Geneve",
    "value": "CH-GE",
    "parent": "CH"
  },
  "CH-GL": {
    "label": "Glarus",
    "value": "CH-GL",
    "parent": "CH"
  },
  "CH-GR": {
    "label": "Graubunden",
    "value": "CH-GR",
    "parent": "CH"
  },
  "CH-JU": {
    "label": "Jura",
    "value": "CH-JU",
    "parent": "CH"
  },
  "CH-LU": {
    "label": "Luzern",
    "value": "CH-LU",
    "parent": "CH"
  },
  "CH-NE": {
    "label": "Neuchatel",
    "value": "CH-NE",
    "parent": "CH"
  },
  "CH-NW": {
    "label": "Nidwalden",
    "value": "CH-NW",
    "parent": "CH"
  },
  "CH-OW": {
    "label": "Obwalden",
    "value": "CH-OW",
    "parent": "CH"
  },
  "CH-SG": {
    "label": "Sankt Gallen",
    "value": "CH-SG",
    "parent": "CH"
  },
  "CH-SH": {
    "label": "Schaffhausen",
    "value": "CH-SH",
    "parent": "CH"
  },
  "CH-SZ": {
    "label": "Schwyz",
    "value": "CH-SZ",
    "parent": "CH"
  },
  "CH-SO": {
    "label": "Solothurn",
    "value": "CH-SO",
    "parent": "CH"
  },
  "CH-TG": {
    "label": "Thurgau",
    "value": "CH-TG",
    "parent": "CH"
  },
  "CH-TI": {
    "label": "Ticino",
    "value": "CH-TI",
    "parent": "CH"
  },
  "CH-UR": {
    "label": "Uri",
    "value": "CH-UR",
    "parent": "CH"
  },
  "CH-VS": {
    "label": "Valais",
    "value": "CH-VS",
    "parent": "CH"
  },
  "CH-VD": {
    "label": "Vaud",
    "value": "CH-VD",
    "parent": "CH"
  },
  "CH-ZG": {
    "label": "Zug",
    "value": "CH-ZG",
    "parent": "CH"
  },
  "CH-ZH": {
    "label": "Zurich",
    "value": "CH-ZH",
    "parent": "CH"
  },
  "SY": {
    "label": "Syrian Arab Republic",
    "value": "SY"
  },
  "SY-HA": {
    "label": "Al Hasakah",
    "value": "SY-HA",
    "parent": "SY"
  },
  "SY-LA": {
    "label": "Al Ladhiqiyah",
    "value": "SY-LA",
    "parent": "SY"
  },
  "SY-QU": {
    "label": "Al Qunaytirah",
    "value": "SY-QU",
    "parent": "SY"
  },
  "SY-RA": {
    "label": "Ar Raqqah",
    "value": "SY-RA",
    "parent": "SY"
  },
  "SY-SU": {
    "label": "As Suwayda'",
    "value": "SY-SU",
    "parent": "SY"
  },
  "SY-DR": {
    "label": "Dar'a",
    "value": "SY-DR",
    "parent": "SY"
  },
  "SY-DY": {
    "label": "Dayr az Zawr",
    "value": "SY-DY",
    "parent": "SY"
  },
  "SY-DI": {
    "label": "Dimashq",
    "value": "SY-DI",
    "parent": "SY"
  },
  "SY-HL": {
    "label": "Halab",
    "value": "SY-HL",
    "parent": "SY"
  },
  "SY-HM": {
    "label": "Hamah",
    "value": "SY-HM",
    "parent": "SY"
  },
  "SY-HI": {
    "label": "Hims",
    "value": "SY-HI",
    "parent": "SY"
  },
  "SY-ID": {
    "label": "Idlib",
    "value": "SY-ID",
    "parent": "SY"
  },
  "SY-RD": {
    "label": "Rif Dimashq",
    "value": "SY-RD",
    "parent": "SY"
  },
  "SY-TA": {
    "label": "Tartus",
    "value": "SY-TA",
    "parent": "SY"
  },
  "TW": {
    "label": "Taiwan, Province of China",
    "value": "TW"
  },
  "TW-CHA": {
    "label": "Changhua",
    "value": "TW-CHA",
    "parent": "TW"
  },
  "TW-CYQ": {
    "label": "Chiayi",
    "value": "TW-CYQ",
    "parent": "TW"
  },
  "TW-HSQ": {
    "label": "Hsinchu",
    "value": "TW-HSQ",
    "parent": "TW"
  },
  "TW-HUA": {
    "label": "Hualien",
    "value": "TW-HUA",
    "parent": "TW"
  },
  "TW-KHH": {
    "label": "Kaohsiung",
    "value": "TW-KHH",
    "parent": "TW"
  },
  "TW-KEE": {
    "label": "Keelung",
    "value": "TW-KEE",
    "parent": "TW"
  },
  "TW-KIN": {
    "label": "Kinmen",
    "value": "TW-KIN",
    "parent": "TW"
  },
  "TW-LIE": {
    "label": "Lienchiang",
    "value": "TW-LIE",
    "parent": "TW"
  },
  "TW-MIA": {
    "label": "Miaoli",
    "value": "TW-MIA",
    "parent": "TW"
  },
  "TW-NAN": {
    "label": "Nantou",
    "value": "TW-NAN",
    "parent": "TW"
  },
  "TW-NWT": {
    "label": "New Taipei",
    "value": "TW-NWT",
    "parent": "TW"
  },
  "TW-PEN": {
    "label": "Penghu",
    "value": "TW-PEN",
    "parent": "TW"
  },
  "TW-PIF": {
    "label": "Pingtung",
    "value": "TW-PIF",
    "parent": "TW"
  },
  "TW-TXG": {
    "label": "Taichung",
    "value": "TW-TXG",
    "parent": "TW"
  },
  "TW-TNN": {
    "label": "Tainan",
    "value": "TW-TNN",
    "parent": "TW"
  },
  "TW-TPE": {
    "label": "Taipei",
    "value": "TW-TPE",
    "parent": "TW"
  },
  "TW-TTT": {
    "label": "Taitung",
    "value": "TW-TTT",
    "parent": "TW"
  },
  "TW-TAO": {
    "label": "Taoyuan",
    "value": "TW-TAO",
    "parent": "TW"
  },
  "TW-ILA": {
    "label": "Yilan",
    "value": "TW-ILA",
    "parent": "TW"
  },
  "TW-YUN": {
    "label": "Yunlin",
    "value": "TW-YUN",
    "parent": "TW"
  },
  "TJ": {
    "label": "Tajikistan",
    "value": "TJ"
  },
  "TJ-DU": {
    "label": "Dushanbe",
    "value": "TJ-DU",
    "parent": "TJ"
  },
  "TJ-KT": {
    "label": "Khatlon",
    "value": "TJ-KT",
    "parent": "TJ"
  },
  "TJ-GB": {
    "label": "Kuhistoni Badakhshon",
    "value": "TJ-GB",
    "parent": "TJ"
  },
  "TJ-RA": {
    "label": "Nohiyahoi Tobei Jumhuri",
    "value": "TJ-RA",
    "parent": "TJ"
  },
  "TJ-SU": {
    "label": "Sughd",
    "value": "TJ-SU",
    "parent": "TJ"
  },
  "TZ": {
    "label": "Tanzania, United Republic of",
    "value": "TZ"
  },
  "TZ-01": {
    "label": "Arusha",
    "value": "TZ-01",
    "parent": "TZ"
  },
  "TZ-02": {
    "label": "Dar es Salaam",
    "value": "TZ-02",
    "parent": "TZ"
  },
  "TZ-03": {
    "label": "Dodoma",
    "value": "TZ-03",
    "parent": "TZ"
  },
  "TZ-27": {
    "label": "Geita",
    "value": "TZ-27",
    "parent": "TZ"
  },
  "TZ-04": {
    "label": "Iringa",
    "value": "TZ-04",
    "parent": "TZ"
  },
  "TZ-05": {
    "label": "Kagera",
    "value": "TZ-05",
    "parent": "TZ"
  },
  "TZ-06": {
    "label": "Kaskazini Pemba",
    "value": "TZ-06",
    "parent": "TZ"
  },
  "TZ-07": {
    "label": "Kaskazini Unguja",
    "value": "TZ-07",
    "parent": "TZ"
  },
  "TZ-28": {
    "label": "Katavi",
    "value": "TZ-28",
    "parent": "TZ"
  },
  "TZ-08": {
    "label": "Kigoma",
    "value": "TZ-08",
    "parent": "TZ"
  },
  "TZ-09": {
    "label": "Kilimanjaro",
    "value": "TZ-09",
    "parent": "TZ"
  },
  "TZ-10": {
    "label": "Kusini Pemba",
    "value": "TZ-10",
    "parent": "TZ"
  },
  "TZ-11": {
    "label": "Kusini Unguja",
    "value": "TZ-11",
    "parent": "TZ"
  },
  "TZ-12": {
    "label": "Lindi",
    "value": "TZ-12",
    "parent": "TZ"
  },
  "TZ-26": {
    "label": "Manyara",
    "value": "TZ-26",
    "parent": "TZ"
  },
  "TZ-13": {
    "label": "Mara",
    "value": "TZ-13",
    "parent": "TZ"
  },
  "TZ-14": {
    "label": "Mbeya",
    "value": "TZ-14",
    "parent": "TZ"
  },
  "TZ-15": {
    "label": "Mjini Magharibi",
    "value": "TZ-15",
    "parent": "TZ"
  },
  "TZ-16": {
    "label": "Morogoro",
    "value": "TZ-16",
    "parent": "TZ"
  },
  "TZ-17": {
    "label": "Mtwara",
    "value": "TZ-17",
    "parent": "TZ"
  },
  "TZ-18": {
    "label": "Mwanza",
    "value": "TZ-18",
    "parent": "TZ"
  },
  "TZ-29": {
    "label": "Njombe",
    "value": "TZ-29",
    "parent": "TZ"
  },
  "TZ-19": {
    "label": "Pwani",
    "value": "TZ-19",
    "parent": "TZ"
  },
  "TZ-20": {
    "label": "Rukwa",
    "value": "TZ-20",
    "parent": "TZ"
  },
  "TZ-21": {
    "label": "Ruvuma",
    "value": "TZ-21",
    "parent": "TZ"
  },
  "TZ-22": {
    "label": "Shinyanga",
    "value": "TZ-22",
    "parent": "TZ"
  },
  "TZ-30": {
    "label": "Simiyu",
    "value": "TZ-30",
    "parent": "TZ"
  },
  "TZ-23": {
    "label": "Singida",
    "value": "TZ-23",
    "parent": "TZ"
  },
  "TZ-31": {
    "label": "Songwe",
    "value": "TZ-31",
    "parent": "TZ"
  },
  "TZ-24": {
    "label": "Tabora",
    "value": "TZ-24",
    "parent": "TZ"
  },
  "TZ-25": {
    "label": "Tanga",
    "value": "TZ-25",
    "parent": "TZ"
  },
  "TH": {
    "label": "Thailand",
    "value": "TH"
  },
  "TH-37": {
    "label": "Amnat Charoen",
    "value": "TH-37",
    "parent": "TH"
  },
  "TH-15": {
    "label": "Ang Thong",
    "value": "TH-15",
    "parent": "TH"
  },
  "TH-38": {
    "label": "Bueng Kan",
    "value": "TH-38",
    "parent": "TH"
  },
  "TH-31": {
    "label": "Buri Ram",
    "value": "TH-31",
    "parent": "TH"
  },
  "TH-24": {
    "label": "Chachoengsao",
    "value": "TH-24",
    "parent": "TH"
  },
  "TH-18": {
    "label": "Chai Nat",
    "value": "TH-18",
    "parent": "TH"
  },
  "TH-36": {
    "label": "Chaiyaphum",
    "value": "TH-36",
    "parent": "TH"
  },
  "TH-22": {
    "label": "Chanthaburi",
    "value": "TH-22",
    "parent": "TH"
  },
  "TH-50": {
    "label": "Chiang Mai",
    "value": "TH-50",
    "parent": "TH"
  },
  "TH-57": {
    "label": "Chiang Rai",
    "value": "TH-57",
    "parent": "TH"
  },
  "TH-20": {
    "label": "Chon Buri",
    "value": "TH-20",
    "parent": "TH"
  },
  "TH-86": {
    "label": "Chumphon",
    "value": "TH-86",
    "parent": "TH"
  },
  "TH-46": {
    "label": "Kalasin",
    "value": "TH-46",
    "parent": "TH"
  },
  "TH-62": {
    "label": "Kamphaeng Phet",
    "value": "TH-62",
    "parent": "TH"
  },
  "TH-71": {
    "label": "Kanchanaburi",
    "value": "TH-71",
    "parent": "TH"
  },
  "TH-40": {
    "label": "Khon Kaen",
    "value": "TH-40",
    "parent": "TH"
  },
  "TH-81": {
    "label": "Krabi",
    "value": "TH-81",
    "parent": "TH"
  },
  "TH-10": {
    "label": "Krung Thep Maha Nakhon",
    "value": "TH-10",
    "parent": "TH"
  },
  "TH-52": {
    "label": "Lampang",
    "value": "TH-52",
    "parent": "TH"
  },
  "TH-51": {
    "label": "Lamphun",
    "value": "TH-51",
    "parent": "TH"
  },
  "TH-42": {
    "label": "Loei",
    "value": "TH-42",
    "parent": "TH"
  },
  "TH-16": {
    "label": "Lop Buri",
    "value": "TH-16",
    "parent": "TH"
  },
  "TH-58": {
    "label": "Mae Hong Son",
    "value": "TH-58",
    "parent": "TH"
  },
  "TH-44": {
    "label": "Maha Sarakham",
    "value": "TH-44",
    "parent": "TH"
  },
  "TH-49": {
    "label": "Mukdahan",
    "value": "TH-49",
    "parent": "TH"
  },
  "TH-26": {
    "label": "Nakhon Nayok",
    "value": "TH-26",
    "parent": "TH"
  },
  "TH-73": {
    "label": "Nakhon Pathom",
    "value": "TH-73",
    "parent": "TH"
  },
  "TH-48": {
    "label": "Nakhon Phanom",
    "value": "TH-48",
    "parent": "TH"
  },
  "TH-30": {
    "label": "Nakhon Ratchasima",
    "value": "TH-30",
    "parent": "TH"
  },
  "TH-60": {
    "label": "Nakhon Sawan",
    "value": "TH-60",
    "parent": "TH"
  },
  "TH-80": {
    "label": "Nakhon Si Thammarat",
    "value": "TH-80",
    "parent": "TH"
  },
  "TH-55": {
    "label": "Nan",
    "value": "TH-55",
    "parent": "TH"
  },
  "TH-96": {
    "label": "Narathiwat",
    "value": "TH-96",
    "parent": "TH"
  },
  "TH-39": {
    "label": "Nong Bua Lam Phu",
    "value": "TH-39",
    "parent": "TH"
  },
  "TH-43": {
    "label": "Nong Khai",
    "value": "TH-43",
    "parent": "TH"
  },
  "TH-12": {
    "label": "Nonthaburi",
    "value": "TH-12",
    "parent": "TH"
  },
  "TH-13": {
    "label": "Pathum Thani",
    "value": "TH-13",
    "parent": "TH"
  },
  "TH-94": {
    "label": "Pattani",
    "value": "TH-94",
    "parent": "TH"
  },
  "TH-82": {
    "label": "Phangnga",
    "value": "TH-82",
    "parent": "TH"
  },
  "TH-93": {
    "label": "Phatthalung",
    "value": "TH-93",
    "parent": "TH"
  },
  "TH-56": {
    "label": "Phayao",
    "value": "TH-56",
    "parent": "TH"
  },
  "TH-67": {
    "label": "Phetchabun",
    "value": "TH-67",
    "parent": "TH"
  },
  "TH-76": {
    "label": "Phetchaburi",
    "value": "TH-76",
    "parent": "TH"
  },
  "TH-66": {
    "label": "Phichit",
    "value": "TH-66",
    "parent": "TH"
  },
  "TH-65": {
    "label": "Phitsanulok",
    "value": "TH-65",
    "parent": "TH"
  },
  "TH-14": {
    "label": "Phra Nakhon Si Ayutthaya",
    "value": "TH-14",
    "parent": "TH"
  },
  "TH-54": {
    "label": "Phrae",
    "value": "TH-54",
    "parent": "TH"
  },
  "TH-83": {
    "label": "Phuket",
    "value": "TH-83",
    "parent": "TH"
  },
  "TH-25": {
    "label": "Prachin Buri",
    "value": "TH-25",
    "parent": "TH"
  },
  "TH-77": {
    "label": "Prachuap Khiri Khan",
    "value": "TH-77",
    "parent": "TH"
  },
  "TH-85": {
    "label": "Ranong",
    "value": "TH-85",
    "parent": "TH"
  },
  "TH-70": {
    "label": "Ratchaburi",
    "value": "TH-70",
    "parent": "TH"
  },
  "TH-21": {
    "label": "Rayong",
    "value": "TH-21",
    "parent": "TH"
  },
  "TH-45": {
    "label": "Roi Et",
    "value": "TH-45",
    "parent": "TH"
  },
  "TH-27": {
    "label": "Sa Kaeo",
    "value": "TH-27",
    "parent": "TH"
  },
  "TH-47": {
    "label": "Sakon Nakhon",
    "value": "TH-47",
    "parent": "TH"
  },
  "TH-11": {
    "label": "Samut Prakan",
    "value": "TH-11",
    "parent": "TH"
  },
  "TH-74": {
    "label": "Samut Sakhon",
    "value": "TH-74",
    "parent": "TH"
  },
  "TH-75": {
    "label": "Samut Songkhram",
    "value": "TH-75",
    "parent": "TH"
  },
  "TH-19": {
    "label": "Saraburi",
    "value": "TH-19",
    "parent": "TH"
  },
  "TH-91": {
    "label": "Satun",
    "value": "TH-91",
    "parent": "TH"
  },
  "TH-33": {
    "label": "Si Sa Ket",
    "value": "TH-33",
    "parent": "TH"
  },
  "TH-17": {
    "label": "Sing Buri",
    "value": "TH-17",
    "parent": "TH"
  },
  "TH-90": {
    "label": "Songkhla",
    "value": "TH-90",
    "parent": "TH"
  },
  "TH-64": {
    "label": "Sukhothai",
    "value": "TH-64",
    "parent": "TH"
  },
  "TH-72": {
    "label": "Suphan Buri",
    "value": "TH-72",
    "parent": "TH"
  },
  "TH-84": {
    "label": "Surat Thani",
    "value": "TH-84",
    "parent": "TH"
  },
  "TH-32": {
    "label": "Surin",
    "value": "TH-32",
    "parent": "TH"
  },
  "TH-63": {
    "label": "Tak",
    "value": "TH-63",
    "parent": "TH"
  },
  "TH-92": {
    "label": "Trang",
    "value": "TH-92",
    "parent": "TH"
  },
  "TH-23": {
    "label": "Trat",
    "value": "TH-23",
    "parent": "TH"
  },
  "TH-34": {
    "label": "Ubon Ratchathani",
    "value": "TH-34",
    "parent": "TH"
  },
  "TH-41": {
    "label": "Udon Thani",
    "value": "TH-41",
    "parent": "TH"
  },
  "TH-61": {
    "label": "Uthai Thani",
    "value": "TH-61",
    "parent": "TH"
  },
  "TH-53": {
    "label": "Uttaradit",
    "value": "TH-53",
    "parent": "TH"
  },
  "TH-95": {
    "label": "Yala",
    "value": "TH-95",
    "parent": "TH"
  },
  "TH-35": {
    "label": "Yasothon",
    "value": "TH-35",
    "parent": "TH"
  },
  "TL": {
    "label": "Timor-Leste",
    "value": "TL"
  },
  "TL-AN": {
    "label": "Ainaro",
    "value": "TL-AN",
    "parent": "TL"
  },
  "TL-BO": {
    "label": "Bobonaro",
    "value": "TL-BO",
    "parent": "TL"
  },
  "TL-DI": {
    "label": "Dili",
    "value": "TL-DI",
    "parent": "TL"
  },
  "TL-ER": {
    "label": "Ermera",
    "value": "TL-ER",
    "parent": "TL"
  },
  "TL-LI": {
    "label": "Liquica",
    "value": "TL-LI",
    "parent": "TL"
  },
  "TL-MT": {
    "label": "Manatuto",
    "value": "TL-MT",
    "parent": "TL"
  },
  "TL-VI": {
    "label": "Viqueque",
    "value": "TL-VI",
    "parent": "TL"
  },
  "TG": {
    "label": "Togo",
    "value": "TG"
  },
  "TG-C": {
    "label": "Centrale",
    "value": "TG-C",
    "parent": "TG"
  },
  "TG-K": {
    "label": "Kara",
    "value": "TG-K",
    "parent": "TG"
  },
  "TG-M": {
    "label": "Maritime",
    "value": "TG-M",
    "parent": "TG"
  },
  "TG-P": {
    "label": "Plateaux",
    "value": "TG-P",
    "parent": "TG"
  },
  "TG-S": {
    "label": "Savanes",
    "value": "TG-S",
    "parent": "TG"
  },
  "TK": {
    "label": "Tokelau",
    "value": "TK"
  },
  "TO": {
    "label": "Tonga",
    "value": "TO"
  },
  "TO-01": {
    "label": "'Eua",
    "value": "TO-01",
    "parent": "TO"
  },
  "TO-02": {
    "label": "Ha'apai",
    "value": "TO-02",
    "parent": "TO"
  },
  "TO-03": {
    "label": "Niuas",
    "value": "TO-03",
    "parent": "TO"
  },
  "TO-04": {
    "label": "Tongatapu",
    "value": "TO-04",
    "parent": "TO"
  },
  "TO-05": {
    "label": "Vava'u",
    "value": "TO-05",
    "parent": "TO"
  },
  "TT": {
    "label": "Trinidad and Tobago",
    "value": "TT"
  },
  "TT-ARI": {
    "label": "Arima",
    "value": "TT-ARI",
    "parent": "TT"
  },
  "TT-CHA": {
    "label": "Chaguanas",
    "value": "TT-CHA",
    "parent": "TT"
  },
  "TT-CTT": {
    "label": "Couva-Tabaquite-Talparo",
    "value": "TT-CTT",
    "parent": "TT"
  },
  "TT-DMN": {
    "label": "Diego Martin",
    "value": "TT-DMN",
    "parent": "TT"
  },
  "TT-MRC": {
    "label": "Mayaro-Rio Claro",
    "value": "TT-MRC",
    "parent": "TT"
  },
  "TT-PED": {
    "label": "Penal-Debe",
    "value": "TT-PED",
    "parent": "TT"
  },
  "TT-PTF": {
    "label": "Point Fortin",
    "value": "TT-PTF",
    "parent": "TT"
  },
  "TT-POS": {
    "label": "Port of Spain",
    "value": "TT-POS",
    "parent": "TT"
  },
  "TT-PRT": {
    "label": "Princes Town",
    "value": "TT-PRT",
    "parent": "TT"
  },
  "TT-SFO": {
    "label": "San Fernando",
    "value": "TT-SFO",
    "parent": "TT"
  },
  "TT-SJL": {
    "label": "San Juan-Laventille",
    "value": "TT-SJL",
    "parent": "TT"
  },
  "TT-SGE": {
    "label": "Sangre Grande",
    "value": "TT-SGE",
    "parent": "TT"
  },
  "TT-SIP": {
    "label": "Siparia",
    "value": "TT-SIP",
    "parent": "TT"
  },
  "TT-TOB": {
    "label": "Tobago",
    "value": "TT-TOB",
    "parent": "TT"
  },
  "TT-TUP": {
    "label": "Tunapuna-Piarco",
    "value": "TT-TUP",
    "parent": "TT"
  },
  "TN": {
    "label": "Tunisia",
    "value": "TN"
  },
  "TN-31": {
    "label": "Beja",
    "value": "TN-31",
    "parent": "TN"
  },
  "TN-13": {
    "label": "Ben Arous",
    "value": "TN-13",
    "parent": "TN"
  },
  "TN-23": {
    "label": "Bizerte",
    "value": "TN-23",
    "parent": "TN"
  },
  "TN-81": {
    "label": "Gabes",
    "value": "TN-81",
    "parent": "TN"
  },
  "TN-71": {
    "label": "Gafsa",
    "value": "TN-71",
    "parent": "TN"
  },
  "TN-32": {
    "label": "Jendouba",
    "value": "TN-32",
    "parent": "TN"
  },
  "TN-41": {
    "label": "Kairouan",
    "value": "TN-41",
    "parent": "TN"
  },
  "TN-42": {
    "label": "Kasserine",
    "value": "TN-42",
    "parent": "TN"
  },
  "TN-73": {
    "label": "Kebili",
    "value": "TN-73",
    "parent": "TN"
  },
  "TN-12": {
    "label": "L'Ariana",
    "value": "TN-12",
    "parent": "TN"
  },
  "TN-14": {
    "label": "La Manouba",
    "value": "TN-14",
    "parent": "TN"
  },
  "TN-33": {
    "label": "Le Kef",
    "value": "TN-33",
    "parent": "TN"
  },
  "TN-53": {
    "label": "Mahdia",
    "value": "TN-53",
    "parent": "TN"
  },
  "TN-82": {
    "label": "Medenine",
    "value": "TN-82",
    "parent": "TN"
  },
  "TN-52": {
    "label": "Monastir",
    "value": "TN-52",
    "parent": "TN"
  },
  "TN-21": {
    "label": "Nabeul",
    "value": "TN-21",
    "parent": "TN"
  },
  "TN-61": {
    "label": "Sfax",
    "value": "TN-61",
    "parent": "TN"
  },
  "TN-43": {
    "label": "Sidi Bouzid",
    "value": "TN-43",
    "parent": "TN"
  },
  "TN-34": {
    "label": "Siliana",
    "value": "TN-34",
    "parent": "TN"
  },
  "TN-51": {
    "label": "Sousse",
    "value": "TN-51",
    "parent": "TN"
  },
  "TN-83": {
    "label": "Tataouine",
    "value": "TN-83",
    "parent": "TN"
  },
  "TN-72": {
    "label": "Tozeur",
    "value": "TN-72",
    "parent": "TN"
  },
  "TN-11": {
    "label": "Tunis",
    "value": "TN-11",
    "parent": "TN"
  },
  "TN-22": {
    "label": "Zaghouan",
    "value": "TN-22",
    "parent": "TN"
  },
  "TR": {
    "label": "Türkiye",
    "value": "TR"
  },
  "TR-01": {
    "label": "Adana",
    "value": "TR-01",
    "parent": "TR"
  },
  "TR-02": {
    "label": "Adiyaman",
    "value": "TR-02",
    "parent": "TR"
  },
  "TR-03": {
    "label": "Afyonkarahisar",
    "value": "TR-03",
    "parent": "TR"
  },
  "TR-04": {
    "label": "Agri",
    "value": "TR-04",
    "parent": "TR"
  },
  "TR-68": {
    "label": "Aksaray",
    "value": "TR-68",
    "parent": "TR"
  },
  "TR-05": {
    "label": "Amasya",
    "value": "TR-05",
    "parent": "TR"
  },
  "TR-06": {
    "label": "Ankara",
    "value": "TR-06",
    "parent": "TR"
  },
  "TR-07": {
    "label": "Antalya",
    "value": "TR-07",
    "parent": "TR"
  },
  "TR-75": {
    "label": "Ardahan",
    "value": "TR-75",
    "parent": "TR"
  },
  "TR-08": {
    "label": "Artvin",
    "value": "TR-08",
    "parent": "TR"
  },
  "TR-09": {
    "label": "Aydin",
    "value": "TR-09",
    "parent": "TR"
  },
  "TR-10": {
    "label": "Balikesir",
    "value": "TR-10",
    "parent": "TR"
  },
  "TR-74": {
    "label": "Bartin",
    "value": "TR-74",
    "parent": "TR"
  },
  "TR-72": {
    "label": "Batman",
    "value": "TR-72",
    "parent": "TR"
  },
  "TR-69": {
    "label": "Bayburt",
    "value": "TR-69",
    "parent": "TR"
  },
  "TR-11": {
    "label": "Bilecik",
    "value": "TR-11",
    "parent": "TR"
  },
  "TR-12": {
    "label": "Bingol",
    "value": "TR-12",
    "parent": "TR"
  },
  "TR-13": {
    "label": "Bitlis",
    "value": "TR-13",
    "parent": "TR"
  },
  "TR-14": {
    "label": "Bolu",
    "value": "TR-14",
    "parent": "TR"
  },
  "TR-15": {
    "label": "Burdur",
    "value": "TR-15",
    "parent": "TR"
  },
  "TR-16": {
    "label": "Bursa",
    "value": "TR-16",
    "parent": "TR"
  },
  "TR-17": {
    "label": "Canakkale",
    "value": "TR-17",
    "parent": "TR"
  },
  "TR-18": {
    "label": "Cankiri",
    "value": "TR-18",
    "parent": "TR"
  },
  "TR-19": {
    "label": "Corum",
    "value": "TR-19",
    "parent": "TR"
  },
  "TR-20": {
    "label": "Denizli",
    "value": "TR-20",
    "parent": "TR"
  },
  "TR-21": {
    "label": "Diyarbakir",
    "value": "TR-21",
    "parent": "TR"
  },
  "TR-81": {
    "label": "Duzce",
    "value": "TR-81",
    "parent": "TR"
  },
  "TR-22": {
    "label": "Edirne",
    "value": "TR-22",
    "parent": "TR"
  },
  "TR-23": {
    "label": "Elazig",
    "value": "TR-23",
    "parent": "TR"
  },
  "TR-24": {
    "label": "Erzincan",
    "value": "TR-24",
    "parent": "TR"
  },
  "TR-25": {
    "label": "Erzurum",
    "value": "TR-25",
    "parent": "TR"
  },
  "TR-26": {
    "label": "Eskisehir",
    "value": "TR-26",
    "parent": "TR"
  },
  "TR-27": {
    "label": "Gaziantep",
    "value": "TR-27",
    "parent": "TR"
  },
  "TR-28": {
    "label": "Giresun",
    "value": "TR-28",
    "parent": "TR"
  },
  "TR-29": {
    "label": "Gumushane",
    "value": "TR-29",
    "parent": "TR"
  },
  "TR-30": {
    "label": "Hakkari",
    "value": "TR-30",
    "parent": "TR"
  },
  "TR-31": {
    "label": "Hatay",
    "value": "TR-31",
    "parent": "TR"
  },
  "TR-76": {
    "label": "Igdir",
    "value": "TR-76",
    "parent": "TR"
  },
  "TR-32": {
    "label": "Isparta",
    "value": "TR-32",
    "parent": "TR"
  },
  "TR-34": {
    "label": "Istanbul",
    "value": "TR-34",
    "parent": "TR"
  },
  "TR-35": {
    "label": "Izmir",
    "value": "TR-35",
    "parent": "TR"
  },
  "TR-46": {
    "label": "Kahramanmaras",
    "value": "TR-46",
    "parent": "TR"
  },
  "TR-78": {
    "label": "Karabuk",
    "value": "TR-78",
    "parent": "TR"
  },
  "TR-70": {
    "label": "Karaman",
    "value": "TR-70",
    "parent": "TR"
  },
  "TR-36": {
    "label": "Kars",
    "value": "TR-36",
    "parent": "TR"
  },
  "TR-37": {
    "label": "Kastamonu",
    "value": "TR-37",
    "parent": "TR"
  },
  "TR-38": {
    "label": "Kayseri",
    "value": "TR-38",
    "parent": "TR"
  },
  "TR-79": {
    "label": "Kilis",
    "value": "TR-79",
    "parent": "TR"
  },
  "TR-71": {
    "label": "Kirikkale",
    "value": "TR-71",
    "parent": "TR"
  },
  "TR-39": {
    "label": "Kirklareli",
    "value": "TR-39",
    "parent": "TR"
  },
  "TR-40": {
    "label": "Kirsehir",
    "value": "TR-40",
    "parent": "TR"
  },
  "TR-41": {
    "label": "Kocaeli",
    "value": "TR-41",
    "parent": "TR"
  },
  "TR-42": {
    "label": "Konya",
    "value": "TR-42",
    "parent": "TR"
  },
  "TR-43": {
    "label": "Kutahya",
    "value": "TR-43",
    "parent": "TR"
  },
  "TR-44": {
    "label": "Malatya",
    "value": "TR-44",
    "parent": "TR"
  },
  "TR-45": {
    "label": "Manisa",
    "value": "TR-45",
    "parent": "TR"
  },
  "TR-47": {
    "label": "Mardin",
    "value": "TR-47",
    "parent": "TR"
  },
  "TR-33": {
    "label": "Mersin",
    "value": "TR-33",
    "parent": "TR"
  },
  "TR-48": {
    "label": "Mugla",
    "value": "TR-48",
    "parent": "TR"
  },
  "TR-49": {
    "label": "Mus",
    "value": "TR-49",
    "parent": "TR"
  },
  "TR-50": {
    "label": "Nevsehir",
    "value": "TR-50",
    "parent": "TR"
  },
  "TR-51": {
    "label": "Nigde",
    "value": "TR-51",
    "parent": "TR"
  },
  "TR-52": {
    "label": "Ordu",
    "value": "TR-52",
    "parent": "TR"
  },
  "TR-80": {
    "label": "Osmaniye",
    "value": "TR-80",
    "parent": "TR"
  },
  "TR-53": {
    "label": "Rize",
    "value": "TR-53",
    "parent": "TR"
  },
  "TR-54": {
    "label": "Sakarya",
    "value": "TR-54",
    "parent": "TR"
  },
  "TR-55": {
    "label": "Samsun",
    "value": "TR-55",
    "parent": "TR"
  },
  "TR-63": {
    "label": "Sanliurfa",
    "value": "TR-63",
    "parent": "TR"
  },
  "TR-56": {
    "label": "Siirt",
    "value": "TR-56",
    "parent": "TR"
  },
  "TR-57": {
    "label": "Sinop",
    "value": "TR-57",
    "parent": "TR"
  },
  "TR-73": {
    "label": "Sirnak",
    "value": "TR-73",
    "parent": "TR"
  },
  "TR-58": {
    "label": "Sivas",
    "value": "TR-58",
    "parent": "TR"
  },
  "TR-59": {
    "label": "Tekirdag",
    "value": "TR-59",
    "parent": "TR"
  },
  "TR-60": {
    "label": "Tokat",
    "value": "TR-60",
    "parent": "TR"
  },
  "TR-61": {
    "label": "Trabzon",
    "value": "TR-61",
    "parent": "TR"
  },
  "TR-62": {
    "label": "Tunceli",
    "value": "TR-62",
    "parent": "TR"
  },
  "TR-64": {
    "label": "Usak",
    "value": "TR-64",
    "parent": "TR"
  },
  "TR-65": {
    "label": "Van",
    "value": "TR-65",
    "parent": "TR"
  },
  "TR-77": {
    "label": "Yalova",
    "value": "TR-77",
    "parent": "TR"
  },
  "TR-66": {
    "label": "Yozgat",
    "value": "TR-66",
    "parent": "TR"
  },
  "TR-67": {
    "label": "Zonguldak",
    "value": "TR-67",
    "parent": "TR"
  },
  "TM": {
    "label": "Turkmenistan",
    "value": "TM"
  },
  "TM-A": {
    "label": "Ahal",
    "value": "TM-A",
    "parent": "TM"
  },
  "TM-B": {
    "label": "Balkan",
    "value": "TM-B",
    "parent": "TM"
  },
  "TM-D": {
    "label": "Dasoguz",
    "value": "TM-D",
    "parent": "TM"
  },
  "TM-L": {
    "label": "Lebap",
    "value": "TM-L",
    "parent": "TM"
  },
  "TM-M": {
    "label": "Mary",
    "value": "TM-M",
    "parent": "TM"
  },
  "TC": {
    "label": "Turks and Caicos Islands",
    "value": "TC"
  },
  "TV": {
    "label": "Tuvalu",
    "value": "TV"
  },
  "TV-FUN": {
    "label": "Funafuti",
    "value": "TV-FUN",
    "parent": "TV"
  },
  "UG": {
    "label": "Uganda",
    "value": "UG"
  },
  "UG-314": {
    "label": "Abim",
    "value": "UG-314",
    "parent": "UG"
  },
  "UG-301": {
    "label": "Adjumani",
    "value": "UG-301",
    "parent": "UG"
  },
  "UG-322": {
    "label": "Agago",
    "value": "UG-322",
    "parent": "UG"
  },
  "UG-323": {
    "label": "Alebtong",
    "value": "UG-323",
    "parent": "UG"
  },
  "UG-302": {
    "label": "Apac",
    "value": "UG-302",
    "parent": "UG"
  },
  "UG-303": {
    "label": "Arua",
    "value": "UG-303",
    "parent": "UG"
  },
  "UG-218": {
    "label": "Bududa",
    "value": "UG-218",
    "parent": "UG"
  },
  "UG-201": {
    "label": "Bugiri",
    "value": "UG-201",
    "parent": "UG"
  },
  "UG-420": {
    "label": "Buhweju",
    "value": "UG-420",
    "parent": "UG"
  },
  "UG-117": {
    "label": "Buikwe",
    "value": "UG-117",
    "parent": "UG"
  },
  "UG-219": {
    "label": "Bukedea",
    "value": "UG-219",
    "parent": "UG"
  },
  "UG-118": {
    "label": "Bukomansibi",
    "value": "UG-118",
    "parent": "UG"
  },
  "UG-225": {
    "label": "Bulambuli",
    "value": "UG-225",
    "parent": "UG"
  },
  "UG-416": {
    "label": "Buliisa",
    "value": "UG-416",
    "parent": "UG"
  },
  "UG-401": {
    "label": "Bundibugyo",
    "value": "UG-401",
    "parent": "UG"
  },
  "UG-402": {
    "label": "Bushenyi",
    "value": "UG-402",
    "parent": "UG"
  },
  "UG-202": {
    "label": "Busia",
    "value": "UG-202",
    "parent": "UG"
  },
  "UG-120": {
    "label": "Buvuma",
    "value": "UG-120",
    "parent": "UG"
  },
  "UG-226": {
    "label": "Buyende",
    "value": "UG-226",
    "parent": "UG"
  },
  "UG-121": {
    "label": "Gomba",
    "value": "UG-121",
    "parent": "UG"
  },
  "UG-304": {
    "label": "Gulu",
    "value": "UG-304",
    "parent": "UG"
  },
  "UG-403": {
    "label": "Hoima",
    "value": "UG-403",
    "parent": "UG"
  },
  "UG-417": {
    "label": "Ibanda",
    "value": "UG-417",
    "parent": "UG"
  },
  "UG-203": {
    "label": "Iganga",
    "value": "UG-203",
    "parent": "UG"
  },
  "UG-418": {
    "label": "Isingiro",
    "value": "UG-418",
    "parent": "UG"
  },
  "UG-204": {
    "label": "Jinja",
    "value": "UG-204",
    "parent": "UG"
  },
  "UG-318": {
    "label": "Kaabong",
    "value": "UG-318",
    "parent": "UG"
  },
  "UG-404": {
    "label": "Kabale",
    "value": "UG-404",
    "parent": "UG"
  },
  "UG-405": {
    "label": "Kabarole",
    "value": "UG-405",
    "parent": "UG"
  },
  "UG-213": {
    "label": "Kaberamaido",
    "value": "UG-213",
    "parent": "UG"
  },
  "UG-101": {
    "label": "Kalangala",
    "value": "UG-101",
    "parent": "UG"
  },
  "UG-222": {
    "label": "Kaliro",
    "value": "UG-222",
    "parent": "UG"
  },
  "UG-122": {
    "label": "Kalungu",
    "value": "UG-122",
    "parent": "UG"
  },
  "UG-102": {
    "label": "Kampala",
    "value": "UG-102",
    "parent": "UG"
  },
  "UG-205": {
    "label": "Kamuli",
    "value": "UG-205",
    "parent": "UG"
  },
  "UG-413": {
    "label": "Kamwenge",
    "value": "UG-413",
    "parent": "UG"
  },
  "UG-414": {
    "label": "Kanungu",
    "value": "UG-414",
    "parent": "UG"
  },
  "UG-206": {
    "label": "Kapchorwa",
    "value": "UG-206",
    "parent": "UG"
  },
  "UG-406": {
    "label": "Kasese",
    "value": "UG-406",
    "parent": "UG"
  },
  "UG-207": {
    "label": "Katakwi",
    "value": "UG-207",
    "parent": "UG"
  },
  "UG-112": {
    "label": "Kayunga",
    "value": "UG-112",
    "parent": "UG"
  },
  "UG-407": {
    "label": "Kibaale",
    "value": "UG-407",
    "parent": "UG"
  },
  "UG-103": {
    "label": "Kiboga",
    "value": "UG-103",
    "parent": "UG"
  },
  "UG-227": {
    "label": "Kibuku",
    "value": "UG-227",
    "parent": "UG"
  },
  "UG-419": {
    "label": "Kiruhura",
    "value": "UG-419",
    "parent": "UG"
  },
  "UG-421": {
    "label": "Kiryandongo",
    "value": "UG-421",
    "parent": "UG"
  },
  "UG-408": {
    "label": "Kisoro",
    "value": "UG-408",
    "parent": "UG"
  },
  "UG-305": {
    "label": "Kitgum",
    "value": "UG-305",
    "parent": "UG"
  },
  "UG-319": {
    "label": "Koboko",
    "value": "UG-319",
    "parent": "UG"
  },
  "UG-325": {
    "label": "Kole",
    "value": "UG-325",
    "parent": "UG"
  },
  "UG-208": {
    "label": "Kumi",
    "value": "UG-208",
    "parent": "UG"
  },
  "UG-228": {
    "label": "Kween",
    "value": "UG-228",
    "parent": "UG"
  },
  "UG-123": {
    "label": "Kyankwanzi",
    "value": "UG-123",
    "parent": "UG"
  },
  "UG-422": {
    "label": "Kyegegwa",
    "value": "UG-422",
    "parent": "UG"
  },
  "UG-415": {
    "label": "Kyenjojo",
    "value": "UG-415",
    "parent": "UG"
  },
  "UG-326": {
    "label": "Lamwo",
    "value": "UG-326",
    "parent": "UG"
  },
  "UG-307": {
    "label": "Lira",
    "value": "UG-307",
    "parent": "UG"
  },
  "UG-229": {
    "label": "Luuka",
    "value": "UG-229",
    "parent": "UG"
  },
  "UG-104": {
    "label": "Luwero",
    "value": "UG-104",
    "parent": "UG"
  },
  "UG-124": {
    "label": "Lwengo",
    "value": "UG-124",
    "parent": "UG"
  },
  "UG-114": {
    "label": "Lyantonde",
    "value": "UG-114",
    "parent": "UG"
  },
  "UG-223": {
    "label": "Manafwa",
    "value": "UG-223",
    "parent": "UG"
  },
  "UG-320": {
    "label": "Maracha",
    "value": "UG-320",
    "parent": "UG"
  },
  "UG-105": {
    "label": "Masaka",
    "value": "UG-105",
    "parent": "UG"
  },
  "UG-409": {
    "label": "Masindi",
    "value": "UG-409",
    "parent": "UG"
  },
  "UG-214": {
    "label": "Mayuge",
    "value": "UG-214",
    "parent": "UG"
  },
  "UG-209": {
    "label": "Mbale",
    "value": "UG-209",
    "parent": "UG"
  },
  "UG-410": {
    "label": "Mbarara",
    "value": "UG-410",
    "parent": "UG"
  },
  "UG-423": {
    "label": "Mitooma",
    "value": "UG-423",
    "parent": "UG"
  },
  "UG-115": {
    "label": "Mityana",
    "value": "UG-115",
    "parent": "UG"
  },
  "UG-308": {
    "label": "Moroto",
    "value": "UG-308",
    "parent": "UG"
  },
  "UG-309": {
    "label": "Moyo",
    "value": "UG-309",
    "parent": "UG"
  },
  "UG-106": {
    "label": "Mpigi",
    "value": "UG-106",
    "parent": "UG"
  },
  "UG-107": {
    "label": "Mubende",
    "value": "UG-107",
    "parent": "UG"
  },
  "UG-108": {
    "label": "Mukono",
    "value": "UG-108",
    "parent": "UG"
  },
  "UG-311": {
    "label": "Nakapiripirit",
    "value": "UG-311",
    "parent": "UG"
  },
  "UG-116": {
    "label": "Nakaseke",
    "value": "UG-116",
    "parent": "UG"
  },
  "UG-109": {
    "label": "Nakasongola",
    "value": "UG-109",
    "parent": "UG"
  },
  "UG-230": {
    "label": "Namayingo",
    "value": "UG-230",
    "parent": "UG"
  },
  "UG-327": {
    "label": "Napak",
    "value": "UG-327",
    "parent": "UG"
  },
  "UG-310": {
    "label": "Nebbi",
    "value": "UG-310",
    "parent": "UG"
  },
  "UG-424": {
    "label": "Ntoroko",
    "value": "UG-424",
    "parent": "UG"
  },
  "UG-411": {
    "label": "Ntungamo",
    "value": "UG-411",
    "parent": "UG"
  },
  "UG-328": {
    "label": "Nwoya",
    "value": "UG-328",
    "parent": "UG"
  },
  "UG-321": {
    "label": "Oyam",
    "value": "UG-321",
    "parent": "UG"
  },
  "UG-312": {
    "label": "Pader",
    "value": "UG-312",
    "parent": "UG"
  },
  "UG-110": {
    "label": "Rakai",
    "value": "UG-110",
    "parent": "UG"
  },
  "UG-425": {
    "label": "Rubirizi",
    "value": "UG-425",
    "parent": "UG"
  },
  "UG-412": {
    "label": "Rukungiri",
    "value": "UG-412",
    "parent": "UG"
  },
  "UG-111": {
    "label": "Sembabule",
    "value": "UG-111",
    "parent": "UG"
  },
  "UG-426": {
    "label": "Sheema",
    "value": "UG-426",
    "parent": "UG"
  },
  "UG-215": {
    "label": "Sironko",
    "value": "UG-215",
    "parent": "UG"
  },
  "UG-211": {
    "label": "Soroti",
    "value": "UG-211",
    "parent": "UG"
  },
  "UG-212": {
    "label": "Tororo",
    "value": "UG-212",
    "parent": "UG"
  },
  "UG-113": {
    "label": "Wakiso",
    "value": "UG-113",
    "parent": "UG"
  },
  "UG-313": {
    "label": "Yumbe",
    "value": "UG-313",
    "parent": "UG"
  },
  "UG-330": {
    "label": "Zombo",
    "value": "UG-330",
    "parent": "UG"
  },
  "UA": {
    "label": "Ukraine",
    "value": "UA"
  },
  "UA-43": {
    "label": "Avtonomna Respublika Krym",
    "value": "UA-43",
    "parent": "UA"
  },
  "UA-71": {
    "label": "Cherkaska oblast",
    "value": "UA-71",
    "parent": "UA"
  },
  "UA-74": {
    "label": "Chernihivska oblast",
    "value": "UA-74",
    "parent": "UA"
  },
  "UA-77": {
    "label": "Chernivetska oblast",
    "value": "UA-77",
    "parent": "UA"
  },
  "UA-12": {
    "label": "Dnipropetrovska oblast",
    "value": "UA-12",
    "parent": "UA"
  },
  "UA-14": {
    "label": "Donetska oblast",
    "value": "UA-14",
    "parent": "UA"
  },
  "UA-26": {
    "label": "Ivano-Frankivska oblast",
    "value": "UA-26",
    "parent": "UA"
  },
  "UA-63": {
    "label": "Kharkivska oblast",
    "value": "UA-63",
    "parent": "UA"
  },
  "UA-65": {
    "label": "Khersonska oblast",
    "value": "UA-65",
    "parent": "UA"
  },
  "UA-68": {
    "label": "Khmelnytska oblast",
    "value": "UA-68",
    "parent": "UA"
  },
  "UA-35": {
    "label": "Kirovohradska oblast",
    "value": "UA-35",
    "parent": "UA"
  },
  "UA-30": {
    "label": "Kyiv",
    "value": "UA-30",
    "parent": "UA"
  },
  "UA-32": {
    "label": "Kyivska oblast",
    "value": "UA-32",
    "parent": "UA"
  },
  "UA-09": {
    "label": "Luhanska oblast",
    "value": "UA-09",
    "parent": "UA"
  },
  "UA-46": {
    "label": "Lvivska oblast",
    "value": "UA-46",
    "parent": "UA"
  },
  "UA-48": {
    "label": "Mykolaivska oblast",
    "value": "UA-48",
    "parent": "UA"
  },
  "UA-51": {
    "label": "Odeska oblast",
    "value": "UA-51",
    "parent": "UA"
  },
  "UA-53": {
    "label": "Poltavska oblast",
    "value": "UA-53",
    "parent": "UA"
  },
  "UA-56": {
    "label": "Rivnenska oblast",
    "value": "UA-56",
    "parent": "UA"
  },
  "UA-40": {
    "label": "Sevastopol",
    "value": "UA-40",
    "parent": "UA"
  },
  "UA-59": {
    "label": "Sumska oblast",
    "value": "UA-59",
    "parent": "UA"
  },
  "UA-61": {
    "label": "Ternopilska oblast",
    "value": "UA-61",
    "parent": "UA"
  },
  "UA-05": {
    "label": "Vinnytska oblast",
    "value": "UA-05",
    "parent": "UA"
  },
  "UA-07": {
    "label": "Volynska oblast",
    "value": "UA-07",
    "parent": "UA"
  },
  "UA-21": {
    "label": "Zakarpatska oblast",
    "value": "UA-21",
    "parent": "UA"
  },
  "UA-23": {
    "label": "Zaporizka oblast",
    "value": "UA-23",
    "parent": "UA"
  },
  "UA-18": {
    "label": "Zhytomyrska oblast",
    "value": "UA-18",
    "parent": "UA"
  },
  "AE": {
    "label": "United Arab Emirates",
    "value": "AE"
  },
  "AE-AJ": {
    "label": "'Ajman",
    "value": "AE-AJ",
    "parent": "AE"
  },
  "AE-AZ": {
    "label": "Abu Zaby",
    "value": "AE-AZ",
    "parent": "AE"
  },
  "AE-FU": {
    "label": "Al Fujayrah",
    "value": "AE-FU",
    "parent": "AE"
  },
  "AE-SH": {
    "label": "Ash Shariqah",
    "value": "AE-SH",
    "parent": "AE"
  },
  "AE-DU": {
    "label": "Dubayy",
    "value": "AE-DU",
    "parent": "AE"
  },
  "AE-RK": {
    "label": "Ra's al Khaymah",
    "value": "AE-RK",
    "parent": "AE"
  },
  "AE-UQ": {
    "label": "Umm al Qaywayn",
    "value": "AE-UQ",
    "parent": "AE"
  },
  "GB": {
    "label": "United Kingdom of Great Britain and Northern Ireland",
    "value": "GB"
  },
  "GB-ENG": {
    "label": "England",
    "value": "GB-ENG",
    "parent": "GB"
  },
  "GB-NIR": {
    "label": "Northern Ireland",
    "value": "GB-NIR",
    "parent": "GB"
  },
  "GB-SCT": {
    "label": "Scotland",
    "value": "GB-SCT",
    "parent": "GB"
  },
  "GB-WLS": {
    "label": "Wales",
    "value": "GB-WLS",
    "parent": "GB"
  },
  "UM": {
    "label": "United States Minor Outlying Islands",
    "value": "UM"
  },
  "UM-95": {
    "label": "Palmyra Atoll",
    "value": "UM-95",
    "parent": "UM"
  },
  "US": {
    "label": "United States of America",
    "value": "US"
  },
  "US-AL": {
    "label": "Alabama",
    "value": "US-AL",
    "parent": "US"
  },
  "US-AK": {
    "label": "Alaska",
    "value": "US-AK",
    "parent": "US"
  },
  "US-AZ": {
    "label": "Arizona",
    "value": "US-AZ",
    "parent": "US"
  },
  "US-AR": {
    "label": "Arkansas",
    "value": "US-AR",
    "parent": "US"
  },
  "US-CA": {
    "label": "California",
    "value": "US-CA",
    "parent": "US"
  },
  "US-CO": {
    "label": "Colorado",
    "value": "US-CO",
    "parent": "US"
  },
  "US-CT": {
    "label": "Connecticut",
    "value": "US-CT",
    "parent": "US"
  },
  "US-DE": {
    "label": "Delaware",
    "value": "US-DE",
    "parent": "US"
  },
  "US-DC": {
    "label": "District of Columbia",
    "value": "US-DC",
    "parent": "US"
  },
  "US-FL": {
    "label": "Florida",
    "value": "US-FL",
    "parent": "US"
  },
  "US-GA": {
    "label": "Georgia",
    "value": "US-GA",
    "parent": "US"
  },
  "US-HI": {
    "label": "Hawaii",
    "value": "US-HI",
    "parent": "US"
  },
  "US-ID": {
    "label": "Idaho",
    "value": "US-ID",
    "parent": "US"
  },
  "US-IL": {
    "label": "Illinois",
    "value": "US-IL",
    "parent": "US"
  },
  "US-IN": {
    "label": "Indiana",
    "value": "US-IN",
    "parent": "US"
  },
  "US-IA": {
    "label": "Iowa",
    "value": "US-IA",
    "parent": "US"
  },
  "US-KS": {
    "label": "Kansas",
    "value": "US-KS",
    "parent": "US"
  },
  "US-KY": {
    "label": "Kentucky",
    "value": "US-KY",
    "parent": "US"
  },
  "US-LA": {
    "label": "Louisiana",
    "value": "US-LA",
    "parent": "US"
  },
  "US-ME": {
    "label": "Maine",
    "value": "US-ME",
    "parent": "US"
  },
  "US-MD": {
    "label": "Maryland",
    "value": "US-MD",
    "parent": "US"
  },
  "US-MA": {
    "label": "Massachusetts",
    "value": "US-MA",
    "parent": "US"
  },
  "US-MI": {
    "label": "Michigan",
    "value": "US-MI",
    "parent": "US"
  },
  "US-MN": {
    "label": "Minnesota",
    "value": "US-MN",
    "parent": "US"
  },
  "US-MS": {
    "label": "Mississippi",
    "value": "US-MS",
    "parent": "US"
  },
  "US-MO": {
    "label": "Missouri",
    "value": "US-MO",
    "parent": "US"
  },
  "US-MT": {
    "label": "Montana",
    "value": "US-MT",
    "parent": "US"
  },
  "US-NE": {
    "label": "Nebraska",
    "value": "US-NE",
    "parent": "US"
  },
  "US-NV": {
    "label": "Nevada",
    "value": "US-NV",
    "parent": "US"
  },
  "US-NH": {
    "label": "New Hampshire",
    "value": "US-NH",
    "parent": "US"
  },
  "US-NJ": {
    "label": "New Jersey",
    "value": "US-NJ",
    "parent": "US"
  },
  "US-NM": {
    "label": "New Mexico",
    "value": "US-NM",
    "parent": "US"
  },
  "US-NY": {
    "label": "New York",
    "value": "US-NY",
    "parent": "US"
  },
  "US-NC": {
    "label": "North Carolina",
    "value": "US-NC",
    "parent": "US"
  },
  "US-ND": {
    "label": "North Dakota",
    "value": "US-ND",
    "parent": "US"
  },
  "US-OH": {
    "label": "Ohio",
    "value": "US-OH",
    "parent": "US"
  },
  "US-OK": {
    "label": "Oklahoma",
    "value": "US-OK",
    "parent": "US"
  },
  "US-OR": {
    "label": "Oregon",
    "value": "US-OR",
    "parent": "US"
  },
  "US-PA": {
    "label": "Pennsylvania",
    "value": "US-PA",
    "parent": "US"
  },
  "US-RI": {
    "label": "Rhode Island",
    "value": "US-RI",
    "parent": "US"
  },
  "US-SC": {
    "label": "South Carolina",
    "value": "US-SC",
    "parent": "US"
  },
  "US-SD": {
    "label": "South Dakota",
    "value": "US-SD",
    "parent": "US"
  },
  "US-TN": {
    "label": "Tennessee",
    "value": "US-TN",
    "parent": "US"
  },
  "US-TX": {
    "label": "Texas",
    "value": "US-TX",
    "parent": "US"
  },
  "US-UT": {
    "label": "Utah",
    "value": "US-UT",
    "parent": "US"
  },
  "US-VT": {
    "label": "Vermont",
    "value": "US-VT",
    "parent": "US"
  },
  "US-VA": {
    "label": "Virginia",
    "value": "US-VA",
    "parent": "US"
  },
  "US-WA": {
    "label": "Washington",
    "value": "US-WA",
    "parent": "US"
  },
  "US-WV": {
    "label": "West Virginia",
    "value": "US-WV",
    "parent": "US"
  },
  "US-WI": {
    "label": "Wisconsin",
    "value": "US-WI",
    "parent": "US"
  },
  "US-WY": {
    "label": "Wyoming",
    "value": "US-WY",
    "parent": "US"
  },
  "UY": {
    "label": "Uruguay",
    "value": "UY"
  },
  "UY-AR": {
    "label": "Artigas",
    "value": "UY-AR",
    "parent": "UY"
  },
  "UY-CA": {
    "label": "Canelones",
    "value": "UY-CA",
    "parent": "UY"
  },
  "UY-CL": {
    "label": "Cerro Largo",
    "value": "UY-CL",
    "parent": "UY"
  },
  "UY-CO": {
    "label": "Colonia",
    "value": "UY-CO",
    "parent": "UY"
  },
  "UY-DU": {
    "label": "Durazno",
    "value": "UY-DU",
    "parent": "UY"
  },
  "UY-FS": {
    "label": "Flores",
    "value": "UY-FS",
    "parent": "UY"
  },
  "UY-FD": {
    "label": "Florida",
    "value": "UY-FD",
    "parent": "UY"
  },
  "UY-LA": {
    "label": "Lavalleja",
    "value": "UY-LA",
    "parent": "UY"
  },
  "UY-MA": {
    "label": "Maldonado",
    "value": "UY-MA",
    "parent": "UY"
  },
  "UY-MO": {
    "label": "Montevideo",
    "value": "UY-MO",
    "parent": "UY"
  },
  "UY-PA": {
    "label": "Paysandu",
    "value": "UY-PA",
    "parent": "UY"
  },
  "UY-RN": {
    "label": "Rio Negro",
    "value": "UY-RN",
    "parent": "UY"
  },
  "UY-RV": {
    "label": "Rivera",
    "value": "UY-RV",
    "parent": "UY"
  },
  "UY-RO": {
    "label": "Rocha",
    "value": "UY-RO",
    "parent": "UY"
  },
  "UY-SA": {
    "label": "Salto",
    "value": "UY-SA",
    "parent": "UY"
  },
  "UY-SJ": {
    "label": "San Jose",
    "value": "UY-SJ",
    "parent": "UY"
  },
  "UY-SO": {
    "label": "Soriano",
    "value": "UY-SO",
    "parent": "UY"
  },
  "UY-TA": {
    "label": "Tacuarembo",
    "value": "UY-TA",
    "parent": "UY"
  },
  "UY-TT": {
    "label": "Treinta y Tres",
    "value": "UY-TT",
    "parent": "UY"
  },
  "UZ": {
    "label": "Uzbekistan",
    "value": "UZ"
  },
  "UZ-AN": {
    "label": "Andijon",
    "value": "UZ-AN",
    "parent": "UZ"
  },
  "UZ-BU": {
    "label": "Buxoro",
    "value": "UZ-BU",
    "parent": "UZ"
  },
  "UZ-FA": {
    "label": "Farg'ona",
    "value": "UZ-FA",
    "parent": "UZ"
  },
  "UZ-JI": {
    "label": "Jizzax",
    "value": "UZ-JI",
    "parent": "UZ"
  },
  "UZ-NG": {
    "label": "Namangan",
    "value": "UZ-NG",
    "parent": "UZ"
  },
  "UZ-NW": {
    "label": "Navoiy",
    "value": "UZ-NW",
    "parent": "UZ"
  },
  "UZ-QA": {
    "label": "Qashqadaryo",
    "value": "UZ-QA",
    "parent": "UZ"
  },
  "UZ-QR": {
    "label": "Qoraqalpog'iston Respublikasi",
    "value": "UZ-QR",
    "parent": "UZ"
  },
  "UZ-SA": {
    "label": "Samarqand",
    "value": "UZ-SA",
    "parent": "UZ"
  },
  "UZ-SI": {
    "label": "Sirdaryo",
    "value": "UZ-SI",
    "parent": "UZ"
  },
  "UZ-SU": {
    "label": "Surxondaryo",
    "value": "UZ-SU",
    "parent": "UZ"
  },
  "UZ-TK": {
    "label": "Toshkent",
    "value": "UZ-TK",
    "parent": "UZ"
  },
  "UZ-XO": {
    "label": "Xorazm",
    "value": "UZ-XO",
    "parent": "UZ"
  },
  "VU": {
    "label": "Vanuatu",
    "value": "VU"
  },
  "VU-SAM": {
    "label": "Sanma",
    "value": "VU-SAM",
    "parent": "VU"
  },
  "VU-SEE": {
    "label": "Shefa",
    "value": "VU-SEE",
    "parent": "VU"
  },
  "VU-TAE": {
    "label": "Tafea",
    "value": "VU-TAE",
    "parent": "VU"
  },
  "VE": {
    "label": "Venezuela, Bolivarian Republic of",
    "value": "VE"
  },
  "VE-Z": {
    "label": "Amazonas",
    "value": "VE-Z",
    "parent": "VE"
  },
  "VE-B": {
    "label": "Anzoategui",
    "value": "VE-B",
    "parent": "VE"
  },
  "VE-C": {
    "label": "Apure",
    "value": "VE-C",
    "parent": "VE"
  },
  "VE-D": {
    "label": "Aragua",
    "value": "VE-D",
    "parent": "VE"
  },
  "VE-E": {
    "label": "Barinas",
    "value": "VE-E",
    "parent": "VE"
  },
  "VE-F": {
    "label": "Bolivar",
    "value": "VE-F",
    "parent": "VE"
  },
  "VE-G": {
    "label": "Carabobo",
    "value": "VE-G",
    "parent": "VE"
  },
  "VE-H": {
    "label": "Cojedes",
    "value": "VE-H",
    "parent": "VE"
  },
  "VE-Y": {
    "label": "Delta Amacuro",
    "value": "VE-Y",
    "parent": "VE"
  },
  "VE-W": {
    "label": "Dependencias Federales",
    "value": "VE-W",
    "parent": "VE"
  },
  "VE-A": {
    "label": "Distrito Capital",
    "value": "VE-A",
    "parent": "VE"
  },
  "VE-I": {
    "label": "Falcon",
    "value": "VE-I",
    "parent": "VE"
  },
  "VE-J": {
    "label": "Guarico",
    "value": "VE-J",
    "parent": "VE"
  },
  "VE-X": {
    "label": "La Guaira",
    "value": "VE-X",
    "parent": "VE"
  },
  "VE-K": {
    "label": "Lara",
    "value": "VE-K",
    "parent": "VE"
  },
  "VE-L": {
    "label": "Merida",
    "value": "VE-L",
    "parent": "VE"
  },
  "VE-M": {
    "label": "Miranda",
    "value": "VE-M",
    "parent": "VE"
  },
  "VE-N": {
    "label": "Monagas",
    "value": "VE-N",
    "parent": "VE"
  },
  "VE-O": {
    "label": "Nueva Esparta",
    "value": "VE-O",
    "parent": "VE"
  },
  "VE-P": {
    "label": "Portuguesa",
    "value": "VE-P",
    "parent": "VE"
  },
  "VE-R": {
    "label": "Sucre",
    "value": "VE-R",
    "parent": "VE"
  },
  "VE-S": {
    "label": "Tachira",
    "value": "VE-S",
    "parent": "VE"
  },
  "VE-T": {
    "label": "Trujillo",
    "value": "VE-T",
    "parent": "VE"
  },
  "VE-U": {
    "label": "Yaracuy",
    "value": "VE-U",
    "parent": "VE"
  },
  "VE-V": {
    "label": "Zulia",
    "value": "VE-V",
    "parent": "VE"
  },
  "VN": {
    "label": "Viet Nam",
    "value": "VN"
  },
  "VN-44": {
    "label": "An Giang",
    "value": "VN-44",
    "parent": "VN"
  },
  "VN-43": {
    "label": "Ba Ria - Vung Tau",
    "value": "VN-43",
    "parent": "VN"
  },
  "VN-54": {
    "label": "Bac Giang",
    "value": "VN-54",
    "parent": "VN"
  },
  "VN-53": {
    "label": "Bac Kan",
    "value": "VN-53",
    "parent": "VN"
  },
  "VN-55": {
    "label": "Bac Lieu",
    "value": "VN-55",
    "parent": "VN"
  },
  "VN-56": {
    "label": "Bac Ninh",
    "value": "VN-56",
    "parent": "VN"
  },
  "VN-50": {
    "label": "Ben Tre",
    "value": "VN-50",
    "parent": "VN"
  },
  "VN-31": {
    "label": "Binh Dinh",
    "value": "VN-31",
    "parent": "VN"
  },
  "VN-57": {
    "label": "Binh Duong",
    "value": "VN-57",
    "parent": "VN"
  },
  "VN-58": {
    "label": "Binh Phuoc",
    "value": "VN-58",
    "parent": "VN"
  },
  "VN-40": {
    "label": "Binh Thuan",
    "value": "VN-40",
    "parent": "VN"
  },
  "VN-59": {
    "label": "Ca Mau",
    "value": "VN-59",
    "parent": "VN"
  },
  "VN-CT": {
    "label": "Can Tho",
    "value": "VN-CT",
    "parent": "VN"
  },
  "VN-04": {
    "label": "Cao Bang",
    "value": "VN-04",
    "parent": "VN"
  },
  "VN-DN": {
    "label": "Da Nang",
    "value": "VN-DN",
    "parent": "VN"
  },
  "VN-33": {
    "label": "Dak Lak",
    "value": "VN-33",
    "parent": "VN"
  },
  "VN-72": {
    "label": "Dak Nong",
    "value": "VN-72",
    "parent": "VN"
  },
  "VN-71": {
    "label": "Dien Bien",
    "value": "VN-71",
    "parent": "VN"
  },
  "VN-39": {
    "label": "Dong Nai",
    "value": "VN-39",
    "parent": "VN"
  },
  "VN-45": {
    "label": "Dong Thap",
    "value": "VN-45",
    "parent": "VN"
  },
  "VN-30": {
    "label": "Gia Lai",
    "value": "VN-30",
    "parent": "VN"
  },
  "VN-03": {
    "label": "Ha Giang",
    "value": "VN-03",
    "parent": "VN"
  },
  "VN-63": {
    "label": "Ha Nam",
    "value": "VN-63",
    "parent": "VN"
  },
  "VN-HN": {
    "label": "Ha Noi",
    "value": "VN-HN",
    "parent": "VN"
  },
  "VN-23": {
    "label": "Ha Tinh",
    "value": "VN-23",
    "parent": "VN"
  },
  "VN-61": {
    "label": "Hai Duong",
    "value": "VN-61",
    "parent": "VN"
  },
  "VN-HP": {
    "label": "Hai Phong",
    "value": "VN-HP",
    "parent": "VN"
  },
  "VN-73": {
    "label": "Hau Giang",
    "value": "VN-73",
    "parent": "VN"
  },
  "VN-SG": {
    "label": "Ho Chi Minh",
    "value": "VN-SG",
    "parent": "VN"
  },
  "VN-14": {
    "label": "Hoa Binh",
    "value": "VN-14",
    "parent": "VN"
  },
  "VN-66": {
    "label": "Hung Yen",
    "value": "VN-66",
    "parent": "VN"
  },
  "VN-34": {
    "label": "Khanh Hoa",
    "value": "VN-34",
    "parent": "VN"
  },
  "VN-47": {
    "label": "Kien Giang",
    "value": "VN-47",
    "parent": "VN"
  },
  "VN-28": {
    "label": "Kon Tum",
    "value": "VN-28",
    "parent": "VN"
  },
  "VN-01": {
    "label": "Lai Chau",
    "value": "VN-01",
    "parent": "VN"
  },
  "VN-35": {
    "label": "Lam Dong",
    "value": "VN-35",
    "parent": "VN"
  },
  "VN-09": {
    "label": "Lang Son",
    "value": "VN-09",
    "parent": "VN"
  },
  "VN-02": {
    "label": "Lao Cai",
    "value": "VN-02",
    "parent": "VN"
  },
  "VN-41": {
    "label": "Long An",
    "value": "VN-41",
    "parent": "VN"
  },
  "VN-67": {
    "label": "Nam Dinh",
    "value": "VN-67",
    "parent": "VN"
  },
  "VN-22": {
    "label": "Nghe An",
    "value": "VN-22",
    "parent": "VN"
  },
  "VN-18": {
    "label": "Ninh Binh",
    "value": "VN-18",
    "parent": "VN"
  },
  "VN-36": {
    "label": "Ninh Thuan",
    "value": "VN-36",
    "parent": "VN"
  },
  "VN-68": {
    "label": "Phu Tho",
    "value": "VN-68",
    "parent": "VN"
  },
  "VN-32": {
    "label": "Phu Yen",
    "value": "VN-32",
    "parent": "VN"
  },
  "VN-24": {
    "label": "Quang Binh",
    "value": "VN-24",
    "parent": "VN"
  },
  "VN-27": {
    "label": "Quang Nam",
    "value": "VN-27",
    "parent": "VN"
  },
  "VN-29": {
    "label": "Quang Ngai",
    "value": "VN-29",
    "parent": "VN"
  },
  "VN-13": {
    "label": "Quang Ninh",
    "value": "VN-13",
    "parent": "VN"
  },
  "VN-25": {
    "label": "Quang Tri",
    "value": "VN-25",
    "parent": "VN"
  },
  "VN-52": {
    "label": "Soc Trang",
    "value": "VN-52",
    "parent": "VN"
  },
  "VN-05": {
    "label": "Son La",
    "value": "VN-05",
    "parent": "VN"
  },
  "VN-37": {
    "label": "Tay Ninh",
    "value": "VN-37",
    "parent": "VN"
  },
  "VN-20": {
    "label": "Thai Binh",
    "value": "VN-20",
    "parent": "VN"
  },
  "VN-69": {
    "label": "Thai Nguyen",
    "value": "VN-69",
    "parent": "VN"
  },
  "VN-21": {
    "label": "Thanh Hoa",
    "value": "VN-21",
    "parent": "VN"
  },
  "VN-26": {
    "label": "Thua Thien-Hue",
    "value": "VN-26",
    "parent": "VN"
  },
  "VN-46": {
    "label": "Tien Giang",
    "value": "VN-46",
    "parent": "VN"
  },
  "VN-51": {
    "label": "Tra Vinh",
    "value": "VN-51",
    "parent": "VN"
  },
  "VN-07": {
    "label": "Tuyen Quang",
    "value": "VN-07",
    "parent": "VN"
  },
  "VN-49": {
    "label": "Vinh Long",
    "value": "VN-49",
    "parent": "VN"
  },
  "VN-70": {
    "label": "Vinh Phuc",
    "value": "VN-70",
    "parent": "VN"
  },
  "VN-06": {
    "label": "Yen Bai",
    "value": "VN-06",
    "parent": "VN"
  },
  "VG": {
    "label": "Virgin Islands (British)",
    "value": "VG"
  },
  "VI": {
    "label": "Virgin Islands (U.S.)",
    "value": "VI"
  },
  "WF": {
    "label": "Wallis and Futuna",
    "value": "WF"
  },
  "WF-SG": {
    "label": "Sigave",
    "value": "WF-SG",
    "parent": "WF"
  },
  "WF-UV": {
    "label": "Uvea",
    "value": "WF-UV",
    "parent": "WF"
  },
  "EH": {
    "label": "Western Sahara",
    "value": "EH"
  },
  "YE": {
    "label": "Yemen",
    "value": "YE"
  },
  "YE-AD": {
    "label": "'Adan",
    "value": "YE-AD",
    "parent": "YE"
  },
  "YE-AM": {
    "label": "'Amran",
    "value": "YE-AM",
    "parent": "YE"
  },
  "YE-DA": {
    "label": "Ad Dali'",
    "value": "YE-DA",
    "parent": "YE"
  },
  "YE-BA": {
    "label": "Al Bayda'",
    "value": "YE-BA",
    "parent": "YE"
  },
  "YE-HU": {
    "label": "Al Hudaydah",
    "value": "YE-HU",
    "parent": "YE"
  },
  "YE-JA": {
    "label": "Al Jawf",
    "value": "YE-JA",
    "parent": "YE"
  },
  "YE-MR": {
    "label": "Al Mahrah",
    "value": "YE-MR",
    "parent": "YE"
  },
  "YE-MW": {
    "label": "Al Mahwit",
    "value": "YE-MW",
    "parent": "YE"
  },
  "YE-SA": {
    "label": "Amanat al 'Asimah",
    "value": "YE-SA",
    "parent": "YE"
  },
  "YE-DH": {
    "label": "Dhamar",
    "value": "YE-DH",
    "parent": "YE"
  },
  "YE-HD": {
    "label": "Hadramawt",
    "value": "YE-HD",
    "parent": "YE"
  },
  "YE-IB": {
    "label": "Ibb",
    "value": "YE-IB",
    "parent": "YE"
  },
  "YE-LA": {
    "label": "Lahij",
    "value": "YE-LA",
    "parent": "YE"
  },
  "YE-MA": {
    "label": "Ma'rib",
    "value": "YE-MA",
    "parent": "YE"
  },
  "YE-SD": {
    "label": "Sa'dah",
    "value": "YE-SD",
    "parent": "YE"
  },
  "YE-SN": {
    "label": "San'a'",
    "value": "YE-SN",
    "parent": "YE"
  },
  "YE-SH": {
    "label": "Shabwah",
    "value": "YE-SH",
    "parent": "YE"
  },
  "YE-TA": {
    "label": "Ta'izz",
    "value": "YE-TA",
    "parent": "YE"
  },
  "ZM": {
    "label": "Zambia",
    "value": "ZM"
  },
  "ZM-02": {
    "label": "Central",
    "value": "ZM-02",
    "parent": "ZM"
  },
  "ZM-08": {
    "label": "Copperbelt",
    "value": "ZM-08",
    "parent": "ZM"
  },
  "ZM-03": {
    "label": "Eastern",
    "value": "ZM-03",
    "parent": "ZM"
  },
  "ZM-04": {
    "label": "Luapula",
    "value": "ZM-04",
    "parent": "ZM"
  },
  "ZM-09": {
    "label": "Lusaka",
    "value": "ZM-09",
    "parent": "ZM"
  },
  "ZM-10": {
    "label": "Muchinga",
    "value": "ZM-10",
    "parent": "ZM"
  },
  "ZM-06": {
    "label": "North-Western",
    "value": "ZM-06",
    "parent": "ZM"
  },
  "ZM-05": {
    "label": "Northern",
    "value": "ZM-05",
    "parent": "ZM"
  },
  "ZM-07": {
    "label": "Southern",
    "value": "ZM-07",
    "parent": "ZM"
  },
  "ZM-01": {
    "label": "Western",
    "value": "ZM-01",
    "parent": "ZM"
  },
  "ZW": {
    "label": "Zimbabwe",
    "value": "ZW"
  },
  "ZW-BU": {
    "label": "Bulawayo",
    "value": "ZW-BU",
    "parent": "ZW"
  },
  "ZW-HA": {
    "label": "Harare",
    "value": "ZW-HA",
    "parent": "ZW"
  },
  "ZW-MA": {
    "label": "Manicaland",
    "value": "ZW-MA",
    "parent": "ZW"
  },
  "ZW-MC": {
    "label": "Mashonaland Central",
    "value": "ZW-MC",
    "parent": "ZW"
  },
  "ZW-ME": {
    "label": "Mashonaland East",
    "value": "ZW-ME",
    "parent": "ZW"
  },
  "ZW-MW": {
    "label": "Mashonaland West",
    "value": "ZW-MW",
    "parent": "ZW"
  },
  "ZW-MV": {
    "label": "Masvingo",
    "value": "ZW-MV",
    "parent": "ZW"
  },
  "ZW-MN": {
    "label": "Matabeleland North",
    "value": "ZW-MN",
    "parent": "ZW"
  },
  "ZW-MS": {
    "label": "Matabeleland South",
    "value": "ZW-MS",
    "parent": "ZW"
  },
  "ZW-MI": {
    "label": "Midlands",
    "value": "ZW-MI",
    "parent": "ZW"
  }
}