import { indexOf } from 'lodash'
import React, { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import { useGetTender } from '@cotiss/tender'
import { BreadcrumbModel, Page, PageContent, TransitionContainer, routerService, useTransition } from '@cotiss/common'
import {
  EVALUATION_CHAIR_STEPS,
  EvaluationChairConsensusStep,
  EvaluationChairHeader,
  EvaluationChairIndividualStep,
  EvaluationChairPriceStep,
  EvaluationChairStep,
  evaluationService,
} from '@cotiss/evaluation'

export const EvaluationChairPage = memo(() => {
  const { replace } = useHistory()
  const { tenderId, step: evaluationChairStep } = useParams<{ tenderId: string; step: EvaluationChairStep }>()
  const { tender } = useGetTender(tenderId)
  const { step, transition, onTransition } = useTransition({ initialStep: indexOf(EVALUATION_CHAIR_STEPS, evaluationChairStep) + 1 })

  const headerBreadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'chair' }),
      },
      {
        label: 'Chair evaluation',
      },
    ]
  }, [])

  useEffect(() => {
    if (!tender) {
      return
    }

    if (evaluationChairStep) {
      return replace(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: evaluationChairStep }))
    }

    const activeStep = evaluationService.getActiveChairStep(tender)
    replace(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: activeStep || 'individual-evaluation' }))
  }, [tender])

  useEffect(() => {
    const newStep = indexOf(EVALUATION_CHAIR_STEPS, evaluationChairStep) + 1

    onTransition({ step: newStep || 1, transition: newStep > step ? 'right' : 'left' })
  }, [evaluationChairStep])

  return (
    <Page>
      <EvaluationChairHeader breadcrumbs={headerBreadcrumbs} />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <EvaluationChairIndividualStep key={1} />}
            {step === 2 && <EvaluationChairConsensusStep key={2} />}
            {step === 3 && <EvaluationChairPriceStep key={3} />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
