import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common'
import { EvaluationChairPage, EvaluationProcurementPage, EvaluationViewPage } from '@cotiss/evaluation'

export const EvaluationLayout = memo(() => (
  <Switch>
    <Route path="/evaluation/view/:id" exact>
      <EvaluationViewPage />
    </Route>
    <Route path="/evaluation/chair/:tenderId/:step?" exact>
      <EvaluationChairPage />
    </Route>
    <Route path="/evaluation/tender/:tenderId" exact>
      <EvaluationProcurementPage />
    </Route>
  </Switch>
))
