import { findIndex } from 'lodash'
import React, { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import { useUserAccess } from '@cotiss/user'
import { BreadcrumbModel, FourOhThreePage, Page, PageContent, TransitionContainer, routerService, useTransition } from '@cotiss/common'
import {
  PROJECT_OVERVIEW_TABS,
  ProcurementOverviewAllActivityTab,
  ProcurementOverviewAllRequestsTab,
  ProcurementOverviewBackgroundTab,
  ProcurementOverviewConflictOfInterestTab,
  ProcurementOverviewHeader,
  ProcurementOverviewTab,
  ProcurementSettings,
  useGetProcurement,
} from '@cotiss/procurement'

export const ProcurementOverviewPage = memo(() => {
  const { replace } = useHistory()
  const { permissions } = useUserAccess()
  const { procurementId, tab } = useParams<{ procurementId: string; tab?: ProcurementOverviewTab }>()
  const { procurement } = useGetProcurement(procurementId)
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(PROJECT_OVERVIEW_TABS, ({ id }) => id === tab) + 1 })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Overview',
      onClick: () => replace(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab: 'background' })),
    },
  ]

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab: PROJECT_OVERVIEW_TABS[0].id }))
    }

    const newStep = findIndex(PROJECT_OVERVIEW_TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  useEffect(() => {
    // Kick user back to procurements list view if procurement is archived
    if (procurement?.isArchived) {
      replace(routerService.getHref('/project/list/:tab?'))
    }
  }, [procurement])

  if (!permissions.hasProcurementAccess) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <ProcurementOverviewHeader />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <ProcurementOverviewBackgroundTab />}
            {step === 2 && <ProcurementOverviewAllRequestsTab />}
            {step === 3 && <ProcurementOverviewAllActivityTab />}
            {step === 4 && <ProcurementOverviewConflictOfInterestTab procurementId={procurementId} />}
            {step === 5 && <ProcurementSettings procurementId={procurementId} breadcrumbs={breadcrumbs} />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
