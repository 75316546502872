import { clamp, map, sortBy } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { evaluationEventService, useEvaluationCriteria, useEvaluationEventAnalytics } from '@cotiss/evaluation-event'
import { AsyncInput, Icon, Table, TableColumn, Text, Tooltip, utilService } from '@cotiss/common'

type Props = {
  className?: string
  parentCriteria: GqlEvaluationCriteriaFieldsFragment[]
  envelopeWeightPercentageById: Record<string, number>
  envelopeId: string
  isEditable?: boolean
}

export const EvaluationEventParentCriteriaWeightList = memo((props: Props) => {
  const { className, parentCriteria, envelopeWeightPercentageById, envelopeId, isEditable } = props
  const { track } = useEvaluationEventAnalytics()
  const { mutateUpdateEvaluationCriteria } = useEvaluationCriteria()
  const [parentCriteriaWeightById, setParentCriteriaWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: parentCriteria })
  )

  const parentCriteriaWeightPercentageById = useMemo(() => {
    const totalParentCriteriaWeight = evaluationEventService.getTotalWeight({ weightById: parentCriteriaWeightById })

    return evaluationEventService.getWeightedPercentageById({ weightById: parentCriteriaWeightById, totalWeight: totalParentCriteriaWeight })
  }, [parentCriteriaWeightById])

  const handleUpdateWeight = async (evaluationCriteriaId: string) => {
    track('evaluation_event_wizard_weight_criteria_update_submit')

    const clampedWeight = clamp(Number(parentCriteriaWeightById[evaluationCriteriaId]) || 0, 1, 100)
    setParentCriteriaWeightById({ ...parentCriteriaWeightById, [evaluationCriteriaId]: clampedWeight })

    await mutateUpdateEvaluationCriteria({ evaluationCriteriaId, weight: clampedWeight })
  }

  const { columns } = useMemo(() => {
    const sortedParentCriteria = sortBy(parentCriteria, 'index')

    const columns: TableColumn[] = [
      {
        heading: 'Criteria',
        thClassName: 'w-1/2',
        rows: [
          ...map(sortedParentCriteria, ({ content, index }) => ({
            content: () => (
              <Text>
                {index}. {content}
              </Text>
            ),
          })),
          {
            content: () => <Text className="font-medium">Totals</Text>,
          },
        ],
      },
      {
        heading: 'Value (1 - 100)',
        thClassName: 'w-1/6',
        rows: [
          ...map(sortedParentCriteria, ({ id, weight }) => ({
            content: () =>
              isEditable ? (
                <div className="relative w-20">
                  <AsyncInput
                    value={parentCriteriaWeightById[id]}
                    onChange={({ target }) => setParentCriteriaWeightById({ ...parentCriteriaWeightById, [id]: Number(target.value) || 0 })}
                    onSubmit={() => handleUpdateWeight(id)}
                    onFocus={({ target }) => target.select()}
                    min={0}
                    max={100}
                    placeholder="--"
                    isDisabled={sortedParentCriteria.length < 2}
                  />
                  {sortedParentCriteria.length < 2 && (
                    <Tooltip
                      className="top-2 right-2"
                      tooltipClassName="text-center"
                      tooltip="You cannot edit the weight when you only have 1 criteria"
                      isAbsolute
                    >
                      <Icon icon="lock" variant="light" />
                    </Tooltip>
                  )}
                </div>
              ) : (
                <Text className="text-right">{weight}</Text>
              ),
          })),
          {
            content: () => <></>,
          },
        ],
      },
      {
        heading: 'Weight',
        thClassName: 'text-right w-1/6',
        rows: [
          ...map(sortedParentCriteria, ({ id }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text className="font-medium">{utilService.formatAsPercentage(Number(parentCriteriaWeightPercentageById[id]) * 100)}</Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({parentCriteriaWeightById[id] || 0}/{evaluationEventService.getTotalWeight({ weightById: parentCriteriaWeightById })})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                100.00%
              </Text>
            ),
          },
        ],
      },
      {
        heading: 'Overall weight',
        thClassName: 'text-right w-1/6',
        rows: [
          ...map(sortedParentCriteria, ({ id, evaluationEnvelopeId }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text className="font-medium">
                  {parentCriteriaWeightPercentageById[id] && envelopeWeightPercentageById[evaluationEnvelopeId]
                    ? utilService.formatAsPercentage(
                        Number(parentCriteriaWeightPercentageById[id]) * Number(envelopeWeightPercentageById[evaluationEnvelopeId]) * 100
                      )
                    : '--'}
                </Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({utilService.formatAsPercentage(Number(parentCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(envelopeWeightPercentageById[evaluationEnvelopeId]) * 100, 0)})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                {utilService.formatAsPercentage(Number(envelopeWeightPercentageById[envelopeId]) * 100)}
              </Text>
            ),
          },
        ],
      },
    ]

    return { columns }
  }, [parentCriteriaWeightPercentageById, parentCriteriaWeightById, envelopeWeightPercentageById, isEditable])

  return <Table className={className} columns={columns} />
})
