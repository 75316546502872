import React, { FormEvent, memo, useState } from 'react'
import { useGetSupplierProfile, useMutateSupplier } from '@cotiss/supplier'
import { Button, Form, Label, RegionMultiSelect, Skeleton, Text, sentryService, useToast } from '@cotiss/common'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewGeneralFormRegions = memo(({ organisationId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateSupplierProfile } = useMutateSupplier()
  const { supplierProfile, isLoading } = useGetSupplierProfile(organisationId)
  const [formData, setFormData] = useState({ regions: supplierProfile?.regions || [] })

  const handleReset = () => {
    setFormData({ regions: supplierProfile?.regions || [] })
    setIsEditing(false)
  }

  const handleEdit = () => {
    setFormData({ regions: supplierProfile?.regions || [] })
    setIsEditing(true)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateSupplierProfile(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>Regions</Label>
        <RegionMultiSelect value={formData.regions} onChange={(regions) => setFormData({ ...formData, regions })} />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-start justify-between">
      <div className="w-full">
        <Label>Regions</Label>
        {isLoading && <Skeleton className="h-2 w-1/4" />}
        {!isLoading && (
          <Text>
            {!supplierProfile?.regions.length && '--'}
            {Boolean(supplierProfile?.regions.length) && supplierProfile?.regions.join(', ')}
          </Text>
        )}
      </div>

      {isEditable && !isLoading && (
        <Button className="ml-4" onClick={handleEdit} variant="secondary" state="translucent" size="sm" isLoading={isSaving} isDisabled={isLoading}>
          Update
        </Button>
      )}
    </div>
  )
})
