import { map } from 'lodash'
import classNames from 'classnames'
import React, { FormEvent, memo, useState } from 'react'
import { ShortListPopulatedModel } from '@cotiss/short-list'
import { RecommendationModel, RecommendationShortListItem, useMutateRecommendation } from '@cotiss/recommendation'
import {
  Button,
  Form,
  Dropdown,
  DropdownContent,
  Icon,
  RichText,
  RichTextEditor,
  Table_DEPRECATED,
  Tbody_DEPRECATED,
  Th_DEPRECATED,
  Thead_DEPRECATED,
  Text,
  richTextService,
  sentryService,
  useToast,
} from '@cotiss/common'

type Props = {
  className?: string
  recommendation: RecommendationModel
  shortLists: ShortListPopulatedModel[]
  isEditable?: boolean
}

export const RecommendationView = memo(({ className, recommendation, shortLists, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(isEditable && Boolean(recommendation.recommendation))
  const { updateRecommendation } = useMutateRecommendation()
  const classes = classNames(className, 'flex items-start justify-between')
  const [formData, setFormData] = useState({ recommendation: recommendation.recommendation || richTextService.getEmptyRemirrorJson() })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({ recommendation: recommendation.recommendation || richTextService.getEmptyRemirrorJson() })
    setIsEditing(isEditing)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateRecommendation(recommendation._id, formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className={classes}>
      <div className="w-1/2 mr-8">
        <Table_DEPRECATED className={className} isSeparate>
          <Thead_DEPRECATED>
            <Th_DEPRECATED>Supplier name</Th_DEPRECATED>
            <Th_DEPRECATED className="w-28">Due diligence</Th_DEPRECATED>
            <Th_DEPRECATED className="w-24">Rank</Th_DEPRECATED>
          </Thead_DEPRECATED>
          <Tbody_DEPRECATED>
            {map(shortLists, (shortList) => (
              <RecommendationShortListItem key={shortList._id} shortList={shortList} shortListCount={shortLists.length} isEditable={isEditable} />
            ))}
          </Tbody_DEPRECATED>
        </Table_DEPRECATED>
      </div>
      <div className="w-1/2">
        <Text className="uppercase mb-2" variant="light" size="sm">
          Write recommendation
        </Text>
        <div className="bg-white rounded-lg shadow-lg w-full px-8 py-6 relative">
          {!isEditing && !richTextService.isEmpty(recommendation.recommendation) && (
            <div className="flex flex-col">
              <div className="flex justify-between">
                <Text>Recommendation</Text>
                <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
                  <Icon icon="dots" variant="light" size={20} />
                </Button>
                <Dropdown className="top-8 right-4 absolute" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  <DropdownContent
                    className="flex items-center"
                    onClick={() => {
                      handleToggleEdit(true)
                      setIsDropdownOpen(false)
                    }}
                  >
                    Edit
                  </DropdownContent>
                </Dropdown>
              </div>
              <RichText value={recommendation.recommendation} />
            </div>
          )}
          {!isEditing && richTextService.isEmpty(recommendation.recommendation) && isEditable && (
            <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
              + Add recommendation
            </Button>
          )}
          {!isEditing && richTextService.isEmpty(recommendation.recommendation) && !isEditable && <Text>--</Text>}
          {isEditing && (
            <Form onSubmit={handleSubmit}>
              <RichTextEditor
                value={formData.recommendation}
                onChange={(recommendation) => setFormData({ ...formData, recommendation })}
                isDisabled={isSaving}
              />
              <div className="flex justify-end mt-2">
                <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                  Cancel
                </Button>
                <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  )
})
