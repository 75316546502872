import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationViewInput, GqlEvaluationViewQuery } from '@gql'

export const queryEvaluationView = async (input: GqlEvaluationViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationViewQuery>({
    query: gql`
      ${EVALUATION_FRAGMENT}

      query EvaluationView($input: EvaluationViewInput!) {
        evaluationView(input: $input) {
          ...EvaluationFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationView
}
