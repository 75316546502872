import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT, EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationUserInput,
  GqlUpdateEvaluationUserMutation,
  GqlUpdateEvaluationUserMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationUser = async (input: GqlUpdateEvaluationUserInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationUserMutation, GqlUpdateEvaluationUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation UpdateEvaluationUser($input: UpdateEvaluationUserInput!) {
        updateEvaluationUser(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationUser as GqlEvaluationUserFieldsFragment
}
