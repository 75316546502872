import { useFetch, PaginatedModel } from '@cotiss/common'
import { TenderQuestionModel } from '@cotiss/tender-question'

type Props = {
  tenderId?: string
  limit?: number
  offset?: number
}

export const useListTenderQuestion = ({ tenderId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderQuestionModel>>('/v2/tender-questions', {
    route: `/v2/tender-questions`,
    params: { tenderId, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { tenderQuestions: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
