import React, { memo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { useMutateOrganisation } from '@cotiss/organisation'
import { DOCUMENT_FILE_TYPE, DocumentUpload } from '@cotiss/document'
import { ModalContent, ModalHeader, sentryService, useCallout, useToast } from '@cotiss/common'

export const SettingsOrganisationUploadLogoModal = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { uploadOrganisationLogo } = useMutateOrganisation()

  if (!user?.account?.organisation?._id) {
    return null
  }

  const handleUpload = async (file: File) => {
    if (!user?.account?.organisation?._id) {
      return
    }

    try {
      setIsSaving(true)
      await uploadOrganisationLogo(user?.account?.organisation?._id, file)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="relative min-w-[450px] max-w-[450px]">
      <ModalHeader heading="Upload organisation logo" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload onUpload={handleUpload} acceptedFileTypes={DOCUMENT_FILE_TYPE.image} maxMb={2} isSingle />
      </ModalContent>
    </div>
  )
})
