import {
  Badge,
  Button,
  ConfirmModal,
  ContactModel,
  Icon,
  NoDataPlaceholder,
  Table,
  TableColumn,
  TableHeader,
  TableRowCta,
  Text,
  useCallout,
  useToast,
} from '@cotiss/common'
import { useListOrganisationUser } from '@cotiss/organisation'
import {
  PreferredSupplierAddUserModal,
  PreferredSupplierContactModel,
  useListPreferredSupplier,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier'
import { useUserAccess, userService } from '@cotiss/user'
import { compact, filter, find, includes, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'

type UserStatus = 'notInvited' | 'invited' | 'onboarded'

type ProcessedUser = {
  email: string
  name: string
  phoneNumber: string
  status: UserStatus
  contact?: ContactModel
}

type Props = {
  organisationId?: string
}

function getBadge(status: UserStatus): JSX.Element {
  switch (status) {
    default:
    case 'notInvited':
      return (
        <Badge variant="neutral" state="outline">
          Not invited
        </Badge>
      )
    case 'invited':
      return (
        <Badge variant="warning" state="outline">
          Invited
        </Badge>
      )
    case 'onboarded':
      return (
        <Badge variant="secondary" state="outline">
          <div className="flex items-center">
            <Icon className="mr-1" icon="check-verified-02" size={12} /> Onboarded
          </div>
        </Badge>
      )
  }
}

export const SupplierViewContactTab = memo(({ organisationId }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { preferredSuppliers, isLoading: isLoadingPreferredSuppliers } = useListPreferredSupplier({ pending: false, isEnabled: permissions.isBuyer })
  const { organisationUsers, isLoading: isLoadingOrganisationUsers } = useListOrganisationUser({ organisationId })
  const { updatePreferredSupplier, resendPreferredSupplierInvitation } = useMutatePreferredSupplier()
  const [isSavingMap, setIsSavingMap] = useState<Record<string, boolean>>({})
  const isLoading = permissions.isBuyer ? isLoadingPreferredSuppliers || isLoadingOrganisationUsers : isLoadingOrganisationUsers

  const { preferredSupplier, processedUsers } = useMemo(() => {
    let processedUsers: ProcessedUser[] = []
    const preferredSupplier = find(preferredSuppliers, (preferredSupplier) => preferredSupplier.supplierOrganisation?._id === organisationId)
    if (!preferredSupplier) {
      processedUsers = map(organisationUsers, (user) => ({
        email: user.email,
        name: userService.getFullName(user),
        phoneNumber: user.phone || '--',
        status: 'onboarded' as UserStatus,
      }))

      return { preferredSupplier, processedUsers }
    }

    const accountUserEmails = compact(map(preferredSupplier.supplierOrganisation?.account?.accountUser, ({ email }) => email))
    const uniqueContacts = filter(preferredSupplier.contacts, (contact) => !includes(accountUserEmails, contact.email))

    processedUsers = compact([
      ...map(preferredSupplier.supplierOrganisation?.account?.accountUser, (user) => ({
        email: user.email,
        name: userService.getFullName(user),
        phoneNumber: user.phone || '--',
        status: 'onboarded' as UserStatus,
      })),
      ...map(uniqueContacts, (contact) => ({
        email: contact.email,
        name: userService.getFullName(contact) || '--',
        phoneNumber: contact.phoneNumber || '--',
        status: (contact.invitationSent ? 'invited' : 'notInvited') as UserStatus,
        contact: contact,
      })),
    ])

    return { preferredSupplier, processedUsers }
  }, [organisationUsers, preferredSuppliers, organisationId])

  const handleAddUserModalSubmit = async (contact: PreferredSupplierContactModel) => {
    if (!preferredSupplier) {
      return
    }

    await updatePreferredSupplier(preferredSupplier._id, {
      contacts: [contact],
    })

    if (contact.sendInvitationEmail) {
      openToast('Email invitation sent')
    }
  }

  const handleResend = async (contactId: string, preferredSupplierId: string) => {
    try {
      setIsSavingMap({ ...isSavingMap, [contactId]: true })
      await resendPreferredSupplierInvitation(preferredSupplierId, { contactId })
      setIsSavingMap({ ...isSavingMap, [contactId]: false })
      openToast('Email invitation sent')
    } catch (error: any) {
      setIsSavingMap({ ...isSavingMap, [contactId]: false })
      throw error // Will be caught in the modal onSubmit and displayed as a toast
    }
  }

  const handleEditUser = async (newContact: PreferredSupplierContactModel, oldContact: ContactModel) => {
    if (!preferredSupplier) {
      return
    }
    try {
      setIsSavingMap({ ...isSavingMap, [oldContact._id]: true })
      await updatePreferredSupplier(preferredSupplier._id, {
        contacts: [
          {
            _id: oldContact._id,
            ...newContact,
          },
        ],
      })
      setIsSavingMap({ ...isSavingMap, [oldContact._id]: false })
      if (newContact.sendInvitationEmail) {
        openToast('Email invitation sent')
      }
    } catch (error) {
      setIsSavingMap({ ...isSavingMap, [oldContact._id]: false })
      throw error // Will be caught in the modal onSubmit and displayed as a toast
    }
  }

  const handleInviteUser = async (contact: ContactModel) => {
    if (!preferredSupplier) {
      return
    }

    try {
      setIsSavingMap({ ...isSavingMap, [contact._id]: true })
      await updatePreferredSupplier(preferredSupplier._id, {
        contacts: [
          {
            ...contact,
            sendInvitationEmail: true,
          },
        ],
      })
      setIsSavingMap({ ...isSavingMap, [contact._id]: false })
      openToast('Email invitation sent')
    } catch (error) {
      setIsSavingMap({ ...isSavingMap, [contact._id]: false })
      throw error // Will be caught in the modal onSubmit and displayed as a toast
    }
  }

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Email',
        rows: map(processedUsers, (user) => ({
          content: () => <Text className="truncate">{user.email}</Text>,
          cta:
            preferredSupplier && user.contact ? (
              <TableRowCta
                actions={
                  user.status === 'invited'
                    ? [
                        {
                          label: 'Re-send invite',
                          onClick: () =>
                            openModal(
                              <ConfirmModal
                                heading="Are you sure you want to re-send this invite?"
                                description="This user will be sent an email to join Cotiss"
                                onSubmit={() => handleResend(user.contact?._id as string, preferredSupplier._id)}
                              />
                            ),
                          isDisabled: isSavingMap[user.contact?._id],
                        },
                      ]
                    : [
                        {
                          label: 'Edit',
                          onClick: () =>
                            openModal(
                              <PreferredSupplierAddUserModal
                                contact={{ ...(user.contact as ContactModel), sendInvitationEmail: user.contact?.invitationSent as boolean }}
                                onSubmit={(newContact) => handleEditUser(newContact, user.contact as ContactModel)}
                              />
                            ),
                          isDisabled: isSavingMap[user.contact?._id],
                        },
                        {
                          label: 'Invite to Cotiss',
                          onClick: () =>
                            openModal(
                              <ConfirmModal
                                heading="Are you sure that you want to invite this user to Cotiss?"
                                description="Once they are invited, you cannot edit their details."
                                onSubmit={() => handleInviteUser(user.contact as ContactModel)}
                              />
                            ),
                          isDisabled: isSavingMap[user.contact?._id],
                        },
                      ]
                }
              />
            ) : undefined,
        })),
      },
      {
        heading: 'Name',
        rows: map(processedUsers, (user) => ({
          content: () => <Text>{user.name}</Text>,
        })),
      },
      {
        heading: 'Phone number',
        rows: map(processedUsers, (user) => ({
          content: () => <Text>{user.phoneNumber || '--'}</Text>,
        })),
      },
      ...(preferredSupplier
        ? [
            {
              heading: 'Status',
              rows: map(processedUsers, (user) => ({
                content: () => <Text>{getBadge(user.status)}</Text>,
              })),
            },
          ]
        : []),
    ]

    return { columns }
  }, [preferredSupplier, processedUsers])

  if (!preferredSupplier && !processedUsers.length && !isLoading) {
    return <NoDataPlaceholder label="This organisation does not currently have any users" variant="gray" />
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center space-x-4" variant="white">
        <div>
          <Text className="font-medium" variant="heading" font="jakarta" size="md">
            Users
          </Text>
          {preferredSupplier && (
            <Text className="mt-1" variant="light" size="sm">
              Add users to this contact
            </Text>
          )}
        </div>
        {preferredSupplier && (
          <Button onClick={() => openModal(<PreferredSupplierAddUserModal onSubmit={handleAddUserModalSubmit} />)} variant="secondary" size="sm">
            + Add user
          </Button>
        )}
      </TableHeader>
      <Table
        columns={columns}
        emptyCta={
          <Button
            size="sm"
            variant="secondary"
            state="text"
            onClick={() => openModal(<PreferredSupplierAddUserModal onSubmit={handleAddUserModalSubmit} />)}
          >
            + Add user
          </Button>
        }
        isLoading={isLoading}
      />
    </>
  )
})
