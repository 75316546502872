import { gql } from '@apollo/client'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { EVALUATION_USER_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event'
import { GqlEvaluationUserEnvelopeListQuery, GqlEvaluationUserEnvelopeListInput } from '@gql'

export const queryEvaluationUserEnvelopeList = async (input: GqlEvaluationUserEnvelopeListInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserEnvelopeListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_USER_ENVELOPE_FRAGMENT}

      query EvaluationUserEnvelopeList($input: EvaluationUserEnvelopeListInput!) {
        evaluationUserEnvelopeList(input: $input) {
          items {
            ...EvaluationUserEnvelopeFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserEnvelopeList
}
