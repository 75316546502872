import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group'
import { GqlApprovalTemplateGroupListInput, GqlApprovalTemplateGroupListQuery } from '@gql'

export const queryApprovalTemplateGroupList = async (input: GqlApprovalTemplateGroupListInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateGroupListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      query ApprovalTemplateGroupList($input: ApprovalTemplateGroupListInput) {
        approvalTemplateGroupList(input: $input) {
          items {
            ...ApprovalTemplateGroupFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateGroupList
}
