import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardMetricCycleListInput, GqlPerformanceScorecardMetricCycleListQuery } from '@gql'

export const queryPerformanceScorecardMetricCycleList = async (input: GqlPerformanceScorecardMetricCycleListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      query PerformanceScorecardMetricCycleList($input: PerformanceScorecardMetricCycleListInput!) {
        performanceScorecardMetricCycleList(input: $input) {
          items {
            ...PerformanceScorecardMetricCycleFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleList
}
