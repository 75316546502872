import { PaginatedModel, PaginationParam, useFetch } from '@cotiss/common'
import { PriceItemResponsePopulatedModel } from '@cotiss/price-item-response'

type Props = PaginationParam & {
  tenderResponseId?: string
  tenderId?: string
}

export const useListPriceItemResponse = ({ tenderResponseId, tenderId, limit = -1, offset = 0, ...params }: Props) => {
  const { data, ...rest } = useFetch<PaginatedModel<PriceItemResponsePopulatedModel>>('/v2/price-item-response', {
    params: { tenderResponseId, tenderId, limit, offset, ...params },
    isEnabled: Boolean(tenderResponseId || tenderId),
  })

  return { priceItemResponses: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
