import { Form, ModalContent, Button, ModalConfirmationButton, Icon, sentryService, useCallout, useToast, Text } from '@cotiss/common'
import { ContractApproversTable } from '@cotiss/contract/components'
import React, { FormEvent, useState } from 'react'

type Props = {
  contractShellId: string
  contractId: string
  approvalId?: string
  onSubmit: () => void | Promise<unknown>
}

export const ContractConfirmSubmitModal = ({ contractShellId, contractId, approvalId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await onSubmit()
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[560px] max-w-[560px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="cross" />
        </Button>

        <Text className="font-semibold text-center" size="h5" font="jakarta">
          Are you sure you want to send this contract for approval and signature?
        </Text>

        <div className="mt-4">
          <Text className="text-center mb-4" size="sm">
            This contract will be reviewed by the users below. You will receive an email once the review has been completed.
          </Text>
          <ContractApproversTable size="small" contractShellId={contractShellId} contractId={contractId} approvalId={approvalId} />
        </div>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
}
