import { ConflictOfInterestOutcome, ConflictOfInterestResolution, ConflictOfInterestStatus } from '@cotiss/conflict-of-interest'

type GetConflictStatusParam = {
  dateSigned?: Date
  conflictDeclared: boolean
}

type GetConflictOutcomeParam = {
  dateSigned?: Date
  conflictDeclared: boolean
  resolution?: ConflictOfInterestResolution
}

class ConflictOfInterestService {
  getConflictStatus = ({ dateSigned, conflictDeclared }: GetConflictStatusParam): ConflictOfInterestStatus => {
    if (dateSigned) {
      if (conflictDeclared) {
        return 'conflict'
      } else {
        return 'no-conflict'
      }
    }

    return '--'
  }

  getConflictOutcome = ({ resolution, conflictDeclared, dateSigned }: GetConflictOutcomeParam): ConflictOfInterestOutcome => {
    const status = this.getConflictStatus({ conflictDeclared, dateSigned })
    if (status === '--') return '--'

    if (status === 'no-conflict') return 'included'

    if (status === 'conflict') {
      if (!resolution) return 'pending'
      if (resolution === 'exclude') return 'excluded'
      if (resolution === 'include') return 'managed'
    }

    return '--'
  }
}

export const conflictOfInterestService = new ConflictOfInterestService()
