import React, { memo, useState } from 'react'
import { SupplierViewDrawer } from '@cotiss/supplier'
import { Button, ConfirmModal, Dropdown, DropdownContent, Icon, useCallout } from '@cotiss/common'
import { TenderResponsePopulatedModel, TenderResponseSubmissionUpdateDrawer, useMutateTenderResponse } from '@cotiss/tender-response'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
  onView: (tenderResponse: TenderResponsePopulatedModel) => void
}

export const TenderResponseReviewListCta = memo(({ tenderResponse, onView }: Props) => {
  const { deleteTenderResponse } = useMutateTenderResponse()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { openModal, openDrawer, openNarrowDrawer } = useCallout()

  return (
    <div className="flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openNarrowDrawer(
              <SupplierViewDrawer
                organisationId={tenderResponse.procurementResponse.supplier._id}
                procurementResponseId={tenderResponse.procurementResponse._id}
                tenderResponseId={tenderResponse._id}
              />
            )
          }
        >
          View contact
        </DropdownContent>
        {tenderResponse.isBuyerSubmission && (
          <DropdownContent
            className="flex items-center"
            onClick={() => openDrawer(<TenderResponseSubmissionUpdateDrawer tenderResponseId={tenderResponse._id} />)}
          >
            Edit
          </DropdownContent>
        )}
        {tenderResponse.isBuyerSubmission && (
          <DropdownContent
            className="flex items-center"
            onClick={() =>
              openModal(
                <ConfirmModal
                  heading="Delete submission"
                  description="Are you sure you want to delete this submission?"
                  onSubmit={() => deleteTenderResponse(tenderResponse._id)}
                />
              )
            }
          >
            Delete
          </DropdownContent>
        )}
      </Dropdown>
      <Button onClick={() => onView(tenderResponse)} state="outline" variant="secondary" size="xs" isLink>
        View <Icon className="ml-1" icon="arrow-right" />
      </Button>
    </div>
  )
})
