import { filter, find, map, some } from 'lodash'
import React, { memo, useEffect, useMemo } from 'react'
import { userService } from '@cotiss/user'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Badge, Table, TableColumn, TableHeader, TableRow, Text } from '@cotiss/common'
import { useEvaluationEventAnalytics, useEvaluationUser } from '@cotiss/evaluation-event'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventPanelUpdateSummaryPanelTab = memo(({ evaluationEnvelope }: Props) => {
  const { track } = useEvaluationEventAnalytics()
  const { evaluationUsers, evaluationUserInSession } = useEvaluationUser()

  useEffect(() => {
    track('evaluation_event_panel_update_summary_panel_tab')
  }, [])

  const { columns } = useMemo(() => {
    const accessControl = { resourceType: 'envelope', resourceId: evaluationEnvelope.id }
    const moderateEvaluationUser = find(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'moderate' }))
    const evaluateEvaluationUsers = filter(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'evaluate' }))

    const userRows: TableRow[] = map(evaluateEvaluationUsers, (evaluationUser) => ({
      content: () => (
        <div className="flex items-center truncate">
          <Text className="truncate">{userService.getFullName(evaluationUser.user)}</Text>
          {evaluationUserInSession?.id === evaluationUser.id && (
            <Text className="ml-1" variant="light">
              (you)
            </Text>
          )}
        </div>
      ),
    }))

    const accessRows: TableRow[] = map(evaluateEvaluationUsers, () => ({
      content: () => (
        <Badge state="outline" variant="secondary">
          Evaluate
        </Badge>
      ),
    }))

    if (moderateEvaluationUser) {
      userRows.push({
        content: () => (
          <div className="flex items-center truncate">
            <Text className="truncate">{userService.getFullName(moderateEvaluationUser.user)}</Text>
            {evaluationUserInSession?.id === moderateEvaluationUser.id && (
              <Text className="ml-1" variant="light">
                (you)
              </Text>
            )}
          </div>
        ),
      })

      accessRows.push({
        content: () => (
          <Badge state="outline" variant="warning">
            Moderate
          </Badge>
        ),
      })
    }

    const columns: TableColumn[] = [
      {
        heading: 'users',
        rows: userRows,
      },
      {
        heading: 'tasks',
        rows: accessRows,
      },
    ]

    return { columns }
  }, [evaluationEnvelope, evaluationUsers, evaluationUserInSession])

  return (
    <>
      <TableHeader>
        <Text size="sm" variant="light">
          Envelope {evaluationEnvelope.order}
        </Text>
        <Text className="font-medium" size="lg">
          {evaluationEnvelope.name}
        </Text>
      </TableHeader>
      <Table columns={columns} />
    </>
  )
})
