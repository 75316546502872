import React, { memo, useEffect } from 'react'
import { PreferredSupplierBuyerList, useListPreferredSupplierBuyer } from '@cotiss/preferred-supplier'
import { Header, Page, PageContent, Text, useAnalytics } from '@cotiss/common'

export const PreferredSupplierBuyerListPage = memo(() => {
  const { track } = useAnalytics()
  const { preferredSupplierBuyers, isLoading } = useListPreferredSupplierBuyer()

  useEffect(() => {
    track('preferred_supplier_buyer_list_view')
  }, [])

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          Buyers
        </Text>
      </Header>
      <PageContent>
        <PreferredSupplierBuyerList isLoading={isLoading} preferredSupplierBuyers={preferredSupplierBuyers} />
      </PageContent>
    </Page>
  )
})
