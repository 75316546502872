import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { ApolloClient, InMemoryCache, Observable, createHttpLink, from } from '@apollo/client'
import { authResource, authService } from '@cotiss/auth'
import { localStorageService, utilService } from '@cotiss/common'
import { clearReactiveApprovalTemplate } from '@cotiss/approval-template'
import {
  clearReactivePerformanceMetric,
  clearReactivePerformanceScorecard,
  clearReactivePerformanceScorecardDocument,
  clearReactivePerformanceScorecardMetric,
  clearReactivePerformanceScorecardMetricCycle,
  clearReactivePerformanceScorecardMetricUser,
  clearReactivePerformanceScorecardUser,
} from '@cotiss/performance'
import { clearReactiveApprovalTemplateGroup } from '@cotiss/approval-template-group'
import {
  clearReactiveEvaluation,
  clearReactiveEvaluationCriteria,
  clearReactiveEvaluationEnvelope,
  clearReactiveEvaluationEnvelopeDocument,
  clearReactiveEvaluationEvent,
  clearReactiveEvaluationEventDocument,
  clearReactiveEvaluationSubmission,
  clearReactiveEvaluationSubmissionDocument,
  clearReactiveEvaluationUser,
} from '@cotiss/evaluation-event'

const httpLink = createHttpLink({ uri: `${process.env.API_DOMAIN}/graphql` })

const transactionLink = setContext(async (_, { headers }) => {
  const accessToken = localStorageService.getItem('access-token')

  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'X-Transaction-Id': utilService.generateUid(),
    },
  }
})

const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    const refreshToken = localStorageService.getItem('refresh-token')

    if (!refreshToken || !authService.isTokenValid(refreshToken)) {
      window.location.href = '/login'
      return
    }

    return new Observable((observer) => {
      authResource
        .refreshToken(refreshToken)
        .then((response) => {
          if (!response) {
            return
          }

          localStorageService.setItem('access-token', response.accessToken)
          localStorageService.setItem('refresh-token', response.refreshToken)

          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${response.accessToken}`,
            },
          }))
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }

          // Retry last failed request
          forward(operation).subscribe(subscriber)
        })
        .catch((error) => {
          observer.error(error)
          window.location.href = '/login'
        })
    })
  }
})

const appLink = from([errorLink, httpLink])

export const apolloService = new ApolloClient({
  link: transactionLink.concat(appLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
})

export const clearApolloCache = async () => {
  await Promise.all([
    apolloService.clearStore(),
    clearReactiveApprovalTemplate(),
    clearReactiveApprovalTemplateGroup(),
    clearReactiveEvaluation(),
    clearReactiveEvaluationCriteria(),
    clearReactiveEvaluationEnvelope(),
    clearReactiveEvaluationEnvelopeDocument(),
    clearReactiveEvaluationEvent(),
    clearReactiveEvaluationEventDocument(),
    clearReactiveEvaluationSubmission(),
    clearReactiveEvaluationSubmissionDocument(),
    clearReactiveEvaluationUser(),
    clearReactivePerformanceMetric(),
    clearReactivePerformanceScorecard(),
    clearReactivePerformanceScorecardDocument(),
    clearReactivePerformanceScorecardMetric(),
    clearReactivePerformanceScorecardMetricCycle(),
    clearReactivePerformanceScorecardMetricUser(),
    clearReactivePerformanceScorecardUser(),
  ])
}
