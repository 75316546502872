import React, { memo } from 'react'
import { ContractCreateMethod } from '@cotiss/contract'
import { Badge, Banner, Field, Input, Radio, RadioCard, Text, useFeature } from '@cotiss/common'

type Props = {
  title: string
  createMethod: ContractCreateMethod
  onTitleChange: (title: string) => void
  onCreateMethodChange: (createMethod: ContractCreateMethod) => void
  isDisabled: boolean
}

export const ContractCreateInitialStep = memo(({ title, createMethod, onTitleChange, onCreateMethodChange, isDisabled }: Props) => {
  const isEsCoreFieldDescriptorsEnabled = useFeature('es-core-field-descriptors')

  return (
    <>
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Title your contract
          </Text>
          <Text size="sm">Enter a title below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 1 of {createMethod === 'with-procurement' ? '2' : '1'}
        </Badge>
      </Banner>
      <Field className="mt-8" label="Contract title">
        <Input
          value={title}
          onChange={({ target }) => onTitleChange(target.value)}
          maxLength={100}
          placeholder="Enter title"
          isDisabled={isDisabled}
          isRequired
        />
        {isEsCoreFieldDescriptorsEnabled && (
          <Text className="mt-1" variant="light" size="sm">
            Contact Name, Execution date: YYYYMMDD, Agreement type (SoW, Contract, Variation), Activity Name
          </Text>
        )}
      </Field>
      <Field className="mt-8" label="Procurement association" supplementary="Indicate whether this contract is related to a procurement.">
        <div className="grid grid-cols-2 gap-2">
          <RadioCard<ContractCreateMethod>
            className="border border-gray-100 p-3.5 mr-2"
            value="with-procurement"
            name="contract-create-method"
            onChange={() => onCreateMethodChange('with-procurement')}
            isSelected={createMethod === 'with-procurement'}
            isRequired
          >
            <div className="flex justify-start">
              <Radio isChecked={createMethod === 'with-procurement'} />
            </div>
            <Text className="font-medium mt-2">Link to procurement</Text>
            <Text size="sm" variant="light">
              Select a procurement to associate with this contract.
            </Text>
          </RadioCard>
          <RadioCard<ContractCreateMethod>
            className="border border-gray-100 p-3.5 mr-2"
            value="without-procurement"
            name="contract-create-method"
            onChange={() => onCreateMethodChange('without-procurement')}
            isSelected={createMethod === 'without-procurement'}
            isRequired
          >
            <div className="flex justify-start">
              <Radio isChecked={createMethod === 'without-procurement'} />
            </div>
            <Text className="font-medium mt-2">Independent contract</Text>
            <Text size="sm" variant="light">
              Contract is not linked to a procurement within Cotiss.
            </Text>
          </RadioCard>
        </div>
      </Field>
    </>
  )
})
