import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useState } from 'react'
import { TRANSITION_DURATION, Text } from '@cotiss/common'

type Props = {
  className?: string
  tooltipClassName?: string
  children: ReactNode
  tooltip: string
  width?: number
  position?: 'left' | 'centre' | 'bottom'
  isAbsolute?: boolean
  isEnabled?: boolean
}

export const Tooltip = memo((props: Props) => {
  const { className, tooltipClassName, children, tooltip, width = 250, position = 'left', isAbsolute, isEnabled = true } = props
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const containerClasses = classNames(className, 'inline-block cursor-pointer', {
    relative: !isAbsolute,
    absolute: isAbsolute,
  })
  const tooltipClasses = classNames(tooltipClassName, 'absolute bg-white shadow rounded px-2 py-1', {
    'bottom-[calc(100%+10px)] left-1/2': position === 'left',
    'bottom-[calc(100%+10px)] left-full': position === 'centre',
    'top-[calc(100%+10px)] left-full': position === 'bottom',
  })

  return (
    <div className={containerClasses} onMouseEnter={() => isEnabled && setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
      <AnimatePresence>
        {isTooltipOpen && (
          <motion.div
            className={tooltipClasses}
            hidden={!isTooltipOpen}
            initial={{ y: 5, x: '-50%', opacity: 0 }}
            animate={{ y: 0, x: '-50%', opacity: 1 }}
            transition={{ duration: TRANSITION_DURATION.md }}
            exit={{ y: 5, x: '-50%', opacity: 0 }}
            style={{ width }}
          >
            <Text className="whitespace-pre-wrap" size="sm">
              {tooltip}
            </Text>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  )
})
