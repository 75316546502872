import React, { memo, useMemo, useState } from 'react'
import { Button, Dropdown, DropdownContent, ConfirmModal, useCallout, Icon } from '@cotiss/common'
import {
  ContractDocumentAttachmentSignature,
  contractService,
  useGetContractShell,
  useMutateContractShell,
  ContractSignatureModal,
} from '@cotiss/contract'
import { find } from 'lodash'

type Props = {
  signature: ContractDocumentAttachmentSignature
  contractShellId: string
}

export const ContractSignersEditDeleteCta = memo(({ signature, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateAttachmentSignaturesBulk } = useMutateContractShell()
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { existingSignatures, documentShell } = useMemo(() => {
    const documentShell = find(find(contractShell?.contracts, { status: 'DRAFTING' })?.documentShells, ({ type }) => type === 'CONTRACT')
    return { existingSignatures: documentShell?.attachments.length ? documentShell?.attachments[0].signatures : [], documentShell }
  }, [contractShell])

  const handleDelete = async (id: string) => {
    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const attachment = documentShell?.attachments.length ? documentShell?.attachments[0] : null

    if (!contractShell || !contract || !documentShell || !attachment) {
      return
    }

    const updatedSignatures = existingSignatures.filter((signature) => signature._id !== id)
    await updateAttachmentSignaturesBulk({
      contractShellId: contractShell._id,
      contractId: contract._id,
      documentShellId: documentShell._id,
      attachmentId: attachment?._id,
      body: { items: updatedSignatures },
    })
  }

  return (
    <>
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
        isDisabled={isLoading}
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="fixed top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(<ContractSignatureModal contractShellId={contractShellId} signature={signature} existingSignatures={existingSignatures} />)
          }
        >
          Edit
        </DropdownContent>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Delete"
                description="Are you sure you want to delete this signature?"
                onSubmit={() => handleDelete(signature._id)}
              />
            )
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </>
  )
})
