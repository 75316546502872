import React, { forwardRef, useState } from 'react'
import { TextArea, TextAreaProps, sentryService, useToast } from '@cotiss/common'

type Props = TextAreaProps & {
  onSubmit: () => Promise<void>
}

export const AsyncTextArea = forwardRef<HTMLTextAreaElement, Props>(({ onSubmit, value, isDisabled, ...rest }, ref) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)

  const handleBlur = async () => {
    try {
      setIsSaving(true)
      await onSubmit()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return <TextArea {...rest} value={value} onBlur={handleBlur} isDisabled={isDisabled || isSaving} ref={ref} />
})
