import { filter, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { userService } from '@cotiss/user'
import { GqlEvaluationUserFieldsFragment } from '@gql'
import { EvaluationEventUserAddModal, useEvaluationEvent, useEvaluationEventAnalytics, useEvaluationUser } from '@cotiss/evaluation-event'
import { Badge, Button, ConfirmModal, Table, TableColumn, TableRowCta, Text, sentryService, useAsyncEffect, useCallout } from '@cotiss/common'

type Props = {
  isEditable?: boolean
}

export const EvaluationEventUserList = memo(({ isEditable }: Props) => {
  const { openModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationUserInSession } = useEvaluationUser()
  const { evaluationUsers, queryEvaluationUserList, mutateUpdateEvaluationUser, mutateDeleteEvaluationUser } = useEvaluationUser()
  const canEdit = isEditable && evaluationUserInSession?.role === 'owner'

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      await queryEvaluationUserList({ filter: { evaluationEventId: evaluationEvent.id } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }

    setIsLoading(false)
  }, [])

  const handleDeleteEvaluationUser = async (evaluationUser: GqlEvaluationUserFieldsFragment) => {
    // TODO: Currently this method assumes the user is an owner. We don't currently support any other evaluation user roles at this stage. If we
    // TODO: encounter any other kind of user, bail out.
    if (evaluationUser.role !== 'owner') {
      return
    }

    track('evaluation_event_wizard_panel_delete_owner_submit')

    if (!evaluationUser.accessControls.length) {
      // If the user does not have any access controls (I.e. are not a panel member), delete them.
      await mutateDeleteEvaluationUser({ evaluationUserId: evaluationUser.id })
    } else {
      // Otherwise remove the `role` from the user.
      await mutateUpdateEvaluationUser({ evaluationUserId: evaluationUser.id, role: null })
    }
  }

  const { columns } = useMemo(() => {
    const owners = filter(evaluationUsers, { role: 'owner' })

    const columns: TableColumn[] = [
      {
        heading: 'User',
        thClassName: 'w-1/3',
        rows: map(owners, (evaluationUser) => {
          const canDelete = canEdit && evaluationUserInSession?.id !== evaluationUser.id && owners.length > 1

          return {
            content: () => <Text isInline>{userService.getFullName(evaluationUser.user)}</Text>,
            cta: canDelete && (
              <TableRowCta
                actions={[
                  {
                    label: 'Delete',
                    onClick: () => {
                      openModal(
                        <ConfirmModal
                          heading="Delete panel member"
                          description="Are you sure you want to delete this panel member?"
                          onSubmit={() => handleDeleteEvaluationUser(evaluationUser)}
                        />
                      )
                    },
                  },
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Role',
        thClassName: 'w-2/3',
        rows: map(owners, () => ({
          content: () => (
            <Badge state="outline" variant="neutral">
              Owner
            </Badge>
          ),
        })),
      },
    ]

    return { columns }
  }, [evaluationEvent, evaluationUsers])

  return (
    <Table
      columns={columns}
      emptyCta={
        canEdit && (
          <Button size="sm" variant="secondary" state="text" onClick={() => openModal(<EvaluationEventUserAddModal />)}>
            + Add owner
          </Button>
        )
      }
      isLoading={isLoading}
    />
  )
})
