import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event'
import {
  GqlUpdateEvaluationEnvelopeInput,
  GqlUpdateEvaluationEnvelopeMutation,
  GqlUpdateEvaluationEnvelopeMutationVariables,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'

export const mutateUpdateEvaluationEnvelope = async (input: GqlUpdateEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationEnvelopeMutation, GqlUpdateEvaluationEnvelopeMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      mutation UpdateEvaluationEnvelope($input: UpdateEvaluationEnvelopeInput!) {
        updateEvaluationEnvelope(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationEnvelope as GqlEvaluationEnvelopeFieldsFragment
}
