import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { GqlDeleteApprovalTemplateUserInput, GqlDeleteApprovalTemplateUserMutation, GqlDeleteApprovalTemplateUserMutationVariables } from '@gql'

export const mutateDeleteApprovalTemplateUser = async (input: GqlDeleteApprovalTemplateUserInput) => {
  const { data } = await apolloService.mutate<GqlDeleteApprovalTemplateUserMutation, GqlDeleteApprovalTemplateUserMutationVariables>({
    mutation: gql`
      mutation DeleteApprovalTemplateUser($input: DeleteApprovalTemplateUserInput!) {
        deleteApprovalTemplateUser(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteApprovalTemplateUser as boolean
}
