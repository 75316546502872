import { Button, ListItemsSkeleton, Table_DEPRECATED, Tbody_DEPRECATED, Text, useCallout } from '@cotiss/common'
import {
  PreferredSupplierAddUserModal,
  PreferredSupplierContactModel,
  PreferredSupplierOrganisationCard,
  PreferredSupplierTenderResponseList,
  useGetPreferredSupplier,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier'
import { useGetTenderInvitation } from '@cotiss/tender-invitation'
import { find, map } from 'lodash'
import React, { memo, useEffect } from 'react'

type Props = {
  isDisabled?: boolean
  isNewContact?: boolean
  onAddUsers: (idsToAdd: string[]) => void
  onRemoveUsers: (idsToRemove: string[]) => void
  preferredSupplierId: string
  selectedUsers: string[]
  tenderInvitationId?: string
}

export const TenderInvitationInviteStep = memo(
  ({ isDisabled, isNewContact, onAddUsers, onRemoveUsers, preferredSupplierId, selectedUsers, tenderInvitationId }: Props) => {
    const { openModal } = useCallout()
    const { preferredSupplier, isInitialLoading: isPreferredSupplierLoading } = useGetPreferredSupplier(preferredSupplierId)
    const { tenderInvitation, isInitialLoading: isTenderInvitationLoading } = useGetTenderInvitation(tenderInvitationId)
    const { updatePreferredSupplier } = useMutatePreferredSupplier()
    const isLoading = isPreferredSupplierLoading || isTenderInvitationLoading

    useEffect(() => {
      if (isNewContact && isPreferredSupplierLoading === false) {
        onAddUsers(map(preferredSupplier?.contacts, '_id'))
      }
    }, [isPreferredSupplierLoading])

    const handleAddUserModalSubmit = async (contact: PreferredSupplierContactModel) => {
      if (!preferredSupplier) {
        return
      }

      const newPreferredSupplier = await updatePreferredSupplier(preferredSupplier._id, {
        contacts: [{ ...contact, sendInvitationEmail: false }],
      })
      const newContact = find(newPreferredSupplier.contacts, ({ email }) => email === contact.email)

      if (newContact) {
        onAddUsers([...selectedUsers, newContact._id])
      }
    }

    return (
      <div className="flex-1 flex flex-col p-6">
        <PreferredSupplierOrganisationCard
          organisation={{
            logo: preferredSupplier?.supplierOrganisation?.logo,
            name: preferredSupplier?.supplierOrganisation ? preferredSupplier?.supplierOrganisation.name : preferredSupplier?.organisationName,
            claimedStatus: preferredSupplier?.supplierOrganisation ? preferredSupplier?.supplierOrganisation.claimedStatus : 'UNCLAIMED',
            organisationId: preferredSupplier?.supplierOrganisation?._id,
            city: preferredSupplier?.supplierOrganisation?.account?.location?.city,
            country: preferredSupplier?.supplierOrganisation?.account?.location?.country,
            website: preferredSupplier?.supplierOrganisation?.website,
            contactPhone: preferredSupplier?.supplierOrganisation?.contactPhone,
          }}
        />
        <div className="flex justify-between mt-4 items-center">
          <div>
            <Text className="font-medium" size="h7" variant="heading" font="jakarta" isInline>
              Select who you want to invite
            </Text>
            <Text className="ml-2 font-medium" size="h7" variant="light" font="jakarta" isInline>
              ({selectedUsers.length} selected)
            </Text>
          </div>
          {preferredSupplier && (
            <Button
              size="sm"
              state="translucent"
              variant="secondary"
              onClick={() => openModal(<PreferredSupplierAddUserModal isTenderInvitation onSubmit={handleAddUserModalSubmit} />)}
            >
              + Add email
            </Button>
          )}
        </div>
        {isLoading && (
          <>
            <Table_DEPRECATED>
              <Tbody_DEPRECATED>
                <ListItemsSkeleton rows={3} columns={1} />
              </Tbody_DEPRECATED>
            </Table_DEPRECATED>
          </>
        )}
        {!isLoading && preferredSupplier && (
          <PreferredSupplierTenderResponseList
            className="mt-4"
            isDisabled={isDisabled}
            onAddUsers={onAddUsers}
            onRemoveUsers={onRemoveUsers}
            preferredSupplier={preferredSupplier}
            selectedUsers={selectedUsers}
            tenderInvitation={tenderInvitation}
          />
        )}
      </div>
    )
  }
)
