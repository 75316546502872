import { Button, DraggableListItem, Text, useCallout } from '@cotiss/common'
import { EvaluationEventEnvelopeUpdateModal } from '@cotiss/evaluation-event'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import React, { memo } from 'react'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isActive?: boolean
  isInOverlay?: boolean
  isEditable?: boolean
  isSortable?: boolean
  isDisabled?: boolean
}

export const EvaluationEventEnvelopeListItem = memo((props: Props) => {
  const { evaluationEnvelope, isEditable, isDisabled, ...restProps } = props
  const { openModal } = useCallout()

  return (
    <DraggableListItem id={evaluationEnvelope.id} isDisabled={isDisabled} {...restProps}>
      <div className="relative flex items-center justify-start w-full p-4">
        <Text className="mr-4 truncate">{evaluationEnvelope.name}</Text>
        {isEditable && (
          <Button
            className="invisible group-hover:visible"
            onClick={() => openModal(<EvaluationEventEnvelopeUpdateModal evaluationEnvelope={evaluationEnvelope} />)}
            state="outline"
            variant="secondary"
            size="xs"
            isDisabled={isDisabled}
          >
            Edit
          </Button>
        )}
      </div>
    </DraggableListItem>
  )
})
