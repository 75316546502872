import { find, map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { userService } from '@cotiss/user'
import { documentService, useGetDocument } from '@cotiss/document'
import { useEvaluationEnvelopeDocument, useEvaluationUser } from '@cotiss/evaluation-event'
import {
  Button,
  ErrorPanel,
  PageContent,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  Text,
  datetimeService,
  sentryService,
  useAsyncEffect,
} from '@cotiss/common'

export const EvaluationEventEnvelopeSubmissionResourcesTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const { evaluationUsers } = useEvaluationUser()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationEnvelopeDocuments, queryEvaluationEnvelopeDocumentList } = useEvaluationEnvelopeDocument()
  const { evaluationEventId, evaluationEnvelopeId } = useParams<{ evaluationEventId: string; evaluationEnvelopeId: string }>()

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryEvaluationEnvelopeDocumentList({
        filter: { evaluationEventId, evaluationEnvelopeId },
        pagination: { page: currentPage, pageSize: 100 },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Resource name',
        rows: map(evaluationEnvelopeDocuments, ({ fileName, documentId }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(documentId)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Uploaded by',
        rows: map(evaluationEnvelopeDocuments, ({ evaluationUserId }) => {
          const evaluationUser = find(evaluationUsers, { id: evaluationUserId })
          return {
            content: () => <Text>{evaluationUser ? userService.getFullName(evaluationUser.user) : '--'}</Text>,
          }
        }),
      },
      {
        heading: 'Date created',
        rows: map(evaluationEnvelopeDocuments, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
      {
        heading: 'Size',
        rows: map(evaluationEnvelopeDocuments, ({ fileSize }) => ({
          content: () => <Text>{documentService.formatSize(fileSize)}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [evaluationEnvelopeDocuments])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  return (
    <PageContent>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" font="jakarta" size="lg">
          Resources
        </Text>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </PageContent>
  )
})
